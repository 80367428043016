import "./Greeting.css"

function Greeting() {
  return (
    <div className="greeting-div">
      <h2 className="greeting-header">
        Ready to play tic-tac-toe against a deep neural network? Click on any square on any of the boards to 
        begin. When you start playing, different colors will appear on different
        parts of the board to explain what the model is thinking about. Investigate
        the descriptions below to learn what the colors mean on each board!
      </h2>
    </div>
  )
}

export default Greeting;