import model from "./modelAsJsVar"

const tackerMatmul = (matrix, vector) => {
  let output = []

  for (let i = 0; i < matrix.length; i++) {
    const row = matrix[i]
    let sum = {'a': 0, 'b': 0, 'c': 0, 'd': 0, 'e': 0, 'f': 0, 'g': 0, 'h': 0, 'i': 0}

    for (let j = 0; j < vector.length; j++) {
      const valueFromVector = vector[j]
      const valueFromMatrix = row[j]
      // syntax for the line below: https://stackoverflow.com/questions/34913675/how-to-iterate-keys-values-in-javascript
      for (const [key, value] of Object.entries(valueFromVector)) {
        // console.log(valueFromVector)
        sum[key] += value * valueFromMatrix
      }
    }
    // console.log(sum)
    output.push(sum)
  }
  return output
}

const addBias = (biasVector, vector) => {
  const numTrackers = 9 // value to split the bias fairly to each tracker

  for (let i =0; i< vector.length; i++) {
    const currentBais = biasVector[i]

    // syntax for the line below: https://stackoverflow.com/questions/34913675/how-to-iterate-keys-values-in-javascript
    for (const [key, value] of Object.entries(vector[i])) {
      vector[i][key] = value + (currentBais / numTrackers)
    }
  }
  return vector
}

const ReLU = (vector) => {
  for (let i = 0; i < vector.length; i++) {
    let sum = 0

    // syntax for the line below: https://stackoverflow.com/questions/34913675/how-to-iterate-keys-values-in-javascript
    for (const [key, value] of Object.entries(vector[i])) {
      sum += value
    }

    if (sum < 0) {
      vector[i] = {'a': 0, 'b': 0, 'c': 0, 'd': 0, 'e': 0, 'f': 0, 'g': 0, 'h': 0, 'i': 0}
    }
  }
  return vector;
}



const runInterpretableModel = (board) => {
  const firstMatrix = model['linear_relu_stack.0.weight']
  const firstBiasVector = model['linear_relu_stack.0.bias']
  const secondMatrix = model['linear_relu_stack.2.weight']
  const secondBiasVector = model['linear_relu_stack.2.bias']
  const thirdMatrix = model['linear_relu_stack.4.weight']
  const thirdBiasVecotr = model['linear_relu_stack.4.bias']

  // console.log(board)

  const modelInputStr = []
  for (let i =0; i < board.length; i++) {
    for (let j= 0; j < board[0].length; j++) {
      modelInputStr.push(board[i][j])
    }
  }
  let modelInput = []

  for (let i = 0; i < modelInputStr.length; i++) {
    // console.log(modelInputStr[i])
    if (modelInputStr[i] === '') {
      modelInput.push(0);
    } else if (modelInputStr[i] === "X") {
      modelInput.push(1);
    } else {
      modelInput.push(-1);
    }
  }

  // console.log(modelInput)


  const initialTrackers = [
    {'a': modelInput[0], 'b': 0, 'c': 0, 'd': 0, 'e': 0, 'f': 0, 'g': 0, 'h': 0, 'i': 0},
    {'a': 0, 'b': modelInput[1], 'c': 0, 'd': 0, 'e': 0, 'f': 0, 'g': 0, 'h': 0, 'i': 0},
    {'a': 0, 'b': 0, 'c': modelInput[2], 'd': 0, 'e': 0, 'f': 0, 'g': 0, 'h': 0, 'i': 0},
    {'a': 0, 'b': 0, 'c': 0, 'd': modelInput[3], 'e': 0, 'f': 0, 'g': 0, 'h': 0, 'i': 0},
    {'a': 0, 'b': 0, 'c': 0, 'd': 0, 'e': modelInput[4], 'f': 0, 'g': 0, 'h': 0, 'i': 0},
    {'a': 0, 'b': 0, 'c': 0, 'd': 0, 'e': 0, 'f': modelInput[5], 'g': 0, 'h': 0, 'i': 0},
    {'a': 0, 'b': 0, 'c': 0, 'd': 0, 'e': 0, 'f': 0, 'g': modelInput[6], 'h': 0, 'i': 0},
    {'a': 0, 'b': 0, 'c': 0, 'd': 0, 'e': 0, 'f': 0, 'g': 0, 'h': modelInput[7], 'i': 0},
    {'a': 0, 'b': 0, 'c': 0, 'd': 0, 'e': 0, 'f': 0, 'g': 0, 'h': 0, 'i': modelInput[8]}
  ]

  // console.log(initialTrackers)

  // const initialTrackers = [
  //   {"a": 1, "b": 0},
  //   {'a': 0, 'b': 1}
  // ]

  // const matrix = [
  //   [1, 2],
  //   [3, 4]
  // ]


  const matmulOne = tackerMatmul(firstMatrix, initialTrackers)
  const biasOne = addBias(firstBiasVector, matmulOne)
  const ReLUOne = ReLU(biasOne)


  const matmulTwo = tackerMatmul(secondMatrix, ReLUOne)
  const biasTwo = addBias(secondBiasVector, matmulTwo)
  const ReLUTwo = ReLU(biasTwo)


  const matmulThree = tackerMatmul(thirdMatrix, ReLUTwo)
  const biasThree = addBias(secondBiasVector, matmulThree)
  const ReLUThree = ReLU(biasThree)

  return ReLUThree
  // const 

//   const secondMatrixInput = ReLU(addBias(firstBiasVector, matmul(firstMatrix, modelInput)))
//   const thirdMatrixInput = ReLU(addBias(secondBiasVector, matmul(secondMatrix, secondMatrixInput)))
//   const output = addBias(thirdBiasVecotr, matmul(thirdMatrix, thirdMatrixInput))

//   console.log(output)
//   const best_idx = selectMoveFromOutput(board, output)
//   return [Math.floor(best_idx / 3), best_idx % 3]
}

export default runInterpretableModel