import "./MainBoard.css"
import "../App.css"

function numToColorStr(num) {
  if (num <= 0) {
    return "rgb(255, 255,255)"
  }


  return `rgb(${((1- num) * 255)}, 255, ${((1- num) * 255)})`

}

function MainBoard( {pieces_arr, updateBoard, confidenceArr} ) {
  return (
    <div>
      <h2 className="board-header">Confidence Explanation</h2>
      {pieces_arr.map((row, row_idx) => {
        return (
          <div key={row_idx} className="row">
            {row.map((piece_str, col_idx) => {
            //   console.log("here")
              return (
                <div
                  style={{backgroundColor: numToColorStr(confidenceArr[row_idx*3 + col_idx])}}
                  key={row_idx * 10 + col_idx}
                  className="square"
                  onClick={() => {
                    updateBoard(row_idx, col_idx)
                  }}
                >{piece_str}</div>
              )
            })}
          </div>
        )
      })} 
    </div>
  )
}

export default MainBoard;