import "./Footer.css"

function Footer() {
    return (
        <footer>
            <a className="credit" href="https://github.com/zroe1">Brought to you by Zephaniah Roe</a>
        </footer>
    )
}

export default Footer