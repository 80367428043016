import LIME_obj from "./LimeAsJsVar"

const getXs = (board) => {
    let xs = []
    console.log(board)

    // bitmap for xs
    for (let i = 0; i < board.length; i++) {
      for (let j = 0; j < board[0].length; j++) {

        if (board[i][j] === "X") {
          xs.push(1);
        } else {
          xs.push(0);
        }

      }
    }

    // bitmap for ys
    for (let i = 0; i < board.length; i++) {
      for (let j = 0; j < board[0].length; j++) {
    
        if (board[i][j] === "O") {
          xs.push(1);
        } else {
          xs.push(0);
        }

      }
    }

    return xs
}

const matmul = (matrix, vector) => {
  let output = []

  for (let i = 0; i < matrix.length; i++) {
    const row = matrix[i]

    let sum = 0
    for (let j =0; j < row.length; j++) {
        sum += matrix[i][j] * vector[j]
    }
    output.push(sum)
  }
  return output
}

function runLIME(orginalLoc, newestMove, board) {
  const weights = LIME_obj[`${orginalLoc[0] * 3 + orginalLoc[1]}`]
  const newestMoveWieghts = weights[newestMove[0] * 3 + newestMove[1]]

  const xs = getXs(board)
  console.log(xs, 'm', matmul(weights, xs), 'w', newestMoveWieghts)

  let attentionArr = [0, 0, 0, 0, 0, 0, 0, 0, 0]

  for (let i = 0; i < newestMoveWieghts.length; i++) {
    let weight = newestMoveWieghts[i]
    // console.log(weight)
    // console.log(xs)

    // I try to account for what is NOT there in addition to what IS there in a
    // qualitative way here
    if (xs[i] === 1) {
      console.log("i", i, weight, xs[i])
      attentionArr[i % 9] += weight * xs[i]
      // console.log(attentionArr)
    } 
    else {
      console.log("i", i, weight)
      attentionArr[i % 9] -= weight / 9
      // console.log(attentionArr)
    }

    // I also try to account for the fact that the local example will always
    // assume the original move is present.
    if (i === orginalLoc[0] * 3 + orginalLoc[1]) {
      console.log('here', i)
      attentionArr[i % 9] += 0.5
    }
  }

  // console.log("attention", newestMove, newestMove[0] * 3 + newestMove[1], attentionArr)
  // console.log("matmul", matmul(weights, xs))
  console.log(attentionArr)

  return attentionArr;
}

export default runLIME;