import { useState } from "react"
import "./BruteForceDescription.css"

function BruteForceDescription() {
    const [isToggled, setIsToggled] = useState(false)
    return (
        <>
            <button 
                className="description-name"
                onClick={() => setIsToggled(!isToggled)}
            >
                Technical Description of Gold Standard Explanation
                {isToggled && <div className="close-btn">X</div>}
            </button>
            {isToggled && <div className="description-bottom-div">
                <div className="bottom-description-container">
                    <p className="model-description" style={{marginTop: "35px"}}>
                    Think of this as the gold standard or ground truth for 
                    explaining the model. How this works is there is a version 
                    of the original trained model running in the browser that 
                    tracks how each piece of the input moves through each forward 
                    pass. When the model “makes it’s decision” we can then inspect 
                    which pieces of original data influenced that decision the
                     most or the least.
                    </p>
                    <p className="model-description">
                    The approach does provide the best results for these kinds 
                    of model explanations, but it is both extremely complex and 
                    inefficient. The graphic below shows roughly how the data 
                    tracking works for a single scalar in a single neuron in a 
                    single layer of a forward pass (the actual implementation 
                    is equivalent but does things in a slightly different order). 
                    For much larger models we want to understand, this approach 
                    isn't quite going to get us there. 
                    </p>
                </div>
                <img src="brute_force_copy.png" className="brute-force-graphic bottom-description-container"></img>
                {/* <div>hello</div> */}
            </div>}
        </>
    )
}

export default BruteForceDescription