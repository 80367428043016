import "./MainBoard.css"
import "../App.css"

// function numToColorStr(num) {
//   if (num < 0) {
//     num *= -2
//     if (num > 1) {
//       num = 1
//     }
//     console.log(num)
//     return `rgb(255, 255, ${(1- num) * 255})`
//   } else {
//     if (num > 1) {
//       num = 1
//     }
//     return `rgb(${(1- num) * 255}, ${(1- num) * 255}, 255)`
//   }
// }

function numToColorStr(num) {
  if (num === 0) {
    return "rgb(255, 255,255)"
  }

  if (num < 0) {
    num *= -2
    if (num > 1) {
      num = 1
    }
    // console.log(num)
    return `rgb(255, ${((1- num) * 255) - 50}, ${((1- num) * 255) - 50})`
  } else {
    if (num > 1) {
      num = 1
    }
    return `rgb(${((1- num) * 255) - 50}, 255, ${((1- num) * 255) - 50})`
  }
}

function LIMEBoard( {pieces_arr, updateBoard, LimeArr} ) {
  console.log(LimeArr)
  return (
    <div>
      <h2 className="board-header">"LIME"-inspired Explanation</h2>
      {pieces_arr.map((row, row_idx) => {
        return (
          <div key={row_idx} className="row">
            {row.map((piece_str, col_idx) => {
            //   console.log("here")
              return (
                <div
                  style={{backgroundColor: numToColorStr(LimeArr[row_idx*3 + col_idx])}}
                  key={row_idx * 10 + col_idx}
                  className="square"
                  onClick={() => {
                    updateBoard(row_idx, col_idx)
                  }}
                >{piece_str}</div>
              )
            })}
          </div>
        )
      })} 
    </div>
  )
}

export default LIMEBoard;