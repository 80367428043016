import { useState } from "react"
import "./BruteForceDescription.css"

function CardForChildren() {
    const [isToggled, setIsToggled] = useState(false)
    return (
        <>
            <button 
                className="description-name"
                onClick={() => setIsToggled(!isToggled)}
            >
                Model Card For Children
                {isToggled && <div className="close-btn">X</div>}
            </button>
            {isToggled && <div className="description-bottom-div">
                <div className="bottom-description-container">
                    <h4 className="second-heading">What the robot is made from:</h4>

                    <p className="model-description">
                    The robot was trained with a big computer brain! It's kind 
                    of like your brain but much simpler. The robot is shown the 
                    same board as you and makes a decision of where it should 
                    play. See if you can beat it!
                    </p>

                    <h4 className="second-heading">How the robot learned to play:</h4>
                    <p className="model-description">   
                    The model learned how to play tic-tac-toe by learning from 
                    lots and lots of practice games. So many games you would 
                    not even be able to count that high! If the robot won a 
                    game it learned to keep doing whatever made it win.
                    </p>
                    <h4 className="second-heading">Robot Strengths and Weaknesses:</h4>
                    <p className="model-description">
                    The robot sometimes wins and sometimes loses. 
                    It is not perfect if you make really good moves. I bet you could beat it in a game if!
                    </p>
                </div>
                {/* <img src="brute_force_copy.png" className="brute-force-graphic bottom-description-container"></img> */}
                {/* <div>hello</div> */}
            </div>}
        </>
    )
}

export default CardForChildren