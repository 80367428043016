import { useState } from "react"
import "./BruteForceDescription.css"

function CardForAdults() {
    const [isToggled, setIsToggled] = useState(false)
    return (
        <>
            <button 
                className="description-name"
                onClick={() => setIsToggled(!isToggled)}
            >
                Model Card For Adults
                {isToggled && <div className="close-btn">X</div>}
            </button>
            {isToggled && <div className="description-bottom-div">
                <div className="bottom-description-container">
                    <h4 className="second-heading">What is a model card?</h4>
                    <p className="model-description">
                      Model cards are a proposed way of giving of disclosing 
                      information about a machine learning model to those who
                      interact with it. The original paper describing model cards
                      can be found <a href="https://arxiv.org/pdf/1810.03993">here </a>
                       if you are feeling ambitious and want to read some real
                      computer science research!
                    </p>
                    <h4 className="second-heading">Model Architecture:</h4>

                    <p className="model-description">
                    The model was trained with a deep neural network. It's kind 
                    of like the brain but much simpler. The model takes in data 
                    (in this case the board) and passes it forward. By the time 
                    the data gets to the end, it is in a form we can act upon: 
                    it tells us where it would like to move.
                    </p>

                    <h4 className="second-heading">Training Data:</h4>
                    <p className="model-description">
                    The model learned how to play tic-tac-toe by learning from 
                    about a quarter of a million simulated games. The games 
                    which the O player won out of these simulated games were 
                    used to tell the model how it should behave. The model was 
                    shown these examples multiple times so it could really 
                    learn the right patterns.
                    </p>
                    <h4 className="second-heading">Model Strengths:</h4>
                    <p className="model-description">
                    The model acts reasonably when the correct move is very 
                    obvious. One example of this is shown below, with the 
                    explanations coming from the “gold standard” technique 
                    described above.
                    </p>
                    <img src="experts2.png" className="description-img"></img>
                    <h4 className="second-heading">Model Weaknesses:</h4>
                    <p className="model-description">
                    The model fails most when the correct answer is not entirely 
                    obvious, or when coming up with the right move would take 
                    some extra reasoning. Below is one such example (again with 
                    the gold standard explanation technique).
                    </p>
                    <img src="experts3.png" className="description-img"></img>
                    <h4 className="second-heading">Intended Use:</h4>
                    <p className="model-description">
                    This model should be used to teach people how to play 
                    tic-tac-toe or just to have fun with. The model is not 
                    guaranteed to be right, as we saw above.
                    </p>
                </div>
                {/* <img src="brute_force_copy.png" className="brute-force-graphic bottom-description-container"></img> */}
                {/* <div>hello</div> */}
            </div>}
        </>
    )
}

export default CardForAdults