import logo from './logo.svg';
import './App.css';
import MainBoard from './components/MainBoard'
import { useState } from 'react';
import Header from './components/Header';
import BruteForceDescription from './components/BruteForceDescription';
import runModel from './RunModel';
import runInterpretableModel from './RunInterpretableModel';
import BruteForceBoard from './components/BruteForceBoard';
import runLIME from './RunLIME';
import LIMEBoard from './components/LIMEBoard';
import Greeting from './components/Greeting';
import LIMEDescription from './components/LIMEDescription';
import ConfidenceDescription from './components/ConfidenceDescription';
import Footer from './components/Footer';
import CardForExperts from './components/CardForExperts';
import CardForAdults from './components/CardForAdults';
import CardForChildren from './components/CardForChildren';
import Resources from './components/Resources';

/*
 * Returns "X" if x wins
 * Returns "O" if o wins
 * Returns "Tie" if the game is a tie
 * Returns "Incomplete" if the game is not over
 */
const getGameStatus = (board) => {
  // checks if game is tie
  const boardString = board[0].join("") + board[1].join("") + board[2].join("")
  if (boardString.length === 9) {
    return "Tie"
  }
  
  // checks if any rows or cols form 3 in a row
  for (let i = 0; i < board.length; i++) {
    const rowTotal = board[i][0] + board[i][1] + board[i][2]
    const colTotal = board[0][i] + board[1][i] + board[2][i]
    if (rowTotal === "XXX" || colTotal === "XXX") {
      return 'X'
    }
    if (rowTotal === "OOO" || colTotal === "OOO") {
      return 'O'
    }
  }

  // checks diagonals
  const forwardDiagonalTotal = board[0][0] + board[1][1] + board[2][2]
  const backwardDiagonalTotal = board[2][0] + board[1][1] + board[0][2]

  if (forwardDiagonalTotal === "XXX" || backwardDiagonalTotal === "XXX") {
    return "X"
  }
  if (forwardDiagonalTotal === "OOO" || backwardDiagonalTotal === "OOO") {
    return "O"
  }

  return "Incomplete"
}

function App() {
  const [board, setBoard] = useState([["", "", ""], ["", "", ""], ["", "", ""]]);
//   const [currentPlayer, setCurrentPlayer] = useState("X");
  const [gameSatus, setGameSatus] = useState("Incomplete")
  const [bruteForceColors, setBruteForceColors] = useState({'a': 0, 'b': 0, 'c': 0, 'd': 0, 'e': 0, 'f': 0, 'g': 0, 'h': 0, 'i': 0})
	const [originalMove, setOriginalMove] = useState([-1, -1])
	const [LimeArr, setLimeArr] = useState([[0, 0, 0, 0, 0, 0, 0, 0, 0]])
	const [confidenceArr, setConfidenceArr] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0])

  const add_piece = (i, j) => {
		let isEmpty = true
		for (let row = 0; row < board.length; row++) {
			for (let col = 0; col < board[0].length; col++) {
				if (board[row][col] !== "") {
					isEmpty = false
				}
			}
		}
		let currentOriginalMove = originalMove
		if (isEmpty) {
			currentOriginalMove = [i, j]
			setOriginalMove([i, j])
		}

    let currentGameStatus = getGameStatus(board)
    if (["X", "O", "Tie"].includes(currentGameStatus)) {
        // setGameSatus(currentGameStatus)
        return
      }

    const new_board = board.map((row, _) => ([...row]))

    if (new_board[i][j] !== "") {
      alert("illegal move");
      return
    }

    new_board[i][j] = "X"
    setBoard(new_board)

    currentGameStatus = getGameStatus(new_board)
    if (["X", "O", "Tie"].includes(currentGameStatus)) {
      setGameSatus(currentGameStatus)
      return
    }

    const [o_move, confidenceArrNew] = runModel(new_board)
    const LimeArrNew = runLIME(currentOriginalMove, o_move, new_board)
		// console.log(LimeArrNew)
		setLimeArr(LimeArrNew)

		console.log("HERE", confidenceArrNew)
		setConfidenceArr(confidenceArrNew)

    const interpretations = runInterpretableModel(new_board)
    const interpretationsIdx = o_move[0] * 3 + o_move[1]
    setBruteForceColors(interpretations[interpretationsIdx])

    new_board[o_move[0]][o_move[1]] = "O"
    currentGameStatus = getGameStatus(new_board)
    if (["X", "O", "Tie"].includes(currentGameStatus)) {
      setGameSatus(currentGameStatus)
    }
  }

  const resetBoard = () => {
    setBoard([["", "", ""], ["", "", ""], ["", "", ""]])
    setBruteForceColors({'a': 0, 'b': 0, 'c': 0, 'd': 0, 'e': 0, 'f': 0, 'g': 0, 'h': 0, 'i': 0})
		setLimeArr([0, 0, 0, 0, 0, 0, 0, 0, 0])
		setConfidenceArr([0, 0, 0, 0, 0, 0, 0, 0, 0])
    setGameSatus("Incomplete")
  }


  return (
    <>
      <Header />
			<Greeting />
      <div className='upper-container'>
        <div className='board-container'>
          <div className='board-sub-container'>
            <BruteForceBoard 
              pieces_arr={board} 
              updateBoard={add_piece}
              bruteForceOutput={bruteForceColors}
            />
          </div>
          <div className='board-sub-container'>
            <LIMEBoard 
              pieces_arr={board} 
              updateBoard={add_piece}
							LimeArr={LimeArr}
            //   currentPlayer={currentPlayer}
            />
          </div>
          <div className='board-sub-container'>
            <MainBoard 
              pieces_arr={board} 
              updateBoard={add_piece}
              confidenceArr={confidenceArr}
            />
          </div>
        </div>
      </div>

      {/* Displays the winner */}
      {(gameSatus === "X" || gameSatus === "O") && 
        <div className='game-status'>
           <p className='end-txt'>{gameSatus} wins the game!</p>
        </div>
      }

      {/* Displays message in the case of a tie */}
      {(gameSatus === "Tie") && 
        <div className='game-status'>
           <p className='end-txt'>{gameSatus}! How about a rematch?!</p>
        </div>
      }
      <div className='restart-btn-container'>
        <button className='restart-btn' onClick={resetBoard}>Restart Game</button>
      </div>

			<ConfidenceDescription />
      <BruteForceDescription />
      <LIMEDescription />
			<CardForExperts />
			<CardForAdults />
			<CardForChildren />
			<Resources />
			<Footer></Footer>
    </>
  );
}

export default App;
