const model = {
    "linear_relu_stack.0.weight": [
        [
            0.11563220620155334,
            -0.3689310848712921,
            -0.30672815442085266,
            -0.2772993743419647,
            0.3295266628265381,
            0.08070176839828491,
            -0.35505959391593933,
            0.23365238308906555,
            0.0285753533244133
        ],
        [
            -0.18581029772758484,
            0.031869251281023026,
            0.0025335673708468676,
            -0.2523573040962219,
            -0.10815474390983582,
            0.25384530425071716,
            0.17056575417518616,
            0.3366811275482178,
            -0.14531978964805603
        ],
        [
            0.1505054384469986,
            -0.2984361946582794,
            -0.2526611387729645,
            -0.17793378233909607,
            -0.04125106334686279,
            0.32203635573387146,
            0.0799066349864006,
            -0.22162553668022156,
            -0.11148916184902191
        ],
        [
            0.28283628821372986,
            -0.31742751598358154,
            0.22707165777683258,
            0.2187904566526413,
            -0.33203643560409546,
            -0.05373842641711235,
            -0.2518249750137329,
            -0.24345774948596954,
            0.24076229333877563
        ],
        [
            0.2703024744987488,
            -0.2939249575138092,
            0.006972053088247776,
            0.15730436146259308,
            -0.08353716135025024,
            0.22254005074501038,
            0.07495389133691788,
            0.1691373586654663,
            0.0879378393292427
        ],
        [
            0.00937421154230833,
            0.018253013491630554,
            0.11124873161315918,
            -0.052955906838178635,
            -0.2661024034023285,
            0.09457989037036896,
            -0.32405760884284973,
            -0.10678235441446304,
            0.06823720037937164
        ],
        [
            -0.12716345489025116,
            0.3116128146648407,
            -0.18732482194900513,
            -0.09531859308481216,
            0.1233135536313057,
            -0.19114473462104797,
            0.2781203091144562,
            0.08217889070510864,
            0.2903399169445038
        ],
        [
            0.08338342607021332,
            0.1041502133011818,
            0.3492588400840759,
            0.016210470348596573,
            -0.10515232384204865,
            0.3254138231277466,
            -0.3792524039745331,
            -0.19443729519844055,
            0.27499622106552124
        ],
        [
            -0.05723224952816963,
            0.1722167730331421,
            0.13413959741592407,
            0.048493850976228714,
            -0.14821474254131317,
            0.23919516801834106,
            -0.07257030159235,
            -0.23963028192520142,
            0.0901741310954094
        ],
        [
            -0.2894880175590515,
            -0.2292715162038803,
            -0.27502188086509705,
            -0.02887713350355625,
            0.23238427937030792,
            -0.1839127242565155,
            -0.07712914049625397,
            -0.3245081603527069,
            -0.22339774668216705
        ],
        [
            0.24438366293907166,
            0.15370377898216248,
            -0.12104751169681549,
            -0.120572030544281,
            0.21732313930988312,
            -0.362099826335907,
            -0.2271130532026291,
            0.15292078256607056,
            0.022313831374049187
        ],
        [
            -0.2414713352918625,
            -0.17487673461437225,
            -0.1768561601638794,
            0.34425729513168335,
            0.06259595602750778,
            -0.24108976125717163,
            0.2315363883972168,
            -0.02038859948515892,
            0.3578895032405853
        ],
        [
            0.05856037139892578,
            -0.35236504673957825,
            0.3500206470489502,
            -0.076479472219944,
            0.1868380606174469,
            0.1813623309135437,
            -0.27371159195899963,
            0.18377293646335602,
            0.08395525068044662
        ],
        [
            -0.12244845926761627,
            -0.16320566833019257,
            0.2798506021499634,
            -0.1180703416466713,
            -0.3382777273654938,
            -0.31176725029945374,
            -0.1212187260389328,
            0.3361392617225647,
            0.010389686562120914
        ],
        [
            0.03247017785906792,
            -0.05334392935037613,
            0.023526614531874657,
            -0.2693541347980499,
            0.032419946044683456,
            0.06113680824637413,
            -0.31550535559654236,
            -0.1589258462190628,
            -0.3355342745780945
        ],
        [
            -0.30529895424842834,
            -0.08126231282949448,
            -0.21427428722381592,
            -0.27500081062316895,
            0.1969953030347824,
            -0.1634841114282608,
            -0.2239467203617096,
            0.327513724565506,
            -0.24349890649318695
        ],
        [
            0.2132958024740219,
            -0.2157178670167923,
            -0.03427886962890625,
            0.30398884415626526,
            0.036344483494758606,
            0.002087010070681572,
            0.3119467496871948,
            0.33707475662231445,
            -0.14350998401641846
        ],
        [
            -0.2689039707183838,
            0.33825597167015076,
            -0.12960493564605713,
            -0.0596468523144722,
            0.041314274072647095,
            -0.031726330518722534,
            0.2013069987297058,
            0.134403795003891,
            -0.26068276166915894
        ],
        [
            -0.3520759642124176,
            0.11852575838565826,
            -0.2899877429008484,
            -0.18323521316051483,
            0.31923577189445496,
            0.05017849802970886,
            -0.25775960087776184,
            -0.23167897760868073,
            -0.06472136080265045
        ],
        [
            0.24582773447036743,
            0.19586041569709778,
            0.3047216832637787,
            -0.1801871657371521,
            0.16073645651340485,
            0.23424383997917175,
            0.08860976994037628,
            -0.026785587891936302,
            -0.09447956830263138
        ],
        [
            -0.278513103723526,
            -0.004573265556246042,
            0.01564604416489601,
            -0.27225375175476074,
            -0.3157605528831482,
            -0.30766013264656067,
            0.2540804445743561,
            -0.3007868230342865,
            -0.30354008078575134
        ],
        [
            0.35551509261131287,
            -0.07495299726724625,
            0.05594908446073532,
            -0.25719478726387024,
            -0.22172842919826508,
            0.25086066126823425,
            0.26922333240509033,
            -0.15663248300552368,
            -0.21950200200080872
        ],
        [
            -0.21269147098064423,
            -0.12114731967449188,
            0.1291850358247757,
            0.3805167078971863,
            -0.026064500212669373,
            0.24404089152812958,
            -0.3662676513195038,
            -0.06648010015487671,
            0.14050395786762238
        ],
        [
            0.1571720391511917,
            -0.14866137504577637,
            0.19681823253631592,
            -0.1879676729440689,
            0.04549302160739899,
            -0.32298722863197327,
            0.3048887848854065,
            0.29288217425346375,
            0.3130134046077728
        ],
        [
            -0.1615504026412964,
            0.10056402534246445,
            -0.05005820095539093,
            -0.15646782517433167,
            0.017893316224217415,
            0.33271360397338867,
            -0.17610862851142883,
            0.15633784234523773,
            -0.32367709279060364
        ],
        [
            -0.008430675603449345,
            0.07483592629432678,
            -0.16679930686950684,
            -0.17938342690467834,
            0.0653478130698204,
            0.3540186583995819,
            -0.22745361924171448,
            -0.29242441058158875,
            -0.3365769386291504
        ],
        [
            -0.32661062479019165,
            -0.20106148719787598,
            -0.2576884627342224,
            -0.12903992831707,
            0.06309901922941208,
            -0.0915696769952774,
            0.35477182269096375,
            -0.12761561572551727,
            0.30495592951774597
        ],
        [
            -0.06861487030982971,
            0.08166027814149857,
            -0.07996639609336853,
            0.19748055934906006,
            -0.2928560972213745,
            -0.05140645429491997,
            -0.045079875737428665,
            -0.009074716828763485,
            0.25166386365890503
        ],
        [
            -0.25326550006866455,
            -0.2911819815635681,
            0.1449822187423706,
            -0.3451284170150757,
            0.05192563310265541,
            -0.016717558726668358,
            0.2698424756526947,
            0.06018802151083946,
            0.24748331308364868
        ],
        [
            0.08419328182935715,
            0.19744926691055298,
            0.31622400879859924,
            -0.20310679078102112,
            0.07671445608139038,
            0.0806172639131546,
            0.042292263358831406,
            -0.2454664558172226,
            -0.23603184521198273
        ],
        [
            -0.32095518708229065,
            0.15138354897499084,
            -0.04874095693230629,
            -0.25524187088012695,
            -0.05707168951630592,
            0.2626003623008728,
            -0.2585148811340332,
            -0.2787228524684906,
            -0.016417987644672394
        ],
        [
            0.327909916639328,
            0.21848514676094055,
            -0.2786831855773926,
            0.224339097738266,
            -0.2992227077484131,
            -0.1469866782426834,
            -0.008742284029722214,
            -0.24613067507743835,
            -0.015471731312572956
        ],
        [
            -0.21813231706619263,
            -0.061474140733480453,
            0.09104757010936737,
            0.32299739122390747,
            0.31221887469291687,
            -0.15941539406776428,
            -0.23906518518924713,
            0.09125214070081711,
            -0.004447379615157843
        ],
        [
            0.09366926550865173,
            0.3559872806072235,
            -0.16157390177249908,
            -0.07574272900819778,
            0.2121623158454895,
            0.004880941938608885,
            -0.0006177262985147536,
            -0.32024452090263367,
            -0.31323477625846863
        ],
        [
            -0.007660029921680689,
            -0.3315444886684418,
            0.23851031064987183,
            0.034847140312194824,
            0.22604408860206604,
            -0.23269964754581451,
            0.19245386123657227,
            -0.15261732041835785,
            0.1322595328092575
        ],
        [
            0.3089035749435425,
            -0.030623897910118103,
            -0.16203193366527557,
            -0.09818097949028015,
            -0.2206340730190277,
            0.14198952913284302,
            -0.019353225827217102,
            -0.0396745502948761,
            -0.2768743634223938
        ],
        [
            -0.1443956196308136,
            -0.09152273088693619,
            -0.2379186749458313,
            -0.2022647261619568,
            0.10316669195890427,
            0.2241896688938141,
            0.343198299407959,
            0.16923023760318756,
            -0.2144709676504135
        ],
        [
            -0.08732244372367859,
            0.13536827266216278,
            0.1714065819978714,
            -0.023088742047548294,
            -0.09367753565311432,
            -0.2241460531949997,
            0.07218062877655029,
            -0.16423754394054413,
            -0.35129275918006897
        ],
        [
            -0.1949135810136795,
            0.2797112762928009,
            -0.2447367012500763,
            0.06537307798862457,
            0.05145449563860893,
            -0.2692280113697052,
            -0.19733010232448578,
            0.13404732942581177,
            -0.09226639568805695
        ],
        [
            0.3099078834056854,
            0.18732883036136627,
            -0.13702310621738434,
            0.021194344386458397,
            0.1251196265220642,
            0.152518630027771,
            -0.04614461585879326,
            -0.013354374095797539,
            -0.16533051431179047
        ],
        [
            0.1426207572221756,
            0.2150108963251114,
            -0.36305177211761475,
            0.1750832349061966,
            0.058166954666376114,
            -0.21951106190681458,
            -0.07045982778072357,
            0.2732001543045044,
            -0.029681673273444176
        ],
        [
            0.2354964017868042,
            0.1880296915769577,
            -0.38082361221313477,
            -0.05760584771633148,
            -0.135000079870224,
            -0.04596361145377159,
            -0.17567628622055054,
            0.00942209642380476,
            -0.19026242196559906
        ],
        [
            0.1330968737602234,
            0.028709139674901962,
            0.3354666829109192,
            0.27601000666618347,
            0.03834415599703789,
            -0.1492195874452591,
            -0.1515013426542282,
            0.29809707403182983,
            -0.3057399392127991
        ],
        [
            -0.24016131460666656,
            0.35078108310699463,
            0.029900580644607544,
            -0.2703455984592438,
            0.07639466971158981,
            0.09040901809930801,
            0.05921946093440056,
            -0.25631797313690186,
            -0.21164658665657043
        ],
        [
            -0.24965721368789673,
            -0.23643478751182556,
            -0.14825227856636047,
            -0.2600530683994293,
            0.22568659484386444,
            0.25819501280784607,
            -0.22200778126716614,
            0.23807767033576965,
            -0.0045676580630242825
        ],
        [
            -0.09310217946767807,
            0.33376091718673706,
            0.33523988723754883,
            0.23121939599514008,
            0.060158662497997284,
            -0.10075327008962631,
            -0.33691054582595825,
            -0.13943800330162048,
            0.036453887820243835
        ],
        [
            0.23123127222061157,
            0.19829997420310974,
            0.3374454081058502,
            -0.04098773002624512,
            0.1316613107919693,
            0.11887054890394211,
            -0.09043211489915848,
            -0.01667630672454834,
            -0.08110303431749344
        ],
        [
            0.18712599575519562,
            0.22037510573863983,
            -0.36184024810791016,
            -0.25213804841041565,
            0.12139769643545151,
            0.15327778458595276,
            0.087528295814991,
            -0.25023528933525085,
            0.01606360264122486
        ],
        [
            -0.06882843375205994,
            0.11274844408035278,
            0.15729716420173645,
            0.03249187394976616,
            -0.2526842951774597,
            0.17249450087547302,
            0.3210534155368805,
            0.018512744456529617,
            0.2687181234359741
        ],
        [
            -0.23145738244056702,
            0.0534641407430172,
            0.1854998916387558,
            0.35813552141189575,
            -0.07947180420160294,
            0.11048359423875809,
            0.16355085372924805,
            -0.2902261018753052,
            0.04675058275461197
        ],
        [
            -0.14438748359680176,
            -0.1681518256664276,
            -0.09912029653787613,
            0.3249337077140808,
            0.07665549963712692,
            0.1541074961423874,
            0.1392456442117691,
            0.06251668184995651,
            0.10918145626783371
        ],
        [
            -0.2962639629840851,
            0.015359028242528439,
            0.26024654507637024,
            -0.16716331243515015,
            0.23217828571796417,
            0.3192763924598694,
            0.004043326713144779,
            -0.22930565476417542,
            0.003514290554448962
        ],
        [
            -0.17389611899852753,
            -0.09711772948503494,
            0.33962902426719666,
            -0.2793995141983032,
            0.11281640082597733,
            -0.14901123940944672,
            0.28589507937431335,
            -0.1374741494655609,
            -0.194756880402565
        ],
        [
            -0.15326954424381256,
            -0.205820694565773,
            0.18299852311611176,
            -0.12499996274709702,
            0.1011568009853363,
            -0.1781710982322693,
            0.3530910909175873,
            0.0582064613699913,
            -0.24917970597743988
        ],
        [
            -0.20989249646663666,
            0.11300694197416306,
            -0.29241669178009033,
            0.04430745169520378,
            -0.28024807572364807,
            0.08159640431404114,
            -0.07418903708457947,
            -0.24954700469970703,
            0.090330109000206
        ],
        [
            0.1543445736169815,
            -0.18667306005954742,
            0.1188352033495903,
            0.10347945243120193,
            -0.358427494764328,
            -0.10824450850486755,
            0.1301203966140747,
            0.06574589759111404,
            0.09457191079854965
        ],
        [
            -0.32591864466667175,
            -0.21170756220817566,
            0.046059638261795044,
            -0.2177603542804718,
            -0.2968483865261078,
            0.08854050189256668,
            -0.31131407618522644,
            -0.02981347031891346,
            0.1713063269853592
        ],
        [
            -0.10868459939956665,
            -0.18246865272521973,
            0.23605427145957947,
            0.10310143977403641,
            0.3234656751155853,
            0.24367405474185944,
            0.030346674844622612,
            -0.20597800612449646,
            0.2648792564868927
        ],
        [
            0.06888123601675034,
            -0.0193504448980093,
            0.25217682123184204,
            -0.10581149160861969,
            -0.18586626648902893,
            -0.05122660472989082,
            -0.04511341080069542,
            -0.03427896648645401,
            -0.24174658954143524
        ],
        [
            -0.32925793528556824,
            0.26608213782310486,
            0.1095624640583992,
            0.1644052267074585,
            0.265339732170105,
            -0.03621714189648628,
            -0.14016792178153992,
            0.022686632350087166,
            0.00903676263988018
        ],
        [
            0.014629821293056011,
            0.26140379905700684,
            0.24844849109649658,
            0.23831114172935486,
            -0.20960305631160736,
            -0.28756430745124817,
            0.27970021963119507,
            -0.1771627813577652,
            -0.2418685257434845
        ],
        [
            0.07483848184347153,
            -0.18967494368553162,
            -0.15394285321235657,
            -0.20486293733119965,
            -0.33746400475502014,
            0.20521417260169983,
            -0.2593684792518616,
            0.242386132478714,
            -0.2999943196773529
        ],
        [
            0.11967429518699646,
            0.19954316318035126,
            -0.00358273321762681,
            -0.2133294939994812,
            0.28503066301345825,
            -0.018613256514072418,
            0.23298774659633636,
            0.16088782250881195,
            -0.3526303172111511
        ],
        [
            -0.32308751344680786,
            0.3001190721988678,
            0.16453415155410767,
            -0.09164854139089584,
            -0.11515769362449646,
            0.20143575966358185,
            -0.24690982699394226,
            0.33394020795822144,
            -0.30213266611099243
        ],
        [
            0.028168685734272003,
            0.31113994121551514,
            0.322812020778656,
            -0.05019902065396309,
            -0.2736015021800995,
            0.07439164817333221,
            -0.1574244201183319,
            0.006601178552955389,
            -0.2677581012248993
        ],
        [
            -0.2825066149234772,
            0.305490642786026,
            0.35160723328590393,
            -0.11160792410373688,
            -0.13926386833190918,
            0.31261736154556274,
            -0.16195113956928253,
            0.052361130714416504,
            -0.19522564113140106
        ],
        [
            -0.0958358645439148,
            -0.1890990287065506,
            0.06388602405786514,
            -0.22945527732372284,
            0.2948576807975769,
            -0.36486586928367615,
            -0.04039594903588295,
            0.3046262562274933,
            -0.20882122218608856
        ],
        [
            -0.1030101627111435,
            -0.011159056797623634,
            0.34347638487815857,
            -0.2076905518770218,
            0.14058087766170502,
            0.2881271541118622,
            -0.10052931308746338,
            -0.11483178287744522,
            0.29259294271469116
        ],
        [
            -0.21186889708042145,
            0.35314086079597473,
            -0.05071988329291344,
            0.2959252595901489,
            -0.12387469410896301,
            -0.15720407664775848,
            -0.10717105865478516,
            -0.2998144328594208,
            0.04091363772749901
        ],
        [
            -0.09149643778800964,
            0.017396099865436554,
            -0.32961931824684143,
            -0.1794017255306244,
            0.18038591742515564,
            0.13838616013526917,
            -0.2894456088542938,
            0.07828155159950256,
            0.25714489817619324
        ],
        [
            0.06909214705228806,
            -0.16485989093780518,
            -0.1684875190258026,
            0.33957335352897644,
            0.3139112889766693,
            0.06975305825471878,
            0.23951590061187744,
            -0.00016297151159960777,
            0.3447733521461487
        ],
        [
            0.09320984035730362,
            -0.010027853772044182,
            -0.2645435333251953,
            0.08428345620632172,
            0.21090422570705414,
            -0.09972529113292694,
            0.14901858568191528,
            0.009899175725877285,
            -0.39210787415504456
        ],
        [
            0.20336517691612244,
            -0.20869094133377075,
            -0.15841905772686005,
            -0.157223641872406,
            -0.0076039498671889305,
            0.10894423723220825,
            -0.17986997961997986,
            -0.21516373753547668,
            -0.3545878231525421
        ],
        [
            -0.11254410445690155,
            -0.11815807223320007,
            0.22913438081741333,
            0.2963011562824249,
            0.1357591152191162,
            -0.18613101541996002,
            0.06900180876255035,
            0.2881861627101898,
            0.17563211917877197
        ],
        [
            0.1612032800912857,
            0.18106098473072052,
            0.018597183749079704,
            0.15944789350032806,
            0.06053712218999863,
            -0.17772912979125977,
            -0.02766105905175209,
            0.1739000529050827,
            -0.35587775707244873
        ],
        [
            0.28016793727874756,
            0.12629258632659912,
            -0.11154402792453766,
            0.11538048833608627,
            -0.0022220462560653687,
            0.23773077130317688,
            0.07600454241037369,
            -0.08845964819192886,
            0.041309669613838196
        ],
        [
            -0.21630312502384186,
            -0.3045012652873993,
            -0.3353194296360016,
            -0.18996752798557281,
            0.20081983506679535,
            -0.051067352294921875,
            -0.04475970193743706,
            -0.07167244702577591,
            0.06338850408792496
        ],
        [
            -0.1540566384792328,
            0.0041405679658055305,
            -0.09984123706817627,
            -0.0363265760242939,
            -0.0287384781986475,
            -0.26213523745536804,
            0.3105270266532898,
            0.3473520576953888,
            0.08722081035375595
        ],
        [
            0.32522979378700256,
            -0.2517985701560974,
            0.21141019463539124,
            -0.17987093329429626,
            -0.03163740411400795,
            -0.007100643124431372,
            -0.1839563250541687,
            0.12227708101272583,
            -0.2291996031999588
        ],
        [
            -0.18977631628513336,
            -0.21887105703353882,
            0.08636900037527084,
            0.15924707055091858,
            0.11551085859537125,
            -0.11074266582727432,
            0.11560800671577454,
            -0.16156397759914398,
            0.33181726932525635
        ],
        [
            0.24777096509933472,
            -0.351826012134552,
            -0.25587984919548035,
            -0.041262187063694,
            0.015820221975445747,
            0.127492755651474,
            -0.3379517197608948,
            0.3124270737171173,
            -0.14166857302188873
        ],
        [
            -0.16455136239528656,
            0.0686943531036377,
            0.19287723302841187,
            -0.31865060329437256,
            0.19094961881637573,
            0.1646239459514618,
            -0.031174687668681145,
            0.013902191072702408,
            0.05270038917660713
        ],
        [
            0.3504875600337982,
            0.1576983630657196,
            0.0066282968036830425,
            0.1010148897767067,
            0.1583421230316162,
            0.1208808496594429,
            0.33399638533592224,
            0.1266711950302124,
            -0.06917092204093933
        ],
        [
            0.19209855794906616,
            -0.1271905153989792,
            -0.05172095447778702,
            0.16846339404582977,
            0.2824815809726715,
            -0.2056070864200592,
            -0.1311403214931488,
            -0.03015015460550785,
            0.17213594913482666
        ],
        [
            0.3186827600002289,
            0.06140746548771858,
            0.003099978668615222,
            -0.2889479398727417,
            -0.16254757344722748,
            -0.28051477670669556,
            0.2990526854991913,
            0.3146975636482239,
            0.030805816873908043
        ],
        [
            -0.3034599721431732,
            -0.03984848037362099,
            0.14834243059158325,
            -0.019564488902688026,
            -0.15822280943393707,
            -0.01865397021174431,
            -0.012248793616890907,
            -0.23489604890346527,
            -0.24298208951950073
        ],
        [
            -0.19343158602714539,
            -0.17184342443943024,
            0.32828789949417114,
            -0.13187876343727112,
            -0.21939672529697418,
            0.07718883454799652,
            0.3430587947368622,
            -0.1542060226202011,
            -0.016147691756486893
        ],
        [
            -0.33630988001823425,
            0.19194395840168,
            -0.03721337020397186,
            0.29527008533477783,
            -0.1445755511522293,
            0.052353184670209885,
            0.3430299460887909,
            0.2188338190317154,
            0.1847657710313797
        ],
        [
            0.32501062750816345,
            0.31454017758369446,
            0.24136660993099213,
            0.19377374649047852,
            0.046277064830064774,
            -0.27795758843421936,
            0.22173702716827393,
            -0.23730944097042084,
            -0.10972478985786438
        ],
        [
            0.3174077272415161,
            0.02797791175544262,
            0.3483308255672455,
            -0.3326973617076874,
            -0.23166148364543915,
            -0.05958804860711098,
            -0.201974555850029,
            -0.2278648167848587,
            -0.23161649703979492
        ],
        [
            -0.26753145456314087,
            0.26296466588974,
            0.08514329791069031,
            0.16563306748867035,
            0.04102334380149841,
            0.2718048095703125,
            -0.17182129621505737,
            -0.03522314503788948,
            0.3221052289009094
        ],
        [
            -0.05559142678976059,
            -0.004942956380546093,
            0.28834468126296997,
            -0.26258188486099243,
            -0.0390135757625103,
            -0.1742933690547943,
            -0.3018168807029724,
            0.3265109360218048,
            -0.20076467096805573
        ],
        [
            -0.05058905482292175,
            0.025140121579170227,
            0.31326550245285034,
            -0.1723104864358902,
            0.13389354944229126,
            -0.1895848661661148,
            0.06712766736745834,
            -0.23559246957302094,
            -0.07084498554468155
        ],
        [
            0.2624903619289398,
            -0.30609220266342163,
            -0.3420841693878174,
            0.1520298570394516,
            -0.3115183413028717,
            -0.1471424549818039,
            -0.28331756591796875,
            0.019116263836622238,
            0.0424463115632534
        ],
        [
            0.09147509932518005,
            0.11144723743200302,
            -0.04194651544094086,
            0.2880038022994995,
            -0.20406854152679443,
            0.3505598306655884,
            0.2737792730331421,
            -0.011450512334704399,
            -0.028144434094429016
        ],
        [
            -0.056487515568733215,
            -0.3834092915058136,
            0.004201386123895645,
            0.1329052746295929,
            0.09989207237958908,
            -0.2032846361398697,
            0.21836242079734802,
            -0.22978058457374573,
            -0.20800812542438507
        ],
        [
            -0.001944329938851297,
            -0.14019043743610382,
            0.051828425377607346,
            0.017496028915047646,
            -0.28217628598213196,
            -0.11282369494438171,
            0.3028002977371216,
            0.15368667244911194,
            0.1684161126613617
        ],
        [
            0.16391189396381378,
            -0.08238764852285385,
            0.12744316458702087,
            0.34471192955970764,
            -0.08683586120605469,
            0.09948022663593292,
            0.2736670672893524,
            0.18101075291633606,
            0.18980707228183746
        ],
        [
            -0.20451095700263977,
            -0.1159699484705925,
            -0.050370827317237854,
            0.09713203459978104,
            0.065056711435318,
            0.07070556282997131,
            0.07980083674192429,
            -0.02104797214269638,
            0.142300546169281
        ],
        [
            0.012686020694673061,
            0.12062513828277588,
            -0.1599402278661728,
            0.1775902360677719,
            -0.011584791354835033,
            -0.14199428260326385,
            0.081424281001091,
            -0.1374267190694809,
            0.26945433020591736
        ],
        [
            -0.27961987257003784,
            -0.024604327976703644,
            -0.19230268895626068,
            0.11172789335250854,
            -0.22084002196788788,
            -0.2294885218143463,
            -0.25530922412872314,
            0.2824154794216156,
            0.056779321283102036
        ],
        [
            -0.08437954634428024,
            -0.08372806757688522,
            0.2330802083015442,
            -0.04677227512001991,
            0.15217548608779907,
            0.16493980586528778,
            -0.3065811097621918,
            -0.2317390888929367,
            0.11337645351886749
        ],
        [
            -0.13562555611133575,
            0.022615373134613037,
            -0.22402650117874146,
            0.33835217356681824,
            -0.13934697210788727,
            -0.03841136395931244,
            0.11267167329788208,
            0.09766006469726562,
            -0.04866836592555046
        ],
        [
            -0.3220292329788208,
            -0.05614073947072029,
            0.32576414942741394,
            -0.21428626775741577,
            0.04684256762266159,
            -0.31730732321739197,
            0.054760679602622986,
            -0.03971880301833153,
            -0.2532612383365631
        ],
        [
            -0.04532752186059952,
            -0.09102623909711838,
            -0.045651208609342575,
            0.30535826086997986,
            -0.08255834132432938,
            -0.05291461572051048,
            0.1441575437784195,
            0.28668051958084106,
            -0.3356015980243683
        ],
        [
            0.16347719728946686,
            0.3300688564777374,
            -0.009120136499404907,
            -0.24254421889781952,
            -0.33067166805267334,
            -0.27380189299583435,
            0.07633445411920547,
            0.0231804046779871,
            -0.28186771273612976
        ],
        [
            -0.20459109544754028,
            0.14905989170074463,
            -0.30652928352355957,
            0.31180304288864136,
            -0.27860116958618164,
            -0.0592118538916111,
            -0.247682586312294,
            -0.17649565637111664,
            -0.29865509271621704
        ],
        [
            -0.2656438648700714,
            -0.0584220327436924,
            0.37396329641342163,
            0.24447768926620483,
            -0.0006299305823631585,
            0.10298483073711395,
            0.09623388946056366,
            -0.20342190563678741,
            -0.11528213322162628
        ],
        [
            0.0023929057642817497,
            0.014947190880775452,
            -0.08818888664245605,
            0.24913224577903748,
            -0.07466080784797668,
            -0.27357998490333557,
            0.047093626111745834,
            0.16339130699634552,
            -0.17582185566425323
        ],
        [
            -0.07937938719987869,
            0.1850632131099701,
            0.18028844892978668,
            0.12523458898067474,
            -0.1582118421792984,
            -0.10500060021877289,
            0.009323611855506897,
            0.10544712096452713,
            0.04662749543786049
        ],
        [
            0.016282225027680397,
            -0.25558286905288696,
            -0.3432265520095825,
            -0.027138128876686096,
            -0.16180115938186646,
            0.023190123960375786,
            -0.09516878426074982,
            0.2837081849575043,
            0.034273866564035416
        ],
        [
            -0.2188078612089157,
            -0.06908713281154633,
            -0.2171950340270996,
            0.2248459756374359,
            0.080128513276577,
            -0.2184479534626007,
            0.1653091311454773,
            -0.17437854409217834,
            -0.02134581468999386
        ],
        [
            -0.019591813907027245,
            -0.27759912610054016,
            -0.012204223312437534,
            0.10219188034534454,
            -0.3079834282398224,
            -0.10637227445840836,
            -0.11577730625867844,
            -0.27332374453544617,
            0.07248900830745697
        ],
        [
            0.09349105507135391,
            -0.06222962588071823,
            0.23664653301239014,
            0.2734394371509552,
            0.18019819259643555,
            0.18878203630447388,
            -0.012022403068840504,
            -0.2926456928253174,
            0.05582140386104584
        ],
        [
            0.3532947599887848,
            -0.32082444429397583,
            -0.3523508906364441,
            -0.17396841943264008,
            0.05421854183077812,
            -0.12260115891695023,
            -0.3539106845855713,
            -0.13074824213981628,
            -0.06994729489088058
        ],
        [
            0.16387887299060822,
            -0.05138115584850311,
            0.3149079978466034,
            0.18716076016426086,
            0.1879045069217682,
            -0.008558377623558044,
            0.18841567635536194,
            0.024669742211699486,
            0.051610976457595825
        ],
        [
            -0.19370602071285248,
            -0.29142752289772034,
            0.34214264154434204,
            -0.20431196689605713,
            -0.1540571004152298,
            0.113038070499897,
            -0.223020538687706,
            0.1394074708223343,
            0.013316776603460312
        ],
        [
            0.2579195499420166,
            0.11338308453559875,
            0.25288984179496765,
            0.006080657709389925,
            0.11189476400613785,
            -0.08102934807538986,
            -0.08720261603593826,
            0.11888314038515091,
            0.05938589200377464
        ],
        [
            -0.3627137541770935,
            0.30685707926750183,
            -0.04045219346880913,
            -0.2876763641834259,
            -0.036802988499403,
            -0.0753467008471489,
            -0.29749879240989685,
            -0.3043614327907562,
            0.29314249753952026
        ],
        [
            -0.3189520835876465,
            -0.07361071556806564,
            -0.27697277069091797,
            -0.29834145307540894,
            0.2610788643360138,
            0.0361272469162941,
            -0.1789480745792389,
            -0.3072907328605652,
            -0.09792419523000717
        ],
        [
            -0.19316524267196655,
            -0.3069680631160736,
            0.08341377228498459,
            -0.1758946031332016,
            0.22618608176708221,
            0.014795830473303795,
            -0.18633204698562622,
            -0.29395782947540283,
            -0.11857998371124268
        ],
        [
            0.11434503644704819,
            -0.04293891414999962,
            0.10145273059606552,
            0.17327266931533813,
            0.09447870403528214,
            0.2609599232673645,
            -0.11631861329078674,
            0.1564381867647171,
            0.21245217323303223
        ],
        [
            -0.009507314302027225,
            -0.23110634088516235,
            -0.296166330575943,
            -0.24852414429187775,
            -0.09071717411279678,
            -0.32349932193756104,
            -0.29572367668151855,
            -0.25756505131721497,
            0.23339466750621796
        ],
        [
            0.2859037518501282,
            0.16323120892047882,
            0.34311527013778687,
            0.051415327936410904,
            0.27635765075683594,
            0.305162250995636,
            -0.027296092361211777,
            0.26508787274360657,
            -0.22081585228443146
        ],
        [
            -0.06167334318161011,
            -0.08626244217157364,
            -0.20009376108646393,
            -0.0727493017911911,
            -0.351706862449646,
            -0.1031506136059761,
            0.25629451870918274,
            0.15168073773384094,
            -0.3030000925064087
        ],
        [
            -0.018332241103053093,
            -0.13778990507125854,
            0.2139538824558258,
            0.07501864433288574,
            -0.09740465134382248,
            0.22394341230392456,
            0.18785658478736877,
            -0.17946428060531616,
            -0.34402161836624146
        ],
        [
            -0.2211657166481018,
            -0.14009204506874084,
            0.09551485627889633,
            0.2860904633998871,
            -0.25258493423461914,
            0.17477519810199738,
            -0.34798020124435425,
            -0.29971835017204285,
            0.19683416187763214
        ],
        [
            0.23052680492401123,
            0.03397286683320999,
            -0.06889282912015915,
            0.20195303857326508,
            0.32651224732398987,
            0.27185142040252686,
            0.30341681838035583,
            -0.13023051619529724,
            0.31469452381134033
        ],
        [
            0.051244255155324936,
            -0.04861123487353325,
            -0.13152292370796204,
            -0.13111792504787445,
            -0.2725016176700592,
            0.285299152135849,
            0.028983503580093384,
            0.18492142856121063,
            0.0738162100315094
        ],
        [
            -0.17922243475914001,
            0.14863672852516174,
            0.12084130197763443,
            0.31474730372428894,
            -0.21534381806850433,
            -0.10244060307741165,
            -0.0422893688082695,
            -0.3597101867198944,
            0.1163119524717331
        ],
        [
            0.2525653541088104,
            -0.3107871115207672,
            0.02658909372985363,
            -0.04243079945445061,
            0.33594703674316406,
            -0.24112890660762787,
            -0.26072221994400024,
            0.033258985728025436,
            0.3035283386707306
        ],
        [
            0.13189934194087982,
            0.1411667764186859,
            0.0580349862575531,
            0.2870769798755646,
            0.3330261707305908,
            -0.04478659853339195,
            -0.21034950017929077,
            0.1515529304742813,
            0.0796259269118309
        ],
        [
            -0.028045473620295525,
            -0.29781994223594666,
            -0.11961134523153305,
            -0.10593391954898834,
            0.23378735780715942,
            0.19083324074745178,
            -0.2542097866535187,
            -0.034533943980932236,
            0.21061737835407257
        ],
        [
            -0.2767438590526581,
            0.13103148341178894,
            0.045671798288822174,
            0.20547382533550262,
            -0.33726993203163147,
            -0.17771174013614655,
            0.13712839782238007,
            -0.07433166354894638,
            -0.23954921960830688
        ],
        [
            0.08428167551755905,
            -0.06650856137275696,
            0.2245730608701706,
            -0.010463337413966656,
            0.24473480880260468,
            -0.05664227530360222,
            0.11317258328199387,
            0.22961945831775665,
            -0.3276696503162384
        ],
        [
            -0.23307862877845764,
            -0.35117605328559875,
            0.023247111588716507,
            -0.3133818209171295,
            0.2727212905883789,
            -0.3279346823692322,
            0.24001473188400269,
            0.01850447617471218,
            0.2706860899925232
        ],
        [
            0.18059849739074707,
            -0.15736888349056244,
            0.08055008202791214,
            -0.3047388195991516,
            -0.2118673324584961,
            0.016837971284985542,
            0.3194449841976166,
            -0.19922827184200287,
            -0.148060142993927
        ],
        [
            -0.2201830893754959,
            -0.11211314797401428,
            -0.005001831334084272,
            0.06719870120286942,
            -0.2966333329677582,
            -0.268093079328537,
            0.12570936977863312,
            0.07299599051475525,
            -0.2482190579175949
        ],
        [
            0.03130192309617996,
            -0.013752974569797516,
            0.1197214350104332,
            -0.09380193799734116,
            0.2986724376678467,
            -0.1640172153711319,
            0.10944097489118576,
            0.2015838623046875,
            -0.3160409927368164
        ],
        [
            -0.15796640515327454,
            -0.273596853017807,
            0.3303404450416565,
            0.05824173614382744,
            0.05171360448002815,
            0.2645411491394043,
            0.25790637731552124,
            -0.20233628153800964,
            0.04506806284189224
        ],
        [
            0.10717349499464035,
            0.09823300689458847,
            -0.05227659270167351,
            0.16356994211673737,
            0.028086312115192413,
            0.22707800567150116,
            0.296402245759964,
            -0.264066606760025,
            0.14585202932357788
        ],
        [
            -0.18364830315113068,
            -0.008941789157688618,
            -0.09452980011701584,
            0.0004807121877092868,
            -0.2652791440486908,
            0.31312665343284607,
            -0.26200369000434875,
            0.16883590817451477,
            -0.1480773240327835
        ],
        [
            0.34065011143684387,
            -0.06286107748746872,
            0.015227421186864376,
            -0.2888050973415375,
            -0.3046138286590576,
            -0.08950337767601013,
            0.019745104014873505,
            0.22497843205928802,
            -0.304666131734848
        ],
        [
            0.24825868010520935,
            0.20410630106925964,
            -0.07034680992364883,
            -0.011467695236206055,
            -0.0849226787686348,
            -0.21177084743976593,
            0.08152046799659729,
            -0.10057123005390167,
            0.2904118001461029
        ],
        [
            -0.2650589644908905,
            0.19950754940509796,
            0.07025719434022903,
            0.3215770423412323,
            -0.15180754661560059,
            -0.3681844472885132,
            -0.09081040322780609,
            0.25768205523490906,
            -0.3287849426269531
        ],
        [
            -0.27125826478004456,
            -0.13710448145866394,
            -0.1711926907300949,
            0.30502453446388245,
            -0.19870664179325104,
            0.3624119758605957,
            0.32455918192863464,
            -0.05129287764430046,
            -0.03594561293721199
        ],
        [
            -0.1477828472852707,
            0.3454777002334595,
            -0.33581987023353577,
            0.2655976414680481,
            0.16460509598255157,
            0.1064542829990387,
            0.24177297949790955,
            -0.25175753235816956,
            -0.052323371171951294
        ],
        [
            -0.2554343342781067,
            0.2009258270263672,
            0.2556513845920563,
            0.18379619717597961,
            -0.20065104961395264,
            -0.11753419786691666,
            0.23956933617591858,
            -0.24358132481575012,
            -0.33180224895477295
        ],
        [
            -0.34472599625587463,
            0.296132892370224,
            -0.19451376795768738,
            -0.003836867166683078,
            -0.1725742369890213,
            0.2933826744556427,
            0.06960313022136688,
            0.04158484935760498,
            -0.17279958724975586
        ],
        [
            0.0814545527100563,
            -0.1884399652481079,
            -0.15182656049728394,
            0.1399625837802887,
            0.3179081678390503,
            0.06640642881393433,
            0.030776621773838997,
            0.15695078670978546,
            -0.33519259095191956
        ],
        [
            0.2082250416278839,
            -0.3535381853580475,
            -0.07753448933362961,
            -0.10744674503803253,
            0.33401355147361755,
            -0.08109845966100693,
            0.12773440778255463,
            -0.26614710688591003,
            -0.11649011820554733
        ],
        [
            0.18520791828632355,
            0.005165619309991598,
            0.22474521398544312,
            -0.030133400112390518,
            0.32281771302223206,
            -0.1839299350976944,
            -0.061627861112356186,
            -0.15005329251289368,
            0.25806763768196106
        ],
        [
            0.1708575040102005,
            -0.141057088971138,
            -0.16063112020492554,
            0.1955680400133133,
            -0.1163175106048584,
            -0.13021168112754822,
            -0.06686561554670334,
            0.006414250936359167,
            0.3300541639328003
        ],
        [
            0.17191137373447418,
            -0.33342862129211426,
            -0.33240699768066406,
            -0.1802024245262146,
            0.17739829421043396,
            -0.007007673382759094,
            -0.34969499707221985,
            -0.11905213445425034,
            -0.16960106790065765
        ],
        [
            0.17548975348472595,
            -0.3042471408843994,
            0.2913062274456024,
            -0.36476075649261475,
            -0.06796620041131973,
            0.043025512248277664,
            -0.3515186905860901,
            0.1168731153011322,
            -0.20733442902565002
        ],
        [
            0.11048385500907898,
            0.31138041615486145,
            0.15316443145275116,
            0.05369905009865761,
            -0.3359808027744293,
            0.2077721655368805,
            0.0025204564444720745,
            0.07776045054197311,
            -0.23148249089717865
        ],
        [
            0.010714142583310604,
            0.21099765598773956,
            0.10844714194536209,
            -0.39350834488868713,
            -0.10742536932229996,
            -0.11010996997356415,
            0.28989288210868835,
            0.22397029399871826,
            0.08097879588603973
        ],
        [
            0.1836203932762146,
            -0.34566035866737366,
            -0.06552056223154068,
            -0.2189285159111023,
            -0.016079820692539215,
            -0.12071549147367477,
            0.21768367290496826,
            0.21308307349681854,
            0.12102989852428436
        ],
        [
            -0.35203880071640015,
            -0.2864505648612976,
            0.23076504468917847,
            0.013533111661672592,
            0.23281843960285187,
            0.1121683418750763,
            0.04403997212648392,
            -0.21542972326278687,
            0.07015706598758698
        ],
        [
            -0.14024163782596588,
            -0.3167334496974945,
            0.08384542912244797,
            -0.2381455898284912,
            0.11578019708395004,
            -0.1180671900510788,
            -0.21020478010177612,
            -0.14895687997341156,
            0.3420293629169464
        ],
        [
            -0.1612120419740677,
            0.22546981275081635,
            0.046022895723581314,
            0.08962681144475937,
            -0.12580502033233643,
            0.18157580494880676,
            -0.2586054503917694,
            -0.3111439347267151,
            -0.08026961982250214
        ],
        [
            -0.17617201805114746,
            -0.24781963229179382,
            -0.12027899920940399,
            0.18961308896541595,
            -0.06523296982049942,
            0.21631883084774017,
            0.2536768317222595,
            -0.3206309676170349,
            0.13139280676841736
        ],
        [
            -0.20644614100456238,
            0.019062725827097893,
            -0.34030136466026306,
            0.1136774942278862,
            0.27705103158950806,
            -0.3169417679309845,
            -0.32351288199424744,
            0.01961088925600052,
            -0.2855803966522217
        ],
        [
            -0.29821255803108215,
            -0.26089605689048767,
            -0.19091832637786865,
            0.15046727657318115,
            0.07564503699541092,
            -0.14215458929538727,
            0.22239001095294952,
            0.1963808834552765,
            0.01253102719783783
        ],
        [
            -0.12132227420806885,
            -0.06874830275774002,
            0.2404450923204422,
            0.16177226603031158,
            0.041522033512592316,
            0.2189529538154602,
            -0.11294165253639221,
            -0.0478375218808651,
            -0.004819614812731743
        ],
        [
            -0.011929006315767765,
            -0.26956790685653687,
            -0.26549068093299866,
            -0.293842077255249,
            -0.03339313343167305,
            -0.23116302490234375,
            -0.15243470668792725,
            -0.06263583153486252,
            0.29405108094215393
        ],
        [
            -0.016842344775795937,
            -0.29606422781944275,
            -0.24434982240200043,
            0.13973015546798706,
            -0.29556262493133545,
            -0.15271420776844025,
            0.15029673278331757,
            -0.210659921169281,
            -0.14065146446228027
        ],
        [
            0.23913897573947906,
            0.31456002593040466,
            -0.0998949259519577,
            -0.3850953280925751,
            -0.05208471417427063,
            -0.2724657952785492,
            0.21474258601665497,
            0.12111784517765045,
            -0.31272315979003906
        ],
        [
            0.3477577269077301,
            -0.024879347532987595,
            -0.018090195953845978,
            -0.08661583811044693,
            -0.3336208164691925,
            0.24730105698108673,
            -0.1745106428861618,
            -0.0980716124176979,
            -0.2894049882888794
        ],
        [
            0.3027426600456238,
            0.2816570997238159,
            -0.22669006884098053,
            0.3030751049518585,
            0.20490217208862305,
            0.05019032210111618,
            -0.21254287660121918,
            -0.019781997427344322,
            0.32350999116897583
        ],
        [
            0.09838686138391495,
            0.07980911433696747,
            -0.30871278047561646,
            -0.03552637994289398,
            0.12539690732955933,
            0.008413076400756836,
            -0.226030632853508,
            -0.19152794778347015,
            0.12099167704582214
        ],
        [
            -0.1528586596250534,
            -0.3585413694381714,
            -0.10685273259878159,
            -0.14366601407527924,
            0.059006158262491226,
            0.24372854828834534,
            -0.15726803243160248,
            -0.1573837548494339,
            -0.17454087734222412
        ],
        [
            0.0838550254702568,
            -0.3761904537677765,
            0.13464203476905823,
            -0.13526543974876404,
            -0.056006915867328644,
            -0.24977536499500275,
            0.017033863812685013,
            -0.25308719277381897,
            -0.24592094123363495
        ],
        [
            0.13245587050914764,
            -0.10188817977905273,
            -0.06735094636678696,
            -0.05634574964642525,
            0.060185421258211136,
            0.017944365739822388,
            -0.15453258156776428,
            -0.09894009679555893,
            -0.22643902897834778
        ],
        [
            -0.29693838953971863,
            -0.2569584548473358,
            0.043089866638183594,
            0.18094538152217865,
            -0.27447980642318726,
            0.11702005565166473,
            0.31168708205223083,
            -0.23997534811496735,
            -0.06716260313987732
        ],
        [
            0.26135092973709106,
            -0.24217359721660614,
            -0.35937732458114624,
            0.139205664396286,
            0.07032176107168198,
            -0.29541510343551636,
            -0.049462784081697464,
            0.22695785760879517,
            -0.1502370536327362
        ],
        [
            -0.08241024613380432,
            0.10508543252944946,
            0.3233329653739929,
            -0.22791056334972382,
            -0.30198946595191956,
            0.1457187980413437,
            -0.32818081974983215,
            -0.1541232466697693,
            -0.2705819010734558
        ],
        [
            -0.07766745239496231,
            -0.17780159413814545,
            0.21265658736228943,
            0.3278842270374298,
            -0.3241941034793854,
            0.1795092225074768,
            0.20426063239574432,
            -0.1648770421743393,
            -0.206511989235878
        ],
        [
            0.11246777325868607,
            -0.2997078001499176,
            0.25926005840301514,
            0.1917290836572647,
            -0.1691451370716095,
            0.29115575551986694,
            -0.2312784492969513,
            0.10165884345769882,
            0.3368709683418274
        ],
        [
            0.08120127022266388,
            -0.341988205909729,
            0.0010183912236243486,
            -0.002597516868263483,
            0.20278704166412354,
            -0.21587921679019928,
            0.3375128507614136,
            -0.030141888186335564,
            0.16183817386627197
        ],
        [
            -0.19793590903282166,
            -0.006909964606165886,
            0.2888542711734772,
            0.18899273872375488,
            -0.1958034783601761,
            0.01581805758178234,
            -0.0013390437234193087,
            -0.2834649682044983,
            -0.12781375646591187
        ],
        [
            -0.07303564250469208,
            0.0882209837436676,
            -0.22481763362884521,
            -0.07710334658622742,
            -0.18470829725265503,
            -0.057805195450782776,
            -0.30449798703193665,
            0.08098004013299942,
            0.16423843801021576
        ],
        [
            -0.16645701229572296,
            0.005846083164215088,
            -0.13781622052192688,
            0.22648857533931732,
            0.17043791711330414,
            -0.14448049664497375,
            -0.04620756208896637,
            -0.18226617574691772,
            -0.2800741493701935
        ],
        [
            -0.05457186698913574,
            0.17160212993621826,
            -0.11415256559848785,
            0.1745017170906067,
            -0.08395463228225708,
            0.19597645103931427,
            0.32239001989364624,
            0.13220766186714172,
            -0.2260502427816391
        ],
        [
            -0.22528143227100372,
            -0.23570755124092102,
            0.009068134240806103,
            -0.10906361043453217,
            0.2694470286369324,
            0.14915940165519714,
            0.2584472596645355,
            -0.2658655643463135,
            0.3392032980918884
        ],
        [
            0.1358911097049713,
            0.212771475315094,
            -0.14224106073379517,
            0.04689882695674896,
            -0.16032543778419495,
            0.257405549287796,
            0.04746187850832939,
            0.26335304975509644,
            -0.2808079719543457
        ],
        [
            0.07871155440807343,
            -0.00111248972825706,
            -0.1477615386247635,
            -0.2009637951850891,
            0.18331889808177948,
            0.001841086195781827,
            0.2667402923107147,
            0.3019448518753052,
            0.22865071892738342
        ],
        [
            0.141211599111557,
            -0.1722181886434555,
            -0.17591498792171478,
            -0.09777947515249252,
            0.19475147128105164,
            -0.2738177180290222,
            -0.1398138552904129,
            -0.030420802533626556,
            -0.0880204439163208
        ],
        [
            0.25177648663520813,
            0.2065107822418213,
            0.21447591483592987,
            -0.3007659912109375,
            0.30023711919784546,
            -0.13945390284061432,
            -0.1390824168920517,
            0.11208264529705048,
            -0.18376436829566956
        ],
        [
            -0.06412315368652344,
            0.2352670580148697,
            -0.2631325125694275,
            -0.06506837904453278,
            0.2953895628452301,
            -0.03170713037252426,
            -0.03144380822777748,
            0.18865138292312622,
            0.22876980900764465
        ],
        [
            -0.084195077419281,
            0.14305008947849274,
            -0.1287078708410263,
            -0.03306414559483528,
            0.19939355552196503,
            0.22591519355773926,
            -0.10663485527038574,
            -0.11956701427698135,
            0.3599838316440582
        ],
        [
            0.258558452129364,
            0.06392781436443329,
            -0.30195024609565735,
            -0.08225852996110916,
            0.20112226903438568,
            -0.23931732773780823,
            0.23521031439304352,
            0.17638464272022247,
            -0.2210664302110672
        ],
        [
            0.21737277507781982,
            0.2726202607154846,
            0.06368131935596466,
            -0.34111258387565613,
            -0.16925914585590363,
            -0.37143057584762573,
            -0.2934301793575287,
            -0.25482138991355896,
            0.04301786795258522
        ],
        [
            -0.25949713587760925,
            0.23482517898082733,
            -0.18137745559215546,
            -0.34394335746765137,
            -0.0755142867565155,
            -0.13808244466781616,
            0.2635752558708191,
            0.19527289271354675,
            -0.014404956251382828
        ],
        [
            0.082649365067482,
            0.23315079510211945,
            0.30970102548599243,
            0.3014046251773834,
            -0.004593390040099621,
            0.10616905242204666,
            0.2546112537384033,
            0.2550278902053833,
            0.22788552939891815
        ],
        [
            0.28562942147254944,
            -0.20367228984832764,
            -0.1411048024892807,
            -0.0010245968587696552,
            -0.057565730065107346,
            -0.08916871249675751,
            -0.14683426916599274,
            0.11667608469724655,
            0.05611284822225571
        ],
        [
            0.14183448255062103,
            0.245971217751503,
            0.17454195022583008,
            0.16591152548789978,
            0.06279923766851425,
            0.35473647713661194,
            -0.1517445296049118,
            -0.15249225497245789,
            -0.06172911450266838
        ],
        [
            -0.17074772715568542,
            -0.10798341035842896,
            -0.22142790257930756,
            0.14352190494537354,
            -0.12418781220912933,
            -0.2798252999782562,
            -0.11089938133955002,
            0.3320392966270447,
            0.25731536746025085
        ],
        [
            -0.23976795375347137,
            -0.17451202869415283,
            -0.31736913323402405,
            -0.21898461878299713,
            0.32215794920921326,
            -0.2226298451423645,
            -0.3221433758735657,
            -0.1857713758945465,
            -0.030198972672224045
        ],
        [
            -0.35440853238105774,
            -0.14600662887096405,
            0.05391734465956688,
            0.18389227986335754,
            -0.03328302875161171,
            0.17468984425067902,
            0.18805785477161407,
            -0.25247815251350403,
            -0.10011006891727448
        ],
        [
            -0.3072696030139923,
            -0.05253046751022339,
            -0.004730596672743559,
            -0.02869039960205555,
            0.05855119600892067,
            0.3063328266143799,
            0.16311724483966827,
            0.28571826219558716,
            -0.14332464337348938
        ],
        [
            -0.33099257946014404,
            -0.26193636655807495,
            0.08380349725484848,
            -0.09939166158437729,
            0.16875314712524414,
            -0.08493679761886597,
            -0.030026840046048164,
            -0.06962265819311142,
            0.2584163248538971
        ],
        [
            0.3339780271053314,
            -0.3275565505027771,
            0.06194697692990303,
            -0.1609419733285904,
            0.2920266091823578,
            -0.1263292282819748,
            0.08755376189947128,
            -0.21707013249397278,
            0.2184961885213852
        ],
        [
            -0.22221219539642334,
            0.2927001118659973,
            0.19317221641540527,
            0.01401448529213667,
            0.030369164422154427,
            -0.24142180383205414,
            -0.15675947070121765,
            -0.23567597568035126,
            -0.07622116804122925
        ],
        [
            -0.19188790023326874,
            -0.19423174858093262,
            -0.2690516710281372,
            0.10791026800870895,
            -0.21344847977161407,
            -0.29924148321151733,
            -0.027771051973104477,
            0.017987463623285294,
            0.23952622711658478
        ],
        [
            0.15795300900936127,
            -0.23655040562152863,
            -0.09613511711359024,
            0.16430021822452545,
            0.0612640306353569,
            -0.22702477872371674,
            0.06959852576255798,
            -0.1556481271982193,
            0.28787627816200256
        ],
        [
            0.222896546125412,
            -0.011257682926952839,
            0.033055879175662994,
            -0.15270918607711792,
            -0.1138005256652832,
            -0.09103287756443024,
            0.06127890571951866,
            0.080986887216568,
            0.21356773376464844
        ],
        [
            -0.26042523980140686,
            -0.3162713944911957,
            -0.21566982567310333,
            -0.2263064831495285,
            0.2660145163536072,
            0.13466142117977142,
            -0.19953957200050354,
            -0.21896547079086304,
            0.275674045085907
        ],
        [
            0.1318223476409912,
            -0.3059498369693756,
            -0.3521270453929901,
            -0.19964365661144257,
            0.06514865159988403,
            -0.126006618142128,
            0.22257187962532043,
            -0.047059934586286545,
            -0.08262191712856293
        ],
        [
            -0.2627848982810974,
            0.024810831993818283,
            0.23979109525680542,
            -0.08390939980745316,
            -0.04966415464878082,
            -0.2845497727394104,
            0.14355868101119995,
            0.09226062893867493,
            0.08359559625387192
        ],
        [
            -0.09549380838871002,
            -0.33168309926986694,
            -0.22206556797027588,
            0.30604928731918335,
            -0.21913868188858032,
            0.31825482845306396,
            -0.012976627796888351,
            -0.16225972771644592,
            0.09464288502931595
        ],
        [
            0.31277191638946533,
            0.043414339423179626,
            -0.22891993820667267,
            -0.1400814801454544,
            0.17720317840576172,
            0.22552694380283356,
            -0.0047879344783723354,
            -0.2505705654621124,
            0.11040427535772324
        ],
        [
            -0.2579587399959564,
            0.3302129805088043,
            0.08728808909654617,
            -0.24696432054042816,
            0.17562101781368256,
            -0.27535301446914673,
            -0.15930885076522827,
            -0.33053937554359436,
            0.08756284415721893
        ],
        [
            0.3763873875141144,
            0.09606873989105225,
            0.24498341977596283,
            -0.37131598591804504,
            -0.2304014414548874,
            -0.15005318820476532,
            -0.3155824542045593,
            -0.12278597801923752,
            0.21743109822273254
        ],
        [
            0.04118640720844269,
            -0.07647164911031723,
            -0.3413214087486267,
            -0.3416648209095001,
            0.215772345662117,
            0.17158742249011993,
            -0.33309173583984375,
            -0.22738371789455414,
            0.1847035139799118
        ],
        [
            0.16884475946426392,
            0.12757503986358643,
            0.016862059012055397,
            -0.337039977312088,
            0.022122535854578018,
            -0.06490789353847504,
            -0.19448116421699524,
            0.3133526146411896,
            0.0739610567688942
        ],
        [
            0.20835162699222565,
            0.21731266379356384,
            0.25187283754348755,
            -0.2882634699344635,
            0.13380947709083557,
            -0.09278351813554764,
            -0.28555527329444885,
            0.2727336287498474,
            -0.0003360143455211073
        ],
        [
            -0.14168350398540497,
            0.07228223979473114,
            0.15036414563655853,
            -0.18748845160007477,
            -0.11408542096614838,
            -0.12271855026483536,
            0.32267022132873535,
            -0.2164653092622757,
            -0.2668132483959198
        ],
        [
            0.17250889539718628,
            -0.23054586350917816,
            0.24733023345470428,
            0.030193587765097618,
            0.2578386962413788,
            -0.28849145770072937,
            -0.10242585837841034,
            -0.2648985981941223,
            0.30432164669036865
        ],
        [
            -0.14775046706199646,
            0.09618785977363586,
            -0.20468518137931824,
            -0.31678506731987,
            0.3000269830226898,
            -0.36045798659324646,
            -0.316272497177124,
            0.13351424038410187,
            0.2960973381996155
        ],
        [
            -0.2424369603395462,
            0.03898563235998154,
            -0.34857919812202454,
            -0.156447634100914,
            0.2382596880197525,
            0.10964122414588928,
            -0.18670232594013214,
            0.3005094826221466,
            -0.11117517203092575
        ],
        [
            -0.36578279733657837,
            0.3198360204696655,
            -0.12340710312128067,
            0.27905064821243286,
            0.04154566675424576,
            0.23677946627140045,
            -0.2977605164051056,
            -0.209306001663208,
            0.03762047365307808
        ],
        [
            -0.0565180703997612,
            0.27816805243492126,
            -0.22947338223457336,
            -0.2734478712081909,
            -0.11155984550714493,
            -0.04380480945110321,
            0.06669526547193527,
            -0.19050844013690948,
            -0.1591430902481079
        ],
        [
            -0.09248026460409164,
            -0.2863772511482239,
            -0.2533540427684784,
            0.011155528016388416,
            -0.10071147978305817,
            0.31734582781791687,
            0.08868476748466492,
            -0.2909603714942932,
            -0.14591041207313538
        ],
        [
            0.19954468309879303,
            -0.1372940093278885,
            -0.09200823307037354,
            0.26229995489120483,
            0.2070395052433014,
            0.23698079586029053,
            0.12894393503665924,
            -0.26730310916900635,
            0.10426793247461319
        ],
        [
            0.051512304693460464,
            -0.0692535862326622,
            0.266245573759079,
            -0.23499557375907898,
            -0.16895079612731934,
            0.1480126529932022,
            -0.2652885913848877,
            -0.253366082906723,
            -0.16531755030155182
        ],
        [
            -0.35804834961891174,
            0.165312260389328,
            0.12093928456306458,
            0.19234760105609894,
            0.26740720868110657,
            0.07062137126922607,
            0.27385926246643066,
            -0.17398352921009064,
            -0.1551956981420517
        ],
        [
            -0.17212504148483276,
            -0.019173866137862206,
            -0.06448597460985184,
            0.09836408495903015,
            0.056391194462776184,
            -0.08851362019777298,
            0.2566051781177521,
            0.04151153191924095,
            0.09413180500268936
        ],
        [
            -0.2556343972682953,
            -0.022576602175831795,
            0.020642582327127457,
            0.20004107058048248,
            -0.23461608588695526,
            0.18536710739135742,
            0.27694252133369446,
            0.23296023905277252,
            0.2818300127983093
        ],
        [
            -0.03516699746251106,
            0.31337836384773254,
            -0.05804608389735222,
            -0.3134208023548126,
            -0.09304581582546234,
            -0.10073353350162506,
            0.24015963077545166,
            -0.20727165043354034,
            0.10626523941755295
        ],
        [
            0.24504368007183075,
            0.21599553525447845,
            0.09097626805305481,
            -0.15978817641735077,
            0.16258420050144196,
            -0.1929401159286499,
            -0.05246754735708237,
            0.06436861306428909,
            0.024441439658403397
        ],
        [
            0.2598475515842438,
            0.09382632374763489,
            0.20737570524215698,
            -0.13060694932937622,
            -0.3369557857513428,
            0.23743028938770294,
            0.0916961207985878,
            -0.1778891533613205,
            0.3494734466075897
        ],
        [
            0.0029550485778599977,
            -0.06718508154153824,
            0.1867976039648056,
            -0.14020687341690063,
            0.2500123381614685,
            -0.22395285964012146,
            0.12901955842971802,
            -0.19591407477855682,
            -0.17922121286392212
        ],
        [
            -0.1613241583108902,
            0.2658044099807739,
            -0.06943947821855545,
            0.11116605252027512,
            0.10881473124027252,
            -0.13493938744068146,
            -0.1573043018579483,
            0.05096903070807457,
            -0.34140294790267944
        ],
        [
            -0.06854370981454849,
            0.22334545850753784,
            0.20243467390537262,
            -0.13888883590698242,
            -0.1681460589170456,
            -0.06673348695039749,
            -0.08290180563926697,
            -0.17828314006328583,
            -0.2522980272769928
        ],
        [
            0.3123997449874878,
            0.007907195016741753,
            0.027481675148010254,
            -0.2219589650630951,
            0.26338037848472595,
            0.2208147644996643,
            0.11423394829034805,
            -0.03668520599603653,
            -0.27285534143447876
        ],
        [
            0.03219081461429596,
            -0.17276738584041595,
            -0.08923541009426117,
            -0.025492383167147636,
            -0.20846161246299744,
            0.19021092355251312,
            0.2576523423194885,
            0.18085046112537384,
            -0.12391529977321625
        ],
        [
            0.34738054871559143,
            0.11327158659696579,
            -0.05708868429064751,
            0.02312534675002098,
            -0.12228037416934967,
            0.24916629493236542,
            0.07603424042463303,
            -0.035996127873659134,
            0.3023764491081238
        ],
        [
            -0.052382975816726685,
            0.1615823358297348,
            -0.05776626989245415,
            -0.22247196733951569,
            -0.2941441833972931,
            0.11250678449869156,
            0.17722387611865997,
            0.3277815878391266,
            0.06970367580652237
        ],
        [
            -0.23972046375274658,
            -0.16449646651744843,
            -0.020943500101566315,
            0.13814367353916168,
            0.0691463053226471,
            0.09063995629549026,
            0.08623319864273071,
            0.33499205112457275,
            0.2964381277561188
        ],
        [
            0.16521359980106354,
            0.2536739110946655,
            -0.15436352789402008,
            0.12350059300661087,
            0.1993066966533661,
            -0.09704291820526123,
            -0.220927894115448,
            0.16132526099681854,
            -0.2780907154083252
        ],
        [
            0.10094734281301498,
            -0.3164931535720825,
            -0.10980039834976196,
            0.024961024522781372,
            0.31159520149230957,
            -0.02756723016500473,
            0.20584820210933685,
            -0.1948312371969223,
            0.22058004140853882
        ],
        [
            -0.23630817234516144,
            0.05606131628155708,
            0.33936408162117004,
            -0.33243682980537415,
            0.00686110882088542,
            -0.3090895116329193,
            -0.3452066481113434,
            0.10886063426733017,
            -0.010860612615942955
        ],
        [
            0.09645773470401764,
            -0.06892997026443481,
            -0.21545174717903137,
            0.1583312302827835,
            -0.13766302168369293,
            0.24630917608737946,
            0.18433786928653717,
            0.2587755620479584,
            -0.19534073770046234
        ],
        [
            -0.17529824376106262,
            0.2411162406206131,
            -0.17440636456012726,
            0.19914506375789642,
            0.18662245571613312,
            0.3252899944782257,
            -0.25527647137641907,
            -0.24877353012561798,
            -0.020317845046520233
        ],
        [
            0.17769703269004822,
            0.1494947224855423,
            0.18791456520557404,
            -0.31991854310035706,
            0.22133992612361908,
            -0.15191493928432465,
            -0.33444467186927795,
            0.2704247832298279,
            0.13679613173007965
        ],
        [
            -0.021052004769444466,
            -0.16351915895938873,
            -0.13062529265880585,
            0.08080664277076721,
            0.24157433211803436,
            -0.06259679049253464,
            -0.30479973554611206,
            -0.19400131702423096,
            0.3265719413757324
        ],
        [
            -0.3078557848930359,
            0.2757014036178589,
            -0.05776514485478401,
            -0.14778707921504974,
            -0.1863061934709549,
            0.3117896020412445,
            -0.13282601535320282,
            0.1884773224592209,
            -0.2787240445613861
        ],
        [
            -0.35982173681259155,
            -0.007817723788321018,
            0.19980904459953308,
            0.17602981626987457,
            0.2329840064048767,
            -0.13300077617168427,
            0.28070467710494995,
            -0.06318017840385437,
            0.09148290753364563
        ],
        [
            0.0892389714717865,
            -0.3172180950641632,
            -0.05799248442053795,
            -0.2142936736345291,
            0.010424330830574036,
            -0.32335108518600464,
            -0.19944952428340912,
            0.17741301655769348,
            0.09243188798427582
        ],
        [
            -0.2746264934539795,
            -0.1484963744878769,
            -0.055915459990501404,
            -0.14782677590847015,
            0.08072833716869354,
            0.002170401392504573,
            -0.11761084944009781,
            0.13778075575828552,
            -0.04947980120778084
        ],
        [
            -0.009350016713142395,
            0.15003591775894165,
            0.24999305605888367,
            -0.058418598026037216,
            -0.13906601071357727,
            -0.31610286235809326,
            0.04110802337527275,
            0.20972073078155518,
            0.22515490651130676
        ],
        [
            0.2856127619743347,
            -0.1772446632385254,
            0.038194332271814346,
            0.04042692109942436,
            -0.2529532015323639,
            -0.06938374787569046,
            0.15118961036205292,
            -0.1859695017337799,
            -0.25289517641067505
        ],
        [
            0.07380135357379913,
            -0.2640623450279236,
            0.08162804692983627,
            0.09427250921726227,
            -0.1814299076795578,
            -0.010802675038576126,
            0.20553019642829895,
            0.019083963707089424,
            -0.018227504566311836
        ],
        [
            -0.14128518104553223,
            -0.06266743689775467,
            0.16788625717163086,
            -0.016638662666082382,
            0.12032993882894516,
            -0.23146960139274597,
            0.254496306180954,
            0.02881482057273388,
            0.14867804944515228
        ],
        [
            -0.25507378578186035,
            0.12330558896064758,
            0.18268944323062897,
            -0.03664855659008026,
            0.05762970820069313,
            -0.16222117841243744,
            0.034326523542404175,
            0.2832687199115753,
            0.2670254707336426
        ],
        [
            0.010840998962521553,
            0.07518493384122849,
            -0.15417858958244324,
            0.053821880370378494,
            -0.13687090575695038,
            0.23970216512680054,
            -0.31792792677879333,
            -0.046199724078178406,
            0.34807831048965454
        ],
        [
            -0.20384320616722107,
            0.02953401580452919,
            -0.0970032811164856,
            0.10570763796567917,
            0.10980730503797531,
            -0.12460605055093765,
            -0.11887025833129883,
            0.24338504672050476,
            -0.16102221608161926
        ],
        [
            0.29851728677749634,
            -0.014980586245656013,
            -0.027912966907024384,
            0.1644836813211441,
            -0.3225509524345398,
            -0.2807445228099823,
            0.2100011706352234,
            -0.3512953221797943,
            -0.11879675090312958
        ],
        [
            -0.24324928224086761,
            0.1087082028388977,
            0.34906917810440063,
            0.16651712357997894,
            0.04053470119833946,
            0.3170504570007324,
            0.173461452126503,
            0.2467663437128067,
            -0.29695820808410645
        ],
        [
            0.30386701226234436,
            0.11283430457115173,
            -0.04840327426791191,
            -0.19663108885288239,
            0.11280583590269089,
            0.2905462384223938,
            0.3579065501689911,
            -0.002543580485507846,
            -0.01581716351211071
        ],
        [
            0.31515416502952576,
            -0.15636421740055084,
            0.3496541678905487,
            0.20254632830619812,
            -0.19119834899902344,
            -0.3389906585216522,
            -0.15705524384975433,
            0.17282459139823914,
            0.3499789535999298
        ],
        [
            0.35533493757247925,
            -0.07208644598722458,
            0.11235994845628738,
            0.3449581265449524,
            -0.15077947080135345,
            -0.0370284728705883,
            0.06792699545621872,
            -0.3032055199146271,
            -0.3034234941005707
        ],
        [
            -0.058862391859292984,
            0.15049178898334503,
            -0.05110308155417442,
            -0.20306611061096191,
            0.07155834138393402,
            0.09749767184257507,
            -0.026833683252334595,
            0.019027499482035637,
            0.3658238649368286
        ],
        [
            -0.4008224904537201,
            -0.2334330976009369,
            -0.27501264214515686,
            0.09155930578708649,
            0.20799599587917328,
            -0.27351173758506775,
            0.04762401804327965,
            0.0016282320721074939,
            0.2515706419944763
        ],
        [
            -0.014239225536584854,
            -0.395674467086792,
            -0.3098251521587372,
            0.1129789799451828,
            -0.14547304809093475,
            0.0336139015853405,
            0.20232777297496796,
            -0.2504367530345917,
            0.1308111548423767
        ],
        [
            0.0009242861997336149,
            -0.02346338890492916,
            -0.18131442368030548,
            0.06521826237440109,
            0.10822504758834839,
            -0.33603236079216003,
            -0.22052156925201416,
            0.07443181425333023,
            -0.08851248025894165
        ],
        [
            -0.2586917281150818,
            -0.003253590315580368,
            -0.1014886200428009,
            0.23458713293075562,
            -0.14044709503650665,
            0.25923699140548706,
            0.03409022092819214,
            0.28496456146240234,
            -0.29769742488861084
        ],
        [
            -0.16316547989845276,
            0.0036840843968093395,
            0.1297844499349594,
            0.20949484407901764,
            0.25919535756111145,
            0.350720077753067,
            -0.10972228646278381,
            -0.25738710165023804,
            0.33076947927474976
        ],
        [
            0.279528945684433,
            0.22167715430259705,
            -0.07733453065156937,
            -0.15612950921058655,
            -0.12190371006727219,
            -0.2693421542644501,
            -0.23784229159355164,
            -0.05446188151836395,
            -0.17741726338863373
        ],
        [
            -0.11197622120380402,
            0.1440623700618744,
            -0.1131519302725792,
            -0.31905701756477356,
            -0.3402106463909149,
            0.2722654342651367,
            -0.3110668659210205,
            0.31614476442337036,
            -0.013610923662781715
        ],
        [
            0.011298221535980701,
            0.15082474052906036,
            -0.21326182782649994,
            0.22919131815433502,
            -0.2238224744796753,
            0.24668169021606445,
            -0.036912839859724045,
            0.3452708125114441,
            -0.1868056058883667
        ],
        [
            0.24271813035011292,
            0.18859755992889404,
            -0.13434825837612152,
            0.16755330562591553,
            -0.0519111193716526,
            0.09830793738365173,
            -0.21190065145492554,
            -0.10471867024898529,
            -0.16129161417484283
        ],
        [
            -0.24505849182605743,
            -0.022940143942832947,
            -0.11457675695419312,
            0.008493026718497276,
            -0.187172070145607,
            0.232477068901062,
            -0.21912413835525513,
            0.15369912981987,
            0.2704567313194275
        ],
        [
            -0.30397191643714905,
            0.3229583203792572,
            -0.17716865241527557,
            0.08917070925235748,
            -0.07434272021055222,
            0.05641849711537361,
            -0.16732662916183472,
            0.14621445536613464,
            0.332069993019104
        ],
        [
            -0.0857483446598053,
            0.029727421700954437,
            -0.06646934151649475,
            -0.2765039801597595,
            -0.1878949999809265,
            -0.281432181596756,
            -0.11095844954252243,
            -0.07134402543306351,
            0.150605246424675
        ],
        [
            -0.35775095224380493,
            0.2479184865951538,
            -0.19523461163043976,
            0.11429242044687271,
            0.33130449056625366,
            -0.06465388089418411,
            0.21831835806369781,
            -0.2385566234588623,
            -0.006884395610541105
        ],
        [
            0.34765446186065674,
            -0.10622693598270416,
            0.10198310762643814,
            -0.015733536332845688,
            -0.26177603006362915,
            -0.21284136176109314,
            0.2989850640296936,
            0.0948963537812233,
            -0.31030023097991943
        ],
        [
            -0.031872209161520004,
            -0.04658067971467972,
            0.02938428521156311,
            -0.12720364332199097,
            -0.32413309812545776,
            -0.3313354253768921,
            0.3428233563899994,
            -0.3034665584564209,
            -0.24824555218219757
        ],
        [
            0.2754219174385071,
            0.2823660671710968,
            -0.1721223145723343,
            -0.22652477025985718,
            -0.13719220459461212,
            0.25943315029144287,
            0.2888457477092743,
            0.11439526081085205,
            0.0724254623055458
        ],
        [
            0.24989035725593567,
            0.334602028131485,
            0.07456696033477783,
            0.13970878720283508,
            -0.06624741107225418,
            0.3227616548538208,
            -0.10138823091983795,
            -0.011441933922469616,
            -0.23477788269519806
        ],
        [
            -0.29634377360343933,
            -0.18206673860549927,
            -0.06687759608030319,
            0.33730313181877136,
            -0.3145853579044342,
            -0.2972528636455536,
            0.06857207417488098,
            0.13943280279636383,
            -0.010294489562511444
        ],
        [
            0.14320780336856842,
            -0.08552844077348709,
            -0.08619025349617004,
            0.24801960587501526,
            0.10579970479011536,
            0.17639784514904022,
            -0.2661585509777069,
            -0.042475346475839615,
            0.32045769691467285
        ],
        [
            0.26682841777801514,
            -0.2506570816040039,
            -0.27636846899986267,
            0.14861825108528137,
            0.34034258127212524,
            -0.3606151044368744,
            -0.13949137926101685,
            0.1671571433544159,
            -0.3239095211029053
        ],
        [
            0.3160723149776459,
            -0.011060815304517746,
            -0.09678493440151215,
            0.16114243865013123,
            0.26409712433815,
            -0.3391572833061218,
            0.09111808985471725,
            -0.32424625754356384,
            -0.28751176595687866
        ],
        [
            0.022643698379397392,
            0.04478475823998451,
            -0.20568785071372986,
            -0.30128005146980286,
            -0.34465816617012024,
            0.03877032548189163,
            0.18792590498924255,
            -0.32999858260154724,
            -0.003349220845848322
        ],
        [
            0.3539464771747589,
            -0.04156286641955376,
            -0.13636106252670288,
            -0.20503617823123932,
            -0.239858940243721,
            -0.24392150342464447,
            0.03482331335544586,
            -0.049369871616363525,
            0.06990032643079758
        ],
        [
            0.34604814648628235,
            -0.12937891483306885,
            -0.13823416829109192,
            0.31614476442337036,
            0.24719688296318054,
            -0.09431922435760498,
            0.03373781591653824,
            -0.04836668446660042,
            -0.14157746732234955
        ],
        [
            0.336591899394989,
            -0.1623096615076065,
            -0.2751345932483673,
            0.2834666669368744,
            0.005518398247659206,
            0.11380220949649811,
            0.003842967329546809,
            0.2879401445388794,
            0.1862533837556839
        ],
        [
            0.1008467897772789,
            -0.07763584703207016,
            -0.2730286121368408,
            0.20544393360614777,
            0.05617309734225273,
            -0.15880809724330902,
            -0.05634780600667,
            0.05502290278673172,
            0.32269370555877686
        ],
        [
            0.01257224939763546,
            0.014163659885525703,
            0.12761364877223969,
            0.2919447422027588,
            0.22923442721366882,
            -0.33424320816993713,
            0.18964257836341858,
            0.10542282462120056,
            0.20479445159435272
        ],
        [
            -0.33155882358551025,
            -0.24999752640724182,
            -0.035866059362888336,
            0.03190645948052406,
            0.23780283331871033,
            -0.04895104095339775,
            0.030143480747938156,
            0.03875380754470825,
            0.25657567381858826
        ],
        [
            -0.3035644590854645,
            -0.10417483747005463,
            -0.2208917737007141,
            0.36795946955680847,
            0.27907562255859375,
            -0.34723374247550964,
            -0.06426455825567245,
            -0.27556872367858887,
            0.29680871963500977
        ],
        [
            -0.08886820077896118,
            -0.09723664075136185,
            0.14741815626621246,
            -0.23819023370742798,
            -0.15232737362384796,
            -0.3542060852050781,
            -0.2131313532590866,
            0.15639834105968475,
            0.03726128861308098
        ],
        [
            -0.0566774383187294,
            0.23914647102355957,
            0.29020482301712036,
            -0.10573766380548477,
            0.28660130500793457,
            0.08507733792066574,
            -0.24933411180973053,
            -0.21254368126392365,
            0.0702400803565979
        ],
        [
            -0.06374140083789825,
            0.12751588225364685,
            -0.14230957627296448,
            0.2683236598968506,
            -0.03644392639398575,
            -0.29148635268211365,
            0.2580844759941101,
            0.22909215092658997,
            0.02018093690276146
        ],
        [
            -0.17242267727851868,
            -0.10066083073616028,
            0.2320096492767334,
            -0.05232273042201996,
            -0.3099617063999176,
            -0.2085382491350174,
            -0.04631432518362999,
            0.17289651930332184,
            -0.2020963728427887
        ],
        [
            -0.19040563702583313,
            0.07904066890478134,
            -0.2815108895301819,
            0.26701265573501587,
            -0.19816148281097412,
            0.16287711262702942,
            -0.24616044759750366,
            0.2129257768392563,
            -0.2756158113479614
        ],
        [
            0.19939522445201874,
            0.23586376011371613,
            0.11109652370214462,
            0.2596812844276428,
            -0.2524673640727997,
            -0.13212551176548004,
            0.006261184345930815,
            -0.19648806750774384,
            -0.20203135907649994
        ],
        [
            0.0197245255112648,
            0.10558119416236877,
            -0.062004201114177704,
            0.20811811089515686,
            -0.2948511838912964,
            0.23165665566921234,
            0.1031956896185875,
            0.004887303803116083,
            -0.1084674671292305
        ],
        [
            0.1602252572774887,
            0.039940934628248215,
            0.23946471512317657,
            -0.22488418221473694,
            -0.1684766709804535,
            0.29744088649749756,
            0.15599821507930756,
            -0.1882942020893097,
            -0.027313699945807457
        ],
        [
            -0.06010003760457039,
            0.16945970058441162,
            -0.2779468595981598,
            -0.09448457509279251,
            -0.025881094858050346,
            0.049704622477293015,
            -0.29712438583374023,
            0.21154941618442535,
            0.374280720949173
        ],
        [
            0.09705944359302521,
            0.059055689722299576,
            0.31359195709228516,
            -0.2937432825565338,
            -0.28407973051071167,
            -0.06801030784845352,
            0.1552453190088272,
            -0.15738388895988464,
            -0.2778306305408478
        ],
        [
            0.32546746730804443,
            -0.17341503500938416,
            0.1463537961244583,
            -0.3299594521522522,
            -0.2564537823200226,
            0.025210626423358917,
            -0.2335042804479599,
            0.017915578559041023,
            -0.23246730864048004
        ],
        [
            -0.13589781522750854,
            -0.04796735942363739,
            -0.07164913415908813,
            -0.2315404713153839,
            0.23152202367782593,
            0.09017382562160492,
            -0.2679978907108307,
            -0.28394490480422974,
            -0.33768707513809204
        ],
        [
            -0.20474527776241302,
            -0.17923127114772797,
            -0.009301050566136837,
            0.11437355726957321,
            0.24966241419315338,
            0.19957341253757477,
            -0.1829988807439804,
            -0.3544665575027466,
            -0.2928221523761749
        ],
        [
            -0.20536991953849792,
            -0.3085842728614807,
            0.29653894901275635,
            -0.12033215910196304,
            -0.2811509072780609,
            -0.2655673921108246,
            -0.04407760873436928,
            -0.20200411975383759,
            -0.030948756262660027
        ],
        [
            0.1387433558702469,
            0.22314861416816711,
            0.02475547417998314,
            -0.25438088178634644,
            0.11578749120235443,
            -0.12470089644193649,
            -0.20259027183055878,
            -0.162378191947937,
            0.1731610745191574
        ],
        [
            0.2577904760837555,
            0.3042997717857361,
            0.19442066550254822,
            -0.27298399806022644,
            0.13438154757022858,
            0.0674530416727066,
            -0.17091716825962067,
            0.3195837736129761,
            0.25714248418807983
        ],
        [
            -0.28609320521354675,
            -0.30266857147216797,
            0.25512439012527466,
            -0.08090444654226303,
            0.3227910101413727,
            0.009183477610349655,
            -0.1899915635585785,
            0.08255081623792648,
            -0.2435307651758194
        ],
        [
            -0.18778052926063538,
            0.18830351531505585,
            0.3529830873012543,
            -0.3541693389415741,
            -0.17955763638019562,
            -0.2703687846660614,
            0.016009829938411713,
            -0.1863040030002594,
            0.010871343314647675
        ],
        [
            -0.30367541313171387,
            0.19843323528766632,
            0.18468113243579865,
            0.17327214777469635,
            0.083078533411026,
            -0.1279533952474594,
            0.1197216734290123,
            -0.33444979786872864,
            0.10236197710037231
        ],
        [
            0.24894872307777405,
            0.2705577611923218,
            0.004815749824047089,
            0.19415979087352753,
            -0.34123554825782776,
            0.3137134909629822,
            0.2553599178791046,
            0.0075294021517038345,
            0.018139153718948364
        ],
        [
            0.06738588213920593,
            0.21680359542369843,
            0.023116059601306915,
            0.1992410272359848,
            0.01610155776143074,
            0.23365472257137299,
            0.28979790210723877,
            -0.07381456345319748,
            0.2559036314487457
        ],
        [
            -0.1172163337469101,
            -0.03643231466412544,
            0.3312314450740814,
            0.15979765355587006,
            -0.22794011235237122,
            0.3561195433139801,
            -0.2821769416332245,
            -0.046177495270967484,
            -0.1027386263012886
        ],
        [
            0.12287077307701111,
            0.027081917971372604,
            -0.2819806933403015,
            0.22727127373218536,
            0.07240989804267883,
            0.10585513710975647,
            0.20888224244117737,
            -0.1095549538731575,
            0.046606630086898804
        ],
        [
            0.11055325716733932,
            0.34286177158355713,
            -0.27512335777282715,
            -0.18103808164596558,
            0.24530385434627533,
            0.2219986766576767,
            0.014567969366908073,
            -0.1360296756029129,
            0.020615223795175552
        ],
        [
            -0.1907947063446045,
            0.040980588644742966,
            -0.2463560849428177,
            0.03599099814891815,
            0.03604518249630928,
            0.15854281187057495,
            0.04504256322979927,
            -0.23116110265254974,
            -0.27406081557273865
        ],
        [
            0.05692288279533386,
            0.11407886445522308,
            0.29952603578567505,
            -0.04473347216844559,
            -0.2623103857040405,
            -0.010769661515951157,
            0.3300325572490692,
            -0.27995026111602783,
            0.13313397765159607
        ],
        [
            -0.20715251564979553,
            0.03265887498855591,
            -0.0031787308398634195,
            -0.06224048510193825,
            -0.08767496794462204,
            0.2890421748161316,
            0.32387301325798035,
            -0.33797675371170044,
            0.12530668079853058
        ],
        [
            0.3117915689945221,
            0.20962026715278625,
            -0.06364858895540237,
            -0.2712501883506775,
            0.20194101333618164,
            -0.2716262638568878,
            0.04650645703077316,
            0.3310811221599579,
            0.06298942863941193
        ],
        [
            -0.09046325087547302,
            0.2297816127538681,
            -0.17753484845161438,
            -0.1633017659187317,
            0.24594485759735107,
            -0.16342021524906158,
            0.1798313707113266,
            -0.18345221877098083,
            -0.17713215947151184
        ],
        [
            0.36392512917518616,
            0.08278319984674454,
            -0.29260724782943726,
            0.1153838038444519,
            0.13376520574092865,
            -0.21584369242191315,
            -0.03361748158931732,
            -0.07222693413496017,
            -0.23860475420951843
        ],
        [
            -0.08493699133396149,
            -0.12753048539161682,
            0.22829513251781464,
            -0.14331571757793427,
            -0.16037756204605103,
            -0.09065018594264984,
            -0.34567990899086,
            -0.2511698603630066,
            0.303376704454422
        ],
        [
            -0.21290919184684753,
            0.31408366560935974,
            0.09491052478551865,
            0.08925241976976395,
            0.1091952994465828,
            -0.2121846228837967,
            0.22874103486537933,
            -0.026352396234869957,
            -0.2640395164489746
        ],
        [
            -0.21553198993206024,
            0.19057735800743103,
            -0.19164368510246277,
            0.020101357251405716,
            0.10910049825906754,
            0.06869935244321823,
            0.27862459421157837,
            0.03152861446142197,
            -0.19318439066410065
        ],
        [
            -0.20114539563655853,
            -0.2572961747646332,
            0.2542143762111664,
            -0.190023273229599,
            0.030408602207899094,
            -0.2348552793264389,
            -0.16819128394126892,
            -0.10109667479991913,
            0.06541832536458969
        ],
        [
            -0.1375580132007599,
            0.1963333785533905,
            -0.3648633658885956,
            0.1815887838602066,
            0.19569332897663116,
            0.054892007261514664,
            -0.05716326832771301,
            0.039814967662096024,
            -0.11922500282526016
        ],
        [
            0.13559626042842865,
            0.07383735477924347,
            -0.17540329694747925,
            0.30087146162986755,
            -0.3476710021495819,
            -0.04844062775373459,
            0.02995654195547104,
            -8.010592864593491e-05,
            0.2618775963783264
        ],
        [
            0.00779246399179101,
            0.2548685371875763,
            0.13119353353977203,
            0.07120649516582489,
            -0.010823278687894344,
            -0.09155303984880447,
            0.12253884226083755,
            -0.011009818874299526,
            0.21312066912651062
        ],
        [
            -0.2671521306037903,
            -0.09903477132320404,
            0.10897229611873627,
            0.16124165058135986,
            -0.32280200719833374,
            0.09083015471696854,
            0.3390331566333771,
            0.03537832945585251,
            -0.3227432370185852
        ],
        [
            -0.35744231939315796,
            0.25345632433891296,
            -0.011778500862419605,
            -0.29746073484420776,
            0.12278930097818375,
            0.0154498852789402,
            0.32524850964546204,
            0.09629428386688232,
            0.18470841646194458
        ],
        [
            -0.21627472341060638,
            -0.2960582673549652,
            -0.3366584777832031,
            -0.24863159656524658,
            -0.1158507913351059,
            0.12756110727787018,
            -0.2989550828933716,
            -0.12401770055294037,
            0.09109637141227722
        ],
        [
            -0.2622941732406616,
            0.3226608335971832,
            -0.21295219659805298,
            -0.21168716251850128,
            -0.32632869482040405,
            -0.11698270589113235,
            0.13834477961063385,
            -0.18659508228302002,
            0.23302267491817474
        ],
        [
            -0.21525877714157104,
            -0.1732885092496872,
            0.36567485332489014,
            0.16462799906730652,
            -0.2486313134431839,
            -0.009816613048315048,
            -0.26586151123046875,
            -0.1634349524974823,
            0.07695849984884262
        ],
        [
            -0.06238722801208496,
            -0.31753772497177124,
            0.24539527297019958,
            -0.15482349693775177,
            -0.08921317011117935,
            -0.22084060311317444,
            -0.16910409927368164,
            -0.1708250492811203,
            0.06988595426082611
        ],
        [
            0.07148698717355728,
            0.21638259291648865,
            -0.007665832061320543,
            -0.32400694489479065,
            0.31318199634552,
            0.16436195373535156,
            -0.3539356291294098,
            0.019292699173092842,
            0.18614569306373596
        ],
        [
            -0.1814601570367813,
            -0.17042967677116394,
            0.14934834837913513,
            -0.2408306896686554,
            0.30840885639190674,
            0.10678058117628098,
            0.17680026590824127,
            -0.21105049550533295,
            0.04141053929924965
        ],
        [
            -0.13656874001026154,
            -0.2098277509212494,
            0.1349230855703354,
            0.16776476800441742,
            0.06053389981389046,
            0.1842750459909439,
            -0.35917845368385315,
            0.08202644437551498,
            0.0966767743229866
        ],
        [
            -0.28025707602500916,
            -0.09140422195196152,
            -0.07295847684144974,
            -0.32027679681777954,
            0.15579643845558167,
            -0.11407236754894257,
            0.13603217899799347,
            0.23984697461128235,
            0.08429642766714096
        ],
        [
            -0.2851252257823944,
            -0.29254522919654846,
            0.3040252923965454,
            0.024287229403853416,
            -0.22689034044742584,
            0.01941434107720852,
            0.13368307054042816,
            -0.07799956947565079,
            -0.020114662125706673
        ],
        [
            0.03215523809194565,
            0.19974932074546814,
            0.17756287753582,
            0.11480914056301117,
            0.2795879542827606,
            -0.35624489188194275,
            0.08969371765851974,
            -0.15666764974594116,
            -0.13745898008346558
        ],
        [
            -0.019133178517222404,
            -0.10987697541713715,
            -0.16189761459827423,
            0.00128009426407516,
            -0.08471034467220306,
            -0.2524927854537964,
            -0.08335284888744354,
            0.21135927736759186,
            -0.3145541548728943
        ],
        [
            0.2830348014831543,
            0.2920109033584595,
            0.13864049315452576,
            -0.0317339152097702,
            0.304986834526062,
            0.04316353797912598,
            -0.2546590566635132,
            -0.05605163052678108,
            -0.21415935456752777
        ],
        [
            0.0063901906833052635,
            0.2635200023651123,
            0.2590251863002777,
            0.28314974904060364,
            -0.21256250143051147,
            -0.3374914228916168,
            -0.3187219500541687,
            -0.1999506950378418,
            0.009559948928654194
        ],
        [
            -0.010266591794788837,
            0.09378116577863693,
            -0.050129108130931854,
            0.14642342925071716,
            0.17300377786159515,
            0.1299808770418167,
            -0.19306771457195282,
            0.1665625423192978,
            -0.011554771102964878
        ],
        [
            -0.19875463843345642,
            0.09328241646289825,
            -0.07594932615756989,
            -0.19158935546875,
            -0.27101603150367737,
            0.15956038236618042,
            -0.2479325383901596,
            0.28100717067718506,
            -0.009563282132148743
        ],
        [
            -0.0027696324978023767,
            -0.046302780508995056,
            -0.31478050351142883,
            -0.16349981725215912,
            -0.2805478870868683,
            -0.29117271304130554,
            0.11364561319351196,
            0.33349475264549255,
            -0.3166329562664032
        ],
        [
            0.2344818413257599,
            -0.2446223795413971,
            -0.24085794389247894,
            -0.06814397126436234,
            0.06931786984205246,
            0.0568142831325531,
            0.3382229804992676,
            -0.26673227548599243,
            -0.15433408319950104
        ],
        [
            0.04089390113949776,
            0.046256501227617264,
            0.3255728781223297,
            0.31891363859176636,
            -0.030612999573349953,
            0.3378536105155945,
            -0.3289681077003479,
            0.056017667055130005,
            -0.10854551941156387
        ],
        [
            -0.11059683561325073,
            -0.15927636623382568,
            0.11580133438110352,
            0.22530564665794373,
            0.05481439083814621,
            -0.08574885129928589,
            0.17496971786022186,
            0.058850158005952835,
            -0.20383983850479126
        ],
        [
            0.30121538043022156,
            0.04686934873461723,
            0.025159772485494614,
            -0.3550475835800171,
            0.26561206579208374,
            -0.007068274077028036,
            0.022015780210494995,
            -0.10995974391698837,
            0.1598178595304489
        ],
        [
            -0.2657473683357239,
            0.1368366926908493,
            -0.0943710207939148,
            -0.32836320996284485,
            0.31948354840278625,
            0.24269410967826843,
            -0.15237930417060852,
            0.09176623821258545,
            -0.11930279433727264
        ],
        [
            0.23593774437904358,
            -0.1619095802307129,
            0.0033242423087358475,
            -0.35856693983078003,
            0.0426347590982914,
            -0.13086524605751038,
            0.0660325288772583,
            -0.31441056728363037,
            -0.11906663328409195
        ],
        [
            0.07622727006673813,
            0.04496406391263008,
            -0.20801284909248352,
            0.3314996361732483,
            -0.21888874471187592,
            -0.2284558266401291,
            0.23061373829841614,
            0.03934980183839798,
            0.04793521761894226
        ],
        [
            0.1754300743341446,
            0.26650017499923706,
            0.16822898387908936,
            -0.19920337200164795,
            -0.099330835044384,
            0.2664066255092621,
            -0.10360405594110489,
            0.23769065737724304,
            0.2421247810125351
        ],
        [
            0.11206699162721634,
            -0.07320956140756607,
            0.022165236994624138,
            -0.12772367894649506,
            0.011264500208199024,
            0.13075965642929077,
            -0.1266671121120453,
            -0.3557521104812622,
            0.13828910887241364
        ],
        [
            0.253999263048172,
            0.10441683977842331,
            -0.14837396144866943,
            0.17500440776348114,
            -0.12197399884462357,
            -0.18312007188796997,
            -0.22122177481651306,
            0.11274202913045883,
            0.3001737892627716
        ],
        [
            0.1493920087814331,
            -0.1238778829574585,
            -0.1846432089805603,
            -0.03728724643588066,
            -0.015339339151978493,
            -0.14521196484565735,
            0.0969616174697876,
            0.007007749285548925,
            -0.35033366084098816
        ],
        [
            -0.048210158944129944,
            -0.23295889794826508,
            -0.3475951552391052,
            0.3385494351387024,
            -0.07082519680261612,
            -0.026271667331457138,
            -0.0038368203677237034,
            0.2738470733165741,
            0.0672246590256691
        ],
        [
            0.14535044133663177,
            -0.20886139571666718,
            -0.26726552844047546,
            0.3071923851966858,
            0.0937003493309021,
            -0.0028773967642337084,
            -0.10928379744291306,
            -0.28782424330711365,
            -0.12384183704853058
        ],
        [
            -0.07417985051870346,
            0.018527524545788765,
            0.18585632741451263,
            -0.2407701015472412,
            0.2531026601791382,
            0.07022396475076675,
            -0.10174853354692459,
            0.264374315738678,
            -0.3571925759315491
        ],
        [
            -0.07960875332355499,
            0.08911996334791183,
            0.330597460269928,
            0.2622051537036896,
            -0.06953616440296173,
            -0.051163844764232635,
            -0.17201471328735352,
            0.031116554513573647,
            -0.3324734568595886
        ],
        [
            -0.36163559556007385,
            0.13194963335990906,
            0.12664031982421875,
            -0.08904707431793213,
            0.1812552809715271,
            0.024250412359833717,
            0.11031419783830643,
            0.0973614752292633,
            0.16756653785705566
        ],
        [
            -0.047155432403087616,
            0.14141829311847687,
            0.20920516550540924,
            0.3644362688064575,
            0.19270215928554535,
            0.09002271294593811,
            -0.09058423340320587,
            -0.30288931727409363,
            -0.14023447036743164
        ],
        [
            -0.009776366874575615,
            0.026353390887379646,
            -0.1956033706665039,
            0.003694811835885048,
            0.2677467465400696,
            -0.1766059547662735,
            -0.30678102374076843,
            -0.08187853544950485,
            0.14795710146427155
        ],
        [
            0.323222815990448,
            -0.3033916652202606,
            -0.28964725136756897,
            -0.0324460007250309,
            -0.18698984384536743,
            -0.0043043289333581924,
            -0.07420595735311508,
            0.2264637053012848,
            0.13041923940181732
        ],
        [
            0.06998635828495026,
            -0.018912633880972862,
            -0.13064321875572205,
            0.2555178999900818,
            -0.19527626037597656,
            0.04358123242855072,
            0.20545341074466705,
            -0.11328454315662384,
            0.30570802092552185
        ],
        [
            0.2203894704580307,
            0.11597181111574173,
            0.024223288521170616,
            0.22182467579841614,
            0.3315117657184601,
            -0.2934475839138031,
            0.0033710296265780926,
            0.2978997528553009,
            0.31781840324401855
        ],
        [
            0.20391720533370972,
            0.2064618319272995,
            -0.3370293378829956,
            -0.28880563378334045,
            -0.18344193696975708,
            0.1560983806848526,
            -0.29527828097343445,
            0.3468758165836334,
            -0.21775653958320618
        ],
        [
            0.2917424738407135,
            0.2694788873195648,
            -0.33159980177879333,
            -0.09895993769168854,
            -0.017296457663178444,
            -0.25723952054977417,
            -0.24539116024971008,
            -0.2428031861782074,
            -0.3185479938983917
        ],
        [
            0.3001272976398468,
            0.020055701956152916,
            0.3168293237686157,
            0.04246281087398529,
            0.033393744379282,
            0.1220712661743164,
            0.2569480836391449,
            0.3043158948421478,
            0.003082295646890998
        ],
        [
            0.045334912836551666,
            0.14305536448955536,
            -0.1816580593585968,
            -0.08548519015312195,
            -0.33651652932167053,
            0.23000209033489227,
            -0.014591041952371597,
            0.05092870071530342,
            0.13622526824474335
        ],
        [
            0.14697521924972534,
            -0.09888262301683426,
            0.22366951406002045,
            0.24181951582431793,
            -0.2766605019569397,
            -0.09463444352149963,
            0.09238380938768387,
            0.15228553116321564,
            0.09471607208251953
        ],
        [
            -0.2177942991256714,
            0.1909685581922531,
            -0.19239218533039093,
            0.07800740748643875,
            -0.36769428849220276,
            -0.246000736951828,
            -0.2810664176940918,
            -0.0556042306125164,
            -0.25937211513519287
        ],
        [
            -0.13542835414409637,
            -0.02861770987510681,
            0.024818507954478264,
            -0.3225969672203064,
            0.08420975506305695,
            -0.034228090196847916,
            -0.3220885694026947,
            -0.3532155752182007,
            0.08632016181945801
        ],
        [
            -0.25539687275886536,
            0.04304778575897217,
            -0.35710465908050537,
            -0.07361898571252823,
            0.29055285453796387,
            -0.31301361322402954,
            -0.07585611194372177,
            0.005233154632151127,
            -0.1439623236656189
        ],
        [
            0.1253078430891037,
            -0.23035597801208496,
            0.152923583984375,
            0.26936206221580505,
            -0.29784759879112244,
            0.2779434323310852,
            -0.30019810795783997,
            -0.09202578663825989,
            -0.31723180413246155
        ],
        [
            -0.353146493434906,
            0.19700130820274353,
            -0.2447805553674698,
            -0.2165374904870987,
            -0.005396050866693258,
            -0.0979781374335289,
            0.07408247888088226,
            0.06317317485809326,
            -0.16937729716300964
        ],
        [
            0.1636831909418106,
            0.32080313563346863,
            -0.2166614830493927,
            0.20901460945606232,
            -0.029742224141955376,
            0.3601851165294647,
            -0.28060129284858704,
            0.1905490905046463,
            0.25129514932632446
        ],
        [
            -0.13733376562595367,
            0.10079485923051834,
            -0.2325059026479721,
            0.17731013894081116,
            -0.022091636434197426,
            0.11307958513498306,
            -0.21642673015594482,
            -0.11760561913251877,
            -0.300215482711792
        ],
        [
            0.2158634513616562,
            -0.25682926177978516,
            0.18360251188278198,
            0.26792633533477783,
            0.22242401540279388,
            -0.15271788835525513,
            0.2906648516654968,
            0.1703452169895172,
            -0.18669746816158295
        ],
        [
            0.1349051594734192,
            0.11306633055210114,
            -0.039412956684827805,
            -0.05924099311232567,
            0.21936865150928497,
            -0.2894591689109802,
            0.009823924861848354,
            -0.021066248416900635,
            0.15750610828399658
        ],
        [
            -0.02414323017001152,
            0.31528833508491516,
            -0.2080928236246109,
            -0.16840051114559174,
            0.25223609805107117,
            0.0006614807643927634,
            -0.28892844915390015,
            0.3385494649410248,
            -0.28909966349601746
        ],
        [
            -0.03788154572248459,
            -0.20339283347129822,
            -0.26593899726867676,
            0.20899315178394318,
            0.011840294115245342,
            -0.22691872715950012,
            0.34776771068573,
            -0.18720687925815582,
            0.29699647426605225
        ],
        [
            0.2923351526260376,
            -0.1728002429008484,
            0.11894688010215759,
            -0.3001392185688019,
            -0.29375630617141724,
            0.13274042308330536,
            -0.17102253437042236,
            -0.29679664969444275,
            0.16507117450237274
        ],
        [
            -0.15581746399402618,
            0.04293438047170639,
            0.03654804080724716,
            0.17441871762275696,
            -0.3231886029243469,
            0.13429467380046844,
            0.19806574285030365,
            -0.318670392036438,
            0.06824894994497299
        ],
        [
            0.13886088132858276,
            0.3468126356601715,
            0.09976279735565186,
            -0.007557884324342012,
            -0.1119554191827774,
            0.13619644939899445,
            -0.20410987734794617,
            -0.25948721170425415,
            0.35449114441871643
        ],
        [
            0.10113953053951263,
            -0.01698695495724678,
            -0.1053009033203125,
            0.2865740954875946,
            -0.0349336601793766,
            -0.2556310296058655,
            0.32621192932128906,
            -0.21861617267131805,
            0.2559136152267456
        ],
        [
            -0.012226109392940998,
            -0.17994670569896698,
            0.30733415484428406,
            0.026185812428593636,
            0.15525776147842407,
            0.19367989897727966,
            0.07123687863349915,
            0.012208143249154091,
            0.18408611416816711
        ],
        [
            -0.039463434368371964,
            -0.08443477004766464,
            -0.1950267106294632,
            -0.04753946512937546,
            -0.1020239070057869,
            0.323699027299881,
            -0.3147267997264862,
            0.2688608467578888,
            0.15429693460464478
        ],
        [
            -0.0766831710934639,
            -0.054852090775966644,
            -0.08707498759031296,
            -0.0026874353643506765,
            0.12797047197818756,
            0.08996552228927612,
            0.36410200595855713,
            0.07491833716630936,
            0.24153873324394226
        ],
        [
            0.2565562129020691,
            0.13874372839927673,
            0.03164033964276314,
            0.03746035695075989,
            -0.07420527189970016,
            -0.16965575516223907,
            -0.1957245171070099,
            -0.26242199540138245,
            0.29056572914123535
        ],
        [
            -0.252248078584671,
            -0.27534711360931396,
            0.24840404093265533,
            0.2631629705429077,
            -0.25299546122550964,
            0.357257217168808,
            -0.16819949448108673,
            0.13006655871868134,
            -0.0700918436050415
        ],
        [
            -0.039015717804431915,
            -0.27810218930244446,
            -0.17395755648612976,
            0.14643335342407227,
            -0.12913556396961212,
            -0.16797029972076416,
            0.3306306302547455,
            0.00880501139909029,
            0.011080465279519558
        ],
        [
            0.3189672827720642,
            0.317552387714386,
            -0.30586710572242737,
            0.15566691756248474,
            0.0268254391849041,
            -0.3332329988479614,
            -0.0432337149977684,
            0.28691014647483826,
            0.32718774676322937
        ],
        [
            -0.07008109986782074,
            0.25421664118766785,
            -0.04834337532520294,
            -0.23039573431015015,
            0.1418309062719345,
            0.31505534052848816,
            0.20270703732967377,
            -0.045949287712574005,
            0.09088792651891708
        ],
        [
            -0.010240298695862293,
            0.17910733819007874,
            0.3411836624145508,
            0.1762171983718872,
            0.23653577268123627,
            -0.3314259946346283,
            -0.38144782185554504,
            0.16148638725280762,
            0.0321059450507164
        ],
        [
            -0.0339660681784153,
            0.025434894487261772,
            -0.09285280108451843,
            0.025146814063191414,
            0.17370641231536865,
            -0.2878328263759613,
            0.058834973722696304,
            -0.2297080159187317,
            0.006383311469107866
        ],
        [
            -0.15984013676643372,
            -0.3294348120689392,
            -0.2750031054019928,
            0.33211618661880493,
            0.06768922507762909,
            -0.3221275210380554,
            -0.22910261154174805,
            -0.3079509735107422,
            -0.15863929688930511
        ],
        [
            -0.14271600544452667,
            -0.18331153690814972,
            -0.31177031993865967,
            0.040903203189373016,
            -0.3336961269378662,
            0.3209832012653351,
            0.2592040002346039,
            0.19919423758983612,
            -0.03353383392095566
        ],
        [
            0.12817758321762085,
            0.11077351123094559,
            -0.2796081602573395,
            -0.036239270120859146,
            -0.1739400178194046,
            -0.3547290563583374,
            0.2822180986404419,
            0.3273683190345764,
            -0.2932812571525574
        ],
        [
            -0.2128206044435501,
            -0.2301122099161148,
            -0.1636703759431839,
            -0.016573753207921982,
            -0.15365028381347656,
            -0.15918557345867157,
            -0.003548430046066642,
            -0.03249046951532364,
            -0.25444266200065613
        ],
        [
            0.06689584255218506,
            0.2851191759109497,
            0.10382744669914246,
            -0.2872309982776642,
            0.05428691208362579,
            -0.09056169539690018,
            -0.02723364345729351,
            -0.20953260362148285,
            -0.05450611189007759
        ],
        [
            0.07073456048965454,
            0.3425368070602417,
            0.21001285314559937,
            -0.0685843974351883,
            0.1860368251800537,
            0.2405681014060974,
            0.1618119478225708,
            0.015786975622177124,
            0.02948451228439808
        ],
        [
            -0.1340281218290329,
            0.2331065833568573,
            -0.341607004404068,
            0.3333665132522583,
            -0.17314580082893372,
            -0.03519406169652939,
            0.1271965503692627,
            -0.025206632912158966,
            -0.25908002257347107
        ],
        [
            0.0643952488899231,
            0.11917493492364883,
            0.21973927319049835,
            0.08769656717777252,
            -0.20302774012088776,
            -0.10037219524383545,
            -0.15197476744651794,
            0.1412648856639862,
            0.06284236162900925
        ],
        [
            0.09785367548465729,
            -0.10503659397363663,
            0.23703618347644806,
            0.10027925670146942,
            0.3019723892211914,
            0.10301873087882996,
            -0.3597840368747711,
            -0.29660099744796753,
            0.1215311735868454
        ],
        [
            -0.2706070840358734,
            -0.1642058938741684,
            0.287110298871994,
            0.2796497046947479,
            -0.22342103719711304,
            0.2756255269050598,
            0.06672193109989166,
            -0.15167996287345886,
            -0.18398074805736542
        ],
        [
            -0.11606770753860474,
            -0.08951903879642487,
            0.15322352945804596,
            -0.04729538783431053,
            0.13460104167461395,
            -0.09096939861774445,
            0.035616371780633926,
            0.3018282353878021,
            0.04217694327235222
        ],
        [
            0.1628854125738144,
            -0.2403675764799118,
            -0.0740131214261055,
            -0.09224743396043777,
            0.03804605454206467,
            -0.3072011172771454,
            -0.0479484386742115,
            0.11655084788799286,
            0.060411352664232254
        ],
        [
            -0.22342908382415771,
            -0.16702872514724731,
            -0.3400691747665405,
            0.21188560128211975,
            0.1305077224969864,
            0.27490925788879395,
            -0.25972041487693787,
            0.15195399522781372,
            -0.06479410827159882
        ],
        [
            0.2573305368423462,
            0.03330574929714203,
            0.0709192082285881,
            -0.33349114656448364,
            -0.06323186308145523,
            -0.21156369149684906,
            0.165439173579216,
            -0.09397374838590622,
            -0.03249584138393402
        ],
        [
            0.19210626184940338,
            0.11272188276052475,
            0.08727635443210602,
            0.11205778270959854,
            -0.15644030272960663,
            0.3677569627761841,
            0.05806012079119682,
            -0.053077440708875656,
            -0.1175416111946106
        ],
        [
            0.22046510875225067,
            -0.36373570561408997,
            0.08175107091665268,
            -0.07276246696710587,
            -0.01710224337875843,
            0.20966657996177673,
            0.0423336997628212,
            0.2737000584602356,
            0.05425428971648216
        ],
        [
            0.005399251822382212,
            -0.23191116750240326,
            -0.151156485080719,
            0.00740140862762928,
            -0.21614642441272736,
            0.23101447522640228,
            0.22344116866588593,
            -0.09749027341604233,
            -0.345488965511322
        ],
        [
            -0.29745277762413025,
            0.14262443780899048,
            -0.010750623419880867,
            0.12352871149778366,
            0.25942420959472656,
            -0.21806280314922333,
            -0.35265040397644043,
            -0.3021949827671051,
            -0.07918964326381683
        ],
        [
            0.3423466384410858,
            0.10481896251440048,
            -0.28649890422821045,
            0.04421619325876236,
            -0.1118793711066246,
            0.223277285695076,
            -0.30309632420539856,
            -0.27371159195899963,
            0.06215008348226547
        ],
        [
            0.31602656841278076,
            0.2079305499792099,
            -0.03599216043949127,
            0.13867083191871643,
            0.32322296500205994,
            0.312921404838562,
            -0.3446159362792969,
            -0.07991331070661545,
            -0.24723142385482788
        ],
        [
            -0.06450818479061127,
            -0.2043652981519699,
            -0.2386314868927002,
            0.08207675069570541,
            0.1876317709684372,
            0.12191589176654816,
            0.15398292243480682,
            0.07821778953075409,
            0.1615612655878067
        ],
        [
            -0.37071409821510315,
            0.09924234449863434,
            -0.3049345314502716,
            0.12587742507457733,
            -0.33987677097320557,
            -0.2922598421573639,
            -0.21353189647197723,
            -0.18685530126094818,
            -0.00819449033588171
        ],
        [
            0.3179575204849243,
            -0.02140047959983349,
            0.04477621987462044,
            -0.2894360423088074,
            -0.11492782086133957,
            -0.1366647481918335,
            0.10057351738214493,
            -0.02281978912651539,
            0.1945657879114151
        ],
        [
            -0.21032607555389404,
            -0.05470762401819229,
            0.14364705979824066,
            -0.10993557423353195,
            0.12023431807756424,
            -0.24572202563285828,
            0.20970751345157623,
            -0.22529484331607819,
            0.10032099485397339
        ],
        [
            0.2000858336687088,
            0.24412856996059418,
            -0.1998603343963623,
            0.23698829114437103,
            0.24941620230674744,
            0.17003746330738068,
            -0.23973214626312256,
            0.29997554421424866,
            0.2544034421443939
        ],
        [
            -0.12817160785198212,
            -0.1457173079252243,
            -0.3411519229412079,
            0.07144389301538467,
            -0.06850802153348923,
            0.30250364542007446,
            0.2979191541671753,
            0.03726249188184738,
            -0.15138694643974304
        ],
        [
            0.0531819686293602,
            -0.1365223228931427,
            -0.10875947028398514,
            -0.07771403342485428,
            -0.3239409327507019,
            -0.22038717567920685,
            0.29959040880203247,
            -0.23602119088172913,
            -0.2874253988265991
        ],
        [
            0.02036169543862343,
            -0.13655461370944977,
            0.3330952525138855,
            0.2718467116355896,
            -0.19295252859592438,
            -0.14229127764701843,
            -0.21149976551532745,
            -0.06729689240455627,
            0.33086097240448
        ],
        [
            0.03369053453207016,
            0.3225187063217163,
            0.10125693678855896,
            0.14205387234687805,
            0.2989678978919983,
            -0.3212798237800598,
            0.11428312212228775,
            0.0052000535652041435,
            0.040122419595718384
        ],
        [
            -0.08184046298265457,
            -0.04433460161089897,
            0.13861718773841858,
            0.13476906716823578,
            0.03682979568839073,
            0.327489972114563,
            0.1043955609202385,
            0.15978290140628815,
            0.12942737340927124
        ],
        [
            0.28450822830200195,
            0.18501387536525726,
            0.21308395266532898,
            0.30877014994621277,
            -0.18764838576316833,
            0.27184149622917175,
            -0.35793793201446533,
            0.2778284549713135,
            -0.05148288980126381
        ],
        [
            0.3263135552406311,
            -0.2351197451353073,
            -0.13827459514141083,
            -0.2391253262758255,
            -0.2412634640932083,
            -0.13378652930259705,
            0.18914131820201874,
            0.2022649496793747,
            0.09464418143033981
        ],
        [
            -0.13459546864032745,
            0.04746190831065178,
            -0.2248609960079193,
            0.014557001180946827,
            -0.1372140496969223,
            0.10903898626565933,
            -0.2575611174106598,
            -0.1085875928401947,
            0.3175086975097656
        ],
        [
            0.014634598977863789,
            -0.18154653906822205,
            -0.34430739283561707,
            -0.14846614003181458,
            -0.02643202245235443,
            -0.25399449467658997,
            -0.24241285026073456,
            -0.13243967294692993,
            -0.13293716311454773
        ],
        [
            0.0736939013004303,
            0.18756143748760223,
            -0.17699480056762695,
            0.2067306637763977,
            0.03257773816585541,
            0.06046164780855179,
            0.19937686622142792,
            -0.34266290068626404,
            -0.1719682216644287
        ],
        [
            0.2631552219390869,
            0.06399331986904144,
            0.052939631044864655,
            0.0800543874502182,
            0.33846840262413025,
            -0.19357746839523315,
            0.16513533890247345,
            0.10661014914512634,
            0.1981978416442871
        ],
        [
            -0.34065648913383484,
            0.2539324462413788,
            -0.31066444516181946,
            0.10385376960039139,
            0.08818716555833817,
            -0.15149636566638947,
            0.0947001501917839,
            -0.06085594743490219,
            0.1661229431629181
        ],
        [
            0.24829000234603882,
            0.07042314112186432,
            -0.1052805706858635,
            0.20475682616233826,
            -0.23290203511714935,
            0.3230289816856384,
            0.278144508600235,
            -0.21735365688800812,
            0.029046664014458656
        ],
        [
            -0.11359251290559769,
            0.058098889887332916,
            -0.01387485209852457,
            -0.15195605158805847,
            -0.23366205394268036,
            -0.17808447778224945,
            0.12632855772972107,
            0.24803578853607178,
            -0.1126410961151123
        ],
        [
            -0.038584593683481216,
            0.0006609659758396447,
            -0.18597412109375,
            0.046889279037714005,
            -0.31357541680336,
            0.04697088524699211,
            0.23641671240329742,
            -0.026766959577798843,
            0.2602596580982208
        ],
        [
            -0.03882509842514992,
            -0.18209807574748993,
            0.27224379777908325,
            -0.21656577289104462,
            0.29351797699928284,
            -0.27120059728622437,
            -0.2199690341949463,
            -0.16543415188789368,
            -0.13272534310817719
        ],
        [
            0.11845831573009491,
            0.2462291568517685,
            0.18211719393730164,
            0.25624579191207886,
            0.08540027588605881,
            -0.15357552468776703,
            0.24896258115768433,
            -0.12700380384922028,
            -0.24041683971881866
        ],
        [
            0.3467542827129364,
            -0.2900763154029846,
            -0.3421810567378998,
            -0.1649804264307022,
            0.3037087619304657,
            -0.30039259791374207,
            -0.12309181690216064,
            -0.2553289234638214,
            -0.22500255703926086
        ],
        [
            0.25788551568984985,
            0.07234331220388412,
            -0.07834814488887787,
            0.1494617760181427,
            0.13997995853424072,
            -0.38616469502449036,
            -0.03285062685608864,
            0.31775739789009094,
            0.2862050533294678
        ],
        [
            -0.059750765562057495,
            -0.18398898839950562,
            0.2402619868516922,
            0.3034418225288391,
            0.05925878509879112,
            0.32142359018325806,
            0.028411440551280975,
            0.21850350499153137,
            0.33017921447753906
        ],
        [
            0.2588517665863037,
            -0.3061705231666565,
            0.08435429632663727,
            -0.18779467046260834,
            0.06015113368630409,
            -0.25295597314834595,
            -0.3390582799911499,
            0.2997235655784607,
            0.026641303673386574
        ],
        [
            0.2700355052947998,
            0.18375347554683685,
            0.07434079796075821,
            0.11982981115579605,
            0.19375817477703094,
            -0.19396857917308807,
            0.01335716899484396,
            -0.16615799069404602,
            -0.2770646810531616
        ],
        [
            0.32993221282958984,
            0.2704900801181793,
            0.1572403758764267,
            0.2043401002883911,
            0.3065147399902344,
            -0.28699368238449097,
            0.0912887454032898,
            0.23052838444709778,
            -0.1013147160410881
        ],
        [
            0.29043251276016235,
            0.019740233197808266,
            0.03235748037695885,
            -0.18312060832977295,
            -0.2520347237586975,
            -0.16567440330982208,
            -0.18303287029266357,
            -0.3084461987018585,
            -0.2734227180480957
        ],
        [
            -0.13646076619625092,
            -0.1611853837966919,
            0.28402045369148254,
            -0.15307645499706268,
            0.15918996930122375,
            0.018639611080288887,
            0.2803492844104767,
            0.21023611724376678,
            0.11480807512998581
        ],
        [
            -0.005404973868280649,
            -0.0707850530743599,
            0.03312397003173828,
            -0.30681926012039185,
            -0.26163896918296814,
            0.09526196122169495,
            -0.039390526711940765,
            -0.26689374446868896,
            -0.038094911724328995
        ],
        [
            -0.03922337666153908,
            0.26686254143714905,
            -0.009504145942628384,
            -0.1636088341474533,
            0.07896897941827774,
            -0.1587941199541092,
            -0.28863856196403503,
            0.2504822313785553,
            0.08933381736278534
        ],
        [
            -0.015871556475758553,
            -0.27833253145217896,
            -0.061743009835481644,
            -0.06061696633696556,
            0.1230633407831192,
            -0.24660487473011017,
            0.2949933409690857,
            0.09552287310361862,
            -0.3267263174057007
        ],
        [
            -0.12167013436555862,
            0.007160313427448273,
            -0.03196189925074577,
            -0.3481349050998688,
            -0.21498160064220428,
            -0.02741229720413685,
            -0.2149641364812851,
            0.07363814860582352,
            0.1561635434627533
        ],
        [
            0.27218812704086304,
            -0.014102606102824211,
            0.2870503067970276,
            0.18488571047782898,
            -0.03597912937402725,
            0.2420249879360199,
            0.2966373860836029,
            -0.31454798579216003,
            -0.2611696422100067
        ],
        [
            -0.30006617307662964,
            0.25397372245788574,
            -0.28116944432258606,
            -0.3024638295173645,
            -0.24529802799224854,
            0.2898874878883362,
            -0.22039800882339478,
            0.14692939817905426,
            0.18992486596107483
        ],
        [
            -0.34318092465400696,
            0.3005472421646118,
            0.2743259370326996,
            -0.017113301903009415,
            0.27375340461730957,
            0.2968786358833313,
            -0.06880257278680801,
            0.3244107663631439,
            -0.09568838775157928
        ],
        [
            -0.2499893754720688,
            -0.3107573688030243,
            -0.3492944836616516,
            0.14194555580615997,
            0.1741919219493866,
            0.21905024349689484,
            0.1130245253443718,
            0.01751410961151123,
            -0.09071272611618042
        ],
        [
            -0.23950417339801788,
            0.16953125596046448,
            -0.16320352256298065,
            0.14899761974811554,
            0.28525450825691223,
            0.09662310034036636,
            0.3059177100658417,
            0.26126500964164734,
            0.2545185387134552
        ],
        [
            -0.19417907297611237,
            -0.17938870191574097,
            -0.11834165453910828,
            0.31433314085006714,
            -0.11367372423410416,
            -0.18743620812892914,
            0.11349023133516312,
            0.1825907677412033,
            -0.13687419891357422
        ],
        [
            -0.1537388414144516,
            0.11264673620462418,
            0.3532143235206604,
            0.15177135169506073,
            -0.0340263769030571,
            -0.25398489832878113,
            -0.06512662768363953,
            -0.14577315747737885,
            0.18314728140830994
        ],
        [
            -0.08236511796712875,
            -0.16250111162662506,
            0.3088359534740448,
            0.2520797848701477,
            -0.32094886898994446,
            0.21357198059558868,
            -0.26256677508354187,
            -0.3280213177204132,
            0.24789361655712128
        ],
        [
            0.1891023814678192,
            0.239470437169075,
            -0.2379317581653595,
            0.25009965896606445,
            0.24277345836162567,
            -0.06101234629750252,
            -0.0015848176553845406,
            0.24402663111686707,
            0.3044590353965759
        ],
        [
            -0.18894948065280914,
            -0.223497673869133,
            -0.28507664799690247,
            -0.050027891993522644,
            -0.10856679826974869,
            -0.06293473392724991,
            0.34114977717399597,
            0.3453916609287262,
            0.2905799448490143
        ],
        [
            -0.17604424059391022,
            -0.011052261106669903,
            0.2889114320278168,
            -0.11183133721351624,
            0.1772955060005188,
            0.04951310157775879,
            -0.33819255232810974,
            -0.1224626749753952,
            -0.3532952070236206
        ],
        [
            0.19495989382266998,
            0.17765803635120392,
            -0.20546843111515045,
            0.12488174438476562,
            -0.14060397446155548,
            -0.016344111412763596,
            0.21859027445316315,
            0.18579715490341187,
            0.11433982849121094
        ],
        [
            0.28699958324432373,
            0.006440924014896154,
            -0.2808974087238312,
            -0.15456683933734894,
            0.141444131731987,
            -0.26302024722099304,
            0.26871928572654724,
            0.16098609566688538,
            0.2990677058696747
        ],
        [
            0.07384597510099411,
            0.07021760940551758,
            -0.3452104926109314,
            -0.14409443736076355,
            0.2976096272468567,
            -0.23017896711826324,
            0.14614428579807281,
            -0.14155220985412598,
            -0.021743152290582657
        ],
        [
            -0.3147241175174713,
            0.21071834862232208,
            -0.19872863590717316,
            -0.06804270297288895,
            -0.02625858038663864,
            0.09145267307758331,
            -0.2817825376987457,
            -0.38269612193107605,
            0.18560616672039032
        ],
        [
            0.010997178964316845,
            0.16495274007320404,
            -0.2340845912694931,
            0.31970974802970886,
            0.07966454327106476,
            -0.11572646349668503,
            -0.12391028553247452,
            -0.16552670300006866,
            -0.28074756264686584
        ],
        [
            0.008319716900587082,
            0.3033375144004822,
            0.24068772792816162,
            0.03655252605676651,
            -0.19932103157043457,
            -0.04044163599610329,
            0.07464484125375748,
            -0.03556129336357117,
            -0.2616076171398163
        ],
        [
            -0.21238291263580322,
            0.28866153955459595,
            -0.07408332824707031,
            0.05330517143011093,
            -0.13913753628730774,
            -0.16172344982624054,
            0.33970656991004944,
            0.16826602816581726,
            -0.20139603316783905
        ],
        [
            0.10688449442386627,
            0.20984815061092377,
            -0.28059935569763184,
            0.20657211542129517,
            0.028427032753825188,
            0.3511718511581421,
            0.29912135004997253,
            0.028140809386968613,
            -0.22998768091201782
        ],
        [
            0.2919236421585083,
            0.15563075244426727,
            0.013499544002115726,
            0.28440070152282715,
            -0.25802960991859436,
            -0.23820121586322784,
            0.04337295517325401,
            -0.03819416090846062,
            0.3253227472305298
        ],
        [
            -0.17162950336933136,
            -0.2541138231754303,
            0.10227342694997787,
            0.3239974081516266,
            0.06627903878688812,
            -0.2550000846385956,
            -0.04340777173638344,
            -0.040751904249191284,
            0.0936221107840538
        ],
        [
            0.3159172236919403,
            0.35744282603263855,
            -0.19856393337249756,
            0.15051932632923126,
            0.0749683529138565,
            0.31668856739997864,
            -0.15098784863948822,
            0.1389026641845703,
            0.06031322106719017
        ],
        [
            -0.18602558970451355,
            -0.2685006856918335,
            0.2575247883796692,
            -0.34409385919570923,
            -0.17621877789497375,
            -0.29403558373451233,
            -0.057695142924785614,
            0.12107381969690323,
            -0.19529061019420624
        ],
        [
            -0.2526024281978607,
            -0.09485451877117157,
            0.2807946503162384,
            -0.35583731532096863,
            -0.005112519487738609,
            -0.04233423247933388,
            -0.013402026146650314,
            0.21676282584667206,
            -0.15747767686843872
        ],
        [
            0.1943316012620926,
            0.24817346036434174,
            0.08971872180700302,
            0.16820085048675537,
            0.05659928917884827,
            -0.27312183380126953,
            0.34233906865119934,
            0.10823047161102295,
            -0.2927637994289398
        ],
        [
            0.17545905709266663,
            0.28231653571128845,
            0.09871190041303635,
            -0.03918563947081566,
            -0.3212965428829193,
            0.29969051480293274,
            -0.2324744611978531,
            -0.3419090211391449,
            -0.2755188047885895
        ],
        [
            0.21006691455841064,
            -0.25341230630874634,
            0.13989436626434326,
            0.1581263393163681,
            0.03732428699731827,
            0.21364593505859375,
            0.09199323505163193,
            0.08786161988973618,
            -0.028158919885754585
        ],
        [
            -0.0027466353494673967,
            -0.2859204113483429,
            0.1704891324043274,
            -0.03933977335691452,
            -0.2247564196586609,
            -0.32881391048431396,
            0.2790760397911072,
            -0.12291313707828522,
            -0.1277494877576828
        ],
        [
            -0.3222271203994751,
            -0.32933729887008667,
            -0.20508213341236115,
            0.1324271410703659,
            0.21777594089508057,
            0.14250728487968445,
            0.30913805961608887,
            -0.13826513290405273,
            0.015390547923743725
        ],
        [
            0.09522434324026108,
            -0.22547569870948792,
            0.26462775468826294,
            -0.19065888226032257,
            -0.3034718930721283,
            -0.29388540983200073,
            -0.04950438812375069,
            0.026051970198750496,
            -0.33306920528411865
        ],
        [
            0.0782492607831955,
            0.06336081773042679,
            -0.2217799425125122,
            -0.10311926156282425,
            0.2794705629348755,
            0.18593525886535645,
            0.19250912964344025,
            0.09826042503118515,
            -0.056519586592912674
        ],
        [
            -0.29390987753868103,
            -0.00981470849364996,
            0.3080480992794037,
            -0.15219788253307343,
            0.0042360820807516575,
            -0.02674383670091629,
            -0.29410654306411743,
            0.337576687335968,
            -0.28112077713012695
        ],
        [
            -0.2008817344903946,
            -0.07142641395330429,
            -0.33800309896469116,
            -0.13083964586257935,
            0.2566407322883606,
            -0.30480173230171204,
            -0.05693197250366211,
            0.29920774698257446,
            -0.3584267795085907
        ],
        [
            0.2913348078727722,
            -0.08037134259939194,
            0.10630422085523605,
            0.03298460319638252,
            0.12327730655670166,
            0.13036005198955536,
            -0.23139750957489014,
            -0.26941248774528503,
            0.2893292009830475
        ],
        [
            0.19761940836906433,
            -0.022087303921580315,
            0.04720137268304825,
            0.3132346570491791,
            -0.2389192432165146,
            0.32358378171920776,
            -0.29317453503608704,
            -0.3416087031364441,
            0.3753909766674042
        ],
        [
            0.22209611535072327,
            0.20085717737674713,
            0.28883713483810425,
            -0.12100169062614441,
            -0.04645499214529991,
            -0.16356094181537628,
            -0.05789072811603546,
            -0.10487592220306396,
            -0.09562591463327408
        ],
        [
            -0.0027178053278476,
            -0.013889405876398087,
            -0.2705910801887512,
            -0.24175375699996948,
            -0.38089051842689514,
            0.07964733988046646,
            0.04881603643298149,
            0.22766385972499847,
            0.027855437248945236
        ],
        [
            0.33720678091049194,
            -0.11676431447267532,
            0.26390892267227173,
            -0.03986874967813492,
            0.10230966657400131,
            -0.23856039345264435,
            -0.039372626692056656,
            -0.21437403559684753,
            0.08250743895769119
        ],
        [
            -0.02416452020406723,
            -0.05487652122974396,
            0.04085196182131767,
            -0.13414429128170013,
            0.20617341995239258,
            -0.0033877440728247166,
            0.06394003331661224,
            0.010846561752259731,
            0.020370712503790855
        ],
        [
            0.2249922752380371,
            -0.14522521197795868,
            0.1698223352432251,
            -0.1147024929523468,
            0.22178062796592712,
            0.16137681901454926,
            0.2944186329841614,
            0.2958485782146454,
            -0.07302208244800568
        ],
        [
            0.28442251682281494,
            -0.10891266912221909,
            -0.3428173363208771,
            0.16501548886299133,
            -0.26460129022598267,
            -0.05625639483332634,
            0.21629698574543,
            0.09869059175252914,
            0.08339845389127731
        ],
        [
            -0.2701605260372162,
            0.04398553445935249,
            -0.10200000554323196,
            -0.2383958101272583,
            0.17039324343204498,
            -0.298693984746933,
            0.003809105372056365,
            0.2557230591773987,
            0.0355941541492939
        ],
        [
            -0.060037147253751755,
            -0.24104762077331543,
            -0.29292500019073486,
            0.23658648133277893,
            0.123927541077137,
            0.24915693700313568,
            0.3358481824398041,
            -0.3833087980747223,
            -0.19716766476631165
        ],
        [
            0.0685487687587738,
            -0.2360009104013443,
            -0.31307101249694824,
            -0.015479115769267082,
            0.12357516586780548,
            -0.2177470177412033,
            -0.13936129212379456,
            0.009609438478946686,
            -0.3489563763141632
        ],
        [
            -0.001515611307695508,
            -0.34014323353767395,
            -0.15988031029701233,
            -0.0065992241725325584,
            -0.04554896801710129,
            -0.23109106719493866,
            0.0812542587518692,
            0.293428510427475,
            -0.035097070038318634
        ],
        [
            -0.08503977209329605,
            -0.02873467095196247,
            0.213667094707489,
            0.02054041065275669,
            -0.10098759829998016,
            -0.1298743188381195,
            0.22122445702552795,
            0.1955116093158722,
            -0.022002357989549637
        ],
        [
            0.09180483222007751,
            -0.06854274868965149,
            -0.2740522027015686,
            -0.09700144082307816,
            0.20634125173091888,
            0.22584615647792816,
            0.3125978708267212,
            -0.31395775079727173,
            -0.018514608964323997
        ],
        [
            -0.02847405895590782,
            -0.2534952759742737,
            0.19833309948444366,
            -0.2781655192375183,
            0.24683505296707153,
            0.10883515328168869,
            0.02696344070136547,
            -0.025924673303961754,
            0.18671385943889618
        ],
        [
            0.07816116511821747,
            -0.02148687094449997,
            0.35948896408081055,
            0.0008029592572711408,
            0.30894050002098083,
            0.16190451383590698,
            -0.010971760377287865,
            -0.3282559812068939,
            0.13715918362140656
        ],
        [
            0.2550196945667267,
            -0.33220309019088745,
            0.19329047203063965,
            0.05557271093130112,
            -0.1211559996008873,
            0.038025617599487305,
            0.15814627707004547,
            -0.10811770707368851,
            0.0521540641784668
        ],
        [
            0.28182876110076904,
            0.04202207922935486,
            0.2866053879261017,
            -0.3076859414577484,
            -0.014751695096492767,
            0.26544302701950073,
            -0.14010877907276154,
            -0.1723470240831375,
            0.2727093994617462
        ],
        [
            -0.19784924387931824,
            0.33861520886421204,
            -0.15002092719078064,
            -0.3224177956581116,
            0.01139838807284832,
            0.32042184472084045,
            -0.08592364192008972,
            0.24639764428138733,
            0.009496659971773624
        ],
        [
            -0.15936432778835297,
            -0.09889769554138184,
            0.13954292237758636,
            0.14982399344444275,
            0.16177819669246674,
            -0.11080805957317352,
            -0.007938452996313572,
            -0.22971896827220917,
            0.08181867003440857
        ],
        [
            0.025839677080512047,
            -0.331403911113739,
            0.08610233664512634,
            0.3258855640888214,
            0.32056039571762085,
            0.13842111825942993,
            0.1342306137084961,
            -0.0702492967247963,
            0.2701403498649597
        ],
        [
            0.14661461114883423,
            -0.1369072049856186,
            -0.06193949654698372,
            -0.33321690559387207,
            0.17860271036624908,
            0.07336605340242386,
            0.24361711740493774,
            0.254340797662735,
            0.011454527266323566
        ],
        [
            0.0675012394785881,
            0.27874863147735596,
            0.11198645830154419,
            -0.13055269420146942,
            0.1889301985502243,
            -0.22967566549777985,
            0.07383424043655396,
            -0.1873863786458969,
            -0.25230613350868225
        ],
        [
            -0.1718040555715561,
            0.10252422094345093,
            0.324441134929657,
            -0.2462470531463623,
            0.006377856247127056,
            -0.2605784237384796,
            0.3388553857803345,
            0.13864023983478546,
            -0.2301124632358551
        ],
        [
            -0.2773529291152954,
            -0.05814012140035629,
            0.12755896151065826,
            -0.21694815158843994,
            -0.04885150492191315,
            0.09696400910615921,
            -0.08171851933002472,
            0.13907155394554138,
            -0.11940211057662964
        ],
        [
            -0.04902893677353859,
            0.01917589269578457,
            0.012883522547781467,
            -0.1496216058731079,
            -0.14174184203147888,
            -0.28657153248786926,
            -0.3013477027416229,
            -0.14703981578350067,
            0.09472400695085526
        ],
        [
            0.33715659379959106,
            0.2522194981575012,
            0.06721615046262741,
            0.013847269117832184,
            -0.19962535798549652,
            0.2100352942943573,
            -0.29064276814460754,
            -0.2493806928396225,
            -0.14130304753780365
        ],
        [
            -0.04478743299841881,
            -0.24743950366973877,
            -0.005889270454645157,
            0.0731133222579956,
            -0.3178474009037018,
            0.1560627669095993,
            0.28144749999046326,
            0.24101674556732178,
            0.1257617473602295
        ],
        [
            0.11870738863945007,
            0.11028757691383362,
            -0.029367128387093544,
            -0.07517236471176147,
            -0.11020737886428833,
            0.2875168025493622,
            -0.3046872317790985,
            -0.3146652579307556,
            0.30626943707466125
        ],
        [
            -0.30918416380882263,
            0.06718449294567108,
            0.14740432798862457,
            0.22279542684555054,
            -0.20239925384521484,
            0.1118430346250534,
            -0.18560034036636353,
            -0.01383141241967678,
            0.2491779327392578
        ],
        [
            -0.151558056473732,
            0.3640589118003845,
            -0.02295517921447754,
            0.014045905321836472,
            -0.33198246359825134,
            0.023607363924384117,
            0.3163384199142456,
            -0.23798443377017975,
            0.2600077986717224
        ],
        [
            0.1066407859325409,
            -0.13322630524635315,
            0.3432276248931885,
            0.3445602059364319,
            -0.09182993322610855,
            0.19870541989803314,
            0.2729479968547821,
            -0.13181047141551971,
            0.1794479638338089
        ],
        [
            -0.18515679240226746,
            0.1893160045146942,
            -0.05721334367990494,
            0.2636730968952179,
            -0.26415306329727173,
            -0.10465981066226959,
            0.17920483648777008,
            -0.31154540181159973,
            -0.35458189249038696
        ],
        [
            0.3464689254760742,
            -0.14788153767585754,
            0.04551481083035469,
            0.27874505519866943,
            -0.18831248581409454,
            0.12424812465906143,
            0.169169083237648,
            -0.29130473732948303,
            -0.09562642872333527
        ],
        [
            0.1933480054140091,
            0.024793777614831924,
            0.1684419810771942,
            -0.07659967988729477,
            -0.06084898114204407,
            -0.29207322001457214,
            0.26064538955688477,
            -0.33825820684432983,
            -0.3305653929710388
        ],
        [
            -0.1635400950908661,
            -0.050659023225307465,
            -0.18403230607509613,
            0.3049151301383972,
            0.24251782894134521,
            -0.30130940675735474,
            0.06731955707073212,
            -0.23197785019874573,
            0.013387606479227543
        ],
        [
            0.09089814871549606,
            0.03817860782146454,
            -0.05327249690890312,
            -0.3415682911872864,
            0.19515781104564667,
            0.0480303093791008,
            -0.34389451146125793,
            0.2707626223564148,
            0.0382649190723896
        ],
        [
            0.1683930605649948,
            -0.2915208339691162,
            0.10470770299434662,
            0.2236577272415161,
            -0.25593116879463196,
            0.2127717286348343,
            0.17753125727176666,
            0.35261088609695435,
            0.24023319780826569
        ],
        [
            0.22429560124874115,
            -0.0772322341799736,
            0.1487034559249878,
            0.13153277337551117,
            -0.10635173320770264,
            -0.13485576212406158,
            -0.0905810222029686,
            -0.11356784403324127,
            -0.1687754988670349
        ],
        [
            0.13056541979312897,
            0.1058417409658432,
            0.3356057405471802,
            -0.0746941938996315,
            -0.14031808078289032,
            -0.2603544294834137,
            0.12753832340240479,
            -0.2661767303943634,
            -0.18712477385997772
        ],
        [
            0.10430936515331268,
            -0.13976606726646423,
            -0.19449418783187866,
            0.009944049641489983,
            -0.28253474831581116,
            -0.33998000621795654,
            -0.033059995621442795,
            -0.11903966963291168,
            -0.10381903499364853
        ],
        [
            -0.23201978206634521,
            0.16494780778884888,
            -0.004889175295829773,
            -0.2133965641260147,
            0.17390377819538116,
            0.06713041663169861,
            -0.18029847741127014,
            -0.19868652522563934,
            0.23885224759578705
        ],
        [
            -0.17847314476966858,
            -0.041368693113327026,
            -0.2529532313346863,
            0.34362760186195374,
            -0.1020851656794548,
            -0.21503959596157074,
            -0.23223169147968292,
            -0.025460366159677505,
            0.03657509759068489
        ],
        [
            0.25450560450553894,
            -0.3588162362575531,
            -0.08397829532623291,
            -0.28621041774749756,
            -0.10932556539773941,
            -0.19465012848377228,
            0.18517246842384338,
            0.17628872394561768,
            0.2213669717311859
        ],
        [
            -0.07825610786676407,
            0.22014175355434418,
            0.05335622280836105,
            0.3047020137310028,
            -0.21744996309280396,
            -0.13215234875679016,
            -0.11163914948701859,
            0.3444508910179138,
            0.02122035063803196
        ],
        [
            0.11909282207489014,
            -0.09947600215673447,
            -0.2866012454032898,
            0.058614592999219894,
            -0.11862906068563461,
            -0.22376053035259247,
            -0.013836322352290154,
            -0.15220791101455688,
            0.1745702177286148
        ],
        [
            0.10796776413917542,
            0.2956758439540863,
            0.18634134531021118,
            -0.12962965667247772,
            -0.04071805626153946,
            0.06337074190378189,
            -0.3169335722923279,
            0.29905521869659424,
            0.0012571864062920213
        ],
        [
            0.3201884627342224,
            0.14015363156795502,
            0.08098429441452026,
            -0.13397899270057678,
            -0.04606473818421364,
            -0.19527551531791687,
            -0.2492694854736328,
            0.06735222041606903,
            -0.17644523084163666
        ],
        [
            0.1273132860660553,
            0.2570402920246124,
            0.2831355929374695,
            0.03271107375621796,
            0.14518417418003082,
            -0.24449104070663452,
            0.2568141222000122,
            0.034959577023983,
            0.269500732421875
        ],
        [
            -0.03918205574154854,
            0.18389560282230377,
            0.2596089243888855,
            -0.31499722599983215,
            0.22218450903892517,
            0.010195784270763397,
            -0.28920120000839233,
            -0.25670865178108215,
            -0.06187240779399872
        ],
        [
            -0.05407439172267914,
            -0.0012297006323933601,
            -0.3067270815372467,
            0.2315259426832199,
            0.10272493958473206,
            -0.14002537727355957,
            -0.2838601768016815,
            0.1436997950077057,
            -0.04926599562168121
        ],
        [
            0.13131631910800934,
            0.17781558632850647,
            0.090638667345047,
            -0.20989589393138885,
            -0.2252785861492157,
            0.15008831024169922,
            -0.24087195098400116,
            -0.06015339493751526,
            0.14701694250106812
        ],
        [
            -0.011505406349897385,
            -0.11713991314172745,
            0.34785136580467224,
            -0.2916963994503021,
            -0.3147686719894409,
            -0.11101032048463821,
            0.1426292061805725,
            -0.2974233627319336,
            0.2166927009820938
        ],
        [
            -0.22623971104621887,
            0.17585286498069763,
            -0.07655633240938187,
            -0.2294677346944809,
            0.28905269503593445,
            0.09419169276952744,
            -0.04651042819023132,
            -0.21795973181724548,
            0.3126503825187683
        ],
        [
            -0.27912500500679016,
            -0.3074239194393158,
            0.19721288979053497,
            0.014413975179195404,
            0.1812131702899933,
            0.13097505271434784,
            -0.3255385458469391,
            0.2266794890165329,
            0.31780117750167847
        ],
        [
            -0.2531292736530304,
            0.12104501575231552,
            -0.22550930082798004,
            0.22797881066799164,
            -0.04376177489757538,
            0.22462615370750427,
            0.28784051537513733,
            0.048108041286468506,
            0.17182399332523346
        ],
        [
            0.3484067916870117,
            0.3414592742919922,
            -0.2914457321166992,
            0.14610739052295685,
            -0.15302634239196777,
            0.32295891642570496,
            0.13744975626468658,
            0.29628172516822815,
            0.27065354585647583
        ],
        [
            -0.22593706846237183,
            -0.1776568442583084,
            0.05190581455826759,
            -0.11109964549541473,
            -0.12124766409397125,
            0.3618314862251282,
            0.005547021050006151,
            -0.2666972875595093,
            -0.010574876330792904
        ],
        [
            -0.17332059144973755,
            -0.17269231379032135,
            -0.12470649927854538,
            0.2959803342819214,
            0.2503940165042877,
            0.03932276740670204,
            -0.2454538345336914,
            -0.012829681858420372,
            0.15764747560024261
        ],
        [
            -0.02665567770600319,
            0.1955370455980301,
            -0.29593542218208313,
            -0.07298468798398972,
            -0.22932012379169464,
            0.11690220236778259,
            -0.3745034337043762,
            -0.0899176150560379,
            0.1362377405166626
        ],
        [
            0.2537041902542114,
            -0.03768842667341232,
            -0.03328987956047058,
            0.0640113428235054,
            0.24916206300258636,
            -0.014337646774947643,
            0.18694812059402466,
            0.29844337701797485,
            -0.09331662952899933
        ],
        [
            0.29548054933547974,
            0.2869580388069153,
            0.07898546755313873,
            -0.19452770054340363,
            -0.2720029950141907,
            -0.278247594833374,
            -0.17295707762241364,
            -0.30492329597473145,
            0.2838990390300751
        ],
        [
            0.11148523539304733,
            -0.3183687925338745,
            -0.1824294477701187,
            -0.2660972476005554,
            0.03802867233753204,
            0.23038703203201294,
            0.18519315123558044,
            -0.08618612587451935,
            -0.3432926535606384
        ],
        [
            0.06740634143352509,
            0.08848876506090164,
            0.21537773311138153,
            0.3602864146232605,
            0.2555641233921051,
            -0.0740581676363945,
            -0.06234006583690643,
            0.328620582818985,
            -0.11776851862668991
        ],
        [
            -0.07716377824544907,
            0.27722877264022827,
            0.13552318513393402,
            0.2320711761713028,
            -0.052101559937000275,
            -0.051345668733119965,
            -0.19970446825027466,
            0.3217819929122925,
            0.11329783499240875
        ],
        [
            0.18205612897872925,
            -0.006683769170194864,
            0.21791528165340424,
            0.17038238048553467,
            0.12010308355093002,
            0.1285877227783203,
            0.2004808932542801,
            0.3065876364707947,
            0.2107304483652115
        ],
        [
            0.3588458001613617,
            0.13069956004619598,
            0.1416679322719574,
            -0.2255391627550125,
            -0.22239600121974945,
            0.3039121925830841,
            -0.0916837602853775,
            0.3264373540878296,
            -0.13596364855766296
        ],
        [
            0.16993092000484467,
            0.06177893653512001,
            0.2977713942527771,
            0.12217116355895996,
            0.28852182626724243,
            0.14389236271381378,
            -0.23052261769771576,
            -0.20078249275684357,
            0.24056680500507355
        ],
        [
            0.3081393539905548,
            -0.2705385982990265,
            -0.2491583675146103,
            -0.16273444890975952,
            -0.2836010456085205,
            0.21862512826919556,
            -0.09835971891880035,
            0.08266111463308334,
            -0.21218661963939667
        ],
        [
            0.11833609640598297,
            -0.07417599856853485,
            0.017696505412459373,
            0.0420776903629303,
            -0.20687267184257507,
            -0.06327269971370697,
            -0.04274539276957512,
            -0.17599257826805115,
            -0.13254231214523315
        ],
        [
            0.2978130280971527,
            0.34705787897109985,
            0.22520677745342255,
            0.06929073482751846,
            -0.07135032117366791,
            0.10709288716316223,
            0.129619300365448,
            -0.22249503433704376,
            0.21750617027282715
        ],
        [
            0.30866676568984985,
            -0.13039137423038483,
            0.24377411603927612,
            0.03367738425731659,
            0.006893246900290251,
            0.03729797899723053,
            -0.3279980421066284,
            -0.05528014525771141,
            -0.06369183957576752
        ],
        [
            -0.008152125403285027,
            -0.12109119445085526,
            0.20849913358688354,
            -0.31935036182403564,
            0.3150009214878082,
            -0.041053514927625656,
            -0.16516920924186707,
            0.25139304995536804,
            -0.12845730781555176
        ],
        [
            0.07731517404317856,
            0.10209568589925766,
            -0.13331446051597595,
            0.25155895948410034,
            -0.011539199389517307,
            0.08278484642505646,
            -0.06461764127016068,
            -0.1524326354265213,
            0.10464169830083847
        ],
        [
            0.14331908524036407,
            0.10861142724752426,
            -0.1676848977804184,
            0.06894639879465103,
            -0.3300720155239105,
            -0.34937286376953125,
            -0.056380994617938995,
            0.15819242596626282,
            -0.27994921803474426
        ],
        [
            -0.06210345774888992,
            -0.3395892381668091,
            -0.08054313063621521,
            0.06858018785715103,
            0.3237764835357666,
            -0.17440252006053925,
            0.1660323590040207,
            -0.29772329330444336,
            0.16087116301059723
        ],
        [
            0.20674672722816467,
            0.18603281676769257,
            0.04671122133731842,
            0.10968463122844696,
            0.12865287065505981,
            0.10610347986221313,
            -0.03474478796124458,
            0.012727019377052784,
            -0.13994397222995758
        ],
        [
            -0.012289798818528652,
            -0.07510313391685486,
            -0.10166852921247482,
            -0.31191784143447876,
            -0.01993750035762787,
            -0.0963548868894577,
            0.2597411274909973,
            -0.16902776062488556,
            -0.04238778352737427
        ],
        [
            0.3087872266769409,
            -0.3358774781227112,
            -0.13133488595485687,
            0.004147244617342949,
            -0.2062959223985672,
            0.1623639464378357,
            0.11530690640211105,
            -0.28935903310775757,
            0.17220887541770935
        ],
        [
            0.16589507460594177,
            -0.004738782998174429,
            -0.2351604551076889,
            0.1450379639863968,
            -0.0020875472109764814,
            0.06239384785294533,
            0.22899673879146576,
            0.25972622632980347,
            0.05315877124667168
        ],
        [
            0.18680281937122345,
            0.3139731287956238,
            -0.10893604904413223,
            -0.2656887173652649,
            0.2227901965379715,
            -0.19015397131443024,
            0.1070341169834137,
            -0.4221101701259613,
            -0.06031440570950508
        ],
        [
            0.2754468023777008,
            0.3302454948425293,
            -0.192764550447464,
            0.1343143731355667,
            -0.13815534114837646,
            -0.3210414946079254,
            -0.12402284145355225,
            0.2831750512123108,
            0.2379058450460434
        ],
        [
            -0.18545924127101898,
            0.33126387000083923,
            -0.11563845723867416,
            0.2462659478187561,
            -0.0455545037984848,
            0.27260950207710266,
            0.247360959649086,
            0.13646546006202698,
            -0.06796135008335114
        ],
        [
            0.07215216755867004,
            -0.2276572287082672,
            0.03862476348876953,
            -0.03464365005493164,
            -0.015442387200891972,
            0.3195918798446655,
            -0.08028754591941833,
            0.2902674078941345,
            -0.2632516920566559
        ],
        [
            -0.1261412352323532,
            0.25209134817123413,
            0.24942991137504578,
            0.20947015285491943,
            0.225666344165802,
            -0.07697415351867676,
            0.2313777357339859,
            0.28885379433631897,
            -0.3136999309062958
        ],
        [
            0.10784441977739334,
            0.29254981875419617,
            0.022604389116168022,
            0.018575161695480347,
            -0.2682763338088989,
            0.24160359799861908,
            0.1427215039730072,
            -0.08725401759147644,
            -0.11738897114992142
        ],
        [
            0.310220867395401,
            -0.32992321252822876,
            0.1683237999677658,
            -0.07518664747476578,
            -0.29694613814353943,
            0.0613122284412384,
            -0.009634836576879025,
            0.25210627913475037,
            0.2008015662431717
        ],
        [
            0.31685447692871094,
            -0.1574539691209793,
            -0.3224174976348877,
            0.14855331182479858,
            -0.058135028928518295,
            0.07836789637804031,
            0.14091400802135468,
            -0.09521373361349106,
            0.29048019647598267
        ],
        [
            0.11861421912908554,
            0.07623468339443207,
            0.10154921561479568,
            0.21300938725471497,
            0.2904893755912781,
            0.3192715048789978,
            0.2503734230995178,
            0.07341356575489044,
            0.008498912677168846
        ],
        [
            0.30894988775253296,
            -0.017501741647720337,
            -0.16587546467781067,
            0.032510749995708466,
            -0.2571083605289459,
            -0.20616760849952698,
            -0.13800740242004395,
            0.07759609818458557,
            0.14139577746391296
        ],
        [
            -0.17625628411769867,
            -0.15277701616287231,
            -0.172526553273201,
            -0.30971288681030273,
            0.17050288617610931,
            -0.2145197093486786,
            -0.3612075746059418,
            -0.16643938422203064,
            -0.23852477967739105
        ],
        [
            0.0384027361869812,
            0.21737933158874512,
            -0.12905247509479523,
            0.09518662095069885,
            -0.3114037811756134,
            -0.07437306642532349,
            -0.1231560930609703,
            0.23145747184753418,
            -0.2842201590538025
        ],
        [
            -0.24442847073078156,
            -0.1492035984992981,
            -0.24402496218681335,
            0.04227469488978386,
            -0.13083648681640625,
            0.3490094244480133,
            -0.00900495145469904,
            0.25884801149368286,
            -0.11809583008289337
        ],
        [
            0.2408524453639984,
            0.2881380617618561,
            0.32316118478775024,
            -0.06472637504339218,
            -0.1825314611196518,
            0.14075234532356262,
            0.13666695356369019,
            0.09795818477869034,
            0.19716425240039825
        ],
        [
            -0.21712075173854828,
            -0.0460161417722702,
            0.1259932965040207,
            -0.18756629526615143,
            -0.3287366032600403,
            -0.07687752693891525,
            0.23643404245376587,
            -0.17421910166740417,
            0.3337574005126953
        ],
        [
            -0.2737349569797516,
            -0.2266847789287567,
            -0.05022613704204559,
            0.2053147256374359,
            -0.019178291782736778,
            -0.32905513048171997,
            -0.17992368340492249,
            -0.11497200280427933,
            0.28093820810317993
        ],
        [
            -0.061977121978998184,
            -0.30837181210517883,
            0.27473878860473633,
            0.314899206161499,
            0.3449421525001526,
            -0.2284819632768631,
            0.047106657177209854,
            0.3290051519870758,
            -0.07312098145484924
        ],
        [
            0.1133519783616066,
            0.20072795450687408,
            -0.12991845607757568,
            -0.24869084358215332,
            0.2590632736682892,
            -0.3292919099330902,
            0.2361830770969391,
            0.3011455237865448,
            -0.24245603382587433
        ],
        [
            0.30051928758621216,
            -0.24486756324768066,
            0.025873707607388496,
            0.2645116448402405,
            -0.35269272327423096,
            -0.19882281124591827,
            0.06674279272556305,
            0.2969410717487335,
            0.24706554412841797
        ],
        [
            0.2677311301231384,
            0.11184629797935486,
            0.32007479667663574,
            -0.18480786681175232,
            0.2744305431842804,
            -0.20511087775230408,
            0.1097387745976448,
            -0.25993862748146057,
            -0.18194623291492462
        ],
        [
            0.02573871985077858,
            -0.07562481611967087,
            0.25164005160331726,
            -0.16977308690547943,
            0.09379083663225174,
            0.11859278380870819,
            0.16849184036254883,
            0.30210500955581665,
            -0.31359124183654785
        ],
        [
            0.1006532534956932,
            -0.16928289830684662,
            -0.16893617808818817,
            -0.09125649183988571,
            0.20392045378684998,
            0.1579223871231079,
            0.019297035411000252,
            -0.33381539583206177,
            0.16813892126083374
        ],
        [
            0.0646548867225647,
            -0.035186152905225754,
            -0.3388408124446869,
            -0.30837199091911316,
            0.0143568841740489,
            -0.023072166368365288,
            0.1392030119895935,
            0.170278400182724,
            0.04487051069736481
        ],
        [
            -0.00916214007884264,
            -0.05242748185992241,
            -0.16866865754127502,
            -0.1832362413406372,
            0.04521618038415909,
            0.29142364859580994,
            -0.2531760036945343,
            0.18060632050037384,
            -0.33323103189468384
        ],
        [
            -0.056459806859493256,
            0.3104267120361328,
            -0.1719834804534912,
            0.15771548449993134,
            0.16084031760692596,
            0.2064761370420456,
            -0.2014455497264862,
            -0.0445379875600338,
            -0.3145119547843933
        ],
        [
            -0.01050491165369749,
            0.09406448900699615,
            0.22455567121505737,
            -0.26944273710250854,
            0.0968068316578865,
            -0.03086208552122116,
            0.013911460526287556,
            -0.04914108291268349,
            0.22939671576023102
        ],
        [
            0.23443232476711273,
            0.20594128966331482,
            0.2789088785648346,
            0.1644952893257141,
            -0.16919885575771332,
            0.08566278219223022,
            0.28470417857170105,
            0.2264489084482193,
            -0.26253318786621094
        ],
        [
            -0.21647389233112335,
            0.2271222621202469,
            -0.002067429246380925,
            0.07622849196195602,
            0.10396341979503632,
            0.21044820547103882,
            0.33249256014823914,
            0.19857372343540192,
            -0.31124696135520935
        ],
        [
            0.06922643631696701,
            -0.013327889144420624,
            -0.006616728380322456,
            0.1644192636013031,
            -0.28347569704055786,
            -0.0195554718375206,
            -0.09028029441833496,
            -0.12941938638687134,
            -0.1348147839307785
        ],
        [
            -0.04492461681365967,
            0.15613797307014465,
            -0.2850501835346222,
            -0.07321541011333466,
            -0.2619841396808624,
            -0.20309261977672577,
            0.12164558470249176,
            -0.047119736671447754,
            0.037370335310697556
        ],
        [
            -0.26468080282211304,
            0.302952378988266,
            -0.05299896001815796,
            0.14241041243076324,
            0.041517142206430435,
            0.31056687235832214,
            0.07033725082874298,
            -0.06001830846071243,
            0.09829480946063995
        ],
        [
            0.004703874234110117,
            0.30127450823783875,
            -0.2573612332344055,
            -0.25731587409973145,
            -0.08515724539756775,
            -0.3213408589363098,
            0.1055283397436142,
            0.16742786765098572,
            -0.33996498584747314
        ],
        [
            -0.31635427474975586,
            0.12341969460248947,
            0.2498629242181778,
            -0.22967571020126343,
            0.028289146721363068,
            0.306689590215683,
            0.24195903539657593,
            0.221066415309906,
            -0.16337120532989502
        ],
        [
            0.2741844952106476,
            -0.19702187180519104,
            -0.21950392425060272,
            0.13599319756031036,
            -0.2429227977991104,
            0.10715334117412567,
            0.041396185755729675,
            -0.18880711495876312,
            0.3005621135234833
        ],
        [
            0.024692457169294357,
            0.325788676738739,
            0.11844836920499802,
            -0.0031565269455313683,
            -0.21759332716464996,
            -0.047862209379673004,
            -0.286714106798172,
            -0.01762605458498001,
            -0.08237767219543457
        ],
        [
            -0.07969901710748672,
            -0.1447577029466629,
            -0.2915610074996948,
            -0.10846580564975739,
            -0.2022739052772522,
            -0.13415618240833282,
            0.24352796375751495,
            0.22660893201828003,
            -0.03780542314052582
        ],
        [
            -0.3411024510860443,
            0.08675643056631088,
            0.051717184484004974,
            0.1301894634962082,
            0.04242035001516342,
            -0.04349303990602493,
            -0.15257039666175842,
            -0.07796277850866318,
            -0.01443586964160204
        ],
        [
            0.3183337450027466,
            -0.06966479867696762,
            0.3176455497741699,
            -0.2906283140182495,
            0.30992066860198975,
            0.045013245195150375,
            -0.14709629118442535,
            -0.07944514602422714,
            -0.32993993163108826
        ],
        [
            -0.09351179748773575,
            -0.25506946444511414,
            -0.14196495711803436,
            -0.21613293886184692,
            -0.20896458625793457,
            0.32335081696510315,
            -0.1136857271194458,
            -0.038673609495162964,
            -0.11983774602413177
        ],
        [
            0.08669828623533249,
            0.18823371827602386,
            0.18820321559906006,
            -0.06054653972387314,
            -0.20829464495182037,
            0.0981607437133789,
            -0.31988197565078735,
            -0.13267357647418976,
            0.12329111993312836
        ],
        [
            0.2574266195297241,
            -0.2620387375354767,
            -0.007982884533703327,
            -0.20936836302280426,
            0.1192726120352745,
            0.060605116188526154,
            -0.18836332857608795,
            0.20151887834072113,
            -0.15063072741031647
        ],
        [
            -0.04221188277006149,
            -0.27306026220321655,
            -0.2367180585861206,
            -0.26777276396751404,
            -0.13101905584335327,
            0.09659387171268463,
            -0.19683204591274261,
            0.2787089943885803,
            -0.06625787913799286
        ],
        [
            0.20662616193294525,
            -0.3935011327266693,
            -0.08943195641040802,
            -0.032411087304353714,
            0.3165525794029236,
            -0.10700200498104095,
            0.1979002058506012,
            -0.01554254163056612,
            -0.07860541343688965
        ],
        [
            -0.34121352434158325,
            -0.20594370365142822,
            -0.3558897376060486,
            0.3278745114803314,
            0.2155562937259674,
            -0.02838754653930664,
            -0.04311158508062363,
            -0.13117682933807373,
            -0.09091909974813461
        ],
        [
            0.10137874633073807,
            -0.31920233368873596,
            -0.11631632596254349,
            -0.22642937302589417,
            -0.008038194850087166,
            -0.21670067310333252,
            -0.11710131168365479,
            0.23755300045013428,
            -0.13826388120651245
        ],
        [
            -0.22292950749397278,
            -0.24223774671554565,
            0.3114354908466339,
            0.34177297353744507,
            0.03077259100973606,
            0.23492786288261414,
            0.20605754852294922,
            0.27815768122673035,
            0.27581432461738586
        ],
        [
            -0.05585716292262077,
            0.1508246809244156,
            -0.26274725794792175,
            -0.009094060398638248,
            -0.09790226072072983,
            -0.11299248039722443,
            0.2746778130531311,
            -0.024903101846575737,
            0.21983210742473602
        ],
        [
            -0.12740343809127808,
            0.31796935200691223,
            0.3351195156574249,
            0.1247689425945282,
            -0.056828517466783524,
            -0.19759222865104675,
            -0.3493773639202118,
            0.274466335773468,
            0.07951883226633072
        ],
        [
            -0.3702857494354248,
            0.026516413316130638,
            -0.030204154551029205,
            0.24297228455543518,
            -0.013767424039542675,
            0.1508486121892929,
            0.29089224338531494,
            0.25336307287216187,
            -0.08887311071157455
        ],
        [
            0.07203638553619385,
            -0.15074986219406128,
            -0.12978896498680115,
            -0.37644875049591064,
            -0.3204966187477112,
            -0.23565126955509186,
            0.254355788230896,
            -0.07337300479412079,
            -0.2254657745361328
        ],
        [
            0.22492976486682892,
            0.2900165021419525,
            0.08662918210029602,
            0.0447872094810009,
            0.1175239160656929,
            0.1647864133119583,
            -0.02948073111474514,
            0.12911605834960938,
            -0.16178381443023682
        ],
        [
            0.10790446400642395,
            -0.37001049518585205,
            0.2727024257183075,
            -0.2898551821708679,
            0.25665026903152466,
            0.15238817036151886,
            -0.2683980166912079,
            -0.15884467959403992,
            0.31983810663223267
        ],
        [
            0.006050070747733116,
            0.2707013189792633,
            0.2929075062274933,
            0.30817437171936035,
            0.002729508327320218,
            0.2790423333644867,
            -0.2571119964122772,
            0.06786925345659256,
            -0.2087687999010086
        ],
        [
            -0.24242138862609863,
            -0.34078553318977356,
            -0.26798754930496216,
            -0.034883297979831696,
            -0.07284073531627655,
            -0.38459479808807373,
            0.361176997423172,
            -0.19473907351493835,
            -0.05231856927275658
        ],
        [
            0.38632136583328247,
            -0.2793053686618805,
            0.20632398128509521,
            0.09303978085517883,
            0.09226332604885101,
            -0.2670985162258148,
            -0.07837352901697159,
            -0.33715733885765076,
            0.210378497838974
        ],
        [
            -0.04466583579778671,
            0.09885421395301819,
            -0.27650976181030273,
            -0.18044662475585938,
            0.245725616812706,
            0.207034170627594,
            0.03269098699092865,
            0.08412612974643707,
            0.33851590752601624
        ],
        [
            -0.13635632395744324,
            -0.06784757971763611,
            0.0953817069530487,
            -0.17274093627929688,
            -0.16928204894065857,
            -0.22826838493347168,
            -0.19926294684410095,
            -0.15861451625823975,
            -0.16698786616325378
        ],
        [
            0.24054601788520813,
            0.29935184121131897,
            0.10205775499343872,
            0.20572803914546967,
            0.15000785887241364,
            -0.25545698404312134,
            0.2838088572025299,
            -0.15981347858905792,
            0.03535200282931328
        ],
        [
            -0.16823290288448334,
            0.21834112703800201,
            0.003534526564180851,
            -0.025769080966711044,
            0.007003763224929571,
            0.09097833931446075,
            0.013832992874085903,
            0.21492761373519897,
            0.2745160758495331
        ],
        [
            0.1566641926765442,
            -0.2592991292476654,
            -0.16525372862815857,
            -0.19434939324855804,
            -0.04644482210278511,
            0.13338826596736908,
            0.36255282163619995,
            -0.2171773910522461,
            0.31009456515312195
        ],
        [
            -0.33538588881492615,
            -0.24993166327476501,
            -0.29339367151260376,
            -0.2576341927051544,
            -0.2983592450618744,
            0.3004799783229828,
            -0.2373664528131485,
            0.37165847420692444,
            -0.22629238665103912
        ],
        [
            0.29002201557159424,
            -0.10521838068962097,
            0.23426958918571472,
            -0.34512394666671753,
            0.24206753075122833,
            0.16884468495845795,
            -0.21639597415924072,
            -0.11915174126625061,
            -0.09633854031562805
        ],
        [
            -0.2020971179008484,
            -0.30059152841567993,
            -0.22498951852321625,
            0.06754198670387268,
            0.010598160326480865,
            -0.0827031135559082,
            0.284775972366333,
            0.1450377255678177,
            -0.08147852122783661
        ],
        [
            0.11852451413869858,
            -0.06770472228527069,
            0.3275686800479889,
            0.25236037373542786,
            -0.09799382835626602,
            0.03768688812851906,
            -0.17463606595993042,
            -0.22242103517055511,
            -0.38404709100723267
        ],
        [
            -0.3024204969406128,
            -0.19112758338451385,
            -0.17898184061050415,
            0.307569295167923,
            -0.3361021876335144,
            -0.02117224782705307,
            -0.15730378031730652,
            -0.31585198640823364,
            -0.1979164481163025
        ],
        [
            -0.2926503121852875,
            -0.19175049662590027,
            0.28226372599601746,
            0.11027681827545166,
            -0.20395566523075104,
            -0.21682007610797882,
            -0.14232929050922394,
            0.32741451263427734,
            0.10106609016656876
        ],
        [
            -0.30363112688064575,
            -0.24035446345806122,
            -0.2777421474456787,
            0.051619190722703934,
            -0.05566764622926712,
            -0.0982445478439331,
            0.15941688418388367,
            0.2317858338356018,
            0.08438611775636673
        ],
        [
            0.02826458029448986,
            -0.3256838321685791,
            -0.03594088554382324,
            -0.19503606855869293,
            -0.043974265456199646,
            -0.23415052890777588,
            -0.22427783906459808,
            -0.13129527866840363,
            -0.23330281674861908
        ],
        [
            0.07497967779636383,
            0.1812138706445694,
            0.15035666525363922,
            -0.2345137894153595,
            -0.04645499214529991,
            0.3198564648628235,
            -0.167053684592247,
            -0.08682327717542648,
            -0.15961728990077972
        ],
        [
            0.2043745070695877,
            -0.30280640721321106,
            0.28656306862831116,
            0.029241809621453285,
            -0.3101566731929779,
            -0.09121987968683243,
            0.23598112165927887,
            0.3255119025707245,
            -0.23048202693462372
        ],
        [
            0.3530036509037018,
            -0.038664862513542175,
            -0.3260934054851532,
            -0.23575563728809357,
            -0.23596233129501343,
            -0.27889811992645264,
            -0.3296760320663452,
            0.3389637768268585,
            -0.02452721633017063
        ],
        [
            -0.12176939100027084,
            0.08586964756250381,
            0.13511855900287628,
            -0.3584836423397064,
            -0.09998714178800583,
            -0.007637726608663797,
            0.31501972675323486,
            0.032870322465896606,
            0.19308945536613464
        ],
        [
            -0.2965887784957886,
            -0.13970962166786194,
            -0.16005432605743408,
            -0.33951103687286377,
            -0.10224296152591705,
            0.2745724618434906,
            0.26886820793151855,
            0.25939255952835083,
            -0.19668617844581604
        ],
        [
            -0.20998194813728333,
            -0.033753544092178345,
            0.17047517001628876,
            -0.014035013504326344,
            0.1183379590511322,
            -0.25333812832832336,
            -0.0880984216928482,
            -0.1387849897146225,
            -0.15849682688713074
        ],
        [
            0.08221393078565598,
            0.3025025427341461,
            -0.37387558817863464,
            -0.1556980460882187,
            0.18546561896800995,
            0.13648320734500885,
            0.1016102209687233,
            0.12695202231407166,
            0.36739906668663025
        ],
        [
            0.15542304515838623,
            -0.03376464918255806,
            -0.11119394749403,
            -0.16361990571022034,
            0.11953706294298172,
            -0.21472831070423126,
            0.21139858663082123,
            0.18266291916370392,
            0.18076066672801971
        ],
        [
            0.029175404459238052,
            0.2652497887611389,
            -0.08096333593130112,
            -0.123213991522789,
            0.043526217341423035,
            0.030833495780825615,
            -0.33904218673706055,
            -0.1821405291557312,
            -0.23467902839183807
        ],
        [
            -0.09589764475822449,
            -0.06063971668481827,
            0.3153693675994873,
            -0.06880878657102585,
            0.2944071590900421,
            -0.10743094235658646,
            0.2599847912788391,
            -0.12132038921117783,
            -0.1749849021434784
        ],
        [
            -0.12562572956085205,
            0.006220412906259298,
            0.3045099377632141,
            0.06770776212215424,
            0.09646035730838776,
            0.09538096189498901,
            0.07481343299150467,
            0.20098353922367096,
            -0.31775394082069397
        ],
        [
            0.27225592732429504,
            -0.09860710799694061,
            -0.07165601849555969,
            -0.029887665063142776,
            -0.09139545261859894,
            0.1512414813041687,
            -0.21676243841648102,
            -0.31606459617614746,
            -0.3280794620513916
        ],
        [
            -0.02625209093093872,
            0.027024146169424057,
            0.14196932315826416,
            -0.25330936908721924,
            0.06560961157083511,
            0.038620542734861374,
            0.12723800539970398,
            -0.22206853330135345,
            0.16991478204727173
        ],
        [
            0.08123741298913956,
            0.08175225555896759,
            0.2874075472354889,
            -0.10461341589689255,
            0.20480063557624817,
            0.3011398911476135,
            0.16675788164138794,
            0.000996592571027577,
            0.2031947821378708
        ],
        [
            0.2971824109554291,
            -0.2015521228313446,
            0.11976498365402222,
            -0.057692401111125946,
            0.25371986627578735,
            0.2572817802429199,
            0.2920428514480591,
            0.12392115592956543,
            -0.015598789788782597
        ],
        [
            -0.04536682367324829,
            0.15383271872997284,
            0.11168838292360306,
            -0.3718723654747009,
            0.23822376132011414,
            -0.21564514935016632,
            0.18656203150749207,
            0.27459171414375305,
            0.06880056858062744
        ],
        [
            -0.04808901622891426,
            -0.31324419379234314,
            0.1357608437538147,
            -0.02498696744441986,
            -0.20166312158107758,
            0.20747800171375275,
            -0.0027178784366697073,
            0.310610294342041,
            -0.08315221220254898
        ],
        [
            -0.35549870133399963,
            0.27261364459991455,
            0.27972662448883057,
            0.2785983383655548,
            -0.05107898265123367,
            -0.1132010892033577,
            -0.07967719435691833,
            -0.26604965329170227,
            0.23078611493110657
        ],
        [
            0.2036197930574417,
            -0.04438088834285736,
            -0.26009228825569153,
            -0.05592168867588043,
            -0.1457260400056839,
            -0.23645265400409698,
            0.2455965280532837,
            -0.29780879616737366,
            0.3157154321670532
        ],
        [
            0.1407044529914856,
            0.35498255491256714,
            0.16404123604297638,
            0.07493720948696136,
            -0.12194967269897461,
            0.23861722648143768,
            0.09816235303878784,
            -0.215383380651474,
            0.09224534779787064
        ],
        [
            3.843743343168171e-06,
            0.017408348619937897,
            -0.15618979930877686,
            -0.2529600262641907,
            -0.09926505386829376,
            0.16385385394096375,
            0.2584764063358307,
            0.07542090117931366,
            0.23304958641529083
        ],
        [
            -0.19881483912467957,
            -0.11609426885843277,
            0.16789621114730835,
            -0.022656358778476715,
            -0.3102770149707794,
            -0.055572446435689926,
            0.18599210679531097,
            0.09584296494722366,
            0.06004568189382553
        ],
        [
            0.043880704790353775,
            -0.3634357154369354,
            0.16672451794147491,
            0.022081030532717705,
            0.10393576323986053,
            0.20426712930202484,
            0.14004255831241608,
            -0.054280877113342285,
            -0.16646148264408112
        ],
        [
            0.008163933642208576,
            0.17979592084884644,
            -0.2826487720012665,
            0.0591144934296608,
            -0.08521255105733871,
            0.19979992508888245,
            -0.06261911243200302,
            0.0029425546526908875,
            -0.0373234786093235
        ],
        [
            -0.16248176991939545,
            -0.12056589126586914,
            0.3149036169052124,
            -0.19342178106307983,
            -0.050095029175281525,
            0.27019572257995605,
            -0.1596829891204834,
            -0.09655529260635376,
            -0.24203842878341675
        ],
        [
            0.08629164844751358,
            0.2953588664531708,
            -0.26478463411331177,
            0.296989381313324,
            0.3388565480709076,
            -0.2700623571872711,
            -0.24833983182907104,
            0.09890787303447723,
            -0.2521698772907257
        ],
        [
            0.17588146030902863,
            -0.17953728139400482,
            -0.2982752323150635,
            0.08663396537303925,
            -0.19202840328216553,
            0.31051671504974365,
            0.10946965217590332,
            0.06854439526796341,
            0.2160007804632187
        ],
        [
            -0.24387922883033752,
            -0.3047899603843689,
            0.2087414562702179,
            -0.21139419078826904,
            0.2096789926290512,
            0.3235055208206177,
            -0.23577238619327545,
            -0.1755192130804062,
            0.01311921514570713
        ],
        [
            -0.06068635731935501,
            0.0185101255774498,
            0.02020656317472458,
            -0.05996621772646904,
            0.2466309368610382,
            0.11373704671859741,
            -0.19177760183811188,
            -0.1541566699743271,
            -0.320758581161499
        ],
        [
            0.12288282066583633,
            -0.16733092069625854,
            -0.17091813683509827,
            -0.28963470458984375,
            0.3056018650531769,
            0.09054646641016006,
            -0.11529259383678436,
            -0.1601611226797104,
            -0.10187765210866928
        ],
        [
            0.10262826830148697,
            0.07663944363594055,
            0.07912169396877289,
            0.1779920905828476,
            0.0741647407412529,
            -0.11950179189443588,
            -0.21471616625785828,
            -0.04189658537507057,
            -0.2848244607448578
        ],
        [
            -0.2779591679573059,
            -0.13619641959667206,
            0.3058112859725952,
            -0.04824504628777504,
            -0.27322670817375183,
            0.22960416972637177,
            0.1209983080625534,
            -0.155247762799263,
            -0.04954316467046738
        ],
        [
            -0.025823432952165604,
            0.03128359839320183,
            -0.2331971526145935,
            0.0476926751434803,
            -0.2723543643951416,
            -0.16571983695030212,
            0.27992355823516846,
            0.05294694006443024,
            -0.07907279580831528
        ],
        [
            0.2978866696357727,
            0.0646527037024498,
            -0.1477959007024765,
            0.027215875685214996,
            -0.18895018100738525,
            0.18387755751609802,
            -0.08136572688817978,
            0.01638803631067276,
            -0.08332337439060211
        ],
        [
            0.15910378098487854,
            0.08409431576728821,
            0.29295140504837036,
            0.30610498785972595,
            -0.1664053201675415,
            0.23129035532474518,
            -0.19764494895935059,
            0.088642917573452,
            0.2534957528114319
        ],
        [
            -0.16224275529384613,
            0.3464653789997101,
            0.2956005036830902,
            -0.009851361624896526,
            -0.27696239948272705,
            -0.3161660432815552,
            0.21891507506370544,
            -0.3244856894016266,
            -0.1964113861322403
        ],
        [
            0.1330694854259491,
            -0.3522081673145294,
            -0.38472846150398254,
            0.15577924251556396,
            0.11257708817720413,
            0.28147441148757935,
            0.12168987095355988,
            0.2814418077468872,
            0.14600348472595215
        ],
        [
            -0.008646916598081589,
            -0.23015686869621277,
            0.33809998631477356,
            0.20040328800678253,
            -0.0908963605761528,
            -0.051950618624687195,
            -0.06748921424150467,
            -0.0038858423940837383,
            -0.0926656648516655
        ],
        [
            -0.2978319823741913,
            0.29052871465682983,
            -0.2406996488571167,
            -0.026763534173369408,
            -0.23382122814655304,
            0.20234160125255585,
            0.09978155791759491,
            0.0841776430606842,
            -0.019640464335680008
        ],
        [
            0.19014261662960052,
            0.3249894380569458,
            -0.2739309072494507,
            0.21231801807880402,
            0.29004842042922974,
            0.2767780125141144,
            -0.22115659713745117,
            0.2731039524078369,
            0.31453031301498413
        ],
        [
            -0.2858579754829407,
            0.00555653078481555,
            0.2082679271697998,
            -0.23317407071590424,
            0.04756039008498192,
            0.33334967494010925,
            -0.1884060800075531,
            -0.06653357297182083,
            -0.3179396688938141
        ],
        [
            0.21221409738063812,
            0.29935118556022644,
            0.0037347255274653435,
            -0.2563401162624359,
            0.24675162136554718,
            -0.1619952917098999,
            -0.07670770585536957,
            0.2624794840812683,
            0.014192960225045681
        ],
        [
            0.014004148542881012,
            -0.1261003315448761,
            -0.2930229604244232,
            -0.2889893352985382,
            -0.11140335351228714,
            -0.18429520726203918,
            0.1001022607088089,
            0.13207706809043884,
            -0.16158312559127808
        ],
        [
            -0.20242361724376678,
            -0.2597073018550873,
            0.19946454465389252,
            0.08504221588373184,
            0.23975832760334015,
            0.2518153488636017,
            0.183748260140419,
            0.09371771663427353,
            -0.3164574205875397
        ],
        [
            -0.2884849011898041,
            0.2605912685394287,
            0.1674284189939499,
            -0.27735278010368347,
            0.02472912333905697,
            -0.2222595512866974,
            0.18416446447372437,
            -0.05045674368739128,
            0.02282744087278843
        ],
        [
            -0.2574041485786438,
            -0.07974106818437576,
            0.03629916533827782,
            -0.21744848787784576,
            0.27211102843284607,
            -0.2692526876926422,
            -0.3057401478290558,
            -0.3580325245857239,
            0.3164001405239105
        ],
        [
            0.3747008442878723,
            -0.03855685517191887,
            -0.12238622456789017,
            -0.00023252829851116985,
            0.011378061957657337,
            -0.14517085254192352,
            -0.305996835231781,
            0.08699829876422882,
            -0.28056421875953674
        ],
        [
            -0.15192720293998718,
            0.17024284601211548,
            -0.22690606117248535,
            -0.1238008514046669,
            0.04074568301439285,
            -0.1688937544822693,
            0.2151031345129013,
            -0.154070645570755,
            0.2910045087337494
        ],
        [
            -0.27033472061157227,
            -0.30043646693229675,
            -0.18272818624973297,
            0.008827881887555122,
            0.32910144329071045,
            -0.069478839635849,
            0.24844565987586975,
            0.21242927014827728,
            -0.08445309102535248
        ],
        [
            0.26749852299690247,
            0.30662113428115845,
            0.1555844098329544,
            -0.16612432897090912,
            -0.3363112509250641,
            0.2927488684654236,
            -0.3256172835826874,
            -0.2904192805290222,
            0.023403573781251907
        ],
        [
            0.041761722415685654,
            -0.21794535219669342,
            -0.22040840983390808,
            -0.33051368594169617,
            -0.21077977120876312,
            -0.05148452892899513,
            0.06580038368701935,
            -0.29038476943969727,
            -0.01339103002101183
        ],
        [
            -0.1435859054327011,
            0.23882153630256653,
            0.17912812530994415,
            0.2646901309490204,
            -0.04605604335665703,
            -0.0011420052032917738,
            0.14840175211429596,
            -0.2752563953399658,
            0.25882473587989807
        ],
        [
            0.07291065156459808,
            0.15111732482910156,
            -0.10187579691410065,
            -0.18009674549102783,
            -0.3670543432235718,
            -0.18440601229667664,
            0.27521228790283203,
            -0.24738603830337524,
            0.31417572498321533
        ],
        [
            -0.08586661517620087,
            0.011322658509016037,
            0.3243885934352875,
            0.02425471320748329,
            -0.17441675066947937,
            -0.3181331157684326,
            0.33601105213165283,
            0.1592082679271698,
            -0.26789769530296326
        ],
        [
            -0.266916424036026,
            -0.10956329107284546,
            -0.002063247375190258,
            -0.09775757044553757,
            0.3440192639827728,
            -0.13184691965579987,
            0.3133516013622284,
            0.031108899042010307,
            -0.11646372079849243
        ],
        [
            0.30258873105049133,
            0.054488249123096466,
            -0.15759588778018951,
            0.3274289071559906,
            -0.16069631278514862,
            0.15862829983234406,
            -0.1228267028927803,
            -0.007681126240640879,
            -0.06645793467760086
        ],
        [
            0.3109014928340912,
            -0.268328994512558,
            -0.004162255208939314,
            -0.13491541147232056,
            0.3021303415298462,
            -0.18254554271697998,
            -0.044803328812122345,
            -0.19613045454025269,
            -0.22301292419433594
        ],
        [
            0.11150933057069778,
            0.26919689774513245,
            -0.2872597873210907,
            -0.21641261875629425,
            -0.37766018509864807,
            -0.10244891792535782,
            -0.3049953877925873,
            0.13499006628990173,
            -0.12091536074876785
        ],
        [
            -0.06708696484565735,
            0.03469068929553032,
            0.20732179284095764,
            -0.1576869934797287,
            0.10331431031227112,
            -0.23521320521831512,
            -0.134185329079628,
            0.3284440338611603,
            0.12085004895925522
        ],
        [
            0.13804030418395996,
            -0.01335627306252718,
            0.285458505153656,
            0.2875514030456543,
            0.21388757228851318,
            0.04369529336690903,
            0.2532274127006531,
            -0.008883041329681873,
            -0.2663433849811554
        ],
        [
            -0.3339695930480957,
            0.21792398393154144,
            -0.051034823060035706,
            0.2099045217037201,
            0.23585772514343262,
            0.056063372641801834,
            0.186141237616539,
            -0.03556254878640175,
            0.0034745954908430576
        ],
        [
            0.02052430994808674,
            -0.009687652811408043,
            0.21774590015411377,
            -0.1074116975069046,
            -0.16867390275001526,
            -0.1656845062971115,
            0.07649090141057968,
            -0.2697334587574005,
            0.30062687397003174
        ],
        [
            -0.1686249077320099,
            0.11044983565807343,
            -0.17781388759613037,
            -0.17349372804164886,
            -0.28062835335731506,
            0.09051284193992615,
            0.319513201713562,
            0.05858192220330238,
            -0.21557730436325073
        ],
        [
            0.3012378513813019,
            0.2887013554573059,
            -0.06878559291362762,
            0.16669048368930817,
            0.30082300305366516,
            -0.19330225884914398,
            0.05200499668717384,
            -0.12655197083950043,
            -0.3487783372402191
        ],
        [
            -0.02505122683942318,
            0.24563178420066833,
            0.06523407995700836,
            0.1787550449371338,
            -0.16199418902397156,
            -0.26385197043418884,
            -0.22670643031597137,
            0.3096810579299927,
            0.1161968782544136
        ],
        [
            0.26599186658859253,
            -0.0934784933924675,
            -0.026964517310261726,
            -0.09674747288227081,
            -0.11720393598079681,
            0.15540647506713867,
            -0.0985589325428009,
            -0.0854279026389122,
            -0.2705211639404297
        ],
        [
            0.21713513135910034,
            -0.16732603311538696,
            0.13292868435382843,
            0.3291478157043457,
            -0.22627782821655273,
            -0.08769656717777252,
            -0.2229064702987671,
            0.07107442617416382,
            0.07683907449245453
        ],
        [
            0.27177122235298157,
            0.1484111100435257,
            0.10723618417978287,
            0.09154573827981949,
            0.18475095927715302,
            -0.28176283836364746,
            0.07717864215373993,
            -0.1723400354385376,
            0.0006277493084780872
        ],
        [
            0.3396008312702179,
            -0.2209031581878662,
            0.18967205286026,
            0.06026671826839447,
            -0.27783337235450745,
            0.3073170781135559,
            -0.23637033998966217,
            0.09309863299131393,
            -0.07351285219192505
        ],
        [
            -0.039902463555336,
            0.2654663920402527,
            0.3200886845588684,
            0.28674668073654175,
            0.34509047865867615,
            -0.15449468791484833,
            -0.29951781034469604,
            -0.22766461968421936,
            -0.3206276297569275
        ],
        [
            -0.3189889192581177,
            -0.305431067943573,
            0.2026367038488388,
            0.19015628099441528,
            0.04809899255633354,
            -0.19057637453079224,
            0.29919302463531494,
            0.25242164731025696,
            -0.16433124244213104
        ],
        [
            -0.16365021467208862,
            -0.3416154384613037,
            -0.15309663116931915,
            -0.17007848620414734,
            -0.22807304561138153,
            0.2224682718515396,
            -0.05366895720362663,
            0.3188532292842865,
            0.22946526110172272
        ],
        [
            0.34636008739471436,
            0.2608192563056946,
            -0.15227356553077698,
            -0.17232103645801544,
            0.22206899523735046,
            0.08496539294719696,
            -0.1983863115310669,
            -0.030423790216445923,
            0.2477382868528366
        ],
        [
            -0.054986245930194855,
            0.03517729416489601,
            -0.2256336510181427,
            -0.12901368737220764,
            -0.2282460629940033,
            0.22591955959796906,
            -0.012589813210070133,
            0.21031032502651215,
            0.2033252716064453
        ],
        [
            -0.08005466312170029,
            0.16861259937286377,
            0.21885286271572113,
            0.2775607705116272,
            -0.18257036805152893,
            0.21175044775009155,
            0.3369351327419281,
            0.22003111243247986,
            0.2809978127479553
        ],
        [
            0.05087079852819443,
            -0.24505184590816498,
            -0.2267763316631317,
            0.19447845220565796,
            -0.2481284886598587,
            0.19426196813583374,
            0.29982632398605347,
            0.06521274149417877,
            -0.23443390429019928
        ],
        [
            -0.24458250403404236,
            0.20263077318668365,
            -0.014347686432301998,
            0.2718566656112671,
            -0.2678499221801758,
            -0.228332057595253,
            -0.32531824707984924,
            -0.36046507954597473,
            0.3281700909137726
        ],
        [
            0.07081837952136993,
            -0.3244129419326782,
            0.11409316211938858,
            0.24041573703289032,
            -0.24254372715950012,
            0.08075401186943054,
            0.0485243946313858,
            -0.24421152472496033,
            0.15240129828453064
        ],
        [
            0.1978427618741989,
            -0.2044903039932251,
            -0.2164989411830902,
            0.12762311100959778,
            -0.2375030517578125,
            -0.2526020109653473,
            0.1492631435394287,
            0.2846679985523224,
            -0.18981334567070007
        ],
        [
            0.1327161341905594,
            0.16179721057415009,
            -0.1961321234703064,
            -0.13632294535636902,
            -0.07737622410058975,
            0.18107464909553528,
            -0.20136432349681854,
            0.31992846727371216,
            0.17998923361301422
        ],
        [
            0.0033374971244484186,
            0.05582364648580551,
            -0.269058495759964,
            0.17668956518173218,
            -0.3563759922981262,
            0.30150142312049866,
            -0.027761388570070267,
            -0.10898761451244354,
            -0.2984379529953003
        ],
        [
            -0.21884837746620178,
            -0.14788419008255005,
            0.10055603086948395,
            -0.11038857698440552,
            -0.18427151441574097,
            -0.0966254323720932,
            0.13699215650558472,
            -0.36980924010276794,
            0.15354415774345398
        ],
        [
            -0.31230467557907104,
            -0.2113911211490631,
            -0.32853519916534424,
            -0.007868272252380848,
            0.005278102122247219,
            0.024103699252009392,
            0.0444435179233551,
            0.12423983216285706,
            -0.09975338727235794
        ],
        [
            0.21001829206943512,
            0.25608232617378235,
            0.16788360476493835,
            0.2799382209777832,
            0.12543566524982452,
            -0.2717687487602234,
            -0.2610865533351898,
            -0.17419388890266418,
            -0.030365094542503357
        ],
        [
            0.27833664417266846,
            0.11254973709583282,
            0.2138945311307907,
            -0.1480560600757599,
            0.1300533264875412,
            -0.36764997243881226,
            0.20958976447582245,
            0.1787204146385193,
            0.3007888197898865
        ],
        [
            -0.05153310298919678,
            -0.12280275672674179,
            -0.10658450424671173,
            -0.085650734603405,
            -0.02848053351044655,
            0.10330456495285034,
            0.27649080753326416,
            -0.08889509737491608,
            -0.07989578694105148
        ],
        [
            -0.21068885922431946,
            -0.23975804448127747,
            -0.16644398868083954,
            -0.15269096195697784,
            0.29103654623031616,
            -0.017409926280379295,
            0.2922450602054596,
            -0.08112319558858871,
            0.14272373914718628
        ],
        [
            0.13901203870773315,
            -0.32796698808670044,
            -0.2764272391796112,
            0.11324133723974228,
            -0.19625131785869598,
            0.24624839425086975,
            0.19319747388362885,
            0.2483704835176468,
            0.2729252576828003
        ],
        [
            0.17286843061447144,
            -0.11701608449220657,
            -0.2824914753437042,
            -0.26498034596443176,
            -0.056895509362220764,
            -0.0380999855697155,
            0.05068589001893997,
            0.11093269288539886,
            -0.15131497383117676
        ],
        [
            0.3314751088619232,
            -0.21846505999565125,
            -0.2682091295719147,
            -0.261165589094162,
            0.08427873253822327,
            -0.14408975839614868,
            -0.1153450533747673,
            -0.200921893119812,
            -0.260132372379303
        ],
        [
            0.036396611481904984,
            0.04672588035464287,
            0.01307722833007574,
            0.1821998506784439,
            -0.018938209861516953,
            -0.2634223401546478,
            -0.1724136471748352,
            0.09036427736282349,
            0.19139742851257324
        ],
        [
            -0.024503784254193306,
            -0.15952742099761963,
            0.3565683960914612,
            -0.06071886420249939,
            -0.19374676048755646,
            -0.05016713961958885,
            -0.025950314477086067,
            -0.22681090235710144,
            -0.13928966224193573
        ],
        [
            0.23918966948986053,
            0.10584267973899841,
            0.010577700100839138,
            -0.2986639142036438,
            0.27252641320228577,
            0.32548975944519043,
            -0.10719240456819534,
            0.031694769859313965,
            -0.06657906621694565
        ],
        [
            -0.0563850924372673,
            -0.22402991354465485,
            -0.11368262022733688,
            0.3148787319660187,
            0.17988203465938568,
            -0.2490919679403305,
            0.016997944563627243,
            -0.012575861997902393,
            0.009953430853784084
        ],
        [
            0.3656260669231415,
            0.11069584637880325,
            0.16565221548080444,
            -0.11094407737255096,
            0.04711804538965225,
            0.09777233749628067,
            -0.3288792371749878,
            -0.11482354253530502,
            -0.2801498472690582
        ],
        [
            -0.0743202269077301,
            -0.1279636174440384,
            -0.09446559101343155,
            0.0015763096744194627,
            -0.20758135616779327,
            0.2879544496536255,
            0.2821122109889984,
            -0.0624871663749218,
            0.014204127714037895
        ],
        [
            -0.09854680299758911,
            -0.2949990928173065,
            -0.0008544161100871861,
            -0.05686835199594498,
            0.28583285212516785,
            -0.13165903091430664,
            0.18581867218017578,
            -0.07473420351743698,
            0.3401689827442169
        ],
        [
            -0.10801520198583603,
            0.1547669619321823,
            0.34642279148101807,
            0.19344820082187653,
            -0.11134404689073563,
            -0.21180084347724915,
            -0.19459329545497894,
            0.14781507849693298,
            -0.15970532596111298
        ],
        [
            -0.31852656602859497,
            -0.3170071542263031,
            0.14991486072540283,
            0.14136643707752228,
            0.14474566280841827,
            -0.05110619217157364,
            -0.3184904456138611,
            0.13463819026947021,
            0.29667899012565613
        ],
        [
            0.11480723321437836,
            -0.01827600598335266,
            0.18506328761577606,
            0.1768079400062561,
            0.21672417223453522,
            -0.1006728783249855,
            -0.14986252784729004,
            -0.28570079803466797,
            -0.09950587153434753
        ],
        [
            -0.21534226834774017,
            0.16283361613750458,
            -0.28268465399742126,
            -0.113042913377285,
            -0.09047146886587143,
            0.22391846776008606,
            -0.05078781396150589,
            -0.17924095690250397,
            0.2882634103298187
        ],
        [
            -0.13824956119060516,
            0.02356642857193947,
            -0.02827538549900055,
            -0.08845648169517517,
            -0.22350789606571198,
            0.22369633615016937,
            0.1115245521068573,
            -0.16390059888362885,
            -0.11098906397819519
        ],
        [
            -0.18259158730506897,
            -0.16398781538009644,
            -0.20252875983715057,
            -0.06764746457338333,
            -0.16940008103847504,
            0.3286612033843994,
            -0.3497096300125122,
            0.176374614238739,
            0.3357176184654236
        ],
        [
            -0.10585729777812958,
            0.18535348773002625,
            -0.10935782641172409,
            -0.05089494213461876,
            0.026722919195890427,
            0.08946304768323898,
            -0.3291054964065552,
            0.11837289482355118,
            0.29243409633636475
        ],
        [
            -0.24894452095031738,
            0.3071514666080475,
            -0.3008326590061188,
            -0.09252235293388367,
            0.012758747674524784,
            -0.058645717799663544,
            0.17774981260299683,
            -0.3254362642765045,
            -0.07891101390123367
        ],
        [
            0.17450490593910217,
            -0.26886653900146484,
            0.30961596965789795,
            0.31179124116897583,
            0.14670483767986298,
            0.3060048222541809,
            0.3122471272945404,
            -0.015771035104990005,
            0.05493679270148277
        ],
        [
            0.005169557873159647,
            0.28323403000831604,
            0.19078278541564941,
            0.08561569452285767,
            -0.3137648105621338,
            -0.33430609107017517,
            0.2939927875995636,
            -0.29476892948150635,
            -0.18384292721748352
        ],
        [
            0.3327726423740387,
            0.2919994592666626,
            0.20632129907608032,
            0.11417825520038605,
            0.16145385801792145,
            0.2375563681125641,
            0.25608739256858826,
            0.02205323427915573,
            -0.2909824848175049
        ],
        [
            0.3002559542655945,
            -0.19946566224098206,
            -0.22460049390792847,
            -0.22531531751155853,
            0.23084239661693573,
            -0.01715463027358055,
            -0.33585283160209656,
            0.09991218894720078,
            -0.07848517596721649
        ],
        [
            -0.09369924664497375,
            0.24215686321258545,
            -0.12484104186296463,
            0.2160942703485489,
            -0.14368392527103424,
            0.14273621141910553,
            -0.039161406457424164,
            0.015281220898032188,
            -0.04512818157672882
        ],
        [
            0.14467188715934753,
            0.07797709852457047,
            0.3026142418384552,
            0.056539662182331085,
            -0.24211949110031128,
            0.13860797882080078,
            -0.11964826285839081,
            0.16646504402160645,
            -0.21859490871429443
        ],
        [
            -0.31053802371025085,
            -0.24377702176570892,
            -0.21162693202495575,
            -0.29093101620674133,
            -0.2565353810787201,
            -0.0033003396820276976,
            -0.21539533138275146,
            0.25227630138397217,
            0.1735563725233078
        ],
        [
            0.1793329119682312,
            -0.20274443924427032,
            -0.09980946779251099,
            0.22346192598342896,
            -0.12057031691074371,
            -0.26586323976516724,
            0.1805509626865387,
            -0.3649534583091736,
            -0.047343749552965164
        ],
        [
            0.11031822860240936,
            -0.261421799659729,
            0.08748526126146317,
            -0.04586483910679817,
            0.3256211280822754,
            -0.02004222385585308,
            0.25067898631095886,
            -0.26543325185775757,
            -0.25933605432510376
        ],
        [
            -0.2594440281391144,
            0.20211361348628998,
            0.20656533539295197,
            -0.13825459778308868,
            0.19718118011951447,
            -0.13987578451633453,
            -0.20390523970127106,
            -0.24181754887104034,
            0.2686234414577484
        ],
        [
            0.20140695571899414,
            -0.07828887552022934,
            -0.06391438841819763,
            0.11786102503538132,
            0.2332487404346466,
            0.3148268759250641,
            -0.06022438779473305,
            0.2899743318557739,
            0.026727627962827682
        ],
        [
            -0.27427393198013306,
            0.013757734559476376,
            0.23198208212852478,
            -0.0856662392616272,
            0.23757633566856384,
            0.3709980845451355,
            0.20979651808738708,
            -0.03115924261510372,
            -0.038785435259342194
        ],
        [
            0.15817959606647491,
            -0.15781699120998383,
            0.245731920003891,
            0.25026583671569824,
            0.15302838385105133,
            0.2405892163515091,
            0.19327862560749054,
            0.06158008053898811,
            0.17582690715789795
        ],
        [
            0.35458752512931824,
            0.19174538552761078,
            -0.10943588614463806,
            0.13523392379283905,
            -0.06977585703134537,
            -0.07546529173851013,
            0.0004903058288618922,
            0.2589244246482849,
            -0.06924505531787872
        ],
        [
            -0.06729985028505325,
            0.26782000064849854,
            -0.2494029998779297,
            -0.25743332505226135,
            -0.22754378616809845,
            -0.2502264678478241,
            0.16447994112968445,
            -0.3183441460132599,
            -0.2830154597759247
        ],
        [
            0.2715676426887512,
            0.0847787857055664,
            0.22166705131530762,
            0.29042118787765503,
            0.1658373475074768,
            0.15315943956375122,
            0.17373070120811462,
            0.16759401559829712,
            0.10605327039957047
        ],
        [
            -0.19882498681545258,
            -0.30049628019332886,
            0.249778613448143,
            0.17472563683986664,
            -0.3189230263233185,
            0.024348506703972816,
            0.030143698677420616,
            0.06660793721675873,
            -0.2656603157520294
        ],
        [
            -0.22653630375862122,
            -0.23426054418087006,
            0.042395178228616714,
            -0.2775809168815613,
            -0.20425450801849365,
            -0.29885077476501465,
            0.23284287750720978,
            0.2086266130208969,
            0.049960386008024216
        ],
        [
            0.003770570969209075,
            -0.32513463497161865,
            -0.19678552448749542,
            0.036640189588069916,
            -0.164431631565094,
            -0.18980425596237183,
            0.15920484066009521,
            0.23914213478565216,
            -0.0413576103746891
        ],
        [
            0.3331378400325775,
            0.3522467613220215,
            -0.030164362862706184,
            -0.3342829942703247,
            -0.005017823539674282,
            0.0245404951274395,
            0.00929335504770279,
            -0.30816641449928284,
            -0.044340599328279495
        ],
        [
            -0.12551096081733704,
            0.3030115067958832,
            0.27872684597969055,
            0.13689292967319489,
            -0.3174511790275574,
            0.1645353138446808,
            0.03486787527799606,
            -0.08857882767915726,
            0.37418830394744873
        ],
        [
            0.3345354497432709,
            0.22272731363773346,
            0.2885265350341797,
            -0.2213335633277893,
            0.13594260811805725,
            -0.31494948267936707,
            0.14755232632160187,
            0.0023204314056783915,
            0.16466914117336273
        ],
        [
            -0.27003705501556396,
            -0.37758907675743103,
            -0.17426379024982452,
            -0.28714731335639954,
            -0.14398053288459778,
            0.009065191261470318,
            0.17168918251991272,
            -0.1361580491065979,
            -0.3820512890815735
        ],
        [
            0.08079546689987183,
            -0.028483198955655098,
            -0.24878424406051636,
            -0.17690417170524597,
            0.3589123785495758,
            0.2749294340610504,
            -0.32882627844810486,
            -0.11628629267215729,
            -0.06488242745399475
        ],
        [
            -0.38432326912879944,
            -0.04100588709115982,
            0.11940516531467438,
            -0.14226964116096497,
            0.28749504685401917,
            0.060704804956912994,
            0.22436915338039398,
            0.0031493413262069225,
            0.22644346952438354
        ],
        [
            0.15604934096336365,
            -0.3582899868488312,
            0.07485637813806534,
            -0.16165819764137268,
            0.31030961871147156,
            -0.039734721183776855,
            0.07454515248537064,
            0.04427366703748703,
            0.34323686361312866
        ],
        [
            0.1901646852493286,
            0.30474594235420227,
            -0.3525983393192291,
            -0.15826021134853363,
            -0.29473912715911865,
            -0.14104637503623962,
            -0.28387463092803955,
            0.0028306536842137575,
            0.07396333664655685
        ],
        [
            0.1532561182975769,
            -0.16943798959255219,
            -0.3366195559501648,
            0.2307836264371872,
            -0.17449446022510529,
            -0.1362866908311844,
            -0.3142300248146057,
            -0.15503394603729248,
            0.3132215738296509
        ],
        [
            0.27943170070648193,
            -0.23993495106697083,
            -0.29306453466415405,
            -0.07636332511901855,
            -0.06198761984705925,
            0.25128835439682007,
            0.29125064611434937,
            0.30007874965667725,
            0.1306103616952896
        ],
        [
            -0.05563709884881973,
            -0.10548444092273712,
            -0.047964900732040405,
            -0.048003412783145905,
            0.17487254738807678,
            0.12319916486740112,
            0.15321891009807587,
            -0.13423684239387512,
            0.19703826308250427
        ],
        [
            0.06465407460927963,
            -0.2230331003665924,
            0.17937414348125458,
            0.004824236035346985,
            -0.0996740534901619,
            -0.25974470376968384,
            0.2605242431163788,
            0.2943366765975952,
            -0.29363390803337097
        ],
        [
            0.0353984460234642,
            -0.08661065250635147,
            -0.35626161098480225,
            0.07103139162063599,
            0.20748910307884216,
            0.3599700629711151,
            -0.2670755982398987,
            -0.03738395869731903,
            -0.1590612530708313
        ],
        [
            -0.029997291043400764,
            -0.025655655190348625,
            -0.02928200177848339,
            -0.3598160147666931,
            -0.19163459539413452,
            0.28053563833236694,
            -0.31559380888938904,
            -0.2702888548374176,
            0.17472732067108154
        ],
        [
            -0.2035902589559555,
            0.2009928822517395,
            -0.32238301634788513,
            0.356937438249588,
            -0.2694975733757019,
            -0.17431773245334625,
            0.1910090297460556,
            0.03938056901097298,
            -0.2967715263366699
        ],
        [
            -0.13412749767303467,
            -0.24531987309455872,
            -0.11818154901266098,
            0.31789711117744446,
            -0.1905251294374466,
            -0.03819993883371353,
            0.0030082338489592075,
            -0.2142026424407959,
            -0.2687954306602478
        ],
        [
            -0.01925133727490902,
            -0.16552120447158813,
            0.09296911954879761,
            -0.23153598606586456,
            0.27354785799980164,
            0.1553449034690857,
            0.15816766023635864,
            0.12429145723581314,
            -0.3315510153770447
        ],
        [
            -0.11047974228858948,
            0.21699842810630798,
            -0.2866625189781189,
            0.02359718084335327,
            0.23305146396160126,
            0.24537596106529236,
            -0.2396729737520218,
            -0.15513594448566437,
            -0.2994121313095093
        ],
        [
            0.32491663098335266,
            0.2846970558166504,
            -0.26990464329719543,
            -0.08465155959129333,
            0.016529841348528862,
            -0.018037494271993637,
            0.17179174721240997,
            -0.1260770708322525,
            -0.2892223596572876
        ],
        [
            0.1779966950416565,
            0.35975080728530884,
            0.034919269382953644,
            -0.06936581432819366,
            -0.017149822786450386,
            -0.21948595345020294,
            0.24481074512004852,
            -0.11786068975925446,
            -0.15733525156974792
        ],
        [
            -0.0817103385925293,
            0.13973939418792725,
            0.15184371173381805,
            -0.3336104154586792,
            -0.21049396693706512,
            -0.2323436439037323,
            0.2910093665122986,
            0.21227405965328217,
            0.28210389614105225
        ],
        [
            0.10518189519643784,
            0.27047714591026306,
            -0.0900961235165596,
            -0.18122880160808563,
            0.031037690117955208,
            0.2755652964115143,
            -0.14044047892093658,
            0.037491001188755035,
            0.030068177729845047
        ],
        [
            0.35385724902153015,
            -0.1283576637506485,
            0.1942516565322876,
            0.19281591475009918,
            -0.20354850590229034,
            -0.10891115665435791,
            0.06714556366205215,
            0.2832728326320648,
            -0.10410160571336746
        ],
        [
            0.3340669572353363,
            -0.10397840291261673,
            0.17570185661315918,
            0.09210114926099777,
            -0.1372542828321457,
            0.30070436000823975,
            -0.1912323534488678,
            0.2331513911485672,
            -0.18417607247829437
        ],
        [
            0.09339754283428192,
            -0.12909433245658875,
            0.29896971583366394,
            -0.027244022116065025,
            -0.02337070368230343,
            -0.11986306309700012,
            -0.3402000367641449,
            -0.11432501673698425,
            -0.32874709367752075
        ],
        [
            -0.06183123216032982,
            -0.2625187933444977,
            -0.0950389876961708,
            0.3263551592826843,
            0.23031848669052124,
            -0.3779231905937195,
            0.032290201634168625,
            0.038627054542303085,
            -0.3178662359714508
        ],
        [
            0.3219866156578064,
            -0.23288866877555847,
            -0.2206752747297287,
            -0.33652806282043457,
            -0.09265024960041046,
            -0.19585025310516357,
            0.14104613661766052,
            -0.14186465740203857,
            0.3503556251525879
        ],
        [
            -0.12687765061855316,
            0.10154039412736893,
            0.29562169313430786,
            -0.2399350106716156,
            -0.27409693598747253,
            0.02204340323805809,
            -0.14900796115398407,
            0.29044243693351746,
            0.1154378205537796
        ],
        [
            -0.0938667580485344,
            0.21371948719024658,
            -0.25438475608825684,
            -0.3155914843082428,
            0.005774916149675846,
            0.16635078191757202,
            -0.07435596734285355,
            0.2900833487510681,
            -0.1654355674982071
        ],
        [
            -0.095821812748909,
            0.30046331882476807,
            0.1808101385831833,
            -0.03625347465276718,
            0.3438650965690613,
            -0.3169332444667816,
            -0.24945728480815887,
            0.026221828535199165,
            -0.2627718150615692
        ],
        [
            -0.1966494768857956,
            -0.08732476830482483,
            -0.3354974091053009,
            0.2882397770881653,
            0.10729216784238815,
            -0.22261980175971985,
            0.3759209215641022,
            -0.10869517177343369,
            0.30451083183288574
        ],
        [
            -0.10647488385438919,
            -0.07239186763763428,
            -0.04341098293662071,
            0.29061102867126465,
            -0.18908736109733582,
            -0.28989294171333313,
            -0.10928140580654144,
            0.2784113585948944,
            0.008331270888447762
        ],
        [
            -0.07423564046621323,
            0.3388238251209259,
            -0.17720972001552582,
            0.035227760672569275,
            -0.21786801517009735,
            -0.11572829633951187,
            0.27223485708236694,
            -0.027958083897829056,
            -0.07086952775716782
        ],
        [
            0.13125166296958923,
            -0.2906891405582428,
            -0.06399282813072205,
            -0.11488553881645203,
            -0.3418053388595581,
            0.11246524751186371,
            -0.15006189048290253,
            -0.058082159608602524,
            -0.20754478871822357
        ],
        [
            -0.30437594652175903,
            -0.08618620783090591,
            0.2889513373374939,
            0.0878370851278305,
            -0.04292590543627739,
            0.19035077095031738,
            0.11312134563922882,
            0.21151842176914215,
            -0.3524610698223114
        ],
        [
            -0.025982128456234932,
            -0.20799724757671356,
            0.010681861080229282,
            -0.3336518704891205,
            0.018623730167746544,
            0.0965227410197258,
            -0.32953131198883057,
            0.18674159049987793,
            0.07229164987802505
        ],
        [
            -0.27146556973457336,
            -0.12355077266693115,
            -0.326523095369339,
            -0.033856529742479324,
            -0.27178308367729187,
            0.18666788935661316,
            -0.12412051856517792,
            0.1402541548013687,
            0.1861499845981598
        ],
        [
            -0.05710052326321602,
            -0.13105250895023346,
            0.08753005415201187,
            -0.1703425794839859,
            0.09387782216072083,
            -0.0897311270236969,
            -0.13464763760566711,
            -0.266736775636673,
            0.244464710354805
        ],
        [
            0.20601218938827515,
            -0.23648986220359802,
            0.2549338936805725,
            -0.18208301067352295,
            -0.13984854519367218,
            0.12070689350366592,
            -0.3355692923069,
            -0.12514109909534454,
            0.2631732225418091
        ],
        [
            -0.2732577323913574,
            0.23166689276695251,
            0.36354130506515503,
            -0.01661216840147972,
            0.08450652658939362,
            -0.04346859082579613,
            0.3201024830341339,
            -0.011291873641312122,
            -0.3263761103153229
        ],
        [
            -0.12403727322816849,
            -0.013844167813658714,
            0.24640586972236633,
            0.30074945092201233,
            -0.33313658833503723,
            -0.07709008455276489,
            -0.0581209734082222,
            0.2963337004184723,
            0.2685908079147339
        ],
        [
            0.22186465561389923,
            -0.012596027925610542,
            0.1427043229341507,
            -0.27254948019981384,
            -0.2827144265174866,
            -0.2712702751159668,
            -0.20515859127044678,
            0.2878425419330597,
            -0.23358727991580963
        ],
        [
            -0.056391242891550064,
            0.14997301995754242,
            0.33028650283813477,
            -0.10643545538187027,
            -0.07220854610204697,
            0.2716778516769409,
            -0.022174790501594543,
            0.040775712579488754,
            -0.04057987034320831
        ],
        [
            -0.057159584015607834,
            0.12835900485515594,
            0.24390017986297607,
            -0.062439534813165665,
            -0.05695514380931854,
            0.06172482296824455,
            0.3195352256298065,
            0.32274264097213745,
            -0.22100219130516052
        ],
        [
            0.19208258390426636,
            -0.1772605925798416,
            -0.3669110834598541,
            0.04506753385066986,
            0.07211408019065857,
            -0.21652187407016754,
            0.19504666328430176,
            0.18873833119869232,
            0.28381285071372986
        ],
        [
            0.08766462653875351,
            0.3289388120174408,
            -0.34309324622154236,
            0.08777882158756256,
            -0.2423345297574997,
            -0.08677880465984344,
            -0.10545943677425385,
            -0.24272269010543823,
            -0.10866549611091614
        ],
        [
            0.3746147155761719,
            0.1290835589170456,
            0.14970068633556366,
            -0.21802547574043274,
            -0.039860449731349945,
            -0.1353750079870224,
            -0.32995352149009705,
            0.16971608996391296,
            -0.1767435222864151
        ],
        [
            -0.039417609572410583,
            0.0789700374007225,
            -0.04673559591174126,
            0.012494484893977642,
            -0.27051669359207153,
            -0.14045800268650055,
            -0.12749984860420227,
            0.12304434925317764,
            0.3030356466770172
        ],
        [
            -0.08088414371013641,
            0.062384068965911865,
            0.15225167572498322,
            0.09910253435373306,
            -0.05033314228057861,
            -0.16671615839004517,
            -0.20286673307418823,
            0.3558145761489868,
            -0.18200430274009705
        ],
        [
            -0.2332482486963272,
            -0.3175518810749054,
            -0.19258886575698853,
            0.00686672143638134,
            -0.08669112622737885,
            -0.24124237895011902,
            0.05147542431950569,
            0.22889891266822815,
            -0.3576175272464752
        ],
        [
            0.012870179489254951,
            0.12186556309461594,
            0.3446519672870636,
            -0.2690393030643463,
            -0.27366936206817627,
            -0.01417998131364584,
            0.31298258900642395,
            -0.2733291685581207,
            -0.16524338722229004
        ],
        [
            -0.34707874059677124,
            0.14073485136032104,
            0.050777386873960495,
            -0.10978925228118896,
            -0.2326359897851944,
            -0.08846286684274673,
            0.2093466967344284,
            0.2727803885936737,
            0.2922062277793884
        ],
        [
            0.02756553515791893,
            -0.038748014718294144,
            -0.3449428677558899,
            0.19022756814956665,
            -0.11421933770179749,
            -0.19410280883312225,
            -0.2706608772277832,
            0.10191833227872849,
            -0.20071886479854584
        ],
        [
            -0.19060689210891724,
            0.07834705710411072,
            -0.045497167855501175,
            0.24644748866558075,
            0.17638123035430908,
            -0.18478967249393463,
            -0.06729870289564133,
            -0.2151113748550415,
            -0.36566880345344543
        ],
        [
            -0.3440975546836853,
            0.283302366733551,
            0.04943917691707611,
            0.029665779322385788,
            0.2217327356338501,
            0.31482675671577454,
            0.033722832798957825,
            -0.30242598056793213,
            0.07872263342142105
        ],
        [
            0.08466359972953796,
            0.17374315857887268,
            -0.056508444249629974,
            0.18388377130031586,
            0.17182590067386627,
            0.18047110736370087,
            0.25998571515083313,
            0.12172943353652954,
            0.09817995876073837
        ],
        [
            0.1871473491191864,
            0.16868720948696136,
            0.160773366689682,
            0.30062344670295715,
            -0.2906426787376404,
            0.04057881236076355,
            -0.3143485188484192,
            0.19256030023097992,
            -0.21314404904842377
        ],
        [
            -0.0351606123149395,
            0.01041390560567379,
            -0.14072737097740173,
            0.24778729677200317,
            -0.06314687430858612,
            -0.10424285382032394,
            0.1558018922805786,
            0.30632510781288147,
            -0.04216397553682327
        ],
        [
            0.17823997139930725,
            0.34245413541793823,
            0.16380365192890167,
            -0.07997291535139084,
            0.32542476058006287,
            0.24255813658237457,
            0.046715687960386276,
            -0.24378542602062225,
            -0.3054119050502777
        ],
        [
            0.28165772557258606,
            0.059365980327129364,
            0.05296880751848221,
            -0.28290265798568726,
            0.34192875027656555,
            0.1160600334405899,
            -0.08843763917684555,
            0.24621392786502838,
            0.03233809024095535
        ],
        [
            0.2225630283355713,
            -0.022645171731710434,
            -0.29178380966186523,
            0.013001877814531326,
            -0.17367325723171234,
            -0.16412928700447083,
            0.27717605233192444,
            -0.1137075275182724,
            0.3252258598804474
        ],
        [
            0.14653338491916656,
            0.11650041490793228,
            0.23726238310337067,
            0.16106833517551422,
            -0.17811907827854156,
            -0.31029918789863586,
            -0.3320300877094269,
            0.3256526291370392,
            0.35648128390312195
        ],
        [
            -0.17562252283096313,
            -0.031150031834840775,
            -0.32772064208984375,
            0.3432573080062866,
            -0.08278919756412506,
            0.3055356442928314,
            -0.2965257167816162,
            -0.3400475084781647,
            0.09574338793754578
        ],
        [
            -0.09104403853416443,
            0.21747295558452606,
            -0.033999573439359665,
            -0.17837359011173248,
            -0.2232753187417984,
            -0.26426875591278076,
            0.2731757164001465,
            -0.09755802154541016,
            -0.2561931908130646
        ],
        [
            0.14933694899082184,
            -0.043673738837242126,
            0.23499564826488495,
            -0.015612156130373478,
            0.08727870136499405,
            -0.058382339775562286,
            -0.14599043130874634,
            -0.19804564118385315,
            0.02472549118101597
        ],
        [
            -0.3556385934352875,
            0.30819010734558105,
            0.3024764358997345,
            0.30892762541770935,
            0.16041935980319977,
            -0.06809529662132263,
            -0.24217507243156433,
            0.18507146835327148,
            0.2479478418827057
        ],
        [
            -0.033237867057323456,
            0.24777889251708984,
            0.2457232028245926,
            -0.18996143341064453,
            0.1445256769657135,
            -0.10251190513372421,
            -0.1009155660867691,
            0.09507942944765091,
            -0.33147871494293213
        ],
        [
            -0.029102785512804985,
            -0.20298393070697784,
            0.005691245198249817,
            -0.26642361283302307,
            0.29041534662246704,
            0.13996641337871552,
            0.08292887359857559,
            -0.12165212631225586,
            0.10066791623830795
        ],
        [
            -0.29881665110588074,
            0.2179003357887268,
            0.14495883882045746,
            0.022097710520029068,
            -0.02016729861497879,
            -0.26065874099731445,
            0.17012141644954681,
            0.04489472135901451,
            0.32565128803253174
        ],
        [
            0.2280217409133911,
            -0.12512898445129395,
            0.04524782672524452,
            -0.3074818551540375,
            0.32990631461143494,
            0.13787870109081268,
            0.2814064919948578,
            0.05350776016712189,
            0.05087091773748398
        ],
        [
            -0.19495126605033875,
            0.11209463328123093,
            -0.1832638382911682,
            -0.11642302572727203,
            -0.31595557928085327,
            0.278967946767807,
            0.2693253457546234,
            0.18865308165550232,
            0.21162328124046326
        ],
        [
            0.05519197881221771,
            0.11972667276859283,
            0.13618209958076477,
            0.3442465364933014,
            -0.04174472764134407,
            -0.2403881698846817,
            -0.2323073297739029,
            0.2958225607872009,
            0.27846503257751465
        ],
        [
            0.1495872139930725,
            0.042605187743902206,
            0.29546138644218445,
            0.30562955141067505,
            -0.28959518671035767,
            0.16381600499153137,
            0.3214613199234009,
            0.296356201171875,
            -0.08882316946983337
        ],
        [
            -0.15849030017852783,
            -0.1335507035255432,
            -0.17355388402938843,
            -0.23432862758636475,
            0.1626635640859604,
            0.07430809736251831,
            -0.2703275680541992,
            -0.007126303855329752,
            -0.18790143728256226
        ],
        [
            0.36590903997421265,
            -0.3650139272212982,
            -0.2387460470199585,
            0.07745153456926346,
            0.07774151116609573,
            -0.010235339403152466,
            0.0974106416106224,
            -0.3446356952190399,
            -0.11602013558149338
        ],
        [
            0.10892032831907272,
            0.3695143759250641,
            -0.06740575283765793,
            0.0224338136613369,
            -0.017871741205453873,
            0.025475360453128815,
            0.32256999611854553,
            -0.10620557516813278,
            -0.011722377501428127
        ],
        [
            -0.046669602394104004,
            0.0143937012180686,
            0.12135286629199982,
            -0.3731750547885895,
            -0.05007316544651985,
            -0.2086344063282013,
            -0.12250684946775436,
            0.02416287362575531,
            0.1642044633626938
        ],
        [
            0.13743016123771667,
            -0.2830665707588196,
            -0.2868833839893341,
            0.3278481364250183,
            0.2723931670188904,
            -0.014265931211411953,
            0.2679896652698517,
            -0.023801639676094055,
            -0.16535916924476624
        ],
        [
            -0.08240102231502533,
            0.26157063245773315,
            0.26450085639953613,
            0.2755433917045593,
            -0.10692710429430008,
            -0.21220865845680237,
            0.1091674342751503,
            0.28873205184936523,
            0.03281131014227867
        ],
        [
            -0.24823783338069916,
            -0.09407484531402588,
            0.19583284854888916,
            -0.17571112513542175,
            -0.17345918715000153,
            0.06886763870716095,
            0.34180355072021484,
            -0.06501742452383041,
            -0.15498006343841553
        ],
        [
            0.1603289395570755,
            0.1659097522497177,
            -0.09429022669792175,
            -0.1036219671368599,
            -0.03455492854118347,
            -0.09078864008188248,
            0.10864824801683426,
            0.262050986289978,
            0.2592012286186218
        ],
        [
            0.3036746680736542,
            -0.3126382827758789,
            0.05634360387921333,
            0.24073323607444763,
            0.14527948200702667,
            0.019130881875753403,
            -0.2734975218772888,
            -0.31077075004577637,
            -0.17734020948410034
        ],
        [
            -0.14446786046028137,
            0.012173180468380451,
            0.09847943484783173,
            0.08509868383407593,
            0.27102208137512207,
            0.08264623582363129,
            0.1529913991689682,
            0.21996231377124786,
            -0.3140747845172882
        ],
        [
            0.29832425713539124,
            -0.13646812736988068,
            -0.09466183185577393,
            -0.19849957525730133,
            0.3533462882041931,
            -0.09534641355276108,
            -0.028866151347756386,
            -0.2656506299972534,
            -0.050654087215662
        ],
        [
            0.19311577081680298,
            -0.016156218945980072,
            -0.27963685989379883,
            0.022882798686623573,
            -0.17848552763462067,
            -0.009557798504829407,
            -0.05331502482295036,
            -0.21907225251197815,
            -0.03305048868060112
        ],
        [
            -0.26101261377334595,
            0.266310453414917,
            -0.31419190764427185,
            0.10665743052959442,
            0.31648334860801697,
            0.18776142597198486,
            -0.12595239281654358,
            0.10870036482810974,
            -0.22536547482013702
        ],
        [
            0.17574267089366913,
            0.1502583771944046,
            -0.04755765199661255,
            -0.2942858338356018,
            0.2996249496936798,
            -0.04991737753152847,
            0.14394624531269073,
            0.32608330249786377,
            0.3336598873138428
        ],
        [
            0.17758731544017792,
            0.2811660170555115,
            0.3270393908023834,
            -0.024634407833218575,
            -0.33210763335227966,
            0.2276492416858673,
            0.26816797256469727,
            -0.2525882422924042,
            -0.0030901688151061535
        ],
        [
            -0.1350214183330536,
            -0.23442022502422333,
            -0.04416751116514206,
            -0.25738295912742615,
            -0.24651382863521576,
            0.11531737446784973,
            0.3262713849544525,
            0.0734875276684761,
            -0.01675080880522728
        ],
        [
            0.1172126829624176,
            0.3418938219547272,
            -0.0852292850613594,
            -0.0008594612590968609,
            0.08700138330459595,
            -0.24691204726696014,
            -0.1720321923494339,
            -0.13969312608242035,
            0.3103519082069397
        ],
        [
            -0.07940500229597092,
            0.2833975851535797,
            0.09770892560482025,
            0.29396557807922363,
            -0.007398703135550022,
            0.2738925814628601,
            0.2540692090988159,
            -0.2728271186351776,
            0.07433950155973434
        ],
        [
            -0.29154467582702637,
            -0.09267108142375946,
            -0.19275783002376556,
            0.09122049063444138,
            0.15831363201141357,
            0.28069591522216797,
            -0.057920943945646286,
            -0.04066580533981323,
            -0.334328830242157
        ],
        [
            -0.12295945733785629,
            0.310629278421402,
            -0.021535024046897888,
            0.26655474305152893,
            -0.123078852891922,
            0.13907000422477722,
            -0.005214137956500053,
            0.06370590627193451,
            -0.060185760259628296
        ],
        [
            0.24525171518325806,
            -0.0595814548432827,
            -0.2321309894323349,
            -0.2572902739048004,
            0.225664421916008,
            0.07070980966091156,
            0.16615179181098938,
            0.13532103598117828,
            -0.019941825419664383
        ],
        [
            -0.133987158536911,
            0.24152809381484985,
            0.3024068772792816,
            -0.10015826672315598,
            0.19272223114967346,
            -0.1410616636276245,
            0.16455689072608948,
            0.3038868010044098,
            0.3247334063053131
        ],
        [
            0.19389508664608002,
            0.1771230399608612,
            -0.326885849237442,
            -0.07683795690536499,
            -0.3123936057090759,
            -0.2823066711425781,
            -0.06718634814023972,
            0.15368567407131195,
            0.2103421986103058
        ],
        [
            -0.16326774656772614,
            -0.027411697432398796,
            0.2763419449329376,
            -0.25492987036705017,
            0.20474886894226074,
            0.2743089497089386,
            -0.31759220361709595,
            0.09564683586359024,
            0.11156056821346283
        ],
        [
            0.1866634488105774,
            0.25565433502197266,
            0.07676952332258224,
            0.047846801578998566,
            -0.08071262389421463,
            0.29370930790901184,
            -0.29350706934928894,
            0.3022805452346802,
            -0.23177547752857208
        ],
        [
            0.2383682131767273,
            -0.26584872603416443,
            -0.1554022580385208,
            0.042209889739751816,
            -0.202945739030838,
            0.057576682418584824,
            0.04285408556461334,
            0.12599027156829834,
            0.10982267558574677
        ],
        [
            -0.31409502029418945,
            0.37561383843421936,
            -0.2313164323568344,
            0.13654783368110657,
            0.03323839604854584,
            0.07819268852472305,
            0.23774835467338562,
            0.05004017427563667,
            0.0639195516705513
        ],
        [
            0.05011242255568504,
            -0.38054129481315613,
            0.2502920925617218,
            0.1784031242132187,
            -0.19915227591991425,
            0.11187495291233063,
            -0.0728694275021553,
            0.3328363299369812,
            0.07316756248474121
        ],
        [
            0.0789528638124466,
            -0.008203893899917603,
            0.10528545081615448,
            -0.2616477310657501,
            0.2090979665517807,
            -0.3632603883743286,
            0.058550748974084854,
            0.18404005467891693,
            0.08898019045591354
        ],
        [
            -0.03732309862971306,
            -0.10819637775421143,
            0.00736240716651082,
            -0.37437745928764343,
            -0.1632394790649414,
            0.1543373316526413,
            -0.018481150269508362,
            -0.27811771631240845,
            0.061181966215372086
        ],
        [
            -0.11350803822278976,
            -0.09132639318704605,
            -0.10371941328048706,
            -0.17926926910877228,
            -0.17733585834503174,
            0.09823471307754517,
            0.29784199595451355,
            0.11627287417650223,
            0.16889719665050507
        ],
        [
            -0.2842344045639038,
            -0.18046632409095764,
            0.3030725419521332,
            -0.13768772780895233,
            0.03977281600236893,
            0.19465047121047974,
            0.21792174875736237,
            -0.06162000820040703,
            -0.34724169969558716
        ],
        [
            -0.23017436265945435,
            -0.30693450570106506,
            0.2163776457309723,
            0.2803265154361725,
            -0.2659381031990051,
            0.2108016312122345,
            -0.06314496695995331,
            -0.03243172913789749,
            0.25725772976875305
        ],
        [
            0.035289913415908813,
            0.1419605165719986,
            0.010361835360527039,
            -0.23730681836605072,
            -0.006588891614228487,
            -0.39935019612312317,
            -0.21075816452503204,
            0.03180593624711037,
            -0.22826489806175232
        ],
        [
            -0.05257512256503105,
            0.2939053773880005,
            0.27599507570266724,
            -0.11051365733146667,
            0.3054070472717285,
            0.31864553689956665,
            -0.31765231490135193,
            0.14013315737247467,
            0.04778300225734711
        ],
        [
            0.36687618494033813,
            0.21795186400413513,
            0.16863812506198883,
            0.16257931292057037,
            -0.011655705980956554,
            0.25371402502059937,
            0.016332654282450676,
            0.2242247760295868,
            -0.2804269790649414
        ],
        [
            0.12422165274620056,
            -0.2171618491411209,
            0.331932932138443,
            0.3102210760116577,
            -0.07004483044147491,
            0.053695790469646454,
            -0.10920831561088562,
            -0.30528396368026733,
            -0.09941743314266205
        ],
        [
            0.11360246688127518,
            0.06030692160129547,
            0.3014426827430725,
            0.2613782286643982,
            0.32784584164619446,
            0.186314195394516,
            0.3187643587589264,
            0.24507132172584534,
            0.2807117700576782
        ],
        [
            0.24627430737018585,
            -0.06579194217920303,
            -0.15516060590744019,
            0.36160650849342346,
            -0.2741991877555847,
            -0.07950053364038467,
            -0.3439587652683258,
            0.1399346888065338,
            -0.2917945086956024
        ],
        [
            -0.13301855325698853,
            0.05312624201178551,
            0.22883358597755432,
            0.29878032207489014,
            -0.08407598733901978,
            -0.2541981041431427,
            -0.2329026162624359,
            0.24017144739627838,
            -0.23077964782714844
        ],
        [
            -0.23444385826587677,
            -0.047650791704654694,
            0.246197909116745,
            0.14523634314537048,
            -0.009853378869593143,
            0.10633376985788345,
            0.10190782696008682,
            -0.2795390784740448,
            0.025188753381371498
        ],
        [
            -0.1894855797290802,
            -0.07327861338853836,
            -0.3342326879501343,
            0.08074329793453217,
            -0.23399758338928223,
            0.12372514605522156,
            -0.29912176728248596,
            -0.038502637296915054,
            0.07004740089178085
        ],
        [
            -0.34608957171440125,
            0.34502294659614563,
            -0.028648221865296364,
            0.13241051137447357,
            0.29800528287887573,
            0.20285075902938843,
            0.029384545981884003,
            0.31702977418899536,
            0.1866135150194168
        ],
        [
            -0.22854594886302948,
            -0.1269807517528534,
            0.32679638266563416,
            0.15212829411029816,
            -0.2561558783054352,
            0.09515039622783661,
            -0.15758714079856873,
            -0.19490312039852142,
            -0.23860864341259003
        ],
        [
            -0.008293472230434418,
            0.17574334144592285,
            0.266337513923645,
            0.03973972424864769,
            0.0849815160036087,
            0.34411147236824036,
            0.27902984619140625,
            0.09814109653234482,
            -0.2239486426115036
        ],
        [
            0.2872442305088043,
            0.2969205677509308,
            -0.2991083562374115,
            -0.3142412304878235,
            0.09209875017404556,
            -0.1730061024427414,
            0.2562253773212433,
            -0.07526085525751114,
            0.1585598587989807
        ],
        [
            0.11753019690513611,
            0.24299460649490356,
            0.00836471188813448,
            -0.14817439019680023,
            -0.14330410957336426,
            -0.25978919863700867,
            -0.12409657984972,
            -0.3218601942062378,
            -0.3091111481189728
        ],
        [
            -0.21270246803760529,
            0.27085044980049133,
            0.31923454999923706,
            -0.2675074338912964,
            -0.05958918109536171,
            -0.04625396057963371,
            0.3169453740119934,
            0.08469193428754807,
            0.21363011002540588
        ],
        [
            0.02287149243056774,
            -0.0005238404264673591,
            0.09131602942943573,
            0.16887032985687256,
            0.23002852499485016,
            0.12459035962820053,
            -0.10131783783435822,
            -0.02712208963930607,
            0.11692305654287338
        ],
        [
            -0.20468473434448242,
            0.24895069003105164,
            0.26190999150276184,
            -0.013290965929627419,
            -0.0017451423918828368,
            0.1021123081445694,
            0.1854047030210495,
            0.08089055120944977,
            -0.3119918406009674
        ],
        [
            0.16555151343345642,
            -0.3588089942932129,
            -0.08301914483308792,
            0.2852647602558136,
            0.32277047634124756,
            0.16272921860218048,
            -0.24103352427482605,
            -0.03238678351044655,
            0.06463290005922318
        ],
        [
            0.006872197147458792,
            -0.3219427466392517,
            -0.2727240324020386,
            0.27054309844970703,
            -0.2891208529472351,
            0.21059365570545197,
            0.030942007899284363,
            0.3318973779678345,
            -0.1894228607416153
        ],
        [
            0.007603449746966362,
            0.3035256862640381,
            -0.15119382739067078,
            -0.08713078498840332,
            0.14842276275157928,
            0.17388759553432465,
            -0.35619115829467773,
            0.03966010361909866,
            0.061761993914842606
        ],
        [
            0.12012197822332382,
            -0.18079352378845215,
            0.0930207148194313,
            0.16258502006530762,
            0.23659728467464447,
            0.3220229148864746,
            0.2778695821762085,
            -0.057692959904670715,
            0.14501963555812836
        ],
        [
            -0.06028039753437042,
            -0.10362184047698975,
            -0.02540084533393383,
            -0.37784072756767273,
            -0.15346750617027283,
            -0.043402913957834244,
            0.06419876962900162,
            0.06044906750321388,
            0.2985494136810303
        ],
        [
            -0.04172009229660034,
            -0.023430930450558662,
            -0.18502481281757355,
            0.236481174826622,
            0.32663312554359436,
            0.046553779393434525,
            0.07130829244852066,
            0.18235871195793152,
            0.15058711171150208
        ],
        [
            0.06233876943588257,
            -0.04660915583372116,
            -0.07872360944747925,
            0.12408611923456192,
            0.18645945191383362,
            -0.08797436207532883,
            -0.35902291536331177,
            -0.09210962057113647,
            -0.18797260522842407
        ],
        [
            0.008041334338486195,
            0.2490970343351364,
            0.3226248025894165,
            0.2891294062137604,
            -0.26876577734947205,
            -0.08727792650461197,
            -0.026016367599368095,
            0.02159556746482849,
            0.1404000073671341
        ],
        [
            -0.2841046154499054,
            -0.1506078690290451,
            0.010611430741846561,
            0.11282573640346527,
            0.13888712227344513,
            -0.32195597887039185,
            -0.0694156289100647,
            -0.08109566569328308,
            0.16043612360954285
        ],
        [
            -0.32186707854270935,
            -0.22288773953914642,
            -0.193002387881279,
            0.22426915168762207,
            0.12958569824695587,
            -0.07502651959657669,
            -0.17301324009895325,
            -0.01840505376458168,
            0.09290289133787155
        ],
        [
            -0.2469368875026703,
            0.1316944807767868,
            0.3348295986652374,
            -0.17775040864944458,
            0.3089583218097687,
            -0.2749214172363281,
            0.25730156898498535,
            -0.06030147522687912,
            0.19799835979938507
        ],
        [
            -0.2522432208061218,
            -0.21614637970924377,
            0.20270280539989471,
            -0.13190479576587677,
            0.1282956749200821,
            -0.1474010944366455,
            0.09426001459360123,
            -0.193100705742836,
            -0.0970364511013031
        ],
        [
            0.2448929399251938,
            -0.13060198724269867,
            -0.38431933522224426,
            -0.12867200374603271,
            0.02038133144378662,
            0.21717563271522522,
            -0.15946751832962036,
            0.11529559642076492,
            0.09841334074735641
        ],
        [
            -0.35099732875823975,
            0.09729272127151489,
            -0.11560983955860138,
            -0.3413363993167877,
            0.270041286945343,
            -0.274983674287796,
            0.25505468249320984,
            0.14055189490318298,
            -0.00020296534057706594
        ],
        [
            -0.20056359469890594,
            -0.140249103307724,
            0.006720259785652161,
            0.21369589865207672,
            -0.2406456172466278,
            -0.3107876181602478,
            0.006489708088338375,
            -0.3077610433101654,
            -0.02881384827196598
        ],
        [
            0.19020496308803558,
            -0.2334216684103012,
            -0.2418256551027298,
            -0.05105996131896973,
            -0.24250362813472748,
            0.2754250466823578,
            0.11891019344329834,
            0.06447236239910126,
            -0.33729657530784607
        ],
        [
            -0.23719781637191772,
            -0.1003250926733017,
            -0.10793640464544296,
            0.35423675179481506,
            -0.20254546403884888,
            -0.18176540732383728,
            0.022711342200636864,
            0.10427653789520264,
            0.1602248251438141
        ],
        [
            -0.1598321944475174,
            -0.1883857399225235,
            0.1469796746969223,
            -0.21865126490592957,
            0.20034798979759216,
            -0.08124618977308273,
            0.35405582189559937,
            0.029102586209774017,
            -0.30487802624702454
        ],
        [
            0.2830076515674591,
            -0.21954946219921112,
            -0.35246986150741577,
            -0.10114286094903946,
            0.35441854596138,
            0.16771431267261505,
            0.09922128915786743,
            -0.3017057180404663,
            0.1250501424074173
        ],
        [
            0.03836945444345474,
            -0.07847269624471664,
            -0.054556962102651596,
            -0.14044059813022614,
            0.30929088592529297,
            0.300075888633728,
            0.2612636983394623,
            -0.33759602904319763,
            -0.07474909722805023
        ],
        [
            -0.22129657864570618,
            0.05555843561887741,
            0.323138028383255,
            -0.07030914723873138,
            -0.16403263807296753,
            0.2605293095111847,
            -0.12169574201107025,
            -0.11808514595031738,
            0.3087010681629181
        ],
        [
            -0.2653948962688446,
            0.27895408868789673,
            -0.2844695448875427,
            0.28854435682296753,
            -0.14551319181919098,
            0.16315633058547974,
            0.3355858027935028,
            0.06108368560671806,
            -0.22514608502388
        ],
        [
            -0.017435364425182343,
            -0.1787121593952179,
            -0.3012124001979828,
            -0.2449035346508026,
            0.1027851477265358,
            -0.19940303266048431,
            -0.0032028413843363523,
            0.3144659698009491,
            -0.2897026836872101
        ],
        [
            -0.19080345332622528,
            0.010143652558326721,
            0.08142992854118347,
            -0.2576303780078888,
            0.028592538088560104,
            -0.3345816433429718,
            -0.07770872861146927,
            0.2794349491596222,
            0.27078017592430115
        ],
        [
            -0.16282205283641815,
            0.10511307418346405,
            0.1015157550573349,
            0.26904794573783875,
            -0.019367091357707977,
            0.26534897089004517,
            0.36127355694770813,
            -0.04735546186566353,
            -0.18006695806980133
        ],
        [
            0.2610039710998535,
            0.022993430495262146,
            0.056269675493240356,
            -0.19663061201572418,
            -0.06643154472112656,
            0.014485614374279976,
            -0.33643558621406555,
            -0.16022799909114838,
            0.05731618031859398
        ],
        [
            -0.12527650594711304,
            0.00356783764436841,
            0.22260700166225433,
            -0.17375798523426056,
            0.22125154733657837,
            0.23565982282161713,
            0.1910463124513626,
            0.2702628970146179,
            -0.21546854078769684
        ],
        [
            -0.21482674777507782,
            0.00657533947378397,
            -0.006437106989324093,
            0.2859172523021698,
            -0.17848078906536102,
            0.06693647056818008,
            0.14177992939949036,
            -0.019057175144553185,
            0.05730253458023071
        ],
        [
            0.13121744990348816,
            -0.11215284466743469,
            0.18456612527370453,
            0.1517868936061859,
            -0.014852899126708508,
            0.30442124605178833,
            0.06891684234142303,
            -0.2757013738155365,
            -0.3736184239387512
        ],
        [
            -0.30145978927612305,
            -0.23885703086853027,
            -0.12100691348314285,
            0.166621595621109,
            -0.06511784344911575,
            0.3348330557346344,
            0.03244940936565399,
            -0.3179353177547455,
            0.29010656476020813
        ],
        [
            0.17985016107559204,
            -0.18294304609298706,
            -0.12751443684101105,
            0.2756304442882538,
            0.32087624073028564,
            -0.24231977760791779,
            0.26078513264656067,
            -0.02282584086060524,
            -0.0423123799264431
        ],
        [
            0.03996002674102783,
            -0.2709547281265259,
            -0.14587371051311493,
            -0.1344069093465805,
            0.14754308760166168,
            -0.20404596626758575,
            -0.13546699285507202,
            -0.3737749755382538,
            0.33657753467559814
        ],
        [
            -0.10346406698226929,
            -0.32200706005096436,
            0.24299386143684387,
            0.0995587483048439,
            -0.23759348690509796,
            -0.24468612670898438,
            0.16176706552505493,
            -0.28753408789634705,
            -0.1418122798204422
        ],
        [
            -0.13283875584602356,
            0.2532585859298706,
            0.21171726286411285,
            -0.1366187036037445,
            -0.25873321294784546,
            -0.15869618952274323,
            -0.17062771320343018,
            -0.370712548494339,
            -0.006843328010290861
        ],
        [
            0.2810540497303009,
            -0.015069353394210339,
            0.29963022470474243,
            0.08880320936441422,
            -0.2791743278503418,
            0.046103786677122116,
            0.20443975925445557,
            0.04033339396119118,
            -0.28696131706237793
        ],
        [
            0.23911044001579285,
            -0.23388181626796722,
            0.05813933536410332,
            0.3276636004447937,
            0.13373562693595886,
            0.19216331839561462,
            -0.08746065944433212,
            0.307539165019989,
            -0.03756854310631752
        ],
        [
            -0.32732826471328735,
            -0.10522300004959106,
            -0.17533978819847107,
            -0.0165419839322567,
            -0.08734966069459915,
            0.08650568872690201,
            -0.1063094288110733,
            0.2972562611103058,
            0.283448725938797
        ],
        [
            0.17216262221336365,
            0.059242136776447296,
            0.20913130044937134,
            0.3356322944164276,
            -0.06612025946378708,
            0.013075171038508415,
            -0.2398606240749359,
            0.18529218435287476,
            0.22848014533519745
        ],
        [
            -0.1458713263273239,
            0.029321473091840744,
            0.1892610341310501,
            -0.03400437533855438,
            -0.09769882261753082,
            0.1548428237438202,
            0.08299123495817184,
            -0.07043745368719101,
            -0.027775216847658157
        ],
        [
            0.15053023397922516,
            0.08226581662893295,
            -0.09127361327409744,
            -0.15503202378749847,
            0.10394099354743958,
            -0.24713698029518127,
            0.07055801153182983,
            0.2634347975254059,
            -0.29159149527549744
        ],
        [
            0.07790321856737137,
            0.2060374617576599,
            -0.18152128159999847,
            -0.009392168372869492,
            -0.08503526449203491,
            -0.1656097024679184,
            -0.045700740069150925,
            0.2436550408601761,
            -0.030012108385562897
        ],
        [
            0.31851959228515625,
            -0.128820538520813,
            0.13931173086166382,
            -0.0824817344546318,
            -0.35527125000953674,
            -0.17622552812099457,
            -0.17051145434379578,
            -0.07807441055774689,
            0.1175546944141388
        ],
        [
            -0.10247637331485748,
            -0.06447848677635193,
            -0.08220172673463821,
            0.1575913280248642,
            -0.01728527992963791,
            0.011954713612794876,
            0.33866655826568604,
            0.08177835494279861,
            -0.26061195135116577
        ],
        [
            0.3628092408180237,
            -0.06837742775678635,
            0.09263026714324951,
            0.023991845548152924,
            0.09700387716293335,
            0.038459595292806625,
            -0.17248296737670898,
            -0.035397641360759735,
            -0.20665647089481354
        ],
        [
            0.3514004945755005,
            0.0237136110663414,
            0.25499841570854187,
            -0.26598113775253296,
            -0.05099764093756676,
            -0.3620878756046295,
            0.09875990450382233,
            -0.0064543383195996284,
            0.05999229848384857
        ],
        [
            0.034905146807432175,
            0.11970341950654984,
            0.13950586318969727,
            -0.2999270260334015,
            0.1347670555114746,
            -0.2874455451965332,
            -0.16497831046581268,
            0.3162481188774109,
            -0.2953001856803894
        ],
        [
            0.10718624293804169,
            0.12108699232339859,
            -0.3052538335323334,
            -0.06699497252702713,
            0.23439152538776398,
            -0.27769577503204346,
            -0.019481346011161804,
            0.22021609544754028,
            -0.06053103879094124
        ],
        [
            -0.22704149782657623,
            -0.22043249011039734,
            0.23492974042892456,
            -0.006602251902222633,
            0.2457936555147171,
            0.31224942207336426,
            -0.18600969016551971,
            0.017345502972602844,
            -0.3759942650794983
        ],
        [
            0.11881096661090851,
            0.01845303550362587,
            0.2734815180301666,
            -0.16689535975456238,
            -0.32017144560813904,
            -0.13650445640087128,
            -0.2781515121459961,
            0.33178284764289856,
            -0.2781692445278168
        ],
        [
            -0.05857325717806816,
            -0.2279200255870819,
            0.011297562159597874,
            0.1662493646144867,
            -0.22393526136875153,
            -0.16640642285346985,
            -0.028274934738874435,
            -0.17404180765151978,
            -0.21720345318317413
        ],
        [
            -0.22509390115737915,
            0.1909509301185608,
            -0.008588558994233608,
            0.0901583880186081,
            0.19982391595840454,
            -0.2723889648914337,
            0.1728828251361847,
            -0.16481494903564453,
            0.27574706077575684
        ],
        [
            0.1341204196214676,
            0.13105954229831696,
            -0.04425908625125885,
            0.32110893726348877,
            -0.2661874294281006,
            0.18097931146621704,
            -0.03484566509723663,
            -0.2411694973707199,
            -0.280583918094635
        ],
        [
            -0.14718297123908997,
            0.1648131012916565,
            0.22392213344573975,
            -0.14033500850200653,
            0.11412519216537476,
            -0.1348361074924469,
            -0.2946135401725769,
            -0.30216023325920105,
            0.1098516583442688
        ],
        [
            0.13268133997917175,
            -0.3586406707763672,
            0.03232293948531151,
            0.12287316471338272,
            -0.2141747772693634,
            -0.010757778771221638,
            0.12912407517433167,
            0.321675568819046,
            0.091605544090271
        ],
        [
            -0.17587263882160187,
            -0.3750510811805725,
            -0.09195727854967117,
            -0.200271338224411,
            0.15506494045257568,
            -0.10129689425230026,
            -0.09906776994466782,
            0.35594868659973145,
            0.05079471692442894
        ],
        [
            -0.10118317604064941,
            0.11772283911705017,
            0.26668021082878113,
            -0.03343324735760689,
            0.25200045108795166,
            0.0062904199585318565,
            0.07063483446836472,
            0.039039112627506256,
            -0.26502907276153564
        ],
        [
            -0.013001704588532448,
            0.2597814202308655,
            0.048279549926519394,
            -0.16216908395290375,
            -0.10895209014415741,
            -0.03121998719871044,
            0.3230994641780853,
            -0.30361470580101013,
            0.2352888435125351
        ],
        [
            -0.10314737260341644,
            0.3020879030227661,
            -0.18893297016620636,
            0.3384430706501007,
            -0.1317581981420517,
            -0.25332313776016235,
            0.1442928910255432,
            -0.24290421605110168,
            0.02970387041568756
        ],
        [
            0.0441836379468441,
            -0.37173622846603394,
            0.08065258711576462,
            0.15353594720363617,
            0.08373016119003296,
            -0.008878384716808796,
            0.16478778421878815,
            0.051153190433979034,
            -0.35385456681251526
        ],
        [
            0.18525423109531403,
            -0.14535491168498993,
            -0.16165266931056976,
            -0.1345004439353943,
            0.32785260677337646,
            -0.11283046752214432,
            -0.11238808184862137,
            -0.22522081434726715,
            0.03436431288719177
        ],
        [
            -0.21767228841781616,
            -0.29711419343948364,
            0.31246086955070496,
            -0.15009985864162445,
            -0.19482460618019104,
            0.037319280207157135,
            -0.31679418683052063,
            -0.2863723635673523,
            0.17323018610477448
        ],
        [
            0.08826152980327606,
            0.29374146461486816,
            -0.0029544332064688206,
            -0.3118972182273865,
            -0.11389725655317307,
            -0.044301439076662064,
            -0.14963991940021515,
            0.15904289484024048,
            -0.3982521593570709
        ],
        [
            -0.05045599862933159,
            0.26509803533554077,
            -0.1804332733154297,
            -0.23419032990932465,
            -0.03909582644701004,
            -0.3456762731075287,
            0.0741366595029831,
            0.023598026484251022,
            0.054567452520132065
        ],
        [
            0.10356598347425461,
            0.16215431690216064,
            0.2065940499305725,
            -0.265583336353302,
            -0.3563384711742401,
            -0.01976994052529335,
            -0.09838107973337173,
            -0.0836598351597786,
            -0.1450665295124054
        ],
        [
            0.04136212542653084,
            -0.0850483849644661,
            -0.3402409553527832,
            0.3239823877811432,
            -0.22308187186717987,
            0.03692718595266342,
            0.07250304520130157,
            0.25970929861068726,
            0.06292697787284851
        ],
        [
            -0.01316625252366066,
            -0.14609143137931824,
            0.28978848457336426,
            -0.08589864522218704,
            0.27989643812179565,
            -0.2932673394680023,
            -0.09491808712482452,
            -0.061279021203517914,
            0.291126012802124
        ],
        [
            -0.24643534421920776,
            0.19870342314243317,
            -0.10271263867616653,
            -0.26339322328567505,
            -0.17322084307670593,
            0.2986018657684326,
            0.3385222852230072,
            0.0014969996409490705,
            -0.22471247613430023
        ],
        [
            0.14035095274448395,
            -0.26637476682662964,
            0.35003218054771423,
            0.13753251731395721,
            0.12065333127975464,
            0.28143933415412903,
            0.11513867974281311,
            0.07278742641210556,
            0.2474125325679779
        ],
        [
            -0.31936076283454895,
            -0.2021571695804596,
            -0.20940527319908142,
            -0.1546870619058609,
            0.20039570331573486,
            -0.11268260329961777,
            -0.330081045627594,
            0.3434276878833771,
            0.04838225245475769
        ],
        [
            -0.03234865888953209,
            -0.19228331744670868,
            0.008690382353961468,
            0.20429401099681854,
            -0.19795826077461243,
            -0.24682234227657318,
            0.21684004366397858,
            -0.10059378296136856,
            -0.283587247133255
        ],
        [
            0.22579875588417053,
            -0.06068432703614235,
            -0.2257060557603836,
            0.03557809442281723,
            0.273165762424469,
            -0.036760374903678894,
            -0.24104687571525574,
            0.06918423622846603,
            0.01613660715520382
        ],
        [
            0.2183099091053009,
            0.29372408986091614,
            0.04988415911793709,
            -0.245989590883255,
            -0.10415251553058624,
            0.06594997644424438,
            -0.08242982625961304,
            0.15363682806491852,
            0.14262810349464417
        ],
        [
            -0.2107612043619156,
            -0.09763835370540619,
            -0.1283147633075714,
            0.18417610228061676,
            0.22287915647029877,
            -0.11933986097574234,
            0.0318760946393013,
            0.16376855969429016,
            -0.09228227287530899
        ],
        [
            0.3734983801841736,
            0.26102492213249207,
            -0.11371976137161255,
            0.11982391029596329,
            0.20589803159236908,
            0.08605673164129257,
            -0.27919450402259827,
            -0.25657370686531067,
            -0.2861933708190918
        ],
        [
            -0.20593222975730896,
            -0.08813415467739105,
            -0.0672358050942421,
            0.2195872664451599,
            0.3011806905269623,
            0.04992568865418434,
            0.043381836265325546,
            -0.1665067970752716,
            0.21733702719211578
        ],
        [
            0.2964588701725006,
            0.0265920702368021,
            0.3030177354812622,
            -0.019448334351181984,
            0.04281679540872574,
            0.3173077702522278,
            0.2575318515300751,
            -0.061924904584884644,
            0.1983799934387207
        ],
        [
            0.045976970344781876,
            0.26773273944854736,
            -0.0095573915168643,
            -0.2447088360786438,
            0.14187973737716675,
            0.06190995126962662,
            0.10654368996620178,
            -0.2004850208759308,
            0.02855140157043934
        ],
        [
            -0.3664548695087433,
            -0.2056935876607895,
            -0.26626506447792053,
            0.24289903044700623,
            -0.2060191035270691,
            0.039733607321977615,
            -0.37959161400794983,
            0.24606385827064514,
            0.026660488918423653
        ],
        [
            0.16321875154972076,
            0.03897600248456001,
            0.22674743831157684,
            -0.2181321382522583,
            -0.20546308159828186,
            0.25266188383102417,
            -0.015598426572978497,
            0.025804584845900536,
            -0.17714270949363708
        ],
        [
            -0.1463254690170288,
            -0.33377188444137573,
            0.15599803626537323,
            -0.27765005826950073,
            0.10621731728315353,
            0.1028544083237648,
            -0.09641239047050476,
            0.10864792764186859,
            0.13091999292373657
        ],
        [
            0.11485307663679123,
            0.05345495417714119,
            0.2145247757434845,
            0.1227169930934906,
            0.13171342015266418,
            -0.10547108948230743,
            0.31213584542274475,
            -0.2437901496887207,
            0.0030868675094097853
        ],
        [
            0.22632914781570435,
            0.19612284004688263,
            0.02419855445623398,
            0.26674893498420715,
            -0.02659183368086815,
            -0.025347156450152397,
            0.08387666195631027,
            -0.12238524109125137,
            -0.22655048966407776
        ],
        [
            -0.36148858070373535,
            -0.12138433754444122,
            0.34431159496307373,
            -0.29650598764419556,
            -0.10436343401670456,
            0.17164932191371918,
            0.06950632482767105,
            -0.10301575809717178,
            0.22409652173519135
        ],
        [
            0.2656795382499695,
            0.11380430310964584,
            0.11130576580762863,
            -0.0858570784330368,
            0.30631500482559204,
            -0.15992885828018188,
            0.10362409800291061,
            -0.26645198464393616,
            -0.00016346578195225447
        ],
        [
            -0.12088556587696075,
            -0.293335497379303,
            0.22795605659484863,
            -0.2083865851163864,
            0.22831130027770996,
            0.29740357398986816,
            -0.005161127541214228,
            0.09559424966573715,
            0.08256871998310089
        ],
        [
            0.15781641006469727,
            0.1668083369731903,
            0.25759178400039673,
            -0.23249222338199615,
            -0.17176562547683716,
            -0.11970198154449463,
            -0.395063191652298,
            -0.0823284313082695,
            -0.003931383136659861
        ],
        [
            -0.3390667140483856,
            0.24782894551753998,
            -0.04217023774981499,
            -0.2609062194824219,
            0.026120366528630257,
            0.320661723613739,
            -0.13401174545288086,
            0.3357246220111847,
            -0.14114689826965332
        ],
        [
            -0.1030210629105568,
            -0.2855246365070343,
            0.20364905893802643,
            0.20765258371829987,
            0.10051020979881287,
            -0.07540386915206909,
            0.2645060420036316,
            -0.2595467269420624,
            -0.2976991832256317
        ],
        [
            -0.22568745911121368,
            0.08595087379217148,
            0.24278861284255981,
            -0.3495852053165436,
            -0.30507153272628784,
            -0.2192949652671814,
            -0.08642681688070297,
            0.19674454629421234,
            0.06256937980651855
        ],
        [
            0.2579115927219391,
            -0.03457314521074295,
            0.2353564202785492,
            -0.06924543529748917,
            -0.2572964131832123,
            0.13733619451522827,
            0.30302438139915466,
            0.3292018473148346,
            0.1557946503162384
        ],
        [
            -0.3705345690250397,
            0.21797171235084534,
            0.22135508060455322,
            -0.19542348384857178,
            0.29080426692962646,
            -0.27751660346984863,
            0.007770239841192961,
            -0.24935513734817505,
            0.14993835985660553
        ],
        [
            0.34400051832199097,
            -0.19166730344295502,
            -0.20274579524993896,
            0.28741851449012756,
            -0.11884435266256332,
            0.3190426826477051,
            -0.03170885890722275,
            0.06322744488716125,
            0.2917473614215851
        ],
        [
            -0.2977368235588074,
            0.2908118963241577,
            0.172661691904068,
            0.31807389855384827,
            0.22657698392868042,
            0.17919357120990753,
            0.17152082920074463,
            -0.34737592935562134,
            -0.27630454301834106
        ],
        [
            -0.13008736073970795,
            -0.2434651106595993,
            0.1306418776512146,
            -0.14533837139606476,
            0.07690754532814026,
            0.1339542716741562,
            0.20095989108085632,
            -0.21545568108558655,
            0.2125658541917801
        ],
        [
            -0.28453367948532104,
            -0.0558999702334404,
            0.2173231542110443,
            -0.21578368544578552,
            0.16694390773773193,
            -0.17610186338424683,
            -0.21473075449466705,
            0.09679529070854187,
            0.06816963851451874
        ],
        [
            0.20698852837085724,
            -0.1794564127922058,
            -0.11115654557943344,
            0.22300148010253906,
            0.0972495973110199,
            0.09108945727348328,
            0.1251947581768036,
            -0.284467488527298,
            -0.2626957893371582
        ],
        [
            -0.06648557633161545,
            0.07868780940771103,
            -0.16435891389846802,
            0.10449409484863281,
            0.1021261066198349,
            -0.16840481758117676,
            0.03184103965759277,
            0.14577369391918182,
            -0.019051218405365944
        ],
        [
            0.3113786280155182,
            0.05978783965110779,
            0.2934380769729614,
            0.33170756697654724,
            -0.22028499841690063,
            0.24548077583312988,
            0.1174413189291954,
            0.26908332109451294,
            -0.08394806832075119
        ],
        [
            0.2718571126461029,
            -0.03543437644839287,
            0.08964986354112625,
            0.3280790150165558,
            0.17748555541038513,
            -0.009264863096177578,
            -0.2871675193309784,
            0.10052595287561417,
            0.2129589319229126
        ],
        [
            0.38275861740112305,
            0.13857784867286682,
            -0.2155156433582306,
            -0.20821037888526917,
            -0.08474928885698318,
            -0.06696767359972,
            -0.2540077269077301,
            -0.05556756630539894,
            0.01203030627220869
        ],
        [
            0.2988635301589966,
            -0.09481912106275558,
            -0.05409051850438118,
            0.3486218750476837,
            0.16596968472003937,
            -0.24675729870796204,
            -0.2823216915130615,
            0.13147486746311188,
            0.07158961147069931
        ],
        [
            -0.11817750334739685,
            -0.26139092445373535,
            0.10727787017822266,
            -0.1682988554239273,
            0.2538321316242218,
            -0.17324791848659515,
            0.24058783054351807,
            -0.05448320880532265,
            -0.06706193089485168
        ],
        [
            -0.07473348826169968,
            -0.13292765617370605,
            -0.29493048787117004,
            -0.1387423425912857,
            -0.00038042484084144235,
            0.2769720256328583,
            0.2943519949913025,
            -0.19462934136390686,
            0.11556441336870193
        ],
        [
            -0.2261011302471161,
            0.18351557850837708,
            -0.16773323714733124,
            0.0511179119348526,
            -0.11344190686941147,
            -0.26880425214767456,
            0.2668658196926117,
            -0.18102239072322845,
            -0.29009273648262024
        ],
        [
            0.16646434366703033,
            0.04498208314180374,
            0.017161520197987556,
            -0.27107611298561096,
            -0.06186723709106445,
            0.28391000628471375,
            -0.23631678521633148,
            0.21385665237903595,
            -0.3144516348838806
        ],
        [
            -0.14148589968681335,
            0.16410647332668304,
            0.2757628262042999,
            0.11003095656633377,
            -0.35203635692596436,
            0.11340399086475372,
            -0.19125431776046753,
            -0.2304667830467224,
            0.03730905428528786
        ],
        [
            0.07152895629405975,
            0.34163588285446167,
            0.13475172221660614,
            -0.08739938586950302,
            0.06141442432999611,
            -0.15090152621269226,
            -0.11707039177417755,
            -0.22131358087062836,
            0.09625865519046783
        ],
        [
            -0.12796150147914886,
            0.30577248334884644,
            -0.11478474736213684,
            0.04841882362961769,
            0.3074638545513153,
            -0.25049927830696106,
            0.01975853182375431,
            -0.152366042137146,
            0.28841161727905273
        ],
        [
            -0.002564346417784691,
            -0.3046695590019226,
            0.10909900814294815,
            -0.12623943388462067,
            0.023382481187582016,
            0.1291557401418686,
            -0.020294716581702232,
            0.009663947857916355,
            0.2750399112701416
        ],
        [
            -0.2554762363433838,
            0.3328375518321991,
            -0.1407182514667511,
            -0.29172149300575256,
            -0.28075435757637024,
            0.13567893207073212,
            -0.2605466842651367,
            0.07601992785930634,
            0.08848849684000015
        ],
        [
            0.28845828771591187,
            -0.1314033716917038,
            0.3351559042930603,
            -0.12428709864616394,
            -0.15209345519542694,
            0.0672682672739029,
            0.02226240187883377,
            -0.04125278443098068,
            0.24036464095115662
        ],
        [
            0.2110588699579239,
            0.335548996925354,
            -0.1188572570681572,
            -0.057106949388980865,
            -0.17568260431289673,
            -0.07548969238996506,
            -0.05705281347036362,
            0.02262832783162594,
            0.03951288014650345
        ],
        [
            0.2696632146835327,
            0.013626769185066223,
            -0.3166661560535431,
            0.03726956620812416,
            0.20334167778491974,
            0.19564317166805267,
            0.13749535381793976,
            -0.29996341466903687,
            0.21028076112270355
        ],
        [
            0.1447373628616333,
            0.3186303377151489,
            -0.32294130325317383,
            0.007878366857767105,
            0.027476396411657333,
            -0.20921988785266876,
            -0.20198212563991547,
            0.14073500037193298,
            -0.3146459758281708
        ],
        [
            0.34282806515693665,
            0.14375624060630798,
            0.08391296118497849,
            0.28501614928245544,
            -0.2743542194366455,
            -0.1308913379907608,
            0.004606469068676233,
            0.012372707948088646,
            -0.354919970035553
        ],
        [
            0.1981024295091629,
            -0.12976205348968506,
            -0.1696426421403885,
            0.16044391691684723,
            0.0035774288699030876,
            -0.2972659170627594,
            0.31921711564064026,
            -0.07218291610479355,
            -0.030456164851784706
        ],
        [
            -0.30921539664268494,
            -0.11283286660909653,
            -0.10286884754896164,
            -0.11053923517465591,
            0.14338593184947968,
            0.19279977679252625,
            0.2103092074394226,
            0.2691594064235687,
            0.24740485846996307
        ],
        [
            -0.28628361225128174,
            0.15342792868614197,
            0.1306702196598053,
            0.18811045587062836,
            -0.23922184109687805,
            -0.1403569132089615,
            -0.27590933442115784,
            0.10711677372455597,
            -0.30053025484085083
        ],
        [
            -0.254423588514328,
            -0.24623049795627594,
            0.2614487111568451,
            0.2263363003730774,
            -0.07167867571115494,
            -0.253648966550827,
            0.3244217038154602,
            0.09127021580934525,
            0.31093135476112366
        ],
        [
            -0.0526544526219368,
            0.12097778171300888,
            -0.10224810242652893,
            0.29831165075302124,
            0.026742571964859962,
            0.2546546459197998,
            0.32957518100738525,
            -0.35494595766067505,
            0.1245722696185112
        ],
        [
            0.053731661289930344,
            0.32400575280189514,
            -0.36275792121887207,
            0.30615928769111633,
            -0.12949495017528534,
            0.046657394617795944,
            0.0016829940723255277,
            -0.14546304941177368,
            0.1347605586051941
        ],
        [
            0.13063225150108337,
            -0.35544058680534363,
            -0.35708025097846985,
            -0.1437714844942093,
            0.26887568831443787,
            -0.32997778058052063,
            0.2816721498966217,
            0.01044168509542942,
            -0.16291549801826477
        ],
        [
            0.0833749994635582,
            0.05201200768351555,
            -0.01632671058177948,
            0.06838703155517578,
            -0.14063946902751923,
            0.2431364804506302,
            -0.19869452714920044,
            -0.13499651849269867,
            0.10187947750091553
        ],
        [
            -0.27417007088661194,
            -0.2366916537284851,
            -0.11665721982717514,
            0.22659793496131897,
            -0.18737463653087616,
            -0.125429168343544,
            0.006621929816901684,
            -0.2969478368759155,
            0.1315309703350067
        ],
        [
            0.024989178404211998,
            0.2699934244155884,
            -0.2749742269515991,
            0.06501550227403641,
            -0.19720597565174103,
            0.19864977896213531,
            0.05180742219090462,
            0.0351000651717186,
            -0.06128048896789551
        ],
        [
            0.22897380590438843,
            -0.05312507972121239,
            0.19370783865451813,
            -0.09882207959890366,
            0.1716199666261673,
            -0.01617121882736683,
            -0.11057834327220917,
            -0.06416592746973038,
            0.24800734221935272
        ],
        [
            -0.3000603914260864,
            0.06542372703552246,
            0.3281528353691101,
            -0.2941376268863678,
            0.0899868905544281,
            -0.22874772548675537,
            0.2535816729068756,
            -0.1820899397134781,
            -0.21630805730819702
        ],
        [
            -0.024171026423573494,
            0.06348491460084915,
            -0.10251335799694061,
            0.21191613376140594,
            0.10636170208454132,
            0.2302924245595932,
            0.27283617854118347,
            0.0876171737909317,
            -0.1105082556605339
        ],
        [
            0.21861965954303741,
            0.10229292511940002,
            0.15816862881183624,
            -0.23393839597702026,
            0.07775244861841202,
            0.3618767261505127,
            -0.21454499661922455,
            -0.2872859835624695,
            -0.2184736579656601
        ],
        [
            -0.11126727610826492,
            -0.34065836668014526,
            0.00672919861972332,
            0.29217204451560974,
            0.2955244779586792,
            0.050922464579343796,
            -0.029968837276101112,
            -0.2692905366420746,
            -0.16076791286468506
        ],
        [
            -0.3463622033596039,
            -0.2857901155948639,
            -0.2721336781978607,
            -0.3062950670719147,
            -0.07479146122932434,
            -0.24184538424015045,
            -0.2739720642566681,
            0.09766028076410294,
            -0.036879006773233414
        ],
        [
            -0.30477285385131836,
            -0.16687412559986115,
            -0.10907692462205887,
            0.36959630250930786,
            0.011780976317822933,
            0.26424121856689453,
            0.2180263102054596,
            -0.3215779960155487,
            -0.03222937881946564
        ],
        [
            -0.32074642181396484,
            0.17829950153827667,
            -0.018296511843800545,
            -0.028817903250455856,
            0.21412695944309235,
            0.2229509949684143,
            0.3090871572494507,
            0.24018041789531708,
            -0.24396900832653046
        ],
        [
            -0.12598000466823578,
            0.2513364851474762,
            -0.20379173755645752,
            0.16145825386047363,
            0.3101765811443329,
            -0.24334490299224854,
            0.011824561282992363,
            -0.29464906454086304,
            0.14302824437618256
        ],
        [
            0.24032947421073914,
            0.2585628628730774,
            0.25405609607696533,
            -0.3417622148990631,
            0.09641372412443161,
            0.20407746732234955,
            -0.33299359679222107,
            0.1935206800699234,
            -0.048696696758270264
        ],
        [
            -0.060648370534181595,
            -0.16282960772514343,
            0.007935784757137299,
            -0.07585127651691437,
            -0.28015342354774475,
            -0.2455395758152008,
            0.10951074957847595,
            0.09868550300598145,
            -0.15290729701519012
        ],
        [
            0.05997505038976669,
            0.032424360513687134,
            0.2119683176279068,
            0.1251009702682495,
            0.12759946286678314,
            0.15360970795154572,
            -0.3071718215942383,
            0.2525072395801544,
            0.22480696439743042
        ],
        [
            -0.17570553719997406,
            0.06073002144694328,
            -0.19944597780704498,
            -0.27297091484069824,
            0.08393237739801407,
            0.301668643951416,
            0.26529186964035034,
            -0.014647611416876316,
            0.24750754237174988
        ],
        [
            0.2929147183895111,
            0.14593073725700378,
            -0.03411068022251129,
            0.2893999516963959,
            0.20887278020381927,
            0.004548827651888132,
            0.020192356780171394,
            -0.10647780448198318,
            0.07604583352804184
        ],
        [
            0.22316734492778778,
            -0.20778632164001465,
            -0.03502617031335831,
            0.3035486936569214,
            0.2597244083881378,
            0.1763175129890442,
            -0.30235955119132996,
            0.1974862962961197,
            0.22439847886562347
        ],
        [
            -0.11707157641649246,
            0.06556513160467148,
            -0.016262436285614967,
            -0.05957472696900368,
            -0.3206886053085327,
            0.07683578878641129,
            0.20869064331054688,
            -0.33539652824401855,
            -0.2494933009147644
        ],
        [
            0.24136604368686676,
            0.3338143825531006,
            0.18709294497966766,
            0.18267211318016052,
            0.19149208068847656,
            -0.0839933305978775,
            -0.25236761569976807,
            0.3810550272464752,
            -0.04299861565232277
        ],
        [
            -0.013427799567580223,
            0.11164356023073196,
            0.21158991754055023,
            0.03990313038229942,
            -0.12247471511363983,
            -0.29030099511146545,
            -0.04975588619709015,
            0.1348974108695984,
            0.2532708942890167
        ]
    ],
    "linear_relu_stack.0.bias": [
        -0.14738868176937103,
        -0.09835340827703476,
        0.18824264407157898,
        0.03988534212112427,
        0.23636746406555176,
        -0.22527331113815308,
        0.029848381876945496,
        -0.1739063560962677,
        0.17530497908592224,
        0.16010963916778564,
        -0.0778362974524498,
        0.06211427226662636,
        -0.17853835225105286,
        -0.04543211683630943,
        -0.18049666285514832,
        -0.24752013385295868,
        -0.2660444378852844,
        0.028032811358571053,
        -0.09432978928089142,
        0.2639208734035492,
        0.2837883234024048,
        0.32428982853889465,
        -0.24734079837799072,
        -0.0732930526137352,
        -0.2947442829608917,
        0.14386433362960815,
        -0.2530535161495209,
        -0.1849004030227661,
        0.11082297563552856,
        -0.04595327377319336,
        0.21098032593727112,
        0.30497443675994873,
        0.1499919593334198,
        0.22925499081611633,
        0.09023435413837433,
        -0.08257948607206345,
        0.0008829616126604378,
        0.3048941493034363,
        0.08127926290035248,
        0.31720709800720215,
        -0.22528940439224243,
        -0.009299627505242825,
        -0.14840656518936157,
        0.2114267647266388,
        -0.21537993848323822,
        -0.18378408253192902,
        0.010334611870348454,
        -0.3168841600418091,
        0.021749058738350868,
        -0.28496870398521423,
        -0.1083797737956047,
        -0.035619866102933884,
        -0.14528268575668335,
        -0.0859852284193039,
        -0.29652130603790283,
        -0.09219267964363098,
        0.18278729915618896,
        -0.22832614183425903,
        0.21469981968402863,
        0.2696138918399811,
        0.1186862364411354,
        0.30229681730270386,
        0.011311007663607597,
        0.157577246427536,
        0.03357713669538498,
        -0.03561117872595787,
        -0.25000280141830444,
        0.2516489028930664,
        0.13315775990486145,
        0.09154227375984192,
        0.2386428713798523,
        0.2092677652835846,
        0.24117466807365417,
        0.18582606315612793,
        -0.27234482765197754,
        -0.2869645059108734,
        0.04738781601190567,
        -0.08866537362337112,
        -0.21106915175914764,
        -0.14381682872772217,
        0.14247871935367584,
        -0.29071980714797974,
        0.21891115605831146,
        0.16427281498908997,
        0.18799693882465363,
        0.10418392717838287,
        0.1930166482925415,
        -0.2860049307346344,
        0.11964227259159088,
        -0.14919520914554596,
        -0.10959186404943466,
        0.2570154070854187,
        0.014522681012749672,
        -0.20771163702011108,
        -0.12572161853313446,
        0.17390593886375427,
        -0.26091715693473816,
        -0.13871297240257263,
        -0.28444409370422363,
        0.2638291120529175,
        -0.02230570837855339,
        -0.1945439726114273,
        -0.019246064126491547,
        0.1621101200580597,
        0.2790476083755493,
        0.19619107246398926,
        0.295925110578537,
        0.009608356282114983,
        -0.251382976770401,
        0.1469111293554306,
        -0.0685926154255867,
        -0.08023417741060257,
        0.1872919499874115,
        0.13646216690540314,
        -0.27273762226104736,
        0.2481565773487091,
        0.2519545555114746,
        -0.0668497383594513,
        0.11188384890556335,
        0.1894938349723816,
        0.18272538483142853,
        0.09121039509773254,
        -0.16949592530727386,
        -0.0839669331908226,
        0.2552139461040497,
        0.032671812921762466,
        -0.14692924916744232,
        0.13360361754894257,
        0.24981176853179932,
        0.02849389798939228,
        0.08809135109186172,
        -0.28093141317367554,
        -0.2721543610095978,
        -0.2721274495124817,
        0.011621891520917416,
        0.30154669284820557,
        0.06591451168060303,
        -0.12142271548509598,
        0.0567341148853302,
        -0.05820530280470848,
        0.24234089255332947,
        0.23576660454273224,
        -0.1409723311662674,
        0.1300705373287201,
        -0.24761579930782318,
        -0.1281682252883911,
        -0.12892553210258484,
        0.18112578988075256,
        0.028380345553159714,
        -0.07149109989404678,
        -0.1189599260687828,
        -0.2737513780593872,
        0.07408829778432846,
        -0.17847780883312225,
        0.020834332332015038,
        -0.10798025131225586,
        -0.20858654379844666,
        -0.02375071309506893,
        -0.1100919172167778,
        -0.19483327865600586,
        0.25393441319465637,
        0.10734792798757553,
        -0.14469200372695923,
        0.09808512032032013,
        0.09400472044944763,
        -0.23217177391052246,
        -0.08842657506465912,
        -0.1851048320531845,
        -0.29430100321769714,
        0.22160206735134125,
        0.19516398012638092,
        -0.06432969123125076,
        0.05908161774277687,
        -0.015968548133969307,
        0.02557685784995556,
        -0.10308844596147537,
        -0.0020627204794436693,
        0.04732585325837135,
        -0.2606651484966278,
        -0.06382598727941513,
        -0.0982925146818161,
        0.09783390164375305,
        -0.22700940072536469,
        0.03460384160280228,
        0.3219718039035797,
        0.16774135828018188,
        -0.158657968044281,
        0.21022890508174896,
        0.06800249218940735,
        0.08231176435947418,
        -0.20943354070186615,
        -0.11955682188272476,
        -0.2645959258079529,
        0.24841563403606415,
        0.20656031370162964,
        0.12049157917499542,
        -0.06352534890174866,
        0.29655301570892334,
        0.17304976284503937,
        0.15357622504234314,
        -0.03457298129796982,
        0.17079387605190277,
        -0.23475609719753265,
        0.15416190028190613,
        -0.18630580604076385,
        0.08448252826929092,
        -0.29001834988594055,
        -0.21479932963848114,
        0.12126302719116211,
        0.16848193109035492,
        0.10081154108047485,
        -0.22572113573551178,
        -0.15846195816993713,
        0.1689254343509674,
        0.07880188524723053,
        0.1964154988527298,
        0.18657442927360535,
        0.21910224854946136,
        0.3061467707157135,
        0.10103903710842133,
        0.14884740114212036,
        -0.15894277393817902,
        0.00322747346945107,
        0.10672890394926071,
        0.2052038460969925,
        -0.27246180176734924,
        -0.27299240231513977,
        0.11656532436609268,
        -0.20952336490154266,
        0.3304022550582886,
        0.07334356755018234,
        -0.25039345026016235,
        -0.0293470099568367,
        0.28184810280799866,
        -0.09764938056468964,
        0.07259031385183334,
        -0.293717622756958,
        0.25570160150527954,
        0.30903390049934387,
        -0.19125941395759583,
        0.11279459297657013,
        0.2973671853542328,
        0.18883834779262543,
        0.22237181663513184,
        -0.1932792067527771,
        0.3397485017776489,
        0.025542106479406357,
        0.1011848896741867,
        0.3037835657596588,
        0.03422264754772186,
        0.13486839830875397,
        0.12695570290088654,
        -0.08975136280059814,
        0.19402416050434113,
        0.08494942635297775,
        0.19593916833400726,
        0.21454761922359467,
        -0.050262995064258575,
        -0.01926002837717533,
        -0.2677263915538788,
        -0.2824850082397461,
        -0.1731746345758438,
        -0.22624889016151428,
        0.005159961991012096,
        -0.08802761882543564,
        -0.13875211775302887,
        0.2865709960460663,
        0.24429479241371155,
        -0.06662309169769287,
        -0.006973453797399998,
        0.13152241706848145,
        -0.2186235934495926,
        0.32154586911201477,
        -0.05238239839673042,
        -0.18206940591335297,
        0.20294071733951569,
        -0.2301712930202484,
        0.3175541162490845,
        0.29506635665893555,
        -0.047835588455200195,
        -0.17122212052345276,
        0.10733337700366974,
        0.22803878784179688,
        -0.2944287359714508,
        0.3287111520767212,
        0.3288165032863617,
        0.20246632397174835,
        -0.29845330119132996,
        0.1668030172586441,
        0.1076309010386467,
        0.116267129778862,
        -0.22635509073734283,
        0.15896786749362946,
        0.16898469626903534,
        0.011112040840089321,
        -0.14923948049545288,
        -0.0558500662446022,
        -0.02827337384223938,
        -0.194981649518013,
        0.20020802319049835,
        0.04246331378817558,
        -0.14298757910728455,
        0.33649179339408875,
        0.07824110239744186,
        0.24539025127887726,
        -0.27510666847229004,
        0.20397181808948517,
        -0.04201080650091171,
        -0.1815417855978012,
        -0.3001525104045868,
        -0.14004439115524292,
        0.24540792405605316,
        0.3113698363304138,
        0.16834132373332977,
        0.11556006968021393,
        -0.21028974652290344,
        -0.1453544646501541,
        0.22487281262874603,
        0.146040141582489,
        0.185209259390831,
        0.24209249019622803,
        -0.2198588252067566,
        -0.1795564889907837,
        -0.1474752426147461,
        0.3314897119998932,
        0.02155710756778717,
        -0.0893704816699028,
        -0.11435294896364212,
        -0.1002836748957634,
        0.23361794650554657,
        -0.2117862105369568,
        0.006734651047736406,
        0.08997053653001785,
        0.07166179269552231,
        0.09236041456460953,
        0.008772206492722034,
        0.08507693558931351,
        -0.1841212958097458,
        0.1284402459859848,
        0.020458051934838295,
        0.18795417249202728,
        -0.19364824891090393,
        -0.19839145243167877,
        0.3173607289791107,
        0.166477233171463,
        -0.14150619506835938,
        -0.013545515015721321,
        0.1889358013868332,
        0.2540758550167084,
        -0.2473396211862564,
        -0.11784782260656357,
        -0.23447321355342865,
        -0.2845959961414337,
        0.1345008909702301,
        -0.22077319025993347,
        -0.02567591890692711,
        0.19335147738456726,
        -0.16506429016590118,
        0.2395927608013153,
        -0.133403018116951,
        0.23708654940128326,
        0.20138844847679138,
        -0.3121965527534485,
        -0.011003156192600727,
        -0.15036191046237946,
        0.1367526352405548,
        -0.24699676036834717,
        0.009668120183050632,
        -0.11532018333673477,
        0.26858702301979065,
        0.1459093987941742,
        0.22574304044246674,
        -0.219795823097229,
        -0.26425161957740784,
        0.33321627974510193,
        -0.045013267546892166,
        0.03537856414914131,
        0.09899220615625381,
        -0.09358786046504974,
        -0.048938650637865067,
        -0.15585698187351227,
        0.32902956008911133,
        0.21400897204875946,
        0.26137909293174744,
        -0.3260323405265808,
        -0.284461110830307,
        0.308319628238678,
        -0.2718126177787781,
        0.15317578613758087,
        -0.14539015293121338,
        0.1469491869211197,
        -0.15944108366966248,
        0.03805001080036163,
        -0.26135778427124023,
        -0.29274988174438477,
        0.13485704362392426,
        0.20809713006019592,
        -0.20426033437252045,
        0.2146182507276535,
        -0.15941700339317322,
        -0.24814102053642273,
        -0.038468632847070694,
        0.32107797265052795,
        0.22288641333580017,
        -0.002312627388164401,
        -0.050726618617773056,
        0.17983999848365784,
        -0.08435787260532379,
        0.30605632066726685,
        0.01640952005982399,
        -0.020854096859693527,
        0.02907724305987358,
        0.06319905817508698,
        -0.16736197471618652,
        -0.17674872279167175,
        0.2252834141254425,
        0.14247305691242218,
        -0.28607961535453796,
        -0.19878455996513367,
        0.3162500262260437,
        -0.1301165074110031,
        -0.00452663516625762,
        -0.30600231885910034,
        0.11094173043966293,
        -0.32888928055763245,
        -0.2713084816932678,
        -0.26399096846580505,
        -0.2837968170642853,
        0.28237205743789673,
        -0.2438855916261673,
        -0.0946463793516159,
        0.22230218350887299,
        0.17309050261974335,
        -0.18352000415325165,
        -0.05936625972390175,
        -0.17262063920497894,
        -0.16216230392456055,
        0.019679266959428787,
        0.03891438990831375,
        -0.019298410043120384,
        -0.2142583280801773,
        0.13251014053821564,
        -0.10302849113941193,
        -0.26838418841362,
        0.13932311534881592,
        0.19647707045078278,
        0.2437070608139038,
        -0.26905083656311035,
        -0.20884236693382263,
        0.07659353315830231,
        0.13946786522865295,
        0.28079140186309814,
        -0.15584248304367065,
        0.3233119249343872,
        -0.1652509868144989,
        -0.07186374813318253,
        -0.11639358103275299,
        -0.11090891063213348,
        0.15134075284004211,
        0.024034550413489342,
        -0.08497858792543411,
        -0.12772035598754883,
        -0.13327354192733765,
        -0.19343800842761993,
        0.03484157472848892,
        0.28409498929977417,
        -0.007152929902076721,
        -0.2199525088071823,
        0.16200505197048187,
        0.2713363766670227,
        -0.018926376476883888,
        0.06084197759628296,
        0.17375944554805756,
        -0.21201670169830322,
        -0.1688762605190277,
        -0.13981884717941284,
        0.1597549170255661,
        -0.12239748984575272,
        0.12847691774368286,
        -0.1485876441001892,
        -0.05015178769826889,
        -0.04571928456425667,
        -0.2742714285850525,
        0.32556918263435364,
        -0.28829512000083923,
        -0.02651529759168625,
        0.16042889654636383,
        -0.0777636170387268,
        -0.2783908545970917,
        -0.15586236119270325,
        -0.018323566764593124,
        0.2705918550491333,
        0.24368591606616974,
        -0.2526519000530243,
        -0.19407999515533447,
        0.16742706298828125,
        -0.11562170833349228,
        0.27570220828056335,
        0.29038870334625244,
        0.09415007382631302,
        0.14797724783420563,
        -0.20927006006240845,
        0.13632041215896606,
        -0.13626393675804138,
        0.009449242614209652,
        0.22697699069976807,
        0.23987989127635956,
        0.09557997435331345,
        0.11976736783981323,
        -0.04229634627699852,
        0.29473623633384705,
        -0.014066874049603939,
        0.3392016291618347,
        -0.043917205184698105,
        -0.05701255798339844,
        0.33380037546157837,
        -0.07373511791229248,
        0.01796010509133339,
        0.15696784853935242,
        0.3167448043823242,
        0.3242717385292053,
        0.254617840051651,
        0.258352130651474,
        0.07977131009101868,
        -0.22445711493492126,
        -0.14992812275886536,
        -0.13811956346035004,
        -0.045413680374622345,
        0.1536390781402588,
        -0.11327212303876877,
        0.24853673577308655,
        -0.022254450246691704,
        -0.25908753275871277,
        0.3072360157966614,
        0.22765178978443146,
        -0.20127123594284058,
        0.17505104839801788,
        0.10484328866004944,
        -0.05706037953495979,
        0.14145269989967346,
        0.04786871001124382,
        0.06981369107961655,
        -0.22730666399002075,
        -0.20531992614269257,
        0.29648536443710327,
        -0.34006550908088684,
        0.02220463939011097,
        -0.15540365874767303,
        0.28224390745162964,
        -0.215694859623909,
        0.2567717432975769,
        0.04228032007813454,
        8.299615001305938e-05,
        0.031699150800704956,
        -0.156198650598526,
        0.2857097387313843,
        -0.16272975504398346,
        0.19259414076805115,
        0.2983357608318329,
        0.3200012147426605,
        0.09872176498174667,
        0.20322909951210022,
        0.29096806049346924,
        -0.0664290264248848,
        0.24724119901657104,
        -0.15274031460285187,
        -0.10244186967611313,
        0.13853593170642853,
        -0.1785331517457962,
        -0.050696518272161484,
        0.3317766487598419,
        0.04165411368012428,
        0.09794297814369202,
        0.2344176322221756,
        -0.26101207733154297,
        -0.04026613011956215,
        0.14945785701274872,
        -0.22403612732887268,
        -0.13739319145679474,
        0.25173962116241455,
        0.28830936551094055,
        -0.18144214153289795,
        -0.2880428731441498,
        -0.1476231962442398,
        0.1810320019721985,
        -0.08801440894603729,
        0.0005455317441374063,
        0.21876762807369232,
        0.30886515974998474,
        0.06748578697443008,
        0.007909431122243404,
        -0.025238405913114548,
        0.18333302438259125,
        -0.2219167798757553,
        0.2606135606765747,
        0.08447524160146713,
        -0.20897585153579712,
        0.20386379957199097,
        0.102847158908844,
        -0.23415592312812805,
        -0.2733284533023834,
        -0.0030173459090292454,
        -0.07220173627138138,
        0.16879244148731232,
        0.2749403417110443,
        0.06768007576465607,
        0.06519726663827896,
        0.12551961839199066,
        -0.2984946072101593,
        -0.27824607491493225,
        0.043346256017684937,
        0.06568166613578796,
        0.2512981593608856,
        0.2504158914089203,
        -0.05227930098772049,
        -0.028639167547225952,
        -0.012223305180668831,
        -0.08731155842542648,
        0.29522714018821716,
        -0.09398841857910156,
        -0.21105946600437164,
        -0.17173567414283752,
        -0.32568252086639404,
        0.07150378823280334,
        -0.002279519336298108,
        -0.011119870468974113,
        0.11023688316345215,
        -0.11045319586992264,
        -0.11904897540807724,
        0.04115958511829376,
        -0.15115100145339966,
        0.05080529674887657,
        0.13831469416618347,
        0.130970761179924,
        -0.11018799990415573,
        -0.11549193412065506,
        0.08125245571136475,
        -0.01884464919567108,
        -0.07032937556505203,
        0.3328043818473816,
        -0.19202643632888794,
        -0.27434220910072327,
        -0.14916153252124786,
        -0.25427165627479553,
        0.13336487114429474,
        0.16212747991085052,
        -0.11859447509050369,
        0.2335548996925354,
        -0.058003153651952744,
        0.30940982699394226,
        0.05655252933502197,
        0.06845442950725555,
        0.2563115358352661,
        0.31475695967674255,
        -0.15840710699558258,
        0.32499000430107117,
        -0.171653151512146,
        0.2493714988231659,
        -0.014434046112000942,
        -0.05175461247563362,
        -0.20449495315551758,
        -0.08176493644714355,
        0.3280234932899475,
        -0.12058830261230469,
        0.33273184299468994,
        0.2082633525133133,
        0.004824203439056873,
        0.3272249698638916,
        0.26106059551239014,
        0.07313188165426254,
        -0.16811035573482513,
        -0.31960466504096985,
        0.2500077188014984,
        -0.26841405034065247,
        0.23485706746578217,
        -0.10614732652902603,
        0.33924421668052673,
        0.2307766079902649,
        0.21407349407672882,
        0.31039273738861084,
        -0.2587903141975403,
        0.057627469301223755,
        0.16196869313716888,
        0.24290840327739716,
        0.21762332320213318,
        0.17062249779701233,
        -0.2638927698135376,
        -0.2309950590133667,
        -0.049013469368219376,
        -0.2492748200893402,
        -0.3022216856479645,
        -0.02569108083844185,
        -0.22041134536266327,
        0.19261157512664795,
        0.2191644161939621,
        -0.08408690243959427,
        0.27918148040771484,
        0.16390524804592133,
        0.1254461258649826,
        0.013741266913712025,
        0.18145449459552765,
        -0.11851686984300613,
        0.09824970364570618,
        0.17852303385734558,
        -0.20345444977283478,
        -0.19868320226669312,
        0.22967411577701569,
        -0.03753708675503731,
        -0.22136372327804565,
        -0.1709233820438385,
        0.30270135402679443,
        0.27495434880256653,
        -0.1606583297252655,
        0.17466416954994202,
        -0.043608054518699646,
        0.13007457554340363,
        -0.33180955052375793,
        -0.17136354744434357,
        0.29636311531066895,
        0.2299058735370636,
        -0.09840360283851624,
        0.2608230710029602,
        0.14768336713314056,
        -0.0703049823641777,
        0.3292856216430664,
        0.22355124354362488,
        0.27388834953308105,
        0.11897189915180206,
        0.1685493141412735,
        0.2535993754863739,
        -0.22503609955310822,
        0.06524135172367096,
        -0.13507065176963806,
        0.33580026030540466,
        -0.03688979893922806,
        -0.06865207105875015,
        0.19747591018676758,
        0.04306594282388687,
        -0.2828511893749237,
        -0.026255885139107704,
        0.22380784153938293,
        -0.11025966703891754,
        0.03656652942299843,
        0.04968889802694321,
        0.1255272626876831,
        -0.13767309486865997,
        -0.22759458422660828,
        -0.16434918344020844,
        0.21778811514377594,
        0.26612675189971924,
        0.04037857428193092,
        0.18067951500415802,
        0.22224928438663483,
        -0.21184642612934113,
        0.22955165803432465,
        0.01661498285830021,
        -0.027034470811486244,
        -0.27893054485321045,
        -0.013610118068754673,
        -0.05762886628508568,
        -0.07550843060016632,
        -0.19058184325695038,
        -0.03330450877547264,
        0.05397613346576691,
        -0.1359352320432663,
        -0.08580859750509262,
        0.2974635660648346,
        0.2542985677719116,
        0.10336395353078842,
        -0.09987419843673706,
        -0.0833398774266243,
        -0.09823009371757507,
        -0.1401192843914032,
        -0.03411628305912018,
        0.1982952207326889,
        -0.21649222075939178,
        -0.27640023827552795,
        -0.2577776610851288,
        0.3446801006793976,
        0.20806856453418732,
        -0.29310327768325806,
        0.27579814195632935,
        0.12283708900213242,
        -0.029876479879021645,
        0.274729460477829,
        0.0747004970908165,
        -0.32191070914268494,
        0.08509886264801025,
        -0.21632438898086548,
        -0.18308360874652863,
        -0.04239250347018242,
        0.3042686879634857,
        -0.2003175914287567,
        -0.24037641286849976,
        -0.18640094995498657,
        -0.05463492125272751,
        -0.11930504441261292,
        -0.04440581053495407,
        0.3123247027397156,
        -0.06475946307182312,
        -0.1847422569990158,
        -0.1555812507867813,
        -0.08777689933776855,
        -0.1916918158531189,
        -0.1966390460729599,
        -0.1846509426832199,
        -0.16673968732357025,
        0.21461646258831024,
        0.09298838675022125,
        -0.21171234548091888,
        -0.10253541171550751,
        -0.3215025067329407,
        0.10763472318649292,
        -0.04699551314115524,
        -0.2529699504375458,
        0.3413999378681183,
        0.18206606805324554,
        -0.24018098413944244,
        0.16102559864521027,
        -0.26035943627357483,
        -0.08686140179634094,
        0.13452677428722382,
        0.15934087336063385,
        -0.21137946844100952,
        -0.1957053393125534,
        -0.12098251283168793,
        0.1543477326631546,
        0.25947579741477966,
        0.2606704831123352,
        0.15646450221538544,
        -0.31146806478500366,
        0.2824033796787262,
        0.008466179482638836,
        0.06121271476149559,
        -0.09182529896497726,
        -0.0016803857870399952,
        -0.018007341772317886,
        0.20259061455726624,
        0.29456454515457153,
        -0.05145680159330368,
        0.07248571515083313,
        0.042833391577005386,
        0.1615494340658188,
        -0.22401060163974762,
        0.3301984667778015,
        0.2796303927898407,
        0.12921175360679626,
        -0.0009617999894544482,
        -0.17532756924629211,
        0.06863819062709808,
        0.2839049994945526,
        0.06537759304046631,
        -0.29656171798706055,
        0.27764707803726196,
        -0.14179763197898865,
        -0.09628527611494064,
        0.20131094753742218,
        -0.08078527450561523,
        -0.2294900119304657,
        0.059626467525959015,
        0.09092335402965546,
        0.01335937064141035,
        -0.24452394247055054,
        -0.24003848433494568,
        0.05335618928074837,
        0.29820865392684937,
        0.302600234746933,
        -0.06390455365180969,
        -0.08174178004264832,
        -0.11579544842243195,
        -0.20058953762054443,
        0.19136813282966614,
        -0.22357890009880066,
        -0.2982749342918396,
        0.19900642335414886,
        -0.17861519753932953,
        0.2329341620206833,
        -0.20948825776576996,
        0.2085868865251541,
        0.27100515365600586,
        -0.0745697170495987,
        -0.26572009921073914,
        -0.2563536465167999,
        0.12292342633008957,
        -0.14484596252441406,
        0.13563506305217743,
        0.09919394552707672,
        -0.2398504912853241,
        -0.1633526235818863,
        0.3110522925853729,
        -0.14591675996780396,
        -0.09738913178443909,
        -0.04879757761955261,
        -0.25043976306915283,
        -0.27783897519111633,
        0.06312627345323563,
        0.34630119800567627,
        -0.26215681433677673,
        0.1354581117630005,
        -0.17545799911022186,
        -0.27307987213134766,
        0.20468705892562866,
        0.075264573097229,
        -0.034444648772478104,
        -0.1623818576335907,
        -0.25748568773269653,
        0.039301976561546326,
        -0.23670096695423126,
        0.2015332728624344,
        0.15873774886131287,
        -0.03696851432323456,
        0.06740120053291321,
        0.1517753154039383,
        -0.17960889637470245,
        -0.07758211344480515,
        0.09441172331571579,
        -0.289805144071579,
        0.1166723370552063,
        0.3155866861343384,
        0.21359285712242126,
        0.11993201076984406,
        -0.05587998405098915,
        0.03832312673330307,
        -0.20353668928146362,
        -0.3003723919391632,
        0.02119522914290428,
        0.26878321170806885,
        -0.23441751301288605,
        0.17444583773612976,
        -0.16612975299358368,
        0.3043200671672821,
        -0.11367988586425781,
        0.10592211782932281,
        -0.11375640332698822,
        -0.10245196521282196,
        0.18109126389026642,
        0.1858341097831726,
        0.08254760503768921,
        0.20478764176368713,
        -0.04838406294584274,
        0.23146289587020874,
        0.22738154232501984,
        -0.22688893973827362,
        0.07534852623939514,
        -0.19174043834209442,
        0.33046871423721313,
        -0.00448954151943326,
        -0.27020323276519775,
        -0.19943222403526306,
        0.018303165212273598,
        -0.19839781522750854,
        0.1734810769557953,
        0.1286730021238327,
        -0.05725359171628952,
        -0.2856212854385376,
        -0.19553326070308685,
        0.27017322182655334,
        0.06387593597173691,
        -0.2327483594417572,
        -0.04971955344080925,
        -0.1776769757270813,
        -0.08678988367319107,
        -0.10458298772573471,
        -0.15508028864860535,
        -0.06098775565624237,
        0.06534717231988907,
        -0.08559072762727737,
        0.2743850648403168,
        -0.22703969478607178,
        0.14836262166500092,
        0.14371910691261292,
        0.2340393364429474,
        0.22626017034053802,
        0.15280815958976746,
        0.036464862525463104,
        0.28644758462905884,
        -0.2995243966579437,
        0.07241393625736237,
        0.24170053005218506,
        0.14729392528533936,
        -0.041866399347782135,
        -0.026919759809970856,
        -0.32190510630607605,
        0.3232194781303406,
        0.046443864703178406,
        0.18462306261062622,
        -0.13142085075378418,
        0.1858503371477127,
        -0.025580771267414093,
        0.25791695713996887,
        0.17533576488494873,
        0.20528548955917358,
        0.11282035708427429,
        0.166092649102211,
        0.23598520457744598,
        -0.2214173525571823,
        -0.2838781774044037,
        -0.14553695917129517,
        -0.22922086715698242,
        0.20621110498905182,
        0.04694737493991852,
        -0.26575908064842224,
        -0.02304009720683098,
        0.3371475636959076,
        0.0051245857030153275,
        0.10891257971525192,
        0.21976588666439056,
        -0.15864203870296478,
        0.014864264987409115,
        0.15878960490226746,
        0.0008540386916138232,
        0.09710870683193207,
        0.046146731823682785,
        -0.05989395081996918,
        -0.25679707527160645,
        -0.3181096613407135,
        0.2056168168783188,
        0.06539832055568695,
        -0.13636378943920135,
        0.2991979122161865,
        -0.2922084331512451,
        -0.27988383173942566,
        -0.2334519922733307,
        -0.021437639370560646
    ],
    "linear_relu_stack.2.weight": [
        [
            -0.009092593565583229,
            0.040345627814531326,
            0.027203384786844254,
            0.0007176469080150127,
            -0.019490011036396027,
            -0.042978785932064056,
            -0.003177486127242446,
            -0.03186542168259621,
            0.0017081575933843851,
            -0.0016738965641707182,
            -0.054325252771377563,
            0.024622566998004913,
            -0.006340606138110161,
            -0.012031489051878452,
            0.033980242908000946,
            -0.0039712716825306416,
            -0.007913029752671719,
            -0.006307054311037064,
            -0.004594162572175264,
            0.009387155063450336,
            0.014821480959653854,
            0.017024461179971695,
            -0.035847898572683334,
            0.021262679249048233,
            -0.01957445777952671,
            -0.021484123542904854,
            0.030758248642086983,
            0.025992173701524734,
            0.027320366352796555,
            -0.015360244549810886,
            0.010946220718324184,
            0.03333459794521332,
            -0.035080451518297195,
            -0.008607562631368637,
            0.021253179758787155,
            0.004907567519694567,
            -0.026905661448836327,
            -0.015912501141428947,
            -0.01562014501541853,
            -0.012441215105354786,
            0.011712552979588509,
            -0.008202890865504742,
            3.753293276531622e-05,
            -0.015801122412085533,
            -0.02552562765777111,
            -0.014812305569648743,
            0.019821561872959137,
            -0.03263160213828087,
            0.01951685920357704,
            0.02361396886408329,
            -0.014696821570396423,
            -0.013511023484170437,
            0.00569451879709959,
            -0.007372075226157904,
            0.02375495806336403,
            -0.008278606459498405,
            -0.009945172816514969,
            0.019582677632570267,
            -0.019390203058719635,
            -0.015511109493672848,
            0.01335881557315588,
            0.024033991619944572,
            -0.028554217889904976,
            0.020775312557816505,
            -0.02007260173559189,
            0.03093835711479187,
            -0.0377984344959259,
            0.007563555613160133,
            -0.005333846900612116,
            0.03029523231089115,
            -0.0021321233361959457,
            -0.0047806547954678535,
            0.02863159216940403,
            0.023262206465005875,
            -0.0042197080329060555,
            0.034067485481500626,
            0.025458067655563354,
            0.0037622235249727964,
            -0.00033900822745636106,
            0.023534445092082024,
            0.0305181834846735,
            0.014591136015951633,
            0.015783758834004402,
            -0.02149376831948757,
            0.007204700261354446,
            -0.017421407625079155,
            -0.0027041153516620398,
            0.013761279173195362,
            0.005745450500398874,
            -0.005190242547541857,
            0.013992467895150185,
            0.0037275648210197687,
            -0.025922367349267006,
            0.041169364005327225,
            0.038556333631277084,
            0.005323100835084915,
            -0.018393373116850853,
            0.009866131469607353,
            0.022185908630490303,
            0.010979535058140755,
            0.016918346285820007,
            -0.01612422987818718,
            -0.006022718269377947,
            0.007569498382508755,
            0.03796146810054779,
            0.015321807004511356,
            0.011942427605390549,
            -0.015152797102928162,
            -0.007110453676432371,
            0.029754197224974632,
            0.026274027302861214,
            -0.006081884726881981,
            -0.013898195698857307,
            -0.00935336947441101,
            0.001677645486779511,
            0.019210493192076683,
            -0.00476129399612546,
            -0.060413334518671036,
            -0.029275543987751007,
            -0.01607753522694111,
            -0.018112661316990852,
            -0.025256577879190445,
            -0.031236344948410988,
            -0.03310150280594826,
            -0.005088940728455782,
            0.03390832990407944,
            -0.014220823533833027,
            0.028510823845863342,
            0.007300902623683214,
            -0.02114076539874077,
            0.024130098521709442,
            -0.03229038789868355,
            -0.004115589894354343,
            0.01406518742442131,
            -0.0011465013958513737,
            -0.023508893325924873,
            -0.005129285156726837,
            -0.00019085968961007893,
            -0.0574927031993866,
            0.038907263427972794,
            0.009339665062725544,
            0.023874852806329727,
            0.025953199714422226,
            0.015028893947601318,
            -0.0009427863988094032,
            -0.016423149034380913,
            0.012111308984458447,
            0.0017672604881227016,
            0.027206722646951675,
            0.0036300597712397575,
            -0.024064527824521065,
            -0.05236528441309929,
            0.001356466324068606,
            -0.07110772281885147,
            -0.018047962337732315,
            0.04060269519686699,
            -0.02503906562924385,
            0.023485494777560234,
            0.02648242749273777,
            0.012397561222314835,
            -0.012501931749284267,
            0.026779595762491226,
            -0.016507897526025772,
            0.027979888021945953,
            -0.03444839268922806,
            -0.024417705833911896,
            0.034869126975536346,
            -0.023725392296910286,
            0.005189831834286451,
            -0.00027598717133514583,
            0.0215014461427927,
            0.008845622651278973,
            -0.02877982147037983,
            0.03420872986316681,
            0.0009863529121503234,
            0.015188408084213734,
            -0.01042300183326006,
            0.00452619232237339,
            0.005903077311813831,
            0.004899822175502777,
            -0.010700694285333157,
            -0.026964349672198296,
            0.02052547223865986,
            0.014878862537443638,
            -0.021444322541356087,
            0.03888177499175072,
            -0.017670998349785805,
            0.0004575974016916007,
            0.006644748151302338,
            -0.021364200860261917,
            -0.038976095616817474,
            -0.02778635360300541,
            -0.008828835561871529,
            -0.0072826179675757885,
            0.013913742266595364,
            0.007017144467681646,
            0.01368221826851368,
            0.012515897862613201,
            -0.034727130085229874,
            0.03695276007056236,
            0.023454934358596802,
            -0.030371781438589096,
            0.0018873641965910792,
            0.010870249010622501,
            -0.018968423828482628,
            0.007515602745115757,
            -0.01678668148815632,
            0.020510602742433548,
            0.014324890449643135,
            0.030101286247372627,
            -0.005545646883547306,
            -0.008914321660995483,
            -0.009222781285643578,
            0.00640202546492219,
            -0.01798107847571373,
            0.02238466404378414,
            0.003670019330456853,
            -0.029703881591558456,
            -0.020911065861582756,
            -0.02068113535642624,
            0.019285419955849648,
            -0.01735055446624756,
            -0.033673714846372604,
            0.008966837078332901,
            -0.010293682105839252,
            0.03188014030456543,
            0.03966715931892395,
            0.0010838762391358614,
            0.010556579567492008,
            -0.040832970291376114,
            -0.051732078194618225,
            -0.02964162826538086,
            -0.05067336931824684,
            -0.003918495029211044,
            -0.016857437789440155,
            0.00021877915423829108,
            -0.04180702194571495,
            -0.022352203726768494,
            0.015890110284090042,
            -0.013613934628665447,
            0.004857564345002174,
            -0.01749807968735695,
            -0.023696115240454674,
            0.0040961080230772495,
            -0.030403967946767807,
            0.009278464131057262,
            0.0005239769816398621,
            0.04054032266139984,
            -0.013987156562507153,
            0.011977416463196278,
            0.011597247794270515,
            0.012886452488601208,
            -0.02837344817817211,
            -0.01809869147837162,
            -0.02043440379202366,
            0.017551004886627197,
            -0.0099008958786726,
            -0.022127557545900345,
            0.011793847195804119,
            0.04572790861129761,
            -0.010616552084684372,
            0.014350219629704952,
            0.04130545258522034,
            -0.027891509234905243,
            0.03564223647117615,
            -0.021587688475847244,
            0.027670426294207573,
            0.02488737739622593,
            0.021069705486297607,
            -0.04667647182941437,
            -0.013577074743807316,
            0.03147803619503975,
            0.005055817309767008,
            0.005170661956071854,
            -0.0009689399739727378,
            0.0007292981608770788,
            -0.012654075399041176,
            -0.030691005289554596,
            -0.02194998972117901,
            0.004199352581053972,
            0.015566930174827576,
            0.01945388875901699,
            0.006573425140231848,
            -0.036718402057886124,
            0.019302992150187492,
            -0.009048345498740673,
            -0.019423024728894234,
            0.046005502343177795,
            -0.002562006004154682,
            -0.009087281301617622,
            -0.007837035693228245,
            0.022987859323620796,
            0.0009390198974870145,
            -0.006976706441491842,
            -0.03671000152826309,
            0.03253839910030365,
            0.0156665351241827,
            0.024389781057834625,
            0.04228150472044945,
            -0.0200547743588686,
            0.0018020092975348234,
            0.03675975650548935,
            -0.018925053998827934,
            0.020289309322834015,
            -0.0062684291042387486,
            -0.020825963467359543,
            0.027498885989189148,
            -0.003257602220401168,
            -0.030923105776309967,
            0.01419538352638483,
            0.03782234340906143,
            0.022022971883416176,
            -0.0014947507297620177,
            -0.019296444952487946,
            -0.005158846266567707,
            0.01816185750067234,
            -0.043128833174705505,
            -0.02797902561724186,
            -0.003471542149782181,
            -0.02741452492773533,
            -0.03019658848643303,
            0.033938273787498474,
            -0.015042283572256565,
            -0.03800611570477486,
            -0.03415539488196373,
            -0.03423401713371277,
            -0.0123239541426301,
            -0.017078977078199387,
            0.010912289842963219,
            0.021264061331748962,
            0.006298411171883345,
            -0.02246708795428276,
            0.023414649069309235,
            0.010830601677298546,
            -0.008392449468374252,
            0.0006044916226528585,
            -0.01947902701795101,
            -0.03060409240424633,
            -0.007638859562575817,
            0.005160301458090544,
            0.03166563808917999,
            -0.060923557728528976,
            -0.0032446046825498343,
            -0.02969745732843876,
            0.0070715672336518764,
            -0.026214823126792908,
            0.0029301908798515797,
            -0.016962986439466476,
            -0.01213766448199749,
            -0.0008639939478598535,
            0.018174028024077415,
            -0.053742509335279465,
            -0.025122936815023422,
            -0.019792305305600166,
            -0.01108638010919094,
            -0.022485313937067986,
            -0.026706404983997345,
            0.020356522873044014,
            0.010956983081996441,
            0.04170496389269829,
            0.004479922354221344,
            -0.025035997852683067,
            -0.005543193779885769,
            0.014058702625334263,
            0.0031417561694979668,
            0.005622258875519037,
            -0.009783301502466202,
            0.02392999641597271,
            0.034079279750585556,
            0.015816155821084976,
            0.019963659346103668,
            -0.016399173066020012,
            0.009319022297859192,
            0.026187915354967117,
            0.013959107920527458,
            -0.03174438327550888,
            -0.028089091181755066,
            0.014078790321946144,
            0.010870887897908688,
            -0.026484526693820953,
            -0.0007502303924411535,
            0.02422761172056198,
            0.015343640930950642,
            -0.008012352511286736,
            -0.024207528680562973,
            -0.012152292765676975,
            0.007340454496443272,
            -0.052805814892053604,
            0.04695608466863632,
            -0.003810377325862646,
            0.003702203743159771,
            0.03920402005314827,
            -0.015370805747807026,
            0.006527864374220371,
            -0.0529770627617836,
            -0.003967795521020889,
            0.004772599320858717,
            -0.007676688488572836,
            0.026323165744543076,
            0.010291168466210365,
            0.04002448543906212,
            0.011453608050942421,
            0.0012315086787566543,
            0.006001261994242668,
            0.0058769588358700275,
            -0.0032066109124571085,
            0.02474583126604557,
            -0.03723754361271858,
            0.022108929231762886,
            -0.020642241463065147,
            -0.00724595133215189,
            0.02383355423808098,
            -0.02240489423274994,
            0.020028693601489067,
            -0.006065629422664642,
            0.0077672479674220085,
            -0.022249888628721237,
            0.052893828600645065,
            -0.02550233155488968,
            0.0019247638992965221,
            0.044347766786813736,
            0.019022952765226364,
            0.05310884863138199,
            -0.028625817969441414,
            0.002611919306218624,
            -0.04922829940915108,
            0.014897763729095459,
            0.018577465787529945,
            -0.007221766747534275,
            -0.0009326464496552944,
            0.022616339847445488,
            0.01426137238740921,
            -0.04087333008646965,
            0.04326475411653519,
            -0.008738021366298199,
            0.0011427492136135697,
            0.02662298083305359,
            -0.021275745704770088,
            -0.005213682074099779,
            -0.037917863577604294,
            -0.040597569197416306,
            -0.04927804693579674,
            0.0010316723491996527,
            0.00025907394592650235,
            0.003553153946995735,
            -0.006211572326719761,
            -0.04352733865380287,
            0.03056446649134159,
            -0.010772298090159893,
            -0.004774821922183037,
            -0.007414086721837521,
            -0.012617537751793861,
            -0.018482914194464684,
            0.030520450323820114,
            -0.0032649668864905834,
            -0.0014638494467362761,
            0.03666183352470398,
            0.018729135394096375,
            0.02572437934577465,
            0.011392541229724884,
            -0.01639658398926258,
            0.019228283315896988,
            0.008180885575711727,
            -0.011051849462091923,
            -0.0069101229310035706,
            -0.019178858026862144,
            -0.013120496645569801,
            -0.023596901446580887,
            -0.00924015138298273,
            0.017193295061588287,
            0.007217671722173691,
            -0.006377813406288624,
            0.023061122745275497,
            0.024275237694382668,
            0.010487683117389679,
            0.00860915333032608,
            -0.006268974393606186,
            -0.031843479722738266,
            0.03140341490507126,
            -0.0023109468165785074,
            -0.03028513863682747,
            -0.01401166245341301,
            -0.03894897550344467,
            -0.0035596168600022793,
            0.019019944593310356,
            -0.0285665150731802,
            -0.024906042963266373,
            0.008695002645254135,
            -0.08440007269382477,
            0.027740199118852615,
            0.008283675648272038,
            -0.025399696081876755,
            0.02554556541144848,
            -0.0032320464961230755,
            0.018145279958844185,
            -0.014853518456220627,
            -0.04133541136980057,
            -0.0036935231182724237,
            0.0018420122796669602,
            0.01913530007004738,
            -0.010879207402467728,
            -0.0323181077837944,
            0.013729263097047806,
            -0.041003912687301636,
            -0.0003390176862012595,
            0.004727292340248823,
            -0.006790222134441137,
            -0.011166289448738098,
            -0.025156401097774506,
            0.021202869713306427,
            0.0009702770039439201,
            -0.04727105423808098,
            0.0278315506875515,
            0.002418717136606574,
            -0.03539654240012169,
            0.003133651800453663,
            -0.0069090924225747585,
            0.015492510981857777,
            0.026480669155716896,
            0.002068770583719015,
            -0.01752624660730362,
            -0.002866436028853059,
            -0.005441444460302591,
            0.008388208225369453,
            0.03822392597794533,
            0.05055868998169899,
            0.020474085584282875,
            -0.03225528821349144,
            0.024463467299938202,
            0.03571910038590431,
            -0.02203715406358242,
            0.022746779024600983,
            0.028350019827485085,
            -0.016220221295952797,
            0.013353491201996803,
            0.01802585832774639,
            -0.011725329793989658,
            0.01427589263767004,
            -0.02574988640844822,
            -0.010243397206068039,
            -0.00048561423318460584,
            0.011286994442343712,
            0.038156405091285706,
            0.060265954583883286,
            0.01609036512672901,
            0.017001062631607056,
            0.0058232699520885944,
            -0.05005420371890068,
            0.029779920354485512,
            -0.03333054482936859,
            0.023898134008049965,
            0.015252687968313694,
            -0.021909121423959732,
            -0.005067593418061733,
            -0.0030172099359333515,
            -0.019355088472366333,
            -0.02760005183517933,
            0.0015607152599841356,
            -0.040464092046022415,
            -0.030789228156208992,
            0.021290529519319534,
            -0.0033462580759078264,
            0.009477616287767887,
            -0.019151968881487846,
            0.006593110505491495,
            0.03904268518090248,
            -0.012924223206937313,
            -0.00029246858321130276,
            -0.0080806789919734,
            -0.027211355045437813,
            -0.0005610441439785063,
            0.01655706949532032,
            0.00044215278467163444,
            0.03214053809642792,
            -0.011806434020400047,
            -0.019062798470258713,
            -0.005766445305198431,
            -0.015194546431303024,
            0.022513385862112045,
            0.03221995756030083,
            -0.002311968244612217,
            0.0054954648949205875,
            -0.005981638561934233,
            -0.053997695446014404,
            0.008630221709609032,
            -0.03986470028758049,
            0.01245145034044981,
            0.00156506581697613,
            -0.016192978248000145,
            -0.03003143146634102,
            0.019491536542773247,
            -0.005879994481801987,
            -0.01711873523890972,
            0.0123781468719244,
            -0.019168246537446976,
            -0.019687417894601822,
            0.024178624153137207,
            0.0167460348457098,
            0.003305537160485983,
            0.011898231692612171,
            0.030907437205314636,
            0.030801573768258095,
            0.02094593457877636,
            -0.0075552999041974545,
            -0.006239207927137613,
            -0.013101973570883274,
            -0.01755082793533802,
            0.02211642637848854,
            -0.04470640793442726,
            0.004676207900047302,
            -0.012381091713905334,
            0.015636669471859932,
            -0.0297666247934103,
            -0.02737007848918438,
            0.027148425579071045,
            0.003690746845677495,
            -0.030616704374551773,
            -0.03610400855541229,
            -0.015315006487071514,
            -0.0009352267370559275,
            0.016904396936297417,
            -0.02965361624956131,
            -0.005741950124502182,
            0.02419261448085308,
            -0.007028364110738039,
            -0.0032053112518042326,
            0.04844411462545395,
            -0.006656765937805176,
            0.00672954274341464,
            -0.01506044715642929,
            0.004520887974649668,
            0.02434428781270981,
            0.023918600752949715,
            -0.016811486333608627,
            0.025792565196752548,
            0.03488757088780403,
            0.00018031778745353222,
            -0.00862710177898407,
            -0.01029277965426445,
            -0.005751438904553652,
            0.022134941071271896,
            -0.01686219498515129,
            0.010225010104477406,
            -0.04262486845254898,
            0.008472302928566933,
            -0.02255930006504059,
            -0.03190941736102104,
            -0.03093920275568962,
            -0.025359826162457466,
            0.007007150910794735,
            -0.00471712090075016,
            0.0249943844974041,
            0.025284921750426292,
            0.002207424258813262,
            0.00844636932015419,
            0.020463941618800163,
            -0.020117826759815216,
            -0.010293107479810715,
            -0.022851264104247093,
            -0.029754921793937683,
            0.0031074651051312685,
            -0.009456039406359196,
            -0.02133365161716938,
            -0.017966827377676964,
            0.012282044626772404,
            -0.009116983972489834,
            0.01242479495704174,
            0.018513593822717667,
            0.02517249621450901,
            -0.015071013011038303,
            0.07634548842906952,
            -0.030689077451825142,
            0.002874088706448674,
            0.0086586968973279,
            -0.02402750588953495,
            -0.048746638000011444,
            -0.012329528108239174,
            -0.01058080792427063,
            -0.034782085567712784,
            -0.013986671343445778,
            0.01975732482969761,
            0.0057666669599711895,
            0.01338133029639721,
            -0.006569419521838427,
            0.01740456186234951,
            -0.009079812094569206,
            -0.05167597532272339,
            0.013009569607675076,
            -0.009514356032013893,
            0.024020232260227203,
            -0.02931697480380535,
            0.013020911253988743,
            0.006977432407438755,
            -0.0195903480052948,
            -0.028857605531811714,
            -0.012198199518024921,
            0.0030179114546626806,
            -0.010568832978606224,
            0.017996039241552353,
            -0.020461175590753555,
            -0.007172897923737764,
            -0.04029492661356926,
            -0.008315885439515114,
            -0.034413259476423264,
            0.013130850158631802,
            0.0028460815083235502,
            -0.03367651626467705,
            0.026181550696492195,
            -0.002577403327450156,
            0.011500095017254353,
            0.0010029614204540849,
            -0.006026103626936674,
            0.03821664676070213,
            0.02296060137450695,
            -0.0028051771223545074,
            -0.0007965907570905983,
            0.033883001655340195,
            -0.035620205104351044,
            0.014021039940416813,
            0.024448655545711517,
            0.013957502320408821,
            -0.015077877789735794,
            -0.021549420431256294,
            -0.008215499110519886,
            -0.003424543421715498,
            0.0056870607659220695,
            -5.961499846307561e-05,
            0.011625698767602444,
            0.01831284537911415,
            -0.016060790047049522,
            0.013484416529536247,
            0.002383767394348979,
            0.009083360433578491,
            -0.005722878500819206,
            -0.031238142400979996,
            -0.014527201652526855,
            0.022793227806687355,
            0.013214046135544777,
            0.034542832523584366,
            0.013466952368617058,
            0.024206219241023064,
            -0.0063524870201945305,
            -0.013786755502223969,
            0.008981185965240002,
            0.034424010664224625,
            0.045915670692920685,
            0.005935123190283775,
            -0.026254786178469658,
            -0.03719430789351463,
            0.003326006466522813,
            0.005875126458704472,
            -0.004025693517178297,
            -0.0019314948003739119,
            0.003654083237051964,
            0.007272050250321627,
            -0.011374037712812424,
            0.03650866448879242,
            0.02477521076798439,
            -0.013691237196326256,
            0.00593114597722888,
            -0.060987018048763275,
            -0.01749849133193493,
            0.00036147108767181635,
            -0.028886782005429268,
            0.007651139050722122,
            -0.0020099394023418427,
            -0.017670603469014168,
            -0.016526497900485992,
            0.004288843832910061,
            -0.025538885965943336,
            -0.011780965141952038,
            -0.011566072702407837,
            0.046884890645742416,
            0.0028035505674779415,
            -0.03791346773505211,
            0.002951243659481406,
            0.0039733112789690495,
            -0.022247575223445892,
            -0.0067208437249064445,
            -0.03229321911931038,
            0.023769747465848923,
            0.005354550667107105,
            -0.019382374361157417,
            -0.004872296005487442,
            -0.005405690521001816,
            0.0067323436960577965,
            0.025941696017980576,
            -0.019722700119018555,
            0.003729147370904684,
            -0.015015973709523678,
            0.023551253601908684,
            0.01791180483996868,
            0.028055395931005478,
            0.04426645487546921,
            -0.0012110801180824637,
            -0.0039402153342962265,
            -0.007091096602380276,
            0.02419762872159481,
            0.012406243942677975,
            -0.02937454544007778,
            0.015245899558067322,
            0.019441889598965645,
            0.019447626546025276,
            0.018449706956744194,
            -0.025211257860064507,
            -0.008768491446971893,
            0.0008629654766991735,
            -0.0052991099655628204,
            0.022533146664500237,
            0.03708069026470184,
            0.02996007725596428,
            0.005431787576526403,
            0.025414541363716125,
            -0.031204471364617348,
            0.0018604095093905926,
            -0.0177173912525177,
            -0.03611202538013458,
            -0.014644625596702099,
            0.009826955385506153,
            -0.006991824135184288,
            -0.0077113495208323,
            -0.024828661233186722,
            -0.00026961718685925007,
            -0.0364890992641449,
            -0.0027621116023510695,
            -0.015856854617595673,
            0.005944524891674519,
            -0.03451743349432945,
            0.032080814242362976,
            0.0001859316398622468,
            0.001110666780732572,
            0.016467861831188202,
            0.0298695657402277,
            -0.002172035863623023,
            -0.016425594687461853,
            0.047088705003261566,
            0.001639062655158341,
            -0.010824141092598438,
            0.026963185518980026,
            0.0016504678642377257,
            -0.035835910588502884,
            -0.03388873115181923,
            0.030901813879609108,
            -0.016498060896992683,
            -0.020607121288776398,
            0.006434524431824684,
            -0.0038729265797883272,
            -0.034047696739435196,
            0.013917502015829086,
            -0.03336317092180252,
            -0.013051510788500309,
            -0.018895355984568596,
            -0.010856111533939838,
            0.010485067032277584,
            0.037278059870004654,
            -0.0072522247210145,
            0.01459794957190752,
            0.007270664907991886,
            -0.018054278567433357,
            -0.013232807628810406,
            -0.02766556851565838,
            -0.008233943954110146,
            0.016369957476854324,
            0.020012281835079193,
            -0.047054391354322433,
            -0.028693677857518196,
            -0.0138468649238348,
            0.03051903285086155,
            0.03217250481247902,
            0.0327313058078289,
            0.009034473448991776,
            0.028277114033699036,
            0.03541805222630501,
            0.007748973090201616,
            -0.03085535578429699,
            0.02498778887093067,
            -0.013773518614470959,
            -0.04602454975247383,
            0.02111334539949894,
            0.011139980517327785,
            0.025915013626217842,
            -0.03246510401368141,
            0.02583579160273075,
            -0.03991766646504402,
            0.0036277156323194504,
            0.021965233609080315,
            0.015865251421928406,
            -0.05555041879415512,
            0.03302102908492088,
            -0.02387278713285923,
            0.02968539297580719,
            -0.032682448625564575,
            0.0005141825531609356,
            0.03232666105031967,
            -0.03604209050536156,
            0.016898883506655693,
            -0.013113630935549736,
            -0.011541691608726978,
            0.011725254356861115,
            0.013371579349040985,
            -0.012256749905645847,
            -0.003979423549026251,
            0.03008158504962921,
            -0.018824074417352676,
            -0.006693939678370953,
            -0.013229098170995712,
            -0.05446062609553337,
            -0.02495819516479969,
            0.0371951088309288,
            0.0012619926128536463,
            0.030337920412421227,
            0.005620390642434359,
            0.0012354935752227902,
            0.007241156883537769,
            -9.776444130693562e-06,
            0.0004983123508282006,
            0.020675208419561386,
            -0.0036685504019260406,
            0.0005945376469753683,
            0.022885747253894806,
            -0.05078451707959175,
            0.011026433669030666,
            0.010510793887078762,
            -0.027662355452775955,
            0.04491693899035454,
            -0.019148608669638634,
            -0.011106044985353947,
            -0.06794721633195877,
            -0.004326966125518084,
            0.02178376168012619,
            0.010819479823112488,
            0.00032483082031831145,
            0.008935626596212387,
            -0.0003184304805472493,
            -0.015481707639992237,
            0.018595868721604347,
            -0.0002422062389086932,
            -0.03935018181800842,
            0.014414089731872082,
            -0.025582097470760345,
            0.01879006437957287,
            -0.0053112744353711605,
            0.026497112587094307,
            -0.00812353566288948,
            0.029007935896515846,
            0.017708614468574524,
            -0.0005297149764373899,
            0.012780427001416683,
            -0.0025559028144925833,
            -0.014738600701093674,
            -0.013605598360300064,
            0.016882076859474182,
            -0.004902346525341272,
            0.014260711148381233,
            -0.010847941040992737,
            0.016185441985726357,
            0.009455783292651176,
            -0.016299046576023102,
            0.018967280164361,
            -0.02727781981229782,
            0.023342186585068703,
            0.04227897524833679,
            -0.022647105157375336,
            0.023103876039385796,
            -0.016208158805966377,
            -0.01413348875939846,
            -0.056632399559020996,
            -0.020899303257465363,
            -0.031226102262735367,
            0.02353867143392563,
            -0.020103180781006813,
            0.0020121897105127573,
            -0.01179462019354105,
            0.018777145072817802,
            0.0026658803690224886,
            0.019325360655784607,
            0.05935808643698692,
            -0.02702099084854126,
            -0.0040186247788369656,
            0.020530635491013527,
            -0.025843461975455284,
            -0.002890842966735363,
            -0.0028811730444431305,
            0.021901777014136314,
            -0.009929575026035309,
            0.00842287763953209,
            0.02504369057714939,
            0.003154104808345437,
            0.018078869208693504,
            0.006438456475734711,
            -0.03158453851938248,
            0.004765077959746122,
            0.026447003707289696,
            -0.019647765904664993,
            0.006345001980662346,
            -0.006856569554656744,
            -0.044430240988731384,
            0.03959759697318077,
            -0.023567501455545425,
            0.0014400772051885724,
            -0.016022061929106712,
            0.03876423090696335,
            -0.03905639797449112,
            -0.000935800897423178,
            0.020495109260082245,
            0.029782196506857872,
            -0.015667231753468513,
            -0.023080823943018913,
            0.017718767747282982,
            -0.017005199566483498,
            0.0032043051905930042,
            0.030759572982788086,
            0.002677378011867404,
            -0.019954925402998924,
            0.0006111529073677957,
            0.031298838555812836,
            -0.02241852506995201,
            -0.007089902181178331,
            -0.01678776554763317,
            -0.01274624653160572,
            -0.023312322795391083,
            0.012499201111495495,
            -0.01490069180727005,
            0.012660222128033638
        ],
        [
            0.007302822545170784,
            -0.02701556123793125,
            0.0008025363786146045,
            -0.026561813428997993,
            -0.014605952426791191,
            -0.061856091022491455,
            -0.0018201875500380993,
            -0.02125346101820469,
            -0.01666550152003765,
            0.02778208628296852,
            0.01271557155996561,
            0.004052793141454458,
            -0.00726311607286334,
            0.030973931774497032,
            -0.03330278396606445,
            0.0022575962357223034,
            0.002499880502000451,
            -0.02548796497285366,
            -0.00130165193695575,
            0.03045351803302765,
            0.0016784449107944965,
            -0.012444909662008286,
            0.025089772418141365,
            0.026285501196980476,
            -0.012019074521958828,
            0.01701154187321663,
            0.041474275290966034,
            0.00939691811800003,
            -0.01175443921238184,
            0.017731310799717903,
            0.03618815541267395,
            -0.0035526889842003584,
            0.006987950298935175,
            0.036757443100214005,
            -0.02151862531900406,
            -0.032536543905735016,
            -0.014736153185367584,
            -0.006714852061122656,
            0.003026618156582117,
            -0.013234389945864677,
            0.01852714642882347,
            0.02363612875342369,
            -0.003153042634949088,
            -0.004682235885411501,
            0.012839440256357193,
            0.046378493309020996,
            0.02938762865960598,
            0.009036649018526077,
            0.020969029515981674,
            0.01666765846312046,
            0.017510442063212395,
            -0.02332768402993679,
            0.013383402489125729,
            -0.020893018692731857,
            -0.025421489030122757,
            0.04523779824376106,
            -0.020924940705299377,
            0.012611664831638336,
            -0.0001271777000511065,
            0.03665886074304581,
            -0.008424587547779083,
            0.008887995965778828,
            0.004166741389781237,
            0.02320743538439274,
            0.05397830903530121,
            0.004681102465838194,
            0.017642298713326454,
            -0.014259200543165207,
            -0.013387706130743027,
            0.0021743890829384327,
            0.019891271367669106,
            0.003443172201514244,
            0.03683948144316673,
            0.0006621437496505678,
            0.0034748087637126446,
            0.012200627475976944,
            0.03319881111383438,
            0.02558520995080471,
            -0.014131099916994572,
            0.01637703739106655,
            -0.0010136723285540938,
            -0.0081260297447443,
            0.012199969962239265,
            0.018668239936232567,
            0.014302362687885761,
            -0.023930080235004425,
            0.026593683287501335,
            0.02047654055058956,
            0.019747430458664894,
            -0.020669544115662575,
            -0.001052873209118843,
            0.0010744857136160135,
            -0.002286443253979087,
            -0.02070421725511551,
            -4.3502335756784305e-05,
            0.008414112962782383,
            0.0305758286267519,
            0.0020953675266355276,
            0.06455977261066437,
            0.02059479057788849,
            0.014121651649475098,
            0.009491891600191593,
            -0.023412305861711502,
            -0.017338372766971588,
            -0.027560457587242126,
            0.02655183896422386,
            0.0019527317490428686,
            -0.006015821360051632,
            0.019063549116253853,
            -0.011454957537353039,
            0.008782326243817806,
            -0.025524728000164032,
            -0.003276748349890113,
            -0.01958511956036091,
            0.00569216487929225,
            -0.008113013580441475,
            0.008973527699708939,
            0.027104545384645462,
            -0.03041689470410347,
            0.030558981001377106,
            0.011527697555720806,
            -0.0036534189712256193,
            -0.06264503300189972,
            0.03635473549365997,
            -0.01678418181836605,
            0.0036257097963243723,
            -0.016971496865153313,
            0.0035625591408461332,
            0.018234537914395332,
            -0.012866523116827011,
            0.028955381363630295,
            0.026770535856485367,
            -0.020200777798891068,
            -0.02666870877146721,
            -0.02060147561132908,
            0.021520160138607025,
            -0.004560639150440693,
            0.019767044112086296,
            -0.025527605786919594,
            -0.019284548237919807,
            0.007930812425911427,
            -0.010582285933196545,
            -0.0076450081542134285,
            0.0048273284919559956,
            0.015249708667397499,
            -0.009890864603221416,
            0.029180804267525673,
            0.027792146429419518,
            -0.011261539533734322,
            -0.0018209448317065835,
            0.030934849753975868,
            0.024700364097952843,
            -0.010408210568130016,
            -0.012910113669931889,
            -0.012920189648866653,
            0.03326341137290001,
            0.02629142627120018,
            0.016446806490421295,
            0.001219285884872079,
            0.016524510458111763,
            -0.011712456122040749,
            0.015292318537831306,
            0.03568282350897789,
            0.013628723099827766,
            -0.0018055037362501025,
            0.01179814524948597,
            -0.017938842996954918,
            -0.0009647636325098574,
            0.06584948301315308,
            -0.005035576410591602,
            -0.016813626512885094,
            -0.00828598253428936,
            0.023667337372899055,
            0.03363334760069847,
            0.0007712417864240706,
            -0.0329180508852005,
            -0.012805174104869366,
            -0.0014537378447130322,
            -0.038171861320734024,
            -0.0007594135240651667,
            -0.03434017673134804,
            0.0013151431921869516,
            -0.029743356630206108,
            0.01719883270561695,
            -0.01598639413714409,
            -0.023735785856842995,
            -0.008672963827848434,
            0.02568681910634041,
            0.013158706948161125,
            0.01355193555355072,
            0.0039539821445941925,
            0.018762702122330666,
            0.011062417179346085,
            0.0030781340319663286,
            -0.025398418307304382,
            0.023086292669177055,
            0.004193215165287256,
            -0.02207687497138977,
            0.02993936836719513,
            -0.032013557851314545,
            0.005069606937468052,
            -0.029903821647167206,
            -0.022672653198242188,
            -0.010464215651154518,
            0.05383821949362755,
            3.0467132091871463e-05,
            0.015063559636473656,
            -0.03307976946234703,
            -0.006553387735038996,
            -0.012475037947297096,
            0.014554797671735287,
            -0.009217482060194016,
            0.03527963533997536,
            0.02471008710563183,
            -0.0025654227938503027,
            0.012736493721604347,
            -0.03702916204929352,
            0.005064014345407486,
            0.004887183662503958,
            -0.008227217942476273,
            0.0386296771466732,
            -0.004309955984354019,
            -0.024537721648812294,
            0.006203825119882822,
            0.016526509076356888,
            -0.039905909448862076,
            -0.015889432281255722,
            -0.010342944413423538,
            -0.004541301168501377,
            -0.01161862164735794,
            -0.030738649889826775,
            0.022794989868998528,
            0.018515225499868393,
            0.03193628415465355,
            0.0009821123676374555,
            0.03681241348385811,
            -0.04945812374353409,
            -0.036913223564624786,
            0.014559228904545307,
            -0.03607264161109924,
            0.0003988278331235051,
            -0.014053638093173504,
            -0.024696368724107742,
            -0.013187885284423828,
            0.0068449596874415874,
            -0.022503476589918137,
            0.008441966027021408,
            -0.004568892531096935,
            -0.017446372658014297,
            0.013792920857667923,
            -0.011934942565858364,
            0.0018420973792672157,
            0.019002744928002357,
            -0.015656696632504463,
            0.03171619772911072,
            0.024655889719724655,
            -0.03590700402855873,
            0.005265322979539633,
            0.03643345460295677,
            -0.022926893085241318,
            -0.020450139418244362,
            0.012673269025981426,
            0.01640838012099266,
            -0.029291411861777306,
            0.017804117873311043,
            -0.013377968221902847,
            -0.00928632915019989,
            0.006966053508222103,
            0.003720381762832403,
            -0.0486455000936985,
            -0.026234222576022148,
            -0.03862779214978218,
            -0.010895516723394394,
            0.011853526346385479,
            -0.018831854686141014,
            0.01900615356862545,
            -0.01277226209640503,
            -0.0052318815141916275,
            0.035258833318948746,
            -0.011921906843781471,
            -0.009252640418708324,
            0.00773406820371747,
            -0.021151704713702202,
            -0.02306962013244629,
            0.033088091760873795,
            0.002587285591289401,
            -0.0046803089790046215,
            -0.04750053212046623,
            -0.01217131782323122,
            0.011128022335469723,
            0.015601614490151405,
            0.004780793096870184,
            -0.03444570675492287,
            -0.02535676583647728,
            0.011989499442279339,
            0.010611830279231071,
            0.020786145702004433,
            -0.008929004892706871,
            0.006483649369329214,
            -0.030189691111445427,
            -0.0010683431755751371,
            -0.0037082491908222437,
            0.01068086177110672,
            0.003790832357481122,
            0.004210995975881815,
            -0.010528139770030975,
            -0.014098286628723145,
            -0.019533207640051842,
            -0.01105758547782898,
            -0.005571636836975813,
            0.02608655020594597,
            -0.014804399572312832,
            0.011946490034461021,
            0.020452795550227165,
            0.02151598036289215,
            -0.013070310465991497,
            0.023162972182035446,
            0.01692557893693447,
            -0.011050748638808727,
            -0.023981796577572823,
            0.012850798666477203,
            -0.014235860668122768,
            0.043107107281684875,
            -0.04968395456671715,
            -0.01727111078798771,
            0.010996962897479534,
            -0.024316731840372086,
            0.009265263564884663,
            0.010301688686013222,
            0.02068510092794895,
            0.0029997925739735365,
            0.005449940450489521,
            0.003910766448825598,
            -0.002296825870871544,
            0.007075375411659479,
            -0.0208097193390131,
            0.02569548971951008,
            0.018840961158275604,
            -0.012702830135822296,
            0.01591230183839798,
            0.015876907855272293,
            0.015947699546813965,
            -0.011987855657935143,
            0.011230404488742352,
            0.04212924838066101,
            0.005877932533621788,
            -0.020813072100281715,
            -0.010040412656962872,
            0.012419914826750755,
            -0.004239632748067379,
            0.02176191657781601,
            -0.0012315214844420552,
            0.03975255787372589,
            -0.02478637732565403,
            0.02411067858338356,
            -0.016310065984725952,
            -0.03180142864584923,
            -0.055276356637477875,
            0.03830946981906891,
            -0.023568764328956604,
            0.01315585058182478,
            -0.01905733346939087,
            0.04376263543963432,
            -0.003357494017109275,
            0.030875492841005325,
            0.020532315596938133,
            -0.028560061007738113,
            -0.003320791060104966,
            0.016538966447114944,
            -0.022421550005674362,
            0.029202044010162354,
            -0.005593876354396343,
            -0.031514350324869156,
            0.02939630299806595,
            -0.01029537059366703,
            0.011072192341089249,
            0.020041601732373238,
            0.018840104341506958,
            0.03695910796523094,
            0.03083893470466137,
            -0.008163496851921082,
            -0.008968612179160118,
            0.020981287583708763,
            0.005959074012935162,
            -0.0002952316717710346,
            -0.04123500734567642,
            -0.013866276480257511,
            -0.03384721279144287,
            -0.013921487145125866,
            -0.02059497870504856,
            0.012269021943211555,
            0.017364053055644035,
            0.021370964124798775,
            0.015992136672139168,
            0.012527955695986748,
            -0.003535574534907937,
            -0.007569108158349991,
            -0.012290780432522297,
            -0.018848007544875145,
            -0.0222230926156044,
            -0.038662996143102646,
            0.02323174849152565,
            0.013019740581512451,
            0.004688863642513752,
            0.016279635950922966,
            0.0016244295984506607,
            0.008443226106464863,
            0.02959693968296051,
            0.019884632900357246,
            0.007181357592344284,
            0.004751432687044144,
            0.02455010823905468,
            0.04418567940592766,
            0.04656093195080757,
            -0.04310297593474388,
            -0.0017629761714488268,
            0.011056438088417053,
            -0.04642363265156746,
            0.04839455708861351,
            -0.01180614996701479,
            0.005507931113243103,
            -0.014567481353878975,
            -0.015831420198082924,
            -0.00481612840667367,
            -0.005916581954807043,
            0.018519489094614983,
            0.006602579262107611,
            -0.027127869427204132,
            0.012732376344501972,
            -0.017971577122807503,
            0.01265404000878334,
            -0.03002878837287426,
            -0.020823409780859947,
            0.03138575330376625,
            0.0057442463003098965,
            0.002212665043771267,
            0.03102092817425728,
            0.014203998260200024,
            -0.016641318798065186,
            0.028986221179366112,
            -0.01954970322549343,
            0.02501099742949009,
            0.025316491723060608,
            -0.04874560609459877,
            -0.009372645989060402,
            -0.013699054718017578,
            0.03441779688000679,
            0.05839552357792854,
            -0.011379373259842396,
            -0.03587517887353897,
            -0.006671107839792967,
            0.0003023833269253373,
            -0.07216370105743408,
            0.007402232848107815,
            -0.03290806710720062,
            0.005704587325453758,
            0.05528853461146355,
            0.004879820626229048,
            0.00946851447224617,
            0.0075778537429869175,
            -0.005013999529182911,
            -0.01877724751830101,
            -0.001571817439980805,
            -0.00978911854326725,
            -0.008898365311324596,
            -0.007855718955397606,
            0.0021935617551207542,
            0.03242472559213638,
            0.02187134511768818,
            0.01689075119793415,
            -0.021977044641971588,
            -0.010547405108809471,
            -0.0035890520084649324,
            0.014333289116621017,
            0.036581434309482574,
            0.002650074427947402,
            0.02999899536371231,
            -0.02666597254574299,
            0.0065433308482170105,
            0.010723349638283253,
            -0.014756878837943077,
            0.03397803381085396,
            -0.005966721102595329,
            0.016421115025877953,
            -0.020434029400348663,
            -0.006465723738074303,
            0.011333315633237362,
            -0.030640816316008568,
            -0.07080399990081787,
            0.019133778288960457,
            0.021917223930358887,
            -0.02211693860590458,
            0.028167378157377243,
            0.05945831164717674,
            0.031448084861040115,
            0.026417726650834084,
            -0.030742952600121498,
            0.01663333550095558,
            -0.024771058931946754,
            -0.00249731563962996,
            0.0008269883110187948,
            -0.00030469035846181214,
            0.01239763479679823,
            -0.03956374526023865,
            -0.0032817302271723747,
            -0.027463627979159355,
            0.03163053095340729,
            0.007835091091692448,
            -0.012574085034430027,
            0.003317709779366851,
            0.019638054072856903,
            0.027157437056303024,
            -0.019186420366168022,
            0.015939565375447273,
            -0.04785788804292679,
            -0.022983599454164505,
            -0.003901124931871891,
            -0.028434058651328087,
            -0.03583850711584091,
            -0.003085922682657838,
            0.01938694901764393,
            0.02248341031372547,
            -0.015879683196544647,
            -0.01196000725030899,
            0.059863604605197906,
            0.014165064319968224,
            0.05524937063455582,
            -0.013887663371860981,
            -0.015852157026529312,
            0.016162853688001633,
            0.01586891897022724,
            0.030445706099271774,
            0.022646524012088776,
            -0.01955399475991726,
            0.02676195092499256,
            0.04570727422833443,
            0.02050870843231678,
            -0.037248991429805756,
            0.0022776685655117035,
            -0.007706406991928816,
            0.024447791278362274,
            -0.02599087916314602,
            0.056615907698869705,
            -0.047146979719400406,
            0.006163180805742741,
            0.019862117245793343,
            0.010931544937193394,
            0.006123076658695936,
            -0.000576576916500926,
            0.018767675384879112,
            0.006837141700088978,
            0.021928049623966217,
            -0.02358722686767578,
            0.004825395531952381,
            -0.0064477077685296535,
            -0.01572599820792675,
            -0.022305548191070557,
            0.010119504295289516,
            -0.015570574440062046,
            -0.023059815168380737,
            -0.014189166016876698,
            -0.021963585168123245,
            0.030440444126725197,
            -0.0018956790445372462,
            0.010642115958034992,
            0.020938025787472725,
            -0.01294588390737772,
            0.009073390625417233,
            -0.024242360144853592,
            0.0008605057955719531,
            0.021231671795248985,
            -0.025545906275510788,
            -0.024455448612570763,
            -0.009397651068866253,
            0.011868408881127834,
            0.03178118169307709,
            -0.0067396159283816814,
            -0.024518854916095734,
            -0.011621288023889065,
            -0.023635921999812126,
            0.030987611040472984,
            0.018641887232661247,
            0.018637986853718758,
            0.028573837131261826,
            0.012395884841680527,
            0.004894730634987354,
            0.016085071489214897,
            0.0009333866182714701,
            -0.021071093156933784,
            0.006459606811404228,
            0.01467737928032875,
            -0.018210982903838158,
            0.020250804722309113,
            0.02964106947183609,
            -0.0074122012592852116,
            0.020340867340564728,
            -0.0023798136971890926,
            -0.002012730110436678,
            -0.011334146372973919,
            0.03701414912939072,
            -0.006241183262318373,
            -0.010532689280807972,
            0.010798778384923935,
            -0.006257105618715286,
            -0.007029770407825708,
            -0.027247175574302673,
            -0.0036423983983695507,
            0.008623069152235985,
            -0.03190019726753235,
            0.018253803253173828,
            0.021211959421634674,
            -0.022634001448750496,
            -0.014982443302869797,
            -0.016460765153169632,
            0.014047659002244473,
            0.020776476711034775,
            0.03731059655547142,
            0.003756473306566477,
            -0.025481320917606354,
            0.01670457050204277,
            -0.03567943722009659,
            -0.0032131928019225597,
            0.003725256770849228,
            0.0015806572046130896,
            -0.007038538809865713,
            0.026294700801372528,
            -0.030946971848607063,
            -0.007808987982571125,
            0.01542733609676361,
            0.034245602786540985,
            -0.011638987809419632,
            0.020421916618943214,
            -0.019405823200941086,
            0.010482268407940865,
            0.017029505223035812,
            0.015471812337636948,
            0.013680550269782543,
            -0.005238236393779516,
            -0.0010168506996706128,
            0.018941812217235565,
            0.0005141653819009662,
            0.039070505648851395,
            0.008301555179059505,
            0.011164296418428421,
            0.025622034445405006,
            0.00461400393396616,
            -0.04050228372216225,
            -0.008792606182396412,
            0.0009011729853227735,
            0.018157031387090683,
            -0.012809754349291325,
            0.00908437930047512,
            -0.023842066526412964,
            -0.0037280127871781588,
            -0.021409399807453156,
            0.013583100400865078,
            -0.03137260302901268,
            -0.00968512799590826,
            -0.0064583574421703815,
            -0.027297943830490112,
            -0.016718797385692596,
            0.00012761929247062653,
            0.011419192887842655,
            0.017689352855086327,
            -0.009319614619016647,
            0.005292587447911501,
            -0.014395923353731632,
            0.013169870711863041,
            -0.019262094050645828,
            0.014367072843015194,
            0.012458317913115025,
            0.016031421720981598,
            -0.0200028195977211,
            -0.009143178351223469,
            0.018109550699591637,
            0.014367048628628254,
            0.03581099957227707,
            -0.014607519842684269,
            -0.02173500321805477,
            0.02791914902627468,
            -0.005946377292275429,
            0.03323417901992798,
            -0.003059050999581814,
            -0.006260231602936983,
            0.005363122094422579,
            0.010861284099519253,
            0.04545178264379501,
            0.0034057265147566795,
            -0.029507003724575043,
            -0.014856388792395592,
            0.006855563726276159,
            -0.011235169135034084,
            -0.004966996610164642,
            0.00838697049766779,
            -0.02508269064128399,
            0.02777184173464775,
            0.03623971715569496,
            -0.014399218373000622,
            0.03274308145046234,
            -0.004353736527264118,
            0.015033558942377567,
            -0.014916807413101196,
            0.01633269712328911,
            0.019938357174396515,
            0.014579408802092075,
            -0.040045782923698425,
            0.0066133844666182995,
            -0.012116928584873676,
            -0.013576953671872616,
            0.005914342124015093,
            -0.02566790208220482,
            -0.02540791593492031,
            0.023407472297549248,
            0.012790723703801632,
            -0.011111633852124214,
            0.006060635205358267,
            -0.042139600962400436,
            0.0059504457749426365,
            -0.004026875831186771,
            0.017076939344406128,
            -0.018775558099150658,
            -0.011317072436213493,
            0.03448164463043213,
            -0.00018018610717263073,
            0.025394964963197708,
            0.005694658029824495,
            0.01890355907380581,
            -0.019524529576301575,
            0.01001489907503128,
            0.023956306278705597,
            -0.012440689839422703,
            0.023805741220712662,
            0.008176776580512524,
            -0.005118659697473049,
            8.173847891157493e-05,
            6.949697126401588e-05,
            -0.023815307766199112,
            -0.014680671505630016,
            -0.006606734823435545,
            -0.013810872100293636,
            -0.023795025423169136,
            -0.0001379589521093294,
            0.0060651847161352634,
            0.012105241417884827,
            -0.018024388700723648,
            0.0030969041399657726,
            0.008411569520831108,
            -0.004097925499081612,
            0.027749890461564064,
            -0.014042266644537449,
            0.012011362239718437,
            -0.009592985734343529,
            0.025350233539938927,
            0.002362647093832493,
            0.028959941118955612,
            -0.01631154492497444,
            0.04266447201371193,
            0.004386435728520155,
            -0.04123269021511078,
            -0.01548308227211237,
            0.032055698335170746,
            0.005272903945297003,
            0.02571233920753002,
            0.006545564625412226,
            -0.008503196761012077,
            -0.04310690611600876,
            0.0014106397284194827,
            0.027715206146240234,
            0.02367510087788105,
            -0.011276756413280964,
            0.024329064413905144,
            -0.031653136014938354,
            0.0130078736692667,
            -0.031799688935279846,
            0.01779416762292385,
            0.019077621400356293,
            0.019014710560441017,
            0.010605907998979092,
            -0.0030425041913986206,
            0.0028890788089483976,
            0.03822745755314827,
            -0.025224456563591957,
            0.0012000080896541476,
            -0.030842909589409828,
            -0.01176411472260952,
            -0.009198312647640705,
            -0.01328713446855545,
            0.0075338855385780334,
            -0.022552086040377617,
            0.019808461889624596,
            -0.017776455730199814,
            -0.006190090905874968,
            -0.019362056627869606,
            -0.04466640204191208,
            -0.017648328095674515,
            -0.019217459484934807,
            -0.027553755789995193,
            -0.018353916704654694,
            0.02540801279246807,
            0.029133949428796768,
            -0.0313907153904438,
            -0.026777345687150955,
            0.04559658095240593,
            -0.011902104131877422,
            -0.03775980696082115,
            0.040307071059942245,
            0.012571919709444046,
            -0.0018131118267774582,
            0.004098875913769007,
            0.022166891023516655,
            -0.014868555590510368,
            -0.051041748374700546,
            -0.015217848122119904,
            0.03847905248403549,
            -0.012481508776545525,
            0.018658529967069626,
            0.010533920489251614,
            0.022159472107887268,
            -0.00413410784676671,
            -0.00944067258387804,
            -0.015556017868220806,
            -0.01800382509827614,
            -0.023725349456071854,
            0.004161710850894451,
            -0.00598518829792738,
            0.01301096472889185,
            0.02436070516705513,
            0.0081538250669837,
            -0.02228069305419922,
            0.017296461388468742,
            0.007941087707877159,
            0.015048405155539513,
            0.022378141060471535,
            0.013914602808654308,
            0.007529257796704769,
            -0.019131191074848175,
            -0.004072638228535652,
            0.006890719756484032,
            -0.019508935511112213,
            -0.00724956626072526,
            0.008615708909928799,
            -0.011128266341984272,
            0.009551317431032658,
            -0.019670678302645683,
            0.007438759785145521,
            -0.004914410877972841,
            0.020653145387768745,
            -0.01658358983695507,
            0.0010215885704383254,
            0.023923752829432487,
            -0.01656404510140419,
            0.008621524088084698,
            0.043139491230249405,
            -0.004954095929861069,
            0.04209095239639282,
            -0.025476541370153427,
            -0.012854190543293953,
            0.001115142018534243,
            0.012327034957706928,
            0.008966849185526371,
            -0.027281880378723145,
            -0.01862797513604164,
            0.012297467328608036,
            -0.013786078430712223,
            0.01129032950848341,
            -0.014247183687984943,
            0.012847600504755974,
            0.0036158780567348003,
            0.027737880125641823,
            -0.0349799320101738,
            -0.01622653938829899,
            -0.010418830439448357,
            -0.024005431681871414,
            0.02961762063205242,
            -0.02551981806755066,
            -0.017717914655804634,
            -0.016668424010276794,
            -0.03527114540338516,
            -0.002956297481432557,
            -0.008188880048692226,
            0.01351245492696762,
            -0.00014376740728039294,
            -0.053509268909692764,
            0.026028897613286972,
            -0.01362188532948494,
            0.010583213530480862,
            0.0033368412405252457,
            -0.002981443889439106,
            0.03610444813966751,
            0.02753446437418461,
            0.019968345761299133,
            -0.008067450486123562,
            0.02385357953608036,
            -0.018201148137450218,
            -0.010666484013199806,
            0.01020717341452837,
            0.0010496483882889152,
            0.036058876663446426,
            0.017532510682940483,
            0.013886052183806896,
            0.032232239842414856,
            -0.033045876771211624,
            -0.028612045571208,
            -0.026623211801052094,
            0.018397854641079903,
            -0.036136478185653687,
            -0.021837158128619194,
            -0.019475089386105537,
            0.011538910679519176,
            0.04480772837996483,
            0.03716380521655083,
            -0.02246544510126114,
            0.022393357008695602,
            -0.028530029579997063,
            -0.027338223531842232,
            0.008823304437100887,
            0.003474811092019081,
            0.014677039347589016,
            0.016638359054923058,
            0.029061174020171165,
            -0.00550440326333046,
            0.041568875312805176,
            -0.01610724627971649,
            -0.020016705617308617,
            -0.0030109414365142584,
            -0.0056769768707454205,
            -0.015625454485416412,
            0.022612037137150764,
            0.022905977442860603,
            -0.010468020103871822,
            -0.002981817815452814,
            -0.009270826354622841,
            -0.02033708058297634,
            0.023397281765937805,
            0.03154301270842552,
            -0.0058026243932545185,
            0.014991026371717453,
            -0.03471411019563675,
            -0.016823548823595047,
            0.031281501054763794,
            0.004608260001987219,
            -0.014043913222849369,
            -0.012474674731492996,
            0.03723269701004028,
            0.010551056824624538,
            -0.014646748080849648,
            -0.0008128061308525503,
            -0.07007313519716263,
            0.020322676748037338,
            0.0038176265079528093,
            0.045760903507471085,
            0.004981964826583862,
            -0.011193751357495785,
            0.029037930071353912,
            -0.014427746646106243,
            -8.701308979652822e-05,
            0.0018395729130133986,
            0.03570584952831268,
            -0.028247151523828506,
            -0.045445188879966736,
            -0.046668969094753265,
            0.006113999988883734,
            0.01916939578950405,
            -0.025290971621870995,
            -0.03096872568130493,
            -0.01185663416981697,
            0.00885862112045288,
            -0.009990439750254154,
            0.008337794803082943,
            0.012801711447536945,
            0.018823863938450813,
            0.014719375409185886,
            -0.010105619207024574,
            0.016647323966026306,
            -0.03732585534453392,
            0.03006310947239399,
            -0.03813298046588898,
            -0.0247406717389822,
            -0.013331303372979164,
            -0.03733639419078827,
            -0.008246134035289288,
            0.00783037580549717,
            0.0017671330133453012,
            0.015596332028508186,
            -0.010059408843517303,
            0.0019797896966338158,
            0.007137491833418608,
            0.03981702774763107,
            -0.006079214159399271,
            -0.03149690479040146,
            -0.004323792643845081,
            -0.030856242403388023,
            -0.03949008509516716,
            -0.034409452229738235,
            0.036693230271339417,
            0.000686877581756562,
            -0.013083957135677338,
            -0.005273668561130762,
            0.02129311114549637,
            0.03506166860461235,
            0.023064758628606796,
            -0.024118229746818542,
            0.013597573153674603,
            0.009222948923707008,
            0.007309712003916502,
            0.011374098248779774,
            0.00013666076119989157,
            -0.012331687845289707,
            0.009647935628890991,
            -0.006113595329225063,
            -0.0017384279053658247,
            0.019699659198522568,
            0.008882281370460987,
            0.008514736779034138,
            -0.022226037457585335,
            0.019064294174313545,
            0.035181138664484024,
            0.004291045945137739,
            -0.04025899991393089,
            -0.011149351485073566,
            0.03170143440365791,
            0.002518924418836832,
            0.03375960886478424,
            0.030299153178930283
        ],
        [
            0.017088228836655617,
            -0.018675487488508224,
            -0.005244134925305843,
            0.014013472944498062,
            -0.004185633733868599,
            0.03410414233803749,
            -0.006119244731962681,
            0.00503191165626049,
            0.0006331389886327088,
            -0.02886134572327137,
            -0.016474703326821327,
            0.01673322729766369,
            -0.03930593281984329,
            -0.0014746296219527721,
            -0.059402044862508774,
            0.004601139109581709,
            -0.00967200007289648,
            0.011319579556584358,
            -0.02043863944709301,
            -0.01403797697275877,
            0.0016151397721841931,
            0.01319133024662733,
            0.04936631768941879,
            -0.030050009489059448,
            0.015584558248519897,
            -0.031977422535419464,
            0.017984293401241302,
            -0.031339239329099655,
            0.013060024939477444,
            -0.012885047122836113,
            -0.008354207500815392,
            -0.01934373751282692,
            -0.006686116103082895,
            -0.02661868743598461,
            0.007427912671118975,
            0.003005554201081395,
            -0.02594614401459694,
            0.03132972866296768,
            0.001798689947463572,
            0.018248962238430977,
            0.006927719339728355,
            -0.00035703383036889136,
            0.009455550462007523,
            -0.0029105255380272865,
            -0.01889086700975895,
            0.015889693051576614,
            -0.011038709431886673,
            -0.038663458079099655,
            0.00577534269541502,
            0.022263500839471817,
            -0.0239971112459898,
            0.018342474475502968,
            -0.03231126442551613,
            0.00882504042237997,
            -0.03337930887937546,
            0.009015913121402264,
            -0.006279860623180866,
            0.008857562206685543,
            -0.004790257662534714,
            0.0264273714274168,
            0.001517110038548708,
            0.005713477730751038,
            0.000584167311899364,
            -0.01817484386265278,
            0.031155306845903397,
            -0.018853073939681053,
            -0.025351589545607567,
            0.019333047792315483,
            -0.011309478431940079,
            -0.01216832920908928,
            -0.012437681667506695,
            0.0202115997672081,
            0.00706071499735117,
            0.024760853499174118,
            0.004740509204566479,
            -0.03283330053091049,
            0.005560808349400759,
            0.00048807618441060185,
            -0.034884218126535416,
            -0.032109782099723816,
            -0.015702810138463974,
            0.020510081201791763,
            0.036572325974702835,
            0.00995120219886303,
            -0.0035498030483722687,
            -0.020344046875834465,
            -0.013997746631503105,
            0.03145228326320648,
            0.029098117724061012,
            0.026877641677856445,
            -0.031704146414995193,
            0.009021134115755558,
            0.0302742850035429,
            -0.016956180334091187,
            -0.004221304319798946,
            -0.016038648784160614,
            -0.024792414158582687,
            0.01934482902288437,
            0.006331399083137512,
            -0.011803173460066319,
            -0.019287632778286934,
            -0.05041113123297691,
            -0.0065850745886564255,
            0.025412404909729958,
            -0.001436496269889176,
            -0.016460061073303223,
            -0.005938957445323467,
            0.022144200280308723,
            -0.0034347781911492348,
            0.00860578939318657,
            -0.022250575944781303,
            -0.01750114932656288,
            -0.017440179362893105,
            -0.02750839665532112,
            0.043768323957920074,
            -0.008806772530078888,
            0.011135788634419441,
            0.001877123606391251,
            0.017303213477134705,
            0.01836039498448372,
            -0.0018186874222010374,
            -0.003088597906753421,
            0.022765204310417175,
            -0.03448163717985153,
            -0.02860388718545437,
            0.017660049721598625,
            -0.023157581686973572,
            -0.024526547640562057,
            -0.02133006975054741,
            -0.024671480059623718,
            -0.03054044581949711,
            -0.031526993960142136,
            -0.01779317483305931,
            -0.004157067742198706,
            -0.028071125969290733,
            -0.024373859167099,
            0.0030425828881561756,
            0.04050348326563835,
            0.021096663549542427,
            -0.013032330200076103,
            0.013517173007130623,
            0.02921152114868164,
            0.016292642802000046,
            0.02453375980257988,
            0.002139028627425432,
            0.03399047255516052,
            0.013890055008232594,
            0.026528852060437202,
            0.005210793577134609,
            0.0039934441447257996,
            0.027392160147428513,
            0.028315696865320206,
            0.0027797624934464693,
            -0.021155070513486862,
            -0.000703485100530088,
            -0.013457924127578735,
            0.00033447818714194,
            0.014792769216001034,
            -0.0033296046312898397,
            -0.00010589109297143295,
            0.0023831352591514587,
            -0.022428443655371666,
            0.030106615275144577,
            -0.005567306187003851,
            0.02296214923262596,
            0.026932887732982635,
            -0.012354893609881401,
            -0.009142464958131313,
            0.028600512072443962,
            -0.0047729103825986385,
            -0.0035598413087427616,
            -0.028381604701280594,
            0.00010999877122230828,
            -0.022037003189325333,
            0.0021531519014388323,
            -0.015223522670567036,
            -0.002777877263724804,
            0.02292654477059841,
            0.015570495277643204,
            0.0008435416384600103,
            0.010035228915512562,
            -0.024194268509745598,
            -0.018433952704072,
            -0.011730684898793697,
            0.00379492761567235,
            0.030622193589806557,
            0.005672732833772898,
            -0.025716662406921387,
            -0.03369785100221634,
            -0.019412603229284286,
            0.01415940560400486,
            -0.00018729220028035343,
            0.04172642529010773,
            0.005179974250495434,
            0.032959166914224625,
            0.009495386853814125,
            -0.002324821660295129,
            0.003483182517811656,
            -0.01187606155872345,
            0.025135772302746773,
            -0.022574907168745995,
            -0.012422006577253342,
            -0.017179829999804497,
            0.028081651777029037,
            -0.018361495807766914,
            0.019637830555438995,
            -0.05208265408873558,
            0.019080111756920815,
            0.03236546367406845,
            -0.010844436474144459,
            0.0227428637444973,
            -0.023187367245554924,
            -0.004042116925120354,
            0.020317442715168,
            0.02312079817056656,
            0.01049018930643797,
            0.026876261457800865,
            0.01588977314531803,
            -0.0006609678384847939,
            -0.017524411901831627,
            0.01270969770848751,
            -0.030354924499988556,
            0.01186542958021164,
            0.011863290332257748,
            -0.023249676451086998,
            -0.038609351962804794,
            0.018709911033511162,
            -0.020692303776741028,
            0.014590208418667316,
            -0.006379939615726471,
            0.00506011163815856,
            0.00988051388412714,
            0.02475181594491005,
            0.007416100706905127,
            -0.01742185465991497,
            0.007627903483808041,
            0.04306633025407791,
            0.02755078673362732,
            0.028777271509170532,
            0.032180558890104294,
            -0.009646148420870304,
            -0.01953943632543087,
            -0.030527425929903984,
            0.009060591459274292,
            -0.013441886752843857,
            -0.01314416155219078,
            0.01993153803050518,
            0.022725321352481842,
            0.01653101108968258,
            -0.012767497450113297,
            -0.011932624503970146,
            -0.028591196984052658,
            -0.0039061703719198704,
            0.030238160863518715,
            -0.029731038957834244,
            0.022854633629322052,
            -0.030185874551534653,
            0.028376000002026558,
            0.03894057869911194,
            -0.02243582345545292,
            0.0043103755451738834,
            -0.018581459298729897,
            0.008431420661509037,
            0.02665812335908413,
            0.0030352736357599497,
            -0.027984293177723885,
            -0.013878689147531986,
            -0.005024690646678209,
            0.012885143980383873,
            0.026331298053264618,
            -0.003500375896692276,
            0.02549213357269764,
            0.004337995778769255,
            0.019893769174814224,
            -0.013099667616188526,
            -0.011361099779605865,
            0.010154632851481438,
            0.023381028324365616,
            0.01620468683540821,
            -0.03132849931716919,
            -0.005973882507532835,
            0.031202789396047592,
            -0.028338506817817688,
            -0.0007598947267979383,
            0.027841543778777122,
            0.01842779666185379,
            -0.014775171875953674,
            -0.030516624450683594,
            0.009566220454871655,
            -0.01135914959013462,
            0.030248994007706642,
            0.019754359498620033,
            -0.005100748036056757,
            -0.013912852853536606,
            -0.019459443166851997,
            0.011643810197710991,
            0.0031689205206930637,
            0.00010222783748758957,
            -0.031356580555438995,
            0.012040076777338982,
            -0.01607677899301052,
            0.005474813282489777,
            0.002424827078357339,
            -0.01755966991186142,
            -0.022272376343607903,
            -0.01770450733602047,
            -0.028852540999650955,
            -0.018590930849313736,
            -0.020336240530014038,
            -0.014294748194515705,
            0.01029203087091446,
            0.03045661561191082,
            -0.007953430525958538,
            0.0324874147772789,
            -0.01947595179080963,
            -0.0029882092494517565,
            -0.011132287792861462,
            0.011959904804825783,
            0.004505625925958157,
            0.008153250440955162,
            -0.00754355825483799,
            -0.026801571249961853,
            -0.0003296149370726198,
            0.018337121233344078,
            -0.02447858639061451,
            0.03594272956252098,
            0.01294702012091875,
            0.03042626939713955,
            0.0137566477060318,
            -0.028196372091770172,
            0.013891248032450676,
            0.023433323949575424,
            0.03761054575443268,
            -0.015042164362967014,
            0.012593303807079792,
            -0.0031753487419337034,
            0.008639885112643242,
            -0.008851389400660992,
            -0.023551465943455696,
            -0.00938675832003355,
            -0.01387244276702404,
            -0.03168840333819389,
            0.014857458882033825,
            0.0022702233400195837,
            0.02964434213936329,
            0.02874688245356083,
            -0.015007294714450836,
            0.020505430176854134,
            -0.017356790602207184,
            0.025955213233828545,
            0.016490893438458443,
            -0.012809351086616516,
            0.037158288061618805,
            0.02426747977733612,
            0.009525420144200325,
            0.019681066274642944,
            -0.02423931658267975,
            -0.023408977314829826,
            0.016259076073765755,
            -0.03546985611319542,
            0.01517881266772747,
            -0.01567872427403927,
            0.03090701997280121,
            -0.002164016244933009,
            -0.006408975459635258,
            0.030979853123426437,
            -0.01952916942536831,
            -0.013314258307218552,
            -0.005850206594914198,
            0.0053305476903915405,
            0.005969042424112558,
            0.011696038767695427,
            0.03812183067202568,
            0.01989859715104103,
            0.022602414712309837,
            -0.030967501923441887,
            0.03023943118751049,
            -0.019228925928473473,
            -0.00283733825199306,
            0.015772126615047455,
            -0.012804411351680756,
            -0.012997961603105068,
            0.0019024034263566136,
            0.02900044247508049,
            0.007718250621110201,
            -0.008060869760811329,
            0.03087460994720459,
            0.0061325435526669025,
            0.0017382403602823615,
            0.005905453115701675,
            -0.02523820847272873,
            -0.02291243150830269,
            0.004398645367473364,
            0.02530079521238804,
            0.06364059448242188,
            0.015336277894675732,
            -0.0020801322534680367,
            -0.01836862787604332,
            0.01561385951936245,
            0.03379137068986893,
            0.025046469643712044,
            -0.03182869404554367,
            0.005239044316112995,
            -0.017733728513121605,
            0.009965225122869015,
            -0.026095835492014885,
            -0.006797433365136385,
            -0.02626088820397854,
            0.024710295721888542,
            -0.014950219541788101,
            0.010130115784704685,
            -0.006375682074576616,
            0.016400951892137527,
            -0.006741594523191452,
            0.009425966069102287,
            -0.02178497239947319,
            0.003731220029294491,
            -0.0135338818654418,
            -0.0004351068055257201,
            0.008348815143108368,
            0.005079867318272591,
            0.012729945592582226,
            0.018628284335136414,
            0.0007203913992270827,
            -0.02496957778930664,
            -0.004147471860051155,
            -0.0135425990447402,
            -0.018485520035028458,
            -6.790056795580313e-05,
            -0.03678612783551216,
            -0.009744232520461082,
            0.02147248014807701,
            -0.018810562789440155,
            0.030974311754107475,
            0.0026981050614267588,
            -0.03307227045297623,
            -0.028892848640680313,
            -0.010809174738824368,
            -0.0069240969605743885,
            -0.00551602290943265,
            -0.01771284081041813,
            -0.02640698477625847,
            0.020508088171482086,
            -0.016639389097690582,
            -0.009669116698205471,
            0.028744377195835114,
            0.0085496436804533,
            0.05125098675489426,
            0.007924471981823444,
            0.02014131098985672,
            0.005788980517536402,
            -0.004638535436242819,
            0.0050691827200353146,
            0.018372653052210808,
            0.013213839381933212,
            0.0011510503245517612,
            0.0174588430672884,
            0.0025049683172255754,
            -0.030396776273846626,
            0.0015146821970120072,
            0.024451786652207375,
            -0.01830463670194149,
            0.026729276403784752,
            -0.028695806860923767,
            0.02336273156106472,
            -0.021243203431367874,
            -0.002280590822920203,
            0.006866660434752703,
            -0.006323988549411297,
            0.015517040155827999,
            -0.02278686687350273,
            -0.004941461607813835,
            0.03480925038456917,
            0.03890719637274742,
            -0.019654972478747368,
            -0.0014007792342454195,
            -0.011513669043779373,
            0.0037421162705868483,
            -0.018288712948560715,
            -0.011160796508193016,
            -0.004103572573512793,
            -0.012175289914011955,
            0.042205534875392914,
            0.004514478147029877,
            -0.0005918445531278849,
            0.019586428999900818,
            -0.027836116030812263,
            0.012464904226362705,
            -0.020483892410993576,
            0.0032264955807477236,
            0.01535971462726593,
            -0.014034866355359554,
            -0.010585391893982887,
            -0.02145659178495407,
            0.0441843718290329,
            0.01705157198011875,
            0.018566880375146866,
            -0.0020818577613681555,
            0.028614481911063194,
            0.020391646772623062,
            -0.01262511033564806,
            -0.025305144488811493,
            -0.009273002855479717,
            0.024626871570944786,
            -0.024750323966145515,
            -0.018391374498605728,
            -0.0044646961614489555,
            0.03174838796257973,
            0.0035318133886903524,
            0.022667722776532173,
            -0.016509994864463806,
            0.036498039960861206,
            0.021044813096523285,
            -0.027800168842077255,
            -0.0185630414634943,
            0.0031611258164048195,
            0.028551926836371422,
            0.007747293915599585,
            -0.022268129512667656,
            0.030652368441224098,
            -0.007356490474194288,
            0.01683393493294716,
            0.010336359031498432,
            0.010072271339595318,
            0.026164457201957703,
            0.0011000584345310926,
            0.03668288514018059,
            -0.022099830210208893,
            0.0020185105968266726,
            -0.0032970039173960686,
            -0.0034170018043369055,
            0.028932755813002586,
            0.03486283868551254,
            0.030524061992764473,
            0.029353013262152672,
            -0.018438950181007385,
            0.0066950637847185135,
            -0.0320185124874115,
            0.016167324036359787,
            -0.008713138289749622,
            -0.004083170089870691,
            0.01380828209221363,
            0.04385462775826454,
            0.016354020684957504,
            -0.02994617447257042,
            -0.02989073097705841,
            0.02357308752834797,
            -0.0070387935265898705,
            0.012580756098031998,
            -0.04303494095802307,
            -0.0023584277369081974,
            -0.0014562223805114627,
            0.016156291589140892,
            0.0069006760604679585,
            0.02316785790026188,
            0.017986223101615906,
            0.020001405850052834,
            -0.009233144111931324,
            -0.007510065566748381,
            0.01730128936469555,
            -0.002558273496106267,
            -0.022746535018086433,
            -0.012688188813626766,
            -0.02320108748972416,
            0.008436989970505238,
            0.008812965825200081,
            -0.0027853103820234537,
            0.0017497614026069641,
            -0.008261272683739662,
            0.013501276262104511,
            -0.01112140528857708,
            -0.00855360459536314,
            -0.018966801464557648,
            -0.010962681844830513,
            -0.03109331615269184,
            0.0028450810350477695,
            -0.016940223053097725,
            -0.02810591645538807,
            0.024847952648997307,
            -0.011742442846298218,
            0.0010519663337618113,
            0.017842872068285942,
            -0.01590682379901409,
            0.011310013011097908,
            0.0012285984121263027,
            0.02936628833413124,
            -0.003343580523505807,
            -0.007823825813829899,
            0.013727579265832901,
            -0.02312820591032505,
            0.014125704765319824,
            0.019657790660858154,
            -0.004977368284016848,
            0.009268254972994328,
            0.00782095454633236,
            -0.027539493516087532,
            -0.02953130565583706,
            0.004978727083653212,
            0.005272275768220425,
            -0.02072870172560215,
            0.01673559658229351,
            0.020819470286369324,
            -0.03505348786711693,
            0.026363860815763474,
            0.01897391676902771,
            -0.012226874940097332,
            -0.022234834730625153,
            -0.00025064233341254294,
            0.04420768842101097,
            0.002453484805300832,
            0.012143218889832497,
            -0.013617276214063168,
            -0.02544485777616501,
            -0.02047562785446644,
            -0.01323601882904768,
            0.030726032331585884,
            -0.0009192035649903119,
            0.018840989097952843,
            0.021230585873126984,
            0.014294303953647614,
            0.01615080237388611,
            -0.020757067948579788,
            -0.006746739149093628,
            0.015454518608748913,
            0.02004140056669712,
            0.01405278965830803,
            -0.022664684802293777,
            0.020848557353019714,
            0.0005709195975214243,
            -0.0022443076595664024,
            -0.011961288750171661,
            0.0008807354606688023,
            -0.004555610474199057,
            0.017254916951060295,
            0.0247830618172884,
            -0.028264686465263367,
            -0.026216605678200722,
            0.017865151166915894,
            0.02614792063832283,
            0.005628230981528759,
            0.00628629419952631,
            -0.015210975892841816,
            0.021236734464764595,
            -0.004815706983208656,
            0.03547884523868561,
            -0.028144674375653267,
            -0.017050987109541893,
            -0.021353429183363914,
            -0.02601393312215805,
            -0.020968301221728325,
            -0.0271780863404274,
            0.004801956936717033,
            0.0027305735275149345,
            -0.027019305154681206,
            0.002714392263442278,
            0.027161838486790657,
            -0.0264509879052639,
            -0.01906069926917553,
            -0.005292954389005899,
            0.03341912478208542,
            -0.0069855074398219585,
            0.0005821242812089622,
            -0.009340599179267883,
            -0.02806686796247959,
            -0.011429842561483383,
            0.029702359810471535,
            0.008041915483772755,
            -0.024995354935526848,
            -0.02104765549302101,
            -0.022733448073267937,
            -0.019308799877762794,
            -0.026856984943151474,
            0.01678881049156189,
            -0.008463689126074314,
            -0.02755008637905121,
            0.005120294634252787,
            0.012376070022583008,
            -0.018150586634874344,
            0.005564439110457897,
            0.0027985868509858847,
            -0.011411168612539768,
            -0.02161235176026821,
            0.0047649177722632885,
            -0.029457513242959976,
            -0.023470936343073845,
            -0.023989444598555565,
            0.005841584410518408,
            -0.010221531614661217,
            -0.026634525507688522,
            -0.02406102418899536,
            -0.015549512580037117,
            0.00043392929364927113,
            0.011240696534514427,
            0.014961259439587593,
            0.012135567143559456,
            0.01892646960914135,
            -0.02424045465886593,
            0.011083042249083519,
            -0.023254750296473503,
            0.0007589732995256782,
            -0.015418954193592072,
            0.008890051394701004,
            0.00550979794934392,
            -0.018538791686296463,
            0.026429399847984314,
            -0.026133758947253227,
            -0.016614971682429314,
            -0.0022846220526844263,
            0.02142619714140892,
            0.0015811368357390165,
            0.022330502048134804,
            0.027318637818098068,
            -0.010910856537520885,
            -0.027594050392508507,
            0.03083394467830658,
            -0.004200800321996212,
            0.05281836912035942,
            -0.0019542579539120197,
            0.006429213099181652,
            -0.012523198500275612,
            0.006941886618733406,
            -0.02682635188102722,
            0.02938690036535263,
            -0.0025842259638011456,
            -0.0049370331689715385,
            0.010527113452553749,
            0.028753167018294334,
            0.008431837894022465,
            -0.033400487154722214,
            0.0017107068561017513,
            -0.01430442649871111,
            0.010274305008351803,
            -0.003054019995033741,
            0.028971776366233826,
            0.022640997543931007,
            -0.011946196667850018,
            -0.003776835510507226,
            -0.006101312581449747,
            0.010003549978137016,
            0.019682465121150017,
            0.014803480356931686,
            -0.010353242047131062,
            -0.004807508084923029,
            -0.025519661605358124,
            0.01909712515771389,
            -0.011191659606993198,
            0.03957618400454521,
            -0.009241652674973011,
            0.011589044705033302,
            0.007329623680561781,
            0.020225360989570618,
            -0.025644740089774132,
            0.0017710408428683877,
            -0.014468864537775517,
            0.017362315207719803,
            0.00621767807751894,
            -0.020838089287281036,
            0.0027584268245846033,
            0.0010149742010980844,
            -0.0005374304600991309,
            0.028610745444893837,
            -0.013784261420369148,
            -0.012733076699078083,
            0.027794772759079933,
            0.02063005603849888,
            -0.024208322167396545,
            0.015876352787017822,
            0.032354455441236496,
            2.9938495572423562e-05,
            -0.01482788659632206,
            -0.01922363042831421,
            0.019599776715040207,
            0.01109028048813343,
            -0.01959637925028801,
            -0.007543344981968403,
            -0.0031957575120031834,
            -0.0025580076035112143,
            0.016941716894507408,
            0.02212264947593212,
            -0.0017590643838047981,
            0.024340221658349037,
            -0.016561169177293777,
            -0.03232802450656891,
            0.025372179225087166,
            -0.018809642642736435,
            0.026955299079418182,
            -0.0025156692136079073,
            0.012882526032626629,
            -0.019667821004986763,
            0.04887422174215317,
            -0.030956007540225983,
            0.0008665568893775344,
            0.009961273521184921,
            0.014026977121829987,
            -0.0027076692786067724,
            -0.03487015888094902,
            0.012465832754969597,
            0.01328721921890974,
            0.02235100418329239,
            0.00365675101056695,
            0.017900977283716202,
            0.00038416197639890015,
            0.004152954556047916,
            0.0024466796312481165,
            -0.016819071024656296,
            -0.022005341947078705,
            0.04065956547856331,
            0.0252612866461277,
            -0.003509561298415065,
            0.02703649364411831,
            0.026712380349636078,
            0.022042451426386833,
            0.03024677000939846,
            -0.0004265658790245652,
            -0.006815922446548939,
            0.0340169295668602,
            -0.006683609914034605,
            -0.004635857418179512,
            0.03180322423577309,
            0.00702125858515501,
            -0.002774196909740567,
            -0.004863930866122246,
            -0.0013998919166624546,
            0.008051597513258457,
            0.0006828741170465946,
            -0.022619102150201797,
            0.007715658284723759,
            -0.02099810540676117,
            -0.004958053585141897,
            -0.010735707357525826,
            0.021850494667887688,
            0.013345006853342056,
            0.00010627514711814001,
            0.003013035748153925,
            0.029610108584165573,
            -0.022642532363533974,
            0.002625248860567808,
            0.011183190159499645,
            0.02992386184632778,
            0.0014277435839176178,
            0.021092457696795464,
            0.008691019378602505,
            0.01967496983706951,
            -0.007311082445085049,
            -0.0009704031981527805,
            -0.01095147430896759,
            0.028210695832967758,
            -0.030139196664094925,
            -0.01574666053056717,
            0.0003847766784019768,
            0.02774811163544655,
            -0.010929316282272339,
            -0.008334511891007423,
            0.001949074910953641,
            -0.030582519248127937,
            -0.005088537931442261,
            0.003325195051729679,
            -0.026838798075914383,
            -0.015390666201710701,
            -0.0005889866733923554,
            -0.006084857974201441,
            0.01854795031249523,
            -0.009855655953288078,
            -0.014573678374290466,
            0.015382478944957256,
            0.02863333374261856,
            -0.017446255311369896,
            0.019649479538202286,
            -0.01622122898697853,
            0.003558238735422492,
            0.019472917541861534,
            0.016794994473457336,
            -0.0006330928299576044,
            0.003567257896065712,
            0.021685315296053886,
            -0.022355375811457634,
            -0.01734413206577301,
            0.03165087476372719,
            0.014734869822859764,
            -0.03195212781429291,
            0.004958294332027435,
            0.01572825014591217,
            -0.03327397629618645,
            -0.004569247830659151,
            0.032627325505018234,
            0.01049388200044632,
            0.03087707608938217,
            0.003404408460482955,
            -0.0006954342825338244,
            0.014645000919699669,
            -0.0006075396668165922,
            0.008824571035802364,
            0.0024809918832033873,
            0.02275031805038452,
            0.02144698239862919,
            -0.004665635991841555,
            0.03191188722848892,
            -0.0047589452005922794,
            -0.02380801923573017,
            0.039790716022253036,
            -0.012740736827254295,
            0.018243009224534035,
            0.012220635078847408,
            -0.03460225835442543,
            0.013199780136346817,
            -0.026688726618885994,
            0.023789269849658012,
            0.03565056622028351,
            0.03135388717055321,
            -0.024214686825871468,
            0.029812220484018326,
            0.007007241249084473,
            -0.01804742030799389,
            -0.005707684438675642,
            0.01980300433933735,
            0.02174065262079239,
            -0.0019363239407539368,
            -0.015290897339582443,
            0.004047153517603874,
            -0.019199496135115623,
            -0.0072878943756222725,
            -0.028160840272903442,
            -0.015689725056290627,
            -0.009328602813184261,
            -0.00844686571508646,
            -0.006616176106035709,
            -0.02987811528146267,
            0.014391327276825905,
            0.0022563033271580935,
            0.0016261786222457886,
            -0.016558097675442696,
            0.013154523447155952,
            -0.004286896903067827,
            0.01122509315609932,
            -0.02283520996570587,
            0.03204464912414551,
            0.018276270478963852,
            -0.010900733061134815,
            0.02697467990219593,
            0.00013884167128708214,
            0.014472496695816517,
            0.02600095234811306,
            0.0055703334510326385,
            -0.011697378940880299,
            0.018360957503318787,
            0.025130223482847214,
            0.009436474181711674,
            -0.025724448263645172,
            0.007024588994681835,
            0.02412579394876957,
            0.0008834642358124256,
            0.023213835433125496,
            0.022652197629213333,
            -0.01721268706023693,
            0.03435494378209114,
            -0.009638522751629353,
            0.010188735090196133,
            -0.002864192007109523,
            -0.026823116466403008,
            -0.004090882372111082,
            -0.018305432051420212,
            0.0068268259055912495,
            -0.008616156876087189,
            -0.009356683120131493,
            0.018326327204704285,
            -0.012378804385662079,
            0.0212897639721632,
            0.028766687959432602,
            0.017276577651500702,
            0.0324857123196125,
            0.027935849502682686,
            0.008973794989287853,
            0.02407476119697094,
            0.013039500452578068,
            -0.025690078735351562,
            -0.0061354502104222775,
            0.03536918759346008,
            0.0195845365524292,
            -0.004285500384867191,
            -0.03377363830804825,
            0.006855320185422897,
            -0.01262728963047266,
            0.0016125815454870462,
            0.028106248006224632,
            0.009212811477482319,
            0.011164549738168716,
            -0.015382899902760983,
            0.00031180359655991197,
            0.007020140998065472,
            0.008643794804811478,
            0.007609818130731583,
            -0.01042917463928461,
            0.020189646631479263,
            -0.009152183309197426,
            -0.007445261813700199,
            -0.025769058614969254,
            0.0074280137196183205,
            -0.0009408868500031531,
            -0.02723027765750885,
            0.008722858503460884,
            -0.02467021904885769,
            -0.022583365440368652,
            -0.014745605178177357,
            -0.01684286631643772,
            0.011796976439654827,
            -0.0013143188552930951,
            -0.010976300574839115,
            0.01024570595473051,
            -0.010713961906731129,
            0.0210633035749197,
            0.010739540681242943,
            0.0013830491807311773,
            0.013364601880311966,
            -0.01279358845204115,
            -0.019260600209236145,
            0.006899018306285143,
            0.016627410426735878,
            0.01859522983431816,
            0.006064269691705704,
            -0.006853473372757435,
            0.008261379785835743,
            0.02312859706580639,
            0.0007789319497533143,
            -0.03911440819501877
        ],
        [
            -0.024385206401348114,
            -0.009038211777806282,
            0.013639405369758606,
            -0.008577574975788593,
            -0.002762150252237916,
            0.0068645537830889225,
            0.010588599368929863,
            0.02666635997593403,
            0.00048330065328627825,
            -0.01134276483207941,
            -0.04812981188297272,
            -0.006816674489527941,
            0.003871270688250661,
            -0.00793959479779005,
            -0.04867231473326683,
            -0.012834572233259678,
            -0.014109193347394466,
            0.0070076677948236465,
            0.02087230794131756,
            -0.0227447971701622,
            -0.02717253565788269,
            0.009614403359591961,
            0.021819815039634705,
            -0.0257103331387043,
            0.0017530415207147598,
            -0.012786652892827988,
            0.015197171829640865,
            -0.0318019837141037,
            0.007029904052615166,
            0.026946384459733963,
            0.045046232640743256,
            -0.012723690830171108,
            0.004059624392539263,
            -0.0076370746828615665,
            -0.0006682100356556475,
            0.01958182081580162,
            0.023288484662771225,
            0.022291529923677444,
            -0.007354153320193291,
            -0.015537532046437263,
            -0.005623443517833948,
            0.0066456543281674385,
            0.0015562459593638778,
            -0.009085108526051044,
            0.049325596541166306,
            -0.0025206045247614384,
            -0.009860717691481113,
            -0.012373586185276508,
            -0.0006945280474610627,
            0.0414661206305027,
            -0.03290232643485069,
            0.005272200331091881,
            0.01317604910582304,
            0.016795367002487183,
            0.01238772552460432,
            0.020417308434844017,
            -0.002090336522087455,
            0.0035040683578699827,
            0.006655344273895025,
            -0.02717687375843525,
            0.015421033836901188,
            0.010418836958706379,
            -0.0021982835605740547,
            0.023639140650629997,
            0.02406282350420952,
            0.007912060245871544,
            0.04729911684989929,
            0.024812616407871246,
            -0.02415553480386734,
            -0.0014813755406066775,
            0.024535443633794785,
            -0.04371555522084236,
            -0.03004034422338009,
            0.031549688428640366,
            0.008153093978762627,
            0.04686659947037697,
            -0.006201289128512144,
            -0.006470605731010437,
            -0.01122657023370266,
            0.006928782910108566,
            -0.03085106424987316,
            0.06795580685138702,
            0.021368060261011124,
            0.02270183153450489,
            0.00011493563943076879,
            -0.0013025234220549464,
            0.02954547293484211,
            0.017744440585374832,
            -0.018532972782850266,
            0.013536778278648853,
            -0.005613850895315409,
            0.023813951760530472,
            -0.02830435521900654,
            0.014393051154911518,
            -0.01058488804847002,
            -0.049890048801898956,
            -0.030183609575033188,
            0.0340837687253952,
            0.0165532436221838,
            0.008403059095144272,
            0.0061833206564188,
            0.05690483748912811,
            -0.0025578653439879417,
            -0.026318980380892754,
            -0.015375839546322823,
            0.0244439747184515,
            -0.02575474977493286,
            -0.027061425149440765,
            -0.01685509830713272,
            -0.017063362523913383,
            0.04052438959479332,
            0.03509506583213806,
            -0.005435796454548836,
            -0.028846917673945427,
            -0.04856576398015022,
            -0.01930725947022438,
            -0.021309975534677505,
            -0.02385963872075081,
            -0.033134575933218,
            0.01567058451473713,
            -0.015830283984541893,
            0.01814674213528633,
            -0.03954899311065674,
            -0.005142082925885916,
            -0.011591737158596516,
            0.03791743889451027,
            0.011538559570908546,
            -0.016156284138560295,
            0.006312502548098564,
            0.01230593305081129,
            -0.01823912374675274,
            -0.009452196769416332,
            0.015096457675099373,
            0.00788555946201086,
            -0.02570374496281147,
            0.02607436664402485,
            0.035542212426662445,
            -0.012071254663169384,
            -0.003868282772600651,
            -0.018073011189699173,
            -0.006505507044494152,
            -0.017544802278280258,
            0.0005980088608339429,
            0.006635481957346201,
            0.013615003786981106,
            0.035687610507011414,
            -0.006998091470450163,
            0.005454143974930048,
            0.00927545316517353,
            -0.029014792293310165,
            -0.014190048910677433,
            -0.02123292163014412,
            -0.009187139570713043,
            -0.04422580823302269,
            -0.008582189679145813,
            0.010478406213223934,
            -0.00945673044770956,
            -0.0077355047687888145,
            0.029971428215503693,
            0.010787530802190304,
            -0.015572864562273026,
            0.0037287953309714794,
            0.04101385921239853,
            -0.016366587951779366,
            0.004366773646324873,
            -0.030446216464042664,
            -0.018555955961346626,
            -0.02076525054872036,
            0.04779243469238281,
            -0.029179731383919716,
            0.022763865068554878,
            -0.012877770699560642,
            -0.011255430057644844,
            -0.04976324737071991,
            0.022788846865296364,
            -0.025382397696375847,
            -0.006948685739189386,
            -0.019010232761502266,
            0.007558266632258892,
            -0.01188056729733944,
            -0.004139351192861795,
            0.01384049654006958,
            0.007148727774620056,
            -0.003568182699382305,
            0.0026539727114140987,
            -0.021195081993937492,
            0.013172811828553677,
            -0.03616838902235031,
            0.003764091758057475,
            0.04443720728158951,
            0.04396592825651169,
            -0.0051797120831906796,
            0.006753440480679274,
            0.006453401874750853,
            -0.022829746827483177,
            0.025567345321178436,
            -0.019940121099352837,
            0.018738223239779472,
            0.024572649970650673,
            -0.008654906414449215,
            -0.01322113536298275,
            -0.027262968942523003,
            -0.04167383536696434,
            -0.035194456577301025,
            0.0072298250161111355,
            -0.01488383486866951,
            0.013559484854340553,
            0.004535145591944456,
            0.03030085191130638,
            0.0013080911012366414,
            0.0309763140976429,
            -0.021998515352606773,
            0.01657717302441597,
            0.010035455226898193,
            0.009973910637199879,
            -0.009204243309795856,
            3.8021075852157082e-06,
            -0.03156980127096176,
            0.034244563430547714,
            0.04818401113152504,
            -0.022254468873143196,
            0.014853567816317081,
            -0.045208901166915894,
            -0.029040785506367683,
            0.014684629626572132,
            0.05340324342250824,
            0.01764766126871109,
            0.033042654395103455,
            0.011002989485859871,
            -0.020917275920510292,
            -0.030504615977406502,
            -0.023971764370799065,
            -0.02993185631930828,
            -0.008292417041957378,
            0.011594288982450962,
            -0.02124646306037903,
            0.004873933270573616,
            -0.013391803950071335,
            0.0025179467629641294,
            0.034754760563373566,
            -0.007762157823890448,
            -0.0018881300929933786,
            0.025064604356884956,
            -0.007710732519626617,
            0.030528556555509567,
            0.0057267360389232635,
            -0.011277222074568272,
            -0.01875206269323826,
            -0.012251504696905613,
            0.007052915636450052,
            0.027159128338098526,
            0.010101174004375935,
            -0.00880503375083208,
            0.007092108950018883,
            -0.021520303562283516,
            0.017475366592407227,
            -0.004245960619300604,
            0.01041311677545309,
            0.001138031017035246,
            0.02294054627418518,
            0.030599365010857582,
            0.03105311095714569,
            -0.036798618733882904,
            -0.00679839588701725,
            0.00556879723444581,
            -0.01894785277545452,
            0.008550937287509441,
            -0.004694171249866486,
            0.04174957796931267,
            0.02576860599219799,
            0.01050341036170721,
            0.028007645159959793,
            0.013896092772483826,
            0.023966675624251366,
            -0.008717705495655537,
            -0.028969237580895424,
            0.005682836752384901,
            -0.011736229993402958,
            -0.009420734830200672,
            -0.018649348989129066,
            -0.015477198176085949,
            0.015417496673762798,
            0.004392709583044052,
            -0.007220815401524305,
            -0.01654091104865074,
            0.003201849292963743,
            -0.014193297363817692,
            -0.005645211320370436,
            -0.011457731947302818,
            0.013320371508598328,
            -0.023039955645799637,
            0.03778780624270439,
            0.03789900988340378,
            0.01652306690812111,
            0.019495805725455284,
            0.034121181815862656,
            0.03522130846977234,
            -0.021154822781682014,
            0.02123676799237728,
            0.02791742980480194,
            -0.010476700961589813,
            0.029695995151996613,
            -0.020276280120015144,
            -0.024740485474467278,
            0.0005034805508330464,
            -0.012360556982457638,
            0.04341033473610878,
            -0.04231613874435425,
            0.03783726319670677,
            -0.016478635370731354,
            -0.022031130269169807,
            -0.023947283625602722,
            0.005552635993808508,
            0.025532081723213196,
            0.025237413123250008,
            0.05079574137926102,
            -0.007665092591196299,
            -0.00945148803293705,
            0.029631104320287704,
            0.024816477671265602,
            0.030276907607913017,
            0.02704956941306591,
            -0.028293058276176453,
            0.04516224190592766,
            -0.04231532663106918,
            -0.016054203733801842,
            -0.024165788665413857,
            0.035778019577264786,
            -0.017015501856803894,
            -0.005164542235434055,
            -0.01061767153441906,
            -0.011280802078545094,
            0.00939815491437912,
            -0.013515227474272251,
            -0.006169431377202272,
            0.028933115303516388,
            0.010695735923945904,
            0.019824432209134102,
            0.016971498727798462,
            0.04951535537838936,
            -0.02632950060069561,
            0.009060841053724289,
            -0.009840114042162895,
            -0.020514482632279396,
            0.01434890367090702,
            0.02948896959424019,
            0.010572569444775581,
            0.009747201576828957,
            -0.003440176136791706,
            0.013585537672042847,
            -0.03885257616639137,
            -0.012691745534539223,
            0.011494659818708897,
            0.0069611226208508015,
            0.046414099633693695,
            -0.025171440094709396,
            -0.007313287816941738,
            0.02404039166867733,
            0.01378780696541071,
            -0.016587290912866592,
            -0.030290866270661354,
            -0.021696390584111214,
            0.007563252467662096,
            0.027856402099132538,
            -0.0003842312144115567,
            0.029479892924427986,
            -0.00034012433025054634,
            -0.0073079681023955345,
            -0.0003415607789065689,
            0.00412912480533123,
            -0.047399427741765976,
            -0.02521388605237007,
            0.009431096725165844,
            0.029645802453160286,
            -0.004089617170393467,
            -0.018550973385572433,
            0.007838349789381027,
            0.006506034638732672,
            0.009294004179537296,
            0.006345133297145367,
            -0.022365806624293327,
            0.0014142572181299329,
            0.033345650881528854,
            0.010166451334953308,
            0.01336871087551117,
            0.0595131516456604,
            0.033530570566654205,
            -8.226228965213522e-05,
            -0.003010164014995098,
            -0.029302744194865227,
            0.011856324039399624,
            0.0327894389629364,
            0.011836490593850613,
            -0.024601250886917114,
            0.010441893711686134,
            0.02859797328710556,
            0.02918069250881672,
            0.034993477165699005,
            0.04337740316987038,
            0.060485661029815674,
            -0.008687558583915234,
            0.032394129782915115,
            -0.01840503141283989,
            -0.003789858426898718,
            -0.031048141419887543,
            0.002645379863679409,
            0.012651780620217323,
            0.008139395155012608,
            0.018112769350409508,
            0.03893272206187248,
            -0.005370466969907284,
            -0.025883357971906662,
            0.022078031674027443,
            -0.0049806199967861176,
            -0.01480115856975317,
            -0.025936877354979515,
            0.030774768441915512,
            0.031648483127355576,
            -0.0586274228990078,
            -0.02305246703326702,
            0.03788803517818451,
            0.026538312435150146,
            0.04150855541229248,
            -0.02982773631811142,
            -0.005853538867086172,
            -0.04557163640856743,
            -0.021821925416588783,
            0.03478274494409561,
            -0.021514706313610077,
            0.025005433708429337,
            0.052548766136169434,
            -0.021106120198965073,
            -0.03785631060600281,
            0.003519392805173993,
            -0.021147873252630234,
            0.002661046339198947,
            0.011437620036303997,
            -0.021816501393914223,
            -0.001389081939123571,
            -0.014843074604868889,
            -0.04228801652789116,
            -0.037591736763715744,
            -0.0030288214329630136,
            0.025107497349381447,
            -0.02583797089755535,
            -0.02359769120812416,
            -0.00023352308198809624,
            0.051667824387550354,
            0.012370152398943901,
            0.033900223672389984,
            -0.01024271547794342,
            -0.037725064903497696,
            -0.02381090261042118,
            -0.0013851211406290531,
            0.03830242156982422,
            -0.016258999705314636,
            0.02080196514725685,
            0.015609812922775745,
            -0.0198829248547554,
            0.035799503326416016,
            0.02133525162935257,
            0.006348969880491495,
            -0.011455593630671501,
            0.027690669521689415,
            0.018967000767588615,
            0.03356822952628136,
            -0.011991661041975021,
            0.022758854553103447,
            -0.013027714565396309,
            -0.029284730553627014,
            -0.027654947713017464,
            0.025911692529916763,
            -0.021811943501234055,
            -0.0013726585311815143,
            -0.0030014102812856436,
            0.01620728336274624,
            -0.015945447608828545,
            0.007328207138925791,
            0.035313814878463745,
            0.011764475144445896,
            -0.014773532748222351,
            0.01022822130471468,
            -0.01927298679947853,
            -0.03376944735646248,
            0.051129989326000214,
            0.016190718859434128,
            0.01027774065732956,
            0.012352611869573593,
            0.031328439712524414,
            0.0454128161072731,
            -0.012377462349832058,
            0.02950788475573063,
            0.02378261834383011,
            0.03584018349647522,
            0.010582123883068562,
            -0.026510819792747498,
            0.02457069605588913,
            -0.010183826088905334,
            0.02164939045906067,
            0.009432193823158741,
            -0.0038590149488300085,
            0.011869889684021473,
            0.04730115458369255,
            0.03975425660610199,
            -0.024707669392228127,
            0.03282281011343002,
            -0.03032303974032402,
            0.007595201954245567,
            -0.017735188826918602,
            0.02945779263973236,
            0.012309796176850796,
            -0.014473075978457928,
            -0.008428758010268211,
            -0.017812790349125862,
            0.013665895909070969,
            -0.006113858427852392,
            0.0025064214132726192,
            0.008787354454398155,
            0.0040731970220804214,
            -0.007691728882491589,
            -0.013158522546291351,
            0.008359754458069801,
            0.03297455236315727,
            0.0014746652450412512,
            0.02654055505990982,
            0.00645656231790781,
            0.030453041195869446,
            0.0015217296313494444,
            0.019066158682107925,
            0.018296899273991585,
            0.021697871387004852,
            -0.006429691333323717,
            0.03742160648107529,
            -0.01265257690101862,
            -0.01944517157971859,
            0.018578793853521347,
            -0.013671006076037884,
            0.016681768000125885,
            -0.006662050262093544,
            0.02893417701125145,
            0.006238700821995735,
            0.029985930770635605,
            0.02255994640290737,
            0.03512582182884216,
            -0.032945163547992706,
            0.0026243894826620817,
            -0.005652467254549265,
            0.00868070125579834,
            0.0044031329452991486,
            -0.043327201157808304,
            -0.023473775014281273,
            -0.02346767485141754,
            0.01519010215997696,
            -0.01664241962134838,
            0.03063882142305374,
            -0.01972084492444992,
            0.012251469306647778,
            0.006181302480399609,
            -0.018291207030415535,
            -0.027639521285891533,
            -0.009967120364308357,
            -0.01812504604458809,
            -0.03260263055562973,
            0.0026821400970220566,
            0.024265017360448837,
            0.058809760957956314,
            -0.06145733967423439,
            -0.018261952325701714,
            -0.008335120044648647,
            -0.03438393399119377,
            -0.03127427026629448,
            -0.009080118499696255,
            0.031212011352181435,
            -0.0021206350065767765,
            -0.02144036442041397,
            0.040440112352371216,
            0.022338060662150383,
            -0.013797649182379246,
            0.004617042373865843,
            0.01943744719028473,
            -0.009748467244207859,
            0.037466295063495636,
            0.005494382232427597,
            -0.004546584561467171,
            -0.010985874570906162,
            0.07384440302848816,
            -0.0035740199964493513,
            -0.003176524769514799,
            0.026321936398744583,
            -0.035094328224658966,
            0.0010325228795409203,
            0.017587577924132347,
            0.030235368758440018,
            -0.008603781461715698,
            -0.01773674413561821,
            0.01063026674091816,
            0.0375177375972271,
            0.0016115558100864291,
            0.027687517926096916,
            -0.021062258630990982,
            -0.0436413437128067,
            0.02436716854572296,
            -0.0015428479528054595,
            0.02571391500532627,
            0.01303534209728241,
            0.030412819236516953,
            -0.03911476954817772,
            0.012948714196681976,
            -0.0206909142434597,
            0.056352049112319946,
            -0.010452225804328918,
            -0.027026789262890816,
            -0.0035574643407016993,
            -0.00956901628524065,
            0.025301292538642883,
            0.04418326914310455,
            -0.03662735968828201,
            -0.04007429629564285,
            -0.008562721312046051,
            -0.0017315789591521025,
            -0.022538267076015472,
            0.037412356585264206,
            -0.02271217852830887,
            0.03245727717876434,
            0.011491185985505581,
            -0.0017540187109261751,
            -0.013498920015990734,
            -0.009399356320500374,
            0.040405791252851486,
            0.017254047095775604,
            -0.02597413957118988,
            0.006005976349115372,
            0.0022976784966886044,
            -0.0271892249584198,
            0.022821718826889992,
            0.031320881098508835,
            0.03498781844973564,
            0.03439311310648918,
            0.0068651363253593445,
            0.007097562309354544,
            0.05000029876828194,
            -0.012854830361902714,
            -0.04754408076405525,
            -0.032332733273506165,
            -0.03480839356780052,
            0.028685595840215683,
            0.028902748599648476,
            -0.003958198707550764,
            -0.022756315767765045,
            0.026525653898715973,
            0.010398901998996735,
            0.03175001218914986,
            0.03250323608517647,
            -0.03104942850768566,
            0.011715184897184372,
            -0.021144066005945206,
            -0.06828723847866058,
            -0.009106753394007683,
            0.012879675254225731,
            -0.03734423592686653,
            -0.003771638497710228,
            -6.799624679842964e-05,
            -0.03815668821334839,
            -0.009124421514570713,
            -0.019622802734375,
            0.027634533122181892,
            0.003458230523392558,
            0.013798205181956291,
            0.027823472395539284,
            0.006880478467792273,
            0.04416012763977051,
            -0.019448695704340935,
            0.007274725940078497,
            -0.011127675883471966,
            -0.0022450441028922796,
            -0.010614887811243534,
            0.01420735102146864,
            0.017653508111834526,
            0.026715576648712158,
            -0.01027833390980959,
            0.038717616349458694,
            0.002976792398840189,
            0.006180375814437866,
            0.019713718444108963,
            -0.009770085103809834,
            0.005102506838738918,
            -0.03597782552242279,
            0.03865061327815056,
            -0.0007879480835981667,
            -0.02065197005867958,
            -0.018765684217214584,
            -0.006298566237092018,
            0.018794849514961243,
            -0.019916310906410217,
            0.0059066046960651875,
            0.020162785425782204,
            -0.021814832463860512,
            -0.006347340065985918,
            0.0131610706448555,
            -0.004206118173897266,
            -0.01869018003344536,
            0.017385711893439293,
            0.008643237873911858,
            -0.032115064561367035,
            0.018158117309212685,
            -0.012050322256982327,
            -0.024703364819288254,
            -0.007245404180139303,
            -0.005880886223167181,
            -0.03257826343178749,
            0.013212237507104874,
            0.02069125883281231,
            0.05563025176525116,
            0.01669510267674923,
            0.013947218656539917,
            0.01281306054443121,
            0.03860083222389221,
            -0.020265335217118263,
            -0.025692345574498177,
            -0.009544549509882927,
            0.01273492444306612,
            0.018020711839199066,
            0.027401527389883995,
            -0.05323614180088043,
            -0.021849051117897034,
            -0.0030158795416355133,
            -0.005818755831569433,
            -0.029278820380568504,
            0.016082575544714928,
            0.0034142774529755116,
            0.001639452762901783,
            0.026251576840877533,
            -0.017884982749819756,
            0.01534423977136612,
            0.004010627046227455,
            0.0035126188304275274,
            0.026072757318615913,
            0.0038842700887471437,
            0.01542753353714943,
            -0.019699765369296074,
            0.015909545123577118,
            -0.04698831960558891,
            -0.04933478310704231,
            -0.029291706159710884,
            -0.009671253152191639,
            0.047888100147247314,
            0.030116315931081772,
            -0.0213907603174448,
            -0.0030811247415840626,
            0.026255780830979347,
            -0.019861623644828796,
            0.022418510168790817,
            0.010115600191056728,
            -0.025224408134818077,
            -0.024747727438807487,
            0.028075015172362328,
            0.016440080478787422,
            0.06816266477108002,
            0.008157754316926003,
            0.02649611607193947,
            0.018799511715769768,
            -0.054453179240226746,
            0.010490112006664276,
            0.025990478694438934,
            -0.04725983738899231,
            -0.03354427590966225,
            -0.039036549627780914,
            0.028007635846734047,
            -0.025074731558561325,
            -0.009272491559386253,
            -0.014393703080713749,
            0.03527315333485603,
            0.05102997645735741,
            -0.050675902515649796,
            -0.031190605834126472,
            -0.001277437317185104,
            0.03296244516968727,
            -0.05591832473874092,
            -0.023000599816441536,
            -0.02308231219649315,
            0.001181463012471795,
            -0.0025924737565219402,
            -0.015903694555163383,
            -0.005967737175524235,
            0.03154851868748665,
            0.023727230727672577,
            0.005596562754362822,
            -0.012685290537774563,
            0.04301108792424202,
            -0.012112604454159737,
            -0.008902858011424541,
            0.037385277450084686,
            0.02004982903599739,
            0.023357924073934555,
            0.0048327273689210415,
            0.015128916129469872,
            0.024296950548887253,
            -0.04376300796866417,
            -0.01058543287217617,
            0.0020493818446993828,
            0.03240814805030823,
            -0.007226638961583376,
            -0.0066603804007172585,
            -0.02352486178278923,
            0.007076980546116829,
            -0.013759675435721874,
            -0.004819471854716539,
            0.011810840107500553,
            0.028104722499847412,
            0.02899974398314953,
            0.004199347924441099,
            0.015718460083007812,
            0.04002915695309639,
            0.017521072179079056,
            0.03487478569149971,
            0.02705240808427334,
            -0.02958153933286667,
            -0.029590321704745293,
            0.013687594793736935,
            -0.003316334681585431,
            0.013664335943758488,
            -0.03458152338862419,
            0.036268290132284164,
            0.032149337232112885,
            -0.06703199446201324,
            -0.054153844714164734,
            0.02512369304895401,
            -0.0035312925465404987,
            0.019062230363488197,
            0.004492420237511396,
            -0.006655200384557247,
            -0.003882927820086479,
            -0.037642497569322586,
            -0.03117331489920616,
            -0.02139878086745739,
            -0.027713917195796967,
            0.0036354069598019123,
            -5.3970310545992106e-05,
            -0.02141210250556469,
            -0.02523145265877247,
            -0.013031183741986752,
            -0.08630897849798203,
            0.025685900822281837,
            0.02816922962665558,
            -0.005552337039262056,
            -0.008250069804489613,
            0.010655016638338566,
            -0.014796370640397072,
            0.03848179057240486,
            -0.01896471157670021,
            0.010109880939126015,
            -0.0071087987162172794,
            -0.0243536327034235,
            0.006092809606343508,
            0.020862413570284843,
            -0.03452311456203461,
            0.032589759677648544,
            0.024702535942196846,
            -0.0069023738615214825,
            -0.024830158799886703,
            -0.014582596719264984,
            0.024564502760767937,
            -0.04141015559434891,
            0.011872034519910812,
            -0.00721815787255764,
            0.0013330545043572783,
            -0.03227406367659569,
            0.03319558873772621,
            -0.022306211292743683,
            -0.024264086037874222,
            -0.012906556949019432,
            -0.003598777111619711,
            0.005364852957427502,
            0.03791886195540428,
            -0.01295892708003521,
            -0.021186141297221184,
            0.017973529174923897,
            -0.10260383039712906,
            0.0029508250299841166,
            0.017413020133972168,
            -0.04174814745783806,
            -0.06611457467079163,
            0.012121970765292645,
            -0.0042767636477947235,
            -0.021670067682862282,
            0.03117545321583748,
            -0.005758205894380808,
            0.006976190023124218,
            0.024737833067774773,
            0.016197778284549713,
            0.018434524536132812,
            -0.010395456105470657,
            0.012670361436903477,
            -0.0064317164942622185,
            0.05258038640022278,
            -0.004454128909856081,
            -0.020952703431248665,
            0.010802766308188438,
            -0.03554629907011986,
            -0.035001035779714584,
            -0.004058267921209335,
            0.029638605192303658,
            -0.02381780557334423,
            0.01058613508939743,
            0.02711438573896885,
            -0.04376377910375595,
            0.015030286274850368,
            -0.012770157307386398,
            -0.026588356122374535,
            -0.020751381292939186,
            0.03410951793193817,
            -0.04297519475221634,
            -0.014507218264043331,
            0.019458385184407234,
            -0.004580516833811998,
            0.040346670895814896,
            -0.0027614280115813017,
            0.04097030684351921,
            -0.014169546775519848,
            -0.03266793116927147,
            -0.022663898766040802,
            0.02568121999502182,
            -0.001281418139114976,
            0.014045745134353638,
            0.019470108672976494,
            0.01377087738364935,
            0.013523970730602741,
            0.005378487054258585,
            -0.0382331945002079,
            0.01993429847061634,
            -0.011593234725296497,
            0.003895069472491741,
            -0.013168941251933575,
            0.02392587810754776,
            0.008145414292812347,
            0.01770016923546791,
            -0.017307177186012268,
            -0.018970221281051636,
            -0.0059370556846261024,
            -0.03617306798696518,
            -0.02512730285525322,
            0.02502886764705181,
            -0.032393623143434525,
            0.01260390505194664,
            0.011508521623909473,
            -0.013160220347344875,
            -0.01679595559835434,
            0.023425111547112465,
            0.018079375848174095,
            0.02328374981880188,
            -0.04629984125494957,
            -0.021154165267944336,
            0.02271917462348938,
            -0.013936038129031658,
            -0.02760804072022438,
            0.034466564655303955,
            0.008742081932723522,
            -0.023203596472740173,
            -0.018853802233934402,
            -0.0019017050508409739,
            0.015196278691291809,
            0.03020418807864189,
            -0.06635584682226181,
            0.00011644459300441667,
            -0.017771655693650246,
            -0.03182195872068405,
            0.03326215595006943,
            -0.0018589214887470007,
            -0.06322275102138519,
            0.03424334526062012,
            0.04054157808423042,
            -0.016942068934440613,
            -0.008288263343274593,
            -0.000873986748047173,
            0.021053437143564224,
            0.002761607989668846,
            0.029731228947639465,
            0.0037057283334434032,
            -0.004721446428447962,
            0.012823394499719143,
            0.007072621025145054,
            -0.04412327706813812,
            -0.06987635791301727,
            0.017127051949501038,
            -0.023124707862734795,
            -0.01581750437617302,
            0.03953592851758003,
            0.011083325371146202,
            -2.340736500627827e-05,
            -0.004130483139306307,
            -0.02396836131811142,
            0.009715613909065723,
            0.013867378234863281,
            0.0018877849215641618,
            -0.05128687992691994,
            -0.04122930020093918,
            0.03324867784976959,
            0.0012312228791415691,
            0.008852491155266762,
            -0.008185986429452896,
            0.0036537572741508484,
            -0.006910855416208506,
            -0.011871828697621822,
            -0.02656828612089157,
            0.016026994213461876,
            -0.006902326829731464,
            -0.0002567074552644044,
            0.016473520547151566,
            0.012071183882653713,
            0.0011943726567551494,
            -0.042390499264001846,
            0.008231507614254951,
            -0.0011437807697802782,
            -0.003995767794549465,
            0.03310496360063553,
            0.026687175035476685,
            -0.004186950158327818,
            0.00692342733964324,
            0.0004652894858736545,
            -0.0026969928294420242,
            0.008381658233702183,
            0.03884119540452957,
            -0.002177022397518158,
            0.018880022689700127
        ],
        [
            0.006635244470089674,
            0.010436974465847015,
            0.03673423081636429,
            0.002393149770796299,
            -0.019788064062595367,
            -0.04412222281098366,
            0.004051831550896168,
            0.0031438807491213083,
            0.0015012177173048258,
            -0.02067619003355503,
            0.023373153060674667,
            -0.0014909348683431745,
            0.007347006816416979,
            0.011615012772381306,
            0.015730418264865875,
            -0.038860637694597244,
            -0.015328328125178814,
            0.00765906972810626,
            -0.02149980142712593,
            0.01656205579638481,
            0.039964377880096436,
            0.02612987533211708,
            0.04045746847987175,
            0.019350571557879448,
            0.005090261809527874,
            -0.01337284967303276,
            0.019850460812449455,
            -0.023585835471749306,
            0.017176320776343346,
            -0.018993938341736794,
            -0.022531356662511826,
            -0.005803367123007774,
            -0.00954862218350172,
            0.02224898897111416,
            0.01042203139513731,
            0.02547706477344036,
            0.01761758141219616,
            0.02977529540657997,
            0.014421910047531128,
            -0.024191461503505707,
            0.008197187446057796,
            -0.015500965528190136,
            0.03592566028237343,
            -0.011533604934811592,
            -0.004791788291186094,
            0.02289428748190403,
            0.013199691660702229,
            0.030097704380750656,
            -0.004608820658177137,
            0.030224837362766266,
            -0.0008451899630017579,
            -0.009395855478942394,
            -0.004987240768969059,
            0.008464066311717033,
            -0.016687659546732903,
            0.02877667173743248,
            0.0048784068785607815,
            -0.008505399338901043,
            -0.007515982259064913,
            0.025166278705000877,
            0.017036784440279007,
            -0.038054510951042175,
            0.019849952310323715,
            -0.010115745477378368,
            -0.0029982696287333965,
            -0.006138254888355732,
            -0.0035404879599809647,
            -0.015505512244999409,
            0.022537723183631897,
            -0.01615133136510849,
            -0.025418313220143318,
            -0.005251456517726183,
            0.02523082122206688,
            -0.028896508738398552,
            0.005357055924832821,
            -0.0126408152282238,
            0.012613598257303238,
            -0.02880672551691532,
            0.07460860908031464,
            -0.01942821964621544,
            0.021212253719568253,
            -0.02341415546834469,
            -0.026098323985934258,
            -0.015313309617340565,
            0.005300221033394337,
            0.038260433822870255,
            0.01998285949230194,
            0.042482562363147736,
            -0.01686195656657219,
            0.008689920417964458,
            0.007712429855018854,
            0.00199197418987751,
            0.026465483009815216,
            0.042348843067884445,
            -0.010039348155260086,
            0.04412349686026573,
            0.029471546411514282,
            -0.013500264845788479,
            0.07315421104431152,
            -0.007719344925135374,
            -0.04277120530605316,
            -0.006883921101689339,
            -0.03228897601366043,
            0.023023122921586037,
            -0.006303504575043917,
            0.005826301407068968,
            0.019559554755687714,
            0.016383063048124313,
            0.051711685955524445,
            0.007290892768651247,
            0.0020916240755468607,
            -0.027245834469795227,
            0.025002766400575638,
            0.03284149244427681,
            -0.039827823638916016,
            0.0005682327901013196,
            0.01070594135671854,
            -0.0034585644025355577,
            0.018493130803108215,
            0.011226358823478222,
            0.007663473021239042,
            0.0015620863996446133,
            -0.04997432604432106,
            0.027024613693356514,
            -0.0034389717038720846,
            -0.01673021726310253,
            -0.002621142193675041,
            -0.004363767337054014,
            -0.01930762268602848,
            -0.027783678844571114,
            0.0016724546439945698,
            0.03256187215447426,
            0.03671121969819069,
            0.04351785406470299,
            0.02724107913672924,
            0.0030402604024857283,
            0.03865521401166916,
            -0.01631171815097332,
            0.03166002780199051,
            0.0028077238239347935,
            0.011174585670232773,
            3.7490757677005604e-05,
            0.021504579111933708,
            0.004891030956059694,
            0.020645033568143845,
            0.009827698580920696,
            -0.01157050859183073,
            0.008019581437110901,
            0.020488016307353973,
            -0.013607512228190899,
            0.002440346172079444,
            -0.047174010425806046,
            -0.04219814017415047,
            -0.012636776082217693,
            -0.012439046986401081,
            0.019832706078886986,
            0.009506388567388058,
            0.012785715982317924,
            0.02687888592481613,
            -0.0035228526685386896,
            0.010417124256491661,
            0.031073076650500298,
            -0.008111398667097092,
            -0.009431594982743263,
            0.005173904821276665,
            -0.0015481217997148633,
            0.019010188058018684,
            -0.0011479973327368498,
            0.039479635655879974,
            -0.024262553080916405,
            0.009860211052000523,
            0.005146126728504896,
            0.020743701606988907,
            0.022788958624005318,
            0.033421892672777176,
            -0.006312160287052393,
            -0.001302841934375465,
            -0.03120841272175312,
            0.026837319135665894,
            -0.006341665051877499,
            -0.007054077927023172,
            0.0022326426114887,
            -0.00545947439968586,
            0.0003605098172556609,
            -0.002747107995674014,
            0.008486502803862095,
            -0.025384876877069473,
            -0.014182317070662975,
            0.004277671687304974,
            -0.02727474272251129,
            0.007006123196333647,
            0.01794271729886532,
            -0.03297695145010948,
            -0.02023680880665779,
            -0.03368096426129341,
            0.01609928160905838,
            -0.007170561701059341,
            -0.0360594280064106,
            -0.008653035387396812,
            -0.0030730769503861666,
            0.005600747652351856,
            0.03478948399424553,
            -0.01015312597155571,
            -0.01736295036971569,
            0.07107078284025192,
            -0.023341801017522812,
            0.05800265073776245,
            0.04759170114994049,
            -0.03951028734445572,
            -0.021586844697594643,
            -0.029017502442002296,
            0.015863651409745216,
            0.044816091656684875,
            -0.026205267757177353,
            -0.00036141788586974144,
            0.0056732469238340855,
            -0.01576983369886875,
            -0.0064033567905426025,
            -0.012822980061173439,
            -0.024325283244252205,
            0.007931740954518318,
            -0.0066063180565834045,
            0.014589344151318073,
            -0.0148582449182868,
            0.02931436523795128,
            -0.03914037346839905,
            0.02294270321726799,
            0.02448933944106102,
            0.009520746767520905,
            0.01797526702284813,
            0.02342248521745205,
            0.003662940114736557,
            -0.007603228557854891,
            0.009928606450557709,
            -0.005468506366014481,
            0.014903942123055458,
            0.027711300179362297,
            -0.01011574175208807,
            -0.019500521942973137,
            -0.04636331647634506,
            0.0060265567153692245,
            0.0010341325541958213,
            -0.031231045722961426,
            -0.007103641051799059,
            0.02635946311056614,
            0.004643281921744347,
            -0.008547991514205933,
            0.006340144667774439,
            0.024486808106303215,
            0.008150409907102585,
            0.001809005276300013,
            -0.03753983601927757,
            0.051081810146570206,
            0.019260864704847336,
            0.027558892965316772,
            0.006177168805152178,
            -0.021649174392223358,
            0.02652985230088234,
            -0.008613093756139278,
            -0.010357577353715897,
            0.03231181204319,
            -0.0040010446682572365,
            0.028971076011657715,
            0.01953832060098648,
            -0.03153376653790474,
            -0.023615576326847076,
            -0.03803681209683418,
            -0.001175519311800599,
            0.013558762148022652,
            -0.0496675930917263,
            -0.03092929534614086,
            -0.013003416359424591,
            0.02015715464949608,
            -0.0032659247517585754,
            -0.012065623886883259,
            0.01867631822824478,
            -0.03172101452946663,
            0.01892547681927681,
            -0.014753256924450397,
            0.0357099212706089,
            -0.0010753581300377846,
            0.002410145243629813,
            -0.024617448449134827,
            -0.0031588717829436064,
            0.020065875723958015,
            -0.0051286667585372925,
            -0.013810024596750736,
            -0.01077303383499384,
            -0.0070703597739338875,
            -0.037040773779153824,
            0.010420653969049454,
            -0.01743827573955059,
            -0.02444450929760933,
            -0.03959624469280243,
            0.004639721009880304,
            0.013582305051386356,
            0.03008398413658142,
            -0.03221990540623665,
            0.025150209665298462,
            -0.031405311077833176,
            -0.029007110744714737,
            0.04120086878538132,
            0.008002479560673237,
            0.030437715351581573,
            0.016547061502933502,
            0.0038103966508060694,
            -0.010158097371459007,
            -0.0009877332486212254,
            0.012422123923897743,
            0.0063080149702727795,
            0.002550330013036728,
            0.007182944566011429,
            -0.021198594942688942,
            -0.02679145149886608,
            -0.00306349596939981,
            -0.0637611597776413,
            -0.007006281986832619,
            -0.006598407868295908,
            0.02002977579832077,
            -0.004138072486966848,
            -0.0035414467565715313,
            0.021975796669721603,
            0.013680057600140572,
            -0.017731880769133568,
            -0.005869755521416664,
            -0.015230393968522549,
            -0.0044414992444217205,
            0.018514610826969147,
            0.020144091919064522,
            -0.010966391302645206,
            0.030379630625247955,
            -0.006971852388232946,
            0.03285479545593262,
            0.009682342410087585,
            -0.015620742924511433,
            0.01083595771342516,
            0.01540163904428482,
            -0.006418966688215733,
            0.024174677208065987,
            0.02931400015950203,
            0.016534987837076187,
            0.018943510949611664,
            0.008377540856599808,
            0.010203225538134575,
            -0.027906572446227074,
            0.009367073886096478,
            -0.024572500959038734,
            0.02299540303647518,
            0.013622650876641273,
            0.022974353283643723,
            0.01504838839173317,
            -0.015283143147826195,
            -0.020608961582183838,
            0.016112782061100006,
            -0.012961830012500286,
            0.012285377830266953,
            -0.011273499578237534,
            -0.02544940449297428,
            -0.00024859068798832595,
            0.02141241542994976,
            -0.028845494613051414,
            0.033585917204618454,
            0.056934475898742676,
            0.003740333253517747,
            -0.010914991609752178,
            0.005565968342125416,
            0.02608431503176689,
            -0.01795564964413643,
            -0.030431805178523064,
            -0.004612805787473917,
            -0.048703309148550034,
            -0.005987431854009628,
            -0.03052927553653717,
            0.013653023168444633,
            -0.01269226148724556,
            -0.007277737371623516,
            -0.01959264650940895,
            0.04336976259946823,
            -0.009164237417280674,
            0.03339970111846924,
            -0.04850097373127937,
            0.0017918554367497563,
            -0.014112052507698536,
            -0.0006370048504322767,
            -0.02758278325200081,
            -0.008802494034171104,
            -0.029272809624671936,
            -0.03990936279296875,
            -0.012819142080843449,
            -0.02413010597229004,
            -8.898496889742091e-05,
            0.01277376338839531,
            -0.04605690762400627,
            0.030477847903966904,
            0.011866696178913116,
            0.007070848718285561,
            0.010190894827246666,
            -0.00809001736342907,
            0.01694505661725998,
            -0.0013780412264168262,
            -0.0009237243211828172,
            -0.0015696478076279163,
            -0.013511694967746735,
            -0.0005578603013418615,
            0.02713426761329174,
            -0.035999149084091187,
            0.009719755500555038,
            -0.021603887900710106,
            -0.005067803896963596,
            -0.02661183848977089,
            -0.00012343822163529694,
            -0.009438163600862026,
            -0.0007841386250220239,
            -0.0036198515444993973,
            0.010160284116864204,
            -0.007733020465821028,
            0.021187379956245422,
            -0.046901680529117584,
            0.0006847583572380245,
            -0.014882002025842667,
            -0.019124945625662804,
            -0.023685971274971962,
            0.028788257390260696,
            -0.001853182795457542,
            0.01983591541647911,
            -0.0006757439114153385,
            0.016799230128526688,
            0.031033417209982872,
            -0.026974396780133247,
            0.029819531366229057,
            0.020063970237970352,
            -0.01808024011552334,
            0.002304666442796588,
            0.008090282790362835,
            -0.02839767187833786,
            0.020580168813467026,
            0.01512716244906187,
            0.015349550172686577,
            -0.004077027086168528,
            -0.017956161871552467,
            0.023311322554945946,
            -0.0015060746809467673,
            -0.005640395451337099,
            0.016460757702589035,
            0.00023958463862072676,
            -0.015524085611104965,
            -0.028080012649297714,
            -0.008676390163600445,
            -0.009783366695046425,
            -0.031928129494190216,
            -0.011954349465668201,
            0.008531151339411736,
            0.0015282367821782827,
            0.020177075639367104,
            0.006031624507158995,
            0.029787743464112282,
            0.036494821310043335,
            0.012267153710126877,
            0.0035408956464380026,
            -0.011530726216733456,
            0.019804729148745537,
            -0.011631030589342117,
            -0.015028955414891243,
            0.014858339913189411,
            0.02724463678896427,
            0.00695758918300271,
            -0.009290747344493866,
            0.01902919076383114,
            0.01593596860766411,
            0.025180622935295105,
            -0.020678915083408356,
            0.03580194711685181,
            -0.01849709078669548,
            0.02610551379621029,
            -0.025127699598670006,
            0.01241537556052208,
            -0.03521624580025673,
            0.03725590929389,
            -0.041416432708501816,
            0.04441279545426369,
            0.01863282360136509,
            -0.0015016755787655711,
            0.01260549295693636,
            0.013010089285671711,
            -0.0050303698517382145,
            0.0002986136241815984,
            0.019980711862444878,
            0.0030603690538555384,
            -0.010762197896838188,
            -0.03813639283180237,
            -0.020509185269474983,
            0.02667335793375969,
            -0.026135394349694252,
            0.01784501224756241,
            0.03472265228629112,
            0.03706097602844238,
            0.0018699202919378877,
            -0.02856053039431572,
            0.010211235843598843,
            0.021742545068264008,
            -0.026600006967782974,
            -0.007051660213619471,
            0.029267411679029465,
            -0.062035445123910904,
            0.017853787168860435,
            0.017265725880861282,
            0.023696739226579666,
            0.0012918555876240134,
            -0.012568325735628605,
            -0.006204781588166952,
            -0.0021218464244157076,
            -0.04475961625576019,
            0.01661595329642296,
            0.024703243747353554,
            0.024317029863595963,
            0.021256009116768837,
            0.028382867574691772,
            -0.024724487215280533,
            0.021549714729189873,
            -0.007766332011669874,
            0.044733926653862,
            0.01778026483952999,
            0.01098257303237915,
            -0.01287088356912136,
            0.054933518171310425,
            0.02816518396139145,
            0.07339576631784439,
            -0.0015276257181540132,
            -0.034231483936309814,
            -0.02261682040989399,
            0.005716351792216301,
            -0.03924604132771492,
            -0.02613474428653717,
            -0.025442076846957207,
            0.01249872986227274,
            0.012251638807356358,
            0.015430152416229248,
            -0.018064089119434357,
            -0.01988232508301735,
            0.024824582040309906,
            -0.01808381825685501,
            0.03928888961672783,
            -0.02875012904405594,
            0.019297808408737183,
            0.01018395647406578,
            0.0033139584120362997,
            0.03204130008816719,
            -0.027256883680820465,
            -0.0040263826958835125,
            0.009684857912361622,
            -0.03693360090255737,
            -0.035081736743450165,
            0.015251296572387218,
            -0.021751368418335915,
            0.028007803484797478,
            0.010112601332366467,
            -0.022610483691096306,
            0.0053978548385202885,
            -0.006238589063286781,
            0.0029543701093643904,
            0.007073018699884415,
            -0.023812413215637207,
            0.006391454953700304,
            -0.012550987303256989,
            0.004471019376069307,
            0.0061032152734696865,
            -0.018438493832945824,
            0.0032361687626689672,
            -0.0083819180727005,
            0.03120119497179985,
            0.01831972412765026,
            0.00585991470143199,
            -0.0020679933950304985,
            0.016460375860333443,
            -0.03308361768722534,
            -0.017213264480233192,
            -0.013508697040379047,
            0.006176796276122332,
            0.013266311027109623,
            -0.0356016643345356,
            0.029351895675063133,
            0.026250042021274567,
            -0.01756884902715683,
            0.027931801974773407,
            -0.009192895144224167,
            -0.008005266077816486,
            -0.031854551285505295,
            0.031703539192676544,
            0.020811112597584724,
            0.009783647954463959,
            0.019721461459994316,
            0.02335834689438343,
            0.021063445135951042,
            0.028793100267648697,
            0.013346408493816853,
            -0.02155814692378044,
            0.0365353599190712,
            -0.01878935471177101,
            -0.01604776456952095,
            0.017038147896528244,
            -0.002755556022748351,
            -0.012827715836465359,
            0.03680334985256195,
            0.012647769413888454,
            -0.029028261080384254,
            -0.039484865963459015,
            0.031146112829446793,
            -0.01057070679962635,
            -0.028988102450966835,
            0.0012951715616509318,
            0.020581644028425217,
            -0.02876327931880951,
            0.009519428014755249,
            -0.029911698773503304,
            -0.0008515106746926904,
            0.046162914484739304,
            0.018233878538012505,
            -0.021060504019260406,
            -0.012586276978254318,
            0.018908753991127014,
            0.002325227949768305,
            -0.008451035246253014,
            -0.0055594295263290405,
            0.01021925825625658,
            -0.03616711124777794,
            -0.02227540872991085,
            -0.01084123831242323,
            0.005799869075417519,
            -0.010084490291774273,
            -0.010485597886145115,
            -0.015550889074802399,
            -0.028979910537600517,
            0.01254454255104065,
            0.043315496295690536,
            0.019410032778978348,
            0.0326804481446743,
            -0.03684254735708237,
            0.02566578797996044,
            -0.005561583675444126,
            -0.02578057162463665,
            0.016626492142677307,
            -0.0036992705427110195,
            0.015584709122776985,
            0.02808445133268833,
            -0.0009517132421024144,
            0.02319943532347679,
            -0.014515542425215244,
            -0.016522590070962906,
            0.03824349492788315,
            -0.013424473814666271,
            0.018764866515994072,
            -0.010870386846363544,
            0.013159197755157948,
            0.005086348857730627,
            0.026806429028511047,
            -0.0005177795537747443,
            0.008099673315882683,
            -0.026907943189144135,
            -0.0011608665809035301,
            -0.0393361821770668,
            0.011576703749597073,
            0.039407819509506226,
            0.022042397409677505,
            0.010049696080386639,
            0.01706758141517639,
            0.006454067304730415,
            0.027796916663646698,
            -0.009713741019368172,
            -0.004423803649842739,
            -0.011145707219839096,
            0.0035725936759263277,
            0.026930605992674828,
            0.005472441203892231,
            0.010635693557560444,
            -0.01568632200360298,
            -0.024584881961345673,
            -0.025606554001569748,
            0.006911813281476498,
            -0.008832774125039577,
            -0.021834706887602806,
            0.07919251173734665,
            0.02187063731253147,
            -0.006215981207787991,
            0.005399386864155531,
            -0.013627147302031517,
            0.025209641084074974,
            0.051798902451992035,
            -0.016647860407829285,
            -0.02566732093691826,
            -0.003095644060522318,
            0.0036080076824873686,
            0.007664236705750227,
            0.007533596362918615,
            0.003536552656441927,
            0.02694767899811268,
            -0.0005806099507026374,
            -0.0026930144522339106,
            -0.017695758491754532,
            -0.018860923126339912,
            0.012058197520673275,
            -0.02531077340245247,
            -0.03042653203010559,
            -0.014489199966192245,
            0.001107312855310738,
            0.02052254043519497,
            -0.022165341302752495,
            -0.034041520208120346,
            -0.011779538355767727,
            0.02420070394873619,
            0.0056328861974179745,
            0.012664164416491985,
            -0.024511810392141342,
            -0.0023703360930085182,
            -0.04159817472100258,
            -0.018039090558886528,
            -0.011851531453430653,
            0.011548733338713646,
            -0.009880977682769299,
            0.024261904880404472,
            0.00871644914150238,
            0.029970111325383186,
            -0.013834752142429352,
            -0.018362589180469513,
            0.04171651974320412,
            -0.030468739569187164,
            -0.021965349093079567,
            -0.006497909780591726,
            -0.02844066545367241,
            -0.0005964844021946192,
            -0.005904010962694883,
            0.0036188934464007616,
            -0.008812876418232918,
            0.03014899417757988,
            0.004913493525236845,
            0.03891590237617493,
            0.021792348474264145,
            0.013767526485025883,
            -0.019955730065703392,
            -0.022904297336935997,
            0.045567385852336884,
            -0.010640284046530724,
            0.012930377386510372,
            0.021870488300919533,
            -0.02231591008603573,
            0.0014416574267670512,
            -0.029035134240984917,
            -0.06079355254769325,
            0.028462981805205345,
            -0.009024576283991337,
            0.016491180285811424,
            0.008585264906287193,
            0.02616947516798973,
            -0.005664949771016836,
            0.037759214639663696,
            0.00914800725877285,
            -0.02040129341185093,
            0.016529273241758347,
            0.0036427078302949667,
            -0.016663741320371628,
            -0.013859216123819351,
            0.01778174191713333,
            0.030688438564538956,
            0.03493187576532364,
            0.030251536518335342,
            0.01700839214026928,
            0.011560386046767235,
            -0.024386905133724213,
            -0.024076972156763077,
            0.02101692371070385,
            -0.026531163603067398,
            0.030302638188004494,
            0.003149565774947405,
            -0.004066252615302801,
            0.007312640082091093,
            0.029355395585298538,
            0.004259556531906128,
            -0.002742891199886799,
            0.0016598139191046357,
            0.019439131021499634,
            -0.016142399981617928,
            0.047638632357120514,
            -0.01589452102780342,
            0.05043870583176613,
            -0.006444491911679506,
            -0.024076644331216812,
            -0.02919844351708889,
            0.01304005365818739,
            0.028187589719891548,
            -0.011894562281668186,
            0.015863945707678795,
            0.018464356660842896,
            0.014715573750436306,
            0.0037398047279566526,
            0.019172944128513336,
            0.006986032240092754,
            -0.007161777000874281,
            -0.016389306634664536,
            0.021548805758357048,
            -0.022988375276327133,
            0.008432704955339432,
            0.008986136876046658,
            0.023787105455994606,
            -0.004977941047400236,
            -0.011761680245399475,
            0.005701574496924877,
            0.03634162247180939,
            0.027981704100966454,
            -0.02826436050236225,
            -0.005930381361395121,
            -0.017261927947402,
            0.0020127526950091124,
            0.015017318539321423,
            -0.010453729890286922,
            -0.005892143119126558,
            -0.005243846680969,
            -0.02626594714820385,
            0.0039674569852650166,
            0.010048110969364643,
            0.02037131041288376,
            -0.0026559862308204174,
            0.011087759397923946,
            0.043075792491436005,
            -0.011680549941956997,
            -0.0015201123896986246,
            -0.025586457923054695,
            0.03137171268463135,
            0.01156659796833992,
            -0.01883172057569027,
            0.001960968831554055,
            -0.027924682945013046,
            0.0119583485648036,
            -0.028752431273460388,
            0.01779971644282341,
            -0.012772558256983757,
            0.036551713943481445,
            -0.023820148780941963,
            6.534412386827171e-05,
            0.02976897731423378,
            -0.03166751563549042,
            0.03797851502895355,
            0.017050309106707573,
            0.0015211757272481918,
            -0.005103204865008593,
            0.007514865603297949,
            -0.010007676668465137,
            0.017316540703177452,
            -0.026691488921642303,
            0.011364850215613842,
            -0.0036208261735737324,
            -0.010864851996302605,
            -0.003122568828985095,
            -0.000984590849839151,
            0.006545881740748882,
            0.017191626131534576,
            0.008539740927517414,
            -0.00011771841673180461,
            -0.0017255001002922654,
            0.01539904996752739,
            0.02568328194320202,
            0.011408340185880661,
            0.01573348604142666,
            0.00891831610351801,
            -9.553717245580629e-05,
            0.05629263073205948,
            0.017751529812812805,
            -0.016763845458626747,
            0.02778244949877262,
            -0.011574823409318924,
            0.027928585186600685,
            -0.00023995192896109074,
            0.033317338675260544,
            0.010695879347622395,
            -0.053747378289699554,
            -0.0038885329850018024,
            -0.013475227169692516,
            0.007833100855350494,
            -0.017466116696596146,
            -0.019873157143592834,
            0.030767275020480156,
            0.02517964318394661,
            -0.014432070776820183,
            0.04291389882564545,
            0.02444799616932869,
            -0.03455106541514397,
            -0.01592380367219448,
            -0.002079080091789365,
            -0.004189053550362587,
            0.045698780566453934,
            0.021662861108779907,
            0.02417569048702717,
            -0.000858672137837857,
            0.036010921001434326,
            -0.01886669173836708,
            0.03356634080410004,
            0.032411448657512665,
            -0.01266525685787201,
            -0.019781317561864853,
            0.040139369666576385,
            0.009940994903445244,
            -0.008675730787217617,
            0.03589481860399246,
            -0.02220250479876995,
            -0.0038461454678326845,
            0.0057436805218458176,
            0.04603515937924385,
            0.047557152807712555,
            -0.017531486228108406,
            0.027127500623464584,
            0.02445705607533455,
            0.024714959785342216,
            -0.012950843200087547,
            0.01197009440511465,
            -0.018917355686426163,
            0.012602067552506924,
            -0.04330824315547943,
            -0.008433676324784756,
            0.00986664742231369,
            -0.007849425077438354,
            0.021543772891163826,
            0.026695474982261658,
            -0.03456343710422516,
            -0.028614943847060204,
            -0.030011732131242752,
            0.03254086151719093,
            0.04000725969672203,
            -0.014748835004866123,
            -0.007229391485452652,
            0.009202663786709309,
            0.008053823374211788,
            0.03370096907019615,
            -0.012602248229086399,
            0.0028363887686282396,
            0.013928060419857502,
            0.0030598074663430452,
            0.039571572095155716,
            -0.034454964101314545,
            0.00045249887625686824,
            0.0022264921572059393,
            0.004234515130519867,
            0.0045342459343373775,
            0.017896851524710655,
            -0.010564987547695637,
            0.041984330862760544,
            0.039186615496873856,
            -0.008711331523954868,
            -0.010974238626658916,
            0.03114437870681286,
            0.01410824153572321,
            0.01310376450419426,
            -0.011516109108924866,
            0.0011318156030029058,
            -0.023002920672297478,
            0.005254175513982773,
            -0.004103366285562515,
            0.027460917830467224,
            0.014061122201383114,
            0.02763744257390499,
            0.0037948053795844316,
            0.057203639298677444,
            -0.024471880868077278,
            -0.02244020439684391,
            0.007550857029855251,
            0.0021417492534965277,
            -0.007559469435364008,
            -0.031165970489382744,
            0.033939506858587265,
            -0.05388245731592178,
            -0.03431680053472519,
            -0.002691174391657114,
            -0.0494508296251297,
            0.028365325182676315,
            0.015463104471564293,
            -0.01838567666709423,
            0.014924159273505211,
            0.03490235283970833,
            0.0009250190341845155,
            -0.018943483009934425,
            -0.03780682384967804,
            0.01856597512960434,
            0.021800169721245766,
            -0.04406427964568138,
            0.01577123813331127,
            0.015696872025728226,
            0.0030409316532313824,
            0.014000700786709785,
            0.0194834154099226,
            -0.03240370377898216,
            -0.018162131309509277,
            -0.03395236283540726,
            -0.013183212839066982,
            0.009189841337502003,
            -0.04411422461271286,
            -0.03327019885182381,
            0.026064613834023476,
            0.01053599826991558,
            0.06504843384027481,
            -0.014087648130953312,
            0.02106615900993347,
            0.038127582520246506,
            -0.0022977537009865046,
            -0.008409226313233376,
            -0.05826467648148537,
            0.02322726510465145,
            -0.02290116623044014,
            0.018496906384825706,
            0.029677370563149452,
            0.010456950403749943,
            0.0029111241456121206,
            -0.027639534324407578,
            -0.0015257337363436818,
            0.0030766816344112158,
            -0.036697037518024445,
            0.030851854011416435,
            0.010608911514282227,
            -0.018710844218730927,
            -0.0010570317972451448,
            0.019523242488503456,
            -0.003330022795125842,
            0.015272418037056923,
            -0.03144291043281555,
            0.050489190965890884,
            -0.010285897180438042,
            0.010102306492626667,
            -0.01957099512219429
        ],
        [
            -0.028470654040575027,
            -0.01139223761856556,
            0.012334161438047886,
            -0.010916442610323429,
            0.01408434845507145,
            0.029200423508882523,
            0.03230471909046173,
            -0.014839230105280876,
            0.009540820494294167,
            -0.024630779400467873,
            -0.022106340155005455,
            -0.017187360674142838,
            0.021023506298661232,
            0.03574882447719574,
            -0.01360433828085661,
            -0.05314607545733452,
            0.007388958241790533,
            0.006788406055420637,
            0.023478159680962563,
            0.004410711117088795,
            0.005740575958043337,
            0.009898267686367035,
            0.07389895617961884,
            0.01041050162166357,
            0.015605832450091839,
            0.008755176328122616,
            -0.02131159044802189,
            -0.05188976600766182,
            -0.009156815707683563,
            -0.04492255300283432,
            0.02873229794204235,
            -0.009381716139614582,
            -0.00031464238418266177,
            -0.003806270891800523,
            0.012596736662089825,
            0.01879558153450489,
            -0.032951511442661285,
            -0.008708874695003033,
            0.020801082253456116,
            0.0343465730547905,
            0.003924589138478041,
            0.018144208937883377,
            -0.003223181702196598,
            -0.00485320296138525,
            -0.008923745714128017,
            -0.04688366502523422,
            -0.03371892124414444,
            -0.011687987484037876,
            0.008989552035927773,
            -0.0023134753573685884,
            -0.02102527767419815,
            0.012474972754716873,
            0.0314074382185936,
            0.009258221834897995,
            -0.0115228071808815,
            0.0072953179478645325,
            -0.024769721552729607,
            0.048020463436841965,
            -0.01768517680466175,
            0.010759902186691761,
            -0.0016767573542892933,
            -0.018800396472215652,
            -0.001209384761750698,
            0.018875693902373314,
            -0.03380076587200165,
            -0.006204045843333006,
            -0.024433299899101257,
            -0.0031228638254106045,
            0.006490456406027079,
            -0.025778384879231453,
            -0.014460097998380661,
            0.013691495172679424,
            0.01660163514316082,
            0.02690230682492256,
            -0.00047189369797706604,
            0.012106562033295631,
            0.00731272529810667,
            -0.00333710340783,
            -0.030769066885113716,
            0.010205407626926899,
            0.03162700682878494,
            0.013383268378674984,
            0.00483526149764657,
            -0.005934090819209814,
            0.014320729300379753,
            0.00018863128207158297,
            0.008413785137236118,
            -0.019251730293035507,
            0.021898280829191208,
            -0.054103657603263855,
            -0.0023556991945952177,
            0.019284464418888092,
            0.000825681141577661,
            -0.02543041855096817,
            -0.05341567471623421,
            0.0014815693721175194,
            0.053057413548231125,
            -0.014423606917262077,
            -0.03023461624979973,
            0.020383788272738457,
            -0.022311892360448837,
            -0.010981906205415726,
            -0.056309886276721954,
            0.018240204080939293,
            0.01666719652712345,
            -0.01146589033305645,
            0.025660349056124687,
            0.05428681895136833,
            -0.06637848168611526,
            0.008169368840754032,
            -0.010433644987642765,
            0.01424387376755476,
            -0.02218502201139927,
            0.0033858008682727814,
            0.02613772638142109,
            -0.0006852957885712385,
            0.008727159351110458,
            0.01676289364695549,
            -0.04358468949794769,
            -0.017395906150341034,
            0.005689411424100399,
            0.027414990589022636,
            -0.009148173965513706,
            -0.028269805014133453,
            -0.033337756991386414,
            -0.05409883335232735,
            0.05899618938565254,
            0.022290891036391258,
            0.022492386400699615,
            -0.029249433428049088,
            -0.0033003108110278845,
            0.028954526409506798,
            0.006697583012282848,
            -0.0023548011668026447,
            0.0009932316606864333,
            -0.005210283678025007,
            0.009993876330554485,
            0.008780048228800297,
            -0.00274660624563694,
            -0.0039495257660746574,
            0.0014660669257864356,
            0.0039445809088647366,
            -0.005399881862103939,
            0.029510993510484695,
            -0.03482648357748985,
            -0.05402642488479614,
            0.043642666190862656,
            0.005171534605324268,
            0.00012241551303304732,
            0.006182090379297733,
            0.034575529396533966,
            -0.04015255719423294,
            -0.008150157518684864,
            0.028492622077465057,
            0.03112206980586052,
            -0.03889260068535805,
            -0.008923894725739956,
            0.019993038848042488,
            0.03202870115637779,
            -0.0543421134352684,
            -0.00688273785635829,
            -0.005161588080227375,
            -0.01342003233730793,
            -0.04600079357624054,
            0.012146527878940105,
            -0.033037710934877396,
            -0.06683822721242905,
            0.02646980993449688,
            -0.02165120653808117,
            0.01257904153317213,
            0.014159956946969032,
            0.02159731835126877,
            0.04064522683620453,
            0.050376150757074356,
            -0.027713177725672722,
            0.01865248754620552,
            -0.01083423849195242,
            -0.025124600157141685,
            -0.031529203057289124,
            -0.005831559654325247,
            -0.0040558939799666405,
            0.025085918605327606,
            0.03762853518128395,
            -0.006244951859116554,
            -0.011834601871669292,
            0.025000950321555138,
            0.012513351626694202,
            -0.005804172717034817,
            -0.006558251101523638,
            0.0009562240447849035,
            -0.02846515364944935,
            -0.0002775220200419426,
            -0.028263168409466743,
            -0.021892553195357323,
            0.014217402786016464,
            0.039081841707229614,
            -0.017527390271425247,
            -0.03080226667225361,
            0.015825465321540833,
            0.005126735661178827,
            -0.007869369350373745,
            -0.01846943609416485,
            0.014688419178128242,
            -0.02662796527147293,
            -0.08057485520839691,
            -0.014947053976356983,
            0.01299004815518856,
            0.012681853957474232,
            -0.015344212763011456,
            0.016045955941081047,
            -0.050408490002155304,
            -0.008732659742236137,
            -0.07914053648710251,
            0.010084209963679314,
            -0.023400431498885155,
            -0.021562092006206512,
            -0.0065222689881920815,
            0.022028448060154915,
            -0.0005686688818968832,
            -0.028817791491746902,
            0.026454757899045944,
            0.03570502623915672,
            0.03304443880915642,
            -0.028449667617678642,
            0.018250079825520515,
            -0.08197365701198578,
            0.03891292214393616,
            0.034774165600538254,
            -0.015145672485232353,
            0.03742368891835213,
            -0.019630558788776398,
            -0.01737286150455475,
            0.025316717103123665,
            0.034418798983097076,
            -0.07883808016777039,
            -0.016149790957570076,
            0.02042129449546337,
            0.008066698908805847,
            0.011296246200799942,
            -0.06733794510364532,
            0.01743653416633606,
            -0.01876889169216156,
            0.006581158377230167,
            -0.028654303401708603,
            -0.009246552363038063,
            -0.0017854481702670455,
            0.023597532883286476,
            0.020391492173075676,
            0.0007796746212989092,
            -0.016591407358646393,
            0.012193582020699978,
            0.011392449960112572,
            -0.02508804388344288,
            0.023206064477562904,
            0.023445921018719673,
            0.011494956910610199,
            -0.032612357288599014,
            0.02210833877325058,
            -0.035925060510635376,
            0.012616286054253578,
            -0.02342079021036625,
            -0.005468706600368023,
            -0.010628918185830116,
            -0.04748915135860443,
            -0.023921474814414978,
            -0.011847815476357937,
            -0.0008813558961264789,
            -0.01851777546107769,
            0.023066889494657516,
            -0.018105560913681984,
            -0.0157830361276865,
            -0.002291858196258545,
            -0.014143697917461395,
            -0.019167691469192505,
            -0.012628454715013504,
            0.003267923602834344,
            0.004344291519373655,
            0.017896903678774834,
            0.0020985014270991087,
            -0.01293005608022213,
            -0.02368876151740551,
            -0.007032355293631554,
            0.02533661015331745,
            -0.009234786033630371,
            0.02049645408987999,
            0.040537405759096146,
            -0.007801770698279142,
            -0.02975766360759735,
            0.00447561452165246,
            0.013350448571145535,
            0.00028548092814162374,
            -0.014514469541609287,
            0.01927555911242962,
            0.04127127304673195,
            -0.013637980446219444,
            0.009306908585131168,
            0.029129285365343094,
            -0.023968441411852837,
            -0.0021161779295653105,
            -0.0006108389352448285,
            -0.0028392854146659374,
            -0.04169106483459473,
            0.03330365940928459,
            0.01720183715224266,
            -0.018137745559215546,
            0.038346048444509506,
            0.022426875308156013,
            -0.00964844599366188,
            0.021167244762182236,
            0.03255302086472511,
            -0.03737473115324974,
            0.00981193408370018,
            -0.0328621044754982,
            0.023042552173137665,
            0.005290886387228966,
            -0.02421981655061245,
            -0.026505032554268837,
            0.03063300810754299,
            0.007819619961082935,
            0.018813494592905045,
            -0.016346799209713936,
            -0.04521933197975159,
            -0.004179040435701609,
            -0.0003722227702382952,
            -0.019724292680621147,
            0.004090263042598963,
            -0.022009873762726784,
            0.013585509732365608,
            -0.02391057088971138,
            0.02985380031168461,
            0.005377224646508694,
            0.023898418992757797,
            0.0014560201670974493,
            0.0023386667016893625,
            -0.007079722359776497,
            0.043693069368600845,
            -0.02228035405278206,
            -0.023698143661022186,
            0.02137347310781479,
            0.018941009417176247,
            -0.011119520291686058,
            -0.0019047533860430121,
            -0.04663287103176117,
            -0.025214802473783493,
            -0.010705935768783092,
            0.03226575627923012,
            -0.013917871750891209,
            0.014918778091669083,
            -0.020696038380265236,
            -0.0032903719693422318,
            -0.031227465718984604,
            -0.0178696196526289,
            -0.023669956251978874,
            -0.014737599529325962,
            -0.05022472143173218,
            0.009291901253163815,
            -0.0009397913236171007,
            -0.03704841062426567,
            0.014396154321730137,
            -0.013123413547873497,
            0.0009479214204475284,
            0.004112084396183491,
            0.005315651651471853,
            -0.017429469153285027,
            0.020495818927884102,
            0.030538175255060196,
            0.011182579211890697,
            -0.0317925363779068,
            -0.04800849035382271,
            -0.020276077091693878,
            -0.0037289736792445183,
            0.03883912041783333,
            -0.025251269340515137,
            -0.0031969426199793816,
            0.0139389643445611,
            0.0028873609844595194,
            -0.021911799907684326,
            0.04518207907676697,
            -0.00035644270246848464,
            0.0015397078823298216,
            0.054479874670505524,
            0.01280896458774805,
            0.011606204323470592,
            0.026087015867233276,
            -0.04352300241589546,
            -0.0024662932846695185,
            0.014296181499958038,
            -0.014612789265811443,
            0.01685282774269581,
            0.019014161080121994,
            0.0046282620169222355,
            -0.000570206088013947,
            -0.023624595254659653,
            0.017389293760061264,
            -0.06223071739077568,
            -0.010783350095152855,
            0.01883510686457157,
            -0.04432041943073273,
            -0.0028885577339679003,
            0.020247580483555794,
            -0.027056768536567688,
            0.04017673060297966,
            0.0017697169678285718,
            0.004505022894591093,
            0.007555006537586451,
            -0.015983423218131065,
            -0.024767789989709854,
            -0.014302573166787624,
            -0.016719922423362732,
            0.018070746213197708,
            0.02913077548146248,
            0.037831079214811325,
            0.018073666840791702,
            -0.05439925938844681,
            0.03391179069876671,
            0.007632868364453316,
            -0.018418846651911736,
            0.007990307174623013,
            0.02068774215877056,
            -0.012649508193135262,
            0.05241828039288521,
            0.020076759159564972,
            0.06348263472318649,
            0.004931597504764795,
            -0.014314522966742516,
            0.008701683953404427,
            0.039616379886865616,
            -0.052402012050151825,
            0.015501260757446289,
            -0.03771224990487099,
            0.015901556238532066,
            -0.03239302337169647,
            0.030710840597748756,
            0.0012171643320471048,
            -0.0004846694355364889,
            0.022328486666083336,
            -0.038231011480093,
            0.006156877148896456,
            -0.030151689425110817,
            0.011390337720513344,
            0.0054615032859146595,
            -0.0027088879141956568,
            -0.02691684290766716,
            -0.03569149598479271,
            0.03402165696024895,
            0.012321056798100471,
            -0.016783906146883965,
            -0.07029403001070023,
            -0.0015304788248613477,
            -0.023261932656168938,
            -0.005520824808627367,
            0.026072710752487183,
            0.04938959330320358,
            -0.00011014541814802215,
            0.007372482214123011,
            0.01676136627793312,
            0.01582416705787182,
            0.006096094846725464,
            0.015358703210949898,
            0.031607162207365036,
            -0.024419087916612625,
            0.023570265620946884,
            -0.02432372234761715,
            -0.033922575414180756,
            -0.008024384267628193,
            -0.017629139125347137,
            -0.0072340890765190125,
            -0.005482125096023083,
            0.018911613151431084,
            -0.0010478351032361388,
            -0.0011833547614514828,
            0.015339895151555538,
            0.032542191445827484,
            -0.019350701943039894,
            0.013663859106600285,
            0.009308517910540104,
            -0.014356681145727634,
            0.010838635265827179,
            -0.05022178217768669,
            0.02091081440448761,
            -0.007488799747079611,
            -0.007733318954706192,
            -0.060844723135232925,
            -0.017109153792262077,
            -0.010522984899580479,
            -0.002080144127830863,
            0.016789956018328667,
            -0.019529512152075768,
            -0.07066140323877335,
            0.019908934831619263,
            0.04080983251333237,
            -0.0018694564932957292,
            0.0029624481685459614,
            0.02145044505596161,
            -0.008883517235517502,
            -0.003546861233189702,
            -0.011921345256268978,
            0.013905174098908901,
            -0.0235099159181118,
            0.023059360682964325,
            -0.01866981014609337,
            0.010000724345445633,
            0.03444778174161911,
            -0.0007123024552129209,
            -0.03878072649240494,
            -0.021241113543510437,
            -0.008695111609995365,
            0.013351529836654663,
            0.012064900249242783,
            0.012525334022939205,
            -0.007448522839695215,
            -0.017992911860346794,
            0.020976729691028595,
            0.010482941754162312,
            0.03346746787428856,
            -0.026834841817617416,
            -0.019540756940841675,
            -0.028970440849661827,
            -0.029195711016654968,
            -0.02597963996231556,
            0.027371592819690704,
            -0.0009890199871733785,
            -0.011027337051928043,
            0.010416311211884022,
            0.005444943439215422,
            -0.04831814393401146,
            0.03066050261259079,
            0.006571917794644833,
            -0.033748138695955276,
            0.028647586703300476,
            -0.02385176159441471,
            -0.020760126411914825,
            -0.022229695692658424,
            0.015249904245138168,
            0.0037766103632748127,
            -0.06224261224269867,
            0.011221309192478657,
            -0.023894838988780975,
            0.014028645120561123,
            -0.012818176299333572,
            0.019286587834358215,
            -0.042579732835292816,
            0.027618354186415672,
            -0.009658228605985641,
            -0.008692084811627865,
            0.04259894788265228,
            0.019048448652029037,
            -0.022170931100845337,
            0.01730481907725334,
            -0.008404712192714214,
            -0.0008821093360893428,
            0.022336320951581,
            0.01536695659160614,
            0.004601762164384127,
            -0.007866697385907173,
            -0.014077812433242798,
            -0.030039194971323013,
            0.0252700075507164,
            -0.022457526996731758,
            0.02942056767642498,
            -0.0028575195465236902,
            0.019949764013290405,
            0.01566726341843605,
            0.02686890959739685,
            -0.02387896180152893,
            -0.0447922945022583,
            0.008203399367630482,
            0.032725945115089417,
            0.024803660809993744,
            0.0010934025049209595,
            0.019172711297869682,
            0.017182335257530212,
            0.010621710680425167,
            -0.00838578213006258,
            0.045049648731946945,
            0.006089500617235899,
            -0.017697587609291077,
            0.01718831993639469,
            -0.008775085210800171,
            -0.006237046327441931,
            -0.007106482051312923,
            0.002141280798241496,
            -0.02427344024181366,
            0.02130889892578125,
            0.0016899170586839318,
            -0.02287665568292141,
            -0.03550868108868599,
            0.025474078953266144,
            -0.01337525062263012,
            0.0011101439595222473,
            0.008357311598956585,
            -0.0033507784828543663,
            -0.00717165507376194,
            -0.00038884577224962413,
            -0.014572139829397202,
            -0.005380645394325256,
            0.0534561425447464,
            0.0019221869297325611,
            -0.03908572345972061,
            -0.022143470123410225,
            0.016566284000873566,
            -0.03180120512843132,
            -0.009666708298027515,
            0.021993190050125122,
            -0.022496042773127556,
            0.00015973254630807787,
            -0.0025085590314120054,
            -0.007252796553075314,
            -0.030967773869633675,
            0.03968175873160362,
            0.002555299550294876,
            0.02666340582072735,
            0.02465134672820568,
            -0.005794864613562822,
            -0.029997844249010086,
            -0.015532917343080044,
            -0.021078292280435562,
            0.017397567629814148,
            0.033094074577093124,
            -0.017418792471289635,
            0.009532296098768711,
            0.014331835322082043,
            -0.02489311620593071,
            -0.023900683969259262,
            -0.015476507134735584,
            -0.01676461286842823,
            -0.007060357369482517,
            -0.03722553327679634,
            -0.014251915737986565,
            -0.024153415113687515,
            -0.062471792101860046,
            -0.004722056444734335,
            -0.004481658339500427,
            0.02181585319340229,
            -0.034670207649469376,
            -0.0035526903811842203,
            0.02722228690981865,
            0.04163574427366257,
            -0.044227320700883865,
            0.012089340016245842,
            -0.008279439993202686,
            0.025116870179772377,
            -0.006736798211932182,
            -0.009066548198461533,
            -0.012937069870531559,
            -0.00017393800953868777,
            -0.046559885144233704,
            0.02243613451719284,
            -0.0015939753502607346,
            0.01900552026927471,
            0.0232318677008152,
            0.02522132731974125,
            0.011069145984947681,
            0.015359864570200443,
            -0.03284827247262001,
            -0.018349958583712578,
            -0.005389929283410311,
            0.011517214588820934,
            -0.002015866572037339,
            0.004756334237754345,
            -0.06307445466518402,
            -0.01907060667872429,
            0.008855696767568588,
            0.01749991625547409,
            0.002914860611781478,
            0.005871762055903673,
            0.009480067528784275,
            0.012020770460367203,
            0.01857345551252365,
            -0.01378096267580986,
            -0.02524016983807087,
            -0.008331941440701485,
            -0.0007106521516107023,
            -0.02165069989860058,
            -0.0022961492650210857,
            -0.04403642937541008,
            -0.021205808967351913,
            -0.01888090930879116,
            0.007462815381586552,
            0.029936043545603752,
            0.06172127276659012,
            -0.013229647651314735,
            0.020078998059034348,
            0.004798974841833115,
            0.03456994146108627,
            -0.010245495475828648,
            0.022508837282657623,
            -0.014799732714891434,
            -0.019733579829335213,
            -0.02798006497323513,
            0.003806796856224537,
            0.0019106782274320722,
            -0.034722618758678436,
            0.017753927037119865,
            -0.01192242931574583,
            0.011060872115194798,
            0.009541863575577736,
            -0.01751542091369629,
            -0.021016312763094902,
            -0.02662089094519615,
            0.004811333026736975,
            -0.03523186594247818,
            0.010832708328962326,
            0.013918301090598106,
            0.0277705118060112,
            -0.026142049580812454,
            0.027993910014629364,
            -0.005487265530973673,
            0.008495306596159935,
            -0.01946444623172283,
            -0.023715879768133163,
            -0.03297943249344826,
            0.020470166578888893,
            -0.013538673520088196,
            -0.04904522746801376,
            0.031249145045876503,
            -0.0836247056722641,
            -0.008831832557916641,
            0.02540876716375351,
            0.013232260011136532,
            -0.01578597165644169,
            0.01772705651819706,
            0.03487376496195793,
            -0.014250184409320354,
            0.017565743997693062,
            -0.0005231300019659102,
            -0.01547794509679079,
            -0.030901355668902397,
            0.012536323629319668,
            0.009075060486793518,
            -0.060326073318719864,
            -0.005963122937828302,
            0.0020250147208571434,
            -0.014270656742155552,
            -0.039696674793958664,
            -0.051660507917404175,
            -0.007540681399405003,
            0.000207244636840187,
            0.024650469422340393,
            0.009695220738649368,
            -0.008071118034422398,
            -0.0336206816136837,
            0.01686493307352066,
            -0.009493754245340824,
            -0.03257925435900688,
            -0.025600139051675797,
            -0.08351972699165344,
            -0.017435995861887932,
            -0.027907421812415123,
            0.01962137781083584,
            -0.0029482159297913313,
            -0.0019452872220426798,
            0.027085604146122932,
            0.029841072857379913,
            -0.012778245843946934,
            -0.019608555361628532,
            0.0478208102285862,
            -0.022459732368588448,
            0.008412168361246586,
            0.004942744504660368,
            0.03639105334877968,
            -0.07621189206838608,
            -0.026863260194659233,
            -0.013049165718257427,
            -0.00950117502361536,
            -0.00983379129320383,
            -0.009602781385183334,
            0.010041570290923119,
            0.008644218556582928,
            0.024039382115006447,
            0.03807413950562477,
            0.017480630427598953,
            -0.0013844723580405116,
            -0.05587903782725334,
            -0.00402831332758069,
            0.011197550222277641,
            -0.028407469391822815,
            0.03205026686191559,
            0.02536945790052414,
            0.010799989104270935,
            0.02384195849299431,
            0.01335664838552475,
            -0.027412155643105507,
            -0.00229817908257246,
            -0.019713668152689934,
            0.008772964589297771,
            0.028136584907770157,
            -0.017351260408759117,
            -0.04983936995267868,
            -0.035410355776548386,
            0.037061914801597595,
            -0.0029687664937227964,
            0.022066880017518997,
            -0.04396895691752434,
            -0.02446640096604824,
            0.0028705820441246033,
            0.03575771674513817,
            -0.006976458244025707,
            -0.00624542310833931,
            0.012700773775577545,
            -0.05304128676652908,
            -0.018987368792295456,
            -0.041859325021505356,
            0.02865297719836235,
            -0.030602822080254555,
            0.033663906157016754,
            0.034015316516160965,
            0.008788066916167736,
            0.03995409980416298,
            0.00534366350620985,
            -0.007838890887796879,
            0.007319685537368059,
            -0.004366936627775431,
            -0.02322976104915142,
            -0.031709861010313034,
            -0.02431974746286869,
            -0.02143445797264576,
            0.008834576234221458,
            -0.007788139395415783,
            0.019045410677790642,
            0.025431226938962936,
            0.012159546837210655,
            0.025012977421283722,
            -0.040845081210136414,
            -0.04500714689493179,
            0.00630460400134325,
            0.007077967282384634,
            -0.017481811344623566,
            0.0005386652774177492,
            0.025956572964787483,
            -0.02219121716916561,
            0.011319181881844997,
            0.02141353115439415,
            -0.004579346161335707,
            0.02612355723977089,
            0.033359650522470474,
            -0.029210366308689117,
            -0.0006135175935924053,
            0.011198922991752625,
            0.03894420713186264,
            0.024355774745345116,
            -0.012040205299854279,
            0.03559831529855728,
            0.03387885168194771,
            0.023965446278452873,
            -0.023403579369187355,
            -0.00971622858196497,
            -0.03537623956799507,
            0.01877288892865181,
            0.032879333943128586,
            -0.0022490317933261395,
            -0.015028039924800396,
            -0.00805435050278902,
            -0.005952702835202217,
            -0.0438702255487442,
            -0.014927182346582413,
            0.0014574548695236444,
            0.02216842770576477,
            0.025667035952210426,
            0.0008596576517447829,
            0.0027270023711025715,
            -0.02843783050775528,
            0.03529014065861702,
            -0.02348407544195652,
            -0.04885563254356384,
            0.011315740644931793,
            -0.00534933852031827,
            0.049198150634765625,
            -0.014571272768080235,
            0.006910683587193489,
            -0.020946772769093513,
            0.010589583776891232,
            0.05762023851275444,
            -0.0005014418857172132,
            -0.016437746584415436,
            -0.04129145294427872,
            -0.003658781060948968,
            0.005551706999540329,
            -0.01967296004295349,
            0.004438002128154039,
            -0.03875293955206871,
            0.0495867058634758,
            -0.0387781523168087,
            -0.0014817755436524749,
            0.020734300836920738,
            0.03809605538845062,
            -0.024769717827439308,
            0.026118718087673187,
            -0.012278146110475063,
            0.022613415494561195,
            0.01840341091156006,
            -0.030725130811333656,
            -0.009223119355738163,
            -0.009272045455873013,
            -0.000508716853801161,
            -0.012300712987780571,
            -0.007842645049095154,
            -0.06196347996592522,
            0.014780968427658081,
            0.0047551896423101425,
            0.015143638476729393,
            -0.0049865394830703735,
            0.0033399390522390604,
            0.00013586120621766895,
            0.001644686795771122,
            -0.005443616304546595,
            0.02633049711585045,
            -0.019797509536147118,
            0.0024958334397524595,
            0.035453278571367264,
            0.02837360091507435,
            0.013985158875584602,
            0.0016057066386565566,
            0.006988360546529293,
            -0.02651309408247471,
            -0.01973715052008629,
            0.04809208959341049,
            0.00683667603880167,
            -0.004954674746841192,
            0.016863210126757622,
            0.023937582969665527,
            0.0005668841185979545,
            0.010193136520683765,
            -0.038242850452661514,
            0.013684931211173534,
            0.042352985590696335,
            0.015383346937596798,
            -0.018863677978515625,
            0.004536221269518137,
            0.01103277038782835,
            0.007649233564734459,
            0.022841187193989754,
            -0.008775769732892513,
            -0.04883391782641411,
            -0.007337286602705717,
            0.011483332142233849,
            -0.006326107773929834,
            0.02136755920946598,
            0.02453729137778282,
            -0.009171568788588047,
            0.029376612976193428,
            -0.031623952090740204,
            -0.0004750622028950602,
            0.04042231664061546,
            -0.04192306473851204,
            0.0055498769506812096,
            -0.021535828709602356,
            0.01137875858694315,
            -0.010577868670225143,
            -0.016104640439152718,
            -0.04746825620532036,
            -0.00747414818033576,
            0.023768683895468712,
            -0.013826822862029076,
            0.03715952858328819,
            -0.02712668664753437,
            -0.00046916567953303456,
            0.007636863738298416,
            -0.0032908516004681587,
            -0.025219619274139404,
            -0.017635729163885117,
            -0.025774739682674408,
            0.014464720152318478,
            -0.002054800745099783,
            -0.0006831554346717894,
            0.013774845749139786,
            -0.03964637219905853,
            0.01500007789582014,
            -0.015076256357133389,
            -0.0055540818721055984,
            0.018631482496857643,
            0.0005784309469163418,
            -0.028926607221364975,
            -0.012856751680374146,
            0.003283428493887186,
            -0.013358194380998611,
            -0.012451945804059505,
            -0.009289619512856007,
            -0.015431850217282772,
            0.016336191445589066,
            0.017357919365167618,
            -0.011618087068200111,
            -0.041994623839855194,
            -0.03215865418314934,
            0.03677681088447571,
            0.014121880754828453,
            -0.026218924671411514,
            0.02253790944814682,
            0.02559148333966732,
            -0.00863681361079216,
            -0.013716023415327072,
            -0.007962011732161045,
            0.003309879219159484,
            0.021854480728507042,
            -0.011903663165867329,
            -0.007073819171637297,
            -0.054399143904447556,
            -0.03900435194373131,
            -0.02127021551132202,
            0.012873422354459763,
            0.025449244305491447,
            0.0023895499762147665,
            -0.0013191795442253351,
            0.013103963807225227,
            -0.01185165997594595,
            0.020955989137291908,
            0.028437113389372826,
            -0.02017655223608017,
            0.010556344874203205,
            -0.049933239817619324,
            -0.0020427987910807133,
            -0.04308033362030983,
            0.020539535209536552,
            -0.0286761075258255,
            -0.0104304114356637,
            -0.0027840188704431057,
            0.02842218056321144,
            0.006927357986569405,
            0.01093371119350195,
            0.032442398369312286,
            0.00977044552564621,
            -0.08135026693344116,
            0.00021780146926175803,
            0.028723251074552536
        ],
        [
            0.005063342396169901,
            0.021277163177728653,
            -0.03410938009619713,
            -0.052208345383405685,
            -0.02334027737379074,
            -0.07205967605113983,
            0.0340731106698513,
            -0.03078235127031803,
            0.007570804096758366,
            0.0003281692916061729,
            0.03232355788350105,
            -0.019070735201239586,
            -0.0049251592718064785,
            0.04609624296426773,
            0.0469379760324955,
            -0.02536412701010704,
            -0.04038708657026291,
            -0.002135647926479578,
            0.013227260671555996,
            0.027807725593447685,
            0.03624970093369484,
            0.02890866994857788,
            -0.07981548458337784,
            0.0524931401014328,
            0.03951086476445198,
            0.01273198239505291,
            0.0025101180654019117,
            -0.0871899425983429,
            0.007608155719935894,
            0.0017973206704482436,
            0.0009789316682145,
            -0.023034920915961266,
            0.02692001685500145,
            0.015552456490695477,
            0.032445237040519714,
            0.008059178479015827,
            0.020334750413894653,
            -0.01403428427875042,
            -0.0150955431163311,
            -0.014301415532827377,
            -0.003552024019882083,
            -0.03559698536992073,
            0.018698768690228462,
            0.01911218836903572,
            -0.032628294080495834,
            -0.034382015466690063,
            0.013927611522376537,
            -0.002038790611550212,
            -0.008393139578402042,
            -0.010940270498394966,
            -0.00026433274615556,
            -6.5896001615328714e-06,
            0.050786230713129044,
            0.021874675527215004,
            -4.790761522599496e-05,
            -0.01737206056714058,
            -0.02672906219959259,
            0.03457663208246231,
            0.006587477866560221,
            0.020994918420910835,
            0.01666601188480854,
            -0.0063868239521980286,
            0.006923339795321226,
            -0.04754322022199631,
            -0.03072916530072689,
            -0.03233793377876282,
            0.025835853070020676,
            0.021928781643509865,
            -0.010945715010166168,
            -0.022492095828056335,
            0.022914722561836243,
            -0.010853040032088757,
            0.006743630860000849,
            0.02149294503033161,
            0.05674147605895996,
            -0.022748330608010292,
            0.009226765483617783,
            0.03471621870994568,
            0.05907673388719559,
            0.038629837334156036,
            -0.028394537046551704,
            -0.0033668330870568752,
            0.015979232266545296,
            0.007828164845705032,
            0.008020334877073765,
            0.01469290629029274,
            0.03153984248638153,
            -0.052924446761608124,
            -0.019821427762508392,
            0.02036646194756031,
            -0.019801679998636246,
            0.028124304488301277,
            -0.003710848977789283,
            -0.11357521265745163,
            -0.051712486892938614,
            -0.01439015194773674,
            0.04984051361680031,
            0.010346879251301289,
            -0.006970824208110571,
            -0.005631358828395605,
            -0.018872708082199097,
            0.009144489653408527,
            -0.06624701619148254,
            0.022451860830187798,
            0.01183739397674799,
            -0.011339979246258736,
            -0.027480345219373703,
            -0.02742735669016838,
            -0.020435253158211708,
            -0.0381268635392189,
            -0.008872732520103455,
            0.021026745438575745,
            -0.05144184082746506,
            0.013643588870763779,
            -0.05385811999440193,
            0.028845133259892464,
            0.00845008809119463,
            0.019513072445988655,
            0.012221623212099075,
            0.02607010304927826,
            -0.01429498940706253,
            -0.017866870388388634,
            0.05231238529086113,
            -0.004411392845213413,
            -0.012635099701583385,
            0.002343153115361929,
            -0.013299581594765186,
            0.0027142988983541727,
            -0.008439760655164719,
            -0.05013987049460411,
            0.007875421084463596,
            -0.02333129569888115,
            0.029757140204310417,
            -0.020949436351656914,
            0.020243870094418526,
            0.028273267671465874,
            0.03917255997657776,
            -0.025333702564239502,
            -0.010781239718198776,
            0.0276862233877182,
            -0.028049645945429802,
            -0.01854352466762066,
            0.024744199588894844,
            0.004008335992693901,
            -0.05930376052856445,
            -0.04839408025145531,
            0.03767044097185135,
            -0.0026375374291092157,
            -0.015521354041993618,
            0.020937908440828323,
            0.01510598137974739,
            -0.0023808060213923454,
            -0.006453338079154491,
            -0.025582529604434967,
            0.03800681233406067,
            0.0008374933968298137,
            0.02056632563471794,
            -0.002720747608691454,
            0.0012431176146492362,
            0.024375302717089653,
            -0.0017249692464247346,
            -0.02391335368156433,
            0.015351039357483387,
            -0.049696099013090134,
            -0.013831917196512222,
            0.028694240376353264,
            -0.07237424701452255,
            0.028334954753518105,
            -0.05140175297856331,
            0.010096687823534012,
            0.02781645581126213,
            0.006705906707793474,
            -0.011743790470063686,
            0.03728987276554108,
            -0.033320724964141846,
            0.00384086393751204,
            0.005523612257093191,
            -0.013559848070144653,
            -0.003012505592778325,
            0.026571908965706825,
            -0.0048377374187111855,
            -0.02526550181210041,
            -0.06853442639112473,
            -0.024289367720484734,
            -0.002180417301133275,
            -0.010400894097983837,
            0.03111443854868412,
            -0.016043884679675102,
            0.018616294488310814,
            0.005143039859831333,
            -0.012985404580831528,
            0.01920701563358307,
            -0.05625219643115997,
            0.030678778886795044,
            -0.004454349633306265,
            -0.002401859499514103,
            -0.015215829014778137,
            0.012911695055663586,
            -0.010877236723899841,
            0.006005174946039915,
            -0.035371918231248856,
            0.0209279116243124,
            0.011237899772822857,
            0.009332668967545033,
            -0.07669282704591751,
            0.02618468925356865,
            0.03871886432170868,
            0.028477244079113007,
            -0.004726667422801256,
            -0.013364643789827824,
            -0.025998832657933235,
            0.025177570059895515,
            0.028450913727283478,
            0.00180669198743999,
            0.024421360343694687,
            0.020545393228530884,
            0.01486715767532587,
            -0.0017739286413416266,
            -0.022187568247318268,
            0.007105446420609951,
            0.0019346348708495498,
            0.021346673369407654,
            -0.0017945454455912113,
            -0.031011873856186867,
            -0.020208699628710747,
            -0.0254236850887537,
            -0.0026347634848207235,
            -0.026545453816652298,
            -0.03808153420686722,
            0.03726610541343689,
            0.035872846841812134,
            0.039130035787820816,
            -0.008780905045568943,
            -0.024862170219421387,
            -0.04405101761221886,
            0.0031708665192127228,
            0.03608781471848488,
            0.01477046124637127,
            -0.020300570875406265,
            0.0035505262203514576,
            0.01809646375477314,
            0.012913933955132961,
            0.023476492613554,
            -0.03635416924953461,
            0.032728325575590134,
            0.02496224269270897,
            -0.0006469128420576453,
            -0.03406446427106857,
            0.016895975917577744,
            0.006515131797641516,
            -0.015712769702076912,
            0.001222860300913453,
            -0.001982248853892088,
            0.016966000199317932,
            0.04027582332491875,
            -0.009977567009627819,
            -0.013273714110255241,
            -0.008628024719655514,
            0.0013033144641667604,
            0.02234945259988308,
            0.040273185819387436,
            -0.017561426386237144,
            -0.030710211023688316,
            0.01605575904250145,
            -0.014345325529575348,
            -0.003750689560547471,
            -0.02099994756281376,
            -0.05672049894928932,
            0.008819358423352242,
            -0.014646326191723347,
            0.022295605391263962,
            -0.06306098401546478,
            0.002951804781332612,
            -0.045560434460639954,
            -0.03615584969520569,
            -0.018086975440382957,
            0.01830432377755642,
            -0.011975172907114029,
            0.02618938870728016,
            0.00823177769780159,
            -0.012121366336941719,
            -0.040970057249069214,
            0.004378370475023985,
            -0.015282316133379936,
            -0.006186411716043949,
            0.04226537048816681,
            0.017409099265933037,
            -0.04778565466403961,
            0.02142268605530262,
            0.01877395436167717,
            -0.005962861701846123,
            -0.010905226692557335,
            0.001319674775004387,
            0.024121131747961044,
            -0.004380916710942984,
            -0.02482917346060276,
            -0.028594575822353363,
            -0.03690851479768753,
            -0.02956276386976242,
            -0.06096752732992172,
            -0.015927445143461227,
            -0.01273154467344284,
            0.019383931532502174,
            0.040365736931562424,
            -0.005375186912715435,
            0.037545934319496155,
            -0.0060124569572508335,
            0.01698780059814453,
            0.011345149949193,
            0.03707123175263405,
            0.016405664384365082,
            -0.005801438353955746,
            0.0034307606983929873,
            0.025418618693947792,
            -0.043559130281209946,
            -0.03260388970375061,
            -0.001247728941962123,
            0.013009277172386646,
            0.010313233360648155,
            0.007840463891625404,
            0.03423631936311722,
            0.0030976198613643646,
            -0.03477996215224266,
            -0.02702590823173523,
            -0.0015964620979502797,
            -0.03539486229419708,
            0.017009001225233078,
            -0.01746060885488987,
            -0.05333339050412178,
            0.02163463830947876,
            -0.046299148350954056,
            -0.0029648584313690662,
            0.019799135625362396,
            0.025743236765265465,
            -0.019711676985025406,
            -0.010053172707557678,
            -0.018840329721570015,
            0.010042042471468449,
            -0.002716202987357974,
            0.025387680158019066,
            -0.025215202942490578,
            0.04272947087883949,
            -0.023619014769792557,
            -0.012001612223684788,
            -0.05979694426059723,
            -0.0066652665846049786,
            -0.020150819793343544,
            0.025928186252713203,
            -0.011254861950874329,
            -0.03747204318642616,
            0.022210173308849335,
            -0.02405598573386669,
            0.024271294474601746,
            -0.005986407399177551,
            -0.09793519973754883,
            -0.018762098625302315,
            0.019312266260385513,
            -0.020975466817617416,
            0.016698485240340233,
            0.00496494909748435,
            0.0008794373134151101,
            -0.02205977588891983,
            -0.03602691367268562,
            -0.013743831776082516,
            -0.017485087737441063,
            0.017029894515872,
            0.050926826894283295,
            0.002532049547880888,
            -0.021293146535754204,
            -0.004251468926668167,
            -0.024360748007893562,
            -0.024276437237858772,
            0.04388169199228287,
            -0.0439559705555439,
            -0.054176490753889084,
            0.023307958617806435,
            0.014353334903717041,
            -0.051627542823553085,
            0.00019913195865228772,
            -0.00554721150547266,
            -0.033662907779216766,
            0.023635953664779663,
            0.003193238750100136,
            -0.000586672336794436,
            -0.02826882153749466,
            0.011824012733995914,
            -0.03811408206820488,
            -0.01704985834658146,
            0.011709880083799362,
            0.032567668706178665,
            -0.009307005442678928,
            -0.012504180893301964,
            -0.002499693538993597,
            -0.04096447303891182,
            -0.0011073221685364842,
            0.029856618493795395,
            0.0019358075223863125,
            0.025083066895604134,
            0.019493000581860542,
            -0.04016793146729469,
            0.021561896428465843,
            0.04854952543973923,
            0.0015983178745955229,
            0.025674212723970413,
            0.012633614242076874,
            -0.014024470001459122,
            -0.01910504139959812,
            0.03699081763625145,
            -0.005177103448659182,
            -0.0037899045273661613,
            -0.015600547194480896,
            -0.013938071206212044,
            0.0016508380649611354,
            -0.0721960961818695,
            0.008516685105860233,
            -0.028961004689335823,
            0.04438140243291855,
            -0.06807651370763779,
            0.0015896616969257593,
            0.009033577516674995,
            -0.11689874529838562,
            -0.0184208694845438,
            0.04551813378930092,
            0.023385807871818542,
            -0.021561499685049057,
            0.03314896672964096,
            -0.052087366580963135,
            0.02973322570323944,
            -0.005381435621529818,
            -0.036216314882040024,
            0.0069193169474601746,
            -0.031137805432081223,
            0.015072234906256199,
            -0.031074928119778633,
            0.03613906726241112,
            0.0254580806940794,
            0.012831520289182663,
            0.0029157998505979776,
            0.033516984432935715,
            0.032625891268253326,
            0.04634173586964607,
            -0.018987709656357765,
            -0.017897333949804306,
            -0.022358862683176994,
            0.02781221829354763,
            3.740486590686487e-06,
            0.017568357288837433,
            -0.10178539901971817,
            -0.00814127828925848,
            -0.013369614258408546,
            0.004644673317670822,
            0.02282455749809742,
            0.01620909571647644,
            0.019163822755217552,
            0.0011943535646423697,
            -0.013114353641867638,
            -0.02768198773264885,
            0.024264877662062645,
            0.004332047421485186,
            -0.018192682415246964,
            -0.007494606543332338,
            -0.011373277753591537,
            -0.009264077059924603,
            0.05495959892868996,
            0.025746367871761322,
            0.009446053765714169,
            0.008590063080191612,
            0.032274097204208374,
            0.0011808794224634767,
            -0.0021266669500619173,
            0.04266151785850525,
            -0.03826071694493294,
            0.00785854458808899,
            0.016608422622084618,
            0.016038881614804268,
            0.06275463849306107,
            0.01640559546649456,
            -0.005024237092584372,
            -0.0485234297811985,
            -0.023251304402947426,
            -0.007392478175461292,
            -0.016987990587949753,
            0.0298155564814806,
            -0.017160683870315552,
            -0.036132652312517166,
            -0.020277628675103188,
            0.01806437224149704,
            -0.040846746414899826,
            0.017211338505148888,
            0.019336996600031853,
            0.004615250509232283,
            0.018447227776050568,
            0.01713559404015541,
            -0.007450088392943144,
            -0.018429197371006012,
            0.019667353481054306,
            0.015316409058868885,
            -0.019387628883123398,
            3.909556471626274e-05,
            0.0010934988968074322,
            0.028846587985754013,
            0.008382209576666355,
            0.012528692372143269,
            0.013656719587743282,
            0.011273268610239029,
            -0.0016109419520944357,
            0.005874691065400839,
            0.03284670412540436,
            0.037000834941864014,
            0.05709617957472801,
            -0.008087280206382275,
            -0.0005511139752343297,
            -0.036987051367759705,
            0.012047902680933475,
            0.012340366840362549,
            -0.019520079717040062,
            0.04422697424888611,
            0.0029298877343535423,
            -0.012051536701619625,
            -0.0023873536847531796,
            -0.012810629792511463,
            -0.017100315541028976,
            -0.0023973374627530575,
            0.03175174444913864,
            -0.017359232529997826,
            -0.015500523149967194,
            -0.02463098242878914,
            -0.006340329069644213,
            -0.029300296679139137,
            0.030715569853782654,
            -0.052189674228429794,
            -0.03517439216375351,
            0.0024741673842072487,
            0.0032445613760501146,
            -0.013485695235431194,
            0.03223850578069687,
            -0.0211468655616045,
            -0.010216815397143364,
            0.031147776171565056,
            -0.016509883105754852,
            0.02508191205561161,
            -0.032265469431877136,
            0.002499239519238472,
            -0.009063642472028732,
            0.007022308185696602,
            -0.029918478801846504,
            0.026383928954601288,
            -0.024586455896496773,
            0.006352631840854883,
            -0.01089460588991642,
            -0.02408541552722454,
            -0.030421625822782516,
            0.03507808595895767,
            -0.0005285590887069702,
            0.0001051208091666922,
            -0.02926596812903881,
            -0.05424897372722626,
            0.016435950994491577,
            0.028426239266991615,
            0.0063185314647853374,
            0.019498666748404503,
            -0.02002747356891632,
            0.00010514088353374973,
            0.01557324267923832,
            -0.01060953177511692,
            -0.01661529205739498,
            -0.005336401052772999,
            -0.019225597381591797,
            -0.028505852445960045,
            -0.024594957008957863,
            -0.026059379801154137,
            -0.01901787705719471,
            0.035451680421829224,
            -0.02462831325829029,
            -0.033822838217020035,
            0.011523443274199963,
            -0.015030479058623314,
            0.0012571198167279363,
            -0.029829008504748344,
            0.03128578141331673,
            0.0029971611220389605,
            -0.03448765352368355,
            0.02411911077797413,
            0.0005203196196816862,
            0.0009087103535421193,
            0.038932543247938156,
            -0.0038665183819830418,
            0.015739548951387405,
            0.013957195915281773,
            -0.02685657888650894,
            0.0224665068089962,
            -0.0038836803287267685,
            -0.02639816887676716,
            -0.040400680154561996,
            -0.05045875906944275,
            0.03025423176586628,
            -0.01486209873110056,
            0.032674361020326614,
            -0.0060029239393770695,
            0.006055076140910387,
            0.02699558436870575,
            0.033083632588386536,
            -0.0289837084710598,
            -0.007908991537988186,
            -0.006212944630533457,
            -0.015249854885041714,
            0.013954522088170052,
            -0.021766284480690956,
            -0.01672842539846897,
            0.026226263493299484,
            0.010862844996154308,
            -0.04750347509980202,
            0.01818895898759365,
            -0.012622128240764141,
            -0.03464708477258682,
            0.0223550945520401,
            0.027449579909443855,
            -0.007395198568701744,
            -0.02706657536327839,
            -0.016902683302760124,
            0.03256687521934509,
            -0.011860024183988571,
            -0.011439215391874313,
            0.010048162192106247,
            0.010688230395317078,
            0.022084970027208328,
            -0.008317893370985985,
            -0.0057394723407924175,
            -0.004590255673974752,
            -0.030468644574284554,
            0.00020110627519898117,
            -0.024008654057979584,
            -0.03184747323393822,
            0.02717592567205429,
            0.010297581553459167,
            0.03202535957098007,
            0.03711243346333504,
            0.013108162209391594,
            -0.02040015161037445,
            0.02615857496857643,
            0.04089799150824547,
            0.04708167165517807,
            0.008758324198424816,
            0.03319745510816574,
            -0.025167573243379593,
            -0.01285631489008665,
            0.004076061304658651,
            0.010563241317868233,
            0.006082736887037754,
            0.007646725047379732,
            -0.017019599676132202,
            -0.023861432448029518,
            -0.0006987123051658273,
            -0.012492544949054718,
            0.013456761837005615,
            -0.02796247974038124,
            0.037971023470163345,
            -0.028432920575141907,
            0.024173034355044365,
            0.025431791320443153,
            -0.009756024926900864,
            0.03044244274497032,
            -0.003867436433210969,
            -0.008203188888728619,
            0.020775219425559044,
            -0.02363026887178421,
            0.01556880958378315,
            -0.022358596324920654,
            -0.043490536510944366,
            0.014513373374938965,
            -0.03565841168165207,
            -0.01507839560508728,
            -0.008036568760871887,
            0.06630517542362213,
            0.027864404022693634,
            0.024895254522562027,
            -0.006414100062102079,
            0.017174387350678444,
            0.002792973071336746,
            -0.009337368421256542,
            0.0466308668255806,
            0.026143759489059448,
            0.0053152781911194324,
            -0.03144613280892372,
            -0.016292529180645943,
            -0.007166027557104826,
            0.03745195269584656,
            -0.012987857684493065,
            -0.009366323240101337,
            -0.07633313536643982,
            0.012931467965245247,
            0.0033599415328353643,
            0.031009171158075333,
            -0.000992433400824666,
            -0.020011892542243004,
            0.019994940608739853,
            0.006826289929449558,
            -0.019635235890746117,
            -0.058608509600162506,
            0.026500333100557327,
            -0.03484232351183891,
            -0.0011986425379291177,
            -0.02075103297829628,
            0.0021436663810163736,
            0.009528686292469501,
            0.011045290157198906,
            0.0013800981687381864,
            -0.004726612474769354,
            -0.0036414973437786102,
            -0.02381352335214615,
            0.009376193396747112,
            0.011582991108298302,
            0.026159068569540977,
            0.025744576007127762,
            -0.03407472372055054,
            0.012512970715761185,
            -0.07526138424873352,
            -0.00887246523052454,
            0.003463001688942313,
            0.027156434953212738,
            -0.019391704350709915,
            -0.012952866032719612,
            0.0007092806044965982,
            -0.040578681975603104,
            -0.027138616889715195,
            0.025126144289970398,
            -0.027990330010652542,
            -0.011270062997937202,
            0.010395798832178116,
            -0.026843585073947906,
            0.03794657438993454,
            -0.041646987199783325,
            -0.015377177856862545,
            -0.016722673550248146,
            -0.01843377947807312,
            0.022324224933981895,
            -0.03225034847855568,
            -0.028078120201826096,
            0.023300377652049065,
            0.007115840446203947,
            0.015666985884308815,
            0.022933823987841606,
            0.019847624003887177,
            -0.052105166018009186,
            -0.014329900965094566,
            -0.0448378250002861,
            -0.05394464731216431,
            -0.010083241388201714,
            0.0404859334230423,
            -0.02671598084270954,
            -0.010280154645442963,
            -0.025764193385839462,
            0.02401706390082836,
            0.0029405062086880207,
            0.002371782436966896,
            -0.055614762008190155,
            -0.0012624505907297134,
            -0.02603275328874588,
            -0.007619881071150303,
            -0.05333230271935463,
            -0.009283645078539848,
            -0.061690859496593475,
            0.006998065393418074,
            -0.015378240495920181,
            0.03412836790084839,
            0.00750451534986496,
            -0.030034637078642845,
            -0.017663029953837395,
            -0.01717119850218296,
            -0.012404747307300568,
            -0.0225161612033844,
            -0.013289963826537132,
            -0.009067739360034466,
            -0.0806390568614006,
            -0.010656162165105343,
            -0.003901595016941428,
            -0.0019916926976293325,
            0.0225794930011034,
            0.025987740606069565,
            -0.02017322927713394,
            0.01291003730148077,
            -0.036437734961509705,
            0.018006514757871628,
            -0.011451981030404568,
            0.02522190846502781,
            0.007992776110768318,
            0.027416007593274117,
            0.04622811824083328,
            0.025934165343642235,
            -0.0697401687502861,
            0.010996517725288868,
            -0.03742867335677147,
            -0.015226558782160282,
            -0.010036278516054153,
            -0.01750815473496914,
            0.04561612010002136,
            -0.03299901261925697,
            0.0286877378821373,
            -0.006601892877370119,
            0.03829182684421539,
            0.004864207468926907,
            0.04192686453461647,
            0.012530188076198101,
            -0.023591844365000725,
            0.006230844184756279,
            -0.031898848712444305,
            -0.046596460044384,
            0.019064757972955704,
            0.009999793022871017,
            0.027973376214504242,
            -0.01765403524041176,
            0.007936373353004456,
            -0.014922023750841618,
            0.0017228629440069199,
            -0.05067923292517662,
            -0.03948485478758812,
            -0.02463539130985737,
            0.011012877337634563,
            0.00989995151758194,
            -0.0612756609916687,
            -0.012003116309642792,
            0.03285827115178108,
            -0.016108395531773567,
            0.0312940739095211,
            0.043222516775131226,
            -0.005474718287587166,
            0.02137601561844349,
            0.025385616347193718,
            -0.02579384110867977,
            -0.009482569061219692,
            0.014211522415280342,
            0.007427096366882324,
            -0.02964480221271515,
            0.021269850432872772,
            -0.01417317520827055,
            0.003513794392347336,
            0.010249278508126736,
            0.0460038036108017,
            0.015684159472584724,
            -0.01209135539829731,
            -0.0014472402399405837,
            -0.006419848185032606,
            0.0034590705763548613,
            -0.00778410816565156,
            0.010170968249440193,
            -0.014836063608527184,
            -0.018923498690128326,
            -0.014093607664108276,
            -0.007577195763587952,
            -0.014156199060380459,
            0.010041313245892525,
            0.0064172279089689255,
            0.00562877906486392,
            0.03460758924484253,
            0.02875356376171112,
            -0.017305925488471985,
            -0.017028681933879852,
            0.025365890935063362,
            0.0019894936122000217,
            0.03923596069216728,
            0.031613849103450775,
            0.030975231900811195,
            0.04109807312488556,
            -0.026571258902549744,
            0.016319088637828827,
            0.0094546964392066,
            -0.004873331170529127,
            -0.03531482815742493,
            0.006443928927183151,
            0.004143145866692066,
            -0.019554773345589638,
            0.017981115728616714,
            0.013059419579803944,
            -0.03326774016022682,
            -0.04755188152194023,
            0.013397580944001675,
            0.005354773253202438,
            0.06721644848585129,
            0.005642716772854328,
            0.015384582802653313,
            0.010140957310795784,
            0.009166372939944267,
            0.004671904724091291,
            -0.00451332051306963,
            -0.021524056792259216,
            0.022475305944681168,
            -0.018123367801308632,
            0.06262881308794022,
            -0.03181104734539986,
            -0.007703227456659079,
            -0.015424505807459354,
            -0.01415895577520132,
            0.008905441500246525,
            0.01891065016388893,
            0.02307811751961708,
            -0.05796455591917038,
            -0.03553313389420509,
            -0.09765734523534775,
            0.026943955570459366,
            -0.0032164892181754112,
            0.0045712292194366455,
            -0.008376042358577251,
            0.011998307891190052,
            0.017380978912115097,
            -0.019143525511026382,
            -0.08824146538972855,
            -0.04779012128710747,
            -0.00628225551918149,
            -0.007436245679855347,
            -0.01505391113460064,
            -0.02603372000157833,
            -0.01635010540485382,
            0.033336788415908813,
            -0.0703091099858284,
            -0.032988425344228745,
            0.030455108731985092,
            -0.013126236386597157,
            -0.03241075575351715,
            -0.03250553458929062,
            0.008229694329202175,
            -0.009738529101014137,
            0.02202504128217697,
            0.0036366807762533426,
            -0.029118899255990982,
            -0.0061357105150818825,
            0.03612503036856651,
            0.029072515666484833,
            -0.025358667597174644,
            0.027306729927659035,
            0.027218308299779892,
            -0.026889584958553314,
            0.015145931392908096,
            -0.03925199434161186,
            0.009998601861298084,
            0.011361238546669483,
            0.008494026958942413,
            -0.006605503149330616,
            0.005081804469227791,
            0.02245529368519783,
            0.0013144206022843719,
            0.015078154392540455,
            -0.02565653622150421,
            0.034930113703012466,
            0.027844401076436043,
            0.029282892122864723,
            -0.01420589443296194,
            -0.01000387966632843,
            0.006402796134352684,
            0.001120854401960969,
            -0.00492986710742116,
            -0.020343830808997154,
            0.012611446902155876,
            0.02621084824204445,
            -0.006203874945640564,
            -0.029447825625538826,
            0.01025527622550726,
            -0.013032897375524044,
            0.03819175437092781,
            -0.05387632176280022,
            0.01852886565029621,
            0.03204194828867912,
            0.050018310546875,
            0.039056338369846344,
            0.02170482464134693,
            0.00762079656124115,
            -0.016250908374786377,
            -0.06601139903068542,
            0.004166693892329931,
            -0.013915634714066982,
            -0.027708515524864197,
            -0.007059528026729822,
            -0.013764072209596634,
            0.015670739114284515,
            -0.01653057150542736,
            0.007972274906933308,
            -0.015536741353571415,
            -0.023391438648104668,
            0.016217181459069252,
            -0.01583697646856308,
            -0.016149938106536865,
            -0.026557989418506622,
            0.03684700280427933,
            0.03822598233819008,
            -0.025629818439483643,
            0.01605912856757641,
            -0.01608271710574627,
            -0.07272358983755112,
            0.03481941297650337,
            -0.0025672067422419786,
            -0.020504333078861237,
            -0.002870420925319195,
            0.010512025095522404,
            0.016290687024593353,
            0.012035995721817017,
            -0.002451108768582344,
            0.02190340869128704,
            0.007095284294337034,
            -0.03373797982931137,
            -0.017936481162905693,
            -0.01139091607183218,
            0.01833745650947094,
            -0.047871485352516174,
            -0.006916332058608532,
            -0.026016734540462494,
            -0.013795564882457256,
            0.026504648849368095,
            -0.006947407964617014,
            0.0250418558716774,
            -0.0010607285657897592,
            -0.002639496698975563,
            -0.006880529224872589,
            0.022183308377861977,
            0.0003249654546380043,
            0.009992046281695366,
            0.020093943923711777,
            0.03019501455128193,
            -0.0108973802998662,
            -0.00815427117049694,
            0.03330773487687111,
            -0.029136892408132553,
            0.014558501541614532,
            -0.003886514576151967,
            0.004935860633850098,
            -0.013679228723049164
        ],
        [
            -0.008940157480537891,
            0.004376786760985851,
            0.022893570363521576,
            -0.015601979568600655,
            0.027839358896017075,
            0.015032784081995487,
            0.027541188523173332,
            0.029690373688936234,
            -0.025535479187965393,
            0.0010140730300918221,
            -0.0036737690679728985,
            0.003963389899581671,
            -0.023992691189050674,
            -0.021118244156241417,
            0.028956709429621696,
            -0.05580360069870949,
            0.02243131212890148,
            0.0378660224378109,
            -0.008942268788814545,
            0.02842075563967228,
            -0.022082315757870674,
            0.00353724742308259,
            -0.05805546045303345,
            -0.025821229442954063,
            -0.024598082527518272,
            0.03803194314241409,
            -0.015719499439001083,
            -0.018553752452135086,
            0.003807482775300741,
            -0.035475317388772964,
            0.01289951242506504,
            0.024187905713915825,
            0.014883754774928093,
            0.018636493012309074,
            0.0029169090557843447,
            0.007550310809165239,
            0.006695152726024389,
            -0.02393173612654209,
            0.021672876551747322,
            0.008034199476242065,
            -0.016047164797782898,
            -0.028925472870469093,
            0.015033883973956108,
            -0.008320104330778122,
            -0.04491638019680977,
            -0.013014348223805428,
            0.014576195739209652,
            -0.006798640359193087,
            -0.013990399427711964,
            0.02017439529299736,
            0.009972672909498215,
            0.004526123870164156,
            -0.025185447186231613,
            -0.009410704486072063,
            0.00697978213429451,
            0.013340374454855919,
            -0.04661558195948601,
            -0.021108683198690414,
            -0.024929463863372803,
            0.01927255094051361,
            -0.03436895087361336,
            -0.0015074082184582949,
            -0.0015949233202263713,
            0.02411816641688347,
            0.004688421729952097,
            0.013463764451444149,
            -0.04084424674510956,
            -0.032480739057064056,
            -0.027125898748636246,
            -0.003075230633839965,
            -0.03183799982070923,
            -0.02381676435470581,
            -0.031911738216876984,
            -0.018381979316473007,
            -0.005677265115082264,
            -0.020904311910271645,
            0.006505595985800028,
            0.024902040138840675,
            0.015899498015642166,
            -0.03202551603317261,
            -0.022118471562862396,
            -0.00798321608453989,
            0.026676496490836143,
            0.02035289630293846,
            -0.006592209916561842,
            0.022159753367304802,
            0.012259048409759998,
            -0.0023115205112844706,
            -0.0045591541565954685,
            0.008906478062272072,
            -0.02799540013074875,
            0.0014148023910820484,
            0.012659472413361073,
            -0.008959010243415833,
            -0.0075842346996068954,
            0.01688663475215435,
            -0.021794280037283897,
            0.02629503794014454,
            -0.025996264070272446,
            -0.001984946196898818,
            0.02355409599840641,
            -0.025339994579553604,
            -0.03774493187665939,
            0.019285449758172035,
            0.0008032919140532613,
            0.008721670135855675,
            -0.0035168637987226248,
            -0.001969805220142007,
            0.01365288719534874,
            0.02542542666196823,
            -0.012122243642807007,
            0.0025512075517326593,
            -0.02339201793074608,
            -0.019977059215307236,
            -0.02920982427895069,
            -0.008695732802152634,
            -0.015827050432562828,
            0.02740061655640602,
            -0.025618312880396843,
            0.032840050756931305,
            -0.011412051506340504,
            -0.0024343549739569426,
            0.05639678239822388,
            0.028579026460647583,
            0.01783781498670578,
            0.0059094177559018135,
            -0.00816364586353302,
            0.0047845118679106236,
            0.029491519555449486,
            -0.006202884018421173,
            -0.0057518198154866695,
            -0.027871455997228622,
            0.05540384724736214,
            0.02815501019358635,
            -0.027880914509296417,
            0.003378404537215829,
            0.020412784069776535,
            0.013576877303421497,
            0.0103985033929348,
            0.009127338416874409,
            -0.00885728932917118,
            -0.000928057765122503,
            0.014499309472739697,
            0.020298577845096588,
            -0.02064189873635769,
            -0.005242602434009314,
            -0.008821710012853146,
            0.0039926134049892426,
            0.020495682954788208,
            -0.03801698237657547,
            -0.027012581005692482,
            -0.03316333517432213,
            0.03744426742196083,
            -0.04474073648452759,
            -0.009700215421617031,
            -0.0062439218163490295,
            -0.014557326212525368,
            0.003838127013295889,
            -0.041703853756189346,
            -0.0323663204908371,
            -0.014988304115831852,
            0.02635183185338974,
            -0.029249683022499084,
            -0.029110794886946678,
            -0.023658741265535355,
            0.061923444271087646,
            0.0011162093142047524,
            0.0237226951867342,
            -0.03496991842985153,
            -0.021830972284078598,
            0.02929897978901863,
            0.04121106490492821,
            0.005745623726397753,
            -0.03191772475838661,
            -0.013825562782585621,
            -0.02090335451066494,
            -0.011032543145120144,
            -0.014703433029353619,
            -0.01190277375280857,
            -0.03870682790875435,
            0.028675230219960213,
            0.005697053391486406,
            0.018875960260629654,
            0.018729448318481445,
            -0.005315322894603014,
            0.025863097980618477,
            -0.010158028453588486,
            0.020905202254652977,
            0.02719547413289547,
            0.012331582605838776,
            -0.023651396855711937,
            -0.015833275392651558,
            0.004045917186886072,
            0.0032722079195082188,
            -0.02116110548377037,
            0.010708373971283436,
            -0.02895917370915413,
            0.01518160942941904,
            0.013979691080749035,
            -0.007513892371207476,
            -0.009585599415004253,
            -0.021278908476233482,
            0.003461477579548955,
            0.014584507793188095,
            -0.028381280601024628,
            1.8700684449868277e-05,
            0.010934799909591675,
            -0.024918464943766594,
            0.018169673159718513,
            -0.028955597430467606,
            0.01166538242250681,
            0.028934553265571594,
            -0.015055515803396702,
            -0.0044813379645347595,
            0.015574419870972633,
            0.022081710398197174,
            0.029858790338039398,
            0.020640071481466293,
            0.025763778015971184,
            0.011965911835432053,
            0.0028549141716212034,
            -0.007254742085933685,
            0.02718684822320938,
            -0.006006455980241299,
            -0.027394670993089676,
            0.03417724370956421,
            0.01378227211534977,
            0.014102712273597717,
            -0.030052771791815758,
            -0.002800910035148263,
            0.018061136826872826,
            0.023929210379719734,
            -0.005035877227783203,
            0.015868524089455605,
            -0.008621676824986935,
            0.024165570735931396,
            0.026537809520959854,
            -0.004112763796001673,
            -0.016246961429715157,
            -0.009517640806734562,
            0.021178603172302246,
            0.011174858547747135,
            0.02179352380335331,
            -0.002046148059889674,
            -0.00017235602717846632,
            -0.02370719611644745,
            0.025471700355410576,
            0.02365940622985363,
            -0.030787566676735878,
            -0.0029826757963746786,
            -0.012174207717180252,
            -0.019390856847167015,
            0.003584379330277443,
            0.0010047099785879254,
            -0.004300660453736782,
            0.024414584040641785,
            -0.02585008554160595,
            0.046762917190790176,
            0.021140089258551598,
            0.03038591332733631,
            0.007651942316442728,
            0.017069939523935318,
            -0.011018548160791397,
            -0.021253211423754692,
            0.006240785121917725,
            -0.0016914473380893469,
            0.03372979909181595,
            0.02186344563961029,
            0.019426332786679268,
            0.03913860768079758,
            0.013942304067313671,
            0.009787485934793949,
            -0.026144305244088173,
            0.0193866528570652,
            0.01834707334637642,
            0.025164276361465454,
            0.03703582286834717,
            -0.00732838548719883,
            -0.018971718847751617,
            0.02012496255338192,
            -0.01468015369027853,
            0.016274841502308846,
            -0.0033450426999479532,
            -0.050965096801519394,
            -0.004452841356396675,
            0.014279580675065517,
            -0.01796065829694271,
            -0.035142652690410614,
            0.016461089253425598,
            0.0037864288315176964,
            0.011957862414419651,
            -0.003953265026211739,
            0.0212811641395092,
            -0.02842896431684494,
            0.011240064166486263,
            -0.016932383179664612,
            0.01735231652855873,
            0.018254458904266357,
            0.018047675490379333,
            -0.007181344088166952,
            0.002910225884988904,
            0.008882216177880764,
            0.0005063218413852155,
            0.022263286635279655,
            -0.017785707488656044,
            -0.06713548302650452,
            -0.043907247483730316,
            0.006153474561870098,
            -0.00284734764136374,
            -0.04646748676896095,
            -0.023872599005699158,
            -0.00306526105850935,
            -0.02913499064743519,
            0.023315750062465668,
            0.011409049853682518,
            0.03261800855398178,
            -0.003997418098151684,
            -0.01907731592655182,
            0.011375321075320244,
            -0.024665841832756996,
            0.004612267948687077,
            0.028142772614955902,
            -0.018190724775195122,
            0.0014351586578413844,
            0.026184264570474625,
            0.03482269495725632,
            -0.04671483859419823,
            -0.006957666482776403,
            0.01492187101393938,
            0.01600782759487629,
            0.03615240007638931,
            0.023655017837882042,
            0.02242712862789631,
            0.00577535480260849,
            -0.029835890978574753,
            -0.011487293988466263,
            -0.011453411541879177,
            -0.03573024645447731,
            0.023600971326231956,
            0.019255541265010834,
            -0.021903913468122482,
            -0.004766652826219797,
            -0.051396943628787994,
            0.021013593301177025,
            0.0121512021869421,
            -0.02754647098481655,
            0.0027815462090075016,
            0.020796338096261024,
            0.01573745161294937,
            -0.040678396821022034,
            -0.04615440219640732,
            0.012951710261404514,
            0.046494729816913605,
            -0.023373393341898918,
            0.01378056313842535,
            0.005237929988652468,
            -0.017220623791217804,
            0.019711850211024284,
            -0.038657594472169876,
            -0.012850443832576275,
            -0.026793260127305984,
            0.000495138403493911,
            -0.01142155658453703,
            0.01356478687375784,
            -0.04207421839237213,
            -0.005051455460488796,
            -0.003953797742724419,
            0.028769413009285927,
            -0.03048154152929783,
            -0.006502890959382057,
            0.02772681601345539,
            -0.022800998762249947,
            -0.018537864089012146,
            0.014008895494043827,
            0.0040857200510799885,
            0.0030067849438637495,
            -0.026766445487737656,
            -0.0075022028759121895,
            0.025733698159456253,
            -0.021776162087917328,
            -0.004632372874766588,
            -0.025636708363890648,
            -0.002186115365475416,
            0.007087358273565769,
            -0.009042705409228802,
            0.008757849223911762,
            -0.038417767733335495,
            0.02427441067993641,
            -0.03002539649605751,
            0.018808716908097267,
            -0.004983389750123024,
            -0.03177958354353905,
            0.02100522816181183,
            0.03398891165852547,
            -0.029507912695407867,
            0.0349334180355072,
            0.025242282077670097,
            0.011183365248143673,
            0.01452137716114521,
            -0.04685894772410393,
            -0.025549253448843956,
            -0.014543258585035801,
            -0.005229962058365345,
            0.025683216750621796,
            0.026702580973505974,
            0.009367896243929863,
            -0.020361319184303284,
            -0.0242194551974535,
            -0.021682854741811752,
            0.02437836304306984,
            0.004981333389878273,
            -0.0019121667137369514,
            0.002559162676334381,
            -0.023598400875926018,
            0.015085507184267044,
            -0.00864010863006115,
            0.02190523035824299,
            -0.007490058895200491,
            0.020268427208065987,
            0.017375173047184944,
            -0.015199048444628716,
            0.030220825225114822,
            -0.09510858356952667,
            0.02336977981030941,
            0.007079921662807465,
            -0.03138913959264755,
            0.02287997677922249,
            0.0526290163397789,
            0.0024376220535486937,
            0.014815756119787693,
            0.016329003497958183,
            -0.018446804955601692,
            0.008960403501987457,
            0.029111597687005997,
            0.025345049798488617,
            0.028362607583403587,
            0.0323416143655777,
            0.01162363588809967,
            0.02295800857245922,
            0.02365550957620144,
            -0.029458487406373024,
            -0.02622177265584469,
            0.012989426963031292,
            -0.02083529159426689,
            0.031021492555737495,
            -0.018062133342027664,
            -0.012584386393427849,
            -0.00844946876168251,
            -0.052386656403541565,
            -0.002757384441792965,
            0.02149093709886074,
            0.03392595425248146,
            -0.0010552253806963563,
            -0.029026901349425316,
            -0.014008831232786179,
            0.004877230152487755,
            -0.024344608187675476,
            -0.018255561590194702,
            0.025836292654275894,
            -0.023511242121458054,
            0.005027480889111757,
            0.00969572551548481,
            0.012148959562182426,
            -0.011036445386707783,
            0.02341368980705738,
            -0.014406188391149044,
            -0.017188332974910736,
            0.005894293077290058,
            -0.027476239949464798,
            0.012348774820566177,
            -0.008890697732567787,
            -0.01880241185426712,
            0.008518308401107788,
            0.0038255895487964153,
            -0.019136449322104454,
            -0.00813679862767458,
            -0.004117793403565884,
            -0.0025583100505173206,
            -0.01735915057361126,
            -0.0020979237742722034,
            -0.02336009591817856,
            0.03797009214758873,
            0.001000954769551754,
            -0.022580871358513832,
            0.0024961172603070736,
            0.03504752367734909,
            0.004598445259034634,
            0.028871499001979828,
            -0.06256189197301865,
            -0.025146478787064552,
            -0.02328156679868698,
            0.0008055861690081656,
            -0.02220107614994049,
            -0.016520434990525246,
            -0.03589232265949249,
            -0.000869940675329417,
            -0.042645376175642014,
            0.018357886001467705,
            0.01772421970963478,
            0.005871436093002558,
            -0.021689623594284058,
            -0.016392428427934647,
            0.025876766070723534,
            0.019938960671424866,
            -0.011328951455652714,
            -0.023565800860524178,
            -0.03321138769388199,
            -0.013054427690804005,
            0.010698922909796238,
            0.01801123470067978,
            -0.011563065461814404,
            0.03568767011165619,
            -0.009571779519319534,
            0.0077081709168851376,
            0.025433994829654694,
            0.02422291412949562,
            -0.03938329592347145,
            -0.018005315214395523,
            -0.014148230664432049,
            0.009461363777518272,
            -0.00045802659587934613,
            -0.01090338733047247,
            -0.01697736419737339,
            -0.001162179745733738,
            -0.04397931694984436,
            -0.012073637917637825,
            0.02057485282421112,
            0.017929518595337868,
            0.019972307607531548,
            0.0031317463144659996,
            0.001129012438468635,
            0.030487844720482826,
            0.03249037265777588,
            -0.0014886152930557728,
            0.019881149753928185,
            0.009203854948282242,
            -0.010887688025832176,
            0.017437627539038658,
            -0.04302247613668442,
            0.0014932279009371996,
            0.012179223820567131,
            -0.007506519556045532,
            -0.014417611062526703,
            0.02743276208639145,
            -0.0076372940093278885,
            -0.025243444368243217,
            0.02672416716814041,
            -0.025905534625053406,
            -0.001694733859039843,
            -0.015280463732779026,
            -0.004648567643016577,
            -0.006423466373234987,
            -0.008815224282443523,
            -0.007444144692271948,
            -0.01380956545472145,
            0.008537677116692066,
            -0.028341270983219147,
            -0.01869397796690464,
            0.02982807345688343,
            -0.00046398481936194,
            0.005143056623637676,
            -0.013726819306612015,
            0.025259461253881454,
            -0.00928333681076765,
            0.022467441856861115,
            -0.012470382265746593,
            -0.0005696445005014539,
            0.02087472379207611,
            0.029080074280500412,
            -0.009001736529171467,
            -0.0032145921140909195,
            -0.018589278683066368,
            -0.004703280981630087,
            0.02612730860710144,
            -0.0007070971769280732,
            -0.015280402265489101,
            -0.010978834703564644,
            0.009136674925684929,
            -0.024736762046813965,
            0.016016727313399315,
            -0.038474392145872116,
            0.02527841366827488,
            -0.02249003015458584,
            0.023009879514575005,
            0.011956563219428062,
            0.007228207308799028,
            -0.0002107511245412752,
            0.023397471755743027,
            0.0018047156045213342,
            0.03207441419363022,
            0.021180063486099243,
            -0.029998762533068657,
            0.01278725452721119,
            0.04854156821966171,
            0.026973901316523552,
            0.009040526114404202,
            -0.014906169846653938,
            0.006965777836740017,
            0.03263774886727333,
            0.029224185273051262,
            0.041630156338214874,
            0.02257615514099598,
            -0.0019477667519822717,
            -0.013136799447238445,
            -0.02248682640492916,
            -0.029795007780194283,
            -0.03278563171625137,
            -0.00570581154897809,
            -0.03495329990983009,
            -0.002380305901169777,
            0.01494699064642191,
            0.003367290599271655,
            0.015120765194296837,
            0.025311358273029327,
            0.044968485832214355,
            -0.0436181016266346,
            0.0324830487370491,
            -0.010071526281535625,
            0.011956456117331982,
            0.004374662879854441,
            -0.010942885652184486,
            0.029247544705867767,
            0.014153152704238892,
            -0.013189535588026047,
            -0.003968012984842062,
            0.011181254871189594,
            0.0002475522633176297,
            0.022164233028888702,
            0.005523559637367725,
            -0.030581466853618622,
            -0.02737792208790779,
            0.01571880653500557,
            -0.022085895761847496,
            -0.03204650804400444,
            0.027058668434619904,
            0.0007092226296663284,
            0.012069690972566605,
            -0.03681798651814461,
            0.009697330184280872,
            0.03173891082406044,
            0.008312181569635868,
            0.0012562369229272008,
            0.007577906828373671,
            -0.030423888936638832,
            0.02449200488626957,
            0.019125185906887054,
            -0.027696805074810982,
            0.014725647866725922,
            -0.014937203377485275,
            0.02625502645969391,
            0.025756623595952988,
            -0.018549570813775063,
            -0.02902967669069767,
            0.022518062964081764,
            0.006998077500611544,
            0.005133163183927536,
            0.043708983808755875,
            -0.021080872043967247,
            0.006473976653069258,
            0.008879792876541615,
            -0.009828612208366394,
            -0.03777999430894852,
            0.00657814322039485,
            -0.03647554665803909,
            0.028649568557739258,
            -0.025545591488480568,
            0.028882743790745735,
            -0.02124864235520363,
            0.026669848710298538,
            -0.02604861930012703,
            -0.032788652926683426,
            0.006456256378442049,
            0.007117889821529388,
            -0.004246694967150688,
            0.02667999267578125,
            -0.0026258693542331457,
            -0.001911425730213523,
            -0.009021777659654617,
            -0.02520744688808918,
            -0.020740490406751633,
            -0.05635635182261467,
            -0.007286600302904844,
            0.010834182612597942,
            0.007606554310768843,
            0.020351437851786613,
            -0.03677267208695412,
            0.03155062347650528,
            -0.01729580946266651,
            -0.014804160222411156,
            -0.0012914645485579967,
            -0.07315900921821594,
            -0.012733942829072475,
            -0.021477526053786278,
            -0.01637248322367668,
            -0.01850329153239727,
            0.006723274476826191,
            0.025106726214289665,
            0.02839033491909504,
            -0.0038564142305403948,
            -0.007924728095531464,
            0.030478352680802345,
            -0.007800132501870394,
            -0.009828697890043259,
            -0.017370181158185005,
            0.02514493651688099,
            0.032374024391174316,
            0.02671886794269085,
            -0.009919627569615841,
            -0.02765640802681446,
            0.006488709710538387,
            0.007821972481906414,
            0.0038885127287358046,
            -0.01341208629310131,
            0.014068549498915672,
            0.016816582530736923,
            -0.02044634148478508,
            0.0351640023291111,
            -0.02336742915213108,
            0.018272606655955315,
            0.0018385988660156727,
            -0.027639882639050484,
            -0.013874154537916183,
            0.01437826082110405,
            -0.003320920281112194,
            -0.024911800399422646,
            0.00374786788597703,
            0.0015801884001120925,
            0.01450407225638628,
            0.0014588646590709686,
            0.006618661340326071,
            0.013271372765302658,
            -0.018084445968270302,
            -0.05897466838359833,
            0.003013554261997342,
            0.01096839178353548,
            0.013769014738500118,
            -0.022324778139591217,
            -0.031083475798368454,
            0.021614806726574898,
            0.023778028786182404,
            0.017026560381054878,
            -0.035462886095047,
            0.01749565079808235,
            0.005294657312333584,
            0.03068874403834343,
            0.02634703740477562,
            0.0037300416734069586,
            -0.030470171943306923,
            -0.021899685263633728,
            0.02150958776473999,
            0.003195336554199457,
            0.03220377489924431,
            0.02261214330792427,
            0.017491599544882774,
            -0.005529940593987703,
            -0.008641967549920082,
            -0.02282736636698246,
            0.014812381938099861,
            -0.0070793810300529,
            -0.02584340050816536,
            0.01993328519165516,
            -0.04575576260685921,
            0.02169838920235634,
            -0.007275633048266172,
            -0.01795978657901287,
            0.008099586702883244,
            0.010671332478523254,
            0.014404497109353542,
            0.0021272057201713324,
            -0.02096816897392273,
            -0.030876776203513145,
            0.03701971098780632,
            -0.013987873680889606,
            -0.005616414826363325,
            -0.01893339492380619,
            -0.0031117540784180164,
            -0.0006030315416865051,
            -0.03721918910741806,
            -0.006114516872912645,
            0.017782602459192276,
            0.008691610768437386,
            0.0008705515065230429,
            -0.03826767951250076,
            -0.045753516256809235,
            -0.021009163931012154,
            -0.007580429315567017,
            -0.0018348103621974587,
            -0.01479248981922865,
            0.028650306165218353,
            0.045502983033657074,
            -0.030438436195254326,
            -0.019448235630989075,
            0.011846440844237804,
            0.04274032637476921,
            0.009000513702630997,
            0.0031128947157412767,
            -0.010570458136498928,
            -0.003747635753825307,
            -0.0170879103243351,
            -8.344479283550754e-05,
            0.022618815302848816,
            0.012157183140516281,
            -0.0024035105016082525,
            -0.026459358632564545,
            0.0028017323929816484,
            -0.006021983455866575,
            0.020662643015384674,
            -0.011911546811461449,
            0.008257132954895496,
            0.015854977071285248,
            -0.06115135923027992,
            -0.03648810088634491,
            -0.009641277603805065,
            -0.012592297047376633,
            0.018367519602179527,
            0.013939055614173412,
            0.029647139832377434,
            0.006681771483272314,
            0.004407948348671198,
            0.02180684544146061,
            0.004615445621311665,
            0.0019113024463877082,
            -0.003297043265774846,
            -0.004839815199375153,
            0.05699313059449196,
            -0.0016346259508281946,
            -0.025716545060276985,
            0.031269997358322144,
            0.012567967176437378,
            -0.027541575953364372,
            0.023478228598833084,
            -0.02764423005282879,
            -0.030212773010134697,
            -0.008458312600851059,
            -0.0427444651722908,
            0.012439010664820671,
            -0.015283511951565742,
            0.010232497937977314,
            -0.0017898698570206761,
            -0.041520118713378906,
            -0.016026776283979416,
            -0.013941727578639984,
            0.013168878853321075,
            -0.0035102800466120243,
            -0.021180544048547745,
            -0.013927036896348,
            -0.009016139432787895,
            -0.005146723706275225,
            -0.027236497029662132,
            0.02219756320118904,
            0.015899643301963806,
            0.01695418916642666,
            0.006815060041844845,
            0.011391731910407543,
            0.005009883549064398,
            0.026165157556533813,
            -0.019060399383306503,
            0.012857080437242985,
            -0.04127984866499901,
            0.014978093095123768,
            -0.02090565301477909,
            -0.02894763834774494,
            0.0017476279754191637,
            -0.010281551629304886,
            0.033284980803728104,
            0.012217449955642223,
            -0.007333127316087484,
            -0.038675591349601746,
            0.01974063739180565,
            -0.012085738591849804,
            -0.03618883341550827,
            -0.023689966648817062,
            0.0005277222371660173,
            0.0015677454648539424,
            0.008240992203354836,
            -0.0006058506551198661,
            0.007032574154436588,
            0.001114788232371211,
            -0.025420723482966423,
            -0.014070823788642883,
            -0.00885535217821598,
            -0.04308953881263733,
            -0.004176132380962372,
            0.02648673951625824,
            -0.02564742974936962,
            -0.03956533968448639,
            0.024972356855869293,
            -0.05441046133637428,
            -0.00035862840013578534,
            -0.026395346969366074,
            -0.04199742153286934,
            -0.033576857298612595,
            0.0002583918976597488,
            -0.024529296904802322,
            -0.003116912441328168,
            0.032710831612348557,
            -0.011672990396618843,
            -0.02307586558163166,
            -0.018586291000247,
            -0.024813564494252205,
            -0.017438814043998718,
            -0.00797282811254263,
            0.007303695194423199,
            0.012924803420901299,
            0.01684262976050377,
            0.023313026875257492,
            0.044064246118068695,
            0.02098567970097065,
            0.02736578695476055,
            -0.03365135192871094,
            0.01967117004096508,
            0.002117352094501257,
            0.003085901029407978,
            -0.0034042864572256804,
            7.801740866852924e-05,
            0.018973978236317635,
            -0.030254844576120377,
            -0.022140102460980415,
            0.0040176971815526485,
            -0.003946952521800995,
            -0.0204546507447958,
            0.015266316011548042,
            0.027068840339779854,
            -0.03366757184267044,
            -0.0929049402475357,
            0.02862577885389328,
            0.0170566588640213,
            -0.013612817972898483,
            0.014895308762788773,
            0.01724039763212204,
            0.02290314808487892,
            0.007966602221131325,
            0.008590285666286945,
            -0.027849230915308,
            -0.009431167505681515,
            -0.007104024291038513,
            -0.018954429775476456,
            -0.002244808943942189,
            -0.0009111497201956809,
            -0.03131410852074623,
            -0.01674805022776127,
            -0.047391247004270554,
            0.02069026045501232,
            0.03491945564746857,
            -0.020823074504733086,
            -0.0012132611591368914,
            -0.016615411266684532,
            0.009429912082850933,
            -0.03015916980803013,
            -0.03145456314086914,
            0.01710614748299122,
            0.028455443680286407,
            -0.022730695083737373,
            0.021169694140553474,
            -0.022714655846357346,
            0.010251997038722038,
            -0.01098489947617054,
            -0.020658910274505615,
            -0.00244085188023746,
            0.025720030069351196,
            0.036212190985679626,
            -0.032110076397657394,
            0.0016197850927710533,
            -0.028462110087275505,
            0.004047404043376446,
            -0.031458865851163864,
            0.015665708109736443,
            0.03734814375638962,
            -0.04148639366030693,
            -0.022889884188771248,
            0.02190333977341652,
            0.01121822651475668,
            0.0357511080801487,
            -0.014056388288736343,
            -0.023992668837308884,
            -0.029454434290528297,
            -0.0011367106344550848,
            0.006474758498370647,
            -0.0069177583791315556,
            0.011933283880352974,
            0.027406414970755577,
            0.02615547552704811,
            -0.03498482331633568,
            -0.004558735527098179,
            -0.015131146647036076,
            0.005000825505703688,
            0.02122315764427185,
            0.0026409095153212547,
            0.002679683966562152,
            -0.020806800574064255,
            0.020604202523827553,
            0.015944113954901695,
            0.017684267833828926,
            -0.02177233248949051,
            -0.011944611556828022,
            -0.032623667269945145,
            -0.03551865369081497,
            0.007626597303897142,
            0.006168236490339041,
            -0.005467661656439304,
            -0.009582358412444592,
            -0.026555705815553665,
            -0.011118875816464424,
            -0.02115621231496334,
            0.03031207248568535,
            0.02968287281692028,
            0.004358994774520397,
            -0.012705398723483086,
            0.012372750788927078,
            0.02706376649439335,
            -0.013285334222018719,
            -0.0025555207394063473,
            -0.007927116937935352,
            0.019862528890371323,
            0.013283193111419678,
            0.033083394169807434,
            -0.004928630776703358,
            -0.02540474757552147,
            0.03878488391637802,
            0.012046067044138908,
            -0.005235590972006321,
            -0.037114184349775314,
            0.012391057796776295,
            -0.0024819623213261366
        ],
        [
            -0.01675545610487461,
            -0.009336421266198158,
            0.02216697484254837,
            0.023614348843693733,
            -0.02287249267101288,
            0.00895386841148138,
            -0.03617377206683159,
            -0.03423989564180374,
            0.010227220132946968,
            0.015683434903621674,
            0.032774027436971664,
            -0.016362978145480156,
            0.00351900327950716,
            -0.02374316193163395,
            -0.033032678067684174,
            -0.02477901428937912,
            0.01254428643733263,
            -0.05192267522215843,
            -0.007663528900593519,
            -0.018861819058656693,
            -0.013411402702331543,
            0.006941553670912981,
            -0.05708472803235054,
            -0.008024829439818859,
            -0.025495924055576324,
            -0.01224545668810606,
            -0.0401419922709465,
            0.04030336067080498,
            -0.002246837131679058,
            -0.010527716018259525,
            0.027534494176506996,
            0.009009119123220444,
            0.005655202548950911,
            0.0035096078645437956,
            0.005820395424962044,
            0.0006221624207682908,
            -0.02084791660308838,
            0.012133548967540264,
            0.0064671640284359455,
            0.03290863707661629,
            0.0009183576330542564,
            0.029887137934565544,
            -0.003386808792129159,
            -0.009772500954568386,
            -0.0958496630191803,
            -0.032812755554914474,
            -0.011028477922081947,
            -0.02432524412870407,
            0.013020355254411697,
            -0.010754171758890152,
            0.02491118758916855,
            -0.004700107034295797,
            0.033641379326581955,
            -0.01366611197590828,
            0.049771856516599655,
            -0.0276251919567585,
            -0.004203164484351873,
            -0.01358531042933464,
            0.020579038187861443,
            -0.00881472509354353,
            -0.0004622403939720243,
            -0.022572673857212067,
            0.0014923934359103441,
            0.01693931594491005,
            0.034693725407123566,
            -0.018605437129735947,
            0.017750049009919167,
            -0.03610928729176521,
            0.01753252185881138,
            0.04458675906062126,
            0.020069751888513565,
            -0.012297924607992172,
            0.015213001519441605,
            -0.019770534709095955,
            0.014934621751308441,
            -0.018557973206043243,
            0.019670089706778526,
            -0.002359057078137994,
            -0.003140352899208665,
            -0.05421935021877289,
            0.011828101240098476,
            -0.04368812218308449,
            -0.009894349612295628,
            0.026988983154296875,
            0.011199234053492546,
            -0.002993944101035595,
            -0.038019564002752304,
            0.01702195778489113,
            -0.009529398754239082,
            0.043064236640930176,
            -0.02119498886168003,
            0.006792592350393534,
            0.0026753421407192945,
            -0.009828523732721806,
            0.013968649320304394,
            0.04565678536891937,
            -0.03508703410625458,
            -0.019421830773353577,
            -0.0754866823554039,
            0.028659868985414505,
            0.0033442615531384945,
            -0.04146423190832138,
            0.03116784058511257,
            -0.02405822090804577,
            -0.033678509294986725,
            -0.042596593499183655,
            -0.0010031990241259336,
            -0.0019095613388344646,
            0.03424285724759102,
            -0.006150873377919197,
            0.01371222734451294,
            0.007391282822936773,
            0.006335007958114147,
            -0.028179623186588287,
            0.011968816630542278,
            0.006667976267635822,
            -0.025797374546527863,
            -0.002448306418955326,
            -0.004624452441930771,
            0.02172238938510418,
            0.02004397101700306,
            0.009803756140172482,
            -0.03162625804543495,
            -0.033297330141067505,
            -0.01673961989581585,
            0.024427376687526703,
            -0.015006332658231258,
            -0.010593312792479992,
            -0.008269795216619968,
            -0.010355547070503235,
            -0.012447698973119259,
            0.021361174061894417,
            -0.03502349555492401,
            -0.017358463257551193,
            0.013442416675388813,
            -0.015706272795796394,
            0.0007434419821947813,
            -0.00480275833979249,
            0.015743881464004517,
            0.02703920379281044,
            0.01292742881923914,
            -0.014483225531876087,
            -0.006758854724466801,
            -0.010086331516504288,
            -0.006731259636580944,
            -0.014432386495172977,
            0.01433639321476221,
            -0.014396987855434418,
            0.006573690101504326,
            -0.017771374434232712,
            0.030252613127231598,
            -0.005340974777936935,
            -0.020152468234300613,
            -0.0019832253456115723,
            0.01437894906848669,
            0.019884908571839333,
            0.03193160891532898,
            -0.015177539549767971,
            -0.07624348253011703,
            0.004713139496743679,
            -0.012666902504861355,
            0.01792626827955246,
            -0.017469223588705063,
            0.002122472273185849,
            -0.047715719789266586,
            -0.05346759408712387,
            0.003256084630265832,
            0.005329361651092768,
            0.006023339927196503,
            0.0004533664614427835,
            0.03072803094983101,
            -0.026052717119455338,
            -0.005712056066840887,
            0.01739080622792244,
            -0.02764064446091652,
            -0.0052631027065217495,
            0.015755418688058853,
            0.0011499684769660234,
            0.017523087561130524,
            0.04848659038543701,
            -0.006990036927163601,
            0.016088642179965973,
            -0.024017872288823128,
            -0.03070652298629284,
            -0.03195427730679512,
            0.01367936097085476,
            -0.04424336552619934,
            -0.007860221900045872,
            0.0350329764187336,
            0.004292006604373455,
            0.01563619077205658,
            -0.001556763076223433,
            0.05690724775195122,
            -0.021716298535466194,
            -0.0321405865252018,
            0.024614930152893066,
            0.004460354335606098,
            0.025519056245684624,
            0.037068553268909454,
            0.014665966853499413,
            0.00565012451261282,
            -0.026066957041621208,
            0.07354412972927094,
            -0.007234147284179926,
            0.005863923579454422,
            0.016409046947956085,
            0.008029001764953136,
            -0.06733741611242294,
            -0.011547011323273182,
            -0.00445963442325592,
            0.03375763073563576,
            -0.019686343148350716,
            0.015384022146463394,
            0.014250417239964008,
            0.019161881878972054,
            0.019295495003461838,
            -0.013269892893731594,
            -0.019053824245929718,
            -0.0003126391093246639,
            -0.02502456121146679,
            0.0036623235791921616,
            0.04653417691588402,
            0.024439755827188492,
            -0.010814480483531952,
            0.004252727143466473,
            -0.08299543708562851,
            -0.0339520089328289,
            -0.03521190583705902,
            -0.017569061368703842,
            0.0005716501618735492,
            0.031862471252679825,
            0.026217404752969742,
            0.007925418205559254,
            0.005525748711079359,
            -0.002943853847682476,
            0.003551227506250143,
            0.0014844770776107907,
            -0.02645043656229973,
            0.013330960646271706,
            -0.002325876383110881,
            0.013626329600811005,
            0.020374665036797523,
            0.01208349410444498,
            -9.225576746985098e-09,
            -0.006402030121535063,
            0.014266431331634521,
            0.03216136619448662,
            0.013175598345696926,
            -0.028826424852013588,
            -0.028023075312376022,
            -0.022055702283978462,
            -0.039495136588811874,
            0.033274151384830475,
            -0.008944986388087273,
            -0.0051956940442323685,
            -0.030501866713166237,
            0.02424752339720726,
            0.01009010337293148,
            -0.0008879490196704865,
            -0.04859063774347305,
            -0.02509278617799282,
            -0.006577947176992893,
            -0.009181554429233074,
            0.020393425598740578,
            -0.02143971621990204,
            -0.014159233309328556,
            0.019503751769661903,
            0.023953193798661232,
            -0.03736231476068497,
            0.032876286655664444,
            0.0334230400621891,
            -0.00979845691472292,
            -0.008793579414486885,
            -0.014283415861427784,
            0.014213914051651955,
            -0.0016754401149228215,
            0.0009717386565171182,
            -0.03201894462108612,
            -0.001960957422852516,
            0.02148277498781681,
            0.022057075053453445,
            -0.017071329057216644,
            0.030301647260785103,
            -0.012376530095934868,
            -0.0018098998116329312,
            -0.020541254431009293,
            0.018849903717637062,
            0.033312518149614334,
            -0.025325486436486244,
            0.0069108023308217525,
            0.02168971300125122,
            -0.03055417537689209,
            0.005650188308209181,
            -0.0011909392196685076,
            0.01672118715941906,
            -0.01316898874938488,
            -0.016697969287633896,
            0.0046932026743888855,
            0.036579012870788574,
            0.014125986024737358,
            0.020095134153962135,
            -0.0007262288127094507,
            -0.03706096112728119,
            0.038866594433784485,
            0.009148145094513893,
            -0.05747056007385254,
            -0.020241616293787956,
            0.040759362280368805,
            0.02286454662680626,
            -0.04407484829425812,
            0.015445949509739876,
            -0.029255008324980736,
            0.00694494741037488,
            0.02474968694150448,
            -0.010264352895319462,
            -0.038697581738233566,
            0.020258275792002678,
            -0.00553844403475523,
            0.01869266852736473,
            -0.04199456423521042,
            0.027274660766124725,
            0.015293113887310028,
            -0.023101404309272766,
            0.02292378433048725,
            -0.01368376798927784,
            -0.04355031996965408,
            -0.04223344102501869,
            -0.0030242432840168476,
            0.03780665993690491,
            0.012251769192516804,
            0.01595371961593628,
            -0.04770907387137413,
            -0.02696838788688183,
            0.023156434297561646,
            0.013648979365825653,
            -0.023135123774409294,
            0.042140692472457886,
            -0.02942737564444542,
            0.009336618706583977,
            -0.04173194617033005,
            -0.053681548684835434,
            -0.007901636883616447,
            0.01761629618704319,
            -0.008320271037518978,
            0.011274104006588459,
            -0.0028210205491632223,
            0.03166411444544792,
            0.004506794735789299,
            -0.005455364007502794,
            -0.0016698309918865561,
            0.01207087840884924,
            0.04032805189490318,
            -0.022689376026391983,
            -0.005249598529189825,
            0.0356614850461483,
            0.02853976935148239,
            -0.02594710886478424,
            0.02767222560942173,
            0.0275944322347641,
            0.044521480798721313,
            -0.003867062274366617,
            -0.013502543792128563,
            -0.05048804357647896,
            -0.04510622099041939,
            0.008023920468986034,
            0.005523215513676405,
            0.029529789462685585,
            -0.016740476712584496,
            0.00023151117784436792,
            -0.02008601650595665,
            0.006295790895819664,
            0.004048048984259367,
            -0.008443263359367847,
            -0.005028726067394018,
            0.014295361004769802,
            -0.02033337391912937,
            0.03072500228881836,
            0.012047848664224148,
            -0.05423251911997795,
            0.03035554103553295,
            -0.020858323201537132,
            0.014816535636782646,
            0.016743367537856102,
            0.014499847777187824,
            -0.019240541383624077,
            0.03615085035562515,
            -0.00975119136273861,
            0.007039029151201248,
            0.02791536971926689,
            -0.006860092747956514,
            0.018522141501307487,
            -0.03743110969662666,
            -0.021339677274227142,
            0.01803862489759922,
            -0.0311647467315197,
            0.019306590780615807,
            -0.005336215253919363,
            0.01805303990840912,
            -0.013421710580587387,
            -0.020265651866793633,
            -0.019755959510803223,
            -0.034072477370500565,
            -0.03381781280040741,
            0.006703873630613089,
            0.02699226513504982,
            0.005218781065195799,
            0.02436978556215763,
            -0.049249228090047836,
            -0.03807245194911957,
            -0.0007270290516316891,
            -0.016520507633686066,
            -0.009714845567941666,
            -0.0086690504103899,
            0.024257641285657883,
            0.020796921104192734,
            0.0005741446511819959,
            0.012750646099448204,
            -0.0033599440939724445,
            -0.030112208798527718,
            0.00535033643245697,
            0.04074430838227272,
            -0.017105042934417725,
            -0.0835253968834877,
            0.03474609926342964,
            0.009144900366663933,
            -0.01824249140918255,
            -0.013291502371430397,
            0.018765198066830635,
            -0.03773638233542442,
            0.011662302538752556,
            0.02393157221376896,
            0.023011628538370132,
            0.017000092193484306,
            -0.0016759218415245414,
            0.0328047052025795,
            0.017658105120062828,
            0.040514085441827774,
            0.018883561715483665,
            -0.004756325855851173,
            -0.02334463968873024,
            -0.013281806372106075,
            0.003860719036310911,
            0.046164996922016144,
            -0.006129422225058079,
            0.0005568046472035348,
            0.010495423339307308,
            0.019561387598514557,
            0.042190466076135635,
            -0.012259118258953094,
            0.021959226578474045,
            -0.03768683969974518,
            0.019450034946203232,
            0.003979760222136974,
            -0.008534634485840797,
            0.03231663629412651,
            -0.03252692148089409,
            -0.0033390603493899107,
            -0.03575929254293442,
            0.03696132451295853,
            -0.03161222115159035,
            -0.0007218060782179236,
            0.030812181532382965,
            -0.05875605717301369,
            -0.032155636698007584,
            -0.011874246411025524,
            0.011126133613288403,
            -0.01862506754696369,
            -0.004899339750409126,
            0.018153050914406776,
            0.022859681397676468,
            -0.0154249994084239,
            -0.009996014647185802,
            -0.008932285942137241,
            -0.013651794753968716,
            0.02866537868976593,
            -0.007467087358236313,
            -0.03404562175273895,
            -0.01707710511982441,
            -0.02131882682442665,
            -0.01088234968483448,
            0.015134943649172783,
            -0.06543568521738052,
            0.059342436492443085,
            0.025044692680239677,
            -0.05317397788167,
            -0.010942121036350727,
            -0.004790040198713541,
            0.010986126028001308,
            0.012969641014933586,
            -0.022784274071455002,
            -0.018802585080266,
            0.01774471625685692,
            0.0037453356198966503,
            -0.012713147327303886,
            -0.07269085943698883,
            -0.022621797397732735,
            -0.0287272110581398,
            -0.007664911448955536,
            0.0012007851619273424,
            -0.04630206525325775,
            -0.012193015776574612,
            -0.01750081591308117,
            0.003096182132139802,
            0.0031819373834878206,
            0.02194431982934475,
            0.0315835066139698,
            0.004895003978163004,
            0.014556924812495708,
            0.0041343639604747295,
            -0.007961379364132881,
            -0.016246803104877472,
            0.024217523634433746,
            0.02795465663075447,
            0.005988882854580879,
            0.031800296157598495,
            0.0005091079510748386,
            0.02787593938410282,
            -0.01014954224228859,
            -0.019090840592980385,
            0.0064548016525805,
            0.006104316562414169,
            0.04091069847345352,
            0.02704714983701706,
            -0.02444634400308132,
            0.06343388557434082,
            0.07572281360626221,
            0.08436436951160431,
            -0.029715463519096375,
            0.04634217172861099,
            0.009613486006855965,
            -0.024173323065042496,
            0.01871413178741932,
            0.0010833118576556444,
            -0.007944312877953053,
            0.0108646834269166,
            0.017184698954224586,
            0.019698286429047585,
            0.010532007552683353,
            0.010458279401063919,
            -0.007238191552460194,
            -0.027473023161292076,
            -0.020009269937872887,
            0.016499914228916168,
            0.04024948924779892,
            0.0006264953408390284,
            0.0019945132080465555,
            -0.007410864345729351,
            0.01703098975121975,
            0.012688012793660164,
            0.009939078241586685,
            0.002184327458962798,
            0.021771609783172607,
            -0.01001744344830513,
            0.007400949019938707,
            0.017947006970643997,
            0.0157347209751606,
            -0.02560688555240631,
            0.014494961127638817,
            -0.023569492623209953,
            -0.003673285711556673,
            0.03155113011598587,
            0.04014374315738678,
            -0.023190069943666458,
            0.0022845491766929626,
            0.024066124111413956,
            0.027093179523944855,
            0.06332859396934509,
            0.0004387326189316809,
            0.03149505332112312,
            -0.015441158786416054,
            -0.018059102818369865,
            -0.0030004919972270727,
            0.0106875691562891,
            0.007323527242988348,
            -0.05994220823049545,
            -0.02213030867278576,
            -0.009758492931723595,
            0.05299223214387894,
            -0.00110531784594059,
            0.009534486569464207,
            -0.030710235238075256,
            -0.024687912315130234,
            0.011573477648198605,
            0.015216546133160591,
            -0.022783668711781502,
            0.011632469482719898,
            -0.014879869297146797,
            -0.0024173660203814507,
            5.871094617759809e-05,
            0.019112171605229378,
            0.015529584139585495,
            0.015663374215364456,
            -0.009034278802573681,
            -0.05659421905875206,
            0.02391406148672104,
            0.022418277338147163,
            0.023105783388018608,
            -0.0005340555217117071,
            -0.03246045857667923,
            -0.01855510286986828,
            0.04795053228735924,
            -0.030017876997590065,
            -0.00014983191795181483,
            0.022879719734191895,
            -0.03016333095729351,
            0.008154897019267082,
            -0.015794910490512848,
            0.006157682742923498,
            0.03570764884352684,
            -0.06127997487783432,
            0.02534785494208336,
            -0.021255139261484146,
            0.017046844586730003,
            0.004392622038722038,
            -0.03736201673746109,
            0.006831335369497538,
            0.021385621279478073,
            -0.0166050735861063,
            0.006951881572604179,
            -0.006114576943218708,
            0.04399503767490387,
            0.010748953558504581,
            -0.003910992294549942,
            0.0353776291012764,
            -0.016970044001936913,
            -0.008545040152966976,
            0.009620843455195427,
            -0.006009350530803204,
            0.014395256526768208,
            -0.04212917760014534,
            0.04893296957015991,
            0.0015154269058257341,
            0.004709947854280472,
            0.01676156185567379,
            0.057147081941366196,
            0.027464818209409714,
            0.0079397177323699,
            0.008782698772847652,
            0.029745519161224365,
            -0.01902129128575325,
            0.026711614802479744,
            -0.01644166000187397,
            0.014654976315796375,
            0.00846098456531763,
            -0.03174447640776634,
            -0.01565210148692131,
            0.046128612011671066,
            0.0054303123615682125,
            0.00243799714371562,
            -0.031539857387542725,
            0.03088291361927986,
            -0.0335075706243515,
            -0.006042967550456524,
            -0.021126149222254753,
            -0.010401361621916294,
            -0.036412179470062256,
            0.05148351937532425,
            0.024394527077674866,
            -0.03426515683531761,
            0.026724742725491524,
            -0.020209625363349915,
            0.008963435888290405,
            -0.013744737021625042,
            0.015217958949506283,
            -0.03316855803132057,
            -0.014906431548297405,
            -0.019834667444229126,
            -0.02321547083556652,
            0.0026818891055881977,
            0.01798946224153042,
            0.06452041864395142,
            0.03324218839406967,
            -0.028445741161704063,
            0.039213038980960846,
            0.009831511415541172,
            0.0038948527071624994,
            0.010245202109217644,
            -0.02520206943154335,
            0.00021877576364204288,
            0.031214889138936996,
            -0.015525110065937042,
            -0.015237207524478436,
            -0.011897550895810127,
            -0.026270434260368347,
            0.014554161578416824,
            -0.009687957353889942,
            0.03433005139231682,
            0.031371839344501495,
            0.01772778108716011,
            0.02017000876367092,
            0.015199369750916958,
            0.024757144972682,
            -0.0268553514033556,
            -0.012811222113668919,
            -0.0071487147361040115,
            0.020842578262090683,
            -0.01919715292751789,
            0.028561687096953392,
            -0.02593260444700718,
            -0.00990049634128809,
            0.006633169017732143,
            0.028154008090496063,
            0.026589445769786835,
            -0.033213961869478226,
            0.034661054611206055,
            -0.0016473158029839396,
            0.030321573838591576,
            -0.014244452118873596,
            0.012701842002570629,
            -0.011387868784368038,
            0.03102511540055275,
            0.02355431392788887,
            0.04246244952082634,
            0.031070023775100708,
            0.04146651551127434,
            -0.024125009775161743,
            -0.05786975845694542,
            0.005978146567940712,
            -0.0012388580944389105,
            -0.0252383965998888,
            0.028568673878908157,
            0.003914393950253725,
            -0.023194273933768272,
            -0.0240041371434927,
            -0.009987395256757736,
            -0.01733093336224556,
            -0.0024892434012144804,
            -0.014368690550327301,
            -0.028660809621214867,
            -0.016388246789574623,
            -0.030997326597571373,
            0.009526379406452179,
            -0.027086269110441208,
            -0.033012669533491135,
            -0.015750396996736526,
            0.027235431596636772,
            -0.03135480731725693,
            -0.020230064168572426,
            -0.006178538780659437,
            0.010979403741657734,
            0.006526749115437269,
            0.005813769996166229,
            0.02058454416692257,
            0.014800447970628738,
            -0.005799473728984594,
            -0.004427013918757439,
            0.07213395833969116,
            -0.0015874685486778617,
            -0.006383446045219898,
            0.009224039502441883,
            -0.0053294626995921135,
            -0.00623873807489872,
            0.005141504108905792,
            0.008629865944385529,
            -0.012493610382080078,
            0.010594059713184834,
            -0.04770558327436447,
            0.02189265564084053,
            0.006454371381551027,
            0.002670184476301074,
            -0.0032825765665620565,
            -0.0305625032633543,
            -0.030204234644770622,
            -0.018049489706754684,
            0.06323155760765076,
            0.007000452373176813,
            -0.004735725931823254,
            -0.014439557678997517,
            0.009154275059700012,
            0.03107171133160591,
            0.003661301452666521,
            -0.03325105458498001,
            -0.008021275512874126,
            0.03566853702068329,
            -0.027935735881328583,
            -0.008386185392737389,
            0.036497458815574646,
            0.07329069077968597,
            -0.017278889194130898,
            0.017497912049293518,
            0.009325014427304268,
            0.014150206930935383,
            -0.029360909014940262,
            -0.008160057477653027,
            0.029765862971544266,
            -0.027755944058299065,
            -0.027590740472078323,
            -0.03466566652059555,
            -0.019000966101884842,
            -0.01701156608760357,
            -0.0006166445673443377,
            0.013724292628467083,
            -0.03923752158880234,
            -0.000883869593963027,
            0.05696422979235649,
            -0.005553929600864649,
            -0.017115658149123192,
            -0.005244571249932051,
            -0.03536193072795868,
            -0.0160982646048069,
            -0.04100588336586952,
            0.0035913437604904175,
            -0.018544236198067665,
            0.005593649111688137,
            -0.004473332315683365,
            0.03940639644861221,
            0.015039704740047455,
            0.005779668688774109,
            -0.013561630621552467,
            -0.01829110085964203,
            0.01630222238600254,
            0.005126167554408312,
            -0.020063167437911034,
            0.01033798884600401,
            0.04343939200043678,
            0.0026888279244303703,
            -0.016552530229091644,
            0.016043318435549736,
            0.027778001502156258,
            0.004449378699064255,
            0.012544645927846432,
            0.007645713165402412,
            0.0034578440245240927,
            0.01749425008893013,
            -0.020169785246253014,
            0.032803915441036224,
            -0.03118433803319931,
            -0.01858423464000225,
            -0.0047628493048250675,
            -0.029020026326179504,
            -0.013330978341400623,
            -0.03176730126142502,
            0.03683094307780266,
            -0.025566479191184044,
            0.007045278325676918,
            0.003590498585253954,
            -0.01784888468682766,
            -0.012803456746041775,
            -0.002197789028286934,
            -0.010771747678518295,
            -0.005847718100994825,
            0.034628912806510925,
            -0.012590489350259304,
            -0.006054744590073824,
            -0.017042243853211403,
            0.012496101669967175,
            0.003628498874604702,
            0.05163167417049408,
            0.005917028523981571,
            0.011808888986706734,
            0.030159518122673035,
            -0.020311210304498672,
            -0.05182622745633125,
            -0.0021785765420645475,
            -0.0038993104826658964,
            0.023299535736441612,
            0.016708487644791603,
            -0.020014500245451927,
            0.04045123606920242,
            0.0014903981937095523,
            -0.009911878034472466,
            -0.0069602373987436295,
            -0.005668680649250746,
            -0.010104671120643616,
            -0.0003320406249258667,
            0.015430251136422157,
            -0.026264738291502,
            -0.009092841297388077,
            0.02481759339570999,
            0.03130226582288742,
            0.022935334593057632,
            -0.025183383375406265,
            0.060402654111385345,
            0.010962002910673618,
            -0.044393815100193024,
            -0.01070460770279169,
            -0.013389352709054947,
            0.04818369448184967,
            -0.015253905206918716,
            -0.029078971594572067,
            -0.009504871442914009,
            0.03330430015921593,
            0.0275406576693058,
            0.0008202600292861462,
            0.007312675006687641,
            0.011733793653547764,
            0.016901396214962006,
            -0.01293957605957985,
            -0.0011897009098902345,
            -0.012129710055887699,
            -0.05906553938984871,
            -0.017922891303896904,
            -0.010808901861310005,
            -0.019933195784687996,
            0.012022802606225014,
            0.02999301068484783,
            0.022224808111786842,
            0.005816694349050522,
            -0.015131412073969841,
            0.0018097322899848223,
            -0.05964239314198494,
            0.014858923852443695,
            -0.03617452085018158,
            -0.02160044200718403,
            -0.0031351735815405846,
            0.011258439160883427,
            0.0272535253316164,
            -0.021054526790976524,
            0.018536143004894257,
            0.005632568150758743,
            -0.0015845304587855935,
            0.012812326662242413,
            0.02371278963983059,
            -0.015854327008128166,
            0.01971133053302765,
            -0.004311099648475647,
            0.03706197813153267,
            -0.013481400907039642,
            0.01546892337501049,
            0.011924313381314278,
            -0.021767692640423775,
            0.004124902654439211,
            0.03257434815168381,
            -0.008313609287142754,
            -0.005480763968080282,
            0.018125031143426895,
            -0.02311987802386284,
            0.011621375568211079,
            -0.022116433829069138,
            0.014338802546262741,
            -0.004566456191241741,
            -0.009061298333108425,
            0.006175866350531578,
            -0.02886778488755226,
            0.002620948478579521,
            0.008018462918698788,
            -0.020570596680045128,
            0.01857769303023815,
            -0.007414042018353939,
            0.022778553888201714,
            0.013073134236037731,
            0.0002893402997869998,
            0.05202438309788704,
            -0.014624389819800854,
            -0.0005932854837737978,
            -0.027928316965699196,
            -0.024030273780226707,
            0.02045643888413906,
            -0.02577846683561802,
            0.004162999335676432,
            0.03745398670434952,
            -0.02181500382721424,
            0.012756365351378918,
            -0.0005378653295338154,
            -0.017584199085831642,
            -0.0557856485247612,
            -0.016017360612750053,
            0.012250160798430443,
            0.03959007188677788,
            0.029219407588243484,
            -0.024685103446245193,
            0.011343218386173248,
            0.0337151437997818,
            -0.021060016006231308,
            0.009270195849239826,
            -0.03489036485552788,
            0.004091428592801094,
            0.009073717519640923,
            0.026213951408863068,
            -0.0018110820092260838,
            0.0018910784274339676,
            -0.0023781033232808113,
            -0.005877130199223757,
            0.024692339822649956,
            -0.01748080551624298,
            -0.03319988772273064,
            -0.04269828647375107,
            0.006184173747897148,
            0.018922720104455948,
            0.028429288417100906,
            -0.00773638067767024,
            0.04134777560830116,
            0.02865554764866829,
            -0.0378839448094368,
            -0.008336972445249557,
            -0.004951170179992914,
            -0.020948726683855057,
            0.02373810112476349,
            -0.007722440175712109,
            -0.03963577002286911,
            -0.018369898200035095,
            0.01117713376879692,
            0.0056523894891142845,
            -0.001739726634696126,
            0.037024110555648804,
            -0.007238489110022783,
            0.022809958085417747,
            -0.0032420605421066284,
            -0.036612704396247864,
            -0.02704882249236107,
            -0.0320950523018837,
            -0.021262122318148613,
            0.019163884222507477,
            -0.01830054074525833,
            0.038847871124744415,
            -0.01040851604193449,
            -0.02770838886499405,
            -0.017306646332144737,
            0.020737387239933014,
            0.007144120987504721,
            -0.014289287850260735,
            -0.03626333549618721,
            0.024701055139303207,
            -0.0679195448756218,
            0.0017886299174278975,
            0.03406950458884239,
            0.01652008295059204,
            -0.039267394691705704,
            -0.0011229696683585644,
            0.006300528068095446,
            0.0012143668718636036,
            0.02998087927699089,
            0.010950235649943352,
            -0.012147527188062668
        ],
        [
            -0.006009906996041536,
            -0.044446855783462524,
            0.04766468331217766,
            0.005821387283504009,
            0.02179848589003086,
            -0.09421248733997345,
            0.015653185546398163,
            -0.024639569222927094,
            -0.02493080124258995,
            -0.017741432413458824,
            0.009756636805832386,
            -0.004351103212684393,
            -0.0057381391525268555,
            -0.006798171903938055,
            -0.015453011728823185,
            0.004875100217759609,
            0.04890529438853264,
            -0.010616902261972427,
            0.010942460969090462,
            -0.0012029738863930106,
            0.006538719870150089,
            0.030401557683944702,
            -0.007030598819255829,
            -0.007912896573543549,
            0.02110946923494339,
            -0.02299504727125168,
            -0.017511924728751183,
            -0.009833310730755329,
            -0.05974810570478439,
            0.0023782639764249325,
            0.018059324473142624,
            -0.018169062212109566,
            0.004385422449558973,
            -0.01161765307188034,
            0.021933382377028465,
            0.03106697089970112,
            -0.014026195742189884,
            -0.013370959088206291,
            0.035706598311662674,
            0.0012829442275688052,
            -0.008454911410808563,
            -0.04087429121136665,
            0.002289067255333066,
            0.036515455693006516,
            0.01133252028375864,
            -0.026386119425296783,
            0.00022809379152022302,
            -0.021477600559592247,
            -0.021750226616859436,
            -0.010624703019857407,
            0.0037004463374614716,
            -0.029300715774297714,
            0.03632325306534767,
            0.028235457837581635,
            -0.03889096900820732,
            0.019278526306152344,
            -0.009625284932553768,
            -0.013131116516888142,
            0.01704023964703083,
            -0.00831870548427105,
            0.017641104757785797,
            -0.0264593455940485,
            -0.04451952129602432,
            -0.02002214640378952,
            -0.005401840899139643,
            -0.03304344043135643,
            -0.024397356435656548,
            -0.03232378140091896,
            0.02785152569413185,
            0.005836219526827335,
            -0.011739227920770645,
            0.012641636654734612,
            0.010261910036206245,
            0.02784275822341442,
            -0.02279624156653881,
            0.04445935785770416,
            -0.01673223450779915,
            0.04448693245649338,
            -0.0024101948365569115,
            -0.017712116241455078,
            -0.024568019434809685,
            0.020184531807899475,
            0.01281511690467596,
            0.02771182172000408,
            0.020711908116936684,
            0.010522152297198772,
            -0.01359931193292141,
            -0.008317997679114342,
            0.04015906900167465,
            0.017583539709448814,
            -0.02514488808810711,
            -0.04258083179593086,
            0.036524541676044464,
            0.03166193515062332,
            0.02042490988969803,
            0.009868374094367027,
            -0.020590659230947495,
            0.043794043362140656,
            0.0023266577627509832,
            0.012067537754774094,
            0.002319861203432083,
            -0.022467324510216713,
            0.026985956355929375,
            0.000334063166519627,
            0.014462403953075409,
            0.009653421118855476,
            0.005733486730605364,
            0.004422503989189863,
            0.05814364179968834,
            -0.018743395805358887,
            -0.002144098049029708,
            0.027283383533358574,
            0.022201336920261383,
            -0.038160666823387146,
            -0.016241444274783134,
            0.025819577276706696,
            -0.0480891689658165,
            0.016524169594049454,
            -0.027811553329229355,
            -0.05115393549203873,
            -0.012215642258524895,
            0.019054237753152847,
            -0.013331295922398567,
            -0.012746537104249,
            0.008761858567595482,
            0.014068540185689926,
            -0.040789924561977386,
            -0.02320006489753723,
            -0.009242801927030087,
            0.015548693016171455,
            0.004264144226908684,
            -0.03232107311487198,
            0.03651265799999237,
            -0.0015048625646159053,
            -0.013303994201123714,
            -0.00019737679394893348,
            -0.02176056243479252,
            0.05557210370898247,
            0.013082842342555523,
            -0.021038658916950226,
            -0.03360021114349365,
            -0.03124791570007801,
            -0.052401088178157806,
            0.009540620259940624,
            0.019638502970337868,
            -0.022698666900396347,
            -0.022369520738720894,
            0.020450040698051453,
            -0.011397520080208778,
            -0.03166009858250618,
            -0.0455826073884964,
            -0.02257724665105343,
            0.03963569551706314,
            -0.06668318808078766,
            9.368584142066538e-05,
            -0.0095027144998312,
            -0.0752016082406044,
            -0.03977646678686142,
            0.006924481131136417,
            -0.0716041699051857,
            -0.041957270354032516,
            -0.03560830280184746,
            -0.02197394333779812,
            -0.0048621101304888725,
            0.006176012102514505,
            -0.05197262763977051,
            0.035856712609529495,
            -0.0005888130399398506,
            0.04988834634423256,
            -0.0005749008851125836,
            -0.06178279593586922,
            0.016130350530147552,
            0.00904154498130083,
            -0.049068935215473175,
            0.014162260107696056,
            0.023203818127512932,
            -0.05204201862215996,
            0.02251330204308033,
            0.010246053338050842,
            -0.013058084063231945,
            -0.0001761308085406199,
            -0.007846380583941936,
            -0.003763357177376747,
            -0.01770772598683834,
            -0.04254449903964996,
            -0.006638977210968733,
            -0.06710729748010635,
            -0.03600156679749489,
            -0.005066707730293274,
            -0.018765222281217575,
            0.06401075422763824,
            -0.03857489675283432,
            0.021382365375757217,
            -0.02296214923262596,
            -0.02232777141034603,
            -0.00956115685403347,
            -0.04105554148554802,
            -0.012625291012227535,
            -0.030014097690582275,
            -0.03314780071377754,
            -0.05252157524228096,
            0.001947440905496478,
            -0.004333213437348604,
            -0.01604355126619339,
            0.040218986570835114,
            -0.0006098479498177767,
            -0.0928976833820343,
            -0.0006864776369184256,
            0.008403677493333817,
            0.010012634098529816,
            0.009466427378356457,
            0.031340546905994415,
            0.043133173137903214,
            0.01621437445282936,
            0.033686570823192596,
            -0.04102158918976784,
            0.0015992755070328712,
            0.01957031525671482,
            -0.004156953655183315,
            -0.003941912669688463,
            -0.03602719306945801,
            0.015059067867696285,
            -0.037195730954408646,
            0.038284782320261,
            -0.01345267053693533,
            0.002378184348344803,
            0.039366841316223145,
            0.02157105877995491,
            -0.010927117429673672,
            -0.03324173390865326,
            0.010578298009932041,
            -0.01499188132584095,
            0.01100067887455225,
            -0.020667320117354393,
            0.025184454396367073,
            -0.02434179186820984,
            -0.028636334463953972,
            0.005278547760099173,
            -0.03315962478518486,
            0.026376405730843544,
            -0.019453905522823334,
            -0.02113872952759266,
            -0.01048340555280447,
            -0.003222527215257287,
            0.022616755217313766,
            -0.030738400295376778,
            -0.02683395706117153,
            -0.02927601896226406,
            0.024288207292556763,
            -0.022284479811787605,
            0.044002898037433624,
            0.025885844603180885,
            0.03898618370294571,
            -0.008441237732768059,
            0.02740783430635929,
            -0.0018930535297840834,
            -0.03545155003666878,
            -0.013685067184269428,
            0.04724382609128952,
            -0.03485052287578583,
            -0.0315631739795208,
            0.01309463195502758,
            0.020727332681417465,
            -0.05224021151661873,
            0.02487071231007576,
            0.0693608745932579,
            0.022057628259062767,
            0.01120816171169281,
            -0.02197432331740856,
            0.036833345890045166,
            -0.045991938561201096,
            -0.0077167050912976265,
            0.00832428503781557,
            0.008467940613627434,
            0.028980890288949013,
            -0.008241291157901287,
            0.01956568844616413,
            0.020727770403027534,
            0.0051742796786129475,
            -0.07132602483034134,
            -0.008197321556508541,
            -0.010481798090040684,
            -0.0263493824750185,
            0.016372637823224068,
            0.006178932264447212,
            -0.050484076142311096,
            0.0029645576141774654,
            -0.005739586893469095,
            -0.01741970144212246,
            0.026959508657455444,
            0.037565793842077255,
            0.03344053030014038,
            0.03269018977880478,
            0.028367485851049423,
            -0.019870061427354813,
            0.05881873518228531,
            0.012886063195765018,
            0.007507849019020796,
            0.02837877906858921,
            -0.007593998685479164,
            -0.01532742939889431,
            -0.03398029878735542,
            -0.02375214546918869,
            0.00022363547759596258,
            -0.06733089685440063,
            -0.07123637944459915,
            -0.015441825613379478,
            -0.0076993051916360855,
            -0.07105497270822525,
            -0.001900275470688939,
            0.04545357823371887,
            -0.030531248077750206,
            0.005039991810917854,
            -0.03409548103809357,
            -0.052061811089515686,
            0.019006572663784027,
            -0.02127525955438614,
            -0.03693944960832596,
            -0.013871235772967339,
            -0.022424696013331413,
            0.017349036410450935,
            0.045412976294755936,
            0.014817100018262863,
            -0.04853776842355728,
            0.010158305056393147,
            -0.019250400364398956,
            -0.05143355205655098,
            -0.04131404310464859,
            -0.008064894005656242,
            0.0258504506200552,
            -0.014298261143267155,
            -0.013285098597407341,
            0.0315399095416069,
            0.0028893069829791784,
            0.014698912389576435,
            -0.018106916919350624,
            -0.045005153864622116,
            -0.016691287979483604,
            0.006954953074455261,
            0.032232776284217834,
            0.02221636287868023,
            0.014200743287801743,
            0.022949006408452988,
            -0.030680393800139427,
            0.009049084037542343,
            -0.029092544689774513,
            -0.02538636513054371,
            0.02295212633907795,
            0.017719537019729614,
            -0.014603964053094387,
            0.029871614649891853,
            0.03502573445439339,
            -0.02583542838692665,
            0.008193686604499817,
            0.05299922451376915,
            -0.04310128465294838,
            0.004330581985414028,
            0.022830374538898468,
            -0.023664740845561028,
            0.006146842148154974,
            0.023228958249092102,
            -0.02718380279839039,
            -0.060013867914676666,
            0.03213462233543396,
            -0.06727883219718933,
            -0.02568976767361164,
            0.02798100747168064,
            0.015391044318675995,
            -0.008092199452221394,
            -0.015556134283542633,
            -0.008041190914809704,
            -0.02438940480351448,
            -0.05622442811727524,
            0.04073726758360863,
            -0.018566953018307686,
            0.01120663434267044,
            -0.007661136332899332,
            -0.030396688729524612,
            0.008249827660620213,
            0.015038837678730488,
            -0.04689585417509079,
            0.025794023647904396,
            0.0242050401866436,
            -0.05051887035369873,
            0.035783667117357254,
            -0.0045294733718037605,
            -0.019177809357643127,
            -0.053226497024297714,
            0.020079270005226135,
            0.0500357411801815,
            -0.0014381085056811571,
            0.019253110513091087,
            0.00868587289005518,
            -0.02830309048295021,
            0.059598565101623535,
            0.025360383093357086,
            -0.015264102257788181,
            -0.02807718701660633,
            0.029622750356793404,
            -0.02758103795349598,
            0.007391833234578371,
            0.04550982266664505,
            0.00861912127584219,
            -0.018073922023177147,
            0.03340300917625427,
            0.02956552430987358,
            0.010477018542587757,
            -0.08295625448226929,
            0.013052316382527351,
            -0.0007834093994461,
            0.025318415835499763,
            -0.04863845184445381,
            -0.012321232818067074,
            -0.002645275555551052,
            0.0017013088800013065,
            0.02000829577445984,
            -0.053630419075489044,
            -0.0046522472985088825,
            -0.0855506882071495,
            -0.01784726046025753,
            0.06253079324960709,
            0.0009492436656728387,
            0.029860178008675575,
            -0.00301717733964324,
            -0.010823981836438179,
            -0.021592773497104645,
            0.0242091566324234,
            0.03263125941157341,
            0.013150837272405624,
            0.02026030793786049,
            -0.029134755954146385,
            0.019821375608444214,
            -0.0011842302046716213,
            -0.05556430295109749,
            0.02857188694179058,
            0.02837512642145157,
            0.010260489769279957,
            -0.004519645590335131,
            -0.0036885475274175406,
            -0.042023055255413055,
            0.02326747588813305,
            0.006995569448918104,
            0.029288114979863167,
            0.044246625155210495,
            -0.0015328392619267106,
            0.011605349369347095,
            0.007419108878821135,
            0.03579998388886452,
            -0.05552076920866966,
            0.008632906712591648,
            -0.0020102609414607286,
            -0.021245354786515236,
            -0.09873709827661514,
            0.008993377909064293,
            -0.015200214460492134,
            0.007253083400428295,
            0.04086746647953987,
            0.03791080415248871,
            -0.0153025658801198,
            0.0011238782899454236,
            -0.014303936623036861,
            0.016769422218203545,
            -0.003759189508855343,
            -0.013673901557922363,
            0.0463731549680233,
            -0.021229499951004982,
            -0.02938520722091198,
            0.05016343668103218,
            -0.008534735068678856,
            0.0007178454543463886,
            0.030706806108355522,
            -0.00840577483177185,
            0.024860944598913193,
            0.006612601224333048,
            0.015579157508909702,
            -0.011254602111876011,
            -0.021392425522208214,
            0.061837539076805115,
            -0.02658631093800068,
            0.0594961978495121,
            0.025498270988464355,
            0.04672713577747345,
            -0.024605462327599525,
            -0.017061177641153336,
            -0.023158736526966095,
            0.0023385686799883842,
            -0.0347658172249794,
            -0.010422056540846825,
            0.08960234373807907,
            -0.08454390615224838,
            0.0391496866941452,
            -0.01907876506447792,
            -0.00030211330158635974,
            0.04964219406247139,
            0.04137439653277397,
            -0.020891793072223663,
            -0.048039164394140244,
            0.038770806044340134,
            -0.0237074326723814,
            -0.024943094700574875,
            -0.011487197130918503,
            -0.03516347333788872,
            0.016134150326251984,
            -0.08098743110895157,
            -0.037187669426202774,
            0.012260430492460728,
            0.00349185848608613,
            -0.05897655710577965,
            0.04841527342796326,
            0.027919724583625793,
            -0.01828962191939354,
            0.032377272844314575,
            0.002097630174830556,
            -0.011267084628343582,
            -0.010806874372065067,
            0.005620990414172411,
            0.0014996347017586231,
            0.003922558855265379,
            -0.004293361678719521,
            -0.0034585490357130766,
            0.042241424322128296,
            -0.021368129178881645,
            -0.04910947382450104,
            0.028568409383296967,
            0.0275883786380291,
            0.044704731553792953,
            0.06495070457458496,
            0.018499581143260002,
            -0.01609509252011776,
            -0.03591024503111839,
            0.03742193803191185,
            0.008468563668429852,
            -0.03251112997531891,
            -0.015841258689761162,
            0.03729713708162308,
            0.02482444979250431,
            0.0007968488498590887,
            -0.00785374827682972,
            -0.06482786685228348,
            -0.02949235588312149,
            -0.016148485243320465,
            0.014077316969633102,
            0.016962984576821327,
            -0.0006249870057217777,
            -0.002658367622643709,
            0.022509761154651642,
            -0.008878156542778015,
            0.02606421895325184,
            -0.0023082660045474768,
            -0.03172866627573967,
            0.029666438698768616,
            -0.005614289548248053,
            -0.0381900854408741,
            -0.021992191672325134,
            0.04228576272726059,
            0.03384669870138168,
            -0.022154631093144417,
            -0.0013121316442266107,
            -0.05443909391760826,
            -0.015048825182020664,
            0.03741581737995148,
            -0.02193574793636799,
            0.010428361594676971,
            -0.029107674956321716,
            -0.011236581951379776,
            0.012605693191289902,
            0.018890516832470894,
            0.03550122305750847,
            0.007890885695815086,
            -0.015806155279278755,
            -0.018305998295545578,
            0.005765158217400312,
            -0.022588280960917473,
            0.03865472972393036,
            0.017457550391554832,
            0.04561129957437515,
            -0.08773361146450043,
            0.016492730006575584,
            0.019459428265690804,
            -0.002957344753667712,
            -0.03404036909341812,
            0.02045571431517601,
            -0.01689792610704899,
            -0.020939787849783897,
            0.027207082137465477,
            0.049051545560359955,
            -0.011701129376888275,
            -0.004473250824958086,
            -0.04737770929932594,
            0.008067987859249115,
            -0.02613416686654091,
            -0.011643507517874241,
            -0.021674009039998055,
            -0.009691125713288784,
            0.022282809019088745,
            0.026816602796316147,
            0.03147022798657417,
            0.01884607970714569,
            -0.06672423332929611,
            0.0037884453777223825,
            0.012933246791362762,
            -0.024310212582349777,
            -0.010782601311802864,
            -0.011985227465629578,
            0.02479315549135208,
            -0.02007431536912918,
            0.0005558310658670962,
            -0.03061855584383011,
            -0.04049791395664215,
            -0.04045695438981056,
            -0.041939496994018555,
            0.005348478443920612,
            -0.015668530017137527,
            -0.022939959540963173,
            -0.028348224237561226,
            -0.05111424997448921,
            -0.032452456653118134,
            -0.07263150066137314,
            -0.0024312252644449472,
            0.016349460929632187,
            0.003277310635894537,
            0.0175587460398674,
            -0.0227406844496727,
            0.02650219202041626,
            -0.006422003731131554,
            -0.022787706926465034,
            -0.02730884775519371,
            0.005401106085628271,
            0.036548271775245667,
            0.008280718699097633,
            -0.016049383208155632,
            0.02194424532353878,
            -0.0030712110456079245,
            0.004517574328929186,
            -0.0610743872821331,
            0.023618945851922035,
            0.01870000548660755,
            -0.10641033202409744,
            -0.01601852849125862,
            -0.02122793160378933,
            -0.02579992450773716,
            -0.08405335992574692,
            -0.021656090393662453,
            0.02897615171968937,
            -0.003399160923436284,
            -0.010521586053073406,
            -0.057171594351530075,
            -0.017017925158143044,
            0.015933511778712273,
            -0.007469569332897663,
            0.022093918174505234,
            -0.030048219487071037,
            0.01731056533753872,
            -0.006079840939491987,
            -0.10902341455221176,
            -0.0057358769699931145,
            0.06759089231491089,
            -0.034956999123096466,
            0.008693434298038483,
            -0.021398505195975304,
            0.01915905624628067,
            -0.015966424718499184,
            -0.023958072066307068,
            -0.09315744787454605,
            0.02308380976319313,
            -0.018259361386299133,
            0.02745058201253414,
            0.008420090191066265,
            -0.006401869002729654,
            -0.0004410922701936215,
            0.0064119501039385796,
            -0.0005465184221975505,
            -0.040125876665115356,
            -0.01857001893222332,
            -0.04836389422416687,
            0.003148261457681656,
            -0.034827202558517456,
            -0.018421847373247147,
            0.029242314398288727,
            -0.06067031994462013,
            -0.05194809287786484,
            -0.014031286351382732,
            -0.03014236129820347,
            0.015998709946870804,
            -0.04548779875040054,
            -0.027001427486538887,
            -0.0726524367928505,
            0.00935871247202158,
            0.005796533543616533,
            0.03408345580101013,
            -0.04359205439686775,
            -0.03435265272855759,
            0.004011852666735649,
            0.015594332478940487,
            -0.016446789726614952,
            -0.023658057674765587,
            0.004954687785357237,
            0.016784140840172768,
            0.02989945374429226,
            0.02155814878642559,
            0.03768623620271683,
            0.052797939628362656,
            -0.023122841492295265,
            -0.04219135642051697,
            0.02600688859820366,
            0.03505970910191536,
            -0.043190550059080124,
            -0.005425755865871906,
            0.025712845847010612,
            0.038498375564813614,
            0.019964540377259254,
            -0.00733985286206007,
            0.005516663659363985,
            -0.017506618052721024,
            0.012951787561178207,
            -0.027613842859864235,
            0.018800554797053337,
            0.015644386410713196,
            -0.0021370002068579197,
            -0.029088366776704788,
            0.058530163019895554,
            -0.016820084303617477,
            -0.04064774513244629,
            -0.004662865772843361,
            0.018648069351911545,
            -0.01716727763414383,
            0.003591965651139617,
            0.0051650735549628735,
            0.02296443283557892,
            0.017446495592594147,
            0.016358554363250732,
            0.03529120236635208,
            0.0013534848112612963,
            -0.027367278933525085,
            -0.009800068102777004,
            -0.020182523876428604,
            -0.0117091815918684,
            -0.04205147922039032,
            -0.005455993581563234,
            0.02325764298439026,
            0.02666178159415722,
            -0.012870379723608494,
            -0.07567963749170303,
            0.023707469925284386,
            -0.02293245866894722,
            -0.016209853813052177,
            0.07243428379297256,
            -0.041255705058574677,
            -0.03407349810004234,
            0.00259092403575778,
            -0.0019254513317719102,
            -0.016088314354419708,
            0.0023883816320449114,
            -0.023674560710787773,
            0.031801581382751465,
            -0.02454664558172226,
            -0.03732689097523689,
            0.031954195350408554,
            0.0025542955845594406,
            -0.026575222611427307,
            -0.011380440555512905,
            0.009473429061472416,
            -0.039872124791145325,
            0.018285606056451797,
            0.000595398829318583,
            0.027556627988815308,
            0.021432919427752495,
            -0.0035390309058129787,
            -0.004614129662513733,
            0.01964941993355751,
            0.005434972234070301,
            -0.0020251828245818615,
            0.015174330212175846,
            -0.017399180680513382,
            -0.0026847857516258955,
            0.015589164569973946,
            -0.03842593729496002,
            0.048695310950279236,
            0.02409304492175579,
            -0.05932069942355156,
            0.008033365942537785,
            -0.0004419406468514353,
            -0.03261655941605568,
            0.009479998610913754,
            -0.007194294594228268,
            -0.02123008295893669,
            0.05122877657413483,
            -0.007242799736559391,
            0.03269515186548233,
            0.017773540690541267,
            0.026585280895233154,
            -0.028247663751244545,
            -0.012684077955782413,
            -0.07085820287466049,
            0.02932191826403141,
            -0.09395669400691986,
            -0.052459605038166046,
            0.016738180071115494,
            5.926163339609047e-06,
            0.0026248630601912737,
            -0.0018036386463791132,
            -0.0075120883993804455,
            0.0037282162811607122,
            0.010086989030241966,
            0.0058571286499500275,
            -0.011454666033387184,
            -0.015190337784588337,
            0.02169044129550457,
            -0.034578364342451096,
            0.020021740347146988,
            0.004457198083400726,
            -0.025600792840123177,
            -0.021421916782855988,
            -0.016528131440281868,
            0.010610353201627731,
            0.03758036717772484,
            -0.035528089851140976,
            -0.04946976155042648,
            0.029667740687727928,
            0.03893686830997467,
            -0.03556030988693237,
            -0.019108474254608154,
            -0.03566395118832588,
            0.0271557979285717,
            0.050222594290971756,
            -0.04842935502529144,
            0.0023265520576387644,
            0.0014004878466948867,
            -0.020899059250950813,
            -0.01661277934908867,
            -0.006908367853611708,
            -0.06007980555295944,
            0.006486179307103157,
            -0.012431529350578785,
            -0.05053451284766197,
            0.010869289748370647,
            0.004992200061678886,
            -0.036778006702661514,
            -0.010963794775307178,
            0.021419543772935867,
            -0.02225806564092636,
            -0.009358636103570461,
            0.024233181029558182,
            -0.028305763378739357,
            -0.027015913277864456,
            -0.025046229362487793,
            -0.07858840376138687,
            0.012734400108456612,
            -0.0343099944293499,
            -0.012616666033864021,
            -0.006485986988991499,
            -0.027254339307546616,
            0.043337251991033554,
            -0.014733071438968182,
            -0.025138771161437035,
            0.001835978007875383,
            -0.018331391736865044,
            -0.024934159591794014,
            0.01365637592971325,
            0.02149961143732071,
            -0.061493124812841415,
            -0.07510096579790115,
            0.011767708696424961,
            -0.008760575205087662,
            0.04930896311998367,
            -0.02271948754787445,
            0.011128322221338749,
            0.015051654540002346,
            -0.0016961334040388465,
            0.013007908128201962,
            -0.022506309673190117,
            -0.040259603410959244,
            -0.041278768330812454,
            0.03245880827307701,
            -0.03076162561774254,
            -0.0577537827193737,
            -0.008964903652668,
            0.029624881222844124,
            -0.037477102130651474,
            -0.006039164960384369,
            0.012096606194972992,
            -0.03775786608457565,
            0.006155379116535187,
            -0.05511770769953728,
            0.061891987919807434,
            -0.08677061647176743,
            0.016423599794507027,
            -0.058435454964637756,
            0.03823139891028404,
            0.018827468156814575,
            -0.035521000623703,
            0.017283394932746887,
            0.03177284821867943,
            -0.029526226222515106,
            0.010402576997876167,
            0.043285153806209564,
            -0.013699788600206375,
            0.07083823531866074,
            0.018366463482379913,
            -0.03426632657647133,
            -0.05076014623045921,
            -0.04520425572991371,
            0.008533370681107044,
            -0.028450874611735344,
            -0.02637074887752533,
            -0.004481526091694832,
            -0.02710712142288685,
            0.01771617867052555,
            0.008116173557937145,
            0.006048684008419514,
            0.008762087672948837,
            0.02227145805954933,
            -0.02445744350552559,
            -0.00011150716454721987,
            0.02436421439051628,
            0.010625374503433704,
            0.03504977747797966,
            -0.023954257369041443,
            -0.022445330396294594,
            0.0016618523513898253,
            -0.0026555133517831564,
            0.0388038195669651,
            0.025849508121609688,
            0.03203544020652771,
            -0.06491443514823914,
            0.03541912883520126,
            -0.014663102105259895,
            -0.04133163392543793,
            -0.01992800645530224,
            0.007685375865548849,
            0.03186509385704994,
            0.03862728178501129,
            0.015687476843595505,
            -0.0058825453743338585,
            0.0454622283577919,
            -0.015743257477879524,
            -0.03805282711982727,
            -0.03369364142417908,
            -0.01099964790046215,
            -0.008226260542869568,
            -0.025904228910803795,
            -0.02411649376153946,
            0.022405870258808136,
            0.01765909418463707,
            0.007952988147735596,
            0.029117409139871597,
            -0.014027074910700321,
            -0.04923947900533676,
            0.03927221894264221,
            0.010722094215452671,
            0.028068801388144493,
            -0.03866798058152199,
            -0.009772337973117828,
            -0.013693107292056084,
            -0.038468796759843826,
            0.009005249477922916,
            -0.031032107770442963,
            0.010334748774766922,
            -0.003701169975101948,
            0.02403447777032852,
            0.007328808307647705,
            0.039387837052345276,
            -0.004054245073348284,
            -0.09035098552703857,
            -0.005656412336975336,
            0.027609119191765785,
            0.0017952292691916227,
            -0.02889665961265564,
            -0.004341396503150463,
            -0.028557252138853073,
            -0.020902030169963837,
            0.02772965468466282,
            0.017163541167974472,
            -0.012048241682350636,
            -0.022467313334345818,
            -0.032251205295324326,
            0.027987677603960037,
            0.0017125105950981379,
            -0.009257052093744278,
            -0.013092849403619766,
            0.013399267569184303,
            -0.019424190744757652,
            0.04408350959420204,
            -0.03348684683442116,
            0.026334257796406746,
            -0.021231379359960556,
            -0.04190927743911743,
            -0.01103759091347456,
            -0.0010219773976132274,
            0.011270594783127308,
            -0.029727963730692863,
            0.0033265016973018646,
            0.021617678925395012,
            -0.02388652414083481,
            -0.004736822564154863,
            0.04056387022137642,
            0.05061976611614227,
            0.03199642524123192,
            -0.014788970351219177,
            0.02533882111310959,
            -0.01779136247932911,
            -0.01699232868850231,
            -0.03161752596497536,
            -0.02235003001987934,
            0.021844498813152313,
            -0.007687119767069817,
            -0.022700795903801918,
            0.02309182845056057,
            -0.014464488252997398,
            -0.03732176497578621,
            -0.0058636050671339035,
            0.03309198096394539,
            -0.038273345679044724,
            -0.0021995410788804293,
            -0.02284916117787361,
            0.018205048516392708,
            0.04088227450847626,
            -0.03271215409040451,
            -0.003877566196024418,
            0.027399038895964622,
            0.005757309030741453,
            -0.026404235512018204,
            -0.020246505737304688,
            0.042565345764160156
        ],
        [
            -0.018330885097384453,
            -0.00919589027762413,
            -0.014284956268966198,
            -0.01902456395328045,
            -0.014811423607170582,
            -8.04493174655363e-06,
            0.03927474841475487,
            -0.10470028221607208,
            0.0040343101136386395,
            -0.006533399689942598,
            -0.005788004491478205,
            -0.025735652074217796,
            -0.0845412090420723,
            0.016251079738140106,
            -0.020301304757595062,
            0.02041846700012684,
            -0.011226664297282696,
            0.03751622512936592,
            0.025150751695036888,
            0.024548303335905075,
            0.0060674757696688175,
            -0.025434982031583786,
            -0.1061796173453331,
            -0.029796086251735687,
            -0.026606924831867218,
            0.041687868535518646,
            0.04661601036787033,
            -0.007863795384764671,
            -0.009470733813941479,
            -0.039041705429553986,
            0.00818498432636261,
            -0.031056959182024002,
            -0.019698994234204292,
            0.04374607652425766,
            -0.02476533129811287,
            -0.000939572521019727,
            -0.004050785209983587,
            0.029678355902433395,
            0.029851151630282402,
            -0.01841193065047264,
            0.003515321295708418,
            -0.01211896724998951,
            -0.014267271384596825,
            -0.0047797225415706635,
            -0.016403598710894585,
            0.0035948771983385086,
            -0.025664620101451874,
            0.008292566984891891,
            0.017121458426117897,
            -0.00813170988112688,
            -0.03202500939369202,
            0.02158142812550068,
            0.007387543562799692,
            -0.055257201194763184,
            -0.038782551884651184,
            0.014686929993331432,
            -0.06538131833076477,
            -0.040594104677438736,
            -0.038905613124370575,
            -0.014817832037806511,
            -0.02500719577074051,
            -0.0472363643348217,
            0.038999348878860474,
            0.034233033657073975,
            -0.021129002794623375,
            0.01893603801727295,
            -0.05820567160844803,
            -0.029106738045811653,
            -0.031529828906059265,
            0.041407935321331024,
            -0.0008061335538513958,
            0.03307904675602913,
            -0.028849095106124878,
            0.011734155006706715,
            -0.0008491399930790067,
            0.005616659298539162,
            -0.009105052798986435,
            -0.020228777080774307,
            -0.043859176337718964,
            -0.019567737355828285,
            -0.07186444103717804,
            0.026257671415805817,
            -0.029088573530316353,
            0.015582713298499584,
            0.012579111382365227,
            -0.015018966980278492,
            -0.032560478895902634,
            0.03141936659812927,
            0.00916708167642355,
            -0.03351810574531555,
            0.013685575686395168,
            0.0009172835852950811,
            -0.011034910567104816,
            0.0014834348112344742,
            0.0019414469134062529,
            -0.009135489352047443,
            0.01891651749610901,
            -0.001300798379816115,
            -0.08717229962348938,
            -0.010106101632118225,
            -0.05735299736261368,
            -0.06343939155340195,
            -0.04127909243106842,
            0.003335528075695038,
            0.0034222914837300777,
            -0.02590731903910637,
            -0.04157835990190506,
            -0.009898083284497261,
            -0.03376700356602669,
            -0.000620213511865586,
            -0.0500127412378788,
            0.01173606887459755,
            -0.00924211461097002,
            -0.021433455869555473,
            -0.04288823902606964,
            -0.01942267082631588,
            -0.03477214649319649,
            0.007242230698466301,
            0.028095344081521034,
            0.02220086380839348,
            0.007976087741553783,
            -0.01336666103452444,
            -0.0781247466802597,
            0.013617053627967834,
            0.01641552336513996,
            0.022725945338606834,
            -0.052448615431785583,
            0.010155954398214817,
            -0.003437645733356476,
            -0.02657594345510006,
            -0.0018648323602974415,
            -0.002086411463096738,
            -0.051405809819698334,
            0.00556190824136138,
            0.005359718110412359,
            0.016521938145160675,
            0.0072036380879580975,
            0.040758103132247925,
            0.01165946014225483,
            -0.06672442704439163,
            0.013126718811690807,
            -0.013866201043128967,
            0.024095507338643074,
            -0.024865634739398956,
            -0.0339256152510643,
            -0.03827260434627533,
            0.03938456252217293,
            -0.04329332709312439,
            -0.012672453187406063,
            0.009744693525135517,
            -0.0112442122772336,
            0.018837541341781616,
            -0.05197226256132126,
            -0.08044550567865372,
            -0.054043710231781006,
            -0.01480135228484869,
            0.049745336174964905,
            -0.01022749487310648,
            -0.01632705330848694,
            0.005826756823807955,
            0.020534269511699677,
            0.004715031012892723,
            0.05704965442419052,
            -0.03664033114910126,
            -0.067702516913414,
            0.011278362944722176,
            0.024149872362613678,
            -0.0093120988458395,
            -0.00040764984441921115,
            0.01068113837391138,
            0.007583444472402334,
            -0.046217020601034164,
            0.002298483159393072,
            0.01682332158088684,
            -0.047685083001852036,
            -0.02118726819753647,
            -0.045879967510700226,
            0.012541869655251503,
            0.0057905809953808784,
            -0.002295206766575575,
            -0.057006049901247025,
            -0.01968795619904995,
            -0.03430270776152611,
            0.022755911573767662,
            0.010020090267062187,
            -0.017842590808868408,
            0.031398531049489975,
            -0.010417821817100048,
            0.001568178297020495,
            0.007155351806432009,
            -0.008861000649631023,
            0.00391231058165431,
            -0.016575772315263748,
            0.027876321226358414,
            0.015019322745501995,
            -0.026821885257959366,
            -0.03560183197259903,
            -0.012702775187790394,
            -0.001011138898320496,
            -0.019963320344686508,
            -0.006396342534571886,
            -0.009468570351600647,
            -0.04574539139866829,
            0.01155309472233057,
            0.0134076913818717,
            0.018581168726086617,
            0.003235657000914216,
            -0.04830121621489525,
            -0.048225872218608856,
            0.005141834262758493,
            -0.07564347982406616,
            -0.015482671558856964,
            0.020707443356513977,
            -0.029487870633602142,
            0.015967296436429024,
            0.017015129327774048,
            0.015888718888163567,
            0.014941450208425522,
            0.0008345848182216287,
            0.037988826632499695,
            0.04818550497293472,
            0.01856015808880329,
            0.037168703973293304,
            -0.0012414976954460144,
            0.005612675100564957,
            -0.0372786819934845,
            0.028982218354940414,
            0.02541765384376049,
            -0.012783450074493885,
            0.02938101999461651,
            0.03859797492623329,
            -0.03566891327500343,
            0.00324913184158504,
            0.008611204102635384,
            -0.05128055438399315,
            0.008110564202070236,
            0.05800376087427139,
            -0.026643918827176094,
            -0.012236732989549637,
            -0.04078405350446701,
            0.032353710383176804,
            0.0020049947779625654,
            0.005723829846829176,
            -0.03114502690732479,
            0.0318676121532917,
            0.0028276529628783464,
            -0.0026376298628747463,
            0.0023982541169971228,
            -0.023947307839989662,
            -0.012130645103752613,
            0.014126659370958805,
            -0.011700190603733063,
            0.032088883221149445,
            -0.01660817489027977,
            -0.0188030693680048,
            -0.015229316428303719,
            -0.009581498801708221,
            0.011092256754636765,
            -0.016547415405511856,
            -0.01299193687736988,
            -0.01080444734543562,
            0.008137164637446404,
            -0.05960461124777794,
            -0.019488098099827766,
            0.0030063518788665533,
            0.0032500000670552254,
            0.03745259344577789,
            0.018456779420375824,
            -0.044255662709474564,
            0.03216558322310448,
            -0.02779175341129303,
            -0.01828000694513321,
            0.009422091767191887,
            -0.021608050912618637,
            0.0006734131602570415,
            -0.01447379495948553,
            0.05628850683569908,
            -0.0019470200641080737,
            -0.022269271314144135,
            0.031645726412534714,
            0.005332381930202246,
            0.005709763150662184,
            -0.012522264383733273,
            -0.03413582220673561,
            -0.010660192929208279,
            -0.009877219796180725,
            -0.001886167679913342,
            -0.061355799436569214,
            -0.02176838368177414,
            0.0013758145505562425,
            0.0016436079749837518,
            -0.020819930359721184,
            0.021976694464683533,
            -0.023440729826688766,
            0.03463982418179512,
            -0.005967618897557259,
            0.002844390692189336,
            0.019142216071486473,
            -0.05452340841293335,
            -0.028232526034116745,
            -0.032305117696523666,
            0.020087791606783867,
            -0.00393499992787838,
            -0.0279169213026762,
            0.021207179874181747,
            -0.03452606871724129,
            -0.02569197304546833,
            0.04644021764397621,
            -0.004889598581939936,
            -0.04476609453558922,
            0.010960512794554234,
            0.029743680730462074,
            -0.006558430381119251,
            -0.019026832655072212,
            -0.024632403627038002,
            0.005153744015842676,
            0.039668552577495575,
            0.009582356549799442,
            -0.021176740527153015,
            0.025528572499752045,
            0.019366079941391945,
            0.08001860231161118,
            -0.018513422459363937,
            -0.023670515045523643,
            0.010365322232246399,
            0.016695961356163025,
            -0.03289814665913582,
            0.02632961794734001,
            -0.04204661771655083,
            -0.018672281876206398,
            -0.014429645612835884,
            0.022305333986878395,
            -0.008191275410354137,
            0.040020354092121124,
            -0.03332388028502464,
            -0.016572104766964912,
            -0.0031059477478265762,
            0.007824559696018696,
            -0.03769495710730553,
            0.027633605524897575,
            0.006250255741178989,
            0.04553021863102913,
            -0.021673796698451042,
            -0.008352969773113728,
            0.0010439811740070581,
            0.007163511589169502,
            -0.02739419788122177,
            0.018863307312130928,
            -0.035554736852645874,
            -0.03801026567816734,
            -0.026766249909996986,
            -0.0020249844528734684,
            0.05362354591488838,
            -0.039001721888780594,
            -0.016836486756801605,
            -0.04344473406672478,
            -0.0005379127687774599,
            -0.04904477670788765,
            0.0229094997048378,
            0.004856269806623459,
            -0.0346996933221817,
            0.019735418260097504,
            -0.07342378795146942,
            0.009971695952117443,
            -0.03464522957801819,
            0.03195180371403694,
            -0.002614082768559456,
            -0.04174421727657318,
            0.02982505038380623,
            0.003193836659193039,
            -0.006860311143100262,
            -0.024728959426283836,
            0.029286550357937813,
            0.006972887087613344,
            -0.003922468516975641,
            0.030582677572965622,
            0.06405898928642273,
            -0.024989116936922073,
            0.05003892630338669,
            -0.04191657155752182,
            -0.004242866300046444,
            -0.043573200702667236,
            0.026872923597693443,
            0.03897755965590477,
            -0.007372558116912842,
            -0.008361101150512695,
            -0.05423874408006668,
            -0.056603964418172836,
            -0.018282897770404816,
            -0.05129767581820488,
            -0.0654275193810463,
            -0.01836158148944378,
            -0.02863946557044983,
            -0.027774296700954437,
            0.0056440019980072975,
            -0.012348861433565617,
            0.0035307342186570168,
            0.03129933029413223,
            -0.015000272542238235,
            -0.03633682802319527,
            -0.0070222108624875546,
            -0.000562730769161135,
            0.008881726302206516,
            -0.014811638742685318,
            0.012890041805803776,
            0.004137144889682531,
            -0.005244773346930742,
            -0.0649518370628357,
            -0.05130046233534813,
            -0.003884341102093458,
            -0.020814746618270874,
            -0.032312147319316864,
            -0.023581398651003838,
            0.029651988297700882,
            -0.030241547152400017,
            0.007660821080207825,
            0.004043968860059977,
            -0.0917208194732666,
            -0.015580736100673676,
            0.0023395998869091272,
            -0.039446260780096054,
            -0.034499045461416245,
            0.04681125283241272,
            -0.005667195189744234,
            -0.01611832156777382,
            0.054845381528139114,
            -0.03386905789375305,
            0.02722117491066456,
            -0.04103992506861687,
            0.06363040208816528,
            0.000818150001578033,
            -0.008115333504974842,
            0.03476707637310028,
            -0.048280104994773865,
            0.016661057248711586,
            0.027511512860655785,
            0.009912952780723572,
            0.003193743759766221,
            -0.027286598458886147,
            0.009874268434941769,
            -0.00863909162580967,
            -0.018457578495144844,
            0.010701253078877926,
            0.010011469013988972,
            0.00039355666376650333,
            -0.014009810984134674,
            0.021257003769278526,
            -0.08879253268241882,
            -0.061892762780189514,
            -0.012047314085066319,
            0.036913152784109116,
            -0.01837688870728016,
            0.03910699486732483,
            -0.017094453796744347,
            -0.025682194158434868,
            0.035401351749897,
            -0.011493977159261703,
            0.017512425780296326,
            -0.0009657523478381336,
            -0.04110823571681976,
            0.006838922388851643,
            0.023003214970231056,
            0.008570726029574871,
            0.019989730790257454,
            -0.02627694047987461,
            -0.02135433629155159,
            0.0020791548304259777,
            -0.012706845067441463,
            0.004193657077848911,
            -0.030056757852435112,
            0.023057060316205025,
            -0.018643712624907494,
            -0.043293215334415436,
            -0.03458058834075928,
            0.010385531932115555,
            -0.0004433910653460771,
            0.019207410514354706,
            0.0008479122770950198,
            -0.01888853870332241,
            -0.006038299761712551,
            -0.004251255188137293,
            -0.016858622431755066,
            0.021141305565834045,
            -0.017669521272182465,
            -0.09226364642381668,
            0.014317142777144909,
            -0.0011408441932871938,
            -0.059491779655218124,
            -0.08133750408887863,
            -0.02750116027891636,
            -0.026209251955151558,
            -0.00195048947352916,
            -0.009064313024282455,
            -0.00030641304329037666,
            0.04033950716257095,
            -0.0004825448850169778,
            -0.013079281896352768,
            -0.0066098403185606,
            -0.011354421265423298,
            -0.0032470792066305876,
            -0.0017996395472437143,
            -0.03697136417031288,
            -0.029367951676249504,
            -0.05244193226099014,
            0.03568249195814133,
            -0.017698517069220543,
            -0.02801336906850338,
            0.007005648221820593,
            -0.008625906892120838,
            -0.015728026628494263,
            0.024683872237801552,
            0.0370749831199646,
            -0.05359523743391037,
            0.03429838642477989,
            -0.03227893263101578,
            0.00017931219190359116,
            0.013264953158795834,
            -0.013777382671833038,
            0.0023715822026133537,
            -0.009302986785769463,
            -0.023684529587626457,
            0.017291706055402756,
            0.005161461420357227,
            0.004143957979977131,
            -0.06180720031261444,
            -0.03568613529205322,
            -0.020432710647583008,
            0.05092412978410721,
            -0.018835972994565964,
            -0.04631323367357254,
            0.018543561920523643,
            -0.053750284016132355,
            -0.04241793230175972,
            -0.02191801927983761,
            0.026004759594798088,
            0.020812097936868668,
            0.029828988015651703,
            0.002797464607283473,
            -0.0031367731280624866,
            0.04425644129514694,
            -0.0035717186983674765,
            -0.015509184449911118,
            -0.033252935856580734,
            -0.02167975902557373,
            -0.007774792145937681,
            -0.03149135783314705,
            -0.011150471866130829,
            -0.03979218751192093,
            -0.030132992193102837,
            -0.0044920737855136395,
            0.004647411871701479,
            0.009071724489331245,
            -0.02648025192320347,
            -0.032720018178224564,
            0.022872088477015495,
            2.4500402560079237e-06,
            -0.02700626291334629,
            -0.019212018698453903,
            -9.028558270074427e-05,
            -0.008343650959432125,
            -0.03820431977510452,
            -0.015478075481951237,
            0.023491371423006058,
            0.012394549325108528,
            -0.02005033753812313,
            -0.008488722145557404,
            0.03888856992125511,
            0.0062093366868793964,
            0.023116078227758408,
            -0.0676177367568016,
            -0.003913339227437973,
            -0.04189180210232735,
            0.01165864709764719,
            0.0029404324013739824,
            0.00029379772604443133,
            -0.008738224394619465,
            0.05381188541650772,
            0.057551782578229904,
            0.0386647954583168,
            0.0010913398582488298,
            0.01840674690902233,
            -0.017452258616685867,
            -0.04550278186798096,
            0.0054128337651491165,
            -0.015207872726023197,
            -0.015950344502925873,
            0.0047216652892529964,
            0.020906604826450348,
            0.0007635888177901506,
            0.0015060236910358071,
            -0.005819790530949831,
            -0.01601462997496128,
            -0.013852879405021667,
            0.05194585770368576,
            -0.014711239375174046,
            -0.02200491540133953,
            0.005418997257947922,
            0.054319653660058975,
            -0.0069596958346664906,
            -0.0445210263133049,
            0.024377137422561646,
            -0.007476320490241051,
            0.010962754487991333,
            -0.008388947695493698,
            -0.024886464700102806,
            -0.026421349495649338,
            -0.028709525242447853,
            -0.05897614359855652,
            -0.01336043979972601,
            -0.015089703723788261,
            -0.05094603821635246,
            -0.041489146649837494,
            0.004257293418049812,
            -0.018375849351286888,
            0.0011226446367800236,
            0.018931923434138298,
            0.01778942160308361,
            -0.045355916023254395,
            -0.02872115559875965,
            -0.026094287633895874,
            -0.021371738985180855,
            -0.00564774451777339,
            0.015469443053007126,
            -0.02502930536866188,
            0.010257495567202568,
            0.0057165059261024,
            -0.029095666483044624,
            -0.030545778572559357,
            -0.04038717597723007,
            -0.03232312202453613,
            0.01039968989789486,
            0.0015693418681621552,
            -0.03640742599964142,
            -0.02807748131453991,
            -0.01843932271003723,
            0.0327555313706398,
            -0.046718765050172806,
            0.007040403783321381,
            -0.011654210276901722,
            0.00778901157900691,
            0.028255833312869072,
            -0.013317300006747246,
            0.021891798824071884,
            -0.05922994017601013,
            0.00745635200291872,
            -0.019657611846923828,
            0.027280209586024284,
            -0.03523801639676094,
            -0.018869787454605103,
            -0.0035124083515256643,
            0.023968443274497986,
            -0.02932281792163849,
            -0.033710550516843796,
            -0.01753224804997444,
            0.001626891433261335,
            0.0029175158124417067,
            0.008965207263827324,
            -0.010326961055397987,
            -0.06185472756624222,
            0.017886443063616753,
            -0.020666809752583504,
            -0.008554873056709766,
            0.011469518765807152,
            0.02842622995376587,
            0.028651531785726547,
            -0.03891275078058243,
            0.029814647510647774,
            0.014747759327292442,
            -0.04503262788057327,
            -0.043346840888261795,
            -0.032641828060150146,
            -0.022166483104228973,
            0.040157467126846313,
            0.02801509201526642,
            -0.018257934600114822,
            0.019111789762973785,
            0.024126902222633362,
            -0.024510465562343597,
            0.02829916775226593,
            0.0124815683811903,
            -0.031130239367485046,
            0.040769461542367935,
            -0.010495967231690884,
            -0.03316262736916542,
            -0.021694041788578033,
            -0.007417743559926748,
            -0.007815791293978691,
            0.0003396505780983716,
            -0.00331848650239408,
            -0.01419532485306263,
            -0.013250845484435558,
            -0.019633358344435692,
            -0.053782667964696884,
            -0.025123899802565575,
            0.003746638773009181,
            0.03276491537690163,
            0.03784898668527603,
            -0.008500486612319946,
            -0.005655937362462282,
            -0.038911525160074234,
            -0.01839570142328739,
            -0.006561142858117819,
            -0.03236681595444679,
            0.0065778568387031555,
            -0.03686515614390373,
            -0.009523436427116394,
            0.03841404989361763,
            -0.009163468144834042,
            0.02945864573121071,
            0.06365837901830673,
            0.014674839563667774,
            0.02325301431119442,
            -0.009457855485379696,
            -0.01163475215435028,
            -0.03128186985850334,
            -0.04008349031209946,
            0.019693365320563316,
            0.017965910956263542,
            -0.02792310155928135,
            -0.01218451652675867,
            0.0057515595108270645,
            -0.04090728610754013,
            0.008062399923801422,
            -0.036062274128198624,
            0.010872582904994488,
            -0.04458417743444443,
            0.030032675713300705,
            -0.03787672147154808,
            -0.011928309686481953,
            0.010820933617651463,
            -0.01671183668076992,
            0.03453550487756729,
            -0.10404030978679657,
            -0.010520863346755505,
            -0.012774534523487091,
            -0.029451265931129456,
            -0.05868818238377571,
            -0.016914354637265205,
            0.023288916796445847,
            0.01840171031653881,
            -0.01342136412858963,
            -0.004837654531002045,
            0.015112434513866901,
            -0.019759830087423325,
            -0.005153142847120762,
            -0.02006702497601509,
            -0.052501045167446136,
            -0.026167573407292366,
            0.03934478759765625,
            -0.027608593925833702,
            -0.016380460932850838,
            -0.04967701435089111,
            0.02601582370698452,
            0.018811428919434547,
            0.013158399611711502,
            0.03918851166963577,
            -0.026925791054964066,
            0.010582641698420048,
            -0.0014104674337431788,
            -0.037980854511260986,
            -0.0011895665666088462,
            -0.06012977659702301,
            0.02507229708135128,
            0.02694549597799778,
            -0.043357964605093,
            -0.04605520889163017,
            -0.04188341274857521,
            -0.0011571856448426843,
            0.0070471190847456455,
            0.0012977253645658493,
            -0.007847370579838753,
            -0.033247511833906174,
            -0.001983886118978262,
            -0.02552756480872631,
            -0.024333981797099113,
            0.0511641688644886,
            0.014757813885807991,
            0.004723977297544479,
            -0.002539030509069562,
            0.012057749554514885,
            0.04437699541449547,
            -0.0054470812901854515,
            -0.019115902483463287,
            -0.05982580408453941,
            0.005202698055654764,
            -0.04816383495926857,
            0.014361971989274025,
            0.04331284016370773,
            -0.010265684686601162,
            0.02255077101290226,
            0.01844538003206253,
            -0.02231457084417343,
            -0.02192392759025097,
            -0.07197080552577972,
            -0.00965903140604496,
            -0.019864460453391075,
            -0.07738000899553299,
            -0.007080958690494299,
            -0.027747880667448044,
            -0.026500878855586052,
            -0.03215785324573517,
            0.004961078520864248,
            -0.04419999197125435,
            -0.08178236335515976,
            -0.008211216889321804,
            -0.04486062750220299,
            -0.0357070229947567,
            0.006381120998412371,
            -0.002480895956978202,
            0.050749778747558594,
            -0.007771610748022795,
            -0.008572706021368504,
            -0.009390614926815033,
            -0.02156258001923561,
            0.03618631511926651,
            0.02033786103129387,
            0.015947945415973663,
            -0.018484514206647873,
            -0.014178358018398285,
            -0.03227422386407852,
            -0.06812173128128052,
            -0.029053809121251106,
            -0.03466852754354477,
            -0.0011095860973000526,
            0.045162927359342575,
            -0.015960820019245148,
            0.012151683680713177,
            -0.006652881391346455,
            0.009367773309350014,
            -0.014209059998393059,
            0.025577925145626068,
            0.006514838431030512,
            -0.007049477659165859,
            -8.143638115143403e-05,
            -0.04241790995001793,
            -0.008349121548235416,
            0.015008300542831421,
            -0.03490646928548813,
            0.03162200376391411,
            -0.04432658851146698,
            -0.011838988400995731,
            0.011860429309308529,
            -0.05179017782211304,
            0.04155990853905678,
            0.02300213649868965,
            -0.04418272152543068,
            -0.04036600887775421,
            -0.007312096189707518,
            -0.0513404943048954,
            0.028454329818487167,
            0.01122861634939909,
            -0.010057196952402592,
            0.014290553517639637,
            0.024416619911789894,
            -0.03082060068845749,
            0.014778121374547482,
            0.00794215127825737,
            0.04786497354507446,
            -0.027611099183559418,
            -0.010258779861032963,
            -0.0025047664530575275,
            -0.0252961628139019,
            -0.022643735632300377,
            -0.0019553501624614,
            0.02114137075841427,
            -0.007568802684545517,
            0.009475793689489365,
            0.020891929045319557,
            0.0052757253870368,
            -0.06010842323303223,
            -0.06149427965283394,
            -0.008945067413151264,
            -0.08922946453094482,
            0.04138478264212608,
            -0.0633900836110115,
            -0.008803130127489567,
            0.04497122764587402,
            -0.02075168304145336,
            -0.006457793526351452,
            -0.024322066456079483,
            0.011634706519544125,
            -0.05419377610087395,
            0.05859928950667381,
            0.02592715620994568,
            -0.04422030225396156,
            -0.03570469468832016,
            0.023815739899873734,
            0.041041649878025055,
            -0.023475762456655502,
            -0.011038428172469139,
            -0.05399502441287041,
            0.041866227984428406,
            -0.008894862607121468,
            -0.04553920775651932,
            0.05748991668224335,
            -0.04614400118589401,
            0.016538769006729126,
            0.038044098764657974,
            -0.0035242848098278046,
            -0.033779848366975784,
            -0.015636850148439407,
            0.006511322222650051,
            0.019636329263448715,
            -0.0388733372092247,
            -0.013862648978829384,
            -0.028448589146137238,
            -0.029455989599227905,
            -0.0293793473392725,
            -0.02533010020852089,
            -0.04980723187327385,
            -0.036927979439496994,
            -0.03596466779708862,
            -0.03712579235434532,
            -0.020295655354857445,
            -0.027834933251142502,
            0.014312250539660454,
            0.0009765861323103309,
            -0.01182357594370842,
            -0.008986239321529865,
            0.008976902812719345,
            0.0020718835294246674,
            0.034893445670604706,
            -0.005314544774591923,
            -0.017719775438308716,
            -0.0709100216627121,
            -0.009862103499472141,
            0.017168056219816208,
            0.005987569689750671,
            -0.03237272426486015,
            0.030425196513533592,
            -0.03806804493069649,
            0.0240708626806736,
            -0.05382826551795006,
            0.026884187012910843,
            0.027464404702186584,
            -0.01802266389131546,
            0.027826808393001556,
            -0.004994834773242474,
            -0.03332212567329407,
            -0.032227084040641785,
            -0.013887065462768078,
            -0.07521524280309677,
            0.03348532319068909,
            0.011630045250058174,
            -0.012700732797384262,
            0.006964526604861021,
            -0.00814993679523468,
            0.05285137891769409,
            -0.015036244876682758,
            -0.012840814888477325,
            0.0009773707715794444,
            0.03538167104125023,
            -0.0035382274072617292,
            0.0031277956441044807,
            0.04826296120882034,
            -0.045361947268247604,
            0.004516497254371643,
            -0.0017372872680425644,
            -0.10743850469589233,
            -0.04175061732530594,
            -0.04400184378027916,
            -0.017445670440793037,
            -0.016527770087122917,
            0.0011689446400851011,
            0.003626264864578843,
            -0.06532830744981766,
            -0.008715875446796417,
            0.008625149726867676,
            -0.03567938506603241,
            -0.0060576023533940315,
            -0.014469989575445652,
            0.010494435206055641,
            -0.05408290773630142,
            -0.02622278220951557,
            0.012064754031598568,
            0.030309654772281647,
            -0.014496480114758015,
            0.019245916977524757,
            -0.022276166826486588,
            -0.027713635936379433,
            -0.000838558713439852,
            0.046480268239974976,
            -0.06758898496627808,
            0.004634468350559473,
            0.026160098612308502,
            0.0018855867674574256,
            -0.020139386877417564,
            0.028984596952795982,
            0.0251041017472744,
            -0.0603269524872303,
            0.006534789688885212,
            -0.038569673895835876,
            0.02058754488825798,
            -0.017404692247509956,
            0.0005467816372402012,
            -0.01565607264637947,
            0.005893581081181765,
            0.020930640399456024,
            -0.031833939254283905,
            0.021284490823745728,
            -0.02561347745358944,
            -0.06763562560081482,
            -0.045961473137140274,
            -0.005773493554443121,
            -0.02186405099928379,
            0.014203512109816074,
            -0.013234811834990978,
            -0.007154096383601427,
            -0.009132873266935349,
            0.0055453842505812645,
            -0.03756451979279518,
            -0.02055426314473152,
            -0.02085530199110508,
            0.039215561002492905,
            0.029194273054599762,
            -0.037271901965141296,
            0.004717613570392132,
            -0.04909332096576691,
            -0.007407126482576132,
            0.016061628237366676,
            -0.03531867638230324,
            0.0391787625849247,
            0.006886660121381283,
            -0.023326678201556206
        ],
        [
            0.004995955154299736,
            -0.015809210017323494,
            0.018977435305714607,
            0.005502044688910246,
            -0.02423892170190811,
            -0.011994036845862865,
            -0.009487967938184738,
            -0.032784730195999146,
            -0.01066984236240387,
            0.011259054765105247,
            -0.029679954051971436,
            0.030256954953074455,
            -0.0003545520012266934,
            -0.02345310151576996,
            -0.007756200153380632,
            -0.020485712215304375,
            0.0011937665985897183,
            -0.007314664777368307,
            -0.02028452232480049,
            -0.013643528334796429,
            -0.02215178869664669,
            -0.01947624795138836,
            -0.005467037204653025,
            -0.0076941135339438915,
            -0.01229030266404152,
            0.015776904299855232,
            -0.03338885307312012,
            -0.02383640594780445,
            0.012539377436041832,
            -0.005305183585733175,
            0.005263623781502247,
            0.006505586672574282,
            -0.0075030033476650715,
            0.03646150231361389,
            -0.022236470133066177,
            0.015508648939430714,
            -0.006512218154966831,
            -0.01243019662797451,
            -0.01164051704108715,
            0.007734796032309532,
            0.010079671628773212,
            0.01031598448753357,
            0.024135425686836243,
            -0.0251480545848608,
            0.007880273275077343,
            -0.013755054213106632,
            0.03154158592224121,
            0.029216688126325607,
            -0.025383349508047104,
            -0.0057728588581085205,
            0.013048017397522926,
            0.006789647974073887,
            -0.004061329178512096,
            0.031732428818941116,
            0.024069152772426605,
            0.023684730753302574,
            -0.010100951418280602,
            -0.016775328665971756,
            0.02373972162604332,
            -0.008078042417764664,
            -0.016995009034872055,
            -0.023469379171729088,
            -0.021423062309622765,
            -0.00227412860840559,
            -0.016743509098887444,
            0.025750424712896347,
            -0.011452869512140751,
            0.015501875430345535,
            0.02137705124914646,
            -0.00915643759071827,
            0.0347212478518486,
            -0.022776518017053604,
            0.012348266318440437,
            0.02846563048660755,
            0.03367054462432861,
            -0.003947553224861622,
            0.01622774824500084,
            -0.029452787712216377,
            0.034411776810884476,
            0.0037547671236097813,
            0.02933174930512905,
            -0.0027524528559297323,
            0.019357403740286827,
            0.002964974846690893,
            -0.02869335561990738,
            0.002483055926859379,
            -0.011982455849647522,
            -0.020981861278414726,
            0.0344785675406456,
            0.028165655210614204,
            0.019290179014205933,
            0.001456006313674152,
            0.0029110766481608152,
            0.009274481795728207,
            -0.0009063761681318283,
            -0.007766076363623142,
            -0.002274442231282592,
            -0.026467781513929367,
            -0.05601614713668823,
            0.02018178254365921,
            0.019506115466356277,
            0.021013669669628143,
            0.014033356681466103,
            -0.007809718605130911,
            0.023233579471707344,
            -0.012006595730781555,
            -0.016253650188446045,
            0.015027735382318497,
            0.025970298796892166,
            0.0002156974223908037,
            -0.03428163006901741,
            0.004767541773617268,
            0.015472888946533203,
            -0.004584931768476963,
            -0.029390808194875717,
            0.010706828907132149,
            -0.012958639301359653,
            -0.014609955251216888,
            0.0313173271715641,
            0.012082483619451523,
            0.01352386362850666,
            -0.015076435171067715,
            0.007952321320772171,
            0.0015899278223514557,
            -0.003744437824934721,
            -0.0073967063799500465,
            -0.024096686393022537,
            -0.023121671751141548,
            0.018325651064515114,
            0.02894548885524273,
            0.013415705412626266,
            -0.017012348398566246,
            0.0005511177005246282,
            0.027208523824810982,
            0.015085541643202305,
            0.010990746319293976,
            -0.001816416042856872,
            -0.01335570216178894,
            -0.00540469354018569,
            -0.02386208437383175,
            -0.019925067201256752,
            0.02813814952969551,
            0.014037411659955978,
            0.03490246832370758,
            0.01932416670024395,
            -0.029743842780590057,
            0.0026607790496200323,
            0.009007645770907402,
            0.02640252374112606,
            -0.008158048614859581,
            0.031560853123664856,
            0.02335716411471367,
            -0.009781770408153534,
            0.016243860125541687,
            -0.02127559669315815,
            0.018346067517995834,
            0.018708668649196625,
            -0.03209983557462692,
            0.0033687667455524206,
            0.009593280963599682,
            0.010981560684740543,
            -0.02893095090985298,
            0.002554896753281355,
            -0.03174753114581108,
            -0.007947574369609356,
            0.021001609042286873,
            -0.020965497940778732,
            -0.03638630732893944,
            -0.0033771127928048372,
            -0.011738608591258526,
            -0.008479706011712551,
            -0.01936057023704052,
            -0.011024795472621918,
            0.02264551632106304,
            0.007684307172894478,
            -0.039088793098926544,
            -0.02768959105014801,
            -0.00269799935631454,
            -0.002945228014141321,
            0.0351068414747715,
            -0.019735051319003105,
            0.035250332206487656,
            -0.0007930016727186739,
            0.013924185186624527,
            0.03346358239650726,
            -0.002238175366073847,
            -0.01082727499306202,
            0.016865968704223633,
            0.019981542602181435,
            -0.023965047672390938,
            -0.0014912317274138331,
            -0.025163782760500908,
            -0.020596260204911232,
            0.009409364312887192,
            0.027354931458830833,
            0.02880200184881687,
            0.026855051517486572,
            -0.019711613655090332,
            -0.02858191914856434,
            0.02472355216741562,
            0.022724619135260582,
            0.030558999627828598,
            0.016226215288043022,
            0.026434481143951416,
            0.04121201112866402,
            0.011720079928636551,
            -0.019449980929493904,
            0.006727248430252075,
            0.013111972250044346,
            0.03388722985982895,
            -0.018561644479632378,
            -0.01372966542840004,
            -0.02154596522450447,
            -0.0040760706178843975,
            0.03328786417841911,
            0.0146781699731946,
            -0.022244680672883987,
            -0.0043563274666666985,
            -0.015046549029648304,
            -0.034097809344530106,
            0.009424190036952496,
            0.002128674415871501,
            0.030532147735357285,
            0.0069162664003670216,
            0.012943942099809647,
            -0.05399687588214874,
            0.01553394366055727,
            0.00828066747635603,
            -0.007025804370641708,
            -0.015001967549324036,
            -0.0004422238271217793,
            -0.023540878668427467,
            0.016486261039972305,
            -0.02115457132458687,
            0.010433807037770748,
            0.01702750287950039,
            -0.004511039704084396,
            -0.003697047010064125,
            -0.020513324066996574,
            -0.0016680058324709535,
            0.010620269924402237,
            0.009829478338360786,
            0.00980949867516756,
            -0.015556642785668373,
            0.03115673176944256,
            0.02108830027282238,
            -0.043374203145504,
            -0.010701186954975128,
            -0.02832389809191227,
            0.007142622489482164,
            0.0012100900057703257,
            -0.029050156474113464,
            -0.021595554426312447,
            0.00995621457695961,
            -0.014071403071284294,
            0.02534855157136917,
            0.00762142613530159,
            0.005468981806188822,
            -0.011058271862566471,
            0.009964310564100742,
            -0.002636419842019677,
            -0.007596824783831835,
            0.03173232451081276,
            -0.02066856622695923,
            -0.008330083452165127,
            -0.004344363231211901,
            -0.029461903497576714,
            0.008186748251318932,
            0.002672596136108041,
            -0.008207903243601322,
            -0.01951925829052925,
            0.025250807404518127,
            -0.004634706303477287,
            0.025275379419326782,
            0.006608122028410435,
            -0.02940983884036541,
            -0.024906206876039505,
            -0.014809360727667809,
            0.02413262613117695,
            0.02359333075582981,
            0.008067750371992588,
            0.039028603583574295,
            0.015202795155346394,
            0.021315421909093857,
            -0.026378558948636055,
            0.010979484766721725,
            -0.002802730305120349,
            0.024569055065512657,
            0.00884026475250721,
            0.018047332763671875,
            0.0150632718577981,
            -0.03031863272190094,
            -0.025293394923210144,
            -0.02988877147436142,
            -0.01910649985074997,
            0.004743883386254311,
            -0.025217264890670776,
            -0.001238140626810491,
            0.014400755986571312,
            -0.0183356162160635,
            0.005190744996070862,
            0.03743624687194824,
            0.005889715626835823,
            0.033011049032211304,
            -0.015126308426260948,
            0.004321863409131765,
            -0.0004898456973023713,
            0.025371450930833817,
            0.0066877612844109535,
            0.0037241994868963957,
            0.00875523779541254,
            -0.029625605791807175,
            0.009004241786897182,
            0.011157275177538395,
            0.0003245220286771655,
            -0.03913538157939911,
            0.011896518990397453,
            0.024692866951227188,
            -0.0239349864423275,
            -0.029702864587306976,
            0.008818295784294605,
            0.0012944296468049288,
            0.022213801741600037,
            -0.030362844467163086,
            -0.002033218741416931,
            -0.02631799876689911,
            0.018457626923918724,
            -0.01806764304637909,
            0.006969205569475889,
            0.003040717216208577,
            -0.00954439491033554,
            0.005263705272227526,
            0.012462799437344074,
            0.017464851960539818,
            0.027879616245627403,
            -0.03553113713860512,
            0.026997245848178864,
            -0.007104610092937946,
            0.026472296565771103,
            0.02431563474237919,
            0.011742637492716312,
            0.013489619828760624,
            0.0020064362324774265,
            0.005189409013837576,
            -0.002856482518836856,
            -0.039320893585681915,
            0.013367126695811749,
            0.0179609265178442,
            -0.02544349431991577,
            0.023977508768439293,
            0.022050755098462105,
            0.0029662742745131254,
            -0.03211522102355957,
            0.006662396248430014,
            0.02502153441309929,
            -0.02646392583847046,
            0.017117183655500412,
            0.019156383350491524,
            0.0013354049297049642,
            0.02106398530304432,
            0.019067637622356415,
            0.016909394413232803,
            -0.01459456142038107,
            0.009620281867682934,
            -0.01915842667222023,
            0.0020275041460990906,
            0.04003855958580971,
            0.014898101799190044,
            -0.014957192353904247,
            -0.022100869566202164,
            -0.0041110641323029995,
            0.02930559031665325,
            0.03404977545142174,
            -0.0076516796834766865,
            -0.004315079189836979,
            0.024049073457717896,
            0.024306582286953926,
            -0.02591993287205696,
            -0.03445432707667351,
            0.03020443208515644,
            -0.02990630455315113,
            0.02771788462996483,
            -0.021587155759334564,
            -0.019239619374275208,
            0.014549549669027328,
            -0.01627604104578495,
            0.013780966401100159,
            -0.005820682272315025,
            -0.02986779436469078,
            -0.006429474800825119,
            -0.00933459959924221,
            -0.013682120479643345,
            -0.015936486423015594,
            0.0009624345693737268,
            -0.013022443279623985,
            0.02383117377758026,
            -0.019263798370957375,
            0.021310511976480484,
            -0.02002466656267643,
            -0.02282804250717163,
            -0.017201336100697517,
            0.027102475985884666,
            0.023440292105078697,
            -0.0315161868929863,
            0.01432648953050375,
            0.026842564344406128,
            0.0309259332716465,
            -0.020848294720053673,
            0.001958058215677738,
            -0.02918507717549801,
            0.00921775121241808,
            -0.029010074213147163,
            -0.0231904499232769,
            0.027233146131038666,
            -0.004457041155546904,
            0.0010016666492447257,
            -0.009448859840631485,
            -0.016431255266070366,
            -0.017727917060256004,
            -0.013555784709751606,
            -0.002592268167063594,
            -0.027568167075514793,
            0.003999012988060713,
            0.009978133253753185,
            -0.016950689256191254,
            0.009347773157060146,
            0.01652495376765728,
            0.002143256366252899,
            -0.0262980367988348,
            0.03323148563504219,
            -0.02828352153301239,
            0.024116909131407738,
            0.016012392938137054,
            0.008285535499453545,
            0.031608231365680695,
            0.021230734884738922,
            0.03298727795481682,
            -0.00581774115562439,
            0.018983636051416397,
            0.025640109553933144,
            0.029565343633294106,
            -0.0011383953969925642,
            -0.011203658767044544,
            -0.011293675750494003,
            0.0053443447686731815,
            -0.0109057342633605,
            -0.005718070548027754,
            -0.05069652199745178,
            0.026031779125332832,
            -0.013161951676011086,
            0.0105288652703166,
            0.016682112589478493,
            0.0424664244055748,
            0.031136387959122658,
            0.004833179991692305,
            0.023942025378346443,
            -0.03898338973522186,
            0.019460884854197502,
            -0.019839171320199966,
            -0.019932981580495834,
            0.023448707535862923,
            0.010578503832221031,
            0.021720677614212036,
            2.503649375285022e-05,
            0.017830386757850647,
            -0.027765484526753426,
            -0.008079254068434238,
            -0.009235366247594357,
            -0.0005720594781450927,
            -0.0024641128256917,
            -0.008963700383901596,
            0.027270030230283737,
            -0.009813632816076279,
            -0.007408762816339731,
            0.021577125415205956,
            0.008356488309800625,
            -0.023421574383974075,
            0.02944762445986271,
            0.02873426489531994,
            -0.021244578063488007,
            0.002224665367975831,
            0.027704434469342232,
            0.016049213707447052,
            -0.00993522722274065,
            -0.021103253588080406,
            0.007427896372973919,
            -0.031649377197027206,
            -0.017885316163301468,
            0.05497055500745773,
            0.025945883244276047,
            -0.015435878187417984,
            -0.01358313113451004,
            -0.013561677187681198,
            -0.027270808815956116,
            0.002844667760655284,
            -0.007745168171823025,
            0.029785796999931335,
            0.03150912746787071,
            -0.02659444510936737,
            0.011969021521508694,
            -0.02229764126241207,
            0.03486725315451622,
            0.016521433368325233,
            -0.05068894475698471,
            -0.002181106014177203,
            -0.02263253554701805,
            -0.006923953536897898,
            -0.016778824850916862,
            0.024984411895275116,
            -0.01824352890253067,
            0.016103632748126984,
            -0.036302823573350906,
            -0.008946439251303673,
            -0.012909432873129845,
            -0.007249700836837292,
            0.030778488144278526,
            0.018851768225431442,
            -0.018340572714805603,
            0.0338558629155159,
            0.009945598430931568,
            -0.012229005806148052,
            0.008816877380013466,
            0.008721631020307541,
            0.01929793506860733,
            0.01575346104800701,
            -0.0009498712606728077,
            0.0012998012825846672,
            -0.030009418725967407,
            0.01293832529336214,
            -0.008224480785429478,
            -0.026546595618128777,
            0.004681036341935396,
            -0.004287287592887878,
            0.03105231001973152,
            0.021641477942466736,
            0.027952393516898155,
            0.008263890631496906,
            -0.027432842180132866,
            0.0008011932368390262,
            0.01529827993363142,
            0.026810510084033012,
            0.016095835715532303,
            0.01852535456418991,
            -0.024616360664367676,
            0.0031731086783111095,
            0.03041795641183853,
            -0.016080191358923912,
            0.007399463560432196,
            0.019136538729071617,
            0.02490939013659954,
            0.004452650900930166,
            -0.0020800214260816574,
            0.003312270389869809,
            -0.010060532949864864,
            0.028806786984205246,
            -0.0231071338057518,
            -0.026281418278813362,
            -0.033537257462739944,
            -0.029426058754324913,
            0.004320486448705196,
            0.015290530398488045,
            -0.005991089157760143,
            -0.0016310062492266297,
            -0.020457783713936806,
            -0.036466289311647415,
            0.00890293251723051,
            0.022564921528100967,
            -0.010676708072423935,
            -0.007653256878256798,
            0.010908459313213825,
            -0.005888215731829405,
            0.03442823141813278,
            -0.016177207231521606,
            -0.028731580823659897,
            -0.03258194401860237,
            -0.0032419145572930574,
            -0.0030549936927855015,
            -0.010638861916959286,
            0.03361440822482109,
            -0.0020813392475247383,
            0.01787007413804531,
            0.01998678408563137,
            -0.024806179106235504,
            0.0019334601238369942,
            0.016613397747278214,
            0.009396141394972801,
            0.030753230676054955,
            0.0197913721203804,
            -0.00017509539611637592,
            -0.030500546097755432,
            -0.00843031145632267,
            0.00836755707859993,
            -0.010499906726181507,
            0.010683510452508926,
            0.008696047589182854,
            0.0024624059442430735,
            -0.014516179449856281,
            -0.014982549473643303,
            0.0028807076159864664,
            -0.007713014725595713,
            0.01957325078547001,
            -0.02228361740708351,
            -0.0028531576972454786,
            -0.023489298298954964,
            0.02992991916835308,
            -0.01797768846154213,
            0.0012947437353432178,
            -0.015562445856630802,
            0.011592705734074116,
            -0.04186949506402016,
            0.015481063164770603,
            -0.036604270339012146,
            -0.003347043413668871,
            0.028545912355184555,
            0.00997053924947977,
            -0.01127307116985321,
            0.024063628166913986,
            -0.021201642230153084,
            0.019611530005931854,
            -0.01614443026483059,
            -0.0016131128650158644,
            -0.00908592063933611,
            -0.01455625519156456,
            0.019519822672009468,
            0.027949221432209015,
            -0.002438628114759922,
            0.013688229024410248,
            -0.02113277278840542,
            -0.03318324312567711,
            0.007699973415583372,
            -0.0024737815838307142,
            0.0064308494329452515,
            -0.011917606927454472,
            0.026134025305509567,
            0.029631316661834717,
            0.008580918423831463,
            0.02642260305583477,
            -0.014742547646164894,
            -0.01955123245716095,
            0.0029969385359436274,
            -0.0052209156565368176,
            -0.01793847605586052,
            -0.01791137270629406,
            0.03621109575033188,
            0.0018743573455139995,
            -0.01848647929728031,
            -0.0037110042758286,
            0.010893069207668304,
            0.019784873351454735,
            0.008820977061986923,
            0.012953157536685467,
            0.01708930917084217,
            -0.00585625134408474,
            -0.01463682297617197,
            -0.025150369852781296,
            0.0005876460345461965,
            0.009378718212246895,
            0.014971165917813778,
            0.03203772008419037,
            -0.030567163601517677,
            0.004892019554972649,
            -0.010110215283930302,
            0.028970928862690926,
            -0.0031744642183184624,
            -0.029816143214702606,
            0.0168368611484766,
            -5.0130722229368985e-05,
            0.018102621659636497,
            0.028386518359184265,
            1.4181641745381057e-05,
            -0.03871316462755203,
            0.01915808767080307,
            -0.01312615629285574,
            0.03559296205639839,
            0.016126608476042747,
            -0.021573234349489212,
            -0.017190828919410706,
            -0.0051062884740531445,
            0.015787508338689804,
            0.01197073608636856,
            -0.005992786027491093,
            -0.02613246627151966,
            0.004341045394539833,
            -0.028669485822319984,
            -0.032694123685359955,
            0.012009181082248688,
            -0.01661566272377968,
            -0.012512624263763428,
            0.024012453854084015,
            -0.01319903414696455,
            -0.03980438783764839,
            -0.015502025373280048,
            -0.021546073257923126,
            0.03912215679883957,
            0.016565419733524323,
            -0.03653550520539284,
            0.0023903697729110718,
            -0.024417871609330177,
            0.013929648324847221,
            -0.0173074658960104,
            0.016017353162169456,
            -0.012736435979604721,
            -0.007322905119508505,
            -0.004889283794909716,
            0.002266854280605912,
            0.013255332596600056,
            -0.0008446926367469132,
            0.012969397939741611,
            -0.008012387901544571,
            -0.016138209030032158,
            -0.016689911484718323,
            -0.0031010156963020563,
            0.019599037244915962,
            0.005995488725602627,
            0.00834569986909628,
            -0.01687198504805565,
            -0.06756853312253952,
            -0.0056559136137366295,
            0.021620500832796097,
            0.026827488094568253,
            -0.01852380856871605,
            0.014167994260787964,
            -0.027380313724279404,
            -0.020882459357380867,
            0.0009258377831429243,
            -0.012518501840531826,
            -0.026688359677791595,
            0.007283204235136509,
            -0.02409561723470688,
            -0.01521504856646061,
            0.0243196040391922,
            -0.026061762124300003,
            -0.014287520200014114,
            -0.013609178364276886,
            -0.022888869047164917,
            -0.01248498260974884,
            -0.007127868942916393,
            0.003568823914974928,
            0.027916867285966873,
            -0.003279151627793908,
            -0.01221778430044651,
            -0.007422454189509153,
            -0.022106310352683067,
            -0.02243635803461075,
            0.01787405088543892,
            -0.026059526950120926,
            0.06341719627380371,
            0.018414581194519997,
            0.02110600285232067,
            0.013993959873914719,
            0.00668046809732914,
            0.00040949825779534876,
            0.015931276604533195,
            -0.01686583273112774,
            -0.011290226131677628,
            0.0098722530528903,
            0.0047344849444925785,
            -0.025990024209022522,
            0.01401476003229618,
            -0.010488069616258144,
            -0.01037508063018322,
            0.022492272779345512,
            0.013319438323378563,
            -0.008615457452833652,
            0.007502297405153513,
            0.030610037967562675,
            0.016401275992393494,
            0.021981239318847656,
            -0.01352518517524004,
            0.0015024099266156554,
            -0.017205415293574333,
            0.003153515048325062,
            0.02599932812154293,
            0.018243612721562386,
            0.01177112478762865,
            -0.009311486035585403,
            -0.008228466846048832,
            -0.02610727958381176,
            0.02077552303671837,
            0.010917512699961662,
            -0.016929974779486656,
            0.025822728872299194,
            0.005861022975295782,
            0.011273079551756382,
            0.0247981995344162,
            0.011375963687896729,
            0.011137163266539574,
            -0.01694147102534771,
            0.0016011703992262483,
            -0.00995317567139864,
            0.01858869567513466,
            -0.008389412425458431,
            -0.00011290147813269868,
            -0.02121124230325222,
            -0.029948784038424492,
            0.032348696142435074,
            0.003348482772707939,
            0.012307609431445599,
            0.02541486732661724,
            -0.01633944921195507,
            -0.0338323600590229,
            -0.02211741730570793,
            0.005781067535281181,
            -0.014914516359567642,
            -0.010068138130009174,
            0.03674235939979553,
            0.03829881176352501,
            -0.011237863451242447,
            -0.035920027643442154,
            -0.006220557726919651,
            0.036482300609350204,
            0.03749414533376694,
            0.018649986013770103,
            0.02449367754161358,
            0.02069821022450924,
            -0.01971111260354519,
            0.02563534677028656,
            -0.028912633657455444,
            0.034386564046144485,
            -0.0031471375841647387,
            0.002525757299736142,
            -0.02923155575990677,
            -0.022753436118364334,
            0.0002801917144097388,
            -0.00706964498385787,
            -0.014136318117380142,
            -0.015537791885435581,
            -0.03265759348869324,
            0.022795481607317924,
            0.03481695428490639,
            0.01120099239051342,
            -0.007229235023260117,
            0.013232561759650707,
            0.0027012834325432777,
            0.02626863680779934,
            -0.00960619281977415,
            0.009896503761410713,
            0.0023188507184386253,
            -0.012981776148080826,
            0.0293271541595459,
            -0.016857052221894264,
            0.012172889895737171,
            -0.0039677005261182785,
            0.010944630950689316,
            0.011039062403142452,
            0.016004346311092377,
            0.00164416478946805,
            -0.01659349724650383,
            -0.01444175373762846,
            -0.0006812455831095576,
            0.00550938630476594,
            -0.01775316521525383,
            -0.008772848173975945,
            -0.009067322127521038,
            0.004194913897663355,
            -0.029154853895306587,
            0.013003095053136349,
            -0.007610945496708155,
            -0.011870745569467545,
            -0.005035769660025835,
            -0.04019606113433838,
            -0.02865462563931942,
            0.027671359479427338,
            0.013060659170150757,
            -0.028393611311912537,
            0.026802796870470047,
            0.020697632804512978,
            -0.012008307501673698,
            -0.014909759163856506,
            0.0073719145730137825,
            0.04033739119768143,
            -0.030428165569901466,
            -0.023695990443229675,
            -0.04379825294017792,
            -0.03046192228794098,
            0.01823815144598484,
            -0.017060967162251472,
            -0.015362422913312912,
            -0.011766374111175537,
            -0.02095833420753479,
            0.03636680915951729,
            0.0012413415824994445,
            0.026906898245215416,
            0.01528576947748661,
            -0.027700770646333694,
            -0.027465665712952614,
            0.020602738484740257,
            0.015316655859351158,
            0.0015899130376055837,
            -0.002769717015326023,
            -0.02535295858979225,
            0.016837485134601593,
            0.01566576585173607,
            0.008029154501855373,
            -0.014991328120231628,
            0.015713106840848923,
            0.004777782130986452,
            0.028331037610769272,
            -0.01427659671753645,
            -0.010436383076012135,
            0.007062828168272972,
            0.014404983259737492,
            -0.02344011515378952,
            -0.03765198960900307,
            0.03893274441361427,
            -0.00493923993781209,
            -0.02995024248957634,
            0.009904790669679642,
            -0.008805097080767155,
            -0.006313967984169722,
            0.010994864627718925,
            -0.016415927559137344,
            -0.02479621209204197,
            0.011048059910535812,
            -0.01586662232875824,
            -0.00307053467258811,
            -0.028471924364566803,
            -0.03173533454537392,
            0.017728010192513466,
            -0.020008424296975136,
            0.01589185744524002,
            -0.007470021490007639,
            0.00017823783855419606,
            -0.010594741441309452,
            0.04605681076645851,
            0.00977905374020338,
            0.026520686224102974,
            -0.0025251705665141344,
            -0.003878982039168477,
            0.011149965226650238,
            -0.001687128795310855,
            -0.007096950430423021,
            -0.0008464592974632978,
            0.03072201833128929,
            -0.012172969058156013,
            0.014527583494782448,
            0.025601768866181374,
            -0.0365452915430069,
            0.00822959840297699,
            -0.015784773975610733,
            0.014860478229820728,
            -0.01818065159022808,
            -0.015425878576934338,
            0.02471221797168255,
            -0.04156230017542839,
            0.0012979459716007113,
            -0.009326855652034283,
            0.001112756202928722,
            -0.007716805208474398,
            0.01800215244293213,
            0.03384781628847122,
            0.009478156454861164,
            -0.017421144992113113,
            -0.01403791457414627,
            -0.029002849012613297,
            0.027035489678382874,
            0.03235841915011406,
            0.008740704506635666,
            -0.03203559294342995,
            0.009761135093867779,
            -0.0008190619992092252,
            -0.008272919803857803,
            0.04215497151017189,
            -0.014431626535952091,
            -0.006509793922305107,
            0.00024204653163906187,
            -0.030400170013308525,
            0.01750938966870308,
            -0.006258937995880842,
            0.022083068266510963,
            0.015580803155899048,
            0.028318408876657486,
            0.0006755495560355484,
            -0.016287805512547493,
            0.008814950473606586,
            0.0067603131756186485,
            -0.006890349090099335,
            0.0058328257873654366,
            0.021564967930316925,
            -0.013854268938302994,
            -0.04738125950098038,
            -0.017453666776418686,
            0.03984388709068298,
            0.012667541392147541,
            0.00725377956405282,
            -0.024141816422343254,
            0.0060505629517138,
            0.011727074161171913,
            -0.023806588724255562,
            -0.027578866109251976,
            -0.0003936146677006036,
            0.012589318677783012,
            0.02905115857720375,
            0.02916705794632435,
            0.003026337595656514,
            0.02013784646987915,
            0.02836516499519348,
            -0.028977714478969574,
            -0.026163365691900253,
            0.00016907545796129853,
            0.014064468443393707,
            -0.006557765882462263,
            0.02874225378036499,
            -0.025018248707056046,
            -0.03199117258191109,
            -0.004939074162393808,
            -0.024584311991930008,
            -0.010140872560441494,
            -0.015945352613925934,
            0.011536823585629463,
            0.0022269408218562603,
            0.017764821648597717,
            0.0021829132456332445,
            -0.0016060732305049896,
            -0.026620017364621162,
            0.013406448066234589,
            0.01989138126373291,
            -0.029744146391749382,
            -0.03883679211139679,
            -0.013932159170508385,
            -0.03718705475330353,
            0.010021868161857128,
            -0.009129547514021397,
            0.000752536638174206
        ],
        [
            0.01773703843355179,
            0.028957301750779152,
            -0.020529869943857193,
            0.028876814991235733,
            -0.014291701838374138,
            -0.042839132249355316,
            -0.0034774881787598133,
            -0.0012677214108407497,
            0.013680877164006233,
            -0.015529056079685688,
            -0.021021710708737373,
            -0.011160005815327168,
            0.03598055616021156,
            0.0023569506593048573,
            0.06820754706859589,
            -0.012965536676347256,
            0.011943534016609192,
            0.033185992389917374,
            -0.012366965413093567,
            -0.02733631059527397,
            -0.004303468856960535,
            -0.013285650871694088,
            -0.030800972133874893,
            0.022310227155685425,
            -0.022599918767809868,
            -0.012746897526085377,
            -0.03713708370923996,
            0.02974836900830269,
            0.009158889763057232,
            -0.010829279199242592,
            -0.006984540726989508,
            0.03385511040687561,
            -0.026738671585917473,
            0.02020559273660183,
            -0.023417610675096512,
            -0.008968205191195011,
            0.018261287361383438,
            -0.028730062767863274,
            0.008846915327012539,
            0.02639812044799328,
            -0.011845450848340988,
            0.019312040880322456,
            -0.0007050473359413445,
            -0.012923995032906532,
            -0.0053209890611469746,
            0.02919793501496315,
            -0.019505422562360764,
            0.03429708257317543,
            0.013576066121459007,
            0.01821381226181984,
            -0.015185317024588585,
            -0.0007313218666240573,
            -0.046010084450244904,
            -0.03406904637813568,
            0.04687024652957916,
            0.023687029257416725,
            0.00944785587489605,
            -0.024359416216611862,
            -0.01487512607127428,
            -0.013403455726802349,
            0.01875983737409115,
            0.02601887471973896,
            0.033604059368371964,
            0.0029504720587283373,
            0.02006191946566105,
            0.033351633697748184,
            -0.015919078141450882,
            0.006304724141955376,
            -0.009730011224746704,
            -5.5897900892887264e-05,
            0.030558094382286072,
            0.037757959216833115,
            -0.021039055660367012,
            -0.006381855346262455,
            -0.0032516622450202703,
            0.03902329131960869,
            0.010972064919769764,
            0.008041607216000557,
            0.007605801336467266,
            -0.03456320986151695,
            0.03879896178841591,
            -0.006442580837756395,
            -0.017149949446320534,
            0.012931082397699356,
            0.033054884523153305,
            0.012647271156311035,
            -0.008925266563892365,
            0.02608097903430462,
            -0.009903030470013618,
            0.015410667285323143,
            -0.01794268935918808,
            -0.03593166545033455,
            -0.04521254822611809,
            0.021120255813002586,
            0.0216840710490942,
            0.006375514902174473,
            -0.02370620146393776,
            0.03538991138339043,
            -0.06555210798978806,
            -0.019722962751984596,
            -0.011500557884573936,
            0.026165800169110298,
            0.025966309010982513,
            -0.028162945061922073,
            -0.0024934238754212856,
            0.008602796122431755,
            0.015445832163095474,
            -0.011436033062636852,
            -0.026921216398477554,
            0.029149601235985756,
            -0.01967046782374382,
            -0.01605057157576084,
            0.02659446746110916,
            0.02255113236606121,
            -0.04275723919272423,
            0.015834545716643333,
            -0.002682723803445697,
            0.014869756065309048,
            -0.021157922223210335,
            -0.024454927071928978,
            -0.031356651335954666,
            -0.01952395960688591,
            -0.029408130794763565,
            -0.0031601826194673777,
            -0.012071813456714153,
            -0.02435402385890484,
            0.03167565166950226,
            -0.02593929134309292,
            -0.01837916672229767,
            0.0016094321617856622,
            -0.03081519529223442,
            -0.02505107969045639,
            -0.022890882566571236,
            0.02706398442387581,
            0.0005644296761602163,
            0.004579333588480949,
            0.013153813779354095,
            0.00653010793030262,
            0.018778186291456223,
            -0.025879859924316406,
            0.008794281631708145,
            -0.001800544559955597,
            -0.026511915028095245,
            0.03597090020775795,
            -0.02455632947385311,
            -0.019308460876345634,
            0.024656152352690697,
            -0.002657345961779356,
            -0.0007892372086644173,
            0.004991408437490463,
            -0.00040213268948718905,
            -0.042149074375629425,
            0.011764923110604286,
            0.014257831498980522,
            -0.0027727282140403986,
            0.009090668521821499,
            -0.008510869927704334,
            0.005866272374987602,
            -0.03303336724638939,
            0.027491480112075806,
            0.0041117542423307896,
            -0.022410547360777855,
            0.006466134916990995,
            -0.0037558034528046846,
            -0.021012891083955765,
            0.04244941473007202,
            -0.008412373252213001,
            -0.01875041052699089,
            -0.0074991825968027115,
            -0.007952593266963959,
            -0.0018390270415693521,
            -0.03194355219602585,
            -0.017550311982631683,
            -0.009393085725605488,
            -0.041558876633644104,
            0.014437183737754822,
            0.01779952086508274,
            0.008840111084282398,
            0.025775976479053497,
            0.0008965802262537181,
            0.03742941841483116,
            -0.007317313924431801,
            -0.0071088094264268875,
            -0.0036606292705982924,
            0.017504695802927017,
            -0.022926390171051025,
            0.043701812624931335,
            0.025299252942204475,
            0.01855235919356346,
            0.03239530697464943,
            0.032756611704826355,
            -0.004707109183073044,
            -0.016264554113149643,
            -0.022988995537161827,
            -0.007510428316891193,
            0.018915997818112373,
            0.010834071785211563,
            -0.007875957526266575,
            -0.007644898723810911,
            -0.03267037123441696,
            -0.008594708517193794,
            -0.005840390454977751,
            0.0065309032797813416,
            -0.006399679463356733,
            -0.0064619965851306915,
            -0.01234421320259571,
            0.020364193245768547,
            0.04573478922247887,
            0.011988977901637554,
            0.04165280610322952,
            -0.021511316299438477,
            0.014511131681501865,
            0.02089076116681099,
            0.014572271145880222,
            0.03577167168259621,
            0.00787424948066473,
            0.019193163141608238,
            0.013127116486430168,
            -0.00228821556083858,
            0.006613049656152725,
            0.022315820679068565,
            -0.0017649196088314056,
            0.04790014773607254,
            0.020647099241614342,
            0.017361998558044434,
            0.05413162335753441,
            0.014841094613075256,
            -0.0007894120644778013,
            0.009836363606154919,
            0.030752278864383698,
            -0.008665572851896286,
            -0.013120435178279877,
            -0.04996718838810921,
            -0.012662583962082863,
            0.012909851036965847,
            -0.029870666563510895,
            0.006495804525911808,
            0.03531259670853615,
            -0.001803021295927465,
            -0.005748171359300613,
            -0.009164613671600819,
            0.028325388208031654,
            0.041752152144908905,
            -0.004222283139824867,
            -0.004337535239756107,
            -0.020620381459593773,
            -0.039436254650354385,
            0.02681015059351921,
            -0.02354755997657776,
            0.023084916174411774,
            -0.012180868536233902,
            0.0053010256960988045,
            0.018537824973464012,
            0.007795588579028845,
            -0.02680293470621109,
            -0.016491372138261795,
            0.03531555458903313,
            -0.015921633690595627,
            0.030105000361800194,
            0.017605815082788467,
            0.020608719438314438,
            0.032654426991939545,
            -0.023670168593525887,
            0.004066213965415955,
            0.05233052000403404,
            0.0103937778621912,
            -0.03248583525419235,
            0.020102307200431824,
            -0.01572011224925518,
            -0.02881982922554016,
            0.03260697424411774,
            0.013632044196128845,
            0.002549272496253252,
            0.0035540293902158737,
            0.020375989377498627,
            0.00674672145396471,
            0.025370847433805466,
            -0.017531439661979675,
            0.029897606000304222,
            0.028712160885334015,
            0.023539507761597633,
            0.02711748331785202,
            -0.01892990618944168,
            -0.011052542366087437,
            -0.005452265497297049,
            0.032460641115903854,
            0.035659439861774445,
            0.05767005681991577,
            0.01922745071351528,
            0.01772567629814148,
            -0.02212338335812092,
            -0.00779598206281662,
            0.02716026082634926,
            0.017065497115254402,
            0.0066654616966843605,
            0.012198155745863914,
            0.021963726729154587,
            0.001404128735885024,
            0.017239155247807503,
            0.013226221315562725,
            0.02085961028933525,
            0.013945427723228931,
            0.009545939974486828,
            0.0038393978029489517,
            -0.02616308256983757,
            -0.07532180845737457,
            0.00497934827581048,
            -0.010796913877129555,
            -0.01549448724836111,
            -0.02172873727977276,
            -0.00872382614761591,
            -0.0051181623712182045,
            -0.014017402194440365,
            0.02586304396390915,
            0.03093956783413887,
            -0.01620052009820938,
            0.020748266950249672,
            -0.005428710952401161,
            -0.009557624347507954,
            0.024465693160891533,
            -0.017562972381711006,
            0.03893817216157913,
            0.01217134390026331,
            0.002493624808266759,
            0.013739312067627907,
            -0.016151009127497673,
            -0.003382261609658599,
            -0.010366353206336498,
            0.02417816035449505,
            -0.0026777407620102167,
            0.007114258594810963,
            -0.014618949964642525,
            0.0036198380403220654,
            0.017218584194779396,
            0.029186896979808807,
            -0.018138771876692772,
            -0.03144821152091026,
            -0.0079744728282094,
            0.007798909675329924,
            0.027248555794358253,
            -0.018302887678146362,
            -0.031006427481770515,
            0.029957033693790436,
            0.017195358872413635,
            -0.007558660581707954,
            -0.03183787316083908,
            0.03801429644227028,
            -0.00971178524196148,
            0.03006741590797901,
            0.022918790578842163,
            -0.025786155834794044,
            0.006263650022447109,
            0.002448414685204625,
            -0.012387337163090706,
            -0.005565288010984659,
            0.011539672501385212,
            0.01528150774538517,
            -0.0168691948056221,
            -0.027769923210144043,
            0.02802850492298603,
            0.03350820764899254,
            -0.008890566416084766,
            0.037806835025548935,
            -0.040396686643362045,
            -0.05250994488596916,
            -0.023340722545981407,
            0.04505747929215431,
            0.010515651665627956,
            -0.006628981791436672,
            0.0029759204480797052,
            -0.025229351595044136,
            0.021096574142575264,
            0.03289081156253815,
            -0.01320246048271656,
            -0.00018459239799994975,
            0.002006048569455743,
            0.01855708286166191,
            -0.03097287192940712,
            0.02500521019101143,
            0.029065163806080818,
            0.011618053540587425,
            0.01738452911376953,
            -0.018933024257421494,
            -0.01118382252752781,
            0.009862266480922699,
            -0.006748768500983715,
            0.02484758384525776,
            0.035736434161663055,
            0.019307492300868034,
            -0.04954560101032257,
            0.03027135506272316,
            -0.004032916855067015,
            -0.02198563888669014,
            0.02114666998386383,
            -0.03596699982881546,
            0.03486676514148712,
            0.023825233802199364,
            0.03644442930817604,
            0.03167182579636574,
            0.030251426622271538,
            0.022450119256973267,
            0.01895809732377529,
            -0.005297094117850065,
            -0.0036482252180576324,
            0.004402084741741419,
            -0.008399232290685177,
            -0.002520584501326084,
            0.011861487291753292,
            -0.007587436120957136,
            0.02225457690656185,
            0.013735261745750904,
            0.008377413265407085,
            0.014181230217218399,
            0.037054695188999176,
            0.01947947032749653,
            0.023989180102944374,
            -0.026568375527858734,
            0.027620501816272736,
            -0.04944799467921257,
            -0.0259851161390543,
            -0.010578271001577377,
            -0.02683156356215477,
            -0.04713359475135803,
            0.012421979568898678,
            -0.015976078808307648,
            -0.012504065409302711,
            -0.011952336877584457,
            -0.023581689223647118,
            -0.01136049535125494,
            0.03656903654336929,
            0.027012163773179054,
            0.02947893738746643,
            0.014381643384695053,
            -0.023772651329636574,
            0.0015701105585321784,
            0.004867546260356903,
            -0.0035196251701563597,
            0.0001332398533122614,
            0.028033705428242683,
            -0.03973016142845154,
            0.019215505570173264,
            0.03076399676501751,
            -0.014647199772298336,
            -0.007934799417853355,
            -0.030705107375979424,
            0.0011039869859814644,
            -0.037262123078107834,
            -0.07710490375757217,
            -0.04347674176096916,
            -0.007701090071350336,
            0.0018178741447627544,
            -0.008025243878364563,
            0.008468453772366047,
            0.00343790790066123,
            0.012241074815392494,
            0.009369655512273312,
            -0.006936416495591402,
            0.02014499343931675,
            0.004380342550575733,
            -0.000488979509100318,
            0.0452704057097435,
            0.02885999158024788,
            -0.00033200375037267804,
            -0.0009006920736283064,
            0.011814762838184834,
            0.038401126861572266,
            0.023121077567338943,
            0.012811441905796528,
            0.00992286205291748,
            0.024677634239196777,
            -0.0017921238904818892,
            -0.016019918024539948,
            0.024266894906759262,
            -0.023640671744942665,
            -0.03146156668663025,
            -0.03489213436841965,
            0.010965253226459026,
            0.013392407447099686,
            0.04853377863764763,
            0.03210078552365303,
            0.043541137129068375,
            -0.00915879849344492,
            -0.034031182527542114,
            0.020078202709555626,
            -0.01736026629805565,
            0.02391873113811016,
            -0.015977993607521057,
            -0.025034816935658455,
            -0.022504502907395363,
            -0.022221045568585396,
            -0.010918490588665009,
            -0.003308367682620883,
            0.008458730764687061,
            -0.028048964217305183,
            -0.012848916463553905,
            0.02880154736340046,
            0.006933439057320356,
            0.025550013408064842,
            -0.025290746241807938,
            0.018732549622654915,
            -0.0034225548151880503,
            -0.04144124686717987,
            -0.007019710261374712,
            0.006014686077833176,
            0.00765514001250267,
            -0.0047639827243983746,
            0.014970577321946621,
            0.0036854897625744343,
            0.02475488930940628,
            0.0003646750410553068,
            -0.016962723806500435,
            -0.007606805767863989,
            0.0056011127308011055,
            0.002426697639748454,
            0.010484040714800358,
            0.00805749837309122,
            0.028537852689623833,
            0.0029917375650256872,
            0.0006729151937179267,
            0.022164156660437584,
            0.0018200831254944205,
            0.0075246114283800125,
            -0.02063313126564026,
            0.020700398832559586,
            0.017859969288110733,
            -0.008383701555430889,
            -0.018553851172327995,
            0.014165999367833138,
            0.031869955360889435,
            0.003956000320613384,
            -0.004380328580737114,
            0.0024928709026426077,
            0.001341294962912798,
            -0.018233364447951317,
            0.017234111204743385,
            0.03188806399703026,
            0.030846798792481422,
            0.03819821774959564,
            0.00048385828267782927,
            0.014388414099812508,
            0.03597108647227287,
            0.03017786704003811,
            -6.873589882161468e-05,
            -0.029284516349434853,
            -0.02247275412082672,
            0.028615759685635567,
            0.03349738568067551,
            -0.036756109446287155,
            0.011526383459568024,
            -0.009438560344278812,
            -0.017741167917847633,
            0.006614925339818001,
            -0.01663045585155487,
            0.015015814453363419,
            -0.009725943207740784,
            -0.016597989946603775,
            -0.005436141509562731,
            -0.0005815583281219006,
            -0.024677889421582222,
            -0.027696603909134865,
            -0.019455431029200554,
            0.025342224165797234,
            -0.016587471589446068,
            0.03297972306609154,
            0.0012304704869166017,
            -0.0020020450465381145,
            0.0259719118475914,
            0.021245108917355537,
            -0.003171721938997507,
            -0.013846948742866516,
            -0.0188099667429924,
            0.029153402894735336,
            -0.006584578193724155,
            0.025302452966570854,
            0.005491683259606361,
            -0.00483992462977767,
            -0.0021199993789196014,
            0.015104783698916435,
            -0.000520120607689023,
            0.03749943897128105,
            0.009189525619149208,
            0.002118297154083848,
            0.012480000033974648,
            -0.0010177739895880222,
            0.002742520533502102,
            -0.010412664152681828,
            -0.009688652120530605,
            -0.004137128591537476,
            0.026261236518621445,
            -0.02375057339668274,
            0.0042874631471931934,
            -0.022381840273737907,
            -0.004359018057584763,
            0.016674263402819633,
            -0.0014297725865617394,
            -0.01472681388258934,
            -0.011443152092397213,
            0.030019443482160568,
            0.01789478398859501,
            -0.0039083450101315975,
            0.004712904337793589,
            0.03608756884932518,
            0.03510433807969093,
            -0.0032315042335540056,
            -0.02181246317923069,
            -0.005687746684998274,
            0.007747414521872997,
            0.009299864061176777,
            0.005540573503822088,
            0.008644122630357742,
            0.015530220232903957,
            0.003350808983668685,
            -0.008362849242985249,
            -0.0016117147170007229,
            0.0026229205541312695,
            0.010317552834749222,
            -0.021529901772737503,
            -0.007294559385627508,
            0.018993236124515533,
            0.004097986500710249,
            -0.012943080626428127,
            0.00555643904954195,
            0.02145596593618393,
            0.018957432359457016,
            0.0011682073818519711,
            0.012572859413921833,
            -0.002644664840772748,
            -0.00408440176397562,
            0.005781323183327913,
            -0.005823971703648567,
            0.027449525892734528,
            0.012640881352126598,
            -0.024088550359010696,
            -0.00647793198004365,
            0.021330490708351135,
            -0.009505929425358772,
            0.02244464121758938,
            -0.0013627849984914064,
            0.011279493570327759,
            -0.0397833026945591,
            -0.004616974387317896,
            0.0019025845685973763,
            0.044333212077617645,
            -0.08985394239425659,
            -0.0071307881735265255,
            -0.014309684745967388,
            0.015433382242918015,
            0.026126064360141754,
            0.01444138865917921,
            0.00122741365339607,
            0.033211853355169296,
            -0.01824146695435047,
            0.007496600970625877,
            0.03459150716662407,
            -0.0013232184574007988,
            0.021478483453392982,
            0.020672835409641266,
            0.009867625311017036,
            0.024282334372401237,
            -0.004841813817620277,
            -0.016864581033587456,
            -0.008066053502261639,
            0.022182688117027283,
            0.006680602207779884,
            -0.00045627361396327615,
            -0.0009809413459151983,
            0.03487100452184677,
            0.020876672118902206,
            -0.008554262109100819,
            0.028602546080946922,
            0.020979981869459152,
            0.03473546355962753,
            0.017031501978635788,
            0.03302512317895889,
            -0.0028338998090475798,
            0.010191007517278194,
            -0.014924154616892338,
            -0.028795510530471802,
            0.01132154930382967,
            -0.04048827663064003,
            -0.002177451504394412,
            0.035442326217889786,
            0.016144627705216408,
            -0.0034024715423583984,
            0.0286121666431427,
            0.024612965062260628,
            -0.019625384360551834,
            0.019930796697735786,
            0.008152995258569717,
            0.0014071910409256816,
            -0.04887993261218071,
            0.01541551761329174,
            -0.02790386788547039,
            0.026180656626820564,
            0.026764875277876854,
            -0.011502892710268497,
            -0.02076704427599907,
            -0.027264678850769997,
            -0.011881928890943527,
            0.011057873256504536,
            0.0042214361019432545,
            -0.017405500635504723,
            0.03303955867886543,
            0.002740396186709404,
            0.007504661101847887,
            -0.012325901538133621,
            -0.013037282042205334,
            -0.0007076055044308305,
            0.02705008164048195,
            -0.013493827544152737,
            -0.014181054197251797,
            -0.005974653642624617,
            0.03066418319940567,
            0.011087847873568535,
            -0.00040947418892756104,
            0.026599418371915817,
            -0.0017672026297077537,
            -0.024406766518950462,
            0.025524737313389778,
            -0.019610155373811722,
            0.012437361292541027,
            0.038150906562805176,
            -0.007279329001903534,
            0.01303030177950859,
            -0.020659776404500008,
            -0.006649683229625225,
            -0.03039417788386345,
            -0.009598755277693272,
            0.015932776033878326,
            -0.012936398386955261,
            0.007318812422454357,
            -0.02649472840130329,
            -0.009179550223052502,
            -0.028403401374816895,
            0.012961535714566708,
            0.008156167343258858,
            0.0015096344286575913,
            -0.005380529910326004,
            0.03294973820447922,
            -0.0018580914475023746,
            0.000535949831828475,
            -0.014318833127617836,
            -0.0092447679489851,
            -0.01315345335751772,
            0.02952408790588379,
            0.017385730519890785,
            0.04787781462073326,
            -0.02589374966919422,
            -0.014961509965360165,
            -0.018593804910779,
            0.0017906476277858019,
            0.009315296076238155,
            -0.005540118552744389,
            0.009343761950731277,
            0.011589274741709232,
            -0.01389444712549448,
            0.009993310086429119,
            -0.04927347972989082,
            0.011417069472372532,
            0.0313594788312912,
            -0.020380085334181786,
            -0.000706532911863178,
            0.004580629523843527,
            0.003690168494358659,
            0.014271805994212627,
            0.02652451954782009,
            -0.030066590756177902,
            0.032602615654468536,
            -0.0318896621465683,
            -0.027425633743405342,
            0.03252482786774635,
            -0.0036175167188048363,
            0.0345362164080143,
            -0.0038457121700048447,
            -0.007460600696504116,
            -0.002019737381488085,
            0.001930951839312911,
            0.013599240221083164,
            0.028108488768339157,
            0.00012299278751015663,
            0.015722731128335,
            0.03565312922000885,
            0.03975794464349747,
            -0.00405144551768899,
            0.021381638944149017,
            0.017524609342217445,
            0.02663431316614151,
            -0.010915213264524937,
            -0.010021795518696308,
            -0.005687988828867674,
            0.039282917976379395,
            0.013938086107373238,
            -0.018694372847676277,
            -0.027248404920101166,
            -0.026630304753780365,
            -0.023223143070936203,
            -0.029901590198278427,
            0.00016531607252545655,
            -0.01171166729182005,
            0.01137244701385498,
            -0.012413382530212402,
            -0.03160814940929413,
            0.026258433237671852,
            0.037338513880968094,
            -0.06462620943784714,
            0.01131497509777546,
            0.01604100503027439,
            -0.025540227070450783,
            -0.020099841058254242,
            0.001576270442456007,
            0.00557357631623745,
            -0.03822585940361023,
            -0.01702132634818554,
            0.02815198339521885,
            0.016913127154111862,
            -0.015177377499639988,
            0.017738696187734604,
            0.01578240655362606,
            0.008889973163604736,
            0.013064301572740078,
            -0.001191418617963791,
            -0.006037418264895678,
            -0.02822759747505188,
            0.011689584702253342,
            0.0030017218086868525,
            0.008530335500836372,
            0.0007822539191693068,
            -0.02660025842487812,
            -0.009987099096179008,
            -0.01892462931573391,
            0.009755238890647888,
            0.024451255798339844,
            0.0012423947919160128,
            0.04570971056818962,
            0.0016796611016616225,
            0.014954102225601673,
            0.0077842227183282375,
            -0.0070578851737082005,
            -0.0055312723852694035,
            -0.009867717511951923,
            -0.02140507660806179,
            0.019434180110692978,
            -0.02323894202709198,
            0.0006159367621876299,
            0.0016488521359860897,
            0.018359288573265076,
            -0.015046603977680206,
            0.018303804099559784,
            -0.020362310111522675,
            -0.03693314269185066,
            0.015611018054187298,
            -0.007967804558575153,
            -0.01810336671769619,
            0.021552614867687225,
            0.023599890992045403,
            0.010378509759902954,
            0.016209375113248825,
            -0.0019681467674672604,
            0.009247696958482265,
            -0.01097153127193451,
            0.018295662477612495,
            0.0022655175998806953,
            -0.04734200984239578,
            0.03450707718729973,
            -0.0017138198018074036,
            0.018011225387454033,
            -0.022294992581009865,
            0.0011474797502160072,
            0.01621188409626484,
            0.02430918999016285,
            0.0004056632751598954,
            -0.012531726621091366,
            0.0030079304706305265,
            -0.0408211424946785,
            0.01352122612297535,
            -0.004059005528688431,
            0.028731074184179306,
            -0.035389889031648636,
            -0.009456610307097435,
            0.027247220277786255,
            -0.011455989442765713,
            0.007755352184176445,
            0.004739552736282349,
            -0.010674846358597279,
            -0.04550086334347725,
            0.010406543500721455,
            0.0008349369163624942,
            -0.030743660405278206,
            0.0029754426795989275,
            -0.020592093467712402,
            0.008509953506290913,
            -0.06281568855047226,
            -0.11071912199258804,
            0.027601689100265503,
            -0.03455759212374687,
            0.021032296121120453,
            0.00715031148865819,
            0.008211388252675533,
            0.016566984355449677,
            -0.017481792718172073,
            -0.008309981785714626,
            -0.022039344534277916,
            -0.004022588022053242,
            0.02475523203611374,
            0.043194521218538284,
            -0.013557560741901398,
            -0.033521201461553574,
            -0.056781284511089325,
            -0.0036032015923410654,
            -0.01040636096149683,
            -0.05259198695421219,
            0.05010091885924339,
            -0.029659507796168327,
            0.01786225661635399,
            0.045115333050489426,
            -0.019472502171993256,
            -0.009317242540419102,
            0.020858585834503174,
            0.004180179443210363,
            0.022993002086877823,
            -0.027209604158997536,
            0.0038086038548499346,
            -0.02254505641758442,
            0.0016848178347572684,
            -0.05664278194308281,
            0.028475478291511536,
            0.020437242463231087,
            -0.0024544207844883204,
            -0.010732312686741352,
            -0.0005376069457270205,
            0.021785017102956772,
            -0.019247766584157944,
            0.02900777757167816,
            0.015562178567051888,
            0.01115262322127819,
            -0.024422939866781235,
            0.01918192207813263,
            -0.0018140884349122643,
            -0.01680249162018299,
            0.041970472782850266,
            -0.01969325914978981,
            0.02521968074142933,
            0.04619322717189789,
            0.02588183991611004,
            0.008892818354070187,
            -0.014666561968624592,
            -0.03907271474599838,
            0.031204072758555412,
            -0.011973361484706402,
            -0.007055582478642464,
            0.014298953115940094,
            -0.02787802554666996,
            -0.01335602905601263,
            0.025980575010180473,
            -0.036523282527923584,
            -0.017405232414603233,
            -0.0024489667266607285,
            -0.010444066487252712,
            0.019529806450009346,
            0.013878339901566505,
            -0.014069613069295883,
            0.005055280867964029,
            -0.013294482603669167,
            0.01248129177838564,
            0.008370935916900635,
            0.009945552796125412,
            -0.004738763906061649,
            -0.018620774149894714,
            -0.05953040346503258,
            -0.006214804016053677,
            0.008806586265563965,
            -0.010764664970338345,
            -0.00947027001529932,
            0.00545260775834322,
            -0.042715415358543396,
            0.014373191632330418,
            0.02637244388461113,
            -0.021000290289521217,
            0.025087645277380943,
            0.017654655501246452,
            -0.03841070830821991,
            -0.03682125732302666,
            -0.025269459933042526,
            0.00017178691632580012,
            -0.020953916013240814,
            -0.0244517233222723,
            0.017781347036361694,
            0.010669674724340439,
            0.023963743820786476,
            0.023635517805814743,
            0.04309970140457153,
            0.015363379381597042,
            0.0006286755669862032,
            0.03615743666887283,
            -0.020756201818585396,
            0.02353084832429886,
            -0.013094997964799404,
            -0.0036049240734428167,
            0.01677839830517769,
            0.014562170021235943,
            0.007487067487090826,
            0.029398635029792786,
            0.02935064397752285,
            0.017229849472641945,
            0.00896153412759304,
            -0.0018650031415745616,
            -0.007886717095971107,
            -0.0246361643075943,
            0.017879460006952286,
            -0.022546254098415375,
            0.016510220244526863,
            0.04053330793976784,
            0.008451476693153381,
            -0.011902958154678345,
            0.001606033300049603,
            0.0017526643350720406,
            -0.0031805902253836393,
            -0.018054785206913948,
            -0.0391233004629612,
            -0.02674841694533825,
            -0.03699083253741264,
            -0.0010313187958672643,
            0.009844372048974037,
            0.022716565057635307
        ],
        [
            -0.006316584534943104,
            -0.02582348883152008,
            -0.003743888810276985,
            -0.014865782111883163,
            0.01892373524606228,
            -0.011932571418583393,
            0.007948420010507107,
            0.01261247880756855,
            0.01200887095183134,
            0.0014409760478883982,
            0.004449948202818632,
            -0.007099698297679424,
            -0.011280949227511883,
            -0.011714017949998379,
            -0.007512975018471479,
            0.003386399243026972,
            -0.0009370135376229882,
            -0.0275629423558712,
            -0.0031728537287563086,
            0.025972530245780945,
            0.021457482129335403,
            0.022655272856354713,
            0.009406797587871552,
            0.011892260052263737,
            0.014911731705069542,
            -0.02283557504415512,
            -0.014799771830439568,
            0.020174473524093628,
            0.0023241329472512007,
            0.02533820830285549,
            0.007026937324553728,
            0.004074776079505682,
            -0.008591697551310062,
            0.0255277156829834,
            0.025897039100527763,
            0.018608083948493004,
            0.008462589234113693,
            -0.022444987669587135,
            0.00706840306520462,
            -0.020763760432600975,
            -0.012015875428915024,
            0.052160780876874924,
            0.020139772444963455,
            -0.018411699682474136,
            -0.0038814335130155087,
            0.00415022112429142,
            -0.022339196875691414,
            -0.018458306789398193,
            -0.001347272307612002,
            -0.020842861384153366,
            -0.0401972271502018,
            0.015748726204037666,
            0.007457980420440435,
            -0.011598127894103527,
            0.009370679967105389,
            0.009356815367937088,
            -0.014163837768137455,
            -0.00974330771714449,
            0.007318874821066856,
            -0.020853843539953232,
            -0.015225286595523357,
            -0.01951158232986927,
            0.015108194202184677,
            0.0032399664632976055,
            0.0058473558165133,
            -0.03127732872962952,
            0.028071600943803787,
            -0.0023796716704964638,
            -0.01457047089934349,
            0.02673402987420559,
            0.006812152452766895,
            -0.004226196091622114,
            0.015564704313874245,
            -0.0030705444514751434,
            0.03336773067712784,
            0.002978430362418294,
            0.016422735527157784,
            -0.0304072555154562,
            -0.013597985729575157,
            -0.007873176597058773,
            0.0316159650683403,
            -0.010246293619275093,
            -0.008770466782152653,
            0.013777948915958405,
            0.03283374756574631,
            -0.04383134841918945,
            0.03394804149866104,
            -0.025012917816638947,
            0.0025666377041488886,
            -0.008463212288916111,
            -0.002928616711869836,
            0.01557711511850357,
            0.03049115091562271,
            -0.016712259501218796,
            -0.021233640611171722,
            0.006386158987879753,
            -0.04688435420393944,
            -0.0031541550997644663,
            0.06315445154905319,
            0.03318306803703308,
            -0.005609860643744469,
            -0.004723021294921637,
            0.0001261633588001132,
            0.011269032955169678,
            0.017998050898313522,
            0.02272767759859562,
            -0.027441956102848053,
            -0.04904493689537048,
            -0.005501951090991497,
            0.01217254251241684,
            0.046804238110780716,
            -0.024490153416991234,
            -0.016259409487247467,
            -0.030639110133051872,
            -0.010905356146395206,
            0.012138965539634228,
            0.0016212551854550838,
            0.025824392214417458,
            0.0006312079494819045,
            -0.010728730820119381,
            0.025277001783251762,
            -0.02416212297976017,
            -0.016336938366293907,
            -0.02325408346951008,
            0.024747485294938087,
            -0.016323350369930267,
            0.033308982849121094,
            -0.019927842542529106,
            0.02192481979727745,
            -0.014401651918888092,
            0.021292392164468765,
            -0.022620683535933495,
            -0.038634080439805984,
            -0.005438465625047684,
            -0.007700640708208084,
            0.03256995603442192,
            0.028909575194120407,
            -0.019278952851891518,
            0.01954721286892891,
            0.008552411571145058,
            -0.012767596170306206,
            -0.010857121087610722,
            0.022449426352977753,
            0.018043173477053642,
            -0.005117527209222317,
            0.006763551849871874,
            0.022407880052924156,
            0.0012735038762912154,
            0.002879152074456215,
            0.018886925652623177,
            -0.02399514429271221,
            -0.008109956048429012,
            0.02026299573481083,
            -0.04860501363873482,
            -0.03110400214791298,
            -0.003653872525319457,
            -0.019423242658376694,
            0.02218792773783207,
            0.025233982130885124,
            0.009186805225908756,
            0.004431701730936766,
            -0.026931384578347206,
            -0.03178201988339424,
            -0.016191506758332253,
            0.001166146364994347,
            -0.0032812210265547037,
            0.004190666601061821,
            -0.004141955636441708,
            -0.0005758100305683911,
            0.017225001007318497,
            0.024631980806589127,
            -0.01959334872663021,
            0.04159187898039818,
            -0.016565706580877304,
            -0.040119122713804245,
            -0.007411211729049683,
            -0.01654197834432125,
            -0.0035945726558566093,
            0.008367824368178844,
            0.030969776213169098,
            -0.011455310508608818,
            -0.01587948203086853,
            -0.009413696825504303,
            -0.030209627002477646,
            0.03151468560099602,
            0.00512089766561985,
            0.024240991100668907,
            0.02069932036101818,
            0.019629143178462982,
            -0.03580503165721893,
            -0.02877068519592285,
            -0.0034900433383882046,
            0.04167918860912323,
            0.0007600004901178181,
            -0.004240421112626791,
            -0.0010543831158429384,
            -0.03832050785422325,
            0.02837040089070797,
            -0.034633491188287735,
            0.01760099269449711,
            -0.024978628382086754,
            -0.0009163417853415012,
            -0.011229148134589195,
            0.010516961105167866,
            0.059694286435842514,
            -0.021221378818154335,
            -0.014424136839807034,
            -0.014809546060860157,
            -0.04311280697584152,
            -0.026902517303824425,
            -0.008121917955577374,
            -0.03835082799196243,
            0.04631761088967323,
            -0.02882067672908306,
            0.017372190952301025,
            -0.002477998612448573,
            -0.03142908215522766,
            0.0011287390952929854,
            0.000355237047187984,
            0.023044215515255928,
            -0.0033992701210081577,
            0.015215747058391571,
            0.03789817541837692,
            -0.027821339666843414,
            0.026281317695975304,
            -0.0028099205810576677,
            0.019293418154120445,
            0.01057069469243288,
            -0.02428629994392395,
            0.004462347831577063,
            -0.013574265874922276,
            -0.009599275887012482,
            -0.003378495806828141,
            -0.008577092550694942,
            -0.00040691590402275324,
            0.019682886078953743,
            -0.02809208258986473,
            0.025541141629219055,
            0.002513061510398984,
            -0.022440079599618912,
            0.017509695142507553,
            0.009091870859265327,
            -0.01096190232783556,
            -0.018841823562979698,
            0.015475397929549217,
            0.01953825354576111,
            0.005798506084829569,
            -0.013845881447196007,
            0.010332467965781689,
            0.0015142785850912333,
            -0.014071031473577023,
            0.006772644817829132,
            0.03256993740797043,
            0.013617145828902721,
            0.007708252407610416,
            -0.030908718705177307,
            0.03255215287208557,
            -0.014306027442216873,
            0.0020904780831187963,
            -0.005160890519618988,
            0.037129130214452744,
            -0.011129602789878845,
            0.008916675113141537,
            0.017100133001804352,
            -0.04112769663333893,
            -0.013037561438977718,
            -0.0005624897894449532,
            -0.0019328980706632137,
            -0.011272101663053036,
            0.024663250893354416,
            0.004511848092079163,
            0.012401076965034008,
            0.0037734133657068014,
            0.035595253109931946,
            0.0030626491643488407,
            -0.00960121676325798,
            0.00545588880777359,
            -0.009469174779951572,
            -0.010929127223789692,
            0.009107837453484535,
            0.0007100087241269648,
            -0.015513621270656586,
            0.01716950535774231,
            -0.048481907695531845,
            0.0037136529572308064,
            0.01724502630531788,
            -0.012226756662130356,
            -0.03481002524495125,
            -0.024604475125670433,
            -0.025971340015530586,
            -0.023490577936172485,
            0.01404581405222416,
            0.015938110649585724,
            0.008728805929422379,
            -0.026778733357787132,
            -0.027889788150787354,
            -0.018180612474679947,
            -0.00982991885393858,
            0.038693562150001526,
            -0.010586890392005444,
            -0.027878668159246445,
            0.028000211343169212,
            0.01751210354268551,
            -0.015668945387005806,
            -0.010113303549587727,
            -0.008890506811439991,
            0.0013174075866118073,
            0.014600795693695545,
            -0.014671609736979008,
            -0.004531423095613718,
            -0.0359942801296711,
            0.011189764365553856,
            -0.016596516594290733,
            -0.026435356587171555,
            -0.033047936856746674,
            -0.06429670006036758,
            0.010595520958304405,
            -0.017289739102125168,
            0.022013798356056213,
            0.01783894933760166,
            -0.0071172756142914295,
            0.01752193458378315,
            -0.03663605824112892,
            0.010652981698513031,
            -0.008031762205064297,
            -0.029700960963964462,
            -0.023255912587046623,
            -0.006648835260421038,
            0.019461413845419884,
            0.024403994902968407,
            -0.020199235528707504,
            -8.80980005604215e-05,
            0.010994107462465763,
            0.04169059917330742,
            -0.028923865407705307,
            -0.0024571113754063845,
            0.016988152638077736,
            0.0037328924518078566,
            -0.0039840866811573505,
            0.021236170083284378,
            0.020653584972023964,
            -0.007097990717738867,
            0.02705838717520237,
            0.0007624225690960884,
            -0.016810592263936996,
            -0.011404615826904774,
            -0.02105988934636116,
            -0.014703419059515,
            -0.026298779994249344,
            0.0076354085467755795,
            0.009148498997092247,
            0.020915791392326355,
            0.018779581412672997,
            0.0044438098557293415,
            0.016105594113469124,
            0.037914879620075226,
            0.027745455503463745,
            -0.023719429969787598,
            0.00206878874450922,
            -0.011534314602613449,
            0.015721313655376434,
            -0.03010396659374237,
            0.014608270488679409,
            -0.030293498188257217,
            -0.014983526431024075,
            0.020717797800898552,
            -0.006748411804437637,
            0.0003356030792929232,
            -0.022610802203416824,
            0.027808774262666702,
            0.026536744087934494,
            0.004184686578810215,
            -0.014103153720498085,
            0.0012763225240632892,
            0.026180807501077652,
            -0.003497509751468897,
            -0.03402586653828621,
            -0.026915598660707474,
            0.0012481219600886106,
            0.013130980543792248,
            -0.0355650819838047,
            0.023898793384432793,
            0.020969994366168976,
            0.009096121415495872,
            -0.038772061467170715,
            0.04093613848090172,
            -0.01721991039812565,
            -0.015181293711066246,
            0.015186200849711895,
            0.025429170578718185,
            0.020150132477283478,
            0.008101576939225197,
            -0.0071204714477062225,
            -0.027876360341906548,
            -0.03134797886013985,
            0.022017449140548706,
            0.015866439789533615,
            0.017328711226582527,
            0.029646683484315872,
            -0.035937611013650894,
            0.019080620259046555,
            -0.012410172261297703,
            -0.025937044993042946,
            0.025359181687235832,
            -0.009776744060218334,
            -0.022304542362689972,
            -0.02018161490559578,
            -0.017198951914906502,
            -0.006922085303813219,
            0.025914812460541725,
            0.008393107913434505,
            -0.04031280055642128,
            -0.03235214948654175,
            0.03363967314362526,
            0.006337032187730074,
            0.02430984377861023,
            -0.002081753918901086,
            0.020580315962433815,
            -0.02519593946635723,
            -0.0220132265239954,
            -0.0330306738615036,
            -0.003256509080529213,
            0.010883638635277748,
            0.014435715042054653,
            -0.028513459488749504,
            -0.03980623930692673,
            -0.0074562025256454945,
            -0.002292744582518935,
            -0.016082139685750008,
            -0.009476792998611927,
            -0.0021719960495829582,
            0.03777574747800827,
            -0.021096834912896156,
            0.008871006779372692,
            -0.02478659898042679,
            0.011052587069571018,
            0.002396166790276766,
            -0.045990388840436935,
            0.025875413790345192,
            0.015744009986519814,
            0.002787768840789795,
            -0.0014094669604673982,
            0.039763107895851135,
            -0.006566834636032581,
            0.026569928973913193,
            -0.0017712144181132317,
            -0.01788179948925972,
            0.05540032684803009,
            -0.028165046125650406,
            0.023156555369496346,
            -0.02238398976624012,
            -0.0055420068092644215,
            -0.014369669370353222,
            0.02056151255965233,
            -0.017214639112353325,
            -0.004795925226062536,
            0.011318514123558998,
            0.020002231001853943,
            -0.014432047493755817,
            0.01635914295911789,
            0.02858046069741249,
            -0.0025352798402309418,
            0.010556602850556374,
            -0.037276409566402435,
            0.03777164965867996,
            0.019119907170534134,
            -0.0211324505507946,
            0.013133780099451542,
            -0.0044863359071314335,
            0.015098347328603268,
            -0.04255354404449463,
            -0.01643787883222103,
            0.028308380395174026,
            0.018323469907045364,
            0.037616949528455734,
            -0.016305997967720032,
            0.00429806113243103,
            -0.020131081342697144,
            0.01860133558511734,
            0.015642164275050163,
            0.0307324156165123,
            -0.0021554110571742058,
            -0.036223068833351135,
            0.01219685934484005,
            -0.08059540390968323,
            0.028015196323394775,
            0.014055849052965641,
            0.030971406027674675,
            -0.0038672129157930613,
            -0.009204791858792305,
            0.035303547978401184,
            0.008967751637101173,
            0.013947742059826851,
            -0.02008805051445961,
            0.004976052325218916,
            -0.027573907747864723,
            -0.011462599970400333,
            0.021660353988409042,
            0.016391340643167496,
            0.010916324332356453,
            -0.007155301049351692,
            -0.014191803522408009,
            -0.017618300393223763,
            -0.010463274084031582,
            0.030145589262247086,
            -0.007425860967487097,
            0.016731401905417442,
            0.0018066111952066422,
            -0.015950685366988182,
            0.007506421767175198,
            -0.003206251421943307,
            -0.00702973548322916,
            0.028750291094183922,
            0.013592800125479698,
            0.00017023015243466944,
            -0.013166435062885284,
            0.030035270377993584,
            -0.007504770066589117,
            -0.02459142729640007,
            0.024167759343981743,
            0.002058593323454261,
            0.0013441040646284819,
            0.0008003557450138032,
            -0.021660832688212395,
            0.007200803142040968,
            -0.0044922856613993645,
            0.04833654314279556,
            -0.026982754468917847,
            -0.022254876792430878,
            -0.0022927685640752316,
            0.005119455046951771,
            -0.01540635246783495,
            -0.02484237588942051,
            0.01721138320863247,
            0.010396474972367287,
            -0.0048282565549016,
            0.024304071441292763,
            0.01613699086010456,
            0.00300968112424016,
            -0.003033246612176299,
            0.009492634795606136,
            0.00022877188166603446,
            -0.0018585704965516925,
            0.042999543249607086,
            -0.007198812905699015,
            -0.012691470794379711,
            0.025271939113736153,
            -0.03853204473853111,
            0.01712162047624588,
            -0.012798883952200413,
            -0.02665773034095764,
            0.005332678556442261,
            -0.012516330927610397,
            -0.011875949800014496,
            0.025232644751667976,
            0.015376012772321701,
            -0.012107755988836288,
            -0.004002861212939024,
            0.022224560379981995,
            0.028802664950489998,
            0.008742358535528183,
            -0.025734050199389458,
            0.0018512890674173832,
            0.028383076190948486,
            -0.030886443331837654,
            0.004686517640948296,
            0.034973036497831345,
            0.040247075259685516,
            -0.03132188320159912,
            -0.023294799029827118,
            0.02555285021662712,
            -0.004365353845059872,
            -0.0008253409760072827,
            -0.034528762102127075,
            -0.056846749037504196,
            0.023892996832728386,
            -0.038846731185913086,
            0.005694681312888861,
            0.00108101696241647,
            0.02109486795961857,
            -0.011452089995145798,
            0.018979281187057495,
            -0.012467296794056892,
            -0.00021806599397677928,
            0.023858163505792618,
            -0.018939480185508728,
            4.781871393788606e-05,
            0.008856619708240032,
            0.005392783787101507,
            0.011043512262403965,
            -0.034339044243097305,
            0.014963834546506405,
            0.012084724381566048,
            -0.031612928956747055,
            -0.021884404122829437,
            0.02436617948114872,
            -0.0005523050203919411,
            0.028794065117836,
            0.0017192483646795154,
            -0.0009661481599323452,
            0.015584392473101616,
            0.008041474036872387,
            0.024527136236429214,
            -0.010288654826581478,
            0.0003474439727142453,
            0.02165450155735016,
            -0.02914605662226677,
            0.004851985722780228,
            -0.011115986853837967,
            -0.009517280384898186,
            0.001456586760468781,
            0.005237795878201723,
            0.004923032131046057,
            -0.018262367695569992,
            -0.04504379630088806,
            -0.0019967197440564632,
            -0.02808714658021927,
            -0.013783149421215057,
            -0.027345921844244003,
            -0.02318727970123291,
            0.018363650888204575,
            -0.03201080486178398,
            0.008524060249328613,
            0.03317679464817047,
            -0.0056530870497226715,
            0.018707726150751114,
            -0.00516986008733511,
            0.013787588104605675,
            -0.0021990295499563217,
            -0.012239158153533936,
            0.01256895624101162,
            0.00441741943359375,
            -0.022113407030701637,
            -0.010875037871301174,
            -0.009866599924862385,
            -0.01973753236234188,
            0.038060206919908524,
            -0.0077359373681247234,
            0.01985270157456398,
            0.024041060358285904,
            0.02041533589363098,
            -0.04619809612631798,
            -0.04919882118701935,
            0.006896581966429949,
            -0.023516738787293434,
            0.005158346612006426,
            0.016289692372083664,
            0.011329596862196922,
            -0.014083190821111202,
            -0.012631063349545002,
            0.03172247111797333,
            0.010587153024971485,
            -0.017095178365707397,
            0.02517826296389103,
            0.007824493572115898,
            -0.009039179421961308,
            0.01325559988617897,
            0.017482087016105652,
            -0.010497735813260078,
            0.006836819462478161,
            -0.0018064961768686771,
            0.029976779595017433,
            -0.004072020296007395,
            -0.006548283156007528,
            0.02120429463684559,
            -0.00026856461772695184,
            -0.024596795439720154,
            -0.007912657223641872,
            -0.006368032656610012,
            -0.012036657892167568,
            -0.002330299001187086,
            -0.037405457347631454,
            0.010818722657859325,
            0.052426405251026154,
            0.015861520543694496,
            -0.03483803570270538,
            -0.017826378345489502,
            0.011498712003231049,
            0.027335025370121002,
            0.005686370190232992,
            0.002211784478276968,
            -0.02910631150007248,
            -0.031173035502433777,
            -0.000984798651188612,
            0.0029202515725046396,
            0.03218352794647217,
            0.026315080001950264,
            -0.008418411947786808,
            -0.014034539461135864,
            -0.004311388824135065,
            0.013056669384241104,
            -0.008800306357443333,
            -0.04112282767891884,
            0.018740547820925713,
            0.0239355880767107,
            0.008680401369929314,
            0.010691380128264427,
            0.0028085950762033463,
            0.008268642239272594,
            0.033131182193756104,
            -0.003090580692514777,
            0.03580200672149658,
            0.023440493270754814,
            0.0171086136251688,
            0.012826668098568916,
            0.04501885548233986,
            -0.01943804882466793,
            0.03719457611441612,
            -0.03744736686348915,
            -0.01021743007004261,
            -0.009674184024333954,
            0.009984434582293034,
            -0.008222340606153011,
            -0.011276439763605595,
            0.03132238611578941,
            0.016540126875042915,
            -0.046766579151153564,
            0.01770717464387417,
            -0.016414310783147812,
            0.033345118165016174,
            -0.01248901430517435,
            0.002399497665464878,
            -0.021900184452533722,
            -0.02015736699104309,
            -0.02480371668934822,
            -0.013947674073278904,
            0.003159081097692251,
            0.005013287998735905,
            0.034796394407749176,
            0.03076210431754589,
            0.014000628143548965,
            0.006923880893737078,
            -0.01953856274485588,
            0.01319410465657711,
            0.03917301818728447,
            0.02079193666577339,
            0.022714614868164062,
            0.021517520770430565,
            -0.03928445652127266,
            0.019482390955090523,
            -0.026355624198913574,
            0.030757412314414978,
            0.004158560186624527,
            -0.0006588684627786279,
            0.0108654098585248,
            0.029136735945940018,
            -0.02614612877368927,
            0.03185122832655907,
            -0.018441420048475266,
            0.014308901503682137,
            -0.011236944235861301,
            0.033359378576278687,
            0.01777581311762333,
            -0.021840162575244904,
            0.0024292566813528538,
            -0.019391650334000587,
            0.02015286684036255,
            -0.00511631416156888,
            -0.02677656151354313,
            -0.015675505623221397,
            0.024882042780518532,
            -0.007747092749923468,
            -0.009686440229415894,
            0.021802978590130806,
            0.03267071023583412,
            0.030573904514312744,
            0.02650962397456169,
            0.028823459520936012,
            -0.014210036024451256,
            0.01070654671639204,
            -0.03301285207271576,
            -0.0038136483635753393,
            0.01903168484568596,
            -0.0349867157638073,
            -0.010132711380720139,
            0.01772891916334629,
            0.038308124989271164,
            -0.0014702249318361282,
            0.017552731558680534,
            0.005463310517370701,
            0.037334199994802475,
            0.020734181627631187,
            0.035855796188116074,
            -0.01839028298854828,
            0.02623879350721836,
            0.009555671364068985,
            0.01149930339306593,
            -0.030936209484934807,
            -0.04785141348838806,
            -0.010281218215823174,
            0.022507337853312492,
            0.016650212928652763,
            -0.01979408599436283,
            0.006747087929397821,
            0.009963714517652988,
            0.008792036212980747,
            0.016820112243294716,
            -0.01956852898001671,
            0.009845295920968056,
            0.008614317514002323,
            -0.03237834572792053,
            -0.017570871859788895,
            -0.030912980437278748,
            0.023851780220866203,
            0.002816068474203348,
            -0.0038956196513026953,
            -0.044091787189245224,
            0.017146769911050797,
            -0.02905222587287426,
            -0.01434258185327053,
            0.04582757130265236,
            -0.021426232531666756,
            -0.02798118256032467,
            -0.0013703258009627461,
            0.044214505702257156,
            0.005363457836210728,
            0.013233176432549953,
            -0.016081897541880608,
            -0.001119798282161355,
            0.01246325671672821,
            -0.007829328067600727,
            0.0006043895846232772,
            0.01929456740617752,
            -0.011545603163540363,
            -0.0056433118879795074,
            0.024579789489507675,
            -0.023085342720150948,
            -0.00015828129835426807,
            -0.01769944094121456,
            0.000484860735014081,
            -0.03015987016260624,
            -0.02328319288790226,
            -0.03811870515346527,
            7.317827112274244e-05,
            -0.006621944718062878,
            -0.004186002071946859,
            -0.0038075759075582027,
            0.026353951543569565,
            -0.010563269257545471,
            -0.004766829311847687,
            0.017321301624178886,
            -0.028552567586302757,
            0.01768828183412552,
            0.01932506263256073,
            0.033590324223041534,
            -0.0016117937630042434,
            0.012644479982554913,
            -0.02374120242893696,
            -0.025884151458740234,
            -0.00799465924501419,
            0.015040168538689613,
            0.011838598176836967,
            -0.004243688192218542,
            -0.015371764078736305,
            0.026160648092627525,
            -0.010309925302863121,
            0.007789434399455786,
            -0.029864119365811348,
            0.035814378410577774,
            -0.015538720414042473,
            0.024500349536538124,
            0.010721192695200443,
            0.016865931451320648,
            0.04547515884041786,
            0.009712846018373966,
            -0.025636183097958565,
            0.028576558455824852,
            -0.007938740774989128,
            0.009783554822206497,
            0.0027998669538646936,
            0.025649795308709145,
            -0.005961044225841761,
            0.04345444589853287,
            0.0057479338720440865,
            -0.04212121665477753,
            -0.013749836012721062,
            0.003039934439584613,
            0.03862289711833,
            -0.01140378788113594,
            0.0004418434400577098,
            -0.0431702546775341,
            0.007452272344380617,
            0.018621930852532387,
            0.01310672052204609,
            -0.03277289494872093,
            -0.0038221103604882956,
            -0.015973633155226707,
            0.013404150493443012,
            -0.006322153378278017,
            0.013490617275238037,
            -0.0031936122104525566,
            0.025573991239070892,
            0.014134716242551804,
            -0.015010458417236805,
            -0.0002315009623998776,
            0.009816613979637623,
            -0.022290535271167755,
            0.021722236648201942,
            -0.006486124824732542,
            -0.035575419664382935,
            0.039287369698286057,
            0.011415490880608559,
            -0.012416278943419456,
            -0.003376738401129842,
            0.0022446427028626204,
            -0.012811405584216118,
            -0.012477114796638489,
            0.013084451667964458,
            -0.0076565127819776535,
            0.02465788461267948,
            -0.018745960667729378,
            0.01377194095402956,
            0.013195599429309368,
            -0.043275877833366394,
            -0.008811676874756813,
            0.00229054456576705,
            0.011426541022956371,
            -0.007747027091681957,
            0.030458655208349228,
            -0.007104512304067612,
            0.024227621033787727,
            0.040579572319984436,
            0.022111034020781517,
            -0.026253562420606613,
            -0.0003073729749303311,
            -0.005055093206465244,
            -0.017015939578413963,
            -0.021085364744067192,
            0.021218018606305122,
            -0.014396931044757366,
            0.006735057570040226,
            -0.019763324409723282,
            -0.02400858886539936,
            0.002595823258161545,
            0.02306641824543476,
            -0.006720876321196556,
            0.018182627856731415,
            0.026109082624316216,
            0.030955135822296143,
            0.006407845299690962,
            0.006979738362133503,
            0.03246818482875824,
            0.0032195462845265865,
            -0.0168391652405262,
            -0.04756660386919975,
            0.031214067712426186,
            -0.01505481731146574,
            -0.02963300794363022,
            -0.0005099980044178665,
            0.009054798632860184,
            -0.008652452379465103,
            0.019823621958494186,
            0.01126357913017273,
            -0.016543444246053696,
            -0.03800635784864426,
            0.012256343849003315,
            0.03456547483801842,
            0.019432196393609047,
            0.006838218308985233,
            -0.020916800945997238,
            -0.00031591049628332257,
            0.007565767969936132,
            -0.019785217940807343,
            0.007330488879233599,
            0.0205715112388134,
            -0.007635951973497868,
            -0.027350736781954765,
            -0.024765171110630035,
            -0.0026667604688555002,
            -0.016697049140930176,
            -0.021641993895173073,
            -0.012761997058987617,
            0.005944235250353813,
            0.010262935422360897,
            0.05024293065071106,
            -0.018240898847579956,
            -0.01715337112545967,
            -0.033240653574466705,
            -0.009857418946921825,
            0.0028138342313468456,
            -0.029189568012952805,
            0.012425120919942856,
            0.03438515588641167,
            -0.018965624272823334,
            -0.023057401180267334,
            0.028192704543471336,
            -0.018027372658252716,
            0.012561523355543613,
            0.014354695565998554,
            0.025826508179306984,
            0.030997278168797493,
            -0.03208085894584656,
            0.03486787900328636,
            -0.025762494653463364,
            0.006652589421719313,
            -0.004847622476518154,
            7.347152131842449e-05,
            -0.0034217729698866606,
            -0.018606694415211678,
            -0.012029477395117283,
            0.01826685294508934,
            -0.018612656742334366,
            0.007679827511310577,
            -0.0014295424334704876,
            -0.0004092429007869214,
            0.001483089872635901,
            -0.022239865735173225,
            -0.001429132535122335,
            -0.043128833174705505,
            0.018270697444677353,
            -0.021155327558517456,
            -0.02975030243396759,
            -0.030165597796440125,
            0.009304926730692387,
            0.021402783691883087,
            -0.009028111584484577,
            0.0023164201993495226,
            0.020262977108359337,
            0.02909083664417267,
            0.03040424734354019,
            0.028411978855729103,
            0.010038556531071663,
            0.009649147279560566,
            0.008153037168085575
        ],
        [
            0.005090723745524883,
            0.008470609784126282,
            0.021801825612783432,
            0.025737188756465912,
            -0.005680555943399668,
            0.017225487157702446,
            -0.0033915494568645954,
            0.007711305283010006,
            0.022116784006357193,
            0.0007243672152981162,
            -0.05904055014252663,
            -0.026605304330587387,
            0.03884388506412506,
            -0.004789229016751051,
            0.04667926952242851,
            -0.02135932445526123,
            -0.003819182747974992,
            -0.01080798264592886,
            -0.02615978568792343,
            -0.01564904861152172,
            0.000726227939594537,
            0.0031840780284255743,
            -0.021166909486055374,
            0.047499168664216995,
            0.025744665414094925,
            0.03891544044017792,
            0.012176691554486752,
            0.04598807543516159,
            -0.022598996758461,
            -0.011915726587176323,
            -0.01616458222270012,
            0.025973329320549965,
            0.029048796743154526,
            -0.02987814135849476,
            0.0015800966648384929,
            0.0064476169645786285,
            0.003062828443944454,
            0.010971940122544765,
            -0.009403187781572342,
            -0.020512636750936508,
            0.004915900062769651,
            -0.006690701469779015,
            0.018800701946020126,
            -0.0037662391550838947,
            0.025008674710989,
            -0.012288779020309448,
            0.01350406650453806,
            -0.02869856357574463,
            -0.001691893907263875,
            -0.019480036571621895,
            0.005281783174723387,
            -0.022643450647592545,
            -0.003443051129579544,
            0.01560478750616312,
            0.04679311439394951,
            0.025852931663393974,
            -0.017987258732318878,
            -0.016627563163638115,
            0.01595780998468399,
            0.017901793122291565,
            0.018662691116333008,
            -0.019093122333288193,
            -0.033196594566106796,
            0.01606445200741291,
            -0.019199851900339127,
            -0.006617729086428881,
            -0.004945147782564163,
            0.027403874322772026,
            0.015179917216300964,
            -0.020378919318318367,
            -0.010323277674615383,
            -0.028093645349144936,
            -0.012070240452885628,
            0.030441490933299065,
            0.0368494987487793,
            0.031808242201805115,
            0.0010549313155934215,
            0.01838030107319355,
            -0.027067584916949272,
            0.010174287483096123,
            -0.0029159029945731163,
            -0.02508769929409027,
            0.011684629134833813,
            -0.026669781655073166,
            0.028430545702576637,
            0.023188693448901176,
            0.020379075780510902,
            0.002914000302553177,
            -0.03591837361454964,
            -0.006418078206479549,
            -0.01486390270292759,
            -0.004872622434049845,
            -0.007917267270386219,
            0.029252685606479645,
            0.005877417512238026,
            0.02061436139047146,
            0.03142642229795456,
            -0.011501324363052845,
            -0.06876277923583984,
            0.012897268868982792,
            0.023531315848231316,
            0.0310202743858099,
            0.00288369576446712,
            -0.031268272548913956,
            -0.01779073476791382,
            0.03164282441139221,
            0.027023546397686005,
            0.0139688765630126,
            -0.01072719320654869,
            0.004525063093751669,
            0.029083499684929848,
            -0.015066299587488174,
            0.027156701311469078,
            -0.015179320238530636,
            -0.07195322215557098,
            0.012964308261871338,
            0.018041478469967842,
            -0.029701951891183853,
            0.014967158436775208,
            0.013527055270969868,
            0.0019875180441886187,
            -0.01998523809015751,
            -0.02538232132792473,
            -0.0011097668902948499,
            0.030589168891310692,
            0.011859187856316566,
            -0.0080451350659132,
            -0.005847051274031401,
            0.010466648265719414,
            -0.022373275831341743,
            -0.014594952575862408,
            0.0007483690860681236,
            0.0053231483325362206,
            0.0322314128279686,
            -0.02554483339190483,
            -0.01527734100818634,
            0.005478556267917156,
            0.004642216954380274,
            -0.029318585991859436,
            0.007518399972468615,
            -0.026968402788043022,
            0.02690393291413784,
            0.013900640420615673,
            0.027670234441757202,
            0.04225925728678703,
            -0.008692996576428413,
            0.007084906101226807,
            0.012277590110898018,
            -0.016153043136000633,
            0.034592971205711365,
            -0.025994019582867622,
            -0.041121844202280045,
            -0.00983627513051033,
            0.002896711928769946,
            0.01296949852257967,
            -0.02191862463951111,
            0.013150716200470924,
            0.04749410226941109,
            -0.0018815216608345509,
            0.01886112615466118,
            0.028173470869660378,
            -0.029901692643761635,
            -0.011715724132955074,
            0.004973140545189381,
            -0.022670526057481766,
            0.028709525242447853,
            0.011102799326181412,
            0.013401255942881107,
            0.0031804873142391443,
            -0.026424864307045937,
            0.018010864034295082,
            0.001377531443722546,
            -0.0070312307216227055,
            0.02395884320139885,
            0.0137419318780303,
            -0.030404547229409218,
            -0.03263340890407562,
            -0.009454349987208843,
            -0.007416442967951298,
            0.04177917540073395,
            -0.0006079039303585887,
            -0.0020792135037481785,
            0.014321982860565186,
            -0.012340802699327469,
            -0.01637064851820469,
            -0.01118193008005619,
            0.023438429459929466,
            -0.016020098701119423,
            -0.034552253782749176,
            0.01264755055308342,
            -0.009447943419218063,
            -0.033198609948158264,
            0.008980799466371536,
            0.018745195120573044,
            0.027180958539247513,
            0.0318538136780262,
            -0.018214158713817596,
            0.017346054315567017,
            0.01953430101275444,
            -0.02216476760804653,
            0.003162577049806714,
            -0.0005856226780451834,
            0.0004898581537418067,
            -0.02390238642692566,
            0.020235661417245865,
            -0.004639642778784037,
            0.025650756433606148,
            0.007689973339438438,
            -0.0067769382148981094,
            0.012032529339194298,
            -0.021141985431313515,
            -0.03304500877857208,
            0.0013260159175843,
            0.019537923857569695,
            0.0013620798708871007,
            -0.008107730187475681,
            -0.0015999011229723692,
            -0.04002757370471954,
            -0.004965089727193117,
            -0.03009699657559395,
            0.040681999176740646,
            0.008592038415372372,
            -0.0013581051025539637,
            0.026901936158537865,
            0.005357823800295591,
            -0.025387907400727272,
            0.026136068627238274,
            -0.027835845947265625,
            -0.004704433027654886,
            0.013852034695446491,
            -0.040132179856300354,
            0.023872563615441322,
            -0.06293030828237534,
            0.01223753858357668,
            -0.03955984488129616,
            -0.006446876563131809,
            -0.010062609799206257,
            -0.0029079769738018513,
            0.0009914397960528731,
            -0.002317422069609165,
            -0.0002045691362582147,
            0.0015876994002610445,
            -0.034991953521966934,
            0.008816651068627834,
            0.027591710910201073,
            -0.018995286896824837,
            -0.023993197828531265,
            -0.015320632606744766,
            -0.029388288035988808,
            -0.010265286080539227,
            0.024892648681998253,
            0.01862468384206295,
            -0.021510453894734383,
            -0.010048586875200272,
            0.037326741963624954,
            -0.004574371501803398,
            0.020210659131407738,
            -0.017833342775702477,
            0.008669940754771233,
            -0.001534965937025845,
            0.005632794927805662,
            0.012122120708227158,
            -0.0016646868316456676,
            -0.0037430543452501297,
            0.027560850605368614,
            -0.04599399492144585,
            -0.00529653811827302,
            -0.004563453141599894,
            0.0044766259379684925,
            -0.009182361885905266,
            0.028104456141591072,
            -0.017688976600766182,
            -0.015292322263121605,
            0.020504750311374664,
            -0.002932838164269924,
            0.005923743359744549,
            0.021230245009064674,
            -0.020556241273880005,
            0.006254933774471283,
            -0.019982239231467247,
            -0.0032321407925337553,
            0.04841000959277153,
            -0.01617208495736122,
            -0.019798370078206062,
            -0.0019873606506735086,
            -0.008268327452242374,
            0.010610072873532772,
            0.019644279032945633,
            0.0194900780916214,
            0.01666605845093727,
            0.020336028188467026,
            -0.01708037778735161,
            0.025603104382753372,
            0.0017939426470547915,
            0.024807529523968697,
            0.014957782812416553,
            0.023350641131401062,
            0.003931101877242327,
            -0.006462541408836842,
            -0.025715403258800507,
            -0.026738978922367096,
            -0.017793452367186546,
            0.021807599812746048,
            -0.006797113921493292,
            -0.017662640661001205,
            0.02092559263110161,
            -0.0063608488999307156,
            -0.028864093124866486,
            0.0013868768000975251,
            0.04172623157501221,
            -0.02867216058075428,
            0.020652003586292267,
            -0.004956918302923441,
            -0.005224068649113178,
            -0.003974026069045067,
            -0.035338662564754486,
            -0.016930479556322098,
            0.02036222256720066,
            -0.0028710956685245037,
            -0.013644835911691189,
            0.027379127219319344,
            -0.06755038350820541,
            -0.035070061683654785,
            -0.03326787054538727,
            0.02317962609231472,
            -0.032900601625442505,
            0.032617922872304916,
            0.017909830436110497,
            -0.0032063177786767483,
            0.010278531350195408,
            -0.018053511157631874,
            -0.03032912127673626,
            0.011303408071398735,
            0.0385483019053936,
            0.021991560235619545,
            0.008281911723315716,
            -0.030448703095316887,
            0.03153067082166672,
            0.022216718643903732,
            0.016282210126519203,
            0.0027570698875933886,
            -0.0002003027475439012,
            0.03229694813489914,
            -0.0037681511603295803,
            0.014876337721943855,
            -0.026229187846183777,
            0.025374935939908028,
            -0.004759808070957661,
            -0.002611019415780902,
            -0.007980071939527988,
            -0.013698450289666653,
            -0.04260438680648804,
            -0.01590667851269245,
            -0.04856085777282715,
            0.035866837948560715,
            -0.028308670967817307,
            0.014327514916658401,
            -0.015390555374324322,
            -0.020140862092375755,
            -0.03239620849490166,
            -0.013181227259337902,
            0.007318929303437471,
            0.01575385592877865,
            -0.01697164587676525,
            -0.010966969653964043,
            0.011340911500155926,
            0.03495321050286293,
            0.016573937609791756,
            0.006897955667227507,
            -0.02215840108692646,
            -0.03453371673822403,
            0.02487519383430481,
            -0.0015874759992584586,
            0.0037461058236658573,
            -0.006283925846219063,
            -0.0038427182007580996,
            -0.0013347358908504248,
            -0.02594425342977047,
            -0.022661983966827393,
            0.02277887798845768,
            0.040562745183706284,
            0.02869519218802452,
            0.006812624633312225,
            0.026806199923157692,
            -0.05295465141534805,
            -0.025197407230734825,
            0.020907284691929817,
            -0.010936334729194641,
            -0.024320319294929504,
            0.024904681369662285,
            0.003512891009449959,
            -0.026793591678142548,
            0.0050871907733380795,
            -0.005606904625892639,
            0.012415284290909767,
            -0.017895346507430077,
            -0.026366185396909714,
            -0.035645391792058945,
            -0.002843576716259122,
            0.027646465227007866,
            0.025272544473409653,
            0.027426205575466156,
            0.027256960049271584,
            -0.019360439851880074,
            0.02032877504825592,
            0.00022182065004017204,
            0.005112174898386002,
            -0.015055118128657341,
            0.012486109510064125,
            -0.005522884428501129,
            0.013038826175034046,
            0.014229079708456993,
            -0.0011692311381921172,
            0.019261933863162994,
            0.05562042072415352,
            -0.015807297080755234,
            -0.03178228437900543,
            0.03845544531941414,
            0.0158491600304842,
            0.019349537789821625,
            0.04494643956422806,
            -0.021328028291463852,
            -0.032712772488594055,
            -0.02764606848359108,
            0.013007822446525097,
            0.031228501349687576,
            0.013920363038778305,
            0.013087733648717403,
            0.022980252280831337,
            0.005997175350785255,
            0.018574275076389313,
            0.026300113648176193,
            -0.022340167313814163,
            -0.00959092564880848,
            -0.017720768228173256,
            -0.007461595349013805,
            0.015001998282968998,
            -0.008246409706771374,
            0.014267866499722004,
            -0.028340060263872147,
            0.0015107049839571118,
            -0.048256006091833115,
            -0.0057155778631567955,
            0.00897287204861641,
            -0.006355881690979004,
            0.0070699662901461124,
            0.002717473078519106,
            -0.06304207444190979,
            0.027436016127467155,
            0.017171548679471016,
            -0.008762244135141373,
            0.015057834796607494,
            -0.02017803303897381,
            -0.039079342037439346,
            -0.01002798043191433,
            -0.008765078149735928,
            0.011446332558989525,
            0.028019843623042107,
            0.03607264533638954,
            -0.013819997198879719,
            0.019888000562787056,
            -0.0030579285230487585,
            0.017170971259474754,
            0.015324716456234455,
            -0.01990906521677971,
            0.026947731152176857,
            -0.062442846596241,
            -0.028271909803152084,
            0.022899480536580086,
            -0.029472630470991135,
            -0.024581803008913994,
            -0.0021609975956380367,
            -0.009692084044218063,
            0.023245379328727722,
            -0.019709598273038864,
            -0.031157448887825012,
            0.019025014713406563,
            -0.03557421267032623,
            -0.009986203163862228,
            0.00414576381444931,
            0.029333781450986862,
            0.03352167084813118,
            -0.0318361259996891,
            0.02794191986322403,
            -0.01870211772620678,
            0.015854880213737488,
            0.015461739152669907,
            -0.03690091520547867,
            -0.08595871180295944,
            0.023309417068958282,
            -0.03514191880822182,
            -0.02511649765074253,
            0.025770192965865135,
            -0.00643240986391902,
            -0.03582717478275299,
            -0.022362196817994118,
            -0.01371269766241312,
            0.018456699326634407,
            0.03561002016067505,
            -0.0015196512686088681,
            0.005552121438086033,
            -0.029113110154867172,
            -0.0356663353741169,
            0.0014804481761530042,
            -0.005477915983647108,
            0.011606166139245033,
            -0.01391848549246788,
            -0.010813062079250813,
            -0.03441859409213066,
            -0.03255555033683777,
            -0.018831385299563408,
            0.017580201849341393,
            -0.008359633386135101,
            0.002739450428634882,
            -0.0017048624577000737,
            0.01187919918447733,
            0.01197031419724226,
            0.006739174481481314,
            0.015433710999786854,
            -0.031200334429740906,
            -0.028513764962553978,
            -0.008497942239046097,
            0.010635362938046455,
            0.03410601615905762,
            -0.02680586650967598,
            -0.050265826284885406,
            0.055825039744377136,
            -0.01449564378708601,
            -0.020724495872855186,
            0.01890050619840622,
            -0.008439318276941776,
            0.00489211268723011,
            -0.03865695744752884,
            0.01362081989645958,
            -0.01395319402217865,
            -0.02961636148393154,
            0.028418440371751785,
            0.02152424119412899,
            0.02137741632759571,
            0.01887408271431923,
            -0.02180349826812744,
            -0.03879711404442787,
            0.030223138630390167,
            0.010258953087031841,
            0.020155340433120728,
            0.06265699863433838,
            0.030140984803438187,
            -0.016013778746128082,
            -0.00282889767549932,
            -0.006940645165741444,
            -0.002687618136405945,
            0.022938281297683716,
            0.005100656300783157,
            -0.005796584766358137,
            -0.017666976898908615,
            0.026288481429219246,
            0.009999518282711506,
            -0.0049497694708406925,
            -0.022699588909745216,
            0.024742504581809044,
            -0.029496701434254646,
            -0.03284773975610733,
            0.017312468960881233,
            -0.04774772748351097,
            0.0257117822766304,
            0.020819464698433876,
            -0.03537587821483612,
            -0.0020084239076822996,
            -0.02160877175629139,
            0.03216400742530823,
            0.008299735374748707,
            -0.008301830850541592,
            0.0078354487195611,
            -0.016893135383725166,
            0.019146190956234932,
            0.020749112591147423,
            0.007023342419415712,
            0.01288624107837677,
            0.02268393710255623,
            0.02814824879169464,
            -0.0013867190573364496,
            -0.00896606594324112,
            0.004066658206284046,
            0.0067766387946903706,
            0.003050051396712661,
            -0.04001714289188385,
            0.024247771129012108,
            0.007502904627472162,
            -0.0303513091057539,
            -0.018167076632380486,
            -6.313946505542845e-05,
            -0.030085988342761993,
            -0.0028310976922512054,
            -0.015937305986881256,
            -0.009648844599723816,
            0.010083631612360477,
            0.0037522839847952127,
            -0.014628376811742783,
            -0.022356024011969566,
            0.03323055058717728,
            0.014385447837412357,
            0.022277824580669403,
            0.020255664363503456,
            -0.00836316216737032,
            0.038560617715120316,
            0.01094607263803482,
            -0.004734373185783625,
            0.031583890318870544,
            -0.03126547858119011,
            -0.04370775446295738,
            -0.027194751426577568,
            0.023357808589935303,
            -0.025108523666858673,
            0.0005875550559721887,
            -0.013492831028997898,
            -0.0007959175272844732,
            0.02895776368677616,
            -0.004456664901226759,
            -0.015926744788885117,
            0.015785343945026398,
            -0.012047908268868923,
            -0.007754877209663391,
            0.027762483805418015,
            -0.026340452954173088,
            0.03207429498434067,
            -0.015668421983718872,
            0.027179032564163208,
            0.026730692014098167,
            -0.01475962158292532,
            0.015873799100518227,
            0.009106171317398548,
            -0.024787357077002525,
            0.030812690034508705,
            -0.0014851958258077502,
            0.016305135563015938,
            0.0017941297264769673,
            -0.006912448443472385,
            0.006648192182183266,
            -0.011793089099228382,
            0.0009606060921214521,
            0.005622356664389372,
            -0.02126774750649929,
            0.03649398311972618,
            -0.01171357836574316,
            0.02490825578570366,
            0.0011771605350077152,
            -0.005373581312596798,
            -0.022803423926234245,
            -0.008373542688786983,
            0.005736853461712599,
            0.029481790959835052,
            0.005419922061264515,
            0.030342260375618935,
            0.028717070817947388,
            0.03289519622921944,
            0.015580720268189907,
            0.010045466013252735,
            0.021964939311146736,
            -0.0004989260924048722,
            -0.012234775349497795,
            0.03590967133641243,
            0.014080887660384178,
            -0.023839449509978294,
            0.027713140472769737,
            0.0015168075915426016,
            -0.007163407746702433,
            -0.01176123321056366,
            0.018576012924313545,
            -0.01769918203353882,
            -0.03423859179019928,
            -0.02927052602171898,
            0.034847188740968704,
            0.023426849395036697,
            0.010232036001980305,
            0.030959462746977806,
            -0.017638789489865303,
            0.0033666579984128475,
            0.02756456658244133,
            -0.006397590972483158,
            -0.00048357227933593094,
            0.007975432090461254,
            -0.01834055967628956,
            0.029193419963121414,
            -0.00823359563946724,
            0.015441798605024815,
            -0.01123245619237423,
            -0.016731031239032745,
            -0.029236143454909325,
            -0.0035217946860939264,
            0.010655343532562256,
            -0.03126644715666771,
            -0.009557696990668774,
            0.015714546665549278,
            -0.03200173005461693,
            0.013869304209947586,
            0.006116409320384264,
            0.021169379353523254,
            -0.004992784932255745,
            0.02955939620733261,
            0.03107939101755619,
            0.029937488958239555,
            -0.0156418327242136,
            0.007663017604500055,
            0.005718799773603678,
            0.03197672218084335,
            0.021453090012073517,
            -0.03629373013973236,
            -0.01649322733283043,
            0.03322896361351013,
            -0.021326910704374313,
            -0.02175450511276722,
            0.0059700231067836285,
            -0.0011450283927842975,
            0.014191973023116589,
            -0.02385803684592247,
            0.022962259128689766,
            -0.056515056639909744,
            -0.00019573541067074984,
            0.011047112755477428,
            0.02528667077422142,
            0.02902480587363243,
            0.02885974384844303,
            0.006556909065693617,
            -0.055265091359615326,
            -0.017090104520320892,
            -0.02841551974415779,
            0.014459202066063881,
            0.016865136101841927,
            0.026406023651361465,
            0.02256019413471222,
            -0.011591892689466476,
            -0.0365590900182724,
            0.0023236037231981754,
            0.005311375949531794,
            -0.014011424966156483,
            -0.01802346669137478,
            -0.010270041413605213,
            -0.02025442011654377,
            -0.01376725360751152,
            0.032888419926166534,
            -0.03185194358229637,
            -0.015313012525439262,
            0.020654108375310898,
            0.023220308125019073,
            -0.0053196921944618225,
            -0.036454323679208755,
            0.011855736374855042,
            -0.029520366340875626,
            -0.01564878225326538,
            0.035018082708120346,
            -0.011994724161922932,
            -0.01887308992445469,
            0.007286898326128721,
            -0.06342614442110062,
            -0.0007311318186111748,
            -0.018804121762514114,
            0.03033256344497204,
            0.0004704877792391926,
            -0.012241602875292301,
            0.030181121081113815,
            0.002517538610845804,
            0.03323405981063843,
            -0.02075265906751156,
            -0.001739883329719305,
            0.0038308522198349237,
            0.0391542948782444,
            0.02810278907418251,
            0.020328067243099213,
            0.012632976286113262,
            0.029003268107771873,
            0.03664371371269226,
            0.03241356462240219,
            0.019227202981710434,
            0.02152809128165245,
            -0.015669506043195724,
            0.0034030049573630095,
            -0.01445846352726221,
            -0.04590398445725441,
            0.025439133867621422,
            0.03134831786155701,
            -0.0004905476816929877,
            0.020236516371369362,
            0.00043320105760358274,
            0.01082514226436615,
            -0.022502953186631203,
            -0.02168867737054825,
            0.0010664272122085094,
            -0.020522065460681915,
            -0.042053867131471634,
            0.02133025974035263,
            -0.014528268948197365,
            -0.02083340287208557,
            -0.0022986559197306633,
            -0.02536933682858944,
            -0.0533260703086853,
            -0.013891289010643959,
            0.014746411703526974,
            -0.02185591496527195,
            0.02031705342233181,
            0.0022802839521318674,
            0.014654486440122128,
            -0.011912360787391663,
            -0.015556558966636658,
            0.032537415623664856,
            -0.005171026568859816,
            0.017352120950818062,
            -0.018775973469018936,
            -0.025439457967877388,
            0.006766478531062603,
            0.020941568538546562,
            -0.04174301028251648,
            0.01589791662991047,
            0.0042429110035300255,
            0.01717803254723549,
            -0.00984692107886076,
            -0.01760806515812874,
            -0.01259614247828722,
            -0.0016098945634439588,
            0.020952703431248665,
            -0.012173962779343128,
            0.029473910108208656,
            0.027212990447878838,
            0.002993540605530143,
            0.017384814098477364,
            -0.02635282836854458,
            0.0352034717798233,
            -0.010302863083779812,
            -0.023276647552847862,
            0.02807578630745411,
            -0.001443606335669756,
            0.03546774014830589,
            0.01034740824252367,
            0.033594295382499695,
            -0.027228722348809242,
            0.023698436096310616,
            0.0032626711763441563,
            -0.0057226624339818954,
            0.014301082119345665,
            -0.020969407632946968,
            -0.027279671281576157,
            0.015641123056411743,
            -0.011304724030196667,
            -0.028709188103675842,
            -0.020279591903090477,
            -0.007836828008294106,
            -0.005995386280119419,
            -0.0008926482987590134,
            0.013940734788775444,
            -0.008778342045843601,
            -0.018351750448346138,
            0.01116195134818554,
            -0.025291163474321365,
            0.008081482723355293,
            0.0054317922331392765,
            -0.009965026751160622,
            0.02995947375893593,
            -0.0012506028870120645,
            0.026409365236759186,
            0.04426863417029381,
            0.007537854369729757,
            -0.01541917398571968,
            -0.019987981766462326,
            -0.010214685462415218,
            -0.01140095479786396,
            0.013874218799173832,
            -0.025023948401212692,
            0.03124423697590828,
            -0.038091741502285004,
            -0.015668626874685287,
            -0.025176038965582848,
            -0.017610963433980942,
            -0.02619217522442341,
            0.00576268183067441,
            -0.04084975644946098,
            -0.012135667726397514,
            -0.009440835565328598,
            -0.034767791628837585,
            -0.017922639846801758,
            0.0181359201669693,
            -0.02899208664894104,
            0.022457648068666458,
            -0.005397344473749399,
            0.008669721893966198,
            -0.009116980247199535,
            0.012394271790981293,
            -0.01607196405529976,
            0.02574414573609829,
            0.008285668678581715,
            -0.0025364449247717857,
            -0.005712392274290323,
            -0.022343825548887253,
            -0.027108123525977135,
            -0.03192705288529396,
            -0.007545068860054016,
            -0.013101832009851933,
            0.0032862690277397633,
            0.009240686893463135,
            -0.020925583317875862,
            -0.006535776890814304,
            -0.03463460132479668,
            0.023497391492128372,
            0.030137354508042336,
            0.02093290165066719,
            -0.010413391515612602,
            0.017447419464588165,
            0.016721375286579132,
            -0.007496686186641455,
            0.029964249581098557,
            -0.01977889984846115,
            -0.0061467611230909824,
            -0.0037182150408625603,
            0.012493696995079517,
            -0.037447746843099594,
            -0.018445227295160294,
            -0.04225349426269531,
            0.008500033989548683,
            0.03671615198254585,
            0.03314201533794403,
            0.01177236158400774,
            0.00669076619669795,
            0.02607879415154457,
            -0.008682295680046082,
            -0.002424781210720539,
            -0.003708758857101202,
            0.015616190619766712,
            -0.008398273959755898,
            0.004993277136236429,
            -0.04778777062892914,
            0.014060868881642818,
            -0.02307290956377983,
            -0.01123454887419939,
            -0.020724233239889145,
            0.013366353698074818,
            -0.007739736698567867,
            0.03471451625227928,
            0.003982262685894966,
            0.0018217177130281925,
            0.0060780528001487255,
            -0.02743244171142578,
            0.02995174378156662,
            -0.019692646339535713,
            0.017185460776090622,
            0.0386476144194603,
            -0.014110784977674484,
            -0.016664939001202583,
            0.029526660218834877,
            -0.006674407981336117,
            -0.012476034462451935,
            -0.008553843945264816,
            0.0319167785346508,
            0.05582687631249428,
            -0.006193140521645546,
            -0.0016721615102142096,
            0.031712912023067474,
            -0.05807732418179512,
            -0.014834528788924217,
            0.007829053327441216,
            -0.010294764302670956,
            0.016582101583480835,
            -0.03008459322154522,
            -0.009230141527950764,
            -0.04288402944803238,
            0.020822133868932724,
            -0.028743669390678406,
            -0.05440954118967056,
            -0.013048001565039158,
            -0.004996127914637327,
            0.005902962759137154,
            -0.07297223061323166,
            -0.01805417239665985,
            0.031315162777900696,
            -0.033049385994672775,
            -0.020280936732888222,
            -0.028049733489751816,
            -0.016953377053141594,
            -0.005504370667040348,
            0.003403402166441083,
            0.016011008992791176,
            -0.005093683023005724,
            -0.010300198569893837,
            -0.015623276121914387,
            -0.037913594394922256,
            0.00857813935726881,
            0.004605988040566444,
            -0.01435413770377636,
            0.008373578079044819,
            0.001475228345952928,
            -0.02256947010755539,
            -0.0014747354434803128,
            0.0323120579123497,
            0.009168276563286781,
            -0.0019227106822654605,
            -0.02251671813428402,
            -0.02037099562585354,
            0.02427174709737301,
            0.0070732347667217255,
            -0.009135766886174679,
            0.00950603187084198,
            0.005470020696520805,
            -0.007976211607456207,
            -0.039154838770627975,
            0.01374594122171402,
            -0.0051026311703026295,
            -0.01641976460814476,
            0.00928567722439766,
            -0.01986643858253956,
            -0.017712417989969254,
            -0.027265416458249092,
            0.006826105527579784,
            0.00961995404213667,
            -0.015943825244903564,
            0.03335847333073616,
            0.0008000617963261902,
            0.022411925718188286,
            0.019186880439519882,
            0.018898148089647293,
            0.013991065323352814,
            -0.015783101320266724,
            -0.01599937677383423,
            -0.01044798456132412,
            -0.022328738123178482,
            -0.027347832918167114,
            0.000518450397066772,
            -0.022670742124319077
        ],
        [
            -0.02882060594856739,
            0.03294232487678528,
            -0.005250024609267712,
            0.018851986154913902,
            0.0170424897223711,
            0.03906193748116493,
            -0.027612052857875824,
            0.0167401060461998,
            0.0017429743893444538,
            0.010180161334574223,
            -0.014327106066048145,
            -0.01950768567621708,
            0.004319883417338133,
            -0.02418811060488224,
            -0.001755811390466988,
            0.00010740036668721586,
            0.03899693116545677,
            0.03494775667786598,
            0.00413129897788167,
            0.01056853961199522,
            0.0222556684166193,
            0.014447850175201893,
            0.01368915569037199,
            0.00239601475186646,
            0.0136498948559165,
            0.018216881901025772,
            -0.04097290337085724,
            -0.025974582880735397,
            -0.02903822623193264,
            0.01501990295946598,
            0.02112962305545807,
            -0.013005328364670277,
            0.01246293168514967,
            -0.0034482404589653015,
            -0.009458834305405617,
            -0.010632972232997417,
            -0.0051204427145421505,
            -0.034566354006528854,
            0.03617473691701889,
            -0.02074887976050377,
            0.005132186226546764,
            0.023021042346954346,
            0.009138866327702999,
            0.00981235597282648,
            0.004197104834020138,
            0.04827522858977318,
            0.010238010436296463,
            -0.03923964127898216,
            -0.002250863006338477,
            -0.011590326204895973,
            0.003076057182624936,
            0.025357989594340324,
            -0.012277234345674515,
            0.008833151310682297,
            -0.006611720658838749,
            0.009234394878149033,
            -0.01761052757501602,
            0.00962876621633768,
            0.009766532108187675,
            0.023423628881573677,
            -0.0005083667929284275,
            0.021677490323781967,
            0.020552169531583786,
            0.017892342060804367,
            0.006591299083083868,
            0.017218392342329025,
            -0.009732388891279697,
            0.02193804830312729,
            0.011861737817525864,
            0.009606408886611462,
            0.007203628774732351,
            0.013059754855930805,
            -0.013471821323037148,
            -0.003346559125930071,
            -0.052967656403779984,
            0.011367385275661945,
            -0.017420846968889236,
            -0.004780956543982029,
            -0.0034775289241224527,
            -0.033276066184043884,
            0.012754412367939949,
            -0.014958802610635757,
            0.0204564668238163,
            -0.02141522988677025,
            -0.02881016954779625,
            -0.0049066562205553055,
            -0.0037655846681445837,
            -0.02422521263360977,
            -0.004405684303492308,
            -0.029784124344587326,
            0.04611239209771156,
            0.0010081693762913346,
            0.02734627015888691,
            -0.004526596050709486,
            0.032474394887685776,
            -0.02667079120874405,
            -0.0278520155698061,
            0.01870635896921158,
            -0.046539243310689926,
            0.009086486883461475,
            0.004875530023127794,
            0.008860155008733273,
            0.01771172322332859,
            -0.009661535732448101,
            -0.009565469808876514,
            -0.00021149612439330667,
            -0.030040722340345383,
            0.0072599146515131,
            -0.0002568708441685885,
            0.015279850922524929,
            -0.0045502642169594765,
            -0.0338481068611145,
            -0.01982499286532402,
            -0.02447599545121193,
            -0.015623515471816063,
            -0.009122495539486408,
            0.004007477778941393,
            -0.019970696419477463,
            0.008491139858961105,
            -0.012552179396152496,
            0.02783443219959736,
            -0.01527327299118042,
            -0.03437687084078789,
            -0.038042087107896805,
            0.02827407605946064,
            0.00833282433450222,
            0.01958235166966915,
            0.020651211962103844,
            0.023332148790359497,
            -0.014313368126749992,
            -0.03572642430663109,
            0.012614624574780464,
            -0.00200858386233449,
            0.017821239307522774,
            -0.019363882020115852,
            0.0018128795782104135,
            -0.015076661482453346,
            0.04228443279862404,
            0.012261303141713142,
            0.01949852705001831,
            0.01647677645087242,
            0.02642684057354927,
            0.019036617130041122,
            -0.03408754989504814,
            0.026774168014526367,
            0.015586834400892258,
            -0.011531131342053413,
            -0.021522201597690582,
            0.03769482299685478,
            0.02965068630874157,
            0.015992101281881332,
            0.003438510000705719,
            0.011203698813915253,
            -0.03589828312397003,
            0.0018472576048225164,
            0.033078696578741074,
            0.047206811606884,
            0.03353480249643326,
            -0.004951676819473505,
            -0.0059354910627007484,
            -0.010306892916560173,
            0.021278878673911095,
            0.014738398604094982,
            0.013397669419646263,
            0.032472435384988785,
            -0.013545841909945011,
            0.005377648863941431,
            -0.03270496428012848,
            -0.022047875449061394,
            0.014772186055779457,
            0.0032267309725284576,
            -0.003697624895721674,
            -0.0172811821103096,
            0.012915385887026787,
            0.003518782090395689,
            -0.0024530221708118916,
            -0.00646961247548461,
            -0.011466315016150475,
            -0.007027408108115196,
            0.04601984843611717,
            0.000804284936748445,
            0.006520527880638838,
            -0.04644211381673813,
            0.033627189695835114,
            0.014105984941124916,
            0.011612463742494583,
            -0.024333367124199867,
            -0.014143523760139942,
            0.02201869711279869,
            0.035562630742788315,
            0.003384464420378208,
            0.017704788595438004,
            -0.07295061647891998,
            0.010444635525345802,
            -0.012750474736094475,
            -0.034571386873722076,
            0.02755219116806984,
            0.020772382616996765,
            0.002790909493342042,
            -0.029178204014897346,
            0.021867340430617332,
            -0.02177395299077034,
            -0.029562348499894142,
            -0.007997320033609867,
            -0.044071368873119354,
            0.013388635590672493,
            0.0016773100942373276,
            0.018030034378170967,
            0.009475917555391788,
            -0.003338469658046961,
            0.027546510100364685,
            -0.0059056952595710754,
            -0.012795446440577507,
            -0.011281891725957394,
            -0.029025206342339516,
            -0.023856256157159805,
            -0.03424298018217087,
            0.006659489590674639,
            -0.026887325569987297,
            -0.01692917011678219,
            -0.0029298351146280766,
            0.029079748317599297,
            -0.012758794240653515,
            -0.0068344720639288425,
            0.014023128896951675,
            -0.004706291016191244,
            0.035965800285339355,
            0.001341661554761231,
            0.029266422614455223,
            0.022744689136743546,
            -0.04247397556900978,
            0.03731377050280571,
            0.022302156314253807,
            0.0001840853365138173,
            0.009194900281727314,
            -0.014051022008061409,
            0.026265781372785568,
            -0.0026429560966789722,
            -0.010349994525313377,
            0.008522309362888336,
            0.009619364514946938,
            -0.035855233669281006,
            0.005634620785713196,
            0.027019653469324112,
            0.045192211866378784,
            -0.03906308487057686,
            0.01381512638181448,
            0.029670696705579758,
            0.024609830230474472,
            -0.045659348368644714,
            0.008723174221813679,
            -0.0325164794921875,
            -0.03697331249713898,
            0.025257157161831856,
            -0.03136137127876282,
            0.02512260712683201,
            0.03445104509592056,
            -0.013677859678864479,
            -0.04447246715426445,
            0.03933993726968765,
            -0.012786359526216984,
            -0.037958450615406036,
            -0.018693765625357628,
            0.02621670998632908,
            -0.03126133605837822,
            -0.05462203174829483,
            0.0003020097210537642,
            -0.0008190884836949408,
            -0.019445817917585373,
            -0.02291753515601158,
            -0.006320734973996878,
            0.004821971524506807,
            0.02207249589264393,
            -0.0002723622019402683,
            0.0160248801112175,
            -0.008075601421296597,
            0.02051781862974167,
            -0.014896629378199577,
            -0.0037375991232693195,
            -0.004440031945705414,
            0.01206289418041706,
            0.015923283994197845,
            -0.023923266679048538,
            -0.03682849556207657,
            -0.02314060553908348,
            -0.02380121313035488,
            -0.030527757480740547,
            -0.05999300628900528,
            -0.02461608499288559,
            -0.036394041031599045,
            -0.025942876935005188,
            0.028510455042123795,
            -0.01288610976189375,
            -0.012255200184881687,
            -0.008782079443335533,
            0.02830364927649498,
            0.012291187420487404,
            -0.006924384273588657,
            -0.03044118732213974,
            0.007516840007156134,
            -0.010297971777617931,
            0.00429378729313612,
            0.014885269105434418,
            0.015355379320681095,
            -0.029398908838629723,
            0.014151671901345253,
            0.002865968970581889,
            0.03001573495566845,
            -0.028347214683890343,
            -0.012431664392352104,
            -0.0413392148911953,
            -0.010985611006617546,
            -0.007580889388918877,
            -0.014609693549573421,
            0.026826689019799232,
            0.005822064820677042,
            -0.025940265506505966,
            0.009708454832434654,
            -0.025083549320697784,
            0.00826575979590416,
            -0.02799989841878414,
            -0.05064600333571434,
            -0.029590358957648277,
            0.014595795422792435,
            0.007974556647241116,
            0.03837701678276062,
            -0.05285552144050598,
            0.021432427689433098,
            0.009704227559268475,
            -0.027931146323680878,
            0.019710831344127655,
            0.018266605213284492,
            0.03426153212785721,
            0.03451982140541077,
            -0.017452865839004517,
            -0.06400447338819504,
            0.02341623231768608,
            -0.01659148931503296,
            0.03174881637096405,
            0.002981524681672454,
            -0.028433047235012054,
            0.02538515441119671,
            -0.03507288917899132,
            -0.0061485362239181995,
            -0.01735800690948963,
            0.02896084263920784,
            0.01863749884068966,
            0.02949606440961361,
            -0.032647598534822464,
            0.002893454860895872,
            0.02568196877837181,
            -0.07506511360406876,
            -0.019164593890309334,
            -0.05495576560497284,
            -0.0041693588718771935,
            0.010865282267332077,
            0.002734281588345766,
            0.008486035279929638,
            -0.005395358894020319,
            0.006713711190968752,
            -0.025764159858226776,
            -0.0023681805469095707,
            0.015537319704890251,
            -0.03343427553772926,
            -0.01660877838730812,
            -0.0052754743956029415,
            -0.004736719187349081,
            -0.017220262438058853,
            -0.03290445730090141,
            -0.000872137607075274,
            -0.00016714214871171862,
            -0.013018647208809853,
            0.03715069219470024,
            -0.0028202966786921024,
            -0.031347375363111496,
            0.015685686841607094,
            0.016842950135469437,
            -0.00688379118219018,
            0.018477875739336014,
            0.034789204597473145,
            0.05361064150929451,
            0.003739733947440982,
            -0.0336918905377388,
            -0.02183694578707218,
            -0.038261741399765015,
            0.05976876616477966,
            -0.01554641779512167,
            0.03866066783666611,
            -0.02918747439980507,
            0.03249042481184006,
            0.0005746196839027107,
            0.038611460477113724,
            -0.025120340287685394,
            -0.00011750936391763389,
            0.033765822649002075,
            0.0027216568123549223,
            0.03190154209733009,
            0.009208119474351406,
            0.014089640229940414,
            0.024246463552117348,
            0.03427174687385559,
            0.014550256542861462,
            -0.01042376272380352,
            -0.03648689016699791,
            0.03168550878763199,
            0.002598028862848878,
            -0.018869103863835335,
            -0.016475386917591095,
            0.01930818147957325,
            0.008248984813690186,
            0.01268400065600872,
            -0.005717429798096418,
            -0.030815867707133293,
            0.009484902955591679,
            -0.022636424750089645,
            0.025844328105449677,
            0.013174837455153465,
            0.02816968970000744,
            0.01407650951296091,
            0.017927782610058784,
            -0.014264211058616638,
            0.02423950470983982,
            0.05277290940284729,
            0.014489405788481236,
            0.0028645119164139032,
            -0.060899700969457626,
            -0.045135997235774994,
            0.025905722752213478,
            0.008962140418589115,
            0.020222967490553856,
            0.012671084143221378,
            0.008086428046226501,
            -0.012435429729521275,
            0.0385943241417408,
            0.03067679889500141,
            -0.03144053369760513,
            0.02897833287715912,
            0.004255256149917841,
            0.027696434408426285,
            -0.022407980635762215,
            -0.027611488476395607,
            -0.03453371301293373,
            -0.026487817987799644,
            -0.01089054811745882,
            -0.04157088324427605,
            -0.016909318044781685,
            0.014274693094193935,
            -0.020585715770721436,
            0.01026479247957468,
            0.021529529243707657,
            0.008493631146848202,
            0.018285714089870453,
            -0.0276280976831913,
            0.02452664077281952,
            0.007304174825549126,
            0.012107531540095806,
            -0.0043741269037127495,
            0.039150942116975784,
            0.029568811878561974,
            -0.02420194260776043,
            0.035268254578113556,
            -0.014822007156908512,
            0.03451103717088699,
            0.011149385944008827,
            -0.02582976035773754,
            -0.023453213274478912,
            0.019229697063565254,
            0.02976279705762863,
            0.03707795590162277,
            -0.015674883499741554,
            0.022212862968444824,
            0.004849816206842661,
            -0.036926303058862686,
            -0.025538219138979912,
            0.020589591935276985,
            -0.054031070321798325,
            -0.015409188345074654,
            0.01797817274928093,
            0.011282923631370068,
            -0.00746203912422061,
            -0.026474272832274437,
            0.03344463184475899,
            -0.04321710765361786,
            -0.014059485867619514,
            -0.0344906710088253,
            -0.004226168151944876,
            0.004488867241889238,
            -0.01960660144686699,
            -0.11647313833236694,
            0.018909839913249016,
            -0.049847304821014404,
            -0.023552212864160538,
            0.025240618735551834,
            -0.00028668271261267364,
            0.000742362521123141,
            -0.03572731465101242,
            -0.006809713318943977,
            -0.005429365672171116,
            -0.023369962349534035,
            -0.019763333722949028,
            0.0363546647131443,
            0.02010636404156685,
            -0.02628401480615139,
            0.042223136872053146,
            -0.003946938551962376,
            0.0006661834777332842,
            -0.019291622564196587,
            0.03264925256371498,
            0.0016083146911114454,
            -0.0263923779129982,
            0.015482475981116295,
            -0.002426320221275091,
            -0.024641655385494232,
            -0.007438511587679386,
            0.05270601436495781,
            0.017859596759080887,
            -0.0007876846357248724,
            0.004593719728291035,
            0.01388849038630724,
            -0.0015404693549498916,
            -0.030169788748025894,
            -0.0030010307673364878,
            -0.022335946559906006,
            0.026386523619294167,
            -0.03889044001698494,
            -0.03500303626060486,
            -0.04344356432557106,
            0.03418933227658272,
            0.011587301269173622,
            0.04339703172445297,
            0.001377161592245102,
            -0.030712176114320755,
            0.023697996512055397,
            -0.03720977157354355,
            -0.0436539463698864,
            -0.0162871852517128,
            0.0008944198489189148,
            0.039322659373283386,
            0.018138350918889046,
            0.011790314689278603,
            0.019997619092464447,
            0.027866709977388382,
            -0.0012363475980237126,
            0.005242032464593649,
            0.023366067558526993,
            0.0503087043762207,
            0.01779540441930294,
            -0.06022028252482414,
            -0.006008105352520943,
            0.012514462694525719,
            -0.01999603398144245,
            -0.012933390215039253,
            0.006880366709083319,
            -0.02586629055440426,
            0.023867109790444374,
            -0.021436508744955063,
            -0.014379007741808891,
            -0.017029907554388046,
            0.005517402198165655,
            -0.005534820258617401,
            -0.0026814790908247232,
            0.005267225205898285,
            -0.02723616734147072,
            0.0030665958765894175,
            -0.007862482219934464,
            -0.017520558089017868,
            -0.03171180561184883,
            -0.018600132316350937,
            0.02489824779331684,
            0.005896246526390314,
            0.03385240212082863,
            -0.028048355132341385,
            0.04529198631644249,
            -0.03736362233757973,
            0.027089059352874756,
            0.002795684151351452,
            0.023223398253321648,
            -0.01962215267121792,
            0.03664768859744072,
            0.015518182888627052,
            -0.009158659726381302,
            -0.007538129109889269,
            0.011355938389897346,
            -0.007236173842102289,
            0.007534546311944723,
            0.018968235701322556,
            -0.014272543601691723,
            -0.03238173574209213,
            0.02717404067516327,
            -0.0021403487771749496,
            0.03598083555698395,
            -0.03377687931060791,
            0.00037513882853090763,
            0.020501138642430305,
            -0.012194890528917313,
            -0.03312157467007637,
            0.03255783021450043,
            -0.01654273085296154,
            0.012082979083061218,
            0.032584190368652344,
            -0.005799724254757166,
            0.02927173301577568,
            0.046916473656892776,
            -0.009879069402813911,
            -0.009126692079007626,
            0.04994850978255272,
            -0.018702467903494835,
            0.006186131853610277,
            0.015204585157334805,
            0.017672069370746613,
            -0.007872655056416988,
            0.01605493575334549,
            -0.011589769273996353,
            0.016587302088737488,
            -0.023123137652873993,
            0.02481403946876526,
            0.011586756445467472,
            0.008436656557023525,
            0.0137973353266716,
            0.05122719705104828,
            -0.01057504490017891,
            -0.030690666288137436,
            -0.008532844483852386,
            -0.020305559039115906,
            0.019683990627527237,
            -0.021744750440120697,
            -0.01737050525844097,
            0.009338666684925556,
            0.04153214395046234,
            0.005195663310587406,
            -0.01362521480768919,
            -0.026517532765865326,
            -0.016769643872976303,
            -0.04232319816946983,
            -0.004193851724267006,
            -0.01255392748862505,
            -0.003556452691555023,
            -0.004410041496157646,
            -0.005782861262559891,
            -0.01679982617497444,
            -0.028442034497857094,
            -0.023651350289583206,
            0.029025869444012642,
            0.04903368651866913,
            0.013186448253691196,
            -0.022676004096865654,
            -0.025166796520352364,
            0.004195583518594503,
            0.017489811405539513,
            -0.03181087598204613,
            0.03493368998169899,
            -0.020183954387903214,
            -0.019646456465125084,
            0.018279291689395905,
            0.04897323250770569,
            -0.01507455576211214,
            0.006391415372490883,
            -0.0023058168590068817,
            0.010382539592683315,
            0.021446596831083298,
            -0.02277485467493534,
            -0.002142253564670682,
            -0.0004929988645017147,
            0.017692232504487038,
            0.016862336546182632,
            0.0006968898233026266,
            0.01698683388531208,
            -0.018566399812698364,
            0.02615249902009964,
            -0.003620748408138752,
            0.016259627416729927,
            -0.06239411607384682,
            0.005101223010569811,
            -0.024205420166254044,
            -0.006587421987205744,
            0.023142023012042046,
            -0.0010744845494627953,
            0.02698412351310253,
            0.0009169885306619108,
            -0.010555433109402657,
            0.024353906512260437,
            -0.010835917666554451,
            0.011737045831978321,
            0.02005569078028202,
            -0.0037342896685004234,
            -0.013423559255897999,
            -0.04795108363032341,
            0.01613825000822544,
            0.009398789145052433,
            -0.041282154619693756,
            0.0013748431811109185,
            0.016248352825641632,
            -0.03481938689947128,
            0.006564932409673929,
            -0.004368462134152651,
            -0.053738247603178024,
            0.015022728592157364,
            -0.035257488489151,
            -0.024277593940496445,
            0.006953359115868807,
            0.011819509789347649,
            0.007232386618852615,
            -0.015247762203216553,
            -0.006829097401350737,
            0.029393615201115608,
            0.02309362031519413,
            0.015440243296325207,
            0.03702273219823837,
            0.030940700322389603,
            -0.011297161690890789,
            0.019921939820051193,
            -0.005671284627169371,
            0.026552587747573853,
            -0.0014483347767964005,
            0.018484611064195633,
            0.006292976904660463,
            -0.036462653428316116,
            0.017781147733330727,
            0.0037407316267490387,
            0.01968628540635109,
            0.018688511103391647,
            0.03472858667373657,
            -0.024504641070961952,
            -0.024179108440876007,
            -0.010725981555879116,
            0.005139077082276344,
            -0.008185558952391148,
            -0.020904576405882835,
            0.024734120815992355,
            0.034278735518455505,
            0.03654145821928978,
            -0.02813056670129299,
            -0.014257979579269886,
            -0.010873163118958473,
            -0.01408601738512516,
            -0.016880564391613007,
            0.0032268508803099394,
            0.014415567740797997,
            0.02090969681739807,
            0.029481688514351845,
            -0.032002802938222885,
            0.0027317372150719166,
            -0.04739599674940109,
            -0.010873770341277122,
            -0.0039675310254096985,
            -0.01213548518717289,
            0.010012421756982803,
            0.0052947006188333035,
            0.029610315337777138,
            0.008923553861677647,
            -0.013152397237718105,
            0.029580548405647278,
            -0.03332311287522316,
            0.012487982399761677,
            0.0069407629780471325,
            -0.023308806121349335,
            0.01331010926514864,
            0.018086615949869156,
            -0.06990067660808563,
            0.021635016426444054,
            -0.08992508053779602,
            -0.05185436084866524,
            0.0033708191476762295,
            0.009231600910425186,
            -0.034320492297410965,
            0.004383105784654617,
            -0.03996908664703369,
            -0.015694409608840942,
            0.01983301341533661,
            -0.010600184090435505,
            0.004990262445062399,
            0.008472098968923092,
            0.03384131193161011,
            -0.0385156124830246,
            0.015070580877363682,
            0.023952940478920937,
            -0.02810957469046116,
            -0.02567409537732601,
            -0.013616450130939484,
            0.02193319797515869,
            0.01714840531349182,
            -0.005038348957896233,
            -0.02116617187857628,
            -0.0004029823758173734,
            0.018116679042577744,
            0.0395718477666378,
            0.0025680633261799812,
            -0.014389327727258205,
            -0.0013918096665292978,
            0.007494800258427858,
            0.02874300256371498,
            0.031795185059309006,
            0.03534989431500435,
            -0.04905489459633827,
            -0.004992694593966007,
            0.014358945190906525,
            0.0055231256410479546,
            0.004154512658715248,
            -0.007558772806078196,
            0.01033514179289341,
            -0.01957009918987751,
            0.04235631600022316,
            0.0005609894287772477,
            0.037652093917131424,
            -0.006177163682878017,
            -0.005954113323241472,
            -0.02214357629418373,
            -0.011071233078837395,
            -0.025898465886712074,
            0.03049788810312748,
            0.0006787253078073263,
            -0.0361437164247036,
            -0.01752975955605507,
            -0.015259279869496822,
            0.005819219630211592,
            0.005801640450954437,
            -0.012553278356790543,
            -0.044666875153779984,
            0.011118550784885883,
            -0.04052800312638283,
            0.023260509595274925,
            0.024860693141818047,
            -0.04090360179543495,
            0.0046641286462545395,
            -0.003590150037780404,
            -0.007828779518604279,
            -0.023276666179299355,
            -0.0016498597105965018,
            0.009547030553221703,
            -0.008541909046471119,
            -0.00833770353347063,
            0.02999003231525421,
            -0.004056160803884268,
            -0.019623246043920517,
            -0.06698790937662125,
            0.0018534681294113398,
            0.0118161765858531,
            -0.004119522403925657,
            -0.012275856919586658,
            0.0016118022613227367,
            0.014606710523366928,
            0.005472179036587477,
            0.020748205482959747,
            0.023554684594273567,
            0.017428960651159286,
            -0.03181074187159538,
            0.019467417150735855,
            -0.01505066454410553,
            0.02184969000518322,
            0.024919543415308,
            0.003691264893859625,
            0.013203594833612442,
            -0.04425240308046341,
            0.01263424288481474,
            0.011244376190006733,
            -0.019892040640115738,
            0.019756808876991272,
            0.010000769980251789,
            0.042253341525793076,
            -0.018070587888360023,
            -0.03401815891265869,
            7.805674249539152e-05,
            -0.02215050719678402,
            0.00808883085846901,
            0.039254870265722275,
            -0.005924255587160587,
            -0.028852762654423714,
            0.0038050420116633177,
            0.017024599015712738,
            -0.025403348729014397,
            0.017166489735245705,
            0.0031040830072015524,
            0.048275962471961975,
            -0.0022943660151213408,
            -0.010601443238556385,
            0.014226685278117657,
            -0.017378069460392,
            -0.07571519166231155,
            0.023023901507258415,
            -0.01735948771238327,
            0.023697299882769585,
            -0.002630556235089898,
            0.014075824990868568,
            0.00502835214138031,
            -0.007856297306716442,
            -0.003937248140573502,
            0.020030319690704346,
            0.00013894829316996038,
            -0.005401983391493559,
            0.0006241890951059759,
            0.01212573517113924,
            -0.023760132491588593,
            0.04218438267707825,
            0.01969391666352749,
            -0.02796020358800888,
            -0.012644955888390541,
            -0.010712710209190845,
            0.009380094707012177,
            -0.030866511166095734,
            -0.035489585250616074,
            0.0017984628211706877,
            -0.004308389965444803,
            0.0014939965913072228,
            0.027054572477936745,
            -0.06446824967861176,
            0.025127416476607323,
            0.024134283885359764,
            -0.012249051593244076,
            -0.010087288916110992,
            0.02127877250313759,
            -0.021883780136704445,
            0.041979704052209854,
            0.0198813546448946,
            -0.009856557473540306,
            -0.049299851059913635,
            0.004248238634318113,
            -0.00013779316213913262,
            0.008767257444560528,
            -0.014768050983548164,
            0.009339610114693642,
            -0.0160636268556118,
            0.022356100380420685,
            -0.012205001898109913,
            -0.0017348873661831021,
            0.01231380831450224,
            -0.04383032023906708,
            0.011054846458137035,
            -0.030859442427754402,
            0.00797431729733944,
            0.012286636978387833,
            -0.000805234129074961,
            -0.008659154176712036,
            -0.001064493553712964,
            0.017883066087961197,
            0.04457774758338928,
            0.026251086965203285,
            0.009365173056721687,
            -0.014260255731642246,
            -0.02290043607354164,
            -0.007317541167140007,
            0.011586270295083523,
            -0.004150436259806156,
            -0.02041872777044773,
            -0.018903665244579315,
            -0.013828994706273079,
            0.017436277121305466,
            -0.03427386283874512,
            0.00848296657204628,
            0.04913098365068436,
            0.018510425463318825,
            0.04294509440660477,
            -0.011530398391187191,
            -0.022084413096308708,
            0.02925417199730873,
            0.04192687198519707,
            0.007325409911572933,
            -0.01843913272023201,
            -0.017081448808312416,
            -0.012129981070756912,
            -0.027674680575728416,
            -0.006542537361383438,
            -0.042504481971263885,
            0.010623081587255001,
            0.02870534546673298,
            -0.008277020417153835,
            0.02409661002457142,
            -0.0007580530364066362,
            0.019936801865696907,
            0.01034027710556984,
            0.01324475184082985,
            0.0010158634977415204,
            0.0037510250695049763,
            -0.02395293116569519,
            -0.018467213958501816,
            -0.02965935692191124,
            0.010235663503408432,
            0.04356224089860916,
            -0.0008459060918539762,
            0.009084424935281277,
            -0.023892588913440704,
            -0.0022803866304457188,
            -0.02453012205660343,
            0.028446434065699577,
            -0.0027097691781818867,
            0.030195849016308784,
            -0.02586129494011402,
            0.04538542032241821,
            -0.036553867161273956,
            -0.0105937784537673,
            -0.020847568288445473,
            0.026483988389372826,
            -0.015052637085318565,
            0.02294330485165119,
            0.02630092203617096,
            -0.010851559229195118,
            -0.04462910443544388,
            0.03457358479499817,
            -0.02472318336367607,
            -0.009953822940587997,
            -0.009521794505417347,
            -0.033566664904356,
            -0.020237889140844345,
            0.0218665711581707,
            -0.018267210572957993,
            0.008124490268528461,
            0.0015950094675645232,
            0.02293110452592373,
            -0.014325857162475586,
            -0.008449910208582878,
            -0.014210555702447891,
            0.008676476776599884,
            -0.0028483436908572912,
            0.016403701156377792,
            -0.006738572381436825,
            -0.0147323627024889,
            -0.027820387855172157,
            0.02606172487139702,
            -0.02368781715631485,
            0.03279110789299011,
            0.010111307725310326,
            -0.005401885136961937,
            0.01489611342549324,
            0.024537650868296623,
            -0.004132994916290045
        ],
        [
            -0.023124247789382935,
            -0.052905403077602386,
            0.02831515669822693,
            -0.0065161059610545635,
            0.012620585970580578,
            -0.014933985657989979,
            -0.007158537395298481,
            0.009093656204640865,
            0.03281082585453987,
            0.02726871520280838,
            -0.017922785133123398,
            0.003205431392416358,
            0.04329638555645943,
            0.0024833243805915117,
            0.01659364067018032,
            -0.014356599189341068,
            -0.008098590187728405,
            -0.001100060879252851,
            -0.020593594759702682,
            -0.04133797436952591,
            -0.005265340209007263,
            0.002359513659030199,
            0.024801719933748245,
            0.023945214226841927,
            0.018432294949889183,
            0.031114930287003517,
            0.06461630761623383,
            0.047257762402296066,
            0.04412437975406647,
            -0.009334188885986805,
            6.0084716096753255e-05,
            0.007597442250698805,
            -0.022482067346572876,
            0.011659827083349228,
            0.00913161039352417,
            0.05844215303659439,
            -0.02199874445796013,
            -0.0006429768982343376,
            -0.01897069253027439,
            -0.02154070883989334,
            0.025007886812090874,
            0.012361528351902962,
            0.013075833208858967,
            0.01379466988146305,
            -0.008036384359002113,
            0.04109843820333481,
            0.009088998660445213,
            -0.025551633909344673,
            -0.005538001190871,
            -0.009114399552345276,
            -0.0021517081186175346,
            0.002907239366322756,
            -0.02191409468650818,
            -0.03901637718081474,
            0.016505898907780647,
            0.03521707281470299,
            0.03692138195037842,
            0.013408702798187733,
            0.005238700192421675,
            -0.00988940242677927,
            -0.037858035415410995,
            0.021950578317046165,
            -0.02427908405661583,
            -0.011582698673009872,
            -0.023158617317676544,
            -0.0034375099930912256,
            -0.04137444496154785,
            0.009224273264408112,
            0.008127928711473942,
            -0.018456635996699333,
            0.007805628702044487,
            0.023649320006370544,
            -0.012357780709862709,
            0.02312067151069641,
            0.04919501394033432,
            0.03912130743265152,
            0.004679066129028797,
            -0.006936478894203901,
            -0.019577519968152046,
            0.03455059975385666,
            0.046552062034606934,
            -0.02607361227273941,
            -0.021717237308621407,
            0.010623786598443985,
            0.010474033653736115,
            -0.016318466514348984,
            -0.010120541788637638,
            0.040814124047756195,
            -0.0007841301267035306,
            -0.021498635411262512,
            0.03730589896440506,
            -0.0300464890897274,
            -0.029390297830104828,
            0.031145509332418442,
            0.006574580911546946,
            -0.0004245326272211969,
            0.04323173686861992,
            0.0029271335806697607,
            0.07578355818986893,
            0.0071811676025390625,
            0.0024811686016619205,
            -0.0030367544386535883,
            0.039838194847106934,
            -0.02042285166680813,
            0.038155555725097656,
            -0.022354813292622566,
            0.012678838334977627,
            -0.0243841540068388,
            0.00033890147460624576,
            0.01852133870124817,
            -0.004593745805323124,
            0.04281950742006302,
            0.03311918303370476,
            -0.014028056524693966,
            0.005934632383286953,
            0.007061463315039873,
            0.02718907780945301,
            -0.04097704216837883,
            0.029975509271025658,
            -0.007826537825167179,
            -0.005801509600132704,
            -0.0042112539522349834,
            -0.007535377983003855,
            -0.011651020497083664,
            -1.5532301404164173e-05,
            -0.012609521858394146,
            -0.01003775279968977,
            0.007958355359733105,
            0.03295637294650078,
            0.02664673700928688,
            -0.014515560120344162,
            -0.0260709747672081,
            0.004701948259025812,
            0.014103035442531109,
            0.010085451416671276,
            0.03193860128521919,
            -0.023876311257481575,
            -0.011886014603078365,
            -0.02468218095600605,
            -0.017057305201888084,
            0.032544609159231186,
            0.018103064969182014,
            -0.04556754231452942,
            -0.026734085753560066,
            0.029763372614979744,
            -0.0036978786811232567,
            0.02141287364065647,
            -0.014549758285284042,
            -0.01319272443652153,
            0.05056149140000343,
            -0.05085667967796326,
            -0.05670500546693802,
            0.030202431604266167,
            -0.015585080720484257,
            -0.034852251410484314,
            0.012332046404480934,
            -0.018053991720080376,
            -0.00642692344263196,
            0.022110765799880028,
            0.02543826214969158,
            0.02052842080593109,
            0.018512405455112457,
            -0.011324799619615078,
            0.017258010804653168,
            0.03437206521630287,
            -0.014960912056267262,
            0.017515365034341812,
            -0.07704193890094757,
            -0.004430816508829594,
            0.024665387347340584,
            0.012507287785410881,
            0.01132057886570692,
            0.010617856867611408,
            -0.01663745753467083,
            0.004515958484262228,
            0.037660397589206696,
            -0.0049927206709980965,
            -0.014234407804906368,
            -0.006807961035519838,
            -0.022437402978539467,
            -0.011102251708507538,
            0.045175377279520035,
            0.05938192829489708,
            0.019484128803014755,
            -0.0011793379671871662,
            0.01222902163863182,
            0.01976660266518593,
            -0.010783142410218716,
            -0.06796303391456604,
            0.0018949584336951375,
            -0.01854928582906723,
            -0.007675524335354567,
            -0.054247837513685226,
            0.013740775175392628,
            -0.010688094422221184,
            0.025850839912891388,
            -0.02448357827961445,
            0.015273424796760082,
            -0.04489647597074509,
            0.016101542860269547,
            -0.025120213627815247,
            0.03419916704297066,
            -0.0257936492562294,
            0.008690118789672852,
            0.024789173156023026,
            -0.0328243151307106,
            -0.01897476240992546,
            0.0006675535114482045,
            -0.013711783103644848,
            0.02217048592865467,
            0.01725388690829277,
            0.008699067868292332,
            0.014474322088062763,
            0.012294759973883629,
            -0.0037209964357316494,
            -0.033966805785894394,
            -0.009357578121125698,
            0.0048625473864376545,
            -0.0003743714769370854,
            -0.050227224826812744,
            0.022339636459946632,
            0.01934262551367283,
            -0.04175927862524986,
            -0.018442140892148018,
            0.013271644711494446,
            -0.012908591888844967,
            -0.0007177197840064764,
            -0.00731426477432251,
            0.031200643628835678,
            -0.004180548246949911,
            -0.06950483471155167,
            0.029544532299041748,
            -0.033555854111909866,
            -0.004874046891927719,
            -0.06855320930480957,
            -0.017967689782381058,
            -0.028855042532086372,
            -0.009436226449906826,
            0.01095321774482727,
            0.025365103036165237,
            -0.004246265161782503,
            0.02581518329679966,
            0.019600393250584602,
            -0.011593293398618698,
            0.05389351770281792,
            -0.043790288269519806,
            -0.03469131514430046,
            0.019074035808444023,
            -0.0010893651051446795,
            -0.0010527926497161388,
            0.019787622615695,
            -0.011970322579145432,
            -0.002296845894306898,
            0.015813536942005157,
            0.026909230276942253,
            -0.02006150409579277,
            0.017654070630669594,
            0.03697190806269646,
            -0.01929647848010063,
            -0.021211061626672745,
            -0.0037596302572637796,
            0.03824054077267647,
            0.03593287616968155,
            -0.003478921949863434,
            -0.0009326101280748844,
            0.0030665460508316755,
            -0.023167939856648445,
            -0.009863474406301975,
            -0.007461625151336193,
            -0.0016039457404986024,
            -0.005939891096204519,
            -0.0005461382679641247,
            -0.00453993771225214,
            0.014274584129452705,
            0.04882630705833435,
            -0.01528699416667223,
            -0.007815279997885227,
            -0.011554501950740814,
            -0.0057345181703567505,
            0.004127596504986286,
            0.029776273295283318,
            -0.0023681207094341516,
            0.028061168268322945,
            0.041419532150030136,
            -0.03421729803085327,
            0.004863618407398462,
            0.009962505660951138,
            0.05393039435148239,
            0.024645814672112465,
            0.0109812431037426,
            -0.020288167521357536,
            0.006543440744280815,
            -0.031839367002248764,
            -0.015379252843558788,
            -0.00543547747656703,
            0.005941319745033979,
            -0.035423897206783295,
            0.032827772200107574,
            -0.004917668178677559,
            -0.007120857480913401,
            0.027588916942477226,
            0.044956937432289124,
            -0.04104682058095932,
            -0.037058036774396896,
            0.04982982948422432,
            -0.007451494690030813,
            0.015620329417288303,
            -0.04331517592072487,
            -0.030148815363645554,
            -0.00420640641823411,
            0.013488702476024628,
            -0.007913927547633648,
            0.02336672879755497,
            0.028523987159132957,
            0.031250011175870895,
            0.027686143293976784,
            -0.012531544081866741,
            -0.014373204670846462,
            0.02280990220606327,
            -0.01919538713991642,
            -0.026020003482699394,
            0.05717386305332184,
            0.03689197450876236,
            -0.03400779888033867,
            -0.015926839783787727,
            -0.032922353595495224,
            -0.006088411435484886,
            0.027149684727191925,
            0.010133632458746433,
            0.022399218752980232,
            0.007747636642307043,
            -0.028311682865023613,
            0.01332678459584713,
            0.008915664628148079,
            0.034633785486221313,
            0.01624426804482937,
            -0.004293777979910374,
            0.01618022285401821,
            -0.018807031214237213,
            -0.004356819204986095,
            -0.003740922547876835,
            0.014663321897387505,
            0.03355855494737625,
            0.016420582309365273,
            0.024933597072958946,
            -0.022374246269464493,
            0.02127060294151306,
            -0.001039970782585442,
            -0.006906754802912474,
            0.0051872278563678265,
            0.04017331078648567,
            -0.060425058007240295,
            -0.043631814420223236,
            -0.07123023271560669,
            0.037810295820236206,
            0.03262617066502571,
            -0.005130384583026171,
            0.02460736408829689,
            0.018562762066721916,
            0.041129689663648605,
            0.03156651183962822,
            -0.02711956761777401,
            0.01536588929593563,
            -0.01605367474257946,
            0.03311525657773018,
            -0.009598405100405216,
            0.008169140666723251,
            0.025682741776108742,
            0.005909337196499109,
            0.016827218234539032,
            0.0026735973078757524,
            -0.0018781457329168916,
            0.002923691878095269,
            0.0371176078915596,
            -0.015899498015642166,
            -0.00801505520939827,
            -0.02216424234211445,
            0.018902700394392014,
            -0.04511870816349983,
            -0.014724873006343842,
            0.025623243302106857,
            0.02081143856048584,
            0.0063613662496209145,
            -0.02659899927675724,
            0.03921903669834137,
            0.002656647004187107,
            0.034460995346307755,
            -0.010136007331311703,
            -0.00047629259643144906,
            -0.006103726103901863,
            -0.02320498414337635,
            0.029934672638773918,
            0.015377485193312168,
            -0.023548215627670288,
            -0.01927828975021839,
            -0.005730259697884321,
            -0.017598167061805725,
            0.003154499689117074,
            0.01342356763780117,
            0.032838743180036545,
            0.03818265348672867,
            -0.0020779306069016457,
            0.008295953273773193,
            -0.0578138567507267,
            -0.04120688885450363,
            0.03790084272623062,
            -0.0038867115508764982,
            0.021444089710712433,
            0.03260181099176407,
            -0.015101798810064793,
            -0.013986711390316486,
            -0.026300940662622452,
            -0.055157218128442764,
            0.04301648586988449,
            -0.022458119317889214,
            0.03085053525865078,
            -0.007392831612378359,
            0.029675504192709923,
            -0.004911055788397789,
            -0.009488776326179504,
            0.01259313989430666,
            -0.02070293389260769,
            -0.04168414697051048,
            0.03617339953780174,
            -0.006203836295753717,
            -0.04847622662782669,
            -0.004685144405812025,
            0.01938662864267826,
            -0.002217600354924798,
            -0.012516857124865055,
            -0.0005351171130314469,
            0.013266443274915218,
            -0.03407534211874008,
            0.0066567035391926765,
            -0.05503939837217331,
            -0.011911436915397644,
            0.004682297818362713,
            -0.03523510321974754,
            0.014827074483036995,
            -0.03936861827969551,
            -0.0030578384175896645,
            0.00261595263145864,
            -0.03022615797817707,
            0.05028533190488815,
            0.007416906300932169,
            -0.012119296938180923,
            0.007378304377198219,
            0.019547104835510254,
            -0.05354264751076698,
            0.01798463985323906,
            -0.01242469809949398,
            -0.02556121163070202,
            -0.0006030384683981538,
            -0.010845497250556946,
            0.024839507415890694,
            0.024894937872886658,
            0.018872810527682304,
            0.011831562966108322,
            0.03795195370912552,
            -0.015772322192788124,
            0.036787956953048706,
            -0.020839467644691467,
            0.03992386534810066,
            -0.029058072715997696,
            0.031394410878419876,
            -0.030083119869232178,
            0.0034656990319490433,
            0.05169939249753952,
            -0.0025225719437003136,
            -0.01735319383442402,
            0.015420332551002502,
            0.02811327576637268,
            0.020074516534805298,
            -0.03289590775966644,
            -0.005084148142486811,
            -0.07245060801506042,
            -0.05048540607094765,
            -0.04068610817193985,
            -0.016617024317383766,
            0.0026536379009485245,
            0.015953131020069122,
            -0.0007451904239133,
            -0.04034274071455002,
            0.0025721851270645857,
            -0.061332594603300095,
            -0.010295421816408634,
            -0.013459785841405392,
            -0.00020903900440316647,
            -0.07217345386743546,
            0.03159135580062866,
            0.028274215757846832,
            0.02015906758606434,
            -0.00037218889337964356,
            0.042289067059755325,
            -0.0472983717918396,
            0.009397179819643497,
            0.005852729082107544,
            0.02692742832005024,
            -0.014262298122048378,
            0.011129605583846569,
            -0.018946221098303795,
            -0.016221798956394196,
            0.0020602168515324593,
            -0.0058331117033958435,
            -0.0031783091835677624,
            -0.005384001415222883,
            0.017075419425964355,
            0.00761581864207983,
            0.01097682025283575,
            -0.05602081120014191,
            -0.023915179073810577,
            -0.04940003529191017,
            0.01683671586215496,
            0.018290696665644646,
            0.00758744589984417,
            -0.006775046698749065,
            0.02780844457447529,
            0.028714213520288467,
            0.012862487696111202,
            -0.009806112386286259,
            -0.013081652112305164,
            0.01936771161854267,
            -0.034570325165987015,
            0.002021107589825988,
            0.055972665548324585,
            -0.012337511405348778,
            -0.005892702378332615,
            -0.03442838415503502,
            0.012408394366502762,
            0.021232791244983673,
            0.03134969621896744,
            0.032956719398498535,
            -0.017832083627581596,
            -0.012639413587749004,
            -0.018240367993712425,
            0.031098945066332817,
            -0.014590093865990639,
            -0.015390862710773945,
            0.0026465721894055605,
            0.014584770426154137,
            0.03229449689388275,
            0.030010666698217392,
            0.009210407733917236,
            -0.019068237394094467,
            0.010807381011545658,
            0.0386342778801918,
            -0.010895377956330776,
            0.0035687126219272614,
            -0.02869332954287529,
            0.01813420094549656,
            0.022949762642383575,
            0.01900533214211464,
            -0.02444005385041237,
            -0.033014584332704544,
            0.04199466109275818,
            -0.005551149602979422,
            -0.0009043074678629637,
            0.004919178318232298,
            -0.03703393042087555,
            -0.008126969449222088,
            -0.03172973543405533,
            0.013365263119339943,
            0.02609584480524063,
            -0.04143725708127022,
            -9.845788008533418e-05,
            0.028227373957633972,
            0.02105844020843506,
            0.015730222687125206,
            -0.007317486219108105,
            0.013262092135846615,
            0.01192037295550108,
            -0.008891548961400986,
            -0.016833607107400894,
            0.0021277545019984245,
            0.010457316413521767,
            0.025920961052179337,
            -0.020349688827991486,
            0.032328806817531586,
            -0.0068192691542208195,
            0.0014607306802645326,
            0.032759420573711395,
            0.024267323315143585,
            0.01076507568359375,
            -0.06302809715270996,
            -0.01698489859700203,
            -0.04300227388739586,
            -0.0523698553442955,
            -0.019276056438684464,
            -0.02382654882967472,
            0.02608679234981537,
            0.04278922453522682,
            -0.0064887129701673985,
            0.002478442620486021,
            -0.030120190232992172,
            -5.394856634666212e-05,
            -0.006161186844110489,
            0.026321833953261375,
            -0.003433350007981062,
            0.007622985169291496,
            0.023146500810980797,
            -0.015074755065143108,
            -0.010860041715204716,
            0.04031945765018463,
            -0.001767828012816608,
            0.026878202334046364,
            0.002832106314599514,
            -0.0114469388499856,
            -0.007406636606901884,
            -0.04290417581796646,
            0.024616355076432228,
            -0.03814321383833885,
            0.010656076483428478,
            -0.0020773494616150856,
            -0.038113996386528015,
            0.023247364908456802,
            -0.014034596271812916,
            0.014604470692574978,
            0.012635941617190838,
            -0.013229616917669773,
            0.034975022077560425,
            0.006549182813614607,
            -0.03597427159547806,
            0.013579167425632477,
            -0.0261355172842741,
            0.001810445566661656,
            -0.01662391424179077,
            -0.032078854739665985,
            0.0038561311084777117,
            0.03870251774787903,
            0.0009141918853856623,
            -0.002402160782366991,
            0.016497503966093063,
            0.0013619683450087905,
            -0.024870704859495163,
            -0.0392981618642807,
            0.01934637874364853,
            0.020964426919817924,
            0.009835152886807919,
            -0.012898758985102177,
            -0.016579315066337585,
            -0.03767208382487297,
            -0.04184089973568916,
            -0.025557469576597214,
            -0.049925435334444046,
            -0.00011004447878804058,
            -0.007097700610756874,
            0.011243153363466263,
            0.022712092846632004,
            0.02672692760825157,
            -0.03876737505197525,
            0.022245893254876137,
            -0.0018564292695373297,
            0.020296921953558922,
            -0.017591586336493492,
            -0.021426675841212273,
            -0.025734635069966316,
            0.03544856980443001,
            -0.002657325239852071,
            -0.03420214354991913,
            -0.037058595567941666,
            0.039372775703668594,
            0.04337882250547409,
            0.028109047561883926,
            -0.02330114133656025,
            0.019975991919636726,
            0.026672733947634697,
            -0.015290447510778904,
            -0.008333534002304077,
            0.023109186440706253,
            -0.031048182398080826,
            -0.022547584027051926,
            0.07163123786449432,
            -0.014569230377674103,
            0.023532196879386902,
            -0.026954172179102898,
            -0.031606175005435944,
            -0.013391954824328423,
            -0.023056281730532646,
            -0.028469860553741455,
            -0.02265508659183979,
            -0.0006422693259082735,
            0.012193383648991585,
            -0.006568958982825279,
            -0.01591428369283676,
            0.002968054497614503,
            -0.008627586998045444,
            -0.003688114695250988,
            -0.05319956690073013,
            -0.012882663868367672,
            0.04159989953041077,
            -0.019038274884223938,
            -0.007725167088210583,
            -0.033368051052093506,
            -0.028558900579810143,
            -0.010693538002669811,
            0.016447633504867554,
            -0.018735207617282867,
            0.019221171736717224,
            0.0176865067332983,
            0.02891911007463932,
            0.012225951999425888,
            0.008718225173652172,
            0.025685975328087807,
            0.03643326088786125,
            0.012709497474133968,
            0.025889141485095024,
            -0.02411884255707264,
            0.028668930754065514,
            0.018654614686965942,
            0.0036471085622906685,
            -0.006974298506975174,
            0.010656948201358318,
            -0.04371510073542595,
            -0.01373935304582119,
            -0.000504861818626523,
            0.0006927117938175797,
            0.0454505980014801,
            0.014336253516376019,
            -0.027782078832387924,
            0.023446353152394295,
            0.01670134626328945,
            0.03989192470908165,
            0.03119410015642643,
            -0.026432322338223457,
            0.03280787914991379,
            0.012604341842234135,
            -0.03229591250419617,
            -0.00579827930778265,
            -0.024266915395855904,
            0.035102177411317825,
            -0.023013750091195107,
            -0.02641107887029648,
            0.03622282296419144,
            0.013150598853826523,
            0.03691346198320389,
            0.016338452696800232,
            0.0034677120856940746,
            -0.009801756590604782,
            -0.032719146460294724,
            0.03280812129378319,
            -0.03268137201666832,
            0.02364722080528736,
            0.008445153944194317,
            -0.002223905175924301,
            -0.01762043498456478,
            0.010101662948727608,
            -0.04067860171198845,
            0.0023354478180408478,
            -0.033836156129837036,
            -0.02454267255961895,
            0.019816555082798004,
            0.005142668727785349,
            -0.02272835187613964,
            -0.06281391531229019,
            -0.0071352082304656506,
            -0.022248797118663788,
            -0.007602011319249868,
            -0.04078727215528488,
            0.0021585493814200163,
            0.03044532984495163,
            -0.07611173391342163,
            0.08418478816747665,
            0.00033291056752204895,
            0.049982886761426926,
            -0.03330999240279198,
            0.015461644157767296,
            0.02012903057038784,
            -0.013291346840560436,
            0.004825822543352842,
            -0.01593393087387085,
            0.046780675649642944,
            -0.007807184476405382,
            0.02962411753833294,
            0.0051437970250844955,
            -0.02600790560245514,
            -0.012386557646095753,
            0.02564978413283825,
            -0.06249433010816574,
            0.01732812449336052,
            -0.03453188017010689,
            -0.0090624475851655,
            -0.012039153836667538,
            -0.018690505996346474,
            0.01717747002840042,
            0.005095840431749821,
            -0.027506820857524872,
            -0.026151856407523155,
            0.016978861764073372,
            0.040764275938272476,
            -0.020867574959993362,
            0.03718181699514389,
            -0.02436411753296852,
            -0.020180009305477142,
            0.00021254425519146025,
            0.05063281208276749,
            0.010238585993647575,
            0.006222300231456757,
            -0.013997260481119156,
            0.008451994508504868,
            -0.027621958404779434,
            -0.03426316753029823,
            -0.0941798985004425,
            0.03677324950695038,
            0.01172039657831192,
            -0.03586186096072197,
            0.03228601813316345,
            -0.022233067080378532,
            -0.003112108213827014,
            -0.041210614144802094,
            0.051130861043930054,
            0.01339929923415184,
            0.07301101088523865,
            0.02085880935192108,
            -0.015567108057439327,
            0.03191934898495674,
            -0.011055250652134418,
            -0.014809618704020977,
            -0.07077167928218842,
            -0.02987348847091198,
            0.007671118713915348,
            0.027340319007635117,
            0.0028052106499671936,
            -0.03772323951125145,
            0.034094296395778656,
            0.02761933207511902,
            -0.036867618560791016,
            0.00977330096065998,
            -0.00903703086078167,
            -0.0059770806692540646,
            -0.010386275127530098,
            -0.018721256405115128,
            -0.025711961090564728,
            -0.017295317724347115,
            0.029927987605333328,
            -0.042493801563978195,
            0.004620946012437344,
            0.020202303305268288,
            -0.03499651327729225,
            -0.014041343703866005,
            0.03307501599192619,
            -0.004645018372684717,
            0.023906957358121872,
            0.030945850536227226,
            0.030876407399773598,
            0.0032306290231645107,
            -0.024039629846811295,
            0.029414664953947067,
            -0.02308877743780613,
            -0.0006146653322502971,
            0.005467668641358614,
            -0.013152411207556725,
            -0.020454855635762215,
            0.007528232876211405,
            -0.02743256464600563,
            -0.017095521092414856,
            -0.017040899023413658,
            -0.009012426249682903,
            -0.014056450687348843,
            0.01328246109187603,
            -0.037843093276023865,
            -0.021822933107614517,
            0.010645833797752857,
            -0.015526670031249523,
            0.023517470806837082,
            -0.054194748401641846,
            -0.027656350284814835,
            0.004014455713331699,
            -0.00248676142655313,
            -0.002485433593392372,
            0.012212761677801609,
            0.007404771167784929,
            0.0021148379892110825,
            -0.0035812132991850376,
            0.03118213638663292,
            0.010877988301217556,
            0.007211788557469845,
            0.005493601784110069,
            -0.05476381629705429,
            -0.03560497239232063,
            0.02708546444773674,
            -0.021458791568875313,
            -0.004081756807863712,
            0.0012471787631511688,
            0.006736428942531347,
            0.05182857811450958,
            0.004419385455548763,
            -0.025312308222055435,
            0.059703197330236435,
            0.024790944531559944,
            -0.024529337882995605,
            0.02608020231127739,
            -0.02361638844013214,
            -0.030048469081521034,
            0.007821731269359589,
            0.012562241405248642,
            0.030171215534210205,
            0.011385866440832615,
            0.05334316939115524,
            -0.04288807883858681,
            -0.03940598666667938,
            -0.045650288462638855,
            -0.014806009829044342,
            0.0027055777609348297,
            0.03280803933739662,
            -0.026060696691274643,
            -0.030119094997644424,
            -0.006676282733678818,
            -0.00572176044806838,
            0.042739175260066986,
            0.025288179516792297,
            0.042228445410728455,
            -0.011282763443887234,
            -0.004388229921460152,
            0.01677737943828106,
            -0.03915596753358841,
            -0.022704865783452988,
            0.017863372340798378,
            0.04274029657244682,
            0.02323734015226364,
            0.012613862752914429,
            -0.043989915400743484,
            0.007062737829983234,
            0.024687109515070915,
            0.0017394464230164886,
            0.023263514041900635,
            -0.03410512953996658,
            -0.007449594791978598,
            -0.04815802350640297,
            -0.007196556776762009,
            -0.04475603252649307,
            0.023422488942742348,
            -0.0011722471099346876,
            0.020379655063152313,
            0.05100042000412941,
            0.015724267810583115,
            -0.030548013746738434,
            0.0033631206024438143,
            0.003235848620533943,
            0.008757748641073704,
            0.029611578211188316,
            -0.014361766166985035,
            0.030751079320907593,
            -0.010126558132469654,
            -0.011637349613010883,
            -0.0385720320045948,
            0.037269845604896545,
            -0.03338367119431496,
            -0.06454142928123474,
            -0.015865566208958626,
            -0.009046283550560474,
            0.002321085426956415,
            -0.01366296224296093,
            -0.025444786995649338,
            0.029537906870245934,
            0.008188950829207897,
            -0.014550572261214256,
            0.010274797677993774,
            -0.023467745631933212,
            0.009210414253175259,
            -0.03574707359075546,
            -0.015514240600168705,
            -0.04025077819824219,
            0.02325235679745674,
            -0.001393779180943966,
            -0.010906575247645378,
            0.004007870797067881,
            -0.014627856202423573,
            -0.015311573632061481,
            0.03146493434906006,
            -0.008894000202417374,
            0.012509570457041264,
            0.01293829083442688,
            0.008175528608262539,
            0.002824296709150076,
            0.012968629598617554,
            0.010789907537400723,
            0.08599903434515,
            0.023784466087818146,
            -0.024328794330358505,
            -0.024734148755669594,
            -0.005054468754678965,
            -0.03043431043624878,
            -0.025391196832060814,
            -0.0003878083371091634,
            -0.016267605125904083,
            0.005407631862908602,
            -0.009090891107916832,
            0.010583682917058468,
            0.010607906617224216,
            0.030335156247019768,
            0.025904368609189987,
            -0.0007698378758504987,
            0.0009447467164136469,
            0.004858948290348053,
            0.0014655072009190917,
            0.030108248814940453,
            -0.04055880382657051,
            0.02098802849650383,
            0.0042801001109182835,
            0.025900574401021004,
            0.004452483728528023,
            -0.03031434863805771,
            0.023553458973765373,
            -0.009408592246472836,
            -0.0014256867580115795,
            -0.026743492111563683,
            0.0016343453899025917,
            -0.030977269634604454,
            -0.027773283421993256,
            -0.04447213187813759,
            0.011879605241119862,
            0.03179846704006195,
            0.023175450041890144,
            -0.03665053844451904,
            0.024710755795240402,
            -0.00498886127024889,
            0.024871937930583954,
            -0.009561197832226753,
            0.030808715149760246,
            -0.028412526473402977,
            0.013810700736939907,
            -0.06104511767625809,
            -0.02804276905953884,
            0.034360121935606
        ],
        [
            -0.013023587875068188,
            0.010612793266773224,
            0.022087499499320984,
            -0.014652449637651443,
            -0.015025630593299866,
            -0.001628372585400939,
            0.037308212369680405,
            0.02048216573894024,
            0.013918131589889526,
            -0.015978937968611717,
            -0.02249678410589695,
            -0.01755407266318798,
            0.002585729816928506,
            0.011162867769598961,
            -0.012454546988010406,
            0.03408112749457359,
            -0.02982799895107746,
            0.00953740905970335,
            -0.01935259811580181,
            0.004563694819808006,
            -0.03847672790288925,
            -0.019548315554857254,
            -0.008449172601103783,
            0.008704887703061104,
            -0.03275712952017784,
            0.009181897155940533,
            0.0019353339448571205,
            0.040680430829524994,
            -0.04256610944867134,
            -0.038703225553035736,
            -0.006924365181475878,
            -0.02422347664833069,
            -0.002661382546648383,
            -0.025531547144055367,
            0.01091530080884695,
            -0.031826235353946686,
            0.00948395486921072,
            -0.028914013877511024,
            -0.019429543986916542,
            0.014091185294091702,
            -0.00812714733183384,
            -0.026992183178663254,
            0.030462218448519707,
            0.034820664674043655,
            -0.009377778507769108,
            0.00014558358816429973,
            0.015553541481494904,
            0.032165996730327606,
            -0.02890307828783989,
            -0.02201002463698387,
            -0.01198944915086031,
            0.026633648201823235,
            -0.012810834683477879,
            -0.024436742067337036,
            0.004343258682638407,
            -0.01803467608988285,
            0.008936452679336071,
            0.020643435418605804,
            0.020606257021427155,
            -0.0013242304557934403,
            -0.01505249086767435,
            -0.0006882708403281868,
            -0.03516918048262596,
            0.029409967362880707,
            0.003593007568269968,
            0.006404419895261526,
            0.019719479605555534,
            -0.020131755620241165,
            -0.001975832274183631,
            0.032042212784290314,
            0.016677390784025192,
            -0.006861274130642414,
            -0.01068849116563797,
            -0.031039774417877197,
            0.014776109717786312,
            -0.021817028522491455,
            -0.0030779666267335415,
            0.04387464374303818,
            0.001645625801756978,
            -0.02223934791982174,
            0.025039466097950935,
            -0.005212186835706234,
            -0.010134714655578136,
            0.01584145426750183,
            0.026331447064876556,
            -0.0071251108311116695,
            -0.01690416783094406,
            -0.024516703560948372,
            -0.02795114554464817,
            0.011463841423392296,
            0.02219756320118904,
            -0.013053118251264095,
            -0.030553439632058144,
            0.010971903800964355,
            -0.031430523842573166,
            -0.01826724410057068,
            -0.02865043841302395,
            -0.032245393842458725,
            -0.014371444471180439,
            0.019417498260736465,
            -0.004469605628401041,
            0.02853427827358246,
            -0.007838461548089981,
            0.0013923553051427007,
            -0.03765340894460678,
            -0.011833482421934605,
            0.027858056128025055,
            -0.034415725618600845,
            -0.012732314877212048,
            -0.028231630101799965,
            -0.010252115316689014,
            -0.03228991478681564,
            -0.02129005268216133,
            -0.010115247219800949,
            0.052726585417985916,
            0.02001349814236164,
            -0.013028035871684551,
            0.012965764850378036,
            -0.01634274236857891,
            0.026279080659151077,
            0.0020991398487240076,
            0.022618472576141357,
            0.004478735849261284,
            0.03063221462070942,
            0.005885953549295664,
            -0.015492691658437252,
            0.03310340270400047,
            -0.024001918733119965,
            0.02653839997947216,
            -0.008911356329917908,
            0.009094768203794956,
            0.005697031505405903,
            -0.010731697082519531,
            -0.0727665051817894,
            -0.02578914538025856,
            0.014602148905396461,
            -0.031567029654979706,
            2.8033246053382754e-05,
            0.019172480329871178,
            -0.002321250271052122,
            -0.011457628570497036,
            0.00105960201472044,
            0.03376167267560959,
            0.024133596569299698,
            0.023679858073592186,
            0.0014465833082795143,
            -0.027753692120313644,
            0.005264264531433582,
            0.00909077562391758,
            0.023372575640678406,
            0.009485103189945221,
            0.03841666132211685,
            -0.011201426386833191,
            0.005990159697830677,
            0.04096286743879318,
            -0.023758890107274055,
            0.03850099444389343,
            -0.02167966589331627,
            0.010511050932109356,
            -0.03181171789765358,
            0.013341587036848068,
            -0.01504729874432087,
            0.00559729989618063,
            -0.013825062662363052,
            -0.00847698375582695,
            -0.0029188732150942087,
            -0.054350193589925766,
            0.0036468058824539185,
            0.01331085991114378,
            -0.0070343054831027985,
            0.012181670404970646,
            0.008058881387114525,
            -0.03099384531378746,
            0.00545316468924284,
            -0.035877328366041183,
            0.021017199382185936,
            0.024410007521510124,
            -0.034414730966091156,
            0.041855067014694214,
            0.02038942649960518,
            -0.03904784098267555,
            0.003219576785340905,
            -0.031854163855314255,
            -0.02192530408501625,
            -0.017171908169984818,
            -0.028699606657028198,
            0.03203938528895378,
            -0.01249869354069233,
            -0.031880054622888565,
            0.023177018389105797,
            0.0003033328102901578,
            0.021616987884044647,
            0.002351596485823393,
            -0.013841375708580017,
            0.007909443229436874,
            -0.010646878741681576,
            0.051176268607378006,
            0.0036048872862011194,
            0.03491790592670441,
            0.016844715923070908,
            -0.014790340326726437,
            0.008714374154806137,
            0.013876741752028465,
            0.021393055096268654,
            -0.008945818990468979,
            0.01057556550949812,
            0.009218716993927956,
            0.023056814447045326,
            0.013040414080023766,
            -0.007537210360169411,
            -0.0018546732608228922,
            0.002777696121484041,
            -0.0197866503149271,
            0.042992908507585526,
            0.0034055595751851797,
            -0.019153382629156113,
            0.007099537644535303,
            -0.006201631389558315,
            -0.006829083431512117,
            0.014700265601277351,
            0.003860501339659095,
            0.017799170687794685,
            -0.03649494796991348,
            0.03301349654793739,
            0.007124500814825296,
            0.029638977721333504,
            0.03659549355506897,
            0.02509944699704647,
            -0.043594323098659515,
            0.03759638965129852,
            -0.018821321427822113,
            -0.0007238282705657184,
            0.0007311063818633556,
            -0.021318059414625168,
            0.04914561286568642,
            0.009112530387938023,
            0.013153275474905968,
            0.01652628183364868,
            -0.016692088916897774,
            -0.02648317441344261,
            0.032259274274110794,
            0.008291312493383884,
            0.02611193247139454,
            -0.02886619046330452,
            0.011712667532265186,
            -0.00022806580818723887,
            0.02653355710208416,
            0.0004395357973407954,
            0.005803457926958799,
            0.013730418868362904,
            -0.0049632457084953785,
            0.020050128921866417,
            -0.02428845316171646,
            0.012281941249966621,
            0.009703114628791809,
            0.03142141178250313,
            -0.016860922798514366,
            0.004253388848155737,
            0.008616399019956589,
            -0.03237047418951988,
            -0.021821415051817894,
            0.05145835131406784,
            -0.0061555290594697,
            0.0043661827221512794,
            0.005330902058631182,
            -0.014941788278520107,
            0.005835423246026039,
            -0.020899970084428787,
            0.012073967605829239,
            -0.017595650628209114,
            -0.0015650196000933647,
            0.004454797133803368,
            0.013899104669690132,
            -0.025069236755371094,
            0.02794439159333706,
            0.01431390643119812,
            0.024848071858286858,
            -0.009038496762514114,
            0.0037739789113402367,
            0.002331517171114683,
            0.03161818906664848,
            -0.011358825489878654,
            0.026280753314495087,
            -0.027654847130179405,
            -0.020727641880512238,
            -0.01920292153954506,
            -0.01020871102809906,
            -0.027922503650188446,
            0.010833102278411388,
            0.010199647396802902,
            -0.03597886487841606,
            0.024618662893772125,
            0.0013766142074018717,
            -0.005680378992110491,
            0.021749641746282578,
            0.022259917110204697,
            -0.013118884526193142,
            -0.01753363385796547,
            -0.037257514894008636,
            -0.009342754259705544,
            -0.003928392194211483,
            0.0031029267702251673,
            -0.013189075514674187,
            -0.00997003074735403,
            -0.00674507487565279,
            0.015282895416021347,
            -0.01581052877008915,
            -0.014121182262897491,
            0.037288494408130646,
            -0.012301632203161716,
            0.015845300629734993,
            0.005867798812687397,
            0.00023874721955507994,
            0.028311647474765778,
            0.0030594756826758385,
            -0.004106044769287109,
            -0.0029273515101522207,
            -0.0048057567328214645,
            -0.025001872330904007,
            0.017232822254300117,
            0.01037019956856966,
            -0.03990896791219711,
            0.004215468652546406,
            0.03484487533569336,
            -0.027579357847571373,
            -0.018360381945967674,
            -0.031119223684072495,
            0.01900148205459118,
            0.015669653192162514,
            -0.009504471905529499,
            -0.06399733573198318,
            0.026284273713827133,
            0.013774453662335873,
            0.007443206384778023,
            -0.02010241709649563,
            -0.015000563114881516,
            0.0033234229777008295,
            -0.03618329018354416,
            -0.006647577974945307,
            0.019935403019189835,
            -0.02530384063720703,
            -0.01567334122955799,
            0.0018782687839120626,
            0.011259530670940876,
            0.02444034442305565,
            0.022081425413489342,
            -0.015844978392124176,
            -0.018867915496230125,
            -0.006397323217242956,
            0.013856416568160057,
            -0.00726995849981904,
            0.02613774873316288,
            -0.015792425721883774,
            -0.010387157090008259,
            -0.034742292016744614,
            -0.01293682400137186,
            0.03512805700302124,
            0.032290223985910416,
            -0.0038081989623606205,
            -0.04788634181022644,
            -0.016713278368115425,
            -0.013171426020562649,
            -0.006863778457045555,
            -0.004209559410810471,
            -0.0008434007759205997,
            0.027260860428214073,
            0.004444899503141642,
            0.007191427517682314,
            -0.018712174147367477,
            0.021965699270367622,
            0.017992999404668808,
            -0.01064902450889349,
            -0.027281498536467552,
            0.01940913125872612,
            0.024328023195266724,
            -0.02349364385008812,
            0.0007663318538106978,
            0.037847936153411865,
            0.03367425128817558,
            0.021789224818348885,
            -0.0032564725261181593,
            0.010468722321093082,
            -0.01712656021118164,
            -0.009517716243863106,
            0.007392874453216791,
            -0.016372356563806534,
            -0.0446491502225399,
            0.015895206481218338,
            -0.011956031434237957,
            -0.00927736796438694,
            0.016614269465208054,
            -0.01597491279244423,
            0.030639860779047012,
            -0.04595140740275383,
            0.005492808762937784,
            0.01772322878241539,
            -0.0187233816832304,
            0.003860746743157506,
            -0.034837622195482254,
            -0.028205372393131256,
            -0.009196915663778782,
            0.02960927039384842,
            -0.03982904553413391,
            0.010065453127026558,
            0.0016286841128021479,
            -0.024060435593128204,
            -0.010201837867498398,
            0.020073257386684418,
            -0.024495113641023636,
            0.02618718519806862,
            0.04569185897707939,
            0.021123237907886505,
            0.03960532322525978,
            0.01894620805978775,
            0.000575398444198072,
            -0.013658161275088787,
            -0.024369755759835243,
            0.03233226016163826,
            0.007449525874108076,
            0.02815179154276848,
            0.016695549711585045,
            0.03981181979179382,
            -0.035882025957107544,
            0.014010852202773094,
            0.01690296083688736,
            -0.019043514505028725,
            0.030752431601285934,
            0.02511422522366047,
            0.029277285560965538,
            0.039368636906147,
            -0.009846874512732029,
            -0.0005302212084643543,
            4.183762212051079e-05,
            -0.025783786550164223,
            -0.014967708848416805,
            0.023872198536992073,
            -0.0013813853729516268,
            0.04370567947626114,
            -0.009821288287639618,
            0.011926958337426186,
            0.03703240305185318,
            0.025672441348433495,
            0.015762386843562126,
            -0.03510923311114311,
            0.012343338690698147,
            -0.010972470045089722,
            -0.014161719009280205,
            -0.034677207469940186,
            -0.0014511850895360112,
            -0.0059732855297625065,
            -0.01468760333955288,
            -0.030241219326853752,
            0.027066534385085106,
            -0.010978887788951397,
            0.012480025179684162,
            0.022522035986185074,
            0.020070848986506462,
            0.007652407046407461,
            -0.025754278525710106,
            -0.011364446021616459,
            -0.025896728038787842,
            -0.010135776363313198,
            0.017696192488074303,
            0.01621248759329319,
            0.011840973980724812,
            0.011851758696138859,
            0.03003382310271263,
            0.0015058106509968638,
            -0.003111420199275017,
            -0.009708861820399761,
            -0.0016657327068969607,
            -0.0029811051208525896,
            0.02442166768014431,
            0.0027242284268140793,
            0.016685977578163147,
            0.04549885541200638,
            0.012087557464838028,
            -0.03500743955373764,
            -0.013179413042962551,
            -0.0007056091562844813,
            0.00847043190151453,
            -0.010272960178554058,
            0.026258833706378937,
            0.013620812445878983,
            -0.01292417198419571,
            0.003937247674912214,
            0.009991902858018875,
            0.0018414949299767613,
            0.03448057547211647,
            -0.0013120713410899043,
            0.022220339626073837,
            0.02894880250096321,
            -0.02648192271590233,
            -0.0297329593449831,
            -0.011527974158525467,
            -0.01817205920815468,
            -0.010188207030296326,
            -0.023303939029574394,
            -0.03347549960017204,
            0.03375909477472305,
            -0.002116193762049079,
            0.00774557376280427,
            0.0039635165594518185,
            0.000552610494196415,
            0.020738698542118073,
            0.014242413453757763,
            0.012153842486441135,
            -0.000123698846437037,
            -0.03306739777326584,
            -0.0321064293384552,
            -0.009620614349842072,
            0.0278535895049572,
            -0.004042132291942835,
            -0.0010507178958505392,
            -0.02677568420767784,
            0.011460170149803162,
            -0.035556819289922714,
            -0.011400447227060795,
            -0.07028638571500778,
            0.010890824720263481,
            -0.01823435164988041,
            0.022156918421387672,
            0.0008889283635653555,
            0.012831220403313637,
            0.04473225027322769,
            -0.04702161252498627,
            0.0013470384292304516,
            0.004924191161990166,
            -0.00010811680840561166,
            0.00561604555696249,
            -0.0062227207235991955,
            0.022949596866965294,
            0.0372268483042717,
            0.02458002232015133,
            -0.0068105049431324005,
            0.018696682527661324,
            0.00942197535187006,
            -0.004163701552897692,
            0.0431709960103035,
            -0.006402791943401098,
            -0.026504335924983025,
            -0.0324687622487545,
            0.025213029235601425,
            -0.02117009088397026,
            0.011674309149384499,
            0.04359864816069603,
            0.005877993535250425,
            -0.001676678890362382,
            0.01453464850783348,
            0.004471035674214363,
            0.015281273052096367,
            -0.008384588174521923,
            -0.01267145574092865,
            0.034093812108039856,
            0.03292521834373474,
            -0.03730441629886627,
            -0.01851438172161579,
            0.0030137200374156237,
            0.002374787349253893,
            -0.0231156162917614,
            0.010148962028324604,
            -0.0017836031038314104,
            0.016886640340089798,
            0.02551247365772724,
            0.004020698368549347,
            -0.03294270113110542,
            -0.022839484736323357,
            0.03126584738492966,
            0.029967185109853745,
            0.05125224590301514,
            0.00772716011852026,
            -0.008359850384294987,
            -0.008342417888343334,
            -0.019734926521778107,
            -0.0055443593300879,
            -0.000735465670004487,
            0.01806727424263954,
            -0.02266399748623371,
            0.027299804612994194,
            -0.01246625930070877,
            -0.020759668201208115,
            0.005808388348668814,
            0.023344548419117928,
            0.03821586072444916,
            -0.002032910706475377,
            -0.012588143348693848,
            0.015018155798316002,
            0.0333719439804554,
            -0.012993346899747849,
            -0.01693795993924141,
            0.010229269973933697,
            0.018884679302573204,
            -0.011191591620445251,
            0.06252329796552658,
            -0.027632327750325203,
            -0.004713483154773712,
            0.03333441913127899,
            0.02313467301428318,
            -0.011049448512494564,
            0.010795577429234982,
            -0.020290633663535118,
            -0.03657691553235054,
            -0.01900588534772396,
            -0.002060377737507224,
            0.03146525099873543,
            0.01993102952837944,
            0.031417254358530045,
            -0.009699196554720402,
            0.026172306388616562,
            -0.007450887933373451,
            0.037961602210998535,
            -0.007455121260136366,
            0.02388223446905613,
            0.005252532660961151,
            -0.007188788149505854,
            0.019162677228450775,
            0.005723320879042149,
            0.020394008606672287,
            -0.005528934299945831,
            0.02229383774101734,
            -0.012827286496758461,
            0.028695613145828247,
            -0.016358256340026855,
            -0.014258820563554764,
            -0.009648095816373825,
            -0.016958065330982208,
            -0.03506406396627426,
            -0.02132514864206314,
            0.012565717101097107,
            -0.0014673700788989663,
            -0.00398937938734889,
            -0.023454852402210236,
            0.013777083717286587,
            0.010125831700861454,
            -0.004142920020967722,
            -0.00944996066391468,
            0.030528873205184937,
            0.004633602686226368,
            -0.03982912749052048,
            -0.01080261915922165,
            0.03719419240951538,
            0.029452048242092133,
            0.05156385526061058,
            -0.013841678388416767,
            -0.023458946496248245,
            0.03144242614507675,
            -0.018827063962817192,
            0.020441215485334396,
            0.012440502643585205,
            -0.025399185717105865,
            0.02009882777929306,
            -0.014054156839847565,
            -0.0017451579915359616,
            -0.0258126612752676,
            0.011011188849806786,
            -0.038453225046396255,
            -0.03083781898021698,
            -0.01845386065542698,
            0.028079327195882797,
            -0.010645448230206966,
            -0.008355185389518738,
            0.028813187032938004,
            -0.027220234274864197,
            -0.0025016770232468843,
            -0.0025168603751808405,
            0.01013250183314085,
            -0.05103091523051262,
            0.01984076388180256,
            -0.00020911564934067428,
            -0.008156121708452702,
            -0.0019223135896027088,
            0.031432799994945526,
            -0.027095694094896317,
            0.016169309616088867,
            -0.030903739854693413,
            -0.01576904021203518,
            -0.01077317912131548,
            0.016251232475042343,
            -0.02175150439143181,
            0.026698781177401543,
            0.03919726237654686,
            -0.018235638737678528,
            -0.026528090238571167,
            0.0032659119460731745,
            0.021759722381830215,
            -0.022373421117663383,
            0.004307952243834734,
            -0.010782821103930473,
            0.00309314695186913,
            -0.0038455617614090443,
            -0.03379739075899124,
            0.029770394787192345,
            0.051203276962041855,
            -0.018410271033644676,
            0.0061028506606817245,
            -0.015917116776108742,
            -0.014356236904859543,
            0.020153198391199112,
            0.03177677094936371,
            0.0028710197657346725,
            -0.018326571211218834,
            -0.02350868284702301,
            0.01953313685953617,
            0.028636038303375244,
            -0.01180759072303772,
            -0.04915374889969826,
            0.0050656674429774284,
            0.026973966509103775,
            0.0022021436598151922,
            0.013261795043945312,
            -0.0025543966330587864,
            -0.028385700657963753,
            -0.007817990146577358,
            0.02523280680179596,
            -0.02178165130317211,
            -0.008643639273941517,
            0.016850413754582405,
            -0.0007716635009273887,
            0.025614410638809204,
            0.019784679636359215,
            0.00802656076848507,
            -0.03122015669941902,
            0.014552217908203602,
            -0.008149928413331509,
            -0.007998395711183548,
            -0.018816029652953148,
            -0.008807150647044182,
            -0.03136569634079933,
            -0.004970676731318235,
            -0.035624388605356216,
            0.01725304126739502,
            0.009832184761762619,
            0.006268172059208155,
            0.012727138586342335,
            -0.017229072749614716,
            -0.035890139639377594,
            0.01403308380395174,
            -0.022035764530301094,
            -0.016971910372376442,
            -0.016953716054558754,
            0.0032761350739747286,
            0.0007813553675077856,
            -0.019792264327406883,
            -0.02546764723956585,
            0.03381513059139252,
            0.0011513354256749153,
            -0.00618347804993391,
            -0.03059866838157177,
            0.02567160874605179,
            -0.00793430395424366,
            -0.01067590806633234,
            -0.023519227281212807,
            -0.007358935661613941,
            -0.014235318638384342,
            0.015190720558166504,
            -0.024934232234954834,
            -0.021880993619561195,
            -0.008983532898128033,
            0.016886772587895393,
            0.026635820046067238,
            -0.016372406855225563,
            -0.010200952179729939,
            -0.011810540221631527,
            -0.016773438081145287,
            0.004976977128535509,
            0.03113965317606926,
            0.03017275035381317,
            -0.004572129342705011,
            0.01118006557226181,
            -0.012820594944059849,
            -0.0023090492468327284,
            0.013039423152804375,
            -0.07920971512794495,
            -0.012349090538918972,
            0.008178401738405228,
            0.04300516098737717,
            -0.061866164207458496,
            0.002873685210943222,
            0.00207485631108284,
            -0.003157177474349737,
            0.026864590123295784,
            -0.015567871741950512,
            -0.009931301698088646,
            -0.034708816558122635,
            0.033731479197740555,
            0.026536880061030388,
            0.026224210858345032,
            -0.022591080516576767,
            -0.009991924278438091,
            0.00026342642377130687,
            -0.032450512051582336,
            -0.007225823123008013,
            -0.009383038617670536,
            -0.005554891191422939,
            -0.06933320313692093,
            -0.02888627164065838,
            0.01646275445818901,
            -0.02531902864575386,
            0.02012399211525917,
            0.04342895373702049,
            0.011915410868823528,
            -0.02987687475979328,
            0.011377764865756035,
            -0.002693397691473365,
            0.04680684953927994,
            0.02478433959186077,
            0.027243152260780334,
            -0.0028330073691904545,
            -0.0027519329451024532,
            0.0013634874485433102,
            0.04217987135052681,
            -0.008341156877577305,
            0.0036388880107551813,
            0.03279896453022957,
            0.035051338374614716,
            -0.016147246584296227,
            0.006150015629827976,
            0.00611843541264534,
            0.012767505832016468,
            0.022174041718244553,
            0.0003245264233555645,
            -0.00041312543908134103,
            -0.02328905649483204,
            -0.030706139281392097,
            -0.022190047428011894,
            -0.01892325095832348,
            -0.014724794775247574,
            -0.02678573504090309,
            -0.01824984699487686,
            -0.0001012765133054927,
            -0.01736227609217167,
            0.009212019853293896,
            -0.008438040502369404,
            -0.02784351073205471,
            0.08749765157699585,
            0.03286803141236305,
            0.01475027296692133,
            -0.013475148938596249,
            0.005114899016916752,
            0.02083558402955532,
            -0.03408132866024971,
            -0.009249583818018436,
            0.031918156892061234,
            0.012281789444386959,
            -0.009937182068824768,
            -0.017222236841917038,
            -0.023655731230974197,
            0.006727559491991997,
            -0.010405026376247406,
            0.016237691044807434,
            0.029330141842365265,
            -0.02095102332532406,
            0.010242493823170662,
            -0.009165555238723755,
            0.01151721179485321,
            0.03550918027758598,
            -0.020848378539085388,
            -0.0016599714290350676,
            0.050199832767248154,
            0.032502468675374985,
            -0.018505088984966278,
            -0.024266185238957405,
            -0.031575702130794525,
            -0.06254837661981583,
            0.044190701097249985,
            0.02197032980620861,
            -0.008028856478631496,
            0.03663269430398941,
            0.021101024001836777,
            0.015453704632818699,
            0.002505701035261154,
            -0.008425756357610226,
            0.03581001237034798,
            -0.015411565080285072,
            -0.0038771701510995626,
            0.021692080423235893,
            -0.013641244731843472,
            -0.00827159732580185,
            0.02142372913658619,
            0.03386516496539116,
            0.03201846778392792,
            -0.009945353493094444,
            -0.014862910844385624,
            0.02364318259060383,
            0.02184589020907879,
            0.004536089021712542,
            0.01754530891776085,
            -0.034895770251750946,
            0.012778081931173801,
            0.016204599291086197,
            0.02718278393149376,
            -0.019163554534316063,
            0.0005829468136653304,
            0.048546016216278076,
            -0.03135496750473976,
            -0.048293933272361755,
            0.03539791703224182,
            0.021733293309807777,
            0.018677301704883575,
            0.022181157022714615,
            0.009394713677465916,
            0.027239758521318436,
            -0.0353863500058651,
            -0.0017871896270662546,
            0.002534196712076664,
            0.07096868008375168,
            0.049427274614572525,
            -0.003802540013566613,
            -0.027855638414621353,
            0.03495444357395172,
            0.0076698861084878445,
            -0.00011283851199550554,
            0.014536049216985703,
            -0.048649486154317856,
            0.045199204236269,
            -0.0076228901743888855,
            0.02898016758263111,
            0.019718803465366364,
            0.01115860603749752,
            -0.002689481945708394,
            0.018570737913250923,
            -0.012635010294616222,
            0.003720924025401473,
            0.007297361735254526,
            0.05490768700838089,
            0.0004902524524368346,
            0.024753641337156296,
            0.03580383211374283,
            0.018191637471318245,
            0.03549179062247276,
            -0.024447226896882057,
            0.01101878471672535,
            -0.029950188472867012,
            -0.003789830720052123,
            0.02122027799487114,
            -0.015448001213371754,
            0.0258494820445776,
            0.02903585694730282,
            -0.037294235080480576,
            -0.007398344110697508,
            -0.018504252657294273,
            0.014048718847334385,
            0.010091520845890045,
            -0.013306718319654465,
            0.03575064614415169,
            0.019163286313414574,
            -0.018304279074072838,
            -0.05020259693264961,
            -0.029646428301930428,
            0.0008413279429078102,
            -0.02548316679894924,
            0.007535848300904036,
            0.01765863038599491,
            0.0180672574788332,
            0.0257156640291214,
            0.005323030054569244,
            -0.013581509701907635,
            -0.011795300990343094,
            0.009894002228975296,
            0.00018084236944559962,
            0.004197378642857075,
            0.0037960053887218237,
            -0.03227433189749718,
            -0.030924659222364426,
            -0.019244281575083733,
            -0.01581639237701893,
            0.024928050115704536,
            0.008991650305688381,
            -0.013238181360065937,
            0.005846640095114708,
            0.02457275055348873,
            0.025478996336460114,
            -0.007983324117958546,
            0.021947218105196953,
            -0.031234122812747955,
            0.028120219707489014,
            -0.04497829079627991,
            -0.010312079451978207,
            -0.0040803090669214725,
            0.03279635310173035,
            0.0072840433567762375,
            -0.027343126013875008,
            -0.043121252208948135,
            -0.026581423357129097,
            -0.008439610712230206,
            -0.02202278934419155,
            0.0017683581681922078,
            0.00506485253572464,
            -0.0030420245602726936,
            -0.014372928068041801,
            -0.015305073000490665,
            -0.011398171074688435,
            0.04021359607577324,
            0.007015809882432222,
            -0.007493583485484123,
            -0.00018444280431140214,
            -0.007323667407035828,
            0.0018943161703646183,
            -0.019719617441296577,
            -0.00917133316397667,
            -0.022086041048169136,
            -0.014595690183341503,
            -0.01512693241238594,
            -0.010521716438233852,
            -0.014909641817212105,
            -0.02345358021557331,
            0.02821388654410839,
            -0.02031564898788929,
            -0.0027360303793102503,
            -0.0009796321392059326,
            0.03569205850362778,
            0.011454197578132153,
            0.011963700875639915,
            -0.00813276320695877,
            0.011942490004003048,
            0.004248166922479868,
            -0.025995831936597824,
            0.032702259719371796,
            0.014525501057505608,
            0.02102026715874672,
            0.0343896709382534,
            -0.07005468755960464,
            0.021096527576446533,
            -0.01824779622256756
        ],
        [
            0.042228203266859055,
            0.004226256627589464,
            -0.014241684228181839,
            0.002574014710262418,
            0.018221097066998482,
            -0.003464262466877699,
            0.012591268867254257,
            -0.0303428303450346,
            -0.014109600335359573,
            0.020458552986383438,
            -0.013113073073327541,
            0.025762155652046204,
            -0.02539949305355549,
            0.03566458448767662,
            0.013082128949463367,
            -0.04707227274775505,
            -0.033538784831762314,
            0.01936957985162735,
            0.05170838162302971,
            -0.025059372186660767,
            0.006539535708725452,
            -0.016084151342511177,
            -0.00868632085621357,
            -0.009092163294553757,
            0.013817084953188896,
            -0.03438805043697357,
            0.06537695229053497,
            0.04299415275454521,
            -0.02998179942369461,
            -0.0007357114809565246,
            -0.006822764407843351,
            -0.020106034353375435,
            -0.01854082942008972,
            -0.03847791254520416,
            -0.017616424709558487,
            -0.054534878581762314,
            0.02594778873026371,
            -0.00715588079765439,
            0.02654827944934368,
            -0.029095755890011787,
            -0.006157543510198593,
            -0.026175253093242645,
            0.042968880385160446,
            -0.01370975561439991,
            0.015044066123664379,
            0.01884540729224682,
            0.020986167713999748,
            0.0185578390955925,
            0.02381703071296215,
            0.018266646191477776,
            0.03679648041725159,
            0.022154731675982475,
            -0.05677209794521332,
            0.001379044377245009,
            0.04118747264146805,
            0.02911820076406002,
            -0.009803048335015774,
            0.04238869994878769,
            -0.00010915882012341172,
            0.030219994485378265,
            0.020382948219776154,
            -0.0007189702591858804,
            -0.028885483741760254,
            0.007150959689170122,
            0.0066727157682180405,
            0.030344758182764053,
            -0.03466913476586342,
            0.013668756000697613,
            0.010069267824292183,
            0.01661091297864914,
            -0.018258677795529366,
            -0.045400895178318024,
            -0.038472943007946014,
            0.0070070261135697365,
            0.013428769074380398,
            -0.031101809814572334,
            0.06699863076210022,
            -0.02313995361328125,
            -0.014035101048648357,
            0.006792756728827953,
            0.01556788757443428,
            0.015278574079275131,
            -0.03203902766108513,
            -0.01735759899020195,
            -0.016227608546614647,
            0.02227744646370411,
            -0.037085503339767456,
            -0.0008098016260191798,
            0.01300357747823,
            -0.005806011147797108,
            0.01970381662249565,
            -0.02312767505645752,
            -0.001134183956310153,
            0.008866798132658005,
            -0.021118637174367905,
            0.013996479101479053,
            0.0026035532355308533,
            0.0006597404135391116,
            0.086666040122509,
            0.024832453578710556,
            0.018516570329666138,
            -0.0009977803565561771,
            0.016200169920921326,
            0.001423268928192556,
            0.024337725713849068,
            -0.021896110847592354,
            0.043204907327890396,
            0.00013840841711498797,
            0.024802010506391525,
            0.010601402260363102,
            0.018910996615886688,
            0.01762864552438259,
            -0.026687605306506157,
            -0.005011781584471464,
            -0.01408388838171959,
            -0.01011032983660698,
            0.006975787691771984,
            -0.027365107089281082,
            -0.029692457988858223,
            -0.004559376277029514,
            0.006949346978217363,
            -0.022923048585653305,
            -0.02918187715113163,
            -0.04224440082907677,
            0.01256331242620945,
            0.015780312940478325,
            0.01643265038728714,
            -0.025414761155843735,
            0.013961436226963997,
            0.038553591817617416,
            -0.009785274043679237,
            -0.004525535274296999,
            0.08744804561138153,
            0.051463402807712555,
            -0.005909271538257599,
            -0.02725246362388134,
            -0.048500608652830124,
            0.044589314609766006,
            -0.03830462321639061,
            0.01859176531434059,
            -0.0017643091268837452,
            -0.0023469419684261084,
            -0.053599584847688675,
            -0.04594649001955986,
            0.03135507553815842,
            -0.005528806243091822,
            -0.002151131397113204,
            0.01572277769446373,
            0.004378573037683964,
            0.009318209253251553,
            0.03129329904913902,
            -0.008936434984207153,
            0.005051193293184042,
            -0.01013097818940878,
            -0.029958656057715416,
            0.027265654876828194,
            -0.02560226060450077,
            -0.042916689068078995,
            -0.001527414540760219,
            0.02305166982114315,
            0.027800388634204865,
            0.009406507946550846,
            -0.0009723531547933817,
            0.014428806491196156,
            -0.022211195901036263,
            0.024865008890628815,
            -0.007281215395778418,
            -0.02426246739923954,
            -0.026718275621533394,
            0.0048415400087833405,
            0.01930178515613079,
            -0.012114849872887135,
            0.029644183814525604,
            -0.02261456847190857,
            0.011138662695884705,
            0.019489215686917305,
            0.034220196306705475,
            0.019467424601316452,
            -0.0031009423546493053,
            0.015741437673568726,
            0.06047966331243515,
            -0.00287158926948905,
            0.03144499287009239,
            -0.01655995473265648,
            -0.017084242776036263,
            -0.030279837548732758,
            0.06565285474061966,
            0.01801261305809021,
            -0.002234421204775572,
            0.0008886822615750134,
            0.03956189751625061,
            0.02514350414276123,
            -0.06127513200044632,
            -0.002341798972338438,
            0.007990416139364243,
            -0.009544670581817627,
            0.006739911623299122,
            0.0024956620763987303,
            0.05622955039143562,
            0.01137396227568388,
            0.017247958108782768,
            -0.03205299749970436,
            -0.024214299395680428,
            0.020901408046483994,
            0.011973481625318527,
            -0.007076566573232412,
            0.004380180034786463,
            0.04803164303302765,
            -0.00914147961884737,
            -0.019927943125367165,
            0.022030359134078026,
            0.029910024255514145,
            -0.03392970561981201,
            -0.028818495571613312,
            0.049141932278871536,
            0.0024012711364775896,
            -0.025816716253757477,
            0.015489273704588413,
            0.009564529173076153,
            0.004901517648249865,
            0.01748962514102459,
            0.035227276384830475,
            -0.03560797870159149,
            0.013277413323521614,
            0.017837073653936386,
            -0.025843877345323563,
            0.056096162647008896,
            -0.0043550156988203526,
            0.02862214483320713,
            -0.03852792829275131,
            -0.06427507102489471,
            0.006352418567985296,
            -0.021056396886706352,
            -0.019070101901888847,
            0.05586092919111252,
            -0.034184519201517105,
            -0.014630245044827461,
            -0.005613991059362888,
            -0.0171950776129961,
            -7.858801109250635e-05,
            -0.05363531410694122,
            -0.008947072550654411,
            0.025363365188241005,
            -0.0019880877807736397,
            -0.019971011206507683,
            -0.003757279133424163,
            0.04149366542696953,
            -0.025952162221074104,
            -0.0029647238552570343,
            -0.06693460792303085,
            -0.0018512890674173832,
            -0.0004182960547041148,
            -0.028571272268891335,
            -0.0024353782646358013,
            0.013999821618199348,
            0.020122094079852104,
            0.027747267857193947,
            0.029632622376084328,
            -0.023068660870194435,
            -0.0029056353960186243,
            0.03425921872258186,
            -0.01884123682975769,
            -0.03892555460333824,
            -0.00908927246928215,
            0.0010426236549392343,
            -0.008106392808258533,
            0.003537701675668359,
            0.014652561396360397,
            -0.00495955441147089,
            -0.025192994624376297,
            -0.03047926351428032,
            0.030888356268405914,
            0.0048210397362709045,
            0.03465145081281662,
            -0.015317444689571857,
            0.00883815623819828,
            0.028462223708629608,
            -0.030239887535572052,
            -0.008781497366726398,
            -0.0342637337744236,
            -0.0202752947807312,
            0.0025797684211283922,
            -0.006811491679400206,
            0.006108935922384262,
            -0.02128640189766884,
            -0.03939526900649071,
            -0.013877352699637413,
            -0.03802817687392235,
            -0.005617883987724781,
            -0.007922708056867123,
            -0.03386485576629639,
            0.022633954882621765,
            0.010445657186210155,
            0.01357962191104889,
            -0.005694268736988306,
            0.01093584019690752,
            0.017993928864598274,
            0.00842773262411356,
            0.020251236855983734,
            0.02609054185450077,
            0.020114930346608162,
            -0.0013738065026700497,
            0.04296667501330376,
            -0.033089227974414825,
            -0.0041043139062821865,
            -0.02587895095348358,
            0.03498845174908638,
            -0.05135899782180786,
            -0.045776814222335815,
            -0.009037823416292667,
            0.05957537889480591,
            -0.01882321387529373,
            -0.016796743497252464,
            0.024887962266802788,
            0.003226423403248191,
            0.028138326480984688,
            0.0027506581973284483,
            -0.02220720797777176,
            -0.00838632695376873,
            -0.0033795672934502363,
            -0.0407802015542984,
            -0.018031207844614983,
            -0.05454091355204582,
            0.0020554917864501476,
            0.013619127683341503,
            -0.02394423820078373,
            0.01767711713910103,
            0.04221080616116524,
            -0.02660137601196766,
            -0.01478639617562294,
            0.011362174525856972,
            0.000815163308288902,
            0.0167937520891428,
            -0.011005789041519165,
            0.01823323220014572,
            0.03996095806360245,
            -0.017361056059598923,
            0.019811099395155907,
            -0.02068941295146942,
            0.02291748858988285,
            0.017585208639502525,
            -0.002466228324919939,
            0.02424076944589615,
            -0.04925759509205818,
            -0.005852509289979935,
            -0.02129940129816532,
            0.021778197959065437,
            -0.013685178942978382,
            -0.004327818751335144,
            -0.020083434879779816,
            -0.017343852669000626,
            -0.03931410238146782,
            0.015521932393312454,
            -0.04147397354245186,
            0.034222155809402466,
            -0.04264562204480171,
            -0.0038717782590538263,
            -0.017765643075108528,
            -0.04403696581721306,
            0.008025649935007095,
            -0.023832084611058235,
            -0.002360825426876545,
            0.018221409991383553,
            -0.010829724371433258,
            -0.01674978993833065,
            -0.008948258124291897,
            -0.014333860017359257,
            0.024641571566462517,
            -0.00021243063383735716,
            -0.008524611592292786,
            -0.014239109121263027,
            -0.03741026297211647,
            -0.010974203236401081,
            -0.015532165765762329,
            0.04341508448123932,
            -0.01811833679676056,
            0.009543828666210175,
            -0.02355845831334591,
            -0.015340186655521393,
            0.0010967219714075327,
            -0.0182289257645607,
            -0.03705707937479019,
            0.00843043252825737,
            -0.0055646514520049095,
            -0.004008255898952484,
            -0.03527331352233887,
            -0.011533205397427082,
            -0.04762868583202362,
            0.020520489662885666,
            -0.0385877750813961,
            0.0053719887509942055,
            0.020888876169919968,
            -0.032116811722517014,
            0.007486134767532349,
            -0.022111762315034866,
            -0.02329086698591709,
            0.010589390993118286,
            0.009748314507305622,
            -0.012866487726569176,
            0.01586752012372017,
            -0.02279818058013916,
            -0.03873399272561073,
            -0.02600826881825924,
            -0.05296318978071213,
            -0.026454027742147446,
            0.04801313951611519,
            0.0003763906133826822,
            -0.016211850568652153,
            -0.018887042999267578,
            0.020461948588490486,
            0.00842411257326603,
            -0.007572975941002369,
            -0.026468699797987938,
            -0.010066664777696133,
            -0.02320222370326519,
            -0.01658128947019577,
            -0.024161821231245995,
            0.022116471081972122,
            -0.0677771046757698,
            -0.028034187853336334,
            0.056089308112859726,
            -0.02535983920097351,
            -0.008970635011792183,
            -0.011409460566937923,
            0.06722259521484375,
            -0.014377681538462639,
            -0.017518827691674232,
            0.007877692580223083,
            -0.0025389459915459156,
            0.019946618005633354,
            -0.03108704648911953,
            0.017322590574622154,
            0.0026284530758857727,
            -0.02256060391664505,
            -0.0243521798402071,
            0.04671645537018776,
            0.009584220126271248,
            0.006104000378400087,
            -0.015569748356938362,
            -0.01567552052438259,
            -0.029892532154917717,
            0.010755684226751328,
            -0.022139566019177437,
            0.00412167189642787,
            -0.05240946263074875,
            -0.054120033979415894,
            0.014067305251955986,
            -0.05189729854464531,
            -0.059773363173007965,
            0.005925429053604603,
            -0.02432091161608696,
            -0.014412502758204937,
            -0.008760415017604828,
            -0.030899086967110634,
            -0.00984290149062872,
            0.004615998361259699,
            0.05317588895559311,
            0.00870517734438181,
            0.01740974374115467,
            0.0073993029072880745,
            -0.01714845933020115,
            0.00540143484249711,
            -0.015237047336995602,
            -0.006141049321740866,
            0.009814637713134289,
            -0.019859561696648598,
            0.01717022992670536,
            -0.027854369953274727,
            -0.0210565272718668,
            0.005131510552018881,
            -0.011507407762110233,
            -0.024559080600738525,
            -0.008387364447116852,
            -0.005280562210828066,
            0.02625609003007412,
            -0.0002835234045051038,
            -0.03892312943935394,
            -0.029261518269777298,
            -0.01829877682030201,
            -0.053446922451257706,
            0.03340465575456619,
            0.04488097131252289,
            0.009126641787588596,
            0.021133901551365852,
            0.010387384332716465,
            -0.0125073017552495,
            -0.02920026332139969,
            -0.03467957302927971,
            -0.053582098335027695,
            -0.030763670802116394,
            0.0006977056036703289,
            -0.04397251456975937,
            -0.013133017346262932,
            -0.014504379592835903,
            0.016454406082630157,
            0.011371581815183163,
            0.025227656587958336,
            0.009820074774324894,
            -0.023065999150276184,
            -0.00651830667629838,
            -0.02416953071951866,
            -0.05543633550405502,
            -0.03227631375193596,
            -0.06663872301578522,
            -0.007076120004057884,
            0.013651618734002113,
            0.027861090376973152,
            0.0313444621860981,
            -0.019954174757003784,
            -0.00021182048658374697,
            0.021457489579916,
            -0.017471451312303543,
            0.002552986843511462,
            -0.03435944765806198,
            -0.02785470336675644,
            -0.016861585900187492,
            0.0068240417167544365,
            -0.014311030507087708,
            0.005932726431638002,
            -0.0031976825557649136,
            -0.013733870349824429,
            -0.005540465470403433,
            -0.02192024700343609,
            0.0064387391321361065,
            -0.022467534989118576,
            0.01621478609740734,
            -0.05724859982728958,
            -0.0012644209200516343,
            0.043991100043058395,
            0.006665460765361786,
            -0.010648896917700768,
            0.017429247498512268,
            0.026162149384617805,
            0.02844015136361122,
            -0.0006093871197663248,
            -0.03247952461242676,
            0.004777323920279741,
            -0.02642875164747238,
            -0.01923433691263199,
            -0.004740037955343723,
            -0.030733516439795494,
            0.0005943433498032391,
            -0.030392171815037727,
            0.0032997385133057833,
            -0.0003824321029242128,
            -0.040420252829790115,
            -0.01714727282524109,
            -0.006974018644541502,
            -0.028461836278438568,
            -0.012384356930851936,
            0.0061798314563930035,
            -0.03842789679765701,
            -0.010521071031689644,
            -0.04398822411894798,
            -0.007211352698504925,
            -0.00949067622423172,
            -0.016123495995998383,
            -0.021362630650401115,
            0.007960549555718899,
            -0.019010350108146667,
            -0.02725968509912491,
            0.02162010222673416,
            -0.006058820988982916,
            -0.004788713529706001,
            -0.029902275651693344,
            0.018650202080607414,
            -0.04306136444211006,
            -0.03417106345295906,
            0.042338695377111435,
            -0.04415660724043846,
            0.008515726774930954,
            -0.01807529293000698,
            -0.04656544327735901,
            -0.01752539351582527,
            0.006875122897326946,
            -0.0033528769854456186,
            -0.0209618229418993,
            0.02903175726532936,
            0.005850053392350674,
            0.00015293413889594376,
            0.010960702784359455,
            -0.014687001705169678,
            -0.0049579753540456295,
            -0.06371237337589264,
            -0.027480868622660637,
            -0.05515643581748009,
            -0.048312149941921234,
            0.007662846241146326,
            0.017533408477902412,
            -0.011085623875260353,
            0.005876627750694752,
            -0.00021782224939670414,
            0.02531457506120205,
            0.016431575641036034,
            0.019039416685700417,
            -0.002499448601156473,
            0.04251331463456154,
            -0.0425448976457119,
            -0.018083546310663223,
            -0.0335179939866066,
            0.005933690350502729,
            -0.0224673580378294,
            -0.0033090764191001654,
            -0.01567997597157955,
            0.005898931995034218,
            -0.014717929996550083,
            0.00949269998818636,
            0.004295981023460627,
            0.048992227762937546,
            0.031416937708854675,
            -0.0756923034787178,
            0.001136616338044405,
            -0.015002482570707798,
            -0.017947832122445107,
            0.040179163217544556,
            -0.06277240067720413,
            -0.029281064867973328,
            0.012227335013449192,
            0.030226197093725204,
            0.024420537054538727,
            -0.04154326766729355,
            0.03285043314099312,
            0.0038350000977516174,
            -0.0026030433364212513,
            0.013467161916196346,
            -0.00655319495126605,
            0.020969562232494354,
            -0.024385720491409302,
            0.014360101893544197,
            0.012031851336359978,
            0.04979554936289787,
            -0.029979245737195015,
            0.022962555289268494,
            -0.023714877665042877,
            -0.006652601528912783,
            -0.005873316433280706,
            -0.03603131324052811,
            -0.019951732829213142,
            0.034001607447862625,
            0.010191471315920353,
            0.03712121397256851,
            0.0230038333684206,
            -0.03581641986966133,
            -0.05170884355902672,
            -0.019343988969922066,
            -0.03495188057422638,
            0.00077206757850945,
            -0.03739878535270691,
            -0.006586238741874695,
            -0.036528050899505615,
            0.005224897526204586,
            0.03910544142127037,
            -0.00186723074875772,
            -0.03416765108704567,
            0.0224551223218441,
            -0.020898669958114624,
            -0.015840796753764153,
            0.0020093973726034164,
            0.00922271516174078,
            0.017193244770169258,
            0.008098861202597618,
            0.0037901115138083696,
            -0.051528435200452805,
            0.05812330171465874,
            -0.02935967594385147,
            0.03149191290140152,
            0.03641427308320999,
            -0.007318592630326748,
            0.027067139744758606,
            0.009753179736435413,
            0.02345806173980236,
            0.05254149064421654,
            0.021116087213158607,
            0.016091125085949898,
            -0.003358078422024846,
            -0.07258520275354385,
            -0.0155378058552742,
            -0.012469354085624218,
            0.005296183750033379,
            -0.006573072634637356,
            -0.027844006195664406,
            0.015482190996408463,
            0.009088017046451569,
            -0.013573801144957542,
            -0.03586189076304436,
            0.028213098645210266,
            0.005692046135663986,
            0.060176849365234375,
            -0.0061620669439435005,
            0.006803788710385561,
            0.030458392575383186,
            0.0010438332101330161,
            -0.013948624953627586,
            -0.03597768768668175,
            -0.011989076621830463,
            0.0070376102812588215,
            -0.01710977405309677,
            -0.047156091779470444,
            0.028351588174700737,
            -0.04460544511675835,
            -0.00512669188901782,
            -0.06524748355150223,
            -0.013015073724091053,
            -0.027127714827656746,
            -0.024796858429908752,
            -0.02375066466629505,
            0.0059638433158397675,
            0.009593014605343342,
            0.02116367779672146,
            -0.006568936165422201,
            0.02201991155743599,
            -0.026874052360653877,
            -0.0233865138143301,
            -0.026922060176730156,
            0.007176571525633335,
            -0.014508799649775028,
            0.07239435613155365,
            0.006429005414247513,
            -0.02982148341834545,
            0.022397402673959732,
            0.04270971938967705,
            0.02563033066689968,
            -0.019257336854934692,
            -0.022690583020448685,
            0.00598645955324173,
            0.018733439967036247,
            -0.02336266078054905,
            0.027143554762005806,
            -0.040383707731962204,
            0.02455143816769123,
            0.04458250850439072,
            0.008535320870578289,
            -0.023242482915520668,
            -0.002125613624230027,
            -0.009182003326714039,
            0.005051761865615845,
            0.004650450311601162,
            -0.018196333199739456,
            0.00339371501468122,
            -0.03603871166706085,
            -0.005639871582388878,
            -0.05292486399412155,
            -0.02674747072160244,
            -0.003947901073843241,
            -0.0015552917029708624,
            -0.033990222960710526,
            -0.006666428875178099,
            0.0025826555211097,
            -0.007004832848906517,
            0.022946981713175774,
            0.01916073076426983,
            -0.0005265998770482838,
            -0.026988042518496513,
            -0.02834523469209671,
            0.006961101200431585,
            0.031045526266098022,
            -0.02349855564534664,
            0.026602620258927345,
            -0.03357845917344093,
            0.022096842527389526,
            -0.012552306056022644,
            0.05306251719594002,
            0.06298041343688965,
            -0.02899770438671112,
            0.029487615451216698,
            -0.01351464819163084,
            -0.0340927392244339,
            0.01094517856836319,
            -0.00392600242048502,
            0.02325722761452198,
            0.030618922784924507,
            0.002543441951274872,
            0.010459231212735176,
            0.018076680600643158,
            -0.010189673863351345,
            0.00441349670290947,
            -0.09727411717176437,
            -0.00010059832129627466,
            -0.030041806399822235,
            0.022206313908100128,
            -0.022113310173153877,
            0.02492106705904007,
            -0.02853389084339142,
            -0.02357007935643196,
            -0.021445278078317642,
            0.003680748399347067,
            -0.01596345193684101,
            -0.023258620873093605,
            0.00414506159722805,
            0.031431894749403,
            -0.014450224116444588,
            -0.030621003359556198,
            0.0125765111297369,
            0.013279681093990803,
            0.0342598557472229,
            0.02797587774693966,
            0.019099121913313866,
            -0.008975768461823463,
            -0.022618595510721207,
            -0.03361080214381218,
            0.030112935230135918,
            -0.0032633962109684944,
            0.04378368705511093,
            -0.030748343095183372,
            -0.04650101065635681,
            -0.005384803283959627,
            0.035238880664110184,
            -0.029323743656277657,
            0.030781513080000877,
            0.00013854999269824475,
            0.017609231173992157,
            0.03987406939268112,
            0.03134283795952797,
            0.02667626179754734,
            0.054844390600919724,
            0.024010783061385155,
            -0.035767897963523865,
            0.009686886332929134,
            0.015668118372559547,
            -0.02730727195739746,
            0.031921885907649994,
            0.016122570261359215,
            -0.030082697048783302,
            -0.013717394322156906,
            0.006585773546248674,
            0.02284877747297287,
            0.023540588095784187,
            0.01950754225254059,
            -0.0020374420564621687,
            -0.024881241843104362,
            0.044050171971321106,
            0.012729855254292488,
            -0.061660539358854294,
            -0.0012996895238757133,
            0.0020792975556105375,
            0.0083383247256279,
            0.020738767459988594,
            -0.0025937489699572325,
            -0.0051376051269471645,
            -0.06815295666456223,
            -0.008340038359165192,
            -0.0009538837475702167,
            -0.00034992845030501485,
            0.01717611402273178,
            0.013039731420576572,
            0.007765699177980423,
            -0.039575688540935516,
            -0.03321424126625061,
            0.02246677689254284,
            0.005291033536195755,
            -0.00734478235244751,
            0.04393961280584335,
            -0.016127727925777435,
            -0.0115241389721632,
            0.019441870972514153,
            -0.0005840263329446316,
            -0.01323450542986393,
            0.013151651248335838,
            -0.041085146367549896,
            -0.06738033145666122,
            0.010928142815828323,
            -0.006050594616681337,
            0.031240075826644897,
            -0.0067702773958444595,
            -0.02285187505185604,
            -0.0455307736992836,
            0.03130725398659706,
            -0.008552235551178455,
            -0.02464604564011097,
            -0.002650116803124547,
            0.03668768331408501,
            -0.004688150715082884,
            0.0012410145718604326,
            -0.009034384973347187,
            0.02408059686422348,
            -0.002935264725238085,
            -0.02818155102431774,
            -0.017559828236699104,
            -0.01587664894759655,
            -0.023996125906705856,
            0.04578051343560219,
            -0.00272749294526875,
            0.00486669410020113,
            0.03075665794312954,
            0.012628541328012943,
            -0.03502664715051651,
            0.005129734519869089,
            -0.014543307013809681,
            0.031075092032551765,
            0.004302632994949818,
            0.03935985639691353,
            -0.014865276403725147,
            -0.037352241575717926,
            0.0725034847855568,
            0.02793191745877266,
            0.01945214346051216,
            -0.009539566934108734,
            -0.004002354107797146,
            -0.0016790591180324554,
            0.014327842742204666,
            -0.009908793494105339,
            0.03745026886463165,
            0.03750528395175934,
            -0.002172013046219945,
            0.012405315414071083,
            0.04281596466898918,
            -0.024131815880537033,
            -0.02418011613190174,
            -0.008574241772294044,
            -0.01767617091536522,
            -0.006889308802783489,
            -0.021865440532565117,
            0.02049923688173294,
            0.012997176498174667,
            0.030696028843522072,
            0.02936651185154915,
            -0.026929005980491638,
            0.000939241552259773,
            -0.008251972496509552,
            -0.008750030770897865,
            -0.057950425893068314,
            -0.02374190464615822,
            0.02269514836370945,
            0.007980387657880783,
            0.024826686829328537,
            -0.04151049628853798,
            -0.008030382916331291,
            0.0032609945628792048,
            -0.04117418825626373,
            0.020577186718583107,
            -0.007843734696507454,
            0.00965837948024273,
            0.025571268051862717,
            -0.026570959016680717,
            -0.01261601597070694,
            -0.013642162084579468,
            0.00407788110896945,
            -0.03831307962536812,
            -0.008230982348322868,
            -0.024398198351264,
            0.012066417373716831,
            -0.03269485384225845,
            -0.007638324983417988,
            -0.04605601355433464,
            0.034772809594869614,
            0.026094883680343628,
            -0.047987114638090134,
            0.0020003141835331917,
            -0.03928006812930107,
            0.000858725281432271,
            0.02646956779062748,
            0.01247770432382822,
            -0.032780829817056656,
            -0.0034309346228837967,
            -0.09434280544519424,
            0.04704707860946655,
            -0.004937375895678997,
            -0.04140973463654518,
            0.004407582804560661,
            -0.012182798236608505,
            0.02047846093773842,
            -0.031539514660835266,
            -0.05598084628582001,
            -0.012977620586752892,
            -0.08271777629852295,
            -0.0412098690867424,
            0.021711071953177452,
            0.007391872350126505,
            0.025809956714510918,
            0.0014605582691729069,
            0.002125747036188841,
            0.019092246890068054,
            -0.02118077129125595,
            0.005309391766786575,
            0.012626606039702892,
            0.009215161204338074,
            -0.04993930459022522,
            0.023306459188461304,
            -0.010568368248641491,
            -0.0011808176059275866,
            -0.038787562400102615,
            -0.02869601733982563,
            -0.035030435770750046,
            -0.02232334390282631,
            0.01625349558889866,
            -0.023356668651103973,
            0.04553249478340149,
            0.007161841262131929,
            0.017953015863895416,
            -0.014709088020026684,
            0.02695014327764511,
            -0.040184881538152695,
            0.01021616905927658,
            0.025350350886583328,
            -0.012723090127110481,
            0.01166533399373293,
            0.005788545124232769,
            0.01425167080014944,
            0.009876747615635395,
            -3.835455936496146e-05,
            0.021634578704833984,
            -0.00016287407197523862,
            0.00427653081715107,
            0.009878168813884258,
            0.018352514132857323,
            -0.020168200135231018,
            -0.009430932812392712,
            -0.038969483226537704,
            0.018989197909832,
            -0.07414939999580383,
            0.019701076671481133,
            -0.015928301960229874,
            0.01680295169353485,
            0.03678687661886215,
            -0.012090325355529785,
            -0.030200788751244545,
            -0.006024480797350407,
            -0.0353991724550724,
            -0.0005522641004063189,
            -0.014420803636312485,
            -0.0060987020842731,
            0.013036545366048813,
            -0.01693005859851837,
            0.02773546241223812
        ],
        [
            -0.004383450374007225,
            -0.03510064631700516,
            -0.0016802711179479957,
            -0.02592376060783863,
            0.026005614548921585,
            -0.03889840096235275,
            0.02082039974629879,
            0.012410076335072517,
            -0.023052962496876717,
            -0.0017148342449218035,
            0.017588473856449127,
            -0.021815234795212746,
            0.0002442908298689872,
            0.01851790025830269,
            -0.023331904783844948,
            0.03373345732688904,
            -0.017471466213464737,
            0.013908579014241695,
            0.036785710602998734,
            -0.016134798526763916,
            0.0013947829138487577,
            0.01995766907930374,
            0.05764984339475632,
            -0.017432643100619316,
            0.035874415189027786,
            0.01588360033929348,
            0.04029601812362671,
            0.01733797788619995,
            -0.03345440700650215,
            0.02160647325217724,
            0.013514857739210129,
            0.003974118735641241,
            -0.013809560798108578,
            -0.01878920942544937,
            -0.01332000084221363,
            0.0019236919470131397,
            0.0005722201894968748,
            0.021802213042974472,
            0.024095183238387108,
            0.0008447428699582815,
            -0.02113243006169796,
            0.004261179361492395,
            -0.005383735056966543,
            -0.005897087510675192,
            0.033714886754751205,
            0.010658297687768936,
            0.0299989040941,
            0.017268963158130646,
            0.017695125192403793,
            0.004851826932281256,
            -0.01811399683356285,
            -0.011338304728269577,
            -0.03859894722700119,
            -0.019589833915233612,
            0.0069471742026507854,
            0.02393275499343872,
            0.010247611440718174,
            0.032915230840444565,
            -0.009775390848517418,
            0.027992894873023033,
            0.010505708865821362,
            -0.029296189546585083,
            0.023373156785964966,
            -0.008499087765812874,
            -0.018211234360933304,
            -0.03507482260465622,
            -0.02062745951116085,
            -0.013770240359008312,
            -0.02384410984814167,
            0.027267595753073692,
            -0.002199052833020687,
            -0.007454720791429281,
            -0.00852183811366558,
            -0.002633493859320879,
            -0.04145927354693413,
            -0.008325064554810524,
            -0.017633093520998955,
            0.026540376245975494,
            -0.018954960629343987,
            -0.019652392715215683,
            -0.034222621470689774,
            0.028040669858455658,
            0.03475192189216614,
            -0.012255473993718624,
            -0.01800762116909027,
            -0.012442396953701973,
            -0.0011014100164175034,
            0.03714629262685776,
            -0.024001287296414375,
            0.025506986305117607,
            0.008782818913459778,
            0.015329373069107533,
            -0.018894942477345467,
            -0.04664643108844757,
            0.016870830208063126,
            -0.017444411292672157,
            0.0177569929510355,
            -0.02085568569600582,
            0.0448681116104126,
            -0.024202976375818253,
            0.015524988994002342,
            0.008329733274877071,
            0.014726377092301846,
            0.009057419374585152,
            -0.008387318812310696,
            -0.0025469560641795397,
            0.009152093902230263,
            -0.01927144266664982,
            0.015276671387255192,
            0.008325128816068172,
            0.005901314318180084,
            0.0030984876211732626,
            0.00802680291235447,
            -0.015077822841703892,
            0.029017295688390732,
            -0.026423530653119087,
            0.02327865920960903,
            0.03349354863166809,
            -0.002281538676470518,
            -0.006764214951545,
            -0.014672014862298965,
            0.012437581084668636,
            -0.005768461152911186,
            0.021528827026486397,
            -0.013022315688431263,
            0.02998543158173561,
            -0.016832644119858742,
            -0.022973360493779182,
            0.0016878588357940316,
            -0.008937831968069077,
            0.02095974236726761,
            0.04164170101284981,
            0.01214094553142786,
            0.03766818344593048,
            -0.018074804916977882,
            -0.003835141658782959,
            -0.012880243360996246,
            0.0011243053013458848,
            -0.007160174194723368,
            0.014746854081749916,
            0.018397198989987373,
            0.004374837037175894,
            0.007796080783009529,
            0.016454286873340607,
            0.03351336717605591,
            0.01842430606484413,
            0.036426346749067307,
            -0.0062173944897949696,
            -0.012392899952828884,
            0.01870923861861229,
            -0.02030903473496437,
            -0.033150359988212585,
            -0.014347412623465061,
            -0.0014621926238760352,
            0.02947949804365635,
            -0.007307332940399647,
            0.003072442952543497,
            -0.01746983453631401,
            0.008646986447274685,
            -0.048749890178442,
            0.01055506058037281,
            -0.02043008990585804,
            -0.011277392506599426,
            0.027302853763103485,
            0.02899331785738468,
            0.00013903096260037273,
            -0.025356315076351166,
            0.030520059168338776,
            0.02821263298392296,
            -0.006068279966711998,
            -0.022146228700876236,
            0.029901694506406784,
            0.04549788311123848,
            -0.009563855826854706,
            0.013133790343999863,
            0.007611870765686035,
            0.012499331496655941,
            -0.020822936668992043,
            0.0007909530540928245,
            0.006210423540323973,
            -0.0352458693087101,
            -0.005789068527519703,
            0.0018547635991126299,
            0.032796282321214676,
            -0.02754303812980652,
            0.022587571293115616,
            -0.00506974384188652,
            -0.034608423709869385,
            -0.0159879382699728,
            0.02175311930477619,
            0.0028988670092076063,
            0.01148700900375843,
            0.047956351190805435,
            0.006827324628829956,
            -0.02226421609520912,
            -0.0026021162047982216,
            -0.002027226611971855,
            0.030643749982118607,
            -0.0028862145263701677,
            -0.011215725913643837,
            0.005181430839002132,
            -0.031438589096069336,
            0.012495254166424274,
            0.0252679493278265,
            0.022975528612732887,
            0.006598330568522215,
            0.018640395253896713,
            0.02140413597226143,
            0.02804899774491787,
            0.028700225055217743,
            0.0024960266891866922,
            0.0357309952378273,
            -0.013823778368532658,
            0.018019728362560272,
            0.006825828924775124,
            0.031061777845025063,
            0.02073723077774048,
            0.025260016322135925,
            0.015875710174441338,
            -0.0019680827390402555,
            -0.004479632712900639,
            0.02208557538688183,
            0.041000846773386,
            -0.0007263558800332248,
            -0.013911993242800236,
            0.009859954938292503,
            -0.014770262874662876,
            -0.009891824796795845,
            0.03563014417886734,
            0.011194785125553608,
            -0.021671989932656288,
            0.010350489057600498,
            0.004867946729063988,
            -0.017309533432126045,
            0.020101269707083702,
            0.01614629104733467,
            -0.04388806223869324,
            0.010018442757427692,
            0.01030244491994381,
            -0.008606597781181335,
            -0.01899169199168682,
            0.002064777771010995,
            0.004517104476690292,
            0.026733681559562683,
            0.01153627410531044,
            -0.01029121968895197,
            0.0052582621574401855,
            -0.016982244327664375,
            0.008143214508891106,
            -0.0184469036757946,
            0.031465012580156326,
            0.002523689530789852,
            0.032921548932790756,
            0.027432579547166824,
            0.0014545002486556768,
            -0.006769463885575533,
            -0.013198998756706715,
            -0.011054244823753834,
            -0.009066383354365826,
            -0.01749887689948082,
            0.011059836484491825,
            0.031731776893138885,
            -0.021259602159261703,
            -0.04166392982006073,
            -0.03157833218574524,
            0.010993842035531998,
            0.014969075098633766,
            -0.01972852274775505,
            0.016824081540107727,
            0.021188948303461075,
            -0.005800371523946524,
            -0.03223896026611328,
            0.016068536788225174,
            -0.0029576444067060947,
            -0.010181618854403496,
            0.022088218480348587,
            0.0014992662472650409,
            0.01845579780638218,
            0.017291491851210594,
            0.03223409503698349,
            0.016118723899126053,
            -0.01885644532740116,
            -0.014927751384675503,
            -0.010805551894009113,
            0.026074612513184547,
            -0.0041770306415855885,
            -0.0230245478451252,
            -0.01779063418507576,
            -0.021703345701098442,
            0.009601715952157974,
            0.0160883367061615,
            0.013253680430352688,
            0.008885576389729977,
            0.004880791530013084,
            -0.030317014083266258,
            0.05078626051545143,
            0.041359152644872665,
            -0.007906843908131123,
            -0.008719656616449356,
            0.002054393058642745,
            0.0043851276859641075,
            0.009737644344568253,
            -0.004421566147357225,
            0.007745411247014999,
            0.014264694415032864,
            0.0032081014942377806,
            -0.00043671269668266177,
            -0.05447371304035187,
            0.018605871126055717,
            0.028543036431074142,
            0.017295243218541145,
            -0.020819535478949547,
            -0.029831400141119957,
            -0.03251621127128601,
            -0.014849103055894375,
            0.003750164294615388,
            0.008507939986884594,
            0.027661634609103203,
            0.029864942654967308,
            -0.017933785915374756,
            -0.0061513702385127544,
            0.0017675583949312568,
            0.0019127123523503542,
            0.029470812529325485,
            0.018635127693414688,
            -0.0006656859186477959,
            -0.03943862393498421,
            0.0029609755147248507,
            0.012688107788562775,
            0.0140774454921484,
            0.01903792843222618,
            0.03174898773431778,
            -0.017976658418774605,
            -0.027234334498643875,
            -0.02921014092862606,
            -0.002611554926261306,
            -0.004856306128203869,
            -0.020868979394435883,
            -0.0005624015466310084,
            0.00036133607500232756,
            -0.0003823381557594985,
            -0.019730256870388985,
            -0.015108690597116947,
            0.008416076190769672,
            0.009255694225430489,
            -0.014061175286769867,
            0.02522893436253071,
            0.035011373460292816,
            -0.026885610073804855,
            0.026890341192483902,
            0.006574627943336964,
            -0.01958496868610382,
            -0.006100631784647703,
            0.026462966576218605,
            0.029877861961722374,
            0.00850613508373499,
            -0.031811345368623734,
            -0.00025158250355161726,
            0.012218354269862175,
            0.0037531224079430103,
            -0.02932692877948284,
            -0.0033102051820605993,
            0.025136370211839676,
            -0.0011558247497305274,
            0.029388301074504852,
            0.013356084935367107,
            -0.058028414845466614,
            0.021695271134376526,
            0.0163212101906538,
            -0.011436824686825275,
            -0.0052989874966442585,
            0.02986546978354454,
            -0.02563955821096897,
            0.02005314640700817,
            0.01580442674458027,
            -0.046882275491952896,
            0.0341750793159008,
            0.03711407631635666,
            0.024442065507173538,
            -0.007508391048759222,
            0.008347582072019577,
            0.019056551158428192,
            0.0034538947511464357,
            -0.021001413464546204,
            0.024959692731499672,
            0.05084756016731262,
            -1.5469996696992894e-06,
            -0.018624674528837204,
            0.04188496246933937,
            -0.008147072978317738,
            -0.010497928597033024,
            0.004860322456806898,
            -0.017180832102894783,
            0.0014163776068016887,
            0.016123734414577484,
            -0.006152580492198467,
            0.019232088699936867,
            -0.008713322691619396,
            0.01154821552336216,
            0.01729734055697918,
            -0.008062792010605335,
            0.00984620675444603,
            0.02231660671532154,
            -0.01090322621166706,
            -0.01590767689049244,
            0.013040426187217236,
            -0.02301681973040104,
            -0.008354846388101578,
            0.015150710009038448,
            0.014533164910972118,
            0.013318594545125961,
            0.02692120149731636,
            0.028793346136808395,
            -0.03163434565067291,
            -0.03791821375489235,
            0.009841387160122395,
            0.024033555760979652,
            -0.0015819328837096691,
            -0.0062205009162425995,
            -0.025747353211045265,
            0.02171482890844345,
            0.03120073303580284,
            0.04751782491803169,
            0.002116032876074314,
            0.0028151453007012606,
            0.03409162908792496,
            0.012225399725139141,
            -0.0283119585365057,
            0.014640701934695244,
            -0.04779764637351036,
            0.030422793701291084,
            -0.04287824407219887,
            0.010814649984240532,
            0.015676580369472504,
            -0.003765488974750042,
            0.02636810578405857,
            -0.03269500657916069,
            -0.014333697967231274,
            0.026556706055998802,
            0.018390752375125885,
            -0.010143560357391834,
            0.013012823648750782,
            0.01718510314822197,
            -0.04052341356873512,
            0.002047101268544793,
            0.02516007237136364,
            0.0256249587982893,
            0.09216240048408508,
            -0.01125028170645237,
            -0.005634356290102005,
            -0.02447832189500332,
            0.00042232219129800797,
            0.00821487233042717,
            0.02333240956068039,
            -0.015906767919659615,
            -0.03462960943579674,
            0.04889118671417236,
            -0.005238722078502178,
            0.004241846036165953,
            0.027937384322285652,
            -0.04964631795883179,
            0.0411100871860981,
            0.005837554577738047,
            0.034365806728601456,
            -0.02856031060218811,
            0.024015208706259727,
            -0.010464807040989399,
            0.022155622020363808,
            -0.00922899879515171,
            -0.012953913770616055,
            -0.004325567744672298,
            -0.01904410310089588,
            0.013844908215105534,
            -0.024386538192629814,
            -0.004457182250916958,
            -0.018952708691358566,
            -0.011757032945752144,
            -0.02265077643096447,
            0.023245887830853462,
            -0.02667844295501709,
            0.0123283164575696,
            0.017598550766706467,
            0.025879502296447754,
            -0.014625642448663712,
            0.03532111272215843,
            0.010513882152736187,
            -0.02860277146100998,
            -0.05801638588309288,
            0.0042111556977033615,
            0.030798524618148804,
            -0.02129930816590786,
            -0.024197887629270554,
            -0.004645448178052902,
            0.03247873857617378,
            0.02179606817662716,
            -0.008571088314056396,
            0.01671546883881092,
            0.011294751428067684,
            -0.02503194473683834,
            0.02962782420217991,
            0.013490082696080208,
            0.044317565858364105,
            0.02912583015859127,
            -0.028680894523859024,
            -0.012377525679767132,
            0.025295278057456017,
            0.018783241510391235,
            -0.024889634922146797,
            -0.0028032311238348484,
            0.01121852919459343,
            0.027332423254847527,
            0.00540920952335,
            0.006667839363217354,
            0.012148069217801094,
            -0.04473848640918732,
            -0.031225405633449554,
            0.033980097621679306,
            -0.0007103114621713758,
            0.01660921424627304,
            0.012142711319029331,
            -0.0052020554430782795,
            -0.00802738219499588,
            -0.017966322600841522,
            -0.0183609239757061,
            0.0010542257223278284,
            -0.005596052389591932,
            -0.0027074276003986597,
            -0.006019768305122852,
            0.0008367167320102453,
            0.0008696122677065432,
            -0.02052907459437847,
            -0.026100456714630127,
            -0.022767584770917892,
            -0.0057811439037323,
            0.02815791592001915,
            -0.0048989346250891685,
            0.02342551201581955,
            -0.017018023878335953,
            -0.007155293598771095,
            0.019687257707118988,
            -0.03432606905698776,
            -0.004055286757647991,
            0.028748126700520515,
            0.01743265427649021,
            0.00033539332798682153,
            -0.00290320860221982,
            0.021049611270427704,
            0.02724338322877884,
            -0.0143232187256217,
            0.024865780025720596,
            -0.019692176952958107,
            0.014757733792066574,
            0.004953806754201651,
            0.0057990369386971,
            -0.0015995962312445045,
            0.03248807415366173,
            0.00459377421066165,
            0.002608884358778596,
            0.020507192239165306,
            -0.02734297700226307,
            0.03451427072286606,
            0.02682599611580372,
            0.014900253154337406,
            0.010791735723614693,
            -0.006758714560419321,
            -0.024229636415839195,
            -0.015529582276940346,
            -0.0070708696730434895,
            0.021136600524187088,
            -0.005285154562443495,
            0.019196735695004463,
            -0.017172984778881073,
            -0.023944975808262825,
            -0.02689805068075657,
            -0.012768374755978584,
            0.015504542738199234,
            0.005897513125091791,
            -0.035912711173295975,
            0.013829315081238747,
            -0.006401615682989359,
            -0.024158159270882607,
            0.036371875554323196,
            0.0027003642171621323,
            0.022139502689242363,
            -0.025135887786746025,
            -0.0002688956446945667,
            -0.007722342386841774,
            -0.013455605134367943,
            -0.02035505324602127,
            0.026716554537415504,
            0.009242935106158257,
            0.003379103261977434,
            0.02221187762916088,
            -0.011616131290793419,
            -0.021083207800984383,
            0.017937466502189636,
            0.005371185019612312,
            0.0008044217829592526,
            0.0005088404286652803,
            -0.0629705861210823,
            -0.04746232181787491,
            0.005234271287918091,
            -0.02585466019809246,
            -0.009578514844179153,
            -0.0035749541129916906,
            0.03558824583888054,
            0.03089042939245701,
            -0.008184579201042652,
            0.0014965711161494255,
            0.012464924715459347,
            -0.0022869575768709183,
            0.024760035797953606,
            0.013531822711229324,
            0.014446502551436424,
            -0.008627104572951794,
            0.037534911185503006,
            -0.028332429006695747,
            0.006341683678328991,
            0.0012517388677224517,
            0.05238017067313194,
            0.005645585712045431,
            0.0016290441853925586,
            -0.0016834400594234467,
            -0.009598612785339355,
            -0.026447633281350136,
            -0.02139490842819214,
            0.004410624038428068,
            -0.004950142931193113,
            0.02717043273150921,
            -0.0029601012356579304,
            0.0011896860087290406,
            -0.0022017485462129116,
            0.02688005566596985,
            -0.0046860817819833755,
            -0.012932999059557915,
            0.01991022378206253,
            0.026460999622941017,
            -0.041600633412599564,
            -0.030732398852705956,
            -0.014948167838156223,
            0.024888480082154274,
            0.0360177643597126,
            0.01004247460514307,
            0.0035924813710153103,
            -0.009808839298784733,
            0.01575160212814808,
            -0.06077089533209801,
            0.01564342901110649,
            -0.010157953016459942,
            -0.005057985894382,
            0.009754917584359646,
            0.011915897950530052,
            -0.015345522202551365,
            -0.025975169613957405,
            0.021863797679543495,
            -0.010525168851017952,
            -0.0005300436168909073,
            0.02093643508851528,
            0.0013028105022385716,
            -0.0022979984059929848,
            -0.04433100298047066,
            0.017140457406640053,
            -0.007431747857481241,
            0.008067704737186432,
            0.013480855152010918,
            0.011975083500146866,
            0.02295118197798729,
            0.003411057870835066,
            -0.015269235707819462,
            -0.011659395880997181,
            0.04075479507446289,
            0.046580877155065536,
            -0.024772172793745995,
            -0.025171278044581413,
            0.01979437656700611,
            0.015790922567248344,
            -0.015124507248401642,
            0.02610005810856819,
            -0.002973957685753703,
            0.02824617549777031,
            0.02635684795677662,
            -0.026135388761758804,
            -0.009370643645524979,
            -0.004033397883176804,
            0.01652146689593792,
            -0.006009931210428476,
            0.047182753682136536,
            0.01615033484995365,
            0.0051027145236730576,
            -0.040772322565317154,
            -0.0017877770587801933,
            -0.0032111932523548603,
            0.02771853096783161,
            0.020391272380948067,
            -0.00754612497985363,
            -0.015213965438306332,
            0.037348486483097076,
            0.02978641167283058,
            0.016977114602923393,
            -0.0031275535002350807,
            -0.016760271042585373,
            -0.028005575761198997,
            -0.007085226476192474,
            0.02765761688351631,
            0.03577526658773422,
            -0.0003653650637716055,
            -0.0066951788030564785,
            0.0015059270663186908,
            -0.018782837316393852,
            -0.03241632506251335,
            -0.03322279453277588,
            0.01445028092712164,
            -0.0009026917978189886,
            0.010211745277047157,
            0.015338379889726639,
            -0.04848802089691162,
            0.062408097088336945,
            0.016559557989239693,
            0.017075393348932266,
            0.0153500409796834,
            0.00271281274035573,
            -0.008617100305855274,
            0.0014505180297419429,
            -0.011287174187600613,
            0.008094945922493935,
            -0.014865725301206112,
            0.0022026929073035717,
            0.007609860971570015,
            -0.020800646394491196,
            0.0103255994617939,
            0.007648145779967308,
            0.004658418241888285,
            -0.03536315634846687,
            0.031462185084819794,
            -0.019401755183935165,
            -0.03685100004076958,
            -0.039825472980737686,
            -0.029765885323286057,
            0.03789602592587471,
            -0.007429518736898899,
            0.01585952751338482,
            -0.0046067251823842525,
            0.019641203805804253,
            -0.02343091182410717,
            0.024481849744915962,
            0.00968062411993742,
            -0.009181761182844639,
            -0.009531231597065926,
            -0.020693542435765266,
            0.026610946282744408,
            0.026604602113366127,
            -0.016164135187864304,
            0.024613408371806145,
            0.00963213387876749,
            0.008880176581442356,
            0.020756084471940994,
            -0.008954484947025776,
            0.025755150243639946,
            0.00438885809853673,
            -0.017307443544268608,
            0.04376857355237007,
            0.0031647449359297752,
            -0.026713959872722626,
            0.015313207171857357,
            0.006319032981991768,
            -0.011722887866199017,
            -0.001338931848295033,
            -0.015273600816726685,
            -0.010058341547846794,
            0.01781936176121235,
            0.02357400208711624,
            -0.010624410584568977,
            0.027211353182792664,
            0.021309370175004005,
            0.02439342811703682,
            0.02119062840938568,
            0.011927035637199879,
            0.012583578005433083,
            0.012731792405247688,
            -0.015035795979201794,
            0.043937187641859055,
            0.01686679944396019,
            0.024978909641504288,
            0.024879425764083862,
            -0.01930285058915615,
            0.02898613177239895,
            -0.021054206416010857,
            0.021596482023596764,
            -0.018434254452586174,
            0.021165592595934868,
            0.025346295908093452,
            -0.012102526612579823,
            0.024889133870601654,
            -0.021650949493050575,
            -0.0033562886528670788,
            -0.013065826147794724,
            0.02427995391190052,
            0.015044859610497952,
            0.025679824873805046,
            0.011124764569103718,
            -0.010333891957998276,
            0.014313126914203167,
            -0.004724919330328703,
            0.00037705490831285715,
            -0.010569929145276546,
            -0.0003038517606910318,
            -0.02137972041964531,
            0.015683157369494438,
            0.03356863185763359,
            -0.03405093401670456,
            -0.0195015799254179,
            0.026090657338500023,
            0.013831418007612228,
            -0.0005954112857580185,
            -0.05089311674237251,
            -0.02532300353050232,
            -0.012999665923416615,
            0.030754132196307182,
            0.01997176744043827,
            0.003232206916436553,
            -0.005135153885930777,
            0.04087205231189728,
            0.0005729696131311357,
            -0.03514941781759262,
            -0.014848630875349045,
            -0.024983646348118782,
            -0.02409409172832966,
            -0.007453333120793104,
            0.006725752726197243,
            -0.02693156525492668,
            -0.02356315776705742,
            0.027674244716763496,
            -0.05296868830919266,
            -0.013094084337353706,
            -0.0247291661798954,
            -0.0005360667128115892,
            0.004487696569412947,
            -0.0013987026177346706,
            0.020520923659205437,
            -0.0034913714043796062,
            0.005306208040565252,
            -0.03340312838554382,
            0.001556027913466096,
            0.007701550610363483,
            -0.03159083053469658,
            -0.01103757880628109,
            0.014078281819820404,
            -0.020337972790002823,
            0.03467507287859917,
            -0.016813598573207855,
            0.02132774144411087,
            0.027398066595196724,
            -0.0039087627083063126,
            0.021372204646468163,
            0.03389344364404678,
            0.019746873527765274,
            0.020558848977088928,
            0.013866442255675793,
            -0.01446539256721735,
            0.027926448732614517,
            0.01103254221379757,
            -0.021801436319947243,
            -0.03393825888633728,
            -0.040165290236473083,
            -0.0074727414175868034,
            -0.0055081346072256565,
            0.0009302992257289588,
            -0.026239192113280296,
            -0.01595483347773552,
            -0.03600362688302994,
            0.008151215501129627,
            -0.027422942221164703,
            0.03874850273132324,
            -0.006461598910391331,
            -0.01480151992291212,
            -0.02157953754067421,
            0.0043395934626460075,
            0.054878879338502884,
            -0.007736606523394585,
            0.01662462018430233,
            0.0001202261628350243,
            -0.024479743093252182,
            -0.007944089360535145,
            -0.018862927332520485,
            0.03708123415708542,
            -0.0030072485096752644,
            0.03553687781095505,
            0.029987815767526627,
            -0.009731961414217949,
            0.008462714031338692,
            0.029527651146054268,
            0.012991179712116718,
            0.0064913248643279076,
            -0.05083484575152397,
            0.044222500175237656,
            -0.02638319320976734,
            0.01847023144364357,
            0.006988705135881901,
            -0.028393805027008057,
            -0.018906528130173683,
            0.036761801689863205,
            -0.003939255140721798,
            0.014994808472692966,
            0.022175192832946777,
            0.0030208879616111517,
            0.0008973379153758287,
            -0.015326767228543758,
            -0.01711479388177395,
            0.025133041664958,
            -0.011779853142797947,
            -0.007411355618387461,
            0.01858692429959774,
            -0.019954992458224297,
            0.007086826954036951,
            -0.003145921044051647,
            0.03037835843861103,
            -0.012017949484288692,
            -0.032854072749614716,
            -0.021984660997986794,
            -0.03165928274393082,
            -0.007159368600696325,
            0.005998310633003712,
            0.010093141347169876,
            0.019981952384114265,
            0.053836576640605927,
            -0.0026298933662474155,
            -0.02585921622812748,
            0.008164884522557259,
            0.002501298673450947,
            0.01672482304275036,
            0.03303833678364754,
            0.004058925434947014,
            0.029984135180711746,
            0.006787290330976248,
            -0.021689698100090027,
            0.004771941341459751,
            0.02644946798682213,
            0.03126060590147972,
            0.016261553391814232,
            -0.012740673497319221,
            -0.007081207819283009,
            -0.028535345569252968,
            -0.05976400151848793,
            0.032288625836372375,
            0.03470391035079956,
            -0.01782023347914219,
            0.0002640949678607285,
            0.01370831485837698,
            0.039395831525325775,
            0.016833210363984108,
            -0.03066094219684601,
            -0.020134571939706802,
            -0.029253806918859482,
            -0.010830605402588844,
            0.040176812559366226,
            0.03178516775369644,
            -0.02798156812787056,
            -0.005696181207895279,
            -0.00814498495310545,
            -0.03387138992547989,
            0.009743901900947094,
            0.037767816334962845,
            0.006976019125431776,
            -0.034573499113321304,
            0.00852133333683014,
            0.01829490065574646,
            0.05903816595673561,
            -0.0020157089456915855,
            0.042168449610471725,
            -0.02347496710717678,
            0.006306932307779789,
            0.006037640385329723,
            -0.023977288976311684,
            -0.01004584040492773,
            -0.030297258868813515,
            0.028645016252994537,
            -0.001981663051992655,
            -0.009629489853978157,
            0.0032819383777678013,
            0.016047872602939606,
            -0.024083901196718216,
            -0.015850940719246864,
            -0.014312545768916607,
            -0.014849148690700531,
            0.0062343766912817955,
            0.0049325814470648766,
            -0.008763983845710754,
            0.02026580087840557,
            0.015286611393094063,
            -0.014220455661416054,
            0.0002854290942195803,
            0.025791050866246223,
            0.00673725176602602,
            -0.018306914716959,
            0.006867405958473682,
            0.022455979138612747,
            0.038938455283641815,
            -0.014211343601346016,
            0.005108848214149475,
            -0.012445316649973392,
            0.025039032101631165,
            -0.005458380561321974,
            0.036038730293512344,
            0.008261642418801785,
            0.029013944789767265,
            0.0035505981650203466,
            0.012401321902871132,
            -0.027247192338109016,
            0.030936535447835922,
            -0.0036097608972340822,
            0.011123217642307281,
            0.00400913180783391,
            0.0055503882467746735,
            0.005991313140839338,
            0.010968358255922794,
            0.036575302481651306,
            0.022440213710069656,
            0.010241223499178886,
            -0.008700409904122353,
            -0.023698672652244568,
            0.028443943709135056,
            -0.02552258037030697,
            -0.03147061541676521,
            0.009435185231268406
        ],
        [
            -0.026467498391866684,
            0.051083821803331375,
            -0.027363363653421402,
            0.012452594935894012,
            -0.021453876048326492,
            0.017919955775141716,
            -0.017574461176991463,
            0.012997716665267944,
            0.026746876537799835,
            -0.040253378450870514,
            0.021954068914055824,
            -0.04310925677418709,
            -0.029429655522108078,
            0.043010685592889786,
            0.052686117589473724,
            -0.023233994841575623,
            0.010723220184445381,
            0.025089234113693237,
            -0.03507430478930473,
            0.01979599893093109,
            0.0051009454764425755,
            0.013129468075931072,
            0.03852231055498123,
            0.04884808510541916,
            -0.04672538861632347,
            0.008335169404745102,
            -0.007268516346812248,
            0.020944157615303993,
            0.009676352143287659,
            -0.04269149899482727,
            0.0042336974292993546,
            -0.01402283925563097,
            -0.012035469524562359,
            -0.016250737011432648,
            -0.03678515553474426,
            0.022007497027516365,
            -0.009017649106681347,
            0.018958957865834236,
            -0.005301680415868759,
            -0.04218218848109245,
            -0.018276715651154518,
            -0.012485567480325699,
            -0.005113143473863602,
            0.013177747838199139,
            -0.023092225193977356,
            -0.011961845681071281,
            -0.0015640431083738804,
            -0.011973122134804726,
            0.014764443971216679,
            0.04605653136968613,
            0.02720978856086731,
            -0.012524466961622238,
            -0.02972155250608921,
            0.02802639827132225,
            0.014104733243584633,
            -0.012944711372256279,
            0.00012522672477643937,
            0.01699076220393181,
            -0.0041439589112997055,
            0.01579795964062214,
            0.009618154726922512,
            0.022105766460299492,
            -0.04611930623650551,
            0.013949706219136715,
            0.009330939501523972,
            0.010128388181328773,
            0.007542328909039497,
            -0.03438166528940201,
            0.02869315631687641,
            -0.032336656004190445,
            0.010691922158002853,
            -0.06234272941946983,
            -0.009435915388166904,
            0.017764490097761154,
            -0.029669201001524925,
            0.07032736390829086,
            0.06074667349457741,
            0.029124293476343155,
            0.02125934138894081,
            0.0034319250844419003,
            0.02206851914525032,
            -0.027745528146624565,
            -0.016483895480632782,
            -0.03960150480270386,
            0.011074654757976532,
            0.03347352147102356,
            0.034556880593299866,
            0.04233100265264511,
            -0.022276712581515312,
            -0.03451329469680786,
            -0.004827259108424187,
            0.005332868080586195,
            -0.007659874856472015,
            -0.0015972394030541182,
            -0.006806600838899612,
            -0.005589991807937622,
            0.0067512220703065395,
            -0.010130703449249268,
            -0.024326590821146965,
            -0.024172067642211914,
            0.04142005741596222,
            -0.023558294400572777,
            -0.021341517567634583,
            0.0005527062457986176,
            0.013638436794281006,
            0.018872255459427834,
            -0.01453537866473198,
            -0.016101274639368057,
            0.017629047855734825,
            -0.015810934826731682,
            0.04069755971431732,
            -0.016304420307278633,
            0.00906082708388567,
            0.03979106619954109,
            -0.01258943323045969,
            -0.029949240386486053,
            -0.02068718709051609,
            -0.04514860734343529,
            -0.03409795090556145,
            -0.0015991072868928313,
            -0.03743548318743706,
            -0.011190598830580711,
            0.036800071597099304,
            -0.0519678108394146,
            0.030725065618753433,
            -0.03811187669634819,
            0.059308577328920364,
            -0.00798364169895649,
            0.013210360892117023,
            -0.009726648218929768,
            -0.004216644912958145,
            -0.02211928553879261,
            0.01743059605360031,
            0.013901310041546822,
            -0.03276849538087845,
            0.015014303848147392,
            0.012910484336316586,
            0.017384789884090424,
            -0.0016573615139350295,
            0.024104828014969826,
            -0.03647279739379883,
            0.023525796830654144,
            0.014378655701875687,
            -0.01719573885202408,
            0.013560030609369278,
            0.019558152183890343,
            0.011177942156791687,
            -0.017682909965515137,
            0.00558793731033802,
            -0.004386118147522211,
            -0.004578922875225544,
            -0.02135513164103031,
            -0.036186788231134415,
            -0.007412836421281099,
            -0.019116219133138657,
            0.022844715043902397,
            0.03355986252427101,
            0.005372920539230108,
            -0.007183692418038845,
            0.003051441628485918,
            0.00922570563852787,
            -0.013401316478848457,
            -0.015522129833698273,
            -0.04091503471136093,
            0.0021791697945445776,
            0.032829780131578445,
            -0.014009080827236176,
            0.0013192625483497977,
            0.015714284032583237,
            0.027741873636841774,
            0.0258199293166399,
            -0.03438936918973923,
            -0.009462826885282993,
            0.028991762548685074,
            -0.007508758455514908,
            -0.013803311623632908,
            -0.02723650261759758,
            0.03513549268245697,
            0.0028351766522973776,
            -0.014494230039417744,
            0.02387477643787861,
            -0.029920097440481186,
            -0.020840231329202652,
            0.04142541065812111,
            -0.004299023654311895,
            0.0180642269551754,
            0.033569470047950745,
            -0.007694497238844633,
            -0.019770318642258644,
            0.010233597829937935,
            -0.02270570769906044,
            -0.024044781923294067,
            -0.01615941897034645,
            -0.026498015969991684,
            -0.020078206434845924,
            0.024224525317549706,
            -0.004149865359067917,
            0.0002647599612828344,
            -0.03614773601293564,
            -0.005669524427503347,
            -0.0062311613000929356,
            -0.0002176733542000875,
            -0.0020956993103027344,
            0.003652169369161129,
            -0.03813926503062248,
            -0.029226884245872498,
            0.038675811141729355,
            0.0002449478779453784,
            0.026357505470514297,
            -0.005183859262615442,
            -0.022493477910757065,
            0.03541265055537224,
            -0.05279430001974106,
            -0.0020755452569574118,
            -0.014438324607908726,
            0.02220362424850464,
            -0.0023994268849492073,
            0.02325114607810974,
            -0.020808475092053413,
            -0.03813768923282623,
            -0.032900020480155945,
            0.015286089852452278,
            -0.020390186458826065,
            -0.021735914051532745,
            -0.026562079787254333,
            -0.04421601817011833,
            0.041884757578372955,
            -0.030168382450938225,
            0.019145799800753593,
            0.026258448138833046,
            -0.011171115562319756,
            0.006125768180936575,
            -0.01947769708931446,
            0.008916414342820644,
            0.009479598142206669,
            -0.006649218499660492,
            0.04678204655647278,
            0.00721895694732666,
            -0.012719713151454926,
            -0.031547922641038895,
            -0.010083467699587345,
            -0.029621047899127007,
            -0.039519935846328735,
            0.02723066136240959,
            -0.002301522996276617,
            -0.02866455726325512,
            0.04158972576260567,
            0.005013030022382736,
            -0.01945650950074196,
            0.027663275599479675,
            0.0036249421536922455,
            -0.004679130855947733,
            0.026440642774105072,
            0.0195377878844738,
            -0.00301197892986238,
            -0.007407117635011673,
            0.02329040691256523,
            -0.0027920075226575136,
            -0.015579916536808014,
            0.018874766305088997,
            0.023824984207749367,
            0.01931930147111416,
            0.004644460044801235,
            -0.009670021943747997,
            -0.03943295776844025,
            0.02319389209151268,
            0.02004113607108593,
            0.02754306048154831,
            -0.025134608149528503,
            0.02077188715338707,
            0.032424502074718475,
            -0.003381601767614484,
            -0.016646016389131546,
            -0.0031142868101596832,
            -0.00707639753818512,
            0.017624977976083755,
            0.027115195989608765,
            0.02785719931125641,
            -0.013498496264219284,
            0.01329771988093853,
            0.006194145418703556,
            -0.030803339555859566,
            -0.007441731635481119,
            -0.0024825206492096186,
            0.008877278305590153,
            0.014561468735337257,
            0.031256988644599915,
            0.03383089229464531,
            0.012332321144640446,
            0.0001501703663961962,
            -0.04612007737159729,
            0.01367996260523796,
            -0.013908740133047104,
            0.018439877778291702,
            -0.03030734322965145,
            0.016577506437897682,
            0.0159157607704401,
            -0.00996079295873642,
            0.03394689783453941,
            -0.007501200307160616,
            0.017015427350997925,
            -0.000886634283233434,
            0.0067418706603348255,
            0.030008411034941673,
            -0.016137775033712387,
            0.001822436461225152,
            0.005682824179530144,
            0.00911100022494793,
            0.009503580629825592,
            -0.0011227948125451803,
            0.0003979695029556751,
            0.018354395404458046,
            -0.022642450407147408,
            -0.0010761237936094403,
            0.006620440166443586,
            0.04118318483233452,
            -0.03645813837647438,
            -0.00876943115144968,
            -0.005183025263249874,
            0.014114605262875557,
            0.004366125911474228,
            -0.019171155989170074,
            -0.01839776337146759,
            0.045559100806713104,
            -0.01759660430252552,
            0.030957920476794243,
            0.044291239231824875,
            -0.03685874864459038,
            -0.0020790041889995337,
            0.01635187678039074,
            0.038320619612932205,
            0.04327738285064697,
            -0.02749042958021164,
            0.028273481875658035,
            -0.014143931679427624,
            -0.0038823464419692755,
            -0.005848494358360767,
            -0.010421543382108212,
            -0.025707663968205452,
            0.027499603107571602,
            0.016463320702314377,
            0.024220911785960197,
            -0.014942517504096031,
            -0.014041160233318806,
            -0.010111224837601185,
            0.048468973487615585,
            0.0029472883325070143,
            0.03150960057973862,
            -0.03227454051375389,
            0.001503231469541788,
            -0.008975707925856113,
            -0.04910594969987869,
            -0.062167130410671234,
            0.016447633504867554,
            -0.03295959532260895,
            0.014839552342891693,
            0.023135287687182426,
            0.03362641483545303,
            -0.030596710741519928,
            -0.0007215109071694314,
            0.03696209937334061,
            -0.03621537610888481,
            0.022331571206450462,
            0.02128789760172367,
            -0.0017131001222878695,
            0.00498084956780076,
            0.037542279809713364,
            0.03196682408452034,
            -0.022372733801603317,
            -0.018630847334861755,
            -0.04485497251152992,
            0.021949471905827522,
            -0.004124141298234463,
            0.018538717180490494,
            0.00296872784383595,
            -0.030356429517269135,
            -0.03668010234832764,
            -0.010527134872972965,
            0.05142262578010559,
            0.02486598864197731,
            0.027997009456157684,
            0.012724620290100574,
            -0.025382209569215775,
            -0.018946325406432152,
            0.011617693118751049,
            0.055428944528102875,
            -0.014367048628628254,
            0.022294782102108,
            -0.01049268152564764,
            0.0034354040399193764,
            0.0006623275694437325,
            0.016217483207583427,
            0.007150944788008928,
            0.008768250234425068,
            -0.01772061549127102,
            -0.0051205395720899105,
            -0.02212030068039894,
            -0.03521696850657463,
            -0.03357572853565216,
            0.017501836642622948,
            -0.01146001648157835,
            0.02306968905031681,
            0.023240329697728157,
            -0.010525873862206936,
            0.011606437154114246,
            0.018761850893497467,
            0.024525111541152,
            -0.03721219673752785,
            -0.01681182160973549,
            -0.008422945626080036,
            0.022881925106048584,
            0.036908894777297974,
            0.027614274993538857,
            0.01105330791324377,
            0.015235957689583302,
            0.03223799541592598,
            -0.04617084562778473,
            0.0037879510782659054,
            0.04111581668257713,
            -0.008882001973688602,
            0.06335192173719406,
            0.03201267495751381,
            -0.03517777472734451,
            0.022101212292909622,
            -0.002445730846375227,
            0.011465872637927532,
            0.006845163647085428,
            0.0023914824705570936,
            0.004257491324096918,
            0.021236171945929527,
            -0.0032608648762106895,
            -0.032438527792692184,
            -0.016658585518598557,
            0.020789146423339844,
            -0.020967500284314156,
            -0.009901771321892738,
            -0.010389535687863827,
            0.03456130996346474,
            -0.011395667679607868,
            -0.04818354547023773,
            -0.03349487483501434,
            -0.035184238106012344,
            0.014631829224526882,
            0.027847986668348312,
            0.007830606773495674,
            -0.026091229170560837,
            -0.03109857067465782,
            0.04071896895766258,
            0.004476936999708414,
            0.017245985567569733,
            -0.0037426019553095102,
            -0.0002457693044561893,
            0.00851623248308897,
            -0.017210356891155243,
            -0.026334505528211594,
            -0.0025413052644580603,
            0.031094349920749664,
            0.005791532341390848,
            0.002675091614946723,
            -0.012688137590885162,
            0.0012174188159406185,
            0.03124183788895607,
            0.017840739339590073,
            -0.010414853692054749,
            0.02373366989195347,
            0.02415548264980316,
            -0.004693618044257164,
            -0.01715320535004139,
            -0.00037441219319589436,
            -0.0038764909841120243,
            0.019481468945741653,
            -0.010988637804985046,
            0.032281700521707535,
            0.028477488085627556,
            -0.00423984881490469,
            0.013034936971962452,
            0.025674639269709587,
            -0.035086337476968765,
            -0.013240152969956398,
            0.001257722033187747,
            -0.02687590941786766,
            0.019143449142575264,
            -0.026435093954205513,
            -0.01652177795767784,
            0.01452195830643177,
            -0.03151874616742134,
            -0.006306906696408987,
            0.039332315325737,
            0.008061041124165058,
            0.017513560131192207,
            0.004084732849150896,
            0.016405325382947922,
            -0.014917068183422089,
            0.03017864190042019,
            -0.024156974628567696,
            0.0019986641127616167,
            0.045592423528432846,
            0.0017954184440895915,
            0.018947042524814606,
            0.012014124542474747,
            -0.03833425045013428,
            0.028667723760008812,
            0.009423200972378254,
            0.030745605006814003,
            0.02142619714140892,
            -0.02703997679054737,
            -0.03955405205488205,
            0.012409819290041924,
            -0.05306902900338173,
            0.017184685915708542,
            0.01758367009460926,
            0.01273568905889988,
            0.022013787180185318,
            0.027170730754733086,
            0.03568921610713005,
            0.01005389541387558,
            -0.012727933004498482,
            0.02174949273467064,
            0.03765685856342316,
            0.015595031902194023,
            -0.01878386363387108,
            -0.010381202213466167,
            0.005445062182843685,
            0.03481683135032654,
            -0.0016705901362001896,
            -0.012651502154767513,
            -0.0395752489566803,
            -0.02032635733485222,
            0.02562578208744526,
            0.03455512970685959,
            0.016120929270982742,
            0.027746520936489105,
            -0.030918240547180176,
            0.01929410733282566,
            -0.009961526840925217,
            -0.027998611330986023,
            0.010383606888353825,
            -0.006615781225264072,
            -0.022563526406884193,
            -0.02902512438595295,
            0.00019596614583861083,
            -0.020598309114575386,
            0.04481751471757889,
            -0.009444627910852432,
            -0.01011868566274643,
            -0.02512665092945099,
            -0.0046544247306883335,
            -0.007000979967415333,
            -0.01620868220925331,
            0.005123041104525328,
            -0.028925331309437752,
            0.005053872242569923,
            -0.0008467515581287444,
            -0.015737636014819145,
            0.02637632191181183,
            0.02456132136285305,
            0.030124332755804062,
            -0.00855633057653904,
            -0.030286544933915138,
            0.0005862443940714002,
            -0.016411591321229935,
            0.024098003283143044,
            0.008888835087418556,
            0.05833552032709122,
            0.035174306482076645,
            0.00969998724758625,
            0.003184147644788027,
            -0.01592297852039337,
            0.008859394118189812,
            -0.011476580984890461,
            -0.031827569007873535,
            -0.0021135318093001842,
            -0.01081331167370081,
            -0.029095157980918884,
            -0.026993900537490845,
            0.02150704152882099,
            0.0037431393284350634,
            0.032708629965782166,
            -0.03191715478897095,
            -0.02110249549150467,
            -0.009920225478708744,
            -0.0006381833227351308,
            0.014860662631690502,
            -0.016184581443667412,
            0.00781639851629734,
            -0.042058009654283524,
            0.016955191269516945,
            -0.005094015505164862,
            0.015702664852142334,
            -0.006810463033616543,
            -0.01252865232527256,
            -0.004877507220953703,
            -0.0059054759331047535,
            0.05178922787308693,
            -0.0071717011742293835,
            0.03126313164830208,
            -0.019787272438406944,
            -0.017076874151825905,
            -0.016141988337039948,
            -0.021410351619124413,
            -0.03787851333618164,
            -0.00814760196954012,
            0.00269605265930295,
            -0.0073348041623830795,
            0.009138689376413822,
            0.03398124873638153,
            0.001512069720774889,
            0.02793237566947937,
            0.03595433384180069,
            0.03547954931855202,
            0.051446545869112015,
            0.004779560957103968,
            0.006580794230103493,
            0.03265458717942238,
            0.030702698975801468,
            0.0035392045974731445,
            -0.011445224285125732,
            -0.006119523197412491,
            0.032802533358335495,
            -0.02920340560376644,
            0.035960737615823746,
            0.027017541229724884,
            0.004142876714468002,
            -0.009659970179200172,
            -0.011023358441889286,
            -0.014965582638978958,
            0.013201168738305569,
            -0.04637777805328369,
            0.010493224486708641,
            -0.02654130943119526,
            0.015380693599581718,
            0.03281938284635544,
            -0.02603982947766781,
            -0.012609927915036678,
            -0.027558377012610435,
            0.013289478607475758,
            0.04910891130566597,
            -0.006160748191177845,
            0.018138082697987556,
            -0.02538592927157879,
            -0.021837927401065826,
            0.07662174105644226,
            -0.015327926725149155,
            -0.006289857905358076,
            0.02069256827235222,
            0.0029815221205353737,
            -0.005778448190540075,
            -0.0395684652030468,
            -0.015395008958876133,
            0.009823491796851158,
            -0.002234151354059577,
            0.011526471935212612,
            0.03248881921172142,
            -0.003398985369130969,
            -0.009167127311229706,
            0.03615783154964447,
            -0.013666676357388496,
            -0.004851012025028467,
            -0.023218821734189987,
            -0.03186812251806259,
            -0.030528781935572624,
            -0.06286417692899704,
            -0.007988541387021542,
            -0.003709282260388136,
            -0.038883503526449203,
            0.006815425585955381,
            0.04691627249121666,
            0.03249982371926308,
            0.0018481967272236943,
            -0.014170821756124496,
            0.024755660444498062,
            -0.024670111015439034,
            0.02327699027955532,
            0.02393311634659767,
            -0.04495154321193695,
            0.004446877166628838,
            0.002223201794549823,
            0.00726888095960021,
            -0.006781929172575474,
            -0.029241977259516716,
            0.029645469039678574,
            -0.021891716867685318,
            0.023641163483262062,
            -0.020999716594815254,
            0.020733758807182312,
            -0.011926252394914627,
            -0.009873040951788425,
            0.023462656885385513,
            0.04016491025686264,
            0.037680450826883316,
            -0.039578456431627274,
            0.0064028725028038025,
            -0.02551809698343277,
            -0.04496346786618233,
            -0.025420166552066803,
            -0.005067428108304739,
            0.031123129650950432,
            -0.0540042445063591,
            0.021461930125951767,
            0.02331574633717537,
            -0.00025191425811499357,
            0.02750067412853241,
            -0.024942465126514435,
            0.004003606736660004,
            0.030032096430659294,
            -0.031209172680974007,
            0.0088212164118886,
            -0.01066247932612896,
            -0.0005442809779196978,
            0.005260496865957975,
            -0.023802515119314194,
            0.02815840393304825,
            0.05080361291766167,
            -0.001944882795214653,
            0.026146281510591507,
            0.014075596816837788,
            0.03832284361124039,
            0.019875628873705864,
            -0.030328307300806046,
            -0.007193522993475199,
            0.021953284740447998,
            -0.022262511774897575,
            -0.01511695422232151,
            -0.04072626307606697,
            -0.03180668130517006,
            0.019383033737540245,
            -0.031177012249827385,
            0.02230735681951046,
            -0.0019693707581609488,
            0.033233627676963806,
            0.0013573296600952744,
            -0.01625346951186657,
            -0.009965200908482075,
            -0.022941842675209045,
            0.010671226307749748,
            -0.02937968075275421,
            -0.02073829062283039,
            0.002449959749355912,
            0.045866549015045166,
            0.026316581293940544,
            0.006461738608777523,
            -0.02539438009262085,
            -0.04378359019756317,
            0.03871205076575279,
            -0.016191266477108,
            -0.025037039071321487,
            0.01088744681328535,
            -0.044724054634571075,
            -0.052009452134370804,
            -0.03638248145580292,
            0.025229668244719505,
            0.027519239112734795,
            -0.006186738144606352,
            -0.0011287968372926116,
            -0.027551673352718353,
            -0.0019726217724382877,
            0.01734946481883526,
            0.05460523068904877,
            0.011564531363546848,
            0.03567204996943474,
            0.01887865923345089,
            -0.06808193773031235,
            -0.025627994909882545,
            0.0006910639349371195,
            -0.01987389847636223,
            0.016530364751815796,
            -0.012705682776868343,
            -0.008868425153195858,
            -0.02468683384358883,
            0.029223263263702393,
            0.003775028046220541,
            -0.01848735846579075,
            -0.01037284079939127,
            -0.04256194457411766,
            0.010233551263809204,
            -0.04656487703323364,
            -0.012143594212830067,
            -0.01144340168684721,
            0.012385299429297447,
            -0.0062737613916397095,
            0.024408826604485512,
            0.001629541744478047,
            -0.02633986622095108,
            -0.008699283003807068,
            0.019662171602249146,
            0.020228704437613487,
            -0.043652355670928955,
            -0.07613354176282883,
            -0.01585855707526207,
            0.001669447054155171,
            -0.015267007052898407,
            0.01604638062417507,
            0.015088846907019615,
            0.015906019136309624,
            -0.037018805742263794,
            0.056869182735681534,
            -0.01735796593129635,
            -0.01821216382086277,
            0.025482971221208572,
            0.021139122545719147,
            0.013593687675893307,
            0.03955985605716705,
            -0.014776448719203472,
            0.016781771555542946,
            0.0025077711325138807,
            -0.010234718210995197,
            0.00891029741615057,
            0.03162868320941925,
            0.0364004448056221,
            -0.013239504769444466,
            0.009593833237886429,
            -0.0028598078060895205,
            0.013723351061344147,
            -0.0247635617852211,
            0.0367082878947258,
            -0.00664161704480648,
            -0.01672358438372612,
            -0.0261642187833786,
            0.008882288821041584,
            0.0011668723309412599,
            -0.007563289720565081,
            -0.012522288598120213,
            0.00019051482377108186,
            -0.017816780135035515,
            -0.037149470299482346,
            -0.02872367948293686,
            -0.025389669463038445,
            0.0029898432549089193,
            0.002642205683514476,
            0.00025710294721648097,
            0.023237960413098335,
            -0.04770492762327194,
            -0.03283384069800377,
            0.021733172237873077,
            0.04009537771344185,
            -0.042526982724666595,
            -0.0006503748591057956,
            -0.004061829298734665,
            0.006944751832634211,
            -0.043732937425374985,
            -0.01485078502446413,
            -0.009566465392708778,
            -0.019333474338054657,
            -0.04540672153234482,
            0.018126513808965683,
            -0.013846258632838726,
            0.0196695439517498,
            -0.02287752740085125,
            0.021042386069893837,
            -0.05340895056724548,
            -0.00580597436055541,
            0.01013279240578413,
            0.006683305371552706,
            -0.004861386958509684,
            -0.0026156201492995024,
            -0.014015334658324718,
            0.015455618500709534,
            0.011076699942350388,
            -0.004687725566327572,
            0.023963961750268936,
            0.009756412357091904,
            -7.535536860814318e-05,
            -0.0020189261995255947,
            0.003702646354213357,
            -0.06490673869848251,
            -0.038360219448804855,
            0.016017721965909004,
            0.045867279171943665,
            -0.03691686689853668,
            0.048925720155239105,
            0.019441625103354454,
            -0.02137436531484127,
            0.014531037770211697,
            -0.01161830686032772,
            0.03585996478796005,
            -0.04077275097370148,
            -0.014888833276927471,
            -0.058280836790800095,
            0.0062898434698581696,
            0.012420241720974445,
            -0.023150982335209846,
            -0.032723043113946915,
            0.031138107180595398,
            -0.02869221568107605,
            -0.02772466093301773,
            -0.029128296300768852,
            -0.009051172994077206,
            -0.02104642428457737,
            0.023580946028232574,
            -0.0020064974669367075,
            -0.0063209314830601215,
            0.007591125555336475,
            0.024605367332696915,
            0.03449799865484238,
            -0.008929001167416573,
            -0.0118919238448143,
            -0.02825809270143509,
            -0.003329122206196189,
            -0.02541450224816799,
            0.07502990961074829,
            0.0325460210442543,
            -0.017065580934286118,
            0.014160367660224438,
            0.004313426557928324,
            -0.032007597386837006,
            0.0389309786260128,
            0.004632866475731134,
            -0.04931541159749031,
            0.024733470752835274,
            -0.02681266888976097,
            0.023289278149604797,
            -0.022965777665376663,
            0.008569995872676373,
            -0.02285667508840561,
            0.022871220484375954,
            0.004859343636780977,
            0.006349276285618544,
            0.032860059291124344,
            -0.020005090162158012,
            -0.02437257394194603,
            0.007524285465478897,
            0.007026851177215576,
            0.021588457748293877,
            -0.023409603163599968,
            -0.022366704419255257,
            0.014332940801978111,
            -0.02197040617465973,
            0.024840986356139183,
            -0.036879975348711014,
            0.030535293743014336,
            0.035495586693286896,
            -0.05566909536719322,
            -0.010748520493507385,
            -0.06853475421667099,
            0.008119032718241215,
            0.01835249364376068,
            -0.034615397453308105,
            0.013839160092175007,
            -0.030505133792757988,
            -0.06121830269694328,
            -0.029544511809945107,
            -0.004538409877568483,
            -0.033941011875867844,
            -0.013835168443620205,
            -0.0036571375094354153,
            -0.003120826557278633,
            0.0006923031178303063,
            0.001622398616746068,
            0.03254050388932228,
            -0.023936256766319275,
            -0.04753613471984863,
            -0.03297025337815285,
            -0.029914291575551033,
            0.0037930666003376245,
            0.0409681536257267,
            -0.02767091803252697,
            -0.029268398880958557,
            -0.021860655397176743,
            0.009203014895319939,
            -0.009245616383850574,
            0.0021241570357233286,
            -0.003092785133048892,
            -0.014423257671296597,
            -0.02669914811849594,
            0.007372784428298473,
            -0.04607376828789711,
            -0.0341225229203701,
            0.012565501034259796,
            0.03221139684319496,
            -0.005469404626637697,
            0.015457089059054852,
            -0.019826920703053474,
            0.018366385251283646,
            -0.026059173047542572,
            0.03061855398118496,
            0.012817352078855038,
            0.08386939018964767,
            0.028030676767230034,
            0.01979750208556652,
            -0.017977410927414894,
            0.025399452075362206,
            0.02245020493865013,
            0.01644989103078842,
            0.011931825429201126,
            -0.007110842503607273,
            -0.0032649855129420757,
            -0.003941667266190052,
            -0.01400449313223362,
            -0.04236067086458206,
            0.0207980964332819,
            0.016209375113248825,
            -0.010032438673079014,
            -0.015077131800353527,
            -0.0305183045566082,
            -0.02964852564036846,
            -0.0130129624158144,
            -0.053949061781167984,
            -0.04705285653471947,
            0.031767915934324265,
            -0.014523868449032307,
            0.004850632976740599,
            0.025293054059147835,
            0.010026310570538044,
            0.0012222039513289928,
            0.016654500737786293,
            0.03290890157222748,
            -0.019994810223579407,
            0.010021280497312546,
            0.029965829104185104,
            -0.04757034033536911,
            -0.06844388693571091,
            -2.6638595954864286e-05,
            0.005470667500048876,
            0.0031601854134351015,
            -0.04649317264556885,
            -0.050515927374362946,
            0.016114024445414543,
            -0.006409213412553072,
            0.007619858253747225,
            -0.01985447108745575,
            0.05791870877146721,
            0.029085280373692513,
            0.04202396050095558,
            0.02788059040904045
        ],
        [
            -0.002177693648263812,
            -0.0016293487278744578,
            -0.043288737535476685,
            -0.01163835171610117,
            -0.0028932723216712475,
            -0.004120302386581898,
            0.00042620213935151696,
            0.02771911211311817,
            0.03350089117884636,
            -0.003446838352829218,
            0.06934313476085663,
            -0.0066795507445931435,
            0.029586797580122948,
            0.011890516616404057,
            0.08246089518070221,
            0.02525271847844124,
            -0.0778852254152298,
            -0.024501614272594452,
            -0.01682891882956028,
            0.010080477222800255,
            0.013790175318717957,
            0.005424263887107372,
            -0.061723336577415466,
            0.026792440563440323,
            0.07183239609003067,
            0.008763276040554047,
            0.012658867053687572,
            0.009502105414867401,
            -0.0004914536839351058,
            0.002345673507079482,
            -0.026241689920425415,
            -0.014401236549019814,
            -0.011282263323664665,
            0.005411958787590265,
            -0.009886747226119041,
            -0.029340548440814018,
            -0.015772497281432152,
            -0.0016701833810657263,
            -0.03572847694158554,
            -0.02455666847527027,
            0.060282621532678604,
            -0.019475286826491356,
            0.0125294029712677,
            -0.02904435247182846,
            0.04936736077070236,
            -0.009902195073664188,
            -0.014765729196369648,
            0.009117497131228447,
            0.03960762545466423,
            -0.001206562970764935,
            -0.0022426533978432417,
            0.03109326958656311,
            -0.020904602482914925,
            -0.0331970676779747,
            -0.036796268075704575,
            0.0014857222558930516,
            0.017882511019706726,
            -0.030520467087626457,
            0.03982742503285408,
            0.03382965549826622,
            0.013213233090937138,
            0.02191384695470333,
            -0.050723109394311905,
            0.021707793697714806,
            0.00018231158901471645,
            0.022006763145327568,
            0.053295236080884933,
            -0.0010595074854791164,
            0.010689128190279007,
            -0.005169386509805918,
            0.00813352596014738,
            -0.041882265359163284,
            -0.05469027906656265,
            -0.0008047231240198016,
            0.04189194366335869,
            -0.03768617659807205,
            0.029632261022925377,
            0.029192611575126648,
            0.04625958576798439,
            -0.008870418183505535,
            -0.0037556069437414408,
            -0.004771053791046143,
            -0.033031366765499115,
            -0.01841059699654579,
            -0.023796504363417625,
            0.022762667387723923,
            0.0020618692506104708,
            -0.006164393853396177,
            0.0012714378535747528,
            0.0116005539894104,
            -0.0006968594389036298,
            -0.004734501708298922,
            0.004982478450983763,
            0.02218874730169773,
            -0.013478600420057774,
            0.026975123211741447,
            0.067711241543293,
            0.017261989414691925,
            0.009366088546812534,
            -0.01005949079990387,
            -0.010469834320247173,
            0.012422716245055199,
            -0.00421477947384119,
            0.01294422335922718,
            -0.05677163600921631,
            -0.014557004906237125,
            -0.0554981492459774,
            -0.04027076065540314,
            -0.010468530468642712,
            -0.0060703204944729805,
            0.016871316358447075,
            0.006020770873874426,
            -0.004314947873353958,
            -0.013416832312941551,
            -0.10257468372583389,
            -0.032475732266902924,
            0.03593241423368454,
            0.008659935556352139,
            0.021766696125268936,
            0.015476809814572334,
            0.01217995211482048,
            0.020600084215402603,
            0.0554024912416935,
            -0.013402072712779045,
            -0.028289437294006348,
            -0.07026462256908417,
            0.013436475768685341,
            0.022842952981591225,
            0.028152653947472572,
            0.006948196794837713,
            0.012442613020539284,
            -0.016656506806612015,
            -0.0009651965810917318,
            -0.031418342143297195,
            0.016053494065999985,
            -0.02180291712284088,
            0.014481686986982822,
            -0.06880957633256912,
            0.02472827583551407,
            -0.007153939455747604,
            -0.02085029147565365,
            0.005685852840542793,
            -0.022079313173890114,
            0.008682848885655403,
            -0.006912820506840944,
            -0.025384923443198204,
            0.01626184582710266,
            0.0019506752723827958,
            -0.010620669461786747,
            -0.014710475690662861,
            -0.009970912709832191,
            0.012640615925192833,
            0.03499678149819374,
            0.012421226128935814,
            0.0024856128729879856,
            -0.012667447328567505,
            0.008903034962713718,
            -0.00895974412560463,
            -0.03844230994582176,
            -0.009848582558333874,
            -0.0031455408316105604,
            -0.013530274853110313,
            -0.02423975057899952,
            -0.03966950997710228,
            -0.006456349045038223,
            0.030553285032510757,
            -0.04402562975883484,
            -0.005470431875437498,
            -0.04504343122243881,
            -0.002311676274985075,
            0.021760212257504463,
            0.01624561846256256,
            -0.040565527975559235,
            0.006345256231725216,
            0.008686446584761143,
            0.03034386597573757,
            0.03256716951727867,
            -0.011477790772914886,
            0.0019530834397301078,
            0.010714256204664707,
            -0.034964002668857574,
            0.02911985106766224,
            -0.008481546305119991,
            -0.015563847497105598,
            -0.02094612829387188,
            0.016975941136479378,
            -0.0035887891426682472,
            0.020315030589699745,
            0.005079183727502823,
            0.002096682321280241,
            0.016696332022547722,
            0.05734897777438164,
            0.011735960841178894,
            0.025888042524456978,
            0.004091540351510048,
            -0.014576751738786697,
            0.017272375524044037,
            0.011503882706165314,
            0.004181955009698868,
            -0.04280126467347145,
            0.002393998671323061,
            0.00040437706047669053,
            0.03795429319143295,
            0.01627071015536785,
            -0.03995276242494583,
            0.01898280344903469,
            0.010298707522451878,
            0.005260182544589043,
            4.934926255373284e-05,
            -0.03924674913287163,
            0.028360584750771523,
            -0.028800543397665024,
            -0.00713907927274704,
            -0.010153032839298248,
            -0.005052454769611359,
            -0.0015645032981410623,
            0.013147038407623768,
            -0.0295949075371027,
            -0.01924283057451248,
            0.03610876947641373,
            -0.003100466215983033,
            0.010520766489207745,
            -0.008456443436443806,
            -0.014111397787928581,
            0.013769343495368958,
            -0.0007492501754313707,
            -0.03514112904667854,
            0.014854799956083298,
            -0.015995033085346222,
            -0.017309609800577164,
            0.005186136346310377,
            0.0007214436773210764,
            -0.001969125820323825,
            -0.02989731915295124,
            0.003211025148630142,
            -0.04383948817849159,
            0.0356387160718441,
            -0.010330223478376865,
            -0.030104633420705795,
            -0.022427968680858612,
            0.04676078259944916,
            0.016298726201057434,
            -0.024555442854762077,
            -0.013263878412544727,
            0.02192671224474907,
            -0.0012831828789785504,
            0.03468267247080803,
            0.006299149245023727,
            -0.033368829637765884,
            0.026213448494672775,
            -0.030221449211239815,
            -0.007787989918142557,
            -0.008579145185649395,
            -0.03331739455461502,
            -0.024846266955137253,
            0.0269005186855793,
            0.030286893248558044,
            -3.848576307063922e-05,
            -0.03396512567996979,
            -0.03856724873185158,
            -0.035476669669151306,
            0.03600184991955757,
            -0.030653275549411774,
            -0.010815034620463848,
            -0.005474232602864504,
            -0.05466650798916817,
            -0.03498517721891403,
            -0.02956433780491352,
            0.030285106971859932,
            0.02551017515361309,
            0.002558361040428281,
            -0.015836212784051895,
            -0.024020975455641747,
            0.023728864267468452,
            -0.0024323624093085527,
            0.00693631824105978,
            0.022454798221588135,
            -0.03969325125217438,
            -0.0016887554666027427,
            -0.013262332417070866,
            -0.04298592358827591,
            -0.04659024626016617,
            0.028502540662884712,
            -0.005577113013714552,
            -0.010793802328407764,
            -0.018725497648119926,
            -0.015234126709401608,
            -0.014009403064846992,
            -0.009635337628424168,
            0.03539985790848732,
            0.004863249137997627,
            -0.023690342903137207,
            0.013809372670948505,
            -0.02275843545794487,
            0.039508506655693054,
            0.006234830245375633,
            -0.005602589808404446,
            -0.06474687159061432,
            -0.0555582121014595,
            0.0036347880959510803,
            0.03154802694916725,
            0.0005344093078747392,
            0.023960718885064125,
            0.044001899659633636,
            -0.019875720143318176,
            0.034289196133613586,
            -0.004101706203073263,
            -0.004103002604097128,
            0.00604925537481904,
            0.02136111445724964,
            -0.02193981036543846,
            -0.026887524873018265,
            -0.03177081421017647,
            -0.006852149032056332,
            0.009464545175433159,
            -0.003575206734240055,
            0.006945725530385971,
            -0.028372207656502724,
            0.03964396193623543,
            0.020406855270266533,
            0.013526052236557007,
            -0.06564714014530182,
            -0.03363129496574402,
            0.009282073006033897,
            -0.00787342619150877,
            -0.02338048256933689,
            0.029762573540210724,
            -0.0028583919629454613,
            0.0024952758103609085,
            -0.015752427279949188,
            -0.03554335609078407,
            0.005968596786260605,
            0.01651395671069622,
            0.02660704404115677,
            0.01848815567791462,
            0.03615421801805496,
            0.00429331511259079,
            -0.040254659950733185,
            0.0063075595535337925,
            -0.013720664195716381,
            0.013644706457853317,
            0.04878276586532593,
            -0.015862440690398216,
            -0.022824401035904884,
            0.012226251885294914,
            -0.015543711371719837,
            0.011994138360023499,
            0.004499354865401983,
            -0.02859628014266491,
            -0.01018003560602665,
            -0.006084138061851263,
            -0.009919038973748684,
            -0.014474029652774334,
            0.012885186821222305,
            -0.029763294383883476,
            -0.0037649061996489763,
            0.009037471376359463,
            0.005742834415286779,
            -0.029206274077296257,
            -0.020508144050836563,
            -0.0044855885207653046,
            -0.032664112746715546,
            0.003114077728241682,
            0.001287366496399045,
            -0.03215240687131882,
            0.02534675970673561,
            0.018499840050935745,
            -0.007997533306479454,
            0.0003373136860318482,
            -0.03415181115269661,
            0.0001399106258759275,
            0.005661976058036089,
            0.009369755163788795,
            0.031328245997428894,
            -0.03744368627667427,
            0.0013929291162639856,
            -0.014381189830601215,
            -0.027632910758256912,
            -0.02020532265305519,
            -0.030296029523015022,
            -0.09210352599620819,
            -0.0367429256439209,
            -0.021876761689782143,
            -0.015073179267346859,
            0.0033883722499012947,
            0.013729614205658436,
            0.019028283655643463,
            0.008263836614787579,
            -0.030105944722890854,
            0.0412827767431736,
            -0.0175384022295475,
            0.005277115851640701,
            0.020289307460188866,
            0.01307853776961565,
            0.03171783313155174,
            0.004017975647002459,
            0.007281564176082611,
            -0.01917095109820366,
            0.023004353046417236,
            0.019666675478219986,
            -0.013983109965920448,
            0.011001046746969223,
            -0.042028576135635376,
            -0.017121491953730583,
            -0.0248236283659935,
            -0.016427477821707726,
            -0.022688308730721474,
            0.01787572167813778,
            -0.01808701828122139,
            0.017381584271788597,
            0.01808682642877102,
            0.020893309265375137,
            -0.01878618821501732,
            -0.0811920315027237,
            0.02923637256026268,
            0.00395564129576087,
            0.01874433271586895,
            -0.057197343558073044,
            0.049049485474824905,
            0.00223638117313385,
            -0.02416417747735977,
            0.00593750923871994,
            0.05075464025139809,
            -0.02208120748400688,
            -0.05709206312894821,
            -0.0018280076328665018,
            -0.008705979213118553,
            0.07437437772750854,
            0.024417003616690636,
            -0.004912794101983309,
            0.020717190578579903,
            -0.0010929808486253023,
            0.0017197986599057913,
            0.004723163787275553,
            0.026685254648327827,
            0.0005820810329169035,
            -0.0015697125345468521,
            0.01182074099779129,
            0.04122938960790634,
            -0.012487118132412434,
            0.04148920252919197,
            -0.019343126565217972,
            0.05011046305298805,
            -0.004799613729119301,
            -0.011937087401747704,
            0.005227867513895035,
            -0.031222902238368988,
            -0.034059759229421616,
            -0.013133344240486622,
            -0.004435532260686159,
            0.030572719871997833,
            -0.023236094042658806,
            0.017996059730648994,
            -0.02878129668533802,
            0.00602963799610734,
            -0.025966033339500427,
            -0.0012154928408563137,
            0.023701224476099014,
            -0.041489068418741226,
            0.0042493329383432865,
            0.027004921808838844,
            0.03332061320543289,
            -0.017102299258112907,
            0.023043759167194366,
            0.023084457963705063,
            0.02657845802605152,
            0.035133883357048035,
            -0.019238747656345367,
            -0.006978398188948631,
            -0.005696360487490892,
            0.01952492445707321,
            -0.0257559884339571,
            0.013138322159647942,
            -0.008159676566720009,
            0.004431117791682482,
            -0.04069780185818672,
            -0.01301395334303379,
            -0.052489232271909714,
            -0.01266859844326973,
            0.021479489281773567,
            0.008402487263083458,
            -0.002770406426861882,
            -0.034616000950336456,
            0.01664952002465725,
            -0.0356731079518795,
            -0.002110301051288843,
            -0.00671811169013381,
            0.005114666186273098,
            0.0487082414329052,
            0.010632225312292576,
            0.008589036762714386,
            0.02561076544225216,
            0.016551576554775238,
            0.007589907851070166,
            0.0009941556490957737,
            -0.03815499320626259,
            -0.05414767935872078,
            -0.01976943574845791,
            -0.0015876301331445575,
            -0.035349708050489426,
            -0.0050256336107850075,
            -0.019576288759708405,
            -0.0165685024112463,
            0.05287753790616989,
            0.008703372441232204,
            0.025341065600514412,
            0.005544981453567743,
            0.03050321154296398,
            -0.0017504224088042974,
            -0.05688294395804405,
            -0.0072451443411409855,
            -0.006868311669677496,
            -0.03635667636990547,
            0.009931424632668495,
            0.0072435177862644196,
            -0.013013642281293869,
            0.03367246687412262,
            0.02268533781170845,
            -0.02505246363580227,
            -0.050501223653554916,
            -0.031344566494226456,
            0.006636237259954214,
            -0.0036858604289591312,
            -0.023530542850494385,
            0.0042557986453175545,
            0.005723197013139725,
            -0.009832490235567093,
            -0.0017644667532294989,
            -0.005091267172247171,
            0.030449049547314644,
            -0.026346653699874878,
            -0.014767806977033615,
            -0.01309372577816248,
            0.0024506966583430767,
            -0.024202153086662292,
            -0.001388922450132668,
            -0.025508668273687363,
            0.012251154519617558,
            0.04879218339920044,
            0.02337578497827053,
            -0.019835714250802994,
            0.027134422212839127,
            -0.02091594599187374,
            -0.01651984639465809,
            -0.025670310482382774,
            0.008871985599398613,
            -0.02680494636297226,
            0.0392969585955143,
            -0.045735858380794525,
            -0.009843602776527405,
            -0.019161179661750793,
            -0.03254278004169464,
            0.024274704977869987,
            0.016999734565615654,
            0.0036598138976842165,
            0.003446230897679925,
            0.027066262438893318,
            0.00711176497861743,
            -0.0091349883005023,
            0.02040506713092327,
            -0.004729586653411388,
            0.005108368117362261,
            -0.0033232439309358597,
            0.010074998252093792,
            -0.04505966603755951,
            0.015740886330604553,
            0.015015758574008942,
            0.019734874367713928,
            -0.016392875462770462,
            -0.011706344783306122,
            -0.0003212353913113475,
            -0.018638884648680687,
            0.0030895289964973927,
            0.014303906820714474,
            -0.027856256812810898,
            0.023652074858546257,
            0.05054859817028046,
            -0.025279562920331955,
            -0.059106405824422836,
            -0.015568534843623638,
            0.004759376402944326,
            0.0067222206853330135,
            -0.002914039883762598,
            -0.0123295234516263,
            -0.02568059228360653,
            -0.025821244344115257,
            -0.048104092478752136,
            -0.008427808061242104,
            -0.00877972412854433,
            -0.02044404111802578,
            0.0019107897533103824,
            0.02281283773481846,
            -0.036513522267341614,
            -0.08790494501590729,
            0.003069875529035926,
            0.029860904440283775,
            -0.006192107684910297,
            -0.026578152552247047,
            -0.02603020705282688,
            -0.015663057565689087,
            -0.005220027174800634,
            -0.025631584227085114,
            -0.04995885118842125,
            -0.026212068274617195,
            -0.0026328356470912695,
            0.026498164981603622,
            -0.01636008732020855,
            -0.0022438117302954197,
            -0.03181399777531624,
            0.043543849140405655,
            -0.035216376185417175,
            -0.012336143292486668,
            0.020153887569904327,
            0.006524935364723206,
            -0.000573845871258527,
            0.02655605971813202,
            -0.012913736514747143,
            0.025100473314523697,
            0.008435221388936043,
            -0.013442724011838436,
            0.051017530262470245,
            0.043697621673345566,
            -0.02960832417011261,
            -0.008424101397395134,
            -0.017381612211465836,
            0.03225290775299072,
            -0.0002460245741531253,
            0.03887736424803734,
            0.004933715797960758,
            -0.009232808835804462,
            -0.02769974060356617,
            0.026552798226475716,
            -0.022089818492531776,
            -0.0009479675209149718,
            0.024787265807390213,
            -0.025649145245552063,
            0.0006289210286922753,
            0.0031358490232378244,
            -0.05129612237215042,
            0.008398217149078846,
            0.0447411984205246,
            0.07121004909276962,
            0.03075018897652626,
            -0.044830769300460815,
            -0.0030269904527813196,
            -0.046892199665308,
            0.006084720138460398,
            -0.008359422907233238,
            -0.012380165047943592,
            0.030384639278054237,
            0.03438481688499451,
            -0.0037670712918043137,
            -0.008776766248047352,
            -0.018744150176644325,
            0.013431714847683907,
            0.020803924649953842,
            -0.0045683132484555244,
            -0.0077777705155313015,
            0.022207967936992645,
            0.03368360921740532,
            -0.012930023483932018,
            0.00011085243022534996,
            0.01537961233407259,
            1.7594113160157576e-05,
            -0.03411344811320305,
            0.020154431462287903,
            -0.028707772493362427,
            0.0017134294612333179,
            -0.0035834447480738163,
            0.023835493251681328,
            -0.03905915096402168,
            0.020363925024867058,
            -0.02578887715935707,
            -0.023220036178827286,
            0.024083636701107025,
            0.023677704855799675,
            -0.036861665546894073,
            0.007441358175128698,
            0.016873374581336975,
            0.027377482503652573,
            -0.05161958932876587,
            -0.007108256220817566,
            0.006484284996986389,
            -0.005644567310810089,
            -0.0023306498769670725,
            -0.016495047137141228,
            0.01658850908279419,
            -0.07326318323612213,
            -0.0375354029238224,
            -0.0049719493836164474,
            -0.036127254366874695,
            -0.03331620991230011,
            0.00280853477306664,
            -0.0174186360090971,
            -0.025716448202729225,
            0.03061974048614502,
            -0.02047594077885151,
            -0.02917187102138996,
            -0.02462947741150856,
            -0.0362313948571682,
            -0.0016804876504465938,
            0.023314744234085083,
            0.020409870892763138,
            -0.004511468578130007,
            -0.042285673320293427,
            0.031900350004434586,
            0.005577720236033201,
            0.01672162301838398,
            0.01762964203953743,
            -0.011458204127848148,
            0.005872227251529694,
            -0.021215219050645828,
            -0.022304542362689972,
            -0.0156785249710083,
            -0.01544081512838602,
            0.01728782244026661,
            -0.054677706211805344,
            0.04178217798471451,
            0.006457510404288769,
            -0.014064758084714413,
            -0.012103985995054245,
            0.012409809045493603,
            -0.02988300658762455,
            -0.026588894426822662,
            -0.021324967965483665,
            0.003784174332395196,
            0.007492549251765013,
            0.030113715678453445,
            -0.039229266345500946,
            0.025030717253684998,
            0.028302829712629318,
            -0.012752640061080456,
            -0.02294987440109253,
            -0.021078776568174362,
            0.026035411283373833,
            -0.003919997718185186,
            0.015888145193457603,
            0.013807221315801144,
            -0.030471421778202057,
            -0.024347921833395958,
            0.03544692322611809,
            0.01806076616048813,
            -0.016132142394781113,
            -0.031103268265724182,
            0.003015591064468026,
            -0.0062480708584189415,
            -0.06330100446939468,
            -0.08097323775291443,
            0.039698801934719086,
            -0.024929160252213478,
            -0.003213882679119706,
            0.017260730266571045,
            -0.03174588456749916,
            0.02149052359163761,
            0.011209757998585701,
            -0.008208741433918476,
            0.029571186751127243,
            -0.0359884537756443,
            -0.03582102432847023,
            -0.0020291355904191732,
            0.02673545852303505,
            -0.06576036661863327,
            0.007427442353218794,
            -0.008769828826189041,
            0.027722682803869247,
            0.006773732136934996,
            -0.03246457502245903,
            -0.004492591600865126,
            -0.004131336696445942,
            -0.00029503009864129126,
            0.01614893041551113,
            0.046193547546863556,
            -0.06884755194187164,
            -0.08805391192436218,
            -0.027635913342237473,
            -0.019600268453359604,
            -0.01224750466644764,
            0.05684413015842438,
            -0.026305533945560455,
            0.011917084455490112,
            -0.0383189395070076,
            -0.007086395286023617,
            -0.003423549234867096,
            0.052659958600997925,
            -0.004110070876777172,
            -0.00785361509770155,
            0.031465958803892136,
            -0.024030517786741257,
            -0.005529731512069702,
            -0.033324193209409714,
            0.004298255778849125,
            0.009655309841036797,
            -0.06990630179643631,
            0.020679567009210587,
            -0.0015677808551117778,
            0.0045719873160123825,
            0.02941110171377659,
            -0.005595495458692312,
            -0.03282075747847557,
            -0.005885124672204256,
            0.030752113088965416,
            -0.06947360187768936,
            0.01528211310505867,
            -0.021829141303896904,
            0.015907613560557365,
            0.0015702084638178349,
            -0.03948909789323807,
            -0.03705925494432449,
            0.011910108849406242,
            0.033807605504989624,
            0.05737578123807907,
            0.02236606739461422,
            0.0016321175498887897,
            0.015862779691815376,
            -0.04221189022064209,
            -0.03509087115526199,
            -0.016990164294838905,
            0.045460525900125504,
            0.01685989461839199,
            -0.027475198730826378,
            0.011268946342170238,
            0.01983300782740116,
            -0.017794817686080933,
            -0.01809881627559662,
            0.062135424464941025,
            0.03511113300919533,
            0.02943762019276619,
            0.0055930535309016705,
            0.018485218286514282,
            -0.023793330416083336,
            -0.03128514811396599,
            -0.006540174130350351,
            0.0016319999704137444,
            -0.023901240900158882,
            0.027827786281704903,
            -0.032106056809425354,
            0.00027023887378163636,
            -0.008192852139472961,
            -0.016639424487948418,
            0.002843826310709119,
            -0.046898528933525085,
            0.005074193235486746,
            -0.025746451690793037,
            -0.037935126572847366,
            0.0317426398396492,
            0.025362364947795868,
            0.021963411942124367,
            -0.008128061890602112,
            -0.004943172447383404,
            -0.01906277798116207,
            -0.009110326878726482,
            -0.004765348043292761,
            -0.0077985976822674274,
            -0.0026338205207139254,
            0.005407174117863178,
            -0.007059628143906593,
            -0.010637685656547546,
            -0.021301181986927986,
            -0.006720933131873608,
            -0.0005470215110108256,
            0.03916200250387192,
            -0.011110388673841953,
            -0.03485507518053055,
            0.023835375905036926,
            0.0541105791926384,
            0.021593313664197922,
            0.007399889640510082,
            0.008862996473908424,
            -0.010744965635240078,
            -0.019420720636844635,
            -0.04899090528488159,
            0.01564694195985794,
            0.045280445367097855,
            0.009137328714132309,
            0.035159192979335785,
            -0.05328906700015068,
            -0.031096266582608223,
            0.0809134691953659,
            0.022478802129626274,
            0.03137650713324547,
            -0.04322260245680809,
            0.009027549996972084,
            -0.05014696344733238,
            0.009654445573687553,
            0.004883728455752134,
            0.013505768962204456,
            -0.0007376887951977551,
            -0.009413265623152256,
            -0.023630771785974503,
            0.01961798034608364,
            0.0005154779646545649,
            -0.01862829364836216,
            -0.0006336391088552773,
            -0.028125464916229248,
            -0.01917181722819805,
            -0.013083103112876415,
            -0.01236451230943203,
            -0.03993730619549751,
            -0.052574533969163895,
            0.004128482658416033,
            -0.02348492480814457,
            0.030548546463251114,
            0.009584187529981136,
            0.05466809868812561,
            0.012882878072559834,
            -0.042851585894823074,
            -0.021144984290003777,
            -0.0186562929302454,
            -0.020411832258105278,
            -0.0034771321807056665,
            -0.02260785549879074,
            0.014266952872276306,
            0.026209287345409393,
            -0.020925583317875862,
            -0.011604949831962585,
            0.01814449205994606,
            0.0030924107413738966,
            0.006819078233093023,
            0.021768417209386826,
            -0.011172877624630928,
            -0.007720369845628738,
            0.009756242856383324,
            0.016036847606301308,
            -0.024025363847613335,
            -0.039758022874593735,
            0.0279096607118845,
            -0.009203260764479637,
            0.0836174339056015,
            0.014822825789451599,
            0.005054001230746508,
            0.0001562580728204921,
            -0.011807485483586788,
            -0.06637994945049286,
            -0.00029344073846004903,
            0.033141400665044785,
            -0.015675224363803864,
            0.015305190347135067,
            0.031112831085920334,
            -0.026923205703496933,
            0.02845301479101181,
            0.022945227101445198,
            0.019946899265050888,
            0.03475426137447357,
            -0.012068518437445164,
            0.012219928205013275,
            0.02404147945344448,
            0.002659901510924101,
            -0.028648318722844124,
            0.031289760023355484,
            0.0055138422176241875,
            -0.03237723559141159,
            0.018561476841568947,
            0.011517932638525963,
            -0.03921262174844742,
            -0.02106727845966816,
            0.0016053252620622516,
            0.005219873506575823,
            0.0028545779641717672,
            -0.004000640008598566,
            0.029808497056365013,
            0.025601522997021675,
            -0.03069174475967884,
            0.02667413279414177,
            0.028778526932001114,
            0.023301292210817337,
            0.034997690469026566,
            0.02283652499318123,
            -0.03256874158978462,
            -0.01228185836225748,
            0.019971176981925964,
            -0.014937196858227253,
            0.02913358062505722,
            -0.03931139037013054,
            -0.03748997673392296,
            0.033441442996263504,
            0.02160022407770157,
            0.0073774135671556,
            -0.019423391669988632,
            0.013587888330221176,
            -0.023256702348589897,
            -0.008518372662365437,
            0.027719566598534584,
            -0.015288280323147774,
            0.014059828594326973,
            0.006346044130623341,
            0.019068287685513496,
            0.008621759712696075,
            0.018115198239684105,
            0.035224802792072296,
            -0.0021106237545609474,
            0.028361525386571884,
            0.02522069588303566,
            -0.019149962812662125,
            -0.006418024655431509,
            0.028356701135635376,
            -0.037822920829057693,
            0.04840908572077751,
            0.01472084317356348,
            -0.049943018704652786,
            -0.0065304008312523365,
            -0.022475404664874077,
            0.00960598886013031,
            -0.017157552763819695,
            0.01737152598798275,
            -0.007298023905605078,
            0.015247499570250511,
            0.0037562178913503885,
            -0.004656769335269928,
            -0.016872165724635124,
            0.008620590902864933,
            0.01609763689339161,
            0.021194634959101677,
            -0.005123212933540344,
            -0.012866045348346233,
            0.019895700737833977,
            0.02796350046992302,
            0.015152075327932835,
            0.008425588719546795,
            0.0012148777022957802,
            -0.041301701217889786,
            0.00185617886018008,
            -0.042281001806259155,
            0.054066941142082214,
            -0.022089609876275063
        ],
        [
            0.01434686966240406,
            0.006914377678185701,
            -0.021961480379104614,
            -0.04690392687916756,
            -0.018915900960564613,
            0.01104216929525137,
            -0.009878224693238735,
            0.01505440752953291,
            -0.025317320600152016,
            0.0009841474238783121,
            0.025580396875739098,
            0.006488469894975424,
            -0.01855849102139473,
            -0.04468519613146782,
            -0.03974775969982147,
            0.006997521501034498,
            0.026930958032608032,
            0.015551043674349785,
            -0.003178916871547699,
            0.02390482649207115,
            0.03469868376851082,
            0.0018086765194311738,
            -0.035888995975255966,
            -0.04980645328760147,
            -0.07421137392520905,
            -0.048942092806100845,
            0.03987303003668785,
            0.004466205835342407,
            0.036662399768829346,
            0.03583245351910591,
            0.02683807909488678,
            0.010423727333545685,
            -0.025432463735342026,
            -0.0039057419635355473,
            0.005904818885028362,
            -0.06911817938089371,
            0.018064340576529503,
            0.013206830248236656,
            -0.015393625944852829,
            0.003917731810361147,
            0.002143284073099494,
            -0.0419427789747715,
            0.04032977670431137,
            0.012994379736483097,
            -0.023417459800839424,
            -0.008031118661165237,
            0.0404420867562294,
            -0.04339137300848961,
            -0.022090425714850426,
            0.06794160604476929,
            0.03983883932232857,
            0.01403903029859066,
            0.04071415588259697,
            0.02957194112241268,
            0.043118592351675034,
            -0.05847412720322609,
            0.006893084850162268,
            0.01147918589413166,
            -0.02579970471560955,
            0.02631823904812336,
            0.01101353857666254,
            -0.022846175357699394,
            0.005155323073267937,
            -0.00337006663903594,
            0.022348511964082718,
            0.006319307256489992,
            -0.010209689848124981,
            -0.027550483122467995,
            0.02446041814982891,
            0.000330615061102435,
            0.01516534574329853,
            0.005792590323835611,
            -0.04092152416706085,
            -0.004488891921937466,
            -0.004082359839230776,
            -0.004348869435489178,
            0.02874305099248886,
            -0.005551469512283802,
            0.003467341186478734,
            0.009022034704685211,
            -0.011487394571304321,
            0.04335394501686096,
            -0.031047694385051727,
            0.010389699600636959,
            -0.031860921531915665,
            0.01988855004310608,
            0.03501862660050392,
            0.01873093470931053,
            -0.0024538435973227024,
            0.06157933548092842,
            -0.02611001394689083,
            -0.006378184538334608,
            -0.004987799096852541,
            -0.02557685784995556,
            -0.017834791913628578,
            -0.011650092899799347,
            -0.008180409669876099,
            -0.007750311866402626,
            0.03657279908657074,
            -0.02453736588358879,
            -0.018249070271849632,
            -0.03163482993841171,
            0.03195246309041977,
            0.02554772049188614,
            -0.002915251301601529,
            0.02322969026863575,
            0.0304607804864645,
            -0.00738135538995266,
            -0.02245469018816948,
            0.002476280089467764,
            -0.0016106974799185991,
            0.01784294657409191,
            0.025196431204676628,
            -0.0007173279882408679,
            0.060475531965494156,
            -0.023417631164193153,
            -0.0054682232439517975,
            0.004911807365715504,
            -0.03956862539052963,
            -0.026238134130835533,
            0.0023526411969214678,
            -0.0027842025738209486,
            0.033875081688165665,
            -0.013491573743522167,
            0.01796313002705574,
            -0.005907317157834768,
            -0.031768083572387695,
            -0.019511181861162186,
            -0.015983296558260918,
            0.02275199070572853,
            0.02144305221736431,
            -0.007410009391605854,
            0.001978536369279027,
            0.02529294602572918,
            0.018289584666490555,
            0.022255897521972656,
            0.0014155703829601407,
            -0.00477571552619338,
            0.016153957694768906,
            0.038201723247766495,
            -0.03340649604797363,
            -0.01378476619720459,
            -0.03881043195724487,
            -0.016398068517446518,
            0.05276675149798393,
            0.014806492254137993,
            -0.011262491345405579,
            0.020644987002015114,
            0.029409324750304222,
            -0.02506168745458126,
            -0.0400339812040329,
            0.006592253688722849,
            -0.023004602640867233,
            -0.01728684827685356,
            0.03379553556442261,
            0.012216406874358654,
            -0.0004136173229198903,
            0.023018954321742058,
            -0.024980612099170685,
            -0.012469145469367504,
            0.014085862785577774,
            0.00509321503341198,
            0.025679191574454308,
            0.006923330016434193,
            -0.006192771717905998,
            -0.0038249839562922716,
            0.029987242072820663,
            0.022847339510917664,
            -0.05319403484463692,
            0.017128750681877136,
            -0.014171026647090912,
            -0.06672298163175583,
            0.03707599639892578,
            -0.03357549384236336,
            0.04920526593923569,
            -0.03439059853553772,
            0.04850436747074127,
            -0.0034585846588015556,
            4.8348687414545566e-05,
            0.03011038340628147,
            -0.0017443931428715587,
            0.029866468161344528,
            0.05937926098704338,
            0.019598018378019333,
            -0.026627816259860992,
            0.014632447622716427,
            0.001991827506572008,
            -0.019156621769070625,
            0.01853816956281662,
            0.02487998642027378,
            -0.004257029388099909,
            -0.03496851772069931,
            0.06331528723239899,
            0.01231903862208128,
            -0.030115438625216484,
            0.006453218869864941,
            -0.03342709317803383,
            0.012316921725869179,
            -0.0005635456182062626,
            0.014802759513258934,
            0.002443303819745779,
            0.010646561160683632,
            -0.0014141862047836185,
            -0.024006271734833717,
            -0.029025258496403694,
            0.012037364766001701,
            -0.03584225848317146,
            0.01900610700249672,
            -0.0029715097043663263,
            -0.021877523511648178,
            0.02840985171496868,
            -0.018214914947748184,
            0.0031493804417550564,
            0.07717064023017883,
            0.009889647364616394,
            0.02193477749824524,
            -0.005929608829319477,
            -0.001716848462820053,
            -0.014705664478242397,
            0.01733364537358284,
            -0.023157203570008278,
            -0.08545961230993271,
            -0.031206069514155388,
            0.005664116702973843,
            -0.03456777334213257,
            0.03084416314959526,
            0.007960153743624687,
            0.004572741221636534,
            0.04379019886255264,
            -0.026256317272782326,
            0.04105328395962715,
            0.02144048549234867,
            0.04016679897904396,
            0.02336263470351696,
            0.056124549359083176,
            0.025349369272589684,
            0.05799034610390663,
            0.006271487567573786,
            0.008317229337990284,
            0.04435855895280838,
            -0.005733224097639322,
            0.026380091905593872,
            -0.04142452031373978,
            -0.004773703869432211,
            -0.0724613219499588,
            0.01738911122083664,
            0.0175082478672266,
            0.02546834945678711,
            -0.0022135721519589424,
            0.01554564107209444,
            -0.030332626774907112,
            -0.039957914501428604,
            -0.08985333144664764,
            0.0004283955495338887,
            0.0005347792757675052,
            0.0034803932067006826,
            0.005197382066398859,
            0.016235878691077232,
            -0.05703079327940941,
            -0.009577433578670025,
            -0.030567390844225883,
            -0.022779790684580803,
            0.006531885825097561,
            0.030086124315857887,
            0.016174914315342903,
            0.0024795776698738337,
            0.027888242155313492,
            0.030987080186605453,
            -0.019566087052226067,
            0.02467227913439274,
            0.048030100762844086,
            0.048531729727983475,
            -0.006883879192173481,
            0.019824234768748283,
            -0.021600821986794472,
            -0.02711920626461506,
            -0.02989770658314228,
            -0.033485930413007736,
            -0.02572130411863327,
            -0.0057783229276537895,
            -0.0403158999979496,
            0.035156190395355225,
            0.006159569136798382,
            -0.024773014709353447,
            0.024657675996422768,
            0.004053714219480753,
            -0.041766129434108734,
            -0.0014427064452320337,
            0.020641278475522995,
            -0.01100919023156166,
            0.004268723540008068,
            0.03651081398129463,
            0.023195462301373482,
            0.006768116727471352,
            -0.025517962872982025,
            -0.01745784655213356,
            -0.03679729625582695,
            -0.019732004031538963,
            0.015758907422423363,
            -0.026115795597434044,
            0.011150391772389412,
            -0.005605566781014204,
            -0.0006808597245253623,
            -0.014619647525250912,
            -0.006178052630275488,
            -0.09686780720949173,
            -0.009041406214237213,
            0.012252234853804111,
            0.047402478754520416,
            -0.05127520114183426,
            -0.022894179448485374,
            0.027377430349588394,
            0.02303936518728733,
            -0.008324382826685905,
            -0.046624552458524704,
            0.0028934096917510033,
            0.013158059678971767,
            -0.01035252120345831,
            -0.00389076373539865,
            0.023373540490865707,
            0.02151012048125267,
            0.016453733667731285,
            -0.003421288449317217,
            -0.02654583565890789,
            0.012333251535892487,
            -0.012548745609819889,
            -0.0769805833697319,
            0.0021042246371507645,
            0.0019460186595097184,
            -0.02395833656191826,
            0.009865924715995789,
            -0.01743597351014614,
            0.019620386883616447,
            -0.029043108224868774,
            -0.04836655780673027,
            -0.01892077922821045,
            -0.020199274644255638,
            0.0027338939253240824,
            -0.03667226433753967,
            0.014239060692489147,
            0.0005317840841598809,
            -0.0029368477407842875,
            -0.033904701471328735,
            -0.007105696946382523,
            0.031230824068188667,
            -0.0075694117695093155,
            -0.0009870947105810046,
            0.0005311723798513412,
            -0.013407312333583832,
            -0.031627677381038666,
            0.02579902857542038,
            0.021755529567599297,
            -0.02787843346595764,
            0.02913903258740902,
            -0.0050022476352751255,
            0.015721231698989868,
            0.01848897710442543,
            -0.023902656510472298,
            -0.013065709732472897,
            0.05018096789717674,
            -0.014772975817322731,
            -0.024355415254831314,
            0.021932024508714676,
            0.015079525299370289,
            -0.011021985672414303,
            0.030270688235759735,
            -0.0034359830897301435,
            -0.01977291703224182,
            0.0027159699238836765,
            0.002412175526842475,
            0.020147455856204033,
            -0.0008306806557811797,
            0.057944804430007935,
            -0.032794754952192307,
            -0.01937253773212433,
            -0.0004696968535427004,
            -0.02659827470779419,
            -0.0023872938472777605,
            0.018715500831604004,
            0.03911739960312843,
            0.01740988902747631,
            0.000595227291341871,
            0.032281018793582916,
            0.02730291523039341,
            -0.010387344285845757,
            0.03536996245384216,
            0.007259570527821779,
            -0.0027075237594544888,
            0.009303834289312363,
            0.007955731824040413,
            0.009532051160931587,
            0.010927545838057995,
            0.028520161285996437,
            0.005482523702085018,
            0.04973755031824112,
            0.009580791927874088,
            -0.006995947100222111,
            -0.04112973436713219,
            -0.011732439510524273,
            0.0841696709394455,
            0.054015565663576126,
            -0.05849124491214752,
            -0.009261646308004856,
            0.0034917632583528757,
            0.008809711784124374,
            0.039855893701314926,
            -0.020678706467151642,
            -0.01364455372095108,
            0.015084280632436275,
            -0.023274552077054977,
            -0.022006500512361526,
            -0.033100828528404236,
            0.04106036573648453,
            0.043808743357658386,
            0.002070133574306965,
            -0.03377231955528259,
            0.019932137802243233,
            0.023971017450094223,
            0.0022813351824879646,
            0.011324127204716206,
            -0.002281768014654517,
            0.03951147943735123,
            0.05139965936541557,
            -0.03079283982515335,
            -0.01230749674141407,
            -0.07407162338495255,
            -0.017398124560713768,
            0.01828899048268795,
            0.018791740760207176,
            0.013366488739848137,
            0.008174112997949123,
            -0.017091484740376472,
            0.011567933484911919,
            0.00986028928309679,
            0.0010575323831290007,
            -0.011279556900262833,
            0.015874218195676804,
            -0.023311281576752663,
            -0.002110654255375266,
            0.029267288744449615,
            0.027315864339470863,
            0.018244564533233643,
            -0.004182999953627586,
            -0.019310608506202698,
            -0.00283269421197474,
            -0.017255589365959167,
            0.02608880028128624,
            0.06206676363945007,
            0.006508917547762394,
            0.020242782309651375,
            -0.014828778803348541,
            -0.030661772936582565,
            0.004521755967289209,
            -0.006099036429077387,
            0.04679557681083679,
            -0.004957381635904312,
            0.003732414683327079,
            0.011327216401696205,
            0.028784384950995445,
            -0.0013682948192581534,
            0.015659168362617493,
            -0.029258104041218758,
            0.03465455770492554,
            -0.06328355520963669,
            -0.03117392212152481,
            -0.024416951462626457,
            0.009400089271366596,
            -0.05492362380027771,
            -0.025817641988396645,
            -0.012272563762962818,
            0.015965469181537628,
            -0.01318766176700592,
            -0.02817649021744728,
            0.01839621178805828,
            -0.0005496318335644901,
            0.009662383235991001,
            -0.004445282276719809,
            -0.015016783028841019,
            -0.0051147909834980965,
            -0.013978032395243645,
            -0.03414016216993332,
            0.03121795691549778,
            0.02765015698969364,
            -0.004214197862893343,
            -0.029284793883562088,
            0.019330479204654694,
            0.01770908199250698,
            -0.02363816648721695,
            0.039618462324142456,
            0.034976303577423096,
            -0.0154383210465312,
            0.02163849025964737,
            -0.010031159967184067,
            0.00025153433671221137,
            0.03195521607995033,
            -0.036477260291576385,
            -0.03346765413880348,
            0.005722240079194307,
            0.01001447718590498,
            -0.041133519262075424,
            0.02791624516248703,
            -0.0305222999304533,
            -0.03121822327375412,
            0.05791851505637169,
            0.0220631193369627,
            -0.004484058357775211,
            -0.03165803477168083,
            0.007916703820228577,
            0.02445809915661812,
            0.0163430143147707,
            -0.021619178354740143,
            -0.0651777982711792,
            0.04225855693221092,
            0.03015659563243389,
            0.02125580795109272,
            -0.011180652305483818,
            -0.0200330950319767,
            0.03719466179609299,
            0.03993311896920204,
            -0.022520460188388824,
            0.03132391721010208,
            -0.008021991699934006,
            0.0026143230497837067,
            0.012259813025593758,
            -0.004830637946724892,
            0.05535271391272545,
            -0.07569686323404312,
            0.00729845417663455,
            -0.0004584288690239191,
            0.0010162923717871308,
            -0.02361098863184452,
            0.006509469356387854,
            0.033069998025894165,
            0.029896754771471024,
            -0.03458502143621445,
            -0.03596419841051102,
            0.0011224726913496852,
            0.04049818590283394,
            0.04490542411804199,
            0.002635783748701215,
            -0.004243920091539621,
            -0.017870044335722923,
            0.027171628549695015,
            -0.01999031938612461,
            0.010347879491746426,
            -0.0018445138121023774,
            -0.022494159638881683,
            0.0055260080844163895,
            -0.035933446139097214,
            0.014612971805036068,
            0.009455593302845955,
            -0.025866279378533363,
            -0.01162050012499094,
            -0.022035498172044754,
            0.0144670270383358,
            -0.02158588543534279,
            0.0007632930646650493,
            0.012922019697725773,
            0.03413492813706398,
            -0.024524562060832977,
            -0.017697656527161598,
            -0.029928673058748245,
            -0.004111270420253277,
            -0.008521286770701408,
            -0.0031218768563121557,
            -0.009291088208556175,
            0.03425709903240204,
            -0.02052547223865986,
            0.027129001915454865,
            -0.025684917345643044,
            -0.0027219553012400866,
            -0.018241077661514282,
            0.014966937713325024,
            0.0170638095587492,
            -0.04669271036982536,
            -0.0001823933853302151,
            -0.01989959180355072,
            -0.019526638090610504,
            0.043778397142887115,
            -0.019830448552966118,
            0.02403467707335949,
            -0.013523142784833908,
            0.013267798349261284,
            0.04012318328022957,
            -0.0027002221904695034,
            0.048049915581941605,
            -0.01452601607888937,
            0.007503799628466368,
            0.014388698153197765,
            -0.03593963012099266,
            -0.044618286192417145,
            0.03360876068472862,
            -0.024773310869932175,
            0.02092966064810753,
            -0.016819942742586136,
            -0.05666877701878548,
            -0.02325933612883091,
            -0.0026252695824950933,
            0.009446796029806137,
            0.034333884716033936,
            -0.009816918522119522,
            -0.01861702837049961,
            -0.05625886097550392,
            0.026656655594706535,
            -0.018706247210502625,
            -0.006873093545436859,
            0.016302665695548058,
            -0.008381658233702183,
            -0.04753998667001724,
            0.04175055772066116,
            -0.008785523474216461,
            0.026209762319922447,
            -0.02306460589170456,
            -0.018228452652692795,
            0.039052896201610565,
            -0.08502974361181259,
            0.022310014814138412,
            0.009461763314902782,
            0.020116157829761505,
            0.027192329987883568,
            0.0349368155002594,
            -0.0009812498465180397,
            0.007242199033498764,
            -0.016211649402976036,
            0.004294766113162041,
            0.003367078024893999,
            0.03161322697997093,
            -0.002466259989887476,
            0.02225985750555992,
            0.04195576161146164,
            0.05603605881333351,
            -0.02286551147699356,
            0.025795893743634224,
            -0.03941553458571434,
            0.03869941458106041,
            -0.011621031910181046,
            0.020540200173854828,
            0.025690436363220215,
            0.0002863931586034596,
            0.03303591161966324,
            0.011206917464733124,
            -0.01813933439552784,
            -0.021956412121653557,
            0.043225668370723724,
            -0.024873506277799606,
            -0.03595872223377228,
            0.056520868092775345,
            0.021798150613904,
            0.013308809138834476,
            -0.015213573351502419,
            -0.007061490789055824,
            -0.022908959537744522,
            -0.03589415177702904,
            -0.005963600240647793,
            0.044104691594839096,
            0.029328811913728714,
            -0.04161309823393822,
            0.008403709158301353,
            -0.008052886463701725,
            -0.016711236909031868,
            0.05389324203133583,
            -0.05433002859354019,
            -0.008348864503204823,
            0.002887540962547064,
            0.0055129434913396835,
            0.002292413031682372,
            -0.041214656084775925,
            0.013947182334959507,
            -0.009055901318788528,
            -0.01061992533504963,
            0.008073905482888222,
            -0.0292957853525877,
            0.0182937178760767,
            -0.039779242128133774,
            -0.004590919706970453,
            0.05160164460539818,
            -0.021673448383808136,
            -0.0322643406689167,
            -0.014772211201488972,
            0.011006577871739864,
            -0.01773098111152649,
            -0.0018470878712832928,
            0.02750920131802559,
            0.03934621065855026,
            0.02594570443034172,
            -0.00168232386931777,
            -0.02655518241226673,
            -0.016203997656702995,
            0.003204808570444584,
            -0.03810187056660652,
            -0.02734622173011303,
            -0.029919074848294258,
            -0.05814377963542938,
            0.029674256220459938,
            -0.010604233480989933,
            0.009010870940983295,
            0.018520763143897057,
            0.028525035828351974,
            -0.053821198642253876,
            -0.07763021439313889,
            0.03142760321497917,
            0.03840475156903267,
            0.014264559373259544,
            0.010274400003254414,
            0.04024259373545647,
            0.025083178654313087,
            0.011940941214561462,
            0.0005709452670998871,
            0.035562507808208466,
            0.017489947378635406,
            0.01752348244190216,
            -0.013418717309832573,
            -0.010440447367727757,
            0.037264831364154816,
            0.02947896160185337,
            0.01550351269543171,
            0.025360049679875374,
            0.03345582261681557,
            0.024953387677669525,
            -0.027694523334503174,
            -0.02590855024755001,
            0.030571985989809036,
            0.025685274973511696,
            -0.06230383738875389,
            0.012189977802336216,
            -0.0045347376726567745,
            -0.013962358236312866,
            -0.0015442481962963939,
            0.007460454478859901,
            0.015087384730577469,
            -0.007301001809537411,
            -0.021919280290603638,
            0.0014580156421288848,
            0.025365961715579033,
            0.007688944227993488,
            -0.004458840936422348,
            0.036556728184223175,
            -0.0056286463513970375,
            -0.04894343763589859,
            -0.009336786344647408,
            -0.002470113104209304,
            0.02075442485511303,
            0.0025820580776780844,
            -0.019289446994662285,
            -0.004602729808539152,
            0.05892481282353401,
            -0.02539215236902237,
            0.02033897303044796,
            -0.0264019425958395,
            0.015491197817027569,
            0.0071605052798986435,
            0.016502972692251205,
            -0.028965327888727188,
            -0.020205574110150337,
            -0.019067278131842613,
            0.004461166448891163,
            -0.012293682433664799,
            0.0009158203611150384,
            0.022575480863451958,
            -0.025126740336418152,
            0.014451845549046993,
            0.012442735023796558,
            -0.051217369735240936,
            0.010663711465895176,
            0.029665548354387283,
            -0.04207916557788849,
            0.001451600925065577,
            0.02071773260831833,
            0.012017634697258472,
            -0.011026031337678432,
            -0.0663975402712822,
            0.05738375708460808,
            -0.008239692077040672,
            0.055288396775722504,
            0.005685006733983755,
            -0.08835921436548233,
            -0.047534044831991196,
            0.0026708748191595078,
            -0.020020293071866035,
            -0.0039911288768053055,
            -0.035589564591646194,
            0.017969127744436264,
            -0.023440243676304817,
            0.003915737848728895,
            0.022464828565716743,
            -0.030476270243525505,
            -0.002001555170863867,
            0.0072415792383253574,
            0.02398635260760784,
            0.031101223081350327,
            -0.017441697418689728,
            -0.015461446717381477,
            0.060181304812431335,
            0.056186266243457794,
            0.026462698355317116,
            0.04301413893699646,
            0.011079554446041584,
            0.03310871496796608,
            -0.03580394759774208,
            0.02167888730764389,
            -0.0030626177322119474,
            0.014724033884704113,
            -0.006051245611160994,
            0.008180271834135056,
            0.07498075813055038,
            -0.021318065002560616,
            -0.005959175527095795,
            0.015393035486340523,
            0.0031661971006542444,
            -0.022486232221126556,
            0.010217598639428616,
            0.016737649217247963,
            -0.029780756682157516,
            -0.017777230590581894,
            -0.02991611696779728,
            -0.028418416157364845,
            0.02740735560655594,
            0.05948716774582863,
            -0.019053218886256218,
            -0.07321061193943024,
            0.015310343354940414,
            -0.020936818793416023,
            -0.006874586921185255,
            -0.0801052451133728,
            -0.006151905283331871,
            0.0308049526065588,
            0.015260920859873295,
            0.020732242614030838,
            -0.016706015914678574,
            -0.023856572806835175,
            -0.0015169705729931593,
            -0.023266827687621117,
            -0.04582345485687256,
            -0.03654651343822479,
            0.027284592390060425,
            -0.02493494749069214,
            -0.018269497901201248,
            -0.01567104645073414,
            0.006014915648847818,
            -0.027255656197667122,
            -0.024073392152786255,
            0.008494557812809944,
            -0.03432295471429825,
            -0.03234364837408066,
            0.03465355187654495,
            -0.012771349400281906,
            0.014669232070446014,
            -0.013099675998091698,
            0.04483631253242493,
            -0.04693341627717018,
            -0.003413744270801544,
            0.003361636772751808,
            -0.042720116674900055,
            -0.04268471896648407,
            -0.01807321421802044,
            -0.031362760812044144,
            0.009210589341819286,
            -0.036461103707551956,
            -0.036858946084976196,
            0.023922989144921303,
            0.062297698110342026,
            0.010268627665936947,
            0.035593558102846146,
            -0.005278129130601883,
            0.03553823381662369,
            -0.014245390892028809,
            0.024777323007583618,
            -0.024190105497837067,
            0.018800979480147362,
            -0.031741365790367126,
            -0.00990420300513506,
            -0.009578418917953968,
            0.043195635080337524,
            -0.028621749952435493,
            -0.00023826245160307735,
            0.01438298262655735,
            -0.01009431853890419,
            0.04635581746697426,
            0.03423480689525604,
            -0.011470384895801544,
            0.017795737832784653,
            -0.0015753990737721324,
            0.022521620616316795,
            -0.019977813586592674,
            -0.003433923702687025,
            0.0031424995977431536,
            0.02207930013537407,
            -0.007254486903548241,
            0.004440790042281151,
            0.010452959686517715,
            0.012144646607339382,
            0.023955905809998512,
            0.0019303541630506516,
            -0.012719109654426575,
            0.02650996297597885,
            0.06111340597271919,
            0.014949644915759563,
            0.03401685506105423,
            -0.007447294890880585,
            -0.040383659303188324,
            -0.024164455011487007,
            -0.02502397634088993,
            -0.009424468502402306,
            0.027356261387467384,
            -0.07736561447381973,
            0.03878902271389961,
            0.06102365627884865,
            0.040168505162000656,
            0.00836089812219143,
            -0.02618470974266529,
            0.05346654728055,
            -0.018511632457375526,
            0.013844284228980541,
            -0.014793161302804947,
            -0.03258289769291878,
            -0.04227384924888611,
            -0.004053559619933367,
            -0.010776378214359283,
            -0.0074774292297661304,
            0.014924725517630577,
            0.03822973370552063,
            -0.03299635648727417,
            -0.03510264679789543,
            0.03215903043746948,
            -0.018533291295170784,
            0.03022048994898796,
            0.020695583894848824,
            0.0016878331080079079,
            -0.04268021881580353,
            -0.04171200469136238,
            0.028300384059548378,
            0.0051881782710552216,
            -0.025107944384217262,
            -0.029109543189406395,
            -0.005464565474539995,
            0.01762298494577408,
            -0.019523724913597107,
            0.019046034663915634,
            0.014287051744759083,
            -0.029477741569280624,
            -0.02411380596458912,
            -0.041133612394332886,
            0.031058363616466522,
            -0.02849697507917881,
            0.04186717048287392,
            -0.0037860998418182135,
            -0.017028333619236946,
            0.01658695749938488,
            -0.05122410133481026,
            -0.01620267890393734,
            0.013674981892108917,
            -0.012697642669081688,
            0.026696158573031425,
            0.0316661074757576,
            0.016916897147893906,
            0.03246442228555679,
            -0.022694438695907593,
            0.02997295930981636,
            0.02239559404551983,
            -0.01760375127196312,
            0.002587028546258807,
            0.03810107707977295,
            -0.0033898630645126104,
            0.002586043905466795,
            0.01536731980741024,
            -0.012917937710881233,
            0.022296642884612083,
            -0.027346448972821236,
            0.03892524912953377,
            0.01488924864679575,
            -0.028165303170681,
            0.023523878306150436,
            0.014311782084405422,
            0.003677758388221264,
            0.001593089895322919,
            -0.007636733818799257,
            -0.005872746463865042,
            0.0082011129707098,
            -0.021099666133522987,
            0.008611008524894714,
            0.03912678733468056,
            0.019425492733716965,
            0.04716968908905983,
            -0.021548286080360413,
            0.01272482331842184,
            0.03456750512123108,
            0.0195312537252903,
            -0.01611415296792984,
            -0.026853976771235466,
            0.014391225762665272,
            0.01347044575959444,
            0.002026435686275363,
            0.013872742652893066,
            -0.002690725028514862,
            0.025666959583759308,
            -0.008270809426903725,
            -0.01999863050878048,
            -0.0009210709831677377,
            -0.010174287483096123,
            0.014970485121011734,
            -0.01969042234122753,
            0.014962454326450825,
            -0.030813980847597122,
            -0.012543040327727795,
            0.040243469178676605,
            0.006791580468416214,
            -0.02163575030863285,
            0.03996098414063454,
            -0.02810845337808132,
            0.010588901117444038,
            -0.02571585401892662,
            0.023498177528381348,
            -0.01454799436032772,
            0.033677421510219574,
            -0.0198394563049078,
            0.055817026644945145,
            -0.027333427220582962,
            -0.03821864351630211,
            0.047004736959934235,
            0.021905558183789253,
            -0.010489806532859802,
            0.017441662028431892,
            0.032437801361083984,
            -0.04746558144688606
        ],
        [
            -0.03127451241016388,
            -0.044415414333343506,
            0.012487786822021008,
            0.009641529060900211,
            0.007696277461946011,
            -0.004643822088837624,
            0.027895508334040642,
            -0.03150762990117073,
            0.009878727607429028,
            0.011723414994776249,
            -0.00768556771799922,
            -0.01545798871666193,
            0.015837684273719788,
            -0.011715437285602093,
            -0.019982855767011642,
            -0.01513746939599514,
            0.024006545543670654,
            -0.0031317563261836767,
            -0.004589690826833248,
            -0.019029458984732628,
            -0.002770244376733899,
            0.00759135140106082,
            0.04250936210155487,
            0.009774674661457539,
            -0.03194635733962059,
            -0.011661759577691555,
            -0.027335267513990402,
            -0.024911504238843918,
            -0.029918961226940155,
            0.0079964529722929,
            0.01721535623073578,
            0.03704297915101051,
            -0.00015295854245778173,
            0.03009055368602276,
            0.011493795551359653,
            -0.02245900221168995,
            0.0003541172482073307,
            0.0010590532328933477,
            -0.025127744302153587,
            0.00678815646097064,
            0.02014770172536373,
            0.04004614055156708,
            -0.004322326276451349,
            -0.019477156922221184,
            0.006829351186752319,
            -0.025197697803378105,
            0.007882921025156975,
            0.023384124040603638,
            0.013886988162994385,
            -0.00010755491530289873,
            0.0247886274009943,
            -0.022531086578965187,
            0.0012479197466745973,
            0.009619687683880329,
            0.008804886601865292,
            -0.0024211567360907793,
            -0.005053380504250526,
            -0.0027356918435543776,
            -0.027285708114504814,
            0.0036445825826376677,
            0.012261704541742802,
            -0.013290029019117355,
            -0.023174265399575233,
            0.02787710726261139,
            -0.022639233618974686,
            -0.026366353034973145,
            -0.021478021517395973,
            -0.008491810411214828,
            0.017704902216792107,
            0.02863314189016819,
            -0.02763233333826065,
            0.007699009962379932,
            -0.005706179887056351,
            -0.03248091787099838,
            -0.01001730002462864,
            0.01799224130809307,
            -0.013744273222982883,
            0.0007808309746906161,
            -0.048446185886859894,
            0.004647873807698488,
            -0.010510394349694252,
            0.01210545189678669,
            0.017869509756565094,
            -0.012340676970779896,
            0.025013336911797523,
            0.008977632038295269,
            0.009125777520239353,
            -0.01596398837864399,
            0.015673067420721054,
            -0.00970000121742487,
            0.03978276625275612,
            0.029567059129476547,
            0.026127561926841736,
            0.04283914342522621,
            0.006520422175526619,
            0.020846061408519745,
            -0.031040484085679054,
            0.002392078749835491,
            -0.01362681109458208,
            0.0034117503091692924,
            0.03253795579075813,
            -0.015136933885514736,
            -0.013824059627950191,
            0.028379714116454124,
            0.0274458397179842,
            0.02145584300160408,
            0.026978416368365288,
            -0.01309195626527071,
            0.0003373971558175981,
            -0.0060862279497087,
            -0.0009225883986800909,
            -0.046809129416942596,
            0.006547700148075819,
            -0.007105802185833454,
            0.0027556305285543203,
            0.006705871783196926,
            0.008067173883318901,
            -0.005798959638923407,
            -0.02818133495748043,
            0.016903435811400414,
            -0.002416492672637105,
            -0.02896532043814659,
            -0.04709933325648308,
            -0.012736366130411625,
            0.011851726099848747,
            0.019453058019280434,
            -0.014501387253403664,
            0.004543170798569918,
            0.010160407051444054,
            -0.011003558523952961,
            0.017445001751184464,
            -0.011062754318118095,
            -0.021473627537488937,
            -0.02413967251777649,
            -0.00607689656317234,
            0.013376100920140743,
            0.008735941722989082,
            -0.015049150213599205,
            -0.0013288275804370642,
            -0.0025740291457623243,
            -0.027512256056070328,
            0.008600173518061638,
            -0.009613489732146263,
            0.026128515601158142,
            -0.00025405254564248025,
            0.000600457948166877,
            0.020521657541394234,
            0.01665942370891571,
            -0.004903930239379406,
            0.026334859430789948,
            0.015528611838817596,
            0.061389997601509094,
            -0.010127514600753784,
            0.01158457063138485,
            0.013651594519615173,
            -0.0031868305522948503,
            0.024400673806667328,
            0.027857914566993713,
            0.03002288192510605,
            -0.034647081047296524,
            -0.01842079497873783,
            0.020557060837745667,
            -0.0034566204994916916,
            -0.003482276340946555,
            -0.00027779577067121863,
            -0.007259051781147718,
            -0.019969062879681587,
            0.026205122470855713,
            0.02011270448565483,
            0.02854914218187332,
            -0.000623602420091629,
            0.024934178218245506,
            0.00705953361466527,
            0.01949896477162838,
            -0.03748701512813568,
            0.022448314353823662,
            -0.029998330399394035,
            -0.02291712537407875,
            0.0018940947484225035,
            0.01268940232694149,
            -0.009489720687270164,
            0.038515605032444,
            -0.03496637940406799,
            -0.03329436853528023,
            -0.02625603973865509,
            0.012085957452654839,
            -0.03862767294049263,
            -0.02522425539791584,
            0.013365685939788818,
            0.021508870646357536,
            -0.008157373405992985,
            -0.008106691762804985,
            -0.039137694984674454,
            -0.019418975338339806,
            0.005878258962184191,
            0.006715458817780018,
            -0.01383099053055048,
            0.013954555615782738,
            0.028903432190418243,
            0.0038244540337473154,
            -0.003172519151121378,
            0.005521370563656092,
            0.014607412740588188,
            0.03038087859749794,
            -0.006211301311850548,
            0.030911238864064217,
            -0.00416575325652957,
            -0.01547439955174923,
            -0.04039163887500763,
            -0.020310916006565094,
            -0.006810871884226799,
            0.006845686584711075,
            0.02978777512907982,
            -0.010513875633478165,
            0.03649258241057396,
            0.020464889705181122,
            -0.011693490669131279,
            -0.004056389909237623,
            -0.008162004873156548,
            0.027689535170793533,
            -0.0023896305356174707,
            -0.019926927983760834,
            -0.013427052646875381,
            -0.012765450403094292,
            0.02615404687821865,
            -0.026535620912909508,
            0.01983289048075676,
            -0.0007950523286126554,
            -0.03097255900502205,
            -0.019200783222913742,
            0.026044711470603943,
            -0.03745114430785179,
            0.03796248510479927,
            0.02783500961959362,
            0.011779212392866611,
            0.037996578961610794,
            -0.013019690290093422,
            0.02025919407606125,
            0.017785154283046722,
            -0.006759967189282179,
            -0.004742378368973732,
            0.024136148393154144,
            -0.00771485548466444,
            -0.016698718070983887,
            -0.004543056711554527,
            0.024192769080400467,
            0.002871965290978551,
            -0.027231300249695778,
            -0.027010679244995117,
            -0.007508402224630117,
            -0.010656394995748997,
            0.03896702080965042,
            0.012512542307376862,
            -0.028506863862276077,
            0.025814445689320564,
            -0.028360851109027863,
            0.002377832308411598,
            -0.016169575974345207,
            0.010535188019275665,
            0.03524978086352348,
            -0.009905973449349403,
            0.0012314774794504046,
            0.0060461885295808315,
            0.01658903807401657,
            -0.008835244923830032,
            -0.031808555126190186,
            0.013794542290270329,
            -0.0027554919943213463,
            0.010589555837213993,
            0.01013597846031189,
            -0.038148242980241776,
            0.021059678867459297,
            -0.005685101263225079,
            -0.01113101001828909,
            0.04070061817765236,
            0.028316913172602654,
            -0.013723034411668777,
            -0.013890189118683338,
            0.027507541701197624,
            0.029867149889469147,
            -0.01134577114135027,
            0.029194924980401993,
            0.0006945667555555701,
            -0.021246491000056267,
            0.013847703114151955,
            0.010225842706859112,
            0.03035379946231842,
            -0.006786540616303682,
            0.033523280173540115,
            -0.029528826475143433,
            0.006693263538181782,
            -0.0024438148830085993,
            -0.004807476419955492,
            -0.02153901197016239,
            -0.017687469720840454,
            0.022612594068050385,
            0.019833924248814583,
            -0.02547053061425686,
            -0.013149193488061428,
            0.018287645652890205,
            -0.015402479097247124,
            0.01845458708703518,
            0.027657920494675636,
            0.0007872901624068618,
            0.027061307802796364,
            0.02030576951801777,
            0.011123516596853733,
            0.013007724657654762,
            -0.026898682117462158,
            -0.011586183682084084,
            0.008548488840460777,
            -0.011919879354536533,
            0.026425102725625038,
            -0.02608281932771206,
            -0.011772526428103447,
            -0.015967512503266335,
            0.021996645256876945,
            -0.006158056203275919,
            0.018715299665927887,
            -0.012945367023348808,
            -0.0015247437404468656,
            -0.043886713683605194,
            -0.005534167867153883,
            -0.03832339495420456,
            -0.0004463855002541095,
            0.0014039201196283102,
            0.015491086058318615,
            0.02751321531832218,
            0.0034254342317581177,
            -0.014722776599228382,
            -0.02762041799724102,
            -0.025044942274689674,
            0.009840442799031734,
            0.02568005584180355,
            0.007543195504695177,
            -0.008509748615324497,
            0.008917609229683876,
            -0.018224887549877167,
            -0.019941674545407295,
            0.008931398391723633,
            -0.010325337760150433,
            -0.027629582211375237,
            -0.027347873896360397,
            -0.010662965476512909,
            0.014313149265944958,
            0.008980754762887955,
            -0.016025099903345108,
            -0.0014147281181067228,
            -0.02208097279071808,
            -0.0029305603820830584,
            0.0004663185973186046,
            -0.018045466393232346,
            -0.020989008247852325,
            -0.022372283041477203,
            -0.007740320637822151,
            -0.019337590783834457,
            0.026090677827596664,
            0.026504112407565117,
            0.03401826694607735,
            -0.013058565557003021,
            0.037148263305425644,
            0.019470902159810066,
            -0.008359712548553944,
            0.004666052293032408,
            -0.0025248974561691284,
            0.0005271235131658614,
            0.03790808841586113,
            -0.020477063953876495,
            0.029076170176267624,
            0.023602446541190147,
            -0.015663079917430878,
            -0.02167966589331627,
            0.017201635986566544,
            -0.00484942551702261,
            0.010248271748423576,
            -0.004637856502085924,
            -0.03210296109318733,
            -0.0030326717533171177,
            -0.002914185170084238,
            -0.006814360152930021,
            -0.02623463049530983,
            0.015176307410001755,
            0.018640881404280663,
            -0.02708207257091999,
            -0.029321137815713882,
            0.009980924427509308,
            0.011298407800495625,
            -0.0014792373403906822,
            0.029033996164798737,
            0.013016262091696262,
            0.03387881815433502,
            0.012129577808082104,
            -0.013216016814112663,
            -0.02126566506922245,
            -0.027318904176354408,
            0.040168121457099915,
            -0.0012228278210386634,
            0.024674557149410248,
            0.03120773658156395,
            0.008133496157824993,
            -0.05418830364942551,
            -0.01759970188140869,
            0.026193486526608467,
            -0.009505800902843475,
            0.010211644694209099,
            -0.003578975796699524,
            0.00037010337109677494,
            -0.0074198124930262566,
            0.014166517183184624,
            0.0008984681335277855,
            -0.008398227393627167,
            0.019619686529040337,
            0.008130732923746109,
            -0.01669694297015667,
            0.013350984081625938,
            0.018775956705212593,
            -0.009648824110627174,
            0.0087450435385108,
            -0.019842663779854774,
            0.007805859204381704,
            0.035393230617046356,
            0.030874326825141907,
            0.04957173392176628,
            0.017089542001485825,
            0.01512299757450819,
            0.03322715312242508,
            0.03083130158483982,
            -0.005768687929958105,
            0.027708549052476883,
            0.02533138543367386,
            0.007710179779678583,
            0.024427352473139763,
            0.008042543195188046,
            -0.027743840590119362,
            -0.016703331843018532,
            0.005313010886311531,
            0.005887266248464584,
            0.005071508232504129,
            -0.013470197096467018,
            0.003759906627237797,
            -0.001379096880555153,
            0.025115862488746643,
            -0.007801298052072525,
            0.0014381309738382697,
            -0.012676291167736053,
            -0.026560883969068527,
            -0.007164844311773777,
            0.03308906406164169,
            0.01416503544896841,
            -0.029238155111670494,
            0.011268876492977142,
            -0.01531397458165884,
            -0.014319048263132572,
            0.00833685602992773,
            -0.0182157214730978,
            -0.022512618452310562,
            0.0420173741877079,
            -0.029545729979872704,
            -0.026075340807437897,
            -0.0023670163936913013,
            -0.004186123143881559,
            -0.00337808346375823,
            -0.010343524627387524,
            -0.0002107449690811336,
            -0.004931321367621422,
            -0.010782147757709026,
            0.004952457267791033,
            0.04377366229891777,
            0.038275714963674545,
            -0.011601301841437817,
            -0.007144880015403032,
            -0.005705691408365965,
            -0.007011250592768192,
            0.029547354206442833,
            0.015806207433342934,
            0.02233782969415188,
            -0.017829010263085365,
            -0.004286141600459814,
            -0.009460612200200558,
            0.014533462934195995,
            1.1212265235371888e-05,
            -0.03434554859995842,
            -0.020361417904496193,
            0.02043449878692627,
            0.03031228855252266,
            0.009190389886498451,
            -0.012984873726963997,
            -0.0005701918271370232,
            -0.05256202816963196,
            -0.015734922140836716,
            -0.025170521810650826,
            -0.0004917604965157807,
            0.0262460857629776,
            0.03909582644701004,
            0.025330450385808945,
            -0.02059481106698513,
            -0.005076244007796049,
            0.00664123659953475,
            -0.028707453981041908,
            0.010372065007686615,
            0.02083628624677658,
            0.03444623574614525,
            -0.011630178429186344,
            -0.02668638899922371,
            -0.0028463031630963087,
            0.02141244150698185,
            -0.004874407313764095,
            -0.0023280601017177105,
            -0.013798790983855724,
            -0.027039363980293274,
            0.012124765664339066,
            -0.022908847779035568,
            -0.006574162747710943,
            0.005926421843469143,
            -0.029000215232372284,
            0.014500503428280354,
            -0.005829415749758482,
            -0.02388356812298298,
            -0.010702941566705704,
            -0.0197812058031559,
            0.02660556696355343,
            0.003119288943707943,
            0.02083696983754635,
            0.0077101257629692554,
            -0.008100787177681923,
            0.020190900191664696,
            0.03622690960764885,
            -0.030678024515509605,
            0.0006730884197168052,
            -0.013637902215123177,
            -0.01822829060256481,
            0.022948414087295532,
            -0.020981160923838615,
            0.025902001187205315,
            0.02044478803873062,
            -0.006873240228742361,
            0.0006778017268516123,
            0.016348635777831078,
            -0.027596386149525642,
            0.012402553111314774,
            -0.019174732267856598,
            0.02242416888475418,
            -0.017745722085237503,
            -0.019931452348828316,
            -0.02752613089978695,
            0.004878779873251915,
            -0.022999867796897888,
            -0.015211931429803371,
            0.004108057357370853,
            -0.005631722509860992,
            0.004610082134604454,
            0.023626714944839478,
            -0.0029020640067756176,
            0.00894944742321968,
            0.026307234540581703,
            0.036015015095472336,
            0.014778376556932926,
            -0.02383909747004509,
            0.005930535029619932,
            -0.019212156534194946,
            0.01989530585706234,
            -0.030306518077850342,
            0.02607656456530094,
            -0.04975351318717003,
            0.015457246452569962,
            0.028884097933769226,
            0.01610059291124344,
            -0.010166331194341183,
            -0.018769169226288795,
            0.003924370743334293,
            0.003604683792218566,
            -0.005659457761794329,
            0.02578751929104328,
            0.009667010977864265,
            0.033738527446985245,
            0.017478467896580696,
            0.02343253791332245,
            0.010371953248977661,
            -0.006193747743964195,
            0.022464165464043617,
            -0.020619835704565048,
            -0.0065079680643975735,
            -3.1680046959081665e-05,
            0.011835291050374508,
            -0.01479695551097393,
            -0.02264181710779667,
            0.006314639933407307,
            -0.007511924486607313,
            -0.018650885671377182,
            0.03367101401090622,
            0.017599590122699738,
            -0.026157144457101822,
            -0.016349684447050095,
            -0.0064147706143558025,
            -0.0011951252818107605,
            -0.014182275161147118,
            0.008295412175357342,
            -0.015259366482496262,
            0.010339359752833843,
            0.02351013943552971,
            -0.02079044096171856,
            0.02483709342777729,
            -0.010974831879138947,
            -0.013066321611404419,
            -0.0029572350904345512,
            -0.026769381016492844,
            -0.017626645043492317,
            -0.01723574660718441,
            -0.011402945965528488,
            -0.04824592545628548,
            0.02208523452281952,
            0.0041330731473863125,
            -0.02480693720281124,
            0.03258347511291504,
            -0.04703882336616516,
            -0.012458751909434795,
            -0.02555875852704048,
            0.026392219588160515,
            -0.023067858070135117,
            -0.036475565284490585,
            -0.0047293356619775295,
            0.013928421773016453,
            0.0063345059752464294,
            0.020834941416978836,
            0.027334606274962425,
            -0.02632923051714897,
            0.01727037876844406,
            -0.004812856204807758,
            0.0007990856538526714,
            -0.015122814103960991,
            -0.014293821528553963,
            0.0019309414783492684,
            -0.020419728010892868,
            0.008890394121408463,
            0.025092335417866707,
            0.01984243281185627,
            -0.004653584212064743,
            -0.012467674911022186,
            0.0006535104475915432,
            0.006331904791295528,
            0.0014934084611013532,
            0.007873082533478737,
            -0.01472360547631979,
            -0.015182530507445335,
            -0.009866724722087383,
            0.019354194402694702,
            0.002028722083196044,
            0.0019040594343096018,
            -0.024313507601618767,
            0.021090246737003326,
            0.02375034987926483,
            0.03016195073723793,
            -0.022354232147336006,
            0.026622746139764786,
            -0.01546924002468586,
            0.021070795133709908,
            0.011638283729553223,
            -0.015718981623649597,
            0.008916965685784817,
            -0.0017487119184806943,
            -0.022424494847655296,
            0.02534465491771698,
            0.024412186816334724,
            0.002390817506238818,
            -0.021476034075021744,
            -0.025941716507077217,
            0.013877084478735924,
            -0.008467246778309345,
            0.032116081565618515,
            -0.007390663493424654,
            0.020893098786473274,
            -0.0042222957126796246,
            -0.013570682145655155,
            -0.00017649053188506514,
            0.03425375372171402,
            -0.003114105900749564,
            -0.02282422035932541,
            -0.025391630828380585,
            0.00097032287158072,
            0.016066646203398705,
            -0.009853438474237919,
            -0.020270271226763725,
            -0.014671547338366508,
            0.006049256306141615,
            -0.01671452261507511,
            0.014143411070108414,
            -0.03210648521780968,
            -0.003725511720404029,
            0.019732631742954254,
            -0.022657813504338264,
            0.04135647043585777,
            -0.0036272660363465548,
            0.04402285814285278,
            -0.007608954794704914,
            -0.0026261548046022654,
            0.004152521025389433,
            0.027493726462125778,
            -0.006618545390665531,
            0.028069812804460526,
            0.01848425716161728,
            0.023168256506323814,
            0.008796590380370617,
            -0.006514336913824081,
            -0.010725771076977253,
            -0.03582611307501793,
            -0.03353428840637207,
            0.02634228952229023,
            -0.010221648961305618,
            -0.008226453326642513,
            -0.0279681496322155,
            0.0033277857583016157,
            0.0070090387016534805,
            0.008924855850636959,
            -0.010693348944187164,
            -0.0028086062520742416,
            -0.018593164160847664,
            -0.028807416558265686,
            0.039905570447444916,
            -0.02078974060714245,
            -0.01842336729168892,
            -0.020825177431106567,
            -0.004648236092180014,
            -0.0030038643162697554,
            -0.028979534283280373,
            0.0258781760931015,
            0.016736749559640884,
            0.021968206390738487,
            -0.027343254536390305,
            0.012204944156110287,
            0.011512752622365952,
            -0.03501997888088226,
            -0.0036875877995043993,
            0.013556834310293198,
            0.005743352230638266,
            0.02968735247850418,
            -0.002975689247250557,
            0.008345951326191425,
            -0.022955570369958878,
            0.0072836983017623425,
            0.019177855923771858,
            0.006943275220692158,
            -0.022811908274888992,
            0.0024516636040061712,
            0.035214461386203766,
            0.008523681201040745,
            0.0035842189099639654,
            0.008568587712943554,
            0.015828080475330353,
            -0.019063878804445267,
            0.024280596524477005,
            0.016725191846489906,
            0.005150291137397289,
            0.03392919525504112,
            -0.0012436900287866592,
            -0.01771782711148262,
            0.02081693708896637,
            -0.0160654429346323,
            -0.004861241206526756,
            0.00788344070315361,
            0.03197411447763443,
            0.010347318835556507,
            -0.04096077382564545,
            -0.02570749819278717,
            0.010708067566156387,
            0.012821626849472523,
            -0.030491452664136887,
            -0.02785969153046608,
            -0.0002414181362837553,
            -0.009582358412444592,
            0.008922982029616833,
            0.03378099948167801,
            -0.002271355362609029,
            0.03931831195950508,
            0.013948900625109673,
            -0.016116367653012276,
            -0.01705053634941578,
            0.003981869202107191,
            0.021364862099289894,
            -0.0013735416578128934,
            -0.021537579596042633,
            0.023375805467367172,
            0.026988934725522995,
            -0.02667110413312912,
            0.01871115155518055,
            -0.023072201758623123,
            -0.019442448392510414,
            -0.004314825404435396,
            0.014466333203017712,
            -0.008927847258746624,
            -0.014837554655969143,
            -0.025006357580423355,
            -0.010059019550681114,
            0.02668321505188942,
            -0.02155693620443344,
            0.007108336314558983,
            -0.003719121916219592,
            -0.007931722328066826,
            0.020679499953985214,
            0.02732442505657673,
            -0.02498713508248329,
            -0.027949534356594086,
            -0.028277238830924034,
            0.0235520601272583,
            0.016252536326646805,
            -0.00901566818356514,
            -0.02974214032292366,
            -0.00030182269983924925,
            -0.00798799004405737,
            0.01497088372707367,
            -0.03410341218113899,
            0.0018356770742684603,
            -0.014186112210154533,
            -0.01594146341085434,
            0.006002985406666994,
            -0.025956274941563606,
            0.013112411834299564,
            0.03642399609088898,
            0.022690415382385254,
            0.008314801380038261,
            -0.011756348423659801,
            -0.024952195584774017,
            -0.036111827939748764,
            0.004681382793933153,
            0.01547361072152853,
            -0.05174676701426506,
            0.012804809957742691,
            0.025843173265457153,
            0.020158909261226654,
            -0.013042643666267395,
            -0.0005260207108221948,
            -0.014279546216130257,
            0.03422589227557182,
            0.020741349086165428,
            0.013129398226737976,
            0.03624662756919861,
            -0.008780497126281261,
            0.011710081249475479,
            -0.009710804559290409,
            -0.013412262313067913,
            -0.00876561552286148,
            -0.02524770423769951,
            -0.03040301240980625,
            0.010274733416736126,
            0.005290584173053503,
            -0.022383078932762146,
            -0.030842868611216545,
            -0.031387489289045334,
            -0.0030573236290365458,
            0.004188327584415674,
            -0.025670459493994713,
            0.01371813379228115,
            0.010702371597290039,
            0.0013249902985990047,
            0.002734903246164322,
            0.00551180262118578,
            0.004288168624043465,
            0.018712451681494713,
            0.03331868723034859,
            0.029091736301779747,
            -0.02172570861876011,
            -0.009744559414684772,
            -0.01454413402825594,
            0.03553153574466705,
            0.03190218284726143,
            -0.0034585585817694664,
            -0.011621353216469288,
            0.028745215386152267,
            0.015791049227118492,
            0.0077621773816645145,
            -0.028992125764489174,
            0.0343284048140049,
            0.02165786363184452,
            0.013513647951185703,
            -0.025232426822185516,
            0.02386392280459404,
            -0.01984459161758423,
            0.022616101428866386,
            -0.06195133551955223,
            0.03385983407497406,
            0.0023697386495769024,
            -0.01993463933467865,
            0.0252496637403965,
            0.0071868207305669785,
            -0.0044380296021699905,
            -0.03377681225538254,
            -0.004876385908573866,
            -0.027346277609467506,
            -0.01760331355035305,
            0.0013096812181174755,
            0.008195353671908379,
            0.0008689835085533559,
            0.03459499031305313,
            -0.006839398294687271,
            0.046901676803827286,
            -0.03809342160820961,
            0.006523307412862778,
            0.014702918007969856,
            0.015070926398038864,
            0.009471532888710499,
            0.016220811754465103,
            0.014868950471282005,
            -0.02265947125852108,
            -0.02711593732237816,
            -0.001685227849520743,
            0.0486435666680336,
            -0.0336037278175354,
            -0.00537077896296978,
            -0.02017316408455372,
            0.012644448317587376,
            -0.011638898402452469,
            0.011614490300416946,
            -0.02632453292608261,
            0.004005475901067257,
            0.03188087046146393,
            0.0257989801466465,
            0.01164171751588583,
            -0.005952890962362289,
            0.028596479445695877,
            0.0291492510586977,
            -0.009991670027375221,
            -0.022504623979330063,
            -0.014223798178136349,
            -0.01489250734448433,
            -0.008485385216772556,
            0.013301817700266838,
            0.03856188803911209,
            -0.011005286127328873,
            0.0051412805914878845,
            0.017808502539992332,
            0.006737339776009321,
            -0.02421431802213192,
            0.02065085992217064,
            0.0015293537871912122,
            -0.013590317219495773,
            0.003423588816076517,
            -0.01131447684019804,
            -0.0065626585856080055,
            -5.77142636757344e-05,
            0.0264457855373621,
            0.013423146679997444,
            -0.008476242423057556,
            -0.018512817099690437,
            -0.03616448864340782,
            -0.0024380062241107225,
            -0.037156589329242706,
            0.023210909217596054,
            0.02320154756307602,
            0.006122036837041378,
            -0.002420241478830576,
            0.020185884088277817,
            -0.03678878769278526,
            -0.015196244232356548,
            0.01583356037735939,
            -0.03819941729307175,
            -0.01818772591650486,
            -0.017545918002724648,
            -0.004639682359993458,
            -0.006414654664695263,
            -0.040251974016427994,
            -0.004807995166629553,
            -0.04161469265818596,
            0.025385228917002678,
            -0.008453366346657276,
            0.02271099016070366,
            -0.0394381508231163,
            -0.024792073294520378,
            0.01912989467382431,
            0.01129956915974617,
            -0.012345519848167896,
            0.020429981872439384,
            -0.009376133792102337,
            -0.02536640502512455,
            -0.022913409397006035,
            -0.019683314487338066,
            -0.001108871423639357,
            0.02153552696108818,
            -0.051452167332172394,
            0.006957803852856159,
            -0.01398394349962473,
            -0.0028777995612472296,
            -0.016458487138152122,
            -0.020730966702103615,
            -0.04496809095144272,
            -0.0221195537596941,
            -0.01911073923110962,
            -0.025714822113513947,
            -0.04228134825825691,
            0.0032820510677993298,
            -0.0020228063222020864,
            -0.026859039440751076,
            0.012110776267945766,
            -0.011365666054189205,
            0.02719292975962162,
            0.023696361109614372,
            -0.008823473006486893,
            -0.013803091831505299,
            0.023127881810069084,
            -0.0037331487983465195,
            -0.004562938120216131,
            -0.02622932381927967,
            -0.015102609060704708,
            -0.03207451105117798,
            0.0017472716281190515,
            0.013295747339725494,
            -0.03381409868597984,
            0.03324670344591141,
            -0.026685092598199844,
            0.031460195779800415,
            0.02197386883199215,
            0.04107281193137169,
            0.03180024027824402,
            0.016053522005677223,
            -0.03613616153597832,
            -0.03172236308455467,
            -0.02347746677696705,
            0.034284498542547226,
            -0.01944449171423912,
            0.024460287764668465,
            -0.0372939370572567,
            0.03056924231350422,
            0.02373555116355419,
            0.009846704080700874,
            0.0005049750907346606,
            0.0016055412124842405
        ],
        [
            0.0007901998469606042,
            -0.009134150110185146,
            -0.023140741512179375,
            0.016947973519563675,
            0.029331397265195847,
            0.03561260551214218,
            0.00532967783510685,
            0.0235197264701128,
            0.028210604563355446,
            -0.029528312385082245,
            0.008776301518082619,
            -0.011959066614508629,
            -0.012682783417403698,
            -0.016169825568795204,
            -0.015371444635093212,
            0.00029255112167447805,
            -0.03719629719853401,
            -0.00052124192006886,
            -0.029022101312875748,
            -0.006130124442279339,
            0.011350386776030064,
            -0.023465992882847786,
            0.005692172329872847,
            0.021450994536280632,
            -0.038036048412323,
            -0.014706875197589397,
            -0.04924018681049347,
            -0.01696627587080002,
            0.007908034138381481,
            -0.01923796720802784,
            0.021429436281323433,
            0.010316024534404278,
            0.03677274286746979,
            -0.026392828673124313,
            0.017867177724838257,
            0.00924408808350563,
            -0.009608014486730099,
            0.0016468187095597386,
            -0.020802432671189308,
            -0.008457720279693604,
            0.007530326955020428,
            0.04016093909740448,
            0.0062336428090929985,
            -0.02039802446961403,
            -0.061980437487363815,
            -0.03225426748394966,
            -0.020397352054715157,
            -0.035092201083898544,
            -0.03054087422788143,
            0.024433234706521034,
            -0.020798057317733765,
            -0.04074281081557274,
            -0.0031374716199934483,
            -0.03166741877794266,
            -0.05956822633743286,
            -0.025315405800938606,
            -0.020027440041303635,
            -0.016329841688275337,
            0.008079225197434425,
            -0.007192314602434635,
            -0.03648035600781441,
            -0.021320732310414314,
            0.019495181739330292,
            -0.01888924650847912,
            0.004263943061232567,
            -0.012572928331792355,
            -0.002126954961568117,
            -0.003678292501717806,
            -0.022457128390669823,
            -0.015151391737163067,
            0.008624617010354996,
            -0.030938245356082916,
            0.03797620162367821,
            0.007912312634289265,
            -0.04354192689061165,
            -0.022569354623556137,
            -0.01056555937975645,
            0.002801282098516822,
            0.00036906759487465024,
            -0.0065074702724814415,
            0.011765355244278908,
            -0.06440391391515732,
            -0.01154339499771595,
            0.006947400979697704,
            0.02525722049176693,
            0.01619713380932808,
            -0.004302384797483683,
            -0.0010584206320345402,
            0.015131845138967037,
            -0.00010457407915964723,
            -0.005411486607044935,
            -0.01014769822359085,
            -0.027785316109657288,
            -0.025578223168849945,
            0.0023574279621243477,
            0.024136103689670563,
            0.004642936401069164,
            -0.006542825140058994,
            -0.05055990815162659,
            -0.021337708458304405,
            -0.012584008276462555,
            -0.06870456039905548,
            -0.006651056464761496,
            0.013267033733427525,
            -0.007696741260588169,
            0.014649521559476852,
            0.028898924589157104,
            0.043583501130342484,
            -0.019455645233392715,
            0.023234954103827477,
            -0.033972322940826416,
            0.0036982852034270763,
            -0.013325507752597332,
            -0.005379633512347937,
            0.027904534712433815,
            0.01837034523487091,
            -0.02552567794919014,
            0.0015785354189574718,
            0.027182186022400856,
            -0.03335098177194595,
            0.013594192452728748,
            -0.02289590984582901,
            0.015100087970495224,
            0.02084510400891304,
            -0.009257680736482143,
            0.003654085099697113,
            -0.01353480014950037,
            0.018265940248966217,
            0.018953725695610046,
            -0.027720538899302483,
            -0.021043917164206505,
            -0.01509863417595625,
            -0.07048758119344711,
            -0.02765091322362423,
            -0.005128955934196711,
            0.009778555482625961,
            -0.002692852634936571,
            -0.028413085266947746,
            0.03287177160382271,
            -0.0024780924431979656,
            -0.016193823888897896,
            0.020045915618538857,
            -0.013387947343289852,
            -0.02149812877178192,
            -0.034963056445121765,
            0.008032932877540588,
            -0.006308259442448616,
            -0.02156013995409012,
            -0.013164500705897808,
            -0.008622206747531891,
            0.016159668564796448,
            -0.04004659876227379,
            0.0033385802526026964,
            0.0013565748231485486,
            -0.020704513415694237,
            -0.03513866662979126,
            -0.04424046725034714,
            -0.007680052425712347,
            0.012629506178200245,
            -0.050851594656705856,
            0.004333683755248785,
            -0.021640261635184288,
            0.007585613522678614,
            0.0035648641642183065,
            0.02666967175900936,
            0.02211957611143589,
            -0.03846903145313263,
            -0.005903054028749466,
            -0.022256717085838318,
            0.009640436619520187,
            -0.018545160070061684,
            -0.005369906779378653,
            0.030856460332870483,
            0.019362619146704674,
            -0.003733278252184391,
            -0.01268155500292778,
            0.021540319547057152,
            0.006889559794217348,
            -0.016525445505976677,
            -0.004232165403664112,
            0.007252223324030638,
            0.02152186632156372,
            0.006092182360589504,
            0.019648466259241104,
            0.011519378051161766,
            -0.0315985269844532,
            -0.013309923931956291,
            0.006053913850337267,
            0.01371198333799839,
            0.009574305266141891,
            -0.029406225308775902,
            -0.0155281201004982,
            -0.04858611524105072,
            0.008820070885121822,
            0.00629959674552083,
            0.03428947180509567,
            -0.0014705038629472256,
            -0.003963656257838011,
            -0.03606226667761803,
            0.0008425743435509503,
            -0.021451514214277267,
            0.00772543391212821,
            0.04351191967725754,
            -0.015597132034599781,
            -0.04025660455226898,
            -0.020743679255247116,
            -0.03195738419890404,
            -0.04666157066822052,
            -0.009173895232379436,
            -0.013801622204482555,
            0.005996043793857098,
            -0.010671161115169525,
            -0.024094119668006897,
            0.018521934747695923,
            -0.039176784455776215,
            -0.015387801453471184,
            0.021158533170819283,
            -0.013446308672428131,
            0.012997584417462349,
            -0.01809740625321865,
            0.03404159098863602,
            0.02895263396203518,
            -0.02226840890944004,
            0.001423634123057127,
            -0.001350419595837593,
            0.04806893318891525,
            0.03445813059806824,
            0.011304477229714394,
            -0.033611271530389786,
            0.0296933613717556,
            -0.0008244994678534567,
            -0.037941962480545044,
            0.011859501712024212,
            0.0035725568886846304,
            -0.047897327691316605,
            0.00018712555174715817,
            -0.01229186076670885,
            0.02824411354959011,
            -0.0033052009530365467,
            0.007951952517032623,
            0.0016604079864919186,
            0.011170938611030579,
            -0.024026591330766678,
            0.008992722257971764,
            0.02843080833554268,
            0.012203305028378963,
            -0.012804309837520123,
            0.014191307127475739,
            -0.021342631429433823,
            -0.004843721631914377,
            -0.026145633310079575,
            0.033857882022857666,
            -0.02091505564749241,
            0.00726484227925539,
            -0.008541444316506386,
            0.011505572125315666,
            0.034107036888599396,
            -0.013149525970220566,
            -0.041102707386016846,
            0.022261805832386017,
            -0.025925250723958015,
            -0.024545639753341675,
            -0.026430293917655945,
            -0.035079848021268845,
            0.0034289476461708546,
            0.005957845598459244,
            0.008672758936882019,
            0.0062974607571959496,
            0.006623716559261084,
            -0.014258782379329205,
            -0.012871906161308289,
            0.027304086834192276,
            -0.019754663109779358,
            -0.010331141762435436,
            0.026441184803843498,
            -0.007740788161754608,
            0.001408219221048057,
            -0.009788044728338718,
            0.028673073276877403,
            -0.016355741769075394,
            0.028818801045417786,
            0.008571295998990536,
            0.029732586815953255,
            -0.029743434861302376,
            0.014892170205712318,
            -0.0008313182624988258,
            0.0018933768151327968,
            -0.020794933661818504,
            0.028352707624435425,
            -0.008004426024854183,
            -0.017014112323522568,
            -0.051430538296699524,
            -0.024945858865976334,
            0.004938614554703236,
            0.012284137308597565,
            -0.02530248463153839,
            0.009217716753482819,
            -0.0065371813252568245,
            -0.014695512130856514,
            0.02249293215572834,
            0.03382645174860954,
            -0.023347485810518265,
            -0.0024691689759492874,
            0.017854833975434303,
            -0.003988884389400482,
            -0.030476737767457962,
            -0.02326687052845955,
            0.04045433551073074,
            -0.013291110284626484,
            -0.05233737453818321,
            -0.005868188571184874,
            0.04793672263622284,
            -0.011750061064958572,
            0.015030091628432274,
            0.014562316238880157,
            -0.042030125856399536,
            -0.024413814768195152,
            -0.001487202476710081,
            0.009821766056120396,
            0.0004679310950450599,
            -0.022801736369729042,
            0.016267726197838783,
            0.0069191488437354565,
            -0.004879791755229235,
            0.0006050870288163424,
            0.031917836517095566,
            -0.04765313118696213,
            0.027971532195806503,
            0.006881734821945429,
            0.006985384505242109,
            0.028443386778235435,
            0.005511557683348656,
            -0.06360156834125519,
            0.016324806958436966,
            0.01995924673974514,
            0.026062646880745888,
            -0.014434554614126682,
            -0.03924008831381798,
            -0.01168543566018343,
            0.009419584646821022,
            0.029624635353684425,
            0.0010905328672379255,
            0.0018585603684186935,
            -0.013106808066368103,
            0.008200638927519321,
            0.034285906702280045,
            -0.011408938094973564,
            0.008991826325654984,
            0.002360066631808877,
            -0.0030443593859672546,
            -0.002985898870974779,
            -0.005652375519275665,
            -0.00861268863081932,
            0.004071380011737347,
            -0.019294144585728645,
            0.009917578659951687,
            -0.0016067225951701403,
            0.005807020235806704,
            -0.03317224979400635,
            0.012925371527671814,
            0.022956231608986855,
            -0.030797423794865608,
            0.02128576673567295,
            -0.023810256272554398,
            0.03342301398515701,
            0.011417210102081299,
            -0.028428951278328896,
            -0.021616386249661446,
            -0.014633145183324814,
            -0.02636379934847355,
            -0.007816832512617111,
            -0.011258822865784168,
            -0.04330379515886307,
            -0.04382885619997978,
            0.026777563616633415,
            -0.03127996623516083,
            0.00398673303425312,
            -0.016403893008828163,
            0.05218828096985817,
            -0.03203117102384567,
            0.007333226501941681,
            -0.010119445621967316,
            0.011092711240053177,
            0.012723092921078205,
            -0.013366474770009518,
            -0.03871864080429077,
            0.006584262475371361,
            -0.015056155622005463,
            -0.015905000269412994,
            -0.03274334594607353,
            -0.016283167526125908,
            -0.044952984899282455,
            -0.013113956898450851,
            0.010542417876422405,
            -0.025128697976469994,
            0.015533949248492718,
            0.024464793503284454,
            -0.01823336072266102,
            -0.026189884170889854,
            -0.017128581181168556,
            -0.01974707841873169,
            -0.03744969516992569,
            -0.017174536362290382,
            -0.014583207666873932,
            0.026662806048989296,
            -0.021947912871837616,
            0.01833360455930233,
            -0.021052243188023567,
            -0.006143425591289997,
            -0.02142851985991001,
            0.013932224363088608,
            -0.0023916896898299456,
            0.006695679854601622,
            -0.025284525007009506,
            0.021308928728103638,
            0.001468294532969594,
            -0.01647702418267727,
            0.012902368791401386,
            -0.011395106092095375,
            0.02783307619392872,
            0.0007867051172070205,
            0.031229855492711067,
            -0.06855103373527527,
            0.0062472885474562645,
            -0.03843620419502258,
            0.009507092647254467,
            0.024717628955841064,
            -0.029641740024089813,
            0.011183738708496094,
            -0.015704315155744553,
            -0.015977073460817337,
            0.01669093407690525,
            0.009942397475242615,
            0.006689844187349081,
            -0.004317916464060545,
            -0.006729761604219675,
            0.01634555496275425,
            -0.029640214517712593,
            -0.02520454302430153,
            0.002012108452618122,
            -0.00031543293152935803,
            0.0027771659661084414,
            0.016214272007346153,
            0.0005516078672371805,
            -0.00026232886011712253,
            0.026933128014206886,
            0.008709910325706005,
            0.017724236473441124,
            -0.01589416153728962,
            0.020623650401830673,
            0.03163052722811699,
            0.005752450320869684,
            -0.03438502550125122,
            -0.010173432528972626,
            -0.01870402880012989,
            0.017634941264986992,
            0.009656157344579697,
            -0.01467770617455244,
            -0.002398455049842596,
            -0.020047565922141075,
            -0.011089231818914413,
            0.011374717578291893,
            -0.009009725414216518,
            -0.06078624352812767,
            -0.037658147513866425,
            -0.02013152651488781,
            -0.03332456946372986,
            0.006474792957305908,
            -0.01803424209356308,
            0.02385815605521202,
            -0.002386556239798665,
            -0.011820504441857338,
            -0.03780109062790871,
            -0.011594329960644245,
            0.040144409984350204,
            -0.014142939820885658,
            -0.0030567890498787165,
            -0.008807783015072346,
            -0.014069601893424988,
            0.014490747824311256,
            -0.01119485218077898,
            0.013064679689705372,
            -0.008414200507104397,
            0.03479393571615219,
            -0.029221434146165848,
            -0.03138512372970581,
            0.0014738517347723246,
            0.011547933332622051,
            -0.04219289869070053,
            -0.043685272336006165,
            -0.021860385313630104,
            -0.015852881595492363,
            0.028471805155277252,
            -0.0358271561563015,
            -0.004147727508097887,
            -0.0042794435285031796,
            -0.0026712403632700443,
            -0.017781751230359077,
            -0.023998724296689034,
            0.02910807356238365,
            -0.021225273609161377,
            -0.056179337203502655,
            0.03147691860795021,
            -0.00812019407749176,
            -0.00036157623981125653,
            0.033123575150966644,
            0.008420953527092934,
            -0.013272807002067566,
            -0.011908741667866707,
            -0.010006760247051716,
            0.028649277985095978,
            0.0018757887883111835,
            -0.016149209812283516,
            -0.04259362444281578,
            -0.020717952400445938,
            0.009572651237249374,
            -0.01641036383807659,
            0.006541535258293152,
            0.007856725715100765,
            -0.0006901179440319538,
            -0.0169854536652565,
            -0.00033044980955310166,
            -0.010851974599063396,
            0.011654880829155445,
            -0.0549791157245636,
            -0.05118640884757042,
            -0.046379465609788895,
            0.012590271420776844,
            -0.005935479886829853,
            0.005494223907589912,
            0.021861575543880463,
            -0.02880263701081276,
            -0.030457381159067154,
            -0.022371355444192886,
            0.03243443742394447,
            -0.03136986494064331,
            0.009229467250406742,
            -0.02148880623281002,
            0.028232328593730927,
            0.0014995711389929056,
            -0.01608889549970627,
            0.018368542194366455,
            -0.009658041410148144,
            -0.030861329287290573,
            0.009306695312261581,
            -0.036239366978406906,
            -0.006794847082346678,
            -0.007936988957226276,
            0.02686430886387825,
            0.01734337955713272,
            0.019414352253079414,
            0.003133560763671994,
            -0.028812097385525703,
            0.009989624843001366,
            -0.019593339413404465,
            0.011390788480639458,
            -0.03868979960680008,
            0.036156557500362396,
            0.013753182254731655,
            -0.017644332721829414,
            -0.05573653802275658,
            -0.0003696611092891544,
            0.01596861146390438,
            0.03594281151890755,
            -0.01713969185948372,
            -0.012195519171655178,
            -0.018887821584939957,
            0.021967843174934387,
            -0.01522750873118639,
            0.00350781949236989,
            0.033492960035800934,
            -0.030715705826878548,
            0.0059067253023386,
            0.016231494024395943,
            -0.021202517673373222,
            0.011720881797373295,
            -0.012731019407510757,
            0.004468122497200966,
            0.004210701212286949,
            0.0194302536547184,
            -0.022868257015943527,
            -0.015965450555086136,
            0.027432700619101524,
            0.029410356655716896,
            0.0017089437460526824,
            -0.0045369695872068405,
            -0.027593471109867096,
            -0.008486135862767696,
            -0.013010883703827858,
            -0.011036078445613384,
            0.03410954028367996,
            -0.0017100711120292544,
            0.010632586665451527,
            0.0013008882524445653,
            0.027497483417391777,
            -0.008490698412060738,
            0.005060914438217878,
            -0.020310673862695694,
            0.017024457454681396,
            -0.01358353067189455,
            -0.031235849484801292,
            -0.041593264788389206,
            0.006379363127052784,
            -0.0054177637211978436,
            0.014437544159591198,
            0.002241564216092229,
            -0.01640826091170311,
            -0.004190823063254356,
            0.0005354493041522801,
            0.01442360132932663,
            0.021086102351546288,
            0.016088565811514854,
            0.01376358699053526,
            0.024900835007429123,
            -0.0063792322762310505,
            0.019420694559812546,
            0.014235688373446465,
            0.005102608818560839,
            -0.011840195395052433,
            -0.044212497770786285,
            -0.027651671320199966,
            -0.05028063431382179,
            0.012765977531671524,
            -0.002866110997274518,
            -0.02350459061563015,
            0.010544775053858757,
            -0.026262039318680763,
            -0.03112703748047352,
            0.007612492889165878,
            0.026459410786628723,
            -0.018780333921313286,
            -0.004431902430951595,
            -0.005321321077644825,
            0.013789127580821514,
            -0.03122570365667343,
            0.02038801647722721,
            -0.013114535249769688,
            -0.006513846572488546,
            -0.02725262939929962,
            -0.0033943403977900743,
            0.0030499391723424196,
            0.0242195762693882,
            -0.03288709744811058,
            -0.017415128648281097,
            0.022174328565597534,
            -0.0033561468590050936,
            -0.0179945956915617,
            0.0009767733281478286,
            0.013573447242379189,
            -0.02801237814128399,
            -0.004762515425682068,
            0.029071521013975143,
            0.010996367782354355,
            -0.007763093337416649,
            -0.011945758014917374,
            0.012862727046012878,
            -0.016474099829792976,
            0.01731140725314617,
            0.003331576706841588,
            -0.023422013968229294,
            -0.036053307354450226,
            -0.059794891625642776,
            -0.024183815345168114,
            -0.008990412577986717,
            0.02541651763021946,
            0.03638235107064247,
            -0.025479594245553017,
            -0.01834946870803833,
            -0.005261344835162163,
            0.011707833968102932,
            0.004270028788596392,
            0.025667410343885422,
            0.009749907068908215,
            0.029520057141780853,
            -0.011357740499079227,
            0.015014957636594772,
            -0.022491738200187683,
            0.027333160862326622,
            0.0004541596572380513,
            0.002019161358475685,
            0.013269111514091492,
            -0.006377820856869221,
            -0.0025759954005479813,
            -0.010217191651463509,
            0.01315221656113863,
            -0.007833748124539852,
            -0.008180495351552963,
            -0.022715765982866287,
            -0.00485839881002903,
            -0.02179199829697609,
            -0.006423108745366335,
            0.01905703917145729,
            -0.01893077790737152,
            0.026425346732139587,
            0.00830598920583725,
            0.013520213775336742,
            0.029821032658219337,
            -0.012961813248693943,
            -0.013099049217998981,
            0.014946872368454933,
            0.009040262550115585,
            0.001171028590761125,
            -0.007161013316363096,
            -0.005647902376949787,
            -0.007480164058506489,
            -0.0042257243767380714,
            0.027029646560549736,
            -0.03149052709341049,
            -0.028179526329040527,
            -0.0057155899703502655,
            -0.00767614645883441,
            0.03550771251320839,
            -0.04557561129331589,
            0.021437378600239754,
            0.025576036423444748,
            0.001734535675495863,
            -0.04138815775513649,
            -0.044505905359983444,
            -0.017190804705023766,
            -0.022517049685120583,
            -0.007485906593501568,
            0.0016970885917544365,
            0.02359422855079174,
            0.0453517884016037,
            0.0010435027070343494,
            0.02666664496064186,
            0.03287985920906067,
            -0.019108587875962257,
            0.02542276307940483,
            0.027748407796025276,
            -0.04692951962351799,
            -0.01050620898604393,
            -0.007493339478969574,
            -0.0140978479757905,
            0.01802091673016548,
            0.025115495547652245,
            -0.014306982979178429,
            0.0227055661380291,
            0.01866883970797062,
            -0.0004047440306749195,
            -0.018944742158055305,
            0.009576740674674511,
            0.02092309668660164,
            -0.015335862524807453,
            -0.0010180891258642077,
            -0.029615579172968864,
            -0.06591539084911346,
            0.01730465702712536,
            0.009670168161392212,
            -0.02207729034125805,
            -0.006637056823819876,
            0.02464289404451847,
            0.01396411843597889,
            0.011108324863016605,
            0.03380740061402321,
            0.01809820719063282,
            0.02877473086118698,
            0.020808853209018707,
            -0.01622498407959938,
            -0.025423331186175346,
            -0.014284909702837467,
            0.004585603252053261,
            -0.020035285502672195,
            0.013092344626784325,
            0.000524016679264605,
            0.014688997529447079,
            0.029174547642469406,
            0.011543438769876957,
            0.0100925974547863,
            0.019405100494623184,
            0.03818036988377571,
            0.005783502943813801,
            -0.04617500305175781,
            0.0021172387059777975,
            -0.013392980210483074,
            0.02480420470237732,
            -0.034140076488256454,
            -0.022612346336245537,
            0.030127929523587227,
            0.027530383318662643,
            -0.04945338889956474,
            0.005127298645675182,
            0.008327165618538857,
            0.010859426110982895,
            0.01147585827857256,
            0.019401073455810547,
            -0.006896347273141146,
            -0.022602958604693413,
            -0.034667547792196274,
            -0.019166046753525734,
            0.011860680766403675,
            -0.028975125402212143,
            -0.00971032865345478,
            0.021403834223747253,
            -0.02819157764315605,
            -0.010948670096695423,
            0.012482219375669956,
            -0.02350483275949955,
            0.014843574725091457,
            -0.050119541585445404,
            0.015451381914317608,
            0.015895729884505272,
            0.007001102436333895,
            -0.03727332875132561,
            0.03117881342768669,
            0.015231396071612835,
            -0.0038073372561484575,
            -0.03158160671591759,
            -0.01744387298822403,
            -0.022696204483509064,
            -0.026645410805940628,
            0.009889633394777775,
            -0.00733385793864727,
            -0.012678496539592743,
            0.012137052603065968,
            0.005485307890921831,
            0.001001632073894143,
            -0.010255614295601845,
            -0.01670602336525917,
            0.006075308658182621,
            -0.024761535227298737,
            0.0001163774577435106,
            0.008281960152089596,
            0.010065452195703983,
            -0.03274312987923622,
            -0.02340058796107769,
            0.018625659868121147,
            -0.02438751608133316,
            0.022860979661345482,
            0.01581467315554619,
            -0.03541897237300873,
            -0.01911182515323162,
            0.03606562688946724,
            -0.027897527441382408,
            -0.0578218437731266,
            -0.034400105476379395,
            -0.004872186109423637,
            -0.019424889236688614,
            0.006312658544629812,
            0.022907178848981857,
            0.021292613819241524,
            0.02770809456706047,
            -0.012335103005170822,
            0.04026978090405464,
            -0.022169768810272217,
            0.028400728479027748,
            -0.032790906727313995,
            0.023564189672470093,
            0.016218045726418495,
            -0.020244449377059937,
            0.026527343317866325,
            0.024731727316975594,
            -0.03525242209434509,
            0.023418426513671875,
            -0.029361996799707413,
            0.03855219855904579,
            -0.0009979092283174396,
            0.01681441441178322,
            0.004669028799980879,
            -0.03296441584825516,
            0.000352206319803372,
            -0.008182059042155743,
            0.03068646788597107,
            0.016383467242121696,
            -0.010248296894133091,
            -0.019778721034526825,
            0.020986534655094147,
            0.023108256980776787,
            -0.009974345564842224,
            -0.016961174085736275,
            -0.018472563475370407,
            0.011447715573012829,
            -0.011273308657109737,
            -0.0018167130183428526,
            0.028032800182700157,
            -0.009192286990582943,
            0.009475238621234894,
            -0.04417646303772926,
            0.01362451259046793,
            0.031341034919023514,
            0.02670055441558361,
            0.00354279694147408,
            -0.03795627877116203,
            0.03518155217170715,
            -0.004232065286487341,
            0.024341342970728874,
            -0.019035354256629944,
            -0.020605947822332382,
            0.018315676599740982,
            0.033659085631370544,
            0.033114396035671234,
            0.02446243166923523,
            -0.030925434082746506,
            0.028443701565265656,
            -0.02320476435124874,
            0.017603861168026924,
            0.01471622847020626,
            -0.017074130475521088,
            0.004786069504916668,
            0.012196692638099194,
            -0.05488196760416031,
            -0.02509346418082714,
            0.019323302432894707,
            -0.012263020500540733,
            -0.01983402855694294,
            0.019871199503540993,
            -0.0033433916978538036,
            0.005849354434758425,
            0.0073076575063169,
            -0.007195016834884882,
            -0.021613914519548416,
            -0.03300675377249718,
            0.03027777187526226,
            0.014531298540532589,
            -0.05799863860011101,
            0.005602884106338024,
            0.03041582554578781,
            -0.03463616967201233,
            -0.00022616810747422278,
            -0.01983690820634365,
            0.006953119765967131,
            0.009437397122383118,
            -0.024467121809720993,
            0.003195027355104685,
            -0.016852445900440216,
            -0.012263219803571701,
            0.023984238505363464,
            -0.01346838753670454,
            0.0011469413293525577,
            0.008967703208327293,
            -0.018758289515972137,
            0.023488778620958328,
            -0.029024511575698853,
            -0.05587432533502579,
            -0.01761719398200512,
            0.024363018572330475,
            0.025566764175891876,
            0.011468000710010529,
            0.033106282353401184,
            -0.03242868930101395,
            0.030592944473028183,
            0.01660776324570179,
            -0.009882545098662376,
            0.00875302217900753,
            0.0026032752357423306,
            0.022611234337091446,
            -0.010535790584981441,
            0.008387218229472637,
            -0.02760106511414051,
            -0.00017349848349113017,
            -0.04632407799363136,
            0.03127547726035118,
            -0.011917723342776299,
            0.018708793446421623,
            0.0206803847104311,
            -0.04609208554029465,
            0.0231802798807621,
            -0.0038246302865445614,
            0.006525054108351469,
            0.03006371483206749,
            -0.00784220453351736,
            -0.04052722454071045,
            -0.03870747610926628,
            0.022829599678516388,
            -0.012393184006214142,
            0.001507596462033689,
            0.015525998547673225,
            0.018036283552646637,
            -0.0197150819003582,
            0.008481075055897236,
            -0.0038676403928548098,
            0.0017378447810187936,
            0.013713040389120579,
            0.03319045156240463,
            -0.014458024874329567,
            -0.004101553466171026,
            0.06456898152828217,
            -0.009558144956827164,
            0.023722875863313675,
            0.030112726613879204,
            0.02042795717716217,
            0.04463053122162819,
            0.008366570807993412,
            0.01346281636506319,
            0.029264913871884346,
            0.018873175606131554,
            0.0019151371670886874,
            0.015344609506428242,
            0.007669894956052303,
            -0.026613160967826843,
            0.0007544109830632806,
            0.013005424290895462,
            0.008083842694759369,
            -0.011908256448805332,
            0.01650310680270195,
            -0.028232557699084282,
            -0.07056936621665955,
            -0.04194667935371399,
            -0.003488780464977026,
            0.026704607531428337,
            0.01677023060619831,
            0.014069641008973122,
            -0.02940785326063633,
            0.03057730756700039,
            0.0032833493314683437,
            -0.009273429401218891,
            0.02434132806956768,
            0.009372827596962452,
            0.03211985155940056,
            -0.01632561720907688,
            0.013618916273117065,
            -0.033240802586078644,
            -0.026996273547410965,
            0.03390168398618698,
            -0.033157359808683395,
            -0.013192033395171165,
            0.0010712312068790197,
            0.02319936268031597,
            -0.02639080211520195,
            0.027444392442703247,
            0.0028669501189142466,
            -0.003955595660954714,
            -0.03269689530134201,
            0.02269020862877369
        ],
        [
            -0.03652600944042206,
            -0.005577558651566505,
            -0.013783824630081654,
            0.027656737715005875,
            -0.013660509139299393,
            0.019320158287882805,
            -0.022426901385188103,
            0.02074347622692585,
            0.03669198974967003,
            -0.06356598436832428,
            0.047500576823949814,
            -0.008932847529649734,
            0.02615264430642128,
            0.016847020015120506,
            -0.05436892434954643,
            -0.07895499467849731,
            -0.05136389657855034,
            0.018590714782476425,
            -0.02794363535940647,
            -0.0009677903144620359,
            -0.01823989860713482,
            -0.008086659014225006,
            0.04380621761083603,
            0.03730185329914093,
            -0.004588482901453972,
            -0.010667209513485432,
            0.020650912076234818,
            0.054518479853868484,
            0.02115802653133869,
            0.023203672841191292,
            -0.0354517363011837,
            0.01292696874588728,
            -0.0347798615694046,
            -0.0051334151066839695,
            0.02559586614370346,
            -0.013547643087804317,
            -0.016717316582798958,
            -0.028675874695181847,
            -0.010793443769216537,
            -0.02937733195722103,
            0.010320139117538929,
            0.02925502322614193,
            -0.02008463814854622,
            0.007200617343187332,
            -0.03920528665184975,
            0.00214117718860507,
            0.0038909248542040586,
            -0.014035996980965137,
            -0.0031820167787373066,
            -0.014409402385354042,
            -0.032618198543787,
            0.011252583004534245,
            -0.015678074210882187,
            0.005535867065191269,
            0.033733490854501724,
            0.028356993570923805,
            -0.0017789534758776426,
            -0.07051882892847061,
            0.02739931084215641,
            -0.0021137548610568047,
            -0.009077110327780247,
            0.00262512918561697,
            0.007255971897393465,
            -0.007031710352748632,
            0.003843985963612795,
            0.028767408803105354,
            -0.04404769092798233,
            0.01968907192349434,
            0.011141310445964336,
            -0.011993126943707466,
            -0.0603053905069828,
            -0.009222200140357018,
            0.016288230195641518,
            -0.01404632069170475,
            -0.013687320053577423,
            0.01759631186723709,
            -0.019503701478242874,
            0.019640808925032616,
            -0.009551734663546085,
            -0.038148704916238785,
            -0.0020332972053438425,
            -0.02233361452817917,
            -0.03642281889915466,
            0.0211744774132967,
            -0.017561452463269234,
            -0.013320239260792732,
            0.037160810083150864,
            -0.003983831498771906,
            -0.039350032806396484,
            -0.0021436673123389482,
            0.0035388765390962362,
            -0.0259474478662014,
            -0.0008578276610933244,
            -0.012932750396430492,
            0.023556789383292198,
            -0.036708638072013855,
            0.010430087335407734,
            0.01957336999475956,
            -0.03912805765867233,
            -0.0037546600215137005,
            0.028598831966519356,
            0.009920037351548672,
            -0.0193838682025671,
            -0.0007977208588272333,
            -0.003583303652703762,
            -0.015397164039313793,
            -0.011428900063037872,
            -0.0041918945498764515,
            0.020731141790747643,
            0.020324449986219406,
            -0.013064289465546608,
            -0.022302374243736267,
            -0.014920838177204132,
            -0.011100087314844131,
            0.007786732167005539,
            -0.0177462138235569,
            -0.008628160692751408,
            0.01410211343318224,
            0.033281855285167694,
            -0.03947855904698372,
            -0.012818273156881332,
            -0.015686389058828354,
            0.005881758872419596,
            -0.013811592012643814,
            -0.0068680718541145325,
            0.006722251884639263,
            0.0361001119017601,
            -0.0021148785017430782,
            0.017767461016774178,
            0.03743060678243637,
            0.03347860276699066,
            0.007102261763066053,
            -0.04441414028406143,
            0.04020692780613899,
            -0.007784932851791382,
            -0.0240032896399498,
            0.021894922479987144,
            0.0025433029513806105,
            -0.02998388558626175,
            -0.014618098735809326,
            -0.011628915555775166,
            -0.019910093396902084,
            0.011571870185434818,
            0.002466684440150857,
            0.02356943115592003,
            0.03578965738415718,
            -0.02385684847831726,
            0.02765786647796631,
            -0.0016560058575123549,
            -0.06755805015563965,
            -0.0012873634696006775,
            -0.039969783276319504,
            0.02191786840558052,
            -0.003292048117145896,
            0.02527463063597679,
            0.007626362610608339,
            0.044023238122463226,
            -0.030532773584127426,
            -0.002267468487843871,
            0.03612947091460228,
            -0.02314240112900734,
            -0.018710728734731674,
            -0.02587338164448738,
            -0.011526606045663357,
            0.014840933494269848,
            -0.0024868992622941732,
            -0.03697358816862106,
            -0.0011177686974406242,
            0.02469887211918831,
            -0.013607333414256573,
            -0.02472148835659027,
            0.02481665089726448,
            -0.005768924951553345,
            0.020944004878401756,
            0.02938443049788475,
            -0.03463535010814667,
            0.02801668643951416,
            0.024386044591665268,
            0.040431126952171326,
            0.012965335510671139,
            0.01736576110124588,
            0.04246453940868378,
            -0.0186977069824934,
            -0.014401782304048538,
            -0.024110164493322372,
            0.015653865411877632,
            -0.008772483095526695,
            -0.0002546300820540637,
            -0.037600163370370865,
            -0.02747313492000103,
            -0.02985900640487671,
            -0.007177240215241909,
            0.04072505608201027,
            0.0333806611597538,
            0.017441317439079285,
            -0.024130098521709442,
            -0.0006142951897345483,
            0.0031269099563360214,
            -0.07090132683515549,
            0.019830865785479546,
            0.02056146413087845,
            0.005513802170753479,
            -0.015890980139374733,
            0.01953289657831192,
            -0.02157905139029026,
            -0.038541413843631744,
            0.0722251757979393,
            -0.02029070444405079,
            0.034412771463394165,
            0.013501299545168877,
            0.018167447298765182,
            -0.03414206579327583,
            0.055415503680706024,
            -0.0009023917373269796,
            -0.06116689741611481,
            0.005778092425316572,
            0.0042827194556593895,
            0.023868992924690247,
            -0.006759887095540762,
            -0.014421072788536549,
            -0.03951522707939148,
            -0.001961278961971402,
            -0.003474979195743799,
            -0.014962680637836456,
            -0.02632787823677063,
            -0.002381609519943595,
            -0.019361434504389763,
            -0.05248508229851723,
            0.02152005024254322,
            -0.011394350789487362,
            0.02223948761820793,
            0.02644019015133381,
            0.013061014004051685,
            -0.01045775506645441,
            0.015366967767477036,
            0.008695933036506176,
            0.0380537286400795,
            0.03166455030441284,
            0.012261747382581234,
            -0.04161246865987778,
            -0.014418925158679485,
            -0.005307856481522322,
            -0.014729481190443039,
            -0.031730640679597855,
            -0.014159056358039379,
            -0.007668026257306337,
            0.010334871709346771,
            0.0037362403236329556,
            -0.03945723921060562,
            0.006553582847118378,
            -0.05688401684165001,
            0.014314861968159676,
            0.011694819666445255,
            -0.012408740818500519,
            -0.01060312706977129,
            -0.020354734733700752,
            -0.022437436506152153,
            -0.029393693432211876,
            0.001747530186548829,
            -0.005965729709714651,
            0.03788577765226364,
            0.030117999762296677,
            -0.011421126313507557,
            0.00036855408689007163,
            -0.02157212607562542,
            -0.004088582471013069,
            -0.005070447456091642,
            -0.00559653015807271,
            -0.012988167814910412,
            0.0042221155017614365,
            0.015739988535642624,
            0.03729791194200516,
            0.00573227321729064,
            0.014456674456596375,
            -0.012859667651355267,
            0.02052978053689003,
            -0.06015804037451744,
            -0.011075473390519619,
            -0.008424965664744377,
            -0.00997641310095787,
            0.044214457273483276,
            -0.0059682829305529594,
            -0.02789708413183689,
            0.019967062398791313,
            -0.03934098780155182,
            0.016678741201758385,
            -0.0062460764311254025,
            -0.07246190309524536,
            -0.008224553428590298,
            0.002948729321360588,
            -0.029281318187713623,
            -0.030098935589194298,
            -0.05349845811724663,
            -0.01380952075123787,
            0.01922774128615856,
            -0.003479505656287074,
            0.033695828169584274,
            0.011143133975565434,
            0.011782454326748848,
            -0.01817437633872032,
            0.04427399858832359,
            0.02013406902551651,
            -0.0016276466194540262,
            0.006223962642252445,
            0.015584388747811317,
            -0.0366428978741169,
            -0.010853983461856842,
            0.03950723633170128,
            -0.002198451431468129,
            -0.026685399934649467,
            0.044857513159513474,
            0.031259093433618546,
            0.032187920063734055,
            -0.004457348026335239,
            0.028106553480029106,
            0.02043783664703369,
            -0.017753608524799347,
            0.017057036980986595,
            0.0067571536637842655,
            -0.013608459383249283,
            -0.030833590775728226,
            -0.0345674604177475,
            0.008347622118890285,
            0.04155497997999191,
            -0.01964404247701168,
            -0.025128407403826714,
            0.029032934457063675,
            0.00182077765930444,
            0.0003107638913206756,
            0.01892911270260811,
            0.01032515149563551,
            -0.01950356550514698,
            -0.04242532700300217,
            -0.006025812588632107,
            0.02935144677758217,
            -0.0003030656953342259,
            -0.05632638931274414,
            0.032446254044771194,
            -0.00806805957108736,
            -0.04293951392173767,
            -0.007060943637043238,
            0.061080753803253174,
            0.02491833083331585,
            -0.012938179075717926,
            0.02817663736641407,
            0.0013479974586516619,
            0.02105589769780636,
            0.022511325776576996,
            0.0015334843192249537,
            0.030140792950987816,
            -0.02928248606622219,
            -0.02587040327489376,
            -0.06526739150285721,
            -0.0080901263281703,
            0.014931246638298035,
            0.012122866697609425,
            -0.025390300899744034,
            0.01769798994064331,
            0.008952892385423183,
            -0.05514180660247803,
            -0.04516465589404106,
            -0.021795187145471573,
            0.023997368291020393,
            0.016200702637434006,
            -0.003779542399570346,
            -0.044337015599012375,
            -0.018226079642772675,
            -0.00928803626447916,
            -0.028004206717014313,
            0.021085679531097412,
            -0.03919590264558792,
            -0.009652060456573963,
            0.053682900965213776,
            -0.015931706875562668,
            0.035420577973127365,
            0.06405908614397049,
            -0.060343287885189056,
            0.028276609256863594,
            0.01154487207531929,
            -0.01919197104871273,
            0.022033188492059708,
            -0.015098895877599716,
            0.01930924504995346,
            -0.019210772588849068,
            0.0010716962860897183,
            0.035745661705732346,
            0.005545911379158497,
            0.034663937985897064,
            -0.0067078606225550175,
            0.010942812077701092,
            0.021581489592790604,
            -0.008194958791136742,
            0.016973700374364853,
            0.01420507300645113,
            -0.0010937484912574291,
            -0.0006117912125773728,
            0.022707607597112656,
            -0.0019992650486528873,
            -0.03948960453271866,
            -0.040040746331214905,
            0.012502281926572323,
            0.005171880591660738,
            -0.007617739960551262,
            -0.0013949538115411997,
            0.0016126609407365322,
            0.03139594569802284,
            -0.013533534482121468,
            -0.005043005105108023,
            0.016867103055119514,
            -0.03877895697951317,
            -0.009425001218914986,
            -0.025362782180309296,
            0.049207113683223724,
            0.00909899640828371,
            0.04998354613780975,
            0.0023391321301460266,
            -0.0471014566719532,
            -0.013786026276648045,
            -0.04152613505721092,
            -0.042007919400930405,
            -0.012578209862112999,
            0.02843943051993847,
            -0.11154685169458389,
            -0.024415114894509315,
            -0.00036805454874411225,
            -0.030091090127825737,
            0.002006718423217535,
            -0.014596058055758476,
            -0.013914595358073711,
            -0.02255459688603878,
            -0.018803982064127922,
            0.011992155574262142,
            0.012365142814815044,
            0.05532417073845863,
            0.0007679268019273877,
            -0.032369520515203476,
            -0.017635459080338478,
            0.010624486953020096,
            0.027109166607260704,
            0.008222324773669243,
            0.034888580441474915,
            -0.046941179782152176,
            -0.0003258768701925874,
            -0.019485166296362877,
            0.005182041320949793,
            -0.006059201899915934,
            -0.0039455462247133255,
            -0.09170670062303543,
            -0.011753097176551819,
            0.021916333585977554,
            0.024424074217677116,
            -0.026226183399558067,
            -0.012308378703892231,
            0.003227346809580922,
            0.03197838366031647,
            0.020276445895433426,
            -0.04033380746841431,
            0.019904376938939095,
            0.0017747755628079176,
            0.025041524320840836,
            0.050743069499731064,
            -0.03341392055153847,
            -0.016075696796178818,
            -0.0335213728249073,
            0.021792219951748848,
            -0.005720586981624365,
            0.002851030556485057,
            0.01375876646488905,
            -0.008737167343497276,
            0.03725263848900795,
            0.019212523475289345,
            -0.010557547211647034,
            0.02965632453560829,
            -0.031323712319135666,
            0.01132090762257576,
            0.024155467748641968,
            0.012321327812969685,
            0.011098618619143963,
            0.02474377490580082,
            0.03295990824699402,
            0.005822054110467434,
            -0.056114573031663895,
            0.019528135657310486,
            -0.0016334621468558908,
            -0.011110890656709671,
            -0.013096516951918602,
            -0.01602921448647976,
            -0.02002071402966976,
            0.08690375834703445,
            0.04413320869207382,
            -0.02377372793853283,
            -0.02613667957484722,
            -0.042715299874544144,
            0.01656424254179001,
            -0.07691703736782074,
            -0.04991371929645538,
            0.012214032001793385,
            -0.0003963609633501619,
            -0.002426613587886095,
            -0.05154979228973389,
            0.03206843137741089,
            0.010146062821149826,
            -0.0035189001355320215,
            0.04340251907706261,
            -0.026019519194960594,
            0.0006891055381856859,
            -0.009493891149759293,
            0.009878910146653652,
            -0.0026804038789123297,
            0.030404379591345787,
            0.00732890609651804,
            0.050791095942258835,
            -0.01687012054026127,
            -0.018516385927796364,
            0.007709830068051815,
            0.021527212113142014,
            0.030239438638091087,
            -0.00359056843444705,
            -0.011115653440356255,
            0.02354842610657215,
            -0.02349919080734253,
            -0.05372827500104904,
            -0.027048297226428986,
            0.023967411369085312,
            -0.026916900649666786,
            0.01442934200167656,
            0.02312115766108036,
            -0.017506321892142296,
            0.016214298084378242,
            0.013983873650431633,
            -0.006260198540985584,
            0.010284884832799435,
            -0.023615114390850067,
            -0.005219427868723869,
            0.011736597865819931,
            0.0753958597779274,
            -0.0146873714402318,
            -0.0012392272474244237,
            -0.0005636618589051068,
            -0.031008007004857063,
            -0.006316786166280508,
            -0.03171539679169655,
            0.004830371122807264,
            0.00424201600253582,
            -0.01255885511636734,
            -0.019573012366890907,
            -0.00881079863756895,
            0.04022347927093506,
            -0.03244831785559654,
            0.009377275593578815,
            -0.009412778541445732,
            -0.030076079070568085,
            -0.013457585126161575,
            0.0313652902841568,
            0.02091125026345253,
            -0.0224943608045578,
            0.03455212712287903,
            -0.007605727296322584,
            -0.013060793280601501,
            -0.02130620740354061,
            -0.026027904823422432,
            -0.06007887050509453,
            0.014458159916102886,
            -0.036771103739738464,
            0.012881542555987835,
            0.009352363646030426,
            0.02342940680682659,
            -0.0063029383309185505,
            -0.0050120241940021515,
            0.003105186391621828,
            -0.013472473248839378,
            0.016045324504375458,
            0.006330103613436222,
            -0.019941378384828568,
            0.02396700344979763,
            0.01129504106938839,
            -0.0068390462547540665,
            0.012364117428660393,
            -0.015125865116715431,
            0.0003299904346931726,
            0.019912224262952805,
            0.029046151787042618,
            -0.0055811419151723385,
            0.005441219080239534,
            0.02206294983625412,
            -0.004689565859735012,
            0.02988716959953308,
            0.0017194804968312383,
            0.009627492167055607,
            0.0034705735743045807,
            0.002123412676155567,
            0.007525564171373844,
            -0.005856265313923359,
            0.057790227234363556,
            -0.011499615386128426,
            0.00048650437383912504,
            0.01515537966042757,
            0.00795178022235632,
            0.007939521223306656,
            -0.0012164004147052765,
            0.057581301778554916,
            0.005650864448398352,
            -0.023890387266874313,
            -0.017904702574014664,
            0.021653922274708748,
            0.031055444851517677,
            -0.019760016351938248,
            0.016919370740652084,
            -0.018079403787851334,
            -0.04470663517713547,
            -0.04073010012507439,
            0.0014381592627614737,
            0.016231367364525795,
            -0.005943497642874718,
            0.01291002705693245,
            -0.0037348438054323196,
            0.00674313073977828,
            0.01868300698697567,
            -0.007145380135625601,
            0.0009739917586557567,
            -0.0018977043218910694,
            0.010662046261131763,
            -0.005058038979768753,
            0.007610937114804983,
            -0.018189726397395134,
            -0.016683898866176605,
            -0.028371352702379227,
            -0.01177095714956522,
            -0.01075542252510786,
            0.02372441440820694,
            -0.018582142889499664,
            -0.016333822160959244,
            -0.005379504058510065,
            -0.0017442436655983329,
            0.01904594525694847,
            -0.014196070842444897,
            -0.03967827931046486,
            0.033597130328416824,
            -0.006473890971392393,
            -0.04041401669383049,
            -0.020000120624899864,
            0.0627301037311554,
            -0.008105586282908916,
            -0.0186955314129591,
            0.015884198248386383,
            -0.018841739743947983,
            0.005439954809844494,
            0.0005809126305393875,
            -0.029302578419446945,
            0.019613265991210938,
            0.013994181528687477,
            0.030186599120497704,
            0.020012883469462395,
            0.022096889093518257,
            0.03718414530158043,
            0.02656325325369835,
            0.0676734447479248,
            -0.01169686857610941,
            0.015808532014489174,
            -0.01687939651310444,
            0.001376901171170175,
            0.0271245576441288,
            0.004556701052933931,
            -0.06305812299251556,
            -0.014814811758697033,
            0.006469913758337498,
            0.002642423612996936,
            -0.008030342869460583,
            0.021199306473135948,
            -0.013662789016962051,
            -0.059063784778118134,
            0.023097744211554527,
            -0.015009501948952675,
            -0.018547195941209793,
            -0.002361950231716037,
            0.0006281508831307292,
            0.00814585480839014,
            0.0019794919062405825,
            -0.013435042463243008,
            0.013659510761499405,
            0.010763678699731827,
            0.0004925128887407482,
            -0.013410842046141624,
            0.036109164357185364,
            -0.021265506744384766,
            -0.011348787695169449,
            0.0064373076893389225,
            0.02896181493997574,
            -0.03514780476689339,
            0.022166918963193893,
            0.02405134029686451,
            0.010747896507382393,
            0.008287834003567696,
            0.007402503862977028,
            -0.009181113913655281,
            0.05775244161486626,
            0.004832581616938114,
            -0.007442578207701445,
            0.0358116440474987,
            -0.01902887597680092,
            -0.01581902988255024,
            0.048749636858701706,
            -0.01482947077602148,
            0.019432419911026955,
            -0.018104666844010353,
            -0.03421555459499359,
            -0.016940318048000336,
            -0.01216026023030281,
            0.02412116341292858,
            0.029811887070536613,
            0.015288873575627804,
            -0.024160919710993767,
            -0.043262600898742676,
            -0.007132514845579863,
            0.0361732617020607,
            0.001120686181820929,
            -0.023625560104846954,
            -0.002012865385040641,
            0.032402168959379196,
            -0.0038894815370440483,
            -0.015805194154381752,
            -0.006419890094548464,
            -0.033323030918836594,
            -0.02274460159242153,
            -0.01740136742591858,
            0.04106052964925766,
            -0.006469381507486105,
            -0.0006201020441949368,
            0.005886856000870466,
            0.02656620368361473,
            -0.015517238527536392,
            -0.020523246377706528,
            0.032480109483003616,
            0.015341105870902538,
            -0.01937026157975197,
            -0.001584021607413888,
            0.010153760202229023,
            0.002825011033564806,
            0.014011801220476627,
            -0.008197561837732792,
            -0.008543637581169605,
            0.030453775078058243,
            -0.033868927508592606,
            -0.011005944572389126,
            0.005450334399938583,
            -0.03896411880850792,
            -0.025523003190755844,
            -0.019838688895106316,
            0.006141068413853645,
            -0.014396344311535358,
            -0.012944729067385197,
            0.008427565917372704,
            0.01516095269471407,
            0.03919035941362381,
            0.016428476199507713,
            -0.021663514897227287,
            -0.010847694240510464,
            -0.04737779125571251,
            -0.026261089369654655,
            0.009802027605473995,
            0.004282571841031313,
            0.060089386999607086,
            -0.03360936418175697,
            -0.022771181538701057,
            0.0019384704064577818,
            0.04266693443059921,
            0.0008644075132906437,
            -0.0038849639240652323,
            0.0011819839710369706,
            0.004399801604449749,
            -0.001269629574380815,
            -0.006438617594540119,
            0.01194909680634737,
            -0.001303590484894812,
            -0.00760229816660285,
            -0.01863548345863819,
            0.019659308716654778,
            -0.00038877796032465994,
            -0.0006152491550892591,
            -0.005876601208001375,
            0.022280516102910042,
            0.00603057025000453,
            0.038857702165842056,
            -0.02102871984243393,
            0.016466176137328148,
            0.008466092869639397,
            0.013608675450086594,
            -0.00029129517497494817,
            -0.013225573115050793,
            -0.02014322578907013,
            -0.004453340079635382,
            -0.025213727727532387,
            -0.005035052075982094,
            -0.07584024965763092,
            0.01658584177494049,
            -0.017968708649277687,
            0.009110250510275364,
            0.0378769151866436,
            0.012096780352294445,
            0.006351969204843044,
            0.0335930660367012,
            -0.025537140667438507,
            -0.0056335763074457645,
            -0.0006531401304528117,
            0.026457644999027252,
            0.035435501486063004,
            -0.012885188683867455,
            -0.011434159241616726,
            0.01158046443015337,
            0.006720329634845257,
            0.01413456816226244,
            -0.025979751721024513,
            -0.048745740205049515,
            0.0022524010855704546,
            -0.012483935803174973,
            -0.005620515439659357,
            -0.008620022796094418,
            -0.049158044159412384,
            -0.012091286480426788,
            0.02052689902484417,
            0.023846391588449478,
            0.003140045329928398,
            -0.012630639597773552,
            0.02190829999744892,
            0.013984697870910168,
            0.03057870641350746,
            0.03438423201441765,
            -0.015985433012247086,
            -0.026561981067061424,
            -0.029008006677031517,
            0.01752525195479393,
            -0.04779774323105812,
            -0.036679621785879135,
            0.031755272299051285,
            0.022019831463694572,
            -0.052726101130247116,
            0.029616251587867737,
            0.015462865121662617,
            0.01556550245732069,
            -0.031808849424123764,
            -0.011601081117987633,
            -0.014694323763251305,
            -0.021604053676128387,
            -0.02434663660824299,
            -0.010795608162879944,
            -0.01463258545845747,
            0.026667777448892593,
            0.03378760814666748,
            0.014076078310608864,
            0.03870958089828491,
            0.014742323197424412,
            -0.006453086156398058,
            -0.018781453371047974,
            0.006533821113407612,
            -0.04708908870816231,
            0.03599090874195099,
            0.010684645734727383,
            -0.0011008783476427197,
            -0.014948294498026371,
            -0.030550040304660797,
            -0.011146881617605686,
            -0.012063059955835342,
            0.026859520003199577,
            0.007105979137122631,
            0.021835356950759888,
            -0.006521633360534906,
            -0.0266262199729681,
            0.008602539077401161,
            -0.005681172478944063,
            0.02651611715555191,
            0.0349264070391655,
            0.018740955740213394,
            -0.0004877801693510264,
            0.009937784634530544,
            0.03920933976769447,
            -0.007723973598331213,
            -0.006949794478714466,
            0.10423251241445541,
            0.00471151527017355,
            0.0037535179872065783,
            -0.012055439874529839,
            0.06271188706159592,
            0.014028174802660942,
            -0.00829037744551897,
            -0.012903274968266487,
            -0.018138661980628967,
            -0.00940084271132946,
            -0.01460680179297924,
            0.0008731911657378078,
            -0.023550301790237427,
            0.013654252514243126,
            0.03046155534684658,
            -0.032746609300374985,
            0.002964079612866044,
            -0.09623134881258011,
            0.015146831050515175,
            -0.01988299936056137,
            0.029890019446611404,
            0.01036213431507349,
            -0.04020028933882713,
            -0.057940736413002014,
            -0.004499570466578007,
            -0.00382121535949409,
            -0.01057636272162199,
            0.0027980778831988573,
            -0.0012463367311283946,
            0.040364690124988556,
            0.010559799149632454,
            -0.03253970667719841,
            0.027714258059859276,
            0.003222761210054159,
            -0.03998371958732605,
            -0.03456786274909973,
            0.0070738657377660275,
            -0.006486160680651665,
            0.014260643161833286,
            0.029909731820225716,
            -0.01048600859940052,
            0.007552336435765028,
            0.023192590102553368,
            -0.018064847216010094,
            0.014417501166462898,
            0.010093850083649158,
            0.0031833278480917215,
            -0.008471610955893993,
            -0.00632415059953928,
            0.017568685114383698,
            0.04462946578860283,
            0.015237280167639256,
            0.012899830006062984,
            0.004515351261943579,
            -0.014485713094472885,
            0.03008372150361538,
            0.08328810334205627,
            0.001073437393642962,
            -0.01718531735241413,
            -0.016702506691217422,
            0.013060060329735279,
            0.001775725046172738,
            -0.021909376606345177,
            -0.02169506624341011,
            0.03242049738764763,
            0.01972256600856781,
            0.018651457503437996,
            0.03639816865324974,
            -0.005581957753747702,
            -0.023786919191479683,
            0.05593053996562958,
            0.028935005888342857,
            -0.015222202986478806,
            -0.022116385400295258,
            0.0012405114248394966,
            0.006332212593406439,
            -0.04687076434493065,
            0.0012594792060554028,
            -0.10336344689130783,
            -0.0033605562057346106,
            0.017409898340702057,
            -0.00741723645478487,
            -0.0017641981830820441,
            0.025313230231404305,
            -0.046456675976514816,
            0.013374416157603264,
            0.0006305983406491578,
            -0.013246951624751091,
            -0.005697775166481733,
            0.008565977215766907,
            0.01994503103196621,
            -0.036447130143642426,
            0.04141353443264961,
            0.02212696708738804,
            0.023024942725896835,
            0.024988939985632896,
            -0.006590158212929964,
            0.039170727133750916,
            -0.02430218830704689,
            -0.005998249631375074,
            -0.012349755503237247,
            0.0032635407987982035,
            -0.00881878286600113,
            0.022489946335554123,
            0.01950456202030182,
            -0.08399755507707596,
            0.006054381374269724,
            -0.01572141796350479,
            0.007830630987882614,
            0.012465325184166431,
            0.03363441675901413,
            -0.039592575281858444,
            -0.02307336963713169,
            0.0337701290845871,
            0.04527018964290619,
            0.03792443126440048,
            -0.004210453946143389,
            0.014783554710447788,
            -0.002367755863815546,
            -0.027907485142350197,
            0.0093529112637043,
            0.025131558999419212,
            -0.015377354808151722,
            -0.02918393164873123,
            -0.0062307026237249374,
            0.019378235563635826,
            0.027933567762374878,
            0.010868401266634464,
            -0.01497631799429655,
            -0.020395653322339058,
            -0.019373005256056786,
            0.017248915508389473,
            -0.012868434190750122,
            -0.05202130600810051,
            -0.054891522973775864,
            0.0013622967526316643,
            0.029121704399585724,
            -0.04657716676592827,
            -0.02051929570734501,
            0.013737577944993973,
            -0.025722557678818703,
            0.030009903013706207,
            -0.0030758781358599663,
            0.05952940508723259,
            0.02070102095603943,
            -0.011965924873948097,
            0.013992449268698692
        ],
        [
            0.023708773776888847,
            0.004577761981636286,
            0.0027074830140918493,
            -0.009826031513512135,
            -0.028205355629324913,
            -0.055357493460178375,
            -0.007548127789050341,
            -0.02680915594100952,
            -0.031360771507024765,
            0.01700686477124691,
            -0.00021079073485452682,
            0.018396666273474693,
            0.016555221751332283,
            0.005039743147790432,
            -0.00578776141628623,
            -0.009910780936479568,
            0.004364034626632929,
            0.02098531275987625,
            0.010564353317022324,
            -0.009716782718896866,
            -0.029650148004293442,
            -0.0065588871948421,
            -0.0566386915743351,
            0.037760742008686066,
            0.03442729637026787,
            0.03172970935702324,
            -0.01615557074546814,
            0.01934536173939705,
            -0.00995385181158781,
            0.02015857957303524,
            0.011478263884782791,
            -0.007122616749256849,
            -0.011163312010467052,
            0.0030398403760045767,
            0.013518497347831726,
            -0.008851489052176476,
            -0.004061757121235132,
            0.024969343096017838,
            -0.02830430120229721,
            -0.0007597475778311491,
            0.030908016487956047,
            0.004459998570382595,
            -0.018288396298885345,
            -0.0022019147872924805,
            0.04411904886364937,
            -0.04223041236400604,
            0.016353413462638855,
            0.02287425473332405,
            0.007030986715108156,
            -0.016576595604419708,
            0.039170268923044205,
            -0.005449243821203709,
            0.028889864683151245,
            -0.007343995850533247,
            0.03920496255159378,
            -0.014447045512497425,
            -0.020480453968048096,
            -0.00951121561229229,
            -0.03199504688382149,
            0.0045049963518977165,
            -0.025721481069922447,
            0.03269456699490547,
            0.0026510341558605433,
            0.016051096841692924,
            -0.04532593861222267,
            -0.0021469087805598974,
            0.016707787290215492,
            0.017324084416031837,
            -0.015238039195537567,
            -0.024318600073456764,
            0.025843307375907898,
            0.0029964945279061794,
            -0.0047598774544894695,
            -0.020875373855233192,
            0.041159629821777344,
            0.03043210133910179,
            -0.021977530792355537,
            -0.006568589247763157,
            0.007045483682304621,
            -0.024407995864748955,
            -0.015118728391826153,
            -0.00214679935015738,
            0.011342041194438934,
            0.011505981907248497,
            0.009140269830822945,
            0.015020471066236496,
            0.020181523635983467,
            -0.04762737825512886,
            0.01657291315495968,
            -0.00616989703848958,
            0.037751369178295135,
            0.02078862302005291,
            0.021380485966801643,
            0.03808389976620674,
            0.015502387657761574,
            -0.01569923758506775,
            0.0075162798166275024,
            -0.008526338264346123,
            -0.03695425018668175,
            -0.01655566692352295,
            0.01932959258556366,
            0.011716055683791637,
            -0.024050526320934296,
            0.011207416653633118,
            -0.005749473348259926,
            0.012361457571387291,
            -0.02060815319418907,
            0.014857791364192963,
            -0.016695965081453323,
            0.013549626804888248,
            -0.017304209992289543,
            -0.012269406579434872,
            0.027317551895976067,
            0.0371948778629303,
            -0.025974052026867867,
            -0.018205169588327408,
            0.018013373017311096,
            -0.008360791020095348,
            -0.017215905711054802,
            0.002571035409346223,
            -0.031556472182273865,
            -0.016485847532749176,
            -0.0006839346024207771,
            0.021586742252111435,
            0.02170693874359131,
            0.024307943880558014,
            0.011061856523156166,
            -0.030092502012848854,
            0.03290766850113869,
            -0.03370289131999016,
            -0.0022536269389092922,
            -0.0077038295567035675,
            0.013891734182834625,
            -0.012729608453810215,
            0.02857207879424095,
            0.026109831407666206,
            0.007152053061872721,
            -0.013329697772860527,
            0.01317627914249897,
            0.009268926456570625,
            0.009618207812309265,
            -0.0024081827141344547,
            -0.009897091425955296,
            0.00035253720125183463,
            -0.004103147890418768,
            0.01120340172201395,
            -0.009051697328686714,
            -0.029535822570323944,
            0.02222304977476597,
            -0.019667649641633034,
            0.017146997153759003,
            0.03105003759264946,
            -0.017717579379677773,
            0.030458353459835052,
            -0.007930913008749485,
            0.009363701567053795,
            0.001987501746043563,
            0.017791258171200752,
            0.0037688687443733215,
            0.03199455887079239,
            0.01369407121092081,
            -0.02727857418358326,
            0.007453614845871925,
            0.008361196145415306,
            -0.019409101456403732,
            -0.010045398958027363,
            0.02264293096959591,
            -0.006104407832026482,
            0.012986183166503906,
            0.015292391180992126,
            0.026374738663434982,
            0.0028855018317699432,
            0.00039861424011178315,
            -0.03937945514917374,
            -0.03853033483028412,
            -0.017259987071156502,
            0.0055365245789289474,
            -0.029991572722792625,
            0.027013786137104034,
            -0.010084125213325024,
            0.017070306465029716,
            0.02956894040107727,
            0.010052015073597431,
            -0.030996188521385193,
            0.007898086681962013,
            -0.007927486672997475,
            -0.01877869851887226,
            0.031239856034517288,
            0.000949119043070823,
            0.004938323982059956,
            -0.014254258945584297,
            0.01745976135134697,
            -0.03913106396794319,
            0.01740189827978611,
            -0.018756145611405373,
            0.0250204149633646,
            -0.006481772754341364,
            0.009762275032699108,
            0.03728530928492546,
            0.025308486074209213,
            0.012507683597505093,
            0.0018504176987335086,
            0.032697636634111404,
            -0.0341571606695652,
            -0.02423117868602276,
            0.015234502963721752,
            0.03898545354604721,
            0.02390475571155548,
            0.019668767228722572,
            -0.02799096703529358,
            0.023588096722960472,
            0.017230771481990814,
            -0.042914096266031265,
            -0.014437127858400345,
            0.004613816738128662,
            0.007529481779783964,
            0.022003285586833954,
            -0.0228712297976017,
            0.03138462081551552,
            -0.02881353534758091,
            0.017584986984729767,
            0.006585370749235153,
            0.0044446238316595554,
            -0.005629129242151976,
            0.025490304455161095,
            -0.00017377582844346762,
            -0.01938852109014988,
            -0.03872276842594147,
            0.008598528802394867,
            -0.0055429209023714066,
            -0.0013423628406599164,
            -0.023173758760094643,
            -0.02403511106967926,
            0.023902904242277145,
            0.014323262497782707,
            -0.014720279723405838,
            -0.023239130154252052,
            0.03310073912143707,
            0.025338800624012947,
            0.03238419443368912,
            -0.003642079420387745,
            0.01873348467051983,
            0.034995127469301224,
            -0.002657997189089656,
            -0.023813964799046516,
            -0.02683611959218979,
            0.0063848611898720264,
            -0.02927227132022381,
            -0.021571146324276924,
            0.019287865608930588,
            0.035724472254514694,
            -0.018307602033019066,
            0.024032315239310265,
            -0.015472344122827053,
            -0.026930781081318855,
            0.007706149946898222,
            0.02553412690758705,
            -0.03569967299699783,
            0.0013058797921985388,
            -0.01366861630231142,
            -0.0018022683216258883,
            0.02367386221885681,
            0.007429521530866623,
            0.03501109406352043,
            0.04004539176821709,
            0.03576141968369484,
            -0.01149161159992218,
            0.01606755703687668,
            0.0021832515485584736,
            0.012523381970822811,
            -0.02841101586818695,
            -0.0005004419363103807,
            -0.023082425817847252,
            -0.02151285670697689,
            0.011026984080672264,
            0.002661285223439336,
            0.025060085579752922,
            -0.0071941399946808815,
            0.012982089072465897,
            0.00953021552413702,
            -0.005832356866449118,
            -0.03259042650461197,
            0.025832632556557655,
            0.044865868985652924,
            -0.023715268820524216,
            0.01796530745923519,
            0.022345272824168205,
            0.01765918917953968,
            0.025189146399497986,
            0.018869660794734955,
            0.023068513721227646,
            0.02875635400414467,
            0.002933286828920245,
            0.013785344548523426,
            0.014483468607068062,
            -0.0013174483319744468,
            0.03798207640647888,
            0.005855640862137079,
            0.008383998647332191,
            0.005860736593604088,
            -0.02648993209004402,
            0.0032857651822268963,
            -0.002478565089404583,
            -0.012895457446575165,
            -0.022188933566212654,
            0.026328738778829575,
            0.02971000038087368,
            -0.03541821986436844,
            0.029579445719718933,
            -0.014670245349407196,
            -0.012863576412200928,
            0.013759194873273373,
            -0.008361677639186382,
            -0.005302378907799721,
            -0.01292981207370758,
            -0.0033336004707962275,
            -0.006324694491922855,
            0.03165476396679878,
            0.012397216632962227,
            -0.04138079658150673,
            0.0069623710587620735,
            0.024910572916269302,
            0.02100132778286934,
            -0.036729905754327774,
            -0.017502959817647934,
            -0.027850352227687836,
            0.029727069661021233,
            0.036636050790548325,
            -0.0308522991836071,
            -0.017820779234170914,
            -0.00617016339674592,
            0.006882564164698124,
            -0.0013594321208074689,
            0.025438614189624786,
            0.012277483940124512,
            0.022706441581249237,
            -0.015234916470944881,
            -0.026361985132098198,
            -0.021967748180031776,
            0.0028801686130464077,
            -0.011778220534324646,
            -0.02256925404071808,
            -0.013118712231516838,
            0.004266778007149696,
            0.0014824062818661332,
            -0.04527241364121437,
            0.003283463418483734,
            0.002033576602116227,
            0.030531637370586395,
            -0.060406267642974854,
            0.030306421220302582,
            0.0556185208261013,
            -0.007903549820184708,
            -0.013188722543418407,
            -0.009336408227682114,
            -0.025982659310102463,
            0.006894462276250124,
            0.01618657074868679,
            -0.02012377604842186,
            0.02904326654970646,
            0.01797536574304104,
            0.028408166021108627,
            0.010044991970062256,
            -0.015866577625274658,
            -0.02433345653116703,
            -0.017827380448579788,
            0.04032735899090767,
            -0.030788196250796318,
            -0.021870015189051628,
            -0.010460945777595043,
            0.016193460673093796,
            0.013763902708888054,
            -0.03480997309088707,
            0.028162477537989616,
            -0.025500018149614334,
            0.022896723821759224,
            0.010336227715015411,
            0.0018399354303255677,
            0.0026933366898447275,
            0.005727110896259546,
            -0.018715087324380875,
            -0.0012446484761312604,
            0.016225142404437065,
            0.0053281160071492195,
            -0.014001521281898022,
            -0.005962396506220102,
            0.02975553460419178,
            -0.009555825963616371,
            0.01510637067258358,
            -0.044865623116493225,
            0.022214079275727272,
            -0.008269242942333221,
            0.03252461180090904,
            -0.032429326325654984,
            -0.03238710016012192,
            0.021413087844848633,
            -0.015771614387631416,
            -0.0166284441947937,
            -0.02001301944255829,
            -0.006956607569009066,
            -0.0005794950411655009,
            0.0012733852490782738,
            -0.001536790281534195,
            -0.025543643161654472,
            -0.01314643956720829,
            -0.0014704600907862186,
            -0.0369587279856205,
            0.006961663719266653,
            0.027836810797452927,
            -0.014003079384565353,
            0.000684528611600399,
            0.015556886792182922,
            -0.030132805928587914,
            0.02388342283666134,
            0.008738338015973568,
            -0.02616930939257145,
            0.02279648371040821,
            -0.007916176691651344,
            -0.0069060190580785275,
            0.0003276857314631343,
            0.015638776123523712,
            -0.013696223497390747,
            -0.009476478211581707,
            0.02845294401049614,
            0.0073813763447105885,
            -0.0005354773602448404,
            -0.02281796932220459,
            0.01606929302215576,
            0.025132518261671066,
            -0.030718626454472542,
            -0.027913562953472137,
            -0.012118938378989697,
            0.0020423601381480694,
            -0.03679174184799194,
            -0.013772987760603428,
            0.01284058764576912,
            -0.008430343121290207,
            0.02265947498381138,
            0.01467435248196125,
            -0.01748858019709587,
            0.0009276558412238955,
            0.02624225616455078,
            0.01498253084719181,
            -0.012005587108433247,
            -0.018699757754802704,
            -0.007333367131650448,
            -0.012246073223650455,
            -0.016762977465987206,
            -0.028440099209547043,
            0.00022993655875325203,
            0.010771986097097397,
            -0.02083783596754074,
            -0.006768964231014252,
            -0.026843613013625145,
            0.029625998809933662,
            0.0256067905575037,
            0.01920616813004017,
            -0.023569874465465546,
            -0.02502109855413437,
            -0.028527377173304558,
            -0.05235867574810982,
            0.0014088478637859225,
            -0.005115410313010216,
            0.011241793632507324,
            -0.02494603581726551,
            0.006781977601349354,
            -0.0005519249243661761,
            0.030250946059823036,
            -0.020260125398635864,
            -0.014403682202100754,
            -0.006566003896296024,
            0.009640623815357685,
            0.025887057185173035,
            0.006695008836686611,
            0.009312587790191174,
            -0.0003202736843377352,
            0.0057708644308149815,
            0.02920709364116192,
            0.012192882597446442,
            -0.013749388977885246,
            0.023318598046898842,
            0.0006008339114487171,
            0.032225389033555984,
            -0.006414986215531826,
            -0.0012626235838979483,
            0.010166519321501255,
            0.007903817109763622,
            -0.005719439592212439,
            0.0032756191212683916,
            0.020639069378376007,
            0.0065165250562131405,
            0.01926462911069393,
            0.02229139767587185,
            -0.023362956941127777,
            -0.026100723072886467,
            0.03210897371172905,
            0.01477546151727438,
            0.028412194922566414,
            0.006272963713854551,
            -0.013456949964165688,
            -0.007911475375294685,
            -0.014695769175887108,
            -0.0028687415178865194,
            -0.02882852777838707,
            0.009010798297822475,
            -0.025853952392935753,
            0.008659308776259422,
            -0.011211493983864784,
            0.004295581020414829,
            -0.00042591223609633744,
            0.02816196158528328,
            0.0016031803097575903,
            0.0072668567299842834,
            -0.006656142417341471,
            -0.01175977848470211,
            -0.002642166567966342,
            -0.013248598203063011,
            0.028280550613999367,
            -0.014929858036339283,
            0.023658113554120064,
            -0.0009933457477018237,
            0.030053388327360153,
            0.01418994925916195,
            0.02343563921749592,
            -0.037426337599754333,
            0.0367555096745491,
            -0.02570038102567196,
            0.027502313256263733,
            -0.024318432435393333,
            0.0008318576728925109,
            0.010609607212245464,
            -0.014698026701807976,
            -0.04077130928635597,
            -0.010330261662602425,
            -0.005130930803716183,
            -0.020005356520414352,
            0.016683921217918396,
            9.139552275883034e-05,
            -0.03942318260669708,
            0.017336487770080566,
            -0.010925620794296265,
            -0.0028502566274255514,
            0.006852010264992714,
            0.002689798129722476,
            -0.014396995306015015,
            0.04232551530003548,
            0.026698093861341476,
            -0.020064296200871468,
            -0.02928478829562664,
            0.028352167457342148,
            -0.030148595571517944,
            -0.019663048908114433,
            0.015273586846888065,
            -0.011364412494003773,
            0.010597309097647667,
            -0.002045007422566414,
            -0.001381025416776538,
            -0.034867413341999054,
            0.008604712784290314,
            0.014393637888133526,
            0.004288511350750923,
            0.003953792154788971,
            0.01218827348202467,
            0.009289161302149296,
            0.022536704316735268,
            0.03315601870417595,
            0.004183929413557053,
            -0.0211162269115448,
            0.025743503123521805,
            0.022263530641794205,
            0.017534345388412476,
            0.023090610280632973,
            -0.024325966835021973,
            0.026409627869725227,
            -0.02074781246483326,
            0.033579353243112564,
            0.009934570640325546,
            -0.009635165333747864,
            -0.030777866020798683,
            0.0021724055986851454,
            0.011805098503828049,
            -0.0010641126427799463,
            -0.010784883983433247,
            0.012154825031757355,
            0.023257754743099213,
            0.0076748402789235115,
            0.019891444593667984,
            -0.001581318094395101,
            -0.005224175285547972,
            -0.012761358171701431,
            0.019580040127038956,
            -0.012653921730816364,
            -0.01646483689546585,
            0.002343471860513091,
            0.024867845699191093,
            0.018626287579536438,
            0.02409389428794384,
            0.01664804294705391,
            0.011831513606011868,
            -0.0035567060112953186,
            -0.04815860465168953,
            0.02352268435060978,
            0.003263883525505662,
            0.02543671987950802,
            -0.009762250818312168,
            -0.03741494193673134,
            -0.005652922671288252,
            -0.01402071863412857,
            0.014467354863882065,
            0.03999195620417595,
            -0.003163044573739171,
            -0.001190136419609189,
            0.02596428617835045,
            0.020809868350625038,
            -0.028084086254239082,
            -0.03341873362660408,
            -0.0014176638796925545,
            0.016179021447896957,
            0.02123630791902542,
            0.0010155047057196498,
            0.04208369180560112,
            0.029926825314760208,
            0.005167447030544281,
            -0.006781638599932194,
            -0.012797815725207329,
            -0.006151825655251741,
            0.01133226789534092,
            -0.014690423384308815,
            -0.010130534879863262,
            0.018469274044036865,
            -0.007423924282193184,
            0.018713193014264107,
            0.007231788709759712,
            0.03759697452187538,
            -0.03281590715050697,
            0.006616637110710144,
            0.03389221057295799,
            -0.019392378628253937,
            0.02463330514729023,
            -0.030712764710187912,
            0.02855161763727665,
            0.0011051336769014597,
            -0.03222441300749779,
            0.012570399791002274,
            0.01837504282593727,
            0.018403997644782066,
            0.009269950911402702,
            0.0007125515840016305,
            -0.009151453152298927,
            -0.004189417231827974,
            0.018035421147942543,
            -0.029228433966636658,
            0.01837695948779583,
            0.006333347409963608,
            0.0017386015970259905,
            -0.021832626312971115,
            -0.025967877358198166,
            0.02425953559577465,
            0.02509750984609127,
            0.016955742612481117,
            -0.020177237689495087,
            0.01593763194978237,
            0.02048458717763424,
            0.03819601237773895,
            -0.03639552369713783,
            -0.01826542615890503,
            -0.0037903054617345333,
            0.01655612513422966,
            -0.014074007049202919,
            -0.01633898727595806,
            -0.04956655576825142,
            0.0014561653370037675,
            0.02867700159549713,
            0.039778467267751694,
            -0.01864999532699585,
            0.015884367749094963,
            0.005071213468909264,
            -0.027876857668161392,
            -0.005065908655524254,
            -0.031577859073877335,
            -0.03530072048306465,
            -0.010096742771565914,
            0.031568605452775955,
            -0.006574628408998251,
            -0.010306811891496181,
            0.012948577292263508,
            -0.022558247670531273,
            -0.01896517351269722,
            0.03134061396121979,
            0.01085641048848629,
            -0.029472967609763145,
            0.012341705150902271,
            0.02996331825852394,
            0.03180184215307236,
            0.023529009893536568,
            -0.04181640222668648,
            -0.01012096181511879,
            0.024953139945864677,
            0.011277581565082073,
            0.0027453589718788862,
            -0.017707260325551033,
            -0.009837820194661617,
            0.021126756444573402,
            -0.015254250727593899,
            -0.009925267659127712,
            0.016005584970116615,
            0.0320860855281353,
            -0.03138502687215805,
            -0.0068419803865253925,
            -0.016370728611946106,
            0.020347965881228447,
            0.031405407935380936,
            0.009629194624722004,
            0.02787759155035019,
            0.017604924738407135,
            -0.03224083036184311,
            0.011806140653789043,
            -0.00959088560193777,
            -0.007506947964429855,
            -0.03422833979129791,
            0.010084273293614388,
            -0.02560459077358246,
            0.006637535523623228,
            0.0178313497453928,
            0.03011304885149002,
            0.017606867477297783,
            -0.020957840606570244,
            -0.0014519602991640568,
            0.016294177621603012,
            0.023915274068713188,
            0.009909152053296566,
            0.008796626701951027,
            0.009043088182806969,
            0.0015795051585882902,
            0.010402805171906948,
            -0.01683964394032955,
            0.0021600513719022274,
            -0.01130687166005373,
            0.0456567145884037,
            0.013029263354837894,
            0.027254756540060043,
            -0.011120220646262169,
            0.016596155241131783,
            -0.014549117535352707,
            0.0016712566139176488,
            0.036692388355731964,
            0.015340040437877178,
            0.023617886006832123,
            0.017074238508939743,
            -0.0025230019818991423,
            -0.02037854492664337,
            -0.015704620629549026,
            0.004635569639503956,
            0.01667110249400139,
            -0.017074475064873695,
            0.010184014216065407,
            -0.02049867995083332,
            -0.05981198325753212,
            0.004607278387993574,
            -0.008083357475697994,
            -0.0038455380126833916,
            0.020584585145115852,
            0.04160751402378082,
            0.003475438803434372,
            0.027405766770243645,
            -0.02582676336169243,
            -0.0009220652864314616,
            -0.012929582968354225,
            -0.024872401729226112,
            -0.01071112509816885,
            0.008612112142145634,
            0.020030785351991653,
            0.023281477391719818,
            -0.014178114011883736,
            0.014630010351538658,
            -0.034279484301805496,
            0.02448374778032303,
            -0.024850161746144295,
            0.00957212783396244,
            -0.024147171527147293,
            0.008694184944033623,
            0.0060446737334132195,
            -0.033349134027957916,
            0.0008832546300254762,
            0.03300529345870018,
            -0.002653637668117881,
            0.010292447172105312,
            -0.021549658849835396,
            0.008217155002057552,
            -0.018846312537789345,
            0.02325410768389702,
            0.028609927743673325,
            0.03050473891198635,
            -0.00024332423345185816,
            -0.02148805931210518,
            0.02973027154803276,
            -0.033303629606962204,
            0.00946739874780178,
            0.018659578636288643,
            -0.02935781329870224,
            -0.02471359819173813,
            0.032019395381212234,
            0.015412074513733387,
            0.02348334528505802,
            -0.004311503376811743,
            0.034902360290288925,
            -0.007420542184263468,
            0.031796324998140335,
            -0.028598304837942123,
            -0.038072369992733,
            -0.016616610810160637,
            0.028886113315820694,
            0.025502866134047508,
            -0.03733183071017265,
            -0.03834543377161026,
            -0.02616378851234913,
            -0.030016757547855377,
            -0.014287496916949749,
            0.03674251213669777,
            -0.005723113194108009,
            0.033404044806957245,
            -0.004302425775676966,
            0.00554538331925869,
            -0.03807893395423889,
            0.023511450737714767,
            0.02992570400238037,
            0.003951823804527521,
            0.026887943968176842,
            0.00307813985273242,
            -0.029025860130786896,
            0.013284599408507347,
            -0.01600487157702446,
            -0.005405065603554249,
            -0.03410615026950836,
            -0.013526537455618382,
            0.004085612948983908,
            -0.0004215984372422099,
            0.018014822155237198,
            -0.015574798919260502,
            0.03454692289233208,
            -0.022161638364195824,
            0.01990550197660923,
            -0.0059262970462441444,
            0.018181247636675835,
            0.020495330914855003,
            0.023106325417757034,
            -0.03496083244681358,
            -0.0064299385994672775,
            -0.025903411209583282,
            0.00788296852260828,
            -0.0075655910186469555,
            0.003530926536768675,
            0.024553243070840836,
            -0.022489771246910095,
            0.022617077454924583,
            0.004522821865975857,
            0.009892419911921024,
            0.010150045156478882,
            -0.01460270956158638,
            0.022433815523982048,
            -0.009940136224031448,
            0.012089700438082218,
            0.03691272437572479,
            0.016202544793486595,
            -0.04119224473834038,
            -0.025193246081471443,
            0.02104385942220688,
            -0.002302435925230384,
            -0.017486725002527237,
            0.011435391381382942,
            -0.03979913145303726,
            0.025282979011535645,
            0.0008968714973889291,
            0.011621873825788498,
            0.011946280486881733,
            0.016466636210680008,
            -0.028071928769350052,
            -0.02868214063346386,
            -0.018401216715574265,
            0.00800253450870514,
            0.0028297484386712313,
            -0.03800114244222641,
            -0.03150467202067375,
            -0.0021423371508717537,
            -0.02840960957109928,
            0.02692880667746067,
            0.0018008343176916242,
            -0.007834012620151043,
            -0.014517365954816341,
            -0.019724469631910324,
            0.05027688294649124,
            0.001941196620464325,
            0.013585356064140797,
            -0.007466467097401619,
            -0.0029253086540848017,
            -0.0004957760102115571,
            0.015918303281068802,
            -0.006314372643828392,
            -0.013268744572997093,
            -0.013598421588540077,
            -0.03413674980401993,
            -0.011689811013638973,
            -0.012570536695420742,
            -0.003562087193131447,
            0.03725222870707512,
            0.013615883886814117,
            -0.046025846153497696,
            -0.004454591311514378,
            -0.019014276564121246,
            -0.016723763197660446,
            0.004381311126053333,
            0.02817082777619362,
            0.010569846257567406,
            0.050791654735803604,
            0.03149748221039772,
            0.01577228680253029,
            -0.006072111427783966,
            -0.02760525420308113,
            0.020793408155441284,
            -0.019901195541024208,
            -0.016958486288785934,
            0.015142520889639854,
            0.010455572977662086,
            -0.0057628885842859745,
            -0.03147318214178085,
            -0.011562881991267204,
            -0.012628517113626003,
            0.01225149817764759,
            0.004051927477121353,
            0.00578244449570775,
            -0.0275138970464468,
            0.014775323681533337,
            -0.005171692930161953,
            -0.026740506291389465,
            -0.03996000438928604,
            0.006726215127855539,
            0.03176582604646683,
            -0.01623874343931675,
            -0.023619607090950012,
            0.02275010570883751,
            0.014921138063073158,
            -0.021259058266878128,
            -0.011031225323677063,
            -0.03396488353610039,
            0.005264212843030691,
            -1.6787496861070395e-05,
            0.026300495490431786,
            -0.0065610287711024284,
            -0.00525077898055315,
            0.026967672631144524,
            -0.028495216742157936,
            0.0355842188000679,
            -0.026876157149672508,
            0.025380417704582214,
            -0.03155556321144104,
            -0.01620822586119175,
            0.028182929381728172,
            -0.024529339745640755,
            0.008684800006449223,
            -0.01605062559247017,
            -0.010205093771219254,
            0.01641538180410862,
            -0.0074697500094771385,
            -0.012407150119543076,
            0.022971298545598984,
            -0.0024772812612354755,
            -0.043342072516679764,
            -0.017576390877366066,
            -0.00428601773455739,
            -0.00993103627115488,
            0.01837710291147232,
            -0.02583291381597519,
            -0.011361245065927505,
            0.007932684384286404,
            -0.011796515434980392,
            0.021217605099081993,
            0.00025356162223033607,
            0.0266118086874485,
            -0.01224615890532732,
            0.029269523918628693,
            0.002620535437017679,
            0.03834887966513634,
            0.007820788770914078,
            0.017235420644283295,
            -0.03934135660529137,
            0.028454719111323357,
            0.0028610150329768658,
            -0.03179516643285751,
            -0.026723599061369896,
            0.032568857073783875,
            0.014307776466012001,
            -0.006120413076132536,
            0.032287225127220154,
            0.010547991842031479,
            -0.03181774169206619,
            0.023828698322176933,
            0.0015660120407119393,
            -0.019411979243159294,
            -0.03868669643998146,
            0.006410305853933096,
            0.0247358251363039,
            -0.004882799927145243,
            0.02717582881450653,
            -0.0056883529759943485,
            -0.007156308274716139,
            0.005155554972589016,
            -0.027347736060619354,
            -0.0007884548394940794,
            0.019358187913894653,
            0.01975775882601738,
            0.017367951571941376,
            0.047327980399131775,
            -0.009867486543953419,
            0.012045659124851227,
            0.014155426062643528,
            -0.04190291464328766,
            -0.031183024868369102,
            -0.03448379412293434,
            0.016599120572209358,
            0.011555669829249382,
            -0.042926572263240814,
            -0.016316654160618782,
            -0.045874644070863724,
            -0.009013380855321884
        ],
        [
            -0.00431049894541502,
            -0.005704643204808235,
            -0.0018233798909932375,
            -0.01681104488670826,
            0.02485072612762451,
            -0.022551771253347397,
            -0.010251053608953953,
            -0.010022643022239208,
            0.013086879625916481,
            -0.031197218224406242,
            0.01301578525453806,
            0.0239188764244318,
            -0.00191895489115268,
            -0.007605474442243576,
            0.010975269600749016,
            -0.046132173389196396,
            -0.022472374141216278,
            0.017299698665738106,
            0.007163005881011486,
            0.009295227006077766,
            0.016906676813960075,
            0.021315228193998337,
            -0.015031849034130573,
            -0.0013978274073451757,
            -0.03778820484876633,
            0.016276085749268532,
            0.019072916358709335,
            0.021513981744647026,
            -0.016953548416495323,
            -0.023509373888373375,
            -0.02155565656721592,
            0.02102203480899334,
            -0.006656391080468893,
            -0.012582271359860897,
            -0.032224930822849274,
            -0.022680630907416344,
            0.02066769078373909,
            -0.041538842022418976,
            -0.027472637593746185,
            -0.032003723084926605,
            -0.005031361244618893,
            0.0017670398810878396,
            0.0014051764737814665,
            0.002059640595689416,
            0.01631361059844494,
            0.019864043220877647,
            -0.01613050140440464,
            0.002721271710470319,
            0.03629947453737259,
            0.016491742804646492,
            -0.00974322110414505,
            0.011366509832441807,
            -0.0341997854411602,
            -0.013619448989629745,
            -0.030790098011493683,
            0.039683133363723755,
            0.023803651332855225,
            -0.019483447074890137,
            0.007450324948877096,
            0.003907178062945604,
            0.004087415989488363,
            0.0047971573658287525,
            -0.00908992625772953,
            0.015811443328857422,
            0.025292351841926575,
            -0.010193739086389542,
            -0.018515294417738914,
            0.020194951444864273,
            0.011902964673936367,
            0.0012819531839340925,
            -0.022123096510767937,
            -0.004362183157354593,
            0.010818646289408207,
            -0.0022244914434850216,
            0.05251433327794075,
            -0.002260439796373248,
            0.04322335869073868,
            -0.009375862777233124,
            -0.0010981144150719047,
            -0.025267023593187332,
            -0.0094712283462286,
            -0.024275198578834534,
            0.0006145636434666812,
            -0.017419518902897835,
            0.02958843670785427,
            -0.03284597024321556,
            -0.008083469234406948,
            0.038578059524297714,
            0.0018227404216304421,
            -0.0126310084015131,
            0.009400084614753723,
            0.004461985547095537,
            0.012441669590771198,
            0.026120563969016075,
            0.009555964730679989,
            -0.03874495252966881,
            0.0390474908053875,
            -0.014088694006204605,
            0.047810740768909454,
            -0.004837240558117628,
            0.003598148701712489,
            0.0104896305128932,
            0.008685817942023277,
            0.020010147243738174,
            0.018049050122499466,
            0.024006355553865433,
            0.00866188295185566,
            0.009573156014084816,
            0.0198154728859663,
            0.02193865180015564,
            0.01697956584393978,
            -0.020726194605231285,
            -0.007914847694337368,
            -0.03225604072213173,
            -0.050832584500312805,
            0.006351204589009285,
            0.02285868488252163,
            0.006335902027785778,
            -0.02231532521545887,
            -0.02823985368013382,
            -0.003709533018991351,
            0.005524670239537954,
            -0.009534134529531002,
            -0.01829673908650875,
            0.004006028641015291,
            0.023460587486624718,
            -0.0003292881010565907,
            0.017903165891766548,
            0.004410464782267809,
            0.025167735293507576,
            0.01641997881233692,
            -4.574923877953552e-05,
            -0.0042735375463962555,
            -0.017261669039726257,
            -0.02527514100074768,
            0.02029453217983246,
            -0.028271643444895744,
            0.026391049847006798,
            -0.015981581062078476,
            -0.014453468844294548,
            -0.021080099046230316,
            0.006206384394317865,
            -0.0055525838397443295,
            0.01635085791349411,
            0.023883771151304245,
            0.01667945459485054,
            -0.022049998864531517,
            0.01684287004172802,
            -0.01174565963447094,
            -0.0034306710585951805,
            -0.02238091453909874,
            -0.008945715613663197,
            0.026026485487818718,
            -0.01557247620075941,
            0.041086986660957336,
            -0.011883781291544437,
            0.009917084127664566,
            0.031019819900393486,
            0.004081151448190212,
            0.0048685623332858086,
            -0.009746934287250042,
            -0.015411519445478916,
            0.0002768228587228805,
            -0.010807152837514877,
            -0.0008023986592888832,
            -0.04216291382908821,
            0.030327776446938515,
            0.008048420771956444,
            0.018442781642079353,
            -0.016625933349132538,
            0.02235177345573902,
            -0.013030899688601494,
            -0.008375893346965313,
            0.030947359278798103,
            0.019075969234108925,
            -0.01673051528632641,
            0.017581339925527573,
            0.030041063204407692,
            0.011700023896992207,
            -0.002755646826699376,
            0.016028154641389847,
            0.010222145356237888,
            -0.0006733574555255473,
            -0.026547519490122795,
            0.009655838832259178,
            -0.02996780537068844,
            0.04406794160604477,
            0.0166227538138628,
            -0.009612721391022205,
            0.029450509697198868,
            0.0301763117313385,
            -0.011352447792887688,
            -0.04057261720299721,
            -0.022264011204242706,
            0.02540973387658596,
            0.028209250420331955,
            -0.022303184494376183,
            -0.012088103219866753,
            -0.006371067836880684,
            0.010631644167006016,
            -0.022762585431337357,
            0.018202779814600945,
            -0.032796382904052734,
            0.014379570260643959,
            -0.03707476705312729,
            -0.0008440941455774009,
            0.021243471652269363,
            0.0037646812852472067,
            0.023642316460609436,
            -0.004089126363396645,
            -0.007551136892288923,
            -0.027471331879496574,
            -0.0412224642932415,
            -0.00710068317130208,
            -0.0021863183937966824,
            -0.0026381269562989473,
            -0.006065381225198507,
            -0.007246195338666439,
            -0.010722992941737175,
            -0.003055580658838153,
            -0.007059693802148104,
            -0.010103047825396061,
            -0.03692295774817467,
            0.01921534724533558,
            0.0074260421097278595,
            0.0020726253278553486,
            0.031127242371439934,
            -0.007472984492778778,
            -0.017144499346613884,
            -0.03153056278824806,
            9.171303099719808e-05,
            0.015085507184267044,
            0.014271929860115051,
            0.0014577937545254827,
            -0.019695693626999855,
            -0.03095211274921894,
            -0.01325796265155077,
            0.032388102263212204,
            0.015135138295590878,
            0.04267890378832817,
            -0.015037544071674347,
            -0.023652909323573112,
            -0.016208166256546974,
            0.02694758027791977,
            0.026826094835996628,
            0.011378039605915546,
            0.007070652209222317,
            0.009395752102136612,
            -0.010476566851139069,
            -0.002023265929892659,
            -0.004378134850412607,
            0.026626452803611755,
            0.024750014767050743,
            0.026985114440321922,
            -0.007704848423600197,
            0.03093201294541359,
            0.031867388635873795,
            0.006524155382066965,
            0.0040852162055671215,
            -0.007546034175902605,
            0.017207110300660133,
            -0.00012867267651017755,
            -0.0068273101933300495,
            0.024479202926158905,
            -0.005257347598671913,
            0.024661261588335037,
            -0.01307107787579298,
            0.02517317235469818,
            0.02264237031340599,
            -0.006837302353233099,
            -0.027708906680345535,
            0.03159971162676811,
            -0.006479080766439438,
            0.023676348850131035,
            0.03936440125107765,
            0.01322176493704319,
            0.04027743265032768,
            -0.01627088524401188,
            -0.005784918554127216,
            0.004178544506430626,
            -0.03198493644595146,
            0.022160077467560768,
            -0.004780915565788746,
            0.01632574014365673,
            0.007231622934341431,
            -0.02474638819694519,
            0.012283225543797016,
            -0.0031188332941383123,
            0.008366710506379604,
            -0.021567020565271378,
            0.013598229736089706,
            0.012231135740876198,
            -0.0004417940217535943,
            -0.011914562433958054,
            -0.046554915606975555,
            0.02053290233016014,
            -0.014073734171688557,
            0.03521237149834633,
            0.016934474930167198,
            0.031443774700164795,
            -0.010943945497274399,
            0.021030865609645844,
            0.0014599637361243367,
            0.007397964596748352,
            -0.023094817996025085,
            -0.044176727533340454,
            -0.015591857954859734,
            -0.016244597733020782,
            0.017570728436112404,
            0.00745790870860219,
            0.014112894423305988,
            -0.028071265667676926,
            0.016013767570257187,
            -0.024220727384090424,
            -0.03337865695357323,
            0.054707612842321396,
            0.011663943529129028,
            0.0007325266487896442,
            0.002799063688144088,
            0.006121180020272732,
            -0.028853869065642357,
            -0.03011348843574524,
            0.029759524390101433,
            0.009569347836077213,
            -0.038464054465293884,
            0.007641885429620743,
            -0.010013743303716183,
            0.0511242039501667,
            0.029849441722035408,
            0.00928808655589819,
            0.024489596486091614,
            -0.012979489751160145,
            0.019135011360049248,
            -0.017271697521209717,
            -0.028816459700465202,
            0.029346195980906487,
            -0.006600051652640104,
            -0.007130871061235666,
            0.0248523261398077,
            -0.005405097268521786,
            -0.003650818718597293,
            0.04572397097945213,
            0.027984492480754852,
            -0.03862898424267769,
            -0.014598810113966465,
            -0.010830911807715893,
            -0.006082361564040184,
            -0.02451357990503311,
            -0.022910527884960175,
            -0.013623282313346863,
            0.008209827356040478,
            -0.07109864056110382,
            -0.03294803202152252,
            -0.03333976864814758,
            0.019737163558602333,
            0.02619161084294319,
            -0.013137023895978928,
            0.00332202878780663,
            0.01984694041311741,
            0.005319243762642145,
            -0.015880335122346878,
            -0.038607217371463776,
            -0.016272958368062973,
            -0.00929681770503521,
            -0.04101752117276192,
            0.013309692032635212,
            -0.005801409017294645,
            -0.009180783294141293,
            -0.00646825460717082,
            -0.016482200473546982,
            -0.04454091563820839,
            0.025029506534337997,
            -0.020980823785066605,
            0.022510284557938576,
            0.018154451623558998,
            0.009425263851881027,
            -0.03776336833834648,
            0.01180439256131649,
            -0.015212973579764366,
            -0.002161289332434535,
            0.014942634850740433,
            0.007765038404613733,
            -0.021969011053442955,
            0.020616846159100533,
            0.033478327095508575,
            -0.0033640945330262184,
            0.023116163909435272,
            -0.030226316303014755,
            -0.025571227073669434,
            -0.023020144551992416,
            0.02640431746840477,
            0.013467970304191113,
            -0.031482528895139694,
            -0.02139333263039589,
            -0.029281916096806526,
            -0.0029628262855112553,
            0.007113303989171982,
            0.029779102653265,
            -0.018479226157069206,
            0.0007156576029956341,
            0.03804909437894821,
            -0.024258235469460487,
            -0.0029704265762120485,
            -0.030155938118696213,
            -0.04379040375351906,
            0.02354043535888195,
            0.013225766830146313,
            0.02594783529639244,
            0.013962741009891033,
            -0.013076616451144218,
            -0.03432920202612877,
            0.011033332906663418,
            -0.010587379336357117,
            -0.02313689887523651,
            -0.026087751612067223,
            0.021361175924539566,
            -0.05063400790095329,
            -0.029661353677511215,
            -0.01742640696465969,
            0.026736514642834663,
            -0.0008681209874339402,
            0.023720823228359222,
            -0.008525931276381016,
            -0.015446752309799194,
            0.03221740573644638,
            -0.03726639226078987,
            0.03511400148272514,
            -0.020993443205952644,
            0.0003494566190056503,
            0.008112305775284767,
            0.0030307918787002563,
            0.029717780649662018,
            -0.01975717954337597,
            -0.004425532650202513,
            -0.0320914052426815,
            0.03209039568901062,
            0.020179344341158867,
            0.03424486145377159,
            -0.009768159128725529,
            -0.02286369539797306,
            0.00042284568189643323,
            -0.07530588656663895,
            -0.011072664521634579,
            -0.015516682527959347,
            -0.01616579294204712,
            -0.007388521917164326,
            -0.0134506244212389,
            0.057439859956502914,
            -0.04720507934689522,
            -0.026415487751364708,
            -0.029374241828918457,
            -0.03518110513687134,
            -0.024341100826859474,
            0.022026613354682922,
            -0.004057501908391714,
            -0.0323018841445446,
            0.027368871495127678,
            -0.018653132021427155,
            -0.007205131463706493,
            -0.022950969636440277,
            0.027548551559448242,
            0.004583662375807762,
            0.029980098828673363,
            -0.03723590821027756,
            -0.015480052679777145,
            0.018916601315140724,
            -0.02082984894514084,
            -0.02420673333108425,
            -0.04685855284333229,
            0.025183826684951782,
            -0.0035999941173940897,
            0.0237888116389513,
            0.007067118305712938,
            0.018994539976119995,
            -0.007553755771368742,
            0.02142811194062233,
            -0.014562382362782955,
            -0.020330440253019333,
            -0.026799412444233894,
            -0.02781588025391102,
            -0.010828269645571709,
            0.012378442101180553,
            0.027801675722002983,
            -0.00493800500407815,
            0.01331542432308197,
            -0.04194224625825882,
            0.01313958689570427,
            -0.004592180717736483,
            0.07735782861709595,
            0.031239528208971024,
            0.03095705807209015,
            0.012190663255751133,
            0.0023927234578877687,
            0.01716581918299198,
            -0.02776498906314373,
            -0.020989496260881424,
            0.027971342206001282,
            0.010471266694366932,
            0.012026501819491386,
            0.004643339663743973,
            0.019255410879850388,
            0.0021286800038069487,
            0.013510400429368019,
            -0.00024564925115555525,
            -0.030364172533154488,
            0.027064315974712372,
            0.00661662220954895,
            -0.01673317700624466,
            -0.057647451758384705,
            0.0063660843297839165,
            -0.029323043301701546,
            0.01411991473287344,
            0.014365077018737793,
            -0.014600352384150028,
            0.010664761997759342,
            -0.02763279527425766,
            -0.02637181431055069,
            0.01377822458744049,
            0.016561508178710938,
            0.017450090497732162,
            -0.011881769634783268,
            -0.01960413157939911,
            -0.02756992168724537,
            0.020127052441239357,
            0.017313264310359955,
            0.009295235387980938,
            -0.012836975045502186,
            0.012135720811784267,
            -2.4381930415984243e-05,
            0.01737039163708687,
            -0.013671224936842918,
            -0.006695191375911236,
            0.03743826970458031,
            0.007869429886341095,
            -0.0020256307907402515,
            -0.00023158824478741735,
            0.015493940562009811,
            -0.020751245319843292,
            0.006528916768729687,
            0.02106725051999092,
            -0.017931213602423668,
            0.042113956063985825,
            0.015414954163134098,
            -0.024654757231473923,
            -0.01865496300160885,
            -0.008980587124824524,
            -0.02359931357204914,
            0.0001750384399201721,
            0.03256819024682045,
            -0.023247115314006805,
            -0.013511797413229942,
            -0.021458983421325684,
            0.03498286008834839,
            -0.022621771320700645,
            0.025895070284605026,
            -0.023070454597473145,
            -0.00309710088185966,
            0.03476809337735176,
            -0.007145795039832592,
            -0.006698115728795528,
            0.023576322942972183,
            -0.023579146713018417,
            -0.01551517378538847,
            0.015689074993133545,
            -0.013361173681914806,
            0.01706329919397831,
            -0.0803881585597992,
            0.016855457797646523,
            0.01922566257417202,
            0.032443005591630936,
            -0.01703687757253647,
            0.01114265713840723,
            0.028796009719371796,
            -0.006754995323717594,
            -0.017048507928848267,
            -0.002307639457285404,
            -0.038476280868053436,
            -0.009733859449625015,
            0.015249967575073242,
            -0.012983943335711956,
            0.018718751147389412,
            -0.017737729474902153,
            -0.008193762972950935,
            -0.030773751437664032,
            0.02051801048219204,
            0.001504819723777473,
            -0.0053844028152525425,
            0.0030354203190654516,
            0.037461020052433014,
            0.01672257110476494,
            -0.02429172396659851,
            -0.022610442712903023,
            -0.01373907271772623,
            -0.007274256087839603,
            -0.021675841882824898,
            0.020111167803406715,
            0.04001227766275406,
            -0.01053245086222887,
            -0.01956281065940857,
            0.012605458498001099,
            -0.008534828200936317,
            0.026925718411803246,
            0.02004718966782093,
            -0.002320698695257306,
            0.013057329691946507,
            0.020263943821191788,
            -0.008484412916004658,
            -0.020344415679574013,
            0.0018550908425822854,
            0.03807319700717926,
            0.0002393286267761141,
            0.009343840181827545,
            0.027043592184782028,
            0.013978405855596066,
            -0.015762288123369217,
            0.007234963122755289,
            -0.036648087203502655,
            -0.0010472829453647137,
            0.008512708358466625,
            0.02453252486884594,
            -0.01254415512084961,
            0.01579945906996727,
            -0.003666520118713379,
            0.012679272331297398,
            -0.008751940913498402,
            -0.004651540424674749,
            0.019078319892287254,
            0.02573401853442192,
            0.01943173259496689,
            0.01857541874051094,
            -0.008892826735973358,
            0.03577427938580513,
            -0.009397104382514954,
            0.02863297611474991,
            0.010690191760659218,
            0.005684779956936836,
            0.03224705532193184,
            -0.030403021723031998,
            0.0005228000227361917,
            0.011825514025986195,
            -0.009578090161085129,
            -0.045861706137657166,
            -0.00447588786482811,
            -0.010841038078069687,
            -0.001029022503644228,
            -0.005436792504042387,
            0.018755879253149033,
            -0.030657358467578888,
            -0.012998993508517742,
            -0.013607319444417953,
            -0.01023164764046669,
            -0.01701265573501587,
            0.02717706747353077,
            -0.018665527924895287,
            0.02371399663388729,
            0.001606994541361928,
            -0.01885070651769638,
            0.018327802419662476,
            0.0024115194100886583,
            -0.01734955795109272,
            0.03590688854455948,
            0.00038240663707256317,
            -0.005857793614268303,
            -0.02364240773022175,
            -0.014574174769222736,
            -0.002836205530911684,
            0.028375694528222084,
            -0.028013724833726883,
            -0.02815992571413517,
            0.011286399327218533,
            0.03370046615600586,
            0.002729418221861124,
            -0.026801500469446182,
            0.03439873829483986,
            0.003918860107660294,
            -0.017010992392897606,
            0.023768598213791847,
            0.017719030380249023,
            0.016781890764832497,
            0.02608911320567131,
            0.04270940646529198,
            0.01835787668824196,
            -0.003879225580021739,
            0.013403059914708138,
            -0.02387017384171486,
            0.02170524187386036,
            0.027249116450548172,
            -0.02148430235683918,
            -0.03326769173145294,
            -0.02678527869284153,
            -0.032687943428754807,
            -0.007665359880775213,
            -0.03943861275911331,
            -0.0008635419071651995,
            0.011898484081029892,
            -0.020585136488080025,
            0.03213231638073921,
            -0.021086109802126884,
            0.008897700347006321,
            -0.011553574353456497,
            -0.02045762725174427,
            0.030352935194969177,
            -0.014020183123648167,
            -0.03297079727053642,
            -0.027476292103528976,
            0.01953861676156521,
            3.305956852273084e-05,
            0.02636110782623291,
            0.001105544506572187,
            0.0067994315177202225,
            -0.004464781377464533,
            -0.004765279125422239,
            -0.030250947922468185,
            0.015461242757737637,
            -0.026313114911317825,
            -0.031936079263687134,
            0.03969073295593262,
            -0.007263150066137314,
            -0.025845453143119812,
            -0.006731846835464239,
            0.026524506509304047,
            -0.01846480555832386,
            0.003193671815097332,
            -0.04526227340102196,
            -0.015300348401069641,
            0.031121091917157173,
            0.019428947940468788,
            0.01168988086283207,
            0.025804659351706505,
            0.011334152892231941,
            0.011100277304649353,
            0.031080961227416992,
            0.00518815778195858,
            -0.024492917582392693,
            0.000964791455771774,
            -0.004216592758893967,
            0.036990661174058914,
            -0.012555181980133057,
            0.02804700843989849,
            0.020565545186400414,
            0.021503718569874763,
            -0.016413865610957146,
            0.002604501787573099,
            -0.011258191429078579,
            -0.007667142897844315,
            -0.012052690610289574,
            -0.004624960944056511,
            0.014021951705217361,
            0.010793649591505527,
            -0.0017943945713341236,
            0.0009031431982293725,
            -0.007108577527105808,
            0.028104053810238838,
            -0.02659563347697258,
            0.01754571683704853,
            0.01904231682419777,
            0.014545579440891743,
            0.038482118397951126,
            -0.00948245543986559,
            -7.257921242853627e-05,
            -0.020127413794398308,
            0.02912476286292076,
            -0.011467662639915943,
            0.010650050826370716,
            -0.00436544232070446,
            -0.03013170138001442,
            0.028922896832227707,
            -0.016014287248253822,
            0.0194135382771492,
            0.03338024765253067,
            0.01117472443729639,
            0.019209707155823708,
            0.02154027670621872,
            -0.012625033035874367,
            -0.025831514969468117,
            0.03768349066376686,
            0.003801469225436449,
            -0.028895236551761627,
            -0.010146674700081348,
            0.013818795792758465,
            0.03995104879140854,
            0.012242961674928665,
            -0.027865637093782425,
            -0.016166122630238533,
            0.010882086120545864,
            0.03187158331274986,
            0.00851314328610897,
            0.010499739088118076,
            0.0300214234739542,
            -0.018153240904211998,
            -0.003392209066078067,
            0.009144643321633339,
            0.02619563229382038,
            0.041750550270080566,
            -0.006118723191320896,
            -0.03178391605615616,
            0.003897972870618105,
            -0.038244910538196564,
            0.006551695987582207,
            0.0181239303201437,
            0.021372418850660324,
            -0.01967816799879074,
            0.0011107129976153374,
            -0.028089655563235283,
            0.06093740835785866,
            -0.015675539150834084,
            0.029604818671941757,
            0.0068106199614703655,
            0.026216810569167137,
            0.01151054073125124,
            0.005641099065542221,
            0.0028000292368233204,
            -0.006028636824339628,
            -0.008309940807521343,
            0.004842135589569807,
            0.013386898674070835,
            -0.0037060920149087906,
            -0.02969224750995636,
            -0.018318144604563713,
            0.03962916508316994,
            -0.026866575703024864,
            0.022528409957885742,
            -0.02954406477510929,
            -0.021596858277916908,
            -0.028938166797161102,
            -0.03224527835845947,
            0.018979527056217194,
            -0.0069254557602107525,
            -0.014033468440175056,
            0.004409632179886103,
            -0.001993679441511631,
            -0.031316082924604416,
            -0.013918878510594368,
            0.022739941254258156,
            0.0024229201953858137,
            -0.0432879664003849,
            0.02835945598781109,
            0.0016989533323794603,
            0.024427929893136024,
            0.0021666106767952442,
            0.0053172423504292965,
            -0.04981059581041336,
            0.022614235058426857,
            0.027492128312587738,
            -0.0299304760992527,
            0.008396603167057037,
            0.003487603273242712,
            6.963958730921149e-05,
            0.0362144410610199,
            -0.028931202366948128,
            -0.023611580953001976,
            -0.008070786483585835,
            -0.03608868271112442,
            0.00927309226244688,
            0.029504140838980675,
            0.032024964690208435,
            -0.005516809411346912,
            -0.005468560848385096,
            0.029071034863591194,
            0.01791578158736229,
            -0.004398636519908905,
            -0.007451370824128389,
            0.003923514392226934,
            -0.03343601152300835,
            -0.001365065691061318,
            -0.03111780248582363,
            -0.013265487737953663,
            0.02823973260819912,
            -0.023949507623910904,
            0.029035333544015884,
            0.029630720615386963,
            0.009979665279388428,
            0.028199000284075737,
            -0.011454927735030651,
            -0.033838365226984024,
            -0.006555052008479834,
            -0.001704970607534051,
            -0.06942921876907349,
            -0.02927970513701439,
            0.01975068636238575,
            -0.023942729458212852,
            -0.027409084141254425,
            -0.01893865503370762,
            -0.0070012458600103855,
            0.007639068178832531,
            -0.0006739487289451063,
            -0.006826164200901985,
            0.021375423297286034,
            0.018138494342565536,
            -0.012681500986218452,
            0.009960548020899296,
            -0.026972120627760887,
            0.006723527330905199,
            0.022938085719943047,
            -0.0037743535358458757,
            -0.003824801417067647,
            -0.02634947933256626,
            -0.021806910634040833,
            0.015188466757535934,
            0.0128706693649292,
            0.026761939749121666,
            0.0014698071172460914,
            0.023835046216845512,
            0.02001214772462845,
            -0.03234343230724335,
            -0.02054707333445549,
            -0.011146650649607182,
            -0.04184240475296974,
            0.030017631128430367,
            -0.026998812332749367,
            0.03919580951333046,
            -0.06034558266401291,
            0.020459314808249474,
            -0.0247920211404562,
            -0.0007908684783615172,
            0.031417325139045715,
            0.005874985828995705,
            0.009042322635650635,
            -0.0275614932179451,
            0.014460563659667969,
            0.008889475837349892,
            0.0129628861322999,
            0.031122898682951927,
            -0.015979165211319923,
            -0.005307472310960293,
            -0.03322609141469002,
            0.015528258867561817,
            -0.04357155039906502,
            0.009063837118446827,
            0.0034731903579086065,
            -0.019069582223892212,
            0.017471395432949066,
            0.01644493266940117,
            -0.01451974455267191,
            0.019879050552845,
            0.028494002297520638,
            -0.006582029163837433,
            -0.00883074477314949,
            -0.028436988592147827,
            -0.038330674171447754,
            -0.00014604565512854606,
            0.010071131400763988,
            -0.01443440280854702,
            -0.010710538364946842,
            0.003812829265370965,
            -0.0217518899589777,
            -0.03743848577141762,
            0.04010285809636116,
            0.00146007991861552,
            -0.023614617064595222,
            -0.04063146188855171,
            -0.02147139422595501,
            0.0008273918647319078,
            0.004149960819631815,
            0.02174348756670952,
            -0.008996859192848206,
            -0.018452195450663567,
            0.00523624662309885,
            0.00653351005166769,
            0.0036797220818698406,
            -0.003975334577262402,
            -0.018611392006278038,
            -0.004512718878686428,
            -0.0493290089070797,
            0.014853517524898052,
            -0.025021115317940712,
            0.007342624478042126,
            -0.013929004780948162,
            -0.030845463275909424,
            0.000516268250066787,
            -0.001285670092329383,
            0.008391099981963634,
            -0.020948778837919235,
            -0.025043101981282234,
            0.008479639887809753,
            -0.020469509065151215,
            0.02909969910979271,
            -0.007707997225224972,
            -0.008095679804682732,
            0.00935628917068243,
            0.03710539638996124,
            0.014550085179507732,
            0.007523286622017622,
            0.028941797092556953,
            -0.01607118919491768,
            0.031419042497873306,
            0.026236822828650475,
            0.004436894785612822,
            -0.02504126913845539,
            0.030937841162085533,
            -0.03272782638669014,
            -0.022926947101950645,
            0.03392479941248894,
            0.02485739253461361,
            -0.00233287806622684,
            0.005670998245477676,
            -0.015416786074638367,
            0.0005950373015366495,
            0.042382195591926575,
            -0.004300118889659643,
            0.03763465955853462,
            -0.008275054395198822,
            -0.008513822220265865,
            0.01305184792727232,
            -0.02067713998258114,
            0.021534698083996773,
            0.016252268105745316,
            -0.006824121344834566,
            0.006654128897935152,
            0.006716396659612656,
            -0.006069789174944162,
            0.020011799409985542,
            0.03291577473282814,
            -0.0034382641315460205,
            0.003137029707431793,
            -0.017194733023643494,
            0.029536712914705276,
            -0.01956161856651306,
            0.03446301072835922,
            0.025457965210080147,
            0.031208893284201622,
            0.022401586174964905,
            -0.0131898308172822,
            0.02636958286166191
        ],
        [
            -0.03909650072455406,
            -0.012679575011134148,
            -0.021586934104561806,
            0.007093507330864668,
            -0.014272348955273628,
            -0.03433734178543091,
            -0.008716240525245667,
            -0.010547828860580921,
            0.029897766187787056,
            0.028663622215390205,
            -0.006428082473576069,
            -0.006822980474680662,
            0.002958907512947917,
            -0.015805650502443314,
            -0.01946440152823925,
            0.024914829060435295,
            -0.0027279481291770935,
            0.009604609571397305,
            -0.026716452091932297,
            0.03221242129802704,
            0.005710895638912916,
            -0.009827454574406147,
            -0.009476629085838795,
            0.014109892770648003,
            -0.016037771478295326,
            0.008961872197687626,
            0.02420618198812008,
            0.011215069331228733,
            0.0362212210893631,
            -0.0019437180599197745,
            -0.011677278205752373,
            -0.004127440974116325,
            -0.012198180891573429,
            -0.026846809312701225,
            0.002451137639582157,
            0.02432153932750225,
            0.03694918006658554,
            -0.02319023758172989,
            0.026984471827745438,
            -0.004233210813254118,
            -0.008938642218708992,
            0.048337750136852264,
            0.0053509981371462345,
            -0.03221461549401283,
            -0.0037996582686901093,
            0.016021423041820526,
            0.013827989809215069,
            0.01668783463537693,
            0.001441166503354907,
            -0.011724036186933517,
            0.007596661802381277,
            0.00791835319250822,
            0.043343521654605865,
            0.04314899817109108,
            -0.03139928728342056,
            -0.0017653077375143766,
            0.026087561622262,
            -0.00829561147838831,
            0.031628694385290146,
            0.0005851931637153029,
            -0.0315156951546669,
            -0.015504598617553711,
            0.043879225850105286,
            -0.027429284527897835,
            0.028346985578536987,
            -0.002743693068623543,
            -0.005025792401283979,
            0.02465936169028282,
            0.008822307921946049,
            -0.029157059267163277,
            -0.015267031267285347,
            0.003189783077687025,
            0.004571633413434029,
            0.031907059252262115,
            7.494296005461365e-05,
            -0.0012481602607294917,
            0.028483683243393898,
            0.002311384305357933,
            0.008891400881111622,
            0.03503800556063652,
            -0.011691847816109657,
            0.03121420554816723,
            -0.01710762083530426,
            -0.017591657117009163,
            -0.004420076496899128,
            0.004223165567964315,
            -0.024265320971608162,
            0.0245222095400095,
            0.015426116064190865,
            0.013034886680543423,
            -0.00440020440146327,
            -0.015892241150140762,
            -0.010553065687417984,
            -0.0376250334084034,
            0.00820540264248848,
            0.0010220750700682402,
            -0.042681239545345306,
            0.005441894289106131,
            0.0779896229505539,
            -0.018275607377290726,
            -0.020075703039765358,
            0.02591041289269924,
            -0.02263430319726467,
            -0.0010314439423382282,
            0.019454823806881905,
            -0.01996055245399475,
            -0.00862927921116352,
            0.02355312742292881,
            0.013246873393654823,
            -0.030694512650370598,
            0.007824650965631008,
            0.03286066651344299,
            -0.021523194387555122,
            0.02330865152180195,
            0.019791103899478912,
            -0.01791565865278244,
            -0.000967524538282305,
            -0.0059306323528289795,
            -0.005469940137118101,
            -0.0052962652407586575,
            -0.014238081872463226,
            -0.013979117386043072,
            -0.046327535063028336,
            -0.011695491150021553,
            0.031943269073963165,
            -0.0071814279071986675,
            0.019426314160227776,
            -0.0013082120567560196,
            0.01983059011399746,
            -0.02212868630886078,
            -0.01452005747705698,
            -0.046774301677942276,
            -0.009712460450828075,
            -0.033009883016347885,
            -0.014133873395621777,
            -0.0061821043491363525,
            0.03173624724149704,
            -0.04060244560241699,
            0.006551199126988649,
            0.03926755487918854,
            -0.004777505062520504,
            0.014132777228951454,
            -0.020273391157388687,
            -0.03000924549996853,
            -0.015250157564878464,
            -0.011907449923455715,
            0.03727882355451584,
            -0.006051047705113888,
            -0.030409101396799088,
            -0.007400299422442913,
            -0.0036835763603448868,
            -0.05606740713119507,
            -0.031646016985177994,
            -0.01534685306251049,
            0.021732009947299957,
            0.030846988782286644,
            0.01249692589044571,
            -0.005643433891236782,
            0.035505253821611404,
            0.026075832545757294,
            -0.006433343514800072,
            0.028536612167954445,
            -0.00896335206925869,
            0.037348367273807526,
            -0.014448551461100578,
            -0.04621512070298195,
            -0.026653015986084938,
            0.0035399910993874073,
            0.04079076647758484,
            -0.021034996956586838,
            0.02671574242413044,
            0.025717779994010925,
            -0.00183194933924824,
            -0.0292829442769289,
            -0.01672828197479248,
            -0.02322622574865818,
            0.028983745723962784,
            -0.006869559641927481,
            -0.002560764318332076,
            0.002379270503297448,
            0.02444988116621971,
            -0.002080896170809865,
            -0.013248307630419731,
            0.02026115544140339,
            0.029712680727243423,
            -0.024084055796265602,
            -0.02422894909977913,
            -0.012958680279552937,
            0.003932468127459288,
            0.0032421844080090523,
            0.01694357953965664,
            -0.03096739389002323,
            0.012622163631021976,
            0.021482210606336594,
            -0.016026571393013,
            -0.0027850675396621227,
            0.0030854595825076103,
            -0.013540149666368961,
            -0.03681064024567604,
            -0.0053739240393042564,
            0.008587195537984371,
            0.015760287642478943,
            -0.02263898402452469,
            -0.017503516748547554,
            0.0424436554312706,
            0.007660919800400734,
            -0.002109007677063346,
            -0.010187868028879166,
            -0.04425607621669769,
            -0.006986681837588549,
            0.011218948289752007,
            -0.02808190882205963,
            0.01845145970582962,
            -0.016158685088157654,
            -0.022201528772711754,
            0.02073296345770359,
            0.03714083507657051,
            -0.00589747866615653,
            -0.013815860264003277,
            -3.8360722101060674e-05,
            -0.032972946763038635,
            0.038511037826538086,
            -0.0018442663131281734,
            0.008490446023643017,
            -0.015553423203527927,
            0.010132619179785252,
            0.019068611785769463,
            -0.004902149084955454,
            -0.004977927077561617,
            -0.009507113136351109,
            -0.00889570638537407,
            0.028606656938791275,
            0.035358838737010956,
            -0.02529078535735607,
            -0.02411249652504921,
            -0.017860565334558487,
            -0.06330868601799011,
            -0.008498241193592548,
            0.0004408137465361506,
            0.03981500491499901,
            -0.03579207509756088,
            -0.0334571972489357,
            0.031201910227537155,
            -0.0029140969272702932,
            0.015998709946870804,
            -0.011466316878795624,
            -0.016903089359402657,
            0.026530936360359192,
            0.00700062932446599,
            -0.04235472157597542,
            -0.0030787340365350246,
            -0.002817894332110882,
            -0.003153849160298705,
            0.033434268087148666,
            0.02736440673470497,
            -0.012402213178575039,
            0.0173663143068552,
            -0.03364558890461922,
            -0.020554689690470695,
            -0.009031128138303757,
            -0.011356038972735405,
            -0.019160723313689232,
            -0.03312457725405693,
            0.0008476019720546901,
            0.031802885234355927,
            -0.028484143316745758,
            0.008735399693250656,
            0.022850144654512405,
            0.012118473649024963,
            -0.00984328892081976,
            6.279363151406869e-05,
            0.026341278105974197,
            0.019225165247917175,
            -0.005353640764951706,
            0.031590454280376434,
            0.019422536715865135,
            0.02688414976000786,
            -0.024189362302422523,
            -0.0034355714451521635,
            0.01368468813598156,
            0.0026194818783551455,
            0.020721500739455223,
            -0.021468864753842354,
            -0.045128487050533295,
            0.024109499529004097,
            0.02570504881441593,
            -0.013194647617638111,
            0.004604083951562643,
            0.013247037306427956,
            -0.019181745126843452,
            0.02059353142976761,
            -0.0017928510205820203,
            -0.0004190339532215148,
            0.024971934035420418,
            0.006034472491592169,
            0.003718704218044877,
            -0.03319684788584709,
            -0.005267192609608173,
            0.02165917120873928,
            0.008901846595108509,
            0.008493948727846146,
            0.02999979443848133,
            0.014021996408700943,
            -0.013358223251998425,
            -0.02431618608534336,
            0.02467292547225952,
            -0.02670687809586525,
            -0.04532017931342125,
            0.009663409553468227,
            -0.01312934048473835,
            -0.026636304333806038,
            -0.010193344205617905,
            0.026253849267959595,
            0.029075458645820618,
            -0.001354007632471621,
            0.0208855289965868,
            0.012497029267251492,
            0.010276125743985176,
            -0.02825573831796646,
            0.02004839852452278,
            -0.012311000376939774,
            0.01949118822813034,
            0.01013864018023014,
            -0.020494122058153152,
            -0.02637999877333641,
            -0.006097877398133278,
            -0.0411619134247303,
            -0.029075777158141136,
            -0.03211677819490433,
            -0.004440221004188061,
            -0.027366485446691513,
            0.004330341704189777,
            -0.021545112133026123,
            0.029264919459819794,
            -0.024347655475139618,
            -0.038942642509937286,
            -0.026639258489012718,
            -0.0022332745138555765,
            0.008059350773692131,
            -0.00029978257953189313,
            0.03354247659444809,
            0.03451269119977951,
            -0.03671169653534889,
            -0.0312212947756052,
            0.006996281910687685,
            -0.04970904067158699,
            0.016310779377818108,
            -0.026600588113069534,
            -0.015774883329868317,
            0.016321402043104172,
            0.01007540337741375,
            0.008792965672910213,
            0.008509518578648567,
            -0.03180929273366928,
            -0.005218567792326212,
            0.027311990037560463,
            0.030605724081397057,
            0.010893173515796661,
            -0.024493204429745674,
            0.007924634031951427,
            0.024369128048419952,
            -0.008149581961333752,
            0.03236319497227669,
            0.03349918872117996,
            -0.030337676405906677,
            -0.018498463556170464,
            -0.0007771473610773683,
            0.0049489145167171955,
            0.014285922050476074,
            0.015132247470319271,
            -0.0039031009655445814,
            0.0188945010304451,
            -0.015186919830739498,
            -0.044428080320358276,
            -0.031380992382764816,
            -0.02747308276593685,
            -0.00503145158290863,
            0.007730284705758095,
            -0.004509203135967255,
            0.023205658420920372,
            0.027146782726049423,
            0.032679080963134766,
            -0.03227759897708893,
            -0.008117277175188065,
            0.02533363737165928,
            0.027153901755809784,
            -0.00039808652945794165,
            -0.04956554248929024,
            0.008431069552898407,
            0.02638319879770279,
            0.0017782229697331786,
            0.019904183223843575,
            -0.049191612750291824,
            0.016533663496375084,
            -0.0355391800403595,
            -0.008404147811233997,
            -0.02368750609457493,
            0.01388484612107277,
            0.005085097625851631,
            0.027717918157577515,
            0.008615721948444843,
            -0.04433956369757652,
            0.004568652715533972,
            0.006673967931419611,
            -0.01757402904331684,
            0.03393720090389252,
            -0.029613543301820755,
            -0.043799079954624176,
            0.011161195114254951,
            0.02315080538392067,
            -0.01325695589184761,
            -0.001547023537568748,
            -0.023093614727258682,
            0.020087353885173798,
            -0.019464822486042976,
            0.028297914192080498,
            0.017954112961888313,
            0.006963977124541998,
            -0.019315768033266068,
            -0.0160689577460289,
            0.005485678091645241,
            -0.017597133293747902,
            0.00400807848200202,
            -0.006935350131243467,
            0.03008483350276947,
            -0.013764592818915844,
            -0.02341333031654358,
            -0.0036763634998351336,
            0.0253811776638031,
            0.04213937371969223,
            0.018406245857477188,
            0.029652085155248642,
            -0.0126664899289608,
            0.00028262348496355116,
            -0.03219559043645859,
            -0.010461896657943726,
            0.027518756687641144,
            0.0061609093099832535,
            0.018337242305278778,
            0.018142741173505783,
            -0.03570323437452316,
            -0.03240211680531502,
            0.003960445523262024,
            0.019200533628463745,
            -0.020337555557489395,
            -0.02171134203672409,
            -0.015211451798677444,
            0.028897464275360107,
            -0.017775969579815865,
            -0.012776302173733711,
            0.03234238177537918,
            0.0018092701211571693,
            -0.02740762196481228,
            0.0030557867139577866,
            0.03701496496796608,
            0.008258925750851631,
            0.01301388256251812,
            0.02558302879333496,
            0.0017140660202130675,
            -0.007288395427167416,
            -0.006788855418562889,
            0.0010973947355523705,
            0.010342475958168507,
            -0.001817074022255838,
            0.003995492123067379,
            -0.014525565318763256,
            -0.03086935728788376,
            0.013483488000929356,
            -0.015045473352074623,
            0.017324376851320267,
            -0.005054360721260309,
            -0.012143628671765327,
            0.00823011714965105,
            0.012318752706050873,
            -4.4152380723971874e-05,
            0.016347555443644524,
            0.00774443568661809,
            -0.02684042975306511,
            -0.006949249189347029,
            0.02333100140094757,
            0.01734606921672821,
            -0.013205070048570633,
            0.01311406958848238,
            0.023013293743133545,
            0.02232605218887329,
            -0.00013888947432860732,
            -0.029524361714720726,
            -0.01312727015465498,
            -0.043858788907527924,
            0.010651899501681328,
            0.011940560303628445,
            0.004107001703232527,
            0.011402157135307789,
            -0.024466639384627342,
            0.024742521345615387,
            -0.01456003449857235,
            0.013871056959033012,
            -0.012588627636432648,
            -0.0022013839334249496,
            0.018287617713212967,
            0.025963403284549713,
            0.004094255622476339,
            0.017993159592151642,
            -0.016471778973937035,
            -0.0210689939558506,
            0.025761209428310394,
            0.021604597568511963,
            -0.02366555668413639,
            -0.014330578967928886,
            -0.0022033315617591143,
            0.0027936925180256367,
            -0.056791190057992935,
            0.012929181568324566,
            -0.004038685001432896,
            -0.002969858469441533,
            0.02011634223163128,
            0.025279201567173004,
            0.004961689468473196,
            0.027667924761772156,
            -0.015265898779034615,
            -0.016697127372026443,
            -0.005628671031445265,
            0.012580313719809055,
            0.01914314553141594,
            0.029518792405724525,
            -0.03597203642129898,
            -0.08411865681409836,
            0.023798048496246338,
            -0.0014241941971704364,
            0.002582659013569355,
            0.014940489083528519,
            0.0015673137968406081,
            0.026812700554728508,
            0.01518483366817236,
            0.025957396253943443,
            0.006806110963225365,
            -0.02467437833547592,
            -0.014966716058552265,
            -0.019650258123874664,
            -0.00710455235093832,
            0.013417432084679604,
            0.02133040875196457,
            0.023419883102178574,
            0.019113723188638687,
            0.0009639494819566607,
            0.03600459545850754,
            0.0029480222146958113,
            -0.05681299790740013,
            0.024489354342222214,
            0.006297519896179438,
            -0.01815122738480568,
            -0.01773255132138729,
            0.02508024126291275,
            -0.03870661184191704,
            -0.024654943495988846,
            -0.004794723354279995,
            -0.009341306984424591,
            -0.0038000561762601137,
            -0.0008033161284402013,
            0.018513889983296394,
            0.009704680182039738,
            0.01989373192191124,
            0.005329113453626633,
            0.014565091580152512,
            0.0030769554432481527,
            -0.030041882768273354,
            0.03159007802605629,
            0.02230527438223362,
            -0.01822366937994957,
            -0.024040687829256058,
            -0.023441825062036514,
            0.02131054736673832,
            0.008259379304945469,
            0.02188286744058132,
            -0.008042815141379833,
            0.01867489516735077,
            -0.014180430211126804,
            -0.0027046967297792435,
            -0.01827928237617016,
            -0.002655115444213152,
            0.027891848236322403,
            0.011192806996405125,
            0.030118288472294807,
            -0.0023738269228488207,
            -0.016538655385375023,
            0.05447351559996605,
            0.020037245005369186,
            -0.0018537556752562523,
            0.012153535149991512,
            -0.009063354693353176,
            -0.02555915154516697,
            0.013922646641731262,
            0.005314947571605444,
            0.005580950994044542,
            0.006607481278479099,
            0.020228970795869827,
            0.013250087387859821,
            -0.019979313015937805,
            0.015068607404828072,
            -0.046270184218883514,
            0.009732223115861416,
            -0.02418649196624756,
            0.04460114240646362,
            -0.008699024096131325,
            0.038345109671354294,
            0.03205827623605728,
            -0.0041657667607069016,
            -0.0102135194465518,
            -0.009554535150527954,
            0.02588929608464241,
            -0.02772904932498932,
            -0.020478438585996628,
            0.030750509351491928,
            0.022678175941109657,
            -0.015797492116689682,
            -0.016053805127739906,
            0.01020741369575262,
            -0.020481351763010025,
            0.015393242239952087,
            0.009318578988313675,
            0.015136783942580223,
            -0.033973824232816696,
            0.028453055769205093,
            -0.023585384711623192,
            -0.013076664879918098,
            0.016093255952000618,
            -0.002053029602393508,
            0.006934985518455505,
            -0.00931108184158802,
            -0.009388391859829426,
            0.02563987486064434,
            -0.02160697616636753,
            -0.016161048784852028,
            -0.013944191858172417,
            0.031122535467147827,
            -0.014819568023085594,
            -0.024648452177643776,
            0.03755141794681549,
            -0.015723135322332382,
            0.027156181633472443,
            0.031090687960386276,
            -0.08150873333215714,
            -0.06747793406248093,
            0.022180046886205673,
            0.022604046389460564,
            -0.004477658309042454,
            0.0348246693611145,
            -0.00979880802333355,
            -0.008213941939175129,
            -0.011203678324818611,
            0.019975772127509117,
            -0.020180419087409973,
            -0.0004042555228807032,
            0.025005260482430458,
            -0.0054735285229980946,
            -0.011996546760201454,
            0.019754134118556976,
            0.02353561855852604,
            -0.008823220618069172,
            0.001477723941206932,
            -0.031415972858667374,
            0.02107308991253376,
            -0.013153217732906342,
            0.007408815901726484,
            -0.01898677833378315,
            -0.010617597959935665,
            0.003024284029379487,
            0.0015233614249154925,
            0.005355839617550373,
            0.029413515701889992,
            -0.002320876345038414,
            0.007310671731829643,
            -0.022649383172392845,
            0.0036607400979846716,
            -0.01165018044412136,
            0.0003774612850975245,
            -0.002008067909628153,
            0.030863087624311447,
            0.006201927084475756,
            -0.03275046870112419,
            0.011908290907740593,
            0.010479847900569439,
            -0.02137301303446293,
            0.020754963159561157,
            0.007008103653788567,
            0.02561154030263424,
            -0.015467962250113487,
            0.01683366484940052,
            -0.015407168306410313,
            0.005867945495992899,
            0.023314954712986946,
            -0.0162081066519022,
            0.002931637689471245,
            -0.010961116291582584,
            0.012436702847480774,
            -0.01727517694234848,
            0.030861597508192062,
            -0.0144563689827919,
            0.01498723030090332,
            0.014090174809098244,
            0.005802689585834742,
            -0.002385564846917987,
            0.003671296639367938,
            -0.009825467132031918,
            0.028135206550359726,
            -0.0038707635831087828,
            -0.010337364859879017,
            -0.0012739533558487892,
            -0.014937430620193481,
            -0.03635132685303688,
            -0.015648240223526955,
            0.020789198577404022,
            -0.002421092242002487,
            -0.011049355380237103,
            0.006675433367490768,
            -0.02672119252383709,
            0.04140670225024223,
            0.019146287813782692,
            0.02708054520189762,
            -0.017080403864383698,
            0.004881030414253473,
            0.01804676093161106,
            0.005328454542905092,
            0.0093683497980237,
            0.01679268293082714,
            0.025030260905623436,
            0.02970496378839016,
            0.026257382705807686,
            0.034262146800756454,
            0.010395239107310772,
            0.031055347993969917,
            0.005531022325158119,
            0.001698920619674027,
            0.004675060976296663,
            0.027953529730439186,
            -0.01576290838420391,
            -0.002162714721634984,
            0.014338101260364056,
            0.0038584964349865913,
            0.03032219596207142,
            -0.004755564499646425,
            0.032296959310770035,
            -0.015589054673910141,
            -0.01147680077701807,
            -0.005476448219269514,
            0.00561852240934968,
            -0.05054030567407608,
            -0.017226142808794975,
            -0.025076186284422874,
            -0.009069622494280338,
            0.007836437784135342,
            -0.00023259397130459547,
            -0.019442714750766754,
            0.0076555004343390465,
            -0.0002097008837154135,
            -0.021425802260637283,
            0.009226433001458645,
            -0.033999476581811905,
            -0.0001780334278009832,
            -0.02757241763174534,
            -0.04289032891392708,
            -0.006443613208830357,
            -0.02284843847155571,
            -0.012843048200011253,
            0.007800017949193716,
            0.03175951540470123,
            -0.029644129797816277,
            0.035944707691669464,
            -0.005773170851171017,
            0.004226855933666229,
            -0.0006210041465237737,
            -0.006491468753665686,
            0.030758777633309364,
            0.014755510725080967,
            0.03412483632564545,
            0.003761033294722438,
            0.02627098187804222,
            -0.0236425269395113,
            0.031903937458992004,
            0.011294093914330006,
            -0.020731046795845032,
            0.013786938041448593,
            -0.00826990231871605,
            0.019476475194096565,
            0.02068990096449852,
            0.022190473973751068,
            0.01968253217637539,
            -0.0021676223259419203,
            -0.013580665923655033,
            0.03330884501338005,
            0.020165488123893738,
            -0.0017739783506840467,
            -0.013309315778315067,
            0.056248150765895844,
            0.004389792215079069,
            0.006167883053421974,
            -0.016323978081345558,
            0.010258483700454235,
            0.0056123025715351105,
            -0.0336059033870697,
            -0.012663165107369423,
            -0.028745640069246292,
            0.00988068152219057,
            0.043416280299425125,
            0.030052784830331802,
            0.01879274845123291,
            -0.010041320696473122,
            -0.030808094888925552,
            -0.018918143585324287,
            -0.028694216161966324,
            0.013616250827908516,
            0.009768253192305565,
            0.013349666260182858,
            -0.013714682310819626,
            0.04472869262099266,
            -0.030230538919568062,
            -0.025059647858142853,
            -0.031443916261196136,
            -0.006189616862684488,
            -0.024112896993756294,
            -0.006494385655969381,
            0.021596470847725868,
            0.002611419651657343,
            0.022060241550207138,
            0.03605543449521065,
            -0.0189345795661211,
            0.012433750554919243,
            -0.01754011958837509,
            0.009032251313328743,
            -0.02435118518769741,
            0.0026275317650288343,
            -0.034050747752189636,
            -0.034547511488199234,
            0.030319925397634506,
            -0.07478788495063782,
            0.022948844358325005,
            -0.00800100713968277,
            0.012480841018259525,
            0.01836160570383072,
            0.0029496171046048403,
            0.015911437571048737,
            -0.0018744291737675667,
            0.009126847609877586,
            0.0032289871014654636,
            -0.01970556750893593,
            0.02192811481654644,
            -0.02282400242984295,
            0.0031306762248277664,
            -0.012543282471597195,
            0.014577740803360939,
            -0.01785825565457344,
            0.014901098795235157,
            -0.014717724174261093,
            0.011202678084373474,
            0.043258730322122574,
            -0.01241830550134182,
            0.023447785526514053,
            0.018288522958755493,
            -0.0035185706801712513,
            -0.022816263139247894,
            0.02161788009107113,
            0.0014150511706247926,
            0.010526945814490318,
            0.022677328437566757,
            -0.012816652655601501,
            -0.03381190076470375,
            0.019373970106244087,
            0.01970406249165535,
            -0.01034521870315075,
            -0.010639702901244164,
            0.002034078584983945,
            0.020763002336025238,
            -0.019869642332196236,
            0.03618170693516731,
            -0.023477742448449135,
            -0.010881006717681885,
            0.017877032980322838,
            -0.020757563412189484,
            0.015064272098243237,
            -0.020112209022045135,
            -0.016711199656128883,
            -0.016215139999985695,
            0.011858918704092503,
            0.012698140926659107,
            0.016516711562871933,
            0.0008842063252814114,
            -0.033062003552913666,
            -0.015642408281564713,
            -0.03470144420862198,
            0.026426993310451508,
            0.00017127543105743825,
            0.0034253972116857767,
            -0.01079593412578106,
            -0.04016755521297455,
            0.022552914917469025,
            -0.029301663860678673,
            -0.02490709163248539,
            -0.025419998914003372,
            0.018221592530608177,
            0.012880044989287853,
            -0.004934444557875395,
            0.009413883090019226,
            -0.00409459276124835,
            0.006952478084713221,
            0.028148287907242775,
            -0.008906315080821514,
            -0.02697358839213848,
            0.0015835834201425314,
            0.02689472772181034,
            0.022853411734104156,
            -0.015840355306863785,
            0.008841046132147312,
            -0.0011969800107181072,
            -0.0024104772601276636,
            0.005024221260100603,
            -0.022233836352825165,
            -0.006824209820479155,
            0.001582993776537478,
            0.021529486402869225,
            -0.013964515179395676,
            -0.0205042976886034,
            -0.0021000041160732508,
            -0.010088497772812843,
            0.012833359651267529,
            0.01683838479220867,
            -0.028100566938519478,
            0.005369137041270733,
            -0.034462664276361465,
            0.0334894135594368,
            0.008093174546957016,
            0.002084811683744192,
            -0.002403168473392725,
            0.006617552600800991,
            0.0007553673931397498,
            -0.03926369920372963,
            0.0261656753718853,
            -0.0139006981626153,
            0.028048953041434288,
            -0.013005612418055534,
            -0.0011450337478891015,
            0.008672207593917847,
            0.008924396708607674,
            0.02904602140188217,
            0.021143121644854546,
            -0.015350555069744587,
            0.030130043625831604,
            0.005204261280596256,
            0.0033299021888524294,
            -0.0137472003698349,
            0.02676338516175747,
            -0.036151230335235596,
            0.007095793727785349,
            -0.001986114075407386,
            0.0045818448998034,
            -0.02613631635904312,
            0.013604658655822277,
            -0.00978026632219553,
            -0.014938636682927608,
            0.015958147123456,
            0.03472505137324333,
            0.0004178502131253481,
            -0.011767876334488392,
            0.00940705556422472,
            0.027745435014367104,
            0.008413225412368774,
            0.021779712289571762,
            -0.04161675274372101,
            -0.022084612399339676,
            0.0021831232588738203,
            0.025056732818484306,
            -0.03132373467087746,
            -0.034578777849674225,
            -0.021961785852909088,
            0.015522287227213383,
            0.015218316577374935,
            0.009344450198113918,
            0.06003571301698685,
            0.03266780823469162,
            0.022712450474500656,
            -0.03221047669649124,
            0.02093261294066906,
            -0.007565023843199015,
            0.0408666618168354,
            0.015439302660524845,
            -0.01590055041015148,
            0.016058016568422318,
            -0.030821632593870163,
            0.019183728843927383,
            0.003958386834710836,
            -0.004497311543673277,
            0.007225202862173319,
            0.010070819407701492,
            -0.018715836107730865,
            0.013781925663352013,
            -0.013683803379535675,
            -0.036418937146663666,
            -0.05968515947461128,
            -0.013696021400392056,
            0.03945954516530037,
            -0.0016028864774852991,
            -0.01903223805129528,
            -0.003812838112935424,
            -0.004924010019749403,
            -0.015523198060691357,
            0.021555373445153236,
            -0.021323949098587036,
            0.0045999279245734215,
            0.02279321290552616,
            0.015102080069482327,
            0.01507645845413208,
            0.02850913628935814,
            0.03176306560635567,
            0.02403939515352249,
            -0.014186837710440159,
            0.023520253598690033,
            0.04708169773221016,
            -0.03229677677154541,
            -0.026160243898630142,
            -0.015879729762673378,
            0.02650265581905842,
            0.015256010927259922,
            -0.006940508261322975,
            -0.05196687579154968,
            -0.028623003512620926
        ],
        [
            0.0008466996951028705,
            0.02171441726386547,
            -0.02230447717010975,
            -0.014609646052122116,
            0.0006456914125010371,
            0.0064317574724555016,
            0.011610074900090694,
            -0.013156277127563953,
            -0.015018577687442303,
            -0.013702459633350372,
            0.01400502398610115,
            -0.030572030693292618,
            0.01310200896114111,
            0.018310993909835815,
            0.029587943106889725,
            0.03602432459592819,
            -0.009171789512038231,
            -0.024368351325392723,
            -0.026673652231693268,
            0.009289869107306004,
            -0.015062037855386734,
            -0.029352320358157158,
            -0.08284170180559158,
            0.006332797929644585,
            0.02320868708193302,
            0.0042830463498830795,
            -0.017374858260154724,
            0.012766011990606785,
            0.0332455076277256,
            0.029346289113163948,
            0.010795696638524532,
            0.0003646626719273627,
            0.026072459295392036,
            0.01735188625752926,
            0.007466856390237808,
            -0.009450497105717659,
            -0.008171183057129383,
            -0.020513299852609634,
            0.02156560681760311,
            -0.008242001757025719,
            -8.49522984935902e-05,
            -0.06502742320299149,
            0.014337249100208282,
            0.01415481697767973,
            0.005511067342013121,
            0.006735871080309153,
            0.000260839588008821,
            0.024780433624982834,
            0.0028167979326099157,
            -0.007431000005453825,
            -0.029206957668066025,
            0.016512876376509666,
            0.003615334862843156,
            0.011620039120316505,
            -0.019092636182904243,
            0.041896067559719086,
            0.014334049075841904,
            -0.021296817809343338,
            0.039127547293901443,
            0.019776560366153717,
            0.010394393466413021,
            -0.006844548974186182,
            0.01717216894030571,
            -0.016995128244161606,
            -0.00629472965374589,
            0.025427214801311493,
            0.0035581232514232397,
            -0.026592688634991646,
            0.004789805039763451,
            0.03381031006574631,
            0.0018562005134299397,
            -0.024986566975712776,
            -0.006740851327776909,
            -0.006989393383264542,
            0.07829150557518005,
            0.03458784148097038,
            0.04060833528637886,
            -0.010723723098635674,
            0.012361709028482437,
            -0.00522217620164156,
            0.02186412736773491,
            -0.005742655601352453,
            -0.027593063190579414,
            -0.008515278808772564,
            0.0077451663091778755,
            -0.0053887031972408295,
            -0.01440333854407072,
            -0.03196248412132263,
            0.00791895855218172,
            0.06519228965044022,
            -0.015246937982738018,
            0.00728602847084403,
            0.004407333210110664,
            0.00045105788740329444,
            -0.009472021833062172,
            -0.011456426233053207,
            -0.003559008240699768,
            -0.017917392775416374,
            -0.049215175211429596,
            0.015214413404464722,
            -0.0012896163389086723,
            0.016987672075629234,
            -0.019317278638482094,
            -0.02149520255625248,
            -0.03169015422463417,
            -0.004230912309139967,
            -0.012119189836084843,
            -0.027863269671797752,
            -0.05168408155441284,
            -0.003760768100619316,
            0.010248644277453423,
            -0.007172345649451017,
            0.01921345293521881,
            0.0035616913810372353,
            -0.09580931067466736,
            0.02209395356476307,
            0.0023767859674990177,
            -0.01986605115234852,
            0.028744475916028023,
            0.035838328301906586,
            0.002407185500487685,
            0.000586116686463356,
            0.05831720307469368,
            -0.008246301673352718,
            -0.013470571488142014,
            -0.039410512894392014,
            -0.0023530314210802317,
            -0.006701416335999966,
            0.025307629257440567,
            -0.011295134201645851,
            -0.002896318444982171,
            -0.0037985038943588734,
            0.0005582060548476875,
            0.027958830818533897,
            0.022132912650704384,
            0.020791495218873024,
            -0.024519607424736023,
            -0.04081428423523903,
            0.0030261215288192034,
            -0.01689905673265457,
            -0.007558200508356094,
            -0.017499247565865517,
            0.03217669576406479,
            0.027356421574950218,
            -0.038538213819265366,
            -0.060878414660692215,
            0.018700288608670235,
            0.02328152023255825,
            -0.04034847393631935,
            -0.016492100432515144,
            -0.007748243398964405,
            -0.007886248640716076,
            -0.021831978112459183,
            0.01584508828818798,
            -0.031469736248254776,
            0.01886492408812046,
            -0.023404303938150406,
            0.01261368952691555,
            0.014598441310226917,
            0.003392567625269294,
            0.018924914300441742,
            -0.028966626152396202,
            -0.04182446002960205,
            0.018363960087299347,
            -0.02401629462838173,
            0.04883534461259842,
            -0.042347926646471024,
            -0.02956279180943966,
            -0.04053470119833946,
            0.01471666805446148,
            -0.0013062988873571157,
            0.028314583003520966,
            -0.06906091421842575,
            -0.037335798144340515,
            -0.053925931453704834,
            -0.048879653215408325,
            -0.0016027864767238498,
            -0.04568204656243324,
            0.009883591905236244,
            0.024432333186268806,
            0.046694885939359665,
            0.016394350677728653,
            -0.06754498183727264,
            -0.0211948249489069,
            0.009492306970059872,
            0.0015287634450942278,
            0.015776770189404488,
            0.003921711817383766,
            -0.016472185030579567,
            0.022464321926236153,
            -0.013609972782433033,
            -0.03639857843518257,
            -0.019665934145450592,
            -0.013292849063873291,
            0.0025812082458287477,
            -0.019972024485468864,
            0.010034676641225815,
            0.011982645839452744,
            0.03137066587805748,
            -0.04751721769571304,
            -0.014684390276670456,
            0.0016727907350286841,
            0.02676520310342312,
            0.010389703325927258,
            -0.0697261244058609,
            0.019801359623670578,
            0.04565928131341934,
            0.04454702511429787,
            -0.009362376295030117,
            0.004888927564024925,
            -0.05285774916410446,
            0.020208517089486122,
            0.012118309736251831,
            -0.0008799378992989659,
            0.005459025502204895,
            -0.020424555987119675,
            0.03202414885163307,
            -0.011181524954736233,
            -0.02650190331041813,
            0.019857747480273247,
            0.024694954976439476,
            -0.013126056641340256,
            -0.007497927639633417,
            -0.023882336914539337,
            -0.00042200105963274837,
            0.012903688475489616,
            0.015169244259595871,
            0.010606124997138977,
            -0.03287015110254288,
            0.02890697866678238,
            -0.009702075272798538,
            0.01843886636197567,
            0.011765179224312305,
            0.021850382909178734,
            0.026184260845184326,
            -0.005432440899312496,
            0.05288583040237427,
            0.016586724668741226,
            0.008216596208512783,
            0.02084920182824135,
            0.002344672568142414,
            -0.01296250056475401,
            0.041063178330659866,
            -0.009214282967150211,
            -0.012974971905350685,
            -0.01752857118844986,
            0.007457285188138485,
            -0.02868303656578064,
            0.011667080223560333,
            -0.006874426268041134,
            -0.007153619546443224,
            0.030750975012779236,
            0.01983468234539032,
            -0.007229537703096867,
            -0.01710490882396698,
            0.03241681680083275,
            -0.00013380590826272964,
            -0.03165547177195549,
            0.006374967284500599,
            0.003853960195556283,
            0.025843188166618347,
            0.010579480789601803,
            0.00888310931622982,
            0.036003220826387405,
            0.04616718739271164,
            0.0013914324808865786,
            -0.01640847697854042,
            -0.022312050685286522,
            0.006252927239984274,
            -0.035021986812353134,
            0.001780462684109807,
            -0.03141368180513382,
            -0.023094702512025833,
            -0.003333399770781398,
            -0.040907539427280426,
            -0.0026120715774595737,
            0.00918000191450119,
            -0.041660621762275696,
            -0.04707280546426773,
            0.0029114526696503162,
            0.014788076281547546,
            0.013086538761854172,
            -0.0272848941385746,
            -0.021701626479625702,
            -0.0350269079208374,
            -0.023231249302625656,
            -0.04614390805363655,
            -0.050645969808101654,
            0.03346969932317734,
            -0.016298748552799225,
            0.010673049837350845,
            0.017051927745342255,
            0.02093178778886795,
            0.03878304734826088,
            0.03389759361743927,
            -0.046540796756744385,
            0.04951212555170059,
            -0.044646698981523514,
            0.05035258084535599,
            -0.0028021144680678844,
            0.023225385695695877,
            0.014278072863817215,
            0.0063986508175730705,
            0.0054820082150399685,
            -0.010605333372950554,
            -0.04349342733621597,
            0.013360532000660896,
            -0.0005238557350821793,
            0.0012629758566617966,
            0.02007431723177433,
            0.0565267875790596,
            -0.0029324484057724476,
            0.011937272734940052,
            -0.001155251869931817,
            -0.03142096474766731,
            0.014331850223243237,
            0.025105098262429237,
            0.005811695475131273,
            0.007780751679092646,
            -0.013641460798680782,
            -0.01249948050826788,
            -0.027562269940972328,
            -0.035210028290748596,
            -0.0005956050008535385,
            5.397094355430454e-05,
            -0.01515932846814394,
            0.04599716141819954,
            0.008169789798557758,
            0.02278449945151806,
            -0.0032295703422278166,
            -0.028205931186676025,
            -0.03545133024454117,
            0.014630751684308052,
            0.03269205242395401,
            0.023065883666276932,
            0.04964939132332802,
            0.01175873912870884,
            0.020149311050772667,
            0.021532172337174416,
            0.008140633814036846,
            -0.0017699436284601688,
            0.04873102158308029,
            -0.03481984883546829,
            -0.012370632961392403,
            0.016569826751947403,
            -0.04680160805583,
            0.030494149774312973,
            0.023090410977602005,
            0.016010526567697525,
            -0.01194437500089407,
            -0.02084226720035076,
            0.011950600892305374,
            0.03232892230153084,
            0.01796071045100689,
            -0.056755371391773224,
            -0.01356936153024435,
            0.01919085718691349,
            0.021841801702976227,
            -0.04179545119404793,
            0.03855758160352707,
            -0.017581475898623466,
            0.009051249362528324,
            0.03231441602110863,
            -0.012488212436437607,
            -0.021245967596769333,
            0.021796902641654015,
            0.07230488210916519,
            0.01714945025742054,
            -0.03946731612086296,
            0.012707877904176712,
            -0.02522514946758747,
            0.019513817504048347,
            0.02612471953034401,
            -0.013679731637239456,
            -0.002617497695609927,
            0.04223458468914032,
            -0.0174386128783226,
            -0.05509011819958687,
            -0.03214345872402191,
            0.009309358894824982,
            -0.08801811933517456,
            -0.020160887390375137,
            0.01864139549434185,
            0.006568319164216518,
            -0.0008196179987862706,
            0.02980026789009571,
            -0.0005190429510548711,
            0.003944057505577803,
            -0.05200641229748726,
            -0.024137476459145546,
            0.026545971632003784,
            0.017890481278300285,
            0.002226531272754073,
            -0.015806013718247414,
            -0.0361502505838871,
            0.0052642785012722015,
            -0.007897824048995972,
            0.02721763774752617,
            0.024302495643496513,
            -0.06317678838968277,
            0.02078251913189888,
            0.012018729001283646,
            0.002802057657390833,
            -0.014304842799901962,
            -0.01015356183052063,
            -0.03492521122097969,
            0.0075669726356863976,
            -0.014460476115345955,
            0.007251118775457144,
            0.03673478960990906,
            -0.026368772611021996,
            0.017657088115811348,
            -0.035878654569387436,
            -0.08707331120967865,
            0.00672354968264699,
            -0.032788146287202835,
            -0.0047346316277980804,
            -0.03741884231567383,
            -0.005920175928622484,
            0.03237012401223183,
            -0.0059585957787930965,
            -0.032852452248334885,
            -0.026303770020604134,
            -0.024187343195080757,
            0.08096881210803986,
            0.0037266546860337257,
            0.009310181252658367,
            0.002272952813655138,
            0.0016974132740870118,
            -0.0307157002389431,
            -0.011206772178411484,
            0.006653128657490015,
            0.03385810926556587,
            -0.025059208273887634,
            0.007732951547950506,
            0.008048106916248798,
            -0.00016120293003041297,
            0.005217122845351696,
            -0.015849953517317772,
            -0.00947971548885107,
            0.050237711519002914,
            0.012539532035589218,
            -0.03890688344836235,
            0.014042941853404045,
            0.015688244253396988,
            -0.030443113297224045,
            0.004935558885335922,
            -0.045713141560554504,
            0.011739951558411121,
            -0.008657490834593773,
            -0.006071907002478838,
            0.014689003117382526,
            0.010767031461000443,
            -0.04104868695139885,
            0.013190063647925854,
            -0.01805659383535385,
            -0.02957230620086193,
            0.04184601083397865,
            -0.016021251678466797,
            0.026062171906232834,
            0.006606895010918379,
            0.02058621495962143,
            0.0063476646319031715,
            0.0567060224711895,
            0.008989650756120682,
            0.024703459814190865,
            0.004803103860467672,
            -0.010936937294900417,
            -0.035512443631887436,
            -0.0036765120457857847,
            0.023555399850010872,
            0.009097651578485966,
            0.03367410972714424,
            -0.00572532694786787,
            -0.025143494829535484,
            0.029916873201727867,
            0.013883649371564388,
            -0.007093061693012714,
            -0.012016401626169682,
            0.0215824656188488,
            -0.006949839647859335,
            0.003210094990208745,
            0.030087465420365334,
            0.010441326536238194,
            -0.027444249019026756,
            0.002046676818281412,
            -0.024685697630047798,
            -0.024356428533792496,
            0.09134909510612488,
            0.05684751644730568,
            0.01601242832839489,
            0.017456520348787308,
            -0.024804135784506798,
            0.04946603998541832,
            0.014040879905223846,
            -0.0018838307587429881,
            -0.0034626007545739412,
            -0.002536368789151311,
            0.025521162897348404,
            -0.012783373706042767,
            0.03677166625857353,
            0.034170545637607574,
            -0.037684470415115356,
            -0.003710497636348009,
            -0.009805042296648026,
            0.02287222258746624,
            -0.024154681712388992,
            -0.019224941730499268,
            0.029868388548493385,
            -0.028863750398159027,
            0.01675054058432579,
            0.01595303975045681,
            0.013382420875132084,
            0.008129587396979332,
            0.03094986267387867,
            -0.03424142673611641,
            0.03456529602408409,
            0.0030865587759763002,
            0.010110787115991116,
            -0.02060641534626484,
            0.009578864090144634,
            -0.021285224705934525,
            0.019073868170380592,
            -0.011698654852807522,
            -0.02604173868894577,
            0.02397966757416725,
            -0.0770193561911583,
            -0.008216166868805885,
            -0.04675149917602539,
            0.020443236455321312,
            0.017851654440164566,
            -0.003253678558394313,
            0.016379738226532936,
            0.0062972442246973515,
            -0.00828137993812561,
            0.0026976964436471462,
            -0.027373718097805977,
            0.021372497081756592,
            0.005461570806801319,
            0.022690562531352043,
            0.033364828675985336,
            -0.024381693452596664,
            0.0032411166466772556,
            -0.060496389865875244,
            -0.005927056074142456,
            -0.04664590582251549,
            -0.0020250005181878805,
            0.04224680736660957,
            0.0024143641348928213,
            -0.017638079822063446,
            -0.010056596249341965,
            -0.0006114100106060505,
            -0.004064933396875858,
            -0.007574582472443581,
            0.020209550857543945,
            0.012372567318379879,
            0.053733646869659424,
            0.01776449754834175,
            -0.015143654309213161,
            -0.016237379983067513,
            -0.0290058683604002,
            -0.0205159243196249,
            0.00581200560554862,
            0.03201166167855263,
            -0.0005937678506597877,
            0.07395156472921371,
            -0.015231534838676453,
            0.009737877175211906,
            0.011807112023234367,
            -0.026380492374300957,
            -0.013813813216984272,
            0.03744712844491005,
            0.01869991235435009,
            -0.014017057605087757,
            0.014331482350826263,
            -0.030511310324072838,
            0.023124780505895615,
            0.013320445083081722,
            -0.01572277396917343,
            0.018067842349410057,
            0.029773395508527756,
            -0.0002955309464596212,
            0.022285351529717445,
            -0.04009067639708519,
            -0.02218862809240818,
            0.018830247223377228,
            0.019399285316467285,
            0.031061038374900818,
            0.007872888818383217,
            -0.019328799098730087,
            0.024916818365454674,
            0.029711676761507988,
            -0.004423805046826601,
            -0.014933714643120766,
            0.016322368755936623,
            0.009732196107506752,
            -0.006492787506431341,
            -0.032510049641132355,
            -0.0019928438123315573,
            0.0013924726517871022,
            0.03193245083093643,
            0.046548958867788315,
            -0.0331096313893795,
            -0.032534923404455185,
            -0.02692091464996338,
            0.03743649646639824,
            0.005962516646832228,
            0.004356443416327238,
            0.024465195834636688,
            0.02653508447110653,
            0.03023477829992771,
            -0.014949031174182892,
            -0.00032576199737377465,
            -0.014267059043049812,
            -0.001103007234632969,
            -0.01663077063858509,
            -0.026900701224803925,
            0.028273040428757668,
            0.0161166712641716,
            -0.07540667802095413,
            0.0419146902859211,
            0.012721793726086617,
            0.012433931231498718,
            -0.017388001084327698,
            0.022021189332008362,
            0.02495294250547886,
            -0.0005518198013305664,
            0.027528652921319008,
            0.0029540853574872017,
            0.04753803834319115,
            -0.007543598767369986,
            -0.011411778628826141,
            -0.006235863547772169,
            -0.00349618261680007,
            -0.01206905860453844,
            0.025134321302175522,
            -0.021937496960163116,
            0.02189219370484352,
            -0.01963157206773758,
            0.029868951067328453,
            0.028361165896058083,
            0.016992641612887383,
            0.05578882619738579,
            0.047283172607421875,
            -0.02667897753417492,
            -0.0071078198961913586,
            0.011941933073103428,
            0.019949454814195633,
            0.0024868117179721594,
            0.027065742760896683,
            0.02155543863773346,
            0.013112514279782772,
            -0.002417734358459711,
            0.023215722292661667,
            0.023610439151525497,
            -0.016521770507097244,
            0.04050853103399277,
            0.0013995843473821878,
            0.019718702882528305,
            -0.028301460668444633,
            0.051678404211997986,
            0.019881730899214745,
            0.0008541166316717863,
            -0.015710655599832535,
            0.022230835631489754,
            -0.01605921983718872,
            -0.011882723309099674,
            0.014064536429941654,
            0.02398977242410183,
            -0.009275068528950214,
            0.029170816764235497,
            0.026635965332388878,
            0.0019505543168634176,
            -0.017690809443593025,
            -0.02604670263826847,
            0.012854096479713917,
            0.007727930322289467,
            -0.02060302160680294,
            0.00999116525053978,
            -0.026570312678813934,
            -0.08470471203327179,
            0.0005425345152616501,
            0.03286522254347801,
            0.004295082297176123,
            0.00882703997194767,
            -0.021436553448438644,
            0.04092840105295181,
            -0.049205232411623,
            -0.006479681935161352,
            0.03036849945783615,
            -0.05187995359301567,
            0.015942523255944252,
            -0.02043290063738823,
            -0.014732264913618565,
            0.027321437373757362,
            -0.006286781746894121,
            -0.012500452809035778,
            0.015770364552736282,
            -0.007402495015412569,
            0.0011082245036959648,
            -0.008053915575146675,
            0.0172758549451828,
            -0.016066212207078934,
            0.012658586725592613,
            -0.010472090914845467,
            0.04574230685830116,
            -0.01800002157688141,
            0.013719798997044563,
            0.005664248485118151,
            0.029176287353038788,
            -0.00806229654699564,
            -0.04272545501589775,
            -0.024458885192871094,
            -0.016510112211108208,
            -0.03206447511911392,
            -0.027207868173718452,
            0.049678266048431396,
            0.007985186763107777,
            0.03236381709575653,
            -0.0001338487199973315,
            0.03344837948679924,
            -0.009567123837769032,
            -0.018044685944914818,
            -0.047954827547073364,
            0.0051500871777534485,
            0.021937226876616478,
            -0.018285395577549934,
            0.031239142641425133,
            0.015622472390532494,
            -0.005248274188488722,
            0.024743402376770973,
            -0.0038637605030089617,
            -0.020813247188925743,
            -0.005228675436228514,
            -0.01238646637648344,
            -0.005559470504522324,
            -0.0037188304122537374,
            -0.02377389371395111,
            0.03541135415434837,
            0.0036737346090376377,
            -0.013387313112616539,
            0.026335377246141434,
            0.00015011233335826546,
            0.015237766318023205,
            0.00730419484898448,
            0.01195414550602436,
            -0.04315473884344101,
            0.006579182576388121,
            0.04256116598844528,
            -0.0006833907682448626,
            -0.005496427416801453,
            -0.0031858740840107203,
            3.8325895729940385e-05,
            0.014598133973777294,
            0.0010328305652365088,
            -0.009273332543671131,
            0.02517937868833542,
            -0.038149263709783554,
            0.014147019013762474,
            0.008954651653766632,
            -0.0007001254125498235,
            0.021467970684170723,
            0.0391763411462307,
            0.03419327735900879,
            0.041725050657987595,
            -0.033069826662540436,
            -0.009459993802011013,
            0.036762211471796036,
            0.012340085580945015,
            -0.0070082820020616055,
            -0.01846095360815525,
            -0.03449903801083565,
            -0.05699421465396881,
            -0.06740058213472366,
            0.022596418857574463,
            0.013994796201586723,
            0.00545460544526577,
            -0.010845163837075233,
            -0.0015548237133771181,
            -0.03660698980093002,
            -0.00040972576243802905,
            1.2316160791669972e-05,
            0.019144564867019653,
            -0.003143194830045104,
            0.009914202615618706,
            0.002758180955424905,
            0.01749250292778015,
            0.03377210348844528,
            -0.011122871190309525,
            -0.06415826082229614,
            0.0033992682583630085,
            -0.00920912530273199,
            -0.0415167473256588,
            -0.00046300850226543844,
            -0.03167074918746948,
            0.008837687782943249,
            0.01738288253545761,
            0.04977402463555336,
            -0.002390918554738164,
            0.037333860993385315,
            -0.010617915540933609,
            0.004607281647622585,
            0.05854612588882446,
            -0.012014344334602356,
            -0.03713686391711235,
            0.014695796184241772,
            -0.014657733961939812,
            0.026482924818992615,
            0.008795673958957195,
            -0.007941912859678268,
            -0.06563328951597214,
            -0.014355307444930077,
            -0.004674823489040136,
            0.017403261736035347,
            -0.03897595405578613,
            0.016933826729655266,
            -0.01302847359329462,
            0.026252202689647675,
            0.007325627375394106,
            0.010684916749596596,
            -0.013687479309737682,
            0.003948112018406391,
            0.01997445710003376,
            -0.014944353140890598,
            0.04089691862463951,
            0.002806036965921521,
            -0.0199590977281332,
            0.030307436361908913,
            0.01599610410630703,
            -0.030992433428764343,
            0.019077245146036148,
            0.011456457898020744,
            -0.043156128376722336,
            -0.00010165816638618708,
            -0.012192502617835999,
            -0.027475230395793915,
            0.0016032832209020853,
            0.01216401718556881,
            -0.02203046903014183,
            -0.0017623071325942874,
            -0.02221541851758957,
            0.02864331565797329,
            -0.03365430608391762,
            -0.03077409788966179,
            0.004797716159373522,
            0.03456021845340729,
            -0.021910855546593666,
            0.01930832304060459,
            0.002805315889418125,
            0.019635597243905067,
            -0.03432532027363777,
            0.03366885334253311,
            -0.0023094413336366415,
            -0.022548772394657135,
            0.017187977209687233,
            -0.037684421986341476,
            0.012472023256123066,
            -0.03729834780097008,
            -0.008559294044971466,
            -0.013670887798070908,
            0.016220271587371826,
            0.015402786433696747,
            0.026837065815925598,
            -0.02417011931538582,
            -0.038310520350933075,
            -0.001835037488490343,
            -0.03243749588727951,
            0.0015922576421871781,
            -0.021778684109449387,
            -0.03317762911319733,
            -0.008340047672390938,
            0.00921559240669012,
            -0.03774832561612129,
            -0.002464377786964178,
            0.038990020751953125,
            -0.006793429143726826,
            -0.021278055384755135,
            0.09692299365997314,
            -0.024725358933210373,
            0.02245727926492691,
            0.003095170482993126,
            -0.023992082104086876,
            0.015794042497873306,
            -0.00405430793762207,
            0.02652607671916485,
            0.027671577408909798,
            -0.015906795859336853,
            -0.04174244403839111,
            0.02394769713282585,
            -0.014275295659899712,
            -0.010137607343494892,
            0.02355053462088108,
            0.016609981656074524,
            0.0437835156917572,
            -0.02698625437915325,
            -0.07166827470064163,
            -0.042894892394542694,
            -0.024204786866903305,
            -0.027487963438034058,
            0.028047140687704086,
            0.02540571242570877,
            -0.0051531121134757996,
            -0.010305415838956833,
            0.006653222255408764,
            0.031116748228669167,
            -0.04158046841621399,
            -0.019356409087777138,
            -0.019356198608875275,
            -0.04001892730593681,
            -0.04028523713350296,
            -0.014616813510656357,
            -0.004968327470123768,
            -0.00457259826362133,
            -0.05431637167930603,
            0.06874800473451614,
            -0.010401536710560322,
            -0.05377126857638359,
            -0.026175005361437798,
            -0.006698166485875845,
            0.015477225184440613,
            -0.04060426354408264,
            0.004361913539469242,
            0.006840933579951525,
            -0.01925627514719963,
            -0.05214435234665871,
            -0.0028737112879753113,
            0.001434330944903195,
            -0.01954355090856552,
            -0.03351132571697235,
            0.007241344545036554,
            0.001094061299227178,
            -0.0032983061391860247,
            0.013321666046977043,
            0.05275924876332283,
            0.027484528720378876,
            0.01617436669766903,
            0.020114321261644363,
            0.017431145533919334,
            0.005915568210184574,
            0.014530275948345661,
            0.008126169443130493,
            -0.022876068949699402,
            -0.001390017569065094,
            0.015161045826971531,
            0.040455762296915054,
            0.00712357135489583,
            0.03371644392609596,
            0.012878825888037682,
            -1.3842794032825623e-05,
            0.02621828392148018,
            -0.060339175164699554,
            -0.0021741038653999567,
            0.011845420114696026,
            0.010905430652201176,
            -0.0016884655924513936,
            0.020350243896245956,
            0.010454065166413784,
            -0.005124418996274471,
            -0.017223797738552094,
            0.04820478335022926,
            0.01268688589334488,
            -0.0151451351121068,
            0.0371156670153141,
            -0.021557427942752838,
            -0.002741931239143014,
            0.019374040886759758,
            0.029632456600666046,
            -0.00881247129291296,
            0.007821000181138515,
            0.01494924072176218,
            0.021791541948914528,
            -0.015206103213131428,
            -0.006045366637408733,
            0.0053170532919466496,
            -0.025804366916418076,
            0.028765138238668442,
            -0.01721395179629326,
            0.020810266956686974,
            -0.022412654012441635,
            0.020062416791915894,
            -0.039905183017253876,
            -0.03963790461421013,
            0.016616126522421837,
            0.01686130464076996,
            -0.03530298173427582,
            -0.004742726683616638,
            0.027692003175616264,
            -0.0004846635856665671,
            0.01802702061831951,
            0.014439363963901997,
            0.025525974109768867,
            -0.023509157821536064,
            -0.002193273976445198,
            -0.025773553177714348,
            -0.015137269161641598,
            -0.0005322707002051175,
            -0.07184454798698425,
            -0.0056383670307695866,
            0.025840092450380325,
            0.03321603313088417,
            -0.024916691705584526,
            -0.0003352074418216944,
            -0.011157916858792305,
            0.00066206487827003,
            -0.042709242552518845,
            -0.012323671020567417,
            -0.022281574085354805,
            0.0016789197688922286,
            -0.02742711640894413,
            -0.03277357667684555,
            -0.02234361134469509,
            0.010132214985787868,
            -0.045518748462200165,
            0.013823146931827068,
            0.021277479827404022,
            -0.03919830173254013,
            0.010708877816796303,
            -0.016850478947162628,
            -0.022092953324317932,
            0.0016661484260112047,
            0.019037384539842606,
            -0.007436871528625488,
            -0.01725408062338829,
            -0.006808531004935503
        ],
        [
            0.02217179909348488,
            -0.038683801889419556,
            0.03159372881054878,
            -0.009777284227311611,
            -0.00429563969373703,
            -0.02284558117389679,
            -0.018324458971619606,
            -0.03158119320869446,
            0.025059018284082413,
            0.02463632822036743,
            -0.04274056851863861,
            0.0070801530964672565,
            0.04308005049824715,
            0.017425537109375,
            -0.001195628778077662,
            -0.08228687942028046,
            -0.006037825718522072,
            0.012842383235692978,
            -0.0029868711717426777,
            -0.016589561477303505,
            0.005994854029268026,
            -0.023285862058401108,
            0.06168293580412865,
            -0.01289342064410448,
            0.030343396589159966,
            0.031901437789201736,
            0.03384380415081978,
            -0.01606937311589718,
            -0.016433868557214737,
            0.010135217569768429,
            0.0032357878517359495,
            -0.029613446444272995,
            -0.014310912229120731,
            0.018486306071281433,
            0.009427760727703571,
            0.007427195552736521,
            0.01952330209314823,
            -0.02820531092584133,
            0.009402433410286903,
            0.024146605283021927,
            -0.04221373051404953,
            0.021786224097013474,
            -0.026124270632863045,
            -0.01711270399391651,
            -0.002693036338314414,
            -0.003591358894482255,
            0.006720940116792917,
            0.05322824791073799,
            0.0070960624143481255,
            -0.0014059952227398753,
            -0.004746376536786556,
            0.02247966080904007,
            -0.010062525980174541,
            -0.045485056936740875,
            0.03696403652429581,
            0.004117380362004042,
            0.013701493851840496,
            0.031537976115942,
            -0.019406849518418312,
            0.026106910780072212,
            -0.015564382076263428,
            -0.0016433451091870666,
            -0.03234109282493591,
            -0.01977609470486641,
            0.007448118180036545,
            0.014205865561962128,
            -0.052678775042295456,
            0.004887990187853575,
            -0.02034733071923256,
            0.015976130962371826,
            0.002246213611215353,
            0.026586053892970085,
            0.01668580248951912,
            0.004689096938818693,
            -0.009121420793235302,
            0.02800850011408329,
            0.032781463116407394,
            0.014821449294686317,
            0.01350831612944603,
            0.0061447578482329845,
            0.01318714115768671,
            -0.00640762597322464,
            -4.434564834809862e-05,
            0.02612762153148651,
            0.002499581780284643,
            -0.027481697499752045,
            0.017108982428908348,
            0.03372897207736969,
            0.005424097180366516,
            0.015657486394047737,
            0.023676574230194092,
            -0.021024338901042938,
            -0.013451103121042252,
            0.016994493082165718,
            0.04358017072081566,
            0.0018565964419394732,
            0.02041851542890072,
            0.018289098516106606,
            0.05668865516781807,
            0.00704619288444519,
            0.05560172349214554,
            0.017866389825940132,
            0.013355575501918793,
            -0.007092953659594059,
            0.0021962900646030903,
            0.0167566929012537,
            0.01823521964251995,
            0.036263883113861084,
            -0.04794696345925331,
            -0.0013616604264825583,
            -0.035213615745306015,
            -0.04750707000494003,
            0.018855178728699684,
            -0.004996121395379305,
            -0.0072552720084786415,
            -0.030876781791448593,
            -0.0008185884216800332,
            -0.026070499792695045,
            -0.003258846001699567,
            0.010743572376668453,
            0.0065786587074398994,
            -0.009387461468577385,
            -0.0015062240418046713,
            -0.007104854565113783,
            -0.01918572559952736,
            0.035390373319387436,
            -0.011716200970113277,
            0.035006824880838394,
            -0.004538021516054869,
            0.017817722633481026,
            0.006062510423362255,
            -0.03297967091202736,
            0.06348865479230881,
            -0.032845210283994675,
            -0.005132944788783789,
            0.020088259130716324,
            0.020452609285712242,
            -0.008281427435576916,
            -0.05811263620853424,
            -0.011010107584297657,
            -0.020231058821082115,
            0.007106222212314606,
            -0.012255780398845673,
            -0.007322124671190977,
            -0.009278287179768085,
            0.02855767495930195,
            0.006714149843901396,
            0.02031238190829754,
            -0.009123500436544418,
            0.021290626376867294,
            0.018804490566253662,
            0.050033088773489,
            -0.0054527753964066505,
            0.058251865208148956,
            0.039735693484544754,
            0.005498349200934172,
            0.005246095359325409,
            0.023846985772252083,
            0.030887434259057045,
            -0.04561440274119377,
            0.01985860802233219,
            -0.005770422518253326,
            -0.05927208065986633,
            0.012645166367292404,
            -0.006442413665354252,
            0.024769891053438187,
            0.006129776127636433,
            0.03551776334643364,
            0.032200682908296585,
            -0.01592777669429779,
            0.024141114205121994,
            0.002128895837813616,
            0.05338072404265404,
            0.037647977471351624,
            0.010613081976771355,
            -0.0033367746509611607,
            0.006949251517653465,
            0.037681594491004944,
            0.038614220917224884,
            0.03321775794029236,
            0.013594970107078552,
            -0.014641565270721912,
            -0.030081214383244514,
            0.037571050226688385,
            -0.02412373758852482,
            0.023091979324817657,
            -0.03816522657871246,
            -0.0146996034309268,
            0.033119864761829376,
            -0.020407285541296005,
            0.042539261281490326,
            -0.019728614017367363,
            0.04311861842870712,
            0.026212524622678757,
            0.0010843537747859955,
            0.00017444996046833694,
            0.035056374967098236,
            -0.02415097877383232,
            0.041222307831048965,
            0.04258570447564125,
            0.014571246691048145,
            -0.02747703343629837,
            -0.0067426483146846294,
            -0.017761200666427612,
            0.06475307047367096,
            0.009400137700140476,
            0.01782326027750969,
            -0.012908419594168663,
            0.025420788675546646,
            -0.020953787490725517,
            -0.006238029338419437,
            0.011643764562904835,
            -0.04601442441344261,
            -0.03421885147690773,
            0.020414292812347412,
            -0.03232270106673241,
            0.010187319479882717,
            -0.001378320623189211,
            -0.03211453929543495,
            0.029591409489512444,
            0.029512254521250725,
            -0.00016390621021855623,
            0.022390354424715042,
            0.03602904826402664,
            0.014393887482583523,
            0.0308087021112442,
            0.05914132669568062,
            0.008742625825107098,
            0.019717881456017494,
            -0.02858622372150421,
            0.031379129737615585,
            -0.018191639333963394,
            -0.007642573211342096,
            -0.03006744757294655,
            0.003854408161714673,
            -0.010025383904576302,
            -0.02194291539490223,
            0.0050956131890416145,
            -0.022551031783223152,
            0.020135872066020966,
            -0.03444604203104973,
            0.002576959552243352,
            -0.03177485242486,
            0.010774653404951096,
            0.0001355772401439026,
            -0.04113536328077316,
            0.049025796353816986,
            -0.02405228652060032,
            -0.01355008129030466,
            0.03507615253329277,
            0.010397353209555149,
            -0.004424720071256161,
            0.04563026502728462,
            0.023419208824634552,
            0.024831019341945648,
            0.006017034407705069,
            -0.013627766631543636,
            -0.020513618364930153,
            0.004759510047733784,
            -0.01414108369499445,
            0.02160247415304184,
            0.034186795353889465,
            -0.024377405643463135,
            -0.021095819771289825,
            -0.02128537930548191,
            0.02739357203245163,
            -0.02378169074654579,
            0.03150468319654465,
            -0.004949032794684172,
            0.04443085566163063,
            -0.011669627390801907,
            0.008623450994491577,
            -0.03345738723874092,
            0.0018603806383907795,
            0.008507100865244865,
            -0.026327358558773994,
            0.026364510878920555,
            -0.02120502106845379,
            0.03379827365279198,
            0.010117918252944946,
            -0.01891416683793068,
            -0.00023168962798081338,
            -0.0037445342168211937,
            -0.02410058118402958,
            0.008449919521808624,
            -0.019031308591365814,
            0.036499153822660446,
            -0.041959427297115326,
            -0.003595088142901659,
            0.017900433391332626,
            0.026923468336462975,
            0.0033943357411772013,
            -0.024205882102251053,
            0.03261109068989754,
            -0.013012150302529335,
            0.02258802019059658,
            0.0368831604719162,
            0.013846440240740776,
            -0.011376971378922462,
            -0.010761436074972153,
            0.008319065906107426,
            -0.008935649879276752,
            -0.02115592733025551,
            0.015387548133730888,
            -0.008387730456888676,
            0.03517386317253113,
            0.028970839455723763,
            -0.021361077204346657,
            -0.010541418567299843,
            -0.013030876405537128,
            0.02746620960533619,
            -0.0314687080681324,
            0.016809314489364624,
            0.013546527363359928,
            0.013422951102256775,
            0.01130523532629013,
            0.0015791902551427484,
            -0.0019609243609011173,
            -0.009502114728093147,
            0.005315455608069897,
            0.016319969668984413,
            0.028713753446936607,
            -0.018513716757297516,
            -0.03807990252971649,
            -0.020782895386219025,
            -0.004286990966647863,
            -0.0073359329253435135,
            -0.00508976960554719,
            0.0033500713761895895,
            -0.013726248405873775,
            0.007775814738124609,
            0.013593179173767567,
            0.0226524006575346,
            0.023862041532993317,
            -0.0028525751549750566,
            0.03229822963476181,
            0.018550843000411987,
            0.004031531047075987,
            -0.00895707681775093,
            -0.029240040108561516,
            0.03220826014876366,
            -0.052950624376535416,
            -0.035462282598018646,
            -0.028069309890270233,
            -0.0045556314289569855,
            0.01673445664346218,
            -0.01147136464715004,
            -0.011539075523614883,
            -0.01278053130954504,
            0.022054454311728477,
            0.013546689413487911,
            -0.025790007784962654,
            -0.013908416032791138,
            0.015569103881716728,
            -0.018430892378091812,
            -0.004354007076472044,
            0.00017747118545230478,
            0.011454204097390175,
            0.021334728226065636,
            -0.03383265808224678,
            0.0044898297637701035,
            -0.028184378519654274,
            0.023325981572270393,
            0.007277338765561581,
            -0.014942537061870098,
            0.016402335837483406,
            -0.018039830029010773,
            0.02710120379924774,
            -0.013378365896642208,
            -0.020908406004309654,
            -0.025387411937117577,
            0.0048105306923389435,
            0.011015376076102257,
            0.04609517380595207,
            -0.035873860120773315,
            -0.03088391199707985,
            0.012214510701596737,
            0.025886710733175278,
            -0.012591595761477947,
            0.010228103958070278,
            -0.01221451349556446,
            -0.011726844124495983,
            0.026933860033750534,
            0.00895626936107874,
            0.048113465309143066,
            0.016540810465812683,
            0.009559866040945053,
            -0.021516451612114906,
            0.005478041712194681,
            -0.01396553497761488,
            0.019166667014360428,
            0.016112321987748146,
            0.010563021525740623,
            0.0021342600230127573,
            0.014967473223805428,
            -0.008333000354468822,
            0.020403726026415825,
            -0.021095845848321915,
            -0.011937800794839859,
            -0.029060952365398407,
            -0.022153805941343307,
            -0.01868257112801075,
            -0.006737407762557268,
            0.006853531114757061,
            0.028047800064086914,
            -0.025825127959251404,
            0.006055537611246109,
            0.056412965059280396,
            0.03209863230586052,
            0.00479827681556344,
            -0.009396474808454514,
            0.008410063572227955,
            0.044657208025455475,
            -0.003221980994567275,
            -0.012839357368648052,
            -0.007365338038653135,
            -0.004735813941806555,
            0.025305472314357758,
            0.045730289071798325,
            -0.0029519686941057444,
            0.033866994082927704,
            0.011193275451660156,
            0.026930129155516624,
            -0.02758295275270939,
            -0.01099772471934557,
            0.01724042370915413,
            0.008415215648710728,
            -0.021768633276224136,
            -0.0017063443083316088,
            0.04003887251019478,
            0.0031516910530626774,
            -0.035185806453228,
            0.007033810019493103,
            0.013293072581291199,
            -0.010352885350584984,
            -0.005204407963901758,
            0.009520281106233597,
            0.020599128678441048,
            0.018177209421992302,
            -0.03153139725327492,
            -0.026275426149368286,
            0.03127992898225784,
            0.02473718486726284,
            -0.0018252736190333962,
            -0.01178006175905466,
            0.006793101318180561,
            0.023077767342329025,
            0.03902450576424599,
            -0.034438151866197586,
            0.006402789149433374,
            -0.0018023528391495347,
            -0.03687167912721634,
            -0.05344024673104286,
            -0.018731553107500076,
            0.010087508708238602,
            -0.027275534346699715,
            0.023841163143515587,
            -0.03971782326698303,
            0.026396578177809715,
            0.011504892259836197,
            -0.03574467450380325,
            -0.016378626227378845,
            0.005146194249391556,
            0.032247625291347504,
            -0.0037476690486073494,
            -0.01618020236492157,
            0.023833997547626495,
            0.02005722001194954,
            -0.007910247892141342,
            0.02675308845937252,
            0.02376173809170723,
            0.034385498613119125,
            -0.019429253414273262,
            0.026824207976460457,
            -0.012645033188164234,
            0.03726492449641228,
            0.005475473124533892,
            -0.00793022196739912,
            -0.0015489921206608415,
            0.028396794572472572,
            0.048282183706760406,
            0.04612402245402336,
            0.01234477385878563,
            -0.018268195912241936,
            -0.0005728114629164338,
            -0.06929540634155273,
            0.004615464713424444,
            -0.04584537073969841,
            0.07862833887338638,
            0.026013672351837158,
            0.003984989132732153,
            0.015114163979887962,
            0.017817411571741104,
            -0.01714523322880268,
            -0.06421278417110443,
            0.04503904655575752,
            -0.017117515206336975,
            0.021350692957639694,
            -0.00521725881844759,
            0.02917720563709736,
            -0.02726178616285324,
            0.044138263911008835,
            -0.016952672973275185,
            -0.03347861394286156,
            0.016054298728704453,
            0.012417900376021862,
            0.027297133579850197,
            0.020926926285028458,
            0.002419811673462391,
            -0.010534089989960194,
            -0.008538984693586826,
            0.03590534254908562,
            -0.013735318556427956,
            0.021176833659410477,
            -0.037852052599191666,
            -0.03082404099404812,
            0.016152622178196907,
            -0.015005495399236679,
            0.028551580384373665,
            -0.023363765329122543,
            0.0026208257768303156,
            0.018006229773163795,
            -0.02721882238984108,
            -0.009947177954018116,
            0.009214524179697037,
            0.05026582255959511,
            0.028866009786725044,
            -0.04030069336295128,
            0.00800101738423109,
            0.017829138785600662,
            -0.024779321625828743,
            -0.017097441479563713,
            0.008647487498819828,
            -0.023656677454710007,
            0.015629682689905167,
            0.005484405905008316,
            -0.025624988600611687,
            -0.04910549893975258,
            0.007976667024195194,
            -0.029036154970526695,
            0.0051291147246956825,
            0.023209603503346443,
            -0.013069866225123405,
            0.053669776767492294,
            0.025722671300172806,
            -0.061050839722156525,
            0.003010094165802002,
            -0.0005709833931177855,
            0.030220570042729378,
            -0.07227031886577606,
            0.025194905698299408,
            0.004290298093110323,
            0.015408175066113472,
            -0.005129728466272354,
            -0.01635109633207321,
            0.007730959914624691,
            -0.007456562016159296,
            0.023457983508706093,
            -0.025179006159305573,
            0.013193031772971153,
            0.004442064557224512,
            -0.0016816259594634175,
            -0.02252238430082798,
            -0.024231793358922005,
            0.01078744512051344,
            0.01946130581200123,
            0.02099449560046196,
            -0.010763298720121384,
            0.042655572295188904,
            0.010697492398321629,
            0.014955607242882252,
            0.03630087897181511,
            0.02945200726389885,
            0.03320523723959923,
            0.027183419093489647,
            -0.0010457418393343687,
            0.00983031839132309,
            -0.006121535319834948,
            0.016879767179489136,
            -0.03319893404841423,
            0.017824459820985794,
            0.010437916032969952,
            -0.03074192814528942,
            -0.03099127858877182,
            0.009801485575735569,
            0.02829771861433983,
            0.0010951330186799169,
            -0.004756676498800516,
            0.01713971421122551,
            0.002386696171015501,
            0.01702645979821682,
            -0.007405291311442852,
            0.019783830270171165,
            0.01100012194365263,
            0.010483494028449059,
            -0.02333047054708004,
            0.02667023427784443,
            -0.014324911870062351,
            -0.026291096583008766,
            -0.033049046993255615,
            -0.006268921308219433,
            -0.01216081716120243,
            -0.000309852825012058,
            -0.014705456793308258,
            -0.023384690284729004,
            0.020739158615469933,
            0.03186313435435295,
            -0.013807146809995174,
            0.024882273748517036,
            -0.03542593866586685,
            0.028611186891794205,
            0.013475810177624226,
            0.028497807681560516,
            0.018627827987074852,
            0.03385394066572189,
            0.05527960881590843,
            -0.022844815626740456,
            -0.02307988330721855,
            -0.03586228936910629,
            0.039687834680080414,
            -0.002008289797231555,
            -0.01177210547029972,
            0.017461903393268585,
            -0.01595236174762249,
            -0.03217155486345291,
            -0.0251153577119112,
            0.03876405954360962,
            0.036895379424095154,
            0.02575850859284401,
            0.014818724244832993,
            -0.0025626232381910086,
            0.03618496283888817,
            0.015546636655926704,
            0.00835898332297802,
            0.03532009944319725,
            0.013940413482487202,
            0.031690843403339386,
            0.017081206664443016,
            -0.03672931715846062,
            -0.00045790578587912023,
            0.012372768484055996,
            -0.03903340548276901,
            0.031204115599393845,
            -0.03111802227795124,
            0.002233194885775447,
            0.008087160997092724,
            0.005103320349007845,
            -0.02562747895717621,
            0.015457609668374062,
            -0.014998206868767738,
            -0.015256342478096485,
            0.01443009078502655,
            -0.0016229297034442425,
            0.025802571326494217,
            0.01138175930827856,
            0.027024326846003532,
            0.006673622410744429,
            0.029916424304246902,
            0.019533341750502586,
            -0.03207727149128914,
            -0.023184077814221382,
            0.0337301641702652,
            -0.023435307666659355,
            0.010454181581735611,
            -0.01222339179366827,
            0.0022167726419866085,
            0.016943298280239105,
            -0.011255299672484398,
            -0.017452171072363853,
            0.026180367916822433,
            0.06199352815747261,
            -0.0026854153256863356,
            -0.025651851668953896,
            -0.01327828224748373,
            -0.020857742056250572,
            -0.062177624553442,
            0.013401560485363007,
            0.028619395568966866,
            -0.021429337561130524,
            0.010459349490702152,
            0.0018269052961841226,
            -0.020132143050432205,
            0.02296537533402443,
            0.017895612865686417,
            -0.014742210507392883,
            0.005243770778179169,
            0.034040533006191254,
            0.021701756864786148,
            -0.005724180024117231,
            -0.004286720883101225,
            0.05634266138076782,
            -0.002717833034694195,
            0.03641743212938309,
            0.028787024319171906,
            -0.004950203467160463,
            0.02089114487171173,
            0.028636539354920387,
            0.025712987408041954,
            0.0320066474378109,
            -0.006121863145381212,
            -0.0005292758578434587,
            0.019852282479405403,
            0.024772776290774345,
            -0.04924841970205307,
            -0.01868867501616478,
            -0.022413119673728943,
            -0.0368247888982296,
            -0.017071912065148354,
            -0.004039706662297249,
            -0.004924099426716566,
            -0.04142118990421295,
            0.007725199684500694,
            0.04751760885119438,
            0.0073845661245286465,
            0.005651898682117462,
            -0.027628954499959946,
            -0.011168519966304302,
            -0.003248413559049368,
            0.013510647229850292,
            -0.011758748441934586,
            -0.018090801313519478,
            0.0073553635738790035,
            -0.009501535445451736,
            0.003643473144620657,
            -0.018549708649516106,
            0.006957194302231073,
            -0.01986200548708439,
            -0.03797103837132454,
            0.0220378078520298,
            0.011257998645305634,
            -0.013061086647212505,
            -0.011116948910057545,
            -0.0018536547431722283,
            0.019127067178487778,
            0.010766234248876572,
            0.02089686691761017,
            -0.011537792161107063,
            -0.020871438086032867,
            0.028808116912841797,
            0.012043633498251438,
            0.00014925839786883444,
            -0.03171275556087494,
            -0.033209554851055145,
            0.02190808206796646,
            0.025889521464705467,
            -0.008466976694762707,
            0.010762270539999008,
            0.03967619687318802,
            -0.014883968979120255,
            0.030495788902044296,
            -0.022171014919877052,
            0.023571297526359558,
            0.014220879413187504,
            0.02154146134853363,
            0.0038780474569648504,
            0.019894184544682503,
            0.02636381797492504,
            0.0015019961865618825,
            0.003005064558237791,
            0.02728249318897724,
            0.0182847511023283,
            -0.041033510118722916,
            0.04756477102637291,
            -0.004574449732899666,
            -0.029258547350764275,
            -0.0003428023774176836,
            0.01337327528744936,
            0.0037603469099849463,
            -0.024840515106916428,
            0.023715581744909286,
            0.020217083394527435,
            0.008699535392224789,
            0.010020483285188675,
            0.006777856964617968,
            0.008441278710961342,
            0.012113725766539574,
            0.06563468277454376,
            0.05285274609923363,
            -0.025713231414556503,
            -0.013961199671030045,
            0.02952597104012966,
            -0.017301660031080246,
            -0.0018642944050952792,
            -0.03828110173344612,
            -0.027979478240013123,
            -0.0035683352034538984,
            -0.039244312793016434,
            -0.04015086218714714,
            0.034681692719459534,
            0.04685335233807564,
            -0.019817179068922997,
            -0.006204719189554453,
            0.035306353121995926,
            -0.021872064098715782,
            -0.004938281606882811,
            -0.013725997880101204,
            0.025924989953637123,
            -0.010043253190815449,
            0.002077947137877345,
            0.015833774581551552,
            0.022921379655599594,
            0.016036001965403557,
            -0.002040770137682557,
            -0.04307839274406433,
            0.01642882451415062,
            -0.0314612090587616,
            0.013670463114976883,
            -4.971950329490937e-05,
            0.014211714267730713,
            0.04038725048303604,
            -0.031308483332395554,
            -0.04556349664926529,
            0.025934210047125816,
            0.0321645624935627,
            0.033476635813713074,
            -0.023509621620178223,
            0.011066436767578125,
            -0.0119675537571311,
            0.02784554474055767,
            -0.0005213734111748636,
            0.023402182385325432,
            -0.0014698079321533442,
            0.0067281583324074745,
            0.002730361185967922,
            -0.015471119433641434,
            -0.018047688528895378,
            -0.003303896402940154,
            0.004171865526586771,
            -0.0026000177022069693,
            0.024301839992403984,
            0.047829534858465195,
            -0.017562871798872948,
            0.036436568945646286,
            0.02305610291659832,
            -0.012700759805738926,
            0.011532282456755638,
            0.0294540636241436,
            -0.004906031768769026,
            0.017011811956763268,
            0.04464082419872284,
            -0.028985213488340378,
            0.012422723695635796,
            -0.013861087150871754,
            0.006569381803274155,
            -0.037324268370866776,
            -0.024267997592687607,
            0.02555641531944275,
            -0.04085772484540939,
            0.03463844954967499,
            0.023643745109438896,
            -0.023683246225118637,
            -0.022392772138118744,
            0.029641790315508842,
            -0.03285777568817139,
            -0.0014511548215523362,
            0.03557201102375984,
            0.0006363417487591505,
            -0.012355156242847443,
            -0.02380869723856449,
            0.016709815710783005,
            -0.029116157442331314,
            -0.03624866530299187,
            0.0209267009049654,
            -0.028781335800886154,
            0.01352322194725275,
            0.009585094638168812,
            0.02112269401550293,
            0.0114704929292202,
            -0.007318008691072464,
            0.009610408917069435,
            0.0021912609227001667,
            0.023194486275315285,
            0.004619817715138197,
            -0.011868255212903023,
            -0.020943738520145416,
            0.015230796299874783,
            -0.00871685054153204,
            -0.05516698211431503,
            0.011145983822643757,
            0.003017410170286894,
            0.014972586184740067,
            0.00972042791545391,
            -0.014662710949778557,
            -0.02360910177230835,
            0.04295339807868004,
            -0.018703047186136246,
            0.01849226839840412,
            -0.03720724955201149,
            0.045592620968818665,
            -0.00990331918001175,
            -0.01523277536034584,
            0.02469221130013466,
            0.043464187532663345,
            -0.010652909986674786,
            -0.0062173535116016865,
            0.025024494156241417,
            -0.017796466127038002,
            0.005794980563223362,
            -0.026713119819760323,
            0.03514459729194641,
            0.032279592007398605,
            -0.03354412689805031,
            0.010940884239971638,
            -0.02773311547935009,
            0.04854438826441765,
            -0.0002624473418109119,
            0.023545725271105766,
            -0.01361805573105812,
            -0.003204084001481533,
            0.008246318437159061,
            0.03893638402223587,
            -0.03441748395562172,
            0.02734658494591713,
            0.043345872312784195,
            0.043941985815763474,
            0.007928160019218922,
            0.03583890199661255,
            0.01258869469165802,
            -0.028072066605091095,
            0.02101634256541729,
            -0.03803662210702896,
            0.03550205007195473,
            -0.015656085684895515,
            -0.01654905267059803,
            -0.05470975488424301,
            0.028873277828097343,
            -0.011843511834740639,
            -0.04790418595075607,
            0.0026887578424066305,
            0.0159610603004694,
            -0.01851888932287693,
            0.035318516194820404,
            0.01122751273214817,
            -0.029178034514188766,
            -0.021297818049788475,
            0.023631131276488304,
            -0.04329849034547806,
            -0.011638759635388851,
            -0.030369402840733528,
            -0.014936943538486958,
            0.025761937722563744,
            0.02071564272046089,
            -0.04109540209174156,
            0.027120308950543404,
            0.02084275893867016,
            -0.02401554025709629,
            0.009322631172835827,
            -0.012261413969099522,
            0.02907324582338333,
            -0.004816323518753052,
            -0.02796454355120659,
            -0.011260629631578922,
            -0.040268830955028534,
            -0.03705322742462158,
            0.048598501831293106,
            0.0035953300539404154,
            -0.006899739149957895,
            0.016017841175198555,
            0.028379131108522415,
            -0.0097569040954113,
            -0.0015409548068419099,
            0.060640014708042145,
            -0.043407924473285675,
            0.0015665242681279778,
            -0.0037083991337567568,
            -0.019347159191966057,
            0.032910045236349106,
            -0.006584314163774252,
            -0.01098197977989912,
            0.0242448840290308,
            0.004118576645851135,
            -0.02619444578886032,
            0.022407887503504753,
            -0.02282053604722023,
            -0.038119278848171234,
            -0.022973373532295227,
            0.00874672457575798,
            0.017663877457380295,
            0.02383510023355484,
            -0.02395477145910263,
            0.00047213095240294933,
            -0.015747135505080223,
            0.004198404960334301,
            -0.009321463294327259,
            -0.010497963055968285,
            0.010032609105110168,
            0.026979275047779083,
            -0.020090293139219284,
            -0.03724784776568413,
            -0.024265417829155922,
            -0.006639022380113602,
            0.025856459513306618,
            0.015966080129146576,
            -0.000464935670606792,
            -0.04311516508460045,
            -0.012004975229501724,
            0.07483106106519699,
            -0.0034739989787340164,
            -0.014354766346514225,
            -0.022877782583236694,
            -0.005622515454888344,
            -0.029802456498146057,
            -0.0024051766376942396,
            -0.02287076972424984,
            0.019201435148715973,
            0.009789393283426762,
            -0.027006031945347786,
            0.008439641445875168,
            0.03378516808152199,
            0.022671489045023918,
            0.04554498940706253,
            0.009651344269514084,
            -0.006645912770181894,
            -0.013056481257081032,
            -0.034119006246328354,
            -0.0008022477850317955,
            0.026329267770051956,
            -0.023323915898799896,
            -0.017853448167443275,
            0.0037551233544945717,
            0.030330384150147438,
            -0.005708197597414255,
            -0.033258404582738876,
            -0.016101764515042305
        ],
        [
            0.03489556163549423,
            -0.006355838850140572,
            -0.0012959616724401712,
            -0.014484011568129063,
            -0.010806150734424591,
            0.032583847641944885,
            -0.005602382123470306,
            0.06867718696594238,
            0.012485479936003685,
            -0.009922045283019543,
            0.022249620407819748,
            -0.010206712409853935,
            -0.005579773802310228,
            -0.026162799447774887,
            0.06728983670473099,
            0.037694644182920456,
            0.0008873697370290756,
            -0.04145167022943497,
            -0.008988404646515846,
            0.009315342642366886,
            -0.0253695547580719,
            0.0057499646209180355,
            0.009092370979487896,
            -0.008729896508157253,
            0.030750520527362823,
            -0.010144059546291828,
            0.037399034947156906,
            0.044818103313446045,
            -0.013341289944946766,
            0.029048917815089226,
            0.0342351533472538,
            0.008624809794127941,
            0.023437784984707832,
            0.01602170430123806,
            0.03671159967780113,
            0.03368626907467842,
            -0.002656153868883848,
            -0.03434419631958008,
            -0.037790365517139435,
            0.0018127474468201399,
            0.03946000710129738,
            0.008166403509676456,
            -0.00728435767814517,
            0.015800775960087776,
            0.03110349550843239,
            0.020247967913746834,
            0.014549260027706623,
            -0.021740682423114777,
            -0.022536855190992355,
            0.03557834401726723,
            -0.00825036596506834,
            0.011945652775466442,
            -0.03305736556649208,
            -0.037310220301151276,
            -0.011638530530035496,
            -0.02246876060962677,
            -0.009363217279314995,
            0.00391420628875494,
            0.01640765182673931,
            0.014580824412405491,
            -0.04449807107448578,
            0.018755469471216202,
            0.004186457954347134,
            0.014740447513759136,
            0.0027349188458174467,
            -0.0044954498298466206,
            0.060833778232336044,
            0.02368560992181301,
            0.01002469938248396,
            0.013269041664898396,
            -0.03824370726943016,
            -0.01577443815767765,
            -0.004445734899491072,
            -0.032923635095357895,
            -0.03251234441995621,
            -0.08867892622947693,
            -0.0032074267510324717,
            -0.021929506212472916,
            0.05419715866446495,
            0.03142498806118965,
            0.007661046460270882,
            0.012312408536672592,
            -0.044332049787044525,
            0.010779553093016148,
            -0.03802976757287979,
            -0.009603124111890793,
            0.005578918382525444,
            0.011407519690692425,
            -0.012649527750909328,
            0.014928356744349003,
            0.0008874642662703991,
            -0.027634723111987114,
            0.01957825943827629,
            -0.012996403500437737,
            0.02121494710445404,
            0.0010796244023367763,
            -0.03323658928275108,
            -0.015460417605936527,
            0.05008648708462715,
            0.018429631367325783,
            0.022773772478103638,
            0.013518931344151497,
            -0.02053753286600113,
            0.0005123076261952519,
            0.00036735201138071716,
            -0.02847236767411232,
            -0.012126263231039047,
            -0.007109186612069607,
            0.009434842504560947,
            -0.04633983597159386,
            0.0255717895925045,
            0.008414753712713718,
            0.015607614070177078,
            0.009793655015528202,
            -0.0341687835752964,
            -0.006012095604091883,
            0.02109195478260517,
            0.030556421726942062,
            0.03821083903312683,
            0.02661074325442314,
            0.004057917278259993,
            0.03954966738820076,
            0.03213164955377579,
            0.021458515897393227,
            -0.03424104303121567,
            -0.01872118003666401,
            0.009879951365292072,
            -0.03992138057947159,
            -0.013218528591096401,
            0.013349834829568863,
            -0.01093933917582035,
            0.04296790435910225,
            0.004503009840846062,
            -0.049950167536735535,
            -0.01455693133175373,
            -0.005958734080195427,
            0.0004534529580269009,
            -0.07903293520212173,
            0.0018698613857850432,
            0.020900677889585495,
            0.013561349362134933,
            -0.0033357732463628054,
            -0.004172237124294043,
            0.005742243025451899,
            0.0037316924426704645,
            -0.02979152835905552,
            -0.07382206618785858,
            -0.019923225045204163,
            0.0003596220340114087,
            0.010482560843229294,
            -0.045845791697502136,
            0.07550287991762161,
            -0.013248198665678501,
            -0.013753293082118034,
            0.01980067789554596,
            -0.022032808512449265,
            -0.06225881725549698,
            -0.03375454246997833,
            -0.010429819114506245,
            0.010067096911370754,
            0.006244221702218056,
            0.030763927847146988,
            0.00690281530842185,
            -0.03252226859331131,
            -0.014703398570418358,
            0.012706407345831394,
            -0.06357647478580475,
            -0.03729566931724548,
            0.003513239324092865,
            0.006582277826964855,
            -0.010077962651848793,
            -0.012740137055516243,
            0.0017634864198043942,
            -0.013200574554502964,
            0.0038206169847398996,
            0.015216565690934658,
            -0.007174738682806492,
            -0.007681745570152998,
            0.036835767328739166,
            -0.025352953001856804,
            -0.02660679817199707,
            -0.005205311346799135,
            0.023997340351343155,
            0.004665696062147617,
            -0.015442403964698315,
            -0.013868198730051517,
            0.011929834261536598,
            0.02714928239583969,
            -0.00439698901027441,
            -0.016383767127990723,
            0.01368706114590168,
            0.009189961478114128,
            -0.019683705642819405,
            0.0012834197841584682,
            -0.008598691783845425,
            0.020131481811404228,
            -0.01606680639088154,
            0.013117837719619274,
            0.018630439415574074,
            0.00898334477096796,
            0.005305937025696039,
            -0.00391728151589632,
            -0.014346346259117126,
            -0.0220916997641325,
            0.023483889177441597,
            -0.02457721158862114,
            -0.050064004957675934,
            -0.0150681771337986,
            -0.007323967758566141,
            -0.04306911677122116,
            0.027673564851284027,
            0.009160717949271202,
            -0.0007760166772641242,
            0.03408303111791611,
            0.027285274118185043,
            -0.011234298348426819,
            -0.001758880098350346,
            -0.05323968455195427,
            -0.022813202813267708,
            0.015767747536301613,
            -0.028069738298654556,
            -0.00936801452189684,
            -0.058582283556461334,
            0.030680939555168152,
            -0.03378225862979889,
            0.025970807299017906,
            -0.05840316414833069,
            0.00897802785038948,
            0.025960486382246017,
            -0.02355121821165085,
            -0.01567537523806095,
            0.04074402153491974,
            0.019181374460458755,
            -0.0062759206630289555,
            -0.037661392241716385,
            -0.023828648030757904,
            0.019068792462348938,
            0.02485591359436512,
            -0.022506805136799812,
            0.010923837311565876,
            -0.0010413752170279622,
            0.011771625839173794,
            -0.01169303897768259,
            -0.011601193808019161,
            -0.014458722434937954,
            -0.016634907573461533,
            0.02611166052520275,
            -0.0029791519045829773,
            0.002373624825850129,
            -0.012614298611879349,
            -0.02717350423336029,
            -0.010777221992611885,
            0.01066628098487854,
            -0.0007668553153052926,
            -0.03159576654434204,
            0.004923101980239153,
            0.005457194987684488,
            0.016341324895620346,
            -0.048864707350730896,
            -0.004488721024245024,
            -0.015055674128234386,
            0.038039252161979675,
            0.013806614093482494,
            0.03161739185452461,
            0.006501758471131325,
            -0.08393507450819016,
            0.029136713594198227,
            0.006755749229341745,
            -0.002230494050309062,
            -0.0013834107667207718,
            -0.017213867977261543,
            0.02401850000023842,
            0.0008514217333868146,
            0.018519392237067223,
            0.032442353665828705,
            -0.021644502878189087,
            -0.04324579983949661,
            -0.05292689427733421,
            0.008158448152244091,
            0.02124086581170559,
            -0.02453029528260231,
            0.009055844508111477,
            -0.014148954302072525,
            -0.0012385002337396145,
            0.027691658586263657,
            0.024214351549744606,
            -0.021101349964737892,
            -0.03061932697892189,
            0.007845181040465832,
            -0.009000367484986782,
            -0.028866134583950043,
            -0.007356760557740927,
            0.02732256054878235,
            -0.02342546544969082,
            0.003994069993495941,
            0.05125574767589569,
            -0.08038610219955444,
            -0.020144231617450714,
            0.0021924194879829884,
            0.012271776795387268,
            0.025636769831180573,
            0.026621835306286812,
            0.005227488931268454,
            0.012300970032811165,
            0.02706116996705532,
            0.02813846245408058,
            -0.001775744603946805,
            -0.004142523743212223,
            -0.005526837892830372,
            0.03739845007658005,
            -0.03331029415130615,
            0.0183232631534338,
            -0.0247073695063591,
            -0.03423089161515236,
            0.015526545234024525,
            -0.04327880218625069,
            -0.027761736884713173,
            0.013229683972895145,
            -0.03407523036003113,
            -0.017830772325396538,
            -0.03324160724878311,
            -0.0274551622569561,
            0.003462134161964059,
            0.0688692182302475,
            -0.03086356818675995,
            0.001300897216424346,
            0.04593741521239281,
            -0.051396362483501434,
            -0.014168673194944859,
            -0.033339302986860275,
            -0.025035535916686058,
            0.015931671485304832,
            0.02419900894165039,
            -0.027354031801223755,
            -0.004584698937833309,
            0.020088069140911102,
            -0.023839272558689117,
            2.328657683392521e-05,
            0.0162679310888052,
            -0.03347934037446976,
            0.0073777600191533566,
            0.07502885162830353,
            -0.021689506247639656,
            0.014476978220045567,
            -0.024038206785917282,
            0.044463180005550385,
            0.027274923399090767,
            -0.0056875781156122684,
            -0.0334831140935421,
            0.050430476665496826,
            0.04111367464065552,
            0.02047611214220524,
            0.017348457127809525,
            0.03287746384739876,
            -0.023821508511900902,
            -0.014877193607389927,
            0.03179048374295235,
            -0.026172040030360222,
            -0.004048395436257124,
            0.018613755702972412,
            -0.030250340700149536,
            -0.02410067245364189,
            -0.03324960544705391,
            0.024871202185750008,
            0.021405359730124474,
            0.037053126841783524,
            -0.013195596635341644,
            0.009626463986933231,
            0.01468450203537941,
            0.012081464752554893,
            -0.011503527872264385,
            -0.02298741042613983,
            0.05780200660228729,
            0.00681469077244401,
            -0.03533570468425751,
            0.03043023683130741,
            0.014630458317697048,
            -0.004921837709844112,
            -0.036217398941516876,
            0.017318105325102806,
            0.009895365685224533,
            -0.025481177493929863,
            0.0004524473915807903,
            -0.014316024258732796,
            -0.007605578750371933,
            0.04279536008834839,
            0.014627526514232159,
            0.028885293751955032,
            -0.008716581389307976,
            0.04334653168916702,
            0.0028422100003808737,
            0.016304994001984596,
            0.021283980458974838,
            0.04481356963515282,
            -0.020013993605971336,
            -0.04169738292694092,
            0.01068046037107706,
            0.05008812993764877,
            -0.013532127253711224,
            0.0312667153775692,
            -0.027366919443011284,
            0.001714792801067233,
            0.006944430526345968,
            -0.02297958917915821,
            -0.00813114084303379,
            0.0032488740980625153,
            -0.020801926031708717,
            0.012921420857310295,
            -0.013091559521853924,
            -0.001508536865003407,
            0.01287063304334879,
            -0.011051131412386894,
            0.02627672627568245,
            -0.010982019826769829,
            0.02742045931518078,
            -0.02161596156656742,
            -0.0023852731101214886,
            -0.06632556021213531,
            -0.0030071118380874395,
            -0.032506346702575684,
            0.020190244540572166,
            -0.0064732711762189865,
            0.0006012029480189085,
            0.027810415253043175,
            -0.005188309121876955,
            0.0046190740540623665,
            0.01445375382900238,
            0.06123466417193413,
            -0.015500146895647049,
            0.01937759481370449,
            -0.046563368290662766,
            0.02089742384850979,
            0.021364007145166397,
            -0.03822387009859085,
            0.00492429593577981,
            -0.06640475243330002,
            0.022648358717560768,
            -0.04998508468270302,
            0.015252213925123215,
            0.012987328693270683,
            0.014440511353313923,
            0.02331317588686943,
            0.04531843215227127,
            0.02243412286043167,
            -0.01165578793734312,
            0.02793138474225998,
            0.0017438997747376561,
            0.02152976579964161,
            0.022500792518258095,
            -0.013176977634429932,
            0.010434473864734173,
            0.00018610371625982225,
            0.022218259051442146,
            -0.03877286612987518,
            -0.007056557573378086,
            0.023992495611310005,
            0.041858285665512085,
            0.02646242082118988,
            -0.027502086013555527,
            0.0004128030559513718,
            0.019105399027466774,
            0.005271079950034618,
            0.0026333355344831944,
            0.03828759118914604,
            0.020540472120046616,
            -0.015379084274172783,
            0.03396744281053543,
            0.05162914842367172,
            -0.002210239414125681,
            0.019133873283863068,
            -0.010598529130220413,
            -0.01733609288930893,
            -0.011653320863842964,
            0.00993379857391119,
            -0.02186203934252262,
            -0.05753551796078682,
            -0.033051248639822006,
            -0.04199368506669998,
            -0.007481240201741457,
            0.005450014490634203,
            -0.0405375212430954,
            -0.014276664704084396,
            -0.036906883120536804,
            -0.04368949681520462,
            -0.013758423738181591,
            0.049014266580343246,
            0.02306656911969185,
            0.024387545883655548,
            -0.02575748600065708,
            0.0126882279291749,
            0.03616248443722725,
            0.0032387454994022846,
            0.018261734396219254,
            -0.05429232865571976,
            0.028849678114056587,
            -0.01985657401382923,
            -0.04263777285814285,
            -0.028305349871516228,
            -0.04820846766233444,
            -0.012934183701872826,
            0.021591125056147575,
            0.013591072522103786,
            -0.0248625036329031,
            0.06634500622749329,
            0.02880297601222992,
            -0.03575713932514191,
            -0.011241340078413486,
            -0.028485141694545746,
            -0.011025645770132542,
            -0.04595896601676941,
            -0.028319193050265312,
            -0.009612994268536568,
            0.026476256549358368,
            0.029440417885780334,
            0.06272869557142258,
            0.03325493633747101,
            -0.0037006253842264414,
            -0.030626537278294563,
            -0.05862803757190704,
            -0.034600768238306046,
            0.011646649800240993,
            -0.014278505928814411,
            0.023315923288464546,
            0.011939967051148415,
            0.012463226914405823,
            0.0034391176886856556,
            0.042731501162052155,
            0.02005522884428501,
            0.017761513590812683,
            -0.01246283296495676,
            0.015767650678753853,
            -0.052127037197351456,
            0.006451859604567289,
            -0.0012761215912178159,
            -0.0386023074388504,
            0.069171242415905,
            -0.014548731036484241,
            0.01622653193771839,
            0.0002365839754929766,
            0.038509149104356766,
            0.025333240628242493,
            0.015699060633778572,
            0.0481543242931366,
            0.0374697744846344,
            0.017102528363466263,
            0.024153687059879303,
            -0.019945818930864334,
            -0.0013489689445123076,
            -0.020701894536614418,
            -7.326368358917534e-05,
            -0.0008037227089516819,
            0.005710205528885126,
            0.011085116304457188,
            0.013295379467308521,
            0.0465671569108963,
            -0.0328034982085228,
            -0.00956504512578249,
            0.006596933118999004,
            0.009352810680866241,
            -0.008325688540935516,
            0.0032262676395475864,
            0.027196811512112617,
            -0.017042100429534912,
            -0.022054016590118408,
            0.008602509275078773,
            -0.028622422367334366,
            0.008768033236265182,
            0.02088177762925625,
            -0.03853757306933403,
            -0.016105052083730698,
            -0.011377239599823952,
            -0.026960454881191254,
            0.01830693893134594,
            0.01421335805207491,
            -0.015807028859853745,
            -0.002607643138617277,
            0.013372008688747883,
            0.07011979073286057,
            0.03860289603471756,
            0.012524452060461044,
            0.023672161623835564,
            0.0246319230645895,
            0.0509781688451767,
            -0.010357894003391266,
            0.012189388275146484,
            0.06603658944368362,
            0.013764376752078533,
            0.022353339940309525,
            -6.862115697003901e-05,
            0.001711054239422083,
            0.011983330361545086,
            0.015508645214140415,
            -0.026888111606240273,
            -0.009798622690141201,
            -0.020505845546722412,
            -0.03417651355266571,
            0.0020813741721212864,
            0.0025387094356119633,
            0.004958275239914656,
            0.010894907638430595,
            -0.003126450814306736,
            -0.037532124668359756,
            -0.029461894184350967,
            -0.01661004312336445,
            0.002882016124203801,
            0.045697037130594254,
            -0.016847901046276093,
            0.041852131485939026,
            -0.0517117902636528,
            0.06368861347436905,
            0.019036101177334785,
            -0.013864053413271904,
            -0.011069848202168941,
            -0.00014707271475344896,
            0.0032754282001405954,
            0.008449528366327286,
            -0.015064018778502941,
            -0.0036444657016545534,
            -0.020229674875736237,
            0.05071801692247391,
            0.03383348882198334,
            0.012464242056012154,
            -0.02040037512779236,
            -0.002168712904676795,
            0.025866301730275154,
            -0.01899777725338936,
            0.01957571506500244,
            0.006078195292502642,
            0.05657998099923134,
            0.011211371049284935,
            -0.04202069714665413,
            -0.026590371504426003,
            0.017725570127367973,
            0.010628778487443924,
            -0.006745391990989447,
            -0.03853810578584671,
            -0.028671134263277054,
            0.009270010516047478,
            0.007805496454238892,
            -0.008432392030954361,
            -0.051557037979364395,
            0.034883275628089905,
            0.018943993374705315,
            -0.008334874175488949,
            -0.008771722204983234,
            0.004655915312469006,
            -0.014655125327408314,
            0.008840369060635567,
            0.0123370960354805,
            -0.025331001728773117,
            0.0017484708223491907,
            -0.019921502098441124,
            0.00026314042042940855,
            -0.02680007368326187,
            0.014835475012660027,
            0.011630219407379627,
            0.01626506820321083,
            -0.02792542800307274,
            0.012381642125546932,
            0.033892516046762466,
            -0.012886171229183674,
            0.016256574541330338,
            -0.015419845469295979,
            0.026265770196914673,
            0.027811652049422264,
            0.009981098584830761,
            -0.06045936420559883,
            0.004382757470011711,
            -0.007621180731803179,
            -0.03950446471571922,
            -0.020839722827076912,
            -0.027124756947159767,
            0.013960419222712517,
            -0.05168290436267853,
            0.03361557424068451,
            0.0008382793748751283,
            -0.008090082556009293,
            0.005317403003573418,
            -0.024892929941415787,
            -0.007095733657479286,
            -0.00875022541731596,
            0.0022585734259337187,
            -0.004008120391517878,
            -0.006440762430429459,
            -0.0023852630984038115,
            0.009979971684515476,
            -0.0071289981715381145,
            -0.07116144150495529,
            -0.02054479904472828,
            -0.01893736980855465,
            -0.03843534737825394,
            -0.002468937309458852,
            -0.012761845253407955,
            -0.030081506818532944,
            -0.02010658010840416,
            0.03453928604722023,
            -0.03816842660307884,
            -0.004041044507175684,
            -0.036458976566791534,
            0.015847891569137573,
            -0.026289349421858788,
            0.016937922686338425,
            0.004484247881919146,
            -0.0044233365915715694,
            0.041595976799726486,
            0.019144579768180847,
            -0.006661823485046625,
            0.02213289588689804,
            0.005711000878363848,
            -0.005150980781763792,
            0.020061055198311806,
            -0.006221681833267212,
            -0.01949758268892765,
            0.030980678275227547,
            -0.016915375366806984,
            -0.009210609830915928,
            0.060649678111076355,
            0.017479130998253822,
            -0.030077027156949043,
            -0.013458387926220894,
            -0.007554337847977877,
            -0.01506887935101986,
            -0.02117501199245453,
            -0.04515356570482254,
            0.012931548058986664,
            -0.020205926150083542,
            0.03359390050172806,
            0.017219800502061844,
            -0.0006026770570315421,
            -0.012763183563947678,
            0.00115393684245646,
            -0.020670395344495773,
            0.015841737389564514,
            -0.029797855764627457,
            0.0008692590054124594,
            0.006441816221922636,
            0.04395947977900505,
            -0.013488761149346828,
            -0.08161299675703049,
            -0.011031653732061386,
            -0.003391685662791133,
            0.008594500832259655,
            0.007737960200756788,
            0.025502916425466537,
            -0.0015639920020475984,
            0.0334298349916935,
            -0.047661345452070236,
            -0.07624314725399017,
            -0.018544454127550125,
            0.022416694089770317,
            -0.013131363317370415,
            -0.014905428513884544,
            -0.0037966729141771793,
            0.003264523111283779,
            0.019403912127017975,
            -0.030327463522553444,
            -0.04631229117512703,
            -0.006574197206646204,
            -0.03518342226743698,
            0.018362142145633698,
            0.03910529240965843,
            -0.049535226076841354,
            0.026612883433699608,
            0.0005699474131688476,
            0.006761530879884958,
            0.0491512268781662,
            -0.003886825405061245,
            0.029081908985972404,
            0.020636294037103653,
            0.004834062419831753,
            0.006855385843664408,
            0.04015647619962692,
            -0.03277042880654335,
            -5.081768904346973e-05,
            -0.03224462643265724,
            -0.014677095226943493,
            -0.041548751294612885,
            0.1001703217625618,
            -0.00628584623336792,
            0.007496654987335205,
            0.0015397536335512996,
            0.01222052238881588,
            0.018833504989743233,
            0.009655368514358997,
            -0.00011853260366478935,
            -0.006028741132467985,
            0.011856588535010815,
            0.025250719860196114,
            -0.029743751510977745,
            0.022401059046387672,
            -0.053608376532793045,
            -0.013772419653832912,
            -0.02888571098446846,
            0.01819181628525257,
            0.03311615064740181,
            0.042500823736190796,
            0.040840961039066315,
            -0.06431059539318085,
            0.009649156592786312,
            0.0357544980943203,
            0.029775796458125114,
            -0.07155515253543854,
            0.005877931602299213,
            -0.0236013475805521,
            -0.012376118451356888,
            0.008995677344501019,
            0.02560654655098915,
            0.02450941875576973,
            -0.024484995752573013,
            0.003949020989239216,
            0.03267433121800423,
            0.04700174182653427,
            -0.016438255086541176,
            -0.006105402950197458,
            0.014022973366081715,
            -0.007402737624943256,
            -0.02258099615573883,
            0.024255678057670593,
            -0.03572845831513405,
            -0.0025294891092926264,
            -0.013823374174535275,
            -0.03801363706588745,
            0.015293581411242485,
            0.02512322925031185,
            0.06375110894441605,
            0.0009334354544989765,
            -0.011456034146249294,
            0.000445865880465135,
            -0.029359066858887672,
            -0.016885744407773018,
            -0.019725531339645386,
            -0.029079779982566833,
            -0.04234423488378525,
            -0.05389797315001488,
            0.04716012626886368,
            -0.0029575489461421967,
            -0.01404634490609169,
            -0.030224455520510674,
            0.019038265570998192,
            0.013681089505553246,
            0.01964060217142105,
            -0.0076779830269515514,
            0.017081748694181442,
            -0.0353485606610775,
            -0.016448266804218292,
            -0.024660395458340645,
            -0.0235663540661335,
            0.01719013974070549,
            0.008641519583761692,
            -0.0033496590331196785,
            -0.02682683616876602,
            -0.03918696939945221,
            -0.012856255285441875,
            -0.003768273862078786,
            0.020314620807766914,
            0.02122114785015583,
            0.02403094619512558,
            -0.029962657019495964,
            0.030132412910461426,
            0.013587135821580887,
            0.0154439527541399,
            0.028466105461120605,
            -0.01846965216100216,
            0.019650965929031372,
            0.01340203545987606,
            0.009541666135191917,
            -0.02481437847018242,
            -0.023835768923163414,
            -0.008512042462825775,
            0.023012343794107437,
            0.012352907098829746,
            0.015492097474634647,
            0.054237574338912964,
            0.013550113886594772,
            0.0070135705173015594,
            -0.043576374650001526,
            -0.030058037489652634,
            0.04778840392827988,
            0.005510745104402304,
            -0.008430472575128078,
            -0.039562392979860306,
            0.0265777837485075,
            0.04740459844470024,
            -0.004850383382290602,
            -0.014929395169019699,
            -0.006927379406988621,
            0.016707338392734528,
            -0.027105093002319336,
            0.0006849127821624279,
            -0.007503664121031761,
            0.04971011355519295,
            0.02639523334801197,
            -0.016273407265543938,
            -0.04620332270860672,
            -0.05584557726979256,
            -0.009187293238937855,
            0.00040069350507110357,
            0.05166921764612198,
            0.01696106232702732,
            -0.021989434957504272,
            -0.014451869763433933,
            0.026796957477927208,
            -0.01958666928112507,
            0.05113158002495766,
            -0.014640860259532928,
            -0.02646578475832939,
            6.177318573463708e-05,
            -0.0022540728095918894,
            0.021003471687436104,
            -0.009029380977153778,
            0.04136616736650467,
            0.024018188938498497,
            0.026605641469359398,
            0.004798023495823145,
            -0.002706347033381462,
            -0.006452598609030247,
            0.024643482640385628,
            -0.0004409248649608344,
            0.007595459930598736,
            -0.001864556921645999,
            -0.011855287477374077,
            0.017046162858605385,
            0.02853657864034176,
            0.04399394616484642,
            -0.00757036916911602,
            0.024232812225818634,
            0.015930287539958954,
            0.015518254600465298,
            0.0084447106346488,
            0.02081412449479103,
            -0.012894517742097378,
            0.017551764845848083,
            0.002426616847515106,
            0.008222666569054127,
            0.009527074173092842,
            0.0005071614868938923,
            -0.01939154788851738,
            -0.007563726510852575,
            0.010428708977997303,
            0.033216558396816254,
            0.008300664834678173,
            0.04461649805307388,
            0.010029368102550507,
            -0.03065170720219612,
            -0.05209442600607872,
            0.0076066129840910435,
            0.011660917662084103,
            0.007576602511107922,
            0.006771726068109274,
            0.008562942035496235,
            -0.014706348069012165,
            0.019294414669275284,
            0.037972498685121536,
            -0.02611537277698517,
            0.023186590522527695,
            0.007833613082766533,
            0.005885470192879438,
            -0.0206476878374815,
            0.008593291975557804,
            0.02854268252849579,
            0.028028439730405807,
            0.02965746819972992,
            -0.0029808373656123877,
            0.040501195937395096,
            0.018302377313375473,
            0.013259880244731903,
            -0.014979014173150063,
            0.016737081110477448,
            -0.03041647933423519,
            -0.0493447445333004,
            -0.02806652896106243,
            -0.02365858107805252,
            0.011753521859645844,
            -0.0025404191110283136,
            0.01365447323769331,
            0.0022535708267241716,
            -0.04125885292887688,
            -0.024122100323438644,
            0.001384842791594565,
            0.004353421274572611,
            -0.00015405916201416403,
            0.0035109780728816986,
            -0.07433076202869415,
            0.017045211046934128,
            -0.028098082169890404,
            -0.017952894791960716,
            -0.01978539116680622,
            0.02539246156811714,
            0.014831234700977802,
            0.01236786413937807,
            0.01932293176651001,
            -0.019729573279619217,
            -0.0038522593677043915,
            -0.014939979650080204,
            -0.009983736090362072,
            0.05110711604356766,
            -0.021663948893547058,
            0.015336093492805958,
            -0.04492057114839554,
            -0.0325385257601738,
            -0.02141326665878296,
            -0.048441484570503235,
            0.0404835008084774,
            0.0026821258943527937,
            0.032776039093732834,
            -0.011663803830742836,
            0.014402142725884914,
            -0.0754900574684143,
            -0.0457356795668602,
            0.006630297750234604,
            0.005976980086416006,
            0.02613014169037342,
            -0.0008061562548391521,
            -0.02575431391596794,
            0.011136447079479694,
            0.011117926798760891,
            -0.017345117405056953,
            0.042490869760513306,
            0.010889148339629173,
            -0.023602653294801712,
            0.03993414714932442,
            -0.001073010847903788,
            -0.012391585856676102,
            -0.043728094547986984
        ],
        [
            -0.019389519467949867,
            -0.019307538866996765,
            -0.03245415538549423,
            -0.004153575748205185,
            0.02343376912176609,
            0.06622491776943207,
            -0.018164990469813347,
            0.022231435403227806,
            0.0048172082751989365,
            0.016924438998103142,
            0.01868036575615406,
            0.012669924646615982,
            0.0012745243730023503,
            -0.009274493902921677,
            0.014460945501923561,
            -0.029969237744808197,
            -0.010908779688179493,
            0.007656692992895842,
            0.030019612982869148,
            0.002298674313351512,
            0.03618030995130539,
            -0.02670097164809704,
            0.03915378451347351,
            0.029961267486214638,
            -0.009566830471158028,
            0.013256990350782871,
            0.037780504673719406,
            0.040969230234622955,
            -0.007394780404865742,
            -0.003126939758658409,
            0.010155974887311459,
            -0.03432154655456543,
            -0.030153295025229454,
            0.015404283069074154,
            -0.017557892948389053,
            0.03849319741129875,
            -0.016607709228992462,
            0.016543805599212646,
            0.019021473824977875,
            0.02179417945444584,
            -0.01673937402665615,
            0.05245935916900635,
            -0.02562163770198822,
            -0.004741869866847992,
            0.0018051512306556106,
            0.024411620572209358,
            0.007023037411272526,
            0.0031565919052809477,
            0.015516703948378563,
            -0.003146336181089282,
            0.02992200292646885,
            0.021965788677334785,
            -0.007192118093371391,
            0.005651666317135096,
            -0.009961951524019241,
            -0.018302608281373978,
            0.018184779211878777,
            0.005350437015295029,
            -0.018935056403279305,
            0.007264029234647751,
            -0.03650849312543869,
            0.00911491084843874,
            -0.009997372515499592,
            -0.017905259504914284,
            0.01399897038936615,
            0.01236824132502079,
            0.014386494643986225,
            -0.006136077921837568,
            -0.007663669530302286,
            0.010985084809362888,
            0.007571754511445761,
            0.004436851479113102,
            -0.02748718671500683,
            0.012331124395132065,
            0.042378928512334824,
            -0.04409466311335564,
            -0.020142463967204094,
            -0.02237272448837757,
            0.048939913511276245,
            0.0027673153672367334,
            0.021309401839971542,
            -0.0379142202436924,
            -0.024167712777853012,
            0.013610465452075005,
            -0.025714730843901634,
            -0.03426540270447731,
            -0.02236931584775448,
            0.0030246260575950146,
            0.005710497498512268,
            -0.00318022258579731,
            -0.013393987901508808,
            -0.01718372479081154,
            0.020068589597940445,
            -0.004679810721427202,
            -0.004951226059347391,
            0.026639124378561974,
            -0.008013967424631119,
            0.02063041552901268,
            0.043519847095012665,
            0.014422000385820866,
            -0.0050466894172132015,
            0.009914753027260303,
            -0.03350947052240372,
            -0.03317875415086746,
            -0.002731959568336606,
            0.013488242402672768,
            0.012240995652973652,
            0.012517368420958519,
            -0.0028778892010450363,
            -0.01655026525259018,
            0.01358320377767086,
            0.007742266170680523,
            0.02674311399459839,
            0.026341818273067474,
            -0.04200360178947449,
            -0.02754531428217888,
            -0.0022999371867626905,
            0.026830947026610374,
            -0.01659255474805832,
            -0.022483382374048233,
            0.01533905416727066,
            0.020660456269979477,
            0.05388519912958145,
            -0.022568445652723312,
            0.001502555562183261,
            -0.041194088757038116,
            0.014270350337028503,
            0.018928280100226402,
            0.014337376691401005,
            0.00844496674835682,
            0.015532918274402618,
            0.023573894053697586,
            -0.02236664853990078,
            0.018517734482884407,
            0.020611904561519623,
            -0.004594992380589247,
            -0.0029119078535586596,
            0.012479251250624657,
            -0.0073178913444280624,
            -0.0030908710323274136,
            0.02021843008697033,
            0.01741241291165352,
            0.027656612917780876,
            -0.0005948570324108005,
            0.034255944192409515,
            -0.04388679936528206,
            0.002688815351575613,
            -0.03437507897615433,
            0.02618139609694481,
            0.008421989157795906,
            -0.02021006867289543,
            -0.003197425277903676,
            0.018453722819685936,
            0.008266744203865528,
            -0.03708469867706299,
            -0.01794479973614216,
            -0.013786538504064083,
            -0.001747235539369285,
            -0.010627874173223972,
            0.009612904861569405,
            -0.020937159657478333,
            0.020442357286810875,
            0.020338816568255424,
            0.028421442955732346,
            -0.01670072413980961,
            0.019551865756511688,
            -0.07127150893211365,
            0.010175921954214573,
            -0.014702372252941132,
            0.01607939973473549,
            -0.006189426872879267,
            -0.016647979617118835,
            -0.013719017617404461,
            0.02401536889374256,
            0.00717501062899828,
            -0.0324505940079689,
            -0.025227895006537437,
            -0.01974324695765972,
            0.046565067023038864,
            0.03085619956254959,
            -0.03668989613652229,
            -0.015131508000195026,
            0.015592426061630249,
            -0.0186380036175251,
            0.025750121101737022,
            0.023437775671482086,
            -0.008166411891579628,
            0.02462247759103775,
            -0.01834690570831299,
            -0.010008604265749454,
            -0.030414719134569168,
            0.012163394130766392,
            0.0484272763133049,
            -0.02553365007042885,
            0.024258097633719444,
            -0.009986637160182,
            -0.01573900692164898,
            0.027990901842713356,
            0.009677945636212826,
            -0.017957711592316628,
            0.02431587688624859,
            0.0002516515087336302,
            0.007371370680630207,
            0.029487570747733116,
            -0.023296762257814407,
            0.017068099230527878,
            0.048207782208919525,
            -0.022282883524894714,
            -0.002233834471553564,
            0.017449140548706055,
            -0.019361699000000954,
            -0.023354584351181984,
            0.032248593866825104,
            0.017459822818636894,
            -0.034459516406059265,
            0.011170333251357079,
            -0.03175561502575874,
            0.024007106199860573,
            0.013590843416750431,
            0.015896093100309372,
            0.01739807054400444,
            -0.01753603108227253,
            -0.020305542275309563,
            0.017197581008076668,
            -0.023026814684271812,
            -0.03191336244344711,
            0.004332211799919605,
            -0.020329277962446213,
            0.0018086126074194908,
            -0.02152649313211441,
            0.004061490297317505,
            0.00912704411894083,
            -0.01786160282790661,
            -0.031818632036447525,
            0.013340149074792862,
            0.00774542149156332,
            0.017690693959593773,
            -0.020770572125911713,
            0.014113321900367737,
            -0.01676364056766033,
            0.010041669011116028,
            -0.0047350735403597355,
            -0.035853248089551926,
            -0.0023992208298295736,
            0.0032205714378505945,
            0.0012078335275873542,
            -0.027827460318803787,
            0.025423621758818626,
            0.024321001023054123,
            -0.020253462716937065,
            -0.030948461964726448,
            -0.003756208810955286,
            0.033767879009246826,
            -0.030242282897233963,
            0.014296208508312702,
            -0.01287905778735876,
            0.017662063241004944,
            0.04144508019089699,
            -0.001598835806362331,
            -0.04069162905216217,
            -0.012344196438789368,
            0.027256427332758904,
            0.007041386794298887,
            0.00440695695579052,
            0.03468254953622818,
            -0.07979188859462738,
            0.007653543725609779,
            -0.0356176532804966,
            0.039756547659635544,
            0.02278290130198002,
            0.004360927268862724,
            -0.0016248421743512154,
            0.0036636339500546455,
            -0.01971171237528324,
            -0.018661143258213997,
            -0.01080536749213934,
            0.01869458518922329,
            -0.002421675017103553,
            0.00952177681028843,
            0.00916254986077547,
            0.03578835353255272,
            0.0023020384833216667,
            0.022135227918624878,
            0.026754023507237434,
            0.026947347447276115,
            0.02910376526415348,
            0.011166730895638466,
            -0.01567152701318264,
            -0.03500329330563545,
            -0.039389073848724365,
            -0.009260053746402264,
            0.025661546736955643,
            0.025645028799772263,
            0.00639106472954154,
            0.023975208401679993,
            -0.03587722405791283,
            -0.04875335842370987,
            0.00778226787224412,
            -0.0006006353069096804,
            -0.021379729732871056,
            0.01083564292639494,
            -0.023983009159564972,
            -0.026111165061593056,
            0.04100209102034569,
            0.034969501197338104,
            0.014527612365782261,
            -0.0013905687956139445,
            0.03538172319531441,
            -0.01956699602305889,
            -0.001990873832255602,
            -0.045531973242759705,
            -0.005941679235547781,
            -0.0021758005023002625,
            -0.026529228314757347,
            0.01928752288222313,
            -0.028273573145270348,
            0.006078184582293034,
            -0.028235360980033875,
            0.005251693539321423,
            -0.025848016142845154,
            0.008197939954698086,
            0.007457079831510782,
            -0.014930715784430504,
            0.04670662060379982,
            0.026884105056524277,
            -0.030382202938199043,
            -0.0014473532792180777,
            -0.03696826845407486,
            0.004310552962124348,
            0.008384066633880138,
            -0.023836618289351463,
            0.008361490443348885,
            -0.018463769927620888,
            0.021988429129123688,
            0.00031899524037726223,
            -0.023109130561351776,
            0.018785156309604645,
            0.030696716159582138,
            0.022081123664975166,
            0.03116690367460251,
            0.00605580722913146,
            0.042136501520872116,
            -0.016625840216875076,
            0.006572782527655363,
            -0.019004369154572487,
            0.024323342368006706,
            -0.009236909449100494,
            -0.04386558756232262,
            0.03118233196437359,
            -0.005129035096615553,
            0.004042484797537327,
            0.01184807252138853,
            0.009313170798122883,
            -0.0032252727542072535,
            0.0026697306893765926,
            0.015933729708194733,
            -0.00046506043872796,
            -0.000779283291194588,
            0.018701080232858658,
            0.027089068666100502,
            0.025805078446865082,
            -0.024919534102082253,
            -0.04313777759671211,
            0.018868274986743927,
            0.011337884701788425,
            -0.03366820141673088,
            0.03559950366616249,
            -0.027385160326957703,
            -0.020710039883852005,
            0.009569109417498112,
            0.023829294368624687,
            0.020405184477567673,
            0.052245132625103,
            0.024685826152563095,
            0.029415972530841827,
            0.00599339185282588,
            -0.011012451723217964,
            -0.011398954316973686,
            0.02110421285033226,
            -0.006907281000167131,
            0.017813658341765404,
            -0.028449349105358124,
            -0.011374182067811489,
            -0.0208649393171072,
            -0.015418709255754948,
            0.022860212251544,
            0.015331229194998741,
            0.011185571551322937,
            -0.002777049783617258,
            0.05602984130382538,
            -0.026077216491103172,
            -0.010333113372325897,
            -0.02759898453950882,
            0.012225013226270676,
            0.003960436210036278,
            0.008769392967224121,
            0.002143907593563199,
            -0.022830164059996605,
            -0.028104329481720924,
            -0.024883827194571495,
            0.012506337836384773,
            0.03167756274342537,
            0.020088037475943565,
            0.017647815868258476,
            -0.009248431771993637,
            -0.02282024919986725,
            0.017789194360375404,
            -0.02481815777719021,
            -0.018795257434248924,
            0.004985487554222345,
            0.0009952224791049957,
            0.03102877549827099,
            0.038409750908613205,
            -0.024491630494594574,
            0.017133960500359535,
            -0.01618475280702114,
            -0.021690456196665764,
            -0.03733319416642189,
            0.005992384161800146,
            -0.006899427622556686,
            0.01149070356041193,
            0.01616193726658821,
            0.0029254250694066286,
            -0.0030396580696105957,
            -0.05629342794418335,
            0.007605753373354673,
            -0.004371108487248421,
            0.019977480173110962,
            -0.01263015903532505,
            0.01669890619814396,
            0.03816675394773483,
            0.008874242193996906,
            -0.015359560959041119,
            -0.01348904613405466,
            -0.01611642725765705,
            0.015583708882331848,
            -0.012881011702120304,
            -0.007006692700088024,
            -0.029986850917339325,
            0.0243458840996027,
            0.013080193661153316,
            -0.02307782508432865,
            0.028953375294804573,
            0.019972119480371475,
            0.02225726656615734,
            -0.0038620561826974154,
            0.018063059076666832,
            0.0012142001651227474,
            0.00981560256332159,
            0.042055096477270126,
            -0.0127701535820961,
            0.0011392735177651048,
            0.015966184437274933,
            -0.014416991733014584,
            -0.004344306420534849,
            -0.011027646251022816,
            -0.01953704282641411,
            0.023529306054115295,
            0.01842549443244934,
            0.011840291321277618,
            0.049034010618925095,
            -0.013304051011800766,
            -0.0023074110504239798,
            -0.0013467913959175348,
            0.011306485161185265,
            -0.012459195218980312,
            -0.01148271281272173,
            -0.02007967419922352,
            -0.006546580232679844,
            -0.02804720588028431,
            -0.010180508717894554,
            0.00509727094322443,
            -0.013113302178680897,
            -0.037984754890203476,
            0.007744221482425928,
            -0.0491146557033062,
            0.01309614535421133,
            0.027177639305591583,
            -0.01721784844994545,
            0.008679314516484737,
            -0.02201150543987751,
            0.015890691429376602,
            -0.01750890165567398,
            0.01842629164457321,
            -0.05607933551073074,
            0.023617858067154884,
            -0.023106422275304794,
            0.04296925663948059,
            -0.03904608637094498,
            -0.0028468214441090822,
            -0.02344474010169506,
            -0.028932521119713783,
            0.02127172239124775,
            -0.0018195632146671414,
            0.015217186883091927,
            0.017341487109661102,
            0.01643344759941101,
            0.009268720634281635,
            -0.021072234958410263,
            0.018851032480597496,
            -0.004172031302005053,
            0.009575081057846546,
            -0.021844010800123215,
            0.04489538073539734,
            0.0134041802957654,
            -0.015808166936039925,
            0.006506197154521942,
            0.003173690289258957,
            0.038003940135240555,
            -0.02338930405676365,
            -0.03311096131801605,
            0.019669845700263977,
            -0.0029240755829960108,
            0.002769134007394314,
            0.054275333881378174,
            0.016887221485376358,
            -0.021377984434366226,
            -0.010656867176294327,
            0.004279412794858217,
            0.0015076722484081984,
            0.022140366956591606,
            -0.006177152507007122,
            -0.03302759304642677,
            0.023035366088151932,
            -0.0012002041330561042,
            -0.01948121376335621,
            0.03644714877009392,
            0.031796883791685104,
            0.011544772423803806,
            0.039008013904094696,
            -0.022675534710288048,
            -0.049899324774742126,
            0.00804219301789999,
            -0.026989813894033432,
            -0.03306864574551582,
            0.013274464756250381,
            -0.022724013775587082,
            0.05193354934453964,
            -0.015413361601531506,
            0.03167223557829857,
            -0.002789606573060155,
            -0.024566838517785072,
            -0.011086355894804,
            0.005944731645286083,
            0.03209219127893448,
            -0.009880189783871174,
            0.0032235728576779366,
            0.011036506853997707,
            0.018088726326823235,
            0.001045521697960794,
            -0.007137843873351812,
            -0.019240453839302063,
            0.026367874816060066,
            -0.014855125918984413,
            0.04155782237648964,
            -0.0012128609232604504,
            -0.006651721894741058,
            0.0035758228041231632,
            0.015975816175341606,
            0.01539207436144352,
            -0.017261557281017303,
            0.0004270370409358293,
            0.014378489926457405,
            -0.01636177860200405,
            -0.020220167934894562,
            -0.0169425867497921,
            0.03965100273489952,
            -0.017653770744800568,
            0.00461902329698205,
            -0.03497686982154846,
            0.011944057419896126,
            -0.026695607230067253,
            -0.02575109712779522,
            -0.014876914210617542,
            0.016286976635456085,
            -0.02111257240176201,
            0.0258534774184227,
            0.031501345336437225,
            0.01862948015332222,
            0.016093041747808456,
            -0.008128470741212368,
            0.004560929257422686,
            -0.016647910699248314,
            0.015039509162306786,
            -0.025100350379943848,
            -0.021883979439735413,
            -0.02176557295024395,
            0.026264069601893425,
            -0.013371496461331844,
            -0.020883722230792046,
            -0.026177626103162766,
            0.02235717698931694,
            0.010339759290218353,
            0.016822561621665955,
            0.0013796334387734532,
            0.01808381825685501,
            -0.009787791408598423,
            0.02860950119793415,
            -0.021978894248604774,
            0.03163299709558487,
            0.052999746054410934,
            -0.0411439910531044,
            0.01693185418844223,
            0.01613660529255867,
            0.014896060340106487,
            -0.0031584412790834904,
            -0.01268711220473051,
            -0.019466053694486618,
            -0.005781655665487051,
            0.014339140616357327,
            -0.024588754400610924,
            0.00958709605038166,
            0.02384626865386963,
            -0.039724480360746384,
            0.022012606263160706,
            -0.0025790988001972437,
            -0.02242382802069187,
            -0.013404441997408867,
            0.0011883671395480633,
            -0.018798070028424263,
            -0.02492673322558403,
            -0.038550395518541336,
            -0.005168452858924866,
            0.0007916679605841637,
            -0.017309043556451797,
            -0.009105288423597813,
            -0.03497064486145973,
            0.006169650238007307,
            0.039383355528116226,
            -0.016884300857782364,
            -0.03869029879570007,
            -0.024911941960453987,
            -0.028392141684889793,
            0.01986851915717125,
            0.024189475923776627,
            0.02841884270310402,
            -0.01532511506229639,
            0.016585782170295715,
            -0.010829235427081585,
            -0.011417346075177193,
            -0.04073093459010124,
            0.03657109662890434,
            0.0006997886812314391,
            0.032128483057022095,
            -0.010207394137978554,
            0.025069978088140488,
            -0.008403610438108444,
            -0.005280331242829561,
            0.018715322017669678,
            -0.02267027273774147,
            0.028020597994327545,
            -0.009007065556943417,
            -0.02562902309000492,
            0.027420252561569214,
            -0.02154567465186119,
            0.01994796097278595,
            0.02301270142197609,
            0.01990833319723606,
            0.0014148992486298084,
            -0.006701685022562742,
            -0.023915180936455727,
            -0.01772417686879635,
            0.025180716067552567,
            0.0026654216926544905,
            0.013780499808490276,
            0.0036798783112317324,
            -0.012158426456153393,
            0.0024698665365576744,
            -0.0010594150517135859,
            0.0007987747667357326,
            -0.03496493771672249,
            -0.025775950402021408,
            -0.02024715766310692,
            -0.016769977286458015,
            0.01672721840441227,
            0.028829576447606087,
            -0.010194945149123669,
            0.021894732490181923,
            -0.015337477438151836,
            -0.0070659383200109005,
            -0.0003902755561284721,
            -0.0038866864051669836,
            0.024241458624601364,
            0.01982501521706581,
            -0.02364661730825901,
            0.015191095881164074,
            0.008440271951258183,
            0.01075426209717989,
            -0.00201627891510725,
            -0.013711567968130112,
            -0.020024793222546577,
            0.024350611492991447,
            -0.036966338753700256,
            -0.02061736397445202,
            -0.003219052916392684,
            0.01947631500661373,
            0.015755627304315567,
            0.011686267331242561,
            0.010649566538631916,
            0.008443840779364109,
            -0.029112765565514565,
            -0.019738301634788513,
            0.016447415575385094,
            -0.02258598431944847,
            0.008013386279344559,
            0.0335792601108551,
            -0.030870378017425537,
            0.03499731421470642,
            0.027846600860357285,
            0.008311373181641102,
            0.02116519585251808,
            -0.023451602086424828,
            -0.008695404976606369,
            0.0015631841961294413,
            -0.020149152725934982,
            -0.007310162298381329,
            -0.04194628819823265,
            0.02208380587399006,
            -0.01198609545826912,
            0.00593228405341506,
            -0.008574633859097958,
            -0.029581628739833832,
            0.009376347996294498,
            0.017467621713876724,
            0.00722120376303792,
            -0.0031128707341849804,
            -0.00693398667499423,
            -0.0005205774796195328,
            -0.02083669975399971,
            0.0026462737005203962,
            0.04429489001631737,
            0.008664377965033054,
            0.02479664981365204,
            -0.0031619977671653032,
            -0.03670680895447731,
            0.015514438971877098,
            0.01888388954102993,
            0.028173919767141342,
            -0.03272286802530289,
            0.0026965539436787367,
            -8.758626063354313e-05,
            0.008282073773443699,
            0.006043140310794115,
            -0.004170791711658239,
            -0.02753213234245777,
            0.026021597906947136,
            -0.08547037094831467,
            0.003081848146393895,
            0.011797145009040833,
            -0.012021905742585659,
            0.02976781129837036,
            -0.02759949117898941,
            0.010624426417052746,
            0.0024565740022808313,
            0.0021073035895824432,
            0.00413621636107564,
            0.0013637744123116136,
            0.0020782446954399347,
            -0.03205641731619835,
            0.041315726935863495,
            0.03279754891991615,
            0.054300662130117416,
            -0.03589959815144539,
            0.026295270770788193,
            0.010487162508070469,
            0.033696793019771576,
            -0.02077939547598362,
            -0.02666562609374523,
            0.03265047445893288,
            -0.02321406826376915,
            -0.005253686103969812,
            0.04252669960260391,
            -0.018409812822937965,
            -0.014871588908135891,
            -0.02812080644071102,
            0.03178199380636215,
            0.04154554381966591,
            0.05007614940404892,
            -0.012208966538310051,
            0.01623365841805935,
            0.033067524433135986,
            0.017678488045930862,
            -0.022220119833946228,
            0.014101390726864338,
            0.01931309513747692,
            -0.008042149245738983,
            -0.02496928535401821,
            -0.008588303811848164,
            -0.016146395355463028,
            -0.016342781484127045,
            -0.004619413986802101,
            -0.015525233931839466,
            -0.04797941818833351,
            0.013268954120576382,
            0.020219596102833748,
            0.02686982974410057,
            0.02934945747256279,
            -0.005379077512770891,
            -0.006907294038683176,
            0.0009377441019751132,
            -0.040037982165813446,
            -0.02697644755244255,
            -0.020862529054284096,
            -0.000745680183172226,
            0.043395400047302246,
            0.03524971008300781,
            -0.021827049553394318,
            -0.026210755109786987,
            -0.0002915529767051339,
            0.04503107815980911,
            0.01696007326245308,
            -0.006974868476390839,
            0.026830941438674927,
            -0.032389428466558456,
            -0.008446000516414642,
            0.012390482239425182,
            0.004184695892035961,
            -0.028964633122086525,
            -0.045692797750234604,
            -0.017501622438430786,
            0.03413252532482147,
            0.03851984441280365,
            -0.034386128187179565,
            0.014102133922278881,
            0.018809309229254723,
            -0.014423786662518978,
            0.03752066195011139,
            0.022906159982085228,
            -0.015387202613055706,
            -0.008053088560700417,
            0.024933110922574997,
            0.000498526613228023,
            0.028657423332333565,
            0.024131128564476967,
            -0.024861985817551613,
            0.005016162991523743,
            0.009637176059186459,
            0.0301430132240057,
            0.015582946129143238,
            0.008798019029200077,
            0.03147733584046364,
            0.031946662813425064,
            0.006344279740005732,
            0.013953383080661297,
            -0.03201991692185402,
            -0.02717886120080948,
            0.010559318587183952,
            0.013273804448544979,
            -0.040349822491407394,
            0.0009023876627907157,
            -0.00966830737888813,
            0.0005630528321489692,
            0.01934533566236496,
            0.0008383805979974568,
            -0.02147020772099495,
            -0.03165927901864052,
            0.02515830472111702,
            -0.01627199910581112,
            0.019389228895306587,
            0.02304108254611492,
            0.028969375416636467,
            0.03309426084160805,
            0.015352601185441017,
            0.03044954687356949,
            0.013231157325208187,
            -0.016002872958779335,
            -0.01309527363628149,
            0.0026589808985590935,
            0.01891808956861496,
            0.028176093474030495,
            0.020365575328469276,
            0.0004231866041664034,
            0.03079940937459469,
            0.0012101262109354138,
            -0.03177845850586891,
            -0.009595456533133984,
            -0.0029271403327584267,
            0.08682021498680115,
            0.008558761328458786,
            0.021202927455306053,
            0.004237601067870855,
            0.05080942437052727,
            0.041136350482702255,
            0.0066085439175367355,
            0.010794251225888729,
            0.0014058698434382677,
            -0.022046351805329323,
            0.0275551900267601,
            -0.006316060200333595,
            -0.012351003475487232,
            -0.00825758557766676,
            0.012477018870413303,
            0.01967092975974083,
            -0.037285659462213516,
            0.003835924668237567,
            -0.000944221974350512,
            -0.027219004929065704,
            0.008875840343534946,
            0.031201517209410667,
            0.00035549618769437075,
            -0.03241506963968277,
            -0.007927747443318367,
            -0.0306173674762249,
            0.009705067612230778,
            0.006480923853814602,
            -0.017401255667209625,
            0.009501251392066479,
            0.012760328128933907,
            -0.036485861986875534,
            0.00038254851824603975,
            -0.015948278829455376,
            0.006311242934316397,
            -0.01353941299021244,
            -0.030560040846467018,
            0.0019533841405063868,
            -0.005347101017832756,
            0.026789788156747818,
            -0.009394697844982147,
            -0.012975748628377914,
            -0.028626350685954094,
            0.01282269973307848,
            0.01321230735629797,
            0.044783759862184525,
            0.005681656301021576,
            0.05768207833170891,
            -0.004595254547894001,
            -0.02378062531352043,
            0.0018420967971906066,
            -0.017233310267329216,
            -0.014963888563215733,
            0.023984719067811966,
            0.0060602715238928795,
            0.018956299871206284,
            0.01477486826479435,
            0.05832822248339653,
            -0.009398727677762508,
            -0.026178045198321342,
            -0.013817356899380684,
            -0.0055167595855891705,
            0.04421557858586311,
            0.00961676798760891,
            0.016749616712331772,
            0.012537233531475067,
            -0.017971858382225037,
            0.025281598791480064,
            -0.001928017707541585,
            -0.012124858796596527,
            0.0222338754683733,
            0.04435257986187935,
            -0.028509898111224174,
            0.04292580857872963,
            -0.02431865781545639,
            0.023851163685321808,
            0.033006761223077774,
            -0.004362189210951328,
            0.0084768645465374,
            -0.010462500154972076,
            -0.00013621171819977462,
            0.048413969576358795,
            0.021441809833049774,
            -0.017172040417790413,
            -0.02670319564640522,
            0.030786285176873207,
            0.0438605360686779,
            -0.018047712743282318,
            0.03026091307401657,
            0.05410556495189667,
            -0.017333168536424637,
            -0.02019890770316124,
            -0.007089471444487572,
            -0.0019424520432949066,
            0.026218969374895096,
            0.021381251513957977,
            0.02025122009217739,
            0.020021241158246994,
            -0.0036844818387180567,
            -0.018089644610881805,
            -0.02986043132841587,
            -0.018196692690253258,
            -0.007935498841106892,
            -0.006244516000151634,
            0.004138752352446318,
            0.02787700854241848,
            -0.004240446723997593,
            0.021218763664364815,
            0.01361170131713152,
            0.03186700493097305,
            -0.01217734906822443,
            0.003014615038409829,
            -0.007011867593973875,
            -0.007219317369163036,
            -0.0015171727864071727,
            0.013167029246687889,
            -0.04035496339201927,
            0.03572939336299896,
            0.05343874171376228,
            -0.025077465921640396,
            0.013625020161271095,
            0.03257706016302109,
            -0.025130735710263252,
            -0.005408523604273796,
            -0.024823343381285667,
            0.010761682875454426,
            0.008862937800586224,
            0.027927421033382416,
            -0.029120387509465218,
            -0.0002984509046655148,
            0.03805018216371536,
            0.024424519389867783,
            -0.031079687178134918,
            -0.01785534992814064,
            -0.015713466331362724,
            -0.010171356610953808,
            -0.009802780114114285,
            -0.017949974164366722,
            -0.019188866019248962,
            -0.028162457048892975,
            -0.021818341687321663,
            0.028244031593203545,
            -0.03472578525543213,
            -0.0019566586706787348,
            -0.010098843835294247,
            -0.005049276165664196,
            -0.0076681566424667835
        ],
        [
            0.031568873673677444,
            0.00836068857461214,
            0.014112140983343124,
            -0.023952515795826912,
            -0.0035361661575734615,
            -0.03652247413992882,
            0.01726687327027321,
            -0.03979110345244408,
            -0.0033974398393183947,
            -0.004994881339371204,
            0.04136320576071739,
            0.020427128300070763,
            0.01881319098174572,
            -0.032776713371276855,
            -0.042469363659620285,
            0.01399309653788805,
            -0.016576331108808517,
            0.02554520219564438,
            -0.01402779296040535,
            -0.011808672919869423,
            -0.0021879554260522127,
            -0.01341103482991457,
            -0.016853438690304756,
            -0.017421280965209007,
            -0.05102641135454178,
            0.01655663549900055,
            -0.0021551235113292933,
            -0.005397840868681669,
            -0.021028107032179832,
            -0.01970077119767666,
            -0.019370920956134796,
            0.03230622410774231,
            -0.008638272061944008,
            0.019109832122921944,
            -0.0214548222720623,
            0.00060688192024827,
            -0.029546642675995827,
            0.022281821817159653,
            -0.01645967923104763,
            0.007112966850399971,
            0.0018356719519942999,
            -0.049689583480358124,
            -0.008067470043897629,
            -0.007380843162536621,
            0.014625503681600094,
            0.009234126657247543,
            0.025908229872584343,
            -0.03290141001343727,
            -0.019677555188536644,
            0.04840930178761482,
            -0.02306385152041912,
            -0.024283967912197113,
            -0.0530906580388546,
            -0.017730584368109703,
            -0.005218996666371822,
            0.03671291843056679,
            -0.015706274658441544,
            0.01928502880036831,
            0.03260993957519531,
            0.018046727403998375,
            0.03063879907131195,
            0.009653931483626366,
            -0.02125340700149536,
            0.006231223698705435,
            -0.03335455805063248,
            0.0031292587518692017,
            0.019674578681588173,
            -0.013044647872447968,
            -0.0011076785158365965,
            0.02305499278008938,
            0.021545078605413437,
            0.0006753086927346885,
            -0.006021211389452219,
            0.01718820072710514,
            0.011868419125676155,
            -0.03186798840761185,
            0.02439850941300392,
            -0.026948805898427963,
            -0.010195707902312279,
            0.023723840713500977,
            0.007427073549479246,
            -0.02571217343211174,
            0.03198743984103203,
            -0.005304614081978798,
            -0.0025234303902834654,
            0.015167811885476112,
            -0.02375783398747444,
            -0.018108049407601357,
            0.029082082211971283,
            -0.021699823439121246,
            0.01116967387497425,
            0.005174852907657623,
            0.011481769382953644,
            0.012287631630897522,
            0.02141093835234642,
            0.01278337650001049,
            -0.00083446892676875,
            -0.0021405965089797974,
            0.08281230926513672,
            -0.0326898992061615,
            0.022306496277451515,
            -0.006655848119407892,
            -0.014302288182079792,
            -0.016183003783226013,
            0.017148366197943687,
            -0.0059837279841303825,
            0.03052808716893196,
            -0.007907356135547161,
            -0.030767323449254036,
            -0.014133589342236519,
            0.014840707182884216,
            0.00799383595585823,
            0.020718812942504883,
            -0.015846408903598785,
            -0.037113893777132034,
            -0.009378336369991302,
            -0.010424109175801277,
            0.007433864753693342,
            -0.0054463609121739864,
            0.009202085435390472,
            -0.03200119361281395,
            -0.007465213071554899,
            -0.028803443536162376,
            -0.00830860435962677,
            0.009990664198994637,
            0.014265521429479122,
            -0.00658608041703701,
            0.016890589147806168,
            -0.004981098230928183,
            0.003843584330752492,
            0.009643636643886566,
            0.040105853229761124,
            0.015082133933901787,
            -0.027180541306734085,
            -0.015938853845000267,
            0.01492193154990673,
            -0.009045042097568512,
            0.0063133458606898785,
            0.026265522465109825,
            0.0008132898365147412,
            -0.005149962846189737,
            -0.01075455080717802,
            -0.016418229788541794,
            -0.031134897843003273,
            0.016437379643321037,
            -0.005955101922154427,
            0.01183801144361496,
            0.024871470406651497,
            0.02837340533733368,
            -0.025779426097869873,
            0.007509739138185978,
            -0.006077843252569437,
            -0.016705848276615143,
            -0.03697958588600159,
            -0.021983692422509193,
            0.03181729465723038,
            0.012769884429872036,
            -0.0153525210916996,
            0.014467175118625164,
            0.030262095853686333,
            0.01201219204813242,
            -0.02256750874221325,
            0.04461313411593437,
            -0.02024783194065094,
            -0.012936375103890896,
            -0.019585317000746727,
            0.013589582405984402,
            0.03415152058005333,
            -0.042189083993434906,
            -0.00900255236774683,
            -0.02300943247973919,
            -0.026858508586883545,
            0.023743201047182083,
            -0.01616399921476841,
            -0.012548052705824375,
            -0.018275294452905655,
            -0.015373045578598976,
            0.00972949992865324,
            0.03219893202185631,
            -0.011982896365225315,
            0.0552024208009243,
            -0.02834758348762989,
            0.010808587074279785,
            -0.010395580902695656,
            -0.015613684430718422,
            0.01735919527709484,
            -0.007086329627782106,
            -0.0024958294816315174,
            0.008936748839914799,
            0.015757443383336067,
            0.01568448543548584,
            0.0269735436886549,
            -0.03625936433672905,
            -0.005276649259030819,
            -0.0018138316227123141,
            -0.04190785437822342,
            -0.030153293162584305,
            -0.009102663956582546,
            0.030481774359941483,
            -0.021445343270897865,
            0.03244638815522194,
            -0.01872221566736698,
            -0.05945225805044174,
            0.014883787371218204,
            -0.0060149142518639565,
            -0.025495585054159164,
            -0.01416853442788124,
            -0.03368876129388809,
            0.021396854892373085,
            -0.018333980813622475,
            -0.021358240395784378,
            -0.03878774493932724,
            -0.045454517006874084,
            -0.050692956894636154,
            -0.03639736771583557,
            -0.0031646578572690487,
            0.0035141748376190662,
            0.017428750172257423,
            -0.03365181013941765,
            -0.009409177117049694,
            0.0029953240882605314,
            -0.025237204506993294,
            0.032542016357183456,
            0.019431766122579575,
            -0.015288513153791428,
            -0.03717794641852379,
            0.010254496708512306,
            0.005707810632884502,
            0.03890823945403099,
            0.0308513343334198,
            0.013724438846111298,
            -0.03572816774249077,
            -0.013644060119986534,
            0.015290125273168087,
            0.01498577743768692,
            0.02325998805463314,
            -0.030500149354338646,
            0.0025295845698565245,
            -0.0016624854179099202,
            0.01923876255750656,
            -0.0024293651804327965,
            -0.014194648712873459,
            0.011204211041331291,
            -0.019111307337880135,
            0.015875836834311485,
            0.0009076935821212828,
            0.009478132240474224,
            0.0020337654277682304,
            -0.00399712985381484,
            -0.021580928936600685,
            -0.019923631101846695,
            -0.02496969886124134,
            -0.031485989689826965,
            0.011959734372794628,
            0.017597708851099014,
            -0.024960441514849663,
            0.011884048581123352,
            0.0281336959451437,
            -0.028136665001511574,
            0.003297290299087763,
            -0.0019424274796620011,
            -0.03566303849220276,
            -0.016282659024000168,
            -0.011411977931857109,
            0.0236583910882473,
            0.006341733504086733,
            0.019385403022170067,
            -0.008406550623476505,
            0.013498963788151741,
            0.024503692984580994,
            -0.004288214258849621,
            -0.02407812513411045,
            -0.013952691107988358,
            -0.012899071909487247,
            0.011158810928463936,
            -0.03886111453175545,
            -0.019598372280597687,
            0.026284553110599518,
            0.02779310569167137,
            -0.02258431725203991,
            -0.01630730926990509,
            -0.003960820380598307,
            -0.010020732879638672,
            -0.028088795021176338,
            -0.01073037926107645,
            0.020850611850619316,
            -0.018233006820082664,
            -0.008095559664070606,
            -0.01019468903541565,
            -0.033805154263973236,
            -0.014819493517279625,
            0.0488857626914978,
            0.0013635320356115699,
            -0.015341759659349918,
            -0.026652971282601357,
            -0.010846933349967003,
            0.016348104923963547,
            -0.029731962829828262,
            0.016207724809646606,
            0.02352824993431568,
            0.003681357717141509,
            0.020406723022460938,
            0.0038509052246809006,
            -0.06897223740816116,
            -0.004462719429284334,
            -0.022842252627015114,
            -0.029145805165171623,
            -0.01697755977511406,
            0.031669262796640396,
            -0.017492936924099922,
            -0.025896113365888596,
            0.00782773643732071,
            0.02601502649486065,
            -0.007468134164810181,
            0.022879445925354958,
            0.01039238553494215,
            -0.004095238167792559,
            -0.021094132214784622,
            -0.0013333054957911372,
            0.035813719034194946,
            -0.016031887382268906,
            0.03340991958975792,
            -0.013725641183555126,
            -0.031869836151599884,
            0.00400968175381422,
            0.019557064399123192,
            0.0164895411580801,
            0.0018363536801189184,
            0.0069152479991316795,
            -0.004051700234413147,
            -0.009229036048054695,
            0.013326050713658333,
            0.015305492095649242,
            0.028713008388876915,
            0.004551014397293329,
            -0.02955360896885395,
            0.012094552628695965,
            -0.029008809477090836,
            0.02728746272623539,
            0.009272838942706585,
            -0.03689351677894592,
            0.01689775474369526,
            -0.015743305906653404,
            0.02550489269196987,
            0.0034561401698738337,
            -0.0027171901892870665,
            -0.005033329129219055,
            0.004675718955695629,
            0.020333589985966682,
            0.003908140584826469,
            -0.030103493481874466,
            -0.001757443998940289,
            -0.006380228325724602,
            -0.03228622302412987,
            -0.014822564087808132,
            -0.008629019372165203,
            -0.04201635718345642,
            -0.022524690255522728,
            0.001300856121815741,
            -0.031151771545410156,
            -0.011562363244593143,
            0.01746222749352455,
            0.008824406191706657,
            -0.011941932141780853,
            -0.03866681456565857,
            0.019931476563215256,
            -0.013469697907567024,
            0.0319417268037796,
            0.015420147217810154,
            -0.007288712542504072,
            -0.006349825765937567,
            -0.0023145568557083607,
            -0.018821952864527702,
            0.023953689262270927,
            0.006072381976991892,
            -0.04189422354102135,
            0.011322000995278358,
            -0.032407961785793304,
            0.02314223162829876,
            -0.03867669776082039,
            -0.02471873164176941,
            -0.019288331270217896,
            0.0009028440690599382,
            -0.025885673239827156,
            0.012626951560378075,
            -0.019896000623703003,
            -0.002294879872351885,
            -0.07481255382299423,
            0.022383684292435646,
            -0.010219979099929333,
            -0.02693803422152996,
            -0.02108149230480194,
            -0.028805606067180634,
            -0.025531327351927757,
            -0.007533182855695486,
            0.026133323088288307,
            0.015400786884129047,
            0.005729868542402983,
            -0.027072198688983917,
            0.010753901675343513,
            0.02004537358880043,
            -0.007655408699065447,
            -0.013182494789361954,
            -0.00028773079975508153,
            0.003016121219843626,
            0.028628433123230934,
            0.011085005477070808,
            -0.007105318363755941,
            0.028750287368893623,
            0.006064698565751314,
            -0.02656850777566433,
            0.017515305429697037,
            0.009303418919444084,
            0.00665117334574461,
            -0.05003165826201439,
            0.0015697234775871038,
            0.004119726363569498,
            -0.00788024440407753,
            -0.022382060065865517,
            0.003067814512178302,
            -0.023058215156197548,
            -0.016714327037334442,
            -0.04806884378194809,
            0.022313226014375687,
            0.04082294553518295,
            0.022976508364081383,
            -0.008158489130437374,
            -0.026313601061701775,
            -0.00020136806415393949,
            -0.015773221850395203,
            0.027910519391298294,
            -0.0182627122849226,
            -0.006454381626099348,
            0.013516402803361416,
            -0.008216473273932934,
            0.017744502052664757,
            -0.021684059873223305,
            0.022935742512345314,
            0.03115910291671753,
            0.013058689422905445,
            -0.00115809659473598,
            0.008287380449473858,
            -0.013431462459266186,
            0.03293199464678764,
            0.0442320816218853,
            0.028687987476587296,
            0.026944277808070183,
            -0.09085572510957718,
            -0.03786258026957512,
            0.015712004154920578,
            0.00881264265626669,
            0.0010820104507729411,
            -0.007663056720048189,
            -0.0061536007560789585,
            -0.007608679588884115,
            -0.003363319905474782,
            0.0012631429126486182,
            0.018628908321261406,
            0.018827108666300774,
            0.007091986946761608,
            -0.005870738532394171,
            -0.027680695056915283,
            0.029385829344391823,
            -0.009815648198127747,
            -0.030301038175821304,
            -0.005656825844198465,
            -0.0025472003035247326,
            -0.037732385098934174,
            0.004023700021207333,
            0.013547781854867935,
            0.033058516681194305,
            -0.0145418094471097,
            0.017408624291419983,
            0.018199972808361053,
            -0.01074889674782753,
            -0.006073993165045977,
            -0.01844886504113674,
            0.026220394298434258,
            -0.007367729675024748,
            -0.01964595727622509,
            -0.008792993612587452,
            -0.0428573340177536,
            -0.037927258759737015,
            -0.0015430547064170241,
            0.049470849335193634,
            0.010274209082126617,
            -0.004272335208952427,
            0.0033697611652314663,
            -0.05956484377384186,
            -0.029341688379645348,
            -0.012402882799506187,
            0.02857988327741623,
            0.009697682224214077,
            -0.0005745179951190948,
            0.008356047794222832,
            0.015098728239536285,
            -0.023018645122647285,
            0.0045072766952216625,
            0.0011037932708859444,
            -0.012492332607507706,
            0.01564924791455269,
            -0.013369007967412472,
            0.02036755159497261,
            -0.015584583394229412,
            0.0159566942602396,
            -0.0277299415320158,
            -0.0016096299514174461,
            -0.009797574952244759,
            -0.008714890107512474,
            0.034099314361810684,
            0.00032136321533471346,
            -0.007028393447399139,
            -0.03601541370153427,
            0.000888515030965209,
            -0.046986740082502365,
            -0.02266709692776203,
            -0.009423932991921902,
            -0.009720567613840103,
            0.0145754124969244,
            0.01159628015011549,
            0.022674962878227234,
            -0.018300974741578102,
            -0.029485642910003662,
            -0.05132041126489639,
            -0.04652506485581398,
            -0.02957436442375183,
            -0.04041914641857147,
            0.0094941770657897,
            -0.015191673301160336,
            0.00957730133086443,
            0.007748587056994438,
            -0.03081519901752472,
            -0.0030632198322564363,
            0.005609956104308367,
            -0.02151353843510151,
            -0.009406458586454391,
            0.027785109356045723,
            -0.058068785816431046,
            -0.023041915148496628,
            -0.008066875860095024,
            0.01629336178302765,
            -0.02594812586903572,
            0.005471320822834969,
            0.01600698009133339,
            0.026793088763952255,
            -0.04866994172334671,
            0.012316448614001274,
            -0.004799668211489916,
            -0.022071480751037598,
            0.025985613465309143,
            0.008019053377211094,
            -0.027333423495292664,
            -0.004412096459418535,
            -0.00868921261280775,
            -0.0009729688754305243,
            0.02661348320543766,
            -0.0003231521986890584,
            0.03368499502539635,
            -0.008750692941248417,
            0.02145586907863617,
            0.014389926567673683,
            -0.005966933909803629,
            -0.023749595507979393,
            -0.010553698986768723,
            -0.019845958799123764,
            -0.018569355830550194,
            -0.03012162819504738,
            -0.0322970449924469,
            0.03190411627292633,
            0.011927874758839607,
            0.022198500111699104,
            -0.005886408966034651,
            -0.01596955396234989,
            0.00847688503563404,
            0.01364835910499096,
            0.010257595218718052,
            -0.002593069802969694,
            -0.02135147899389267,
            0.007525572087615728,
            -0.005513593088835478,
            0.028566433116793633,
            -0.011364229954779148,
            0.006189371924847364,
            0.016781136393547058,
            -0.02684057503938675,
            -0.005590570624917746,
            0.012971382588148117,
            -0.03757280483841896,
            0.006108319386839867,
            -0.02219146303832531,
            0.013280628249049187,
            -0.004015056416392326,
            -0.00032164351432584226,
            -0.018834535032510757,
            -0.004216151311993599,
            -0.019284239038825035,
            -0.015690987929701805,
            0.040214572101831436,
            0.02722015418112278,
            0.009997565299272537,
            -0.005887559149414301,
            -0.03010360337793827,
            0.001384579692967236,
            0.00343778682872653,
            0.013519592583179474,
            -0.05161290615797043,
            -0.0225544236600399,
            0.01655317284166813,
            -0.006588824093341827,
            0.022385016083717346,
            0.007804218214005232,
            -0.013727867975831032,
            0.014709517359733582,
            0.02783122844994068,
            0.017055178061127663,
            -0.013672465458512306,
            -0.001596548710949719,
            -0.0016272073844447732,
            0.027652936056256294,
            0.023047909140586853,
            -0.030197249725461006,
            0.031203264370560646,
            -0.017738845199346542,
            0.030482472851872444,
            0.001291643246077001,
            0.02371457777917385,
            0.023025330156087875,
            -0.014351342804729939,
            0.017165394499897957,
            0.03085019253194332,
            0.03414206951856613,
            -0.01984545588493347,
            0.018557576462626457,
            -0.02065771259367466,
            -0.0169226061552763,
            0.023037247359752655,
            -0.029876336455345154,
            0.022122839465737343,
            -0.0019575662445276976,
            -0.005305007565766573,
            0.008991370908915997,
            -0.02697923593223095,
            -0.0146662387996912,
            -0.04296485334634781,
            0.01629679650068283,
            0.005253319162875414,
            -0.045224033296108246,
            0.025185421109199524,
            -0.03837629035115242,
            0.0329580120742321,
            -0.021221593022346497,
            0.0067099579609930515,
            0.00046058918815106153,
            -0.02230416238307953,
            0.009425526484847069,
            -0.017499350011348724,
            -0.05751996859908104,
            -0.0013250726042315364,
            -0.00641261413693428,
            -0.013713246211409569,
            0.02716638892889023,
            -0.029675206169486046,
            -0.019870996475219727,
            -0.045191045850515366,
            0.0175864789634943,
            0.011143594980239868,
            -0.015452053397893906,
            -0.0338878333568573,
            -0.033679720014333725,
            -0.020170338451862335,
            0.009230288676917553,
            0.040358785539865494,
            -0.0171382874250412,
            -0.003341957461088896,
            0.023873167112469673,
            0.008143498562276363,
            0.022287286818027496,
            -0.022294828668236732,
            0.00234835222363472,
            -0.014975998550653458,
            0.021244121715426445,
            0.016991492360830307,
            -0.013797203078866005,
            -0.0409775972366333,
            0.0044331541284918785,
            -0.010976897552609444,
            -0.00413903733715415,
            0.01419734489172697,
            -0.000400537479436025,
            -0.050870686769485474,
            0.003930039703845978,
            -0.015987273305654526,
            0.002890076022595167,
            0.0013677839888259768,
            0.015845881775021553,
            -0.023555438965559006,
            0.007804322522133589,
            0.008288081735372543,
            0.020222842693328857,
            -0.048370715230703354,
            -0.03267471864819527,
            0.025190133601427078,
            0.00751033890992403,
            0.0168985053896904,
            0.010477212257683277,
            -0.00863692071288824,
            -0.008488969877362251,
            0.008950894698500633,
            0.004970168229192495,
            0.011031007394194603,
            -0.02452995255589485,
            -0.009149988181889057,
            -0.020404305309057236,
            0.02157556265592575,
            -0.032103922218084335,
            0.027747295796871185,
            0.03211119398474693,
            0.009993045590817928,
            0.011808515526354313,
            -0.0061377170495688915,
            -0.007594486698508263,
            -0.02009918913245201,
            -0.02101873978972435,
            0.004241758957505226,
            -0.020936641842126846,
            0.004473567008972168,
            -0.004544034134596586,
            0.026345929130911827,
            -0.0006865744362585247,
            0.003734143218025565,
            0.006491755601018667,
            -0.01238448079675436,
            0.011097079142928123,
            0.023846285417675972,
            0.019714215770363808,
            -0.008216646499931812,
            0.007066461723297834,
            -0.010680154897272587,
            0.02024991065263748,
            -0.052267298102378845,
            0.001766508910804987,
            -0.020886797457933426,
            0.02226065844297409,
            0.005948684643954039,
            -0.013087963685393333,
            0.0009948528604581952,
            -0.021517610177397728,
            0.0008950694464147091,
            0.020367182791233063,
            0.026496486738324165,
            -0.029225096106529236,
            -0.01954210177063942,
            -0.01712152361869812,
            0.006390908733010292,
            -0.026373080909252167,
            0.03180382400751114,
            -0.04095185920596123,
            0.013755057007074356,
            0.0052453139796853065,
            0.0025678810197860003,
            -0.04218081757426262,
            -0.06303635984659195,
            0.01748199202120304,
            -0.011028767563402653,
            -0.00479730311781168,
            -0.03789365664124489,
            -0.041362911462783813,
            0.015150188468396664,
            -0.019318468868732452,
            0.020090946927666664,
            0.0026311168912798166,
            -0.012508738785982132,
            0.002396661788225174,
            0.006358819082379341,
            0.006738440599292517,
            0.008967768400907516,
            -0.03209752216935158,
            -0.04340001940727234,
            0.0009666213882155716,
            -0.0019981381483376026,
            -0.009378188289701939,
            0.017620917409658432,
            -0.00015747123688925058,
            -0.01590118557214737,
            0.01452470663934946,
            0.018062347546219826,
            0.011702349409461021,
            0.04856954142451286,
            -0.00538678141310811,
            0.013494499959051609,
            -0.003093420062214136,
            -0.04391106590628624,
            0.037315502762794495,
            0.031521786004304886,
            0.03142659738659859,
            -0.023500967770814896,
            -0.057029590010643005,
            -0.032813891768455505,
            -0.02668985165655613,
            -0.009392458014190197,
            -0.026587437838315964,
            0.042100075632333755,
            0.0006501834141090512,
            -0.057841744273900986,
            -0.01436452567577362,
            -0.03311561420559883,
            0.045774415135383606,
            0.03345164656639099,
            0.007320110686123371,
            0.009083813987672329,
            -0.051454946398735046,
            -0.035577192902565,
            -0.016353199258446693,
            0.019174590706825256,
            -0.020036889240145683,
            -0.010148783214390278,
            0.005265733227133751,
            -0.018199793994426727,
            0.03445957601070404,
            -0.016895657405257225,
            0.01690150424838066,
            0.02214842103421688,
            0.0274165328592062,
            0.003397752530872822,
            0.03289024159312248,
            -0.025179965421557426,
            0.0057581765577197075,
            0.018925471231341362,
            0.02378668822348118,
            0.0035019349306821823,
            -0.006969226524233818,
            0.015038182958960533,
            -0.02708669938147068,
            0.04021493345499039,
            -0.04221268370747566,
            -0.039075370877981186,
            0.027623001486063004,
            0.01948336325585842,
            -0.007957881316542625,
            0.008361993357539177,
            0.025896640494465828,
            -0.030561553314328194,
            -0.04128506779670715,
            0.022318465635180473,
            0.005181436892598867,
            0.01841195672750473,
            -0.004312310833483934,
            -0.019399723038077354,
            0.04508496820926666,
            0.005585480015724897,
            -0.006174973677843809,
            -0.03230452165007591,
            0.02329440601170063,
            -0.016643891111016273,
            0.01099999900907278,
            -0.03760193660855293,
            -0.029655881226062775,
            0.0323004275560379,
            -0.006050713825970888,
            -0.013504797592759132,
            -0.04548332095146179,
            0.031555648893117905,
            -0.05646234750747681,
            -0.00218910351395607,
            0.027967853471636772,
            -0.014434591867029667,
            0.030971454456448555,
            0.04336399585008621,
            -0.009885645471513271,
            -0.033708881586790085,
            0.03898417949676514,
            0.015623555518686771,
            0.008793121203780174,
            0.018302300944924355,
            0.01482749730348587,
            -0.010685130022466183,
            0.03245745599269867,
            -0.035202521830797195,
            -0.000655914715025574,
            -0.013949098065495491,
            0.004977626260370016,
            -0.004533202853053808,
            -0.01621120050549507,
            0.009757816791534424,
            -0.0130855031311512,
            0.008089852519333363,
            0.016516035422682762,
            -0.0015706511912867427,
            -0.022825831547379494,
            -0.02202790416777134,
            -0.000840196677017957,
            0.0170428529381752,
            -0.033130452036857605,
            -0.03013382852077484,
            0.03183998167514801,
            -0.014768383465707302,
            0.021980170160531998,
            0.015326549299061298,
            0.008473060093820095,
            0.009608726017177105,
            0.0037857070565223694,
            0.0035728311631828547,
            -0.00016137924103531986,
            0.033055614680051804,
            0.011017192155122757,
            0.032543495297431946,
            -0.04362881928682327,
            -0.018155325204133987,
            -0.018924085423350334,
            -0.017829986289143562,
            0.033745698630809784,
            -0.040686190128326416,
            0.0068659232929348946,
            0.024200184270739555,
            -0.013104898855090141,
            0.022567972540855408,
            0.027265965938568115,
            -0.00845663994550705,
            -0.009061568416655064,
            -0.009152729995548725,
            0.006199806462973356,
            0.014677191153168678,
            -0.04422164708375931,
            -0.024500152096152306,
            -0.04081879183650017,
            -0.015632033348083496,
            0.023732518777251244,
            0.011054904200136662,
            0.014599528163671494,
            -0.008572065271437168,
            -0.0043153115548193455,
            -0.015065975487232208,
            -0.02205338515341282,
            -0.01444398146122694,
            -0.02955699898302555,
            0.010737575590610504,
            0.018856627866625786,
            0.019411658868193626,
            0.006862030830234289,
            0.004579238127917051,
            -0.00534974317997694,
            -0.04857458546757698,
            -0.015628181397914886,
            -0.0021252150181680918,
            -0.046279653906822205,
            0.0129148093983531,
            -0.008784046396613121,
            -0.002325015142560005,
            -0.06012817472219467,
            0.027178356423974037,
            -0.0018463965971022844,
            -0.028184471651911736,
            -0.007159475237131119,
            0.013654421083629131,
            0.00450733071193099,
            0.03093208186328411,
            -0.026617271825671196,
            -0.02010331302881241,
            0.04489770904183388,
            0.0012626700336113572,
            7.612884019181365e-06,
            -0.02989819459617138,
            -0.04924885928630829,
            0.000560610496904701,
            -0.009832818992435932,
            -0.0326562263071537,
            -0.0031152069568634033,
            0.02652158960700035,
            0.027295533567667007,
            -0.03556031361222267,
            0.008358259685337543,
            0.009386100806295872,
            0.01638495922088623,
            -0.002008036943152547,
            -0.038386955857276917,
            -0.02094663493335247,
            -0.045990969985723495,
            0.031441785395145416,
            -0.018203143030405045,
            -0.020969001576304436,
            0.003302858443930745,
            -0.007558222860097885,
            -0.023799847811460495,
            -0.032217711210250854,
            -0.009301076643168926,
            0.025574486702680588,
            -0.009727251715958118,
            0.0064727626740932465,
            0.017798427492380142,
            -0.01746206544339657,
            0.020746907219290733,
            -0.004000489600002766,
            -0.00014665942580904812,
            -0.008054034784436226,
            -0.008820274844765663,
            -0.04902466759085655,
            -0.014989204704761505,
            0.02956979162991047,
            0.034818198531866074,
            0.008602909743785858,
            0.0035272592213004827,
            0.020259667187929153,
            -0.009083390235900879,
            -0.02279229648411274,
            0.042964011430740356,
            -0.010006499476730824,
            0.022021515294909477,
            -0.01892334222793579,
            0.02049199678003788,
            -0.0017893543699756265,
            0.012205121107399464,
            -0.010927245020866394,
            -0.01728985086083412,
            0.02971358597278595,
            0.010666961781680584,
            -0.0023354687727987766,
            -0.012917677871882915,
            0.025832179933786392,
            0.022514453157782555,
            0.027226459234952927,
            0.03550940379500389,
            -0.007500878535211086,
            0.033946044743061066,
            -0.027636023238301277
        ],
        [
            -0.04042309895157814,
            -0.012206865474581718,
            -0.020112337544560432,
            -0.01876831240952015,
            -0.02291453815996647,
            -0.025184698402881622,
            -0.021381041035056114,
            0.028156252577900887,
            0.02019602060317993,
            -0.03184526041150093,
            -0.03425995633006096,
            0.012144084088504314,
            -0.023169854655861855,
            0.03200375661253929,
            0.05205315724015236,
            -0.05189384147524834,
            -0.03147955611348152,
            -0.0028956893365830183,
            -0.05885767564177513,
            0.0042948974296450615,
            -0.0005900835967622697,
            -0.019232861697673798,
            0.005975803826004267,
            -0.002649762202054262,
            0.03215924650430679,
            -0.017819739878177643,
            0.013281943276524544,
            -0.0020994222722947598,
            -0.0027428704779595137,
            -0.03288155049085617,
            0.007035715505480766,
            0.024264860898256302,
            -0.0168368611484766,
            -0.013128077611327171,
            -0.00597307039424777,
            0.0533243864774704,
            -0.03355054557323456,
            0.0009804465807974339,
            0.00792907364666462,
            -0.008418982848525047,
            -0.012870676815509796,
            0.004445945378392935,
            -0.026241958141326904,
            -0.020848780870437622,
            0.018986595794558525,
            -0.004191468935459852,
            -0.012150527909398079,
            0.007510294206440449,
            -0.002871875185519457,
            0.028252892196178436,
            0.0004932295414619148,
            0.018145091831684113,
            -0.0020221006125211716,
            0.024546319618821144,
            0.003654335392639041,
            0.025217626243829727,
            -0.005465656518936157,
            0.08978967368602753,
            -0.002886971225962043,
            -0.032558292150497437,
            -0.026179742068052292,
            0.006235784385353327,
            -0.01735115796327591,
            0.011064107529819012,
            -0.02161584608256817,
            0.027150088921189308,
            -0.024317096918821335,
            -0.021496538072824478,
            0.01301664486527443,
            0.01265736110508442,
            -0.014419294893741608,
            -0.034414686262607574,
            0.025120463222265244,
            -0.029977865517139435,
            0.030817097052931786,
            0.011225550435483456,
            0.026532532647252083,
            0.014839204028248787,
            0.009991500526666641,
            0.00403580954298377,
            -0.01922183483839035,
            0.0079541290178895,
            0.01859935186803341,
            -0.003046422963961959,
            0.023212336003780365,
            -0.012151035480201244,
            0.005647500976920128,
            0.018562903627753258,
            -0.02418644353747368,
            1.9959301425842568e-05,
            -0.013635899871587753,
            0.02541692741215229,
            -0.007076956797391176,
            0.023686330765485764,
            -0.009450441226363182,
            -0.05027830973267555,
            0.02561945468187332,
            -0.0166290495544672,
            0.06153153255581856,
            -0.010739147663116455,
            0.018572065979242325,
            0.06110965088009834,
            -0.02279401198029518,
            0.01666603982448578,
            -0.00646492512896657,
            0.0028501469641923904,
            0.020756898447871208,
            0.03165017440915108,
            0.007931776344776154,
            0.03004285879433155,
            0.018419289961457253,
            -0.0532819963991642,
            0.034152694046497345,
            -0.018918801099061966,
            -0.04191034287214279,
            -0.03532348573207855,
            0.03601783141493797,
            -0.04558439925312996,
            0.015025709755718708,
            0.009157626889646053,
            0.01674593985080719,
            0.01051359437406063,
            0.012218802236020565,
            -0.03147902339696884,
            -0.016699103638529778,
            -0.03150087594985962,
            0.05419386178255081,
            0.016544021666049957,
            0.010232903063297272,
            -0.008548314683139324,
            -0.021951569244265556,
            -0.028442788869142532,
            0.03268352150917053,
            0.029347417876124382,
            -0.02828032895922661,
            -0.020645111799240112,
            0.015138566493988037,
            -0.01617397367954254,
            0.0006547828670591116,
            0.0009336786461062729,
            -0.036132875829935074,
            0.0011085706064477563,
            -0.008580799214541912,
            -0.02893255092203617,
            0.023353444412350655,
            -0.03563136234879494,
            -0.06648971140384674,
            -0.019579865038394928,
            0.01957901380956173,
            -0.01933290809392929,
            -0.0642426609992981,
            -0.023341653868556023,
            0.010186279192566872,
            -0.027873700484633446,
            -0.017601827159523964,
            -0.015968937426805496,
            -0.013464972376823425,
            0.023501012474298477,
            0.07645802199840546,
            0.053125713020563126,
            -0.019379019737243652,
            -0.01440197229385376,
            -0.05546770989894867,
            -0.039676908403635025,
            0.030208904296159744,
            0.012836284935474396,
            -0.03511562943458557,
            0.005082827061414719,
            0.002353300340473652,
            0.013393986038863659,
            0.008193159475922585,
            -0.02279050089418888,
            0.0025094866286963224,
            -0.008554848842322826,
            0.018186848610639572,
            -0.02731943130493164,
            0.014919202774763107,
            0.02080603688955307,
            0.028306664898991585,
            -0.019191553816199303,
            0.014857016503810883,
            -0.016654793173074722,
            -0.005273870658129454,
            0.02619500830769539,
            -0.004804546013474464,
            0.012523078359663486,
            0.01018309686332941,
            -0.013377027586102486,
            -0.019861087203025818,
            0.0032917121425271034,
            -0.03420887887477875,
            0.003754351055249572,
            -0.07996027916669846,
            -0.02355792373418808,
            0.0172804594039917,
            0.03144175186753273,
            0.009675642475485802,
            0.034105993807315826,
            -0.039350476115942,
            -0.005577477160841227,
            -0.019176175817847252,
            -0.01571051962673664,
            -0.02794243022799492,
            0.007792823016643524,
            -0.015762221068143845,
            -0.021717697381973267,
            0.03170325234532356,
            0.04393244907259941,
            -0.04189472272992134,
            0.02134661003947258,
            -0.027562445029616356,
            0.003495891811326146,
            0.015835605561733246,
            0.01254269853234291,
            0.022700630128383636,
            0.03367410600185394,
            -0.0019131948938593268,
            -0.002636271994560957,
            0.007565146312117577,
            0.012899419292807579,
            0.01442619226872921,
            -0.007865573279559612,
            -0.010947397910058498,
            0.01073946338146925,
            -0.03502881899476051,
            -0.012224245816469193,
            0.03691022843122482,
            0.022027797996997833,
            0.006139071192592382,
            0.02288120612502098,
            -0.006059091072529554,
            0.033383920788764954,
            -0.039273474365472794,
            0.022405093535780907,
            -0.023533223196864128,
            0.01443796418607235,
            -0.031504541635513306,
            -0.012615738436579704,
            -0.015782911330461502,
            -0.012079255655407906,
            -0.013563641346991062,
            0.0025077317841351032,
            -0.019889134913682938,
            0.01173414383083582,
            -0.03294811770319939,
            0.014398384839296341,
            -0.0011124748270958662,
            0.011748436838388443,
            -0.03439262509346008,
            0.013419254682958126,
            -0.0010911667486652732,
            -0.028521396219730377,
            0.0019473187858238816,
            0.0005487518501468003,
            0.013352631591260433,
            0.007036754861474037,
            0.002973695285618305,
            0.01770711876451969,
            0.0016060062916949391,
            0.002840621629729867,
            0.022298350930213928,
            0.028504518792033195,
            0.03584006056189537,
            0.01589180715382099,
            0.04163909703493118,
            -0.021462317556142807,
            -0.02004954218864441,
            -9.176064486382529e-05,
            0.022038310766220093,
            -0.015397271141409874,
            0.015799596905708313,
            0.03269418329000473,
            -0.024533484131097794,
            0.025297751650214195,
            0.03710457682609558,
            -0.011165903881192207,
            -0.011333592236042023,
            0.01129140704870224,
            0.0018072571838274598,
            -0.014394172467291355,
            0.01109621673822403,
            -0.017641838639974594,
            0.02143237739801407,
            -0.04631209373474121,
            -0.031472064554691315,
            0.030467763543128967,
            -0.020068971440196037,
            -0.04156472906470299,
            0.031138140708208084,
            -0.005586705636233091,
            -0.03785154968500137,
            0.03809714317321777,
            0.015166565775871277,
            -0.0032095899805426598,
            -0.013963751494884491,
            -0.02109185792505741,
            -0.004662198014557362,
            0.03745030239224434,
            0.00847505684942007,
            0.024273011833429337,
            0.004859132692217827,
            -0.01604309491813183,
            0.009547649882733822,
            0.004461610224097967,
            0.012207132764160633,
            -0.07515603303909302,
            0.00966660212725401,
            -0.048380352556705475,
            0.00036554207326844335,
            -0.035636965185403824,
            0.01008635200560093,
            -0.024712909013032913,
            -0.013403983786702156,
            0.01046709343791008,
            0.03593118116259575,
            -0.046282634139060974,
            -0.06468996405601501,
            -0.026371272280812263,
            0.02337789721786976,
            0.011291127651929855,
            -0.0008158515556715429,
            -0.011981304734945297,
            -0.04008375480771065,
            0.03617933765053749,
            0.00038298260187730193,
            -0.044303834438323975,
            0.05582612007856369,
            -0.07759116590023041,
            -0.002234075916931033,
            -0.010154807940125465,
            0.009211280383169651,
            0.010685192421078682,
            0.036498986184597015,
            -0.014501430094242096,
            0.012006672099232674,
            0.04228891059756279,
            -0.0354212187230587,
            -0.012531603686511517,
            0.0006677883211523294,
            -0.011079522781074047,
            0.029658691957592964,
            -0.049134377390146255,
            0.011849891394376755,
            -0.009380473755300045,
            0.015025852248072624,
            -0.07428926229476929,
            -0.0061378031969070435,
            -0.0109951077029109,
            -0.04636435583233833,
            -0.03109641931951046,
            -0.010326724499464035,
            -0.029628237709403038,
            -0.004807464312762022,
            -0.009373528882861137,
            0.00115687504876405,
            -0.0014155161334201694,
            0.014664145186543465,
            0.0062918150797486305,
            -0.014557156711816788,
            -0.028428541496396065,
            -0.029481908306479454,
            0.006057500373572111,
            -0.008138814009726048,
            0.007164554204791784,
            -0.007184849586337805,
            -0.02152872644364834,
            0.004058400634676218,
            0.033600691705942154,
            -0.006247795186936855,
            0.012768113985657692,
            0.019163373857736588,
            0.02675424888730049,
            0.04020817205309868,
            0.023463981226086617,
            -0.027363097295165062,
            0.03242608532309532,
            -0.058189332485198975,
            0.0007495296304114163,
            -0.03436934947967529,
            0.025656772777438164,
            0.008203434757888317,
            0.012853167951107025,
            0.0022026461083441973,
            0.022228451445698738,
            -0.037842847406864166,
            0.03903646394610405,
            0.0025088111869990826,
            -0.020092075690627098,
            0.01929878443479538,
            -0.010957593098282814,
            0.038850441575050354,
            -0.011564994230866432,
            -0.011893904767930508,
            0.0042589642107486725,
            -0.08964109420776367,
            0.029026849195361137,
            0.003117309184744954,
            -0.04451519623398781,
            -0.05735247582197189,
            -0.052784472703933716,
            -0.015354971401393414,
            -0.009435716085135937,
            0.01514403335750103,
            0.007982971146702766,
            0.01621212437748909,
            -0.00830051675438881,
            0.001304791308939457,
            0.02042563259601593,
            0.03040662407875061,
            -0.016069728881120682,
            0.01873614639043808,
            0.004320085048675537,
            0.006710270885378122,
            0.045673444867134094,
            -0.027447212487459183,
            0.0028376574628055096,
            -0.009787354618310928,
            0.05062087997794151,
            0.0030177640728652477,
            -0.0145666329190135,
            0.03524071350693703,
            -0.0019980776123702526,
            0.046124979853630066,
            0.03274768590927124,
            -0.01847021095454693,
            -0.001223656116053462,
            -0.009043541736900806,
            -0.00964351836591959,
            -0.007949111051857471,
            0.02472185343503952,
            -0.01844627596437931,
            0.029575753957033157,
            -0.020267371088266373,
            -0.014962024055421352,
            3.547887899912894e-05,
            0.02496444620192051,
            -0.009472446516156197,
            -0.0035225532483309507,
            0.005682955030351877,
            -0.039957769215106964,
            -0.005505112931132317,
            -0.03437495231628418,
            0.005287578795105219,
            0.014897529035806656,
            0.02584010176360607,
            -0.039175909012556076,
            -0.01851661130785942,
            0.021232102066278458,
            0.008188663981854916,
            0.024877341464161873,
            -0.025268400087952614,
            -0.028288908302783966,
            0.05378282442688942,
            -0.009607408195734024,
            0.03152718394994736,
            -0.026388630270957947,
            -0.014178358018398285,
            -0.019607869908213615,
            0.005411630496382713,
            0.033812835812568665,
            0.044977109879255295,
            -0.013502833433449268,
            -0.002212891122326255,
            0.054234690964221954,
            0.016813768073916435,
            0.02451976016163826,
            -0.02584053762257099,
            -0.0018825968727469444,
            0.004251156933605671,
            0.009020754136145115,
            0.030446529388427734,
            0.0015290005831047893,
            0.02966315858066082,
            -0.015106798149645329,
            0.01653379015624523,
            -0.003632600884884596,
            0.011351393535733223,
            -0.020434889942407608,
            0.009335391223430634,
            0.01065713632851839,
            -0.00790764857083559,
            -0.027239272370934486,
            0.006630321964621544,
            0.01939166896045208,
            0.019126059487462044,
            -0.052795134484767914,
            -0.015338671393692493,
            -0.0197630412876606,
            -0.11799903959035873,
            0.0341055653989315,
            0.010995795950293541,
            -0.00403762049973011,
            -0.025444818660616875,
            -0.007357936352491379,
            0.013673736713826656,
            0.025510895997285843,
            -0.029277846217155457,
            0.02890939638018608,
            0.010640544816851616,
            -0.0006308014271780849,
            0.009433245286345482,
            0.030177362263202667,
            0.005626696161925793,
            -0.024798525497317314,
            -0.029796911403536797,
            0.026558011770248413,
            -0.028085725381970406,
            -0.01849958673119545,
            -0.06269000470638275,
            -0.012237138114869595,
            -0.023973675444722176,
            -0.032505061477422714,
            0.014559520408511162,
            0.004821279086172581,
            0.03371421992778778,
            -0.008041346445679665,
            0.010098238475620747,
            0.01138624269515276,
            0.007506281137466431,
            0.025412648916244507,
            0.006213040091097355,
            -0.04664388671517372,
            0.015351948328316212,
            0.038988761603832245,
            0.01429987046867609,
            0.014639107510447502,
            0.0042493026703596115,
            0.034022197127342224,
            0.020948154851794243,
            0.012767181731760502,
            0.020977366715669632,
            -0.005073946435004473,
            0.03409014269709587,
            -0.022695379331707954,
            0.002177033806219697,
            0.04702721908688545,
            0.01937304064631462,
            0.0019772076047956944,
            0.005992259364575148,
            -0.02782236784696579,
            -0.00886028353124857,
            0.03189874812960625,
            0.04508361592888832,
            0.021032750606536865,
            -0.020938044413924217,
            0.024192815646529198,
            0.012785842642188072,
            -0.013955474831163883,
            -0.030857766047120094,
            -0.017546484246850014,
            -0.005607263650745153,
            0.011515375226736069,
            0.03317802771925926,
            -0.0024930129293352365,
            0.041432615369558334,
            0.021396465599536896,
            -0.029869643971323967,
            -0.006048580631613731,
            -0.02911163866519928,
            0.028687667101621628,
            0.015370502136647701,
            0.008955558761954308,
            -0.023754727095365524,
            0.046450961381196976,
            0.02166507951915264,
            0.010783694684505463,
            -0.07657492160797119,
            0.017474930733442307,
            -0.0036337224300950766,
            0.036098726093769073,
            0.00943997036665678,
            0.032586827874183655,
            0.01227456796914339,
            -0.014162207953631878,
            -0.042977459728717804,
            0.011427316814661026,
            -0.042353201657533646,
            0.0017232074169442058,
            -0.008995833806693554,
            -0.013566084206104279,
            0.0309098269790411,
            0.0029749867971986532,
            -0.041245803236961365,
            0.017360175028443336,
            -0.01433187909424305,
            -0.018290670588612556,
            -0.027843089774250984,
            -0.011646837927401066,
            -0.0003212636220268905,
            -0.0082579730078578,
            -0.018596569076180458,
            0.03181428462266922,
            -0.01690167374908924,
            0.00828789733350277,
            0.007806225214153528,
            -0.03212908282876015,
            0.003991307690739632,
            0.016698643565177917,
            0.029898716136813164,
            -0.00214185263030231,
            -0.02228248305618763,
            0.02074352279305458,
            -0.02134762518107891,
            0.016092339530587196,
            9.669846622273326e-05,
            -0.019341055303812027,
            0.009009330533444881,
            0.007009440567344427,
            -0.04143592715263367,
            -0.031256742775440216,
            0.023331891745328903,
            -0.023627860471606255,
            0.017402803525328636,
            0.028720267117023468,
            0.0017255914863198996,
            -0.010259331203997135,
            -0.00299255084246397,
            0.031175460666418076,
            0.01197512075304985,
            -0.0831802487373352,
            0.009872997179627419,
            -0.03480317443609238,
            -0.004274249076843262,
            -0.03824140131473541,
            -0.01701982691884041,
            -0.01673658937215805,
            0.0207008458673954,
            0.0008168776985257864,
            -0.05004197359085083,
            -0.032304298132658005,
            0.04763130471110344,
            0.03281034156680107,
            -0.028169186785817146,
            -0.04300972446799278,
            -0.0007359626470133662,
            0.006055037025362253,
            -0.017924778163433075,
            0.027218598872423172,
            -0.028286077082157135,
            -0.027334341779351234,
            0.01508539542555809,
            0.08117803931236267,
            0.05271725729107857,
            -0.018976356834173203,
            -0.014062573201954365,
            0.0053690047934651375,
            0.002904188586398959,
            -0.03174249082803726,
            0.0099907536059618,
            0.0003740470274351537,
            -0.01739436946809292,
            -0.005266018211841583,
            -0.0232501570135355,
            -0.012608052231371403,
            -0.02231178805232048,
            -0.016436927020549774,
            -0.002135422546416521,
            -0.020367737859487534,
            0.027829725295305252,
            -0.04345741122961044,
            0.027730317786335945,
            -0.01729944720864296,
            -0.03727758303284645,
            -0.06853336840867996,
            -0.00718356017023325,
            0.0180964432656765,
            -0.013161241076886654,
            0.013526281341910362,
            0.0034473594278097153,
            0.0038886265829205513,
            0.011445262469351292,
            -0.011767624877393246,
            -0.027391942217946053,
            -0.023756319656968117,
            0.000387095584301278,
            -0.018091004341840744,
            -0.011435295455157757,
            -0.0007208609604276717,
            -0.012307507917284966,
            -0.011959502473473549,
            -0.005908978637307882,
            0.029701482504606247,
            -0.03170522674918175,
            0.022525733336806297,
            -0.0284134428948164,
            0.027137409895658493,
            0.031516093760728836,
            -0.010351413860917091,
            -0.035675980150699615,
            0.024782048538327217,
            -0.0448186919093132,
            0.03134775534272194,
            -0.01724478416144848,
            -0.023060649633407593,
            0.012141045182943344,
            0.010055053047835827,
            0.019908877089619637,
            -0.039034146815538406,
            0.020480705425143242,
            0.00946019496768713,
            0.02883310616016388,
            -0.032809194177389145,
            0.020770089700818062,
            -0.030280139297246933,
            -0.025638647377490997,
            -0.016749609261751175,
            0.016793329268693924,
            0.0009341954719275236,
            0.028767244890332222,
            -0.03128135949373245,
            -0.023801680654287338,
            0.005462144501507282,
            -0.00499985134229064,
            0.00458806287497282,
            0.047403089702129364,
            0.030617011711001396,
            0.0029571629129350185,
            -0.05378974601626396,
            0.02623039484024048,
            -0.03537864238023758,
            0.009383918717503548,
            0.011257948353886604,
            -0.006553088314831257,
            -0.004135386552661657,
            -0.009706162847578526,
            0.022953487932682037,
            0.00450459448620677,
            -0.04382018744945526,
            -0.04208923131227493,
            -0.0011663547484204173,
            -0.026611866429448128,
            0.02184455841779709,
            0.029095038771629333,
            -0.02083948254585266,
            0.02553427591919899,
            0.0167247261852026,
            0.04220607504248619,
            -0.013739628717303276,
            -0.03523445501923561,
            0.0019697078969329596,
            0.0009669140563346446,
            -0.031337663531303406,
            -0.03173945099115372,
            0.038623590022325516,
            0.018571721389889717,
            0.003061117371544242,
            -0.06148909404873848,
            -0.05080893635749817,
            -0.03267856687307358,
            0.015903281047940254,
            0.0037793631199747324,
            -0.004545392002910376,
            0.004126077517867088,
            -0.03652223199605942,
            -0.005889758467674255,
            0.001862147357314825,
            0.044615358114242554,
            -0.008720040321350098,
            0.01624891720712185,
            0.01927892118692398,
            0.023399658501148224,
            -0.007346955593675375,
            -0.04534802958369255,
            0.01885334961116314,
            -0.0031181329395622015,
            -0.021853435784578323,
            -0.023349178954958916,
            0.021341297775506973,
            0.008172073401510715,
            -0.029199538752436638,
            -0.010504094883799553,
            0.03508342057466507,
            -0.017380934208631516,
            6.466254126280546e-05,
            -0.007775598205626011,
            -0.08916530013084412,
            -0.014625053852796555,
            0.0002209042722824961,
            -0.021758731454610825,
            0.022382840514183044,
            0.022969486191868782,
            -0.021631088107824326,
            -0.020390696823596954,
            -0.027693554759025574,
            0.012159369885921478,
            0.00432219123467803,
            0.000493475585244596,
            -0.053937867283821106,
            -0.026779644191265106,
            -0.019387176260352135,
            -0.020309973508119583,
            0.02448224276304245,
            0.011764578521251678,
            0.06114989146590233,
            -0.018217332661151886,
            0.017887918278574944,
            0.011051069013774395,
            0.013092488050460815,
            -0.005648279562592506,
            0.017552098259329796,
            0.030600450932979584,
            0.023701542988419533,
            0.02385803684592247,
            0.015499657951295376,
            0.016630426049232483,
            0.003620453644543886,
            0.01678399182856083,
            -0.005969606339931488,
            0.023692991584539413,
            0.03115464746952057,
            0.03813345357775688,
            0.013702788390219212,
            -0.002753762062638998,
            0.006967965047806501,
            0.027651377022266388,
            0.008822613395750523,
            -0.0428614616394043,
            -0.05060229450464249,
            -0.020600344985723495,
            0.04413619264960289,
            -0.020617594942450523,
            0.0009529797243885696,
            0.011503566987812519,
            -0.013727439567446709,
            -0.030189065262675285,
            -0.05081203579902649,
            -0.024784734472632408,
            -0.018007665872573853,
            0.015495161525905132,
            0.010317306965589523,
            -0.009939142502844334,
            0.016994481906294823,
            -0.05643214285373688,
            0.00838107243180275,
            0.018383612856268883,
            -0.05784546211361885,
            0.0056027602404356,
            0.004221103619784117,
            -0.01854550465941429,
            -0.017830954864621162,
            -0.04007047042250633,
            -0.05025189742445946,
            -0.01379687711596489,
            -0.07674887776374817,
            -0.010798255912959576,
            0.01763349212706089,
            -0.018329786136746407,
            -0.02072596177458763,
            0.004492336418479681,
            0.006532254628837109,
            0.02121376246213913,
            -0.002121138619259,
            0.03885163366794586,
            -0.0006137910531833768,
            -0.001626624958589673,
            -0.016903648152947426,
            0.016398003324866295,
            -0.006901923567056656,
            0.00984895322471857,
            0.0024743876419961452,
            0.030978241935372353,
            0.034825682640075684,
            0.03281606733798981,
            0.024502934888005257,
            -0.00758761540055275,
            -0.014676272869110107,
            0.02606046013534069,
            0.01867877133190632,
            0.0157943032681942,
            0.0047466387040913105,
            0.025020835921168327,
            -0.020178813487291336,
            -0.01676149293780327,
            -0.030106863006949425,
            0.013038204051554203,
            -0.017522159963846207,
            -0.00041386630618944764,
            -0.09809810668230057,
            0.02392094023525715,
            0.02500600926578045,
            -0.019923904910683632,
            -0.0491669587790966,
            -0.01058541052043438,
            0.0005326384562067688,
            0.02713661454617977,
            0.004102350678294897,
            0.0032463432289659977,
            -0.08512558788061142,
            -0.01763852871954441,
            0.011401837691664696,
            -0.022751830518245697,
            0.004706970881670713,
            0.011912942864000797,
            -0.0020669661462306976,
            0.05486583337187767,
            -0.0076807900331914425,
            0.02988857962191105,
            0.01906002126634121,
            0.018599053844809532,
            -0.032996803522109985,
            0.013172710314393044,
            0.021966416388750076,
            0.0011235128622502089,
            -0.008187560364603996,
            0.04561343416571617,
            0.0016087174881249666,
            -0.0315328873693943,
            -0.02817351184785366,
            -0.015427075326442719,
            0.021350286900997162,
            0.04652765393257141,
            -0.057559072971343994,
            -0.01595368981361389,
            0.041714850813150406,
            0.0045070955529809,
            0.01717379502952099,
            -0.04801270738244057,
            0.03483555465936661,
            0.025358518585562706,
            0.004423993639647961,
            0.011134865693747997,
            -0.01640779711306095,
            0.030031180009245872,
            -0.024670535698533058,
            0.024118082597851753,
            0.01671445183455944,
            0.0052406382746994495,
            -0.01259972620755434,
            -0.031523339450359344,
            0.022398365661501884,
            -0.027999036014080048,
            0.007529643829911947,
            0.00501295318827033,
            0.015994802117347717,
            0.007654469460248947,
            0.00826248899102211,
            -0.045539483428001404,
            -0.016357310116291046,
            0.026707038283348083,
            -0.02244679443538189,
            0.015515876933932304,
            -0.006453209090977907,
            0.01562778279185295,
            -0.021786028519272804,
            -0.014459284022450447,
            0.0033212981652468443,
            0.01695428043603897,
            -0.0027724469546228647,
            0.03271011263132095,
            -0.03545810654759407,
            -0.05845991522073746,
            -0.021035658195614815,
            0.003341740695759654,
            0.02111666277050972,
            -0.008135120384395123,
            -0.018561404198408127,
            -0.009556843899190426,
            -0.013136038556694984,
            0.024024229496717453,
            0.010103104636073112,
            0.005419617518782616,
            -0.030263960361480713,
            -0.025320524349808693,
            0.006257088854908943,
            -0.020788472145795822,
            -0.0033220176119357347,
            -0.047632817178964615,
            -0.0016043914947658777,
            0.00658485759049654,
            0.010279129259288311,
            -0.018636194989085197,
            -0.006585998460650444,
            -0.00216424441896379,
            -0.021997887641191483,
            -0.016259806230664253,
            -0.017641732469201088,
            -0.036346685141325,
            0.02699383907020092,
            0.007210868876427412,
            -0.023025115951895714,
            -0.03014358878135681,
            -0.016561303287744522,
            -0.0607125498354435,
            -0.024175405502319336,
            -0.0045946151949465275,
            0.016057400032877922,
            0.033704616129398346,
            0.009234756231307983,
            0.012712299823760986,
            0.005113712977617979,
            -0.02591385878622532,
            0.03222179412841797,
            0.007121443282812834,
            0.006028251722455025,
            0.010859455913305283,
            0.03699193522334099,
            -0.02492903172969818,
            0.0053902845829725266,
            0.02638396807014942,
            -0.005942576099187136,
            0.022777840495109558,
            0.03521455451846123,
            -0.008853808045387268,
            0.007478128187358379,
            0.0242714025080204,
            -0.013593297451734543,
            -0.026494506746530533,
            0.044940367341041565,
            0.013538639061152935,
            -0.007103119511157274,
            -0.05519378185272217,
            -0.02351928874850273,
            0.02669910527765751,
            -0.021079324185848236,
            -0.010234812274575233,
            -0.012062005698680878,
            0.002637108089402318,
            0.014762761071324348,
            -0.017604460939764977,
            -0.018750298768281937,
            -0.02957584150135517,
            0.02585037238895893,
            -0.00907922349870205,
            -0.014116602018475533
        ],
        [
            0.006185157690197229,
            -0.03980572521686554,
            0.02285914495587349,
            -0.023477809503674507,
            0.006715398747473955,
            0.011552267707884312,
            -0.026894034817814827,
            -0.0317823700606823,
            -0.01022318284958601,
            -0.0209349375218153,
            -0.01569623313844204,
            -0.011262202635407448,
            0.02567463554441929,
            -0.00865263119339943,
            -0.016771873459219933,
            -0.0001711821969365701,
            -0.005561482161283493,
            -0.005397309549152851,
            -0.02985813468694687,
            -0.023341597989201546,
            -0.01948423497378826,
            -0.0026150362100452185,
            -0.0025969829875975847,
            0.0101958978921175,
            0.02224866673350334,
            0.00143788184504956,
            -0.05414571240544319,
            -0.011621329002082348,
            0.02066553570330143,
            0.010333909653127193,
            -0.01972382143139839,
            0.011205336079001427,
            -0.015842342749238014,
            -0.01826172135770321,
            -0.0065984344109892845,
            0.013597901910543442,
            -0.006897331215441227,
            0.023726800456643105,
            -0.0023799226619303226,
            0.00824957899749279,
            -0.002487042685970664,
            -0.006613545119762421,
            -0.026118189096450806,
            -0.002820811001583934,
            -0.011649233289062977,
            0.0010831443360075355,
            -0.020307622849941254,
            0.006562711205333471,
            -0.004254510160535574,
            -0.01653609611093998,
            0.04253389313817024,
            -0.0067772576585412025,
            0.01778455637395382,
            -0.02326887659728527,
            -0.05967770516872406,
            0.015558775514364243,
            0.00896509364247322,
            -0.008639762178063393,
            -0.014899836853146553,
            0.02459375001490116,
            -0.0035627034958451986,
            0.005578924901783466,
            0.029974782839417458,
            0.021502891555428505,
            0.011302847415208817,
            -0.0006578199099749327,
            -0.04221281036734581,
            0.03184960409998894,
            -0.017690474167466164,
            -0.006755966693162918,
            -0.009841501712799072,
            0.01520040724426508,
            0.03524842858314514,
            0.02370103821158409,
            -0.0175790898501873,
            -0.042879603803157806,
            -0.03770899027585983,
            0.013992111198604107,
            -0.035701751708984375,
            0.012693294323980808,
            0.005166403017938137,
            0.003423028625547886,
            0.023378608748316765,
            -0.00014151737559586763,
            0.0009444142342545092,
            0.019345371052622795,
            0.0032680605072528124,
            -0.006401915568858385,
            -0.016553634777665138,
            -0.02962341345846653,
            0.01223712507635355,
            0.03016701154410839,
            -0.006512958090752363,
            9.323262929683551e-06,
            0.0059885745868086815,
            0.013662035576999187,
            0.04000326991081238,
            -0.016959920525550842,
            -0.04203491657972336,
            -0.001168352784588933,
            0.01679426245391369,
            0.0077168322168290615,
            -0.029739942401647568,
            0.010320705361664295,
            -0.0022017131559550762,
            -0.011101381853222847,
            0.010351941920816898,
            0.011350512504577637,
            -0.027124591171741486,
            0.038190871477127075,
            -0.032468296587467194,
            -0.030985133722424507,
            0.02839188650250435,
            -0.0037945094518363476,
            -0.05345514416694641,
            -0.022911056876182556,
            0.03126267343759537,
            -0.031054439023137093,
            0.004904003813862801,
            -0.016746830195188522,
            0.0028458398301154375,
            -0.0030299616046249866,
            -0.04765172675251961,
            0.007866614498198032,
            0.008386856876313686,
            0.036673158407211304,
            0.00772442389279604,
            -0.02963927760720253,
            -0.02038426883518696,
            0.024671820923686028,
            0.015147004276514053,
            0.025815200060606003,
            -0.019231144338846207,
            0.006395953707396984,
            -0.006748370360583067,
            0.010892829857766628,
            -0.01944669336080551,
            -0.04054507613182068,
            0.004880291409790516,
            0.006950364913791418,
            0.033444471657276154,
            -0.03260907530784607,
            -0.00863227155059576,
            -0.0040054949931800365,
            0.002818432869389653,
            -0.01860799267888069,
            -0.011238026432693005,
            0.007499400991946459,
            -0.012639275752007961,
            -0.020819129422307014,
            0.011696464382112026,
            0.016872860491275787,
            0.00969737395644188,
            -0.0014697767328470945,
            0.015008100308477879,
            -0.00014442656538449228,
            0.03065977804362774,
            -0.019077811390161514,
            -0.018641002476215363,
            -0.04583800584077835,
            0.010785123333334923,
            0.008344153873622417,
            -0.017100580036640167,
            -0.02370332181453705,
            0.014516831375658512,
            -0.02863175980746746,
            -0.009097219444811344,
            0.00803350005298853,
            -0.02964818850159645,
            -0.008056181482970715,
            -0.006369780749082565,
            -0.005201338324695826,
            -0.011716699227690697,
            -0.016012724488973618,
            0.014216355979442596,
            0.008702989667654037,
            -0.016665827482938766,
            0.015263575129210949,
            -0.01534777507185936,
            -0.04489988833665848,
            0.025869004428386688,
            -0.005535898730158806,
            -0.007132466416805983,
            0.02672264538705349,
            -0.02675287239253521,
            0.000387156120268628,
            -0.01699930615723133,
            0.009511093609035015,
            0.004105184692889452,
            -0.011569199152290821,
            -0.01772923581302166,
            -0.010104087181389332,
            -0.06814414262771606,
            0.00678107887506485,
            0.009752749465405941,
            0.027523597702383995,
            -0.0012006282340735197,
            -0.0016669923206791282,
            -0.022204436361789703,
            -0.022569341585040092,
            0.001552643720060587,
            0.020682083442807198,
            -0.029489923268556595,
            0.031546566635370255,
            -0.010598530992865562,
            0.010022924281656742,
            -0.0008658415172249079,
            0.013399165123701096,
            0.009604152292013168,
            0.007714098319411278,
            -0.016913415864109993,
            -0.026667460799217224,
            0.0002601499727461487,
            0.0232868492603302,
            -0.036336418241262436,
            -0.0005632687825709581,
            0.013346265070140362,
            0.023600837215781212,
            -0.01968315616250038,
            0.016793139278888702,
            0.014006761834025383,
            0.01632056199014187,
            0.03410733491182327,
            0.011736993677914143,
            0.009015129879117012,
            -0.005903440993279219,
            -0.00032402732176706195,
            0.012561487965285778,
            -0.025697702541947365,
            0.0033699930645525455,
            -0.030868809670209885,
            -0.022941138595342636,
            0.019071407616138458,
            -0.013619122095406055,
            -0.02620985172688961,
            0.03498351573944092,
            -0.01945405639708042,
            -0.021704401820898056,
            -0.017389537766575813,
            0.01559525914490223,
            -0.004120520781725645,
            0.01991785317659378,
            0.0419403500854969,
            0.029557332396507263,
            -0.0037580570206046104,
            0.01523685548454523,
            0.02515728585422039,
            -0.025269581004977226,
            0.029831457883119583,
            0.0102852089330554,
            -0.02430395781993866,
            0.03422737494111061,
            0.006577282212674618,
            0.009733645245432854,
            -0.016041401773691177,
            -0.01520511507987976,
            -0.009122185409069061,
            0.0011868582805618644,
            0.034461814910173416,
            0.006492132321000099,
            0.002067295601591468,
            0.013108543120324612,
            -0.0021003643050789833,
            0.02195036970078945,
            0.017242537811398506,
            -0.022084152325987816,
            0.024398334324359894,
            0.0023554163053631783,
            0.01775902882218361,
            -0.02118629403412342,
            -0.0024218657054007053,
            -0.015239757485687733,
            -0.003387558739632368,
            -0.023615963757038116,
            -0.01819959469139576,
            -0.011582949198782444,
            -0.025820111855864525,
            0.009076843038201332,
            0.024455606937408447,
            -0.0028920304030179977,
            -0.02880742959678173,
            -0.0017953419592231512,
            -0.004097698722034693,
            -0.008660713210701942,
            -0.0006252016173675656,
            -0.013921130448579788,
            0.030210282653570175,
            -0.014794581569731236,
            0.003597385250031948,
            -0.003487969748675823,
            -0.010093280114233494,
            0.003365257754921913,
            -0.0334857814013958,
            0.025753110647201538,
            0.002466549864038825,
            0.005606283899396658,
            -0.016633618623018265,
            -0.0264948271214962,
            0.009898412972688675,
            0.022857632488012314,
            0.01447284035384655,
            0.0162484273314476,
            0.043926578015089035,
            0.026793943718075752,
            -0.011365135200321674,
            0.016154753044247627,
            0.008089637383818626,
            0.012950686737895012,
            0.014471709728240967,
            0.019743746146559715,
            0.026477133855223656,
            -0.0077599165961146355,
            -0.024748550727963448,
            -0.005689722485840321,
            -0.020280761644244194,
            -0.026088913902640343,
            -0.023358063772320747,
            -0.005767515394836664,
            0.01939118094742298,
            0.00871164072304964,
            0.02379065752029419,
            -0.023955868557095528,
            0.01614113338291645,
            -0.008993691764771938,
            0.013904492370784283,
            0.024628985673189163,
            0.002025732770562172,
            0.007695547305047512,
            0.0028072791174054146,
            0.01564728654921055,
            0.020272862166166306,
            -0.004681330174207687,
            -0.04632196202874184,
            0.020316097885370255,
            0.0028178058564662933,
            -0.008847260847687721,
            -0.01619724929332733,
            -0.009505819529294968,
            -0.005196271929889917,
            -0.014536295086145401,
            0.038582902401685715,
            -0.015035287477076054,
            -0.025024715811014175,
            0.004306476563215256,
            -0.027710234746336937,
            0.0020052813924849033,
            -0.007602535653859377,
            0.018463095650076866,
            0.0018467787886038423,
            0.019106723368167877,
            -0.020895466208457947,
            0.017940891906619072,
            -0.01253932248800993,
            0.022309299558401108,
            0.009498492814600468,
            -0.001496202195994556,
            -0.018206927925348282,
            -0.026674913242459297,
            0.02612035721540451,
            -0.032828547060489655,
            0.0035498288925737143,
            -0.017294825986027718,
            0.035071223974227905,
            0.009634814225137234,
            0.00010557124915067106,
            -0.004648567643016577,
            -0.030455803498625755,
            0.029751485213637352,
            -0.007863271050155163,
            -0.009535256773233414,
            -0.017053334042429924,
            -0.0031421901658177376,
            -0.06673961877822876,
            0.0295827966183424,
            -0.022503776475787163,
            -0.0009972605621442199,
            -0.0010211803019046783,
            0.013719186186790466,
            -0.0019097214099019766,
            -0.021728303283452988,
            -0.01789764314889908,
            0.027505308389663696,
            0.028490127995610237,
            -0.01711246557533741,
            -0.039381157606840134,
            -0.0021133103873580694,
            0.027561329305171967,
            -0.03488507121801376,
            0.01896834187209606,
            -0.01235919538885355,
            0.022320281714200974,
            -0.04880845546722412,
            -0.004193821921944618,
            -0.024779563769698143,
            -0.04573197662830353,
            -0.01906657963991165,
            -0.02375919558107853,
            -0.0030719952192157507,
            -0.02243145927786827,
            -0.010343177244067192,
            -0.004802856594324112,
            -0.0030864314176142216,
            -0.00720438826829195,
            0.03800026327371597,
            0.018908319994807243,
            0.02892453223466873,
            0.03099522553384304,
            0.03746362403035164,
            0.0019229925237596035,
            0.025821886956691742,
            -0.017655188217759132,
            -0.009203683584928513,
            0.040697094053030014,
            0.009930434636771679,
            -0.0368565171957016,
            0.0144666638225317,
            0.01623164303600788,
            -0.036954544484615326,
            -0.026743890717625618,
            0.021927043795585632,
            0.0024318466894328594,
            0.00378229096531868,
            -0.03593824803829193,
            -0.012200960889458656,
            0.004910058341920376,
            -0.03180351480841637,
            0.002039966406300664,
            -0.03463215380907059,
            0.017028527334332466,
            -0.03171310946345329,
            0.024504220113158226,
            -0.009783058427274227,
            -0.00134650815743953,
            -0.026602478697896004,
            0.019776588305830956,
            0.00392011646181345,
            -0.02005615085363388,
            -0.004767847713083029,
            0.0037922768387943506,
            -0.022044170647859573,
            0.0102281104773283,
            0.017067844048142433,
            0.003008310915902257,
            0.011688373051583767,
            0.01353045180439949,
            0.027481185272336006,
            0.02547447383403778,
            -0.0388825461268425,
            -0.001640553236939013,
            -0.019364595413208008,
            -0.008478658273816109,
            -0.017923502251505852,
            -0.005594357382506132,
            -0.0213079284876585,
            0.0027123941108584404,
            0.02892906591296196,
            0.014341981150209904,
            -0.023425038903951645,
            0.021969618275761604,
            0.01922547072172165,
            -0.023868516087532043,
            -0.02153669111430645,
            -0.005160888656973839,
            0.002658471465110779,
            0.020111514255404472,
            -0.02455822005867958,
            -0.0077538080513477325,
            0.023047538474202156,
            -0.004624646622687578,
            0.00858850684016943,
            0.002323902677744627,
            0.03085549734532833,
            -0.0023331823758780956,
            0.00350192841142416,
            -0.02531769871711731,
            -0.01900649257004261,
            0.0038456751499325037,
            -0.002153979614377022,
            -0.00913524255156517,
            -0.016362929716706276,
            0.010000107809901237,
            0.017867594957351685,
            0.001765348482877016,
            -0.027073046192526817,
            0.019073691219091415,
            0.0138989994302392,
            -0.024149589240550995,
            -0.009669046849012375,
            -0.06997312605381012,
            -0.043371785432100296,
            -0.004070387687534094,
            0.0208028145134449,
            0.016121869906783104,
            -0.039385177195072174,
            -0.03200764209032059,
            -0.024881934747099876,
            -0.011391943320631981,
            0.012484227307140827,
            0.03617432713508606,
            0.01997632160782814,
            -0.021341029554605484,
            0.020297465845942497,
            0.03691938892006874,
            -0.02742535062134266,
            -0.01763489656150341,
            -0.009221965447068214,
            0.013449788093566895,
            0.005616628099232912,
            0.015638595446944237,
            -0.014056854881346226,
            -0.014515247195959091,
            0.027028683573007584,
            0.022022167220711708,
            -0.007135319523513317,
            -0.017686089500784874,
            -0.023274624720215797,
            -0.022075338289141655,
            -0.009205905720591545,
            0.006083449814468622,
            0.0283474400639534,
            0.020375948399305344,
            -0.012234539724886417,
            -0.01175886020064354,
            -0.01720442622900009,
            0.0003069155791308731,
            -0.022858576849102974,
            0.0014532096683979034,
            0.016810838133096695,
            0.0176447331905365,
            -0.026443105190992355,
            -0.010096278972923756,
            -0.03128732740879059,
            0.0027530936058610678,
            0.03171258792281151,
            0.005539808422327042,
            -0.0427570715546608,
            0.03237951174378395,
            -0.04184498265385628,
            0.03435739129781723,
            0.02620241604745388,
            0.018671875819563866,
            0.010149918496608734,
            -0.0067446352913975716,
            -0.03979931399226189,
            -0.025665059685707092,
            -0.03124508447945118,
            -0.013544152490794659,
            -0.051316313445568085,
            0.020599227398633957,
            -0.00662855664268136,
            -0.015454446896910667,
            0.026041269302368164,
            0.009931585751473904,
            -0.015454830601811409,
            -0.009161524474620819,
            0.024958796799182892,
            0.02598428726196289,
            0.014317481778562069,
            -0.0225093774497509,
            0.03190072253346443,
            -0.02397196739912033,
            0.011771637946367264,
            0.022801382467150688,
            0.019067907705903053,
            -0.019628126174211502,
            -0.01271920744329691,
            0.02200264111161232,
            -0.0020464416593313217,
            -0.005058969836682081,
            -0.014728411100804806,
            0.013431179337203503,
            -0.006407695822417736,
            0.022205786779522896,
            -0.014042582362890244,
            0.010098904371261597,
            0.003041376592591405,
            0.005186816677451134,
            0.007512680254876614,
            -0.03485027328133583,
            0.0014858313370496035,
            0.02923617511987686,
            -0.008885392919182777,
            -0.04271451383829117,
            -0.006341527681797743,
            -0.011874509043991566,
            -0.022567907348275185,
            0.017582472413778305,
            -0.01500783022493124,
            -0.020817168056964874,
            0.009105844423174858,
            0.009428921155631542,
            -0.008916460908949375,
            0.0023151894565671682,
            0.013685617595911026,
            -0.015256552956998348,
            0.02654402330517769,
            -0.013146757148206234,
            -0.01737128756940365,
            -0.0017911508912220597,
            -0.03208300843834877,
            0.0021390768233686686,
            0.007466004230082035,
            -0.007033657282590866,
            0.0007812916301190853,
            0.010904506780207157,
            -0.03945380821824074,
            0.024188123643398285,
            -0.031192226335406303,
            -0.013926812447607517,
            -0.0034488688688725233,
            0.02305610291659832,
            -0.019859179854393005,
            -0.02249099127948284,
            0.025653287768363953,
            -0.04023722931742668,
            0.025468476116657257,
            -0.02775002457201481,
            0.012707341462373734,
            0.015724066644906998,
            -0.04774153605103493,
            -0.02923114225268364,
            -0.0520026795566082,
            0.02857678383588791,
            -0.0299987755715847,
            -0.006192552391439676,
            -0.04377599060535431,
            0.011845404282212257,
            -0.019099976867437363,
            0.001253810478374362,
            0.03017282299697399,
            0.0147022670134902,
            -0.026267118752002716,
            0.0023467426653951406,
            0.025571802631020546,
            0.0026794213335961103,
            0.015505828894674778,
            0.002831803634762764,
            0.04553985595703125,
            -0.0295625738799572,
            0.033954475075006485,
            -0.03973971679806709,
            0.01432090811431408,
            0.011119664646685123,
            0.006478568539023399,
            0.021400313824415207,
            0.018445074558258057,
            0.018322739750146866,
            -0.008921664208173752,
            -0.006935663986951113,
            -0.025187743827700615,
            0.03414136543869972,
            0.026180174201726913,
            -0.01268568355590105,
            0.02992996573448181,
            -0.03942158445715904,
            -0.010599956847727299,
            0.002786311088129878,
            -0.02528030425310135,
            0.02299756184220314,
            0.02724095620214939,
            -0.02002321183681488,
            -0.007595026399940252,
            -0.006260140333324671,
            -0.029013896360993385,
            0.02637000009417534,
            0.021285943686962128,
            -0.0028557665646076202,
            0.006185774225741625,
            0.031436897814273834,
            0.006427759770303965,
            -0.0037399723660200834,
            0.03698829561471939,
            0.016896190121769905,
            -0.042261213064193726,
            8.045281720114872e-05,
            -0.032464783638715744,
            0.012493269518017769,
            0.023254895582795143,
            0.018606645986437798,
            0.006788747850805521,
            -0.0027992292307317257,
            -0.008423926308751106,
            -0.030063128098845482,
            -0.013829474337399006,
            -0.0026815009769052267,
            -0.003227825276553631,
            0.030636373907327652,
            -0.004371890798211098,
            7.538439967902377e-05,
            0.0024254668969660997,
            0.0148050831630826,
            0.003292734269052744,
            0.0026212686207145452,
            0.033046822994947433,
            0.03571290522813797,
            0.009888087399303913,
            0.00841959286481142,
            0.004340025130659342,
            -0.008512690663337708,
            0.016477853059768677,
            -0.009571055881679058,
            -0.02237836644053459,
            -0.019116094335913658,
            -0.018475865945219994,
            -0.011926516890525818,
            -0.043306246399879456,
            0.01063800323754549,
            0.008161327801644802,
            0.021107131615281105,
            0.013823440298438072,
            0.025896521285176277,
            -0.008166784420609474,
            -0.008225604891777039,
            -0.0050938669592142105,
            -0.027040883898735046,
            0.0030023653525859118,
            0.0053969090804457664,
            0.030001839622855186,
            -1.3584465705207549e-05,
            -0.025231631472706795,
            -0.014696834608912468,
            0.03334895521402359,
            0.013401700183749199,
            -0.011333432048559189,
            0.027606653049588203,
            0.01739411987364292,
            0.027116188779473305,
            0.01169415283948183,
            0.024681903421878815,
            0.014928383752703667,
            -0.03988836333155632,
            0.024534376338124275,
            0.029242439195513725,
            0.03827989846467972,
            0.008144340477883816,
            -0.012912371195852757,
            0.013860817067325115,
            -0.005384081043303013,
            0.0018091858364641666,
            0.02757643535733223,
            0.004174818750470877,
            0.016635779291391373,
            0.004617893602699041,
            -0.01968555711209774,
            -0.01711975783109665,
            -0.026450732722878456,
            -0.04858751967549324,
            -0.0072771585546433926,
            0.009732574224472046,
            0.009997708722949028,
            -0.014913082122802734,
            0.016217248514294624,
            -0.021559875458478928,
            -0.01028116513043642,
            -0.0068727582693099976,
            -0.0014598588459193707,
            0.04595833271741867,
            -0.060331281274557114,
            0.029050489887595177,
            -0.004681560676544905,
            -0.004380462691187859,
            -0.040401581674814224,
            0.02491108514368534,
            0.02553519792854786,
            -0.038951363414525986,
            -0.04278578236699104,
            0.03326553851366043,
            -0.019087202847003937,
            0.023145047947764397,
            -0.019242841750383377,
            0.04208222031593323,
            -0.05682952329516411,
            -0.022149134427309036,
            0.01577901281416416,
            0.023410750553011894,
            0.017049472779035568,
            0.008078250102698803,
            -0.004372584633529186,
            0.027661975473165512,
            -0.014932410791516304,
            0.017046775668859482,
            0.002503348048776388,
            -0.023154890164732933,
            0.005270769353955984,
            0.03195885196328163,
            -0.010139168240129948,
            0.014381230808794498,
            0.01425240095704794,
            -0.031934529542922974,
            -0.027371138334274292,
            0.01270140428096056,
            0.016858575865626335,
            -0.0156150097027421,
            -0.02331393212080002,
            -0.023181065917015076,
            -0.017869237810373306,
            -0.03432992845773697,
            -0.03030586615204811,
            0.017252584919333458,
            -0.03368643298745155,
            -0.01598394848406315,
            0.025701716542243958,
            -0.019569963216781616,
            -0.0565430112183094,
            -0.004379692953079939,
            0.010795462876558304,
            -0.024162549525499344,
            -0.03489594906568527,
            0.015348060987889767,
            -0.018126236274838448,
            -0.013619457371532917,
            -0.011578739620745182,
            0.03233470395207405,
            -0.030016092583537102,
            0.02318386733531952,
            -0.010383153334259987,
            0.029014023020863533,
            -0.006400281097739935,
            0.03479000926017761,
            -0.010132275521755219,
            0.021980928257107735,
            -0.00996355526149273,
            -0.021491194143891335,
            0.006786674726754427,
            -0.014537861570715904,
            -0.0006791150663048029,
            0.01143327634781599,
            -0.019761553034186363,
            0.024125920608639717,
            0.03938251733779907,
            -0.026093346998095512,
            -0.0195502620190382,
            -0.022481640800833702,
            0.003661087481305003,
            0.0025905845686793327,
            -0.027762554585933685,
            0.012609870173037052,
            -0.014202081598341465,
            -0.006975783500820398,
            0.0014241042081266642,
            0.018263015896081924,
            0.027555160224437714,
            -0.025566039606928825,
            -0.030499381944537163,
            0.014919922687113285,
            0.02719493955373764,
            0.023582326248288155,
            -0.023907624185085297,
            0.007902441546320915,
            0.019001398235559464,
            -0.022522522136569023,
            0.023801838979125023,
            0.023245496675372124,
            0.03078869730234146,
            -0.00024125518393702805,
            -0.010200231336057186,
            -0.024191077798604965,
            0.031117616221308708,
            0.03548247739672661,
            -0.029966501519083977,
            0.02335907332599163,
            -0.023809822276234627,
            0.03749130666255951,
            -0.007445314899086952,
            -0.010562445037066936,
            0.009029807522892952,
            -0.01764320582151413,
            0.032957106828689575,
            -0.003132658079266548,
            -0.0240130964666605,
            -0.02027430571615696,
            0.019896021112799644,
            -0.06493569910526276,
            -0.008138688281178474,
            -0.0015034694224596024,
            0.02068348601460457,
            0.012456673197448254,
            -0.034515250474214554,
            0.034514449536800385,
            0.01355819869786501,
            -0.027555255219340324,
            0.008290373720228672,
            0.0003351074119564146,
            0.0012081633321940899,
            -0.01216074638068676,
            0.02616387978196144,
            -0.031168220564723015,
            -0.031136300414800644,
            -0.015048638917505741,
            0.02090841345489025,
            0.03972553461790085,
            -0.013788310810923576,
            -0.009651323780417442,
            -0.020823433995246887,
            -0.009514502249658108,
            0.008611883968114853,
            -0.028203999623656273,
            -0.05559500306844711,
            0.020585069432854652,
            0.015286648645997047,
            -0.010400896891951561,
            0.02133430726826191,
            0.02721959725022316,
            -0.019813382998108864,
            0.01823665015399456,
            0.019183292984962463,
            -0.038725435733795166,
            0.020763136446475983,
            -0.0031750365160405636,
            0.03630252927541733,
            -0.01675361767411232,
            -0.015889320522546768,
            0.025709275156259537,
            -0.014329885132610798,
            0.025879699736833572,
            -0.014135310426354408,
            -0.005344802048057318,
            -0.015531230717897415,
            -0.00500003295019269,
            0.013441044837236404,
            -0.014680637046694756,
            -0.030727075412869453,
            0.006139963399618864,
            -0.0399661585688591,
            -0.03819658234715462,
            0.04147621616721153,
            -0.015740223228931427,
            -0.0036074845120310783,
            -0.01274142973124981,
            0.005779549945145845,
            -0.01861359179019928,
            -0.010072177276015282,
            -0.029256045818328857,
            0.014940064400434494,
            -0.006458108313381672,
            -0.01633201539516449,
            0.021955415606498718,
            -0.005346684250980616,
            0.01772928796708584,
            -0.027664121240377426,
            -0.0011023570550605655,
            -0.0022505007218569517,
            -0.004220499191433191,
            -0.008168955333530903,
            0.02740875445306301,
            0.0031950066331773996,
            0.021952465176582336,
            0.042934536933898926,
            0.00496828043833375,
            -0.016442807391285896,
            -0.03007391281425953,
            0.026223544031381607,
            0.02856944315135479,
            0.007689591497182846,
            -0.013275833800435066,
            0.007285784464329481,
            -0.019907593727111816,
            0.00495245773345232,
            -0.0041267285123467445,
            -0.027757924050092697,
            -0.037345029413700104,
            0.01255920808762312,
            0.0031333588995039463,
            0.039324186742305756,
            0.025618717074394226,
            -0.012040057219564915,
            -0.001301978132687509,
            -0.003548296634107828,
            0.008763511665165424,
            0.023134492337703705,
            0.028442075476050377,
            0.001046101562678814,
            0.03293768689036369,
            0.029697079211473465,
            -0.03368459641933441,
            0.021413058042526245,
            0.0042745573446154594,
            -0.004232536535710096,
            0.033319782465696335,
            -0.008043508045375347,
            -0.015883512794971466,
            -3.834470408037305e-05,
            -0.01942148618400097,
            0.014630584977567196,
            -0.02689654566347599,
            0.002040431136265397,
            -0.009810077957808971,
            0.03629835695028305,
            -0.029083073139190674,
            -0.018988514319062233,
            0.008215627633035183,
            0.024421848356723785,
            -0.0019143399549648166,
            -0.023434601724147797,
            0.00014450401067733765,
            -0.01112774945795536,
            -0.01499910093843937,
            -0.012082301080226898,
            -0.03478043153882027,
            0.003626537974923849,
            -0.0007712243823334575,
            -0.06363128125667572,
            -0.0398889034986496,
            0.014627225697040558,
            0.013523293659090996,
            -0.005055747926235199,
            -0.023286812007427216,
            -0.015255674719810486,
            -0.0005202715401537716,
            0.0197010338306427,
            -0.023181457072496414,
            -0.03509014844894409,
            -0.0007912238943390548,
            0.020940616726875305,
            -0.002087874338030815,
            0.031468890607357025,
            0.0007697615073993802,
            0.00261064525693655,
            -0.03273937106132507,
            0.0258135125041008,
            0.00016975021571852267,
            0.03473819047212601,
            -0.03807203471660614,
            0.011576592922210693
        ],
        [
            -0.033215250819921494,
            0.015630574896931648,
            0.00471640145406127,
            -0.0049508074298501015,
            0.023116109892725945,
            -0.014178362675011158,
            0.005072272848337889,
            -0.013012104667723179,
            0.0043498133309185505,
            -0.014748066663742065,
            0.008015371859073639,
            0.012168508023023605,
            -0.05399013310670853,
            -0.022046493366360664,
            -0.01729290746152401,
            -0.02154853194952011,
            0.017268193885684013,
            -0.01816066913306713,
            -0.032895877957344055,
            0.011627004481852055,
            0.015717923641204834,
            -0.02365013398230076,
            0.030694734305143356,
            -0.028365710750222206,
            -0.01950874738395214,
            0.004884133581072092,
            0.014837294816970825,
            0.031297467648983,
            0.02278999052941799,
            0.006489141844213009,
            0.019834745675325394,
            0.008500220254063606,
            -0.03969838097691536,
            -0.03735392168164253,
            -0.03451121971011162,
            -0.020420167595148087,
            -0.015617414377629757,
            -0.009432724677026272,
            0.025427065789699554,
            -0.03390480950474739,
            0.006157314404845238,
            -0.0013854842400178313,
            0.01635099947452545,
            0.011406605131924152,
            -0.04249880090355873,
            -0.01770593225955963,
            -0.033741869032382965,
            0.027956131845712662,
            0.033718690276145935,
            0.0026209717616438866,
            -0.015463335439562798,
            0.012227145954966545,
            -0.03152511268854141,
            0.02834632433950901,
            0.016593877226114273,
            -0.02528460882604122,
            0.01613829843699932,
            0.03593369945883751,
            -0.011242348700761795,
            -0.017026416957378387,
            0.02130691148340702,
            -0.02501838654279709,
            0.010437608696520329,
            0.01372441928833723,
            -0.0010064562084153295,
            0.044949330389499664,
            -0.07932259142398834,
            0.00839911587536335,
            0.017923083156347275,
            0.027689127251505852,
            0.005726102273911238,
            0.016003843396902084,
            -0.03413258120417595,
            -0.033774543553590775,
            -0.03473806381225586,
            0.037186454981565475,
            0.032707951962947845,
            0.015402182936668396,
            -0.032309338450431824,
            0.013775056228041649,
            -0.027936164289712906,
            -0.01411521527916193,
            -0.007206059992313385,
            -0.01508296001702547,
            0.015887705609202385,
            -0.0013078892370685935,
            0.01108498964458704,
            -0.019283004105091095,
            0.01742180436849594,
            0.030722979456186295,
            -0.008940630592405796,
            0.0101289888843894,
            0.01493597961962223,
            0.029059041291475296,
            -0.017444849014282227,
            0.003940210677683353,
            -0.021449262276291847,
            0.0005125891184434295,
            0.07284528762102127,
            0.008452036418020725,
            -0.004913663957268,
            -0.04221009090542793,
            -0.006025236565619707,
            0.009287544526159763,
            0.020219450816512108,
            -0.003994068596512079,
            -0.010657289065420628,
            -0.023878056555986404,
            0.041605785489082336,
            0.005798415746539831,
            -0.010833329521119595,
            0.00918456632643938,
            -0.01548726111650467,
            -0.03178256377577782,
            0.032434217631816864,
            0.012026595883071423,
            0.030808553099632263,
            0.009231584146618843,
            0.020640112459659576,
            -0.022161759436130524,
            -0.011392157524824142,
            0.008804745972156525,
            0.020771227777004242,
            -0.022051502019166946,
            -0.018601469695568085,
            -0.02051842212677002,
            0.02500724419951439,
            -0.003124726004898548,
            -0.017595600336790085,
            -0.029551053419709206,
            -2.587977724033408e-05,
            -0.011564893648028374,
            0.0392480306327343,
            0.05094701051712036,
            0.028341926634311676,
            -0.03228943794965744,
            -0.027293402701616287,
            0.037189699709415436,
            -0.02946021780371666,
            0.02608391083776951,
            -0.023910941556096077,
            0.00472415192052722,
            -0.017596527934074402,
            -0.011633327230811119,
            0.027888614684343338,
            -0.0029668172355741262,
            -0.004103112034499645,
            0.006198834162205458,
            -0.0021903649903833866,
            -0.033052802085876465,
            0.021957997232675552,
            -0.06586740911006927,
            0.018386900424957275,
            0.003982618451118469,
            0.018051983788609505,
            -0.0017913454212248325,
            -0.017914289608597755,
            -0.05544260889291763,
            -0.004132130648940802,
            0.029155809432268143,
            0.01224608812481165,
            0.026134628802537918,
            -0.02556350640952587,
            0.018509292975068092,
            -0.0012201247736811638,
            -0.03436795622110367,
            -0.004564069211483002,
            0.04762914404273033,
            0.024668147787451744,
            0.016676165163517,
            -0.013850495219230652,
            0.010236131958663464,
            -0.041342176496982574,
            -0.06427793949842453,
            -0.018172703683376312,
            0.009945373982191086,
            0.012574505992233753,
            -0.0021491211373358965,
            -0.010193132795393467,
            -0.011284491047263145,
            -0.02137100324034691,
            -0.020247187465429306,
            -0.035231441259384155,
            -0.0036075497046113014,
            0.021056095138192177,
            0.02342652715742588,
            -0.010106409899890423,
            -0.04296322166919708,
            -0.002839388558641076,
            0.01959621161222458,
            0.01096480805426836,
            -0.022343717515468597,
            0.04815736040472984,
            0.002449955325573683,
            0.011397392489016056,
            -0.040428150445222855,
            0.00964595377445221,
            -0.006388614885509014,
            -0.04390484467148781,
            0.03229852393269539,
            -0.02234060689806938,
            0.009575145319104195,
            0.013681475073099136,
            0.006138192489743233,
            0.05268681421875954,
            0.025377962738275528,
            0.018014905974268913,
            -0.009418079629540443,
            -0.01704430766403675,
            0.016665514558553696,
            0.017376292496919632,
            -0.01102124247699976,
            -0.009375629015266895,
            -0.02447867952287197,
            -0.016892138868570328,
            0.031466446816921234,
            -0.009769964031875134,
            0.007267961278557777,
            0.010842104442417622,
            -0.0001698839187156409,
            -0.004998700227588415,
            0.024164825677871704,
            -0.0206860713660717,
            0.03334984555840492,
            -0.020217323675751686,
            -0.0282900370657444,
            -0.08867097645998001,
            0.001217776327393949,
            0.02382519468665123,
            0.004125527571886778,
            0.02034861221909523,
            -0.009842632338404655,
            -0.0532512366771698,
            -0.032059744000434875,
            0.042971689254045486,
            -0.025163989514112473,
            -0.004967785906046629,
            -0.013083301484584808,
            0.023612258955836296,
            0.008219385519623756,
            0.0031352315563708544,
            0.00901223998516798,
            0.007954159751534462,
            0.0029210220091044903,
            0.015319928526878357,
            -0.007170837838202715,
            -0.0248129703104496,
            0.004117381293326616,
            -0.01056578103452921,
            0.024194812402129173,
            0.002874357858672738,
            0.01977311447262764,
            -0.00020599228446371853,
            -0.0188816599547863,
            -0.023439684882760048,
            0.019093116745352745,
            0.03113672323524952,
            0.0035935223568230867,
            0.03208785876631737,
            -0.009735824540257454,
            0.010206950828433037,
            0.016872739419341087,
            0.011281650513410568,
            -0.0034598009660840034,
            0.03840691223740578,
            0.018521837890148163,
            -0.029075825586915016,
            -0.008554632775485516,
            0.023167692124843597,
            0.02399730682373047,
            0.013216428458690643,
            0.004861508030444384,
            -0.0064141955226659775,
            0.005646462552249432,
            0.006278985179960728,
            0.027387753129005432,
            -0.049630019813776016,
            -0.01868944987654686,
            0.03229520097374916,
            0.0027455834206193686,
            -0.006094803102314472,
            0.02209632284939289,
            -0.028293704614043236,
            -0.023313723504543304,
            -0.05524330213665962,
            0.009675076231360435,
            0.007753899320960045,
            -0.04923853650689125,
            -0.0008725692168809474,
            0.02273024618625641,
            -0.011426501907408237,
            0.030193863436579704,
            0.00012473551032599062,
            -0.0015208610566332936,
            -0.0026393267326056957,
            -0.005365039687603712,
            0.004711531568318605,
            0.01167944073677063,
            0.03027772344648838,
            0.014836512506008148,
            -0.0031464549247175455,
            0.02499852329492569,
            -0.002812911756336689,
            -0.027245108038187027,
            -0.03131043165922165,
            -0.0537741482257843,
            -0.0026346626691520214,
            -0.02664690837264061,
            -0.02922670915722847,
            -0.00827164575457573,
            -0.0043917251750826836,
            -0.01718791387975216,
            0.023307273164391518,
            -0.005542243365198374,
            0.03153959661722183,
            0.004577441141009331,
            -0.019483797252178192,
            0.0010519004426896572,
            -0.0006132088601589203,
            -0.005991511978209019,
            -0.0012440901482477784,
            -0.06282496452331543,
            -0.03546326979994774,
            0.014328314922749996,
            0.019165046513080597,
            -0.024411074817180634,
            -0.003966871183365583,
            -0.0239836685359478,
            0.03287075459957123,
            0.030788833275437355,
            0.02689032442867756,
            -0.0010330489603802562,
            0.013779588043689728,
            -0.0023541797418147326,
            0.023501433432102203,
            -0.0029401422943919897,
            -0.023982329294085503,
            -0.026483315974473953,
            -0.0018278692150488496,
            -0.011901389807462692,
            0.00630426499992609,
            0.020725509151816368,
            -0.010729929432272911,
            -0.035079266875982285,
            0.028670523315668106,
            0.0029521251562982798,
            0.014403999783098698,
            -0.0011691957479342818,
            0.026277069002389908,
            -0.04041093587875366,
            0.04466140270233154,
            0.006782308220863342,
            -0.05587354674935341,
            -0.038509320467710495,
            0.0018730164738371968,
            0.015230520628392696,
            -0.02074199914932251,
            0.00798666663467884,
            -0.02025900036096573,
            0.04291822761297226,
            0.005993878003209829,
            0.03913537412881851,
            0.02505519799888134,
            0.03357314318418503,
            -0.028232287615537643,
            -0.016162779182195663,
            0.03726795315742493,
            0.016415635123848915,
            -0.04547484964132309,
            0.0029497563373297453,
            0.005760890431702137,
            0.002805957105010748,
            -0.018089164048433304,
            -0.005394385661929846,
            -0.021628940477967262,
            -0.03129862993955612,
            -0.019554710015654564,
            0.025053517892956734,
            0.0016956423642113805,
            0.03353379666805267,
            0.014613036997616291,
            -0.010655670426785946,
            0.012373470701277256,
            -0.007854183204472065,
            0.04163742437958717,
            -0.005592749919742346,
            0.025490984320640564,
            0.035292886197566986,
            0.012952174991369247,
            -0.006543178576976061,
            -0.005295929498970509,
            0.00238186027854681,
            0.004391391761600971,
            0.03894539549946785,
            0.004116728901863098,
            -0.02654598280787468,
            0.017210854217410088,
            -0.021484190598130226,
            0.013294750824570656,
            -0.022800734266638756,
            0.007245689630508423,
            0.00789585430175066,
            -0.01098315417766571,
            0.018968747928738594,
            0.03348840773105621,
            0.007709712255746126,
            0.0058709606528282166,
            -0.017606938257813454,
            0.024695560336112976,
            -0.011076544411480427,
            -0.03952702879905701,
            0.02352331206202507,
            0.01454885397106409,
            0.027443215250968933,
            -0.01470190193504095,
            -0.016544008627533913,
            -0.03549598157405853,
            0.03621736541390419,
            -0.006656154058873653,
            0.026403479278087616,
            0.022475484758615494,
            -0.02852109633386135,
            -0.03165440633893013,
            0.01878468692302704,
            -0.0024605472572147846,
            0.026663344353437424,
            0.021094633266329765,
            -0.017682256177067757,
            -0.020691459998488426,
            -0.005451638717204332,
            -0.023151904344558716,
            -0.0018243147060275078,
            0.008170616813004017,
            -0.023784736171364784,
            -0.0435798242688179,
            -0.006876354105770588,
            0.03119751811027527,
            -0.00879677478224039,
            0.0210280679166317,
            0.008953004144132137,
            -0.015053098089993,
            -0.009185749106109142,
            0.012504467740654945,
            -0.00907687284052372,
            -0.02665683627128601,
            -0.013508439064025879,
            -0.029362047091126442,
            -0.08296489715576172,
            0.0019847266376018524,
            0.03862275555729866,
            0.013455960899591446,
            -0.006549081765115261,
            0.013142988085746765,
            0.029529297724366188,
            0.003043105360120535,
            0.038084451109170914,
            -0.00861689168959856,
            6.171007407829165e-05,
            0.022815080359578133,
            0.023068716749548912,
            0.03373820707201958,
            -0.026538735255599022,
            -0.013857926242053509,
            0.008358646184206009,
            -0.020009782165288925,
            -0.02109442837536335,
            -0.02315841242671013,
            0.0319589339196682,
            -0.035384587943553925,
            -0.006469849031418562,
            5.404721014201641e-05,
            0.01442886795848608,
            0.02139718271791935,
            -0.03585505485534668,
            0.016653796657919884,
            0.025028904899954796,
            0.034302111715078354,
            -0.02091367542743683,
            0.025408916175365448,
            -0.010342959314584732,
            0.007509763818234205,
            0.0010106672998517752,
            0.039187606424093246,
            -0.0178066398948431,
            -0.03915000334382057,
            0.015372751280665398,
            -0.011027928441762924,
            0.030111683532595634,
            0.07090367376804352,
            0.014476273208856583,
            -0.003914780914783478,
            -0.00872134417295456,
            0.0007942072115838528,
            0.011312099173665047,
            -0.02541806362569332,
            0.028984013944864273,
            -0.046328213065862656,
            0.004669592250138521,
            0.02866143360733986,
            0.030361901968717575,
            -0.014550422318279743,
            -0.04258335009217262,
            0.009577675722539425,
            -0.02286767028272152,
            0.00793056283146143,
            -0.002652756404131651,
            -0.008823337964713573,
            -0.020095089450478554,
            0.009446333162486553,
            -0.008964499458670616,
            0.02534363605082035,
            -0.0073977201245725155,
            0.016303788870573044,
            -0.008182058110833168,
            -0.0004802121839020401,
            0.03018779680132866,
            0.022304562851786613,
            -0.007482118438929319,
            -0.014198891818523407,
            0.021096812561154366,
            -0.024096574634313583,
            -0.011179307475686073,
            -0.010654277168214321,
            0.007847810164093971,
            -0.044892989099025726,
            -0.004980148281902075,
            0.011906134895980358,
            -0.006593803875148296,
            0.014474817551672459,
            -0.05705489218235016,
            -0.0005078450194559991,
            -0.00502769835293293,
            -0.012171498499810696,
            -0.018526768311858177,
            0.024882126599550247,
            -0.0021671766880899668,
            -0.02438758872449398,
            0.01214424055069685,
            -0.001120990258641541,
            -0.0032935182098299265,
            0.018215155228972435,
            0.02666282095015049,
            0.00047897896729409695,
            -0.019257646054029465,
            -0.01898622140288353,
            -0.011352560482919216,
            -0.04248952865600586,
            0.04326986148953438,
            -0.028375646099448204,
            -0.01444645319133997,
            0.010179510340094566,
            0.0023852407466620207,
            -0.007562284357845783,
            -0.027736321091651917,
            0.0012041694717481732,
            0.03169706091284752,
            0.033006701618433,
            -0.036093708127737045,
            -0.033258549869060516,
            -0.02993050590157509,
            0.014647225849330425,
            0.0198647640645504,
            -0.0030456949025392532,
            -0.019438855350017548,
            -0.014489552937448025,
            -0.00966464914381504,
            -0.023348689079284668,
            -0.0016947308322414756,
            0.007965164259076118,
            -0.0028636488132178783,
            -0.02414514496922493,
            0.036685869097709656,
            0.01765689067542553,
            -0.018530504778027534,
            -0.020274901762604713,
            0.010121609084308147,
            0.00503050722181797,
            0.0015335683710873127,
            -0.0056018829345703125,
            -0.017214201390743256,
            0.028857992962002754,
            -0.011352594010531902,
            0.009055030532181263,
            0.024069882929325104,
            -0.006712017115205526,
            -0.007724820636212826,
            0.011714735068380833,
            -0.014414863660931587,
            -0.02572057768702507,
            0.023130403831601143,
            -0.02570044808089733,
            0.0383644662797451,
            0.030225547030568123,
            0.031494125723838806,
            0.007978909648954868,
            0.011329548433423042,
            -0.00039176951395347714,
            -0.0167180635035038,
            -0.022905400022864342,
            0.03255615010857582,
            -0.005071713589131832,
            0.018240846693515778,
            -0.040099192410707474,
            -0.007062462158501148,
            0.01112869568169117,
            0.0346410758793354,
            -0.026369526982307434,
            0.009624877944588661,
            0.03286150097846985,
            -0.028094736859202385,
            -0.04937451705336571,
            0.018249202519655228,
            0.031840983778238297,
            0.007453853730112314,
            0.011095055378973484,
            -0.017603470012545586,
            0.011603052727878094,
            -0.0075646983459591866,
            0.01087341457605362,
            0.02945798449218273,
            -0.01709219254553318,
            0.011074649170041084,
            0.014622215181589127,
            -0.005629296414554119,
            -0.012015215121209621,
            0.007417534943670034,
            -0.0017326298402622342,
            -0.03698701411485672,
            -0.002012173645198345,
            0.0011138187255710363,
            0.013182969763875008,
            0.015506084077060223,
            0.02802574448287487,
            -0.014939148910343647,
            0.017854439094662666,
            -0.005429325625300407,
            -0.03651188313961029,
            -0.00442308047786355,
            0.013424628414213657,
            -0.006178488954901695,
            -0.02231922373175621,
            -0.041672512888908386,
            -0.033949024975299835,
            -0.10235114395618439,
            -0.006407401990145445,
            0.011081278324127197,
            0.05749395489692688,
            -0.024835478514432907,
            -0.038707002997398376,
            0.02514205314218998,
            -0.0069155097007751465,
            0.022811800241470337,
            -0.028816664591431618,
            0.02335982210934162,
            -0.03258168697357178,
            -0.019516943022608757,
            0.01636621169745922,
            0.002681948011741042,
            -0.0020523422863334417,
            -0.012828977778553963,
            0.032268233597278595,
            0.005923432298004627,
            -0.030378423631191254,
            -0.022346695885062218,
            -0.002356477314606309,
            -0.025414932519197464,
            0.0007979844813235104,
            -0.023861944675445557,
            -0.003523009829223156,
            -0.003360689850524068,
            0.016521796584129333,
            -0.02674173004925251,
            0.036458250135183334,
            -0.009949234314262867,
            0.0008590398938395083,
            0.013527174480259418,
            -0.004735937807708979,
            0.014091739431023598,
            -0.013579998165369034,
            0.02184530347585678,
            -0.07539059221744537,
            -0.021354855969548225,
            0.008841353468596935,
            0.0322716124355793,
            -0.006803709082305431,
            0.021760687232017517,
            0.003289563115686178,
            0.010540376417338848,
            -0.0005381785449571908,
            -0.007208989001810551,
            0.01983473263680935,
            0.0020339651964604855,
            0.02408476732671261,
            -0.005390259437263012,
            -0.008672535419464111,
            -0.0018781364196911454,
            -0.02167944423854351,
            -0.03680361434817314,
            -0.0014159854035824537,
            0.00995289534330368,
            0.006342101842164993,
            -0.014785111881792545,
            0.026112288236618042,
            -0.01290821097791195,
            0.011348657310009003,
            -0.02207793854176998,
            -0.01874668523669243,
            -0.02453136071562767,
            0.036981482058763504,
            0.019027821719646454,
            0.017040900886058807,
            -0.02502846159040928,
            0.00033373699989169836,
            0.020602598786354065,
            0.021782897412776947,
            -7.960803486639634e-05,
            0.02319493144750595,
            -0.008447723463177681,
            -0.027146754786372185,
            0.029303323477506638,
            0.0017041517421603203,
            -0.017047062516212463,
            -0.04802250862121582,
            -0.024143915623426437,
            -0.005857468117028475,
            -0.021023252978920937,
            -0.014580829069018364,
            -0.011808857321739197,
            0.023019662126898766,
            -0.002106011612340808,
            0.03020143136382103,
            0.02918553724884987,
            -0.023274829611182213,
            0.008212728425860405,
            8.541118586435914e-05,
            -0.0004841093032155186,
            -0.005913830827921629,
            0.013996650464832783,
            -0.017203912138938904,
            0.000993147143162787,
            -0.0013265321031212807,
            0.03716813772916794,
            -0.04260675236582756,
            0.005236747208982706,
            -0.025252051651477814,
            0.008478767238557339,
            0.029585765674710274,
            -0.021115975454449654,
            0.003012631554156542,
            -0.022929638624191284,
            -0.017726080492138863,
            -0.01092744804918766,
            -0.014527338556945324,
            0.013717818073928356,
            -0.0148690240457654,
            -0.02048680931329727,
            -0.026480423286557198,
            -0.013747276738286018,
            -0.019244765862822533,
            0.007906977087259293,
            0.03128093108534813,
            -0.001592680113390088,
            -0.01231048908084631,
            -0.020718304440379143,
            0.004015197977423668,
            0.006898743566125631,
            -0.004798524081707001,
            -0.022293346002697945,
            0.009353694505989552,
            0.0244276225566864,
            0.022240012884140015,
            0.011580712161958218,
            0.0040620556101202965,
            -0.0013941604411229491,
            0.017007766291499138,
            -0.00909965019673109,
            -0.04629947245121002,
            0.010215628892183304,
            0.014821243472397327,
            0.00557681592181325,
            0.01020057313144207,
            -0.016345471143722534,
            -0.017052004113793373,
            0.021628674119710922,
            0.0021707764826714993,
            -0.01722641848027706,
            -0.01971900463104248,
            0.016685903072357178,
            0.0078521016985178,
            -0.022169923409819603,
            0.013560199178755283,
            -0.023347867652773857,
            -0.029866976663470268,
            -0.04835406690835953,
            -0.002139564137905836,
            -0.03698832541704178,
            -0.038971129804849625,
            0.02202671952545643,
            0.02860867604613304,
            -0.010987202636897564,
            0.031886644661426544,
            0.03154728189110756,
            -0.04201795905828476,
            -0.006281476467847824,
            0.005161193199455738,
            0.03751793131232262,
            -0.021763067692518234,
            0.014604385010898113,
            -0.012942628003656864,
            -0.013771233148872852,
            -0.008837655186653137,
            -0.0985850989818573,
            0.0005786551046185195,
            -0.026696499437093735,
            -0.017720436677336693,
            -0.022215276956558228,
            0.00032704832847230136,
            -0.02396158128976822,
            -0.019135814160108566,
            0.014234543778002262,
            0.03309217840433121,
            0.02989291027188301,
            0.014043435454368591,
            0.0307889636605978,
            0.04473703354597092,
            -0.022518225014209747,
            -0.004955208860337734,
            -0.04011838883161545,
            0.024937719106674194,
            -0.006677636876702309,
            -0.0011953451903536916,
            -0.005357937887310982,
            0.007594185881316662,
            0.031298257410526276,
            0.043276842683553696,
            -0.012562677264213562,
            0.024592267349362373,
            -0.013556189835071564,
            -0.005384404212236404,
            -0.01728951372206211,
            -0.002063485560938716,
            -0.044793058186769485,
            0.010849141515791416,
            0.00867042038589716,
            -0.02599312737584114,
            -0.024266919121146202,
            0.016505001112818718,
            -0.02497921884059906,
            0.022907042875885963,
            0.025664277374744415,
            0.005370683968067169,
            -0.02046246826648712,
            0.05482420325279236,
            -0.027613649144768715,
            -0.002131731016561389,
            0.03875444456934929,
            -0.029684411361813545,
            -0.004127753898501396,
            -0.01682734116911888,
            -0.005227250512689352,
            -0.022803708910942078,
            -0.014920296147465706,
            -0.015449417755007744,
            0.02159968577325344,
            0.008283572271466255,
            0.013152464292943478,
            0.0009404117590747774,
            -0.01208709366619587,
            -0.018775006756186485,
            0.021773509681224823,
            -0.02002858929336071,
            -0.002448849845677614,
            0.009307720698416233,
            0.002345056738704443,
            -0.009937990456819534,
            -0.0031086590606719255,
            -0.023278599604964256,
            0.017716316506266594,
            0.004625748377293348,
            0.02254164218902588,
            0.008558850735425949,
            0.014015091583132744,
            0.04163302108645439,
            -0.02618192508816719,
            0.010517336428165436,
            -0.010941069573163986,
            0.014900273643434048,
            -0.04466274753212929,
            -0.0006703907856717706,
            -0.0018751356983557343,
            -0.012551097199320793,
            0.00199488690122962,
            -0.03301932290196419,
            -0.0024171480908989906,
            -0.009258282370865345,
            0.029497133567929268,
            0.007203893736004829,
            0.00737800681963563,
            -0.01192781887948513,
            -0.01971772313117981,
            -0.0393604077398777,
            0.025276601314544678,
            0.015199922025203705,
            -0.041681818664073944,
            0.035926833748817444,
            0.037104930728673935,
            0.010186729021370411,
            -0.012085547670722008,
            0.020689072087407112,
            -0.029888467863202095,
            0.0016857077134773135,
            0.025541745126247406,
            -0.018633371219038963,
            0.01981002651154995,
            0.02144123613834381,
            -0.019333239644765854,
            0.027722477912902832,
            0.00857562106102705,
            0.026203349232673645,
            0.01789902150630951,
            -0.027769217267632484,
            -0.025925137102603912,
            0.01610300876200199,
            0.027858132496476173,
            -0.040370095521211624,
            0.02533281221985817,
            0.015197444707155228,
            -0.0322527177631855,
            0.0060307178646326065,
            0.01002631988376379,
            -0.007896221242845058,
            -0.012021330185234547,
            0.02207193523645401,
            0.03546598181128502,
            0.008562333881855011,
            0.009016625583171844,
            0.010853449814021587,
            -0.015579168684780598,
            -0.0035241320729255676,
            0.008767994120717049,
            -0.004173159133642912,
            -0.009661298245191574,
            0.0008306043455377221,
            0.014630267396569252,
            -0.004427906591445208,
            0.03153132647275925,
            -0.049854081124067307,
            -0.0090615414083004,
            0.013108779676258564,
            -0.0310105811804533,
            0.035673025995492935,
            -0.0011077163508161902,
            -0.0001840699405875057,
            0.030042948201298714,
            -0.05649913102388382,
            -0.014460197649896145,
            -0.024573834612965584,
            0.004007245413959026,
            0.007917552255094051,
            0.013307762332260609,
            0.0024443739093840122,
            -0.007905879989266396,
            -0.01476261392235756,
            -0.013590564019978046,
            0.0012663699453696609,
            0.01724805310368538,
            0.014353461563587189,
            0.033682931214571,
            0.020443817600607872,
            -0.004406113177537918,
            -0.013812635093927383,
            0.014424856752157211,
            -0.004616511985659599,
            -0.008454881608486176,
            0.008678482845425606,
            0.013577992096543312,
            0.011627079918980598,
            -0.016229549422860146,
            0.003337881527841091,
            0.008062107488512993,
            -0.022277861833572388,
            -0.03990994393825531,
            -0.0810406357049942,
            0.015045110136270523,
            -0.009735883213579655,
            0.0013964702375233173,
            -0.01582000032067299,
            -0.014596154913306236,
            -0.02273871749639511,
            -0.009675855748355389,
            0.0033365460112690926,
            0.008966690860688686,
            -0.017965257167816162,
            -0.03237826004624367,
            -0.01998809166252613,
            0.026411443948745728,
            0.12279986590147018,
            0.03757912665605545,
            0.017652321606874466,
            0.002699747448787093,
            -0.006610618904232979,
            -0.015300998464226723,
            0.012992586940526962,
            0.003392975078895688,
            0.016611434519290924,
            0.0012485957704484463,
            0.013469669967889786,
            -0.001596146379597485,
            0.02818378433585167,
            0.019967524334788322,
            -0.041808973997831345,
            0.01036528218537569,
            0.012570437975227833,
            -0.01037383172661066,
            -0.07923953235149384,
            -0.03497365117073059,
            0.014529956504702568,
            -0.019428623840212822,
            -0.017931532114744186,
            -0.037825364619493484,
            0.017933519557118416,
            0.0017596418038010597,
            0.017286259680986404,
            -0.0035710076335817575
        ],
        [
            0.0014036772772669792,
            0.010115951299667358,
            0.017024099826812744,
            0.04392968863248825,
            0.001633891137316823,
            0.08155660331249237,
            -0.010769017040729523,
            0.017404356971383095,
            -0.014331751503050327,
            -0.012125804089009762,
            0.008936172351241112,
            0.02921193651854992,
            0.002882750006392598,
            -0.0024621582124382257,
            0.007718180771917105,
            0.025297069922089577,
            0.014065188355743885,
            0.02420388162136078,
            0.030056362971663475,
            -0.013963724486529827,
            0.031739044934511185,
            0.011559276841580868,
            0.02721153385937214,
            0.018811438232660294,
            -0.020914873108267784,
            0.01635214127600193,
            -0.004339398350566626,
            -0.05539068207144737,
            -0.020912962034344673,
            0.012770206667482853,
            -0.031470030546188354,
            -0.018437223508954048,
            0.03714596480131149,
            -0.008327917195856571,
            0.015213592909276485,
            0.027552340179681778,
            0.015199249610304832,
            0.017724189907312393,
            0.001494634780101478,
            0.03190579637885094,
            -0.009386235848069191,
            0.016154421493411064,
            -0.014224817045032978,
            0.03319660946726799,
            0.014159666374325752,
            0.019271070137619972,
            -0.011644631624221802,
            0.034532953053712845,
            -0.01094723679125309,
            -0.008900182321667671,
            -0.030851928517222404,
            -0.03477966785430908,
            0.016526978462934494,
            0.03323211148381233,
            -0.05377426743507385,
            -0.023038651794195175,
            -0.004729895852506161,
            0.005351482890546322,
            0.022305000573396683,
            -0.005426718387752771,
            -0.023416437208652496,
            -0.0037539431359618902,
            0.031489547342061996,
            0.01233820803463459,
            0.0017988757463172078,
            0.0081861587241292,
            -0.008959543891251087,
            -0.010747278109192848,
            -0.0018252929439768195,
            0.007666037883609533,
            -0.023485852405428886,
            -0.006349897012114525,
            -0.01981339417397976,
            0.008084205910563469,
            0.004473250359296799,
            -0.026559071615338326,
            0.030919501557946205,
            -0.05691729485988617,
            -0.021062184125185013,
            0.009290473535656929,
            0.013714860193431377,
            -0.007996909320354462,
            0.020100409165024757,
            -0.012156100012362003,
            0.006738043390214443,
            0.003971680998802185,
            -0.03187606483697891,
            0.02518453821539879,
            0.01270330511033535,
            0.030457640066742897,
            -0.002122408477589488,
            0.007814423181116581,
            -0.019632333889603615,
            -0.015563241206109524,
            -0.051806699484586716,
            0.032063230872154236,
            -3.647496487246826e-05,
            0.03177331015467644,
            -0.0608929768204689,
            -0.003362112445756793,
            -0.014569878578186035,
            0.01612701080739498,
            -0.040971312671899796,
            -0.0012439123820513487,
            0.009473331272602081,
            -0.023432454094290733,
            0.026175180450081825,
            0.0147396894171834,
            0.005415555089712143,
            -0.022280825302004814,
            -0.025273023173213005,
            0.04208625853061676,
            0.007304100319743156,
            -0.02855677343904972,
            -0.022275779396295547,
            0.006578072905540466,
            0.007303973659873009,
            0.008390208706259727,
            0.020903119817376137,
            0.022509951144456863,
            0.014661932364106178,
            -0.02913285419344902,
            0.10329638421535492,
            0.0010575540363788605,
            -0.03603675216436386,
            0.027391940355300903,
            -0.051435209810733795,
            -0.005514957942068577,
            -0.04063750430941582,
            -0.049970559775829315,
            0.028401831164956093,
            0.03633105382323265,
            0.011944223195314407,
            0.040145475417375565,
            0.01587064005434513,
            -0.024674147367477417,
            0.021097457036376,
            -0.033574365079402924,
            -0.007961450144648552,
            0.006661357823759317,
            -0.025644797831773758,
            -0.03787669166922569,
            -0.029617730528116226,
            -0.034630175679922104,
            -0.026652084663510323,
            -0.021419674158096313,
            0.021005675196647644,
            0.014052066020667553,
            -0.01681499369442463,
            -0.02504490688443184,
            -0.020936839282512665,
            -0.05085529759526253,
            -0.023874664679169655,
            -0.038984011858701706,
            -0.002444505924358964,
            0.010967069305479527,
            -0.011237925849854946,
            -0.027145663276314735,
            -0.009370806626975536,
            0.043824225664138794,
            0.004737468436360359,
            0.03106909617781639,
            -0.0037109770346432924,
            -0.0066306982189416885,
            -0.03497787564992905,
            0.03761642426252365,
            0.00866533350199461,
            0.03838198259472847,
            0.01494536828249693,
            0.0129344891756773,
            0.016374723985791206,
            0.00785802211612463,
            0.023318875581026077,
            0.01974010095000267,
            0.00660651596263051,
            -0.037899404764175415,
            -0.002796192653477192,
            -0.026787566021084785,
            0.01295956876128912,
            -0.0010428642854094505,
            -0.009486548602581024,
            -0.0004277121333871037,
            0.046540357172489166,
            -0.01365563366562128,
            -0.013942547142505646,
            0.0020303039345890284,
            0.0017414145404472947,
            0.038349006325006485,
            -0.012248714454472065,
            -0.011052307672798634,
            0.014263599179685116,
            0.024948779493570328,
            0.03524300456047058,
            0.024674197658896446,
            0.021433651447296143,
            -0.033559489995241165,
            -0.0004093205207027495,
            0.011896518990397453,
            -0.020137326791882515,
            -0.02833976410329342,
            -0.033536285161972046,
            0.032360874116420746,
            0.041872937232255936,
            0.021350009366869926,
            0.02085573971271515,
            -0.019574489444494247,
            -0.024542443454265594,
            -0.023343948647379875,
            0.002044182037934661,
            -0.02786419354379177,
            -0.016493333503603935,
            0.02410636469721794,
            -0.0038239185232669115,
            -0.02371950075030327,
            0.014257716946303844,
            0.026988118886947632,
            -0.015281913802027702,
            0.013853644952178001,
            -0.018508881330490112,
            0.018154680728912354,
            -0.02288092114031315,
            -0.03788622096180916,
            0.031196080148220062,
            -0.014578179456293583,
            0.0010037679458037019,
            0.07713069021701813,
            0.01688961312174797,
            0.01270384807139635,
            -0.008323430083692074,
            -0.007501678541302681,
            0.039160583168268204,
            -0.012989815324544907,
            0.013513637706637383,
            -0.01309118140488863,
            0.01176589634269476,
            -0.007597476709634066,
            -0.049590811133384705,
            -0.021429769694805145,
            -0.01712792180478573,
            -0.030934955924749374,
            0.02646339125931263,
            0.03192409873008728,
            -0.02189520001411438,
            -0.001482158899307251,
            0.005929797887802124,
            0.027216335758566856,
            -0.025691423565149307,
            0.011249990202486515,
            0.0315219946205616,
            -0.0006791697815060616,
            -0.015415131114423275,
            0.01259106956422329,
            0.051133424043655396,
            -0.02870393544435501,
            0.03019549697637558,
            -0.010834033600986004,
            -0.0021709809079766273,
            -0.001632551895454526,
            -0.02132927067577839,
            0.03041491098701954,
            0.020026637241244316,
            0.020464463159441948,
            0.0015569673851132393,
            -0.022864654660224915,
            -0.008401406928896904,
            -0.027246449142694473,
            0.02465255931019783,
            -0.01068614237010479,
            0.00278362981043756,
            -0.006115001160651445,
            -0.034020014107227325,
            -0.02846301905810833,
            0.03360775485634804,
            -0.0823199599981308,
            -0.06541271507740021,
            -0.017791995778679848,
            0.000774253043346107,
            0.003982139751315117,
            -0.010540966875851154,
            -0.022893507033586502,
            -0.00997792650014162,
            -0.04325580596923828,
            -0.029755238443613052,
            -0.021850431337952614,
            0.037177566438913345,
            -0.022611819207668304,
            0.019645171239972115,
            -0.0015643398510292172,
            -0.0016295700334012508,
            -0.036378782242536545,
            0.039317596703767776,
            -0.023598602041602135,
            -0.0001066058684955351,
            -0.004615158773958683,
            0.0003246742708142847,
            -0.021569255739450455,
            0.028641581535339355,
            0.01678287610411644,
            -0.005418641958385706,
            -0.049709733575582504,
            0.034040145576000214,
            -0.03166157379746437,
            0.014432649128139019,
            -0.01489463821053505,
            0.00842215120792389,
            -0.014279092662036419,
            -0.0047437530010938644,
            -0.016077712178230286,
            0.008458370342850685,
            0.012902654707431793,
            -0.059280041605234146,
            0.03170944005250931,
            -0.032631706446409225,
            0.013779981061816216,
            -0.026462005451321602,
            -0.03467191383242607,
            0.006623321212828159,
            0.00792486872524023,
            0.012943183071911335,
            0.007596364710479975,
            0.015865594148635864,
            0.06931149959564209,
            -0.033555787056684494,
            0.04390641301870346,
            0.03274526447057724,
            -0.01629382185637951,
            -0.0150032052770257,
            -0.01632312498986721,
            -0.03733005374670029,
            -0.003092827508226037,
            -0.04279801622033119,
            0.004682151135057211,
            -0.0006035907426849008,
            -0.052428096532821655,
            0.019791411235928535,
            -0.021113641560077667,
            0.018605202436447144,
            0.031147561967372894,
            0.021028142422437668,
            0.032720278948545456,
            0.0013336383271962404,
            0.01238106470555067,
            -0.023267949000000954,
            -0.008527470752596855,
            -0.01699640043079853,
            0.03146696090698242,
            -0.03303709253668785,
            -0.0036797733046114445,
            0.007593208458274603,
            0.024975210428237915,
            0.012513667345046997,
            0.01579989306628704,
            0.03342054784297943,
            0.023286936804652214,
            0.00703313946723938,
            -0.017636418342590332,
            -0.008666756562888622,
            -0.07302635908126831,
            -0.015874948352575302,
            -0.0006347561138682067,
            -0.004569917917251587,
            0.018890652805566788,
            0.019396884366869926,
            0.02486242540180683,
            0.015273925848305225,
            0.0027477832045406103,
            -0.02547362633049488,
            0.00037136697210371494,
            0.019249269738793373,
            0.005452566314488649,
            0.022356096655130386,
            -0.026220571249723434,
            -0.00758772250264883,
            0.02657824382185936,
            -0.005261935759335756,
            -0.02015027403831482,
            0.02519124560058117,
            -0.026303647086024284,
            -0.018975771963596344,
            -0.009343558922410011,
            0.059482790529727936,
            -0.007117539178580046,
            0.029260696843266487,
            0.03174214065074921,
            -0.012987738475203514,
            0.025927167385816574,
            -0.035203926265239716,
            0.006921818014234304,
            -0.022939365357160568,
            -0.0006114908028393984,
            -0.03307471424341202,
            0.014119644649326801,
            -0.015100367367267609,
            0.030776042491197586,
            0.012551489286124706,
            0.0006409187917597592,
            0.01886097528040409,
            0.038043875247240067,
            0.036022186279296875,
            0.004553347826004028,
            -0.004463773220777512,
            -0.048434097319841385,
            0.007374109234660864,
            0.0022461730986833572,
            0.032436393201351166,
            -0.03554665297269821,
            -0.00235448544844985,
            -0.005758285988122225,
            -0.04087868705391884,
            -0.010401220992207527,
            0.0030241934582591057,
            -0.01933962106704712,
            -0.008386231027543545,
            -0.025363784283399582,
            -0.009707157500088215,
            -0.007090481463819742,
            0.000834563747048378,
            0.0017638902645558119,
            0.04064581170678139,
            0.028887441381812096,
            -0.10725955665111542,
            -0.038758739829063416,
            0.05285704508423805,
            0.00960518978536129,
            -0.022614169865846634,
            -0.04197748377919197,
            -0.011864947155117989,
            -0.00040787685429677367,
            -0.00969006959348917,
            -0.03584175184369087,
            -0.005686998832970858,
            -0.01602485589683056,
            0.02796950563788414,
            -0.008831688202917576,
            0.006452986039221287,
            -0.037867721170186996,
            -0.01458909921348095,
            -0.02063257433474064,
            -0.026093734428286552,
            -0.010706371627748013,
            0.019209014251828194,
            0.0002292529388796538,
            0.021524101495742798,
            -0.01028353814035654,
            -0.014173717238008976,
            0.03626168519258499,
            -0.010913917794823647,
            0.054337404668331146,
            0.0239622313529253,
            -0.01168884988874197,
            0.0001303553144680336,
            -0.004470257554203272,
            -0.07249453663825989,
            0.03484705835580826,
            -0.04971890524029732,
            -0.026334822177886963,
            -0.007646138779819012,
            0.004540803376585245,
            -0.014188184402883053,
            0.0080482829362154,
            0.01793919876217842,
            0.020283905789256096,
            -0.04110796004533768,
            0.01964043453335762,
            -0.030731305480003357,
            0.011435396037995815,
            0.000926833541598171,
            -0.06931491196155548,
            0.03601161018013954,
            -0.0075419289059937,
            -0.02517172135412693,
            0.01980711705982685,
            -0.007487765047699213,
            0.02347748726606369,
            0.03632742166519165,
            -0.05096021294593811,
            -0.01097499206662178,
            -0.002286133822053671,
            -0.008017949759960175,
            0.04005967080593109,
            0.011899545788764954,
            0.0005027824663557112,
            0.04373977333307266,
            -0.012322821654379368,
            0.035894330590963364,
            0.012213376350700855,
            -0.02408786676824093,
            -0.02554411254823208,
            0.05128278583288193,
            -0.004451315850019455,
            -0.01728193648159504,
            -0.007941728457808495,
            0.012467363849282265,
            -0.016720768064260483,
            0.009078636765480042,
            0.029057204723358154,
            -0.019651077687740326,
            0.027084235101938248,
            -0.030444609001278877,
            -0.00962323509156704,
            -0.018742328509688377,
            -0.005741273518651724,
            0.017688531428575516,
            -0.0208908561617136,
            -0.012654602527618408,
            0.007362923584878445,
            -0.02083784155547619,
            0.02359473891556263,
            0.02021290361881256,
            0.022906115278601646,
            -0.020334405824542046,
            0.003179203486070037,
            -0.009207022376358509,
            -0.03916282579302788,
            0.013623762875795364,
            -0.038041964173316956,
            -0.004888106137514114,
            -0.02020765282213688,
            0.03827536478638649,
            0.016438229009509087,
            0.012126829475164413,
            0.01012513693422079,
            0.01367923803627491,
            0.01733282580971718,
            0.021642537787556648,
            -0.019582683220505714,
            0.045680612325668335,
            0.010768244042992592,
            0.03381192311644554,
            -0.014063555747270584,
            -0.0036734335590153933,
            0.0006087536457926035,
            0.03130730614066124,
            -0.017054889351129532,
            0.026597287505865097,
            0.016824500635266304,
            0.011218019761145115,
            0.0013955917675048113,
            -0.06585726141929626,
            -0.010878853499889374,
            -0.009649918414652348,
            -0.02971644513309002,
            -0.01642788201570511,
            0.008206461556255817,
            -0.0190555602312088,
            -0.05592307075858116,
            0.014497540891170502,
            0.04561333730816841,
            0.020451301708817482,
            0.013578599318861961,
            0.007776981219649315,
            -0.027979763224720955,
            -0.03401780501008034,
            -0.00027386582223698497,
            -0.025034375488758087,
            -0.03354824706912041,
            0.027092672884464264,
            -0.015722254291176796,
            0.01321731973439455,
            -0.02399846911430359,
            -0.03128008544445038,
            -0.003225654596462846,
            0.033167507499456406,
            0.04742962867021561,
            -0.0025456263683736324,
            -0.02835312858223915,
            0.01884789951145649,
            -0.010016356594860554,
            -0.022101478651165962,
            -0.02953195758163929,
            0.03623294085264206,
            -0.04131809249520302,
            -0.004981913138180971,
            0.015291906893253326,
            0.017872463911771774,
            -0.004091610200703144,
            -0.009914339520037174,
            -0.04391225427389145,
            -0.06543080508708954,
            -0.02420971170067787,
            0.009788785129785538,
            0.010821850039064884,
            0.05140471085906029,
            0.008308811113238335,
            -0.026215916499495506,
            0.02721123956143856,
            0.014272208325564861,
            0.009439085610210896,
            0.014182054437696934,
            0.002819355810061097,
            0.044821832329034805,
            0.009497023187577724,
            -0.030205169692635536,
            0.0010749341454356909,
            -0.010539280250668526,
            -0.027715880423784256,
            -0.06126202642917633,
            0.012244956567883492,
            -0.022044988349080086,
            -0.009305384010076523,
            0.020873870700597763,
            -0.0640830248594284,
            -0.021005181595683098,
            0.02002176269888878,
            -0.009167149662971497,
            0.011848211288452148,
            0.030008874833583832,
            0.0225253663957119,
            0.030218185856938362,
            0.011733596213161945,
            0.008309920318424702,
            0.0021930404473096132,
            0.03141588717699051,
            -0.02612254209816456,
            -0.008587938733398914,
            -0.02804548293352127,
            0.010904941707849503,
            0.012331650592386723,
            0.0297605711966753,
            0.0436706580221653,
            0.021147463470697403,
            -0.003321910509839654,
            -0.01052558422088623,
            -0.008037458173930645,
            -0.017120562493801117,
            0.05075645074248314,
            0.011770202778279781,
            -0.004135359078645706,
            -0.02781236357986927,
            0.031365878880023956,
            0.009297986514866352,
            0.0010209507308900356,
            0.00783696211874485,
            -0.004423604346811771,
            0.019156182184815407,
            0.02239852584898472,
            0.005189937073737383,
            -0.03570978343486786,
            0.011324945837259293,
            -0.0062740398570895195,
            0.010538909584283829,
            -0.008454442955553532,
            0.0029700775630772114,
            0.0011509137693792582,
            -0.016796277835965157,
            0.032111525535583496,
            0.013923273421823978,
            0.012234142050147057,
            0.015642810612916946,
            -0.016885600984096527,
            -0.00885049905627966,
            0.01485326886177063,
            0.018089579418301582,
            -0.009692532010376453,
            0.02108643762767315,
            -0.027219709008932114,
            -0.0005667951190844178,
            0.00542067363858223,
            -0.020581193268299103,
            -0.004030248150229454,
            -0.004150830674916506,
            -0.027876006439328194,
            0.00246804510243237,
            -0.021344928070902824,
            -0.011056927032768726,
            -0.008754104375839233,
            -0.05617574229836464,
            -0.005876664072275162,
            -0.018169522285461426,
            -0.001635933411307633,
            -0.004327866714447737,
            -0.014481565915048122,
            -0.011917652562260628,
            -0.0011328035034239292,
            0.020031951367855072,
            0.01421470195055008,
            0.00172477460000664,
            -0.00524358032271266,
            -0.008762432262301445,
            0.025236325338482857,
            0.01900377683341503,
            0.022163115441799164,
            0.0231274776160717,
            0.008090684190392494,
            -0.00932992808520794,
            0.010957730934023857,
            -0.0467904731631279,
            -0.03005330264568329,
            -0.00986417941749096,
            0.013267381116747856,
            0.014940276741981506,
            0.023290524259209633,
            -0.013192439451813698,
            0.018445584923028946,
            0.011690600775182247,
            0.05118827521800995,
            -0.01966107450425625,
            0.00398945203050971,
            0.01026037149131298,
            0.0421154610812664,
            0.033008452504873276,
            0.03547518700361252,
            -0.04326373338699341,
            -0.01241978444159031,
            0.028474709019064903,
            -0.014812174253165722,
            0.016441062092781067,
            -0.02343263104557991,
            -0.02388114109635353,
            -0.0462493933737278,
            -0.009478122927248478,
            -0.0066087194718420506,
            -0.009687845595180988,
            -0.012098737061023712,
            -0.04776102304458618,
            0.021272676065564156,
            -0.017092199996113777,
            0.022972041741013527,
            -0.01204728428274393,
            0.0037251750472933054,
            0.010616936720907688,
            -0.038539987057447433,
            0.02266116254031658,
            0.014436750672757626,
            0.006363772787153721,
            0.019147338345646858,
            0.03057710826396942,
            0.005497688893228769,
            -0.017294462770223618,
            -0.03511222451925278,
            -0.004645809065550566,
            0.03214366361498833,
            -0.01485070213675499,
            0.03472660481929779,
            -0.012659802101552486,
            -0.036708954721689224,
            -0.01430863793939352,
            0.027769779786467552,
            0.0219303946942091,
            -0.01281534880399704,
            -0.013456525281071663,
            -0.010306520387530327,
            -0.006503385026007891,
            -0.02535954862833023,
            0.001738935592584312,
            -0.030917976051568985,
            -0.011741584166884422,
            -0.016824765130877495,
            -0.012734419666230679,
            0.0008579714922234416,
            -0.012268113903701305,
            0.002189990133047104,
            -0.03987682983279228,
            -0.00980209931731224,
            0.014475800096988678,
            -0.028154762461781502,
            0.02239406853914261,
            -0.01892690174281597,
            0.035516854375600815,
            -0.07975997775793076,
            -0.030931740999221802,
            0.04845163971185684,
            0.03697053715586662,
            -0.01651865988969803,
            -0.013214842416346073,
            -0.007039982359856367,
            0.008613415993750095,
            0.018957573920488358,
            0.026987984776496887,
            0.00823560543358326,
            0.014268054626882076,
            -0.041103966534137726,
            -0.04354589432477951,
            0.05202900618314743,
            -0.0036905831657350063,
            0.01692797802388668,
            -0.008925549685955048,
            -0.012256672605872154,
            -0.022495077922940254,
            0.03215232118964195,
            0.06025521457195282,
            -0.02893848344683647,
            -0.0123888636007905,
            -0.011791698634624481,
            -0.011805453337728977,
            -0.05318216234445572,
            0.003292052773758769,
            0.04138295724987984,
            0.02117268368601799,
            0.0015430389903485775,
            0.021619558334350586,
            -0.02734529972076416,
            0.010723177343606949,
            0.01937839202582836,
            0.022651519626379013,
            -0.018143311142921448,
            0.018023010343313217,
            0.0029327264055609703,
            -0.02166140265762806,
            -0.040482599288225174,
            -0.029662473127245903,
            -0.004859435837715864,
            -0.013134201057255268,
            -0.01735491305589676,
            0.03307081386446953,
            -0.07997453957796097,
            0.01785355806350708,
            -0.007321115583181381,
            -0.022237692028284073,
            -0.07943647354841232,
            -0.023957543075084686,
            -0.005661149974912405,
            0.0007868228713050485,
            0.02623704820871353,
            -0.036467332392930984,
            0.014152365736663342,
            0.03373932093381882,
            0.023043178021907806,
            -0.000814027211163193,
            0.022203609347343445,
            -0.014051520265638828,
            -0.0017896550707519054,
            -0.029187295585870743,
            0.050331924110651016,
            0.03365826606750488,
            -0.02319459430873394,
            0.006975565105676651,
            -0.013362064957618713,
            -0.06334889680147171,
            0.01863393373787403,
            -0.038416385650634766,
            0.013823462650179863,
            0.02859976701438427,
            0.007934999652206898,
            -0.03962192311882973,
            -0.008329021744430065,
            -0.005431061144918203,
            -0.01630363240838051,
            0.022505423054099083,
            0.013430795632302761,
            0.0021668123081326485,
            0.005130492150783539,
            -0.030982622876763344,
            -0.0007882398786023259,
            -0.004689916502684355,
            -0.00771009735763073,
            0.020563390105962753,
            0.005302757490426302,
            -0.006388244219124317,
            -0.015501180663704872,
            0.010957580991089344,
            0.03287879377603531,
            -0.03712941333651543,
            0.01535364892333746,
            -0.01470926497131586,
            0.0016431733965873718,
            0.004895710851997137,
            -0.029822533950209618,
            0.006985077168792486,
            -0.011078447103500366,
            0.017387934029102325,
            -0.017181798815727234,
            0.013856631703674793,
            0.005522439256310463,
            -0.006322900298982859,
            0.011038210242986679,
            -0.02749033086001873,
            -0.023716509342193604,
            -0.01657194271683693,
            0.022238347679376602,
            -0.01110812183469534,
            -0.02257688343524933,
            -0.05076081305742264,
            0.0295303612947464,
            0.00688535301014781,
            -0.01740417629480362,
            -0.0034707775339484215,
            0.03218425065279007,
            -0.011986239813268185,
            0.007024166639894247,
            -0.0017842813394963741,
            0.03231308236718178,
            -0.015887225046753883,
            0.03934837877750397,
            -0.006236603017896414,
            -0.03676452115178108,
            0.03954644501209259,
            0.0602092370390892,
            -0.007185854949057102,
            0.025500763207674026,
            0.02481994777917862,
            0.017019370570778847,
            0.03127669543027878,
            -0.04273419827222824,
            0.05681066960096359,
            -3.656913395388983e-05,
            -0.010302659124135971,
            -0.047123413532972336,
            -0.0008355312165804207,
            0.03217058628797531,
            0.025552362203598022,
            0.0030132904648780823,
            -0.007553132250905037,
            0.0005870771710760891,
            0.012463298626244068,
            0.037413377314805984,
            0.014319786801934242,
            0.020160125568509102,
            -0.026950910687446594,
            0.035559237003326416,
            -0.012329530902206898,
            -0.006448634434491396,
            -0.01073116809129715,
            -0.045767687261104584,
            -0.012974084354937077,
            0.027481907978653908,
            -0.020081372931599617,
            -0.004462772514671087,
            0.00616150489076972,
            0.00010004932119045407,
            -0.011068105697631836,
            0.005297884810715914,
            -0.032203685492277145,
            -0.007753940299153328,
            0.055261705070734024,
            -0.002581698587164283,
            0.028945228084921837,
            0.02164275385439396,
            -0.01404130831360817,
            -0.017500948160886765,
            -0.03385869413614273,
            -0.002958807395771146,
            0.012261464260518551,
            0.008874491788446903,
            -0.02004306949675083,
            0.01224072277545929,
            0.003343714401125908,
            -0.021973881870508194,
            -0.018021851778030396,
            0.034194063395261765,
            0.0045404802076518536,
            -0.01580868288874626,
            -0.0020462768152356148,
            -0.0174922626465559,
            0.10334794968366623,
            -0.039837125688791275,
            -0.021798228845000267,
            -0.017525091767311096,
            0.004204307682812214,
            -1.0648174111338449e-06,
            0.0049444325268268585,
            -0.02398734726011753,
            0.01290370523929596,
            0.0125599829480052,
            0.014825250953435898,
            -0.007680528797209263,
            -0.03206850215792656,
            0.03304444998502731,
            0.014405641704797745,
            0.010119056329131126,
            0.008000495843589306,
            0.022073576226830482,
            -0.021566009148955345,
            -0.053390372544527054,
            -0.006049690302461386,
            0.0028248406015336514,
            0.017045313492417336,
            -0.01788136549293995,
            0.04934899881482124,
            -0.016401246190071106,
            0.0026696145068854094,
            0.024485008791089058,
            -0.006379421800374985,
            -0.00946506392210722,
            -0.00223692343570292,
            -0.011748366057872772,
            0.04828245937824249,
            -0.0011297271121293306,
            -0.03322521597146988,
            0.02822391502559185,
            0.027922607958316803,
            0.016543932259082794,
            0.022714553400874138,
            -0.021715058013796806,
            0.04788340628147125,
            0.012529362924396992,
            0.024339165538549423,
            0.025873389095067978,
            -0.012859117239713669,
            -0.01873137801885605,
            -0.03299267590045929,
            0.011617862619459629,
            0.001756645506247878,
            0.031718168407678604,
            -0.009866820648312569,
            0.04509974271059036,
            -0.03736191987991333,
            -0.01830548234283924,
            -0.03484301641583443,
            -0.01781785674393177,
            -0.00809948518872261,
            0.006455073598772287,
            -0.005660476163029671,
            0.00572893675416708,
            -0.024333368986845016,
            0.01956641487777233,
            0.0014157817931845784,
            -0.025422513484954834,
            -0.017161615192890167,
            -0.008933125995099545,
            -0.008354639634490013,
            0.021565651521086693,
            -0.031250886619091034,
            -0.018847735598683357,
            0.017212700098752975,
            0.030486999079585075,
            -0.01443826500326395,
            -0.018001321703195572,
            -0.01879938133060932,
            -0.021135102957487106,
            0.016095418483018875,
            -0.04775320738554001,
            0.0142454719170928,
            -0.03074531815946102
        ],
        [
            -0.022852178663015366,
            -0.014490030705928802,
            0.00048795039765536785,
            -0.08356344699859619,
            0.007232211995869875,
            -0.02791648916900158,
            0.01934001035988331,
            -0.0158169437199831,
            0.014349139295518398,
            -0.02449730783700943,
            0.0023305120412260294,
            0.014345073141157627,
            0.0250242967158556,
            0.024156376719474792,
            0.019678330048918724,
            -0.00866072066128254,
            -0.010861543007194996,
            -0.004333776887506247,
            -0.005468401126563549,
            0.03205861151218414,
            0.01178840734064579,
            -0.021441740915179253,
            0.018273593857884407,
            -0.015979178249835968,
            0.019079236313700676,
            -0.010974970646202564,
            0.04233812913298607,
            -0.06284041702747345,
            0.037577804177999496,
            -0.011280739679932594,
            0.01874217763543129,
            0.0019370750524103642,
            0.00817094650119543,
            -0.004526972305029631,
            0.026762444525957108,
            -0.04968883469700813,
            0.014926948584616184,
            0.00501724099740386,
            0.025410523638129234,
            0.026069797575473785,
            -0.046412933617830276,
            -0.08341479301452637,
            -0.00465196929872036,
            0.020465794950723648,
            0.014802522026002407,
            -0.006640654522925615,
            -0.02577400580048561,
            -0.035327523946762085,
            -0.015069879591464996,
            0.02705087512731552,
            -0.0014214374823495746,
            -0.033840276300907135,
            -0.058530714362859726,
            -0.005464038345962763,
            -0.03643692657351494,
            -0.05953396111726761,
            0.012726266868412495,
            -0.023691359907388687,
            0.043011169880628586,
            -0.0009423571173101664,
            -0.0436873733997345,
            -0.034469570964574814,
            -0.018762029707431793,
            -0.00035512959584593773,
            -0.028804203495383263,
            0.016700802370905876,
            0.03702431172132492,
            0.006958224810659885,
            0.019008545204997063,
            0.011126428842544556,
            -0.018750857561826706,
            -0.0034757708199322224,
            -0.01863241381943226,
            0.01902717724442482,
            -0.012996834702789783,
            -0.002173635410144925,
            -0.02004680223762989,
            -0.01774345152080059,
            0.06565842032432556,
            0.01794409193098545,
            0.025582998991012573,
            0.006075890734791756,
            -0.004642748273909092,
            -0.016511879861354828,
            0.013874231837689877,
            -0.009269274771213531,
            -0.03418299928307533,
            0.010236408561468124,
            -0.007883100770413876,
            0.013384729623794556,
            -0.03166944161057472,
            0.015253172256052494,
            0.001708456315100193,
            -0.09074489027261734,
            -0.058500006794929504,
            -0.003644767915830016,
            -0.005531716160476208,
            0.009891360998153687,
            0.05287744477391243,
            0.028350181877613068,
            0.00030958233401179314,
            0.010820199735462666,
            -0.013169108889997005,
            -0.01705780439078808,
            -0.008991570211946964,
            -0.014225266873836517,
            -0.03300032019615173,
            -0.042568549513816833,
            0.002375069074332714,
            0.0157204307615757,
            -0.012642543762922287,
            0.0073431008495390415,
            -0.03407221660017967,
            -0.020983925089240074,
            -0.06657282263040543,
            -0.01744278334081173,
            0.01154989656060934,
            -0.008759284391999245,
            0.016401436179876328,
            0.0012515810085460544,
            0.033203125,
            -0.003454654011875391,
            -0.023684006184339523,
            0.03631638363003731,
            0.01605275645852089,
            -0.018706701695919037,
            -0.021167540922760963,
            0.022342558950185776,
            0.005261626094579697,
            -0.008403729647397995,
            -0.010152914561331272,
            -0.0390915647149086,
            0.037255123257637024,
            -0.008017536252737045,
            0.027450479567050934,
            0.0024642646312713623,
            0.02351517416536808,
            0.022084465250372887,
            -0.0037540041375905275,
            -0.03365439921617508,
            -0.003907335922122002,
            0.00123960233759135,
            -0.022582080215215683,
            -0.021872583776712418,
            -0.03049691952764988,
            -0.044881995767354965,
            0.023366283625364304,
            -0.05724116042256355,
            -0.04391830787062645,
            -0.010725846514105797,
            0.013047941960394382,
            -0.020145338028669357,
            -0.009664340876042843,
            -0.026366541162133217,
            0.024847447872161865,
            0.017545051872730255,
            -0.11251317709684372,
            0.031416088342666626,
            0.005964359268546104,
            0.01568819023668766,
            0.015485393814742565,
            0.002050582552328706,
            -0.036774251610040665,
            0.029247350990772247,
            0.000909741036593914,
            0.008454101160168648,
            0.01512856688350439,
            -0.04378673806786537,
            -0.018173441290855408,
            -0.03312114626169205,
            -0.01930050738155842,
            0.028551243245601654,
            -0.012253888882696629,
            -0.023786162957549095,
            -0.01576543040573597,
            0.01716548763215542,
            -0.025537321344017982,
            -0.08573203533887863,
            -0.014313768595457077,
            0.02079962007701397,
            -0.003274099202826619,
            -0.00045175926061347127,
            -0.029759276658296585,
            -0.02426442876458168,
            0.026837585493922234,
            0.007774967234581709,
            0.00883815623819828,
            -0.004511801991611719,
            0.024989008903503418,
            -0.004635245073586702,
            0.0019758620765060186,
            0.03852500021457672,
            -0.0005591317312791944,
            0.03077791817486286,
            0.00033206131774932146,
            0.013455338776111603,
            0.00830784160643816,
            -0.004187169950455427,
            0.02791561558842659,
            -0.03471986949443817,
            -0.015523893758654594,
            -0.022660955786705017,
            0.007379491813480854,
            0.02524237148463726,
            0.021549003198742867,
            -0.01904374361038208,
            -0.009594831615686417,
            0.006500324234366417,
            0.0049530621618032455,
            -0.030686020851135254,
            0.0031332981307059526,
            0.023568276315927505,
            -0.028773004189133644,
            -0.059855297207832336,
            0.005533474963158369,
            -0.02509789727628231,
            -0.010507014580070972,
            -0.04053988307714462,
            -0.011828928254544735,
            -0.006884552538394928,
            0.015531751327216625,
            -0.024304984137415886,
            -0.05535168573260307,
            -0.010722207836806774,
            -0.02994818612933159,
            -0.03456598147749901,
            -0.00014514754002448171,
            -0.0026121586561203003,
            -0.013680796138942242,
            -0.02212158963084221,
            0.006755982991307974,
            0.006868641823530197,
            0.025245340541005135,
            -0.022563958540558815,
            -0.020355168730020523,
            0.018559465184807777,
            0.014337219297885895,
            -0.038910023868083954,
            -0.006514533888548613,
            0.012457397766411304,
            -0.01261929888278246,
            0.013749084435403347,
            0.024911217391490936,
            0.01754094660282135,
            -0.009944695979356766,
            -0.016697868704795837,
            -0.02545125223696232,
            0.012013389728963375,
            0.004545852541923523,
            -0.01850811205804348,
            -0.02624710090458393,
            -0.013033493421971798,
            0.056704696267843246,
            0.0024087121710181236,
            0.03700387477874756,
            -0.02806485816836357,
            -0.012666343711316586,
            -0.01037674956023693,
            -0.03708687052130699,
            -0.03973568230867386,
            0.012103461660444736,
            -0.02842520736157894,
            0.0033526821061968803,
            0.0002257718297187239,
            0.028763283044099808,
            -0.0028120577335357666,
            0.011401217430830002,
            0.01919420063495636,
            -0.024413788691163063,
            -0.05702101066708565,
            -0.014141686260700226,
            -0.044550348073244095,
            -0.0318031944334507,
            0.019480295479297638,
            -0.01855192705988884,
            0.017609093338251114,
            0.02208874188363552,
            -0.014936538413167,
            0.009503576904535294,
            -0.04606824740767479,
            0.028730958700180054,
            -0.027661597356200218,
            -0.010418662801384926,
            0.01842804253101349,
            0.023891344666481018,
            -0.020817913115024567,
            -0.03424679860472679,
            -0.04579288512468338,
            -0.02303728461265564,
            0.02533489465713501,
            0.02194385789334774,
            0.03355662524700165,
            -0.003985712304711342,
            -0.00016636046348139644,
            -0.02305571734905243,
            0.045878298580646515,
            -0.033239684998989105,
            -0.10933459550142288,
            -0.031463366001844406,
            -0.05852954089641571,
            -0.011218254454433918,
            -0.020999765023589134,
            -0.027683166787028313,
            -0.011499243788421154,
            0.0045819212682545185,
            0.013707070611417294,
            -0.0638563334941864,
            -0.02795260027050972,
            -0.028697041794657707,
            -0.007540910039097071,
            -0.027809778228402138,
            0.01779750920832157,
            -0.055063385516405106,
            -0.009662876836955547,
            0.025770096108317375,
            0.016261259093880653,
            0.0027797967195510864,
            -0.01994265615940094,
            -0.0018332904437556863,
            -0.023973209783434868,
            0.034141674637794495,
            0.008351275697350502,
            0.01021694764494896,
            -0.019925154745578766,
            0.02197299152612686,
            -0.03124203532934189,
            -0.020175369456410408,
            -0.022456955164670944,
            -0.04896637052297592,
            0.016349192708730698,
            -0.0679294541478157,
            -0.016592316329479218,
            0.020342111587524414,
            -0.048468299210071564,
            0.009663752280175686,
            -0.06992808729410172,
            0.028757089748978615,
            -0.02818399854004383,
            -0.01229245588183403,
            0.013036907650530338,
            -0.020285289734601974,
            0.00940675474703312,
            0.027180353179574013,
            0.009452121332287788,
            0.014718016609549522,
            -0.055797331035137177,
            0.044237781316041946,
            -0.010858802124857903,
            -0.015131249092519283,
            0.0027345893904566765,
            0.03515028953552246,
            -0.02710290253162384,
            -0.028304219245910645,
            0.008056890219449997,
            -0.023719264194369316,
            0.0010115262120962143,
            -0.008142707869410515,
            -0.013968591578304768,
            -0.0007280310965143144,
            -0.016754377633333206,
            0.01631925068795681,
            -0.008599148131906986,
            -0.02282588742673397,
            0.028521159663796425,
            -0.02766352705657482,
            0.00855132844299078,
            0.012881548143923283,
            -0.0030511871445924044,
            -0.0009416942484676838,
            -0.0034805939067155123,
            0.01299839373677969,
            -0.020544352009892464,
            -0.014576045796275139,
            -0.013421080075204372,
            -0.06993716210126877,
            -0.031124848872423172,
            0.034302257001399994,
            0.017697878181934357,
            -0.02949734590947628,
            -0.014322848990559578,
            -0.033993277698755264,
            -0.024759147316217422,
            0.029003607109189034,
            0.019147496670484543,
            0.020830530673265457,
            -0.023222526535391808,
            -0.007039126940071583,
            -0.01094911340624094,
            0.00523348618298769,
            -0.030080989003181458,
            0.009357426315546036,
            -0.014371090568602085,
            0.004214422311633825,
            0.0051327059045434,
            0.02710477076470852,
            -0.01742350496351719,
            -0.020704997703433037,
            -0.01735265925526619,
            -0.017247870564460754,
            -0.0048532611690461636,
            -0.013995492830872536,
            0.012354371137917042,
            -0.022523149847984314,
            0.0063630361109972,
            0.021567707881331444,
            -0.007929867133498192,
            -0.03635790944099426,
            -0.020874910056591034,
            -0.052417196333408356,
            0.004565604496747255,
            -0.016224436461925507,
            0.018496675416827202,
            -0.0565488375723362,
            -0.023009268566966057,
            0.015335075557231903,
            0.02220015972852707,
            -0.0029170194175094366,
            -0.00858005415648222,
            0.0023698220029473305,
            0.018060125410556793,
            -0.0739392340183258,
            -0.06332670897245407,
            -0.003859595162793994,
            0.015584816224873066,
            0.017295468598604202,
            0.05625108256936073,
            0.01520206406712532,
            -0.003955836407840252,
            -0.010499311611056328,
            0.0064814225770533085,
            0.020406750962138176,
            -0.026688948273658752,
            0.028399789705872536,
            -0.0008233319385908544,
            0.030205436050891876,
            0.020946359261870384,
            0.025959964841604233,
            -0.03814486786723137,
            0.004662131424993277,
            0.032779112458229065,
            -0.050574880093336105,
            0.017674023285508156,
            0.024990351870656013,
            0.009185820817947388,
            0.022876570001244545,
            -0.018932726234197617,
            -0.02652055211365223,
            0.006096032913774252,
            0.0030812870245426893,
            -0.005914236418902874,
            0.005039822775870562,
            -0.017190679907798767,
            -0.04736899212002754,
            -0.004591160919517279,
            0.04088879004120827,
            -0.010329634882509708,
            0.035907234996557236,
            0.030619770288467407,
            0.011899697594344616,
            0.017132915556430817,
            0.015071003697812557,
            0.012422655709087849,
            0.021891189739108086,
            -0.015924442559480667,
            0.012948920018970966,
            -0.013480746187269688,
            0.009446446783840656,
            -0.0072392793372273445,
            -0.03772799298167229,
            -0.02948608435690403,
            -0.007347248028963804,
            -0.02828405797481537,
            -0.02657681331038475,
            0.003927265759557486,
            -0.034387994557619095,
            0.028612250462174416,
            -0.011017289012670517,
            0.027176013216376305,
            0.004255152307450771,
            -0.03137780353426933,
            0.033435434103012085,
            0.006362111307680607,
            0.045900292694568634,
            -0.026320677250623703,
            0.0011179849971085787,
            -0.018104538321495056,
            0.02754858322441578,
            -0.0313166119158268,
            -0.0074164909310638905,
            -0.024172697216272354,
            -0.04839736968278885,
            -0.0032665024045854807,
            -0.020490562543272972,
            -0.002775203436613083,
            -0.00833713635802269,
            0.013161254115402699,
            0.01458565704524517,
            0.02865595929324627,
            -0.006929835304617882,
            0.03251701220870018,
            -0.013558809645473957,
            -0.008054195903241634,
            -0.007784830406308174,
            -0.014728578738868237,
            -0.003259018762037158,
            0.029238924384117126,
            0.003317139344289899,
            0.027403147891163826,
            0.03413795679807663,
            0.014227681793272495,
            0.0016871230909600854,
            0.007631747983396053,
            -0.027294637635350227,
            0.02786743827164173,
            0.013340235687792301,
            -0.02491283230483532,
            -0.01929524913430214,
            -0.07574901729822159,
            -0.029827281832695007,
            -0.0018069033976644278,
            0.028165338560938835,
            0.03001086786389351,
            -0.026767442002892494,
            0.014036941342055798,
            0.05748153105378151,
            0.06815341114997864,
            0.09453998506069183,
            0.026246879249811172,
            0.011686639860272408,
            0.034922901540994644,
            -0.028352396562695503,
            0.02062956430017948,
            -0.02119188942015171,
            0.03295855596661568,
            0.01575688272714615,
            -0.009490164928138256,
            0.018696283921599388,
            0.008701253682374954,
            -0.09103021770715714,
            0.031564440578222275,
            -0.0248088538646698,
            -0.004344762768596411,
            -0.026079727336764336,
            0.03679798170924187,
            0.024647118523716927,
            -0.021823350340127945,
            0.02507069706916809,
            0.022644324228167534,
            -0.023873193189501762,
            -0.005829786416143179,
            0.011856775730848312,
            0.0012426440371200442,
            -0.007942955009639263,
            0.018805520609021187,
            0.03864850103855133,
            -0.043676260858774185,
            0.00993409939110279,
            0.01675081066787243,
            -0.016248458996415138,
            -0.02049398422241211,
            0.020267978310585022,
            -0.004756226669996977,
            -0.0004704266902990639,
            -0.007254168391227722,
            0.00920642539858818,
            -0.007603749632835388,
            -0.01959375850856304,
            -0.02941317670047283,
            -0.009262670762836933,
            0.02425272949039936,
            -0.002913919510319829,
            -0.0213277880102396,
            0.02431272156536579,
            0.02255408652126789,
            -0.013529101386666298,
            -0.059157732874155045,
            -0.015289036557078362,
            -0.07545830309391022,
            -0.028492320328950882,
            -0.018611598759889603,
            -0.027203427627682686,
            0.005495831836014986,
            0.02470538578927517,
            -0.004350017756223679,
            -0.012908868491649628,
            0.003897609654814005,
            0.007526995614171028,
            0.00840736273676157,
            0.008641844615340233,
            -0.003982872236520052,
            -0.014550275169312954,
            -0.052507270127534866,
            -0.019499236717820168,
            -0.01090273167937994,
            -0.02216433547437191,
            0.0014651796082034707,
            -0.0267487820237875,
            0.009427636861801147,
            0.00030363997211679816,
            0.0723399892449379,
            0.011933142319321632,
            0.033927369862794876,
            -0.0020413307938724756,
            0.03740490600466728,
            0.022388797253370285,
            8.829408034216613e-05,
            -0.014431213960051537,
            -0.015078860335052013,
            0.02594292350113392,
            -0.018274949863553047,
            0.014164447784423828,
            -0.030387908220291138,
            0.026256104931235313,
            -0.0013879474718123674,
            0.007420152425765991,
            -0.033936046063899994,
            -0.009793344885110855,
            -0.008773110806941986,
            0.021106157451868057,
            0.05069425329566002,
            -0.08127104490995407,
            -0.003426909213885665,
            0.007658850401639938,
            -0.012341120280325413,
            -0.0334981232881546,
            0.030320467427372932,
            0.016480205580592155,
            0.013581407256424427,
            0.00031967429094947875,
            -0.004818232264369726,
            -0.04561242833733559,
            0.00029023195384070277,
            0.017056265845894814,
            0.011218308471143246,
            -0.016334230080246925,
            0.027132729068398476,
            -0.034073472023010254,
            -0.08162186294794083,
            0.004465675912797451,
            -0.000911551236640662,
            0.011721537448465824,
            0.048940498381853104,
            -9.496445272816345e-05,
            -0.03221388906240463,
            0.03454411402344704,
            0.035172730684280396,
            -0.017562491819262505,
            -0.010578684508800507,
            -0.010708543471992016,
            -0.007249518297612667,
            0.00903642363846302,
            0.028801340609788895,
            0.03207988664507866,
            -0.017943020910024643,
            0.015545735135674477,
            -0.01638643816113472,
            0.014734799042344093,
            -0.00799288135021925,
            -0.0022516597528010607,
            0.01578105054795742,
            0.03533732891082764,
            -0.004925872199237347,
            0.006939546670764685,
            0.02567681297659874,
            -0.0031331817153841257,
            -0.049110185354948044,
            -0.0006348335300572217,
            -0.01372129563242197,
            -0.02638167515397072,
            0.02382032945752144,
            -0.006212782114744186,
            -0.010380317457020283,
            -0.030442843213677406,
            -0.013323781080543995,
            0.025381918996572495,
            0.002630486385896802,
            -0.02377212792634964,
            0.007140041794627905,
            0.021386532112956047,
            0.002340308390557766,
            0.020505491644144058,
            0.03223785385489464,
            0.005275221075862646,
            -0.004087445326149464,
            -0.020605096593499184,
            -0.003693709848448634,
            -0.005488712806254625,
            0.0060553536750376225,
            0.007085422985255718,
            -0.0178950447589159,
            -0.04512191191315651,
            0.0021732861641794443,
            -0.008483674377202988,
            0.003009314648807049,
            -0.01570276729762554,
            0.028962021693587303,
            -0.03499026596546173,
            0.003440664615482092,
            -0.032405704259872437,
            0.027203897014260292,
            -0.03103237971663475,
            0.02615717425942421,
            -0.003601529635488987,
            -0.0011818495113402605,
            0.008921454660594463,
            0.025809338316321373,
            0.017244720831513405,
            0.018552782014012337,
            -0.018621526658535004,
            -0.02392166666686535,
            -0.008832311257719994,
            -0.04840559512376785,
            -0.011521339416503906,
            -0.014269337989389896,
            -0.0225258469581604,
            -0.018898166716098785,
            0.04214101657271385,
            -0.012308993376791477,
            -0.018658721819519997,
            0.017015086486935616,
            0.03420240432024002,
            -0.001711389166302979,
            0.025963664054870605,
            -0.007536192424595356,
            0.018487436696887016,
            0.0023714439012110233,
            -0.01248914748430252,
            0.005425258073955774,
            0.005500627215951681,
            -0.022617286071181297,
            0.007630476728081703,
            -0.02986777015030384,
            0.004577863961458206,
            0.004228170495480299,
            0.019998734816908836,
            0.004702280275523663,
            0.018591510131955147,
            0.017819184809923172,
            0.005283887963742018,
            -0.02724476158618927,
            -0.0009880984434857965,
            0.020934928208589554,
            -0.0027505119796842337,
            -0.059815388172864914,
            0.00431312620639801,
            0.04635078087449074,
            0.027432162314653397,
            0.010454115457832813,
            0.010726392269134521,
            -0.002509679412469268,
            -0.02724423073232174,
            -0.003967751283198595,
            0.007497753947973251,
            -0.051626045256853104,
            -0.01732429675757885,
            -0.039910878986120224,
            0.018325014039874077,
            0.06284758448600769,
            -0.011972028762102127,
            -0.021104520186781883,
            -0.017886683344841003,
            0.03047090768814087,
            -0.0001026328100124374,
            0.013467784970998764,
            -0.019724546000361443,
            -0.010911219753324986,
            -0.04129161685705185,
            0.001085739815607667,
            -0.00028926876257173717,
            0.028073729947209358,
            -0.035136524587869644,
            0.024057894945144653,
            0.005229408387094736,
            -0.061454929411411285,
            0.000521212350577116,
            -0.04031858220696449,
            -0.033073458820581436,
            0.0035422539804130793,
            -0.008891113102436066,
            -0.004064531531184912,
            0.03231998160481453,
            0.0164587814360857,
            -0.004811807069927454,
            -0.007074249908328056,
            0.020444992929697037,
            -0.03735927864909172,
            -0.028081266209483147,
            -0.019862229004502296,
            -0.0010137386852875352,
            -0.010801858268678188,
            0.03223784640431404,
            0.01402202993631363,
            -0.01777239702641964,
            -0.014660702086985111,
            0.020861158147454262,
            -0.03271469473838806,
            -0.08266182243824005,
            -0.027394570410251617,
            -0.04397565498948097,
            0.010762945748865604,
            0.026132112368941307,
            0.01654442586004734,
            0.010472890920937061,
            0.013471522368490696,
            0.01705940067768097,
            -0.02012135274708271,
            0.001734076882712543,
            -0.0018994061974808574,
            -0.020475614815950394,
            -0.026423316448926926,
            -0.03347737342119217,
            -0.03533878177404404,
            0.0063713290728628635,
            0.002102585742250085,
            0.019696198403835297,
            0.028980066999793053,
            -0.0034334450028836727,
            0.0010700046550482512,
            -0.009100262075662613,
            0.014447184279561043,
            -0.06398613005876541,
            -0.0036143341567367315,
            -0.020267242565751076,
            -0.009522542357444763,
            -0.034579385071992874,
            0.012118740938603878,
            0.006924841552972794,
            -0.025554411113262177,
            0.006784668192267418,
            -0.03130562976002693,
            -0.040727075189352036,
            -0.023060208186507225,
            -0.01973399892449379,
            -0.020757200196385384,
            -0.08178546279668808,
            0.02376522682607174,
            -0.013325774110853672,
            -0.0172735545784235,
            -0.02689437009394169,
            -0.02811911702156067,
            0.0334860235452652,
            0.017050044611096382,
            -0.02561020664870739,
            -0.040311142802238464,
            0.0003870060609187931,
            0.004621350672096014,
            -0.012689990922808647,
            0.0066369036212563515,
            -0.007141158916056156,
            -0.02644271031022072,
            0.01817210204899311,
            -0.01311197504401207,
            -0.014221863821148872,
            -0.006292617879807949,
            -0.004939105361700058,
            0.04237379506230354,
            -0.021338021382689476,
            -0.008853687904775143,
            0.034108225256204605,
            0.020996887236833572,
            0.008055362850427628,
            -0.012661746703088284,
            -0.04949500784277916,
            0.02168828621506691,
            0.00912288948893547,
            -0.02685179002583027,
            0.013393084518611431,
            -0.06442570686340332,
            -0.004682981874793768,
            -0.03533053770661354,
            -0.015609761700034142,
            -0.018158549442887306,
            -0.04545295983552933,
            -0.00047016076860018075,
            -0.02117544412612915,
            0.007439870852977037,
            0.006677236407995224,
            -0.01820218749344349,
            0.020464733242988586,
            0.011538625694811344,
            -0.04287346452474594,
            -0.05028688907623291,
            -0.001038887770846486,
            0.01670617051422596,
            0.025921300053596497,
            -0.008456994779407978,
            0.00883871503174305,
            -0.028238017112016678,
            -0.002591982251033187,
            -0.016879938542842865,
            -0.01820322312414646,
            -0.0004650884948205203,
            0.018676090985536575,
            0.030247827991843224,
            0.046002890914678574,
            0.019666455686092377,
            0.017329491674900055,
            0.037187907844781876,
            0.0007081059738993645,
            -0.01707492209970951,
            -0.018567511811852455,
            -0.022302713245153427,
            -0.017928777262568474,
            0.0037419372238218784,
            0.009735812433063984,
            -0.06445614993572235,
            0.005830214824527502,
            0.010635236278176308,
            -0.024976661428809166,
            0.04087691009044647,
            0.03528919443488121,
            0.029754873365163803,
            -0.023537537083029747,
            -0.032577820122241974,
            -0.01086918544024229,
            -0.021245965734124184,
            -0.024872712790966034,
            -0.010230077430605888,
            -0.03166570886969566,
            -0.004886564798653126,
            -0.018524467945098877,
            -0.0354343019425869,
            -0.04703867435455322,
            0.021061314269900322,
            0.07219552993774414,
            -0.04493087902665138,
            -0.0199939776211977,
            -0.014658934436738491,
            -0.012905780225992203,
            0.021935904398560524,
            -0.012635448947548866,
            -0.01655074581503868,
            -0.008333848789334297,
            0.028691226616501808,
            -0.006673386320471764,
            0.023409606888890266,
            0.033025048673152924,
            0.016636021435260773,
            0.01272679679095745,
            0.037591878324747086,
            0.0258946493268013,
            0.0031847592908889055,
            -0.05622895434498787,
            -0.015406280755996704,
            0.015010801143944263,
            -0.029446467757225037,
            0.03210639953613281,
            -0.03430963680148125,
            0.004723018035292625,
            0.021881723776459694,
            0.007400218863040209,
            0.023492474108934402,
            -0.03583647310733795,
            0.03365879878401756,
            -0.01841009594500065,
            0.031485918909311295,
            0.022405404597520828,
            -0.009132909588515759,
            -0.014421400614082813,
            0.02532012388110161,
            -0.008241157047450542,
            -0.0074234395287930965,
            -0.03413693979382515,
            -0.04565919563174248,
            -0.02147899568080902,
            0.032400306314229965,
            0.014595589600503445,
            -0.014324115589261055,
            -0.019190357998013496,
            -0.04121364280581474,
            -0.015161576680839062,
            0.0017726686783134937,
            -0.007846269756555557,
            -0.03406000882387161,
            0.026143252849578857,
            0.0015640855999663472,
            -0.011292637325823307,
            0.028005464002490044,
            -0.033558979630470276,
            0.018384946510195732,
            -0.048041701316833496,
            -0.01731855794787407,
            0.048704180866479874,
            0.024918779730796814,
            0.025548435747623444,
            0.017150023952126503,
            0.0008307874668389559,
            0.016123639419674873,
            0.023562965914607048,
            -0.012259638868272305,
            -0.014035937376320362,
            -0.01970045082271099,
            -0.05116541311144829,
            0.0078102289699018,
            -0.01826576516032219,
            0.01700439676642418,
            0.07224099338054657,
            -0.012619675137102604,
            -0.060262542217969894,
            0.0006966717774048448,
            -0.008922924287617207,
            -0.056012917309999466,
            0.019803732633590698,
            -0.01101370807737112,
            -0.011254975572228432,
            -0.0120819928124547,
            -0.004416610114276409,
            -0.03446564823389053,
            -0.0029969345778226852,
            0.01921548880636692,
            -0.0021143131889402866,
            -0.006572635844349861,
            -0.02787046507000923,
            0.01858472265303135,
            0.027517979964613914,
            0.013234930112957954,
            -0.007188017480075359,
            -0.00436702324077487,
            -0.003109931480139494,
            -0.004805160686373711,
            -0.04576888307929039,
            0.02290404587984085,
            -0.047660015523433685,
            0.03068261221051216
        ],
        [
            -0.009267942048609257,
            -0.0597873218357563,
            -0.021652041003108025,
            0.020567530766129494,
            0.015337805263698101,
            -0.00444550858810544,
            -0.000570020405575633,
            0.006859880872070789,
            -0.01150888204574585,
            -0.05368269979953766,
            -0.003636881709098816,
            -0.043205760419368744,
            -0.01028390135616064,
            0.02513975463807583,
            -0.009812936186790466,
            -0.04336307942867279,
            0.04556429386138916,
            0.033387716859579086,
            -0.09071533381938934,
            -0.024149395525455475,
            0.01738630048930645,
            0.033237770199775696,
            0.029780970886349678,
            0.011486395262181759,
            -0.05141608044505119,
            -0.04916877672076225,
            -0.03224676102399826,
            -0.06151166558265686,
            0.019924426451325417,
            -0.04646836966276169,
            0.03313102573156357,
            0.0342722162604332,
            -0.0604555606842041,
            0.006193002685904503,
            0.018795039504766464,
            0.01448238454759121,
            -0.036646194756031036,
            0.03600776940584183,
            0.010191448032855988,
            -0.008416852913796902,
            0.026503993198275566,
            0.006431840360164642,
            0.009932287037372589,
            -0.03370261192321777,
            -0.0032354018185287714,
            -0.019154179841279984,
            -0.04456653445959091,
            0.03004402108490467,
            -0.0281973984092474,
            -0.0491880401968956,
            -0.02691846527159214,
            -0.03573886305093765,
            -0.04765751212835312,
            0.04073125496506691,
            0.005409980658441782,
            -0.01509352121502161,
            0.008354602381587029,
            -0.0024344322737306356,
            0.02373855747282505,
            -0.051966678351163864,
            0.008280477486550808,
            0.006581557914614677,
            -0.028297822922468185,
            0.021313194185495377,
            -0.02762509137392044,
            -0.009076373651623726,
            -0.07519706338644028,
            -0.016690831631422043,
            -0.03313393518328667,
            -0.00997890718281269,
            -0.021213121712207794,
            -0.023444319143891335,
            0.018244685605168343,
            -0.025541244074702263,
            0.021508773788809776,
            0.05428186431527138,
            -0.030235743150115013,
            -0.027611233294010162,
            -0.012394638732075691,
            -0.016510464251041412,
            -0.025848092511296272,
            -0.03904268890619278,
            0.0074292016215622425,
            -0.04909105598926544,
            -0.011216611601412296,
            -0.056086037307977676,
            -0.013961796648800373,
            -0.02225296013057232,
            0.005985254421830177,
            -0.03781803324818611,
            0.030728530138731003,
            0.01378706656396389,
            -0.031157823279500008,
            -0.02345004677772522,
            0.0030098247807472944,
            -0.015127540566027164,
            0.04838890582323074,
            0.0016633359482511878,
            -0.04167020693421364,
            -0.023598041385412216,
            0.003906707279384136,
            0.011491919867694378,
            -0.05778815969824791,
            -0.0037678577937185764,
            0.010774480178952217,
            -0.020694172009825706,
            0.00024308271531481296,
            0.02777893655002117,
            -0.02196057140827179,
            0.014444628730416298,
            0.013908573426306248,
            -0.02220826968550682,
            -0.02030528523027897,
            0.04177960380911827,
            0.05569368973374367,
            0.0013568090507760644,
            0.020450381562113762,
            -0.01913534663617611,
            -0.014761914499104023,
            -0.019756585359573364,
            -0.07022908329963684,
            -0.023128554224967957,
            0.06641113013029099,
            -0.015617498196661472,
            0.016178952530026436,
            0.017365174368023872,
            0.03489140793681145,
            0.009687192738056183,
            0.02642838843166828,
            -0.015009238384664059,
            0.031235642731189728,
            -0.0038772241678088903,
            0.06999552994966507,
            0.008552415296435356,
            -0.006664779502898455,
            -0.0050277565605938435,
            -0.002119377488270402,
            0.027884449809789658,
            -0.04792109131813049,
            -0.03459211066365242,
            0.013154092244803905,
            -0.048562172800302505,
            0.018711091950535774,
            -0.007019551005214453,
            -0.010146783664822578,
            -0.023358790203928947,
            0.03231530636548996,
            0.0001572669279994443,
            -0.022131189703941345,
            0.016421787440776825,
            0.010421158745884895,
            0.02656397596001625,
            0.0006291017052717507,
            0.025247374549508095,
            -0.008634138852357864,
            0.004397287033498287,
            0.015946803614497185,
            -0.0014881916576996446,
            -0.06640128791332245,
            0.07977181673049927,
            0.008457690477371216,
            -0.008497396484017372,
            0.0045224884524941444,
            -0.031096089631319046,
            0.047592755407094955,
            0.060619644820690155,
            0.0005423597758635879,
            0.02787706069648266,
            -0.026565445587038994,
            -0.03240051120519638,
            0.009890960529446602,
            -0.04186190292239189,
            0.03926946595311165,
            -0.05747523158788681,
            -0.036167290061712265,
            0.016699662432074547,
            -0.009876611642539501,
            -0.015480616129934788,
            0.032689403742551804,
            0.043243128806352615,
            -0.01958080753684044,
            0.0005488359602168202,
            0.06637965887784958,
            -0.0006265410920605063,
            0.020373167470097542,
            0.02095741406083107,
            0.003481390420347452,
            -0.0012196741299703717,
            -0.08921542018651962,
            0.029316695407032967,
            0.01573009602725506,
            -0.004381589591503143,
            -0.02429923787713051,
            0.01756851002573967,
            -0.028603991493582726,
            -0.002419297117739916,
            -0.0017124891746789217,
            -0.012616065330803394,
            -0.015165264718234539,
            -0.08727835863828659,
            -0.053054891526699066,
            0.018304595723748207,
            0.016381269320845604,
            0.010044575668871403,
            -0.05101928859949112,
            0.03595762327313423,
            0.06012102961540222,
            -0.01717272773385048,
            0.02564951404929161,
            -0.008709069341421127,
            -0.04061778634786606,
            0.025940297171473503,
            -0.014191287569701672,
            -0.01683797687292099,
            0.0477304682135582,
            -0.004585283808410168,
            -0.03896592557430267,
            0.013995092362165451,
            0.031990669667720795,
            0.03443031385540962,
            0.009226773865520954,
            -0.0717383623123169,
            0.0021935408003628254,
            -0.02763214148581028,
            0.012566081248223782,
            -0.03878899663686752,
            -0.022282784804701805,
            0.02206503041088581,
            -0.018466854467988014,
            0.007363507524132729,
            0.01987646147608757,
            0.00016587316349614412,
            -0.07380174100399017,
            0.005267214495688677,
            -0.03677678480744362,
            -0.04514124244451523,
            0.02737647481262684,
            0.009183441288769245,
            0.026824239641427994,
            -0.053910404443740845,
            -0.01817726157605648,
            0.012360898777842522,
            0.01974811591207981,
            -0.00939879845827818,
            -0.044042911380529404,
            -0.020372800529003143,
            0.03502018749713898,
            -0.0008844099938869476,
            -0.02292594686150551,
            0.006776946596801281,
            -0.06170898303389549,
            0.03130016103386879,
            -0.005990631878376007,
            0.03386564552783966,
            -0.027048954740166664,
            -0.0349537692964077,
            -0.0070405988954007626,
            -0.020795421674847603,
            0.0045219482854008675,
            -0.0533955954015255,
            0.04911872372031212,
            0.04057762771844864,
            0.053355131298303604,
            0.0224148016422987,
            -0.06287553906440735,
            0.020224757492542267,
            -0.03200152516365051,
            0.0061505939811468124,
            0.04697175323963165,
            -0.00557834655046463,
            0.012339343316853046,
            0.011520975269377232,
            0.0010923704830929637,
            -0.01743331551551819,
            -0.027775485068559647,
            0.030908577144145966,
            -0.0019795550033450127,
            -0.01671990193426609,
            0.02481449767947197,
            0.02072066441178322,
            0.0233276579529047,
            -0.04259093105792999,
            -0.02538667991757393,
            0.0027035484090447426,
            0.0306926928460598,
            0.028622759506106377,
            0.016238966956734657,
            0.005241004284471273,
            0.023647010326385498,
            -0.025857048109173775,
            -0.01739366352558136,
            -0.030491704121232033,
            -0.0031086562667042017,
            0.04316936060786247,
            0.05820690095424652,
            0.019573358818888664,
            0.005804426036775112,
            -0.036430563777685165,
            0.02019602805376053,
            0.020285122096538544,
            -0.006484982091933489,
            -0.010831876657903194,
            -0.005195092875510454,
            0.004349089227616787,
            -0.025342531502246857,
            0.016218652948737144,
            0.008275574073195457,
            -0.03183625638484955,
            0.010308211669325829,
            -0.05115935206413269,
            -0.025907831266522408,
            -0.03861331194639206,
            -0.02041589841246605,
            0.003434300422668457,
            -0.004466414917260408,
            0.04657250642776489,
            0.02010424993932247,
            0.026578031480312347,
            0.028423180803656578,
            -0.0321839414536953,
            -0.014938334934413433,
            -0.03700031712651253,
            -0.0030729537829756737,
            0.03148246929049492,
            -0.03254639729857445,
            0.047017984092235565,
            0.07842530310153961,
            -0.0008601736626587808,
            0.017150256782770157,
            0.026220502331852913,
            -0.002031907206401229,
            -0.004824261646717787,
            0.03534821420907974,
            -0.010874456726014614,
            -0.005520861130207777,
            0.06010987237095833,
            0.021257948130369186,
            -0.0811774954199791,
            0.02382289618253708,
            -0.03475075215101242,
            -0.007299077697098255,
            -0.08985351771116257,
            -0.01753317192196846,
            -0.03421537205576897,
            0.002665040548890829,
            0.05909905210137367,
            -0.020362310111522675,
            0.001499416888691485,
            0.021837454289197922,
            -0.0261191725730896,
            -0.03981908783316612,
            0.014694234356284142,
            -0.013383859768509865,
            -0.0036217248998582363,
            0.013607002794742584,
            -0.01977357640862465,
            -0.01814216934144497,
            0.005698130466043949,
            -0.008426474407315254,
            -0.04364759102463722,
            0.0021529910154640675,
            -0.06480911374092102,
            0.020819474011659622,
            -0.005357468966394663,
            0.03427729383111,
            -0.005173956509679556,
            -0.005989690776914358,
            0.007751195225864649,
            -0.04065105319023132,
            -0.013483871705830097,
            0.031174812465906143,
            0.0017088600434362888,
            -0.042613379657268524,
            0.02381000481545925,
            -0.03133484348654747,
            0.041704390197992325,
            -0.054230719804763794,
            0.03493208438158035,
            0.009896948002278805,
            0.010157972574234009,
            0.05298100784420967,
            -0.004923311527818441,
            -0.034222885966300964,
            -0.029875734820961952,
            0.01935003139078617,
            0.00586401904001832,
            -0.0032199847046285868,
            -0.008224898017942905,
            -0.009996063075959682,
            0.02729683369398117,
            -0.016768284142017365,
            -0.04341606795787811,
            -0.0029206557665020227,
            -0.01593787595629692,
            0.01393965631723404,
            -0.025056196376681328,
            -0.030588241294026375,
            -0.04026452451944351,
            -0.01977338083088398,
            -0.029923737049102783,
            0.02195058763027191,
            0.06485144048929214,
            -0.030052097514271736,
            -0.006286115851253271,
            -0.03930582106113434,
            -0.0026487421710044146,
            0.0016082023503258824,
            0.06760144978761673,
            -0.03153768926858902,
            0.01018480770289898,
            0.042124927043914795,
            0.04030516371130943,
            0.002403821563348174,
            -0.006690974347293377,
            0.017853345721960068,
            -0.013733018189668655,
            -0.07234843820333481,
            -0.02104487456381321,
            0.03841470927000046,
            -0.02407374419271946,
            -0.0006947347428649664,
            -0.0025777912233024836,
            -0.007824487052857876,
            -0.017669562250375748,
            0.10222919285297394,
            0.08427366614341736,
            0.028594279661774635,
            -0.013485157862305641,
            -0.0035445080138742924,
            0.01757914572954178,
            0.015131930820643902,
            0.004823178052902222,
            -0.015076841227710247,
            0.09094758331775665,
            -0.011109543964266777,
            0.05952244624495506,
            0.020899547263979912,
            -0.00747135141864419,
            0.02630722150206566,
            0.010207723826169968,
            0.0020860969088971615,
            0.009334770031273365,
            -0.05059775710105896,
            0.018699077889323235,
            0.020776944234967232,
            0.005758109502494335,
            0.003042333060875535,
            0.024868182837963104,
            -0.015894008800387383,
            -0.009469950571656227,
            -0.012288943864405155,
            -0.022822244092822075,
            0.06842023134231567,
            0.003110160119831562,
            -0.01983761414885521,
            -0.040077079087495804,
            0.006966486107558012,
            -0.041837502270936966,
            -0.024733619764447212,
            0.04258847236633301,
            0.005808117799460888,
            0.021583257243037224,
            0.020695406943559647,
            0.0015482741873711348,
            0.004003248643130064,
            -0.02008688822388649,
            0.012485918588936329,
            -0.07959849387407303,
            0.021777652204036713,
            0.0006352293421514332,
            0.014690742827951908,
            0.019069364294409752,
            0.007204313296824694,
            -0.07208007574081421,
            -0.009350514970719814,
            0.016292978078126907,
            0.03235476091504097,
            0.020763492211699486,
            -0.020761363208293915,
            0.04194040223956108,
            0.03240104764699936,
            -0.009614735841751099,
            -0.012089473195374012,
            -0.02254812978208065,
            -0.03925982862710953,
            0.022164536640048027,
            -0.0009560479666106403,
            -0.026478812098503113,
            -0.04254700616002083,
            -0.014236784540116787,
            0.002152959583327174,
            0.0078608812764287,
            -0.029648534953594208,
            0.02546601928770542,
            -0.020261935889720917,
            0.035996440798044205,
            0.02950134687125683,
            0.03225976973772049,
            -0.00865144096314907,
            0.029100513085722923,
            0.007100923918187618,
            -0.05084598809480667,
            0.030214840546250343,
            0.0014148687478154898,
            -0.026705507189035416,
            -0.03105572611093521,
            0.0301792211830616,
            0.016183165833353996,
            -0.03696662560105324,
            -0.01207907497882843,
            -0.011110884137451649,
            0.030931927263736725,
            0.006936077494174242,
            -0.0003379646223038435,
            0.028699956834316254,
            -0.03808618709445,
            -0.008412727154791355,
            -0.019675362855196,
            0.03318936005234718,
            0.027211753651499748,
            -0.005005849990993738,
            -0.029195833951234818,
            -0.032108478248119354,
            -0.0009100321913138032,
            0.014171866700053215,
            0.025091370567679405,
            0.008089526556432247,
            0.0073282369412481785,
            -0.057332154363393784,
            0.036644235253334045,
            -0.0055887955240905285,
            0.021029090508818626,
            -0.012408002279698849,
            0.005598442163318396,
            -0.011242717504501343,
            -0.018561046570539474,
            -0.03040654957294464,
            -0.026258260011672974,
            0.02767370268702507,
            -0.02036428451538086,
            0.06928013265132904,
            -0.02742648869752884,
            0.037345416843891144,
            -0.09526732563972473,
            0.0016834409907460213,
            -0.02060166373848915,
            0.03046635165810585,
            0.034302566200494766,
            -0.014645196497440338,
            -0.021133050322532654,
            0.011999932117760181,
            -0.02872159332036972,
            -0.005318702198565006,
            0.03566409647464752,
            0.002810141071677208,
            -0.004365763161331415,
            -0.027497494593262672,
            0.008097994141280651,
            -0.022702768445014954,
            -0.02626018598675728,
            -0.018584799021482468,
            0.020341727882623672,
            -0.032151009887456894,
            0.00028282092534936965,
            -0.011599507182836533,
            0.009758519940078259,
            -0.015867166221141815,
            -0.03322559967637062,
            0.0013743051094934344,
            -0.0375576876103878,
            -0.010166715830564499,
            -0.002796695102006197,
            0.002787132980301976,
            0.008306982927024364,
            0.049236640334129333,
            0.003894038964062929,
            -0.05334186553955078,
            -0.000354619522113353,
            0.022665295749902725,
            -0.019887791946530342,
            -0.0059976172633469105,
            0.016877613961696625,
            -0.06516104936599731,
            -0.01242320891469717,
            0.031175579875707626,
            -0.05748145282268524,
            -0.028921261429786682,
            -0.03416120260953903,
            -0.0063179610297083855,
            -0.03973929584026337,
            0.014417652040719986,
            0.015988605096936226,
            0.008329616859555244,
            0.01684936322271824,
            -0.013036114163696766,
            -0.03398330509662628,
            -0.01265809778124094,
            0.054363466799259186,
            -0.07869309186935425,
            -0.013494623824954033,
            0.0533803254365921,
            0.00846742931753397,
            -0.036904044449329376,
            -0.02760576270520687,
            -0.010992122814059258,
            -0.015804804861545563,
            0.008749671280384064,
            0.006256525870412588,
            -0.012876979075372219,
            -0.003309184219688177,
            0.00828101672232151,
            -0.002932803938165307,
            -0.009290222078561783,
            -0.017298661172389984,
            -0.05334073305130005,
            0.0375836007297039,
            0.022740501910448074,
            0.05433182418346405,
            0.012461710721254349,
            0.01728476956486702,
            -0.0063512795604765415,
            0.033412326127290726,
            -0.022166911512613297,
            0.007487336173653603,
            -0.01466643251478672,
            0.026364538818597794,
            -0.038339827209711075,
            -0.03629814088344574,
            -0.06323917210102081,
            0.029119210317730904,
            -0.04430920630693436,
            0.0004532060702331364,
            -0.018492039293050766,
            0.0225378330796957,
            -0.08565840125083923,
            0.00011345661914674565,
            0.00031072093406692147,
            -0.019450679421424866,
            -0.03156489506363869,
            -0.020993655547499657,
            0.028318889439105988,
            0.06952492147684097,
            0.06361086666584015,
            -0.029402684420347214,
            -0.006357098929584026,
            -0.01027210708707571,
            -0.05256466940045357,
            0.0017026226269081235,
            0.0388973169028759,
            -0.010280380956828594,
            0.02907363697886467,
            -0.015774700790643692,
            0.03555512800812721,
            0.020737675949931145,
            -0.027142345905303955,
            0.017546338960528374,
            -0.0037768520414829254,
            0.026670878753066063,
            -0.04491221159696579,
            -0.0022178201470524073,
            -0.013833698816597462,
            -0.07477045804262161,
            -0.02940717525780201,
            -0.022955341264605522,
            -0.024497104808688164,
            -0.018234867602586746,
            0.02390073984861374,
            -0.016320783644914627,
            0.005791284143924713,
            0.018103735521435738,
            0.05023593083024025,
            0.04170190915465355,
            0.02134409360587597,
            -0.026711691170930862,
            -0.049917276948690414,
            -0.03036503866314888,
            0.010480290278792381,
            -0.025692125782370567,
            -0.012202532961964607,
            -0.008132860995829105,
            0.004145277664065361,
            0.018407229334115982,
            -0.03644867241382599,
            -0.026752334088087082,
            0.02347530610859394,
            -0.0037227950524538755,
            -0.012610992416739464,
            -0.03264807164669037,
            -0.055966995656490326,
            -0.041156522929668427,
            -0.01377140637487173,
            0.0028491918928921223,
            -0.013760223984718323,
            -0.023553140461444855,
            -0.0822957307100296,
            0.03080870397388935,
            0.01835130900144577,
            0.019993770867586136,
            -0.004805489908903837,
            0.03201087936758995,
            0.01644286885857582,
            -0.007702716626226902,
            -0.007031494751572609,
            0.022371456027030945,
            0.0016363407485187054,
            -0.013788394629955292,
            -0.006676895543932915,
            -0.014260533265769482,
            0.007361374329775572,
            0.06274758279323578,
            -0.028053371235728264,
            -0.025559641420841217,
            0.024515541270375252,
            -0.014135944657027721,
            0.011624245904386044,
            -0.014363735914230347,
            -0.03398161381483078,
            0.012439584359526634,
            0.01547064445912838,
            0.02032230794429779,
            -0.020466076210141182,
            -0.018721414729952812,
            0.02375176176428795,
            0.01295627374202013,
            -0.02499336749315262,
            -0.022199634462594986,
            -0.041150059551000595,
            -0.033053942024707794,
            -0.024028951302170753,
            -0.015491806901991367,
            -0.03491436690092087,
            0.0388772077858448,
            0.02313345856964588,
            0.0011201484594494104,
            -0.01752951368689537,
            -0.052724625915288925,
            -0.017546186223626137,
            0.0356777086853981,
            0.08092547208070755,
            0.03751936927437782,
            -0.02320941910147667,
            0.025074366480112076,
            0.01618851348757744,
            0.008134610019624233,
            0.02299206331372261,
            -0.01207322534173727,
            0.08025365322828293,
            0.013816704042255878,
            0.0356970839202404,
            0.004977068398147821,
            -0.06347084790468216,
            0.01596326008439064,
            -0.011850190348923206,
            0.05179622769355774,
            -0.004220429342240095,
            0.03197546675801277,
            0.006574470549821854,
            -0.008983324281871319,
            0.0022085928358137608,
            0.010397682897746563,
            0.03641741722822189,
            0.026627350598573685,
            0.008788540959358215,
            -0.03294539079070091,
            0.04093049094080925,
            -0.009845235385000706,
            -0.024926643818616867,
            -0.0177469439804554,
            0.010119759477674961,
            0.05173248425126076,
            0.030731871724128723,
            -0.010394100099802017,
            0.02489824779331684,
            -0.039866577833890915,
            -0.062297992408275604,
            -0.004049073904752731,
            0.021934159100055695,
            0.014262499287724495,
            -0.01757141575217247,
            -0.00684331264346838,
            0.04250955954194069,
            0.02557084895670414,
            -0.022041259333491325,
            -0.06598199158906937,
            0.04078789800405502,
            0.02782459743320942,
            -0.010945062153041363,
            -0.049680691212415695,
            -0.019288066774606705,
            -0.02628558874130249,
            0.02702845260500908,
            -0.019645266234874725,
            -0.07908695936203003,
            0.021052975207567215,
            -0.022126054391264915,
            0.022371390834450722,
            0.05526476725935936,
            -0.06473585218191147,
            0.015989258885383606,
            0.028928974643349648,
            -0.07403229176998138,
            0.03538260981440544,
            0.043746549636125565,
            0.02266658842563629,
            0.03243932127952576,
            0.009909424930810928,
            0.005642759148031473,
            -0.0011467424919828773,
            0.028898267075419426,
            0.03129439428448677,
            -0.044329628348350525,
            -0.03710165247321129,
            -0.035077668726444244,
            0.03291698545217514,
            -0.0012395787052810192,
            -0.02090608887374401,
            -0.012669685296714306,
            0.0023116578813642263,
            0.0018241207581013441,
            -0.027272872626781464,
            -0.0018631035927683115,
            0.004597827792167664,
            0.018676703795790672,
            -0.03367742896080017,
            -0.06702452898025513,
            -0.03601627051830292,
            0.01510540395975113,
            0.02525958977639675,
            -0.02484097331762314,
            -0.011001955717802048,
            -0.0046676769852638245,
            0.008795259520411491,
            0.0626305416226387,
            -0.012367642484605312,
            0.07135062664747238,
            0.0497056283056736,
            -0.010973255150020123,
            -0.024511855095624924,
            0.013628429733216763,
            0.04206232354044914,
            0.003436201950535178,
            0.02942446991801262,
            0.018027320504188538,
            0.06041784957051277,
            0.027808232232928276,
            -0.00625482527539134,
            0.006405108142644167,
            0.007108533289283514,
            -0.06370534747838974,
            0.059563156217336655,
            -0.025305015966296196,
            0.0010380729800090194,
            0.013286728411912918,
            0.01306317187845707,
            0.0018233873415738344,
            -0.007002714555710554,
            0.028300927951931953,
            -0.012212296947836876,
            0.0223117396235466,
            0.020013069733977318,
            -0.016877956688404083,
            -0.014907633885741234,
            -0.08198044449090958,
            -0.008085986599326134,
            -0.006506375037133694,
            0.0027220870833843946,
            0.0011295122094452381,
            -0.0005190439405851066,
            -0.02130514569580555,
            -0.05174073949456215,
            0.0045200432650744915,
            -0.010675177909433842,
            0.0005349767743609846,
            -0.06277620047330856,
            0.027391597628593445,
            -0.10839599370956421,
            0.0058532520197331905,
            0.011557149700820446,
            0.013117257505655289,
            -0.03800945356488228,
            -0.08257994055747986,
            0.0038330871611833572,
            -0.041698820888996124,
            0.010943788103759289,
            -0.03285292163491249,
            0.02806384116411209,
            -0.019024401903152466,
            -0.06345894932746887,
            0.0033779521472752094,
            0.012405996210873127,
            -0.023459123447537422,
            -0.04195060953497887,
            -0.008881501853466034,
            0.061760954558849335,
            -0.022258084267377853,
            -0.003442550776526332,
            -0.00541583402082324,
            -0.04567243531346321,
            -0.03770148381590843,
            0.014800749719142914,
            0.014245832338929176,
            0.01907309703528881,
            0.04496734216809273,
            -0.006723135709762573,
            0.03978331387042999,
            -0.07394952327013016,
            -0.01364978589117527,
            -0.021507220342755318,
            -0.047320201992988586,
            0.007477074395865202,
            -0.02919372171163559,
            0.008072132244706154,
            0.0148317227140069,
            -0.005985605530440807,
            -0.0008422683458775282,
            0.017550058662891388,
            -0.051457129418849945,
            -0.013767666183412075,
            -0.03429475426673889,
            -0.026132531464099884,
            0.050874121487140656,
            0.02480059489607811,
            0.031224234029650688,
            0.050900012254714966,
            0.0033109262585639954,
            0.04582380875945091,
            -0.012907211668789387,
            0.0375039167702198,
            -0.08184611052274704,
            -0.02869824320077896,
            0.0046551343984901905,
            0.017231330275535583,
            0.017896683886647224,
            -0.014856468886137009,
            0.032111186534166336,
            -0.0157442819327116,
            -0.02376854419708252,
            0.0262672808021307,
            -0.008136685006320477,
            0.0014000135706737638,
            0.009847162291407585,
            0.038737453520298004,
            0.0011745903175324202,
            -0.02053794637322426,
            -0.04513099789619446,
            0.0036640625912696123,
            0.017180340364575386,
            -0.07972345501184464,
            0.00640994543209672,
            -0.034087762236595154,
            0.05965510383248329,
            0.005450137425214052,
            0.003526611253619194,
            -0.01862945593893528,
            0.029305940493941307,
            -0.045398611575365067,
            0.02431548573076725,
            -0.037514347583055496,
            0.03887954354286194,
            -0.0030016854871064425,
            -0.0177890807390213,
            0.021143080666661263,
            0.0068556517362594604,
            -0.028300797566771507,
            -0.06207747757434845,
            0.012197554111480713,
            -0.021328270435333252,
            -0.03914587199687958,
            0.03414134681224823,
            0.028663931414484978,
            -0.012872972525656223,
            -0.008135133422911167,
            -0.010929468087852001,
            -0.017578190192580223,
            -0.014913421124219894,
            0.05942755937576294,
            0.1015944853425026,
            0.024224143475294113,
            0.007228868547827005,
            -0.03075401671230793,
            0.006341550964862108,
            -0.03039264865219593,
            -0.02900870144367218,
            0.01913781836628914,
            0.05160444602370262,
            -0.04374831169843674,
            0.03420492261648178,
            0.02090355008840561,
            0.014767209999263287,
            0.026238394901156425,
            0.025071702897548676,
            0.015063106082379818,
            -0.02937568537890911,
            -0.008726842701435089,
            -0.011628941632807255,
            -0.014563166536390781,
            0.07521368563175201,
            -0.04759904369711876,
            0.02658456191420555,
            0.016148412600159645,
            0.016884291544556618,
            0.0224003866314888,
            -0.0007219873368740082,
            -0.03322749212384224,
            0.015019068494439125,
            -0.009695567190647125,
            0.010755429975688457,
            -0.028282949700951576,
            0.05564063787460327,
            0.0040001338347792625,
            -0.03105299361050129,
            -0.005078605376183987,
            -0.01873258873820305,
            0.011095764115452766,
            -0.011711235158145428,
            0.0089456457644701,
            0.03362366929650307,
            0.00226901238784194,
            -0.00036366228596307337,
            -0.02878282405436039,
            0.039896778762340546,
            -0.07347162067890167,
            0.039295800030231476,
            0.01799440197646618
        ],
        [
            0.024432387202978134,
            0.011143878102302551,
            0.017180336639285088,
            -0.01977616362273693,
            0.0240029189735651,
            -0.06359264254570007,
            -0.017256569117307663,
            -0.04888155683875084,
            -0.019799621775746346,
            -0.03674982860684395,
            0.009340140968561172,
            -0.017451025545597076,
            -0.028651662170886993,
            -0.03446183353662491,
            -0.0372219979763031,
            0.0557546429336071,
            0.03560415655374527,
            -0.019539548084139824,
            -0.051451802253723145,
            0.02304394543170929,
            -0.012331348843872547,
            0.004989977926015854,
            -0.020057061687111855,
            0.011585737578570843,
            0.013854112476110458,
            -0.02825848199427128,
            -0.04529471695423126,
            -0.024645768105983734,
            0.012884520925581455,
            -0.015640053898096085,
            0.0064004999585449696,
            0.04181024432182312,
            0.0035124828573316336,
            0.01840502955019474,
            0.011821219697594643,
            0.032217204570770264,
            -0.02027972787618637,
            0.032286062836647034,
            0.026953982189297676,
            0.03503048047423363,
            -0.019885381683707237,
            -0.002859984990209341,
            0.007261565886437893,
            0.015914186835289,
            0.01581414043903351,
            -0.006759797688573599,
            -0.029053939506411552,
            -0.001673049875535071,
            -0.020120959728956223,
            -0.0004197404196020216,
            -0.026406779885292053,
            -0.0015074979746714234,
            -0.02478203736245632,
            0.02753431349992752,
            0.04392026737332344,
            0.011803437024354935,
            -0.01902136579155922,
            -0.014659898355603218,
            -0.028482649475336075,
            -0.006531501654535532,
            0.028182651847600937,
            0.017450222745537758,
            0.004165819846093655,
            -0.019123679026961327,
            -0.029913170263171196,
            -0.053873416036367416,
            0.029647916555404663,
            0.02320759929716587,
            -0.006107964087277651,
            -0.0022467582020908594,
            0.006198544520884752,
            0.021936772391200066,
            0.02683238685131073,
            0.01564714126288891,
            0.009032280184328556,
            0.024635888636112213,
            -0.007042545359581709,
            0.0156483706086874,
            0.020891763269901276,
            -0.033338576555252075,
            -0.01965133473277092,
            0.03733935207128525,
            0.0072120302356779575,
            0.011020579375326633,
            0.007912197150290012,
            -0.014086499810218811,
            -0.0270532239228487,
            -0.00483619375154376,
            0.038335200399160385,
            -0.02879311516880989,
            0.02341236174106598,
            0.0025594555772840977,
            0.01930837705731392,
            -0.006280113011598587,
            -0.005127898417413235,
            -0.0052690873853862286,
            0.006602947134524584,
            0.02345387451350689,
            0.006017197389155626,
            -0.0014961304841563106,
            0.01655697636306286,
            -0.0022734575904905796,
            -0.028728382661938667,
            0.02567155286669731,
            0.013341967947781086,
            0.015311582013964653,
            0.0326739139854908,
            0.029106859117746353,
            -0.0043703485280275345,
            0.02205768972635269,
            -0.015339098870754242,
            -0.026940707117319107,
            0.02840396575629711,
            -0.019171901047229767,
            0.029582075774669647,
            -0.011396410875022411,
            -0.02987770363688469,
            -0.0013863480417057872,
            0.009136321023106575,
            -0.03774358704686165,
            -0.009666367433965206,
            -0.02688121795654297,
            -0.06450705975294113,
            -0.028057070448994637,
            0.032091375440359116,
            0.0062150536105036736,
            0.011265495792031288,
            -0.006899614818394184,
            -0.010346686467528343,
            0.017719469964504242,
            0.018830979242920876,
            -0.02602413296699524,
            -0.018474891781806946,
            -0.059480004012584686,
            0.00196029688231647,
            0.03048723191022873,
            -0.009172765538096428,
            0.012459815479815006,
            0.02275959774851799,
            0.013642674311995506,
            -0.017498893663287163,
            -0.004488226026296616,
            0.03906404972076416,
            -0.00806081760674715,
            -0.0017450143350288272,
            0.012244755402207375,
            -0.006054495926946402,
            -0.02322462387382984,
            0.016514873132109642,
            -0.018399706110358238,
            -0.03141826018691063,
            0.048006605356931686,
            -0.011229904368519783,
            0.012285836040973663,
            0.0070359231904149055,
            0.01906541921198368,
            0.038214948028326035,
            -0.007904052734375,
            -0.032307349145412445,
            -0.0565539225935936,
            0.029376178979873657,
            -0.004983918741345406,
            -0.006019539199769497,
            0.015105520375072956,
            -0.03735671192407608,
            -0.051087167114019394,
            0.0197457242757082,
            -0.01834351383149624,
            0.01970687136054039,
            -0.010377293452620506,
            -0.011900397017598152,
            0.0053002480417490005,
            0.03174364194273949,
            -0.007292345631867647,
            -0.03534494712948799,
            -0.02410009689629078,
            -0.008644909597933292,
            0.011141687631607056,
            -0.02073531039059162,
            -0.0246729739010334,
            -0.03626183420419693,
            0.01161655317991972,
            0.009301893413066864,
            -0.022839805111289024,
            0.01662042923271656,
            0.008038700558245182,
            -0.01633111573755741,
            -0.0014878654619678855,
            -0.00503608537837863,
            0.006937926169484854,
            0.0049863774329423904,
            -0.020319094881415367,
            0.004686705768108368,
            0.028907084837555885,
            0.022182997316122055,
            0.01572471670806408,
            0.03204964101314545,
            -0.0060282424092292786,
            0.0153761962428689,
            0.014658469706773758,
            0.021443666890263557,
            -0.031910497695207596,
            -0.026413526386022568,
            0.037510789930820465,
            0.0034250435419380665,
            0.003203669795766473,
            0.007409067824482918,
            -0.05456467717885971,
            -0.014065048657357693,
            -0.010068350471556187,
            -0.014982788823544979,
            -0.024912657216191292,
            -0.041135869920253754,
            0.018956124782562256,
            0.010429266840219498,
            0.028367942199110985,
            0.014622047543525696,
            -0.0014653289690613747,
            0.02380371280014515,
            0.03382937237620354,
            -0.027371682226657867,
            -0.011170952580869198,
            -0.004729261621832848,
            -0.0015828785253688693,
            0.020585110411047935,
            -0.011324767954647541,
            -0.019259149208664894,
            -0.017272811383008957,
            -0.0013503395020961761,
            0.015573306009173393,
            -0.0026207759510725737,
            0.0015025633620098233,
            0.03732084855437279,
            0.01722494140267372,
            -0.022691570222377777,
            0.01901065930724144,
            0.024527207016944885,
            0.028446657583117485,
            0.03556365519762039,
            0.005421882960945368,
            0.005678887944668531,
            0.02843983843922615,
            0.0157685074955225,
            0.00434121023863554,
            -0.059814851731061935,
            -0.014026914723217487,
            0.023164307698607445,
            -0.0035220549907535315,
            0.008561051450669765,
            -0.021680520847439766,
            -0.027863651514053345,
            -0.02396947704255581,
            0.02362259477376938,
            0.002001360757276416,
            -0.0012299283407628536,
            -0.014220206998288631,
            0.0032965335994958878,
            -0.004525280557572842,
            -0.029651274904608727,
            -0.02695510722696781,
            0.009201210923492908,
            0.012658725492656231,
            0.05331747606396675,
            0.011265154927968979,
            -0.025194164365530014,
            0.006698129698634148,
            0.030715739354491234,
            -0.00037591002183035016,
            0.011434370651841164,
            -0.009874491021037102,
            0.020852642133831978,
            -0.023157598450779915,
            0.023535382002592087,
            0.024346932768821716,
            0.013781622983515263,
            -0.01122884638607502,
            -0.03852653503417969,
            0.0023565359879285097,
            0.03120284155011177,
            -0.006439788267016411,
            -0.017268089577555656,
            -0.018224485218524933,
            -0.017774434760212898,
            0.0023005467373877764,
            0.006197311915457249,
            0.0132305808365345,
            0.0065580387599766254,
            0.005474512465298176,
            -0.008553230203688145,
            -0.022476375102996826,
            -0.024147287011146545,
            0.0031345211900770664,
            0.026679573580622673,
            0.01364777609705925,
            0.011814776808023453,
            -0.03261644393205643,
            -0.033039774745702744,
            0.020721465349197388,
            0.003571782261133194,
            0.005441462155431509,
            -0.03146791458129883,
            0.014837219379842281,
            -0.022178687155246735,
            -0.011412560939788818,
            0.008389069698750973,
            0.003920469433069229,
            -0.0006276144995354116,
            -0.008680563420057297,
            0.008780645206570625,
            0.028122007846832275,
            -0.028929347172379494,
            -0.015549271367490292,
            -0.0012942651519551873,
            0.001696595223620534,
            -0.02614223212003708,
            -0.03249797224998474,
            0.010657212696969509,
            -0.005443987436592579,
            -0.002591118449345231,
            0.013416456058621407,
            0.030115092173218727,
            -0.022579649463295937,
            0.013627039268612862,
            0.011130571365356445,
            0.012070206925272942,
            -0.02397843264043331,
            0.004430531524121761,
            0.01698365993797779,
            0.022379904985427856,
            0.028637008741497993,
            -0.020737778395414352,
            0.030034026131033897,
            -0.009873192757368088,
            0.03449355065822601,
            -0.04265770688652992,
            0.012479107826948166,
            -0.017403747886419296,
            0.022503869608044624,
            -0.005257543176412582,
            -0.004126946907490492,
            0.03238311782479286,
            0.016083717346191406,
            0.008085313253104687,
            0.01783205382525921,
            0.013308390974998474,
            -0.0006166943931020796,
            -0.010612387210130692,
            -0.02156088687479496,
            -0.023387575522065163,
            0.002083216328173876,
            -0.019959108904004097,
            0.010111512616276741,
            0.0103183938190341,
            0.028029877692461014,
            -0.022120533511042595,
            0.011699706315994263,
            0.000948058906942606,
            0.009163248352706432,
            0.035741277039051056,
            0.022338882088661194,
            0.016237683594226837,
            0.025680052116513252,
            0.006751658860594034,
            -0.03134724125266075,
            0.0255561713129282,
            0.019357549026608467,
            0.0030351991299539804,
            -0.015172000974416733,
            0.011397508904337883,
            0.015330617316067219,
            -0.010441290214657784,
            0.011258456856012344,
            -0.025900211185216904,
            0.021181194111704826,
            -0.024165350943803787,
            0.017520049586892128,
            0.011470695957541466,
            0.014524022117257118,
            -0.0330219641327858,
            -2.979965938720852e-05,
            -0.022483617067337036,
            0.026266707107424736,
            0.02474393881857395,
            -0.006509222090244293,
            -0.037202246487140656,
            0.016557568684220314,
            0.025960741564631462,
            -0.00157156924251467,
            0.04238758981227875,
            -0.0419028177857399,
            0.002095055067911744,
            0.005500271450728178,
            -0.010533101856708527,
            -0.03322838991880417,
            -0.02293401211500168,
            0.008836462162435055,
            -0.006459241267293692,
            -0.01902848668396473,
            0.003057322930544615,
            0.012743846513330936,
            -0.008392618969082832,
            0.019442295655608177,
            -0.019572120159864426,
            0.014581567607820034,
            0.019476501271128654,
            0.004960878752171993,
            0.021594002842903137,
            -0.008766922168433666,
            -0.026710566133260727,
            -0.0157471913844347,
            0.01405631098896265,
            -0.019602371379733086,
            -0.0012031446676701307,
            0.022521547973155975,
            0.011567192152142525,
            -0.026745283976197243,
            -0.007058425340801477,
            0.038913700729608536,
            -0.008639384061098099,
            -0.03126229718327522,
            -0.005313278641551733,
            0.022467439994215965,
            0.04575522989034653,
            -0.00840981025248766,
            -0.025641296058893204,
            -0.027633458375930786,
            -0.014323988929390907,
            0.012695234268903732,
            0.022671431303024292,
            -0.005679423920810223,
            0.015183040872216225,
            -0.007697300985455513,
            -0.022599391639232635,
            -0.003827323205769062,
            -0.009721009992063046,
            0.013128065504133701,
            0.023338647559285164,
            -0.004974220879375935,
            0.003805528860539198,
            -0.025338105857372284,
            -0.0359007827937603,
            0.0008228333899751306,
            0.016313061118125916,
            0.023725779727101326,
            -0.00824547279626131,
            0.03198036924004555,
            0.024816114455461502,
            0.03940306231379509,
            0.0113963158801198,
            -0.0119090024381876,
            -0.0018501559970900416,
            -0.011664146557450294,
            -0.038026902824640274,
            0.012590705417096615,
            0.008546343073248863,
            0.013869422487914562,
            0.027419129386544228,
            0.010628798045217991,
            -0.02034064196050167,
            0.012000306509435177,
            0.006316506769508123,
            0.029001103714108467,
            0.029569601640105247,
            0.005641271825879812,
            0.02484687604010105,
            0.0009298018412664533,
            0.014356269501149654,
            -0.019016209989786148,
            0.024088796228170395,
            0.027327267453074455,
            -0.015625670552253723,
            0.02855576016008854,
            0.003959187306463718,
            -0.00831032358109951,
            -0.023388050496578217,
            -0.0009028539061546326,
            0.0008028927841223776,
            -0.01646570675075054,
            -0.013594201765954494,
            0.004657234065234661,
            0.020484983921051025,
            0.002753988839685917,
            0.030491502955555916,
            -0.00031938377651385963,
            0.027316885069012642,
            -0.02577866241335869,
            -0.009945172816514969,
            -0.012831592001020908,
            -0.04407794401049614,
            0.014904951676726341,
            0.042218245565891266,
            0.006148075219243765,
            -0.0025123318191617727,
            -0.03271396830677986,
            -0.0020641745068132877,
            -0.02150183729827404,
            -0.009256754070520401,
            -0.014869138598442078,
            0.02030009590089321,
            0.0020733820274472237,
            -0.02801693230867386,
            0.020503150299191475,
            0.02973632700741291,
            0.014853132888674736,
            0.03222901374101639,
            0.005593041889369488,
            -0.026704566553235054,
            0.03485478460788727,
            0.00776278181001544,
            0.03446241095662117,
            -0.003187964903190732,
            0.01745607890188694,
            -0.015929829329252243,
            -0.007646656595170498,
            0.030883312225341797,
            0.023703591898083687,
            -0.02390446700155735,
            0.030681030824780464,
            -0.010367708280682564,
            -0.02070026844739914,
            0.03240130469202995,
            -0.004205386154353619,
            -0.01533378753811121,
            -0.018720200285315514,
            0.03860953450202942,
            -0.021781127899885178,
            -0.04442509636282921,
            -0.014927362091839314,
            0.029292264953255653,
            0.035922639071941376,
            0.01008217129856348,
            -0.005297260358929634,
            0.012769230641424656,
            0.017802245914936066,
            0.014982199296355247,
            -0.008758400566875935,
            -0.0016350989462807775,
            -0.002120908349752426,
            0.008129545487463474,
            -0.006441964767873287,
            -0.034636177122592926,
            -0.020446795970201492,
            -0.014433727599680424,
            -0.040581487119197845,
            -0.012359333224594593,
            0.019022304564714432,
            -0.015181539580225945,
            -0.016670439392328262,
            -0.01806190423667431,
            -0.017003847286105156,
            0.01806524209678173,
            -0.003555248025804758,
            -0.02989908680319786,
            0.020774008706212044,
            0.028489207848906517,
            0.033800654113292694,
            -0.010462253354489803,
            0.0034707929007709026,
            0.028693730011582375,
            0.02406725287437439,
            -0.019752267748117447,
            0.009471548721194267,
            -0.00019252917263656855,
            -0.03047197125852108,
            0.03538665920495987,
            0.020168552175164223,
            -0.015533164143562317,
            -0.002394311595708132,
            -0.017343105748295784,
            -0.017193112522363663,
            -0.014536256901919842,
            0.0056165773421525955,
            0.017299367114901543,
            -0.007029510103166103,
            0.011469384655356407,
            0.03297010064125061,
            -0.009840029291808605,
            0.019792761653661728,
            0.028306998312473297,
            0.016418542712926865,
            0.018603211268782616,
            0.027901947498321533,
            -0.017246784642338753,
            -0.019726356491446495,
            0.013237775303423405,
            -0.0077275498770177364,
            0.03357216343283653,
            0.006676505319774151,
            0.01740259677171707,
            -0.012583274394273758,
            0.024025358259677887,
            -0.004091306589543819,
            -0.011631777510046959,
            -0.01173019502311945,
            -0.026035768911242485,
            0.0013323241146281362,
            0.00934432353824377,
            -0.002598232589662075,
            -0.01010866742581129,
            -0.017598677426576614,
            -0.021835273131728172,
            -0.0006478026043623686,
            -0.05186541751027107,
            -0.00919099897146225,
            0.0033532867673784494,
            0.0037920703180134296,
            0.0002346477413084358,
            0.006899606436491013,
            -0.01170889101922512,
            0.01332914736121893,
            0.001999176573008299,
            -0.02314051240682602,
            -0.01003944966942072,
            0.021618206053972244,
            -0.026465367525815964,
            0.014048472978174686,
            0.015865575522184372,
            -0.004316958133131266,
            0.005476722028106451,
            -0.013883738778531551,
            0.013050828129053116,
            -0.02023339830338955,
            -0.00045023486018180847,
            0.014276926405727863,
            0.005368097219616175,
            -0.008270064368844032,
            -0.003994102589786053,
            0.00730487797409296,
            0.016759604215621948,
            0.012392330914735794,
            -0.02581682987511158,
            -0.029665466398000717,
            0.014307670295238495,
            0.04176757112145424,
            0.027019578963518143,
            0.01461346447467804,
            0.016278022900223732,
            -0.008053209632635117,
            0.006842342205345631,
            0.02316190116107464,
            -0.022336885333061218,
            -0.020532261580228806,
            0.00937979482114315,
            0.012865593656897545,
            -0.020399989560246468,
            -0.019180629402399063,
            0.02353684790432453,
            -0.007158095017075539,
            0.01995469257235527,
            -0.00015838402032386512,
            -0.00847273226827383,
            0.004445347469300032,
            0.017372747883200645,
            0.022835269570350647,
            -0.017681818455457687,
            0.010007482022047043,
            0.011499281041324139,
            0.002450239844620228,
            -0.02075093239545822,
            0.012909768149256706,
            -0.032840095460414886,
            -0.03799697756767273,
            -0.033436499536037445,
            -0.022546151652932167,
            -0.008471707813441753,
            -0.004957870580255985,
            0.006857722066342831,
            -0.0012149421963840723,
            0.011182339861989021,
            -0.007043363992124796,
            0.023619284853339195,
            0.00021358401863835752,
            0.004395471420139074,
            0.017681825906038284,
            -0.026239972561597824,
            0.03602876886725426,
            -0.016184045001864433,
            -0.02401912957429886,
            0.011445717886090279,
            -0.021208850666880608,
            0.005392805673182011,
            0.005686670541763306,
            -0.0062397560104727745,
            -0.01760251633822918,
            -0.001962444745004177,
            -0.023409731686115265,
            -0.018734145909547806,
            0.009973090142011642,
            0.027310045436024666,
            0.024184219539165497,
            0.030462998896837234,
            -0.005204958375543356,
            0.0005073936190456152,
            0.031980179250240326,
            0.017109012231230736,
            -0.025623971596360207,
            0.01377074234187603,
            0.0006062384927645326,
            -0.019417600706219673,
            0.008829062804579735,
            0.02119845524430275,
            -0.01097073219716549,
            0.012774279341101646,
            0.009692083112895489,
            0.03055601380765438,
            -0.028089316561818123,
            -0.021898552775382996,
            -0.03901340439915657,
            -0.04333890229463577,
            -0.009761183522641659,
            0.03115040436387062,
            -0.0034496767912060022,
            0.030497046187520027,
            0.022633979097008705,
            0.004682907834649086,
            -0.005598812829703093,
            -0.017167503014206886,
            -0.020834261551499367,
            0.01187966950237751,
            0.01719065196812153,
            -0.01315223053097725,
            -0.0010124710388481617,
            0.023035632446408272,
            -0.021375933662056923,
            -0.019295644015073776,
            -0.012060075998306274,
            -0.03476735204458237,
            0.023169558495283127,
            0.0006406273460015655,
            -0.006401145365089178,
            -0.02596125192940235,
            0.012829954735934734,
            0.019347960129380226,
            -0.011829199269413948,
            0.02524239756166935,
            -0.034858617931604385,
            -0.018611663952469826,
            -0.010977035388350487,
            -0.0019135941984131932,
            0.007048625964671373,
            -0.02715221792459488,
            -0.0258324034512043,
            -0.030804762616753578,
            0.014844541437923908,
            -0.014528216794133186,
            -0.01885746791958809,
            -0.004027539864182472,
            0.004094286821782589,
            -0.004782864358276129,
            -0.007745790760964155,
            -0.01961618848145008,
            0.02877020090818405,
            0.009743443690240383,
            -0.006811163388192654,
            0.011006304062902927,
            -0.025291992351412773,
            -0.02043522149324417,
            0.044296085834503174,
            0.01004999503493309,
            -0.03364526852965355,
            0.004865801893174648,
            -0.0014510503970086575,
            0.008879981003701687,
            -9.431027137907222e-05,
            0.0025037480518221855,
            -0.0037557361647486687,
            -0.021968340501189232,
            -0.009894631803035736,
            -0.02005833573639393,
            0.007106394972652197,
            0.0035649086348712444,
            -0.0077981348149478436,
            0.028481628745794296,
            0.010609555058181286,
            0.0042451187036931515,
            -0.07473199814558029,
            0.011050889268517494,
            -0.040823232382535934,
            0.012143664993345737,
            0.008065659552812576,
            -0.011613030917942524,
            0.026098141446709633,
            -0.021556591615080833,
            0.0314231775701046,
            -0.0006045716581866145,
            0.004983723163604736,
            -0.02258058823645115,
            0.02158847078680992,
            -0.05094185844063759,
            0.012575087137520313,
            -0.0030250283889472485,
            -0.03215273097157478,
            -0.007672070991247892,
            0.017641877755522728,
            0.0048472099006175995,
            0.014102832414209843,
            -0.008139231242239475,
            -0.019401490688323975,
            0.015644485130906105,
            -0.02356862835586071,
            -0.06929632276296616,
            -0.0022108866833150387,
            -0.004022214096039534,
            0.030427804216742516,
            0.0371905118227005,
            -0.005526676308363676,
            -0.03699004650115967,
            0.026180440559983253,
            -0.012932227924466133,
            -0.004504448734223843,
            -0.011615695431828499,
            -0.02099495194852352,
            -0.013323316350579262,
            -0.021021226420998573,
            0.018962685018777847,
            0.0029025475960224867,
            0.014442075975239277,
            0.037831902503967285,
            -0.02010495588183403,
            -0.011337854899466038,
            0.0015804242575541139,
            -0.008840464055538177,
            -0.010400762781500816,
            0.0037683688569813967,
            -0.03315241262316704,
            0.0034719333052635193,
            -0.021522024646401405,
            -0.006192469969391823,
            -0.007003773003816605,
            0.02837136760354042,
            -0.018781593069434166,
            -0.020717114210128784,
            0.006687789689749479,
            -0.013564134947955608,
            0.0184759721159935,
            -0.006102263927459717,
            -0.020462511107325554,
            -0.004686562344431877,
            0.0207101758569479,
            0.034629471600055695,
            -0.027348849922418594,
            0.010217344388365746,
            0.02309536188840866,
            -0.04497082531452179,
            0.013594097457826138,
            0.03225141018629074,
            -0.017731420695781708,
            0.03240644559264183,
            0.010312734171748161,
            -0.015239798463881016,
            -0.027107015252113342,
            0.016862014308571815,
            -0.014489560388028622,
            0.02956196665763855,
            -0.013004799373447895,
            -0.027958685532212257,
            0.02104656584560871,
            -0.028176220133900642,
            -0.026820609346032143,
            0.025174764916300774,
            0.012322325259447098,
            0.012404799461364746,
            0.020957136526703835,
            0.010301586240530014,
            0.017053969204425812,
            -0.02006528712809086,
            0.02647051401436329,
            0.03219412639737129,
            -0.02157679572701454,
            -0.020061640068888664,
            -0.0031835611443966627,
            -0.02538842149078846,
            0.008440711535513401,
            -0.03437654674053192,
            -0.014680314809083939,
            -0.0027056082617491484,
            0.0013708933256566525,
            -0.017678996548056602,
            -0.02041771076619625,
            -0.01582055352628231,
            0.02415871061384678,
            0.013863910920917988,
            0.028835127130150795,
            -0.02540302649140358,
            0.01655755005776882,
            -0.0072532473132014275,
            0.015656841918826103,
            -0.024115340784192085,
            -0.010647634975612164,
            -0.02809740975499153,
            0.007543086539953947,
            0.010965833440423012,
            -0.011387288570404053,
            0.0035734991542994976,
            -0.0015528373187407851,
            -0.009622675366699696,
            -0.003279794240370393,
            -0.00813786219805479,
            -0.00027722230879589915,
            -0.018718434497714043,
            -0.013508472591638565,
            -0.034992266446352005,
            -0.01857571303844452,
            0.018830129876732826,
            0.02138656936585903,
            -0.060092438012361526,
            -0.05133591219782829,
            0.0439179465174675,
            0.012720335274934769,
            -0.03215714171528816,
            0.014677065424621105,
            0.021422790363430977,
            -0.00672733411192894,
            0.02751707099378109,
            -0.019411766901612282,
            -0.015114388428628445,
            0.015567094087600708,
            0.019160080701112747,
            0.005219115410000086,
            0.01176389493048191,
            0.013767831027507782,
            0.013841584324836731,
            -0.022996528074145317,
            -0.019651299342513084,
            -0.04145340621471405,
            -0.0018594821449369192,
            0.031123990193009377,
            0.012021096423268318,
            -0.015386559069156647,
            -0.02950858324766159,
            0.023742428049445152,
            0.018233763054013252,
            -0.019013894721865654,
            -0.025259027257561684,
            0.0073119536973536015,
            0.011556034907698631,
            -0.004869605414569378,
            -0.01856345310807228,
            -0.005850678775459528,
            0.017168190330266953,
            0.013903683051466942,
            -0.022688930854201317,
            -0.007622302509844303,
            0.004607819020748138,
            -0.044745925813913345,
            0.0033677825704216957,
            0.005159083288162947,
            -0.023023564368486404,
            0.012438169680535793,
            -0.009739421308040619,
            -0.017776453867554665,
            0.016690460965037346,
            0.013954956084489822,
            -0.01049761101603508,
            0.01968749426305294,
            -0.018328865990042686,
            0.01139274425804615,
            0.022622181102633476,
            -0.00920578557997942,
            -0.020305337384343147,
            -0.019613921642303467,
            -0.012407097965478897,
            -0.01014658436179161,
            -0.01404432114213705,
            0.00041521608363837004,
            -0.007421023212373257,
            -0.049525655806064606,
            0.024865880608558655,
            -0.012104855850338936,
            -0.0006491842796094716,
            -0.025354843586683273,
            0.025504225865006447,
            0.0038185524754226208,
            0.02143934741616249,
            -0.0018386695301160216,
            -0.022285623475909233,
            -0.003490881994366646,
            -0.030059857293963432,
            -0.029066316783428192,
            0.028825804591178894,
            0.035151176154613495,
            -0.006744281854480505,
            -0.020534811541438103,
            0.023763801902532578,
            -0.0330490916967392,
            -0.01140706054866314,
            -0.002323199063539505,
            -0.004313202574849129,
            -0.015762105584144592,
            0.017210770398378372,
            -0.030367547646164894,
            -0.01360377948731184,
            0.036475978791713715,
            -0.003370943246409297,
            0.030971067026257515,
            -0.008968284353613853,
            -0.01287644449621439,
            0.013312633149325848,
            -0.03648848831653595,
            -0.026228157803416252,
            -0.0028924753423780203,
            0.011031276546418667,
            -0.03501245751976967,
            -0.026757212355732918,
            -0.012700728140771389,
            0.009805480018258095,
            0.0050177001394331455,
            -0.010373644530773163,
            0.013254847377538681,
            -0.02601502276957035,
            -0.013668252155184746,
            -0.009391077794134617,
            -0.023998528718948364,
            -0.01723468489944935,
            -0.02111727185547352,
            0.05829795077443123,
            0.021134808659553528,
            -0.0002390246809227392,
            -0.0028748144395649433,
            0.001205177279189229,
            0.013028710149228573,
            -0.044037073850631714,
            0.009275739081203938,
            -0.014706652611494064
        ],
        [
            0.03655748814344406,
            0.002295169746503234,
            -0.014142315834760666,
            0.007313468959182501,
            -0.014235462062060833,
            0.01924220472574234,
            -0.02152613177895546,
            0.004213383886963129,
            0.002365275751799345,
            0.02232065051794052,
            0.01627638004720211,
            0.009419393725693226,
            0.03484995663166046,
            0.0017029257724061608,
            0.009007534012198448,
            0.04752384126186371,
            0.0221550390124321,
            0.013793417252600193,
            -0.013331346213817596,
            0.007826356217265129,
            -0.009182180278003216,
            0.01862645149230957,
            0.009354237467050552,
            0.017022904008626938,
            -0.01570538617670536,
            -0.01491805724799633,
            0.025689493864774704,
            0.0017971402266994119,
            -0.019079264253377914,
            -0.03497619554400444,
            0.00968942791223526,
            -0.001089173718355596,
            0.02779814414680004,
            0.0030828737653791904,
            0.015105999074876308,
            0.012830073945224285,
            0.016858745366334915,
            0.0004638013197109103,
            0.007363492157310247,
            0.016634264960885048,
            -0.01397012360394001,
            0.005544960964471102,
            0.01622924953699112,
            0.008710893802344799,
            0.003071538871154189,
            0.008255824446678162,
            -0.03534845635294914,
            -0.0170955378562212,
            0.013254830613732338,
            0.043125540018081665,
            0.0031908892560750246,
            -0.02355634979903698,
            -0.02272975817322731,
            -0.014507132582366467,
            -0.02209051512181759,
            0.003593376837670803,
            0.02477545477449894,
            0.002436882583424449,
            -0.01577964425086975,
            0.014096499420702457,
            -0.04171784222126007,
            0.022860553115606308,
            0.0074422019533813,
            -0.0186832994222641,
            0.0043478719890117645,
            -0.007141285575926304,
            0.03611334040760994,
            0.010669666342437267,
            0.00909489393234253,
            0.006058704573661089,
            -0.03362856060266495,
            -0.014545977115631104,
            0.009374896995723248,
            -0.010757998563349247,
            0.009152110666036606,
            -0.0318978913128376,
            -0.02172456867992878,
            -0.021023277193307877,
            0.018505239859223366,
            0.021800139918923378,
            0.027007579803466797,
            -0.03201372176408768,
            0.01728268712759018,
            0.0005648493533954024,
            0.0030781831592321396,
            -0.03624195605516434,
            -0.04015292227268219,
            0.013676423579454422,
            -0.019340718165040016,
            0.003552693175151944,
            0.019249550998210907,
            -0.015064540319144726,
            0.014722771011292934,
            -0.0294019915163517,
            0.033145491033792496,
            0.01716669835150242,
            -0.03583795577287674,
            0.026983210816979408,
            0.05996108055114746,
            -0.027113638818264008,
            0.02954355627298355,
            -0.031083619222044945,
            0.01224783156067133,
            -0.003257199190557003,
            0.004616980440914631,
            -0.01744145154953003,
            0.014822382479906082,
            0.004036531317979097,
            -0.0018396046943962574,
            -0.02056117169559002,
            0.0005184470792300999,
            0.0316130705177784,
            0.023832889273762703,
            -0.0005965231102891266,
            -0.02667616866528988,
            0.022289955988526344,
            0.016449591144919395,
            -0.000844326801598072,
            -0.00964412558823824,
            0.009193511679768562,
            0.008002202957868576,
            0.03256624564528465,
            0.05868156999349594,
            -0.006558602210134268,
            0.02068733051419258,
            0.013387558050453663,
            0.033623091876506805,
            -0.02776564285159111,
            0.026619376614689827,
            -0.0434608981013298,
            0.009807566180825233,
            0.000924807449337095,
            -0.020089657977223396,
            0.05804946646094322,
            -0.0216657854616642,
            -0.005919641815125942,
            -0.0005131086800247431,
            0.021024314686655998,
            -0.005427550058811903,
            0.000802818569354713,
            -0.022319631651043892,
            0.008027742616832256,
            -0.009805910289287567,
            -0.020750591531395912,
            0.017114244401454926,
            0.01655799150466919,
            0.009652064181864262,
            0.0074183703400194645,
            0.011643894016742706,
            0.0046846903860569,
            -0.018409118056297302,
            -0.03427104651927948,
            -0.025433937087655067,
            -0.06036832556128502,
            -0.005917551461607218,
            -0.002567986026406288,
            -0.028388096019625664,
            0.006956238765269518,
            0.012018905021250248,
            0.005452335346490145,
            0.008814569562673569,
            -0.021696992218494415,
            0.03384381905198097,
            0.0020122192800045013,
            -0.024861929938197136,
            -0.0052102007903158665,
            -0.038599394261837006,
            0.0484638474881649,
            0.024463968351483345,
            -0.00040318380342796445,
            -0.02500777319073677,
            -0.007356644608080387,
            -0.0174097940325737,
            0.008273306302726269,
            0.035076990723609924,
            0.024416597560048103,
            -0.008112545125186443,
            0.008501973934471607,
            0.019587283954024315,
            -0.0407104454934597,
            -0.01971760205924511,
            0.03617900609970093,
            0.026999162510037422,
            0.0029902965761721134,
            0.007891584187746048,
            -0.002170542487874627,
            -0.02637583203613758,
            0.027305128052830696,
            -0.0014499888056889176,
            0.026617297902703285,
            0.004903503693640232,
            0.01835324428975582,
            0.04171520099043846,
            -0.014110337011516094,
            -0.012176990509033203,
            -0.008076001890003681,
            -0.02340717241168022,
            0.021848896518349648,
            0.0067239562049508095,
            0.01744368113577366,
            0.0027417794335633516,
            0.02509322762489319,
            0.017363522201776505,
            -0.004807265009731054,
            0.058832909911870956,
            -0.01506891194730997,
            0.03369564935564995,
            -0.013715999200940132,
            0.001879606512375176,
            -0.015187880024313927,
            -0.01672373339533806,
            -0.05394577607512474,
            0.02495625615119934,
            -0.026328030973672867,
            -0.0035216149408370256,
            0.04424601048231125,
            0.008600719273090363,
            0.02213207446038723,
            0.017887471243739128,
            0.032068073749542236,
            0.003943440038710833,
            -0.017229951918125153,
            0.03416334465146065,
            -0.017399197444319725,
            -0.030156899243593216,
            0.03298201784491539,
            -0.029398545622825623,
            0.0034645861014723778,
            0.005679295398294926,
            -0.018647976219654083,
            0.02154850959777832,
            0.005327349528670311,
            -0.0020444330293685198,
            0.014879675582051277,
            -0.008288498967885971,
            -0.011978900991380215,
            0.020345142111182213,
            0.00827273353934288,
            -0.0115527817979455,
            -0.002311574062332511,
            -0.012278351932764053,
            -0.006102661602199078,
            0.011908430606126785,
            0.0036752852611243725,
            -0.009483981877565384,
            0.030796533450484276,
            0.016550108790397644,
            0.0269999448210001,
            0.026147428900003433,
            0.008016485720872879,
            0.02866937592625618,
            0.004684404470026493,
            0.018301455304026604,
            -0.004297464620321989,
            -0.03695503622293472,
            -0.010604774579405785,
            0.004885152913630009,
            0.03706950694322586,
            -0.001073489198461175,
            -0.00454888679087162,
            -0.032006606459617615,
            -0.024656761437654495,
            -0.01233099214732647,
            0.02013418823480606,
            -0.002003363100811839,
            -0.003295754548162222,
            0.03691636398434639,
            -0.012085692025721073,
            0.026109915226697922,
            0.03635765239596367,
            0.006971110124140978,
            -0.02221355028450489,
            0.005771989468485117,
            -0.03987591341137886,
            0.015716418623924255,
            0.015315975062549114,
            -0.04448606073856354,
            0.009028501808643341,
            -0.026799215003848076,
            0.024733740836381912,
            0.021189222112298012,
            0.010780838318169117,
            0.0012279718648642302,
            -0.015154733322560787,
            -0.02334233932197094,
            0.014484448358416557,
            -0.02774200402200222,
            -0.0016498258337378502,
            0.02004731446504593,
            -0.026206597685813904,
            0.018169235438108444,
            -0.01952625997364521,
            0.010897639207541943,
            -0.0348411463201046,
            -0.018877064809203148,
            -0.022308580577373505,
            0.01343270018696785,
            0.033281534910202026,
            0.030884701758623123,
            0.009573779068887234,
            0.008458063006401062,
            -0.015661653131246567,
            -0.009073118679225445,
            0.014222661033272743,
            0.012455346062779427,
            -0.030066577717661858,
            -0.025650547817349434,
            -0.021970532834529877,
            -0.025156933814287186,
            -0.01251306477934122,
            -0.07898538559675217,
            -0.00908335205167532,
            -0.00174962286837399,
            -0.027010014280676842,
            -0.029048651456832886,
            -0.05384646728634834,
            0.020127445459365845,
            0.025393100455403328,
            0.008704148232936859,
            -0.011654187925159931,
            0.010671027936041355,
            0.03853189945220947,
            0.02120434306561947,
            0.002574031939730048,
            -0.017727253958582878,
            -0.006388197652995586,
            -0.025650927796959877,
            0.002021300606429577,
            0.029768237844109535,
            -0.006926978938281536,
            -0.0003896194393746555,
            -0.005998078268021345,
            -0.00548181775957346,
            -0.049986861646175385,
            0.010057155042886734,
            -0.008012517355382442,
            0.010433506220579147,
            -0.014895894564688206,
            -0.013968442566692829,
            0.02545817568898201,
            -0.03237571194767952,
            -0.013487087562680244,
            -0.009796617552638054,
            -0.00985474418848753,
            -0.016506485641002655,
            0.012509861961007118,
            0.003218557918444276,
            -0.023110253736376762,
            -0.001757430494762957,
            0.0040814513340592384,
            0.04570287466049194,
            -0.054814208298921585,
            -0.011832023970782757,
            -0.01327145379036665,
            0.016137871891260147,
            0.02721727266907692,
            0.0026496872305870056,
            -0.02873694896697998,
            -0.028639456257224083,
            -0.00980047881603241,
            0.013264380395412445,
            -0.0030353949405252934,
            -0.04287620261311531,
            -0.016907237470149994,
            -0.005733884405344725,
            -0.020094387233257294,
            -0.015182548202574253,
            0.005109126679599285,
            0.011215871199965477,
            0.03259621933102608,
            0.03158239275217056,
            -0.012349379248917103,
            -0.026871763169765472,
            0.00211151922121644,
            0.026148008182644844,
            -0.025661909952759743,
            0.018678536638617516,
            -0.0038850426208227873,
            -0.014053508639335632,
            0.017356999218463898,
            -0.004967888351529837,
            -0.016352171078324318,
            0.0033118906430900097,
            0.010801157914102077,
            0.016333147883415222,
            -0.007675541564822197,
            -0.028215331956744194,
            -0.01137135736644268,
            -0.02126108668744564,
            0.029322758316993713,
            0.015368366613984108,
            -0.0098467031493783,
            -0.01011167373508215,
            0.035830046981573105,
            -0.0072211469523608685,
            -0.0021714488975703716,
            -0.02168123982846737,
            0.0003286699065938592,
            0.015605522319674492,
            -0.007852056063711643,
            0.015224836766719818,
            -0.00842540618032217,
            -0.022006604820489883,
            -0.018781514838337898,
            0.018398290500044823,
            -0.0023630906362086535,
            -0.012273650616407394,
            -0.020461900159716606,
            -0.02151472680270672,
            0.002172577427700162,
            0.00015683105448260903,
            0.0058507113717496395,
            -0.013829636387526989,
            0.0349816270172596,
            0.0105605972930789,
            -0.006174905691295862,
            -0.007604275364428759,
            -0.03602650761604309,
            0.0028922425117343664,
            0.023541772738099098,
            -0.01997705176472664,
            0.011698417365550995,
            -0.013444505631923676,
            -0.003379611764103174,
            0.005686406511813402,
            0.00022880711185280234,
            -0.014467884786427021,
            0.04136861488223076,
            0.004650990478694439,
            -0.031167710199952126,
            0.017492564395070076,
            -0.004692059475928545,
            0.022836610674858093,
            -0.014181911014020443,
            0.026376565918326378,
            -0.029174143448472023,
            0.0011724315118044615,
            -0.0078040058724582195,
            -0.028832925483584404,
            -0.011824273504316807,
            0.010269802063703537,
            -0.013492819853127003,
            -0.0054982914589345455,
            0.002415963914245367,
            0.03797215223312378,
            0.025874238461256027,
            0.012596401385962963,
            0.07418999820947647,
            0.00820874236524105,
            0.04705623537302017,
            0.028729040175676346,
            0.018161900341510773,
            0.0053843483328819275,
            -0.005416924599558115,
            -0.021866289898753166,
            -0.01978679560124874,
            -0.03837773576378822,
            0.016488363966345787,
            -0.01286559272557497,
            -0.02914915420114994,
            0.010684485547244549,
            -0.017192360013723373,
            -0.015673724934458733,
            -0.0390467531979084,
            -0.015350340865552425,
            0.0015009479830041528,
            0.017203213647007942,
            -0.0416753776371479,
            -0.03166566044092178,
            0.01205703429877758,
            -0.0029907221905887127,
            0.018047556281089783,
            -0.031940482556819916,
            6.891131488373503e-05,
            0.027406027540564537,
            -0.02102969028055668,
            0.022146837785840034,
            -0.03154454752802849,
            0.0015448300400748849,
            0.033031612634658813,
            0.008635294623672962,
            -0.030186176300048828,
            0.03777169808745384,
            0.02781122550368309,
            -0.0036009210161864758,
            0.008533578366041183,
            0.030011724680662155,
            0.025887221097946167,
            -0.009908865205943584,
            -0.002652091672644019,
            -0.012644409202039242,
            -0.003514000913128257,
            0.02919432334601879,
            -0.05583522841334343,
            0.039682742208242416,
            0.03221406787633896,
            0.00031413373653776944,
            0.02954106219112873,
            0.020732639357447624,
            0.020114373415708542,
            -0.02771918475627899,
            -0.024278393015265465,
            -0.013951299712061882,
            0.01633729226887226,
            0.01954890415072441,
            -0.007781848777085543,
            -0.01286119781434536,
            0.016707466915249825,
            0.04247192665934563,
            -0.0006581635097973049,
            0.014528044499456882,
            0.030273035168647766,
            0.002432870678603649,
            0.010942594148218632,
            0.02864953503012657,
            -0.0018490947550162673,
            0.01086859218776226,
            -0.03420106694102287,
            0.012738238088786602,
            0.023151444271206856,
            -0.00696927634999156,
            0.025483671575784683,
            0.02652687579393387,
            -0.03464221954345703,
            -0.023751135915517807,
            -0.021756155416369438,
            -0.029478391632437706,
            0.025981487706303596,
            0.030731040984392166,
            0.019885228946805,
            0.02243279665708542,
            -0.039429496973752975,
            0.006898009218275547,
            0.019456442445516586,
            -0.00799588579684496,
            0.023857254534959793,
            0.021531634032726288,
            -0.008843625895678997,
            -0.008919298648834229,
            0.012896226719021797,
            0.024168835952878,
            -0.010342998430132866,
            0.003975673578679562,
            0.011082305572926998,
            0.025892699137330055,
            -0.010834498330950737,
            0.007287072483450174,
            0.03586239740252495,
            0.031147390604019165,
            0.021863199770450592,
            -0.0022431607358157635,
            -0.02468060329556465,
            -0.02117428183555603,
            -0.018897973001003265,
            0.029529714956879616,
            -0.02525099366903305,
            0.027603646740317345,
            -0.024161942303180695,
            0.009502273052930832,
            -0.007817445322871208,
            -0.007360446732491255,
            -0.0104233892634511,
            -0.0021163218189030886,
            0.020858298987150192,
            -0.0029399723280221224,
            -0.02806767262518406,
            -0.01160349976271391,
            -0.004441087134182453,
            0.003024553181603551,
            0.028630927205085754,
            0.03262683004140854,
            0.027054939419031143,
            0.0011151352664455771,
            -0.028087394312024117,
            0.005964776035398245,
            -0.004524994641542435,
            0.0021674775052815676,
            0.0467180572450161,
            -0.025670833885669708,
            -0.0035099599044770002,
            -0.0013416092842817307,
            -0.001787122804671526,
            0.02874906174838543,
            -0.008152327500283718,
            -0.003090402577072382,
            -0.016230503097176552,
            -0.009583347477018833,
            -0.021166615188121796,
            0.01817191019654274,
            -0.017515648156404495,
            -0.003990057855844498,
            -0.014969765208661556,
            0.0032684472389519215,
            -0.023564888164401054,
            0.010670104064047337,
            -0.023311149328947067,
            -0.01005921047180891,
            0.00720828864723444,
            -0.003123539499938488,
            -0.015938827767968178,
            -0.018555810675024986,
            -0.025268366560339928,
            0.004224756266921759,
            0.017161570489406586,
            0.01618892140686512,
            0.027579322457313538,
            0.027849800884723663,
            0.020656919106841087,
            -0.005728321149945259,
            0.01787957176566124,
            -0.0058176699094474316,
            0.007361907511949539,
            0.01717476174235344,
            0.015905534848570824,
            0.039023254066705704,
            -0.0041778432205319405,
            0.024518487975001335,
            -0.015970321372151375,
            0.02320103347301483,
            0.004604620859026909,
            -0.0088583929464221,
            0.004689518362283707,
            0.028813421726226807,
            0.020609360188245773,
            -0.009625581093132496,
            0.025173738598823547,
            -0.006252333987504244,
            -0.0003828160697594285,
            -0.012188921682536602,
            -0.005147054325789213,
            0.01297458354383707,
            -0.02294715866446495,
            0.001345160766504705,
            -0.024851739406585693,
            0.00695086270570755,
            -0.01065730955451727,
            0.028343800455331802,
            -0.01059642806649208,
            0.009923409670591354,
            -0.010551488026976585,
            -0.020031651481986046,
            0.002405177103355527,
            -0.017386911436915398,
            -0.02455432340502739,
            0.006994315423071384,
            -0.025714591145515442,
            -0.01230825949460268,
            -0.030293550342321396,
            0.030733825638890266,
            0.031920481473207474,
            0.028737561777234077,
            -0.03339090198278427,
            -0.04114837944507599,
            0.00022406700009014457,
            0.000922361621633172,
            -0.01319670956581831,
            0.049398958683013916,
            -0.02603948675096035,
            -0.00997261330485344,
            -0.0032492431346327066,
            -0.007424136158078909,
            0.02249864861369133,
            0.010572761297225952,
            -0.028469152748584747,
            -0.005685765761882067,
            0.019087746739387512,
            -0.053057290613651276,
            -0.007264303509145975,
            -0.000931855698581785,
            0.003552147187292576,
            0.004007145296782255,
            -0.024694329127669334,
            -0.014317319728434086,
            -0.01622779667377472,
            0.005564525257796049,
            0.04131529480218887,
            0.028730932623147964,
            0.0009043393074534833,
            -0.02469613030552864,
            0.014063621871173382,
            -0.029401913285255432,
            -0.038642849773168564,
            -0.021976249292492867,
            0.0056501468643546104,
            -0.0035656788386404514,
            0.017935490235686302,
            0.02245946414768696,
            -0.008237293921411037,
            -0.017688604071736336,
            -0.006327935494482517,
            -0.028657104820013046,
            0.04110189154744148,
            -0.012318743392825127,
            0.02534046582877636,
            0.013508652336895466,
            -0.0007551633752882481,
            0.01465846411883831,
            0.01844831183552742,
            -0.014787775464355946,
            0.040210627019405365,
            0.024699058383703232,
            0.01145112793892622,
            -0.0018851780332624912,
            0.0032312374096363783,
            -0.021416090428829193,
            0.025212004780769348,
            0.006009435746818781,
            -0.01765693537890911,
            -0.02302427589893341,
            0.02058982104063034,
            -0.017852822318673134,
            0.02210291661322117,
            -0.01257794164121151,
            -0.008254026062786579,
            0.005322260782122612,
            -0.008685102686285973,
            0.027053268626332283,
            -0.008669517002999783,
            0.008684869855642319,
            -0.010777253657579422,
            0.027109945192933083,
            0.020529944449663162,
            0.011096377857029438,
            0.024864953011274338,
            0.008485729806125164,
            -0.008430884219706059,
            0.024834468960762024,
            -0.027234766632318497,
            0.013510499149560928,
            0.01202996727079153,
            0.02277897112071514,
            0.0021393666975200176,
            0.004435259383171797,
            -0.015447932295501232,
            -0.031915489584207535,
            0.030085032805800438,
            0.005776288919150829,
            0.020960722118616104,
            0.009463440626859665,
            0.0070717595517635345,
            0.007717246655374765,
            -0.007986090146005154,
            0.02130323462188244,
            -0.01767178624868393,
            0.0100875124335289,
            0.021525999531149864,
            -0.044760704040527344,
            5.522427090909332e-05,
            -0.020625123754143715,
            0.0292180385440588,
            -0.028673019260168076,
            0.02300405316054821,
            0.0015874423552304506,
            0.02504958026111126,
            -0.011563079431653023,
            -0.005057698581367731,
            -0.004704395774751902,
            0.030664097517728806,
            -0.005755043588578701,
            -0.038931090384721756,
            0.0012533280532807112,
            0.052938975393772125,
            0.023459067568182945,
            -0.021156813949346542,
            0.020437758415937424,
            0.0311879962682724,
            -0.02944914624094963,
            -0.0013526050606742501,
            0.00581658398732543,
            0.02419333904981613,
            0.017701281234622,
            0.009439134038984776,
            -0.011310473084449768,
            0.030012352392077446,
            0.0003935144923161715,
            -0.020847782492637634,
            0.03210639953613281,
            0.03666767477989197,
            -0.026853365823626518,
            0.026148086413741112,
            0.024615008383989334,
            0.013254879042506218,
            -0.02537030726671219,
            -0.0033469952177256346,
            -0.005427339114248753,
            -0.016714386641979218,
            0.007058193441480398,
            0.011913946829736233,
            -0.0358772911131382,
            0.037249378859996796,
            -0.0301925390958786,
            0.0336478017270565,
            -0.022436970844864845,
            0.005742587614804506,
            -0.004647867754101753,
            -0.009448615834116936,
            -0.010129735805094242,
            -0.014540440402925014,
            -0.02837640978395939,
            0.0026999751571565866,
            -0.026414593681693077,
            0.028375424444675446,
            -0.016288235783576965,
            0.0028358728159219027,
            0.0007343640900216997,
            0.043281447142362595,
            -0.014087636955082417,
            -0.003599281422793865,
            0.00854140892624855,
            -0.007404504809528589,
            0.010591723024845123,
            0.027072181925177574,
            -0.01659531705081463,
            -0.02864999510347843,
            0.023421186953783035,
            -0.011955230496823788,
            -0.014550319872796535,
            -0.024135524407029152,
            -0.01059748511761427,
            0.046498510986566544,
            -0.03582819551229477,
            0.0014073194470256567,
            -0.014281664043664932,
            -0.0025342144072055817,
            0.04356670752167702,
            -0.032764844596385956,
            0.02418670803308487,
            0.0035233362577855587,
            0.0011317846365272999,
            0.024508731439709663,
            -0.01885492540895939,
            0.009661133401095867,
            -0.030658189207315445,
            -0.023186948150396347,
            -0.016037816181778908,
            -0.026014039292931557,
            -0.01406562514603138,
            0.008155652321875095,
            -0.026207348331809044,
            0.008601383306086063,
            0.02848796546459198,
            0.02316618710756302,
            0.006802975200116634,
            -0.01080360822379589,
            0.03004447929561138,
            0.024199185892939568,
            0.0019696680828928947,
            0.02749672718346119,
            -0.007976136170327663,
            0.028808267787098885,
            0.013544807210564613,
            0.021826984360814095,
            0.008526947349309921,
            0.011150937527418137,
            -0.04629601538181305,
            -0.011631050147116184,
            0.003648150945082307,
            -0.03334509953856468,
            0.018911590799689293,
            0.04131896793842316,
            -0.021581366658210754,
            0.008783853612840176,
            -0.011167937889695168,
            0.013430308550596237,
            0.03304231911897659,
            -0.018526248633861542,
            -0.025886544957756996,
            -0.028757482767105103,
            -0.016539055854082108,
            -0.015024745836853981,
            -0.02931496873497963,
            0.003119161818176508,
            0.013470649719238281,
            0.012014045380055904,
            -0.03534815460443497,
            -0.01770414598286152,
            -0.01636740006506443,
            0.04747200384736061,
            -0.03337506204843521,
            0.001606019795872271,
            -0.044885195791721344,
            -0.012501247227191925,
            0.03894093632698059,
            0.03667924180626869,
            0.03377211466431618,
            -0.03176366165280342,
            -0.013026239350438118,
            0.021057698875665665,
            -0.01614774763584137,
            -0.026201145723462105,
            -0.004892235156148672,
            -0.01621255837380886,
            0.022333715111017227,
            -0.032231371849775314,
            0.04741712659597397,
            -0.03258781507611275,
            0.009680578485131264,
            0.010859636589884758,
            -0.014707597903907299,
            -0.0071675535291433334,
            -0.05518200621008873,
            0.017911620438098907,
            0.017953503876924515,
            -0.00461694085970521,
            0.0031093438155949116,
            0.05384780466556549,
            0.0011791761498898268,
            0.032529041171073914,
            -0.03115619346499443,
            0.029361344873905182,
            -0.015083876438438892,
            -0.027105165645480156,
            0.012240482494235039,
            0.027103237807750702,
            -0.039461638778448105,
            -0.007228071801364422,
            0.011074444279074669,
            0.0013543753884732723,
            0.01700037531554699,
            -0.027361204847693443,
            -0.006045947782695293,
            0.038001589477062225,
            0.019697364419698715,
            0.03484831377863884,
            0.03606986626982689,
            0.010281531140208244,
            -0.017509086057543755,
            0.012121826410293579,
            0.026276221498847008,
            -0.004005809780210257,
            -0.018304437398910522,
            0.011601470410823822,
            0.013816948048770428,
            -0.026713447645306587,
            0.015475887805223465,
            0.007015527691692114,
            -0.022499045357108116,
            0.008534572087228298,
            0.019923025742173195,
            0.020207848399877548,
            -0.010373243130743504,
            -0.028522197157144547,
            0.006030415650457144,
            0.018074847757816315,
            -0.01803302951157093,
            0.012498820200562477,
            -0.02979319542646408,
            -0.024519190192222595,
            0.009386599995195866,
            0.03219323232769966,
            0.029788874089717865,
            0.006892671342939138,
            -0.02035660482943058,
            0.012377641163766384,
            -0.003650007303804159,
            0.02326546609401703,
            0.024547560140490532,
            -0.022304238751530647,
            0.057596541941165924,
            0.03431936353445053,
            0.021372398361563683,
            -0.03012867644429207,
            0.00690277386456728,
            -0.0508275143802166,
            0.007060076110064983,
            0.0033814492635428905,
            -0.01755560003221035,
            -0.021823393180966377,
            0.029413864016532898,
            -0.030137687921524048,
            0.02709079533815384,
            0.007206875830888748,
            0.006081433966755867,
            -0.006828452460467815,
            -0.01989082805812359,
            -0.016542287543416023,
            0.05707583576440811,
            0.0028939652256667614,
            0.01328528393059969,
            0.029760120436549187,
            -0.01717548258602619,
            0.014715839177370071,
            -0.02533719502389431,
            0.014550094492733479,
            0.0016566416015848517,
            0.023419884964823723,
            -0.012993398122489452,
            0.03182115778326988,
            -0.009295551106333733,
            -0.01949825882911682,
            0.012208125554025173,
            0.011112583801150322,
            0.010492520406842232,
            0.021679824218153954,
            0.033355314284563065,
            0.04455399513244629,
            -0.03855658322572708,
            -0.009148339740931988,
            -0.012955067679286003,
            -0.024758076295256615,
            -0.014792216941714287,
            0.018313409760594368,
            0.024071581661701202,
            -0.00939014833420515,
            -0.0028742325957864523,
            -0.028892451897263527,
            -0.022158605977892876,
            3.12152442347724e-05,
            -0.01719559356570244,
            -0.01786917820572853,
            -0.01482602208852768,
            0.0027649931143969297,
            0.014147975482046604,
            0.0313178226351738,
            0.01854129135608673,
            -0.019138989970088005,
            -0.011492801830172539,
            0.013584950007498264,
            -0.019671635702252388,
            -0.012438123114407063,
            0.0211134385317564,
            0.02389352396130562,
            0.006232357118278742,
            0.01617305353283882
        ],
        [
            -0.00861493032425642,
            0.027398014441132545,
            0.030649608001112938,
            0.009129988960921764,
            0.009760082699358463,
            -0.031362880021333694,
            -0.0015941214514896274,
            -0.0004188476304989308,
            -0.00703203771263361,
            0.02509131468832493,
            0.01496462058275938,
            -0.01686583273112774,
            0.02762669511139393,
            -0.010740458033978939,
            -0.0239743422716856,
            0.028322216123342514,
            -0.008086842484772205,
            -0.02356910891830921,
            0.019752375781536102,
            0.024461746215820312,
            -0.048867858946323395,
            0.02013506181538105,
            -0.024771912023425102,
            -0.011733620427548885,
            0.010688343085348606,
            -0.026331165805459023,
            -0.029091423377394676,
            0.014569506980478764,
            0.017867324873805046,
            -0.012456091120839119,
            0.008566832169890404,
            0.004347102716565132,
            0.00634914543479681,
            -0.0009055050904862583,
            -0.004552001599222422,
            -0.0040502408519387245,
            0.025586623698472977,
            0.003572995075955987,
            0.007180626038461924,
            0.0034738602116703987,
            0.018182404339313507,
            -0.020338183268904686,
            0.00031755052623339,
            -0.022325316444039345,
            0.015249337069690228,
            -0.0016219422686845064,
            0.0292641781270504,
            -0.011608871631324291,
            -0.011987224221229553,
            0.012315531261265278,
            -0.019350934773683548,
            0.015074297785758972,
            -0.060842592269182205,
            0.009872392751276493,
            -0.032897405326366425,
            -0.015371209941804409,
            -0.033931706100702286,
            -0.023285340517759323,
            -0.00795836467295885,
            -0.011507946997880936,
            -0.07563817501068115,
            -0.0019230758771300316,
            0.010329732671380043,
            -0.03630100190639496,
            -0.030994102358818054,
            -0.019853655248880386,
            0.012346894480288029,
            0.02100566029548645,
            0.0006881284061819315,
            0.033001333475112915,
            -0.010257776826620102,
            -0.017103612422943115,
            -0.011461768299341202,
            -0.00461882958188653,
            -0.06253467500209808,
            -0.022466063499450684,
            0.0034871906973421574,
            0.005194896832108498,
            0.05024498328566551,
            0.011402277275919914,
            0.013672282919287682,
            -0.03950498625636101,
            0.02828814834356308,
            0.0011970814084634185,
            -0.009356660768389702,
            -0.017969030886888504,
            0.0068063512444496155,
            -0.034776221960783005,
            -0.02792101912200451,
            0.022414514794945717,
            0.018753010779619217,
            -0.02975718304514885,
            0.007164350710809231,
            0.019700326025485992,
            0.037609461694955826,
            0.026865409687161446,
            -0.018083058297634125,
            -0.0007323331665247679,
            -0.012883196584880352,
            -0.01772654987871647,
            -0.052687548100948334,
            0.03890616074204445,
            -0.05858728289604187,
            -0.023632396012544632,
            -0.022175418213009834,
            -0.043503399938344955,
            -0.016079029068350792,
            0.021612299606204033,
            0.04425441101193428,
            0.009973003529012203,
            -0.016317248344421387,
            -0.001801539328880608,
            -0.01878027804195881,
            -0.0005836606724187732,
            0.031075509265065193,
            0.02490105852484703,
            0.008733442984521389,
            -0.002819121116772294,
            -0.009466533549129963,
            -0.028071580454707146,
            0.02075071632862091,
            0.00946609117090702,
            0.04403955116868019,
            0.0014253414701670408,
            -0.010318241082131863,
            0.0500120185315609,
            -0.04176228120923042,
            0.0008139722631312907,
            -0.009156354703009129,
            -0.0035666117910295725,
            0.005460704676806927,
            0.015018157660961151,
            0.004033499397337437,
            0.008317580446600914,
            0.009956208989024162,
            0.024746915325522423,
            0.01885014958679676,
            -0.007716011721640825,
            0.01067259255796671,
            -0.0190347321331501,
            0.010754628106951714,
            -0.00818804744631052,
            -0.0023972352501004934,
            -0.0048785521648824215,
            -0.01659144088625908,
            -0.048116691410541534,
            0.025678884238004684,
            -0.06700456142425537,
            -0.016231510788202286,
            -0.04362732917070389,
            0.0003246167616453022,
            0.06110154837369919,
            -0.009881991893053055,
            0.046762894839048386,
            -0.0010390138486400247,
            0.018781349062919617,
            -0.008997038938105106,
            -0.01009455043822527,
            -0.023780304938554764,
            0.015139814466238022,
            0.02445245534181595,
            0.017700355499982834,
            8.068780152825639e-05,
            0.017658233642578125,
            0.007746574003249407,
            0.010554740205407143,
            -0.09100473672151566,
            -0.048666276037693024,
            0.006529597099870443,
            0.04132082313299179,
            0.035798028111457825,
            -0.017194604501128197,
            0.013982884585857391,
            -0.015767831355333328,
            -0.036114130169153214,
            -0.0070376526564359665,
            -0.006775137037038803,
            -0.012668890878558159,
            0.014547445811331272,
            -0.02383066713809967,
            0.0030196397565305233,
            -0.014098971150815487,
            -0.002438047667965293,
            0.008057206869125366,
            -0.03486141562461853,
            -0.006959706544876099,
            0.00877872109413147,
            0.02669704332947731,
            -0.020725326612591743,
            0.027577215805649757,
            0.012518612667918205,
            0.0293903686106205,
            0.0014688906958326697,
            0.0020002909004688263,
            -0.03993993252515793,
            0.023887641727924347,
            -0.005828211084008217,
            -0.019078200682997704,
            0.014066295698285103,
            -0.033257558941841125,
            -0.020979907363653183,
            -0.0011024095583707094,
            0.042869262397289276,
            -0.018007375299930573,
            -0.024221893399953842,
            0.019532103091478348,
            -0.02576611191034317,
            -0.01054446492344141,
            -0.016032880172133446,
            -0.04258197173476219,
            0.009430182166397572,
            0.0034129908308386803,
            -0.008767131716012955,
            0.02387387305498123,
            -0.002084404928609729,
            -0.0037438177969306707,
            0.00010575869237072766,
            -0.0007524461252614856,
            0.030797729268670082,
            0.02277303859591484,
            0.013748081400990486,
            0.012336271815001965,
            -0.014244978316128254,
            0.011663325130939484,
            0.012010006234049797,
            0.07705266773700714,
            -0.0001547331630717963,
            -0.029533499851822853,
            -0.08735576272010803,
            -0.015106035396456718,
            -0.0060207424685359,
            0.02590825967490673,
            -0.029442453756928444,
            -0.028308531269431114,
            -0.05817195400595665,
            0.023938225582242012,
            0.02232617698609829,
            0.03787989169359207,
            -0.0022184234112501144,
            0.007881383411586285,
            -0.0006347379530780017,
            -0.005595618858933449,
            0.025537803769111633,
            -0.026314107701182365,
            0.024966029450297356,
            0.018111323937773705,
            0.0030430476181209087,
            -0.011016091331839561,
            0.021511120721697807,
            0.01765582710504532,
            0.0013441330520436168,
            -0.0068425992503762245,
            0.04111602529883385,
            -0.0035028571728616953,
            -0.0036031005438417196,
            0.0031632801983505487,
            -0.021484488621354103,
            -0.019215472042560577,
            0.0011803203960880637,
            -0.031263407319784164,
            -0.01807119883596897,
            0.019512640312314034,
            0.01712530292570591,
            0.020161228254437447,
            0.001645291456952691,
            -0.03639863431453705,
            0.012721123173832893,
            -0.039711371064186096,
            0.00895204022526741,
            0.014084112830460072,
            0.012152927927672863,
            -0.01388777419924736,
            -0.010172856040298939,
            -0.0235130712389946,
            0.010675671510398388,
            0.015189428813755512,
            0.020150354132056236,
            0.01942797563970089,
            -0.024858299642801285,
            0.03684782609343529,
            -0.025475788861513138,
            -0.04629703238606453,
            0.028707565739750862,
            0.002613096497952938,
            -0.020896241068840027,
            -0.007959676906466484,
            0.005940902512520552,
            0.009426536038517952,
            0.020498543977737427,
            0.03223466873168945,
            0.010398481041193008,
            0.010943409986793995,
            -0.00579215120524168,
            -0.01374319288879633,
            -0.03062296472489834,
            0.011276334524154663,
            -0.02673482894897461,
            -0.04439195990562439,
            -0.05765295773744583,
            0.017069097608327866,
            0.01382409781217575,
            -0.019100308418273926,
            -0.0018209125846624374,
            0.04010217636823654,
            0.0002825005503837019,
            -0.042171698063611984,
            -0.014623589813709259,
            0.03070484660565853,
            -0.00793523620814085,
            -0.009357626549899578,
            -0.06831973791122437,
            -0.00791215430945158,
            0.013448676094412804,
            -0.021885572001338005,
            0.0018219610210508108,
            0.01316243875771761,
            0.00804738700389862,
            0.014151797629892826,
            -0.02183413691818714,
            -0.02659585140645504,
            -0.01708407700061798,
            -0.005644866265356541,
            0.019938858225941658,
            0.018230106681585312,
            -0.020090371370315552,
            0.010415020398795605,
            0.011874380521476269,
            -0.009836992248892784,
            -0.003711284138262272,
            -0.0016351215308532119,
            0.071354940533638,
            -0.034841541200876236,
            0.011534393765032291,
            0.00618105661123991,
            -0.014574872329831123,
            0.03145766630768776,
            -0.01708703674376011,
            -0.022552557289600372,
            0.01310450118035078,
            -0.00018753366020973772,
            -0.012327689677476883,
            0.01739426702260971,
            -0.01888943649828434,
            -0.027227506041526794,
            0.01718740351498127,
            -0.006738153286278248,
            -0.0424240343272686,
            -0.0162198543548584,
            0.023987699300050735,
            0.046602342277765274,
            -0.046479325741529465,
            0.0023616012185811996,
            -0.021912608295679092,
            0.01011999137699604,
            -0.022833924740552902,
            -0.018957022577524185,
            0.007370585575699806,
            0.04160965606570244,
            -0.030001278966665268,
            -0.002900575753301382,
            -0.011328155174851418,
            -0.015042608603835106,
            0.05026738718152046,
            0.007861172780394554,
            -0.011063803918659687,
            -0.0026573515497148037,
            -0.00216089840978384,
            -0.03200259059667587,
            0.019097397103905678,
            0.020805127918720245,
            0.04572383314371109,
            0.009454677812755108,
            -0.008617640472948551,
            -0.0014601469738408923,
            -0.005438269581645727,
            -0.011624187231063843,
            -0.026151621714234352,
            0.04101921617984772,
            0.00846622884273529,
            -0.02553098276257515,
            -0.03537750244140625,
            -0.022749265655875206,
            0.014047108590602875,
            -0.00706727197393775,
            -0.03332012891769409,
            -0.006938005331903696,
            -0.029343381524086,
            -0.05282863602042198,
            -0.00042828317964449525,
            -0.004487613216042519,
            -0.03472577780485153,
            0.0008461577817797661,
            -0.00252191792242229,
            -0.00847434438765049,
            -0.018654722720384598,
            -0.013216337189078331,
            0.009461070410907269,
            0.04412267729640007,
            0.015248902142047882,
            -0.019548121839761734,
            -0.01298031397163868,
            0.014207734726369381,
            -0.023386793211102486,
            0.029396383091807365,
            -0.0023100089747458696,
            -0.003391294041648507,
            0.01288413256406784,
            0.0012223212979733944,
            -0.030711142346262932,
            -0.0010327274212613702,
            0.026810364797711372,
            -0.026981396600604057,
            -0.004524890799075365,
            0.016018096357584,
            -0.01217467151582241,
            -0.008622477762401104,
            -0.018152110278606415,
            0.0005430342862382531,
            0.003388252342119813,
            0.01420228648930788,
            0.0276131983846426,
            0.013258076272904873,
            -0.015310319140553474,
            -0.013543732464313507,
            -0.002279708394780755,
            0.013127078302204609,
            0.03457891196012497,
            -0.00915013812482357,
            0.020702989771962166,
            -0.0004776062269229442,
            0.01628606580197811,
            0.02503107488155365,
            0.010235222056508064,
            -0.008159792050719261,
            -0.006650637835264206,
            0.03073393553495407,
            0.00984245166182518,
            -0.01895585097372532,
            -0.012477824464440346,
            0.0033338558860123158,
            -0.06261837482452393,
            0.04058767482638359,
            0.04977912828326225,
            0.011699317954480648,
            -0.004426162224262953,
            0.012918506748974323,
            -0.00844841543585062,
            -0.00649176724255085,
            0.014699233695864677,
            -0.013851647265255451,
            -0.004860150162130594,
            0.01098817028105259,
            -0.025825323536992073,
            -0.02461007609963417,
            -0.03363336995244026,
            -0.005540437530726194,
            0.0008750856504775584,
            0.004566491115838289,
            -0.01988031715154648,
            0.028171945363283157,
            0.030247719958424568,
            0.04338451102375984,
            -0.023264551535248756,
            -0.0073670088313519955,
            0.00830532144755125,
            0.02993849106132984,
            -0.009746698662638664,
            -0.003255367511883378,
            -0.008980754762887955,
            0.011703401803970337,
            0.03818962723016739,
            -0.02137277089059353,
            -0.0066616651602089405,
            0.03591759502887726,
            -0.0016583348624408245,
            0.030385702848434448,
            -0.014475270174443722,
            -0.027944030240178108,
            -0.018386777490377426,
            0.034184180200099945,
            -0.01116397138684988,
            -0.03734791651368141,
            -0.016428716480731964,
            0.012796256691217422,
            0.02551376447081566,
            0.031948525458574295,
            0.03822938725352287,
            0.0070092747919261456,
            -0.07578680664300919,
            -0.018844230100512505,
            0.023094622418284416,
            0.022116051986813545,
            0.011233116500079632,
            -0.04879121109843254,
            -0.020757947117090225,
            -0.005561858415603638,
            -0.018284693360328674,
            0.013925776816904545,
            0.008575208485126495,
            -0.027728838846087456,
            -0.02523905783891678,
            -0.010142827406525612,
            0.025192832574248314,
            -0.001932544051669538,
            -0.01946217380464077,
            0.010710597969591618,
            0.010906114242970943,
            0.0331035815179348,
            -0.016705963760614395,
            -0.016986839473247528,
            -0.019200362265110016,
            0.017629876732826233,
            0.021882761269807816,
            0.010223142802715302,
            0.011367195285856724,
            -0.031864456832408905,
            0.016666589304804802,
            0.026726676151156425,
            -0.05211514234542847,
            0.007984308525919914,
            0.006678491365164518,
            0.03297540172934532,
            0.01527744997292757,
            -0.021607836708426476,
            -0.01253414060920477,
            -0.05718306452035904,
            0.007689500693231821,
            0.01915028877556324,
            -0.02423197403550148,
            0.004410036839544773,
            -0.03393004462122917,
            -0.01808156445622444,
            -0.012040222994983196,
            0.0333106629550457,
            -0.0220319926738739,
            -0.018526969477534294,
            0.012647013179957867,
            0.006935205776244402,
            -0.028205877169966698,
            0.024930354207754135,
            0.0007172305486164987,
            0.0013506804825738072,
            -0.010741885751485825,
            0.026147348806262016,
            -0.007298709359019995,
            -0.02578074485063553,
            -0.0007738257409073412,
            -0.03488815948367119,
            0.03421276807785034,
            -0.015785904601216316,
            -0.006143998820334673,
            -0.002039356390014291,
            0.0019485463853925467,
            0.016520602628588676,
            -0.007175530306994915,
            0.0050277928821742535,
            -0.0004664052394218743,
            0.014302646741271019,
            0.013821049593389034,
            -0.02642000839114189,
            -0.0541662760078907,
            0.0015442330623045564,
            0.029880791902542114,
            0.03356911614537239,
            0.0365510955452919,
            -0.009438645094633102,
            0.02828717976808548,
            0.0236430112272501,
            0.027459444478154182,
            0.016796190291643143,
            -0.010212861001491547,
            0.0071049160324037075,
            0.003484435612335801,
            0.03697581589221954,
            -0.0031105498783290386,
            -0.03530537709593773,
            0.03369986638426781,
            -0.05976817384362221,
            -0.021674184128642082,
            -0.012500387616455555,
            -0.044644229114055634,
            0.009427165612578392,
            0.014409537427127361,
            -0.027205174788832664,
            -0.0060784704983234406,
            -0.021765699610114098,
            -0.0038042343221604824,
            0.0333111397922039,
            0.01680462621152401,
            0.01186149287968874,
            -0.018604913726449013,
            0.02221602387726307,
            0.02471768856048584,
            0.02441120706498623,
            -0.013887734152376652,
            0.005622855853289366,
            -0.03242334723472595,
            -0.032110195606946945,
            0.0038470763247460127,
            -0.00587170897051692,
            -0.07065419852733612,
            0.008238269947469234,
            0.016962021589279175,
            -0.020251454785466194,
            -0.005636687856167555,
            0.024277202785015106,
            0.018961869180202484,
            0.016814693808555603,
            0.029854105785489082,
            -0.012276924215257168,
            0.0001560861710458994,
            -0.01472486462444067,
            -0.0030442492570728064,
            -0.02466288022696972,
            -0.018960021436214447,
            -0.018109727650880814,
            -0.001315343426540494,
            0.023829009383916855,
            -0.0294070765376091,
            -0.019165070727467537,
            0.06350278109312057,
            0.0029381373897194862,
            -0.0013152249157428741,
            0.03955840691924095,
            0.0001764635817380622,
            -0.02036629244685173,
            -0.038378167897462845,
            0.03565237671136856,
            -0.016981789842247963,
            0.013741246424615383,
            0.02835765667259693,
            -0.022777313366532326,
            -0.02971707656979561,
            0.026850787922739983,
            0.036653511226177216,
            0.02323429100215435,
            -0.016547024250030518,
            -0.030110755935311317,
            -0.014709721319377422,
            -0.007324165664613247,
            0.02358010597527027,
            0.041983675211668015,
            0.022157803177833557,
            -0.046014733612537384,
            -0.016264833509922028,
            -0.014321301132440567,
            -0.04381203651428223,
            0.0033968568313866854,
            0.036556292325258255,
            0.006549989804625511,
            0.011450988240540028,
            -0.019058099016547203,
            0.01920807920396328,
            -0.019532550126314163,
            -0.00694947550073266,
            -0.010056862607598305,
            -0.0019382367609068751,
            -0.02126125432550907,
            -0.007736732251942158,
            0.03343844413757324,
            0.016849162057042122,
            0.008032439276576042,
            0.023150432854890823,
            -0.012793670408427715,
            0.010763805359601974,
            0.015738077461719513,
            0.008772783912718296,
            -0.013253823854029179,
            0.034674372524023056,
            -0.05022255331277847,
            8.921480912249535e-05,
            0.02145220898091793,
            -0.005895097274333239,
            -0.02113562449812889,
            0.019311288371682167,
            0.024689098820090294,
            -0.001707947812974453,
            -0.029490849003195763,
            -0.009027836844325066,
            0.02467930316925049,
            -0.0009277136996388435,
            0.005634280387312174,
            0.02033182419836521,
            0.011427001096308231,
            0.03595585748553276,
            -0.018438544124364853,
            0.017351802438497543,
            -0.021216141059994698,
            0.022518089041113853,
            -0.013701112940907478,
            -0.029959313571453094,
            -0.007393039762973785,
            0.0025532813742756844,
            -0.0198105089366436,
            -0.018266523256897926,
            -0.03910799324512482,
            -0.00883377343416214,
            0.02725628949701786,
            -0.019286859780550003,
            0.023119041696190834,
            -0.020431645214557648,
            0.032766345888376236,
            -0.005018439143896103,
            0.00239797355607152,
            0.01200415100902319,
            -0.024166889488697052,
            0.019983962178230286,
            -0.01579938270151615,
            -0.02399793267250061,
            0.010256913490593433,
            0.01378648355603218,
            -0.02120371349155903,
            -0.02446187287569046,
            -0.01640654169023037,
            0.005677297245711088,
            0.006803881376981735,
            0.00913503672927618,
            -0.016510145738720894,
            -0.003951928112655878,
            -0.014896945096552372,
            0.004972237162292004,
            0.014074290171265602,
            0.027381490916013718,
            0.018306205049157143,
            0.024866605177521706,
            -0.00689464807510376,
            0.017402753233909607,
            0.016932526603341103,
            -0.005514399614185095,
            -0.016558723524212837,
            0.008335454389452934,
            -0.018040362745523453,
            0.02073489874601364,
            -0.017399504780769348,
            -0.0016347210621461272,
            -0.009103912860155106,
            -0.014728866517543793,
            -0.034180860966444016,
            -0.030590474605560303,
            -0.02685518004000187,
            0.0011316213058307767,
            -0.007614906411617994,
            -0.018886785954236984,
            0.01145007461309433,
            -0.0109176617115736,
            -0.002346421591937542,
            -0.08787181228399277,
            -0.00746710691601038,
            -0.02017267234623432,
            -0.008330250158905983,
            -0.0040534669533371925,
            0.027070730924606323,
            0.006472419016063213,
            -0.012006757780909538,
            0.006048076786100864,
            0.018938759341835976,
            0.0036698479671031237,
            0.032376427203416824,
            0.02567163296043873,
            -0.02161959372460842,
            -0.021168623119592667,
            0.0300042275339365,
            0.002828040858730674,
            0.0017413083696737885,
            0.03480115532875061,
            -0.005705215968191624,
            -0.022717954590916634,
            -0.01867874152958393,
            -0.016231505200266838,
            -0.009804443456232548,
            -0.020760545507073402,
            -0.011832267045974731,
            0.020405415445566177,
            -0.061708275228738785,
            -0.025093523785471916,
            -0.033114753663539886,
            -0.04459434375166893,
            -0.07687629759311676,
            -0.019741572439670563,
            0.029082341119647026,
            -0.0015970461536198854,
            -0.009016916155815125,
            -0.0005157645209692419,
            -0.031502265483140945,
            0.0018463855376467109,
            -0.039869826287031174,
            -0.0007825535722076893,
            0.004108495078980923,
            -0.01178513839840889,
            0.03713400661945343,
            0.0021307331044226885,
            0.013715023174881935,
            0.022737883031368256,
            -0.017059043049812317,
            -0.06954124569892883,
            0.0008749772096052766,
            0.026207955554127693,
            -0.03633749485015869,
            0.004544652532786131,
            -0.0023342189379036427,
            0.029312457889318466,
            -0.026626983657479286,
            -0.0030926899053156376,
            -0.017806565389037132,
            0.003396526910364628,
            0.019520550966262817,
            0.018300626426935196,
            0.0004244414158165455,
            0.011331402696669102,
            -0.02872927486896515,
            -0.04761841520667076,
            -0.07779402285814285,
            0.014878385700285435,
            0.02418244257569313,
            0.005110014695674181,
            -0.020972708240151405,
            0.010334060527384281,
            0.010186949744820595,
            0.018013734370470047,
            0.00625165831297636,
            -0.024787642061710358,
            0.014117763377726078,
            0.010807366110384464,
            -0.014130748808383942,
            0.018088826909661293,
            -0.002074270974844694,
            -0.021932773292064667,
            0.0035002075601369143,
            -0.019387658685445786,
            -0.02027238719165325,
            -0.010407825000584126,
            0.02207306958734989,
            0.02043159119784832,
            0.02151024527847767,
            0.006155258044600487,
            0.0026351488195359707,
            -0.008688084781169891,
            -0.044128477573394775,
            0.012609011493623257,
            -0.02007148042321205,
            -0.0052094548009335995,
            -0.011696717701852322,
            0.028575319796800613,
            0.012760546058416367,
            0.0064042010344564915,
            -0.00830694381147623,
            -3.7109417462488636e-05,
            0.029141327366232872,
            -0.06860698759555817,
            -0.022980978712439537,
            -0.030529150739312172,
            0.0013362409081310034,
            0.010195108130574226,
            -0.009329680353403091,
            -0.00282203103415668,
            -0.025711366906762123,
            -0.02246992290019989,
            0.003405705327168107,
            0.02829628437757492,
            -0.0173037052154541,
            0.02846510335803032,
            -0.0028457678854465485,
            -0.02460174448788166,
            0.009669329971075058,
            0.011484834365546703,
            -0.017319919541478157,
            0.017201581969857216,
            -1.012700977298664e-05,
            -0.02548055164515972,
            -0.04354984313249588,
            0.022435713559389114,
            -0.017117807641625404,
            -0.0018858258845284581,
            -0.008988342247903347,
            0.02973237633705139,
            -0.013651535846292973,
            0.005800907034426928,
            -0.1131962239742279,
            -0.009507274255156517,
            0.020356524735689163,
            -0.020087623968720436,
            -0.00978255458176136,
            -0.038421373814344406,
            -0.00020538386888802052,
            0.020221209153532982,
            -0.0037226122803986073,
            -0.022410515695810318,
            -0.009336238726973534,
            -0.03187314420938492,
            -0.011580429971218109,
            -0.003945973236113787,
            0.007080624345690012,
            -0.017788203433156013,
            0.0004595216305460781,
            -0.022668205201625824,
            -0.03347796946763992,
            -0.010217090137302876,
            -0.021480180323123932,
            -0.03561028838157654,
            0.002783915027976036,
            0.011257996782660484,
            0.007898634299635887,
            0.03335357829928398,
            -0.03215821087360382,
            0.019317587837576866,
            0.003195171244442463,
            0.022586174309253693,
            -0.020180776715278625,
            -0.03418172150850296,
            -0.004192154388874769,
            -0.03586587309837341,
            0.011659638024866581,
            -0.017732979729771614,
            0.03236759826540947,
            -0.08396108448505402,
            0.025141118094325066,
            -0.001961491769179702,
            -0.005078746471554041,
            0.009758826345205307,
            0.014487814158201218,
            -0.03019522875547409,
            -0.03174499422311783,
            0.015047186985611916,
            0.033653367310762405,
            0.019326942041516304,
            -0.020560121163725853,
            -0.03042515181005001,
            0.01951281540095806,
            -0.006038278806954622,
            -0.03393028303980827,
            -0.0021576806902885437,
            0.018081119284033775,
            0.006433501373976469,
            0.01555805467069149,
            0.028685370460152626,
            -0.03785174340009689,
            -0.008732788264751434,
            0.011987942270934582,
            -0.01430648472160101,
            0.006704105529934168,
            0.022919490933418274,
            -0.0018674918683245778,
            -0.015574134886264801,
            0.0045705316588282585,
            -0.03195922076702118,
            -0.02292526885867119,
            0.009065588936209679,
            0.004345476161688566,
            0.034734565764665604,
            -0.01812726818025112,
            -0.07083971053361893,
            0.0193075779825449,
            0.022800248116254807,
            -0.006556392181664705,
            -0.024756697937846184,
            0.02724832110106945,
            0.027484284713864326,
            0.012264159508049488,
            -0.07621733099222183,
            -0.014240690506994724,
            9.769725875230506e-05,
            -0.03915737196803093,
            -0.08619964122772217,
            0.004640445578843355,
            0.022587936371564865,
            0.010714201256632805,
            0.010129130445420742,
            -0.03129556030035019,
            -0.028130408376455307,
            -0.02649988792836666,
            0.007330494932830334,
            0.019517550244927406,
            0.015950800850987434,
            0.003512326627969742,
            -0.011164114810526371,
            -0.03345021605491638,
            -0.05346248298883438,
            0.004331252072006464,
            0.03129974380135536,
            0.007751827593892813,
            0.04495885223150253,
            -0.004088072571903467,
            -0.014570672996342182,
            0.014350933022797108,
            -0.005859316326677799,
            0.01229947805404663,
            0.0038290845695883036,
            0.0021907987538725138,
            0.03850581869482994,
            -0.012006280943751335,
            -0.012325257994234562,
            0.0014903214760124683,
            0.017376383766531944,
            0.01273830235004425,
            -0.014056922867894173,
            -0.0012990018585696816,
            -0.004877621307969093,
            0.003996563609689474,
            -0.047971051186323166,
            -0.010969896800816059,
            -0.046092841774225235,
            0.03239836543798447,
            0.03372135013341904,
            0.024950562044978142,
            -0.012770588509738445,
            0.004291106481105089,
            0.017570553347468376,
            -0.049740929156541824,
            -0.023893093690276146,
            -0.013794250786304474,
            0.017172113060951233,
            0.0194083359092474,
            -0.03931400179862976,
            -0.010159650817513466,
            0.0054054949432611465,
            0.003266989253461361,
            0.010653018951416016,
            -0.004253509920090437,
            0.02028721570968628,
            -0.014922824688255787,
            -0.01294267550110817,
            -0.025911390781402588,
            -0.017453966662287712,
            -0.00423000892624259
        ],
        [
            -0.008291180245578289,
            -0.05065225064754486,
            -0.011130418628454208,
            0.008107202127575874,
            0.008310508914291859,
            0.054920755326747894,
            -0.007757759653031826,
            0.051149073988199234,
            -0.019082872197031975,
            -0.017656436190009117,
            0.0018216465832665563,
            -0.01817757450044155,
            0.02784132771193981,
            -0.02803950011730194,
            0.00035334372660145164,
            0.04655265063047409,
            -0.0226180050522089,
            0.025984955951571465,
            0.04185634106397629,
            -0.01099656242877245,
            0.011053360067307949,
            -0.00065170245943591,
            0.006308861542493105,
            -0.038857351988554,
            0.0078110950998961926,
            -0.01043650135397911,
            -0.013477493077516556,
            0.017629019916057587,
            -0.004560962785035372,
            0.00561867468059063,
            0.018529264256358147,
            0.031045960262417793,
            -0.01287660002708435,
            -0.006948524154722691,
            0.0005918879760429263,
            -0.006027495488524437,
            -0.014303216710686684,
            -0.013788582757115364,
            0.015813367441296577,
            0.02845177799463272,
            0.04131036996841431,
            0.028203237801790237,
            -0.02343454211950302,
            0.014523171819746494,
            0.023517578840255737,
            -0.011772646568715572,
            -0.041499871760606766,
            -0.034196991473436356,
            -0.01181594654917717,
            -0.0017370119458064437,
            -0.014906840398907661,
            0.03547224402427673,
            -0.029755011200904846,
            -0.0015403827419504523,
            -0.03649124503135681,
            0.01670609787106514,
            -0.005554931703954935,
            0.021197199821472168,
            -0.03042708709836006,
            0.027122823521494865,
            0.01482731569558382,
            0.0182690117508173,
            0.019794350489974022,
            -0.012018205597996712,
            -0.021012261509895325,
            0.027141964063048363,
            -0.0016443837666884065,
            -0.02537207305431366,
            0.024094652384519577,
            0.010625320486724377,
            0.016057534143328667,
            0.024177351966500282,
            -0.010887129232287407,
            -0.03390137106180191,
            -0.036098405718803406,
            0.005485987290740013,
            0.016103049740195274,
            0.03915294259786606,
            -0.014962068758904934,
            -0.01578015461564064,
            0.02075417898595333,
            -0.015630053356289864,
            -0.028344253078103065,
            -0.01228085346519947,
            0.02810950204730034,
            0.00916599202901125,
            -0.030268419533967972,
            -0.011829963885247707,
            -0.00035549397580325603,
            -0.0005656526191160083,
            -0.014416353777050972,
            0.012934251688420773,
            0.008623636327683926,
            0.020225366577506065,
            -0.0024702304508537054,
            0.0029887398704886436,
            0.0105581134557724,
            0.04080010578036308,
            -0.03131011128425598,
            -0.007828672416508198,
            -0.024835610762238503,
            0.019633105024695396,
            -0.03812241554260254,
            -0.005798868369311094,
            0.008476749062538147,
            0.023890702053904533,
            0.023825736716389656,
            0.018969379365444183,
            -0.01661047525703907,
            0.012461623176932335,
            -0.02186645194888115,
            0.024141380563378334,
            -0.006041424814611673,
            0.012478338554501534,
            -0.028593238443136215,
            0.0035916813649237156,
            -0.020417245104908943,
            0.002879527397453785,
            0.016149278730154037,
            0.029682602733373642,
            -0.023957448080182076,
            -0.020307976752519608,
            0.031264424324035645,
            0.01071220077574253,
            -0.026969507336616516,
            -0.0072292666882276535,
            0.0467967689037323,
            -0.014547926373779774,
            0.009365593083202839,
            -0.019385309889912605,
            0.023581165820360184,
            0.026485644280910492,
            0.025769561529159546,
            0.02127738483250141,
            0.031518153846263885,
            0.015447195619344711,
            -0.010154678486287594,
            -0.015802381560206413,
            0.004929045680910349,
            -0.024818720296025276,
            -0.020800575613975525,
            -0.0032186447642743587,
            -0.008356483653187752,
            -0.029402170330286026,
            -0.04690920561552048,
            0.028249524533748627,
            0.02591363899409771,
            -0.021123578771948814,
            -0.02741812728345394,
            0.009464644826948643,
            -0.015462755225598812,
            -0.006386332679539919,
            0.00018073910905513912,
            0.0005556425312533975,
            0.02201760932803154,
            -0.020754599943757057,
            -0.025978779420256615,
            0.021631786599755287,
            0.008809171617031097,
            -0.02050698921084404,
            0.02621445432305336,
            0.0421442911028862,
            0.01360616646707058,
            -0.012394275516271591,
            0.03372655063867569,
            0.03141992539167404,
            0.0502069890499115,
            0.04116983711719513,
            0.0431363582611084,
            0.003827763721346855,
            0.027323739603161812,
            0.030425652861595154,
            0.017535710707306862,
            0.0064040240831673145,
            0.04184596240520477,
            0.017467960715293884,
            -0.025742238387465477,
            0.019279781728982925,
            0.046820078045129776,
            0.02916848100721836,
            -0.012545912526547909,
            -0.02573264390230179,
            0.035570722073316574,
            0.015307034365832806,
            0.009549121372401714,
            -0.02565508894622326,
            -0.034314002841711044,
            0.004176445305347443,
            0.024776507169008255,
            -0.018896279856562614,
            0.03224755451083183,
            0.04861997440457344,
            -0.023514186963438988,
            0.01806769147515297,
            -0.013266772031784058,
            0.0019555469043552876,
            0.010486654937267303,
            -0.001258260104805231,
            -0.004521662835031748,
            0.02536793053150177,
            0.0007742831367067993,
            -0.009822316467761993,
            -0.061433032155036926,
            -0.016963351517915726,
            0.046909309923648834,
            -0.027130255475640297,
            0.027438625693321228,
            0.035311009734869,
            0.003732870100066066,
            0.029283134266734123,
            0.009586307220160961,
            -0.03300757706165314,
            0.015617355704307556,
            -0.02348805032670498,
            -0.029474295675754547,
            -0.009115984663367271,
            0.005755578633397818,
            0.01623118668794632,
            0.01739845611155033,
            0.009412300772964954,
            0.01411260012537241,
            0.023935049772262573,
            -0.0500100702047348,
            -0.006465303245931864,
            -0.01455019786953926,
            0.06809154152870178,
            -0.006479605566710234,
            0.011065281927585602,
            0.03736580163240433,
            -0.013928367756307125,
            -0.01653749868273735,
            0.04422625154256821,
            -0.03172611445188522,
            0.024050844833254814,
            0.007197913713753223,
            -0.02618199959397316,
            -0.02610533870756626,
            -0.017626933753490448,
            -0.02927055023610592,
            0.01046718005090952,
            0.022351156920194626,
            0.012554323300719261,
            0.00986761786043644,
            -0.011469312943518162,
            0.016594789922237396,
            -0.007878857664763927,
            -0.02609836496412754,
            -0.02690487913787365,
            0.01619037613272667,
            0.0016100731445476413,
            -0.008381525054574013,
            0.0033796727657318115,
            0.009667783975601196,
            -0.020502854138612747,
            -8.476208313368261e-05,
            -0.012822967953979969,
            0.0008440841920673847,
            0.040877360850572586,
            0.06303396821022034,
            -0.009639378637075424,
            -0.010505356825888157,
            0.035525377839803696,
            0.012219434604048729,
            -0.008711940608918667,
            -0.0024844249710440636,
            0.0321170911192894,
            0.020532015711069107,
            -0.0014538478571921587,
            -0.008398925885558128,
            0.008322153240442276,
            -0.023954737931489944,
            -0.03456676006317139,
            0.00921354629099369,
            0.006462919060140848,
            0.010928621515631676,
            0.002269216114655137,
            0.030933713540434837,
            0.017068641260266304,
            0.02858070470392704,
            -0.04267358407378197,
            -0.035378556698560715,
            -0.002259124768897891,
            0.015098413452506065,
            -0.012295429594814777,
            0.008355625905096531,
            -0.026925059035420418,
            0.018545346334576607,
            -0.00046397180994972587,
            -0.007997604086995125,
            0.029264340177178383,
            -0.02239231765270233,
            0.03277977183461189,
            -0.0115788159891963,
            0.008346404880285263,
            -0.007086966186761856,
            0.04937422648072243,
            -0.01762818917632103,
            -0.041923247277736664,
            0.005582562647759914,
            0.028016135096549988,
            -0.031837426126003265,
            0.009674753062427044,
            -0.04457547888159752,
            -0.025757765397429466,
            0.0022072254214435816,
            0.030275143682956696,
            0.018007539212703705,
            0.001520425546914339,
            -0.007286352105438709,
            0.033461712300777435,
            -0.034653957933187485,
            -0.010685227811336517,
            0.0028405121993273497,
            0.015638308599591255,
            0.014759081415832043,
            -0.012978017330169678,
            0.029931221157312393,
            -0.007781560532748699,
            0.007184134796261787,
            -0.000358582881744951,
            -0.015350899659097195,
            -0.012646538205444813,
            0.014516557566821575,
            0.05763164535164833,
            -0.023417405784130096,
            -0.0070085180923342705,
            0.018663650378584862,
            -0.005977793131023645,
            0.026016129180788994,
            0.0025663739070296288,
            0.03510165587067604,
            -0.004907223861664534,
            -0.029012223705649376,
            0.017470708116889,
            0.015471048653125763,
            -0.006581855472177267,
            0.002245434559881687,
            0.002180845243856311,
            0.028564665466547012,
            0.01218349114060402,
            -0.011170492507517338,
            0.025650104507803917,
            -0.020651785656809807,
            0.017238005995750427,
            -0.020471835508942604,
            0.00041048083221539855,
            -0.006500009912997484,
            0.023294789716601372,
            -0.005620467476546764,
            -0.0010870170081034303,
            0.02936840057373047,
            -0.013041968457400799,
            -0.00020951218903064728,
            -0.029399292543530464,
            0.05426942929625511,
            0.010462671518325806,
            0.0017673048423603177,
            0.011623713187873363,
            0.027270488440990448,
            -0.03311469778418541,
            -0.011368726380169392,
            -0.009018371812999249,
            -0.05310700461268425,
            0.011463681235909462,
            0.0389128215610981,
            -0.007799550890922546,
            -0.02246090956032276,
            0.012777378782629967,
            0.02417551726102829,
            0.026876023039221764,
            -0.0017589697381481528,
            -0.011382787488400936,
            0.000897380115929991,
            0.009182140231132507,
            -0.03188853710889816,
            -0.012271636165678501,
            -0.004392293281853199,
            0.012693282216787338,
            -0.008981844410300255,
            -0.031663645058870316,
            -0.05116426944732666,
            -0.011722704395651817,
            -0.009922736324369907,
            -0.014770026318728924,
            0.029631800949573517,
            -0.004872655961662531,
            0.019861768931150436,
            0.017502563074231148,
            0.005252317059785128,
            -0.006598302163183689,
            -0.001921874238178134,
            0.0035077237989753485,
            -0.007243633735924959,
            0.015476563014090061,
            0.009590850211679935,
            0.03349125757813454,
            0.019289517775177956,
            -0.015186050906777382,
            0.013572612777352333,
            0.04296768084168434,
            -0.00767709594219923,
            0.0329848974943161,
            -0.005712623707950115,
            -0.03752976655960083,
            -0.01859651319682598,
            -0.00012632804282475263,
            -0.033250898122787476,
            0.027421239763498306,
            0.012111902236938477,
            -0.014418082311749458,
            0.03911452740430832,
            0.029873739928007126,
            0.011976830661296844,
            -0.017865987494587898,
            -0.04013361781835556,
            0.02020202949643135,
            0.03588961809873581,
            -0.0184812992811203,
            0.03630657494068146,
            -0.029498839750885963,
            -0.03143717721104622,
            -0.001772019430063665,
            -0.02291060984134674,
            0.01862243190407753,
            -0.017273783683776855,
            0.06665226072072983,
            0.04836378991603851,
            0.021245034411549568,
            -0.018161499872803688,
            -0.028670622035861015,
            0.021286752074956894,
            -0.00462171109393239,
            0.0320720374584198,
            -0.016265425831079483,
            0.0007533940952271223,
            -0.0049446928314864635,
            0.04930058494210243,
            0.007820320315659046,
            -0.015003782697021961,
            -0.010168639943003654,
            0.013297125697135925,
            0.03214365243911743,
            0.03327197954058647,
            0.02359095774590969,
            0.01445088256150484,
            -0.0025439856108278036,
            -0.012980473227798939,
            0.015981601551175117,
            0.03128237649798393,
            0.0069249472580850124,
            0.028312085196375847,
            0.01988128572702408,
            -0.03272135183215141,
            0.003510150359943509,
            -0.016596166417002678,
            -0.017397155985236168,
            0.014073542319238186,
            -0.007190657779574394,
            -0.03012118674814701,
            -0.02249320037662983,
            0.03864315524697304,
            -0.031700968742370605,
            0.00470230495557189,
            0.05336649715900421,
            -0.0014962624991312623,
            -0.0003528055385686457,
            0.029758846387267113,
            0.009228506125509739,
            0.0060550011694431305,
            -0.017444849014282227,
            -0.022299712523818016,
            -0.01891876384615898,
            0.015429308637976646,
            -0.004586898721754551,
            0.011323882266879082,
            0.005595444701611996,
            0.018990429118275642,
            0.029113586992025375,
            0.025297770276665688,
            -0.012058963067829609,
            0.003237832570448518,
            -0.016795014962553978,
            0.019203506410121918,
            0.0046133543364703655,
            -0.01573622040450573,
            0.029543550685048103,
            0.004428081680089235,
            -0.010554340668022633,
            -0.03609584644436836,
            0.0024478251580148935,
            -0.011660309508442879,
            0.013811799697577953,
            0.028920572251081467,
            -0.007720578461885452,
            0.026619015261530876,
            0.031631164252758026,
            -0.00044826249359175563,
            0.025221413001418114,
            0.002735828747972846,
            -0.011877533048391342,
            -0.03772106394171715,
            0.014473631978034973,
            0.011784803122282028,
            -0.01833239756524563,
            0.0006160331540741026,
            -0.026226555928587914,
            -0.02101471833884716,
            0.008615988306701183,
            0.009297582320868969,
            -0.018048785626888275,
            0.028879469260573387,
            0.01497839204967022,
            -0.03814015910029411,
            -0.014358233660459518,
            -0.01889476552605629,
            -0.00693903025239706,
            0.0313347689807415,
            -0.013425661250948906,
            -0.028213508427143097,
            -2.4850978661561385e-05,
            -0.033423036336898804,
            0.0168475192040205,
            0.028061317279934883,
            -0.008506812155246735,
            -0.003285482060164213,
            -0.05882451683282852,
            0.06391140073537827,
            0.01557788997888565,
            -0.005685869138687849,
            0.0266379714012146,
            -0.01144347433000803,
            0.032370664179325104,
            0.012695170007646084,
            0.01669722981750965,
            -0.018122946843504906,
            0.00706652645021677,
            -0.028614936396479607,
            -0.0022343634627759457,
            0.009342875331640244,
            0.024945907294750214,
            0.03433964401483536,
            0.0321076475083828,
            0.00537671335041523,
            0.046039458364248276,
            0.007402600254863501,
            -0.0034110259730368853,
            -0.019019361585378647,
            -0.009310038760304451,
            -0.024595651775598526,
            0.0048118457198143005,
            0.016706349328160286,
            0.004538644105195999,
            -0.008570609614253044,
            -0.023417390882968903,
            0.0034716338850557804,
            0.010765567421913147,
            -0.019692329689860344,
            -0.029043246060609818,
            0.010601728223264217,
            0.005182905588299036,
            0.002709784312173724,
            0.011275772005319595,
            0.023855607956647873,
            0.007740761619061232,
            -0.002817345317453146,
            0.026434654369950294,
            -0.008438345044851303,
            0.01646537333726883,
            0.0012655929895117879,
            0.005368734709918499,
            -0.0007350756786763668,
            -0.0264379121363163,
            0.009798555634915829,
            0.022625362500548363,
            -0.017688224092125893,
            0.012904481031000614,
            0.01753764972090721,
            0.013255988247692585,
            0.009835093282163143,
            -0.022381700575351715,
            0.015249939635396004,
            0.01604391448199749,
            -0.0010796155547723174,
            0.027122827246785164,
            -0.004887912422418594,
            -0.04445773735642433,
            -0.014906511642038822,
            0.014937780797481537,
            0.0033759300131350756,
            -0.019618500024080276,
            -0.020924704149365425,
            0.012507397681474686,
            0.017524294555187225,
            0.017561709508299828,
            0.0013399330200627446,
            -0.022994916886091232,
            -0.01441175676882267,
            0.03242870420217514,
            -0.03553816303610802,
            0.012403516098856926,
            -0.02104959264397621,
            -0.005957701243460178,
            -0.020472601056098938,
            0.02289239689707756,
            -0.030580749735236168,
            0.0031199234072118998,
            0.02628408372402191,
            0.022790763527154922,
            0.02184617519378662,
            -0.023576317355036736,
            0.0261139664798975,
            0.019979476928710938,
            0.01900653727352619,
            -0.02081332542002201,
            0.017998619005084038,
            0.04563643038272858,
            0.02835295908153057,
            -0.01727490872144699,
            0.03114989772439003,
            0.04775519296526909,
            -0.005431200843304396,
            0.010344789363443851,
            -0.023138314485549927,
            -0.019372444599866867,
            0.006877738516777754,
            0.03743945434689522,
            -0.01508705597370863,
            0.0009629271226003766,
            -0.006183945573866367,
            -0.013750649057328701,
            0.0017994343070313334,
            0.009316117502748966,
            0.005265711806714535,
            0.02330603264272213,
            0.003261178033426404,
            -0.040983136743307114,
            -0.02760297991335392,
            -0.04713559150695801,
            -0.03628093749284744,
            -0.020412875339388847,
            0.012696195393800735,
            0.0455913171172142,
            0.011127069592475891,
            0.017543599009513855,
            0.017581019550561905,
            0.04805796593427658,
            -0.052196841686964035,
            0.012736735865473747,
            0.017715375870466232,
            -0.013078917749226093,
            -0.031539253890514374,
            0.01485416665673256,
            -0.014845154248178005,
            -0.02386993169784546,
            0.0013937903568148613,
            0.0005445035640150309,
            4.0577717300038785e-05,
            -0.006064622197300196,
            -0.012912184931337833,
            0.0107787000015378,
            0.007527470588684082,
            0.04159872606396675,
            0.026872428134083748,
            -0.01821289211511612,
            -0.005931545980274677,
            0.0066777183674275875,
            -0.008223533630371094,
            -0.012894940562546253,
            0.006824917159974575,
            -0.03984440118074417,
            0.013962485827505589,
            0.05614977329969406,
            -0.035356007516384125,
            -0.027981296181678772,
            0.02131512574851513,
            -0.027905847877264023,
            0.03195820748806,
            0.006499451119452715,
            -0.040710918605327606,
            0.02912081964313984,
            0.005748421885073185,
            0.02607513777911663,
            0.00853740330785513,
            -0.004175328649580479,
            0.02620578557252884,
            -0.02028794027864933,
            0.017764119431376457,
            -0.01943664439022541,
            -0.01462301705032587,
            0.007539690937846899,
            0.017223618924617767,
            -0.02443542145192623,
            0.031043941155076027,
            0.029657870531082153,
            0.02201923541724682,
            0.006016332656145096,
            0.021496962755918503,
            -0.009635686874389648,
            0.01817474514245987,
            0.002838721964508295,
            0.02312587946653366,
            -0.004321410320699215,
            0.012892520986497402,
            -0.0003305701829958707,
            0.004577611107379198,
            -0.03764504939317703,
            -0.029482929036021233,
            -0.013677236624062061,
            -0.014677859842777252,
            0.018008286133408546,
            -0.02555098757147789,
            -0.005786027759313583,
            -0.003780183382332325,
            -0.017643315717577934,
            0.0005979900597594678,
            -0.005278387572616339,
            0.04668986052274704,
            -0.039422888308763504,
            0.002032236196100712,
            0.0028533502481877804,
            -0.00852267723530531,
            0.006162878125905991,
            0.008193706162273884,
            -0.03812620788812637,
            0.02490273118019104,
            -0.009904389269649982,
            0.029399657621979713,
            -0.006078722886741161,
            -0.02655676379799843,
            0.007029374595731497,
            0.01476032380014658,
            0.011127125471830368,
            -0.0209902822971344,
            0.02223997935652733,
            -0.02070918120443821,
            0.016075782477855682,
            0.004296279978007078,
            -0.01298778597265482,
            -0.06491648405790329,
            0.008425726555287838,
            0.019031241536140442,
            -0.0142501350492239,
            0.0007644533761776984,
            0.02082863263785839,
            -0.030056476593017578,
            -0.015324829146265984,
            -0.0013683194993063807,
            -0.0057494970969855785,
            -0.02293587289750576,
            0.005847195629030466,
            0.02955319918692112,
            -0.002840478904545307,
            -0.01233572605997324,
            -0.011407921090722084,
            -0.02152036875486374,
            -0.013380294665694237,
            -0.01668708771467209,
            0.03806072100996971,
            -5.704474097001366e-05,
            0.02169596031308174,
            0.005222517065703869,
            0.00829769391566515,
            -0.005128700286149979,
            0.033119238913059235,
            0.016711652278900146,
            0.01517814863473177,
            -0.02073991671204567,
            0.030778123065829277,
            -0.023257119581103325,
            0.018021918833255768,
            -0.006929607596248388,
            -0.028291380032896996,
            -0.03253759443759918,
            -0.01900906302034855,
            -0.02387709729373455,
            0.016148515045642853,
            -0.0077249775640666485,
            0.04430516064167023,
            0.002244232688099146,
            0.005586541257798672,
            0.002974850358441472,
            0.027048248797655106,
            0.017760830000042915,
            -0.003984780516475439,
            -0.0030059439595788717,
            -0.017189564183354378,
            -0.03635312244296074,
            0.019973428919911385,
            -0.007690912112593651,
            0.007661791518330574,
            -0.03879984840750694,
            -0.020475173369050026,
            -0.03356549143791199,
            0.011362146586179733,
            -0.025669557973742485,
            0.012313120998442173,
            0.023868756368756294,
            -0.025680409744381905,
            0.011127788573503494,
            0.03853904455900192,
            0.02427215687930584,
            -0.017323704436421394,
            0.04423775523900986,
            -0.05111366882920265,
            -0.006766124162822962,
            -0.016319328919053078,
            0.022390395402908325,
            -0.022944102063775063,
            -0.0007818501908332109,
            0.013136866502463818,
            0.01267456728965044,
            -0.03589993715286255,
            -0.010250655934214592,
            0.003362732008099556,
            -0.026629531756043434,
            0.028014224022626877,
            0.022293835878372192,
            -0.0013179846573621035,
            0.012688598595559597,
            0.011943570338189602,
            0.03022840991616249,
            0.0129264360293746,
            -0.014834553934633732,
            -0.03247179463505745,
            0.00940802600234747,
            0.010014323517680168,
            0.036921270191669464,
            -0.03295949846506119,
            -0.023962460458278656,
            -0.0106134582310915,
            0.007178053259849548,
            0.011600303463637829,
            -0.02238379791378975,
            -0.03339865803718567,
            0.022786451503634453,
            -0.0004088632995262742,
            0.01445403415709734,
            -0.002945917658507824,
            0.017084095627069473,
            -0.010538473725318909,
            -0.009580315090715885,
            -0.025900540873408318,
            -0.01410624198615551,
            0.04441172629594803,
            -0.008774396032094955,
            0.01668565161526203,
            0.006548634730279446,
            0.024135585874319077,
            0.03545137122273445,
            0.020087633281946182,
            0.020514780655503273,
            -0.030017858371138573,
            0.05641013756394386,
            0.008052894845604897,
            -0.023434896022081375,
            -0.03503495082259178,
            -0.01961182802915573,
            0.028302522376179695,
            0.03509026765823364,
            -0.01016877219080925,
            0.014251490123569965,
            -0.007039058022201061,
            0.005402207374572754,
            0.010716266930103302,
            0.02973213978111744,
            -0.018663274124264717,
            0.023631492629647255,
            0.008316028863191605,
            -0.005408240016549826,
            0.009463378228247166,
            -0.045601002871990204,
            -0.029538514092564583,
            0.02929871529340744,
            -0.0017564079025760293,
            0.02420164830982685,
            -0.0412125363945961,
            0.010725201107561588,
            0.006222462747246027,
            0.004763271193951368,
            0.016098111867904663,
            -0.04217895492911339,
            -0.03818878158926964,
            -0.026350390166044235,
            0.027210896834731102,
            0.0019843759946525097,
            -0.018884819000959396,
            0.002787459408864379,
            -0.023113612085580826,
            -0.01961223967373371,
            -0.00129392696544528,
            0.006193357985466719,
            -0.01448500994592905,
            0.041424162685871124,
            -0.04669372737407684,
            0.007526952773332596,
            0.025325246155261993,
            -0.004979225341230631,
            0.009107330814003944,
            -0.024376701563596725,
            0.020629044622182846,
            -0.015156922861933708,
            -0.024066489189863205,
            -0.026299577206373215,
            0.011382892727851868,
            0.02713118866086006,
            -0.029997779056429863,
            -0.01190869975835085,
            0.049558915197849274,
            0.01873442530632019,
            0.012071564793586731,
            -0.01104316208511591,
            0.016709186136722565,
            -0.03928622603416443,
            0.04708808287978172,
            -0.06171838194131851,
            -0.013281287625432014,
            -0.017337558791041374,
            0.024568652734160423,
            -0.03407839313149452,
            0.014731697738170624,
            -0.005628654733300209,
            -0.0017275839345529675,
            -0.016289202496409416,
            0.007730268407613039,
            0.019986458122730255,
            0.010808457620441914,
            -0.011533067561686039,
            -0.023479612544178963,
            0.01185424905270338,
            -0.015449872240424156,
            -0.0031832116656005383,
            -0.01629379764199257,
            -0.009507649578154087,
            -0.010981491766870022,
            0.024313300848007202,
            -0.03491762652993202,
            -0.006905837915837765,
            -0.01274755410850048,
            -0.008119354955852032,
            0.022050512954592705,
            0.02423042617738247,
            0.007874242030084133,
            -0.045657653361558914,
            -0.01930650696158409,
            -0.00862276740372181,
            0.007042689248919487,
            -0.022135533392429352,
            -0.05429087206721306,
            0.035408761352300644,
            -0.016153544187545776,
            0.03627922013401985,
            0.015302084386348724,
            -0.03198177367448807,
            0.019326984882354736,
            0.015852229669690132,
            -0.008061557076871395,
            -0.018071433529257774,
            -0.022717338055372238,
            -0.011323533020913601,
            -0.048817213624715805,
            -0.032594338059425354,
            0.01422075740993023,
            -0.019655518233776093,
            -0.0047136396169662476,
            -0.03575122356414795,
            -0.00016899470938369632,
            -0.012881333008408546,
            0.01923077180981636,
            0.038802847266197205,
            0.01728825643658638,
            0.029090387746691704,
            -0.021312881261110306,
            0.0049547296948730946,
            0.0166345052421093,
            0.015248720534145832,
            -0.023469574749469757,
            0.025327108800411224,
            0.02704949490725994,
            -0.02123168669641018,
            0.01814054511487484,
            0.012747950851917267,
            0.0008654710836708546,
            -0.00969446636736393,
            -0.0011495191138237715,
            0.0009901197627186775,
            -0.024952096864581108,
            0.03625241667032242,
            -0.035899825394153595,
            -0.027903812006115913,
            -0.02344171516597271,
            -0.020580828189849854,
            0.008899590000510216,
            -0.01916475221514702,
            0.018164483830332756,
            -0.019337696954607964,
            0.01184812281280756,
            0.04426667466759682,
            -0.016484320163726807,
            -0.051792941987514496,
            -0.020858559757471085,
            0.023052701726555824,
            0.006424336228519678,
            0.031118499115109444,
            -0.003799273632466793,
            0.024536123499274254,
            0.021192841231822968,
            0.01895810477435589,
            -0.0011021256214007735,
            0.013813271187245846,
            0.02396877482533455,
            0.026309438049793243,
            0.01007787324488163,
            0.03645128756761551,
            -0.02903766557574272,
            -0.011306882835924625,
            -0.03542352467775345,
            -0.01125454530119896,
            0.004911310505121946,
            0.005496426019817591,
            -0.020441288128495216,
            -0.014597211964428425,
            0.0015373585047200322,
            0.02665468491613865,
            0.01089068315923214
        ],
        [
            -0.025936339050531387,
            0.00515802251175046,
            0.04148922488093376,
            0.028081132099032402,
            -0.013985618948936462,
            -0.026355409994721413,
            -0.01268935389816761,
            0.011387458071112633,
            -0.003060455434024334,
            -0.0076454998925328255,
            -0.057999137789011,
            -0.02836204133927822,
            -0.03199514374136925,
            -0.03638077527284622,
            -0.03491988405585289,
            -0.00917799212038517,
            0.013335148803889751,
            0.024303851649165154,
            0.005223018117249012,
            0.02014153264462948,
            -0.00720458896830678,
            0.012086538597941399,
            0.04265322536230087,
            -0.024697596207261086,
            0.023169705644249916,
            0.02844293788075447,
            0.017817644402384758,
            -0.0014693817356601357,
            0.02917436696588993,
            -0.021625706925988197,
            0.024995816871523857,
            0.023872191086411476,
            -0.013502061367034912,
            0.03009742684662342,
            0.002552503952756524,
            0.02603672444820404,
            0.035789214074611664,
            -0.01773015782237053,
            -0.0013482961803674698,
            -0.014155788347125053,
            0.010544036515057087,
            0.022904176265001297,
            -0.03649877384305,
            -0.0150180384516716,
            0.01581456884741783,
            -0.031009990721940994,
            0.019686605781316757,
            0.006112577393651009,
            0.012375091202557087,
            -0.03186342120170593,
            -0.021546855568885803,
            -0.008311551995575428,
            0.01179491262882948,
            -0.018885159865021706,
            -0.013981756754219532,
            -7.279576948349131e-06,
            0.0009227990522049367,
            0.014641892164945602,
            -0.01267454493790865,
            -0.007391464430838823,
            0.018129507079720497,
            0.030973142012953758,
            -0.008435690775513649,
            0.006661996711045504,
            -0.02735745720565319,
            0.0034274784848093987,
            -0.0069596171379089355,
            0.020777320489287376,
            -0.010903330519795418,
            0.028203165158629417,
            0.03390448912978172,
            0.028677258640527725,
            0.028737947344779968,
            -0.019030746072530746,
            -0.04156021401286125,
            0.013016883283853531,
            -0.0017247078940272331,
            -0.02074330672621727,
            -0.021912362426519394,
            0.016801273450255394,
            -0.017040282487869263,
            0.019261259585618973,
            -0.024666788056492805,
            0.010084537789225578,
            0.013692470267415047,
            0.020439961925148964,
            -0.02696087956428528,
            -0.022771747782826424,
            -0.016166290268301964,
            -0.018035953864455223,
            -0.025716904550790787,
            -0.006740539334714413,
            0.025299951434135437,
            0.022915657609701157,
            0.0004542624519672245,
            0.025825895369052887,
            -0.005763604771345854,
            0.014340661466121674,
            -0.0002714161528274417,
            -0.004193301312625408,
            -0.012126851826906204,
            -0.015406054444611073,
            0.022031255066394806,
            0.02270287275314331,
            -0.009411937557160854,
            -0.0042295875027775764,
            0.025656478479504585,
            0.005260488484054804,
            -0.07428088784217834,
            -0.015406059101223946,
            -0.014924542047083378,
            0.00011826798436231911,
            0.032747093588113785,
            0.021849965676665306,
            -0.0309942327439785,
            0.009053467772901058,
            0.016929566860198975,
            -0.029139699414372444,
            0.024813862517476082,
            0.009118840098381042,
            -0.0261701587587595,
            -0.00248664035461843,
            0.002879668027162552,
            -0.016652485355734825,
            -0.0015994097338989377,
            0.03921011835336685,
            -0.020352309569716454,
            0.025210067629814148,
            -0.013716896995902061,
            0.0030502518638968468,
            -0.012266355566680431,
            -0.042308591306209564,
            -0.021446794271469116,
            0.026844365522265434,
            -0.025821084156632423,
            0.02638082392513752,
            0.028311461210250854,
            0.01421303953975439,
            -0.0033754247706383467,
            0.028154918923974037,
            0.012731213122606277,
            0.012974045239388943,
            -0.00316591071896255,
            0.006763630546629429,
            -0.014437795616686344,
            -0.028960710391402245,
            0.029680421575903893,
            -0.005484872031956911,
            -0.020494483411312103,
            0.01424947939813137,
            0.007136433385312557,
            0.012454703450202942,
            -0.016456395387649536,
            0.015088982880115509,
            -0.027384893968701363,
            0.028529362753033638,
            0.01125284843146801,
            -0.004941293504089117,
            0.03420064598321915,
            -0.017800433561205864,
            0.01487861480563879,
            -0.0003370575432199985,
            -0.045687876641750336,
            -0.018008122220635414,
            -0.017898615449666977,
            -0.0007000494515523314,
            0.04139489307999611,
            -0.021834803745150566,
            -0.013750157319009304,
            0.006677514407783747,
            -0.003363056341186166,
            0.008079427294433117,
            0.00011557999823708087,
            0.0024249369744211435,
            -0.0371207594871521,
            -0.04014585539698601,
            -0.0012958078878000379,
            0.0008074305369518697,
            0.0017667505890130997,
            -0.012722134590148926,
            -0.003007039660587907,
            0.020041964948177338,
            -0.024592120200395584,
            0.008930334821343422,
            -0.00515542458742857,
            -0.005436446983367205,
            -0.03388405963778496,
            0.016615482047200203,
            -0.0016270286869257689,
            -0.017548421397805214,
            0.015296043828129768,
            -0.020790867507457733,
            -0.0508493110537529,
            0.01816827431321144,
            -0.024516809731721878,
            0.007362962234765291,
            -0.0011155123356729746,
            0.017855055630207062,
            -0.008161140605807304,
            -0.00026126467855647206,
            -0.012764324434101582,
            -0.010094137862324715,
            -0.008436492644250393,
            -0.02370588108897209,
            0.0565006360411644,
            -0.018594888970255852,
            -0.010836872272193432,
            0.008453323505818844,
            0.028321746736764908,
            0.02170688845217228,
            -0.002260758774355054,
            0.005360318347811699,
            -0.017277196049690247,
            -0.02216673456132412,
            0.012146713212132454,
            0.0021192242857068777,
            0.0032352518755942583,
            0.016487831249833107,
            0.008744223043322563,
            -0.013957168906927109,
            -0.0021416733507066965,
            0.017057444900274277,
            -0.015720436349511147,
            0.047984350472688675,
            0.004867889918386936,
            -0.07966194301843643,
            -0.0027987854555249214,
            0.016971368342638016,
            0.022443333640694618,
            -0.025947537273168564,
            0.0234967153519392,
            0.007624213118106127,
            -0.013471421785652637,
            -0.027926113456487656,
            0.014863238669931889,
            0.01794041506946087,
            -0.04157934710383415,
            0.024308590218424797,
            -0.034165624529123306,
            -0.018064158037304878,
            -0.004295222461223602,
            0.010598985478281975,
            0.007953161373734474,
            0.029685091227293015,
            0.03989727795124054,
            0.00303693488240242,
            0.022718751803040504,
            0.017932336777448654,
            0.03164418786764145,
            0.0038072511088103056,
            0.014384676702320576,
            0.030773254111409187,
            0.0241019856184721,
            0.02417234517633915,
            -0.02663053385913372,
            -0.008888863027095795,
            0.018195435404777527,
            0.007646362297236919,
            0.030359122902154922,
            -0.003844787599518895,
            -0.023175114765763283,
            0.003271618625149131,
            0.009279297664761543,
            -0.02862091176211834,
            0.01277155801653862,
            -0.0382247194647789,
            -0.02170107327401638,
            -0.015173103660345078,
            0.0043829502537846565,
            -0.0533573254942894,
            -0.0007674028165638447,
            0.016792943701148033,
            0.010593203827738762,
            -0.021817414090037346,
            0.022097326815128326,
            4.285760223865509e-05,
            0.02650461159646511,
            0.00489042280241847,
            0.03379262983798981,
            -0.011875648982822895,
            -0.0015980358002707362,
            0.012662604451179504,
            -0.0009744993294589221,
            -0.041604530066251755,
            0.024846060201525688,
            -0.0069306534714996815,
            -0.012451143935322762,
            -0.02301764115691185,
            -0.011847466230392456,
            -0.010830621235072613,
            -0.02100960910320282,
            0.017092261463403702,
            -0.012920527718961239,
            -0.017925536260008812,
            0.027745896950364113,
            -0.014437510631978512,
            -0.016233231872320175,
            -0.0127794798463583,
            -0.023625275120139122,
            -0.014405965805053711,
            -0.03042961284518242,
            0.012578240595757961,
            -0.01765572652220726,
            0.014068021439015865,
            -0.021555889397859573,
            0.001524391584098339,
            0.00944785587489605,
            0.0181166660040617,
            0.0018314956687390804,
            -0.018440335988998413,
            0.008156930096447468,
            -0.01575489342212677,
            -0.02152954787015915,
            0.02234889380633831,
            0.0030367784202098846,
            -0.034642912447452545,
            -0.022477684542536736,
            -0.016194410622119904,
            0.0010872117709368467,
            0.030780207365751266,
            -0.015344941057264805,
            0.01519709825515747,
            0.0012999629834666848,
            -0.0389765128493309,
            -0.019283628091216087,
            0.01002271194010973,
            0.010030796751379967,
            0.006852126680314541,
            0.011408731341362,
            -0.0270317941904068,
            0.019657162949442863,
            -0.031802207231521606,
            0.013364741578698158,
            -0.00010323070455342531,
            0.018792321905493736,
            0.03415894880890846,
            -0.005441838875412941,
            -0.017248257994651794,
            -0.004419645760208368,
            -0.020606907084584236,
            0.021561266854405403,
            -0.049287233501672745,
            -0.04266849532723427,
            -0.025419842451810837,
            -0.008149959146976471,
            0.005800632294267416,
            -0.010583036579191685,
            -0.02274780534207821,
            0.003209903370589018,
            -0.009951166808605194,
            -0.028518786653876305,
            0.01863560825586319,
            -0.014494208618998528,
            -0.018761925399303436,
            0.020511094480752945,
            -0.04190004989504814,
            0.007519203703850508,
            -0.022641172632575035,
            -0.021120697259902954,
            -0.004812839441001415,
            -0.00917738862335682,
            0.031324900686740875,
            -0.027274221181869507,
            0.00030412664636969566,
            0.01731656864285469,
            -0.00625648396089673,
            0.01959182322025299,
            0.03812769055366516,
            -0.0037062664050608873,
            0.009872276335954666,
            -0.042453814297914505,
            -0.014827040024101734,
            -0.0010588247096166015,
            -0.00674806721508503,
            -0.015159284695982933,
            0.008043349720537663,
            0.024631747975945473,
            -0.010843376629054546,
            -0.0070582190528512,
            0.021503811702132225,
            0.004987174179404974,
            5.0394301069900393e-05,
            0.019191201776266098,
            -0.01285709161311388,
            -0.02662179060280323,
            0.021226733922958374,
            0.01976953260600567,
            -0.0242324136197567,
            0.004741587210446596,
            -0.027332359924912453,
            -0.019777417182922363,
            0.013660730794072151,
            -0.0194697268307209,
            0.03475423529744148,
            -0.014949812553822994,
            0.016926206648349762,
            0.007780204527080059,
            0.00925926398485899,
            -0.00747621851041913,
            -0.030512960627675056,
            -0.015978092327713966,
            -0.02720627747476101,
            0.026995593681931496,
            -0.01163796242326498,
            0.0046528703533113,
            -0.004562725778669119,
            0.021140940487384796,
            -0.012156917713582516,
            0.017512347549200058,
            0.009733892045915127,
            0.03946617990732193,
            -0.012287118472158909,
            -0.013530474156141281,
            0.04530567303299904,
            9.226976544596255e-05,
            -0.0008030546014197171,
            0.02135745994746685,
            0.036802276968955994,
            -0.005299759563058615,
            0.020414670929312706,
            0.02956726960837841,
            -0.01562991365790367,
            0.017049510031938553,
            -0.007139183580875397,
            -0.008816706947982311,
            0.028179343789815903,
            0.01568661630153656,
            -0.07126462459564209,
            -0.025171538814902306,
            0.005556417163461447,
            0.0070707122795283794,
            -0.004734761081635952,
            -0.008297184482216835,
            0.03157951682806015,
            0.01014079712331295,
            -0.006672166287899017,
            0.04771128669381142,
            0.015265694819390774,
            -0.0009718626970425248,
            0.007989512756466866,
            -0.02462606132030487,
            -0.022138381376862526,
            0.0006657036137767136,
            -0.02094254642724991,
            -0.03553862124681473,
            0.004482016898691654,
            -0.01838669180870056,
            -0.0125197172164917,
            0.0281975120306015,
            -0.022486025467514992,
            -0.0185247790068388,
            0.011268614791333675,
            -0.01793842576444149,
            -0.012896493077278137,
            -0.011599581688642502,
            -0.0030501768924295902,
            0.04566791653633118,
            0.0174992848187685,
            0.02254868485033512,
            0.01357338484376669,
            -0.016826115548610687,
            0.012050515972077847,
            0.022637849673628807,
            0.005116635467857122,
            -0.013419033959507942,
            0.010348554700613022,
            -0.011235124431550503,
            0.028164107352495193,
            0.019251808524131775,
            -0.008683130145072937,
            -0.013723337091505527,
            -0.01055053062736988,
            0.005614491645246744,
            -0.012301914393901825,
            0.0143321193754673,
            0.005073269829154015,
            -0.018014807254076004,
            -0.030809208750724792,
            0.03120693936944008,
            0.02561732567846775,
            0.027432845905423164,
            0.013305051252245903,
            -0.033261291682720184,
            -0.025576623156666756,
            -0.027645617723464966,
            -0.005058847833424807,
            0.014481540769338608,
            -0.028019588440656662,
            -0.062285859137773514,
            -0.01065827440470457,
            -0.00814806204289198,
            -0.019748913124203682,
            0.03337215632200241,
            -0.011321715079247952,
            0.009841912426054478,
            0.005795417353510857,
            -0.03457288444042206,
            -0.008436276577413082,
            0.02807551436126232,
            0.002300508785992861,
            0.01602802984416485,
            0.04323546960949898,
            0.01313265971839428,
            -0.03775032237172127,
            -0.037585124373435974,
            0.022220931947231293,
            -0.019047817215323448,
            0.000569526688195765,
            -0.0048094685189425945,
            0.012242727912962437,
            0.007719326298683882,
            0.033333033323287964,
            0.019961915910243988,
            0.025419488549232483,
            -0.007320178207010031,
            -0.009482105262577534,
            0.02495136484503746,
            -0.03084852173924446,
            0.014548007398843765,
            -0.024081889539957047,
            0.0024485313333570957,
            -0.031217046082019806,
            -0.02549106441438198,
            -0.0045564319007098675,
            0.003474632976576686,
            0.03583419322967529,
            0.03751111030578613,
            0.0235038623213768,
            -0.0009311832254752517,
            0.030808759853243828,
            0.019432025030255318,
            -0.00392137048766017,
            -0.01078140176832676,
            0.02417607791721821,
            -0.015316621400415897,
            0.010708414949476719,
            0.017482707276940346,
            0.014658963307738304,
            -0.013166534714400768,
            0.023622779175639153,
            -0.019370082765817642,
            -0.025498181581497192,
            0.017857858911156654,
            0.013100871816277504,
            0.01637556590139866,
            0.02254246175289154,
            -0.009885008446872234,
            0.022635702043771744,
            0.008123300038278103,
            -0.01336716115474701,
            -0.02722202241420746,
            -0.024886853992938995,
            0.017452040687203407,
            -0.03162369877099991,
            -0.008894487284123898,
            0.007762480061501265,
            -0.0290994793176651,
            -0.0002693743444979191,
            -0.0026124706491827965,
            -0.01949493959546089,
            0.02125144749879837,
            -0.013943716883659363,
            0.02745714969933033,
            -0.035883937031030655,
            0.01872468926012516,
            -0.013480555266141891,
            -0.03294511139392853,
            0.002946674358099699,
            -0.02340255305171013,
            0.01819167286157608,
            0.030884183943271637,
            -0.002323625609278679,
            -0.014273444190621376,
            0.01003502681851387,
            0.0046855369582772255,
            0.02591545693576336,
            0.02854074537754059,
            -0.011310400441288948,
            0.04303174838423729,
            -0.011609811335802078,
            0.021530961617827415,
            0.014003765769302845,
            0.006840965244919062,
            -0.0002055891527561471,
            0.008149992674589157,
            -0.023402264341711998,
            0.027360865846276283,
            -0.016652828082442284,
            0.00016418707673437893,
            0.03191569820046425,
            -0.031948644667863846,
            0.00014984414156060666,
            0.015847187489271164,
            0.030948705971240997,
            -0.009561276994645596,
            -0.0033104862086474895,
            0.04556257650256157,
            -0.021061774343252182,
            0.0038069537840783596,
            0.034015409648418427,
            0.0005100759444758296,
            0.0267795417457819,
            -0.014140103943645954,
            0.008692188188433647,
            -0.030183125287294388,
            0.0018272569868713617,
            0.020612087100744247,
            0.005117768421769142,
            -0.026444902643561363,
            -0.011933688074350357,
            -0.0030965397600084543,
            -0.0020569725893437862,
            0.03456668183207512,
            -0.0003292036708444357,
            -0.008531490340828896,
            -0.0042124539613723755,
            -0.0001371006219414994,
            0.032016459852457047,
            0.0033901226706802845,
            0.04400968924164772,
            -0.02668795920908451,
            -0.015749109908938408,
            0.02124974876642227,
            0.004522690083831549,
            0.03129381313920021,
            0.0061939978040754795,
            0.013174491934478283,
            0.028310852125287056,
            0.009786591865122318,
            -0.002159224124625325,
            0.037591833621263504,
            0.006639632396399975,
            -0.00029913641628809273,
            0.02941347472369671,
            0.008445002138614655,
            -0.023446280509233475,
            0.01590542308986187,
            0.028216222301125526,
            -0.014668948017060757,
            0.007237252313643694,
            -0.003997019492089748,
            -0.010661762207746506,
            -0.027363542467355728,
            -0.018256776034832,
            -0.018837163224816322,
            0.04205342009663582,
            -0.037201110273599625,
            0.014896699227392673,
            0.040162838995456696,
            0.0183268915861845,
            0.012563738971948624,
            0.015584083274006844,
            0.019808949902653694,
            -0.007279970683157444,
            -0.030539002269506454,
            -0.02492750994861126,
            0.005213530268520117,
            0.02292277291417122,
            -0.019559623673558235,
            -0.022777633741497993,
            -0.028555378317832947,
            0.018201859667897224,
            -0.008547179400920868,
            0.012782009318470955,
            0.011094041168689728,
            -0.021526072174310684,
            0.003889613552019,
            -0.02109425887465477,
            0.012142715975642204,
            0.03481592237949371,
            0.036118801683187485,
            -0.004902988206595182,
            0.016194498166441917,
            0.019915975630283356,
            -0.011503979563713074,
            -0.03425492346286774,
            0.02150651253759861,
            -0.005229081958532333,
            -0.0023562926799058914,
            -0.01986800692975521,
            -0.06034834310412407,
            0.01982574537396431,
            0.015409442596137524,
            0.013673027977347374,
            -0.011247072368860245,
            -0.019289687275886536,
            -0.0023271755781024694,
            0.02208073064684868,
            0.018026813864707947,
            0.014730139635503292,
            -0.012733657844364643,
            0.010586938820779324,
            0.01954602636396885,
            -0.005178298335522413,
            0.019442874938249588,
            0.0013907774118706584,
            -0.00027566487551666796,
            -0.013697569258511066,
            -0.00796311441808939,
            -0.002003725850954652,
            -0.02069947123527527,
            -0.038025401532649994,
            -0.0068862782791256905,
            -0.011909623630344868,
            -0.008272551000118256,
            -0.003648049896582961,
            0.024019969627261162,
            -0.012639233842492104,
            0.022964252158999443,
            0.004167928826063871,
            0.01206507720053196,
            -0.00754521694034338,
            0.04195989668369293,
            0.014232693240046501,
            0.026701772585511208,
            0.01445409283041954,
            -0.041970670223236084,
            -0.043737951666116714,
            0.02942122146487236,
            0.03852469101548195,
            0.0034321255516260862,
            0.0030617217998951674,
            0.014257963746786118,
            -0.017536986619234085,
            -0.029407450929284096,
            -0.027034062892198563,
            0.021817302331328392,
            -0.012000407092273235,
            0.030524827539920807,
            0.012322429567575455,
            0.029719563201069832,
            0.02847837656736374,
            -0.00478370999917388,
            -0.02509401924908161,
            -8.209812949644402e-05,
            0.014038468711078167,
            -0.0056984215043485165,
            -0.016692794859409332,
            -0.010346134193241596,
            -0.015420018695294857,
            0.012317211367189884,
            0.02156481146812439,
            -0.005024800542742014,
            0.034065697342157364,
            -0.013420725241303444,
            -0.02768838033080101,
            -0.015337652526795864,
            0.018390869721770287,
            0.002183689037337899,
            0.030108505859971046,
            0.014080611057579517,
            -0.007289181463420391,
            -0.023842280730605125,
            -0.007705626077950001,
            0.02736946940422058,
            0.004064216278493404,
            -0.0034176630433648825,
            -0.0208720862865448,
            -0.021071480587124825,
            0.01751849614083767,
            0.030374806374311447,
            0.012759452685713768,
            -0.016424521803855896,
            -0.008444203995168209,
            0.015106238424777985,
            0.011830850504338741,
            -0.00028187912539578974,
            -0.014329567551612854,
            0.03741103410720825,
            0.026524296030402184,
            0.0032569398172199726,
            -0.0270647294819355,
            0.008057479746639729,
            -0.01414041593670845,
            0.023785438388586044,
            0.03217307850718498,
            -0.0024349945597350597,
            0.0012963579501956701,
            0.011517509818077087,
            0.0022768976632505655,
            0.016734935343265533,
            0.01673913560807705,
            -0.02541421540081501,
            -0.008376849815249443,
            0.021547965705394745,
            -0.0226877573877573,
            -0.03184803947806358,
            -0.021941909566521645,
            -0.007299385033547878,
            0.003845121245831251,
            0.0030323220416903496,
            0.032665450125932693,
            -0.005719292908906937,
            -0.03799119591712952,
            0.03112480603158474,
            0.001153075136244297,
            0.029673686251044273,
            0.013646901585161686,
            0.028176788240671158,
            -0.011971679516136646,
            -0.003965368494391441,
            0.021806709468364716,
            0.01964065618813038,
            0.03186768293380737,
            -0.05061546340584755,
            -0.014988976530730724,
            0.04482390731573105,
            -0.006141375284641981,
            0.01816650852560997,
            -0.01868630200624466,
            -0.02274453639984131,
            -0.08480145782232285,
            -0.013361893594264984,
            0.03778231516480446,
            0.011304840445518494,
            0.006055603269487619,
            0.04058067128062248,
            0.0012675354955717921,
            0.03736362233757973,
            0.03461967781186104,
            0.0136094456538558,
            0.00025873497361317277,
            -0.019295640289783478,
            -0.02541365474462509,
            -0.04408160224556923,
            -0.010033637285232544,
            0.03181705251336098,
            0.004203025717288256,
            0.010991943068802357,
            0.02929520234465599,
            0.0030117661226540804,
            0.036607831716537476,
            -0.016699543222784996,
            0.0275996383279562,
            0.009605699218809605,
            -0.013045997358858585,
            0.011541192419826984,
            0.0182346161454916,
            -0.01823396421968937,
            -0.0057857767678797245,
            0.009821793995797634,
            0.03169259428977966,
            0.005897302180528641,
            0.0022543692030012608,
            -0.018573591485619545,
            -0.001091929618269205,
            -0.028504805639386177,
            0.02575061097741127,
            0.001564627280458808,
            0.008219058625400066,
            0.018901020288467407,
            0.020621314644813538,
            0.01471041887998581,
            -0.015902938321232796,
            0.0195982176810503,
            0.0032248084899038076,
            -0.01913788542151451,
            -0.01809454895555973,
            0.015713483095169067,
            0.024564778432250023,
            -0.0111572640016675,
            -0.03670322522521019,
            0.03629932552576065,
            0.0053490810096263885,
            -0.02108066715300083,
            0.029480494558811188,
            -0.023657679557800293,
            -0.02336067706346512,
            0.012624509632587433,
            0.0042734467424452305,
            -0.015160639770328999,
            0.006118570454418659,
            -0.017765941098332405,
            0.024468833580613136,
            0.026666199788451195,
            0.0012435937533155084,
            -0.025619372725486755,
            -0.06135532259941101,
            0.02769693173468113,
            -0.020005637779831886,
            0.02139020338654518,
            -0.004930229391902685,
            -0.0011145720491185784,
            -0.025675008073449135,
            0.028154438361525536,
            0.0005478700622916222,
            0.01836903765797615,
            -0.056931305676698685,
            0.018967248499393463,
            0.003319451119750738,
            -0.029738647863268852,
            0.03341737389564514,
            0.018836982548236847,
            0.03865912929177284,
            -0.03141754865646362,
            0.002058364450931549,
            0.032276831567287445,
            0.03483867272734642,
            0.010983993299305439,
            0.025332193821668625,
            0.01069151982665062,
            -0.019148332998156548,
            0.012060564942657948,
            -0.014635473489761353,
            0.03166694566607475,
            -0.019052032381296158,
            0.010688877664506435,
            -0.02896367572247982,
            0.013912319205701351,
            0.010386927053332329,
            -0.008581520058214664,
            0.004644966218620539,
            -0.017320794984698296,
            0.0036931538488715887,
            -0.010468432679772377,
            -0.01812099665403366,
            -0.0011075236834585667,
            0.018456673249602318,
            -0.028819316998124123,
            0.0056840237230062485,
            0.0006712810136377811,
            -0.00893588736653328,
            -0.02005244977772236,
            0.03589823096990585,
            -0.007219303399324417,
            0.013910683803260326,
            -0.03460600972175598,
            -0.02161460556089878,
            0.00101364532019943,
            -0.007897918112576008,
            0.0094060730189085,
            0.004189681261777878,
            0.027054443955421448,
            0.015458663925528526,
            0.036367207765579224,
            -0.02177904173731804,
            0.03210275620222092,
            0.011367819271981716,
            0.0012074060505256057,
            -0.0006718233344145119,
            -0.0024354474153369665,
            0.006002089008688927,
            -0.037299420684576035,
            0.035984497517347336,
            -0.049887701869010925,
            0.00809361133724451,
            0.0012532655382528901,
            -0.014294793829321861,
            0.03983132913708687,
            0.018068723380565643,
            -0.01879945583641529,
            0.027672739699482918,
            -0.032661840319633484,
            0.008575571700930595,
            -0.047508932650089264,
            0.023481642827391624,
            0.025252334773540497,
            0.0070212059654295444,
            -0.014229129999876022,
            -0.04653818905353546,
            0.0007403193158097565,
            0.005056047346442938,
            -0.016902051866054535,
            0.02586129866540432,
            0.002776709385216236,
            0.011548963375389576,
            0.007573021575808525,
            -0.006906642112880945,
            -0.04486969858407974,
            0.022536467760801315,
            -0.013320290483534336,
            0.024283716455101967,
            0.021311812102794647,
            0.01891428977251053,
            0.0274945180863142,
            0.008780298754572868,
            0.005546795669943094,
            -0.0338740274310112,
            -0.014934842474758625,
            -0.018679829314351082,
            -0.013941286131739616,
            -0.03961525484919548,
            0.03248699754476547,
            -0.013128691352903843,
            -0.007602741476148367,
            0.00401338841766119,
            -0.021416490897536278,
            0.02357335202395916,
            0.004942178726196289,
            -0.004421438090503216,
            -0.026225566864013672,
            -0.02603732980787754,
            -0.011187110096216202,
            0.04037350043654442,
            -0.030442165210843086,
            0.0008586598560214043,
            0.04681961238384247,
            0.02341405116021633,
            0.00541701540350914,
            0.0193060003221035,
            -0.00836279708892107,
            -0.027071041986346245,
            0.012317607179284096,
            0.03150008246302605,
            -0.006208192557096481,
            -0.013718648813664913,
            0.002393946284428239,
            0.01721307821571827,
            0.008393312804400921,
            0.030834555625915527,
            0.003825100604444742,
            0.027937594801187515,
            -0.02353300340473652,
            0.015212145633995533,
            0.0006689964211545885,
            -0.00038409142871387303,
            0.025733279064297676,
            0.025992536917328835,
            -0.040114063769578934,
            -0.030263200402259827,
            -0.007701536640524864,
            -0.026393206790089607,
            -0.02489200420677662,
            0.012974360026419163
        ],
        [
            0.03336324915289879,
            0.016341645270586014,
            0.02303295210003853,
            0.019113689661026,
            -0.004086773842573166,
            -0.013559282757341862,
            0.022389009594917297,
            0.030491294339299202,
            -0.0038597185630351305,
            0.016518717631697655,
            0.02123505435883999,
            0.016703391447663307,
            -0.029800251126289368,
            -0.010450353845953941,
            -0.005503800697624683,
            -0.008969348855316639,
            0.03359197825193405,
            -0.012334671802818775,
            -0.01867670938372612,
            0.031865090131759644,
            0.0068264673464000225,
            -0.010232062079012394,
            0.004324577748775482,
            0.024729380384087563,
            0.0034088222309947014,
            0.0011631351662799716,
            0.037315450608730316,
            -0.008652214892208576,
            0.018062761053442955,
            0.03287480026483536,
            0.02880856953561306,
            0.01961902715265751,
            -0.0008643369073979557,
            0.01608782447874546,
            0.028275558724999428,
            -0.012278854846954346,
            0.024616269394755363,
            -0.0054271165281534195,
            0.002241472713649273,
            -0.016286984086036682,
            0.0011086378945037723,
            0.0053317854180932045,
            0.011649028398096561,
            -0.028079763054847717,
            0.01109325885772705,
            0.025754449889063835,
            0.013533111661672592,
            0.013926845975220203,
            -0.007789998315274715,
            -0.040997251868247986,
            0.0029463109094649553,
            -0.013478816486895084,
            -0.042945630848407745,
            -0.02192576974630356,
            -0.025662675499916077,
            -0.01910923607647419,
            -0.03263702243566513,
            -0.04136092960834503,
            -0.004207110498100519,
            -0.0026744497008621693,
            -0.005493021570146084,
            -0.023757539689540863,
            -0.026312358677387238,
            -0.0024097245186567307,
            -0.024741588160395622,
            0.03192095085978508,
            -0.0005953859072178602,
            -0.016541097313165665,
            -0.0132590401917696,
            0.023933278396725655,
            -0.02394920028746128,
            -0.007419858127832413,
            -0.0065383510664105415,
            0.01596117578446865,
            -0.009831477887928486,
            -0.0006003403104841709,
            0.026267731562256813,
            -0.0008645073976367712,
            0.0076613337732851505,
            -0.0010958786588162184,
            -0.02676447480916977,
            0.008918127045035362,
            0.014336932450532913,
            0.029088545590639114,
            -0.023337511345744133,
            -0.002717588096857071,
            0.03195421025156975,
            -0.014725436456501484,
            0.0020229793153703213,
            0.04585593193769455,
            -0.03318843990564346,
            0.02952439710497856,
            -0.017209304496645927,
            -0.041232842952013016,
            0.04642006382346153,
            -0.0012862650910392404,
            -0.020565476268529892,
            -0.013265267014503479,
            -0.05664806440472603,
            -0.0022726620081812143,
            0.0019093152368441224,
            -0.01597888208925724,
            -0.02577398158609867,
            -0.017395783215761185,
            -0.018722474575042725,
            0.007582244463264942,
            0.025116128847002983,
            -0.004982675891369581,
            0.05113161355257034,
            -0.02056737057864666,
            -0.016057513654232025,
            -0.008496144786477089,
            -0.012630583718419075,
            0.023467326536774635,
            0.02558804862201214,
            0.030342819169163704,
            0.012930091470479965,
            0.04495307430624962,
            -0.01324197556823492,
            -0.012502550147473812,
            0.020110925659537315,
            -0.024221045896410942,
            0.013283876702189445,
            -0.032679326832294464,
            -0.007410678546875715,
            0.010857237502932549,
            -0.01497909426689148,
            0.01524042896926403,
            0.03106781095266342,
            0.016976140439510345,
            -0.0013444882351905107,
            -0.05122475326061249,
            0.03639717772603035,
            0.033227887004613876,
            -0.02270577847957611,
            -0.0017439251532778144,
            0.004954418167471886,
            0.04052288830280304,
            0.01531228981912136,
            -0.03352945297956467,
            -0.005757748149335384,
            -0.024059724062681198,
            -0.0398421436548233,
            0.0024470645003020763,
            0.050509147346019745,
            0.02093624323606491,
            -0.010938323102891445,
            0.021762888878583908,
            0.01553527731448412,
            0.013293725438416004,
            0.002918192185461521,
            0.017710689455270767,
            0.010358898900449276,
            -0.025356082245707512,
            -0.018746711313724518,
            -0.009679951705038548,
            0.02540365234017372,
            0.01650167442858219,
            -0.02993817627429962,
            0.002220897702500224,
            -0.026681769639253616,
            0.027017813175916672,
            0.01117715984582901,
            -0.009552582167088985,
            -0.012008626013994217,
            -0.007245342712849379,
            0.01843084581196308,
            -0.03941505774855614,
            -0.034972477704286575,
            -0.030986350029706955,
            -0.004071518778800964,
            -0.013014858588576317,
            0.006293338257819414,
            0.005452787969261408,
            0.01395066175609827,
            0.0019497693283483386,
            -0.0014028878649696708,
            0.020667580887675285,
            -0.035163287073373795,
            0.015133227221667767,
            0.025089329108595848,
            -0.04118731617927551,
            -0.02121707983314991,
            0.025097474455833435,
            -0.01901726797223091,
            0.03294757008552551,
            0.02676401473581791,
            -0.004366723354905844,
            -0.02373558096587658,
            -0.019560493528842926,
            -0.004700095858424902,
            0.03157384321093559,
            0.033411748707294464,
            -5.2790735935559496e-05,
            -0.0018056831322610378,
            0.007845349609851837,
            0.026798909530043602,
            -0.02157481759786606,
            0.012119597755372524,
            -0.00622007017955184,
            0.03507263958454132,
            0.0027953307144343853,
            0.027664948254823685,
            0.025983016937971115,
            0.03268223628401756,
            0.038354210555553436,
            -0.03952839970588684,
            0.006225640885531902,
            0.010874547995626926,
            0.018693378195166588,
            0.02391202189028263,
            0.013494571670889854,
            -0.0028861111495643854,
            0.03140071779489517,
            0.022151082754135132,
            -0.019658902660012245,
            0.016896257176995277,
            0.006517637986689806,
            0.02588646486401558,
            0.03239981457591057,
            0.023582477122545242,
            -0.046178657561540604,
            0.01841568388044834,
            -0.0051248031668365,
            -0.004689098335802555,
            0.03379537910223007,
            0.012665318325161934,
            0.029974067583680153,
            -0.025589292868971825,
            0.03612613305449486,
            0.023007195442914963,
            -0.00247203023172915,
            0.027933383360505104,
            -0.026118379086256027,
            0.0021235516760498285,
            0.006441943347454071,
            0.05487603694200516,
            0.02856183797121048,
            -0.024844940751791,
            0.01310434378683567,
            -0.009303449653089046,
            0.01939900405704975,
            -0.017933130264282227,
            0.004826502408832312,
            -0.012919921427965164,
            -0.006490995176136494,
            -0.012132097035646439,
            0.01495131105184555,
            -0.003758650738745928,
            0.0022697115782648325,
            0.004236896522343159,
            0.0031526919919997454,
            -0.018248632550239563,
            0.04007169231772423,
            0.018011748790740967,
            -0.015781860798597336,
            -0.013843029737472534,
            0.02720586024224758,
            -0.013689338229596615,
            -0.005352555774152279,
            -0.0019806427881121635,
            -0.012965566478669643,
            -0.024016035720705986,
            -0.013142692856490612,
            -0.0006040229345671833,
            0.03302498906850815,
            -0.009977593086659908,
            0.016087589785456657,
            0.015878990292549133,
            0.0011876259231939912,
            0.015704795718193054,
            0.0027603774797171354,
            -0.019590288400650024,
            -0.034870393574237823,
            -0.011509167961776257,
            -0.003533598966896534,
            0.002299630083143711,
            -0.0030201931949704885,
            0.02370484359562397,
            -0.005476478487253189,
            -0.03697685897350311,
            0.0259654950350523,
            -0.02223450504243374,
            -0.04196465387940407,
            0.013459654524922371,
            -0.018258927389979362,
            0.019010402262210846,
            -0.008987215347588062,
            0.028059883043169975,
            -0.01240531262010336,
            0.025482121855020523,
            0.012831422500312328,
            -0.028538361191749573,
            0.006993505172431469,
            0.01793062314391136,
            0.040677256882190704,
            -0.011766799725592136,
            -0.01748504675924778,
            -0.007222373969852924,
            0.03441237658262253,
            -0.005589724984019995,
            -0.018291153013706207,
            -0.023060306906700134,
            -0.03252258524298668,
            0.0062795826233923435,
            -0.006243939511477947,
            -0.02694757468998432,
            0.025744318962097168,
            -0.03821218013763428,
            -0.024945871904492378,
            -0.03337932750582695,
            -0.01238988246768713,
            -0.023476919159293175,
            0.030241841450333595,
            0.01400956604629755,
            0.03301475569605827,
            0.029821274802088737,
            0.026860173791646957,
            -0.017985736951231956,
            0.02083372138440609,
            0.02237560600042343,
            -0.0005328315892256796,
            -0.029998967424035072,
            -0.026777546852827072,
            0.019971298053860664,
            0.028150727972388268,
            -0.023969069123268127,
            0.032168198376894,
            -0.032300978899002075,
            0.017346298322081566,
            -0.01990421488881111,
            -0.015620779246091843,
            0.005658719222992659,
            0.005972557235509157,
            0.03490525111556053,
            -0.02090778574347496,
            -0.017779475077986717,
            0.01960800215601921,
            -0.03434710204601288,
            -0.033951759338378906,
            -0.02300233580172062,
            -0.025322915986180305,
            0.013993916101753712,
            0.028161609545350075,
            -0.005214883480221033,
            0.0063756234012544155,
            0.013830747455358505,
            -0.03228238970041275,
            0.03521844744682312,
            -0.019629839807748795,
            0.013132465071976185,
            0.036341119557619095,
            -0.021650314331054688,
            -0.01613207720220089,
            -0.006838065106421709,
            -0.0302688330411911,
            0.011179138906300068,
            -0.05195550620555878,
            0.018910374492406845,
            0.015426920726895332,
            -0.022572683170437813,
            -0.016423683613538742,
            0.013702405616641045,
            -0.003954426851123571,
            -0.02267690934240818,
            -0.025209153071045876,
            -0.05070270225405693,
            0.017731057479977608,
            0.03330893814563751,
            -0.031115267425775528,
            -0.012088283896446228,
            -0.010111793875694275,
            -0.024949345737695694,
            -0.0018253445159643888,
            0.02260899730026722,
            0.007995187304913998,
            0.02746511995792389,
            -0.02534731663763523,
            0.0051797302439808846,
            -0.04216843098402023,
            -0.03047865442931652,
            -0.007225843612104654,
            0.012904689647257328,
            0.027638910338282585,
            0.019197335466742516,
            -0.01784457080066204,
            0.01449467334896326,
            -0.04174560680985451,
            -0.021974151954054832,
            0.021106978878378868,
            0.020230475813150406,
            0.022666050121188164,
            0.029350170865654945,
            0.016714904457330704,
            0.065206378698349,
            0.0033877920359373093,
            0.03555872291326523,
            0.02692546509206295,
            0.005050329957157373,
            -0.002265497576445341,
            0.042013514786958694,
            -0.0423596166074276,
            -0.004980834666639566,
            0.008482892997562885,
            0.01665411703288555,
            0.019216934219002724,
            -0.006661570630967617,
            -0.04028122499585152,
            0.03180504962801933,
            -0.01805095002055168,
            -5.401218732004054e-05,
            0.010028093121945858,
            -0.009111179038882256,
            -0.01955115981400013,
            -0.002537722932174802,
            0.023932866752147675,
            -0.030368175357580185,
            -0.014355691149830818,
            -0.005488378461450338,
            0.004643801134079695,
            -0.048465099185705185,
            0.02151922695338726,
            0.002157778013497591,
            0.018196314573287964,
            -0.014205945655703545,
            0.020924966782331467,
            -0.04552039876580238,
            -0.012456310912966728,
            0.019004276022315025,
            -0.00919040385633707,
            -0.03170288726687431,
            -0.015570218674838543,
            -0.015767671167850494,
            0.017748383805155754,
            0.02304898016154766,
            -0.03650530427694321,
            0.02067500539124012,
            -0.02364766225218773,
            -0.022810379043221474,
            0.009337959811091423,
            0.0127029400318861,
            0.011485093273222446,
            0.014795258641242981,
            -0.012190748937427998,
            -0.0013259397819638252,
            -0.0076781706884503365,
            0.009579705074429512,
            0.01423071138560772,
            0.025699250400066376,
            0.017028100788593292,
            -0.0054750703275203705,
            -0.023190559819340706,
            0.00672448193654418,
            0.026583990082144737,
            -0.02216176502406597,
            0.03016229160130024,
            -0.03605976700782776,
            0.017658324912190437,
            0.004197379108518362,
            0.0032949624583125114,
            -0.0175937432795763,
            -0.030023561790585518,
            0.022131863981485367,
            -0.016290273517370224,
            -0.014459370635449886,
            0.03264699876308441,
            -0.016976380720734596,
            -0.0002637413563206792,
            -0.001132735051214695,
            -0.004668604116886854,
            0.008504749275743961,
            0.016742225736379623,
            -0.008110913448035717,
            -0.005988363642245531,
            -0.03273610398173332,
            -0.01674645207822323,
            -0.025316322222352028,
            -0.021853720769286156,
            0.008703681640326977,
            -0.03300204873085022,
            0.0013494421727955341,
            0.004792210180312395,
            0.019939156249165535,
            0.029945597052574158,
            -0.025395484641194344,
            0.010635622777044773,
            -0.022318191826343536,
            -0.03161270171403885,
            0.05551319941878319,
            0.03300873935222626,
            0.036972884088754654,
            -0.0015145459910854697,
            -0.02215644158422947,
            -0.002320862840861082,
            0.005454403348267078,
            0.02678936906158924,
            -0.018464388325810432,
            -0.01936054229736328,
            0.007487730588763952,
            -0.012716236524283886,
            -0.011655240319669247,
            -0.010597347281873226,
            0.01479160226881504,
            0.01749098114669323,
            -0.015272466465830803,
            0.0001807170920073986,
            0.0017606121255084872,
            0.017717663198709488,
            0.04128402844071388,
            -0.023449977859854698,
            0.029201190918684006,
            0.0041293068788945675,
            0.020533565431833267,
            -0.028095485642552376,
            0.008373782970011234,
            -0.01528447400778532,
            0.006388148758560419,
            -0.021332547068595886,
            -0.018396323546767235,
            -0.01711982861161232,
            0.013075926341116428,
            0.030769621953368187,
            0.00853765569627285,
            -0.012904091738164425,
            -0.026710616424679756,
            0.004415637813508511,
            -0.0032003335654735565,
            0.030985027551651,
            -0.03314491733908653,
            0.03240877389907837,
            -0.008487329818308353,
            -0.002160291187465191,
            0.037361081689596176,
            0.00615833280608058,
            0.03068585693836212,
            -0.00999970082193613,
            -0.037042681127786636,
            0.024438591673970222,
            0.03505050390958786,
            -0.0137602798640728,
            -0.012094853445887566,
            0.01237635500729084,
            -0.01288173720240593,
            -0.01541069708764553,
            0.013741429895162582,
            -0.035331398248672485,
            0.009964121505618095,
            0.020708715543150902,
            0.010376960039138794,
            0.006501160096377134,
            0.004927407950162888,
            0.024816544726490974,
            0.025167597457766533,
            -0.0024990758392959833,
            -0.006387942936271429,
            -0.018720487132668495,
            0.022918924689292908,
            -0.022536447271704674,
            0.03057897463440895,
            0.0015579606406390667,
            -0.004182866774499416,
            -0.026242712512612343,
            -0.027366692200303078,
            -0.0127245569601655,
            0.011880875565111637,
            0.01074177585542202,
            0.0174197219312191,
            -0.004763156175613403,
            0.029989801347255707,
            0.007983089424669743,
            0.006177317351102829,
            0.004404714331030846,
            0.021470321342349052,
            0.003660026006400585,
            -0.04121548682451248,
            -0.03216973692178726,
            -0.026050444692373276,
            -0.02222094126045704,
            0.02186121605336666,
            -0.023698991164565086,
            -0.011197282932698727,
            -0.030326422303915024,
            -0.012509099207818508,
            -0.013066004030406475,
            0.0035016010515391827,
            0.017862984910607338,
            -0.008956664241850376,
            0.004319917410612106,
            -0.018716536462306976,
            -0.011120172217488289,
            0.0031517266761511564,
            0.04058106243610382,
            0.037649184465408325,
            0.016545791178941727,
            0.012286444194614887,
            0.0017991203349083662,
            -0.02483189105987549,
            0.035218704491853714,
            -0.01961824856698513,
            -0.008973781950771809,
            -0.02645300142467022,
            0.020987963303923607,
            -0.022151058539748192,
            0.018994608893990517,
            -0.004892780911177397,
            -0.006326842587441206,
            -0.026599615812301636,
            0.009043990634381771,
            0.040712837129831314,
            -0.029733868315815926,
            -0.014297908172011375,
            0.02311883680522442,
            0.008237707428634167,
            0.0292012058198452,
            0.011746843345463276,
            0.017918869853019714,
            -0.0007816321449354291,
            -0.0229694452136755,
            0.022079812362790108,
            0.000260706729022786,
            0.0006670036818832159,
            0.03283979371190071,
            0.02040191739797592,
            0.009657044894993305,
            0.010608995333313942,
            0.04250635206699371,
            -0.019039077684283257,
            0.0005610589287243783,
            8.652073302073404e-05,
            -0.0025604628026485443,
            -0.029199784621596336,
            0.010550053790211678,
            0.026104023680090904,
            0.02424333058297634,
            0.03806108981370926,
            -0.023477915674448013,
            -0.041074372828006744,
            -0.02927936054766178,
            0.02811419405043125,
            -0.012320607900619507,
            0.02370155043900013,
            -0.012620754539966583,
            -0.025977732613682747,
            -0.03551322966814041,
            0.016063131392002106,
            -0.032340824604034424,
            -0.005891382694244385,
            -0.01650424301624298,
            -0.019408132880926132,
            0.034592196345329285,
            0.02279650792479515,
            0.0008848695433698595,
            0.004566204734146595,
            0.0333695225417614,
            0.0039009929168969393,
            -0.019804270938038826,
            0.01307686883956194,
            0.014982626773416996,
            -0.02636193484067917,
            -0.019906483590602875,
            0.01723521761596203,
            -0.0009678435162641108,
            -0.01501377485692501,
            -0.011520682834088802,
            -0.019853966310620308,
            -0.006079668179154396,
            0.022656502202153206,
            -0.028131326660513878,
            -0.010627537034451962,
            0.03531338647007942,
            -0.01477202121168375,
            0.02791839838027954,
            -0.020425619557499886,
            -0.042845696210861206,
            -0.020590094849467278,
            -0.011786110699176788,
            -0.02300170622766018,
            0.019601212814450264,
            -0.02521069161593914,
            -0.0343443863093853,
            -0.03788641840219498,
            0.0294939074665308,
            -0.020932741463184357,
            0.02125122956931591,
            0.0007930300780571997,
            0.02893204055726528,
            0.031827155500650406,
            0.049894947558641434,
            -0.014395763166248798,
            -0.05319949984550476,
            -0.015466046519577503,
            0.011593326926231384,
            0.016022132709622383,
            0.014704650267958641,
            -0.026836492121219635,
            -0.004278720822185278,
            0.04204506054520607,
            -0.008734101429581642,
            0.02757672406733036,
            -0.010769459418952465,
            0.007429817225784063,
            -0.015553083270788193,
            0.02313298173248768,
            0.004885189235210419,
            -0.014329343102872372,
            -0.007371865212917328,
            0.0003886451304424554,
            -0.016307326033711433,
            -0.005755026824772358,
            0.04042881727218628,
            0.0030392128974199295,
            -0.0028946378733962774,
            -0.013140987604856491,
            0.016451818868517876,
            -0.008791802451014519,
            0.037444762885570526,
            0.04015875607728958,
            -0.02345258742570877,
            0.031124021857976913,
            0.022126495838165283,
            0.00613946421071887,
            0.029066607356071472,
            -0.0037356086540967226,
            0.0059107160195708275,
            0.029526958242058754,
            0.02912377566099167,
            0.006040262524038553,
            0.014857061207294464,
            -0.027819879353046417,
            -0.007903852500021458,
            0.025516169145703316,
            0.017719456925988197,
            0.0074248118326067924,
            0.02052992768585682,
            -0.027948632836341858,
            -0.006297427695244551,
            -0.029757218435406685,
            0.018194861710071564,
            0.0021732684690505266,
            0.01595393195748329,
            0.039865490049123764,
            0.03174885734915733,
            0.03124726191163063,
            0.016289139166474342,
            -0.007133254781365395,
            -0.00235356274060905,
            0.03350033238530159,
            -0.0341828316450119,
            0.004708016756922007,
            0.018516141921281815,
            0.0035358367022126913,
            0.006229318678379059,
            0.0008119410485960543,
            -0.049176573753356934,
            -0.015419073402881622,
            0.036386873573064804,
            -0.005848619155585766,
            0.02275988832116127,
            -0.003820544108748436,
            0.015028112567961216,
            -0.01641957275569439,
            0.009369256906211376,
            0.015165919438004494,
            -0.022497763857245445,
            0.017966272309422493,
            -0.0011871048482134938,
            -0.030887320637702942,
            0.0066724191419780254,
            0.016263695433735847,
            0.019446546211838722,
            -0.04577375948429108,
            0.020200325176119804,
            0.02092139795422554,
            -0.018224598839879036,
            0.013094982132315636,
            -0.027855858206748962,
            0.04379347339272499,
            0.02338704839348793,
            0.022930217906832695,
            0.004109938163310289,
            0.006101470440626144,
            0.017200900241732597,
            -0.0076555232517421246,
            -0.0035239208955317736,
            0.032416343688964844,
            -0.010225493460893631,
            -0.013322873041033745,
            -0.011034197174012661,
            0.029893020167946815,
            -0.007809491828083992,
            0.03734108433127403,
            -0.019310586154460907,
            0.014042760245501995,
            0.01321369968354702,
            0.001249995781108737,
            0.00945030152797699,
            0.0041088867001235485,
            -0.032362889498472214,
            -0.015823429450392723,
            0.021531539037823677,
            0.022868191823363304,
            -0.002138720592483878,
            0.014404951594769955,
            -0.04579081013798714,
            0.005959497764706612,
            0.012327097356319427,
            0.0035948881413787603,
            0.0040115718729794025,
            0.01872982271015644,
            0.02063635177910328,
            -0.03715646639466286,
            -0.030504461377859116,
            0.00981308612972498,
            0.035044047981500626,
            -0.022842513397336006,
            0.035286955535411835,
            0.015807298943400383,
            0.010310035198926926,
            -0.02056989073753357,
            -0.05326419696211815,
            -0.02903297170996666,
            0.0418059416115284,
            0.03351033851504326,
            0.017490828409790993,
            0.013028733432292938,
            0.006977398414164782,
            0.0014034914784133434,
            0.004635969176888466,
            -0.023576607927680016,
            0.020875684916973114,
            -0.033296022564172745,
            0.019944263622164726,
            0.020985662937164307,
            -0.002487421967089176,
            0.006349002011120319,
            -0.004635079298168421,
            -0.012560657225549221,
            0.03094886615872383,
            0.016926687210798264,
            -0.023525414988398552,
            -0.01191150676459074,
            0.008261806331574917,
            0.012734156101942062,
            -0.012528722174465656,
            -0.023202568292617798,
            0.025784889236092567,
            0.035161007195711136,
            -0.014026130549609661,
            -0.009772119112312794,
            0.00989387184381485,
            -0.025950618088245392,
            0.007259403355419636,
            -0.04448198527097702,
            -0.015702689066529274,
            -0.03925146162509918,
            0.02191738784313202,
            0.02426713891327381,
            0.016829874366521835,
            -0.010865307413041592,
            0.035131074488162994,
            0.007570578251034021,
            -0.016614219173789024,
            -0.03434472158551216,
            -0.019867390394210815,
            0.017399149015545845,
            0.03474646061658859,
            -0.018296295776963234,
            0.02480214647948742,
            0.029000159353017807,
            -0.055069711059331894,
            0.023807747289538383,
            0.0026890623848885298,
            -0.0006891530938446522,
            -0.0353119894862175,
            -0.03017016500234604,
            -0.021200183779001236,
            0.0314895324409008,
            0.043813224881887436,
            0.000599670922383666,
            -0.01537236850708723,
            -0.01169903390109539,
            -0.025301320478320122,
            -0.0003297524817753583,
            -0.02523949183523655,
            -0.022941788658499718,
            0.019986558705568314,
            -0.02581443265080452,
            -0.04358451068401337,
            -0.00041791790863499045,
            0.010056122206151485,
            -0.00039760759682394564,
            -0.02314365655183792,
            -0.02084343694150448,
            -0.027906052768230438,
            -0.02488863281905651,
            0.02837279997766018,
            -0.019601324573159218,
            0.006067486014217138,
            -0.0039408546872437,
            -0.020742543041706085,
            -0.01241343654692173,
            0.018881404772400856,
            0.029494483023881912,
            0.028122486546635628,
            -0.012949018739163876,
            0.0063326372765004635,
            0.04303925111889839,
            -0.004483622964471579,
            0.003463896457105875,
            -0.00693508330732584,
            0.01272027101367712,
            -0.01711888611316681,
            -0.012902196496725082,
            0.04674892500042915,
            -0.03749217465519905,
            -0.0001585616555530578,
            0.01303305197507143,
            0.013170827180147171,
            -0.027927931398153305,
            0.003588703228160739,
            0.011135464534163475,
            -0.033676035702228546,
            0.0072318995371460915,
            0.005882755387574434,
            -0.018255120143294334,
            -0.017376072704792023,
            0.035789962857961655,
            -0.004008963704109192,
            -0.0035178489051759243,
            0.04066332429647446,
            0.012126222252845764,
            0.009179113432765007,
            0.015092920511960983,
            -0.03706035017967224,
            -0.02054871805012226,
            0.006475628353655338,
            -0.023809799924492836,
            -0.011070027947425842,
            -0.025002002716064453,
            -0.004653667099773884,
            -0.028310313820838928,
            0.007905072532594204,
            -0.017211882397532463,
            0.00020304112695157528,
            -0.027175387367606163,
            0.01799086667597294,
            0.013217251747846603,
            0.0038911381270736456,
            -0.002297915518283844,
            0.029491007328033447,
            0.028532981872558594,
            0.01358873676508665,
            -0.013150089420378208,
            -0.015086385421454906,
            0.009363166987895966,
            0.011120864190161228,
            -0.016649849712848663,
            0.01205560564994812,
            0.020763564854860306,
            0.01507548987865448,
            0.032629162073135376,
            -0.008026069030165672,
            -0.016666362062096596,
            0.005076260305941105,
            0.002339276485145092,
            0.02096155285835266,
            0.02487567812204361,
            0.009990831837058067,
            -0.007821337319910526,
            -0.014149020425975323,
            -0.01078934408724308,
            0.018718726933002472,
            -0.0007171428296715021,
            0.004935299511998892,
            -0.021465344354510307,
            -0.012938186526298523,
            0.033712465316057205,
            0.00022786362387705594,
            -0.01296102162450552,
            0.004301351960748434,
            -0.030305851250886917,
            -0.02732023037970066,
            0.030814217403531075,
            0.03583595156669617,
            0.0011554224183782935,
            0.030694181099534035,
            -0.03132838010787964,
            -0.020331939682364464,
            -0.00956600159406662,
            -0.020690662786364555,
            0.003297104500234127,
            0.023396532982587814,
            -0.00295680551789701,
            0.03119700960814953,
            -0.03214895725250244,
            0.0006402551662176847,
            0.04587392136454582,
            -0.002523865783587098,
            0.004984175320714712,
            0.012941836379468441,
            0.020692771300673485,
            0.026034489274024963,
            -0.017872324213385582,
            -0.003361932933330536,
            -0.023729076609015465,
            0.02118520811200142,
            -0.010014551691710949,
            -0.011873790994286537,
            0.007235859986394644,
            -0.009218081831932068,
            0.0075264279730618,
            0.041614633053541183,
            -0.008521314710378647,
            0.02259434387087822,
            0.013339653611183167,
            0.007804578170180321,
            -0.011564907617866993,
            0.022510934621095657,
            0.011517220176756382,
            -0.01516246609389782,
            0.016682036221027374,
            0.006044642999768257,
            -0.00990304909646511,
            -0.03130246326327324
        ],
        [
            0.016585949808359146,
            0.04328225180506706,
            0.010098999366164207,
            -0.0007664037402719259,
            0.0016955566825345159,
            -0.019250409677624702,
            0.028748583048582077,
            -0.0130960363894701,
            -0.009248364716768265,
            -0.005469979252666235,
            -0.002568401163443923,
            0.019366661086678505,
            -0.0011888383887708187,
            0.004529500845819712,
            0.029583318158984184,
            0.032164208590984344,
            0.024264566600322723,
            0.011350476182997227,
            -0.03205869719386101,
            -0.0010722411097958684,
            0.006684666033834219,
            -0.011017163284122944,
            -0.034311696887016296,
            0.007957987487316132,
            0.046149954199790955,
            0.02189921773970127,
            0.008542978204786777,
            0.008578922599554062,
            0.009924710728228092,
            0.02811235934495926,
            -0.04214710742235184,
            0.025581486523151398,
            -0.012353146448731422,
            -0.004623189102858305,
            0.025235002860426903,
            -0.006912201177328825,
            0.001152461743913591,
            0.03296046331524849,
            -0.015297136269509792,
            -0.00454515079036355,
            0.023895621299743652,
            -0.014864145778119564,
            0.0027991575188934803,
            0.009019449353218079,
            -0.019607914611697197,
            -0.030749166384339333,
            0.020534012466669083,
            -0.008124301210045815,
            -0.011733167804777622,
            -0.03566918522119522,
            -0.012172866612672806,
            -0.005293090827763081,
            0.023714561015367508,
            0.019861627370119095,
            -0.026406019926071167,
            -0.02063932456076145,
            -0.020715603604912758,
            0.002217215485870838,
            0.039943594485521317,
            0.0007588042062707245,
            0.004160408396273851,
            0.012174281291663647,
            -0.006930479779839516,
            -0.0030477335676550865,
            0.0038772833067923784,
            0.005250386893749237,
            -0.021655481308698654,
            0.007980385795235634,
            -0.011705133132636547,
            0.005820877850055695,
            -0.018514633178710938,
            -0.00047176561201922596,
            -0.03621405363082886,
            0.019519055262207985,
            -0.017945999279618263,
            -0.003424130380153656,
            0.03179992735385895,
            -0.024682024493813515,
            0.028951318934559822,
            0.016519717872142792,
            0.008109265938401222,
            -0.03491794690489769,
            0.019657906144857407,
            -0.02677040919661522,
            -0.0018944167532026768,
            -0.021218106150627136,
            0.026516355574131012,
            0.009857190772891045,
            -0.012125840410590172,
            0.046187713742256165,
            -0.01022571511566639,
            0.015927530825138092,
            0.010423758067190647,
            -0.052641741931438446,
            0.029833152890205383,
            -0.017945850268006325,
            -0.042043548077344894,
            -0.023974524810910225,
            -0.03982885181903839,
            0.026961496099829674,
            -0.04869161546230316,
            -0.04061909392476082,
            0.007076090667396784,
            0.017929868772625923,
            -0.02092289924621582,
            0.029487622901797295,
            0.0084565170109272,
            -0.033658239990472794,
            0.013763945549726486,
            -0.02274297922849655,
            0.0018241602228954434,
            0.036863211542367935,
            -0.021293137222528458,
            -0.03415720537304878,
            -0.03351666405797005,
            0.006386813707649708,
            -0.01636294461786747,
            0.013917680829763412,
            0.00015256967162713408,
            0.00030568946385756135,
            -0.027900494635105133,
            -0.02683032862842083,
            0.049655016511678696,
            0.0013354434631764889,
            -0.02374722808599472,
            0.005353942047804594,
            -0.04230009764432907,
            -0.009135398082435131,
            0.01814376749098301,
            0.014164934866130352,
            -0.010306543670594692,
            -0.05086267739534378,
            0.011192543432116508,
            -0.0009217137121595442,
            0.0048337168991565704,
            0.010676299221813679,
            -0.01037269551306963,
            -0.013690785504877567,
            0.007539419922977686,
            0.016774190589785576,
            -0.01930089108645916,
            -0.0056137037463486195,
            -0.036516331136226654,
            -0.006842832081019878,
            -0.022553741931915283,
            0.0582006461918354,
            0.002650848124176264,
            0.03114587999880314,
            0.006319187115877867,
            0.014534246176481247,
            0.031121039763092995,
            -0.023552339524030685,
            0.010969861410558224,
            0.03070867620408535,
            0.026994235813617706,
            0.01177193783223629,
            -0.006495899520814419,
            -0.021932607516646385,
            0.008571089245378971,
            -0.007456512656062841,
            -0.01189426239579916,
            -0.013710093684494495,
            -0.01871277391910553,
            -0.006283102557063103,
            -0.033355530351400375,
            0.020289961248636246,
            0.039831649512052536,
            -0.038069967180490494,
            -0.04856017604470253,
            0.025960741564631462,
            8.829872967908159e-06,
            -0.025073928758502007,
            -0.05047483742237091,
            -0.03213152661919594,
            0.023095518350601196,
            -0.02091650851070881,
            0.01017683558166027,
            0.005540215875953436,
            0.0005424400442279875,
            -0.0095863938331604,
            0.029334470629692078,
            -0.021525751799345016,
            0.005819784943014383,
            0.022679954767227173,
            -0.008228267543017864,
            -0.0012769750319421291,
            -0.005934686865657568,
            0.031674575060606,
            -0.008648647926747799,
            -0.02085893414914608,
            -0.024138452485203743,
            -0.005957761779427528,
            0.06277303397655487,
            -0.001972074853256345,
            0.016033267602324486,
            0.011222248896956444,
            -0.04925205931067467,
            0.024666760116815567,
            -0.019870812073349953,
            -0.027765007689595222,
            0.011120513081550598,
            0.009792037308216095,
            -0.003037509275600314,
            0.01785331219434738,
            -0.015161901712417603,
            0.015961136668920517,
            -0.0013097443152219057,
            0.004317304119467735,
            0.0006834773812443018,
            0.021316491067409515,
            0.008899177424609661,
            0.03113986924290657,
            0.041330479085445404,
            0.021264150738716125,
            -0.020255181938409805,
            -0.02091386914253235,
            0.03453828766942024,
            0.031445059925317764,
            0.029892629012465477,
            0.0002020984684349969,
            -0.01890457421541214,
            -0.02587183378636837,
            0.02610086277127266,
            0.0056997486390173435,
            0.028829239308834076,
            0.05953822657465935,
            -0.04008088633418083,
            0.012927902862429619,
            -0.01667727343738079,
            -0.015644758939743042,
            0.004984918050467968,
            -0.004716016817837954,
            0.017368018627166748,
            -0.015490919351577759,
            0.007396109402179718,
            -0.002684930805116892,
            0.0251883864402771,
            0.03158120438456535,
            0.009846307337284088,
            0.006135649047791958,
            -0.010878263041377068,
            0.02341553568840027,
            0.035931263118982315,
            -0.02920720912516117,
            -0.011726919561624527,
            0.0047386409714818,
            0.00939270295202732,
            0.01900404691696167,
            -0.021946096792817116,
            -0.004028573166579008,
            0.002745905891060829,
            -0.0019599376246333122,
            -0.012091086246073246,
            0.015073966234922409,
            -0.005919554736465216,
            -0.032261475920677185,
            -0.03510887920856476,
            0.016995074227452278,
            0.0343063585460186,
            -0.005875089671462774,
            0.026189293712377548,
            0.03532719239592552,
            0.012391495518386364,
            0.024945739656686783,
            0.01892276294529438,
            0.03310433402657509,
            0.022249367088079453,
            -0.0212103221565485,
            0.005422577261924744,
            -0.029171209782361984,
            0.03167419880628586,
            0.00378485512919724,
            0.021770840510725975,
            -0.04417328163981438,
            -0.03138354420661926,
            -0.0268308836966753,
            0.031881824135780334,
            0.015400881879031658,
            -0.0077729676850140095,
            0.01723327487707138,
            0.0182802677154541,
            0.010177145712077618,
            0.01677999459207058,
            -0.027003150433301926,
            0.010843777097761631,
            0.03354847803711891,
            0.006041195243597031,
            0.006287208292633295,
            -0.012759260833263397,
            -0.018921831622719765,
            0.001810695044696331,
            0.022360501810908318,
            -0.005026785656809807,
            0.004828627221286297,
            0.023893725126981735,
            0.008376779966056347,
            -0.009852386079728603,
            -0.001598847913555801,
            0.016365623101592064,
            -0.01485621090978384,
            0.02934904210269451,
            0.00900394469499588,
            0.00033486803295090795,
            0.003027893602848053,
            -0.016239818185567856,
            -0.0481230728328228,
            0.010552652180194855,
            0.019277524203062057,
            -0.01157132163643837,
            0.007432251702994108,
            0.0025700402911752462,
            0.019973106682300568,
            0.009437603875994682,
            -0.013193786144256592,
            -0.0301524568349123,
            0.007445675786584616,
            0.010332728736102581,
            0.00015823327703401446,
            0.0015793712809681892,
            0.029563182964920998,
            -0.016622032970190048,
            0.014639290980994701,
            -0.018807612359523773,
            -0.03092576563358307,
            0.027833648025989532,
            -0.0038324922788888216,
            0.009566176682710648,
            0.035102542489767075,
            -0.019512053579092026,
            -0.02631257474422455,
            -0.0061759562231600285,
            -0.03996884077787399,
            0.00566794304177165,
            0.0014627884374931455,
            0.004754351917654276,
            -0.00935160182416439,
            0.02005704864859581,
            0.033970050513744354,
            -0.027488762512803078,
            0.016737889498472214,
            0.019360467791557312,
            0.027902619913220406,
            -0.002130792010575533,
            0.014474177733063698,
            0.027048401534557343,
            0.0007859074976295233,
            0.01933140493929386,
            -0.03254377469420433,
            -0.004304652567952871,
            -0.03819247707724571,
            -0.007680293172597885,
            0.027191417291760445,
            -0.020908234640955925,
            -0.004895742051303387,
            0.01580306515097618,
            -0.011142967268824577,
            0.030426086857914925,
            0.0077167777344584465,
            0.012008211575448513,
            0.0016633030027151108,
            -0.000682008103467524,
            -0.012952023185789585,
            0.004222831688821316,
            -0.002849492011591792,
            -0.04095141962170601,
            -0.030798761174082756,
            0.013728097081184387,
            -0.010442763566970825,
            0.015070564113557339,
            -0.001515687326900661,
            0.0018807791639119387,
            -0.02239275723695755,
            -0.04827834293246269,
            0.016509437933564186,
            0.014619412831962109,
            0.030256744474172592,
            -0.010385970585048199,
            -0.053702909499406815,
            0.015542061068117619,
            0.009151142090559006,
            -0.04191682115197182,
            -0.016335325315594673,
            0.03602166473865509,
            0.004634550306946039,
            0.01875847950577736,
            -0.03170193359255791,
            0.015993576496839523,
            0.030856484547257423,
            0.00685130525380373,
            -0.022898340597748756,
            -0.020108656957745552,
            0.031509824097156525,
            0.04071333631873131,
            -0.011223485693335533,
            0.04848555848002434,
            0.02536586858332157,
            -0.007697386667132378,
            0.035926494747400284,
            0.017409252002835274,
            -0.026401201263070107,
            0.017388753592967987,
            0.023394718766212463,
            0.0015368935419246554,
            0.0007372756954282522,
            0.016734974458813667,
            -0.03197914734482765,
            -0.009995455853641033,
            -0.00857187807559967,
            -0.010101288557052612,
            -0.026447145268321037,
            -0.028552908450365067,
            -0.034516867250204086,
            0.00653805723413825,
            -0.011259939521551132,
            -0.007171913981437683,
            0.00741113955155015,
            0.01567145809531212,
            -0.030064553022384644,
            -0.024341020733118057,
            0.01629396714270115,
            -0.03452078625559807,
            -0.04556151106953621,
            0.0033097704872488976,
            -0.01908883824944496,
            0.024455657228827477,
            0.02491155080497265,
            0.025034116581082344,
            -0.03985399380326271,
            -0.01972910203039646,
            0.007905579172074795,
            -0.045522503554821014,
            -0.02516535483300686,
            0.01394867803901434,
            -0.01172288041561842,
            0.011458851397037506,
            -0.02256092056632042,
            0.04247862473130226,
            0.023293090984225273,
            -0.03557898849248886,
            0.012237396091222763,
            -0.003729034448042512,
            0.023145310580730438,
            -0.005825541447848082,
            0.011159413494169712,
            -0.0035685484763234854,
            0.018511485308408737,
            -0.014990613795816898,
            -0.053579818457365036,
            0.003406945616006851,
            -0.03923236206173897,
            -0.013192801736295223,
            -0.011925224214792252,
            -0.024166755378246307,
            -0.021426809951663017,
            -0.008628416806459427,
            0.022484030574560165,
            0.0036777385976165533,
            -0.01263827458024025,
            0.019267208874225616,
            -0.004007918760180473,
            -0.016743166372179985,
            -0.026972554624080658,
            0.012334476225078106,
            -0.014571844600141048,
            0.0009541109320707619,
            0.015442583709955215,
            -0.009141713380813599,
            0.009737449698150158,
            0.018001887947320938,
            0.03010084480047226,
            0.015345392748713493,
            0.007935110479593277,
            0.014417244121432304,
            -0.008968404494225979,
            0.010609989054501057,
            -0.019052447751164436,
            0.020207833498716354,
            0.007695099338889122,
            -0.01413785945624113,
            -0.011345903389155865,
            -0.004605935420840979,
            -0.011209642514586449,
            -0.030830498784780502,
            0.025829626247286797,
            0.017004704102873802,
            -0.005756468512117863,
            -0.025834333151578903,
            -0.020153937861323357,
            0.06619828939437866,
            -0.03835245221853256,
            0.0478123277425766,
            0.005560803227126598,
            0.023833829909563065,
            0.008816869929432869,
            -0.029840078204870224,
            0.015533765777945518,
            -0.032224636524915695,
            0.0058801486156880856,
            0.014127791859209538,
            -0.004048231057822704,
            -0.021299714222550392,
            0.00023921173124108464,
            -0.02050006203353405,
            -0.007043538615107536,
            -0.0013888893881812692,
            0.006982147227972746,
            0.003488032380118966,
            0.021497322246432304,
            0.006035246420651674,
            0.005922037176787853,
            0.01547149010002613,
            -0.012333409860730171,
            0.021495964378118515,
            -0.03451317548751831,
            -0.02075495570898056,
            0.0261211134493351,
            -0.009183092042803764,
            -0.017254935577511787,
            0.020369181409478188,
            -0.00013520791253540665,
            0.04104836657643318,
            -0.025073960423469543,
            -0.0070532443933188915,
            -0.0298258475959301,
            -0.003154868260025978,
            0.02155088447034359,
            0.01380801573395729,
            -0.009449649602174759,
            -0.012373492121696472,
            -0.04352503642439842,
            -0.009720574133098125,
            0.00879131443798542,
            -0.013289921917021275,
            0.027088642120361328,
            0.02259434387087822,
            -0.011867448687553406,
            -0.0034920331090688705,
            0.029098674654960632,
            -0.020083842799067497,
            0.023603148758411407,
            0.014100740663707256,
            -0.018471084535121918,
            0.0030685397796332836,
            -0.040662478655576706,
            0.023798299953341484,
            0.02367113158106804,
            0.031308021396398544,
            0.02301008440554142,
            0.008124759420752525,
            -0.034361451864242554,
            0.010921457782387733,
            -0.009742890484631062,
            -0.008206787519156933,
            -0.011312653310596943,
            -0.010488451458513737,
            0.007225682958960533,
            -0.007436592131853104,
            -0.011008810251951218,
            -0.02219243533909321,
            -0.02326425351202488,
            -0.0037639762740582228,
            0.014480167999863625,
            0.004621495958417654,
            0.014830551110208035,
            0.012906080111861229,
            0.03308132663369179,
            0.038172077387571335,
            -0.01970139518380165,
            -0.03781195729970932,
            0.03127216547727585,
            0.0035687305498868227,
            0.019314106553792953,
            0.00493601243942976,
            -0.015844028443098068,
            -0.004102778621017933,
            -0.031243562698364258,
            -0.00999374594539404,
            0.013795983046293259,
            0.050805628299713135,
            -0.004443937446922064,
            0.014939142391085625,
            -0.017450707033276558,
            0.03196990117430687,
            0.012011293321847916,
            -0.0359918437898159,
            0.022341791540384293,
            0.008107093162834644,
            0.0036655322182923555,
            -0.030489493161439896,
            -0.006373395211994648,
            -0.042910583317279816,
            0.03451399505138397,
            0.03708804398775101,
            0.007566060405224562,
            0.0009219004423357546,
            0.010698279365897179,
            -0.018181683495640755,
            -0.01824667677283287,
            0.03477634862065315,
            0.045923858880996704,
            0.01511550322175026,
            0.008937149308621883,
            -0.004919291008263826,
            0.016497749835252762,
            0.003179509425535798,
            0.024417251348495483,
            0.002264672890305519,
            0.03486419841647148,
            0.013953004963696003,
            -0.009527044370770454,
            -0.004498932044953108,
            -0.015375591814517975,
            0.028476495295763016,
            -0.028383411467075348,
            0.005079370457679033,
            -0.001825871178880334,
            -0.020947851240634918,
            -0.014490095898509026,
            0.02717522904276848,
            0.022275710478425026,
            0.02049071528017521,
            0.012906207703053951,
            -0.02526136115193367,
            -0.005531264003366232,
            0.0015534729463979602,
            -0.0180656798183918,
            0.02138545736670494,
            0.009682172909379005,
            -0.006814544554799795,
            -0.007652981672435999,
            0.02025299333035946,
            -0.009993148036301136,
            -0.02674880437552929,
            -0.00593200558796525,
            0.03736186772584915,
            -0.002651429735124111,
            -0.00604135449975729,
            -0.014760520309209824,
            0.03418048843741417,
            0.022640129551291466,
            -0.002507128519937396,
            -0.04567462578415871,
            0.0016036516753956676,
            0.02578832395374775,
            0.010900643654167652,
            -0.04654678702354431,
            0.024182258173823357,
            -0.024845311418175697,
            -0.011978269554674625,
            0.03663410246372223,
            0.026018010452389717,
            -0.025688087567687035,
            0.01770261488854885,
            -0.013332439586520195,
            -0.027868086472153664,
            -0.004789417143911123,
            0.010785856284201145,
            0.0338689349591732,
            0.019391609355807304,
            -0.017403602600097656,
            -0.026716800406575203,
            0.02075457200407982,
            0.02566734328866005,
            0.00436603045091033,
            -0.012191437184810638,
            0.005385189317166805,
            0.026800578460097313,
            -0.007355982903391123,
            -0.022549543529748917,
            0.0232556015253067,
            -0.0037532756105065346,
            -0.034521061927080154,
            -0.02378455176949501,
            0.026334263384342194,
            -0.00880243070423603,
            0.012436903081834316,
            0.002420205855742097,
            -0.016755957156419754,
            -0.0038811054546386003,
            0.018229307606816292,
            -0.0035726013593375683,
            -0.02134198695421219,
            0.029738759621977806,
            0.008652064017951488,
            -0.02469860576093197,
            -0.024759849533438683,
            0.03131965920329094,
            0.026058120653033257,
            -0.015231788158416748,
            -0.0060320510528981686,
            -0.040660057216882706,
            -0.010205449536442757,
            -0.011150028556585312,
            -0.022167138755321503,
            0.0010469866683706641,
            0.04022825136780739,
            0.024855025112628937,
            -0.012617251835763454,
            -0.030424436554312706,
            -0.0006701096426695585,
            0.022387271746993065,
            -0.01082263607531786,
            -0.02720264159142971,
            0.00328426668420434,
            0.01853226125240326,
            -0.04166005179286003,
            0.0015012257499620318,
            -0.010496679693460464,
            -0.004898684564977884,
            0.02239813841879368,
            -0.007521756924688816,
            -0.01197635568678379,
            0.023626020178198814,
            -0.028114236891269684,
            0.007076098583638668,
            -0.010706210508942604,
            -0.016808142885565758,
            -0.03147364407777786,
            0.016230477020144463,
            -0.0021876064129173756,
            0.007060627918690443,
            -0.0285523422062397,
            0.0022535044699907303,
            0.0007570682209916413,
            0.029307100921869278,
            -0.02028188668191433,
            0.028411857783794403,
            -0.00278631248511374,
            0.0013025641674175858,
            0.02559736557304859,
            0.023587539792060852,
            -0.00277807773090899,
            0.02130662091076374,
            -0.001577261951752007,
            -0.014725739136338234,
            0.033457133919000626,
            -0.033976033329963684,
            0.015374524518847466,
            -0.011363782919943333,
            0.040908265858888626,
            0.020233048126101494,
            0.019016701728105545,
            0.013171923346817493,
            -0.028414709493517876,
            0.014332537539303303,
            -0.04258449748158455,
            0.06745339930057526,
            0.007253115065395832,
            -0.009514517150819302,
            -0.018193049356341362,
            0.007705672178417444,
            -0.02651098743081093,
            -0.012288550846278667,
            -0.024678679183125496,
            0.024481099098920822,
            0.028069468215107918,
            0.015370490029454231,
            0.0022391462698578835,
            -0.007382493000477552,
            -0.02906632050871849,
            0.013026283122599125,
            0.038308944553136826,
            0.020313257351517677,
            -0.013282052241265774,
            -0.009644453413784504,
            -0.019184956327080727,
            0.022446250542998314,
            0.012900016270577908,
            0.004188601393252611,
            0.01689806394279003,
            -0.0024539108853787184,
            -0.015374371781945229,
            0.011381507851183414,
            0.04397151991724968,
            0.01577245257794857,
            -0.014678885228931904,
            -0.010907365009188652,
            -0.010716048069298267,
            0.0038384348154067993,
            0.031974680721759796,
            -0.0368553102016449,
            0.012185868807137012,
            -0.007801457773894072,
            -0.013953935354948044,
            0.02285328321158886,
            -0.022093916311860085,
            -0.009374447166919708,
            -0.020416785031557083,
            0.023276634514331818,
            -0.021426865831017494,
            -0.002399998717010021,
            0.023451127111911774,
            -0.0204671248793602,
            0.041815385222435,
            -0.026888437569141388,
            0.004154653288424015,
            0.006656238809227943,
            0.017229201272130013,
            -0.03390563279390335,
            0.015678711235523224,
            0.004224576987326145,
            -0.0033804820850491524,
            0.0006032492965459824,
            -0.043026894330978394,
            0.03178819268941879,
            -0.02448110468685627,
            0.013726535253226757,
            0.005899640265852213,
            2.6684856493375264e-05,
            -0.045205388218164444,
            0.01851869560778141,
            -0.0011594697134569287,
            0.011670653708279133,
            0.030816810205578804,
            -0.014108777977526188,
            0.008812195621430874,
            -0.01774747483432293,
            0.004649963695555925,
            0.020233383402228355,
            -0.026908637955784798,
            0.008630849421024323,
            0.03924212604761124,
            0.03099512867629528,
            -0.022760096937417984,
            0.019883420318365097,
            0.03437918797135353,
            0.017518866807222366,
            -0.021778540685772896,
            0.02672691084444523,
            -0.0015889873029664159,
            -0.010892268270254135,
            -0.0070092142559587955,
            0.015502974390983582,
            0.04165138676762581,
            0.00763498293235898,
            0.003445056965574622,
            0.028399767354130745,
            0.0027523322496563196,
            -0.02162567339837551,
            -0.029757985845208168,
            0.02218877524137497,
            0.01883871480822563,
            0.004139462485909462,
            0.02371051348745823,
            0.009948549792170525,
            -0.00459176953881979,
            0.015101413242518902,
            0.003919436130672693,
            -0.035158898681402206,
            0.012409454211592674,
            -0.0014479823876172304,
            -0.0058007799088954926,
            0.022290335968136787,
            -0.02541341260075569,
            0.023760223761200905,
            0.01357744075357914,
            -0.013183197937905788,
            -0.032338470220565796,
            0.006785686127841473,
            0.026467682793736458,
            -0.001325415913015604,
            -0.04028499498963356,
            0.0032458556815981865,
            0.0012867405312135816,
            -0.007630108390003443,
            0.016502918675541878,
            -0.01683962345123291,
            0.0052662501111626625,
            0.01076890155673027,
            -0.0040331692434847355,
            0.003363115480169654,
            0.01921173743903637,
            -0.032169051468372345,
            0.003744125831872225,
            0.020644931122660637,
            0.039219170808792114,
            0.020556949079036713,
            0.024893179535865784,
            0.009058297611773014,
            -0.019798165187239647,
            0.02880929410457611,
            -0.004411780741065741,
            0.02473941631615162,
            -0.023919809609651566,
            2.3652357413084246e-05,
            -0.02095009572803974,
            0.01640154793858528,
            -0.04496949538588524,
            0.006414519157260656,
            0.007706105709075928,
            -0.04413483291864395,
            -0.01027167309075594,
            0.004977737087756395,
            0.0004765877965837717,
            -0.03985379636287689,
            -0.017345180734992027,
            -0.01688339374959469,
            0.02587089128792286,
            0.009451116435229778,
            -0.0007915366441011429,
            -0.02296953834593296,
            0.002634193515405059,
            0.00568299600854516,
            0.03846728801727295,
            0.005027289502322674,
            0.043825503438711166,
            0.03679358586668968,
            -0.012490346096456051,
            -0.011388192884624004,
            -0.025783181190490723,
            -0.021999554708600044,
            -0.0033452201168984175,
            -0.030068373307585716,
            -0.006174617446959019,
            0.03605199605226517,
            -0.002252398058772087,
            -0.030783681198954582,
            -0.038120292127132416,
            -0.013357055373489857,
            -0.01607777550816536,
            0.025869831442832947,
            -0.029967376962304115,
            0.025308474898338318,
            0.0511825792491436,
            0.016502240672707558,
            0.01267261989414692,
            0.014410640113055706,
            0.005317257717251778,
            -0.028664909303188324,
            0.03607270494103432,
            -0.004132221918553114,
            -0.011516820639371872,
            -0.00921153649687767,
            -0.026664458215236664,
            0.022461464628577232,
            0.014698575250804424,
            0.026290584355592728,
            0.013809974305331707,
            0.014370177872478962,
            -3.6569592339219525e-05,
            -0.01972999982535839,
            0.00827856920659542,
            -0.012671573087573051,
            0.0008046507136896253,
            0.02200777269899845,
            -0.014025798067450523,
            0.02794320322573185,
            0.005206138826906681,
            0.011055280454456806,
            0.008019846864044666,
            0.02647203765809536,
            0.01659371517598629,
            0.031275153160095215,
            0.01734675094485283,
            -0.052019841969013214,
            0.03431124985218048,
            0.028019636869430542,
            0.018977388739585876,
            0.025975562632083893,
            0.02057284116744995,
            -0.008921475149691105,
            0.052619852125644684,
            0.04074590280652046,
            -0.04784638062119484,
            -0.003631045576184988,
            -0.020063985139131546,
            0.013936781324446201,
            0.011167751625180244,
            0.0009839128470048308,
            0.00015659611381124705,
            0.019688326865434647,
            -0.006722699850797653,
            -0.021472197026014328,
            -0.024633800610899925,
            -0.0007373631815426052,
            0.006046650465577841,
            0.03109961934387684,
            -0.01458249893039465,
            -0.02459004707634449,
            0.015734996646642685,
            -0.011118123307824135,
            -0.022826148197054863,
            0.006821403745561838,
            0.037569835782051086,
            -0.010179035365581512,
            0.01736036315560341,
            -0.028171883895993233,
            -0.01606396585702896,
            -0.0006700986996293068,
            -0.00835358165204525,
            -0.008523269556462765,
            -0.0028506785165518522,
            0.010964865796267986,
            0.03503965586423874,
            0.01846405677497387,
            -0.0156666561961174,
            0.029563751071691513,
            0.01847205124795437,
            -0.03103603981435299,
            0.021114397794008255,
            -0.01775791309773922,
            0.01179626863449812,
            -0.006511724088340998,
            -0.024682190269231796,
            -0.027731329202651978,
            0.0019150199368596077,
            -0.007800646126270294,
            0.01048583909869194,
            -0.02203010767698288,
            -0.01945026032626629,
            -0.01682300679385662,
            0.00922535639256239,
            -0.03341319411993027,
            0.01469236146658659,
            0.007738427724689245,
            -0.017628921195864677,
            -0.024379445239901543,
            0.005879860371351242,
            -0.027039071545004845,
            -0.017317932099103928,
            0.027040211483836174,
            0.0336751788854599,
            -0.043072011321783066,
            -0.020243218168616295
        ],
        [
            0.022210320457816124,
            -0.0072835697792470455,
            0.03228218853473663,
            0.033241722732782364,
            -0.014954132959246635,
            -0.02503865398466587,
            0.004186195321381092,
            -0.015635447576642036,
            0.015627415850758553,
            -0.009818248450756073,
            0.015576822683215141,
            -0.005372403189539909,
            -0.016506290063261986,
            -0.006180821917951107,
            -0.008733229711651802,
            0.009060131385922432,
            0.01617579162120819,
            0.03211440518498421,
            -0.025449831038713455,
            -0.01675604283809662,
            0.036341872066259384,
            -0.024536384269595146,
            0.028921404853463173,
            0.010835357941687107,
            0.0197056345641613,
            -0.03797405585646629,
            -0.022415615618228912,
            -0.04040330648422241,
            -0.017261594533920288,
            -0.003361480077728629,
            0.009089699946343899,
            0.02190519869327545,
            -0.01793224737048149,
            -0.032923150807619095,
            0.023668166249990463,
            0.00191497930791229,
            -0.011915896087884903,
            -0.012620789930224419,
            0.021521996706724167,
            0.029737146571278572,
            0.0028311663772910833,
            0.041090503334999084,
            -0.018583210185170174,
            -0.0036185174249112606,
            -0.006784535478800535,
            -0.018827257677912712,
            0.00796880666166544,
            -0.008502303622663021,
            -0.023344945162534714,
            0.04806214198470116,
            0.0025058381725102663,
            0.0060285963118076324,
            -0.002230769954621792,
            -0.01600002683699131,
            -0.005765483248978853,
            0.0006114793359301984,
            -0.02550368569791317,
            -0.008088313043117523,
            0.0005538816330954432,
            0.022623959928750992,
            0.027502816170454025,
            0.005660145543515682,
            0.010642314329743385,
            -0.00915947649627924,
            0.020468300208449364,
            -0.01719142124056816,
            0.023383257910609245,
            -0.015514708124101162,
            0.007500439416617155,
            0.012126792222261429,
            0.031612981110811234,
            -0.023233667016029358,
            0.004232148174196482,
            -0.010672555305063725,
            -0.026861529797315598,
            0.017340049147605896,
            0.01662350632250309,
            -0.01856349967420101,
            0.0010075669270008802,
            -0.010703912936151028,
            0.02537536434829235,
            0.014778964221477509,
            0.0015374509384855628,
            -0.026385830715298653,
            -0.0079864002764225,
            0.015089492313563824,
            -0.022045521065592766,
            -0.000615921919234097,
            0.03421954810619354,
            0.01938707008957863,
            -0.0015981477918103337,
            -0.0026946470607072115,
            0.019139431416988373,
            0.042057156562805176,
            -0.017919346690177917,
            0.003861283650621772,
            -0.03727263957262039,
            0.002961906837299466,
            0.05216296762228012,
            -0.020076919347047806,
            0.024103811010718346,
            -0.019026856869459152,
            -0.022689389064908028,
            -0.013768001459538937,
            0.0033443174324929714,
            -0.017428871244192123,
            -0.0022103418596088886,
            0.029482152312994003,
            -0.005570427048951387,
            -0.005157416686415672,
            0.029913226142525673,
            -0.018258240073919296,
            0.033165473490953445,
            0.011011096648871899,
            0.07612024992704391,
            -0.026437805965542793,
            -0.014344309456646442,
            -0.0038165331352502108,
            -0.016805730760097504,
            0.010610555298626423,
            0.005438291467726231,
            0.018243396654725075,
            -0.04932066425681114,
            0.0299312062561512,
            0.0051903678104281425,
            0.035813089460134506,
            0.010421046987175941,
            -0.003057403489947319,
            0.019796518608927727,
            0.030381537973880768,
            -0.01994701847434044,
            0.016047105193138123,
            0.010392406024038792,
            -0.02169731631875038,
            0.03105710819363594,
            -0.029593534767627716,
            0.002477658446878195,
            0.014953159727156162,
            -0.00626111775636673,
            0.017009038478136063,
            0.034884773194789886,
            0.007019411772489548,
            -0.010393447242677212,
            0.03011481463909149,
            -0.003254188457503915,
            0.0002951628703158349,
            0.008330088108778,
            -0.02012907899916172,
            0.027450643479824066,
            0.023515569046139717,
            -0.016262490302324295,
            0.010327022522687912,
            -0.009863766841590405,
            -0.005558270029723644,
            -0.01689784973859787,
            0.025995297357439995,
            -0.016051070764660835,
            -0.021694103255867958,
            0.03805353865027428,
            0.007888630032539368,
            0.025040507316589355,
            -0.02185082994401455,
            -0.006265462376177311,
            -0.015582549385726452,
            0.014892062172293663,
            0.01301669143140316,
            0.025240803137421608,
            0.03477337583899498,
            0.028595147654414177,
            0.028692876920104027,
            -0.0008965381421148777,
            -0.030190205201506615,
            0.0029402454383671284,
            0.028952402994036674,
            0.016655972227454185,
            -0.01141760591417551,
            0.01224503479897976,
            0.01698479987680912,
            0.03777626156806946,
            -0.020573383197188377,
            -0.0038287595380097628,
            0.017368242144584656,
            0.004857400897890329,
            0.013775739818811417,
            0.029614342376589775,
            0.03585295006632805,
            0.03187178447842598,
            0.014199905097484589,
            0.0019693474750965834,
            0.019425997510552406,
            -0.02989022433757782,
            0.01180555485188961,
            0.02807198464870453,
            0.0019700939301401377,
            0.02756917104125023,
            0.01589052751660347,
            0.025037866085767746,
            -0.015513304620981216,
            -0.018915222957730293,
            -0.007981253787875175,
            0.03363674879074097,
            0.016260379925370216,
            -0.001865684287622571,
            0.012387637048959732,
            0.010641933418810368,
            0.01427245419472456,
            -0.035756248980760574,
            -0.009409936144948006,
            0.03232235461473465,
            0.026792319491505623,
            0.019292818382382393,
            -0.0033383991103619337,
            -0.005382665432989597,
            -0.003098770510405302,
            -0.009942876175045967,
            0.0011661056196317077,
            -0.0007409922545775771,
            0.0061298091895878315,
            -0.010155503638088703,
            0.013599857687950134,
            0.001357093919068575,
            -0.0037218460347503424,
            -0.014380260370671749,
            0.03822075203061104,
            0.02535449154675007,
            -0.02148507349193096,
            0.01759319007396698,
            -0.00075583066791296,
            0.03633785620331764,
            -0.003855660790577531,
            0.0358300656080246,
            0.015482458285987377,
            -0.007371334359049797,
            0.02419104613363743,
            0.03076348640024662,
            -0.02193523943424225,
            -0.02727472223341465,
            0.030418507754802704,
            -0.026042087003588676,
            -0.02641112543642521,
            0.02969338186085224,
            0.004383462015539408,
            0.0018987728981301188,
            -0.011269552633166313,
            0.00542061310261488,
            -0.0113278953358531,
            0.032565101981163025,
            -0.017723001539707184,
            0.022951705381274223,
            0.013152150437235832,
            0.02078879065811634,
            0.03617967292666435,
            -0.0021344427950680256,
            -0.004499089438468218,
            -0.014904169365763664,
            0.00023895667982287705,
            -0.02617492526769638,
            -0.018679864704608917,
            -0.02470303513109684,
            0.027967996895313263,
            0.011653141118586063,
            0.02052900195121765,
            0.013842873275279999,
            -0.019852841272950172,
            -0.04288575053215027,
            -0.0031350012868642807,
            0.03242509067058563,
            0.005107938311994076,
            0.005310520995408297,
            0.0006595334853045642,
            0.007660608738660812,
            0.015853388234972954,
            0.02447553351521492,
            -0.017492415383458138,
            0.03513927757740021,
            -0.013734526932239532,
            0.005947942845523357,
            -0.021452251821756363,
            0.007207474205642939,
            -0.02164584957063198,
            -0.03857883810997009,
            -0.0004207307647448033,
            0.004677716642618179,
            -0.03148997202515602,
            0.008549205027520657,
            -0.023695407435297966,
            0.013747043907642365,
            -0.01669294387102127,
            -0.021955275908112526,
            -0.02537136897444725,
            -0.022075273096561432,
            -0.03706895560026169,
            -0.01855439879000187,
            0.026484359055757523,
            -0.04130864515900612,
            0.042395301163196564,
            0.023753654211759567,
            -0.0173986554145813,
            0.016849305480718613,
            0.030474862083792686,
            0.017367135733366013,
            -0.0007675265078432858,
            0.026646755635738373,
            -0.010972283780574799,
            0.014108162373304367,
            0.02372339367866516,
            -0.009929660707712173,
            -0.013315031304955482,
            -0.02052837610244751,
            0.02454497665166855,
            0.008171321824193,
            -0.023258959874510765,
            0.020318735390901566,
            0.01930893585085869,
            -0.016157889738678932,
            0.027912719175219536,
            -0.013325374573469162,
            -0.025070391595363617,
            -0.026971247047185898,
            0.0044806585647165775,
            -0.003192814765498042,
            -0.027728930115699768,
            0.009848503395915031,
            0.003917756490409374,
            -0.009151888079941273,
            -0.0026644922327250242,
            0.011641337536275387,
            -0.026017703115940094,
            -0.022402962669730186,
            -0.028402937576174736,
            0.01883794367313385,
            0.016236115247011185,
            0.002604380017146468,
            0.0039385538548231125,
            0.01629970222711563,
            0.00015894036914687604,
            -0.019830182194709778,
            0.00835949182510376,
            0.025523360818624496,
            -0.022009413689374924,
            -0.004377910401672125,
            -0.03961620107293129,
            0.002284454880282283,
            -0.00752994604408741,
            0.02140696346759796,
            -0.003976345993578434,
            0.027344772592186928,
            0.02364729344844818,
            0.012127735652029514,
            0.02808321639895439,
            0.017704350873827934,
            -0.00021314033074304461,
            -0.016007263213396072,
            -0.013175341300666332,
            -0.014181150123476982,
            0.000641383696347475,
            0.029661908745765686,
            -0.017825279384851456,
            -0.00024067329650279135,
            0.02739064395427704,
            -0.009445114992558956,
            0.027670975774526596,
            -0.019237086176872253,
            0.012619652785360813,
            0.02403230592608452,
            -0.00947137176990509,
            -0.005145602859556675,
            -0.012950981967151165,
            0.024249743670225143,
            0.0033289045095443726,
            0.019294781610369682,
            -0.0013999862130731344,
            -0.03206446021795273,
            0.008748205378651619,
            -0.014470553025603294,
            -0.008399530313909054,
            0.005881663877516985,
            0.02260546199977398,
            0.021661115810275078,
            -0.0059526399709284306,
            0.014267579652369022,
            -0.011686353944242,
            -0.02629099041223526,
            -0.031910255551338196,
            -0.0020319505129009485,
            0.04632872715592384,
            0.006269481964409351,
            -0.0283979345113039,
            0.030451547354459763,
            -0.01490981038659811,
            -0.012953906320035458,
            0.01860523782670498,
            0.03424246236681938,
            0.03849109634757042,
            -0.01894369162619114,
            0.01273895613849163,
            0.015000566840171814,
            0.02500634826719761,
            -0.0029552322812378407,
            -0.02376611717045307,
            -0.00879252515733242,
            0.03294011950492859,
            -0.01637171395123005,
            -0.0018039431888610125,
            -0.012539914809167385,
            -0.018510859459638596,
            0.02930019609630108,
            0.004801427014172077,
            -0.0019630747847259045,
            0.01776866801083088,
            -0.013322620652616024,
            0.009200891479849815,
            0.011914679780602455,
            0.005346497520804405,
            -0.00447005033493042,
            0.022737208753824234,
            -0.020540030673146248,
            0.005980013869702816,
            0.014845437370240688,
            0.022968528792262077,
            0.02604418806731701,
            -0.0250799972563982,
            0.05015198141336441,
            -0.015891283750534058,
            0.032943084836006165,
            -0.010355176404118538,
            -0.006199749186635017,
            -0.01973091997206211,
            0.009738531894981861,
            0.04077243432402611,
            0.0011318251490592957,
            0.007227955386042595,
            -0.0050649396143853664,
            0.002223632065579295,
            -0.007370386738330126,
            0.02154483273625374,
            -0.027039071545004845,
            -0.0022766306065022945,
            0.027284692972898483,
            -0.03336261585354805,
            -0.003170065116137266,
            -0.035957638174295425,
            0.031377337872982025,
            0.008687672205269337,
            0.029503516852855682,
            0.03295233100652695,
            0.02270733192563057,
            0.08621754497289658,
            -0.004785656463354826,
            -0.004913817625492811,
            -0.028617260977625847,
            0.016890591010451317,
            -0.022092429921030998,
            0.03098670020699501,
            -0.0010077886981889606,
            -0.012492855079472065,
            0.030908335000276566,
            0.017482314258813858,
            0.013875340111553669,
            -0.010680205188691616,
            -0.002055494813248515,
            0.008867793716490269,
            -0.019876329228281975,
            0.016747497022151947,
            -0.019416673108935356,
            0.00108981947414577,
            0.022794609889388084,
            -0.009049349464476109,
            0.017375433817505836,
            0.0036042602732777596,
            0.02065989375114441,
            -0.004889545496553183,
            0.006614032667130232,
            0.024465695023536682,
            -0.024865997955203056,
            -0.02562982775270939,
            -0.0031878401059657335,
            0.013760106638073921,
            0.0018929707584902644,
            -0.01991315186023712,
            -0.032349344342947006,
            -0.02995934709906578,
            0.016683483496308327,
            0.008232167921960354,
            -0.0003135460428893566,
            0.016108447685837746,
            -0.02158431150019169,
            -0.011015783064067364,
            0.003981446381658316,
            0.00019827988580800593,
            0.027739785611629486,
            0.0016994760371744633,
            0.032758697867393494,
            -0.017886999994516373,
            0.012328688986599445,
            -0.015457715839147568,
            -0.005748190451413393,
            0.004735647235065699,
            -0.020138317719101906,
            0.02502821572124958,
            0.015846671536564827,
            -0.0005630490486510098,
            0.029260512441396713,
            0.005799517035484314,
            -0.0036642663180828094,
            -0.008076992817223072,
            -0.016388483345508575,
            -0.02599753811955452,
            0.009233266115188599,
            -0.010514712892472744,
            -0.0019069226691499352,
            -0.008967784233391285,
            0.03467455133795738,
            -0.0337676964700222,
            0.03783373534679413,
            0.02072242647409439,
            -0.02596866525709629,
            0.0019630747847259045,
            0.031053001061081886,
            0.020360613241791725,
            0.01776660792529583,
            -0.018800022080540657,
            -0.019850602373480797,
            -0.0006970969261601567,
            0.002787945093587041,
            0.004805863834917545,
            -0.005059989169239998,
            0.006713304203003645,
            0.02021045796573162,
            -0.01582230068743229,
            -0.0034039390739053488,
            0.0011091118212789297,
            0.0075157685205340385,
            0.009345957078039646,
            0.0324074812233448,
            -0.0017994100926443934,
            0.03177380934357643,
            0.014473775401711464,
            0.02014145627617836,
            -0.015750065445899963,
            0.004263582173734903,
            0.01587347872555256,
            0.013128058053553104,
            0.026197822764515877,
            0.017031995579600334,
            -0.04322206974029541,
            0.008751465007662773,
            0.03299103304743767,
            -0.004752481821924448,
            -0.0034037423320114613,
            0.03219696506857872,
            0.0015106169739738107,
            -0.026706606149673462,
            0.01799657940864563,
            -0.021681975573301315,
            0.02618463896214962,
            0.0049226172268390656,
            0.019286196678876877,
            -0.006945456378161907,
            0.00806167908012867,
            0.027078211307525635,
            -0.004035810474306345,
            0.018924886360764503,
            0.02370970882475376,
            -0.011783469468355179,
            0.002320094732567668,
            -0.02263549342751503,
            0.01841140165925026,
            0.011766070500016212,
            -0.0005660344031639397,
            0.03065560944378376,
            -0.016611548140645027,
            0.00044277514098212123,
            0.005967150907963514,
            0.006946873851120472,
            0.03332924470305443,
            -0.010652503930032253,
            0.012443102896213531,
            -0.0015693692257627845,
            0.021498894318938255,
            -0.016578935086727142,
            0.0223435889929533,
            0.03275611996650696,
            0.020706847310066223,
            0.008215227164328098,
            -0.0004314552352298051,
            -0.022388126701116562,
            0.0010197802912443876,
            -0.010010753758251667,
            0.018970122560858727,
            -0.022640686482191086,
            -0.008592392317950726,
            0.02455523982644081,
            -0.02737450785934925,
            0.0214020237326622,
            -0.009390229359269142,
            0.004476815462112427,
            -0.023188814520835876,
            -0.0016940339701250196,
            -0.010577620007097721,
            -0.047773782163858414,
            -0.004711247980594635,
            -0.00947751197963953,
            -0.008241471834480762,
            0.034802455455064774,
            -0.008278128691017628,
            1.2135576071159448e-05,
            -0.0078038666397333145,
            -0.02877834066748619,
            0.02328755520284176,
            0.0259051863104105,
            0.018459156155586243,
            0.004386899992823601,
            0.0036407371517270803,
            0.010574336163699627,
            -0.0054653100669384,
            0.02520112879574299,
            0.018490677699446678,
            0.008022865280508995,
            -0.025532608851790428,
            -0.02358064614236355,
            2.1833215214428492e-05,
            0.012530803680419922,
            -0.0076317330822348595,
            -0.005245525855571032,
            0.021757496520876884,
            0.007643226068466902,
            -0.024883022531867027,
            0.007177711930125952,
            -0.017421603202819824,
            -0.035113442689180374,
            -0.006888596341013908,
            0.024961156770586967,
            0.022427985444664955,
            0.03176536411046982,
            -0.030651167035102844,
            0.014206470921635628,
            0.019414503127336502,
            0.039900459349155426,
            0.020913155749440193,
            0.021381866186857224,
            0.00988952536135912,
            -0.008111371658742428,
            -0.01878305897116661,
            0.02890477329492569,
            0.01151436660438776,
            -0.02242995798587799,
            0.0038630077615380287,
            -0.003785486798733473,
            0.02214527130126953,
            -0.013412472791969776,
            -0.02028566226363182,
            0.012608915567398071,
            0.012868157587945461,
            -0.003929184749722481,
            0.024624748155474663,
            -0.02627241611480713,
            0.014676985330879688,
            -0.017901519313454628,
            0.032368071377277374,
            -0.03959471732378006,
            0.009122099727392197,
            -0.002872327109798789,
            0.024034151807427406,
            -0.014736838638782501,
            0.0073576127178967,
            -0.004194706678390503,
            0.03537575528025627,
            -0.017004506662487984,
            0.00020233751274645329,
            0.027777966111898422,
            -0.03714853525161743,
            -0.030022913590073586,
            -0.02474755048751831,
            0.00955765601247549,
            -0.00888813380151987,
            0.024067651480436325,
            -0.0016741682775318623,
            0.006582116242498159,
            0.018364524468779564,
            -0.004311753436923027,
            0.021017175167798996,
            0.014624561183154583,
            -0.009084031917154789,
            0.005316719878464937,
            0.028790904209017754,
            -0.01594369299709797,
            -0.017735620960593224,
            -0.0015254028839990497,
            -0.030020995065569878,
            0.05669967457652092,
            0.0012432903749868274,
            0.010269892401993275,
            0.03195282071828842,
            0.015186738222837448,
            -0.032342974096536636,
            -0.02390870824456215,
            -0.01003961730748415,
            -0.017858494073152542,
            0.029594751074910164,
            0.015537075698375702,
            -0.03685152903199196,
            -0.01802765764296055,
            -0.031316328793764114,
            -0.02053738199174404,
            0.022845542058348656,
            0.007340071257203817,
            0.0004251405189279467,
            -0.024159975349903107,
            -0.004061856772750616,
            -0.0023438269272446632,
            -0.0019746581092476845,
            0.028520502150058746,
            0.03251682221889496,
            -0.02126152627170086,
            -0.009501119144260883,
            0.05369225889444351,
            0.004464641213417053,
            0.022640137001872063,
            0.004448125138878822,
            0.01017128024250269,
            0.02265842817723751,
            0.02682395465672016,
            -0.0014754253206774592,
            -0.018829254433512688,
            0.01160480361431837,
            -0.003148765070363879,
            0.02213497832417488,
            0.03247761353850365,
            0.017464950680732727,
            -0.00457671657204628,
            -0.0062812333926558495,
            0.009312309324741364,
            0.02648763358592987,
            0.016329297795891762,
            -0.003126448020339012,
            -0.012635752558708191,
            0.025180092081427574,
            0.027235617861151695,
            0.007965491153299809,
            -0.0074515389278531075,
            0.015324503183364868,
            -0.01570022851228714,
            -0.02552962116897106,
            -0.0171563271433115,
            0.041658755391836166,
            0.04195214435458183,
            -0.018983036279678345,
            -0.019002873450517654,
            0.007397436071187258,
            0.03368224576115608,
            -0.023349974304437637,
            -0.009745631366968155,
            0.022094689309597015,
            0.007030881941318512,
            0.03159227967262268,
            -0.0294403824955225,
            0.0488276407122612,
            -0.0094986567273736,
            -0.008007626980543137,
            -0.021563364192843437,
            -0.031446900218725204,
            0.009580779820680618,
            -0.0016924019437283278,
            -0.012832456268370152,
            0.0005112977814860642,
            -0.0034528155811131,
            -0.01515666488558054,
            -0.004607390612363815,
            -0.02703108824789524,
            0.010009749792516232,
            0.0047982526011765,
            -0.01870863139629364,
            -0.03173393756151199,
            0.01257065124809742,
            0.0044892108999192715,
            0.0325063019990921,
            0.03322345018386841,
            0.020287150517106056,
            0.031830206513404846,
            -0.001936021028086543,
            0.019896304234862328,
            -0.0010436803568154573,
            0.007091051898896694,
            -0.002523164264857769,
            -0.005671467166393995,
            -0.000587186892516911,
            -0.01624785177409649,
            -0.005603752098977566,
            -0.0023380492348223925,
            0.000815753941424191,
            0.00963597185909748,
            0.03509550914168358,
            0.02254401706159115,
            0.03945852816104889,
            -0.011193378828465939,
            -0.007196137215942144,
            -0.004475007764995098,
            0.016141237691044807,
            -0.014696959406137466,
            -0.03322228789329529,
            0.018026534467935562,
            -0.02208557352423668,
            -0.012826881371438503,
            0.0015377301024273038,
            -0.02644447423517704,
            0.018006622791290283,
            -0.01094817090779543,
            -0.007442865986377001,
            -0.033052824437618256,
            0.014181028120219707,
            -0.02919897623360157,
            0.022333726286888123,
            -0.019232725724577904,
            0.001365966396406293,
            -0.010576602071523666,
            -0.0175891425460577,
            0.0004929375718347728,
            0.00897081010043621,
            -0.0185912624001503,
            -0.03103773482143879,
            0.020382946357131004,
            0.007764668669551611,
            -0.026647260412573814,
            -0.032439716160297394,
            -0.025967448949813843,
            -0.001213823794387281,
            0.015274588018655777,
            -0.012928194366395473,
            -0.024079160764813423,
            -0.002679414115846157,
            0.029167240485548973,
            0.016055671498179436,
            -0.028397925198078156,
            0.029821649193763733,
            0.03263794258236885,
            -0.013376516290009022,
            -0.0051175253465771675,
            0.006602393928915262,
            0.03671881556510925,
            -0.01843576319515705,
            0.012648936361074448,
            -0.016167545691132545,
            0.008960352279245853,
            -0.0014080576365813613,
            -0.012222127057611942,
            -0.013455863110721111,
            0.020306019112467766,
            -0.011468022130429745,
            -0.004898104351013899,
            -0.020777249708771706,
            0.02677745558321476,
            0.023916946724057198,
            -0.005087004043161869,
            0.009950944222509861,
            0.005582824349403381,
            0.022837653756141663,
            0.006945488043129444,
            -0.018102116882801056,
            0.014210646040737629,
            -0.029261650517582893,
            0.015797024592757225,
            0.021670307964086533,
            -0.026198329403996468,
            -0.0006625424139201641,
            -0.011662201024591923,
            0.004186901729553938,
            0.0017526681767776608,
            -0.006517679896205664,
            0.027921076864004135,
            -0.007984275929629803,
            0.018377989530563354,
            0.012668621726334095,
            0.031083935871720314,
            0.030700763687491417,
            -0.03720180690288544,
            -0.016630059108138084,
            -0.020840883255004883,
            -0.022906679660081863,
            -1.5022528714325745e-05,
            -0.019011378288269043,
            -0.01621950790286064,
            -0.025201937183737755,
            0.01529406476765871,
            0.019527558237314224,
            0.01729464717209339,
            0.01911393739283085,
            0.013445612974464893,
            -0.010169652290642262,
            0.00446197297424078,
            0.015987813472747803,
            0.005012044683098793,
            0.008829204365611076,
            0.0035800214391201735,
            -0.01195675227791071,
            0.003207763656973839,
            0.02494482696056366,
            0.007986443117260933,
            0.03139999136328697,
            0.035626329481601715,
            0.040955550968647,
            -0.004728891421109438,
            -0.027554739266633987,
            0.020755929872393608,
            0.008142479695379734,
            -0.014053288847208023,
            -0.03234199061989784,
            0.008554656989872456,
            0.01870681159198284,
            -0.024343300610780716,
            0.0051461816765367985,
            0.0028074290603399277,
            0.010633422993123531,
            -0.03170596808195114,
            0.042430710047483444,
            -0.027573609724640846,
            -0.02046632207930088,
            -0.02563752606511116,
            0.017471754923462868,
            0.014171905815601349,
            0.007324016187340021,
            -0.009719029068946838,
            0.0006551723345182836,
            -0.004494563210755587,
            -0.006808026228100061,
            -0.01709793135523796,
            -0.009458327665925026,
            -0.001660631620325148,
            -0.010459071956574917,
            -0.005746822338551283,
            -0.022941898554563522,
            0.01759503409266472,
            0.015965858474373817,
            -0.017692530527710915,
            -0.017394451424479485,
            0.04194392263889313,
            0.02613169141113758,
            -0.028623733669519424,
            0.03177189826965332,
            0.018210703507065773,
            -0.022291550412774086,
            -0.01662020944058895,
            -0.04690773785114288,
            0.01890999637544155,
            0.01289199199527502,
            0.01259146723896265,
            -0.014776280149817467,
            0.039626430720090866,
            0.03022652305662632,
            -0.013801549561321735,
            -0.031171776354312897,
            0.012319281697273254,
            0.02746959589421749,
            0.007127623539417982,
            0.020244883373379707,
            -0.017404725775122643,
            0.03243670612573624,
            -0.0027872780337929726,
            0.03615527227520943,
            -0.03369321674108505,
            0.008606142364442348,
            0.005026074592024088,
            0.0011481756810098886,
            0.011630806140601635,
            -0.014669799245893955,
            -0.008474888280034065,
            0.013179433532059193,
            -0.016045188531279564,
            -0.02437085472047329,
            -0.021074382588267326,
            0.010106010362505913,
            -0.012154211290180683,
            -0.02150583080947399,
            0.013379768468439579,
            0.0020619193091988564,
            0.0054964348673820496,
            -0.03685331344604492,
            0.003900985000655055,
            -0.01454917248338461,
            -0.0036407785955816507,
            0.026997467502951622,
            -0.009846426546573639,
            0.016310645267367363,
            0.020624831318855286,
            0.002654031850397587,
            -0.024380244314670563,
            -0.010576972737908363,
            -0.021328870207071304,
            0.027294233441352844,
            -0.02213430218398571,
            -0.018798746168613434,
            0.028078710660338402,
            0.024114632979035378,
            0.030916081741452217,
            -0.02355901524424553,
            0.005691337399184704,
            -0.017378414049744606,
            -0.02920331247150898,
            0.03737812861800194,
            0.028991179540753365,
            0.029255855828523636,
            -0.014685356989502907,
            -0.020423848181962967,
            0.0019408477237448096,
            0.011483914218842983,
            0.028726987540721893,
            0.03262796625494957,
            -0.01213875599205494,
            -0.0013495136518031359,
            0.00016512071306351572,
            0.0038450637366622686,
            0.01028362475335598,
            0.014981912449002266,
            0.017641816288232803,
            0.018958700820803642,
            0.008392315357923508,
            0.024410629644989967,
            0.006131405476480722,
            0.0056851753033697605,
            0.03055456653237343,
            0.022347332909703255,
            -0.007885139435529709,
            0.02769375406205654,
            0.017548704519867897
        ],
        [
            -0.008855095133185387,
            0.015414480119943619,
            -0.01601828634738922,
            0.006818149238824844,
            0.011686951853334904,
            0.005429727490991354,
            0.019418369978666306,
            0.03631633520126343,
            -0.0193477813154459,
            0.03474302217364311,
            -0.01078430749475956,
            -0.0025639282539486885,
            -0.015633024275302887,
            -0.041929423809051514,
            -0.002982823643833399,
            0.002353921066969633,
            0.025980107486248016,
            0.0032809777185320854,
            0.0374058298766613,
            -0.027874024584889412,
            0.028489425778388977,
            0.02661711350083351,
            0.01895187981426716,
            -0.012647625990211964,
            0.02007654309272766,
            -0.015890024602413177,
            0.0036676600575447083,
            0.02434278093278408,
            0.02341703325510025,
            0.023867467418313026,
            0.002075879368931055,
            0.03276355192065239,
            -0.029102668166160583,
            0.004088431131094694,
            0.017078718170523643,
            0.004190111067146063,
            0.03447896987199783,
            -0.003653182415291667,
            0.004077407997101545,
            0.00031028914963826537,
            0.03645440936088562,
            0.017005162313580513,
            -0.026506027206778526,
            0.02598660998046398,
            0.013187994249165058,
            0.02809034287929535,
            -0.017794962972402573,
            -0.031924400478601456,
            0.005478376522660255,
            0.00024598350864835083,
            0.01589011773467064,
            -0.011515283957123756,
            0.004208560101687908,
            0.0004922719672322273,
            -0.011823194101452827,
            0.03614573925733566,
            -0.03060823120176792,
            -0.010257451795041561,
            -0.008616339415311813,
            -0.0057526552118361,
            0.030696049332618713,
            -0.008928869850933552,
            -0.02436126209795475,
            -0.018708622083067894,
            0.029151853173971176,
            0.014774787239730358,
            -0.045112110674381256,
            -0.005016734357923269,
            -0.009247653186321259,
            0.0019491888815537095,
            0.02039078064262867,
            0.03481050208210945,
            0.0009488593204878271,
            0.020828913897275925,
            -0.029461173340678215,
            -0.0473397932946682,
            0.031241703778505325,
            0.015772396698594093,
            -0.03529370203614235,
            -0.02163330838084221,
            -0.023563586175441742,
            -0.024926278740167618,
            -0.00324969831854105,
            -0.02178850583732128,
            0.022130083292722702,
            -0.0075715347193181515,
            -0.016983162611722946,
            0.004929563961923122,
            0.020340101793408394,
            0.006468650419265032,
            0.004437591880559921,
            -0.021707110106945038,
            0.04033698886632919,
            -0.008664813823997974,
            0.007161937188357115,
            -0.014964839443564415,
            0.040222685784101486,
            0.03774102404713631,
            0.028057975694537163,
            0.029505616053938866,
            0.009491113014519215,
            0.0025168005377054214,
            -0.01166878454387188,
            -0.0013745494652539492,
            0.034551169723272324,
            0.023389006033539772,
            0.033334922045469284,
            0.015289513394236565,
            -0.001801392761990428,
            0.019788194447755814,
            -0.021476706489920616,
            0.01317426934838295,
            0.02363433502614498,
            -0.02010866440832615,
            0.008946750313043594,
            0.01266574952751398,
            -0.020008960738778114,
            0.01667303405702114,
            -0.00416529132053256,
            -0.006176926661282778,
            0.03243260458111763,
            0.025388959795236588,
            0.06843157112598419,
            0.004749041050672531,
            -0.012120413593947887,
            -0.040126923471689224,
            -0.013500831089913845,
            0.0017685036873444915,
            -0.0007909432169981301,
            -0.015853604301810265,
            -0.025923337787389755,
            -0.00818647164851427,
            0.023809613659977913,
            0.04361940920352936,
            0.017681272700428963,
            0.0068734860979020596,
            0.001102325040847063,
            0.009900262579321861,
            0.029088545590639114,
            -0.002856127917766571,
            -0.022657349705696106,
            0.003546918509528041,
            0.02445114590227604,
            0.0017105945153161883,
            0.027756357565522194,
            0.02802112326025963,
            0.01735672354698181,
            0.012202069163322449,
            0.007126928772777319,
            -0.01171028707176447,
            -0.005938645452260971,
            -0.011526430957019329,
            -0.004232632927596569,
            -0.013764828443527222,
            -0.00039863528218120337,
            0.016954759135842323,
            0.0072873481549322605,
            -0.005398710258305073,
            -0.004208553582429886,
            -0.027641674503684044,
            0.020671024918556213,
            0.031355030834674835,
            0.03210529312491417,
            -0.002363355364650488,
            -0.02518138661980629,
            0.03423082455992699,
            0.035916879773139954,
            0.034988902509212494,
            -0.013739821501076221,
            -0.03350849822163582,
            -0.031342364847660065,
            -1.3466456039168406e-05,
            0.010072077624499798,
            0.010562716983258724,
            0.014489375054836273,
            0.015656374394893646,
            0.02214818447828293,
            -0.019216321408748627,
            -0.008451055735349655,
            -0.0036189642269164324,
            -0.035282351076602936,
            -0.008316612802445889,
            0.026636609807610512,
            -0.003149561583995819,
            -0.02682936005294323,
            0.031151963397860527,
            0.008154958486557007,
            0.01812269166111946,
            -0.006284311879426241,
            -0.031855423003435135,
            0.005983720533549786,
            0.022089848294854164,
            0.004554295912384987,
            0.03472695127129555,
            -0.013535242527723312,
            -0.020693471655249596,
            0.014554745517671108,
            -0.01590467430651188,
            -0.008458451367914677,
            0.027509862557053566,
            -0.010181515477597713,
            0.03417934104800224,
            -0.001545268576592207,
            0.013265755027532578,
            0.02410704642534256,
            -0.01122357975691557,
            -0.009913078509271145,
            0.03222375735640526,
            0.01856262981891632,
            0.010922007262706757,
            -0.0034297010861337185,
            -0.03512671962380409,
            0.019680224359035492,
            -0.008400995284318924,
            -0.0010247473837807775,
            0.022659985348582268,
            0.015130912885069847,
            -0.019179027527570724,
            -0.011824521236121655,
            -0.0380135215818882,
            0.015605811029672623,
            -0.02020946331322193,
            -0.037139713764190674,
            0.01607426069676876,
            0.007169375196099281,
            -0.02804601937532425,
            -0.015420022420585155,
            -0.0011526498710736632,
            -0.018761904910206795,
            1.884491894088569e-06,
            0.008804628625512123,
            -0.021242987364530563,
            -0.03136681020259857,
            0.028036300092935562,
            0.036192912608385086,
            0.025198301300406456,
            0.03216589242219925,
            0.011738699860870838,
            0.0029405897948890924,
            -0.005965277086943388,
            -0.003921222407370806,
            0.015252113342285156,
            -0.027510477229952812,
            0.01873938925564289,
            -0.03758427873253822,
            -0.0140423234552145,
            -0.011778309009969234,
            0.007948632352054119,
            0.013276102021336555,
            -0.015282519161701202,
            0.013480868190526962,
            -0.01133363414555788,
            0.02241518720984459,
            0.020623084157705307,
            0.006392852868884802,
            0.02820504643023014,
            0.013857771642506123,
            0.035285208374261856,
            0.045557763427495956,
            -0.006767866667360067,
            -0.016099371016025543,
            0.019300175830721855,
            0.022164298221468925,
            -0.02204268053174019,
            -0.02943718433380127,
            0.0286786537617445,
            -0.018660107627511024,
            -0.022039372473955154,
            0.00846898928284645,
            0.015919895842671394,
            0.02796068973839283,
            0.002574973041191697,
            -0.018777048215270042,
            0.019162354990839958,
            -0.03929755091667175,
            0.005779723171144724,
            -0.009878847748041153,
            0.022432103753089905,
            0.019925087690353394,
            0.011732782237231731,
            0.0271668191999197,
            0.026735350489616394,
            0.028058167546987534,
            -0.023807629942893982,
            0.000414775829995051,
            0.022123558446764946,
            -0.016808880493044853,
            -0.06573519855737686,
            0.011742586269974709,
            0.004056461621075869,
            0.03240067511796951,
            0.019839750602841377,
            0.0015734834596514702,
            0.012134225107729435,
            -0.0323815792798996,
            0.04014427587389946,
            -0.022412041202187538,
            -0.007317662239074707,
            0.03174740821123123,
            -0.008442014455795288,
            0.024954551830887794,
            0.003931546118110418,
            -0.004339999984949827,
            0.0004898916813544929,
            0.026271501556038857,
            0.023507798090577126,
            -0.009788078255951405,
            -0.03665166720747948,
            -0.03765576332807541,
            -0.0307033471763134,
            0.02451508119702339,
            0.005705429706722498,
            -0.02545432187616825,
            -0.016694890335202217,
            -0.01547770481556654,
            -0.05410794913768768,
            0.026838500052690506,
            -0.0025894613936543465,
            0.021108075976371765,
            0.002789566293358803,
            -0.02771086059510708,
            -0.007948440499603748,
            -0.019280757755041122,
            0.03374111279845238,
            0.039370376616716385,
            -0.013147403486073017,
            0.03927270323038101,
            -0.009236619807779789,
            0.03332838788628578,
            0.02320370078086853,
            -0.00015670715947635472,
            0.0146956667304039,
            -0.009064399637281895,
            0.012846720404922962,
            0.014465910382568836,
            0.011554908007383347,
            -0.009751660749316216,
            0.017945175990462303,
            -0.0019500344060361385,
            -0.03422613814473152,
            -0.02551284432411194,
            0.008852973580360413,
            -0.0013695853995159268,
            -0.024869101122021675,
            0.01780431903898716,
            -0.02176397480070591,
            0.013849734328687191,
            0.012324118055403233,
            0.015712928026914597,
            0.03469521924853325,
            0.0025351536460220814,
            0.014069739729166031,
            0.019034763798117638,
            0.03178815171122551,
            -0.01674381084740162,
            0.01510727871209383,
            0.019640745595097542,
            -0.002249038079753518,
            0.013132108375430107,
            -0.0007109857397153974,
            0.001492921612225473,
            -0.014678528532385826,
            0.01327933743596077,
            -0.00690111331641674,
            0.03974303603172302,
            -0.030724547803401947,
            -0.025110801681876183,
            0.008377527818083763,
            0.02510218322277069,
            0.02509911172091961,
            -0.022765351459383965,
            0.01490643247961998,
            -0.01468866690993309,
            -0.019250061362981796,
            -0.017250878736376762,
            -0.030545124784111977,
            -0.012127555906772614,
            -0.013230566866695881,
            0.03570345416665077,
            0.021260568872094154,
            0.007065287791192532,
            -0.025149619206786156,
            0.020752839744091034,
            -0.027190741151571274,
            0.021067360416054726,
            0.015918485820293427,
            -0.028906162828207016,
            2.6128907848033123e-05,
            -0.0017817894695326686,
            0.0022257044911384583,
            -0.01610506884753704,
            0.019040564075112343,
            -0.016703467816114426,
            -0.01424622442573309,
            0.04857586324214935,
            -0.0015907171182334423,
            0.028941486030817032,
            0.013950075954198837,
            0.006916411221027374,
            0.015330135822296143,
            0.02422144077718258,
            0.028216363862156868,
            0.035684142261743546,
            0.02116449922323227,
            0.01421473827213049,
            -0.010959312319755554,
            0.005426055286079645,
            -0.039492640644311905,
            -0.009194353595376015,
            -0.006987925618886948,
            -0.019543005153536797,
            -0.011614955961704254,
            -0.00972424354404211,
            -0.010818121954798698,
            -0.01369091123342514,
            0.036222126334905624,
            0.02870887890458107,
            0.00235488498583436,
            -0.011543117463588715,
            0.020574064925312996,
            0.018030783161520958,
            0.019321443513035774,
            0.016631439328193665,
            0.003228657878935337,
            -0.003624177537858486,
            0.006460162345319986,
            0.01792074181139469,
            0.02974124439060688,
            0.030608493834733963,
            0.019733598455786705,
            -0.029307104647159576,
            -0.016083497554063797,
            0.02204989455640316,
            -0.02612236700952053,
            -0.008687222376465797,
            0.032429687678813934,
            -0.007919021882116795,
            0.011081772856414318,
            -0.010523286648094654,
            0.019365385174751282,
            0.009196740575134754,
            -0.014964131638407707,
            0.03104819729924202,
            -0.03199585899710655,
            -0.017773771658539772,
            0.007388165686279535,
            0.01815933734178543,
            0.05223655328154564,
            0.022857455536723137,
            -0.012459258548915386,
            0.01395998615771532,
            0.03333232179284096,
            0.009710213169455528,
            0.01619645766913891,
            0.01226183120161295,
            -0.023078149184584618,
            0.01619015447795391,
            0.008231010288000107,
            0.03270610049366951,
            -0.02278471738100052,
            0.004250250291079283,
            0.01222541555762291,
            -0.014223113656044006,
            0.025838052853941917,
            -0.006355045363306999,
            -0.0198122039437294,
            0.0017779481131583452,
            -0.019721802324056625,
            -0.014612494967877865,
            -0.0032981291878968477,
            0.02295313961803913,
            -0.02026911824941635,
            0.025735726580023766,
            -0.03017399087548256,
            0.01541760005056858,
            -0.037407055497169495,
            0.01048190239816904,
            -0.010264091193675995,
            0.021291526034474373,
            0.02162650041282177,
            0.023799454793334007,
            0.022173425182700157,
            0.023992877453565598,
            -0.007676577195525169,
            0.003605910809710622,
            -0.015964830294251442,
            -0.0175625029951334,
            -0.03433641418814659,
            -0.0004716787952929735,
            0.005415791645646095,
            -0.016646001487970352,
            0.0059832134284079075,
            -0.02317848615348339,
            -0.008303328417241573,
            0.02989938296377659,
            0.0067968289367854595,
            -0.02023543044924736,
            0.01077172439545393,
            0.023144450038671494,
            -0.018118631094694138,
            -0.002675818745046854,
            0.02743220515549183,
            0.009553734213113785,
            0.006906142923980951,
            -0.021743109449744225,
            -0.012609602883458138,
            0.011632820591330528,
            -0.01244175061583519,
            -0.010802127420902252,
            0.018384477123618126,
            -0.01563129387795925,
            0.01831042394042015,
            0.013306466862559319,
            0.023627379909157753,
            0.02762988954782486,
            0.012102664448320866,
            0.0270086657255888,
            0.005834239535033703,
            -0.021817732602357864,
            -0.022974882274866104,
            0.015287146903574467,
            -0.01995345577597618,
            0.006223637610673904,
            0.017000393941998482,
            -0.005805982742458582,
            0.020762179046869278,
            0.07638269662857056,
            0.030899066478013992,
            -0.015696950256824493,
            0.004854620900005102,
            -0.018033603206276894,
            0.001156961894594133,
            -0.017332471907138824,
            -0.0025301401037722826,
            0.026557356119155884,
            0.02733265422284603,
            -0.003945867996662855,
            0.01867775246500969,
            0.020521966740489006,
            -0.007047123741358519,
            -0.0024750418961048126,
            0.021480679512023926,
            0.02512412890791893,
            -0.015316345728933811,
            0.03224947303533554,
            0.01040846761316061,
            -0.008440803736448288,
            -0.001195813762024045,
            -0.022235238924622536,
            -0.0024638331960886717,
            -0.025961803272366524,
            0.001238935044966638,
            -0.008141863159835339,
            -0.036729998886585236,
            0.01155137550085783,
            0.01725747622549534,
            0.0033471195492893457,
            -0.000528809439856559,
            0.007471353281289339,
            0.028055910021066666,
            -0.004077641759067774,
            -0.022168206050992012,
            0.024547984823584557,
            -0.0028738314285874367,
            -0.02568282000720501,
            -0.004476625472307205,
            -0.006317034363746643,
            0.0301589984446764,
            -0.02583761140704155,
            -0.01942070946097374,
            -0.011213171295821667,
            0.02022993378341198,
            0.009153068996965885,
            -0.014329935424029827,
            0.023363934829831123,
            0.02205130085349083,
            -0.02300179935991764,
            0.023438746109604836,
            -0.012840354815125465,
            -0.016731368377804756,
            -0.025504963472485542,
            -0.015096272341907024,
            -0.007612504996359348,
            0.03659188374876976,
            -0.0018428262555971742,
            -0.02476370334625244,
            -0.010371485725045204,
            0.026486670598387718,
            0.014328020624816418,
            -0.02135983482003212,
            0.004872272256761789,
            -0.003927920013666153,
            0.01492254063487053,
            0.022656839340925217,
            0.005977190099656582,
            0.00540435453876853,
            -0.008914531208574772,
            -0.012519205920398235,
            0.012703314423561096,
            -0.00871126540005207,
            0.02069464698433876,
            -0.010948444716632366,
            -0.020584100857377052,
            -0.008532724343240261,
            -0.0172998309135437,
            0.011208021081984043,
            -0.021080300211906433,
            -0.014636625535786152,
            -0.02174953930079937,
            -0.03628742694854736,
            -0.03863009437918663,
            -0.006805706769227982,
            0.015902874991297722,
            -0.01935848966240883,
            0.031116724014282227,
            0.0225515253841877,
            0.015243985690176487,
            -0.03279685974121094,
            -0.022485926747322083,
            -0.006143858190625906,
            -0.0040235696360468864,
            -0.036090653389692307,
            0.01694684475660324,
            0.0023510754108428955,
            0.0015760805690661073,
            0.02567634917795658,
            0.00689790491014719,
            -0.023879775777459145,
            0.00615156814455986,
            0.020340928807854652,
            -0.01628836803138256,
            -0.013627671636641026,
            0.03466055542230606,
            -0.005458388477563858,
            -0.013879644684493542,
            0.01766505278646946,
            0.00500439153984189,
            0.000989190535619855,
            -0.003999708686023951,
            0.031171929091215134,
            -0.04334759712219238,
            0.04622287675738335,
            0.009208998642861843,
            -0.015133898705244064,
            -0.03044302389025688,
            -0.0077649448066949844,
            -0.017408883199095726,
            -0.0003352984203957021,
            -0.004573178477585316,
            0.006794747896492481,
            0.00260104238986969,
            -0.025091635063290596,
            0.003170656505972147,
            0.032118409872055054,
            -0.003499829676002264,
            -0.019146006554365158,
            -0.009081130847334862,
            -0.011292727664113045,
            0.00401210505515337,
            0.005148346070200205,
            0.02437111735343933,
            -0.022318677976727486,
            -0.028563031926751137,
            0.022632867097854614,
            0.03886750340461731,
            -0.02004368044435978,
            -0.006600802764296532,
            -0.014185027219355106,
            -0.008152785710990429,
            0.006568683311343193,
            -0.014455461874604225,
            -0.00045243542990647256,
            0.020337620750069618,
            -0.03345439210534096,
            0.017462966963648796,
            -0.0016377146821469069,
            0.03172222152352333,
            -0.011621624231338501,
            0.008325246162712574,
            -0.0032264976762235165,
            0.0015199253102764487,
            0.006152920424938202,
            -0.010563287883996964,
            0.033040083944797516,
            0.0193413645029068,
            0.011265881359577179,
            0.019165266305208206,
            0.03046777844429016,
            -0.014137537218630314,
            0.02166907489299774,
            0.010288062505424023,
            0.009077870287001133,
            0.0034278281964361668,
            -0.007612420711666346,
            -0.019504593685269356,
            0.006046851631253958,
            0.022987009957432747,
            0.013729033060371876,
            -0.0011730872793123126,
            0.0037859228905290365,
            -0.020286928862333298,
            -0.030301164835691452,
            0.007621892262250185,
            0.006183819845318794,
            0.008895006962120533,
            0.01621544547379017,
            -0.02828531712293625,
            0.03279309347271919,
            0.025537217035889626,
            0.0037356331013143063,
            0.022353170439600945,
            0.007605150807648897,
            -0.018736880272626877,
            0.027497347444295883,
            0.010935160331428051,
            -0.01765924133360386,
            0.0344066321849823,
            0.0020845215767621994,
            -0.013189887627959251,
            -0.01091083511710167,
            -0.018354294821619987,
            -0.0020266941282898188,
            -0.026668338105082512,
            0.013994437642395496,
            -0.012699794955551624,
            -0.03393983468413353,
            0.03102630190551281,
            -0.03306731954216957,
            0.010698117315769196,
            0.020850589498877525,
            0.0300457663834095,
            0.014222700148820877,
            -0.021423649042844772,
            0.023679660633206367,
            0.028390955179929733,
            -0.013227799907326698,
            -0.0072756982408463955,
            0.03025105968117714,
            -0.023966580629348755,
            0.030978906899690628,
            -0.016307737678289413,
            0.01748308725655079,
            -0.027177156880497932,
            -0.004688541870564222,
            -0.01274639181792736,
            0.015514569357037544,
            0.014083800837397575,
            -0.020150957629084587,
            0.036470312625169754,
            -0.004348491784185171,
            0.021871177479624748,
            0.016807422041893005,
            -0.028634006157517433,
            0.034723903983831406,
            -0.011359788477420807,
            -0.00850524753332138,
            -0.005986950825899839,
            -0.004620832856744528,
            -0.007882674224674702,
            -0.0011299209436401725,
            0.04191078990697861,
            0.056767504662275314,
            -0.008921034634113312,
            0.03208618238568306,
            0.01302475854754448,
            0.016587531194090843,
            0.03052576445043087,
            0.008652825839817524,
            0.013051249086856842,
            -0.0022357425186783075,
            -0.019625728949904442,
            -0.009105357341468334,
            -0.03455647453665733,
            0.03391023352742195,
            0.00785765703767538,
            -0.02403395250439644,
            0.029792532324790955,
            0.044539924710989,
            -0.02265464887022972,
            0.028152771294116974,
            0.0011516293743625283,
            -0.0019016877049580216,
            0.019725043326616287,
            -0.00899748969823122,
            0.022605955600738525,
            -0.017217420041561127,
            -0.012335455976426601,
            -0.030464891344308853,
            -0.017994843423366547,
            0.025734268128871918,
            -0.00251190597191453,
            0.01903979852795601,
            0.004784279502928257,
            -0.023055024445056915,
            0.02559971995651722,
            -0.01968603953719139,
            0.018178071826696396,
            0.018266618251800537,
            -0.01280710194259882,
            0.008420986123383045,
            0.01649698242545128,
            0.0109185129404068,
            0.02146543189883232,
            0.0011759683256968856,
            -0.03171684965491295,
            0.03349073603749275,
            0.011773609556257725,
            -0.014715232886373997,
            0.00445268489420414,
            -0.005457876715809107,
            0.03391025587916374,
            -0.008559392765164375,
            -0.01920313574373722,
            0.035654257982969284,
            -0.0274597629904747,
            0.0019935115706175566,
            -0.0012761257821694016,
            0.004146589897572994,
            -0.029161376878619194,
            -0.018225636333227158,
            -0.010590835474431515,
            0.023786557838320732,
            0.013341355137526989,
            0.0075483364053070545,
            -0.012114843353629112,
            0.004459822084754705,
            0.010812591761350632,
            0.00133108242880553,
            0.022824879735708237,
            0.010068608447909355,
            -0.026564886793494225,
            0.013807007111608982,
            0.01738804765045643,
            -0.008543457835912704,
            0.04303794726729393,
            0.005267436616122723,
            0.007541289087384939,
            0.0009575577569194138,
            -0.010730667971074581,
            -0.014875859953463078,
            0.00034983200021088123,
            0.025921327993273735,
            0.01578974723815918,
            0.011600497178733349,
            -0.02942865714430809,
            0.02898888848721981,
            -0.019063521176576614,
            0.01820378191769123,
            0.008675487712025642,
            -0.019160432741045952,
            0.014077787287533283,
            -0.015191910788416862,
            0.0390290729701519,
            0.026702281087636948,
            -0.0434870719909668,
            -0.019038161262869835,
            0.01237434521317482,
            0.021905794739723206,
            0.030235817655920982,
            -0.001057419111020863,
            -0.014819136820733547,
            -0.0037917722947895527,
            -0.01586325466632843,
            -0.013636269606649876,
            -0.01940835453569889,
            0.028061477467417717,
            0.02176263928413391,
            0.01023030374199152,
            0.001534282579086721,
            0.02430299110710621,
            0.014878424815833569,
            0.01675713248550892,
            0.038511913269758224,
            0.035693373531103134,
            0.007954674772918224,
            -0.04110228270292282,
            -0.01127263531088829,
            0.01302289217710495,
            -0.02025909163057804,
            0.02078481949865818,
            -0.027235154062509537,
            0.01573438197374344,
            0.0049119265750050545,
            -0.010843023657798767,
            0.00454156007617712,
            -0.015311873517930508,
            0.007219483144581318,
            0.027069276198744774,
            0.014831564389169216,
            0.021863073110580444,
            0.01056681852787733,
            -0.016742901876568794,
            0.008686698041856289,
            0.00046599580673500896,
            0.01193983107805252,
            -0.015382670797407627,
            0.012578343041241169,
            0.034483227878808975,
            -0.02716844342648983,
            0.017973532900214195,
            0.021159233525395393,
            -0.028474101796746254,
            0.02031194046139717,
            -0.024384070187807083,
            0.002542333211749792,
            -0.014020378701388836,
            -0.005137498490512371,
            0.033866025507450104,
            -0.027057383209466934,
            -0.01375062856823206,
            0.010794119909405708,
            0.022688521072268486,
            -0.04372048377990723,
            0.004925522953271866,
            -0.010995287448167801,
            0.015416028909385204,
            0.02499512769281864,
            0.02435876987874508,
            0.0008423445979133248,
            0.005283801816403866,
            -0.012227929197251797,
            -0.014654722064733505,
            -0.015528573654592037,
            0.030831219628453255,
            0.06878673285245895,
            -0.013157394714653492,
            0.03150147199630737,
            -0.0013787307543680072,
            -0.0038798919413238764,
            0.009816264733672142,
            0.01823933981359005,
            -0.018804317340254784,
            0.038551025092601776,
            0.033846475183963776,
            0.02518797293305397,
            -0.01693052425980568,
            0.03351322188973427,
            0.015364323742687702,
            -0.015498937107622623,
            0.023771485313773155,
            0.027165567502379417,
            0.000638986995909363,
            0.010410218499600887,
            0.006382352206856012,
            -0.011557143181562424,
            -0.0018851659260690212,
            0.022390060126781464,
            0.005712459329515696,
            0.029639462009072304,
            -0.030140656977891922,
            0.041900187730789185,
            0.02133003994822502,
            0.011310080997645855,
            0.018400246277451515,
            0.023254701867699623,
            0.0028428402729332447,
            0.019510963931679726,
            0.021538401022553444,
            0.03554508462548256,
            -0.009972620755434036,
            -0.030070768669247627,
            -0.011344325728714466,
            0.016027525067329407,
            0.00999576784670353,
            -0.01793499104678631,
            0.035078153014183044,
            0.04869186133146286,
            0.01027457881718874,
            0.02904951386153698,
            -0.012151926755905151,
            -0.00451692147180438,
            -0.0012883275048807263,
            0.006081222090870142,
            -0.025322187691926956,
            0.02419387921690941,
            -0.03284329175949097,
            -0.014217355288565159,
            0.0028748882468789816,
            -0.0065959785133600235,
            -0.023747485131025314,
            -0.010156344622373581,
            -0.03515290841460228,
            0.0238744355738163,
            -0.017297059297561646,
            0.040290944278240204,
            0.024759147316217422,
            -0.017079005017876625,
            -0.018555697053670883,
            0.008653181605041027,
            0.023360639810562134,
            -0.01436002179980278,
            -0.0156319011002779,
            0.005510638002306223,
            -0.02255750261247158,
            -0.007747385185211897,
            0.011147144250571728,
            0.03291923925280571,
            0.01711704395711422,
            0.014019531197845936,
            0.019978255033493042,
            -0.02148151397705078,
            -0.034639645367860794,
            0.009014859795570374,
            0.005942376330494881,
            0.008225275203585625,
            0.037542469799518585,
            0.021295923739671707,
            -0.005739269778132439,
            -0.015878578647971153,
            -0.006167837418615818,
            -0.03469117730855942,
            0.0010300364810973406,
            -0.022274525836110115,
            -0.00846919883042574,
            -0.02038843184709549,
            -0.04218771308660507,
            -0.007574551273137331,
            0.022736934944987297,
            -0.006279048044234514,
            0.031000519171357155,
            -0.027464471757411957,
            -0.017342599108815193,
            0.000607899040915072,
            -0.003193173324689269
        ],
        [
            0.013681207783520222,
            -0.014169934205710888,
            0.032547906041145325,
            -0.043398454785346985,
            0.01892474852502346,
            -0.04753114655613899,
            0.02810935117304325,
            -0.06038650497794151,
            0.02020839974284172,
            0.029705453664064407,
            -0.01128350105136633,
            0.00648517906665802,
            -0.014940372668206692,
            0.007617281284183264,
            0.044128116220235825,
            0.003949765115976334,
            0.008714443072676659,
            0.00022683330462314188,
            -0.00035175433731637895,
            -0.019789092242717743,
            -0.0038036887999624014,
            0.013208078220486641,
            0.011789565905928612,
            0.00974789634346962,
            0.05471133068203926,
            0.0027497645933181047,
            -0.005244970787316561,
            0.01344610657542944,
            -0.04264679551124573,
            -0.0005965735763311386,
            -0.0065190792083740234,
            0.029749736189842224,
            -0.018405752256512642,
            0.028760991990566254,
            -0.016324210911989212,
            -0.019144974648952484,
            -0.03555147349834442,
            0.005151973105967045,
            -0.017931809648871422,
            0.018934398889541626,
            0.02297665737569332,
            0.00544892018660903,
            0.009122136048972607,
            0.01393816526979208,
            0.009802740067243576,
            -0.009269976988434792,
            -0.026393240317702293,
            0.03289271518588066,
            0.028116505593061447,
            -0.030881362035870552,
            0.009279976598918438,
            -0.0032276210840791464,
            0.06101945415139198,
            -0.05463689565658569,
            0.0198256466537714,
            -0.04811645299196243,
            -0.04770434647798538,
            -0.039000313729047775,
            -0.03721882775425911,
            -0.026423081755638123,
            -0.00150806934107095,
            -0.01996796764433384,
            0.021954039111733437,
            0.04801645129919052,
            -0.0017460538074374199,
            -0.0191201064735651,
            -0.015867244452238083,
            0.003815433708950877,
            -0.027997277677059174,
            0.0368143767118454,
            0.02437092363834381,
            -0.027283957228064537,
            0.025407496839761734,
            -0.024531077593564987,
            -0.08495239168405533,
            -0.004066068213433027,
            -0.0056275129318237305,
            0.037264809012413025,
            0.04294367507100105,
            -0.016435394063591957,
            0.011165609583258629,
            -0.011272377334535122,
            0.0048239692114293575,
            -0.01392606645822525,
            -0.02768397144973278,
            0.014856941998004913,
            -0.004309584386646748,
            0.011675414629280567,
            -0.04332277551293373,
            0.026080157607793808,
            -0.0025349531788378954,
            0.027812480926513672,
            0.00843743234872818,
            0.02484240010380745,
            0.05161892622709274,
            -0.013040177524089813,
            0.08316396921873093,
            0.0313580222427845,
            -0.09140404313802719,
            0.03481590747833252,
            -0.019910212606191635,
            -0.03285417705774307,
            -0.004821659065783024,
            -0.009819376282393932,
            -0.032857801765203476,
            -0.016615040600299835,
            0.002339995466172695,
            -0.0026843841187655926,
            -0.010330161079764366,
            -0.018315190449357033,
            0.002455231500789523,
            -0.027252590283751488,
            -0.025194279849529266,
            -0.024630462750792503,
            0.05222965404391289,
            -0.011834230273962021,
            -0.015247554518282413,
            0.017422012984752655,
            -0.02392173744738102,
            0.01063520833849907,
            0.003657143795862794,
            -0.01584644243121147,
            -0.07675899565219879,
            -0.020521320402622223,
            -0.024283071979880333,
            -0.0326947420835495,
            -0.06696834415197372,
            0.03245493024587631,
            -0.03000660240650177,
            -0.010923079214990139,
            0.0029921240638941526,
            0.02092461660504341,
            -0.025081736966967583,
            -0.042003534734249115,
            -0.012525924481451511,
            -0.02766178548336029,
            -0.019359251484274864,
            0.01995365507900715,
            0.00020109258184675127,
            0.008334736339747906,
            0.010166489519178867,
            0.02341476082801819,
            -0.04886467382311821,
            0.0019078798359259963,
            -0.019555766135454178,
            -0.01645859330892563,
            0.039000265300273895,
            0.014454279094934464,
            -0.004396818112581968,
            -0.026785915717482567,
            0.036794137209653854,
            0.027486931532621384,
            0.04168858379125595,
            0.023522570729255676,
            0.018654456362128258,
            0.039504848420619965,
            0.025242483243346214,
            0.009624929167330265,
            -0.0026335674338042736,
            -0.04269075766205788,
            -0.020146215334534645,
            -3.444976027822122e-05,
            0.0015343788545578718,
            -0.0012138886377215385,
            -0.026256972923874855,
            0.02453346736729145,
            0.007236826233565807,
            -0.05056580528616905,
            0.004470934625715017,
            0.027003005146980286,
            -0.002745814621448517,
            -0.033609647303819656,
            0.04635229334235191,
            0.03520717844367027,
            -0.006506185978651047,
            0.0007892969297245145,
            0.004727451596409082,
            0.03455408662557602,
            -0.03114411048591137,
            0.02447018213570118,
            0.03835485130548477,
            -0.007391598541289568,
            -0.003690479090437293,
            -0.009490122087299824,
            -0.001154742087237537,
            -0.013162781484425068,
            0.0496378168463707,
            0.004422727972269058,
            0.005790971219539642,
            0.00958696287125349,
            0.011290349066257477,
            -0.019082816317677498,
            0.0019302087603136897,
            -0.017258578911423683,
            -0.036980416625738144,
            0.041846659034490585,
            0.013126198202371597,
            0.01796475425362587,
            -0.02305724285542965,
            -0.010130287148058414,
            0.006499191280454397,
            -0.024781255051493645,
            0.01815740577876568,
            0.0011389077408239245,
            -0.025742290541529655,
            0.04791593924164772,
            -0.040319833904504776,
            -0.0005098609253764153,
            -0.01404836680740118,
            0.004674398340284824,
            0.01454008836299181,
            0.04181640222668648,
            0.005892498418688774,
            -0.0020310478284955025,
            0.008660062216222286,
            0.0010218671523034573,
            -0.02082127146422863,
            0.0049854726530611515,
            0.006518643349409103,
            0.035208042711019516,
            0.01827189140021801,
            -0.022029448300600052,
            -0.022026726976037025,
            0.013823816552758217,
            0.036584045737981796,
            0.0017002314561977983,
            0.02532072737812996,
            0.010037667118012905,
            0.027399251237511635,
            -0.008296607993543148,
            0.018049510195851326,
            -0.016580164432525635,
            -0.005776972509920597,
            -0.012135511264204979,
            0.06559254974126816,
            0.027353713288903236,
            -0.047614023089408875,
            -0.014384587295353413,
            0.007067055441439152,
            -0.03300372511148453,
            0.002165233949199319,
            0.006729917600750923,
            0.02862093597650528,
            -0.011767062358558178,
            0.013149546459317207,
            0.021589618176221848,
            -0.02292821928858757,
            0.047618065029382706,
            -0.03491441532969475,
            0.009577564895153046,
            0.011874301359057426,
            -0.045209553092718124,
            -0.03634556755423546,
            0.009976044297218323,
            -0.0347880944609642,
            -0.03131476789712906,
            0.021387455984950066,
            0.014509432949125767,
            -0.015323766507208347,
            0.04224265739321709,
            0.020626896992325783,
            -0.03668725863099098,
            -0.07166168838739395,
            0.002137810457497835,
            -0.0276572797447443,
            -0.0020640548318624496,
            0.00461953692138195,
            0.0018035669345408678,
            -0.02561127580702305,
            -0.014156093820929527,
            -0.01962205581367016,
            -0.014938783831894398,
            -0.017975952476263046,
            -0.04055619239807129,
            -0.030894698575139046,
            -0.032519396394491196,
            -0.016115082427859306,
            -0.030489953234791756,
            0.017409106716513634,
            0.03194505721330643,
            0.0017957912059500813,
            -0.00742696225643158,
            0.007632839493453503,
            -0.03380928561091423,
            0.03299199789762497,
            0.02546931430697441,
            0.03553741052746773,
            -0.0024848785251379013,
            -0.0036109231878072023,
            -0.007210127543658018,
            0.01916194148361683,
            -0.044805802404880524,
            -0.010135430842638016,
            0.021901879459619522,
            0.012688380666077137,
            0.0330348014831543,
            -0.04012385010719299,
            0.030911017209291458,
            -0.03419370576739311,
            -0.007590810302644968,
            0.007868881337344646,
            -0.012829511426389217,
            -0.029566491022706032,
            0.00942393857985735,
            -0.013913491740822792,
            0.04783467948436737,
            -0.05201289802789688,
            -0.03505902364850044,
            0.006930968724191189,
            0.006206193007528782,
            -0.020348867401480675,
            0.02015521377325058,
            0.008418231271207333,
            0.02676260471343994,
            -0.013420150615274906,
            0.00813931506127119,
            -0.003512205323204398,
            0.028168238699436188,
            -0.002709507243707776,
            -0.025240518152713776,
            -0.002606528578326106,
            -0.04441254213452339,
            0.0015405419981107116,
            -0.04791883006691933,
            0.00194832612760365,
            0.010149382054805756,
            -0.06554857641458511,
            0.042676590383052826,
            0.031322747468948364,
            0.0067474874667823315,
            0.018429027870297432,
            -0.014429050497710705,
            -0.01343472208827734,
            -0.00331066083163023,
            0.0001444802328478545,
            -0.05512566864490509,
            0.0002157751878257841,
            -0.026186227798461914,
            -0.0033547834027558565,
            0.0033801039680838585,
            -0.014614359475672245,
            -0.00010782039316836745,
            -0.06629123538732529,
            -0.0010467248503118753,
            -0.0603889562189579,
            0.02381398156285286,
            -0.016026264056563377,
            0.009439444169402122,
            -0.014612507075071335,
            0.05807027593255043,
            -0.06081126630306244,
            -0.006913349963724613,
            -0.017614321783185005,
            -0.010090886615216732,
            0.041253674775362015,
            -0.03426249697804451,
            0.001682605710811913,
            -0.033236995339393616,
            -0.02935689315199852,
            0.0060415612533688545,
            0.02883864939212799,
            -0.027158284559845924,
            0.03419828042387962,
            -0.004788091406226158,
            -0.0042771208100020885,
            0.023399565368890762,
            0.02421555109322071,
            0.008005620911717415,
            -0.01786625012755394,
            0.002628783695399761,
            0.016370708122849464,
            0.02229492925107479,
            0.007284124381840229,
            0.04209365323185921,
            -0.025388525798916817,
            -0.03764424845576286,
            0.0348641611635685,
            -0.008895411156117916,
            0.005708122160285711,
            0.028698809444904327,
            0.014026248827576637,
            0.006998285185545683,
            0.017954058945178986,
            0.0032379101030528545,
            0.029750891029834747,
            -0.04465002566576004,
            0.03773760050535202,
            -0.0507584810256958,
            0.038349881768226624,
            -0.02536320686340332,
            -0.017346259206533432,
            -0.0032095639035105705,
            0.00516494968906045,
            0.02994629554450512,
            -0.03765612468123436,
            0.024026542901992798,
            0.0029007012490183115,
            0.031695201992988586,
            0.021741533651947975,
            -0.06469631940126419,
            0.007464305032044649,
            -0.059518128633499146,
            -0.0037898928858339787,
            -0.02983209118247032,
            0.005632353480905294,
            0.015019778162240982,
            -0.05021416023373604,
            0.027304718270897865,
            0.020813683047890663,
            0.002785265212878585,
            0.04789343103766441,
            0.015945853665471077,
            -0.022081805393099785,
            -0.005891437642276287,
            0.0006579566397704184,
            0.0155324162915349,
            0.0027074282988905907,
            -0.02507113665342331,
            0.026117263361811638,
            0.019491655752062798,
            -0.06479649990797043,
            0.007538072764873505,
            -0.036581721156835556,
            0.0141257019713521,
            -0.061784323304891586,
            -0.05062773451209068,
            -0.05159173905849457,
            -0.01349701453000307,
            -0.007536466233432293,
            -0.026015454903244972,
            0.03270270302891731,
            0.001206250162795186,
            -0.0078032067976891994,
            0.023054203018546104,
            0.023816540837287903,
            0.05649527162313461,
            -0.005139811430126429,
            -0.0035258366260677576,
            0.027845511212944984,
            -0.018893711268901825,
            -0.055639006197452545,
            0.01233751606196165,
            0.026128631085157394,
            -0.09352091699838638,
            0.003800018923357129,
            -0.02155381254851818,
            -0.031200390309095383,
            0.08796446770429611,
            -0.04726819694042206,
            -0.0027466141618788242,
            -0.012519885785877705,
            -0.01000102236866951,
            -0.05033626779913902,
            -0.015136606059968472,
            -0.001318631344474852,
            0.008384169079363346,
            -0.012618623673915863,
            0.02514006942510605,
            -0.01822200044989586,
            -0.07118356972932816,
            -0.01973816566169262,
            0.05033305659890175,
            -0.008659148588776588,
            0.0015596045413985848,
            0.0003574539441615343,
            0.005277592223137617,
            0.02835141122341156,
            -0.042023781687021255,
            -0.015257209539413452,
            -0.00897790864109993,
            -0.03694859892129898,
            0.009523102082312107,
            -0.04014519229531288,
            -0.055287569761276245,
            0.012031170539557934,
            0.02940702810883522,
            -0.0012780787656083703,
            -0.06062296777963638,
            -0.036642301827669144,
            -0.03368224576115608,
            0.063836008310318,
            -0.0009640111238695681,
            0.003233374794945121,
            0.028808942064642906,
            0.00472586415708065,
            -0.010837416164577007,
            0.014858915470540524,
            -0.01935076154768467,
            0.04977942258119583,
            0.06012982875108719,
            0.018353622406721115,
            0.016290292143821716,
            0.030904486775398254,
            0.06167394667863846,
            0.05236160755157471,
            0.014091112650930882,
            -0.014545196667313576,
            0.03503239527344704,
            0.009493646211922169,
            0.008607801981270313,
            0.00978405587375164,
            -0.020161088556051254,
            0.031008664518594742,
            -0.03429138660430908,
            0.012730706483125687,
            -0.026464859023690224,
            -0.028453804552555084,
            -0.029688801616430283,
            0.020691795274615288,
            0.04753797501325607,
            0.000782561837695539,
            -0.07389306277036667,
            -0.030927490442991257,
            -0.008444848470389843,
            -0.040368322283029556,
            0.0004648576723411679,
            -0.016343839466571808,
            0.012959981337189674,
            0.026275096461176872,
            -0.01429604273289442,
            0.008218578062951565,
            -0.022407684475183487,
            0.004315279424190521,
            -0.026129264384508133,
            -0.06082256883382797,
            0.01327977143228054,
            -0.010633092373609543,
            -0.008266651071608067,
            -0.005389628000557423,
            0.02826707996428013,
            0.00011928049934795126,
            0.06332956254482269,
            -0.02136218175292015,
            -0.014034277759492397,
            -0.003962026908993721,
            0.03233980014920235,
            0.018370045349001884,
            -0.012138145044445992,
            -0.01950353942811489,
            -0.0015446728793904185,
            -0.017635134980082512,
            0.028727084398269653,
            0.016078772023320198,
            -0.04014716297388077,
            0.009319323115050793,
            -0.08669976145029068,
            -0.02033887431025505,
            -0.06509838998317719,
            -0.01182231679558754,
            0.014539891853928566,
            0.000976216106209904,
            0.02240998111665249,
            -0.035288065671920776,
            -0.006368614267557859,
            -0.009561382234096527,
            -0.022411618381738663,
            -0.01969900168478489,
            -0.01209426298737526,
            -0.022050153464078903,
            0.027342792600393295,
            -0.059504494071006775,
            -0.007331575732678175,
            -0.02210615761578083,
            -0.013682463206350803,
            0.027207979932427406,
            0.04669438302516937,
            -0.0048324717208743095,
            0.015601985156536102,
            0.0748806819319725,
            0.0014470162568613887,
            -0.015813523903489113,
            -0.02712232433259487,
            0.01706455647945404,
            0.019289642572402954,
            0.01217616256326437,
            0.00558376032859087,
            0.0046042632311582565,
            -0.024431917816400528,
            0.00511970417574048,
            -0.044249966740608215,
            -0.00981778185814619,
            -0.05394498258829117,
            0.015913914889097214,
            -0.023322200402617455,
            0.016324885189533234,
            0.0028382735326886177,
            -0.017689159139990807,
            -0.0194481760263443,
            0.005817477125674486,
            -0.02855360507965088,
            0.020672451704740524,
            -0.0019742557778954506,
            -0.0572720505297184,
            -0.011236278340220451,
            0.012076412327587605,
            0.019167810678482056,
            -0.01870439015328884,
            -0.03690033406019211,
            -0.017243338748812675,
            0.014096986502408981,
            0.04008066654205322,
            0.030422938987612724,
            0.0014668030198663473,
            0.014443205669522285,
            -0.020516950637102127,
            -0.015897560864686966,
            0.024760153144598007,
            0.02113712579011917,
            -0.001085765426978469,
            -0.04429103806614876,
            -0.022239265963435173,
            -0.0072248042561113834,
            0.04047197476029396,
            -0.028372440487146378,
            0.004775564186275005,
            -0.018973596394062042,
            0.025290191173553467,
            -0.030180340632796288,
            0.01231104414910078,
            -0.023525938391685486,
            0.040425755083560944,
            0.0006930414820089936,
            0.01635800674557686,
            0.010863746516406536,
            -0.009955834597349167,
            -0.00017170797218568623,
            -0.022922895848751068,
            -0.013854836113750935,
            -0.03554321825504303,
            0.019735056906938553,
            0.014408392831683159,
            0.006839334033429623,
            0.011834141798317432,
            0.009815326891839504,
            0.0015132135013118386,
            -0.03955864533782005,
            -0.017921170219779015,
            0.004762802738696337,
            -0.03644059970974922,
            -0.007530819159001112,
            0.03679507598280907,
            0.011674311943352222,
            -0.0007781208259984851,
            0.014944743365049362,
            0.0015999485040083528,
            0.003993417602032423,
            -0.04088648408651352,
            0.013321074657142162,
            0.02635084092617035,
            -0.01760961301624775,
            -0.0006525044445879757,
            -0.03683203086256981,
            0.049567319452762604,
            0.019577449187636375,
            -0.010855644010007381,
            -0.007518704514950514,
            -0.04070793837308884,
            0.004228202160447836,
            0.01941613107919693,
            0.009599611163139343,
            0.01148173213005066,
            0.0027510197833180428,
            0.023403121158480644,
            0.0063232299871742725,
            0.0003453153185546398,
            0.011389108374714851,
            -0.011909226886928082,
            0.0029676021076738834,
            -0.02918376214802265,
            0.034643713384866714,
            0.032293740659952164,
            0.02391202375292778,
            0.038377825170755386,
            0.05001911148428917,
            -0.03036867454648018,
            -0.015288038179278374,
            0.0027981235180050135,
            0.022726308554410934,
            -0.032601747661828995,
            -0.01519318949431181,
            -0.024367226287722588,
            0.011514891870319843,
            0.003834748174995184,
            -0.0323011539876461,
            0.006119828205555677,
            0.012073678895831108,
            -0.010131950490176678,
            0.052361637353897095,
            -0.039401572197675705,
            -0.0626731887459755,
            0.057386498898267746,
            -0.054035913199186325,
            0.015889236703515053,
            0.0017343570943921804,
            -0.013888097368180752,
            -0.042464133352041245,
            -0.0038905919063836336,
            0.012394402176141739,
            -0.008665258064866066,
            0.013332616537809372,
            -0.028205012902617455,
            -0.01671210303902626,
            0.0028949647676199675,
            0.005433097947388887,
            -0.052729301154613495,
            -0.03693831339478493,
            0.02206888049840927,
            0.026912832632660866,
            0.010219510644674301,
            -0.030655907467007637,
            -0.017117870971560478,
            -0.01132539939135313,
            -0.023537592962384224,
            0.0034800609573721886,
            -0.016491562128067017,
            0.0004348047368694097,
            -0.10318581759929657,
            -0.04930182918906212,
            -0.012812547385692596,
            -0.01799953356385231,
            -0.016650820150971413,
            0.01669890433549881,
            -0.007232764270156622,
            0.032767776399850845,
            -0.02490377239882946,
            0.004766214173287153,
            0.018885087221860886,
            -0.024289987981319427,
            -0.02085021883249283,
            -0.02440587431192398,
            -0.0052321357652544975,
            0.05441088601946831,
            -0.04370775446295738,
            0.02952282875776291,
            0.013417982496321201,
            -0.008441423065960407,
            -0.003201978513970971,
            0.013840863481163979,
            -0.05623875930905342,
            0.01251972559839487,
            -0.037589069455862045,
            -0.02442956529557705,
            0.03142798691987991,
            0.023698315024375916,
            0.011323821730911732,
            -0.023129066452383995,
            0.03756685182452202,
            0.025205692276358604,
            -0.010187131352722645,
            0.033043861389160156,
            -0.002985455561429262,
            -0.009396009147167206,
            -0.02829352207481861,
            -0.01846160925924778,
            0.014197982847690582,
            0.04225557669997215,
            0.02166435495018959,
            -0.044298961758613586,
            0.009038767777383327,
            -0.027796760201454163,
            -0.005840833764523268,
            -0.06814488023519516,
            0.03264428675174713,
            -0.03188292682170868,
            -0.01596582867205143,
            -0.031242582947015762,
            0.04497130215167999,
            -0.007385538890957832,
            0.0004210369079373777,
            -0.0066396924667060375,
            0.03324832022190094,
            -0.050788577646017075,
            0.019092559814453125,
            -0.03122512623667717,
            -0.023034196346998215,
            0.017035100609064102,
            -0.060995522886514664,
            -0.041354648768901825,
            -0.026682628318667412,
            0.001156504382379353,
            -0.007501682266592979,
            -0.03234165534377098,
            -0.011391713283956051,
            0.006397285498678684,
            0.05050378665328026,
            -0.022206012159585953,
            -0.031992167234420776,
            -0.019061345607042313,
            0.02447437308728695,
            -0.03099675104022026,
            -0.01209690235555172,
            -0.02844271995127201,
            0.0477888360619545,
            0.00436524348333478,
            -0.019047129899263382,
            -0.036428432911634445,
            -0.012508900836110115,
            0.053310804069042206,
            -0.01296576764434576,
            0.011874081566929817,
            -0.03790801763534546,
            -0.03611629083752632,
            0.04597615823149681,
            -0.05997283384203911,
            0.012804667465388775,
            -0.07317972183227539,
            0.07512615621089935,
            -0.020227406173944473,
            0.030869118869304657,
            0.10515568405389786,
            -0.03768761828541756,
            -0.06823915243148804,
            -0.012137175537645817,
            0.01507985033094883,
            -0.0003394136147107929,
            -0.0044710454531013966,
            0.023281550034880638,
            0.03861445188522339,
            0.006263985764235258,
            -0.06184754893183708,
            -0.015306415967643261,
            -0.017692849040031433,
            -0.022632643580436707,
            0.03263028338551521,
            0.010290141217410564,
            -0.019802335649728775,
            0.01959632895886898,
            0.018831923604011536,
            -0.013617519289255142,
            0.01642473042011261,
            0.019144412130117416,
            0.027365734800696373,
            0.001150927972048521,
            -0.012678565457463264,
            -0.07106729596853256,
            0.017662057653069496,
            -0.018956119194626808,
            -0.035379767417907715,
            0.027807284146547318,
            0.03653096780180931,
            -0.01422561053186655,
            0.01158231683075428,
            0.05414840206503868,
            0.025119271129369736,
            0.026531968265771866,
            0.008520739153027534,
            -0.013384468853473663,
            -0.012092535383999348,
            0.005822769366204739,
            -0.03578998148441315,
            -0.00662360480055213,
            0.02491459995508194,
            0.029294749721884727,
            0.03606584295630455,
            -0.027074748650193214,
            -0.01454202551394701,
            0.009765676222741604,
            0.06738249957561493,
            -0.040842454880476,
            0.007637389469891787,
            -0.04211699590086937,
            -0.01845492795109749,
            0.05035421997308731,
            -0.031095100566744804,
            -0.005275806412100792,
            0.03086002916097641,
            -0.010532903485000134,
            -0.01684458740055561,
            -0.0021547479555010796,
            -0.03850002586841583,
            0.0013214887585490942,
            -0.027986571192741394,
            -0.1034604161977768,
            0.020129380747675896,
            0.007834728807210922,
            -0.019733736291527748,
            -0.016273243352770805,
            -0.030602484941482544,
            -0.020197000354528427,
            -0.019442562013864517,
            0.03825731948018074,
            0.039235346019268036,
            -0.04652790352702141,
            0.0021368127781897783,
            -0.022384746000170708,
            0.02368837594985962,
            0.02093738131225109,
            0.01127544417977333,
            -0.03779136389493942,
            0.025065457448363304,
            -0.058333832770586014,
            -0.006826076190918684,
            -0.030629519373178482,
            0.036706652492284775,
            -0.0036156494170427322,
            0.020062608644366264,
            -0.006227351725101471,
            -0.04038672894239426,
            0.00522956857457757,
            0.0016148234717547894,
            0.009620830416679382,
            -0.0543905571103096,
            0.003580196062102914,
            0.03283010050654411,
            0.01943065971136093,
            -0.0036276166792958975,
            -0.020955625921487808,
            -0.013086080551147461,
            0.00031016053981147707,
            0.00010579180525382981,
            -0.014817246235907078,
            -0.02158796228468418,
            -0.0025189556181430817,
            0.003214301774278283,
            0.007282257545739412,
            -0.061130277812480927,
            -0.029358841478824615,
            0.006293023470789194,
            -0.032472752034664154,
            -0.013117634691298008,
            0.003755044424906373,
            0.03843036666512489,
            -0.015831567347049713,
            -0.030658047646284103,
            -0.023442436009645462,
            -0.006195735186338425,
            -0.017036763951182365,
            0.0021773383487015963,
            -0.009932644665241241,
            0.01911250501871109,
            -0.003140480723232031,
            0.008319652639329433,
            -0.0038001281209290028,
            -0.003291444620117545,
            0.02175533026456833,
            -0.00456215487793088,
            -0.00090713647659868,
            0.031578510999679565,
            -0.020030410960316658,
            -0.03185433894395828,
            0.02561079151928425,
            -0.059998832643032074,
            -0.014058408327400684,
            0.016454370692372322,
            -0.04051331803202629,
            -0.013186986558139324,
            0.01916862465441227,
            -0.03536628186702728,
            -0.005768126342445612,
            -0.0034254761412739754,
            0.019938502460718155,
            0.02081165835261345,
            -0.0110264727845788,
            -0.01603926531970501,
            -0.01394916232675314,
            -0.019804585725069046,
            0.005288988351821899,
            0.024220338091254234,
            -0.03895680978894234,
            -0.037143364548683167,
            0.00739672314375639,
            0.007607879117131233,
            -0.04432019963860512,
            -0.020758505910634995,
            0.0031115366145968437,
            -0.013208556920289993,
            0.03412844240665436,
            0.01165713183581829,
            0.022553306072950363,
            0.003076958702877164,
            -0.000566615432035178,
            -0.01472171675413847,
            0.02013380080461502,
            -0.007062720600515604,
            -0.035725854337215424,
            -0.019363930448889732,
            -0.02482004277408123,
            -0.01898333802819252,
            0.00633975537493825,
            -0.01025757659226656,
            -0.00881794560700655,
            -0.021666880697011948,
            -0.012211144901812077,
            0.03099221922457218,
            -0.012243514880537987,
            0.02779567986726761,
            0.020009392872452736,
            0.0027318838983774185,
            0.005488799884915352,
            -0.031108394265174866,
            0.028725555166602135,
            -0.04037698730826378,
            -0.0456865169107914,
            -0.0027930117212235928,
            0.001115430030040443,
            -0.06763072311878204,
            0.0074742683209478855,
            -0.0020756530575454235,
            0.05567506328225136,
            -0.020322587341070175,
            -0.006060629617422819,
            -0.017531899735331535,
            0.0005430504097603261,
            0.030499162152409554,
            -0.03320109471678734,
            0.008363245986402035,
            0.016299821436405182,
            0.08702313899993896,
            0.032348617911338806,
            0.02388330176472664,
            -0.02657978981733322,
            0.022223075851798058,
            -0.020564554259181023,
            -0.030373072251677513,
            0.026420339941978455,
            0.032448019832372665,
            -0.017959561198949814,
            -0.0022495000157505274,
            -0.029788417741656303,
            0.01060440018773079,
            0.026971396058797836,
            0.05572229623794556,
            0.020209047943353653,
            -0.07911020517349243
        ],
        [
            0.00549846887588501,
            0.02959277108311653,
            -0.024280918762087822,
            -0.016700537875294685,
            -0.006284247152507305,
            0.013198825530707836,
            0.013508232310414314,
            0.007848167791962624,
            -0.00825480092316866,
            0.012115280143916607,
            -0.006159713491797447,
            -0.010871919803321362,
            0.013301797211170197,
            -0.007324738893657923,
            -0.05092182010412216,
            0.007476231083273888,
            0.033694345504045486,
            0.012933950871229172,
            0.006887136492878199,
            -0.027302920818328857,
            0.009046943858265877,
            0.002900803228840232,
            0.008246620185673237,
            -0.026736631989479065,
            -0.0237712599337101,
            -0.02601509913802147,
            -0.02029004693031311,
            -0.0048179952427744865,
            -0.01537503581494093,
            -0.047243259847164154,
            -0.028004221618175507,
            0.010821796953678131,
            0.03250082582235336,
            -0.04877660050988197,
            -0.022468356415629387,
            0.009282155893743038,
            0.008555694483220577,
            -0.017441004514694214,
            0.01837049052119255,
            -0.01505639124661684,
            0.006061447784304619,
            0.00748707028105855,
            0.004777755122631788,
            -0.034209273755550385,
            0.0210756566375494,
            -0.00022931376588530838,
            -0.020078549161553383,
            0.014116134494543076,
            0.0432266928255558,
            0.01631171628832817,
            0.02962668426334858,
            0.006147902458906174,
            -0.026963775977492332,
            0.036159515380859375,
            -0.004983073566108942,
            0.0031426900532096624,
            -0.025004006922245026,
            0.03266674652695656,
            0.0074165393598377705,
            0.021999036893248558,
            0.004616308957338333,
            0.01746019907295704,
            -0.006022678688168526,
            -0.020213352516293526,
            0.02491023764014244,
            -0.0245143324136734,
            0.02848323993384838,
            -0.046548184007406235,
            -0.0318927988409996,
            -0.026807626709342003,
            -0.00488197011873126,
            0.01950814761221409,
            -0.018926216289401054,
            0.011870389804244041,
            -0.03410575911402702,
            0.06587136536836624,
            0.007628022693097591,
            0.029340732842683792,
            -0.06386236101388931,
            0.00987936370074749,
            -0.04103390872478485,
            -0.011148491874337196,
            0.026027530431747437,
            0.01198467519134283,
            -0.014612001366913319,
            0.001441875589080155,
            0.02368331141769886,
            -0.0009479320724494755,
            -0.027251314371824265,
            0.001023592078126967,
            -0.021486228331923485,
            0.007289619650691748,
            -0.046980973333120346,
            0.00046886346535757184,
            0.035577043890953064,
            -0.01827646791934967,
            0.03747306391596794,
            0.017451703548431396,
            -3.928213118342683e-05,
            -0.00790773518383503,
            0.018875282257795334,
            -0.009781604632735252,
            0.016125377267599106,
            0.030645690858364105,
            -0.021428147330880165,
            -0.0018408788600936532,
            -0.023947633802890778,
            0.028977952897548676,
            -0.032480575144290924,
            -0.003913245629519224,
            0.008073433302342892,
            -0.002322274725884199,
            0.014580493792891502,
            -0.011416547931730747,
            0.0658901110291481,
            0.009764543734490871,
            -0.018533602356910706,
            -0.02597791701555252,
            0.00772340502589941,
            0.027339797466993332,
            -0.0018616481684148312,
            -0.024887021631002426,
            -0.05533749610185623,
            0.01673930138349533,
            -0.0016847294755280018,
            0.029867034405469894,
            -0.0012612933060154319,
            0.033076170831918716,
            0.00993284396827221,
            0.0005956601817160845,
            -0.008036462590098381,
            0.07048483937978745,
            -0.03233378380537033,
            0.03928126394748688,
            0.02413836307823658,
            0.03069315291941166,
            0.022763751447200775,
            -0.0020370096899569035,
            0.023144830018281937,
            -0.01789814978837967,
            0.007483916822820902,
            -0.03604789078235626,
            -0.012638300657272339,
            -0.010271571576595306,
            0.04066292196512222,
            0.01911589503288269,
            -0.03771322965621948,
            -0.013956598937511444,
            0.0013891489943489432,
            -0.0021274995524436235,
            0.034140802919864655,
            -0.014154398813843727,
            0.020861567929387093,
            0.00820461567491293,
            -0.02747565321624279,
            0.02962832897901535,
            0.04790905863046646,
            -0.0017774689476937056,
            -0.01130480132997036,
            -0.05446069687604904,
            0.013571394607424736,
            0.010496128350496292,
            0.0544387549161911,
            -0.0001217272219946608,
            0.027897268533706665,
            0.009866480715572834,
            0.009262369014322758,
            0.04362853616476059,
            -0.005579846445471048,
            -0.021384740248322487,
            -0.0010295439278706908,
            0.0066613443195819855,
            0.025162314996123314,
            0.032193612307310104,
            0.014250717125833035,
            -0.0212433859705925,
            0.004681033082306385,
            0.04369959607720375,
            -0.004912195727229118,
            -0.03498556837439537,
            0.03377247601747513,
            -0.010479507967829704,
            0.038122501224279404,
            0.02259320579469204,
            -0.007959074340760708,
            -0.008734791539609432,
            0.020802630111575127,
            0.01061381958425045,
            0.011567390523850918,
            0.005657595582306385,
            -0.01140010915696621,
            0.019524790346622467,
            -0.05352529138326645,
            -0.022039512172341347,
            -0.0006109980749897659,
            0.006999898236244917,
            0.0026401146315038204,
            0.01486608013510704,
            0.04520462080836296,
            0.0018462715670466423,
            0.031283989548683167,
            -0.004200003109872341,
            0.0073321424424648285,
            -0.010586808435618877,
            -0.06700807809829712,
            0.013146311044692993,
            0.0016549098072573543,
            -0.013175670988857746,
            -0.007070131134241819,
            0.011099990457296371,
            0.015898065641522408,
            0.022589001804590225,
            -0.06271019577980042,
            -0.023909928277134895,
            0.025908369570970535,
            0.029510848224163055,
            -0.02986813522875309,
            -0.03648122027516365,
            0.030936576426029205,
            0.04431620240211487,
            -0.010330201126635075,
            0.011179881170392036,
            0.0015613321447744966,
            -0.023771869018673897,
            -0.0015137410955503583,
            -0.011420217342674732,
            0.03166622295975685,
            -0.02058238536119461,
            0.014376274310052395,
            0.010936972685158253,
            -0.005756520666182041,
            0.042300768196582794,
            -0.019971691071987152,
            -0.024928493425250053,
            -0.04023366421461105,
            -0.021711280569434166,
            0.0107834218069911,
            0.005563291721045971,
            -0.0259220190346241,
            -0.0038420590572059155,
            0.026056107133626938,
            -0.015466918237507343,
            -0.023604264482855797,
            0.02011089026927948,
            -0.010576237924396992,
            -0.006944518536329269,
            0.006923021748661995,
            -0.016386382281780243,
            0.026085997000336647,
            -0.0025096465833485126,
            0.024087591096758842,
            -0.009276237338781357,
            0.015644721686840057,
            0.01260298490524292,
            0.028050044551491737,
            0.023876531049609184,
            0.004321877378970385,
            -0.007079913280904293,
            0.033334843814373016,
            0.0522589236497879,
            0.01166265644133091,
            -0.003274237271398306,
            0.020000576972961426,
            -0.01712111383676529,
            0.008946176618337631,
            0.049459438771009445,
            -0.01719401776790619,
            0.027506118640303612,
            0.0037075479049235582,
            -0.03432399034500122,
            0.00580860348418355,
            0.03465384617447853,
            -0.006065688095986843,
            0.014250369742512703,
            0.010674131102859974,
            0.014018997550010681,
            0.00033332756720483303,
            0.020768985152244568,
            -0.01395195908844471,
            -0.01294042356312275,
            0.015703164041042328,
            0.0020896790083497763,
            -0.0062864599749445915,
            0.03867034986615181,
            0.02309081330895424,
            0.027961809188127518,
            -0.000997989671304822,
            0.03244470804929733,
            -0.007384884171187878,
            0.025732725858688354,
            -0.008160654455423355,
            0.03170665726065636,
            -0.002253083512187004,
            0.024986255913972855,
            -0.027389926835894585,
            -0.022169221192598343,
            0.04037271812558174,
            0.007576839532703161,
            -0.009454693645238876,
            -0.020395448431372643,
            0.02957279048860073,
            0.0075652687810361385,
            -0.0023026259150356054,
            -0.0011814385652542114,
            0.0078093199990689754,
            0.007614887319505215,
            -0.010117646306753159,
            -0.041371386498212814,
            0.019405506551265717,
            0.029508251696825027,
            -0.031037429347634315,
            -0.014811006374657154,
            0.018308892846107483,
            -0.025898613035678864,
            -0.006331638433039188,
            0.03771686553955078,
            -0.02941940166056156,
            0.014449618756771088,
            0.026272306218743324,
            0.01987515389919281,
            0.021784214302897453,
            -0.020776202902197838,
            -0.00033370524761267006,
            -0.02901141159236431,
            -0.03192908689379692,
            -0.01719897799193859,
            -0.057680893689394,
            0.018090955913066864,
            -0.012437041848897934,
            0.017154213041067123,
            0.03029404580593109,
            -0.008107297122478485,
            -0.00066232995595783,
            -0.01624166965484619,
            -0.003381340065971017,
            0.011559724807739258,
            -0.005796152167022228,
            -0.04029765725135803,
            0.015514340251684189,
            -0.004566767252981663,
            -0.025106901302933693,
            0.000379839533707127,
            -0.03599082678556442,
            0.011128549464046955,
            -0.02243557572364807,
            0.015124966390430927,
            0.0018841262208297849,
            0.027082713320851326,
            -0.010836649686098099,
            0.003921689931303263,
            -0.009222006425261497,
            0.014103454537689686,
            0.04326491057872772,
            0.001250700792297721,
            -0.01598617248237133,
            0.02068462409079075,
            -0.02861962839961052,
            0.01938755065202713,
            -0.02368403598666191,
            -0.03747965395450592,
            0.024685362353920937,
            0.0019320787396281958,
            0.04338602349162102,
            -0.030961882323026657,
            0.03758329153060913,
            -0.00034856508136726916,
            0.017054008319973946,
            0.03155001997947693,
            -0.007248507346957922,
            -0.006609973963350058,
            0.02373657189309597,
            -0.025491829961538315,
            0.037895672023296356,
            -0.018158748745918274,
            0.0007151809404604137,
            0.007481738924980164,
            -0.013996739871799946,
            -0.01492810808122158,
            0.0043318746611475945,
            0.019129754975438118,
            -0.0034341136924922466,
            0.025180332362651825,
            0.00464410986751318,
            -0.016589835286140442,
            -0.0043874466791749,
            0.046450089663267136,
            0.0006144255166873336,
            -0.0008089524926617742,
            -0.021146761253476143,
            -0.04011000320315361,
            0.011551312170922756,
            0.003247022395953536,
            0.037094712257385254,
            0.022526150569319725,
            0.01395519357174635,
            -0.012852161191403866,
            0.015899287536740303,
            0.017177164554595947,
            0.030666247010231018,
            0.03826583921909332,
            -0.0012309950543567538,
            0.01587766967713833,
            -0.0067886244505643845,
            -0.0007911993307061493,
            -0.009519143030047417,
            0.016201399266719818,
            -0.03676270321011543,
            0.008775368332862854,
            -0.012504816986620426,
            0.047617699950933456,
            0.00860332790762186,
            0.012039641849696636,
            -0.01923307217657566,
            0.014721827581524849,
            -0.013660615310072899,
            0.0010278788395226002,
            -0.01628813147544861,
            0.026450153440237045,
            0.03796852380037308,
            0.03161856532096863,
            0.024761425331234932,
            0.012471913360059261,
            -0.03621644526720047,
            0.020674025639891624,
            -0.00879847351461649,
            0.027424469590187073,
            -0.01628190092742443,
            0.03279475122690201,
            -0.027759987860918045,
            0.018265753984451294,
            0.014447202906012535,
            -0.031621187925338745,
            0.012741758488118649,
            -0.008557919412851334,
            0.02342851832509041,
            -0.02113412320613861,
            0.013003401458263397,
            0.02763724885880947,
            -0.0038380089681595564,
            0.017852598801255226,
            -0.028695926070213318,
            -0.007743362803012133,
            -0.015895463526248932,
            0.0024564212653785944,
            -0.017841756343841553,
            0.023430420085787773,
            -0.020941322669386864,
            0.02527163363993168,
            0.0029722675681114197,
            0.01099887304008007,
            0.02339796908199787,
            0.032791294157505035,
            0.013057736679911613,
            0.03245771303772926,
            0.011590158566832542,
            -0.0023478928487747908,
            0.012153408490121365,
            -0.0015599316684529185,
            0.017571892589330673,
            0.021822702139616013,
            -0.006186908110976219,
            0.02040695957839489,
            0.0005063700955361128,
            -0.013540089130401611,
            0.007762442342936993,
            0.025712918490171432,
            -0.007753686048090458,
            -0.04541214555501938,
            0.0028153015300631523,
            -0.00805791188031435,
            0.02399299666285515,
            0.024532772600650787,
            0.010422533378005028,
            0.00220181280747056,
            0.005764290690422058,
            0.03425921872258186,
            -0.002804122632369399,
            0.007675901986658573,
            -0.016622697934508324,
            -0.028291422873735428,
            0.022674812003970146,
            0.005979915149509907,
            0.00908479280769825,
            0.018724344670772552,
            0.053840380162000656,
            -0.011288951151072979,
            0.0068756029941141605,
            -0.005891135428100824,
            -0.08045662194490433,
            0.013871216215193272,
            0.00356411375105381,
            -0.015879852697253227,
            -0.01446123793721199,
            0.03086588717997074,
            -0.007248238194733858,
            0.025252124294638634,
            0.011249704286456108,
            0.002772930543869734,
            -0.029836775735020638,
            -0.0003741858818102628,
            -0.025797737762331963,
            -0.022375842556357384,
            -0.008456019684672356,
            0.0013474497245624661,
            -0.018341809511184692,
            -0.02057182788848877,
            0.035051390528678894,
            0.01918206550180912,
            0.009665021672844887,
            0.00011696777073666453,
            -0.009615394286811352,
            -0.043024592101573944,
            -0.038386743515729904,
            -0.0006580507033504546,
            0.010887139476835728,
            -0.004611168522387743,
            0.019474070519208908,
            0.006536086089909077,
            0.007321384735405445,
            -0.0026626980397850275,
            0.02027074061334133,
            0.005091922357678413,
            0.03056313656270504,
            -0.04404600337147713,
            -0.06853237003087997,
            0.018362032249569893,
            -0.03808969259262085,
            -0.009565634652972221,
            0.00782153569161892,
            -0.00531670032069087,
            -0.0054201786406338215,
            0.01201024278998375,
            0.011131181381642818,
            -0.020526625216007233,
            -0.000807764008641243,
            -0.0267631858587265,
            0.01780807413160801,
            0.009638811461627483,
            0.017176609486341476,
            0.015566732734441757,
            -0.021874310448765755,
            0.02338624745607376,
            0.012268058024346828,
            0.0034833387471735477,
            0.03897804766893387,
            -0.015607346780598164,
            -0.006901380140334368,
            0.04566001519560814,
            -0.024996386840939522,
            -0.002180660841986537,
            -0.011829014867544174,
            -0.03256077319383621,
            0.01098159234970808,
            0.013094042427837849,
            0.006049274932593107,
            -0.018035952001810074,
            -0.0011166362091898918,
            -0.009352536872029305,
            0.035140544176101685,
            -0.01008920930325985,
            -0.019207092002034187,
            0.013486041687428951,
            -0.04001126438379288,
            0.011320462450385094,
            0.011436930857598782,
            -0.025380423292517662,
            -0.04070379212498665,
            -0.029301444068551064,
            -0.0011687700171023607,
            0.0159376822412014,
            -0.023704934865236282,
            0.025157829746603966,
            0.017028488218784332,
            0.03150777146220207,
            -0.00769042270258069,
            0.022534679621458054,
            0.05840673670172691,
            -0.006822352763265371,
            -0.008588707074522972,
            -0.03867345675826073,
            0.01944640837609768,
            0.020109748467803,
            0.020659279078245163,
            0.02324664406478405,
            0.024614039808511734,
            0.031097471714019775,
            0.010881701484322548,
            0.019512062892317772,
            0.02305910363793373,
            0.005144777707755566,
            -0.009836639277637005,
            -0.02314884029328823,
            0.002265472197905183,
            -0.01235777698457241,
            0.008496483787894249,
            -0.021437259390950203,
            0.0024783252738416195,
            0.03618132695555687,
            0.02218610607087612,
            -0.027501270174980164,
            0.028379451483488083,
            -0.010327733121812344,
            -0.017727790400385857,
            -0.002879293169826269,
            -0.021385161206126213,
            0.01573561690747738,
            0.011800036765635014,
            -0.0152814332395792,
            -0.007564233615994453,
            0.02099309302866459,
            -0.010495119728147984,
            0.014011742547154427,
            0.01924847438931465,
            -0.020410092547535896,
            0.04199296981096268,
            0.04641861841082573,
            -0.012427572160959244,
            -0.03039182536303997,
            -0.01940532959997654,
            0.015771329402923584,
            -0.0011344735976308584,
            0.008332964032888412,
            0.010631218552589417,
            -0.009808208793401718,
            0.02096538618206978,
            0.03798381984233856,
            -0.008761497214436531,
            0.03665924817323685,
            0.024106735363602638,
            0.012034878134727478,
            0.02472744509577751,
            0.002068731002509594,
            0.01855316199362278,
            0.039330240339040756,
            -0.059147097170352936,
            -0.014303474687039852,
            -0.007125395815819502,
            -0.01488691195845604,
            -0.007171774748712778,
            0.025640403851866722,
            0.03901181370019913,
            0.037597235292196274,
            0.025170985609292984,
            -0.04952728748321533,
            -0.010129271075129509,
            -0.015441914089024067,
            -0.030313611030578613,
            -0.014201229438185692,
            0.021022647619247437,
            0.011896677315235138,
            -0.029498817399144173,
            0.0005250583635643125,
            0.0045405058190226555,
            -0.011259010061621666,
            0.017359651625156403,
            -0.0186038576066494,
            0.017869386821985245,
            -0.015650128945708275,
            -0.04022898152470589,
            0.006351559888571501,
            0.029149800539016724,
            -0.030881674960255623,
            -0.01317084114998579,
            0.03914421796798706,
            -0.01799209788441658,
            -0.01690967008471489,
            0.012322122231125832,
            -0.025822140276432037,
            0.01120450347661972,
            -0.008065818808972836,
            -0.018892651423811913,
            0.03816723823547363,
            -0.00863513071089983,
            0.00997762568295002,
            -0.03196173906326294,
            0.021638669073581696,
            0.017318233847618103,
            -0.011245817877352238,
            0.005306791514158249,
            -0.0023689584340900183,
            0.03189225122332573,
            -0.0015274612233042717,
            0.030387844890356064,
            0.014987490139901638,
            0.017211610451340675,
            0.006486823316663504,
            0.002486502518877387,
            0.013494090177118778,
            0.02470034919679165,
            -0.0013843750348314643,
            0.03480175882577896,
            -0.009275675751268864,
            -0.0050254459492862225,
            -0.027371427044272423,
            0.025936204940080643,
            0.021842915564775467,
            0.026062797755002975,
            -0.0008152993978001177,
            0.016133980825543404,
            -0.0038741515018045902,
            -0.002317521721124649,
            0.008729555644094944,
            -0.0006973435520194471,
            -0.008419729769229889,
            0.008460276760160923,
            0.00917776394635439,
            0.0035088402219116688,
            -0.011039650067687035,
            0.03616001829504967,
            -0.022634128108620644,
            -0.005951771512627602,
            0.036028362810611725,
            0.02370130643248558,
            0.011365010403096676,
            0.02510985918343067,
            0.01557850930839777,
            0.02079017087817192,
            0.015777839347720146,
            -0.010335096158087254,
            -0.027221402153372765,
            -0.023092158138751984,
            -0.004952084273099899,
            -0.02056836150586605,
            -0.007587498053908348,
            0.002124214544892311,
            -0.018265677616000175,
            -0.006574003957211971,
            0.03827771544456482,
            -0.017767449840903282,
            0.005911251064389944,
            -0.0014273942215368152,
            0.01482008583843708,
            0.03998889401555061,
            -0.00870602112263441,
            0.009888962842524052,
            0.020669814199209213,
            -0.010295568034052849,
            -0.03645715489983559,
            0.027033807709813118,
            -0.012770602479577065,
            0.02116156555712223,
            -0.015410922467708588,
            0.023379690945148468,
            0.011433078907430172,
            -0.017100028693675995,
            0.007233965676277876,
            0.0699632465839386,
            0.030548740178346634,
            -0.011237477883696556,
            0.014552416279911995,
            -0.03437715023756027,
            -0.01074138842523098,
            -0.023365076631307602,
            0.05287909135222435,
            0.0046291411854326725,
            0.028207950294017792,
            -0.00672469288110733,
            0.03556625545024872,
            0.02408088929951191,
            -0.020748334005475044,
            -0.030882136896252632,
            -0.05155537649989128,
            0.031031640246510506,
            -0.014055364765226841,
            -0.008595540188252926,
            0.0064347852021455765,
            -0.024200525134801865,
            -0.024895424023270607,
            -0.0326385535299778,
            -0.019250648096203804,
            -0.015058612450957298,
            -0.006407248787581921,
            0.014664918184280396,
            -0.018143950030207634,
            0.002880916465073824,
            -0.003584156511351466,
            0.05122679844498634,
            0.019820259883999825,
            0.0252604391425848,
            -0.04278241842985153,
            -0.029527898877859116,
            0.020736923441290855,
            -0.010950922034680843,
            0.004215752240270376,
            0.0205820444971323,
            -0.009099769406020641,
            0.0262922216206789,
            0.03544268384575844,
            0.003703719237819314,
            -0.017147967591881752,
            0.0022913787979632616,
            0.012275395914912224,
            0.05627414584159851,
            -0.009988938458263874,
            -0.033239565789699554,
            -0.00262587727047503,
            -0.01944768615067005,
            -0.02473572827875614,
            -0.0279878880828619,
            0.011038937605917454,
            0.023606816306710243,
            0.06721518933773041,
            0.039996203035116196,
            0.0018898301059380174,
            0.02120516262948513,
            0.020204126834869385,
            0.03634405881166458,
            0.022750454023480415,
            0.019109465181827545,
            0.046049099415540695,
            -0.015416343696415424,
            -0.01818694919347763,
            -0.0060431137681007385,
            0.023114539682865143,
            0.0036703108344227076,
            0.0007352094980888069,
            -0.037165138870477676,
            -0.001002427306957543,
            0.028757423162460327,
            -0.0007465485250577331,
            -0.02982422709465027,
            -0.006931550335139036,
            0.005540375132113695,
            0.027597254142165184,
            -0.05174480751156807,
            -0.03280201181769371,
            -0.0014367944095283747,
            0.02802148461341858,
            -0.002217930508777499,
            0.021013129502534866,
            0.01829840987920761,
            0.007309997919946909,
            -0.003111570607870817,
            0.007971719838678837,
            0.01748763397336006,
            -0.0075913663022220135,
            -0.013883222825825214,
            0.003193201031535864,
            0.0026201836299151182,
            0.04192497953772545,
            -0.018659012392163277,
            0.009122451767325401,
            -0.0029656009282916784,
            0.038724225014448166,
            -0.013998900540173054,
            -0.020893670618534088,
            -0.017438599839806557,
            -0.027509775012731552,
            -0.0011129573686048388,
            0.016145730391144753,
            -0.013088295236229897,
            0.017146319150924683,
            0.022943178191781044,
            -0.032002467662096024,
            0.020353099331259727,
            0.02766535058617592,
            -0.0020306729711592197,
            0.022480322048068047,
            -0.06632944196462631,
            0.025059331208467484,
            0.019637644290924072,
            0.020258208736777306,
            -0.037366192787885666,
            -0.005826376844197512,
            -0.035613615065813065,
            -0.0360940620303154,
            -0.025823453441262245,
            0.04045006260275841,
            0.011738407425582409,
            0.015911009162664413,
            -0.022939130663871765,
            0.019789710640907288,
            -0.008168257772922516,
            0.043314315378665924,
            0.0024471732322126627,
            -0.0019592924509197474,
            -0.02317853644490242,
            -0.021874982863664627,
            -0.03498619422316551,
            0.00496723223477602,
            0.008660192601382732,
            -0.019467821344733238,
            0.016853149980306625,
            0.006961505860090256,
            0.010783366858959198,
            0.015351240523159504,
            0.0160025916993618,
            0.032360535115003586,
            0.0014151872601360083,
            -0.009779476560652256,
            -0.006257973611354828,
            0.004110320936888456,
            -0.03203826770186424,
            -0.014214256778359413,
            0.020363133400678635,
            0.00724131939932704,
            0.010994917713105679,
            -0.026996048167347908,
            0.031065408140420914,
            0.010620239190757275,
            -0.01356592494994402,
            0.020941413938999176,
            0.030404912307858467,
            0.028272230178117752,
            0.024508465081453323,
            0.014975614845752716,
            -0.0009730452438816428,
            0.014236390590667725,
            0.02361498959362507,
            0.015493623912334442,
            0.017796987667679787,
            0.0007884641527198255,
            0.046498656272888184,
            0.04803440347313881,
            0.014863944612443447,
            -0.015537768602371216,
            0.027915624901652336,
            0.03399672359228134,
            0.002908795140683651,
            0.009711390361189842,
            0.03755755349993706,
            0.010688208974897861,
            -0.01620258204638958,
            -0.01352313905954361,
            0.022956056520342827,
            -0.04518669843673706,
            -0.018509766086935997,
            0.02149740420281887,
            0.00841655395925045,
            0.030289696529507637,
            -0.030098645016551018,
            -0.037098776549100876,
            0.026970716193318367,
            -0.03335176408290863,
            -0.03655242919921875,
            0.04818348586559296,
            -0.023155707865953445,
            0.017383789643645287,
            0.011106745339930058,
            0.024555515497922897,
            0.006395840086042881,
            -0.0024051889777183533,
            0.009005730040371418,
            0.0035393948201090097,
            0.019533082842826843,
            -0.008538087829947472,
            0.01483254972845316,
            0.05707560107111931,
            0.002739191986620426,
            0.010416808538138866,
            -0.03813400864601135,
            0.03610915690660477,
            -0.020215269178152084,
            0.030089274048805237,
            0.05761124566197395,
            -0.016033437103033066,
            -0.03727884963154793,
            -0.026723233982920647,
            -0.0012843989534303546,
            -0.011692872270941734,
            -0.0036894134245812893,
            0.005453440826386213,
            -0.018454764038324356,
            -0.02563646249473095,
            0.03396343067288399,
            0.026808258146047592,
            -0.031850457191467285,
            0.007358251605182886,
            0.02389146201312542,
            0.018358120694756508,
            0.012389850802719593,
            -0.029034024104475975,
            -0.03405046463012695,
            0.0040602716617286205,
            -0.01728796400129795,
            0.008304479531943798,
            0.05094504728913307,
            0.02582981251180172,
            -0.0174159724265337,
            -0.01797347329556942,
            -0.010803576558828354,
            0.01456564012914896,
            -0.059913963079452515,
            -0.010777863673865795,
            -0.005404285155236721,
            0.005054230336099863,
            0.021010814234614372,
            -0.020714305341243744,
            -0.015296345576643944,
            -0.0023967833258211613,
            -0.0075208526104688644,
            -0.02814529277384281,
            0.008807139471173286,
            0.0176071897149086,
            0.012942471541464329,
            -0.04501250758767128,
            0.0005535820964723825,
            -0.0199498999863863,
            0.05185943841934204,
            -0.020702803507447243,
            0.0101312305778265,
            0.03060014173388481,
            -0.006711386609822512,
            -0.01696735993027687,
            0.026485968381166458,
            0.012721830047667027,
            0.02661667764186859,
            -0.04927624389529228,
            0.03214092552661896,
            0.002303261775523424,
            0.021663261577486992,
            -0.003767334623262286,
            0.02143320068717003,
            0.018489785492420197,
            -0.01000542938709259,
            -0.04166937619447708,
            -0.014329547993838787,
            -0.010700307786464691,
            0.005743743386119604,
            0.029131673276424408,
            -0.02946379967033863,
            0.041101302951574326,
            0.0027971400413662195,
            -0.024278363212943077
        ],
        [
            0.04587133973836899,
            0.0017818205524235964,
            0.01879941113293171,
            0.004065884277224541,
            0.003268484491854906,
            0.06169438734650612,
            -0.009277205914258957,
            0.02246672846376896,
            0.0173712857067585,
            -0.029095850884914398,
            0.031230280175805092,
            0.02039327286183834,
            0.029817309230566025,
            -0.02556457743048668,
            0.021881423890590668,
            -0.01511097326874733,
            0.011172567494213581,
            -0.013831057585775852,
            0.029777511954307556,
            -0.044511087238788605,
            -0.02572043053805828,
            -0.017547866329550743,
            0.009273633360862732,
            -0.031347546726465225,
            0.007036395370960236,
            0.035008832812309265,
            0.006790885701775551,
            0.031812526285648346,
            -0.02220957539975643,
            -0.015442337840795517,
            -0.04868365079164505,
            -0.028314542025327682,
            0.008270869962871075,
            -0.001884433557279408,
            -0.02383480593562126,
            -0.03307769075036049,
            -0.005016036797314882,
            -0.016332194209098816,
            -0.007848069071769714,
            -0.013879967853426933,
            0.03303007408976555,
            -0.05822775512933731,
            -0.01743769645690918,
            -0.008461900986731052,
            -0.007966327480971813,
            -0.01762087643146515,
            -0.005278728436678648,
            0.04337050020694733,
            0.037630677223205566,
            0.018483731895685196,
            0.03247442469000816,
            0.01882218010723591,
            -0.023455068469047546,
            0.012017311528325081,
            -0.01621817611157894,
            0.05103367939591408,
            0.010054548271000385,
            0.018114393576979637,
            -0.02034563198685646,
            -0.003663263749331236,
            0.0060679297894239426,
            0.013355572707951069,
            -0.030716534703969955,
            -0.05034780129790306,
            0.004632297437638044,
            -0.0038715973496437073,
            0.0005992760416120291,
            -0.013977674767374992,
            0.01808743178844452,
            -0.016933562234044075,
            0.02612527459859848,
            0.013840683735907078,
            0.011060979217290878,
            0.02723146229982376,
            -0.01582798734307289,
            -0.04941015690565109,
            0.03968258574604988,
            0.022045429795980453,
            0.0029460196383297443,
            0.0312153622508049,
            0.004583629313856363,
            -0.018066365271806717,
            0.010612721554934978,
            0.009393066167831421,
            -0.040058404207229614,
            -0.012005987577140331,
            0.012154275551438332,
            0.03280564397573471,
            0.006380246952176094,
            0.03983372449874878,
            -0.0009332190966233611,
            -0.02180824987590313,
            0.007497196551412344,
            -0.030089128762483597,
            0.0027041188441216946,
            0.003927990328520536,
            -0.0005003153346478939,
            0.007170031778514385,
            -0.023702485486865044,
            0.01689748466014862,
            0.017282793298363686,
            0.024820255115628242,
            0.032807186245918274,
            -0.047369420528411865,
            -0.035456106066703796,
            -0.008978747762739658,
            0.00027312219026498497,
            0.013135058805346489,
            0.02340368926525116,
            -0.018234651535749435,
            0.004542442969977856,
            0.028773680329322815,
            0.016016073524951935,
            -0.0021774510387331247,
            -0.0326770544052124,
            -0.02513655088841915,
            -0.011864988133311272,
            -0.006257134024053812,
            0.005732388701289892,
            0.030166547745466232,
            -0.0070603652857244015,
            0.022359685972332954,
            0.046060290187597275,
            -0.03280283510684967,
            0.013269742950797081,
            -0.045175690203905106,
            0.04419158026576042,
            -0.02042553760111332,
            -0.015455693006515503,
            0.02163361757993698,
            0.00010864700743695721,
            0.029949991032481194,
            0.04230998083949089,
            0.049157559871673584,
            -0.026060648262500763,
            -0.02463558502495289,
            0.024928852915763855,
            -0.013988137245178223,
            0.002611620584502816,
            0.02924800105392933,
            -0.015390771441161633,
            -0.006317522376775742,
            -0.015811963006854057,
            0.035330288112163544,
            -0.014379194006323814,
            0.012101649306714535,
            -0.028088444843888283,
            0.02213461697101593,
            -0.02030032128095627,
            0.00937128346413374,
            -0.02599751390516758,
            0.06275157630443573,
            0.020132126286625862,
            0.02167459763586521,
            0.024561939761042595,
            -0.009493633173406124,
            0.006247745826840401,
            -0.007789870258420706,
            -0.01986403949558735,
            0.03871891647577286,
            -0.01284227054566145,
            0.01668447256088257,
            0.013493861071765423,
            0.009192530065774918,
            0.012098577804863453,
            0.020463837310671806,
            0.015064846724271774,
            -0.060643695294857025,
            0.01305485051125288,
            0.0072372364811599255,
            -0.017033932730555534,
            0.05490529164671898,
            -0.0028053130954504013,
            0.002199192764237523,
            0.03517867624759674,
            0.03187311068177223,
            0.02198905497789383,
            0.024547403678297997,
            0.022580141201615334,
            -0.025088990107178688,
            0.020610976964235306,
            0.02477833814918995,
            -0.015289721079170704,
            0.013475676067173481,
            0.029957009479403496,
            -0.03032735548913479,
            0.007415765430778265,
            0.026094194501638412,
            -0.01063972245901823,
            0.0008385150576941669,
            0.017424501478672028,
            -0.022375088185071945,
            -0.009091357700526714,
            -0.018518051132559776,
            -0.021112369373440742,
            0.011481319554150105,
            -0.029354186728596687,
            0.025199754163622856,
            -0.00532641401514411,
            -0.02926350012421608,
            -0.04602061212062836,
            -0.00712436530739069,
            0.013150383718311787,
            -0.024037322029471397,
            0.005826304666697979,
            -0.018620338290929794,
            -0.035967808216810226,
            0.0046932632103562355,
            -0.017129836603999138,
            -0.016471661627292633,
            -0.014449533075094223,
            0.04699025675654411,
            0.0379316620528698,
            0.036372166126966476,
            -0.0063568963669240475,
            -0.004684019833803177,
            0.008427741006016731,
            0.019515158608555794,
            0.013468227349221706,
            -0.008609503507614136,
            -0.010997225530445576,
            0.004698809236288071,
            -0.02741066738963127,
            -0.02870161458849907,
            -0.014513671398162842,
            0.06245477497577667,
            -0.060198720544576645,
            0.028349028900265694,
            0.030812837183475494,
            -0.01059882901608944,
            -0.021713197231292725,
            0.022220773622393608,
            0.01868818700313568,
            -0.005952516105026007,
            -0.008997677825391293,
            0.0034969872795045376,
            0.007396838627755642,
            0.026660721749067307,
            0.02493233047425747,
            0.0039445278234779835,
            0.020831871777772903,
            0.030016092583537102,
            -0.014035687781870365,
            -0.021573886275291443,
            -0.02251463197171688,
            -0.014981321059167385,
            0.008209181018173695,
            -0.012574603781104088,
            0.012165335938334465,
            0.007322933990508318,
            -0.014556409791111946,
            0.003185128327459097,
            0.022189414128661156,
            0.029262546449899673,
            -0.01669612154364586,
            -0.002575668739154935,
            0.030198991298675537,
            0.009876631200313568,
            0.03454503044486046,
            -0.04456925019621849,
            -0.005644966382533312,
            -0.021383728832006454,
            0.010266761295497417,
            0.020183220505714417,
            0.01773502491414547,
            0.04226227477192879,
            0.03000812977552414,
            -0.0015986775979399681,
            0.014047888107597828,
            -0.030047103762626648,
            0.013815635815262794,
            0.017347052693367004,
            -0.04392150044441223,
            -0.003797343233600259,
            0.01111485343426466,
            -0.009143900126218796,
            -0.05194927752017975,
            0.005696714855730534,
            -0.008885092101991177,
            0.01910492405295372,
            -0.01543671265244484,
            0.009298520162701607,
            0.031372640281915665,
            -0.000645376683678478,
            -0.020755702629685402,
            0.024576082825660706,
            0.007041527424007654,
            -0.012233074754476547,
            -0.0238976888358593,
            0.03114062175154686,
            -0.009550775401294231,
            -0.0077706980518996716,
            0.03820550814270973,
            0.005567334126681089,
            -0.014505874365568161,
            0.025774987414479256,
            -0.03735460340976715,
            0.002186129568144679,
            0.02625734731554985,
            0.024111883714795113,
            -0.011499885469675064,
            -0.014426175504922867,
            -0.01250475738197565,
            -0.004536963999271393,
            0.006977668963372707,
            0.006584377493709326,
            -0.014498166739940643,
            0.004219811875373125,
            0.02214490994811058,
            -0.02639431320130825,
            -0.03641558811068535,
            -0.003795107128098607,
            -0.0056868367828428745,
            -0.021735522896051407,
            0.0001094513718271628,
            0.0030937667470425367,
            0.03700949251651764,
            0.0011379009811207652,
            -0.0073505849577486515,
            0.003958818968385458,
            -0.0012361191911622882,
            0.003310097148641944,
            0.008522327058017254,
            -0.0020052320323884487,
            -0.03287895396351814,
            0.003532188246026635,
            0.031083932146430016,
            0.012571901082992554,
            0.0416223518550396,
            0.027845527976751328,
            0.03166050836443901,
            -0.00043924219789914787,
            0.031370583921670914,
            0.027123423293232918,
            -0.005362082738429308,
            -0.010061665438115597,
            0.01946004293859005,
            -0.02762570045888424,
            -0.02036871761083603,
            -0.0016760688740760088,
            -0.004756761249154806,
            0.013458200730383396,
            -0.012942700646817684,
            -0.027406765148043633,
            0.016733549535274506,
            0.037081073969602585,
            -0.007434724364429712,
            -0.02332579344511032,
            0.02669536881148815,
            0.0001899241906357929,
            -0.01845085620880127,
            0.053599704056978226,
            -0.010023191571235657,
            -0.018828263506293297,
            0.05054449662566185,
            0.03399113938212395,
            -0.003394071478396654,
            -0.012777211144566536,
            0.02703617699444294,
            0.02717425674200058,
            0.002660233760252595,
            -0.0028781741857528687,
            -0.00877339206635952,
            -0.03289863467216492,
            -0.020080430433154106,
            -0.020093359053134918,
            0.012409050017595291,
            0.031396664679050446,
            0.0182704608887434,
            -0.04216458275914192,
            -0.008512360975146294,
            -0.0375390350818634,
            0.026318969205021858,
            0.03944274038076401,
            -0.0003113706479780376,
            0.007731333840638399,
            0.01291110273450613,
            -0.03888668492436409,
            -0.03023120015859604,
            -0.01583881303668022,
            -0.04169279709458351,
            -0.007393973413854837,
            -0.03131800889968872,
            -0.003376663662493229,
            0.034079939126968384,
            0.013813202269375324,
            0.026293335482478142,
            0.01708361692726612,
            -9.278429934056476e-05,
            0.005137224216014147,
            0.028282256796956062,
            0.024207592010498047,
            -0.008322114124894142,
            0.021077649667859077,
            0.0753256306052208,
            0.007814887911081314,
            -0.02009822241961956,
            -0.00031150507857091725,
            -0.008644907735288143,
            -0.031754326075315475,
            0.027067162096500397,
            4.05131395382341e-05,
            -0.009451182559132576,
            -0.024732302874326706,
            -0.016625624150037766,
            -0.002592814387753606,
            0.026646876707673073,
            0.053063441067934036,
            0.016209125518798828,
            -0.013765545561909676,
            -0.012853886000812054,
            -0.040068283677101135,
            -0.023772425949573517,
            -0.020878629758954048,
            -0.003935284446924925,
            -0.016408005729317665,
            -0.013200921937823296,
            -0.049489833414554596,
            0.010456855408847332,
            -0.0037166106048971415,
            -0.011545615270733833,
            -0.02261550910770893,
            -0.058351144194602966,
            -0.00717595499008894,
            0.0022497856989502907,
            0.0275160800665617,
            0.01238520722836256,
            0.03670778498053551,
            -0.03320245072245598,
            -0.03926326334476471,
            0.000563328096177429,
            -0.0024190745316445827,
            0.038084086030721664,
            -0.003157365834340453,
            0.019184555858373642,
            -0.016048461198806763,
            -0.005378031637519598,
            0.007312214467674494,
            0.004142754711210728,
            0.01526846643537283,
            0.06280931830406189,
            0.03523990884423256,
            -0.01298826839774847,
            0.016613585874438286,
            0.002455875976011157,
            0.002518052002415061,
            -0.03151927888393402,
            -0.007009255699813366,
            -0.028633536770939827,
            0.022203728556632996,
            0.0017529819160699844,
            0.019748061895370483,
            0.021596165373921394,
            0.0015812501078471541,
            -0.005019660573452711,
            0.0026393600273877382,
            -0.019075216725468636,
            0.002918771468102932,
            0.0074128215201199055,
            -0.021557318046689034,
            0.040008097887039185,
            0.03545219078660011,
            0.009990387596189976,
            -0.05369080975651741,
            -0.027445632964372635,
            0.029627066105604172,
            0.00903692003339529,
            0.02480967715382576,
            -0.01828867569565773,
            0.010628834366798401,
            -0.018349995836615562,
            -0.026667777448892593,
            -0.0014417601050809026,
            0.0010367344366386533,
            -0.011205360293388367,
            -0.05102217197418213,
            0.0025187251158058643,
            0.0060946205630898476,
            -0.020265333354473114,
            -0.014500182121992111,
            0.021568359807133675,
            -0.017148079350590706,
            0.004786145873367786,
            0.011006731539964676,
            -0.08081939816474915,
            -0.017446395009756088,
            -0.023976309224963188,
            0.009797351434826851,
            -0.053171493113040924,
            -0.019887901842594147,
            0.006656600162386894,
            -0.03477095440030098,
            -0.03309217840433121,
            0.01803678087890148,
            -0.0033542478922754526,
            0.018926315009593964,
            0.012658714316785336,
            -0.027384156361222267,
            -0.03448517993092537,
            0.0040808492340147495,
            0.017219819128513336,
            0.032954588532447815,
            -0.005680792964994907,
            0.04797724261879921,
            0.021693609654903412,
            0.01764664798974991,
            0.023996155709028244,
            -0.00727877439931035,
            -0.0014448375441133976,
            -0.031663231551647186,
            -0.0009068538784049451,
            -0.014165074564516544,
            -0.019744042307138443,
            0.005781915970146656,
            -0.012270363979041576,
            -0.008824103511869907,
            -0.0003019498544745147,
            0.002436212031170726,
            0.03314469754695892,
            -0.016008198261260986,
            0.008014180697500706,
            0.007108049001544714,
            -0.03189707174897194,
            0.005576171912252903,
            0.008031636476516724,
            -0.04551650583744049,
            0.008306387811899185,
            0.025172211229801178,
            -0.004321481566876173,
            -0.025175131857395172,
            -0.01933315210044384,
            0.04036412015557289,
            -0.0072779362089931965,
            -0.04758978635072708,
            0.0001605489378562197,
            0.013060473836958408,
            -0.025513527914881706,
            0.01889931783080101,
            0.03374648839235306,
            -0.013795999810099602,
            0.032199036329984665,
            -0.024190332740545273,
            -0.01814105361700058,
            0.014929922297596931,
            0.016606353223323822,
            -0.013491296209394932,
            0.00708827143535018,
            -0.01975836418569088,
            -0.030712634325027466,
            -0.032085858285427094,
            0.018475031480193138,
            0.01105769257992506,
            0.00866369716823101,
            0.024644410237669945,
            -0.013467186130583286,
            -0.04143356531858444,
            0.002319870050996542,
            0.014550588093698025,
            -0.015208872966468334,
            0.029890745878219604,
            -0.010587891563773155,
            -0.02565823495388031,
            -0.004783138167113066,
            0.014991740696132183,
            0.03010084293782711,
            0.03878248855471611,
            0.016139304265379906,
            0.027142878621816635,
            -0.0011732832062989473,
            -0.015175245702266693,
            -0.012253771536052227,
            -0.0005340561037883162,
            -0.02091744728386402,
            0.006428049877285957,
            0.035207487642765045,
            -0.04132821038365364,
            0.021097028627991676,
            0.029511811211705208,
            0.013156333938241005,
            0.017290471121668816,
            -0.0017682339530438185,
            0.017730699852108955,
            0.010043232701718807,
            -0.017516490072011948,
            -0.008975339122116566,
            -0.00476917764171958,
            0.010222812183201313,
            -0.023032281547784805,
            0.013758488930761814,
            0.0010221853153780103,
            -0.04448695108294487,
            0.030553976073861122,
            -0.012225945480167866,
            -0.05617667734622955,
            -0.014770930632948875,
            0.02984854392707348,
            -0.026258429512381554,
            0.012506301514804363,
            0.014583132229745388,
            0.013017973862588406,
            0.010988623835146427,
            0.022936034947633743,
            -0.04970340430736542,
            0.012273739092051983,
            0.034025926142930984,
            0.010732579976320267,
            0.024784350767731667,
            0.014016855508089066,
            0.027255209162831306,
            0.0028934678994119167,
            -0.013725534081459045,
            0.021264001727104187,
            0.016903892159461975,
            -0.005827315617352724,
            0.03781095892190933,
            0.02189389429986477,
            -0.05606674402952194,
            0.048078544437885284,
            0.013702777214348316,
            0.03045363910496235,
            0.04210575297474861,
            0.034168541431427,
            -0.022012531757354736,
            -0.021715568378567696,
            -0.024374917149543762,
            0.03429046645760536,
            -0.010115602985024452,
            -0.004578630439937115,
            0.026336513459682465,
            0.004415126051753759,
            0.019643081352114677,
            0.022956181317567825,
            0.0280813779681921,
            0.002457211958244443,
            -0.017047099769115448,
            -0.0008253280539065599,
            -0.04551001638174057,
            -0.023965971544384956,
            -0.02592354640364647,
            -0.01912897452712059,
            0.027260735630989075,
            0.03644208610057831,
            -0.028772616758942604,
            -0.002307494403794408,
            -0.00300095253624022,
            -0.014197593554854393,
            -0.0075309742242097855,
            0.029387975111603737,
            -0.0010658088140189648,
            -0.042962584644556046,
            0.023553624749183655,
            -0.02996515855193138,
            -0.013185588642954826,
            -0.020605798810720444,
            0.007396739441901445,
            -0.023646516725420952,
            0.0266802366822958,
            -0.0272731501609087,
            0.01648757793009281,
            -0.0039035433437675238,
            0.015836099162697792,
            0.031821638345718384,
            -0.018556898459792137,
            -0.004088948480784893,
            -0.004109885543584824,
            0.015700196847319603,
            0.027859456837177277,
            -0.011989613063633442,
            0.024321507662534714,
            -0.040218789130449295,
            -0.026901639997959137,
            0.022462252527475357,
            -0.00870486255735159,
            0.020090123638510704,
            0.029607968404889107,
            0.018042389303445816,
            -0.028767945244908333,
            -0.0369575098156929,
            -0.014818119816482067,
            0.026555996388196945,
            -0.022081701084971428,
            0.020762456580996513,
            -0.010418618097901344,
            -0.0033398305531591177,
            -0.029681120067834854,
            0.005229434929788113,
            0.059092625975608826,
            -0.029989350587129593,
            0.0126541992649436,
            0.015373346395790577,
            -0.08129125088453293,
            -0.04019985720515251,
            -0.015020583756268024,
            -0.022647801786661148,
            -0.0035644853487610817,
            0.04725436121225357,
            0.03563820198178291,
            0.02544455975294113,
            0.021481962874531746,
            -0.052220266312360764,
            -0.020222337916493416,
            0.014989898540079594,
            -0.010601326823234558,
            -0.034890640527009964,
            -0.04341457784175873,
            0.02999197132885456,
            0.02387136034667492,
            0.04658457264304161,
            0.017624884843826294,
            0.023963160812854767,
            0.027263730764389038,
            -0.009704092517495155,
            -0.007975025102496147,
            0.029179802164435387,
            0.013353272341191769,
            0.02655281126499176,
            0.022768745198845863,
            0.00872090458869934,
            0.021592434495687485,
            -0.024547474458813667,
            -0.014832738786935806,
            -0.009211353026330471,
            -0.02532810904085636,
            -0.02977277897298336,
            -0.010697486810386181,
            -0.031784456223249435,
            -0.0008593238308094442,
            -0.01580037921667099,
            -0.018724072724580765,
            -0.009584601037204266,
            0.02935463935136795,
            0.004151410888880491,
            -0.017217392101883888,
            -0.0029421381186693907,
            0.01052528154104948,
            0.028736626729369164,
            0.014878852292895317,
            0.0194720271974802,
            -0.01706583797931671,
            0.008340765722095966,
            -0.017370862886309624,
            -0.01870710216462612,
            0.025684893131256104,
            0.01598135568201542,
            -0.009714488871395588,
            0.018066836521029472,
            0.022568540647625923,
            -0.02613849751651287,
            -0.0016828845255076885,
            -0.0306697990745306,
            -0.03480781614780426,
            0.004358296282589436,
            -0.017286796122789383,
            -0.030706901103258133,
            -0.019194455817341805,
            0.02689407765865326,
            -0.01297244243323803,
            0.03714996203780174,
            -0.008161175064742565,
            0.008063708432018757,
            0.014026133343577385,
            0.036958638578653336,
            0.00020829196728300303,
            -0.03182914853096008,
            -0.016650600358843803,
            -0.03132378309965134,
            0.011199498549103737,
            -0.0017111962661147118,
            0.003858122043311596,
            0.001954370178282261,
            -0.017253445461392403,
            0.024866463616490364,
            -0.017373964190483093,
            0.005716269835829735,
            -0.013547617010772228,
            0.04064938426017761,
            -0.0026968042366206646,
            0.0312928780913353,
            0.011170171201229095,
            -0.03598460182547569,
            -0.03755795955657959,
            -0.04750537872314453,
            -0.034960679709911346,
            0.007907042279839516,
            -0.02898668311536312,
            -0.004799433518201113,
            0.014501821249723434,
            -0.030237974599003792,
            -3.3142649044748396e-05,
            -0.012724247761070728,
            0.0012049609795212746,
            -0.03790910914540291,
            -0.0722191333770752,
            0.027346577495336533,
            0.014039465226233006,
            -0.0028463939670473337,
            0.04669296741485596,
            -0.04691368713974953,
            0.011160444468259811,
            0.008520741946995258,
            0.0860045999288559,
            -0.06444109976291656,
            0.006803079973906279,
            -0.000934138719458133,
            -0.0028665228746831417,
            0.011903657577931881,
            -0.0694291964173317,
            0.016874676570296288,
            0.014940123073756695,
            0.017277318984270096,
            0.020586268976330757,
            0.03046378865838051,
            -0.028812048956751823,
            0.031225256621837616,
            0.008365670219063759,
            -0.012282425537705421,
            -0.031655021011829376,
            -0.007059422787278891,
            0.0328417643904686,
            0.041662272065877914,
            -0.022165507078170776,
            -0.022719666361808777,
            0.0016540149226784706,
            -0.021069929003715515,
            -0.04215439409017563,
            0.03239908441901207,
            0.023519078269600868,
            0.015484091825783253,
            0.017650552093982697,
            0.019852956756949425,
            0.0034815205726772547,
            -0.0036340556107461452,
            0.005117833614349365,
            0.01272508129477501,
            -0.026379413902759552,
            -0.006555262487381697,
            -0.0043167537078261375,
            0.005801666993647814,
            0.016302034258842468,
            -0.02064548432826996,
            -0.009960047900676727,
            0.05398432910442352,
            -0.008966755121946335,
            0.004753986839205027,
            0.014970097690820694,
            -0.0004900770727545023,
            0.03434426337480545,
            0.049852196127176285,
            0.0281659085303545,
            -0.03280923515558243,
            0.022601131349802017,
            0.00037525923107750714,
            -0.015871794894337654,
            -0.016685912385582924,
            -0.026697790250182152,
            0.003952503204345703,
            0.009993604384362698,
            -0.012987951748073101,
            0.01658523455262184,
            -0.025755755603313446,
            0.03373107686638832,
            0.04815603792667389,
            -0.007092632818967104,
            0.05608674883842468,
            -0.04090465232729912,
            -0.012852078303694725,
            -0.027697328478097916,
            -0.02661783993244171,
            -0.007550947368144989,
            0.002050207694992423,
            -0.04999452829360962,
            -0.009693722240626812,
            0.023255908861756325,
            -0.023669973015785217,
            0.038151200860738754,
            -0.018084045499563217,
            0.002736285561695695,
            -0.0024581977631896734,
            -0.019943974912166595,
            -0.027179421856999397,
            -0.04184030741453171,
            0.04493353143334389,
            -0.008810876868665218,
            0.010547565296292305,
            0.036078665405511856,
            -0.010037768632173538,
            0.025038110092282295,
            0.041651710867881775,
            0.03567786142230034,
            0.025985045358538628,
            -0.010472855530679226,
            -0.0458386205136776,
            -0.05919918790459633,
            -0.0007894905284047127,
            -0.0026815624441951513,
            0.01845431886613369,
            0.009726554155349731,
            0.047360651195049286,
            -0.01953280344605446,
            0.047681089490652084,
            -0.010593211278319359,
            0.029054071754217148,
            0.005951049271970987,
            0.020413583144545555,
            0.012881956994533539,
            0.019327465444803238,
            0.010057790204882622,
            0.03638535737991333,
            0.017965734004974365,
            -0.025843797251582146,
            0.027261175215244293,
            -0.008405319415032864,
            0.013396541588008404,
            0.013368075713515282,
            -0.06620766222476959,
            0.012589667923748493,
            0.03511076420545578,
            0.01245451346039772,
            -0.011868310160934925,
            0.016997143626213074,
            -0.03943841904401779,
            -0.025454744696617126,
            0.012002608738839626,
            0.03558201342821121,
            0.014889822341501713,
            -0.02366076596081257,
            -0.05152639374136925,
            -0.008713033981621265,
            -0.0014520359691232443,
            0.023527005687355995,
            0.01846753992140293,
            -0.010514413006603718,
            -0.014525546692311764,
            0.0023743549827486277,
            0.028537435457110405,
            -0.03369893133640289,
            -0.027553031221032143,
            0.01682986132800579,
            -0.0051746610552072525,
            0.017773285508155823,
            0.037877846509218216,
            0.017684655264019966,
            0.019902963191270828,
            -0.0004502811352722347,
            0.007017137017101049,
            -0.006860238499939442,
            0.016606222838163376,
            -0.01840929128229618,
            0.0029688861686736345,
            0.006100244354456663,
            0.0471886582672596,
            0.01328879501670599,
            -0.0011438318761065602,
            -0.021456651389598846,
            0.03517014533281326,
            -0.0069788983091712,
            -0.015115105547010899,
            -0.0022697607055306435,
            -0.006860405672341585,
            0.034965623170137405,
            -0.01941095106303692,
            -0.0038119982928037643,
            0.05730177462100983,
            0.004825185984373093,
            0.0468282550573349,
            0.0025009678211063147,
            0.028399579226970673,
            -0.03094656951725483,
            0.006224188953638077,
            -0.01263993326574564,
            0.009841879829764366,
            -0.02282765507698059,
            0.003679428016766906,
            0.0004112037131562829,
            -0.01109482254832983,
            -0.0207164715975523,
            0.03113345429301262,
            0.018876805901527405,
            -0.015835262835025787,
            -0.0030711914878338575,
            -0.009598514065146446,
            0.01739347353577614,
            0.03443855419754982,
            0.00943545252084732,
            0.020851077511906624,
            -0.04649179056286812,
            0.028560729697346687,
            0.005167821887880564,
            0.025662852451205254,
            0.030386026948690414,
            -0.030627496540546417,
            -0.002793513238430023,
            0.006575255189090967,
            0.02762852981686592,
            -0.02425360679626465,
            0.009691169485449791,
            0.03447144851088524,
            0.03440010920166969,
            -0.018378062173724174,
            -0.004388198722153902,
            -0.02483680285513401,
            0.0023627642076462507,
            0.0030922377482056618,
            -0.023749500513076782,
            0.010449930094182491,
            -0.01657627336680889,
            -0.019158851355314255,
            -0.03719915449619293,
            0.024352503940463066,
            -0.03111446090042591,
            -0.00442898366600275,
            0.03997691348195076,
            -0.026302115991711617,
            -0.05252351239323616,
            0.014783159829676151,
            -0.05211963132023811,
            0.005560795310884714,
            -0.016021916642785072,
            0.014274802058935165,
            -0.01191174890846014,
            0.017869289964437485,
            -0.022603871300816536,
            -0.028141310438513756,
            -0.017032671719789505
        ],
        [
            -0.021455401554703712,
            -0.023145759478211403,
            -0.01243410911411047,
            0.01383251417428255,
            0.023359693586826324,
            -0.03747200220823288,
            -0.010469946078956127,
            -0.049259915947914124,
            -0.017173664644360542,
            0.0034686652943491936,
            0.007500858511775732,
            0.023695925250649452,
            -0.033900391310453415,
            0.018220307305455208,
            0.00602776650339365,
            0.012693831697106361,
            -0.0032725268974900246,
            0.02306913025677204,
            0.0010533611057326198,
            0.026183750480413437,
            -0.07103169709444046,
            -0.020323436707258224,
            0.0018948018550872803,
            0.006629956420511007,
            -0.007596794981509447,
            -0.0045186420902609825,
            -0.027753617614507675,
            0.017049817368388176,
            -0.006048821844160557,
            -0.002983253914862871,
            0.02552398480474949,
            -0.013598260469734669,
            -0.01720668561756611,
            0.00018193187133874744,
            0.009170408360660076,
            0.027419498190283775,
            -0.012089310213923454,
            -0.008632739074528217,
            -0.02803201787173748,
            0.028490370139479637,
            -0.036397889256477356,
            -0.013507039286196232,
            0.010212291032075882,
            -0.023230617865920067,
            -0.013356264680624008,
            -0.01768878474831581,
            -0.020286742597818375,
            -0.010470845736563206,
            -0.026494501158595085,
            -0.03704848513007164,
            -0.0024441361892968416,
            0.015286749228835106,
            -0.03308895602822304,
            -0.04298536852002144,
            0.02106197364628315,
            0.008774385787546635,
            -0.03746955841779709,
            -0.0039376309141516685,
            -0.027238519862294197,
            -0.010487155988812447,
            0.03155676648020744,
            0.026975959539413452,
            -0.031430523842573166,
            0.02390756830573082,
            -0.022010527551174164,
            0.003190058981999755,
            -0.002818529959768057,
            0.0022402482572942972,
            -0.014887038618326187,
            -0.004756905138492584,
            0.003439659718424082,
            0.012875732034444809,
            -0.013295208103954792,
            0.024176334962248802,
            -0.04124461114406586,
            0.0014031734317541122,
            0.03379763290286064,
            -0.032113492488861084,
            0.011166144162416458,
            -0.009824132546782494,
            -0.0010857362067326903,
            0.003031106200069189,
            0.0022658815141767263,
            0.004265804309397936,
            0.00038820566260255873,
            0.018184136599302292,
            -0.031432315707206726,
            0.00982581451535225,
            -0.0031599467620253563,
            -0.06153585761785507,
            0.004761685151606798,
            0.018515171483159065,
            -0.020309200510382652,
            0.031139083206653595,
            0.008769060485064983,
            -0.020833948627114296,
            -0.0106128491461277,
            0.018503697589039803,
            -0.06259377300739288,
            -0.008611716330051422,
            -0.01958618126809597,
            -0.023094862699508667,
            -0.013029498979449272,
            -0.023903360590338707,
            -0.016602223739027977,
            0.02433917298913002,
            0.03833533450961113,
            0.022433269768953323,
            -0.05644123628735542,
            0.029134830459952354,
            0.012206828221678734,
            -0.015009960159659386,
            0.014851920306682587,
            0.01579596847295761,
            -0.03295549750328064,
            -0.0037440191954374313,
            0.01008476223796606,
            0.015924867242574692,
            0.028787042945623398,
            0.031038682907819748,
            0.016064781695604324,
            -0.024250291287899017,
            -0.012253021821379662,
            0.006003160495311022,
            0.008960079401731491,
            0.0030603257473558187,
            -0.016496140509843826,
            -0.002774534747004509,
            -0.013665218837559223,
            -0.02668684907257557,
            0.0019859743770211935,
            0.02229282259941101,
            0.051759980618953705,
            -0.046652667224407196,
            -0.023386947810649872,
            -0.02926037833094597,
            0.002954872092232108,
            -0.028687141835689545,
            0.02361309714615345,
            -0.01617678999900818,
            0.03430204093456268,
            0.03150125592947006,
            -0.01027035340666771,
            0.01501404494047165,
            -0.0706707239151001,
            -0.006704936735332012,
            0.024210184812545776,
            0.023992354050278664,
            -0.011973446235060692,
            0.012390363961458206,
            -0.0008133443188853562,
            0.03286160156130791,
            0.030414411798119545,
            0.03262218087911606,
            -0.020999547094106674,
            -0.02376069687306881,
            -0.01598311774432659,
            0.029351500794291496,
            -0.031217996031045914,
            -0.03529742732644081,
            -0.008105460554361343,
            -0.011963192373514175,
            0.013496720232069492,
            -0.012697230093181133,
            0.010916654951870441,
            0.027182983234524727,
            0.020469220355153084,
            -0.002034656936302781,
            -0.04741281270980835,
            -0.0003110856923740357,
            0.041723597794771194,
            0.028408918529748917,
            -0.031592924147844315,
            0.0004494949243962765,
            -0.030190182849764824,
            -0.0019255154766142368,
            -0.05573287978768349,
            0.020238609984517097,
            0.01495451107621193,
            -0.02129112184047699,
            0.021797919645905495,
            -0.009309269487857819,
            -0.0057832314632833,
            0.026443565264344215,
            0.017213841900229454,
            0.030673226341605186,
            0.004693138413131237,
            -0.013706187717616558,
            0.005525818560272455,
            0.035738710314035416,
            -0.0014272466069087386,
            0.010693434625864029,
            -0.04192407801747322,
            -0.044774334877729416,
            -0.018015265464782715,
            0.03953031077980995,
            -0.005952739156782627,
            -0.006890862714499235,
            -0.013590948656201363,
            0.01541121955960989,
            0.006677801255136728,
            0.0017584596062079072,
            0.023176206275820732,
            0.012716416269540787,
            0.006633908953517675,
            -0.0100546358153224,
            0.013276620768010616,
            0.043725788593292236,
            -0.03143376484513283,
            0.0053723775781691074,
            -0.026233650743961334,
            0.02802206389605999,
            0.0022143919486552477,
            -0.012638467364013195,
            0.018125198781490326,
            0.008962866850197315,
            -0.003603015560656786,
            -0.007421108894050121,
            0.02048962377011776,
            0.03723043203353882,
            0.03012373298406601,
            -0.0034152043517678976,
            -0.029426580294966698,
            -0.008630191907286644,
            0.01880662515759468,
            -0.02257455140352249,
            0.03365536406636238,
            -0.0080752894282341,
            -0.02051536925137043,
            -0.0031689952593296766,
            -0.0048109302297234535,
            0.010328135453164577,
            -0.016243064776062965,
            0.0027479876298457384,
            -0.04297555238008499,
            0.02814985252916813,
            0.007868445478379726,
            0.00932255294173956,
            0.014110013842582703,
            -0.01885836385190487,
            -0.01960930973291397,
            -0.02959112450480461,
            0.02800930105149746,
            0.01085981260985136,
            0.001810399815440178,
            -0.007660965900868177,
            -0.01118053775280714,
            0.0015929067740216851,
            0.02477089688181877,
            0.035282496362924576,
            -0.004420571029186249,
            -0.0028830927331000566,
            -0.028596967458724976,
            0.01692916452884674,
            -0.01757149025797844,
            -0.003606530837714672,
            -0.00044222804717719555,
            -0.015717465430498123,
            0.028192078694701195,
            0.0008399367216043174,
            -0.021426811814308167,
            -0.019151004031300545,
            -0.04355800151824951,
            -0.018919887021183968,
            0.02669263631105423,
            0.0005890610045753419,
            0.00013595420750789344,
            -0.009490109980106354,
            0.01969071477651596,
            0.024732137098908424,
            -0.026003658771514893,
            0.03124467097222805,
            -0.006204577628523111,
            0.022576384246349335,
            0.0190148763358593,
            0.008053353987634182,
            0.03802323341369629,
            -0.027270754799246788,
            -0.0030017730314284563,
            -0.009524455294013023,
            0.037631623446941376,
            0.0014109661569818854,
            0.030494090169668198,
            -0.018943963572382927,
            -0.01261917781084776,
            -0.02859746664762497,
            0.02767077274620533,
            0.010350897908210754,
            0.0077226851135492325,
            0.01685604639351368,
            -0.024237850680947304,
            0.03932427242398262,
            0.005471613723784685,
            0.016740158200263977,
            0.01340111531317234,
            -0.015655048191547394,
            -0.01922548934817314,
            -0.021449493244290352,
            -0.038849249482154846,
            0.015395401045680046,
            -0.01186436228454113,
            0.033762913197278976,
            0.012371002696454525,
            -0.0016475056763738394,
            0.031121261417865753,
            0.041938263922929764,
            0.02667136676609516,
            -0.02295134961605072,
            0.013472947292029858,
            -0.016016850247979164,
            -0.04957929626107216,
            0.003464007517322898,
            0.015577137470245361,
            0.026416681706905365,
            0.006898163352161646,
            0.012096845544874668,
            -0.010968713089823723,
            0.01767762191593647,
            -0.006587116979062557,
            0.0028719177935272455,
            0.035765863955020905,
            0.02945942059159279,
            0.016831906512379646,
            -0.0258355513215065,
            -0.03319096937775612,
            -0.020567873492836952,
            0.02094515971839428,
            -0.015256728045642376,
            -7.973892206791788e-05,
            -0.024189118295907974,
            -0.05595175176858902,
            -0.03614139184355736,
            -0.002208247547969222,
            -0.03181937709450722,
            -0.010308259166777134,
            0.01032178197056055,
            0.02240055240690708,
            0.0016235057264566422,
            0.02794228494167328,
            -0.017084157094359398,
            0.0020441741216927767,
            -0.011933092027902603,
            -0.004773144610226154,
            -0.02021738514304161,
            -0.028327763080596924,
            0.018199030309915543,
            -0.03011712245643139,
            -0.019177455455064774,
            -0.014237906783819199,
            -0.06556355953216553,
            0.008050492033362389,
            -0.026366453617811203,
            -0.003842019010335207,
            0.011277442798018456,
            0.009832634590566158,
            -0.008086265064775944,
            -0.0067434245720505714,
            0.023345964029431343,
            -0.016784651204943657,
            -0.01287739910185337,
            0.03073805570602417,
            -0.011639922857284546,
            -0.04829968139529228,
            -0.03017944097518921,
            0.03934817761182785,
            -0.020785002037882805,
            -0.01889626495540142,
            0.019100381061434746,
            0.016615504398941994,
            0.03713317960500717,
            0.03239642083644867,
            -0.03560036048293114,
            0.005445921327918768,
            -0.02924804575741291,
            -0.027265120297670364,
            0.01900329627096653,
            -0.02234448306262493,
            -0.04351898282766342,
            -0.018921006470918655,
            0.016356082633137703,
            0.004294726997613907,
            -0.023627985268831253,
            0.02015262469649315,
            -0.007354671601206064,
            -0.02048289030790329,
            -0.03070339746773243,
            0.027192721143364906,
            -0.01461261510848999,
            0.0017922220285981894,
            -0.03773801401257515,
            -0.007611346431076527,
            -0.0409839004278183,
            0.037510890513658524,
            -0.06179004907608032,
            0.013598869554698467,
            -0.006985948886722326,
            -0.007829148322343826,
            0.0014161898288875818,
            -0.005766857415437698,
            0.02961159497499466,
            -0.01873268373310566,
            0.0310763381421566,
            0.018856653943657875,
            -0.006485668942332268,
            -0.015803871676325798,
            -0.03150350973010063,
            0.017520811408758163,
            -0.012413650751113892,
            -0.014916365966200829,
            -0.02533736452460289,
            0.03436574712395668,
            0.004355048295110464,
            -0.01344648003578186,
            -0.029892394319176674,
            -0.0012904776958748698,
            -0.015582522377371788,
            0.005891486536711454,
            0.05342656746506691,
            0.013885600492358208,
            -0.011662783101201057,
            -0.014447101391851902,
            0.036246996372938156,
            -0.03402789682149887,
            0.024026498198509216,
            0.036645546555519104,
            0.00981856882572174,
            -0.020866312086582184,
            0.004674653522670269,
            -0.003206721507012844,
            0.018894363194704056,
            0.017530959099531174,
            0.021661171689629555,
            -0.002648185007274151,
            -0.007872712798416615,
            -0.007790934294462204,
            -0.03413277119398117,
            0.029906736686825752,
            0.019537722691893578,
            -0.025737285614013672,
            0.02447514422237873,
            0.013976183719933033,
            0.004611351061612368,
            -0.026636600494384766,
            0.006314194295555353,
            -0.015714718028903008,
            -0.014277968555688858,
            0.02156405709683895,
            -0.04986754432320595,
            0.007422733586281538,
            -0.013177395798265934,
            -0.01902701146900654,
            -0.016821125522255898,
            -0.028083041310310364,
            0.01969495601952076,
            0.010309663601219654,
            0.0013827966758981347,
            0.030358459800481796,
            -0.011225929483771324,
            -0.03625309839844704,
            0.017955394461750984,
            0.0077442810870707035,
            0.000906881527043879,
            0.020339909940958023,
            0.013750151731073856,
            -0.0008057812228798866,
            -0.008990163914859295,
            0.008580900728702545,
            0.008771155029535294,
            0.016052071005105972,
            -0.0036830375902354717,
            -0.027383917942643166,
            0.02573125623166561,
            -0.01949966698884964,
            0.007390290033072233,
            -0.010344812646508217,
            -0.0014741566265001893,
            -0.012003649026155472,
            0.012442936189472675,
            0.03037930093705654,
            -0.028317246586084366,
            -0.012353632599115372,
            -0.010747464373707771,
            0.004424602724611759,
            0.00231136754155159,
            0.027883712202310562,
            0.017567986622452736,
            0.024832699447870255,
            -0.021165883168578148,
            -0.05093450844287872,
            0.03527316823601723,
            -0.0040901959873735905,
            0.0008399614598602057,
            0.014573030173778534,
            0.028770845383405685,
            -0.04207802191376686,
            0.015885166823863983,
            0.028444673866033554,
            0.010208253748714924,
            0.011361082084476948,
            -0.005841290112584829,
            -0.004814282525330782,
            -0.008620288223028183,
            -0.018496081233024597,
            0.002774727763608098,
            -0.032584547996520996,
            0.02750467322766781,
            -0.018941838294267654,
            -0.0008641360327601433,
            0.011470870114862919,
            -0.008312053047120571,
            -0.0056770737282931805,
            -0.025724783539772034,
            0.013439753092825413,
            -0.028334179893136024,
            -0.025228872895240784,
            -0.024119513109326363,
            -0.021185871213674545,
            0.009551169350743294,
            -0.032370246946811676,
            -0.036842431873083115,
            0.02426942251622677,
            -0.018808146938681602,
            -0.014375949278473854,
            0.01733376830816269,
            -0.04535354673862457,
            -0.021526331081986427,
            -0.0017792856087908149,
            0.03467312455177307,
            -0.014291942119598389,
            -0.02315104380249977,
            -0.007937274873256683,
            0.001628009951673448,
            0.005471222568303347,
            -0.01918528974056244,
            -0.01666884310543537,
            0.015401344746351242,
            0.014732024632394314,
            0.00022255844669416547,
            -0.054732680320739746,
            -0.011149306781589985,
            0.006085643544793129,
            -0.013095180504024029,
            0.038500916212797165,
            -0.02928752638399601,
            -0.006739743519574404,
            0.04527627304196358,
            0.005704293958842754,
            0.007721857167780399,
            0.012285342440009117,
            0.03014872968196869,
            0.034887537360191345,
            -0.0023646585177630186,
            0.025662600994110107,
            0.03811635449528694,
            0.03682965412735939,
            -0.006904099602252245,
            -0.021883931010961533,
            0.030435150489211082,
            0.009196624159812927,
            0.03571247681975365,
            -0.0007069036364555359,
            0.024107787758111954,
            0.03286091238260269,
            -0.006957836914807558,
            -0.01677771657705307,
            0.03873085975646973,
            -0.0028069482650607824,
            0.001560448668897152,
            0.018680036067962646,
            -0.01362313237041235,
            0.007069070357829332,
            0.006888185162097216,
            0.012621669098734856,
            0.024426430463790894,
            0.03177850320935249,
            0.025972500443458557,
            -0.017170503735542297,
            0.027728885412216187,
            0.0025364377070218325,
            0.010398544371128082,
            -0.03152399882674217,
            0.04173808917403221,
            0.011026863008737564,
            0.0034080862533301115,
            0.018805215135216713,
            0.014542341232299805,
            0.006249808240681887,
            0.028839319944381714,
            0.0345662496984005,
            -0.014781968668103218,
            0.02853957936167717,
            -0.03000033274292946,
            0.029868535697460175,
            -0.010036500170826912,
            0.018606925383210182,
            -0.021563762798905373,
            -0.024772224947810173,
            0.018061352893710136,
            -0.03921326994895935,
            0.006652054376900196,
            -0.048536356538534164,
            -0.02780333161354065,
            0.02867817133665085,
            0.02478889748454094,
            0.03205250948667526,
            -0.006991553120315075,
            0.02749040350317955,
            0.015112431719899178,
            0.038083091378211975,
            0.006951724644750357,
            0.022514715790748596,
            0.015600423328578472,
            -0.01354686077684164,
            -0.008363783359527588,
            0.014911632984876633,
            -0.0027479741256684065,
            0.0435388907790184,
            0.006855130195617676,
            -0.015549757517874241,
            -0.032630614936351776,
            0.029852570965886116,
            -0.008917106315493584,
            -0.013121284544467926,
            0.03478369861841202,
            -0.04155426472425461,
            0.03010549396276474,
            -0.01725160703063011,
            0.002773803425952792,
            -0.020858731120824814,
            -0.017903171479701996,
            -0.021297216415405273,
            -0.012582918629050255,
            -0.029319684952497482,
            0.006011472549289465,
            -0.0038349481765180826,
            -0.02356075868010521,
            0.02894672565162182,
            -0.05747207999229431,
            -0.012617425061762333,
            0.017598025500774384,
            0.009626303799450397,
            -0.0030586912762373686,
            0.013654598034918308,
            -0.013980231247842312,
            -0.015691453590989113,
            -0.01818281225860119,
            -0.015461906790733337,
            0.010295407846570015,
            0.00753174489364028,
            0.023018324747681618,
            -0.022378673776984215,
            -0.01927666738629341,
            0.013918183743953705,
            0.018432356417179108,
            -0.021022148430347443,
            -0.023591451346874237,
            0.013863394036889076,
            0.017534034326672554,
            -0.02351541817188263,
            0.015356456860899925,
            0.020826127380132675,
            0.008021778427064419,
            0.034123823046684265,
            -0.010181166231632233,
            0.0013706819154322147,
            -0.02325076423585415,
            0.012535388581454754,
            0.03888300061225891,
            -0.025395279750227928,
            -0.029041707515716553,
            -0.040549326688051224,
            -0.0018726170528680086,
            -0.01767435297369957,
            0.011148630641400814,
            0.03364438936114311,
            -0.010384638793766499,
            0.013576097786426544,
            -0.02537747286260128,
            -0.02976970188319683,
            0.03065516985952854,
            -0.035320837050676346,
            0.023696452379226685,
            0.030564578250050545,
            -0.017826750874519348,
            -0.009049665182828903,
            0.0005231379182077944,
            0.0252655278891325,
            -0.01452996488660574,
            0.002129766857251525,
            0.0158070120960474,
            -0.013710868544876575,
            0.026469722390174866,
            0.023351550102233887,
            0.020483676344156265,
            0.0029749067034572363,
            -0.03536525368690491,
            -0.017794514074921608,
            -0.014926258474588394,
            -0.011622489430010319,
            -0.023314109072089195,
            0.025921937078237534,
            -0.003106220392510295,
            -0.010301304049789906,
            -0.013131891377270222,
            -0.018709009513258934,
            0.024440815672278404,
            0.018789375200867653,
            -0.011703851632773876,
            -0.01986808516085148,
            0.015068139880895615,
            0.0028025449719280005,
            -0.009599556215107441,
            0.01603291742503643,
            -0.01372403372079134,
            -0.001963528571650386,
            -0.007138226181268692,
            0.052357595413923264,
            -0.013805374503135681,
            0.02278166264295578,
            -0.0077256993390619755,
            0.008850893005728722,
            0.005937371868640184,
            -0.010254580527544022,
            -0.020133381709456444,
            -0.009996287524700165,
            -0.002247898606583476,
            0.022569140419363976,
            0.025015702471137047,
            0.038806747645139694,
            -0.013443264178931713,
            0.015623911283910275,
            -0.0330088771879673,
            0.023383459076285362,
            0.009395656175911427,
            0.009053505957126617,
            -0.02241463027894497,
            0.023043332621455193,
            0.011661323718726635,
            0.04504603520035744,
            -0.020941196009516716,
            -0.0063225035555660725,
            -0.03099508211016655,
            -0.019233690574765205,
            0.02852618508040905,
            0.007576402276754379,
            0.006520499940961599,
            0.005316418129950762,
            -0.030054712668061256,
            -0.01208146009594202,
            0.015080803073942661,
            0.018024688586592674,
            -0.015997447073459625,
            -0.015281426720321178,
            -0.02541951835155487,
            0.03006800077855587,
            -0.030336791649460793,
            -0.03786080330610275,
            -0.0026043897960335016,
            0.03872375190258026,
            0.009543092921376228,
            0.025582125410437584,
            0.021623114123940468,
            -0.003591913962736726,
            0.019140932708978653,
            -0.009090999141335487,
            0.01969769038259983,
            0.025220757350325584,
            0.023404639214277267,
            -0.005009932443499565,
            -0.01929205097258091,
            -0.014610343612730503,
            -0.0345466323196888,
            -0.04884294420480728,
            0.021250877529382706,
            0.0222970899194479,
            -0.010121749714016914,
            0.01742963120341301,
            -0.026850054040551186,
            -0.007793487515300512,
            0.0007114453474059701,
            -0.03670860081911087,
            -0.020276857540011406,
            0.004820128437131643,
            -0.006342163775116205,
            0.0068230037577450275,
            -0.04935644194483757,
            -0.028405651450157166,
            -0.007514392491430044,
            0.02480572834610939,
            -0.018312666565179825,
            -0.03408093377947807,
            0.033598605543375015,
            0.00021982536418363452,
            -0.022402215749025345,
            -0.06254597753286362,
            -0.029040047898888588,
            -0.02208748459815979,
            0.023297922685742378,
            -0.003925282042473555,
            -0.009083470329642296,
            -0.041882775723934174,
            -0.05157287418842316,
            0.03474324941635132,
            0.012686395086348057,
            0.013470855541527271,
            -0.026546066626906395,
            -0.04007255658507347,
            0.008133198134601116,
            -0.04044480249285698,
            -0.014462094753980637,
            -0.04908469319343567,
            0.006252944003790617,
            0.03592999652028084,
            -0.01992357149720192,
            -0.05637567117810249,
            0.029719814658164978,
            0.03190794959664345,
            -0.0052353194914758205,
            0.022129425778985023,
            -0.02106343023478985,
            0.009258694015443325,
            0.01089454349130392,
            0.035028617829084396,
            -0.02582748420536518,
            0.015453439205884933,
            -0.005754782352596521,
            -0.03950551152229309,
            0.014610474929213524,
            -0.036642543971538544,
            0.008210514672100544,
            0.010714701376855373,
            0.022761736065149307,
            0.033575672656297684,
            0.0019709868356585503,
            -0.039295993745326996,
            0.0039096493273973465,
            0.0015765437856316566,
            -0.010032065212726593,
            -0.02550993487238884,
            0.019843051210045815,
            -0.010369612835347652,
            0.016642438247799873,
            0.01003327313810587,
            0.0046097831800580025,
            0.020356643944978714,
            -0.03666863590478897,
            -0.034479834139347076,
            0.0056926230899989605,
            -0.006585716735571623,
            -0.011124887503683567,
            -0.012365203350782394,
            0.030926408246159554,
            -0.002447407227009535,
            -0.018899425864219666,
            -0.03365951031446457,
            -0.013973746448755264,
            0.02567487582564354,
            -0.016352679580450058,
            -0.025387611240148544,
            0.028215723112225533,
            -0.024216782301664352,
            -0.001672418904490769,
            -0.0005664101918227971,
            -0.01908968575298786,
            -0.018244227394461632,
            0.03507308289408684,
            0.03288200870156288,
            0.018768776208162308,
            0.011243332177400589,
            -0.031476765871047974,
            -0.011958333663642406,
            -0.032001808285713196,
            0.036292076110839844,
            -0.007108631078153849,
            -0.007918105460703373,
            -0.002731040120124817,
            0.010009250603616238,
            -0.024982770904898643,
            -0.011397111229598522,
            -0.017750827595591545,
            0.033856891095638275,
            -0.023457670584321022,
            0.0034095547161996365,
            0.009552955627441406,
            0.011390713974833488,
            -0.02708374708890915,
            -0.019992176443338394,
            -0.01632673107087612,
            0.004861908033490181,
            0.01762191392481327,
            0.006736935116350651,
            -0.027941590175032616,
            -0.019254624843597412,
            0.05334334447979927,
            -0.05149320513010025,
            -0.026715556159615517,
            -0.009463086724281311,
            0.018954982981085777,
            0.011790293268859386,
            -0.00896784570068121,
            -0.005740868393331766,
            0.007575450465083122,
            0.00480326684191823,
            0.007705844007432461,
            -0.03818134590983391,
            -0.022641455754637718,
            0.013841185718774796,
            0.011543035507202148,
            -0.008012229576706886,
            -0.038233041763305664,
            -0.030650697648525238,
            -0.0016066239913925529,
            0.025416085496544838,
            0.06198785454034805,
            -0.008437475189566612,
            -0.0035087449941784143,
            0.006016462575644255,
            -0.014398958534002304,
            0.024467432871460915,
            -0.02977786585688591,
            -0.010537073947489262,
            -0.017550403252243996,
            -0.008745672181248665,
            -0.02733813412487507,
            -0.011493543162941933,
            0.029647942632436752,
            0.007501676212996244,
            0.0005521136918105185,
            0.031153367832303047,
            -0.0164013858884573,
            0.008339980617165565,
            0.015582256019115448,
            0.016100328415632248,
            -0.004162600729614496,
            0.010818555019795895,
            0.008436257019639015,
            0.015367737039923668,
            -0.01838819868862629,
            0.0014586831675842404,
            -0.004631446208804846,
            -0.023264631628990173,
            -0.035821739584207535,
            0.03458331152796745,
            0.032167889177799225,
            -0.023792143911123276,
            -0.014930942095816135,
            0.00900711864233017,
            -0.05704965069890022,
            -0.016492728143930435,
            0.015034414827823639,
            -0.05178774148225784,
            0.013628092594444752,
            0.034599997103214264,
            -0.012462192215025425,
            0.01070359256118536,
            -0.002362374449148774,
            -0.009424612857401371,
            0.005021213553845882,
            0.010549903847277164,
            -0.023468216881155968,
            0.009987869299948215,
            -0.039686258882284164,
            -0.01679503731429577,
            -0.023630039766430855,
            0.00866655819118023,
            -0.01663121022284031,
            -0.019670501351356506,
            -0.008947729133069515,
            -0.00025474996073171496,
            -0.004883266054093838,
            0.007006772328168154,
            0.007234313525259495,
            -0.013643612153828144,
            0.01526052225381136,
            -0.000956254662014544,
            -0.0027064464520663023,
            -0.044734153896570206,
            0.006458995398133993,
            -0.01841125823557377,
            -0.020122764632105827,
            0.0543939433991909,
            -0.02188335545361042,
            -0.026460986584424973,
            -0.010757760144770145,
            -0.05176623538136482,
            -0.011751830577850342,
            -0.005773905199021101,
            -0.019441161304712296,
            0.0237701628357172,
            -0.010094350203871727,
            0.020335834473371506,
            -0.01855066791176796,
            -0.0013833020348101854,
            0.007182498462498188,
            -0.02373417466878891,
            -0.03985672816634178,
            -0.016940316185355186,
            -0.0017342345090582967,
            -0.06778237968683243,
            0.002629930851981044,
            0.025425974279642105,
            0.017186613753437996,
            -0.028147783130407333,
            0.026574157178401947,
            -0.032249826937913895,
            -0.008324275724589825,
            -0.027599884197115898,
            -0.014228868298232555,
            0.021541576832532883,
            0.0371357686817646,
            -0.02739238180220127,
            -0.016492513939738274,
            -0.007062763441354036,
            -0.015467474237084389,
            0.007096871268004179,
            -0.04468652978539467,
            -0.011562587693333626,
            -0.024587202817201614,
            0.019438566640019417,
            -0.00012566550867632031,
            -0.0149116525426507,
            -0.05610598996281624,
            0.02246057242155075,
            0.007418531458824873
        ],
        [
            -0.005424689967185259,
            -0.020185407251119614,
            -0.0005618317518383265,
            0.005905137863010168,
            0.005893422290682793,
            -0.0002711013949010521,
            -0.012201832607388496,
            0.022385435178875923,
            -0.022320479154586792,
            0.004336888436228037,
            -0.02867967262864113,
            -0.023646105080842972,
            0.023792320862412453,
            0.03639467433094978,
            0.004679709207266569,
            -0.060968752950429916,
            -0.013491949066519737,
            -0.007662671618163586,
            -0.007613010238856077,
            0.004427126608788967,
            0.006171338725835085,
            0.020357441157102585,
            0.06033288314938545,
            0.015866108238697052,
            -0.04906695708632469,
            -0.009638801217079163,
            -0.035147543996572495,
            0.015652893111109734,
            -0.001837949501350522,
            -0.045489441603422165,
            0.003651837119832635,
            -0.013242109678685665,
            0.00884514581412077,
            -0.020608454942703247,
            0.0007956370245665312,
            -0.008002824150025845,
            -0.03099544160068035,
            0.01886862888932228,
            0.011986890807747841,
            0.03189835324883461,
            0.02781250886619091,
            0.0014086280716583133,
            -0.018390022218227386,
            0.0026272330433130264,
            -0.012833422981202602,
            -0.0024084607139229774,
            -0.019632350653409958,
            -0.025013262405991554,
            -0.008086989633738995,
            0.01392692793160677,
            -0.032627612352371216,
            0.0014427995774894953,
            0.04057268053293228,
            -0.002929173642769456,
            -0.012971533462405205,
            -0.0028186144772917032,
            0.015242544934153557,
            -0.017223266884684563,
            0.03021518886089325,
            -0.0022039455361664295,
            0.007497693877667189,
            0.03148386627435684,
            -0.027165487408638,
            0.0071954927407205105,
            0.00660709710791707,
            0.025624100118875504,
            -0.05603575333952904,
            0.0283034797757864,
            -0.002704216865822673,
            0.0031956774182617664,
            -0.0042603882029652596,
            0.057823214679956436,
            0.0355120524764061,
            0.025086266919970512,
            0.010612967424094677,
            0.016419345512986183,
            -0.06195762753486633,
            -0.018610285595059395,
            0.013638145290315151,
            -0.000605581677518785,
            -0.012379113584756851,
            -0.027728592976927757,
            -0.020631810650229454,
            0.023744232952594757,
            0.01211608201265335,
            -0.002812775317579508,
            0.02473197877407074,
            -0.018960416316986084,
            -0.038167763501405716,
            -0.07760405540466309,
            0.01810670830309391,
            -0.004971284419298172,
            -0.049666475504636765,
            -0.02565196342766285,
            0.023631619289517403,
            -0.003982238471508026,
            -0.010089478455483913,
            -0.005579234566539526,
            -0.07303701341152191,
            0.007978829555213451,
            0.006579004228115082,
            0.02481936849653721,
            -0.0017863898538053036,
            0.01883292943239212,
            0.03625772148370743,
            -0.04172418639063835,
            0.010138312354683876,
            0.006953962612897158,
            0.004886440001428127,
            -0.024668360128998756,
            -0.03626515343785286,
            -0.024550478905439377,
            0.03171714395284653,
            -0.010416722856462002,
            -0.025046097114682198,
            -0.018058011308312416,
            -0.010936247184872627,
            0.010739075019955635,
            0.011526765301823616,
            -0.002447964623570442,
            -0.00038789320387877524,
            0.03446151688694954,
            0.003842482576146722,
            -0.014740163460373878,
            -0.019011443480849266,
            0.03642182797193527,
            0.04083333536982536,
            0.019612034782767296,
            0.01102505624294281,
            0.03993360325694084,
            -0.03615908324718475,
            0.011982133612036705,
            -0.01304140966385603,
            0.030641408637166023,
            -0.011669767089188099,
            -0.05322764068841934,
            -0.03186814486980438,
            0.008024460636079311,
            -0.020409516990184784,
            0.001690985867753625,
            -0.022455155849456787,
            0.013983367010951042,
            -0.05497670918703079,
            -0.04171324893832207,
            -0.0028012809343636036,
            0.029723268002271652,
            -0.003351098159328103,
            0.02775540016591549,
            0.015905963256955147,
            0.012948417104780674,
            -0.062116507440805435,
            -0.10052412003278732,
            -0.00903047900646925,
            0.0020508943125605583,
            0.0016721709398552775,
            -0.0019698748365044594,
            -0.0024845830630511045,
            -0.01245523989200592,
            0.0022928023245185614,
            -0.06081730127334595,
            0.03667788580060005,
            0.015006127767264843,
            -0.02384408935904503,
            0.004146788734942675,
            -0.0025973422452807426,
            0.03507557138800621,
            0.019475096836686134,
            0.0047878059558570385,
            -0.045686766505241394,
            -0.012587379664182663,
            0.005585200153291225,
            0.049668993800878525,
            -0.012707955203950405,
            0.04466157779097557,
            0.014618100598454475,
            0.029397837817668915,
            0.007656484376639128,
            0.009284383617341518,
            0.040022823959589005,
            -0.02723042294383049,
            0.040334053337574005,
            -0.03665895760059357,
            -0.02727057971060276,
            -0.011973973363637924,
            0.0010340481530874968,
            0.02008308842778206,
            0.005434505641460419,
            -0.013851683586835861,
            -0.05137653648853302,
            -0.03395349904894829,
            -0.013080793432891369,
            0.011261176317930222,
            -0.020369620993733406,
            -0.008656400255858898,
            0.006223522592335939,
            0.01639305055141449,
            -0.02488543465733528,
            -0.029023153707385063,
            -0.03451032564043999,
            0.0021452931687235832,
            0.00881576631218195,
            -0.007893617264926434,
            -0.07348397374153137,
            0.005620896350592375,
            0.003180513624101877,
            -0.02241223305463791,
            0.0019297696417197585,
            -0.028838692232966423,
            -0.002643177518621087,
            -0.03095434606075287,
            -0.00475103547796607,
            -0.028652075678110123,
            -0.07271058857440948,
            -0.004009051714092493,
            0.012386851944029331,
            -0.0023183214943856,
            0.018868187442421913,
            0.0026829924900084734,
            0.0002137741248589009,
            0.0057981256395578384,
            -0.006121974904090166,
            0.03328702598810196,
            0.032399553805589676,
            0.026425106450915337,
            0.01423186156898737,
            -0.022605050355196,
            0.02605428919196129,
            -0.008969427086412907,
            -0.005516466218978167,
            0.010846908204257488,
            -0.014839241281151772,
            -0.02702535316348076,
            0.0007230645860545337,
            -0.02413601242005825,
            -0.007764430250972509,
            0.023486098274588585,
            -0.005033515859395266,
            -0.003984284587204456,
            -0.002676767762750387,
            -0.05367599055171013,
            -0.0076181525364518166,
            0.009123663417994976,
            -0.020881762728095055,
            -0.025396617129445076,
            -0.013670801185071468,
            0.0007960362709127367,
            -0.013685105368494987,
            -0.0023889755830168724,
            -0.01286094170063734,
            0.029889263212680817,
            -0.0012621855130419135,
            0.005023421254009008,
            0.006746775936335325,
            -0.02315615676343441,
            -0.022749843075871468,
            0.016352595761418343,
            0.008644464425742626,
            -0.02736109308898449,
            -0.01987462490797043,
            0.020289065316319466,
            -0.058870378881692886,
            0.01810947246849537,
            0.0034656142815947533,
            -0.03580430522561073,
            -0.052449148148298264,
            -0.011729149147868156,
            -0.015416506677865982,
            0.0032722868490964174,
            0.02540779300034046,
            0.01769459992647171,
            -0.011186073534190655,
            -0.03467985987663269,
            0.029901646077632904,
            0.024460697546601295,
            0.011918406933546066,
            -0.017721328884363174,
            0.01830093376338482,
            -0.00785496924072504,
            0.015511599369347095,
            -0.0024700206704437733,
            -0.004696135874837637,
            0.023756753653287888,
            0.038470443338155746,
            0.02874070033431053,
            0.017692476511001587,
            -0.037447500973939896,
            -0.034795328974723816,
            0.043802227824926376,
            0.02201477624475956,
            -0.030950983986258507,
            -0.027702223509550095,
            -0.07640494406223297,
            -0.010215641930699348,
            -0.015366659499704838,
            -0.011021710000932217,
            -0.008284719660878181,
            -0.003966685384511948,
            -0.026277078315615654,
            -0.0010329086799174547,
            0.0030539745930582285,
            -0.00907202996313572,
            -0.0242201779037714,
            -0.0031389736104756594,
            -0.03498654067516327,
            -0.02515062317252159,
            -0.04379350319504738,
            0.01906052604317665,
            -0.05750833451747894,
            0.02463112771511078,
            -0.020949939265847206,
            0.0071952748112380505,
            0.028709720820188522,
            -0.005835382267832756,
            0.0009143115603365004,
            0.006483867764472961,
            -0.017672721296548843,
            0.04242552071809769,
            0.010234146378934383,
            -0.012285226956009865,
            -0.023372719064354897,
            -0.03190234676003456,
            -0.006168977823108435,
            0.047387510538101196,
            0.03644627705216408,
            0.02180294133722782,
            0.03854227066040039,
            -0.04765327647328377,
            0.007691025268286467,
            -0.020574890077114105,
            0.016145937144756317,
            0.003241941099986434,
            0.018297463655471802,
            -0.012378486804664135,
            0.009822040796279907,
            0.013422270305454731,
            0.0012806460727006197,
            -0.005099091678857803,
            -0.0065172710455954075,
            0.03563820198178291,
            -0.05545322597026825,
            0.027036497369408607,
            -0.044541407376527786,
            -0.02787954732775688,
            -0.017826218158006668,
            0.017445189878344536,
            -0.03608153760433197,
            0.008220890536904335,
            0.0156369898468256,
            0.023314297199249268,
            0.040977854281663895,
            -0.02835632674396038,
            -0.07123120874166489,
            -0.05239657312631607,
            -0.049080707132816315,
            0.00964661967009306,
            -0.014442007057368755,
            -0.012141071259975433,
            -0.015277072787284851,
            0.02525787055492401,
            -0.03967057168483734,
            0.04070335626602173,
            -0.020313145592808723,
            -0.026699626818299294,
            0.010332314297556877,
            -0.013268367387354374,
            -0.002005290938541293,
            -0.014495966956019402,
            -0.036971740424633026,
            0.029849207028746605,
            0.015611683018505573,
            -0.018363524228334427,
            -0.002558137057349086,
            0.01246260479092598,
            -0.0015827839961275458,
            0.01999286562204361,
            -0.062442369759082794,
            -0.043070610612630844,
            0.022896820679306984,
            0.06080785393714905,
            -0.004292272962629795,
            0.03586665168404579,
            -0.01990925334393978,
            0.016884468495845795,
            -0.009546362794935703,
            -0.025919411331415176,
            -0.01091077085584402,
            -0.021036820486187935,
            -0.03166690468788147,
            0.0034929581452161074,
            0.0662982389330864,
            0.020796814933419228,
            -0.074200339615345,
            -0.03309835493564606,
            0.052981361746788025,
            -0.05498554930090904,
            0.007234666496515274,
            0.023067697882652283,
            -0.006695136893540621,
            0.03245808184146881,
            0.017334533855319023,
            -0.029804956167936325,
            -0.0021048199851065874,
            0.01035813707858324,
            -0.012260439805686474,
            0.006016791798174381,
            0.017242465168237686,
            -0.013966682367026806,
            0.009776960127055645,
            0.010799781419336796,
            -0.0030138713773339987,
            -0.0012610750272870064,
            -0.026625432074069977,
            -0.03227388486266136,
            -0.01071981992572546,
            0.009350518696010113,
            -0.004075078293681145,
            -0.012836973182857037,
            -0.03532733395695686,
            0.01609509252011776,
            -0.011587114073336124,
            -0.005495133809745312,
            -0.057374048978090286,
            0.08478163927793503,
            0.030335769057273865,
            -0.03094148263335228,
            -0.006258057896047831,
            0.03622957319021225,
            0.013992874883115292,
            0.006977353245019913,
            0.03205904737114906,
            -0.03082338348031044,
            0.0053420825861394405,
            -0.007405554875731468,
            0.05048190429806709,
            -0.017113488167524338,
            -0.055601123720407486,
            -0.039407022297382355,
            -0.014446757733821869,
            0.01825833134353161,
            -0.0034593406599014997,
            0.0070980386808514595,
            0.005472089163959026,
            -0.003640079405158758,
            0.02845051884651184,
            -0.008768254891037941,
            -0.03216155618429184,
            0.026770826429128647,
            -0.02958175539970398,
            0.010218669660389423,
            0.0036005983129143715,
            -0.03833126276731491,
            0.022460976615548134,
            -0.019854752346873283,
            -0.00869826041162014,
            0.015503501519560814,
            0.023035408928990364,
            -0.02725762315094471,
            -0.06610143184661865,
            0.00759480893611908,
            -0.01304896455258131,
            -0.010988925583660603,
            0.023373758420348167,
            -0.04422547295689583,
            -0.05024125054478645,
            -0.015484357252717018,
            -0.03654928877949715,
            -0.021593835204839706,
            -0.045921750366687775,
            0.02438902109861374,
            -0.016233021393418312,
            0.01670876145362854,
            0.022000348195433617,
            0.019849659875035286,
            0.034901056438684464,
            0.0029147849418222904,
            0.034092117100954056,
            -0.025634566321969032,
            0.016468966379761696,
            -0.010339080356061459,
            0.006957198493182659,
            0.03210825473070145,
            -0.005022979807108641,
            -0.021489212289452553,
            0.00296198227442801,
            -0.032818615436553955,
            -0.06344784796237946,
            0.0007346620550379157,
            0.030597204342484474,
            -0.08710477501153946,
            -0.04186040535569191,
            -0.011981111019849777,
            0.004081830848008394,
            -0.010679823346436024,
            -0.03793541342020035,
            -0.036573026329278946,
            0.01965065859258175,
            0.004369691945612431,
            0.01324848085641861,
            0.0195524450391531,
            0.014133007265627384,
            -0.008672981522977352,
            -0.058310989290475845,
            -0.006279605906456709,
            -0.015164662152528763,
            0.01153690554201603,
            0.029600797221064568,
            0.030205896124243736,
            -0.016202406957745552,
            -0.019941052421927452,
            0.009711675345897675,
            0.012153998017311096,
            -0.02195870690047741,
            0.017973778769373894,
            -0.024649102240800858,
            -0.012335242703557014,
            0.034819599241018295,
            0.013449600897729397,
            0.022109253332018852,
            -0.010851113125681877,
            0.008330185897648335,
            -0.017926786094903946,
            0.019959591329097748,
            -0.015454970300197601,
            0.022290727123618126,
            -0.0034642855171114206,
            -0.04276840016245842,
            -0.0005641890456900001,
            -0.05797627940773964,
            -0.02371530793607235,
            0.013244160450994968,
            -0.020218567922711372,
            0.034276414662599564,
            -0.0028071128763258457,
            0.012507528066635132,
            -0.029969096183776855,
            -0.05802464485168457,
            0.009493434801697731,
            -0.02882383018732071,
            0.02787332981824875,
            -0.05350767448544502,
            0.0012491222005337477,
            0.029275020584464073,
            0.0008911506156437099,
            0.023792782798409462,
            0.011954041197896004,
            -0.04856238141655922,
            0.013740820810198784,
            -0.012046834453940392,
            0.023226182907819748,
            0.011725446209311485,
            0.012470947578549385,
            -0.008092625066637993,
            0.022966261953115463,
            0.020991798490285873,
            0.015557972714304924,
            0.014466839842498302,
            -0.0549372099339962,
            0.006214713212102652,
            -0.024786926805973053,
            0.007016198709607124,
            0.011948595754802227,
            -0.012688782066106796,
            -0.012126576155424118,
            0.012052087113261223,
            0.033846236765384674,
            -0.024220341816544533,
            -0.0307611096650362,
            0.0006436050753109157,
            0.009369817562401295,
            0.02632857672870159,
            -0.009270417504012585,
            -0.0020180644933134317,
            -0.012764908373355865,
            -0.021977923810482025,
            0.013221792876720428,
            -0.010252614505589008,
            -0.02325858362019062,
            0.007306941784918308,
            -0.03325950354337692,
            0.008323662914335728,
            -0.010233125649392605,
            -0.013153205625712872,
            -0.012416300363838673,
            -0.013576267287135124,
            -0.032422877848148346,
            -0.05058584362268448,
            -0.03145886957645416,
            0.012238690629601479,
            -0.04077150672674179,
            0.001064843381755054,
            0.03493815287947655,
            -0.056362707167863846,
            -0.018348494544625282,
            0.03496590256690979,
            0.0016896715387701988,
            0.018773868680000305,
            -0.0018501249141991138,
            0.001600232906639576,
            0.012934559024870396,
            0.012446217238903046,
            -0.026293059810996056,
            -0.024840468540787697,
            -0.026450123637914658,
            0.01600622572004795,
            -0.0033020314294844866,
            0.007038777694106102,
            -0.014990333467721939,
            -0.03714121878147125,
            -0.04527527466416359,
            -0.06772486865520477,
            0.024492790922522545,
            -0.011719913221895695,
            -0.013295126147568226,
            0.029428472742438316,
            0.026310482993721962,
            -0.029116645455360413,
            0.0182091873139143,
            0.01770661398768425,
            0.01668725349009037,
            -0.04348890483379364,
            0.003498742589727044,
            0.006762049626559019,
            -0.026943422853946686,
            0.03526981920003891,
            -0.03416592255234718,
            -0.042535290122032166,
            -4.534726031124592e-05,
            0.03179775923490524,
            -0.07799220085144043,
            -0.013698660768568516,
            0.017852429300546646,
            0.03729681670665741,
            -0.018238646909594536,
            -0.02317672409117222,
            -0.02701631188392639,
            -0.008368115872144699,
            -0.03400711342692375,
            0.006561993155628443,
            -0.019345661625266075,
            -0.00321913231164217,
            0.009336506016552448,
            0.028672175481915474,
            -0.01048958208411932,
            -0.0666576623916626,
            0.02514665760099888,
            0.02988971397280693,
            -0.02881798706948757,
            -0.018352938815951347,
            0.018523404374718666,
            0.001260786084458232,
            -0.011746971867978573,
            0.005971436854451895,
            -0.024010345339775085,
            0.0016792318783700466,
            -0.04019352048635483,
            -0.0024040197022259235,
            0.03100389428436756,
            0.03272461146116257,
            -0.0005815557087771595,
            0.017014842480421066,
            -0.045099612325429916,
            0.003402227535843849,
            -0.012706343084573746,
            -0.001398084801621735,
            0.0035887937992811203,
            -0.01118753757327795,
            0.0007048989646136761,
            0.023320002481341362,
            0.029897935688495636,
            -0.004032195080071688,
            0.002818254055455327,
            0.04837803170084953,
            0.015615015290677547,
            -0.01140202209353447,
            0.0006588508258573711,
            -0.022729702293872833,
            0.02157180942595005,
            0.02880145236849785,
            -0.008458641357719898,
            -0.058035530149936676,
            0.02927475981414318,
            -0.0019810872618108988,
            -0.04877922683954239,
            -0.00414652656763792,
            -0.046744294464588165,
            -0.011203499510884285,
            -0.03549743816256523,
            0.043418049812316895,
            -0.06346122175455093,
            0.00365883088670671,
            -0.017733782529830933,
            -0.019993524998426437,
            0.003226787317544222,
            0.03904861584305763,
            0.013490804471075535,
            -0.02252420224249363,
            -0.02454710192978382,
            0.022202786058187485,
            0.0014261463657021523,
            -0.021748024970293045,
            0.03675853833556175,
            0.0007241841522045434,
            0.002226696815341711,
            0.015899157151579857,
            -0.01594926416873932,
            -0.04201261326670647,
            0.01447273325175047,
            -0.03055928647518158,
            0.01785733364522457,
            -0.033960167318582535,
            0.016497107222676277,
            -0.0008732000715099275,
            0.00019005454669240862,
            -0.009580196812748909,
            -0.027941355481743813,
            -0.015534349717199802,
            -0.08887016773223877,
            -0.003505151020362973,
            -0.010424340143799782,
            0.0012618934269994497,
            -0.07665566354990005,
            -0.002490451792255044,
            -0.0014773403527215123,
            -0.020912308245897293,
            0.0149110471829772,
            0.006405624561011791,
            0.02953050099313259,
            0.010048676282167435,
            -0.015543979592621326,
            0.027181364595890045,
            -0.020781908184289932,
            0.02510913461446762,
            -0.027712872251868248,
            0.01625165529549122,
            -0.03321915864944458,
            -0.020072631537914276,
            0.013950570486485958,
            -0.029758716002106667,
            0.04341570660471916,
            0.04316980391740799,
            -0.009887324646115303,
            -0.011793103069067001,
            -0.011607837863266468,
            -0.0039829472079873085,
            -0.005012831650674343,
            -0.010529742576181889,
            0.025711476802825928,
            -0.009658705443143845,
            -0.03605009987950325,
            -0.005363681819289923,
            0.009438415057957172,
            -0.011178934015333652,
            0.010843031108379364,
            0.06662262231111526,
            -0.014344323426485062,
            -0.00480621587485075,
            -0.007375649176537991,
            -0.021971918642520905,
            0.006866053678095341,
            -0.012437039986252785,
            -0.019632436335086823,
            -0.03382381424307823,
            -0.002666783519089222,
            -0.03349291905760765,
            -0.025745036080479622,
            -0.012977593578398228,
            0.0008908851887099445,
            -0.027295460924506187,
            -0.009137656539678574,
            0.012868446297943592,
            -0.00894144643098116,
            0.007435935083776712,
            0.025451885536313057,
            0.013744277879595757,
            -0.0194834154099226,
            0.040086209774017334,
            0.0902300700545311,
            0.058490559458732605,
            -0.005936924833804369,
            0.02325720526278019,
            0.021337546408176422,
            0.02248835191130638,
            -0.025961847975850105,
            0.02024414762854576,
            -0.04611895978450775,
            -0.004300934728235006,
            0.006524161901324987,
            -0.03528573364019394,
            -0.004165322985500097,
            -0.025586670264601707,
            0.011835028417408466,
            -0.010028953664004803,
            0.052275151014328,
            0.030715998262166977,
            -0.017648449167609215,
            -0.02329944632947445,
            0.031260374933481216,
            0.02768653631210327,
            -0.009048324078321457,
            -0.025764986872673035,
            -0.024266313761472702,
            -0.0017287947703152895,
            -0.019679177552461624,
            0.02264135517179966,
            -0.04293021559715271,
            -0.002808839315548539,
            0.015661658719182014,
            0.009263047948479652,
            0.0016835497226566076,
            0.013588453643023968,
            0.02062196098268032,
            0.010918471030890942,
            -0.01259153988212347,
            -0.008012477308511734,
            -0.0031777173280715942,
            0.009779085405170918,
            -0.027701839804649353,
            -0.029825905337929726,
            -0.019369155168533325,
            0.01843906007707119,
            -0.009786130860447884,
            0.01356501691043377,
            -0.03297630324959755,
            0.004651476629078388,
            -0.04593076556921005,
            -0.047802094370126724,
            0.022599369287490845,
            0.00915791466832161,
            0.017468661069869995,
            0.0008303812355734408,
            0.008026855997741222,
            -0.0055327024310827255,
            0.03155818581581116,
            -0.023775530979037285,
            -0.08334069699048996,
            0.02619965560734272,
            0.007589153945446014,
            0.0012363585410639644,
            -0.016619235277175903,
            0.004325763788074255,
            0.02070571482181549,
            -0.05073503404855728,
            0.0019494426669552922,
            -0.012602606788277626,
            -0.04068537801504135,
            -0.004815923050045967,
            -0.021254075691103935,
            -0.01940050907433033,
            -0.014723614789545536,
            -0.013088615611195564,
            0.004247245844453573,
            0.018076278269290924,
            -0.001046761404722929,
            0.004670424852520227,
            -0.00815278384834528,
            0.013665912672877312,
            0.02038755640387535,
            0.003231788519769907,
            -0.0053686220198869705,
            -0.03437267243862152,
            0.0028580192010849714,
            0.006695128977298737,
            0.0028868201188743114,
            0.01534306164830923,
            0.015499791130423546,
            0.022687088698148727,
            0.005836954805999994,
            0.031839627772569656,
            -0.024229276925325394,
            0.02425292506814003,
            -0.011199728585779667,
            0.03993234783411026,
            -0.04957016184926033,
            -0.026910318061709404,
            0.02147393301129341,
            0.0437181331217289,
            0.011648394167423248,
            -0.01933882385492325,
            -0.025446470826864243,
            0.01290856022387743,
            0.0022109150886535645,
            -0.039827607572078705,
            -0.03901220113039017,
            -0.021374275907874107,
            0.02923927642405033,
            -0.03334183990955353,
            -0.031132156029343605,
            -0.049438923597335815,
            0.014520508237183094,
            -0.013826238922774792,
            -0.06949276477098465,
            -0.014760651625692844,
            0.014498597010970116,
            -0.017191104590892792,
            -0.04715343192219734,
            0.0279616117477417,
            -0.026525413617491722,
            -0.013326358050107956,
            -0.020695863291621208,
            -0.01858529821038246,
            -0.02773667499423027,
            0.01763552986085415,
            -0.0005998940905556083,
            0.018983151763677597,
            0.019708478823304176,
            0.013053216971457005,
            -0.004621900152415037,
            -0.05135461688041687,
            0.031717173755168915,
            0.013277394697070122,
            0.010087328962981701,
            0.0352841280400753,
            -0.008588501252233982,
            0.033913273364305496,
            -0.02565929666161537,
            0.013447647914290428,
            0.03494736924767494,
            0.013122583739459515,
            -0.017135508358478546,
            -0.010661118663847446,
            0.023804835975170135,
            0.006830317433923483,
            -0.015815168619155884,
            0.0008151683723554015,
            0.013459178619086742,
            -0.03193721920251846,
            -0.04130955785512924,
            -0.016617344692349434,
            -0.01566474325954914,
            -0.026684904471039772,
            0.01955210417509079,
            0.021801365539431572,
            0.029672633856534958,
            -0.07308800518512726,
            0.00020317742018960416,
            -0.02220907248556614,
            0.014737497083842754,
            -0.037361156195402145,
            -0.015481551177799702,
            0.029479041695594788,
            -0.02654179185628891,
            0.0010231405030936003,
            -0.014933416619896889,
            0.04194815084338188,
            -0.05752667039632797,
            -0.009541500359773636,
            -0.03874479606747627,
            0.01620125025510788,
            0.04833284392952919,
            -0.022293303161859512,
            0.023373859003186226,
            0.007455859798938036,
            0.0006997341988608241,
            -0.043388351798057556,
            -0.013823943212628365,
            -0.031172730028629303,
            -0.008753559552133083,
            -0.031412456184625626,
            0.009946071542799473,
            0.02726799249649048,
            0.05847413092851639,
            0.017467398196458817,
            -0.021075023338198662,
            0.015511316247284412,
            -0.02708239108324051,
            0.014369312673807144,
            -0.05887927487492561,
            0.02203945815563202,
            -0.019876182079315186,
            0.005960572510957718,
            -0.026356173679232597,
            -0.03267286717891693,
            0.017577694728970528,
            -0.002130972919985652,
            -0.023680804297327995,
            -0.01022002100944519,
            0.0548328198492527,
            0.0023246423806995153,
            0.017574330791831017,
            -0.01772531494498253,
            0.002655185293406248,
            -0.006481671705842018,
            -0.041707850992679596,
            0.02672444097697735,
            -0.012363053858280182,
            -0.0038635767996311188,
            0.017267465591430664,
            -0.025462927296757698,
            -0.06840129941701889,
            -0.01709454506635666,
            0.026944339275360107,
            -0.0064600445330142975,
            -0.004657902289181948,
            -0.027064403519034386,
            -0.018679829314351082,
            -0.0224901232868433,
            0.025726791471242905,
            -0.027787474915385246,
            0.02738712728023529,
            0.011418845504522324,
            0.014759744517505169,
            -0.010811001993715763,
            0.03602465242147446,
            0.03377975523471832,
            0.006074305158108473,
            -0.002575475489720702,
            -0.009438986890017986,
            0.003449806710705161,
            -0.018305717036128044,
            -0.0028912562411278486,
            -0.016347430646419525,
            0.0011648590443655849,
            0.004038797691464424,
            -0.004973864648491144,
            -0.07265961170196533,
            -0.038974206894636154,
            -0.013204740360379219,
            0.03427447751164436,
            -0.036973364651203156,
            0.022498657926917076,
            0.016846299171447754,
            0.03060193359851837,
            -0.02561490796506405,
            -0.017961882054805756
        ],
        [
            -0.004624466877430677,
            -0.03558671846985817,
            -0.02142195962369442,
            0.01856542006134987,
            -0.0298733189702034,
            0.02891702391207218,
            0.007911755703389645,
            0.03831840679049492,
            -0.02814294397830963,
            -0.002103848848491907,
            0.005084349773824215,
            0.021506115794181824,
            -0.005226059351116419,
            0.03178422898054123,
            0.013939677737653255,
            0.03550315648317337,
            -0.0038437850307673216,
            -0.007295358460396528,
            0.02354847453534603,
            0.009128471836447716,
            0.009019928053021431,
            -0.025610731914639473,
            -0.007703079842031002,
            0.008107890374958515,
            -0.012446265667676926,
            0.038215745240449905,
            0.013093062676489353,
            0.014445800334215164,
            -0.03160668909549713,
            -0.015157422050833702,
            -0.011121135205030441,
            -0.003323311684653163,
            0.010867943055927753,
            -0.0034825163893401623,
            -0.03623754531145096,
            0.026668820530176163,
            -0.0007239496917463839,
            0.014225415885448456,
            0.005783290136605501,
            0.008808212354779243,
            0.014110550284385681,
            0.017571967095136642,
            -0.0022505815140902996,
            -0.027869561687111855,
            -0.013996187597513199,
            -0.015240123495459557,
            -0.006527478341013193,
            0.02887939102947712,
            0.015626197680830956,
            0.0011692692060023546,
            0.0256241075694561,
            -0.013421432115137577,
            -0.027915727347135544,
            -0.03182370960712433,
            -0.02464999258518219,
            -0.010178493335843086,
            -0.019581731408834457,
            0.007946926169097424,
            -0.0004389002569951117,
            -0.02508571743965149,
            -0.030752941966056824,
            -0.02012668363749981,
            -0.018338872119784355,
            0.0023161773569881916,
            -0.005853528622537851,
            0.023330803960561752,
            -0.010830140672624111,
            0.011415311135351658,
            -0.025526970624923706,
            0.018356861546635628,
            -0.0005343953380361199,
            0.03528956323862076,
            -0.019274882972240448,
            -0.03382623940706253,
            -0.007846812717616558,
            0.017742661759257317,
            -0.009929223917424679,
            0.025203630328178406,
            -0.01597662828862667,
            0.03724953159689903,
            0.007457739673554897,
            0.018724631518125534,
            -0.013551364652812481,
            -0.01712249591946602,
            0.014147905632853508,
            0.010669329203665257,
            0.01697426848113537,
            0.014562972821295261,
            0.025174790993332863,
            -0.02799846976995468,
            -0.019327884539961815,
            -0.017110193148255348,
            0.011480633169412613,
            0.0019444564823061228,
            0.0129612497985363,
            0.007172735873609781,
            0.00509653240442276,
            0.0030382624827325344,
            -0.060323432087898254,
            0.017526237294077873,
            -0.028493160381913185,
            -0.02805227041244507,
            -0.01795313134789467,
            -0.022964294999837875,
            0.016824040561914444,
            0.028370143845677376,
            0.012241296470165253,
            0.03877352923154831,
            -0.0060693430714309216,
            0.007049954496324062,
            -0.008032417856156826,
            -0.006318184547126293,
            0.02767394296824932,
            0.02998874895274639,
            -0.005166229791939259,
            0.0180733073502779,
            0.012326239608228207,
            -0.013074241578578949,
            0.028450090438127518,
            0.02843726985156536,
            -0.027210073545575142,
            0.0035286147613078356,
            0.03811945393681526,
            0.003243027487769723,
            -0.0020513045601546764,
            0.009066740982234478,
            -0.03626643121242523,
            -0.010425851680338383,
            -0.025842325761914253,
            -0.013269355520606041,
            -0.03018350712954998,
            0.03421228379011154,
            0.01472089346498251,
            -0.015966907143592834,
            0.014800230041146278,
            -0.029533782973885536,
            -0.01535969041287899,
            0.0271501075476408,
            -0.0007117142667993903,
            0.033393457531929016,
            0.017195522785186768,
            -0.04085853323340416,
            0.0447276271879673,
            0.007997740060091019,
            -0.007249009795486927,
            -0.009807044640183449,
            -0.004717115778476,
            -0.018455035984516144,
            0.024529922753572464,
            -0.01728171668946743,
            -0.021473290398716927,
            0.03309295326471329,
            -0.017039820551872253,
            -0.007653845939785242,
            -0.008215089328587055,
            -0.014758382923901081,
            0.01704084500670433,
            0.028743650764226913,
            0.018711397424340248,
            -0.023205235600471497,
            0.011009867303073406,
            0.02153090387582779,
            0.03035549260675907,
            -0.03167568892240524,
            -0.0017866172129288316,
            0.01773841865360737,
            0.024818919599056244,
            0.044233664870262146,
            -0.009243558160960674,
            0.036663271486759186,
            0.026294808834791183,
            -0.001882402691990137,
            -0.022266466170549393,
            0.033299099653959274,
            -0.01792500726878643,
            0.033030033111572266,
            0.021576404571533203,
            -0.008321530185639858,
            -0.02780541218817234,
            -0.017346590757369995,
            -0.006850080098956823,
            -0.01687677763402462,
            0.024664487689733505,
            0.007929407991468906,
            0.028671870008111,
            -0.02108626440167427,
            -0.004273526836186647,
            -0.009527022019028664,
            -0.012507922016084194,
            0.012590310536324978,
            0.003855309681966901,
            0.013296077959239483,
            -0.015512124635279179,
            -0.02025778405368328,
            0.025408398360013962,
            -0.0013925156090408564,
            0.029674381017684937,
            -0.004453966394066811,
            0.013079767115414143,
            -0.024482745677232742,
            -0.0336882509291172,
            0.011427819728851318,
            -0.008265462704002857,
            0.0211930014193058,
            -0.030733266845345497,
            0.026433758437633514,
            0.0024642657954245806,
            0.024333374574780464,
            0.024598494172096252,
            0.035850152373313904,
            0.001156506477855146,
            0.023428939282894135,
            -0.0028881109319627285,
            -0.004921312443912029,
            0.01580147258937359,
            0.03874508664011955,
            -0.00701478635892272,
            0.012003319337964058,
            -0.0188615620136261,
            -0.01969052664935589,
            -0.0007941934163682163,
            -0.012704276479780674,
            -0.031408216804265976,
            0.010705587454140186,
            -0.011671233922243118,
            0.030719446018338203,
            -0.032964058220386505,
            0.019672701135277748,
            -0.04131533205509186,
            0.006324054207652807,
            0.006270203739404678,
            -0.011024671606719494,
            -0.023446187376976013,
            0.03189844265580177,
            -0.0362662672996521,
            -0.002276830608025193,
            0.05014045536518097,
            -0.019116472452878952,
            -0.003665007185190916,
            0.0013227284653112292,
            -0.0076310113072395325,
            0.02348528802394867,
            0.032785989344120026,
            0.01907988451421261,
            0.022726427763700485,
            0.02148543857038021,
            -0.01978522166609764,
            -0.024165702983736992,
            -0.026646681129932404,
            -0.007768602576106787,
            -0.029399584978818893,
            0.027369584888219833,
            -0.01324709877371788,
            0.019361499696969986,
            0.011313939467072487,
            -0.008060027845203876,
            0.002120326040312648,
            -0.008986306376755238,
            -0.01548248901963234,
            -0.021861473098397255,
            -0.00669847521930933,
            0.012939197942614555,
            0.011959107592701912,
            0.038894347846508026,
            0.022480115294456482,
            0.022929461672902107,
            0.026430752128362656,
            -0.0017535327933728695,
            -0.0019365650368854403,
            0.007548603694885969,
            -0.04730125889182091,
            -0.06279885768890381,
            -0.02509125880897045,
            0.0001893907319754362,
            0.00883809570223093,
            0.03640177473425865,
            -0.022141506895422935,
            0.002157013863325119,
            -0.017064804211258888,
            -0.018285904079675674,
            -0.020951511338353157,
            -0.005633147899061441,
            0.013087485916912556,
            -0.0067155142314732075,
            -0.0024569372180849314,
            0.005798332393169403,
            0.015935879200696945,
            0.014979296363890171,
            -0.027981147170066833,
            0.012706045992672443,
            -0.0252345260232687,
            0.031764719635248184,
            0.024140287190675735,
            0.004192881286144257,
            0.0338006392121315,
            -0.019326945766806602,
            0.003247747663408518,
            -0.007011584937572479,
            -0.04643898457288742,
            -0.005682148039340973,
            0.027269838377833366,
            -0.021466054022312164,
            0.0043043578043580055,
            -0.022676987573504448,
            0.030294569209218025,
            -0.009691832587122917,
            0.008066454902291298,
            0.013022790662944317,
            0.04211483895778656,
            0.00809406116604805,
            0.011211029253900051,
            -0.006999432574957609,
            -0.019465919584035873,
            0.02687952108681202,
            -0.021232761442661285,
            -0.003737820778042078,
            -0.0021904471796005964,
            0.023458492010831833,
            0.02783205732703209,
            0.010245583020150661,
            -0.010475353337824345,
            -0.04185943305492401,
            0.015299644321203232,
            0.04877054691314697,
            0.0309218131005764,
            0.00662307208403945,
            0.023966863751411438,
            0.02453453466296196,
            0.007877682335674763,
            0.0276908278465271,
            0.02391878515481949,
            0.001056031440384686,
            -0.00484996335580945,
            0.03107740543782711,
            -0.020579509437084198,
            -0.003314186353236437,
            0.003980162087827921,
            -0.036011554300785065,
            -0.017504841089248657,
            0.026885826140642166,
            0.0030358382500708103,
            0.025003736838698387,
            -0.040890730917453766,
            0.026609722524881363,
            -0.03175562247633934,
            0.02758660353720188,
            -0.05270133912563324,
            0.00978926382958889,
            0.038310401141643524,
            -0.011915318667888641,
            -0.012374474667012691,
            -0.02261897176504135,
            -0.017710471525788307,
            -0.005687760654836893,
            -0.011391164734959602,
            -0.00725138746201992,
            -0.029259050264954567,
            0.010636651888489723,
            0.01365660596638918,
            -0.016065889969468117,
            0.020989608019590378,
            0.008493122644722462,
            0.0008059721440076828,
            -0.003917851485311985,
            -0.020910561084747314,
            0.035279616713523865,
            -0.010078604333102703,
            0.020137090235948563,
            -0.006130160763859749,
            0.018033260479569435,
            -0.014734192751348019,
            0.02865253947675228,
            -0.015949979424476624,
            -0.02093103900551796,
            0.007588834036141634,
            0.003481850726529956,
            -0.0018459550337865949,
            0.03509029000997543,
            0.00648887874558568,
            0.006857161410152912,
            0.0004260105488356203,
            -0.018127189949154854,
            0.023959772661328316,
            -0.017470674589276314,
            0.01052007731050253,
            0.012067023664712906,
            -0.008568967692553997,
            0.0010006882948800921,
            0.027944138273596764,
            0.00421283021569252,
            -0.017140410840511322,
            0.01108175702393055,
            0.02399095892906189,
            -0.045797523111104965,
            -0.003145334543660283,
            0.009230724535882473,
            0.005859903525561094,
            0.005149331409484148,
            -0.010788663290441036,
            -0.023214224725961685,
            0.01573878899216652,
            -0.016268892213702202,
            0.022648733109235764,
            0.015144090168178082,
            -0.01856505312025547,
            0.023551134392619133,
            -0.020487001165747643,
            0.03467138111591339,
            0.010947797447443008,
            0.005809949245303869,
            0.006420589517802,
            0.03670569509267807,
            0.020687969401478767,
            -0.04493311047554016,
            -0.00278835603967309,
            0.001904236851260066,
            -0.0006550610414706171,
            -0.019898412749171257,
            -0.0038057928904891014,
            0.0325833298265934,
            0.030417902395129204,
            0.001739456201903522,
            -0.017968541011214256,
            0.0029341562185436487,
            0.02253020741045475,
            -0.016521843150258064,
            0.02136406861245632,
            -0.010714475996792316,
            -0.005758883897215128,
            -0.043427832424640656,
            -0.0053824372589588165,
            -0.002478992799296975,
            0.012480827048420906,
            0.013882291503250599,
            0.011736316606402397,
            -0.029807012528181076,
            0.015698067843914032,
            0.01105309184640646,
            0.020795417949557304,
            0.01583731733262539,
            -0.0029259691946208477,
            0.02238931693136692,
            0.01104656606912613,
            -0.025799140334129333,
            -0.012052548117935658,
            0.020563583821058273,
            0.022158944979310036,
            -0.02869589254260063,
            -0.016055667772889137,
            -0.011030969209969044,
            -0.00424616876989603,
            0.028004715219140053,
            0.019068479537963867,
            -0.022273525595664978,
            0.0018178739119321108,
            -0.02515666000545025,
            0.021774722263216972,
            0.009770556353032589,
            -0.014725010842084885,
            0.0356263741850853,
            -0.01160539872944355,
            0.0014542128192260861,
            -0.018730726093053818,
            0.002563467947766185,
            -0.011057010851800442,
            0.0086025670170784,
            0.027564652264118195,
            -0.03980148211121559,
            0.02549983561038971,
            -0.02273626998066902,
            0.0022178839426487684,
            -0.02028793841600418,
            -0.025468451902270317,
            0.02148038148880005,
            0.009123246185481548,
            0.03522241488099098,
            0.001247056876309216,
            0.020631596446037292,
            0.0176239050924778,
            -0.021756745874881744,
            0.03243841975927353,
            0.00489727733656764,
            0.0008676462457515299,
            0.01598924770951271,
            0.044488921761512756,
            0.02909773215651512,
            -0.02632416971027851,
            -0.03639378026127815,
            -0.017866112291812897,
            -0.027699628844857216,
            0.016270577907562256,
            0.0044095576740801334,
            0.016036642715334892,
            0.0009423051378689706,
            -0.0016469017136842012,
            0.0070051634684205055,
            0.04093198850750923,
            0.002430227817967534,
            0.022245856001973152,
            -0.008362384513020515,
            -0.018719036132097244,
            0.028013011440634727,
            -0.004658815450966358,
            -0.008487356826663017,
            0.01914723962545395,
            0.005902478937059641,
            0.0043531605042517185,
            0.02206166833639145,
            -0.023960094898939133,
            0.031043246388435364,
            0.020000524818897247,
            0.01001756265759468,
            0.015962371602654457,
            -0.012979571707546711,
            0.001495025702752173,
            0.010335211642086506,
            0.034279778599739075,
            0.0004890586715191603,
            -0.036050379276275635,
            -0.014911129139363766,
            -0.0007100826478563249,
            0.026997238397598267,
            -0.0003298625524621457,
            0.01769711636006832,
            -0.039140842854976654,
            0.03046703152358532,
            -0.052532512694597244,
            -0.0011486756848171353,
            -0.026803070679306984,
            -0.00025542345247231424,
            -0.019043518230319023,
            0.010957134887576103,
            0.028957318514585495,
            0.01752951741218567,
            0.00452415319159627,
            -0.010652696713805199,
            0.026052992790937424,
            -0.0022586120758205652,
            0.04558951035141945,
            -0.0012279863003641367,
            0.02495718188583851,
            -0.02554996870458126,
            0.010370749980211258,
            -0.020337751135230064,
            -0.0076708439737558365,
            0.0248106699436903,
            0.002139993477612734,
            0.009580781683325768,
            -0.022535191848874092,
            0.012719430960714817,
            0.020801924169063568,
            -0.020762523636221886,
            0.010616900399327278,
            0.03862157091498375,
            0.012952994555234909,
            0.01676315814256668,
            -0.004329526796936989,
            0.030514515936374664,
            0.027659010142087936,
            0.028486985713243484,
            0.009692833758890629,
            0.025579215958714485,
            -0.021019577980041504,
            -0.005831340327858925,
            0.02251938357949257,
            0.0016749330097809434,
            6.582018977496773e-05,
            -0.03401363641023636,
            -0.001856519840657711,
            0.025769775733351707,
            -0.030229981988668442,
            0.016906490549445152,
            0.00573661457747221,
            0.029691850766539574,
            0.050981905311346054,
            0.008284422568976879,
            0.03587095066905022,
            -0.04749546945095062,
            0.0065962811931967735,
            -0.026484975591301918,
            0.006896035745739937,
            -0.004477619659155607,
            -0.0012966940412297845,
            -0.027682790532708168,
            0.014409499242901802,
            -0.011014636605978012,
            0.00954106543213129,
            0.00760419387370348,
            -0.024017395451664925,
            -0.015525980852544308,
            0.03177458420395851,
            -0.00265679438598454,
            0.029023490846157074,
            -0.001752631738781929,
            -0.006263982970267534,
            0.017315808683633804,
            -0.03936758637428284,
            -0.021748527884483337,
            -0.008231669664382935,
            0.025378761813044548,
            -0.006797032430768013,
            -0.00394580140709877,
            0.01327083446085453,
            0.012415643781423569,
            0.019736047834157944,
            -0.0075600421987473965,
            0.021145673468708992,
            -0.03365257754921913,
            0.008777763694524765,
            0.028934461995959282,
            -0.023903707042336464,
            -0.02003837190568447,
            0.008557754568755627,
            -0.014675289392471313,
            -0.03299090266227722,
            0.044871844351291656,
            -0.02394923008978367,
            -0.03471510857343674,
            0.02422044798731804,
            0.0013526990078389645,
            -0.011825562454760075,
            -0.011582903563976288,
            0.0029054470360279083,
            0.025295589119195938,
            -0.011259104125201702,
            0.036246541887521744,
            -0.005768442526459694,
            0.03500092774629593,
            -0.0011115416418761015,
            0.010026076808571815,
            -0.02255662903189659,
            -0.012439069338142872,
            -0.03258870169520378,
            0.01760050095617771,
            -0.026705719530582428,
            0.016828937456011772,
            0.023203805088996887,
            -0.0016497899778187275,
            0.010746740736067295,
            0.004628079477697611,
            -0.014301282353699207,
            0.0197664313018322,
            0.05342365428805351,
            -0.022025832906365395,
            -0.02944861724972725,
            -9.519990271655843e-05,
            -0.005923436488956213,
            0.012100327759981155,
            -0.009833124466240406,
            0.012880533002316952,
            -0.019443927332758904,
            -0.01879854127764702,
            0.0013502559158951044,
            0.032248519361019135,
            0.033482473343610764,
            0.016963155940175056,
            -0.017877040430903435,
            0.0014472270850092173,
            0.028698131442070007,
            0.04366853088140488,
            0.03569050878286362,
            0.009092138148844242,
            0.022814661264419556,
            0.026206407696008682,
            -0.008866287767887115,
            0.022908343002200127,
            0.001016128109768033,
            0.021292420104146004,
            0.0068352967500686646,
            0.024828728288412094,
            -0.035666000097990036,
            -0.021803591400384903,
            -0.033962056040763855,
            0.01804923079907894,
            -0.009724163450300694,
            0.000612384348642081,
            -0.007689745165407658,
            -0.014638279564678669,
            0.03279931843280792,
            0.018374620005488396,
            0.024294188246130943,
            0.04102885350584984,
            -0.009670611470937729,
            0.019038213416934013,
            0.0032231479417532682,
            -0.0033485579770058393,
            -0.0057694436982274055,
            0.03486106917262077,
            0.03077157959342003,
            0.018353357911109924,
            -0.010084474459290504,
            -0.012259196490049362,
            -0.008272265084087849,
            -0.025836192071437836,
            0.009278004989027977,
            0.039734214544296265,
            -0.02878039889037609,
            0.027009624987840652,
            -0.03937428072094917,
            0.024827733635902405,
            -0.0014199555153027177,
            -0.019459033384919167,
            -0.020416485145688057,
            -0.0026470054872334003,
            0.014058340340852737,
            0.010848631151020527,
            0.03709632530808449,
            -0.010768405161798,
            0.037112630903720856,
            0.01712847873568535,
            -0.014303154312074184,
            0.024277111515402794,
            0.029343759641051292,
            -0.01592668518424034,
            -0.0324939489364624,
            0.007221045903861523,
            -0.008354655466973782,
            -0.036915700882673264,
            -0.0038151408080011606,
            0.016221104189753532,
            -0.0037904162891209126,
            -0.021732592955231667,
            -0.020135048776865005,
            -0.03579116240143776,
            -0.013878109864890575,
            0.03884011134505272,
            0.014830379746854305,
            -0.02622387744486332,
            0.028297172859311104,
            -0.030972301959991455,
            -0.00044559905654750764,
            -0.04442306235432625,
            0.01004641130566597,
            -0.01714799366891384,
            -0.024705585092306137,
            -0.029357029125094414,
            0.020500579848885536,
            0.004871089477092028,
            0.02357430011034012,
            0.02645219676196575,
            0.014111111871898174,
            -0.011333901435136795,
            0.0009511903626844287,
            -0.018617888912558556,
            -0.030571112409234047,
            0.011385022662580013,
            0.04752212390303612,
            -0.01536776963621378,
            0.01734945923089981,
            -0.023788122460246086,
            -0.024214548990130424,
            0.03155249357223511,
            0.00023587135365232825,
            0.01964748464524746,
            0.009679113514721394,
            0.025468917563557625,
            0.03211777284741402,
            -0.03271682560443878,
            0.017976200208067894,
            0.008855278603732586,
            0.036265015602111816,
            0.002211921149864793,
            0.03558049723505974,
            0.02421131357550621,
            -0.006885454058647156,
            -0.01097115408629179,
            0.024279678240418434,
            0.001925313612446189,
            -0.0013844837667420506,
            0.024078477174043655,
            0.038445115089416504,
            -0.043221365660429,
            -0.022663813084363937,
            0.028086895123124123,
            -0.023310521617531776,
            -0.012521518394351006,
            -0.026494158431887627,
            -0.005713464692234993,
            -0.007612472400069237,
            -0.0173414945602417,
            -0.013947645202279091,
            -0.00688562635332346,
            -0.013967794366180897,
            -0.0032341377809643745,
            -0.008728007785975933,
            -0.002819973975419998,
            -0.0006932155811227858,
            0.01622881181538105,
            0.02425001561641693,
            -0.052008386701345444,
            -0.031476620584726334,
            -0.020965244621038437,
            -0.0221098605543375,
            0.005319677758961916,
            -0.005274549126625061,
            0.00711304135620594,
            -0.0244935043156147,
            0.010609632357954979,
            -0.02054300159215927,
            0.0008953079232014716,
            0.03196903318166733,
            0.020787999033927917,
            0.016014140099287033,
            -0.040403854101896286,
            -0.017423436045646667,
            0.03511172905564308,
            -0.035873208194971085,
            -0.01291566900908947,
            0.025850750505924225,
            0.04562627896666527,
            -0.002318736631423235,
            -0.02158353477716446,
            0.00964449904859066,
            0.0328998938202858,
            -0.05731624737381935,
            0.01718604750931263,
            0.024150632321834564,
            0.010149677284061909,
            0.012109654024243355,
            -0.002792126964777708,
            0.017919782549142838,
            0.024341925978660583,
            0.028921185061335564,
            0.018401922658085823,
            -0.0016806439962238073,
            -0.04066876694560051,
            0.0383804552257061,
            -0.013624952174723148,
            -0.031112324446439743,
            0.0036705865059047937,
            -0.02921277843415737,
            -0.01743086613714695,
            -0.015632616356015205,
            -0.02277877740561962,
            0.06431834399700165,
            0.0037728387396782637,
            0.006811939645558596,
            -0.025729641318321228,
            -0.007605941966176033,
            0.017192132771015167,
            -0.004271212033927441,
            -0.02440127171576023,
            -0.00013986248814035207,
            -0.007009740918874741,
            0.014242946170270443,
            -0.015126699581742287,
            0.005143753718584776,
            -0.010846582241356373,
            0.043612610548734665,
            -0.0073622120544314384,
            0.021756023168563843,
            0.02208702079951763,
            -0.007136378437280655,
            -0.004582885652780533,
            -0.0242912620306015,
            0.005751628894358873,
            -0.018399275839328766,
            0.0007664906443096697,
            -0.06937017291784286,
            -0.010487479157745838,
            0.009043899364769459,
            0.020128939300775528,
            0.019788682460784912,
            -0.018120713531970978,
            0.008575845509767532,
            0.018323227763175964,
            -0.03427198529243469,
            -0.005680895876139402,
            -0.026988867670297623,
            -0.008664709515869617,
            -0.028767332434654236,
            0.025795605033636093,
            -0.02781921625137329,
            0.0220187921077013,
            -0.014533818699419498,
            0.01478051207959652,
            0.02550400421023369,
            -0.046807099133729935,
            -0.020673120394349098,
            0.034217528998851776,
            0.009094316512346268,
            -0.033928610384464264,
            -0.07066391408443451,
            0.009510955773293972,
            0.0121975252404809,
            0.015922024846076965,
            0.018922865390777588,
            -0.018039604648947716,
            0.03127533197402954,
            0.02240164764225483,
            -0.011602862738072872,
            0.024928735569119453,
            0.014349736273288727,
            -0.0011038982775062323,
            -0.015063997358083725,
            -0.0062122349627316,
            0.01386184897273779,
            -0.023531967774033546,
            0.02282830700278282,
            -0.008020853623747826,
            -0.019641520455479622,
            -0.003461774904280901,
            -0.014413020573556423,
            -0.016460653394460678,
            0.0362296886742115,
            0.015863297507166862,
            -0.027875715866684914,
            0.027867747470736504,
            -0.009806573390960693,
            -0.009663529694080353,
            -0.014684174209833145,
            0.025353282690048218,
            -0.02447061985731125,
            0.059103965759277344,
            0.004643041640520096,
            -0.009271509945392609,
            0.021139344200491905,
            0.0309444572776556,
            -0.020299110561609268,
            -0.018295468762516975,
            0.001472697826102376,
            -3.2064384868135676e-05,
            -0.015044484287500381,
            -0.013460066169500351,
            0.003171304240822792,
            -0.006519288755953312,
            0.015325034968554974,
            0.04212154448032379,
            -0.01774672046303749,
            -0.011186872608959675,
            0.014509799890220165,
            0.02942565456032753,
            0.017883101478219032,
            -0.015038058161735535,
            -0.03853515908122063,
            0.01625896990299225,
            -0.011810330674052238,
            -0.009384945034980774,
            0.03374064341187477,
            -0.02467666007578373,
            -0.022853296250104904,
            -0.02118517830967903,
            0.018344784155488014,
            -0.010604502633213997,
            0.04027989134192467,
            -0.020015018060803413,
            -0.028143983334302902,
            -0.0025307966861873865,
            0.00925302691757679,
            0.004091247450560331,
            0.007558015175163746,
            0.024344153702259064,
            -0.009522277861833572,
            -0.0009791586780920625,
            0.021523991599678993,
            0.004311535973101854,
            0.017711013555526733,
            -0.019976645708084106,
            -0.024007733911275864,
            -0.024564601480960846,
            0.017002319917082787,
            -0.01287414226680994,
            -0.02030751295387745,
            -0.013593723066151142,
            0.019814422354102135,
            0.0016839922172948718,
            -0.0225492212921381,
            0.019626803696155548,
            -0.005448989570140839,
            0.026947030797600746,
            -0.02574257180094719,
            0.03700294718146324,
            0.015050658956170082,
            0.018614063039422035,
            -0.0016272857319563627,
            0.02453521080315113,
            0.03236745670437813,
            7.2586874011904e-05,
            0.015034747309982777,
            0.024690518155694008,
            -0.016149278730154037,
            -0.0185638926923275,
            -0.006233153399080038,
            -0.019693497568368912,
            -0.03133412450551987,
            -0.027898522093892097,
            0.010761059820652008,
            -0.008146432228386402,
            0.03497796505689621,
            0.01962623931467533,
            -0.011981419287621975,
            0.019811855629086494,
            0.014010929502546787,
            0.039756592363119125,
            -0.0009112982079386711,
            -0.021092073991894722,
            0.029948076233267784,
            -0.002655352232977748,
            -0.07469795644283295,
            0.03341217711567879,
            -0.015851596370339394,
            0.039127711206674576,
            -0.0015079830773174763,
            -4.276293111615814e-05,
            -0.024489648640155792,
            -0.015465345233678818,
            -0.01323738694190979,
            0.004327576141804457,
            0.008080895058810711,
            0.009075953625142574,
            0.016148332506418228,
            0.016980452463030815,
            0.03974280506372452,
            -0.021096395328640938,
            -0.015030927024781704,
            -0.04153786227107048,
            0.014675960876047611,
            0.022763198241591454,
            -0.021668821573257446,
            0.01182585209608078,
            -0.014628684148192406,
            -0.011788306757807732,
            0.009407288394868374,
            0.008683985099196434
        ],
        [
            -0.032734986394643784,
            0.025672566145658493,
            0.006354838609695435,
            -0.031131785362958908,
            -0.02530910260975361,
            -0.03684714436531067,
            -0.009795509278774261,
            -0.015439096838235855,
            0.01843753457069397,
            0.0175541490316391,
            -0.03524358198046684,
            0.010358245112001896,
            -0.003523745108395815,
            -0.014705199748277664,
            -0.00565239368006587,
            -0.0006650141440331936,
            -0.003882803488522768,
            -0.0038473026361316442,
            0.0373200997710228,
            -0.013502451591193676,
            -0.019704733043909073,
            -0.0033266060054302216,
            0.03464601933956146,
            -0.00779819255694747,
            -0.005781938787549734,
            -0.021066939458251,
            -0.022187095135450363,
            0.0017006380949169397,
            -0.01431650947779417,
            0.01519139762967825,
            0.013683855533599854,
            0.0014160317368805408,
            0.025904780253767967,
            -0.02687752991914749,
            -0.007597717922180891,
            -0.061241697520017624,
            -0.008699080906808376,
            0.02148984745144844,
            0.011247738264501095,
            0.024457067251205444,
            0.030970921739935875,
            -0.018085431307554245,
            0.007479790132492781,
            0.0072472067549824715,
            0.002641696948558092,
            -0.010185010731220245,
            0.003982600290328264,
            0.03625836223363876,
            0.006784623023122549,
            0.02644837647676468,
            -0.007416117936372757,
            0.026070715859532356,
            0.04831777885556221,
            0.005872268229722977,
            0.012238913215696812,
            -0.029058951884508133,
            -0.003999602515250444,
            0.0032771904952824116,
            -0.029184123501181602,
            -0.0042197671718895435,
            -0.015166817232966423,
            0.004828446079045534,
            -0.00940620992332697,
            0.026494286954402924,
            -0.03080172650516033,
            0.01618826389312744,
            0.027293801307678223,
            -0.007010542321950197,
            -0.008836551569402218,
            -0.032771021127700806,
            -0.021706290543079376,
            0.0011186087504029274,
            -0.000575843034312129,
            -0.012215053662657738,
            0.007095412816852331,
            -0.001080840826034546,
            0.0027261092327535152,
            -0.02539057470858097,
            -0.01791059784591198,
            -0.030754148960113525,
            -0.010663106106221676,
            0.017693836241960526,
            -0.028644278645515442,
            0.022682594135403633,
            0.0031576899345964193,
            0.020462721586227417,
            0.023098552599549294,
            0.021087486296892166,
            0.0004615343059413135,
            -0.026906490325927734,
            -0.01106864120811224,
            0.013955926522612572,
            -0.020796239376068115,
            -0.03627117723226547,
            0.013912664726376534,
            0.025457341223955154,
            -0.00760953314602375,
            -0.029643867164850235,
            0.054584261029958725,
            -0.030706189572811127,
            0.010008125565946102,
            -0.01916266418993473,
            0.032187070697546005,
            0.01115709263831377,
            0.00858668889850378,
            -0.029135091230273247,
            -0.014483909122645855,
            -0.016684237867593765,
            0.004987617488950491,
            0.0033254099544137716,
            0.037931881844997406,
            0.011957498267292976,
            0.010878469794988632,
            -0.01119172852486372,
            -0.056247539818286896,
            -0.011828415095806122,
            0.03705035522580147,
            -0.03050985559821129,
            -0.04040614888072014,
            -0.020593643188476562,
            0.011910893023014069,
            0.031231479719281197,
            -0.08876722306013107,
            0.020165178924798965,
            -0.005491263698786497,
            0.002842131769284606,
            -0.0009509867522865534,
            -0.0012031069491058588,
            0.004765506833791733,
            0.03232515975832939,
            0.0034905432257801294,
            0.03731827810406685,
            0.010364672169089317,
            0.03270367905497551,
            0.00762032950296998,
            0.025256434455513954,
            0.015095129609107971,
            0.042503733187913895,
            -0.0275310967117548,
            0.012013096362352371,
            -0.008467323146760464,
            0.018396813422441483,
            0.004111412912607193,
            -0.025421621277928352,
            0.06617497652769089,
            -0.028841331601142883,
            0.04322892054915428,
            -0.02210545353591442,
            0.01822749711573124,
            0.04033865034580231,
            -0.009733399376273155,
            -0.03867027908563614,
            0.009111108258366585,
            0.009144588373601437,
            0.03695748746395111,
            0.005285885650664568,
            -0.03853550925850868,
            -0.027520887553691864,
            0.010453681461513042,
            -0.01011691428720951,
            0.02836894989013672,
            0.0156391728669405,
            0.020259512588381767,
            0.00433594873175025,
            -0.0025311694480478764,
            -0.041529733687639236,
            -0.039520833641290665,
            -0.028238002210855484,
            -0.017552049830555916,
            -0.015336509793996811,
            -0.014436386525630951,
            0.0257255882024765,
            -0.048132430762052536,
            0.030766507610678673,
            -0.004874999634921551,
            0.009773715399205685,
            -0.00915493257343769,
            0.013738867826759815,
            -0.024442894384264946,
            -0.042966827750205994,
            -0.007001263555139303,
            0.002264639362692833,
            0.010602185502648354,
            0.001172806485556066,
            -0.007820313796401024,
            0.00953288096934557,
            -0.02200084924697876,
            -0.028523270040750504,
            0.022634055465459824,
            -0.02618989534676075,
            0.01836034096777439,
            -0.03876570612192154,
            -0.0753408893942833,
            -0.020592866465449333,
            0.001411951845511794,
            -0.020738590508699417,
            0.0007223511929623783,
            -0.005584086291491985,
            -0.013300018385052681,
            0.006350076291710138,
            0.020031027495861053,
            -0.02147058956325054,
            -0.07229659706354141,
            0.00560337770730257,
            -0.024440893903374672,
            -0.029301881790161133,
            -0.014957042410969734,
            -0.006945210043340921,
            -0.017911866307258606,
            0.02169298194348812,
            0.005610627122223377,
            -0.040317874401807785,
            0.011180511675775051,
            -0.017442530021071434,
            0.015719251707196236,
            -0.03742481395602226,
            -0.012273620814085007,
            -0.024844296276569366,
            0.02600281313061714,
            -0.02787252515554428,
            0.02187807857990265,
            -0.007781790103763342,
            -0.025930501520633698,
            0.04043296352028847,
            -0.009179188869893551,
            0.05182948708534241,
            -0.01472659595310688,
            0.0014739366015419364,
            0.028561612591147423,
            0.018207088112831116,
            -0.0016466545639559627,
            0.03644118085503578,
            0.010558626614511013,
            -0.028579769656062126,
            -0.000478098401799798,
            -0.01667487435042858,
            -0.04058268293738365,
            0.011624577455222607,
            -0.012886091135442257,
            -0.027082987129688263,
            0.03438962996006012,
            0.021057408303022385,
            -0.004155175760388374,
            0.005686004180461168,
            0.030590323731303215,
            -0.011132919229567051,
            0.03598057106137276,
            -0.00040546825039200485,
            0.0026392266154289246,
            -0.02897774800658226,
            -0.012855338864028454,
            -0.01497479435056448,
            -0.036237988620996475,
            0.02111007273197174,
            -4.700176214100793e-05,
            0.010506163351237774,
            0.009197982028126717,
            0.02035227231681347,
            -0.04283738508820534,
            4.422206257004291e-05,
            0.01233822014182806,
            0.006408228538930416,
            -0.048717569559812546,
            -0.010372759774327278,
            -0.0030677521135658026,
            -0.028105447068810463,
            -0.021259915083646774,
            0.0040664225816726685,
            0.00768614374101162,
            -0.015114095993340015,
            0.0019869834650307894,
            -0.03738534450531006,
            0.028568070381879807,
            0.034244928508996964,
            -0.032946567982435226,
            0.012724432162940502,
            0.030163446441292763,
            0.004556091036647558,
            0.03386417403817177,
            0.0010518196504563093,
            0.005050180479884148,
            0.004187834914773703,
            0.015382536686956882,
            -0.009130637161433697,
            0.02478347159922123,
            -0.0007867122767493129,
            -0.03054705820977688,
            -0.02309367246925831,
            0.02380007691681385,
            0.018661923706531525,
            0.003734276397153735,
            -0.022725211456418037,
            0.03331764042377472,
            0.00596619164571166,
            -0.013153061270713806,
            0.03606990724802017,
            0.011678527109324932,
            -0.006131931208074093,
            -0.03871165215969086,
            -0.0029351022094488144,
            -0.0166066475212574,
            -0.003123808652162552,
            -0.0015874974196776748,
            0.004303413908928633,
            -0.02187315560877323,
            0.06959391385316849,
            0.015320909209549427,
            -0.032762039452791214,
            0.001203292515128851,
            0.014445007778704166,
            0.025082696229219437,
            0.033721376210451126,
            0.006350410170853138,
            -0.00902585219591856,
            -0.009545790031552315,
            -0.019363416358828545,
            -0.03353897109627724,
            -0.010585522279143333,
            -0.004330596886575222,
            0.03299345821142197,
            -0.004465778358280659,
            -0.0023751191329210997,
            0.024854620918631554,
            0.011104167439043522,
            0.010676734149456024,
            0.004927043803036213,
            0.0003053670807275921,
            -0.006031868513673544,
            0.022885410115122795,
            0.01832888275384903,
            0.012463905848562717,
            0.030949009582400322,
            -0.005172263365238905,
            -0.0049026599153876305,
            0.03305845707654953,
            -0.020707320421934128,
            -0.022801201790571213,
            0.03619496896862984,
            -0.025005638599395752,
            -0.018363013863563538,
            0.015329805202782154,
            0.012038057669997215,
            0.013730498030781746,
            0.02170603536069393,
            0.0219836737960577,
            0.023873604834079742,
            -0.03201146796345711,
            -0.033082641661167145,
            -0.014723951928317547,
            0.021128371357917786,
            -0.011803007684648037,
            -0.04347272589802742,
            0.026056092232465744,
            -0.0053735096007585526,
            -0.004081982187926769,
            0.023624634370207787,
            -0.019724145531654358,
            0.028164420276880264,
            -0.030225861817598343,
            -0.020175691694021225,
            0.017622610554099083,
            -0.019596491008996964,
            0.0220111683011055,
            -0.026014484465122223,
            0.007136384956538677,
            0.03284139558672905,
            -0.03382183611392975,
            0.029138391837477684,
            -0.009932860732078552,
            -0.05410562455654144,
            -0.03262165188789368,
            0.029940690845251083,
            0.010421899147331715,
            -0.016432367265224457,
            -0.025343529880046844,
            0.027128977701067924,
            0.004627559799700975,
            0.013524966314435005,
            -0.003465911839157343,
            0.01554630696773529,
            0.043753836303949356,
            -0.006577254738658667,
            -0.0006870672223158181,
            -0.01190631277859211,
            -0.024187136441469193,
            0.030097438022494316,
            0.016144273802638054,
            -0.04976993426680565,
            -0.015138966962695122,
            0.014587103389203548,
            -0.003111155703663826,
            0.01309004332870245,
            -0.02397468313574791,
            0.0029861421789973974,
            -0.0023542442359030247,
            0.01801774464547634,
            -0.02587815932929516,
            0.02930145524442196,
            -0.002658052369952202,
            -0.007880030199885368,
            0.0066490513272583485,
            -0.026647701859474182,
            -0.027440594509243965,
            -0.019345534965395927,
            -0.006047545466572046,
            0.02455284260213375,
            0.02371317520737648,
            -0.01620018295943737,
            -0.008260679431259632,
            0.010961021296679974,
            -0.008440729230642319,
            0.022107385098934174,
            -0.021692367270588875,
            -0.018799902871251106,
            -0.03055690787732601,
            -0.02696000225841999,
            0.002985734259709716,
            0.000603020831476897,
            -0.012457091361284256,
            0.024959955364465714,
            0.06346806138753891,
            0.012074108235538006,
            0.010761087760329247,
            0.006007224787026644,
            -0.0002228243974968791,
            -0.00288013624958694,
            -0.037815820425748825,
            0.026463191956281662,
            -0.02033207006752491,
            -0.04552042856812477,
            -0.03398127853870392,
            0.004334197845309973,
            -0.00861205905675888,
            0.05624483898282051,
            0.026089729741215706,
            0.009620456956326962,
            0.01633439026772976,
            -0.037941548973321915,
            0.0057395705953240395,
            0.02202085591852665,
            -0.012485058046877384,
            -0.019472861662507057,
            -0.0037093330174684525,
            -0.03805660083889961,
            0.01992729865014553,
            0.004981970880180597,
            -0.0043067485094070435,
            -0.05996772274374962,
            0.014615296386182308,
            -0.006722697522491217,
            -0.01328337099403143,
            -0.01921856962144375,
            -0.044242702424526215,
            0.007219093851745129,
            -0.005322061013430357,
            0.019663486629724503,
            0.04429274797439575,
            -0.022625811398029327,
            0.025457724928855896,
            -0.006763582583516836,
            0.004781392868608236,
            0.005261891055852175,
            -0.0330340638756752,
            0.013775182887911797,
            -0.010355029255151749,
            0.02273179404437542,
            -0.010949219577014446,
            0.00645291106775403,
            -0.018424751237034798,
            -0.02480356954038143,
            -0.005855575203895569,
            0.020050136372447014,
            0.026712819933891296,
            -0.027164163067936897,
            0.004290164448320866,
            -0.04138469323515892,
            0.04568450152873993,
            0.01921803504228592,
            0.012811754830181599,
            -0.0002913449425250292,
            -0.023019937798380852,
            -0.004601234570145607,
            -0.053103186190128326,
            0.024048857390880585,
            -0.0025825747288763523,
            -0.005211646668612957,
            0.029613714665174484,
            -0.010834852233529091,
            -0.0777769684791565,
            -0.02056073397397995,
            -0.037742968648672104,
            0.022023944184184074,
            -0.005305527709424496,
            0.010626418516039848,
            0.03156614303588867,
            0.017233671620488167,
            -0.011506197042763233,
            -0.03088371828198433,
            0.019214430823922157,
            -0.012007112614810467,
            0.038308948278427124,
            0.008360356092453003,
            -0.0239142756909132,
            0.030359536409378052,
            0.010334838181734085,
            -0.017449427396059036,
            0.025273628532886505,
            -0.01681838557124138,
            -0.020041806623339653,
            -0.016789700835943222,
            -0.011643070727586746,
            0.0022585429251194,
            -0.010494262911379337,
            -0.009887179359793663,
            0.028683843091130257,
            -0.004204160068184137,
            0.002739597810432315,
            0.018463507294654846,
            -0.011475750245153904,
            -0.019329460337758064,
            -0.028355540707707405,
            0.03211684897542,
            -0.0016475837910547853,
            0.001214394229464233,
            -0.05199417099356651,
            -0.02656196430325508,
            0.009812992997467518,
            0.018422676250338554,
            0.0414973646402359,
            -0.01849827542901039,
            -0.006463790778070688,
            0.041590504348278046,
            -0.03617845103144646,
            0.008062184788286686,
            0.007560310885310173,
            -0.030297989025712013,
            0.013018800877034664,
            0.008064373396337032,
            0.010210596024990082,
            -0.004342780448496342,
            -0.00785579439252615,
            0.02777205966413021,
            -0.021225443109869957,
            0.03421618044376373,
            -0.006613734643906355,
            0.021372782066464424,
            0.018664218485355377,
            -0.050964463502168655,
            -0.011641347780823708,
            0.008807260543107986,
            -0.020554156973958015,
            0.01398883294314146,
            -0.026210522279143333,
            -0.015978345647454262,
            -0.00992334820330143,
            0.015971696004271507,
            0.009756123647093773,
            -0.004264781717211008,
            0.007169201970100403,
            -0.010256101377308369,
            -0.03256519138813019,
            0.016374729573726654,
            -0.0005590717773884535,
            0.022215060889720917,
            0.0027565588243305683,
            0.016692034900188446,
            -0.03953288495540619,
            -0.002399568213149905,
            -0.003472057171165943,
            0.015942374244332314,
            0.03236323967576027,
            0.004650659393519163,
            0.00023088745365384966,
            -0.013376427814364433,
            0.034466564655303955,
            0.0056740036234259605,
            0.019873714074492455,
            -0.005692705046385527,
            -0.03540651127696037,
            0.016425108537077904,
            0.026054218411445618,
            -0.022798815742135048,
            0.027938390150666237,
            0.012705489061772823,
            7.754240505164489e-05,
            0.011762013658881187,
            0.023768456652760506,
            0.003367452882230282,
            -0.009521763771772385,
            -0.029413606971502304,
            0.005920478142797947,
            0.013189245015382767,
            -0.0087873674929142,
            0.037891075015068054,
            -0.0006671369774267077,
            -0.029609128832817078,
            -0.0087647195905447,
            -0.007892904803156853,
            0.03614280745387077,
            0.037150654941797256,
            -0.0489288829267025,
            -0.03330317884683609,
            -0.006337038706988096,
            0.032950352877378464,
            -0.014231045730412006,
            0.0023119922261685133,
            0.012343286536633968,
            0.010323720052838326,
            -0.01599833182990551,
            0.034725621342659,
            0.027154533192515373,
            0.03166678920388222,
            -0.029794657602906227,
            0.012934639118611813,
            -0.01837988756597042,
            -0.022021818906068802,
            -0.039624180644750595,
            -0.013070020824670792,
            0.022675829008221626,
            -0.03819319233298302,
            -0.04268006607890129,
            0.03189742565155029,
            0.024750985205173492,
            0.022612782195210457,
            -0.0021399289835244417,
            -0.02721777744591236,
            -0.024208463728427887,
            -0.021589303389191628,
            0.029117336496710777,
            -0.031423650681972504,
            -0.01468433253467083,
            0.015072036534547806,
            -0.010016294196248055,
            0.006650225725024939,
            0.01850128546357155,
            0.010992207564413548,
            0.016242392361164093,
            0.03965798765420914,
            0.029959777370095253,
            -0.016210563480854034,
            -0.01462672557681799,
            -0.0178688857704401,
            -0.02334458753466606,
            0.058111533522605896,
            -0.020621564239263535,
            -0.03035302273929119,
            0.019435197114944458,
            0.018825583159923553,
            -0.010248555801808834,
            -0.031203459948301315,
            0.026596643030643463,
            0.035829097032547,
            -0.019330332055687904,
            0.014368996024131775,
            -0.009824123233556747,
            0.0297551229596138,
            -0.005367639474570751,
            -0.0206986665725708,
            0.02135574072599411,
            0.02492690086364746,
            -0.028157755732536316,
            0.029308080673217773,
            -0.007849019952118397,
            -0.022059444338083267,
            -0.006152774207293987,
            -0.0060665300115942955,
            -0.007469747681170702,
            0.014324192889034748,
            -0.012623779475688934,
            -0.025691088289022446,
            0.00047836743760854006,
            -0.03302007541060448,
            0.018069863319396973,
            -0.0019218230154365301,
            0.03444686904549599,
            -0.02263968624174595,
            -0.0325876921415329,
            -0.01232251338660717,
            -0.019916797056794167,
            -0.019974946975708008,
            -0.011940025724470615,
            -0.0014675094280391932,
            -0.02600867673754692,
            -0.005714041646569967,
            0.023281175643205643,
            -0.0164030771702528,
            -0.04050306975841522,
            -0.03579530492424965,
            0.011044583283364773,
            0.0011757747270166874,
            0.017694707959890366,
            -0.049200695008039474,
            0.010291491635143757,
            -0.015741214156150818,
            0.005123690702021122,
            0.008168811909854412,
            -0.0019161825766786933,
            -0.0034718532115221024,
            0.013196365907788277,
            0.017098775133490562,
            0.020503686740994453,
            -0.036083005368709564,
            -0.005593710113316774,
            0.013558306731283665,
            0.029186563566327095,
            -0.02337948977947235,
            0.01419066358357668,
            0.032289642840623856,
            -0.02657766453921795,
            -0.005955463275313377,
            0.03109230101108551,
            -0.004941582679748535,
            -0.05449367314577103,
            -0.030713286250829697,
            0.018963374197483063,
            -0.012478915974497795,
            -0.0030617222655564547,
            0.01139067206531763,
            -0.03278005123138428,
            0.025137044489383698,
            0.007825789041817188,
            -0.00737837515771389,
            -0.026949681341648102,
            -0.022362347692251205,
            -0.004248181823641062,
            -0.030896250158548355,
            0.004965071566402912,
            -0.0028396949637681246,
            -0.013732606545090675,
            0.034377094358205795,
            0.028990086168050766,
            0.03200267627835274,
            0.0022611282765865326,
            0.023331232368946075,
            -0.008243856951594353,
            -0.012632966041564941,
            0.004734105430543423,
            0.008729876019060612,
            0.02552454173564911,
            0.02624165266752243,
            0.018735436722636223,
            -0.019633205607533455,
            -0.03786228597164154,
            -0.03381648659706116,
            0.019390111789107323,
            0.014501862227916718,
            -0.03340392932295799,
            0.03175807744264603,
            -0.02417934313416481,
            0.02813565544784069,
            0.02961583435535431,
            0.001654941588640213,
            -0.02075612172484398,
            0.010514280758798122,
            0.016042444854974747,
            0.027692299336194992,
            -0.013099524192512035,
            0.04745464399456978,
            -0.03266191482543945,
            0.027932027354836464,
            -0.028652045875787735,
            -0.01466513704508543,
            0.01857604645192623,
            -0.030324751511216164,
            -0.021121811121702194,
            -0.020069485530257225,
            0.015480040572583675,
            -0.031134603545069695,
            -0.003653447376564145,
            -0.007074357476085424,
            0.002943608211353421,
            0.024107322096824646,
            0.03793356567621231,
            -0.014312774874269962,
            0.017311060801148415,
            0.03945336863398552,
            -0.07284699380397797,
            -0.0005941988783888519,
            0.016826769337058067,
            -0.009587306529283524,
            -0.04791657254099846,
            -0.0072692628018558025,
            0.033620525151491165,
            -0.011921119876205921,
            -0.014634433202445507,
            0.02311464212834835,
            0.017325375229120255,
            0.013712795451283455,
            0.011082801967859268,
            0.03897707909345627,
            -0.040790412575006485,
            0.01775990054011345,
            0.022280240431427956,
            -0.04067153111100197,
            -0.009961796924471855,
            -0.02249610796570778,
            0.03721937909722328,
            0.011832029558718204,
            0.021454231813549995,
            -0.027294201776385307,
            0.014333399944007397,
            0.046378519386053085,
            0.0056875054724514484,
            0.029085246846079826,
            -0.09190447628498077,
            -0.025189178064465523,
            -0.006822149269282818,
            -0.0018981489120051265,
            0.004898405633866787,
            -0.029714500531554222,
            0.03345761448144913,
            0.03796752169728279,
            0.03088967688381672,
            0.01863923668861389,
            -0.05947159230709076,
            0.012035115621984005,
            -0.011218370869755745,
            -0.032152675092220306,
            -0.020154474303126335,
            0.010431100614368916,
            -0.0057642641477286816,
            -0.040826573967933655,
            0.002694780705496669,
            0.0009636496542952955,
            -0.02077161893248558,
            0.04181328043341637,
            0.007553044240921736,
            -0.0003031055966857821,
            -0.01629721187055111,
            0.02374487929046154,
            0.030408766120672226,
            -0.02054482512176037,
            -0.04457709565758705,
            -0.03893503546714783,
            -0.020371869206428528,
            -0.007380662951618433,
            0.025215407833456993,
            -0.021161800250411034,
            -0.014174308627843857,
            -0.034875668585300446,
            -0.025461995974183083,
            -0.025360582396388054,
            0.0040460145100951195,
            0.011464462615549564,
            -0.0038901178631931543,
            -0.029580052942037582,
            0.033563658595085144,
            0.008218009024858475,
            0.0227312371134758,
            -0.01083446480333805,
            -0.026695597916841507,
            -0.012569854035973549,
            0.04993527755141258,
            -0.01617305353283882,
            0.002820919966325164,
            -0.013448934070765972,
            0.01217200979590416,
            0.013214310631155968,
            -0.023485032841563225,
            -0.0017477471847087145,
            0.03377791866660118,
            -0.010971203446388245,
            0.02079898677766323,
            0.023287078365683556,
            -0.00024555964046157897,
            -0.004109016619622707,
            0.004860206041485071,
            -0.0024725182447582483,
            0.015865061432123184,
            -0.0344887301325798,
            0.05249514430761337,
            0.03183305263519287,
            -0.02664013020694256,
            0.003458833321928978,
            0.022566845640540123,
            0.034628696739673615,
            0.021235788241028786,
            -0.06473405659198761,
            0.014294194057583809,
            0.0065196543000638485,
            -0.007624714635312557,
            -0.001952526392415166,
            -0.04462399333715439,
            0.012438075616955757,
            -0.03633171319961548,
            0.0039286017417907715,
            0.010472279042005539,
            0.03642457723617554,
            -0.025210430845618248,
            0.02194744162261486,
            0.004215415101498365,
            -0.005688014440238476,
            -0.0021472997032105923,
            -0.01598947122693062,
            0.024526195600628853,
            -0.010495505295693874,
            -0.02908771112561226,
            0.022720709443092346,
            0.007584911305457354,
            -0.0004086410626769066,
            0.061523254960775375,
            0.02323971502482891,
            0.012879196554422379,
            0.025547852739691734,
            0.03626854717731476,
            0.023847734555602074,
            -0.055709194391965866,
            -0.0021918718703091145,
            -0.0019136691698804498,
            0.0008909528842195868,
            0.03964535892009735,
            -0.021558422595262527,
            0.031173046678304672,
            -0.02299555018544197,
            -0.035888463258743286,
            -0.005380913615226746,
            0.021077727898955345,
            0.026388563215732574,
            0.011319879442453384,
            -0.007936391048133373,
            -0.030611833557486534,
            0.03376657888293266,
            -0.006409103516489267,
            0.021077176555991173,
            -0.05558767542243004,
            0.0041900333017110825,
            -0.0151343559846282,
            -0.028058618307113647,
            0.02907123975455761,
            0.014904570765793324,
            0.006712101399898529,
            -0.007281174883246422,
            0.0010259882546961308,
            0.017240308225154877,
            -0.037134356796741486,
            0.003303111530840397,
            -0.0060209729708731174,
            0.014876731671392918,
            -0.00795027706772089,
            -0.0221404992043972,
            0.0014150666538625956,
            -0.01737285777926445,
            -0.040326155722141266,
            0.0036410284228622913,
            0.020879162475466728,
            -0.022356417030096054,
            0.013050932437181473,
            0.015067399479448795,
            0.027257977053523064,
            0.009825196117162704,
            -0.003208065638318658,
            0.024131737649440765,
            -0.012059326283633709,
            -0.011841531842947006,
            0.02626369334757328,
            0.03039083071053028,
            0.011008178815245628,
            -0.020464492961764336,
            -0.007340926676988602,
            -0.03332635387778282,
            0.0071640899404883385,
            0.014974299818277359,
            0.014024442993104458,
            0.014759600162506104,
            -0.00944218784570694,
            0.041983410716056824,
            -0.058490052819252014,
            -0.0015570441028103232,
            -0.023338831961154938,
            -0.007858523167669773,
            -0.0160687156021595,
            0.003918415401130915,
            0.016269441694021225,
            0.0004217351379338652,
            -0.0029697190038859844,
            0.030639290809631348,
            -0.03621525317430496,
            -0.0005429043667390943,
            0.029134826734662056,
            -0.02101029083132744,
            -0.07241946458816528,
            0.02605101652443409,
            0.06638947874307632,
            0.016759401187300682,
            0.00465389946475625,
            -0.009904085658490658,
            0.0028780708089470863,
            0.015228640288114548,
            0.002571364166215062,
            -0.006609938107430935,
            0.00017103392747230828,
            0.018674211576581,
            -0.03111834079027176,
            -0.00917067937552929,
            -0.02685917541384697,
            -0.017604772001504898,
            -0.021209081634879112,
            0.02641533501446247,
            0.007839999161660671,
            0.01309602614492178,
            -0.01757323555648327,
            -0.0027946250047534704,
            -0.005022739060223103,
            -0.011120003648102283,
            -0.020376034080982208,
            0.0066809626296162605,
            -0.0010260582203045487,
            0.04006155952811241,
            0.026594247668981552,
            0.021459195762872696,
            -0.014981463551521301,
            -0.02432108111679554,
            0.013187727890908718,
            0.009414208121597767,
            0.035184893757104874,
            -0.048841506242752075,
            -0.02363770082592964,
            0.02026335895061493,
            0.034160640090703964,
            0.026867346838116646,
            -0.025322312489151955,
            0.03655993193387985,
            0.029324423521757126,
            0.018366238102316856
        ],
        [
            0.006510412320494652,
            0.03334302082657814,
            0.01991107128560543,
            -0.009165510535240173,
            -0.03219687566161156,
            -0.004499020986258984,
            0.004100677091628313,
            0.0065110111609101295,
            -0.009608202613890171,
            0.012861033901572227,
            0.002318502403795719,
            0.033574119210243225,
            -0.011548859998583794,
            0.025038229301571846,
            0.07104302197694778,
            -0.03309616073966026,
            0.0655069425702095,
            -0.016472958028316498,
            0.02305268868803978,
            -0.020183434709906578,
            0.02677016146481037,
            0.015077364630997181,
            -0.01823585294187069,
            -0.004020810592919588,
            0.06285004317760468,
            -0.016102269291877747,
            -0.004964877385646105,
            0.025075996294617653,
            0.0064327530562877655,
            -0.009487871080636978,
            0.024753089994192123,
            0.019553177058696747,
            -0.0428461991250515,
            -0.009961361065506935,
            -0.021263813599944115,
            0.013906060717999935,
            0.013072041794657707,
            0.014543183147907257,
            0.007463911082595587,
            0.012577472254633904,
            -0.030418766662478447,
            -0.04420754685997963,
            -0.008798412047326565,
            -0.027436597272753716,
            0.05328986048698425,
            0.016066674143075943,
            0.021547161042690277,
            0.03631846606731415,
            -0.004374078009277582,
            -0.05289531871676445,
            0.015532128512859344,
            -0.02053375355899334,
            -0.0536554716527462,
            -0.028255579993128777,
            0.0002448792802169919,
            0.0031175040639936924,
            0.016834864392876625,
            0.0036805805284529924,
            0.03795613721013069,
            -0.008634328842163086,
            0.024757185950875282,
            0.017586030066013336,
            -0.029006680473685265,
            -0.0017617841949686408,
            0.03993231803178787,
            0.003944943193346262,
            0.016663402318954468,
            -0.009089563973248005,
            0.009754293598234653,
            0.027760865166783333,
            0.03288407623767853,
            -0.005459449253976345,
            0.0062043871730566025,
            -0.012972300872206688,
            -0.00847978238016367,
            -0.04954001307487488,
            0.04682978615164757,
            -0.021776240319013596,
            -0.03279559314250946,
            -0.0025692752096801996,
            -0.011958993971347809,
            -0.016090212389826775,
            0.0028346525505185127,
            0.0009658343042246997,
            -0.009677727706730366,
            -0.016563985496759415,
            0.011492705903947353,
            -0.043723899871110916,
            0.034346502274274826,
            0.03032657504081726,
            -0.013583969324827194,
            0.0027324482798576355,
            -0.023319615051150322,
            -0.010330643504858017,
            0.0038415000308305025,
            -0.017772186547517776,
            0.029541371390223503,
            0.013551685959100723,
            -0.017313452437520027,
            0.016483202576637268,
            0.0172900278121233,
            0.048783037811517715,
            -0.002028894843533635,
            0.012493839487433434,
            -0.01480225007981062,
            0.025060269981622696,
            -0.012736121192574501,
            -0.01199460681527853,
            0.011525294743478298,
            -0.013856119476258755,
            -0.000914613890927285,
            0.053648944944143295,
            0.013867568224668503,
            -0.0036148710642009974,
            -0.055530648678541183,
            -0.009125943295657635,
            4.4027001422364265e-05,
            0.01918378472328186,
            0.016420330852270126,
            0.010165276005864143,
            0.013964304700493813,
            0.00989410001784563,
            0.06883470714092255,
            0.011674041859805584,
            -0.01739218458533287,
            0.017406020313501358,
            -0.02411188744008541,
            -0.025693334639072418,
            0.027083784341812134,
            -0.004051863681524992,
            0.018511133268475533,
            -0.005024860613048077,
            0.07492782920598984,
            -0.00012079141015419737,
            0.018740810453891754,
            -0.022283846512436867,
            -0.03443950042128563,
            0.02801831066608429,
            -0.0007176863146014512,
            0.005213067401200533,
            0.000821281922981143,
            -0.02014986053109169,
            -0.021206606179475784,
            -0.0008691378170624375,
            -0.002917604986578226,
            0.019454726949334145,
            0.002778009045869112,
            0.044008102267980576,
            0.008236992172896862,
            -0.0008334649028256536,
            0.024904318153858185,
            0.01951165311038494,
            -0.01644941233098507,
            0.004112533293664455,
            0.010550327599048615,
            -0.007943232543766499,
            0.024761930108070374,
            -0.005639101844280958,
            -0.01902877725660801,
            0.013592439703643322,
            0.01705564372241497,
            0.027186112478375435,
            -0.028012791648507118,
            0.01827990636229515,
            -0.036704957485198975,
            0.05558396130800247,
            0.02533561922609806,
            -0.0006876420811749995,
            -0.0031710334587842226,
            -0.037969354540109634,
            -0.04108564928174019,
            0.00029946787981316447,
            -0.03460107743740082,
            -0.018012411892414093,
            0.010240123607218266,
            0.028469378128647804,
            0.017109712585806847,
            -0.018404049798846245,
            -0.010916714556515217,
            0.041380736976861954,
            -0.010988380759954453,
            0.0026972077321261168,
            -0.007711237296462059,
            -0.024507420137524605,
            0.011026323772966862,
            -0.01869744248688221,
            0.0033727746922522783,
            0.012175913900136948,
            0.00806310586631298,
            -0.0005558191332966089,
            0.011992896907031536,
            0.013505473732948303,
            0.006817861460149288,
            -0.02169661968946457,
            0.01425838004797697,
            -0.02199852652847767,
            0.011293502524495125,
            0.006536995992064476,
            -0.008243530057370663,
            -0.03087271749973297,
            0.005623819772154093,
            -0.024481868371367455,
            -0.0029416754841804504,
            -0.02000833861529827,
            0.017480647191405296,
            0.046936966478824615,
            -0.021973777562379837,
            0.05030033364892006,
            0.0011934133945032954,
            -0.014163482934236526,
            0.016383830457925797,
            -0.013664503581821918,
            0.00042231459519825876,
            0.012768334709107876,
            0.03488241508603096,
            0.010975997895002365,
            -0.008876869454979897,
            0.008643785491585732,
            0.008685700595378876,
            0.03181430324912071,
            -0.016257820650935173,
            0.011892365291714668,
            -0.006631825584918261,
            0.014873695559799671,
            -0.01309688575565815,
            0.0330754853785038,
            -0.04490199312567711,
            -0.008991868235170841,
            -0.015437725000083447,
            -0.011590040288865566,
            0.035370707511901855,
            -0.012744789011776447,
            -0.036939818412065506,
            -0.009729643352329731,
            0.05772927403450012,
            -0.032592691481113434,
            0.0005574444076046348,
            0.009074919857084751,
            -0.017827391624450684,
            -0.024410275742411613,
            -0.014730299822986126,
            0.002384640509262681,
            -0.007618979550898075,
            -0.01167505793273449,
            -0.027062254026532173,
            0.029351506382226944,
            -0.0027894224040210247,
            -0.01935911737382412,
            -0.04488704353570938,
            0.02278890833258629,
            -0.011150157079100609,
            -0.014245823957026005,
            0.014630865305662155,
            0.011540359817445278,
            -0.004047578200697899,
            -0.01474373321980238,
            -0.005409990902990103,
            -0.04092947766184807,
            0.022334732115268707,
            0.02255227603018284,
            0.012343764305114746,
            0.020516132935881615,
            -0.026446223258972168,
            -0.0009300310630351305,
            0.046633388847112656,
            0.02626202628016472,
            0.005149495787918568,
            0.0011143145384266973,
            0.00367474602535367,
            -0.037229545414447784,
            0.02953561581671238,
            -0.001339329988695681,
            1.0757723430288024e-05,
            0.014899808913469315,
            -0.02776922471821308,
            0.011776899918913841,
            0.0031412953976541758,
            0.005060033407062292,
            0.029627719894051552,
            -0.011001047678291798,
            0.008895826525986195,
            0.02528773434460163,
            0.006380393169820309,
            0.019866742193698883,
            -0.03254212439060211,
            0.012747467495501041,
            -0.05298732966184616,
            0.02690979652106762,
            -0.008827446959912777,
            0.03991745039820671,
            -0.00394084956496954,
            -0.004547682590782642,
            0.021713459864258766,
            0.013695908710360527,
            0.007344581186771393,
            0.0009953165426850319,
            -0.00245230202563107,
            0.030361976474523544,
            0.02531423419713974,
            0.0283382385969162,
            0.014648852869868279,
            -0.0030357432551681995,
            -0.017187317833304405,
            -0.03139924257993698,
            -0.013595003634691238,
            -0.04821556806564331,
            0.02879362367093563,
            0.050535671412944794,
            0.05090311914682388,
            -0.02717667445540428,
            0.020831502974033356,
            -0.028421105816960335,
            -0.027777573093771935,
            0.008428231813013554,
            0.004302155692130327,
            0.010325689800083637,
            -0.013950228691101074,
            -0.021715398877859116,
            -0.013508150354027748,
            -0.039727527648210526,
            0.02255469746887684,
            0.0029250490479171276,
            -0.07092423737049103,
            0.029122384265065193,
            -0.0009734558407217264,
            0.029910877346992493,
            -0.02241486869752407,
            0.025335630401968956,
            -0.011961853131651878,
            -0.0012422994477674365,
            0.011089751496911049,
            -0.03033941425383091,
            0.045029640197753906,
            -0.023052338510751724,
            -0.02634149231016636,
            -0.023819977417588234,
            0.003965088166296482,
            -0.01464003510773182,
            -0.009973183274269104,
            0.014879372902214527,
            -0.025977592915296555,
            0.044951893389225006,
            -0.02986186556518078,
            -0.03183932974934578,
            0.015424720011651516,
            0.01833968423306942,
            -0.0100698908790946,
            -0.00694570317864418,
            -0.018191343173384666,
            0.03243374451994896,
            0.018166758120059967,
            0.010484486818313599,
            -0.012247277423739433,
            -0.03162267804145813,
            0.03740030899643898,
            -0.006769320461899042,
            -0.013660060241818428,
            -0.015191051177680492,
            0.020596647635102272,
            0.046770162880420685,
            0.023808246478438377,
            0.013776176609098911,
            0.06673678010702133,
            -0.02417183481156826,
            -0.02549215406179428,
            -0.005066916346549988,
            0.015247510746121407,
            -0.0035967635922133923,
            0.002869880525395274,
            0.012580929324030876,
            -0.049586739391088486,
            0.023486409336328506,
            -0.02790914662182331,
            0.0112910782918334,
            -0.019070282578468323,
            0.03393884375691414,
            0.017331529408693314,
            -0.021857619285583496,
            0.013944339007139206,
            0.002754432149231434,
            0.007472435478121042,
            -0.036645833402872086,
            -0.0090161869302392,
            0.015041647478938103,
            0.06963692605495453,
            -0.023332105949521065,
            0.01332936156541109,
            0.006869905162602663,
            -0.022436631843447685,
            -0.03590897470712662,
            -0.005245737265795469,
            0.03987786918878555,
            0.031929872930049896,
            -0.01086470577865839,
            0.0485624223947525,
            -0.020513707771897316,
            -0.03474986553192139,
            0.043001431971788406,
            -0.0258609838783741,
            0.028080079704523087,
            0.04800236597657204,
            0.03198298439383507,
            0.0110460314899683,
            -0.0070172566920518875,
            0.012159253470599651,
            -0.004861597437411547,
            0.030655302107334137,
            -0.008855841122567654,
            -0.009854847565293312,
            -0.02351805940270424,
            0.04064426198601723,
            0.014411860145628452,
            -0.028091471642255783,
            -0.019702132791280746,
            0.006680919323116541,
            0.035818491131067276,
            -0.027052538469433784,
            -0.06815200299024582,
            0.014226329512894154,
            0.0062537966296076775,
            0.005762363784015179,
            -0.049141060560941696,
            0.008088911883533001,
            0.015225404873490334,
            0.0465758852660656,
            0.05061216279864311,
            -0.013745661824941635,
            0.01725979894399643,
            0.02035951055586338,
            -0.0009272514726035297,
            -0.02877201698720455,
            0.014059855602681637,
            -0.011773094534873962,
            -0.004275675863027573,
            0.0235601756721735,
            -0.005255896598100662,
            -0.01182601135224104,
            -0.0014533589128404856,
            0.0009558432502672076,
            0.03455372527241707,
            0.049754492938518524,
            -0.003247901564463973,
            0.014094667509198189,
            0.02575613185763359,
            -0.0006356747471727431,
            -0.0030787477735430002,
            0.004847156349569559,
            -0.08126972615718842,
            -0.026863185688853264,
            -0.010624944232404232,
            0.04068966582417488,
            0.01532280258834362,
            -0.007110567297786474,
            0.01267923042178154,
            -0.03128159046173096,
            -0.038656044751405716,
            0.0092603275552392,
            -0.03465697914361954,
            0.015239857137203217,
            -0.028492208570241928,
            -0.012699390761554241,
            -0.014875882305204868,
            0.026360532268881798,
            0.03322220593690872,
            0.0007842298364266753,
            -0.0016890589613467455,
            0.043306607753038406,
            -0.018936609849333763,
            -0.015593647956848145,
            -0.006424494553357363,
            0.004664580803364515,
            0.0030166804790496826,
            0.004207246005535126,
            -0.0003648338024504483,
            -0.03207589313387871,
            0.002006946597248316,
            0.018108002841472626,
            0.018037309870123863,
            -0.006383602973073721,
            0.01337801106274128,
            0.03707578778266907,
            0.047119494527578354,
            0.015979472547769547,
            0.0069211446680128574,
            0.01419897098094225,
            -0.03253963962197304,
            -0.020817065611481667,
            -0.02586730569601059,
            0.06486427783966064,
            -0.0076963407918810844,
            -0.010270235128700733,
            0.008975791744887829,
            -0.03744840249419212,
            0.004761135671287775,
            -0.06033200025558472,
            0.022395921871066093,
            0.010775669477880001,
            -0.0012834571534767747,
            0.01275057252496481,
            0.004008847288787365,
            0.0238178838044405,
            -0.004795263521373272,
            -0.02283669076859951,
            0.037693530321121216,
            0.008678683079779148,
            -0.016160203143954277,
            -0.02276446670293808,
            0.006089715752750635,
            -0.007724745199084282,
            0.005869239568710327,
            -0.004892859607934952,
            0.04293866455554962,
            0.012768442742526531,
            -0.002340873470529914,
            0.0008247965597547591,
            -0.03758872300386429,
            -0.009732476435601711,
            0.016709687188267708,
            0.021666131913661957,
            0.011579824611544609,
            0.025458281859755516,
            0.021837541833519936,
            -0.015132236294448376,
            -0.020523808896541595,
            -0.04408661648631096,
            0.04463019222021103,
            -0.05673652142286301,
            0.022194430232048035,
            0.004912997595965862,
            0.005225598346441984,
            -0.030141061171889305,
            0.010147642344236374,
            0.01480121910572052,
            0.015300788916647434,
            -0.017044920474290848,
            0.07195185124874115,
            -0.01203342154622078,
            -0.013034851290285587,
            0.02341778576374054,
            0.002094046212732792,
            0.003376190084964037,
            0.025528518483042717,
            -0.03241138532757759,
            -0.025578737258911133,
            -0.000492280232720077,
            0.035460710525512695,
            -0.023562096059322357,
            0.039973556995391846,
            0.007962203584611416,
            -0.020158860832452774,
            -0.015735028311610222,
            -0.00445302901789546,
            0.0009450126672163606,
            0.022454015910625458,
            -0.007627423852682114,
            -0.028214076533913612,
            -0.001960752299055457,
            -0.0037699518725275993,
            0.010177629999816418,
            -0.02777274325489998,
            -0.012426771223545074,
            0.02653917856514454,
            0.007455991581082344,
            0.048890553414821625,
            -0.03413916006684303,
            0.034609466791152954,
            0.004153902176767588,
            -0.033193398267030716,
            0.01556878536939621,
            0.0010067729745060205,
            -0.011113411746919155,
            0.02332325093448162,
            -0.0396961010992527,
            0.012689336203038692,
            0.0016101348446682096,
            -0.02764010988175869,
            0.0024380118120461702,
            -0.002711160574108362,
            0.014108296483755112,
            -0.015484744682908058,
            0.009650749154388905,
            0.00986435916274786,
            -0.02759495936334133,
            -0.01895136758685112,
            -0.02366493083536625,
            -0.006433311849832535,
            0.016768693923950195,
            0.020746929571032524,
            0.022278066724538803,
            -0.014387055300176144,
            0.02225002460181713,
            0.04519204422831535,
            0.004823047202080488,
            0.02034035138785839,
            -0.009979997761547565,
            0.02323499321937561,
            -0.0020902222022414207,
            -0.014157278463244438,
            -0.025771791115403175,
            0.013235704973340034,
            0.05599580705165863,
            -0.023315081372857094,
            -0.0775185152888298,
            -0.0018863353179767728,
            0.024629861116409302,
            0.04161817580461502,
            -0.02549820952117443,
            0.013762988150119781,
            0.04874848201870918,
            0.0238352008163929,
            0.011871264316141605,
            -0.0017521751578897238,
            -0.01875046081840992,
            -0.02365838922560215,
            -0.013673229143023491,
            -0.041361041367053986,
            0.00042708756518550217,
            0.0067833648063242435,
            0.022811459377408028,
            0.040014468133449554,
            0.013626926578581333,
            0.036295440047979355,
            -0.004852753132581711,
            -0.005319621413946152,
            0.0171781238168478,
            -0.005024302750825882,
            0.00035695836413651705,
            0.0399477556347847,
            0.009905099868774414,
            0.004237581510096788,
            0.030102217569947243,
            0.012620978988707066,
            -0.026247089728713036,
            0.0017727856757119298,
            0.01907293312251568,
            -0.011680723167955875,
            -0.004331583622843027,
            0.006356119178235531,
            0.014988080598413944,
            0.013080623000860214,
            0.01826533116400242,
            -0.033290185034275055,
            0.02315850555896759,
            -0.0377342663705349,
            0.02662135846912861,
            -0.010395410470664501,
            0.06251057237386703,
            -0.03150457888841629,
            0.0054133590310812,
            0.010028350166976452,
            0.0010517080081626773,
            0.00484903808683157,
            0.020469384267926216,
            0.015971440821886063,
            0.009871524758636951,
            -0.029308855533599854,
            0.03115207329392433,
            0.00318881100974977,
            0.004204572644084692,
            -0.0018413735087960958,
            0.035309549421072006,
            -0.016238249838352203,
            -0.014852532185614109,
            0.00820941012352705,
            0.01675407402217388,
            -0.029046179726719856,
            0.03683551400899887,
            -0.028359387069940567,
            -0.0007796299178153276,
            0.001015554298646748,
            -0.006451855413615704,
            0.023914963006973267,
            0.02057855762541294,
            0.007204277906566858,
            -0.012138022109866142,
            0.003745315596461296,
            -0.004206138197332621,
            -0.027474144473671913,
            0.021064892411231995,
            -0.007338850758969784,
            -0.0872357040643692,
            -0.023589059710502625,
            0.014605382457375526,
            -0.010507438331842422,
            -0.01570165529847145,
            0.019962254911661148,
            0.0032633666414767504,
            0.022094570100307465,
            0.007468399591743946,
            -0.015124140307307243,
            -0.049435947090387344,
            -0.002676399890333414,
            0.025847049430012703,
            -0.018197495490312576,
            -0.030105728656053543,
            0.021108713001012802,
            0.021358545869588852,
            -0.01353684812784195,
            0.025598924607038498,
            -0.007703439798206091,
            -0.02866792306303978,
            0.03491024672985077,
            -0.008275365456938744,
            -0.0031382308807224035,
            -0.039506636559963226,
            -0.007471655961126089,
            -0.010729038156569004,
            -0.029580289497971535,
            0.019289439544081688,
            0.04101789742708206,
            4.711899600806646e-05,
            0.01313601341098547,
            0.01708986796438694,
            -0.029127968475222588,
            -0.011696795001626015,
            -0.035988353192806244,
            0.006567078176885843,
            0.03296322003006935,
            -0.02213406376540661,
            -0.022887784987688065,
            0.03494231030344963,
            0.02459913119673729,
            -0.027328260242938995,
            -0.0588347390294075,
            -0.0003541884070727974,
            -0.016842959448695183,
            -0.007489210460335016,
            0.005299959797412157,
            0.010180814191699028,
            -0.012796731665730476,
            0.008727707900106907,
            -0.017971741035580635,
            -0.015468830242753029,
            -0.004246930126100779,
            0.016744744032621384,
            -0.020292656496167183,
            -0.025944784283638,
            -0.017264671623706818,
            -0.029959997162222862,
            0.005377996247261763,
            0.0030812432523816824,
            0.04001588374376297,
            -0.015209621749818325,
            0.005661936476826668,
            0.005288630723953247,
            -0.007195700891315937,
            0.07223702222108841,
            -0.013245630078017712,
            0.020451456308364868,
            -0.011717967689037323,
            0.0004673436633311212,
            -0.016801225021481514,
            -0.01626899652183056,
            0.011230812408030033,
            0.02220640890300274,
            -0.01321464590728283,
            -0.020569568499922752,
            -0.002042942214757204,
            -0.005829279311001301,
            -0.010612747631967068,
            -0.0023747682571411133,
            0.05023174360394478,
            0.03858650103211403,
            0.008838718757033348,
            0.047869838774204254,
            0.01622680202126503,
            -0.030475221574306488,
            -0.0018230624264106154,
            0.029961291700601578,
            -0.013418070040643215,
            0.0046254051849246025,
            -0.026058711111545563,
            0.007132579572498798,
            0.023734968155622482,
            -0.020286155864596367,
            -0.0038218514528125525,
            -0.0576317198574543,
            0.02910825051367283,
            -0.009072870947420597,
            0.0053614601492881775,
            -0.023596003651618958,
            0.015395533293485641,
            -0.00603890884667635,
            0.015430609695613384,
            -0.03368527069687843,
            0.013762153685092926,
            0.020500866696238518,
            0.0017251267563551664,
            0.01061779074370861,
            0.0070975469425320625,
            -0.007408562581986189,
            -0.008499512448906898,
            -0.02353629283607006,
            0.05060180276632309,
            -0.01659443974494934,
            0.0030413209460675716,
            -0.023632289841771126,
            0.009745134972035885,
            -0.018516069278120995,
            0.00502739055082202,
            0.05810534209012985,
            0.02390146255493164,
            0.033961523324251175,
            -0.011110492050647736,
            0.008333796635270119,
            0.016472555696964264,
            -0.008539943024516106,
            0.01141397189348936,
            0.014434963464736938,
            -0.011517892591655254,
            -0.026386655867099762,
            0.03968479484319687,
            -0.033268582075834274,
            -0.013359509408473969,
            0.03399981930851936,
            -0.03390384837985039,
            -0.005528714042156935,
            0.022685125470161438,
            0.02621724270284176,
            0.01456502266228199,
            0.029902273789048195,
            -0.005289444699883461,
            0.017733024433255196,
            -0.008281347341835499,
            0.03750593960285187,
            0.02860124409198761,
            -0.016741858795285225,
            -0.026982532814145088,
            -0.006745964288711548,
            -0.01832987181842327,
            0.02527514472603798,
            -0.013378863222897053,
            -0.0036719669587910175,
            0.025596551597118378,
            0.02263600379228592,
            -0.007700284477323294,
            0.010159491561353207,
            -0.019557306542992592,
            -0.011566504836082458,
            -0.03208329528570175,
            -0.039024513214826584,
            0.019318141043186188,
            -0.03771967440843582,
            0.01843326911330223,
            -0.019546715542674065,
            0.017923805862665176,
            -0.035778239369392395,
            0.00904531218111515,
            -0.010646901093423367,
            -0.015351989306509495,
            0.0036443520803004503,
            0.029027029871940613,
            -0.02216203510761261,
            -0.004531246609985828,
            0.0009380014962516725,
            -3.7183308450039476e-05,
            -0.024596231058239937,
            -0.00034838044666685164,
            0.027291541919112206,
            -0.01708042062819004,
            -0.009896011091768742,
            -0.00630249222740531,
            -0.0594281405210495,
            -0.002290326403453946,
            -0.00899092759937048,
            -0.012069359421730042,
            -0.02350894920527935,
            0.01218267809599638,
            0.00235691387206316,
            0.0332317017018795,
            0.033645953983068466,
            -0.010757186450064182,
            -0.007292376831173897,
            -0.038356535136699677,
            -0.013354131020605564,
            0.024116167798638344,
            -0.012450780719518661,
            0.011483404785394669,
            -0.04025772586464882,
            -0.03484687581658363,
            0.01162608340382576,
            0.009050293825566769,
            0.05503683537244797,
            -0.0012638710904866457,
            0.01689506322145462,
            0.021047621965408325,
            -0.003262587357312441,
            -0.02227618917822838,
            0.02080593816936016,
            -0.039800237864255905,
            0.027243375778198242,
            -0.03238089010119438,
            -0.057515207678079605,
            0.023353127762675285,
            -0.012894737534224987,
            -0.019899882376194,
            0.012738939374685287,
            -0.014960046857595444,
            0.023013539612293243,
            -0.001744337729178369,
            0.004025154747068882,
            0.021977970376610756,
            -0.017685700207948685,
            0.004928898997604847,
            0.0032793483696877956,
            0.0380329005420208,
            0.0436168871819973,
            0.007727736607193947,
            -0.00397107657045126,
            0.00492169288918376,
            -0.0434146411716938,
            0.03261870518326759,
            -0.006994523108005524,
            0.01115840021520853,
            0.06350460648536682,
            0.031388115137815475,
            0.001503019011579454,
            -0.038102708756923676,
            0.005119748413562775,
            0.008896801620721817,
            0.017556024715304375,
            0.001472973613999784,
            -0.018746597692370415,
            0.04667828977108002,
            -0.07210078090429306,
            0.010912816971540451,
            -0.021292859688401222,
            -0.03843894973397255,
            -0.03440248221158981,
            0.038992397487163544,
            -0.02115754596889019,
            0.035382770001888275,
            0.03028397262096405,
            0.010911379009485245,
            0.009000388905405998,
            -0.003484027460217476,
            0.013588947243988514,
            -0.005501564592123032,
            0.020928310230374336,
            0.031605616211891174,
            0.000719201925676316,
            0.024545393884181976,
            0.05876586213707924,
            -0.01607351005077362,
            0.00756909791380167,
            -0.0005039331736043096,
            -0.009018265642225742,
            0.02703317254781723,
            0.000290836178464815,
            0.013537961058318615,
            0.01466588955372572,
            0.011460697278380394,
            0.030949681997299194,
            0.015138565562665462,
            -0.01281600259244442,
            0.04803166165947914,
            0.015922412276268005,
            0.018748052418231964,
            0.032633181661367416,
            0.020878905430436134,
            -0.01171479094773531,
            0.04292500764131546,
            0.01713394746184349,
            -0.041233427822589874,
            0.007341347169131041,
            -0.004746715538203716,
            0.05641276761889458,
            -0.005836979020386934,
            0.03934662789106369,
            0.014780934900045395,
            0.006553102750331163,
            0.02007218636572361,
            -0.03741612285375595,
            -0.019649170339107513,
            -0.005467000417411327,
            -0.007284298073500395,
            0.056079424917697906,
            0.025879664346575737,
            0.029186543077230453,
            0.028447246178984642,
            -0.06581922620534897,
            -0.011034906841814518,
            -0.0006233106832951307,
            0.005050015635788441,
            -0.0024505029432475567,
            -0.00783347524702549,
            0.012493474408984184,
            0.01892954297363758,
            0.028710611164569855,
            0.020560914650559425,
            -0.01029791310429573,
            0.025050515308976173,
            -0.0011231501121073961,
            -0.009865287691354752,
            0.003406836651265621,
            -0.0036680724006146193,
            0.027881786227226257,
            -0.004313747398555279,
            0.03488347306847572,
            -0.02408517897129059,
            -0.0268718209117651,
            -0.0002019122039200738,
            -0.00790267251431942,
            0.021846534684300423,
            0.012795887887477875,
            -0.004759167321026325,
            -0.008931208401918411,
            -0.0016979244537651539,
            -0.020617928355932236,
            -0.017615027725696564,
            0.0073793912306427956,
            0.008902613073587418,
            0.03461041674017906,
            0.005344707518815994,
            0.01607426628470421,
            0.045869484543800354,
            0.006747578736394644,
            -0.006763973738998175,
            0.028199268504977226,
            -0.03499313071370125,
            0.0019471391569823027,
            0.028520628809928894,
            0.006886892952024937,
            -0.006150162313133478
        ],
        [
            0.01612703502178192,
            -0.028349563479423523,
            -0.009725676849484444,
            0.03965962678194046,
            -0.004139018710702658,
            0.007108460646122694,
            -0.010501282289624214,
            0.008540268987417221,
            0.004815852735191584,
            -0.005931526888161898,
            -0.013105897232890129,
            -0.03064381331205368,
            -0.019526220858097076,
            -0.02169634774327278,
            -0.007269551511853933,
            0.011655941605567932,
            -0.033333420753479004,
            0.03209182247519493,
            0.025628171861171722,
            0.02796776592731476,
            0.030554456636309624,
            -0.004974231589585543,
            0.011417526751756668,
            -0.031463317573070526,
            0.05761474370956421,
            -0.018100010231137276,
            -0.022538837045431137,
            -0.04650304093956947,
            0.011051624082028866,
            0.005299641750752926,
            0.026555588468909264,
            -0.0001736530102789402,
            0.009313263930380344,
            -0.025477532297372818,
            -0.010310777463018894,
            -0.0046914671547710896,
            -0.027302363887429237,
            0.020924557000398636,
            -0.015183048322796822,
            0.021992739289999008,
            0.01475680060684681,
            0.030265307053923607,
            0.010002224706113338,
            -0.00487735727801919,
            0.01452517881989479,
            0.03469870984554291,
            0.005751328077167273,
            -0.03742191195487976,
            -0.009313510730862617,
            -0.02170056290924549,
            -0.013124636374413967,
            0.035971030592918396,
            -0.04367648810148239,
            0.006339516490697861,
            -0.002340564737096429,
            0.05234545096755028,
            0.023359887301921844,
            0.012935780920088291,
            -0.024812670424580574,
            -0.015091544948518276,
            0.021463649347424507,
            -0.029044831171631813,
            0.017919087782502174,
            -0.014463099651038647,
            0.0024401918053627014,
            0.029114359989762306,
            -0.0076612262055277824,
            -0.010944734327495098,
            0.00044020629138685763,
            -0.00013506757386494428,
            -0.014898029156029224,
            -0.017410460859537125,
            -0.024474192410707474,
            -0.002910258946940303,
            0.02374955452978611,
            -0.006297057960182428,
            0.008725589141249657,
            0.002219343790784478,
            -0.0073818932287395,
            -0.04117928072810173,
            -0.025621140375733376,
            0.0015360214747488499,
            -0.010908101685345173,
            0.01981072500348091,
            0.004704223480075598,
            0.028940975666046143,
            0.020104756578803062,
            -0.0023503294214606285,
            -0.0009925456251949072,
            0.03195107355713844,
            -0.015289044007658958,
            0.000337297999067232,
            -0.01621047407388687,
            0.0006766400183551013,
            0.013734434731304646,
            0.021028922870755196,
            0.04396199807524681,
            0.006883666850626469,
            -0.023978769779205322,
            -0.04110102728009224,
            -0.0011420657392591238,
            0.019189398735761642,
            0.009043602272868156,
            -2.05937321879901e-05,
            -0.01003320049494505,
            0.016816649585962296,
            0.0006082403706386685,
            0.03827947750687599,
            0.041994858533144,
            0.008853788487613201,
            -0.02557534910738468,
            -0.02919168770313263,
            0.01474272832274437,
            -0.005520198959857225,
            -0.0642734244465828,
            -0.025738179683685303,
            0.022767435759305954,
            -0.032213348895311356,
            -0.03687690570950508,
            -0.019246889278292656,
            -0.0017721179174259305,
            -0.010944090783596039,
            0.0021745681297034025,
            0.018174665048718452,
            0.0011143612209707499,
            0.013064926490187645,
            -0.04520091414451599,
            0.024686381220817566,
            -0.02430001273751259,
            0.0026524134445935488,
            -0.034472912549972534,
            0.029684873297810555,
            0.021751003339886665,
            -0.0004884698428213596,
            0.002822238951921463,
            0.0005722773494198918,
            0.03974723815917969,
            0.03532300889492035,
            -0.021475104615092278,
            0.0003630236897151917,
            -0.00032337967422790825,
            -0.021894309669733047,
            0.023445241153240204,
            -0.028886104002594948,
            0.05648711696267128,
            -0.031561143696308136,
            0.00952647253870964,
            -0.008309816010296345,
            0.018384696915745735,
            0.0012996632140129805,
            -0.020774077624082565,
            -0.032567813992500305,
            2.424593549221754e-05,
            -0.01456767413765192,
            -0.021493060514330864,
            0.01939273625612259,
            0.023831935599446297,
            0.015127193182706833,
            0.03884801268577576,
            -0.01458008959889412,
            -0.029240963980555534,
            0.006825122516602278,
            0.01922658272087574,
            0.0008786527905613184,
            0.008212150074541569,
            -0.0017262405017390847,
            0.05345402657985687,
            0.014579368755221367,
            0.029056765139102936,
            0.01417283620685339,
            -0.0010256057139486074,
            0.0001853058929555118,
            0.03321199491620064,
            0.017333928495645523,
            0.020177140831947327,
            -0.012313338927924633,
            -0.016392821446061134,
            -0.0017905919812619686,
            0.016469772905111313,
            0.008657559752464294,
            -0.0026159065309911966,
            -0.003324471414089203,
            0.000609244336374104,
            -0.02821676805615425,
            -0.020245825871825218,
            -0.019115136936306953,
            -0.019745733588933945,
            -0.023757722228765488,
            0.010253203101456165,
            0.009535177610814571,
            0.06654118001461029,
            0.03627294301986694,
            -0.04867370054125786,
            0.012123309075832367,
            0.009865078143775463,
            -0.014724296517670155,
            0.03672223910689354,
            -0.024759406223893166,
            0.0044450582936406136,
            -0.017386943101882935,
            -0.03001321665942669,
            0.005763986147940159,
            -0.047780755907297134,
            0.0028155650943517685,
            -0.06597883999347687,
            -0.033463671803474426,
            0.02210043929517269,
            0.018889863044023514,
            0.029588228091597557,
            0.00015696098853368312,
            0.003319605253636837,
            -0.01705200970172882,
            -0.005509752780199051,
            -0.025252193212509155,
            -0.01190452091395855,
            0.0033613150008022785,
            -0.0388430692255497,
            0.024095049127936363,
            -0.00755559466779232,
            -0.0057087489403784275,
            0.027100570499897003,
            0.034341394901275635,
            -0.0030521058943122625,
            0.020749086514115334,
            0.03324107825756073,
            0.05286548659205437,
            0.02155112288892269,
            -0.013590037822723389,
            0.032973479479551315,
            -0.02720746211707592,
            -0.012117397040128708,
            0.0066540115512907505,
            -0.009746846742928028,
            -0.0008983029401861131,
            0.05301746353507042,
            -0.022468212991952896,
            -0.0171625055372715,
            -0.021556008607149124,
            0.002377290278673172,
            0.005250946618616581,
            0.005527190864086151,
            -0.002836520317941904,
            -0.00844108872115612,
            0.010790192522108555,
            0.008379826322197914,
            -0.018222594633698463,
            0.019073868170380592,
            0.02673514187335968,
            0.012636343948543072,
            -0.004947849083691835,
            0.010903837159276009,
            -0.005020663142204285,
            0.016637956723570824,
            -0.0070173535495996475,
            -0.002336270874366164,
            -0.028022147715091705,
            -0.023568883538246155,
            0.007905243895947933,
            0.03844873234629631,
            0.05938248336315155,
            0.01897757686674595,
            0.004526134580373764,
            0.05095804110169411,
            0.008494446985423565,
            -0.011978060007095337,
            0.02384394034743309,
            -0.02157721295952797,
            0.0027923784218728542,
            0.018795987591147423,
            0.010829770006239414,
            -0.014396164566278458,
            -0.018248680979013443,
            0.011597659438848495,
            -0.02920963056385517,
            -0.015552142634987831,
            -0.01062543410807848,
            -0.014788666740059853,
            -0.006505256053060293,
            -0.002852478064596653,
            0.0319223515689373,
            -0.0048245773650705814,
            0.005501136649399996,
            0.010562537238001823,
            0.029360180720686913,
            0.02566830813884735,
            0.017230387777090073,
            0.018848633393645287,
            -0.051076456904411316,
            -0.024674920365214348,
            0.0037494138814508915,
            -0.014757583849132061,
            0.0039373403415083885,
            -0.01721331477165222,
            -0.014987973496317863,
            -0.004716174677014351,
            0.021193990483880043,
            0.0019417876610532403,
            -0.038245826959609985,
            0.027817539870738983,
            0.008988370187580585,
            0.020187629386782646,
            0.02020762674510479,
            0.01200484111905098,
            -0.03671623393893242,
            -0.00196946132928133,
            0.05634807050228119,
            0.017929576337337494,
            -0.058352258056402206,
            -0.03346018120646477,
            0.030949998646974564,
            -0.0004625384754035622,
            -0.040927860885858536,
            0.015876077115535736,
            0.0032453041058033705,
            -0.01571611687541008,
            -0.04842287674546242,
            0.02768486551940441,
            -0.01667766086757183,
            -0.00841407012194395,
            0.0017926228465512395,
            0.02663622424006462,
            -0.0187288336455822,
            -0.01460861787199974,
            -0.006813697051256895,
            -0.010377208702266216,
            -0.001630476675927639,
            -0.051671069115400314,
            0.01724371872842312,
            0.013270026072859764,
            -0.030528657138347626,
            0.03849358856678009,
            -0.005609759129583836,
            0.005880409851670265,
            0.019991407170891762,
            -0.005179805681109428,
            -0.048057351261377335,
            -0.019757527858018875,
            0.010685041546821594,
            -0.023375466465950012,
            0.0033016882371157408,
            -0.00670353788882494,
            -0.01984998770058155,
            0.03556671366095543,
            -0.003310140920802951,
            -0.042615633457899094,
            0.025519302114844322,
            -0.019164064899086952,
            0.03768739104270935,
            0.012914979830384254,
            0.046655140817165375,
            -0.0024723177775740623,
            -0.03770960494875908,
            0.04461999237537384,
            0.021003784611821175,
            0.010802842676639557,
            -0.03171153739094734,
            -0.024579783901572227,
            -0.008556047454476357,
            0.01502283662557602,
            -0.01703033223748207,
            0.015892433002591133,
            0.02323322556912899,
            0.056489646434783936,
            0.02151767909526825,
            0.0046457163989543915,
            0.017198214307427406,
            -0.011233366094529629,
            0.02168976701796055,
            0.005691250320523977,
            -5.139401037013158e-05,
            0.000848267343826592,
            -0.003141325432807207,
            0.007253123447299004,
            -0.019172949716448784,
            0.02320285513997078,
            0.015635216608643532,
            0.03877747431397438,
            0.046062879264354706,
            0.08378028124570847,
            -0.017473001033067703,
            0.007852813228964806,
            -0.018027573823928833,
            0.016006965190172195,
            0.03832588717341423,
            -0.00856719072908163,
            0.04310793802142143,
            -0.010360744781792164,
            0.031731411814689636,
            0.020934922620654106,
            0.011379439383745193,
            -0.026534799486398697,
            -0.03634405881166458,
            -0.0028486030641943216,
            0.04031837731599808,
            -0.02339424192905426,
            0.00562952971085906,
            0.005487165413796902,
            0.019066937267780304,
            -0.02545781619846821,
            0.02617250755429268,
            -0.03251411393284798,
            0.028468411415815353,
            0.011508309282362461,
            0.015437979251146317,
            -0.012775070033967495,
            -0.010131209157407284,
            -0.028112979605793953,
            0.022915055975317955,
            -0.014037110842764378,
            -0.017639387398958206,
            0.0010175888892263174,
            0.03921830281615257,
            0.03544119372963905,
            0.00011702584743034095,
            0.0037644014228135347,
            0.013875680975615978,
            0.02821965329349041,
            -0.0171407051384449,
            0.0406726710498333,
            -0.004036427475512028,
            -0.03452743589878082,
            0.025097275152802467,
            0.031412553042173386,
            0.017026977613568306,
            -0.03625626489520073,
            0.05037111043930054,
            -0.008633295074105263,
            0.034293901175260544,
            -0.02957339957356453,
            -0.024290533736348152,
            0.0030243108049035072,
            0.02718236856162548,
            0.02868281491100788,
            -5.396593041950837e-05,
            -0.010297378525137901,
            0.013144051656126976,
            0.030651206150650978,
            0.032281335443258286,
            0.013021831400692463,
            0.01656920276582241,
            0.016409367322921753,
            0.02168227732181549,
            0.007197655271738768,
            -0.047210659831762314,
            0.017017265781760216,
            0.017316296696662903,
            -0.01621842011809349,
            0.0007565636187791824,
            0.019492115825414658,
            -0.02003643475472927,
            0.030023787170648575,
            -0.024029957130551338,
            0.0002697198069654405,
            -0.006887206342071295,
            0.013380900025367737,
            0.02408958226442337,
            0.023275990039110184,
            -0.02594933658838272,
            -0.011181026697158813,
            -0.02307162620127201,
            -0.029068145900964737,
            0.01529762428253889,
            0.0066586630418896675,
            -0.0246142391115427,
            -0.014443517662584782,
            0.007893341593444347,
            0.0017483793199062347,
            0.02350039593875408,
            0.008725842460989952,
            -0.004017517436295748,
            -0.02244563028216362,
            0.008541391231119633,
            -0.021167851984500885,
            -0.03789515793323517,
            -0.025357920676469803,
            0.0022035930305719376,
            -0.02926895022392273,
            0.04291804879903793,
            0.014915402978658676,
            0.054612983018159866,
            -0.006286699324846268,
            0.005401172209531069,
            0.039118167012929916,
            0.014784821309149265,
            -0.013000335544347763,
            -0.013059549033641815,
            0.008632680401206017,
            -0.03226667642593384,
            -0.016518669202923775,
            -0.009896934032440186,
            -0.036415647715330124,
            0.018859731033444405,
            0.024810953065752983,
            0.040889423340559006,
            0.04951705038547516,
            0.03214787319302559,
            0.04172465205192566,
            0.01746048964560032,
            0.00015117613656912,
            0.0001478477061027661,
            0.021276861429214478,
            -0.0010192097397521138,
            0.027128828689455986,
            -0.04479077458381653,
            -0.01568484865128994,
            0.001825044397264719,
            0.026395056396722794,
            -0.024437090381979942,
            -0.012672276236116886,
            0.018277153372764587,
            0.02288627438247204,
            -0.038615476340055466,
            0.009745711460709572,
            0.021315088495612144,
            -0.03505495935678482,
            -0.002891007112339139,
            -0.010084828361868858,
            -0.021228933706879616,
            0.010545393452048302,
            -0.004982538055628538,
            -0.011523837223649025,
            -0.016608959063887596,
            -0.022252194583415985,
            -0.030031634494662285,
            -0.002454988891258836,
            0.009862278588116169,
            0.07549172639846802,
            -0.01031962689012289,
            -0.018624963238835335,
            0.0010279215639457107,
            -0.006558847147971392,
            -0.00883688498288393,
            0.023531585931777954,
            -0.022403815761208534,
            -0.015570458024740219,
            -0.01682317443192005,
            0.00617193290963769,
            -0.02331174723803997,
            0.015931932255625725,
            0.003963818307965994,
            -0.020296650007367134,
            0.026418566703796387,
            -0.018445931375026703,
            0.014207388274371624,
            0.019304009154438972,
            0.0015441376017406583,
            0.031351394951343536,
            -0.0037600253708660603,
            0.009372926317155361,
            0.014312571845948696,
            -0.005067483987659216,
            -0.007752918638288975,
            -0.025631770491600037,
            -0.026972701773047447,
            -0.01581924594938755,
            0.023716023191809654,
            -0.013176728039979935,
            0.010531383566558361,
            -0.01528980489820242,
            0.02249179594218731,
            0.0315730981528759,
            0.0026167333126068115,
            0.017358452081680298,
            0.0433652326464653,
            0.0005523920408450067,
            -0.02264006808400154,
            0.005716456100344658,
            0.005787397734820843,
            0.0024374567437916994,
            0.01551734283566475,
            0.008805056102573872,
            0.037729278206825256,
            -0.022761600092053413,
            0.010262549854815006,
            0.016152070835232735,
            0.028719456866383553,
            0.007960385642945766,
            -0.030268214643001556,
            -0.006830213591456413,
            -0.03377721086144447,
            -0.032393209636211395,
            -0.0779150128364563,
            -0.025149105116724968,
            0.04529913142323494,
            0.007274523843079805,
            -0.026736939325928688,
            0.021128540858626366,
            0.008578614331781864,
            -0.0080611202865839,
            -0.0003710005257744342,
            -0.002523858333006501,
            -0.035923071205616,
            0.02569374442100525,
            0.01899469457566738,
            0.024333111941814423,
            -0.02292397990822792,
            0.02694876864552498,
            0.03964800015091896,
            0.015338833443820477,
            0.04796356335282326,
            0.026150500401854515,
            -0.00801953673362732,
            0.020166484639048576,
            0.02969248779118061,
            -0.016927585005760193,
            0.01315655093640089,
            -0.00040071961120702326,
            -0.0034018366131931543,
            0.0310567244887352,
            -0.03440086171030998,
            -0.0024402192793786526,
            -0.01767713390290737,
            -0.0007037266041152179,
            0.01630379632115364,
            -0.02737526409327984,
            0.025687234476208687,
            0.026599984616041183,
            -0.042657624930143356,
            -0.02415613643825054,
            -0.0028557947371155024,
            -0.049189064651727676,
            0.006824682466685772,
            -0.023963022977113724,
            0.009944227524101734,
            -0.002398890443146229,
            0.002234091516584158,
            -0.003868500469252467,
            -0.001161830616183579,
            -0.032204218208789825,
            -0.012050447054207325,
            -0.002210406819358468,
            -0.03695886582136154,
            0.030750418081879616,
            0.03370300307869911,
            -0.0018236521864309907,
            0.0035923151299357414,
            -0.015953516587615013,
            -0.011297645047307014,
            -0.003714192658662796,
            -0.019037561491131783,
            0.012370598502457142,
            0.045144472271203995,
            0.016442155465483665,
            -0.032699719071388245,
            0.018886299803853035,
            0.024998702108860016,
            -0.0470307320356369,
            -0.013188700191676617,
            0.020291049033403397,
            -0.017221253365278244,
            0.0182294100522995,
            -0.005530587397515774,
            0.017836840823292732,
            -0.0031669994350522757,
            0.006030154414474964,
            0.02270599827170372,
            0.018817774951457977,
            0.022020358592271805,
            0.04299822822213173,
            0.01704334281384945,
            -0.04325662925839424,
            -0.007442311849445105,
            0.01364730205386877,
            0.02563517726957798,
            -0.021196207031607628,
            0.01483406126499176,
            0.009332227520644665,
            -0.010958286933600903,
            -0.009715160354971886,
            0.04616471379995346,
            -0.003799950471147895,
            0.06044420227408409,
            0.00992820505052805,
            0.02907617576420307,
            -0.005375854205340147,
            -0.03501107543706894,
            0.029620889574289322,
            0.034997764974832535,
            0.0040491786785423756,
            -0.03125189617276192,
            -0.02594241499900818,
            -0.03214990347623825,
            0.02698707766830921,
            -0.011542302556335926,
            0.024045970290899277,
            -0.011648773215711117,
            0.012816728092730045,
            0.02189534157514572,
            0.010950234718620777,
            -0.03344117850065231,
            0.017038723453879356,
            -0.009158872999250889,
            0.013979234732687473,
            0.005045813042670488,
            -0.01724660024046898,
            -0.016773218289017677,
            0.03480032458901405,
            0.006906379479914904,
            0.016334498301148415,
            0.018466759473085403,
            -0.023526359349489212,
            -0.004836143925786018,
            0.011272871866822243,
            -0.0015029136557132006,
            -0.001078080851584673,
            -0.01115777250379324,
            0.026142597198486328,
            -0.03387070074677467,
            0.03571739047765732,
            0.03129377216100693,
            -0.016419367864727974,
            0.009247319772839546,
            0.01573207601904869,
            0.0031166027765721083,
            -0.023523718118667603,
            0.004784679040312767,
            0.002789776772260666,
            0.003921527415513992,
            0.010653111152350903,
            0.014356380328536034,
            0.013293647207319736,
            -0.01810482330620289,
            -0.028740227222442627,
            -0.014290429651737213,
            -0.042841993272304535,
            -0.010802865959703922,
            -0.009512033313512802,
            -0.007693267427384853,
            0.014991454780101776,
            0.017393961548805237,
            -0.030471619218587875,
            0.017852677032351494,
            0.017028456553816795,
            -0.015874559059739113,
            -0.0055180988274514675,
            0.03639864921569824,
            0.008940126746892929,
            0.011695796623826027,
            -0.056700874119997025,
            0.032626084983348846,
            0.03329947963356972,
            -0.01741429604589939,
            -0.011385609395802021,
            -0.03082358092069626,
            0.017088856548070908,
            -0.040862977504730225,
            -0.0014475827338173985,
            -0.0012715006014332175,
            -0.010501163080334663,
            -0.011233092285692692,
            0.027245528995990753,
            -0.036297012120485306,
            0.005062680225819349,
            -0.005115510430186987,
            0.0003724849666468799,
            0.031974852085113525,
            -0.02280762977898121,
            -0.01469399780035019,
            -0.0035547767765820026,
            -0.025337668135762215,
            -0.017649872228503227,
            0.020724255591630936,
            0.009941969066858292,
            -0.020223360508680344,
            -0.01223486103117466,
            -0.019146814942359924,
            -0.03234722092747688,
            -0.01344122551381588,
            -0.019835734739899635,
            0.00015943238395266235,
            0.03332207351922989,
            -0.044296540319919586,
            -0.02955998294055462,
            -0.01425892673432827,
            0.0003798817633651197,
            0.053617991507053375,
            0.021210741251707077,
            0.048588670790195465,
            0.011843284592032433,
            -0.0026644545141607523,
            -0.0156778022646904,
            0.011738477274775505,
            -0.0015420694835484028,
            0.006511631887406111,
            0.01916317641735077,
            -0.019776619970798492,
            0.002022066153585911,
            -0.020509008318185806,
            -0.02388952299952507,
            -0.012629351578652859,
            -0.019561802968382835,
            0.017956111580133438,
            0.007844544015824795,
            -0.007903911173343658,
            -0.039850518107414246,
            -0.012282343581318855,
            -0.007756613194942474,
            -0.01267270278185606,
            0.007105263415724039,
            0.02820974960923195,
            0.028052808716893196,
            0.00993090309202671,
            0.0012694834731519222,
            0.0009669094579294324,
            -0.009965489618480206,
            -0.027365611866116524,
            0.033803828060626984,
            0.014024165458977222,
            -0.013300148770213127,
            -0.04114095866680145,
            -0.0064512682147324085,
            0.020715929567813873,
            0.013720577582716942,
            0.011979376897215843,
            -0.03928714618086815,
            -0.02369173988699913,
            0.020901409909129143,
            -0.01370168849825859,
            0.0059289829805493355,
            -0.0010042680660262704,
            0.02743399515748024,
            0.038104377686977386,
            0.01882775127887726,
            -0.009772748686373234,
            -0.008333452045917511,
            0.015708578750491142,
            -0.03821146488189697,
            -0.027021043002605438,
            -0.03375508636236191,
            -0.04190326854586601,
            0.026789797469973564,
            0.011475362814962864,
            -0.0064073000103235245,
            -0.013239504769444466,
            0.004248044919222593,
            -0.008815762586891651,
            -0.026882754638791084,
            0.003900829702615738,
            -0.009578644298017025,
            0.0407140776515007,
            -0.010019497945904732,
            0.006872826721519232,
            0.0028444223571568727,
            0.016534389927983284,
            -0.03227941319346428,
            -0.023746969178318977,
            -0.008931390009820461,
            -0.019696516916155815,
            -0.02558252215385437,
            0.0027283739764243364,
            0.006810226943343878,
            -0.005481895990669727,
            0.04897989332675934,
            -0.01596505008637905,
            -0.01945759728550911,
            -0.026196956634521484,
            -0.03290720283985138,
            0.011163248680531979,
            -0.0336713008582592,
            -0.0335652157664299,
            0.00966145284473896,
            0.01604400761425495,
            0.008623052388429642,
            0.000681445118971169,
            0.001562778023071587,
            0.02289731614291668,
            -0.01995493844151497,
            0.0016583945835009217,
            0.01898283325135708,
            0.0019305594032630324,
            0.06441818922758102,
            -0.017965473234653473,
            0.01688121259212494,
            0.03394204005599022,
            -0.008733365684747696,
            -0.017851142212748528,
            -0.025773726403713226,
            -0.003505531931295991,
            -0.03794688358902931,
            0.008895735256373882,
            -0.013069506734609604,
            0.03377090021967888,
            -0.03848126530647278,
            0.00617781700566411,
            0.01830529421567917,
            0.01593751087784767,
            0.023075858131051064,
            0.02343353070318699,
            0.02489786222577095,
            0.016366612166166306,
            0.006148974411189556,
            -0.0013799432199448347,
            0.013919863849878311,
            -0.030699046328663826,
            0.06415929645299911,
            0.019418034702539444,
            0.018926287069916725,
            -0.016240162774920464,
            0.02585548907518387,
            0.02049773745238781,
            0.01581997610628605,
            -0.00029653942328877747,
            -0.03007926419377327,
            0.006051900330930948,
            0.061358220875263214,
            0.03480897843837738,
            -0.0485197938978672,
            -0.02262597158551216,
            -0.019127998501062393,
            0.020629413425922394,
            0.041132982820272446,
            -0.025129426270723343,
            -0.03390538692474365,
            -0.006403225939720869,
            0.0057418253272771835,
            0.012323529459536076,
            0.02404213137924671,
            -0.036671675741672516,
            -0.028461841866374016,
            -0.01600707694888115,
            -0.0142191331833601,
            0.00363815831951797,
            0.041768353432416916,
            -0.0016201583202928305,
            0.021163688972592354,
            0.016859324648976326,
            0.015592730604112148,
            0.05258806422352791,
            0.02991676516830921,
            0.028453417122364044,
            0.0068439156748354435,
            -0.005780844017863274,
            0.011694495566189289,
            -0.00561441108584404,
            0.00661760289222002,
            0.00957221258431673,
            -0.023851631209254265,
            -0.024042541161179543,
            -0.038896024227142334,
            0.013083035126328468,
            0.030196256935596466,
            -0.034436024725437164,
            -0.03624848648905754,
            0.026191456243395805,
            0.025814812630414963,
            -0.00028118101181462407,
            0.0032990057952702045,
            -0.004590265452861786,
            0.020739125087857246,
            0.02039801888167858,
            0.013550637289881706,
            -0.010537683963775635,
            -0.020663544535636902,
            0.0054428656585514545,
            0.011357144452631474,
            0.026705997064709663,
            0.024283044040203094,
            -0.0132148377597332,
            -0.0029349513351917267,
            -0.006363755092024803,
            -0.001184414024464786,
            -0.019904348999261856,
            -0.003705467563122511,
            -0.038886457681655884,
            -0.024969421327114105,
            0.028494104743003845,
            0.01099549513310194,
            0.02786443568766117,
            -0.024400750175118446,
            -0.00504194526001811,
            -0.010261856019496918,
            0.0047155143693089485,
            -0.014275460503995419,
            -0.005413235165178776,
            -0.03281838074326515,
            0.015850424766540527,
            -0.03738879784941673,
            -0.007899106480181217,
            -0.02078554779291153,
            0.0010741319274529815,
            -0.014467845670878887,
            0.009332805871963501,
            -0.019660552963614464,
            0.011604414321482182,
            -0.00917112361639738,
            0.023605380207300186,
            -0.017318615689873695,
            -0.030400052666664124,
            -0.025387153029441833,
            -0.014555121771991253,
            -0.026337919756770134,
            -0.036618683487176895,
            -0.02503892593085766,
            -0.01470628660172224,
            4.585999340633862e-05,
            0.034163836389780045,
            0.04199899360537529,
            -0.0344558022916317,
            0.07171283662319183,
            -0.02547598071396351,
            0.024937989190220833,
            -0.01977771706879139,
            -0.01746177487075329,
            -0.018920745700597763,
            -0.011159682646393776,
            0.0028518280014395714,
            -0.03522644191980362,
            -0.04374241828918457,
            0.013878824189305305,
            0.0331156887114048,
            0.005228957161307335,
            0.036562785506248474,
            -0.027318201959133148,
            0.029273400083184242,
            -0.005701964255422354,
            0.012446455657482147,
            0.03278857097029686,
            -0.016878187656402588,
            -0.025613199919462204,
            0.020195016637444496,
            -0.006244989577680826,
            -0.009890361689031124,
            0.002690817229449749,
            -0.03195235878229141
        ],
        [
            0.007764111272990704,
            0.02286774106323719,
            0.024493852630257607,
            -0.02380480244755745,
            -0.0033698587212711573,
            0.07283817231655121,
            0.0010049868142232299,
            0.027650121599435806,
            -0.01756417378783226,
            0.009634202346205711,
            -0.01933455839753151,
            0.007062403950840235,
            -0.022284764796495438,
            -0.0216126199811697,
            0.025612473487854004,
            -0.019283214583992958,
            -0.030262043699622154,
            0.0007700652349740267,
            -0.008263976313173771,
            -0.0058740912936627865,
            0.04403232783079147,
            0.011402909643948078,
            0.04767952859401703,
            0.008080021478235722,
            0.06251595914363861,
            -0.02693984843790531,
            -0.006301562767475843,
            0.027238203212618828,
            0.007222653366625309,
            0.038464128971099854,
            -0.040975891053676605,
            0.03201202675700188,
            -0.019151978194713593,
            0.013666734099388123,
            0.02183227427303791,
            0.028212420642375946,
            0.01832585781812668,
            -0.011688679456710815,
            0.006922198925167322,
            -0.015214202925562859,
            -0.01588268019258976,
            0.023718712851405144,
            0.004143886733800173,
            0.029187990352511406,
            0.029479628428816795,
            0.021548820659518242,
            0.0055552697740495205,
            -0.01195800956338644,
            -0.015712745487689972,
            0.05059148743748665,
            0.006966425571590662,
            0.014548074454069138,
            0.035396043211221695,
            0.02179483138024807,
            -0.03111421689391136,
            -0.0005065191071480513,
            -0.023066170513629913,
            0.03726934269070625,
            -0.01083128247410059,
            0.025692064315080643,
            0.011821822263300419,
            -0.001177801750600338,
            0.02892172709107399,
            -0.03791629150509834,
            0.04476648569107056,
            -0.03553460165858269,
            -0.011562811210751534,
            -0.0027265637181699276,
            -0.025040682405233383,
            -0.011809468269348145,
            0.008508370257914066,
            0.018336433917284012,
            0.018008381128311157,
            0.028118183836340904,
            0.008428473025560379,
            0.012507330626249313,
            0.02522713504731655,
            -0.0002966303436551243,
            -0.03785751014947891,
            -0.023874087259173393,
            0.01078515313565731,
            -0.036040082573890686,
            -0.0010025252122431993,
            0.013347039930522442,
            -0.010232442989945412,
            -0.00812579970806837,
            -0.022095365449786186,
            -0.006199925672262907,
            0.007901373319327831,
            0.04211410507559776,
            -0.0023925441782921553,
            -0.022608496248722076,
            -0.008323426358401775,
            -0.028819361701607704,
            0.016778208315372467,
            -0.0029730498790740967,
            -0.0027948576025664806,
            0.01619601808488369,
            0.0376594103872776,
            -0.02230597287416458,
            0.03363344818353653,
            -0.03664899244904518,
            -0.02321428433060646,
            0.020312052220106125,
            -0.013067814521491528,
            0.016575835645198822,
            -0.026577377691864967,
            0.018250735476613045,
            -0.02634495124220848,
            -0.01565324142575264,
            0.02658880688250065,
            -0.021062981337308884,
            0.0010084464447572827,
            -0.006507104728370905,
            0.039848487824201584,
            0.027127770707011223,
            -0.026641875505447388,
            0.036056943237781525,
            -0.013781801797449589,
            0.021312681958079338,
            0.04228527471423149,
            -0.026459218934178352,
            0.002589947311207652,
            0.025668691843748093,
            -0.018511082977056503,
            -0.02182140201330185,
            0.018223144114017487,
            0.013748053461313248,
            -0.014514393173158169,
            -0.02309957519173622,
            0.016228966414928436,
            0.031145107001066208,
            -0.04123226925730705,
            -0.011646745726466179,
            0.020284732803702354,
            -0.00041115711792372167,
            0.023629823699593544,
            -0.023891566321253777,
            0.032022759318351746,
            0.0029000258073210716,
            0.011510372161865234,
            -0.009650456719100475,
            0.039713963866233826,
            0.001179509679786861,
            0.0285209808498621,
            -0.03480755537748337,
            -0.019775282591581345,
            0.02745724841952324,
            -0.035998739302158356,
            0.01415945217013359,
            0.02521565929055214,
            0.015593556687235832,
            0.038550060242414474,
            0.033135730773210526,
            -0.04906952381134033,
            0.009023051708936691,
            0.03554632514715195,
            0.010573811829090118,
            -0.001832738402299583,
            -0.04758419468998909,
            0.017664046958088875,
            0.00649647181853652,
            0.03828952834010124,
            0.010510897263884544,
            -0.024351628497242928,
            0.03256044536828995,
            -0.050427425652742386,
            0.03374448046088219,
            0.04904584586620331,
            0.029491957277059555,
            -0.008202625438570976,
            -0.012493593618273735,
            -0.06290989369153976,
            0.037664975970983505,
            0.001869199681095779,
            -0.042317163199186325,
            0.004181152209639549,
            -0.046643711626529694,
            0.000229532306548208,
            0.0216691754758358,
            -0.02898915484547615,
            0.0019952915608882904,
            -0.0038718623109161854,
            -0.027765054255723953,
            0.014861611649394035,
            -0.007806836627423763,
            0.029549315571784973,
            -0.007794621400535107,
            -0.017400769516825676,
            0.0021453064400702715,
            -0.020487269386649132,
            0.021722599864006042,
            0.052602000534534454,
            -0.009362634271383286,
            0.018663085997104645,
            0.03398662433028221,
            0.02181294932961464,
            0.02311432734131813,
            0.021821098402142525,
            0.004331964533776045,
            0.03880957141518593,
            0.025063330307602882,
            0.03186944127082825,
            -0.024007022380828857,
            0.04545525088906288,
            0.027261212468147278,
            0.03318816423416138,
            0.01633589155972004,
            0.016113046556711197,
            -0.017662042751908302,
            -0.010635646991431713,
            0.018688851967453957,
            -0.020879777148365974,
            0.04517589882016182,
            -0.02900836430490017,
            0.003310781205072999,
            0.0052232458256185055,
            0.008113999851047993,
            -0.011687341146171093,
            0.0087880939245224,
            0.001234607887454331,
            -0.04629429057240486,
            0.008936895057559013,
            0.02005254663527012,
            0.027243640273809433,
            -0.04319560155272484,
            0.058394983410835266,
            -0.023648960515856743,
            0.018142111599445343,
            0.02777387760579586,
            0.016931014135479927,
            0.014488344080746174,
            0.020109107717871666,
            0.02387874200940132,
            0.02193892002105713,
            -0.0036132868845015764,
            0.020147675648331642,
            0.02345091663300991,
            0.02453864924609661,
            0.018563922494649887,
            -0.02242797613143921,
            -0.015107043087482452,
            -0.006284346804022789,
            -0.014156791381537914,
            -0.01337520033121109,
            -0.01842532493174076,
            -0.009756283834576607,
            -0.030850108712911606,
            0.018846426159143448,
            -0.0263051837682724,
            -0.026082800701260567,
            -0.022934971377253532,
            -0.01162542775273323,
            -0.014990773051977158,
            0.012681093066930771,
            0.009684408083558083,
            -0.005882278550416231,
            0.02448895573616028,
            -0.03936319053173065,
            0.010270182974636555,
            0.031251925975084305,
            0.00904201902449131,
            0.01658651791512966,
            -0.026542069390416145,
            -0.007348707877099514,
            -0.05650519207119942,
            -0.013935370370745659,
            0.0008967904141172767,
            0.001486868946813047,
            0.049363117665052414,
            0.026581043377518654,
            0.035784024745225906,
            0.03530191630125046,
            0.033234018832445145,
            -0.03964422270655632,
            0.02153194695711136,
            0.004444397985935211,
            -0.03618477284908295,
            -0.023138169199228287,
            -0.004561503883451223,
            0.030655138194561005,
            -0.017870618030428886,
            0.004398453049361706,
            0.019157152622938156,
            -0.003904825309291482,
            0.02521257847547531,
            0.034379031509160995,
            -0.005383869633078575,
            0.029467783868312836,
            -0.013142629526555538,
            -0.005172205623239279,
            0.03783634305000305,
            0.012167317792773247,
            -0.01861461065709591,
            -0.019237518310546875,
            -0.02316957153379917,
            -0.045214541256427765,
            0.04167668893933296,
            0.021300090476870537,
            -0.03277028724551201,
            0.03702697902917862,
            -0.02865474857389927,
            0.000456217530881986,
            0.022982018068432808,
            0.006706081796437502,
            0.015489327721297741,
            -0.05810842290520668,
            0.02436300367116928,
            0.021169640123844147,
            0.006687811575829983,
            0.013161658309400082,
            -0.008381225168704987,
            0.020076820626854897,
            0.010558271780610085,
            0.005241446662694216,
            -0.05301620066165924,
            -0.028176693245768547,
            0.005660086404532194,
            0.022201240062713623,
            -0.014140943996608257,
            0.00901022832840681,
            0.008734060451388359,
            -0.025444138795137405,
            -0.04804554581642151,
            -0.026459861546754837,
            0.008749856613576412,
            -0.04326941817998886,
            -0.04647838696837425,
            0.030108438804745674,
            0.007061944343149662,
            -0.0269614364951849,
            -0.001421685446985066,
            -0.005130166187882423,
            0.016487019136548042,
            -0.031704846769571304,
            -0.06994003057479858,
            -0.026950454339385033,
            -0.0021286695264279842,
            -0.01902090013027191,
            0.017283210530877113,
            -0.03361055254936218,
            -0.0003352730127517134,
            -0.06147140637040138,
            -0.0023634429089725018,
            0.03552108258008957,
            0.017784681171178818,
            0.05474582687020302,
            -0.0012636554893106222,
            -0.0015092462999746203,
            0.0451049879193306,
            0.04294428601861,
            0.02424844726920128,
            -0.00634587649255991,
            -0.001295994152314961,
            -0.05833839625120163,
            0.011801134794950485,
            -0.031032808125019073,
            0.0009115234133787453,
            0.009258069097995758,
            -0.008646630682051182,
            0.018484754487872124,
            -0.010962175205349922,
            0.002747616497799754,
            -0.011908184736967087,
            0.043974243104457855,
            -0.025528114289045334,
            -0.012608836404979229,
            -0.03977365419268608,
            0.011517252773046494,
            0.018680380657315254,
            0.04343117028474808,
            -0.01773059368133545,
            0.035701338201761246,
            -0.0051558720879256725,
            0.03505383059382439,
            -0.03377977013587952,
            0.01595311053097248,
            -0.001075955224223435,
            -0.023892048746347427,
            0.015072138980031013,
            0.057055287063121796,
            -0.011706061661243439,
            -0.012253460474312305,
            0.010935164988040924,
            -0.009846233762800694,
            -0.030153077095746994,
            -0.01008412055671215,
            0.040313731878995895,
            -0.029301367700099945,
            -0.010889588855206966,
            -0.0010296074906364083,
            0.037283238023519516,
            0.024928824976086617,
            0.04871236905455589,
            -0.05577891692519188,
            -0.014206991530954838,
            0.002824941650032997,
            0.01916312612593174,
            0.02923274040222168,
            -0.019616074860095978,
            -0.018220406025648117,
            -0.04002860561013222,
            -0.031048575416207314,
            0.02952895499765873,
            -0.01839764229953289,
            -0.06896116584539413,
            0.01583710126578808,
            -0.046554770320653915,
            0.010435808449983597,
            -0.009896713308990002,
            -0.013190713711082935,
            0.043294817209243774,
            -0.006280327215790749,
            0.04216679930686951,
            -0.002004951937124133,
            0.005461134482175112,
            -0.010303650051355362,
            0.021607302129268646,
            0.04930191487073898,
            -0.002891347510740161,
            0.0007288300548680127,
            0.005052801221609116,
            0.022279296070337296,
            -0.015148965641856194,
            -0.03630559891462326,
            -0.03421347960829735,
            -0.015981709584593773,
            -0.011737027205526829,
            0.013728642836213112,
            0.06397029012441635,
            0.002718178788200021,
            -0.008654453791677952,
            -0.03319397568702698,
            -0.014961902983486652,
            0.0061187283135950565,
            -0.016878295689821243,
            -0.01671258918941021,
            -0.005799720995128155,
            -0.028664564713835716,
            0.011669158935546875,
            -0.015049073845148087,
            -0.003940237686038017,
            0.043847765773534775,
            0.032932355999946594,
            -0.0235364381223917,
            -0.016190819442272186,
            -0.003944915719330311,
            0.046895891427993774,
            0.06481844186782837,
            0.017525678500533104,
            -0.03675365075469017,
            -0.011044153943657875,
            -0.025248033925890923,
            -0.028906574472784996,
            -0.05473173409700394,
            0.005433519370853901,
            0.023095402866601944,
            0.032164838165044785,
            0.0076710074208676815,
            -0.016506429761648178,
            0.007302565034478903,
            0.021786946803331375,
            0.032559871673583984,
            -0.03434925898909569,
            -0.012845502234995365,
            -0.018519025295972824,
            -0.02637208066880703,
            -0.06588727235794067,
            -0.008808541111648083,
            0.016026457771658897,
            -0.0047131250612437725,
            0.010800985619425774,
            -0.016596591100096703,
            -0.007787995971739292,
            -0.002657234203070402,
            -0.04634034261107445,
            0.008109955117106438,
            -0.016433551907539368,
            0.0023815976455807686,
            0.012470394372940063,
            -0.0390310063958168,
            -0.0011985930614173412,
            -0.06952852755784988,
            -0.004637193866074085,
            -0.021721480414271355,
            0.017034661024808884,
            -0.03656255081295967,
            0.03472870588302612,
            0.04244989901781082,
            0.004868257325142622,
            0.013080956414341927,
            0.0004626504669431597,
            -0.027490835636854172,
            0.0017947031883522868,
            0.010393929667770863,
            -0.010740666650235653,
            0.01942865177989006,
            -0.02286786586046219,
            -0.029754791408777237,
            -0.028994308784604073,
            0.004598075058311224,
            0.021910298615694046,
            0.0018505407497286797,
            -0.04438675940036774,
            0.010601161047816277,
            0.034782763570547104,
            -0.01907394640147686,
            -0.025912107899785042,
            0.02641371823847294,
            -0.026302913203835487,
            -0.020019495859742165,
            -0.015115142799913883,
            0.007884206250309944,
            0.0015904100146144629,
            0.020714543759822845,
            0.0014664862537756562,
            -0.006511999294161797,
            -0.03873338922858238,
            0.014292030595242977,
            -0.014872179366648197,
            -0.018170369789004326,
            0.026272471994161606,
            0.0006087139481678605,
            0.002246958203613758,
            -0.03628399223089218,
            0.018127549439668655,
            0.03326554596424103,
            0.0009621462086215615,
            0.013532680459320545,
            0.0009939989540725946,
            -0.027938557788729668,
            -0.03766932711005211,
            0.061568841338157654,
            -0.0034903583582490683,
            0.002824719762429595,
            -0.004149001557379961,
            0.008751926012337208,
            0.012086817063391209,
            -0.03953257575631142,
            0.020838014781475067,
            0.00955707672983408,
            -0.009535268880426884,
            0.040037546306848526,
            0.0012242307420819998,
            -0.010291153565049171,
            -0.02465609833598137,
            -0.017450900748372078,
            0.031139705330133438,
            -0.006900911219418049,
            -0.005131306126713753,
            -0.026010440662503242,
            0.022945571690797806,
            0.047690797597169876,
            -0.004622885957360268,
            0.01920640841126442,
            0.0008223306504078209,
            -0.006804059259593487,
            -0.002755439607426524,
            -0.011236341670155525,
            -0.015679024159908295,
            -0.006816101726144552,
            0.026018012315034866,
            0.01821555756032467,
            0.046082753688097,
            -0.010225004516541958,
            -0.07719165831804276,
            0.03043726459145546,
            0.03411481902003288,
            -0.05339387431740761,
            -0.02351601980626583,
            -0.0024407499004155397,
            -0.0026466851122677326,
            0.013954711146652699,
            -0.016301503404974937,
            -0.0007326476625166833,
            0.011744937859475613,
            0.03048744983971119,
            0.04395396262407303,
            0.0013559659710153937,
            -0.02509106509387493,
            -0.026646306738257408,
            0.0033797025680541992,
            0.03659621253609657,
            0.005564728286117315,
            -0.0169981662184,
            0.0075156898237764835,
            -0.0003016610280610621,
            -0.029597096145153046,
            -0.029863370582461357,
            -0.034240249544382095,
            -0.017557496204972267,
            -0.007536145392805338,
            0.00996545422822237,
            0.03555847704410553,
            -0.012771972455084324,
            0.024959512054920197,
            -0.01674318127334118,
            -0.031227316707372665,
            -0.015451460145413876,
            0.026404062286019325,
            0.07385193556547165,
            -0.034252166748046875,
            0.04105982556939125,
            -0.0012037078849971294,
            -0.012503956444561481,
            0.01278562843799591,
            -0.03152109682559967,
            0.01657397300004959,
            -0.01859351433813572,
            -0.008431953378021717,
            -0.016045689582824707,
            0.006761685013771057,
            0.015441243536770344,
            -0.004449901171028614,
            0.04901296645402908,
            -0.009621640667319298,
            0.012999601662158966,
            -0.005534669384360313,
            -0.023495642468333244,
            -0.023928940296173096,
            -0.05649739131331444,
            -0.03603016212582588,
            0.018486078828573227,
            -0.018378712236881256,
            -0.029766378924250603,
            -0.010574458166956902,
            -0.010909090749919415,
            0.011342614889144897,
            0.03819001466035843,
            0.015620358288288116,
            0.031523775309324265,
            0.005816421937197447,
            -0.016771512106060982,
            -0.028598733246326447,
            -0.002226686803624034,
            -0.04542691260576248,
            0.023646797984838486,
            -0.02713468298316002,
            0.0385485477745533,
            -0.02251609042286873,
            -0.02886788547039032,
            -0.01696186326444149,
            -0.018878651782870293,
            0.013473693281412125,
            -0.017862094566226006,
            0.016533218324184418,
            0.023485399782657623,
            -0.02470812015235424,
            0.0075634135864675045,
            -0.02798357419669628,
            0.01863308809697628,
            -0.023151056841015816,
            -0.030512424185872078,
            0.006581663154065609,
            -0.0048416992649436,
            0.004985531326383352,
            0.05131345987319946,
            -0.00388913881033659,
            -0.03628508001565933,
            -0.03426123410463333,
            -0.044288843870162964,
            0.018092753365635872,
            0.014219984412193298,
            -0.0008056429214775562,
            -0.0048625958152115345,
            -0.014743907377123833,
            -0.06777641177177429,
            0.0061524719931185246,
            -0.021501295268535614,
            0.003466558875516057,
            0.02319890260696411,
            -0.08309143781661987,
            -0.028293238952755928,
            -0.02219432406127453,
            -0.032641470432281494,
            -0.005789391230791807,
            -0.028500696644186974,
            -0.008802223950624466,
            0.026917891576886177,
            0.011507568880915642,
            0.016479315236210823,
            0.021066244691610336,
            0.009363668039441109,
            -0.003973965998739004,
            0.022868748754262924,
            0.00672933179885149,
            0.03988867625594139,
            -0.01303705945611,
            0.009865480475127697,
            0.009648468345403671,
            0.01488238200545311,
            0.01572546176612377,
            0.024837784469127655,
            0.010069821029901505,
            -0.011895492672920227,
            0.0033132615499198437,
            0.017697656527161598,
            0.023458190262317657,
            -0.01983547955751419,
            -0.003912766929715872,
            0.03005608730018139,
            0.03747258335351944,
            0.01603369042277336,
            -0.010328195057809353,
            0.040900424122810364,
            0.028281230479478836,
            0.02624349482357502,
            0.0020345149096101522,
            -0.011265668086707592,
            -0.028043905273079872,
            -0.019111163914203644,
            -0.03496694937348366,
            -0.047653257846832275,
            0.02127573825418949,
            -0.011718825437128544,
            0.009713844396173954,
            -0.03590025752782822,
            0.009666960686445236,
            0.02257448621094227,
            0.023965904489159584,
            0.04367811605334282,
            -0.003912946674972773,
            0.0336303636431694,
            0.021653013303875923,
            -0.02419116720557213,
            -0.015507359988987446,
            -0.011008050292730331,
            -0.008077003061771393,
            -0.017332231625914574,
            -0.030878698453307152,
            0.00579396216198802,
            -0.020656505599617958,
            0.008483919315040112,
            0.017391294240951538,
            -0.0640215203166008,
            -0.050044216215610504,
            0.012447592802345753,
            9.436673281015828e-05,
            -0.02948976308107376,
            0.008645781315863132,
            0.013580257073044777,
            0.02344847284257412,
            0.021145060658454895,
            -0.017232827842235565,
            0.007266873959451914,
            0.011123687960207462,
            -0.04791049659252167,
            0.017273327335715294,
            -0.02073267288506031,
            0.02718428522348404,
            0.014443748630583286,
            -0.014938835054636002,
            -0.022540505975484848,
            0.050344716757535934,
            0.01825588569045067,
            -0.0557728037238121,
            0.0010987611021846533,
            0.009760323911905289,
            -0.011788683943450451,
            0.03278195485472679,
            0.03827330470085144,
            0.0376148521900177,
            0.01790904439985752,
            0.02101987786591053,
            0.015969060361385345,
            0.05026478320360184,
            0.0323028601706028,
            -0.029023630544543266,
            0.023404162377119064,
            -0.02461111918091774,
            0.03966003283858299,
            -0.011193799786269665,
            -0.0386245995759964,
            -0.03242119029164314,
            0.026829028502106667,
            -0.02716531604528427,
            -0.0167445819824934,
            0.06112419813871384,
            -0.0285521000623703,
            -0.006171199027448893,
            0.03111460618674755,
            0.01379944197833538,
            -0.03485434502363205,
            -0.00385547848418355,
            0.023016350343823433,
            0.014222760684788227,
            -0.038131967186927795,
            -0.02806677483022213,
            0.011107048019766808,
            0.005518179852515459,
            0.006722572725266218,
            -0.003120057052001357,
            0.0054894546046853065,
            -0.03326193615794182,
            0.042070422321558,
            -0.035610392689704895,
            -0.05498645454645157,
            0.006240619346499443,
            0.008095991797745228,
            0.02388589270412922,
            -0.01741470769047737,
            -0.009342832490801811,
            -0.027142243459820747,
            0.03544650599360466,
            0.010145457461476326,
            0.02242131158709526,
            -0.008330302312970161,
            0.025464702397584915,
            -0.000786187534686178,
            0.05451108515262604,
            0.0018100274028256536,
            0.058087460696697235,
            0.020382417365908623,
            -0.009080245159566402,
            0.028842076659202576,
            -0.008968251757323742,
            -0.010675734840333462,
            -0.023056164383888245,
            -0.025945385918021202,
            0.02525956928730011,
            -0.010756962932646275,
            0.016964511945843697,
            -0.004131740890443325,
            0.05444907769560814,
            -0.03827459365129471,
            0.02152843400835991,
            -0.00485397269949317,
            -0.014842619188129902,
            -0.02580525353550911,
            -0.018085438758134842,
            0.004063946660608053,
            -0.0225201565772295,
            0.02514449506998062,
            -0.002558541251346469,
            -0.01838774047791958,
            0.020563703030347824,
            0.003840675577521324,
            -0.013443563133478165,
            0.0260208398103714,
            -0.021491076797246933,
            0.007772686425596476,
            -0.009843403473496437,
            0.009256581775844097,
            -0.018212974071502686,
            -0.0235335361212492,
            0.01044439896941185,
            0.02519102580845356,
            0.023306380957365036,
            -0.024341316893696785,
            -0.005176902282983065,
            -0.028739813715219498,
            -0.023775745183229446,
            0.029469424858689308,
            0.007832991890609264,
            -0.03501490131020546,
            -0.0004087576817255467,
            -0.006871286779642105,
            -0.03198179230093956,
            0.05186907947063446,
            0.019724948331713676,
            -0.028601687401533127,
            -0.002807487268000841,
            0.0476464219391346,
            0.046369440853595734,
            -0.012273242697119713,
            -0.03806304559111595,
            -0.018213864415884018,
            -0.0019707076717168093,
            0.040879134088754654,
            0.03427840396761894,
            0.051949065178632736,
            -0.0116964066401124,
            0.03722325712442398,
            0.027452891692519188,
            -0.03270440921187401,
            0.01768362522125244,
            0.018485216423869133,
            0.08561115711927414,
            -0.029094263911247253,
            -0.007835791446268559,
            -0.02034611813724041,
            0.007280481047928333,
            0.06557101756334305,
            0.026412783190608025,
            0.002196335233747959,
            -0.027858706191182137,
            -0.0026758478488773108,
            0.012945882976055145,
            0.021813396364450455,
            -0.006889400538057089,
            0.04258996993303299,
            0.009908078238368034,
            0.058163996785879135,
            -0.010742033831775188,
            -0.007089496590197086,
            -0.040153685957193375,
            0.003914430271834135,
            0.04838530719280243,
            0.03239799290895462,
            -0.00034979297197423875,
            -0.007501216139644384,
            -0.03650110214948654,
            -0.010987939313054085,
            0.014527231454849243,
            0.010829045437276363,
            0.00969952717423439,
            0.01710326410830021,
            0.03171999007463455,
            0.011347572319209576,
            0.006049013696610928,
            0.021027758717536926,
            -0.02484569512307644,
            0.01327814906835556,
            -0.06235892325639725,
            -0.005457695573568344,
            0.008873107843101025,
            0.013329013250768185,
            -0.008845909498631954,
            0.017028117552399635,
            0.01270122081041336,
            0.014134700410068035,
            -0.018721027299761772,
            0.021600253880023956,
            -0.001462643500417471,
            0.022018563002347946,
            0.02963876724243164,
            -0.014469023793935776,
            0.013770072720944881,
            0.027258776128292084,
            0.02638528123497963,
            -0.025491593405604362,
            0.005707683973014355,
            0.005380420479923487,
            0.010056929662823677,
            0.026006251573562622,
            -0.010542075149714947,
            0.027544712647795677,
            0.011016559787094593,
            0.003111735451966524,
            -0.02242499403655529,
            -0.011195068247616291,
            -0.04204288497567177,
            0.016299866139888763,
            -0.018349815160036087,
            0.03105015680193901,
            0.018474532291293144,
            0.01730487309396267,
            0.009257201105356216,
            -0.054391033947467804,
            -0.015380279161036015,
            0.022768869996070862,
            -0.0319758802652359,
            0.009346798062324524,
            -0.011470435187220573,
            0.007973872125148773,
            0.040160052478313446,
            -0.027951443567872047,
            -0.013254028744995594,
            0.08001894503831863,
            0.008463572710752487,
            0.015573625452816486,
            0.03986912593245506,
            -0.0048093050718307495,
            -0.01276556495577097,
            0.003186179092153907,
            0.03974774852395058,
            0.02467232011258602,
            -0.018627271056175232,
            0.039621416479349136,
            -0.015563251450657845,
            0.019657911732792854,
            -0.01924455724656582,
            -0.026574527844786644,
            0.001062441966496408,
            0.03210936859250069,
            0.0089497035369277,
            -0.0402231328189373,
            -0.017807411029934883,
            -0.0372966006398201,
            0.013188701122999191,
            0.02836124785244465,
            0.032195039093494415,
            0.02360456995666027,
            -0.013904666528105736,
            -0.008356383070349693,
            -0.02905218116939068,
            0.03286290168762207,
            0.02196233533322811,
            0.025315357372164726,
            -0.02267594449222088,
            -0.019597915932536125,
            0.005295981653034687,
            0.025906527414917946,
            -0.014276714995503426,
            0.025899773463606834,
            0.008755079470574856,
            -0.034775808453559875,
            0.02692342922091484,
            0.03697044402360916,
            0.024452820420265198,
            0.017465928569436073,
            -0.03671196848154068,
            -0.023345813155174255,
            0.007842098362743855,
            0.007504575420171022,
            0.02269061654806137,
            -0.022713543847203255,
            0.04683861508965492,
            -0.031546451151371,
            -0.001477812766097486,
            0.016897201538085938,
            -0.014967535622417927,
            -0.005048501770943403,
            0.023801645264029503,
            0.006482558324933052,
            0.030879050493240356,
            -0.022739481180906296,
            -0.014791704714298248,
            0.0256143007427454,
            -0.021813472732901573,
            0.024577096104621887,
            -0.005400809925049543,
            -0.0057164146564900875,
            -0.007410549558699131
        ],
        [
            -0.04221264645457268,
            -0.017061056569218636,
            -0.017788276076316833,
            -0.003842052072286606,
            0.029768791049718857,
            0.006959921680390835,
            0.033667173236608505,
            0.034789130091667175,
            -0.004548753146082163,
            0.015837816521525383,
            0.00026867346605286,
            0.020509300753474236,
            -0.006971409544348717,
            0.030932528898119926,
            0.011971734464168549,
            -0.043201468884944916,
            0.044092223048210144,
            0.022604981437325478,
            0.014186837710440159,
            -0.010287129320204258,
            0.010832265019416809,
            0.026612214744091034,
            0.034023843705654144,
            0.029145875945687294,
            -0.03250991925597191,
            0.007486732676625252,
            0.03868122398853302,
            -0.0027267124969512224,
            -0.02019352652132511,
            0.02609109692275524,
            0.03467734530568123,
            -0.004851838108152151,
            0.020385514944791794,
            0.011951474472880363,
            0.010205928236246109,
            -0.00029234433895908296,
            0.025365963578224182,
            0.024649057537317276,
            0.011240498162806034,
            0.004800666123628616,
            0.00021426216699182987,
            -0.004320784471929073,
            0.04007388651371002,
            -0.0009718660730868578,
            -0.018313799053430557,
            0.01337568461894989,
            0.014891481958329678,
            -0.02500178851187229,
            0.02395186573266983,
            0.011495516635477543,
            -0.02067170850932598,
            -0.009282777085900307,
            -0.005376406479626894,
            0.020877894014120102,
            0.010511355474591255,
            0.02345629408955574,
            0.009428167715668678,
            0.007437476888298988,
            -0.014520598575472832,
            -0.010939554311335087,
            0.00030533026438206434,
            0.010137978941202164,
            -0.037854257971048355,
            0.0044387336820364,
            -0.004492170177400112,
            0.01820407435297966,
            0.00309100397862494,
            -0.008728799410164356,
            0.00924281869083643,
            0.01341975387185812,
            0.03186441585421562,
            0.025599835440516472,
            -0.0034058501478284597,
            -0.014767211861908436,
            -0.01095630880445242,
            0.039312105625867844,
            0.022295907139778137,
            -0.004194091074168682,
            -0.08221966028213501,
            0.012253510765731335,
            0.0024698427878320217,
            0.00876790750771761,
            0.0019176056375727057,
            -0.015029214322566986,
            -0.027968566864728928,
            -0.017056697979569435,
            0.0008987387409433722,
            0.025202030315995216,
            -0.03625607118010521,
            -0.08077111840248108,
            0.026305200532078743,
            0.016655176877975464,
            -0.025270329788327217,
            -0.02134404331445694,
            0.04049323499202728,
            -0.01903802715241909,
            -0.013156047090888023,
            0.006233539432287216,
            0.051273901015520096,
            0.028947528451681137,
            -0.03735434263944626,
            -0.024657344445586205,
            -0.01013348437845707,
            0.021164871752262115,
            0.03603019565343857,
            -0.005815907381474972,
            0.03216582536697388,
            0.01651313155889511,
            0.015785684809088707,
            -0.010669471696019173,
            -0.027210893109440804,
            0.029925644397735596,
            -0.003355751046910882,
            -0.01932413876056671,
            0.03523563593626022,
            0.032506365329027176,
            -0.005308621563017368,
            -0.06417401880025864,
            0.02836015820503235,
            0.01599348895251751,
            0.02815920300781727,
            -0.015000992454588413,
            -0.004225080832839012,
            -0.011402311734855175,
            0.031603749841451645,
            0.008705069310963154,
            0.0026886495761573315,
            0.012021055445075035,
            0.007263071835041046,
            0.042796194553375244,
            -0.013971759006381035,
            -0.05149019509553909,
            0.0479174442589283,
            -0.03695081174373627,
            -0.0033382235560566187,
            0.017617469653487206,
            0.013900237157940865,
            -0.003895155154168606,
            0.002030625706538558,
            0.0016581269446760416,
            0.0244565699249506,
            -0.022654660046100616,
            -0.026866089552640915,
            0.01376225333660841,
            -0.00813056156039238,
            0.0125588895753026,
            0.03641388565301895,
            0.03301727771759033,
            0.005115753971040249,
            0.0008068283786997199,
            0.03353392332792282,
            -0.007783367298543453,
            0.0016521235229447484,
            0.004042548127472401,
            -0.02675972320139408,
            -0.026731010526418686,
            0.02773630991578102,
            0.03692617267370224,
            0.022812040522694588,
            0.028618071228265762,
            0.011293650604784489,
            0.016084903851151466,
            -0.0015476427506655455,
            0.02450268529355526,
            0.014131864532828331,
            -0.04648607224225998,
            0.0325610488653183,
            -0.03768147528171539,
            -0.049908459186553955,
            0.02497868239879608,
            -0.021881457418203354,
            0.030481945723295212,
            0.0020678401924669743,
            -0.03629579022526741,
            0.018583428114652634,
            0.022678831592202187,
            -0.0017748400568962097,
            0.024511022493243217,
            -0.01828562468290329,
            0.03515709191560745,
            0.00900551863014698,
            -0.028432490304112434,
            -0.017162436619400978,
            -0.0045637097209692,
            0.016092482954263687,
            -0.002891578245908022,
            0.005491860676556826,
            -0.03247002139687538,
            0.00805511325597763,
            0.01727575808763504,
            -0.010874392464756966,
            0.014315234497189522,
            -0.03983577340841293,
            0.0004494541499298066,
            0.001712585217319429,
            0.016853125765919685,
            0.0026238171849399805,
            0.014568272978067398,
            -0.014859753660857677,
            0.018278149887919426,
            -0.010899691842496395,
            0.002780953887850046,
            -0.04715780168771744,
            0.021319877356290817,
            -0.03394520655274391,
            0.02581770159304142,
            -0.01713278703391552,
            0.0190440583974123,
            0.0071319337002933025,
            -0.0008427452994510531,
            -0.006099160294979811,
            -0.013624435290694237,
            0.005731908604502678,
            -0.04494340345263481,
            0.006894419901072979,
            0.008046367205679417,
            -0.022770607843995094,
            0.02465425431728363,
            -0.023354973644018173,
            -0.031073756515979767,
            -0.0277978777885437,
            0.020722193643450737,
            0.003164165886119008,
            0.03426000103354454,
            -0.004535972606390715,
            0.018082328140735626,
            0.031438592821359634,
            0.02603720687329769,
            -0.005521424580365419,
            -0.00682816980406642,
            -0.040246788412332535,
            0.014974811114370823,
            0.0323173925280571,
            -0.017894791439175606,
            -0.018509378656744957,
            -0.018575601279735565,
            -0.016152210533618927,
            -0.0333830825984478,
            -0.006153617985546589,
            0.005710621830075979,
            -0.010282423347234726,
            0.03176352381706238,
            -0.004811736289411783,
            0.018550250679254532,
            -0.01805208809673786,
            0.0017033983021974564,
            0.017480485141277313,
            0.0015497837448492646,
            -0.00790809653699398,
            -0.031797461211681366,
            0.015235625207424164,
            0.03277480602264404,
            -0.04821975529193878,
            0.02012445777654648,
            -0.013328256085515022,
            0.015685511752963066,
            0.014932208694517612,
            -0.023136578500270844,
            0.01001936849206686,
            0.043886248022317886,
            -0.04651506617665291,
            -0.028619905933737755,
            -0.02837834320962429,
            0.007435103412717581,
            0.005666253622621298,
            -0.0008150591747835279,
            0.013818497769534588,
            -0.010774411261081696,
            -0.006504802033305168,
            -0.057595208287239075,
            -0.009997567161917686,
            0.01074376329779625,
            0.014451324008405209,
            -0.02546127326786518,
            -0.0002719609474297613,
            -0.025168519467115402,
            0.02624339610338211,
            0.011144652031362057,
            -0.004219782538712025,
            0.011006772518157959,
            -0.03585034981369972,
            -0.010774971917271614,
            -0.011687551625072956,
            0.011307322420179844,
            -0.028228241950273514,
            -0.006867998279631138,
            -0.005459215957671404,
            0.02313697151839733,
            0.03006410226225853,
            -0.02507178485393524,
            -0.00022367830388247967,
            0.02192430943250656,
            0.016752570867538452,
            0.00493319146335125,
            0.03244341164827347,
            0.02795756608247757,
            0.0050667268224060535,
            -0.005597066134214401,
            -0.005768272560089827,
            -0.003219245234504342,
            0.016785211861133575,
            -0.013410564512014389,
            0.005751566495746374,
            -0.057526297867298126,
            0.006827775854617357,
            -0.017416631802916527,
            0.0024589349050074816,
            -0.03399407118558884,
            -0.03574031591415405,
            -0.021442735567688942,
            -0.03844784200191498,
            0.016367735341191292,
            0.022355489432811737,
            0.03555997088551521,
            -0.027296844869852066,
            0.025557084009051323,
            -0.01762908697128296,
            -0.03276568651199341,
            -0.02275063470005989,
            0.03591784089803696,
            -0.023291151970624924,
            -0.013838413171470165,
            -0.01521351933479309,
            -0.05346698686480522,
            -0.004755333531647921,
            -0.01701909489929676,
            0.007792622782289982,
            -0.0038625674787908792,
            0.02724681794643402,
            0.03279172256588936,
            0.01918972283601761,
            0.01707782968878746,
            0.0005483451532199979,
            0.031111683696508408,
            0.027838097885251045,
            -0.02141307108104229,
            -0.030620809644460678,
            0.000456701178336516,
            -0.029258225113153458,
            0.00898419413715601,
            -0.005193296819925308,
            0.04781050607562065,
            0.013476940803229809,
            0.00882529653608799,
            -0.028486622497439384,
            0.004033176228404045,
            -0.0006276809726841748,
            0.02673587016761303,
            -0.010306181386113167,
            0.0680590569972992,
            0.03705066069960594,
            -0.05820143595337868,
            -0.030877338722348213,
            -0.003605036297813058,
            0.015770986676216125,
            -0.032144784927368164,
            0.028089292347431183,
            -0.036110952496528625,
            -0.016735859215259552,
            -0.0034540847409516573,
            0.02832513302564621,
            -0.029068997129797935,
            0.016998693346977234,
            0.0077873761765658855,
            0.03023422695696354,
            -0.0011979039991274476,
            -0.05030060186982155,
            0.02772965095937252,
            -0.004930143244564533,
            -0.03454551845788956,
            -0.02496817521750927,
            0.01457237172871828,
            0.03490084409713745,
            -0.024077359586954117,
            0.0069481912069022655,
            0.006801856681704521,
            -0.005709679797291756,
            0.0006657078629359603,
            -0.008426177315413952,
            0.024153951555490494,
            0.010582310147583485,
            -0.010103095322847366,
            -0.037532661110162735,
            -0.011375275440514088,
            0.013718822970986366,
            0.0043272776529192924,
            -0.0053160060197114944,
            0.009881746955215931,
            -0.025371931493282318,
            0.016809072345495224,
            -0.027499830350279808,
            0.03954869508743286,
            0.007716970983892679,
            -0.00875130295753479,
            0.07244978100061417,
            0.008112580515444279,
            0.035980138927698135,
            0.012518569827079773,
            0.0030153044499456882,
            0.0254982803016901,
            0.008511199615895748,
            0.028668412938714027,
            0.0336865670979023,
            -0.03764283284544945,
            0.02166643925011158,
            0.007812573574483395,
            -0.003416352905333042,
            -0.0036494890227913857,
            -0.005118647124618292,
            0.024074358865618706,
            0.02590656653046608,
            -0.010878787375986576,
            -0.022877397015690804,
            -0.008113552816212177,
            -0.03146664798259735,
            0.024059124290943146,
            0.005648529157042503,
            -0.0008932964992709458,
            -0.021386733278632164,
            -0.018438071012496948,
            0.01732795313000679,
            -0.03579048439860344,
            0.004342266824096441,
            -0.031241677701473236,
            0.03139284998178482,
            -0.023919248953461647,
            -0.03654087707400322,
            0.011556913144886494,
            0.009855132550001144,
            0.005470631178468466,
            0.027454981580376625,
            -0.017207834869623184,
            -0.040437448769807816,
            -0.015594088472425938,
            -0.027323981747031212,
            -0.015109403058886528,
            -0.02626762166619301,
            -0.01779683120548725,
            0.013561025261878967,
            0.03377753123641014,
            -0.00807309988886118,
            -0.020934857428073883,
            0.021847549825906754,
            -0.02720882184803486,
            0.014833319932222366,
            0.016531025990843773,
            -0.016024021431803703,
            0.009398858062922955,
            -0.02530948631465435,
            0.023211447522044182,
            -0.039806801825761795,
            -0.029367350041866302,
            -0.04000763222575188,
            -0.017182940617203712,
            -0.01955931819975376,
            0.015527359209954739,
            -0.03678073361515999,
            0.006015158724039793,
            0.020906174555420876,
            0.025971604511141777,
            -0.0037934354040771723,
            -0.017170902341604233,
            0.016971919685602188,
            -0.010598637163639069,
            -0.012614273466169834,
            0.013875199481844902,
            0.02627464197576046,
            0.047478653490543365,
            -0.025146281346678734,
            -0.026955056935548782,
            -0.0036307533737272024,
            0.02272920496761799,
            -0.0012799554970115423,
            0.0471949502825737,
            0.003719428088515997,
            -0.011885267682373524,
            0.022238126024603844,
            -0.052988894283771515,
            -0.022896716371178627,
            -0.024525167420506477,
            0.01933099329471588,
            -0.024098340421915054,
            -0.05502717196941376,
            -0.02467818185687065,
            -0.026877103373408318,
            -0.0037105735391378403,
            -0.011220709420740604,
            0.006381999235600233,
            -0.08679229766130447,
            -0.008662951178848743,
            -0.04339854419231415,
            -0.02296452969312668,
            0.002034358447417617,
            0.04449474811553955,
            -0.045677121728658676,
            -0.0001222201535711065,
            0.0309886634349823,
            0.01292099803686142,
            -0.006722817663103342,
            -0.02047840505838394,
            -0.018640320748090744,
            -0.011145555414259434,
            0.02977360598742962,
            0.00677134795114398,
            0.01634056679904461,
            0.006285049486905336,
            0.0065253786742687225,
            -0.014753570780158043,
            -0.04007565602660179,
            0.02759096957743168,
            -0.024490829557180405,
            -0.03125261515378952,
            0.00015820223779883236,
            -0.024147624149918556,
            -0.005479462910443544,
            -0.015969378873705864,
            -0.00909416563808918,
            0.030974242836236954,
            0.023302525281906128,
            0.01246120035648346,
            -0.023901110514998436,
            -0.007320358883589506,
            -0.024469096213579178,
            0.02676095813512802,
            -0.035479698330163956,
            -0.0732308104634285,
            -0.06885065138339996,
            0.023622598499059677,
            -0.0020777182653546333,
            -0.013448801822960377,
            0.03306454047560692,
            0.0011716553708538413,
            -0.005772337317466736,
            -0.06082532927393913,
            -0.013089152052998543,
            -0.019938353449106216,
            -0.0190898384898901,
            0.01995084621012211,
            -0.011993926018476486,
            0.021109841763973236,
            -0.018628356978297234,
            0.04220892861485481,
            0.04036858305335045,
            0.003120765555649996,
            0.013925651088356972,
            -0.0207372959703207,
            0.024555882439017296,
            -0.045129675418138504,
            0.02758377604186535,
            -0.027568520978093147,
            -0.0012410936178639531,
            0.021279476583003998,
            -0.03472299873828888,
            -0.0036308346316218376,
            -0.028113173320889473,
            -0.009827321395277977,
            -0.008055338636040688,
            0.019752996042370796,
            -0.01662677899003029,
            -0.015352552756667137,
            -0.024647917598485947,
            0.03790508210659027,
            -0.014636201784014702,
            0.01936488226056099,
            0.03156282752752304,
            0.03610552102327347,
            0.004102570470422506,
            0.01758270338177681,
            0.00714477663859725,
            -0.008234580047428608,
            0.009092013351619244,
            0.02184196375310421,
            -0.00023777619935572147,
            0.013407641090452671,
            0.03050442785024643,
            0.010415998287498951,
            -0.03879503160715103,
            0.023363608866930008,
            -0.027160005643963814,
            0.028996475040912628,
            -0.0031121186912059784,
            0.014333762228488922,
            0.025632446631789207,
            -0.020974768325686455,
            0.004827625583857298,
            0.03390144929289818,
            0.027361171320080757,
            -0.012652060948312283,
            -0.016519645228981972,
            -0.004941212013363838,
            -0.013478928245604038,
            0.015752403065562248,
            -0.026604993268847466,
            0.002146261977031827,
            0.00525241531431675,
            -0.02001827210187912,
            -0.002505891490727663,
            0.013290376402437687,
            -0.014811284840106964,
            -0.0027461061254143715,
            0.00192547426559031,
            0.01808840036392212,
            0.00558013329282403,
            0.006037416402250528,
            0.007386260200291872,
            -0.008233984000980854,
            -0.0033541203010827303,
            0.011953017674386501,
            0.05273605138063431,
            0.014600923284888268,
            0.0021818329114466906,
            0.025883449241518974,
            0.03392627462744713,
            0.021432386711239815,
            0.016440607607364655,
            0.00432890048250556,
            -0.0005927177844569087,
            -0.00955069437623024,
            0.009465319104492664,
            0.0034801762085407972,
            0.017012199386954308,
            -0.026484455913305283,
            0.008842073380947113,
            -0.02484505996108055,
            -0.00885422620922327,
            0.0025101476348936558,
            0.014027654193341732,
            -0.026387304067611694,
            0.029701348394155502,
            0.0174378864467144,
            -0.0018502186285331845,
            0.008154756389558315,
            0.020177086815238,
            -0.0021588639356195927,
            -0.026144396513700485,
            -0.01124919019639492,
            -0.030832866206765175,
            -0.01720578223466873,
            0.02323143370449543,
            -0.010068337433040142,
            -0.017555439844727516,
            -0.05134556069970131,
            -0.03147980198264122,
            0.03989778086543083,
            0.02631366066634655,
            -0.02437833696603775,
            -0.018981628119945526,
            -0.006872837897390127,
            0.01837942562997341,
            -0.01585039682686329,
            -0.020117444917559624,
            0.02442196011543274,
            -0.0052747041918337345,
            -0.021230382844805717,
            0.026234010234475136,
            0.031028270721435547,
            -0.026187285780906677,
            0.016310611739754677,
            0.019736887887120247,
            -0.03477760776877403,
            0.031195910647511482,
            0.019101373851299286,
            -0.0043154312297701836,
            -0.02112932875752449,
            0.02276788093149662,
            0.02402777224779129,
            0.0012071331730112433,
            -0.023878229781985283,
            0.027303047478199005,
            -0.012761663645505905,
            -0.0236155204474926,
            0.02212016098201275,
            -0.004108872730284929,
            -0.06124870851635933,
            0.0003225124964956194,
            -0.05461110919713974,
            -0.024324284866452217,
            0.0029407613910734653,
            -0.003426617244258523,
            -0.033612240105867386,
            -0.02905581146478653,
            -0.002809213474392891,
            -0.007046391721814871,
            -0.0199067872017622,
            -0.02223956398665905,
            -0.005352730397135019,
            -0.014024807140231133,
            0.004709704779088497,
            0.01659684255719185,
            -0.03748500347137451,
            0.02275785617530346,
            0.01654147543013096,
            -0.04337339848279953,
            0.0035010629799216986,
            -0.020850341767072678,
            -0.011540786363184452,
            -0.014649087563157082,
            0.017079273238778114,
            0.005360940471291542,
            -0.03997179493308067,
            0.022051112726330757,
            0.007398639805614948,
            0.0016039899783208966,
            -0.005211255978792906,
            0.002205118304118514,
            0.0009712990722618997,
            0.016889749094843864,
            0.03741398826241493,
            -0.007867260836064816,
            0.04241763427853584,
            0.016763413324952126,
            0.010117595084011555,
            -0.008161877281963825,
            0.010481729172170162,
            -0.04126201197504997,
            0.023168278858065605,
            0.060578037053346634,
            -0.007173925172537565,
            0.025553731247782707,
            0.0071728965267539024,
            0.025181805714964867,
            -0.00014873332111164927,
            0.02995258755981922,
            -0.030071469023823738,
            0.003837405238300562,
            0.009184458293020725,
            -0.03479449078440666,
            0.0015123533084988594,
            -0.04739382863044739,
            0.005933757405728102,
            0.018191112205386162,
            0.024132119491696358,
            0.01979725994169712,
            0.00711907260119915,
            0.02219420112669468,
            0.0011712269624695182,
            0.01785200648009777,
            -3.977419692091644e-05,
            0.05114169046282768,
            -0.004869138356298208,
            -0.0014035352505743504,
            -0.05404543876647949,
            -0.02382330782711506,
            0.022713487967848778,
            0.0053084674291312695,
            0.0026625357568264008,
            -0.005115974228829145,
            0.031319230794906616,
            -0.008069461211562157,
            -0.029409565031528473,
            0.005507698282599449,
            0.018139826133847237,
            -0.03289506211876869,
            -0.04915040731430054,
            0.016842825338244438,
            0.035729482769966125,
            0.01170589029788971,
            0.06044195964932442,
            -0.04954713210463524,
            -0.02358565665781498,
            -0.06669248640537262,
            0.03690927103161812,
            -0.03225240483880043,
            0.002453009132295847,
            0.006111813243478537,
            -0.02679779753088951,
            -0.0006109931855462492,
            0.032855335623025894,
            0.008019249886274338,
            -0.0009052444365806878,
            -0.018464021384716034,
            -0.01147120539098978,
            -0.009995481930673122,
            -0.03606867417693138,
            0.02369675040245056,
            -0.0039090909995138645,
            -0.024059299379587173,
            -0.038637008517980576,
            -0.010277967900037766,
            0.017761539667844772,
            -0.052001066505908966,
            0.0006221959483809769,
            -0.03568509966135025,
            0.0005372950108721852,
            -0.013649039901793003,
            -0.0068208868615329266,
            -0.05938992649316788,
            0.01981915906071663,
            -0.0033093979582190514,
            0.0007970122969709337,
            0.034084826707839966,
            -0.02051493711769581,
            0.010850836522877216,
            -0.03645024076104164,
            0.0035182274878025055,
            0.0418730303645134,
            -0.002419794676825404,
            -0.0006400548736564815,
            -0.01868058741092682,
            -0.04682525247335434,
            0.025717930868268013,
            -0.0179792158305645,
            0.043900396674871445,
            -0.006541087292134762,
            -0.02850204147398472,
            -0.03555804491043091,
            -0.030206380411982536,
            -0.017603274434804916,
            -0.007947864010930061,
            0.0002448011073283851,
            -0.004692669026553631,
            -0.034231603145599365,
            0.020244015380740166,
            -0.028039108961820602,
            0.0016623850679025054,
            0.0038320557214319706,
            -0.011888233944773674,
            -0.042976025491952896,
            0.00994018279016018,
            -0.02353387139737606,
            -0.000504716532304883,
            0.0004310186195652932,
            -0.01328063104301691,
            0.034291043877601624,
            -0.02261998876929283,
            0.026729542762041092,
            -0.006260605528950691,
            0.01108816172927618,
            -0.009301858954131603,
            -0.033969733864068985,
            -0.019581269472837448,
            0.012794998474419117,
            -0.008431837894022465,
            0.007807021960616112,
            -0.06745447218418121,
            0.024978071451187134,
            0.012040294706821442,
            0.011816334910690784,
            0.022735726088285446,
            0.0017232864629477262,
            -0.010247026570141315,
            -0.03580005094408989,
            -0.022870095446705818,
            -0.011328373104333878,
            0.02799908071756363,
            0.005786573980003595,
            -0.005557668395340443,
            -0.015328818000853062,
            0.049125850200653076,
            0.015073445625603199,
            0.010352148674428463,
            -0.016627391800284386,
            -0.07415688782930374,
            -0.028442300856113434,
            -0.016696743667125702,
            0.0016046540113165975,
            -0.018286792561411858,
            0.01892605796456337,
            -0.006526442244648933,
            -0.033153507858514786,
            -0.00838269293308258,
            -0.007069215178489685,
            0.0024012639187276363,
            -0.028769049793481827,
            -0.004918911028653383,
            0.025913124904036522,
            -0.005471790675073862,
            0.0034251143224537373,
            0.004168073646724224,
            -0.028686385601758957,
            -0.0016106263501569629,
            0.012933780439198017,
            -0.003072879044339061,
            -0.0021770722232759,
            -0.02179737575352192,
            0.010865440592169762,
            -0.016209451481699944,
            -0.07810243964195251,
            0.02359458990395069,
            0.0041915494948625565,
            -0.0037574826274067163,
            0.013316147960722446,
            0.027810577303171158,
            -0.014376197941601276,
            -0.003595928894355893,
            -0.02989153563976288,
            -0.027819737792015076,
            -0.10573207587003708,
            0.004134064074605703,
            0.009755996987223625,
            0.015550553798675537,
            0.015177840366959572,
            0.00811095256358385,
            -0.04114289581775665,
            -0.03101487085223198,
            0.014738495461642742,
            0.0075125908479094505,
            0.0006214720197021961,
            -0.02082347869873047,
            0.014005046337842941,
            0.02340874634683132,
            0.023110203444957733,
            0.024087190628051758,
            -0.00017087135347537696,
            -0.035723745822906494,
            0.021827831864356995,
            -0.007224828936159611,
            0.00786242913454771,
            -0.007678043097257614,
            -0.03340495377779007,
            -0.0016786502674221992,
            0.01611149124801159,
            0.01007198914885521,
            -0.0012399650877341628,
            -0.038038577884435654,
            0.018822655081748962,
            0.024817325174808502,
            -0.0071045588701963425,
            -0.001607244834303856,
            -0.022424178197979927,
            0.028030984103679657,
            -0.0010215999791398644,
            -0.049367986619472504,
            0.02668774500489235,
            -0.05384901165962219,
            -0.04536765068769455,
            0.01231254730373621,
            -0.03176811337471008,
            -0.011104418896138668,
            0.013446367345750332,
            0.022487718611955643,
            0.014741147868335247,
            0.00983683206140995,
            -0.007799725979566574,
            -0.0178920179605484,
            -0.016383763402700424,
            0.03191237896680832,
            -0.004209994338452816,
            0.03175292909145355,
            0.0634768009185791,
            0.031223461031913757,
            -0.003148955525830388,
            -0.04170325770974159,
            -0.024405939504504204,
            -0.05455091595649719,
            0.0196259506046772,
            -0.017168279737234116,
            -0.005057640373706818,
            0.02252017706632614,
            -0.040168628096580505,
            -0.007017997559159994,
            -0.028767883777618408,
            0.0017594016389921308,
            -0.02298930287361145,
            -0.05633792653679848,
            -0.022258829325437546,
            -0.0035844463855028152,
            0.010880895890295506,
            -0.019840292632579803,
            -0.007543914020061493,
            0.015429938212037086,
            0.02463422529399395,
            0.02191978506743908,
            -0.0038309707306325436,
            0.03556111454963684,
            0.001576849608682096,
            -0.06840144097805023,
            -0.011244555935263634,
            0.0441758893430233,
            0.01909358985722065,
            -0.0022081381175667048,
            0.0247971098870039,
            0.00913646724075079,
            0.007163760717958212,
            0.01848095841705799,
            -0.01894434727728367,
            0.06875663250684738,
            0.00573854660615325,
            0.012150721624493599,
            0.022301409393548965,
            -0.020239582285284996,
            -0.01805306412279606,
            0.003575046081095934,
            -0.01977703720331192,
            0.025569351390004158,
            0.013203109614551067,
            0.024446941912174225,
            0.020694520324468613,
            -0.002935146214440465,
            0.03291675075888634,
            0.015557481907308102,
            0.002936525736004114,
            -0.0116531727835536,
            -0.009536019526422024,
            -0.0061020334251224995,
            -0.031210344284772873,
            0.024203618988394737,
            0.03222673013806343,
            0.03443075716495514,
            -0.004403356462717056,
            0.029624441638588905,
            -0.025279300287365913,
            -0.03155842050909996,
            -0.030176222324371338,
            0.018418997526168823,
            -0.0031977223698049784,
            0.008550706319510937,
            0.006477152928709984,
            0.01890268735587597,
            0.013473108410835266,
            0.0034189200960099697,
            -0.005264752544462681,
            0.0355491004884243,
            0.024925339967012405,
            0.007492211647331715,
            -0.10184388607740402,
            -0.01832856610417366,
            0.022264767438173294,
            0.0030613781418651342,
            -0.015098377130925655,
            0.017689161002635956,
            -0.042849816381931305,
            0.0008240115130320191,
            0.015559469349682331
        ],
        [
            -0.00010287627810612321,
            0.029655905440449715,
            -0.007762337103486061,
            -0.02897615358233452,
            -0.01962115988135338,
            -0.017191758379340172,
            -0.010655508376657963,
            -0.08875225484371185,
            -0.03127821162343025,
            0.01975123956799507,
            -0.02349199913442135,
            0.011901806108653545,
            -0.031871430575847626,
            -0.049678705632686615,
            -0.03286771476268768,
            0.013522259891033173,
            0.005052126478403807,
            -0.003050313564017415,
            -0.006554031278938055,
            -0.006237900350242853,
            -0.0191586185246706,
            -0.001233740826137364,
            -0.043394193053245544,
            -0.020146850496530533,
            -0.02872549369931221,
            -0.029798224568367004,
            0.04850362241268158,
            0.007145427633076906,
            0.0001888924598461017,
            -0.030012793838977814,
            -0.01934553124010563,
            -0.03592013940215111,
            0.008836853317916393,
            0.0019459236646071076,
            -0.02165110409259796,
            -0.012654430232942104,
            0.010091467760503292,
            0.0013349554501473904,
            -0.004926725756376982,
            -0.002877608174458146,
            0.007352596614509821,
            -0.06003735959529877,
            -0.011011109687387943,
            0.009268075227737427,
            0.04301701858639717,
            -0.08633284270763397,
            8.106960194709245e-06,
            -0.002242474118247628,
            -0.012798456475138664,
            -0.05079798027873039,
            -0.01211187057197094,
            -0.0027261730283498764,
            -0.043623194098472595,
            -0.028438497334718704,
            -0.025372805073857307,
            -0.007414669264107943,
            0.022221531718969345,
            -0.044841326773166656,
            -0.033126961439847946,
            -0.021285327151417732,
            0.04516405612230301,
            -0.02492203190922737,
            0.003447883762419224,
            -0.04545051231980324,
            -0.002974705770611763,
            -0.11354342103004456,
            0.013594320975244045,
            -0.010827548801898956,
            -0.033805444836616516,
            0.044401396065950394,
            0.03581587225198746,
            -0.032235778868198395,
            -0.06539873778820038,
            -0.0029297105502337217,
            -0.047979313880205154,
            0.008756270632147789,
            -0.0006701943930238485,
            -0.027269698679447174,
            -0.03924120217561722,
            -0.014378145337104797,
            0.022414730861783028,
            0.006885842885822058,
            -0.008165606297552586,
            -0.024817733094096184,
            -0.041078511625528336,
            0.017681784927845,
            -0.013682478107511997,
            -0.03586163371801376,
            0.03699764236807823,
            0.004192734137177467,
            -0.04302870109677315,
            -0.045537881553173065,
            0.02211466059088707,
            0.01716906949877739,
            -0.04087010398507118,
            -0.017562344670295715,
            -0.028043685480952263,
            -0.026501093059778214,
            -0.06986897438764572,
            0.01254301518201828,
            -0.011029047891497612,
            0.03478249907493591,
            0.013206600211560726,
            -0.03144703060388565,
            0.01903083547949791,
            -0.04095610976219177,
            -0.027370544150471687,
            0.01602087914943695,
            -0.002036831108853221,
            0.005982919596135616,
            0.03268536552786827,
            0.029721105471253395,
            -0.019898945465683937,
            0.012934854254126549,
            -0.037386052310466766,
            0.0030773442704230547,
            -0.022782178595662117,
            0.024661755189299583,
            0.005606015678495169,
            0.0004434106813278049,
            -0.02008657529950142,
            0.03091316856443882,
            0.09899687021970749,
            0.007829972542822361,
            -0.02648378536105156,
            -0.05696696415543556,
            -0.03589840233325958,
            -0.0010101073421537876,
            0.024645552039146423,
            -0.01787213794887066,
            -0.0008845983538776636,
            0.02462942898273468,
            -0.001114511862397194,
            -0.07984887063503265,
            0.01976707950234413,
            0.02513863518834114,
            0.00012915974366478622,
            0.010793390683829784,
            -0.0022501505445688963,
            -0.01662280410528183,
            0.020660221576690674,
            -0.02055845782160759,
            -0.048787038773298264,
            0.010104035958647728,
            -0.012047273106873035,
            -0.016202101483941078,
            -0.04374086856842041,
            -0.019845277070999146,
            -0.02316674217581749,
            0.025906622409820557,
            0.002087282482534647,
            0.060435809195041656,
            0.01052062213420868,
            0.03452257439494133,
            0.007824715226888657,
            0.0030553301330655813,
            -0.018938129767775536,
            0.02829165570437908,
            -0.03324411064386368,
            0.018373414874076843,
            -0.037062786519527435,
            0.019773274660110474,
            -0.0020338452886790037,
            0.017242353409528732,
            -0.01499217003583908,
            0.03429654613137245,
            0.019411496818065643,
            -0.030251726508140564,
            -0.04668060690164566,
            -0.012597581371665001,
            -0.01126626506447792,
            -0.04077089950442314,
            -0.02283678948879242,
            -0.02310851961374283,
            -0.04244241863489151,
            0.006846150849014521,
            -0.053369808942079544,
            -0.026371775195002556,
            -0.03865920752286911,
            0.011196778155863285,
            -0.028495430946350098,
            -0.013775409199297428,
            0.003692382015287876,
            0.002265490824356675,
            0.03170726075768471,
            0.00813163723796606,
            0.01837284117937088,
            0.008237099274992943,
            -0.03686080127954483,
            0.0018027229234576225,
            0.025079647079110146,
            -0.03750929608941078,
            0.014936203137040138,
            0.01329272985458374,
            -0.020024536177515984,
            0.026683077216148376,
            0.03415908291935921,
            0.026752455160021782,
            0.00777580589056015,
            -0.026813579723238945,
            -0.005365542136132717,
            0.010071049444377422,
            -0.004211025778204203,
            -0.014925702475011349,
            -0.000770531827583909,
            0.016904693096876144,
            -0.0363241583108902,
            0.017355026677250862,
            0.027944860979914665,
            -0.027495231479406357,
            0.005648329388350248,
            0.012399212457239628,
            0.0143718421459198,
            -0.017051739618182182,
            -0.016882076859474182,
            -0.009228106588125229,
            0.010022258386015892,
            0.014923695474863052,
            0.0185931995511055,
            0.01088683307170868,
            0.02950700744986534,
            -0.05627021938562393,
            -0.012000953778624535,
            -0.000480713410070166,
            -0.010499479249119759,
            -0.023685820400714874,
            -0.031827159225940704,
            -0.0011986693134531379,
            -0.006972700823098421,
            -0.02938392013311386,
            -0.02718004398047924,
            -0.020519215613603592,
            -0.028109699487686157,
            -0.01091101672500372,
            -0.010089319199323654,
            -0.03617832809686661,
            0.09771769493818283,
            0.014988073147833347,
            0.003834271337836981,
            0.018537262454628944,
            -0.02492791786789894,
            -0.01445561833679676,
            -0.03573260456323624,
            -0.015437239781022072,
            -0.075853630900383,
            0.012077574618160725,
            0.04617942497134209,
            -0.04225568100810051,
            0.0034099393524229527,
            -0.02915968932211399,
            -0.018413811922073364,
            -0.016575494781136513,
            -0.017278319224715233,
            -0.008320323191583157,
            -0.019211841747164726,
            -0.005624703131616116,
            -0.0046178000047802925,
            0.027756761759519577,
            0.04793494939804077,
            0.012555519118905067,
            0.04491281881928444,
            -0.028485426679253578,
            0.06454093754291534,
            0.045555513352155685,
            0.029574725776910782,
            0.05148163065314293,
            -0.007096667308360338,
            -0.013984308578073978,
            -0.04130633920431137,
            -0.03612593933939934,
            -0.008519153110682964,
            0.057053159922361374,
            -0.04236767068505287,
            -0.011189806275069714,
            0.037017591297626495,
            -0.013707371428608894,
            0.021776696667075157,
            -0.03398307412862778,
            -0.01960204914212227,
            -0.028018893674016,
            -0.022227998822927475,
            0.019789213314652443,
            -0.014107082970440388,
            -0.0013646065490320325,
            -0.03986145183444023,
            -0.004755537956953049,
            -0.03124457783997059,
            0.029733622446656227,
            -0.00917983241379261,
            0.02856113761663437,
            0.012893792241811752,
            0.01712237112224102,
            -0.0003605298406910151,
            -0.04566473141312599,
            -0.015307558700442314,
            -0.03837728127837181,
            -0.0072245169430971146,
            0.013298263773322105,
            0.015323895961046219,
            -0.039840392768383026,
            0.01392046082764864,
            0.00030015999800525606,
            -0.05651922896504402,
            -0.019828936085104942,
            -0.02744324319064617,
            -0.06924133002758026,
            -0.03671944513916969,
            0.06911575794219971,
            0.024426154792308807,
            -0.014027376659214497,
            -0.04006027802824974,
            -0.027549991384148598,
            -0.013118591159582138,
            -0.023693937808275223,
            -0.0610770545899868,
            0.03508911654353142,
            -0.030323831364512444,
            -0.041195910423994064,
            0.008281996473670006,
            0.028650648891925812,
            -0.007353148888796568,
            0.012130665592849255,
            -0.00024442351423203945,
            -0.005905237514525652,
            -0.006568268407136202,
            0.017728542909026146,
            0.008462500758469105,
            -0.012383837252855301,
            -0.020078670233488083,
            -0.02853459119796753,
            -0.010920480825006962,
            0.004846415016800165,
            0.03828905150294304,
            0.0068027060478925705,
            -0.05586574226617813,
            0.009864723309874535,
            -0.030524391680955887,
            -0.015766281634569168,
            -0.014091270044445992,
            -0.002424003556370735,
            -0.005982331465929747,
            0.016994768753647804,
            -0.022172793745994568,
            -0.003307113191112876,
            -0.033019620925188065,
            -0.0025409390218555927,
            -0.014107516966760159,
            -0.014190321788191795,
            0.008334807120263577,
            -0.04223491996526718,
            -0.005438406951725483,
            0.06901831179857254,
            0.01422864105552435,
            -0.0004960193182341754,
            -0.01682359352707863,
            0.0023569976910948753,
            -0.01293670292943716,
            -0.0060949670150876045,
            -0.041678451001644135,
            0.007774170953780413,
            -0.027522850781679153,
            0.020069563761353493,
            0.008824300952255726,
            0.0076759508810937405,
            0.030621463432908058,
            0.024221928790211678,
            0.05455368012189865,
            0.015924138948321342,
            -0.023980557918548584,
            -0.017088711261749268,
            -0.021179886534810066,
            0.0025019655004143715,
            0.04417159780859947,
            -0.03231910988688469,
            -0.033918146044015884,
            0.061301104724407196,
            -0.004462473560124636,
            0.01100293267518282,
            0.017199140042066574,
            -0.01702156662940979,
            -0.023308636620640755,
            0.01474672555923462,
            -0.02782483585178852,
            0.0018381408881396055,
            0.039551425725221634,
            -0.06420815736055374,
            -0.024454448372125626,
            0.003836400108411908,
            -0.0265782680362463,
            -0.02783944085240364,
            -0.013892079703509808,
            0.02537611871957779,
            0.0039710719138383865,
            -0.07251308858394623,
            0.018445750698447227,
            -0.021078895777463913,
            0.02497006021440029,
            -0.02544514276087284,
            -0.008322280831634998,
            0.007975324988365173,
            0.04808533564209938,
            -0.028810853138566017,
            0.012472212314605713,
            0.024180112406611443,
            -0.007191554643213749,
            -0.045039866119623184,
            -0.03069167211651802,
            0.0417780801653862,
            -0.0345936119556427,
            0.014477674849331379,
            -0.02105065807700157,
            0.02977866865694523,
            -0.06027516350150108,
            0.014685496687889099,
            0.02012259140610695,
            -0.005070997402071953,
            -0.029943520203232765,
            -0.046498123556375504,
            -0.032376956194639206,
            -0.020295746624469757,
            -0.07496437430381775,
            -0.0012516683200374246,
            0.028647243976593018,
            -0.018473690375685692,
            0.05388331040740013,
            -0.007495199330151081,
            -0.04420097917318344,
            -0.012509389780461788,
            -0.011599208228290081,
            0.04517967253923416,
            -0.0006936226272955537,
            0.001659701461903751,
            -0.0013456662418320775,
            -0.03629674017429352,
            0.013273929245769978,
            -0.012136057950556278,
            0.015959687530994415,
            0.016954481601715088,
            0.03807751461863518,
            -0.006213659420609474,
            0.03505018725991249,
            0.03996460884809494,
            -0.02109205350279808,
            0.021776240319013596,
            -0.02336539886891842,
            -0.0025420060846954584,
            -0.03370828181505203,
            0.041184019297361374,
            0.019017793238162994,
            -0.0033615559805184603,
            -0.00835737120360136,
            -0.0037871357053518295,
            0.07269726693630219,
            -0.0029376090969890356,
            0.008114819414913654,
            -0.02269458957016468,
            -0.009118934161961079,
            4.280391749489354e-06,
            0.015159535221755505,
            0.014220401644706726,
            -0.029949335381388664,
            0.014248248189687729,
            0.0283980630338192,
            -0.045776717364788055,
            -0.01747782900929451,
            0.023578712716698647,
            0.02935570850968361,
            -0.031026499345898628,
            0.028074370697140694,
            0.018685368821024895,
            -0.031224461272358894,
            -0.023272724822163582,
            0.0332101471722126,
            0.010834273882210255,
            -0.03376965969800949,
            -0.02764241024851799,
            -0.031859397888183594,
            5.289516047923826e-05,
            -0.02332775481045246,
            -0.0002013294433709234,
            -0.011592995375394821,
            -0.004307703580707312,
            0.027995241805911064,
            -0.032214533537626266,
            -0.06265780329704285,
            -0.019391480833292007,
            -0.008647017180919647,
            0.013315167278051376,
            0.008530684746801853,
            0.035617195069789886,
            0.04265856370329857,
            0.02787134423851967,
            0.02229868620634079,
            -0.04098326340317726,
            0.013661745004355907,
            0.028298556804656982,
            0.036048974841833115,
            0.0049722581170499325,
            -0.0079048415645957,
            0.011342747136950493,
            -0.011981618590652943,
            0.06644139438867569,
            0.014424251392483711,
            0.010138805024325848,
            0.01847016252577305,
            -0.02589421533048153,
            0.026224860921502113,
            -0.011826533824205399,
            -0.04787739738821983,
            -0.046899355947971344,
            0.01910625956952572,
            -0.011010726913809776,
            -0.0046044401824474335,
            0.004543096758425236,
            -0.009099009446799755,
            -0.03918079659342766,
            -0.006789126433432102,
            -0.005355055443942547,
            0.02050045318901539,
            -0.033890046179294586,
            -0.006684714928269386,
            -0.005373198073357344,
            -0.028460126370191574,
            0.0008357541519217193,
            0.02038995921611786,
            0.033698610961437225,
            -0.039037834852933884,
            -0.003374099498614669,
            0.00639566732570529,
            -0.020795438438653946,
            -0.0169815756380558,
            0.024033043533563614,
            0.06094037741422653,
            -0.03209029883146286,
            -0.026608016341924667,
            -0.014309922233223915,
            0.006423261947929859,
            -0.004896716680377722,
            0.04179210588335991,
            0.03850644454360008,
            0.024744799360632896,
            0.03179888799786568,
            -0.01932193711400032,
            -0.049893345683813095,
            0.02137143537402153,
            0.032342445105314255,
            0.017970096319913864,
            -0.03800087422132492,
            -0.03316808491945267,
            0.017456399276852608,
            -0.004323866218328476,
            0.018505141139030457,
            -0.02976243570446968,
            0.007286675740033388,
            -0.006394380237907171,
            -0.034504540264606476,
            0.002169922925531864,
            -0.008531656116247177,
            -0.027087775990366936,
            -0.0005999610875733197,
            -0.029448818415403366,
            0.017771219834685326,
            -0.01526557095348835,
            0.05089375004172325,
            0.020546087995171547,
            0.04550240561366081,
            0.052346814423799515,
            -0.034308161586523056,
            0.0038869893178343773,
            -0.014318648725748062,
            -0.0032083685509860516,
            -0.03258131444454193,
            -0.012229722924530506,
            0.0009834023658186197,
            -0.034752581268548965,
            -0.013102257624268532,
            0.03226353973150253,
            -0.04205352067947388,
            0.04940112307667732,
            0.01003703847527504,
            0.02437709830701351,
            0.014395822770893574,
            -0.0024108777288347483,
            -0.013466638512909412,
            0.018718315288424492,
            -0.009398910216987133,
            -0.029596004635095596,
            -0.016414200887084007,
            0.033972907811403275,
            0.012230181135237217,
            -0.01571590080857277,
            0.06845191866159439,
            -0.03930231183767319,
            -0.010775119997560978,
            -0.01638631895184517,
            0.013458533212542534,
            -0.007480495609343052,
            -0.02387191727757454,
            -0.01612463779747486,
            0.021550102159380913,
            0.024727847427129745,
            0.044893063604831696,
            -0.06403955072164536,
            -0.03852490708231926,
            0.017209023237228394,
            -0.021785682067275047,
            -0.006333797704428434,
            0.04669733718037605,
            0.03195446357131004,
            0.026816902682185173,
            -0.0033848490566015244,
            0.008114789612591267,
            0.001406912342645228,
            -0.02697576768696308,
            0.03137415274977684,
            -0.056524403393268585,
            -0.07006336748600006,
            -0.015026281587779522,
            -0.014155935496091843,
            0.03219402953982353,
            0.05675773322582245,
            -0.0005859664524905384,
            -0.029963180422782898,
            -0.010968453250825405,
            -0.01361926645040512,
            0.01734899915754795,
            0.0006139130564406514,
            0.017876917496323586,
            0.025876237079501152,
            -0.041014108806848526,
            0.02037334442138672,
            -0.007217153441160917,
            0.026019906625151634,
            -0.024506468325853348,
            -0.07535017281770706,
            -0.045159678906202316,
            -0.017434682697057724,
            0.011499408632516861,
            0.03067614883184433,
            0.003650990314781666,
            0.04357354715466499,
            0.016336439177393913,
            0.07160627841949463,
            -0.049810219556093216,
            -0.002774187596514821,
            -0.05205425247550011,
            0.07828738540410995,
            -0.013453397899866104,
            -0.02543916366994381,
            0.016934510320425034,
            -0.006616104394197464,
            -0.008177926763892174,
            0.010046129114925861,
            0.008643780834972858,
            0.04452887549996376,
            0.012298187240958214,
            0.00922098197042942,
            -0.026969460770487785,
            -0.05512801930308342,
            0.019462937489151955,
            0.03180481493473053,
            0.005898589733988047,
            -0.03040236607193947,
            0.0008186738123185933,
            -0.019115906208753586,
            -0.029006443917751312,
            -0.03530529513955116,
            -0.04791341722011566,
            0.01680942438542843,
            -0.009277398698031902,
            0.03589368239045143,
            0.021713562309741974,
            0.021667204797267914,
            0.04530198872089386,
            -0.03833696246147156,
            -0.015600331127643585,
            0.015209833160042763,
            -0.02168210595846176,
            -0.013782554306089878,
            0.006188813131302595,
            -0.0683794841170311,
            0.004350714851170778,
            0.014048817567527294,
            -0.03364456817507744,
            -0.02491825446486473,
            -0.00804530642926693,
            0.0746571272611618,
            -0.018549419939517975,
            0.0067855785600841045,
            -0.012787796556949615,
            -0.06471947580575943,
            -0.034924302250146866,
            -0.011687399819493294,
            0.008959610015153885,
            -0.018505409359931946,
            0.03970084339380264,
            -0.022767357528209686,
            0.013947783038020134,
            -0.0018255935283377767,
            -0.06410058587789536,
            0.023454386740922928,
            0.05411800369620323,
            -0.008000362664461136,
            0.040742482990026474,
            0.016957100480794907,
            0.06255505979061127,
            -0.02552848681807518,
            -0.004832688719034195,
            -0.011674915440380573,
            0.030972128733992577,
            0.004867557901889086,
            0.01148799154907465,
            0.022348351776599884,
            0.0239822156727314,
            0.02544744499027729,
            0.014643346890807152,
            0.039990492165088654,
            0.039707913994789124,
            -0.012309208512306213,
            -0.02386525273323059,
            0.018593747168779373,
            0.026835789903998375,
            0.02289229817688465,
            -0.0696156769990921,
            0.020811030641198158,
            -0.05655735731124878,
            -0.014907333999872208,
            -0.01768016442656517,
            -0.06293750554323196,
            0.0068082488141953945,
            0.0327586904168129,
            -0.013009062968194485,
            0.009025691077113152,
            -0.022414885461330414,
            0.033906489610672,
            -0.048472970724105835,
            0.038324616849422455,
            -0.017617754638195038,
            -0.0019137482158839703,
            -0.014904552139341831,
            0.058303169906139374,
            -0.018791696056723595,
            0.025716982781887054,
            0.002263113623484969,
            -0.005634050816297531,
            0.03993505612015724,
            0.05485955625772476,
            -0.004784403834491968,
            -0.00253912596963346,
            -0.02039577066898346,
            -0.032493796199560165,
            0.023943336680531502,
            -0.023483293130993843,
            -0.019071538001298904,
            -0.004257959313690662,
            -0.024080919101834297,
            0.007597710937261581,
            0.029407696798443794,
            0.02506474032998085,
            0.03866555541753769,
            0.042648617178201675,
            0.020477838814258575,
            0.01737203076481819,
            -0.015564117580652237,
            -0.005658486392349005,
            -0.014672896824777126,
            0.010326158255338669,
            -0.022865036502480507,
            -0.0019253668142482638,
            0.014773729257285595,
            -0.02079736813902855,
            -0.03320571780204773,
            -0.051961466670036316,
            0.04688702151179314,
            -0.021691516041755676,
            -0.08117589354515076,
            -0.04120336472988129,
            -0.02811969257891178,
            -0.009853639639914036,
            0.004964625928550959,
            -0.036352500319480896,
            -0.01355965156108141,
            0.02170976623892784,
            -0.005039711482822895,
            0.02080569788813591,
            -0.0067867618054151535,
            0.00020449988369364291,
            -0.034369003027677536,
            0.00884411670267582,
            -0.017579078674316406,
            -0.02989445999264717,
            0.008904767222702503,
            0.016808923333883286,
            0.017202094197273254,
            0.044866953045129776,
            0.07136810570955276,
            -0.016136446967720985,
            0.02144007198512554,
            0.02349688485264778,
            -0.024871857836842537,
            -0.05713701993227005,
            0.03430565074086189,
            0.008503329008817673,
            -0.04141484946012497,
            0.01785486564040184,
            0.03701537847518921,
            -0.04057152941823006,
            0.02321222610771656,
            0.012073315680027008,
            0.02895292267203331,
            0.042177435010671616,
            0.014504748396575451,
            -0.000694264133926481,
            0.02874203771352768,
            0.01661887764930725,
            -0.010747041553258896,
            -0.05833153799176216,
            0.011579249985516071,
            -0.0006836532847955823,
            -0.030117187649011612,
            -0.018251249566674232,
            -0.03645261004567146,
            0.01334083266556263,
            -0.010841649025678635,
            -0.02286783792078495,
            0.038899779319763184,
            0.013151954859495163,
            0.016846179962158203,
            0.031443316489458084,
            0.005849563051015139,
            -0.041647352278232574,
            0.0054978071711957455,
            -0.014777101576328278,
            -0.04082617536187172,
            0.028350740671157837,
            -0.013949519954621792,
            -0.052428629249334335,
            -0.028990156948566437,
            -0.01905781589448452,
            0.03510250151157379,
            0.012242592871189117,
            0.00956379622220993,
            -0.03495407477021217,
            -0.027421100065112114,
            0.0005805682740174234,
            -0.034130215644836426,
            -0.016778165474534035,
            0.024328632280230522,
            0.022637199610471725,
            -0.017334410920739174,
            0.020110711455345154,
            -0.026538673788309097,
            -0.015969714149832726,
            0.01111580990254879,
            -0.016036678105592728,
            -0.03603387996554375,
            -0.0008493192144669592,
            0.009440952911973,
            -0.03993033990263939,
            0.011768457479774952,
            0.0533498078584671,
            -0.008751040324568748,
            -0.027364328503608704,
            -0.06349283456802368,
            -0.044078122824430466,
            -0.019727470353245735,
            -0.050213929265737534,
            0.044556811451911926,
            0.010932107456028461,
            0.024197932332754135,
            0.004626547452062368,
            -0.024351002648472786,
            0.007667338475584984,
            -0.007233040872961283,
            -0.06416623294353485,
            -0.015335578471422195,
            0.01875554956495762,
            -0.0004172923509031534,
            -0.019373970106244087,
            0.028886890038847923,
            0.019284328445792198,
            0.001975730527192354,
            0.03966376930475235,
            -0.04014425724744797,
            0.027185440063476562,
            0.047410789877176285,
            0.020982984453439713,
            0.013806531205773354,
            -0.0899791345000267,
            -0.0031997174955904484,
            0.01318533904850483,
            0.022373899817466736,
            -0.0028930758126080036,
            0.01319894939661026,
            0.01263052225112915,
            0.025178855285048485,
            0.0019757116679102182,
            -0.02269483543932438,
            -0.0015882033621892333,
            0.03797503560781479,
            0.004511651583015919,
            0.0275773573666811,
            -0.0030762292444705963,
            0.006472023669630289,
            -0.0015647477703168988,
            -0.05112259089946747,
            0.024703556671738625,
            -0.01778149977326393,
            -0.0284807700663805,
            -0.03975163400173187,
            -0.024317607283592224,
            0.01237491611391306,
            0.002980281366035342,
            0.05385742709040642,
            -0.02062242291867733,
            -0.04783904179930687,
            -0.005290437955409288,
            -0.009367831982672215,
            -0.003438567975535989,
            0.0047624604776501656,
            0.00937242154031992,
            -0.01064613088965416,
            -0.06814787536859512,
            0.03532104939222336,
            -0.05464803799986839,
            0.017026031389832497,
            -0.0007678489200770855,
            -0.013133919797837734,
            -0.030045093968510628,
            0.012984364293515682,
            -0.009360081516206264,
            0.010881875641644001,
            -0.023818112909793854,
            -0.0029631361830979586,
            0.009072662331163883,
            0.030349422246217728,
            -0.02308986894786358,
            0.040085747838020325,
            -0.0212384220212698,
            -0.003464272478595376,
            -0.014964102767407894,
            -0.010944169014692307,
            -0.025784799829125404,
            -0.042140498757362366,
            -0.0076197488233447075,
            -0.02232261560857296,
            -0.015034417621791363,
            -0.017518142238259315,
            0.0037249792367219925,
            0.030756821855902672,
            -0.0047311545349657536,
            0.005346743389964104,
            0.0009008517954498529,
            0.005033812485635281,
            0.012694969773292542,
            -0.010281949304044247,
            0.012588130310177803,
            0.008918976411223412,
            -0.0031732951756566763,
            -0.038686834275722504,
            -0.00507356645539403,
            0.0004472701984923333,
            0.019903842359781265,
            0.0397295206785202,
            0.010975388810038567,
            0.0009180620545521379,
            0.01268006581813097,
            -0.012923764996230602,
            0.009699544869363308,
            -0.0327664278447628,
            -0.02679276652634144,
            -0.023777751252055168,
            0.02488262578845024,
            -0.03916666656732559,
            0.03570221737027168,
            0.023133840411901474,
            -0.030247928574681282,
            0.018459629267454147,
            0.0004191038606222719,
            0.039854854345321655,
            -0.07810279726982117,
            0.0032548692543059587,
            0.006781834177672863,
            -0.019555700942873955,
            0.00874529778957367,
            -0.006935907993465662,
            -0.10391733050346375,
            0.05865368992090225,
            -0.0005968401674181223,
            -0.019232356920838356,
            -0.01512649841606617,
            -0.005756743717938662,
            -0.061896853148937225,
            0.004310911055654287,
            -0.04726114496588707,
            0.008983415551483631,
            0.007982631213963032,
            0.039534226059913635,
            -0.028151649981737137,
            -0.01392174419015646,
            0.020514214411377907,
            -0.009648852981626987,
            0.01720510609447956,
            -0.03696918487548828,
            0.025882968679070473,
            -0.04347962513566017,
            0.00985372718423605,
            -0.06136173754930496,
            0.008029825054109097,
            -0.022936519235372543,
            -0.0017025982961058617,
            0.009288252331316471,
            -0.005342462565749884,
            0.004780244547873735,
            -0.0021601212210953236,
            -0.021181199699640274,
            -0.015582789666950703,
            0.004948151297867298,
            0.015872757881879807,
            0.018624741584062576,
            -0.0007284182356670499,
            -0.04709530994296074,
            0.015880929306149483,
            -0.039346594363451004
        ],
        [
            -0.004426080733537674,
            -0.01608792133629322,
            -0.019525110721588135,
            -0.005411539692431688,
            0.002804829739034176,
            0.007482023909687996,
            0.03423990681767464,
            0.05092310160398483,
            0.011301408521831036,
            0.012003021314740181,
            -0.013363073579967022,
            0.011452334932982922,
            0.020007161423563957,
            -0.0302029550075531,
            0.010399219579994678,
            0.0003037767019122839,
            0.0027262947987765074,
            0.012377469800412655,
            0.03597494587302208,
            -0.02831139601767063,
            -0.000585092231631279,
            -0.006709801498800516,
            0.04108298569917679,
            -0.00598348630592227,
            0.0018310832092538476,
            -0.003545403480529785,
            0.002436120295897126,
            -0.01919734664261341,
            -0.025467464700341225,
            -0.02596225216984749,
            0.024132344871759415,
            -0.01662559062242508,
            -0.027927232906222343,
            -0.0006012081867083907,
            0.013426500372588634,
            -0.017336001619696617,
            0.01655019447207451,
            -0.030434757471084595,
            0.00891846138983965,
            -0.005621937569230795,
            0.033765118569135666,
            0.012357412837445736,
            0.009612872265279293,
            0.00564334774389863,
            0.020492255687713623,
            0.009695212356746197,
            0.0021522908937186003,
            -0.007978850975632668,
            -0.024135926738381386,
            0.03589322790503502,
            -0.017424631863832474,
            0.035273291170597076,
            0.02769695222377777,
            0.02681552618741989,
            0.006962672807276249,
            0.04034288227558136,
            0.021754559129476547,
            0.03630261495709419,
            -0.010838920250535011,
            0.028787847608327866,
            0.0036454307846724987,
            -0.009074834175407887,
            0.020868845283985138,
            -0.0026730168610811234,
            -0.02022133395075798,
            0.012728400528430939,
            0.017682433128356934,
            -0.0009975397260859609,
            0.03258157894015312,
            -0.029194561764597893,
            0.021905647590756416,
            -0.02016228437423706,
            -0.01156574860215187,
            -0.02880474552512169,
            -0.004325017333030701,
            0.00839207787066698,
            -0.007092691026628017,
            0.031492702662944794,
            -0.0319647379219532,
            0.028079766780138016,
            -0.021989667788147926,
            0.021457819268107414,
            0.017467284575104713,
            -0.016987478360533714,
            0.02414880320429802,
            0.009984034113585949,
            -0.020519739016890526,
            0.010246135294437408,
            0.017524126917123795,
            -0.034869395196437836,
            -0.011336633004248142,
            0.02285691723227501,
            0.015620248392224312,
            0.04572320729494095,
            -0.01896819844841957,
            0.014237552881240845,
            -0.007022095378488302,
            0.048231616616249084,
            0.03915729746222496,
            -0.03342444449663162,
            -0.014530668966472149,
            -0.0017646201886236668,
            -0.016209403052926064,
            0.023857727646827698,
            -0.015797782689332962,
            0.013883165083825588,
            0.009472385048866272,
            -0.015694258734583855,
            -0.041146717965602875,
            0.017300372943282127,
            0.02872607111930847,
            -0.0036091250367462635,
            0.0219196155667305,
            -0.016641629859805107,
            0.012555248104035854,
            -0.01023259200155735,
            0.005830019246786833,
            0.0016084827948361635,
            0.01497729867696762,
            0.0068138050846755505,
            -0.01926392875611782,
            0.030175458639860153,
            -0.041053175926208496,
            -0.00625562621280551,
            -0.006002889014780521,
            0.014233936555683613,
            0.010759497061371803,
            -0.0139499232172966,
            -0.02791178971529007,
            0.0018720135558396578,
            -0.022171413525938988,
            0.04082375019788742,
            -0.0051459637470543385,
            0.01137628499418497,
            0.0052537559531629086,
            0.02106058970093727,
            -0.0220781397074461,
            -0.0025582630187273026,
            -0.01840895041823387,
            0.014490782283246517,
            -0.03392001613974571,
            -0.013849702663719654,
            0.027612166479229927,
            0.01925760880112648,
            0.013626761734485626,
            -0.0416412390768528,
            0.004174146801233292,
            -0.04308395832777023,
            0.022909516468644142,
            0.04459426924586296,
            -0.030163588002324104,
            0.0175542701035738,
            -0.007463635876774788,
            0.0002648438676260412,
            0.006440997123718262,
            0.02632388100028038,
            0.029109273105859756,
            -0.021936416625976562,
            0.049098994582891464,
            -0.0030132101383060217,
            0.02259043976664543,
            -0.011581100523471832,
            -0.0031447126530110836,
            0.021081460639834404,
            -0.008860633708536625,
            -0.0229329951107502,
            0.016932642087340355,
            0.023651188239455223,
            0.040597688406705856,
            0.014428393915295601,
            0.025539197027683258,
            0.021627498790621758,
            0.05451854318380356,
            0.026432469487190247,
            -0.007311347406357527,
            -0.030977005138993263,
            0.015702560544013977,
            -0.020063366740942,
            0.03211938589811325,
            -0.0012392259668558836,
            -0.03582970052957535,
            0.00937021803110838,
            0.01378802303224802,
            0.0071229045279324055,
            -0.01796329766511917,
            -0.025210857391357422,
            -0.001071867416612804,
            -0.02498677559196949,
            -0.009085842408239841,
            0.01977170817553997,
            0.007095617242157459,
            0.018721159547567368,
            0.012980657629668713,
            0.0021484282333403826,
            0.023883627727627754,
            0.0157107375562191,
            0.02797936089336872,
            0.0009139296016655862,
            0.026018021628260612,
            -0.023658163845539093,
            0.018622083589434624,
            0.03799619525671005,
            -0.03905447944998741,
            -0.01867893524467945,
            0.021684950217604637,
            -0.03902638703584671,
            0.029229234904050827,
            0.0046159084886312485,
            0.019131815060973167,
            -0.009940999560058117,
            0.013015981763601303,
            0.008428739383816719,
            0.024137990549206734,
            -0.00256499950774014,
            -0.026015492156147957,
            0.011533914133906364,
            0.021753963083028793,
            0.021836884319782257,
            -0.02787117101252079,
            -0.006811137776821852,
            0.028931476175785065,
            0.03269566223025322,
            0.012116284109652042,
            0.041128937155008316,
            -0.011420357041060925,
            -0.032638195902109146,
            -0.002517458749935031,
            -0.005355027038604021,
            0.029064176604151726,
            0.004690072033554316,
            -0.026141315698623657,
            0.021815141662955284,
            0.022617707028985023,
            -0.015201842412352562,
            0.02675514854490757,
            0.042332787066698074,
            -0.00888729840517044,
            -0.011686403304338455,
            0.005570191890001297,
            -0.016837498173117638,
            0.02273123897612095,
            -0.014905921183526516,
            -0.03515291586518288,
            -0.024168845266103745,
            0.0011360518401488662,
            -0.012552738189697266,
            -0.015419011935591698,
            0.016954239457845688,
            -0.017216453328728676,
            0.0012602296192198992,
            0.03770895674824715,
            0.02522112987935543,
            -0.021850958466529846,
            -0.012498995289206505,
            0.01835852675139904,
            -0.004316921345889568,
            0.007249008398503065,
            0.018368372693657875,
            0.018987420946359634,
            -0.00022169950534589589,
            0.004748685751110315,
            -0.0320817306637764,
            0.027049003168940544,
            -0.006532398983836174,
            -0.017608214169740677,
            0.0005780863575637341,
            -0.00562757533043623,
            -0.02138473279774189,
            0.030243953689932823,
            -0.009466225281357765,
            -0.022628379985690117,
            -0.015451599843800068,
            0.014202985912561417,
            -0.003409504657611251,
            0.0015854043886065483,
            0.01421527098864317,
            -0.01305240485817194,
            -0.027308447286486626,
            0.013517674058675766,
            -0.012105677276849747,
            -0.03347540646791458,
            -0.01774110272526741,
            0.020395800471305847,
            0.030480433255434036,
            0.02014429122209549,
            0.0076300399377942085,
            -0.004594322293996811,
            -0.007249401416629553,
            -0.02822132222354412,
            0.019496511667966843,
            0.02800079993903637,
            0.008219379931688309,
            -0.0028888133820146322,
            -0.025971168652176857,
            -0.0005539566627703607,
            0.04679446294903755,
            -0.004830791614949703,
            -0.017397131770849228,
            -0.002671577734872699,
            0.003024474484845996,
            -0.0036037941463291645,
            -0.023231619969010353,
            -0.0038749855011701584,
            0.01780976727604866,
            0.03566678613424301,
            0.04687594622373581,
            0.007299378514289856,
            -0.007587152998894453,
            -0.016548970714211464,
            0.05191522836685181,
            0.000753349915612489,
            -0.027387116104364395,
            -0.008450441062450409,
            -0.006849172990769148,
            0.022832326591014862,
            0.008974460884928703,
            -0.0174320787191391,
            -0.026215285062789917,
            0.01837148144841194,
            0.0021479353308677673,
            0.001993349054828286,
            -0.01503896713256836,
            -0.00815010815858841,
            0.012051314115524292,
            -0.0305185429751873,
            -0.016654454171657562,
            0.0074731046333909035,
            -0.02138846553862095,
            0.03290801867842674,
            -0.024798624217510223,
            -0.0017573360819369555,
            0.031867288053035736,
            0.01971031352877617,
            -0.0004708650230895728,
            -0.017794352024793625,
            0.009694037027657032,
            -0.025956688448786736,
            -0.011345045641064644,
            0.0307768601924181,
            -0.01627647876739502,
            -0.01182745024561882,
            0.008592994883656502,
            -0.005766307935118675,
            0.015018000267446041,
            -0.01610625721514225,
            0.019538532942533493,
            -0.017063206061720848,
            0.0341481938958168,
            0.00043483279296196997,
            -0.006638582795858383,
            -0.015408352017402649,
            -0.019492968916893005,
            -0.013159607537090778,
            0.03744857758283615,
            0.019943716004490852,
            0.020820975303649902,
            0.02302473969757557,
            -0.032120008021593094,
            0.02862062305212021,
            0.00728608900681138,
            0.029319776222109795,
            0.004960464313626289,
            -0.03573625162243843,
            0.03990746662020683,
            0.01094944216310978,
            -0.01801721379160881,
            -0.0342973954975605,
            0.009684191085398197,
            -0.026875585317611694,
            0.009898311458528042,
            0.001653760438784957,
            0.012829526327550411,
            0.021557720378041267,
            -0.003539139172062278,
            -0.024853358045220375,
            0.027909811586141586,
            0.036409344524145126,
            0.009175133891403675,
            0.010277965106070042,
            -0.0060668387450277805,
            0.036710239946842194,
            0.011056632734835148,
            -0.030499741435050964,
            0.024769127368927002,
            -0.014181990176439285,
            0.02695457451045513,
            0.007293980102986097,
            0.0008230886305682361,
            0.02524472400546074,
            -0.011901718564331532,
            0.020817255601286888,
            0.012132333591580391,
            -0.01819690316915512,
            0.02868364006280899,
            -0.005210896022617817,
            0.02221141941845417,
            0.016180479899048805,
            0.014910540543496609,
            0.014771172776818275,
            0.0012847735779359937,
            -0.03405560553073883,
            0.046176325529813766,
            -0.01182901207357645,
            0.03206498920917511,
            -0.0019802518654614687,
            -0.0019930237904191017,
            0.013915183022618294,
            0.01697860099375248,
            0.020314205437898636,
            0.022896962240338326,
            -0.01283948216587305,
            -0.007982827723026276,
            0.021401919424533844,
            0.01426977850496769,
            -0.024110326543450356,
            0.015451354905962944,
            -0.0033315762411803007,
            0.03439231589436531,
            0.018426664173603058,
            0.020116839557886124,
            0.010700554586946964,
            0.02882787026464939,
            0.04931784048676491,
            0.02371986396610737,
            0.0008409282891079783,
            0.0174283254891634,
            0.008846290409564972,
            0.03218124806880951,
            0.02169063873589039,
            0.01931944489479065,
            -0.015478145331144333,
            0.02653472311794758,
            0.016868259757757187,
            0.04630718380212784,
            0.0006987580563873053,
            0.030536655336618423,
            0.01649267040193081,
            0.012582122348248959,
            0.04275099188089371,
            -0.017761852592229843,
            0.0053761908784508705,
            0.02154052071273327,
            0.014324224554002285,
            -0.021126264706254005,
            -0.01758570969104767,
            0.02002212218940258,
            -0.023455588147044182,
            0.07496079802513123,
            0.0468880794942379,
            -0.011842098087072372,
            -0.025829827412962914,
            -0.012542043812572956,
            -0.016481472179293633,
            0.027957472950220108,
            -0.00401925016194582,
            0.025260010734200478,
            0.0015409118495881557,
            -0.009112078696489334,
            0.028595633804798126,
            0.01865026354789734,
            0.017651690170168877,
            -0.011510121636092663,
            0.005224654916673899,
            0.010158938355743885,
            0.01139878761023283,
            -0.014673512428998947,
            -0.01237472053617239,
            -0.012465744279325008,
            -0.00010393096454208717,
            -0.039811570197343826,
            0.022516002878546715,
            0.035099200904369354,
            -0.0037519505713135004,
            -0.011146308854222298,
            0.03824843466281891,
            -0.005774772725999355,
            0.0034422148019075394,
            0.029279522597789764,
            -0.01205770019441843,
            0.011663942597806454,
            -0.03400593250989914,
            -0.012996412813663483,
            -0.03337548300623894,
            0.012588567100465298,
            -0.0015817497624084353,
            -0.0023611243814229965,
            -0.02648165635764599,
            -0.008050280623137951,
            -0.032435718923807144,
            0.0015393190551549196,
            0.0009874880779534578,
            -0.010883981361985207,
            0.018112121149897575,
            -0.010295278392732143,
            0.05923541635274887,
            -0.015489522367715836,
            -0.015602584928274155,
            -0.005793340969830751,
            -0.01697196438908577,
            0.03788016736507416,
            0.0026102946139872074,
            0.05415606126189232,
            -0.01796681247651577,
            -0.017563724890351295,
            0.0046618967317044735,
            -0.025675050914287567,
            -0.021434836089611053,
            0.02556484006345272,
            0.013517380692064762,
            -0.005542348604649305,
            0.02776901051402092,
            0.04225475713610649,
            -0.004047294147312641,
            0.03519986569881439,
            0.03193160891532898,
            -0.027466678991913795,
            0.00693158246576786,
            -0.03463379666209221,
            -0.005676021333783865,
            -0.010994406417012215,
            -0.008331047371029854,
            -0.03461580350995064,
            -0.017839277163147926,
            0.0069674476981163025,
            0.04525470361113548,
            -0.0032661762088537216,
            0.03972003608942032,
            -0.010275464504957199,
            -0.029865466058254242,
            -0.03179977089166641,
            0.01650100201368332,
            -0.01523649226874113,
            0.03805069625377655,
            0.000732269836589694,
            -0.02047669142484665,
            0.008630425669252872,
            -0.015040441416203976,
            0.015230116434395313,
            -0.010606619529426098,
            -0.008052187971770763,
            -0.03160133957862854,
            -0.013353722169995308,
            -0.004439383279532194,
            0.03026093915104866,
            -0.024062709882855415,
            0.020148666575551033,
            0.007920797914266586,
            0.0017834230093285441,
            -0.021158333867788315,
            0.017852457240223885,
            0.020190414041280746,
            -0.031187666580080986,
            -0.035570304840803146,
            -0.022197285667061806,
            0.014290292747318745,
            -0.02613897994160652,
            0.0016832798719406128,
            -0.029363121837377548,
            -0.019307425245642662,
            -0.018139246851205826,
            0.0011347838444635272,
            -0.01834617182612419,
            0.0035192971117794514,
            0.020209724083542824,
            -0.0030408950988203287,
            -0.007766225375235081,
            -0.01468572486191988,
            -0.008179964497685432,
            0.022947464138269424,
            0.007925300858914852,
            -0.01906711608171463,
            -0.015748750418424606,
            -0.0147484065964818,
            -0.032813847064971924,
            -0.007659608498215675,
            -0.004878981504589319,
            -0.0060288552194833755,
            0.013434147462248802,
            0.01039806567132473,
            0.0021718302741646767,
            0.020278325304389,
            0.030449196696281433,
            0.022694099694490433,
            0.027950463816523552,
            -0.011870235204696655,
            -0.006567779462784529,
            -0.003060451941564679,
            -0.012261895462870598,
            0.005373513791710138,
            -0.026056192815303802,
            -0.0034175999462604523,
            -0.00704567926004529,
            -0.021250568330287933,
            -0.011730245314538479,
            -0.014718289487063885,
            -0.009327298030257225,
            0.004912520293146372,
            0.007565954700112343,
            0.005441025365144014,
            0.03227260336279869,
            -0.008567668497562408,
            0.02695075236260891,
            0.013995438814163208,
            0.007020034361630678,
            0.015876781195402145,
            0.01997450739145279,
            0.004686511121690273,
            0.023522216826677322,
            -0.00556039297953248,
            0.027340464293956757,
            0.027573417872190475,
            0.014479749836027622,
            0.01567666046321392,
            -0.02214595302939415,
            -0.02931966260075569,
            -0.006295123603194952,
            0.016647759824991226,
            -0.011397385969758034,
            -0.030211161822080612,
            0.027029110118746758,
            0.0069412970915436745,
            -0.026725446805357933,
            -0.01306028664112091,
            -0.002500199479982257,
            0.022750722244381905,
            0.01946447417140007,
            0.025961553677916527,
            0.010033564642071724,
            -0.002093424554914236,
            -0.012128991074860096,
            -0.011540750041604042,
            0.010464727878570557,
            0.028765201568603516,
            -0.007309499196708202,
            -0.009313262067735195,
            -0.02160039357841015,
            0.010363731533288956,
            -0.006733519956469536,
            -0.025470126420259476,
            0.014508653432130814,
            -0.021364253014326096,
            0.04351716488599777,
            0.04258529841899872,
            -0.0038287541829049587,
            0.006632999982684851,
            0.031770698726177216,
            -0.01707884669303894,
            0.024556448683142662,
            0.026577655225992203,
            0.0014705380890518427,
            0.013201850466430187,
            0.016889216378331184,
            -0.018932925537228584,
            0.0033547612838447094,
            0.011865129694342613,
            -0.001386017887853086,
            0.011317952536046505,
            0.00665608374401927,
            -0.0011679470771923661,
            0.004618053324520588,
            -0.01597977802157402,
            0.005439553875476122,
            0.006069359835237265,
            -0.016564659774303436,
            -0.019857164472341537,
            -0.02325671911239624,
            -0.011888465844094753,
            -0.02115098387002945,
            0.011526678688824177,
            0.02733183279633522,
            0.030261889100074768,
            -0.013927558436989784,
            -0.014663496986031532,
            -0.016112729907035828,
            -0.016355181112885475,
            -0.029848400503396988,
            0.03253232687711716,
            -0.02114180475473404,
            -0.0017900570528581738,
            0.028637807816267014,
            0.01126220915466547,
            0.021259503439068794,
            -0.0003495377895887941,
            -0.02457561157643795,
            -0.013569975271821022,
            -0.029876580461859703,
            0.021580714732408524,
            -0.021432343870401382,
            -0.0189592856913805,
            -0.00665304996073246,
            -0.0013620047830045223,
            0.010862976312637329,
            0.002198512200266123,
            0.013907895423471928,
            -0.0013025406515225768,
            -0.002944571664556861,
            -0.015961626544594765,
            0.02325032465159893,
            -0.020716121420264244,
            -0.007012662012130022,
            0.027570514008402824,
            -0.023336971178650856,
            0.02474290132522583,
            -0.025302520021796227,
            0.008803526870906353,
            -0.006671890150755644,
            -0.024137822911143303,
            0.007995078340172768,
            0.008205234073102474,
            -0.02262914553284645,
            -0.019335204735398293,
            0.02404516190290451,
            0.022584713995456696,
            -0.0026223843451589346,
            -0.023110264912247658,
            -0.017001863569021225,
            -0.0005341533687897027,
            -0.01811668463051319,
            -0.011419371701776981,
            0.010612600483000278,
            0.005418367683887482,
            -0.006216030567884445,
            0.0008782502845861018,
            -0.004017895087599754,
            0.006829524878412485,
            -0.03322312608361244,
            -0.027103731408715248,
            -0.03513120487332344,
            -0.020653514191508293,
            -0.01684567518532276,
            -0.007638742681592703,
            0.001513572526164353,
            -0.03607507422566414,
            -0.02100718393921852,
            0.020934686064720154,
            -0.017402278259396553,
            0.0026107565499842167,
            -0.016972437500953674,
            -0.009574939496815205,
            0.03137735277414322,
            0.008264531381428242,
            0.007374374195933342,
            -0.003358362475410104,
            0.0012195002054795623,
            0.011275378055870533,
            -0.021905897185206413,
            0.0074759782291948795,
            0.020519305020570755,
            -0.020819829776883125,
            -0.020583339035511017,
            0.0009480929584242404,
            -0.018062492832541466,
            -0.009096035733819008,
            0.040832217782735825,
            -0.0335327610373497,
            0.005691772326827049,
            0.01989155262708664,
            -0.012787863612174988,
            0.0006612069555558264,
            0.010715057142078876,
            0.0014578773407265544,
            -0.012090267613530159,
            0.02108393795788288,
            0.001058125402778387,
            0.009290184825658798,
            0.03704831004142761,
            -0.006374841555953026,
            0.024724455550312996,
            -0.019941354170441628,
            0.014034866355359554,
            -0.02119310386478901,
            -0.01219538040459156,
            0.029024707153439522,
            -0.0025814000982791185,
            0.004356117453426123,
            -0.0005157715058885515,
            0.03225450590252876,
            0.01696961559355259,
            -0.009452803991734982,
            0.023073863238096237,
            -0.005589716602116823,
            0.03025207482278347,
            -0.03355133533477783,
            0.023550089448690414,
            -0.009227126836776733,
            0.0064760614186525345,
            -0.007846404798328876,
            -0.008251838386058807,
            -0.02376619167625904,
            0.030993778258562088,
            -0.037295032292604446,
            -0.01497727818787098,
            -0.03373170271515846,
            -0.009062644094228745,
            0.014687534421682358,
            -0.029555456712841988,
            0.028503265231847763,
            0.004401032347232103,
            0.0016721539432182908,
            0.00654831575229764,
            -0.02013099193572998,
            -0.029974473640322685,
            -0.025315159931778908,
            0.019179796800017357,
            0.004238142166286707,
            -0.023309487849473953,
            0.03538985550403595,
            0.02465125359594822,
            -0.020738806575536728,
            -0.00885874405503273,
            -0.008963426575064659,
            0.011963007971644402,
            -0.0173675324767828,
            -0.022878602147102356,
            0.015589715912938118,
            -0.02324366755783558,
            -0.009095610119402409,
            0.013535194098949432,
            0.022337090224027634,
            -0.023377887904644012,
            0.02420913614332676,
            -0.03153363615274429,
            -0.03367835655808449,
            -0.008006807416677475,
            0.0014114632504060864,
            0.019548343494534492,
            -0.03314610943198204,
            -0.033010587096214294,
            0.007944748736917973,
            -0.013176030479371548,
            0.02778969332575798,
            0.03512318804860115,
            0.017368676140904427,
            -0.017992336302995682,
            0.028268689289689064,
            0.003499079030007124,
            -0.021728545427322388,
            0.0026858425699174404,
            0.028291670605540276,
            0.04431984946131706,
            -0.013545808382332325,
            0.02181263640522957,
            0.04185991734266281,
            0.017553158104419708,
            0.016866935417056084,
            -0.017508776858448982,
            -0.022201623767614365,
            -0.012827903963625431,
            0.03331127017736435,
            0.013333329930901527,
            -0.0009233743767254055,
            0.0004625138535629958,
            0.031665410846471786,
            -0.011914679780602455,
            -0.015246466733515263,
            -0.01463725883513689,
            0.018628114834427834,
            0.007331531960517168,
            0.010232615284621716,
            0.027464618906378746,
            0.019761525094509125,
            0.03721342235803604,
            -0.020104117691516876,
            0.044909462332725525,
            -0.025151804089546204,
            -0.024004749953746796,
            -0.026354027912020683,
            -0.014676238410174847,
            0.011670258827507496,
            0.016538195312023163,
            -0.0009197433828376234,
            0.00030465394956991076,
            0.01629817672073841,
            0.012927761301398277,
            0.009627358056604862,
            -0.04343777894973755,
            -0.02426404133439064,
            -0.0011021370301023126,
            0.010464025661349297,
            0.024440109729766846,
            -0.03671228513121605,
            -0.00042513033258728683,
            -0.0016786418855190277,
            0.030460501089692116,
            0.03344743326306343,
            -0.03073144517838955,
            0.003748206654563546,
            0.029779406264424324,
            0.035914428532123566,
            0.006039369851350784,
            -0.011202065274119377,
            0.04710258170962334,
            0.0404377281665802,
            -0.010291432030498981,
            0.05486317723989487,
            0.03196081146597862,
            -0.0020265968050807714,
            0.020609542727470398,
            0.015099853277206421,
            -0.028798986226320267,
            0.04402949661016464,
            0.005243182182312012,
            -0.002568191150203347,
            -0.013468204066157341,
            0.04887757822871208,
            -0.024326713755726814,
            -0.04131954908370972,
            0.031794384121894836,
            0.008598060347139835,
            -0.017092479392886162,
            0.007675844710320234,
            0.01773732341825962,
            0.024267783388495445,
            -0.0004049434792250395,
            0.006595892366021872,
            0.02686886303126812,
            0.024860745295882225,
            0.02723602019250393,
            0.002646195702254772,
            -0.007071444299072027,
            -0.013907579705119133,
            0.0003088121593464166,
            0.029622070491313934,
            -0.030611153692007065,
            0.05329420045018196,
            0.004732053726911545,
            -0.019975785166025162,
            0.007212216034531593,
            -0.02603267878293991,
            0.01007645670324564,
            -0.01876569353044033,
            0.009685354307293892,
            0.016302993521094322,
            0.003887539030984044,
            0.01685793697834015,
            -0.00575676467269659,
            0.020561207085847855,
            0.0283134113997221,
            0.018557608127593994,
            0.024779949337244034,
            -0.015633080154657364,
            -0.025862645357847214,
            -0.029066044837236404,
            0.03235972672700882,
            0.03733083978295326,
            0.003581381170079112,
            0.008506960235536098,
            0.020126841962337494,
            0.031058847904205322,
            0.010642276145517826,
            -0.01530067902058363,
            -0.01693517155945301,
            0.012913533486425877,
            0.03227551281452179,
            0.028869753703475,
            0.027852701023221016,
            -0.006942161358892918,
            -0.02160172536969185,
            0.02559797652065754,
            0.0038109661545604467,
            -0.025089262053370476,
            -0.01857992447912693,
            0.018009288236498833,
            0.03836364671587944,
            -0.03578868880867958,
            -0.010288304649293423,
            -0.004749678540974855,
            -0.014319723471999168,
            0.016316188499331474,
            0.025517964735627174,
            -0.02615489438176155,
            0.01753692887723446,
            -0.019403329119086266,
            -0.007242084946483374,
            0.016518499702215195,
            -0.013763499446213245,
            -0.022624865174293518,
            -0.02059946022927761,
            -0.009816463105380535,
            0.027797646820545197,
            0.009986800141632557,
            0.01047294121235609,
            0.01120377704501152,
            -0.010963039472699165,
            0.020253587514162064,
            0.011316994205117226,
            0.026554277166724205,
            -0.013436391018331051,
            -0.024748295545578003,
            -0.03574065491557121,
            -0.03029501810669899,
            -0.007098993752151728,
            -0.01851678267121315,
            0.007601311895996332,
            0.024683721363544464,
            0.00129848497454077,
            0.04436492174863815,
            0.014935079030692577,
            0.0018246994586661458,
            -0.01948699913918972,
            -0.011499444022774696,
            0.0049410914070904255,
            0.023740418255329132,
            0.007278014440089464,
            0.03715762495994568,
            0.019180404022336006,
            0.02149765007197857,
            -0.0037690664175897837,
            0.01652940735220909,
            0.02109743095934391,
            0.011180142872035503,
            -0.018100092187523842,
            -0.0027909649070352316,
            -0.026668595150113106,
            -0.01201678067445755,
            0.036365658044815063,
            -0.0011443846160545945,
            0.016054434701800346,
            0.014438134618103504,
            -0.006633437238633633,
            0.010518285445868969,
            0.016726002097129822,
            0.01473254058510065,
            0.022240621969103813
        ],
        [
            -0.024936117231845856,
            0.010080179199576378,
            -0.00501946872100234,
            -0.0189067255705595,
            0.004076062235981226,
            -0.004098872654139996,
            0.021759837865829468,
            0.005772664677351713,
            -0.01961228996515274,
            -0.03721855208277702,
            0.0159548781812191,
            0.001961302477866411,
            -0.031169066205620766,
            -0.017460815608501434,
            0.0455324612557888,
            0.016331860795617104,
            -0.022735225036740303,
            -0.028100185096263885,
            -0.005535641219466925,
            -0.0002560199936851859,
            0.03875218704342842,
            0.007104338612407446,
            -0.016002047806978226,
            0.02017061784863472,
            0.019640875980257988,
            -0.021157221868634224,
            -0.006985356565564871,
            -0.016447745263576508,
            -0.015503013506531715,
            -0.02486644685268402,
            0.006622003857046366,
            -0.014941336587071419,
            0.016805404797196388,
            -0.01793011650443077,
            0.031576987355947495,
            -0.02849854901432991,
            0.005490311421453953,
            0.016471141949295998,
            0.025947175920009613,
            -0.023522743955254555,
            0.012936552986502647,
            0.00611373595893383,
            0.005678921472281218,
            -0.0011994501110166311,
            -0.00527661619707942,
            -0.01632136106491089,
            0.004486269783228636,
            0.00867612473666668,
            -0.002750599756836891,
            -0.04441363736987114,
            -0.0016428186791017652,
            0.02329186163842678,
            0.017450448125600815,
            0.02755460888147354,
            0.025572331622242928,
            -0.020298674702644348,
            -0.02068951539695263,
            -0.03449277579784393,
            0.019439252093434334,
            -0.030455591157078743,
            0.024853959679603577,
            0.01900286227464676,
            0.01832251064479351,
            0.00491762300953269,
            0.02528403513133526,
            0.004983445163816214,
            -0.024919098243117332,
            0.00812987145036459,
            0.011494098231196404,
            -0.022855760529637337,
            0.004691418260335922,
            -0.005415946710854769,
            -0.0016359944129362702,
            -0.023961318656802177,
            0.02628312259912491,
            -0.021618595346808434,
            -0.014971246011555195,
            0.010847538709640503,
            -0.0009842722211033106,
            0.012784330174326897,
            0.027369268238544464,
            -0.016447721049189568,
            -0.0011090108891949058,
            -0.0113406116142869,
            0.017687078565359116,
            -0.012685184367001057,
            0.0014323408249765635,
            -0.0068494658917188644,
            0.009427590295672417,
            -0.0075268191285431385,
            0.02179989218711853,
            0.008144798688590527,
            0.018387259915471077,
            -0.0023065556306391954,
            0.002680964069440961,
            0.02287033386528492,
            -0.002507222816348076,
            0.007909598760306835,
            -0.003580959979444742,
            -0.01493676658719778,
            -0.014231035485863686,
            -0.012252598069608212,
            0.019513266161084175,
            0.030689075589179993,
            0.0266264658421278,
            -0.006599481217563152,
            -0.012544237077236176,
            0.006809824611991644,
            -0.0051970528438687325,
            0.007922294549643993,
            0.030178992077708244,
            -0.0038256391417235136,
            -0.022556232288479805,
            -0.028380021452903748,
            0.027974283322691917,
            -0.024710994213819504,
            0.026886308565735817,
            0.022217489778995514,
            0.015264029614627361,
            -0.01766011118888855,
            0.010967772454023361,
            -0.02025504782795906,
            0.040826063603162766,
            0.01416961569339037,
            -0.022230176255106926,
            0.0179611686617136,
            0.02766045741736889,
            -0.008324113674461842,
            -2.9720040402025916e-05,
            -0.008371707051992416,
            -0.02011500857770443,
            0.03908447176218033,
            0.018306957557797432,
            0.009893154725432396,
            0.010976159945130348,
            0.02394401654601097,
            0.019638746976852417,
            -0.011358696036040783,
            0.016530174762010574,
            0.022760264575481415,
            -0.00815216638147831,
            0.015368916094303131,
            -0.029542366042733192,
            0.03321469947695732,
            0.007040177471935749,
            0.003277386538684368,
            0.018964197486639023,
            0.021526768803596497,
            0.023593300953507423,
            -0.039464350789785385,
            -0.013453608378767967,
            0.009967317804694176,
            0.03908153995871544,
            0.042822081595659256,
            0.0005801529041491449,
            -0.016406293958425522,
            0.0032734901178628206,
            0.0064843823201954365,
            -0.047407738864421844,
            0.018385251984000206,
            0.018052035942673683,
            -0.023438360542058945,
            0.01589313894510269,
            0.009690243750810623,
            0.011302076280117035,
            -0.0016992216696962714,
            0.0287159513682127,
            0.008989645168185234,
            -0.05352967977523804,
            0.01697266474366188,
            -0.030622128397226334,
            -0.03452545031905174,
            -0.012096039019525051,
            -0.019565222784876823,
            0.030368363484740257,
            -0.034179870039224625,
            -0.0032524364069104195,
            0.03305341675877571,
            -0.01265394501388073,
            -0.021543772891163826,
            0.01056093443185091,
            0.018194949254393578,
            0.02114376798272133,
            -0.01037650927901268,
            -0.02017419971525669,
            0.0008857838110998273,
            -0.008301297202706337,
            0.0024191292468458414,
            8.467778388876468e-05,
            0.0007281714933924377,
            -0.0065690684132277966,
            -0.01759888231754303,
            0.01787448301911354,
            -0.016555408015847206,
            -0.024056291207671165,
            -0.000507946650031954,
            -0.022069096565246582,
            -0.010543427430093288,
            -0.0008406852139160037,
            0.005132549442350864,
            0.03329771012067795,
            0.0027453619986772537,
            -0.0026715968269854784,
            0.00020756208687089384,
            -0.027806296944618225,
            0.012346748262643814,
            0.003746733535081148,
            -0.0020837613847106695,
            0.032446470111608505,
            0.01951410435140133,
            0.0019829568918794394,
            -0.019489334896206856,
            -0.010419368743896484,
            -0.01275893859565258,
            0.00021513771207537502,
            -0.006117057986557484,
            -0.027986034750938416,
            0.0033124051988124847,
            0.012419351376593113,
            -0.01754721812903881,
            -0.006249851081520319,
            -0.04376184567809105,
            0.017198679968714714,
            -0.028164047747850418,
            0.024434056133031845,
            0.023920612409710884,
            -0.06097648665308952,
            -0.006130702793598175,
            -0.006684422492980957,
            0.007587157189846039,
            -0.01250679325312376,
            -0.0022387232165783644,
            -0.005952152423560619,
            -0.017484840005636215,
            -0.0008711477275937796,
            -0.025752641260623932,
            0.03111022338271141,
            -0.023652007803320885,
            0.03229774534702301,
            0.0077458154410123825,
            0.014527383260428905,
            -0.0011193865211680532,
            0.008031637407839298,
            0.014055406674742699,
            -0.04369029775261879,
            0.0008346510003320873,
            -0.0060647521167993546,
            -0.006696783006191254,
            -0.02747751772403717,
            -0.00959867425262928,
            -0.004273741040378809,
            0.022686641663312912,
            -0.007801454048603773,
            -0.004134289920330048,
            0.004481565672904253,
            0.01842748373746872,
            0.020304301753640175,
            0.019304350018501282,
            -0.026735346764326096,
            -0.013899942860007286,
            0.029960520565509796,
            0.017003312706947327,
            0.01636238768696785,
            0.030113544315099716,
            0.03232560306787491,
            0.01745343953371048,
            0.011157592758536339,
            0.02832651510834694,
            0.02575961872935295,
            0.006362812127918005,
            -0.0013284818269312382,
            -0.0035048581194132566,
            -0.01664220169186592,
            -0.030136026442050934,
            -0.02512526884675026,
            0.0276342760771513,
            -0.012829422950744629,
            -0.021870069205760956,
            -0.01218247041106224,
            -0.017041761428117752,
            0.006267308723181486,
            0.012168843299150467,
            0.004402142949402332,
            0.0064467452466487885,
            0.012708872556686401,
            0.028783604502677917,
            -0.034677088260650635,
            0.0020089298486709595,
            -0.008059212937951088,
            -0.017254523932933807,
            -0.01574365422129631,
            0.018176738172769547,
            0.025122541934251785,
            0.02376188337802887,
            0.01004774309694767,
            -0.030163228511810303,
            -0.019794248044490814,
            0.02899532951414585,
            -0.001628225902095437,
            -0.007094005588442087,
            0.008910933509469032,
            -0.016888046637177467,
            0.00017580538406036794,
            0.00446305563673377,
            -0.013963415287435055,
            -0.008170697838068008,
            -0.018487906083464622,
            0.016579963266849518,
            0.018032705411314964,
            0.02146104723215103,
            0.019907886162400246,
            -0.009626003913581371,
            0.023561064153909683,
            0.021317707374691963,
            -0.02110285311937332,
            0.031205588951706886,
            -0.0179382786154747,
            0.025985002517700195,
            0.027195198461413383,
            0.020670520141720772,
            0.016057493165135384,
            0.004744897596538067,
            -0.038331497460603714,
            0.03752391040325165,
            0.02335928939282894,
            -0.01394711248576641,
            0.018601292744278908,
            -0.030914807692170143,
            0.009386091493070126,
            0.0005048611201345921,
            0.008688836358487606,
            -0.013269567862153053,
            0.015136949717998505,
            0.022605333477258682,
            0.013814734295010567,
            0.014240818098187447,
            0.008932993747293949,
            -0.039136674255132675,
            0.019058363512158394,
            -0.017525244504213333,
            -0.024356964975595474,
            0.01549424696713686,
            -0.024551229551434517,
            0.010179480537772179,
            -0.0077622816897928715,
            0.033998042345047,
            0.014835217036306858,
            0.0049848188646137714,
            -0.014455416239798069,
            0.023297160863876343,
            0.02426949515938759,
            0.03303578123450279,
            0.016070622950792313,
            -0.023696811869740486,
            0.02397884987294674,
            0.02968693897128105,
            0.029396021738648415,
            -0.020649973303079605,
            -0.005452563986182213,
            0.002862344030290842,
            0.027662716805934906,
            0.02255675010383129,
            -0.003825962310656905,
            -0.01223656814545393,
            -0.026312382891774178,
            -0.02600250579416752,
            0.005796224810183048,
            0.012992670759558678,
            0.020276473835110664,
            -0.019616441801190376,
            0.019230615347623825,
            0.008973080664873123,
            -0.028129631653428078,
            -0.035364944487810135,
            0.020788172259926796,
            0.018826637417078018,
            -0.023513760417699814,
            -0.005237787030637264,
            0.004183715675026178,
            0.02819797396659851,
            0.004058720078319311,
            0.019753428176045418,
            -0.03393061086535454,
            0.029168089851737022,
            0.03507046774029732,
            -0.03593532368540764,
            -0.020148886367678642,
            0.024006066843867302,
            0.00878491997718811,
            0.010781430639326572,
            -0.012553664855659008,
            0.006728022359311581,
            0.007992721162736416,
            -0.013485146686434746,
            0.06890819221735,
            0.004536030348390341,
            -0.021476266905665398,
            0.019494591280817986,
            0.02120392583310604,
            0.006826625671237707,
            0.02315687946975231,
            0.011439437977969646,
            -0.007332262583076954,
            -0.027125917375087738,
            -0.028350144624710083,
            0.022595040500164032,
            -0.02357352152466774,
            0.0076108649373054504,
            -0.0077208965085446835,
            0.027851015329360962,
            0.02333202213048935,
            0.03788985684514046,
            -0.035383351147174835,
            -0.02769206464290619,
            0.0032924457918852568,
            -0.009328700602054596,
            -0.006442262791097164,
            -0.01855793036520481,
            -0.004728743340820074,
            0.034121278673410416,
            0.0232874546200037,
            -0.019993532449007034,
            0.030589453876018524,
            0.02595667913556099,
            -0.010635489597916603,
            0.005355123430490494,
            -0.03929826244711876,
            0.021673792973160744,
            0.008975451812148094,
            -0.0014968516770750284,
            -0.04513540863990784,
            -0.0008149378118105233,
            0.01376558467745781,
            0.03204299136996269,
            0.003690420649945736,
            -0.009051990695297718,
            0.0038283204194158316,
            -0.01619805581867695,
            -0.020140744745731354,
            -0.015661321580410004,
            0.02139180526137352,
            -0.01884358748793602,
            0.007693194784224033,
            -0.017738355323672295,
            -0.014338038861751556,
            -0.010175778530538082,
            -0.002237144857645035,
            -0.06877533346414566,
            0.029902713373303413,
            -0.0110309524461627,
            -0.00976159144192934,
            0.024435076862573624,
            0.02849208377301693,
            0.030200136825442314,
            0.010055899620056152,
            0.02447792887687683,
            0.0063039883971214294,
            -0.013152624480426311,
            0.012328512035310268,
            0.013127006590366364,
            -0.006284146104007959,
            0.0018309830920770764,
            -0.0035295074339956045,
            -0.03381527215242386,
            0.01839977130293846,
            -0.00977092795073986,
            -0.02593088150024414,
            0.006869520526379347,
            -0.02619102969765663,
            0.0025699471589177847,
            0.01884712651371956,
            -0.00833851844072342,
            0.026449190452694893,
            0.00854178611189127,
            -0.004832601174712181,
            0.016364077106118202,
            -0.0015199545305222273,
            -0.013756179250776768,
            -0.026013370603322983,
            -0.007127242628484964,
            -0.01991603896021843,
            0.014453694224357605,
            -0.016492163762450218,
            0.023352138698101044,
            0.023997221142053604,
            -0.028585566207766533,
            0.03167648985981941,
            0.01417105458676815,
            0.016207121312618256,
            -0.02445821277797222,
            -0.02842262014746666,
            0.0015890662325546145,
            0.019286122173070908,
            0.011940710246562958,
            -0.03947218880057335,
            0.00014734604337718338,
            -0.0002964591549243778,
            0.007335801608860493,
            0.016046153381466866,
            0.004176029469817877,
            0.03052479773759842,
            -0.03464667871594429,
            0.012572084553539753,
            0.0002633756084833294,
            0.026117917150259018,
            0.028337012976408005,
            0.02035941183567047,
            0.013746007345616817,
            -0.029762374237179756,
            0.013730745762586594,
            -0.02830912545323372,
            0.03895944729447365,
            -8.837817586027086e-05,
            0.029201585799455643,
            0.015328038483858109,
            0.008392095565795898,
            0.0059749167412519455,
            0.03430904820561409,
            0.021817421540617943,
            0.020238924771547318,
            0.022244105115532875,
            -0.0219859778881073,
            -0.027416164055466652,
            0.001172119751572609,
            -0.05425507202744484,
            0.027465082705020905,
            -0.03707017004489899,
            -0.025980520993471146,
            0.024371890351176262,
            -0.030095573514699936,
            -0.004764826502650976,
            -0.025862831622362137,
            0.0021853127982467413,
            -0.007659816648811102,
            0.014044972136616707,
            -0.021762553602457047,
            0.0332137793302536,
            -0.013725889846682549,
            -0.026189029216766357,
            0.004685227759182453,
            0.01653088442981243,
            0.017898807302117348,
            0.025133803486824036,
            -0.04731002449989319,
            -0.009263565763831139,
            -0.00028342739096842706,
            -0.0011482249246910214,
            0.0026794609148055315,
            -0.00014073276543058455,
            0.029742615297436714,
            0.015888269990682602,
            -0.0075949449092149734,
            -0.013963467441499233,
            -0.01523896586149931,
            -0.002948359353467822,
            0.02995004504919052,
            0.0017350673442706466,
            -0.0003426954208407551,
            0.0064926412887871265,
            0.017111478373408318,
            -0.026801155880093575,
            0.027341263368725777,
            0.02961679734289646,
            0.019722523167729378,
            0.014431129209697247,
            -0.021948697045445442,
            -0.00146065978333354,
            -0.036607544869184494,
            -0.013517701998353004,
            -0.009385612793266773,
            -0.015437708236277103,
            0.028431221842765808,
            0.00246933801099658,
            -0.001255506300367415,
            -0.0025693830102682114,
            -0.0009220053907483816,
            -0.01958553120493889,
            -0.001773028983734548,
            0.002483856165781617,
            -0.02335517667233944,
            -0.00339861074462533,
            0.029353462159633636,
            -0.003148529911413789,
            0.014101207256317139,
            0.019979845732450485,
            -0.014414163306355476,
            -0.006073268596082926,
            -0.013299833051860332,
            -0.0064080143347382545,
            0.001051997416652739,
            0.016199832782149315,
            -0.011272712610661983,
            -0.009555052034556866,
            0.009526608511805534,
            -0.013502370566129684,
            0.01236807182431221,
            -0.012762945145368576,
            0.013773919083178043,
            0.018352195620536804,
            0.03050457499921322,
            0.01177484542131424,
            0.022303415462374687,
            -0.02603909745812416,
            -0.016125360503792763,
            -0.020405994728207588,
            0.005390685983002186,
            0.00820963829755783,
            0.015777025371789932,
            -0.02178795449435711,
            -0.012763538397848606,
            0.025374840945005417,
            0.018659114837646484,
            0.015489916317164898,
            0.024827489629387856,
            0.03708387166261673,
            -0.028522156178951263,
            0.025778314098715782,
            0.008915032260119915,
            0.008390853181481361,
            -0.011148495599627495,
            -0.01860431209206581,
            -0.018813999369740486,
            -0.013302650302648544,
            0.00457612844184041,
            -0.006441269535571337,
            -0.0006266506388783455,
            -0.02372138388454914,
            0.0071921334601938725,
            -0.014619686640799046,
            0.005155195947736502,
            -0.01640080101788044,
            0.028847038745880127,
            0.032406579703092575,
            -0.0026180464774370193,
            0.007370158098638058,
            0.023930439725518227,
            -0.01487776916474104,
            0.015338742174208164,
            -0.016747314482927322,
            -0.019469324499368668,
            0.016574522480368614,
            0.015919357538223267,
            0.039230603724718094,
            0.0014768100809305906,
            0.019595013931393623,
            0.004251978825777769,
            -0.002966279396787286,
            0.0005453264457173645,
            -0.0017530194018036127,
            -0.025461580604314804,
            0.019602619111537933,
            0.009788078255951405,
            -0.006416988559067249,
            0.005665460601449013,
            0.033884286880493164,
            0.01917072758078575,
            -0.00124817353207618,
            -0.01543910801410675,
            -0.009573915973305702,
            5.663386400556192e-05,
            0.0014422122621908784,
            0.019660893827676773,
            0.012645998038351536,
            -0.01083105057477951,
            0.02075231820344925,
            0.007525951601564884,
            -0.030217813327908516,
            -0.02541493810713291,
            0.004525979980826378,
            0.01137565914541483,
            -0.04945893958210945,
            0.014102413319051266,
            -0.028623072430491447,
            -0.0015830830670893192,
            0.016613729298114777,
            0.001003980403766036,
            0.014630506746470928,
            0.0060084122233092785,
            0.020642133429646492,
            -0.00845063291490078,
            0.004329794552177191,
            0.0042427340522408485,
            -0.0139536177739501,
            0.009106146171689034,
            -0.007597256917506456,
            -0.012023401446640491,
            -0.026774268597364426,
            -0.023305976763367653,
            -0.02921176142990589,
            -0.016021080315113068,
            -0.024468353018164635,
            0.018353786319494247,
            -0.02282298356294632,
            0.025381866842508316,
            -0.029627561569213867,
            -0.014476158656179905,
            -0.02505991794168949,
            -0.0157027505338192,
            -0.0306421909481287,
            -0.02197234146296978,
            -0.015000246465206146,
            -0.01678190939128399,
            0.023996679112315178,
            0.0005233818083070219,
            -0.027061322703957558,
            -0.01776655577123165,
            0.023936424404382706,
            0.008611439727246761,
            0.0043473378755152225,
            -0.016567328944802284,
            -0.019513573497533798,
            -0.019886383786797523,
            0.0014684938360005617,
            0.02832043543457985,
            -0.0026718620210886,
            0.014875926077365875,
            0.0003802677965722978,
            0.008117582648992538,
            -0.02105400525033474,
            0.01786046288907528,
            -0.022626960650086403,
            0.01418192870914936,
            0.006145953666418791,
            0.03316466137766838,
            0.0052705928683280945,
            -0.005552359856665134,
            -0.02013830468058586,
            0.028870295733213425,
            -0.015065062791109085,
            0.011412586085498333,
            0.033959873020648956,
            0.020462946966290474,
            -0.006212979089468718,
            -0.00030802690889686346,
            -0.039927296340465546,
            -0.00885273702442646,
            -0.025063255801796913,
            0.028383390977978706,
            0.0008913884521462023,
            -0.010974396951496601,
            0.020081333816051483,
            0.011837377212941647,
            -0.005189936142414808,
            0.010650391690433025,
            -0.00022806467313785106,
            0.01260429061949253,
            0.011759079992771149,
            0.0068087088875472546,
            0.02532532997429371,
            -0.01179973129183054,
            -0.028729883953928947,
            0.02475612238049507,
            -0.012471014633774757,
            0.022813288494944572,
            0.026526547968387604,
            0.024895036593079567,
            -0.018149979412555695,
            0.023906206712126732,
            -0.006272990722209215,
            -0.013725453987717628,
            0.026313448324799538,
            0.017553027719259262,
            0.008944020606577396,
            0.009109782986342907,
            0.019914574921131134,
            0.01563611812889576,
            0.005554389674216509,
            0.017857417464256287,
            -0.006640431936830282,
            -0.008364166133105755,
            -0.020978350192308426,
            -0.002160403411835432,
            -0.01991450972855091,
            -0.005821071565151215,
            -0.009840046055614948,
            0.005195439327508211,
            0.0037114277947694063,
            0.0526161715388298,
            0.019112233072519302,
            -0.020252598449587822,
            -0.025555085390806198,
            -0.013722742907702923,
            -0.018493320792913437,
            -0.009540301747620106,
            -0.0021613866556435823,
            -0.0022742205765098333,
            0.03138764575123787,
            0.005956071428954601,
            -0.008063242770731449,
            -0.022423619404435158,
            0.027654729783535004,
            0.01029233168810606,
            -0.008214314468204975,
            -0.009439278393983841,
            -0.012118586339056492,
            0.014593598432838917,
            0.02796907164156437,
            0.038272425532341,
            0.03567118942737579,
            0.033048756420612335,
            0.0027983454056084156,
            0.009961767122149467,
            -0.026347102597355843,
            -0.017581354826688766,
            0.026007726788520813,
            -0.018761537969112396,
            0.03193043917417526,
            -0.013056262396275997,
            0.002918115584179759,
            0.012459521181881428,
            0.026340169832110405,
            0.040874529629945755,
            0.0017313816351816058,
            0.029258204624056816,
            -0.0025496187154203653,
            -0.0013962893281131983,
            0.019633427262306213,
            -0.03631770610809326,
            -0.013240747153759003,
            -0.03402634337544441,
            0.014091790653765202,
            0.03366653993725777,
            -0.027732856571674347,
            0.00463727256283164,
            0.0181781854480505,
            -0.009846729226410389,
            -0.024328436702489853,
            0.013923048041760921,
            0.0034378033597022295,
            0.016110004857182503,
            -0.006799199618399143,
            0.017713060602545738,
            0.0013132255990058184,
            -0.006330806761980057,
            0.023592663928866386,
            -0.022317400202155113,
            0.013084768317639828,
            0.01879107393324375,
            0.00835549645125866,
            -0.008650985546410084,
            -0.017468692734837532,
            0.028657570481300354,
            -0.01772094890475273,
            -0.02497321180999279,
            0.02694069966673851,
            -0.02186073176562786,
            0.018304837867617607,
            0.024980848655104637,
            0.019312916323542595,
            -0.00672113336622715,
            -0.02558952383697033,
            0.023962119594216347,
            -0.020548665896058083,
            0.011284497566521168,
            -0.01784379966557026,
            0.019414003938436508,
            -0.00482807494699955,
            -0.006152178626507521,
            -0.001287593157030642,
            -0.008539258502423763,
            0.03748920559883118,
            0.027370991185307503,
            -0.036931537091732025,
            0.032125361263751984,
            -0.022977516055107117,
            -0.024446023628115654,
            0.012163486331701279,
            0.0022295047529041767,
            0.008588925004005432,
            0.02560492791235447,
            -0.011141302064061165,
            0.024629458785057068,
            0.05025717616081238,
            0.0014628841308876872,
            -0.0014531974447891116,
            -0.03882064297795296,
            0.02114981785416603,
            0.050994645804166794,
            -0.01259690523147583,
            -0.006737121380865574,
            0.0027468057814985514,
            0.03612224757671356,
            0.033143483102321625,
            0.0203073862940073,
            -0.021253861486911774,
            -0.022644126787781715,
            -0.015635330229997635,
            -0.004722369834780693,
            0.011570536531507969,
            -0.019102120772004128,
            -0.01095256581902504,
            0.015946578234434128,
            -0.015072074718773365,
            0.023752931505441666,
            -0.011620843783020973,
            0.007198338862508535,
            -0.006682113278657198,
            0.020135371014475822,
            -0.011130966246128082,
            -0.028488274663686752,
            -0.03603067249059677,
            0.0007246753084473312,
            0.002978831995278597,
            0.011365312151610851,
            -0.0209274310618639,
            0.025078190490603447,
            0.047974057495594025,
            0.02851143479347229,
            0.025331653654575348,
            0.02141985110938549,
            -0.035585567355155945,
            -0.012468453496694565,
            -0.0031241916585713625,
            0.033698517829179764,
            -0.005168243311345577,
            0.010821158066391945,
            0.035513125360012054,
            0.003214586526155472,
            -0.00045540998689830303,
            -0.018268337473273277,
            -0.02647530846297741,
            -0.039052776992321014,
            -0.019929371774196625,
            0.03872885927557945,
            -0.0036736575420945883,
            -0.01570161059498787,
            -0.015699362382292747,
            -0.011969602666795254,
            -0.03145849332213402,
            0.013183250091969967,
            0.009034868329763412,
            -0.0040484098717570305,
            0.0002957326651085168,
            -0.0093535790219903,
            -0.0005976618849672377,
            0.02154938131570816,
            0.023744067177176476,
            0.012932206504046917,
            -0.033187516033649445,
            -0.022083286195993423,
            0.011927912943065166,
            0.007124779280275106,
            0.007210585288703442,
            0.00015909997455310076,
            0.015423520468175411,
            -0.025269165635108948,
            0.02258562482893467,
            -0.0150749571621418,
            0.013590903021395206,
            0.014759542420506477,
            0.04921042546629906,
            0.02743920125067234,
            0.014099190011620522,
            -0.016348009929060936,
            0.04799101874232292,
            -0.01070394553244114,
            -0.024888286367058754,
            -0.026752205565571785,
            0.032575108110904694,
            0.01275006402283907,
            -0.024345649406313896,
            -0.010164637118577957,
            0.02619483321905136,
            -0.01838957704603672,
            0.008639805018901825,
            0.01307890098541975,
            -0.0016913223080337048,
            0.02165527641773224,
            0.0016320499125868082,
            -0.001952527672983706,
            0.0009065742488019168,
            0.023725228384137154,
            -0.007685079239308834,
            0.009659714065492153,
            -0.013840562663972378,
            0.0005569476052187383,
            0.048399098217487335,
            0.025876732543110847,
            0.017652301117777824,
            0.007664924953132868,
            0.022060129791498184,
            0.0188448429107666,
            -0.007691101171076298,
            -0.026728861033916473,
            0.016588561236858368,
            -0.009971230290830135,
            -0.005329709965735674,
            0.006305442191660404,
            -0.009763997979462147,
            -0.008795618079602718,
            -0.02770293690264225,
            0.0259040929377079,
            -0.0315433107316494,
            0.027766985818743706,
            -0.0051942551508545876,
            0.017633091658353806,
            -0.03683186322450638,
            -0.04015985131263733,
            0.004367372021079063,
            -0.0037992740981280804,
            0.013370133936405182,
            0.009220870211720467,
            -0.024300996214151382,
            -0.026676099747419357,
            0.005251399241387844,
            0.01376855093985796,
            -0.023125343024730682,
            -0.010921264067292213,
            -0.018547577783465385,
            0.002292779739946127,
            -0.01956646330654621,
            -0.005867650266736746,
            -0.012049619108438492,
            0.016396114602684975,
            -0.03203662857413292,
            -0.052315160632133484,
            0.003788374364376068,
            0.03517546132206917,
            0.04343632236123085,
            -0.0135832903906703,
            0.012752780690789223,
            0.0384586863219738,
            -0.0013431564439088106,
            -0.02135845087468624
        ],
        [
            -0.003963153809309006,
            0.055592384189367294,
            -0.03194344788789749,
            -0.003791665891185403,
            -0.004718795884400606,
            -0.014887535013258457,
            0.02249719761312008,
            0.048246391117572784,
            0.020488984882831573,
            -0.024955976754426956,
            0.026701107621192932,
            0.011808590032160282,
            0.011790252290666103,
            0.04320108890533447,
            0.030826643109321594,
            -0.045106712728738785,
            -0.004858723841607571,
            0.021647052839398384,
            -0.008532099425792694,
            -0.012677589431405067,
            -0.023117925971746445,
            -0.01560941431671381,
            -0.008693037554621696,
            -0.023515190929174423,
            -0.029249658808112144,
            -0.008607259020209312,
            -0.04247048869729042,
            0.007023586425930262,
            0.008405755273997784,
            -0.03533989191055298,
            0.03125050291419029,
            0.033065129071474075,
            0.01524852029979229,
            -0.03075886331498623,
            -0.017825698480010033,
            0.004205287899821997,
            0.03383120521903038,
            0.004303597379475832,
            -0.0021920129656791687,
            0.01358694676309824,
            -0.01464009564369917,
            -0.005322091281414032,
            -0.015887176617980003,
            0.015618220902979374,
            0.025936787948012352,
            0.009975003078579903,
            0.0005518143880181015,
            -0.03916359320282936,
            0.005007351282984018,
            -0.010553708299994469,
            -0.009865145199000835,
            0.03150695189833641,
            -0.0051031275652348995,
            0.005300696007907391,
            -0.025292154401540756,
            0.02636358141899109,
            0.025421325117349625,
            0.010031437501311302,
            0.03182591125369072,
            0.008008608594536781,
            0.02492266148328781,
            0.03421281650662422,
            -0.030460894107818604,
            0.02308133989572525,
            0.0037867717910557985,
            0.04109366610646248,
            -0.023259388282895088,
            -0.00018358472152613103,
            -0.013696442358195782,
            -0.05488310381770134,
            -0.023373425006866455,
            -0.005557538475841284,
            0.0017417492344975471,
            -0.007883626967668533,
            0.017844807356595993,
            0.021485034376382828,
            -0.017030853778123856,
            0.02191843092441559,
            0.03551723435521126,
            -0.0411221943795681,
            -0.002124526770785451,
            -0.04671517759561539,
            -0.003889059415087104,
            -0.0011308606481179595,
            0.004723119083791971,
            -0.012958750128746033,
            0.005721296649426222,
            -0.00483306311070919,
            0.0017294002464041114,
            0.05990554392337799,
            -0.003901682561263442,
            0.0028490968979895115,
            -0.006671682000160217,
            0.030443349853157997,
            0.008163846097886562,
            -0.044536590576171875,
            -0.016683073714375496,
            -0.023233817890286446,
            -0.07824444770812988,
            0.004340089857578278,
            0.02175019308924675,
            0.05863703787326813,
            -0.008661388419568539,
            -0.050583455711603165,
            -0.016670431941747665,
            0.04030703008174896,
            0.0338609404861927,
            0.002320351777598262,
            0.023003140464425087,
            0.03969978913664818,
            0.03692331165075302,
            0.010384966619312763,
            0.030285624787211418,
            -0.009944540448486805,
            -0.05868353322148323,
            0.017858488485217094,
            0.02017502300441265,
            -0.02512523904442787,
            -0.01850697211921215,
            0.0321628637611866,
            -0.023452086374163628,
            0.0021498859860002995,
            -0.04786963015794754,
            -0.012211675755679607,
            0.02079382911324501,
            0.015348364599049091,
            0.002217820379883051,
            -0.04646571725606918,
            -0.009717441163957119,
            0.029884671792387962,
            -0.030222121626138687,
            0.0007294626557268202,
            0.005891399923712015,
            0.0385746993124485,
            -0.035437244921922684,
            -0.0029534022323787212,
            -0.01600305363535881,
            0.03266696259379387,
            -0.07386483252048492,
            0.01844119466841221,
            -0.027134118601679802,
            -0.0001982810499612242,
            -0.01594892144203186,
            -0.01860474795103073,
            0.03723590448498726,
            -0.01493770070374012,
            -0.013817067258059978,
            0.018568670377135277,
            0.009717150591313839,
            0.012259687297046185,
            -0.013081221841275692,
            0.023233922198414803,
            0.014266093261539936,
            -0.027044063434004784,
            0.009050427936017513,
            -0.0067450255155563354,
            -0.007034020498394966,
            0.028616158291697502,
            -0.03144797682762146,
            0.048707861453294754,
            0.020676156505942345,
            -0.0469927154481411,
            -0.004975564777851105,
            0.020898977294564247,
            -0.023291703313589096,
            -0.005147858522832394,
            -0.010239767841994762,
            -0.0019529242999851704,
            0.007924865931272507,
            -0.015696756541728973,
            -0.05031900852918625,
            -0.005587820429354906,
            -0.04941265657544136,
            -0.009616495110094547,
            -0.0030549587681889534,
            0.018757717683911324,
            -0.0051795304752886295,
            0.012250151485204697,
            0.00698601920157671,
            -0.04751571640372276,
            -0.0032957729417830706,
            0.001767205074429512,
            -0.04043367877602577,
            0.02288329042494297,
            -0.016135748475790024,
            0.032241109758615494,
            0.014754334464669228,
            -0.03026640973985195,
            -0.014475767500698566,
            -0.025570062920451164,
            -0.00940681155771017,
            -0.01059651654213667,
            -0.014587055891752243,
            -0.02727409079670906,
            0.032849911600351334,
            0.030892252922058105,
            0.007659934461116791,
            0.016534186899662018,
            0.033961810171604156,
            -0.024689478799700737,
            -0.003602324752137065,
            -0.029088346287608147,
            0.015608188696205616,
            -0.04447241500020027,
            -0.05213230103254318,
            -0.010842395015060902,
            0.009961158037185669,
            -0.029344430193305016,
            -0.006397812627255917,
            -0.011687276884913445,
            -0.012816756963729858,
            0.03294187784194946,
            -0.02069801278412342,
            -0.022175684571266174,
            0.0025735723320394754,
            0.003068024991080165,
            -0.036613091826438904,
            -0.03496044874191284,
            0.01785321719944477,
            -0.05518188700079918,
            -0.01493893377482891,
            -0.014649779535830021,
            -0.010079420171678066,
            -0.032675471156835556,
            -0.01795864850282669,
            0.07744568586349487,
            0.02595549449324608,
            -0.01554005779325962,
            0.009306207299232483,
            0.018912525847554207,
            -0.03861809894442558,
            -0.015325687825679779,
            -0.044746559113264084,
            0.0016328179044649005,
            -0.011916883289813995,
            -0.018507041037082672,
            -0.025921130552887917,
            -0.0025488613173365593,
            0.02021148055791855,
            0.01467240322381258,
            -0.007719107903540134,
            -0.03990974649786949,
            -0.01772656850516796,
            -0.020663483068346977,
            -0.025625567883253098,
            0.016283228993415833,
            0.030755365267395973,
            0.01454138569533825,
            -0.03169609606266022,
            -0.023946503177285194,
            0.017686352133750916,
            -0.005222601816058159,
            0.007533870171755552,
            -0.026177486404776573,
            0.006569216027855873,
            -0.0253776665776968,
            -0.01819596439599991,
            0.031825657933950424,
            0.010459364391863346,
            0.052701517939567566,
            -0.01780129037797451,
            0.028014205396175385,
            0.01407048013061285,
            -0.02836725302040577,
            -0.006474107503890991,
            -0.029973803088068962,
            -0.04231542348861694,
            0.02701151743531227,
            0.00983536895364523,
            0.0186543557792902,
            -0.006165789440274239,
            0.009633746929466724,
            0.004047350957989693,
            0.021690716966986656,
            -0.022528275847434998,
            -0.0023526609875261784,
            -0.010763575322926044,
            -0.024442005902528763,
            -0.0034610643051564693,
            -0.019340187311172485,
            -0.01052502728998661,
            0.03862110152840614,
            0.012068381533026695,
            0.0769905373454094,
            0.021802512928843498,
            -0.015554798766970634,
            0.01626884937286377,
            0.0926959216594696,
            0.00012502276513259858,
            -0.04620589315891266,
            -0.02376648224890232,
            -0.014792666770517826,
            0.003282789373770356,
            0.01752384752035141,
            -0.02854899689555168,
            0.035728927701711655,
            0.02258356288075447,
            -0.0035852680448442698,
            0.053864289075136185,
            0.005541987717151642,
            -0.017985187470912933,
            -0.043122630566358566,
            0.015725983306765556,
            0.01168201956897974,
            0.01884417049586773,
            0.014736901968717575,
            0.014764775522053242,
            0.03402771055698395,
            -0.010344303213059902,
            0.021770698949694633,
            0.012252121232450008,
            0.009642279706895351,
            0.02704489231109619,
            -0.010011659003794193,
            0.03035619854927063,
            0.008769020438194275,
            -0.008414769545197487,
            -0.013741198927164078,
            0.014579059556126595,
            0.030019037425518036,
            -0.010865419171750546,
            -0.047234706580638885,
            0.05503615364432335,
            0.05101340636610985,
            -0.02914498560130596,
            0.04648752138018608,
            -0.012604975141584873,
            0.027677416801452637,
            0.020466569811105728,
            0.016938460990786552,
            0.03237079083919525,
            -0.00028190866578370333,
            -0.02974192425608635,
            -0.01270042173564434,
            -0.007146130315959454,
            -0.032879676669836044,
            -0.013431891798973083,
            0.02717093750834465,
            -0.023073116317391396,
            -0.055144548416137695,
            0.028732676059007645,
            0.06546963006258011,
            -0.012651641853153706,
            -0.018272167071700096,
            -0.010693461634218693,
            -0.01816432923078537,
            -0.013620658777654171,
            0.01595614105463028,
            0.0003240184159949422,
            -0.024086814373731613,
            -0.04261888191103935,
            -0.035512685775756836,
            -0.06214839965105057,
            -0.041248440742492676,
            0.008491171523928642,
            -0.02499447390437126,
            -0.005459454841911793,
            0.019540853798389435,
            -0.009354998357594013,
            0.011278209276497364,
            -0.0642610564827919,
            -0.03004547767341137,
            0.03934881091117859,
            -0.046964824199676514,
            -0.046189285814762115,
            -0.025164714083075523,
            0.017633555456995964,
            -0.0067808800376951694,
            -0.01746872439980507,
            -0.009651447646319866,
            0.02428596094250679,
            -0.026573142036795616,
            -0.025817107409238815,
            0.05017794668674469,
            -0.005781654268503189,
            0.004674926865845919,
            -0.019925929605960846,
            -0.01257820799946785,
            0.01088717207312584,
            -0.027019167318940163,
            0.006026194430887699,
            -0.02546508051455021,
            -0.02944370172917843,
            -0.038045093417167664,
            -0.04183090478181839,
            0.028666267171502113,
            0.0271765124052763,
            0.008936258032917976,
            -0.016430459916591644,
            0.01216217316687107,
            0.002468273276463151,
            0.014088227413594723,
            0.002492303494364023,
            0.013085676357150078,
            0.009366344660520554,
            -0.020337821915745735,
            -0.02277989685535431,
            -0.02897408977150917,
            -0.029511483386158943,
            0.004699449986219406,
            0.015611554495990276,
            0.008250106126070023,
            -0.022510141134262085,
            -0.01753944158554077,
            0.013285264372825623,
            -0.002035797107964754,
            0.010965114459395409,
            -0.01264276821166277,
            0.004657191224396229,
            -0.03356805816292763,
            -0.011366176418960094,
            -0.036001056432724,
            -0.01946326158940792,
            -0.04098954051733017,
            -0.029532672837376595,
            -0.03197193518280983,
            -0.007391879800707102,
            -0.053459323942661285,
            0.007713980972766876,
            -0.026783809065818787,
            0.061941664665937424,
            -0.009311584755778313,
            0.005848230328410864,
            0.021683508530259132,
            -0.021777523681521416,
            -0.022901464253664017,
            0.0344003364443779,
            0.005762540735304356,
            0.029321085661649704,
            -0.021185249090194702,
            -0.013889910653233528,
            0.003958581015467644,
            0.006175213493406773,
            -0.015718791633844376,
            -0.013849248178303242,
            0.013290418311953545,
            -0.018910333514213562,
            0.019640378654003143,
            -0.005755667574703693,
            -0.04254554957151413,
            0.03689085692167282,
            0.03059147298336029,
            -0.004647161811590195,
            0.02848837710916996,
            -0.017029590904712677,
            -0.021112792193889618,
            0.0038282908499240875,
            0.033680520951747894,
            -0.060947731137275696,
            0.03534310683608055,
            -0.009500749409198761,
            -0.016283946111798286,
            -0.0592351071536541,
            0.005241235718131065,
            -0.014152749441564083,
            0.003770824521780014,
            0.008348284289240837,
            -0.00547472620382905,
            0.009291806258261204,
            0.019726132974028587,
            -0.009854714386165142,
            -0.013146938756108284,
            -0.03192399442195892,
            0.028429003432393074,
            -0.01416474487632513,
            -0.014004480093717575,
            0.018837282434105873,
            -0.016263293102383614,
            -0.03765999153256416,
            0.028922395780682564,
            -0.008169188164174557,
            -0.02802063524723053,
            0.01891355961561203,
            -0.0034068827517330647,
            -0.02664634957909584,
            -0.03203601390123367,
            0.0010687755420804024,
            0.02286764606833458,
            0.014533883891999722,
            -0.03748058155179024,
            -0.018342088907957077,
            -0.05778954178094864,
            -0.008452952839434147,
            0.024870699271559715,
            -0.026893988251686096,
            -0.04774610698223114,
            0.03849051520228386,
            -0.010959655046463013,
            0.09722376614809036,
            -0.005660648923367262,
            0.011963894590735435,
            -0.034113895148038864,
            -0.027954448014497757,
            0.015139492228627205,
            -0.08626192808151245,
            -0.05853723734617233,
            0.056769534945487976,
            -0.02813994511961937,
            0.026585271582007408,
            0.024517996236681938,
            0.02123688906431198,
            -0.02276819944381714,
            0.0030483731534332037,
            0.02545146830379963,
            -0.004913007840514183,
            -0.006573282647877932,
            -0.044953133910894394,
            0.017601506784558296,
            -0.043185755610466,
            -0.012425472028553486,
            0.01837398111820221,
            -0.0016191391041502357,
            -0.006222686730325222,
            0.03757845610380173,
            0.01720309630036354,
            0.01710028201341629,
            0.009240495041012764,
            0.0009711563470773399,
            0.03014305792748928,
            -0.0020478705409914255,
            -0.027036329731345177,
            -0.02892230451107025,
            -0.04358324036002159,
            0.01308512594550848,
            0.007223214488476515,
            0.0014037404907867312,
            0.02021140605211258,
            -0.010359341278672218,
            -0.0040091765113174915,
            -0.010735208168625832,
            0.013718895614147186,
            0.022347932681441307,
            0.0007511652074754238,
            0.016445670276880264,
            0.009856543503701687,
            0.016199452802538872,
            -0.0267836581915617,
            -0.008046862669289112,
            -0.01061154529452324,
            -0.012569792568683624,
            0.00871441699564457,
            0.02349979430437088,
            0.0019220089307054877,
            -0.010160747915506363,
            0.007095641922205687,
            -0.09396213293075562,
            -0.012913339771330357,
            -0.0005093822837807238,
            -0.015099520795047283,
            -0.0066709863021969795,
            0.01820531114935875,
            -0.02153167687356472,
            -0.006165219470858574,
            0.03938613086938858,
            0.008872734382748604,
            -0.004574642051011324,
            -0.0045996541157364845,
            -0.006815385073423386,
            -0.006227443460375071,
            0.003077650675550103,
            0.03908982872962952,
            -0.0025415541604161263,
            0.017891205847263336,
            -0.03765825927257538,
            -0.023943815380334854,
            0.027066385373473167,
            -0.02771502360701561,
            -0.015737829729914665,
            -0.010129612870514393,
            0.02709050476551056,
            -0.026281993836164474,
            -0.00812075287103653,
            0.020816128700971603,
            0.010401535779237747,
            -0.02289014309644699,
            0.021930478513240814,
            0.003792337141931057,
            0.03298688679933548,
            -0.03769924119114876,
            -0.007036156952381134,
            -0.019045740365982056,
            -0.047862280160188675,
            0.032075293362140656,
            0.01437437441200018,
            0.016351446509361267,
            0.029303450137376785,
            -0.021921491250395775,
            -0.053346939384937286,
            -0.012104620225727558,
            -0.014001419767737389,
            -0.03282848373055458,
            0.002587364288046956,
            0.029913244768977165,
            0.05510798469185829,
            -0.01125244703143835,
            0.011165408417582512,
            -0.02523026056587696,
            0.016482682898640633,
            0.007139368914067745,
            -0.049816835671663284,
            0.011419453658163548,
            0.025651024654507637,
            0.01611902378499508,
            0.031427666544914246,
            0.02331879362463951,
            -0.0035456051118671894,
            0.014473676681518555,
            -0.032939016819000244,
            -0.005790458060801029,
            -0.006421116180717945,
            0.012611421756446362,
            0.013514978811144829,
            0.024761494249105453,
            0.00016111467266455293,
            0.0026754809077829123,
            -0.0049681332893669605,
            0.02241111919283867,
            0.012193133123219013,
            0.018923688679933548,
            -0.04824310168623924,
            -0.01162540353834629,
            -0.00907149538397789,
            -0.030632875859737396,
            -0.009860260412096977,
            0.0071837580762803555,
            -0.009930443949997425,
            -0.0026699621230363846,
            0.05060335621237755,
            0.007757582701742649,
            0.03098597750067711,
            0.0010002914350479841,
            0.023839855566620827,
            0.00046751918853260577,
            -0.04618864133954048,
            0.03933131694793701,
            0.04420366510748863,
            0.028994692489504814,
            0.0027135713025927544,
            0.030826641246676445,
            0.008368800394237041,
            -0.029627006500959396,
            -0.09811637550592422,
            -0.03076101280748844,
            -0.034221101552248,
            -0.025127341970801353,
            0.021651633083820343,
            0.021267570555210114,
            -0.004294161219149828,
            -0.03640623763203621,
            -0.04645377770066261,
            0.01916833221912384,
            -0.0112704336643219,
            -0.0070065949112176895,
            0.03446394205093384,
            0.002789534395560622,
            -0.01992436684668064,
            -0.04249369353055954,
            -0.011326594278216362,
            0.03488079085946083,
            0.03253816440701485,
            -0.02312205731868744,
            -0.0033597787842154503,
            0.020153379067778587,
            -0.006221551913768053,
            0.008339722640812397,
            0.037103790789842606,
            0.01749974675476551,
            -0.00300942943431437,
            0.023147860541939735,
            -0.0066030132584273815,
            -0.016861403360962868,
            0.0021861076820641756,
            -0.02025475725531578,
            -0.08447513729333878,
            0.0022158948704600334,
            0.018030822277069092,
            -0.00032386594102717936,
            -0.03835410252213478,
            0.007527452427893877,
            -0.03810146078467369,
            0.0321289598941803,
            -0.04343109205365181,
            -0.01612967625260353,
            0.003647085977718234,
            -0.012503094039857388,
            0.0071806153282523155,
            -0.026237959042191505,
            -0.02235482633113861,
            -0.05556236207485199,
            0.00352840987034142,
            -0.023195823654532433,
            0.014096908271312714,
            -0.05296814814209938,
            -0.0077184028923511505,
            -0.023948268964886665,
            -0.013288122601807117,
            0.023393208160996437,
            -0.0062318528071045876,
            -0.01733342371881008,
            -0.002248126547783613,
            0.018273083493113518,
            0.04494784027338028,
            -0.005431452766060829,
            -0.03773019090294838,
            -0.022513123229146004,
            -0.03488388657569885,
            -0.03492631018161774,
            -0.008053344674408436,
            0.007994838990271091,
            -0.035960014909505844,
            0.01821412704885006,
            0.0060458797961473465,
            -0.005057145841419697,
            -0.0010252466890960932,
            -0.01804167777299881,
            -0.017969617620110512,
            0.0029404684901237488,
            -0.006487160921096802,
            -0.01571078971028328,
            -0.033075395971536636,
            -0.005010498687624931,
            0.00015400802658405155,
            0.008308212272822857,
            0.04746108502149582,
            -0.005847316700965166,
            -0.01191877480596304,
            0.024109352380037308,
            0.01658467948436737,
            0.03339545801281929,
            0.016314368695020676,
            -0.006830425467342138,
            -0.022211402654647827,
            0.010790090076625347,
            0.014299346134066582,
            -0.022532707080245018,
            0.03146250545978546,
            -0.02225891500711441,
            -0.03575464338064194,
            0.03870556503534317,
            -0.03359566256403923,
            0.03302188590168953,
            0.012015164829790592,
            -0.040377385914325714,
            -0.028995968401432037,
            0.016041982918977737,
            -0.011472485959529877,
            0.055182285606861115,
            -0.01770637556910515,
            -0.014268201775848866,
            -0.030792420729994774,
            0.0005675924476236105,
            0.017730578780174255,
            -0.016197429969906807,
            -0.02875428833067417,
            0.003254489740356803,
            0.02708696573972702,
            -0.013213397935032845,
            0.04934154823422432,
            -0.02847825177013874,
            0.021225526928901672,
            -0.01816166564822197,
            -0.049386657774448395,
            0.027436163276433945,
            -0.046819355338811874,
            -0.04440659284591675,
            0.004020413383841515,
            -0.01664119027554989,
            -0.016887066885828972,
            -0.04974640905857086,
            -0.03497925400733948,
            -0.0017983521101996303,
            -0.01121542789041996,
            0.020193619653582573,
            -0.03292672708630562,
            -0.05309155210852623,
            -0.02504277229309082,
            -0.004570428282022476,
            0.0010187922744080424,
            -0.010421649552881718,
            -0.031834591180086136,
            -0.025943322107195854,
            0.0016018177848309278,
            0.006364569533616304,
            -0.03384298086166382,
            0.022966906428337097,
            0.02554437518119812,
            0.006286764983087778,
            0.0009668208076618612,
            -0.019366934895515442,
            0.032241642475128174,
            -0.013515833765268326,
            0.0007503826636821032,
            0.03497763350605965,
            0.03080013208091259,
            -0.000160854629939422,
            0.07207292318344116,
            0.036147117614746094,
            -0.014771941117942333,
            0.02559688501060009,
            0.008224139921367168,
            0.047840457409620285,
            0.0024782356340438128,
            -0.020120052620768547,
            -0.04183559864759445,
            -0.007855604402720928,
            -0.05323420837521553,
            -0.017924994230270386,
            0.010027135722339153,
            -0.010719165205955505,
            0.006299033295363188,
            -0.020110270008444786,
            -0.039830949157476425,
            -0.010986530221998692,
            -0.0037059886381030083,
            0.006986461114138365,
            -0.026097673922777176,
            -0.0259479321539402,
            0.03720797225832939,
            0.0023845555260777473,
            -0.012318768538534641,
            -0.013149548321962357,
            0.01855350099503994,
            0.0036018958780914545,
            -0.018301643431186676,
            -0.013540727086365223,
            0.03369496762752533,
            -0.03287125751376152,
            -0.01598864234983921,
            -0.012362292036414146,
            0.02135981060564518,
            0.02554248459637165,
            0.005244397092610598,
            0.005989656317979097,
            0.026457233354449272,
            -0.07667997479438782,
            -0.056368906050920486,
            0.03765535354614258,
            -0.013617664575576782,
            0.006113406736403704,
            -0.03070334531366825,
            0.01746845617890358,
            0.0065826233476400375,
            -0.013477005064487457,
            -0.03137468546628952,
            0.0159623920917511,
            0.030396437272429466,
            0.0034952901769429445,
            0.00584690785035491,
            0.021051105111837387,
            -0.04167935997247696,
            -0.02014213614165783,
            0.0117781488224864,
            -0.04958628863096237,
            -0.02568945661187172,
            0.051631320267915726,
            0.019146958366036415,
            -0.00924359355121851,
            0.02649298496544361,
            -0.0030251655261963606,
            -0.02150590904057026,
            0.02271447144448757,
            -0.013894396834075451,
            0.027219178155064583,
            0.02703842520713806,
            -0.03596151992678642,
            -0.03361911326646805,
            0.015337311662733555,
            0.016997413709759712,
            -0.05383589491248131,
            -0.022231679409742355,
            0.030506568029522896,
            0.02496878243982792,
            -0.02559453807771206,
            -0.04317203909158707,
            -0.01460210606455803,
            0.027056753635406494,
            0.009326200000941753,
            -0.028978876769542694,
            -0.009916353970766068,
            0.006204071920365095,
            0.03667709231376648,
            -0.02950015850365162,
            -0.023769518360495567,
            -0.04567030072212219,
            -0.03513537347316742,
            -0.031152866780757904,
            0.005706620402634144,
            -0.01659911684691906,
            0.033626604825258255,
            0.0005865208222530782,
            -0.024137336760759354,
            -0.03818298876285553,
            -0.05269115790724754,
            -0.02127539925277233,
            -0.08564216643571854,
            0.02140030823647976,
            0.0018866605823859572,
            0.025811901316046715,
            -0.013832513242959976,
            0.03273731470108032,
            0.03981340304017067,
            0.028847694396972656,
            -0.0007700595888309181,
            -0.019799917936325073,
            -0.08118791878223419,
            0.04216796159744263,
            0.0034187890123575926,
            -0.017108263447880745,
            -0.020226575434207916,
            -0.03410867974162102,
            -0.0010189061285927892,
            -0.0183967724442482,
            -0.0007206162554211915,
            0.01172154862433672,
            0.019576236605644226,
            -0.01905989833176136,
            -0.02002689801156521,
            0.03075326792895794,
            -0.0047281417064368725,
            0.037906210869550705,
            -0.007390149869024754,
            -0.005273303482681513,
            -0.014677051454782486,
            0.03312695398926735,
            -0.045507267117500305,
            -0.04773272946476936,
            -0.02935006469488144,
            0.05053490772843361,
            0.014900680631399155,
            0.02421760745346546,
            0.009850390255451202,
            -0.024218643084168434,
            0.024337591603398323,
            0.0172936599701643,
            -0.027136439457535744,
            -0.0037878400180488825,
            0.012989046052098274,
            -0.026988331228494644,
            0.008835933171212673,
            -0.04262261837720871,
            -0.022433578968048096,
            0.006023772526532412,
            -0.008877039887011051,
            -0.02030990645289421,
            0.0466008260846138,
            -0.020590219646692276,
            -0.027003703638911247,
            -0.003062986535951495,
            0.012106145732104778,
            -0.028157060965895653,
            -0.020862815901637077,
            -0.021563222631812096,
            -0.011012159287929535,
            0.023602066561579704,
            -0.04698852449655533,
            -0.05332724750041962,
            0.024893995374441147,
            -0.01961144246160984,
            0.01075584627687931,
            0.0441201813519001,
            0.07070495933294296,
            -0.020640254020690918,
            -0.032261718064546585,
            -0.026593491435050964,
            0.05042552947998047,
            -0.02364317700266838,
            0.004263695329427719,
            -0.010052158497273922,
            0.0001880279160104692,
            0.04023668169975281,
            0.030968062579631805,
            -0.0023553157225251198,
            -0.022966483607888222,
            0.02947411499917507,
            0.0021822312846779823,
            -0.027098357677459717,
            0.005719594657421112,
            0.02948145382106304,
            0.008474252186715603,
            0.011073881760239601,
            0.014941154047846794,
            -0.03189189359545708,
            -0.03514757752418518,
            -0.03077228181064129,
            0.040020231157541275,
            -0.016779117286205292,
            -0.001966874347999692,
            -0.012319366447627544,
            -0.007623918820172548,
            0.005366011988371611,
            0.007929855026304722,
            0.019803637638688087,
            0.018612997606396675,
            -0.042693737894296646,
            0.0017793294973671436,
            0.01844160631299019,
            -0.018939655274152756,
            -0.0066927154548466206,
            0.04254117235541344,
            0.005135505460202694,
            -0.03852338716387749,
            -0.014618064276874065,
            -0.03989617899060249,
            -0.016994062811136246,
            0.0034206723794341087,
            -0.03445548936724663,
            0.011300327256321907,
            -0.04600931704044342,
            -0.0010677864775061607,
            0.019240878522396088,
            -0.02061980590224266,
            -0.044822536408901215,
            -0.007607561070472002,
            0.004924537148326635,
            0.017235400155186653,
            0.019652606919407845,
            0.028144471347332,
            -0.0015325038693845272,
            -0.00803603045642376,
            0.0010910809505730867,
            -0.03653673827648163,
            0.022023487836122513,
            -0.007841946557164192,
            0.03398817032575607
        ],
        [
            -0.023719996213912964,
            0.006946657784283161,
            0.028345147147774696,
            -0.01627463474869728,
            0.014423043467104435,
            -0.031078509986400604,
            -0.004949890077114105,
            -0.0007068845443427563,
            0.011039766483008862,
            -0.024964019656181335,
            -0.010439692996442318,
            -0.01204973179847002,
            0.01770120859146118,
            0.005392070859670639,
            -0.029940228909254074,
            -0.0038829552941024303,
            0.0010722973383963108,
            -0.004881276283413172,
            -0.015853634104132652,
            0.0014130782801657915,
            0.022444579750299454,
            0.02660840004682541,
            -0.016109803691506386,
            -0.029534269124269485,
            -0.0071687959134578705,
            0.007961471565067768,
            0.003961754497140646,
            0.022140564396977425,
            0.01578713022172451,
            0.000986403552815318,
            -0.0026565406005829573,
            -0.028025582432746887,
            -0.009885713458061218,
            -0.013657520525157452,
            0.008253785781562328,
            -0.014803917147219181,
            -0.017675895243883133,
            -0.005416974890977144,
            -0.01963656023144722,
            -0.015798144042491913,
            0.02330549620091915,
            0.01799122802913189,
            -0.00029807601822540164,
            -0.0059939282946288586,
            -0.006803594529628754,
            0.014290649443864822,
            0.005223086569458246,
            -0.007673601619899273,
            0.025443878024816513,
            -0.0011270233662799,
            -0.029815534129738808,
            0.007966965436935425,
            -0.02540396898984909,
            -0.02894393540918827,
            -0.014479958452284336,
            -0.009145609103143215,
            -0.0026650724466890097,
            -0.023496687412261963,
            -0.018375741317868233,
            0.0107518145814538,
            0.011417699046432972,
            -0.004037643317133188,
            0.02144470438361168,
            0.014668025076389313,
            0.01869634911417961,
            0.00878919754177332,
            0.025773562490940094,
            -0.005866027902811766,
            -0.019897105172276497,
            0.025460239499807358,
            -0.028057683259248734,
            0.012684844434261322,
            -0.016556046903133392,
            -0.009730204939842224,
            -0.023489564657211304,
            0.0014319501351565123,
            0.00042575126281008124,
            0.022648021578788757,
            0.015751682221889496,
            0.007220461033284664,
            -0.014843860641121864,
            0.016579672694206238,
            0.011091124266386032,
            0.012156163342297077,
            -0.01714862883090973,
            -0.0005033991765230894,
            0.00418635830283165,
            -0.0028200848028063774,
            0.029232865199446678,
            0.02393508329987526,
            -0.007123879157006741,
            -0.024114742875099182,
            0.0034565404057502747,
            0.016262859106063843,
            -0.022370053455233574,
            -0.02617403119802475,
            -0.0027434725780040026,
            -0.025793924927711487,
            -0.025967225432395935,
            0.00634166831150651,
            0.013267852365970612,
            -0.0076606422662734985,
            -0.010021565482020378,
            -0.003856892231851816,
            0.022220924496650696,
            0.0014452844625338912,
            0.008451071567833424,
            -0.025547649711370468,
            0.010769096203148365,
            0.02809823676943779,
            -0.013503411784768105,
            -0.01189460325986147,
            0.0019852817058563232,
            0.0207122340798378,
            -0.030570413917303085,
            -0.01615542732179165,
            -0.020304879173636436,
            -0.027536019682884216,
            -0.011980355717241764,
            -0.013633235357701778,
            -0.0274838674813509,
            -0.010877257212996483,
            0.007608138024806976,
            0.02639360912144184,
            -0.030176527798175812,
            -0.01376509852707386,
            -0.019798116758465767,
            -0.018401121720671654,
            0.0015548737719655037,
            0.017985086888074875,
            0.0015612280694767833,
            0.016417689621448517,
            0.030485831201076508,
            -0.027410589158535004,
            0.020041167736053467,
            0.02573091723024845,
            0.023958319798111916,
            0.01870903931558132,
            0.017792576923966408,
            -0.028502751141786575,
            -0.024016138166189194,
            0.028353547677397728,
            -0.014577715657651424,
            -0.004329342395067215,
            0.021722713485360146,
            0.01699974201619625,
            -0.008349521085619926,
            -0.026272321119904518,
            0.015310915187001228,
            0.024952910840511322,
            -0.025734400376677513,
            -0.026252806186676025,
            -0.007167880889028311,
            -0.019668666645884514,
            -0.006893690675497055,
            -0.014057165011763573,
            -0.005098707042634487,
            -0.01750774495303631,
            -0.020713046193122864,
            0.010901864618062973,
            0.014348114840686321,
            5.735067679779604e-05,
            -0.01789664663374424,
            0.010359818115830421,
            0.018164735287427902,
            0.028455011546611786,
            -0.002693490358069539,
            0.020751608535647392,
            -0.0023154220543801785,
            -0.008549204096198082,
            -0.012970631942152977,
            -0.024494782090187073,
            0.009141724556684494,
            0.023355556651949883,
            -0.03167729452252388,
            0.02000884898006916,
            -0.017273573204874992,
            0.02617095410823822,
            -0.012708931230008602,
            0.02334430068731308,
            0.006478064227849245,
            -0.006159499753266573,
            -0.013322318904101849,
            -0.0028814072720706463,
            0.020042071118950844,
            -0.0120188407599926,
            0.005918395239859819,
            0.0038133859634399414,
            0.013424751348793507,
            0.017261678352952003,
            -0.01975678838789463,
            -0.023725517094135284,
            0.024195831269025803,
            0.005115506239235401,
            -0.023800773546099663,
            0.016166171059012413,
            -0.02443194016814232,
            -0.005389610305428505,
            -0.023135436698794365,
            -0.018196439370512962,
            -0.013962417840957642,
            -0.01988155022263527,
            -0.02361173927783966,
            0.02160228043794632,
            -0.0038277043495327234,
            -0.01396812405437231,
            -0.006597802042961121,
            -0.03033941239118576,
            0.0008208910585381091,
            0.00720227649435401,
            -0.02412986569106579,
            0.009930187836289406,
            0.01081327348947525,
            -0.028149599209427834,
            -0.01937861554324627,
            0.026178017258644104,
            0.00455462234094739,
            0.020891308784484863,
            -0.026636261492967606,
            -0.02739798091351986,
            0.026608986780047417,
            0.010890074074268341,
            0.003739309962838888,
            -0.009133928455412388,
            0.025031598284840584,
            -0.02711300551891327,
            -0.006920177489519119,
            0.02037571743130684,
            0.011542241089046001,
            -0.01401556096971035,
            -0.02344224788248539,
            -0.015147282741963863,
            -0.02745980955660343,
            0.029739951714873314,
            0.0021730230655521154,
            -0.027466299012303352,
            -0.01512925885617733,
            -0.024772508069872856,
            -0.03113744407892227,
            0.004904765635728836,
            0.015363061800599098,
            0.011580904945731163,
            0.028466612100601196,
            0.02456922084093094,
            -0.001569980988278985,
            -0.0022138783242553473,
            -0.015235929749906063,
            0.02386527881026268,
            -0.030975954607129097,
            0.01676422357559204,
            -0.008506937883794308,
            0.017123334109783173,
            -0.01299950573593378,
            0.022626889869570732,
            0.023678304627537727,
            -0.031835705041885376,
            0.014964481815695763,
            -0.012214278802275658,
            0.02021135948598385,
            -0.006676529534161091,
            0.002834340324625373,
            0.013744056224822998,
            -0.013609159737825394,
            0.014152777381241322,
            0.008913512341678143,
            -0.02531774528324604,
            -0.0242670476436615,
            -0.021614626049995422,
            -0.00451680738478899,
            0.016708627343177795,
            -0.011362112127244473,
            0.010127677582204342,
            -0.010304785333573818,
            0.025459617376327515,
            -0.001993425888940692,
            -0.017902102321386337,
            0.0027231695130467415,
            -0.03009733185172081,
            -0.001614989130757749,
            -0.010963729582726955,
            -0.03102831356227398,
            0.00274451426230371,
            0.0013949028216302395,
            -0.02408050186932087,
            -0.020960301160812378,
            0.02727353759109974,
            -0.030979476869106293,
            -0.014770109206438065,
            -0.030140135437250137,
            0.00022814099793322384,
            0.005602983292192221,
            0.030949126929044724,
            -0.023104649037122726,
            0.004861853085458279,
            -0.019748762249946594,
            0.001382934977300465,
            0.015022632665932178,
            -0.016639990732073784,
            -0.02241053245961666,
            -0.01175364013761282,
            -0.017909500747919083,
            0.02563202753663063,
            0.01985960453748703,
            -0.001064646290615201,
            -0.0026437854394316673,
            -0.007838103920221329,
            -0.016826771199703217,
            -0.02319428324699402,
            -0.015018213540315628,
            0.00909917801618576,
            -0.02475990355014801,
            -0.026840338483452797,
            -0.0257519893348217,
            -0.030024046078324318,
            0.029725300148129463,
            -0.03203214704990387,
            -0.01323920302093029,
            -0.014630633406341076,
            -0.008909315802156925,
            -0.0071515836752951145,
            0.011378382332623005,
            -0.002308632479980588,
            0.016194719821214676,
            -0.027651075273752213,
            0.02746228687465191,
            -0.013947769068181515,
            -0.012263961136341095,
            0.014312599785625935,
            -0.01808340474963188,
            -0.018433067947626114,
            0.026918461546301842,
            -0.009030252695083618,
            0.013149634003639221,
            -0.0026073951739817858,
            0.012522195465862751,
            -0.01887901872396469,
            -0.028807763010263443,
            0.009111419320106506,
            0.021418796852231026,
            -0.013021362014114857,
            -0.030802248045802116,
            0.02602255716919899,
            0.0003418876731302589,
            0.004027828574180603,
            -0.013401088304817677,
            0.0013581757666543126,
            0.020355818793177605,
            -0.004569843411445618,
            0.01909283548593521,
            0.01805783249437809,
            0.0013229429023340344,
            0.017498821020126343,
            -0.014972392469644547,
            0.014672166667878628,
            0.012435991317033768,
            -0.02701709419488907,
            0.021499203518033028,
            0.0010012611746788025,
            0.012864054180681705,
            0.020315956324338913,
            0.016131022945046425,
            0.005007949657738209,
            0.004756364971399307,
            -0.020682374015450478,
            -0.02248188853263855,
            0.024999652057886124,
            0.00572162214666605,
            -0.00016745894390624017,
            0.02019929140806198,
            -0.006304423790425062,
            -0.024517986923456192,
            -0.01397505309432745,
            0.0031544349621981382,
            0.0264765415340662,
            -0.016122695058584213,
            -0.0055517591536045074,
            0.01354475412517786,
            0.016694026067852974,
            -0.01836271956562996,
            -0.008983785286545753,
            -0.02792118676006794,
            0.006153371650725603,
            -0.024985283613204956,
            -0.0022994305472820997,
            -0.01924130506813526,
            0.028742678463459015,
            -0.007432965561747551,
            -0.028482485562562943,
            0.0007685816381126642,
            0.020672570914030075,
            -0.021120954304933548,
            -0.0021278634667396545,
            0.012006253935396671,
            -0.01147901639342308,
            0.012112668715417385,
            -0.03175361454486847,
            -0.01054083276540041,
            -0.00685717910528183,
            -0.031799715012311935,
            -0.02079734392464161,
            0.003391480538994074,
            -0.012279365211725235,
            -0.022460272535681725,
            -0.012923416681587696,
            -0.021917620673775673,
            0.025191502645611763,
            -0.01986498013138771,
            0.0021925047039985657,
            0.009314949624240398,
            -0.0037862120661884546,
            -0.015695389360189438,
            -0.02208995819091797,
            -0.00864445511251688,
            -0.01857021637260914,
            -0.020842354744672775,
            -0.013711873441934586,
            8.690729737281799e-05,
            0.02464640699326992,
            -0.00799097865819931,
            -0.0002740838099271059,
            0.016005631536245346,
            -0.012025061994791031,
            -0.00422421470284462,
            -0.011151215061545372,
            -0.004651674535125494,
            0.015311038121581078,
            0.017700528725981712,
            -0.018778178840875626,
            -0.010102363303303719,
            -0.004132500849664211,
            -0.0001281541772186756,
            0.02733141928911209,
            0.010645268484950066,
            0.019290275871753693,
            -0.022664394229650497,
            -0.015700191259384155,
            0.007873917929828167,
            -0.0024034171365201473,
            0.019936716184020042,
            -0.014315523207187653,
            -0.003894877852872014,
            -0.0017319968901574612,
            0.0015899379504844546,
            0.01498482283204794,
            -0.02444039098918438,
            0.006817950867116451,
            -0.020571431145071983,
            -0.001386057585477829,
            0.023494072258472443,
            -0.011370723135769367,
            0.009393027052283287,
            0.023913469165563583,
            -0.018124785274267197,
            0.008402933366596699,
            0.02478943020105362,
            -0.0180627703666687,
            -0.025852706283330917,
            -0.010392456315457821,
            0.00017084322462324053,
            -0.028174184262752533,
            0.006182642187923193,
            -0.0023977248929440975,
            -0.018698057159781456,
            0.03053959459066391,
            0.009636380709707737,
            -0.0038846726529300213,
            0.02807677909731865,
            -0.022476421669125557,
            0.01415572501718998,
            -0.02087792381644249,
            -0.0074259210377931595,
            0.0286579430103302,
            0.0007062446093186736,
            0.013175860047340393,
            0.025279713794589043,
            -0.006412487477064133,
            -0.029644010588526726,
            -0.008954526856541634,
            -0.021555250510573387,
            -0.027417881414294243,
            -0.02629558928310871,
            -0.008993655443191528,
            -0.028714124113321304,
            -0.025826912373304367,
            0.028052981942892075,
            -0.0191913191229105,
            -0.006755935028195381,
            0.028842302039265633,
            0.017890330404043198,
            0.015216885134577751,
            -0.0007813423871994019,
            -0.025693420320749283,
            0.00040810630889609456,
            0.010271496139466763,
            -0.006761068943887949,
            0.0006322974804788828,
            0.01643928326666355,
            0.018184125423431396,
            -0.003367664059624076,
            0.021835150197148323,
            -0.024291692301630974,
            -0.016446173191070557,
            -0.007196109741926193,
            -0.027784381061792374,
            -0.00584393460303545,
            0.01724090985953808,
            0.012496504932641983,
            -0.0010158518562093377,
            0.010993396863341331,
            -0.0003454115358181298,
            -0.0026123758871108294,
            0.011559046804904938,
            0.021937932819128036,
            0.02951851300895214,
            0.006611999589949846,
            0.012963171117007732,
            -0.021858686581254005,
            0.0073974705301225185,
            0.0022311971988528967,
            0.026817388832569122,
            -0.01494544930756092,
            -0.030544066801667213,
            -0.001624152297154069,
            0.004775641486048698,
            -0.0071984827518463135,
            -0.00948739517480135,
            0.002473604865372181,
            -0.013858877122402191,
            0.009234470315277576,
            -0.0285398717969656,
            0.022458678111433983,
            -0.009915060363709927,
            0.006314996164292097,
            -0.02656400389969349,
            -0.020733334124088287,
            0.00613054633140564,
            -0.03171078860759735,
            0.022674279287457466,
            -0.027105487883090973,
            -0.02557760290801525,
            -0.015409877523779869,
            0.010395974852144718,
            0.004674730356782675,
            0.00543283112347126,
            -0.019566349685192108,
            0.020455457270145416,
            -0.010915539227426052,
            -0.028419911861419678,
            -0.01479051262140274,
            0.00511520728468895,
            -0.020514369010925293,
            -0.02403894253075123,
            0.011917932890355587,
            -0.02519206702709198,
            -0.010051107034087181,
            -0.025813840329647064,
            0.02934337966144085,
            -0.026905981823801994,
            0.0038222100120037794,
            -0.0065974947065114975,
            0.020711807534098625,
            0.02948049269616604,
            -0.0303200613707304,
            0.02143392153084278,
            -0.006861681118607521,
            0.007677888497710228,
            0.024726472795009613,
            -0.018717600032687187,
            0.025898398831486702,
            -0.002296209102496505,
            -0.021182358264923096,
            0.0261045191437006,
            0.0219991784542799,
            0.022498492151498795,
            -0.0006594082224182785,
            -0.027445292100310326,
            -0.006921438034623861,
            -0.012345253489911556,
            -0.02319101057946682,
            0.0077805183827877045,
            -0.03137704357504845,
            0.007072959095239639,
            0.02495911531150341,
            -0.025778094306588173,
            -0.022674860432744026,
            -0.02305193617939949,
            -0.03125213831663132,
            -0.025391314178705215,
            0.005428962409496307,
            -0.027937470003962517,
            -0.027108723297715187,
            -0.004770223051309586,
            0.01474375743418932,
            0.013583643361926079,
            0.007094904780387878,
            -0.0272675734013319,
            0.02287110686302185,
            0.02699337899684906,
            -0.007185707334429026,
            0.02590762823820114,
            -0.027637260034680367,
            0.008134899660944939,
            -0.02390371635556221,
            -0.0162676852196455,
            0.009029783308506012,
            -0.005066669080406427,
            0.003421297762542963,
            -0.010722740553319454,
            0.0015492987586185336,
            -0.02689325250685215,
            0.02834879234433174,
            -0.0015902802115306258,
            0.01222038734704256,
            -0.027976810932159424,
            -0.017363248392939568,
            -0.0051842802204191685,
            0.005862911231815815,
            0.019378256052732468,
            0.008439098484814167,
            0.0004992002504877746,
            0.005126602947711945,
            -0.018059559166431427,
            -0.0035009048879146576,
            -0.010204545222222805,
            -0.009677115827798843,
            0.0081779221072793,
            -0.028535284101963043,
            0.020167957991361618,
            0.007535408716648817,
            -0.003216389799490571,
            -0.013021917082369328,
            -0.010193410329520702,
            0.011313403025269508,
            0.0019182009855285287,
            0.02411750890314579,
            -0.023394815623760223,
            -0.001186974230222404,
            -0.012613075785338879,
            -0.015989987179636955,
            0.02525787614285946,
            -0.011681602336466312,
            0.014016174711287022,
            0.004032764583826065,
            0.01304197870194912,
            0.0032484137918800116,
            -0.030023792758584023,
            0.023607421666383743,
            -0.01545623317360878,
            0.01905275695025921,
            0.01216757483780384,
            -0.024685319513082504,
            0.02000540867447853,
            0.005281267687678337,
            -0.0034823762252926826,
            -0.011140575632452965,
            0.010277917608618736,
            0.010373090393841267,
            -0.0077966926619410515,
            0.004813279956579208,
            0.0034649819135665894,
            -0.015412788838148117,
            -0.023721933364868164,
            -0.011497465893626213,
            -0.017326917499303818,
            -0.002428327454254031,
            -0.01505107618868351,
            0.016407357528805733,
            -0.017140939831733704,
            -0.0024834186770021915,
            0.01824549399316311,
            -0.008203232660889626,
            0.01362893357872963,
            0.025579210370779037,
            -0.023509616032242775,
            -0.02541758306324482,
            -0.019865667447447777,
            0.02687964215874672,
            0.02270258404314518,
            -0.01649007759988308,
            -0.017458707094192505,
            0.02136954478919506,
            0.019252197816967964,
            -0.02134096436202526,
            0.022731304168701172,
            0.029315447434782982,
            0.02969452179968357,
            -0.030574889853596687,
            0.00780945410951972,
            0.002976386109367013,
            0.019525814801454544,
            -0.012477893382310867,
            -0.016405383124947548,
            0.009909715503454208,
            -0.019627770408988,
            0.014359244145452976,
            -0.019792862236499786,
            -0.006216615438461304,
            0.024728894233703613,
            0.016115259379148483,
            -0.02468818984925747,
            0.0002127679472323507,
            0.019230522215366364,
            0.0007869904511608183,
            -0.02320987917482853,
            0.0038838644977658987,
            0.004671164788305759,
            -0.02092650532722473,
            -0.02799537405371666,
            -0.025791756808757782,
            -0.021649839356541634,
            0.004424432758241892,
            -0.019578462466597557,
            0.02824121154844761,
            0.021261395886540413,
            -0.024271270260214806,
            0.002364311832934618,
            0.021389931440353394,
            -0.0014191046357154846,
            -0.013383337296545506,
            0.01997152529656887,
            0.016291800886392593,
            0.017828259617090225,
            -0.007063991390168667,
            0.023236574605107307,
            -0.014611811377108097,
            0.01725355163216591,
            0.023371880874037743,
            0.016841374337673187,
            -0.025838283821940422,
            0.01655338704586029,
            -0.016699083149433136,
            0.011790580116212368,
            -0.0033144988119602203,
            0.0074219536036252975,
            -0.03001098893582821,
            -0.021155621856451035,
            -0.005971185397356749,
            0.01864045299589634,
            -0.02678915113210678,
            -0.01977868750691414,
            -0.02404005639255047,
            -0.007859249599277973,
            -0.007813384756445885,
            0.009335887618362904,
            0.019918974488973618,
            -0.002179680624976754,
            -0.019014954566955566,
            0.0032095524948090315,
            0.019168339669704437,
            -0.02740519493818283,
            0.027450870722532272,
            -0.03182651847600937,
            0.001486585009843111,
            -0.012584453448653221,
            0.015410722233355045,
            -0.00821870006620884,
            -0.030160440132021904,
            0.02345692738890648,
            -0.011994004249572754,
            -0.007989756762981415,
            0.022320378571748734,
            0.00034235790371894836,
            0.020022425800561905,
            0.007377026602625847,
            0.01935533806681633,
            -0.016313130035996437,
            0.011708538979291916,
            -0.02753283828496933,
            0.005379445385187864,
            0.016278918832540512,
            -0.000768571044318378,
            -0.032126281410455704,
            -0.00477788457646966,
            0.0030377234797924757,
            -0.0306266900151968,
            -0.011447868309915066,
            -0.03169852867722511,
            -0.02542937733232975,
            -0.011527051217854023,
            -0.00012695373152382672,
            -0.01989361271262169,
            -0.01954931952059269,
            -0.0017508305609226227,
            -0.022164911031723022,
            0.022262735292315483,
            -0.007325782440602779,
            0.02345677837729454,
            -0.000869540439452976,
            -0.006031854543834925,
            0.020793557167053223,
            -0.027727561071515083,
            -0.002372079761698842,
            -0.0297734085470438,
            -0.022896841168403625,
            0.020178653299808502,
            -0.0003259405493736267,
            -0.023202411830425262,
            0.0036337017081677914,
            -0.0019215281354263425,
            0.011528917588293552,
            0.0007982882671058178,
            0.0228137094527483,
            0.011836587451398373,
            -0.022899389266967773,
            0.011823486536741257,
            -0.030143465846776962,
            -0.023218797519803047,
            -0.0027181459590792656,
            0.00033153322874568403,
            0.0258485097438097,
            -0.029251016676425934,
            -0.022898536175489426,
            0.014342528767883778,
            0.014404872432351112,
            0.0123975845053792,
            -0.028947018086910248,
            -0.008303695358335972,
            -0.012546787038445473,
            0.018554838374257088,
            0.008316751569509506,
            -0.0012252421583980322,
            0.012869743630290031,
            0.023894282057881355,
            -0.013519566506147385,
            0.027722887694835663,
            -0.013911944814026356,
            0.02900533936917782,
            0.017427390441298485,
            0.002030495787039399,
            0.003107610857114196,
            0.004571573343127966,
            -0.028242891654372215,
            -0.0006221918156370521,
            -0.012406675145030022,
            -0.026569850742816925,
            0.02198130637407303,
            0.012763146311044693,
            -0.03042086958885193,
            -0.008039387874305248,
            -0.013355987146496773,
            0.022028064355254173,
            0.01898564212024212,
            0.01248383428901434,
            -0.029971200972795486,
            -0.025593237951397896,
            0.022198805585503578,
            0.018782785162329674,
            -0.003320856485515833,
            -0.030697153881192207,
            -0.030717987567186356,
            -0.014151151292026043,
            0.003375245025381446,
            0.01631554216146469,
            -0.01665121130645275,
            0.004214245826005936,
            -0.03210011497139931,
            -0.002563300309702754,
            0.021699177101254463,
            -0.028071731328964233,
            0.023057807236909866,
            0.0007660072878934443,
            0.026213418692350388,
            0.006356171332299709,
            0.010047167539596558,
            -0.02232852205634117,
            -0.023424841463565826,
            0.02309734746813774,
            0.0017021687235683203,
            0.018068930134177208,
            -0.02470366470515728,
            0.027650011703372,
            -0.030443133786320686,
            0.029494477435946465,
            -0.003921665716916323,
            -0.01190952304750681,
            0.0041802627965807915,
            -0.02908078208565712,
            -0.010785592719912529,
            0.017696868628263474,
            -0.026447197422385216,
            -0.02135825715959072,
            0.024629129096865654,
            0.01580745540559292,
            0.02335279807448387,
            -0.013953461311757565,
            -0.014591478742659092,
            0.019901013001799583,
            -0.006764750927686691,
            -0.004615509882569313,
            -0.024097606539726257,
            -0.02641584910452366,
            0.01869623363018036,
            0.0038092918694019318,
            0.023108098655939102,
            -0.01426100917160511,
            -0.03078244812786579,
            0.02272319421172142,
            0.01641097478568554,
            -0.007607886102050543,
            -0.01761024259030819,
            -0.014855913817882538,
            -0.017159288749098778,
            -0.021211538463830948,
            0.012514123693108559,
            0.0039484575390815735,
            0.004772387444972992,
            0.008740328252315521,
            -0.01143420860171318,
            -0.010837050154805183,
            0.011058181524276733,
            -0.005280866287648678,
            0.025930942967534065,
            -0.013416105881333351,
            0.0034530162811279297,
            0.024463117122650146,
            0.02157222293317318,
            0.013649828732013702,
            0.013547278940677643,
            0.011401071213185787,
            -0.007038258481770754,
            -0.0005145921022631228,
            -0.02293349802494049,
            0.010533807799220085,
            0.015037099830806255,
            -0.014533191919326782,
            0.0005495452787727118,
            -0.0036311973817646503,
            -0.023226415738463402,
            0.015405149199068546,
            0.00822710432112217,
            0.0002270894474349916,
            0.026499662548303604,
            -0.02754286490380764,
            -0.007598172873258591,
            -0.007763823959976435,
            -0.00790797732770443,
            -0.0031356008257716894,
            -0.02362784929573536,
            -0.0089943315833807,
            0.00610470911487937,
            -0.015156744047999382,
            0.01762513257563114,
            0.030248427763581276,
            0.0002684938081074506,
            0.014105552807450294,
            0.02759108692407608,
            -2.115964889526367e-06,
            -0.01548992469906807,
            0.03006443753838539,
            0.022140292450785637,
            0.004166772589087486,
            0.020164597779512405,
            -0.0253355223685503,
            0.027628928422927856,
            -0.019521765410900116,
            -0.00970740057528019,
            -0.0002802320523187518,
            -0.02950727753341198,
            -0.001380602945573628,
            -0.011475829407572746,
            0.02495015412569046,
            -0.003639564150944352,
            -0.008644836954772472,
            0.008328918367624283,
            0.022522710263729095,
            -0.00642264261841774,
            -0.02847287431359291,
            -0.020096009597182274,
            0.005203261971473694,
            0.020040860399603844,
            0.02453053556382656,
            0.001622319221496582,
            -0.009571547619998455,
            0.02865157090127468,
            -0.015697969123721123,
            -0.0232919380068779,
            0.0053042941726744175,
            -0.01646251231431961,
            -0.005769695155322552,
            0.024199021980166435,
            0.021093320101499557,
            -0.030782151967287064,
            -0.03164331242442131,
            0.02550068125128746,
            0.0162977185100317,
            0.004979203920811415,
            -0.006691879127174616,
            0.029807256534695625,
            0.005824967287480831,
            0.008210579864680767,
            -0.026299528777599335,
            -0.02057982236146927,
            0.012583975680172443,
            -0.0025918884202837944,
            0.008841441012918949,
            0.00679402519017458,
            -0.010363945737481117,
            -0.0077671026811003685,
            0.012952943332493305,
            -0.021811841055750847,
            0.018979540094733238,
            -0.007142756599932909,
            0.01716122403740883,
            0.011247501708567142,
            -0.006845705211162567,
            0.016338393092155457,
            -0.026914244517683983,
            0.004170496016740799,
            -0.029910873621702194,
            0.0044021522626280785,
            -0.02762731723487377,
            -0.023553449660539627,
            -0.001280148047953844,
            -0.019520750269293785,
            -0.019878964871168137,
            -0.01689271628856659,
            -0.009314768016338348,
            -0.012294287793338299,
            0.0018962591420859098,
            -0.008373322896659374,
            -0.006374973338097334,
            0.0010919567430391908,
            -0.013944742269814014,
            0.018604576587677002
        ],
        [
            -0.006169224623590708,
            0.010793172754347324,
            0.024160470813512802,
            -0.014661573804914951,
            0.019646096974611282,
            0.06911948323249817,
            0.0188370980322361,
            0.012711371295154095,
            0.017412792891263962,
            0.018066171556711197,
            -0.00846156571060419,
            0.014672295190393925,
            0.011522957123816013,
            -0.038998767733573914,
            -0.06947094202041626,
            0.013555503450334072,
            0.0007505840039812028,
            -0.017341112717986107,
            0.03487660735845566,
            0.024954862892627716,
            0.03443286940455437,
            -0.004474177956581116,
            0.021638531237840652,
            -0.00186178891453892,
            -0.04090125858783722,
            0.015038827434182167,
            0.012399882078170776,
            -0.021877437829971313,
            -0.001376079162582755,
            -0.0352628268301487,
            0.020137466490268707,
            -0.0029438785277307034,
            0.02167735993862152,
            0.032128993421792984,
            0.010106779634952545,
            -0.02061518095433712,
            0.03239763528108597,
            -0.01314267423003912,
            -0.04074421525001526,
            -0.027147114276885986,
            -0.022546270862221718,
            -0.0073627689853310585,
            0.016789302229881287,
            0.028835583478212357,
            0.005515608005225658,
            0.012941188178956509,
            -0.017665714025497437,
            -0.012593177147209644,
            0.007704875431954861,
            -0.03841746971011162,
            -0.012922468595206738,
            -0.03487906605005264,
            0.009732307866215706,
            0.0012552498374134302,
            -0.01858755759894848,
            0.008133269846439362,
            -0.022473661229014397,
            -0.03962385281920433,
            -0.01979900896549225,
            -0.017008546739816666,
            -0.009456121362745762,
            -0.032720357179641724,
            0.005407469812780619,
            0.006318195722997189,
            0.016942329704761505,
            0.028843585401773453,
            0.0036313622258603573,
            -0.01994164288043976,
            -0.020919403061270714,
            -0.024078525602817535,
            0.0014419888611882925,
            0.029257791116833687,
            0.023357413709163666,
            0.022631900385022163,
            0.0063833449967205524,
            0.020811432972550392,
            0.029469484463334084,
            0.0072051603347063065,
            0.01811620593070984,
            0.03204553946852684,
            -0.009558690711855888,
            0.014596104621887207,
            0.003347858553752303,
            0.0063935816287994385,
            0.022901734337210655,
            -0.009188628755509853,
            0.016482826322317123,
            0.016146467998623848,
            -0.003932387568056583,
            0.0015977241564542055,
            0.006309145595878363,
            -0.033619292080402374,
            0.0033581568859517574,
            -0.04539726302027702,
            0.030099429190158844,
            0.03561253100633621,
            -0.016717469319701195,
            -0.024723989889025688,
            0.018620211631059647,
            0.006415246520191431,
            -0.045813169330358505,
            -0.020380230620503426,
            -0.028138592839241028,
            -0.005617012735456228,
            -0.001667704083956778,
            -0.03556758537888527,
            0.02893022820353508,
            0.008095469325780869,
            -0.015452081337571144,
            -0.03059156984090805,
            -0.020035549998283386,
            0.028593363240361214,
            0.024922501295804977,
            0.018007485195994377,
            0.03588327020406723,
            -0.006111172493547201,
            -0.006242900621145964,
            0.00990829523652792,
            0.013113847002387047,
            -0.00817717146128416,
            0.00877328123897314,
            0.0012678453931584954,
            -0.016568413004279137,
            -0.04613891988992691,
            -0.000574557576328516,
            -0.02224579267203808,
            0.032573483884334564,
            0.02802707441151142,
            -0.011428351514041424,
            -0.015957459807395935,
            0.0014038821682333946,
            -0.005431740079075098,
            0.016273818910121918,
            0.021358367055654526,
            0.024658620357513428,
            0.021542394533753395,
            -0.00041560950921848416,
            0.010224124416708946,
            -0.005245985463261604,
            -0.02128254622220993,
            -0.006278248969465494,
            0.00010226191079709679,
            0.010234057903289795,
            0.0002233867853647098,
            0.00799283292144537,
            0.01719667948782444,
            -0.009228132665157318,
            -0.02508271113038063,
            -0.0019330988870933652,
            0.0036319303326308727,
            0.028236975893378258,
            -0.020946267992258072,
            -0.02381245233118534,
            0.03120497055351734,
            -0.04715896397829056,
            -0.012937583960592747,
            -0.02630867436528206,
            -0.018474817276000977,
            -0.0019690063782036304,
            0.009501690976321697,
            0.029155412688851357,
            -0.015805821865797043,
            0.032286159694194794,
            -0.0005229873349890113,
            0.016477353870868683,
            -0.013819586485624313,
            -0.02435440383851528,
            0.005019175820052624,
            -0.004002412315458059,
            0.007928162813186646,
            0.011191935278475285,
            0.021754814311861992,
            0.027695855125784874,
            0.005081166047602892,
            -0.00025670818286016583,
            0.009374630637466908,
            0.036378439515829086,
            0.03035312332212925,
            -0.03687753900885582,
            0.030414365231990814,
            -0.014510387554764748,
            0.02585911564528942,
            -0.015090144239366055,
            0.011697211302816868,
            0.002496457425877452,
            0.008870292454957962,
            -0.0045297518372535706,
            0.018398107960820198,
            0.010843838565051556,
            -0.031521525233983994,
            -0.011681773699820042,
            -0.003066563280299306,
            0.014099019579589367,
            0.014904283918440342,
            0.032144851982593536,
            -0.0016376327257603407,
            0.019603632390499115,
            0.025896303355693817,
            -0.015846284106373787,
            0.015212886966764927,
            -0.006794409826397896,
            0.010774585418403149,
            0.005196313839405775,
            0.029147684574127197,
            0.021555054932832718,
            -0.0029323319904506207,
            -0.034793514758348465,
            0.028743689879775047,
            0.008005088195204735,
            0.027172857895493507,
            -0.02360234037041664,
            0.020662855356931686,
            -0.02748761884868145,
            -0.03288116678595543,
            0.027186453342437744,
            -0.011451167985796928,
            -0.018324699252843857,
            -0.02164473943412304,
            0.028094561770558357,
            -0.015030152164399624,
            0.015042242594063282,
            -0.02832675352692604,
            -0.024194788187742233,
            -0.02175399288535118,
            -0.028186628594994545,
            -0.01637537218630314,
            0.002173377200961113,
            0.020270435139536858,
            -0.022378122434020042,
            0.024707261472940445,
            -0.018976131454110146,
            0.0020448355935513973,
            0.0017712783301249146,
            -0.018080422654747963,
            0.008609247393906116,
            0.028876768425107002,
            0.061858948320150375,
            0.0090272706001997,
            0.015243817120790482,
            0.01953357458114624,
            0.019396942108869553,
            0.0029396291356533766,
            -0.03797628730535507,
            -0.011979220435023308,
            0.0386677086353302,
            -0.0026110804174095392,
            0.01761307194828987,
            0.02981439046561718,
            0.023811006918549538,
            -0.013708876445889473,
            -0.00918160192668438,
            0.02321317233145237,
            0.009279252029955387,
            0.02517309971153736,
            0.02892281860113144,
            0.0005805789260193706,
            -0.005705296061933041,
            0.014021574519574642,
            -0.022966600954532623,
            -0.03991765156388283,
            -0.01924983039498329,
            -0.03030027449131012,
            -0.02599480003118515,
            0.001969036879017949,
            -0.02811552584171295,
            -0.0139643345028162,
            0.0018175150034949183,
            0.010200844146311283,
            -0.028966860845685005,
            -0.021040387451648712,
            -0.015347898006439209,
            -0.006338831968605518,
            0.0016677255043759942,
            -0.023441573604941368,
            -0.0030779431108385324,
            0.020599551498889923,
            0.014005647972226143,
            0.017313338816165924,
            0.04557114467024803,
            -0.018008282408118248,
            -0.030734067782759666,
            0.02337021194398403,
            0.004779730923473835,
            0.011784953065216541,
            -0.008014793507754803,
            0.013500100933015347,
            0.00183210673276335,
            -0.02030443586409092,
            0.014826762489974499,
            -0.02930939570069313,
            -0.01115057710558176,
            0.019002821296453476,
            -0.0035990499891340733,
            -0.011552917771041393,
            -0.03238878771662712,
            0.003778198966756463,
            -0.02329283580183983,
            -0.03024834394454956,
            0.018269434571266174,
            -0.008604535833001137,
            -0.01319326926022768,
            -0.03605196624994278,
            -0.0018350024474784732,
            -0.008115203119814396,
            -0.03166033327579498,
            0.005426868796348572,
            -0.006236531771719456,
            -0.017308834940195084,
            -0.04818395897746086,
            -0.0044752852991223335,
            -0.042331524193286896,
            -0.018954657018184662,
            -0.0253168735653162,
            -0.01853831484913826,
            0.00035663810558617115,
            0.008290349505841732,
            -0.022359151393175125,
            -0.017529433593153954,
            0.006975445430725813,
            0.01647353358566761,
            -0.012897650711238384,
            -0.005056384019553661,
            0.015036223456263542,
            0.01237048301845789,
            0.02478758804500103,
            0.004652775824069977,
            -0.030432578176259995,
            0.001215124037116766,
            -0.03644547984004021,
            0.005259859375655651,
            -0.026848407462239265,
            -0.020084017887711525,
            0.010839514434337616,
            0.023617921397089958,
            -0.0010183267295360565,
            -0.04313364997506142,
            -0.025434959679841995,
            -0.027690192684531212,
            0.023125728592276573,
            -0.028700243681669235,
            0.039262861013412476,
            0.03772902488708496,
            0.025359900668263435,
            0.01205322053283453,
            -0.02617417275905609,
            0.03102891519665718,
            -0.022782744839787483,
            -0.027118206024169922,
            0.028714338317513466,
            -0.019767310470342636,
            -0.006474859081208706,
            0.0033213370479643345,
            -0.011185609735548496,
            -0.010752676986157894,
            0.0156955998390913,
            -0.02217949368059635,
            0.00624186871573329,
            -0.039605267345905304,
            0.02023598924279213,
            -0.026951879262924194,
            -0.004691505338996649,
            -0.0004938166821375489,
            0.02060859650373459,
            0.02763371169567108,
            -0.004748987033963203,
            -0.030962703749537468,
            -0.00923562329262495,
            -0.03211554139852524,
            -0.02701757848262787,
            -0.023765383288264275,
            0.005611162167042494,
            -0.0272459015250206,
            0.041947007179260254,
            0.00803062878549099,
            0.009427476674318314,
            0.013846559450030327,
            0.01597965694963932,
            0.027145367115736008,
            -0.03284468874335289,
            -0.004383035469800234,
            0.029339361935853958,
            -0.017352547496557236,
            -0.009641886688768864,
            -0.016855530440807343,
            0.02348281443119049,
            -0.008030539378523827,
            0.021864231675863266,
            -0.027799362316727638,
            0.005610589869320393,
            0.013060815632343292,
            -0.0012382064014673233,
            0.007326839491724968,
            -0.007220133673399687,
            -0.013457873836159706,
            0.04491010680794716,
            -0.016040442511439323,
            0.014940175227820873,
            0.005403953604400158,
            -0.0258218701928854,
            0.017870966345071793,
            0.00989092793315649,
            -0.020880945026874542,
            -0.01791044883430004,
            0.0281683336943388,
            0.007138231303542852,
            0.016586298123002052,
            -0.005199302453547716,
            -0.018979515880346298,
            0.02370322495698929,
            -0.02094147354364395,
            -0.033765535801649094,
            0.007903633639216423,
            0.02524096518754959,
            0.01044397335499525,
            -0.02088768221437931,
            0.028184257447719574,
            -0.00596857164055109,
            0.026203349232673645,
            0.026353130117058754,
            0.004378002602607012,
            -0.029008913785219193,
            0.012870611622929573,
            0.019710753113031387,
            -0.02246634103357792,
            0.0014655404957011342,
            0.004570873454213142,
            0.0008382375235669315,
            0.007203985936939716,
            -0.01715615950524807,
            -0.0021891440264880657,
            -0.0029533880297094584,
            0.01963968761265278,
            0.007776748389005661,
            0.013541002757847309,
            0.026022648438811302,
            -0.022225577384233475,
            0.01320176012814045,
            -0.02590271458029747,
            0.02102138288319111,
            -0.04563151299953461,
            0.010425381362438202,
            -0.004623204469680786,
            -0.00622221315279603,
            0.01066022552549839,
            -0.03726532310247421,
            -0.017805948853492737,
            0.000477537396363914,
            -0.0027970026712864637,
            0.01719485968351364,
            0.04426264762878418,
            -0.03994761407375336,
            -0.01961766928434372,
            -6.032848250470124e-05,
            0.017771314829587936,
            0.006485589314252138,
            0.004726107697933912,
            0.011520683765411377,
            0.03120395541191101,
            0.0037937993183732033,
            0.030618108808994293,
            0.04518147557973862,
            0.005675584077835083,
            0.027144111692905426,
            -0.039792146533727646,
            -0.002619759179651737,
            -0.026080286130309105,
            -0.029012493789196014,
            0.007219741586595774,
            0.01637628674507141,
            0.030158767476677895,
            0.032509107142686844,
            0.004631150513887405,
            -0.038251087069511414,
            -0.024046599864959717,
            0.00997085403650999,
            0.007036581635475159,
            -0.03061191365122795,
            -0.019003013148903847,
            -0.04481031000614166,
            0.005314787849783897,
            0.020031869411468506,
            0.010483315214514732,
            0.011769435368478298,
            -0.0043899985030293465,
            -0.04598475620150566,
            0.03475191444158554,
            -0.027769949287176132,
            0.02926608920097351,
            -0.07988474518060684,
            0.005661789793521166,
            -0.021865565329790115,
            0.014190968126058578,
            0.020838990807533264,
            -0.0028358004055917263,
            -0.03375057131052017,
            -0.020245147868990898,
            0.0008561006980016828,
            -0.03594948723912239,
            0.025619469583034515,
            -0.01435272116214037,
            0.021164964884519577,
            -0.011162922717630863,
            0.00514058256521821,
            0.007405926939100027,
            -0.006391604896634817,
            -0.017849043011665344,
            -0.020349055528640747,
            0.0034245650749653578,
            0.024163365364074707,
            0.02522962912917137,
            -0.016735780984163284,
            0.014261726289987564,
            -0.03242798149585724,
            -0.003148025367408991,
            -0.0074990796856582165,
            -0.012671318836510181,
            -0.03377574309706688,
            -0.01748715341091156,
            0.008112343028187752,
            -0.013023563660681248,
            0.009240123443305492,
            0.016658907756209373,
            -0.014268845319747925,
            0.03422430902719498,
            0.024699948728084564,
            0.01257337350398302,
            0.012784860096871853,
            0.0018578477902337909,
            0.01810753531754017,
            -0.022944176569581032,
            0.02043457143008709,
            -0.02203482948243618,
            -0.0004928808775730431,
            0.01964072696864605,
            0.008781183511018753,
            -0.054758522659540176,
            -0.014703472144901752,
            0.025018198415637016,
            0.012538955546915531,
            -0.0035175392404198647,
            0.012435230426490307,
            0.029925905168056488,
            0.02225087769329548,
            0.006367232650518417,
            -0.02452806383371353,
            -0.005743060261011124,
            -0.002079437021166086,
            -0.04206889867782593,
            0.027577858418226242,
            0.011948815546929836,
            0.026621732860803604,
            -0.023602476343512535,
            0.010499623604118824,
            0.006371042691171169,
            -0.002657386241480708,
            0.0025778301060199738,
            0.014582988806068897,
            -0.006042739376425743,
            -0.015198955312371254,
            0.02880972810089588,
            0.0007964020478539169,
            -0.005969483871012926,
            -0.008965857326984406,
            0.036980148404836655,
            0.02095711976289749,
            0.010376830585300922,
            0.020870540291070938,
            0.00149562768638134,
            -0.017280058935284615,
            0.018108436837792397,
            -0.006518717855215073,
            -0.013262072578072548,
            0.024565204977989197,
            0.018403366208076477,
            -0.010741100646555424,
            -0.04332420974969864,
            -0.004741133190691471,
            0.012310079298913479,
            -0.013323051854968071,
            -0.0009553690324537456,
            0.021398967131972313,
            0.02541455626487732,
            0.02203303948044777,
            0.021950680762529373,
            0.030060840770602226,
            -0.015140893869102001,
            0.001463351072743535,
            0.009958386421203613,
            -0.012931691482663155,
            -0.040053851902484894,
            0.017505859956145287,
            -0.00568035151809454,
            0.0012944090412929654,
            -0.006389448884874582,
            0.015078759752213955,
            -0.012583098374307156,
            0.005487948656082153,
            -0.03820257633924484,
            0.024478508159518242,
            0.004892731551080942,
            0.05432869493961334,
            0.013044729828834534,
            -0.007449381984770298,
            0.022074999287724495,
            0.03232697397470474,
            0.0035221301950514317,
            -0.03065543808043003,
            0.003907221835106611,
            0.009752337820827961,
            0.026057252660393715,
            0.0022493256255984306,
            0.001887633465230465,
            -0.003052007406949997,
            -0.0348755419254303,
            0.008680895902216434,
            0.041774552315473557,
            -0.01768217794597149,
            0.00862274318933487,
            0.0207257941365242,
            0.011937491595745087,
            -0.015284150838851929,
            -0.0009928734507411718,
            0.029017291963100433,
            -0.028523888438940048,
            -0.017871420830488205,
            -0.00995694100856781,
            -0.002468415768817067,
            -0.026754306629300117,
            0.01870344579219818,
            0.014725703746080399,
            -0.03151834011077881,
            -0.017750423401594162,
            0.026332927867770195,
            0.019915519282221794,
            -0.04305672273039818,
            0.003950006794184446,
            -0.05591024085879326,
            0.013504867441952229,
            0.03418206796050072,
            0.0017517817905172706,
            -0.012467538937926292,
            0.040278274565935135,
            -0.03949538990855217,
            0.040952350944280624,
            -0.010394154116511345,
            -0.021088572219014168,
            -0.006129899062216282,
            0.03174402937293053,
            -0.0017338352045044303,
            -0.020588485524058342,
            0.023202059790492058,
            0.022231021896004677,
            -0.006056573707610369,
            0.007547251880168915,
            0.0430111363530159,
            0.016596484929323196,
            0.025917721912264824,
            0.01649853214621544,
            0.003216624027118087,
            0.012315630912780762,
            -0.030158212408423424,
            -0.02094246633350849,
            -0.02374805510044098,
            -0.029387719929218292,
            -0.017534451559185982,
            0.015537108294665813,
            0.017437664791941643,
            -0.007562721613794565,
            -0.028258850798010826,
            0.011571692302823067,
            -0.02600584179162979,
            -0.032270822674036026,
            0.018222371116280556,
            -0.011886704713106155,
            0.03304850310087204,
            -0.03101358190178871,
            -0.009363921359181404,
            0.009841766208410263,
            -0.001010355306789279,
            0.023534070700407028,
            0.055721476674079895,
            0.003942287527024746,
            -0.018494078889489174,
            0.005125044845044613,
            0.04277357459068298,
            0.03543774411082268,
            0.025216318666934967,
            -0.014121297746896744,
            -0.021626537665724754,
            -0.020090557634830475,
            0.01506111305207014,
            -0.03609292581677437,
            -0.019094213843345642,
            -0.01255717407912016,
            0.022300822660326958,
            0.016817836090922356,
            0.0312560573220253,
            0.03226202726364136,
            -0.004130495712161064,
            0.008333833888173103,
            -0.003811425995081663,
            -0.002603825880214572,
            -0.00875100214034319,
            -0.01737634651362896,
            0.02046331949532032,
            -0.015668202191591263,
            0.02154962159693241,
            0.03342433646321297,
            0.020527906715869904,
            -0.0067674946039915085,
            -0.006600416265428066,
            0.008804704993963242,
            -0.0374123677611351,
            -0.02424280345439911,
            -0.019370675086975098,
            0.010367359034717083,
            0.00498500932008028,
            0.019311625510454178,
            0.008808563463389874,
            0.026613527908921242,
            0.022709177806973457,
            0.028536541387438774,
            -0.015527918003499508,
            0.07057294994592667,
            0.012750944122672081,
            0.02574319764971733,
            0.03114285133779049,
            -0.005094517488032579,
            0.008073711767792702,
            -0.013292955234646797,
            -0.008295309729874134,
            -0.008141234517097473,
            0.021362872794270515,
            -0.0007411826518364251,
            0.01842251606285572,
            -0.014709343202412128,
            0.02283843606710434,
            -0.000447690807050094,
            -0.01760660670697689,
            0.027055423706769943,
            -0.002810060977935791,
            -0.008943242020905018,
            0.02897133119404316,
            0.03641168400645256,
            0.020158713683485985,
            -0.036888498812913895,
            -0.06402057409286499,
            0.031065182760357857,
            -0.008573571220040321,
            0.0067743598483502865,
            -0.008171605877578259,
            -0.018898645415902138,
            -0.0067226882092654705,
            0.043845631182193756,
            0.0056054661981761456,
            0.007710520643740892,
            0.018742656335234642,
            0.02643248625099659,
            -0.04517694562673569,
            0.03925875574350357,
            0.02142981067299843,
            0.036267541348934174,
            -0.030191944912075996,
            -0.006815291941165924,
            0.005645654629915953,
            -0.026120172813534737,
            -0.026939015835523605,
            -0.003709296463057399,
            0.031034696847200394,
            0.03159172832965851,
            -0.007154851220548153,
            0.03776376694440842,
            0.012325900606811047,
            0.023795858025550842,
            0.022871218621730804,
            -0.020725861191749573,
            -0.008891523815691471,
            -0.026052620261907578,
            -0.01989944651722908,
            0.027844391763210297,
            0.026558222249150276,
            -0.026950689032673836,
            -0.0250118188560009,
            -0.019752757623791695,
            -0.009798231534659863,
            0.02942683920264244,
            -0.03053966350853443,
            0.005418449640274048,
            -0.006394559051841497,
            0.015385350212454796,
            -0.0017463312251493335,
            0.01475652027875185,
            -0.046823665499687195,
            0.00708495918661356,
            -0.022532958537340164,
            -0.0037937841843813658,
            -0.0064302957616746426,
            0.020661495625972748,
            -0.004766709636896849,
            -0.019162042066454887,
            -0.008939004503190517,
            -0.009337141178548336,
            -0.008592304773628712,
            0.038072049617767334,
            -0.019367313012480736,
            0.010232104919850826,
            -0.021094337105751038,
            -0.03407490998506546,
            0.023126091808080673,
            -0.008829833008348942,
            0.0003471403615549207,
            0.01152818650007248,
            -0.0013503655791282654,
            -0.015772927552461624,
            0.010385243222117424,
            0.020456718280911446,
            -0.02714194916188717,
            -0.03708837553858757,
            0.010949375107884407,
            -0.04955234378576279,
            0.02622922696173191,
            0.029278207570314407,
            -0.002061530016362667,
            0.014862445183098316,
            -0.053648993372917175,
            0.011414646171033382,
            -0.01203044131398201,
            0.03576141968369484,
            0.03194248303771019,
            0.007365848869085312,
            -0.0288948193192482,
            0.021049106493592262,
            0.04040107876062393,
            -0.0012890924699604511,
            -0.021843837574124336,
            -0.0019412686815485358,
            -0.02940991334617138,
            0.04698575288057327,
            0.018129879608750343,
            -0.019489159807562828,
            -0.010528026148676872,
            0.03214280679821968,
            0.02850930206477642,
            -0.00869207363575697,
            -0.027793284505605698,
            0.003405187977477908,
            -0.009053632616996765,
            0.016772855073213577,
            -0.03947494551539421,
            0.03327510878443718,
            0.015644092112779617,
            0.0062286509200930595,
            -0.003331538522616029,
            0.008880545385181904,
            -0.03048069030046463,
            -0.007390748243778944,
            0.020562749356031418,
            -0.03375336900353432,
            0.021265627816319466,
            -0.014728016220033169,
            0.012335388921201229,
            0.016110803931951523,
            -0.0194984283298254,
            -0.0012605552328750491,
            -0.022774701938033104,
            -0.024812001734972,
            0.01669188216328621,
            -0.02854282036423683,
            0.037680573761463165,
            -0.027796225622296333,
            -0.026907017454504967,
            0.023288575932383537,
            0.02346428669989109,
            0.012614772655069828,
            0.021598372608423233,
            -0.017830662429332733,
            -0.009721428155899048,
            0.05834463983774185,
            0.020170070230960846,
            -0.021294645965099335,
            -0.018233176320791245,
            0.02976507507264614,
            0.006088773719966412,
            0.017732948064804077,
            0.012545179575681686,
            0.029957739636301994,
            -0.005655650980770588,
            0.028613438829779625,
            -0.013229608535766602,
            0.028982732445001602,
            -0.007427510805428028,
            -0.01187620684504509,
            -0.00866927020251751,
            -0.038243912160396576,
            0.03308325633406639,
            0.030427366495132446,
            -0.01514645479619503,
            0.010484267957508564,
            0.002155151218175888,
            0.012171350419521332,
            -0.03872068598866463,
            0.03429032862186432,
            -0.021035486832261086,
            0.0459166094660759,
            -0.0004384970525279641,
            -0.02388807199895382,
            0.0003593509900383651,
            -0.03457774221897125,
            0.013373013585805893,
            -0.02792428806424141,
            0.04890494793653488,
            0.002508757868781686,
            -0.009391299448907375,
            0.020767981186509132,
            -0.07150197774171829,
            -0.008499590680003166,
            0.012850317172706127,
            -0.0025278045795857906,
            0.02314523607492447,
            0.012506615370512009,
            0.03016452118754387,
            -0.043677717447280884,
            0.016214461997151375,
            0.011057531461119652,
            0.0502331368625164,
            0.02984653413295746,
            -0.02486838959157467,
            -0.0034422343596816063,
            -0.00479085510596633,
            -0.013958063907921314,
            0.023906160145998,
            -0.0018638010369613767,
            0.004196908790618181,
            -0.03215225040912628,
            0.03122856840491295,
            0.016342060640454292,
            -0.02257736772298813,
            0.04055808112025261,
            0.004672463517636061,
            -0.005568881519138813,
            -0.014924723654985428,
            0.03148464486002922,
            -0.03428451716899872,
            -0.033782754093408585,
            -0.012574479915201664,
            0.0162945706397295,
            0.027208207175135612,
            0.03482889384031296,
            -0.0005646845675073564,
            0.014316395856440067,
            0.017915787175297737,
            0.0035354429855942726,
            -0.0024749422445893288,
            0.021837417036294937,
            0.03598548471927643,
            -0.01545256469398737,
            -0.023845206946134567,
            -0.027514683082699776,
            0.03379799425601959,
            -0.01278039999306202,
            0.008081000298261642,
            -0.0002777693443931639,
            0.03786617890000343,
            0.031842589378356934,
            0.02097141183912754,
            0.008588512428104877,
            0.021154340356588364,
            -0.00964637566357851,
            0.023679735139012337,
            -0.031583864241838455,
            0.007000729441642761,
            -0.020063603296875954,
            0.03165251016616821,
            -0.009833882562816143,
            0.039583347737789154,
            0.026348212733864784,
            0.017190415412187576,
            0.015640035271644592,
            0.030840788036584854,
            -0.009706288576126099,
            0.011322809383273125,
            -0.01014976017177105,
            0.026249192655086517,
            0.022105740383267403,
            -0.013033892959356308,
            -0.0014261271571740508,
            0.029422447085380554,
            0.001304083620198071,
            0.003438148880377412,
            0.004590766038745642,
            0.022001877427101135,
            -0.008964499458670616,
            -0.0017496926011517644,
            0.012221774086356163,
            0.01741887629032135,
            0.006636537611484528,
            -0.0030577077995985746,
            -0.01101359911262989,
            -0.0020429850555956364,
            -0.01729194074869156,
            -0.003989231772720814,
            0.034337613731622696,
            -0.0050670076161623,
            0.008986255154013634,
            -0.0009481846354901791,
            -0.029000045731663704,
            0.029689962044358253,
            0.002583542838692665,
            0.007683788426220417,
            -0.031442031264305115,
            -0.01918593980371952,
            -0.009635976515710354,
            -0.012879644520580769,
            -0.029717737808823586,
            0.0030573902186006308,
            -0.055509671568870544,
            -0.015357146970927715,
            -0.022559501230716705,
            0.033301953226327896,
            0.025093896314501762,
            0.035584598779678345,
            -0.03306860104203224,
            -0.003624360542744398,
            0.00900175143033266
        ],
        [
            -0.018188051879405975,
            0.006038852967321873,
            0.0013970033032819629,
            -0.005096652079373598,
            -0.029652809724211693,
            -0.040389593690633774,
            0.029861483722925186,
            0.017682263627648354,
            0.01666761189699173,
            -0.00890978891402483,
            -0.014266038313508034,
            0.028803616762161255,
            -0.032421451061964035,
            -0.012088421732187271,
            0.00019450747640803456,
            -0.0026731875259429216,
            0.017328182235360146,
            0.011678323149681091,
            0.01815192587673664,
            0.019547298550605774,
            0.0005066630546934903,
            -0.02848697267472744,
            0.021161505952477455,
            -0.03899554908275604,
            0.02992270700633526,
            -0.024061689153313637,
            0.025181233882904053,
            -0.017308948561549187,
            -0.0036930222995579243,
            -0.02128395065665245,
            -0.012668833136558533,
            -0.023803409188985825,
            0.023568354547023773,
            0.0280842836946249,
            -0.03822492063045502,
            -0.019297698512673378,
            -0.027756251394748688,
            0.008147388696670532,
            -0.010072744451463223,
            0.030563415959477425,
            -0.03028360940515995,
            -0.033816926181316376,
            0.02334459125995636,
            -0.0018287019338458776,
            0.01719621568918228,
            -0.005600780248641968,
            0.02655862644314766,
            0.03150035813450813,
            0.007233963347971439,
            -0.025547126308083534,
            -0.015834735706448555,
            -0.010373751632869244,
            0.011568019166588783,
            0.010913646779954433,
            -0.0029972409829497337,
            0.006417511962354183,
            -0.02400113269686699,
            -0.009106151759624481,
            0.014825825579464436,
            0.014062250033020973,
            -0.01069793663918972,
            -0.013291815295815468,
            0.01348033081740141,
            0.021755555644631386,
            -0.002395142335444689,
            -0.005421309266239405,
            -0.009496795944869518,
            0.0042226118966937065,
            -0.005203040316700935,
            0.02486474998295307,
            0.022860120981931686,
            -0.022541511803865433,
            0.002649292815476656,
            -0.02899685502052307,
            0.00291164917871356,
            -0.04223130643367767,
            0.021731695160269737,
            0.023368390277028084,
            -0.0018979242304340005,
            0.006309114396572113,
            -0.013171158730983734,
            -0.02422005869448185,
            0.010674703866243362,
            -0.011468300595879555,
            -0.031782425940036774,
            0.041121434420347214,
            -0.019506821408867836,
            -0.03164558857679367,
            -0.007805434055626392,
            -0.023774854838848114,
            -0.024715980514883995,
            0.0014566573081538081,
            0.012115065939724445,
            -0.03450692817568779,
            -0.011809117160737514,
            -0.03506718948483467,
            -0.025130942463874817,
            -0.02066938206553459,
            -0.03350390866398811,
            -0.018759671598672867,
            -0.03270911052823067,
            0.00644442904740572,
            0.004993285518139601,
            0.0009393201908096671,
            0.01703963428735733,
            -0.020612606778740883,
            0.009691816754639149,
            -0.010419552214443684,
            0.029923025518655777,
            0.01811903901398182,
            -0.01741860620677471,
            0.021158870309591293,
            -0.0021741348318755627,
            -0.006770554929971695,
            -0.024826310575008392,
            0.02617877908051014,
            -0.014482880011200905,
            0.010820997878909111,
            0.03169003501534462,
            -0.02316005900502205,
            0.010129784233868122,
            0.016729572787880898,
            0.08219188451766968,
            0.02265465073287487,
            0.018710844218730927,
            0.02170558087527752,
            -0.016944359987974167,
            -0.010523775592446327,
            0.012696529738605022,
            -0.008192850276827812,
            -0.024119319394230843,
            -0.012772961519658566,
            0.05477520078420639,
            0.001044651260599494,
            0.01849665306508541,
            -0.022756921127438545,
            -0.039558760821819305,
            -0.05537547543644905,
            0.020098458975553513,
            -0.0013413450215011835,
            0.011381808668375015,
            -0.018160885199904442,
            0.0031236130744218826,
            -0.01513736043125391,
            -0.0003100399626418948,
            0.005036711227148771,
            -0.0011348435655236244,
            0.009634735994040966,
            -0.007339716888964176,
            -0.0342746302485466,
            0.012600306421518326,
            -0.03704332932829857,
            -0.020881764590740204,
            -0.011480063199996948,
            -0.013932470232248306,
            0.01770051382482052,
            -0.026895632967352867,
            0.013354266993701458,
            -0.005539354402571917,
            0.03378697857260704,
            0.027043689042329788,
            0.0218668170273304,
            0.02060088887810707,
            0.004546406678855419,
            -0.01637393794953823,
            0.02728215418756008,
            -0.002732018008828163,
            -0.027946794405579567,
            -0.014188612811267376,
            0.014196735806763172,
            0.020849009975790977,
            0.02676190435886383,
            -0.03379165008664131,
            0.006072873715311289,
            0.019581912085413933,
            0.017740534618496895,
            -0.02580294944345951,
            -0.020889323204755783,
            -0.020399348810315132,
            -0.002910685958340764,
            -0.0010094866156578064,
            -0.010428636334836483,
            0.01057802326977253,
            0.003200322389602661,
            0.033351242542266846,
            0.03187216818332672,
            -0.010128865949809551,
            0.00605658907443285,
            0.020706841722130775,
            0.0054568760097026825,
            -0.014819812960922718,
            -0.0323205403983593,
            -0.01728653535246849,
            0.007888092659413815,
            -0.011096004396677017,
            -0.027110854163765907,
            0.016064152121543884,
            0.0032454889733344316,
            0.010503187775611877,
            -0.0004585000278893858,
            0.010427477769553661,
            0.0024709184654057026,
            -0.02707996405661106,
            0.006235186010599136,
            0.03615209087729454,
            -0.014002497307956219,
            0.012742023915052414,
            -0.01927303895354271,
            -0.024017196148633957,
            -0.019632728770375252,
            -0.013063596561551094,
            0.018089471384882927,
            0.01870439201593399,
            0.02952541783452034,
            0.005496548488736153,
            0.015316281467676163,
            0.04576043412089348,
            0.0024108225479722023,
            0.0010916671017184854,
            0.003057949710637331,
            0.012299125082790852,
            0.014526238664984703,
            -0.027015183120965958,
            -0.0021913216914981604,
            0.0074099767953157425,
            0.005929321050643921,
            -0.012486472725868225,
            -0.002755243331193924,
            0.0014188634231686592,
            0.005072177853435278,
            0.036036983132362366,
            -0.027010714635252953,
            -0.00431364681571722,
            -0.008511199615895748,
            0.023821977898478508,
            -0.01863948069512844,
            -0.004491312894970179,
            -0.003277734387665987,
            0.004897315055131912,
            0.04117405414581299,
            -0.0026397386100143194,
            0.015401742421090603,
            -0.00527943717315793,
            0.004213966894894838,
            -0.017999110743403435,
            0.038106583058834076,
            0.015501528047025204,
            -0.032920509576797485,
            -0.010689319111406803,
            0.019671456888318062,
            -0.00242556887678802,
            -0.034828368574380875,
            0.005155689548701048,
            0.028044508770108223,
            0.02590014785528183,
            0.016467753797769547,
            -0.022128425538539886,
            0.03100523166358471,
            0.028237832710146904,
            -0.0046875691041350365,
            0.01295772660523653,
            0.013217614963650703,
            0.0007764716283418238,
            0.004437634255737066,
            -0.010220929980278015,
            0.03795838728547096,
            0.024918124079704285,
            0.005405026022344828,
            -0.008334496058523655,
            -0.016830645501613617,
            0.017244728282094002,
            -0.012201900593936443,
            -0.017914140596985817,
            0.00399932824075222,
            0.027947884052991867,
            -0.02065153978765011,
            -0.027961280196905136,
            0.026947837322950363,
            -0.03818465769290924,
            0.026538992300629616,
            0.032928451895713806,
            0.019799184054136276,
            0.02501179836690426,
            0.0123084532096982,
            -0.025362404063344002,
            -0.00444898521527648,
            -0.01653265953063965,
            -0.001104525406844914,
            0.03387404978275299,
            0.028284680098295212,
            0.009767625480890274,
            0.015884801745414734,
            -0.02959405817091465,
            -0.01827179081737995,
            -0.02563193067908287,
            -0.03531055524945259,
            0.010988147929310799,
            0.03253623843193054,
            -0.028200725093483925,
            -0.006348094437271357,
            0.006046711467206478,
            0.012952576391398907,
            0.02887783758342266,
            -0.006199684925377369,
            0.020219381898641586,
            -0.008945171721279621,
            0.03667108342051506,
            0.008497080765664577,
            -0.024379584938287735,
            -0.02073519118130207,
            0.017742272466421127,
            0.02004161849617958,
            -0.00442165182903409,
            0.02001902274787426,
            0.022775396704673767,
            0.015215450897812843,
            0.006088935770094395,
            -0.023128222674131393,
            0.02097165212035179,
            0.0034200961235910654,
            0.03214111179113388,
            0.042897116392850876,
            0.017646849155426025,
            0.013917132280766964,
            0.025191044434905052,
            0.007344926241785288,
            -0.02405216172337532,
            0.032929807901382446,
            0.0003555065777618438,
            0.0006290835444815457,
            0.013454706408083439,
            -0.0017340123886242509,
            -0.0028178677894175053,
            -0.018706155940890312,
            -0.0070143165066838264,
            -0.01651194877922535,
            0.01518376637250185,
            -0.011710966937243938,
            -0.02389761060476303,
            0.007453298196196556,
            0.012108782306313515,
            0.03661676496267319,
            0.017061250284314156,
            -0.009924344718456268,
            -0.01804507151246071,
            0.020389940589666367,
            -0.02253517508506775,
            0.014554571360349655,
            0.01602032780647278,
            0.017580704763531685,
            0.002516713924705982,
            0.0163357425481081,
            -0.016078948974609375,
            -0.005714772269129753,
            -0.03918224200606346,
            0.0354267843067646,
            0.009206745773553848,
            0.02768685854971409,
            -0.014755748212337494,
            0.005616726819425821,
            -0.0227725338190794,
            -0.0022843729238957167,
            0.03133213520050049,
            -0.004899228923022747,
            -0.007652694825083017,
            -0.024447454139590263,
            0.0026308053638786077,
            -0.027996033430099487,
            -0.014921329915523529,
            -0.002853174926713109,
            -0.031227542087435722,
            0.0016923093935474753,
            -0.024789420887827873,
            -0.02026992291212082,
            0.02413572184741497,
            -0.004762047901749611,
            -0.012566669844090939,
            -0.006131161004304886,
            -0.03134412318468094,
            -0.00043574898154474795,
            0.00808834657073021,
            0.010501901619136333,
            -0.005225070286542177,
            0.028086671605706215,
            0.02318432368338108,
            0.0006293035694397986,
            0.02120099775493145,
            0.011097279377281666,
            0.003645063377916813,
            -0.020042696967720985,
            -0.025377584621310234,
            -0.03542070463299751,
            -0.005293268710374832,
            -0.00603105966001749,
            -0.028918307274580002,
            0.010290246456861496,
            0.023162981495261192,
            -0.017252851277589798,
            0.019863221794366837,
            0.0009921203600242734,
            0.03430969640612602,
            -0.0011874109040945768,
            -0.026211008429527283,
            -0.02720554731786251,
            -0.00016328204947058111,
            -0.0217682383954525,
            -0.02002582885324955,
            -0.0015361882979050279,
            0.004313699901103973,
            0.006215006113052368,
            -0.024871693924069405,
            -0.023772364482283592,
            -0.0054575433023273945,
            -0.019631564617156982,
            0.025274958461523056,
            0.02397969365119934,
            -0.03108162060379982,
            -0.0263757836073637,
            0.009101479314267635,
            0.006603999994695187,
            -0.036944564431905746,
            -0.006729278713464737,
            -0.027507448568940163,
            0.02237565629184246,
            0.04082777351140976,
            0.011577523313462734,
            0.019412560388445854,
            -0.016762619838118553,
            0.018809199333190918,
            -0.03000713139772415,
            -0.03414865955710411,
            -0.008508346043527126,
            -0.0148019939661026,
            -0.009286529384553432,
            -0.028363214805722237,
            0.012853773310780525,
            0.03252682089805603,
            -0.026502376422286034,
            -0.01590619795024395,
            0.005001953337341547,
            0.036522429436445236,
            -0.004954422824084759,
            -0.01955600269138813,
            0.043056827038526535,
            0.030482688918709755,
            0.024183109402656555,
            0.02368989959359169,
            -0.01829143799841404,
            -0.03300022706389427,
            -0.023647449910640717,
            -0.006744313053786755,
            -0.009509416297078133,
            0.01153766829520464,
            -0.0074448189698159695,
            0.015192262828350067,
            0.006927489768713713,
            -0.04521486535668373,
            0.02825109474360943,
            -0.0225687213242054,
            0.030661605298519135,
            -0.013805673457682133,
            0.016724126413464546,
            -0.01346736028790474,
            -0.001907633151859045,
            0.014241006225347519,
            0.02567700296640396,
            0.004377344623208046,
            -0.007775597274303436,
            -0.006588692311197519,
            -0.014316429384052753,
            0.011446439661085606,
            -0.0027129515074193478,
            -0.005050185136497021,
            0.006303801201283932,
            0.00029182736761868,
            0.008401122875511646,
            -0.017797168344259262,
            0.01332370936870575,
            0.029157286509871483,
            0.007564072031527758,
            0.033690378069877625,
            0.011790452525019646,
            0.06570267677307129,
            0.02228517457842827,
            0.034424878656864166,
            -0.003822921309620142,
            -0.028909197077155113,
            0.025670737028121948,
            0.0384204238653183,
            -0.021984264254570007,
            -0.007311482448130846,
            0.02835835888981819,
            0.023189639672636986,
            -0.043604109436273575,
            -0.013027017936110497,
            0.020998377352952957,
            -0.02728712372481823,
            -0.006868819240480661,
            -0.026095513254404068,
            0.011380013078451157,
            -0.023715147748589516,
            0.012458178214728832,
            0.020056677982211113,
            -0.002436878392472863,
            -0.020925866439938545,
            -0.022346384823322296,
            0.023072563111782074,
            -0.02561154216527939,
            0.02473129704594612,
            -0.0039786165580153465,
            0.02926214225590229,
            0.013714030385017395,
            -0.0016362285241484642,
            -0.035376355051994324,
            -0.012432405725121498,
            -0.012257719412446022,
            0.02144545316696167,
            0.014335923828184605,
            0.0066450308077037334,
            -0.004544971976429224,
            0.02133466675877571,
            0.0025305207818746567,
            0.014408525079488754,
            -0.018466677516698837,
            -0.024101734161376953,
            0.02698885276913643,
            -0.04540159925818443,
            -0.0043303463608026505,
            -0.0017844651592895389,
            -0.019302792847156525,
            0.027184823527932167,
            -0.016670454293489456,
            -0.009822837077081203,
            0.012806338258087635,
            0.030269473791122437,
            -0.0029673792887479067,
            -0.004272318910807371,
            0.028911160305142403,
            0.017728837206959724,
            -0.01956019178032875,
            0.014331812970340252,
            0.01966547593474388,
            -0.026927396655082703,
            0.02271113358438015,
            0.03197502717375755,
            -0.03049725480377674,
            -0.020178886130452156,
            0.016536014154553413,
            -0.03131461888551712,
            0.022567013278603554,
            -0.0070958323776721954,
            0.02196422405540943,
            0.014845874160528183,
            -0.011348667554557323,
            0.016446050256490707,
            0.03297729045152664,
            0.024933306500315666,
            -0.024756312370300293,
            0.02974495105445385,
            0.0032374975271523,
            -0.024224944412708282,
            -0.014062272384762764,
            0.009186631068587303,
            -0.03854996711015701,
            0.008736597374081612,
            0.023687681183218956,
            0.02484128251671791,
            -0.014385444112122059,
            -0.001718216692097485,
            0.01416109874844551,
            0.01387742254883051,
            -0.014081157743930817,
            -0.03382635861635208,
            0.0029399257618933916,
            0.0033651634585112333,
            -0.0328013077378273,
            -0.009804279543459415,
            0.0009834064403548837,
            0.03537115082144737,
            0.03382543846964836,
            0.009434027597308159,
            -0.025765765458345413,
            0.009170026518404484,
            -0.029078228399157524,
            -0.030697306618094444,
            -0.009531683288514614,
            0.03770033270120621,
            0.0365976020693779,
            0.0015577462036162615,
            -0.01665087416768074,
            -0.02500188909471035,
            0.0038486369885504246,
            -0.01686362735927105,
            -0.01055185031145811,
            0.012017348781228065,
            0.011114580556750298,
            -0.027166923508048058,
            0.027997823432087898,
            0.014410129748284817,
            0.019189104437828064,
            0.0013023137580603361,
            -0.011424744501709938,
            -0.02567913383245468,
            -0.003048493294045329,
            0.01888159103691578,
            0.015868743881583214,
            -0.013199619017541409,
            0.0075602782890200615,
            -0.01426765602082014,
            0.009612680412828922,
            -0.023790089413523674,
            -0.004806120879948139,
            0.0015660661738365889,
            0.010188881307840347,
            0.02058294415473938,
            0.02525571547448635,
            0.010341481305658817,
            -0.026343470439314842,
            -0.01262660976499319,
            -0.0028711939230561256,
            0.03844813257455826,
            0.03272693231701851,
            -0.01120302826166153,
            -0.020177846774458885,
            0.018701963126659393,
            -0.024021515622735023,
            0.017340058460831642,
            0.026052815839648247,
            0.014878831803798676,
            0.009949525818228722,
            -0.007951962761580944,
            -0.011191196739673615,
            -0.017281027510762215,
            -0.02678743563592434,
            0.014329914003610611,
            -0.009441633708775043,
            -0.01618717424571514,
            -0.017960166558623314,
            0.008738072589039803,
            0.02034015767276287,
            0.026039086282253265,
            -0.03386884555220604,
            -0.02678528241813183,
            -0.01724756322801113,
            0.038970038294792175,
            0.023052459582686424,
            0.050330571830272675,
            -0.014316178858280182,
            -0.0007379785529337823,
            -0.02012207917869091,
            0.020696720108389854,
            -0.020404256880283356,
            0.0005570314824581146,
            0.03551756218075752,
            -0.011137712746858597,
            0.0016895657172426581,
            -0.013056141324341297,
            0.0022917636670172215,
            0.01686342991888523,
            -0.02048206515610218,
            0.014260533265769482,
            -0.04013783484697342,
            0.03014618158340454,
            0.012241889722645283,
            -0.021041976287961006,
            0.011745404452085495,
            -0.011240584775805473,
            -0.007797787431627512,
            0.031099537387490273,
            -0.02562870644032955,
            -0.0035954159684479237,
            0.0590222105383873,
            0.014230356551706791,
            0.025966672226786613,
            0.014526220969855785,
            0.004869652912020683,
            0.03356213867664337,
            0.016677694395184517,
            0.0062528993003070354,
            -0.017140217125415802,
            -0.00020444276742637157,
            -0.01834505796432495,
            0.005023695062845945,
            -0.0001811983238440007,
            -0.024989992380142212,
            0.016158582642674446,
            -0.02877013385295868,
            0.029229268431663513,
            -0.017244573682546616,
            -0.024699190631508827,
            -0.023011859506368637,
            -0.002898096339777112,
            0.019273066893219948,
            -0.016853054985404015,
            0.009991210885345936,
            0.021976402029395103,
            -0.009431971237063408,
            0.012341659516096115,
            0.023341992869973183,
            0.025398964062333107,
            -0.027150047942996025,
            0.012442776933312416,
            -0.008451567031443119,
            0.018590174615383148,
            -0.03620859608054161,
            0.026888394728302956,
            0.017326777800917625,
            -0.03740616515278816,
            -0.029063722118735313,
            0.04438287764787674,
            0.0011767437681555748,
            0.03196125477552414,
            -0.0017639428842812777,
            -0.01965419016778469,
            -0.023747459053993225,
            0.012759017758071423,
            0.006062070839107037,
            0.01688206009566784,
            0.004275706131011248,
            -0.012702126987278461,
            -0.006357747130095959,
            0.013513471931219101,
            0.012205959297716618,
            0.021088844165205956,
            0.014213698916137218,
            0.004204575903713703,
            0.025825699791312218,
            -0.008614163845777512,
            0.03581257164478302,
            0.028924718499183655,
            -0.009131046943366528,
            -0.0023062850814312696,
            0.012428801506757736,
            0.024997403845191002,
            0.014313189312815666,
            0.007191925309598446,
            0.02845902368426323,
            -0.007513043005019426,
            -0.024041663855314255,
            0.00674858782440424,
            0.02787729911506176,
            0.01357486192137003,
            0.003715822007507086,
            -0.00264349696226418,
            -0.012490806169807911,
            0.02503228560090065,
            -0.005039707291871309,
            -0.020002439618110657,
            0.016856815665960312,
            0.02426818571984768,
            0.0018597691087052226,
            0.004290187265723944,
            0.008047228679060936,
            -0.04599590599536896,
            0.025022756308317184,
            -0.016997406259179115,
            -0.0055466447956860065,
            -0.03487434610724449,
            -0.008126785047352314,
            -0.0073148286901414394,
            -0.029870903119444847,
            0.01591862365603447,
            0.01819840632379055,
            -0.022118037566542625,
            -0.02384595386683941,
            -0.0239434614777565,
            0.023113612085580826,
            0.01912606507539749,
            0.006713773589581251,
            -0.007657517213374376,
            0.011116105131804943,
            -0.012093867175281048,
            -0.045655447989702225,
            -0.008126507513225079,
            -0.013271475210785866,
            -0.019389651715755463,
            0.03855212405323982,
            -0.034747496247291565,
            0.02098546177148819,
            0.03128693997859955,
            -0.016326719895005226,
            0.011407013051211834,
            -0.028051787987351418,
            -0.011751590296626091,
            0.0003760754771064967,
            -0.0036228273529559374,
            0.0025259722024202347,
            0.02853298932313919,
            0.039571646600961685,
            0.015390302985906601,
            -0.028207723051309586,
            -0.02298235148191452,
            0.005976990796625614,
            0.008618136867880821,
            -0.020961416885256767,
            0.002624046988785267,
            0.039293549954891205,
            -0.005142068490386009,
            -0.01590828038752079,
            0.024823004379868507,
            0.01869330368936062,
            -0.03270511329174042,
            0.03586898744106293,
            -0.029044553637504578,
            -0.009288432076573372,
            0.008817583322525024,
            -0.02631457895040512,
            -0.030158162117004395,
            -0.020220469683408737,
            -0.03235889971256256,
            0.007471369579434395,
            -0.00957965012639761,
            -0.012736920267343521,
            0.018059683963656425,
            -0.030529772862792015,
            0.025122975930571556,
            0.021392010152339935,
            0.011602197773754597,
            0.008265442214906216,
            -0.025696827098727226,
            0.01574063114821911,
            0.0006959130405448377,
            -0.01917463354766369,
            -0.004093775060027838,
            0.025252364575862885,
            -0.019937288016080856,
            -0.010643444955348969,
            0.0029347760137170553,
            0.02794473245739937,
            0.022998221218585968,
            -0.026390181854367256,
            0.015571095049381256,
            -0.014462456107139587,
            0.03788566216826439,
            0.002026529284194112,
            0.009196980856359005,
            0.019458752125501633,
            0.018321914598345757,
            0.012350908480584621,
            -0.008933940902352333,
            -0.008370321244001389,
            0.01082733552902937,
            -0.025887997820973396,
            0.012246915139257908,
            0.018279485404491425,
            0.004221896640956402,
            -0.0127331567928195,
            0.022921020165085793,
            -0.01488971896469593,
            0.0013553504832088947,
            0.0011227054055780172,
            0.028187939897179604,
            -0.04611928015947342,
            0.004418071825057268,
            -0.004395230207592249,
            -0.0017608816269785166,
            0.013905875384807587,
            0.007915758527815342,
            0.004521580878645182,
            0.016229545697569847,
            0.027394156903028488,
            0.0028632800094783306,
            -0.014525698497891426,
            -0.02938012406229973,
            -0.012889435514807701,
            -0.014282945543527603,
            -0.022719960659742355,
            0.016366783529520035,
            0.015614300966262817,
            -0.015146871097385883,
            -0.031094705685973167,
            0.0055130296386778355,
            -0.03743341937661171,
            -0.009003066457808018,
            -0.0014601724687963724,
            0.024189570918679237,
            -0.0075688231736421585,
            -0.049575287848711014,
            0.02184072881937027,
            0.017897309735417366,
            -0.011340002529323101,
            -0.0038166677113622427,
            0.014055666513741016,
            0.016077399253845215,
            0.01431671530008316,
            0.02530551701784134,
            0.016352377831935883,
            -0.0013201538240537047,
            0.014901341870427132,
            -0.0073106237687170506,
            0.020355960354208946,
            -0.00658446317538619,
            -0.00688822939991951,
            0.03467350825667381,
            0.007754086051136255,
            0.016001340001821518,
            0.002590232528746128,
            -0.014339523389935493,
            0.03230779990553856,
            -0.0074830190278589725,
            0.0061347633600234985,
            0.02054472267627716,
            0.005393585655838251,
            0.008874589577317238,
            0.009710068814456463,
            0.030567770823836327,
            0.03805164247751236,
            0.02893054485321045,
            -0.006814748980104923,
            0.017961274832487106,
            0.007253090851008892,
            0.013501269742846489,
            -0.03956602141261101,
            0.020024923607707024,
            -0.020784512162208557,
            -0.007340012118220329,
            -0.0382632240653038,
            -0.002977369586005807,
            0.02056729607284069,
            -0.017441632226109505,
            -0.02840321511030197,
            -0.019690759479999542,
            -0.032595183700323105,
            0.013153431005775928,
            0.00012407987378537655,
            -0.008838298730552197,
            0.034712404012680054,
            0.007371973246335983,
            0.007737851236015558,
            -0.005231600720435381,
            0.017119035124778748,
            0.007869329303503036,
            -0.001912549021653831,
            -0.01974020153284073,
            -0.027378465980291367,
            0.032946549355983734,
            0.030081456527113914,
            0.003319995477795601,
            -0.015890181064605713,
            0.007899995893239975,
            -0.025061458349227905,
            0.03906881436705589,
            0.011600316502153873,
            -0.029529951512813568,
            0.007991619408130646,
            0.021857647225260735,
            -0.021248742938041687,
            -0.03692546486854553,
            -0.010877884924411774,
            0.0004489911952987313,
            -0.026920001953840256,
            0.01812075823545456,
            0.03246798366308212,
            0.0152819799259305,
            -0.03470166027545929,
            -0.00906625296920538,
            0.006570504978299141,
            0.014891397207975388,
            0.021535931155085564,
            -0.022194311022758484,
            0.011502082459628582,
            -0.02158193662762642,
            0.0230245441198349,
            -0.028925221413373947,
            0.006092935334891081,
            -0.0030321795493364334,
            -0.03321346268057823,
            0.019866714254021645,
            -0.003112963866442442,
            -0.036423780024051666,
            0.026994379237294197,
            -0.005623277742415667,
            -0.023867979645729065,
            0.021359266713261604,
            0.02564484253525734,
            -0.006182853598147631,
            0.029399776831269264,
            0.006362003739923239,
            0.020550375804305077,
            0.020577285438776016,
            0.0273237656801939,
            0.004597139544785023,
            -0.03294298052787781,
            0.03305082023143768,
            -0.030723541975021362,
            0.030640561133623123,
            -0.031191108748316765,
            0.0028264333959668875,
            0.02192850038409233,
            0.028437893837690353,
            -0.025771599262952805,
            0.010944975540041924,
            -0.02580183371901512,
            0.03332171216607094,
            -0.02095762826502323,
            0.00030494367820210755,
            -0.022510338574647903,
            -0.010190014727413654,
            0.015003838576376438,
            -0.027593163773417473,
            -0.023906202986836433,
            -0.030812213197350502,
            0.0020213224925100803,
            0.009521168656647205,
            0.01477550994604826,
            0.02268805354833603,
            0.033056437969207764,
            -0.01490007247775793,
            0.007781153544783592,
            -0.00030172840342856944,
            -0.0378202423453331,
            0.0470275841653347,
            -0.021052461117506027,
            0.024274680763483047,
            -0.015367093496024609,
            -0.0317244678735733,
            0.003740074811503291,
            0.0379859022796154,
            0.02058691717684269,
            -0.021614274010062218,
            -0.02158358506858349,
            -0.004586507566273212,
            -0.011691194958984852,
            -0.007796401623636484
        ],
        [
            0.00598185695707798,
            -0.0003385746676940471,
            -0.009223715402185917,
            -0.03809936344623566,
            -0.01359961461275816,
            0.019798099994659424,
            0.009682321920990944,
            -0.02181670442223549,
            -0.01379166729748249,
            0.009577075019478798,
            -0.03916482254862785,
            0.004648421425372362,
            -0.0179489403963089,
            -0.02167310193181038,
            -0.045546483248472214,
            0.008560909889638424,
            -0.03187369555234909,
            -0.001101122354157269,
            -0.010958565399050713,
            0.023754457011818886,
            0.0062582846730947495,
            -0.01181888673454523,
            0.028376571834087372,
            0.045991599559783936,
            0.01785513572394848,
            -0.006582492031157017,
            -0.0362088568508625,
            -0.02619277313351631,
            -0.023396553471684456,
            0.005788646638393402,
            -0.0011399082140997052,
            -0.0006935345591045916,
            0.012499852105975151,
            0.023184392601251602,
            -0.015013092197477818,
            -0.006858746986836195,
            -0.020805170759558678,
            -0.030934862792491913,
            0.020360484719276428,
            0.030404655262827873,
            0.03484746068716049,
            -0.00828584749251604,
            -0.011379209347069263,
            0.00821743719279766,
            -0.020359640941023827,
            -0.018694894388318062,
            0.016402289271354675,
            -0.007821371778845787,
            0.022010698914527893,
            0.0036289773415774107,
            0.025803104043006897,
            0.01791050098836422,
            0.020133284851908684,
            0.042342931032180786,
            -0.048463113605976105,
            -0.004305306356400251,
            0.02996608056128025,
            -0.0215123500674963,
            0.0032847654074430466,
            0.006527524441480637,
            0.0030711202416568995,
            -0.008825462311506271,
            -0.007927164435386658,
            0.02493099868297577,
            0.013263351283967495,
            0.003948958124965429,
            0.01477714255452156,
            0.011050519533455372,
            -0.007486309856176376,
            -0.02000822313129902,
            0.0007936185575090349,
            0.0021295880433171988,
            -0.00010454983566887677,
            0.01515951193869114,
            0.004809589125216007,
            -0.021114204078912735,
            -0.027705812826752663,
            0.019635174423456192,
            -0.03168541565537453,
            -0.013325328938663006,
            0.0035671766381710768,
            0.04114300385117531,
            -0.001265740836970508,
            -0.02247883938252926,
            0.022920861840248108,
            0.01698932982981205,
            0.014269634149968624,
            0.004853063728660345,
            -0.014448964968323708,
            -0.010904517024755478,
            -0.004026963375508785,
            0.008542274124920368,
            0.0003451955271884799,
            -0.018161753192543983,
            0.0059018428437411785,
            -0.0007697946857661009,
            -0.03401517495512962,
            -0.01701395958662033,
            -0.03278680890798569,
            -0.013124225661158562,
            0.022305037826299667,
            -0.0025542504154145718,
            0.02087259665131569,
            0.03400326147675514,
            0.012603510171175003,
            -0.00885305181145668,
            -0.008260742761194706,
            0.026417125016450882,
            0.03890621289610863,
            0.0029663117602467537,
            0.039468709379434586,
            -0.0069815549068152905,
            -0.03953143209218979,
            0.004098602570593357,
            0.04099969193339348,
            -0.017834825441241264,
            0.019526971504092216,
            -0.012339700944721699,
            -0.003928898833692074,
            0.003313462482765317,
            -0.03810569643974304,
            -0.023754650726914406,
            -0.06180977821350098,
            0.016496846452355385,
            0.02958844229578972,
            -0.009367881342768669,
            0.025209767743945122,
            -0.013142447918653488,
            0.022798119112849236,
            0.02143719419836998,
            -0.01821458339691162,
            0.003826608881354332,
            -0.02523791790008545,
            -0.0067140222527086735,
            0.023219946771860123,
            -0.013580651953816414,
            -0.025542819872498512,
            -0.007536024786531925,
            0.0311054065823555,
            0.03464853763580322,
            -0.021943898871541023,
            0.023753462359309196,
            -0.011429648846387863,
            -0.0008413473842665553,
            0.006943135987967253,
            -0.01318573858588934,
            0.036923907697200775,
            -0.0009456591214984655,
            -0.013788390904664993,
            -0.004013186786323786,
            -0.029234983026981354,
            0.019096380099654198,
            -0.03184473142027855,
            -0.01248068455606699,
            -0.010256694629788399,
            -0.014871107414364815,
            -0.00414290651679039,
            0.011606233194470406,
            -0.026638424023985863,
            -0.010717601515352726,
            0.01945245824754238,
            -0.0285525880753994,
            0.01186262909322977,
            -0.01093218382447958,
            0.037101395428180695,
            -0.013108034618198872,
            -0.012906983494758606,
            -0.006040915846824646,
            -0.04019220173358917,
            0.006041048560291529,
            -0.027079783380031586,
            -0.018464161083102226,
            0.0016815493581816554,
            0.008196488954126835,
            0.014669781550765038,
            0.038103263825178146,
            0.0015985879581421614,
            0.0030648158863186836,
            -0.02234320156276226,
            0.021883368492126465,
            0.016449717804789543,
            0.002698446623980999,
            -0.0015983069315552711,
            0.007766293361783028,
            0.007484548259526491,
            0.026016123592853546,
            0.034190110862255096,
            -0.03789713233709335,
            -0.004857501480728388,
            0.02496156468987465,
            -0.028537916019558907,
            -0.014799835160374641,
            -0.03008590266108513,
            0.027709849178791046,
            -0.0050287870690226555,
            0.035801000893116,
            0.03189879655838013,
            0.004512911196798086,
            -0.007829587906599045,
            0.0134268281981349,
            0.03616144508123398,
            -0.011302808299660683,
            -0.005576170980930328,
            0.003967140335589647,
            -0.05566137656569481,
            -0.021148325875401497,
            -0.004531363490968943,
            -0.02062530256807804,
            -0.027181098237633705,
            -0.003991081379354,
            -0.011459806933999062,
            -0.04447036236524582,
            0.03259776160120964,
            0.003192158183082938,
            0.004364109598100185,
            -0.018834825605154037,
            0.0013726218603551388,
            -0.008629728108644485,
            0.023112839087843895,
            -0.006053422577679157,
            0.007362704258412123,
            0.016637304797768593,
            -0.012950755655765533,
            -0.046567153185606,
            -0.048405420035123825,
            0.011235981248319149,
            -0.0036258804611861706,
            0.013451346196234226,
            0.0016794560942798853,
            0.003406998235732317,
            -0.008136427961289883,
            0.014739285223186016,
            0.0078754723072052,
            0.03293925151228905,
            0.01707388646900654,
            -0.005771108437329531,
            -0.05498350039124489,
            -0.016805453225970268,
            0.03787868842482567,
            0.03409891203045845,
            -0.0278744176030159,
            0.017093120142817497,
            0.013350820168852806,
            0.02391023375093937,
            -0.006674752105027437,
            0.01863478682935238,
            -0.009564192965626717,
            0.014618408866226673,
            -0.0062197549268603325,
            -0.025241989642381668,
            0.024599429219961166,
            -0.016424212604761124,
            -0.06374708563089371,
            0.008837699890136719,
            0.006524953525513411,
            0.031314246356487274,
            0.037276409566402435,
            -0.004722938407212496,
            -0.010760095901787281,
            0.034790121018886566,
            0.0058333901688456535,
            -0.0014106822200119495,
            -0.016917869448661804,
            0.030173877254128456,
            -0.022628622129559517,
            -0.01743987761437893,
            0.0014387411065399647,
            0.02656000852584839,
            -0.021324703469872475,
            -0.0353628508746624,
            -0.008787018246948719,
            -0.01042003370821476,
            -0.0066035715863108635,
            0.03756501525640488,
            -0.016759449616074562,
            0.028157459571957588,
            -0.0180992279201746,
            -0.014366012997925282,
            -0.022004559636116028,
            -0.013806906528770924,
            -0.01721678115427494,
            -0.02161024883389473,
            -0.025879571214318275,
            0.021433865651488304,
            -0.024654021486639977,
            -0.02842649072408676,
            0.01862628012895584,
            -0.016177279874682426,
            -0.03263910487294197,
            0.020219864323735237,
            -0.011597149074077606,
            -0.013568460009992123,
            0.016462227329611778,
            -0.0071878377348184586,
            -0.012910899706184864,
            0.033674512058496475,
            -0.01909160241484642,
            0.02625867910683155,
            -0.03716312721371651,
            0.004479444585740566,
            -0.027152258902788162,
            -0.001621719216927886,
            -0.007842009887099266,
            -0.00995641853660345,
            -0.007761089131236076,
            -0.0011938245734199882,
            0.02510751225054264,
            0.030391434207558632,
            -0.01427712757140398,
            0.016664722934365273,
            0.047622643411159515,
            0.035965707153081894,
            0.005203693173825741,
            -0.0185626782476902,
            -0.033956434577703476,
            0.005523236468434334,
            0.0014598171692341566,
            -0.03128258138895035,
            0.008800366893410683,
            -9.702899114927277e-05,
            -0.02315540611743927,
            -0.04440277814865112,
            -0.03379671648144722,
            0.01907692477107048,
            0.014056695625185966,
            0.02385781705379486,
            0.000590719748288393,
            -0.024005459621548653,
            -0.013774769380688667,
            0.021645868197083473,
            -0.029161088168621063,
            -0.013702011667191982,
            -0.04168566316366196,
            0.005727357696741819,
            0.01789567619562149,
            0.04587123170495033,
            0.013485576026141644,
            0.020360158756375313,
            0.003795313648879528,
            0.034626305103302,
            -0.0020129482727497816,
            -0.03550969436764717,
            -0.004481279291212559,
            0.009316014125943184,
            0.02333906665444374,
            0.02189376950263977,
            -0.025269167497754097,
            0.020292820408940315,
            -0.019782189279794693,
            0.023192860186100006,
            -0.01878662407398224,
            0.008735176175832748,
            -0.022960452362895012,
            -0.024812979623675346,
            0.0039190384559333324,
            -0.0036293012090027332,
            -0.022759512066841125,
            -0.02635129727423191,
            -0.02121022343635559,
            -0.006285842042416334,
            -0.03264661505818367,
            -0.01763215847313404,
            0.01486410852521658,
            -0.023380018770694733,
            -0.011200678534805775,
            -0.0023652592208236456,
            0.014016104862093925,
            0.013841678388416767,
            -0.005807474255561829,
            0.027248306199908257,
            -0.017437905073165894,
            0.03129388764500618,
            -0.023928144946694374,
            -0.03356873616576195,
            -0.018953384831547737,
            0.021184926852583885,
            0.018139444291591644,
            0.011774149723351002,
            -0.012792795896530151,
            0.018412690609693527,
            0.006109375972300768,
            -0.005835339426994324,
            -0.022053008899092674,
            0.0002838738728314638,
            -0.021694447845220566,
            0.06256053596735,
            -0.01712719351053238,
            -0.017406661063432693,
            -0.01265106350183487,
            -0.007081694435328245,
            0.02357322908937931,
            -0.027951106429100037,
            -0.0354292057454586,
            0.021451285108923912,
            0.012458991259336472,
            0.0023894356563687325,
            -0.014804099686443806,
            -0.0096003208309412,
            -0.011160009540617466,
            -0.01027179230004549,
            -0.03750241920351982,
            0.001114710932597518,
            -0.02169501595199108,
            0.01412163209170103,
            -0.020313849672675133,
            -0.0158949363976717,
            -0.020550712943077087,
            -0.02564062364399433,
            0.01047965232282877,
            0.0415690615773201,
            -0.003995878156274557,
            0.03377857059240341,
            -0.011955740861594677,
            0.0037898533046245575,
            0.017825810238718987,
            -0.02342022955417633,
            -0.0003157834289595485,
            -0.030160512775182724,
            -0.010296959429979324,
            -0.031675826758146286,
            -0.027427945286035538,
            0.0068979267962276936,
            0.011973180808126926,
            -0.015118558891117573,
            -0.024113478139042854,
            -0.007005470339208841,
            0.044007837772369385,
            -0.021925879642367363,
            0.0020315435249358416,
            -0.014675051905214787,
            0.029184497892856598,
            -0.01275791134685278,
            -0.008551052771508694,
            0.007908651605248451,
            -0.017067138105630875,
            -0.021152425557374954,
            -0.015396985225379467,
            0.019911017268896103,
            0.011758087202906609,
            -0.002734958194196224,
            0.0010699525009840727,
            -0.030910678207874298,
            0.02011757902801037,
            0.014112548902630806,
            -0.015353850089013577,
            0.025178033858537674,
            0.01938757486641407,
            -0.0444495789706707,
            -0.016643932089209557,
            0.014598654583096504,
            -0.03162072226405144,
            -0.013500248081982136,
            0.03133634850382805,
            -0.0030937273986637592,
            -0.026795612648129463,
            0.02116955630481243,
            0.05536646768450737,
            -0.0027206914965063334,
            0.021269062533974648,
            0.023597780615091324,
            -0.004487065132707357,
            0.01366119273006916,
            -0.001830781577154994,
            -0.0039013908244669437,
            -0.013545718975365162,
            -0.025968387722969055,
            -0.005355330184102058,
            0.01181590836495161,
            -0.023690249770879745,
            0.0046378737315535545,
            0.030723806470632553,
            -0.023821288719773293,
            0.02113932929933071,
            -0.017322108149528503,
            -0.028217202052474022,
            0.005054856184870005,
            0.01166867557913065,
            0.02815108187496662,
            -0.01785922236740589,
            0.007387198507785797,
            0.01280184555798769,
            0.0211165901273489,
            -0.004034567158669233,
            0.018975725397467613,
            -0.02189757488667965,
            -0.008664589375257492,
            -0.03199922665953636,
            -0.038960058242082596,
            -0.0865267962217331,
            -0.03650401905179024,
            -0.03402433916926384,
            0.02940586395561695,
            -0.026714496314525604,
            -0.04090441018342972,
            0.038681890815496445,
            -0.021639086306095123,
            -0.005480912979692221,
            0.001230815309099853,
            0.01228670496493578,
            -0.025595659390091896,
            0.027834931388497353,
            -0.027635233476758003,
            -0.02697613649070263,
            0.026369668543338776,
            -0.00500042038038373,
            -0.0030626088846474886,
            -0.03348270058631897,
            0.031166965141892433,
            -0.031414687633514404,
            0.015428179875016212,
            0.0057021984830498695,
            -0.019903598353266716,
            0.005341792479157448,
            -0.001887155114673078,
            -0.007618961855769157,
            0.016649803146719933,
            -0.02361977845430374,
            -0.008693020790815353,
            -0.008673170581459999,
            -0.03505542129278183,
            -0.022325148805975914,
            0.01567908376455307,
            0.0241562332957983,
            0.005781624000519514,
            0.010575884021818638,
            -0.036785054951906204,
            -0.051615700125694275,
            0.020576512441039085,
            0.01852383092045784,
            0.002622047672048211,
            0.029472021386027336,
            -0.000595534685999155,
            0.014194129034876823,
            0.028522277250885963,
            0.03145375847816467,
            0.0041694603860378265,
            -0.012624283321201801,
            0.02629079483449459,
            -0.01908186823129654,
            0.005800266284495592,
            -0.00012064174370607361,
            -0.005428782198578119,
            -0.03539203107357025,
            0.026211407035589218,
            0.023635704070329666,
            -0.03209153935313225,
            -0.026257704943418503,
            -0.03583502396941185,
            0.004190018866211176,
            0.009099103510379791,
            0.020813830196857452,
            -0.011524052359163761,
            -0.03411493077874184,
            0.0022651134058833122,
            0.006449056789278984,
            0.008079802617430687,
            -0.012371133081614971,
            -0.0015612998977303505,
            -0.0014210166409611702,
            -0.012231552042067051,
            -0.019393542781472206,
            0.008382129482924938,
            -0.018710847944021225,
            0.019831165671348572,
            -0.02962667867541313,
            -0.006175098475068808,
            0.008071092888712883,
            0.014318818226456642,
            0.030462343245744705,
            -0.006534452084451914,
            -0.008982275612652302,
            0.007073329295963049,
            0.03111790120601654,
            0.016414057463407516,
            -0.017988404259085655,
            -0.024200765416026115,
            0.005700771231204271,
            -0.05797690898180008,
            -0.000635127944406122,
            0.0011360193602740765,
            -0.0182704608887434,
            0.0019686908926814795,
            -0.027575792744755745,
            -0.010531897656619549,
            0.019357729703187943,
            -0.04136757180094719,
            0.022460108622908592,
            -0.026405228301882744,
            0.028944892808794975,
            -0.012032401748001575,
            -0.01708798110485077,
            0.0014191549271345139,
            -0.01714310608804226,
            0.021003954112529755,
            0.021336311474442482,
            0.028373604640364647,
            0.026333626359701157,
            0.02739301510155201,
            0.02357466332614422,
            -0.04912159591913223,
            -0.0397539921104908,
            0.0313807912170887,
            -0.011021588928997517,
            -0.02292078733444214,
            5.373567182687111e-05,
            0.022560596466064453,
            0.02542424201965332,
            -0.019705748185515404,
            -0.01977679133415222,
            0.010289866477251053,
            0.03732417896389961,
            0.01710444688796997,
            -0.014519765041768551,
            0.017615418881177902,
            -0.009833285585045815,
            -0.0303831547498703,
            -0.003346764948219061,
            -0.02238098531961441,
            -0.021183811128139496,
            -0.03815356269478798,
            -0.07193542271852493,
            -0.032362643629312515,
            0.015170980244874954,
            -0.02893010526895523,
            -0.018477797508239746,
            0.0012012084480375051,
            0.020648078992962837,
            -0.02341095171868801,
            -0.0024832773488014936,
            0.01651456207036972,
            -0.04303530231118202,
            0.002816043561324477,
            0.004001976456493139,
            -0.009604502469301224,
            0.014251385815441608,
            0.017182182520627975,
            0.023313341662287712,
            -0.003654706524685025,
            0.01803034357726574,
            -0.01991325430572033,
            0.0316440649330616,
            -0.011508306488394737,
            -0.0061110337264835835,
            0.045466672629117966,
            -0.006334169767796993,
            -0.019871940836310387,
            -0.017720669507980347,
            0.01796717196702957,
            0.03286803886294365,
            -0.012542661279439926,
            -0.0035489315632730722,
            0.006663330364972353,
            -0.009705730713903904,
            0.009830431081354618,
            -0.002786723431199789,
            0.01788805052638054,
            0.01534857414662838,
            -0.002015883568674326,
            0.014181570149958134,
            0.0034519594628363848,
            -0.029661336913704872,
            0.012518912553787231,
            0.0017106577288359404,
            -0.004810423590242863,
            -0.026548074558377266,
            -0.010107957758009434,
            -0.027445916086435318,
            -0.01974823884665966,
            0.012547948397696018,
            0.007883005775511265,
            0.022111158818006516,
            -0.01673566922545433,
            0.028334414586424828,
            0.026830213144421577,
            0.029161322861909866,
            0.005034424364566803,
            0.01784265972673893,
            -0.013348374515771866,
            0.0372571162879467,
            0.016474487259984016,
            -0.016858624294400215,
            0.0061208694241940975,
            0.032215241342782974,
            -0.02128659375011921,
            0.021684492006897926,
            0.008327057585120201,
            -0.028108736500144005,
            0.011151635088026524,
            0.025614559650421143,
            -0.0030649134423583746,
            0.06088213250041008,
            0.013823316432535648,
            -0.0182232353836298,
            0.0033402624540030956,
            0.014327012933790684,
            -0.03234846889972687,
            -0.028962329030036926,
            -0.014600427821278572,
            0.02745012380182743,
            -0.003204150591045618,
            0.021215073764324188,
            -0.040827374905347824,
            -0.036690983921289444,
            0.015651848167181015,
            0.035117484629154205,
            -0.026250334456562996,
            -0.013074925169348717,
            -0.011511399410665035,
            0.011185559444129467,
            -0.005988133139908314,
            0.008575143292546272,
            0.004159014206379652,
            -0.019110998138785362,
            -0.021782027557492256,
            -0.006015676073729992,
            0.019656237214803696,
            0.04096703603863716,
            0.012159600853919983,
            -0.009839929640293121,
            0.019193554297089577,
            -0.03372001647949219,
            0.020124152302742004,
            0.006702338811010122,
            -0.031133076176047325,
            -0.023293521255254745,
            0.028801780194044113,
            0.002913790987804532,
            -0.025542372837662697,
            -0.03728431090712547,
            0.019839176908135414,
            -0.021921832114458084,
            0.019982904195785522,
            -0.008239257149398327,
            -0.023340772837400436,
            0.017529448494315147,
            -0.04143088310956955,
            0.03358079493045807,
            0.018435925245285034,
            -0.0033548749051988125,
            0.034266453236341476,
            0.004817272536456585,
            -0.01724342815577984,
            0.0062593515031039715,
            -0.019319653511047363,
            0.00970414374023676,
            -5.604020043392666e-05,
            -0.009672433137893677,
            0.026388367637991905,
            0.012495891191065311,
            -0.002713407389819622,
            -0.020394371822476387,
            -0.024998487904667854,
            -0.022590085864067078,
            -0.021065548062324524,
            0.0059563047252595425,
            0.03337755426764488,
            0.02134818769991398,
            0.047712672501802444,
            -0.01723606139421463,
            0.01850203238427639,
            -0.005170133430510759,
            -0.010447980836033821,
            -0.019556459039449692,
            0.0074618421494960785,
            -0.03267238661646843,
            0.021743882447481155,
            -0.023327425122261047,
            -0.0238511860370636,
            0.014787630178034306,
            -0.009560908190906048,
            -0.012909717857837677,
            0.010865490883588791,
            0.015291421674191952,
            0.009207538329064846,
            -0.021481838077306747,
            -0.010668007656931877,
            -0.02904484048485756,
            -0.0353575274348259,
            -0.0028723012655973434,
            -0.009956266731023788,
            -0.003143926849588752,
            0.01854637637734413,
            -0.019237011671066284,
            -0.001626263023354113,
            0.0372626967728138,
            -0.001845313236117363,
            0.003111152444034815,
            0.020605551078915596,
            0.0166466161608696,
            -0.00745274405926466,
            0.01357653085142374,
            -0.006806305143982172,
            0.017816588282585144,
            0.015846077352762222,
            -0.026954198256134987,
            -0.00025028694653883576,
            0.027898848056793213,
            0.03462914749979973,
            0.02062615193426609,
            -0.05600469186902046,
            0.027780158445239067,
            0.01924639753997326,
            -0.013630677945911884,
            0.024999579414725304,
            0.001909493119455874,
            0.013881633058190346,
            0.011028497479856014,
            -0.002340080915018916,
            -0.006562715861946344,
            -0.011252385564148426,
            0.022188561037182808,
            0.029041936621069908,
            0.013833262026309967,
            0.017758414149284363,
            -0.010731886141002178,
            -0.001573047717101872,
            0.011910893023014069,
            -0.03877658396959305,
            0.0170750692486763,
            0.013837454840540886,
            -0.042954135686159134,
            -0.0043815127573907375,
            -0.03203775733709335,
            -0.010759216733276844,
            -0.014973614364862442,
            0.011694961227476597,
            0.00025036660372279584,
            -0.024840138852596283,
            -0.012093940749764442,
            0.008127155713737011,
            0.020796962082386017,
            0.027961645275354385,
            -0.02149280346930027,
            -0.022994307801127434,
            0.02547180838882923,
            -0.02546480856835842,
            0.02653632126748562,
            0.003513840027153492,
            -0.013768679462373257,
            -0.022129327058792114,
            0.016736339777708054,
            -0.04763825982809067,
            -0.005164654925465584,
            -0.008929192088544369,
            0.03288058936595917,
            -0.030511239543557167,
            0.03134272247552872,
            0.009250542148947716,
            -0.004615867510437965,
            0.010700701735913754,
            0.014353886246681213,
            -0.0031518551986664534,
            0.041081175208091736,
            -0.02938949689269066,
            -0.0008555508684366941,
            -0.003524580504745245,
            -0.025435473769903183,
            0.01425557117909193,
            0.036280810832977295,
            0.01861630380153656,
            0.01929616369307041,
            0.00404643127694726,
            0.015021267347037792,
            -0.02107982523739338,
            -0.0033370452001690865,
            0.014346427284181118,
            -0.015992894768714905,
            -0.01433455292135477,
            -0.020198971033096313,
            -0.037847261875867844,
            0.010041289031505585,
            0.022518405690789223,
            -0.06383175402879715,
            -0.0003598501207306981,
            0.028342192992568016,
            -0.0009538572048768401,
            0.007555978838354349,
            -0.06994155049324036,
            0.01351367961615324,
            0.01756703108549118,
            0.024434586986899376,
            0.00011457351502031088,
            -0.011686923913657665,
            -0.022875873371958733,
            -0.025192566215991974,
            0.019554708153009415,
            0.01175020169466734,
            -0.0004932739539071918,
            -0.030467228963971138,
            0.028678184375166893,
            0.03700748831033707,
            0.025364603847265244,
            0.000331357674440369,
            0.05745098739862442,
            -0.0234075915068388,
            -0.017742589116096497,
            0.02153751626610756,
            -0.03403091058135033,
            0.0031658862717449665,
            -0.005430041346698999,
            0.005975250154733658,
            0.01269395649433136,
            0.008111010305583477,
            0.023877521976828575,
            0.04446670785546303,
            0.029418205842375755,
            -0.04216423258185387,
            -0.0013952236622571945,
            0.02862946130335331,
            0.0102236894890666,
            -0.012967939488589764,
            -0.005390026140958071,
            0.020428240299224854,
            -0.043256502598524094,
            0.02593817561864853,
            -0.030643442645668983,
            0.02545318193733692,
            0.015663253143429756,
            0.0030523522291332483,
            0.023711876943707466,
            0.03171844035387039,
            0.0038529944140464067,
            -0.037940289825201035,
            0.035158850252628326,
            -0.04036460816860199,
            0.010703857988119125,
            0.005749871022999287,
            -0.01890840195119381,
            -0.01439516432583332,
            -0.0008459605160169303,
            0.028363410383462906,
            -0.030754875391721725,
            -0.02960360050201416,
            -0.0024665165692567825,
            0.0073551880195736885,
            -0.002862639259546995,
            0.03111105225980282,
            -0.02218184992671013,
            -0.0021714922040700912,
            0.016742929816246033,
            -0.028870632871985435,
            0.006615234073251486,
            0.013685801066458225,
            0.028778648003935814,
            -0.002172056818380952,
            -0.01550157181918621,
            -0.010931065306067467,
            0.021772807464003563,
            0.024151509627699852,
            0.017435865476727486,
            0.002571679186075926,
            -0.010815190151333809,
            0.0314343124628067,
            0.017544910311698914,
            -0.04533255100250244,
            0.00740809366106987,
            0.012759499251842499,
            -0.01217265147715807,
            0.003120995359495282,
            -0.00534313777461648,
            0.01916104182600975,
            -0.04639238864183426,
            -0.03862253949046135,
            -0.013453242368996143,
            -0.008552849292755127,
            -0.025574762374162674,
            0.00736955227330327,
            -0.020993756130337715,
            0.01987028680741787,
            0.0003567999810911715,
            -0.007266697008162737,
            -0.0007452915306203067,
            -0.03095575049519539,
            -0.01790817268192768,
            0.014204614795744419,
            0.02003861591219902,
            -0.01555507630109787,
            -0.022500645369291306,
            0.026830874383449554,
            0.0021299943327903748,
            0.008477403782308102,
            0.009191683493554592,
            0.02075079083442688,
            0.0026004286482930183,
            0.02237311191856861,
            -0.012082497589290142,
            0.0019139290088787675,
            0.02481568418443203,
            -0.012321057729423046,
            0.017884790897369385,
            0.013230685144662857,
            0.009557987563312054,
            -0.03420219197869301,
            -0.030307212844491005,
            0.025743989273905754,
            -0.009336423128843307,
            -0.04454101622104645,
            0.026889611035585403,
            -0.023321960121393204,
            0.03191352263092995,
            0.025413699448108673,
            0.009681249968707561,
            0.018491700291633606,
            0.01064777560532093,
            0.003961540292948484,
            0.011617762967944145,
            -0.021057045087218285,
            -0.007643706165254116,
            -0.004986399784684181,
            -0.02475583180785179,
            -0.023654047399759293,
            0.020940255373716354,
            -0.03451257944107056,
            -0.011034565977752209,
            -0.023881318047642708,
            0.005446082446724176,
            0.03094635345041752,
            -0.026440175250172615,
            -0.004263291601091623,
            0.022136656567454338,
            0.01405338291078806,
            0.007276665885001421,
            -0.0036309242714196444,
            0.027791928499937057
        ],
        [
            0.025262191891670227,
            -0.0214768685400486,
            0.0020910364110022783,
            -0.004438711795955896,
            0.00253954972140491,
            -0.03278794139623642,
            -0.0013606761349365115,
            -0.03989224135875702,
            0.0178621094673872,
            0.0060739582404494286,
            -0.02514439821243286,
            0.0162033811211586,
            0.031980644911527634,
            -0.046455804258584976,
            0.0447671115398407,
            -0.030736930668354034,
            -0.012161962687969208,
            0.01875987835228443,
            0.0034786395262926817,
            -0.02088168077170849,
            0.011777038685977459,
            -0.001762635656632483,
            -9.284699444833677e-06,
            0.034256890416145325,
            0.022750644013285637,
            0.013582921586930752,
            -0.02297671139240265,
            -0.03513053432106972,
            -0.024843744933605194,
            -0.028499923646450043,
            0.010092868469655514,
            0.015722155570983887,
            0.02962350659072399,
            -0.002806221367791295,
            0.006400051061064005,
            0.009717177599668503,
            0.0013578993966802955,
            0.02199362777173519,
            -0.02152799814939499,
            0.035337477922439575,
            0.02201518602669239,
            0.022815195843577385,
            -0.01623765379190445,
            -0.01038157008588314,
            0.01964741200208664,
            -0.03328277915716171,
            0.010508662089705467,
            0.03672270104289055,
            0.012702841311693192,
            0.002848173025995493,
            0.028173308819532394,
            -0.01610550656914711,
            -0.05815621465444565,
            -0.04118433967232704,
            0.028089268133044243,
            -0.02059834636747837,
            -0.014559452421963215,
            -0.011381366290152073,
            0.01156247965991497,
            -0.004250380676239729,
            0.030823707580566406,
            -0.02473505586385727,
            0.010666701011359692,
            -0.0029833833687007427,
            -0.0032158379908651114,
            0.02865065447986126,
            -0.03645801916718483,
            0.012398499064147472,
            0.0020202193409204483,
            0.016228917986154556,
            0.006358761806041002,
            -0.0002950672060251236,
            -0.012528764083981514,
            -0.017938274890184402,
            -0.020622264593839645,
            0.025622930377721786,
            -0.03021710179746151,
            -0.018599532544612885,
            -0.02815447375178337,
            -0.04155983030796051,
            0.04086799547076225,
            -0.009582231752574444,
            0.028658436611294746,
            0.02251538448035717,
            0.01645633950829506,
            0.020725857466459274,
            -0.02201453410089016,
            -0.033504754304885864,
            0.014371293596923351,
            0.0094454912468791,
            0.04298912361264229,
            -0.027955392375588417,
            -0.04144200682640076,
            0.004628852009773254,
            -0.020811723545193672,
            -0.0007018007454462349,
            -0.0486188568174839,
            0.0043518031015992165,
            -0.04042382538318634,
            -0.022186817601323128,
            -0.012240003794431686,
            -0.015684345737099648,
            0.03920111805200577,
            -0.022720877081155777,
            0.017201296985149384,
            0.018821734935045242,
            0.019223228096961975,
            0.05649518966674805,
            -0.03399175405502319,
            -0.03667648881673813,
            0.023893604055047035,
            -0.007967718876898289,
            0.00542454095557332,
            -0.013057050295174122,
            -0.008652211166918278,
            0.0035900736693292856,
            -0.009667309001088142,
            -0.007322038523852825,
            0.007110492791980505,
            0.017556333914399147,
            0.007050324231386185,
            -0.01638564094901085,
            0.035659417510032654,
            0.0028239258099347353,
            0.027135642245411873,
            -0.01663898304104805,
            0.003835737006738782,
            -0.017381982877850533,
            -0.02868860587477684,
            -0.02692333422601223,
            0.040055397897958755,
            0.014887272380292416,
            0.01562543585896492,
            0.001522996579296887,
            -0.01756298542022705,
            -0.011265180073678493,
            0.011097223497927189,
            -0.007769029587507248,
            -0.013585779815912247,
            -0.020684588700532913,
            0.01695987768471241,
            -0.021323688328266144,
            0.008185586892068386,
            -0.01768038608133793,
            -0.01323766727000475,
            0.01778302900493145,
            -0.006060544401407242,
            -0.008752243593335152,
            0.029508979991078377,
            -0.027592848986387253,
            0.008878716267645359,
            0.01644730195403099,
            -0.02082386612892151,
            0.05869092792272568,
            -0.009960106573998928,
            -0.039427660405635834,
            -0.04063187912106514,
            -0.0003502814215607941,
            -0.05416054278612137,
            -0.011628556065261364,
            -0.0019799780566245317,
            -0.003777038538828492,
            0.0016622549155727029,
            -0.031037762761116028,
            -0.022132180631160736,
            0.0666293203830719,
            -0.012012479826807976,
            -0.008098295889794827,
            -0.013422621414065361,
            -0.008615108206868172,
            0.00035947636933997273,
            -0.01431628130376339,
            -0.012721164152026176,
            0.01395472139120102,
            -0.002006120514124632,
            -0.013183043338358402,
            -0.06252141296863556,
            0.035189419984817505,
            -0.020137138664722443,
            0.03745782747864723,
            0.015092533081769943,
            0.007608454208821058,
            -0.00672775087878108,
            0.014606853015720844,
            0.025835633277893066,
            -0.012493440881371498,
            0.0003750340547412634,
            0.024477506056427956,
            -0.0005766573594883084,
            -0.015759197995066643,
            0.03073102980852127,
            0.02409443072974682,
            -0.04371115192770958,
            0.003586010541766882,
            0.012919570319354534,
            -0.003969509620219469,
            0.002761990297585726,
            -0.029656806960701942,
            -0.02329370565712452,
            0.007918684743344784,
            0.013228276744484901,
            -0.008875233121216297,
            0.007466147653758526,
            -0.04862173646688461,
            -0.020161865279078484,
            0.007285419851541519,
            -0.016817590221762657,
            -0.002425258280709386,
            -0.0011884295381605625,
            -0.030170734971761703,
            0.024310830980539322,
            0.0366412028670311,
            -0.0580151230096817,
            0.025286560878157616,
            0.030501557514071465,
            0.028237592428922653,
            -0.0025622232351452112,
            0.031100139021873474,
            0.005117432214319706,
            -0.024606918916106224,
            0.018670137971639633,
            0.025712469592690468,
            0.03192168101668358,
            0.0303671732544899,
            -0.01065035630017519,
            -0.04660443589091301,
            0.019784869626164436,
            -0.016669757664203644,
            -0.028344111517071724,
            0.024216100573539734,
            0.02476351335644722,
            0.01436864398419857,
            -0.02865232713520527,
            0.0037676177453249693,
            -0.05415666103363037,
            0.03403439000248909,
            0.00513264536857605,
            -0.0070497216656804085,
            0.011093376204371452,
            -0.01491976622492075,
            -0.0038069181609898806,
            0.02940169908106327,
            -0.009980644099414349,
            0.018458375707268715,
            0.01135324127972126,
            -0.007247155997902155,
            0.030356066301465034,
            0.007362795993685722,
            0.014683478511869907,
            0.03599313646554947,
            0.0021114794071763754,
            0.007602914236485958,
            0.02744450978934765,
            -0.014633680693805218,
            -0.007557723205536604,
            -0.020406965166330338,
            -0.01631040684878826,
            -0.008943771943449974,
            0.018048500642180443,
            -0.03395921736955643,
            0.01781652122735977,
            -0.02805003523826599,
            0.017176728695631027,
            0.012149262242019176,
            0.02294175699353218,
            0.03809629753232002,
            0.024836942553520203,
            0.03771166130900383,
            0.011214974336326122,
            0.030764073133468628,
            -0.015427215956151485,
            0.011203793808817863,
            -0.001640023780055344,
            0.0017664239276200533,
            0.01591898873448372,
            0.013725950382649899,
            0.01682494580745697,
            -0.02294943295419216,
            0.012684118933975697,
            -0.022833731025457382,
            0.009041906334459782,
            0.02373052015900612,
            0.020887786522507668,
            0.04395992308855057,
            -0.00543494988232851,
            -0.01966298371553421,
            0.023740969598293304,
            -0.00399926258251071,
            0.0020505227148532867,
            -0.009423455223441124,
            -0.004472244530916214,
            -0.020812688395380974,
            -0.019397906959056854,
            0.010259249247610569,
            -0.013261920772492886,
            -0.021867802366614342,
            -0.017105042934417725,
            0.030475439503788948,
            0.009903853759169579,
            -0.017388248816132545,
            -0.00018801212718244642,
            0.021709036082029343,
            -0.00849155243486166,
            -0.007315151393413544,
            0.0011134211672469974,
            0.04772539809346199,
            0.00553477555513382,
            -0.013212980702519417,
            0.028447050601243973,
            -0.02296786569058895,
            0.02518749237060547,
            0.019600028172135353,
            0.019827455282211304,
            0.016299836337566376,
            -0.004593256860971451,
            0.0027181049808859825,
            0.03007310815155506,
            0.03577406704425812,
            -0.00802195817232132,
            0.0030203701462596655,
            0.0019392470130696893,
            0.03592950850725174,
            0.008386536501348019,
            -0.07150044292211533,
            -0.01884244568645954,
            0.010904679074883461,
            -0.019954068586230278,
            -0.00436228234320879,
            0.009942291304469109,
            0.008686586283147335,
            0.008033771999180317,
            -0.032979078590869904,
            0.03918556123971939,
            0.017193859443068504,
            -0.03740053251385689,
            0.014032830484211445,
            -0.021802589297294617,
            -0.03920406475663185,
            -0.006386656779795885,
            0.0105137312784791,
            -0.034953419119119644,
            -0.05312494561076164,
            -0.017889587208628654,
            -0.004894288722425699,
            -0.033931031823158264,
            0.029964352026581764,
            0.02003846876323223,
            -0.009065276011824608,
            0.02406766638159752,
            -0.031549062579870224,
            -0.030869999900460243,
            0.003989752382040024,
            -0.010990887880325317,
            -0.007745873183012009,
            -0.04091789200901985,
            -0.002174761611968279,
            -0.015292616561055183,
            0.050049588084220886,
            0.024206828325986862,
            0.00911898072808981,
            0.007076725829392672,
            -0.024817995727062225,
            -0.0422658771276474,
            -0.003194103017449379,
            -0.042595598846673965,
            0.011625265702605247,
            0.04725896194577217,
            0.022747037932276726,
            -0.0011618786957114935,
            -0.009177236817777157,
            -0.007889064028859138,
            -0.001192697905935347,
            -0.044682398438453674,
            0.023308146744966507,
            -0.028745247051119804,
            0.0006737190415151417,
            -0.028392845764756203,
            0.03087177872657776,
            0.004519967827945948,
            -0.021375978365540504,
            -0.005401739850640297,
            -0.008716019801795483,
            0.008710539899766445,
            0.02833988517522812,
            -0.029038839042186737,
            -0.030597014352679253,
            0.034667544066905975,
            -0.016879195347428322,
            -0.008743180893361568,
            -0.043639760464429855,
            0.02043515257537365,
            -0.015413708984851837,
            0.025270454585552216,
            -0.02781038172543049,
            -0.008198566734790802,
            0.028630925342440605,
            -0.0017387309344485402,
            -0.029282215982675552,
            -0.010224697180092335,
            0.009147264063358307,
            -0.020680276677012444,
            0.011455054394900799,
            -0.007010083645582199,
            -0.02121499739587307,
            0.001267787767574191,
            0.04014348238706589,
            -0.03129902482032776,
            -0.0034880454186350107,
            -0.010125454515218735,
            -0.03723679482936859,
            0.005262985359877348,
            0.048664845526218414,
            -0.008977890945971012,
            -0.01870664581656456,
            0.04079302027821541,
            -0.02765301801264286,
            -0.010757194831967354,
            0.027195096015930176,
            -0.01921636238694191,
            -0.0003609792038332671,
            0.030767252668738365,
            -0.004925962537527084,
            -0.01524069532752037,
            0.031993478536605835,
            0.002796804765239358,
            0.008286887779831886,
            -0.02817087434232235,
            0.012401649728417397,
            0.03150636702775955,
            -0.03319352865219116,
            -0.021760357543826103,
            -0.010725514031946659,
            -0.009411877952516079,
            -0.006597471423447132,
            0.007586842868477106,
            -0.0011991902720183134,
            0.00971316173672676,
            0.023456398397684097,
            -0.033278848975896835,
            0.004618498962372541,
            -0.006704192142933607,
            -0.012900639325380325,
            0.03153374791145325,
            -0.04302014783024788,
            -0.006087073124945164,
            -0.027737660333514214,
            -0.007442993577569723,
            -0.06235913932323456,
            -0.020053396001458168,
            -0.0013375383568927646,
            0.0066103083081543446,
            0.034151799976825714,
            0.02509893663227558,
            0.02628001756966114,
            0.051575470715761185,
            -0.016577010974287987,
            0.009234027937054634,
            0.01873140037059784,
            0.016677306964993477,
            -0.029655151069164276,
            -0.007629299070686102,
            0.030511362478137016,
            -0.02813655510544777,
            -0.0015494718682020903,
            0.032851770520210266,
            0.011985493823885918,
            -0.02455575205385685,
            0.01823028177022934,
            0.022082719951868057,
            -0.016204925253987312,
            0.003413986414670944,
            0.02060406468808651,
            0.002491891849786043,
            0.036677539348602295,
            -0.017890729010105133,
            -0.04751071333885193,
            -0.030386729165911674,
            0.01921122521162033,
            -0.025016631931066513,
            0.0034838702995330095,
            0.052194688469171524,
            -0.0149833457544446,
            0.0037392403464764357,
            0.028173748403787613,
            -0.022580552846193314,
            -0.007640306372195482,
            0.0070634568110108376,
            0.011178351938724518,
            -0.04964892566204071,
            -0.02926536276936531,
            0.008723195642232895,
            -0.014333426021039486,
            0.013452749699354172,
            -0.007044294383376837,
            0.01643843576312065,
            0.011144074611365795,
            0.039763193577528,
            -0.01359100267291069,
            0.01895831897854805,
            -0.021270010620355606,
            -0.024119557812809944,
            0.0036097955889999866,
            0.02952606976032257,
            -0.009712858125567436,
            -0.01000030804425478,
            -0.023749517276883125,
            0.01323284674435854,
            0.02560282126069069,
            0.012721202336251736,
            0.01409018225967884,
            0.016810420900583267,
            -0.0068584284745156765,
            -0.0020670013036578894,
            0.02078043669462204,
            0.026051444932818413,
            0.008448421955108643,
            0.02365187183022499,
            -0.0034883671905845404,
            0.0014234816189855337,
            -0.022964300587773323,
            0.0015059449942782521,
            -0.02273939736187458,
            0.031011532992124557,
            -0.028024667873978615,
            0.020696835592389107,
            0.006802514661103487,
            0.03718459978699684,
            -0.02863006293773651,
            0.036831945180892944,
            -0.016183195635676384,
            -0.027841269969940186,
            -0.019984230399131775,
            -0.009568942710757256,
            0.030080541968345642,
            -0.031044797971844673,
            -0.05180473253130913,
            0.025893453508615494,
            0.010198901407420635,
            0.013893838040530682,
            -0.03174673020839691,
            -0.003366577671840787,
            0.032198332250118256,
            -0.03856242448091507,
            -0.017379550263285637,
            0.01585998199880123,
            0.06571736186742783,
            0.014208766631782055,
            -0.005212890449911356,
            -0.009798409417271614,
            -0.004101394675672054,
            -0.019762614741921425,
            0.01926538161933422,
            -0.018116749823093414,
            -0.012051044963300228,
            -0.01134735718369484,
            -0.01608465611934662,
            0.013299524784088135,
            0.01916760765016079,
            -0.02859196998178959,
            0.02508140541613102,
            0.02609017863869667,
            -0.00927075743675232,
            -0.006008117459714413,
            0.025570150464773178,
            -0.02415263094007969,
            -0.021921666339039803,
            0.0181912612169981,
            -0.02692091464996338,
            0.0034121964126825333,
            0.0039647906087338924,
            0.026256093755364418,
            0.040910832583904266,
            0.020302701741456985,
            0.031867701560258865,
            -0.01205773837864399,
            -0.008299797773361206,
            0.015017246827483177,
            -0.03737090900540352,
            0.0002938459801953286,
            -0.01897580549120903,
            -0.00995329488068819,
            0.010490131564438343,
            0.023692991584539413,
            -0.03851395845413208,
            -0.006841402035206556,
            -0.023149607703089714,
            -0.013149235397577286,
            0.01141307782381773,
            -0.023761294782161713,
            0.02790689654648304,
            -0.01662219688296318,
            -0.03453497588634491,
            0.0055488902144134045,
            -0.008455035276710987,
            -0.0015357121592387557,
            0.022098582237958908,
            -0.0013818630250170827,
            0.010511848144233227,
            -0.025668751448392868,
            0.030455032363533974,
            -0.05191811919212341,
            -0.017902091145515442,
            -0.002853630343452096,
            -0.01504611223936081,
            0.02810211293399334,
            0.002679460449144244,
            -0.019445672631263733,
            -0.03222724795341492,
            -0.03884721174836159,
            0.04490578919649124,
            0.019502677023410797,
            0.002242768183350563,
            0.02499162033200264,
            -0.021447664126753807,
            0.02819022722542286,
            -0.009760313667356968,
            -0.031366001814603806,
            0.017977174371480942,
            0.005891792941838503,
            0.005445342976599932,
            -0.014748960733413696,
            -0.013619416393339634,
            -0.015508558601140976,
            0.032685812562704086,
            -0.03968994691967964,
            -0.008661639876663685,
            -0.020858831703662872,
            0.009275330230593681,
            -0.04175975173711777,
            0.008855658583343029,
            0.02956283651292324,
            -0.05055157095193863,
            -0.003006262704730034,
            0.03774493560194969,
            0.018278943374753,
            -0.00676285894587636,
            0.02123742736876011,
            -0.003817591117694974,
            -0.027768662199378014,
            0.003334769979119301,
            0.03932279720902443,
            0.05695199966430664,
            0.015434120781719685,
            -0.060832638293504715,
            -0.022415677085518837,
            -0.02931823767721653,
            0.021065736189484596,
            0.019172322005033493,
            0.02064782939851284,
            -0.02493712306022644,
            0.008496884256601334,
            0.020523380488157272,
            -0.008262364193797112,
            0.012737059034407139,
            0.015065761283040047,
            -0.028028246015310287,
            0.008565640076994896,
            0.006494326051324606,
            0.022054890170693398,
            -0.015432354062795639,
            -0.0006361340638250113,
            0.03881510719656944,
            -0.0032726165372878313,
            0.04011719673871994,
            0.010363445617258549,
            0.0054171206429600716,
            0.038291122764348984,
            -0.002102627418935299,
            0.01568455621600151,
            -0.0167243555188179,
            0.0138552812859416,
            -0.011165284551680088,
            0.03144633769989014,
            0.029177652671933174,
            -0.0008638981380499899,
            -0.02014787122607231,
            -0.006979110650718212,
            0.02388887107372284,
            -0.008734936825931072,
            0.006999574601650238,
            0.01561699714511633,
            -0.02478211745619774,
            0.0018177159363403916,
            -0.005248668137937784,
            -0.01678353361785412,
            0.0188713688403368,
            -0.024541528895497322,
            -0.0012749212328344584,
            -0.020265527069568634,
            0.03208374232053757,
            -0.041543930768966675,
            -0.012785442173480988,
            0.04097514599561691,
            0.021086689084768295,
            0.0151906106621027,
            -0.0318402424454689,
            -0.010047221556305885,
            0.0011694749118760228,
            -0.029865970835089684,
            0.006910518743097782,
            -0.005369111429899931,
            -0.009675673209130764,
            0.015894601121544838,
            0.023967470973730087,
            -0.0029285370837897062,
            -0.04866359755396843,
            0.035633184015750885,
            -0.013069501146674156,
            0.013958921656012535,
            0.02884780429303646,
            -0.024995705112814903,
            0.010711661539971828,
            0.0038344247732311487,
            -0.01281843613833189,
            -0.027706889435648918,
            -0.028014225885272026,
            -0.06427336484193802,
            -0.008298816159367561,
            0.008780469186604023,
            0.023998353630304337,
            0.016232838854193687,
            0.003988079261034727,
            7.82539282226935e-05,
            0.004397003911435604,
            0.003583362326025963,
            -0.0030118755530565977,
            0.022914238274097443,
            -0.000697171373758465,
            -0.02273777313530445,
            0.0006232807063497603,
            0.012861481867730618,
            -0.010096531361341476,
            -0.005615935195237398,
            0.0010849892860278487,
            0.00903330184519291,
            -0.013457908295094967,
            -0.03485570102930069,
            -0.015615086071193218,
            0.01368748489767313,
            0.006487786304205656,
            0.04125654697418213,
            0.005666302517056465,
            0.03347635641694069,
            0.00012419550330378115,
            -0.03296200931072235,
            -0.01479699183255434,
            0.0522364005446434,
            -0.03243168070912361,
            -0.016566436737775803,
            0.014730232767760754,
            0.01738218404352665,
            0.025701090693473816,
            -0.025660984218120575,
            -0.017413100227713585,
            0.03383813425898552,
            -0.015741731971502304,
            -0.029478782787919044,
            0.00860519614070654,
            -0.01177289243787527,
            -0.032875195145606995,
            0.0014959130203351378,
            -0.033736702054739,
            0.03238193690776825,
            0.013455374166369438,
            0.011469914577901363,
            0.016438672319054604,
            0.029460188001394272,
            -0.013403856195509434,
            0.022783810272812843,
            0.008384069427847862,
            0.017454419285058975,
            0.03999840468168259,
            0.017552953213453293,
            0.01261448860168457,
            0.0013381341705098748,
            0.0052140261977910995,
            -0.042854271829128265,
            -0.034914810210466385,
            0.02285606786608696,
            0.006885013543069363,
            0.008270413614809513,
            0.022389274090528488,
            0.018670562654733658,
            -0.02542848512530327,
            0.0188668854534626,
            -0.05660569667816162,
            0.04713965952396393,
            -0.03297821804881096,
            -0.014862939715385437,
            -0.024037810042500496,
            -0.022148555144667625,
            0.011926177889108658,
            0.00899698305875063,
            0.004200902301818132,
            -0.02818232774734497,
            -0.007727109827101231,
            0.014233079738914967,
            0.017434395849704742,
            -0.014001015573740005,
            0.012180379591882229,
            -0.005949303973466158,
            0.013646275736391544,
            -0.010505951009690762,
            0.028275541961193085,
            0.037531040608882904,
            0.01223955862224102,
            0.0032311456743627787,
            -0.012810812331736088,
            -0.01198440883308649,
            -0.010997682809829712,
            -0.0014191108057275414,
            0.016469204798340797,
            -0.016763903200626373,
            0.009886084124445915,
            -0.005787338130176067,
            -0.015811141580343246,
            -0.011077373288571835,
            0.01406087726354599,
            0.011374699883162975,
            -0.03515465557575226,
            0.02445533499121666,
            -0.009564469568431377,
            0.01946330815553665,
            -0.03555605560541153,
            -0.04024527594447136,
            9.384295844938606e-05,
            -0.03335986286401749,
            0.026714012026786804,
            0.02227243036031723,
            0.03211446851491928,
            -0.023553885519504547,
            -0.018514901399612427,
            0.046769097447395325,
            0.013228659518063068,
            -0.004007797222584486,
            0.04204579442739487,
            0.0036771229933947325,
            0.013203756883740425,
            -0.023461900651454926,
            0.010280757211148739,
            0.02435491234064102,
            0.007947618141770363,
            0.037732042372226715,
            0.02945041097700596,
            -0.004820852540433407,
            -0.010995079763233662,
            -0.02262476086616516,
            -0.014721868559718132,
            -0.007117526605725288,
            0.027475502341985703,
            0.023789821192622185,
            0.031628042459487915,
            -0.010114957578480244,
            0.02459293231368065,
            0.02027948759496212,
            0.029313361272215843,
            0.024798216298222542,
            0.01911775767803192,
            5.0653434300329536e-05,
            -0.029323942959308624,
            0.03002205304801464,
            -0.010739842429757118,
            -0.037534043192863464,
            -0.014608428813517094,
            -0.0231697428971529,
            -0.010670083574950695,
            0.02958712726831436,
            -0.015087511390447617,
            -0.003325393423438072,
            0.01977202482521534,
            -0.0180949904024601,
            -0.0227753184735775,
            0.007654515560716391,
            -0.04137081280350685,
            0.016932884231209755,
            -0.03510946407914162,
            0.02744240313768387,
            -0.06811632215976715,
            0.013714387081563473,
            0.01613037846982479,
            0.008246456272900105,
            0.021679986268281937,
            -0.018428761512041092,
            -0.008690261282026768,
            -0.012837644666433334,
            -0.027119001373648643,
            0.024686532095074654,
            0.01024209801107645,
            0.00015877038822509348,
            -0.016808444634079933,
            0.00912756659090519,
            -0.03334809094667435,
            -0.06272182613611221,
            0.018006084486842155,
            -0.00489715626463294,
            0.04535869508981705,
            0.018421001732349396,
            0.013759992085397243,
            -0.006473355460911989,
            0.003531425027176738,
            0.0018045674078166485,
            -0.026443522423505783,
            0.03703049570322037,
            0.006208506878465414,
            -0.013960901647806168,
            0.023139426484704018,
            -0.002661291044205427,
            -0.03419402614235878,
            0.0034253443591296673,
            0.007815524935722351,
            -0.0004374281852506101,
            -0.005655124317854643,
            -0.013950776308774948,
            0.07987263798713684,
            0.01672564446926117,
            0.010891580022871494,
            -0.024564171209931374,
            0.009624089114367962,
            0.014349611476063728,
            -0.03374124690890312,
            0.007725151721388102,
            0.002915747929364443,
            -0.026629144325852394,
            -0.0180974081158638,
            0.013323639519512653,
            0.003533786628395319,
            -0.003606628393754363,
            0.0014166072942316532,
            0.007100402377545834,
            -0.021697871387004852,
            -0.003229554509744048,
            0.008245958015322685,
            0.02565549872815609,
            -0.03761449083685875,
            0.015449212864041328,
            0.0037755074445158243,
            0.031972452998161316,
            0.027636215090751648,
            -0.015977147966623306,
            -0.035628847777843475,
            0.001915997825562954,
            -0.019503582268953323,
            0.018694648519158363,
            0.024552596732974052,
            -0.00828789733350277,
            -0.002472490072250366,
            -0.012503359466791153,
            0.0009018348064273596,
            0.003661608323454857,
            -0.0021832571364939213,
            0.006866110488772392,
            0.00191896827891469,
            0.004309522919356823,
            -0.011906648986041546,
            -0.007411597762256861,
            0.0116770900785923,
            -0.018874788656830788,
            0.019804587587714195,
            0.002331178868189454,
            -0.0005029625026509166,
            -0.02921449765563011,
            0.03296830132603645,
            0.006015617400407791,
            -0.05837082117795944,
            -0.03219649940729141,
            0.030337100848555565,
            -0.02250288613140583,
            0.0021953030955046415,
            -0.07298740744590759,
            -0.0019581569358706474,
            0.009364944882690907,
            -0.014750617556273937,
            -0.018665267154574394,
            0.021401075646281242,
            -0.04482461139559746,
            -0.013563658110797405,
            -0.04284198582172394,
            -0.023394295945763588,
            -0.015485401265323162,
            0.015448816120624542,
            0.0014188432833179832,
            0.05877048149704933,
            -0.06353563815355301,
            0.029659390449523926,
            -0.0005358332418836653,
            0.021341757848858833,
            -0.014608757570385933,
            0.017041243612766266,
            -0.023167042061686516,
            0.032128289341926575,
            -0.028519591316580772,
            -0.0017783140065148473,
            -0.006514306645840406,
            0.019362913444638252,
            -0.008317308500409126,
            -0.00557068781927228,
            0.008910244330763817,
            0.01397828757762909,
            -0.0225916039198637,
            -0.002431922359392047,
            0.03411740064620972,
            0.013156252913177013,
            -0.0024736393243074417,
            -0.004750885535031557,
            -0.018082408234477043,
            -0.0030823328997939825,
            0.023570461198687553,
            -0.046979162842035294,
            0.016568446531891823,
            0.026490330696105957,
            0.0005101868300698698,
            -0.00046171658323146403,
            0.00525051262229681,
            0.032934755086898804,
            0.0026439251378178596,
            -0.00202668528072536,
            -0.013429740443825722,
            -0.024446766823530197,
            0.020953495055437088,
            0.033423978835344315,
            0.002802349394187331,
            0.03476778417825699,
            -0.027733391150832176,
            -0.0006709021399728954
        ],
        [
            0.018502146005630493,
            -0.004377351608127356,
            -0.013098341412842274,
            0.013013465330004692,
            0.017317064106464386,
            0.02930488996207714,
            0.012746763415634632,
            0.03343170881271362,
            0.015846634283661842,
            0.02829059399664402,
            0.021203819662332535,
            9.247634443454444e-05,
            -0.02213156223297119,
            0.019603241235017776,
            -0.010219311341643333,
            -0.029474126175045967,
            -0.02118801511824131,
            -0.02857309766113758,
            0.03929569199681282,
            -0.012864531017839909,
            0.04726613685488701,
            0.02889430522918701,
            0.020875273272395134,
            -0.016446944326162338,
            0.009562096558511257,
            -0.009490947239100933,
            0.007352928631007671,
            0.007119683548808098,
            -0.019931742921471596,
            0.03307773917913437,
            -0.02269165962934494,
            -0.02924080565571785,
            -0.022905917838215828,
            0.02690216526389122,
            -0.01690779998898506,
            -0.007893675938248634,
            -0.007425677962601185,
            0.013010774739086628,
            -0.02361089549958706,
            -0.006648540962487459,
            0.009515082463622093,
            0.04583783820271492,
            -0.042934756726026535,
            0.012185322120785713,
            -0.0410270094871521,
            0.017005430534482002,
            0.0234683845192194,
            -0.04838583245873451,
            -0.01525106467306614,
            0.025551846250891685,
            0.018607614561915398,
            -0.01501640398055315,
            -0.0066385893151164055,
            -0.02609790489077568,
            -0.03001285530626774,
            -0.019848110154271126,
            -0.03153550997376442,
            0.007975212298333645,
            -0.021861445158720016,
            -0.0322260856628418,
            0.018778527155518532,
            -0.020076116546988487,
            -0.027862483635544777,
            -0.0007314176764339209,
            0.003989967051893473,
            -0.00011964770237682387,
            -0.01716792583465576,
            0.02367514744400978,
            0.01896658167243004,
            -0.025122983381152153,
            0.009257661178708076,
            0.0140076894313097,
            0.03918439522385597,
            0.00891469419002533,
            -0.034063391387462616,
            0.021498780697584152,
            -0.022866301238536835,
            0.0057946485467255116,
            0.005320134572684765,
            -0.03574278950691223,
            -0.016398195177316666,
            -0.04504275321960449,
            8.129548223223537e-05,
            -0.03156404197216034,
            5.956816312391311e-05,
            -0.012469635345041752,
            0.007103119045495987,
            -0.006448102183640003,
            -0.021831531077623367,
            0.024983221665024757,
            -0.0157118309289217,
            -0.006477097515016794,
            -0.018873823806643486,
            0.035688616335392,
            0.018923107534646988,
            -0.0054173157550394535,
            -0.012175328098237514,
            0.008501671254634857,
            -0.04792211577296257,
            0.031224681064486504,
            0.027586527168750763,
            -0.05484182387590408,
            -0.022765936329960823,
            0.025584839284420013,
            -0.011988315731287003,
            -0.0046370141208171844,
            0.03570728003978729,
            0.0029507894068956375,
            0.0023990501649677753,
            0.02234409563243389,
            0.029203832149505615,
            0.03789602592587471,
            -0.019849050790071487,
            0.003165996866300702,
            0.042606886476278305,
            0.008577559143304825,
            -0.021478207781910896,
            0.030496910214424133,
            0.015093912370502949,
            -0.01921190693974495,
            0.017263062298297882,
            -0.006958870682865381,
            0.05936231464147568,
            0.020939402282238007,
            -0.022928113117814064,
            -0.02129330113530159,
            0.002364062936976552,
            -0.006824677810072899,
            -0.03565691411495209,
            0.015376036055386066,
            0.01918693445622921,
            0.0048631466925144196,
            -0.09253444522619247,
            0.04847647249698639,
            0.028806865215301514,
            -0.01136595569550991,
            -0.009926285594701767,
            -0.008060500957071781,
            0.02509424276649952,
            0.019326789304614067,
            0.0011794454185292125,
            0.021740028634667397,
            -0.03813008591532707,
            -0.02685842476785183,
            0.02284487523138523,
            -0.011136858724057674,
            0.029667818918824196,
            0.016337411478161812,
            0.028316203504800797,
            -0.0032018532510846853,
            0.025796959176659584,
            -0.05363960191607475,
            -0.029894353821873665,
            0.0012256967602297664,
            -0.02755867876112461,
            0.003994564525783062,
            -0.030378812924027443,
            -0.0036182638723403215,
            0.007779804058372974,
            0.030097365379333496,
            0.01283198781311512,
            0.01588405855000019,
            -0.0031444558408111334,
            -0.025238219648599625,
            -0.027349475771188736,
            0.014742564409971237,
            -0.020178543403744698,
            0.018710045143961906,
            0.017620988190174103,
            -0.025228839367628098,
            0.016949286684393883,
            -0.03200399875640869,
            0.05370066687464714,
            -0.007845567539334297,
            -0.007652834989130497,
            -0.049375612288713455,
            -0.004158989991992712,
            0.011738908477127552,
            0.013390879146754742,
            0.002949238521978259,
            0.03243942931294441,
            0.03552025929093361,
            -0.010847690515220165,
            -0.0318145677447319,
            0.023396428674459457,
            0.014985943213105202,
            -0.036988016217947006,
            0.002825955394655466,
            0.016676073893904686,
            0.018236517906188965,
            0.00023020198568701744,
            -0.002549236873164773,
            0.05108865723013878,
            0.015113486908376217,
            0.008122209459543228,
            0.001121399225667119,
            0.01085332315415144,
            0.01592685841023922,
            -0.010666332207620144,
            0.010974002070724964,
            -0.015010926872491837,
            -0.0012925623450428247,
            0.016034111380577087,
            0.026296330615878105,
            0.009850045666098595,
            -0.024743303656578064,
            -0.0005205116467550397,
            -0.06382569670677185,
            0.02079836279153824,
            0.003672192571684718,
            -0.026143617928028107,
            -0.003657752647995949,
            -0.028639545664191246,
            -0.0012124100467190146,
            0.005915143061429262,
            -0.0036607328802347183,
            -0.031541574746370316,
            0.022396937012672424,
            -0.023922637104988098,
            -0.00916968658566475,
            0.02330770157277584,
            0.03776080533862114,
            -0.00128080602735281,
            -0.024153895676136017,
            0.025743240490555763,
            0.015208989381790161,
            0.03236978501081467,
            -0.00433660252019763,
            0.019354380667209625,
            0.04050327092409134,
            -0.027191825211048126,
            0.012080063112080097,
            0.007034669630229473,
            -0.028562776744365692,
            0.02982785552740097,
            0.036964308470487595,
            -0.030518973246216774,
            0.020935945212841034,
            0.005073919426649809,
            -0.040233537554740906,
            -0.008115077391266823,
            -0.024063274264335632,
            -0.011594723910093307,
            0.0035930986050516367,
            0.028514768928289413,
            0.005876064300537109,
            -0.03022327646613121,
            0.029201731085777283,
            0.020008716732263565,
            0.01835341565310955,
            0.02607499249279499,
            -0.022243661805987358,
            0.011015920899808407,
            -0.012215269729495049,
            -0.017581447958946228,
            -0.02584819309413433,
            0.025418084114789963,
            0.035497404634952545,
            -0.0043215760961174965,
            -0.01972285658121109,
            -0.020638449117541313,
            0.0057404059916734695,
            0.019505640491843224,
            -0.02872198447585106,
            0.0016991646261885762,
            -0.020727289840579033,
            0.031083296984434128,
            -0.029758762568235397,
            0.0069411820732057095,
            -0.0017998369876295328,
            -0.006096038036048412,
            -0.024804193526506424,
            -0.010055994614958763,
            -0.009042518213391304,
            0.013294384814798832,
            0.03501734137535095,
            0.013519150204956532,
            -0.0029528343584388494,
            -0.018260300159454346,
            -0.026867862790822983,
            0.03346455842256546,
            -0.000820270914118737,
            -0.02536826953291893,
            -0.022347161546349525,
            -0.0014052873011678457,
            0.002611178206279874,
            0.0011214818805456161,
            0.019321464002132416,
            -0.033477190881967545,
            -0.032615985721349716,
            0.008858075365424156,
            -0.03330535814166069,
            0.01721024513244629,
            -0.014686868526041508,
            0.012685396708548069,
            0.002090141177177429,
            -0.023778602480888367,
            0.004929813556373119,
            -0.018856167793273926,
            0.010781570337712765,
            -0.016249563544988632,
            0.010054824873805046,
            0.023388860747218132,
            -0.0036557321436703205,
            0.02444171905517578,
            -0.026332877576351166,
            -0.0216447152197361,
            -0.042699240148067474,
            -0.019066696986556053,
            -0.06311028450727463,
            -0.005543581675738096,
            0.037329137325286865,
            -0.0064140367321670055,
            0.022788822650909424,
            -0.029046446084976196,
            -0.0396069660782814,
            -0.03485332429409027,
            -0.0023334803991019726,
            -0.023096762597560883,
            -0.011981458403170109,
            0.02084396407008171,
            0.025184467434883118,
            0.0030695151071995497,
            -0.020251059904694557,
            0.03125854209065437,
            0.02474828250706196,
            0.02006826177239418,
            0.008719495497643948,
            -0.0036100568249821663,
            -0.023929817602038383,
            -0.012647205963730812,
            0.011520255357027054,
            -0.006799125112593174,
            -0.011523043736815453,
            0.031069142743945122,
            -0.01897863857448101,
            -0.032728083431720734,
            0.010884520597755909,
            -0.012084977701306343,
            -0.0021321873646229506,
            -0.01694635860621929,
            0.022075824439525604,
            -0.022116156294941902,
            0.026501663029193878,
            0.006393712479621172,
            0.05723400041460991,
            -0.018441278487443924,
            -0.034863922744989395,
            0.03646266460418701,
            -0.034661564975976944,
            0.000996511778794229,
            -0.041509099304676056,
            0.0055197617039084435,
            0.02581598423421383,
            -0.002709741936996579,
            0.023355836048722267,
            0.013726652599871159,
            0.011168066412210464,
            -0.02669660560786724,
            0.009311429224908352,
            -0.0008845112752169371,
            -0.014804675243794918,
            -0.034829121083021164,
            -0.0062489802949130535,
            0.022575367242097855,
            0.003197870682924986,
            0.017718294635415077,
            -0.006070678122341633,
            0.020997557789087296,
            0.00013208354357630014,
            0.03902788832783699,
            -0.013454816304147243,
            0.03933197259902954,
            -0.0069504063576459885,
            0.024192649871110916,
            -0.03594464808702469,
            0.025172706693410873,
            0.025820577517151833,
            0.021578529849648476,
            -0.025112852454185486,
            0.06435030698776245,
            -0.02863972634077072,
            -0.014757934957742691,
            -0.014193078503012657,
            -0.05828573554754257,
            0.016615577042102814,
            0.011004900559782982,
            -0.017985431477427483,
            0.004611746873706579,
            0.010003188624978065,
            -0.017657944932579994,
            -0.01764344424009323,
            0.02133435755968094,
            -0.014926876872777939,
            -0.008634058758616447,
            -0.026182085275650024,
            0.008769242092967033,
            -0.0015625407686457038,
            -0.00800412893295288,
            -0.002752323169261217,
            -0.05251974239945412,
            0.003310679690912366,
            -0.02311553619801998,
            -0.0211949422955513,
            -0.007719856221228838,
            -0.007000235840678215,
            -0.013943071477115154,
            -0.04781998693943024,
            0.01961609534919262,
            0.027392307296395302,
            -0.04210984706878662,
            -0.021528515964746475,
            -0.0007532038143835962,
            0.03354928269982338,
            0.07415561378002167,
            0.006123082712292671,
            0.015621073544025421,
            -0.012665015645325184,
            -0.005346979945898056,
            -0.011559786275029182,
            0.012169658206403255,
            0.005237287376075983,
            -0.004448873922228813,
            0.006361180450767279,
            -0.05115580931305885,
            0.024421440437436104,
            0.005532256793230772,
            -0.02499978616833687,
            0.023677440360188484,
            -0.006713779643177986,
            -0.023600924760103226,
            -0.030942421406507492,
            0.02099355310201645,
            0.05071226879954338,
            -0.0011748663382604718,
            0.026609718799591064,
            -0.02256162464618683,
            -0.046857092529535294,
            0.017778178676962852,
            0.014399060979485512,
            0.03777025267481804,
            0.02135571464896202,
            0.0035741825122386217,
            -0.0481780581176281,
            0.011191660538315773,
            -0.004918042104691267,
            0.020992886275053024,
            0.0808771625161171,
            0.004439511802047491,
            -0.018006213009357452,
            -0.019984567537903786,
            -0.0023235671687871218,
            0.027196967974305153,
            0.03173302859067917,
            -0.01904347352683544,
            -0.006806584540754557,
            -0.008734558708965778,
            -0.0060495114885270596,
            -0.028973689302802086,
            -0.009434781968593597,
            -0.001995069906115532,
            -0.016826309263706207,
            -0.05162573605775833,
            -0.035857681185007095,
            -0.016651013866066933,
            0.025296799838542938,
            0.015155261382460594,
            0.00023903987312223762,
            -0.004647204652428627,
            0.031698036938905716,
            -0.012138182297348976,
            0.011793469078838825,
            0.004677198361605406,
            -0.019472723826766014,
            0.050384461879730225,
            -0.03734836354851723,
            -0.032415200024843216,
            -0.008991648443043232,
            -0.009238248690962791,
            0.0599721223115921,
            0.006029795855283737,
            0.03478633612394333,
            -0.014134381897747517,
            -0.01948690228164196,
            -0.016559135168790817,
            0.012848214246332645,
            -0.016830459237098694,
            0.026523470878601074,
            -0.00022762890148442239,
            -0.036890532821416855,
            0.021918265148997307,
            0.018873877823352814,
            0.012100360356271267,
            -0.004110467620193958,
            -0.041150134056806564,
            -0.015700381249189377,
            0.009695734828710556,
            0.01249624416232109,
            0.0008839740767143667,
            0.009927892126142979,
            0.02531372383236885,
            -0.015774909406900406,
            0.0359555259346962,
            -0.007463446818292141,
            -0.0034972350113093853,
            0.021529413759708405,
            0.025268757715821266,
            0.005986383650451899,
            0.014786355197429657,
            0.008088751696050167,
            0.010774281807243824,
            0.0026711185928434134,
            -0.015383871272206306,
            -0.03224324434995651,
            -0.022946543991565704,
            0.02986263670027256,
            -0.03198210895061493,
            0.011740808375179768,
            0.018929706886410713,
            -0.0009466166375204921,
            0.011708815582096577,
            -0.012564067728817463,
            -0.022812720388174057,
            0.012871242128312588,
            0.01557240355759859,
            -0.003167412942275405,
            0.07769704610109329,
            0.011781735345721245,
            -0.007898355834186077,
            0.004265396390110254,
            0.01600470021367073,
            -0.048808205872774124,
            0.005401408765465021,
            -0.003142234869301319,
            0.035558849573135376,
            -0.0021977934520691633,
            0.016430508345365524,
            0.014925122261047363,
            0.016773376613855362,
            0.01974196545779705,
            0.020711906254291534,
            0.025730393826961517,
            0.031021689996123314,
            0.015351569280028343,
            0.011029754765331745,
            0.04332521930336952,
            -0.034455545246601105,
            -0.013371629640460014,
            0.004161097574979067,
            -0.04097266495227814,
            0.03132318705320358,
            0.019256126135587692,
            -0.017743635922670364,
            -0.005820244085043669,
            0.031237030401825905,
            0.0007046933751553297,
            0.0007936363108456135,
            -0.020216070115566254,
            0.014359344728291035,
            -0.022871414199471474,
            -0.001342711504548788,
            -0.01476194802671671,
            0.029506610706448555,
            0.02317154034972191,
            -0.026266980916261673,
            -0.03562810644507408,
            -0.012915963307023048,
            0.02237745374441147,
            0.04403405636548996,
            -0.033096179366111755,
            -0.017145363613963127,
            0.015413208864629269,
            0.023036861792206764,
            -0.011628419160842896,
            -0.002327290363609791,
            -0.008810017257928848,
            -0.029985781759023666,
            0.00860291626304388,
            -0.036360811442136765,
            0.023680945858359337,
            0.009109684266149998,
            -0.028551310300827026,
            -0.04481153190135956,
            -0.007195478770881891,
            -0.005023356527090073,
            -0.023173581808805466,
            -0.03262564539909363,
            0.008589126169681549,
            0.008666355162858963,
            -0.02567421831190586,
            0.024039918556809425,
            0.0178714357316494,
            0.025850921869277954,
            0.0017977438401430845,
            0.02812926098704338,
            0.001551261288113892,
            0.021579017862677574,
            -0.007427385542541742,
            0.007846000604331493,
            -0.028355387970805168,
            0.02570229023694992,
            -0.044136013835668564,
            0.02612871117889881,
            -0.012537272647023201,
            0.02198462374508381,
            -0.01573118008673191,
            -0.007561958394944668,
            -0.024204015731811523,
            0.05365433171391487,
            -0.0015242119552567601,
            -0.03356282040476799,
            0.033788204193115234,
            -0.028750402852892876,
            0.01170189306139946,
            0.0006575188599526882,
            -0.019790077582001686,
            0.018944041803479195,
            0.013900378718972206,
            -0.0008847478311508894,
            -0.02131272852420807,
            -0.052003875374794006,
            0.010775025002658367,
            0.01696537435054779,
            0.017171278595924377,
            0.010662423446774483,
            0.0006562311318702996,
            -0.027269966900348663,
            0.008732805959880352,
            -0.02337612584233284,
            -0.007466163951903582,
            0.015117268078029156,
            -0.023742347955703735,
            -0.008321983739733696,
            -0.019025588408112526,
            0.016328847035765648,
            -0.016261134296655655,
            -0.0015323881525546312,
            -0.036666300147771835,
            -0.060823213309049606,
            -0.009935001842677593,
            -0.037792425602674484,
            -0.013460437767207623,
            0.026527447625994682,
            0.010786277242004871,
            0.009931722655892372,
            0.037879012525081635,
            -0.025978868827223778,
            -0.001869755913503468,
            0.009414623491466045,
            -0.03102172166109085,
            0.0033179062884300947,
            -0.004286892246454954,
            -0.0229189395904541,
            -0.022751104086637497,
            -0.04417867958545685,
            -0.010612962767481804,
            0.04977439343929291,
            0.032236356288194656,
            -0.04316791146993637,
            0.009149919264018536,
            0.01478759665042162,
            -0.01708178222179413,
            0.025642074644565582,
            0.0019303524168208241,
            0.007496444508433342,
            -0.0299160685390234,
            -0.057406775653362274,
            0.031155316159129143,
            0.006563306786119938,
            -0.006022498942911625,
            0.01918686367571354,
            -0.03480180352926254,
            -0.02807903289794922,
            0.009603563696146011,
            -0.012745145708322525,
            0.00993903074413538,
            -0.002265745075419545,
            0.04034695029258728,
            -0.019198672845959663,
            -0.016939613968133926,
            0.01776197738945484,
            -0.00024102255702018738,
            -0.012654487043619156,
            0.019044026732444763,
            -0.019093912094831467,
            -0.008348445408046246,
            0.03540517017245293,
            -0.03373341262340546,
            -0.03289272263646126,
            -0.002198083559051156,
            0.04252714291214943,
            -0.00293352990411222,
            -0.020144864916801453,
            0.01680527999997139,
            -0.014331025071442127,
            -0.01609809696674347,
            -0.027662163600325584,
            0.035506490617990494,
            0.003311732318252325,
            0.0340350940823555,
            -0.01031807903200388,
            -0.009670039638876915,
            -0.019504303112626076,
            -0.0001563417463330552,
            -0.04125569388270378,
            0.09182602912187576,
            -0.02855241298675537,
            -0.002647405955940485,
            -0.018722381442785263,
            -0.012631460092961788,
            -0.021359117701649666,
            -0.01959287002682686,
            0.0010580105008557439,
            0.010177180171012878,
            -0.0007817715522833169,
            -0.012224143370985985,
            -0.06287084519863129,
            -0.03346709907054901,
            -0.026577668264508247,
            0.016200069338083267,
            -0.02248755842447281,
            -0.029548345133662224,
            -0.02717631682753563,
            0.027292147278785706,
            0.02530457079410553,
            0.00022670289035886526,
            0.007248472422361374,
            0.019580818712711334,
            -0.003880552016198635,
            -0.00979757122695446,
            0.005296236369758844,
            0.00019971630536019802,
            -0.02842038683593273,
            0.015119151212275028,
            -0.02680991217494011,
            -0.012381641194224358,
            -0.020812300965189934,
            0.03327861800789833,
            0.03508404269814491,
            0.005620459094643593,
            -0.01344490796327591,
            0.03901856765151024,
            0.0298873633146286,
            -0.005576572846621275,
            0.030680138617753983,
            0.03961304947733879,
            -0.032603032886981964,
            0.011536382138729095,
            0.011195948347449303,
            -0.029284315183758736,
            0.034332990646362305,
            0.025255128741264343,
            -0.019882773980498314,
            0.05629589408636093,
            0.015735039487481117,
            -0.02345435880124569,
            -0.003383088856935501,
            0.033022843301296234,
            0.021164577454328537,
            0.00946285855025053,
            0.009726902469992638,
            0.060537852346897125,
            -0.022410092875361443,
            0.02672114595770836,
            -0.01946372352540493,
            0.05514071136713028,
            0.02723434381186962,
            0.007871921174228191,
            0.014956309460103512,
            0.014661942608654499,
            0.015513624995946884,
            0.013162963092327118,
            -0.018966728821396828,
            -0.02280965819954872,
            0.008860056288540363,
            0.015580407343804836,
            -0.0034678950905799866,
            0.06411360204219818,
            -0.01578899845480919,
            0.015535195358097553,
            0.0113587761297822,
            -0.0063257585279643536,
            -0.020095061510801315,
            0.015598200261592865,
            -0.02588033862411976,
            -0.02061982825398445,
            -0.024765606969594955,
            0.019607938826084137,
            -0.005839487537741661,
            0.006942802108824253,
            0.019771691411733627,
            -0.009430861100554466,
            0.005862842779606581,
            -0.028386356309056282,
            0.04960710182785988,
            -0.019367985427379608,
            -0.0038444676902145147,
            -0.007023948710411787,
            0.01595211960375309,
            0.027387486770749092,
            0.007106523960828781,
            -0.019535355269908905,
            -0.02070903219282627,
            0.0070199258625507355,
            0.018665431067347527,
            0.01597798615694046,
            -0.01207781583070755,
            -0.030513646081089973,
            0.033144351094961166,
            -0.005451437085866928,
            0.03909100964665413,
            0.02610398270189762,
            0.021571170538663864,
            -0.02725202962756157,
            0.0015229140408337116,
            -0.009038950316607952,
            -0.020004836842417717,
            -0.05751035362482071,
            -0.020209550857543945,
            0.032746851444244385,
            0.0019213455962017179,
            0.026458734646439552,
            -0.010949000716209412,
            0.004786915145814419,
            -0.036753419786691666,
            -0.02572198212146759,
            0.027905691415071487,
            -0.00154406251385808,
            -0.0061372206546366215,
            0.008661423809826374,
            -0.04100893437862396,
            0.0054040513932704926,
            0.05047645419836044,
            -0.006306397262960672,
            -0.052295979112386703,
            0.031745150685310364,
            0.01457785815000534,
            0.008048469200730324,
            0.025150679051876068,
            -0.015354802832007408,
            -0.007978511974215508,
            0.011337926611304283,
            -0.009005813859403133,
            -0.01135477889329195,
            0.02561524696648121,
            0.021289298310875893,
            -0.039429325610399246,
            0.004889078438282013,
            -0.01711089350283146,
            0.03291523456573486,
            0.03207682818174362,
            0.02750164456665516,
            -0.012948703020811081,
            -0.011479593813419342,
            -0.042765263468027115,
            0.009325368329882622,
            -0.018335098400712013,
            0.022186126559972763,
            0.030755553394556046,
            -0.0044746408239007,
            0.01807595230638981,
            -0.051349252462387085,
            -0.02725091762840748,
            -0.0061989459209144115,
            0.01468401588499546,
            -0.03194744884967804,
            -0.01645817421376705,
            -0.026679497212171555,
            -0.008939415216445923,
            0.05157650262117386,
            -0.008188029751181602,
            0.020707568153738976,
            0.03148995712399483,
            0.046917036175727844,
            -0.030061841011047363,
            -0.019502723589539528,
            -0.005712700076401234,
            0.04728247597813606,
            0.007552972994744778,
            -0.00023796512687113136,
            0.015359205193817616,
            0.06841174513101578,
            -0.011369961313903332,
            0.015500216744840145,
            0.0005511732888408005,
            0.015519934706389904,
            0.022294392809271812,
            0.006202917546033859,
            -0.0129393944516778,
            0.025030406191945076,
            -0.020313015207648277,
            0.012611024081707,
            -0.009010844863951206,
            -0.020692402496933937,
            0.04959895834326744,
            -0.010467921383678913,
            -0.007781977765262127,
            -0.009877721779048443,
            0.026320666074752808,
            -0.010814500041306019,
            -0.0009800175903365016,
            0.008135558106005192,
            -0.01303669810295105,
            0.00658218190073967,
            0.04099585860967636,
            -0.0004067016707267612,
            0.017864063382148743,
            -0.0035817092284560204,
            0.014815661124885082,
            0.006174292415380478,
            -0.04103972017765045,
            -0.017221275717020035,
            0.01452712807804346,
            -0.029365040361881256,
            0.015063135884702206,
            0.0017371949506923556,
            0.010770054534077644,
            -0.016932900995016098,
            -0.026106465607881546,
            -0.01907455548644066,
            -0.014266634359955788,
            -0.009575347416102886,
            -0.014156782999634743,
            0.028315840288996696,
            0.013429160229861736,
            0.003880352945998311,
            -0.003435587277635932,
            -0.013713283464312553,
            0.0023383446969091892,
            0.0074349031783640385,
            0.012731439433991909,
            -0.004829144570976496,
            -0.009524505585432053,
            -0.034781984984874725,
            0.04298676922917366,
            -0.009010553359985352,
            0.013340538367629051,
            0.0030674238223582506,
            -0.026311244815587997,
            0.0005100289126858115,
            -0.03120397962629795,
            -0.010296477936208248,
            0.03424221649765968,
            -0.021962970495224,
            0.014017211273312569,
            0.004026064183562994,
            0.025046026334166527,
            -0.002158782444894314,
            -0.030026834458112717,
            0.013218951411545277,
            0.019993826746940613,
            -0.010629117488861084,
            0.017723530530929565,
            -0.010556714609265327,
            -0.009424247778952122,
            -0.010845419950783253,
            0.01985950395464897,
            -0.01807248778641224,
            0.04685474932193756,
            -0.0014841931406408548,
            -0.047122567892074585,
            -0.01113910973072052,
            0.022409897297620773,
            -0.03558940812945366,
            -0.008080603554844856,
            0.015788620337843895,
            0.05744536966085434,
            -0.023954330012202263,
            0.015501030720770359,
            -0.01951177418231964,
            -0.0016532137524336576,
            0.026255302131175995,
            -0.015820924192667007,
            0.01639077439904213,
            -0.017063144594430923,
            0.010870645754039288,
            0.0325593426823616,
            -0.02044750563800335,
            -0.018504805862903595,
            -0.018445724621415138,
            0.026779813691973686,
            0.026240503415465355,
            0.04510582238435745,
            -0.01232894603163004,
            0.010456310585141182,
            -0.009851093403995037,
            -0.005385741591453552,
            -0.0010859830072149634,
            0.006934650707989931,
            0.017025163397192955,
            0.017739856615662575,
            0.01218876987695694,
            0.023278113454580307,
            -0.02595195546746254,
            0.013168858364224434,
            -0.012582555413246155,
            0.02169664204120636,
            -0.05016685277223587,
            0.030553054064512253,
            -0.01500014215707779,
            -0.02408425696194172,
            -0.00393357640132308,
            0.031353119760751724,
            0.018007254227995872,
            -0.026376161724328995,
            0.00720725953578949,
            0.005773651413619518,
            -0.018555941060185432,
            0.012215222232043743,
            -0.001864628167822957,
            0.008093365468084812,
            -0.02758120559155941,
            0.02355353720486164,
            -0.008048092015087605,
            0.028320997953414917,
            0.02412685565650463,
            -0.02146306075155735,
            -0.0194241963326931,
            0.0011597946286201477,
            -0.008968476206064224,
            0.05221392586827278,
            0.02549029514193535,
            0.011953860521316528,
            0.0003021601296495646
        ],
        [
            -0.019355408847332,
            -0.06377778947353363,
            -0.006707143969833851,
            0.027985014021396637,
            0.02187405899167061,
            0.027024738490581512,
            -0.03656359389424324,
            0.006967031396925449,
            0.00726358825340867,
            -0.04751655086874962,
            0.07661972939968109,
            -0.05032435432076454,
            -0.01306773629039526,
            0.04603677988052368,
            0.008641639724373817,
            0.05812215432524681,
            -0.045321181416511536,
            -0.06783471256494522,
            0.024064522236585617,
            -0.005806673318147659,
            0.020167434588074684,
            0.00927317701280117,
            0.021059658378362656,
            0.01965218037366867,
            0.05444074049592018,
            0.023776650428771973,
            0.06125529482960701,
            -0.015932442620396614,
            -0.041906170547008514,
            -0.03195565193891525,
            0.01430562511086464,
            0.01883554644882679,
            0.006975570693612099,
            -0.04033263400197029,
            -0.008771753869950771,
            -0.0007942253723740578,
            -0.00549852242693305,
            0.03210334852337837,
            -0.011279826052486897,
            0.008732822723686695,
            0.031499166041612625,
            -0.09462659060955048,
            0.0851566269993782,
            -0.05509341135621071,
            -0.004535941872745752,
            0.002751990919932723,
            0.020630663260817528,
            -0.014116047881543636,
            0.00038315722486004233,
            -0.02185075916349888,
            0.013118188828229904,
            0.009901447221636772,
            -0.014480441808700562,
            -0.0552334301173687,
            0.02127000130712986,
            -0.004359833430498838,
            0.016425274312496185,
            0.021867983043193817,
            0.029137689620256424,
            0.01622694358229637,
            0.030342429876327515,
            -0.03201734274625778,
            0.021410735324025154,
            -0.02305324375629425,
            0.02604876644909382,
            -0.023392634466290474,
            0.0328076034784317,
            0.03343493491411209,
            -0.029483865946531296,
            0.022853029891848564,
            -0.029490312561392784,
            -0.01926286518573761,
            0.011877162382006645,
            0.003899302799254656,
            -0.00983993150293827,
            0.050019361078739166,
            -0.005674565676599741,
            -0.05732494220137596,
            0.0413396917283535,
            -0.04837929084897041,
            -0.07134130597114563,
            0.024745386093854904,
            -0.016451841220259666,
            0.0018826627638190985,
            0.029018906876444817,
            -0.020581644028425217,
            -0.0038043279200792313,
            -0.08285976201295853,
            -0.00765995355322957,
            0.03442246839404106,
            0.035261120647192,
            0.009570651687681675,
            -0.01703391969203949,
            0.009243391454219818,
            -0.016165370121598244,
            -0.02070341259241104,
            0.030669471248984337,
            0.027591554448008537,
            -0.03275317698717117,
            -0.0003190366260241717,
            -0.02718864008784294,
            0.02369752898812294,
            -0.017166975885629654,
            0.004696331452578306,
            -0.048682086169719696,
            0.016121944412589073,
            -0.02176567353308201,
            -0.025116126984357834,
            0.061971280723810196,
            -0.0029771558474749327,
            -0.06953848898410797,
            -0.07648122310638428,
            -0.009449108503758907,
            -0.018883738666772842,
            -0.07536338269710541,
            -0.026810145005583763,
            0.011015587486326694,
            0.07367029786109924,
            -0.01220304612070322,
            -0.0011163216549903154,
            -0.0592162050306797,
            -0.027492107823491096,
            0.0007133953622542322,
            0.02173396199941635,
            -0.035696741193532944,
            0.029478654265403748,
            0.04430360719561577,
            0.01609739661216736,
            0.0074262493290007114,
            0.03949128836393356,
            -0.01570318080484867,
            0.02916604094207287,
            0.021328125149011612,
            -0.048717185854911804,
            0.026786871254444122,
            -0.06085323542356491,
            0.038499630987644196,
            -0.020373256877064705,
            0.05231127515435219,
            -0.023392772302031517,
            0.019431358203291893,
            0.0024081580340862274,
            0.012799372896552086,
            -0.0011927521554753184,
            0.005316581577062607,
            0.08104215562343597,
            -0.05116497352719307,
            -0.002957101445645094,
            -0.04761958494782448,
            -0.024005763232707977,
            -0.05995793268084526,
            0.028120966628193855,
            0.016438830643892288,
            -0.05521395802497864,
            0.030148101970553398,
            -0.021340645849704742,
            0.02006310410797596,
            -0.004688869696110487,
            0.013010159134864807,
            0.0005722530768252909,
            -0.02195543423295021,
            0.052224159240722656,
            -0.019838847219944,
            -0.08915350586175919,
            0.03359973058104515,
            -0.07218578457832336,
            -0.008172213099896908,
            0.029463300481438637,
            0.013566606678068638,
            -0.010143149644136429,
            -0.021629882976412773,
            -0.07541001588106155,
            0.023517481982707977,
            -0.0311135221272707,
            -0.002761200303211808,
            0.036635950207710266,
            0.039897430688142776,
            0.017071779817342758,
            0.0034524742513895035,
            -0.03259101137518883,
            0.004595334641635418,
            -0.0021697981283068657,
            -0.049430858343839645,
            -0.06083117797970772,
            0.04013875126838684,
            -0.023819632828235626,
            -0.04542871192097664,
            -0.0003834409872069955,
            0.0493401400744915,
            0.03785952925682068,
            0.041489023715257645,
            -0.043230436742305756,
            0.005024660378694534,
            -0.023018358275294304,
            -0.013684221543371677,
            0.0014310466358438134,
            -0.03085985966026783,
            -0.0384586937725544,
            0.022005612030625343,
            0.0018644697265699506,
            -0.049719374626874924,
            -0.03900616243481636,
            -0.02310332842171192,
            -0.022059978917241096,
            -0.06453344970941544,
            0.024758443236351013,
            0.0375269390642643,
            -0.034687042236328125,
            0.003945118747651577,
            0.0264685470610857,
            -0.007978923618793488,
            0.050328683108091354,
            0.0069617098197340965,
            0.040876030921936035,
            -0.045851707458496094,
            0.02725692093372345,
            0.011097662150859833,
            -0.009922284632921219,
            0.03513408452272415,
            0.028285300359129906,
            0.014956539496779442,
            0.01756095513701439,
            -0.051425568759441376,
            0.06068235635757446,
            0.012620787136256695,
            0.035987772047519684,
            -0.0814354419708252,
            -0.03321230411529541,
            0.011469857767224312,
            0.031226128339767456,
            0.030391627922654152,
            0.0032543730922043324,
            -0.054444022476673126,
            -0.019563211128115654,
            -0.04541243612766266,
            -0.03400266915559769,
            -0.048454128205776215,
            0.017428956925868988,
            0.004011428914964199,
            -0.06282549351453781,
            0.03858494758605957,
            -0.0017837737686932087,
            0.03316252678632736,
            -0.03656955808401108,
            -0.026581348851323128,
            0.024983135983347893,
            0.01720469258725643,
            -0.06680183112621307,
            -0.05378250405192375,
            -0.00801127590239048,
            -0.0013313243398442864,
            0.0010897667380049825,
            0.05602771416306496,
            0.0065252818167209625,
            -0.02282009832561016,
            0.02092318795621395,
            0.025527307763695717,
            -0.005571221932768822,
            0.03515489771962166,
            -0.03742843121290207,
            0.03858649730682373,
            0.009315953589975834,
            0.03274887055158615,
            -0.01418386958539486,
            -0.015076451003551483,
            0.027896907180547714,
            0.02897176891565323,
            -0.0484989695250988,
            0.032012272626161575,
            0.01141164917498827,
            -0.038012683391571045,
            -0.03988651931285858,
            0.014066352508962154,
            -0.00851327646523714,
            0.0036083953455090523,
            -0.003348718164488673,
            0.006009172648191452,
            -0.0037177710328251123,
            0.04254213720560074,
            0.00786037091165781,
            -0.018172770738601685,
            -0.028337353840470314,
            0.01598442904651165,
            -0.0009020388824865222,
            -0.03288182243704796,
            0.020951049402356148,
            0.007014173083007336,
            -0.016736844554543495,
            -0.03393642604351044,
            -0.023692024871706963,
            0.0030521810986101627,
            -0.02618638426065445,
            -0.010787421837449074,
            0.017720334231853485,
            0.0020302205812186003,
            -0.015443122014403343,
            0.04490863159298897,
            -0.010950976982712746,
            0.02529657818377018,
            -0.01885823905467987,
            0.036182649433612823,
            -0.055096834897994995,
            0.010661073960363865,
            0.012954392470419407,
            0.015751559287309647,
            0.0009442303562536836,
            0.030877796933054924,
            -0.04421228915452957,
            0.03779321536421776,
            -0.05665276199579239,
            0.03058350831270218,
            0.008804108016192913,
            -0.015481323935091496,
            -0.016341883689165115,
            0.019713666290044785,
            0.09790235757827759,
            -0.05244457721710205,
            -0.027518007904291153,
            0.024888332933187485,
            0.02336828038096428,
            0.032366130501031876,
            -0.009360742755234241,
            -0.02743976190686226,
            0.008260292001068592,
            -0.015239653177559376,
            -0.05258716270327568,
            -0.044625092297792435,
            0.034200966358184814,
            -0.025827720761299133,
            -0.023046834394335747,
            -0.030451955273747444,
            -0.04480373114347458,
            0.012055057100951672,
            -0.037795282900333405,
            -0.002481590723618865,
            -0.029917147010564804,
            -0.013938553631305695,
            -0.033541981130838394,
            0.00874265655875206,
            -0.05173570290207863,
            0.032345619052648544,
            -0.03185613453388214,
            0.06803395599126816,
            -0.029660584405064583,
            0.03126554191112518,
            0.049756526947021484,
            -0.0676192194223404,
            -0.03712526336312294,
            0.02975451387465,
            0.009512962773442268,
            -0.08979284018278122,
            0.020930949598550797,
            0.06347142904996872,
            -0.005934635642915964,
            0.0051435078494250774,
            -0.0042993552051484585,
            0.03787625953555107,
            -0.0035150686744600534,
            0.024296686053276062,
            -0.002805753843858838,
            -0.010895198211073875,
            -0.0018008352490141988,
            0.023571593686938286,
            0.0013247787719592452,
            0.00916340947151184,
            0.004202628508210182,
            -0.06967078894376755,
            -0.005662466399371624,
            0.010266181081533432,
            -0.05976139381527901,
            -0.03564659506082535,
            -0.02492195926606655,
            -0.00631692074239254,
            0.013743002898991108,
            -0.03940616548061371,
            -0.013012347742915154,
            0.004105210304260254,
            0.01802404783666134,
            -0.018132107332348824,
            -0.03565938398241997,
            -0.06015220656991005,
            -0.04453710839152336,
            0.01621145009994507,
            0.003392465179786086,
            0.021926134824752808,
            0.04121481254696846,
            0.017120875418186188,
            -0.023609578609466553,
            0.02159973233938217,
            -0.035594046115875244,
            0.00077105313539505,
            0.037107955664396286,
            0.043714579194784164,
            0.015958091244101524,
            -0.09618530422449112,
            -0.006295185070484877,
            0.016150858253240585,
            0.058426257222890854,
            -0.005479404237121344,
            -0.008589586243033409,
            0.040805116295814514,
            -0.015463157556951046,
            -0.020591676235198975,
            0.020663440227508545,
            -0.02888142317533493,
            -0.04579146206378937,
            0.006230005994439125,
            -0.009173918515443802,
            -0.02058100886642933,
            0.0075035602785646915,
            -0.007883037440478802,
            0.006646769121289253,
            0.04600878432393074,
            0.03146505355834961,
            -0.07063350826501846,
            0.03745221346616745,
            0.009939735755324364,
            -0.031536489725112915,
            0.046503130346536636,
            -0.02868737280368805,
            -0.03623043745756149,
            0.014501507394015789,
            -0.08813419193029404,
            -0.027857940644025803,
            0.044698845595121384,
            0.0022439025342464447,
            0.041019558906555176,
            0.027093499898910522,
            -0.0034972422290593386,
            0.013711409643292427,
            0.0032638460397720337,
            0.00530044874176383,
            -0.017763327807188034,
            0.021437760442495346,
            0.009627783671021461,
            0.018754415214061737,
            0.040722884237766266,
            -0.028989523649215698,
            0.028629016131162643,
            0.008559788577258587,
            -0.006599373184144497,
            -0.05664148926734924,
            -0.011382688768208027,
            0.017710091546177864,
            0.03500416502356529,
            -0.006125126499682665,
            0.029236065223813057,
            0.08417289704084396,
            -0.09104211628437042,
            0.009674121625721455,
            0.03447306156158447,
            -0.05120920017361641,
            -0.025792023167014122,
            0.02414683625102043,
            -0.0446791797876358,
            0.00570740457624197,
            -0.061240218579769135,
            -0.009410512633621693,
            -0.0296950563788414,
            0.014258906245231628,
            0.03315845876932144,
            -0.002657907549291849,
            -0.08611439913511276,
            0.026906413957476616,
            -0.018480300903320312,
            -0.018686018884181976,
            -0.012890645302832127,
            -0.0023702059406787157,
            -0.016224445775151253,
            -0.01360328122973442,
            -0.051709603518247604,
            -0.018256647512316704,
            -0.020724106580018997,
            -0.1044447049498558,
            -0.06699822098016739,
            0.0288085974752903,
            -0.029569286853075027,
            0.002751799998804927,
            0.004820163361728191,
            -0.08300900459289551,
            0.04354921728372574,
            -0.016849512234330177,
            0.025123320519924164,
            -0.001608982915058732,
            0.07304253429174423,
            -0.0056236800737679005,
            0.0005458714440464973,
            0.010622869245707989,
            0.07379297912120819,
            -0.03925022855401039,
            -0.0050097303465008736,
            -0.0354519784450531,
            -0.01377966906875372,
            0.025898652151226997,
            -0.009424160234630108,
            0.06318876147270203,
            -0.012655645608901978,
            -0.04591284692287445,
            -0.03091157041490078,
            0.050270259380340576,
            -0.055165987461805344,
            -0.046200014650821686,
            0.03242562711238861,
            0.004232827574014664,
            -0.06977961957454681,
            -0.03546300157904625,
            -0.02684609778225422,
            -0.0716795101761818,
            -0.043006885796785355,
            0.016751186922192574,
            -0.03311022371053696,
            0.013979660347104073,
            0.026281163096427917,
            0.028591493144631386,
            -0.016980700194835663,
            -0.030430886894464493,
            -0.022250181064009666,
            0.01863591931760311,
            -0.04612507298588753,
            0.022518053650856018,
            0.024580910801887512,
            -0.06152698025107384,
            -0.013258334249258041,
            0.025915371254086494,
            0.044326528906822205,
            0.03082781471312046,
            0.0010375194251537323,
            -0.000537711544893682,
            -0.020307479426264763,
            -0.038932815194129944,
            0.0005656520370393991,
            -0.021371856331825256,
            0.03949308022856712,
            -0.0028453224804252386,
            -0.022473305463790894,
            -0.0032714977860450745,
            0.022290054708719254,
            0.02414943277835846,
            0.027673041447997093,
            -0.01310677919536829,
            -0.01639447547495365,
            0.05846336483955383,
            -0.047389790415763855,
            0.029698312282562256,
            0.02331167459487915,
            -0.04509507864713669,
            -0.0010878655593842268,
            -0.01475807186216116,
            0.018867041915655136,
            0.015810539945960045,
            0.025663068518042564,
            0.008943957276642323,
            -0.02079489827156067,
            -0.010286210104823112,
            -0.045334551483392715,
            -0.004469949286431074,
            0.04092555120587349,
            -0.00045533684897236526,
            -0.011663361452519894,
            -0.0005776055040769279,
            0.0038317416328936815,
            -0.029522834345698357,
            -0.02359870634973049,
            0.055333033204078674,
            0.03988800197839737,
            -0.006768884602934122,
            -0.03545873612165451,
            0.021084044128656387,
            -0.032116372138261795,
            -0.07726830989122391,
            -0.010182148776948452,
            0.009021956473588943,
            0.033108022063970566,
            -0.02039247192442417,
            -0.025978073477745056,
            -0.00855181086808443,
            0.012339037843048573,
            0.03155180439352989,
            -0.0400208942592144,
            0.008059093728661537,
            0.0338565856218338,
            -0.07556205242872238,
            -0.06320513784885406,
            0.05848807096481323,
            0.01892671547830105,
            0.003034175606444478,
            -0.028098957613110542,
            0.01293095014989376,
            -0.04184854030609131,
            -0.027991147711873055,
            -0.02011496014893055,
            0.03748128190636635,
            0.02816861681640148,
            -0.049149904400110245,
            0.035033196210861206,
            -0.03182755038142204,
            -0.011858657002449036,
            -0.005412977188825607,
            0.005131443962454796,
            0.023454664275050163,
            -0.02266554906964302,
            -0.049769844859838486,
            -0.023404309526085854,
            0.028110992163419724,
            -0.019297422841191292,
            0.041037991642951965,
            -0.04770113527774811,
            -0.05026814714074135,
            -0.0705471783876419,
            0.011802049353718758,
            -0.04490141570568085,
            -0.021068228408694267,
            0.020704282447695732,
            -0.0010680536506697536,
            -0.029052432626485825,
            0.013081088662147522,
            -0.0016720332205295563,
            -0.011706926859915257,
            -0.0036299063358455896,
            -0.056188590824604034,
            -0.012296490371227264,
            -0.002596782287582755,
            -0.030462905764579773,
            -0.03428727015852928,
            -0.044335413724184036,
            0.02027799002826214,
            -0.05710764601826668,
            -0.023520637303590775,
            -0.05054823309183121,
            0.04506709426641464,
            0.007315485738217831,
            0.00308410357683897,
            -0.05669675022363663,
            0.027729757130146027,
            0.0004593956982716918,
            0.0077954987064003944,
            -0.016679031774401665,
            -0.011703834868967533,
            -0.04772539809346199,
            0.005584128201007843,
            0.018532874062657356,
            -0.048971205949783325,
            -0.02025102637708187,
            -0.02399018034338951,
            -0.020665746182203293,
            0.003055207896977663,
            0.06184478849172592,
            -0.02005799673497677,
            -0.02318839728832245,
            0.005428226199001074,
            -0.055064521729946136,
            -0.0273191686719656,
            -0.019548313692212105,
            0.025594092905521393,
            0.003773171454668045,
            -0.003245953004807234,
            -0.08700252324342728,
            -0.042305272072553635,
            -0.02914740890264511,
            0.008746620267629623,
            -0.004741559736430645,
            -0.0025608609430491924,
            -0.01546813827008009,
            -0.023739084601402283,
            0.032242052257061005,
            -0.010781609453260899,
            0.018071940168738365,
            -0.029419133439660072,
            -0.0021294092293828726,
            -0.02864072285592556,
            0.00478539289906621,
            -0.04716089367866516,
            -0.06579695641994476,
            0.04652249068021774,
            -0.040748272091150284,
            0.052238352596759796,
            0.003756705205887556,
            -0.08919715881347656,
            0.002261208603158593,
            -0.027961943298578262,
            0.03479966148734093,
            0.0009314555791206658,
            -0.009152106009423733,
            -0.013928748667240143,
            -0.009463653899729252,
            -0.004979080520570278,
            0.00962565466761589,
            -0.026189547032117844,
            -0.010455675423145294,
            -0.02340824529528618,
            0.0007601116667501628,
            -0.06567202508449554,
            0.004223195370286703,
            -0.0401962585747242,
            -0.036578916013240814,
            0.0009457636042498052,
            -0.017599403858184814,
            -0.013861443847417831,
            -0.005964559968560934,
            0.014890734106302261,
            0.020186228677630424,
            0.03427036479115486,
            0.01758768782019615,
            -0.022376270964741707,
            -0.09143731743097305,
            -0.062047891318798065,
            0.012180550955235958,
            -0.01994209736585617,
            0.023774566128849983,
            -0.011215178295969963,
            -0.0071125333197414875,
            -0.019483668729662895,
            0.007822905667126179,
            -0.030639301985502243,
            0.007335757836699486,
            0.00512014701962471,
            -0.026180841028690338,
            -0.015655148774385452,
            0.010282452218234539,
            -0.01471813302487135,
            -0.03034006431698799,
            -0.020465390756726265,
            -0.03758629411458969,
            -0.03572343289852142,
            0.011352568864822388,
            -0.0026515049394220114,
            -0.015896759927272797,
            -0.06755733489990234,
            -0.00015767592412885278,
            0.03352026641368866,
            -0.07319335639476776,
            0.038717519491910934,
            -0.007454492151737213,
            0.004016777500510216,
            0.02688678167760372,
            0.010644481517374516,
            -0.011310068890452385,
            -0.01095181331038475,
            -0.014902511611580849,
            0.017010055482387543,
            -0.017088208347558975,
            -0.03186706081032753,
            -0.005547216162085533,
            -0.035793617367744446,
            -0.020272083580493927,
            -0.05484782159328461,
            0.026919618248939514,
            -0.05894891545176506,
            -0.004122186917811632,
            -0.007514615077525377,
            0.0025526767130941153,
            -0.02444525435566902,
            0.000892950629349798,
            -0.033647868782281876,
            0.018417540937662125,
            -0.04347095638513565,
            -0.02158326655626297,
            -0.10289609432220459,
            -0.009952494874596596,
            0.002503890311345458,
            0.055066026747226715,
            -0.07728475332260132,
            -0.014210513792932034,
            -0.017867399379611015,
            -0.059125710278749466,
            -0.013907868415117264,
            -0.01630190759897232,
            -0.027067191898822784,
            0.01606694981455803,
            -0.03487895429134369,
            -0.027369126677513123,
            0.02163184992969036,
            -0.08459817618131638,
            -0.01673741266131401,
            -0.03666220232844353,
            0.032281890511512756,
            -0.05457001179456711,
            -0.014406718313694,
            0.007112705614417791,
            -0.017663421109318733,
            0.039476338773965836,
            0.026077795773744583,
            0.021042678505182266,
            -0.04517369344830513,
            0.013112385757267475,
            -0.0014534647343680263,
            0.030588291585445404,
            0.024876076728105545,
            0.026020603254437447,
            -0.023331230506300926,
            -0.03125745803117752,
            0.020022977143526077,
            -0.041570454835891724,
            -0.015620589256286621,
            0.008051394484937191,
            0.012293829582631588,
            0.02535102888941765,
            -0.05085669457912445,
            -0.03102766163647175,
            0.01806604117155075,
            -0.0023218702990561724,
            0.016851510852575302,
            -0.01325579360127449,
            -0.059965603053569794,
            0.05697627738118172,
            -0.023656565696001053,
            0.02131137065589428,
            -0.054673679172992706,
            0.024920452386140823,
            -0.02251988835632801,
            -0.025970283895730972,
            -0.04732874035835266,
            -0.008893746882677078,
            0.04396781325340271,
            0.05647346004843712,
            -0.028646601364016533,
            -0.021722400560975075,
            -0.0043179732747375965,
            0.022877313196659088,
            0.021769631654024124,
            -0.010360639542341232,
            0.005320881959050894,
            -0.005971900187432766,
            0.001345657859928906,
            -0.0083418358117342,
            -0.041212473064661026,
            -0.033732373267412186,
            -0.03817109391093254,
            0.01581890881061554,
            0.02096758969128132,
            -0.01710475981235504,
            0.009348027408123016,
            -0.025891482830047607,
            -0.017269615083932877,
            0.011639651842415333,
            -0.027076615020632744,
            0.012744744308292866,
            -0.006062055006623268,
            0.005145964212715626,
            -0.035495907068252563,
            -0.0029673150274902582,
            0.013672193512320518,
            0.027932288125157356,
            0.02633475326001644,
            0.011327545158565044,
            0.02528427354991436,
            0.015743453055620193,
            -0.010683520697057247,
            0.028371751308441162,
            -0.05870221182703972,
            -0.016696210950613022,
            -0.005352862644940615,
            -0.01548662967979908,
            0.007456786930561066,
            0.04269130155444145,
            0.005825452972203493,
            -0.008030065335333347,
            -0.032822366803884506,
            -0.03553245961666107,
            0.05548315495252609,
            -0.0020298317540436983,
            0.016448477283120155,
            -0.03443967178463936,
            -0.01626245491206646,
            0.06992774456739426,
            0.017872225493192673,
            -0.009157415479421616,
            0.050581883639097214,
            -0.05654938891530037,
            0.027476925402879715,
            0.00429560849443078,
            0.0019258257234469056,
            0.012625015340745449,
            0.009216735139489174,
            0.024745147675275803,
            -0.060048382729291916,
            0.013095129281282425,
            -0.057188741862773895,
            0.005340756382793188,
            -0.021492324769496918,
            -0.0010700717102736235,
            -0.07806574553251266,
            -0.08218146115541458,
            -0.01477802637964487,
            0.014275097288191319,
            0.021070251241326332,
            -0.029508264735341072,
            0.006302366033196449,
            0.012387535534799099,
            0.004827368538826704,
            -0.024395650252699852,
            -0.02796514332294464,
            -0.07523023337125778,
            -0.024942509829998016,
            -0.0014498921809718013,
            0.017236119136214256,
            -0.028968708589673042,
            -0.08516043424606323,
            -0.05286036804318428,
            0.036726631224155426,
            0.045036133378744125,
            -0.0218951515853405,
            -0.05519631505012512,
            0.0416996031999588,
            -0.014809989370405674,
            0.04207489639520645,
            0.04617289826273918,
            -0.06326954811811447,
            -0.031018225476145744,
            0.03050076775252819,
            0.03799315169453621,
            -0.016538934782147408,
            -0.016467848792672157,
            0.012478294782340527,
            0.004752147011458874,
            0.003325084922835231,
            -0.024966275319457054,
            -0.04118841141462326,
            0.021853439509868622,
            0.008715792559087276,
            0.05257184058427811,
            -0.004070571158081293,
            0.004715409595519304,
            -0.09418097883462906,
            0.018464669585227966,
            0.00574235524982214,
            0.051993295550346375,
            -0.022858917713165283,
            -0.004353472031652927,
            0.07362716645002365,
            -0.012319988571107388,
            -0.041821617633104324,
            -0.03929601609706879,
            -0.03318541496992111,
            -0.049266185611486435,
            -0.019584089517593384,
            -0.021203119307756424,
            0.03777210786938667,
            -0.04674325883388519,
            -0.08635757863521576,
            0.020645512267947197,
            -0.002490609884262085,
            -0.006955854129046202,
            -0.008632288314402103,
            0.013151301071047783,
            -0.01877540722489357,
            0.022258814424276352,
            0.012100627645850182,
            -0.044978849589824677,
            -0.0348958745598793,
            -0.08426613360643387,
            -0.02010197751224041,
            -0.02831941843032837,
            -0.012071957811713219,
            -0.04516969621181488,
            -0.019069550558924675,
            0.03163769468665123,
            0.011936158873140812,
            -0.012524659745395184,
            0.032809268683195114,
            -0.0754501223564148,
            0.03130345046520233,
            0.005901023279875517,
            0.00488606421276927,
            0.007121996954083443,
            -0.036511555314064026,
            0.053320590406656265,
            -0.05645774304866791,
            -0.05538398027420044,
            0.009500587359070778,
            -0.009650162421166897,
            -0.010949562303721905,
            -0.02265431359410286,
            -0.05208207294344902,
            0.005396197084337473,
            -0.004485215060412884,
            -0.013476291671395302,
            -0.013705393299460411,
            -0.017502659931778908,
            0.0284806527197361,
            0.0043308185413479805,
            0.017722249031066895,
            -0.014224149286746979,
            -0.010616355575621128,
            -0.06916719675064087,
            -0.034807030111551285,
            -0.005501714535057545,
            0.01744450442492962,
            -0.04009944573044777,
            -0.00810890831053257,
            -0.028356745839118958,
            -0.003486087080091238,
            -0.029356423765420914,
            -0.019600186496973038,
            -0.024308888241648674,
            0.00697823753580451,
            -0.013515332713723183,
            0.056086182594299316,
            0.005117539782077074,
            -0.03723208233714104,
            0.02126125618815422,
            -0.024226108565926552,
            -0.04766293242573738,
            0.012668362818658352,
            0.03691552206873894,
            -0.021905677393078804,
            -0.015193928964436054,
            -0.005396902095526457,
            0.024038881063461304,
            -0.0018176700687035918,
            -0.06058406084775925,
            -0.054916173219680786,
            0.039019521325826645,
            -0.019245289266109467,
            0.021138787269592285,
            0.06863829493522644,
            0.026110993698239326,
            0.015547039918601513,
            0.06785497069358826,
            -0.0024024141021072865,
            -0.03669733554124832,
            0.046611037105321884,
            0.07837382704019547,
            -0.022874154150485992
        ],
        [
            -0.024543505162000656,
            -0.029847318306565285,
            -0.0012538733426481485,
            -0.03108319453895092,
            0.018468203023076057,
            0.030335530638694763,
            0.001740153064019978,
            -0.028656654059886932,
            -0.003703625639900565,
            0.017664941027760506,
            -0.0013900372432544827,
            -0.002998278709128499,
            0.023281274363398552,
            -0.029478348791599274,
            -0.015898654237389565,
            -0.019683662801980972,
            -0.02453269436955452,
            0.011347292922437191,
            -0.03011801838874817,
            0.003035863395780325,
            -0.025567980483174324,
            -0.018030790612101555,
            -0.011738386005163193,
            -0.021935826167464256,
            -0.031313955783843994,
            0.012631653808057308,
            0.0038755738642066717,
            0.007489151321351528,
            -0.01723291166126728,
            -0.005118841305375099,
            0.021096553653478622,
            -0.0014768928522244096,
            0.0079950587823987,
            -0.00604913430288434,
            0.015186064876616001,
            -0.026628507301211357,
            0.006152782589197159,
            -0.013458886183798313,
            0.02926194854080677,
            0.02597726136445999,
            -0.03128677234053612,
            -0.014116652309894562,
            0.020357560366392136,
            -0.025153109803795815,
            0.008403695188462734,
            -0.025097232311964035,
            -0.011011210270226002,
            -0.020167171955108643,
            0.0034598391503095627,
            -0.015225285664200783,
            0.020216060802340508,
            -0.01425463892519474,
            -0.028871316462755203,
            -0.01518451701849699,
            0.02280370146036148,
            -0.021278899163007736,
            0.009703030809760094,
            -0.01114361546933651,
            0.01602681539952755,
            -0.019789963960647583,
            -0.011904011480510235,
            -0.0006001533474773169,
            0.01324895117431879,
            0.01924406737089157,
            0.0028961568605154753,
            -0.02333891950547695,
            0.02665434032678604,
            -0.01679351180791855,
            0.013456648215651512,
            -0.008767964318394661,
            -0.0240932684391737,
            -0.031535159796476364,
            -0.020190617069602013,
            -0.02422119677066803,
            0.006362203974276781,
            0.02231483906507492,
            -0.011130986735224724,
            0.008793329820036888,
            -0.021719226613640785,
            -0.01602167636156082,
            -0.005094323307275772,
            -0.021174469962716103,
            -0.018271315842866898,
            0.019815417006611824,
            0.007813836447894573,
            0.015122952871024609,
            -0.024014148861169815,
            -0.007564906496554613,
            -0.014725824818015099,
            0.02375013753771782,
            0.013971823267638683,
            0.001568468869663775,
            -0.013670100830495358,
            0.012842771597206593,
            0.013706602156162262,
            0.0057397885248064995,
            -0.010199771262705326,
            0.024710556492209435,
            0.008181797340512276,
            0.027873288840055466,
            -0.016080480068922043,
            0.015721092000603676,
            0.024583609774708748,
            -0.008255616761744022,
            0.028302224352955818,
            0.0023047581780701876,
            0.027028903365135193,
            -0.02157212235033512,
            -0.0029581442940980196,
            0.0026935054920613766,
            0.02143496461212635,
            0.007767788600176573,
            -0.027510013431310654,
            0.012435998767614365,
            -0.0035469592548906803,
            -0.004231875762343407,
            -0.024266505613923073,
            0.0168004110455513,
            -0.01986452378332615,
            -0.02155309170484543,
            0.029761338606476784,
            -0.007139970548450947,
            -0.0049710627645254135,
            0.012139588594436646,
            -0.004487683530896902,
            0.027092406526207924,
            0.025718137621879578,
            -0.009183304384350777,
            0.010811414569616318,
            -0.0006113467388786376,
            -0.024303430691361427,
            0.011813539080321789,
            -0.018506834283471107,
            0.01640172302722931,
            0.020624365657567978,
            0.005459681153297424,
            0.026893949136137962,
            -0.02789313532412052,
            0.01453650277107954,
            0.01940031908452511,
            -0.017267270013689995,
            0.02146945707499981,
            0.014181935228407383,
            -0.023956838995218277,
            -0.01791190728545189,
            0.009620913304388523,
            -0.00562890712171793,
            -0.024445654824376106,
            0.022102050483226776,
            0.025628799572587013,
            -0.00037986604729667306,
            -0.021548807621002197,
            0.011592942290008068,
            -0.026365846395492554,
            -0.001389654353260994,
            -0.02950826659798622,
            0.012894321233034134,
            -0.03139786049723625,
            0.02012372761964798,
            0.02269277535378933,
            0.022492030635476112,
            0.004874365404248238,
            0.006830601021647453,
            -0.022000007331371307,
            -0.0014600800350308418,
            -0.0015013400698080659,
            -0.010137575678527355,
            0.018254823982715607,
            -0.015525572933256626,
            0.028163660317659378,
            0.030103642493486404,
            -0.012179270386695862,
            -0.0013715060194954276,
            -0.032701801508665085,
            0.023817328736186028,
            -0.007066127844154835,
            0.004060819745063782,
            0.004428274463862181,
            -0.03161002695560455,
            -0.01679275371134281,
            0.016573462635278702,
            -0.031198982149362564,
            -0.008776935748755932,
            -0.028703615069389343,
            0.02293061465024948,
            0.027758879587054253,
            0.01650407910346985,
            0.004667377099394798,
            0.025506893172860146,
            0.015368380583822727,
            -0.017194319516420364,
            -0.03163101524114609,
            -0.007475954946130514,
            -0.017839934676885605,
            -5.8933212130796164e-05,
            -0.027444826439023018,
            -0.01702876016497612,
            -0.0027805035933852196,
            0.008649664930999279,
            0.00606918428093195,
            -0.0247386172413826,
            -0.004384805914014578,
            -0.021727582439780235,
            0.013161266222596169,
            0.00038071724702604115,
            0.013262429274618626,
            -0.02892029844224453,
            0.01614387333393097,
            0.021162057295441628,
            -0.02728600800037384,
            -0.02306283451616764,
            0.009598069824278355,
            -0.028232987970113754,
            0.022789333015680313,
            -0.018092650920152664,
            -0.009173043072223663,
            -0.01888394169509411,
            -0.0036697187460958958,
            -0.011858657002449036,
            -0.0088797677308321,
            -0.026644272729754448,
            0.0017887692665681243,
            0.0009836535900831223,
            -0.02565191313624382,
            0.024282246828079224,
            -0.026282982900738716,
            -0.009618541225790977,
            -0.018889477476477623,
            0.009913369081914425,
            -0.0034450856037437916,
            0.0067062280140817165,
            0.0018094992265105247,
            -0.02760489284992218,
            0.014960819855332375,
            -0.030024318024516106,
            -0.0009261029190383852,
            -0.026442358270287514,
            -0.01415088027715683,
            -0.008084353990852833,
            0.02734273485839367,
            0.013886417262256145,
            -0.008792204782366753,
            -0.01082204096019268,
            -0.027000142261385918,
            -0.000410918437410146,
            -0.025660814717411995,
            -0.016244111582636833,
            0.023307746276259422,
            0.00039626233046874404,
            0.03017624095082283,
            0.00761348707601428,
            -0.02929460071027279,
            -0.018161658197641373,
            -0.0025429846718907356,
            0.00698776775971055,
            -0.0037235808558762074,
            -0.010499821975827217,
            -0.030487554147839546,
            -0.017235998064279556,
            -0.013582373037934303,
            -0.026816148310899734,
            -0.023662522435188293,
            -0.030017895624041557,
            -0.003522491781041026,
            -0.023374522104859352,
            0.012022583745419979,
            0.0224294550716877,
            -0.028464308008551598,
            0.012619995512068272,
            0.016555041074752808,
            -0.02863171324133873,
            -0.029580766335129738,
            -0.01711220294237137,
            -0.029476068913936615,
            0.028295712545514107,
            -0.018532313406467438,
            -0.007804801221936941,
            -0.02665562555193901,
            -0.015288111753761768,
            -0.00762677239254117,
            -0.012067876756191254,
            -0.022909261286258698,
            0.002948091132566333,
            -0.022601941600441933,
            0.009209373034536839,
            0.021534068509936333,
            0.02620277926325798,
            -0.009044327773153782,
            0.013792706653475761,
            0.027452103793621063,
            0.017305484041571617,
            0.007110093254595995,
            0.027071228250861168,
            -0.00037362752482295036,
            0.02425508387386799,
            0.02075912058353424,
            -0.024413900449872017,
            -0.02188095636665821,
            0.019874317571520805,
            -0.02279171161353588,
            -0.004456596449017525,
            -0.01949138194322586,
            0.004414188675582409,
            -0.029868487268686295,
            -0.00893993116915226,
            0.016977859660983086,
            -0.014631596393883228,
            0.011214427649974823,
            0.025516092777252197,
            0.008413960225880146,
            -0.01570446416735649,
            0.010061834938824177,
            -0.009806700050830841,
            -0.026434684172272682,
            -0.017487751320004463,
            0.013349928893148899,
            0.022227181121706963,
            0.015937021002173424,
            -0.011273549869656563,
            -0.032106898725032806,
            -0.011843614280223846,
            -0.010111875832080841,
            0.018338574096560478,
            -0.007603216450661421,
            -0.004505089949816465,
            0.003741299267858267,
            0.012382984161376953,
            0.0019049060065299273,
            -0.017979390919208527,
            -0.01212140079587698,
            0.011792508885264397,
            0.025280151516199112,
            -0.01441313698887825,
            -0.01225273311138153,
            -0.016807183623313904,
            -0.016636721789836884,
            -0.004625766072422266,
            0.017686713486909866,
            0.024271657690405846,
            0.019962934777140617,
            0.011973574757575989,
            -0.01837802864611149,
            -0.026953479275107384,
            0.023064838722348213,
            -0.02993985451757908,
            0.012848634272813797,
            -0.026558928191661835,
            0.028354516252875328,
            0.024625388905405998,
            0.00572255440056324,
            0.0280649084597826,
            0.020838672295212746,
            0.014730777591466904,
            -0.009007936343550682,
            0.028979279100894928,
            0.02582681179046631,
            0.004147883038967848,
            0.006904695648699999,
            0.022601213306188583,
            0.02019716054201126,
            0.01153884083032608,
            0.011855191551148891,
            -0.03039277158677578,
            -0.021909749135375023,
            -0.02667352929711342,
            -0.014301483519375324,
            0.006774868816137314,
            -0.010622423142194748,
            -0.0015679927309975028,
            -0.010123703628778458,
            -0.02804120071232319,
            0.017841115593910217,
            0.011336450465023518,
            -0.014026466757059097,
            0.01040496677160263,
            0.0014786331448704004,
            -0.016547895967960358,
            0.01889047399163246,
            -0.028714828193187714,
            -0.028819464147090912,
            -0.005349644925445318,
            0.014170518144965172,
            -0.009005777537822723,
            0.02699732594192028,
            -0.010206406004726887,
            -0.01734410598874092,
            0.01589224487543106,
            -0.02981015108525753,
            -0.024750404059886932,
            -0.02900828793644905,
            -0.0016648994060233235,
            0.019073132425546646,
            -0.016816915944218636,
            -0.02591622807085514,
            0.02824975922703743,
            -0.0087676290422678,
            -0.02021184377372265,
            -0.003843323327600956,
            -0.020515315234661102,
            -0.021922344341874123,
            0.019355515018105507,
            -0.028538430109620094,
            0.026187604293227196,
            0.019169311970472336,
            0.010489728301763535,
            -0.004996675066649914,
            -0.003751659533008933,
            0.01357627846300602,
            0.014255106449127197,
            -0.020341455936431885,
            -0.007354386616498232,
            0.012591168284416199,
            0.01120363175868988,
            0.010921625420451164,
            -0.006371764000505209,
            0.007782310247421265,
            -0.012629927136003971,
            -0.005504293832927942,
            0.028401795774698257,
            -0.024952765554189682,
            -0.03171747550368309,
            0.02450357750058174,
            0.026839522644877434,
            0.009802586399018764,
            -0.030996346846222878,
            -0.010637848637998104,
            -0.016303418204188347,
            -0.024630898609757423,
            0.003859136486425996,
            -0.013339162804186344,
            0.029512915760278702,
            -0.020077887922525406,
            0.017351850867271423,
            0.004475440829992294,
            0.010956775397062302,
            -0.01574651151895523,
            0.030353596433997154,
            0.01788766123354435,
            -0.01775256358087063,
            0.009424044750630856,
            0.029370106756687164,
            -0.02547481283545494,
            0.023890141397714615,
            -0.0031737564131617546,
            0.0053588771261274815,
            0.020913731306791306,
            -0.02585439197719097,
            -0.011984424665570259,
            0.016933726146817207,
            0.030417436733841896,
            0.002540390472859144,
            -0.006260143592953682,
            0.013387385755777359,
            0.0252643171697855,
            0.01337672770023346,
            -0.025912921875715256,
            0.009114990010857582,
            0.017928561195731163,
            -0.007349168416112661,
            -0.03066164068877697,
            0.001968570053577423,
            0.0035748134832829237,
            0.010750352405011654,
            0.027528837323188782,
            -0.019078245386481285,
            -0.025585202500224113,
            0.0001617442030692473,
            -0.014817923307418823,
            -0.03232209011912346,
            -0.02971307560801506,
            -0.03077514097094536,
            -0.008665835484862328,
            0.0295163132250309,
            -0.03128870204091072,
            0.02237391285598278,
            0.005496600642800331,
            -0.02173277735710144,
            -0.02065378427505493,
            0.020176025107502937,
            0.0197033379226923,
            -0.012334766797721386,
            0.027865730226039886,
            -0.013430754654109478,
            -0.015379874035716057,
            0.00029061385430395603,
            0.013851983472704887,
            -0.016715673729777336,
            -0.02124074287712574,
            0.0010684361914172769,
            -0.013909123837947845,
            0.029670847579836845,
            -0.02710592746734619,
            0.021185321733355522,
            -0.013500411063432693,
            -0.005573374684900045,
            -0.012109357863664627,
            -0.0031539739575237036,
            -0.01679353043437004,
            0.014760131947696209,
            0.016382437199354172,
            0.024577675387263298,
            0.01683039590716362,
            -0.01702205464243889,
            -0.03115665540099144,
            0.0010473376605659723,
            -0.0011169343488290906,
            0.027092453092336655,
            0.008479995653033257,
            -0.031914278864860535,
            0.028089987114071846,
            -0.02502654492855072,
            -0.011456863954663277,
            0.006878452841192484,
            0.004840315785259008,
            -0.021011585369706154,
            -0.009358271025121212,
            -0.027605298906564713,
            -0.001668395008891821,
            -0.003877604380249977,
            0.0013132296735420823,
            -0.011221243068575859,
            -0.02739069238305092,
            -0.023218167945742607,
            -0.00297292391769588,
            -0.007595640607178211,
            -0.026656093075871468,
            0.023642370477318764,
            0.005980605725198984,
            0.010483777150511742,
            0.02277008444070816,
            0.016121745109558105,
            0.0027141550090163946,
            0.020285852253437042,
            0.020669125020503998,
            -0.01273667998611927,
            0.006988134700804949,
            -0.019862383604049683,
            -0.01921118050813675,
            -0.007985799573361874,
            0.008687537163496017,
            -0.004500183742493391,
            0.0014145676977932453,
            0.017276739701628685,
            -0.0304239671677351,
            0.007163685280829668,
            -0.01710580661892891,
            -0.029198721051216125,
            0.013965989463031292,
            0.013373557478189468,
            0.004430567380040884,
            -0.016122153028845787,
            -0.002826651092618704,
            -0.021205959841609,
            -0.02677864395081997,
            -0.017857763916254044,
            0.022296691313385963,
            -0.002856181701645255,
            -0.027262503281235695,
            0.007827349938452244,
            -0.0017375568859279156,
            0.01192774623632431,
            -0.0031818547286093235,
            0.008907408453524113,
            0.023720303550362587,
            -0.0298246368765831,
            -0.022857120260596275,
            -0.023934734985232353,
            0.010900481604039669,
            0.0286036916077137,
            0.010562056675553322,
            -0.022063307464122772,
            0.0031945283990353346,
            -0.021904312074184418,
            0.023370852693915367,
            0.025567855685949326,
            -0.028809038922190666,
            -0.02760426327586174,
            0.02610258013010025,
            -0.0037617452908307314,
            -0.006964907515794039,
            0.02138620987534523,
            -0.004212533123791218,
            -0.008852282539010048,
            0.012364299967885017,
            0.015060939826071262,
            -0.01176727470010519,
            -0.030809441581368446,
            -0.014754042960703373,
            -0.010262900032103062,
            0.01980963535606861,
            -0.015167218632996082,
            -0.0023525645956397057,
            0.01621863804757595,
            0.019351964816451073,
            0.02904115989804268,
            -0.018637262284755707,
            -0.008431317284703255,
            0.01674371026456356,
            -0.0014068513410165906,
            0.0038276133127510548,
            -0.021555133163928986,
            -0.02580266073346138,
            0.007286470849066973,
            0.023242391645908356,
            -0.014945189468562603,
            0.0019774578977376223,
            -0.004017277155071497,
            -0.022205224260687828,
            0.029247388243675232,
            0.015493066050112247,
            -0.0016538219060748816,
            -0.01764027588069439,
            -0.02936413139104843,
            0.02837587334215641,
            -0.01807873509824276,
            0.013218780979514122,
            -0.006722041871398687,
            0.02341783046722412,
            -0.028750738129019737,
            -0.0057767233811318874,
            -0.029529087245464325,
            -0.030903194099664688,
            0.006171904504299164,
            0.018583791330456734,
            -0.003306635422632098,
            -0.015789253637194633,
            -0.0007313442183658481,
            -0.00013426477380562574,
            -0.026668468490242958,
            -0.022651968523859978,
            0.005381033290177584,
            -0.027732187882065773,
            0.002365867141634226,
            -0.01932796835899353,
            -0.00859718769788742,
            0.008545036427676678,
            0.01897479221224785,
            0.002470047678798437,
            0.01588364876806736,
            -0.026387453079223633,
            0.013147374615073204,
            0.023216724395751953,
            -0.0022227780427783728,
            -0.02451743371784687,
            -0.007880554534494877,
            -0.03271808475255966,
            0.026122767478227615,
            -0.015457537956535816,
            0.022836633026599884,
            0.011898286640644073,
            0.020495375618338585,
            0.0031338741537183523,
            -0.01837688311934471,
            -0.001938863075338304,
            -0.015500087291002274,
            0.015335656702518463,
            -0.014752998948097229,
            0.028973456472158432,
            0.00873878039419651,
            0.027799539268016815,
            -0.02618463709950447,
            -0.002666667103767395,
            -0.007573047652840614,
            0.029309872537851334,
            0.004398189950734377,
            0.029343200847506523,
            0.004159621428698301,
            0.01534399576485157,
            0.021827269345521927,
            0.025592708960175514,
            0.014721982181072235,
            6.55345429549925e-05,
            -0.009725326672196388,
            -0.026589306071400642,
            -0.028755180537700653,
            0.02210872806608677,
            -0.009494039230048656,
            -0.004998111631721258,
            -0.013787408359348774,
            0.029405105859041214,
            -0.029421018436551094,
            0.0036796312779188156,
            0.012516340240836143,
            0.007642123848199844,
            0.0028848510701209307,
            -0.014879396185278893,
            0.0021963254548609257,
            -0.02211899496614933,
            0.008217137306928635,
            -0.0060665118508040905,
            -0.008974429219961166,
            -0.010501817800104618,
            0.02241889014840126,
            -0.017800332978367805,
            0.025837361812591553,
            -0.010348660871386528,
            -0.0273066908121109,
            0.010443574748933315,
            0.028627824038267136,
            0.01244035642594099,
            -0.023445744067430496,
            -0.029739301651716232,
            -0.021791208535432816,
            0.004289823584258556,
            -0.0036861172411590815,
            -0.021831635385751724,
            -0.029485782608389854,
            -0.005781423766165972,
            -0.011511970311403275,
            -0.027486763894557953,
            0.007114770822227001,
            0.008725698105990887,
            0.010111740790307522,
            -0.02913215570151806,
            -0.0009045036276802421,
            0.02407018467783928,
            -0.020650116726756096,
            -0.0071949767880141735,
            0.02986188419163227,
            -0.001258156611584127,
            0.020393280312418938,
            0.012601825408637524,
            -0.007736842148005962,
            -0.009752236306667328,
            -0.0175948329269886,
            -0.031489595770835876,
            0.0046094078570604324,
            0.022298390045762062,
            0.013681339100003242,
            0.021613867953419685,
            -0.0012415095698088408,
            -0.0027589472010731697,
            0.022111205384135246,
            0.029430735856294632,
            -0.013798557221889496,
            -0.012607462704181671,
            -0.00993368774652481,
            0.030024027451872826,
            0.007142945658415556,
            -0.032069940119981766,
            0.003494031261652708,
            0.023422477766871452,
            -0.018305445089936256,
            0.026218386366963387,
            0.02335009165108204,
            -0.01885608397424221,
            -0.028600895777344704,
            0.01180235669016838,
            -0.014694983139634132,
            -0.027397306635975838,
            0.0009025170584209263,
            -0.021057462319731712,
            -0.020286569371819496,
            -0.0147146200761199,
            -0.011795911937952042,
            -0.009652734734117985,
            -0.011128796264529228,
            -0.030605696141719818,
            -4.206405355944298e-05,
            -0.01824752613902092,
            0.012848373502492905,
            -0.016324393451213837,
            0.026545444503426552,
            0.0274080578237772,
            0.020061856135725975,
            0.028796736150979996,
            -0.014936883002519608,
            0.017994949594140053,
            -0.009288226254284382,
            -0.0016220661345869303,
            -0.010069080628454685,
            -0.015569600276648998,
            0.025890102609992027,
            -0.016888542100787163,
            -0.010080523788928986,
            0.025743529200553894,
            -0.026481714099645615,
            -0.002485743723809719,
            0.000951451133005321,
            0.022215789183974266,
            0.019283823668956757,
            0.0030820791143924,
            0.014032448641955853,
            -0.016319062560796738,
            0.00829812791198492,
            0.028592780232429504,
            0.023147283121943474,
            -0.028827929869294167,
            0.017002373933792114,
            -0.03118858113884926,
            0.021490391343832016,
            -0.016287634149193764,
            0.02773226797580719,
            -0.023129962384700775,
            0.007152601145207882,
            0.021168090403079987,
            -0.027778737246990204,
            -0.003570012515410781,
            -0.02975190244615078,
            0.007472860626876354,
            -0.020236095413565636,
            0.001779964310117066,
            -0.005881709512323141,
            0.005018074531108141,
            0.012327046133577824,
            -0.015734722837805748,
            0.016332970932126045,
            -0.014161776751279831,
            -0.02294265851378441,
            -0.02996903285384178,
            0.02809353917837143,
            -0.022012803703546524,
            0.012480337172746658,
            -0.015590209513902664,
            0.022411921992897987,
            -0.01627984642982483,
            -0.024614108726382256,
            0.013414913788437843,
            -0.01868303492665291,
            0.028704721480607986,
            -0.02524678409099579,
            0.015032555907964706,
            0.0021404684521257877,
            -0.02356250397861004,
            -0.011101565323770046,
            -0.003741760039702058,
            0.029784070327878,
            0.015154861845076084,
            0.01900784857571125,
            -0.007736113388091326,
            0.022770322859287262,
            0.020722204819321632,
            0.01987113431096077,
            0.023404182866215706,
            0.02593093551695347,
            -0.008121308870613575,
            -0.02686806581914425,
            -0.0235432181507349,
            -0.019144905731081963,
            0.026037340983748436,
            0.029443319886922836,
            0.004839822184294462,
            0.007414539810270071,
            -0.007027475629001856,
            -0.020268330350518227,
            -0.027882469817996025,
            0.01032733079046011,
            -0.0319557748734951,
            0.011298390105366707,
            -0.004774758592247963,
            -0.01699451170861721,
            -0.008742835372686386,
            0.019596656784415245,
            -0.003488213988021016,
            -0.004762293305248022,
            -0.006097251083701849,
            -0.0069711944088339806,
            0.012168715707957745,
            -0.010683661326766014,
            -0.004344237502664328,
            -0.026965199038386345,
            0.022294552996754646,
            0.0003982493653893471,
            0.021631406620144844,
            -0.023478522896766663,
            -0.012836036272346973,
            0.0139534967020154,
            0.0047285351902246475,
            -0.025362299755215645,
            0.019054895266890526,
            -0.00871986523270607,
            0.008796162903308868,
            0.01004795916378498,
            0.024404184892773628,
            0.0133631257340312,
            -0.023207269608974457,
            -0.03169306367635727,
            -0.012166664935648441,
            -0.024905717000365257,
            0.027310166507959366,
            0.015595078468322754,
            -0.014572289772331715,
            -0.02246050164103508,
            -0.024247966706752777,
            -0.000974901660811156,
            -0.031185775995254517,
            -0.003950622398406267,
            -0.0025120198260992765,
            0.011685756035149097,
            0.008373268879950047,
            -0.024747611954808235,
            0.021432258188724518,
            0.01640341430902481,
            -0.0026439165230840445,
            -0.0304777380079031,
            0.012071661651134491,
            0.028866427019238472,
            0.029915178194642067,
            0.015232003293931484,
            -0.007787307258695364,
            0.021410534158349037,
            -0.02771463617682457,
            0.020254584029316902,
            -0.006678949110209942,
            -0.004708167631179094,
            0.0015035474207252264,
            -0.011636614799499512,
            -0.028490377590060234,
            0.013746222481131554,
            -0.008555441163480282,
            0.007733331527560949,
            -0.008859608322381973,
            0.029747968539595604,
            -0.012645666487514973,
            -0.02209446206688881,
            -0.022490812465548515,
            -0.024931650608778,
            0.002076602540910244,
            -0.02808431163430214,
            -0.026766303926706314,
            0.027531646192073822,
            -0.022814620286226273,
            -0.026886092498898506,
            0.02797769568860531,
            0.011027546599507332,
            -0.02723539248108864,
            -0.0312984324991703,
            -0.02080867439508438,
            0.0005780437495559454,
            -0.004337056539952755,
            0.021548887714743614,
            -0.02325766161084175,
            -0.03091144561767578,
            0.006740574724972248,
            -0.02109370008111,
            -0.012108668684959412,
            -0.011441758833825588,
            0.028870193287730217,
            -0.005007313098758459,
            -0.012604781426489353,
            0.001565467333421111,
            0.0041229017078876495,
            -0.0069861155934631824,
            0.010201390832662582,
            -0.01956119015812874,
            -0.02712189219892025,
            0.0004195554356556386,
            -0.015912683680653572,
            0.004084348678588867,
            0.01977832429111004,
            -0.024064360186457634,
            0.01487769652158022,
            -0.001906012068502605,
            -0.021937616169452667,
            0.027458492666482925,
            -0.021577082574367523,
            -0.0322737842798233,
            0.01646890491247177,
            -0.009350214153528214,
            -0.012651401571929455,
            0.006245228927582502,
            -0.02466682344675064,
            -0.013708707876503468,
            0.011550559662282467,
            0.0021921894513070583,
            0.004256528336554766,
            0.018132563680410385,
            0.020747045055031776,
            0.008111975155770779,
            -0.028779765591025352,
            0.02018636278808117,
            -0.015793034806847572,
            0.011716117151081562,
            0.02426593378186226,
            -0.024291930720210075,
            -0.0015397167298942804,
            0.010395429097115993,
            0.026144040748476982,
            0.018353860825300217,
            -0.017821773886680603,
            -0.011307860724627972,
            -0.02587912790477276,
            0.011615525931119919,
            -0.01569969207048416,
            0.012463546358048916,
            0.007383802905678749,
            -0.01540412288159132,
            -0.027314981445670128,
            0.01837371475994587,
            -0.020211325958371162,
            0.023236488923430443,
            -7.038667718006764e-06,
            -0.014095780439674854,
            -0.009618682786822319,
            0.002827543066814542,
            0.009581169113516808,
            0.012943361885845661,
            0.0020541497506201267,
            -0.0017062280094251037,
            0.01794908195734024,
            -0.020485403016209602,
            -0.010927807539701462,
            -0.013492395170032978,
            0.014943240210413933,
            -0.030452311038970947,
            0.009614558890461922,
            -0.01633327640593052,
            -0.01288536749780178,
            -0.02065642736852169,
            0.0012149448739364743,
            0.010003384202718735,
            0.0018894830718636513,
            0.012234083376824856,
            -0.015394408255815506,
            0.01763017661869526,
            0.0043645896948874,
            -0.006931356620043516,
            0.011327718384563923,
            0.016835490241646767,
            0.015578160062432289,
            0.029086587950587273,
            0.028077654540538788,
            0.016098089516162872,
            -0.0316992811858654
        ],
        [
            0.008906248956918716,
            -0.00990812387317419,
            0.010958497412502766,
            -0.02884036675095558,
            0.015773866325616837,
            0.02895958349108696,
            0.025365417823195457,
            0.021225638687610626,
            -0.0033041713759303093,
            0.035523492842912674,
            0.037054117769002914,
            -0.00521064642816782,
            0.029820431023836136,
            -0.019671030342578888,
            0.040527891367673874,
            -0.04872376471757889,
            0.0305575393140316,
            0.0025891095865517855,
            0.022686250507831573,
            -0.017006415873765945,
            0.01261816918849945,
            -0.001987529918551445,
            0.01581912487745285,
            -0.00927711371332407,
            -0.02899702452123165,
            -0.0014403972309082747,
            -0.031798407435417175,
            -0.006474395282566547,
            -0.030110782012343407,
            -0.006452368572354317,
            0.02893332950770855,
            0.02869664877653122,
            -0.006461715791374445,
            -0.012889957055449486,
            -0.013245068490505219,
            0.012150976806879044,
            -0.01707252860069275,
            -0.005750812590122223,
            -0.008861452341079712,
            -0.020486703142523766,
            -0.0020175676327198744,
            0.010494228452444077,
            -0.018245521932840347,
            -0.005637748166918755,
            0.01352979987859726,
            0.003201629500836134,
            -0.0009703863179311156,
            0.0267744529992342,
            0.0408351831138134,
            0.03650336340069771,
            -0.05198971927165985,
            0.028789781033992767,
            -0.05250183492898941,
            0.01117880642414093,
            0.039903562515974045,
            0.014880133792757988,
            -0.0043551078997552395,
            0.00123981898650527,
            0.023619262501597404,
            -0.03078080527484417,
            -0.010903099551796913,
            0.004818342160433531,
            -0.01759253442287445,
            0.04033590108156204,
            0.012020625174045563,
            0.012805110774934292,
            -0.0021636239252984524,
            -0.006781274452805519,
            -0.010836799629032612,
            0.010231520980596542,
            -0.0127651896327734,
            0.018671462312340736,
            0.005727977026253939,
            -0.022643057629466057,
            0.015531656332314014,
            -6.204800797604548e-07,
            0.02474251762032509,
            -0.021048100665211678,
            0.03866032510995865,
            -0.00678443256765604,
            -0.021263768896460533,
            0.0016039445763453841,
            0.0015138264279812574,
            0.03322656452655792,
            -0.02314327470958233,
            -0.020060790702700615,
            -0.0022306006867438555,
            0.006556594744324684,
            0.01534498855471611,
            0.026678672060370445,
            0.03370228409767151,
            0.026078078895807266,
            -0.023551352322101593,
            -0.04234402999281883,
            0.041402846574783325,
            0.006220683455467224,
            -0.04323529824614525,
            0.008197644725441933,
            -0.04777305945754051,
            -0.025658154860138893,
            0.007467767223715782,
            0.052893538028001785,
            -0.00929174106568098,
            -0.002191054169088602,
            0.024522831663489342,
            0.002324281958863139,
            0.0035866056568920612,
            0.002002292312681675,
            0.00025989164714701474,
            0.020375708118081093,
            -0.02275756746530533,
            0.020087415352463722,
            0.0020743869245052338,
            -0.013083267956972122,
            0.007898352108895779,
            0.022112589329481125,
            0.0043115620501339436,
            0.017798006534576416,
            0.001969113713130355,
            -0.011874133720993996,
            -0.0040846941992640495,
            -0.016287654638290405,
            -0.013026520609855652,
            -0.016389664262533188,
            -0.01833047717809677,
            -0.026953259482979774,
            -0.006993474904447794,
            -0.00450562871992588,
            -0.0014635714469477534,
            -0.010554252192378044,
            0.026577511802315712,
            0.05481811240315437,
            0.006175746209919453,
            0.031871430575847626,
            -0.008845082484185696,
            -0.02285495400428772,
            0.025088129565119743,
            -0.001025163335725665,
            0.030847826972603798,
            -0.013345080427825451,
            0.006644828245043755,
            0.0032590266782790422,
            -0.03181429207324982,
            0.019802624359726906,
            0.04354899749159813,
            0.03744843974709511,
            -0.002964787418022752,
            0.011479875072836876,
            -0.017428245395421982,
            -0.019608601927757263,
            0.03383539617061615,
            0.04178023710846901,
            0.019938360899686813,
            0.025103826075792313,
            -0.027468916028738022,
            0.0156391691416502,
            0.02905680052936077,
            -0.007331665605306625,
            -0.020844638347625732,
            -0.004445964004844427,
            -0.025798264890909195,
            -0.02239794097840786,
            0.008786756545305252,
            -0.030553199350833893,
            -0.010438811965286732,
            -0.06396486610174179,
            -0.006133487448096275,
            0.02539408579468727,
            0.001765357912518084,
            -0.02547929249703884,
            -0.025064272806048393,
            -0.009424838237464428,
            -0.04365229234099388,
            0.04010927304625511,
            0.012679088860750198,
            -0.03399135172367096,
            0.048383377492427826,
            -0.02621680311858654,
            -0.03210032358765602,
            0.050591688603162766,
            -0.007128274068236351,
            -0.007438028696924448,
            0.052856091409921646,
            -0.00016957824118435383,
            -0.023509962484240532,
            0.02059495821595192,
            -0.005729089956730604,
            0.01689009740948677,
            0.040524888783693314,
            -0.02424776740372181,
            -0.021616345271468163,
            0.03967096284031868,
            0.06619151681661606,
            0.018205786123871803,
            0.009398119524121284,
            -0.041076090186834335,
            -0.01653771661221981,
            0.008259548805654049,
            0.030399352312088013,
            -0.0023951560724526644,
            0.0158015638589859,
            0.007908917032182217,
            0.009358279407024384,
            -0.005714617669582367,
            -0.03931853547692299,
            -0.03068673424422741,
            -0.02800885960459709,
            0.014435351826250553,
            -0.009712819941341877,
            0.015445447526872158,
            0.003979854751378298,
            0.0004566406714729965,
            -0.018123146146535873,
            0.012713863514363766,
            0.024860981851816177,
            0.023180177435278893,
            -0.012646010145545006,
            -0.013474876061081886,
            -0.023977650329470634,
            0.03218068182468414,
            -0.03288311883807182,
            -0.026004565879702568,
            -0.016195520758628845,
            -0.012148392386734486,
            -0.011320199817419052,
            0.016070861369371414,
            -0.0034780194982886314,
            -0.006610151380300522,
            0.03334593400359154,
            0.03063531219959259,
            -0.003956503700464964,
            -0.015021695755422115,
            0.033231645822525024,
            0.011774478480219841,
            0.03693241626024246,
            0.025504980236291885,
            0.048412419855594635,
            0.024108367040753365,
            0.013829524628818035,
            -0.008814681321382523,
            0.018587566912174225,
            0.010673046112060547,
            -0.01958012580871582,
            0.004009110853075981,
            0.022489750757813454,
            -0.03408900648355484,
            -0.007489529903978109,
            0.027816327288746834,
            0.019975118339061737,
            0.0056564523838460445,
            0.006021954119205475,
            0.001330215367488563,
            -0.0007473529549315572,
            -0.007712185848504305,
            -0.03300218656659126,
            0.020693887025117874,
            0.015518651343882084,
            0.03550248220562935,
            -0.03362565487623215,
            0.04439904913306236,
            -0.007681418210268021,
            -0.009222657419741154,
            -0.04140093922615051,
            -0.031062033027410507,
            -0.02659265697002411,
            -0.03348932042717934,
            -0.02274015173316002,
            0.0022418571170419455,
            -0.003719933331012726,
            0.02870495617389679,
            -0.003273929003626108,
            0.038279321044683456,
            -0.01219198014587164,
            0.030383586883544922,
            0.009757285937666893,
            0.028077445924282074,
            -0.0004274928360246122,
            -0.008979895152151585,
            0.014043605886399746,
            -0.016314925625920296,
            0.004199204966425896,
            0.011530909687280655,
            -0.010667241178452969,
            -0.006976470351219177,
            0.012873788364231586,
            0.019276654347777367,
            -0.0010914704762399197,
            0.045428041368722916,
            -0.038916848599910736,
            0.020049376413226128,
            0.02808152139186859,
            -0.02091233618557453,
            0.03218813240528107,
            0.01458490826189518,
            0.022422287613153458,
            0.0426039919257164,
            -0.017022302374243736,
            0.004688428249210119,
            0.021947816014289856,
            0.015998732298612595,
            -0.0068815057165920734,
            0.03667965531349182,
            0.009447519667446613,
            0.012452802620828152,
            0.017411094158887863,
            -0.006473991088569164,
            -0.00585490558296442,
            0.03037332557141781,
            0.03298879414796829,
            -0.008921889588236809,
            -0.045758768916130066,
            -0.002775590866804123,
            -0.024841666221618652,
            0.006459530908614397,
            -0.012194677256047726,
            0.028496835380792618,
            0.017492424696683884,
            -0.02557150460779667,
            0.006302164401859045,
            0.033876433968544006,
            -0.023406580090522766,
            -0.015215856023132801,
            0.0406656451523304,
            0.005977761931717396,
            0.0031402420718222857,
            -0.004746184218674898,
            0.01727604866027832,
            0.009330359287559986,
            0.0010492483852431178,
            -0.011397779919207096,
            0.05462176352739334,
            -0.03223919868469238,
            -0.0027456304524093866,
            0.014610176905989647,
            0.035143859684467316,
            -0.020180245861411095,
            0.03607605770230293,
            -0.0029276537243276834,
            0.026225300505757332,
            -0.02978997305035591,
            -0.026692571118474007,
            0.03671929985284805,
            0.016239723190665245,
            -0.00659031979739666,
            -0.030484989285469055,
            -0.007248246110975742,
            0.021522654220461845,
            0.015321592800319195,
            -0.0002706165541894734,
            0.04084353521466255,
            0.04399526119232178,
            0.003276374191045761,
            0.010866571217775345,
            0.013551932759582996,
            0.02322138100862503,
            0.0370110385119915,
            -0.0018349376041442156,
            0.028722500428557396,
            -0.017552774399518967,
            -0.01935429871082306,
            0.005107861012220383,
            0.005414286162704229,
            -0.019642969593405724,
            -0.020227793604135513,
            -0.0231839157640934,
            -0.0038319353479892015,
            -0.018852395936846733,
            0.01627684012055397,
            0.014088265597820282,
            0.007015688344836235,
            0.0035783573985099792,
            0.0024539134465157986,
            0.016555165871977806,
            -0.022367756813764572,
            -0.009521975181996822,
            0.023415016010403633,
            0.02661754935979843,
            -0.0013116347836330533,
            3.9546910556964576e-05,
            0.009306221269071102,
            0.03363262861967087,
            -0.007971422746777534,
            -0.01293991133570671,
            0.0004887731629423797,
            -0.005697363056242466,
            -0.026398343965411186,
            -0.0024814095813781023,
            -0.029410194605588913,
            0.011468540877103806,
            0.013827917166054249,
            -0.015982983633875847,
            0.00995205994695425,
            -0.02676256373524666,
            -0.014483529143035412,
            0.04431917145848274,
            0.0180805716663599,
            0.031661491841077805,
            0.06387454271316528,
            0.003082908922806382,
            0.014453619718551636,
            0.02693677507340908,
            0.0041819969192147255,
            -0.033716924488544464,
            -0.013525648042559624,
            0.030255494639277458,
            0.015752488747239113,
            0.02718902938067913,
            -0.02098991721868515,
            -0.0008501558913849294,
            -0.010431093163788319,
            0.022735092788934708,
            -0.0055129933170974255,
            -0.03307479992508888,
            0.012954558245837688,
            0.03217756003141403,
            -0.022930793464183807,
            -0.022881247103214264,
            0.009793851524591446,
            0.03942948579788208,
            -0.017119845375418663,
            0.02242046222090721,
            0.0026966689620167017,
            -0.0689077079296112,
            -0.02539409138262272,
            -0.002587227150797844,
            -0.016081474721431732,
            -0.017014959827065468,
            0.016730258241295815,
            -0.0028459583409130573,
            -0.002718936651945114,
            -0.0054412949830293655,
            0.022689472883939743,
            -0.02203712798655033,
            0.07412803173065186,
            0.00019777282432187349,
            0.005866647232323885,
            -0.01310325413942337,
            -0.022951750084757805,
            0.0032521297689527273,
            0.024708349257707596,
            -0.014794287271797657,
            -0.005561775993555784,
            0.014599735848605633,
            -0.02761024236679077,
            -0.006364608183503151,
            -0.011679882183670998,
            -0.005568650551140308,
            -0.005578622687608004,
            -0.01709573343396187,
            -0.015921318903565407,
            -0.03385728970170021,
            -0.028437096625566483,
            0.03539421781897545,
            -0.03026895597577095,
            0.041449788957834244,
            0.008875641040503979,
            -0.020445041358470917,
            -0.007329063024371862,
            -0.009352099150419235,
            -0.030370980501174927,
            0.02486427314579487,
            -0.017850929871201515,
            -0.04581186920404434,
            -0.019393840804696083,
            -0.018849514424800873,
            -0.024909501895308495,
            0.005383946932852268,
            -0.012144221924245358,
            -0.008874048478901386,
            -0.020587140694260597,
            0.042255982756614685,
            -0.012866348028182983,
            0.02167201228439808,
            0.03670487180352211,
            0.027651630342006683,
            -0.03438466787338257,
            -0.030250977724790573,
            -0.0006782974814996123,
            -0.007455191574990749,
            -0.005235229153186083,
            -0.0130467489361763,
            -0.02438441291451454,
            -0.07996472716331482,
            -0.03226190060377121,
            0.027185829356312752,
            0.01504899375140667,
            -0.020615048706531525,
            0.013297504745423794,
            -0.0003248963621445,
            0.003621121635660529,
            -0.036043133586645126,
            0.022925158962607384,
            -0.03325719013810158,
            0.03676754981279373,
            -0.02758209779858589,
            0.0033435714431107044,
            0.03630480170249939,
            -0.020162133499979973,
            0.00015839563275221735,
            0.004579673986881971,
            0.0236130990087986,
            -0.034469179809093475,
            0.007622423116117716,
            0.035004790872335434,
            0.02198110893368721,
            -0.008819811046123505,
            -0.038304153829813004,
            -0.0009096052963286638,
            0.024083834141492844,
            0.023483548313379288,
            0.02806888334453106,
            0.03351341560482979,
            -0.019158372655510902,
            -0.017579149454832077,
            0.018030868843197823,
            -0.006743056699633598,
            0.03623942658305168,
            -0.02349185198545456,
            0.013938461430370808,
            0.012109964154660702,
            0.004409193526953459,
            -0.003503028769046068,
            -0.031873881816864014,
            0.0147659070789814,
            -0.036647263914346695,
            -0.018257765099406242,
            0.004285333678126335,
            0.003347659483551979,
            -0.01904737763106823,
            -0.00878999661654234,
            -0.018715834245085716,
            -0.029618151485919952,
            -0.002636645222082734,
            -0.022182796150445938,
            0.0032679233700037003,
            -0.0001504566171206534,
            -0.028033845126628876,
            0.0009108489030040801,
            0.017552344128489494,
            -0.0031812291126698256,
            0.03334851190447807,
            0.003365714568644762,
            0.01109891664236784,
            0.02298193983733654,
            0.013427766971290112,
            0.04234182834625244,
            0.028994964435696602,
            -0.023517578840255737,
            0.008322225883603096,
            0.006144077051430941,
            -0.0017446941928938031,
            -0.03267480060458183,
            0.001773037831299007,
            -0.020562967285513878,
            0.028515629470348358,
            0.011754571460187435,
            -0.03216629847884178,
            0.025333622470498085,
            -0.02459542825818062,
            0.026379326358437538,
            0.004716320428997278,
            -0.007721361704170704,
            0.010586231015622616,
            -0.03303418308496475,
            -0.009264945052564144,
            -0.04466632753610611,
            0.02060725912451744,
            0.00923827663064003,
            -0.006763086188584566,
            -0.028149107471108437,
            0.012220948934555054,
            0.010944744572043419,
            0.016240490600466728,
            0.018534556031227112,
            -0.03897665813565254,
            0.023311538621783257,
            0.028493599966168404,
            0.04187949374318123,
            -0.022563911974430084,
            -0.02397731877863407,
            0.01917249895632267,
            -0.02968204766511917,
            0.0006608317489735782,
            0.025184134021401405,
            -0.028888123109936714,
            0.0032063545659184456,
            -0.02859109826385975,
            -0.03237994760274887,
            -0.024838358163833618,
            0.0006429002387449145,
            0.024946073070168495,
            -0.0005722625064663589,
            0.010730739682912827,
            0.014705265872180462,
            0.021746117621660233,
            0.039392732083797455,
            0.04224874824285507,
            0.019651858136057854,
            0.03797147795557976,
            -0.01682693511247635,
            0.032722052186727524,
            -0.044422369450330734,
            0.013446763157844543,
            -0.007750256452709436,
            -0.025957167148590088,
            0.0074068703688681126,
            0.012178617529571056,
            0.008515710942447186,
            0.012807266786694527,
            -0.012900080531835556,
            -0.011143972165882587,
            -0.015091508626937866,
            -0.007875978015363216,
            0.014651196077466011,
            -0.025436071678996086,
            0.035614848136901855,
            0.004104115534573793,
            0.035219427198171616,
            0.02766970358788967,
            0.009632897563278675,
            0.007316878065466881,
            0.02675122208893299,
            0.008884195238351822,
            0.013346544466912746,
            0.019861530512571335,
            -0.021030593663454056,
            0.01443398930132389,
            0.035826485604047775,
            -0.007147656753659248,
            0.02816489152610302,
            -0.01191740669310093,
            -0.023535165935754776,
            -0.006328764371573925,
            0.014712353236973286,
            0.005545844789594412,
            0.024198662489652634,
            0.0028506172820925713,
            0.02391357347369194,
            0.028545372188091278,
            0.009808247908949852,
            0.03452828899025917,
            -0.014299674890935421,
            -0.03229406476020813,
            -0.004249269608408213,
            -0.010930631309747696,
            0.00833431538194418,
            -0.03246627002954483,
            0.02404124103486538,
            -0.034677717834711075,
            -0.029735393822193146,
            0.016899211332201958,
            0.007394483312964439,
            0.0262505691498518,
            0.009141894057393074,
            0.01467161811888218,
            -0.014701510779559612,
            -0.0012721688253805041,
            0.030188636854290962,
            0.04087342694401741,
            -0.016566630452871323,
            -0.004571857862174511,
            0.013384607620537281,
            -0.019830014556646347,
            0.0412624254822731,
            -0.016281800344586372,
            -0.0279974527657032,
            0.032930485904216766,
            -0.020151237025856972,
            -0.012543228454887867,
            0.02624964341521263,
            -0.057894110679626465,
            -0.03494368493556976,
            -0.019373076036572456,
            -0.029284512624144554,
            -0.0023963667917996645,
            -0.008871326223015785,
            0.006828955840319395,
            0.0008979708072729409,
            -0.0265989787876606,
            -0.009919934906065464,
            -0.01603532023727894,
            -0.009869178757071495,
            0.027914946898818016,
            -0.00730879046022892,
            -0.008486013859510422,
            0.01675925962626934,
            0.010358679108321667,
            0.0015092011308297515,
            -0.004266395233571529,
            -0.0022930388804525137,
            0.043004754930734634,
            0.00472310371696949,
            0.010709716938436031,
            0.00915634073317051,
            -0.01913471892476082,
            -0.04159398376941681,
            0.009822392836213112,
            -0.01277494989335537,
            -0.0049297865480184555,
            -0.019999466836452484,
            0.0010290468344464898,
            0.018084276467561722,
            -0.024811670184135437,
            0.017029762268066406,
            0.03781381621956825,
            0.0004715063259936869,
            -0.009310507215559483,
            -0.016923362389206886,
            0.0011544357985258102,
            0.006469257641583681,
            0.012808006256818771,
            -0.020974010229110718,
            -0.0137779600918293,
            -0.02835649996995926,
            0.0062885223887860775,
            -0.027876950800418854,
            -0.08305397629737854,
            -0.001835510367527604,
            0.01878727413713932,
            -0.028006043285131454,
            0.024808648973703384,
            -0.029092317447066307,
            0.02458050101995468,
            -0.013254673220217228,
            -0.02138707973062992,
            -0.006955600343644619,
            0.01805214025080204,
            -0.002132378052920103,
            -0.0036125893238931894,
            0.00915867742151022,
            -0.004997717682272196,
            -0.02176302671432495,
            0.008716210722923279,
            0.018992915749549866,
            -0.03477933257818222,
            0.05062234401702881,
            0.005183911882340908,
            -0.002446012105792761,
            0.04526882246136665,
            -0.013326991349458694,
            0.023779504001140594,
            0.00501408614218235,
            -0.027263926342129707,
            0.012051264755427837,
            0.01248474046587944,
            0.00654967874288559,
            0.03653974458575249,
            0.006795027293264866,
            -0.004650657530874014,
            0.025928068906068802,
            0.020628435537219048,
            0.016241317614912987,
            -0.01958518847823143,
            -0.01666019670665264,
            -0.028658980503678322,
            0.023328833281993866,
            -0.008309738710522652,
            0.03586997091770172,
            -0.01964684948325157,
            0.01147901639342308,
            0.011581706814467907,
            -0.009508141316473484,
            -0.019119078293442726,
            -0.04412247613072395,
            0.040630042552948,
            0.04596159607172012,
            0.00462331622838974,
            -0.004444392863661051,
            -0.01763227954506874,
            0.026611343026161194,
            0.005350394174456596,
            0.0035697976127266884,
            0.00875829253345728,
            5.4584277677349746e-05,
            0.026445910334587097,
            0.04023726284503937,
            0.03880031779408455,
            0.04143115133047104,
            -0.025516677647829056,
            0.035207878798246384,
            0.005924679338932037,
            -0.030004452913999557,
            0.03969644382596016,
            -0.018716558814048767,
            -0.004918352235108614,
            0.0460340715944767,
            0.0010218295501545072,
            -0.011799306608736515,
            -0.025806548073887825,
            -0.011956669390201569,
            0.04179000109434128,
            0.0024735096376389265,
            0.005818563513457775,
            0.019972490146756172,
            0.02467656321823597,
            -0.015294679440557957,
            -0.017046745866537094,
            0.006233471445739269,
            -0.017442097887396812,
            0.021282680332660675,
            0.025410428643226624,
            -0.014330415055155754,
            -0.00015743279072921723,
            0.05218357592821121,
            0.03151547908782959,
            -0.0028493867721408606,
            0.014874457381665707,
            -0.0054421573877334595,
            -0.010685332119464874,
            -0.001763504114933312,
            0.004731407854706049,
            -0.0007872639107517898,
            0.010874459519982338,
            -0.014792412519454956,
            -0.01709885522723198,
            -0.032773181796073914,
            0.030106427147984505,
            -0.009708757512271404,
            -0.015808789059519768,
            0.022609855979681015,
            0.0338815301656723,
            0.04472395032644272,
            0.0023871513549238443,
            -0.007193615660071373,
            -0.024458928033709526,
            0.02258462645113468,
            -0.004388391971588135,
            0.026227788999676704,
            0.005425573792308569,
            -0.026620591059327126,
            0.0024703871458768845,
            0.0023161491844803095,
            0.050660163164138794,
            0.040445126593112946,
            -0.012370307929813862,
            0.04255039617419243,
            0.027733171358704567,
            -0.00042899345862679183,
            -0.03271511197090149,
            0.015905236825346947,
            -0.033055178821086884,
            0.017104053869843483,
            -0.022733040153980255,
            -0.0003911921812687069,
            -0.029775459319353104,
            0.011378957889974117,
            0.005853075534105301,
            0.03241974860429764,
            -0.004047383088618517,
            -0.032135024666786194,
            0.03261900693178177,
            -0.02158471755683422,
            -0.019741108641028404,
            0.005450539756566286,
            0.02263680286705494,
            0.022742880508303642,
            -0.027961349114775658,
            -0.0017949051689356565,
            -0.002260801149532199,
            0.013204432092607021,
            -0.023663124069571495,
            -0.010033054277300835,
            -0.011651313863694668,
            0.019138555973768234,
            0.046634428203105927,
            0.018675217404961586,
            -0.013427519239485264,
            -0.028630726039409637,
            -0.0011765965027734637,
            -0.003128154668956995,
            0.03659846633672714,
            -0.01566915400326252,
            0.05618775635957718,
            -0.00900157168507576,
            0.002451848005875945,
            0.009829788468778133,
            0.005146844312548637,
            0.01759183779358864,
            0.028476493433117867,
            0.02098267897963524,
            -0.00698859104886651,
            0.021871626377105713,
            -0.014732381328940392,
            0.008981386199593544,
            -0.018855156376957893,
            0.023479757830500603,
            0.011442764662206173,
            0.014775368385016918,
            0.004770757630467415,
            0.010515901260077953,
            -0.05294839292764664,
            -0.00897902064025402,
            -0.030906785279512405,
            -0.018221141770482063,
            -0.014109881594777107,
            -0.044421274214982986,
            -0.025420617312192917,
            0.013916514813899994,
            -0.0010265405289828777,
            0.033864304423332214,
            0.016931064426898956,
            0.014855298213660717,
            0.008874026127159595,
            0.035340726375579834,
            0.003919718787074089,
            0.014726581983268261,
            0.010217075236141682,
            0.001005963422358036,
            -0.019274547696113586,
            -0.002006339142099023,
            -0.025066005066037178,
            0.043449707329273224,
            0.03102848492562771,
            0.0009209986310452223,
            -0.03777896612882614,
            0.007419831119477749,
            0.028668835759162903,
            0.02288993075489998,
            0.02273237146437168,
            -0.021348146721720695,
            -0.005772092379629612,
            0.03405456617474556,
            0.01285409927368164,
            0.04275280609726906,
            0.013103161938488483,
            -0.037557490170001984,
            -0.028139330446720123,
            0.03976483643054962,
            -0.015808338299393654,
            -0.007250404916703701,
            0.022924967110157013,
            -0.010329267010092735,
            -0.027925796806812286,
            0.024030067026615143,
            0.02094915509223938,
            -0.02785882167518139,
            -0.02796202525496483,
            0.013129097409546375,
            0.02747402712702751,
            -0.015381751582026482,
            -0.024868667125701904,
            -0.0238126702606678,
            0.01987355761229992,
            -0.016473693773150444,
            -0.010933220386505127,
            -0.022363491356372833,
            -0.01622915267944336,
            -0.01325368694961071,
            0.03489654138684273,
            0.005610763095319271,
            0.017764698714017868,
            0.0297431368380785,
            0.030492760241031647,
            -0.026019010692834854,
            0.0442793183028698,
            0.004032929427921772,
            -0.02206512540578842,
            -0.0024345149286091328,
            -0.04438905790448189,
            0.039792388677597046,
            0.0012673275778070092,
            -0.0036606614012271166,
            0.028010686859488487,
            0.026691824197769165,
            0.025748884305357933,
            0.04568261280655861,
            0.016962405294179916,
            0.00919276662170887,
            0.038120120763778687,
            -0.00847797840833664,
            -0.0176741573959589,
            0.004643918480724096,
            -0.026615014299750328,
            0.02932792715728283,
            -0.013280080631375313,
            0.003930349368602037,
            0.028930045664310455,
            -0.01181510929018259,
            0.030353063717484474,
            -0.02391381561756134,
            0.015203995630145073,
            0.008746862411499023,
            0.03167349845170975,
            -0.0032740957103669643,
            0.04880509153008461,
            -0.004026737995445728,
            0.04289066046476364,
            -0.034188162535429,
            -0.000385555176762864,
            -0.040950383991003036,
            0.01475813239812851,
            0.016272542998194695,
            -0.028350695967674255,
            0.0017906308639794588,
            -0.02068750187754631,
            0.01663501374423504,
            0.048693254590034485,
            -0.0012077400460839272,
            0.002400602214038372,
            -0.005324068944901228,
            0.027772044762969017,
            0.025072745978832245,
            0.04785105958580971,
            0.01750081591308117,
            -0.03014213591814041,
            -0.0077546127140522,
            -0.003916464745998383,
            -0.033554404973983765,
            -0.010979611426591873,
            -0.02475935034453869,
            -0.00739323440939188,
            -0.03626059368252754,
            -0.012233652174472809,
            -0.023251473903656006,
            -0.0016557874623686075,
            -0.004966986831277609,
            -0.011921022087335587,
            0.0028011153917759657,
            -0.03216378763318062,
            0.012337884865701199,
            0.016405869275331497,
            0.019276630133390427,
            0.052843477576971054,
            -0.0031445713248103857
        ],
        [
            -0.029575685039162636,
            -0.04029387608170509,
            -0.003666473552584648,
            0.014420980587601662,
            0.027918675914406776,
            -0.0030260663479566574,
            -0.01777474768459797,
            -0.02313503995537758,
            0.01999962329864502,
            0.009008885361254215,
            -0.04887636378407478,
            0.029997151345014572,
            -0.02238890528678894,
            0.015841932967305183,
            0.04875778779387474,
            -0.07175355404615402,
            0.023465728387236595,
            -0.026824146509170532,
            0.025133812800049782,
            0.020479559898376465,
            -0.00602624611929059,
            0.005401711445301771,
            -0.02201572060585022,
            -0.027118844911456108,
            0.05520131438970566,
            0.004139708820730448,
            0.0065450421534478664,
            0.01986481249332428,
            -0.022387443110346794,
            0.03122267872095108,
            0.030174003913998604,
            0.005855743307620287,
            -0.021011482924222946,
            0.012050624936819077,
            0.007954799570143223,
            0.02063787914812565,
            0.016921909525990486,
            -0.021528255194425583,
            -0.017128489911556244,
            0.0012215744936838746,
            0.0015444207238033414,
            -0.005292564630508423,
            -0.017034340649843216,
            -0.013465034775435925,
            0.004373888950794935,
            -8.162333688233048e-05,
            0.023017194122076035,
            -0.029446611180901527,
            -0.027432408183813095,
            0.008255049586296082,
            0.01443801261484623,
            -0.016558123752474785,
            -0.0010247882455587387,
            0.011703599244356155,
            -0.008308475837111473,
            -0.02013971284031868,
            0.03350536525249481,
            0.030841980129480362,
            -0.0004182318807579577,
            -0.01513634342700243,
            -0.018138501793146133,
            -0.011126099154353142,
            0.010188821703195572,
            0.01904486119747162,
            -0.014567790552973747,
            0.016382375732064247,
            -0.005146997515112162,
            0.022406768053770065,
            0.02092617191374302,
            0.007091181818395853,
            -0.0026973134372383356,
            0.018299657851457596,
            -0.00906160194426775,
            -0.027380742132663727,
            -0.0051685054786503315,
            0.010195243172347546,
            0.007477272767573595,
            -0.02011740952730179,
            0.02935132384300232,
            0.03601812571287155,
            -0.005104906857013702,
            -0.012813474982976913,
            -0.005005659535527229,
            -0.016185367479920387,
            -0.002851100405678153,
            -0.004997479263693094,
            -0.011327247135341167,
            -0.011044006794691086,
            0.03602638840675354,
            0.013925124891102314,
            0.0047220150008797646,
            0.02743690088391304,
            -0.005328583996742964,
            0.05696457251906395,
            -0.026378514245152473,
            0.01137946080416441,
            -0.027531685307621956,
            0.04328330606222153,
            0.006750986911356449,
            0.015220820903778076,
            -0.015690652653574944,
            -0.0064143771305680275,
            0.0049425456672906876,
            0.001897039357572794,
            -0.00130532740149647,
            0.015523713082075119,
            0.028972668573260307,
            -0.031643498688936234,
            -0.04454104229807854,
            0.022869156673550606,
            0.011909151449799538,
            0.013532828539609909,
            -0.0010959552600979805,
            0.01991347037255764,
            -0.04517301917076111,
            -0.006931839045137167,
            -0.007738678250461817,
            -0.016713066026568413,
            -0.003919417038559914,
            0.021640079095959663,
            -0.02792527712881565,
            0.003043764503672719,
            0.047201357781887054,
            -0.004182863049209118,
            -0.007857345044612885,
            0.014831109903752804,
            0.007676432374864817,
            -0.015368194319307804,
            -0.00271282228641212,
            -0.0056965951807796955,
            0.031640853732824326,
            -0.008900049142539501,
            -0.02913620136678219,
            -0.028677597641944885,
            -0.022616656497120857,
            -0.010739302262663841,
            -0.009329330176115036,
            -0.042625200003385544,
            0.021480735391378403,
            -0.0013011546107009053,
            0.013507765717804432,
            -0.018406454473733902,
            -0.0011622196761891246,
            -0.009567472152411938,
            0.0014203762402758002,
            0.013708285056054592,
            -0.012302677147090435,
            0.022014006972312927,
            0.030107490718364716,
            0.02009071595966816,
            0.0296351108700037,
            0.000559719861485064,
            -0.029494918882846832,
            0.001110687735490501,
            -0.006278306245803833,
            -0.008900443091988564,
            -0.049465619027614594,
            -0.012796510010957718,
            -0.010285634547472,
            -0.004450089763849974,
            -0.0012165755033493042,
            -0.006518783047795296,
            -0.03483309969305992,
            0.01064563449472189,
            -0.00022099228226579726,
            0.03805173933506012,
            0.0065134186297655106,
            0.0034111065324395895,
            0.0006153883878141642,
            0.00924330111593008,
            0.02198592945933342,
            0.0051084039732813835,
            0.01890961453318596,
            -0.007000815588980913,
            -0.007209614850580692,
            -0.021696805953979492,
            -0.002470248145982623,
            -0.02183607779443264,
            -0.004616891499608755,
            0.02801881916821003,
            -0.0026105856522917747,
            0.020530426874756813,
            0.00442964443936944,
            -0.0028221842367202044,
            0.021054435521364212,
            0.03420319780707359,
            0.010898640379309654,
            -0.016519229859113693,
            0.027730725705623627,
            -0.0070137325674295425,
            0.03634371608495712,
            -0.03400517627596855,
            -0.03008429892361164,
            0.031259939074516296,
            0.002351604402065277,
            0.025666605681180954,
            0.03577803820371628,
            0.024335509166121483,
            -0.016249461099505424,
            -0.005830492824316025,
            -0.01383289135992527,
            0.010269503109157085,
            -0.0024070499930530787,
            0.0011711138067767024,
            0.033510223031044006,
            -0.005314975045621395,
            0.015456357039511204,
            0.01932031847536564,
            -0.007273341994732618,
            0.017931591719388962,
            -0.02904939278960228,
            -0.011701668612658978,
            -0.015197886154055595,
            -0.0012191232526674867,
            0.02462749369442463,
            0.015463048592209816,
            -0.029628654941916466,
            0.018653491511940956,
            -0.002795716281980276,
            0.015436383895576,
            -0.010452459566295147,
            0.03764387592673302,
            0.03995732590556145,
            0.018794627860188484,
            0.02849598601460457,
            -0.014714167453348637,
            -0.00022506753157358617,
            0.029415978118777275,
            0.022305823862552643,
            0.021464304998517036,
            -0.038772549480199814,
            0.0064369370229542255,
            -0.03427019342780113,
            0.02883932925760746,
            0.006653695832937956,
            0.025294765830039978,
            -0.0314396433532238,
            0.014016851782798767,
            -0.023952985182404518,
            -0.023860953748226166,
            -0.027188871055841446,
            0.01818625070154667,
            -0.020387444645166397,
            0.005143039859831333,
            0.019710468128323555,
            -0.03075295500457287,
            -0.027429888024926186,
            -0.017440227791666985,
            -0.01585184410214424,
            -0.01585986837744713,
            -0.0006510688690468669,
            -0.015925446525216103,
            0.016168346628546715,
            -0.009439622052013874,
            -0.004640600178390741,
            0.02258629910647869,
            0.009581306017935276,
            -0.04639670252799988,
            0.03188733011484146,
            -0.011027744971215725,
            0.014063659124076366,
            0.02376878820359707,
            -0.007699302863329649,
            -0.0057439436204731464,
            -0.019395407289266586,
            0.002969384891912341,
            -0.0285086240619421,
            -0.02371719665825367,
            -0.0027862407732754946,
            -0.005110276862978935,
            0.02901323139667511,
            0.012101593427360058,
            0.004210308659821749,
            0.021876448765397072,
            0.045984603464603424,
            0.027180710807442665,
            0.008651306852698326,
            0.008239217102527618,
            0.004716034047305584,
            -0.024236006662249565,
            0.04316624626517296,
            0.023497218266129494,
            -0.02429627999663353,
            -0.018375778570771217,
            -0.024300772696733475,
            -0.025733182206749916,
            0.0028772070072591305,
            0.004783289972692728,
            -0.0032338369637727737,
            -0.003418906359001994,
            -0.025265730917453766,
            -0.017297198995947838,
            0.017555516213178635,
            0.009434434585273266,
            -0.0012542835902422667,
            -0.003593891393393278,
            0.012893796898424625,
            0.0142240384593606,
            0.006779920309782028,
            0.00497129512950778,
            0.0285154078155756,
            0.01633382961153984,
            0.0047051748260855675,
            0.018669117242097855,
            -0.0007093444583006203,
            0.047603510320186615,
            -0.017520900815725327,
            0.02111174538731575,
            -0.009488673880696297,
            0.00843879021704197,
            0.02775486372411251,
            0.023405471816658974,
            0.012409166432917118,
            -0.02554461359977722,
            -0.006232463289052248,
            -0.05230429023504257,
            -0.03563675284385681,
            0.03189105540513992,
            0.014757286757230759,
            0.0011397921480238438,
            -0.040333256125450134,
            0.007127676624804735,
            -0.029645757749676704,
            0.01113482378423214,
            -0.01758579909801483,
            -0.000273361976724118,
            0.028091849759221077,
            -0.04778185114264488,
            0.027576981112360954,
            0.017704421654343605,
            -0.023831885308027267,
            -0.020618123933672905,
            0.011292854323983192,
            -0.0031416777055710554,
            0.007565950974822044,
            0.03450842574238777,
            0.021538838744163513,
            -0.004619849845767021,
            0.020741712301969528,
            0.008471528068184853,
            0.010445533320307732,
            -0.0342644527554512,
            -0.045091819018125534,
            -0.002473682863637805,
            0.01770993508398533,
            -0.0008034985512495041,
            0.0049013677053153515,
            -0.017097048461437225,
            0.010498501360416412,
            -0.035710569471120834,
            0.01919271983206272,
            -0.0025492042768746614,
            -0.0018472075462341309,
            0.019962504506111145,
            -0.029788034036755562,
            0.02346915565431118,
            0.012839793227612972,
            0.023327209055423737,
            0.03276066109538078,
            0.014557424001395702,
            0.013166306540369987,
            -0.028310565277934074,
            -0.02925967425107956,
            0.022039322182536125,
            0.015384315513074398,
            0.026610344648361206,
            0.04059093818068504,
            0.03819770738482475,
            0.011080624535679817,
            -0.016458768397569656,
            0.014420942403376102,
            0.008650694042444229,
            0.0050876559689641,
            -0.0020144011359661818,
            -0.02806430496275425,
            -0.03497255593538284,
            0.0100380415096879,
            0.04228693246841431,
            -0.012044230476021767,
            -0.01737685687839985,
            0.008885814808309078,
            -0.01508493721485138,
            0.010602387599647045,
            -0.014481167308986187,
            -0.03736329451203346,
            0.01184665784239769,
            0.01535456907004118,
            -0.0007697060354985297,
            0.02411140874028206,
            -0.02901574969291687,
            0.015937408432364464,
            0.009239458478987217,
            0.009607519023120403,
            0.022450363263487816,
            -0.019885849207639694,
            -0.01981598511338234,
            -0.008155843243002892,
            -0.05206477642059326,
            -0.02923472225666046,
            0.03487419709563255,
            0.0062681944109499454,
            -0.009955625049769878,
            0.010462462902069092,
            0.0005669449456036091,
            0.005241131875663996,
            0.015834493562579155,
            0.03419991955161095,
            -0.011565488763153553,
            0.010481157340109348,
            -0.015091843903064728,
            0.015600391663610935,
            0.006759317126125097,
            0.016106950119137764,
            0.014365799725055695,
            -0.0143760796636343,
            0.0005147939664311707,
            -0.0011895145289599895,
            0.00010660724365152419,
            0.04226769134402275,
            -0.013234731741249561,
            0.03817109763622284,
            -0.007720739580690861,
            0.025416340678930283,
            0.015871752053499222,
            0.01032282691448927,
            -0.014287220314145088,
            0.014702326618134975,
            0.005993842147290707,
            -0.005540058482438326,
            -0.006964579690247774,
            0.020969683304429054,
            0.03066161461174488,
            -0.026971690356731415,
            -0.032237932085990906,
            0.002937230747193098,
            0.02591569349169731,
            -0.030090197920799255,
            -0.007207353599369526,
            0.025851745158433914,
            -0.009445616975426674,
            0.009534013457596302,
            0.00956056360155344,
            -0.008881691843271255,
            -0.025157852098345757,
            -0.010012305341660976,
            0.020220713689923286,
            -0.017294911667704582,
            0.08239111304283142,
            -0.034396689385175705,
            -0.026867784559726715,
            -0.03130165487527847,
            0.023929787799715996,
            0.03566857799887657,
            -0.006704019848257303,
            0.0031583444215357304,
            0.003970683552324772,
            -0.011482764966785908,
            0.011605918407440186,
            0.005198299419134855,
            0.01778642274439335,
            0.009009880013763905,
            0.0028307768516242504,
            0.012412069365382195,
            0.056321658194065094,
            0.029649987816810608,
            0.0205635204911232,
            0.013838749378919601,
            -0.005123443901538849,
            -0.024313606321811676,
            -0.021944765001535416,
            0.013507951982319355,
            0.008572450838983059,
            -0.018757086247205734,
            -0.02414441853761673,
            -0.024214867502450943,
            -0.0316576324403286,
            -0.025362906977534294,
            0.03462151065468788,
            0.0009253497119061649,
            -0.0033245044760406017,
            -0.03035331889986992,
            -0.001996956067159772,
            0.038326069712638855,
            -0.022245772182941437,
            0.0580839067697525,
            -0.058659497648477554,
            -0.02604069747030735,
            0.020818904042243958,
            -0.016690492630004883,
            -0.0215905774384737,
            0.007225904613733292,
            -0.011078129522502422,
            -0.00685529038310051,
            0.0024302464444190264,
            -0.010630984790623188,
            0.027237668633461,
            -0.03556984290480614,
            0.030281228944659233,
            0.010420078411698341,
            -0.025937458500266075,
            0.023876488208770752,
            0.009613704867661,
            0.00535312807187438,
            -0.005599969066679478,
            0.002870091237127781,
            0.030218807980418205,
            -0.029619451612234116,
            -0.006748794112354517,
            -0.025067633017897606,
            -0.04064285755157471,
            0.028785061091184616,
            0.023485518991947174,
            0.02815582975745201,
            0.014905780553817749,
            -0.016947371885180473,
            0.027525968849658966,
            0.005899100098758936,
            0.02803584560751915,
            0.0006055844132788479,
            0.012250121682882309,
            -0.015419702976942062,
            0.0065138982608914375,
            -0.020376993343234062,
            0.02224390208721161,
            0.04653865844011307,
            -0.012491005472838879,
            0.03460628166794777,
            -0.028539109975099564,
            0.0003809780755545944,
            -0.003677502041682601,
            -0.022490091621875763,
            -0.03617827594280243,
            0.02927895449101925,
            0.023529451340436935,
            0.02986917272210121,
            -0.011489342898130417,
            0.0012702711392194033,
            0.04144579917192459,
            -0.03826775401830673,
            -0.003371134400367737,
            -0.026596369221806526,
            -0.0007383093470707536,
            0.020998531952500343,
            0.028703249990940094,
            0.00568719208240509,
            -0.01968211680650711,
            0.025045018643140793,
            0.006764538586139679,
            -0.00925353541970253,
            0.0007328547653742135,
            0.031214237213134766,
            -0.0236048586666584,
            0.033694542944431305,
            0.02916014939546585,
            -0.020640188828110695,
            0.01965833641588688,
            0.0008970974013209343,
            -0.022026296705007553,
            -0.020960958674550056,
            -0.021375121548771858,
            0.02129482477903366,
            -0.01670020818710327,
            -0.00614981958642602,
            0.03206944838166237,
            0.03435022011399269,
            0.012109427712857723,
            -0.015127927996218204,
            0.0005056991940364242,
            -0.021178748458623886,
            0.01439622975885868,
            0.014876451343297958,
            -0.007017870433628559,
            -0.009828883223235607,
            0.004816622473299503,
            0.00606959592550993,
            0.027154380455613136,
            0.011604714207351208,
            0.03463321551680565,
            -0.011719560250639915,
            0.007632040418684483,
            0.0036905407905578613,
            -0.024515945464372635,
            -0.01226002350449562,
            -0.04694681987166405,
            0.025187861174345016,
            -0.011700177565217018,
            -0.035947035998106,
            0.009770403616130352,
            0.012818017043173313,
            0.028037160634994507,
            0.02989303693175316,
            0.013521510176360607,
            -0.008010524325072765,
            -0.045952074229717255,
            0.012693211436271667,
            0.003952146042138338,
            0.031272999942302704,
            0.0052564567886292934,
            -0.029623571783304214,
            0.02504999004304409,
            0.01096409186720848,
            -0.007983418181538582,
            6.280948582571e-05,
            -0.018234072253108025,
            0.01943359151482582,
            0.0202022772282362,
            -0.00843945425003767,
            -0.004742950666695833,
            0.020304936915636063,
            0.012851719744503498,
            0.029901398345828056,
            -0.00014870610903017223,
            0.027898015454411507,
            0.028165265917778015,
            0.0004982678219676018,
            -0.034900687634944916,
            0.034502409398555756,
            -0.0012565631186589599,
            0.021456493064761162,
            -0.015661288052797318,
            0.00402582623064518,
            -0.014278016984462738,
            -0.027723532170057297,
            -0.010819196701049805,
            0.012890726327896118,
            0.0021625470835715532,
            -0.019947374239563942,
            -0.0021709389984607697,
            0.009736476466059685,
            0.0006027717026881874,
            -0.030080018565058708,
            -0.01720910519361496,
            -0.00200180453248322,
            0.03731052204966545,
            -0.005741090048104525,
            -0.010985209606587887,
            0.03296886384487152,
            0.009650728665292263,
            0.011111076921224594,
            0.032358407974243164,
            -0.015686562284827232,
            0.04318679869174957,
            0.015466652810573578,
            -0.018981201574206352,
            -0.028310813009738922,
            0.01353625301271677,
            -0.0036661287304013968,
            0.0036604530178010464,
            0.0294065922498703,
            -0.02618243731558323,
            0.013567234389483929,
            0.015555130317807198,
            -0.009773649275302887,
            -0.02210511825978756,
            -0.009615886956453323,
            0.0097274798899889,
            0.01432734727859497,
            -0.012324600480496883,
            -0.022541267797350883,
            0.009920320473611355,
            -0.017109280452132225,
            -0.007932785898447037,
            0.025228941813111305,
            -0.025996163487434387,
            0.0303819440305233,
            0.0008925126167014241,
            0.006764792371541262,
            0.026810992509126663,
            -0.00862882286310196,
            0.01689893938601017,
            0.04802239313721657,
            -0.044479481875896454,
            -0.02523454837501049,
            -0.04350957274436951,
            0.006105204578489065,
            -0.04246602579951286,
            0.008420851081609726,
            -0.029521476477384567,
            -0.007377265486866236,
            0.02028346247971058,
            0.04697277769446373,
            0.019991004839539528,
            0.0074575236067175865,
            0.036656927317380905,
            0.024920931085944176,
            -0.026882192119956017,
            -0.020044904202222824,
            -0.03945985063910484,
            -0.01530719269067049,
            0.016218362376093864,
            0.028237609192728996,
            0.018732711672782898,
            0.0073134698905050755,
            0.011113290674984455,
            -0.00610084505751729,
            -0.030212249606847763,
            -0.0009081026073545218,
            0.013071106746792793,
            -0.013842205516994,
            -0.002387149492278695,
            0.0035623079165816307,
            -0.03235317021608353,
            0.03656240180134773,
            -0.028991634026169777,
            -0.03697682172060013,
            0.02603154629468918,
            -0.0188565906137228,
            -0.02036278322339058,
            -0.010469963774085045,
            -0.009051681496202946,
            -0.015064121223986149,
            0.03331537917256355,
            0.02141677588224411,
            0.029201477766036987,
            0.016670098528265953,
            -0.013791235163807869,
            0.009779614396393299,
            0.020809948444366455,
            -0.01694541610777378,
            0.0004755845002364367,
            -0.0017614525277167559,
            0.022821974009275436,
            0.016275642439723015,
            -0.041089050471782684,
            0.02872665785253048,
            -0.019374290481209755,
            -0.005234349053353071,
            0.011463218368589878,
            0.003147655399516225,
            0.02577994391322136,
            -0.030996521934866905,
            -0.004672888666391373,
            -0.0025592155288904905,
            0.007760369684547186,
            -0.00332145718857646,
            -0.030247408896684647,
            0.019422797486186028,
            0.022446131333708763,
            0.010085084475576878,
            0.011871150694787502,
            -0.007630556356161833,
            -0.003324387827888131,
            -0.017422597855329514,
            -0.0022444198839366436,
            -0.002339523285627365,
            0.016191715374588966,
            -0.013280739076435566,
            0.027077697217464447,
            0.0031802249141037464,
            -0.028343914076685905,
            -0.01374618150293827,
            -0.01125242281705141,
            -0.013697081245481968,
            -0.0026683853939175606,
            0.012334069237112999,
            -0.020059019327163696,
            -0.005916958674788475,
            -0.009170872159302235,
            0.02448272705078125,
            -0.003086294513195753,
            -0.006849896162748337,
            -0.011348630301654339,
            -0.034709468483924866,
            -0.0064760600216686726,
            -0.026391124352812767,
            0.004028405994176865,
            0.028140362352132797,
            -0.02307325042784214,
            0.023227300494909286,
            -0.015811137855052948,
            -0.02624930627644062,
            -0.030957235023379326,
            -0.030444860458374023,
            -0.006812345702201128,
            0.008751352317631245,
            -0.008731786161661148,
            -0.043265994638204575,
            0.010435506701469421,
            -0.010667458176612854,
            -0.004673026502132416,
            0.04107820615172386,
            0.014943230897188187,
            -0.02559124119579792,
            0.025286907330155373,
            0.00808748323470354,
            -0.02797827683389187,
            0.024348128587007523,
            -0.025058291852474213,
            0.016838617622852325,
            0.013973671942949295,
            -0.03543829545378685,
            0.01172619592398405,
            -0.0247461199760437,
            -0.0008156540570780635,
            -0.0008990026544779539,
            -0.02351495251059532,
            0.0021348726004362106,
            -0.009927721694111824,
            0.02976359985768795,
            0.022822091355919838,
            -0.01650181971490383,
            -0.03287339210510254,
            0.01980403997004032,
            0.03246484696865082,
            0.006770599167793989,
            0.025988858193159103,
            0.0062212529592216015,
            -0.02690795809030533,
            0.005625009071081877,
            -0.03506949543952942,
            -0.009538392536342144,
            0.007848085835576057,
            0.038281381130218506,
            -0.004189147148281336,
            0.007049150764942169,
            -0.03052835538983345,
            -0.007271588779985905,
            -0.002887002658098936,
            -0.003616067348048091,
            0.0017493253108114004,
            0.006208629347383976,
            -0.030731795355677605,
            -0.003049469320103526,
            0.00559397554025054,
            0.019087186083197594,
            0.00426485575735569,
            0.013277077116072178,
            -0.01231306791305542,
            0.03292005881667137,
            -0.013027850538492203,
            0.005749210715293884,
            0.028937509283423424,
            0.02053263783454895,
            -0.008707472123205662,
            0.01349600963294506,
            0.02987820655107498,
            -0.006166065577417612,
            0.016475386917591095,
            0.024312958121299744,
            0.0332353450357914,
            0.00967974029481411,
            -0.0029772177804261446,
            0.003161731408908963,
            0.012349323369562626,
            -0.01252028625458479,
            0.020437033846974373,
            -0.02618546411395073,
            0.012529857456684113,
            -0.005933504551649094,
            0.004766861442476511,
            -0.001079243840649724,
            -0.013025813736021519,
            0.004040840081870556,
            -0.007598607335239649,
            0.04034951329231262,
            0.0004884639638476074,
            -0.019991975277662277,
            -0.01332500297576189,
            0.0197554063051939,
            0.014602015726268291,
            0.02357686497271061,
            0.02028236724436283,
            0.024982167407870293,
            -0.030770380049943924,
            -0.016786299645900726,
            -0.026468396186828613,
            0.0022927960380911827,
            -0.03259102627635002,
            0.029958337545394897,
            0.043559350073337555,
            -0.034042492508888245,
            -0.02195330522954464,
            -0.04256212338805199,
            0.014764071442186832,
            -0.0024115866981446743,
            -0.01667696051299572,
            -0.032904140651226044,
            -0.02468697912991047,
            0.02102999947965145,
            0.014554433524608612,
            -0.009259512647986412,
            0.00658166129142046,
            -0.04157307371497154,
            -0.014742850326001644,
            0.04298373684287071,
            -0.0058224559761583805,
            0.02295878902077675,
            0.026624834164977074,
            -0.027730977162718773,
            -0.02645295113325119,
            0.026598073542118073,
            0.006133245304226875,
            0.0015596623998135328,
            0.002743374789133668,
            0.001165495254099369,
            0.017139660194516182,
            0.0013714679516851902,
            0.03299574553966522,
            -0.027561167255043983,
            0.017911452800035477,
            -0.002327543217688799,
            -0.013118771836161613,
            0.002029254799708724,
            -0.024011828005313873,
            0.030038611963391304,
            0.008792377077043056,
            -0.026346759870648384,
            0.028878990560770035,
            -0.0022183419205248356,
            -0.01084867212921381,
            0.019627386704087257,
            0.004257614258676767,
            0.0305275060236454,
            -0.0016398165607824922,
            0.03871209919452667,
            -0.027035631239414215,
            -0.01796543411910534,
            -0.02028554305434227,
            0.023054780438542366,
            -0.0036998202558606863,
            -0.03755953162908554,
            -0.014041764661669731,
            -0.020411347970366478,
            -0.05203088000416756,
            0.013230766169726849,
            0.0019338119309395552,
            -0.003816345240920782,
            -0.03435518965125084,
            -0.001921537914313376,
            -0.025444403290748596,
            0.03502676263451576,
            0.010720817372202873,
            0.016409967094659805,
            -0.001987010007724166,
            0.007671115919947624,
            0.035813964903354645,
            0.00635878648608923,
            0.02135402522981167,
            -0.01884816773235798,
            0.02711787074804306,
            -0.002865714719519019,
            -0.012477031908929348,
            0.009477601386606693,
            -0.011637945659458637,
            -0.022881703451275826,
            -0.013162672519683838,
            -0.025454411283135414,
            -0.005610164720565081,
            -0.007548054214566946,
            -0.03345322236418724,
            -0.0455009751021862,
            0.012971052899956703,
            -0.021936170756816864,
            -0.034393757581710815,
            -0.005289423745125532,
            -0.021050531417131424,
            0.03131281957030296,
            -0.02442275546491146,
            -0.02318432554602623,
            0.0083316620439291,
            -0.00360634527169168,
            -0.012630060315132141,
            -0.010536544024944305,
            -0.019527267664670944,
            -0.015625864267349243,
            0.026330547407269478,
            0.017191758379340172,
            -0.009942518547177315,
            0.013790617696940899,
            0.024683402851223946,
            0.00024926941841840744,
            0.018837204203009605,
            0.025674760341644287,
            -0.007657198701053858,
            0.011383337900042534,
            -0.006123852450400591,
            -0.052735406905412674,
            0.011567930690944195,
            -0.016532132402062416,
            0.0035457180347293615,
            -0.03303155675530434,
            0.007792704273015261,
            -0.010238495655357838,
            -0.0056027318350970745,
            -0.024154556915163994,
            -0.009950162842869759,
            0.014266119338572025,
            0.011782116256654263,
            0.021933360025286674,
            0.0024120293091982603,
            0.03633435070514679,
            0.01779405027627945,
            0.0021394037175923586,
            0.030367257073521614,
            0.011334526352584362,
            0.013522513210773468,
            0.047567225992679596,
            -0.02735009230673313,
            -0.006319756153970957,
            0.003966949414461851,
            0.016059063374996185,
            -0.022567149251699448,
            -0.01955445669591427,
            -0.01869688183069229,
            -0.0010533479508012533,
            0.030263734981417656,
            0.024507099762558937,
            -0.017678841948509216,
            -0.02556411735713482,
            0.016151534393429756,
            0.027952978387475014,
            -0.038781266659498215,
            0.01596665568649769,
            -0.03270240128040314,
            -0.007150658871978521,
            0.055252380669116974,
            -0.016160249710083008,
            0.013679970987141132,
            -0.01677512750029564,
            -0.00658819917589426,
            -0.015418840572237968,
            0.0024050420615822077,
            -0.025312161073088646,
            -0.010761572048068047
        ],
        [
            0.037462424486875534,
            -0.0172992255538702,
            -0.007162174209952354,
            -0.005453402642160654,
            -0.029247259721159935,
            -0.05307871848344803,
            -0.022958403453230858,
            -0.034539178013801575,
            -0.015091519802808762,
            0.01661294884979725,
            0.03392734378576279,
            0.016739875078201294,
            0.009667626582086086,
            -0.023815684020519257,
            0.02655010111629963,
            0.02258487418293953,
            0.011038929224014282,
            0.009946578182280064,
            0.016464240849018097,
            -0.004224730655550957,
            0.021159661933779716,
            -0.017623240128159523,
            -0.0389704667031765,
            0.0249222069978714,
            0.0371754989027977,
            0.021632375195622444,
            0.03331049904227257,
            -0.010231450200080872,
            -0.008595609106123447,
            0.010280029848217964,
            -0.035770900547504425,
            0.010560590773820877,
            0.0034422704484313726,
            -0.00634415028616786,
            0.033046904951334,
            0.008852457627654076,
            0.006095350254327059,
            0.04814927652478218,
            -0.0014503480633720756,
            0.022520657628774643,
            -0.01616360805928707,
            0.01800524815917015,
            0.018862007185816765,
            0.017740629613399506,
            0.0070268260315060616,
            -0.004149796906858683,
            -0.007423714268952608,
            -0.02532034181058407,
            -0.0018145383801311255,
            0.008204427547752857,
            -0.0306133721023798,
            -0.01265482697635889,
            -0.001516930409707129,
            0.00319438218139112,
            -0.02923070266842842,
            -0.02800154685974121,
            0.008100624196231365,
            -0.02242378517985344,
            0.006948341149836779,
            -0.0034619050566107035,
            0.041763562709093094,
            -0.031774092465639114,
            0.014807591214776039,
            0.025497622787952423,
            0.015163657255470753,
            0.02537454664707184,
            -0.002777299378067255,
            0.005086228717118502,
            0.02115657366812229,
            -0.011982572264969349,
            0.012485917657613754,
            0.02643148973584175,
            0.025200163945555687,
            -0.009495568461716175,
            0.024113837629556656,
            -0.015779104083776474,
            0.03134695068001747,
            0.0128870764747262,
            0.026480216532945633,
            0.008079390972852707,
            0.006709492299705744,
            1.7376216419506818e-05,
            -0.01563888043165207,
            -0.020743101835250854,
            0.02432452328503132,
            0.03304431959986687,
            -0.016632894054055214,
            -0.03184448927640915,
            0.04517374187707901,
            0.020362984389066696,
            -0.042954329401254654,
            0.02654058299958706,
            0.006242583971470594,
            -0.02562670409679413,
            -0.023741483688354492,
            -0.010382839478552341,
            -0.037885598838329315,
            -0.02053818851709366,
            -0.009965118020772934,
            0.019891049712896347,
            -0.0325186513364315,
            -0.033300016075372696,
            -0.016970010474324226,
            -0.0006587470998056233,
            -0.010740329511463642,
            0.034817006438970566,
            0.002437249757349491,
            0.021054992452263832,
            0.03186966851353645,
            -0.0009006957989186049,
            -0.03604623302817345,
            0.02715875394642353,
            -0.03407644107937813,
            -0.031165922060608864,
            -0.03987635672092438,
            -0.008219705894589424,
            0.023084599524736404,
            0.035128623247146606,
            -0.0016656764782965183,
            -0.029659470543265343,
            -0.022817732766270638,
            -0.0016517512267455459,
            0.07314089685678482,
            -0.008457966148853302,
            -0.0073293764144182205,
            0.02094789780676365,
            -0.0296139158308506,
            0.032246749848127365,
            -0.008821270428597927,
            0.014849857427179813,
            -0.0077273366041481495,
            -0.011862238869071007,
            -0.009176336228847504,
            0.009954358451068401,
            -0.01425703801214695,
            0.008983412757515907,
            0.012439585290849209,
            0.011346100829541683,
            0.012524168007075787,
            -0.014524292200803757,
            0.0193934366106987,
            -0.0303528793156147,
            0.015164553187787533,
            0.004092874471098185,
            -0.014068764634430408,
            0.03551262244582176,
            0.007331252563744783,
            0.044924717396497726,
            -0.02193414233624935,
            -0.02067643776535988,
            -0.02303515188395977,
            -0.04596015438437462,
            -0.0016439141472801566,
            -0.017568355426192284,
            0.028759462758898735,
            0.02234582044184208,
            0.010254454798996449,
            -0.01841157115995884,
            -0.014933306723833084,
            0.042215682566165924,
            -0.0005011637113057077,
            -0.000744799617677927,
            -0.025095637887716293,
            0.012937696650624275,
            0.02040846273303032,
            0.06902647018432617,
            0.011821143329143524,
            0.0042344327084720135,
            -0.022791875526309013,
            0.00026355794398114085,
            -0.001717560226097703,
            0.0223207026720047,
            -0.025139715522527695,
            0.023688266053795815,
            0.004878208506852388,
            -0.03626580536365509,
            0.030551545321941376,
            0.014027919620275497,
            -0.0602138414978981,
            -1.953909122676123e-05,
            0.032042574137449265,
            -0.02355167083442211,
            -0.012764411978423595,
            -0.009102370589971542,
            -0.023559996858239174,
            0.013424700126051903,
            -0.0073399110697209835,
            -0.009145964868366718,
            0.007944448851048946,
            -0.022056419402360916,
            0.008022379130125046,
            0.021365495398640633,
            0.0347081683576107,
            0.026503728702664375,
            0.001450283918529749,
            -0.03338894993066788,
            -0.003094883169978857,
            -0.007623229641467333,
            -0.006672508083283901,
            0.011553408578038216,
            0.012234887108206749,
            0.03460921719670296,
            0.009951462969183922,
            -0.022957269102334976,
            -0.0038863113150000572,
            0.012996773235499859,
            -0.0024626306258141994,
            -0.01800076849758625,
            -0.01775725558400154,
            -0.015967844054102898,
            -0.013405431061983109,
            0.002152112079784274,
            -0.021518146619200706,
            0.02743230015039444,
            -0.02720118872821331,
            0.0316169410943985,
            0.01970837451517582,
            0.0028956299647688866,
            -0.0198943093419075,
            0.009990309365093708,
            -0.002740987343713641,
            -0.056756749749183655,
            0.009232771582901478,
            -0.03078939951956272,
            0.025000661611557007,
            -0.024307163432240486,
            -0.04181046038866043,
            0.03327746316790581,
            -0.007011584006249905,
            0.025639275088906288,
            0.029636787250638008,
            -0.005694892257452011,
            0.013888110406696796,
            0.033750079572200775,
            0.003260999685153365,
            0.011609829030930996,
            -0.005564404651522636,
            0.0007906820974312723,
            0.01881983131170273,
            -0.03871120139956474,
            0.012178385630249977,
            -0.021612029522657394,
            0.033634401857852936,
            0.027827145531773567,
            0.004107451532036066,
            0.014041444286704063,
            -0.002913873177021742,
            -0.004626194480806589,
            0.010736021213233471,
            0.03356991708278656,
            0.004151954781264067,
            -0.013542774133384228,
            0.013518989086151123,
            0.001652153325267136,
            0.0165064949542284,
            0.008771945722401142,
            -0.02256917580962181,
            -0.002419821685180068,
            0.014019069261848927,
            0.004690713249146938,
            0.0011543786386027932,
            0.002529251156374812,
            -0.009450680576264858,
            -0.002219283254817128,
            -0.021589424461126328,
            0.008582161739468575,
            0.025070706382393837,
            -0.0036318479105830193,
            -0.013278983533382416,
            -0.017395243048667908,
            -0.026706242933869362,
            -0.02699245885014534,
            0.023876728489995003,
            -0.057351913303136826,
            -0.05531420558691025,
            0.012746550142765045,
            -0.04225524142384529,
            -0.017778608947992325,
            -0.009887227788567543,
            -0.030641240999102592,
            -0.0173012875020504,
            0.008174820803105831,
            0.007566333282738924,
            -0.007975716143846512,
            0.0038528423756361008,
            0.01695253513753414,
            -0.018202604725956917,
            -0.0006640571518801153,
            -0.0025481011252850294,
            -0.024493863806128502,
            -0.01677018404006958,
            -0.010759417898952961,
            -0.005371328443288803,
            -0.010879280976951122,
            0.027449054643511772,
            0.01414897944778204,
            -0.009826896712183952,
            -0.019693836569786072,
            -0.012956772930920124,
            -0.012651260010898113,
            0.025714043527841568,
            -0.018513506278395653,
            0.011800859123468399,
            0.01162288524210453,
            -0.004881799686700106,
            -0.010525669902563095,
            0.005222084932029247,
            0.037111733108758926,
            0.03403255715966225,
            0.02842717431485653,
            -0.002134490292519331,
            -0.027629809454083443,
            -0.005581062287092209,
            -0.01050780899822712,
            0.023043377324938774,
            0.007451177574694157,
            0.009713285602629185,
            0.0197110865265131,
            -0.010092136450111866,
            0.0036763548851013184,
            0.014870384708046913,
            0.03776689991354942,
            -0.00046263987314887345,
            0.01259553711861372,
            0.03624293953180313,
            -0.013277444988489151,
            0.04619015008211136,
            -0.037501316517591476,
            -0.004112015478312969,
            0.017673371359705925,
            0.019193759188055992,
            0.01393114309757948,
            -0.027490682899951935,
            0.01678122952580452,
            -0.007119796704500914,
            0.007120092865079641,
            -0.0003018295392394066,
            0.027110641822218895,
            0.015598359517753124,
            0.014196191914379597,
            0.014297019690275192,
            0.005930600222200155,
            -0.01776067353785038,
            0.01272232923656702,
            0.006406118627637625,
            0.01946917176246643,
            0.02731703408062458,
            -0.007744994945824146,
            0.013399627059698105,
            0.028930801898241043,
            0.0345485545694828,
            0.009478973224759102,
            0.024989763274788857,
            0.02452842704951763,
            0.0013182677794247866,
            0.0029646873008459806,
            -0.017255518585443497,
            -0.006314516067504883,
            0.02886122278869152,
            0.00693019013851881,
            -0.020829008892178535,
            0.017181670293211937,
            0.033640507608652115,
            0.025515809655189514,
            -0.03360877186059952,
            -0.02055951952934265,
            -0.053302112966775894,
            -0.0340852364897728,
            -0.013617497868835926,
            0.01047254167497158,
            -0.022253450006246567,
            0.02401003986597061,
            -0.03186478838324547,
            0.004991674330085516,
            -0.007370925974100828,
            -0.01920330710709095,
            0.02845756709575653,
            -0.017695335671305656,
            -0.011974113993346691,
            -0.007958983071148396,
            -0.012501910328865051,
            -0.014021527022123337,
            0.028785403817892075,
            -0.0015455542597919703,
            0.0027504528407007456,
            -0.006426382344216108,
            -0.019291236996650696,
            0.005027140025049448,
            -0.00031376033439300954,
            0.001342418952845037,
            0.0014509474858641624,
            0.033796414732933044,
            0.0024039933923631907,
            -0.007364973891526461,
            0.016506725922226906,
            -0.013163279742002487,
            0.012407826259732246,
            -0.007363403681665659,
            -0.004232325125485659,
            -0.03317885100841522,
            0.0127362459897995,
            -0.01146321464329958,
            0.00776353944092989,
            0.009081822820007801,
            0.014133039861917496,
            -0.028701214119791985,
            -0.011506343260407448,
            -0.006676033604890108,
            0.0031293644569814205,
            0.038298603147268295,
            0.022161170840263367,
            -0.032436300069093704,
            -0.0007397976005449891,
            0.005624036304652691,
            -0.016589954495429993,
            0.0020554305519908667,
            -0.026977933943271637,
            -0.013099510222673416,
            0.02402825839817524,
            -0.015943916514515877,
            -0.046363282948732376,
            -0.009387968108057976,
            0.003245556028559804,
            -0.024364104494452477,
            0.010180768556892872,
            0.02351854369044304,
            0.002240317640826106,
            -0.028984276577830315,
            0.008416566997766495,
            -0.012158848345279694,
            -0.016535276547074318,
            0.002391970716416836,
            0.02537202648818493,
            0.03628977760672569,
            0.01487499289214611,
            -0.017933739349246025,
            0.016315944492816925,
            -0.040293462574481964,
            -0.030529705807566643,
            0.031023986637592316,
            0.03629251569509506,
            0.012600499205291271,
            0.02229263447225094,
            -0.02569044567644596,
            0.01832028105854988,
            -0.02752673253417015,
            0.011580792255699635,
            0.04318975284695625,
            0.012078115716576576,
            -0.0074457768350839615,
            0.0026224281173199415,
            0.009509542025625706,
            -0.0017889013979583979,
            -0.008610629476606846,
            9.642718941904604e-05,
            0.02625945396721363,
            -0.03032887913286686,
            0.03754027932882309,
            0.01569214276969433,
            -0.014546401798725128,
            -0.029019827023148537,
            -0.029797714203596115,
            -0.008215432055294514,
            -0.015037737786769867,
            0.000665507570374757,
            0.033654555678367615,
            -0.00699729286134243,
            -0.010831400752067566,
            -0.028278883546590805,
            0.03748529031872749,
            -0.02731221914291382,
            -0.015749305486679077,
            -0.02414364367723465,
            -0.0035491767339408398,
            -0.04315647855401039,
            -0.02089778333902359,
            0.033394571393728256,
            -0.03131088241934776,
            0.031076019629836082,
            -0.021402452141046524,
            0.030658336356282234,
            -0.02277197130024433,
            0.007342150434851646,
            -0.010461509227752686,
            0.030850015580654144,
            0.007141704671084881,
            -0.02289961837232113,
            -0.031705375760793686,
            0.05243053287267685,
            -0.011091976426541805,
            -0.0026638954877853394,
            0.03616797924041748,
            -0.005877976771444082,
            0.004702518694102764,
            -0.008714121766388416,
            -0.015795156359672546,
            0.010258697904646397,
            -0.0003211943549104035,
            0.02150164544582367,
            -0.01060451753437519,
            0.0039749424904584885,
            -0.035221733152866364,
            -0.006848886143416166,
            -0.016669105738401413,
            -0.02022533118724823,
            0.006318540778011084,
            0.026599889621138573,
            0.027990449219942093,
            -0.017356377094984055,
            0.030780673027038574,
            0.029476117342710495,
            0.03515692055225372,
            -0.014405366964638233,
            0.0011819193605333567,
            -0.016320664435625076,
            0.028674155473709106,
            0.03579733893275261,
            -0.015000469982624054,
            0.02354264445602894,
            0.009286436252295971,
            0.03389022499322891,
            -0.002994932932779193,
            0.0346127413213253,
            -0.021870985627174377,
            0.022837672382593155,
            0.009365455247461796,
            0.00924945343285799,
            -0.009364224970340729,
            0.005971010774374008,
            -0.007919427938759327,
            -0.0009742262773215771,
            0.011646510101854801,
            0.05100764334201813,
            0.014204797334969044,
            -0.019531890749931335,
            0.02391071245074272,
            -0.033313024789094925,
            0.00276971235871315,
            0.0006529469392262399,
            0.017690477892756462,
            -0.02008241042494774,
            -0.00540854362770915,
            -0.01439330168068409,
            -0.003803983563557267,
            -0.011584595777094364,
            -0.0049599469639360905,
            0.016864117234945297,
            0.013833285309374332,
            -0.013634988106787205,
            -0.0021684214007109404,
            0.015295249409973621,
            -0.015615053474903107,
            -0.0032369697000831366,
            0.0014646373456344008,
            -0.013597399927675724,
            -0.011191422119736671,
            -0.006104723550379276,
            0.007921922020614147,
            0.006953644100576639,
            -0.009673301130533218,
            -0.021968739107251167,
            -0.0012778640957549214,
            0.0098170330747962,
            -0.009771670214831829,
            -0.009065655060112476,
            -0.0517815463244915,
            0.0217764750123024,
            -0.01201992854475975,
            0.014620816335082054,
            -0.02340402454137802,
            0.01647804118692875,
            0.02091166004538536,
            0.010379093699157238,
            -0.014093765988945961,
            0.009480857290327549,
            -0.03416641801595688,
            -0.0007566976710222661,
            0.004421614576131105,
            0.007861591875553131,
            0.034972045570611954,
            0.004458503797650337,
            -0.013441130518913269,
            -0.004252343904227018,
            -0.009099449962377548,
            0.005685099400579929,
            -0.017358751967549324,
            -0.00025794847169891,
            0.024732548743486404,
            0.028445672243833542,
            0.002361454302445054,
            -0.006873071193695068,
            -0.004106214269995689,
            0.03612234815955162,
            -0.02165799029171467,
            0.033881623297929764,
            0.016620080918073654,
            0.001262943260371685,
            0.022307025268673897,
            -0.006732242181897163,
            0.04618856683373451,
            -0.0015600282931700349,
            -0.01944119669497013,
            0.010850528255105019,
            -0.014982244931161404,
            0.008228203281760216,
            0.003265101695433259,
            -0.008616753853857517,
            0.003412798745557666,
            0.022242389619350433,
            -0.022131213918328285,
            0.03483723849058151,
            0.03212301805615425,
            -0.019478173926472664,
            0.012502371333539486,
            0.032325927168130875,
            0.04331358149647713,
            -0.014132940210402012,
            0.021256539970636368,
            0.011431806720793247,
            0.016677794978022575,
            -0.046608179807662964,
            0.021863305941224098,
            0.030740153044462204,
            0.018018608912825584,
            -0.02064354531466961,
            -0.012692159041762352,
            0.017956623807549477,
            0.015490962192416191,
            -0.027597572654485703,
            0.03088098019361496,
            0.0584108866751194,
            -0.02394673228263855,
            0.027554644271731377,
            -0.027721578255295753,
            0.0014816968468949199,
            -0.011420641094446182,
            -0.04146327078342438,
            -0.017108000814914703,
            0.017306139692664146,
            0.01754305697977543,
            0.006308198906481266,
            0.031080586835741997,
            0.04412663355469704,
            -0.0009753030026331544,
            0.048783767968416214,
            -0.011879973113536835,
            0.02067510597407818,
            -0.004063848406076431,
            0.024313094094395638,
            0.010541464202105999,
            -0.026232115924358368,
            0.024943847209215164,
            0.0049215275794267654,
            -0.004659252241253853,
            0.016709329560399055,
            0.02321477234363556,
            0.015456981025636196,
            0.021939344704151154,
            0.00887585524469614,
            -0.029826277866959572,
            -0.0394524484872818,
            -0.027603523805737495,
            0.0030499021522700787,
            -0.007057856768369675,
            0.019870057702064514,
            0.0025032651610672474,
            -0.033466290682554245,
            0.023114869371056557,
            -0.01869983784854412,
            0.003809266025200486,
            0.02102399431169033,
            0.0227651484310627,
            -0.034947168081998825,
            -0.04765323922038078,
            0.01052467618137598,
            0.0337202250957489,
            0.009917549788951874,
            -0.008023451082408428,
            0.021853230893611908,
            -0.03389660641551018,
            0.030941633507609367,
            0.03240423649549484,
            0.0042215497232973576,
            -0.0008469134336337447,
            0.004507491830736399,
            -0.01958874613046646,
            0.06704992055892944,
            0.03564755246043205,
            -0.016529442742466927,
            -0.030868176370859146,
            -0.018026357516646385,
            -0.00669964449480176,
            -0.017364807426929474,
            0.025628354400396347,
            0.011300410144031048,
            0.0292480681091547,
            -0.01469802763313055,
            0.022303275763988495,
            0.00893887598067522,
            0.010125096887350082,
            -0.0325072817504406,
            -0.015254504978656769,
            -0.03291834518313408,
            -0.01642124354839325,
            0.028500869870185852,
            -0.014883744530379772,
            0.00030999715090729296,
            -0.02934310771524906,
            -0.011877727694809437,
            0.00416776305064559,
            0.0011123947333544493,
            0.0004637399688363075,
            0.0005924786091782153,
            -0.03201684728264809,
            0.01454049814492464,
            0.020265767350792885,
            0.001255387905985117,
            -0.018062224611639977,
            0.01012660376727581,
            0.018776720389723778,
            -0.021969329565763474,
            -0.03992186486721039,
            -0.014621507376432419,
            -0.0163688063621521,
            -0.02485668659210205,
            0.03150995075702667,
            -0.014163075014948845,
            0.027028247714042664,
            0.03316318243741989,
            0.0044788275845348835,
            0.0276066642254591,
            0.01479900162667036,
            -0.010881207883358002,
            -0.023948710411787033,
            -0.026374388486146927,
            -0.012012294493615627,
            -0.053992580622434616,
            0.008560082875192165,
            -0.026660332456231117,
            0.011301855556666851,
            -0.007793750148266554,
            0.0068952166475355625,
            -0.011501501314342022,
            -0.01769610308110714,
            0.008442237973213196,
            -0.012216191738843918,
            0.014889066107571125,
            0.015048183500766754,
            0.027812601998448372,
            -0.02890315279364586,
            -0.01783599704504013,
            -0.014342044480144978,
            0.003025555983185768,
            0.0069360919296741486,
            0.010601116344332695,
            -0.0022386773489415646,
            -0.023336295038461685,
            -0.0323014110326767,
            -0.005593618843704462,
            -0.010992285795509815,
            -0.011148699559271336,
            0.035818688571453094,
            -0.03575069084763527,
            0.029185157269239426,
            0.012394591234624386,
            0.039173342287540436,
            -0.023996254429221153,
            0.0013839489547535777,
            -0.02334311045706272,
            0.020128872245550156,
            -0.008450509048998356,
            -0.021754173561930656,
            -0.0056688059121370316,
            0.002238030079752207,
            -0.008069324307143688,
            -0.01224677637219429,
            0.002038385020568967,
            0.023150065913796425,
            0.019487816840410233,
            -0.013015475124120712,
            0.008557936176657677,
            -0.005477308761328459,
            0.03122594766318798,
            0.0016715310048311949,
            0.01783679984509945,
            0.01286316942423582,
            0.029954832047224045,
            -0.021879350766539574,
            -0.009042453952133656,
            -0.034594617784023285,
            -0.0047027007676661015,
            -0.026568908244371414,
            -0.010396769270300865,
            -0.009688565507531166,
            -0.023874592036008835,
            -0.014439623802900314,
            -0.014885671436786652,
            -0.0010701336432248354,
            0.005993337836116552,
            0.00032562282285653055,
            0.01218044850975275,
            0.004075007513165474,
            0.013646156527101994,
            -0.0036877801176160574,
            0.015053542330861092,
            -0.003142428118735552,
            0.023706939071416855,
            0.03997541591525078,
            0.00031094657606445253,
            -0.01180225983262062,
            0.01304242666810751,
            0.011906839907169342,
            0.0012692209566012025,
            0.03273900970816612,
            -0.036785490810871124,
            -0.011611245572566986,
            0.02467971108853817,
            0.015750786289572716,
            0.013718667440116405,
            -0.035505060106515884,
            -0.022421961650252342,
            -0.010678407736122608,
            -0.008481933735311031,
            -0.01866033487021923,
            0.012733973562717438,
            -0.017421413213014603,
            -0.007113883271813393,
            -0.010370511561632156,
            -0.00910966470837593,
            -0.031967129558324814,
            -0.004057077690958977,
            -0.0015636447351425886,
            -0.01811916008591652,
            0.023416172713041306,
            0.05484446510672569,
            0.027082188054919243,
            0.023313404992222786,
            0.03571154922246933,
            -0.02442852221429348,
            0.002208910183981061,
            0.0031681470572948456,
            0.015797624364495277,
            -0.02906220220029354,
            0.0018275455804541707,
            0.023874478414654732,
            0.029151540249586105,
            -0.028675563633441925,
            0.029564224183559418,
            -0.01565958373248577,
            0.04675785079598427,
            0.021901525557041168,
            0.03538759797811508,
            0.03170502930879593,
            0.01727757416665554,
            -0.0025849773082882166,
            0.030242640525102615,
            0.0023330182302743196,
            -0.01361562218517065,
            -0.0015919632278382778,
            -0.0030228723771870136,
            -0.004025974310934544,
            -0.028925012797117233,
            -0.015602391213178635,
            -0.05369952693581581,
            0.014724832028150558,
            0.008819515816867352,
            -0.011702003888785839,
            0.0019490699050948024,
            0.03592096269130707,
            -0.00651718582957983,
            0.003853523638099432,
            0.014822140336036682,
            -0.07014245539903641,
            -0.030302325263619423,
            -0.0039042553398758173,
            -0.022805223241448402,
            0.0036143239121884108,
            0.025544432923197746,
            -0.007554039359092712,
            0.01271102949976921,
            -0.015430765226483345,
            -0.011405960656702518,
            0.028657197952270508,
            -0.0278018768876791,
            0.025158405303955078,
            -0.006504859309643507,
            -0.028189491480588913,
            0.031848981976509094,
            -0.009749425575137138,
            0.001209867070429027,
            -0.04463556036353111,
            -0.023207930848002434,
            -0.021586231887340546,
            -0.017007160931825638,
            0.01472169253975153,
            -0.030628133565187454,
            0.016993418335914612,
            -0.025309208780527115,
            0.02999253012239933,
            -0.029868021607398987,
            -0.006648957263678312,
            -0.008778369054198265,
            0.009114217944443226,
            0.006321370135992765,
            -0.008613185957074165,
            0.02773568592965603,
            0.019883984699845314,
            0.041380275040864944,
            -0.0330757237970829,
            -0.011164713650941849,
            -0.0215749628841877,
            0.029317758977413177,
            -0.0016032075509428978,
            -0.02835887297987938,
            -0.00736013799905777,
            0.006984357722103596,
            -0.011661701835691929,
            -0.02835875377058983,
            0.01609596610069275,
            -0.005206259433180094,
            -0.021690305322408676,
            -0.029225792735815048,
            -0.020076483488082886,
            0.02560959756374359,
            0.04364650696516037,
            0.011971128173172474,
            0.04585207998752594,
            -0.017753439024090767,
            0.02069280855357647,
            0.014814096502959728,
            0.019803320989012718,
            -0.0006161275669001043,
            0.042030155658721924,
            0.009675595909357071,
            -0.04253316670656204,
            0.00854442734271288,
            0.006935961544513702,
            0.04337318614125252,
            -0.017091065645217896,
            0.021294498816132545,
            -0.007691245060414076,
            0.0027825324796140194,
            0.009649944491684437,
            -0.00815198477357626,
            0.0011796819744631648,
            -0.021718326956033707,
            0.025754032656550407,
            0.015028298832476139,
            -0.040607742965221405,
            0.03388288989663124,
            0.009260604158043861,
            -0.02284247800707817,
            -0.0230339877307415,
            -0.019701482728123665,
            -0.005530602298676968,
            -0.020768657326698303,
            0.003702331567183137,
            0.01454848051071167,
            0.009622118435800076,
            -0.006128042936325073,
            0.010015769861638546,
            -0.0156038086861372,
            0.048694927245378494,
            -0.0038981225807219744,
            -0.01669207215309143,
            -0.021371273323893547,
            0.059225987643003464,
            0.0262014027684927,
            0.022762369364500046,
            -0.024628018960356712,
            -0.007051925640553236,
            0.02763603813946247,
            0.0014176920522004366,
            0.0274608563631773,
            0.005568613298237324,
            0.018435796722769737,
            0.042655665427446365,
            -0.00528655806556344,
            -0.023278160020709038,
            -0.009254630655050278,
            0.012146909721195698,
            0.005526157096028328,
            0.01302490197122097,
            -0.002881251275539398,
            0.020276349037885666,
            0.034135397523641586,
            -0.013541332446038723,
            0.0074821156449615955,
            -0.019059769809246063,
            0.002880208659917116,
            -0.017514897510409355,
            -0.034412335604429245,
            0.018226169049739838,
            -0.010187789797782898,
            0.00565562816336751,
            0.044983167201280594,
            0.023380229249596596,
            0.018811514601111412,
            0.001901482348330319,
            0.0017841060180217028,
            -0.011773286387324333,
            0.02847667597234249,
            -0.004311379976570606,
            -0.022198546677827835,
            -0.0023240072187036276,
            -0.02595345489680767,
            0.025898527354002,
            0.0030248716939240694,
            -0.011359445750713348,
            0.017423951998353004,
            0.01915523037314415,
            0.04345084726810455,
            -0.02248968742787838,
            0.004741061944514513,
            -0.006070242729038,
            0.025487249717116356,
            -0.025039145722985268,
            -0.021480070427060127,
            -0.027520792558789253,
            -0.01864924281835556,
            0.020447824150323868,
            0.01574181765317917,
            -0.002805059775710106,
            0.019491853192448616
        ],
        [
            0.024910006672143936,
            0.016685180366039276,
            -0.024035660549998283,
            0.014217820949852467,
            0.006596915889531374,
            0.012598484754562378,
            0.022315647453069687,
            0.014101719483733177,
            0.0021117059513926506,
            -0.038316622376441956,
            0.007407913450151682,
            0.000714919064193964,
            -0.016468940302729607,
            0.007805656176060438,
            0.006540409754961729,
            0.019394131377339363,
            -0.006643851287662983,
            -0.01352920662611723,
            0.010599442757666111,
            0.017915062606334686,
            0.02418895810842514,
            0.02196057327091694,
            0.04389035701751709,
            -0.01183954905718565,
            -0.0013338286662474275,
            -0.006302656605839729,
            -0.06469491869211197,
            -0.017626209184527397,
            0.008476992137730122,
            -0.037746503949165344,
            -0.00637366808950901,
            0.01975029893219471,
            -0.029750384390354156,
            0.01564556173980236,
            0.015903331339359283,
            -0.03321685269474983,
            -0.0010267198085784912,
            -0.020958013832569122,
            -0.006202312186360359,
            -0.010296840220689774,
            0.040632545948028564,
            -0.021009918302297592,
            0.013801519758999348,
            0.0034331760834902525,
            -0.019514860585331917,
            -0.004083611071109772,
            0.00905238650739193,
            -0.013066775165498257,
            -0.02544867992401123,
            -0.004786001984030008,
            -0.0037886500358581543,
            -0.004439293406903744,
            0.004751595668494701,
            -0.0026319115422666073,
            -0.016458986327052116,
            -0.010252428241074085,
            -0.00998958945274353,
            -0.01854061707854271,
            -0.033479008823633194,
            0.0051140449941158295,
            0.020608630031347275,
            -0.013539598323404789,
            0.004240147769451141,
            0.017831172794103622,
            -0.0016665133880451322,
            0.010942241176962852,
            -0.02135605365037918,
            -0.009624669328331947,
            -0.03187708556652069,
            0.010340485721826553,
            0.016884300857782364,
            -0.011279514990746975,
            0.03108998015522957,
            0.006500785704702139,
            -0.0007007744861766696,
            0.044600315392017365,
            -0.009860752150416374,
            0.011385953985154629,
            -0.013497288338840008,
            -0.03244875371456146,
            -0.01395117025822401,
            0.008896750397980213,
            0.025685938075184822,
            0.008568929508328438,
            -0.010423059575259686,
            -0.01968158222734928,
            0.027179855853319168,
            -0.011807368136942387,
            -0.0255784522742033,
            0.026195388287305832,
            0.037936143577098846,
            -0.02060641162097454,
            -0.008612534031271935,
            -0.0006158840260468423,
            0.019015798345208168,
            0.0071619413793087006,
            -0.0007641024421900511,
            -0.01612386293709278,
            0.02330530807375908,
            0.0016830968670547009,
            0.054296985268592834,
            0.009160204790532589,
            -0.0035139068495482206,
            -0.021236082538962364,
            0.005326469894498587,
            0.0012643509544432163,
            0.020358460023999214,
            0.0025982011575251818,
            -0.04856075718998909,
            0.008053119294345379,
            -0.015843119472265244,
            -0.03709033504128456,
            0.006249739322811365,
            0.018119923770427704,
            0.046582311391830444,
            -0.0281792264431715,
            0.02665518783032894,
            -0.019477922469377518,
            -0.0281026940792799,
            0.007292780559509993,
            0.004117156378924847,
            -0.013060256838798523,
            -0.03836119920015335,
            0.01318737305700779,
            -0.03232712298631668,
            -0.012601074762642384,
            -0.009358672425150871,
            -0.024009641259908676,
            0.03268171474337578,
            0.0013809750089421868,
            -0.02979901060461998,
            -0.002965995343402028,
            -0.05236729606986046,
            -0.08546604216098785,
            0.009120818227529526,
            0.0255393385887146,
            -0.01805323362350464,
            0.00989847257733345,
            -0.02361459471285343,
            0.028054121881723404,
            0.005923408083617687,
            -0.01248026080429554,
            0.004313403274863958,
            0.015333187766373158,
            -0.05190746486186981,
            -0.030773870646953583,
            0.009867282584309578,
            -0.024365026503801346,
            -0.000795209314674139,
            -0.022984350100159645,
            -0.03155045583844185,
            0.03737528249621391,
            0.024900613352656364,
            0.04338892549276352,
            -0.009268739260733128,
            -0.03315245360136032,
            -0.011762211099267006,
            0.03679254651069641,
            -0.0033948179334402084,
            -0.049980197101831436,
            -0.017586160451173782,
            -0.0072354464791715145,
            -9.437520020583179e-06,
            0.006136190611869097,
            -0.002186001278460026,
            -0.03388632833957672,
            -0.01584502123296261,
            0.007805094122886658,
            0.021828440949320793,
            0.0049080862663686275,
            0.020686382427811623,
            -0.03652522712945938,
            0.015391540713608265,
            -0.001985439332202077,
            -0.014040941372513771,
            -0.00816423911601305,
            -0.022880973294377327,
            0.03386995941400528,
            0.01366423349827528,
            -0.016376927495002747,
            -0.026973435655236244,
            -0.015457008965313435,
            -0.0007812296971678734,
            -0.00282267271541059,
            -0.01291270088404417,
            -0.017767859622836113,
            -0.012740712612867355,
            0.023547330871224403,
            0.006531137973070145,
            0.02967826835811138,
            -0.03722665086388588,
            0.008777658455073833,
            -0.029143793508410454,
            0.00818227045238018,
            0.006188590079545975,
            0.03849896043539047,
            0.017319004982709885,
            0.020928004756569862,
            0.015776662155985832,
            0.010885548777878284,
            -0.0023567751049995422,
            -0.025145677849650383,
            0.02836303412914276,
            0.012373355217278004,
            -0.0005839626537635922,
            0.008812320418655872,
            0.006323148030787706,
            -0.03270740807056427,
            0.036933403462171555,
            0.0035760593600571156,
            -0.032733798027038574,
            0.009636393748223782,
            -0.02114313840866089,
            0.03907531499862671,
            -0.020080992951989174,
            -0.014281360432505608,
            -0.0077304053120315075,
            -0.019974080845713615,
            -0.0005149196949787438,
            0.01679888926446438,
            -0.003156165825203061,
            0.006264926865696907,
            0.009235695004463196,
            -0.02324296534061432,
            0.024053603410720825,
            0.058419451117515564,
            0.024500757455825806,
            -0.021428724750876427,
            -0.0035686218179762363,
            0.01406057458370924,
            0.02399076335132122,
            -0.0013956617331132293,
            0.007117749657481909,
            -0.006343300454318523,
            -0.04931312054395676,
            0.027966594323515892,
            0.003694388549774885,
            0.010944757610559464,
            0.01527348905801773,
            -0.02401873841881752,
            0.030773846432566643,
            -0.027001148089766502,
            -0.001905888319015503,
            -0.003793782787397504,
            -0.05296266824007034,
            0.00012943697220180184,
            -0.022084368392825127,
            0.006045704707503319,
            0.007444868795573711,
            -0.016735291108489037,
            -0.03336463123559952,
            0.016012366861104965,
            0.011234549805521965,
            -0.02309020422399044,
            -0.0005279711331240833,
            0.005104959011077881,
            -0.020111849531531334,
            -0.03391352668404579,
            -0.0005738286999985576,
            -0.005935239605605602,
            0.03835363686084747,
            0.041136905550956726,
            0.00294930930249393,
            -0.010489844717085361,
            -0.03333311155438423,
            0.0063452753238379955,
            -0.0024281765799969435,
            -0.008865478448569775,
            0.018528452143073082,
            0.014290311373770237,
            -0.02459876984357834,
            0.015646759420633316,
            -0.00858439039438963,
            0.013797295279800892,
            -0.0044279382564127445,
            -0.016946177929639816,
            0.00036571273813024163,
            0.0012745630228891969,
            0.03548608720302582,
            0.026661716401576996,
            0.009642507880926132,
            -0.043802812695503235,
            -0.01996210776269436,
            -0.010264633223414421,
            0.020762186497449875,
            -0.0067074294202029705,
            0.02285505272448063,
            0.02060929499566555,
            0.02133757807314396,
            0.0014415273908525705,
            0.006058415398001671,
            -0.04345545545220375,
            0.014411108568310738,
            0.0140420813113451,
            -0.001086382893845439,
            0.007535797078162432,
            0.01677996851503849,
            0.02841331996023655,
            0.012844663113355637,
            -0.0035063675604760647,
            0.02558957040309906,
            -0.03366886451840401,
            -0.013198677450418472,
            -0.031709738075733185,
            0.028282281011343002,
            -0.02272309549152851,
            -0.013692925684154034,
            -0.015210594981908798,
            -0.0010184431448578835,
            -0.033656761050224304,
            -0.03893260657787323,
            -0.035005949437618256,
            0.0022303196601569653,
            -0.00425251480191946,
            0.019843827933073044,
            0.016132431104779243,
            -0.017776090651750565,
            -0.004513496067374945,
            0.03168199583888054,
            0.007194714620709419,
            -0.001184161752462387,
            0.006787041202187538,
            0.017604578286409378,
            0.037242524325847626,
            0.00040658682701177895,
            -0.0026329797692596912,
            -0.06225171312689781,
            0.00030143081676214933,
            -0.006643468048423529,
            0.02056305669248104,
            -0.025611959397792816,
            -0.003777905134484172,
            0.009830585680902004,
            -0.002259650034829974,
            0.01599353365600109,
            0.026775559410452843,
            0.024929171428084373,
            0.02427917718887329,
            -0.021971313282847404,
            -0.021402301266789436,
            0.017135396599769592,
            0.0024315842892974615,
            0.002163920784369111,
            0.0025202948600053787,
            -0.00029651806107722223,
            0.00430343858897686,
            0.02415868081152439,
            0.002656891942024231,
            -0.014526638202369213,
            0.05040282383561134,
            -0.008626856841146946,
            0.03575877845287323,
            -0.026967696845531464,
            -0.00827854685485363,
            -0.031255703419446945,
            -0.006623271852731705,
            0.0020363181829452515,
            0.04057243838906288,
            -0.01715095341205597,
            0.0006522916373796761,
            -0.01811518706381321,
            0.014635404571890831,
            0.007294347044080496,
            -0.007544010877609253,
            -0.0059600165113806725,
            0.028131192550063133,
            -0.02280418574810028,
            0.009290295653045177,
            0.01703697443008423,
            0.030908942222595215,
            0.021131951361894608,
            -0.016414301469922066,
            -0.0357799232006073,
            0.019219234585762024,
            -0.025008922442793846,
            -0.047617003321647644,
            -0.03895112872123718,
            0.008801296353340149,
            0.06295146048069,
            -0.0072615076787769794,
            0.010083957575261593,
            -0.022363027557730675,
            0.00771124055609107,
            -0.029603339731693268,
            -0.03605012223124504,
            -0.002356718759983778,
            -0.01318575069308281,
            -0.01461850106716156,
            -0.0007200044929049909,
            -0.014673614874482155,
            0.019585952162742615,
            0.001318632042966783,
            0.03986823558807373,
            0.03185683488845825,
            -0.0024075424298644066,
            -0.009126179851591587,
            0.01517415139824152,
            -0.008130897767841816,
            -0.004409377928823233,
            -0.011256405152380466,
            0.0141619136556983,
            0.025204848498106003,
            0.02470402419567108,
            0.029280230402946472,
            -0.00752383703365922,
            0.010689452290534973,
            -0.02690010704100132,
            -0.020013056695461273,
            0.022187063470482826,
            -0.008874022401869297,
            0.007880544289946556,
            -0.004331167787313461,
            0.03925752267241478,
            -0.014926054514944553,
            -0.012294392101466656,
            0.022988051176071167,
            0.0073617142625153065,
            0.043987590819597244,
            -0.02009761892259121,
            -0.025959443300962448,
            0.0114290751516819,
            -0.00984437670558691,
            -0.0017499884124845266,
            -0.009043881669640541,
            -0.029677538201212883,
            -0.04265478253364563,
            -0.015423165634274483,
            0.026803774759173393,
            -0.02455035410821438,
            0.004180239513516426,
            -0.029664073139429092,
            -0.032347869127988815,
            0.024010222405195236,
            0.03556409850716591,
            0.00226404145359993,
            -0.02289445325732231,
            0.007362161763012409,
            -0.010549175553023815,
            0.02466695010662079,
            0.008518468588590622,
            -0.03803572803735733,
            0.015901120379567146,
            0.015617351047694683,
            0.029771149158477783,
            0.001542344456538558,
            0.027254892513155937,
            0.022735100239515305,
            0.07881349325180054,
            0.03506137803196907,
            0.017478466033935547,
            0.015849510207772255,
            0.012421377003192902,
            0.01414885651320219,
            -0.003852813271805644,
            -0.002132368739694357,
            -0.02118796482682228,
            0.012880898080766201,
            0.01635831780731678,
            6.567964010173455e-05,
            -0.02352559007704258,
            0.019713718444108963,
            0.031058652326464653,
            0.023234788328409195,
            -0.04997166246175766,
            0.02674630843102932,
            0.023312797769904137,
            0.007156323175877333,
            -0.03740587830543518,
            0.0006563482456840575,
            -0.006890594493597746,
            -0.02760959602892399,
            0.002028177957981825,
            -0.017075248062610626,
            0.004600846208631992,
            0.015268191695213318,
            0.005790730006992817,
            0.014116469770669937,
            0.006367698311805725,
            0.02807881124317646,
            -0.010664017871022224,
            -0.03336994722485542,
            0.016065564006567,
            -0.0041000815108418465,
            0.021205462515354156,
            -0.017165133729577065,
            0.0008260335889644921,
            0.002715855836868286,
            0.005129322409629822,
            0.029711123555898666,
            -0.0011690522078424692,
            0.013644742779433727,
            0.027044009417295456,
            -0.02018643543124199,
            0.026095658540725708,
            -0.010906814597547054,
            -0.015997817739844322,
            -0.022156648337841034,
            0.01688041165471077,
            0.022535119205713272,
            -0.002077533630654216,
            -0.031095610931515694,
            -0.018336433917284012,
            0.02568013034760952,
            0.016238795593380928,
            0.026709727942943573,
            0.015034136362373829,
            0.005650309845805168,
            0.0247573833912611,
            -0.029923152178525925,
            0.014813188463449478,
            -0.02102082595229149,
            0.025038326159119606,
            0.013615647330880165,
            0.021148599684238434,
            0.02393065206706524,
            -0.0019950787536799908,
            0.008445603772997856,
            0.0035532366018742323,
            -0.02505120262503624,
            0.004549085628241301,
            -0.01048964262008667,
            0.01061365008354187,
            0.02191867120563984,
            0.023427274078130722,
            -0.022471388801932335,
            0.017438121140003204,
            -0.0005211666575632989,
            -0.01767532341182232,
            -0.02902965620160103,
            0.02683078870177269,
            0.02999807894229889,
            -0.005098672583699226,
            -0.01238991692662239,
            0.022569848224520683,
            -0.013753192499279976,
            -0.02205381728708744,
            0.002686935244128108,
            -0.01465734001249075,
            0.01604737713932991,
            0.02617759257555008,
            0.011950481683015823,
            -0.0009718635701574385,
            -0.022548571228981018,
            0.025807645171880722,
            -0.024876276031136513,
            -0.026020472869277,
            0.011797280982136726,
            0.018743013963103294,
            0.004048523958772421,
            -0.018290506675839424,
            -0.004743045661598444,
            -0.019795918837189674,
            0.029819749295711517,
            0.016128094866871834,
            -0.03717578575015068,
            0.03332152217626572,
            -0.004175594076514244,
            -0.02286056987941265,
            0.012391584925353527,
            -0.009193281643092632,
            -0.0014331599231809378,
            -0.002574925310909748,
            -0.025985313579440117,
            0.007336602080613375,
            0.02201431803405285,
            0.006366136018186808,
            -0.0007958372007124126,
            0.004832313396036625,
            -0.011970737017691135,
            -0.018022799864411354,
            0.0206911563873291,
            0.005362865049391985,
            0.016029423102736473,
            -0.023399868980050087,
            0.004093568306416273,
            0.040653981268405914,
            0.012249755673110485,
            -0.020671645179390907,
            -0.007791719865053892,
            0.029499011114239693,
            0.0005330182611942291,
            -0.029921112582087517,
            0.004906353075057268,
            -0.0037533086724579334,
            -0.0036517169792205095,
            -0.024811508134007454,
            0.019404612481594086,
            0.02605408988893032,
            -0.024747494608163834,
            -0.008044104091823101,
            -0.009027889929711819,
            0.005655411630868912,
            -0.00045527389738708735,
            -0.007400408387184143,
            -0.014789754524827003,
            -0.012542622163891792,
            -0.03969574347138405,
            0.040889885276556015,
            0.013881250284612179,
            -0.03446058928966522,
            -0.03002038411796093,
            0.013530651107430458,
            -0.03978557139635086,
            0.007917582988739014,
            0.004244246985763311,
            -0.012311920523643494,
            0.007352230604737997,
            -0.006094361189752817,
            -0.02217097394168377,
            0.004293892066925764,
            0.019974375143647194,
            0.014130517840385437,
            -0.0277175884693861,
            0.03210453316569328,
            -0.013135283254086971,
            -0.0028351955115795135,
            0.022986577823758125,
            -0.03454394266009331,
            -0.027384303510189056,
            -0.01177490409463644,
            -0.00674721784889698,
            -0.008199552074074745,
            0.00646817684173584,
            -0.011867408640682697,
            0.0190111193805933,
            -0.028665876016020775,
            -0.00845424085855484,
            -0.027660395950078964,
            0.0398709699511528,
            -0.010618078522384167,
            -0.04375489428639412,
            -0.022357173264026642,
            -0.010017609223723412,
            -0.0037793954834342003,
            -0.0047635482624173164,
            0.02318568341434002,
            0.02815142832696438,
            -0.014786125160753727,
            -0.01036702748388052,
            -0.0006865810137242079,
            -0.010581236332654953,
            -0.012323126196861267,
            -0.010322360321879387,
            0.016106292605400085,
            -0.011593733914196491,
            0.005627392325550318,
            0.01759280450642109,
            -0.02620765194296837,
            -0.02223282866179943,
            -0.0015287769492715597,
            -0.02071133442223072,
            -0.03074597753584385,
            0.022449759766459465,
            0.01047215610742569,
            -0.012202143669128418,
            0.0037472338881343603,
            -0.0567488893866539,
            0.030031923204660416,
            -0.0006516844150610268,
            -0.015618018805980682,
            -0.02032710798084736,
            -0.008622112683951855,
            0.016847312450408936,
            0.016054319217801094,
            0.0181274376809597,
            0.02825135923922062,
            -0.0008455993374809623,
            0.001501461723819375,
            0.020156806334853172,
            -0.016685424372553825,
            0.029332244768738747,
            0.005918033886700869,
            0.02451397478580475,
            -0.02351222187280655,
            0.011404906399548054,
            0.013596388511359692,
            0.014212362468242645,
            -0.032345641404390335,
            0.007943028584122658,
            0.02816859260201454,
            0.006863478571176529,
            -0.005923357792198658,
            0.0030696887988597155,
            0.02715209685266018,
            0.027742629870772362,
            0.006540875881910324,
            -0.0291507076472044,
            -0.04544924944639206,
            -0.004871080629527569,
            -0.02297184057533741,
            0.028125261887907982,
            0.010354599915444851,
            -0.042949527502059937,
            -0.024642210453748703,
            0.0008334655431099236,
            0.02550424262881279,
            -0.002373875118792057,
            0.006776825059205294,
            0.00621398352086544,
            -0.01573076657950878,
            0.01208801195025444,
            0.01132340170443058,
            -0.017859874293208122,
            -0.025672122836112976,
            -0.013115947134792805,
            -0.025337625294923782,
            0.03636551275849342,
            -0.001093209139071405,
            -0.016071807593107224,
            -0.005358445458114147,
            -0.012042040936648846,
            0.0342245027422905,
            0.002656675176694989,
            0.03576194494962692,
            0.007607749197632074,
            0.007944432087242603,
            -0.015126725658774376,
            -0.004326710477471352,
            -0.01480418536812067,
            0.02526295743882656,
            -0.010906297713518143,
            0.012672186829149723,
            0.023984847590327263,
            0.03645823895931244,
            -0.015656892210245132,
            0.021068032830953598,
            0.015103989280760288,
            -0.021542010828852654,
            0.010182416066527367,
            0.018713800236582756,
            -0.03302001953125,
            0.013885948807001114,
            -0.01004556380212307,
            0.029728811234235764,
            -0.020211564376950264,
            -0.0033891936764121056,
            0.005511595401912928,
            -0.01968288980424404,
            0.03823145106434822,
            -0.012505640275776386,
            -1.3586206478066742e-05,
            0.011656464077532291,
            -0.02120252139866352,
            -0.017669351771473885,
            -0.020606067031621933,
            0.03600698336958885,
            0.0009267415152862668,
            -0.022229718044400215,
            -0.01452021487057209,
            -0.009870944544672966,
            0.00489187752828002,
            0.010423663072288036,
            -0.005986221134662628,
            0.023725926876068115,
            0.01775098219513893,
            -0.019184961915016174,
            0.03146849945187569,
            0.02402104064822197,
            0.013227710500359535,
            0.0025900204200297594,
            0.006650630384683609,
            -0.029311886057257652,
            0.027648914605379105,
            -0.034027811139822006,
            -0.006036750040948391,
            -0.016995780169963837,
            -0.0030456800013780594,
            -0.012956851162016392,
            -0.022330038249492645,
            0.017971264198422432,
            -0.00615563103929162,
            -0.017066599801182747,
            -0.027076078578829765,
            0.0013064041268080473,
            -0.01563384011387825,
            -0.004647282883524895,
            0.012436299584805965,
            -0.04502897337079048,
            -0.011750059202313423,
            0.011040925979614258,
            0.009019377641379833,
            0.005686599761247635,
            0.008855556137859821,
            -0.02766122855246067,
            0.040030110627412796,
            0.024171702563762665,
            0.027470966801047325,
            0.02459893375635147,
            -0.00415009306743741,
            0.011367865838110447,
            -0.02627192810177803,
            -0.013556945137679577,
            -0.01490936428308487,
            0.031175993382930756,
            -0.026751991361379623,
            0.030537843704223633,
            0.033971332013607025,
            0.017074426636099815,
            0.0026969509199261665,
            0.0075738332234323025,
            -0.03136853128671646,
            0.03379368409514427,
            -0.013050503097474575,
            0.009963195770978928,
            0.028043201193213463,
            0.00565939350053668,
            0.019626304507255554,
            0.03724563494324684,
            -0.0272072684019804,
            -0.02531655877828598,
            0.015280078165233135,
            -0.01385459490120411,
            0.028141066431999207,
            0.010417073033750057,
            -0.015414521098136902,
            -0.011485462076961994,
            0.0333750955760479,
            0.03714529797434807,
            0.02270597219467163,
            0.006445389240980148,
            -0.03231208398938179,
            0.007806615903973579,
            0.03352271020412445,
            -0.03499668091535568,
            -0.050999194383621216,
            -0.019300885498523712,
            -0.04233616590499878,
            -0.009979327209293842,
            0.023738281801342964,
            0.004808370023965836,
            0.014794674701988697,
            0.02104024775326252,
            -0.021129505708813667,
            -0.01289253681898117,
            -0.024172553792595863,
            -0.02553190104663372,
            -0.009435422718524933,
            0.031401362270116806,
            0.013311860151588917,
            -0.01187844853848219,
            0.027863040566444397,
            -0.029118776321411133,
            -0.011021217331290245,
            0.030678004026412964,
            0.022097963839769363,
            0.01882335916161537,
            -0.0016691518248990178,
            0.028572630137205124,
            -0.009231596253812313,
            -0.004079120699316263,
            -0.018931562080979347,
            0.02477351576089859,
            -0.06106417626142502,
            0.03421970456838608,
            0.04001704975962639,
            0.0016114410245791078,
            0.00037658680230379105,
            -0.013096190989017487,
            -0.0008652762044221163,
            -0.02206362597644329,
            0.035483505576848984,
            -0.020300501957535744,
            -0.02909286879003048,
            0.0001011908971122466,
            0.008763439953327179,
            0.02813507430255413,
            0.02804521657526493,
            0.019297344610095024,
            -0.021176297217607498,
            -0.010844811797142029,
            -0.0008935818914324045,
            0.030300501734018326,
            0.011434279382228851,
            -0.025519998744130135,
            0.02700110152363777,
            -0.01301712915301323,
            0.014932592399418354,
            -0.04976329207420349,
            0.0025194191839545965,
            0.014637896791100502,
            -0.012872316874563694,
            -0.030168164521455765,
            0.0022738908883184195,
            -0.03190214931964874,
            -0.0224200077354908,
            0.010738477110862732,
            0.01023790892213583,
            0.00626031868159771,
            -0.027101654559373856,
            -0.0009076921851374209,
            -0.01001823227852583,
            0.00013766720076091588,
            -0.02703051082789898,
            0.02361396700143814,
            0.007816804572939873,
            -0.0082038389518857,
            -0.016831211745738983,
            -0.030905911698937416,
            -0.011574684642255306,
            -0.008834278210997581,
            0.03667169436812401,
            0.017195355147123337,
            0.01571647822856903,
            -0.0019773058593273163,
            -0.0017276124563068151,
            -0.01331812608987093,
            0.002062037354335189,
            -0.024113256484270096,
            0.03542982041835785,
            0.004641159903258085,
            -0.02162073366343975,
            0.014260179363191128,
            0.03757048025727272,
            -0.0030854055657982826,
            -0.032169923186302185,
            -0.03719590604305267,
            -0.03505337983369827,
            -0.01722351461648941,
            -0.017772838473320007,
            -0.017098257318139076,
            0.02120019681751728,
            -0.0029505244456231594,
            0.027567831799387932,
            -0.03051980584859848,
            -0.0063652764074504375,
            0.0007205794681794941,
            -0.00285616354085505,
            0.04471258074045181,
            -0.02619330585002899,
            -0.015251995995640755,
            -0.012476026080548763,
            -0.015897687524557114,
            0.01179488468915224,
            -0.029386891052126884,
            -0.0246150940656662,
            0.023354656994342804,
            -0.024992918595671654,
            0.02402639575302601,
            -0.007842962630093098,
            -0.016843825578689575,
            -0.019897794350981712,
            -0.012898754328489304,
            -0.004135901108384132,
            -0.005871602334082127,
            -0.03942521661520004,
            0.014063797891139984,
            -0.025296427309513092,
            -0.017210979014635086,
            0.00872108992189169,
            -0.010065247304737568,
            -0.045420337468385696,
            0.02526196651160717,
            0.017904648557305336,
            -0.005859957542270422,
            0.012501203455030918,
            -0.0536433607339859,
            0.004077882040292025,
            -0.010029430501163006,
            0.049977123737335205,
            -0.032970115542411804,
            -0.010139700025320053,
            0.015606559813022614,
            0.010975085198879242,
            0.0036901705898344517,
            -0.02482386864721775,
            0.02136637642979622,
            0.018481673672795296,
            -0.014998970553278923,
            -0.003644312033429742,
            0.019535420462489128,
            -0.0022911070846021175,
            -0.026676690205931664,
            0.0062571680173277855,
            -0.031881511211395264,
            0.018377434462308884,
            -0.010795153677463531,
            -0.03703460469841957,
            0.006444300524890423,
            -0.018069138750433922,
            0.007299948018044233,
            0.0017798268236219883,
            0.007193733938038349,
            -0.0484684519469738,
            -0.00871069636195898,
            0.00276132021099329,
            -0.012507164850831032,
            -0.02736516110599041,
            -0.01378941722214222,
            -0.0013456536689773202,
            0.026454029604792595,
            -0.01304010208696127,
            0.002581943292170763,
            -0.01439621765166521,
            0.018091294914484024,
            0.032648757100105286,
            -0.032519303262233734,
            -0.001128666684962809,
            -0.010771499015390873,
            0.001945997471921146,
            -0.0052163559012115,
            0.004678652621805668,
            0.02910395711660385,
            -0.016842234879732132,
            0.02649315632879734,
            -0.02681339718401432,
            -0.010425951331853867,
            -0.016294879838824272,
            -0.045414429157972336,
            0.009668326936662197,
            -0.015903448686003685,
            -0.03653772920370102,
            -0.024576203897595406,
            -0.02080761082470417,
            0.03611733019351959,
            -0.03412822261452675,
            0.021726420149207115,
            0.005258191842585802,
            0.006302433088421822,
            -0.021758820861577988,
            -0.012484604492783546,
            -0.028065582737326622,
            -0.03178185969591141,
            -0.004822343122214079,
            -0.003241501050069928
        ],
        [
            -0.013741360045969486,
            -0.02331736497581005,
            -0.005533963441848755,
            -0.024207403883337975,
            -0.023118510842323303,
            0.010272928513586521,
            -0.0040063150227069855,
            0.007720387540757656,
            -0.01801600493490696,
            0.017446383833885193,
            -0.03518933802843094,
            0.012794663198292255,
            -0.003884181845933199,
            -0.01596497744321823,
            0.01972850225865841,
            -0.007462950889021158,
            0.024319976568222046,
            8.071485353866592e-06,
            -0.0022043006028980017,
            0.017970452085137367,
            -0.01796272210776806,
            0.007993122562766075,
            0.012163227424025536,
            0.013938860036432743,
            0.0048685153014957905,
            0.021190470084547997,
            -0.016434956341981888,
            -0.009914148598909378,
            -0.035027943551540375,
            0.01735095866024494,
            -0.014727587811648846,
            0.019564779475331306,
            0.009736070409417152,
            0.00421925401315093,
            0.006918579805642366,
            -0.024925850331783295,
            -0.029148610308766365,
            -0.01153131015598774,
            0.01567445695400238,
            0.013697833754122257,
            -0.015391482040286064,
            0.004496280569583178,
            -0.03731565922498703,
            0.018653271719813347,
            -0.002068151952698827,
            0.008685311302542686,
            -0.006252476945519447,
            0.048029396682977676,
            -0.00738121010363102,
            0.0015118301380425692,
            0.024901878088712692,
            0.01030678115785122,
            0.017695127055048943,
            0.016477132216095924,
            0.05257972329854965,
            -0.009061518125236034,
            0.027517320588231087,
            0.010775724425911903,
            0.010973883792757988,
            -0.015962425619363785,
            0.010137667879462242,
            0.02280447632074356,
            0.0050964648835361,
            0.025450823828577995,
            0.014685826376080513,
            0.0021788596641272306,
            0.013842117041349411,
            -0.013640094548463821,
            -0.006106884218752384,
            -0.0010085245594382286,
            1.6819411030155607e-05,
            0.007304527331143618,
            0.015454248525202274,
            -0.026935243979096413,
            0.011640479788184166,
            -0.0006995939766056836,
            0.02150634117424488,
            -0.05032031238079071,
            0.00846143625676632,
            0.04595422372221947,
            0.014514297246932983,
            0.06172532960772514,
            -0.0031628822907805443,
            0.005591296590864658,
            0.014165239408612251,
            0.007405003532767296,
            0.005656271707266569,
            -0.054691944271326065,
            -0.00491776317358017,
            -0.002521681599318981,
            0.031007830053567886,
            0.0003887742350343615,
            0.018615152686834335,
            0.0020637321285903454,
            0.0320255309343338,
            0.026087244972586632,
            -0.004214767832309008,
            -0.015024046413600445,
            0.04368454962968826,
            0.025791656225919724,
            -0.014181694015860558,
            0.005369526334106922,
            -0.031533826142549515,
            -0.0034886098001152277,
            -0.02157602272927761,
            -0.01726572774350643,
            -0.021403983235359192,
            0.004366223234683275,
            -0.033401310443878174,
            0.009100662544369698,
            0.0393645241856575,
            0.015247740782797337,
            0.00539951678365469,
            0.03368529677391052,
            0.058065976947546005,
            0.000837693689391017,
            -0.026419775560498238,
            -0.03699573874473572,
            -0.02720629796385765,
            -0.02423710748553276,
            -0.01747402362525463,
            0.002994697308167815,
            -0.020653391256928444,
            0.021963482722640038,
            -0.018870990723371506,
            0.010192724876105785,
            0.012103734537959099,
            0.0075876833871006966,
            -0.03195777162909508,
            0.01443411223590374,
            -0.0038729426451027393,
            0.0016157478094100952,
            0.0137048764154315,
            0.007012874819338322,
            -0.015309113077819347,
            0.024598367512226105,
            -0.03263780102133751,
            -0.027188237756490707,
            0.011904879473149776,
            -0.006739465519785881,
            -0.005858609918504953,
            0.010564037598669529,
            -0.026713261380791664,
            0.03474318981170654,
            0.010756642557680607,
            -0.028288746252655983,
            -0.03077157773077488,
            0.022525779902935028,
            0.030002104118466377,
            0.04584323987364769,
            0.0013126461999490857,
            0.0074146403931081295,
            0.027776170521974564,
            0.05672497674822807,
            -0.002434726804494858,
            0.009375344961881638,
            0.00617930619046092,
            0.029894601553678513,
            0.006477252580225468,
            0.014040677808225155,
            0.023725952953100204,
            -0.006237112917006016,
            -0.03003142587840557,
            0.016874482855200768,
            0.0069326916709542274,
            0.003500562161207199,
            -0.025968508794903755,
            -0.04040221497416496,
            0.015112725086510181,
            0.024638745933771133,
            0.021787909790873528,
            -0.035612553358078,
            0.03551209717988968,
            0.015381003729999065,
            0.004383715335279703,
            0.02804737165570259,
            -0.02497180737555027,
            -0.019543448463082314,
            0.025261254981160164,
            0.03956388682126999,
            0.0030045739840716124,
            -0.022919680923223495,
            0.00443279929459095,
            0.0038956182543188334,
            0.010278735309839249,
            0.0026574244257062674,
            -0.026272863149642944,
            0.028238119557499886,
            -0.02011939138174057,
            0.00666765496134758,
            -0.019318027421832085,
            -0.04290147125720978,
            -0.04065275192260742,
            -0.039747610688209534,
            0.009830336086452007,
            0.014276747591793537,
            0.0340849794447422,
            -0.026478147134184837,
            -0.021071214228868484,
            -0.02854960784316063,
            0.010155700147151947,
            0.008098781108856201,
            -0.0012960843741893768,
            -0.006335725542157888,
            -0.024515755474567413,
            0.017622606828808784,
            0.02620224840939045,
            0.02281489409506321,
            -0.01190988440066576,
            -0.01708812080323696,
            0.021538449451327324,
            0.03797582536935806,
            -0.015322734601795673,
            0.017669260501861572,
            0.014319924637675285,
            0.0019438971066847444,
            -0.015165448188781738,
            0.00882722344249487,
            -0.007716313004493713,
            -0.0072228992357850075,
            -0.03470771759748459,
            -0.0022141337394714355,
            0.0311201773583889,
            -0.03290716931223869,
            0.027905652299523354,
            0.02833407372236252,
            0.00208693346939981,
            -0.017010491341352463,
            0.007405412383377552,
            0.022547751665115356,
            0.027256499975919724,
            -0.0005957718822173774,
            0.03636933118104935,
            -0.014270304702222347,
            0.03668594732880592,
            -0.002052687108516693,
            -0.05260855704545975,
            0.028448540717363358,
            -0.013631291687488556,
            -0.02329564094543457,
            0.024351133033633232,
            -0.02545221336185932,
            -0.004978695418685675,
            -0.0022746811155229807,
            0.006673614028841257,
            0.016792522743344307,
            -0.009912881068885326,
            -0.020113065838813782,
            -0.019994571805000305,
            0.05217546597123146,
            -0.0036117411218583584,
            -0.018364258110523224,
            -0.005800953134894371,
            -0.027390675619244576,
            0.03391938656568527,
            -0.029197933152318,
            0.02994910627603531,
            -0.028300529345870018,
            -0.02548252046108246,
            0.014216364361345768,
            0.002259615808725357,
            0.011378447525203228,
            -0.04307050630450249,
            -0.010744995437562466,
            0.0006844389135949314,
            0.009392078965902328,
            0.023009562864899635,
            0.006392553448677063,
            0.015899280086159706,
            -0.021452978253364563,
            0.018339570611715317,
            -0.009920971468091011,
            0.02347429469227791,
            0.018694143742322922,
            -0.004230028484016657,
            -0.018356284126639366,
            -0.017321504652500153,
            -0.02148720622062683,
            -0.014178566634654999,
            -0.02664516307413578,
            -0.009296156466007233,
            -0.044257987290620804,
            0.02159847877919674,
            0.025768380612134933,
            -0.0028309752233326435,
            -0.011474166065454483,
            -0.020589768886566162,
            0.07543545961380005,
            -0.012027006596326828,
            -0.004712340421974659,
            -0.0049180323258042336,
            -0.004386225249618292,
            -0.01769603043794632,
            -0.001774967066012323,
            0.019198426976799965,
            -0.01109932828694582,
            0.015439796261489391,
            0.0012662841472774744,
            0.006836083717644215,
            0.008509961888194084,
            -0.022245867177844048,
            0.009396377950906754,
            0.02373216673731804,
            0.02246527187526226,
            -0.019144592806696892,
            0.01672075130045414,
            -0.016427844762802124,
            0.0037108934484422207,
            -0.012471542693674564,
            0.014652817510068417,
            0.009890532121062279,
            0.025579461827874184,
            0.015640124678611755,
            -0.009071175009012222,
            0.01642126962542534,
            0.04495438188314438,
            -0.010188410058617592,
            0.021418796852231026,
            -0.0003361595736350864,
            0.012242225930094719,
            -0.012540352530777454,
            -0.03582436591386795,
            0.004447564482688904,
            0.011428214609622955,
            0.016556454822421074,
            -0.03223581612110138,
            0.04235415160655975,
            -0.04707161337137222,
            0.017932675778865814,
            -0.0175469983369112,
            0.0074986605904996395,
            -0.013049463741481304,
            -0.01239896472543478,
            0.03762906789779663,
            -0.022310828790068626,
            0.044904328882694244,
            -0.017145082354545593,
            0.03163859620690346,
            0.008104223757982254,
            -0.020273445174098015,
            0.02768738940358162,
            -0.006649908144026995,
            -0.05647774413228035,
            -0.023485036566853523,
            0.011494394391775131,
            -0.011133743450045586,
            -0.0005475367070175707,
            0.009486319497227669,
            -0.03150942549109459,
            -0.044562824070453644,
            0.03718848526477814,
            0.009956039488315582,
            -0.0068606422282755375,
            0.014280267059803009,
            -0.0006847665645182133,
            0.021723994985222816,
            0.0165313258767128,
            -0.003433512058109045,
            0.027118133381009102,
            0.006667600944638252,
            -0.007713668514043093,
            0.005419519264250994,
            0.007835005410015583,
            -0.014075499027967453,
            -0.021105268970131874,
            0.0036997762508690357,
            -7.293574162758887e-05,
            0.02419113554060459,
            -0.00657845102250576,
            0.02767941914498806,
            0.0005969228222966194,
            0.011664197780191898,
            -0.002941089915111661,
            -0.03256650269031525,
            -0.013394542038440704,
            0.03294281288981438,
            -0.009972703643143177,
            -0.009210176765918732,
            -0.061979759484529495,
            0.014633282087743282,
            -0.004039696417748928,
            0.014045534655451775,
            -0.0070150382816791534,
            -0.010033550672233105,
            -0.046103090047836304,
            0.042019788175821304,
            0.014170188456773758,
            0.046198002994060516,
            0.001414990285411477,
            0.028212111443281174,
            -0.0030890183988958597,
            -0.025706609711050987,
            0.04328032210469246,
            -0.013010036200284958,
            -0.06183570623397827,
            -0.0035230377689003944,
            -0.005560852587223053,
            -0.024939555674791336,
            0.00022890274703968316,
            0.029657727107405663,
            -0.03540129214525223,
            -0.003474173601716757,
            -0.032303035259246826,
            0.02600361406803131,
            0.03469706326723099,
            -0.006134880241006613,
            -0.03310147300362587,
            -0.021637119352817535,
            0.011869295500218868,
            -0.000887103786226362,
            0.02233685553073883,
            -0.021109523251652718,
            0.012009565718472004,
            0.015265971422195435,
            -0.02483050338923931,
            0.01236764807254076,
            0.006984615232795477,
            0.03553450107574463,
            -0.018476534634828568,
            -0.01609751582145691,
            0.025186501443386078,
            0.026119299232959747,
            0.028515908867120743,
            -0.011325807310640812,
            0.03065800666809082,
            0.06033334508538246,
            0.01505949441343546,
            0.03302576765418053,
            0.022420115768909454,
            -0.021049009636044502,
            0.012399529106914997,
            0.002289427211508155,
            -0.02668161131441593,
            0.03227926045656204,
            -0.01924607902765274,
            -0.016032841056585312,
            0.016267258673906326,
            -0.030208779498934746,
            0.005515540484338999,
            0.03406495600938797,
            0.030252546072006226,
            -0.006608608644455671,
            0.02599690854549408,
            0.0192008875310421,
            -0.026590993627905846,
            -0.022166874259710312,
            -0.0015564056811854243,
            -0.03978347405791283,
            0.008588218130171299,
            -0.013826068490743637,
            0.015514400787651539,
            -0.009206066839396954,
            -0.030653489753603935,
            0.02488020807504654,
            -0.00857546180486679,
            -0.012720861472189426,
            0.032078664749860764,
            -0.030196236446499825,
            0.0034847469069063663,
            0.011781268753111362,
            -0.0026636740658432245,
            0.024356473237276077,
            -0.007880348712205887,
            -0.02561662346124649,
            -0.011099962517619133,
            0.010262995027005672,
            -0.00028066113009117544,
            -0.025690356269478798,
            0.010947108268737793,
            -0.015078863129019737,
            -0.0008896901272237301,
            -0.00906800664961338,
            0.0066945310682058334,
            0.016114871948957443,
            0.03659858927130699,
            -0.010733007453382015,
            -0.005952863954007626,
            -0.0012966167414560914,
            0.007911226712167263,
            -0.02266426756978035,
            -0.009644723497331142,
            0.02092057652771473,
            -0.025783225893974304,
            -0.023212706670165062,
            -0.012223485857248306,
            -0.016718992963433266,
            -0.020328151062130928,
            -0.009045382030308247,
            0.0385577455163002,
            -0.047122206538915634,
            0.006332114338874817,
            -0.01870889961719513,
            -0.015994498506188393,
            0.02812613546848297,
            -0.024837957695126534,
            -0.012426615692675114,
            0.019322561100125313,
            -0.012926476076245308,
            -0.0118467528373003,
            -0.024121612310409546,
            0.010429835878312588,
            0.041142646223306656,
            0.013359077274799347,
            0.02756340615451336,
            -0.007713294122368097,
            0.0031987226102501154,
            -0.02960164099931717,
            -0.005170048680156469,
            0.025754719972610474,
            -0.012489411048591137,
            -0.0012407464673742652,
            0.0173050444573164,
            -0.008150666952133179,
            -0.017701944336295128,
            0.027728112414479256,
            0.028194718062877655,
            0.03916803374886513,
            0.006653027608990669,
            0.001346773817203939,
            -0.012110012583434582,
            -0.03086567297577858,
            0.016385188326239586,
            -0.017198385670781136,
            0.01108655147254467,
            -0.03230617940425873,
            0.04322515428066254,
            0.022858038544654846,
            -0.0007546356064267457,
            0.01496841385960579,
            0.032614223659038544,
            -0.022869082167744637,
            0.04786510020494461,
            -0.051890067756175995,
            -0.030005771666765213,
            -0.010185876861214638,
            0.0021208799444139004,
            -0.023029249161481857,
            0.025149410590529442,
            0.017255855724215508,
            -0.007888215593993664,
            -0.0023635788820683956,
            -0.03766071796417236,
            0.013655278831720352,
            0.011996222659945488,
            -0.022542564198374748,
            0.0002581181761343032,
            -0.019833868369460106,
            0.004842330701649189,
            -0.012256426736712456,
            -0.01907307840883732,
            -0.008554451167583466,
            0.019818704575300217,
            -0.006723321508616209,
            0.014093153178691864,
            0.015826018527150154,
            0.033467743545770645,
            0.01812257431447506,
            0.008769093081355095,
            0.03206427022814751,
            -0.01381192822009325,
            -0.03005247376859188,
            0.015858428552746773,
            -0.022604942321777344,
            -0.030635444447398186,
            0.027685130015015602,
            0.05146641284227371,
            -0.010987231507897377,
            0.004039740189909935,
            -0.01253734715282917,
            0.016675958409905434,
            -0.026793798431754112,
            0.04408807307481766,
            -0.022218195721507072,
            -0.0026399516500532627,
            -0.010261817835271358,
            -0.015095475129783154,
            0.003090739017352462,
            -0.014924181625247002,
            0.008450276218354702,
            0.01506074145436287,
            0.013146338053047657,
            0.005906118545681238,
            0.020237678661942482,
            -0.022217849269509315,
            -0.026899900287389755,
            0.034789834171533585,
            -0.028164636343717575,
            0.006906653754413128,
            -0.012635800056159496,
            -0.0025128861889243126,
            0.018529927358031273,
            0.013746864162385464,
            -0.021662523970007896,
            -0.008777856826782227,
            0.01464501116424799,
            0.011166769079864025,
            0.00943702645599842,
            0.0025310313794761896,
            0.00036964562605135143,
            0.019656449556350708,
            0.0092433150857687,
            -0.004938474390655756,
            0.032360512763261795,
            0.027436556294560432,
            0.006544798146933317,
            -0.005319463089108467,
            0.012145763263106346,
            -0.0031505145598202944,
            0.04723877087235451,
            0.03968879580497742,
            -0.014304178766906261,
            -0.030718954280018806,
            -0.0007194834179244936,
            0.017897263169288635,
            -0.022551922127604485,
            0.016740020364522934,
            0.017792897298932076,
            -0.0025604586116969585,
            -0.030119318515062332,
            -0.07993049174547195,
            0.04446815699338913,
            0.010952160693705082,
            0.011998474597930908,
            0.00949216727167368,
            -0.010515762493014336,
            -0.01418555248528719,
            -0.018989693373441696,
            -0.016244854778051376,
            0.02179880440235138,
            0.01145663857460022,
            -0.022129014134407043,
            -0.010658767074346542,
            -0.021188808605074883,
            0.024558493867516518,
            0.019729353487491608,
            -0.03831259906291962,
            0.01570838689804077,
            -0.007067479193210602,
            -0.010603982023894787,
            0.0217258483171463,
            -0.0022429167293012142,
            -0.010625106282532215,
            0.04503278806805611,
            0.0540144145488739,
            -0.00612675491720438,
            0.019639845937490463,
            0.006335106678307056,
            0.015575958415865898,
            0.030759645625948906,
            0.008595204912126064,
            -0.02015397883951664,
            0.032836396247148514,
            0.02269335649907589,
            0.030886640772223473,
            -0.037567246705293655,
            0.017522476613521576,
            -0.03340586647391319,
            0.0035913027822971344,
            0.019602173939347267,
            0.00575132155790925,
            -0.009025468491017818,
            0.017669783905148506,
            -0.017801716923713684,
            -0.02418634481728077,
            -0.00021049733913969249,
            -0.012786557897925377,
            0.020497474819421768,
            -0.011846501380205154,
            -0.020982908084988594,
            0.04085404798388481,
            0.05802885815501213,
            -0.04780416563153267,
            -0.027048630639910698,
            0.022282158955931664,
            -0.025255659595131874,
            -0.02764165960252285,
            -0.018436510115861893,
            0.014822063967585564,
            0.02433101460337639,
            -0.01843113824725151,
            -0.007372179534286261,
            -0.02845074236392975,
            0.0034314889926463366,
            -0.007834555581212044,
            0.003957954701036215,
            0.029723286628723145,
            0.036945246160030365,
            0.016014138236641884,
            0.015428317710757256,
            -0.014453728683292866,
            0.07596070319414139,
            -0.012440606951713562,
            0.004411603324115276,
            0.0058179874904453754,
            -0.021467043086886406,
            0.04419887438416481,
            -0.010137801989912987,
            -0.030167099088430405,
            0.006870652083307505,
            -0.009942064993083477,
            0.01944286748766899,
            0.005840163212269545,
            -0.023813419044017792,
            0.0008497493108734488,
            0.04005052149295807,
            -0.07341525703668594,
            0.02408977784216404,
            -0.021243290975689888,
            0.012562768533825874,
            0.0020474810153245926,
            -0.00036339808139018714,
            -0.02378552407026291,
            0.02119356207549572,
            0.014867075718939304,
            0.018121985718607903,
            -0.02744142897427082,
            0.08033447712659836,
            -0.004833931569010019,
            -0.02087581902742386,
            -0.022013815119862556,
            -0.025042477995157242,
            0.009485147893428802,
            0.0005183829343877733,
            -0.01942385919392109,
            -0.027954118326306343,
            0.026175793260335922,
            -0.033240195363759995,
            0.026394981890916824,
            0.007460764143615961,
            -0.015044699423015118,
            0.011575008742511272,
            0.019185807555913925,
            0.012771602720022202,
            0.032792218029499054,
            0.04228764772415161,
            0.0320388488471508,
            -0.018654748797416687,
            -0.01658462919294834,
            -0.003294795285910368,
            0.03336786851286888,
            -0.004951625131070614,
            -0.0186547189950943,
            -0.0194137804210186,
            -0.001115698367357254,
            -0.03088444098830223,
            -0.08668854087591171,
            0.02224057912826538,
            0.04194250330328941,
            0.027925588190555573,
            0.007661729585379362,
            -0.0033735092729330063,
            -0.007741199806332588,
            -0.01101277582347393,
            0.01820359006524086,
            0.0016077347099781036,
            0.022377431392669678,
            -0.01964421197772026,
            0.05027312785387039,
            0.05852047726511955,
            0.022253381088376045,
            0.0005555463139899075,
            -0.0613265223801136,
            0.009538834914565086,
            0.0216112919151783,
            -0.0007612946792505682,
            -0.01278717815876007,
            0.007720345631241798,
            -0.01713968627154827,
            -0.006339065730571747,
            -0.011551977135241032,
            -0.0069823418743908405,
            0.00548272579908371,
            0.011253831908106804,
            0.019591206684708595,
            0.008396575227379799,
            -0.008875167928636074,
            -0.05701441690325737,
            0.00585469463840127,
            0.0003251951129641384,
            0.014640195295214653,
            -0.025774402543902397,
            0.0015393042704090476,
            -0.039033062756061554,
            0.028262432664632797,
            -0.003562598256394267,
            -0.034303952008485794,
            0.04901700094342232,
            0.005662322510033846,
            -0.03644470125436783,
            0.001070945872925222,
            0.03390900790691376,
            -0.019614223390817642,
            0.009288919158279896,
            -0.02214139513671398,
            -0.06533586978912354,
            0.05282364413142204,
            0.006883507594466209,
            0.01689840853214264,
            0.020611576735973358,
            -0.05132417380809784,
            0.00969147589057684,
            0.02516648918390274,
            0.022426879033446312,
            0.0428730733692646,
            0.004148378968238831,
            -0.02268064394593239,
            -0.040236398577690125,
            0.024221146479249,
            0.018093707039952278,
            0.003945292439311743,
            -0.027143167331814766,
            0.06145981699228287,
            0.023173188790678978,
            0.013862034305930138,
            -0.009058607742190361,
            -0.017477156594395638,
            -0.03094327077269554,
            -0.0215959120541811,
            -0.009321661666035652,
            -0.009061331860721111,
            0.019660156220197678,
            -0.002325361128896475,
            -0.011430139653384686,
            -0.035431236028671265,
            -0.023481382057070732,
            0.021486548706889153,
            -0.009622558020055294,
            -0.001739064697176218,
            0.010188949294388294,
            -0.0337473601102829,
            -0.028512008488178253,
            0.033264320343732834,
            -0.005972573067992926,
            0.013938485644757748,
            0.003651024540886283,
            -0.008664129301905632,
            -0.0055088624358177185,
            0.02974390611052513,
            0.015037876553833485,
            0.02447921596467495,
            0.022503243759274483,
            -0.022566750645637512,
            0.02654576301574707,
            -0.0343579538166523,
            0.008423643186688423,
            -0.010239850729703903,
            -0.019063543528318405,
            0.03785109892487526,
            0.03287552297115326,
            0.0007433716091327369,
            -0.029163943603634834,
            -0.03318603336811066,
            0.025145068764686584,
            -0.0014341366477310658,
            0.022149858996272087,
            0.024109408259391785,
            -0.006846647243946791,
            -0.01844770833849907,
            -0.047732725739479065,
            -0.009725844487547874,
            0.015908464789390564,
            -0.03552832081913948,
            0.04890745133161545,
            0.01741095818579197,
            0.015105227008461952,
            0.023523004725575447,
            -0.022423945367336273,
            -0.026643602177500725,
            0.004713870119303465,
            -0.0422954224050045,
            -0.010153944604098797,
            0.012357641942799091,
            -0.02033584751188755,
            -0.05692725256085396,
            -0.02507804147899151,
            0.03677905723452568,
            0.005768002476543188,
            -0.03185778856277466,
            0.013213567435741425,
            -0.00027248257538303733,
            0.048983778804540634,
            0.004858007188886404,
            -0.00041927790152840316,
            -0.018032150343060493,
            -0.016646113246679306,
            0.010609193705022335,
            0.0008860441157594323,
            0.0016896706074476242,
            0.009762967005372047,
            0.012643706053495407,
            -0.031166495755314827,
            0.03381969407200813,
            0.01147705502808094,
            0.04306955635547638,
            -0.048031482845544815,
            0.027814719825983047,
            0.017022978514432907,
            -0.003972719423472881,
            -0.02318493276834488,
            0.012427125126123428,
            -0.045633457601070404,
            0.020906638354063034,
            -0.007403039839118719,
            0.009859983809292316,
            -0.02180357649922371,
            -0.00513208843767643,
            0.008217737078666687,
            0.027049772441387177,
            0.011077061295509338,
            0.024580078199505806,
            0.010824927128851414,
            -0.0209524966776371,
            0.025145167484879494,
            0.029071060940623283,
            0.06373592466115952,
            -0.04106033593416214,
            -0.00043958102469332516,
            -0.0004830314719583839,
            -0.021246278658509254,
            -0.004349630791693926,
            -0.017718760296702385,
            0.013576800003647804,
            -0.0023422716185450554,
            -0.023321064189076424,
            -0.04551858454942703,
            -0.0273866169154644,
            -0.006420928984880447,
            -0.04213784262537956,
            0.03394071385264397,
            -0.0007540014921687543,
            0.003032479202374816,
            0.027549896389245987,
            -0.008751099929213524,
            -0.025082848966121674,
            -0.05800706893205643,
            0.03186877816915512,
            -0.02059129625558853,
            0.02057640440762043,
            0.021470606327056885,
            0.012802448123693466,
            -0.011282138526439667,
            0.01582372561097145,
            -0.051389943808317184,
            0.022168844938278198,
            0.03825885429978371,
            0.013416078872978687,
            0.03204437717795372,
            0.053414467722177505,
            0.03606506809592247,
            0.001665672636590898,
            -0.009987874887883663,
            0.02270803414285183,
            -0.014342641457915306,
            -0.016199905425310135,
            -0.005126308649778366,
            -0.0025775907561182976,
            0.003824020503088832,
            -0.015765419229865074,
            -0.04703805223107338,
            -0.010358564555644989,
            0.016770660877227783,
            -0.004472836852073669,
            -0.027183206751942635,
            0.04697289317846298,
            0.009159374982118607,
            -0.012564168311655521,
            0.001016650814563036,
            0.008651421405375004,
            0.013510851189494133,
            0.022589167580008507,
            0.009820370934903622,
            -0.004348765127360821,
            -0.0036230406258255243,
            0.01590917259454727,
            0.019343480467796326,
            0.024934301152825356,
            -0.014131858944892883,
            0.011501510627567768,
            -0.01167387142777443,
            0.01463978923857212,
            -0.018748339265584946,
            -0.013908863067626953,
            0.08217912912368774,
            -0.031913626939058304,
            0.01619940623641014,
            0.018284093588590622,
            0.02642212249338627,
            0.03934070095419884,
            0.027267273515462875,
            -0.03120194375514984,
            0.007268703542649746,
            0.011791498400270939,
            0.03499579429626465,
            -0.01978483982384205,
            -0.030293747782707214,
            -0.02983938716351986,
            0.033653374761343,
            -0.02314251847565174,
            -0.0055888742208480835,
            0.02827007882297039,
            0.008207482285797596,
            0.03332500532269478,
            -0.01199923362582922,
            0.0019161601085215807,
            0.015316412784159184,
            0.0066900490783154964,
            0.03223497048020363,
            0.027742812409996986,
            -0.023709189146757126,
            0.0033144333865493536,
            0.007324013859033585,
            0.018858863040804863,
            -0.0028247134760022163,
            0.016661256551742554,
            0.023657768964767456,
            0.029849719256162643,
            0.007422174792736769,
            -0.008083454333245754,
            0.024478409439325333,
            0.02889026701450348,
            -0.001911695464514196,
            0.04542115703225136,
            -0.015702512115240097,
            -0.017655013129115105
        ],
        [
            -0.04399286210536957,
            -0.023510176688432693,
            0.020566362887620926,
            0.014567778445780277,
            0.004507985431700945,
            -0.01750527322292328,
            0.012406966648995876,
            -0.017582835629582405,
            -0.022016093134880066,
            0.026708411052823067,
            -0.03294019773602486,
            -0.001321508432738483,
            -0.016997365280985832,
            0.03591981157660484,
            -0.009245690889656544,
            0.039669446647167206,
            -0.020004497841000557,
            -0.00792083889245987,
            0.005253945477306843,
            -0.012624742463231087,
            -0.008072037249803543,
            -0.004344390705227852,
            -0.007670493330806494,
            0.03589875251054764,
            -0.026393717154860497,
            -0.01909412071108818,
            0.014594079926609993,
            0.021686473861336708,
            0.030356064438819885,
            -0.06439109146595001,
            -0.027847860008478165,
            0.017948761582374573,
            -0.009547998197376728,
            -0.007882248610258102,
            0.02143144980072975,
            0.057976529002189636,
            0.0026398603804409504,
            -0.021931815892457962,
            -0.03826713562011719,
            -0.011192524805665016,
            0.002611979376524687,
            0.015702946111559868,
            -0.04665689170360565,
            0.025225209072232246,
            0.004633865784853697,
            -0.04303448647260666,
            -0.025296257808804512,
            0.03466293588280678,
            0.01399174053221941,
            0.015447030775249004,
            0.0015004138695076108,
            -0.057473309338092804,
            -0.056489091366529465,
            -0.024390121921896935,
            0.018798377364873886,
            -0.02138591557741165,
            0.010758232325315475,
            0.0036947259213775396,
            0.013141889125108719,
            -0.007425680756568909,
            0.010542262345552444,
            0.023883838206529617,
            0.016437897458672523,
            -0.010988714173436165,
            -0.005761365406215191,
            -0.010758470743894577,
            -0.038782041519880295,
            -0.03703010082244873,
            0.012539578601717949,
            0.01311828289180994,
            0.030082402750849724,
            0.03996827080845833,
            -0.00597537262365222,
            0.03075552172958851,
            0.053568605333566666,
            0.011302907951176167,
            0.01908246986567974,
            0.04728919267654419,
            -0.04362194612622261,
            -0.0011982455616816878,
            -0.0050186580047011375,
            -0.04057956114411354,
            -0.02132776379585266,
            -0.049496445804834366,
            -0.00571984751150012,
            0.024533959105610847,
            0.02730538323521614,
            0.003419059794396162,
            -0.017530713230371475,
            -0.05293582007288933,
            0.0004016640887130052,
            -0.019471842795610428,
            -0.07907532155513763,
            0.01839461177587509,
            -0.023777659982442856,
            -0.014563364908099174,
            0.03145768865942955,
            0.029825782403349876,
            0.0011951873311772943,
            -0.023367462679743767,
            0.02778589352965355,
            0.012757649645209312,
            0.04285823181271553,
            -0.020078929141163826,
            0.002443313365802169,
            0.0023479268420487642,
            -0.002407235559076071,
            0.04408103600144386,
            0.010146347805857658,
            0.016011180356144905,
            -0.01726655662059784,
            -0.004745568614453077,
            0.009226500056684017,
            -0.021329879760742188,
            -0.057902563363313675,
            0.010911565274000168,
            -0.03798085078597069,
            -0.016618749126791954,
            -0.039096858352422714,
            -0.01924615539610386,
            -0.04630564898252487,
            -0.024878578260540962,
            0.005251793656498194,
            -0.03006174974143505,
            0.031004762277007103,
            0.0004849208053201437,
            -4.427926614880562e-05,
            0.024509398266673088,
            0.019697165116667747,
            -0.008500429801642895,
            -0.04618487134575844,
            -0.05403412878513336,
            0.002532677026465535,
            -0.0037020205054432154,
            0.014395512640476227,
            0.05442431569099426,
            -0.0034425174817442894,
            -0.005833114497363567,
            0.014037960208952427,
            0.029668575152754784,
            0.024560406804084778,
            -0.021973593160510063,
            -0.0168630201369524,
            0.005498145241290331,
            -0.014400106854736805,
            0.015350147150456905,
            -0.005476746242493391,
            0.03854653984308243,
            0.0318962000310421,
            0.01447995100170374,
            0.01813380979001522,
            -0.0698414295911789,
            0.004731772933155298,
            -0.03452547639608383,
            -0.024405542761087418,
            -0.01075532753020525,
            0.001674514263868332,
            0.009172330610454082,
            0.04686164855957031,
            0.027734002098441124,
            -0.012215960770845413,
            -0.005209196824580431,
            0.038501106202602386,
            0.015006384812295437,
            0.010047253221273422,
            0.04646691679954529,
            0.013671545311808586,
            0.041183460503816605,
            0.01657523773610592,
            -0.025427429005503654,
            -0.03510550782084465,
            -0.010354050435125828,
            -0.023943081498146057,
            -0.013354474678635597,
            0.02766450308263302,
            0.001296297530643642,
            -0.03397320955991745,
            0.0223270021378994,
            -0.01255244854837656,
            0.020133566111326218,
            0.04858074709773064,
            0.012756460346281528,
            0.019866855815052986,
            0.022220594808459282,
            -0.005432043690234423,
            -0.017591556534171104,
            0.010428719222545624,
            -0.030323462560772896,
            -0.04550427943468094,
            0.027581162750720978,
            -0.03286914899945259,
            0.05256730690598488,
            -0.0643562451004982,
            -0.005725611932575703,
            0.01327579002827406,
            0.03121054731309414,
            -0.017992842942476273,
            -0.02025514841079712,
            -0.038541074842214584,
            0.005591623950749636,
            0.02021794021129608,
            -0.003093537176027894,
            0.0049167354591190815,
            -0.00466615566983819,
            0.04864167422056198,
            -0.004926025867462158,
            0.008671978488564491,
            0.0320851132273674,
            -0.016969213262200356,
            0.006640910636633635,
            0.028468463569879532,
            0.045754339545965195,
            -0.09976053982973099,
            -0.0359659418463707,
            -0.0404365248978138,
            -0.034425411373376846,
            0.0036144109908491373,
            -0.013498649932444096,
            0.023868020623922348,
            -0.03849414363503456,
            0.016397811472415924,
            0.022128242999315262,
            0.016745811328291893,
            0.0303738322108984,
            -0.021156655624508858,
            -0.026648888364434242,
            0.06055882200598717,
            0.029953496530652046,
            0.006011864636093378,
            -0.012637841515243053,
            -0.03210858628153801,
            0.021802829578518867,
            -0.03232673555612564,
            0.005041103344410658,
            -0.007781654596328735,
            -0.01378487329930067,
            -0.012105160392820835,
            0.027324387803673744,
            0.0261544119566679,
            0.03768901526927948,
            -0.018418248742818832,
            -0.02581348642706871,
            0.015680477023124695,
            -2.6024622457043733e-06,
            0.011410093866288662,
            -0.005070504732429981,
            -0.021569201722741127,
            0.02725711464881897,
            -0.013992248103022575,
            -0.02083336003124714,
            0.020605988800525665,
            -0.011216286569833755,
            0.03699008375406265,
            0.032801683992147446,
            0.04546207934617996,
            0.02779061160981655,
            0.008165957406163216,
            0.0016414349665865302,
            0.011932937428355217,
            -0.006174199748784304,
            0.03766246885061264,
            -0.020160214975476265,
            0.021813038736581802,
            0.021606657654047012,
            0.05040784925222397,
            0.03371737524867058,
            -0.010113858617842197,
            0.0005467715673148632,
            -0.031236520037055016,
            -0.007535087876021862,
            -0.027729498222470284,
            0.01727938838303089,
            -0.02843332663178444,
            0.010978088714182377,
            0.01686740107834339,
            -0.013027812354266644,
            -0.006611326243728399,
            -0.011267514899373055,
            0.027888113632798195,
            0.015541146509349346,
            0.021620040759444237,
            0.010984713211655617,
            -0.009472261182963848,
            0.034095391631126404,
            0.015592302195727825,
            -0.004297542851418257,
            0.032333988696336746,
            0.04436805844306946,
            -0.011210829950869083,
            -0.02434522658586502,
            0.031214574351906776,
            0.04042361304163933,
            -0.026007289066910744,
            0.02782023884356022,
            -0.025198079645633698,
            0.009376311674714088,
            0.018380509689450264,
            0.020773855969309807,
            -0.009223787114024162,
            -0.006802205927670002,
            -0.0012248563580214977,
            0.006775744259357452,
            -0.002937105018645525,
            -0.00031018952722661197,
            -0.010806222446262836,
            -0.06516409665346146,
            0.014600878581404686,
            -0.05882237106561661,
            -0.013726728968322277,
            -0.04185457155108452,
            0.04419636353850365,
            -0.022615859284996986,
            -0.01704038493335247,
            -0.016116563230752945,
            -0.027400843799114227,
            0.021122531965374947,
            -0.012264858931303024,
            -0.010189387947320938,
            0.027930164709687233,
            0.022699106484651566,
            0.03525945544242859,
            0.031601328402757645,
            0.02855982817709446,
            -0.039447590708732605,
            0.002630692208185792,
            -0.0083240931853652,
            -0.05204716697335243,
            -0.008427832275629044,
            0.01936892420053482,
            -0.005582988727837801,
            0.007729060482233763,
            -0.020097356289625168,
            -0.024302074685692787,
            0.01786397024989128,
            -0.016961446031928062,
            -0.018349234014749527,
            -0.002556932158768177,
            -0.024003667756915092,
            -0.0397179052233696,
            0.01616423763334751,
            0.02533254772424698,
            -0.017260894179344177,
            0.03412079066038132,
            -0.026435071602463722,
            0.0037316030357033014,
            -0.04174857586622238,
            -0.03342278301715851,
            0.041675470769405365,
            -0.0029654651880264282,
            -0.0038585311267524958,
            0.0089156748726964,
            0.01474673394113779,
            0.008442186750471592,
            -0.0032103480771183968,
            -0.021518167108297348,
            -0.0011864746920764446,
            -0.022090468555688858,
            0.0032441518269479275,
            0.014352013356983662,
            0.02118404023349285,
            0.021282389760017395,
            -0.0014285509241744876,
            -0.03191211447119713,
            0.01974230632185936,
            -0.02871369570493698,
            -0.0327688530087471,
            0.021837076172232628,
            0.009556435979902744,
            -0.005251206457614899,
            0.017603369429707527,
            0.025979405269026756,
            -0.011976920999586582,
            0.017101291567087173,
            0.004407000727951527,
            -0.006138067226856947,
            -0.001258029486052692,
            -0.00012164905638201162,
            0.00669053802266717,
            0.020350171253085136,
            0.00669884355738759,
            -0.023759236559271812,
            0.020749758929014206,
            0.018795473501086235,
            -0.022129129618406296,
            0.013026507571339607,
            -0.021461132913827896,
            0.027615945786237717,
            -0.02986178547143936,
            0.017815006896853447,
            0.024357769638299942,
            -0.0372530035674572,
            -0.0027447252068668604,
            0.015224426984786987,
            -0.003005127189680934,
            -0.08321696519851685,
            -0.00937330536544323,
            -0.005765469744801521,
            -0.08144256472587585,
            0.01673823781311512,
            0.01963173784315586,
            -0.00014449076843447983,
            -0.013347556814551353,
            0.0056677027605473995,
            -0.038597285747528076,
            -0.03370765969157219,
            -0.0111635597422719,
            -0.013116631656885147,
            -0.04343245550990105,
            0.022882472723722458,
            -0.01538896281272173,
            0.008622067980468273,
            -0.028393849730491638,
            -0.006011904217302799,
            0.005470507312566042,
            -0.02632693201303482,
            0.029841529205441475,
            -0.0624418668448925,
            0.05597716569900513,
            -0.03598899394273758,
            -0.027274413034319878,
            0.017266083508729935,
            0.013779436238110065,
            -0.07476724684238434,
            0.003694027429446578,
            -0.03850837051868439,
            -0.01648581773042679,
            0.00885718036442995,
            0.05092845857143402,
            -0.02779119275510311,
            -0.023939836770296097,
            0.03410817310214043,
            0.02525489777326584,
            0.01368603017181158,
            0.004365378059446812,
            0.023167990148067474,
            -0.005683570168912411,
            0.026988603174686432,
            0.00217354204505682,
            0.021954521536827087,
            -0.07930930703878403,
            -0.005064207129180431,
            0.03188277408480644,
            -0.009176015853881836,
            0.022599080577492714,
            0.009096802212297916,
            -0.0002668745582923293,
            -0.02178850769996643,
            -0.08256109803915024,
            -0.04031911492347717,
            -0.023624178022146225,
            0.008101067505776882,
            -0.01645463891327381,
            0.022709300741553307,
            -0.012239435687661171,
            -0.0042158132418990135,
            -0.011274771764874458,
            0.010908849537372589,
            -0.016379868611693382,
            -0.003932327032089233,
            -0.02049827203154564,
            -0.02138667367398739,
            -0.01106597576290369,
            0.01811097003519535,
            -0.03676976263523102,
            0.0034613630268722773,
            0.024937305599451065,
            0.015701936557888985,
            0.018100237473845482,
            0.03030548058450222,
            -0.013322019949555397,
            0.03310715779662132,
            0.002464934950694442,
            0.029216432943940163,
            -0.01649044081568718,
            0.007083714008331299,
            -0.054298415780067444,
            -0.02548854984343052,
            0.04035261645913124,
            0.03410395607352257,
            -0.005903397686779499,
            0.015152530744671822,
            -0.0045181754976511,
            -0.030469713732600212,
            0.016240321099758148,
            -0.08289795368909836,
            0.004790882114320993,
            -0.031092645600438118,
            0.022014355286955833,
            -0.1273459941148758,
            0.039767030626535416,
            -0.01786925457417965,
            0.020950766280293465,
            0.013809438794851303,
            0.0250020083039999,
            0.009914481081068516,
            -0.01596429944038391,
            0.024749796837568283,
            0.02700025402009487,
            0.01103176735341549,
            -0.0193952489644289,
            -0.025196222588419914,
            0.00018110130622517318,
            0.00411801366135478,
            -0.008049461990594864,
            0.018882526084780693,
            -0.0024465322494506836,
            -0.012711592949926853,
            -0.004298916086554527,
            0.018843596801161766,
            -0.005558593198657036,
            -0.02115604095160961,
            -0.052092112600803375,
            0.01651269569993019,
            0.0066352118737995625,
            -0.03884923830628395,
            -0.01936454512178898,
            -0.017913611605763435,
            0.025563690811395645,
            0.03068668767809868,
            0.008482754230499268,
            0.03780639171600342,
            -0.009096751920878887,
            0.0022278130054473877,
            0.018107648938894272,
            0.0591956228017807,
            -0.005217549856752157,
            0.01779160648584366,
            -0.02002735808491707,
            -0.006238545756787062,
            0.02276378497481346,
            -0.014019086956977844,
            0.008308290503919125,
            -0.06104152649641037,
            0.002043285174295306,
            -0.01972811110317707,
            -0.03197762370109558,
            0.024800429120659828,
            -0.013828206807374954,
            0.005916951224207878,
            -0.035241685807704926,
            -0.019921617582440376,
            0.0019704231526702642,
            -0.020482005551457405,
            0.024217797443270683,
            -0.013430692255496979,
            0.0722622200846672,
            -0.012328109703958035,
            0.00012565091310534626,
            -0.011634639464318752,
            -0.04971694573760033,
            -0.031368330121040344,
            0.01089499145746231,
            0.01871790923178196,
            -0.01633080653846264,
            0.015151171013712883,
            -0.012758315540850163,
            -0.022540908306837082,
            -0.041633542627096176,
            0.004865729715675116,
            0.018766894936561584,
            -0.015216242522001266,
            0.009606601670384407,
            -0.029098104685544968,
            -0.008283574134111404,
            0.011707467958331108,
            0.023389333859086037,
            -0.014130331575870514,
            0.01620377041399479,
            -0.01694219931960106,
            -0.04343554377555847,
            -0.031242959201335907,
            0.03608550876379013,
            0.006148726213723421,
            -0.010013806633651257,
            0.02968640998005867,
            -0.007415657863020897,
            -0.009442082606256008,
            -0.016649510711431503,
            -0.020921027287840843,
            -0.018705135211348534,
            0.02175525203347206,
            -0.05064887925982475,
            -0.047065336257219315,
            0.04355556517839432,
            0.04397294297814369,
            -0.045285630971193314,
            0.0120887141674757,
            -0.03730200603604317,
            0.006572025828063488,
            -0.0046078977175056934,
            -0.02480136603116989,
            -0.018019696697592735,
            0.03859188035130501,
            0.015370196662843227,
            -0.010412778705358505,
            -0.012549773789942265,
            0.03371512517333031,
            -0.0039322031661868095,
            0.0009178410400636494,
            -0.019426416605710983,
            -0.03809664770960808,
            0.03453976660966873,
            -0.02824995666742325,
            -0.013860971666872501,
            0.022645361721515656,
            -0.025326907634735107,
            0.0053379531018435955,
            0.0009854644304141402,
            -0.01758912391960621,
            -0.013092001900076866,
            -0.02527604252099991,
            0.007802208885550499,
            0.028116852045059204,
            -0.025829492136836052,
            -0.033574867993593216,
            -0.013936246745288372,
            0.0029898262582719326,
            -0.03725867345929146,
            -0.0016143408138304949,
            -0.024228708818554878,
            -0.0021831332705914974,
            -0.012206831946969032,
            -0.0055116936564445496,
            -0.011477149091660976,
            0.014536608010530472,
            0.019115572795271873,
            -0.005543426610529423,
            -0.025086667388677597,
            -0.01321986224502325,
            0.03738759830594063,
            -0.007286034524440765,
            0.03317401558160782,
            0.02254997193813324,
            -0.020454084500670433,
            -0.05517779290676117,
            0.004393051844090223,
            0.031223895028233528,
            0.033652372658252716,
            0.015673670917749405,
            -0.007777381222695112,
            0.004808075726032257,
            -0.017275162041187286,
            0.021062390878796577,
            -0.07614540308713913,
            -0.007209730334579945,
            0.045817695558071136,
            -0.03649677708745003,
            -0.0029700917657464743,
            0.0429103709757328,
            -0.012274994514882565,
            -0.024908363819122314,
            0.01219254918396473,
            -0.0027457508258521557,
            -0.022224482148885727,
            0.008630484342575073,
            0.003960350528359413,
            0.001986290793865919,
            -0.03510447219014168,
            -0.0089366864413023,
            -0.0027009835466742516,
            0.032150235027074814,
            -0.00939352996647358,
            -0.039572227746248245,
            0.026913847774267197,
            -0.037048645317554474,
            0.03523002564907074,
            0.017909638583660126,
            -0.01269550807774067,
            0.02495342493057251,
            -0.011032416485249996,
            0.028192538768053055,
            0.0008855890482664108,
            0.011265348643064499,
            0.008710911497473717,
            -0.011881857179105282,
            -0.028293676674365997,
            -0.012636538594961166,
            -0.02436279132962227,
            0.001180063933134079,
            -0.012431463226675987,
            -0.0003181941865477711,
            0.018482204526662827,
            0.00011640620505204424,
            -0.02548978291451931,
            0.022984279319643974,
            0.010061321780085564,
            -0.031081581488251686,
            -0.036622967571020126,
            0.0420619435608387,
            0.03715896978974342,
            0.023949531838297844,
            0.03629651665687561,
            0.007807879243046045,
            -0.00928453914821148,
            -0.017402157187461853,
            0.01468479260802269,
            0.03231813386082649,
            0.017034579068422318,
            0.005822925362735987,
            0.03601847589015961,
            0.008512399159371853,
            0.00843101181089878,
            -0.01916871964931488,
            -0.016820324584841728,
            -0.03624064102768898,
            0.027886319905519485,
            -0.03511560708284378,
            -0.011445105075836182,
            0.0016416055150330067,
            0.03369179368019104,
            -0.026747804135084152,
            0.008743947371840477,
            -0.010254277847707272,
            -0.036818329244852066,
            -0.0004057934565935284,
            0.023543933406472206,
            -0.00041667529148980975,
            -0.02692577801644802,
            0.007362040225416422,
            0.020845912396907806,
            0.03257066383957863,
            -0.0024044085294008255,
            0.006506236735731363,
            -0.005758245941251516,
            0.005455110687762499,
            0.012690586037933826,
            -0.029834335669875145,
            0.024181654676795006,
            -0.012934830971062183,
            0.04044516384601593,
            -0.0014092025812715292,
            -0.0036282881628721952,
            -0.02264198288321495,
            -0.04465910792350769,
            0.004478320945054293,
            -0.011752899736166,
            -0.019162140786647797,
            -0.005192675162106752,
            -0.026790253818035126,
            0.02594570256769657,
            0.01712804101407528,
            0.000948962930124253,
            -0.02750772051513195,
            -0.018544966354966164,
            -0.003157360479235649,
            0.015307591296732426,
            0.013332715258002281,
            0.039890989661216736,
            -0.028194516897201538,
            -0.030673379078507423,
            -0.016759201884269714,
            -0.012476661242544651,
            -0.011005791835486889,
            -0.0022513847798109055,
            -0.003292686538770795,
            -0.009361318312585354,
            0.016663413494825363,
            -0.0057034860365092754,
            0.0213882215321064,
            0.01993589848279953,
            -0.06163821369409561,
            0.003298572963103652,
            0.009570053778588772,
            0.04700568690896034,
            -0.032212380319833755,
            0.013571003451943398,
            0.008257944136857986,
            0.031263623386621475,
            -0.0031521960627287626,
            0.001688377233222127,
            0.011367020197212696,
            -0.011209693737328053,
            0.036092359572649,
            -0.007335987873375416,
            0.02984960563480854,
            0.021656394004821777,
            0.03954461216926575,
            -0.011070904321968555,
            -0.017533283680677414,
            -0.019814224913716316,
            -0.00477531086653471,
            -0.0014801330398768187,
            -0.04390088841319084,
            -0.0183542650192976,
            0.012849305756390095,
            0.008601979352533817,
            -0.006141624413430691,
            -0.017355123534798622,
            -0.020555496215820312,
            0.0002212786057498306,
            0.041527021676301956,
            -0.01816915161907673,
            0.015295075252652168,
            0.016115158796310425,
            0.022574754431843758,
            -0.03223658725619316,
            -0.0199213195592165,
            0.02284318581223488,
            0.003959504887461662,
            0.018335167318582535,
            -0.044536057859659195,
            0.004828216973692179,
            -0.0006273851031437516,
            0.018182823434472084,
            -0.033378325402736664,
            -0.00197657011449337,
            -0.01825990527868271,
            -0.01555390190333128,
            -0.03007631190121174,
            0.005828503984957933,
            -0.01297775749117136,
            0.017287908121943474,
            -0.03770541027188301,
            -0.00241121300496161,
            0.003582849632948637,
            -0.008472973480820656,
            -0.04496321082115173,
            -0.024190975353121758,
            0.038088321685791016,
            -0.0252644382417202,
            -0.02910465933382511,
            0.010091984644532204,
            -0.035216011106967926,
            -0.01324662659317255,
            0.012227529659867287,
            -0.0015163642819970846,
            0.04189908131957054,
            0.013942593708634377,
            -0.0005317212198860943,
            -0.021943120285868645,
            -0.0030861487612128258,
            -0.014899668283760548,
            0.0011137210531160235,
            0.023397816345095634,
            0.004316692240536213,
            0.021978160366415977,
            0.020186543464660645,
            -0.00864549819380045,
            0.021505894139409065,
            0.04360358044505119,
            -0.02148391492664814,
            -0.020433658733963966,
            -0.011663949117064476,
            -0.009974995627999306,
            -0.010242702439427376,
            -0.007738546468317509,
            0.04200868308544159,
            -0.00020223732281010598,
            0.004928757902234793,
            0.014215831644833088,
            0.006175075192004442,
            0.03528626263141632,
            0.0299499724060297,
            0.02696787752211094,
            -0.03685759752988815,
            -0.012058814987540245,
            0.038671474903821945,
            0.018626248463988304,
            -0.025647254660725594,
            -0.03499584645032883,
            -0.03407948836684227,
            0.03660261258482933,
            0.012546061538159847,
            -0.016296174377202988,
            -0.009011791087687016,
            -0.013372259214520454,
            -0.016285549849271774,
            0.015483363531529903,
            0.021991977468132973,
            0.017307573929429054,
            -0.02363424375653267,
            -0.023044133558869362,
            0.02514168992638588,
            -0.0250791497528553,
            0.02171103097498417,
            0.016413532197475433,
            -0.01730111800134182,
            -0.04112156480550766,
            0.020268656313419342,
            -0.024230841547250748,
            0.028511377051472664,
            -0.06365283578634262,
            -0.005659676156938076,
            -0.029465341940522194,
            -0.027568692341446877,
            0.02355247363448143,
            -0.0033815994393080473,
            0.01452568918466568,
            0.013698157854378223,
            0.019225604832172394,
            -0.01051277481019497,
            -0.006717931479215622,
            -0.03628959134221077,
            0.03240538015961647,
            0.04798916354775429,
            0.01953739859163761,
            0.015492000617086887,
            0.015847448259592056,
            0.03256447985768318,
            -0.01092700008302927,
            -0.013579051941633224,
            0.028007198125123978,
            -0.013148268684744835,
            0.0019398038275539875,
            0.04269910976290703,
            -0.021061377599835396,
            -0.05199853330850601,
            -0.006812610197812319,
            0.02366570755839348,
            0.02997862920165062,
            -0.03591422364115715,
            0.012846543453633785,
            -0.0325818732380867,
            0.02040734700858593,
            0.0027706788387149572,
            0.012219084426760674,
            -0.0149284852668643,
            0.014751506969332695,
            0.019200550392270088,
            0.011837794445455074,
            0.026815110817551613,
            -0.013044485822319984,
            0.029877057299017906,
            -0.03050190582871437,
            0.049045003950595856,
            0.029841236770153046,
            -0.014631417579948902,
            0.04130920395255089,
            -0.040077559649944305,
            -0.026030458509922028,
            0.0015188991092145443,
            0.01844143494963646,
            0.011775154620409012,
            0.004265384748578072,
            0.042969122529029846,
            0.015760865062475204,
            0.017122626304626465,
            -0.013619201257824898,
            0.04168423265218735,
            0.0019322335720062256,
            -0.03268543630838394,
            -0.0362907275557518,
            -0.006599379703402519,
            0.008782492950558662,
            -0.00600539892911911,
            0.024594010785222054,
            -0.006219005677849054,
            0.017765101045370102,
            -0.007992269471287727,
            -0.015771377831697464,
            0.0060169631615281105,
            0.0012057110434398055,
            -0.005916764494031668,
            0.03676607087254524,
            -0.0004948530695401132,
            -0.001023748773150146,
            0.01349122729152441,
            0.009775174781680107,
            0.012747553177177906,
            -0.0022800578735768795,
            -0.004944709595292807,
            -0.0028782696463167667,
            -0.0011554955272004008,
            0.018792113289237022,
            0.0030332733877003193,
            -0.05293450132012367,
            -0.048301227390766144,
            0.017686204984784126,
            -0.013029629364609718,
            -0.014105724170804024,
            -0.007785095367580652,
            -0.018255483359098434,
            0.009950295090675354,
            -0.022403761744499207,
            0.0074743228033185005,
            -0.016933653503656387,
            -0.006513752043247223,
            -0.003116469131782651,
            0.03029610961675644,
            -0.027112139388918877,
            -0.010867869481444359,
            -0.038215260952711105,
            -0.06796427816152573,
            -0.004147350322455168,
            0.008298645727336407,
            -0.015133334323763847,
            -0.0014323615469038486,
            -0.01667787879705429,
            -0.0095270536839962,
            0.01440616138279438,
            0.019560959190130234,
            0.015419689938426018,
            -0.0012659765779972076,
            0.0269829872995615,
            -0.0004581430403050035,
            0.0056616561487317085,
            0.019298573955893517,
            -0.00499947601929307,
            -0.05052196606993675,
            0.03580785542726517,
            0.033908918499946594,
            0.019012069329619408,
            0.06169100105762482,
            0.01824081689119339,
            0.014560388401150703,
            0.003769513452425599,
            0.002695500385016203,
            -0.045097071677446365,
            0.036321524530649185,
            -0.017428889870643616,
            0.003001206321641803,
            0.028872814029455185,
            0.025009475648403168,
            -0.009530959650874138,
            0.011554802767932415,
            -0.021359603852033615,
            0.01519794575870037,
            -0.002426974941045046,
            0.00215508253313601,
            0.011168085969984531,
            -0.0760837197303772,
            -0.032239027321338654,
            -0.029299935325980186,
            0.008533221669495106
        ],
        [
            -0.010077874176204205,
            0.01271588820964098,
            -0.019946644082665443,
            -0.001738897175528109,
            -0.026218434795737267,
            -0.0050307950004935265,
            0.02509276010096073,
            -0.004641009960323572,
            -0.02971116453409195,
            -0.019782112911343575,
            0.008349885232746601,
            -0.017860455438494682,
            0.02447495050728321,
            -0.025204341858625412,
            0.003751250682398677,
            0.0022857249714434147,
            -0.007321775890886784,
            0.047613706439733505,
            -0.022863687947392464,
            0.029514767229557037,
            -0.01501709595322609,
            -0.03805961087346077,
            0.015205479227006435,
            0.012365723960101604,
            0.035884179174900055,
            0.004263635724782944,
            0.022044885903596878,
            -0.003334362292662263,
            -0.019310640171170235,
            -0.0028725413139909506,
            -0.02435998246073723,
            -0.011064102873206139,
            0.029999475926160812,
            -0.019695637747645378,
            0.030658381059765816,
            -0.03483767434954643,
            0.02207832597196102,
            -0.0014748189132660627,
            -0.02179672382771969,
            -0.01989656127989292,
            -0.008234139531850815,
            -0.07308144122362137,
            0.02130581997334957,
            -0.008848102763295174,
            0.003968988545238972,
            0.02206527628004551,
            0.0008141502621583641,
            0.02236858755350113,
            -0.010249297134578228,
            0.0014676401624456048,
            -0.027301855385303497,
            0.013137531466782093,
            -0.028427092358469963,
            -0.022106623277068138,
            0.045911744236946106,
            -0.0011051861802116036,
            0.018886853009462357,
            0.0229935385286808,
            -0.006183434743434191,
            0.03194174915552139,
            -0.016605375334620476,
            -0.013147526420652866,
            -0.008928135968744755,
            0.024813389405608177,
            -0.02913963794708252,
            -0.009461339563131332,
            0.04549407586455345,
            0.010651635937392712,
            0.006002555135637522,
            0.0296643003821373,
            0.02503308653831482,
            -0.009517980739474297,
            0.0018936224514618516,
            -0.01707400567829609,
            0.06107202544808388,
            -0.044805366545915604,
            0.025325482711195946,
            0.02225092425942421,
            -0.019085301086306572,
            0.03402944654226303,
            -0.01739535480737686,
            -0.00834984052926302,
            -0.040131039917469025,
            -0.01918930374085903,
            -0.0075625828467309475,
            -0.007791459560394287,
            0.014598114416003227,
            0.020575985312461853,
            0.005973146762698889,
            -0.05347522348165512,
            -4.3334028305253014e-05,
            -0.0026456143241375685,
            0.003672270569950342,
            -0.006500015966594219,
            0.03773800656199455,
            0.014524469152092934,
            0.012202718295156956,
            -0.026230888441205025,
            -0.049757178872823715,
            -0.0135791702196002,
            0.00711797084659338,
            0.03389004245400429,
            0.015774734318256378,
            0.01382502168416977,
            0.006241105031222105,
            -0.050491802394390106,
            -0.013747950084507465,
            -0.0024675135500729084,
            -0.014455811120569706,
            0.015558706596493721,
            -0.03582761436700821,
            0.014656754210591316,
            0.012716323137283325,
            0.014915366657078266,
            -0.020038845017552376,
            0.0034662545658648014,
            -0.022623710334300995,
            -0.01776566170156002,
            -0.0049662161618471146,
            0.0366494320333004,
            0.027946949005126953,
            0.010550430044531822,
            0.08854071795940399,
            -0.028984667733311653,
            0.008233592845499516,
            -0.026669474318623543,
            0.03294716402888298,
            -0.016389768570661545,
            0.025444205850362778,
            0.04358658194541931,
            0.004294164944440126,
            0.013836300931870937,
            0.00010681815183488652,
            -0.01938495971262455,
            -0.01776118576526642,
            -0.013071092776954174,
            -0.046864401549100876,
            -0.04898751899600029,
            0.03133619576692581,
            -0.027662243694067,
            -0.006248048041015863,
            0.03166758641600609,
            0.01032152958214283,
            0.014876339584589005,
            -0.019833311438560486,
            0.05005420371890068,
            -0.01862254925072193,
            0.003295599715784192,
            0.006734267808496952,
            0.020155465230345726,
            -0.028413135558366776,
            0.025929251685738564,
            -0.011349400505423546,
            0.0016465227818116546,
            -0.011676421388983727,
            -0.016124751418828964,
            0.015196986496448517,
            -0.006317666731774807,
            -0.023010356351733208,
            -0.005510920193046331,
            -0.01733499765396118,
            0.028963683173060417,
            0.025424610823392868,
            0.006558876018971205,
            0.004310664255172014,
            0.045015767216682434,
            0.009642179124057293,
            -0.001359379617497325,
            -0.03265094757080078,
            -0.00824648980051279,
            -0.016555342823266983,
            0.025590358301997185,
            -0.04992089420557022,
            -0.014518978074193,
            0.011271306313574314,
            -0.0070612370036542416,
            0.013186848722398281,
            -0.020135056227445602,
            -0.008661586791276932,
            -0.017254458740353584,
            -0.03163507953286171,
            0.02200416289269924,
            -0.016612475737929344,
            0.0337962731719017,
            -0.009117141366004944,
            0.02444601245224476,
            0.017758525907993317,
            0.010415653698146343,
            0.023655982688069344,
            0.01693957857787609,
            0.028483737260103226,
            -0.019475022330880165,
            -0.03349195420742035,
            0.02647588402032852,
            -0.028705425560474396,
            -0.018432321026921272,
            0.015183770097792149,
            0.0245014950633049,
            -0.008579869754612446,
            0.01483157742768526,
            0.03852236270904541,
            0.02373553439974785,
            0.030772825703024864,
            -0.0194737296551466,
            0.044705960899591446,
            0.004867346957325935,
            -0.034998729825019836,
            0.020796343684196472,
            -0.0058421790599823,
            -0.024997903034090996,
            -0.003475202014669776,
            0.03887731209397316,
            0.008078009821474552,
            -0.021541891619563103,
            0.02261340245604515,
            0.021846415475010872,
            0.0027949041686952114,
            -0.019388925284147263,
            0.02986299991607666,
            0.004573680926114321,
            -0.02022387646138668,
            -0.0039891875348985195,
            -0.048225149512290955,
            -0.005044571589678526,
            -0.011072195135056973,
            0.0028941817581653595,
            0.04367326945066452,
            0.03753269836306572,
            0.027595190331339836,
            0.013322295621037483,
            -0.005645418073982,
            -0.02163960412144661,
            -0.013280547223985195,
            0.006460001226514578,
            -0.022614093497395515,
            -0.005963215604424477,
            0.060544099658727646,
            -0.01589926704764366,
            -0.024058077484369278,
            0.03862058371305466,
            0.020742839202284813,
            0.019610490649938583,
            -0.012262266129255295,
            0.009335514158010483,
            -0.024274123832583427,
            -0.02446943335235119,
            0.03945089504122734,
            -0.017983000725507736,
            0.005892015527933836,
            0.023162400349974632,
            0.04104536026716232,
            0.0006404694286175072,
            0.023708447813987732,
            -0.02016371861100197,
            0.012727118097245693,
            -0.013004195876419544,
            -0.023384839296340942,
            0.03216620907187462,
            0.025614969432353973,
            0.028584405779838562,
            -0.011058771051466465,
            -0.01687907800078392,
            -0.0008491356857120991,
            0.004235462751239538,
            0.015107744373381138,
            0.034744203090667725,
            -0.009063538163900375,
            0.0012584299547597766,
            0.03837939724326134,
            -0.0030824143905192614,
            -0.006599371787160635,
            0.02048848755657673,
            0.01018648874014616,
            -0.006650757510215044,
            0.012521624565124512,
            -0.017286332324147224,
            0.025140678510069847,
            -0.0207489263266325,
            -0.04797428473830223,
            0.020469821989536285,
            -0.022291967645287514,
            -0.012096702121198177,
            -0.017430171370506287,
            -0.016025185585021973,
            -0.019857464358210564,
            -0.0028294355142861605,
            -0.02427121438086033,
            -0.008828248828649521,
            -0.008797552436590195,
            0.016260333359241486,
            0.0016477372264489532,
            0.0012566301738843322,
            0.006282765883952379,
            -0.015365814790129662,
            -0.006276688538491726,
            0.03330277279019356,
            -0.00310537894256413,
            -0.0044390554539859295,
            0.02689378336071968,
            -0.021257877349853516,
            0.01842353120446205,
            0.023758383467793465,
            -0.03940222039818764,
            -0.019051555544137955,
            0.025342419743537903,
            -0.0027017679531127214,
            -0.027541397139430046,
            -0.0034619863145053387,
            0.006295652128756046,
            -0.01903284527361393,
            -0.08216580003499985,
            -0.012242719531059265,
            -0.024021532386541367,
            0.019332120195031166,
            0.009906625375151634,
            -0.02890615165233612,
            -0.0005097618559375405,
            -0.02638290636241436,
            -0.034845683723688126,
            -0.028702059760689735,
            0.011406282894313335,
            -0.0402434840798378,
            -0.060074739158153534,
            0.03800710663199425,
            0.028127891942858696,
            0.005582435522228479,
            -0.017458666115999222,
            -0.0012010268401354551,
            0.007436051033437252,
            -0.018325038254261017,
            0.013196106068789959,
            -0.00931254867464304,
            0.03838563710451126,
            -0.016635626554489136,
            -0.021819522604346275,
            0.013935143128037453,
            0.0007758963038213551,
            -0.0006921511958353221,
            -0.0007858660537749529,
            0.002312873722985387,
            0.01762060448527336,
            0.02671903930604458,
            0.027618857100605965,
            0.029757460579276085,
            -0.010110748000442982,
            0.041016265749931335,
            0.019446756690740585,
            -0.031595829874277115,
            0.009349326603114605,
            0.023482972756028175,
            -0.01235232874751091,
            -0.010077573359012604,
            0.019071422517299652,
            -0.020339418202638626,
            0.017601095139980316,
            0.013811614364385605,
            -0.007221587467938662,
            -0.026227330788969994,
            -0.01723019778728485,
            -0.0005876200739294291,
            -0.0027826575096696615,
            -0.002228599740192294,
            0.03196639195084572,
            -0.00867474265396595,
            -0.013426326215267181,
            0.029342839494347572,
            -0.0076215858571231365,
            3.8020036299712956e-05,
            0.020269660279154778,
            0.013481725007295609,
            -0.03200747072696686,
            -0.024576624855399132,
            -0.004909215494990349,
            -0.043902941048145294,
            0.02780650183558464,
            0.001973044825717807,
            -0.01463387068361044,
            0.008116740733385086,
            0.019686410203576088,
            -0.024613896384835243,
            -0.029709214344620705,
            -0.017565838992595673,
            0.03136616200208664,
            -0.026574378833174706,
            0.03480635583400726,
            -0.007029395550489426,
            -0.02640576660633087,
            -0.0018046695040538907,
            0.02455279976129532,
            -0.008529224433004856,
            0.03571266308426857,
            0.015434577129781246,
            0.019321246072649956,
            0.03227831423282623,
            0.022325588390231133,
            -0.02132406085729599,
            0.01173489447683096,
            0.020524468272924423,
            0.017740346491336823,
            -0.029810314998030663,
            0.02813446708023548,
            -0.010822908021509647,
            0.028487423434853554,
            -0.0008898148080334067,
            -0.019389299675822258,
            0.010203279554843903,
            -0.013395702466368675,
            0.013591566123068333,
            0.02524075284600258,
            -0.01635805331170559,
            0.04188079386949539,
            -0.024701599031686783,
            -0.008908459916710854,
            -0.0012033405946567655,
            -0.008450018242001534,
            0.0068979039788246155,
            0.021769516170024872,
            -0.03910699859261513,
            -0.00186084711458534,
            -0.008869767189025879,
            0.06604508310556412,
            0.023539118468761444,
            -0.05119193345308304,
            -0.009901443496346474,
            -0.0015617532189935446,
            -0.006545895244926214,
            0.025028051808476448,
            0.03692113608121872,
            0.011442462913691998,
            0.0037409255746752024,
            0.004449533298611641,
            0.018363259732723236,
            -0.021700339391827583,
            -0.007978193461894989,
            -0.00716257793828845,
            0.014260308817029,
            -0.0018304125405848026,
            0.006939978804439306,
            -0.011472862213850021,
            0.04132217541337013,
            0.049145571887493134,
            -0.005035695619881153,
            -0.0230079498142004,
            0.020961150527000427,
            0.02950313314795494,
            0.010205541737377644,
            -0.016536187380552292,
            -0.021984802559018135,
            0.022035714238882065,
            -0.008378768339753151,
            0.02147386036813259,
            0.015652887523174286,
            0.004634126089513302,
            -0.017907317727804184,
            0.03528169170022011,
            0.03568601235747337,
            0.02007441036403179,
            -0.01491217315196991,
            -0.005557965021580458,
            0.0050314487889409065,
            -0.014486541040241718,
            -0.004304959904402494,
            0.015980618074536324,
            0.03271578624844551,
            -0.04391466826200485,
            0.028703296557068825,
            0.034162696450948715,
            0.011807073839008808,
            -0.027618765830993652,
            -0.05317092686891556,
            -0.012132887728512287,
            -0.024841880425810814,
            0.01631958782672882,
            -0.00871680211275816,
            -0.03450946882367134,
            0.007323101162910461,
            0.017040744423866272,
            -0.0034443133044987917,
            0.0005935367662459612,
            0.024074606597423553,
            -0.04036187380552292,
            -0.02277499809861183,
            -0.018758315593004227,
            -0.009246721863746643,
            0.04616347327828407,
            -0.01607261225581169,
            -0.02603885717689991,
            -0.016480660066008568,
            -0.01455618254840374,
            0.01821771450340748,
            0.04057051241397858,
            -0.010386216454207897,
            0.015204429626464844,
            0.018185457214713097,
            0.03057701699435711,
            -0.013954834081232548,
            -0.033085912466049194,
            0.02413184382021427,
            -0.014635912142693996,
            0.00222693826071918,
            0.029293544590473175,
            0.012453132309019566,
            0.01999793015420437,
            -0.026185883209109306,
            0.02912522666156292,
            0.008023460395634174,
            0.03062867745757103,
            0.01678347773849964,
            0.019638393074274063,
            -0.04842284694314003,
            0.030320847406983376,
            -0.00010318782005924731,
            -0.031193988397717476,
            -0.02883378230035305,
            -0.015509548597037792,
            -0.024224167689681053,
            -0.022462720051407814,
            0.03207947313785553,
            -0.0052596465684473515,
            -0.04836847260594368,
            -0.02103230729699135,
            0.018643656745553017,
            -0.009195518679916859,
            0.0122900465503335,
            -0.016259321942925453,
            -0.02875618450343609,
            -0.04599115625023842,
            0.02629084140062332,
            -0.014084012247622013,
            0.001237379154190421,
            -0.03352934122085571,
            0.010518722236156464,
            -0.013534901663661003,
            -0.012349436990916729,
            -0.0009330479661002755,
            -0.028256595134735107,
            0.028441911563277245,
            -0.009102164767682552,
            -0.03046789951622486,
            -0.0022599210496991873,
            -0.012348989024758339,
            0.028845831751823425,
            -0.01826535165309906,
            -0.02011675015091896,
            0.018341757357120514,
            0.040230609476566315,
            0.00849185511469841,
            0.03256838023662567,
            0.019786568358540535,
            -0.00533642154186964,
            0.005745747126638889,
            -0.027209648862481117,
            -0.019644364714622498,
            -0.019366301596164703,
            -0.020047150552272797,
            -0.04983853921294212,
            -0.029797270894050598,
            -0.00894089974462986,
            0.023882640525698662,
            -0.012392389588057995,
            -0.04203013703227043,
            -0.006742965895682573,
            0.0054726325906813145,
            0.02891729213297367,
            -0.02727249637246132,
            0.015679284930229187,
            0.015380186028778553,
            -0.007898535579442978,
            -0.0033877131063491106,
            0.029575733467936516,
            -0.01742357574403286,
            -0.008159715682268143,
            0.014208904467523098,
            0.02620394341647625,
            -0.01507242489606142,
            -0.031639426946640015,
            0.001703873509541154,
            -0.051403071731328964,
            0.01121603138744831,
            0.00010827214282471687,
            0.0030661998316645622,
            -0.005626880098134279,
            0.006485138088464737,
            -0.011848993599414825,
            0.009411728009581566,
            -0.034372132271528244,
            -0.013710388913750648,
            0.009095647372305393,
            -0.003361726412549615,
            -0.006258644163608551,
            0.02202540449798107,
            -0.0027276219334453344,
            -0.005423990078270435,
            0.001352429506368935,
            -0.06075005233287811,
            -0.01453024335205555,
            0.04005742073059082,
            -0.03509114310145378,
            0.01520130317658186,
            0.00985551718622446,
            0.002267697127535939,
            -0.025604039430618286,
            0.03191283717751503,
            0.01161989662796259,
            0.00547315739095211,
            0.01840655878186226,
            -0.003858383512124419,
            0.01912999153137207,
            -0.013969981111586094,
            0.015049375593662262,
            -0.025595804676413536,
            0.00033704552333801985,
            0.013954064808785915,
            -0.021732212975621223,
            0.036737069487571716,
            -0.006461283192038536,
            -0.011911760084331036,
            0.024073299020528793,
            0.002800826681777835,
            -0.001757038407959044,
            0.046098850667476654,
            0.029072176665067673,
            -0.025212783366441727,
            -0.011334824375808239,
            0.04034345969557762,
            0.044600751250982285,
            0.039737362414598465,
            0.028216879814863205,
            -0.025309598073363304,
            -0.02617267519235611,
            0.006360179278999567,
            0.024453703314065933,
            0.008950057439506054,
            -0.01478145457804203,
            0.00982728973031044,
            0.0021838839165866375,
            0.0034664638806134462,
            -0.03317977115511894,
            0.03571637347340584,
            0.021375609561800957,
            0.01966683752834797,
            0.036302682012319565,
            0.022568978369235992,
            -0.01442940067499876,
            0.03459613770246506,
            -0.03494078293442726,
            -0.001558216055855155,
            -0.020025867968797684,
            0.028633182868361473,
            -0.014910771511495113,
            0.0017624226165935397,
            -0.005319219082593918,
            -0.006996879819780588,
            -0.03091445565223694,
            0.012833747081458569,
            0.002450962085276842,
            0.0023715419229120016,
            -0.013623636215925217,
            -0.009772048331797123,
            0.023016881197690964,
            0.042919937521219254,
            0.012989426963031292,
            -0.025849640369415283,
            0.006790990941226482,
            -0.005647337529808283,
            -0.025557005777955055,
            0.007309128064662218,
            -0.013955085538327694,
            -0.006880712229758501,
            -0.012460839934647083,
            0.011366456747055054,
            0.047910891473293304,
            -0.018076041713356972,
            0.018276793882250786,
            0.007375357672572136,
            -0.012588687241077423,
            -0.004376980941742659,
            0.03033834509551525,
            -0.015287553891539574,
            -0.017579253762960434,
            -0.0429355762898922,
            -0.018985919654369354,
            -0.0029707965441048145,
            0.009239188395440578,
            -0.02429189346730709,
            -0.017027538269758224,
            -0.020449381321668625,
            -0.013987403362989426,
            0.022079210728406906,
            0.019958533346652985,
            -0.03945918753743172,
            -0.022564856335520744,
            0.04402977228164673,
            -0.021854033693671227,
            0.001584537560120225,
            -0.0166438240557909,
            -0.010744745843112469,
            -0.025215160101652145,
            0.002393438247963786,
            -0.026455465704202652,
            -0.014997204765677452,
            -0.01545494981110096,
            -0.01352769136428833,
            -0.01398023683577776,
            0.00736406072974205,
            0.00681351451203227,
            0.02298986352980137,
            -0.02222399413585663,
            0.026461025699973106,
            0.02358335815370083,
            -0.013319605961441994,
            -0.025669880211353302,
            -0.03324035182595253,
            -0.006719439290463924,
            -0.027308408170938492,
            -0.011986006051301956,
            0.044425323605537415,
            -0.005179749336093664,
            0.010836420580744743,
            -0.017282310873270035,
            0.021460186690092087,
            0.015568935312330723,
            0.0070246122777462006,
            -0.02884698286652565,
            -0.030361881479620934,
            -0.0005780374631285667,
            0.02790505811572075,
            -0.01833168976008892,
            -0.019384589046239853,
            -0.029079832136631012,
            0.026574179530143738,
            0.02254476770758629,
            0.002231576945632696,
            0.0036353336181491613,
            0.03147145360708237,
            0.021453961730003357,
            0.04110914096236229,
            0.016008926555514336,
            0.013696542009711266,
            -0.03392525762319565,
            -0.025432411581277847,
            -0.027761582285165787,
            0.021444205194711685,
            -0.007015475071966648,
            0.012643814086914062,
            -0.008525624871253967,
            0.027817923575639725,
            0.00012562827032525092,
            0.04096633195877075,
            0.0010476328898221254,
            0.007535654120147228,
            -0.01059430930763483,
            -0.04520720988512039,
            0.012888092547655106,
            -0.006914965808391571,
            -0.02136864885687828,
            -0.039932239800691605,
            0.012957656756043434,
            -0.007186204195022583,
            -0.0073621380142867565,
            0.01180986687541008,
            0.03635649010539055,
            -0.0005846875137649477,
            0.019927896559238434,
            0.013166908174753189,
            -0.0560564287006855,
            0.0009562041377648711,
            -0.010969290509819984,
            0.029865937307476997,
            -0.022685233503580093,
            -0.026869527995586395,
            -0.02117631956934929,
            -0.018517207354307175,
            -0.023343438282608986,
            -0.0061731659807264805,
            -0.005104241892695427,
            0.0022632754407823086,
            0.030136119574308395,
            0.006753118708729744,
            0.007254425901919603,
            -0.002524476032704115,
            0.0036492426879704,
            -0.014965401962399483,
            0.009456203319132328,
            0.020045317709445953,
            0.005662204697728157,
            -0.01616830565035343,
            0.026827191933989525,
            -0.007451602723449469,
            -0.02128072828054428,
            -0.03543764352798462,
            -0.005684106145054102,
            0.007103872951120138,
            0.037092942744493484,
            0.01780702918767929,
            0.04030405730009079,
            0.006449313834309578,
            -0.0312829464673996,
            0.008165312930941582,
            0.0050896527245640755,
            -0.00033707040711306036,
            -0.002930035348981619,
            0.017314724624156952,
            -0.043067507445812225,
            0.005761933978646994,
            -0.03301360458135605,
            0.047822486609220505,
            -0.01383837591856718,
            -0.08217425644397736,
            0.008069072850048542,
            -0.004602107219398022,
            0.01819268986582756,
            -0.0051052323542535305,
            0.03241773694753647,
            -0.0215466171503067,
            0.015217057429254055,
            0.02591526322066784,
            -0.0021655980963259935,
            -0.015768757089972496,
            -0.0052569410763680935,
            -0.013298481702804565,
            -0.004277301020920277,
            0.03655196726322174,
            -0.01030005980283022,
            0.010610664263367653,
            0.033102188259363174,
            -0.00861812848597765,
            -0.00467827869579196,
            0.03203054890036583,
            -0.01281476579606533,
            -0.02424999326467514,
            0.030753495171666145,
            -0.001994319027289748,
            0.00925387255847454,
            0.03150879219174385,
            -0.028789913281798363,
            -0.013224744237959385,
            0.01831389218568802,
            -0.006336351856589317,
            -0.023089343681931496,
            -0.00018067093333229423,
            -0.02234828658401966,
            -0.02493526227772236,
            0.009976795874536037,
            0.0092826122418046,
            -0.02245643176138401,
            -0.029682129621505737,
            -0.013696758076548576,
            0.02657928690314293,
            -0.0093454048037529,
            -0.031080828979611397,
            0.007723668124526739,
            -0.03872564062476158,
            -0.009531878866255283,
            -0.0028408460784703493,
            0.021581478416919708,
            -0.014102056622505188,
            0.009981201961636543,
            0.013299730606377125,
            0.02845791168510914,
            -0.018947739154100418,
            0.004057463724166155,
            0.008610564284026623,
            0.020146755501627922,
            0.02889912761747837,
            0.018150975927710533,
            -0.034119147807359695,
            0.00863967277109623,
            0.013274170458316803,
            0.007312517613172531,
            0.049376796931028366,
            -0.0069128647446632385,
            0.01188331376761198,
            0.03219497948884964,
            0.006976115517318249,
            0.0015690233558416367,
            -0.019205303862690926,
            0.03356001898646355,
            -0.03778268024325371,
            -0.0003437628620304167,
            0.021996688097715378,
            -0.01726387068629265,
            -0.002646438544616103,
            -0.0190102681517601,
            0.0243412833660841,
            -0.02563207410275936,
            0.025669647380709648,
            -0.07445105165243149,
            -0.03973667323589325,
            0.012723402120172977,
            0.02097162976861,
            0.029835810884833336,
            -0.016110358759760857,
            -0.013061866164207458,
            0.0009409156627953053,
            0.0014412979362532496,
            -0.046722739934921265,
            0.04647230729460716,
            0.019358467310667038,
            -0.018042054027318954,
            0.02975958026945591,
            0.01880774274468422,
            0.02920297533273697,
            0.006489083170890808,
            -0.019779162481427193,
            0.03335455432534218,
            -0.0034359872806817293,
            -0.0015988977393135428,
            0.0076385498978197575,
            -0.02468237839639187,
            0.002087363973259926,
            -0.026504984125494957,
            0.009181937202811241,
            -0.01181547436863184,
            -0.001749145332723856,
            -0.003237200668081641,
            -0.028218474239110947,
            0.018570395186543465,
            0.009971486404538155,
            -0.024380594491958618,
            0.01811979152262211,
            -0.030136941000819206,
            -0.008377949707210064,
            -0.0051908940076828,
            -0.025018004700541496,
            -0.026538055390119553,
            0.018415825441479683,
            0.03854428604245186,
            0.010315239429473877,
            -0.002971956506371498,
            0.005537285935133696,
            -0.00012154076830483973,
            -0.015410968102514744,
            0.007920505478978157,
            0.003907320089638233,
            -0.018466683104634285,
            0.022974856197834015,
            -0.030037671327590942,
            -0.0008996027754619718,
            -0.02299049310386181,
            0.01575533300638199,
            0.015073731541633606,
            -0.018422510474920273,
            0.024216962978243828,
            -0.037905607372522354,
            -0.010361434891819954,
            -0.010360132902860641,
            -0.02999761328101158,
            -0.009602896869182587,
            -0.012049451470375061,
            0.006819708738476038,
            0.025166522711515427,
            -0.009779396466910839,
            0.01068359799683094,
            -0.017037076875567436,
            -0.006674175150692463,
            -0.0010440422920510173,
            -0.0038102034013718367,
            0.03948430344462395,
            -0.0020157371181994677,
            0.02324492484331131,
            0.002780000679194927,
            0.029980668798089027,
            -0.01827971078455448,
            0.007647144142538309,
            0.013501487672328949,
            -0.036189571022987366,
            0.003638435620814562,
            -0.03177419304847717,
            0.009518816135823727,
            -0.035129155963659286,
            0.03194241598248482,
            0.007698956411331892,
            0.004476707894355059,
            -0.012052984908223152,
            0.006286876276135445,
            -0.004783750046044588,
            0.03535662591457367,
            -0.004342325497418642,
            0.00022827251814305782,
            -0.00640757754445076,
            0.019281424582004547,
            -0.020486922934651375,
            0.011340913362801075,
            0.011383582837879658,
            -0.024276424199342728,
            0.016554618254303932,
            -0.022265024483203888,
            -0.009984716773033142,
            0.026580043137073517,
            0.0006509412196464837,
            0.003721758257597685,
            -0.014416910707950592,
            0.00036642421036958694,
            -0.01952112279832363,
            0.0376138836145401,
            -0.022405069321393967,
            0.040056463330984116,
            0.03258810192346573,
            -0.018388036638498306,
            0.015348014421761036,
            0.023282691836357117,
            -0.022397765889763832,
            -0.021563464775681496,
            0.0014678891748189926,
            0.013389226980507374,
            -0.004596743267029524,
            -0.06142227724194527,
            0.016714708879590034,
            0.01643255352973938,
            0.024537313729524612,
            0.005256226751953363,
            -0.018603874370455742,
            -0.008423109538853168,
            0.03531578555703163,
            -0.002607777016237378,
            -0.03434208407998085,
            0.00564254866912961,
            -0.00103096105158329,
            -0.013723635114729404,
            0.017101909965276718,
            -0.025895817205309868,
            -0.008501254953444004,
            -0.04099064692854881
        ],
        [
            0.003904065815731883,
            0.046857960522174835,
            0.021445048972964287,
            -0.016636807471513748,
            -0.02371467463672161,
            0.0309151504188776,
            0.02376551739871502,
            0.01743369549512863,
            -0.020228736102581024,
            -0.029858436435461044,
            0.008854813873767853,
            -0.013374374248087406,
            0.02462596446275711,
            0.01730322651565075,
            0.013336332514882088,
            0.040303122252225876,
            0.016595598310232162,
            0.028389645740389824,
            -0.01564689725637436,
            0.013411486521363258,
            0.01502329483628273,
            0.013715334236621857,
            -0.032157011330127716,
            0.023146703839302063,
            0.019386135041713715,
            -0.012125452980399132,
            0.010310127399861813,
            -0.017927860841155052,
            0.013420642353594303,
            0.013127719983458519,
            -0.004487825091928244,
            0.03233366087079048,
            -0.028872979804873466,
            0.01581304520368576,
            -0.0021577035076916218,
            -0.009353823959827423,
            0.014637631364166737,
            0.010584544390439987,
            0.03725903853774071,
            -0.011783785186707973,
            0.015825597569346428,
            0.023949971422553062,
            -0.0017364575760439038,
            -0.02539549209177494,
            -0.01804202049970627,
            0.008753977715969086,
            0.028852544724941254,
            -0.022208865731954575,
            0.026205534115433693,
            0.02219957485795021,
            -0.0160689577460289,
            -0.025367598980665207,
            0.022641561925411224,
            0.014182793907821178,
            -0.020573852583765984,
            -0.018206395208835602,
            0.005031554959714413,
            -0.025903934612870216,
            -0.0021551407407969236,
            -0.004232147708535194,
            0.003888247534632683,
            -0.0223593357950449,
            -0.007341623771935701,
            0.02563261240720749,
            0.014887194149196148,
            -0.03685498610138893,
            -0.0121805090457201,
            -0.02959824539721012,
            -0.0375579334795475,
            0.0027234149165451527,
            0.030116001144051552,
            -0.012024320662021637,
            -0.01865820772945881,
            0.012809990905225277,
            0.005357569083571434,
            -0.020445607602596283,
            0.031031906604766846,
            0.031030375510454178,
            0.0519682914018631,
            0.014250246807932854,
            -0.028865180909633636,
            0.019619865342974663,
            0.02785188890993595,
            -0.011767381802201271,
            -0.0008381238440051675,
            0.0030581762548536062,
            0.020573172718286514,
            -0.0405723862349987,
            -0.021070891991257668,
            -0.002783080330118537,
            0.03153383359313011,
            -0.0013298188569024205,
            0.013741808012127876,
            -0.0009618576150387526,
            -0.003930304665118456,
            -0.02709110639989376,
            0.0006894921534694731,
            -0.020969076082110405,
            0.06303827464580536,
            0.001404738868586719,
            -0.01865827478468418,
            0.02190432697534561,
            -0.017766552045941353,
            0.0028217551298439503,
            -0.002339780330657959,
            -0.023726120591163635,
            -0.006849524565041065,
            -0.02860718034207821,
            -0.009397950023412704,
            0.026079341769218445,
            0.008318515494465828,
            -0.01764506846666336,
            0.005828376859426498,
            0.008938809856772423,
            0.0479683093726635,
            0.014669160358607769,
            0.015904134139418602,
            0.02360343560576439,
            0.009354007430374622,
            0.010990889742970467,
            0.0050280410796403885,
            -0.008206614293158054,
            -0.011944608762860298,
            -0.025608908385038376,
            0.0031136558391153812,
            -0.018236475065350533,
            -0.03791756182909012,
            -0.025419428944587708,
            0.02577626332640648,
            -0.05183219164609909,
            -0.021886566653847694,
            0.02036653272807598,
            -0.005859177093952894,
            -0.02396208606660366,
            0.03363277018070221,
            0.025296473875641823,
            -0.00034963764483109117,
            0.0020404821261763573,
            0.03174499422311783,
            4.3050971726188436e-05,
            0.005304270889610052,
            -0.013944881036877632,
            0.020791076123714447,
            -0.007022107485681772,
            -0.018662894144654274,
            0.02037700079381466,
            0.020936021581292152,
            -0.033564794808626175,
            -0.029011858627200127,
            0.007494406774640083,
            -0.001807958004064858,
            -0.0521472804248333,
            -0.034331291913986206,
            0.013951805420219898,
            -0.005082379560917616,
            0.005806607194244862,
            0.018563196063041687,
            0.01768760196864605,
            -0.00956305954605341,
            -0.0032415844034403563,
            -0.025639981031417847,
            -0.03887130320072174,
            0.0010306023759767413,
            0.001827307278290391,
            -0.013292782939970493,
            -0.051417265087366104,
            -0.07046438008546829,
            0.017843041568994522,
            -0.03746168315410614,
            -0.02803458645939827,
            0.031263940036296844,
            -0.0035277812276035547,
            -0.005288133397698402,
            0.016867242753505707,
            0.010313083417713642,
            0.009561791084706783,
            0.04248447343707085,
            -0.0075234295800328255,
            -0.017383113503456116,
            -0.005775833502411842,
            -0.031636182218790054,
            -0.004789924714714289,
            -0.01471941638737917,
            -0.01341402530670166,
            0.006224879529327154,
            0.012899638153612614,
            0.05073893070220947,
            -0.014058038592338562,
            0.016148807480931282,
            -0.00593083119019866,
            0.01749776117503643,
            -0.023777971044182777,
            0.03179539367556572,
            0.031839482486248016,
            -0.012953512370586395,
            0.021159350872039795,
            -0.03541947528719902,
            -0.01876334846019745,
            0.010907802730798721,
            -0.02481394074857235,
            0.029840778559446335,
            0.027919040992856026,
            -0.0024644224904477596,
            0.012607122771441936,
            -0.0655006393790245,
            -0.027977310121059418,
            -0.005640130955725908,
            0.0028259241953492165,
            0.01774715632200241,
            -0.014521464705467224,
            0.013084689155220985,
            -0.008814351633191109,
            -0.033379267901182175,
            0.022463681176304817,
            -0.0033838425297290087,
            0.03580419346690178,
            0.01792527735233307,
            -0.015126963146030903,
            -0.0033854306675493717,
            -0.026035653427243233,
            -0.0023059812374413013,
            -0.02469603344798088,
            0.024280991405248642,
            -0.0366501584649086,
            0.00022389236255548894,
            0.024181006476283073,
            -0.01811935380101204,
            0.033448226749897,
            -0.026151666417717934,
            0.021051734685897827,
            0.03425993770360947,
            -0.01844814606010914,
            0.01337910071015358,
            0.023001620545983315,
            -0.018205229192972183,
            -0.0037574507296085358,
            0.04257499799132347,
            0.03165634348988533,
            0.0021472314838320017,
            0.01916344463825226,
            -0.02490834705531597,
            -0.02235988900065422,
            -0.03096674010157585,
            0.007348465733230114,
            -0.02064605802297592,
            0.020471613854169846,
            0.008031063713133335,
            -0.0018999966559931636,
            0.017014440149068832,
            -0.02517615258693695,
            0.010924343019723892,
            0.007022685371339321,
            -0.039343904703855515,
            -0.018370451405644417,
            0.013317050412297249,
            0.029645679518580437,
            0.012177410535514355,
            -0.0011326093226671219,
            -0.053005073219537735,
            0.01636316068470478,
            -0.02372811920940876,
            -0.046024516224861145,
            0.00509822741150856,
            0.027575580403208733,
            0.021916057914495468,
            -0.040669530630111694,
            -0.0331663154065609,
            0.011700887233018875,
            0.026611585170030594,
            0.009719252586364746,
            0.007232703734189272,
            0.005194996017962694,
            0.02759542316198349,
            -0.009373591281473637,
            0.02201797440648079,
            -0.0004365650820545852,
            -0.01490625087171793,
            -0.007044830359518528,
            0.013327325694262981,
            0.025977961719036102,
            0.03369233012199402,
            0.008140810765326023,
            0.0228723231703043,
            -0.027081793174147606,
            0.020847178995609283,
            0.00951892975717783,
            -0.003782749641686678,
            -0.00022763496963307261,
            0.02093101106584072,
            -0.02164558880031109,
            0.0200599804520607,
            -0.005907565355300903,
            -0.019503457471728325,
            -0.016499854624271393,
            0.015400436706840992,
            -0.03123784437775612,
            0.024409379810094833,
            -0.00438289437443018,
            0.0345383882522583,
            -0.011671610176563263,
            0.0006151275592856109,
            -0.023033471778035164,
            0.016766246408224106,
            0.010285195894539356,
            -0.017630012705922127,
            -0.030098048970103264,
            -0.035310257226228714,
            -0.008175750263035297,
            0.007532256189733744,
            0.016126031056046486,
            -0.014777621254324913,
            0.014945879578590393,
            -0.008740461431443691,
            0.014230412431061268,
            -0.03590019419789314,
            -0.007940984331071377,
            -0.021728573366999626,
            -0.012499927543103695,
            0.024082252755761147,
            -0.022804928943514824,
            -0.013580208644270897,
            -0.024730876088142395,
            -0.00017677198047749698,
            0.00487586110830307,
            -0.021295955404639244,
            0.011195563711225986,
            -0.013288451358675957,
            -0.01505036186426878,
            -0.002837232779711485,
            -0.025798320770263672,
            -0.011585705913603306,
            -0.015454133041203022,
            0.011558779515326023,
            -0.008283182047307491,
            -0.010890262201428413,
            0.01662367768585682,
            0.02330845408141613,
            0.020372141152620316,
            -0.008506305515766144,
            0.038465969264507294,
            -0.011796933598816395,
            -0.05296115577220917,
            -0.032855380326509476,
            0.03029712475836277,
            -0.008688421919941902,
            -0.027934331446886063,
            0.032830916345119476,
            0.03337148576974869,
            0.005449088755995035,
            -0.03294645622372627,
            0.0028736991807818413,
            0.01994434744119644,
            0.007250349037349224,
            -0.024528849869966507,
            -0.05646951496601105,
            0.04443977028131485,
            -0.009364111348986626,
            0.026010509580373764,
            0.02616078220307827,
            0.027712278068065643,
            0.00019224714196752757,
            0.021107390522956848,
            0.018959587439894676,
            -0.02632511593401432,
            -0.041348956525325775,
            -0.007507598027586937,
            0.0021893540397286415,
            -0.03280804678797722,
            -0.0034513846039772034,
            0.02430860325694084,
            0.008372919633984566,
            0.021151991561055183,
            -0.013971150852739811,
            -0.032123856246471405,
            0.00026718431035988033,
            -0.010848065838217735,
            -0.011116372421383858,
            -0.039351414889097214,
            -0.014694266952574253,
            0.009553133510053158,
            -0.03337771072983742,
            0.02605663798749447,
            -0.01364661380648613,
            0.0028550303541123867,
            -0.01714063063263893,
            0.006186244543641806,
            -0.009256523102521896,
            0.03261503577232361,
            -0.015133969485759735,
            -0.03152626380324364,
            -0.018842849880456924,
            0.018896259367465973,
            0.012218461371958256,
            -0.051436081528663635,
            0.0005075885565020144,
            0.001877973903901875,
            0.011211415752768517,
            0.005108834709972143,
            -0.005503921769559383,
            -0.015000157989561558,
            0.013733056373894215,
            -0.02456340752542019,
            0.011532592587172985,
            -0.005716351792216301,
            0.01079581119120121,
            -0.03293774649500847,
            -0.026555052027106285,
            0.010247456841170788,
            0.01463770866394043,
            0.01108099240809679,
            -0.0007026253151707351,
            0.0007143142283894122,
            -0.03147071599960327,
            -0.037819333374500275,
            0.014006711542606354,
            0.008707084693014622,
            -0.018992502242326736,
            0.05882016569375992,
            0.01218112837523222,
            -0.01606692373752594,
            -0.0543803796172142,
            0.020705178380012512,
            0.014651058241724968,
            -0.004331057891249657,
            -0.03674453869462013,
            -0.02899905852973461,
            -0.06224588304758072,
            -0.016543686389923096,
            0.024811159819364548,
            0.03036298230290413,
            -0.007291807793080807,
            -0.01700977422297001,
            -0.000981219345703721,
            -0.024732299149036407,
            -0.007930801250040531,
            -0.01740063913166523,
            -0.03789021819829941,
            0.020809756591916084,
            -0.01935035176575184,
            -0.005183129571378231,
            -0.06181297451257706,
            -0.004164433106780052,
            -0.0015833789948374033,
            -0.025903688743710518,
            0.01787189021706581,
            0.014279688708484173,
            0.027564622461795807,
            0.07478360086679459,
            0.004612835124135017,
            0.0024120723828673363,
            0.027918582782149315,
            -0.007243850734084845,
            -0.01708320714533329,
            -0.027697622776031494,
            -0.0038369896356016397,
            -0.0350741371512413,
            -0.023620348423719406,
            0.004251381848007441,
            0.00020513938216026872,
            -0.05242890492081642,
            -0.042137231677770615,
            0.0011583372252061963,
            0.0035437571350485086,
            -0.027240943163633347,
            -0.021892540156841278,
            0.024455975741147995,
            0.03157289698719978,
            0.023368997499346733,
            -0.0014515452785417438,
            -0.029611388221383095,
            0.021446289494633675,
            0.017782313749194145,
            0.012181699275970459,
            -0.03971819207072258,
            0.0017459728987887502,
            0.02650829218327999,
            0.0036677357275038958,
            -0.029156874865293503,
            -0.01631350815296173,
            -0.016139786690473557,
            0.03324589133262634,
            -0.002854839898645878,
            0.005832409951835871,
            0.006735518109053373,
            0.02767733298242092,
            -7.437584281433374e-05,
            0.00721663236618042,
            0.007628635037690401,
            0.07820191234350204,
            0.02615926042199135,
            0.022122107446193695,
            0.023449338972568512,
            0.029826942831277847,
            -0.006493543740361929,
            -0.008432881906628609,
            -0.01985960826277733,
            0.010782516561448574,
            0.03806421160697937,
            0.03466609865427017,
            0.0027516228146851063,
            -0.006177243310958147,
            -0.013022245839238167,
            0.025420064106583595,
            0.01142360083758831,
            -0.014120383188128471,
            0.015266377478837967,
            -0.00592524278908968,
            0.02212945930659771,
            0.012074673548340797,
            0.02800636738538742,
            0.025183577090501785,
            -0.01134705450385809,
            -0.014969493262469769,
            0.02090098336338997,
            0.011457398533821106,
            0.0037913676351308823,
            -0.010820871219038963,
            -0.014179409481585026,
            0.022052882239222527,
            0.016102351248264313,
            -0.015528583899140358,
            0.02247653901576996,
            0.01025703176856041,
            -0.037807729095220566,
            0.058083511888980865,
            -0.0023288270458579063,
            -0.06878181546926498,
            0.01836547814309597,
            -0.0003928572405129671,
            0.031460437923669815,
            -0.002824443159624934,
            -0.008712075650691986,
            0.03983279690146446,
            -0.0064367614686489105,
            0.022327067330479622,
            -0.009663502685725689,
            0.010550758801400661,
            -0.016987742856144905,
            -0.007942146621644497,
            -0.0038767496589571238,
            0.001539435819722712,
            0.021870186552405357,
            -0.008202508091926575,
            -0.03509889543056488,
            -0.018499089404940605,
            -0.02080369181931019,
            -0.026114458218216896,
            0.032817643135786057,
            -0.022985652089118958,
            -0.01456101331859827,
            0.003691545221954584,
            0.011369504034519196,
            0.03599386289715767,
            -0.005451491568237543,
            -0.013518597930669785,
            -0.002181552117690444,
            -0.002357909921556711,
            0.007687380537390709,
            -0.009668555110692978,
            -0.025969918817281723,
            0.012535123154520988,
            0.009235424920916557,
            -0.01765928417444229,
            -0.01782643422484398,
            -0.022104090079665184,
            -0.007794700562953949,
            -0.030792422592639923,
            -0.01775520108640194,
            -0.004099337384104729,
            -0.03359995409846306,
            -0.006728402338922024,
            -0.020149171352386475,
            -0.013912749476730824,
            -0.003916632384061813,
            0.017242377623915672,
            -0.015556429512798786,
            0.024679133668541908,
            -0.019295640289783478,
            -0.022827742621302605,
            0.018721891567111015,
            -0.020312577486038208,
            -0.0011567756300792098,
            0.0034544370137155056,
            -0.03858993574976921,
            0.020612722262740135,
            -0.02156052365899086,
            0.01643518917262554,
            -0.00155915308278054,
            0.012308117933571339,
            -0.04132312908768654,
            -0.027078179642558098,
            0.0037559045013040304,
            0.016862349584698677,
            -0.01383332908153534,
            0.02386406622827053,
            0.0346105694770813,
            -0.022168146446347237,
            -0.004689064342528582,
            0.03950870409607887,
            -0.0830007866024971,
            -0.02138160914182663,
            -0.01739983819425106,
            -0.02580118365585804,
            -0.015215263701975346,
            -0.013911805115640163,
            -0.005406613927334547,
            -0.02588137984275818,
            0.013520014472305775,
            0.031720444560050964,
            0.017293179407715797,
            -0.004726544953882694,
            0.016879094764590263,
            0.013478226028382778,
            -0.021155979484319687,
            0.003557061543688178,
            0.004053705837577581,
            -0.008862347342073917,
            -0.0013736742548644543,
            -0.0025258134119212627,
            -0.001299374969676137,
            0.02567441202700138,
            -0.006431906018406153,
            -0.01899160072207451,
            -0.02140406146645546,
            0.031410008668899536,
            -0.00873806793242693,
            0.0266876183450222,
            -0.0104220537468791,
            0.0166142750531435,
            0.035818856209516525,
            -0.0116801168769598,
            -0.024083271622657776,
            0.016705617308616638,
            0.01866140030324459,
            -0.027758203446865082,
            0.029217133298516273,
            0.003301318036392331,
            0.03283700719475746,
            0.02666369266808033,
            -0.0035475539043545723,
            0.008802920579910278,
            0.01451242808252573,
            0.001585417427122593,
            0.007789722178131342,
            -0.02138545736670494,
            -0.018356487154960632,
            0.00926150381565094,
            0.011329827830195427,
            0.02447970025241375,
            0.00050589838065207,
            0.017473766580224037,
            -0.04046790674328804,
            0.00034119936753995717,
            -0.02767878584563732,
            0.01681601069867611,
            -0.018394356593489647,
            0.012809699401259422,
            0.00936893094331026,
            -0.0033419777173548937,
            0.009404265321791172,
            -0.05697106942534447,
            0.00851453561335802,
            -0.004827659577131271,
            -0.008033628575503826,
            -0.02189365029335022,
            -0.022125763818621635,
            0.028448952361941338,
            0.019687317311763763,
            0.014802088961005211,
            -0.016790062189102173,
            0.006763414479792118,
            -0.048367056995630264,
            0.010152673348784447,
            0.0016453403513878584,
            -0.01723618060350418,
            0.014259928837418556,
            0.02727261744439602,
            -0.021294482052326202,
            0.024885086342692375,
            -0.013843606226146221,
            0.012583291158080101,
            0.024900438264012337,
            0.03989042341709137,
            0.023707635700702667,
            0.012993475422263145,
            -0.03306199982762337,
            -0.013544907793402672,
            0.04127587378025055,
            -0.01530397403985262,
            -0.013242837972939014,
            0.014156286604702473,
            -0.014289510436356068,
            -0.009888190776109695,
            0.02330317161977291,
            0.031708382070064545,
            -0.04615115374326706,
            0.029084397479891777,
            0.003240506164729595,
            -0.026179246604442596,
            0.019652869552373886,
            -0.02569993957877159,
            0.0217853095382452,
            -0.020272502675652504,
            -0.01821090281009674,
            0.0064001986756920815,
            -0.02094191126525402,
            0.02683243341743946,
            0.031022777780890465,
            -0.024025512859225273,
            -0.03916901722550392,
            -0.017120709642767906,
            0.006600290536880493,
            -0.03418995067477226,
            0.04121483489871025,
            -0.041893284767866135,
            0.019757619127631187,
            0.03168037161231041,
            -0.016428379341959953,
            -0.007390742655843496,
            0.007517936639487743,
            0.026929093524813652,
            -0.00136128393933177,
            0.00946213398128748,
            -0.017650838941335678,
            0.0067880647256970406,
            -0.02985597588121891,
            0.03071562387049198,
            -0.01328399870544672,
            0.026662420481443405,
            0.025805694982409477,
            0.02696779929101467,
            -0.023891637101769447,
            -0.015075961127877235,
            -0.0035974783822894096,
            -0.013835152611136436,
            -0.030010001733899117,
            -0.013071765191853046,
            -0.004111513029783964,
            0.004522393457591534,
            -0.00055401970166713,
            -0.016121823340654373,
            -0.010435495525598526,
            -0.013101101852953434,
            -0.03831890597939491,
            -0.018129661679267883,
            0.001972343074157834,
            -0.03354921191930771,
            -0.019473183900117874,
            0.01662173680961132,
            0.001820432604290545,
            0.008251688443124294,
            -0.006823019590228796,
            0.003203105181455612,
            -0.023583639413118362,
            0.010168248787522316,
            0.014643594622612,
            -0.00011865152191603556,
            0.011153632774949074,
            -0.010329673066735268,
            -0.000844680005684495,
            -0.024151209741830826,
            -0.02038700133562088,
            -0.01822723262012005,
            0.0002926030720118433,
            -0.011746744625270367,
            -0.007004782557487488,
            -0.020071953535079956,
            -0.013180369511246681,
            -0.027615513652563095,
            -0.013443119823932648,
            -0.01913241110742092,
            -0.005466898437589407,
            -0.02481190487742424,
            -0.033539608120918274,
            0.0034168728161603212,
            -0.04299236088991165,
            0.0250602588057518,
            0.0225275456905365,
            0.02515185810625553,
            -0.02496945485472679,
            0.014182375743985176,
            -0.013076220639050007,
            0.026996931061148643,
            -0.0028384653851389885,
            0.0034162334632128477,
            7.680153066758066e-05,
            0.003321188036352396,
            0.02732379548251629,
            -0.013093080371618271,
            -0.02169940248131752,
            -0.016704827547073364,
            -0.034414876252412796,
            -0.02482903003692627,
            -0.002444340381771326,
            0.04460284486413002,
            -0.027943573892116547,
            0.009163657203316689,
            -0.024309586733579636,
            -0.020315924659371376,
            -0.005418246611952782,
            0.03196055069565773,
            0.006985791493207216,
            -0.006894110236316919,
            0.037720903754234314,
            0.0402214340865612,
            -0.01784105785191059,
            -0.015077789314091206,
            0.025184791535139084,
            0.019837526604533195,
            0.008010610938072205,
            0.034966014325618744,
            -0.0005479237297549844,
            0.01383531279861927,
            -0.020111490041017532,
            0.007350461557507515,
            -0.021383818238973618,
            0.011495409533381462,
            -0.002852656180039048,
            0.023036615923047066,
            0.010106795467436314,
            -0.054276276379823685,
            -0.019190078601241112,
            -0.015096637420356274,
            -0.006084384396672249,
            -0.022766441106796265,
            0.022681908681988716,
            -0.033086419105529785,
            0.02727488800883293,
            -0.006752749439328909,
            0.008355975151062012,
            0.02797931246459484,
            -0.017239024862647057,
            0.009972359985113144,
            -0.025715842843055725,
            0.017811017110943794,
            0.014620909467339516,
            0.027194630354642868,
            -0.003342111362144351,
            0.02203303575515747,
            0.003075274406000972,
            -0.008890742436051369,
            0.021062225103378296,
            0.0108603835105896,
            0.001790397334843874,
            0.03539970517158508,
            -0.010357272811233997,
            0.013947241008281708,
            0.03066515363752842,
            0.011533346027135849,
            -0.01527414284646511,
            0.02937767468392849,
            0.02938566543161869,
            -0.03446877375245094,
            0.004898195620626211,
            -0.01821805164217949,
            0.027761703357100487,
            0.02058824524283409,
            -0.031389206647872925,
            -0.009001870639622211,
            -0.01700090430676937,
            -0.003711103927344084,
            0.007128675002604723,
            0.020665161311626434,
            -0.010215806774795055,
            -0.03894039988517761,
            0.009731861762702465,
            -0.027900980785489082,
            -0.019648050889372826,
            -0.004793904721736908,
            -0.014597119763493538,
            -0.002903721062466502,
            0.02516852132976055,
            0.030963901430368423,
            0.032115764915943146,
            -0.014836221002042294,
            0.00818650797009468,
            0.028929483145475388,
            0.05089128017425537,
            0.022616179659962654,
            0.017290940508246422,
            -0.01937595009803772,
            -0.02565890923142433,
            0.02347681298851967,
            0.013273264281451702,
            0.006562354974448681,
            -0.009857461787760258,
            0.00015438272384926677,
            0.0463414303958416,
            -0.024211160838603973,
            -0.007336412090808153,
            0.0001744053151924163,
            0.020741432905197144,
            0.03695787489414215,
            -0.027531331405043602,
            0.008466377854347229,
            -0.031089020892977715,
            -0.022757884114980698,
            -0.035238973796367645,
            0.026054905727505684,
            -0.02401897683739662,
            -0.015157852321863174,
            -0.02451079525053501,
            -0.03852386027574539,
            0.026183530688285828,
            0.023447012528777122,
            0.013727998360991478,
            0.03363645076751709,
            -0.0209403857588768,
            -0.012730016373097897,
            0.02032984420657158,
            -0.04249634966254234,
            -0.024439938366413116,
            0.011508945375680923,
            -0.003465201472863555,
            0.018014930188655853,
            -0.019590547308325768,
            -0.03306596726179123,
            0.0183966513723135,
            -0.02281503938138485,
            -0.027380814775824547,
            -0.0074478513561189175,
            0.01666480489075184,
            0.00866564642637968,
            0.040422260761260986,
            0.031224999576807022,
            -0.046207331120967865,
            0.012719236314296722,
            -0.03801422938704491,
            0.027619602158665657,
            0.014344141818583012,
            -0.026313213631510735,
            0.010710650123655796,
            -0.022680768743157387,
            -0.0214853473007679,
            0.017503950744867325,
            -0.00432908209040761,
            -0.02965795062482357,
            0.026349179446697235,
            0.013505472801625729,
            0.026882436126470566,
            0.024304023012518883,
            -0.018695348873734474,
            0.032335132360458374,
            -0.030425868928432465,
            0.025325823575258255,
            -0.008425584062933922,
            -0.025796249508857727,
            0.043685123324394226,
            0.02132703922688961,
            -0.0018807868473231792,
            0.008240754716098309,
            -0.01728920452296734,
            0.026067089289426804,
            -0.020791659131646156,
            0.011482348665595055,
            -0.018254853785037994,
            0.006093548610806465,
            0.007896059192717075,
            0.04267440363764763,
            0.012894275598227978,
            -0.009523729793727398,
            0.007773428689688444,
            -0.021569853648543358,
            0.0004835178842768073,
            0.012360571883618832,
            0.01721487194299698,
            0.0094594182446599,
            0.030949516221880913,
            0.05781843513250351,
            0.023220062255859375,
            0.016484295949339867,
            -0.016866132616996765,
            -0.019141141325235367,
            -0.008800233714282513,
            0.022810781374573708,
            -0.02288762293756008,
            -0.007645744364708662,
            0.0309806726872921,
            0.012628941796720028,
            0.008915428072214127,
            0.02524884231388569,
            0.000975225877482444,
            0.032805413007736206,
            -0.010851827450096607,
            -0.021265128627419472,
            0.0009039357537403703,
            -0.028544029220938683,
            0.03818543627858162,
            -0.02519131638109684,
            -0.009108612313866615,
            0.027389224618673325,
            0.005503846798092127,
            -0.012039278633892536,
            -0.017139246687293053,
            -0.008182432502508163,
            -0.009055648930370808,
            -0.046160899102687836,
            0.036274801939725876,
            -0.00541561609134078,
            0.006182979326695204,
            -0.02346542477607727,
            0.004601019434630871,
            0.011089697480201721,
            0.028499480336904526,
            -0.05364454165101051,
            0.007938172668218613,
            -0.014475415460765362,
            -0.02699735201895237,
            0.0015327485743910074,
            -0.039114486426115036,
            0.0012814722722396255,
            0.026723794639110565,
            0.016311567276716232,
            0.013201430439949036,
            -0.04593029245734215,
            0.029600922018289566,
            -0.020095596089959145,
            0.01960071176290512,
            -0.03076355904340744,
            0.000626284338068217,
            -0.010598845779895782,
            -0.04928441345691681,
            -0.012458817102015018,
            0.01979546807706356
        ],
        [
            -0.0035413536243140697,
            0.005434038117527962,
            0.014590688981115818,
            -0.014708130620419979,
            -0.0061104572378098965,
            0.0429094173014164,
            -0.0025622709654271603,
            -0.0026856479234993458,
            -0.00396002409979701,
            0.038118910044431686,
            -0.018009180203080177,
            0.0239164587110281,
            0.02183322235941887,
            -0.005721468012779951,
            -0.03854018449783325,
            0.015654759481549263,
            -0.030229033902287483,
            -0.0035050988662987947,
            -0.0019682953134179115,
            0.011729393154382706,
            -0.004471034277230501,
            -0.003782153595238924,
            0.006325011141598225,
            0.01729288324713707,
            -0.041850075125694275,
            -0.013223854824900627,
            -0.010935265570878983,
            0.007401114795356989,
            0.016047416254878044,
            -0.028521142899990082,
            -0.018554672598838806,
            -0.001814787625335157,
            -0.00284436228685081,
            0.008973426185548306,
            -0.0014033786719664931,
            -0.04469006508588791,
            0.02036290243268013,
            -0.010189807042479515,
            0.01283324882388115,
            -0.01163680199533701,
            0.0458545982837677,
            0.0184379480779171,
            -0.024381564930081367,
            0.008031842298805714,
            0.014707357622683048,
            0.0420738123357296,
            0.02883438766002655,
            0.0022888167295604944,
            -0.004679803736507893,
            0.00041848389082588255,
            -0.011026613414287567,
            0.027540290728211403,
            -0.0008660607854835689,
            0.012528505176305771,
            -0.02561555802822113,
            -0.01524217240512371,
            0.025286005809903145,
            0.03800734132528305,
            -0.022031549364328384,
            0.02652745693922043,
            0.0064064692705869675,
            0.01841985620558262,
            0.007123144809156656,
            -0.00510808639228344,
            -0.012549648061394691,
            0.0076505886390805244,
            -0.009241094812750816,
            -0.020602766424417496,
            0.03798363730311394,
            0.009438340552151203,
            -0.02857091836631298,
            0.0006077972939237952,
            -0.00689706252887845,
            -0.0008023664122447371,
            -0.00159459188580513,
            -0.009920170530676842,
            -0.016984479501843452,
            0.03422422707080841,
            -0.040364041924476624,
            0.024713406339287758,
            0.021441908553242683,
            -0.023202529177069664,
            0.031462591141462326,
            -0.013397134840488434,
            -0.011865010485053062,
            -0.024168934673070908,
            -0.003773205913603306,
            0.0005694706342183053,
            -0.019450277090072632,
            -0.05464429780840874,
            -0.001800327212549746,
            0.0018152830889448524,
            0.014608996920287609,
            -0.03003302775323391,
            0.00363421649672091,
            -0.01176915317773819,
            0.01333276741206646,
            0.007158676162362099,
            0.07480717450380325,
            -0.01256057620048523,
            -0.02370115928351879,
            -0.00376009289175272,
            0.00030269191483967006,
            0.0049070739187300205,
            -0.025599347427487373,
            0.010884406976401806,
            0.029407687485218048,
            0.023058230057358742,
            -0.030147649347782135,
            -0.002138229552656412,
            -0.029827797785401344,
            0.02993740513920784,
            0.01446622982621193,
            0.02931019477546215,
            -0.06341154873371124,
            0.012841050513088703,
            0.021652551367878914,
            -0.032850150018930435,
            0.0172820296138525,
            -0.014919972978532314,
            0.0034428040962666273,
            0.02619038335978985,
            -0.0412905290722847,
            0.004436972085386515,
            0.004513284657150507,
            0.018924999982118607,
            -0.016053566709160805,
            -0.00501292385160923,
            -3.7547160900430754e-05,
            0.00946031603962183,
            -0.012052398175001144,
            0.006216403562575579,
            -0.03749657794833183,
            -0.025869738310575485,
            -0.01047336496412754,
            -0.005299527198076248,
            -0.012816485948860645,
            0.0015753137413412333,
            0.01722724922001362,
            0.02739955484867096,
            -0.01961044780910015,
            0.03104349598288536,
            -0.013584787026047707,
            0.011854317970573902,
            0.0365249440073967,
            -0.004403942730277777,
            0.028742065653204918,
            0.00596289336681366,
            0.005038948729634285,
            0.03989427536725998,
            -0.038614701479673386,
            0.001902019139379263,
            -0.030931223183870316,
            -0.06590305268764496,
            0.012919473461806774,
            -0.016555439680814743,
            -0.001418936881236732,
            0.004650285467505455,
            -0.004356791265308857,
            -0.0248336773365736,
            -0.0023276228457689285,
            0.008053612895309925,
            0.01411451306194067,
            -0.006093982607126236,
            0.019023332744836807,
            -0.025227488949894905,
            -0.013317187316715717,
            -0.024029888212680817,
            -0.0636906698346138,
            0.007313372567296028,
            0.026333602145314217,
            -0.014545876532793045,
            0.052174635231494904,
            -0.01880352944135666,
            0.006233320105820894,
            0.009374374523758888,
            -0.039945222437381744,
            -0.033552445471286774,
            0.002613794058561325,
            0.02087787352502346,
            0.020731940865516663,
            0.004310116171836853,
            -0.02058294042944908,
            0.022573016583919525,
            -0.01416170783340931,
            0.03293983265757561,
            0.028333110734820366,
            0.026346974074840546,
            -0.01578851416707039,
            0.022730320692062378,
            0.02664005197584629,
            0.01485922560095787,
            -0.09657671302556992,
            0.0035699293948709965,
            0.03327210992574692,
            0.011761709116399288,
            0.025070685893297195,
            0.01769559271633625,
            -0.015373134054243565,
            -0.01907547377049923,
            0.016952477395534515,
            0.000952004105783999,
            -0.01540607400238514,
            0.029921643435955048,
            0.005048984196037054,
            -0.0534525103867054,
            -0.022586660459637642,
            -0.03474903851747513,
            -0.027443191036581993,
            0.015559251420199871,
            0.021891511976718903,
            -0.01692785881459713,
            -0.017875153571367264,
            -0.03635052591562271,
            0.010042868554592133,
            -0.00834911409765482,
            -0.019402677193284035,
            0.022721312940120697,
            0.02046462893486023,
            0.015037729404866695,
            -0.007403662893921137,
            0.032701000571250916,
            -0.007597361691296101,
            -0.023581506684422493,
            0.0007412449340336025,
            0.0026691609527915716,
            0.044806379824876785,
            0.017885930836200714,
            0.04620109871029854,
            -0.012306198477745056,
            0.013523280620574951,
            0.00791202299296856,
            0.014758935198187828,
            0.03403031453490257,
            -0.01574776880443096,
            0.006572064012289047,
            -0.04235713928937912,
            -0.027796171605587006,
            0.013504328206181526,
            -0.015687203034758568,
            -0.006876473315060139,
            -0.030605435371398926,
            -0.02624676190316677,
            -0.02930643782019615,
            0.03155627101659775,
            0.015213076025247574,
            -0.032521653920412064,
            0.03210841864347458,
            -0.009449501521885395,
            0.028327763080596924,
            -0.020854471251368523,
            0.017135435715317726,
            -0.04674197733402252,
            -0.0066342283971607685,
            0.03342567756772041,
            0.020205888897180557,
            -0.02101033926010132,
            -0.0031071987468749285,
            -0.031757187098264694,
            0.025522183626890182,
            -0.022857721894979477,
            -0.006757256109267473,
            -0.061407383531332016,
            0.009035663679242134,
            -0.004462434444576502,
            -0.058629751205444336,
            -0.004721305798739195,
            0.011486522853374481,
            -0.02217111922800541,
            -0.06469288468360901,
            -0.006138680037111044,
            -0.042019277811050415,
            -0.019724594429135323,
            0.015214496292173862,
            0.021082768216729164,
            -0.008334779180586338,
            0.03768561780452728,
            -0.012287721037864685,
            0.0028888883534818888,
            -0.005144048482179642,
            -0.030823377892374992,
            0.027674956247210503,
            -0.011558114551007748,
            -0.005129613913595676,
            0.0026071013417094946,
            0.009203556925058365,
            0.015119846910238266,
            -0.006351507268846035,
            0.020807825028896332,
            0.010728836059570312,
            -0.011603745631873608,
            0.019160959869623184,
            -0.004244681913405657,
            -0.008832801133394241,
            -0.02953808195888996,
            -0.003786686807870865,
            -0.024310072883963585,
            0.023746298626065254,
            -0.032965075224637985,
            -0.025990214198827744,
            0.00818879995495081,
            0.007126498967409134,
            -0.0001629050966585055,
            -0.04314057156443596,
            -0.009044934064149857,
            -0.004110357724130154,
            0.01823589764535427,
            -0.06366521120071411,
            -0.026096045970916748,
            0.0008571194484829903,
            -0.009904748760163784,
            0.009277820587158203,
            -0.010520697571337223,
            -0.010710809379816055,
            0.028748197481036186,
            0.0015603118808940053,
            0.010766238905489445,
            0.019749676808714867,
            -0.014868529513478279,
            -0.010573023930191994,
            0.01961475796997547,
            0.03673615679144859,
            -0.009670163504779339,
            0.010842891409993172,
            0.026031944900751114,
            -0.009933809749782085,
            -0.029426462948322296,
            0.02762812003493309,
            0.0015348355518653989,
            -0.010695761069655418,
            0.01081395335495472,
            -0.0185020063072443,
            -0.004606361500918865,
            -0.01834489218890667,
            -0.005561172030866146,
            0.006759133189916611,
            0.010517331771552563,
            0.024706192314624786,
            -0.02464635856449604,
            0.03292366489768028,
            0.011996402405202389,
            0.0029329382814466953,
            -0.021438438445329666,
            0.025267908349633217,
            -0.0045816111378371716,
            -0.0007324372418224812,
            -0.01692364178597927,
            0.026468653231859207,
            0.013221670873463154,
            0.01738770119845867,
            0.01167384348809719,
            -0.010491692461073399,
            0.018954481929540634,
            -0.015634531155228615,
            -0.024773096665740013,
            0.009722822345793247,
            -0.003971414640545845,
            0.0057253148406744,
            0.02206738479435444,
            0.008188060484826565,
            -0.009093447588384151,
            0.0035503357648849487,
            0.013404707424342632,
            0.008266503922641277,
            0.015188312157988548,
            0.02840093895792961,
            -0.010999240912497044,
            0.036174505949020386,
            0.034396156668663025,
            0.02475103922188282,
            0.017041241750121117,
            -0.015298372134566307,
            -0.005153794772922993,
            0.001549169304780662,
            0.006414849776774645,
            0.009270898997783661,
            -0.014300915412604809,
            0.017344679683446884,
            0.019778527319431305,
            0.004674876574426889,
            0.04005996510386467,
            -0.016249436885118484,
            0.009107515215873718,
            0.014501973986625671,
            -0.016280923038721085,
            0.007630877196788788,
            -0.0005519152618944645,
            -0.01740989275276661,
            0.004011939745396376,
            0.03602202236652374,
            0.025362655520439148,
            0.02381793223321438,
            0.00892695039510727,
            -0.0183958038687706,
            -0.06293219327926636,
            0.005055233370512724,
            -0.019859416410326958,
            0.02862558513879776,
            0.016248157247900963,
            -0.022972650825977325,
            -0.032374635338783264,
            0.00999810453504324,
            -0.011801994405686855,
            -0.020594988018274307,
            -0.02919911965727806,
            0.0005398618523031473,
            0.004618186969310045,
            -0.011682909913361073,
            0.008237360045313835,
            0.027674105018377304,
            -0.027743447571992874,
            -0.0032168475445359945,
            -0.0002957971300929785,
            -0.04038111865520477,
            0.011118104681372643,
            -0.02243652567267418,
            0.02171003259718418,
            0.007650928106158972,
            0.005761946085840464,
            0.011164097115397453,
            0.02937409095466137,
            -0.019840598106384277,
            0.04443533718585968,
            0.00011817197082564235,
            -0.04304586723446846,
            -0.041786689311265945,
            -0.020897625014185905,
            -0.011652586050331593,
            -0.01026838831603527,
            0.008845740929245949,
            0.02240973524749279,
            0.023291515186429024,
            -0.020651644095778465,
            0.007732714060693979,
            0.01924515701830387,
            -0.01565825566649437,
            0.009732890874147415,
            0.02166939526796341,
            0.023631645366549492,
            0.0211021788418293,
            -0.04827406257390976,
            -0.029074465855956078,
            0.022525640204548836,
            0.009281085804104805,
            -0.013479582965373993,
            0.025373531505465508,
            0.03388433903455734,
            -0.07780888676643372,
            0.01230835635215044,
            0.02218802273273468,
            0.007190987467765808,
            -0.017168086022138596,
            -0.005985331255942583,
            0.013710963539779186,
            -0.024952540174126625,
            -0.0046393475495278835,
            0.0034558880142867565,
            0.017340704798698425,
            0.018119456246495247,
            -0.0010021253256127238,
            0.00560085941106081,
            0.0036889142356812954,
            -0.019611893221735954,
            -0.02734607644379139,
            0.005486411973834038,
            0.0022114671301096678,
            -0.003215556498616934,
            0.04445748031139374,
            -0.0039415922947227955,
            -0.004151694476604462,
            -0.0009915749542415142,
            -0.019083267077803612,
            -0.0063892994076013565,
            -0.027847671881318092,
            0.02092873677611351,
            -0.008504454046487808,
            0.008356648497283459,
            0.018139110878109932,
            -0.01319753285497427,
            -0.040612634271383286,
            -0.033646903932094574,
            0.015507795847952366,
            -0.0644475519657135,
            0.03351118043065071,
            0.030140995979309082,
            0.029034486040472984,
            -0.02739831618964672,
            0.01412663422524929,
            -0.116163469851017,
            0.04117925837635994,
            -0.037063732743263245,
            0.017573250457644463,
            -0.015687372535467148,
            -0.01352731604129076,
            0.034544412046670914,
            -0.022647308185696602,
            0.030019227415323257,
            -0.001991576747968793,
            -0.009936980903148651,
            0.024063143879175186,
            0.028731100261211395,
            0.0057237595319747925,
            0.018820468336343765,
            -0.009906250983476639,
            -0.01939328759908676,
            -0.0011224823538213968,
            -0.023209476843476295,
            0.012492545880377293,
            0.03643958643078804,
            -0.007745654322206974,
            0.01754234917461872,
            -0.03223508968949318,
            0.0101510901004076,
            0.009673119522631168,
            -0.004452102351933718,
            0.00915599800646305,
            0.005335830617696047,
            0.0025872651021927595,
            -0.019265733659267426,
            0.030143966898322105,
            0.020972467958927155,
            -0.006181828677654266,
            -0.006970843765884638,
            0.01217750646173954,
            -0.020460711792111397,
            -0.023357640951871872,
            -0.07843495160341263,
            -0.0030461668502539396,
            0.01689564809203148,
            0.029292017221450806,
            0.018246522173285484,
            -0.006863204296678305,
            -0.03391123563051224,
            -0.019897112622857094,
            -0.006617878098040819,
            -0.005688422359526157,
            0.01736193336546421,
            -0.0005937957903370261,
            0.021572165191173553,
            0.02602081559598446,
            -0.020584480836987495,
            -0.008007223717868328,
            -0.0050375317223370075,
            0.06140175834298134,
            0.018907608464360237,
            0.004123163875192404,
            -0.0014684888301417232,
            -0.055762484669685364,
            -0.02793276682496071,
            -0.013463057577610016,
            -0.016715817153453827,
            0.021999333053827286,
            0.0005093749496154487,
            -0.020080843940377235,
            -0.03800736367702484,
            0.020907029509544373,
            -0.006803114898502827,
            -0.008401265367865562,
            0.033077288419008255,
            -0.011295936070382595,
            0.002681910526007414,
            -0.032383255660533905,
            0.01884254440665245,
            0.007065186742693186,
            0.02942158840596676,
            -0.010882374830543995,
            -0.019375164061784744,
            -0.008984279818832874,
            0.025997070595622063,
            -0.015219590626657009,
            -0.015344192273914814,
            -0.017016766592860222,
            -0.023857709020376205,
            0.015989044681191444,
            -0.011633913964033127,
            0.016444813460111618,
            -0.0006836846005171537,
            -0.0279857125133276,
            -0.0070092882961034775,
            0.012295396067202091,
            0.03333677724003792,
            -0.009758743457496166,
            -0.025995099917054176,
            0.03903394937515259,
            0.009271642193198204,
            0.016001245006918907,
            0.021232852712273598,
            0.007582023274153471,
            -0.00349784130230546,
            0.00028205194394104183,
            0.022365782409906387,
            -0.017861999571323395,
            0.014468401670455933,
            -0.016663381829857826,
            0.00015161132614593953,
            0.027609599754214287,
            0.036199621856212616,
            0.013329901732504368,
            0.018809041008353233,
            -0.011938459239900112,
            0.013649098575115204,
            0.01011653058230877,
            0.011787899769842625,
            0.008458317257463932,
            0.01407832745462656,
            0.03372257575392723,
            -0.021512765437364578,
            -0.025111230090260506,
            -0.02134719304740429,
            -0.02077539823949337,
            0.00010306511103408411,
            -0.01497633382678032,
            0.012658325023949146,
            -0.005339777562767267,
            0.018274255096912384,
            0.005033018067479134,
            -0.03543832525610924,
            -0.013460615649819374,
            0.027471428737044334,
            -0.010998939163982868,
            0.000728956947568804,
            -0.005447863135486841,
            -0.02857312560081482,
            -0.006051763892173767,
            0.021361004561185837,
            -0.016653170809149742,
            0.019268034026026726,
            -0.027675185352563858,
            -0.024616634473204613,
            0.024511659517884254,
            -0.030220946297049522,
            0.02907441556453705,
            -0.015930334106087685,
            -0.009667622856795788,
            -0.047846801578998566,
            -0.04030932858586311,
            -0.016913708299398422,
            0.02817865088582039,
            0.03704458102583885,
            0.02857092395424843,
            0.006875222083181143,
            -0.0015226479154080153,
            -0.03173138201236725,
            0.029574701562523842,
            0.004711805377155542,
            0.021505847573280334,
            0.015663305297493935,
            -0.02043205127120018,
            2.8253882192075253e-05,
            0.02673877403140068,
            -0.01220119558274746,
            -0.008443200960755348,
            -0.010695093311369419,
            -0.0020489839371293783,
            -0.001528547378256917,
            0.02561398409307003,
            5.787556438008323e-05,
            0.029688429087400436,
            0.02682650461792946,
            -0.003174212761223316,
            -0.02766449935734272,
            0.032513704150915146,
            -0.019938835874199867,
            -0.03538426011800766,
            -0.027716223150491714,
            0.02361069619655609,
            -0.028635794296860695,
            0.0007672702195122838,
            0.02638561837375164,
            -0.03373042866587639,
            0.024048613384366035,
            -0.016846301034092903,
            -0.005549893714487553,
            -0.0015616436721757054,
            -0.02263915166258812,
            -0.03523428365588188,
            0.015404651872813702,
            -0.016511304304003716,
            -0.014244461432099342,
            -0.0203352440148592,
            0.0013827335787937045,
            -0.020285677164793015,
            -0.0008857587235979736,
            0.029703078791499138,
            0.030531328171491623,
            -0.017014216631650925,
            -0.03197629377245903,
            0.0035245695617049932,
            0.008375965990126133,
            -0.03137290105223656,
            0.02545672096312046,
            -0.006470147520303726,
            -0.003419222543016076,
            -0.023074224591255188,
            -0.019400866702198982,
            -0.03840383514761925,
            0.03551994264125824,
            -0.008191091008484364,
            0.003911789506673813,
            0.006197261158376932,
            0.007353950757533312,
            -0.02426106110215187,
            0.012212707661092281,
            -0.0025955112650990486,
            -0.01109323836863041,
            -0.010423161089420319,
            -0.01747715473175049,
            0.008056697435677052,
            -0.009743308648467064,
            -0.008358565159142017,
            0.0029793560970574617,
            -0.024168888106942177,
            -0.007124030962586403,
            -0.0050918348133563995,
            -0.033576641231775284,
            0.03378620371222496,
            0.01031741127371788,
            0.005644795950502157,
            -0.029131053015589714,
            -0.02098114602267742,
            -0.006178154144436121,
            0.033663488924503326,
            -0.028754329308867455,
            0.020405828952789307,
            -0.009190312586724758,
            -0.010755990631878376,
            0.026513462886214256,
            0.008547907695174217,
            0.015850789844989777,
            0.016068555414676666,
            0.027756469324231148,
            0.023609070107340813,
            -0.019734246656298637,
            -0.022534124553203583,
            0.03356439247727394,
            0.024491284042596817,
            0.02754881978034973,
            0.027939947322010994,
            0.028564654290676117,
            0.013494277372956276,
            -0.015891948714852333,
            -0.04496578499674797,
            -0.03735778480768204,
            -0.028989285230636597,
            0.010850911028683186,
            0.004371729679405689,
            -0.07634041458368301,
            -0.008947908878326416,
            0.0208053607493639,
            0.03273295238614082,
            -0.005380628630518913,
            0.019930100068449974,
            0.0028030446264892817,
            -0.047317154705524445,
            0.01857992820441723,
            0.00014024318079464138,
            -0.006280546076595783,
            -0.0016169798327609897,
            -0.06823301315307617,
            0.027541426941752434,
            -0.05155064910650253,
            0.00939305778592825,
            0.0036312146112322807,
            0.03484978899359703,
            -0.03084935061633587,
            -0.023160945624113083,
            -0.027235746383666992,
            0.010674470104277134,
            0.0021629654802381992,
            -0.009389863349497318,
            0.027760744094848633,
            0.02104303427040577,
            0.010447309352457523,
            -0.016949981451034546,
            0.028792457655072212,
            0.010366177186369896,
            -0.021559208631515503,
            -0.04191974923014641,
            0.022647757083177567,
            0.006831949111074209,
            -0.01309328991919756,
            0.012237531132996082,
            0.0020940385293215513,
            0.015230548568069935,
            0.003573743160814047,
            -0.007479931227862835,
            0.0012686271220445633,
            0.0011088278843089938,
            0.027750104665756226,
            -0.019433584064245224,
            0.005481686908751726,
            0.02122320793569088,
            0.04273771867156029,
            0.019074268639087677,
            0.03585153445601463,
            0.012426696717739105,
            0.009068114683032036,
            0.03400558978319168,
            -0.020236747339367867,
            -0.05082286521792412,
            -0.0358828566968441,
            -0.02795928157866001,
            0.02556472085416317,
            -0.0007933186716400087,
            -0.06957770138978958,
            0.007399279624223709,
            -0.02145390398800373,
            -0.021960647776722908,
            0.020573483780026436,
            0.022119909524917603,
            0.007924516685307026,
            -0.02203637920320034,
            0.008271134458482265,
            -0.03052337095141411,
            0.019762804731726646,
            -0.018129989504814148,
            -0.026069967076182365,
            0.023155279457569122,
            -0.025044787675142288,
            0.013130259700119495,
            -0.032235294580459595,
            0.010826396755874157,
            -0.027130404487252235,
            -0.00015764692216180265,
            0.00407080864533782,
            0.023314330726861954,
            0.02063256874680519,
            0.03271001577377319,
            0.03321768715977669,
            -0.023340310901403427,
            -0.013080425560474396,
            0.008355973288416862,
            -0.04279215633869171,
            0.015270856209099293,
            -0.04437940940260887,
            -0.00524823646992445,
            0.006884070113301277,
            0.037938497960567474,
            -0.011426860466599464,
            -0.006590228993445635,
            0.0065195136703550816,
            -0.022652242332696915,
            0.0230865478515625,
            0.052127547562122345,
            0.014614514075219631,
            -0.0038044480606913567,
            0.010216961614787579,
            -0.015234787948429585,
            -0.003205773653462529,
            0.009959868155419827,
            -0.014129693619906902,
            -0.001423183362931013,
            -0.01793067529797554,
            -0.014601326547563076,
            0.04141959547996521,
            -0.03835996240377426,
            0.018647007644176483,
            0.012159698642790318,
            -0.04075094684958458,
            0.021938931196928024,
            0.005389995872974396,
            0.007438595872372389,
            0.010912803001701832,
            0.025825051590800285,
            0.01971525326371193,
            0.013656425289809704,
            0.022293610498309135,
            0.027470454573631287,
            -0.023929819464683533,
            -0.0038832388818264008,
            0.014996415935456753,
            0.014034833759069443,
            0.016969630494713783,
            0.03449074551463127,
            0.009864775463938713,
            0.011074475944042206,
            0.010141625069081783,
            -0.04567970708012581,
            0.027683435007929802,
            -0.02660471946001053,
            -0.022145668044686317,
            -0.02214345522224903,
            -0.06872410327196121,
            -0.013935120776295662,
            0.007297765463590622,
            -0.007263436913490295,
            0.009999999776482582,
            0.06282540410757065,
            -0.006895320490002632,
            0.012491002678871155,
            0.015652306377887726,
            0.002583164721727371,
            0.008011437952518463,
            -0.034065939486026764,
            0.013441174291074276,
            0.03320757672190666,
            -0.018208999186754227,
            0.03386039659380913,
            -0.023223605006933212,
            -0.019965078681707382,
            0.023622583597898483,
            0.036328207701444626,
            -0.028375130146741867,
            -0.01952517218887806,
            -0.02183382399380207,
            -0.01602000743150711,
            -0.02374439872801304,
            -0.014983019791543484,
            0.023776020854711533,
            -0.03369339928030968,
            0.03675234317779541,
            -0.03628243878483772,
            0.0024914683308452368,
            -0.02099996991455555,
            0.027579033747315407,
            -0.014570966362953186,
            -0.03641717508435249,
            -0.021842006593942642,
            -0.010323050431907177,
            0.006028586532920599,
            -0.02046019770205021,
            0.03258902207016945,
            0.023603638634085655,
            -0.02546638436615467,
            -0.01072905957698822,
            0.004924628883600235,
            -0.015060554258525372,
            0.037240445613861084,
            0.034795958548784256,
            -0.049981653690338135,
            0.008731439709663391,
            -0.006721776444464922,
            -0.02879701927304268,
            -0.013518547639250755,
            0.016124431043863297,
            0.0022340549621731043,
            -0.022682294249534607,
            0.0038527476135641336,
            -0.020002050325274467,
            0.012032520957291126,
            0.020813575014472008,
            0.03964212164282799,
            -0.07038351148366928,
            0.022177739068865776,
            -0.06908739358186722,
            -0.026495233178138733,
            0.014390746131539345,
            -0.014513100497424603,
            0.04753657057881355,
            0.0035546745639294386,
            -0.04809504747390747,
            -0.022079117596149445,
            0.003527054563164711,
            -0.007845311425626278,
            -0.0034595108591020107,
            -0.017336489632725716,
            0.016368350014090538,
            -0.010623965412378311,
            0.023436447605490685,
            -0.03908664360642433,
            0.023121627047657967,
            0.012570067308843136,
            -0.06322269141674042,
            0.03920386731624603,
            0.013746517710387707,
            0.011543548665940762,
            -0.008002321235835552,
            0.012540008872747421,
            0.04407534375786781,
            0.00952109508216381,
            -0.01873141899704933,
            0.03091747872531414,
            0.008658746257424355,
            -0.005249214358627796,
            0.006823836360126734,
            0.024395408108830452,
            -0.06000509113073349,
            0.0005615431000478566,
            0.017530564218759537,
            -0.013244963251054287,
            -0.05206059291958809,
            0.012032180093228817,
            0.0036622057668864727,
            -0.005196735262870789,
            0.03174209222197533,
            -0.016982901841402054,
            0.02573322504758835,
            0.004503217060118914,
            -0.013261556625366211,
            -0.005861472338438034,
            0.01923673413693905,
            -0.012697946280241013,
            -0.004787023179233074,
            0.0010195438517257571,
            -0.012968582101166248,
            -0.062350500375032425,
            -0.02885332703590393,
            0.008499348536133766,
            0.05165201425552368,
            0.01753908209502697,
            0.0026444224640727043,
            -0.00055549485841766,
            -0.0036433131899684668,
            -0.0018269986612722278,
            -0.01797218807041645,
            0.017740964889526367,
            -0.02061421051621437,
            0.004043326713144779,
            -0.03344087302684784,
            -0.024335600435733795,
            0.024723749607801437,
            0.005777381360530853,
            -0.021294135600328445,
            0.026695558801293373,
            -0.001592220738530159,
            0.008720612153410912,
            -0.009264189749956131,
            -0.022266242653131485,
            -0.01111786812543869,
            -0.007683489006012678,
            0.016430290415883064,
            -0.03219056874513626,
            -0.0012192402500659227,
            0.023252803832292557
        ],
        [
            -0.051108215004205704,
            0.00998320709913969,
            0.0057871597819030285,
            0.03439030423760414,
            0.026920892298221588,
            -0.014027636498212814,
            0.03926509618759155,
            0.030737994238734245,
            0.015443200245499611,
            0.006808451842516661,
            -0.02785024791955948,
            0.01670023426413536,
            0.03381088748574257,
            -0.011121585965156555,
            -0.027563828974962234,
            -0.03067559376358986,
            -0.011198874562978745,
            -0.010539963841438293,
            0.020126422867178917,
            -0.034552644938230515,
            -0.004341793246567249,
            -0.0013336085248738527,
            -0.026954734697937965,
            0.03171299770474434,
            0.012461407110095024,
            -0.019489318132400513,
            0.02419295348227024,
            0.01097697764635086,
            0.006971593946218491,
            -0.027303103357553482,
            0.02919488027691841,
            -0.019736219197511673,
            -0.00645153783261776,
            -0.010086718946695328,
            -0.006358926650136709,
            0.004779231268912554,
            -0.01594129577279091,
            0.014131687581539154,
            -0.01153115089982748,
            -0.016298769041895866,
            -0.033396024256944656,
            0.04583319276571274,
            -0.026980416849255562,
            0.021416783332824707,
            -0.026905925944447517,
            0.018411507830023766,
            0.01622304506599903,
            -0.0171180572360754,
            -0.010609009303152561,
            0.003754682606086135,
            0.00042597251012921333,
            0.017291372641921043,
            -0.005025885999202728,
            -0.05159424990415573,
            0.0005722427740693092,
            0.029597362503409386,
            0.026648005470633507,
            -0.005878946278244257,
            0.029421616345643997,
            0.005905834026634693,
            -0.018602894619107246,
            0.036124736070632935,
            -0.0008216931601054966,
            0.019614625722169876,
            -0.005795548669993877,
            0.029962027445435524,
            -0.06503846496343613,
            0.00839037261903286,
            0.0030469612684100866,
            0.017534371465444565,
            -0.014986298978328705,
            -0.0470629446208477,
            0.004226713441312313,
            0.008659735321998596,
            -0.01570245623588562,
            0.0003156291786581278,
            0.028948282822966576,
            0.02759176678955555,
            -0.025229917839169502,
            -0.028261804953217506,
            0.014583834446966648,
            -0.004547031130641699,
            -0.01535553578287363,
            0.008063732646405697,
            0.011646180413663387,
            -0.014569881372153759,
            0.005630093161016703,
            0.024391986429691315,
            -0.00470159063115716,
            -0.0014993022195994854,
            0.002316375495865941,
            0.015214676968753338,
            -0.00529729388654232,
            0.030572066083550453,
            -0.018806450068950653,
            0.01793982833623886,
            0.014200211502611637,
            0.030603213235735893,
            -0.045897118747234344,
            -0.015795940533280373,
            0.012631863355636597,
            0.04097818210721016,
            0.0003119570901617408,
            -0.014449845999479294,
            0.01421723049134016,
            0.004963350482285023,
            0.004458477254956961,
            -0.003633202752098441,
            -0.05715204402804375,
            0.021984713152050972,
            -0.01622612029314041,
            -0.04924774542450905,
            0.017422204837203026,
            -0.0017779947957023978,
            -0.07664048671722412,
            -0.03437603637576103,
            0.02027571201324463,
            0.032411955296993256,
            0.027113204821944237,
            -0.000252325291512534,
            0.02434096299111843,
            0.021610887721180916,
            0.00456244545057416,
            0.0009523456101305783,
            0.0021142931655049324,
            0.013919050805270672,
            0.01396427396684885,
            0.0010243038414046168,
            0.007449109572917223,
            -0.024620147421956062,
            -0.016147185117006302,
            -0.037835460156202316,
            -0.055981747806072235,
            -0.01029791496694088,
            -0.044933877885341644,
            0.029664888978004456,
            0.015737434849143028,
            -0.002375386655330658,
            -0.03990165889263153,
            -0.01272546872496605,
            -0.011998568661510944,
            0.003344574011862278,
            0.019998319447040558,
            0.028351441025733948,
            -0.0034678729716688395,
            -0.01000900100916624,
            -0.009985924698412418,
            0.01735180802643299,
            -0.009198341518640518,
            -0.02497212588787079,
            -0.04238494858145714,
            0.0057897912338376045,
            0.029300754889845848,
            -0.10421185195446014,
            -0.007256375625729561,
            0.014504496939480305,
            0.014795397408306599,
            -0.011279135942459106,
            -0.027545854449272156,
            0.026734868064522743,
            0.03559666872024536,
            0.014699411578476429,
            -0.053981713950634,
            -0.028401264920830727,
            -0.02416135184466839,
            -0.01731160655617714,
            -0.029783137142658234,
            -0.0036912362556904554,
            0.03596983104944229,
            -0.0021079371217638254,
            -0.03391353040933609,
            -0.010409723967313766,
            0.01822705753147602,
            -0.036815986037254333,
            0.009441383183002472,
            0.008929208852350712,
            0.0075112609192729,
            -0.001393417827785015,
            -0.02006918005645275,
            0.036392468959093094,
            0.007892063818871975,
            0.04325735941529274,
            -0.030827831476926804,
            -0.011172790080308914,
            -0.006680354475975037,
            0.010461404919624329,
            -0.013186469674110413,
            -0.053441599011421204,
            0.011097242124378681,
            0.005978360306471586,
            0.001219552243128419,
            -0.009883305057883263,
            0.030442604795098305,
            -0.004946285858750343,
            0.01409932505339384,
            0.025036999955773354,
            -0.019244277849793434,
            0.005600181873887777,
            -0.019796155393123627,
            -0.0360095389187336,
            0.023733653128147125,
            -0.007093770895153284,
            -0.038980767130851746,
            -0.02092604711651802,
            0.040279172360897064,
            -0.010465387254953384,
            0.02487989515066147,
            0.035871896892786026,
            0.00431705079972744,
            0.03484225645661354,
            0.014100966043770313,
            -0.026515556499361992,
            0.0023799005430191755,
            0.03647008538246155,
            0.012764619663357735,
            -0.009626886807382107,
            0.01599918305873871,
            -0.03140753135085106,
            0.013500367291271687,
            -0.032654810696840286,
            0.002428697422146797,
            -0.008139933459460735,
            -0.0015554254641756415,
            -0.026989715173840523,
            0.01891230046749115,
            0.042855605483055115,
            -0.006060891319066286,
            0.0030042165890336037,
            0.0428866408765316,
            -0.002431229455396533,
            0.013068575412034988,
            0.04105266556143761,
            -0.040456295013427734,
            -0.013512363657355309,
            0.024393461644649506,
            -0.0327955037355423,
            -0.00592465978115797,
            -0.013033779338002205,
            0.013425852172076702,
            0.004566587042063475,
            -0.051169343292713165,
            -0.0025403061881661415,
            -0.011334515176713467,
            0.02134539932012558,
            0.04296062886714935,
            0.010987241752445698,
            -0.025204917415976524,
            0.004341983702033758,
            0.020776260644197464,
            -0.034013744443655014,
            -0.029036371037364006,
            0.006057437974959612,
            -0.03464566171169281,
            0.012608028016984463,
            -0.022408006712794304,
            0.03826259821653366,
            -0.011813994497060776,
            -0.014124782755970955,
            0.013667980208992958,
            0.006416104268282652,
            -0.01723809354007244,
            0.0035380760673433542,
            0.002255959901958704,
            -0.016322877258062363,
            0.010465073399245739,
            -0.030161112546920776,
            -0.003195139579474926,
            -0.003782873507589102,
            -0.008732500486075878,
            0.026927120983600616,
            0.03788188844919205,
            0.000706880702637136,
            0.0034545138478279114,
            0.011246936395764351,
            0.03479985147714615,
            0.01932714879512787,
            0.026564842090010643,
            0.02325594611465931,
            -0.01205939706414938,
            0.03613585978746414,
            0.003955379128456116,
            0.0010166605934500694,
            0.02085871249437332,
            -0.06731262058019638,
            -0.044458482414484024,
            0.03870724141597748,
            -0.003542426275089383,
            -0.01349025871604681,
            0.028421636670827866,
            0.008058511652052402,
            -0.00040641010855324566,
            -0.019022176042199135,
            -0.007344589103013277,
            0.0033720764331519604,
            0.03018772415816784,
            -0.016719307750463486,
            -0.010842695832252502,
            -0.02386598102748394,
            0.0029666051268577576,
            0.00747655238956213,
            -0.009522040374577045,
            0.03794511407613754,
            0.016175568103790283,
            0.040427543222904205,
            -0.0013714140513911843,
            -0.018937325105071068,
            -0.009528830647468567,
            0.0012862625299021602,
            0.0379008874297142,
            -0.035025693476200104,
            0.016375096514821053,
            0.023606717586517334,
            0.00958320964127779,
            -0.014017526991665363,
            0.030950479209423065,
            -0.08701039105653763,
            0.02036069892346859,
            -0.021295059472322464,
            0.018670842051506042,
            0.033178362995386124,
            -0.011321020312607288,
            0.0279481690376997,
            0.03282719478011131,
            0.02272581309080124,
            -0.03306322544813156,
            5.5253000027732924e-05,
            -0.029647234827280045,
            -0.028397267684340477,
            -0.013917073607444763,
            0.026021454483270645,
            -0.018751488998532295,
            0.00932252500206232,
            0.005223909392952919,
            -0.005802996456623077,
            0.007375266868621111,
            0.016497215256094933,
            -0.013250384479761124,
            0.00951636116951704,
            -0.03387530893087387,
            0.00010246568854199722,
            0.03302565589547157,
            -0.002148556988686323,
            0.012275150045752525,
            -0.039662688970565796,
            -0.011933967471122742,
            -0.03033977374434471,
            -0.0094179417937994,
            -0.0036963766906410456,
            -0.04221053794026375,
            0.04882818087935448,
            -0.04136639088392258,
            -0.036362893879413605,
            0.025759536772966385,
            0.007113616447895765,
            0.030155571177601814,
            -0.0014734795549884439,
            0.0015799198299646378,
            -0.0012489584041759372,
            -0.03113693743944168,
            -0.018880875781178474,
            -0.01731082797050476,
            -0.030515678226947784,
            -0.018347011879086494,
            0.005480400752276182,
            0.007572659291327,
            -0.030386928468942642,
            -0.003595929592847824,
            -0.010972684249281883,
            0.028299376368522644,
            0.0031312003266066313,
            -0.005480694118887186,
            0.00856738816946745,
            0.0187848974019289,
            0.0003421240544412285,
            0.03183680400252342,
            0.018328242003917694,
            -0.022666800767183304,
            -0.01659209281206131,
            0.0020017107017338276,
            0.012848913669586182,
            0.023717740550637245,
            -0.017629770562052727,
            -0.0027405826840549707,
            0.016042985022068024,
            -0.03849039226770401,
            0.018271571025252342,
            0.024257203564047813,
            0.01118752732872963,
            -0.020207002758979797,
            0.02266988717019558,
            0.03549707308411598,
            0.01116220187395811,
            0.022837165743112564,
            0.018238825723528862,
            -0.029830656945705414,
            0.012128707021474838,
            -0.025933895260095596,
            0.017380181699991226,
            -0.018341178074479103,
            -0.05449269339442253,
            -0.010411827825009823,
            -0.008883542381227016,
            -0.010446441359817982,
            0.016876738518476486,
            0.0023572887293994427,
            0.027732055634260178,
            0.04459691047668457,
            -0.026172367855906487,
            -0.007354275789111853,
            -0.028091050684452057,
            0.016738876700401306,
            -0.010577436536550522,
            -0.002510824939236045,
            0.05021696165204048,
            0.013692005537450314,
            0.01841704733669758,
            0.021506527438759804,
            0.011424086056649685,
            -0.05444193631410599,
            -0.03550293669104576,
            0.005127022974193096,
            -0.0013318279525265098,
            -0.054966915398836136,
            -0.018785987049341202,
            -0.007780878338962793,
            -0.031479716300964355,
            0.01803920976817608,
            -0.03041374310851097,
            0.005125974304974079,
            0.01124655269086361,
            0.01831827126443386,
            0.007488990668207407,
            -0.05708726868033409,
            -0.0028649787418544292,
            0.01343243196606636,
            0.050917986780405045,
            0.030039753764867783,
            0.034188106656074524,
            0.04457756504416466,
            -0.010603477247059345,
            -0.008653728291392326,
            -0.03150370717048645,
            0.021626459434628487,
            0.0025997499469667673,
            0.027540357783436775,
            -0.048668041825294495,
            -0.026867907494306564,
            0.008059975691139698,
            -0.020939994603395462,
            -0.00041733734542503953,
            -0.02455264888703823,
            -0.034679051488637924,
            0.035513389855623245,
            -0.014394234865903854,
            0.04879271984100342,
            0.015691936016082764,
            -0.03158881515264511,
            0.0041281008161604404,
            -0.0021729194559156895,
            -0.00183774100150913,
            0.026459526270627975,
            -0.020820068195462227,
            0.0013452679850161076,
            -0.003144816029816866,
            0.0004977221833541989,
            0.027032919228076935,
            -0.020401112735271454,
            0.031111368909478188,
            -0.03716536611318588,
            0.010926335118710995,
            -0.026312826201319695,
            -0.03909651190042496,
            0.007671344559639692,
            0.005919340997934341,
            0.03702301159501076,
            -0.008349145762622356,
            0.02635050378739834,
            0.0013694100780412555,
            0.024544449523091316,
            0.026962343603372574,
            -0.008893468417227268,
            -0.018546652048826218,
            -0.01795770414173603,
            -0.028976259753108025,
            -0.028077319264411926,
            -0.010870350524783134,
            -0.017718864604830742,
            -0.00231573055498302,
            -0.0491669699549675,
            -0.017586778849363327,
            0.04382292926311493,
            0.009947532787919044,
            0.022132508456707,
            -0.021155821159482002,
            0.03506845235824585,
            -0.03619949147105217,
            -0.04386091232299805,
            0.005094281863421202,
            -0.025446612387895584,
            -0.04616467282176018,
            0.0379355326294899,
            0.01437456626445055,
            0.003152209799736738,
            0.021198783069849014,
            -0.006642068270593882,
            -0.028191080316901207,
            0.010434643365442753,
            -0.008339585736393929,
            -0.003124730661511421,
            -0.029443498700857162,
            -0.027076076716184616,
            0.041840728372335434,
            0.004207256715744734,
            0.02842191606760025,
            0.007717308588325977,
            -0.01158708892762661,
            -0.00032960413955152035,
            0.0142643041908741,
            -0.00543892802670598,
            0.03411247953772545,
            0.0029562595300376415,
            -0.02576657570898533,
            -0.0028548594564199448,
            -0.01625254936516285,
            -0.011985947377979755,
            0.023794494569301605,
            0.005926227662712336,
            0.022447723895311356,
            -0.02237556502223015,
            0.017859984189271927,
            -0.020535467192530632,
            0.001467098481953144,
            0.009990093298256397,
            0.01046193391084671,
            -0.021696720272302628,
            0.05484475940465927,
            -0.012947389855980873,
            0.017583254724740982,
            0.024339430034160614,
            -0.014017214998602867,
            -0.006381509359925985,
            0.010642169043421745,
            0.02023746632039547,
            0.006484036333858967,
            -0.009904183447360992,
            0.06026230379939079,
            -0.016567952930927277,
            -0.0034317937679588795,
            0.009319432079792023,
            -0.0418056845664978,
            0.01373739168047905,
            0.01731215976178646,
            0.041050516068935394,
            0.010129616595804691,
            -0.0004492067382670939,
            -0.0014255630085244775,
            0.028903024271130562,
            0.03545672073960304,
            -0.03425081819295883,
            -0.033420365303754807,
            0.003995065111666918,
            -0.0042833625338971615,
            -0.02851109765470028,
            -0.011057398281991482,
            0.011827019043266773,
            0.0007419278845191002,
            0.015590749680995941,
            -0.02257527969777584,
            0.03122885711491108,
            -0.021411219611763954,
            -0.02465088851749897,
            0.038665641099214554,
            0.014910507015883923,
            0.010946215130388737,
            -0.015852639451622963,
            0.01943163573741913,
            0.01439306978136301,
            -0.0003610880521591753,
            0.026931827887892723,
            0.027804166078567505,
            0.004662664607167244,
            0.013880454003810883,
            -0.06351795047521591,
            0.004175999201834202,
            0.006239153910428286,
            -0.026370903477072716,
            -0.0011283749481663108,
            -0.006557176820933819,
            -0.00815857294946909,
            0.004587201401591301,
            -0.04328550398349762,
            0.003475656034424901,
            -0.018156901001930237,
            -0.017491478472948074,
            0.018148427829146385,
            0.02475518174469471,
            0.057221755385398865,
            -0.01205549854785204,
            0.027521543204784393,
            0.0026744178030639887,
            0.06626517325639725,
            0.0015173421707004309,
            0.0490800216794014,
            -0.027871031314134598,
            0.029287997633218765,
            -0.016771096736192703,
            -0.004681841470301151,
            -0.013734464533627033,
            -0.05425412580370903,
            -0.07668057829141617,
            0.004480291623622179,
            -0.017945008352398872,
            0.007874625734984875,
            -0.027843298390507698,
            -0.003221169812604785,
            -0.012649833224713802,
            -0.00454835407435894,
            0.04619982838630676,
            -0.004768362268805504,
            -0.014595680870115757,
            0.00791645236313343,
            0.013743655756115913,
            0.023397110402584076,
            -0.028822118416428566,
            0.05077721178531647,
            0.013380713760852814,
            -0.006107240449637175,
            0.007394108921289444,
            -0.07194995880126953,
            0.022075217217206955,
            0.0032411832362413406,
            0.025752460584044456,
            -0.024051696062088013,
            -0.021997474133968353,
            0.04615803807973862,
            -0.009564762003719807,
            0.03486644849181175,
            0.030759217217564583,
            -0.008374547585844994,
            0.009801624342799187,
            0.006901897490024567,
            -0.008969586342573166,
            -0.04142219200730324,
            -0.060720294713974,
            -0.02729121223092079,
            -0.009752311743795872,
            -0.014575754292309284,
            -0.015186571516096592,
            -0.02330460585653782,
            0.006908724550157785,
            0.032051458954811096,
            0.029266614466905594,
            -0.01190260425209999,
            0.03654886409640312,
            0.03156888112425804,
            0.006743676029145718,
            0.04601798206567764,
            0.025518203154206276,
            0.02774643525481224,
            -0.0642261654138565,
            0.027335520833730698,
            0.0075119854882359505,
            -0.040387775748968124,
            -0.04586547613143921,
            -0.00023831379076000303,
            -0.025604568421840668,
            0.0346793532371521,
            0.004177342168986797,
            -0.006161319091916084,
            -0.022989198565483093,
            0.0034514456056058407,
            0.010758573189377785,
            0.00012479025463107973,
            -0.03138547018170357,
            0.020133309066295624,
            0.02568952739238739,
            0.028605928644537926,
            -0.005374894477427006,
            0.036712855100631714,
            0.006739799398928881,
            0.05739138275384903,
            0.007059931289404631,
            -0.00926810409873724,
            -0.017821097746491432,
            0.03927139565348625,
            0.016269465908408165,
            0.011645084246993065,
            0.02182922326028347,
            -0.05165053904056549,
            0.023417960852384567,
            -0.05887611582875252,
            0.04133421555161476,
            0.0044344644993543625,
            -0.017758460715413094,
            0.001957399770617485,
            0.02592814341187477,
            0.0036058169789612293,
            -0.014942784793674946,
            -0.020512502640485764,
            0.003542952239513397,
            -0.004423146136105061,
            0.012057473883032799,
            -0.01532664056867361,
            -0.0005436089122667909,
            -0.029082294553518295,
            0.0003117486194241792,
            -0.026411080732941628,
            0.012461933307349682,
            -0.0019284682348370552,
            0.02243923954665661,
            0.002718175295740366,
            0.040209751576185226,
            0.009355503134429455,
            0.02769431844353676,
            0.03975459188222885,
            0.027116775512695312,
            -0.02874458208680153,
            -0.00474470853805542,
            0.012868069112300873,
            -0.029904551804065704,
            0.001860651420429349,
            -0.018433136865496635,
            0.010749461129307747,
            -0.06861238181591034,
            -0.005690642166882753,
            0.012011529877781868,
            0.015692954882979393,
            -0.025896020233631134,
            -0.020422033965587616,
            0.034594107419252396,
            0.030086973682045937,
            -0.003977908752858639,
            -0.047889839857816696,
            3.781977284234017e-05,
            -0.017244670540094376,
            -0.010697886347770691,
            0.0059706163592636585,
            0.03519972041249275,
            0.05031198263168335,
            -0.0024686225224286318,
            0.026097141206264496,
            -0.028153620660305023,
            -0.02254537306725979,
            -0.0038295311387628317,
            0.02139950916171074,
            -0.008053637109696865,
            -0.048316389322280884,
            -0.006618572864681482,
            -0.007752637378871441,
            -0.01956827938556671,
            -0.03133367374539375,
            -0.026319561526179314,
            -0.011026027612388134,
            -0.012013225816190243,
            -0.027568168938159943,
            0.03609228506684303,
            0.008611084893345833,
            -0.04825105518102646,
            0.010540164075791836,
            0.026209756731987,
            -0.02000109851360321,
            0.0167531855404377,
            -0.025430496782064438,
            0.016500091180205345,
            0.0031649451702833176,
            0.02558383159339428,
            0.023591764271259308,
            -0.00434001674875617,
            -0.014686723239719868,
            -0.01798551343381405,
            -0.014210703782737255,
            0.06856728345155716,
            0.0063370042480528355,
            -0.03887177258729935,
            -0.03346951678395271,
            -0.04227852448821068,
            -0.011496874503791332,
            -0.002709199907258153,
            0.0013606833526864648,
            0.017643626779317856,
            -0.022896388545632362,
            -0.009160357527434826,
            0.028491269797086716,
            -0.10409412533044815,
            0.028513258323073387,
            0.03953367844223976,
            0.04371323436498642,
            -0.0427110381424427,
            -0.044871214777231216,
            0.02423994243144989,
            0.030257193371653557,
            0.012429574504494667,
            0.015323061496019363,
            -0.0014284485951066017,
            0.042383525520563126,
            -0.011906309984624386,
            0.010726883076131344,
            -0.01749502122402191,
            0.010574904270470142,
            0.028274036943912506,
            0.016190437600016594,
            -0.017865978181362152,
            -0.020149104297161102,
            0.05255825072526932,
            0.0008822272648103535,
            0.0293627567589283,
            -0.01329293753951788,
            -0.014170107431709766,
            0.026753196492791176,
            0.03213530406355858,
            -0.015403537079691887,
            0.005300312768667936,
            -0.018370140343904495,
            -0.013477573171257973,
            0.04524395242333412,
            0.018189067021012306,
            -0.0190225001424551,
            -0.009622527286410332,
            0.017161034047603607,
            0.03868330642580986,
            -0.005328623577952385,
            0.014833229593932629,
            -0.002290187170729041,
            -0.0014749323017895222,
            0.03945155069231987,
            0.05007884278893471,
            0.011933863162994385,
            -0.018000666052103043,
            0.022708052769303322,
            -0.014417269267141819,
            0.013285874389111996,
            0.00512609351426363,
            -0.02449124865233898,
            -0.017765458673238754,
            -0.015077166259288788,
            -0.05352337658405304,
            0.03222420811653137,
            0.00405842624604702,
            0.003473821561783552,
            0.034588929265737534,
            -0.023832807317376137,
            -0.06974467635154724,
            0.016108423471450806,
            -0.02018704079091549,
            0.030883638188242912,
            0.03185894340276718,
            0.031862322241067886,
            -0.011211574077606201,
            -0.04061906784772873,
            -0.05238311365246773,
            -0.02312110736966133,
            -0.02325892262160778,
            0.02926744520664215,
            0.03564631566405296,
            -0.007214169017970562,
            -0.010514366440474987,
            -0.004930476658046246,
            0.031350813806056976,
            0.032086629420518875,
            0.01191309466958046,
            0.037309423089027405,
            -0.0011046401923522353,
            0.0001460938510717824,
            0.021851040422916412,
            0.017150502651929855,
            -0.02217811718583107,
            -0.004760765936225653,
            -0.013904435560107231,
            0.026454905048012733,
            -0.017856724560260773,
            0.019032737240195274,
            -0.011251112446188927,
            0.04702729731798172,
            0.02905556745827198,
            0.011222252622246742,
            -0.006291734054684639,
            -0.02728806436061859,
            0.026136545464396477,
            0.039814624935388565,
            -0.022320160642266273,
            0.01338877808302641,
            -0.037903085350990295,
            -0.028151018545031548,
            0.02990305796265602,
            0.006375482305884361,
            0.03858710080385208,
            0.005690775346010923,
            -0.07655316591262817,
            0.045192062854766846,
            -0.001655177096836269,
            -0.05198736861348152,
            0.029857134446501732,
            -0.04089488834142685,
            -0.03542013838887215,
            0.01813758909702301,
            0.030394596979022026,
            -0.00016642517584841698,
            -0.03367870673537254,
            -0.038832101970911026,
            -0.04335873946547508,
            -0.055815134197473526,
            -0.01056070625782013,
            -0.007135489024221897,
            -0.031039278954267502,
            0.002355299424380064,
            -0.027739381417632103,
            -0.03189447894692421,
            -0.043956317007541656,
            0.006414919160306454,
            0.019950924441218376,
            0.021831460297107697,
            -0.052940431982278824,
            -0.003955347463488579,
            0.031058719381690025,
            0.015018517151474953,
            0.013852803036570549,
            0.028303027153015137,
            0.00229056878015399,
            -0.008986297063529491,
            0.01596059650182724,
            0.015436971560120583,
            0.012154261581599712,
            0.007961947470903397,
            -0.04511712118983269,
            0.06389299035072327,
            0.02359716407954693,
            -0.018827272579073906,
            -0.0633888691663742,
            -0.003028202336281538,
            -0.014772563241422176,
            0.026740487664937973,
            0.04092461243271828,
            0.0110548110678792,
            -0.004899418912827969,
            0.03074846789240837,
            -0.006884844973683357,
            -0.015342776663601398,
            0.03383467346429825,
            -0.01732172816991806,
            -0.01014783140271902,
            -0.04615753889083862,
            0.002855132333934307,
            0.0034359339624643326,
            -0.016590965911746025,
            -0.010810215026140213,
            -0.021611927077174187,
            0.03062075935304165,
            0.044400155544281006,
            0.05137087032198906,
            0.020394936203956604,
            -0.01399867981672287,
            -0.03776494786143303,
            0.007679016795009375,
            -0.021957481279969215,
            -0.0580391064286232,
            -0.03253314271569252,
            -0.019642505794763565,
            -0.00662473076954484,
            -0.01868823729455471,
            0.008803650736808777,
            -0.035368699580430984,
            -0.0044447253458201885,
            -0.02131022699177265,
            0.04390782490372658,
            -0.01750611513853073,
            0.003599687246605754,
            -0.02188965305685997,
            -0.00933134462684393,
            -0.05058922991156578,
            0.02512713521718979,
            -0.015137212350964546,
            -0.025874145328998566,
            -0.006481698248535395,
            -0.02528919279575348,
            -0.022411726415157318,
            -0.005623134318739176,
            -0.0031748965848237276,
            -0.03454998508095741,
            0.0051438589580357075,
            -0.022803353145718575,
            -0.033263199031353,
            -0.04784790799021721,
            -0.08994586020708084,
            0.012616695836186409,
            -0.016256025061011314,
            -0.0038714890833944082,
            0.02017764374613762,
            0.009174879640340805,
            0.01961476355791092,
            0.012084671296179295,
            0.036926355212926865,
            0.02221781760454178,
            -0.018965711817145348,
            -0.016609545797109604,
            -0.009619996882975101,
            0.011638344265520573,
            -0.029147475957870483,
            0.034267671406269073,
            0.0026317539159208536,
            0.004138471093028784,
            0.0131223751232028,
            -0.01120010856539011,
            -0.0038820714689791203,
            0.02614348940551281,
            -0.029970698058605194,
            0.014361146837472916,
            -0.004337894730269909,
            -0.044374700635671616,
            -0.043429870158433914,
            0.03326226770877838,
            -0.002545198192819953,
            -0.005183753557503223,
            0.00481105362996459,
            -0.012037884443998337,
            0.06795381009578705,
            0.012394436635077,
            -0.012132871896028519,
            -0.027534527704119682,
            0.016559891402721405,
            0.01712890714406967,
            -0.009147538803517818,
            -0.03901040181517601,
            0.006920685991644859,
            0.020019514486193657
        ],
        [
            -0.02625383622944355,
            -0.02176503650844097,
            -0.005934623535722494,
            0.016212381422519684,
            -0.017114335671067238,
            0.03325215354561806,
            -0.0014304033247753978,
            0.01428438350558281,
            -0.008306567557156086,
            0.0069350297562778,
            0.01884050853550434,
            -0.044089362025260925,
            -0.041632942855358124,
            0.011210073716938496,
            -0.059137970209121704,
            -0.10305732488632202,
            -0.020645851269364357,
            -0.012110580690205097,
            0.018287228420376778,
            -0.0012247515842318535,
            0.019055092707276344,
            -0.0033549200743436813,
            0.004443508107215166,
            0.005427697207778692,
            -0.05392518267035484,
            0.039250027388334274,
            0.021769540384411812,
            0.016832344233989716,
            -0.02513565868139267,
            0.015599880367517471,
            -0.018459388986229897,
            -0.01645573228597641,
            0.0166767630726099,
            -0.0041300407610833645,
            -0.0024050057400017977,
            -0.026312774047255516,
            0.004598021972924471,
            0.004766660742461681,
            0.01854012906551361,
            -0.016442742198705673,
            0.02773115038871765,
            0.02281046472489834,
            0.030353352427482605,
            -0.01660808175802231,
            -0.027593005448579788,
            0.017139963805675507,
            -0.03421124070882797,
            -0.007752538658678532,
            -0.01620119996368885,
            -0.03492319956421852,
            -0.019810566678643227,
            0.027789883315563202,
            0.04502150043845177,
            0.04189019277691841,
            -0.0062599703669548035,
            0.02624456025660038,
            -0.0017163482261821628,
            0.013793231919407845,
            0.028520632535219193,
            -0.009501609951257706,
            0.0300982054322958,
            -0.005813751369714737,
            0.0028300778940320015,
            -0.031031886115670204,
            0.019846031442284584,
            -0.0063044349662959576,
            -0.03904580697417259,
            0.0022061250638216734,
            0.020789261907339096,
            -0.030233940109610558,
            0.008008505217730999,
            0.00393004110082984,
            -0.0037307930178940296,
            -0.01664724387228489,
            -0.0056637912057340145,
            0.00011470050958450884,
            0.04600204527378082,
            -0.02557426318526268,
            0.009042588993906975,
            -0.021907493472099304,
            -0.03801511973142624,
            -0.008073203265666962,
            -0.019956346601247787,
            -0.0201424527913332,
            -0.015056850388646126,
            -0.028226027265191078,
            0.034407421946525574,
            0.0036888127215206623,
            -0.029283612966537476,
            0.022711127996444702,
            -0.03477358818054199,
            0.0048399087972939014,
            -0.0026045390404760838,
            -0.010522493161261082,
            0.020577620714902878,
            -0.02591661550104618,
            0.024463936686515808,
            0.002291283570230007,
            -0.01377642247825861,
            -0.003288199892267585,
            -0.016154833137989044,
            -0.044623397290706635,
            -0.033072978258132935,
            0.004184918478131294,
            -0.019308926537632942,
            0.029065383598208427,
            0.02160014398396015,
            0.016681062057614326,
            0.05072380229830742,
            0.01723788119852543,
            0.003963851369917393,
            0.033702731132507324,
            0.012823930941522121,
            0.014738444238901138,
            -0.04277682304382324,
            -0.031057320535182953,
            0.00600582268089056,
            0.025278378278017044,
            0.025101177394390106,
            -0.009257777594029903,
            -0.013243961147964,
            -0.011304999701678753,
            -0.0020798451732844114,
            -0.024714408442378044,
            0.02556348219513893,
            -0.050460685044527054,
            0.03973015397787094,
            0.02989399991929531,
            0.00852226372808218,
            0.03396328538656235,
            -0.002763347700238228,
            -0.01289370283484459,
            0.042445942759513855,
            0.0026758676394820213,
            -0.027647383511066437,
            -0.025410940870642662,
            0.013517186976969242,
            0.014610515907406807,
            0.013452828861773014,
            0.025492588058114052,
            -0.014590025879442692,
            -0.022390684112906456,
            0.017861559987068176,
            0.01165448222309351,
            0.011685448698699474,
            0.01594577729701996,
            0.0023447794374078512,
            -0.004693943075835705,
            0.013233067467808723,
            -0.03467918187379837,
            -0.03253579139709473,
            -0.0417683869600296,
            0.006827770732343197,
            -0.01682371273636818,
            -0.004026883281767368,
            0.02541188895702362,
            0.004185676109045744,
            -0.004825083073228598,
            -0.0046959551982581615,
            -0.0005423644324764609,
            0.001671748235821724,
            -0.02343808487057686,
            -0.0021514627151191235,
            -0.026822851970791817,
            0.018358344212174416,
            -0.013569770380854607,
            -0.0174050685018301,
            0.019016887992620468,
            -0.011125403456389904,
            0.004556567873805761,
            -0.03059186413884163,
            0.02153719961643219,
            -0.03989424929022789,
            0.004352159798145294,
            0.037140440195798874,
            0.0069900318048894405,
            -0.0023366203531622887,
            -0.005768860224634409,
            0.04649895429611206,
            -0.03918101638555527,
            0.006306566298007965,
            0.018491389229893684,
            0.004175608046352863,
            0.010858525522053242,
            0.036134716123342514,
            0.011623726226389408,
            0.025015253573656082,
            -0.035145413130521774,
            -0.019042732194066048,
            0.002410810673609376,
            -0.035714056342840195,
            0.01097821444272995,
            0.0402708426117897,
            0.0020082166884094477,
            -0.024249393492937088,
            -0.03026227466762066,
            0.001675913343206048,
            0.016321707516908646,
            0.011353245005011559,
            -0.017697742208838463,
            0.0072070094756782055,
            0.021360764279961586,
            -0.04416228085756302,
            -0.019144529476761818,
            0.00905342772603035,
            -0.042214613407850266,
            -0.0026652563828974962,
            0.03257855027914047,
            0.025796646252274513,
            0.014208928681910038,
            -0.01756051555275917,
            0.011113123036921024,
            -0.025757206603884697,
            0.009854520671069622,
            -0.006061244290322065,
            -0.032296519726514816,
            -0.022114897146821022,
            -0.0007195302750915289,
            -0.031207572668790817,
            -0.012693124823272228,
            0.003267279826104641,
            -0.05997136980295181,
            0.03244367614388466,
            -0.012005554512143135,
            -0.008595772087574005,
            -0.08612651377916336,
            -0.027780681848526,
            -0.018757499754428864,
            -0.0004117804055567831,
            -0.015042695216834545,
            0.020105231553316116,
            0.010388335213065147,
            0.052973076701164246,
            0.0077453358098864555,
            0.025645077228546143,
            0.02254732884466648,
            0.05652112141251564,
            0.01613430306315422,
            -0.008702880702912807,
            -0.045187029987573624,
            -0.008536980487406254,
            0.0260674636811018,
            0.00043735833605751395,
            0.009626510553061962,
            -0.010465171188116074,
            -0.015109212137758732,
            -0.005353536456823349,
            0.011262226849794388,
            0.030404681339859962,
            -0.0038741137832403183,
            0.005491251591593027,
            0.013750402256846428,
            -0.024805262684822083,
            -0.020315654575824738,
            -0.0049641248770058155,
            -0.027850287035107613,
            -0.02496841363608837,
            0.010951170697808266,
            -0.02119584009051323,
            -0.01277258712798357,
            0.03866643086075783,
            0.035793423652648926,
            -0.0038137086667120457,
            0.002905527362599969,
            -0.009840807877480984,
            0.000617960758972913,
            -0.03102443739771843,
            -0.006701725535094738,
            0.027964802458882332,
            -0.014523375779390335,
            -0.023176617920398712,
            0.02099882811307907,
            -0.01209179311990738,
            0.01944333128631115,
            0.006064618472009897,
            -0.0005225023487582803,
            0.011866098269820213,
            -0.005675482098013163,
            -0.008933238685131073,
            -0.021793292835354805,
            -0.01685199700295925,
            -0.004904155153781176,
            0.030467426404356956,
            0.017385251820087433,
            -0.030980100855231285,
            -0.010631078854203224,
            -0.007851778529584408,
            -0.03536388278007507,
            0.006456255447119474,
            0.013358340598642826,
            -0.03492484614253044,
            -0.001225159503519535,
            -0.03960742428898811,
            0.02406187728047371,
            -0.001982626272365451,
            0.00030355664785020053,
            -0.022211149334907532,
            0.010652400553226471,
            -0.0185762457549572,
            -0.011470076628029346,
            0.036820609122514725,
            0.004417468793690205,
            -0.005696720909327269,
            -0.025805531069636345,
            0.016704391688108444,
            -0.023188797757029533,
            -0.026987286284565926,
            0.006254608742892742,
            -0.04433844983577728,
            -0.04051356762647629,
            0.014785229228436947,
            -0.0048380764201283455,
            3.5043118259636685e-05,
            0.01947738043963909,
            0.023100242018699646,
            0.004558634478598833,
            0.014040273614227772,
            0.004722790792584419,
            -0.012295334599912167,
            0.026609711349010468,
            -0.02835954912006855,
            0.04751986637711525,
            -0.022476622834801674,
            0.059078432619571686,
            0.006389554124325514,
            0.010343162342905998,
            -0.010046854615211487,
            0.009854319505393505,
            0.007059831637889147,
            0.023961061611771584,
            -0.013999970629811287,
            0.022394832223653793,
            -0.015123491175472736,
            0.010853826068341732,
            0.03743709623813629,
            0.023213660344481468,
            -0.026133088394999504,
            0.0015900100115686655,
            0.04032599553465843,
            0.012159464880824089,
            0.016895467415452003,
            0.06364521384239197,
            -0.03732338547706604,
            0.0031989191193133593,
            0.02632920630276203,
            -0.02164827659726143,
            -0.02794143185019493,
            0.023339351639151573,
            0.02540331333875656,
            0.010809962637722492,
            -0.022854609414935112,
            -0.03897605091333389,
            -0.029639622196555138,
            0.014933186583220959,
            0.019759302958846092,
            0.03184174746274948,
            -0.023342346772551537,
            0.017984548583626747,
            -0.018124239519238472,
            0.011123845353722572,
            0.00031165292602963746,
            -0.010084290988743305,
            -0.027452105656266212,
            0.028503280133008957,
            -0.013444584794342518,
            0.004926629364490509,
            0.03211659938097,
            0.027950508520007133,
            -0.03809972479939461,
            0.018681442365050316,
            -0.025934437289834023,
            -0.004543936345726252,
            0.017769677564501762,
            0.03853736072778702,
            0.005379986017942429,
            0.009863199666142464,
            -0.016069678589701653,
            0.020280303433537483,
            0.008674506098031998,
            -0.022473173215985298,
            0.016679704189300537,
            -0.02649647369980812,
            -0.026689738035202026,
            -0.0010213328059762716,
            0.020819375291466713,
            0.017873894423246384,
            0.028086645528674126,
            -0.017999984323978424,
            -0.023637855425477028,
            0.025644544512033463,
            0.02792319841682911,
            0.04071156680583954,
            0.01429915614426136,
            0.0014230548404157162,
            0.038326721638441086,
            -0.023691682144999504,
            0.007273348048329353,
            -0.02794589474797249,
            0.005809823051095009,
            -0.0476684607565403,
            -0.018848633393645287,
            0.008042772300541401,
            0.009547810070216656,
            0.004070436581969261,
            8.901103865355253e-05,
            -0.002189705381169915,
            0.010784104466438293,
            -0.004437021911144257,
            -0.020316865295171738,
            -0.01914365217089653,
            -0.014946025796234608,
            -0.015267031267285347,
            0.012187489308416843,
            0.004980463068932295,
            -0.03691937029361725,
            -0.014719506725668907,
            -0.01832040771842003,
            -0.026301879435777664,
            -0.010098573751747608,
            0.018673980608582497,
            0.021020198240876198,
            0.009478128515183926,
            0.028096644207835197,
            -0.010188109241425991,
            0.008966521359980106,
            0.015782762318849564,
            0.01952747069299221,
            0.04601459205150604,
            -0.029600339010357857,
            0.014642532914876938,
            -0.0336398221552372,
            0.0018526649801060557,
            -0.03503013774752617,
            0.036882538348436356,
            -0.019524933770298958,
            0.007851535454392433,
            0.021987847983837128,
            0.01486473809927702,
            0.0019943881779909134,
            0.02299586869776249,
            0.0643913522362709,
            -0.003616917645558715,
            0.009414748288691044,
            -0.006225632969290018,
            0.023440077900886536,
            -0.03778977319598198,
            0.003061052644625306,
            0.017262959852814674,
            0.03004070185124874,
            0.02327389270067215,
            -0.01621423475444317,
            -0.0324108861386776,
            -0.021538399159908295,
            -2.247154407086782e-05,
            -0.017755484208464622,
            0.016676757484674454,
            0.014137029647827148,
            0.024267522618174553,
            -0.047204531729221344,
            0.03414275869727135,
            0.019397040829062462,
            -0.031917743384838104,
            0.0023703789338469505,
            -0.04576389491558075,
            0.023477697744965553,
            0.007180981338024139,
            0.024704288691282272,
            -0.02788405865430832,
            0.018947241827845573,
            -0.015024163760244846,
            -0.00361465266905725,
            0.035145170986652374,
            -0.029491517692804337,
            0.008133961819112301,
            0.026273131370544434,
            -0.01057402417063713,
            0.022743849083781242,
            0.011604017578065395,
            -0.034941717982292175,
            0.01611042208969593,
            -0.013831028714776039,
            0.02987486496567726,
            -0.005166046321392059,
            -0.021587025374174118,
            -0.06181742250919342,
            -0.03246613219380379,
            -0.017899448052048683,
            0.018063154071569443,
            0.025819458067417145,
            0.005309609696269035,
            -0.02742154709994793,
            0.02499781735241413,
            -0.025741485878825188,
            0.03889867290854454,
            -0.009498433209955692,
            0.020670302212238312,
            0.016618922352790833,
            -0.03510598838329315,
            -0.00672246515750885,
            0.027424700558185577,
            -0.01762249879539013,
            0.006668843794614077,
            -0.027888312935829163,
            0.028703918680548668,
            -0.011615179479122162,
            -0.013895208016037941,
            -0.01400662399828434,
            0.021222060546278954,
            0.0008784179808571935,
            0.0019556358456611633,
            -0.022295460104942322,
            0.013605921529233456,
            0.017540166154503822,
            0.01619289070367813,
            -0.015220260247588158,
            0.00671915989369154,
            -0.02708066627383232,
            -0.0019576207268983126,
            0.011406277306377888,
            -0.03244253993034363,
            0.020504772663116455,
            -0.02703188546001911,
            -0.002786815632134676,
            0.02302544377744198,
            -0.03464902192354202,
            0.0482269823551178,
            -0.011920877732336521,
            -0.0238456130027771,
            -0.0025715038646012545,
            0.028764944523572922,
            -0.009573196060955524,
            0.014786356128752232,
            -0.02816072478890419,
            0.022209616377949715,
            0.01895986683666706,
            0.013671034947037697,
            0.02399246208369732,
            0.01031000167131424,
            0.010524771176278591,
            -0.016739200800657272,
            0.03397520259022713,
            -0.0264272540807724,
            0.03144475445151329,
            -0.031496353447437286,
            -0.023383673280477524,
            -0.04329884797334671,
            -0.03765301778912544,
            0.012404996901750565,
            0.03020779974758625,
            -0.0010310937650501728,
            0.009087096899747849,
            0.017406487837433815,
            0.016008850187063217,
            0.0099776117131114,
            0.019919272512197495,
            -0.02543269470334053,
            0.005608316510915756,
            0.03979478403925896,
            -0.006528419442474842,
            -0.013192148879170418,
            0.0013988455757498741,
            -0.017350036650896072,
            -0.027980154380202293,
            0.048698149621486664,
            -0.01099138893187046,
            -0.040608398616313934,
            0.013658840209245682,
            0.02061273530125618,
            0.011479543522000313,
            -0.003831047797575593,
            -0.03101329319179058,
            -0.019182629883289337,
            0.022221606224775314,
            -0.01427088025957346,
            0.01739300973713398,
            -0.021341592073440552,
            -0.009953884407877922,
            0.02649472840130329,
            0.0026099521201103926,
            -0.004376447759568691,
            0.014488347806036472,
            0.030591031536459923,
            -0.0016274952795356512,
            0.0150581244379282,
            0.022144949063658714,
            0.008161483332514763,
            0.022862309589982033,
            0.000686554005369544,
            0.01896905153989792,
            -0.03001137264072895,
            -0.00789377186447382,
            0.005348647944629192,
            0.013931697234511375,
            0.05203908681869507,
            0.01806640438735485,
            -0.024019479751586914,
            -0.002703486941754818,
            -0.010685916990041733,
            -0.012677921913564205,
            -0.02326975204050541,
            0.0031702867709100246,
            -0.013776515610516071,
            -0.03996669128537178,
            0.025235336273908615,
            0.032565731555223465,
            0.0003771637275349349,
            0.008345588110387325,
            -0.012126920744776726,
            -0.041940733790397644,
            0.022666702046990395,
            -0.029209895059466362,
            -0.01898934133350849,
            -0.009968350641429424,
            0.008087865076959133,
            -0.008378411643207073,
            0.013190476223826408,
            0.02317497506737709,
            -0.015033073723316193,
            0.01955878920853138,
            -0.02168978936970234,
            -0.03135853260755539,
            0.031144358217716217,
            -0.008327409625053406,
            -0.021947987377643585,
            -0.005718994420021772,
            -0.0014147781766951084,
            -0.0006796320085413754,
            -0.037332966923713684,
            0.0009043611935339868,
            -0.01751873642206192,
            -0.025892240926623344,
            -0.01568181812763214,
            0.0008649203227832913,
            -0.02112673595547676,
            -0.03556791692972183,
            -0.012416290119290352,
            0.00397809874266386,
            0.016934756189584732,
            -0.003126216819509864,
            -0.008876101113855839,
            -0.014760687947273254,
            0.049895770847797394,
            0.009929788298904896,
            0.002153640380129218,
            0.026060860604047775,
            0.018505899235606194,
            0.034388668835163116,
            -0.014197713695466518,
            0.000981937046162784,
            -0.014749019406735897,
            -0.003518674522638321,
            -0.0023599667474627495,
            -0.0029655105900019407,
            -0.025777820497751236,
            0.04112039506435394,
            0.001582409837283194,
            -0.013222724199295044,
            0.009690532460808754,
            -0.01989828050136566,
            -0.010796038433909416,
            0.02249269187450409,
            -0.02907562628388405,
            -0.014371007680892944,
            -0.029931925237178802,
            -0.019349709153175354,
            0.03047081269323826,
            0.0024767883587628603,
            0.014001953415572643,
            0.006759291049093008,
            0.0243302583694458,
            -0.017181899398565292,
            -0.03774457052350044,
            -0.025273948907852173,
            -0.004471960477530956,
            -0.027223872020840645,
            -0.022223694249987602,
            -0.011066962964832783,
            0.01351039670407772,
            0.0005526826134882867,
            -0.0180972833186388,
            0.029484886676073074,
            0.012810380198061466,
            -0.0038719603326171637,
            0.02555854804813862,
            0.002222363604232669,
            0.003959253430366516,
            0.0002052665950031951,
            0.02978004887700081,
            0.02934981696307659,
            -0.007270693778991699,
            -0.038202736526727676,
            0.0009269376168958843,
            -0.011594186536967754,
            -0.02262227050960064,
            -0.018369702622294426,
            0.02470513992011547,
            0.019858065992593765,
            -0.026609813794493675,
            0.022010216489434242,
            -0.035156331956386566,
            -0.002629299648106098,
            0.015562481246888638,
            -0.008117887191474438,
            0.0047822450287640095,
            0.03276291862130165,
            0.023827601224184036,
            0.005372576881200075,
            0.02175694704055786,
            0.022161247208714485,
            -0.004132111091166735,
            0.02342130057513714,
            -0.02475438453257084,
            0.00626592431217432,
            -0.013212097808718681,
            0.024807913228869438,
            0.028637506067752838,
            -0.0014173028757795691,
            -0.007350930944085121,
            0.025520484894514084,
            -0.0006760073592886329,
            0.034960925579071045,
            0.021962014958262444,
            -0.013000395148992538,
            -0.01739901304244995,
            0.02518201619386673,
            0.03271537646651268,
            -0.008954055607318878,
            0.011173948645591736,
            -0.018971432000398636,
            -0.0044004712253808975,
            0.04161207377910614,
            -0.017861759290099144,
            0.007949969731271267,
            0.02701885625720024,
            0.010890732519328594,
            0.01033999677747488,
            -0.00022585330589208752,
            -0.01981530152261257,
            0.02573072351515293,
            -0.02426149509847164,
            0.00518054747954011,
            -0.011011078022420406,
            -0.04711516574025154,
            0.015533827245235443,
            -0.01440343540161848,
            -0.010298417881131172,
            0.009253736585378647,
            0.024776902049779892,
            -0.03362531214952469,
            0.014401610009372234,
            0.03434453532099724,
            0.0021524163894355297,
            -0.027786925435066223,
            0.020005730912089348,
            -0.009175502695143223,
            0.014390941709280014,
            -0.018463995307683945,
            0.04558873921632767,
            -0.00858257245272398,
            -0.019636375829577446,
            0.025139443576335907,
            0.019947312772274017,
            0.014778993092477322,
            0.02049088478088379,
            -0.00045831469469703734,
            0.009277178905904293,
            0.031483620405197144,
            -0.015530101023614407,
            0.012835985980927944,
            0.016722241416573524,
            -0.014589235186576843,
            0.006115969270467758,
            0.02439485676586628,
            -0.033307209610939026,
            0.01784064620733261,
            -0.0061734821647405624,
            0.04313553124666214,
            0.05805759131908417,
            0.004732017405331135,
            -0.02032771147787571,
            0.024757971987128258,
            0.005496323574334383,
            -0.01570279523730278,
            -0.004942817613482475,
            0.00902287382632494,
            0.02643471024930477,
            -0.03434687852859497,
            -0.025540446862578392,
            0.0008488268940709531,
            -0.008819568902254105,
            -0.012113823555409908,
            0.016278686001896858,
            -0.02673785388469696,
            -0.031486380845308304,
            0.007272021844983101,
            0.027033329010009766,
            -0.007399281486868858,
            0.022495398297905922,
            -0.01955341175198555,
            -0.017950143665075302,
            0.007441452704370022,
            0.01022664550691843,
            0.0041395435109734535,
            -0.012643747963011265,
            0.016060717403888702,
            0.031145183369517326,
            -0.01741190440952778,
            0.02577822096645832,
            0.002701099030673504,
            0.026355216279625893,
            -0.002969401888549328,
            0.016806142404675484,
            -0.016159532591700554,
            -0.028206322342157364,
            0.017781522125005722,
            0.02482195757329464,
            0.039306655526161194,
            -0.01756160892546177,
            -0.03191910684108734,
            -0.015669142827391624,
            -0.009017030708491802,
            0.038060661405324936,
            0.010074496269226074,
            0.00951072946190834,
            -0.02299857698380947,
            0.009920629672706127,
            -0.009817065671086311,
            0.034278951585292816,
            -0.006536038592457771,
            0.019288882613182068,
            -0.014247620478272438,
            -0.00840702187269926,
            -0.003777274629101157,
            0.027768483385443687,
            0.01844150573015213,
            0.012115571647882462,
            0.0003338286478538066,
            0.036604709923267365,
            -0.023081844672560692,
            -0.06358213722705841,
            0.009901905432343483,
            -0.01944994367659092,
            0.02600284107029438,
            -0.0007278624689206481,
            0.02000688761472702,
            0.036163583397865295,
            0.02060033567249775,
            -0.022430740296840668,
            0.03184128552675247,
            0.027854878455400467,
            -0.000267522205831483,
            0.003370241029188037,
            -0.025030791759490967,
            4.3625950638670474e-05,
            0.019922001287341118,
            -0.002294352976605296,
            0.006104576401412487,
            -0.0026128964964300394,
            -0.012530364096164703,
            0.02379697561264038,
            0.03739660978317261,
            0.032672733068466187,
            -0.0008609331562183797,
            0.005164687056094408,
            0.013939955271780491,
            0.025247085839509964,
            0.02894863486289978,
            -0.0037469021044671535,
            0.015449322760105133,
            0.048275213688611984,
            0.026363300159573555,
            0.010403871536254883,
            0.0405707024037838,
            -0.04071196913719177,
            -0.00022087324759922922,
            -0.005905854981392622,
            0.008522945456206799,
            0.07674713432788849,
            0.02530791610479355,
            -0.029532436281442642,
            0.0026739558670669794,
            -0.01746891625225544,
            0.017023008316755295,
            0.003239224897697568,
            0.01840873248875141,
            0.028490908443927765,
            0.013585047796368599,
            -0.0666840523481369,
            0.039879120886325836,
            -0.020254362374544144,
            -0.030198048800230026,
            -0.003504268592223525,
            0.008841292932629585,
            -0.00985016766935587,
            0.04169340059161186,
            -0.02170233614742756,
            -0.012185243889689445,
            0.005033442284911871,
            0.0299629308283329,
            0.039021313190460205,
            0.00717162573710084,
            0.029590856283903122,
            0.0034186113625764847,
            -0.012028937228024006,
            -0.00047271992661990225,
            0.03983031585812569,
            0.03827495872974396,
            -0.04088866710662842,
            -0.006575033068656921,
            0.0061803944408893585,
            0.009422199800610542,
            -0.02281128242611885,
            0.015140262432396412,
            -0.009424518793821335,
            0.018195658922195435,
            0.017304426059126854,
            0.02098042331635952,
            -0.020557405427098274,
            0.009920204989612103,
            -0.023055870085954666,
            -0.00011544501467142254,
            0.01764124445617199,
            0.007303701713681221,
            0.03675809130072594,
            -0.03275434300303459,
            0.0312433410435915,
            0.012247517704963684,
            0.009505486115813255,
            -0.00046073333942331374,
            0.026504971086978912,
            -0.02768857590854168,
            -0.012526134960353374,
            0.020466525107622147,
            -0.0034536186140030622,
            -0.015194553881883621,
            -0.025340430438518524,
            0.0028002983890473843,
            0.017414085566997528,
            -0.013566343113780022,
            -0.003776025027036667,
            -0.019617101177573204,
            -0.012402881868183613,
            0.02653094381093979,
            -0.0077351112850010395,
            0.02291874960064888,
            -0.001067871111445129,
            -0.020824477076530457,
            0.007146012037992477,
            0.018090637400746346,
            -0.037754036486148834,
            -0.02057502605021,
            0.010080093517899513,
            -0.0021018513944000006,
            -0.006498169153928757,
            0.06226744502782822,
            -0.015908952802419662,
            0.016386888921260834,
            -0.00918402150273323,
            0.030554499477148056,
            0.02415407821536064,
            -0.01121513918042183,
            -0.029398296028375626,
            0.0006036823615431786,
            0.008187634870409966,
            -0.019481541588902473,
            0.02657424472272396,
            -0.0046721831895411015,
            -0.01017039641737938,
            -0.013262193650007248,
            -0.0027098660357296467,
            0.0017267937073484063,
            0.01808568276464939,
            -0.005572204478085041,
            -0.019270295277237892,
            0.02296483889222145,
            0.00571470195427537,
            0.013834157027304173,
            -0.0025788627099245787,
            0.034583140164613724,
            -0.005103872157633305,
            0.02557552605867386,
            0.034798286855220795,
            -0.015505771152675152,
            0.02948913909494877,
            0.04585663974285126,
            -0.010127373971045017,
            0.02585345320403576,
            0.02546463906764984,
            -0.03410712629556656,
            -0.004122452344745398,
            0.005499271210283041,
            -0.002520032227039337,
            -0.007379808928817511,
            -0.0024775289930403233,
            0.01587579771876335,
            -0.010431773029267788,
            0.019005507230758667,
            -0.01536187157034874,
            -0.008295491337776184,
            0.01237665768712759,
            0.02916914038360119,
            0.004675261210650206,
            0.003137251129373908,
            -0.02625036984682083,
            0.010736625641584396,
            0.014044811017811298,
            -0.006450720131397247,
            0.04029379412531853,
            0.0014255153946578503,
            -0.03515654429793358,
            -0.05349469557404518,
            -0.048900384455919266,
            0.02043291926383972,
            0.028150487691164017,
            -0.041440077126026154,
            -0.05979457125067711,
            -0.023103926330804825,
            0.0331638939678669,
            0.04751162603497505,
            -0.020883901044726372,
            0.04064956679940224,
            0.02621881477534771,
            -0.0009277755743823946,
            0.014199006371200085
        ],
        [
            -0.015337669290602207,
            0.019637424498796463,
            0.021383322775363922,
            -0.014770429581403732,
            0.024823319166898727,
            -0.015021017752587795,
            0.03607435151934624,
            -0.01096401084214449,
            0.022660205140709877,
            -0.010918868705630302,
            -0.006172835361212492,
            0.007327387575060129,
            -0.02184958942234516,
            0.014264562167227268,
            0.02911178395152092,
            -0.023272037506103516,
            -0.024257641285657883,
            -0.007658130954951048,
            0.010846373625099659,
            0.016354838386178017,
            0.01814747042953968,
            0.012138870544731617,
            -0.04230179265141487,
            -0.0167220588773489,
            -0.0286384429782629,
            -0.003388236043974757,
            0.01119837537407875,
            0.0011991659412160516,
            0.036270443350076675,
            -0.006553256418555975,
            -0.02333468571305275,
            0.037511490285396576,
            -0.01536023523658514,
            0.016440551728010178,
            -0.02031666785478592,
            0.01779063045978546,
            0.0176899004727602,
            -0.007496445439755917,
            0.0323517769575119,
            0.02176222763955593,
            0.02074202336370945,
            0.005063008051365614,
            -0.03114944137632847,
            0.007105586584657431,
            -0.0011818252969533205,
            -0.004012097138911486,
            0.004896888043731451,
            0.037837669253349304,
            0.019999392330646515,
            0.006301614455878735,
            -0.020877603441476822,
            0.015724895521998405,
            0.02269858308136463,
            0.0031873350962996483,
            -0.007755035534501076,
            0.023649079725146294,
            -0.01647305302321911,
            0.038565389811992645,
            0.030573830008506775,
            -0.023197855800390244,
            -0.018340103328227997,
            0.02116318978369236,
            -0.005038396455347538,
            -0.009346961043775082,
            -0.01093436498194933,
            -0.009944290854036808,
            0.032389674335718155,
            -0.024379337206482887,
            -0.03077457845211029,
            -0.01770123466849327,
            -0.02239585667848587,
            0.03102409467101097,
            -0.0071907006204128265,
            0.013847550377249718,
            0.0323001928627491,
            0.04134718328714371,
            -0.013977209106087685,
            -0.012599151581525803,
            0.04980606958270073,
            0.027627119794487953,
            0.011008352041244507,
            -0.006725406274199486,
            -0.008162262849509716,
            0.011847920715808868,
            -0.024530161172151566,
            0.027873272076249123,
            -0.009856348857283592,
            0.01701313443481922,
            0.015488166362047195,
            0.010146517306566238,
            -0.002035193145275116,
            -0.015468833036720753,
            -0.0013538836501538754,
            -0.00811933632940054,
            0.01906370185315609,
            0.00018351056496612728,
            0.022110668942332268,
            0.015665004029870033,
            0.04452028498053551,
            0.014392112381756306,
            -0.013120438903570175,
            0.004655991215258837,
            0.028019487857818604,
            0.01864450052380562,
            -0.02100415527820587,
            0.008941485546529293,
            -0.017352206632494926,
            0.01882735826075077,
            -0.032032378017902374,
            0.012820541858673096,
            0.0387110561132431,
            0.015802545472979546,
            0.00780430668964982,
            0.027888579294085503,
            0.02648785337805748,
            0.016337299719452858,
            -0.01474050898104906,
            -0.01658610813319683,
            -0.015070094726979733,
            -0.02052202820777893,
            0.020006299018859863,
            -0.008901560679078102,
            -0.030413558706641197,
            0.02488299272954464,
            0.032335858792066574,
            -0.025249462574720383,
            -0.0008609270444139838,
            -0.03330015391111374,
            -0.011564887128770351,
            0.00027009384939447045,
            -0.009504054673016071,
            -0.024736547842621803,
            -0.035284873098134995,
            -0.001494301948696375,
            0.008930041454732418,
            -0.011366307735443115,
            -0.002052553463727236,
            -0.030982475727796555,
            0.03359789401292801,
            0.020255181938409805,
            -0.024777768179774284,
            -0.01905524544417858,
            0.021717730909585953,
            -0.00986469630151987,
            -0.04426978901028633,
            0.0004604071145877242,
            0.020999139174818993,
            -0.011798223480582237,
            -0.012726041488349438,
            0.018597448244690895,
            -0.018370546400547028,
            0.007188522256910801,
            -0.033549897372722626,
            -0.005815057549625635,
            -0.005224619992077351,
            -0.022156888619065285,
            0.011729218997061253,
            -0.02079816535115242,
            0.005354580469429493,
            0.019505050033330917,
            0.017376048490405083,
            -0.011485804803669453,
            -0.017615415155887604,
            0.015198602341115475,
            -0.002254173392429948,
            -0.049016110599040985,
            -0.045250311493873596,
            -0.00846801046282053,
            0.027250830084085464,
            -0.014467894099652767,
            -0.020338352769613266,
            -0.033010032027959824,
            0.031194018200039864,
            0.03330692648887634,
            0.018042299896478653,
            -0.0005034108180552721,
            -0.0014351059217005968,
            0.00028431619284674525,
            0.00447144266217947,
            -0.01350355800241232,
            0.040270451456308365,
            0.006577255669981241,
            0.027282392606139183,
            0.021046720445156097,
            0.027820773422718048,
            0.003561916761100292,
            -0.008737131953239441,
            0.014983459375798702,
            -0.002740350551903248,
            -0.011556001380085945,
            -0.01445812452584505,
            -0.0024192817509174347,
            -0.028264500200748444,
            -0.010320006869733334,
            0.010552202351391315,
            0.00405772915109992,
            -0.015320852398872375,
            -0.0016024326905608177,
            -0.008478797040879726,
            0.024885036051273346,
            -0.020079104229807854,
            0.017277942970395088,
            0.0235127005726099,
            -0.015577760525047779,
            -0.021570738404989243,
            0.010059704072773457,
            0.059953782707452774,
            0.00431472435593605,
            -0.008121094666421413,
            0.03772476315498352,
            0.0211779847741127,
            0.013276875950396061,
            -0.005350341089069843,
            -0.03340737894177437,
            -0.03841312602162361,
            0.01908501423895359,
            0.00754551449790597,
            -0.003289420623332262,
            -0.026118842884898186,
            0.01933589205145836,
            0.008922840468585491,
            0.01096365600824356,
            0.012085048481822014,
            0.0019914342556148767,
            -0.030112046748399734,
            -0.026717035099864006,
            0.03203855827450752,
            0.031686246395111084,
            0.03389868512749672,
            -0.02948780357837677,
            -0.042588602751493454,
            -0.005453318823128939,
            -0.017718806862831116,
            -0.031300682574510574,
            -0.005189200863242149,
            -0.010093173943459988,
            -0.05825037881731987,
            -0.018598072230815887,
            0.00976779218763113,
            0.010097934864461422,
            0.01064559817314148,
            -0.01845451630651951,
            -0.008864477276802063,
            0.004432187415659428,
            -0.030196454375982285,
            0.009009124711155891,
            0.012058494612574577,
            0.02116970345377922,
            0.01950867660343647,
            -0.012325618416070938,
            -0.03473249822854996,
            0.004578408785164356,
            0.014268170110881329,
            -0.020013699308037758,
            -0.012223110534250736,
            -0.004457083996385336,
            -0.009406219236552715,
            -0.010797333903610706,
            -0.04437173902988434,
            -0.032059166580438614,
            -0.009071541018784046,
            0.001583404024131596,
            0.024287676438689232,
            0.005941369105130434,
            -0.023592613637447357,
            0.011906892992556095,
            0.009105772711336613,
            -0.012847378849983215,
            0.02195419929921627,
            0.006242881529033184,
            0.03736603260040283,
            0.0048505510203540325,
            -0.0064146993681788445,
            -0.014697364531457424,
            0.010943705216050148,
            -0.002402469515800476,
            -0.0004420242039486766,
            -0.013957724906504154,
            -0.0350225493311882,
            0.009797010570764542,
            0.04615384712815285,
            0.01952844113111496,
            0.013834855519235134,
            0.023037895560264587,
            -0.009603637270629406,
            0.016241658478975296,
            0.028643151745200157,
            0.060946110635995865,
            0.020867565646767616,
            0.0025523377116769552,
            -0.02574368380010128,
            0.010875950567424297,
            0.0050250147469341755,
            0.02946525253355503,
            -0.033983130007982254,
            -0.03208328038454056,
            -0.01930735446512699,
            -0.004837192129343748,
            0.024820426478981972,
            -0.004308920353651047,
            0.015588133595883846,
            0.005174842197448015,
            0.008829615078866482,
            0.015941204503178596,
            0.03175026923418045,
            0.009977995418012142,
            0.006479490548372269,
            -0.020105862990021706,
            0.019692711532115936,
            -0.011808879673480988,
            0.03213328495621681,
            0.017341643571853638,
            -0.019895702600479126,
            0.022562596946954727,
            0.01603691466152668,
            0.0069594313390553,
            0.02734796516597271,
            -0.006350926123559475,
            -0.004738260060548782,
            0.02897820435464382,
            -0.01919863000512123,
            0.06290265917778015,
            0.029146388173103333,
            -0.016742749139666557,
            0.009344144724309444,
            0.02996288239955902,
            0.006323663052171469,
            -0.02109997533261776,
            0.012547153048217297,
            0.01214066706597805,
            0.007038412615656853,
            0.0007217618986032903,
            0.011083969846367836,
            0.040033914148807526,
            -0.01950705610215664,
            0.02007129229605198,
            -0.01115997415035963,
            0.01844664476811886,
            0.002845139242708683,
            0.005332272965461016,
            0.027432449162006378,
            0.04607674852013588,
            0.0025611345190554857,
            -0.03387477248907089,
            -0.034581877291202545,
            -0.0503547303378582,
            0.03580979257822037,
            -0.02200465463101864,
            0.026200763881206512,
            -0.05581459030508995,
            0.014582364819943905,
            0.0171679500490427,
            -0.019959714263677597,
            -0.004176008515059948,
            -0.02574331872165203,
            0.02185669355094433,
            0.015138820745050907,
            -0.009362531825900078,
            0.028498826548457146,
            0.012679053470492363,
            0.017595389857888222,
            0.020224764943122864,
            -0.007145716343075037,
            -0.01228990126401186,
            0.011729560792446136,
            -0.023748787119984627,
            0.04443658888339996,
            0.0063292416743934155,
            0.005874461028724909,
            -0.014438365586102009,
            -0.01626506820321083,
            0.00933429691940546,
            -0.002050524577498436,
            -0.029507284983992577,
            0.005225733388215303,
            -0.010097161866724491,
            0.011955806985497475,
            -0.019766878336668015,
            0.03863174468278885,
            -0.0076526012271642685,
            0.009295814670622349,
            -0.014520619064569473,
            0.004533613100647926,
            -0.027022037655115128,
            -0.018691206350922585,
            0.04864149168133736,
            0.003284933278337121,
            -0.002879892010241747,
            0.03273547440767288,
            -0.0020967856980860233,
            0.013764860108494759,
            -0.021566117182374,
            -0.02426285110414028,
            0.012576647102832794,
            -0.024696240201592445,
            -0.023191748186945915,
            -0.001564337988384068,
            -0.0015117846196517348,
            -0.010393822565674782,
            -0.014534513466060162,
            -0.031226523220539093,
            0.018920760601758957,
            -0.02939191274344921,
            -0.02010618895292282,
            -0.0012402378488332033,
            0.035801250487565994,
            -0.02503153681755066,
            -0.036859314888715744,
            -0.03025962971150875,
            0.00922899879515171,
            0.002815313870087266,
            -0.02388771064579487,
            -0.04596526548266411,
            -0.02335680089890957,
            0.02421376295387745,
            0.012071551755070686,
            0.025477737188339233,
            0.00939231552183628,
            0.002790270373225212,
            0.026508111506700516,
            -0.007937071844935417,
            0.006025971379131079,
            -0.004895319696515799,
            0.01631910353899002,
            -0.00646513095125556,
            -0.03545105829834938,
            0.015360522083938122,
            0.014883789233863354,
            -0.005823471583425999,
            0.010117136873304844,
            0.036106742918491364,
            -0.029057422652840614,
            0.004785112105309963,
            -0.002761946292594075,
            0.018438350409269333,
            0.0124855050817132,
            -0.027942728251218796,
            0.030508626252412796,
            -0.014891640283167362,
            0.002407383406534791,
            0.004336350131779909,
            -0.0001092166276066564,
            -0.035120729357004166,
            -0.012454728595912457,
            0.029728809371590614,
            -0.001042268006131053,
            -0.02301925979554653,
            0.016560308635234833,
            0.027966568246483803,
            -0.0035100881941616535,
            -0.02221677079796791,
            -0.0027104078326374292,
            0.04881485551595688,
            0.02786991186439991,
            0.0440862774848938,
            -0.03610166907310486,
            -0.03895558416843414,
            -0.001849667401984334,
            -0.02590208314359188,
            0.0312229972332716,
            -0.009082039818167686,
            -0.009434961713850498,
            0.021278079599142075,
            0.02233033813536167,
            0.006098596379160881,
            -0.03179722651839256,
            0.013343489728868008,
            0.012953897938132286,
            0.0017776279710233212,
            0.0031181415542960167,
            -0.020585214719176292,
            -9.244115062756464e-05,
            -0.021121082827448845,
            -0.0015433187363669276,
            0.024670733138918877,
            -0.01954984851181507,
            0.03441549465060234,
            -0.004943880718201399,
            0.013864722102880478,
            -0.013868037611246109,
            0.018089648336172104,
            -0.03499222919344902,
            -0.004154976923018694,
            0.0039390078745782375,
            0.009742814116179943,
            0.006411109119653702,
            0.00677762134000659,
            0.008082238957285881,
            0.013313804753124714,
            -0.02247324399650097,
            0.006943539250642061,
            0.029604651033878326,
            0.019108517095446587,
            0.02862727828323841,
            0.005710998550057411,
            -0.018771197646856308,
            0.03279537335038185,
            -0.016105394810438156,
            0.007792272605001926,
            -0.006077094469219446,
            0.016378168016672134,
            0.03409360721707344,
            -0.002319023013114929,
            0.00489430408924818,
            -0.012416270561516285,
            0.00038769980892539024,
            0.023259803652763367,
            -0.027798699215054512,
            0.029812535271048546,
            0.014879257418215275,
            0.014287058264017105,
            -0.012761048972606659,
            -0.006696646101772785,
            0.00903333444148302,
            0.01869906112551689,
            0.02861875481903553,
            -0.01314318273216486,
            0.01921956054866314,
            -0.008790241554379463,
            -0.0049423049204051495,
            -0.009321383200585842,
            0.021749919280409813,
            -0.014966147020459175,
            0.02212372235953808,
            0.02610100246965885,
            -0.026037732139229774,
            -0.07230116426944733,
            -0.018595870584249496,
            0.022698476910591125,
            0.031742293387651443,
            -0.020671922713518143,
            -0.026737557724118233,
            0.0013303296873345971,
            0.00616610050201416,
            0.023850135505199432,
            0.029881099238991737,
            -0.027780842036008835,
            -0.008411246351897717,
            -0.03160422295331955,
            0.01630638726055622,
            0.021411845460534096,
            0.011401488445699215,
            -0.02221759594976902,
            -0.002012387616559863,
            0.008438151329755783,
            0.055415548384189606,
            0.030836347490549088,
            -0.015768129378557205,
            -0.029277198016643524,
            -0.0035942576359957457,
            0.0009897974086925387,
            -0.018564242869615555,
            -0.005829966627061367,
            -0.0293511264026165,
            0.011626945808529854,
            -0.014929750934243202,
            -0.020746538415551186,
            -0.022579943761229515,
            0.00303671695291996,
            8.662937761982903e-05,
            -0.017181415110826492,
            -0.006918513216078281,
            -0.024860825389623642,
            0.027646049857139587,
            -0.018215222284197807,
            0.022915804758667946,
            -0.000225662617594935,
            0.023641470819711685,
            -0.006628612522035837,
            -0.005682373885065317,
            0.02283874899148941,
            0.023295287042856216,
            -0.012508848682045937,
            0.003053109161555767,
            0.03299172967672348,
            0.02485538087785244,
            -0.014956670813262463,
            0.0035710823722183704,
            -0.04293092340230942,
            0.02866089716553688,
            -0.009373466484248638,
            -0.02449920028448105,
            0.021815884858369827,
            -0.031869422644376755,
            0.029197944328188896,
            -0.01224974263459444,
            0.010773866437375546,
            -0.0036530960351228714,
            -0.005249100271612406,
            -0.015752088278532028,
            -0.02113710343837738,
            -0.009023861028254032,
            -0.022535111755132675,
            -0.02695501036942005,
            -0.005640640389174223,
            -0.020124873146414757,
            0.013627797365188599,
            -0.030225742608308792,
            0.007820804603397846,
            0.007128818426281214,
            -0.013048439286649227,
            -0.007188995368778706,
            -0.004487777128815651,
            0.02516951598227024,
            -0.025433512404561043,
            -0.004416527226567268,
            0.0031389466021209955,
            0.022395305335521698,
            0.019589228555560112,
            0.0041610184125602245,
            -0.01807207427918911,
            0.014566894620656967,
            -0.02074170857667923,
            -0.02601700648665428,
            -0.019928397610783577,
            0.0019967874977737665,
            -0.018083151429891586,
            -0.010592244565486908,
            -0.03457394242286682,
            -0.04081225395202637,
            0.01442257221788168,
            0.01915702037513256,
            -0.026060648262500763,
            -0.025323402136564255,
            0.002989433240145445,
            -0.01430367212742567,
            0.006772353779524565,
            0.00030342693207785487,
            0.019729657098650932,
            0.01855630986392498,
            0.002686405787244439,
            -0.015617405995726585,
            -0.020491955801844597,
            -0.010209901258349419,
            -0.013520516455173492,
            0.006977111101150513,
            -0.009028385393321514,
            -0.003996883053332567,
            -0.012838233262300491,
            -0.013934304937720299,
            0.03919190913438797,
            0.0003020086733158678,
            0.004824850708246231,
            0.025750713422894478,
            -0.009126094169914722,
            -0.02267821505665779,
            0.012433997355401516,
            -0.008847433142364025,
            0.03052770346403122,
            0.018032008782029152,
            -0.013201848603785038,
            0.023114243522286415,
            0.023955518379807472,
            0.02419988065958023,
            0.008751393295824528,
            -0.027727294713258743,
            -0.014800681732594967,
            0.027043813839554787,
            -0.011144732125103474,
            -0.013811693526804447,
            0.01748095080256462,
            -0.0026236390694975853,
            -0.013686844147741795,
            -0.010696964338421822,
            0.013421432115137577,
            0.0016348089557141066,
            0.02186141349375248,
            -0.0007127696298994124,
            -0.02162705548107624,
            0.004568236880004406,
            0.022797925397753716,
            -0.04042651504278183,
            -0.01775440201163292,
            0.005677690263837576,
            0.006140426732599735,
            0.029154906049370766,
            0.00426469836384058,
            0.015419024974107742,
            0.040017299354076385,
            0.0015712394379079342,
            0.04227985069155693,
            0.02575431950390339,
            0.02230534888803959,
            0.03339732810854912,
            0.0077274092473089695,
            -0.024167757481336594,
            -0.002662764396518469,
            0.010028915479779243,
            -0.0023126909509301186,
            0.02980962023139,
            0.037049684673547745,
            -0.01614302024245262,
            -0.024891935288906097,
            -0.020291877910494804,
            0.028310885652899742,
            -0.030748985707759857,
            -0.003790583461523056,
            0.020256925374269485,
            -0.03027413971722126,
            0.02702406607568264,
            0.0018472804222255945,
            -0.015262993983924389,
            -0.008035642094910145,
            0.017341291531920433,
            0.0350683219730854,
            -2.453600609442219e-05,
            -0.031797610223293304,
            0.020816167816519737,
            0.003009230364114046,
            0.007459539454430342,
            0.024515947327017784,
            0.026902571320533752,
            -0.013927385210990906,
            0.025668496266007423,
            0.016173237934708595,
            0.01224924810230732,
            0.0051663704216480255,
            -0.0002247088705189526,
            -0.026725927367806435,
            0.023312736302614212,
            -0.0059830741956830025,
            0.028306737542152405,
            0.0029379259794950485,
            0.004357438534498215,
            0.006908763200044632,
            0.036134108901023865,
            0.0019419364398345351,
            -0.00667283870279789,
            0.0024162542540580034,
            -0.0005960458656772971,
            -0.014431189745664597,
            -0.02649512328207493,
            0.016352079808712006,
            -0.014505094848573208,
            0.017028002068400383,
            -0.00787696335464716,
            0.025163352489471436,
            -0.011462475173175335,
            0.055418260395526886,
            0.011767786927521229,
            0.005002432037144899,
            0.021798264235258102,
            0.009256205521523952,
            0.023879285901784897,
            0.0013130416627973318,
            0.005154875572770834,
            -0.05727343261241913,
            0.009263607673346996,
            0.01912669464945793,
            0.013085534796118736,
            -0.00797952339053154,
            -0.013574961572885513,
            0.03124317340552807,
            0.038863785564899445,
            0.003994850441813469,
            -0.018656127154827118,
            0.004743526689708233,
            -0.013362854719161987,
            0.00044290494406595826,
            0.004448881838470697,
            0.008881543762981892,
            0.012405073270201683,
            -0.022765835747122765,
            0.01819394715130329,
            0.007132595404982567,
            -0.01217501051723957,
            0.02271825633943081,
            0.030773892998695374,
            -0.020091554149985313,
            0.0002627680078148842,
            0.013591393828392029,
            0.016547352075576782,
            0.039271727204322815,
            -0.001135519240051508,
            -0.0302767101675272,
            0.005327976308763027,
            -0.0037249578163027763,
            0.004444044549018145,
            0.023232148960232735,
            -0.01315866969525814,
            -0.01960502378642559,
            -0.028614116832613945,
            -0.025697337463498116,
            0.03855109587311745,
            0.010885939002037048,
            -0.01629730686545372,
            0.015023699030280113,
            0.03186416998505592,
            -0.011960775591433048,
            0.004210625775158405,
            0.0019528093980625272,
            -0.012926140800118446,
            -0.018181992694735527,
            0.01484574843198061,
            0.012255559675395489,
            0.04908919334411621,
            -0.026853427290916443,
            0.019757594913244247,
            -0.027888288721442223,
            0.012110268697142601,
            0.008461883291602135,
            -0.010315017774701118,
            -0.005109499674290419,
            0.015926314517855644,
            0.019724750891327858,
            0.013316965661942959,
            -0.046399567276239395,
            0.031682781875133514,
            0.011374339461326599,
            0.04290645569562912,
            0.007413974031805992,
            0.03577899560332298,
            0.01892126351594925,
            -0.0004928589914925396,
            -0.0025945487432181835,
            -0.030240820720791817,
            -0.0187228973954916,
            0.00274241273291409,
            -0.01822541281580925,
            0.023889698088169098,
            0.01575256697833538,
            -0.022485775873064995,
            -0.016602711752057076,
            0.005800280719995499,
            0.025067292153835297,
            -0.009968101978302002,
            0.033604059368371964,
            -0.028026007115840912,
            0.025461852550506592,
            -0.013375055976212025,
            -0.014818708412349224,
            -0.04004894569516182,
            -0.0077459863387048244,
            0.0009185963426716626,
            0.011423221789300442,
            0.03751662001013756,
            -0.02670810930430889,
            -0.0068128774873912334,
            -0.02100200578570366,
            0.03143557906150818,
            0.014082629233598709,
            -0.017108989879488945,
            0.019916541874408722,
            0.004556524567306042,
            -0.011668373830616474,
            0.028279995545744896,
            0.022456364706158638,
            0.019504647701978683,
            0.01137640792876482,
            -0.00824132189154625,
            0.009298407472670078,
            -0.005720050074160099,
            0.01635461300611496,
            0.0026875894982367754,
            -0.005902768578380346,
            -0.027892740443348885,
            -0.00972003024071455,
            -0.0004835619474761188,
            0.018551543354988098,
            -0.023118846118450165,
            0.003528318600729108,
            -0.009016075171530247,
            0.011328303255140781,
            0.040757328271865845,
            -0.016491489484906197,
            -0.04428005963563919,
            0.014752538874745369,
            -0.002703958423808217,
            -0.0019104392267763615,
            0.011029001325368881,
            -0.02493239939212799,
            -0.008870506659150124,
            0.022590680047869682,
            -0.0015748883597552776,
            -0.025771230459213257,
            0.033906664699316025,
            0.00429017236456275,
            -0.0033549803774803877,
            -0.010785497725009918,
            -0.004634121898561716,
            0.0005312426947057247,
            0.0072907572612166405,
            -0.0014545283047482371,
            -0.001291427412070334,
            -0.0025542930234223604,
            0.027807200327515602,
            0.023120341822504997,
            0.06610538065433502,
            0.0003589876287151128,
            0.005291112698614597,
            -0.020923495292663574,
            0.009180533699691296,
            0.03462373837828636,
            0.02202928252518177,
            -0.0014196555130183697,
            -0.01465335302054882,
            0.024180972948670387,
            0.021329887211322784,
            -0.0007622579578310251,
            0.019907601177692413,
            -0.009409855119884014,
            0.0065426756627857685,
            0.014784421771764755,
            -0.020093603059649467,
            0.028859807178378105,
            0.02270357683300972,
            0.002376844407990575,
            0.01646490953862667,
            0.024325914680957794,
            -0.03263076767325401,
            -0.034043457359075546,
            -0.012942718341946602,
            -0.0010401860345155,
            -0.06656445562839508,
            0.01266852580010891,
            0.010805846191942692,
            -0.010996288619935513,
            0.00070986058562994,
            -0.014701842330396175,
            -0.006188613828271627,
            0.010670414194464684,
            -0.010436557233333588,
            -0.020492950454354286,
            0.0010334119433537126,
            0.02771284244954586,
            -0.01591753587126732,
            -0.032914306968450546,
            -0.03580695018172264,
            0.010916436091065407,
            0.008285566233098507,
            0.02184765599668026,
            -0.019679004326462746,
            -0.022142590954899788,
            0.031356632709503174,
            -0.004981105215847492,
            0.0321497768163681,
            -0.006491640582680702,
            0.028206439688801765,
            -0.02425166219472885,
            0.0003262866521254182,
            0.010512853041291237,
            -0.021875446662306786,
            -0.004606205504387617,
            -0.006101319566369057,
            0.02279919572174549,
            0.02492746151983738,
            0.010112177580595016,
            0.030067387968301773,
            -0.00932958722114563,
            0.01712394878268242,
            -0.003443440655246377,
            0.0010851145489141345,
            0.010132585652172565,
            -0.0028632397297769785,
            0.010956205427646637,
            -0.017469627782702446,
            0.004623530898243189,
            0.024575714021921158,
            0.002841563895344734,
            0.036608535796403885,
            0.03911222890019417,
            -0.005303538870066404,
            0.021620580926537514,
            0.050263240933418274,
            0.0029744752682745457,
            0.02213795855641365,
            -0.014454674907028675,
            -0.018381306901574135,
            0.03528968244791031,
            0.02041870541870594,
            0.012989023700356483,
            -0.02265743352472782,
            -0.032286252826452255,
            0.026889292523264885,
            0.040973931550979614,
            -0.027933580800890923,
            -0.007069988641887903,
            -0.007191399112343788,
            0.003148632124066353,
            -0.028365442529320717,
            0.010972550138831139,
            0.0014512301422655582,
            0.054974477738142014,
            -0.018194664269685745,
            0.01707416959106922,
            0.020856056362390518,
            -0.009975562803447247,
            -0.015721911564469337,
            -0.00718549033626914,
            -0.008964275941252708,
            -0.023561978712677956,
            0.0224466435611248,
            -0.015605502761900425,
            0.013766766525804996,
            -0.020771540701389313,
            0.03257019445300102,
            -0.07402054220438004,
            0.0064503164030611515,
            0.025335386395454407,
            -0.006482547614723444,
            -0.02932552807033062,
            0.017871128395199776,
            0.020966552197933197,
            -0.01640142686665058,
            -0.012347560375928879,
            0.01567995920777321,
            -0.015660785138607025,
            0.011804506182670593,
            0.01981576532125473,
            -0.005364813841879368,
            -0.0008284683572128415,
            -0.017890026792883873,
            0.006706383544951677,
            0.032294683158397675,
            0.04172615334391594,
            0.039795733988285065,
            -0.012171621434390545,
            -0.038619715720415115,
            0.001010987558402121,
            -0.001026573940180242,
            -0.028571315109729767,
            -0.004947221837937832,
            -0.03063018061220646,
            0.006525576580315828
        ],
        [
            0.0017137572867795825,
            0.014169334433972836,
            0.006600293330848217,
            -0.024397311732172966,
            0.005086729768663645,
            -0.026405207812786102,
            -0.003380480222404003,
            -0.0008118380210362375,
            -0.029727283865213394,
            0.031645212322473526,
            0.03865562751889229,
            0.03212614730000496,
            0.02070481888949871,
            -0.01460846047848463,
            -0.012521576136350632,
            0.038301534950733185,
            0.023754499852657318,
            0.0007221617270261049,
            0.02154606394469738,
            -0.018682081252336502,
            -0.012574649415910244,
            0.017853297293186188,
            0.005564133636653423,
            -0.024329988285899162,
            -0.04505753517150879,
            -0.025399716570973396,
            0.0034323143772780895,
            -0.007803439628332853,
            0.011337051168084145,
            0.002764404285699129,
            -0.000700371281709522,
            -0.032802384346723557,
            0.007294502109289169,
            0.010314361192286015,
            0.011350431479513645,
            -0.014142641797661781,
            -0.018891630694270134,
            0.0047485847026109695,
            0.05071472004055977,
            -0.023672649636864662,
            -0.022526239976286888,
            -0.004514689091593027,
            -0.02949952334165573,
            0.009335863403975964,
            0.021037381142377853,
            0.008122344501316547,
            0.026582572609186172,
            -0.027192411944270134,
            -0.009397357702255249,
            0.004995178431272507,
            -0.0415673665702343,
            -0.006635993719100952,
            0.02667822688817978,
            -0.019057998433709145,
            -0.008327178657054901,
            0.01771494932472706,
            0.009954004548490047,
            -0.016714783385396004,
            0.022725559771060944,
            0.024138448759913445,
            0.013909181579947472,
            9.087396028917283e-05,
            0.00385350757278502,
            0.020514968782663345,
            0.021138448268175125,
            0.004114408046007156,
            0.0217952448874712,
            0.02274620719254017,
            0.010251701809465885,
            -0.026290040463209152,
            0.0010059716878458858,
            0.012647949159145355,
            -0.004492824897170067,
            0.025703534483909607,
            0.07009707391262054,
            -0.04702580347657204,
            0.01062506902962923,
            0.013517660088837147,
            -0.06158244609832764,
            0.01718810386955738,
            0.020871521905064583,
            -0.012307384982705116,
            -0.013028103858232498,
            0.02456163801252842,
            0.006391635164618492,
            -0.015227848663926125,
            0.02667621523141861,
            0.02221779152750969,
            -0.012209073640406132,
            -0.010997294448316097,
            0.019076865166425705,
            -0.01602795347571373,
            -0.0005828664288856089,
            -0.037112362682819366,
            0.03464429825544357,
            -0.01888951100409031,
            0.0185338594019413,
            0.017765510827302933,
            0.08222885429859161,
            -0.0375978909432888,
            0.02307630330324173,
            -0.03274628147482872,
            -0.009613598696887493,
            0.014341289177536964,
            -0.017657466232776642,
            -0.02089034765958786,
            -0.026103990152478218,
            -0.00026426583644934,
            -0.014503342099487782,
            -0.01061613205820322,
            0.003572058165445924,
            0.026117078959941864,
            0.03096708282828331,
            -0.04129895195364952,
            -0.010175333358347416,
            0.023657824844121933,
            -0.009719548746943474,
            -0.005295570008456707,
            0.011765949428081512,
            -0.003849789034575224,
            -0.016540614888072014,
            0.011737703345716,
            -0.028673436492681503,
            0.022947585210204124,
            -0.024156251922249794,
            0.03742028400301933,
            -0.0008192681125365198,
            0.01786547526717186,
            0.0006377774989232421,
            -0.0026082589756697416,
            -0.03944837674498558,
            0.040742143988609314,
            -0.008013070560991764,
            0.014934555627405643,
            0.020689133554697037,
            -0.008033458143472672,
            0.0163302980363369,
            -0.0018724899273365736,
            0.01959715411067009,
            -0.0034831431694328785,
            -0.024096643552184105,
            -0.018230192363262177,
            0.01608346402645111,
            -0.013804049231112003,
            0.012333821505308151,
            0.010131299495697021,
            -0.014391791075468063,
            0.008981406688690186,
            -0.02426828257739544,
            0.00046858034329488873,
            -0.03789550065994263,
            -0.015091554261744022,
            -0.025865834206342697,
            -0.06223492696881294,
            -0.01790022850036621,
            -0.024347631260752678,
            0.026424281299114227,
            -0.024530868977308273,
            0.0027545318007469177,
            -0.022286396473646164,
            0.004554092418402433,
            0.020580383017659187,
            0.014085165224969387,
            0.009000111371278763,
            -0.03210509940981865,
            -0.05493110418319702,
            0.012610060162842274,
            0.02453097701072693,
            0.002303927903994918,
            -0.03422758728265762,
            -0.011756784282624722,
            -0.007258884608745575,
            0.031351037323474884,
            0.04044688865542412,
            0.000726998143363744,
            -0.022243544459342957,
            0.006340072490274906,
            0.02086326852440834,
            0.018633440136909485,
            -0.05450033023953438,
            0.02375292032957077,
            0.021970659494400024,
            0.008169718086719513,
            0.031089404597878456,
            0.022118812426924706,
            -0.00047041609650477767,
            0.030587445944547653,
            -0.019824083894491196,
            0.024563966318964958,
            0.013218016363680363,
            -0.014025677926838398,
            0.015346943400800228,
            0.04401779919862747,
            0.028172923251986504,
            0.008297611959278584,
            -0.036544669419527054,
            -0.028259631246328354,
            0.034130409359931946,
            0.0364377535879612,
            -0.0324886329472065,
            -0.005282219499349594,
            0.006151372101157904,
            -0.0893307775259018,
            -0.014329513534903526,
            0.011028768494725227,
            -0.05905161425471306,
            -0.015955496579408646,
            0.023387480527162552,
            0.018057530745863914,
            -0.00472662691026926,
            0.023181263357400894,
            0.017631355673074722,
            -0.001085403491742909,
            0.008704742416739464,
            0.027292687445878983,
            0.01659434288740158,
            -0.004801303613930941,
            0.03451354056596756,
            -0.03789186850190163,
            -0.0056985365226864815,
            0.014291954226791859,
            -0.028417516499757767,
            -0.029431845992803574,
            0.007475291378796101,
            -0.03558491915464401,
            0.046011313796043396,
            0.006533478852361441,
            -0.021899070590734482,
            0.00015891529619693756,
            0.03102816455066204,
            -0.03624811768531799,
            0.04347524791955948,
            0.018885282799601555,
            0.03613649308681488,
            -0.006289086304605007,
            0.001559844589792192,
            -0.04059404879808426,
            -0.002767665311694145,
            0.030858708545565605,
            0.03816157206892967,
            -0.023121921345591545,
            -0.026121389120817184,
            -0.03027581423521042,
            0.0027636357117444277,
            -0.010094561614096165,
            -0.01856980472803116,
            0.011567164212465286,
            0.01241373736411333,
            -0.014847253449261189,
            -0.03490259125828743,
            0.027404718101024628,
            -0.01963278092443943,
            0.012127700261771679,
            0.011922375299036503,
            -0.009976509027183056,
            0.01710566319525242,
            0.0012106542708352208,
            0.03613860532641411,
            -0.003286538412794471,
            0.04067553952336311,
            -0.005345952231436968,
            -0.05015282332897186,
            -0.06812702119350433,
            -0.006660123355686665,
            0.027173392474651337,
            0.030839676037430763,
            0.020372876897454262,
            -9.863447485258803e-05,
            0.006884565576910973,
            -0.018978705629706383,
            0.011127760633826256,
            -0.005892791319638491,
            -0.033369336277246475,
            0.014968701638281345,
            -0.00034526613308116794,
            0.005783935077488422,
            0.008273636922240257,
            -0.017199682071805,
            0.006176115944981575,
            0.03801264241337776,
            -0.041928861290216446,
            -0.022225985303521156,
            -0.04444180056452751,
            0.041259393095970154,
            -0.01886281929910183,
            -0.010748157277703285,
            -0.008801816962659359,
            0.0005082194111309946,
            -0.01294560544192791,
            -0.003212472191080451,
            0.023659132421016693,
            -0.009733312763273716,
            -0.03267940506339073,
            0.006857577711343765,
            0.024090737104415894,
            0.008056356571614742,
            0.03141866996884346,
            -0.02568628266453743,
            -0.019740544259548187,
            0.030523577705025673,
            0.012447693385183811,
            0.02550702728331089,
            0.03834407031536102,
            -0.02614411525428295,
            -0.014801492914557457,
            -0.023171480745077133,
            -0.005787612870335579,
            -0.007881779223680496,
            -0.03287140280008316,
            -0.006064270623028278,
            0.001457846025004983,
            -0.00917415414005518,
            0.006644290406256914,
            -0.02944829687476158,
            0.017528345808386803,
            0.02018849365413189,
            0.020777186378836632,
            0.01339248288422823,
            0.02424214594066143,
            0.000814940023701638,
            -0.020996268838644028,
            0.007998607121407986,
            -0.037035323679447174,
            0.00048843800323084,
            -0.01911078952252865,
            0.037592463195323944,
            0.021674253046512604,
            0.03111857734620571,
            -0.03044642135500908,
            -0.014262600801885128,
            0.007795354817062616,
            0.005503332708030939,
            -0.04120592400431633,
            0.033809270709753036,
            -0.02262907102704048,
            -0.0028168789576739073,
            0.025043874979019165,
            -0.020650899037718773,
            -0.012642721645534039,
            0.009431907907128334,
            0.013405133038759232,
            -0.021542491391301155,
            0.032174546271562576,
            -0.025135010480880737,
            -0.026374798268079758,
            -0.007564647123217583,
            -0.02228870429098606,
            -0.011239123530685902,
            0.03189527615904808,
            -0.022816257551312447,
            -0.04003665968775749,
            -0.027288256213068962,
            0.013452103361487389,
            0.01369768287986517,
            0.0330175943672657,
            -0.02962314337491989,
            -0.016880279406905174,
            0.0028883798513561487,
            0.010196051560342312,
            0.004109048284590244,
            -0.03766840323805809,
            -0.004812106490135193,
            0.02249981462955475,
            -0.02501254342496395,
            -0.01830480992794037,
            0.03547784686088562,
            -0.06803389638662338,
            0.018787842243909836,
            0.040591973811388016,
            -0.014089158736169338,
            -0.01101431343704462,
            -0.013289377093315125,
            -0.014884410426020622,
            0.025079891085624695,
            -0.012286197394132614,
            -0.03482409566640854,
            0.033287230879068375,
            -0.01208756398409605,
            0.015912164002656937,
            -0.04300178587436676,
            0.016300896182656288,
            -0.0389152355492115,
            -0.03158382698893547,
            0.014633788727223873,
            0.041359495371580124,
            0.06215425953269005,
            0.02486082725226879,
            -0.07220754772424698,
            0.014825188554823399,
            -0.01979275606572628,
            0.006461793091148138,
            -0.015884174033999443,
            -0.04037797451019287,
            0.014652600511908531,
            -0.03368280455470085,
            0.011838559992611408,
            0.008153224363923073,
            0.015714146196842194,
            -0.027349751442670822,
            0.024438505992293358,
            0.02421189285814762,
            0.02094523049890995,
            -0.006990897003561258,
            0.0031025444623082876,
            -0.032559964805841446,
            -0.0014039998641237617,
            -0.029803823679685593,
            -0.0026619925629347563,
            -0.022458067163825035,
            0.03141132742166519,
            -0.05616604909300804,
            0.025365110486745834,
            -0.029445014894008636,
            0.0018765729619190097,
            -0.02730468101799488,
            0.03776010498404503,
            0.027966564521193504,
            -0.032403554767370224,
            -0.01057219784706831,
            -0.020033452659845352,
            0.0466829277575016,
            -0.011980371549725533,
            0.021565426141023636,
            -0.004154072143137455,
            0.044592056423425674,
            -0.013099812902510166,
            -0.0030604046769440174,
            -0.007360115181654692,
            0.00222330866381526,
            -0.01494909729808569,
            0.03222725912928581,
            -0.014032820239663124,
            0.01924784667789936,
            -0.017269914969801903,
            -0.03330663591623306,
            0.0028284357395023108,
            0.024560321122407913,
            0.03492037579417229,
            0.0246739499270916,
            0.02160513959825039,
            -0.013114294968545437,
            -0.0018970564706251025,
            0.027273114770650864,
            0.0023962990380823612,
            0.018963279202580452,
            -0.01769387349486351,
            0.004645033273845911,
            0.06330175697803497,
            0.005305216647684574,
            0.043364692479372025,
            0.0023604377638548613,
            -0.027506239712238312,
            -0.023627232760190964,
            0.010605060495436192,
            -0.01110872533172369,
            0.023230819031596184,
            0.014379623346030712,
            0.010304322466254234,
            0.023800039663910866,
            0.021134471520781517,
            0.021504303440451622,
            -0.004756732378154993,
            0.03935479000210762,
            -0.00026273090043105185,
            -0.03807477280497551,
            0.023283353075385094,
            0.006806114688515663,
            0.009213756769895554,
            -0.007135261315852404,
            -0.006909854244440794,
            -0.010476920753717422,
            0.006332810502499342,
            0.017001543194055557,
            -0.011860333383083344,
            -0.043220918625593185,
            0.01178783643990755,
            0.01711330935359001,
            0.020908083766698837,
            0.004202635493129492,
            -0.00042630365351215005,
            -0.008563327603042126,
            -0.001303158700466156,
            -0.03202969953417778,
            -0.021616263315081596,
            0.021957451477646828,
            0.01930023543536663,
            -0.009665295481681824,
            0.027846088632941246,
            0.044740479439496994,
            0.012777052819728851,
            0.007770264986902475,
            0.013836822472512722,
            0.0028097887989133596,
            -0.02834981121122837,
            0.01219457108527422,
            0.0027367803268134594,
            0.009362050332129002,
            -0.023248372599482536,
            -0.02543085813522339,
            0.0002818887878675014,
            0.0005768233677372336,
            0.011014727875590324,
            -0.01772397756576538,
            0.00291061052121222,
            0.011865840293467045,
            -0.010747089050710201,
            -0.015768080949783325,
            0.009412665851414204,
            -0.0037241827230900526,
            -0.012466385960578918,
            -0.01612515188753605,
            -0.03085283376276493,
            0.014567731879651546,
            -0.0004758714640047401,
            -0.04662436991930008,
            0.021904993802309036,
            -0.002529454417526722,
            0.009022885002195835,
            0.021009104326367378,
            -0.006292552687227726,
            0.028233734890818596,
            -0.0005326973623596132,
            0.0089966906234622,
            -0.008255984634160995,
            -0.022277895361185074,
            0.028226064518094063,
            -0.06425551325082779,
            -0.027362456545233727,
            0.021224496886134148,
            -0.010582217946648598,
            0.003627464175224304,
            -0.01809561625123024,
            -0.042757462710142136,
            0.015533926896750927,
            -0.01819894276559353,
            -0.009864769876003265,
            0.010124093852937222,
            -0.0006260962109081447,
            0.02169826067984104,
            0.00971347838640213,
            0.020480193197727203,
            0.011979322880506516,
            -0.0010778619907796383,
            0.03504510223865509,
            0.00892054382711649,
            -0.023807864636182785,
            0.003123106202110648,
            0.024958373978734016,
            0.024079719558358192,
            0.04386197403073311,
            -0.03633899614214897,
            0.02082216367125511,
            0.0034392543602734804,
            -0.01885032467544079,
            0.007717732340097427,
            0.017888812348246574,
            0.014290927909314632,
            -0.012662344612181187,
            -0.0037627818528562784,
            0.003953352104872465,
            -0.010968489572405815,
            -0.030630987137556076,
            0.01747341826558113,
            -0.015361273661255836,
            -0.002062213607132435,
            0.011663726530969143,
            0.028433196246623993,
            -0.032816678285598755,
            0.0002861361135728657,
            0.025182414799928665,
            0.025728069245815277,
            -0.01935652457177639,
            -0.026197047904133797,
            0.006064240355044603,
            0.0009272540337406099,
            -0.05032967031002045,
            0.004477257840335369,
            -0.023261338472366333,
            -0.03816748410463333,
            0.006426478270441294,
            -0.011798946186900139,
            -0.013936907052993774,
            0.040546245872974396,
            0.0029937124345451593,
            -0.03291458263993263,
            0.007783459033817053,
            0.027747057378292084,
            0.01828518696129322,
            0.012306387536227703,
            0.01198245957493782,
            0.0019851308315992355,
            -0.020473577082157135,
            0.01279990840703249,
            -0.01420189905911684,
            -0.026120789349079132,
            0.0008436805801466107,
            -0.027668124064803123,
            -0.01583773083984852,
            -0.008746575564146042,
            0.020539959892630577,
            -0.006562402471899986,
            -0.03120771050453186,
            -0.021400298923254013,
            0.01341366209089756,
            -0.032770849764347076,
            -0.01458571758121252,
            0.0036845749709755182,
            -0.021764518693089485,
            -0.011259213089942932,
            0.045742571353912354,
            -0.016378946602344513,
            0.0068518840707838535,
            0.01695956289768219,
            -0.023300843313336372,
            -0.0033410335890948772,
            0.024205656722187996,
            -0.010357030667364597,
            -0.03314550593495369,
            -0.0026907753199338913,
            -0.0008204277837648988,
            -0.02080426923930645,
            0.006817630957812071,
            -0.016224555671215057,
            0.02171752043068409,
            0.019943315535783768,
            0.023527858778834343,
            0.01276811957359314,
            0.0007316124974749982,
            0.02925565466284752,
            -0.0050465986132621765,
            -0.020688047632575035,
            -0.016285758465528488,
            0.027185827493667603,
            0.01661045476794243,
            -0.03267837315797806,
            0.025298286229372025,
            0.009367217309772968,
            0.009707583114504814,
            0.022080140188336372,
            -0.01140645146369934,
            0.035564858466386795,
            -0.03537135571241379,
            -0.0712440088391304,
            -0.03719845414161682,
            -0.021151484921574593,
            0.026583457365632057,
            0.024849900975823402,
            0.0037640666123479605,
            -0.002919756108894944,
            -0.015543279238045216,
            -0.001135901897214353,
            -0.010559504851698875,
            0.03422635421156883,
            0.006350125186145306,
            0.001651302445679903,
            0.028026588261127472,
            -0.04845578968524933,
            -0.024561401456594467,
            0.02135559916496277,
            0.047772474586963654,
            -0.029760776087641716,
            -0.024732040241360664,
            0.009352171793580055,
            -0.029792482033371925,
            -0.03875046595931053,
            -0.004707532003521919,
            -0.009567623026669025,
            -0.0129666393622756,
            -0.018720928579568863,
            -0.013287614099681377,
            0.04333987459540367,
            0.002468331716954708,
            0.02639012038707733,
            -0.0031693344935774803,
            0.020165586844086647,
            0.04924941062927246,
            -0.005893261171877384,
            -0.019893202930688858,
            -0.011439641006290913,
            -0.011454627849161625,
            0.07654520124197006,
            0.004730385262519121,
            -0.011866975575685501,
            -0.0278836227953434,
            0.03275837004184723,
            0.007360559422522783,
            0.009003844112157822,
            -0.02129298262298107,
            0.035836361348629,
            -0.05396665260195732,
            -0.012386592105031013,
            -0.022000953555107117,
            -0.007227939087897539,
            0.007819393649697304,
            -0.00871417298913002,
            0.014373967424035072,
            0.025152776390314102,
            0.04160075634717941,
            0.005068042781203985,
            -0.005767494440078735,
            0.01838505081832409,
            -0.050464365631341934,
            -0.018565567210316658,
            -0.018537526950240135,
            0.022622711956501007,
            -0.027670439332723618,
            0.0018725389381870627,
            -0.028572360053658485,
            0.03370259702205658,
            -0.017978372052311897,
            0.019214821979403496,
            -0.019988318905234337,
            0.026445617899298668,
            -0.011952285654842854,
            -0.022227661684155464,
            -0.017690667882561684,
            0.0252180527895689,
            0.009651378728449345,
            -0.028123153373599052,
            0.009770932607352734,
            0.01042357087135315,
            -0.027742469683289528,
            0.02740410529077053,
            -0.03724610432982445,
            0.01917153410613537,
            0.036708418279886246,
            0.0185259897261858,
            -0.030932266265153885,
            -3.802148421527818e-05,
            0.011461714282631874,
            -0.01273473259061575,
            -0.02081950753927231,
            -0.013814391568303108,
            0.02326556295156479,
            0.016292599961161613,
            0.030426746234297752,
            -0.03043326362967491,
            -0.01853196881711483,
            0.011616939678788185,
            -0.014454895630478859,
            0.03080141544342041,
            -0.024956468492746353,
            0.01713014766573906,
            -0.02550840936601162,
            -0.002151412656530738,
            0.017189832404255867,
            -0.06796020269393921,
            -0.009246348403394222,
            -0.01322115771472454,
            -0.012560996226966381,
            0.028361473232507706,
            -0.03335043042898178,
            -0.02404259517788887,
            -0.000367345375707373,
            -0.02023330144584179,
            0.029410414397716522,
            -0.00873342715203762,
            0.04294165223836899,
            -0.005472551099956036,
            -0.006155271083116531,
            -0.00514936214312911,
            -0.003540709847584367,
            0.027097932994365692,
            -0.02792045846581459,
            -0.018314814195036888,
            -0.07710779458284378,
            -0.049976058304309845,
            -0.020480846986174583,
            -0.026798928156495094,
            0.007508684881031513,
            0.042295191437006,
            -0.01939341053366661,
            0.017975803464651108,
            -0.05977402254939079,
            0.025543034076690674,
            -0.030154094099998474,
            0.024380961433053017,
            -0.03307372331619263,
            -0.018373429775238037,
            0.0012496588751673698,
            0.01165059395134449,
            -0.021177494898438454,
            0.016248978674411774,
            0.017364967614412308,
            -0.006734839174896479,
            0.018791288137435913,
            -0.014970961026847363,
            0.0065359254367649555,
            0.01931140013039112,
            0.01198984682559967,
            0.017892904579639435,
            0.016883300617337227,
            0.022200511768460274,
            0.012393524870276451,
            -0.01899545080959797,
            0.028586676344275475,
            0.009197973646223545,
            0.002268255455419421,
            -0.020961944013834,
            -0.0007456440944224596,
            0.019054606556892395,
            0.041795872151851654,
            0.014973110519349575,
            -0.05751295015215874,
            -0.0627777948975563,
            -0.0016950062708929181,
            0.013974889181554317,
            0.0070307254791259766,
            0.0220694188028574,
            -0.011424760334193707,
            0.03707990422844887,
            -0.005335608031600714,
            0.007358558010309935,
            -0.004133229609578848,
            0.018691411241889,
            -0.023403316736221313,
            0.02249380573630333,
            0.012987525202333927,
            -0.0062337517738342285,
            0.009361129254102707,
            -0.02984721213579178,
            0.027808107435703278,
            0.008846009150147438,
            0.03118503838777542,
            0.030875615775585175,
            -0.044342461973428726,
            -0.010920148342847824,
            0.02031254954636097,
            0.017553329467773438,
            0.016279373317956924,
            0.00022250672918744385,
            -0.0072226026095449924,
            -0.06927026808261871,
            -0.023867597803473473,
            -0.024623291566967964,
            0.014792492613196373,
            0.02310432307422161,
            -0.0019759556744247675,
            0.003403439186513424,
            -0.0105484239757061,
            -0.018616629764437675,
            -0.009376492351293564,
            -0.00986436102539301,
            0.04496872425079346,
            -0.01858966425061226,
            -0.00021586632647085935,
            -0.007999652065336704,
            -0.028207935392856598,
            -0.0066985138691961765,
            -0.006674444302916527,
            0.019930867478251457,
            0.003256280906498432,
            0.012152805924415588,
            0.011182363145053387,
            0.038558848202228546,
            -0.03512690216302872,
            -0.009390211664140224,
            0.010669859126210213,
            0.011441663838922977,
            0.0076014334335923195,
            0.03363887965679169,
            0.015180418267846107,
            -0.005933895241469145,
            -0.00281412317417562,
            -0.01221549604088068,
            -0.026597583666443825,
            0.02052237279713154,
            0.027870120480656624,
            -0.03075939230620861,
            -0.04056708514690399,
            0.03039207123219967,
            -0.02836626209318638,
            -0.010409001260995865,
            0.01970672979950905,
            0.003998043946921825,
            0.021162278950214386,
            -0.0018603055505082011,
            -0.04344622418284416,
            0.014730324037373066,
            0.017817484214901924,
            0.033295270055532455,
            -0.02693329006433487,
            -0.014963164925575256,
            0.005307701416313648,
            -0.0008047163137234747,
            -0.000646277389023453,
            -0.009283025749027729,
            0.0569981187582016,
            -0.01757832057774067,
            0.012131030671298504,
            -0.011168069206178188,
            -0.032619643956422806,
            -0.03628374636173248,
            -0.033264681696891785,
            0.02033151127398014,
            -0.02269909158349037,
            -0.004900431726127863,
            0.012640025466680527,
            -0.0012717327335849404,
            -0.016951434314250946,
            -0.026155799627304077,
            -0.002532101469114423,
            0.010053747333586216,
            0.02622220292687416,
            -0.005306176841259003,
            0.008782342076301575,
            -0.03019315004348755,
            -0.004681683611124754,
            -0.0006463142926804721,
            -0.010723646730184555,
            0.015302937477827072,
            0.001078120432794094,
            -0.022166140377521515,
            -0.005921447649598122,
            0.01911010779440403,
            0.010998268611729145,
            0.02839195728302002,
            0.009268931113183498,
            0.01721242070198059,
            0.024445801973342896,
            0.003824835643172264,
            0.022904926910996437,
            -0.007510647177696228,
            0.02636771835386753,
            -0.009476864710450172,
            -0.032613832503557205,
            0.022931715473532677,
            -0.0009112433181144297,
            0.013519649393856525,
            -0.029697472229599953,
            -0.001362401177175343,
            0.026545450091362,
            -0.004467721097171307,
            0.005813215393573046,
            0.011329475790262222,
            0.007960952818393707,
            0.029555149376392365,
            0.03130664676427841,
            -0.01205289177596569,
            0.001586145139299333,
            -0.016446543857455254,
            0.016987081617116928,
            -0.03227836266160011,
            -0.03188367933034897,
            -0.02262931503355503,
            -0.025249630212783813,
            -0.027845952659845352,
            0.025795109570026398,
            -0.017095761373639107,
            0.0063819559291005135,
            -0.031812313944101334,
            0.0286731980741024,
            -0.02917078696191311,
            0.010794078931212425,
            0.010927494615316391,
            0.029018690809607506,
            0.025213614106178284,
            -0.01022598147392273,
            0.03782612457871437,
            -0.008173356764018536,
            0.010346935130655766,
            -0.0014979385305196047,
            -0.01823021098971367,
            0.021794959902763367,
            -0.0022590269800275564,
            -0.009158608503639698,
            -3.52361639670562e-05,
            -0.003886043094098568,
            -0.004401401616632938,
            -0.0210283063352108,
            0.014527571387588978,
            -0.004364230670034885,
            0.02409842237830162,
            0.01289011538028717,
            0.028683988377451897,
            -0.019507434219121933,
            -0.014834805391728878,
            -0.004373122937977314,
            -0.0017319755861535668,
            -0.004781203810125589,
            -0.025998834520578384,
            -0.01626479998230934,
            -0.043003834784030914,
            0.008179149590432644,
            0.018494900315999985,
            0.011736854910850525,
            -0.007634657435119152,
            -0.0057547083124518394,
            0.0004994135815650225,
            -0.024449849501252174,
            0.021595392376184464,
            0.027965497225522995,
            -0.039637934416532516,
            0.010004977695643902,
            0.02473982237279415,
            -0.06450100988149643,
            -0.03771975636482239,
            -0.004085961263626814,
            -0.007951010018587112,
            0.027267862111330032,
            -0.025362703949213028,
            -0.031085524708032608,
            -0.027656396850943565,
            -0.014364556409418583,
            0.023205993697047234,
            -0.006776129361242056,
            -0.008591379970312119,
            0.0296147633343935,
            0.0022479770705103874,
            0.009527630172669888,
            -0.03718218579888344,
            0.03320818394422531,
            -0.037959713488817215,
            0.03860672935843468,
            -0.009028160013258457,
            -0.017819438129663467,
            0.012751483358442783,
            -0.00878342054784298,
            -0.013107972219586372,
            -0.03008911944925785,
            0.042260169982910156,
            0.04558081924915314,
            0.03213086351752281,
            0.004282988142222166
        ],
        [
            0.006723401136696339,
            0.01663854531943798,
            -0.01394868828356266,
            0.03455980494618416,
            0.014822226017713547,
            0.049127884209156036,
            -0.03606804087758064,
            -0.02639024518430233,
            -0.028923461213707924,
            0.009781579487025738,
            0.007863562554121017,
            -0.018099138513207436,
            -0.016683116555213928,
            -0.012158408761024475,
            -0.018903128802776337,
            0.06499164551496506,
            0.010324354283511639,
            -0.012795652262866497,
            -0.01742575503885746,
            -0.019294532015919685,
            -0.011580784805119038,
            0.008236773312091827,
            -0.046143192797899246,
            0.014566522091627121,
            -0.02304738014936447,
            0.008964424021542072,
            -0.04952176287770271,
            -0.024518998339772224,
            0.025782505050301552,
            0.03732593357563019,
            0.0037667544092983007,
            -0.008730821311473846,
            -0.023547012358903885,
            0.002475737128406763,
            0.027357114478945732,
            -0.02900717593729496,
            0.014498489908874035,
            -0.030428437516093254,
            0.00741462130099535,
            -0.00032664387254044414,
            6.289448356255889e-05,
            0.011864732019603252,
            -0.006155769340693951,
            -0.024886054918169975,
            -0.025006206706166267,
            -0.0035385240335017443,
            -0.0012558178277686238,
            0.0068682716228067875,
            -0.0005772251170128584,
            0.041839905083179474,
            -0.04266221448779106,
            -0.0020562498830258846,
            0.05996846780180931,
            0.0456438846886158,
            -0.012022875249385834,
            -0.034441325813531876,
            0.00021050262148492038,
            0.0014056384097784758,
            -0.04031253233551979,
            0.03749579191207886,
            -0.04739682003855705,
            -0.009905249811708927,
            -0.010038524866104126,
            -0.011941451579332352,
            -0.02217511087656021,
            -0.000180525123141706,
            -0.004627884831279516,
            -0.021912824362516403,
            0.011385439895093441,
            0.02143017202615738,
            -0.030453991144895554,
            -0.020021555945277214,
            0.013175141997635365,
            0.021875444799661636,
            0.001839923090301454,
            0.007281527854502201,
            -0.001328003709204495,
            -0.03153011575341225,
            -0.023911340162158012,
            -0.016383517533540726,
            0.005884532351046801,
            0.0385596789419651,
            0.029478829354047775,
            0.02373134158551693,
            -0.03762923553586006,
            0.02454744279384613,
            0.031845297664403915,
            -0.019144313409924507,
            0.016208890825510025,
            -0.03299254551529884,
            0.004482391756027937,
            0.0204653088003397,
            0.04759349673986435,
            -0.007442656438797712,
            -0.00630986038595438,
            -0.010094838216900826,
            -0.010465601459145546,
            -0.0002670308167580515,
            0.05426530912518501,
            0.010610292665660381,
            0.006468578241765499,
            -0.024695754051208496,
            -0.04588654637336731,
            0.013695953413844109,
            0.016006017103791237,
            -0.010265257209539413,
            -0.008863911963999271,
            0.008960247039794922,
            -0.030840972438454628,
            -0.004213436972349882,
            0.007468670140951872,
            -0.0007682797149755061,
            0.020831352099776268,
            -0.018333302810788155,
            0.01140396948903799,
            -0.0032439057249575853,
            -0.007444850634783506,
            0.004156847484409809,
            -0.03496403619647026,
            -0.006756803020834923,
            -0.02170412801206112,
            0.010062367655336857,
            -0.08179651945829391,
            -0.011084038764238358,
            0.004979812540113926,
            0.019577855244278908,
            -0.08080065250396729,
            0.02603168599307537,
            -0.021583063527941704,
            0.030126264318823814,
            0.02395918034017086,
            0.02664552442729473,
            -0.05845479294657707,
            0.010309902019798756,
            0.033586811274290085,
            0.017604894936084747,
            -0.004495267290621996,
            -0.05376359447836876,
            0.043045688420534134,
            -0.001606001635082066,
            -0.024900225922465324,
            -0.023872077465057373,
            0.009421629831194878,
            0.014302879571914673,
            -0.021645428612828255,
            -0.013020409271121025,
            -0.02685617096722126,
            0.0014298014575615525,
            0.004280677530914545,
            0.0340365394949913,
            -0.007051609456539154,
            0.020308056846261024,
            -0.02997714653611183,
            -0.006893013138324022,
            -0.016063308343291283,
            -0.021586274728178978,
            -0.017948176711797714,
            -0.032045211642980576,
            0.00486135995015502,
            -0.02849649451673031,
            0.010512414388358593,
            -0.02023715153336525,
            0.027852341532707214,
            -0.014741899445652962,
            0.012080885469913483,
            -0.06569602340459824,
            -0.043338023126125336,
            -0.012014265172183514,
            0.0075974357314407825,
            0.015587449073791504,
            0.0012659126659855247,
            0.011193478479981422,
            0.012936889193952084,
            0.00806546863168478,
            0.0005691436817869544,
            0.019691219553351402,
            -0.007200289983302355,
            -0.00847592856734991,
            -0.06639924645423889,
            0.02050868235528469,
            0.013121125288307667,
            0.021714020520448685,
            -0.0073972437530756,
            -0.01844819076359272,
            -0.01386320497840643,
            0.010182714089751244,
            -0.017734413966536522,
            0.007016156334429979,
            0.02049027383327484,
            0.01962810382246971,
            -0.03591613844037056,
            0.005123076029121876,
            0.004372115712612867,
            0.03216854855418205,
            0.01667248271405697,
            -0.005453771445900202,
            0.006033697165548801,
            0.028268497437238693,
            -0.005851327907294035,
            0.021723251789808273,
            0.011948252096772194,
            0.021993178874254227,
            0.023224234580993652,
            0.012791263870894909,
            -0.006852437742054462,
            -0.010609597899019718,
            -0.021807119250297546,
            -0.00018210058624390513,
            0.011767296120524406,
            -0.0369277261197567,
            -0.025006625801324844,
            -0.00024441833375021815,
            0.045680008828639984,
            -0.039388008415699005,
            0.024518629536032677,
            0.025363611057400703,
            0.002418851712718606,
            -0.014262483455240726,
            0.04076730087399483,
            -0.0002607023052405566,
            0.03654618188738823,
            0.017747273668646812,
            0.03606269881129265,
            0.018632082268595695,
            0.009426274336874485,
            0.0014383114175871015,
            -0.011140348389744759,
            -0.011863155290484428,
            0.04284931719303131,
            -0.013409444130957127,
            -0.004706484265625477,
            0.008620013482868671,
            0.030926048755645752,
            0.0021775788627564907,
            -0.02158326841890812,
            -0.0015209295088425279,
            -0.02009277045726776,
            -0.03136420249938965,
            -0.0223658736795187,
            0.00719717051833868,
            -0.011022266931831837,
            -0.008571228012442589,
            -0.02645794115960598,
            0.01709038019180298,
            -0.011231912299990654,
            0.009407509118318558,
            0.018807202577590942,
            0.03474721685051918,
            0.008281025104224682,
            -0.022278016433119774,
            -0.013892538845539093,
            -0.017169881612062454,
            -0.03882398083806038,
            -0.01801207661628723,
            -0.0023509287275373936,
            -0.0022346549667418003,
            -0.02025655470788479,
            0.00401703454554081,
            -0.008048337884247303,
            -0.006590586155653,
            0.0038853187579661608,
            -0.05745546519756317,
            -0.03893702104687691,
            -0.030172716826200485,
            -0.0455034039914608,
            -0.01285423431545496,
            0.02384992316365242,
            -0.029829444363713264,
            -0.035331737250089645,
            -0.03502564877271652,
            -0.0040405807085335255,
            -0.049702245742082596,
            0.0027198411989957094,
            0.006745426915585995,
            -0.015468630939722061,
            -0.020779289305210114,
            0.029320094734430313,
            -0.00017291078984271735,
            0.011544578708708286,
            0.019123010337352753,
            -0.004786965902894735,
            0.006256505381315947,
            -0.030994074419140816,
            0.019297461956739426,
            0.025109417736530304,
            -0.008306200616061687,
            0.01648644730448723,
            -0.007195091340690851,
            -0.004879883956164122,
            0.008065312169492245,
            0.026866935193538666,
            0.007763958070427179,
            -0.022114252671599388,
            -0.001534080132842064,
            0.02089199610054493,
            -0.007361745461821556,
            0.0067542679607868195,
            0.021240346133708954,
            0.009483661502599716,
            -0.0035944574046880007,
            -0.018155626952648163,
            0.03224290907382965,
            -0.015778079628944397,
            -0.013709002174437046,
            0.003981574438512325,
            -0.020518410950899124,
            -0.0075548626482486725,
            0.0025618686340749264,
            0.0356166809797287,
            0.04803185164928436,
            0.006104644853621721,
            -0.008692021481692791,
            0.009853802621364594,
            -0.028769386932253838,
            0.011394641362130642,
            -0.009280502796173096,
            -0.009443876333534718,
            -0.01312263123691082,
            0.026913536712527275,
            -0.007105167955160141,
            0.0353897400200367,
            0.05282103642821312,
            0.01620735414326191,
            0.002450257074087858,
            0.018279151991009712,
            -0.01794123277068138,
            0.005195044446736574,
            -0.012267941609025002,
            -0.01161595992743969,
            0.007477120496332645,
            -0.01598731428384781,
            0.013681866228580475,
            -0.036080606281757355,
            -0.023085955530405045,
            0.01233773771673441,
            -0.02585541270673275,
            -0.00578649528324604,
            0.018551452085375786,
            -0.018805786967277527,
            0.0015977676957845688,
            0.011371037922799587,
            -0.008606812916696072,
            0.023038918152451515,
            -0.010976489633321762,
            -0.0005456882063299417,
            -0.004525071009993553,
            0.016663281247019768,
            0.015065964311361313,
            -0.01817019283771515,
            -0.04614832252264023,
            0.002880352083593607,
            0.042891718447208405,
            0.008228142745792866,
            0.006246041972190142,
            -0.008573049679398537,
            -0.01705419272184372,
            -0.019164767116308212,
            -0.02117060124874115,
            -0.005243368912488222,
            -0.014973885379731655,
            0.009762084111571312,
            0.018074121326208115,
            -0.027335207909345627,
            -0.005558877717703581,
            0.011098193004727364,
            -0.001754656550474465,
            -0.018814634531736374,
            -0.04570508375763893,
            -0.008931297808885574,
            0.007005815394222736,
            0.024822603911161423,
            -0.0062168133445084095,
            -0.053343724459409714,
            0.018136467784643173,
            0.0020760633051395416,
            -0.008059038780629635,
            -0.0038553986232727766,
            -0.006334570236504078,
            -0.0035144337452948093,
            0.0015228580450639129,
            -0.004383498802781105,
            0.017237437888979912,
            0.012192098423838615,
            -0.004972942639142275,
            0.01152204629033804,
            0.08544107526540756,
            0.005226694047451019,
            0.008136855438351631,
            0.02829807996749878,
            0.01126823853701353,
            -0.019609885290265083,
            -0.03935006260871887,
            0.016940101981163025,
            0.01813788153231144,
            -0.03710096701979637,
            -0.009295432828366756,
            -0.02247115597128868,
            0.0117472093552351,
            0.0033189193345606327,
            -0.0331849679350853,
            -0.004501051269471645,
            0.006959488615393639,
            0.0002143825258826837,
            0.028721218928694725,
            0.03277305141091347,
            -0.017112966626882553,
            -0.02407328598201275,
            0.03020213544368744,
            0.009346699342131615,
            0.005866608582437038,
            -0.005662414710968733,
            0.01812739111483097,
            0.010919530875980854,
            -0.023739846423268318,
            -0.04235633462667465,
            -0.017194200307130814,
            -0.0036169749218970537,
            0.03561947122216225,
            0.022855961695313454,
            0.013429982587695122,
            -0.08609291166067123,
            -0.05375443026423454,
            0.07248032093048096,
            -0.020649008452892303,
            -0.030949389562010765,
            -0.04417899623513222,
            -0.030362091958522797,
            -0.002438010647892952,
            -0.026381688192486763,
            -0.045642003417015076,
            -0.00862222258001566,
            -0.023579232394695282,
            0.0196150504052639,
            -0.017288167029619217,
            0.03816669434309006,
            0.05529669672250748,
            -0.019395746290683746,
            -0.03070242330431938,
            -0.04179885610938072,
            -0.0037469191011041403,
            0.016491061076521873,
            0.03648555651307106,
            0.024527594447135925,
            -0.02101290412247181,
            0.030654702335596085,
            0.02964516542851925,
            0.02497420459985733,
            0.05257999524474144,
            0.027978768572211266,
            -0.0013377366121858358,
            0.01667715422809124,
            0.006279696244746447,
            -0.08512229472398758,
            -0.00997579749673605,
            -0.030841844156384468,
            0.016801152378320694,
            0.001345998258329928,
            -0.0007999176159501076,
            -0.030051879584789276,
            0.01662161760032177,
            -0.031236734241247177,
            0.0318322591483593,
            -0.013322684913873672,
            0.028836887329816818,
            -0.0058851842768490314,
            -0.019770577549934387,
            0.006182777229696512,
            -0.030240699648857117,
            0.022772513329982758,
            -0.014661815017461777,
            -0.0415053628385067,
            -0.03504640609025955,
            0.003256503725424409,
            -0.008266746066510677,
            0.06122538074851036,
            0.017694098874926567,
            -0.0017101563280448318,
            -0.04162629693746567,
            -0.018590353429317474,
            0.02175840735435486,
            -0.0023651819210499525,
            -0.04407986253499985,
            -0.035142235457897186,
            0.002115016570314765,
            0.0022697588428854942,
            -0.014519982971251011,
            -0.020603056997060776,
            0.02920372039079666,
            -0.03016364946961403,
            -0.02073093317449093,
            0.030041294172406197,
            -0.011893167160451412,
            0.0237528458237648,
            -0.04108837991952896,
            0.04906632378697395,
            -0.00275422353297472,
            0.007058818358927965,
            -0.011144126765429974,
            0.0008374674944207072,
            -0.017878897488117218,
            0.027526341378688812,
            0.012747206725180149,
            0.012667547911405563,
            0.024499261751770973,
            0.034284915775060654,
            -0.012207011692225933,
            0.008248848840594292,
            -0.009212369099259377,
            0.007121770177036524,
            0.0036308136768639088,
            0.018310334533452988,
            -0.019749313592910767,
            -0.022916914895176888,
            -0.042234547436237335,
            -0.003796015167608857,
            -0.013507106341421604,
            0.008067975752055645,
            0.00016302258882205933,
            0.010900961235165596,
            -0.0033859023824334145,
            -0.00359726301394403,
            0.005889502819627523,
            0.011647628620266914,
            -0.049642667174339294,
            -0.03310346230864525,
            -0.05077280104160309,
            -0.012865355238318443,
            -0.026529652997851372,
            -0.019564740359783173,
            -0.03246564045548439,
            0.013796862214803696,
            -0.040773916989564896,
            0.005865843500941992,
            0.018846649676561356,
            0.003212813287973404,
            0.020524173974990845,
            0.02053731679916382,
            -0.007236115168780088,
            -0.03693375736474991,
            0.0327889546751976,
            -0.02607882209122181,
            -0.006583409383893013,
            0.00797201693058014,
            0.01115948986262083,
            -0.0219679344445467,
            -0.09388868510723114,
            0.006779202260077,
            0.022266648709774017,
            0.021187469363212585,
            0.041694797575473785,
            0.02775711938738823,
            0.007018604781478643,
            0.0011812644079327583,
            -0.006593791302293539,
            0.011282730847597122,
            0.019810348749160767,
            0.01964098960161209,
            -0.009352109394967556,
            0.019868919625878334,
            0.003962995018810034,
            -0.05126173794269562,
            0.0214060228317976,
            0.027991287410259247,
            0.016844594851136208,
            0.02471895143389702,
            -0.023815155029296875,
            0.006310957949608564,
            0.018657224252820015,
            -0.039578672498464584,
            0.010503600351512432,
            0.005612465552985668,
            0.00019318288832437247,
            -0.049990054219961166,
            0.010468740947544575,
            0.011720058508217335,
            -0.015015216544270515,
            0.04484662786126137,
            -0.03658851236104965,
            -0.05508936569094658,
            -0.04617376625537872,
            -0.008281886577606201,
            -0.04553593322634697,
            0.022775115445256233,
            0.03744867444038391,
            -0.05299684777855873,
            -0.01196084264665842,
            0.01265020202845335,
            0.00292735593393445,
            0.030719172209501266,
            0.030717885121703148,
            0.006950432434678078,
            -0.01806500181555748,
            -0.016131646931171417,
            -0.04889079928398132,
            0.015458750538527966,
            -0.0038286831695586443,
            0.00902028102427721,
            0.020247576758265495,
            0.004699379205703735,
            0.011877559125423431,
            -0.011466250754892826,
            -0.004252505488693714,
            0.01237513218075037,
            0.006758409086614847,
            -0.0418853797018528,
            -0.04021996259689331,
            0.012730267830193043,
            -0.004268926102668047,
            0.017301244661211967,
            -0.016210202127695084,
            -0.022443687543272972,
            -0.013925096951425076,
            -0.04213856905698776,
            -0.004010655451565981,
            -0.02218330092728138,
            -0.030100911855697632,
            0.01889030821621418,
            0.0159403458237648,
            -0.033204734325408936,
            0.01176520623266697,
            0.01987292990088463,
            -0.021903840824961662,
            5.170702934265137e-06,
            0.016535816714167595,
            0.019320642575621605,
            -0.0024289553984999657,
            0.014556231908500195,
            -0.01880374550819397,
            -0.012543551623821259,
            -0.00442927423864603,
            -0.006484749726951122,
            0.017268957570195198,
            0.015029861591756344,
            -0.003911012317985296,
            0.0286207664757967,
            -0.03829265758395195,
            -0.013768400065600872,
            -0.012803778983652592,
            0.033078331500291824,
            0.012162981554865837,
            0.03657558932900429,
            -0.032349634915590286,
            -0.037560366094112396,
            0.04905712231993675,
            0.01249002292752266,
            0.03652676194906235,
            0.061252411454916,
            0.008899710141122341,
            0.021090084686875343,
            -0.01747848279774189,
            -0.003567521693184972,
            -0.020212961360812187,
            0.009935607202351093,
            0.013674696907401085,
            -0.02512955851852894,
            -0.03201870247721672,
            0.014666607603430748,
            -0.01257374044507742,
            0.009779158979654312,
            0.02737182006239891,
            -0.013233370147645473,
            0.015385827049612999,
            0.009429749101400375,
            0.03614054620265961,
            0.02625892125070095,
            -0.015940964221954346,
            0.007319559343159199,
            0.005414521787315607,
            -0.04122164100408554,
            -0.008138390257954597,
            0.015943005681037903,
            -0.06007761135697365,
            -0.0401625894010067,
            0.0484243743121624,
            0.01951763406395912,
            0.04717687517404556,
            0.015507517382502556,
            0.027774928137660027,
            -0.009127937257289886,
            0.040179964154958725,
            0.02333398349583149,
            -0.03965989127755165,
            -0.0016950868302956223,
            -0.0024965344928205013,
            -0.029116734862327576,
            -0.040477026253938675,
            0.0029524879064410925,
            0.0054705156944692135,
            0.02178199402987957,
            -0.037119559943675995,
            0.028920255601406097,
            -0.04249773174524307,
            -0.0013861812185496092,
            -0.017322001978754997,
            -0.0014119776897132397,
            -0.03775153309106827,
            -0.008615106344223022,
            0.042588911950588226,
            -0.005649257451295853,
            -0.014654706232249737,
            0.013076025061309338,
            -0.0033611876424402,
            -0.039831213653087616,
            0.024671059101819992,
            0.014283140189945698,
            -0.009170044213533401,
            0.01043988112360239,
            -0.016124455258250237,
            -0.00620713084936142,
            -0.06784000247716904,
            -0.01595175452530384,
            -0.003988472744822502,
            -0.022340048104524612,
            0.0009616783354431391,
            -0.01852588728070259,
            -0.019765999168157578,
            -0.038111429661512375,
            0.024662256240844727,
            -0.025593694299459457,
            0.01261101197451353,
            -0.027834860607981682,
            0.020031293854117393,
            0.007306813262403011,
            0.03541214019060135,
            -0.014528036117553711,
            0.023078424856066704,
            -0.014087962917983532,
            0.030646001920104027,
            0.03699690103530884,
            -0.016144447028636932,
            -0.007902204990386963,
            0.02608569711446762,
            0.02307853288948536,
            -0.003438630374148488,
            -0.034645214676856995,
            -0.031095318496227264,
            -0.0057379137724637985,
            -0.003292286768555641,
            0.027994919568300247,
            -0.007144328206777573,
            -0.03206641227006912,
            0.0017433104803785682,
            0.02669474668800831,
            0.020673217251896858,
            -0.016187015920877457,
            0.007478098385035992,
            -0.05900134518742561,
            0.017305539920926094,
            -0.02764662355184555,
            -0.001313104759901762,
            -0.006850133650004864,
            0.016113566234707832,
            -0.011094529181718826,
            0.0037494783755391836,
            0.007702013943344355,
            0.015124200843274593,
            -0.028735406696796417,
            -0.020000094547867775,
            0.04802795499563217,
            -0.04583687335252762,
            0.01818283461034298,
            0.0192506592720747,
            0.015689225867390633,
            -0.006291447672992945,
            -0.003504960797727108,
            -0.018138591200113297,
            -0.018138214945793152,
            -0.030714187771081924,
            -0.015377133153378963,
            -0.013569210655987263,
            -0.00808621384203434,
            -0.016722651198506355,
            -0.013657202012836933,
            -0.03885164484381676,
            0.017425833269953728,
            0.00935364793986082,
            0.004074519500136375,
            0.014531473629176617,
            -0.010989552363753319,
            0.027100659906864166,
            0.0022599075455218554,
            -0.013358372263610363,
            -0.007779369130730629,
            -0.001302641467191279,
            0.02422608993947506,
            -0.01362732145935297,
            0.013249260373413563,
            0.01616336964070797,
            -0.011847659014165401,
            -0.02226186916232109,
            -0.01657191850244999,
            -0.005188265815377235,
            -0.01371100265532732,
            -0.014787077903747559,
            0.02815626561641693,
            -0.006228476297110319,
            -0.01391358021646738,
            0.016019761562347412,
            -0.04226488620042801,
            -0.028574801981449127,
            -0.025334790349006653,
            -0.02494385465979576,
            -0.036229439079761505,
            0.024994779378175735,
            -0.049855079501867294,
            -0.028279799968004227,
            -0.02394239790737629,
            0.020079027861356735,
            0.03107849322259426,
            -0.0025995864998549223,
            -0.0026690540835261345,
            -0.023328403010964394,
            -0.001088748686015606,
            -0.014926767908036709,
            -0.057538099586963654,
            0.018488358706235886,
            0.023351389914751053,
            0.014639192260801792,
            -0.01142224669456482,
            0.008812898769974709,
            -0.07915833592414856,
            -0.007144604809582233,
            -0.018469158560037613,
            0.037139881402254105,
            -0.006627413909882307,
            -0.01084964070469141,
            -0.04248291254043579,
            0.0034032247494906187,
            -0.001304164296016097,
            0.00046463607577607036,
            -0.020961420610547066,
            0.009828396141529083,
            0.02388523891568184,
            -0.001151297357864678,
            -0.022725878283381462,
            -0.01618668995797634,
            -0.01740266941487789,
            0.034611694514751434,
            0.003221758408471942,
            0.030880378559231758,
            0.041831593960523605,
            0.01656278409063816,
            -0.015057627111673355,
            0.016538085415959358,
            -0.006262538488954306,
            0.009057085029780865,
            0.0019728560000658035,
            -0.0064981537871062756,
            0.026085978373885155,
            0.0012440638383850455,
            0.0017686388455331326,
            0.003989419899880886,
            0.004862387664616108,
            -0.012747655622661114,
            0.03386826813220978,
            -0.022652732208371162,
            -0.019683610647916794,
            0.00984691921621561,
            -0.0020363940857350826,
            0.020058881491422653,
            0.025773227214813232,
            -0.020852871239185333,
            0.03236792981624603,
            -0.04746164008975029,
            0.02152692712843418,
            -0.026413945481181145,
            -0.031664784997701645,
            0.0017430633306503296,
            0.032426465302705765,
            -0.00683013629168272,
            0.014462361112236977,
            0.0270217377692461,
            -0.025423359125852585,
            0.02353495918214321,
            -0.009337229654192924,
            -0.02026261016726494,
            0.005742046516388655,
            -0.020219095051288605,
            -0.017628662288188934,
            -0.018167585134506226,
            -0.026808788999915123,
            -0.004423512611538172,
            -0.04591989144682884,
            0.04032853618264198,
            -0.026138251647353172,
            -0.013836834579706192,
            -0.012143421918153763,
            0.07653633505105972,
            -0.007493132725358009,
            0.010232440195977688,
            -0.03605712205171585,
            0.014506700448691845,
            0.010742015205323696,
            0.003745483001694083,
            0.001477286801673472,
            -0.009258078411221504,
            -0.017895914614200592,
            -0.033576685935258865,
            -0.02437799610197544,
            0.014403481967747211,
            -0.0007702183211222291,
            -0.03178834915161133,
            -0.03159968927502632,
            0.04104850813746452,
            0.009600408375263214,
            -0.04869711771607399,
            0.005046627018600702,
            -0.009666558355093002,
            -0.002530110767111182,
            0.02035663276910782,
            -0.033974409103393555,
            -0.014829752035439014,
            0.01911650225520134,
            -0.045573242008686066,
            -0.03611171245574951,
            -0.05255553498864174,
            0.0008238860755227506,
            -0.024653689935803413,
            0.021567419171333313,
            -0.007692404091358185,
            -0.006498061586171389,
            0.030191225931048393,
            0.03576361760497093,
            -0.003583169775083661,
            -0.01666838862001896,
            -0.00803490448743105,
            0.00020164834859315306,
            0.015096300281584263,
            0.027198173105716705,
            -0.05602483078837395,
            -0.01007762085646391,
            -0.030053555965423584,
            -0.01570148579776287,
            0.00036478418041951954,
            -0.011263689957559109,
            -0.0029873133171349764,
            0.029642796143889427,
            -0.016032494604587555,
            0.012598296627402306,
            0.006721016485244036,
            0.03731635585427284,
            -0.024568632245063782,
            -0.03290791064500809,
            0.029192989692091942,
            -0.007751062978059053,
            -0.0005875907954759896,
            0.033081602305173874,
            -0.001015766290947795,
            -0.01076728105545044,
            0.012661413289606571,
            -0.03461851552128792,
            0.008750072680413723,
            -0.011837632395327091,
            0.002997109666466713,
            -0.005111142992973328,
            0.005382244475185871,
            -0.020430507138371468,
            0.018224339932203293,
            0.011600116267800331,
            -0.04251163452863693,
            0.025336841121315956,
            0.017580166459083557,
            0.008688263595104218,
            0.0358247384428978,
            0.0007138211512938142,
            0.016587160527706146,
            -0.002154167043045163,
            0.01430276595056057,
            0.06107964739203453,
            -0.048773784190416336,
            -0.04265310987830162,
            0.03376556187868118,
            0.007127929478883743,
            -0.016442837193608284,
            0.002188310259953141,
            -0.013022380881011486,
            -0.037805020809173584,
            0.00010931662109214813,
            -0.027756312862038612,
            0.01594942808151245,
            0.04776822030544281,
            -0.004925423301756382,
            0.0221384447067976,
            -0.00532344589009881,
            -0.002490643411874771,
            -0.020129740238189697,
            0.016581250354647636,
            -0.009001336991786957,
            -0.015930108726024628,
            -0.012831680476665497,
            0.017730943858623505,
            0.0021811677142977715,
            -0.010897823609411716,
            -0.012292463332414627,
            0.013872450217604637,
            -0.006416992750018835,
            -0.04194122925400734,
            -0.029582025483250618,
            -0.017679300159215927,
            0.015586349181830883,
            -0.005200042389333248,
            -0.04510961100459099,
            0.030081897974014282,
            -0.014609044417738914,
            -0.007451271638274193,
            0.0026142087299376726,
            0.03138141334056854,
            0.052493393421173096,
            -0.004264333285391331,
            0.02690291590988636,
            -0.018908705562353134,
            -0.027364274486899376,
            -0.01658296398818493,
            0.04001307487487793,
            -0.012083501555025578,
            -0.005577309522777796,
            -0.0021614425349980593,
            -0.024644305929541588,
            0.008121744729578495,
            -0.010136735625565052,
            -0.00790786650031805,
            -0.025992102921009064,
            0.013525567017495632,
            -0.02079864591360092
        ],
        [
            -0.0002809018187690526,
            -0.013143455609679222,
            -0.018662136048078537,
            0.014658039435744286,
            0.017301002517342567,
            -0.0009833942167460918,
            -0.009744786657392979,
            -0.014747880399227142,
            0.025006113573908806,
            -0.027287723496556282,
            -0.012231362983584404,
            0.011406058445572853,
            0.02499469742178917,
            -0.04112911596894264,
            0.005303512327373028,
            -0.023648487403988838,
            -0.009364297613501549,
            0.010014324449002743,
            -0.009744340553879738,
            -0.007842447608709335,
            0.006265129428356886,
            -0.02930101566016674,
            -0.017747126519680023,
            0.012646928429603577,
            -0.02836584858596325,
            0.015854541212320328,
            0.015300953760743141,
            -0.0017479327507317066,
            0.028015216812491417,
            -0.013299204409122467,
            -0.02976183220744133,
            0.034928902983665466,
            -0.02391357719898224,
            -0.014434454962611198,
            -0.026725322008132935,
            0.023415759205818176,
            0.010902677662670612,
            0.010539280250668526,
            0.03222725912928581,
            0.01854272559285164,
            0.032774657011032104,
            0.025249944999814034,
            -0.019114626571536064,
            0.02140878327190876,
            0.022407134994864464,
            -0.010622112080454826,
            0.000834031670819968,
            0.018201762810349464,
            0.004808827769011259,
            0.002140252385288477,
            -0.005525199696421623,
            0.012406419962644577,
            -0.05557907372713089,
            -0.07775084674358368,
            -0.030308108776807785,
            -0.01168545987457037,
            0.024261023849248886,
            0.011746478267014027,
            0.02116367779672146,
            0.030743375420570374,
            -0.03075370378792286,
            -0.012342814356088638,
            0.016230622306466103,
            -0.00362827954813838,
            -0.02886655554175377,
            -0.020182687789201736,
            -0.02166818454861641,
            0.03512626513838768,
            -0.01972643844783306,
            0.011389678344130516,
            0.03468397259712219,
            0.03424445167183876,
            0.008337290026247501,
            0.012332485057413578,
            0.034664932638406754,
            -0.024412501603364944,
            -0.006080815102905035,
            -0.014043410308659077,
            0.014481164515018463,
            -0.0012112237745895982,
            0.035168468952178955,
            0.017548074945807457,
            0.020460855215787888,
            -0.0243055559694767,
            0.03467689827084541,
            -0.021276095882058144,
            -0.00042825230048038065,
            -0.01847194880247116,
            -0.01688499189913273,
            0.01812472939491272,
            -0.015068184584379196,
            0.005231567658483982,
            -0.06405536830425262,
            -0.01966879330575466,
            -0.00090018380433321,
            -0.02665887400507927,
            -0.022890128195285797,
            -0.013482026755809784,
            0.0914958268404007,
            0.0043292962945997715,
            -0.03812161833047867,
            -0.029096217826008797,
            0.0318760983645916,
            -0.029000960290431976,
            0.01833184063434601,
            0.008759520947933197,
            0.029538651928305626,
            0.006420100573450327,
            0.052278369665145874,
            -0.004500005394220352,
            0.011229241266846657,
            -0.023206636309623718,
            0.03434085100889206,
            -0.0361313559114933,
            -0.022981615737080574,
            0.018279092386364937,
            0.020777614787220955,
            0.026084743440151215,
            -0.00642760843038559,
            0.0005136709078215063,
            0.022665303200483322,
            0.00374720711261034,
            0.0032482785172760487,
            0.022705843672156334,
            -0.009688128717243671,
            -0.042517974972724915,
            -0.007872539572417736,
            -0.006752421148121357,
            0.007259830832481384,
            -0.0014957033563405275,
            0.008070933632552624,
            0.024957891553640366,
            -0.031447649002075195,
            0.008535890839993954,
            -0.006385254207998514,
            -0.005475679878145456,
            -0.029454940930008888,
            -0.026776554062962532,
            -0.02005516178905964,
            0.02071969211101532,
            0.018195942044258118,
            0.01934809237718582,
            0.013731885701417923,
            0.004804413300007582,
            0.004630041774362326,
            -0.012568092904984951,
            -0.03037131018936634,
            0.009738790802657604,
            -0.015814930200576782,
            0.04279753565788269,
            -0.012304969131946564,
            -0.022749193012714386,
            0.03231581673026085,
            -0.025575824081897736,
            -0.01951828971505165,
            -0.02872280776500702,
            -0.017596377059817314,
            -0.014121525920927525,
            0.024173950776457787,
            0.032338231801986694,
            -0.022609204053878784,
            -0.01979931630194187,
            -0.02192092128098011,
            0.026321033015847206,
            -0.03299519047141075,
            -0.011022795923054218,
            -0.023220760747790337,
            0.01876024901866913,
            0.0018524184124544263,
            0.011777237989008427,
            -0.012251412495970726,
            -0.0118282875046134,
            0.002532387850806117,
            -0.01056268997490406,
            -0.021600086241960526,
            0.012237613089382648,
            -0.0017063940176740289,
            -0.035523563623428345,
            0.020657721906900406,
            -0.03859441354870796,
            0.005304583813995123,
            -0.011204893700778484,
            0.02979912795126438,
            -0.004734503570944071,
            0.034106556326150894,
            0.03454945236444473,
            -0.007608693093061447,
            -0.023762499913573265,
            -0.017418934032320976,
            -0.014579297043383121,
            0.035332873463630676,
            0.001275000162422657,
            0.04754194617271423,
            -9.089403465623036e-05,
            0.013692501932382584,
            -0.01622864603996277,
            0.013575676828622818,
            -0.009240685030817986,
            -0.027716247364878654,
            0.0035280014853924513,
            0.012502079829573631,
            -0.009164637885987759,
            -0.05237121880054474,
            -0.02308865636587143,
            0.04251787066459656,
            -0.020417524501681328,
            0.02623889409005642,
            0.003723745932802558,
            -0.0007759604486636817,
            -0.03127163276076317,
            0.011133786290884018,
            -0.04034634679555893,
            0.009392754174768925,
            0.0025590467266738415,
            0.002379402983933687,
            -0.0014398841885849833,
            0.02478746697306633,
            -0.03999481350183487,
            -0.00596435135230422,
            0.005955533590167761,
            0.025680383667349815,
            0.02237013168632984,
            0.0022258239332586527,
            -0.010655518621206284,
            0.016084998846054077,
            -0.029512887820601463,
            -0.061731886118650436,
            -0.013983706943690777,
            -0.010492928326129913,
            0.03649914637207985,
            0.017842041328549385,
            0.0038317886646836996,
            -0.05448177084326744,
            0.013325699605047703,
            0.019775481894612312,
            -0.0050389738753438,
            -0.0401117205619812,
            0.005776055157184601,
            0.018874991685152054,
            -0.01619064062833786,
            0.04837917909026146,
            -0.008822543546557426,
            -0.006203842349350452,
            -0.01659306511282921,
            0.030972132459282875,
            -0.02053356170654297,
            0.00201169541105628,
            0.013062335550785065,
            -0.0154165830463171,
            -0.015717044472694397,
            0.02936202846467495,
            -0.0054892273619771,
            -0.02184673398733139,
            0.02014855109155178,
            -0.015021364204585552,
            0.012736179865896702,
            0.008627346716821194,
            0.012824229896068573,
            0.0012373054632917047,
            -0.004177824128419161,
            0.029379278421401978,
            -0.012643296271562576,
            -0.0020719929598271847,
            0.0071181985549628735,
            -0.001664235140196979,
            -0.04722423478960991,
            -0.0038329707458615303,
            -0.020531827583909035,
            0.026131724938750267,
            0.03825628012418747,
            0.013784542679786682,
            -0.002885066904127598,
            -0.002194825792685151,
            0.008235668763518333,
            -0.01957613229751587,
            -0.008662288077175617,
            -0.038366395980119705,
            -0.016172582283616066,
            -0.020202871412038803,
            0.010702630504965782,
            0.016472432762384415,
            -0.015319008380174637,
            -0.00731408828869462,
            0.03279228135943413,
            -0.01062851957976818,
            0.011522199958562851,
            -0.018336936831474304,
            0.008245985954999924,
            0.021555686369538307,
            0.0205245204269886,
            -0.01275596208870411,
            -0.006358073092997074,
            0.024969328194856644,
            -0.008861584588885307,
            -0.009745673276484013,
            0.009174395352602005,
            -0.0009121527546085417,
            -0.020152762532234192,
            0.01268450915813446,
            0.031213557347655296,
            -0.027489425614476204,
            0.028441980481147766,
            -0.01984526589512825,
            0.022224517539143562,
            0.014783256687223911,
            -0.03620230406522751,
            -0.03961483761668205,
            -0.009329675696790218,
            0.01484933402389288,
            -0.026688870042562485,
            0.021841175854206085,
            -0.02218586578965187,
            -0.0182832982391119,
            0.016015203669667244,
            -0.002377430908381939,
            -0.018741322681307793,
            -0.006615866906940937,
            -0.006427903659641743,
            -0.0032961994875222445,
            -0.007580805569887161,
            0.0031137762125581503,
            0.003485220717266202,
            0.0070474278181791306,
            -0.013269081711769104,
            -0.0033378738444298506,
            -0.04461117088794708,
            -0.033434879034757614,
            0.010166303254663944,
            -0.004609645809978247,
            -0.02282112091779709,
            -0.014043525792658329,
            -0.015468642115592957,
            0.02434670552611351,
            0.00449695810675621,
            -0.01610424555838108,
            -0.04912843927741051,
            0.041466981172561646,
            -0.02670840546488762,
            0.03336324915289879,
            -0.020076066255569458,
            -0.002663768595084548,
            -0.013796724379062653,
            0.02078682743012905,
            -0.001808529137633741,
            -0.022975420579314232,
            -0.012188837863504887,
            -0.022954430431127548,
            -0.007085063494741917,
            -0.02108202874660492,
            0.01588672399520874,
            0.02326236106455326,
            0.014422892592847347,
            -0.02829650230705738,
            -0.004927550442516804,
            -0.020075134932994843,
            -0.016170207411050797,
            0.006230797618627548,
            -0.02423526719212532,
            0.004411456640809774,
            0.027487264946103096,
            0.03014722838997841,
            -0.0008537781541235745,
            0.01429047342389822,
            0.000915585842449218,
            -0.0005144210299476981,
            0.028780104592442513,
            0.019870739430189133,
            -0.006592600140720606,
            0.015675758942961693,
            0.0068942345678806305,
            0.050116948783397675,
            0.019447889178991318,
            -0.03285909444093704,
            -0.020891757681965828,
            0.03669603914022446,
            0.020964479073882103,
            -0.02956211194396019,
            -0.0027015062514692545,
            -0.041056472808122635,
            0.009740134701132774,
            0.03920019418001175,
            -2.2396392523660325e-05,
            -0.015281366184353828,
            0.02140071615576744,
            0.006496717222034931,
            0.017437608912587166,
            0.026327578350901604,
            0.02023894712328911,
            0.017355887219309807,
            0.004454030655324459,
            0.029365552589297295,
            0.024182600900530815,
            0.0008894374477677047,
            0.0401410236954689,
            -0.027318987995386124,
            -0.005148725118488073,
            0.01306926179677248,
            -0.021283986046910286,
            0.012469538487493992,
            0.026453794911503792,
            -0.02196836844086647,
            0.010446968488395214,
            -0.015844784677028656,
            0.020042788237333298,
            0.00919914711266756,
            -0.012405439279973507,
            0.022687673568725586,
            -0.04219716042280197,
            -0.0271815937012434,
            -0.02217988669872284,
            -0.02655929885804653,
            0.014473289251327515,
            -0.02617163397371769,
            0.03120223991572857,
            -0.026470812037587166,
            0.007969390600919724,
            0.00819583609700203,
            -0.021180205047130585,
            0.0015912869712337852,
            0.030468804761767387,
            -0.012154593132436275,
            0.00723035354167223,
            -0.05727984383702278,
            0.007554312236607075,
            -0.030276836827397346,
            -0.0016365057090297341,
            0.004746162798255682,
            -0.014376132749021053,
            -0.022812535986304283,
            -0.02365218475461006,
            -0.02107623778283596,
            -0.008953295648097992,
            0.03800007700920105,
            0.008482130244374275,
            4.60136252513621e-05,
            -0.008174838498234749,
            0.022192707285284996,
            0.018487675115466118,
            0.023933885619044304,
            -0.05939750745892525,
            0.02706640213727951,
            0.02790726348757744,
            -0.02114587463438511,
            0.01693129539489746,
            -0.0016813912661746144,
            0.016414988785982132,
            0.02153860218822956,
            0.07954677194356918,
            -0.03815670311450958,
            0.03552793711423874,
            0.007891557179391384,
            -0.010575775057077408,
            0.0010220904368907213,
            -0.014231152832508087,
            -0.010511024855077267,
            -0.027143362909555435,
            0.008182660676538944,
            0.042914979159832,
            0.007390248589217663,
            -0.03210951387882233,
            -0.009148613549768925,
            -0.018143266439437866,
            0.006818113382905722,
            0.0016988135175779462,
            0.039834633469581604,
            -0.0018495941767469049,
            -0.011883406899869442,
            0.012015732936561108,
            0.0032576953526586294,
            -0.02404882200062275,
            -0.011511335149407387,
            0.02988237887620926,
            0.028569335117936134,
            0.0349155068397522,
            0.02303578518331051,
            -0.08097456395626068,
            -0.05331452190876007,
            -0.018871651962399483,
            0.015156656503677368,
            -0.023177584633231163,
            0.0018469068454578519,
            0.003928813152015209,
            -0.006976446136832237,
            0.008205927908420563,
            -0.025758424773812294,
            -0.0089239701628685,
            -0.004861717578023672,
            0.030321206897497177,
            0.010901153087615967,
            -0.016455097123980522,
            0.008206021040678024,
            -0.024406244978308678,
            -0.024584107100963593,
            0.02907995693385601,
            2.074027906928677e-05,
            -0.02329692430794239,
            0.0009405172313563526,
            0.03479382395744324,
            -0.029745593667030334,
            0.0027146777138113976,
            -0.028827661648392677,
            -0.026222342625260353,
            -0.00114512350410223,
            0.007519507315009832,
            0.030578400939702988,
            -0.023099159821867943,
            0.027856528759002686,
            -0.021892962977290154,
            -0.019092364236712456,
            -0.027680587023496628,
            -0.00940767116844654,
            -0.011301936581730843,
            0.03383905068039894,
            -0.004767040256410837,
            0.01580306701362133,
            0.034218620508909225,
            -0.01900174841284752,
            -0.022998319938778877,
            0.017218416556715965,
            0.02778329886496067,
            0.023487990722060204,
            -0.021169422194361687,
            0.018779108300805092,
            0.02278170734643936,
            0.04539928212761879,
            0.019636819139122963,
            0.03485973924398422,
            -0.016013791784644127,
            0.006348141003400087,
            -0.009376228787004948,
            0.029210319742560387,
            0.012301898561418056,
            -0.001241113874129951,
            0.02560454234480858,
            -0.021955912932753563,
            0.016527952626347542,
            -0.006715153809636831,
            0.034140996634960175,
            -0.0015316774370148778,
            0.004509820137172937,
            -0.005918458104133606,
            0.03188270330429077,
            0.015455895103514194,
            -0.023011187091469765,
            0.008723864331841469,
            0.013689521700143814,
            -0.009870320558547974,
            -0.012378918007016182,
            -7.718589768046513e-05,
            0.032040372490882874,
            0.02219516597688198,
            0.011207407340407372,
            -0.02758592925965786,
            -0.03768298029899597,
            0.004689543507993221,
            0.0015201950445771217,
            0.0035354415886104107,
            0.005688037723302841,
            -0.02548041008412838,
            0.024522382766008377,
            -0.0036656938027590513,
            0.005653716623783112,
            -0.005295774899423122,
            -0.028584793210029602,
            -0.0006630616262555122,
            0.029873676598072052,
            0.03298673406243324,
            -0.01831945776939392,
            -0.004865582566708326,
            0.025861985981464386,
            -0.011786322109401226,
            -0.013938022777438164,
            0.027453716844320297,
            0.02521817572414875,
            -0.021000908687710762,
            -0.01277772244066,
            -0.02729152888059616,
            0.0019793289247900248,
            0.022461431100964546,
            0.0018456579418852925,
            0.02651146426796913,
            -0.04536233842372894,
            0.001059517846442759,
            -0.013211728073656559,
            0.02842208929359913,
            -0.027938328683376312,
            -0.04944603890180588,
            -0.01348926406353712,
            0.03211754560470581,
            0.028326543048024178,
            -0.010773198679089546,
            -0.01786690205335617,
            0.026159215718507767,
            -0.0009582812781445682,
            0.02528775855898857,
            -0.017087338492274284,
            -0.00829564779996872,
            0.012060322798788548,
            0.026495421305298805,
            -0.007774185389280319,
            0.047905709594488144,
            -0.005289668682962656,
            0.02298721857368946,
            0.001812273752875626,
            -0.01576094515621662,
            0.012939984910190105,
            -0.03329208493232727,
            0.02973652258515358,
            -0.019920378923416138,
            0.018243147060275078,
            -0.004288813564926386,
            -0.009462466463446617,
            0.024866091087460518,
            -0.0016652063932269812,
            0.002061770763248205,
            -0.05356130748987198,
            0.03090253844857216,
            -0.014385202899575233,
            0.008274136111140251,
            -0.01783015765249729,
            -0.0019260478438809514,
            0.020990122109651566,
            0.00228060781955719,
            0.010853881016373634,
            0.03213058039546013,
            -0.017394112423062325,
            0.00965527631342411,
            -0.031919632107019424,
            0.0045370785519480705,
            -0.004456252325326204,
            -0.006298330146819353,
            0.02027144655585289,
            0.025348573923110962,
            -0.030451150611042976,
            -0.02935214340686798,
            0.004388985224068165,
            -0.007150923367589712,
            0.0077857645228505135,
            0.0362493135035038,
            -0.035464588552713394,
            -0.01924107037484646,
            -0.0384858101606369,
            0.0255669504404068,
            -0.06287974864244461,
            -0.02884780988097191,
            -0.016285909339785576,
            -0.0003656742337625474,
            -0.0035038318019360304,
            -0.02837696298956871,
            -0.012638779357075691,
            0.02453950233757496,
            0.005440110340714455,
            -0.007504236418753862,
            0.008352053351700306,
            0.04097973555326462,
            -0.00649322010576725,
            0.03031442128121853,
            0.017685048282146454,
            0.0058003258891403675,
            0.00519511429592967,
            0.008999455720186234,
            -0.006021701265126467,
            -0.008839786052703857,
            -0.001879576942883432,
            -0.018381141126155853,
            -0.021172065287828445,
            -0.01866861991584301,
            0.0018234464805573225,
            0.016437118873000145,
            0.03355468064546585,
            0.017937136813998222,
            -0.009076408110558987,
            0.02677726186811924,
            -0.001735362340696156,
            -0.013185402378439903,
            0.018544794991612434,
            0.011825451627373695,
            0.003581895027309656,
            -0.014356562867760658,
            0.00930793583393097,
            -0.011125676333904266,
            -0.016513856127858162,
            -0.01723509281873703,
            -0.015372279100120068,
            -0.0014615179970860481,
            -0.02194328047335148,
            0.03198908641934395,
            -0.018266012892127037,
            -0.013723650947213173,
            0.009125987067818642,
            -0.014548275619745255,
            -0.019885022193193436,
            -0.06950400769710541,
            0.019675053656101227,
            -0.009721924550831318,
            -0.011624721810221672,
            0.027315927669405937,
            0.013138441368937492,
            0.0269655529409647,
            0.02816113643348217,
            0.011215642094612122,
            0.030264882370829582,
            0.01178281381726265,
            0.00538101838901639,
            0.01300537958741188,
            0.044004008173942566,
            0.03528302162885666,
            0.037343088537454605,
            -0.03106808289885521,
            -0.006690365262329578,
            -0.011687288992106915,
            -0.006825824733823538,
            0.0028244417626410723,
            0.03356089070439339,
            0.00873322132974863,
            0.030218971893191338,
            -0.004734269808977842,
            0.03748532012104988,
            0.02060823328793049,
            -0.0014115202939137816,
            -0.01555684581398964,
            0.01609654352068901,
            -0.01892642304301262,
            -0.013571036048233509,
            -0.005895317066460848,
            0.004223203286528587,
            -0.015427063219249249,
            0.006301380228251219,
            0.012764263898134232,
            0.0038903066888451576,
            -0.021103087812662125,
            -0.01953219622373581,
            -0.02911725640296936,
            -0.001550805987790227,
            -0.014825331047177315,
            0.01527493353933096,
            0.02881355956196785,
            0.03460037335753441,
            -0.01840263418853283,
            0.001505198422819376,
            -0.01915985532104969,
            0.02360646426677704,
            0.02244606427848339,
            0.005623470991849899,
            0.0018469716887921095,
            0.013099994510412216,
            -0.045460447669029236,
            -0.029058123007416725,
            -0.0017162689473479986,
            0.003523877589032054,
            0.01285812258720398,
            0.015657939016819,
            0.00979981105774641,
            0.05171798914670944,
            -0.008758172392845154,
            0.017351578921079636,
            -0.01994188502430916,
            -0.004761162213981152,
            0.009281083941459656,
            0.027561981230974197,
            -0.015252450481057167,
            0.004234548192471266,
            0.014873535372316837,
            0.007801813073456287,
            -0.023047057911753654,
            -0.005769547540694475,
            0.03635130077600479,
            -0.022956734523177147,
            0.00469446973875165,
            -0.036542970687150955,
            0.010408526286482811,
            0.03889649733901024,
            -0.022450098767876625,
            0.03662924841046333,
            -0.0027175964787602425,
            -0.020908428356051445,
            0.022772155702114105,
            0.05578397214412689,
            -0.007923582568764687,
            0.03720313683152199,
            -0.004550864454358816,
            -0.012110461480915546,
            -0.0011387026170268655,
            0.019278161227703094,
            0.0021524245385080576,
            -0.010423109866678715,
            0.016992894932627678,
            -0.030630866065621376,
            0.006036045961081982,
            0.026114709675312042,
            0.0025723553262650967,
            0.028092391788959503,
            -0.005441412329673767,
            -0.016029827296733856,
            0.02105673775076866,
            -0.00038196329842321575,
            -0.0023941334802657366,
            -0.009872211143374443,
            -0.02089100331068039,
            0.021099615842103958,
            -0.009037993848323822,
            -0.013665420934557915,
            -0.025763513520359993,
            -0.0026824125088751316,
            -0.004881047643721104,
            0.03226381167769432,
            -0.019050002098083496,
            0.014666332863271236,
            -0.019102711230516434,
            0.02178441546857357,
            0.03084811009466648,
            0.04158557951450348,
            0.011654260568320751,
            -0.015306465327739716,
            0.02230796590447426,
            0.002838297514244914,
            -0.016181187704205513,
            -0.04054625704884529,
            0.002197718247771263,
            0.003095218911767006,
            -0.004767204634845257,
            0.01967799849808216,
            -0.040571462363004684,
            -0.0037621427327394485,
            -0.0060646808706223965,
            0.0007207076414488256,
            -0.015007535926997662,
            -0.03112589195370674,
            -0.0116830850020051,
            0.03913019225001335,
            -0.014166954904794693,
            0.0017347173998132348,
            0.0031814384274184704,
            0.0009608488762751222,
            -0.018506286665797234,
            0.016582155600190163,
            0.004167646169662476,
            -0.011425663717091084,
            -0.0009683997486717999,
            0.041205983608961105,
            -0.01261784229427576,
            0.02240009233355522,
            0.041214533150196075,
            -0.022975241765379906,
            -0.012002317234873772,
            0.02610454335808754,
            -0.012099199928343296,
            -0.0013614740455523133,
            -0.01936044543981552,
            -0.017079925164580345,
            0.008821005932986736,
            -0.0005246399668976665,
            -0.019537556916475296,
            0.027844414114952087,
            -0.02977585420012474,
            -0.002230626530945301,
            0.03334810584783554,
            -0.01877809874713421,
            0.04183311387896538,
            0.00459994375705719,
            -0.0008320494671352208,
            -0.016836009919643402,
            0.007716847117990255,
            0.011062153615057468,
            0.013863364234566689,
            -0.024331320077180862,
            0.005352308042347431,
            -0.03910744935274124,
            0.01885286718606949,
            -0.008924901485443115,
            -0.019531553611159325,
            -0.002111622365191579,
            -0.027634942904114723,
            0.006035157013684511,
            -0.03786695748567581,
            0.01213521882891655,
            0.02775326557457447,
            0.06621890515089035,
            0.02637415938079357,
            0.0022164045367389917,
            -0.02515271306037903,
            0.036830246448516846,
            0.03220663219690323,
            0.009590458124876022,
            -0.005375545937567949,
            0.04448803886771202,
            -0.0076253991574049,
            0.06859447062015533,
            0.027512671425938606,
            0.01061831321567297,
            0.005923726595938206,
            -0.012337657622992992,
            -0.05497312918305397,
            0.007080511189997196,
            0.012967758812010288,
            0.017191169783473015,
            0.001742554479278624,
            -0.01286365743726492,
            -0.012025763280689716,
            -0.024092968553304672,
            -0.019276654347777367,
            -0.017268309369683266,
            -0.010026047937572002,
            -0.01722528599202633,
            0.02573779784142971,
            -0.03548957407474518,
            0.025418465957045555,
            -0.015239722095429897,
            0.017596201971173286,
            0.012335358187556267,
            0.03343530744314194,
            0.020295793190598488,
            -0.003386640688404441,
            -0.021344976499676704,
            0.022748438641428947,
            -0.024182060733437538,
            0.01728207990527153,
            0.04695501923561096,
            0.015602374449372292,
            0.010659725405275822,
            -0.01385536789894104,
            0.021166596561670303,
            -0.01940218359231949,
            0.006388314533978701,
            0.03883393481373787,
            -0.015933318063616753,
            -0.003456665901467204,
            0.02825196087360382,
            -0.014026717282831669,
            -0.017133012413978577,
            0.014535862021148205,
            0.008325022645294666,
            0.01571919210255146,
            -0.006787326652556658,
            0.04264330491423607,
            -0.010581529699265957,
            -0.04485992342233658,
            0.00558390561491251,
            0.03256269916892052,
            0.005709222052246332,
            -0.001834744238294661,
            0.020654678344726562,
            0.042903829365968704,
            -0.0068766893818974495,
            0.022892313078045845,
            0.008498422801494598,
            -0.034281063824892044,
            -0.022367805242538452,
            0.017359605059027672,
            0.035358887165784836,
            0.03342944383621216,
            0.029329052194952965,
            -0.01331122126430273,
            0.0011023047845810652,
            -0.020410235971212387,
            -0.004933927208185196,
            0.029630446806550026,
            0.0168642345815897,
            -0.02286968193948269,
            0.00431946711614728,
            -0.003470173105597496,
            -0.04334014654159546,
            0.025467446073889732,
            0.05381524935364723,
            -0.025184718891978264,
            0.014701342210173607,
            0.010484389960765839,
            0.033996086567640305,
            -0.021061601117253304,
            -0.012530643492937088,
            0.025167342275381088,
            -0.01037716306746006,
            0.030874032527208328,
            0.009856152348220348,
            0.02781503088772297,
            0.002322981832548976,
            0.005727823358029127,
            -0.007508536335080862,
            -0.007384259253740311,
            -0.010077772662043571,
            -0.006932550575584173,
            -0.004902827087789774,
            0.021920213475823402,
            0.009765088558197021,
            -0.03962438926100731,
            0.01818598434329033,
            0.013323369435966015,
            0.018381604924798012,
            0.01846693642437458,
            -0.01905091106891632,
            0.009559743106365204,
            0.006978130433708429,
            0.023630136623978615,
            -0.01768159121274948,
            -0.0023952636402100325,
            0.035148654133081436,
            -0.04224464297294617,
            0.005459818057715893,
            0.017529761418700218,
            0.03188575431704521,
            0.0054187169298529625,
            -0.025190189480781555,
            -0.01855519972741604,
            -0.003462190041318536,
            -4.6032233512960374e-05,
            0.03424349054694176,
            0.018918270245194435,
            0.003348834812641144,
            -0.03659659996628761,
            -0.006080062594264746,
            0.030679114162921906,
            -0.015267766080796719,
            -0.009057785384356976,
            -0.03251011297106743,
            -0.03593972697854042,
            0.02197142131626606,
            0.014623698778450489,
            0.015756219625473022,
            0.043798964470624924,
            0.01759665086865425,
            0.006615682039409876,
            -0.012988343834877014,
            0.009959996677935123,
            -0.01623850129544735
        ],
        [
            -0.02479754574596882,
            0.025699911639094353,
            -0.03460885211825371,
            -0.018255405128002167,
            -0.03294136002659798,
            0.020992882549762726,
            0.011261447332799435,
            0.05374040827155113,
            0.0018678969936445355,
            0.03168146312236786,
            -0.0356304794549942,
            0.0035001409705728292,
            -0.03344425559043884,
            0.014468373730778694,
            0.06922054290771484,
            -0.004196624271571636,
            0.00923027191311121,
            0.019425300881266594,
            0.010650014504790306,
            0.00711176497861743,
            0.01575293578207493,
            -0.03857097029685974,
            -0.03441203385591507,
            0.004951469134539366,
            0.09142311662435532,
            -0.04371049627661705,
            0.018774736672639847,
            -0.03178572654724121,
            0.006007323507219553,
            -0.019389493390917778,
            -0.006355291232466698,
            -0.014702935703098774,
            0.020153330639004707,
            -0.03276316076517105,
            -0.0012304371921345592,
            0.027630720287561417,
            -0.006300350185483694,
            -0.004978708457201719,
            -0.005184336099773645,
            -0.024923885241150856,
            -0.0197200458496809,
            -0.03249768167734146,
            0.019237898290157318,
            -0.004487344063818455,
            -0.022632505744695663,
            -0.039274655282497406,
            -0.033556338399648666,
            -0.030766747891902924,
            -0.01879308372735977,
            -0.05886806547641754,
            0.012578356079757214,
            0.024800749495625496,
            0.00598969217389822,
            -0.01776343770325184,
            -0.022588301450014114,
            -0.0026499563828110695,
            -0.04250364378094673,
            0.029438363388180733,
            -0.0040399301797151566,
            -0.0277843214571476,
            0.021022507920861244,
            0.03030618652701378,
            0.012449471279978752,
            0.00565103767439723,
            0.003651396604254842,
            -0.01182510145008564,
            0.0014396439073607326,
            -0.030082954093813896,
            0.0068005044013261795,
            -0.02281074784696102,
            -0.008046768605709076,
            0.0013241601409390569,
            -0.05086859315633774,
            0.0019331466173753142,
            -0.005114253610372543,
            -0.04663103446364403,
            0.0028350225184112787,
            -0.0016732774674892426,
            -0.041669003665447235,
            -0.012707308866083622,
            0.019499223679304123,
            0.030421052128076553,
            0.01010612491518259,
            -0.031749747693538666,
            -0.010184526443481445,
            -0.006812862120568752,
            -0.010370013304054737,
            -0.023779435083270073,
            0.029539568349719048,
            -0.006811080034822226,
            -0.06469570100307465,
            0.007840847596526146,
            0.008278515189886093,
            -0.04384135082364082,
            0.006839986890554428,
            0.0034466206561774015,
            -0.006506727077066898,
            0.004612145945429802,
            0.0033310491126030684,
            0.009190299548208714,
            -0.03458400070667267,
            0.027193529531359673,
            -0.0009376975358463824,
            0.026279132813215256,
            0.030409719794988632,
            0.015881851315498352,
            0.033121440559625626,
            0.010511167347431183,
            0.04309117794036865,
            0.022909745573997498,
            -0.03748635947704315,
            -0.016385378316044807,
            -0.04024103656411171,
            -0.011663490906357765,
            -0.0062591456808149815,
            0.009118904359638691,
            -0.0421358197927475,
            -0.028220608830451965,
            -0.03175413981080055,
            -0.018741125240921974,
            0.016924293711781502,
            0.020266978070139885,
            0.02714947797358036,
            0.014368132688105106,
            0.03127336874604225,
            0.006910182069987059,
            -0.039594415575265884,
            -0.0013867884408682585,
            0.006791144609451294,
            -0.012477248907089233,
            0.019040146842598915,
            0.017585301771759987,
            -0.029715346172451973,
            0.01985430158674717,
            0.019281217828392982,
            0.015357955358922482,
            -0.028750713914632797,
            0.051520612090826035,
            0.03417889401316643,
            -0.022640392184257507,
            -0.024859145283699036,
            0.018645964562892914,
            0.04453367739915848,
            -0.035082798451185226,
            0.04250970110297203,
            0.010955612175166607,
            0.024597059935331345,
            0.04330248385667801,
            0.041132938116788864,
            -0.0180094875395298,
            0.010580391623079777,
            -0.03154270350933075,
            0.005013559013605118,
            0.020582955330610275,
            0.007828849367797375,
            0.0082240654155612,
            0.0024403377901762724,
            -0.013405944220721722,
            0.03836642578244209,
            -0.03209643438458443,
            0.003513901261612773,
            0.026995092630386353,
            0.02217467874288559,
            -0.0034972140565514565,
            0.00218180357478559,
            0.01826157607138157,
            -0.011232182383537292,
            0.022498086094856262,
            -0.004821910057216883,
            0.010467000305652618,
            0.01176550891250372,
            0.03364233300089836,
            -0.040123388171195984,
            -0.05449575558304787,
            -0.002228125464171171,
            -0.031213024631142616,
            -0.04097582399845123,
            0.037282999604940414,
            -0.061625752598047256,
            -0.004528210964053869,
            -0.003462614258751273,
            -0.0217729564756155,
            0.009990653023123741,
            -0.007249176502227783,
            0.0035841548815369606,
            0.03156556561589241,
            -0.0441751591861248,
            -0.04157133027911186,
            -0.017084868624806404,
            -0.0327446348965168,
            -0.00805579498410225,
            -0.032542720437049866,
            -0.059223804622888565,
            0.012318833731114864,
            -0.01670985296368599,
            -0.05516037344932556,
            -0.015171032398939133,
            -0.03805624321103096,
            -0.03396487608551979,
            0.015896106138825417,
            0.007619372569024563,
            -0.001982726389542222,
            0.024274107068777084,
            -0.000905099674127996,
            -0.056841377168893814,
            0.028536226600408554,
            0.07335343956947327,
            0.0264881644397974,
            -0.035895489156246185,
            -0.027634989470243454,
            0.01556379720568657,
            -0.024193059653043747,
            0.005348249338567257,
            0.04490719735622406,
            -0.002804578049108386,
            -0.006545169744640589,
            -0.008071965537965298,
            0.012225715443491936,
            -0.025662776082754135,
            -0.009893440641462803,
            -0.013510978780686855,
            0.046368397772312164,
            0.04657059907913208,
            -0.01578781194984913,
            -0.027442296966910362,
            0.018436787649989128,
            0.0043370844796299934,
            0.018310081213712692,
            -0.015927458181977272,
            -0.027193507179617882,
            0.002715202746912837,
            -0.031232574954628944,
            -0.021599847823381424,
            0.02283676527440548,
            -0.020461030304431915,
            -0.03885292634367943,
            0.002695763949304819,
            -0.028003087267279625,
            0.010880596935749054,
            -0.04468312859535217,
            -0.02164396457374096,
            0.01613761857151985,
            0.0011990265920758247,
            0.010026214644312859,
            0.007198359817266464,
            -0.018980126827955246,
            0.025363357737660408,
            0.008075708523392677,
            0.02759692445397377,
            -0.03225698694586754,
            0.01226514857262373,
            0.005489443428814411,
            0.02503056451678276,
            -0.00919167511165142,
            0.02027338556945324,
            0.014509414322674274,
            -0.03913445025682449,
            0.03374001756310463,
            -0.026652686297893524,
            0.0450558066368103,
            0.016265081241726875,
            -0.022961216047406197,
            0.022067014127969742,
            -0.03918046876788139,
            -0.030453043058514595,
            0.01546171959489584,
            0.010068853385746479,
            0.016866831108927727,
            0.02367575280368328,
            -0.03949931263923645,
            -0.0285196453332901,
            0.01382024772465229,
            -0.029142476618289948,
            -0.06281084567308426,
            -0.051506415009498596,
            -0.03773556277155876,
            -0.014974220655858517,
            -0.00840950757265091,
            0.008378777652978897,
            0.01913365349173546,
            0.013311495073139668,
            -0.016867047175765038,
            2.5639870727900416e-05,
            0.02110840566456318,
            0.013640599325299263,
            -0.02806748077273369,
            0.028012555092573166,
            -0.022241344675421715,
            0.010075908154249191,
            -0.0018276534974575043,
            -0.000426301994593814,
            0.024950481951236725,
            -0.008120188489556313,
            -0.0193984042853117,
            0.0009745165007188916,
            0.01991870440542698,
            0.006929839961230755,
            -0.003743645967915654,
            -0.014110819436609745,
            0.013868994079530239,
            -0.05610474944114685,
            -0.00951987225562334,
            -0.0096568763256073,
            0.00026714621344581246,
            -0.0019930191338062286,
            0.013818765990436077,
            -0.005205173045396805,
            0.012973207049071789,
            0.0172896608710289,
            -0.0033677713945508003,
            0.0008181692101061344,
            0.022456428036093712,
            0.014303335919976234,
            -0.030346402898430824,
            -0.03352740779519081,
            -0.019482815638184547,
            -0.0038714485708624125,
            -0.002679100725799799,
            0.005376541521400213,
            -0.03439580276608467,
            -0.013500574044883251,
            -0.06391024589538574,
            -0.0022819661535322666,
            0.009039289318025112,
            0.01694820635020733,
            0.0014448794536292553,
            0.003788188099861145,
            -0.03354185074567795,
            -0.02579965442419052,
            -0.02103482000529766,
            0.021320044994354248,
            -0.016915615648031235,
            0.010402373038232327,
            -0.019044311717152596,
            -0.06101062521338463,
            0.015674376860260963,
            0.013749649748206139,
            -0.004262744914740324,
            -0.013070345856249332,
            -0.008196157403290272,
            -0.02237086370587349,
            -0.024594031274318695,
            0.060212310403585434,
            -0.039992835372686386,
            -0.02670302614569664,
            0.0368523970246315,
            -0.03600231185555458,
            0.024477457627654076,
            -0.006158970762044191,
            -0.0280288178473711,
            -0.003196741221472621,
            0.0078056929633021355,
            0.009974024258553982,
            -0.012827509082853794,
            0.04802902415394783,
            0.02096792869269848,
            -0.018255842849612236,
            0.002766595222055912,
            0.01780027523636818,
            0.018837248906493187,
            0.0068117184564471245,
            -0.021532373502850533,
            0.03085222654044628,
            0.0026589587796479464,
            0.052413199096918106,
            0.01060059666633606,
            -0.03532344475388527,
            -0.039686933159828186,
            -0.020505109801888466,
            -0.031879574060440063,
            -0.004716166295111179,
            -0.03404230996966362,
            -0.005152576602995396,
            0.013548186980187893,
            0.019248483702540398,
            -0.010234888643026352,
            -0.016204528510570526,
            0.05170988291501999,
            0.03826877474784851,
            -0.06190890818834305,
            -0.012478609569370747,
            -0.0008540921844542027,
            -0.02249114401638508,
            -0.02003597281873226,
            -0.02075321599841118,
            -0.0046914732083678246,
            -0.022971471771597862,
            0.04636596515774727,
            -0.037531521171331406,
            0.012428449466824532,
            -0.056108590215444565,
            0.0021982493344694376,
            -0.03502164036035538,
            -0.0210989061743021,
            -0.020457616075873375,
            0.00433820066973567,
            -0.020917359739542007,
            -0.03520919382572174,
            -0.03365549445152283,
            0.027547385543584824,
            -0.005191418807953596,
            0.012340626679360867,
            -0.004188225604593754,
            0.01798876002430916,
            0.0031357272528111935,
            0.029209721833467484,
            0.015490858815610409,
            0.031523868441581726,
            0.015683775767683983,
            -0.013143239542841911,
            -0.008824807591736317,
            -0.0021055550314486027,
            -0.020424965769052505,
            0.05363557115197182,
            -0.03581448644399643,
            -0.016840053722262383,
            0.00043366249883547425,
            -0.046136222779750824,
            -0.027852827683091164,
            -0.03194336220622063,
            -0.03685082867741585,
            -0.019385352730751038,
            0.0034197468776255846,
            0.006644697859883308,
            0.06431894749403,
            0.050008296966552734,
            -0.08524353057146072,
            0.006723389495164156,
            0.0005141808651387691,
            -0.026052339002490044,
            -0.014643168076872826,
            -0.039685510098934174,
            -0.006200043018907309,
            0.016613470390439034,
            -0.018248645588755608,
            -0.017233330756425858,
            -0.01133204996585846,
            0.009895991533994675,
            0.00022231177717912942,
            -0.024234335869550705,
            -0.0024856640957295895,
            -0.041741907596588135,
            -0.028730733320116997,
            0.015521395951509476,
            -0.019563721492886543,
            -0.03906496241688728,
            0.0031530032865703106,
            -0.025791920721530914,
            -0.007804770953953266,
            -0.013880702666938305,
            0.017220929265022278,
            0.012393567711114883,
            -0.04718422144651413,
            0.027636753395199776,
            -0.03566257655620575,
            -0.004880307707935572,
            0.03906608745455742,
            -0.023153353482484818,
            0.008781330659985542,
            -0.033336102962493896,
            -0.08343333750963211,
            -0.006853435654193163,
            0.011288367211818695,
            0.0409395769238472,
            0.002450550440698862,
            -0.005192256532609463,
            -0.03032105229794979,
            -0.006579315755516291,
            -0.017374718561768532,
            -0.0010198871605098248,
            0.014657164923846722,
            0.027357077226042747,
            -0.04195266589522362,
            -0.04264713078737259,
            -0.036534424871206284,
            0.030729984864592552,
            0.02977495640516281,
            0.033689647912979126,
            -0.013924000784754753,
            -0.041775286197662354,
            0.005010351072996855,
            -0.018253063783049583,
            0.01498193945735693,
            -0.016536472365260124,
            0.04277276620268822,
            -0.042173489928245544,
            -0.022728489711880684,
            -0.0091438889503479,
            -0.05285167694091797,
            0.01784822717308998,
            -0.011104763485491276,
            -0.00484074279665947,
            -0.003302489873021841,
            -0.01260682288557291,
            0.00819010753184557,
            -0.005280721001327038,
            -0.023246394470334053,
            -0.021175233647227287,
            -0.012032031081616879,
            0.01582052744925022,
            0.0036600546445697546,
            0.019427793100476265,
            -0.010374713689088821,
            0.01853877119719982,
            0.032518357038497925,
            -0.005295789800584316,
            0.013856649398803711,
            0.003295169211924076,
            0.015044493600726128,
            0.0109071871265769,
            0.02960697002708912,
            -0.09534506499767303,
            -0.02034650184214115,
            0.01026866678148508,
            0.02945459634065628,
            0.006734236143529415,
            0.020443735644221306,
            -0.046657707542181015,
            0.021760720759630203,
            0.002559529384598136,
            0.016499163582921028,
            -0.02226933091878891,
            -0.025478674098849297,
            -0.03111962415277958,
            -0.001459264662116766,
            0.03958970308303833,
            0.0405905619263649,
            0.015186464414000511,
            0.013933119364082813,
            -0.010904429480433464,
            -0.029086651280522346,
            0.004241228103637695,
            -0.015647239983081818,
            -0.025174656882882118,
            -0.004137899726629257,
            0.03723598271608353,
            0.012213533744215965,
            -0.00906947162002325,
            -0.006503209937363863,
            -0.011566203087568283,
            -0.030007006600499153,
            -0.009077870287001133,
            -0.02339629828929901,
            0.02833578549325466,
            0.020473908632993698,
            0.02909247949719429,
            0.022150587290525436,
            -0.04852989688515663,
            -0.007713028229773045,
            -0.0014332449063658714,
            -0.010358893312513828,
            -0.013795715756714344,
            0.00562659278512001,
            0.013167870230972767,
            -0.02896709367632866,
            -0.036174193024635315,
            -0.026283880695700645,
            0.0004554928163997829,
            0.023899924010038376,
            -0.03553666174411774,
            0.03713502734899521,
            -0.017779970541596413,
            -0.019026154652237892,
            -0.020301736891269684,
            -0.038889989256858826,
            -0.007624667603522539,
            -0.057981863617897034,
            -0.011868943460285664,
            0.00981131847947836,
            0.010472282767295837,
            0.009640607051551342,
            0.03068895824253559,
            -0.02318587340414524,
            0.00493332976475358,
            -0.036624353379011154,
            0.0022262507118284702,
            0.0036627077497541904,
            0.04186940938234329,
            0.061345115303993225,
            0.020082060247659683,
            0.011773921549320221,
            -0.052626874297857285,
            0.03096485696732998,
            0.020319879055023193,
            -0.009452938102185726,
            -0.027213089168071747,
            0.024018317461013794,
            -0.01085021160542965,
            -0.03329133614897728,
            0.009228759445250034,
            0.02751057595014572,
            0.020673882216215134,
            0.021443743258714676,
            0.058609381318092346,
            -0.01171701680868864,
            0.011842766776680946,
            0.02894021011888981,
            0.0132902218028903,
            0.0009269436704926193,
            0.011515116319060326,
            -0.007886041887104511,
            0.043332189321517944,
            -0.03119443729519844,
            -0.024426788091659546,
            0.0007790933595970273,
            -0.04605056345462799,
            0.006009947508573532,
            0.004859759472310543,
            0.010530399158596992,
            0.005973439663648605,
            -0.041663456708192825,
            0.015650250017642975,
            -0.03485364839434624,
            -0.019477704539895058,
            0.010807042941451073,
            -0.00766284205019474,
            0.02700064331293106,
            -0.006113502662628889,
            -0.020879486575722694,
            -0.013047408312559128,
            -0.008234071545302868,
            0.025867072865366936,
            -0.02664196863770485,
            -0.020287873223423958,
            -0.034713126718997955,
            -0.013490556739270687,
            -0.0017109991749748588,
            0.026651829481124878,
            -0.027621475979685783,
            0.00794246420264244,
            0.016447369009256363,
            0.014410467818379402,
            0.02828739397227764,
            -0.018654746934771538,
            0.05450531840324402,
            0.029382020235061646,
            -0.016014447435736656,
            0.014607341960072517,
            0.02539863809943199,
            0.02227526530623436,
            -0.007246346212923527,
            0.02363068237900734,
            0.0015049149515107274,
            0.0069059706293046474,
            0.021442217752337456,
            -0.05236073583364487,
            -0.029328500851988792,
            0.0017754386644810438,
            0.0017035853816196322,
            0.010299422778189182,
            0.00918781477957964,
            -0.006592634133994579,
            -0.030837470665574074,
            0.0143474992364645,
            0.0013869196409359574,
            -0.014966330491006374,
            -0.04226946458220482,
            -0.008704148232936859,
            0.0074142091907560825,
            -0.0004995670751668513,
            -0.04521080106496811,
            -0.015085372142493725,
            0.01342376135289669,
            -0.0023897155188024044,
            0.008295461535453796,
            0.01081135869026184,
            -0.011484000831842422,
            -0.018673768267035484,
            -0.05826015770435333,
            -0.013306674547493458,
            0.026080550625920296,
            -0.02266317419707775,
            -0.014778525568544865,
            -0.03056447207927704,
            -0.004416074138134718,
            0.009253540076315403,
            0.006155302748084068,
            -0.01712103560566902,
            0.016376793384552002,
            -0.019224626943469048,
            -0.017995821312069893,
            -0.034731484949588776,
            -0.02893024869263172,
            -0.015457317233085632,
            -0.034786809235811234,
            -0.027743572369217873,
            0.0004757928545586765,
            0.035495903342962265,
            0.020445622503757477,
            -0.017839359119534492,
            0.011344893835484982,
            0.01625867746770382,
            0.051943615078926086,
            0.02122298814356327,
            -0.004299858585000038,
            -0.010446429252624512,
            0.013382093980908394,
            0.009088689461350441,
            -0.001449717441573739,
            -0.021157966926693916,
            0.004466240759938955,
            -0.03445804864168167,
            0.0069388896226882935,
            -0.02532396838068962,
            -0.004856166895478964,
            -0.01534263789653778,
            -0.009379305876791477,
            -0.02852429449558258,
            -0.00910159945487976,
            0.0022147540003061295,
            -0.02167401649057865,
            -0.04162127897143364,
            0.04678235203027725,
            0.002273108111694455,
            0.013153323903679848,
            -0.013097136281430721,
            0.036085519939661026,
            -0.023743709549307823,
            0.01717129722237587,
            -0.021910598501563072,
            0.0041053337045013905,
            -0.03143946826457977,
            0.01101488247513771,
            -0.04301905632019043,
            -0.01814284920692444,
            0.02576073817908764,
            -0.015095986425876617,
            0.009551341645419598,
            -0.030132107436656952,
            -0.0002942602732218802,
            0.013233712874352932,
            -0.004256621468812227,
            -0.020791009068489075,
            0.00487569160759449,
            0.00559263164177537,
            0.030468184500932693,
            -0.03307368978857994,
            -0.059892259538173676,
            0.01390255056321621,
            -0.014744983054697514,
            0.021523963660001755,
            -0.012772920541465282,
            0.005914389621466398,
            -0.016171950846910477,
            -0.018688522279262543,
            -0.013940182514488697,
            0.024509163573384285,
            -0.009295199997723103,
            -0.012663058005273342,
            -0.024721266701817513,
            0.031451042741537094,
            -0.032515767961740494,
            0.015205285511910915,
            0.007659507915377617,
            -0.01396676991134882,
            0.007566037587821484,
            -0.0007280110730789602,
            0.021184725686907768,
            0.008724330924451351,
            -0.05041884630918503,
            0.029465271160006523,
            0.030619297176599503,
            0.003978887107223272,
            0.017659902572631836,
            0.0020677801221609116,
            0.009844083338975906,
            -0.0266848374158144,
            -0.016209676861763,
            0.008856665343046188,
            0.01934879831969738,
            -0.03899441659450531,
            0.008318915031850338,
            0.050207242369651794,
            0.05266793072223663,
            -0.016634218394756317,
            -0.01930813491344452,
            -0.06853598356246948,
            0.02415519207715988,
            -0.003890552558004856,
            -0.03893992677330971,
            0.019676271826028824,
            -0.0309738889336586,
            0.013220216147601604,
            -0.02461354061961174,
            0.017405608668923378,
            0.014490032568573952,
            0.012240417301654816,
            0.019273217767477036,
            -0.03647615760564804,
            0.04210652410984039,
            0.023877471685409546,
            -0.0033592244144529104,
            0.020577816292643547,
            -0.02776549756526947,
            -0.033179037272930145,
            -0.013859555125236511,
            -0.025150097906589508,
            0.03607712313532829,
            0.004976165015250444,
            0.014402269385755062,
            0.03370505943894386,
            0.03472689166665077,
            -0.027822110801935196,
            0.009572762064635754,
            -0.03289001062512398,
            -0.024028846994042397,
            0.012715264223515987,
            -0.022038478404283524,
            0.030042041093111038,
            -0.004711729008704424,
            0.020115559920668602,
            0.03443785384297371,
            -0.009846244938671589,
            0.014473682269454002,
            0.018105240538716316,
            -0.0028836883138865232,
            0.009857668541371822,
            0.005114092957228422,
            -0.013533447869122028,
            0.003040966344997287,
            -0.07504496723413467,
            0.023250488564372063,
            -0.00746677303686738,
            -0.015374102629721165,
            -0.0013670251937583089,
            -0.022713642567396164,
            0.011153440922498703,
            -0.01692143827676773,
            0.01744093932211399,
            -0.01702781207859516,
            0.024669328704476357,
            0.008586460724473,
            -0.000855546270031482,
            -0.00846609566360712,
            0.06148561090230942,
            0.021046141162514687,
            0.0268000066280365,
            -0.017093965783715248,
            0.00018263065430801362,
            0.0038313413970172405,
            0.030256731435656548,
            0.015581244602799416,
            0.011981042101979256,
            -0.014511523768305779,
            -0.013278082944452763,
            -0.014055564068257809,
            -0.019780481234192848,
            -0.014751016162335873,
            -0.01570359617471695,
            0.017515745013952255,
            0.019561266526579857,
            -0.013015998527407646,
            -0.006238461937755346,
            -0.01710750162601471,
            -0.01200738362967968,
            -0.011258981190621853,
            -0.040525611490011215,
            0.024788344278931618,
            -0.0012897304259240627,
            -0.008861642330884933,
            4.569999873638153e-05,
            0.03218221664428711,
            0.052828267216682434,
            -0.07439980655908585,
            0.019239244982600212,
            -0.013375649228692055,
            0.020917432382702827,
            0.008404220454394817,
            -0.00018223209190182388,
            0.027960442006587982,
            0.010481499135494232,
            0.008378524333238602,
            -0.045020315796136856,
            0.0104625029489398,
            -0.001325976918451488,
            0.02439991384744644,
            0.003412076970562339,
            -0.050067320466041565,
            0.014550423249602318,
            0.020612921565771103,
            0.05860363319516182,
            0.012147778645157814,
            0.031909503042697906,
            -0.003275677328929305,
            -0.004972916562110186,
            -0.00639792624861002,
            0.0224580280482769,
            0.011503178626298904,
            -0.01743610017001629,
            -0.0017436320194974542,
            -0.014215207658708096,
            0.02631152607500553,
            -0.013459043577313423,
            -0.030059145763516426,
            -0.022085146978497505,
            -0.013613841496407986,
            0.01479241531342268,
            -0.004574342165142298,
            -0.0006720662349835038,
            -0.009053739719092846,
            -0.0022353483363986015,
            -0.010213599540293217,
            -0.004586540628224611,
            -0.012990094721317291,
            0.011283877305686474,
            0.052502091974020004,
            0.029324419796466827,
            0.04188014566898346,
            0.01420862227678299,
            0.009210765361785889,
            0.0013846626970916986,
            -0.024624992161989212,
            0.01571463979780674,
            0.009267663583159447,
            -0.030745871365070343,
            -0.006938772741705179,
            0.024056825786828995,
            -0.023843389004468918,
            0.005531385540962219,
            -0.027284720912575722,
            -0.00777086615562439,
            0.008788629435002804,
            -0.02322634682059288,
            0.03835170343518257,
            -0.02824127860367298,
            0.02103256620466709,
            0.03136865422129631,
            -0.033329617232084274,
            0.03813878446817398,
            0.047941725701093674,
            0.025131477043032646,
            0.004855259787291288,
            0.03696954995393753,
            -0.03296608850359917,
            -0.018865497782826424,
            -0.03310409188270569,
            -0.013820664025843143,
            -0.015069114044308662,
            -0.02305630035698414,
            0.01472712866961956,
            0.0005438136868178844,
            -0.04600413143634796,
            0.04108748584985733,
            -0.03085041232407093,
            0.039915505796670914,
            0.020414721220731735,
            0.00677537452429533,
            0.06523989140987396,
            -0.05098786577582359,
            -0.010217148810625076,
            0.014149930328130722,
            -0.019546128809452057,
            0.02689460664987564,
            0.027260679751634598,
            -0.06785830855369568,
            0.02479061670601368,
            -0.04056497663259506,
            -0.02706574648618698,
            -0.03769930452108383,
            -0.020164677873253822,
            0.012066123075783253,
            -0.013253921642899513,
            0.007517571095377207,
            -0.0038560479879379272,
            -0.035317935049533844,
            -0.02336561307311058,
            -0.033738069236278534,
            -0.012293192557990551,
            -0.01786458119750023,
            -0.017069390043616295,
            -0.03282094746828079,
            0.004236037842929363,
            -0.04181681573390961,
            0.033399175852537155,
            0.026182010769844055,
            0.025032436475157738,
            -0.0057462104596197605,
            0.028727324679493904,
            0.010171476751565933,
            -0.02513246424496174,
            -0.018288474529981613,
            -0.012594752945005894,
            -0.018774863332509995,
            -0.0008974222000688314,
            0.021905628964304924,
            -0.030375847592949867,
            -0.04194788634777069,
            0.007504744455218315,
            -0.0025867854710668325,
            0.006115194410085678,
            -0.018498199060559273,
            -0.03347594290971756,
            -0.023870954290032387,
            -0.029164042323827744,
            -0.022866593673825264,
            0.041199833154678345,
            -0.031233100220561028,
            -0.022062063217163086,
            -0.019504345953464508,
            -0.012821352109313011,
            0.009464899078011513,
            -0.06294316053390503,
            -0.01891377754509449,
            -0.045872922986745834,
            -0.029692115262150764,
            -0.006579778157174587,
            -0.022806482389569283,
            0.0064334142953157425,
            -0.022590836510062218,
            -0.007242207881063223,
            -0.004052093252539635,
            -0.030324352905154228,
            0.0068605816923081875,
            0.005324210971593857,
            -0.02830774150788784,
            -0.02112274244427681,
            0.03283185511827469,
            -0.02120528556406498,
            0.0004619511601049453,
            -0.021129120141267776,
            -0.019927697256207466,
            -0.009832981042563915,
            0.008253500796854496,
            -0.029669171199202538
        ],
        [
            -0.01978517882525921,
            -0.0279915239661932,
            -0.0045303720980882645,
            -0.0106505760923028,
            0.036066990345716476,
            -0.03966771811246872,
            0.001956689404323697,
            -0.023672649636864662,
            0.009809625335037708,
            0.0319693423807621,
            -0.028872817754745483,
            0.0449945293366909,
            -0.03320379927754402,
            0.021577760577201843,
            0.041180066764354706,
            0.021064970642328262,
            -0.0040580956265330315,
            0.030592069029808044,
            -0.004442764446139336,
            -0.006695575546473265,
            -0.02990032732486725,
            -0.026594199240207672,
            -0.023058952763676643,
            0.027727575972676277,
            0.017648126929998398,
            -0.00012246397091075778,
            0.01655503176152706,
            -0.00928002130240202,
            0.020949775353074074,
            -0.03032831661403179,
            0.03816554322838783,
            -0.013336834497749805,
            -0.021721117198467255,
            0.023955337703227997,
            -0.00026637755217961967,
            0.03899836167693138,
            -0.036574915051460266,
            0.011362428776919842,
            -0.000647680601105094,
            -0.018085461109876633,
            -0.0104063143953681,
            -0.004865648224949837,
            -0.016519853845238686,
            0.027007531374692917,
            -0.021200958639383316,
            -0.04291160777211189,
            0.00452381419017911,
            0.021444927901029587,
            0.023292575031518936,
            -0.023416660726070404,
            0.009838728234171867,
            0.0034271699842065573,
            -0.06805070489645004,
            -0.03021376207470894,
            0.0430385023355484,
            0.01666616089642048,
            -0.012587236240506172,
            0.009964142926037312,
            0.007181813940405846,
            0.01056071650236845,
            -0.03085245005786419,
            -0.02234434336423874,
            0.0026010768488049507,
            -0.020750781521201134,
            -0.0201663076877594,
            -0.006083184387534857,
            -0.05309658497571945,
            0.00016375916311517358,
            0.021199341863393784,
            0.028676221147179604,
            -0.013405702076852322,
            0.03567273169755936,
            -0.011542916297912598,
            0.030673790723085403,
            -0.015992267057299614,
            0.0434836708009243,
            -0.01860118843615055,
            -0.005876774899661541,
            -0.051717910915613174,
            -0.0020664918702095747,
            -0.00988764688372612,
            0.008417632430791855,
            0.023394117131829262,
            -0.012651621364057064,
            0.0058157360181212425,
            0.026207303628325462,
            -0.015775373205542564,
            0.0355999581515789,
            0.021825486794114113,
            -0.024339715018868446,
            0.012857861816883087,
            -0.04020300507545471,
            0.010855145752429962,
            0.031156761571764946,
            -0.008673880249261856,
            0.01071467436850071,
            0.009004851803183556,
            0.01772734895348549,
            -0.06552799791097641,
            0.007873425260186195,
            0.007338486146181822,
            -0.012860367074608803,
            0.0325692854821682,
            -0.028066610917448997,
            0.00226439512334764,
            0.032010603696107864,
            -0.018223628401756287,
            -0.017986582592129707,
            0.03449373319745064,
            0.01793058216571808,
            0.012427173554897308,
            -0.02330908179283142,
            0.0044637457467615604,
            0.003958937246352434,
            -0.0914916843175888,
            -0.023691799491643906,
            -0.021856416016817093,
            -0.016029607504606247,
            0.02856600470840931,
            -0.01733662188053131,
            -0.025751283392310143,
            -0.023812027648091316,
            0.027559848502278328,
            -0.021979592740535736,
            0.029943497851490974,
            -0.004727776162326336,
            -0.01001800037920475,
            0.0016631988110020757,
            0.02066039852797985,
            -0.0002076681994367391,
            -0.04476843774318695,
            -0.0007507337722927332,
            -0.04034440964460373,
            -0.005112799815833569,
            0.030029410496354103,
            0.034366708248853683,
            0.010428406298160553,
            -0.011239330284297466,
            -0.0027141619939357042,
            -0.017074426636099815,
            -0.0041611529886722565,
            -0.008225261233747005,
            0.025754861533641815,
            0.004528445657342672,
            -0.0173636544495821,
            -0.02329244278371334,
            -0.017641408368945122,
            0.020687395706772804,
            0.013816787861287594,
            -0.013169259764254093,
            0.015660468488931656,
            0.00931206252425909,
            -0.014725358225405216,
            -0.021450981497764587,
            -0.0533800944685936,
            0.02535095438361168,
            -0.026487143710255623,
            0.010305654257535934,
            -0.052119724452495575,
            -0.0439777709543705,
            0.009539803490042686,
            0.014424187131226063,
            -0.003210943890735507,
            -0.0036951808724552393,
            -0.039394598454236984,
            0.022075453773140907,
            -0.0017613650998100638,
            -0.023805636912584305,
            0.03681270778179169,
            -0.02542276494204998,
            -0.008645866066217422,
            -0.033485446125268936,
            0.01068880409002304,
            -0.01621546596288681,
            -0.007708466146141291,
            0.05061102285981178,
            -0.05415615811944008,
            -0.014435280114412308,
            0.015983864665031433,
            0.023223422467708588,
            -0.00948207825422287,
            -0.00920822937041521,
            -0.019901493564248085,
            -0.01821639947593212,
            0.019820597022771835,
            0.021050041541457176,
            0.0028049445245414972,
            -0.021318819373846054,
            -0.0019284888403490186,
            0.03685323894023895,
            -0.00948261097073555,
            -0.0143323615193367,
            -0.07148396968841553,
            0.011144466698169708,
            -0.024344908073544502,
            0.02985820733010769,
            -0.01946791633963585,
            0.005577594041824341,
            0.022615628316998482,
            0.0007874469156377017,
            -0.000692483619786799,
            0.0019407016225159168,
            -0.01107074972242117,
            0.0052614170126616955,
            0.024085525423288345,
            0.017963087186217308,
            0.017783720046281815,
            0.004808344412595034,
            0.01741102710366249,
            -0.006203808356076479,
            -0.02346363104879856,
            0.0021895228419452906,
            0.03850329667329788,
            0.00390995480120182,
            -0.011120351031422615,
            0.00764858303591609,
            -0.027275659143924713,
            0.021878808736801147,
            -0.02048736810684204,
            0.01981092430651188,
            0.043546754866838455,
            0.0029703585896641016,
            0.035122618079185486,
            -0.024624401703476906,
            0.027315836399793625,
            -0.043682463467121124,
            0.003378465073183179,
            -0.018235279247164726,
            0.01882270723581314,
            -0.028043530881404877,
            -0.03463646396994591,
            0.005786106921732426,
            -0.01811620220541954,
            -0.005891134962439537,
            -0.0328698456287384,
            -0.005755263846367598,
            -0.0361856073141098,
            -0.026468781754374504,
            -0.004414482973515987,
            0.025283779948949814,
            -0.015663722530007362,
            -0.024702660739421844,
            -0.0002226343349320814,
            0.04027114063501358,
            0.05135999247431755,
            -0.034598417580127716,
            -0.03083733096718788,
            0.018790151923894882,
            -0.011726131662726402,
            0.005418459884822369,
            0.027654524892568588,
            -0.0296140369027853,
            0.01833481714129448,
            0.009045382030308247,
            0.04826846718788147,
            0.031398482620716095,
            0.0097543615847826,
            0.020303644239902496,
            0.029902586713433266,
            -0.0061579858884215355,
            0.025764314457774162,
            0.04027318209409714,
            0.006009391974657774,
            -0.00806690938770771,
            0.022139795124530792,
            0.016465436667203903,
            -0.007250381167978048,
            -0.01555385161191225,
            -0.021448075771331787,
            0.010373188182711601,
            0.0090139489620924,
            0.0035724849440157413,
            -0.012154783122241497,
            0.007716543041169643,
            0.05124282464385033,
            0.03693773224949837,
            0.029249707236886024,
            0.012618484906852245,
            -0.02696291171014309,
            -0.010036135092377663,
            0.04555590823292732,
            0.03349481150507927,
            0.0255696140229702,
            -0.0004568191070575267,
            0.008375469595193863,
            0.02004065178334713,
            -0.010191668756306171,
            -0.0033943047747015953,
            0.023981863632798195,
            0.03137260302901268,
            -0.00634751794859767,
            -0.01310355868190527,
            0.02853536047041416,
            -0.029774822294712067,
            0.005065395496785641,
            -0.015482082962989807,
            0.031817175447940826,
            -0.01065465621650219,
            0.0014476176584139466,
            -0.009107274003326893,
            -0.04329559952020645,
            0.023804334923624992,
            0.022953271865844727,
            -0.0066675771959125996,
            -0.013705781660974026,
            0.013597647659480572,
            0.010744918137788773,
            0.023038789629936218,
            -0.014372258447110653,
            -0.05784996226429939,
            0.03760455176234245,
            0.021994179114699364,
            0.01853322610259056,
            -0.004226823337376118,
            -0.016124535351991653,
            -0.03524027392268181,
            0.04872061684727669,
            0.024601319804787636,
            -0.03525976836681366,
            0.012714837677776814,
            0.008693247102200985,
            0.05156891793012619,
            -0.021528566256165504,
            -0.030000003054738045,
            0.02263774164021015,
            -0.020468777045607567,
            -0.06695733219385147,
            0.0308285653591156,
            -0.020963154733181,
            0.011664895340800285,
            -0.013615971431136131,
            6.122363993199542e-05,
            0.0006935002747923136,
            0.013782750815153122,
            -0.04963590204715729,
            -0.008515771478414536,
            -0.030787687748670578,
            -0.01982731558382511,
            -0.018402639776468277,
            0.00979484710842371,
            0.005252676550298929,
            -0.0205589197576046,
            0.042407333850860596,
            0.014061873778700829,
            -0.03818170726299286,
            -0.011386462487280369,
            -0.022486411035060883,
            0.02675779163837433,
            -0.025530578568577766,
            -0.05391903966665268,
            0.005416449159383774,
            0.012192540802061558,
            -0.00694515323266387,
            0.022004282101988792,
            0.02476237714290619,
            -0.0007979608490131795,
            -0.016029883176088333,
            0.03816012665629387,
            0.001251842244528234,
            0.03593306243419647,
            -0.01271928008645773,
            0.0285940058529377,
            -0.051336534321308136,
            0.04508654773235321,
            -0.04981578141450882,
            -0.048473428934812546,
            0.05154663324356079,
            0.027232609689235687,
            -0.028603827580809593,
            0.029395125806331635,
            -0.015761859714984894,
            -0.0032469830475747585,
            -0.026477577164769173,
            -0.005547579377889633,
            0.02514878660440445,
            0.045472126454114914,
            -0.009434407576918602,
            -0.024414677172899246,
            -0.008964604698121548,
            -0.010007672011852264,
            -0.016728287562727928,
            -0.008768362924456596,
            -0.018912091851234436,
            0.018897416070103645,
            -0.03043016791343689,
            -0.04661820828914642,
            0.014491022564470768,
            0.016240382567048073,
            0.015309407375752926,
            -0.05015530809760094,
            -0.01594109646975994,
            0.01998976431787014,
            -0.04120398685336113,
            0.021303299814462662,
            -0.024688981473445892,
            -0.01553195621818304,
            0.04121268913149834,
            -0.009273995645344257,
            -0.02333224005997181,
            0.01460060104727745,
            -0.017924204468727112,
            -0.021144509315490723,
            -0.014981841668486595,
            0.030936235561966896,
            0.026552822440862656,
            0.023549837991595268,
            0.020223336294293404,
            0.03822024539113045,
            -0.004964833613485098,
            -0.02058246359229088,
            0.019259724766016006,
            0.00021728459978476167,
            0.02402682416141033,
            0.0487782321870327,
            0.006936151068657637,
            0.008420964702963829,
            0.02915557101368904,
            0.03553622215986252,
            0.0019620663952082396,
            -0.014317890629172325,
            0.018538549542427063,
            0.0005462918197736144,
            -0.05353601276874542,
            -0.021195191890001297,
            0.02872607298195362,
            -0.026493551209568977,
            0.003544694045558572,
            -0.04738488793373108,
            -0.01256165187805891,
            0.005201844032853842,
            -0.016102135181427002,
            0.038213249295949936,
            -0.01350538618862629,
            -0.04456380754709244,
            -0.015763528645038605,
            0.015679989010095596,
            0.009044678881764412,
            -0.022989556193351746,
            0.037041809409856796,
            -0.04465346783399582,
            0.014292346313595772,
            -0.0016964481910690665,
            -0.03405996784567833,
            -0.0040815044194459915,
            -0.017855888232588768,
            0.018771672621369362,
            -0.025680778548121452,
            -0.05078387260437012,
            -0.028650326654314995,
            -0.004159450531005859,
            0.026244141161441803,
            -0.01024892833083868,
            -0.014866535551846027,
            -0.015484321862459183,
            0.034497298300266266,
            0.03725912794470787,
            0.012404713779687881,
            0.01611940935254097,
            -0.005420952569693327,
            -0.0018898091511800885,
            -0.01639988273382187,
            0.02657063491642475,
            0.043044138699769974,
            0.0264736358076334,
            0.035317789763212204,
            -0.001312366221100092,
            -0.0197184756398201,
            0.03117980994284153,
            0.01426759734749794,
            -0.0027646978851407766,
            0.024482987821102142,
            0.013231181539595127,
            -0.01700667291879654,
            0.00948058720678091,
            0.0327761135995388,
            -0.011920962482690811,
            -0.01381878461688757,
            0.030782900750637054,
            -0.010223674587905407,
            0.03384729474782944,
            0.010588161647319794,
            -0.0024612995330244303,
            0.03747116029262543,
            -0.009998483583331108,
            -0.03982986882328987,
            0.006288664415478706,
            -0.030027158558368683,
            -0.01941573992371559,
            -0.10046770423650742,
            0.024033673107624054,
            -0.0197142381221056,
            0.00511886877939105,
            0.02013299986720085,
            0.025203701108694077,
            0.016887586563825607,
            0.0031706138979643583,
            0.009388361126184464,
            0.008525051176548004,
            -0.009211938828229904,
            -0.02133391797542572,
            -0.009880008175969124,
            0.019390353932976723,
            -0.013534500263631344,
            -0.04906132444739342,
            0.02780037932097912,
            -0.008531726896762848,
            -0.028358928859233856,
            -0.03263305872678757,
            -0.02120557427406311,
            0.0017586813773959875,
            0.028109736740589142,
            -0.0517163909971714,
            -0.028415249660611153,
            0.010708851739764214,
            -0.0010062978835776448,
            -0.010838881134986877,
            -0.013817566446959972,
            0.008193392306566238,
            -0.009444070048630238,
            -0.0002706274390220642,
            -9.681640949565917e-05,
            -0.005799277685582638,
            -0.01704745553433895,
            0.023778971284627914,
            0.005283149890601635,
            -0.023752156645059586,
            0.02773875743150711,
            0.02831176482141018,
            0.017589151859283447,
            0.007410293910652399,
            -0.021931597962975502,
            0.023394349962472916,
            -0.050539530813694,
            0.00018529032240621746,
            -0.02703402377665043,
            0.06295344233512878,
            -0.0006341092521324754,
            0.01152214128524065,
            -0.028655050322413445,
            -0.007074933499097824,
            -0.004314697813242674,
            0.006509433034807444,
            0.005850823130458593,
            -0.0728241577744484,
            -0.012238401919603348,
            0.06765153259038925,
            0.008785822428762913,
            -0.015596166253089905,
            -0.0005683654453605413,
            0.006469884421676397,
            0.03092610277235508,
            0.0007273309165611863,
            -0.029353413730859756,
            -0.008088367059826851,
            0.00441603222861886,
            -0.028678761795163155,
            -0.020167242735624313,
            -0.053325992077589035,
            0.011917678639292717,
            0.011694909073412418,
            -0.003861804027110338,
            0.013291362673044205,
            0.019978893920779228,
            0.03644207864999771,
            0.03005249612033367,
            -0.0026680047158151865,
            -0.009953102096915245,
            0.0007974602631293237,
            0.030739299952983856,
            0.018436085432767868,
            0.03193439543247223,
            -0.025961702689528465,
            0.006990663707256317,
            0.016070421785116196,
            -0.009138654917478561,
            0.008190756663680077,
            0.028440868481993675,
            -0.0031022883486002684,
            0.027394196018576622,
            0.004710308276116848,
            0.0024503571912646294,
            0.013705205172300339,
            -0.011011192575097084,
            -0.026653915643692017,
            0.02636701427400112,
            0.030202457681298256,
            0.005103107076138258,
            0.0191960409283638,
            0.0396154560148716,
            0.014429300092160702,
            0.034170374274253845,
            0.022886592894792557,
            0.02974652871489525,
            0.0019909313414245844,
            0.0014045160496607423,
            -0.0026348361279815435,
            0.044280730187892914,
            0.018386397510766983,
            0.007411073427647352,
            0.03727198392152786,
            -0.05856352671980858,
            0.015716953203082085,
            0.0003861163859255612,
            -0.04650581628084183,
            -0.0008946661255322397,
            -0.011686811223626137,
            -0.02650243416428566,
            0.014453250914812088,
            0.055116426199674606,
            -0.013440465554594994,
            -0.0036508420016616583,
            -0.02516932599246502,
            0.02619483508169651,
            -0.012441900558769703,
            -0.0003475105622783303,
            -0.032439637929201126,
            0.042317140847444534,
            -0.04441201314330101,
            -0.01388924103230238,
            0.001698352862149477,
            -0.005591460503637791,
            -0.0033703588414937258,
            0.028080783784389496,
            0.020409155637025833,
            0.0042883469723165035,
            -0.0008525288430973887,
            0.0015512561658397317,
            -0.03557264804840088,
            -0.011823493987321854,
            -0.04830373078584671,
            -0.01807039976119995,
            -0.028480075299739838,
            0.00018332373292651027,
            0.024110078811645508,
            0.023369336500763893,
            0.0042685456573963165,
            -0.020126907154917717,
            -0.010620943270623684,
            -0.008179261349141598,
            0.0071973963640630245,
            0.008485214784741402,
            -0.01404888927936554,
            -0.002238881541416049,
            -0.04512801021337509,
            -0.0021195379085838795,
            0.02392379753291607,
            0.011254232376813889,
            -0.007808665744960308,
            -0.019968684762716293,
            -0.0007597368094138801,
            0.004058471880853176,
            -0.007836751639842987,
            0.004650590941309929,
            -0.004130328074097633,
            -0.01648806594312191,
            -0.01462014764547348,
            -0.01644173078238964,
            -0.022792844101786613,
            -0.04282139614224434,
            0.011890712194144726,
            0.02253875322639942,
            -0.03343468904495239,
            -0.025487851351499557,
            0.00040388392517343163,
            -0.02302183397114277,
            -0.01782676950097084,
            -0.009497616440057755,
            -0.03351198881864548,
            -0.012831690721213818,
            0.007000287063419819,
            0.015721114352345467,
            -0.07697977870702744,
            -0.0012560389004647732,
            -0.005195330362766981,
            -0.019352607429027557,
            -0.04399881511926651,
            0.018811723217368126,
            -0.0008597018313594162,
            0.002453377703204751,
            0.0072761173360049725,
            -0.011893989518284798,
            0.02019311673939228,
            -0.029253454878926277,
            -0.028489956632256508,
            0.017838861793279648,
            -0.004324677865952253,
            0.015466528944671154,
            0.02321232296526432,
            -0.004190072417259216,
            0.011337623931467533,
            0.010406507179141045,
            -0.000684727041516453,
            0.024472828954458237,
            0.027760565280914307,
            0.03628326207399368,
            -0.005166297312825918,
            0.001900051487609744,
            0.01570936292409897,
            0.021550685167312622,
            0.06091250106692314,
            0.00125813705381006,
            -0.011347057297825813,
            0.023641791194677353,
            -0.018369607627391815,
            0.026455452665686607,
            0.0313839316368103,
            -0.015622373670339584,
            -0.015237709507346153,
            0.026610514149069786,
            0.019270507618784904,
            -0.026573963463306427,
            0.01746714673936367,
            0.04480039328336716,
            0.001703533693216741,
            0.03678286075592041,
            0.0230580884963274,
            0.04190649092197418,
            -0.025898072868585587,
            0.026036199182271957,
            -0.012144933454692364,
            0.015138850547373295,
            -0.002101072110235691,
            0.032290637493133545,
            -0.026879701763391495,
            -0.015535703860223293,
            0.004210281185805798,
            -0.013156811706721783,
            -0.01947755180299282,
            0.009679422713816166,
            -0.022201312705874443,
            -0.01194102130830288,
            -0.03155316412448883,
            0.00017561695131007582,
            0.016494998708367348,
            0.021793773397803307,
            0.010283336043357849,
            0.008226069621741772,
            0.04075700044631958,
            -0.01593673601746559,
            0.019842876121401787,
            -0.009167429059743881,
            -0.001133326324634254,
            -0.021658433601260185,
            -0.022829176858067513,
            -0.025342868641018867,
            -0.0021903503220528364,
            0.017267214134335518,
            -0.014492327347397804,
            -0.0018985781352967024,
            0.01449380163103342,
            -0.010353710502386093,
            -0.023577073588967323,
            0.004957644268870354,
            -0.03130984678864479,
            -0.00990652572363615,
            -0.024573490023612976,
            0.01003925409168005,
            -0.05137564241886139,
            0.023464979603886604,
            -0.024633513763546944,
            0.014429268427193165,
            0.025021115317940712,
            0.020562315359711647,
            0.019749125465750694,
            -0.03113950975239277,
            0.019331322982907295,
            0.03286328539252281,
            0.02869175747036934,
            -0.023314809426665306,
            0.013267836533486843,
            0.038400765508413315,
            -0.029836151748895645,
            -0.029530400410294533,
            0.049924347549676895,
            -0.01378160621970892,
            0.024468468502163887,
            0.005121136549860239,
            -0.0074681988917291164,
            -0.0069371601566672325,
            0.019326895475387573,
            -0.006574312224984169,
            0.006385833490639925,
            -0.025787068530917168,
            -0.01996135711669922,
            0.022894002497196198,
            -0.03536437079310417,
            -0.0033822646364569664,
            -0.020427515730261803,
            0.007315020076930523,
            0.029148301109671593,
            0.012391513213515282,
            -0.012653689831495285,
            -0.007418621797114611,
            -0.05857209861278534,
            0.0002916855737566948,
            0.011210957542061806,
            -0.05668150261044502,
            -0.009206968359649181,
            0.03390270099043846,
            -0.028089504688978195,
            0.004973151255398989,
            -0.01737165078520775,
            0.009643221274018288,
            -0.019620515406131744,
            -0.051703017204999924,
            -0.005842519458383322,
            -0.003946459852159023,
            0.01870455965399742,
            -0.025702955201268196,
            -0.0003967324155382812,
            -0.018127504736185074,
            -0.004732990637421608,
            -0.009049483574926853,
            -0.007853490300476551,
            0.005247338674962521,
            0.027545075863599777,
            0.027528513222932816,
            0.01930958405137062,
            0.0007212995551526546,
            -0.02096845954656601,
            0.010481682606041431,
            -0.009926232509315014,
            -0.006581470370292664,
            0.0038468302227556705,
            0.014026530086994171,
            0.028255673125386238,
            -0.009166767820715904,
            0.013859706930816174,
            0.0023438851349055767,
            -0.007375706918537617,
            -0.01661754585802555,
            0.004254126455634832,
            0.03851429373025894,
            0.015440890565514565,
            0.01412232406437397,
            -0.016669774428009987,
            -0.01791756972670555,
            0.01149394828826189,
            0.03678319230675697,
            0.001559943426400423,
            -0.02092745155096054,
            -0.019678272306919098,
            -0.005459604784846306,
            0.032729197293519974,
            -0.021434426307678223,
            -0.023527752608060837,
            0.010929473675787449,
            -0.007979781366884708,
            -0.013248568400740623,
            0.004257231950759888,
            0.025919554755091667,
            -0.015502909198403358,
            0.033979494124650955,
            0.02650299482047558,
            -0.04328851401805878,
            0.0024197541642934084,
            0.006730981171131134,
            -0.00909341685473919,
            -0.03227280080318451,
            -0.0030670897103846073,
            -0.001068625831976533,
            -0.004706881009042263,
            -0.019873255863785744,
            -0.0024895197711884975,
            0.009604579769074917,
            0.0015557511942461133,
            0.020135631784796715,
            -0.012947133742272854,
            0.02682819962501526,
            -0.003607485443353653,
            0.004167824052274227,
            0.0007439414621330798,
            0.01815057545900345,
            0.029028862714767456,
            -0.004120679572224617,
            -0.004180056042969227,
            0.021234292536973953,
            -0.09111897647380829,
            -0.022607160732150078,
            0.01952402852475643,
            0.006747344974428415,
            -0.005798282101750374,
            0.02649056911468506,
            0.018644874915480614,
            -0.047052379697561264,
            -0.02244226261973381,
            0.0050656478852033615,
            -0.016626723110675812,
            -0.021831147372722626,
            -0.004088053945451975,
            -0.00021235508029349148,
            0.015089134685695171,
            -0.01949973963201046,
            -0.004361458122730255,
            0.011019877158105373,
            0.0007159364176914096,
            -0.010659386403858662,
            0.03200101852416992,
            -0.011163187213242054,
            -0.03019045665860176,
            0.01526129711419344,
            -0.008641854859888554,
            0.004590254742652178,
            0.01073446311056614,
            0.04907209053635597,
            -0.008659929037094116,
            -0.0378246046602726,
            -0.05451750382781029,
            -0.003671146696433425,
            0.017189551144838333,
            0.0019119075732305646,
            0.007613972295075655,
            -0.03124081715941429,
            0.030057722702622414,
            0.0005731394048780203,
            -0.02284347452223301,
            -0.007920139469206333,
            -0.01899009197950363,
            -0.003078213892877102,
            0.003051052102819085,
            -0.00856376625597477,
            -0.012383271008729935,
            -0.0007771385135129094,
            -0.020809868350625038,
            0.007116927765309811,
            0.0004021592903882265,
            -0.019223058596253395,
            0.005304425489157438,
            0.004030163865536451,
            -0.009651280008256435,
            -0.0001235395611729473,
            0.03917380049824715,
            0.002520517446100712,
            0.023480158299207687,
            0.03315562754869461,
            -0.006462062709033489,
            0.003669322934001684,
            0.003978550899773836,
            0.006186928134411573,
            0.04424670711159706,
            -0.01127663068473339,
            -0.02807893417775631,
            0.013760501518845558,
            0.02305084653198719,
            0.006343412678688765,
            0.029920944944024086,
            0.026324080303311348,
            0.009384585544466972,
            -0.02303619682788849,
            0.023320859298110008,
            -0.000675438845064491,
            0.001961344387382269,
            -0.004084313753992319,
            0.02002578042447567,
            -0.020075086504220963,
            0.041492920368909836,
            -0.031211774796247482,
            -0.02145346812903881,
            0.0244061890989542,
            0.0014665300259366632,
            -0.06106209754943848,
            0.019781826063990593,
            -0.014053767547011375,
            -0.032476481050252914,
            -0.005159322638064623,
            -0.041693635284900665,
            -0.042689237743616104,
            -0.014496852643787861,
            -0.011782707646489143,
            -0.010510188527405262,
            0.007972808554768562,
            0.0007681834977120161,
            -0.0063810585997998714,
            0.01444732490926981,
            -0.04295162111520767,
            0.017167018726468086,
            0.011364372447133064,
            0.015153740532696247,
            0.008243776857852936,
            0.017179375514388084,
            -0.03938791900873184,
            -0.03469555079936981,
            -0.05440182983875275,
            -0.01772807165980339,
            -0.03879234939813614,
            0.0024716106709092855,
            -0.030723603442311287,
            0.012902241200208664,
            0.008701955899596214,
            0.00758166890591383,
            -0.008007405325770378,
            -0.018244668841362,
            -0.0010478683980181813,
            -0.008087530732154846,
            0.015832260251045227,
            0.04315895214676857,
            0.025959419086575508,
            0.002711229957640171,
            -0.048765119165182114,
            0.037467654794454575,
            -0.027936602011322975,
            0.01619154028594494,
            0.01739414781332016,
            -0.008609130047261715,
            -0.02033274434506893,
            0.03394424542784691,
            0.014481459744274616,
            -0.025358088314533234,
            0.02080630511045456,
            -0.019260616973042488,
            0.007518215570598841,
            0.0017576616955921054,
            0.009801888838410378,
            0.021097928285598755,
            -0.0024700378999114037,
            -0.027155211195349693,
            -0.015414648689329624,
            -0.03661215305328369,
            0.021383466199040413,
            0.019328638911247253,
            -0.028678106144070625,
            -0.041026562452316284,
            -0.04414151981472969,
            0.016217056661844254
        ],
        [
            -0.011515237390995026,
            -0.014560051262378693,
            0.028393013402819633,
            0.035125646740198135,
            0.022098051384091377,
            0.002311448100954294,
            -0.013346793130040169,
            0.014015032909810543,
            -0.0009664584067650139,
            -0.005392993800342083,
            -0.030911728739738464,
            -0.017980456352233887,
            0.01118068490177393,
            0.02433079481124878,
            0.022653359919786453,
            -0.007150004617869854,
            -0.00697556184604764,
            -0.025919156149029732,
            0.014439504593610764,
            -0.031069016084074974,
            0.009515604935586452,
            0.011565890163183212,
            -0.018103767186403275,
            -0.011882209219038486,
            0.028810126706957817,
            9.975838474929333e-05,
            0.010794950649142265,
            0.02036747708916664,
            0.007392045110464096,
            -0.016443394124507904,
            0.003190370509400964,
            0.011995912529528141,
            0.010581408627331257,
            0.012862673960626125,
            0.014301012270152569,
            -0.011609300039708614,
            -0.002240545116364956,
            -0.001484078005887568,
            0.019642891362309456,
            0.001000423333607614,
            -0.0051295943558216095,
            0.006983162369579077,
            0.018824903294444084,
            -0.005457296501845121,
            -0.01811537891626358,
            0.026713944971561432,
            0.016534719616174698,
            0.032864395529031754,
            0.001906682620756328,
            -0.014413739554584026,
            0.023977192118763924,
            -0.010516335256397724,
            0.02139602228999138,
            0.020195335149765015,
            -0.002829872304573655,
            0.018434446305036545,
            -0.023999646306037903,
            -0.01337073091417551,
            0.006697740871459246,
            0.01827973872423172,
            0.031171400099992752,
            0.03428199514746666,
            -0.005006092134863138,
            0.001362175797112286,
            -0.012340712361037731,
            0.006510666571557522,
            -0.004259140696376562,
            -0.011502071283757687,
            0.011897564865648746,
            -3.9943661249708384e-05,
            0.023543616756796837,
            -0.018335262313485146,
            -0.03866079822182655,
            0.004001009277999401,
            0.03520888090133667,
            -0.014831277541816235,
            0.012325776740908623,
            -0.03699812665581703,
            0.016383841633796692,
            0.029646053910255432,
            0.004408945329487324,
            0.005512902978807688,
            0.012763462960720062,
            0.007555872667580843,
            -0.02025182731449604,
            0.03436863422393799,
            -0.022013161331415176,
            -0.017919516190886497,
            0.027758529409766197,
            0.01061101071536541,
            -0.0022322670556604862,
            0.007389536593109369,
            0.031116778030991554,
            -0.0012411329662427306,
            -0.024314546957612038,
            0.015242930501699448,
            0.01978948898613453,
            0.02583458460867405,
            -0.014287512749433517,
            -0.008167363703250885,
            -0.0474306084215641,
            -0.0006053614197298884,
            0.01094809453934431,
            -0.0028457436710596085,
            0.014821471646428108,
            0.03035484068095684,
            0.016160354018211365,
            0.01960812509059906,
            0.05623466521501541,
            -0.0010115111945196986,
            0.01562569849193096,
            0.02951977401971817,
            0.0062272315844893456,
            0.027590950950980186,
            -0.05917896330356598,
            -0.003553320188075304,
            -0.012281266041100025,
            -0.006151060573756695,
            -0.017938904464244843,
            -0.027042238041758537,
            -0.0289949718862772,
            -0.020388096570968628,
            0.07125956565141678,
            0.018755722790956497,
            0.01603412814438343,
            -0.022681914269924164,
            -0.014918169938027859,
            0.032336436212062836,
            2.2509102564072236e-05,
            0.014090728014707565,
            -0.015993718057870865,
            -0.028445091098546982,
            0.02760694921016693,
            0.00021685280080419034,
            -0.029198771342635155,
            0.006902328692376614,
            0.01818193681538105,
            -0.007726637180894613,
            -0.007995620369911194,
            -0.010212036781013012,
            -0.02661597728729248,
            0.01971539668738842,
            -0.015524478629231453,
            0.02912977896630764,
            -0.030972760170698166,
            0.007620830554515123,
            -0.012843037955462933,
            0.02873983234167099,
            0.026685068383812904,
            -0.0453902967274189,
            -0.025302650406956673,
            -0.014389852061867714,
            -0.005264914128929377,
            -0.04690316319465637,
            -0.026821043342351913,
            -0.012062400579452515,
            0.007329026702791452,
            -0.017712825909256935,
            0.027167033404111862,
            0.020181821659207344,
            -0.03732193261384964,
            -0.01499990001320839,
            -0.010428105480968952,
            -0.0002820224908646196,
            -0.024792511016130447,
            0.01472054049372673,
            0.00011821720545412973,
            -0.05047771334648132,
            -0.029964914545416832,
            0.006498715840280056,
            -0.02522898279130459,
            0.036652207374572754,
            -0.03820322826504707,
            -0.015256536193192005,
            -0.0318010188639164,
            0.02857954427599907,
            -0.013683916069567204,
            -0.00047684015589766204,
            0.030973756685853004,
            0.005865009035915136,
            0.02144402451813221,
            0.026997145265340805,
            0.03958861902356148,
            -0.023142004385590553,
            0.028388075530529022,
            0.004167910665273666,
            -0.003960631787776947,
            -0.019424868747591972,
            0.011687108315527439,
            0.00011957354581682011,
            0.03570199012756348,
            -0.007260321173816919,
            0.004367864225059748,
            0.01770276017487049,
            -0.012086054310202599,
            -0.012591248378157616,
            -0.003890210296958685,
            0.016887642443180084,
            0.016305411234498024,
            0.025658536702394485,
            -0.005121431313455105,
            0.031227663159370422,
            -0.013921781443059444,
            0.0011913885828107595,
            -0.03365214914083481,
            0.040519751608371735,
            0.04357842355966568,
            0.043909694999456406,
            0.024682549759745598,
            -0.0020669649820774794,
            -0.004334938712418079,
            0.020947618409991264,
            0.022208811715245247,
            0.01177146378904581,
            0.023997802287340164,
            0.030027542263269424,
            0.0057760970667004585,
            -0.004817618522793055,
            -0.015946827828884125,
            -0.030278848484158516,
            0.01023827400058508,
            -0.015827419236302376,
            0.005993537604808807,
            0.008983178995549679,
            0.0018887239275500178,
            -0.013833173550665379,
            -0.0016705256421118975,
            0.014978835359215736,
            -0.01815486140549183,
            0.01693793013691902,
            -0.02239735797047615,
            0.017686620354652405,
            0.0070495340041816235,
            -0.01805724948644638,
            0.01891820691525936,
            -0.006862764712423086,
            0.03162626922130585,
            0.014561289921402931,
            0.027118908241391182,
            -0.028527336195111275,
            -0.019954470917582512,
            0.0057036601938307285,
            0.01799749583005905,
            -0.0190346110612154,
            -0.026779983192682266,
            -0.0034410639200359583,
            -0.013689110055565834,
            -0.000463562406366691,
            0.02364291623234749,
            -0.0021160251926630735,
            -0.007833282463252544,
            0.012158175930380821,
            0.006581864785403013,
            0.015879545360803604,
            0.0153500372543931,
            -0.015485983341932297,
            -0.00299669592641294,
            -0.035661350935697556,
            0.012362930923700333,
            -0.0044777425937354565,
            -0.01835932582616806,
            0.009540583938360214,
            0.005360172595828772,
            -0.016245247796177864,
            0.027011634781956673,
            0.023143744096159935,
            0.00933350995182991,
            -0.01732923835515976,
            0.016522886231541634,
            0.0025619168300181627,
            0.013435051776468754,
            0.013586973771452904,
            -0.005805343389511108,
            -0.025104552507400513,
            -0.0007162551046349108,
            0.01413724571466446,
            0.019415630027651787,
            0.028721630573272705,
            -0.006852090824395418,
            -0.02573774755001068,
            0.010775936767458916,
            0.007965047843754292,
            0.005610629450529814,
            -0.015411400236189365,
            0.006821632385253906,
            -0.027805805206298828,
            -0.025932978838682175,
            0.033976905047893524,
            -0.018227137625217438,
            0.036245375871658325,
            0.021344805136322975,
            0.019312921911478043,
            -0.015793751925230026,
            -0.028053320944309235,
            0.00671385508030653,
            -0.002569129690527916,
            0.0014640960143879056,
            0.0009567742235958576,
            -0.01164231263101101,
            0.017252637073397636,
            -0.02467559278011322,
            -0.015178869478404522,
            -0.02347186580300331,
            -0.011966672725975513,
            -0.007646702695637941,
            -0.00768703268840909,
            0.01476118341088295,
            0.039123792201280594,
            -0.0006730371387675405,
            0.00791909359395504,
            0.029340265318751335,
            0.007019781041890383,
            0.012800829485058784,
            0.0044712708331644535,
            0.010559206828474998,
            0.00835421308875084,
            -0.013372482731938362,
            0.01463557593524456,
            -0.00745343929156661,
            -0.01520533300936222,
            -0.0116893844678998,
            0.012449877336621284,
            0.01693776249885559,
            0.02779562398791313,
            -0.03226607292890549,
            -0.0013591396855190396,
            0.0070542083121836185,
            -0.02359601855278015,
            -0.019793398678302765,
            -0.027665752917528152,
            0.00866258330643177,
            -0.0003656780463643372,
            -0.00028530502459034324,
            0.007814368233084679,
            -0.028943650424480438,
            -0.006389126181602478,
            -0.01758766360580921,
            0.028693001717329025,
            0.0046454910188913345,
            -0.028579190373420715,
            -0.03008909523487091,
            0.051769185811281204,
            0.021239571273326874,
            0.01413946133106947,
            -0.0025866737123578787,
            -0.043546129018068314,
            0.005037514492869377,
            0.009850172325968742,
            0.02021382935345173,
            -0.030596544966101646,
            0.022520344704389572,
            -0.030321365222334862,
            0.011878371238708496,
            -0.03104962781071663,
            -0.01486099325120449,
            0.03835015371441841,
            0.01630019210278988,
            -0.010993755422532558,
            -0.007959396578371525,
            0.00448619294911623,
            -0.0023901283275336027,
            0.026482701301574707,
            -0.0041346014477312565,
            -0.004163202829658985,
            -0.03493880107998848,
            -0.023087121546268463,
            0.03316693380475044,
            -0.015747029334306717,
            0.025257157161831856,
            0.004861765541136265,
            -0.017913341522216797,
            -0.01952388510107994,
            0.014537314884364605,
            -0.015959639102220535,
            0.022198904305696487,
            0.03719395771622658,
            -0.02417011745274067,
            -0.029569515958428383,
            -0.006189800798892975,
            -0.028027087450027466,
            -0.049824055284261703,
            -0.016513841226696968,
            -0.03062775731086731,
            0.02177153341472149,
            0.04175208508968353,
            0.0021650707349181175,
            0.020637067034840584,
            0.03282108157873154,
            0.018286315724253654,
            -0.010963010601699352,
            -0.009832797572016716,
            -0.0031228286679834127,
            -0.015301947481930256,
            -1.3244186447991524e-05,
            -0.0056646536104381084,
            -0.009894544258713722,
            0.03066547028720379,
            -0.015983983874320984,
            0.021645212545990944,
            -0.01377884577959776,
            0.006868680473417044,
            0.018058711662888527,
            -0.01018091943114996,
            -0.0011069722240790725,
            0.015235460363328457,
            -0.026528241112828255,
            0.02434585988521576,
            -0.0035465117543935776,
            -0.014244507066905499,
            -0.0016365117626264691,
            -0.01444141287356615,
            -0.011499086394906044,
            -0.02239435724914074,
            -0.019563984125852585,
            0.014217429794371128,
            -0.01973380148410797,
            -0.020444823428988457,
            0.02225090004503727,
            -0.04881313443183899,
            -0.008574438281357288,
            -0.006417338736355305,
            -0.013196476735174656,
            -0.01452041044831276,
            -0.026759576052427292,
            0.017207855358719826,
            0.027424311265349388,
            -0.0015702737728133798,
            0.001601649448275566,
            0.007318887859582901,
            -0.0051204063929617405,
            -0.03156816214323044,
            0.016435598954558372,
            -0.012300451286137104,
            -0.05757696181535721,
            0.017562905326485634,
            -0.01370194647461176,
            0.030998123809695244,
            -0.001897343317978084,
            0.0030940528959035873,
            0.02523289993405342,
            0.023361368104815483,
            -0.02016516774892807,
            0.02665209397673607,
            0.00731587503105402,
            -0.007258397061377764,
            -0.0014489182503893971,
            -0.00028764750459231436,
            -0.06584853678941727,
            -0.009685181081295013,
            0.032814279198646545,
            0.01596159115433693,
            -0.025863341987133026,
            0.0014966882299631834,
            -0.020384833216667175,
            -0.00994565337896347,
            -0.0025676123332232237,
            -0.03089907020330429,
            0.0013832711847499013,
            0.01166754961013794,
            -0.028799522668123245,
            0.03092391975224018,
            -0.018650826066732407,
            -0.016911519691348076,
            0.0345049686729908,
            0.019883448258042336,
            -0.021255746483802795,
            -0.02414066530764103,
            -0.015131441876292229,
            0.01090867817401886,
            -0.01677524484694004,
            0.0031495830044150352,
            0.011440387926995754,
            0.027667416259646416,
            -0.017975861206650734,
            -0.0204768106341362,
            -0.009618966840207577,
            -0.03444432094693184,
            0.004507451318204403,
            0.01912759616971016,
            0.0023652135860174894,
            0.0036482899449765682,
            0.04023193567991257,
            0.03357933089137077,
            -0.024464689195156097,
            0.025398187339305878,
            -0.013028686866164207,
            0.02518514357507229,
            0.01886325143277645,
            0.049070805311203,
            -0.024984538555145264,
            -0.009070701897144318,
            0.004865207243710756,
            0.02863001637160778,
            0.0031695503275841475,
            -0.04265514761209488,
            -0.009913126938045025,
            -0.020665334537625313,
            0.01746451109647751,
            -0.03045467659831047,
            -0.005192447453737259,
            -0.006491608917713165,
            0.03247715160250664,
            0.00027078069979324937,
            -0.013014189898967743,
            0.030762527137994766,
            -0.01040580403059721,
            0.02446647919714451,
            0.028944648802280426,
            0.000694727583322674,
            -0.006151279434561729,
            -0.02315816469490528,
            0.026775427162647247,
            -0.006316067650914192,
            0.007911715656518936,
            0.0222927238792181,
            -0.011842434294521809,
            0.029390202835202217,
            -0.01298907957971096,
            -0.023853842169046402,
            0.011317080818116665,
            -0.0034208539873361588,
            0.018083149567246437,
            0.01714406907558441,
            0.00987019669264555,
            -0.02454342320561409,
            -0.004472292028367519,
            -0.03897413983941078,
            0.012725903652608395,
            -0.001653490704484284,
            0.017115741968154907,
            0.003232489572837949,
            0.01767924800515175,
            -0.027142681181430817,
            -0.01794756017625332,
            0.01825254037976265,
            0.030950123444199562,
            0.009850382804870605,
            0.01015380397439003,
            -0.02411723881959915,
            0.02879270724952221,
            -0.01617402397096157,
            -0.021992705762386322,
            -0.036931417882442474,
            -0.03751815855503082,
            -0.006531921215355396,
            0.03359311446547508,
            -0.023228952661156654,
            0.03025142475962639,
            0.017347613349556923,
            -0.022000079974532127,
            -0.03389981761574745,
            -0.02014797367155552,
            0.03302570432424545,
            0.002795856213197112,
            -0.01979578658938408,
            -0.01627945527434349,
            -0.014857834205031395,
            0.02247687242925167,
            -0.007595441769808531,
            -0.0006586358649656177,
            -0.016015730798244476,
            0.020245226100087166,
            0.006958620622754097,
            -0.01462265383452177,
            -0.021769186481833458,
            0.04222338646650314,
            0.03649810329079628,
            0.02640487067401409,
            0.02737373113632202,
            0.01500666793435812,
            -0.007745175156742334,
            -0.014609447680413723,
            0.028822114691138268,
            -0.004405544605106115,
            0.025427386164665222,
            0.03168836236000061,
            -0.027503255754709244,
            -0.00964408554136753,
            0.017295392230153084,
            0.028925059363245964,
            -0.026765666902065277,
            -0.014890610240399837,
            0.02970222570002079,
            -0.004382065963000059,
            -0.024169865995645523,
            -0.035731684416532516,
            -0.024807030335068703,
            -0.023775795474648476,
            0.03096078336238861,
            0.008539529517292976,
            -0.02351013384759426,
            0.03903784602880478,
            0.02054681070148945,
            0.02405521087348461,
            0.006125944666564465,
            -0.010609438642859459,
            -0.018405979499220848,
            0.010663308203220367,
            -0.011354690417647362,
            -0.016180654987692833,
            0.011837339028716087,
            0.009058995172381401,
            0.005517467390745878,
            -0.006339462008327246,
            -8.900692773750052e-05,
            0.02506144344806671,
            0.01838476024568081,
            0.036591723561286926,
            -0.008580537512898445,
            0.04034152254462242,
            -0.024471962824463844,
            -0.024089952930808067,
            0.007815906777977943,
            0.010749300941824913,
            -0.01138669066131115,
            -0.05313410609960556,
            -0.004988265223801136,
            0.008326809853315353,
            0.0004637019010260701,
            6.427727930713445e-05,
            0.019988346844911575,
            0.0018259381176903844,
            -0.0280543752014637,
            -0.02792888879776001,
            -0.03799274563789368,
            -0.032644663006067276,
            -0.005506670102477074,
            0.011307276785373688,
            -0.03032897785305977,
            0.0021694833412766457,
            0.009887580759823322,
            -0.011026670224964619,
            0.006840196903795004,
            -0.025959400460124016,
            -0.002122167032212019,
            -0.0035109829623252153,
            0.01604062132537365,
            -0.03219486400485039,
            0.0019370738882571459,
            -0.0053674145601689816,
            0.011978288181126118,
            -0.020166002213954926,
            -0.032576125115156174,
            -0.007439738605171442,
            -0.01671009697020054,
            0.010859024710953236,
            0.049743443727493286,
            0.019475432112812996,
            -0.022875472903251648,
            0.014005234465003014,
            0.007553704548627138,
            -0.03915748745203018,
            0.026005594059824944,
            0.01684105396270752,
            0.028663961216807365,
            0.016656488180160522,
            0.012850962579250336,
            -0.025672724470496178,
            0.02911815047264099,
            -0.03677494451403618,
            0.023903314024209976,
            0.015978610143065453,
            0.027562277391552925,
            -0.007854173891246319,
            0.01889839768409729,
            0.015801938250660896,
            -0.00850164145231247,
            0.01724611409008503,
            -0.004848544951528311,
            0.012461554259061813,
            -0.031595878303050995,
            9.470086661167443e-05,
            0.010134655050933361,
            0.008822129108011723,
            0.008464543148875237,
            -0.03682379797101021,
            -0.0076180207543075085,
            -0.029091380536556244,
            -0.02111964486539364,
            -0.009290025569498539,
            -0.026379963383078575,
            0.024032985791563988,
            0.0229350496083498,
            0.026284417137503624,
            0.00953481812030077,
            0.02281445637345314,
            -0.014527366496622562,
            0.0066784038208425045,
            0.029768677428364754,
            0.002239224733784795,
            -0.02246561460196972,
            0.020012207329273224,
            -0.01815829612314701,
            -0.010583407245576382,
            0.007984585128724575,
            -0.012402181513607502,
            0.01626567170023918,
            -0.007834726013243198,
            -0.023131534457206726,
            0.0035811762791126966,
            -0.02320197969675064,
            -0.0038741666357964277,
            0.009781129658222198,
            0.024266060441732407,
            -0.03239713981747627,
            -0.004834792576730251,
            0.027813764289021492,
            -0.010290143080055714,
            -0.009372376836836338,
            0.004067912697792053,
            0.02182958647608757,
            -0.02593865431845188,
            0.004399752244353294,
            -0.025686025619506836,
            0.010180613026022911,
            -0.015241635963320732,
            0.0034637197386473417,
            0.004134366754442453,
            -0.02400282956659794,
            -0.033278971910476685,
            -0.006806880701333284,
            0.026245824992656708,
            -0.03412593901157379,
            -0.024651717394590378,
            -0.026291998103260994,
            0.012581504881381989,
            0.0147340577095747,
            0.02641836367547512,
            -0.005238576792180538,
            0.024182278662919998,
            -0.013382603414356709,
            0.0054046050645411015,
            0.02532152272760868,
            0.01582387648522854,
            -0.004984311759471893,
            -0.028763221576809883,
            -0.031567350029945374,
            0.008964326232671738,
            -0.009363069199025631,
            0.03154362738132477,
            -0.022997286170721054,
            0.03552709519863129,
            -0.017766796052455902,
            -0.03144413232803345,
            0.005074577871710062,
            -0.020116794854402542,
            0.03594435006380081,
            -0.036576975136995316,
            -0.004043328110128641,
            0.01403126772493124,
            0.012457506731152534,
            -0.02736213617026806,
            0.009702524170279503,
            -0.001989394659176469,
            0.008779956959187984,
            0.0012593563878908753,
            0.020348384976387024,
            -0.04549698531627655,
            -0.0030367178842425346,
            -0.024938184767961502,
            -0.00022847228683531284,
            0.015709836035966873,
            0.0213143490254879,
            -0.010280154645442963,
            -0.016982875764369965,
            0.002106150845065713,
            0.028370903804898262,
            0.015806863084435463,
            0.015279820188879967,
            0.004115627147257328,
            0.030185209587216377,
            0.01757943630218506,
            0.0065289149060845375,
            0.031804025173187256,
            -0.02791137807071209,
            -0.02545897662639618,
            -0.05403311550617218,
            -0.035234447568655014,
            0.02109536901116371,
            -0.020364979282021523,
            -0.010864290408790112,
            0.0169428251683712,
            0.010213765315711498,
            -0.004885715898126364,
            0.028008537366986275,
            -0.03352954238653183,
            0.04433242976665497,
            0.02333487756550312,
            0.04307222366333008,
            -0.020514938980340958,
            -0.016001012176275253,
            0.03168812394142151,
            0.015577382408082485,
            -0.024704335257411003,
            -0.010360727086663246,
            0.035550326108932495,
            -0.005491693038493395,
            -0.005081342067569494,
            -0.01775839552283287,
            0.009385824203491211,
            0.03180887550115585,
            -0.012215619906783104,
            0.030104270204901695,
            -0.0010524996323511004,
            0.007725529372692108,
            -0.00823146291077137,
            0.009284409694373608,
            0.019240347668528557,
            -0.0276440791785717,
            -0.0003046157071366906,
            0.002933419542387128,
            0.008710495196282864,
            -0.022399265319108963,
            -0.02255604788661003,
            -0.012036618776619434,
            0.013768309727311134,
            0.009583469480276108,
            0.010020166635513306,
            0.009855958633124828,
            0.03883678466081619,
            -0.02335442043840885,
            0.01981528289616108,
            0.02991068735718727,
            -0.02872072346508503,
            -0.005428186617791653,
            0.027517298236489296,
            0.0028428807854652405,
            0.022426342591643333,
            0.02069142647087574,
            -0.011034972965717316,
            0.00816569197922945,
            -0.01959294266998768,
            0.005143656395375729,
            0.007008058950304985,
            0.02506265789270401,
            -0.03353307768702507,
            0.016247574239969254,
            0.014885960146784782,
            0.022296935319900513,
            -0.02512454055249691,
            -0.01786760427057743,
            -0.010168918408453465,
            0.004573222249746323,
            -0.028630772605538368,
            0.0020044019911438227,
            0.019184542819857597,
            -0.001750590861774981,
            0.001681808615103364,
            -0.03539711609482765,
            -0.0018503101309761405,
            -0.02492433413863182,
            0.015370925888419151,
            0.001258471398614347,
            -0.026067424565553665,
            -0.0358600988984108,
            -0.011273284442722797,
            0.0004606271395459771,
            0.015420316718518734,
            -0.0144469253718853,
            0.010009249672293663,
            0.007585996761918068,
            0.013410467654466629,
            -0.0021642353385686874,
            0.007324966136366129,
            -0.02819741517305374,
            0.01831970550119877,
            0.023942025378346443,
            0.025595633313059807,
            0.0039872280322015285,
            0.04028424248099327,
            -0.0030201461631804705,
            0.0028868902008980513,
            -0.015606521628797054,
            -0.015657154843211174,
            -0.0062943147495388985,
            0.0266644936054945,
            -0.03156208619475365,
            0.008651524782180786,
            0.021571336314082146,
            0.008145324885845184,
            0.021671447902917862,
            -0.02185199409723282,
            0.008865542709827423,
            -0.0027586326468735933,
            -0.002977103693410754,
            0.013446394354104996,
            0.028848569840192795,
            -0.011903157457709312,
            0.011745992116630077,
            0.006032298319041729,
            -0.02482585981488228,
            0.019346222281455994,
            -0.035896677523851395,
            0.00937715545296669,
            -0.036497827619314194,
            0.0028506750240921974,
            -0.014644418843090534,
            -0.0066678645089268684,
            0.005105706863105297,
            0.006591706071048975,
            -0.02283296175301075,
            -0.031351231038570404,
            0.025352178141474724,
            -0.017879707738757133,
            -0.026714572682976723,
            0.03090718202292919,
            -0.007551944348961115,
            0.0070243882946670055,
            -0.019892364740371704,
            -0.03191996365785599,
            0.006053150165826082,
            0.020062070339918137,
            -0.00728217326104641,
            0.005048332270234823,
            -0.0010224103461951017,
            0.00623299740254879,
            0.0027088415808975697,
            0.007375011686235666,
            -0.014797758311033249,
            -0.009023850783705711,
            -0.003863133490085602,
            -0.0357314758002758,
            -0.023875048384070396,
            0.025326546281576157,
            -0.03198173642158508,
            0.003499833634123206,
            0.0018345197895541787,
            -0.011325893923640251,
            -0.025036025792360306,
            0.03813749551773071,
            -0.03292463347315788,
            -0.007912838831543922,
            0.0026186686009168625,
            0.05974149331450462,
            0.021604739129543304,
            0.016227366402745247,
            -0.02345188707113266,
            -0.032074932008981705,
            0.009283252991735935,
            0.031106075271964073,
            0.019191820174455643,
            0.02798631228506565,
            0.007177815772593021,
            0.001894177170470357,
            0.032039038836956024,
            -0.019054997712373734,
            0.0016122922534123063,
            0.017300235107541084,
            0.01404227688908577,
            0.01293737068772316,
            0.030702810734510422,
            0.0304254237562418,
            0.022532900795340538,
            -0.025670526549220085,
            -0.03850309178233147,
            -0.005280185490846634,
            0.01581711508333683,
            0.02560379169881344,
            0.01730390079319477,
            0.01828704960644245,
            -0.010718225501477718,
            0.0004467622784432024,
            -0.0036076046526432037,
            -0.024783408269286156,
            -0.0249000433832407,
            -0.005150942597538233,
            -0.009831434115767479,
            -0.028086481615900993,
            -0.002798391506075859,
            -0.038645315915346146,
            0.03228158503770828,
            0.0033784315455704927,
            -0.02231854945421219,
            0.013255683705210686,
            -0.011852317489683628,
            0.02175644412636757,
            0.021788079291582108,
            -0.021734068170189857,
            -0.013213359750807285,
            -0.013235989958047867,
            -0.025745324790477753,
            -0.020873405039310455,
            -0.014370625838637352,
            -0.0032650299835950136,
            -0.02108175680041313,
            0.0022058822214603424,
            -0.017727021127939224,
            0.0032836338505148888,
            -0.014969216659665108,
            0.010822614654898643,
            0.013185200281441212,
            -0.02098502218723297,
            -0.010862077586352825,
            -0.023539256304502487,
            0.003983472939580679,
            0.030932223424315453,
            0.01328355073928833,
            0.00889835599809885,
            0.003516226541250944,
            -0.03039567545056343,
            0.012239630334079266,
            -0.010321669280529022,
            0.02699616178870201,
            0.014866628684103489,
            -0.01466538105159998,
            -0.005725434049963951,
            0.03259903937578201,
            -0.015991726890206337,
            -0.010098800994455814,
            -0.010509805753827095,
            0.024530163034796715,
            -0.0011644328478723764,
            0.010665157809853554,
            0.02279742807149887,
            0.01963738352060318,
            0.00821662973612547,
            0.008921473287045956,
            0.016576504334807396,
            -0.01407428178936243,
            0.007456787396222353,
            -0.02157624438405037,
            0.027870630845427513,
            -0.05583915859460831,
            -0.014813543297350407
        ],
        [
            -0.037317726761102676,
            0.02938753366470337,
            -0.030321240425109863,
            0.013708055950701237,
            0.0016215869691222906,
            0.03939979895949364,
            -0.01429833099246025,
            0.02485879324376583,
            0.028043394908308983,
            -0.0013474967563524842,
            0.020536169409751892,
            -0.03873275965452194,
            0.012203349731862545,
            0.009439116343855858,
            0.027012819424271584,
            -0.011749054305255413,
            -0.03980257362127304,
            -0.0345652811229229,
            -0.024629946798086166,
            0.003298381809145212,
            0.0008241917239502072,
            0.01052786037325859,
            0.008323772810399532,
            0.03794778138399124,
            0.027881000190973282,
            -0.023693872615695,
            0.011823577806353569,
            -0.018438637256622314,
            0.033207476139068604,
            -0.04529515281319618,
            0.015269756317138672,
            0.025004809722304344,
            -0.02654566615819931,
            0.016764674335718155,
            -0.023656046018004417,
            0.008100330829620361,
            -0.004771439824253321,
            0.003846928710117936,
            0.007529526017606258,
            0.01727362722158432,
            0.01544355507940054,
            0.025427350774407387,
            0.023879261687397957,
            0.002869986230507493,
            -0.03538670390844345,
            0.016038954257965088,
            -0.019860906526446342,
            -0.010317366570234299,
            -0.028710996732115746,
            -0.01770527847111225,
            -0.011683030985295773,
            -0.015371669083833694,
            -0.0317034050822258,
            -0.000643267878331244,
            0.013181949965655804,
            -0.009168826043605804,
            0.02705925516784191,
            0.008795717731118202,
            0.0022263803984969854,
            0.024515824392437935,
            -0.02546851895749569,
            -0.004975888412445784,
            -0.02424546144902706,
            -0.018892062827944756,
            -0.01762615516781807,
            -0.02238098531961441,
            0.003169108647853136,
            -0.015089483931660652,
            -0.02513895370066166,
            0.016078975051641464,
            -0.0023257581051439047,
            -0.011533142998814583,
            -0.01101337093859911,
            0.022561337798833847,
            -0.053796980530023575,
            -0.03238113969564438,
            -0.019411571323871613,
            0.02069079875946045,
            -0.02265786938369274,
            -0.0005120238638482988,
            -0.015271148644387722,
            0.014662479050457478,
            -0.019421618431806564,
            -0.01802016794681549,
            -0.00103564967866987,
            -0.038111668080091476,
            0.01507698092609644,
            0.025904646143317223,
            0.01429758034646511,
            0.008371490053832531,
            0.02235131710767746,
            0.021794812753796577,
            0.009204182773828506,
            -0.021108398213982582,
            -0.04462752118706703,
            -0.04849131032824516,
            0.03071674332022667,
            -0.036457423120737076,
            0.06344626843929291,
            -0.00606157211586833,
            -0.018544157966971397,
            -0.018523426726460457,
            -0.01733328029513359,
            0.015095510520040989,
            -0.02300407551229,
            -0.034598879516124725,
            0.012011796236038208,
            0.004092914517968893,
            -0.02475772611796856,
            0.019329886883497238,
            0.005150381941348314,
            0.0060517326928675175,
            -0.027765898033976555,
            0.0303566325455904,
            0.001688081189058721,
            -0.016583269461989403,
            0.01685491017997265,
            -0.013546445406973362,
            -0.004814141895622015,
            -0.01557185873389244,
            -0.021633051335811615,
            0.02942553721368313,
            0.04032064601778984,
            -0.038333646953105927,
            -0.009754998609423637,
            -0.04408302158117294,
            0.020015092566609383,
            -0.006107463501393795,
            0.01805722713470459,
            -0.011493813246488571,
            -0.0006389274494722486,
            -0.00028706761077046394,
            -0.006390200927853584,
            -0.006413409020751715,
            0.026800580322742462,
            0.014020784758031368,
            -0.018988365307450294,
            0.022837305441498756,
            0.03989473730325699,
            0.00021410963381640613,
            0.018633220344781876,
            -0.017687588930130005,
            -0.009102324955165386,
            0.02231917530298233,
            0.004126190673559904,
            -0.03887917473912239,
            -0.002302018227055669,
            -0.05029439553618431,
            -0.02217615395784378,
            -0.023434879258275032,
            -0.03528260439634323,
            0.01727355644106865,
            0.025572851300239563,
            -0.05869811400771141,
            -0.016962023451924324,
            -0.045945681631565094,
            -0.013577465899288654,
            0.0289621502161026,
            -0.023577746003866196,
            -0.003924455959349871,
            -0.02665519155561924,
            -0.014633956365287304,
            -0.004482348915189505,
            -0.05494373291730881,
            0.03528920188546181,
            -0.0028402956668287516,
            -0.04730633273720741,
            0.0006973757408559322,
            -0.00685154739767313,
            0.0022870926186442375,
            0.022286662831902504,
            -0.013377420604228973,
            0.003801103914156556,
            -0.002434453694149852,
            -0.018784822896122932,
            0.02513066865503788,
            -0.020062483847141266,
            0.014244412072002888,
            -0.001226410735398531,
            -0.043460313230752945,
            -0.02290630154311657,
            0.013889157213270664,
            -0.040579505264759064,
            -0.01698657125234604,
            0.021925846114754677,
            0.0269936490803957,
            -0.009404654614627361,
            0.012602568604052067,
            0.018567215651273727,
            -0.029000092297792435,
            0.01454581506550312,
            -0.00039233893039636314,
            -0.003030086401849985,
            -0.006431592162698507,
            0.02690611593425274,
            0.018855536356568336,
            -0.014948503114283085,
            0.006241125520318747,
            -0.013775290921330452,
            -0.03695802390575409,
            0.01406778208911419,
            0.028898239135742188,
            0.02919727936387062,
            0.010655183345079422,
            -0.05434954911470413,
            -0.006261651869863272,
            0.017963889986276627,
            0.004583220928907394,
            0.043775346130132675,
            -0.01518284622579813,
            0.011596773751080036,
            -0.015382065437734127,
            0.00371261453256011,
            0.023686571046710014,
            0.02033938467502594,
            0.012354083359241486,
            0.0326552540063858,
            0.015082938596606255,
            -0.008275686763226986,
            0.009606365114450455,
            -0.030663220211863518,
            0.049079589545726776,
            0.020203040912747383,
            -0.019634393975138664,
            -0.0014705604407936335,
            -0.09320445358753204,
            -0.053819648921489716,
            -0.02022375911474228,
            0.014543024823069572,
            0.00371191487647593,
            0.035216763615608215,
            0.015984734520316124,
            0.011579764075577259,
            0.012426850385963917,
            -0.04274309426546097,
            0.012649394571781158,
            0.02158500999212265,
            -0.012236983515322208,
            0.021216675639152527,
            -0.021788643673062325,
            0.009863520041108131,
            -0.017535632476210594,
            -0.0178457573056221,
            -0.01669417880475521,
            0.032972365617752075,
            0.014966375194489956,
            0.0013489540433511138,
            0.029297536239027977,
            -0.02370763197541237,
            -0.006524444092065096,
            0.019862942397594452,
            -0.006675629876554012,
            0.017914824187755585,
            -0.0019480816554278135,
            -0.020224088802933693,
            0.000997695722617209,
            -0.003962926100939512,
            0.01535374578088522,
            -0.012271871790289879,
            -0.01792941614985466,
            0.005401838570833206,
            0.011560403741896152,
            0.01359971147030592,
            0.008676338940858841,
            -0.0036134470719844103,
            -0.03089180961251259,
            -0.03363383188843727,
            0.006049056071788073,
            0.007420050911605358,
            -0.01918209344148636,
            -0.0046286205761134624,
            -0.02181432768702507,
            -0.02023833617568016,
            -0.005066000856459141,
            0.0382891446352005,
            -0.018476903438568115,
            -0.02416139654815197,
            0.017493151128292084,
            -0.03518874943256378,
            0.019949594512581825,
            0.009463933296501637,
            -0.03220560774207115,
            0.019508009776473045,
            -0.08080437779426575,
            0.01606786623597145,
            0.029665885493159294,
            -0.006645489484071732,
            -0.05660422891378403,
            -0.017773523926734924,
            -0.009422414004802704,
            0.005728561896830797,
            -0.03049750253558159,
            -0.022717466577887535,
            -0.007328758016228676,
            0.01991296000778675,
            -0.008771397173404694,
            0.01704721339046955,
            -0.038376208394765854,
            -0.011950499378144741,
            0.004673187155276537,
            0.032926399260759354,
            -0.027258792892098427,
            0.027203073725104332,
            0.011146719567477703,
            0.007964768446981907,
            0.012930693104863167,
            -0.03738480061292648,
            -0.02236071042716503,
            -0.014561527408659458,
            -0.017600273713469505,
            -0.0008928635506890714,
            -0.034088701009750366,
            0.0166658665984869,
            0.007112008985131979,
            -0.012343521229922771,
            -0.02058587037026882,
            0.012103751301765442,
            -0.02119784988462925,
            -0.008315344341099262,
            0.011887764558196068,
            0.007653433363884687,
            -0.09231315553188324,
            -0.05411605164408684,
            0.028033854439854622,
            0.00926907081156969,
            -0.004857741296291351,
            0.012089590542018414,
            -0.04770156741142273,
            0.013115272857248783,
            0.00011061716213589534,
            -0.014025196433067322,
            0.02944350242614746,
            -0.006383324507623911,
            -0.020443234592676163,
            0.028980256989598274,
            0.035673294216394424,
            0.025871889665722847,
            -0.004478649236261845,
            0.006055374164134264,
            0.02931985631585121,
            0.01180555671453476,
            0.01846982352435589,
            -0.0695926770567894,
            -0.017085999250411987,
            0.015725035220384598,
            0.006262386217713356,
            0.010513545013964176,
            0.026384832337498665,
            0.007762235589325428,
            -0.022231310606002808,
            -0.043499208986759186,
            -0.010770694352686405,
            0.034026872366666794,
            0.025056932121515274,
            -0.03353297710418701,
            -0.0057847402058541775,
            0.02519494853913784,
            -0.003463764674961567,
            0.038101062178611755,
            -0.0008746411767788231,
            -0.0041620624251663685,
            0.01860419474542141,
            -0.04029898717999458,
            -0.00256833853200078,
            -0.012968174181878567,
            -0.0002308454568265006,
            0.01658368669450283,
            0.0056886859238147736,
            0.015240463428199291,
            0.02791992388665676,
            0.044725243002176285,
            0.01777210459113121,
            0.020429672673344612,
            -0.00496292021125555,
            0.02519732154905796,
            -0.021879157051444054,
            -0.0501173660159111,
            0.01769816316664219,
            0.026639217510819435,
            -0.022697996348142624,
            0.013356185518205166,
            0.007790687493979931,
            0.0036114202812314034,
            0.012595020234584808,
            -0.026049291715025902,
            0.028478367254137993,
            0.017490701749920845,
            -0.005919199436903,
            -0.040619850158691406,
            0.03581488877534866,
            -0.010423124767839909,
            0.02580023929476738,
            0.014902152121067047,
            -0.012091000564396381,
            -0.013899429701268673,
            0.009472566656768322,
            0.006900581065565348,
            -0.023193076252937317,
            -0.0073052458465099335,
            -0.0822291374206543,
            -0.003460514359176159,
            0.029805030673742294,
            -0.007712236605584621,
            -0.028844522312283516,
            0.02864677831530571,
            0.011924347840249538,
            -0.023906229063868523,
            0.02392563782632351,
            -0.024160269647836685,
            0.032668374478816986,
            0.03484738990664482,
            0.014280260540544987,
            0.035405483096838,
            -0.016600091010332108,
            -0.002016424434259534,
            -0.022613711655139923,
            -0.01486845314502716,
            0.020029902458190918,
            -0.010418396443128586,
            -0.008237358182668686,
            -0.0005561744910664856,
            -0.016974860802292824,
            -0.017025992274284363,
            -0.009203928522765636,
            -0.013158926740288734,
            -0.005200565792620182,
            0.01758621074259281,
            -0.02216145023703575,
            0.019255230203270912,
            -0.025905724614858627,
            0.013876964338123798,
            -0.001276320545002818,
            0.0014956300146877766,
            0.002740004099905491,
            -0.008736605755984783,
            -0.020024782046675682,
            -0.052913084626197815,
            -0.021319633349776268,
            -0.005228291265666485,
            0.031445637345314026,
            -0.01169496774673462,
            0.0026516371872276068,
            0.0173504538834095,
            0.02935771457850933,
            0.015538339503109455,
            -0.004602422006428242,
            -0.00955113209784031,
            0.008557870984077454,
            -0.013079133816063404,
            -0.025769781321287155,
            -0.02404257468879223,
            -0.018495162948966026,
            0.025712307542562485,
            -0.03045295923948288,
            0.003452207427471876,
            0.03781013935804367,
            0.024542924016714096,
            -0.027264099568128586,
            -0.03412600979208946,
            0.011357204988598824,
            0.023077325895428658,
            0.005834519397467375,
            0.003069272730499506,
            -0.00667957216501236,
            -0.022434744983911514,
            0.006609858479350805,
            -0.03715663403272629,
            0.054117243736982346,
            0.006464765407145023,
            -0.033154040575027466,
            -0.004881564527750015,
            -0.0016779359430074692,
            0.017215438187122345,
            -0.008342034183442593,
            0.0025712833739817142,
            -0.007944273762404919,
            0.028073081746697426,
            -0.01855514571070671,
            -0.03134498745203018,
            0.023717129603028297,
            0.014730723574757576,
            0.016501616686582565,
            -0.003876075381413102,
            -0.02751590497791767,
            -0.03755311667919159,
            -0.039278529584407806,
            -0.010652410797774792,
            0.003417119150981307,
            -0.10275112092494965,
            -0.022218992933630943,
            0.018526896834373474,
            0.033745311200618744,
            0.01728202775120735,
            0.021473271772265434,
            -0.0050139045342803,
            0.008369146846234798,
            -0.048752184957265854,
            -0.01204727590084076,
            -0.014419760555028915,
            -0.011994927190244198,
            -0.022022834047675133,
            0.033923618495464325,
            -0.01634485274553299,
            0.015288573689758778,
            0.0070245033130049706,
            0.006477887276560068,
            -0.02524609863758087,
            -0.00783019419759512,
            -0.008945225737988949,
            0.028302155435085297,
            0.004394278395920992,
            -0.007847216911613941,
            0.01269287895411253,
            -0.010794870555400848,
            0.03860567510128021,
            0.02810630016028881,
            0.014323435723781586,
            -0.02390000782907009,
            -0.04798560217022896,
            -0.017257513478398323,
            -0.016269920393824577,
            -0.004944074433296919,
            0.02534850873053074,
            -0.0018259246135130525,
            -0.008278252556920052,
            -0.043585505336523056,
            0.006919058971107006,
            -0.01905936188995838,
            -0.0320761501789093,
            0.03205205500125885,
            0.009318768978118896,
            0.011739899404346943,
            0.011689899489283562,
            0.03632444515824318,
            0.01816391944885254,
            0.0256174448877573,
            0.011644781567156315,
            -0.02236812189221382,
            0.01902005635201931,
            0.02127252146601677,
            -0.01473529078066349,
            -0.01914077252149582,
            0.024829046800732613,
            -0.021983899176120758,
            -0.023613685742020607,
            0.026035955175757408,
            0.020809290930628777,
            0.007686554454267025,
            -0.013163570314645767,
            0.0256828460842371,
            -0.02028091438114643,
            -0.007988309487700462,
            -0.01444840244948864,
            0.019853290170431137,
            -0.007870866917073727,
            -0.02779775857925415,
            -0.05174652487039566,
            -0.0028022907208651304,
            0.013284583576023579,
            -0.014755467884242535,
            -0.002777171554043889,
            0.008352403528988361,
            -0.02065364085137844,
            0.006936616729944944,
            -0.0002642080944497138,
            -0.02624420076608658,
            -0.018247127532958984,
            0.012487473897635937,
            -0.0359816737473011,
            0.0024832645431160927,
            0.007999420166015625,
            -0.013702023774385452,
            -0.025442881509661674,
            0.03350650519132614,
            -0.008032727986574173,
            0.040115345269441605,
            -0.03620006889104843,
            -0.0014007480349391699,
            -0.017176562920212746,
            0.03269552066922188,
            0.016962436959147453,
            0.007883045822381973,
            -0.038859330117702484,
            -0.018273932859301567,
            -0.014549086801707745,
            0.001020139898173511,
            -0.0072436584159731865,
            0.006009045522660017,
            -0.011072304099798203,
            -0.011732247658073902,
            -0.0021011957433074713,
            0.019043399021029472,
            0.0007069310522638261,
            -0.017338767647743225,
            -0.0100996820256114,
            0.00269564357586205,
            0.04447469115257263,
            0.03715483099222183,
            0.02574671246111393,
            0.03109830990433693,
            0.048193760216236115,
            -0.0014434437034651637,
            0.018446411937475204,
            -0.016808096319437027,
            -0.005963423755019903,
            -0.027212729677557945,
            0.03608876094222069,
            0.017145559191703796,
            -0.05781007185578346,
            -0.03801747411489487,
            0.021956706419587135,
            0.017780251801013947,
            0.011396178044378757,
            0.0308628398925066,
            0.026370827108621597,
            0.019695688039064407,
            0.028218062594532967,
            0.036750297993421555,
            0.020736094564199448,
            -0.03878135606646538,
            0.011615914292633533,
            0.029783470556139946,
            -0.02157514914870262,
            -0.00774355698376894,
            0.012949959374964237,
            0.028009822592139244,
            0.03160708025097847,
            -0.02557412162423134,
            0.02067163772881031,
            0.014128118753433228,
            -0.049241580069065094,
            0.020516732707619667,
            -0.006881613750010729,
            0.024045836180448532,
            -0.0421164408326149,
            -0.014112906530499458,
            0.010910430923104286,
            0.01785483956336975,
            -0.0010422318009659648,
            0.004133682232350111,
            -0.04199642315506935,
            0.029638221487402916,
            0.007843192666769028,
            -0.022670719772577286,
            -0.014836772345006466,
            -0.0024852578062564135,
            -0.0027132974937558174,
            0.0035789574030786753,
            -0.012535053305327892,
            0.031604669988155365,
            -0.019880633801221848,
            0.012998858466744423,
            -0.035855572670698166,
            -0.004471893887966871,
            -0.020452197641134262,
            0.025455230847001076,
            -0.027276761829853058,
            0.023487400263547897,
            -0.013099267147481441,
            -0.04369623214006424,
            0.013401900418102741,
            -0.026110339909791946,
            0.0022842837497591972,
            -0.026637624949216843,
            0.0004907677066512406,
            -0.008513459004461765,
            -0.02561580017209053,
            0.014054467901587486,
            0.007022633217275143,
            -0.01296586636453867,
            0.035467106848955154,
            -0.009328721091151237,
            -0.006757581606507301,
            0.0036346346605569124,
            0.02622842602431774,
            0.031184453517198563,
            0.032211434096097946,
            0.023590467870235443,
            0.01033497042953968,
            0.012468043714761734,
            0.017096446827054024,
            0.003653542371466756,
            -0.030751841142773628,
            0.024559171870350838,
            -0.013734362088143826,
            -0.030413459986448288,
            0.01574097014963627,
            -0.022330796346068382,
            -0.05274137854576111,
            -0.028947195038199425,
            -0.07534769922494888,
            0.024714630097150803,
            -0.02337503805756569,
            -0.04098811000585556,
            0.004018222913146019,
            0.016655333340168,
            0.019930953159928322,
            0.025551198050379753,
            0.03265462443232536,
            0.027978837490081787,
            0.029278581961989403,
            -0.0042046657763421535,
            0.018826501443982124,
            0.018672769889235497,
            0.009087283164262772,
            0.026414694264531136,
            0.03386611491441727,
            3.881677002937067e-06,
            0.0059227775782346725,
            -0.015981055796146393,
            -0.029157759621739388,
            -0.004766096826642752,
            -0.023432908579707146,
            0.03316103667020798,
            -0.039855774492025375,
            -0.011960960924625397,
            0.02284451574087143,
            0.040203072130680084,
            -0.012076041661202908,
            0.012938088737428188,
            -0.03963785246014595,
            0.0031786777544766665,
            0.022464821115136147,
            0.021891910582780838,
            -0.010585683397948742,
            -0.022228840738534927,
            -0.017511418089270592,
            -0.04040484130382538,
            0.01461332943290472,
            0.0194704607129097,
            -0.029701264575123787,
            -0.01008765958249569,
            -0.014911922626197338,
            -0.009675908833742142,
            0.01068041380494833,
            -0.0033082750160247087,
            0.02676561288535595,
            0.023632822558283806,
            -0.007947074249386787,
            0.0042972867377102375,
            0.00223652645945549,
            -0.025842871516942978,
            0.0007159709348343313,
            -0.014442085288465023,
            0.03245658054947853,
            -0.023909326642751694,
            -0.012604492716491222,
            -0.014028236269950867,
            -0.020291507244110107,
            0.0012179403565824032,
            -0.026365749537944794,
            0.02572494186460972,
            0.012390289455652237,
            0.012562139891088009,
            -0.004669627640396357,
            -0.03587210923433304,
            0.02152702957391739,
            0.003697972046211362,
            -0.021718179807066917,
            0.001525701372884214,
            0.03262075409293175,
            0.017707185819745064,
            -0.01611882634460926,
            0.02531278319656849,
            -0.0010412209667265415,
            0.03211211413145065,
            0.0016006524674594402,
            -0.010326419025659561,
            -0.0032926888670772314,
            0.013063604012131691,
            -0.0004209816688671708,
            0.021178649738430977,
            -0.049175333231687546,
            -0.051825374364852905,
            -0.022591209039092064,
            -0.014958742074668407,
            -0.012803063727915287,
            0.038552310317754745,
            -0.012810877524316311,
            0.002968867775052786,
            -0.02748950570821762,
            0.0031516742892563343,
            -0.026318863034248352,
            -0.030762212350964546,
            0.021270038560032845,
            -0.014940264634788036,
            -0.005121513269841671,
            0.007024914957582951,
            -0.0051280418410897255,
            -0.050136007368564606,
            -0.03032546490430832,
            -0.013065546751022339,
            -0.015054084360599518,
            0.006880635395646095,
            0.018599841743707657,
            0.004208154510706663,
            -0.023037785664200783,
            0.024280451238155365,
            -0.005578252021223307,
            0.017026355490088463,
            0.015765933319926262,
            -0.02945476584136486,
            0.022961119189858437,
            0.007742155343294144,
            0.01569809578359127,
            0.012055445462465286,
            -0.014876515604555607,
            -0.0066831232979893684,
            0.0031166323460638523,
            0.008130788803100586,
            -0.011781193315982819,
            0.016671666875481606,
            0.03406006470322609,
            -0.019404055550694466,
            0.020755402743816376,
            -0.02742866985499859,
            -0.016306458041071892,
            -0.02801811322569847,
            -0.004141116049140692,
            -0.02312663570046425,
            0.03234640508890152,
            -0.013987548649311066,
            0.012332864105701447,
            0.04149831831455231,
            0.009887034073472023,
            -0.019578995183110237,
            0.028769738972187042,
            0.01924470253288746,
            0.01804233156144619,
            0.005421311128884554,
            -0.04973059892654419,
            -0.03129599988460541,
            0.023644089698791504,
            -0.027916429564356804,
            0.025764651596546173,
            0.003361836075782776,
            -0.01737619750201702,
            -0.02090543694794178,
            0.027922477573156357,
            0.03305783122777939,
            0.0030747605487704277,
            0.014603503048419952,
            -0.0002907485468313098,
            -0.008423032239079475,
            -0.009773540310561657,
            0.004396809730678797,
            0.014441350474953651,
            -0.020727740600705147,
            -0.015255025587975979,
            0.03875879570841789,
            -0.020308973267674446,
            0.02493094839155674,
            -0.0017729413229972124,
            -0.006005436182022095,
            0.0005899286479689181,
            -0.024566808715462685,
            -0.0021385422442108393,
            -0.027500662952661514,
            0.021984539926052094,
            0.00518355006352067,
            0.03111599013209343,
            -0.015487696044147015,
            -0.00381052540615201,
            -0.017994122579693794,
            -0.007429210469126701,
            0.008378102444112301,
            -0.017475921660661697,
            0.007175177335739136,
            0.011376491747796535,
            0.03408766910433769,
            -0.03714638203382492,
            -0.008016322739422321,
            0.05255855619907379,
            0.0014510279288515449,
            -0.017802145332098007,
            -0.03128238022327423,
            -0.03859803080558777,
            0.09102319926023483,
            0.02634831890463829,
            0.03413437679409981,
            -0.012384667061269283,
            -0.021787971258163452,
            -0.027630887925624847,
            -0.011445964686572552,
            0.014450188726186752,
            0.016855288296937943,
            -0.008661655709147453,
            -0.07723474502563477,
            0.0019212388433516026,
            -0.0156258475035429,
            0.015541927888989449,
            0.02695772983133793,
            0.020340614020824432,
            -0.03055720403790474,
            -0.02458413504064083,
            -0.04092753306031227,
            -0.015652984380722046,
            0.00016807307838462293,
            -0.007597251329571009,
            0.028414422646164894,
            -0.02934621274471283,
            0.0010788204381242394,
            -0.027169743552803993,
            -0.00306634115986526,
            0.01826895959675312,
            -0.011165043339133263,
            0.01975613459944725,
            -0.01650542952120304,
            0.016533829271793365,
            -0.03540375828742981,
            0.04159108176827431,
            -0.008324533700942993,
            0.006600718945264816,
            -0.026690742000937462,
            -0.0035825471859425306,
            0.020892256870865822,
            -0.014077955856919289,
            -0.02337915077805519,
            -0.01783580332994461,
            -0.00417977524921298,
            0.009249076247215271,
            0.0011406460544094443,
            0.019890928640961647,
            -0.04224257171154022,
            -0.013465368188917637,
            -0.002703768666833639,
            0.04018176719546318,
            -0.017376158386468887,
            -0.022119134664535522,
            -0.012500052340328693,
            -0.0015444007003679872,
            -0.013345879502594471,
            -0.01673281379044056,
            0.017521068453788757,
            0.026686174795031548,
            0.025478487834334373,
            0.02774222008883953,
            0.01606275700032711,
            -0.039559993892908096,
            -0.03080352582037449,
            0.03456047922372818,
            0.020844584330916405,
            0.02351965755224228,
            -0.012850737199187279,
            -0.021253956481814384,
            -0.008395273238420486,
            -0.003033976536244154,
            -0.005599920637905598,
            -0.0066433497704565525,
            -0.002665831008926034,
            -0.0414726659655571,
            0.0236692912876606,
            -0.0026964684948325157,
            -0.02970503643155098,
            0.04262465611100197,
            -0.03730091080069542,
            0.02910786308348179,
            0.004991316702216864,
            -0.010495229624211788,
            -0.023081138730049133,
            0.03039589524269104,
            -0.0422702319920063,
            -0.015695709735155106,
            0.050223976373672485,
            -0.025596413761377335,
            -0.0016501664649695158,
            0.036173801869153976,
            0.019468165934085846,
            -0.025600140914320946,
            0.028975050896406174,
            -0.012138239108026028,
            -0.004779580514878035,
            0.033147092908620834,
            0.008733997121453285,
            0.005890942644327879,
            0.026391522958874702,
            0.06348022818565369,
            -0.046522583812475204,
            0.003525279462337494,
            0.008156413212418556,
            0.031052222475409508,
            -0.004463582299649715,
            -0.05300423875451088,
            0.007273219525814056,
            0.002172129927203059,
            0.01378365233540535,
            -0.03285500407218933,
            0.02002929151058197,
            -0.017270322889089584,
            -0.001995145110413432,
            -0.0016757171833887696,
            -0.0010125144617632031,
            0.014889120124280453,
            0.01630735769867897,
            0.033203937113285065,
            -0.03335421904921532,
            -0.027868792414665222,
            0.0023138541728258133,
            0.006624600850045681,
            -0.014476357027888298,
            -0.007519801612943411,
            -0.017327895388007164,
            -0.008206235244870186,
            0.012309810146689415,
            0.0013861292973160744,
            -0.019012531265616417,
            0.0013845011126250029,
            -0.003878273069858551,
            -0.030828403308987617,
            -0.007352327927947044,
            -0.06561893969774246,
            -0.04836023226380348,
            -0.011131862178444862,
            0.026404481381177902,
            -0.008209752850234509,
            -0.02212362177670002,
            0.019095387309789658,
            0.030244484543800354,
            -0.014513025991618633,
            -0.003704492235556245,
            -0.00017004356777761132,
            -0.03520924970507622,
            0.004346512258052826,
            0.025096943601965904
        ],
        [
            -0.01044590026140213,
            0.005522369872778654,
            0.00927701499313116,
            0.00923020951449871,
            0.034884124994277954,
            -0.104751817882061,
            0.0404348261654377,
            -0.08386225253343582,
            -0.009630588814616203,
            0.011936561204493046,
            -0.028011459857225418,
            0.009361088275909424,
            -0.035838786512613297,
            0.02819204330444336,
            0.04756085202097893,
            0.009564966894686222,
            -0.012175495736300945,
            -0.0317884162068367,
            0.009133377112448215,
            -0.02370687574148178,
            -0.017729107290506363,
            -0.02280026115477085,
            -0.04735717549920082,
            0.03018677793443203,
            0.05173472315073013,
            0.029435014352202415,
            -0.00848788395524025,
            -0.00597781827673316,
            -0.0017577221151441336,
            0.013438526540994644,
            -0.02972007542848587,
            0.015333537943661213,
            -0.017201712355017662,
            0.00033874050131998956,
            -0.03580876812338829,
            0.0080473767593503,
            0.016421550884842873,
            -0.03847348690032959,
            -0.026605870574712753,
            -0.02063574641942978,
            -0.02569020353257656,
            0.016477594152092934,
            -0.005666086450219154,
            -0.00997958518564701,
            0.033949289470911026,
            -0.06000683084130287,
            -0.0015522417379543185,
            0.053689006716012955,
            -0.006068894639611244,
            -0.030975688248872757,
            0.022859830409288406,
            -0.0020323372446000576,
            -0.0574001669883728,
            -0.0233741644769907,
            0.0022365597542375326,
            0.01221992913633585,
            0.022357994690537453,
            -0.005792717449367046,
            0.010232148692011833,
            -0.037812598049640656,
            -0.02607903815805912,
            0.011013033799827099,
            0.02493714727461338,
            -0.035095617175102234,
            -0.022716041654348373,
            0.0025549824349582195,
            -0.042981475591659546,
            0.025869816541671753,
            -0.03283388167619705,
            -0.004998842719942331,
            -0.005859090946614742,
            0.0015969093656167388,
            0.053190164268016815,
            0.022286642342805862,
            0.015038790181279182,
            0.02149011567234993,
            0.017032697796821594,
            0.03407195582985878,
            -0.05374510586261749,
            0.0028581274673342705,
            0.01624123938381672,
            0.020430736243724823,
            -0.009192912839353085,
            -0.01660706289112568,
            -0.011888482607901096,
            0.006192907225340605,
            -0.04957001656293869,
            -0.006065802648663521,
            0.014209089800715446,
            -0.04512496292591095,
            -0.009645676240324974,
            -0.05296362563967705,
            -0.009424667805433273,
            0.0637330412864685,
            -0.031513046473264694,
            0.0005138519918546081,
            -0.019017737358808517,
            0.013398072682321072,
            0.0024461408611387014,
            0.030429799109697342,
            -0.022097771987318993,
            -0.02214098908007145,
            0.020830538123846054,
            -0.021172672510147095,
            0.030242962762713432,
            -0.017893698066473007,
            0.0009023169986903667,
            0.012432999908924103,
            0.021177656948566437,
            -0.017885198816657066,
            0.009319202043116093,
            -0.0004266842151992023,
            -0.02412981167435646,
            0.02481936104595661,
            -0.06427832692861557,
            0.023554686456918716,
            0.012698691338300705,
            -0.0655725747346878,
            0.04185469448566437,
            0.030672673135995865,
            0.017457837238907814,
            0.0009759526583366096,
            0.002951639937236905,
            0.020593862980604172,
            0.012182585895061493,
            0.039740271866321564,
            -0.03646988794207573,
            -0.010014357976615429,
            0.0004049341077916324,
            -0.022674286738038063,
            -0.03567822277545929,
            -0.047526609152555466,
            -0.05988721922039986,
            -0.009468602947890759,
            -0.0015670828288421035,
            0.030162964016199112,
            -0.051165621727705,
            -0.01863926649093628,
            -0.021978164091706276,
            -0.058088306337594986,
            -0.00339633715339005,
            -0.026474731042981148,
            0.02820573002099991,
            -0.0027017886750400066,
            -0.006384886335581541,
            -0.01595032587647438,
            0.002375959884375334,
            -0.01915760710835457,
            -0.010831721127033234,
            0.035816214978694916,
            0.028940880671143532,
            0.07369215041399002,
            -0.00519591523334384,
            -0.030697597190737724,
            -0.022227615118026733,
            -0.008864354342222214,
            0.01993974670767784,
            0.027214111760258675,
            -0.017421960830688477,
            -0.028770284727215767,
            0.017351746559143066,
            0.00042751082219183445,
            0.006865635979920626,
            0.028141003102064133,
            -0.027935491874814034,
            -0.016452331095933914,
            -0.022036021575331688,
            -0.01644221879541874,
            0.008114373311400414,
            -0.012846464291214943,
            0.018374035134911537,
            -0.02116190455853939,
            -0.003580939956009388,
            -0.014371907338500023,
            -0.045758750289678574,
            0.006632832810282707,
            -0.042907990515232086,
            -0.020456628873944283,
            -0.013053612783551216,
            0.03033369407057762,
            -0.015891870483756065,
            -0.014818521216511726,
            0.04515526443719864,
            -0.0143065070733428,
            -0.035532835870981216,
            -0.008454248309135437,
            -0.012894988991320133,
            -0.04430418461561203,
            -0.016190964728593826,
            0.005001721438020468,
            0.048852309584617615,
            0.013148863799870014,
            -0.05600694194436073,
            0.0022283715661615133,
            0.012513193301856518,
            0.0039571854285895824,
            0.02400091476738453,
            0.007749666925519705,
            -0.0056491391733288765,
            -0.037257395684719086,
            -0.018564647063612938,
            0.019689911976456642,
            0.020702209323644638,
            -0.036340679973363876,
            0.023306645452976227,
            0.01723932847380638,
            0.018404645845294,
            0.00035429198760539293,
            0.005218175705522299,
            0.032868001610040665,
            0.03280865401029587,
            0.007921289652585983,
            0.045352838933467865,
            -0.011674575507640839,
            -0.004214327782392502,
            0.025546051561832428,
            -0.04279499128460884,
            -0.0030645402148365974,
            -0.02363509126007557,
            0.01886892132461071,
            -0.02473897859454155,
            -0.0075748213566839695,
            0.0386352576315403,
            0.019045960158109665,
            0.006230517756193876,
            -0.041445184499025345,
            0.022087903693318367,
            0.019869184121489525,
            0.017875056713819504,
            0.0025828650686889887,
            0.027811968699097633,
            -0.015103143639862537,
            -0.03030272386968136,
            0.005349039565771818,
            -0.04246797412633896,
            0.019190097227692604,
            -0.015532415360212326,
            -0.02175709791481495,
            -0.02438100054860115,
            0.025716090574860573,
            -0.020492564886808395,
            0.0050242263823747635,
            -0.009690504521131516,
            0.017819687724113464,
            0.01554199494421482,
            -0.04085253179073334,
            -0.056349679827690125,
            -0.0005511809140443802,
            -0.0033224758226424456,
            -0.011990112252533436,
            0.011366540566086769,
            0.008565744385123253,
            0.03883204236626625,
            -0.021438298746943474,
            0.02120126783847809,
            0.001618322916328907,
            0.032385632395744324,
            -0.046037089079618454,
            0.004495825618505478,
            -0.01982368715107441,
            0.04092296212911606,
            0.006146653555333614,
            0.0636121928691864,
            0.010489842854440212,
            -0.0022619147785007954,
            0.020552052184939384,
            -0.005085926502943039,
            0.02192082069814205,
            0.0052590202540159225,
            0.013038063421845436,
            -0.010098342783749104,
            -0.017283059656620026,
            -0.01466413214802742,
            0.014815208502113819,
            0.06468994170427322,
            0.0011108635226264596,
            0.0470898412168026,
            0.007001123391091824,
            -0.04023992642760277,
            0.03765267878770828,
            0.043594807386398315,
            0.02980574034154415,
            -0.020465761423110962,
            0.05340813845396042,
            -0.016684727743268013,
            -0.012935134582221508,
            0.03361836075782776,
            0.021501608192920685,
            0.029210573062300682,
            0.016665447503328323,
            0.005365924444049597,
            0.03464410454034805,
            0.01223757117986679,
            -0.02050601691007614,
            0.02790767326951027,
            -0.001613711123354733,
            0.02216670848429203,
            -0.012807758525013924,
            0.039595168083906174,
            0.008494826965034008,
            -0.03931235522031784,
            0.04756522923707962,
            0.010768950916826725,
            0.015599498525261879,
            0.03746975213289261,
            0.0074238767847418785,
            0.033697471022605896,
            0.05219189450144768,
            0.0209849551320076,
            -0.060502614825963974,
            0.030846264213323593,
            -0.0008930556941777468,
            0.0059187281876802444,
            0.006756884977221489,
            -0.00401187501847744,
            0.03161924332380295,
            0.05942441523075104,
            -0.009014119394123554,
            -0.028845971450209618,
            -0.049186840653419495,
            -0.0007789047085680068,
            0.07310058921575546,
            0.04446180909872055,
            -0.09586793929338455,
            0.005245768465101719,
            0.02194662019610405,
            -0.0404968298971653,
            0.03649003058671951,
            0.029207713901996613,
            -0.02532360330224037,
            -0.054515544325113297,
            -0.01600443199276924,
            0.045168377459049225,
            0.007206339854747057,
            -0.019637009128928185,
            0.01188276894390583,
            0.002911598654463887,
            -0.013115807436406612,
            -0.003176686353981495,
            -0.006812755484133959,
            -0.025796720758080482,
            0.011536411009728909,
            -0.035204146057367325,
            0.0023755438160151243,
            0.005419574677944183,
            -0.07228073477745056,
            -0.0033106899354606867,
            0.01047282200306654,
            0.028691714629530907,
            -0.03739197552204132,
            -0.008438283577561378,
            0.07514261454343796,
            -0.004646469373255968,
            -0.02168823964893818,
            -0.002484908327460289,
            0.03619781881570816,
            -0.023090440779924393,
            0.03386261314153671,
            -0.0033984058536589146,
            0.01423477753996849,
            0.02807622216641903,
            -0.01003722008317709,
            -0.014739864505827427,
            0.010349956341087818,
            -0.02549031935632229,
            -0.019982269033789635,
            0.0475592240691185,
            0.034057606011629105,
            -0.025010768324136734,
            0.030518947169184685,
            -0.00013026566011831164,
            -0.020973285660147667,
            0.023656651377677917,
            0.01881527714431286,
            0.014553196728229523,
            0.01593962498009205,
            0.012515158392488956,
            -0.03325831517577171,
            -0.01592119410634041,
            0.028209421783685684,
            -0.0001678861735854298,
            0.007446054369211197,
            -0.0273760873824358,
            -0.012805445119738579,
            -0.003021963406354189,
            -0.0048067583702504635,
            -0.021600455045700073,
            0.0426662303507328,
            0.02329646237194538,
            -0.02429146319627762,
            -0.002104800660163164,
            -0.03621325641870499,
            -0.013425028882920742,
            -0.045728057622909546,
            -0.022155268117785454,
            -0.0011891244212165475,
            -0.014899279922246933,
            -0.02304665558040142,
            -0.029470909386873245,
            0.022105369716882706,
            0.02842717431485653,
            0.016912203282117844,
            -0.013355924747884274,
            0.013256912119686604,
            0.013423317112028599,
            0.026077164337038994,
            0.014805937185883522,
            -0.0038501813542097807,
            0.003950904589146376,
            0.013930159620940685,
            0.0010831610998138785,
            0.0006585504161193967,
            -0.05505652725696564,
            0.003841104218736291,
            0.006440375000238419,
            -0.018942058086395264,
            -0.007513999938964844,
            0.03585115447640419,
            -0.0515863299369812,
            -0.02494080364704132,
            0.024980679154396057,
            0.015053723938763142,
            -0.026879122480750084,
            0.03192250430583954,
            0.015688946470618248,
            -0.05600984767079353,
            0.005248751491308212,
            0.040651585906744,
            -0.029104921966791153,
            -0.02960493043065071,
            0.0018662572838366032,
            -0.013472010381519794,
            -0.00946912169456482,
            0.03121461160480976,
            -0.0017330498667433858,
            0.033676423132419586,
            0.015485459938645363,
            0.018393531441688538,
            0.01851707510650158,
            -0.012540786527097225,
            0.03096037544310093,
            -0.00030023721046745777,
            -0.025679511949419975,
            0.006909460760653019,
            -0.0051736487075686455,
            -0.009242056868970394,
            -0.03459466993808746,
            -0.02712426893413067,
            -0.039282120764255524,
            0.009454849176108837,
            -0.01464503537863493,
            -0.018653931096196175,
            0.01354247611016035,
            -0.007750550284981728,
            0.06736952066421509,
            -0.019320018589496613,
            0.01826634258031845,
            -0.009906850755214691,
            -0.0030865869484841824,
            -0.0004000135522801429,
            -0.004979634191840887,
            0.0021718896459788084,
            0.009305273182690144,
            0.052768561989068985,
            0.040260396897792816,
            -0.0012631226563826203,
            0.006668997462838888,
            0.049762554466724396,
            -0.0007696612738072872,
            0.010938555933535099,
            -0.025898948311805725,
            0.030510125681757927,
            0.0028478160966187716,
            -0.015047620981931686,
            0.009348993189632893,
            -0.0074452287517488,
            -0.021565299481153488,
            0.010613054037094116,
            0.037957072257995605,
            0.025288885459303856,
            0.004974141716957092,
            -0.012933855876326561,
            -0.00047332904068753123,
            0.016913849860429764,
            -0.01104377955198288,
            -0.02505255676805973,
            -0.030849626287817955,
            -0.003419355023652315,
            -0.08318565040826797,
            0.038674574345350266,
            0.02181096561253071,
            -0.014766319654881954,
            0.02763541415333748,
            0.0002454040222801268,
            -0.00716501334682107,
            -0.002670735353603959,
            0.011154050007462502,
            -0.010891787707805634,
            -0.026147175580263138,
            0.028996257111430168,
            0.01327495276927948,
            0.008690564893186092,
            -0.023672405630350113,
            -0.03951093181967735,
            0.050887588411569595,
            -0.0028410388622432947,
            -0.01949339732527733,
            0.03427129238843918,
            -0.009651146829128265,
            -0.002462884644046426,
            0.01790710724890232,
            -0.02641802281141281,
            0.023560574278235435,
            0.005256940145045519,
            0.005620392505079508,
            -0.00164872279856354,
            -0.0015146301593631506,
            -0.014288623817265034,
            -0.0028724228031933308,
            -0.0167933888733387,
            -0.005789936985820532,
            0.019667521119117737,
            -0.017089135944843292,
            -0.018353736028075218,
            0.04286286234855652,
            -0.017718032002449036,
            0.013073173351585865,
            0.039482954889535904,
            -0.015686510130763054,
            0.03428032994270325,
            -0.015095299109816551,
            0.01036026794463396,
            -0.018509717658162117,
            -0.04122595489025116,
            0.013131381943821907,
            0.06296584010124207,
            -0.029021866619586945,
            0.001631137914955616,
            -0.03218025341629982,
            0.019759194925427437,
            -0.022906523197889328,
            0.02474289946258068,
            -0.03099462017416954,
            -0.05809157341718674,
            -0.027270464226603508,
            0.08874418586492538,
            0.009703557938337326,
            -0.03787647560238838,
            -0.0179669801145792,
            8.348045230377465e-05,
            0.011975693516433239,
            0.014740201644599438,
            0.0014657609863206744,
            -0.03085077926516533,
            0.055139340460300446,
            -0.0243716798722744,
            0.01665719412267208,
            -0.03663003444671631,
            -0.013362129218876362,
            0.0022902952041476965,
            0.04812163859605789,
            0.0032766421791166067,
            -0.015213573351502419,
            -0.03438971936702728,
            0.002860001288354397,
            0.008386686444282532,
            0.03161189705133438,
            -0.013810223899781704,
            -0.02134762704372406,
            -0.020423278212547302,
            -0.015002736821770668,
            -0.002207981189712882,
            0.03246966749429703,
            0.009588371962308884,
            0.07744288444519043,
            0.01268799602985382,
            0.0047562262043356895,
            -0.028595611453056335,
            0.031142137944698334,
            -0.022375134751200676,
            -0.03298061341047287,
            0.004729022737592459,
            -0.033037588000297546,
            -0.030046910047531128,
            0.017889507114887238,
            0.03623102605342865,
            0.004004146438091993,
            5.148600394022651e-05,
            -0.014201981015503407,
            0.02531745843589306,
            0.042574070394039154,
            0.04589146748185158,
            -0.018961522728204727,
            -0.009961580857634544,
            -0.0010370040545240045,
            0.00913914479315281,
            0.028917867690324783,
            0.011044710874557495,
            -0.005382268689572811,
            0.03276203200221062,
            -0.06578050553798676,
            0.026069339364767075,
            0.0004628199094440788,
            -0.04963647574186325,
            -0.011137592606246471,
            -0.02970300428569317,
            0.012462981045246124,
            -0.006599953398108482,
            0.037610020488500595,
            -0.0041565182618796825,
            -0.004675047472119331,
            0.024259714409708977,
            0.018910586833953857,
            -0.030157385393977165,
            0.00542225269600749,
            -0.056571114808321,
            0.014866306446492672,
            -0.050842609256505966,
            -0.0040085334330797195,
            0.007275092881172895,
            0.022547611966729164,
            -0.008565672673285007,
            -0.023622743785381317,
            -0.015595893375575542,
            0.0008209890802390873,
            -0.04121993109583855,
            0.026069609448313713,
            -0.03261592611670494,
            -0.008908871561288834,
            0.018714414909482002,
            -0.019292868673801422,
            0.022649206221103668,
            0.02536029927432537,
            -0.03318360447883606,
            0.009317616932094097,
            0.01968546397984028,
            0.017263615503907204,
            0.007892035879194736,
            0.029247669503092766,
            0.005472519900649786,
            0.02945633977651596,
            -0.0009111654944717884,
            0.06371341645717621,
            -0.029911663383245468,
            0.00652097025886178,
            0.007724236696958542,
            0.05915473774075508,
            -0.027209937572479248,
            0.013743879273533821,
            0.02435179427266121,
            -0.004731085617095232,
            0.003658147295936942,
            0.014777405187487602,
            -0.01352311484515667,
            0.01733260229229927,
            -0.02648398093879223,
            0.008019818924367428,
            0.012234853580594063,
            -0.014416065067052841,
            -0.004182593431323767,
            0.041641514748334885,
            0.021713867783546448,
            -0.005540512502193451,
            -0.012113167904317379,
            -0.02171267755329609,
            -0.03236538916826248,
            0.029853152111172676,
            0.031211793422698975,
            0.006236311513930559,
            -0.06262072175741196,
            0.014779604971408844,
            0.06078439578413963,
            -0.027643220499157906,
            0.010467419400811195,
            -0.03603677079081535,
            -0.0011022856924682856,
            0.02835812047123909,
            0.012526682578027248,
            -0.007640530355274677,
            -0.030990028753876686,
            -0.04894723370671272,
            0.03042682446539402,
            -0.0017738040769472718,
            -0.021882623434066772,
            0.01530533004552126,
            -0.0013196645304560661,
            -0.05155019089579582,
            -0.017218004912137985,
            0.014576835557818413,
            0.040101539343595505,
            -0.007266545202583075,
            0.038448963314294815,
            0.0127003388479352,
            -0.02469140663743019,
            0.00594821572303772,
            -0.006437578704208136,
            0.006596350576728582,
            0.026989461854100227,
            0.013512072153389454,
            0.02696392685174942,
            0.0008137248805724084,
            0.08849731832742691,
            0.003600921481847763,
            0.005174357444047928,
            0.019785983487963676,
            0.005513468291610479,
            -0.0072059957310557365,
            0.03848756477236748,
            0.000951513706240803,
            0.006929253228008747,
            -0.023045361042022705,
            -0.026582345366477966,
            0.045232921838760376,
            0.039697349071502686,
            0.008980042301118374,
            -0.02570497803390026,
            0.007912924513220787,
            0.04192740470170975,
            -0.019356366246938705,
            0.021329233422875404,
            0.016779625788331032,
            -0.0007732625235803425,
            0.027365902438759804,
            0.001497739925980568,
            0.02575439214706421,
            -0.01603785715997219,
            -0.009270171634852886,
            0.03258857876062393,
            0.019768401980400085,
            0.02513657882809639,
            0.03232131153345108,
            -0.054451994597911835,
            0.012567786499857903,
            -0.019015643745660782,
            0.035640064626932144,
            -0.027842655777931213,
            0.012256108224391937,
            0.007101577240973711,
            0.027197953313589096,
            0.004877275787293911,
            -0.037349916994571686,
            0.05995553731918335,
            -0.03618913143873215,
            0.023365747183561325,
            0.0005133923841640353,
            0.025037188082933426,
            0.05930591747164726,
            0.026507817208766937,
            -0.031100239604711533,
            -0.015452695079147816,
            0.016169358044862747,
            -0.03684611618518829,
            0.017227916046977043,
            -0.011416169814765453,
            -0.026476221159100533,
            0.018955862149596214,
            0.0503019280731678,
            0.022385133430361748,
            0.057655952870845795,
            -0.006372220814228058,
            0.0016589678125455976,
            0.01058266218751669,
            0.013317572884261608,
            -0.00941312126815319,
            0.019396452233195305,
            0.018029840663075447,
            0.03715828061103821,
            0.016166454181075096,
            0.0005296755116432905,
            -0.019646160304546356,
            0.01800604723393917,
            -0.004703859332948923,
            0.02764357626438141,
            0.05617062374949455,
            0.01851966604590416,
            0.03135231137275696,
            0.047172971069812775,
            0.00631772680208087,
            -0.018830088898539543,
            0.002027742797508836,
            0.0399620346724987,
            -0.025887448340654373,
            -0.02580605447292328,
            0.007644214667379856,
            0.02383952960371971,
            0.03303385153412819,
            0.024189818650484085,
            0.013215648010373116,
            0.017691604793071747,
            0.0065329731442034245,
            -0.010490127839148045,
            -0.021292168647050858,
            -0.06520343571901321,
            -0.06958476454019547,
            -0.0026422643568366766,
            0.010147199034690857,
            -0.06103408336639404,
            -0.020990654826164246,
            -0.0030084506142884493,
            -0.03334347903728485,
            -0.011654381640255451,
            -0.05507289990782738,
            0.024849414825439453,
            0.024383464828133583,
            -0.02150103636085987,
            0.045345257967710495,
            -0.04187024384737015,
            -0.01912042871117592,
            -0.031604085117578506,
            0.003968755714595318,
            0.004390998277813196,
            0.05613705888390541,
            0.00982485432177782,
            0.009383898228406906,
            -0.014603158459067345,
            -0.00837954506278038,
            0.020190013572573662,
            0.025326957926154137,
            0.0097429184243083,
            -0.025497067719697952,
            0.023742051795125008,
            -0.017355037853121758,
            -0.010677216574549675,
            -0.01435935404151678,
            0.02296314388513565,
            0.014905175194144249,
            0.021853754296898842,
            -0.01819607801735401,
            0.01870349422097206,
            0.01672937721014023,
            -0.01064447034150362,
            -0.03177959471940994,
            0.010297794826328754,
            0.000511295860633254,
            0.004566275980323553,
            0.0510287880897522,
            -0.022631509229540825,
            -0.06172626465559006,
            0.02134634181857109,
            0.024907462298870087,
            0.007121339440345764,
            -0.010688644833862782,
            0.02041049860417843,
            0.03427509963512421,
            0.004263034090399742,
            0.02331097237765789,
            0.01760975457727909,
            0.0018323683179914951,
            -0.022030485793948174,
            0.030885767191648483,
            0.024607937783002853,
            -0.0173686221241951,
            0.04702378809452057,
            0.028681553900241852,
            0.0009977967711165547,
            -0.00902830995619297,
            0.04072989150881767,
            0.0035775708965957165,
            0.026280289515852928,
            -0.01689312979578972,
            -0.008729740977287292,
            0.0037979118060320616,
            -0.03567194566130638,
            0.003205253044143319,
            -0.05335982143878937,
            -0.01136441808193922,
            -0.007930855266749859,
            0.03573901578783989,
            0.025827156379818916,
            -0.008595339953899384,
            -0.07078738510608673,
            -0.0196062084287405,
            -0.02342114970088005,
            -0.030498642474412918,
            -0.002562378067523241,
            0.031639836728572845,
            0.03568446636199951,
            -0.0372207835316658,
            -0.020273230969905853,
            -0.03407396748661995,
            -0.014472676441073418,
            0.0059670936316251755,
            0.04076385125517845,
            0.028027314692735672,
            -0.03496643155813217,
            -0.013868259266018867,
            0.019114559516310692,
            0.006123194936662912,
            0.0010469639673829079,
            -0.0031460432801395655,
            -0.020863264799118042,
            0.010931377299129963,
            -0.012685989029705524,
            0.00950142927467823,
            0.03673746436834335,
            -0.0018246608087792993,
            -0.011115948669612408,
            0.0056468164548277855,
            -0.0276314876973629,
            0.006674801930785179,
            0.06352663040161133,
            -0.010316938161849976,
            -0.025923876091837883,
            0.021766802296042442,
            0.0577499121427536,
            -0.009349919855594635,
            -0.057177767157554626,
            0.013433469459414482,
            -0.019519757479429245,
            0.026643497869372368,
            -0.044381655752658844,
            0.03405624255537987,
            -0.021706320345401764,
            0.04472087323665619,
            -0.0192509014159441,
            -0.010109994560480118,
            -0.028938179835677147,
            0.037490714341402054,
            0.0026338102761656046,
            -0.01434539444744587,
            -0.018923025578260422,
            0.004364759661257267,
            0.012759754434227943,
            -0.01622125692665577,
            -0.026394397020339966,
            -0.026476435363292694,
            -0.004234770778566599,
            -0.011858401820063591,
            -0.03395722433924675,
            -0.00825388915836811,
            -0.004455541260540485,
            0.04534650221467018,
            0.03169120103120804,
            0.04599509388208389,
            0.05433820188045502,
            -0.008360461331903934,
            -0.016127029433846474,
            -0.03503948450088501,
            -0.012532593682408333,
            0.02710815519094467,
            -0.02273034118115902,
            -0.020867066457867622,
            0.000438920222222805,
            0.011023307219147682,
            -0.023741144686937332,
            0.03742556646466255,
            -0.027874555438756943,
            -0.023928504437208176,
            0.018683480098843575,
            0.009212697856128216,
            0.04608246684074402,
            -0.031022945418953896,
            -0.011236464604735374,
            -0.021990496665239334,
            -0.020237768068909645,
            -0.009638754650950432,
            0.01036737859249115,
            -0.0023709964007139206,
            -0.03412443399429321,
            -0.008357871323823929,
            -0.05216994509100914,
            0.022321417927742004,
            0.06382303684949875,
            0.008180109784007072,
            0.02005140297114849,
            -0.030036799609661102,
            -0.10498230159282684,
            0.014923146925866604,
            -0.01311209425330162,
            0.031901054084300995,
            -0.015782512724399567,
            0.0059692091308534145,
            -0.0056807431392371655,
            0.029783165082335472,
            -0.009785997681319714,
            0.005726871080696583,
            0.030695565044879913,
            -0.024527961388230324,
            0.0036707869730889797,
            -0.03553144633769989,
            -0.02177802287042141,
            -0.005500348284840584,
            0.005527551751583815,
            -0.022493349388241768,
            -0.022278383374214172,
            -0.010019268840551376,
            -0.04286104068160057,
            0.008880590088665485,
            0.03744310140609741,
            -0.0019047375535592437,
            -0.012955687940120697,
            -0.0012993467971682549,
            0.0033348186407238245,
            0.008229977451264858,
            -0.03665229678153992,
            -0.0012704037362709641,
            0.019770091399550438,
            0.013499611988663673,
            -0.05070263892412186,
            0.020831573754549026,
            -0.007021162658929825,
            0.0423370897769928,
            0.018075354397296906,
            0.01643754355609417,
            -0.02680284157395363,
            -0.010937240906059742,
            -0.03372450917959213,
            -0.01316206343472004,
            -0.011264489963650703,
            -0.030244730412960052,
            0.023311616852879524,
            0.016781561076641083,
            0.010157410055398941,
            0.02607094869017601,
            0.010811056941747665,
            -0.0146016925573349,
            -0.011531807482242584,
            -0.026040170341730118,
            -0.019027192145586014,
            0.006805661600083113,
            -0.06449690461158752,
            -0.08313243091106415,
            -0.04584116488695145,
            0.03512583300471306
        ],
        [
            -0.034268416464328766,
            -0.02662794664502144,
            0.006559346336871386,
            0.009969761595129967,
            -0.002026669681072235,
            -0.005372798535972834,
            0.0009955839486792684,
            0.010818324983119965,
            0.00473211333155632,
            0.005389717407524586,
            0.011559264734387398,
            0.015485833398997784,
            0.02251984365284443,
            0.01505698449909687,
            -0.021636903285980225,
            -0.00239437073469162,
            0.007245066575706005,
            0.037697721272706985,
            -0.03720476105809212,
            -0.029456671327352524,
            -0.01689852960407734,
            -0.02525986358523369,
            -0.007590204011648893,
            0.03308015316724777,
            -0.027826733887195587,
            0.03512325882911682,
            -0.03004254214465618,
            -0.02162843942642212,
            0.004185850266367197,
            -0.0012972706463187933,
            -0.008756382390856743,
            -0.009739900007843971,
            0.011089653708040714,
            -0.02548755332827568,
            0.007099678739905357,
            -0.025013713166117668,
            -0.004321255721151829,
            0.030779216438531876,
            0.0017307027010247111,
            -0.02859407663345337,
            0.0059187207370996475,
            0.00941629521548748,
            -0.037836141884326935,
            0.022453920915722847,
            0.032776884734630585,
            -0.015266834758222103,
            -0.007877162657678127,
            -0.028472986072301865,
            0.03136832267045975,
            0.0010992661118507385,
            -0.005669816397130489,
            0.02127644419670105,
            0.007286646403372288,
            -0.005340936128050089,
            0.04423150047659874,
            0.012543759308755398,
            -0.030265996232628822,
            -0.02799176052212715,
            -0.014873376116156578,
            -0.027191247791051865,
            -0.00115646340418607,
            0.0005086653982289135,
            0.017832839861512184,
            -0.01948312669992447,
            -0.022975260391831398,
            0.00360162858851254,
            0.03987779840826988,
            -0.000502292939927429,
            0.026249295100569725,
            -0.01475090067833662,
            0.022393424063920975,
            0.031946439296007156,
            -0.007996694184839725,
            -0.014088431373238564,
            0.008485832251608372,
            0.030115528032183647,
            -0.02640078216791153,
            0.010501919314265251,
            0.002511450555175543,
            -0.03443947061896324,
            -0.0157143697142601,
            -0.01559650618582964,
            0.0030172127299010754,
            2.7708827474270947e-05,
            -0.014352066442370415,
            0.02284913696348667,
            0.018419720232486725,
            -0.03649132326245308,
            -0.010249277576804161,
            -0.006574426777660847,
            0.026781050488352776,
            0.017833519726991653,
            0.017138278111815453,
            0.017369015142321587,
            0.03395305946469307,
            0.007035866379737854,
            -0.026783814653754234,
            0.013432669453322887,
            -0.09404782950878143,
            -0.0045829154551029205,
            -0.003661469789221883,
            0.05263651907444,
            -0.01258922927081585,
            0.004045791458338499,
            0.0042138658463954926,
            -0.01584194228053093,
            -0.0012194581795483828,
            0.02887386828660965,
            -0.014565692283213139,
            -0.026495950296521187,
            -0.0024332948960363865,
            -0.030797293409705162,
            -0.001163126085884869,
            -0.005956529639661312,
            -0.05927564576268196,
            -0.024400463327765465,
            -0.028293319046497345,
            0.01591477543115616,
            0.019695069640874863,
            -0.022504454478621483,
            0.025883285328745842,
            -0.011697119101881981,
            -0.032409075647592545,
            0.03346218168735504,
            0.01044968981295824,
            0.03591018170118332,
            0.02334056980907917,
            0.0197927113622427,
            0.0216470155864954,
            -0.0022306214086711407,
            0.01608879305422306,
            -0.005745667964220047,
            0.03551017865538597,
            -0.0026551384944468737,
            0.02368268370628357,
            -0.009783115237951279,
            -0.005965122953057289,
            -0.024494880810379982,
            0.021381229162216187,
            0.009455312974750996,
            0.014343855902552605,
            0.029269641265273094,
            -0.02668091654777527,
            -0.011948571540415287,
            -0.004233045037835836,
            0.024951379746198654,
            0.036467891186475754,
            0.029465455561876297,
            -0.01855327934026718,
            0.030658982694149017,
            0.030384527519345284,
            0.02602921426296234,
            0.015968529507517815,
            0.00034648802829906344,
            -0.022589799016714096,
            0.010942085646092892,
            0.03146221861243248,
            -0.020082078874111176,
            -0.019106436520814896,
            -0.005243584513664246,
            -0.018213002011179924,
            -0.004055086523294449,
            -0.0038127521984279156,
            -0.0004768514190800488,
            0.026717841625213623,
            -0.006399277597665787,
            0.01833866722881794,
            0.019284889101982117,
            0.02319921925663948,
            0.014105432666838169,
            -0.019973719492554665,
            0.008103679865598679,
            -0.03829791396856308,
            0.038799867033958435,
            0.001847400446422398,
            -0.010449664667248726,
            0.025275401771068573,
            0.003080330090597272,
            -0.008076423779129982,
            -0.014064145274460316,
            0.00035616138484328985,
            -0.0035435957834124565,
            0.007453324738889933,
            0.015808433294296265,
            -0.010241256095468998,
            0.01662660390138626,
            0.014319086447358131,
            0.016695352271199226,
            -0.02484099008142948,
            -0.0137018458917737,
            0.02399975061416626,
            0.024560434743762016,
            0.036879051476716995,
            -0.021208206191658974,
            0.01413201168179512,
            0.008305848576128483,
            -0.008626301772892475,
            0.022468579933047295,
            -0.008334234356880188,
            -0.005649173632264137,
            0.009206809103488922,
            0.011389183811843395,
            -0.0013976787449792027,
            0.018791574984788895,
            -0.02697575092315674,
            0.01622634194791317,
            -0.012848597019910812,
            0.006358702201396227,
            -0.01837434619665146,
            0.00935409590601921,
            0.029172956943511963,
            -0.004386827349662781,
            -0.02548186480998993,
            0.029895450919866562,
            -0.005594891961663961,
            -0.0071376957930624485,
            -0.011293006129562855,
            -0.016524525359272957,
            -0.029085740447044373,
            -0.0182472076267004,
            0.024033743888139725,
            -0.04097217693924904,
            -0.020002033561468124,
            0.016773395240306854,
            0.03230658546090126,
            0.015710374340415,
            0.03872139751911163,
            0.028593458235263824,
            0.023578736931085587,
            -0.020570503547787666,
            -0.03086124174296856,
            -0.019283240661025047,
            -0.022397173568606377,
            0.005329565145075321,
            -0.0014354330487549305,
            0.023972300812602043,
            0.0212169848382473,
            0.02656148187816143,
            0.01205750834196806,
            0.02729015424847603,
            -0.015961062163114548,
            -0.021308794617652893,
            0.02144026756286621,
            0.021477654576301575,
            0.02363019995391369,
            0.0215867031365633,
            0.001600821502506733,
            -0.01691487804055214,
            -0.028157053515315056,
            -0.004893745295703411,
            0.008976309560239315,
            -0.014268284663558006,
            -0.020432228222489357,
            -0.013850641436874866,
            -0.002612766809761524,
            -0.028335288166999817,
            0.00512290745973587,
            -0.011948264203965664,
            -0.013197819702327251,
            0.004606108646839857,
            0.011534766294062138,
            0.030862726271152496,
            -0.011765038594603539,
            -0.0290976595133543,
            -0.02721981145441532,
            0.0135860126465559,
            -0.022789686918258667,
            0.031119128689169884,
            -0.008534913882613182,
            0.03561866283416748,
            0.020190102979540825,
            -0.01875000260770321,
            -0.015311075374484062,
            -0.014279337599873543,
            -0.028077442198991776,
            -0.010380267165601254,
            -0.020038923248648643,
            0.028226489201188087,
            -0.009524727240204811,
            -0.021725671365857124,
            0.019596686586737633,
            0.03362877294421196,
            0.0038431184366345406,
            -0.025104759261012077,
            0.012108578346669674,
            0.028452472761273384,
            -0.011673714965581894,
            -0.017316177487373352,
            -0.01501836720854044,
            0.0032079662196338177,
            0.03165165334939957,
            0.02165628969669342,
            0.0036095299292355776,
            -0.006153318099677563,
            0.006758832838386297,
            -0.021598421037197113,
            0.027576448395848274,
            -0.00045172174577601254,
            -0.024328798055648804,
            0.010577274486422539,
            -0.008721395395696163,
            -0.01158328540623188,
            0.028188692405819893,
            -0.00319537497125566,
            0.014242088422179222,
            -0.0067815701477229595,
            0.046993859112262726,
            0.018947962671518326,
            0.001601589028723538,
            -0.00662603136152029,
            0.016968799754977226,
            0.010190259665250778,
            -0.012824466452002525,
            0.0256254393607378,
            0.008998475037515163,
            0.016279933974146843,
            -0.017445983365178108,
            -0.010142029263079166,
            -0.006427068263292313,
            -0.028905188664793968,
            -0.014502751640975475,
            -0.021992715075612068,
            -0.01825052872300148,
            -0.03549002856016159,
            0.007161804474890232,
            0.02938690595328808,
            0.009418180212378502,
            -0.015765536576509476,
            0.034799300134181976,
            0.023485172539949417,
            0.011145655997097492,
            -0.017145194113254547,
            -0.012302486225962639,
            -0.022958822548389435,
            0.02551076002418995,
            0.028513804078102112,
            -0.011842912063002586,
            0.013712568208575249,
            0.03137655556201935,
            -0.025100132450461388,
            0.03517255187034607,
            0.028948912397027016,
            -0.02468658983707428,
            0.017660386860370636,
            -0.01715150475502014,
            0.014504573307931423,
            -0.006933088880032301,
            -0.014112202450633049,
            -0.011574184522032738,
            -0.01216479204595089,
            -0.008393341675400734,
            -0.028906164690852165,
            -0.007018121425062418,
            -0.026898503303527832,
            0.028351200744509697,
            -0.020583299919962883,
            -0.020702069625258446,
            0.03898850455880165,
            -0.032552435994148254,
            0.008328532800078392,
            -0.010082758031785488,
            -0.024923579767346382,
            -0.009573390707373619,
            -0.010592451319098473,
            0.002694531809538603,
            -0.03598513826727867,
            0.0034962729550898075,
            0.009197171777486801,
            0.0012331969337537885,
            0.01086515188217163,
            0.024838047102093697,
            0.020114179700613022,
            0.028104640543460846,
            -0.007464596536010504,
            0.0009119976311922073,
            0.020004117861390114,
            0.008072311989963055,
            -0.011354017071425915,
            -0.023006621748209,
            -0.027886992320418358,
            -0.0035950890742242336,
            0.023465897887945175,
            -0.010220603086054325,
            0.01334120612591505,
            -0.016363224014639854,
            -0.022470880299806595,
            0.008882894180715084,
            -0.035620592534542084,
            0.011285950429737568,
            0.013687447644770145,
            0.01085455622524023,
            0.006947357207536697,
            0.003382774768397212,
            -0.024142051115632057,
            0.058019500225782394,
            -0.010921187698841095,
            -0.02356611005961895,
            0.03167914226651192,
            -0.019442617893218994,
            -0.021774131804704666,
            -0.008482292294502258,
            0.0064742229878902435,
            0.0324394553899765,
            -0.004512067884206772,
            -0.025887466967105865,
            0.0066445572301745415,
            -0.022662919014692307,
            -0.006064015906304121,
            0.015618309378623962,
            0.002298402599990368,
            -0.014961020089685917,
            -0.0060020918026566505,
            0.018474940210580826,
            -0.01542892400175333,
            0.03224000707268715,
            -0.0019653968047350645,
            -0.011001236736774445,
            0.02748681791126728,
            -0.01393841952085495,
            0.018182050436735153,
            -0.01355197187513113,
            0.014069775119423866,
            0.011075520887970924,
            0.017879804596304893,
            -0.02128206193447113,
            -0.020297961309552193,
            0.023491937667131424,
            -0.04121504724025726,
            0.028258217498660088,
            -0.004418244585394859,
            0.014132749289274216,
            0.023067988455295563,
            -0.009891730733215809,
            0.001385988900437951,
            0.0024861071724444628,
            -0.053312260657548904,
            0.018928557634353638,
            0.003090939251706004,
            0.0026238649152219296,
            0.032993122935295105,
            -0.02527271769940853,
            -0.022770900279283524,
            0.027223946526646614,
            0.008002791553735733,
            -0.007212773896753788,
            0.023600760847330093,
            -0.0038866610266268253,
            -0.09159644693136215,
            0.013418582268059254,
            -0.028611036017537117,
            -0.003036648966372013,
            -0.004744143690913916,
            -0.01588231511414051,
            -0.02539789117872715,
            -0.006162737961858511,
            -0.01996813528239727,
            0.03500092029571533,
            0.010201619938015938,
            -0.01186713669449091,
            -0.006089030299335718,
            0.01843142881989479,
            0.006415041163563728,
            0.027067020535469055,
            -0.01326653640717268,
            0.014099884778261185,
            -0.010901007801294327,
            -0.04400404915213585,
            -0.04604484140872955,
            -0.0270969420671463,
            0.009447622112929821,
            0.011972559615969658,
            -0.0189183559268713,
            -0.0053236461244523525,
            -0.014185777865350246,
            -0.033052798360586166,
            -0.00807457696646452,
            0.03430485725402832,
            -0.01913033425807953,
            0.02470029890537262,
            -0.015037773177027702,
            0.004118830431252718,
            0.015754863619804382,
            -0.06405533850193024,
            -0.01708110049366951,
            0.0311586894094944,
            -0.00618933979421854,
            -0.022433815523982048,
            0.0039020399563014507,
            -0.010214429348707199,
            -0.02825750596821308,
            0.0001803300838219002,
            0.014024978503584862,
            -0.022868284955620766,
            0.016721244901418686,
            -0.0424826517701149,
            0.002281100954860449,
            0.024574628099799156,
            -0.0007547139539383352,
            0.016064345836639404,
            0.026102185249328613,
            0.001479731174185872,
            -0.0009291231399402022,
            0.025789352133870125,
            -0.023706555366516113,
            -0.02809775248169899,
            0.0026316361036151648,
            -0.009986545890569687,
            0.027066245675086975,
            0.0054058171808719635,
            0.013079590164124966,
            -0.010780072771012783,
            -0.008920232765376568,
            -0.02146107330918312,
            -0.02098212018609047,
            -0.023689154535531998,
            0.017175201326608658,
            -0.002592727541923523,
            -0.006933147553354502,
            0.01632126048207283,
            0.02644995227456093,
            -0.01550255622714758,
            -0.015904664993286133,
            -0.0232754684984684,
            0.021636154502630234,
            0.004934709519147873,
            -0.026088805869221687,
            0.0008889901218935847,
            0.013769515790045261,
            -0.021250255405902863,
            0.03621170297265053,
            -0.018374662846326828,
            0.04088672250509262,
            0.01453512441366911,
            -0.03243469446897507,
            -0.005239394493401051,
            0.011464686132967472,
            0.007036016788333654,
            -0.025395657867193222,
            0.01572408154606819,
            0.006251025944948196,
            0.018266862258315086,
            0.015519488602876663,
            -0.004697324242442846,
            0.02248256839811802,
            0.010134398937225342,
            -0.0034334619995206594,
            0.028257468715310097,
            0.012334868311882019,
            0.012948554940521717,
            0.017991898581385612,
            0.01796135865151882,
            0.027554910629987717,
            -0.0029020116198807955,
            0.014327453449368477,
            0.02074163220822811,
            0.012558216229081154,
            -0.013462909497320652,
            -0.029532335698604584,
            -0.014509112574160099,
            0.0014650232624262571,
            0.012775923125445843,
            -0.009726506657898426,
            0.008335139602422714,
            -0.03404760733246803,
            0.03359563276171684,
            0.014215504750609398,
            0.0014389116549864411,
            0.0034491249825805426,
            -0.0014950218610465527,
            0.023925043642520905,
            0.031178252771496773,
            0.015764793381094933,
            0.0046279351226985455,
            -0.02893901988863945,
            0.026743240654468536,
            0.011913198046386242,
            0.018876321613788605,
            -0.015052171424031258,
            0.022912926971912384,
            -0.026721199974417686,
            0.019548851996660233,
            -0.006310647819191217,
            0.028069671243429184,
            -0.006611923687160015,
            -0.02583911456167698,
            -0.009805509820580482,
            0.026788342744112015,
            -0.020132988691329956,
            -0.005135358776897192,
            -0.003512463066726923,
            -0.023716891184449196,
            -0.01647369936108589,
            0.021782658994197845,
            0.03582668676972389,
            -0.00921857263892889,
            0.0312343779951334,
            -0.050992537289857864,
            -0.001443090382963419,
            -0.0008602747111581266,
            0.03901392221450806,
            -0.02792549505829811,
            0.00848905649036169,
            -0.011251429095864296,
            0.009152023121714592,
            0.03166139870882034,
            -0.010899454355239868,
            -0.022269736975431442,
            -0.008375462144613266,
            -0.02530788443982601,
            -0.01580062136054039,
            -0.012838487513363361,
            -0.004468078725039959,
            0.02765139937400818,
            -0.011228213086724281,
            -0.010111819952726364,
            -0.018903180956840515,
            -0.0038536915089935064,
            -0.010533095337450504,
            -0.012613913044333458,
            -0.016189927235245705,
            -0.04263778030872345,
            0.003957921173423529,
            -0.01581871137022972,
            -0.018133699893951416,
            0.00391160324215889,
            -0.028865253552794456,
            0.010155044496059418,
            -0.008362176828086376,
            0.015881692990660667,
            0.0028229686431586742,
            0.007140494883060455,
            -0.021702880039811134,
            -0.009037423878908157,
            0.0025008118245750666,
            0.019791869446635246,
            0.005702476017177105,
            -0.010752980597317219,
            0.01909775286912918,
            -0.02819330245256424,
            0.013026737608015537,
            -0.010168198496103287,
            -0.04452547803521156,
            -0.02161545120179653,
            -0.004068184643983841,
            -0.002452359301969409,
            -0.012537606991827488,
            0.008181167766451836,
            0.005911488551646471,
            -0.0005548858316615224,
            -0.02395699918270111,
            0.0010652836645022035,
            0.023697249591350555,
            -0.019199084490537643,
            0.009667576290667057,
            -0.013565854169428349,
            0.023274574428796768,
            -0.011502834036946297,
            0.009842971339821815,
            0.02386690489947796,
            -0.004284288268536329,
            -0.03127393126487732,
            0.02667958103120327,
            -0.022717565298080444,
            -0.018938569352030754,
            -0.026178643107414246,
            0.004985438194125891,
            0.00515854312106967,
            -0.02368834801018238,
            -0.028679953888058662,
            -0.015171393752098083,
            -0.04165628179907799,
            0.026213331148028374,
            -0.00490401778370142,
            -0.039402466267347336,
            0.026074804365634918,
            -0.04281024634838104,
            -0.01192560512572527,
            0.0318065881729126,
            -0.022122668102383614,
            -0.01859675534069538,
            -0.06414894759654999,
            -0.016399376094341278,
            0.018261218443512917,
            -0.02184087224304676,
            -0.0096597820520401,
            0.017357636243104935,
            0.01783435419201851,
            0.007209709845483303,
            0.01229726243764162,
            0.0035609486512839794,
            -0.0021121001336723566,
            0.026131130754947662,
            0.013060095719993114,
            -0.0037868197541683912,
            -0.027836011722683907,
            0.010401111096143723,
            -0.011406718753278255,
            0.0010057566687464714,
            0.020298641175031662,
            -0.00398932583630085,
            0.01379387453198433,
            -0.016605699434876442,
            -0.006662748754024506,
            -0.000912883086130023,
            0.023704683408141136,
            -0.03442750126123428,
            -0.01779165305197239,
            -0.02478383295238018,
            0.025696737691760063,
            -0.01583831012248993,
            0.016855750232934952,
            0.03135951608419418,
            0.020511610433459282,
            0.009549925103783607,
            0.004095927812159061,
            -0.014151589013636112,
            -0.010161064565181732,
            -0.024156924337148666,
            0.01745174638926983,
            0.02716074325144291,
            -0.006740637589246035,
            -0.015064185485243797,
            0.002310339827090502,
            -0.05031929165124893,
            -0.017264313995838165,
            0.015413944609463215,
            0.0118413046002388,
            -0.02095024660229683,
            0.022289199754595757,
            0.01960395835340023,
            0.006362865213304758,
            -0.023392008617520332,
            -0.013895634561777115,
            0.012082702480256557,
            -0.0013076422037556767,
            -0.003389054909348488,
            0.011083594523370266,
            -0.020763691514730453,
            0.006601228844374418,
            -0.019721288233995438,
            0.035045523196458817,
            -0.02500036545097828,
            -0.025000983849167824,
            -0.00804269127547741,
            -0.003170838812366128,
            -0.04053568094968796,
            0.013350794091820717,
            0.03690119460225105,
            0.014167899265885353,
            0.013256887905299664,
            0.012797100469470024,
            -0.00524749793112278,
            -0.007998599670827389,
            -0.03647336736321449,
            0.016521712765097618,
            -0.0022022505290806293,
            -0.004593679215759039,
            -0.0012557579902932048,
            0.010745512321591377,
            0.023006422445178032,
            0.020321926102042198,
            0.008411173708736897,
            0.03614499047398567,
            -0.038073331117630005,
            -0.013533014804124832,
            0.031068576499819756,
            -0.03175080567598343,
            0.01175521407276392,
            -0.021338222548365593,
            0.01459561102092266,
            -0.0008560757851228118,
            0.026090016588568687,
            0.03991670534014702,
            -0.037777893245220184,
            -0.01716373860836029,
            0.04634790122509003,
            0.00958352442830801,
            -0.0010144610423594713,
            0.01764126494526863,
            -0.0017208027420565486,
            0.003224319312721491,
            0.03117971494793892,
            -0.017582282423973083,
            -0.022454023361206055,
            0.004811405204236507,
            0.02659551426768303,
            -0.02360391989350319,
            0.02940952219069004,
            -0.029844673350453377,
            0.009965159930288792,
            -0.027644533663988113,
            -0.004950612783432007,
            0.0023022787645459175,
            0.019184045493602753,
            0.0034313879441469908,
            0.005801103077828884,
            0.018586402758955956,
            0.02697978913784027,
            -0.025988372042775154,
            -0.03371594473719597,
            0.020816810429096222,
            0.027931535616517067,
            0.026289209723472595,
            -0.0002666896325536072,
            -0.02120872028172016,
            -0.015043441206216812,
            0.011290313675999641,
            0.01641125977039337,
            -0.015130904503166676,
            -0.00043004992767237127,
            -0.023686496540904045,
            -0.025390390306711197,
            -0.0009031707886606455,
            0.0048622689209878445,
            0.003955403808504343,
            0.009096374735236168,
            0.027164435014128685,
            -0.0030964072793722153,
            0.039244405925273895,
            0.0002009942108998075,
            0.012215869501233101,
            -0.016996847465634346,
            -0.0005831926828250289,
            -0.012631693854928017,
            0.029602764174342155,
            0.018913840875029564,
            -0.027645248919725418,
            -0.007712102960795164,
            0.025950733572244644,
            0.003067009150981903,
            0.025577686727046967,
            0.018427183851599693,
            -0.005467555485665798,
            0.016816578805446625,
            -0.005591743625700474,
            -0.013154463842511177,
            0.02955906093120575,
            -0.018491489812731743,
            -0.030820785090327263,
            0.024455105885863304,
            -0.023201653733849525,
            -0.0029872851446270943,
            0.019347665831446648,
            -0.007367424666881561,
            0.027877191081643105,
            -0.02413880079984665,
            -0.033243365585803986,
            0.01985490694642067,
            0.015591025352478027,
            0.030124951153993607,
            0.012011819519102573,
            0.022855760529637337,
            -0.019716693088412285,
            -0.03349717706441879,
            0.01171264611184597,
            0.0017025955021381378,
            -0.013222896493971348,
            -0.03601837903261185,
            0.009734599851071835,
            0.013492942787706852,
            -0.010232733562588692,
            -0.027452679350972176,
            0.00013637926895171404,
            -0.014580470509827137,
            -0.008322224952280521,
            0.025262227281928062,
            0.008141089230775833,
            0.0038931844756007195,
            -0.012802806682884693,
            -0.015106350183486938,
            -0.02030407451093197,
            0.025052307173609734,
            -0.042352139949798584,
            -0.03815701976418495,
            0.01376352459192276,
            0.02238405868411064,
            -0.01735827885568142,
            -0.046119529753923416,
            -0.01756153628230095,
            0.0026176953688263893,
            0.021172834560275078,
            0.006664926186203957,
            -0.0032631950452923775,
            -0.02143983729183674,
            -0.01930651254951954,
            0.011251363903284073,
            0.021931424736976624,
            -0.08472397178411484,
            0.03133600577712059,
            0.02478710375726223,
            0.006464676465839148,
            -0.022549817338585854,
            0.0020752528216689825,
            0.0444369800388813,
            -0.03819795697927475,
            0.0006446329643949866,
            0.025339987128973007,
            -0.04003974422812462,
            0.00021709350403398275,
            0.006522631738334894,
            0.02360006421804428,
            0.02760799415409565,
            -0.005153134930878878,
            0.004964366555213928,
            -0.026110831648111343,
            0.003931997809559107,
            -0.016734851524233818,
            -0.023394055664539337,
            0.023742778226733208,
            0.03810684382915497,
            -0.006833378691226244,
            0.024738192558288574,
            -0.02686030976474285,
            -0.013186237774789333,
            0.01119175087660551,
            -0.029093829914927483,
            0.028194233775138855,
            0.03283246234059334,
            0.02172142267227173,
            0.009497672319412231,
            0.02458791434764862,
            0.02850782871246338,
            0.024711953476071358,
            0.03442984074354172,
            -0.07665075361728668,
            0.023097027093172073,
            0.018168313428759575,
            -0.018632786348462105,
            0.013107286766171455,
            0.01135426014661789,
            -0.023995554074645042,
            0.01716342568397522,
            0.01887074112892151,
            -0.04348933696746826,
            -0.025569386780261993,
            0.02832084894180298,
            0.01741444133222103,
            -0.0019420210737735033,
            -0.01588040217757225,
            0.004789798054844141,
            0.0030354077462106943,
            0.02307739108800888,
            -0.010585140436887741,
            0.008263207040727139,
            0.00794274639338255,
            -0.0003293630143161863,
            0.016796531155705452,
            -0.02495206892490387,
            0.003063032403588295,
            0.0032000886276364326,
            0.04402643442153931,
            0.02588263712823391,
            -0.03277195245027542,
            -0.028592344373464584,
            0.029853826388716698,
            -0.02629302442073822,
            0.013065696693956852,
            -0.017348695546388626,
            -0.03250839561223984,
            -0.012970083393156528,
            0.026593029499053955,
            0.004883476532995701,
            0.005215357523411512,
            0.001392560894601047,
            -0.001220292062498629,
            -0.025797680020332336,
            -0.027901597321033478,
            -0.022353431209921837,
            -0.01512325368821621,
            0.0012653429294005036,
            -0.01058657094836235,
            0.010769638232886791,
            0.009685702621936798,
            0.02068680338561535,
            0.006386120803654194,
            -0.012725879438221455,
            0.023830799385905266,
            -0.0023103796411305666,
            0.016573600471019745,
            -0.027934808284044266,
            -0.021692795678973198,
            -0.010240738280117512,
            0.018745526671409607,
            -0.0016468137037009,
            0.006659906357526779,
            0.016829704865813255,
            -0.015220293775200844,
            -0.012931477278470993,
            -0.038556575775146484,
            -0.011004632338881493,
            0.029070081189274788,
            -0.015484550036489964,
            0.021662777289748192,
            0.028224904090166092,
            -0.014160756021738052,
            -0.02810167707502842,
            0.02983441762626171,
            -0.014799773693084717,
            -0.04246864095330238,
            0.0024825248401612043,
            -0.02956285886466503,
            -0.016060693189501762,
            0.018570974469184875,
            0.022443149238824844,
            -0.034558288753032684,
            0.03146903216838837,
            -0.010219141840934753,
            0.009836540557444096,
            0.029797665774822235,
            0.02682632766664028,
            -0.0191508699208498,
            0.037293966859579086,
            -0.02655908092856407,
            0.023470446467399597,
            -0.05077703669667244,
            -0.03541918098926544,
            0.03483302518725395,
            -0.0077892206609249115,
            -0.010609949007630348,
            -0.0006943864282220602,
            0.01852066069841385,
            -0.01435451116412878,
            -0.011951019987463951,
            0.019319789484143257
        ],
        [
            -0.0072319768369197845,
            -0.009996003471314907,
            -0.03345320001244545,
            0.034248240292072296,
            -0.02116367407143116,
            0.018155990168452263,
            -0.002170636784285307,
            0.00947177316993475,
            -0.008222741074860096,
            0.013108039274811745,
            -0.002984212012961507,
            0.006747433450073004,
            0.040214087814092636,
            0.011648626066744328,
            0.011244863271713257,
            0.01471620798110962,
            0.003057977417483926,
            0.01867462508380413,
            -0.006319410167634487,
            -0.002548154443502426,
            -0.019966494292020798,
            -0.0017857881030067801,
            -0.01107325591146946,
            0.031213190406560898,
            -0.0031956054735928774,
            -0.015581031329929829,
            0.003231795271858573,
            0.00244259275496006,
            0.031237240880727768,
            0.03582565858960152,
            -0.036659982055425644,
            0.022545553743839264,
            0.01219184324145317,
            0.028508003801107407,
            -0.018828490749001503,
            0.013057577423751354,
            -0.036374907940626144,
            -0.0023449368309229612,
            0.008585287258028984,
            0.015666212886571884,
            -0.02018541842699051,
            0.041350461542606354,
            0.008885599672794342,
            -0.04030601680278778,
            -0.020343752577900887,
            0.018347060307860374,
            0.00865502655506134,
            -0.006970542017370462,
            0.010445870459079742,
            0.017673876136541367,
            0.008181081153452396,
            0.025066889822483063,
            0.056417860090732574,
            0.010495360009372234,
            -0.005273852963000536,
            0.016496185213327408,
            -0.016475902870297432,
            0.027159681543707848,
            0.024236442521214485,
            0.0032585342414677143,
            -0.014975404366850853,
            -0.02311655506491661,
            -0.02214004471898079,
            0.008166322484612465,
            -0.014312139712274075,
            0.004479248076677322,
            0.012723957188427448,
            0.01201811246573925,
            0.0007324375910684466,
            0.027629729360342026,
            0.003899212460964918,
            0.014325314201414585,
            -0.012719963677227497,
            0.01747002825140953,
            0.019977431744337082,
            0.05818324536085129,
            0.01734197698533535,
            -0.009813777171075344,
            0.0099348658695817,
            0.026934335008263588,
            0.00981923472136259,
            -0.021505510434508324,
            -0.015895936638116837,
            -0.009129715152084827,
            0.00731006870046258,
            -0.0382625088095665,
            -0.01558011956512928,
            0.018376559019088745,
            0.012995817698538303,
            0.040261056274175644,
            0.022868923842906952,
            -0.010743089951574802,
            0.024287166073918343,
            -0.0060021597892045975,
            0.021848279982805252,
            -0.015473535284399986,
            0.025450700893998146,
            0.04288557171821594,
            0.01283180434256792,
            0.029713349416851997,
            -0.04514968767762184,
            -0.027902690693736076,
            -0.0058005191385746,
            -0.025937940925359726,
            -0.01176076103001833,
            -0.007775112055242062,
            -0.0270928293466568,
            -0.03241734951734543,
            0.007035081274807453,
            0.007248198613524437,
            0.023036418482661247,
            0.03392867371439934,
            0.01486265566200018,
            -0.02794414944946766,
            -0.028901048004627228,
            -0.028575478121638298,
            -0.024145543575286865,
            -0.01612689346075058,
            0.019838808104395866,
            -0.010029943659901619,
            -0.011342950165271759,
            0.015601253136992455,
            -0.02149592898786068,
            0.02431468665599823,
            0.024257468059659004,
            0.011635350063443184,
            -0.03769521415233612,
            -0.021863998845219612,
            -0.024920696392655373,
            -0.019308166578412056,
            -0.007137899287045002,
            0.0075655365362763405,
            -0.02576659619808197,
            -0.012160314247012138,
            -0.013791177421808243,
            -0.005567887797951698,
            -0.024544408544898033,
            -0.05051175877451897,
            -0.013471921905875206,
            0.0034317481331527233,
            -0.011122230440378189,
            -0.00953799020498991,
            0.017843617126345634,
            -0.0003957976878155023,
            -0.031652532517910004,
            -0.022668099030852318,
            0.00013354397378861904,
            -0.03350599855184555,
            -0.027637608349323273,
            -0.022171199321746826,
            -0.013174355030059814,
            -0.01753959432244301,
            0.03151964396238327,
            0.009992370381951332,
            -0.013785130344331264,
            0.04092896729707718,
            0.011605445295572281,
            0.004174628295004368,
            0.04106783866882324,
            -0.004058577120304108,
            -0.021041978150606155,
            0.00564600108191371,
            0.02051156386733055,
            0.027643293142318726,
            -0.006856332533061504,
            -0.05546790733933449,
            -0.01389540545642376,
            0.025034692138433456,
            0.03455321863293648,
            0.024801507592201233,
            -0.018833303824067116,
            0.00808567926287651,
            0.010689293965697289,
            0.010588581673800945,
            -0.01991455629467964,
            -0.0014585655881091952,
            -0.024892881512641907,
            -0.016052143648266792,
            -0.012565616518259048,
            -0.0025393918622285128,
            -0.006657641381025314,
            0.027511728927493095,
            0.00484186178073287,
            0.012605929747223854,
            -2.3081207473296672e-05,
            0.01864752545952797,
            -0.010553877800703049,
            0.004699797835201025,
            0.04722879081964493,
            0.027266450226306915,
            0.006423255428671837,
            -0.02345171943306923,
            0.04209881275892258,
            0.012444515712559223,
            0.02860988676548004,
            -0.0032616278622299433,
            -0.01730388030409813,
            -0.014164833351969719,
            -0.010295665822923183,
            0.00676775211468339,
            -0.0267805028706789,
            0.016454655677080154,
            0.00947701744735241,
            0.0023909038864076138,
            -0.000868197821546346,
            0.018757756799459457,
            0.010194974020123482,
            -0.019198473542928696,
            -0.02749578095972538,
            0.0027269613929092884,
            0.023372633382678032,
            0.019695671275258064,
            0.016450902447104454,
            -0.008569684810936451,
            -0.03313424065709114,
            0.020036809146404266,
            -0.00432558124884963,
            0.008382245898246765,
            0.011805239133536816,
            0.013743582181632519,
            -0.023363903164863586,
            -0.007140836678445339,
            0.03699784725904465,
            -0.0035188542678952217,
            -0.013198603875935078,
            0.012561538256704807,
            0.01069406047463417,
            0.018764300271868706,
            0.013124911114573479,
            0.014222322031855583,
            -0.00936185009777546,
            0.03217652067542076,
            0.0039048288017511368,
            -0.017686007544398308,
            -0.03386916592717171,
            0.004791648592799902,
            -0.009143132716417313,
            -0.0019118317868560553,
            -0.017490079626441002,
            -0.013449162244796753,
            0.012158417142927647,
            -0.006829836871474981,
            0.0132072689011693,
            0.01770668290555477,
            -0.00384257803671062,
            -0.021884744986891747,
            0.029852312058210373,
            -0.019425667822360992,
            -0.007421818561851978,
            -0.003010836895555258,
            0.008957835845649242,
            -0.01863216608762741,
            -0.010426143184304237,
            -0.007963982410728931,
            0.00020593883527908474,
            -0.012032324448227882,
            0.02192658931016922,
            0.030564207583665848,
            0.010974773205816746,
            0.008941459469497204,
            0.02792571485042572,
            0.0007319367141462862,
            0.02707774005830288,
            0.03407726809382439,
            0.021626191213726997,
            0.0175324734300375,
            -0.02005445957183838,
            -0.004938866943120956,
            -0.038464512676000595,
            0.012019587680697441,
            0.03585154935717583,
            0.0011206424096599221,
            0.017669085413217545,
            0.021439196541905403,
            0.014468425884842873,
            -0.0067650144919753075,
            0.011707944795489311,
            -0.029799504205584526,
            -0.032000597566366196,
            0.0043319580145180225,
            0.03062383271753788,
            -0.0224150437861681,
            0.023983094841241837,
            -0.028633417561650276,
            0.003197070909664035,
            0.011831842362880707,
            -0.014273297041654587,
            0.004519342910498381,
            0.012380991131067276,
            0.03362921625375748,
            -0.012427667155861855,
            0.0057370844297111034,
            0.038005530834198,
            -0.014224063605070114,
            -0.017401356250047684,
            -0.03802631050348282,
            0.0022243589628487825,
            0.00801459513604641,
            0.06098280847072601,
            -0.003194691613316536,
            0.02111887000501156,
            0.017379065975546837,
            -0.026316488161683083,
            0.03675125539302826,
            0.0379687137901783,
            0.01975485496222973,
            -0.015964210033416748,
            0.012472615577280521,
            0.017906662076711655,
            0.025366373360157013,
            0.0006237429333850741,
            0.011959466151893139,
            -0.028467988595366478,
            -0.016526900231838226,
            -0.0037709951866418123,
            0.03624426946043968,
            0.007937918417155743,
            -1.8326710460314644e-07,
            -0.026562057435512543,
            -0.027313869446516037,
            0.0066259014420211315,
            0.013409355655312538,
            0.005571510177105665,
            0.021042121574282646,
            0.023529240861535072,
            -0.008651651442050934,
            0.007731243036687374,
            0.031441058963537216,
            0.03616107627749443,
            -0.001221846672706306,
            -0.05646597221493721,
            0.02849624678492546,
            -0.03160865977406502,
            0.02869892306625843,
            0.007512832526117563,
            -0.005059721879661083,
            -0.017317093908786774,
            -0.005169807467609644,
            -0.0074853599071502686,
            0.028079325333237648,
            -0.013769986107945442,
            -0.003420519642531872,
            -0.019090887159109116,
            0.003640977665781975,
            0.02218419685959816,
            -0.012114950455725193,
            0.004507964011281729,
            0.011934800073504448,
            0.002884023357182741,
            0.005307952873408794,
            0.021292351186275482,
            -0.011890278197824955,
            0.015299475751817226,
            0.006654429715126753,
            -0.014355391263961792,
            -0.01950620673596859,
            -0.006404270883649588,
            0.02377224527299404,
            0.022388365119695663,
            -0.0007433780119754374,
            -0.002900897292420268,
            0.02356327325105667,
            -0.027555720880627632,
            -0.011543388478457928,
            0.01655970886349678,
            0.02557131089270115,
            0.03371639549732208,
            0.024427669122815132,
            0.008699066936969757,
            -0.013095508329570293,
            -0.007311954163014889,
            -0.018658427521586418,
            0.02710404247045517,
            0.03905388340353966,
            0.0014674901030957699,
            0.03191520273685455,
            0.00974226277321577,
            0.016503017395734787,
            -0.03628261387348175,
            0.006401157472282648,
            0.0385296493768692,
            0.014228935353457928,
            0.030704021453857422,
            0.015316057950258255,
            0.002734823152422905,
            0.02648186683654785,
            -0.02592288888990879,
            0.01676884852349758,
            0.03096029907464981,
            -0.011624581180512905,
            -0.002992097055539489,
            0.0037359038833528757,
            -0.0018284327816218138,
            1.1951035048696212e-05,
            -0.06093432754278183,
            -0.0021399406250566244,
            0.020176542922854424,
            -0.0027673463337123394,
            0.03229425102472305,
            -0.012757910415530205,
            -0.00573133397847414,
            -0.02881169691681862,
            -0.007616095244884491,
            0.008136327378451824,
            -0.033539336174726486,
            -0.017561882734298706,
            -0.03125906363129616,
            0.028322555124759674,
            -0.030058693140745163,
            0.0029425830580294132,
            -0.033201005309820175,
            0.004074688535183668,
            0.012372159399092197,
            -0.006561469752341509,
            0.017226632684469223,
            -0.028261661529541016,
            -0.018543917685747147,
            0.005270200781524181,
            -0.010271766223013401,
            -0.03134064003825188,
            -0.026653699576854706,
            0.026579033583402634,
            0.04785609245300293,
            -0.030325017869472504,
            -0.0014463432598859072,
            -0.015324986539781094,
            -0.01326344907283783,
            0.0385223887860775,
            0.015012575313448906,
            0.041382595896720886,
            0.004061922896653414,
            -0.02427351288497448,
            0.008808709681034088,
            0.038705337792634964,
            -0.020582634955644608,
            -0.01828218437731266,
            0.023096049204468727,
            -0.011107521131634712,
            0.038297541439533234,
            -0.002429635962471366,
            -0.0135072972625494,
            0.02896520309150219,
            0.003976810723543167,
            -0.007656147237867117,
            0.008909820578992367,
            0.009448481723666191,
            0.02643629163503647,
            0.0372561551630497,
            -0.013848301954567432,
            0.006388888694345951,
            0.013445397838950157,
            0.009331121109426022,
            0.012249023653566837,
            0.007524996995925903,
            0.016452118754386902,
            0.015090873464941978,
            -0.0035773287527263165,
            0.03291948884725571,
            -0.0033493281807750463,
            0.024121342226862907,
            0.005520076956599951,
            0.030369101092219353,
            -0.002081654267385602,
            0.01838643290102482,
            0.013210364617407322,
            -0.030329197645187378,
            0.0069147697649896145,
            -0.0241695623844862,
            0.01535130850970745,
            -0.01108449138700962,
            -0.03616161644458771,
            -0.023783311247825623,
            -0.016613991931080818,
            -0.04140915349125862,
            -0.0014039624948054552,
            -0.018349766731262207,
            -0.011922516860067844,
            -0.01857655867934227,
            -0.0039007733575999737,
            -0.010454930365085602,
            -0.016905127093195915,
            -0.01664765365421772,
            -0.022896166890859604,
            0.01113828457891941,
            -0.022430814802646637,
            -0.01903783716261387,
            -0.004240728914737701,
            -0.005696424283087254,
            0.023462587967514992,
            0.026300402358174324,
            0.02555937133729458,
            0.01472813542932272,
            0.013532978482544422,
            0.04956081137061119,
            0.013705691322684288,
            -0.012286629527807236,
            -0.006467190571129322,
            -0.027892228215932846,
            -0.031203096732497215,
            0.013091797940433025,
            0.004864169750362635,
            -0.017977222800254822,
            -0.002824993571266532,
            -0.0008594915852881968,
            -0.011671745218336582,
            0.02922712080180645,
            -0.004272741265594959,
            -0.009068023413419724,
            0.0384254977107048,
            0.0013027092209085822,
            0.009704911150038242,
            -0.03767270967364311,
            0.009200649335980415,
            0.005901465658098459,
            0.022524278610944748,
            -0.02031814493238926,
            -0.015447388403117657,
            0.025930874049663544,
            -0.01721099577844143,
            0.02727951854467392,
            0.02455963008105755,
            0.011118470691144466,
            -0.0023819259367883205,
            0.02729949541389942,
            -0.008609647862613201,
            -0.01587553881108761,
            0.024332180619239807,
            -0.019115861505270004,
            0.033100079745054245,
            0.009286224842071533,
            -0.008895574137568474,
            -0.029795335605740547,
            -0.02874024771153927,
            0.002746846992522478,
            0.018727095797657967,
            0.026756180450320244,
            0.005078508052974939,
            0.015637939795851707,
            0.0034598044585436583,
            -0.004443716257810593,
            0.007815179415047169,
            0.02404700219631195,
            0.008072801865637302,
            -1.745222448334971e-06,
            -0.00017882537213154137,
            0.01732586696743965,
            -0.00989685021340847,
            0.009115533903241158,
            -0.006182219833135605,
            -0.015684159472584724,
            0.002242479007691145,
            -0.024096939712762833,
            -0.0011748425895348191,
            -0.0007133481558412313,
            0.03499520942568779,
            -0.009575256146490574,
            0.0452730767428875,
            -0.023357661440968513,
            0.021940581500530243,
            0.0052187261171638966,
            0.008374620229005814,
            0.004559589549899101,
            0.007947301492094994,
            -0.018917644396424294,
            0.006835303734987974,
            -0.0012073158286511898,
            0.013225695118308067,
            -0.012374382466077805,
            0.005620372481644154,
            0.012877500616014004,
            -0.03126122057437897,
            0.014226211234927177,
            0.028005944564938545,
            0.003004498779773712,
            0.0006239766371436417,
            0.04156853258609772,
            -0.01406950131058693,
            0.04121320694684982,
            -0.049341440200805664,
            0.021981611847877502,
            0.026529867202043533,
            0.019320005550980568,
            0.01309511810541153,
            -0.002888686489313841,
            -0.02489933930337429,
            0.014953850768506527,
            -0.034458015114068985,
            0.011163871735334396,
            -0.03747620806097984,
            -0.005944262258708477,
            -0.009462341666221619,
            0.037764035165309906,
            -0.007734541781246662,
            -0.0356769859790802,
            -0.011282780207693577,
            -0.006869968492537737,
            0.01293873693794012,
            0.004309562034904957,
            0.022178271785378456,
            -0.005789367947727442,
            -0.034631870687007904,
            -0.01719982922077179,
            0.02899824269115925,
            0.03695390373468399,
            0.006546196062117815,
            0.00050959805957973,
            0.027546055614948273,
            -0.003106849268078804,
            0.013387802988290787,
            -0.0047172680497169495,
            0.021398412063717842,
            0.009909004904329777,
            0.014099357649683952,
            -0.0077085052616894245,
            -0.025116873905062675,
            -0.0064625209197402,
            0.0026901874225586653,
            0.03391323983669281,
            -0.004735385533422232,
            -0.019081909209489822,
            0.03973643109202385,
            -0.006961625535041094,
            -0.024939119815826416,
            -0.007608560845255852,
            0.018989887088537216,
            -0.018677758052945137,
            0.025197148323059082,
            0.02792149782180786,
            -0.006673257332295179,
            0.021038012579083443,
            -0.03225924074649811,
            0.00540705444291234,
            0.01832715980708599,
            -0.011928072199225426,
            -0.02164357900619507,
            0.03604964166879654,
            0.003044519806280732,
            0.014763564802706242,
            0.01551069412380457,
            0.02594061940908432,
            -0.022950822487473488,
            -0.01315966248512268,
            -0.044324178248643875,
            0.028501534834504128,
            -0.0147686917334795,
            -0.023621132597327232,
            0.020215872675180435,
            -0.0033507447224110365,
            -0.02729538455605507,
            0.006825732067227364,
            0.0027990604285150766,
            0.016037464141845703,
            -0.02510315552353859,
            0.02360406704246998,
            -0.009567595086991787,
            -0.02971450425684452,
            0.016535460948944092,
            -0.02844996564090252,
            -0.03626154735684395,
            0.02493765763938427,
            -0.01677839085459709,
            -0.006421147380024195,
            0.02573375590145588,
            0.004147340543568134,
            0.021303709596395493,
            0.015133226290345192,
            0.006717902608215809,
            -0.02781836874783039,
            0.020749403163790703,
            -0.010037877596914768,
            0.028673069551587105,
            -0.07484825700521469,
            -0.003165232250466943,
            0.03959597274661064,
            0.0033110480289906263,
            0.041882820427417755,
            -0.00881166011095047,
            0.01958974078297615,
            -0.021480277180671692,
            -0.004425523336976767,
            -0.03615397959947586,
            -0.0009551726980134845,
            0.012021399103105068,
            0.015492540784180164,
            0.009210026822984219,
            0.022600876167416573,
            0.04095124080777168,
            -0.004136380739510059,
            0.016059527173638344,
            0.040550749748945236,
            0.013680689968168736,
            0.0006089223315939307,
            0.0069338190369307995,
            -0.00634709931910038,
            -0.021164776757359505,
            0.005255443509668112,
            0.001374147366732359,
            0.014763456769287586,
            0.005873479414731264,
            0.02311992272734642,
            0.014920174144208431,
            0.04415563866496086,
            -0.017487715929746628,
            0.021484769880771637,
            -0.0267223808914423,
            0.020973043516278267,
            -0.028225617483258247,
            -0.00045287804096005857,
            -0.011205410584807396,
            -0.01529480330646038,
            -0.003991800360381603,
            -0.007201532367616892,
            0.0022245030850172043,
            0.026745645329356194,
            -0.022608688101172447,
            -0.011060583405196667,
            -0.030142715200781822,
            0.024126438423991203,
            -0.022392917424440384,
            -0.032720524817705154,
            -0.019076291471719742,
            0.007540551479905844,
            0.019760088995099068,
            0.002226300537586212,
            0.024383213371038437,
            0.014585890807211399,
            0.006494823843240738,
            0.022863104939460754,
            0.011577531695365906,
            -0.028294838964939117,
            0.020287305116653442,
            -0.03501233085989952,
            0.01715908572077751,
            -0.01010595541447401,
            0.01617659069597721,
            0.014579310081899166,
            -0.006217981688678265,
            0.005635798908770084,
            0.0545387864112854,
            -0.0005318205803632736,
            -0.01187122892588377,
            0.020723357796669006,
            -0.018648911267518997,
            0.004188205115497112,
            0.008592721074819565,
            -0.0023080753162503242,
            0.007473791018128395,
            -0.02465103007853031,
            -0.0013842307962477207,
            0.028644250705838203,
            0.010668803006410599,
            -0.01665864884853363,
            -0.0027508167549967766,
            -0.016631966456770897,
            -0.026944121345877647,
            0.0018228424014523625,
            0.024735532701015472,
            -0.04117508977651596,
            0.025899900123476982,
            -0.004423872102051973,
            -0.0030092166271060705,
            -0.007921118289232254,
            0.022076215595006943,
            0.026301374658942223,
            0.020252104848623276,
            0.00473808916285634,
            0.026836693286895752,
            -0.027220593765378,
            0.007426781579852104,
            -0.025006255134940147,
            -0.011413324624300003,
            -0.019172895699739456,
            -0.02510097250342369,
            0.04565611481666565,
            -0.020471425727009773,
            0.026782220229506493,
            0.02447134628891945,
            0.011363663710653782,
            -0.015065984800457954,
            -0.03146117553114891,
            0.037823986262083054,
            -0.0317474864423275,
            0.012486235238611698,
            -0.014184708707034588,
            -0.0005243608029559255,
            -0.028150413185358047,
            0.037032630294561386,
            -0.019210360944271088,
            -0.006003198213875294,
            -0.006484632845968008,
            0.013069727458059788,
            -0.018447227776050568,
            0.024517936632037163,
            0.009286315180361271,
            0.034295227378606796,
            6.634645978920162e-05,
            -0.013048349879682064,
            0.015901723876595497,
            -0.019574275240302086,
            0.023060930892825127,
            -0.003908401355147362,
            0.010468677617609501,
            0.01826285384595394,
            0.06111351028084755,
            0.025586411356925964,
            0.017032906413078308,
            -0.03584019094705582,
            -0.03166789934039116,
            0.012783359736204147,
            0.02159280702471733,
            -0.02461394853889942,
            0.015378531999886036,
            -0.035318128764629364,
            -0.013661555014550686,
            0.0468176044523716,
            -0.006318480242043734,
            0.03008572943508625,
            0.007330285385251045,
            0.009283379651606083,
            0.027878716588020325,
            -0.03798636049032211,
            -0.032807983458042145,
            0.0003052325628232211,
            0.035032968968153,
            0.028914369642734528,
            -0.010349607095122337,
            -0.0064857760444283485,
            0.026716791093349457,
            -0.025263240560889244,
            0.011934502050280571,
            0.011074824258685112,
            -0.029155870899558067,
            -0.0324828140437603,
            0.011134181171655655,
            -0.05065469443798065,
            0.016065696254372597,
            0.015364060178399086,
            -0.006098527926951647,
            -0.025413714349269867,
            -0.014501460827887058,
            0.012112701311707497,
            -0.00891832821071148,
            0.016216561198234558,
            0.02986196056008339,
            -0.004034033510833979,
            -0.02432060055434704,
            0.0009405704913660884,
            -0.0007701527792960405,
            -0.02925114333629608,
            0.027067741379141808,
            -0.009491647593677044,
            -0.021169276908040047,
            -0.01513095386326313,
            -0.005942665971815586,
            -0.013508948497474194,
            0.03422968089580536,
            -0.020812883973121643,
            -0.025451406836509705,
            -0.019814595580101013,
            -0.026823081076145172,
            0.004441834520548582,
            0.021480301395058632,
            0.021763501688838005,
            0.012910661287605762,
            0.019440343603491783,
            0.018934007734060287,
            0.01783079281449318,
            -0.017504464834928513,
            -0.0063617336563766,
            0.06467434763908386,
            -0.0198826864361763,
            0.0195670947432518,
            0.04367337003350258,
            0.012317627668380737,
            0.008056587539613247,
            0.029365547001361847,
            -0.010329358279705048,
            0.03969376161694527,
            -0.007901973091065884,
            -0.007686751428991556,
            -0.014115788042545319,
            0.0004548463912215084,
            0.048583127558231354,
            0.021582599729299545,
            0.004370420705527067,
            -0.0188665259629488,
            -0.02631770446896553,
            0.007110225968062878,
            0.006879918742924929,
            0.007593581452965736,
            0.01516898162662983,
            -0.016362493857741356,
            -0.002900324761867523,
            -0.007921826094388962,
            -0.007554779760539532,
            -0.0033676247112452984,
            0.01693044789135456,
            -0.02349286526441574,
            -0.03447682037949562,
            0.009781482629477978,
            -0.012326178140938282,
            0.008197166956961155,
            0.0020551870111376047,
            0.004534260835498571,
            -0.016838612034916878,
            -0.04266190901398659,
            0.015089839696884155,
            -0.018118802458047867,
            0.004421235993504524,
            -0.007513050921261311,
            -0.029080616310238838,
            -0.013421819545328617,
            0.007878157310187817,
            -0.03735635057091713,
            0.010650936514139175,
            -0.02447669580578804,
            0.005634012632071972,
            0.0019294751109555364,
            0.00020755907462444156,
            -0.039976075291633606,
            0.021223558112978935,
            -0.02423340454697609,
            0.04913535714149475,
            -0.02097475714981556,
            -0.05753457546234131,
            0.004806202836334705,
            0.007055938243865967,
            -0.039234090596437454,
            0.03481830656528473,
            0.031299933791160583,
            0.018535278737545013,
            0.006422417238354683,
            -0.014605567790567875,
            0.015840932726860046,
            -0.020924381911754608,
            -0.015926657244563103,
            0.025378210470080376,
            -0.0005948879406787455,
            -0.018867377191781998,
            0.014614838175475597,
            -0.010213147848844528,
            0.0153154032304883,
            0.0328105203807354,
            -0.0015757415676489472,
            0.05721893906593323,
            0.02572781592607498,
            0.013234738260507584,
            -0.01258228998631239,
            0.00926877185702324,
            0.016390593722462654,
            -0.050824932754039764,
            0.023819370195269585,
            -0.017725979909300804,
            -0.02443268522620201,
            -0.007213242817670107,
            -0.007955330424010754,
            0.024596573784947395,
            0.026514608412981033,
            0.015999650582671165,
            0.037579525262117386,
            0.004165994934737682,
            0.0018628600519150496,
            -0.009519852697849274,
            -0.006820289883762598,
            0.03718056157231331,
            0.023267259821295738,
            0.017306063324213028,
            -0.02686426043510437,
            0.05255861207842827,
            -0.039304181933403015,
            0.028036268427968025,
            0.007809043861925602,
            0.025430994108319283,
            -0.0033671834971755743,
            0.0159690510481596,
            0.006242312490940094,
            0.03595995530486107,
            0.010178365744650364,
            -0.017009198665618896,
            -0.008870561607182026,
            0.011337114498019218,
            0.0012296815402805805,
            0.00964063685387373,
            -0.03391965106129646,
            -0.010134337469935417,
            0.02914886735379696,
            -0.00837516039609909,
            0.0006847922340966761,
            -0.02697756513953209,
            0.012553363107144833,
            -0.008145860396325588,
            0.025845294818282127,
            0.032794952392578125,
            0.013634699396789074,
            -0.0038810092955827713,
            -0.003408873686566949,
            -0.04780774191021919,
            0.007406254764646292,
            -0.03948117420077324,
            0.017656726762652397,
            0.010110033676028252,
            0.015326940454542637,
            -0.023189451545476913,
            -0.00555089907720685,
            0.022200459614396095,
            -4.238102337694727e-05,
            0.03275376558303833,
            0.00027987416251562536,
            0.01850597746670246,
            -0.033217307180166245,
            0.013139810413122177,
            -0.03333369642496109,
            0.030741345137357712,
            -0.01856711506843567,
            -0.023757025599479675,
            0.03608100116252899,
            0.003634032793343067,
            -0.029138723388314247,
            0.02086515538394451,
            -0.01607178896665573,
            -0.013087019324302673,
            -0.024663519114255905,
            -0.024975117295980453,
            0.012735490687191486
        ],
        [
            0.026404546573758125,
            0.030780909582972527,
            0.02295946702361107,
            0.018286585807800293,
            0.02275177091360092,
            0.07356889545917511,
            0.02268994227051735,
            0.024537386372685432,
            0.006197506561875343,
            0.0025290974881500006,
            -0.005268670618534088,
            0.011737318709492683,
            0.01977088674902916,
            0.01689300872385502,
            0.07844949513673782,
            0.04247068241238594,
            -0.020496703684329987,
            -0.015791911631822586,
            0.002823809627443552,
            -0.026828257367014885,
            -0.027819866314530373,
            -0.02798544615507126,
            -0.014362253248691559,
            0.016806745901703835,
            0.000711574568413198,
            0.033684469759464264,
            0.05813441425561905,
            -0.024951746687293053,
            0.03042413480579853,
            -0.025816498324275017,
            0.012513916939496994,
            -0.06486967951059341,
            -0.02394663728773594,
            -0.03670290485024452,
            0.026092948392033577,
            -0.006929206661880016,
            0.033049024641513824,
            -0.03866784647107124,
            -0.05778772011399269,
            -0.03474700450897217,
            0.022160030901432037,
            -0.004398191813379526,
            -0.02185927890241146,
            -0.0063454098999500275,
            0.0016029085963964462,
            0.0020954443607479334,
            0.009491896256804466,
            -0.010471826419234276,
            0.0033119411673396826,
            -0.04076285660266876,
            0.022007005289196968,
            0.006413787603378296,
            -0.03349161520600319,
            -0.013174035586416721,
            -0.025628119707107544,
            0.009952452965080738,
            0.013969109393656254,
            0.00532145518809557,
            0.037537746131420135,
            0.018041661009192467,
            -0.07199925929307938,
            0.013111778534948826,
            0.024436630308628082,
            -0.028324438259005547,
            0.02851005829870701,
            0.014928837306797504,
            -0.004291886929422617,
            0.0014507215237244964,
            -0.03923053666949272,
            0.01793847419321537,
            0.006855749990791082,
            0.027577580884099007,
            0.01802763156592846,
            0.004583768546581268,
            0.022056661546230316,
            -0.04754624888300896,
            0.02056724578142166,
            0.005863958969712257,
            0.043377835303545,
            0.019695600494742393,
            0.0041473801247775555,
            0.007729635573923588,
            -0.03656930476427078,
            -0.0216726865619421,
            -0.046287499368190765,
            -0.018944337964057922,
            0.011682592332363129,
            -0.04401380941271782,
            -0.06711476296186447,
            0.0012746192514896393,
            0.015336277894675732,
            0.011994707398116589,
            0.0015957012074068189,
            -0.006875102408230305,
            -0.033665332943201065,
            -0.025775879621505737,
            0.016129089519381523,
            0.01543558668345213,
            -0.0035231902729719877,
            -0.02597992680966854,
            0.014551550149917603,
            0.0007197991362772882,
            -0.007408622652292252,
            -0.009782204404473305,
            -0.03854769468307495,
            -0.06432130932807922,
            -0.051201287657022476,
            0.004301097709685564,
            -0.023388981819152832,
            0.02854665368795395,
            -0.02504982054233551,
            0.011999071575701237,
            0.010728437453508377,
            -0.023295359686017036,
            -0.0029175938107073307,
            0.005966907367110252,
            0.029106665402650833,
            0.02144072763621807,
            0.039220817387104034,
            0.007025517523288727,
            0.008324360474944115,
            0.03215985372662544,
            -0.03171447291970253,
            0.015999896451830864,
            -0.04031910374760628,
            -0.0077758124098181725,
            0.05787767469882965,
            0.009451339021325111,
            -0.029218778014183044,
            0.017151786014437675,
            0.016308724880218506,
            -0.012404579669237137,
            0.0010375863639637828,
            -0.053932495415210724,
            0.01636139675974846,
            0.02140980027616024,
            0.014144818298518658,
            -0.009104468859732151,
            0.005151386372745037,
            0.04412325471639633,
            -0.0035288259387016296,
            0.0013252636417746544,
            -0.01578616164624691,
            -0.005783663596957922,
            -0.09719902276992798,
            -0.001955405343323946,
            -0.0063020335510373116,
            -0.03785240277647972,
            -0.02150711603462696,
            0.024758823215961456,
            -0.037951625883579254,
            0.004699116572737694,
            -0.009517376311123371,
            0.023649541661143303,
            0.0047262986190617085,
            -0.02871069684624672,
            -0.02723345346748829,
            -0.00144785491283983,
            0.01654120720922947,
            0.05136521905660629,
            0.019229458644986153,
            -0.018975889310240746,
            -0.033327579498291016,
            -0.010207700543105602,
            0.021845348179340363,
            -0.03023708611726761,
            0.016529016196727753,
            -0.03529166057705879,
            -0.015751609578728676,
            -0.04024282097816467,
            -0.04208642989397049,
            0.02805810607969761,
            0.006909979972988367,
            0.0598130002617836,
            0.013473717495799065,
            0.053392115980386734,
            0.04529951140284538,
            -0.00011027215077774599,
            -0.0026328270323574543,
            -0.0026642880402505398,
            0.026517078280448914,
            -0.02649809420108795,
            -0.02998902089893818,
            0.023918483406305313,
            0.031346384435892105,
            0.007755209691822529,
            0.027741804718971252,
            -0.0012608497636392713,
            -0.03400444611907005,
            0.0016299186972901225,
            0.01372684445232153,
            0.014134877361357212,
            -0.05593737214803696,
            -0.022940434515476227,
            0.010300172492861748,
            0.01707044243812561,
            0.022272685542702675,
            -0.015271433629095554,
            0.025644121691584587,
            0.015500001609325409,
            -0.011722715571522713,
            -0.015075945295393467,
            -0.01201418973505497,
            -0.02147561125457287,
            -0.05986213684082031,
            -0.02419041469693184,
            0.010054427199065685,
            -0.01684710942208767,
            -0.004250924102962017,
            0.004085392691195011,
            0.009175294078886509,
            0.0035234112292528152,
            0.049462929368019104,
            0.03482631966471672,
            -0.015952037647366524,
            0.01600908488035202,
            0.003226042725145817,
            -0.010056146420538425,
            -0.007332786917686462,
            -0.0017044356791302562,
            0.03169551119208336,
            -0.047026827931404114,
            -0.03018978051841259,
            -0.017533225938677788,
            -0.031241796910762787,
            0.06331206858158112,
            -0.007774444296956062,
            -0.020020313560962677,
            -0.008624550886452198,
            -0.03265633434057236,
            -0.049977999180555344,
            0.03713885322213173,
            0.001487115048803389,
            0.005096071865409613,
            -0.06965434551239014,
            0.019464660435914993,
            0.027780679985880852,
            -0.021279068663716316,
            0.015121902339160442,
            -0.01908877305686474,
            0.0019554421305656433,
            0.015362963080406189,
            0.03883946314454079,
            -0.010192562825977802,
            -0.034092068672180176,
            0.015608594752848148,
            -0.015721675008535385,
            -0.009258857928216457,
            -0.013448932208120823,
            0.014011495746672153,
            0.003993371967226267,
            0.008494778536260128,
            0.020082786679267883,
            -0.010074315592646599,
            0.011873706243932247,
            0.0018428745679557323,
            -0.0049116723239421844,
            -0.00319797289557755,
            -0.04594414681196213,
            -0.009548335336148739,
            0.044507041573524475,
            0.04531363397836685,
            -0.025715338066220284,
            -0.004154542926698923,
            -0.0021693571470677853,
            0.00546714523807168,
            -0.037939269095659256,
            -0.026514554396271706,
            0.0031782162841409445,
            -0.0398864671587944,
            0.007129533216357231,
            -0.026011213660240173,
            -0.017797891050577164,
            -0.00977465882897377,
            0.0081367501989007,
            -0.0017797532491385937,
            -0.027045032009482384,
            -0.005537203047424555,
            0.000569101539440453,
            -0.058960918337106705,
            0.008110000751912594,
            -0.030460329726338387,
            -0.023829910904169083,
            0.08283882588148117,
            -0.041428275406360626,
            -0.041939590126276016,
            -0.015611167065799236,
            0.04275360330939293,
            -0.007386427838355303,
            -0.039382606744766235,
            -0.021958908066153526,
            0.019235284999012947,
            -0.021138995885849,
            0.00020878335635643452,
            -0.003305075690150261,
            0.008010056801140308,
            -0.00552228232845664,
            -0.0308658629655838,
            -0.06534608453512192,
            -0.04623250663280487,
            0.00962225440889597,
            -0.030415870249271393,
            0.010613364167511463,
            0.041439346969127655,
            0.022622713819146156,
            -0.028741031885147095,
            0.017272675409913063,
            0.0058748964220285416,
            -0.021870914846658707,
            -0.024350572377443314,
            0.004342176485806704,
            0.013559234328567982,
            -0.01975787803530693,
            0.028345996513962746,
            0.0394509993493557,
            0.04269405081868172,
            0.016478726640343666,
            -0.009320680052042007,
            0.027057036757469177,
            0.037571586668491364,
            0.011692478321492672,
            -0.06717707961797714,
            0.02971167303621769,
            0.07715877145528793,
            -0.029643220826983452,
            0.012648019008338451,
            0.009372190572321415,
            -0.017945772036910057,
            -0.002724542748183012,
            0.0003670355072245002,
            -0.0009817973477765918,
            -0.029140423983335495,
            -0.009707598946988583,
            0.012365536764264107,
            0.05274719372391701,
            0.05469011515378952,
            0.025793541222810745,
            0.00791230145841837,
            0.0014412336749956012,
            -0.011790740303695202,
            0.029134949669241905,
            -0.03227320313453674,
            0.01105030532926321,
            -0.05155513808131218,
            -0.02530932053923607,
            0.010231944732367992,
            -0.014251121319830418,
            -0.04100833833217621,
            -0.0011166572803631425,
            0.06455715000629425,
            -0.008103348314762115,
            -0.005666279699653387,
            0.04360893368721008,
            -0.03373667225241661,
            -0.014254167675971985,
            -0.00277901254594326,
            -0.0010688842739909887,
            -0.005197674501687288,
            0.02023061364889145,
            0.009440707042813301,
            0.0011739793699234724,
            0.012196880765259266,
            0.021857572719454765,
            0.005285538267344236,
            0.044416796416044235,
            0.005637729540467262,
            -0.033554475754499435,
            -0.009723758324980736,
            -0.021210746839642525,
            0.008045373484492302,
            -0.04762507230043411,
            0.014649712480604649,
            -0.02621333673596382,
            0.05672025680541992,
            -0.0476580485701561,
            0.04645267128944397,
            0.020751332864165306,
            0.018549079075455666,
            -0.06604962795972824,
            -0.007251286413520575,
            -0.03179952874779701,
            0.019007882103323936,
            0.007018589414656162,
            0.023400401696562767,
            -0.003028956474736333,
            0.032193224877119064,
            0.005892614834010601,
            -0.048690393567085266,
            0.003551051951944828,
            0.013694093562662601,
            0.0076646688394248486,
            0.050478845834732056,
            -0.03892098739743233,
            0.04144056886434555,
            0.020347075536847115,
            0.013499855995178223,
            -0.027039650827646255,
            0.03739289566874504,
            0.002333917422220111,
            -0.053822387009859085,
            0.002450790023431182,
            -0.03251664713025093,
            -0.0005118558765389025,
            -0.04804360121488571,
            -0.040800534188747406,
            0.023515580222010612,
            0.012274319306015968,
            -0.000291613454464823,
            -0.0032667075283825397,
            0.021581735461950302,
            -0.022304024547338486,
            0.016698602586984634,
            -0.014098173007369041,
            0.04729364812374115,
            0.042557768523693085,
            0.007312306668609381,
            0.0031697405502200127,
            -0.011739143170416355,
            0.04081809148192406,
            0.021345384418964386,
            -0.1041955053806305,
            0.009027160704135895,
            0.05420667305588722,
            0.014855708926916122,
            0.04071001335978508,
            0.03769918158650398,
            0.04727592691779137,
            -0.06164509803056717,
            0.02082889899611473,
            -0.0021007657051086426,
            -0.01830051839351654,
            0.014814025722444057,
            0.04562506824731827,
            0.004637035541236401,
            -0.0023105244617909193,
            -0.04323231056332588,
            -0.04320799186825752,
            -0.04906267300248146,
            -6.712538743158802e-05,
            -0.030557764694094658,
            -0.03732132166624069,
            -0.016664274036884308,
            0.0237407349050045,
            -0.002936604432761669,
            -0.01600509323179722,
            -0.04191288724541664,
            -0.05347610265016556,
            -0.004263949580490589,
            0.002588629489764571,
            0.0014376264298334718,
            -0.004975615069270134,
            0.01699494943022728,
            0.05841677263379097,
            0.005717119667679071,
            0.0016766859916970134,
            -0.0010991261806339025,
            0.020997818559408188,
            0.036337148398160934,
            -0.03365612030029297,
            0.005347431171685457,
            0.03464030101895332,
            0.012020199559628963,
            -0.026890499517321587,
            0.023816175758838654,
            -0.042665377259254456,
            -0.02773851342499256,
            -0.017200442031025887,
            -0.0030726974364370108,
            -0.028257574886083603,
            -0.0015247900737449527,
            0.0017050656024366617,
            0.005532578099519014,
            0.021343549713492393,
            -0.040092650800943375,
            -0.011947466991841793,
            0.010259407572448254,
            -0.013160230591893196,
            -0.013196012936532497,
            -0.02235269546508789,
            0.046215783804655075,
            -0.007327143102884293,
            0.04729568585753441,
            0.019062602892518044,
            0.021574679762125015,
            0.033266738057136536,
            0.04788609966635704,
            0.02291610836982727,
            -0.02593156509101391,
            -0.0875612199306488,
            -0.04692133143544197,
            -0.00031287435558624566,
            0.023167479783296585,
            -0.007204545196145773,
            -0.02584286592900753,
            -0.005258508957922459,
            0.04365718364715576,
            0.07039428502321243,
            -0.0049138907343149185,
            0.013319028541445732,
            0.01780327595770359,
            0.03453950583934784,
            -0.0016640096437186003,
            -0.03496818616986275,
            0.05673053488135338,
            -0.0042292457073926926,
            -0.0005909407627768815,
            -0.018879087641835213,
            0.007387048099189997,
            -0.018589992076158524,
            0.00542485574260354,
            0.001324932323768735,
            0.07889126241207123,
            0.0006281117093749344,
            0.015163535252213478,
            0.0029661976732313633,
            0.0031578720081597567,
            -0.023754412308335304,
            -0.007498770020902157,
            -0.09468591958284378,
            -0.007921027950942516,
            -0.011564858257770538,
            0.00041437975596636534,
            0.014048341661691666,
            0.016458110883831978,
            0.04449181258678436,
            -0.011339355260133743,
            0.05478406324982643,
            0.01812935248017311,
            -0.05765581503510475,
            -0.0375211238861084,
            -0.018809469416737556,
            -0.01505985576659441,
            0.01447466854006052,
            -0.0037701409310102463,
            -0.014076822437345982,
            0.004708387423306704,
            0.011038352735340595,
            0.035396285355091095,
            0.017261166125535965,
            -0.030323727056384087,
            0.0016783258179202676,
            -0.025774357840418816,
            -0.021536603569984436,
            0.0020732656121253967,
            0.02105776034295559,
            0.03025433048605919,
            -0.0003948197700083256,
            -0.033817630261182785,
            -0.013460621237754822,
            -0.01533849723637104,
            -0.015950661152601242,
            0.007244577631354332,
            -0.005523728206753731,
            0.031126178801059723,
            0.01695816032588482,
            0.01925632916390896,
            -0.04481833800673485,
            -0.009993203915655613,
            -0.005660662427544594,
            -0.000755724438931793,
            -0.07909796386957169,
            -0.031683895736932755,
            -0.002184585900977254,
            0.05167488753795624,
            0.018807947635650635,
            -0.018403738737106323,
            -0.04244852066040039,
            -0.007453831844031811,
            -0.043422333896160126,
            0.021041618660092354,
            -0.0022379092406481504,
            -0.02473735436797142,
            0.030652984976768494,
            0.006444329395890236,
            0.04959424212574959,
            -0.0036788354627788067,
            0.032709185034036636,
            -0.03284379094839096,
            0.030853237956762314,
            0.0013619347009807825,
            0.005965304095298052,
            -0.007318544667214155,
            -0.006816496606916189,
            0.02173650823533535,
            -0.006298174150288105,
            -0.044976845383644104,
            0.0010246068704873323,
            -0.000622287392616272,
            -0.007119461428374052,
            0.04950976371765137,
            -0.00802390743046999,
            0.006740523036569357,
            -0.01559849176555872,
            -0.014334028586745262,
            -0.008393879048526287,
            -0.04321964830160141,
            -0.03130287304520607,
            -0.03144092857837677,
            0.029101349413394928,
            -0.04460081830620766,
            -0.054932110011577606,
            -0.0023065104614943266,
            -0.014568611979484558,
            -0.006257990840822458,
            0.017792295664548874,
            -0.01935685984790325,
            -0.01745493710041046,
            -0.030867503955960274,
            -0.004524822812527418,
            0.008688285015523434,
            -0.009805016219615936,
            -0.01940576545894146,
            -0.024154728278517723,
            0.019913449883461,
            0.008328363299369812,
            -0.007902499288320541,
            -0.012884031049907207,
            0.028865672647953033,
            -0.03554374352097511,
            0.003973591607064009,
            -0.007258015219122171,
            0.03577025607228279,
            -0.015566278249025345,
            -0.06935212016105652,
            0.022961420938372612,
            0.023381203413009644,
            0.0019096850883215666,
            -0.005150781013071537,
            0.01401742547750473,
            0.019230790436267853,
            0.02515571191906929,
            0.004320175386965275,
            -0.015862107276916504,
            -0.019765092059969902,
            0.042881280183792114,
            0.021468395367264748,
            -0.00013448199024423957,
            -0.011646413244307041,
            0.004300921224057674,
            -0.02717563509941101,
            -0.020840229466557503,
            0.012219895608723164,
            0.03560083359479904,
            -0.010229566134512424,
            -0.03675133362412453,
            0.005295527633279562,
            0.04820110276341438,
            -0.0027365917339920998,
            0.002500667469576001,
            -0.03211760148406029,
            -0.009086849167943,
            0.0014396350597962737,
            -0.002680571051314473,
            0.0014544316800311208,
            -0.031077558174729347,
            -0.012055253610014915,
            0.010853399522602558,
            -0.02292310632765293,
            0.039498455822467804,
            -0.02594432793557644,
            -0.02459513396024704,
            0.03611528500914574,
            0.025755854323506355,
            0.0022436794824898243,
            -0.04105229303240776,
            0.04526219516992569,
            -0.04151320457458496,
            -0.01631963439285755,
            0.009940208867192268,
            -0.014880332164466381,
            0.016933618113398552,
            0.041091106832027435,
            -0.026897389441728592,
            -0.019160956144332886,
            -0.008669703267514706,
            0.010756928473711014,
            0.01665971241891384,
            -0.020094111561775208,
            0.0008864140836521983,
            -0.0024962853640317917,
            0.068453349173069,
            0.031301774084568024,
            -0.011523762717843056,
            -0.015245310962200165,
            0.016218077391386032,
            0.009026418440043926,
            -0.023605644702911377,
            -0.042457688599824905,
            0.02580476738512516,
            -0.03546452522277832,
            -0.040720850229263306,
            -0.032052092254161835,
            0.005154110956937075,
            -0.03541645407676697,
            0.005000439006835222,
            0.06418680399656296,
            0.006593790836632252,
            -0.019762961193919182,
            -0.018427791073918343,
            0.05508039891719818,
            0.03455515205860138,
            0.016828231513500214,
            0.015043781138956547,
            0.004019753076136112,
            0.008654552511870861,
            0.03198907896876335,
            -0.027082690969109535,
            -0.02872578799724579,
            -0.009458988904953003,
            0.03986047953367233,
            -0.013806303963065147,
            -0.034296631813049316,
            -0.029277360066771507,
            -0.021592436358332634,
            -0.01830098032951355,
            0.019453957676887512,
            0.029560044407844543,
            -0.0014844427350908518,
            -0.0025378288701176643,
            -0.025688743218779564,
            0.029260121285915375,
            0.007827368564903736,
            -0.0013853773707523942,
            0.04684023559093475,
            -0.025036778301000595,
            0.04416907578706741,
            0.0018340649548918009,
            -0.025454195216298103,
            -0.003174668876454234,
            -0.02220865897834301,
            0.003505911910906434,
            -0.02308039367198944,
            0.0316254161298275,
            0.011319001205265522,
            -0.032401833683252335,
            -0.02399023063480854,
            0.0031304412987083197,
            0.005162684712558985,
            -0.05283514782786369,
            -0.024351758882403374,
            -0.05297766998410225,
            0.01933015137910843,
            0.013344790786504745,
            -0.052249789237976074,
            0.012362109497189522,
            -0.016853079199790955,
            -0.05854380130767822,
            -0.03575415909290314,
            -0.015110047534108162,
            0.010036603547632694,
            0.015388294123113155,
            0.019419584423303604,
            -0.009413336403667927,
            0.010379117913544178,
            -0.024233069270849228,
            0.037644144147634506,
            0.02467714063823223,
            -0.011190026067197323,
            -0.02485794946551323,
            0.052626173943281174,
            -0.00018794318020809442,
            0.04551678150892258,
            -0.00011856131459353492,
            -0.009788298979401588,
            -0.005201478023082018,
            -0.00758232269436121,
            -0.03472747281193733,
            -0.005869581364095211,
            0.021744312718510628,
            -0.04429221525788307,
            0.024094682186841965,
            0.042877018451690674,
            0.005813719239085913,
            -0.02625376358628273,
            -0.011342596262693405,
            0.0017658277647569776,
            -0.000926226086448878,
            -0.006139193195849657,
            -0.0279531329870224,
            -0.03639770671725273,
            0.012789218686521053,
            -0.005416266154497862,
            0.020678577944636345,
            -0.01981268636882305,
            -0.03706250712275505,
            0.0207506250590086,
            0.01325635239481926,
            0.01925128512084484,
            -0.0191611610352993,
            -0.021786266937851906,
            -0.014326270669698715,
            -0.005592220462858677,
            -0.01759708859026432,
            0.04146691784262657,
            -0.030677909031510353,
            0.020699337124824524,
            0.04712517186999321,
            -0.05602416396141052,
            -0.002292997669428587,
            -0.008148079738020897,
            0.030514203011989594,
            0.0038032575976103544,
            0.025046546012163162,
            0.006302820984274149,
            0.02597864530980587,
            0.03970259800553322,
            -0.008966578170657158,
            0.013486241921782494,
            0.009545219130814075,
            0.021880291402339935,
            0.007228624075651169,
            0.03444255143404007,
            -0.02077292464673519,
            0.03434676676988602,
            -0.039572082459926605,
            -0.026762861758470535,
            -0.011899759992957115,
            0.01696568727493286,
            -0.03420871123671532,
            0.06332584470510483,
            -0.040599316358566284,
            -0.04106898978352547,
            0.03432454913854599,
            0.010709001682698727,
            -0.02504928968846798,
            0.031342171132564545,
            0.03233211487531662,
            0.02729267254471779,
            -0.005352669861167669,
            0.007715870160609484,
            0.012680375948548317,
            -0.004399492871016264,
            0.05260765179991722,
            -0.04949019104242325,
            0.019968334585428238,
            0.02765360102057457,
            -0.022391878068447113,
            0.0056711221113801,
            -0.03797972574830055,
            -0.025081196799874306,
            0.0003187530383002013,
            0.024232443422079086,
            -0.04551694914698601,
            -0.032228946685791016,
            0.021158652380108833,
            0.00985106360167265,
            0.0004336996644269675,
            -0.02466748096048832,
            -0.029365934431552887,
            -0.006477845832705498,
            -0.007627001963555813,
            -0.017241472378373146,
            0.005121932830661535,
            -0.017674602568149567,
            -0.008731165900826454,
            0.04169683903455734,
            -0.005397527478635311,
            -0.004425749648362398,
            0.050291020423173904,
            -0.010284043848514557,
            0.04476620629429817,
            0.0026590488851070404,
            0.053117889910936356,
            0.046963881701231,
            -0.04395333677530289,
            -0.020869875326752663,
            -0.007668104022741318,
            0.0247880257666111,
            0.011358649469912052,
            -0.036191053688526154,
            -0.0381711907684803,
            -0.010294401086866856,
            -0.035788632929325104,
            0.019838642328977585,
            0.01785186491906643,
            0.03916792571544647,
            -0.02250632643699646,
            0.041911106556653976,
            0.012962809763848782,
            -0.0033694689627736807,
            -0.044060759246349335,
            -0.03603595867753029,
            -0.010705717839300632,
            0.021812302991747856,
            -0.004431397188454866,
            0.019746314734220505,
            -0.023242225870490074,
            -0.002459036884829402,
            0.005536550190299749,
            -0.03227275609970093,
            -0.010706364177167416,
            -0.007761497516185045,
            -0.02071480080485344,
            0.037570200860500336,
            0.023528918623924255,
            0.026931138709187508,
            0.025749875232577324,
            -0.06292541325092316,
            0.010570068843662739,
            0.0012990491231903434,
            0.0023172632791101933,
            0.0068487259559333324,
            -0.03447084128856659,
            0.03060571663081646,
            0.02081543393433094,
            0.049080707132816315,
            0.047939445823431015,
            -0.009082340635359287,
            0.002302764682099223,
            0.027103332802653313,
            -0.008144288323819637,
            0.026643237099051476,
            0.02388756163418293,
            0.06970018893480301,
            -0.014944864436984062,
            0.0027073093224316835,
            0.04262932762503624,
            0.019788548350334167,
            0.005476529244333506,
            -0.01024725753813982,
            -0.0268584992736578,
            -0.01758493296802044,
            0.01847451739013195,
            0.0227054376155138,
            0.044385235756635666,
            -0.00928354263305664,
            -0.025051647797226906,
            0.007740152068436146,
            0.039159297943115234,
            -0.0033280476927757263,
            0.019673220813274384,
            0.02023795247077942,
            -0.01677604950964451,
            0.01830480434000492,
            0.04522041603922844,
            -0.011845424771308899,
            -0.008477377705276012,
            0.037077803164720535,
            -0.033568885177373886,
            -0.01692413166165352,
            -0.019481636583805084,
            0.04687219858169556,
            0.021918023005127907,
            -0.057163894176483154,
            -0.02541227824985981,
            -0.029273420572280884,
            -0.014910425990819931,
            0.009600513614714146,
            0.00554972980171442,
            -0.02126328833401203,
            -0.013998117297887802,
            0.017383115366101265,
            -0.003820766694843769,
            -0.010120360180735588,
            -0.02108561061322689,
            -0.013213747180998325,
            -0.02304784767329693,
            -0.0266091451048851,
            0.011879523284733295,
            0.006056034471839666,
            0.041747067123651505,
            -0.03200507164001465,
            -0.06311652064323425,
            0.05629407614469528,
            0.0049135335721075535,
            0.03208250552415848,
            0.047131843864917755,
            0.0033067676704376936,
            -0.047583941370248795,
            -0.011566574685275555,
            -0.02310732565820217,
            -0.010790945962071419,
            -0.03299713134765625,
            -0.026163848116993904,
            0.009407400153577328,
            0.008208753541111946,
            0.0771680399775505,
            -0.021678991615772247,
            -0.02077776938676834,
            0.023389477282762527,
            -0.07966601848602295,
            -0.01944364234805107,
            0.0003378699184395373,
            0.009487764909863472,
            -0.05168562009930611,
            -0.009815891273319721,
            0.06273338943719864,
            -0.01439465768635273,
            0.0014691372634842992,
            0.013848479837179184,
            0.0006232632440514863,
            0.018508270382881165,
            -0.07879102230072021,
            -0.03888756036758423,
            -0.01325137447565794,
            -0.026880206540226936,
            0.0511506013572216,
            0.0215439610183239,
            -0.07021477818489075,
            0.031729694455862045,
            -0.011467096395790577,
            -0.0663171336054802,
            -0.013618544675409794,
            -0.005606601946055889,
            -0.038297925144433975,
            -0.04782358556985855,
            -0.018214812502264977,
            -0.013749049045145512,
            0.014528623782098293,
            -0.024002060294151306,
            -0.027547456324100494,
            0.04505211487412453,
            -0.03006352297961712,
            -0.019426098093390465,
            0.019793642684817314,
            0.0175241157412529,
            0.017685530707240105,
            -0.02445046231150627,
            0.02107330597937107,
            -0.050894372165203094,
            0.009550242684781551,
            -0.07234121859073639,
            0.01814659871160984,
            0.047291047871112823
        ],
        [
            0.01712786965072155,
            -0.008516199886798859,
            -0.007527685258537531,
            -0.01423285249620676,
            -0.014337197877466679,
            -0.008447561413049698,
            -0.028637515380978584,
            0.025700274854898453,
            0.022867389023303986,
            0.0072183869779109955,
            0.014515511691570282,
            -0.039904143661260605,
            -0.030231144279241562,
            0.01642194204032421,
            0.05070330575108528,
            -0.02654867246747017,
            0.05244264006614685,
            -0.05079229548573494,
            0.002139294520020485,
            -0.0013573329197242856,
            -0.006848359014838934,
            -0.008635131642222404,
            0.019629817456007004,
            0.014725783839821815,
            -0.03105178102850914,
            -0.017754217609763145,
            0.02167324908077717,
            0.045443564653396606,
            0.00037736320518888533,
            0.022860106080770493,
            0.02002640999853611,
            0.004872118588536978,
            -0.0070831975899636745,
            0.020208094269037247,
            0.0314454659819603,
            -0.0005874623311683536,
            -0.01679815724492073,
            -0.023181520402431488,
            -0.013202234171330929,
            -0.0034944326616823673,
            -0.012213755398988724,
            0.023582465946674347,
            0.02038787119090557,
            0.03556961938738823,
            0.025051340460777283,
            -0.005523367784917355,
            -0.03156140074133873,
            -0.047761913388967514,
            0.001670953817665577,
            0.0145161934196949,
            -0.030053289607167244,
            0.03916240856051445,
            0.008113805204629898,
            -0.0016397639410570264,
            0.01673734560608864,
            -0.026305150240659714,
            0.04255356639623642,
            -0.0003844138700515032,
            -0.003105520736426115,
            -0.029352286830544472,
            -0.008152518421411514,
            -0.01494261622428894,
            0.008597721345722675,
            -0.012197977863252163,
            0.042375531047582626,
            0.021058449521660805,
            0.0275825597345829,
            0.003496857127174735,
            0.019498459994792938,
            0.0173286572098732,
            0.014904627576470375,
            0.025167249143123627,
            -0.020946409553289413,
            -0.03270815685391426,
            0.010614849627017975,
            -0.055249981582164764,
            -0.005413161590695381,
            -0.012020934373140335,
            0.045032303780317307,
            -0.02196744829416275,
            0.02102375216782093,
            0.015881076455116272,
            0.027378017082810402,
            0.012896708212792873,
            -0.0018481974257156253,
            -0.04100210592150688,
            -0.003525130683556199,
            -0.018858011811971664,
            -0.02152860537171364,
            0.008017677813768387,
            0.006587960757315159,
            -0.01183407474309206,
            0.026779675856232643,
            0.02333739772439003,
            -0.022740809246897697,
            0.021721765398979187,
            0.02410663664340973,
            -0.024713242426514626,
            0.07515958696603775,
            -0.0009592712740413845,
            -0.027747945860028267,
            0.0061269947327673435,
            -0.014496455900371075,
            -0.005665040109306574,
            0.013734453357756138,
            0.007048757281154394,
            -0.02487402781844139,
            -0.049745455384254456,
            -0.00415806146338582,
            -0.004663833882659674,
            0.02808871865272522,
            0.022590355947613716,
            -0.033091507852077484,
            0.011165710166096687,
            0.0036169737577438354,
            -0.017340518534183502,
            -0.02626795694231987,
            0.004397318698465824,
            0.0027204917278140783,
            0.0008768422994762659,
            0.040783267468214035,
            0.009398600086569786,
            -0.03829445317387581,
            0.004773099906742573,
            0.021261435002088547,
            0.0018832380883395672,
            0.037016045302152634,
            -0.015145632438361645,
            -0.014335686340928078,
            0.0028591430746018887,
            0.02662617713212967,
            0.006274284794926643,
            0.008183466270565987,
            -0.01612003706395626,
            0.03219674155116081,
            -0.0241226963698864,
            0.04205796495079994,
            0.006758984178304672,
            0.004029810428619385,
            0.008143222890794277,
            0.0008034392376430333,
            -0.01168857142329216,
            0.003921132069081068,
            0.015054197050631046,
            -0.00877218134701252,
            0.012960710562765598,
            -0.016925770789384842,
            -0.02697972021996975,
            0.004598047584295273,
            0.015210329554975033,
            -0.022759025916457176,
            -0.036420393735170364,
            -0.002183538628742099,
            -0.019666986539959908,
            -0.01296891551464796,
            0.016992254182696342,
            0.03028830699622631,
            -0.023915570229291916,
            0.029282616451382637,
            0.007017454132437706,
            -0.022593241184949875,
            -0.025186290964484215,
            -0.037999361753463745,
            -0.013881911523640156,
            0.02400929294526577,
            -0.0407751202583313,
            -0.06352675706148148,
            0.011784115806221962,
            0.011176773346960545,
            -0.013714131899178028,
            0.016698146238923073,
            -0.019412802532315254,
            0.05397893115878105,
            0.00899946503341198,
            0.0030329893343150616,
            -0.008762792684137821,
            -0.0056900870986282825,
            -0.02403067797422409,
            0.005467606708407402,
            -0.012890586629509926,
            -0.022520845755934715,
            0.020921679213643074,
            0.0070802317932248116,
            -0.04061604291200638,
            0.007234884891659021,
            -0.03297989442944527,
            -0.03931891918182373,
            -0.024883126839995384,
            0.018909044563770294,
            -0.029090337455272675,
            0.007887978106737137,
            0.044076986610889435,
            0.0630413219332695,
            0.021573929116129875,
            0.013643109239637852,
            0.006080510560423136,
            -0.02252952568233013,
            -0.004384962841868401,
            -0.015104999765753746,
            -0.0069763013161718845,
            -0.02176457829773426,
            0.019796431064605713,
            -0.01010038796812296,
            -0.03499355912208557,
            0.034343499690294266,
            -0.03643595799803734,
            0.02498941868543625,
            -0.008983952924609184,
            0.019287457689642906,
            -0.04245014861226082,
            -0.01837088353931904,
            -0.00961999874562025,
            0.010539618320763111,
            0.0221418384462595,
            -0.023593883961439133,
            0.009508968330919743,
            -0.01183289848268032,
            0.000911418697796762,
            -0.026405824348330498,
            -0.007188987918198109,
            -0.023633623495697975,
            0.0026487642899155617,
            -0.015959784388542175,
            -0.025509066879749298,
            -0.025403546169400215,
            -0.05700802803039551,
            -0.013554726727306843,
            -0.004314029589295387,
            0.01601332426071167,
            -0.003802128601819277,
            0.015213748440146446,
            0.026037856936454773,
            0.040257494896650314,
            -0.02114660106599331,
            -0.010396037250757217,
            -0.006984909530729055,
            0.019592389464378357,
            -0.03151321038603783,
            0.02060963585972786,
            -0.0025919179897755384,
            -0.00046844626194797456,
            -0.022755270823836327,
            -0.0016408885130658746,
            0.02175949141383171,
            0.03700251504778862,
            0.013925126753747463,
            -0.027168462052941322,
            0.016532763838768005,
            0.014121709391474724,
            0.00826509203761816,
            0.0014201787998899817,
            -0.02190469391644001,
            0.013596772216260433,
            -0.02234385721385479,
            -0.020986387506127357,
            -0.001607292564585805,
            0.0298059843480587,
            -0.03567459061741829,
            0.016991686075925827,
            -0.029900996014475822,
            2.4826056687743403e-05,
            0.011882832273840904,
            0.013026590459048748,
            0.003197869984433055,
            0.018534895032644272,
            -0.028294451534748077,
            -0.028628034517169,
            -0.017667463049292564,
            -0.019697614014148712,
            0.05548389256000519,
            0.0387532077729702,
            0.02735060267150402,
            -0.03395284339785576,
            0.020417295396327972,
            0.0424763485789299,
            -0.01987038552761078,
            0.012086939066648483,
            0.03591077774763107,
            -0.007553614675998688,
            -0.019379207864403725,
            -0.02900107391178608,
            -0.001361196511425078,
            -0.016069334000349045,
            0.0031966217793524265,
            0.03542385995388031,
            0.015557159669697285,
            -0.022282462567090988,
            -0.034504394978284836,
            0.007481907494366169,
            -0.03171415999531746,
            0.01920454576611519,
            -0.012970083393156528,
            0.012398837134242058,
            -0.03177226707339287,
            -0.015754392370581627,
            -0.03135187551379204,
            -0.05008532479405403,
            -0.015182316303253174,
            0.012626029551029205,
            0.005831737071275711,
            0.03133220970630646,
            0.03288530930876732,
            0.022688813507556915,
            -0.006653022952377796,
            0.019396930932998657,
            -0.03283114731311798,
            -0.05447361245751381,
            0.011312771588563919,
            -0.020169775933027267,
            -0.010450027883052826,
            -0.04431184008717537,
            0.020879605785012245,
            0.022957321256399155,
            1.3904577826906461e-05,
            0.01572774536907673,
            -0.027398059144616127,
            0.03154205530881882,
            0.010664279572665691,
            0.013534794561564922,
            0.034817349165678024,
            0.006385917775332928,
            0.026649240404367447,
            -0.0356256440281868,
            0.01430057268589735,
            -0.020694976672530174,
            0.01731974259018898,
            -0.0013563120737671852,
            -0.05243406817317009,
            0.03599328175187111,
            -0.0017327390378341079,
            0.007247531320899725,
            0.03575011342763901,
            0.0009238261263817549,
            0.0009464792674407363,
            0.0027225061785429716,
            -0.009910880587995052,
            -0.018600478768348694,
            0.03696516901254654,
            0.0061529106460511684,
            0.013835092075169086,
            -0.0259852334856987,
            -0.03434815630316734,
            0.0298770759254694,
            -0.01663673110306263,
            -0.023392535746097565,
            0.043444693088531494,
            0.020019972696900368,
            0.021976174786686897,
            -0.016167201101779938,
            -0.02912643551826477,
            0.0038169503677636385,
            -0.04664496332406998,
            0.004571371711790562,
            0.0070900688879191875,
            -0.00537597481161356,
            -0.007563537918031216,
            0.027203243225812912,
            -0.030116286128759384,
            0.020248137414455414,
            -0.007080895826220512,
            -0.001002555014565587,
            -0.016293160617351532,
            -0.08366233110427856,
            -0.013370626606047153,
            -0.04637909308075905,
            -0.03286410868167877,
            -0.03736819699406624,
            -0.018186286091804504,
            -0.009784151799976826,
            -0.018269965425133705,
            0.013297398574650288,
            0.0016867700032889843,
            -0.004369252361357212,
            0.02045302465558052,
            -0.011876069940626621,
            0.025491857901215553,
            0.0034896067809313536,
            0.00972396694123745,
            -0.04389381781220436,
            0.028084341436624527,
            0.000918056583032012,
            0.016296759247779846,
            0.0008410008740611374,
            -0.03940394148230553,
            -0.025782031938433647,
            -0.03369337320327759,
            0.010493297129869461,
            0.006423179060220718,
            -0.01653740555047989,
            0.012020038440823555,
            0.024591535329818726,
            0.0057638599537312984,
            -0.01211142260581255,
            0.01090062502771616,
            -0.01872476562857628,
            -0.03164178133010864,
            0.005392215680330992,
            -0.022074947133660316,
            0.02657768316566944,
            -0.012842780910432339,
            0.021794788539409637,
            0.0014544980367645621,
            0.00483687873929739,
            -0.00936891883611679,
            -0.005045437719672918,
            -0.04475978761911392,
            -0.011499341577291489,
            -0.027184637263417244,
            -0.02955280989408493,
            0.005218124482780695,
            -0.026609398424625397,
            -0.008473045192658901,
            0.02938712202012539,
            -0.037683334201574326,
            0.0061672404408454895,
            -0.013281203806400299,
            -0.0083526112139225,
            0.00757500808686018,
            -0.011096915230154991,
            -0.013746684417128563,
            0.046332892030477524,
            0.022796353325247765,
            -0.019091429188847542,
            -0.013861016370356083,
            -0.02603600174188614,
            -0.004321339074522257,
            -0.006860916968435049,
            -0.020139461383223534,
            0.028340401127934456,
            -0.029186062514781952,
            -0.007550173904746771,
            0.01079989317804575,
            0.008700424805283546,
            -0.010566121898591518,
            0.019319159910082817,
            0.019223501905798912,
            0.0052526346407830715,
            -0.030094753950834274,
            -0.031189337372779846,
            -0.00846656784415245,
            0.013376453891396523,
            0.020752262324094772,
            0.019000273197889328,
            0.009114952757954597,
            -0.026986340060830116,
            0.028700552880764008,
            0.012395009398460388,
            0.08780130743980408,
            0.04433735832571983,
            0.005052396096289158,
            -0.0029312053229659796,
            0.010294033214449883,
            0.03499739244580269,
            0.013714582659304142,
            -0.007372918538749218,
            0.01422756165266037,
            0.01573065109550953,
            -0.01814160868525505,
            0.008374223485589027,
            -0.0008773685549385846,
            0.03553740307688713,
            -0.036559101194143295,
            -0.018682364374399185,
            -0.004025226924568415,
            0.02223529852926731,
            -0.002421904820948839,
            0.01095124613493681,
            0.03806554526090622,
            0.004569359589368105,
            0.00010252486390527338,
            -0.029676292091608047,
            -0.021754516288638115,
            -0.008268256671726704,
            -0.027631308883428574,
            0.01340364571660757,
            -0.013702080585062504,
            -0.03460518643260002,
            0.03133534640073776,
            -0.017727870494127274,
            -0.009649562649428844,
            0.037007853388786316,
            0.039207249879837036,
            -0.009412437677383423,
            0.0031798831187188625,
            -0.06034013628959656,
            0.022238126024603844,
            0.01151402946561575,
            -0.001588427578099072,
            0.05239574611186981,
            0.003957516048103571,
            0.002541185123845935,
            -0.0016182924155145884,
            0.02242390438914299,
            -0.02836117334663868,
            0.010851940140128136,
            -0.0037017271388322115,
            0.0018597047310322523,
            -0.006488350685685873,
            -0.006021537818014622,
            0.003983621951192617,
            0.03140813857316971,
            -0.011999349109828472,
            0.022639233618974686,
            0.016576681286096573,
            0.018553858622908592,
            -0.01719857193529606,
            0.020926980301737785,
            0.022994527593255043,
            0.0016058327164500952,
            0.036257773637771606,
            0.014512021094560623,
            0.011850802227854729,
            0.004880291875451803,
            -0.02960835210978985,
            0.03662080317735672,
            0.00867191981524229,
            -0.010910875163972378,
            -0.015262007713317871,
            -0.025338387116789818,
            0.013058328069746494,
            0.011572800576686859,
            -0.029336266219615936,
            0.013115481473505497,
            0.011213460937142372,
            0.04729381576180458,
            0.06897579878568649,
            0.06847839057445526,
            -0.006844053510576487,
            -0.028672892600297928,
            0.007883604615926743,
            -0.013041788712143898,
            -0.008341444656252861,
            0.012604281306266785,
            0.0038260123692452908,
            0.011404613964259624,
            -0.007970604114234447,
            0.002276394050568342,
            0.041761528700590134,
            0.0023987696040421724,
            -0.01924721710383892,
            -0.013056987896561623,
            0.015122217126190662,
            -0.01863957569003105,
            -0.007970582693815231,
            -0.0007436254527419806,
            0.007596173323690891,
            0.02221202291548252,
            0.01958843134343624,
            -0.005467035807669163,
            -0.034440021961927414,
            -0.02880939468741417,
            0.008083658292889595,
            -0.003893291112035513,
            0.02094036154448986,
            0.013240891508758068,
            -0.021723754703998566,
            0.024586671963334084,
            0.03340545669198036,
            -0.021043872460722923,
            0.005755205638706684,
            0.03580261766910553,
            0.016214163973927498,
            -0.0055974326096475124,
            0.02364318259060383,
            -0.023581059649586678,
            -0.06388380378484726,
            0.01071600615978241,
            -0.027339491993188858,
            -0.028764763846993446,
            0.009765513241291046,
            0.022286340594291687,
            -0.02922401763498783,
            0.013655557297170162,
            -0.02271950989961624,
            0.020185254514217377,
            0.03374343365430832,
            0.0033735898323357105,
            0.05807734653353691,
            0.024868328124284744,
            0.01166281197220087,
            -0.025770192965865135,
            -0.003430807264521718,
            -0.012054450809955597,
            -0.010186262428760529,
            0.014806427992880344,
            0.03763613477349281,
            -0.010183379054069519,
            0.023317191749811172,
            -0.010362834669649601,
            -0.044470399618148804,
            -0.0027216272428631783,
            -0.007975393906235695,
            -0.005049963481724262,
            -0.03725819289684296,
            -0.024441566318273544,
            -0.029395172372460365,
            0.01832820661365986,
            0.029391581192612648,
            0.009488840587437153,
            0.0133304288610816,
            0.011369794607162476,
            -0.0041058664210140705,
            -0.029224297031760216,
            0.006680729798972607,
            -0.018270021304488182,
            0.039223603904247284,
            -0.014136554673314095,
            -0.014966866932809353,
            0.004830200225114822,
            -0.015425831079483032,
            0.0007701331051066518,
            0.02959233894944191,
            -0.008914107456803322,
            -0.008749984204769135,
            0.021143220365047455,
            0.053566839545965195,
            -0.0008368477574549615,
            0.011661887168884277,
            0.022051174193620682,
            -0.004188150633126497,
            -0.005687628407031298,
            -0.013068996369838715,
            -0.0212881937623024,
            -0.007992428727447987,
            -0.023013057187199593,
            0.013348007574677467,
            -0.006680002436041832,
            -0.004579311236739159,
            -0.000654728093650192,
            0.026777468621730804,
            -0.013549698516726494,
            -0.015131666325032711,
            0.0005694496212527156,
            0.027168091386556625,
            0.020225007086992264,
            0.0034736907109618187,
            0.0005543000297620893,
            -0.01891021430492401,
            -0.018592022359371185,
            0.0006052679964341223,
            -0.0141895841807127,
            -0.0050677587278187275,
            0.03918231278657913,
            0.02817998081445694,
            -0.01815704256296158,
            -0.03645598888397217,
            0.026782464236021042,
            -0.009649683721363544,
            -0.023572972044348717,
            -0.0026955234352499247,
            -0.006365087348967791,
            -0.012462971732020378,
            -0.028858112171292305,
            -0.012882749550044537,
            -0.03078371472656727,
            0.00924975797533989,
            -0.023148497566580772,
            0.008038485422730446,
            -0.02627791091799736,
            0.005355749744921923,
            -0.006447716150432825,
            0.011507166549563408,
            0.01651660166680813,
            0.019773317500948906,
            0.02648756466805935,
            -0.03092990443110466,
            -0.0017232191748917103,
            -0.03232017531991005,
            0.012705079279839993,
            0.00560977915301919,
            0.011529741808772087,
            -0.05260784924030304,
            0.010721630416810513,
            -0.007760343141853809,
            -0.009010104462504387,
            -0.015441576950252056,
            -0.030031399801373482,
            0.01988815888762474,
            0.013675507158041,
            0.019886387512087822,
            0.017777815461158752,
            0.02380116656422615,
            -0.02245599403977394,
            -0.015076476149260998,
            -0.008934897370636463,
            -0.018386324867606163,
            0.0127644594758749,
            0.04085090011358261,
            0.013862496241927147,
            0.007297827396541834,
            0.030557451769709587,
            0.046080369502305984,
            -0.038713954389095306,
            -0.001230657915584743,
            0.01616821624338627,
            -0.0035036010667681694,
            0.019617415964603424,
            0.0327879823744297,
            0.010558773763477802,
            0.023534605279564857,
            0.033233970403671265,
            0.009446818381547928,
            -0.012773634865880013,
            -0.0010481028584763408,
            0.033446624875068665,
            0.0015306416898965836,
            0.0009457169799134135,
            0.024762563407421112,
            0.0009207666735164821,
            -0.012821495532989502,
            0.01902354322373867,
            -0.011272273026406765,
            -0.005608458071947098,
            0.0072538284584879875,
            0.0055436501279473305,
            0.005025999154895544,
            0.002108961343765259,
            0.026634683832526207,
            0.006801155861467123,
            -0.004986836574971676,
            0.016534050926566124,
            -0.022820131853222847,
            -0.00552324578166008,
            0.0244270209223032,
            0.00523487338796258,
            -0.018313895910978317,
            -0.012470483779907227,
            -0.0054138218984007835,
            -0.020752958953380585,
            -0.0011508018942549825,
            0.0181045550853014,
            -0.01261015236377716,
            -0.018485751003026962,
            0.0022308018524199724,
            -0.00890488550066948,
            0.002085061278194189,
            0.030035248026251793,
            0.014024549163877964,
            -0.0422138050198555,
            -0.027165487408638,
            -0.0057609230279922485,
            -0.006010566372424364,
            0.00595827354118228,
            -0.02550417184829712,
            -6.285056588239968e-05,
            0.042995940893888474,
            -0.04291542246937752,
            0.02758350409567356,
            -0.0023085353896021843,
            0.012786859646439552,
            0.03272780030965805,
            0.014124680310487747,
            0.00943430420011282,
            0.04131607338786125,
            0.016071345657110214,
            -0.031436845660209656,
            0.028925547376275063,
            0.015440643765032291,
            -0.038209449499845505,
            0.039198633283376694,
            0.04690038785338402,
            0.04808371886610985,
            0.009729024022817612,
            -0.0035581174306571484,
            -0.002006403636187315,
            0.03323277831077576,
            -0.03221710026264191,
            -0.006438965909183025,
            0.013324547559022903,
            0.0023020454682409763,
            -0.0172712542116642,
            0.01407209224998951,
            -0.0018067719647660851,
            -0.04613376408815384,
            -0.014032100327312946,
            0.004622011445462704,
            0.018828416243195534,
            0.05955685302615166,
            0.009444667026400566,
            0.007492866367101669,
            0.018130775541067123,
            0.024258345365524292,
            -0.00799279659986496,
            -0.007167434319853783,
            -0.00961522851139307,
            0.030021578073501587,
            -0.007061243988573551,
            -0.03394591063261032,
            -0.00984655786305666,
            0.0341145321726799,
            0.005530082155019045,
            -0.022132886573672295,
            -0.05715499818325043,
            0.041283488273620605,
            0.05720967426896095,
            -0.01540372520685196,
            0.014755882322788239,
            -0.03836328163743019,
            0.017577942460775375,
            -0.0001888012484414503,
            0.010060595348477364,
            0.0004029603151138872,
            -0.025473112240433693,
            0.013448471203446388,
            0.01014681812375784,
            0.00041985115967690945,
            0.007010822184383869,
            -0.020955778658390045,
            -0.006137375254184008,
            0.03152790665626526,
            0.06875745207071304,
            -0.006746901664882898,
            0.008312678895890713,
            -0.013080762699246407,
            0.023981884121894836,
            -0.008424527943134308,
            -0.0152750164270401,
            -0.04210364818572998,
            -0.046199578791856766,
            -0.014368798583745956,
            0.019910544157028198,
            0.019240818917751312,
            -0.009707772172987461,
            -0.02885614149272442,
            -0.041474975645542145,
            0.028174996376037598,
            0.025175485759973526,
            -0.024679070338606834,
            -0.019784200936555862,
            -0.011315269395709038,
            -0.01943778619170189,
            -0.031191671267151833,
            -0.025267360731959343,
            -0.02377839758992195,
            -0.025954142212867737,
            -0.005952320992946625,
            -0.03158219903707504,
            0.028607159852981567,
            -0.03473231941461563,
            -0.015193154104053974,
            -0.013916687108576298,
            -0.004203313495963812,
            0.0195512305945158,
            -0.014962057583034039,
            -0.03075726330280304,
            -0.010321743786334991,
            0.01586007885634899,
            -0.0004515272448770702,
            0.00832452904433012,
            0.017700206488370895,
            -0.016869446262717247,
            -0.010209320113062859,
            -0.033781204372644424,
            -0.015335610136389732,
            -0.032931890338659286,
            0.012413748539984226,
            -0.020337821915745735,
            -0.0006656935438513756,
            0.032437365502119064,
            0.021517710760235786,
            0.02153759077191353,
            0.01295497827231884,
            -0.0296905729919672,
            0.04475729912519455,
            -6.845806638011709e-05,
            0.007849026471376419,
            -0.027648765593767166,
            -0.024530041962862015,
            0.00897970050573349,
            0.03157331049442291,
            -0.0342845693230629,
            0.004421014338731766,
            0.08985750377178192,
            -0.008359575644135475,
            -0.027844248339533806,
            -0.031931668519973755,
            -0.032183416187763214,
            0.05175967887043953,
            0.028735022991895676,
            0.0255733635276556,
            -0.010516623966395855,
            0.0013069824781268835,
            0.02412187121808529,
            -0.0015041844453662634,
            0.0021777877118438482,
            0.026822566986083984,
            -0.003090034471824765,
            0.04335717484354973,
            0.018762588500976562,
            -0.013793917372822762,
            -0.021350454539060593,
            -0.02671443484723568,
            0.030984431505203247,
            -0.04107781872153282,
            0.053821153938770294,
            -0.03825939819216728,
            0.029019670560956,
            0.05642087385058403,
            0.015113698318600655,
            -0.038241006433963776,
            0.02866077609360218,
            0.028270194306969643,
            -0.0001941687223734334,
            0.009362364187836647,
            -0.007048659026622772,
            -0.004474243614822626,
            -0.009578954428434372,
            -0.0074797989800572395,
            -0.002098571043461561,
            -0.02208114042878151,
            0.007023422047495842,
            -0.008160104975104332,
            0.018911872059106827,
            -0.03675267845392227,
            0.003667381126433611,
            -0.02122473530471325,
            0.046890340745449066,
            0.01881503313779831,
            -0.022163797169923782,
            -0.0020137950778007507,
            -0.0015608808025717735,
            0.010906204581260681,
            -0.016654890030622482,
            0.01668843999505043,
            0.055910900235176086,
            0.03762795776128769,
            0.008927467279136181,
            -0.043939895927906036,
            -0.004740903154015541,
            0.03342607617378235,
            -0.023126672953367233,
            -0.036243073642253876,
            0.004771512933075428,
            -0.018724942579865456,
            0.024500951170921326,
            -0.006594631355255842,
            0.024709736928343773,
            -0.008087890222668648,
            0.01589556410908699,
            0.015344108454883099,
            0.015057777054607868,
            0.024645421653985977,
            0.04593387246131897,
            -0.010438893921673298,
            -0.01729544624686241,
            -0.005381584633141756,
            0.0005552142392843962,
            -0.019587572664022446,
            0.04089971259236336,
            -0.035573869943618774,
            -0.006569311488419771,
            -0.029019474983215332,
            -0.0062603456899523735,
            -0.030862784013152122,
            0.043241653591394424,
            0.020686307922005653,
            -0.009671871550381184,
            -0.020594600588083267,
            0.05703456327319145,
            -0.012715732678771019,
            0.0341842919588089,
            0.039485134184360504,
            0.04022681340575218,
            0.036565497517585754,
            0.002852287143468857,
            -0.007418423891067505,
            0.007663325872272253,
            -0.027870425954461098,
            0.011828788556158543,
            -0.02716211788356304,
            -0.00928334891796112,
            0.0009314994094893336,
            0.013723496347665787,
            0.009792154654860497,
            -0.0019022314809262753,
            -0.010572525672614574,
            0.044562336057424545,
            -0.018372299149632454,
            0.006641377694904804,
            0.013408142141997814,
            0.012838704511523247,
            0.006616123020648956,
            0.032244063913822174,
            0.014658614061772823,
            -0.007219903636723757,
            0.021466325968503952,
            0.012086868286132812,
            0.022633494809269905,
            -0.002707632025703788,
            0.012203422375023365,
            -0.009098537266254425,
            0.016669077798724174,
            0.022872289642691612,
            -0.028762931004166603,
            0.004682623781263828,
            0.04932539165019989,
            0.0021248531993478537,
            0.025438878685235977,
            -0.00786684826016426,
            0.008798808790743351,
            -0.02115652710199356,
            -0.0014946794835850596,
            0.0032317291479557753,
            0.018855160102248192,
            0.01587892509996891,
            -0.02589767798781395,
            -0.008670877665281296,
            -0.02517673745751381,
            -0.015484443865716457,
            0.008824893273413181,
            0.017967790365219116,
            -0.04656364396214485,
            0.005808687768876553,
            0.010023833252489567,
            -0.06132899224758148,
            -0.02169853448867798,
            0.021403728052973747,
            0.024734564125537872,
            0.01643153838813305,
            -0.010360172018408775,
            0.03510161116719246,
            -0.037105340510606766,
            0.023341739550232887,
            0.029341401532292366
        ],
        [
            0.03129204735159874,
            -0.02534354105591774,
            0.012897065840661526,
            -0.007989509962499142,
            0.0013413402484729886,
            0.02248964086174965,
            0.02500121481716633,
            -0.006178530864417553,
            0.006106214597821236,
            -0.011823383159935474,
            4.759767034556717e-05,
            -0.026125967502593994,
            -0.005515079014003277,
            -0.025286011397838593,
            -0.037437401711940765,
            0.001343423267826438,
            0.005647079087793827,
            -0.0055752107873559,
            0.020591039210557938,
            0.01569773443043232,
            0.01413748785853386,
            -0.018597140908241272,
            0.031150374561548233,
            -0.017957549542188644,
            -0.019013771787285805,
            0.0079681146889925,
            -0.00037898673326708376,
            0.029378946870565414,
            -0.0035931842867285013,
            0.035832520574331284,
            0.017755024135112762,
            0.009114616550505161,
            -0.010423901490867138,
            -0.02026170678436756,
            -0.020989760756492615,
            5.8931786043103784e-05,
            0.03403737023472786,
            0.011255258694291115,
            -0.015083927661180496,
            0.0015967022627592087,
            -0.026531009003520012,
            0.032786376774311066,
            -0.010618134401738644,
            -0.02393711917102337,
            -0.022357195615768433,
            0.003267589956521988,
            -0.009493391960859299,
            -0.0004772874526679516,
            0.02326079085469246,
            -0.013409404084086418,
            -0.03557943180203438,
            0.008413949981331825,
            0.028569674119353294,
            0.021475274115800858,
            -0.0022628207225352526,
            -0.013682890683412552,
            -0.023770321160554886,
            0.006332188379019499,
            0.01804943196475506,
            0.02336791530251503,
            0.004317594226449728,
            0.028502607718110085,
            0.013800212182104588,
            -0.018518371507525444,
            -0.002446977188810706,
            -0.012213055044412613,
            0.009786750189960003,
            0.027399087324738503,
            0.03221201151609421,
            0.016332650557160378,
            0.02530965954065323,
            0.02023702673614025,
            0.008127156645059586,
            0.02264718897640705,
            -0.057759255170822144,
            -0.0225939080119133,
            0.042612966150045395,
            0.016197478398680687,
            0.012270649895071983,
            0.01477570366114378,
            -0.00013668581959791481,
            0.015500947833061218,
            -0.018306443467736244,
            0.007164375856518745,
            0.04195382073521614,
            -0.007139309775084257,
            -0.0033841547556221485,
            0.030338622629642487,
            -0.021245751529932022,
            0.04070200398564339,
            -0.04592888802289963,
            0.004588990472257137,
            0.03722984716296196,
            -0.04167885333299637,
            0.005810247268527746,
            -0.0024821250699460506,
            0.003868130501359701,
            0.015574539080262184,
            0.07039002329111099,
            -0.008329961448907852,
            0.028565697371959686,
            -0.0022556905169039965,
            -0.030222073197364807,
            -0.004643628839403391,
            0.00747928861528635,
            0.029211636632680893,
            -0.008964413776993752,
            0.027084363624453545,
            -0.055610351264476776,
            -0.027321459725499153,
            0.008245528675615788,
            0.01990092359483242,
            -0.007395062129944563,
            -0.028744138777256012,
            -0.030294395983219147,
            0.017717333510518074,
            0.03502165526151657,
            -0.002980197314172983,
            0.03301912918686867,
            -0.014397713355720043,
            -0.01739458739757538,
            -0.012789741158485413,
            -0.011100846342742443,
            0.01671004109084606,
            0.03430485725402832,
            -0.015483459457755089,
            -0.019000088796019554,
            0.03380604088306427,
            0.029698077589273453,
            0.020594490692019463,
            -0.0068173701874911785,
            -0.03666495904326439,
            -0.00834296178072691,
            -0.014314507134258747,
            -0.002328060567378998,
            0.02318195253610611,
            0.01337896566838026,
            0.002671024529263377,
            0.005536363460123539,
            -0.021398821845650673,
            -0.024159206077456474,
            -0.010386127978563309,
            0.028063546866178513,
            -0.004518733825534582,
            -0.016831478103995323,
            -0.0012961596949025989,
            0.009645518846809864,
            -0.018221816048026085,
            0.023850344121456146,
            0.002470712410286069,
            -0.0010978187201544642,
            -0.0024238708429038525,
            -0.015941323712468147,
            -0.01663031615316868,
            -0.013938226737082005,
            0.038264527916908264,
            0.001014844630844891,
            -0.017534639686346054,
            0.022744467481970787,
            -0.013266859576106071,
            -0.024480033665895462,
            0.009045615792274475,
            -0.04137546569108963,
            0.014892076142132282,
            0.01934833824634552,
            -0.003699101973325014,
            0.030232621356844902,
            -0.025457188487052917,
            0.036849867552518845,
            0.0153473736718297,
            -0.019215745851397514,
            0.015588314272463322,
            0.012936253100633621,
            0.009162039496004581,
            0.021077029407024384,
            -0.019426895305514336,
            -0.013150705955922604,
            -0.02483680471777916,
            -0.0025963210500776768,
            -0.017997432500123978,
            -0.015237930230796337,
            -0.019782718271017075,
            -0.019003115594387054,
            0.030379755422472954,
            0.020762886852025986,
            0.00806182250380516,
            -0.036985695362091064,
            0.02571289800107479,
            -0.025565505027770996,
            0.007582799531519413,
            -1.2891890037280973e-05,
            0.000834246224258095,
            0.05481787025928497,
            -0.007328675128519535,
            0.007506449241191149,
            0.022100549191236496,
            -0.02665816806256771,
            0.015469387173652649,
            0.02042924426496029,
            0.029277797788381577,
            -0.0016579137882217765,
            0.012864813208580017,
            -0.0055324542336165905,
            0.02146507427096367,
            0.03496594354510307,
            -0.02347351610660553,
            -0.0014323918148875237,
            -0.027728945016860962,
            -0.014995541423559189,
            -0.024830732494592667,
            0.001711682416498661,
            0.0015029293717816472,
            0.024100039154291153,
            0.03454804793000221,
            0.012528419494628906,
            0.0037007902283221483,
            -0.02541622519493103,
            0.002259738277643919,
            0.0016368752112612128,
            0.0014066852163523436,
            0.007090760860592127,
            -0.029834100976586342,
            0.02911585196852684,
            -0.009287446737289429,
            0.014912364073097706,
            -0.029989147558808327,
            0.0034693193156272173,
            -0.018172884359955788,
            0.009956621564924717,
            0.030123528093099594,
            0.012480532750487328,
            0.03285062313079834,
            -0.008617207407951355,
            0.02541813626885414,
            -0.011969337239861488,
            0.0006526974611915648,
            0.029632441699504852,
            -0.022208701819181442,
            -0.004853040911257267,
            -0.034521494060754776,
            0.0004842040070798248,
            -0.003781098173931241,
            0.019605381414294243,
            0.028749724850058556,
            0.006219943519681692,
            0.006871997844427824,
            0.02810852974653244,
            0.013465497642755508,
            0.009501254186034203,
            -0.022194072604179382,
            -0.009478386491537094,
            -0.007131550926715136,
            0.010957079008221626,
            -0.008715350180864334,
            0.000966520223300904,
            0.01995278149843216,
            -0.025154992938041687,
            0.011362268589437008,
            -0.017667992040514946,
            -0.016747912392020226,
            0.018832672387361526,
            -0.01417161338031292,
            0.0077655562199652195,
            0.006653061602264643,
            -0.012517932802438736,
            -0.03230510279536247,
            -0.012079458683729172,
            0.027007317170500755,
            -0.007414927706122398,
            -0.03836921602487564,
            0.011900133453309536,
            0.022388465702533722,
            0.03599882125854492,
            -0.028701448813080788,
            0.015662917867302895,
            0.0007923506200313568,
            0.00981551967561245,
            -0.006773162167519331,
            0.0120934983715415,
            0.003933227155357599,
            0.01631205715239048,
            0.0035993652418255806,
            0.009510209783911705,
            -0.06751649081707001,
            -0.01103555504232645,
            -0.014000564813613892,
            -0.008154831826686859,
            -0.0740438923239708,
            -0.004548182711005211,
            0.019433125853538513,
            -0.012467543594539165,
            -0.0023355039302259684,
            0.003983012866228819,
            -0.006663708947598934,
            0.02669951319694519,
            0.0021274311002343893,
            0.00017349258996546268,
            0.015046369284391403,
            -0.008636639453470707,
            -0.007001765072345734,
            0.009049980901181698,
            -0.0024938425049185753,
            0.021841419860720634,
            0.023951664566993713,
            0.022297656163573265,
            -0.00776301696896553,
            0.006987676490098238,
            0.024361830204725266,
            -0.019546205177903175,
            0.000110858709376771,
            -0.020455406978726387,
            0.02386709488928318,
            -0.014236611314117908,
            -0.015657827258110046,
            0.04116673767566681,
            -0.006216538604348898,
            -0.005435851402580738,
            -0.009379761293530464,
            0.03331043943762779,
            0.030651535838842392,
            0.03245914354920387,
            0.010118168778717518,
            -0.04115983098745346,
            0.027863869443535805,
            0.0083727166056633,
            0.016278937458992004,
            0.009340731427073479,
            -0.018269270658493042,
            -0.01548090297728777,
            0.03227190300822258,
            -0.02439095824956894,
            0.023545879870653152,
            -0.018523823469877243,
            0.01303861290216446,
            0.016445621848106384,
            -0.017741844058036804,
            0.01399044319987297,
            0.028383132070302963,
            -0.005300452001392841,
            0.018650375306606293,
            0.02009732276201248,
            -0.042090460658073425,
            -0.008255389519035816,
            -0.015887537971138954,
            0.012108171358704567,
            -0.025898588821291924,
            -0.016129542142152786,
            -0.017588835209608078,
            0.00902116671204567,
            0.052601832896471024,
            -0.021672077476978302,
            0.016355710104107857,
            -0.015308530069887638,
            0.032801490277051926,
            -0.008087781257927418,
            -0.003532156813889742,
            0.02361661195755005,
            -0.02335846610367298,
            0.01284861657768488,
            0.0017193210078403354,
            0.023119071498513222,
            0.0026447901036590338,
            0.007778848521411419,
            0.011372455395758152,
            0.03974219039082527,
            -0.027945879846811295,
            -0.02186082862317562,
            0.002089803572744131,
            -0.017355291172862053,
            0.005819578189402819,
            0.02036161534488201,
            -0.015280716121196747,
            0.03499659523367882,
            0.012680435553193092,
            0.02295757457613945,
            0.011941585689783096,
            -0.006278907414525747,
            0.019707325845956802,
            -0.012680074200034142,
            0.003912312909960747,
            0.03268299996852875,
            0.00888556893914938,
            -0.010320430621504784,
            0.008806894533336163,
            0.015347223728895187,
            -0.016772978007793427,
            -0.031126828864216805,
            -0.03752739727497101,
            0.011781242676079273,
            -0.01346861757338047,
            -0.006745656952261925,
            0.00927241612225771,
            0.02569553069770336,
            0.02741137519478798,
            0.022991953417658806,
            0.022309308871626854,
            0.0049454569816589355,
            -0.023336756974458694,
            -0.028843887150287628,
            -0.04374553635716438,
            0.018676582723855972,
            -0.03414924070239067,
            -0.01621084474027157,
            -0.00689962413161993,
            0.03014674037694931,
            -0.01665736921131611,
            -0.023273127153515816,
            0.021461516618728638,
            0.006803629919886589,
            0.007720169611275196,
            0.013383888639509678,
            0.03673159331083298,
            -0.006853908766061068,
            0.03987719118595123,
            -0.0398009829223156,
            -0.020540187135338783,
            0.0029338945169001818,
            0.003615285735577345,
            0.009561698883771896,
            -0.027319015935063362,
            -0.04360521212220192,
            0.030044903978705406,
            0.03760886937379837,
            0.02153211645781994,
            -0.0013117450289428234,
            0.01904548704624176,
            -0.013944254256784916,
            -0.03067401982843876,
            0.01843305304646492,
            -0.033323321491479874,
            0.0141924899071455,
            -0.028468472883105278,
            -0.002319917781278491,
            -0.024758802726864815,
            0.00912464875727892,
            -0.02950412780046463,
            -0.012439103797078133,
            0.009562136605381966,
            0.015312382020056248,
            -0.024087894707918167,
            -0.05676921829581261,
            0.0271749310195446,
            -0.008410615846514702,
            -0.029593724757432938,
            -0.003732402343302965,
            -0.030782079324126244,
            -0.007586133200675249,
            0.07169456034898758,
            -0.007256651762872934,
            0.015139666385948658,
            -0.008016802370548248,
            0.004047064110636711,
            -0.000970823282841593,
            0.002022539032623172,
            -0.01716271974146366,
            -0.012956653721630573,
            0.0320507287979126,
            0.02174481935799122,
            0.029195066541433334,
            0.011375992558896542,
            -0.00859242957085371,
            -0.012868487276136875,
            -0.0069930898025631905,
            -0.01637248881161213,
            -0.02214198373258114,
            -0.018154824152588844,
            -0.009026795625686646,
            0.01783180981874466,
            -0.004979858174920082,
            0.03269746154546738,
            0.036570992320775986,
            -0.0009278532234020531,
            0.013759160414338112,
            0.006793548818677664,
            0.028993310406804085,
            -0.024126971140503883,
            0.012868687510490417,
            -0.041961412876844406,
            -0.02516561932861805,
            -0.017371127381920815,
            0.009571503847837448,
            0.0014847038546577096,
            0.041904743760824203,
            0.022497005760669708,
            0.02947119064629078,
            -0.02568693645298481,
            0.02876431867480278,
            -0.01814057119190693,
            -0.027523444965481758,
            -0.010461109690368176,
            -0.006006165407598019,
            8.725478255655617e-05,
            0.023086490109562874,
            -0.016415176913142204,
            0.06595052033662796,
            -0.0026807773392647505,
            -0.0419449619948864,
            -0.008709645830094814,
            0.03594658523797989,
            0.027812816202640533,
            -0.02363574132323265,
            -0.005501008592545986,
            -0.019689958542585373,
            0.012880253605544567,
            0.029425133019685745,
            0.02053775265812874,
            -0.0163640808314085,
            -0.026961799710989,
            0.019407691434025764,
            -0.015185474418103695,
            0.003204698907211423,
            -0.025721441954374313,
            0.0174942035228014,
            0.009089517407119274,
            0.002114485949277878,
            0.02694963477551937,
            0.007963355630636215,
            0.016830289736390114,
            -0.01110871136188507,
            0.010563163086771965,
            0.004454843699932098,
            -0.021393921226263046,
            0.04070720821619034,
            0.015804635360836983,
            -0.03965815529227257,
            0.033654551953077316,
            0.04363274574279785,
            -0.021311858668923378,
            -0.024981817230582237,
            -0.028984911739826202,
            -0.017721641808748245,
            0.001842109253630042,
            0.03676551580429077,
            0.03230743482708931,
            0.02051945962011814,
            -0.011963914148509502,
            -0.01489260420203209,
            -0.018208207562565804,
            0.017974700778722763,
            0.02910238690674305,
            -0.015776563435792923,
            0.002404791535809636,
            0.04678451642394066,
            0.001765439403243363,
            -0.0046908739022910595,
            -0.010772346518933773,
            0.014761892147362232,
            0.005053490865975618,
            0.025697942823171616,
            -0.028698407113552094,
            -0.018515324220061302,
            0.014487567357718945,
            -0.00015736391651444137,
            -0.027263609692454338,
            0.027058614417910576,
            -0.030266394838690758,
            -0.008092566393315792,
            -0.006331548560410738,
            0.012067360803484917,
            0.007027770392596722,
            -0.014874010346829891,
            0.020832721143960953,
            0.012492679059505463,
            -0.005685195792466402,
            0.011406331323087215,
            0.022474437952041626,
            -0.019420206546783447,
            -0.013938023708760738,
            -0.019985321909189224,
            -0.010078968480229378,
            -0.021548226475715637,
            0.02237611822783947,
            0.017986703664064407,
            0.02799912355840206,
            0.024780768901109695,
            0.009650438092648983,
            -0.020699944347143173,
            0.029888251796364784,
            -0.008214683271944523,
            0.00179444276727736,
            -0.007987702265381813,
            0.009180072695016861,
            0.0077195074409246445,
            0.03212248161435127,
            0.03691231459379196,
            -0.007810642477124929,
            -0.0035693245008587837,
            0.03425091132521629,
            0.026933465152978897,
            0.007539480924606323,
            -0.006113322451710701,
            -0.007793215569108725,
            0.02748698741197586,
            0.003310430096462369,
            -0.021838225424289703,
            0.01894659548997879,
            0.03643956780433655,
            -0.02227993868291378,
            -0.012649417854845524,
            -0.016394641250371933,
            0.018274912610650063,
            -0.02123829908668995,
            -0.02614273875951767,
            0.01053011417388916,
            0.013510748744010925,
            -0.011607825756072998,
            -0.02282360941171646,
            0.013623178005218506,
            0.003794430522248149,
            -0.04764365404844284,
            0.005870312452316284,
            0.023918962106108665,
            0.018125014379620552,
            0.014900892041623592,
            0.03339077904820442,
            0.015676576644182205,
            0.01839619316160679,
            0.008901253342628479,
            -0.017618032172322273,
            0.029712770134210587,
            -0.04347408562898636,
            0.019245797768235207,
            -0.003786955028772354,
            0.021648334339261055,
            -0.0035205271560698748,
            0.013090232387185097,
            0.02134239673614502,
            0.025327028706669807,
            -0.021930672228336334,
            0.020768025889992714,
            0.014097328297793865,
            -0.009640761651098728,
            -0.013305624015629292,
            -0.018398627638816833,
            0.03544190153479576,
            -0.037444669753313065,
            0.026130311191082,
            -0.012848034501075745,
            0.015995295718312263,
            -0.005605300422757864,
            0.00745933223515749,
            -0.018523363396525383,
            -0.010254565626382828,
            0.01963106542825699,
            0.015394509769976139,
            0.030232787132263184,
            -0.050382088869810104,
            -0.016529273241758347,
            -0.01324318628758192,
            0.025787318125367165,
            0.000987571314908564,
            0.022429533302783966,
            0.01963931880891323,
            0.032993633300065994,
            0.001678811851888895,
            -0.004008762072771788,
            -0.019390862435102463,
            0.030399827286601067,
            -0.009718533605337143,
            -0.035835109651088715,
            0.0036044809967279434,
            -0.027529530227184296,
            -0.027023078873753548,
            0.014328601770102978,
            -0.011552992276847363,
            0.02772650681436062,
            -0.04391932114958763,
            -0.01679653488099575,
            -0.009031055495142937,
            0.039390113204717636,
            0.008851508609950542,
            0.0004872153513133526,
            -0.023211386054754257,
            0.010373535566031933,
            0.02794627472758293,
            -0.02099562995135784,
            0.02392561361193657,
            -0.013663115911185741,
            -0.013303556479513645,
            0.021585427224636078,
            -0.01782846450805664,
            0.0012796268565580249,
            -0.024572473019361496,
            -0.0002302511566085741,
            0.035086389631032944,
            -0.006178276147693396,
            -0.0063832527957856655,
            0.031527042388916016,
            -0.023323843255639076,
            -0.0071846237406134605,
            -0.01863887347280979,
            0.03700195625424385,
            0.03278695419430733,
            0.0049120415933430195,
            -0.015231048688292503,
            0.007107914425432682,
            0.014078734442591667,
            -0.030820975080132484,
            -0.0033381483517587185,
            0.02735038660466671,
            0.012075796723365784,
            -0.03098609298467636,
            -0.025967204943299294,
            -0.007088891696184874,
            0.003076327033340931,
            0.0224528219550848,
            0.008479814045131207,
            0.02815772406756878,
            -0.008168257772922516,
            0.035939715802669525,
            -0.003726665861904621,
            0.026348711922764778,
            0.012581857852637768,
            -0.010578117333352566,
            0.02249964140355587,
            0.033769045025110245,
            0.01413170900195837,
            -0.013897591270506382,
            0.012227693572640419,
            0.011886857450008392,
            0.0348229706287384,
            -0.003750142641365528,
            0.02334938943386078,
            -0.03743860498070717,
            -0.020196005702018738,
            0.010968674905598164,
            0.02767871506512165,
            -0.016814295202493668,
            0.01972493715584278,
            -0.027426518499851227,
            0.022576145827770233,
            0.0012631078716367483,
            -0.03998887911438942,
            -0.0009743923437781632,
            0.015512455254793167,
            0.02872677892446518,
            0.013750365003943443,
            0.010531626641750336,
            -0.03878134489059448,
            -0.03324221819639206,
            -0.002774136606603861,
            0.02586873434484005,
            0.013119962997734547,
            -0.013706093654036522,
            0.013429731130599976,
            -0.0014050343306735158,
            -0.026464134454727173,
            -0.008021572604775429,
            0.035344090312719345,
            0.00833806674927473,
            -0.012541092932224274,
            0.0059741479344666,
            0.008227158337831497,
            -0.008780771866440773,
            0.01973411813378334,
            0.0032983345445245504,
            0.009818380698561668,
            -0.03231189027428627,
            -0.01121179386973381,
            0.023776065558195114,
            0.021119127050042152,
            0.04890690743923187,
            0.02068256586790085,
            0.022563332691788673,
            -0.005180296488106251,
            0.008445397019386292,
            0.032220255583524704,
            0.011712178587913513,
            0.020654410123825073,
            -0.00699269026517868,
            -0.0010905562667176127,
            -0.012540814466774464,
            -0.004301004111766815,
            -0.02869756519794464,
            -0.010764081962406635,
            0.0014846381964161992,
            -0.001115967519581318,
            0.017211027443408966,
            0.015798311680555344,
            0.013287627138197422,
            0.007959697395563126,
            -0.00392651092261076,
            0.020878048613667488,
            -0.028156843036413193,
            -0.014584679156541824,
            -0.022836029529571533,
            0.007722948212176561,
            0.008845750242471695,
            -0.00926228053867817,
            -0.004008637275546789,
            -0.018837731331586838,
            0.008104478940367699,
            0.012248958460986614,
            -0.030569057911634445,
            0.04078051075339317,
            -0.01488842349499464,
            0.016941437497735023,
            -0.0003004708851221949,
            0.02202010340988636,
            -0.012290488928556442,
            0.028524432331323624,
            -0.0009917394490912557,
            -0.012152028270065784,
            0.018918531015515327,
            0.007145577576011419,
            0.01926249824464321,
            0.03974481672048569,
            -0.023993151262402534,
            -0.0008152654045261443,
            -0.006530865095555782,
            0.0281126219779253,
            -0.03682495281100273,
            0.014991546049714088,
            -0.015162307769060135,
            0.019975565373897552,
            0.016410106793045998,
            -0.018727649003267288,
            -0.025277968496084213,
            -0.012065581046044827,
            -0.03151118382811546,
            0.010686750523746014,
            0.02805299498140812,
            -0.013461904600262642,
            -0.009192736819386482,
            0.016189366579055786,
            0.026120927184820175,
            0.022657379508018494,
            -0.0027846500743180513,
            -0.0032213497906923294,
            -0.016826117411255836,
            -0.0033493810333311558,
            -0.016470327973365784,
            0.005249671172350645,
            -0.019871382042765617,
            -0.0013177540386095643,
            0.02385931834578514,
            0.009193532168865204,
            -0.007407099474221468,
            0.022596687078475952,
            -0.012738874182105064,
            -0.01644737832248211,
            0.008294567465782166,
            0.04367323964834213,
            -9.029978537000716e-05,
            0.0031487855594605207,
            0.019875317811965942,
            0.001482873922213912,
            -0.030343597754836082,
            -0.016127748414874077,
            0.03666621446609497,
            -0.01387548167258501,
            0.007308254949748516,
            0.045143719762563705,
            0.019062502309679985,
            0.00524637196213007,
            0.0007213731878437102,
            0.03660736605525017,
            -0.02944188006222248,
            -0.00658522080630064,
            0.03473730385303497,
            -0.0017402939265593886,
            -0.02415098063647747,
            0.02281312085688114,
            0.029874255880713463,
            -0.008199077099561691,
            -0.010942911729216576,
            -0.023441758006811142,
            -0.015964217483997345,
            -0.0014702515909448266,
            -0.017608601599931717,
            0.006469265557825565,
            0.016208022832870483,
            0.004919351544231176,
            -0.00535019114613533,
            0.016250159591436386,
            -0.011154556646943092,
            -0.003824904328212142,
            -0.010213739238679409,
            0.00020577979739755392,
            -0.015676463022828102,
            0.010598781518638134,
            0.024376915767788887,
            -0.025970906019210815,
            0.02382846362888813,
            0.016441084444522858,
            0.03404183313250542,
            0.00871350709348917,
            -0.03912696614861488,
            -0.0011531058698892593,
            -0.020602647215127945,
            -0.01851593516767025,
            0.009790241718292236,
            0.04608214274048805,
            0.007572926580905914,
            -0.012751217000186443,
            0.003950038459151983,
            0.009845697320997715,
            0.018321337178349495,
            -0.019329264760017395,
            0.018585575744509697,
            -0.021615877747535706,
            -0.029372941702604294,
            0.001223456347361207,
            -0.007078217342495918,
            0.027682507410645485,
            -0.026202881708741188,
            -0.00011445215932326391,
            0.02112525701522827,
            -0.021804779767990112,
            0.02766234055161476,
            0.016607318073511124,
            0.004293731413781643,
            -0.015544203110039234,
            0.007139363791793585,
            -0.041597917675971985,
            -0.000671692134346813,
            0.02590017579495907,
            0.022507615387439728,
            -0.0004118711513001472,
            -0.007792963646352291,
            -0.022150270640850067,
            0.034022461622953415,
            0.034860048443078995,
            -0.01496047805994749,
            -0.04186900332570076,
            0.04564980790019035,
            0.01910351775586605,
            -0.022453976795077324,
            0.03336577117443085,
            -0.002455791924148798,
            -0.009812689386308193,
            -0.007142459508031607,
            -0.017832547426223755,
            0.017320601269602776,
            -0.020515430718660355,
            0.028780972585082054,
            0.028808070346713066,
            0.022624460980296135,
            -0.01862396113574505,
            -0.003958175890147686,
            -0.013974887318909168,
            0.021830039098858833,
            0.018403582274913788,
            0.008002669550478458,
            -0.02355550415813923,
            0.019455162808299065,
            0.025962332263588905,
            -0.006501106079667807,
            0.0385383740067482,
            0.011236146092414856,
            0.015663813799619675,
            -0.02623680979013443,
            -0.0031276510562747717,
            -0.009215137921273708,
            -0.005764367990195751,
            0.020222876220941544,
            -0.007430274970829487,
            -0.015058486722409725,
            -0.010670880787074566,
            0.010757224634289742,
            0.009031963534653187,
            -0.027125177904963493,
            -0.042678531259298325,
            0.03371414914727211,
            -0.03377567231655121,
            -0.009279722347855568,
            0.020335111767053604,
            -0.004734006244689226,
            0.025784194469451904,
            -0.00041446866816841066,
            0.03283389285206795,
            -0.014013686217367649,
            -0.0037276276852935553,
            0.014336802996695042,
            -0.022619327530264854,
            -0.008780108764767647,
            0.009440915659070015,
            0.02101363241672516,
            0.014874122105538845,
            -0.024184994399547577,
            0.02970111183822155,
            -0.014838682487607002,
            0.0268500205129385,
            -0.010042514652013779,
            0.017545342445373535,
            0.021814899519085884,
            -0.005605613812804222,
            -0.011761953122913837,
            0.013169961050152779,
            0.023528845980763435,
            0.03404652699828148,
            -0.019439293071627617,
            0.029270190745592117,
            0.013214104808866978,
            0.033178698271512985,
            0.003195780562236905,
            0.044305555522441864,
            -0.0019672210328280926,
            -0.004574557766318321,
            -0.0066367099061608315,
            -0.006779302377253771,
            0.018779771402478218,
            -0.02885284833610058,
            0.021887652575969696,
            -0.004228639882057905,
            0.02125711180269718,
            0.017520617693662643,
            -0.01773458532989025,
            0.027155829593539238,
            0.05003848299384117,
            -0.009765325114130974,
            0.021410994231700897,
            -0.002606641501188278,
            -0.01242795865982771,
            -0.03993455693125725,
            0.01033240556716919,
            0.0022974961902946234,
            0.03261696174740791,
            0.005622794385999441,
            0.03286902606487274,
            -0.009051247499883175,
            0.02690473198890686,
            -0.04479627311229706,
            -0.0030333613976836205
        ],
        [
            -0.03487465903162956,
            0.002910982584580779,
            -0.014458798803389072,
            -0.0002626723435241729,
            0.02346252277493477,
            0.0026398205664008856,
            0.0320456400513649,
            -0.03903405740857124,
            -0.02080775983631611,
            -0.007577927317470312,
            0.02132166363298893,
            0.03638433292508125,
            -0.006192609667778015,
            0.01291784830391407,
            0.025916460901498795,
            -0.022654160857200623,
            0.00868525542318821,
            0.02241559512913227,
            -0.020637331530451775,
            -0.007610882632434368,
            0.01938740722835064,
            0.008726313710212708,
            -0.007134005893021822,
            0.009548483416438103,
            -0.029405707493424416,
            0.029264479875564575,
            -0.006043496076017618,
            -0.019245412200689316,
            0.004410332068800926,
            -0.026516541838645935,
            -0.02231614477932453,
            0.0009551487164571881,
            0.007859844714403152,
            0.019503353163599968,
            -0.005440795794129372,
            0.0033525272738188505,
            -0.019916974008083344,
            0.004841539543122053,
            0.029733842238783836,
            -0.007269971072673798,
            0.006472247187048197,
            -0.011177591979503632,
            0.00817042775452137,
            0.02028949372470379,
            -0.009065380319952965,
            -0.0163064394146204,
            -0.024473045021295547,
            -0.027346957474946976,
            -0.004617145750671625,
            -0.05611145496368408,
            -0.003532197093591094,
            0.0069470531307160854,
            -0.026389172300696373,
            0.0007560023223049939,
            -0.005038745701313019,
            -0.02946414425969124,
            0.012868458405137062,
            -0.03264208510518074,
            0.006094221491366625,
            0.007656950503587723,
            0.007221887353807688,
            0.00045683764619752765,
            0.03819519281387329,
            0.025352392345666885,
            -0.020788809284567833,
            0.0038271306548267603,
            -0.046987470239400864,
            -0.01887737400829792,
            -0.03911973163485527,
            0.01998770609498024,
            0.0215994231402874,
            0.01568382978439331,
            0.023601246997714043,
            -0.017719978466629982,
            0.01784209907054901,
            0.00012031934602418914,
            0.021682754158973694,
            -0.0011688078520819545,
            -0.0014626587508246303,
            -0.0008169332868419588,
            -0.003180823754519224,
            -0.026462489739060402,
            0.026401713490486145,
            -0.02874794229865074,
            0.023103680461645126,
            -0.040202777832746506,
            0.014170664362609386,
            -0.042991895228624344,
            0.030943850055336952,
            0.019668636843562126,
            -0.0033845051657408476,
            0.02759208157658577,
            -0.03784610331058502,
            0.008115305565297604,
            -0.010084430687129498,
            0.024974390864372253,
            -0.04852031543850899,
            -0.02706114761531353,
            0.001645512878894806,
            -0.005466618109494448,
            -0.032710134983062744,
            -0.038997624069452286,
            -0.03552902489900589,
            -0.020563505589962006,
            0.0032686430495232344,
            0.01832258328795433,
            -0.02299826592206955,
            -0.020425831899046898,
            0.004822683986276388,
            0.015298211947083473,
            -0.021123407408595085,
            0.013381286524236202,
            -0.027121881023049355,
            0.0022349709179252386,
            -0.032782334834337234,
            0.025152692571282387,
            -0.011252164840698242,
            -0.02004973590373993,
            0.021819405257701874,
            -0.021579459309577942,
            0.005746521055698395,
            -0.03141508623957634,
            0.06720704585313797,
            0.025074750185012817,
            0.0025348542258143425,
            0.03824649006128311,
            -0.03756977990269661,
            0.006921915803104639,
            0.010175258852541447,
            0.013609766028821468,
            0.0034941427875310183,
            -0.052907656878232956,
            0.0171885434538126,
            -0.04572207108139992,
            -0.007111168000847101,
            -0.034754447638988495,
            0.020400384441018105,
            0.023801542818546295,
            0.03046615980565548,
            -0.013065270148217678,
            -0.010541124269366264,
            0.005033361259847879,
            -0.00437382934615016,
            -0.023271556943655014,
            0.02328658290207386,
            -0.028404857963323593,
            -0.04811406880617142,
            -0.006790412589907646,
            -0.028963524848222733,
            0.040487367659807205,
            -0.06869190186262131,
            -0.04865448549389839,
            -0.01259194128215313,
            -0.02021109312772751,
            -0.024311931803822517,
            -0.02359120547771454,
            0.019660301506519318,
            -0.04960865527391434,
            -0.017192386090755463,
            0.01936587132513523,
            0.018131988123059273,
            -0.01800960674881935,
            0.00784941017627716,
            0.01703467220067978,
            -0.0030566691420972347,
            0.014149662107229233,
            0.04383527487516403,
            0.028111420571804047,
            0.012482798658311367,
            0.007370499894022942,
            -0.0253022238612175,
            -0.0005107252509333193,
            -0.029780322685837746,
            0.028923841193318367,
            -0.007537967059761286,
            -0.028140556067228317,
            0.02522655762732029,
            -0.007189577911049128,
            -0.047053176909685135,
            -0.04332130774855614,
            0.0029546029400080442,
            -0.005338452756404877,
            -0.005483706947416067,
            -0.023465916514396667,
            -0.02270309440791607,
            -0.02425316348671913,
            -0.04588273540139198,
            -0.027626726776361465,
            -0.023655850440263748,
            0.028533410280942917,
            -0.032027747482061386,
            -0.008245090954005718,
            0.060612551867961884,
            -0.003228485584259033,
            0.024747120216488838,
            -0.023402418941259384,
            0.018807942047715187,
            0.009422525763511658,
            -0.04552428796887398,
            0.0225606057792902,
            -0.02920658513903618,
            0.021872619166970253,
            -0.004602685105055571,
            0.01654496043920517,
            0.04327325150370598,
            0.005733549129217863,
            0.05014641955494881,
            -0.029642950743436813,
            -0.037755440920591354,
            -0.019976556301116943,
            0.020579379051923752,
            0.019020553678274155,
            -0.025328924879431725,
            -0.010624018497765064,
            -0.01395468506962061,
            -0.0207794439047575,
            0.0007900847704149783,
            0.015218920074403286,
            0.013568796217441559,
            -0.006177501752972603,
            0.026314590126276016,
            0.005409824661910534,
            -0.009682459756731987,
            0.03837904706597328,
            -0.018660571426153183,
            -0.0538121834397316,
            -0.0190908033400774,
            -0.01133950799703598,
            -0.041673459112644196,
            -0.015153155662119389,
            0.0209016352891922,
            -0.014993701130151749,
            -0.000806896947324276,
            0.026280788704752922,
            0.014951354824006557,
            0.014670045115053654,
            0.0031235029455274343,
            0.013821247965097427,
            0.020990988239645958,
            -0.023461822420358658,
            0.028722072020173073,
            0.008289238438010216,
            -0.0032519891392439604,
            -0.006653482094407082,
            -0.04094615951180458,
            0.02189473621547222,
            -0.026249071583151817,
            -0.025885822251439095,
            0.022602859884500504,
            -0.05157218873500824,
            0.02238517999649048,
            0.004236135166138411,
            0.022611964493989944,
            0.003474761266261339,
            -0.014126887544989586,
            0.01338024903088808,
            -0.029272930696606636,
            0.020793939009308815,
            0.029158219695091248,
            -0.01496630534529686,
            0.051852997392416,
            -0.010185548104345798,
            0.027010122314095497,
            0.029066236689686775,
            -0.01005687564611435,
            -0.05072657763957977,
            -0.002111777663230896,
            0.022782476618885994,
            -0.0005107035976834595,
            0.004673912655562162,
            -0.026591449975967407,
            0.017271917313337326,
            -0.01572204753756523,
            -0.01537682767957449,
            -0.025596173480153084,
            0.0033619096502661705,
            -0.022913072258234024,
            0.035342879593372345,
            -0.008865749463438988,
            0.027184447273612022,
            0.03311953321099281,
            0.012485639192163944,
            0.024897968396544456,
            -0.03764345496892929,
            -0.017827419564127922,
            -0.004014450125396252,
            0.01684466376900673,
            -0.027392534539103508,
            -0.031209563836455345,
            0.0008657248690724373,
            0.035779163241386414,
            -0.029510105028748512,
            -0.009455659426748753,
            0.01579413190484047,
            0.015465853735804558,
            0.030711833387613297,
            -0.01936754770576954,
            0.00029989061295054853,
            0.015611765906214714,
            0.00776636740192771,
            0.02325364761054516,
            0.03257143869996071,
            -0.016799503937363625,
            -0.0022558574564754963,
            -0.024137280881404877,
            -0.05500773712992668,
            -0.015405690297484398,
            0.003240284975618124,
            0.014970390126109123,
            -0.010563154704868793,
            0.03380787745118141,
            0.0176978912204504,
            0.018809277564287186,
            -0.003837941214442253,
            -0.00719823082908988,
            -0.07127104699611664,
            -0.01430866215378046,
            -0.0308256633579731,
            0.005318332463502884,
            -0.02971827983856201,
            -0.018887287005782127,
            0.02336486428976059,
            0.012692597694694996,
            0.031106125563383102,
            -0.016870511695742607,
            -0.018043583258986473,
            -0.030444081872701645,
            0.015358648262917995,
            -0.02072257362306118,
            0.01670558750629425,
            -0.012979024089872837,
            -0.01201828382909298,
            0.017036285251379013,
            -0.00039327811100520194,
            -0.052997078746557236,
            -0.057324543595314026,
            0.017047878354787827,
            0.0038116464857012033,
            -0.027581552043557167,
            -0.030271070078015327,
            0.011690416373312473,
            -0.013071147724986076,
            0.019537953659892082,
            0.028666382655501366,
            -0.031173018738627434,
            0.016053207218647003,
            0.025631118565797806,
            0.009685992263257504,
            -0.0068380627781152725,
            -0.01292172446846962,
            0.04453692585229874,
            0.06239406019449234,
            -0.04764799028635025,
            0.015795966610312462,
            0.0031856773421168327,
            -0.025216320529580116,
            0.012219962663948536,
            -0.015730464830994606,
            0.010233043693006039,
            -0.02922971546649933,
            -0.02969958446919918,
            -0.010816111229360104,
            -0.0037246267311275005,
            0.005178825929760933,
            -0.01430406142026186,
            0.015702830627560616,
            -0.021154992282390594,
            -0.013811200857162476,
            -0.02339193969964981,
            0.013039857149124146,
            0.025617269799113274,
            -0.017502350732684135,
            -0.0032568692695349455,
            -0.0030979628209024668,
            0.008284795098006725,
            0.0015302704414352775,
            -0.020220773294568062,
            0.03198661282658577,
            -0.05196137726306915,
            -0.014781968668103218,
            0.007614009082317352,
            -0.007534839678555727,
            -0.02675601653754711,
            0.020676447078585625,
            -0.009115142747759819,
            0.013035019859671593,
            -0.036942608654499054,
            -0.020441610366106033,
            0.011092444881796837,
            -0.026992013677954674,
            -0.0031928347889333963,
            0.0031881791073828936,
            -0.011880258098244667,
            0.000512310303747654,
            0.011540706269443035,
            0.01571839116513729,
            0.02735871635377407,
            -0.02781800553202629,
            -0.0062539223581552505,
            -0.0345480814576149,
            -0.03132746368646622,
            -0.0024779047816991806,
            -0.0019659502431750298,
            -0.018997177481651306,
            -0.0013594916090369225,
            -0.009969361126422882,
            0.01084506418555975,
            -0.037152525037527084,
            0.025766413658857346,
            0.01719064451754093,
            -0.010005254298448563,
            0.008116786368191242,
            0.02837456576526165,
            0.0338946096599102,
            0.0235443077981472,
            -0.017700176686048508,
            -0.022456403821706772,
            0.01827417127788067,
            0.03728250786662102,
            0.013811958953738213,
            -0.07168617844581604,
            0.007549788802862167,
            -0.00470634875819087,
            -0.021177003160119057,
            -0.036118559539318085,
            0.034204691648483276,
            -0.016150787472724915,
            -0.017035486176609993,
            -0.005028358194977045,
            -0.010047532618045807,
            -0.0011547887697815895,
            -0.011405413039028645,
            -0.038219697773456573,
            -0.004492267034947872,
            -0.008217975497245789,
            0.03601822257041931,
            0.02584882825613022,
            -0.019935118034482002,
            0.0054378025233745575,
            -0.022660931572318077,
            0.0014611039077863097,
            -0.026207275688648224,
            -0.006846849340945482,
            -0.022968987002968788,
            -0.023740064352750778,
            -0.012252564541995525,
            -0.025878271088004112,
            0.030260253697633743,
            0.02539190463721752,
            0.02955530397593975,
            -0.0035633353982120752,
            0.025450684130191803,
            0.0026158224791288376,
            -0.0030425256118178368,
            0.015490978956222534,
            0.010793221183121204,
            -0.009198447689414024,
            0.009566408582031727,
            -0.0011445448035374284,
            -0.003650085534900427,
            -0.012568573467433453,
            -0.04017201438546181,
            -0.0556657575070858,
            -0.057913534343242645,
            -0.0036632041446864605,
            -0.011048826389014721,
            -0.02297559380531311,
            0.024836838245391846,
            -0.009649800136685371,
            0.027930723503232002,
            -0.022417819127440453,
            0.025306735187768936,
            0.021818513050675392,
            0.03698964789509773,
            0.011808284558355808,
            -0.0029898511711508036,
            -0.029176127165555954,
            -0.008981348015367985,
            0.03334018960595131,
            0.013215014711022377,
            0.02253885567188263,
            -0.021746495738625526,
            0.04313761740922928,
            -0.02414066717028618,
            -0.033217161893844604,
            -0.035722408443689346,
            -0.015324349515140057,
            -0.009679027833044529,
            0.03490434214472771,
            -0.004161274991929531,
            -0.012755370698869228,
            -4.704323146142997e-05,
            0.0058424752205610275,
            -0.029080308973789215,
            -0.008952131494879723,
            0.019854163751006126,
            -0.009219177067279816,
            -0.01069488376379013,
            -0.010533601976931095,
            -0.03399980440735817,
            -0.033902980387210846,
            -0.006072117947041988,
            -0.008615577593445778,
            0.01094912365078926,
            0.008602818474173546,
            -0.0261935256421566,
            -0.025481808930635452,
            -0.021452749148011208,
            0.0023032326716929674,
            -0.004906550049781799,
            -0.03409307450056076,
            -0.017935484647750854,
            -0.02454126626253128,
            -0.025398096069693565,
            -0.029830528423190117,
            -0.03201591968536377,
            -0.04230201244354248,
            0.0065946015529334545,
            -0.015586025081574917,
            -0.0038275569677352905,
            0.011440275236964226,
            0.015685411170125008,
            -0.01755533739924431,
            0.023642124608159065,
            -0.015039073303341866,
            0.03168255463242531,
            -0.020400598645210266,
            -0.020597992464900017,
            -0.01700640469789505,
            -0.022385239601135254,
            -0.0010342475725337863,
            -0.045482996851205826,
            -0.010904321447014809,
            0.018689235672354698,
            -0.0024958010762929916,
            -0.014221727848052979,
            -0.007961085066199303,
            -0.015843810513615608,
            -0.017323052510619164,
            0.022311970591545105,
            -0.02527618780732155,
            -0.04080040380358696,
            -0.0018889227649196982,
            -0.007537204306572676,
            0.010842675343155861,
            0.023365607485175133,
            0.031578607857227325,
            0.03867935389280319,
            -0.006614389829337597,
            0.008452518843114376,
            -0.03192703053355217,
            0.019314052537083626,
            0.012904979288578033,
            -0.02079223096370697,
            0.049644585698843,
            0.00450099166482687,
            -0.024866795167326927,
            0.020636964589357376,
            -0.02707461267709732,
            -0.018071990460157394,
            0.030908670276403427,
            -0.010302904061973095,
            0.00958030391484499,
            -0.0012464657193049788,
            0.028027193620800972,
            -0.04438316822052002,
            0.025074081495404243,
            0.021395087242126465,
            -0.020377984270453453,
            0.04167897626757622,
            -0.009196433238685131,
            0.027419093996286392,
            0.019328568130731583,
            -0.03780360519886017,
            -0.008237735368311405,
            -0.011505644768476486,
            -0.022370586171746254,
            0.02932608872652054,
            -0.0011045391438528895,
            -0.01353284902870655,
            -0.03128347545862198,
            0.008788873441517353,
            0.017983047291636467,
            -0.00942439865320921,
            -0.02488354779779911,
            0.0008610367076471448,
            -0.024778738617897034,
            -0.03232510760426521,
            0.017539216205477715,
            0.02353111281991005,
            -0.0029689751099795103,
            -0.0126198660582304,
            -0.009610196575522423,
            -0.0013416885631158948,
            0.014510774984955788,
            -0.013378826901316643,
            -0.035268016159534454,
            0.011556508019566536,
            0.001778984209522605,
            -0.02392798289656639,
            0.03158063441514969,
            0.0019469544058665633,
            0.012805136851966381,
            0.027758045122027397,
            -0.017059020698070526,
            0.011759520508348942,
            0.024206342175602913,
            -0.017699643969535828,
            -0.016401786357164383,
            0.029703304171562195,
            0.01278209313750267,
            0.03163229301571846,
            -0.010048745200037956,
            -0.012375680729746819,
            -0.02980673499405384,
            0.007402094546705484,
            0.016201956197619438,
            0.01642431877553463,
            0.022689983248710632,
            0.003394650062546134,
            -0.05484204739332199,
            -0.042002804577350616,
            -0.007045235484838486,
            0.029286257922649384,
            0.029230637475848198,
            -0.008403120562434196,
            0.018022587522864342,
            0.006810350809246302,
            0.02807467058300972,
            0.004283421207219362,
            0.006337517406791449,
            -0.030014576390385628,
            0.0037628584541380405,
            0.002413835609331727,
            0.03899383917450905,
            -0.0026161533314734697,
            -0.011107292026281357,
            0.014280619099736214,
            0.004404558800160885,
            -0.0226233322173357,
            -0.019511820748448372,
            -0.030818089842796326,
            0.0036441537085920572,
            -0.03673717379570007,
            0.007416680455207825,
            -0.001354542444460094,
            -0.015855863690376282,
            -0.009533986449241638,
            -0.0008629381190985441,
            -0.026578497141599655,
            -0.00443699024617672,
            -0.003975388593971729,
            -0.02940959669649601,
            0.0029813696164637804,
            0.0034042438492178917,
            -0.025366973131895065,
            0.041431766003370285,
            0.01760055124759674,
            -0.011315173469483852,
            -0.023302076384425163,
            0.021357672289013863,
            0.010836738161742687,
            0.025485839694738388,
            -0.013361265882849693,
            0.02615346759557724,
            -0.00026070582680404186,
            0.010045740753412247,
            0.027732638642191887,
            0.0043983361683785915,
            0.013563151471316814,
            -0.015802135691046715,
            -0.0213798638433218,
            0.020403198897838593,
            0.03037101961672306,
            0.005562956910580397,
            0.03205222263932228,
            -0.009708317928016186,
            -0.022395679727196693,
            0.01299016922712326,
            -0.013229561038315296,
            0.05098491162061691,
            0.013064748607575893,
            0.02375526912510395,
            0.001609067083336413,
            -0.003093188162893057,
            -0.005386615637689829,
            -0.004736054223030806,
            0.03948486968874931,
            0.010852833278477192,
            -0.012076249346137047,
            -0.05538107082247734,
            0.03469550609588623,
            -0.02065296843647957,
            0.024674294516444206,
            0.002239394234493375,
            0.04912036657333374,
            0.009670748375356197,
            0.013810080476105213,
            0.0202912800014019,
            0.031563900411129,
            0.007031172979623079,
            -0.003020727075636387,
            -0.02068030647933483,
            -0.008195477537810802,
            -0.005927962716668844,
            -0.010803747922182083,
            0.00022481585619971156,
            0.013629891909658909,
            -0.023733122274279594,
            -0.020403003320097923,
            -0.02106471359729767,
            -0.014122895896434784,
            0.01926598697900772,
            0.010373318567872047,
            -0.018268436193466187,
            0.011870709247887135,
            -0.017406409606337547,
            -0.0373416393995285,
            0.0241225678473711,
            -0.02124532125890255,
            -0.011351642198860645,
            -0.038526684045791626,
            0.000573987141251564,
            0.010549905709922314,
            -0.0034722923301160336,
            -0.02749132737517357,
            0.014146633446216583,
            -0.003395433770492673,
            0.02277965098619461,
            -0.0001565876300446689,
            0.006716288160532713,
            0.005538306199014187,
            0.0037432308308780193,
            -0.0009104642667807639,
            -0.01759614609181881,
            0.002873558783903718,
            -0.017258208245038986,
            -0.0034100720658898354,
            -0.035739701241254807,
            0.040525324642658234,
            -0.015463749878108501,
            0.015496286563575268,
            -0.039035480469465256,
            0.0151128601282835,
            -0.020675726234912872,
            -0.006796885281801224,
            -0.00839477963745594,
            -0.059380967170000076,
            -0.016886599361896515,
            0.021214745938777924,
            -0.018585117533802986,
            -0.016308465972542763,
            -0.0033622966147959232,
            0.0018284062389284372,
            -0.009319182485342026,
            -0.022885369136929512,
            -0.028616929426789284,
            -0.038043875247240067,
            0.0020789597183465958,
            -0.040389105677604675,
            0.021849511191248894,
            -0.034465838223695755,
            0.018794188275933266,
            -0.006789491046220064,
            0.011014004237949848,
            0.033104557543992996,
            -0.04353497922420502,
            -0.007617685943841934,
            0.006583500187844038,
            0.01868266798555851,
            0.03542294725775719,
            -0.016443682834506035,
            -0.008889559656381607,
            -0.011237659491598606,
            0.00836500246077776,
            0.004937374033033848,
            0.06250835955142975,
            0.06056733429431915,
            0.025329984724521637,
            0.03255758807063103,
            0.022525375708937645,
            0.01361667551100254,
            0.023064862936735153,
            -0.03183598443865776,
            -0.028759850189089775,
            -0.004380434285849333,
            0.022725533694028854,
            -0.026315147057175636,
            0.013017949648201466,
            0.0005771116120740771,
            -0.016884256154298782,
            0.022343574091792107,
            0.003303535282611847,
            -0.00394181814044714,
            0.009532625786960125,
            -0.01724056527018547,
            -0.018002061173319817,
            -0.024974316358566284,
            -0.03105170652270317,
            -0.017736677080392838,
            0.005979153793305159,
            -0.010698513127863407,
            -0.05945818871259689,
            0.028808772563934326,
            0.03209326043725014,
            0.021745068952441216,
            0.020922336727380753,
            0.00780877098441124,
            -0.029957884922623634,
            -0.023714039474725723,
            0.03809501975774765,
            0.0038407091051340103,
            0.015709878876805305,
            -0.035425566136837006,
            -0.0017173015512526035,
            0.009140289388597012,
            0.029084034264087677,
            -0.020585164427757263,
            -0.041980449110269547,
            -0.026300916448235512,
            -0.03247257322072983,
            0.014768697321414948,
            -0.010987242683768272,
            -0.02194823883473873,
            0.025151144713163376,
            -0.03840726241469383,
            0.018590670078992844,
            -0.03623660281300545,
            -0.020385844632983208,
            0.019650917500257492,
            -0.025572307407855988,
            0.013644793070852757,
            -0.002785860328003764,
            0.022093789651989937,
            0.017402829602360725,
            -0.0038211694918572903,
            0.01862257532775402,
            -0.02271946333348751,
            -0.027430042624473572,
            -0.03260008618235588,
            -0.02595445327460766,
            -0.03503847122192383,
            0.007545155473053455,
            0.0054766032844781876,
            -0.012691513635218143,
            0.010729951784014702,
            0.007133147679269314,
            0.03159864619374275,
            -0.010179305449128151,
            0.016139443963766098,
            0.028116311877965927,
            -0.005035288166254759,
            -0.023630857467651367,
            0.0024333533365279436,
            -0.033572543412446976,
            -0.010167603380978107,
            -0.026322221383452415,
            -0.025616325438022614,
            0.027275405824184418,
            0.029371926560997963,
            -0.02252366580069065,
            -0.05804077908396721,
            0.02315373346209526,
            0.002682698192074895,
            0.005353989545255899,
            0.012577767483890057,
            0.002632724354043603,
            0.011403245851397514,
            -0.01384668331593275,
            -0.00768666248768568,
            -0.015348602086305618,
            -0.002757756505161524,
            -0.000740055984351784,
            -0.02796837128698826,
            -0.01937878131866455,
            -3.945327625842765e-05,
            0.00577930873259902,
            0.07145784050226212,
            0.015297926962375641,
            -0.009771697223186493,
            0.021214734762907028,
            -0.006839095149189234,
            0.044567402452230453,
            0.019036145880818367,
            -0.022719960659742355,
            0.0019311223877593875,
            0.023359782993793488,
            -0.05085235834121704,
            -0.0024662709329277277,
            0.01803123764693737,
            0.01508052833378315,
            -0.02478143945336342,
            -0.03520732372999191,
            -0.00402276124805212,
            -0.01317345630377531,
            -0.029003778472542763,
            -0.02239038050174713,
            -0.0126293133944273,
            -0.025212666019797325,
            -0.0023818197660148144,
            -0.00547708198428154,
            -0.03738293796777725,
            -0.024516604840755463,
            -0.02275589480996132,
            0.016626257449388504,
            -0.05460437014698982,
            -0.008287717588245869,
            -0.025235425680875778,
            -0.0047236233949661255,
            0.006420860067009926,
            -0.008755236864089966,
            -0.01113243866711855,
            0.024519627913832664,
            -0.00370434345677495,
            -0.005091889761388302,
            0.01948602870106697,
            0.051509033888578415,
            -0.01398001704365015,
            -0.01087093260139227,
            -0.024321869015693665,
            0.02119012363255024,
            0.015957104042172432,
            0.015894422307610512,
            -0.0035120658576488495,
            -0.006212564650923014,
            -0.0003263497492298484,
            -0.011220899410545826,
            -0.0011870661983266473,
            0.005112041719257832,
            0.020483244210481644,
            0.004647025838494301,
            0.005297176074236631,
            -0.012880039401352406,
            -0.015942063182592392,
            -0.01100389938801527,
            -0.022754235193133354,
            0.001544634229503572,
            0.01568690314888954,
            -0.02375362068414688,
            0.05249552056193352,
            -0.04059403017163277,
            -0.0064176893793046474,
            0.01984345354139805,
            0.03355355188250542,
            0.018161209300160408,
            0.007890844717621803,
            -0.011016344651579857,
            0.0004719283024314791,
            -0.0312858521938324,
            -0.0397321917116642,
            0.02069944515824318,
            0.0072062211111187935,
            0.00504140742123127,
            -0.024267619475722313,
            0.019210930913686752,
            -0.024113429710268974,
            -0.018055202439427376,
            -0.006474072579294443,
            0.027972744777798653,
            -0.011584162712097168,
            0.006520467810332775,
            0.010297122411429882,
            0.023243390023708344,
            -0.03679184243083,
            0.0014959670370444655,
            -0.010403475724160671,
            -0.023012546822428703,
            0.02175290882587433,
            0.02929076924920082,
            -0.02423117868602276,
            0.021168310195207596,
            -0.004500417038798332,
            -0.03195052221417427,
            -0.023984333500266075,
            0.014669731259346008,
            -0.035303883254528046,
            -0.012774725444614887,
            -0.01147516816854477,
            -0.011055328883230686,
            -0.004915348254144192,
            0.003106191521510482,
            0.008752681314945221,
            -0.0047372919507324696,
            0.004690464586019516,
            0.00047883359366096556,
            0.017176834866404533,
            -0.019956570118665695,
            0.02446264959871769,
            0.027603179216384888,
            -0.007012908346951008,
            -0.03552039712667465,
            0.014155070297420025,
            0.035235628485679626,
            -0.010737212374806404,
            0.003138204338029027,
            0.04554809257388115,
            -0.0453173890709877,
            -0.03149525076150894,
            -0.02475537732243538,
            0.01771339774131775,
            -0.008338786661624908,
            -0.06814365833997726,
            -0.006682270206511021,
            0.003982688300311565,
            -0.023517122492194176,
            0.018568318337202072,
            -0.025848357006907463,
            -0.032857589423656464,
            0.02583196945488453,
            0.0005906225414946675,
            0.0008065719739533961,
            -0.002073673065751791,
            -0.01858135126531124,
            -0.028996899724006653,
            -0.0028499753680080175,
            0.05661396682262421,
            0.027712712064385414,
            -0.011079187504947186,
            -0.05388506501913071,
            0.03090304136276245,
            -0.06094412878155708,
            -0.012029989622533321,
            -0.01123131439089775,
            -0.01181880198419094
        ],
        [
            0.003776862286031246,
            0.020424889400601387,
            -0.02752114273607731,
            0.0009350666659884155,
            -0.02617553249001503,
            -0.029263710603117943,
            0.01743696630001068,
            -0.008212768472731113,
            0.019262084737420082,
            0.00799142848700285,
            -0.017148161306977272,
            0.020363735035061836,
            0.0025043173227459192,
            -0.032986536622047424,
            0.024624155834317207,
            -0.004693853668868542,
            -0.012357328087091446,
            -0.008698533289134502,
            0.006650255061686039,
            -0.025992725044488907,
            -0.02526734210550785,
            0.013659472577273846,
            0.07350224256515503,
            0.02468775026500225,
            0.011911306530237198,
            -0.023487137630581856,
            -0.014519267715513706,
            -0.04098838195204735,
            0.007455391343683004,
            -0.014623475261032581,
            0.009316788986325264,
            -0.0026789563708007336,
            0.002858236199244857,
            -0.020262863487005234,
            -0.013347767293453217,
            0.008400303311645985,
            -0.0208672396838665,
            0.010336990468204021,
            -0.009246798232197762,
            0.0070837982930243015,
            -0.005045399535447359,
            0.005933827254921198,
            0.023490559309720993,
            0.012266336008906364,
            0.04089248180389404,
            0.007682315073907375,
            -0.024126464501023293,
            0.03701042756438255,
            -0.028008276596665382,
            0.05004643276333809,
            -0.015022406354546547,
            0.029011661186814308,
            0.005272753071039915,
            0.02744450978934765,
            0.009085731580853462,
            -0.015626823529601097,
            -0.004249261692166328,
            0.007179819047451019,
            0.005403035786002874,
            -0.005784566048532724,
            -0.01180519349873066,
            -0.009878908284008503,
            0.025843337178230286,
            -0.025047151371836662,
            0.004912723787128925,
            -0.02908424846827984,
            -0.027072744444012642,
            -0.022807490080595016,
            0.001673376769758761,
            0.023657241836190224,
            0.02675854042172432,
            -0.005779156927019358,
            -0.014798815362155437,
            0.006268838420510292,
            0.022979704663157463,
            -0.005387682467699051,
            -0.003486611880362034,
            -0.02262580767273903,
            -0.0002611303934827447,
            -0.016119444742798805,
            -0.025394784286618233,
            0.025471746921539307,
            0.004292950499802828,
            -0.02169342339038849,
            0.031090721487998962,
            0.027596842497587204,
            -0.0006174288573674858,
            0.025535155087709427,
            0.022673899307847023,
            0.004309618379920721,
            0.008366553112864494,
            0.0010387677466496825,
            0.022551437839865685,
            0.02230469137430191,
            -0.016705362126231194,
            -0.017597977072000504,
            -0.017347177490592003,
            -0.0026648074854165316,
            0.05177925527095795,
            -0.021772366017103195,
            0.02210804633796215,
            0.029482485726475716,
            -0.01654699444770813,
            0.03278902545571327,
            0.023636920377612114,
            0.02773725986480713,
            0.019793888553977013,
            -0.003410718170925975,
            -0.040363021194934845,
            -0.022479968145489693,
            -0.026020511984825134,
            -0.015265031717717648,
            0.026674902066588402,
            0.03359299898147583,
            -0.003898379160091281,
            0.0302251148968935,
            0.019042806699872017,
            0.005958897061645985,
            -0.0026381383650004864,
            0.025948651134967804,
            0.0029451928567141294,
            -0.02142987586557865,
            -0.00187026581261307,
            0.005794057156890631,
            -0.02442072704434395,
            -0.012324029579758644,
            0.06091928109526634,
            -0.02092631720006466,
            -0.004931253846734762,
            0.011328372173011303,
            -0.0068068611435592175,
            0.04632124304771423,
            -0.03302103653550148,
            0.03140079602599144,
            0.02649049274623394,
            0.02375459112226963,
            0.020580096170306206,
            -0.0006470073712989688,
            -0.005275988020002842,
            0.016274288296699524,
            -0.02528565190732479,
            -0.031862951815128326,
            0.016465773805975914,
            0.03264548256993294,
            0.01066907960921526,
            -0.046502284705638885,
            0.00647472869604826,
            -0.010838036425411701,
            -0.016766414046287537,
            -0.012119299732148647,
            0.01332275290042162,
            -0.017525460571050644,
            -0.01957136020064354,
            0.021875571459531784,
            0.019267665222287178,
            0.01291078981012106,
            -0.006569602061063051,
            -0.028782803565263748,
            0.040319811552762985,
            -0.03450038656592369,
            -0.02163965441286564,
            0.02733786217868328,
            0.0023513638880103827,
            -0.025683045387268066,
            0.00025163436657749116,
            -0.0033681492786854506,
            -0.006186835002154112,
            0.02854006178677082,
            -0.022652918472886086,
            -0.02913225255906582,
            0.012991131283342838,
            -0.0199328251183033,
            0.006563285365700722,
            0.02040661685168743,
            0.016682833433151245,
            -0.000662656151689589,
            -0.00685556186363101,
            -0.012938112951815128,
            0.049659665673971176,
            -0.012254299595952034,
            0.02718205936253071,
            -0.03349343314766884,
            -0.015479634515941143,
            0.005894639063626528,
            -0.0203225277364254,
            -0.01957186870276928,
            0.018590817227959633,
            -0.007600519340485334,
            -0.029606854543089867,
            0.02507024258375168,
            -0.02223513089120388,
            -0.013797375373542309,
            -0.003473191289231181,
            0.023333124816417694,
            -0.016852252185344696,
            0.0023316217120736837,
            -0.0030473696533590555,
            -0.0037515098229050636,
            -0.00901664886623621,
            -0.0017158372793346643,
            -0.0022100573405623436,
            -0.006267979275435209,
            0.0175106730312109,
            0.004912555683404207,
            0.009120455011725426,
            -0.01277528889477253,
            0.042807187885046005,
            -0.022717999294400215,
            0.0061427028849720955,
            0.005742205306887627,
            -0.01419710461050272,
            0.014485633932054043,
            -0.002995952032506466,
            0.01613774336874485,
            0.002783749485388398,
            0.010128268040716648,
            -0.01753631979227066,
            0.011378377676010132,
            0.023868881165981293,
            -0.008953846991062164,
            0.019854985177516937,
            0.000998290372081101,
            0.017672883346676826,
            -0.013378257863223553,
            -0.006944028194993734,
            -0.04316779971122742,
            0.004890722222626209,
            -0.014146463945508003,
            -0.01913425698876381,
            0.025604890659451485,
            0.003723383881151676,
            -0.02532843127846718,
            0.003981276415288448,
            -0.029615286737680435,
            0.005421686917543411,
            -0.020164504647254944,
            -0.03237394988536835,
            0.01241323072463274,
            -0.023433195427060127,
            -0.04194599762558937,
            0.006867129355669022,
            7.79810125095537e-06,
            0.023447072133421898,
            -0.0048966784961521626,
            -0.03911583870649338,
            -0.021598152816295624,
            0.00438482454046607,
            0.015442881733179092,
            -0.0037498625461012125,
            0.016358191147446632,
            -0.01795297861099243,
            -0.013616195879876614,
            0.0371093824505806,
            -0.030209781602025032,
            0.02838735096156597,
            -0.01362548116594553,
            0.007512315176427364,
            -0.004146232269704342,
            -0.0020577681716531515,
            -0.02676585502922535,
            0.047790609300136566,
            -0.0045050960034132,
            0.03747711703181267,
            -0.011589335277676582,
            -0.03829118609428406,
            -0.02316100522875786,
            0.01993403024971485,
            0.010932737961411476,
            0.02904471568763256,
            -0.04073036089539528,
            -0.02437489852309227,
            -0.03394686430692673,
            -0.016778206452727318,
            0.010562465526163578,
            -0.007395380176603794,
            -0.013689929619431496,
            -0.015643319115042686,
            -0.004450945649296045,
            0.02921326830983162,
            0.006061194464564323,
            -0.012397722341120243,
            -0.0010849458631128073,
            -0.017699938267469406,
            0.03316020593047142,
            -0.0070954617112874985,
            -0.0047232527285814285,
            0.004880260676145554,
            -0.040120720863342285,
            -0.004732389934360981,
            -0.02463543228805065,
            -0.013888095505535603,
            -0.03949611261487007,
            0.019376976415514946,
            -0.024653185158967972,
            -0.04075057432055473,
            0.0012895383406430483,
            -0.005520202685147524,
            -0.05309842526912689,
            -0.028998911380767822,
            -0.03595400229096413,
            0.01747194491326809,
            0.001437116414308548,
            0.029604341834783554,
            0.015612514689564705,
            0.005295594222843647,
            0.02446221560239792,
            -0.008104482665657997,
            0.008286639116704464,
            -0.008601023815572262,
            0.011838065460324287,
            -0.00457116961479187,
            -0.01431596651673317,
            0.006399242673069239,
            0.027080973610281944,
            0.029095102101564407,
            -0.022836308926343918,
            -0.021028712391853333,
            -0.021233025938272476,
            -0.009145599789917469,
            -1.6863214113982394e-05,
            0.01636967621743679,
            -0.0670381635427475,
            -0.02382369339466095,
            0.07408948987722397,
            0.002888379618525505,
            0.015256074257194996,
            0.026665756478905678,
            0.013735971413552761,
            -0.012347930110991001,
            0.002657785313203931,
            -0.015630444511771202,
            0.04433321952819824,
            -0.005689147859811783,
            -0.021393023431301117,
            -0.008201492950320244,
            0.01779715158045292,
            -0.018216155469417572,
            -0.011187460273504257,
            0.00887769553810358,
            -0.01616041734814644,
            0.007987039163708687,
            -0.02856045588850975,
            -0.026198841631412506,
            -0.0037310176994651556,
            -0.0016066919779404998,
            -0.004522088915109634,
            -0.02061718888580799,
            0.03613773733377457,
            -0.002172465668991208,
            0.026700565591454506,
            0.007991824299097061,
            -0.027774998918175697,
            0.018984515219926834,
            0.04177921265363693,
            0.0033702810760587454,
            0.000948561355471611,
            0.007342734839767218,
            0.00021660175116267055,
            0.014977061189711094,
            -0.013267682865262032,
            0.009384457021951675,
            0.002761837327852845,
            0.03414562717080116,
            0.02996491640806198,
            -0.023307722061872482,
            -0.018404273316264153,
            0.01907864585518837,
            0.013108356855809689,
            -0.003717195475474,
            0.02049778774380684,
            -0.005665604956448078,
            0.031071318313479424,
            -0.014176624827086926,
            -0.018153320997953415,
            0.023659057915210724,
            -0.0052325730212032795,
            0.011066930368542671,
            -5.818478894070722e-05,
            0.033934060484170914,
            0.0011989206541329622,
            0.01117669977247715,
            0.008106409572064877,
            -0.008187133818864822,
            -0.026032201945781708,
            0.0011984456796199083,
            0.041017066687345505,
            0.002844772534444928,
            0.031350765377283096,
            -0.013226814568042755,
            -0.001739046536386013,
            0.010931095108389854,
            -0.011374306865036488,
            0.018536541610956192,
            0.0339810773730278,
            -0.02028653398156166,
            -0.007925386540591717,
            0.012131861411035061,
            -0.03665151819586754,
            0.007688729092478752,
            -0.02144424431025982,
            -0.05935315787792206,
            -0.007523032370954752,
            -0.03135856240987778,
            0.01686347834765911,
            0.017245693132281303,
            -0.027458801865577698,
            -0.016524510458111763,
            -0.012178405188024044,
            0.018867529928684235,
            0.023774437606334686,
            0.011008145287632942,
            -0.005401812959462404,
            0.006371954455971718,
            -0.030246691778302193,
            -0.0038426476530730724,
            0.004566526040434837,
            -0.027561882510781288,
            -0.011179422028362751,
            0.032577261328697205,
            -0.019177762791514397,
            0.012884289026260376,
            0.037827517837285995,
            0.023258280009031296,
            -0.013447719626128674,
            -0.011422052048146725,
            -0.014340631663799286,
            0.0009485805639997125,
            -0.031047513708472252,
            -0.017959151417016983,
            -0.022905932739377022,
            -0.017774241045117378,
            0.010383686050772667,
            0.023305149748921394,
            0.01210264302790165,
            -0.0037077576853334904,
            0.0221940316259861,
            -0.029062526300549507,
            0.004379745572805405,
            -0.02247774973511696,
            0.005564375780522823,
            -0.00651727057993412,
            0.010075455531477928,
            0.0060694399289786816,
            0.004562833812087774,
            -0.02474346198141575,
            -0.017082620412111282,
            0.020868541672825813,
            0.08530499786138535,
            0.006728472653776407,
            0.023684978485107422,
            0.030930178239941597,
            -0.013180429115891457,
            0.017998825758695602,
            -0.0008198829018510878,
            -0.003102000104263425,
            0.028494933620095253,
            0.032267678529024124,
            -0.025759350508451462,
            -0.01131095178425312,
            -0.00886065885424614,
            0.027352744713425636,
            0.011650457046926022,
            0.017063409090042114,
            0.004230753984302282,
            0.02127426117658615,
            0.03731096535921097,
            -0.02149190381169319,
            0.0180081557482481,
            0.008683240041136742,
            0.0026404447853565216,
            -0.03775321692228317,
            -0.008861478418111801,
            0.03037181682884693,
            -0.04370899125933647,
            -0.02380354329943657,
            0.021900609135627747,
            0.034428585320711136,
            0.03977617993950844,
            -0.007279839366674423,
            -0.017003212124109268,
            -0.025151988491415977,
            -0.020220089703798294,
            -0.009861497208476067,
            0.01970607601106167,
            0.05377867445349693,
            -0.03647385537624359,
            0.0060928561724722385,
            -0.02996356040239334,
            -0.051188934594392776,
            -0.00854850560426712,
            0.0034906656946986914,
            0.03102470189332962,
            0.023171475157141685,
            0.026777850463986397,
            0.00615142984315753,
            0.03106427751481533,
            0.03879256546497345,
            0.006766867823898792,
            -0.020512087270617485,
            0.021580299362540245,
            0.03127651289105415,
            0.02216503955423832,
            0.010348345153033733,
            -0.026164673268795013,
            0.0261846911162138,
            -0.011370383203029633,
            0.02633223868906498,
            0.02931731753051281,
            0.020543046295642853,
            0.02757692150771618,
            0.017175324261188507,
            0.014033767394721508,
            -0.021003680303692818,
            -0.03565967082977295,
            0.031933046877384186,
            0.025853466242551804,
            0.02380557917058468,
            0.0012867538025602698,
            -0.026188138872385025,
            -0.01615775376558304,
            0.03053639456629753,
            -0.028539154678583145,
            0.013247420080006123,
            0.004173035733401775,
            0.03597179427742958,
            0.0035958848893642426,
            0.03726955130696297,
            -0.013635218143463135,
            -0.017785590142011642,
            -0.0012255116598680615,
            -0.007871822454035282,
            0.012025696225464344,
            0.032162800431251526,
            -0.005004822742193937,
            0.0030586959328502417,
            -0.011823893524706364,
            0.021929070353507996,
            -0.017020147293806076,
            0.006399740930646658,
            -0.004540778696537018,
            0.007248540874570608,
            0.02543232962489128,
            0.027531374245882034,
            -0.01669827662408352,
            0.034141335636377335,
            -0.03143283352255821,
            -0.0038173897191882133,
            0.02755962684750557,
            -0.008986233733594418,
            0.013453584164381027,
            -0.014517143368721008,
            0.02633996307849884,
            -0.03593994304537773,
            0.02043529972434044,
            0.012063984759151936,
            0.002626611152663827,
            0.014826113358139992,
            -0.009302136488258839,
            0.0007899018237367272,
            -0.014299665577709675,
            -0.00621174369007349,
            0.021581854671239853,
            0.013501941226422787,
            0.0028328050393611193,
            0.013316831551492214,
            -0.03611533343791962,
            -0.0211328212171793,
            0.016467232257127762,
            0.009306316263973713,
            -0.02228180319070816,
            0.011718426831066608,
            0.020816421136260033,
            -0.01591503992676735,
            0.024536626413464546,
            0.008901148103177547,
            0.030810927972197533,
            0.00728280795738101,
            -0.022067105397582054,
            -0.0415688157081604,
            0.012609994038939476,
            -0.021189067512750626,
            0.0056233713403344154,
            -0.008235896937549114,
            0.037334442138671875,
            0.025467999279499054,
            -0.03265025466680527,
            0.008167908526957035,
            0.015353871509432793,
            0.03409198299050331,
            0.017191706225275993,
            -0.018879922106862068,
            0.0007407597731798887,
            -0.016651952639222145,
            -0.028520192950963974,
            0.017286762595176697,
            -0.026902681216597557,
            0.023025738075375557,
            0.012381268665194511,
            -0.01931682415306568,
            0.03534572198987007,
            -0.034872084856033325,
            -0.03082420863211155,
            0.008124527521431446,
            -0.016042392700910568,
            -0.033049214631319046,
            -0.0047864411026239395,
            0.01624145917594433,
            -0.02223714254796505,
            0.0017150001367554069,
            -0.007537215948104858,
            0.025272700935602188,
            -0.021515315398573875,
            0.0008696967270225286,
            -0.026846710592508316,
            -0.03587677702307701,
            0.03535864129662514,
            -0.009199367836117744,
            -0.015455756336450577,
            -0.012447511777281761,
            0.013482502661645412,
            -0.02103399857878685,
            -0.004818204790353775,
            -0.012256510555744171,
            -0.02264893800020218,
            0.0009010654175654054,
            0.009762941859662533,
            -0.040510814636945724,
            0.024322742596268654,
            -0.02748650312423706,
            -0.02494649961590767,
            0.010090031661093235,
            0.02213241159915924,
            0.030069533735513687,
            -0.02217312529683113,
            0.008953739888966084,
            -0.04115636274218559,
            -0.016761507838964462,
            -0.01400397066026926,
            -0.006609068717807531,
            0.026438983157277107,
            0.03294391930103302,
            0.0073812780901789665,
            0.013598484918475151,
            0.01909673772752285,
            0.023970065638422966,
            -0.020848935469985008,
            0.004319468047469854,
            -0.019344734027981758,
            -0.0004143116530030966,
            -0.018035277724266052,
            -0.035331640392541885,
            0.035911545157432556,
            -0.003527470398694277,
            0.007831382565200329,
            -0.0017227629432454705,
            0.01729222573339939,
            -0.043955735862255096,
            0.007802705280482769,
            -0.024548649787902832,
            0.0330183319747448,
            -0.017997153103351593,
            0.0013620129320770502,
            -0.02073635160923004,
            -0.0028107091784477234,
            -0.01437997817993164,
            0.023612622171640396,
            -0.030845072120428085,
            -0.024631120264530182,
            -0.0036177965812385082,
            -0.01641056314110756,
            0.02237236313521862,
            -0.0179304126650095,
            -0.034304093569517136,
            -0.03839719295501709,
            -0.02174614742398262,
            -0.004970605485141277,
            -0.021972795948386192,
            0.024902934208512306,
            0.01094900630414486,
            -0.019877705723047256,
            0.027404773980379105,
            -0.005907061975449324,
            0.018312297761440277,
            0.02239285036921501,
            0.012253914028406143,
            0.015955718234181404,
            0.01976865343749523,
            0.03919188678264618,
            0.009498265571892262,
            0.012506470084190369,
            0.011979188770055771,
            0.020003309473395348,
            0.02648169733583927,
            0.009298447519540787,
            0.014620492234826088,
            0.02672082744538784,
            0.010549116879701614,
            0.01197977177798748,
            0.005773311480879784,
            -0.006073650438338518,
            -0.02599347196519375,
            -0.032214682549238205,
            0.02784944698214531,
            0.0056972624734044075,
            -0.022478194907307625,
            -0.032721638679504395,
            0.03179743140935898,
            0.0028666851576417685,
            -0.02286752499639988,
            -0.03559413179755211,
            0.008337283506989479,
            -0.019075928255915642,
            -0.03160828351974487,
            -0.017334260046482086,
            0.016006313264369965,
            0.008672338910400867,
            -0.035515181720256805,
            0.013792264275252819,
            0.029132984578609467,
            -0.010046558454632759,
            -0.02225126326084137,
            0.0253171157091856,
            0.02061845362186432,
            -0.013123264536261559,
            0.0007078436901792884,
            0.025656966492533684,
            0.025714419782161713,
            0.019305096939206123,
            -0.014630279503762722,
            0.004933943971991539,
            0.005462786182761192,
            0.011787365190684795,
            0.020444856956601143,
            0.0070657143369317055,
            -0.008328993804752827,
            0.005621759686619043,
            -0.012058920226991177,
            0.03548617660999298,
            -0.031973697245121,
            0.010266637429594994,
            0.013438474386930466,
            -0.003598448121920228,
            0.006235127802938223,
            -0.034035567194223404,
            0.01591729000210762,
            -0.01894008368253708,
            0.025224996730685234,
            -0.022821037098765373,
            0.0022872937843203545,
            0.02666189707815647,
            0.029171902686357498,
            0.00512859970331192,
            -0.010203966870903969,
            0.022586513310670853,
            0.013042375445365906,
            0.02229086309671402,
            0.01764601282775402,
            -0.0355764739215374,
            0.024533351883292198,
            -0.012356499210000038,
            0.014728350564837456,
            -0.04998264089226723,
            -0.02883281745016575,
            -0.014794398099184036,
            -0.016587410122156143,
            -0.017654677852988243,
            -0.001878548413515091,
            -0.015377077274024487,
            0.020622722804546356,
            -0.00518100755289197,
            0.019441401585936546,
            0.012857208028435707,
            -0.006451569497585297,
            -0.029120299965143204,
            -0.01704525575041771,
            0.008176886476576328,
            0.006356045603752136,
            0.05581475421786308,
            0.005641446448862553,
            -0.029347291216254234,
            0.024765150621533394,
            -0.018053971230983734,
            -0.016926798969507217,
            -0.006526776123791933,
            0.03160027414560318,
            0.032711874693632126,
            0.010359142906963825,
            0.006685969419777393,
            -0.004668031819164753,
            -0.012191944755613804,
            0.021368198096752167,
            -0.026293162256479263,
            -0.01431078277528286,
            0.011137602850794792,
            -0.007730868179351091,
            0.005005521234124899,
            -0.016511239111423492,
            0.014262337237596512,
            0.014842797070741653,
            -0.03572506457567215,
            0.011453947052359581,
            0.019299406558275223,
            0.015006040222942829,
            0.009702663868665695,
            -0.019137630239129066,
            -0.0037463896442204714,
            -0.002974618226289749,
            0.01681535877287388,
            -0.006537262350320816,
            0.001970516284927726,
            0.018322622403502464,
            -0.012633685022592545,
            0.016224468126893044,
            -0.02971576526761055,
            -0.060009535402059555,
            0.008743707090616226,
            -0.008590919896960258,
            0.023041747510433197,
            -0.006294343154877424,
            0.01428567711263895,
            0.020635100081562996,
            -0.023928357288241386,
            -0.00938390288501978,
            0.006313859950751066,
            0.01383081916719675,
            -0.026031428948044777,
            0.005493462085723877,
            -0.0032173043582588434,
            0.022283311933279037,
            -0.0073245819658041,
            -0.008702388033270836,
            0.028502611443400383,
            0.01670132204890251,
            -0.006091268267482519,
            0.014279977418482304,
            -0.0020474200136959553,
            -0.008013754151761532,
            0.010655860416591167,
            0.016675271093845367,
            0.04361099377274513,
            0.02969236858189106,
            -0.012626794166862965,
            -0.008608438074588776,
            0.022206544876098633,
            0.012719627469778061,
            0.026120901107788086,
            -0.0228483434766531,
            -0.0160850677639246,
            0.03501345217227936,
            0.024210460484027863,
            -0.0362810455262661,
            0.021911365911364555,
            0.05638666823506355,
            0.0007492617587558925,
            0.031727757304906845,
            -0.0038136665243655443,
            0.021892361342906952,
            -0.011192592792212963,
            0.004834461957216263,
            0.005426337476819754,
            -0.012958345003426075,
            0.014442327432334423,
            -0.009137092158198357,
            2.9324560273380484e-06,
            -0.00775119848549366,
            -0.017501793801784515,
            0.03051156923174858,
            0.0052527799271047115,
            0.031113456934690475,
            0.015611742623150349,
            0.018472129479050636,
            0.04027339443564415,
            -0.022373633459210396,
            0.01596568524837494,
            0.007652383763343096,
            -0.03156381845474243,
            0.032792653888463974,
            0.012049122713506222,
            0.024976734071969986,
            -0.01082836277782917,
            0.0038468241691589355,
            -0.014186734333634377,
            -0.01624239608645439,
            -0.004030960146337748,
            0.023230280727148056,
            0.0006093606352806091,
            0.006150207482278347,
            0.06749808043241501,
            -0.001317701768130064,
            -0.0025515565648674965,
            0.011835160665214062,
            0.033317241817712784,
            0.015312625095248222,
            0.0034824060276150703,
            0.04182959720492363,
            -0.016594119369983673,
            0.013805297203361988,
            -0.0014933818019926548,
            0.02485640160739422,
            -0.013483970426023006,
            0.008577510714530945,
            0.0005636862479150295,
            -0.026080762967467308,
            0.021526314318180084,
            0.016618963330984116,
            0.0008677470614202321,
            -0.022096512839198112,
            -0.020274490118026733,
            -0.029275743290781975,
            -0.02130920998752117,
            0.009095463901758194,
            0.031334832310676575,
            -0.017589913681149483,
            -0.0019961760845035315,
            -0.00647328607738018,
            -0.019281575456261635,
            -0.03075432777404785,
            -0.02458566427230835,
            0.029937785118818283,
            0.018306216225028038,
            -0.0148721719160676,
            0.025052586570382118,
            -0.005430327728390694,
            0.026373645290732384,
            0.04364195838570595,
            -0.009800398722290993,
            0.027865828946232796,
            -0.017984353005886078,
            -0.03334924951195717,
            -0.042136259377002716,
            -0.0065488023683428764,
            -0.0064610447734594345,
            0.00524568185210228,
            0.004595725331455469,
            -0.054031919687986374,
            0.03370072692632675,
            0.016760947182774544,
            -0.0042486488819122314,
            -0.022878054529428482,
            -0.01110123936086893,
            0.030350971966981888,
            -0.01022367738187313,
            -0.008426323533058167,
            0.02710731141269207,
            -0.0412718839943409,
            -0.01003240142017603,
            -0.023698559030890465,
            -0.004868039861321449,
            0.027229903265833855,
            -0.028946248814463615,
            -0.046599309891462326,
            0.0302849430590868,
            0.005109755322337151,
            0.010083160363137722,
            0.0001282556331716478,
            -0.014740727841854095,
            -0.007625914644449949,
            -0.00869063101708889,
            -0.03423391282558441,
            -0.005017171148210764,
            -0.04078427329659462,
            0.03222162276506424,
            0.012954219244420528,
            0.029943235218524933,
            -0.015650054439902306,
            -0.003206188092008233,
            -0.020739566534757614,
            0.0013075228780508041,
            0.039504487067461014,
            0.01727157086133957,
            -0.013382164761424065,
            -0.027856510132551193,
            0.018763452768325806,
            -0.02811850979924202,
            -0.010912437923252583,
            -0.025506122037768364,
            -0.025581665337085724,
            0.02633211947977543,
            0.0012471709633246064,
            0.034525711089372635,
            0.011890608817338943,
            -0.02684502862393856,
            0.0035018851049244404,
            0.004264972172677517,
            0.02741354890167713,
            -0.020853959023952484,
            0.012219810858368874,
            0.0234499704092741,
            0.03190373629331589,
            0.0010048074182122946,
            -0.033763498067855835,
            0.021701117977499962,
            -0.02408657781779766,
            -0.02484314888715744,
            -0.00541068846359849,
            -0.00351228890940547,
            -0.013456485234200954,
            -0.004276229068636894,
            0.016374042257666588,
            -0.026132546365261078,
            0.008479667827486992,
            0.01813391223549843,
            -0.001309509389102459,
            -0.02329200692474842,
            -0.020070882514119148,
            0.029431873932480812,
            0.024815058335661888,
            -0.022029586136341095,
            0.029040371999144554,
            -0.014832450076937675,
            0.016864964738488197,
            0.004266624804586172,
            0.015460656024515629,
            -0.027125027030706406,
            -0.010510384105145931,
            -0.0030661753844469786,
            -0.018928416073322296,
            0.0023313164710998535,
            0.004445065278559923,
            0.006058867555111647,
            -0.01995127648115158,
            0.007352867629379034,
            -0.0032165739685297012,
            0.038840312510728836
        ],
        [
            -0.03536852076649666,
            0.0044090780429542065,
            0.021145997568964958,
            -0.01325064618140459,
            -0.012462112121284008,
            0.04900607094168663,
            0.045499566942453384,
            0.05153074860572815,
            -0.009161990135908127,
            -0.01955585554242134,
            0.01624739170074463,
            0.03199594467878342,
            -0.04698452726006508,
            -0.0015889269998297095,
            0.028366949409246445,
            -0.019354818388819695,
            -0.04135332256555557,
            0.03910158574581146,
            0.0023059763479977846,
            0.019392840564250946,
            0.0006386418826878071,
            -0.01159551739692688,
            0.03757951781153679,
            -0.02038775198161602,
            0.03711853176355362,
            0.003764245193451643,
            0.011560529470443726,
            0.012088690884411335,
            -0.01912754401564598,
            -0.02579604648053646,
            0.017970656976103783,
            0.012694678269326687,
            0.018348082900047302,
            0.018689187243580818,
            0.0002321503998246044,
            0.014306636527180672,
            -0.00803428702056408,
            0.023992987349629402,
            0.016343828290700912,
            0.0158796738833189,
            0.06671568751335144,
            0.0035157878883183002,
            0.025571424514055252,
            -0.019246963784098625,
            0.07023047655820847,
            -0.02621506154537201,
            -0.03176390379667282,
            0.04571698233485222,
            -0.01814565435051918,
            -0.03724944218993187,
            -0.01984524540603161,
            0.0002695223956834525,
            -0.026837727054953575,
            -0.0219669658690691,
            0.023082518950104713,
            0.009479287080466747,
            0.0013362121535465121,
            -0.035044774413108826,
            -0.0018832680070772767,
            0.024592546746134758,
            0.01810060441493988,
            -0.012685295194387436,
            0.02176491543650627,
            -0.020041627809405327,
            0.0005815788172185421,
            -0.0240805484354496,
            -0.05655067786574364,
            0.02836349792778492,
            -0.02911623753607273,
            -0.005532542709261179,
            -0.01664472371339798,
            -0.015515471808612347,
            -0.0074553159065544605,
            0.012517130933701992,
            0.048393890261650085,
            -0.022013744339346886,
            -0.039261460304260254,
            0.006125594023615122,
            -0.0007007909007370472,
            0.005569568835198879,
            -0.014172202907502651,
            0.030661307275295258,
            -0.0307749155908823,
            -0.003498350502923131,
            0.00820227712392807,
            -0.03323660045862198,
            0.006348801776766777,
            0.026999127119779587,
            0.002131148474290967,
            0.05211591348052025,
            0.00809447281062603,
            -0.04980699345469475,
            -0.022995075210928917,
            -0.023746754974126816,
            -0.05278051272034645,
            -0.02814374677836895,
            -0.00830734334886074,
            0.01384948194026947,
            -0.0040611098520457745,
            0.029215721413493156,
            0.033460475504398346,
            -0.015738047659397125,
            0.031828392297029495,
            -0.0431295707821846,
            0.00934255588799715,
            -0.02727321721613407,
            0.004614259582012892,
            0.004490583203732967,
            0.022238554432988167,
            0.013841084204614162,
            -0.03092748299241066,
            0.023751569911837578,
            0.03163791075348854,
            0.013153624720871449,
            0.037445567548274994,
            -0.02556173875927925,
            0.0314539410173893,
            -0.041118085384368896,
            -0.013803479261696339,
            -0.0025219637900590897,
            -0.037637002766132355,
            0.010941083543002605,
            0.09448160231113434,
            0.002269487362354994,
            -0.013933965004980564,
            0.0008853170438669622,
            0.03788639232516289,
            -0.016202248632907867,
            -0.013763093389570713,
            0.010339247062802315,
            0.005692901089787483,
            0.03825853019952774,
            -0.08760606497526169,
            -0.014901702292263508,
            0.007976346649229527,
            0.0025528771802783012,
            -0.001544796396046877,
            -0.020619923248887062,
            0.0010210091713815928,
            0.0040457528084516525,
            0.010714743286371231,
            -0.0006441283039748669,
            0.022077402099967003,
            -0.008901726454496384,
            0.017433853819966316,
            -0.012232886627316475,
            0.024115383625030518,
            -0.027061348780989647,
            0.02804427780210972,
            0.0026849405840039253,
            0.05482730641961098,
            -0.014327753335237503,
            0.03066551685333252,
            0.05071952939033508,
            0.04324430972337723,
            -0.048167210072278976,
            -0.0014163872692734003,
            -0.049675170332193375,
            -0.04398205131292343,
            -0.028659449890255928,
            0.005011979956179857,
            0.014386595226824284,
            0.02890714630484581,
            -0.01590271294116974,
            -0.0049086385406553745,
            0.013320954516530037,
            -0.049007002264261246,
            0.016915738582611084,
            0.02235572226345539,
            -0.022462014108896255,
            -0.02038576267659664,
            -0.029337884858250618,
            -0.024498118087649345,
            0.009715763852000237,
            -0.006590732838958502,
            0.05806602165102959,
            -0.012924635782837868,
            -0.035250741988420486,
            0.049971550703048706,
            -0.012942999601364136,
            -0.020984819158911705,
            -0.02153203822672367,
            0.0421232208609581,
            -0.016003182157874107,
            -0.03144138306379318,
            0.025008780881762505,
            -0.037637580186128616,
            -0.005783392582088709,
            0.0010161703685298562,
            -0.014756954275071621,
            0.03774089738726616,
            -0.002020862651988864,
            -0.08712274581193924,
            0.02078753150999546,
            -0.0324590690433979,
            -0.021090904250741005,
            -0.021168522536754608,
            0.019033074378967285,
            -0.018636198714375496,
            -0.03274988383054733,
            -0.03509664535522461,
            0.010168354026973248,
            0.04289828985929489,
            -0.019229300320148468,
            -0.0011138718109577894,
            -0.045306235551834106,
            -0.0006719306693412364,
            -0.0011242395266890526,
            0.025470683351159096,
            0.0013405283680185676,
            -0.026265542954206467,
            0.004179219249635935,
            0.011859907768666744,
            0.01200600154697895,
            -0.006976316682994366,
            0.0026566553860902786,
            -0.01595490053296089,
            0.0023600340355187654,
            0.003539056284353137,
            0.009702498093247414,
            -0.013947706669569016,
            0.010268676094710827,
            0.03878503665328026,
            0.018496936187148094,
            -0.006895240396261215,
            0.07596014440059662,
            -0.052042458206415176,
            0.03739729896187782,
            0.0046931067481637,
            0.025861062109470367,
            -0.0013591914903372526,
            0.02824156917631626,
            -0.013770735822618008,
            0.02196006290614605,
            -0.03771478682756424,
            -0.01613316684961319,
            -0.0400862880051136,
            0.0298551544547081,
            0.029588956385850906,
            0.016453946009278297,
            0.00401336932554841,
            -0.016249721869826317,
            -0.01573268696665764,
            0.022532429546117783,
            0.008302357979118824,
            -0.01508607529103756,
            0.0071495044976472855,
            0.012470809742808342,
            -0.007837370038032532,
            -0.03151107206940651,
            -0.04946611076593399,
            -0.01374428067356348,
            -0.059426289051771164,
            0.019601719453930855,
            0.014919539913535118,
            0.005073990672826767,
            0.019819539040327072,
            0.009184028953313828,
            -0.044076934456825256,
            -0.05382709577679634,
            0.028064675629138947,
            -0.036634545773267746,
            0.018917841836810112,
            0.03246450051665306,
            -0.022082287818193436,
            -0.037219081073999405,
            0.005835335236042738,
            -0.033106978982686996,
            0.037870388478040695,
            -0.018021315336227417,
            -0.00268314010463655,
            -0.039278581738471985,
            -0.021434113383293152,
            -0.005717659834772348,
            0.04357236251235008,
            0.03073870576918125,
            0.010486042127013206,
            0.009290769696235657,
            -0.02220788784325123,
            0.0014048235025256872,
            0.02943594381213188,
            -0.0247111264616251,
            -0.014013147912919521,
            0.08506964147090912,
            0.010639598593115807,
            0.029249077662825584,
            0.02380969002842903,
            0.07069483399391174,
            -0.009168574586510658,
            -0.020817061886191368,
            0.016543840989470482,
            0.0074311550706624985,
            0.017569800838828087,
            -0.02139521948993206,
            -0.036146290600299835,
            0.030495919287204742,
            0.03387971967458725,
            -0.0004467471444513649,
            -0.04097871482372284,
            0.0023556507658213377,
            0.01726502552628517,
            0.01660926640033722,
            0.011640792712569237,
            -0.001874250709079206,
            -0.003175698919221759,
            0.00901383999735117,
            -0.01920071616768837,
            0.0060164229944348335,
            -0.0251704603433609,
            0.0005790882860310376,
            0.020142709836363792,
            0.01567632518708706,
            0.01224161684513092,
            0.004800685681402683,
            0.01198853924870491,
            0.009408879093825817,
            0.00033020597766153514,
            0.005180805921554565,
            0.004473190288990736,
            0.03567105159163475,
            0.021349811926484108,
            -0.017742838710546494,
            0.05573116987943649,
            0.01235596276819706,
            0.017018117010593414,
            0.0018416309030726552,
            0.025125840678811073,
            0.03624754399061203,
            0.004534121602773666,
            0.020685797557234764,
            -0.01277073659002781,
            0.014350416138768196,
            0.02890527993440628,
            -0.007403773721307516,
            -0.024637240916490555,
            0.036828357726335526,
            -0.03906290605664253,
            -0.020189916715025902,
            -0.0006821891292929649,
            0.02082994021475315,
            0.0031347789335995913,
            -0.036701105535030365,
            -0.02372014708817005,
            -0.0010472491849213839,
            0.009636567905545235,
            -0.029976902529597282,
            0.05192267522215843,
            0.02782447263598442,
            -0.03234247490763664,
            -0.0009974536951631308,
            0.004620558116585016,
            0.016578204929828644,
            0.02941731922328472,
            -0.0068807452917099,
            -0.011491592042148113,
            0.014274107292294502,
            0.025110064074397087,
            -0.02542033977806568,
            0.02963821031153202,
            -0.03570794686675072,
            -0.005924688186496496,
            -0.005108649842441082,
            -0.01644798181951046,
            0.035857200622558594,
            -0.05905225872993469,
            -0.019022153690457344,
            -0.04440681263804436,
            -0.021852346137166023,
            0.030186129733920097,
            -0.032105952501297,
            -0.009387207217514515,
            -0.03322906419634819,
            -0.009637327864766121,
            -0.04951733350753784,
            0.028366483747959137,
            -0.009284986183047295,
            0.020708240568637848,
            -0.005710389465093613,
            0.05833350494503975,
            -0.0011743155773729086,
            -0.0422990545630455,
            -0.017546264454722404,
            -0.0014032747130841017,
            0.02035658434033394,
            -0.0012160155456513166,
            0.08272860944271088,
            0.014209605753421783,
            0.003895619185641408,
            -0.012339804321527481,
            -0.024082904681563377,
            0.03533349186182022,
            -0.0210434477776289,
            -0.011323909275233746,
            0.057240936905145645,
            -0.01953006163239479,
            0.01838945597410202,
            -0.011906970292329788,
            -0.005775642115622759,
            -0.018298756331205368,
            0.0321190319955349,
            -0.026293819770216942,
            0.023478327319025993,
            -0.03494837135076523,
            0.006244130432605743,
            -0.012042355723679066,
            0.016054466366767883,
            0.020217223092913628,
            0.01029487419873476,
            -0.032166510820388794,
            -0.03788502886891365,
            -0.025836342945694923,
            0.024839995428919792,
            0.0028285617008805275,
            0.00039347997517324984,
            -0.006883047521114349,
            -0.017495663836598396,
            -0.017933962866663933,
            -0.004645677283406258,
            0.03902798518538475,
            -0.03242316097021103,
            0.023214541375637054,
            -0.00777087127789855,
            0.0333765372633934,
            0.012974380515515804,
            -0.0014169476926326752,
            -0.04498353227972984,
            -0.020109521225094795,
            -0.010468706488609314,
            0.013792230747640133,
            -0.04129050672054291,
            -0.03654598444700241,
            0.01378602720797062,
            0.03958287835121155,
            0.04019935429096222,
            0.01929103210568428,
            0.026751257479190826,
            -0.03826963156461716,
            -0.0030338787473738194,
            -0.0006353455828502774,
            -0.02769416570663452,
            0.03825662285089493,
            -0.011656335555016994,
            0.03582608327269554,
            0.023300444707274437,
            -0.04075445234775543,
            -0.012141288258135319,
            -0.028543589636683464,
            0.018750417977571487,
            0.01323461439460516,
            0.03181525692343712,
            0.006920052692294121,
            0.012800726108253002,
            -0.0006315539940260351,
            0.022311044856905937,
            0.026115616783499718,
            -0.03035232238471508,
            -0.008062636479735374,
            0.002204441698268056,
            0.0002796208136714995,
            -0.02152201719582081,
            0.029858887195587158,
            0.004198101349174976,
            0.006533617619425058,
            -0.014847725629806519,
            -0.026503022760152817,
            -0.011678975075483322,
            0.0006055526901036501,
            0.0064009432680904865,
            0.01031537726521492,
            0.01825544238090515,
            0.019271286204457283,
            -0.0015907917404547334,
            -0.01568206399679184,
            -0.04596266895532608,
            0.01155966054648161,
            0.008055816404521465,
            -0.03527515381574631,
            0.06498946249485016,
            -0.031994447112083435,
            0.03770464286208153,
            -0.0021246690303087234,
            -0.008363021537661552,
            -0.018273353576660156,
            -0.02115621604025364,
            0.017966195940971375,
            0.015367691405117512,
            0.011292519979178905,
            -0.006156297400593758,
            0.000760329538024962,
            0.011150572448968887,
            -0.011673984117805958,
            -0.018092602491378784,
            0.02006893791258335,
            -0.03573400899767876,
            0.02943602204322815,
            0.007274686824530363,
            0.021552562713623047,
            0.005970184225589037,
            -0.028648458421230316,
            0.022778965532779694,
            0.019796928390860558,
            -0.005847086198627949,
            -0.0038273304235190153,
            0.039961133152246475,
            0.008784652687609196,
            -0.03997116908431053,
            -0.004173507913947105,
            -0.025301065295934677,
            0.011116626672446728,
            0.0104451235383749,
            0.004234121181070805,
            0.003391688922420144,
            -0.005130312871187925,
            -0.0180386733263731,
            0.05726328119635582,
            0.019062938168644905,
            0.010545576922595501,
            0.022794540971517563,
            -0.021001087501645088,
            -0.019144495949149132,
            -0.004290885757654905,
            0.011014795862138271,
            -0.020042644813656807,
            -0.011015929281711578,
            0.031417116522789,
            0.0255840253084898,
            0.0323021300137043,
            -0.02111724205315113,
            -0.022113434970378876,
            -0.006167932879179716,
            -0.01992148533463478,
            -0.024012774229049683,
            -0.009270443581044674,
            0.003876361297443509,
            0.022088810801506042,
            -0.05735636129975319,
            -0.018513990566134453,
            -0.0205217432230711,
            0.045045092701911926,
            0.022600196301937103,
            0.011259643360972404,
            -0.01738421991467476,
            -0.015325921587646008,
            0.03958486020565033,
            -0.027446292340755463,
            0.03186342120170593,
            0.00794410239905119,
            0.032329022884368896,
            0.012542646378278732,
            0.005707834381610155,
            -0.006021791137754917,
            -0.03144243359565735,
            -0.010791109874844551,
            -0.006795331835746765,
            0.037174876779317856,
            -0.01983814314007759,
            -0.020788216963410378,
            -0.007087453734129667,
            -0.0017481817631050944,
            -0.025924736633896828,
            0.02977227047085762,
            -0.03654949739575386,
            -0.018637944012880325,
            0.020826447755098343,
            -0.0009686481789685786,
            0.018216881901025772,
            0.025021079927682877,
            0.015257621183991432,
            0.009618055075407028,
            -0.012913999147713184,
            0.027706589549779892,
            -0.017621289938688278,
            -0.020525408908724785,
            0.0057763103395700455,
            0.0072731985710561275,
            -0.0257097315043211,
            -0.047640472650527954,
            -0.03098144382238388,
            0.01741008646786213,
            0.023360956460237503,
            0.00442866375669837,
            0.017187457531690598,
            -5.8439203712623566e-05,
            0.024319583550095558,
            -0.012014084495604038,
            0.0004089392605237663,
            0.006897391751408577,
            -0.017258770763874054,
            0.022693391889333725,
            -0.018534325063228607,
            0.014451245777308941,
            -0.013046746142208576,
            0.01253106165677309,
            -0.020257659256458282,
            -0.02350671961903572,
            -0.0054183341562747955,
            0.009548510424792767,
            0.012231891043484211,
            -0.014097515493631363,
            0.040532972663640976,
            -0.0521479956805706,
            0.014621604233980179,
            -0.003769426606595516,
            0.016357725486159325,
            -0.011504198424518108,
            0.031904399394989014,
            0.03471742942929268,
            -0.03550893813371658,
            0.04007728025317192,
            0.016611987724900246,
            -0.0171278715133667,
            -0.027612239122390747,
            -0.02212940901517868,
            0.0005268019158393145,
            0.022762110456824303,
            0.014864363707602024,
            -0.007092754822224379,
            0.007654877379536629,
            0.011991210281848907,
            -0.006641835905611515,
            -0.028939947485923767,
            0.011720496229827404,
            0.002508319914340973,
            0.007263962645083666,
            -0.01404393371194601,
            -0.014230892062187195,
            -0.008674773387610912,
            -0.0018565092468634248,
            0.0017162257572636008,
            -0.028919482603669167,
            -0.003819335950538516,
            -0.009707276709377766,
            -0.034023791551589966,
            0.04071734473109245,
            0.01843172498047352,
            -0.036878250539302826,
            -0.01098769623786211,
            -0.009975714609026909,
            0.028760703280568123,
            -0.007161439396440983,
            0.03929370641708374,
            0.008606232702732086,
            0.01495773158967495,
            -0.044461846351623535,
            -0.026038529351353645,
            0.05283495411276817,
            0.06160786747932434,
            -0.011416922323405743,
            -0.026951076462864876,
            -0.011682456359267235,
            0.0019643951673060656,
            0.03525460138916969,
            0.029865184798836708,
            0.006772848777472973,
            0.04576626047492027,
            -0.03193804994225502,
            0.038317762315273285,
            0.0174665916711092,
            0.03817575424909592,
            0.015829842537641525,
            -0.038209471851587296,
            -0.03095083124935627,
            -0.024629492312669754,
            0.004303529392927885,
            -0.023726757615804672,
            0.02124437317252159,
            -0.03454327955842018,
            -0.0019370683003216982,
            -0.011602534912526608,
            -0.008161981590092182,
            -0.028755031526088715,
            -0.022185547277331352,
            0.021003112196922302,
            0.01763555034995079,
            0.016764268279075623,
            0.028251085430383682,
            0.008396918885409832,
            -0.049646101891994476,
            0.004240913782268763,
            -0.019544130191206932,
            0.06240752711892128,
            -0.015041119419038296,
            0.01227272767573595,
            -0.026639362797141075,
            -0.013305996544659138,
            -0.003300172509625554,
            0.013030856847763062,
            0.0054413555189967155,
            -0.010965530760586262,
            0.0043770791962742805,
            0.039121631532907486,
            0.02550639398396015,
            -0.012470445595681667,
            -0.0344884991645813,
            -0.022068055346608162,
            0.04381953179836273,
            -0.015424839220941067,
            0.004389967769384384,
            0.01729990728199482,
            0.05211688578128815,
            0.019576609134674072,
            0.00788127351552248,
            -0.015132738277316093,
            -0.018006738275289536,
            -0.02355991303920746,
            0.008188907988369465,
            -0.03021988831460476,
            0.029294410720467567,
            0.02992221526801586,
            -0.03989642858505249,
            0.03114357963204384,
            0.027876395732164383,
            -0.057331569492816925,
            0.0053665004670619965,
            -0.026170041412115097,
            0.004987002350389957,
            -0.04640597477555275,
            0.0024533825926482677,
            -0.027765456587076187,
            -0.0017045512795448303,
            0.009163766168057919,
            -0.01390222180634737,
            0.0027338562067598104,
            0.048647213727235794,
            0.006937151309102774,
            0.015966616570949554,
            -0.010299820452928543,
            -0.016289930790662766,
            0.017969029024243355,
            -0.014068223536014557,
            -0.027320370078086853,
            -0.03486347943544388,
            -0.02239721454679966,
            -0.03968362137675285,
            0.010405132547020912,
            -0.025436412543058395,
            0.02945762872695923,
            -0.006970081944018602,
            0.011229919269680977,
            0.02435091696679592,
            0.008903888054192066,
            0.009026899933815002,
            -0.01125169638544321,
            0.018867651000618935,
            -0.01988781988620758,
            0.005063013173639774,
            0.0015629479894414544,
            0.04193771630525589,
            0.02332594059407711,
            0.011766418814659119,
            -0.027704624459147453,
            -0.013318939134478569,
            0.0039846813306212425,
            0.04117865115404129,
            -0.01757841557264328,
            0.009114152751863003,
            -0.010450382716953754,
            -0.06370168924331665,
            0.03848084434866905,
            0.03259534761309624,
            0.03989255428314209,
            -0.023624921217560768,
            0.017528586089611053,
            -0.011599980294704437,
            -0.01007079891860485,
            0.03152057155966759,
            0.023595917969942093,
            -0.011648896150290966,
            -0.03574196621775627,
            -0.019497090950608253,
            0.02385253831744194,
            0.041787225753068924,
            0.034771647304296494,
            0.004153246525675058,
            0.005391877144575119,
            -0.006315938197076321,
            -0.0015137826558202505,
            0.04525909572839737,
            0.007865133695304394,
            0.010277820751070976,
            -0.0907503068447113,
            0.014476421289145947,
            0.041848987340927124,
            -0.004154682159423828,
            -0.011359883472323418,
            0.02846497669816017,
            0.07540761679410934,
            0.02121652290225029,
            0.024454861879348755,
            -0.019744740799069405,
            0.008208786137402058,
            0.011269742622971535,
            -0.019222136586904526,
            -0.0779992863535881,
            0.013931839726865292,
            -0.010924168862402439,
            0.03433983027935028,
            -0.01972963660955429,
            0.008483262732625008,
            -0.0015909328358247876,
            0.038400985300540924,
            -0.013002237305045128,
            -0.04236830025911331,
            0.00330173596739769,
            -0.0021383841522037983,
            0.029737720265984535,
            -0.0072679356671869755,
            -0.016260098665952682,
            0.012450691312551498,
            0.023802923038601875,
            0.050911735743284225,
            0.032193318009376526,
            0.001018230919726193,
            0.006650673225522041,
            -0.06328186392784119,
            -0.012917431071400642,
            0.021290890872478485,
            4.730151704279706e-05,
            0.030929142609238625,
            -0.008305401541292667,
            0.03448665142059326,
            -0.011522650718688965,
            -0.003530637826770544,
            -0.0028704777359962463,
            0.016696566715836525,
            -0.008258718065917492,
            -0.00471816211938858,
            -0.025719767436385155,
            0.024516049772500992,
            0.007419650908559561,
            -0.048924967646598816,
            -0.006340529303997755,
            0.022899368777871132,
            -0.021689575165510178,
            -0.004182634875178337,
            0.023324796929955482,
            -0.02303166314959526,
            0.014881924726068974,
            -0.018157249316573143,
            0.04292953759431839,
            -0.027812598273158073,
            -0.011608748696744442,
            0.0314289890229702,
            -0.01664738357067108,
            -0.024888508021831512,
            -0.025859322398900986,
            0.02442832477390766,
            0.0256846584379673,
            0.020129647105932236,
            0.0197023693472147,
            -0.01927567645907402,
            0.022957151755690575,
            0.05973213165998459,
            -0.014388385228812695,
            -0.012336375191807747,
            0.03150187432765961,
            -0.02899371087551117,
            0.00563273997977376,
            0.04204917699098587,
            0.026083821430802345,
            -0.00304804602637887,
            0.016685884445905685,
            0.012852377258241177,
            0.05653168633580208,
            0.00532594183459878,
            -0.00010796765855047852,
            0.004822154529392719,
            0.031254712492227554,
            -0.039506521075963974,
            0.027031144127249718,
            0.013819145038723946,
            -0.018999867141246796,
            0.013639003969728947,
            0.0106617771089077,
            -0.009265933185815811,
            0.009392405860126019,
            -0.005916181951761246,
            0.024223262444138527,
            0.0019830612000077963,
            -0.020426319912075996,
            -0.010721580125391483,
            -0.005903827026486397,
            -0.028578802943229675,
            -0.024544667452573776,
            -0.04016001150012016,
            0.006595512852072716,
            -0.026724444702267647,
            0.05013648048043251,
            -0.010278274305164814,
            0.024372179061174393,
            0.0327848419547081,
            0.006041788961738348,
            -0.06028907373547554,
            -0.012091415002942085,
            0.04449925944209099,
            0.005073952954262495,
            0.016581930220127106,
            -0.01169561967253685,
            0.016522865742444992,
            0.01959911175072193,
            0.017581619322299957,
            -0.017295854166150093,
            0.024609306827187538,
            -0.021407658234238625,
            0.06448908895254135,
            -0.022312525659799576,
            0.012251296080648899,
            0.0013608270091935992,
            -0.004376042168587446,
            0.02078721486032009,
            0.0033896833192557096,
            0.00467795180156827,
            -0.005752295721322298,
            -0.009536590427160263,
            0.015000189654529095,
            -0.033647358417510986,
            -0.03585135191679001,
            -0.022664187476038933,
            -0.017129996791481972,
            0.019273515790700912,
            -0.026065107434988022,
            0.007380334194749594,
            -0.029927853494882584,
            0.032845545560121536,
            0.0766158401966095,
            -0.004051424562931061,
            -0.03350059688091278,
            0.014264915138483047,
            -0.042833972722291946,
            -0.05139996111392975,
            0.021996930241584778,
            0.05276533588767052,
            -0.030323276296257973,
            -0.007586572784930468,
            -0.015515009872615337,
            -0.01022935938090086,
            0.0190776027739048,
            -0.01670098677277565,
            -0.017197323963046074,
            0.04082998260855675,
            0.01330681424587965,
            0.00529377069324255,
            0.007772133219987154,
            0.04858706519007683,
            -0.010040970519185066,
            -0.013241348788142204,
            0.024234581738710403,
            0.031751494854688644,
            0.008801702409982681,
            0.02174445614218712,
            0.009098004549741745,
            0.005029698833823204,
            0.01920476369559765,
            -0.02239556983113289,
            0.006368459668010473,
            0.03557998687028885,
            0.0011074289213865995,
            -0.0005714708822779357,
            -0.03792908787727356,
            0.003922730218619108,
            -0.047113724052906036,
            0.009764304384589195,
            0.06532701104879379,
            0.01775325834751129,
            -0.012522110715508461,
            0.011759649030864239,
            0.010077827610075474,
            0.0066023957915604115,
            0.013849441893398762,
            0.0023137314710766077,
            -0.004600561689585447,
            0.01718110591173172,
            -0.03818432614207268,
            -0.012949448078870773,
            -0.004629215225577354,
            -0.031184928491711617,
            0.06734015047550201,
            0.03377208113670349,
            -0.0009374225046485662,
            -0.014745868742465973,
            0.01558043621480465,
            0.015533922240138054,
            -0.02323194406926632,
            0.018244117498397827,
            0.000985163962468505,
            -0.008107949048280716,
            -0.0075554861687123775,
            -0.012065256014466286,
            0.004118792712688446,
            -0.020487552508711815,
            0.006965198554098606,
            -0.022941850125789642,
            0.006577583495527506,
            0.012419440783560276,
            -0.016924671828746796,
            0.023397551849484444,
            0.0484999418258667,
            -0.0041724639013409615,
            -0.00953900720924139,
            0.033510882407426834,
            -0.014296172186732292,
            -0.024134457111358643,
            0.04537582769989967,
            -0.003743812907487154,
            -0.0076346746645867825,
            -0.01617070846259594,
            -0.010080812498927116,
            -0.06311696022748947,
            -0.034553006291389465,
            0.023118160665035248,
            -0.02229129523038864,
            0.03818496689200401,
            0.015856938436627388,
            0.012823175638914108,
            0.042513683438301086,
            0.024851413443684578,
            -0.023835111409425735,
            0.03255932033061981,
            0.013023702427744865,
            -0.0023126571904867887,
            -0.009390772320330143,
            -0.023705076426267624,
            0.0030692131258547306,
            0.022897832095623016
        ],
        [
            -0.004585240967571735,
            -0.024013133719563484,
            -0.01992993988096714,
            -0.009855059906840324,
            0.021551920101046562,
            0.0264279767870903,
            -0.00030970628722570837,
            0.0008304298389703035,
            0.02566748484969139,
            -0.0028354243841022253,
            -0.0023772353306412697,
            -0.025185629725456238,
            0.015121308155357838,
            -0.010869498364627361,
            -0.04113423824310303,
            -0.005185330752283335,
            -0.008794499561190605,
            0.020092645660042763,
            -0.008729325607419014,
            0.002243091817945242,
            -0.003062040777876973,
            0.028434081003069878,
            -0.01785322278738022,
            0.0020990995690226555,
            -0.003418783890083432,
            -0.01520104892551899,
            -0.03791656717658043,
            -0.017610689625144005,
            -0.025889333337545395,
            0.03393876925110817,
            -0.015214074403047562,
            -0.031518325209617615,
            -0.02431153506040573,
            -0.0005175107507966459,
            0.009977197274565697,
            0.011622178368270397,
            0.03176078572869301,
            -0.016716301441192627,
            0.010339288972318172,
            0.03419286012649536,
            0.01992741785943508,
            0.012338433414697647,
            0.04212144762277603,
            0.02654792182147503,
            -0.021912122145295143,
            0.03643672913312912,
            0.02434740588068962,
            0.030383767560124397,
            -0.034059975296258926,
            -0.040274735540151596,
            -0.039076752960681915,
            -0.013346204534173012,
            -0.02811935544013977,
            0.013666904531419277,
            0.027501864358782768,
            0.009254693984985352,
            0.031661827117204666,
            0.011708928272128105,
            -0.014125140383839607,
            -0.004722288344055414,
            -0.03988223895430565,
            0.012573374435305595,
            0.01674548164010048,
            0.006964198313653469,
            0.018270904198288918,
            0.013731313869357109,
            0.007324454840272665,
            0.006645991466939449,
            -0.007305499166250229,
            -0.030250273644924164,
            0.007802088279277086,
            -0.013548286631703377,
            0.011957772076129913,
            0.011117656715214252,
            0.021012738347053528,
            0.03268972784280777,
            0.031970687210559845,
            -0.01214527990669012,
            -0.01986209861934185,
            0.005208153743296862,
            -0.010353359393775463,
            -0.03360024467110634,
            0.010470940731465816,
            0.0007758705178275704,
            0.0028377946000546217,
            0.04478868097066879,
            -0.009610451757907867,
            -0.011051340028643608,
            0.01785685308277607,
            0.034339793026447296,
            -0.03361525014042854,
            -0.012123984284698963,
            0.001734699122607708,
            -0.013350690715014935,
            -0.026833100244402885,
            -0.019647890701889992,
            -0.037097010761499405,
            -0.035326238721609116,
            -0.06845426559448242,
            -0.003915168810635805,
            -0.026450611650943756,
            0.02991260215640068,
            -0.05444130674004555,
            -0.011922714300453663,
            -0.039281215518713,
            0.010814575478434563,
            -0.0051387883722782135,
            -0.01570918969810009,
            0.004865352530032396,
            0.013127820566296577,
            -0.001241708523593843,
            -0.047352127730846405,
            -0.031399231404066086,
            -0.007545129396021366,
            0.015130804851651192,
            -0.020173849537968636,
            -0.0042296722531318665,
            -0.009451644495129585,
            0.02453959360718727,
            -0.00820066686719656,
            0.00775872590020299,
            0.0072444709949195385,
            -0.027164531871676445,
            -0.00427776575088501,
            -0.01633436791598797,
            0.02327652834355831,
            0.022597376257181168,
            0.01316116750240326,
            -0.02490045316517353,
            -0.002552538877353072,
            0.012159951031208038,
            0.017152205109596252,
            -0.010821298696100712,
            -0.03206678479909897,
            0.005687176715582609,
            -0.004012682940810919,
            0.037655308842659,
            -0.023112811148166656,
            0.023513544350862503,
            0.0341300405561924,
            0.015500699169933796,
            0.003175888443365693,
            0.01421742420643568,
            -0.0005567378830164671,
            0.002631303621456027,
            -0.001907662837766111,
            0.03821377083659172,
            -0.017073171213269234,
            -0.013712511397898197,
            -0.01149798184633255,
            0.004548031371086836,
            0.0183552298694849,
            -0.029072046279907227,
            0.012287081219255924,
            0.019062435254454613,
            0.02439459040760994,
            -0.0015107151120901108,
            0.021845011040568352,
            -0.007386629469692707,
            -0.00877812597900629,
            0.03194276615977287,
            -0.033478837460279465,
            -0.0005614383844658732,
            -0.005783091299235821,
            0.022566121071577072,
            -0.021805716678500175,
            -0.0026994841173291206,
            0.04150630161166191,
            -0.012580813840031624,
            0.015521067194640636,
            -0.017780831083655357,
            -0.02240995690226555,
            -0.036202963441610336,
            0.02911447174847126,
            0.004622839856892824,
            -0.006876183673739433,
            0.023564664646983147,
            -0.0036355890333652496,
            0.008632577955722809,
            0.007855750620365143,
            0.012456364929676056,
            -0.027331680059432983,
            -0.04006292298436165,
            -0.03566899523139,
            -0.01579539105296135,
            -0.0031326590105891228,
            0.010330591350793839,
            -0.018929511308670044,
            -0.004548272117972374,
            -0.019800826907157898,
            0.016471948474645615,
            0.007720140274614096,
            -0.012194538488984108,
            0.0059173996560275555,
            -0.033109791576862335,
            0.002986781531944871,
            0.028434157371520996,
            -0.012194935232400894,
            0.01727798394858837,
            0.014635836705565453,
            -0.01087537407875061,
            0.0029946041759103537,
            0.05023200809955597,
            0.026811223477125168,
            -0.08411981910467148,
            -0.007598252035677433,
            -0.025574330240488052,
            0.030722469091415405,
            0.013269082643091679,
            0.015429098159074783,
            0.022802595049142838,
            0.04627783223986626,
            0.0007790661766193807,
            0.02803967520594597,
            -0.022726956754922867,
            -0.02595718763768673,
            0.02036835066974163,
            0.025258485227823257,
            -0.026383787393569946,
            -0.014364472590386868,
            -0.010842736810445786,
            -0.020308440551161766,
            0.013541433028876781,
            0.003805149346590042,
            0.01563139818608761,
            -0.012931724078953266,
            0.013545420952141285,
            -0.03868521749973297,
            -0.002140602096915245,
            -0.0035027607809752226,
            0.0029087618459016085,
            0.039480507373809814,
            0.03776738420128822,
            -0.00455788429826498,
            0.014773782342672348,
            0.03982832282781601,
            -0.015341884456574917,
            -0.009940942749381065,
            -0.0050332010723650455,
            0.026290996000170708,
            0.0006732496549375355,
            0.007009780500084162,
            0.023482292890548706,
            0.015000658109784126,
            -0.0006663998938165605,
            0.027989501133561134,
            0.003378292080014944,
            0.02755335159599781,
            -0.022394413128495216,
            0.010607665404677391,
            0.019282864406704903,
            0.034947432577610016,
            -0.04951152577996254,
            0.009431053884327412,
            0.022301597520709038,
            -0.002454274334013462,
            0.000531278143171221,
            -0.006687016226351261,
            -0.001835106173530221,
            -0.031112106516957283,
            0.04814109578728676,
            0.013781377114355564,
            -0.071921706199646,
            0.0005157497362233698,
            0.00521000474691391,
            -0.003353813895955682,
            0.022207899019122124,
            0.003998101223260164,
            -0.01418131124228239,
            0.04493024945259094,
            0.010525550693273544,
            -0.01694023609161377,
            0.007156768813729286,
            -0.013960033655166626,
            -0.025727247819304466,
            0.03534809499979019,
            0.039605312049388885,
            0.0067818001843988895,
            0.00546561973169446,
            0.0009186692186631262,
            -0.00713869696483016,
            -0.0227883979678154,
            0.022929327562451363,
            0.02000824548304081,
            0.031297191977500916,
            -0.0012073992984369397,
            0.0022051092237234116,
            -0.0003718172083608806,
            0.013790320605039597,
            0.006977719254791737,
            -0.020556408911943436,
            -0.005382873117923737,
            -0.00706374179571867,
            0.009114125743508339,
            0.002839267486706376,
            -0.027904260903596878,
            -0.008046980015933514,
            -0.015813853591680527,
            -0.013766494579613209,
            -0.017702050507068634,
            0.006172498222440481,
            -0.031573548913002014,
            -0.016080722212791443,
            0.012235196307301521,
            -0.007327225990593433,
            0.02339484542608261,
            0.02056918852031231,
            0.047344014048576355,
            -0.019601648673415184,
            -0.003391049802303314,
            0.0029222441371530294,
            -0.002535739680752158,
            -0.022975122556090355,
            -0.016014982014894485,
            -0.02246399223804474,
            0.023252055048942566,
            0.009703879244625568,
            0.018846528604626656,
            -0.027121812105178833,
            0.0061432598158717155,
            -0.01628984697163105,
            0.016058625653386116,
            0.017297288402915,
            -0.009758438915014267,
            -0.012007743120193481,
            0.010757654905319214,
            -0.02255367487668991,
            0.0017573190852999687,
            -0.012266462668776512,
            0.013412636704742908,
            -0.004542415961623192,
            0.014411953277885914,
            -0.0197160504758358,
            0.009946811944246292,
            -0.026186268776655197,
            0.012451572343707085,
            0.03363491967320442,
            0.0013485226081684232,
            -0.009395387023687363,
            0.015536412596702576,
            -0.010941951535642147,
            0.022064095363020897,
            0.02550719492137432,
            0.016108356416225433,
            0.02355322241783142,
            -0.023034781217575073,
            -0.03365761414170265,
            0.005585670471191406,
            -0.01458827592432499,
            -0.007301785051822662,
            -0.07061678916215897,
            0.038894206285476685,
            -0.026588741689920425,
            -0.015564631670713425,
            -0.03388214111328125,
            0.0032856385223567486,
            0.02884114719927311,
            0.049361247569322586,
            -0.00813400000333786,
            -0.04921410232782364,
            0.02328953891992569,
            0.0262789074331522,
            -0.0162523053586483,
            -0.016388842836022377,
            0.0021417434327304363,
            0.03364787995815277,
            -0.027466338127851486,
            -0.00441779987886548,
            0.017894411459565163,
            0.010556853376328945,
            0.04005241021513939,
            0.011801776476204395,
            -0.0026599066331982613,
            -0.021303078159689903,
            0.005422684364020824,
            0.0008679202874191105,
            0.028384625911712646,
            0.007364003919064999,
            0.015338045544922352,
            0.007020809222012758,
            -0.006422011181712151,
            -0.02555367909371853,
            0.025658583268523216,
            0.024052081629633904,
            0.010301167145371437,
            0.0234101340174675,
            0.017811406403779984,
            0.04468813166022301,
            -0.0349472239613533,
            0.015727199614048004,
            -0.002731157001107931,
            -0.029182415455579758,
            0.021461529657244682,
            0.032976288348436356,
            -0.017859460785984993,
            -0.027986375615000725,
            0.03073670156300068,
            0.014760817401111126,
            -0.0009048637584783137,
            -0.03923780098557472,
            0.01478735450655222,
            0.019204745069146156,
            -0.02397378534078598,
            -0.015188482590019703,
            -0.004276052117347717,
            -0.03564474731683731,
            -0.02340639755129814,
            0.03393182530999184,
            -0.017738301306962967,
            -0.014827931299805641,
            -0.0158504880964756,
            -0.02570677176117897,
            -0.013197232969105244,
            0.015937477350234985,
            -0.015908528119325638,
            0.001886093057692051,
            0.019699381664395332,
            -0.0033862609416246414,
            0.02692412957549095,
            -0.018616870045661926,
            0.055689193308353424,
            -0.012070518918335438,
            -0.039369188249111176,
            -0.006190096028149128,
            -0.013866997323930264,
            -0.06491108238697052,
            0.020530804991722107,
            0.05691393464803696,
            0.019967438653111458,
            0.031320951879024506,
            -0.016553815454244614,
            -0.0024181329645216465,
            0.029915671795606613,
            0.019003281369805336,
            -0.012000578455626965,
            0.016075899824500084,
            0.031081872060894966,
            -0.0414232462644577,
            0.020446425303816795,
            0.027802294120192528,
            0.013304969295859337,
            0.026209916919469833,
            -0.006590086035430431,
            0.016130700707435608,
            -0.02671242505311966,
            -0.030481237918138504,
            -0.020719243213534355,
            -0.06355085223913193,
            0.002118145814165473,
            -0.017141444608569145,
            0.025982458144426346,
            -0.03077402338385582,
            0.03973536193370819,
            0.0076945628970861435,
            0.010159773752093315,
            -0.01362441573292017,
            0.011379179544746876,
            -0.03824416548013687,
            -0.004304965026676655,
            0.036031194031238556,
            0.025850975885987282,
            -0.005398693960160017,
            0.001125355251133442,
            -0.000998706673271954,
            -0.008844029158353806,
            -0.03405764698982239,
            0.03277471289038658,
            -0.0012498252326622605,
            -0.008775891736149788,
            -0.023849785327911377,
            -0.016719544306397438,
            -0.017275484278798103,
            0.011242872104048729,
            0.03515605255961418,
            0.025969170033931732,
            0.038869086652994156,
            0.025035424157977104,
            -0.002628834219649434,
            0.018717467784881592,
            0.009476982988417149,
            -0.040224794298410416,
            0.028603969141840935,
            -0.06754372268915176,
            0.00079437525710091,
            0.011921467259526253,
            0.025393890216946602,
            -0.02589450590312481,
            0.015125595033168793,
            0.008351793512701988,
            0.010038965381681919,
            -0.021434994414448738,
            -0.00019658627570606768,
            -0.004754174500703812,
            -0.031767960637807846,
            0.007835476659238338,
            0.016395358368754387,
            0.05459520220756531,
            -0.002555787330493331,
            0.007168575190007687,
            0.005438475403934717,
            0.0289418026804924,
            0.030911004170775414,
            0.011797979474067688,
            0.03233454376459122,
            -0.01936919055879116,
            0.004609448369592428,
            -0.030391311272978783,
            0.043681591749191284,
            0.010655540972948074,
            -0.021174900233745575,
            0.015532434917986393,
            0.05280718579888344,
            0.01257151085883379,
            -0.0016286084428429604,
            -0.010756351985037327,
            -0.024266185238957405,
            -0.041691310703754425,
            -0.01322962623089552,
            -0.0364665761590004,
            -0.03909706696867943,
            -0.006893317215144634,
            0.014555773697793484,
            0.012287585996091366,
            -0.018551113083958626,
            -0.03170572221279144,
            -0.01890634000301361,
            -0.04961412772536278,
            0.004581840708851814,
            0.008679687045514584,
            -0.026394274085760117,
            -0.023046378046274185,
            0.017307355999946594,
            0.02603163570165634,
            -0.00825006328523159,
            -0.007174837868660688,
            -0.021083829924464226,
            -0.022641967982053757,
            0.030722036957740784,
            0.02836083061993122,
            0.019708838313817978,
            0.01058132853358984,
            0.005141394678503275,
            0.00012321278336457908,
            0.035459913313388824,
            0.020097116008400917,
            0.049748025834560394,
            -0.018796592950820923,
            -0.00585572374984622,
            0.0018215728923678398,
            0.02227284573018551,
            -0.009774486534297466,
            0.011973001062870026,
            -6.701797246932983e-05,
            0.011757642030715942,
            0.009636612609028816,
            0.010796687565743923,
            -0.015501948073506355,
            0.012413864023983479,
            -0.007690402213484049,
            0.022011054679751396,
            -0.0057932790368795395,
            -0.016037793830037117,
            -0.028654934838414192,
            0.0522596500813961,
            -0.029532384127378464,
            -0.008607576601207256,
            -0.011531607247889042,
            -0.027138054370880127,
            -0.011792690493166447,
            0.033313263207674026,
            0.004665449261665344,
            0.0052582742646336555,
            0.016716865822672844,
            -0.01579085923731327,
            -0.008300302550196648,
            0.016856230795383453,
            0.01848454959690571,
            -0.012800944969058037,
            0.019627301022410393,
            -0.0024892566725611687,
            0.0018202560022473335,
            0.035174548625946045,
            0.010328877717256546,
            0.0051168836653232574,
            0.013347803615033627,
            -0.005613945424556732,
            0.024482309818267822,
            -0.01609846018254757,
            -8.036976942094043e-05,
            0.016960788518190384,
            -0.007299738470464945,
            -0.004936993587762117,
            -0.0009432812803424895,
            -0.004785709548741579,
            -0.011174276471138,
            0.014523187652230263,
            -0.022451017051935196,
            -0.0029342833440750837,
            0.02784413844347,
            0.035633206367492676,
            0.0064998529851436615,
            -0.014276916161179543,
            0.01759161800146103,
            0.019135257229208946,
            -0.018916914239525795,
            0.0015069700311869383,
            0.02821527048945427,
            0.018645361065864563,
            0.03659423813223839,
            -0.007642457727342844,
            -0.001481388695538044,
            -0.0004988684086129069,
            -0.026412013918161392,
            0.0073676807805895805,
            -0.011659164912998676,
            0.011926982551813126,
            -0.015344048850238323,
            0.00040820229332894087,
            0.014826389029622078,
            -0.006895775906741619,
            -0.02591812051832676,
            -0.008042261935770512,
            -0.0049993060529232025,
            -0.01999039202928543,
            -0.01566307432949543,
            0.035903606563806534,
            0.021512500941753387,
            0.032452233135700226,
            -0.0022896057926118374,
            -0.03787307068705559,
            0.003100936533883214,
            -0.00589384650811553,
            0.005504597444087267,
            0.02899905852973461,
            0.03726734593510628,
            -0.026483934372663498,
            -0.016004903241991997,
            0.004908036906272173,
            0.0282764695584774,
            0.012257968075573444,
            -0.0005556366522796452,
            0.038754843175411224,
            -0.009593936614692211,
            9.12613031687215e-05,
            -0.012328025884926319,
            -0.014530785381793976,
            0.01681751012802124,
            -0.01941254548728466,
            0.009385274723172188,
            0.005944066680967808,
            0.02312508039176464,
            0.03181380033493042,
            0.006691160146147013,
            -0.025337938219308853,
            0.017665065824985504,
            -0.03026438318192959,
            0.002928932663053274,
            0.01783178560435772,
            0.04547036066651344,
            -0.015932640060782433,
            0.0017604187596589327,
            0.0322362519800663,
            0.031078075990080833,
            0.03399277478456497,
            -0.0028826037887483835,
            0.0032354318536818027,
            -0.019676143303513527,
            0.029130874201655388,
            -0.027387794107198715,
            -0.007038344629108906,
            -0.0363888218998909,
            0.04759283363819122,
            -0.02661694400012493,
            -0.020665492862462997,
            0.0061423988081514835,
            0.00407214742153883,
            0.0069946651346981525,
            -0.020320013165473938,
            -0.0003130233380943537,
            0.013403263874351978,
            0.04127265140414238,
            -0.009737392887473106,
            0.03221474215388298,
            0.033379100263118744,
            -0.0020317251328378916,
            -0.0042557790875434875,
            0.004139479249715805,
            -0.04085153713822365,
            -0.017852570861577988,
            0.010652178898453712,
            0.03503674641251564,
            0.01865355670452118,
            0.035459231585264206,
            -0.0030269313137978315,
            -0.026438573375344276,
            -0.013162740506231785,
            0.021672150120139122,
            0.03369298577308655,
            -0.02168026939034462,
            -0.014229409396648407,
            -0.02219967357814312,
            -0.011816431768238544,
            0.039775509387254715,
            0.012051763944327831,
            -0.045871298760175705,
            0.022205324843525887,
            0.01072182971984148,
            -0.03133649379014969,
            -0.007070731371641159,
            -0.022384274750947952,
            -0.009455719962716103,
            0.01627049408853054,
            -0.03952263668179512,
            -0.011084464378654957,
            0.019016297534108162,
            -0.010417579673230648,
            -0.08795769512653351,
            0.05004527047276497,
            -0.005129384808242321,
            -0.021298786625266075,
            0.02493242174386978,
            -0.014672920107841492,
            0.032908111810684204,
            0.02876131236553192,
            -0.004481867887079716,
            -0.005308341700583696,
            -0.010063872672617435,
            -0.028615064918994904,
            0.020421652123332024,
            0.006070127710700035,
            -0.001522391103208065,
            0.04317609965801239,
            0.030278807505965233,
            0.015282995067536831,
            0.026330435648560524,
            -0.015741555020213127,
            -0.026334386318922043,
            -0.02856079488992691,
            -0.022446950897574425,
            0.00687070470303297,
            -0.010570550337433815,
            0.0015214838786050677,
            0.020894328132271767,
            0.009386291727423668,
            0.00044392034760676324,
            -0.04303288459777832,
            -0.02406996674835682,
            -0.014149775728583336,
            -0.010611923411488533,
            0.008851154707372189,
            0.03286419436335564,
            0.01223166286945343,
            0.010444450192153454,
            0.012425311841070652,
            -0.032092273235321045,
            0.010064939968287945,
            -0.0025623771362006664,
            0.014692888595163822,
            0.00772645091637969,
            -0.006447426974773407,
            0.033384304493665695,
            -0.02246943861246109,
            0.033124443143606186,
            0.015506134368479252,
            0.02381153032183647,
            -0.012051661498844624,
            -0.012161500751972198,
            -0.028536416590213776,
            0.03501332923769951,
            -0.03018536977469921,
            -0.02053462155163288,
            -0.019364235922694206,
            0.015352069400250912,
            -0.06082526966929436,
            -0.019685490056872368,
            0.02713748812675476,
            -0.03306398168206215,
            0.03360297903418541,
            0.0045670135878026485,
            0.008165857754647732,
            -0.0003432496450841427,
            -0.025143306702375412,
            0.03159378841519356,
            -0.018483895808458328,
            -0.018873276188969612,
            0.0021763506811112165,
            -0.027517154812812805,
            -0.0013610083842650056,
            -0.012379582040011883,
            0.0430937223136425,
            -0.00828523188829422,
            -0.005358248017728329,
            -0.012321091257035732,
            -0.010082781314849854,
            -0.0677015408873558,
            -0.01299971342086792,
            0.03202875703573227,
            0.01718726009130478,
            -0.03291310742497444,
            0.036878664046525955,
            0.027925003319978714,
            -0.010271476581692696,
            -0.009280337020754814,
            -0.0048345657996833324,
            0.009394326247274876,
            0.0024757736828178167,
            0.019509630277752876,
            -0.02898784726858139,
            0.022480640560388565,
            -0.008633295074105263,
            -0.032798051834106445,
            -0.058047499507665634,
            0.010617547668516636,
            -0.019084887579083443,
            0.02334541454911232,
            -0.03218960762023926,
            0.012340174987912178,
            0.0024495318066328764,
            0.013987206853926182,
            0.04282546415925026,
            -0.002670568646863103,
            -0.014171392656862736,
            0.031196776777505875,
            -0.025921909138560295,
            -0.0028895195573568344,
            0.042565423995256424,
            -0.014082439243793488,
            0.006234853062778711,
            -0.0009251291630789638,
            -0.017377767711877823,
            -0.020217927172780037,
            -0.010136092081665993,
            0.009631594642996788,
            -0.014123156666755676,
            0.02244439162313938,
            -0.019721411168575287,
            -0.01689566858112812,
            -0.02244294062256813,
            -0.029785331338644028,
            0.037279192358255386,
            -0.021935921162366867,
            0.0032328462693840265,
            0.0004879258922301233,
            0.005018696654587984,
            0.020867807790637016,
            -0.003767941379919648,
            0.0035376751329749823,
            0.030019938945770264,
            -0.035121843218803406,
            -0.024302925914525986,
            -0.027744807302951813,
            0.007369424216449261,
            0.007448712829500437,
            -0.02281608246266842,
            0.02688710018992424,
            0.020410478115081787,
            0.01992424950003624,
            0.014386884868144989,
            0.01696736551821232,
            -0.0018614791333675385,
            0.04550487920641899,
            -0.0018372853519394994,
            0.031708043068647385,
            0.048768073320388794,
            0.03558142110705376,
            0.022129317745566368,
            0.019079916179180145,
            -0.00765205966308713,
            -0.030745649710297585,
            0.0001924596872413531,
            0.023142484948039055,
            0.009163609705865383,
            -0.07175565510988235,
            -0.008512197993695736,
            0.03812551870942116,
            0.0017051304457709193,
            0.019634494557976723,
            -0.04089164733886719,
            0.03264231979846954,
            0.006230719853192568,
            0.006669990718364716,
            -0.002269482472911477,
            -0.029692890122532845,
            -0.0005016578361392021,
            0.00876611564308405,
            -0.01512844953685999,
            0.016869502142071724,
            0.004223869182169437,
            -0.0024544887710362673,
            -0.003690868616104126,
            0.019244180992245674,
            0.023080943152308464,
            0.00779589032754302,
            0.044177766889333725,
            -0.011139417998492718,
            0.007939208298921585,
            0.026664413511753082,
            -0.02078290842473507,
            -0.0006953303818590939,
            0.047033268958330154,
            0.040771789848804474,
            -0.014228393323719501,
            -0.001990280346944928,
            0.00814316887408495,
            -0.015789004042744637,
            -0.04947834461927414,
            0.034745316952466965,
            0.032067589461803436,
            0.010775190778076649,
            -0.011682159267365932,
            0.01662270352244377,
            0.004326005466282368,
            0.004896793980151415,
            -0.022880293428897858,
            0.037092193961143494,
            0.0014784878585487604,
            0.007755272556096315,
            -0.015332375653088093,
            -0.014858853071928024,
            -0.018200702965259552,
            -0.02181209996342659,
            -0.03127409145236015,
            0.020352816209197044,
            -0.027355952188372612,
            0.016598962247371674,
            -0.012923766858875751,
            0.0036739581264555454,
            0.039552029222249985,
            0.027544355019927025,
            0.01848696731030941,
            0.0072159916162490845,
            -0.025445297360420227,
            -0.020105591043829918,
            0.01990332640707493,
            0.013776120729744434,
            0.023816358298063278,
            -0.0247061587870121,
            0.026970986276865005,
            -0.02958873100578785,
            -0.018281348049640656,
            0.024704400449991226,
            -0.0158795528113842,
            0.02136940322816372,
            0.012555004097521305,
            0.01570991985499859,
            -0.024279402568936348,
            -0.0008115017553791404,
            -0.012580765411257744,
            0.008578963577747345,
            -0.026583461090922356,
            -0.07071293890476227,
            0.02778421714901924,
            0.0163434986025095,
            -0.009350353851914406,
            -4.0762148273643106e-05,
            -0.007420790381729603,
            0.007302799727767706,
            0.00539444899186492,
            -0.057817861437797546,
            0.0340324342250824,
            -0.007564207073301077,
            -0.03454181179404259,
            -0.010807245969772339,
            0.00021341971296351403,
            -0.014688846655189991,
            0.027344204485416412,
            0.04163394123315811,
            0.00889119878411293,
            -0.07908543944358826,
            0.012805650942027569,
            -0.02097306028008461,
            0.003579639596864581,
            -0.035176780074834824,
            -0.0235135555267334,
            -0.0047644563019275665,
            -0.007273416966199875,
            -0.03898878023028374,
            -0.027300197631120682,
            -0.02427939884364605,
            -0.026727639138698578,
            0.006677128840237856,
            -0.004717385862022638,
            -0.009142212569713593,
            -0.021949023008346558,
            -0.02181350067257881,
            0.018478447571396828,
            0.04606408253312111,
            0.015683291479945183,
            -0.006482062861323357,
            -0.011347431689500809,
            -0.023473696783185005,
            -0.00847610179334879,
            0.009689840488135815,
            0.026573438197374344,
            0.010682236403226852,
            -0.05893252044916153,
            0.02439171075820923,
            0.01907113753259182,
            -0.014835705049335957,
            -0.04419577494263649,
            -0.016629526391625404,
            -0.008970134891569614,
            0.014955750666558743,
            -0.019736671820282936,
            -0.04270530119538307,
            0.0039990623481571674,
            -0.018365398049354553,
            -0.003458427032455802,
            0.017117539420723915,
            0.03606816753745079,
            0.035539571195840836,
            -0.017214765772223473,
            0.012537216767668724,
            0.012662644498050213,
            0.013504412956535816,
            0.01786268875002861,
            0.0189910139888525,
            0.0035979468375444412,
            0.03313978761434555,
            0.02755557745695114,
            -0.03623173385858536,
            -0.00467556482180953,
            0.011044220067560673,
            0.017105503007769585
        ],
        [
            -0.023437432944774628,
            0.019370773807168007,
            -0.02380509115755558,
            -0.010185176506638527,
            -0.005247277207672596,
            -0.025246255099773407,
            -0.019234225153923035,
            0.027247507125139236,
            -0.02259022928774357,
            -0.003935785964131355,
            0.022973692044615746,
            0.011414301581680775,
            -0.025867382064461708,
            0.015277208760380745,
            0.019610047340393066,
            -0.010957525111734867,
            0.004679519683122635,
            -0.016753632575273514,
            -0.044063501060009,
            0.022835854440927505,
            0.04320620000362396,
            0.030842570587992668,
            -0.023794567212462425,
            -0.001700098509900272,
            0.02815006859600544,
            0.0032080071978271008,
            0.014094049111008644,
            0.006853456608951092,
            0.0051428875885903835,
            -0.01785420812666416,
            0.014464803040027618,
            0.007637879811227322,
            0.017135871574282646,
            -0.010951393283903599,
            -0.00784946046769619,
            0.028025509789586067,
            -0.003367595374584198,
            0.03260980546474457,
            0.008425649255514145,
            0.028995497152209282,
            0.04479864612221718,
            0.03489221632480621,
            -0.004081062972545624,
            0.004436882212758064,
            -0.004131725057959557,
            0.00044467367115430534,
            -0.020015649497509003,
            0.010973305441439152,
            -0.01767919398844242,
            0.0051583461463451385,
            -0.015204936265945435,
            0.02415928989648819,
            0.041813597083091736,
            -8.93195319804363e-05,
            -0.03431520238518715,
            -0.01218597311526537,
            -0.007160401903092861,
            0.0327262356877327,
            -0.022487280890345573,
            0.005576637573540211,
            0.026816988363862038,
            0.020091766491532326,
            0.001681808615103364,
            -0.004999865312129259,
            0.010048903524875641,
            -0.015866363421082497,
            0.010576406493782997,
            0.01210204791277647,
            -0.013555062003433704,
            -0.019909488037228584,
            -0.017759600654244423,
            -0.008164058439433575,
            -0.027382323518395424,
            0.01915637217462063,
            0.029744315892457962,
            0.005467701703310013,
            0.0007885899394750595,
            -0.010537216439843178,
            0.04768722504377365,
            -0.007331971079111099,
            0.024140551686286926,
            0.005306008271872997,
            0.02912011742591858,
            0.01955789513885975,
            0.011661073192954063,
            -0.022755637764930725,
            -0.009290729649364948,
            -0.0326339527964592,
            -0.014030775986611843,
            -0.008260117843747139,
            -0.002274194033816457,
            -0.008014854975044727,
            0.014230216853320599,
            -0.018787669017910957,
            -0.0065437788143754005,
            -0.004279728978872299,
            -0.013857957907021046,
            -0.009686104021966457,
            0.05088139325380325,
            0.011062730103731155,
            0.01076593343168497,
            -0.038450002670288086,
            0.030705450102686882,
            0.024453358724713326,
            -0.003333041677251458,
            0.010676052421331406,
            0.02307768352329731,
            -0.007138563320040703,
            0.014854908920824528,
            -0.004481774754822254,
            0.012775332666933537,
            -0.01943262852728367,
            0.0020907737780362368,
            0.020682940259575844,
            -0.022601962089538574,
            -0.0013270735507830977,
            0.011966325342655182,
            0.014260571449995041,
            -0.02910490892827511,
            -0.020731065422296524,
            0.021751193329691887,
            0.0020217278506606817,
            0.02319718338549137,
            -0.00010397319420007989,
            -0.01943473517894745,
            0.016082080081105232,
            -0.0054007298313081264,
            0.030755382031202316,
            0.03670499846339226,
            0.005073809530586004,
            0.006313384976238012,
            -0.013258786872029305,
            -0.0377664715051651,
            0.012196458876132965,
            0.0012436339166015387,
            0.02717091143131256,
            0.04089144617319107,
            -0.040851347148418427,
            0.010287579149007797,
            -0.019352037459611893,
            0.008038965053856373,
            0.03399736434221268,
            0.014582565985620022,
            -0.023776397109031677,
            -0.009663648903369904,
            0.016082363203167915,
            -0.0024573346599936485,
            0.011542816646397114,
            0.03184659406542778,
            -0.029415082186460495,
            0.005543726496398449,
            0.00247019762173295,
            -0.015140832401812077,
            -0.020497530698776245,
            -0.009367709048092365,
            -0.011272159405052662,
            -0.04065851867198944,
            -0.003672143677249551,
            0.025079483166337013,
            0.045428965240716934,
            0.0198456309735775,
            -0.01977180875837803,
            0.004241053014993668,
            0.006908647250384092,
            0.02832704782485962,
            0.00245205988176167,
            -0.009047693572938442,
            0.013950496912002563,
            8.917903323890641e-05,
            0.024948062375187874,
            0.018970578908920288,
            0.01455376110970974,
            0.002543781418353319,
            -0.01456497609615326,
            -0.03347450867295265,
            0.008931560441851616,
            0.01103227399289608,
            -0.01693752408027649,
            -0.015146231278777122,
            -0.01325734332203865,
            -0.020002329722046852,
            -0.022174621000885963,
            -0.033266086131334305,
            0.012356266379356384,
            0.003456120379269123,
            0.028030432760715485,
            -0.010264914482831955,
            -0.0070565189234912395,
            0.0145103195682168,
            -0.01861030049622059,
            -0.02466125786304474,
            -0.005120316054672003,
            0.010061085224151611,
            0.0021823891438543797,
            0.030141500756144524,
            0.01429534237831831,
            0.014000280760228634,
            0.03360002860426903,
            -0.02841632440686226,
            0.033157844096422195,
            0.00042589352233335376,
            0.00020521973783615977,
            0.026077697053551674,
            0.01858844980597496,
            0.04086560755968094,
            0.02401597797870636,
            0.012686589732766151,
            0.018425079062581062,
            0.020072052255272865,
            0.0037004572805017233,
            0.0031959102489054203,
            -0.031315550208091736,
            -0.02165660262107849,
            0.024004099890589714,
            -0.013581676408648491,
            0.00621180422604084,
            0.018648745492100716,
            -0.005424838978797197,
            0.030752386897802353,
            -0.030439097434282303,
            0.012995698489248753,
            0.013581093400716782,
            0.013217349536716938,
            0.019511472433805466,
            -0.023042701184749603,
            -0.04186622425913811,
            -0.028165755793452263,
            0.01635521464049816,
            0.028712302446365356,
            -0.01855071634054184,
            0.03618334233760834,
            -0.018040217459201813,
            -0.003659294918179512,
            0.026745518669486046,
            0.020176177844405174,
            -0.016869017854332924,
            -0.04474719241261482,
            0.03612130507826805,
            -0.017724845558404922,
            -0.012423673644661903,
            -0.02830282412469387,
            0.012180771678686142,
            -0.0047476934269070625,
            0.006853432394564152,
            -0.001720819273032248,
            0.014420405961573124,
            0.015225701034069061,
            0.00532997353002429,
            0.0003610671847127378,
            -0.0029316372238099575,
            -0.03170781955122948,
            -0.02386126108467579,
            -0.010210471227765083,
            0.010807967744767666,
            -0.01694345660507679,
            0.0246683731675148,
            -0.01992662250995636,
            0.005711276084184647,
            -0.005071599036455154,
            -0.0022918805480003357,
            -0.014636374078691006,
            0.028450144454836845,
            -0.00376326497644186,
            0.018603485077619553,
            0.010350719094276428,
            -0.0222166758030653,
            0.007690872065722942,
            0.01679578796029091,
            0.0036413739435374737,
            0.013689675368368626,
            0.026119234040379524,
            -0.021213702857494354,
            0.01838834583759308,
            -0.013078615069389343,
            0.02548316866159439,
            -0.026493258774280548,
            -0.005285593215376139,
            0.00216274312697351,
            -0.02620922029018402,
            0.00702302111312747,
            -0.018406590446829796,
            0.004792806692421436,
            0.029973331838846207,
            -0.030159760266542435,
            0.02835889905691147,
            0.027580318972468376,
            0.03213338181376457,
            -0.04460638388991356,
            -0.010458189062774181,
            0.030055323615670204,
            -0.006193811539560556,
            -0.03854137659072876,
            -0.016686979681253433,
            0.010345813818275928,
            -0.018230173736810684,
            0.03378726541996002,
            0.0065457457676529884,
            0.019847361370921135,
            0.01655399054288864,
            0.016062436625361443,
            0.029779991135001183,
            0.014085162431001663,
            0.035448044538497925,
            0.02966121770441532,
            0.01975865662097931,
            -0.022771846503019333,
            -0.03907134383916855,
            -0.017896030098199844,
            0.03153618425130844,
            -0.02692200057208538,
            -0.008600624278187752,
            -0.007285102270543575,
            -0.0183811467140913,
            0.026794785633683205,
            0.00981353409588337,
            -0.00904061459004879,
            0.024961670860648155,
            0.012611359357833862,
            -0.007864857092499733,
            0.012820628471672535,
            0.024356868118047714,
            0.030023930594325066,
            -0.04510178789496422,
            -0.011320850811898708,
            0.03883042186498642,
            0.030600236728787422,
            0.045310426503419876,
            0.013901582919061184,
            -0.025086043402552605,
            0.013010868802666664,
            0.008105793967843056,
            0.02577640488743782,
            0.02146793343126774,
            -0.0038030482828617096,
            0.012018898501992226,
            -0.011030295863747597,
            0.02127588540315628,
            -0.018375420942902565,
            0.010812790133059025,
            -0.02852718159556389,
            0.007583290804177523,
            -0.004897074308246374,
            -0.00627950020134449,
            -0.02143358439207077,
            -0.014281557872891426,
            -0.03767266869544983,
            -0.014925154857337475,
            0.01996721886098385,
            0.02510545775294304,
            -0.012142901308834553,
            0.04429609701037407,
            0.0021635040175169706,
            -0.010459145531058311,
            -0.011264956556260586,
            -0.0003813542425632477,
            -0.006960576865822077,
            0.011579418554902077,
            0.028931716457009315,
            -0.00843788031488657,
            0.03141900897026062,
            -0.020147033035755157,
            0.033890750259160995,
            0.0013554105535149574,
            0.018931278958916664,
            -0.0411219485104084,
            -0.01069173775613308,
            -0.022704916074872017,
            0.002121823141351342,
            -0.008796701207756996,
            -0.00281472597271204,
            0.029724296182394028,
            -0.0038786199875175953,
            -0.045512642711400986,
            0.010468185879290104,
            -0.018143637105822563,
            -0.04082890599966049,
            -0.028509117662906647,
            -0.01838550716638565,
            -0.02157096192240715,
            0.01316103432327509,
            -0.012944702990353107,
            -0.007211322896182537,
            -0.01696232706308365,
            -0.02632979303598404,
            -0.0033119034487754107,
            -0.004803354851901531,
            0.003487619571387768,
            -0.012664290145039558,
            -0.018572496250271797,
            0.006122298073023558,
            -0.0013988101854920387,
            -0.0073347147554159164,
            0.023460613563656807,
            -2.8289765396039e-05,
            -0.05309028550982475,
            -0.013710594736039639,
            -0.011531157419085503,
            0.017388978973031044,
            0.022205010056495667,
            -0.05093296617269516,
            -0.017583416774868965,
            0.020145196467638016,
            0.0020785226952284575,
            0.012418772093951702,
            -0.00606095464900136,
            -0.00962254498153925,
            0.0332736074924469,
            -0.03769607096910477,
            -0.01317878719419241,
            0.01632685400545597,
            0.007945959456264973,
            -0.0014491052133962512,
            -0.02564103901386261,
            0.006761547643691301,
            0.009523308835923672,
            -0.004950766917318106,
            0.006473594345152378,
            0.029841244220733643,
            -0.038018323481082916,
            -0.022724635899066925,
            0.0029896877240389585,
            -0.012410888448357582,
            -0.004156796727329493,
            -0.0025485039222985506,
            -0.017024526372551918,
            -0.01597178727388382,
            0.014825364574790001,
            -0.04284489154815674,
            0.028320712968707085,
            0.02942487597465515,
            0.020058022812008858,
            -0.023248594254255295,
            -0.025540290400385857,
            0.011225845664739609,
            -0.022845230996608734,
            0.02133902907371521,
            -0.03134041279554367,
            -0.026982786133885384,
            0.02211717516183853,
            -0.009818471036851406,
            -0.01427930686622858,
            -0.024203075096011162,
            -0.011355794034898281,
            -0.016644729301333427,
            0.017861034721136093,
            0.010267304256558418,
            0.008586151525378227,
            0.06683070957660675,
            0.017579708248376846,
            -0.03503378853201866,
            0.024287670850753784,
            0.026965199038386345,
            0.02555854432284832,
            0.0015079855220392346,
            0.002244421048089862,
            -0.009570389054715633,
            0.01824294589459896,
            0.0011942649725824594,
            0.038743048906326294,
            -0.02471325732767582,
            0.02805420383810997,
            0.025201674550771713,
            -0.021517762914299965,
            -0.012198491021990776,
            -0.018721476197242737,
            -0.02382640354335308,
            0.019118594005703926,
            0.02068132907152176,
            -0.006601438391953707,
            0.026741283014416695,
            -0.004885830916464329,
            -0.0005567877669818699,
            0.013194547034800053,
            -0.02676442265510559,
            0.029773404821753502,
            -0.03725600242614746,
            -0.003355871420353651,
            0.0017793887527659535,
            0.0211394801735878,
            0.01881888136267662,
            -0.006415885873138905,
            0.028350193053483963,
            0.009166546165943146,
            0.008102272637188435,
            -0.001205015811137855,
            -0.018642853945493698,
            0.029335202649235725,
            0.012020545080304146,
            0.025222258642315865,
            -0.03221195191144943,
            0.02665676921606064,
            -0.02196592092514038,
            0.023244578391313553,
            -0.005658809561282396,
            0.010893424041569233,
            0.015861421823501587,
            0.009125156328082085,
            0.0293807964771986,
            0.026168672367930412,
            -0.014751976355910301,
            0.03369332104921341,
            -0.021669134497642517,
            -0.005696733482182026,
            -0.012864314019680023,
            0.01900671049952507,
            0.015127746388316154,
            -0.011056950315833092,
            -0.020672494545578957,
            -0.014850104227662086,
            -0.014711486175656319,
            0.01421141903847456,
            -0.033804070204496384,
            0.002531505422666669,
            0.021365229040384293,
            0.04328365623950958,
            0.026074670255184174,
            -0.016405649483203888,
            -0.01697648875415325,
            -0.015169822610914707,
            0.007473887875676155,
            0.005760500207543373,
            -0.0004050844581797719,
            0.01946919783949852,
            0.029348518699407578,
            0.021130021661520004,
            -0.011017996817827225,
            0.017948871478438377,
            0.005313877947628498,
            0.010177387855947018,
            0.012910162098705769,
            0.004157611168920994,
            -0.03187299147248268,
            -0.008614417165517807,
            0.037781961262226105,
            -0.0025597114581614733,
            -0.03033301793038845,
            0.027859890833497047,
            0.007330451626330614,
            0.005413788370788097,
            0.019196394830942154,
            -0.019374482333660126,
            -0.0025834343396127224,
            0.013048284687101841,
            0.026985449716448784,
            -0.020155560225248337,
            -0.02902732603251934,
            -0.0136125972494483,
            -0.006744751241058111,
            -0.018185343593358994,
            0.01860767789185047,
            -0.02246733382344246,
            -0.0075510782189667225,
            -0.022252803668379784,
            -0.024825120344758034,
            -0.01391629409044981,
            -0.0014679881278425455,
            -0.029684975743293762,
            0.00046832015505060554,
            -0.017377585172653198,
            0.03592498227953911,
            -0.00494631752371788,
            0.02624080330133438,
            -0.015353296883404255,
            0.03224891051650047,
            0.006489285733550787,
            -0.007164575159549713,
            -0.02349822409451008,
            -0.01649061031639576,
            0.003197062062099576,
            -0.008396485820412636,
            0.03299731761217117,
            0.0004467870749067515,
            0.027218898758292198,
            -0.013158697634935379,
            -0.03840773180127144,
            -0.018244780600070953,
            -0.04493146762251854,
            -0.011770223267376423,
            -0.018207909539341927,
            -0.015111752785742283,
            0.008695917204022408,
            -0.002058899961411953,
            -0.02367849461734295,
            -0.02212405949831009,
            -0.03176689147949219,
            0.015271713957190514,
            -0.006538595072925091,
            0.033574722707271576,
            -0.01352149248123169,
            0.02461702935397625,
            0.01921185478568077,
            -0.020379560068249702,
            0.013925512321293354,
            -0.015646781772375107,
            0.004015603568404913,
            -0.019421005621552467,
            0.03807844594120979,
            0.020662523806095123,
            -0.019737523049116135,
            0.0011603740276768804,
            0.0061141252517700195,
            0.0074331676587462425,
            0.007047110237181187,
            -0.020952219143509865,
            0.002000645035877824,
            0.004921397659927607,
            -0.020206879824399948,
            0.03111191652715206,
            -0.042315538972616196,
            -0.031230229884386063,
            0.009292475879192352,
            0.004424245562404394,
            0.024020986631512642,
            -0.018755745142698288,
            0.006081513594835997,
            -0.031070319935679436,
            -0.010681508108973503,
            -0.004540222696959972,
            -0.017526909708976746,
            0.01951768808066845,
            -0.027490878477692604,
            -0.00578340794891119,
            0.0063085854053497314,
            0.011337638832628727,
            -0.017359605059027672,
            -0.026497425511479378,
            0.021834127604961395,
            -0.0050023929215967655,
            0.011175607331097126,
            0.024346157908439636,
            0.005982256960123777,
            -0.013198107481002808,
            0.004123567137867212,
            -0.012374014593660831,
            0.014542261138558388,
            -0.03228828310966492,
            -0.006170859094709158,
            0.02036411687731743,
            0.04149959981441498,
            -0.0028988004196435213,
            -0.0033690729178488255,
            0.00840507261455059,
            -0.04758426919579506,
            0.007118023466318846,
            0.02620408497750759,
            0.012462208047509193,
            0.016573941335082054,
            -0.0008578617125749588,
            0.006303756032139063,
            -0.009701072238385677,
            -0.027967171743512154,
            0.027074899524450302,
            0.03209419175982475,
            -0.01331547275185585,
            0.011861142702400684,
            0.016006341204047203,
            -0.02765345387160778,
            -0.001054324908182025,
            0.02745896205306053,
            -0.022064583376049995,
            -0.010205968283116817,
            -0.022470645606517792,
            0.028376387432217598,
            0.015210557729005814,
            0.02857743203639984,
            -0.0007725858595222235,
            -0.03667476773262024,
            -0.0001616459630895406,
            0.031404271721839905,
            0.03252418339252472,
            0.0042093959636986256,
            -0.04389318451285362,
            -0.01288702804595232,
            0.038150422275066376,
            0.01752745546400547,
            0.0013459064066410065,
            0.026138313114643097,
            0.029110968112945557,
            0.007800504565238953,
            0.005144684109836817,
            -0.018499983474612236,
            0.010690671391785145,
            0.025701414793729782,
            -0.017037052661180496,
            -0.01564856618642807,
            0.023411542177200317,
            -0.006703246384859085,
            -0.0037682976108044386,
            0.0028273514471948147,
            -0.018167627975344658,
            -0.0032383850775659084,
            0.017788996919989586,
            0.01829666644334793,
            -0.0013726188335567713,
            -0.016565635800361633,
            -0.013839532621204853,
            0.006255547981709242,
            0.024660464376211166,
            -0.008148466236889362,
            0.02472377009689808,
            0.024260591715574265,
            0.004519075620919466,
            -0.004143909085541964,
            0.010877599939703941,
            0.02130313403904438,
            0.027340086176991463,
            0.02288755401968956,
            0.015037977136671543,
            -0.014381996355950832,
            -0.010198389180004597,
            -0.023368658497929573,
            0.021267473697662354,
            -0.006918317172676325,
            0.011503693647682667,
            0.00018758460646495223,
            0.02689272351562977,
            0.024917615577578545,
            0.007251998875290155,
            0.02337048202753067,
            0.030373362824320793,
            0.02165300026535988,
            -0.017177946865558624,
            -0.024745870381593704,
            0.034443728625774384,
            0.0232979916036129,
            -0.017766235396265984,
            -0.01942545548081398,
            -0.011810395866632462,
            -0.0049770367331802845,
            0.025201812386512756,
            -0.0008701320039108396,
            0.005943199619650841,
            -0.043493811041116714,
            0.025595881044864655,
            -0.027176406234502792,
            -0.013228845782577991,
            0.013657407835125923,
            -0.003591188695281744,
            -0.00991206243634224,
            0.0034664361737668514,
            0.020471787080168724,
            0.008731856010854244,
            0.024348095059394836,
            0.01481577567756176,
            0.03439619764685631,
            0.031212441623210907,
            -0.03467993065714836,
            0.016092315316200256,
            0.00444745970889926,
            0.016614343971014023,
            -0.016229884698987007,
            0.0025485949590802193,
            0.028325017541646957,
            -0.02632252313196659,
            -0.010383028537034988,
            -0.02408100664615631,
            -0.02391522005200386,
            0.013786413706839085,
            0.01977124996483326,
            0.0017164878081530333,
            -0.024803906679153442,
            0.021967975422739983,
            -0.039772700518369675,
            0.036714378744363785,
            -0.007081012241542339,
            0.035870857536792755,
            -0.003054022789001465,
            -0.006593585480004549,
            -0.022654738277196884,
            -0.014927918091416359,
            0.0006436484400182962,
            0.04307131469249725,
            -0.00510818138718605,
            -0.009969792328774929,
            -0.01258578710258007,
            -0.010715589858591557,
            0.02993670105934143,
            0.021219471469521523,
            -0.017888491973280907,
            -0.002621077001094818,
            -0.0014755170559510589,
            0.026415830478072166,
            0.017131183296442032,
            -0.020347656682133675,
            0.02053052932024002,
            -0.030678829178214073,
            -0.002244147239252925,
            0.01661617122590542,
            -0.013334076851606369,
            0.02954445779323578,
            -0.009539281949400902,
            0.009976058267056942,
            -0.006377849727869034,
            -0.0054220217280089855,
            0.014610663056373596,
            0.01336872112005949,
            -0.008259943686425686,
            -0.010678762570023537,
            0.00837673805654049,
            0.03397953137755394,
            -0.019193707033991814,
            -0.027513951063156128,
            0.014440701343119144,
            0.008126642554998398,
            0.03485133498907089,
            0.013066618703305721,
            -0.005167191382497549,
            -0.009100474417209625,
            -0.01942877098917961,
            0.0015363252023234963,
            0.04823523387312889,
            0.008468390442430973,
            0.005369010381400585,
            -0.02545621059834957,
            0.007158911786973476,
            -0.0070841675624251366,
            0.020908603444695473,
            -0.01719699613749981,
            0.018468258902430534,
            0.016309378668665886,
            0.027687735855579376,
            0.002625013468787074,
            -0.009679513052105904,
            0.02231830172240734,
            -0.009359369054436684,
            0.023093150928616524,
            0.008479755371809006,
            0.00018583583005238324,
            0.012607797048985958,
            -0.023304512724280357,
            0.016033053398132324,
            0.005342922639101744,
            -0.009932528249919415,
            0.017461830750107765,
            0.013144167140126228,
            0.02712847851216793,
            0.01971626468002796,
            -0.017311805859208107,
            -0.006607593968510628,
            -0.002188802231103182,
            0.02061208337545395,
            -0.011049543507397175,
            0.0029659734573215246,
            0.019985709339380264,
            0.023693272843956947,
            0.01953793317079544,
            -0.01336746383458376,
            -0.008128456771373749,
            -0.016249241307377815,
            -0.00977320782840252,
            0.005466804839670658,
            0.030982209369540215,
            -0.015382450073957443,
            0.01126406155526638,
            -0.00626147398725152,
            0.011141217313706875,
            -0.006948730908334255,
            0.01876145415008068,
            0.020739298313856125,
            -0.026698090136051178,
            -0.026276813820004463,
            0.027071990072727203,
            0.022089093923568726,
            0.01936565339565277,
            -0.01551271416246891,
            0.029779493808746338,
            0.027298586443066597,
            -0.038327112793922424,
            0.005285760387778282,
            0.010772150941193104,
            0.015450170263648033,
            0.005719589535146952,
            0.01955476962029934,
            -0.003952663857489824,
            -0.007937501184642315,
            -0.00920579582452774,
            0.0038037418853491545,
            0.015789741650223732,
            0.01927352510392666,
            0.011433019302785397,
            0.015464367344975471,
            -0.02821848727762699,
            0.006800756324082613,
            0.03078152798116207,
            0.0028448342345654964,
            -0.021020974963903427,
            -0.006759960670024157,
            0.06850074976682663,
            -0.016358712688088417,
            -0.01461090985685587,
            0.007198935840278864,
            0.01257618609815836,
            -0.019768686965107918,
            -0.030996691435575485,
            0.02735188975930214,
            0.0061524249613285065,
            0.03285834193229675,
            -0.0014953407226130366,
            -0.003935014363378286,
            -0.02152770198881626,
            -0.01791229657828808,
            -0.006854273844510317,
            0.022553814575076103,
            -0.016257472336292267,
            -9.640031930757686e-05,
            -0.0140019366517663,
            -0.01918860338628292,
            0.003288481617346406,
            0.012194233015179634,
            0.03398888185620308,
            0.025652986019849777,
            0.02739391103386879,
            -0.005375722423195839,
            -0.049387089908123016,
            -0.01117849349975586,
            -0.01724826544523239,
            -0.0175138171762228,
            0.0013755576219409704,
            0.0024960089940577745,
            0.013162040151655674,
            -0.006191592197865248,
            -0.0035594122018665075,
            -0.00980927050113678,
            -0.0009044331964105368,
            0.01985587552189827,
            -0.03234611824154854,
            0.013248423114418983,
            -0.011595623567700386,
            -0.004918131045997143,
            0.02002108283340931,
            0.03289937227964401,
            -0.010461551137268543,
            0.003765262896195054,
            0.01362542062997818,
            -0.01528418529778719,
            0.03146490827202797,
            -0.005507215391844511,
            0.03806610405445099,
            0.028529318049550056,
            0.0011504722060635686,
            0.0011170500656589866,
            0.0072746253572404385,
            0.03239402920007706,
            -0.033693525940179825,
            -0.01776818186044693,
            0.010417521931231022,
            0.018407216295599937,
            0.0302652008831501,
            -0.019163714721798897,
            -0.0086935143917799,
            -0.01924205757677555,
            0.024980392307043076,
            0.008324089460074902,
            -0.02047511748969555,
            -0.008452852256596088,
            -0.003254096256569028,
            -0.006977688986808062,
            0.018365640193223953,
            0.028366385027766228,
            -0.01079731434583664,
            0.00604441249743104,
            -0.004229808691889048,
            0.03539332374930382,
            0.00174325006082654,
            0.0042806630954146385,
            0.002960478886961937,
            0.02126719057559967,
            0.010246752761304379,
            0.009959916584193707,
            -0.014606819488108158,
            0.0016177170909941196,
            -0.03160640224814415,
            0.02117222361266613,
            -0.014430399052798748,
            0.04062682390213013,
            -0.027619900181889534,
            -0.002187377540394664,
            -0.018055664375424385,
            -0.009629902429878712,
            -0.017139000818133354,
            0.023472530767321587,
            -0.016330741345882416,
            0.04523828253149986,
            -0.0031497227028012276,
            0.03205682709813118,
            0.0007855766452848911,
            -0.02253975160419941,
            0.037166912108659744,
            -0.011588586494326591,
            0.024940185248851776,
            -0.012716099619865417,
            -0.0028544478118419647,
            0.03402307629585266,
            0.020441139116883278,
            -0.0019144014222547412,
            0.0290091410279274,
            -0.011474885046482086,
            0.003156122984364629,
            0.04453239217400551,
            0.02726021222770214,
            0.0066774506121873856,
            -0.003421051660552621,
            0.012993286363780499,
            -0.016338838264346123,
            0.02748960256576538,
            0.007668901700526476,
            0.005004743114113808,
            -0.003108103759586811,
            -0.024743322283029556,
            -0.011572249233722687,
            0.006776007357984781,
            0.0022035310976207256,
            0.035206541419029236,
            0.023519068956375122,
            0.0048393006436526775,
            -0.0014117019018158317,
            -0.03254033252596855,
            0.026101186871528625,
            0.01046297512948513,
            -0.0035940404050052166,
            -0.02158038131892681,
            -0.010105974040925503,
            9.929061343427747e-05,
            0.013067932799458504
        ],
        [
            0.007207395043224096,
            0.039609190076589584,
            0.029868967831134796,
            -0.05740483105182648,
            -0.011317919008433819,
            -0.03883234038949013,
            0.01368154026567936,
            0.019987482577562332,
            0.004851426929235458,
            -0.007065462414175272,
            -0.02743404358625412,
            -0.02758745662868023,
            0.007167229428887367,
            -0.01457137893885374,
            0.02694706991314888,
            -0.033667366951704025,
            0.0376427061855793,
            0.02746640145778656,
            -0.006802520249038935,
            -0.003547729691490531,
            -0.009123746305704117,
            -0.03069392964243889,
            0.026691818609833717,
            -0.012698129750788212,
            0.009499099105596542,
            -0.01202654279768467,
            0.018363751471042633,
            -0.04614192247390747,
            -0.016046354547142982,
            -0.04683852940797806,
            0.013885430991649628,
            -0.00619846023619175,
            0.03870058432221413,
            -0.0031486554071307182,
            -0.017218610271811485,
            -0.017545131966471672,
            0.009286447428166866,
            0.011305665597319603,
            0.02761882171034813,
            -0.044448502361774445,
            -0.04492770507931709,
            -0.050477273762226105,
            -0.004485344979912043,
            0.02165999263525009,
            0.03374071791768074,
            0.04895574599504471,
            0.019597887992858887,
            -0.02331220917403698,
            -0.014728794805705547,
            0.0347745455801487,
            0.011447891592979431,
            -0.01566239446401596,
            -0.033474136143922806,
            0.022996753454208374,
            0.007466339971870184,
            0.03520618751645088,
            -0.010980085469782352,
            0.004982999060302973,
            0.01635219156742096,
            -0.0069979289546608925,
            -0.00968231912702322,
            -0.02601497806608677,
            -0.04315050691366196,
            -0.02519712597131729,
            0.001543860649690032,
            -0.005118514411151409,
            -0.02722875587642193,
            0.014986153692007065,
            0.011696334928274155,
            0.00689680827781558,
            -0.02933809719979763,
            -0.02884414605796337,
            0.005083855707198381,
            -0.002734498353675008,
            -0.06099538505077362,
            -0.06375215947628021,
            0.016273587942123413,
            0.027741914615035057,
            -0.00011873164476128295,
            0.03975917398929596,
            0.029595792293548584,
            0.06590685248374939,
            0.008426502346992493,
            0.0027208372484892607,
            -0.030393341556191444,
            0.013032323680818081,
            -0.0013145430712029338,
            0.002974915085360408,
            -0.031762633472681046,
            -0.02735455334186554,
            0.016630886122584343,
            -0.03901887685060501,
            -0.029619457200169563,
            -0.03140466287732124,
            -0.019500887021422386,
            -0.008173271082341671,
            0.04936741292476654,
            -0.01209189835935831,
            0.07833989709615707,
            0.011756745167076588,
            0.017053741961717606,
            0.06886574625968933,
            0.01573367603123188,
            0.025785889476537704,
            0.006255907937884331,
            -0.018830368295311928,
            0.011031600646674633,
            0.015590202994644642,
            -0.05276123434305191,
            0.026834765449166298,
            -0.002395636634901166,
            0.0007743031019344926,
            -0.03944946080446243,
            -0.006947064772248268,
            0.049340397119522095,
            -0.03830048441886902,
            -0.025915879756212234,
            -0.0027675011660903692,
            -0.010402403771877289,
            0.009493079036474228,
            0.020548146218061447,
            -0.004224646370857954,
            -0.03132833540439606,
            0.005320802330970764,
            0.03429865837097168,
            -0.003673135070130229,
            -0.028505856171250343,
            -0.01838916353881359,
            0.026296228170394897,
            0.01560404896736145,
            -0.018600916489958763,
            0.008785090409219265,
            0.017854901030659676,
            0.012830187566578388,
            -0.06475481390953064,
            0.013132945634424686,
            -0.017466630786657333,
            0.05796349048614502,
            -0.016224535182118416,
            -0.008110969327390194,
            0.009618829004466534,
            0.020414192229509354,
            -0.023515626788139343,
            -0.0023047111462801695,
            0.037467312067747116,
            -0.009090711362659931,
            0.005777251906692982,
            0.018356656655669212,
            -0.021990785375237465,
            0.018914174288511276,
            0.012226549908518791,
            0.058001186698675156,
            -0.04679613187909126,
            0.004659660160541534,
            -0.022414634004235268,
            0.019863607361912727,
            -0.010180549696087837,
            0.008376025594770908,
            -0.01211598515510559,
            0.06414876878261566,
            -0.018376197665929794,
            0.00975783634930849,
            0.028705283999443054,
            0.009543010033667088,
            0.016026757657527924,
            0.020351774990558624,
            0.025277655571699142,
            0.007751063443720341,
            -0.013404813595116138,
            -0.036170586943626404,
            -0.019760923460125923,
            -0.012558056972920895,
            -0.0190152395516634,
            -0.04797719046473503,
            0.044673994183540344,
            -0.019903050735592842,
            0.021638890728354454,
            0.0048216539435088634,
            0.006140753626823425,
            -0.010491442866623402,
            0.020979704335331917,
            0.0055940356105566025,
            0.04253436625003815,
            0.03745773434638977,
            0.022709054872393608,
            0.03353395313024521,
            0.043194469064474106,
            -0.041684672236442566,
            -0.027145275846123695,
            0.002464298391714692,
            0.030840137973427773,
            -0.04126039147377014,
            -0.04523151367902756,
            0.02249092422425747,
            -0.014089232310652733,
            -0.013667527586221695,
            0.00933822337538004,
            0.0035159895196557045,
            0.031231075525283813,
            -0.00020025928097311407,
            -0.0154532790184021,
            -0.02407131902873516,
            -0.03542383387684822,
            0.04794242978096008,
            0.015025043860077858,
            -0.01733928546309471,
            0.05394664406776428,
            0.055234190076589584,
            -0.004852731246501207,
            -0.00766537943854928,
            -0.019717764109373093,
            -0.02974182926118374,
            0.027454355731606483,
            0.02140815742313862,
            -0.005439521744847298,
            0.006834305357187986,
            -0.017133573070168495,
            -0.017910823225975037,
            0.019250262528657913,
            -0.007247514557093382,
            -0.027274200692772865,
            0.02454087696969509,
            -0.02854907512664795,
            -0.012095204554498196,
            -0.018159234896302223,
            -0.01904737576842308,
            0.056114062666893005,
            0.01811346970498562,
            -0.007442005444318056,
            -0.013531537726521492,
            -0.030799483880400658,
            -0.0413411520421505,
            -0.03984925150871277,
            -0.010418769903481007,
            0.015376348979771137,
            -0.017718331888318062,
            0.039210788905620575,
            0.0109254764392972,
            0.021453138440847397,
            0.01736309565603733,
            -0.02318490296602249,
            0.008373861201107502,
            -0.016539784148335457,
            -0.005330963991582394,
            -0.0063842227682471275,
            0.031255193054676056,
            0.028610194101929665,
            0.03248908743262291,
            0.005006066989153624,
            -0.0050174337811768055,
            -0.016273757442831993,
            -0.00886646006256342,
            -0.010013123042881489,
            -0.01644067093729973,
            -0.007974880747497082,
            0.0141996368765831,
            -0.042270805686712265,
            -0.035330601036548615,
            -0.039060965180397034,
            0.039675887674093246,
            -0.030733516439795494,
            0.016800815239548683,
            -0.058274924755096436,
            -0.03426896780729294,
            -0.0031648699659854174,
            -0.030897919088602066,
            0.00215563690289855,
            0.015955999493598938,
            -0.0004353361146058887,
            -0.05847223103046417,
            0.007520498242229223,
            0.020864585414528847,
            -0.050224922597408295,
            0.017289219424128532,
            -0.003133285790681839,
            -0.019503075629472733,
            0.026052838191390038,
            0.017680328339338303,
            0.02160538360476494,
            0.03840502351522446,
            -0.03750104457139969,
            0.019898638129234314,
            -0.0407390221953392,
            -0.019095061346888542,
            -0.04077313467860222,
            0.0015332780312746763,
            0.00254699750803411,
            -0.012017706409096718,
            0.013423128053545952,
            -0.037517283111810684,
            -0.004671840462833643,
            -0.0013066232204437256,
            0.0220110435038805,
            0.022077543660998344,
            0.03888107091188431,
            0.048562441021203995,
            0.007780803367495537,
            -0.013681280426681042,
            -0.02715553715825081,
            0.022254182025790215,
            0.01289526280015707,
            0.015284539200365543,
            0.027098003774881363,
            0.020803676918148994,
            -0.032467976212501526,
            -0.0024548841174691916,
            -0.00871309544891119,
            0.0519217923283577,
            0.05327987298369408,
            -0.014530695043504238,
            0.0007845411309972405,
            -0.0016157497884705663,
            -0.013525527901947498,
            -0.027524540200829506,
            0.009236241690814495,
            0.012697029858827591,
            3.992335041402839e-05,
            0.02302829921245575,
            -0.03339127451181412,
            -0.015440676361322403,
            -0.03064301609992981,
            0.0011768672848120332,
            0.013333837501704693,
            -0.0033495884854346514,
            -0.02671683020889759,
            0.012612520717084408,
            0.039088744670152664,
            -0.03291762247681618,
            0.010632891207933426,
            0.010170334950089455,
            -0.0029769602697342634,
            -0.016711315140128136,
            0.040146879851818085,
            -0.012364826165139675,
            0.014733368530869484,
            0.006742340978235006,
            0.022325357422232628,
            -0.03675643354654312,
            0.011398475617170334,
            0.019870493561029434,
            -0.026251869276165962,
            -0.011930665001273155,
            -0.06403811275959015,
            0.001112649799324572,
            0.038965996354818344,
            -0.005047219339758158,
            0.02085278555750847,
            0.017040153965353966,
            0.0059603978879749775,
            0.012925919145345688,
            0.04145042970776558,
            0.00748775340616703,
            -0.005833751987665892,
            -0.02899249643087387,
            0.008094287477433681,
            0.043004728853702545,
            0.017499789595603943,
            -0.030214257538318634,
            -0.02148251049220562,
            0.025104496628046036,
            -0.016281772404909134,
            -0.05359741672873497,
            0.08703239262104034,
            -0.0007851689588278532,
            0.01648813858628273,
            0.04143273085355759,
            0.0158403217792511,
            0.02656504698097706,
            0.02005775272846222,
            -0.014719823375344276,
            -0.05133780092000961,
            -0.030257828533649445,
            0.01981465518474579,
            -0.0023589979391545057,
            0.022346099838614464,
            0.06948008388280869,
            0.02669527940452099,
            -0.01895810291171074,
            -0.0155910924077034,
            -0.013072960078716278,
            -0.05522455647587776,
            0.016742272302508354,
            0.010678721591830254,
            -0.014185511507093906,
            -0.017546283081173897,
            -0.06077928841114044,
            -0.034807488322257996,
            0.005256499629467726,
            0.02977154031395912,
            -0.011668412946164608,
            0.011471264064311981,
            0.01891927421092987,
            0.023636896163225174,
            0.03121037222445011,
            0.025182239711284637,
            -0.005651713348925114,
            -0.027154983952641487,
            0.03997448459267616,
            -0.00394988339394331,
            0.07806246727705002,
            0.006467386614531279,
            -0.005451257806271315,
            0.03279096260666847,
            0.012054553255438805,
            0.006174617912620306,
            0.018223270773887634,
            -0.024759266525506973,
            0.008424241095781326,
            -0.016324054449796677,
            -0.025747936218976974,
            -0.0002061147679341957,
            0.03170008584856987,
            0.0013237962266430259,
            -0.019949041306972504,
            0.03260019049048424,
            -0.003666118485853076,
            0.04239165410399437,
            -0.06761183589696884,
            0.020987043157219887,
            -0.018050407990813255,
            -0.009962490759789944,
            0.022326285019516945,
            0.06647887080907822,
            -0.004735197871923447,
            0.051239605993032455,
            0.000555250677280128,
            0.01852012239396572,
            0.06188182905316353,
            -0.02393644116818905,
            -0.01842511259019375,
            0.05746927857398987,
            -0.03550025448203087,
            0.012060300447046757,
            0.011344319209456444,
            -0.011425980366766453,
            0.041010532528162,
            0.017915965989232063,
            -0.017287248745560646,
            -0.020474160090088844,
            -0.02218415029346943,
            0.028318949043750763,
            -0.03184501826763153,
            0.011695709079504013,
            0.014395714737474918,
            -0.026887645944952965,
            -0.06444559246301651,
            -0.0012107266811653972,
            -0.040899720042943954,
            0.006819718983024359,
            0.01947038806974888,
            0.026376282796263695,
            -0.01689668744802475,
            0.017738720402121544,
            0.02297210320830345,
            -0.027272874489426613,
            -0.024665966629981995,
            0.060398995876312256,
            -0.025703001767396927,
            0.0015023918822407722,
            0.03456889092922211,
            0.0055055986158549786,
            -0.019928690046072006,
            0.04067103564739227,
            0.0641510933637619,
            0.005885403603315353,
            -0.008631452918052673,
            0.038173869252204895,
            0.05648260936141014,
            0.006151939742267132,
            0.025077519938349724,
            0.04326282814145088,
            -0.008934920653700829,
            0.0008042827830649912,
            0.012722114101052284,
            -0.028551900759339333,
            -0.008923663757741451,
            -0.016666611656546593,
            -0.0022273582872003317,
            -0.0037067641969770193,
            -0.007528872229158878,
            0.019921565428376198,
            -0.010171975009143353,
            -0.00042333794408477843,
            -0.0010613779304549098,
            0.04652255028486252,
            -0.05069926753640175,
            -0.029374301433563232,
            -0.08073752373456955,
            -0.11778533458709717,
            0.046896085143089294,
            -0.03324582427740097,
            0.002683358732610941,
            0.009252350777387619,
            -0.03735951706767082,
            0.00454488443210721,
            0.04316366836428642,
            -0.01515057124197483,
            0.027445659041404724,
            0.009780257008969784,
            0.017145415768027306,
            -0.030655868351459503,
            0.012785287573933601,
            -0.0026619979180395603,
            -0.015928106382489204,
            0.018183628097176552,
            0.007004984188824892,
            0.020906904712319374,
            -0.0048412843607366085,
            -0.01218605786561966,
            -0.02261369861662388,
            0.01372989360243082,
            0.02726626768708229,
            -0.018601777032017708,
            -0.0015591623960062861,
            -0.030338210985064507,
            -0.015438544563949108,
            0.01630629599094391,
            -0.012826129794120789,
            0.015856469050049782,
            0.0007989866426214576,
            0.021211177110671997,
            -0.027095966041088104,
            0.03918123617768288,
            -0.007245312910526991,
            -0.03759005665779114,
            -0.004234451800584793,
            -0.0008308684336952865,
            0.046755872666835785,
            -0.0012704781256616116,
            0.01858709566295147,
            -0.031235922127962112,
            -0.012024353258311749,
            0.03722962364554405,
            0.02369549870491028,
            -0.03524616360664368,
            0.0676875114440918,
            -0.027992138639092445,
            -0.011244246736168861,
            -0.03608650341629982,
            0.0028249314054846764,
            0.005388383287936449,
            0.022996779531240463,
            0.01958661898970604,
            0.026884527876973152,
            -0.0029764152131974697,
            0.001592275919392705,
            0.012711118906736374,
            0.011773580685257912,
            -0.003610134357586503,
            -0.018941786140203476,
            0.015432422049343586,
            0.0038032110314816236,
            -0.0037192071322351694,
            0.007347190752625465,
            0.0009777199011296034,
            -0.025018366053700447,
            0.008230116218328476,
            -0.04881052300333977,
            -0.007306063547730446,
            0.0059663779102265835,
            -0.003376677865162492,
            -0.020396800711750984,
            -0.020626533776521683,
            0.013054960407316685,
            -0.029566319659352303,
            0.032279618084430695,
            -0.02031613513827324,
            -0.046784427016973495,
            0.01053597405552864,
            -0.023202085867524147,
            0.012426110915839672,
            0.033036865293979645,
            0.010769019834697247,
            -0.02092653140425682,
            -0.011622518301010132,
            0.0158788301050663,
            -0.011103952303528786,
            -0.013740482740104198,
            -0.01171488594263792,
            -0.015496731735765934,
            0.023099828511476517,
            0.02622363716363907,
            -0.012284809723496437,
            -0.06418350338935852,
            -0.03174516186118126,
            -0.007100304123014212,
            0.005488384980708361,
            -0.0136689692735672,
            -0.015575626865029335,
            -0.02111819200217724,
            0.003225283231586218,
            0.01852409914135933,
            0.0380667969584465,
            0.009931550361216068,
            -0.016650119796395302,
            -0.028843330219388008,
            0.014242769218981266,
            0.02137667126953602,
            -0.020166145637631416,
            -0.00023489345039706677,
            0.010350700467824936,
            0.0511690191924572,
            0.06090973690152168,
            -0.05617772042751312,
            -0.024346671998500824,
            0.009382727555930614,
            -0.004487081430852413,
            -0.010431939736008644,
            -0.013789039105176926,
            0.05277858301997185,
            -0.01634092442691326,
            0.004285518079996109,
            0.005069477017968893,
            0.001192335388623178,
            -0.026156697422266006,
            0.019632568582892418,
            -0.03553318232297897,
            0.010707429610192776,
            0.0076048350892961025,
            0.05892358720302582,
            -0.0671398714184761,
            0.017938632518053055,
            0.022611476480960846,
            0.023911619558930397,
            0.027525510638952255,
            -0.009616529569029808,
            0.012882727198302746,
            -0.011556721292436123,
            0.059737201780080795,
            -0.017874455079436302,
            0.0008872294565662742,
            0.011780735105276108,
            -0.023008303716778755,
            -0.06871654838323593,
            0.014686291106045246,
            0.03371129557490349,
            0.016766991466283798,
            -0.024887174367904663,
            -0.00947614200413227,
            -0.028917070478200912,
            0.001057550311088562,
            0.036424364894628525,
            0.06067498028278351,
            0.010556306689977646,
            0.00793373305350542,
            -0.013371532782912254,
            -0.007498530671000481,
            0.015292798168957233,
            0.013423732481896877,
            0.02893948182463646,
            0.02216898836195469,
            -0.011041365563869476,
            -0.003978684078902006,
            0.021511796861886978,
            0.010483157820999622,
            -0.017589712515473366,
            -0.008570315316319466,
            -0.00875750370323658,
            0.01595056802034378,
            -0.0020880133379250765,
            -0.0255748201161623,
            -0.018972964957356453,
            -0.015785546973347664,
            -0.004967538174241781,
            -0.009852861054241657,
            -0.01749226823449135,
            0.008053498342633247,
            -0.004164365120232105,
            -0.006874911952763796,
            0.038013067096471786,
            0.052633143961429596,
            -0.025804482400417328,
            0.0041801817715168,
            -0.03480096533894539,
            0.0026835633907467127,
            0.03406467288732529,
            -0.010476682335138321,
            0.02391638420522213,
            -0.004626412410289049,
            0.025941098108887672,
            -0.010901461355388165,
            0.006100376136600971,
            -0.01746440678834915,
            -0.019832631573081017,
            0.035918403416872025,
            0.022514594718813896,
            0.005335147026926279,
            0.008278567343950272,
            -0.0074887992814183235,
            -0.08134271949529648,
            -0.029657436534762383,
            -0.032469768077135086,
            -0.014352346770465374,
            -0.016292916610836983,
            -0.022046010941267014,
            -0.045646488666534424,
            -0.015370389446616173,
            -0.05951857194304466,
            -0.02510729245841503,
            -0.030917959287762642,
            -0.004478197544813156,
            -0.03977840393781662,
            -0.023057786747813225,
            -0.014137008227407932,
            0.048751600086688995,
            0.023068033158779144,
            0.007639357354491949,
            0.023879842832684517,
            -0.01565609686076641,
            -0.008471742272377014,
            -0.009190868586301804,
            -0.017192000523209572,
            -0.020373210310935974,
            -0.011882946826517582,
            0.008079814724624157,
            -0.00305655924603343,
            0.03210395202040672,
            0.0515710823237896,
            0.0018197537865489721,
            0.025038573890924454,
            -0.0014933658530935645,
            0.02714606188237667,
            -0.009637340903282166,
            0.0066894544288516045,
            -0.008102457039058208,
            -0.04318562150001526,
            -0.03360939025878906,
            -0.025899404659867287,
            0.047434739768505096,
            0.010283241979777813,
            0.024798860773444176,
            -0.012977548874914646,
            0.023533787578344345,
            -0.03465425595641136,
            -0.034685298800468445,
            0.02041938714683056,
            -0.013280829414725304,
            0.012635337188839912,
            0.04328363761305809,
            0.031784381717443466,
            0.0337536446750164,
            -0.03822309523820877,
            -0.005336484871804714,
            -0.018356747925281525,
            0.0004184666322544217,
            -0.00016302120639011264,
            -0.026552310213446617,
            0.04660658910870552,
            -0.025650177150964737,
            -0.014610073529183865,
            -0.024548472836613655,
            -0.02797779254615307,
            -0.007538116071373224,
            0.005164259113371372,
            -0.013566648587584496,
            0.0030326521955430508,
            0.03176729008555412,
            0.05240899324417114,
            -0.019735807552933693,
            0.024051953107118607,
            0.010334211401641369,
            -0.016629528254270554,
            0.01840689405798912,
            -0.02117406576871872,
            0.01830928958952427,
            -0.018039777874946594,
            -0.038372114300727844,
            0.023213636130094528,
            -0.01768864318728447,
            0.002464206190779805,
            -0.02265765704214573,
            7.274085510289297e-05,
            0.030643850564956665,
            0.038115087896585464,
            -0.03262855112552643,
            -0.04317953810095787,
            -0.07175000011920929,
            0.007121330127120018,
            0.029757844284176826,
            -0.028232911601662636,
            0.007092596497386694,
            -0.007452252320945263,
            -0.006029735319316387,
            0.006638731807470322,
            -0.011228317394852638,
            0.04055384173989296,
            -0.00779567938297987,
            -0.012523937970399857,
            0.01703467220067978,
            -0.03350584954023361,
            0.024174129590392113,
            0.029367469251155853,
            0.030007557943463326,
            0.017713433131575584,
            0.002895746845752001,
            0.061292752623558044,
            -0.006859801709651947,
            0.019646350294351578,
            -0.03311876952648163,
            -0.042157769203186035,
            -0.039100050926208496,
            0.05091867968440056,
            -0.0006853457889519632,
            0.023590197786688805,
            -0.04391806945204735,
            -0.03962298110127449,
            0.023004481568932533,
            -0.014004606753587723,
            0.0042448327876627445,
            0.017184363678097725,
            -0.041840262711048126,
            0.06417705863714218,
            0.005206659901887178,
            0.0027374892961233854,
            -0.05059194192290306,
            -0.014106523245573044,
            -0.04039228707551956,
            -0.017221231013536453,
            0.014348907396197319,
            -0.021136829629540443,
            -0.008312412537634373,
            0.01885412074625492,
            -0.05018302798271179,
            0.0022823188919574022,
            0.04641390219330788,
            0.004751220345497131,
            0.02580845355987549,
            -0.016238408163189888,
            -0.004879855550825596,
            -0.0082554891705513,
            0.045510318130254745,
            -0.02815164253115654,
            -0.030560996383428574,
            -0.019167257472872734,
            0.017864106222987175,
            0.051264338195323944,
            -0.012555169872939587,
            0.027992889285087585,
            0.015700582414865494,
            -0.06005081534385681,
            -0.02581903524696827,
            -0.03307633101940155,
            -0.031463392078876495,
            0.00839560478925705,
            0.01794097200036049,
            0.02340540662407875,
            -0.007309533655643463,
            0.03953396901488304,
            0.010319488123059273,
            -0.005666186101734638,
            -0.009068555198609829,
            0.021587051451206207,
            0.032527823001146317,
            0.007039547432214022,
            0.011482801288366318,
            -0.037618622183799744,
            -0.01714448072016239,
            -0.017411133274435997,
            0.03496766462922096,
            0.003325600875541568,
            -0.0030776632484048605,
            -0.009913143701851368,
            -0.055751726031303406,
            0.018543634563684464,
            -0.023010317236185074,
            -0.0343727245926857,
            -0.010774209164083004,
            -0.016499515622854233,
            0.013306855224072933,
            -0.045264191925525665,
            0.04517829045653343,
            -0.00854529906064272,
            -0.05589358136057854,
            -0.01293764729052782,
            -0.015653487294912338,
            -0.0033794993069022894,
            -0.005154159851372242,
            -0.07475446909666061,
            -0.008165368810296059,
            -0.028277626261115074,
            0.030407631769776344,
            0.056996557861566544,
            0.02782382071018219,
            0.015348631888628006,
            0.031474366784095764,
            0.006527557037770748,
            0.0055665504187345505,
            0.04215863719582558,
            0.017383567988872528,
            -0.009001175872981548,
            0.03551701083779335,
            0.03238164260983467,
            0.032503508031368256,
            -0.0240449458360672,
            0.013906649313867092,
            0.025539912283420563,
            0.03763988986611366,
            0.013571594841778278,
            -0.014270687475800514,
            -0.00010238871618639678,
            0.006430282257497311,
            0.0080478610470891,
            -0.021494856104254723,
            0.0002533448569010943,
            0.005113021936267614,
            0.05909399315714836,
            0.02019127458333969,
            -0.01921233721077442,
            -0.028445376083254814,
            -0.02142944373190403,
            0.015119155868887901,
            -0.022401630878448486,
            0.0060415128245949745,
            -0.02088010311126709,
            0.05784273520112038,
            -0.00782021228224039,
            0.06396326422691345,
            -0.0143077801913023,
            -0.042727693915367126,
            -0.02929670363664627,
            -0.07386525720357895,
            0.01171692181378603,
            0.02128700166940689,
            0.05028260499238968,
            -0.027722099795937538,
            -0.04834170639514923,
            -0.04862707108259201,
            -0.0234942939132452,
            -0.007909056730568409,
            -0.0013670569751411676,
            -0.019090283662080765,
            0.03842416778206825,
            -0.0033394061028957367,
            0.06613676995038986,
            0.02651832066476345,
            0.022541042417287827,
            -0.03780555725097656,
            0.009719382040202618,
            0.030696267262101173,
            0.002598087303340435,
            0.001892755040898919,
            -0.003116890089586377,
            -0.04379269853234291,
            0.021850431337952614,
            0.009814741089940071,
            0.01653500646352768,
            -0.0065654548816382885,
            0.028864972293376923,
            0.027969224378466606,
            -0.004048848059028387,
            0.03887684643268585,
            -0.010258265770971775,
            0.05155538395047188,
            -0.013748048804700375,
            -0.11808653920888901,
            0.0484493225812912,
            -0.039052534848451614,
            -0.011411829851567745,
            -0.029947631061077118,
            -0.004253570921719074,
            0.017681408673524857,
            -0.01853162981569767,
            -0.059870827943086624,
            -0.026118436828255653,
            -0.0017320646438747644,
            -0.013370547443628311,
            0.028562167659401894,
            -0.02999916672706604,
            0.034949447959661484,
            0.008401804603636265,
            -0.02822783589363098,
            0.02739538438618183,
            0.016248099505901337,
            0.008673131465911865,
            0.009131154045462608,
            0.0008722922648303211,
            0.035941775888204575,
            0.014649290591478348,
            0.009598048403859138,
            0.01702667400240898,
            -0.018028035759925842,
            -0.018292320892214775,
            0.03541954234242439,
            0.009060300886631012,
            0.034483812749385834,
            -0.02615642361342907,
            0.0006437685806304216,
            0.003156897844746709,
            -0.004112207796424627,
            0.01785147376358509,
            0.0019214758649468422,
            -0.02228471264243126,
            -0.034783441573381424,
            0.001993793062865734,
            -0.03266602009534836,
            -0.09296511113643646,
            0.03986397013068199,
            0.027683096006512642,
            0.0314815379679203,
            0.030294444411993027,
            -0.013227221556007862,
            -0.05179193988442421,
            0.023938728496432304,
            -0.040850549936294556,
            0.010152733884751797,
            0.020855333656072617,
            -0.03371565043926239,
            0.0096178213134408,
            -0.023114396259188652,
            -0.010793084278702736,
            -0.007775342091917992,
            -0.004968670662492514,
            0.00981280580163002,
            0.010371457785367966,
            0.06323377043008804,
            0.04583205282688141,
            0.020957371219992638,
            0.0192230474203825,
            0.010638140141963959,
            -0.03809213638305664,
            0.03962405025959015,
            0.024452002719044685,
            0.052521537989377975,
            -0.00981885101646185
        ],
        [
            -0.004417463205754757,
            -0.02500385232269764,
            -0.033510640263557434,
            -0.02869095467031002,
            -0.018353400751948357,
            0.012561625801026821,
            0.009723777882754803,
            0.04543476924300194,
            0.015166236087679863,
            0.011556667275726795,
            0.034046825021505356,
            0.011407889425754547,
            -0.039544038474559784,
            -0.011831482872366905,
            0.002259037923067808,
            -0.039149727672338486,
            -0.014606609009206295,
            0.018624112010002136,
            -0.02863462269306183,
            -0.025784166529774666,
            0.0017414706526324153,
            -0.031125640496611595,
            -0.05541951581835747,
            -0.0003660168731585145,
            -0.03042934648692608,
            -0.015663471072912216,
            0.0038383821956813335,
            0.03894548863172531,
            -0.018488749861717224,
            0.0023657195270061493,
            0.007550037931650877,
            -0.01932111382484436,
            -0.02516918256878853,
            0.0063448501750826836,
            -0.002590811112895608,
            -0.006240124348551035,
            -0.013707181438803673,
            -0.021804653108119965,
            0.023256739601492882,
            0.02614658884704113,
            0.022409051656723022,
            0.010740299709141254,
            -0.008624201640486717,
            0.008602628484368324,
            -0.025803962722420692,
            0.03771567717194557,
            0.026751864701509476,
            -0.035070162266492844,
            -0.018188759684562683,
            -0.04187041148543358,
            0.023519394919276237,
            0.024565426632761955,
            -0.01161873433738947,
            -0.0654253363609314,
            0.021430356428027153,
            -0.008015468716621399,
            -0.029200101271271706,
            -0.002301056170836091,
            -0.0173521526157856,
            -0.004811841994524002,
            0.022240210324525833,
            -0.023519355803728104,
            -0.03877734765410423,
            0.026046887040138245,
            0.0009178011678159237,
            0.012963316403329372,
            -0.038656990975141525,
            0.011763214133679867,
            0.035809773951768875,
            0.0020971628837287426,
            -0.004710114561021328,
            -0.024460341781377792,
            0.002144194208085537,
            -0.030471688136458397,
            -0.010388924740254879,
            -0.048283737152814865,
            -0.03232656791806221,
            -0.014894246123731136,
            -0.03446371853351593,
            0.011318306438624859,
            0.004933804739266634,
            0.00380571186542511,
            -0.004556525032967329,
            0.02537444792687893,
            -0.022019559517502785,
            0.010697118937969208,
            -0.01580885984003544,
            0.0016367775388062,
            -0.005488910246640444,
            9.01043604244478e-05,
            -0.031279902905225754,
            -0.002775339875370264,
            -0.0025568522978574038,
            -0.0069196587428450584,
            0.005324897821992636,
            -0.03248991444706917,
            0.02230003848671913,
            -0.01874520257115364,
            -0.06623493880033493,
            0.033111993223428726,
            -0.011809879913926125,
            0.008852330967783928,
            0.015803376212716103,
            0.0031358725391328335,
            -0.022308019921183586,
            0.03384757786989212,
            -0.0019000995671376586,
            -0.005487489514052868,
            -0.006038393825292587,
            -0.005993376951664686,
            -0.015951141715049744,
            0.02874312363564968,
            -0.008108475245535374,
            -0.03360371291637421,
            -0.0201070848852396,
            0.0055445535108447075,
            0.010818586684763432,
            0.05075674131512642,
            0.04854197800159454,
            -0.027436377480626106,
            -0.034201428294181824,
            0.025333141908049583,
            0.04248697683215141,
            0.021568961441516876,
            0.03917326405644417,
            -0.05903629958629608,
            0.04342595115303993,
            -0.0161385890096426,
            0.036083370447158813,
            0.029966209083795547,
            0.02848089300096035,
            -0.013189254328608513,
            0.011851462535560131,
            0.04164052754640579,
            0.02303272858262062,
            -0.03295331075787544,
            -0.03684556856751442,
            -0.015271155163645744,
            0.008289489895105362,
            -0.01605256460607052,
            0.021346621215343475,
            0.003343232674524188,
            -0.028910504654049873,
            0.030840028077363968,
            0.017732180655002594,
            0.013985909521579742,
            0.01574818789958954,
            0.03296096995472908,
            0.0316988043487072,
            -0.03341900184750557,
            0.02968927100300789,
            0.032686635851860046,
            0.03763102740049362,
            -0.038076870143413544,
            -0.0424194261431694,
            -0.02976408414542675,
            -0.01997271180152893,
            -0.044674668461084366,
            -0.009780069813132286,
            0.01689421385526657,
            0.01138037908822298,
            -0.005822880193591118,
            0.018078692257404327,
            -0.010047195479273796,
            -0.028439247980713844,
            0.05211382359266281,
            0.035138294100761414,
            0.008917094208300114,
            -0.005204839166253805,
            -0.011593891307711601,
            -0.025150761008262634,
            0.027075909078121185,
            -0.060863785445690155,
            -0.017554404214024544,
            0.02460864931344986,
            0.03186582028865814,
            0.037853632122278214,
            -0.00271413940936327,
            -0.014716865494847298,
            -0.0140311811119318,
            0.014338142238557339,
            -0.006999390199780464,
            0.016590198501944542,
            -0.004095550626516342,
            -0.029392359778285027,
            0.018022887408733368,
            0.014244800433516502,
            0.03568049520254135,
            0.0030050789937376976,
            -0.01411618571728468,
            0.033828940242528915,
            -0.005085795186460018,
            0.0012675095349550247,
            0.04178760573267937,
            -0.017124183475971222,
            0.023095257580280304,
            0.008619498461484909,
            0.0016663344576954842,
            -0.00488997483626008,
            -0.02440068870782852,
            -0.03126878663897514,
            0.023488162085413933,
            -0.016000986099243164,
            0.0035587616730481386,
            0.011942414566874504,
            -0.025728125125169754,
            -0.05806858092546463,
            0.029247118160128593,
            0.03572545945644379,
            0.005607701372355223,
            -0.012822362594306469,
            -0.0404336117208004,
            0.022659486159682274,
            0.0343753844499588,
            0.031328875571489334,
            0.007086100988090038,
            0.03975840285420418,
            -0.017032479867339134,
            -0.031899504363536835,
            0.03077826462686062,
            0.004694332834333181,
            -0.01843784749507904,
            -0.01516304537653923,
            -0.03644026443362236,
            0.0069082616828382015,
            0.010259418748319149,
            -0.0717531219124794,
            -0.024388814345002174,
            0.020328324288129807,
            0.030160387977957726,
            -0.009583624079823494,
            0.0032029475551098585,
            -0.016891412436962128,
            -0.028455227613449097,
            -0.005511991214007139,
            -0.026981333270668983,
            -0.0019174845656380057,
            0.004874107427895069,
            -0.01801757887005806,
            0.0075499555096030235,
            0.0035987573210150003,
            -0.029984787106513977,
            0.005565565545111895,
            0.027352111414074898,
            0.014133409596979618,
            0.01485456433147192,
            -0.0012528341030701995,
            0.024987850338220596,
            -0.026647718623280525,
            -0.006975851953029633,
            0.0009785336442291737,
            0.010742359794676304,
            -0.0024385256692767143,
            -0.03304128721356392,
            0.008286398835480213,
            -0.019624073058366776,
            0.021958960220217705,
            0.0512562021613121,
            0.028388233855366707,
            0.006702912971377373,
            -0.04050157219171524,
            -0.019915474578738213,
            -0.03297126293182373,
            0.0011647059582173824,
            0.024058710783720016,
            -0.004436247516423464,
            0.03434043750166893,
            -0.004197730217128992,
            -0.01167444046586752,
            -0.015568038448691368,
            0.008217139169573784,
            -0.014109673909842968,
            0.027892878279089928,
            -0.02045775204896927,
            0.015066495165228844,
            0.012494306080043316,
            -0.014589548110961914,
            -0.019489413127303123,
            0.014876543544232845,
            0.035311173647642136,
            0.005451473873108625,
            -0.021547731012105942,
            -0.00369665096513927,
            -0.023268824443221092,
            -0.013552848249673843,
            0.023074042052030563,
            -0.0032423825468868017,
            -0.039800576865673065,
            0.022367248311638832,
            0.006568959914147854,
            -0.02752143330872059,
            0.01749902404844761,
            -0.026694700121879578,
            0.013963894918560982,
            0.028142642229795456,
            0.009847461245954037,
            -0.007922746241092682,
            0.022519124671816826,
            0.0051842257380485535,
            -0.015434527769684792,
            -0.008039547130465508,
            -0.03392046317458153,
            0.008336438797414303,
            -0.007525790482759476,
            0.007241775281727314,
            -0.0743173137307167,
            -0.025477945804595947,
            0.028883883729577065,
            8.925097790779546e-05,
            -0.0002312012220500037,
            0.03336232900619507,
            -0.00620066886767745,
            0.03456016257405281,
            0.01971891149878502,
            0.008455200120806694,
            0.03150826692581177,
            -0.022221121937036514,
            0.006626693066209555,
            0.01868765987455845,
            0.0009995963191613555,
            -0.015930619090795517,
            -0.0468742698431015,
            -0.02511713281273842,
            0.02926001138985157,
            0.023409653455018997,
            -0.016919460147619247,
            0.018585653975605965,
            -0.007273112423717976,
            0.014428061433136463,
            -0.007451069541275501,
            -0.03421369940042496,
            -0.021266106516122818,
            -0.033103521913290024,
            -0.005695973057299852,
            -0.03188633173704147,
            -0.01934116519987583,
            0.014416125603020191,
            -0.02478945441544056,
            -0.022577783092856407,
            0.012619705870747566,
            -0.012015500105917454,
            -0.006669589783996344,
            0.013656173832714558,
            0.004536607768386602,
            -0.015318231657147408,
            0.013254866935312748,
            0.01595737598836422,
            0.02396543137729168,
            -0.015062806196510792,
            0.0022497870959341526,
            -0.033402398228645325,
            0.03911188244819641,
            0.009462452493607998,
            -0.04545397683978081,
            0.025561166927218437,
            -0.006867146119475365,
            -0.020480766892433167,
            0.025973787531256676,
            0.01423877663910389,
            0.01943986304104328,
            -0.011423437856137753,
            0.02002863772213459,
            4.508572237682529e-05,
            0.03348970413208008,
            0.0077204033732414246,
            -0.022012444213032722,
            -0.00805608183145523,
            -0.024049095809459686,
            0.016585886478424072,
            -0.0090414397418499,
            0.0328965000808239,
            -0.0019598708022385836,
            0.019471336156129837,
            0.004450944717973471,
            0.028563104569911957,
            0.026336854323744774,
            0.022019442170858383,
            0.01283567026257515,
            0.030615588650107384,
            0.011329875327646732,
            -0.009165684692561626,
            -0.021886834874749184,
            0.027289291843771935,
            0.011922938749194145,
            0.018041811883449554,
            -0.05448690056800842,
            0.002228741068392992,
            -0.01427543442696333,
            0.002628439338877797,
            0.027667388319969177,
            -0.003103695809841156,
            -0.01669321022927761,
            -0.02197004109621048,
            -0.006876435596495867,
            0.06811408698558807,
            -0.007919128984212875,
            -0.009132595732808113,
            -0.00030753150349482894,
            0.022136995568871498,
            0.02074562758207321,
            -0.003697193693369627,
            0.01468766387552023,
            0.020448623225092888,
            0.012759196572005749,
            0.02680947631597519,
            0.013266976922750473,
            0.013019423931837082,
            0.012933466583490372,
            0.007180147338658571,
            0.019561605527997017,
            -0.039762429893016815,
            -0.028649628162384033,
            -0.012013616040349007,
            -0.0027395079378038645,
            0.01779295690357685,
            0.03204138204455376,
            0.007326229475438595,
            -0.005363428965210915,
            -0.013845914043486118,
            0.014377320185303688,
            -0.009747900068759918,
            0.020624525845050812,
            -0.07889140397310257,
            0.026543958112597466,
            -0.013425366021692753,
            -0.001795366290025413,
            -0.0030984152108430862,
            -0.012512793764472008,
            -0.020612457767128944,
            -0.048948999494314194,
            -0.053268928080797195,
            0.03825560584664345,
            0.02046908065676689,
            0.004625662695616484,
            -0.0002791810838971287,
            0.012075132690370083,
            -0.011831119656562805,
            0.013970435597002506,
            0.015632078051567078,
            0.040531545877456665,
            0.036273758858442307,
            -0.0006120067555457354,
            -0.03498580679297447,
            -0.03969139978289604,
            -0.0453820638358593,
            -0.018708940595388412,
            0.033608123660087585,
            -0.04361696541309357,
            0.023940443992614746,
            0.014438332989811897,
            -0.016746386885643005,
            -0.000991142587736249,
            0.04549048840999603,
            -0.004316334612667561,
            0.011932611465454102,
            -0.003914239816367626,
            -0.009714996442198753,
            0.021075835451483727,
            -0.0037144154775887728,
            -0.00696090841665864,
            -0.011249898001551628,
            -0.0018559194868430495,
            0.039353784173727036,
            -0.009895309805870056,
            0.03195314481854439,
            0.020471816882491112,
            -0.012274876236915588,
            -0.022960934787988663,
            -0.016166528686881065,
            0.011108274571597576,
            0.03491194173693657,
            -0.0050718821585178375,
            0.032782550901174545,
            -0.01705889403820038,
            0.00325217773206532,
            -0.0037721588741987944,
            -0.002437227638438344,
            -0.017817890271544456,
            -0.022847166284918785,
            -0.02075493521988392,
            -0.06449215859174728,
            -0.019684065133333206,
            -0.08193812519311905,
            -0.013699856586754322,
            0.0182266216725111,
            -0.02100628986954689,
            0.020524751394987106,
            -0.0037869287189096212,
            -0.005887393839657307,
            -0.020679829642176628,
            -0.007958794012665749,
            -0.022671736776828766,
            0.02553100697696209,
            0.029032975435256958,
            -0.015722936019301414,
            -0.03035033494234085,
            -0.019881978631019592,
            0.005353168584406376,
            0.008646035566926003,
            0.010753817856311798,
            -0.01039336621761322,
            0.0021798342932015657,
            0.016404036432504654,
            -0.0013838193845003843,
            0.03836417570710182,
            -0.029923606663942337,
            -0.0021824948489665985,
            -0.006529360543936491,
            -0.016565978527069092,
            -0.019440239295363426,
            -0.01766975410282612,
            0.040022797882556915,
            -0.02047443389892578,
            0.00016349567158613354,
            -0.021359873935580254,
            0.030118567869067192,
            0.02604765072464943,
            0.009670354425907135,
            0.02871151827275753,
            0.000976324372459203,
            0.012628013268113136,
            -0.01817524991929531,
            -0.024363450706005096,
            0.018428578972816467,
            -0.08415479212999344,
            -0.008060582913458347,
            -0.004972705617547035,
            0.042258042842149734,
            0.009065242484211922,
            -0.05649980157613754,
            0.026920439675450325,
            -0.014491208828985691,
            0.004231013357639313,
            -0.01023394800722599,
            0.016376744955778122,
            0.0008206128841266036,
            -0.015454281121492386,
            -0.0018010314088314772,
            0.02147907018661499,
            -0.02097419463098049,
            0.019901998341083527,
            -0.0035697489511221647,
            -0.010351172648370266,
            -0.032194580882787704,
            0.0131386062130332,
            0.012145369313657284,
            0.004115656949579716,
            -0.010138136334717274,
            -0.025076400488615036,
            0.02346254326403141,
            0.027009444311261177,
            -0.020273704081773758,
            0.01795380376279354,
            0.026933299377560616,
            -0.00037283680285327137,
            0.01450295839458704,
            -0.00698878662660718,
            -0.015032351948320866,
            -0.011849900707602501,
            0.014274107292294502,
            0.023442145437002182,
            -0.050012409687042236,
            0.027504591271281242,
            -0.02056039683520794,
            0.016646062955260277,
            -0.0100593576207757,
            -0.0312014389783144,
            0.03662324696779251,
            -0.014434508979320526,
            -0.015777382999658585,
            -0.007943524047732353,
            0.009749249555170536,
            -0.025539929047226906,
            0.038791876286268234,
            -0.030664388090372086,
            0.013377473689615726,
            0.013193987309932709,
            -0.02776854671537876,
            0.012098596431314945,
            0.027136357501149178,
            0.020026741549372673,
            -0.029000665992498398,
            -0.0468858927488327,
            -0.0010982215171679854,
            0.023153571411967278,
            -0.015895288437604904,
            -0.03753180801868439,
            -0.012427464127540588,
            0.03834851458668709,
            -0.015051708556711674,
            0.003682713955640793,
            0.04674863442778587,
            0.018959639593958855,
            -0.012971111573278904,
            0.029234644025564194,
            0.016721850261092186,
            -0.02724304422736168,
            -0.020733626559376717,
            0.0012151232222095132,
            0.00574047677218914,
            0.013444262556731701,
            0.024194279685616493,
            -0.05107861012220383,
            -0.027023550122976303,
            0.0002230400132248178,
            -0.005669878795742989,
            0.009924596175551414,
            0.011822419241070747,
            -0.02001248300075531,
            -0.01622427999973297,
            -0.006458158139139414,
            -0.01910427212715149,
            0.006989491172134876,
            0.013598023913800716,
            -0.009871098212897778,
            -0.014982723630964756,
            0.04384450241923332,
            -0.013907624408602715,
            -0.007289326284080744,
            -0.015935780480504036,
            -0.05501975119113922,
            -6.741994729964063e-05,
            0.0006487667560577393,
            -0.004272319842129946,
            -0.012655680999159813,
            0.031266357749700546,
            0.017547091469168663,
            0.030934181064367294,
            0.027280515059828758,
            -0.011359437368810177,
            0.02963958866894245,
            -0.02063954621553421,
            0.03935457020998001,
            -0.030189288780093193,
            0.030895382165908813,
            0.00012078069994458929,
            -0.02991327829658985,
            0.00843848381191492,
            0.0015398852992802858,
            -0.034143347293138504,
            0.039573851972818375,
            0.023948393762111664,
            0.026398541405797005,
            0.010946286842226982,
            0.016578704118728638,
            0.01592135988175869,
            -0.04527127742767334,
            -0.018297690898180008,
            -0.03881729021668434,
            0.022855326533317566,
            -0.01636669412255287,
            -0.01880875788629055,
            0.03481786698102951,
            -0.01830602064728737,
            -0.01105243619531393,
            0.00931729655712843,
            0.04095800593495369,
            0.026089025661349297,
            0.03734295070171356,
            -0.025601880624890327,
            0.01786622405052185,
            0.008334744721651077,
            -0.015381228178739548,
            -0.017488211393356323,
            -0.011625664308667183,
            -0.02254854515194893,
            0.015160967595875263,
            0.015454416163265705,
            0.0014443276450037956,
            0.021424850448966026,
            -0.04133125767111778,
            -0.030156215652823448,
            -0.013037578202784061,
            0.01429936196655035,
            0.002097376622259617,
            0.008949344046413898,
            0.022332753986120224,
            -0.024071170017123222,
            0.013742147944867611,
            0.02758350409567356,
            -0.04025411605834961,
            -0.0003818859113380313,
            -0.030470235273241997,
            -0.02455655299127102,
            0.014656598679721355,
            0.03004656359553337,
            0.03830167278647423,
            -0.05435865744948387,
            -0.024727998301386833,
            0.02007642574608326,
            -0.06319160759449005,
            0.0241109486669302,
            0.00498482258990407,
            -0.003985538613051176,
            -0.019843528047204018,
            -0.017015693709254265,
            0.020274484530091286,
            -0.019909890368580818,
            -0.02239401824772358,
            -0.0012943621259182692,
            0.0005616279086098075,
            0.0063728406094014645,
            -0.02018406055867672,
            0.007039728108793497,
            -0.0007166174473240972,
            -0.02994522824883461,
            0.03256810083985329,
            0.029532019048929214,
            0.04558701068162918,
            0.02487712725996971,
            -0.000928815919905901,
            -0.02143590711057186,
            -0.012313787825405598,
            -0.01415995042771101,
            0.012480556033551693,
            -0.0013412603875622153,
            -0.020038636401295662,
            0.00033377486397512257,
            0.012582550756633282,
            0.024239443242549896,
            -0.04098258167505264,
            -0.005426500458270311,
            -0.009175041690468788,
            -0.024649767205119133,
            0.010417450219392776,
            0.0013471912825480103,
            0.028710434213280678,
            0.024567969143390656,
            -0.01204726006835699,
            0.02920088730752468,
            0.02440890297293663,
            0.0009708167635835707,
            -0.012334519065916538,
            0.004419031087309122,
            0.0319502055644989,
            -0.001531819230876863,
            -0.02446923218667507,
            0.03181323781609535,
            0.013446037657558918,
            -0.050794605165719986,
            -0.004525101277977228,
            0.015137010253965855,
            -0.01898277923464775,
            0.0033258062321692705,
            0.023251354694366455,
            -0.01722259446978569,
            -0.0742935985326767,
            -0.029851917177438736,
            -0.020684614777565002,
            -0.007407148368656635,
            -0.02353166602551937,
            -0.012361953966319561,
            -0.001562285702675581,
            0.011167307384312153,
            0.0037631788291037083,
            -0.015700483694672585,
            -0.01574644446372986,
            -0.04572438821196556,
            -0.026980362832546234,
            0.03598447144031525,
            0.03688585013151169,
            -0.039712030440568924,
            -0.01639622449874878,
            -0.01599722169339657,
            0.016465086489915848,
            -0.007841790094971657,
            0.016599638387560844,
            0.027963697910308838,
            0.016343792900443077,
            -0.016041895374655724,
            -0.06272014230489731,
            0.008778568357229233,
            0.03158148005604744,
            0.018442140892148018,
            -0.0003394079103600234,
            -0.018769867718219757,
            0.010060712695121765,
            0.028009815141558647,
            0.0382462814450264,
            0.04361372068524361,
            -0.027392935007810593,
            -0.032620299607515335,
            -0.002677144017070532,
            -0.012234056368470192,
            0.010711132548749447,
            0.0003681714297272265,
            0.025154007598757744,
            -0.01448309700936079,
            0.0053155203349888325,
            -0.03348172456026077,
            0.0389958992600441,
            -0.02966553531587124,
            -0.049905579537153244,
            -0.008871019817888737,
            -0.01667153462767601,
            -0.012369280681014061,
            -0.005719315726310015,
            0.0005414046463556588,
            -0.0047366321086883545,
            0.03432772681117058,
            0.009239205159246922,
            -0.0407930389046669,
            0.026987940073013306,
            -0.025219695642590523,
            0.0037747034803032875,
            -0.003102815942838788,
            0.004488745704293251,
            0.019203659147024155,
            -0.033117860555648804,
            0.03581330180168152,
            0.01459885761141777,
            -0.01688259281218052,
            0.0018030548235401511,
            0.024463260546326637,
            -0.0032974788919091225,
            0.007871491834521294,
            -0.0024223141372203827,
            -0.010916929692029953,
            -0.015650512650609016,
            -0.032681941986083984,
            0.007348259445279837,
            0.020626569166779518,
            -0.03443971276283264,
            -0.005623186007142067,
            0.013625504449009895,
            0.02104039117693901,
            0.016895804554224014,
            -0.027607353404164314,
            -0.01190229132771492,
            -0.012192884460091591,
            0.003944669850170612,
            0.0036686058156192303,
            -0.0007877075695432723,
            0.013396387919783592,
            0.02874240092933178,
            -0.009344000369310379,
            -0.00019040223560296,
            0.016590561717748642,
            0.04343019798398018,
            -0.00811686646193266,
            0.013806960545480251,
            -0.009931384585797787,
            0.01952330395579338,
            -0.008088952861726284,
            0.02456250600516796,
            -0.03225984424352646,
            -0.03314440697431564,
            0.013033254072070122,
            -0.005932503379881382,
            -0.003204932203516364,
            0.005121553782373667,
            0.039379581809043884,
            0.02235918492078781,
            0.010072296485304832,
            -0.009213359095156193,
            0.0216401144862175,
            0.017191370949149132,
            0.01095298770815134,
            -0.013197487220168114,
            0.03375253826379776,
            -0.018743187189102173,
            0.02876862697303295,
            -0.02422444336116314,
            0.027557017281651497,
            0.026431623846292496,
            0.02312072552740574,
            0.011674214154481888,
            -0.00956211518496275,
            -0.008281261660158634,
            0.008094483986496925,
            -0.019547486677765846,
            -0.03568777069449425,
            -0.02112695574760437,
            0.0064649744890630245,
            0.003632676089182496,
            0.008190705440938473,
            0.040020428597927094,
            0.0659906417131424,
            0.02849169261753559,
            0.01635885238647461,
            0.007731132209300995,
            -0.06071484088897705,
            -0.0065579903312027454,
            0.03780524805188179,
            -0.07258587330579758,
            -0.02433796040713787,
            0.011822798289358616,
            -0.03689030185341835,
            0.03603442385792732,
            0.0047361948527395725,
            -0.038452960550785065,
            -0.0015157347079366446,
            -0.0036826382856816053,
            0.001431847340427339,
            -0.04577803239226341,
            0.02697829157114029,
            0.01861344277858734,
            -0.003888854291290045,
            -0.03643465414643288,
            -0.029703926295042038,
            -0.040367960929870605,
            -0.02504192292690277,
            0.024560581892728806,
            -0.0176912359893322,
            0.016570497304201126,
            0.02324548177421093,
            0.024990014731884003,
            -0.015507770702242851,
            -0.011260347440838814,
            -0.03542718663811684,
            0.0026220420841127634,
            0.01782202161848545,
            0.006207638420164585,
            -0.01191101223230362,
            0.002598981838673353,
            -0.012777484953403473,
            -0.03898345306515694,
            0.0034254479687660933,
            -0.01234213262796402,
            -0.0019489169353619218,
            0.017544081434607506,
            0.005498306825757027,
            0.012402284890413284,
            0.030666472390294075,
            -0.050082843750715256,
            -0.005361012648791075,
            0.033548831939697266,
            -0.021768620237708092,
            -0.01456620916724205,
            0.007493489421904087,
            -0.024420548230409622,
            0.025549713522195816,
            -0.0021061971783638,
            0.016860337927937508,
            0.02080206386744976,
            -0.036078259348869324,
            -0.02557317167520523,
            -0.0076813469640910625,
            -0.0014244791818782687,
            -0.012602423317730427,
            0.016296586021780968,
            -0.024803388863801956,
            0.006271671038120985,
            0.013848126865923405,
            0.029105324298143387,
            0.016697432845830917,
            0.005558433011174202,
            0.004194910172373056,
            -0.004989974666386843,
            0.028101544827222824,
            0.033339157700538635,
            -0.0017882675165310502,
            0.00943788979202509,
            0.006863354705274105,
            -0.04920968413352966,
            -0.022031836211681366,
            -0.020771194249391556,
            -0.02294677495956421,
            -0.011632775887846947,
            -0.017967337742447853,
            -0.01457134261727333,
            -0.07494372129440308,
            0.035642851144075394,
            0.018762947991490364,
            0.018670519813895226,
            -0.0012088920921087265,
            0.03029714524745941,
            0.03127746656537056,
            -0.03574720025062561,
            -0.01940970867872238,
            0.014588450081646442,
            -0.004453700501471758,
            -0.0007122045499272645,
            -0.02627142332494259,
            -0.01218219380825758,
            0.018831899389624596,
            -0.06163572892546654,
            0.022440068423748016,
            -0.013168822042644024,
            -0.010190632194280624,
            0.0009884827304631472,
            -0.006037402432411909,
            -0.023216823115944862,
            -0.012256760150194168,
            0.03682487830519676,
            -0.0008998035336844623,
            0.02443017065525055,
            0.025221865624189377,
            0.01787150651216507,
            -0.015621461905539036,
            0.03792215883731842,
            0.02871830388903618,
            0.008687627501785755,
            0.020947039127349854,
            0.008765795268118382,
            0.010125401429831982,
            0.017667338252067566,
            -0.0015782634727656841,
            0.007922524586319923,
            -0.030885087326169014,
            0.01340318750590086,
            -0.007058773655444384,
            -0.03943637013435364,
            -0.01571923866868019,
            0.02539796382188797,
            0.029353871941566467,
            0.0064009567722678185,
            -0.02372993342578411,
            0.01336291991174221,
            0.0026663097087293863,
            -0.0707433745265007,
            0.007823138497769833,
            0.014438479207456112,
            0.011564519256353378,
            0.01367236115038395,
            0.002657894277945161,
            -0.01799784228205681,
            0.016881829127669334,
            -0.0038028752896934748,
            0.026741648092865944,
            -0.024914337322115898,
            0.025368275120854378,
            -0.007899506017565727,
            0.003988240379840136
        ],
        [
            -0.01815648190677166,
            -0.027314402163028717,
            -0.025792835280299187,
            0.028827916830778122,
            -0.002715460490435362,
            0.02243277058005333,
            0.023293666541576385,
            -0.0014929481549188495,
            -0.016225716099143028,
            0.0241051334887743,
            -0.051289793103933334,
            -0.03856106474995613,
            0.02832765132188797,
            -0.00503829400986433,
            -0.053155072033405304,
            0.02052372880280018,
            -0.04040149599313736,
            -0.018031395971775055,
            0.011250091716647148,
            -0.0010880266781896353,
            0.012268122285604477,
            0.0169668085873127,
            0.023870911449193954,
            0.01886114478111267,
            0.007340086158365011,
            -0.027297789230942726,
            -0.011397970840334892,
            0.015045284293591976,
            0.032718777656555176,
            0.04211863875389099,
            0.005827711429446936,
            0.01502840593457222,
            -0.01877957209944725,
            0.022775989025831223,
            0.03216420114040375,
            -0.008310692384839058,
            -0.0018372164340689778,
            0.016064906492829323,
            0.0027304685208946466,
            -0.01551778707653284,
            -0.0146302105858922,
            -0.008306663483381271,
            -0.019444169476628304,
            0.02456490322947502,
            0.012496846728026867,
            -0.033333588391542435,
            -0.015137512236833572,
            -0.01991884410381317,
            -0.03492943197488785,
            -0.01891305111348629,
            0.0052821459248661995,
            0.00936066173017025,
            0.01233933586627245,
            -0.027354206889867783,
            -0.01574357971549034,
            -0.03250252828001976,
            0.007400580681860447,
            0.01568898744881153,
            0.010115775279700756,
            0.0063163042068481445,
            -0.01428762637078762,
            0.01429266668856144,
            0.02362729050219059,
            0.01696205511689186,
            0.021836690604686737,
            0.007242229301482439,
            -0.013164987787604332,
            -0.0033836360089480877,
            0.014318167231976986,
            0.0016745802713558078,
            0.01918123848736286,
            0.02948743663728237,
            0.029717503115534782,
            -0.008783004246652126,
            -0.06533914059400558,
            0.016939153894782066,
            -0.0027363388799130917,
            -0.010256102308630943,
            0.018995413556694984,
            -0.0188383050262928,
            -0.00829068198800087,
            -0.010066680610179901,
            0.005884417798370123,
            0.0011415319750085473,
            -0.007818879559636116,
            -0.019465623423457146,
            -0.02516036666929722,
            -0.04106588661670685,
            -0.016183407977223396,
            0.03799864649772644,
            0.004827108699828386,
            -0.025154203176498413,
            0.03363942354917526,
            -0.01631000265479088,
            0.037673626095056534,
            0.010242065414786339,
            -0.07115080952644348,
            0.01782378926873207,
            -0.0031779450364410877,
            0.011388222686946392,
            0.0003953001578338444,
            0.02450045570731163,
            -0.004545694217085838,
            0.029601389542222023,
            -0.010989461094141006,
            -0.009522163309156895,
            -0.0011786625254899263,
            -0.017786217853426933,
            0.016330312937498093,
            -0.019290583208203316,
            0.0024193166755139828,
            -0.04613148048520088,
            0.024744320660829544,
            0.023008940741419792,
            -0.019756093621253967,
            0.021018046885728836,
            -0.010945391841232777,
            0.01675303280353546,
            -0.00022468196402769536,
            0.003577891504392028,
            0.026506327092647552,
            -0.004844192415475845,
            -0.02540530450642109,
            -0.005456814542412758,
            -0.03065389208495617,
            0.0022514997981488705,
            -0.016853470355272293,
            -0.01293885987251997,
            0.010988116264343262,
            -0.016530383378267288,
            -0.010147295892238617,
            -0.021165557205677032,
            0.015223322436213493,
            -0.0033889487385749817,
            -0.032281920313835144,
            -0.02825271524488926,
            -0.005247056484222412,
            -0.011754881590604782,
            -0.03065611980855465,
            -0.016185633838176727,
            -0.02320110984146595,
            0.0009159457986243069,
            0.004742828197777271,
            -0.002592161763459444,
            -0.004661664832383394,
            -0.001012209220789373,
            0.007338729687035084,
            0.0030995188280940056,
            0.012784845195710659,
            -0.025914043188095093,
            -0.026863975450396538,
            -0.011018859222531319,
            0.004731347784399986,
            -0.01637803204357624,
            0.0076505448669195175,
            0.0018422086723148823,
            0.01770210638642311,
            -0.0335598960518837,
            0.0323299914598465,
            -0.00031728344038128853,
            0.026152970269322395,
            -0.010843134485185146,
            -0.03686179220676422,
            -0.026534775272011757,
            0.01202611718326807,
            0.009444236755371094,
            0.024553436785936356,
            0.0158963892608881,
            -0.0003957266453653574,
            0.020353734493255615,
            0.029277747496962547,
            0.02776319719851017,
            0.006959485821425915,
            -0.02943042851984501,
            -0.02366318367421627,
            -0.031150376424193382,
            0.00039648395613767207,
            0.024862058460712433,
            -0.02597658708691597,
            -0.0034213413018733263,
            -0.04038592427968979,
            -0.004945224616676569,
            -0.024753522127866745,
            0.025563636794686317,
            0.024616161361336708,
            -0.0010402750922366977,
            -0.03398678079247475,
            0.011950181797146797,
            0.001702320296317339,
            -0.009792987257242203,
            0.011410822160542011,
            -0.01363754365593195,
            0.024690845981240273,
            0.02627309039235115,
            -0.00559121323749423,
            -0.004419213160872459,
            0.03096253238618374,
            -0.029670707881450653,
            -0.02603800594806671,
            -0.00448463624343276,
            0.030568256974220276,
            0.004590898286551237,
            0.027865968644618988,
            -0.004760839976370335,
            0.04811575636267662,
            -0.03518137335777283,
            -0.013375597074627876,
            -0.0014041383983567357,
            0.009810736402869225,
            -0.02639826014637947,
            0.00023180742573458701,
            -0.034041717648506165,
            -0.0270355474203825,
            -0.0032546815928071737,
            0.02126815915107727,
            0.03096313588321209,
            0.0029295089188963175,
            -0.0036551458761096,
            -0.0038249618373811245,
            0.035546157509088516,
            0.03329310566186905,
            0.02665102295577526,
            -0.005274765193462372,
            0.004761164542287588,
            0.02369273081421852,
            -0.03274528309702873,
            0.005662580952048302,
            -0.013825674541294575,
            -0.007036915980279446,
            0.025133157148957253,
            0.01871875673532486,
            -2.0445475456654094e-05,
            -0.009131201542913914,
            -0.021733174100518227,
            -0.003197325626388192,
            0.03193284571170807,
            -0.04296186566352844,
            -0.009595361538231373,
            -0.025755450129508972,
            0.010416688397526741,
            -0.013789862394332886,
            0.004990702029317617,
            0.007951311767101288,
            0.014843765646219254,
            0.035787295550107956,
            0.015774445608258247,
            -0.017704712226986885,
            -0.012129725888371468,
            0.02758673205971718,
            -0.01387108862400055,
            0.03827955201268196,
            -0.0025719148106873035,
            -0.01882815919816494,
            0.02896631695330143,
            -0.022509265691041946,
            0.008473874069750309,
            0.03281969577074051,
            0.017385980114340782,
            0.007394550833851099,
            -0.005403389688581228,
            -0.021154647693037987,
            -0.014550862833857536,
            0.0446622259914875,
            0.012563844211399555,
            -0.03917308524250984,
            -0.028979238122701645,
            0.005446199327707291,
            -0.0003174689190927893,
            0.026056593284010887,
            -0.018231740221381187,
            0.0031029393430799246,
            0.01269950345158577,
            0.01952674612402916,
            0.020965522155165672,
            0.0074755349196493626,
            -0.009827563539147377,
            -0.005566759034991264,
            0.007623501121997833,
            -0.030068591237068176,
            0.032307904213666916,
            0.00950356014072895,
            0.02103171870112419,
            -0.023017939180135727,
            -0.03498313948512077,
            -0.015436588786542416,
            -0.03152308613061905,
            -0.006184582598507404,
            -0.07555324584245682,
            0.001771874143742025,
            0.028389085084199905,
            -0.008631535805761814,
            -0.003440320724621415,
            -0.010595466010272503,
            0.023737873882055283,
            -0.01990143395960331,
            0.03750457242131233,
            -0.008489259518682957,
            0.010078857652842999,
            -0.006582210306078196,
            -0.01707058772444725,
            -0.0035531893372535706,
            0.04145728424191475,
            -0.011098572053015232,
            0.00039349053986370564,
            0.01574932038784027,
            -0.001552508445456624,
            0.01652476191520691,
            -0.0036793053150177,
            -0.023043332621455193,
            -0.006358638871461153,
            -0.03102547489106655,
            -0.024185098707675934,
            0.012947817333042622,
            0.0190352201461792,
            0.034285999834537506,
            -0.008383387699723244,
            0.03545260429382324,
            0.004916392266750336,
            -0.03501715883612633,
            -0.00612688111141324,
            0.012430484406650066,
            -0.009728865697979927,
            -0.03830480948090553,
            0.0016242637066170573,
            -0.020718084648251534,
            0.02425677329301834,
            -0.02419406920671463,
            -0.02644769847393036,
            0.026933813467621803,
            0.017698196694254875,
            0.0008086205925792456,
            0.02322971075773239,
            0.021418564021587372,
            -0.006578629836440086,
            -0.024012573063373566,
            0.014428630471229553,
            -0.006306449417024851,
            0.03267194703221321,
            0.03342680260539055,
            -0.027544312179088593,
            -0.019120384007692337,
            -0.016781756654381752,
            -0.04173349589109421,
            0.034867193549871445,
            -0.014123339205980301,
            0.0011916825314983726,
            0.018999608233571053,
            0.020479723811149597,
            -0.01858072355389595,
            0.019246652722358704,
            0.02087121270596981,
            -0.02245698869228363,
            0.02782798744738102,
            0.006253513041883707,
            -0.007332487963140011,
            -0.02149970643222332,
            -0.015274014323949814,
            -0.007139076013118029,
            -0.022928383201360703,
            0.03191543370485306,
            -0.020727310329675674,
            -0.008814211934804916,
            -0.015849176794290543,
            0.0025771413929760456,
            0.003158836392685771,
            -0.028702791780233383,
            -0.005722006782889366,
            0.008877621032297611,
            0.03879913315176964,
            -0.01820865459740162,
            -0.011938024312257767,
            0.0006984681240282953,
            0.023558473214507103,
            -0.0374462753534317,
            -0.024179084226489067,
            -0.0007229578914120793,
            0.0243332851678133,
            0.024184394627809525,
            0.008991124108433723,
            -0.015931539237499237,
            0.009330609813332558,
            -0.015315784141421318,
            -0.014318816363811493,
            0.026208262890577316,
            -0.024810846894979477,
            -0.004208579193800688,
            -0.016994722187519073,
            0.0049020773731172085,
            -0.006637463811784983,
            0.015150432474911213,
            0.011273945681750774,
            0.0010937410406768322,
            0.012167048640549183,
            0.03637632355093956,
            -0.026514148339629173,
            -0.0005803690291941166,
            0.02874843403697014,
            -0.03010432794690132,
            -0.018324939534068108,
            -0.021976904943585396,
            -0.0043138908222317696,
            -0.047429509460926056,
            0.005774771329015493,
            0.03550560772418976,
            0.03330403193831444,
            -0.009119507856667042,
            -0.027431610971689224,
            0.01159797515720129,
            -0.004980552010238171,
            -0.001923895557411015,
            0.01608266867697239,
            0.026674583554267883,
            -0.02922934480011463,
            0.043086931109428406,
            0.008785897865891457,
            0.0021151131950318813,
            -0.016405967995524406,
            0.015496818348765373,
            0.0029656698461622,
            -0.004853097256273031,
            0.008934943936765194,
            0.03600627928972244,
            -0.033332839608192444,
            0.014009697362780571,
            -0.015530936419963837,
            0.01335772406309843,
            -0.0006701381062157452,
            -0.02001265250146389,
            0.03403289616107941,
            0.032708246260881424,
            -0.027377210557460785,
            0.006035684607923031,
            0.006388283334672451,
            -0.020789960399270058,
            0.006826752331107855,
            -0.00782861839979887,
            0.003252903465181589,
            -0.025916608050465584,
            -0.031639907509088516,
            -0.01697416789829731,
            -0.049205295741558075,
            -0.01794677972793579,
            0.00654911482706666,
            -0.02344350889325142,
            0.025646448135375977,
            -0.0002034181379713118,
            0.03850724548101425,
            0.07988375425338745,
            0.010976382531225681,
            -0.013499332591891289,
            0.011465813033282757,
            0.01365254819393158,
            -0.015699218958616257,
            -0.0018697769846767187,
            0.0421583391726017,
            0.015492993406951427,
            -0.02349451556801796,
            0.01118373591452837,
            0.013362765312194824,
            -0.022260228171944618,
            0.0303033459931612,
            -0.02642516791820526,
            -0.0163289662450552,
            -0.020869409665465355,
            0.015343395993113518,
            -0.00689633097499609,
            -0.03490760177373886,
            0.016194729134440422,
            -0.005445335991680622,
            -0.011725214309990406,
            -0.03954475745558739,
            0.010474401526153088,
            0.00475979084149003,
            0.019991101697087288,
            -0.0002565145550761372,
            -0.0022525442764163017,
            0.010126147419214249,
            -0.010402844287455082,
            0.03813720494508743,
            -0.0011708308011293411,
            -0.038961563259363174,
            0.00633855490013957,
            -0.0278166513890028,
            -0.025151502341032028,
            -0.02793099917471409,
            -0.031399231404066086,
            0.031172549352049828,
            0.016660787165164948,
            -0.01218618918210268,
            -0.014208294451236725,
            -0.012934211641550064,
            -0.015437169000506401,
            -0.0225937869399786,
            -0.02444559521973133,
            0.022298721596598625,
            -0.021509960293769836,
            -0.026795724406838417,
            0.030295390635728836,
            0.02890695445239544,
            0.02611687034368515,
            0.014416322112083435,
            0.02277786284685135,
            -0.02968904748558998,
            0.029417725279927254,
            0.03619560971856117,
            0.0006079004378989339,
            0.016213012859225273,
            0.027133576571941376,
            0.017236916348338127,
            -0.026193538680672646,
            -0.00014728333917446434,
            -0.027414510026574135,
            0.009225635789334774,
            -0.042121969163417816,
            -0.007726874202489853,
            -0.006723920814692974,
            -0.012944338843226433,
            -0.008541849441826344,
            -0.014361805282533169,
            -0.018155010417103767,
            0.011850652284920216,
            0.023967359215021133,
            0.02535383589565754,
            0.0342167392373085,
            0.0412820503115654,
            0.012081806547939777,
            0.010505164042115211,
            0.006342526059597731,
            -0.008250557817518711,
            -0.007858541794121265,
            -0.005883501842617989,
            -0.0023249653168022633,
            -0.013489757664501667,
            0.016636492684483528,
            0.008373793214559555,
            0.021632080897688866,
            -0.02393658272922039,
            0.004636818077415228,
            -0.007230966817587614,
            -0.01765456236898899,
            0.014262904413044453,
            0.03252936527132988,
            -0.016871603205800056,
            0.014752550050616264,
            0.0031759904231876135,
            0.003632316133007407,
            0.001025978708639741,
            -0.003132922574877739,
            0.013231881894171238,
            -0.03320556506514549,
            -0.006432732567191124,
            -0.0177738219499588,
            0.013380900025367737,
            -0.03441482037305832,
            -0.01403794065117836,
            0.004979356657713652,
            -0.01876881904900074,
            -0.007072285749018192,
            0.03388681635260582,
            0.011864901520311832,
            0.008763930760324001,
            -0.011967869475483894,
            0.03797384351491928,
            0.002589127514511347,
            0.005299423821270466,
            -0.0164056234061718,
            -0.0005331158172339201,
            -0.017482930794358253,
            0.029668953269720078,
            0.022733673453330994,
            0.027892112731933594,
            -0.004127011634409428,
            0.033687543123960495,
            0.021248474717140198,
            -0.018157297745347023,
            0.001854409696534276,
            -0.014094081707298756,
            -0.012316869571805,
            0.022936047986149788,
            0.021784808486700058,
            -0.003652493702247739,
            -0.004834939260035753,
            -0.013198457658290863,
            0.01877617835998535,
            0.03462526947259903,
            0.005651500541716814,
            0.03170868754386902,
            0.009196433238685131,
            -0.0013540504733100533,
            0.02962079457938671,
            4.612933821590559e-07,
            0.021578434854745865,
            0.0036481281276792288,
            -0.010580708272755146,
            0.02895868569612503,
            -0.0044247484765946865,
            0.033725805580616,
            0.02762931026518345,
            -0.02390231005847454,
            0.0011699156602844596,
            0.013235017657279968,
            -0.014076793566346169,
            0.004080984741449356,
            0.0015166203957051039,
            0.02072833850979805,
            0.006709869019687176,
            0.017793962731957436,
            -0.029826819896697998,
            -0.041707079857587814,
            -0.030476683750748634,
            0.001304985024034977,
            0.0012205763487145305,
            -0.030545812100172043,
            -0.005692478269338608,
            -0.035230088979005814,
            0.0027801068499684334,
            -0.009405515156686306,
            -0.014323515817523003,
            0.0327879972755909,
            -0.024327605962753296,
            -0.035486187785863876,
            0.0030927055049687624,
            -0.02270301803946495,
            0.0072392066940665245,
            0.01397083792835474,
            -0.010246481746435165,
            0.014423212967813015,
            -0.0024993803817778826,
            0.005242971237748861,
            -0.008034605532884598,
            -0.0002876657235901803,
            -0.00930608157068491,
            -0.01444524060934782,
            -0.012770302593708038,
            0.041834451258182526,
            -0.0013288829941302538,
            -0.01026059128344059,
            -0.010406658053398132,
            0.00899976771324873,
            -0.01860458217561245,
            0.025520464405417442,
            -0.02487071417272091,
            -0.03574247658252716,
            0.015588945709168911,
            -0.0004054539604112506,
            0.004607808776199818,
            0.002435646252706647,
            -0.019772136583924294,
            0.01677488163113594,
            -0.0018251838628202677,
            -0.013596009463071823,
            0.016227340325713158,
            0.01804538071155548,
            0.006135730538517237,
            -0.012014028616249561,
            0.028441887348890305,
            0.004453262314200401,
            0.013416662812232971,
            0.03753141686320305,
            0.00646532978862524,
            0.01466971356421709,
            0.01786988042294979,
            -0.02249382808804512,
            -0.0237427968531847,
            -0.008353783749043941,
            -0.030630216002464294,
            -0.024253185838460922,
            0.031720034778118134,
            0.005663305055350065,
            0.00044029875425621867,
            0.012128349393606186,
            -0.0009370679617859423,
            -0.01553268264979124,
            -0.009509555995464325,
            -0.004759281408041716,
            -0.010531500913202763,
            0.021859660744667053,
            -0.005381169728934765,
            -0.003742655972018838,
            0.012036501429975033,
            -0.0016953005688264966,
            -0.011019567027688026,
            -0.019660204648971558,
            0.020105579867959023,
            0.006717177573591471,
            -0.008263281546533108,
            0.013004410080611706,
            -0.028195349499583244,
            -0.024716272950172424,
            -0.014129587449133396,
            -0.013695953413844109,
            0.023821426555514336,
            0.041333429515361786,
            0.031197624281048775,
            -0.0011985251912847161,
            -0.04312355816364288,
            -0.006303435191512108,
            0.021411024034023285,
            -0.019386643543839455,
            -0.03002226911485195,
            -0.018075842410326004,
            -0.03498082235455513,
            0.015613113529980183,
            0.01222575455904007,
            -0.00010561531234998256,
            -0.009700947441160679,
            -0.002311046700924635,
            0.002921616891399026,
            -0.04852296784520149,
            -0.004699135199189186,
            -0.024242155253887177,
            0.01710936240851879,
            -0.02595188468694687,
            0.022963767871260643,
            0.04054025560617447,
            0.02601870894432068,
            -0.010482991114258766,
            0.007506681140512228,
            -0.004897765349596739,
            0.023671889677643776,
            -0.007092752493917942,
            0.031049329787492752,
            -0.016899876296520233,
            -0.007166603580117226,
            0.020894574001431465,
            0.0025438698939979076,
            0.030292926356196404,
            0.0028098616749048233,
            -0.015589852817356586,
            -0.016283242031931877,
            0.034052569419145584,
            0.0037595387548208237,
            -0.018615901470184326,
            0.003128912765532732,
            -0.0189447570592165,
            0.007068007253110409,
            0.028488799929618835,
            -0.02113756351172924,
            0.015821555629372597,
            -0.01390010118484497,
            -0.023691654205322266,
            -0.019281048327684402,
            -0.02619096264243126,
            0.008821943774819374,
            -0.0027643516659736633,
            0.026328036561608315,
            0.023338640108704567,
            0.01984209008514881,
            -0.015338720753788948,
            0.012709748931229115,
            0.016861122101545334,
            -0.006153008434921503,
            -0.015603329986333847,
            0.017559247091412544,
            0.008070804178714752,
            -0.016417523846030235,
            0.01090218685567379,
            -0.05246444046497345,
            0.01473681628704071,
            -0.006820137146860361,
            -0.0015469322679564357,
            -0.014283385127782822,
            -0.021358415484428406,
            -0.011368731036782265,
            -0.013570493087172508,
            -0.01225461158901453,
            0.009007362648844719,
            -0.022817090153694153,
            -0.02228565514087677,
            -0.03490641713142395,
            -0.038778115063905716,
            -0.010561328381299973,
            -0.0234119463711977,
            0.019630633294582367,
            -0.01636641100049019,
            0.03238506615161896,
            0.014459045603871346,
            -0.0025449751410633326,
            -0.02002369798719883,
            -0.004952032584697008,
            0.018011752516031265,
            0.02445303648710251,
            0.024243952706456184,
            -0.04779469221830368,
            -0.02142651379108429,
            -0.011844943277537823,
            -0.015463070012629032,
            0.00974982138723135,
            -0.003147250972688198,
            0.01693735085427761,
            -0.015289809554815292,
            0.03201465308666229,
            0.027595166116952896,
            -0.01934622786939144,
            0.017105186358094215,
            -0.0035010878928005695,
            0.04064786061644554,
            0.014082459732890129,
            0.002302681328728795,
            0.002524849958717823,
            -0.04505313187837601,
            0.014252044260501862,
            -0.02968030795454979,
            -0.006078165955841541,
            -0.0178389772772789,
            -0.002563081681728363,
            0.020086584612727165,
            -0.014307842589914799,
            -0.02872026525437832,
            -0.008040284737944603,
            -0.02897745370864868,
            -0.0790768414735794,
            0.007531553506851196,
            -0.022126998752355576,
            0.023069407790899277,
            0.006134228315204382,
            -0.011052567511796951,
            0.010314364917576313,
            -0.02928493358194828,
            -0.01757674291729927,
            -0.009801558218896389,
            -0.024265548214316368,
            -0.01546852570027113,
            -0.0334787592291832,
            0.014529760926961899,
            0.01401917077600956,
            0.02849387750029564,
            -0.02093004807829857,
            0.005724127404391766,
            0.005059683695435524,
            0.003382152644917369,
            0.0019352907547727227,
            -0.03033561073243618,
            -0.013651390559971333,
            -0.006502680014818907,
            0.00601009139791131,
            0.024606453254818916,
            -0.0014563105069100857,
            0.022764278575778008,
            -0.016130799427628517,
            0.01763767935335636,
            -0.03326132521033287,
            0.029774324968457222,
            0.011364459991455078,
            0.002009717980399728,
            0.028969116508960724,
            -0.03269590064883232,
            -0.03152192384004593,
            -0.039800986647605896,
            -0.02827274054288864,
            -0.003306975122541189,
            0.010465966537594795,
            0.020758377388119698,
            -0.006271085236221552,
            0.01370526384562254,
            -0.035010408610105515,
            -0.026345107704401016,
            -0.016857663169503212,
            0.011654707603156567,
            0.02909891866147518,
            -0.02394653670489788,
            -0.0037189314607530832,
            -0.0005287734093144536,
            -0.009167084470391273,
            -0.007078662980347872,
            0.02200920507311821,
            0.004269333090633154,
            0.013599623925983906,
            -0.013699774630367756,
            -0.028048481792211533,
            0.009103267453610897,
            -0.004589661490172148,
            0.013291754759848118,
            -0.0033235622104257345,
            0.029763968661427498,
            -0.007686817552894354,
            0.026388606056571007,
            -0.02050948515534401,
            -0.022958306595683098,
            0.026145480573177338,
            -0.017834026366472244,
            -0.0039392453618347645,
            0.001211299211718142,
            0.02682211808860302,
            0.011856458149850368,
            -0.01349928043782711,
            0.020624032244086266,
            -0.06799720972776413,
            0.0178377665579319,
            0.007935015484690666,
            -0.04618297517299652,
            0.0023667479399591684,
            0.010964198969304562,
            -0.025926873087882996,
            0.03358166664838791,
            0.010606248863041401,
            0.02912510186433792,
            0.006882995832711458,
            0.03038131259381771,
            -0.029021721333265305,
            0.007945219054818153,
            0.021312078461050987,
            0.003809954971075058,
            0.0016579836374148726,
            0.025820761919021606,
            -0.05758463591337204,
            0.012350717559456825,
            0.025191670283675194,
            0.021538447588682175,
            -0.01641664281487465,
            -0.018087223172187805,
            0.0013200509129092097,
            -0.0052611008286476135,
            -0.009753506630659103,
            -0.03484279289841652,
            -0.00201676064170897,
            0.027080830186605453,
            0.005833852104842663,
            -0.0006828878540545702,
            0.015290236100554466,
            0.004057778511196375,
            0.0002601008163765073,
            0.022341018542647362,
            -0.03246353566646576,
            -0.013608485460281372,
            0.018376486375927925,
            0.005725180264562368,
            -0.0459061823785305,
            0.008385950699448586,
            -0.00997603964060545,
            0.005521133076399565,
            -0.040418483316898346,
            0.04418014734983444,
            -0.005818203091621399,
            0.016419075429439545,
            0.00023990115732885897,
            0.009729724377393723,
            0.006849103141576052,
            -0.0035028858110308647,
            0.0249923188239336,
            0.0020986637100577354,
            0.011525153182446957,
            0.00804177112877369,
            -0.00806594267487526,
            -0.006246436852961779,
            0.01975627802312374,
            -0.012238058261573315,
            -0.01343957707285881,
            0.031131697818636894,
            -0.00066050497116521,
            -0.005568625871092081,
            -0.0025104538071900606,
            0.001232823240570724,
            -0.03709297254681587,
            -0.024994509294629097,
            0.015753861516714096,
            0.009482762776315212,
            0.02275177091360092,
            0.017273075878620148,
            -0.03414958715438843,
            -0.010178451426327229,
            0.02549123391509056,
            -0.022622501477599144,
            0.021625911816954613,
            -0.009198350831866264,
            0.03165096044540405,
            -0.007659636903554201,
            0.02744743973016739,
            -0.053709812462329865,
            0.020826511085033417,
            0.01566402241587639,
            0.01733253337442875,
            0.005685280542820692,
            -0.009114772081375122,
            0.011789513751864433,
            0.0322054959833622,
            -0.02216615155339241,
            0.026119574904441833,
            0.02338891103863716,
            -0.012056902050971985,
            6.558044697158039e-05,
            0.012100647203624249,
            0.0215948186814785,
            0.006472686771303415,
            0.012419089674949646,
            0.0293718371540308,
            0.007266904227435589,
            0.00878305733203888,
            -0.030225707218050957,
            0.018348587676882744,
            0.026541542261838913,
            -0.012373298406600952,
            -0.011022176593542099,
            0.010946034453809261,
            -0.020291674882173538,
            -0.003906523808836937,
            -0.01866290159523487,
            0.011836592108011246,
            0.005820862017571926,
            0.0024727436248213053,
            -0.013440591283142567,
            0.028826279565691948,
            -0.00791397225111723,
            0.03618428856134415,
            0.010344469919800758,
            0.015295232646167278,
            0.020776325836777687,
            -0.03644362464547157,
            -0.02033442258834839,
            0.05647828429937363,
            0.03769033029675484,
            -0.0064847287721931934,
            0.0036311126314103603,
            -0.027339428663253784,
            -0.0033242744393646717,
            0.048879362642765045,
            -0.001523469341918826,
            -0.017480751499533653,
            0.02716038189828396,
            -0.0029298008885234594,
            0.034090347588062286,
            -0.003261434379965067,
            0.011326100677251816,
            -0.01717381551861763
        ],
        [
            0.007846761494874954,
            0.02636488899588585,
            -0.00483494671061635,
            0.017628246918320656,
            -0.028909562155604362,
            -0.013674091547727585,
            0.012875717133283615,
            0.007622802630066872,
            0.007464363239705563,
            -0.02426423504948616,
            -0.0205564945936203,
            0.005584936589002609,
            -0.027759691700339317,
            0.013457385823130608,
            0.025388818234205246,
            0.026007860898971558,
            0.0034618638455867767,
            0.006619531661272049,
            -0.015656281262636185,
            -0.01759374514222145,
            -0.0022035129368305206,
            -0.009429501369595528,
            -0.018623795360326767,
            -0.014467940665781498,
            0.01245873048901558,
            -0.006366361863911152,
            -0.02903476357460022,
            0.0056236423552036285,
            -0.026688629761338234,
            -0.015049545094370842,
            -0.013537617400288582,
            -0.031125284731388092,
            0.024693526327610016,
            0.00627971813082695,
            0.007849200628697872,
            0.0010182010009884834,
            0.025292769074440002,
            0.011098744347691536,
            -0.010882556438446045,
            -0.012044950388371944,
            -0.00791875645518303,
            0.023807603865861893,
            0.007133520673960447,
            0.019098103046417236,
            0.0023621879518032074,
            0.02215161919593811,
            0.014592213556170464,
            -0.012227602303028107,
            0.019678190350532532,
            -0.025524526834487915,
            -0.022684849798679352,
            -0.025449790060520172,
            0.019235409796237946,
            0.018512029200792313,
            -0.00465020164847374,
            0.004881874192506075,
            0.023486431688070297,
            0.0022200047969818115,
            0.02298109233379364,
            0.019924622029066086,
            -0.024694595485925674,
            0.02198927290737629,
            0.014850705862045288,
            -0.020507771521806717,
            -0.008390306495130062,
            -0.005494122859090567,
            -0.02891511097550392,
            -0.009890771470963955,
            -0.025717228651046753,
            0.023278791457414627,
            -0.030097831040620804,
            -0.020276151597499847,
            0.004281660076230764,
            -0.0298050157725811,
            -0.023604057729244232,
            -0.007636034395545721,
            0.019171513617038727,
            -0.007032573223114014,
            0.0018206151435151696,
            0.011636454612016678,
            -0.006352132651954889,
            0.014154717326164246,
            0.01880696229636669,
            0.002959679812192917,
            -0.0025899261236190796,
            -0.02123536914587021,
            0.02077021822333336,
            -0.027585897594690323,
            0.02407659776508808,
            0.026598554104566574,
            -0.026335112750530243,
            0.026092028245329857,
            0.012345541268587112,
            0.004318538587540388,
            0.02438868209719658,
            -0.021111873909831047,
            -0.023317990824580193,
            0.02608206309378147,
            0.010946717113256454,
            0.004438191652297974,
            0.007058646529912949,
            -0.006086453795433044,
            -0.00971997156739235,
            -0.007706448435783386,
            -0.022372443228960037,
            0.006787816062569618,
            -0.020277049392461777,
            -0.02066851034760475,
            -0.006492476910352707,
            -0.026506194844841957,
            -0.030947348102927208,
            -0.017823029309511185,
            -0.021024474874138832,
            0.018900694325566292,
            -0.014815688133239746,
            -0.009867559187114239,
            -0.03213764354586601,
            -0.008772654458880424,
            0.008657343685626984,
            0.009566020220518112,
            0.010230476967990398,
            0.026339510455727577,
            -0.0085892453789711,
            -0.029167955741286278,
            0.02736690640449524,
            0.006629258394241333,
            -0.018266713246703148,
            -0.011541305109858513,
            -0.01287299394607544,
            0.019372303038835526,
            0.023903239518404007,
            0.01693279668688774,
            0.024096906185150146,
            0.029603395611047745,
            0.029957115650177002,
            0.016903191804885864,
            0.00768651906400919,
            0.008763637393712997,
            0.012661468237638474,
            -0.031709328293800354,
            -0.01577221415936947,
            -0.011020099744200706,
            -0.008809218183159828,
            -0.016710475087165833,
            -0.00903363898396492,
            -0.01963920332491398,
            -0.01626896858215332,
            0.0024758426006883383,
            0.0060883574187755585,
            -0.018907349556684494,
            0.02042848989367485,
            0.011987302452325821,
            -0.008934885263442993,
            -0.012488596141338348,
            0.021884068846702576,
            -0.015722114592790604,
            0.0008408762514591217,
            0.011330083943903446,
            0.019120246171951294,
            -0.009341694414615631,
            0.0012118574231863022,
            -0.024414457380771637,
            -0.019330818206071854,
            -0.009430881589651108,
            -0.009649473242461681,
            -0.02693035453557968,
            -0.016376618295907974,
            -0.008986294269561768,
            0.02775547467172146,
            0.01161184161901474,
            -0.02996070683002472,
            -0.01218334399163723,
            -0.022784288972616196,
            0.011641844175755978,
            -0.023628801107406616,
            -0.02004212886095047,
            -0.0002791291626635939,
            0.010199781507253647,
            0.007256872486323118,
            -0.017435386776924133,
            0.025067338719964027,
            -0.003951691091060638,
            -0.007125593721866608,
            -0.020311851054430008,
            0.0015139905735850334,
            -0.013478601351380348,
            0.020686671137809753,
            0.008274607360363007,
            -0.006939433515071869,
            -0.013696003705263138,
            0.01202026754617691,
            -0.01695726439356804,
            -0.015120010823011398,
            0.021716788411140442,
            -0.01314494013786316,
            -0.0015319518279284239,
            0.010728694498538971,
            0.006184369325637817,
            -0.02191290631890297,
            0.029972096905112267,
            -0.022391855716705322,
            -0.02812011167407036,
            0.026065954938530922,
            0.009114615619182587,
            0.01692105084657669,
            -0.007089737802743912,
            -0.005481217056512833,
            0.019101601094007492,
            -0.017247814685106277,
            0.02829950675368309,
            -0.028623957186937332,
            -0.020539745688438416,
            -0.024229485541582108,
            -0.00827798992395401,
            0.004992328584194183,
            -0.026939183473587036,
            -0.023881010711193085,
            -0.024597901850938797,
            -0.002984497230499983,
            0.008135825395584106,
            0.01308349147439003,
            0.016727950423955917,
            0.008428391069173813,
            -0.017029045149683952,
            0.01575389876961708,
            -0.019617028534412384,
            0.03072439879179001,
            0.0038025937974452972,
            -0.025088943541049957,
            -0.025024596601724625,
            -0.0180201418697834,
            -0.022340724244713783,
            -0.025890864431858063,
            0.020989686250686646,
            -0.017153732478618622,
            -0.0012013657251372933,
            -0.006724925711750984,
            -0.02429959923028946,
            -0.01866775006055832,
            0.0031773746013641357,
            0.029317263513803482,
            -0.028407150879502296,
            0.013064857572317123,
            -0.008500689640641212,
            0.02722742408514023,
            -0.017476258799433708,
            -0.029938705265522003,
            -0.022918839007616043,
            -0.022659283131361008,
            -0.02693496271967888,
            -0.007289733737707138,
            -0.027483854442834854,
            0.02843661792576313,
            -0.015555743128061295,
            -0.011173482984304428,
            0.01738973706960678,
            0.02360742911696434,
            0.006533142179250717,
            -0.0016146552516147494,
            -0.020308274775743484,
            -0.01447850838303566,
            -0.01855611801147461,
            0.003279312513768673,
            -0.022624533623456955,
            0.007863812148571014,
            -0.027692850679159164,
            -0.012250963598489761,
            0.0038845501840114594,
            0.030332960188388824,
            -0.016039937734603882,
            0.010852241888642311,
            0.028506681323051453,
            -0.02955460362136364,
            0.020508848130702972,
            -0.007054030895233154,
            0.006396546959877014,
            0.010759249329566956,
            -0.013466772623360157,
            0.011501237750053406,
            0.023967860266566277,
            -0.023101184517145157,
            -0.029379967600107193,
            -0.01048679556697607,
            0.0023517534136772156,
            0.012479301542043686,
            0.0007499419152736664,
            -0.024352502077817917,
            0.014594070613384247,
            -0.027000470086932182,
            0.017705313861370087,
            -0.03021353855729103,
            0.031235292553901672,
            -0.0206201933324337,
            -0.008594023063778877,
            0.008797548711299896,
            -0.01382429525256157,
            0.028464026749134064,
            -0.025614000856876373,
            -0.008145350031554699,
            -0.005293010268360376,
            0.007869361899793148,
            0.030946191400289536,
            -0.007365942932665348,
            -0.003941674716770649,
            -0.00417756661772728,
            -0.003089025616645813,
            -0.005822180304676294,
            0.014371216297149658,
            -0.019177496433258057,
            0.025629565119743347,
            0.00958714634180069,
            -0.01765517145395279,
            0.020563406869769096,
            -0.02637907676398754,
            -7.819863094482571e-05,
            -0.02415212243795395,
            -0.028226755559444427,
            0.022898033261299133,
            0.003443841589614749,
            -0.02187974564731121,
            -0.00898495689034462,
            -0.00023911520838737488,
            0.022234082221984863,
            0.021916639059782028,
            -0.023640107363462448,
            -0.009664155542850494,
            -0.0035173147916793823,
            -0.006004504859447479,
            0.01623787172138691,
            0.0008093373035080731,
            -0.004320994950830936,
            0.004550032317638397,
            0.02891848422586918,
            0.003922026604413986,
            0.026613285765051842,
            -0.014533297158777714,
            -0.03046468272805214,
            -0.028294183313846588,
            -0.01753188669681549,
            0.01590515673160553,
            -0.020642995834350586,
            0.017044533044099808,
            0.003167729824781418,
            -0.002103903330862522,
            -0.002942493185400963,
            -0.020884878933429718,
            0.014420145191252232,
            0.015689339488744736,
            -0.01104811578989029,
            0.018473001196980476,
            0.017017371952533722,
            -0.012523971498012543,
            -0.01913904771208763,
            0.011218443512916565,
            0.010493669658899307,
            0.01380778569728136,
            -0.012891379185020924,
            -0.0198686383664608,
            0.01877816952764988,
            0.009665224701166153,
            -0.02260991930961609,
            -0.02928171679377556,
            0.02262742444872856,
            -0.002008192241191864,
            -0.02950984239578247,
            0.014519982039928436,
            0.01753588393330574,
            0.017331721261143684,
            0.015767868608236313,
            0.022967467084527016,
            0.01651155576109886,
            0.006982508115470409,
            -0.03171640262007713,
            -0.00385098485276103,
            0.013308174908161163,
            0.026155181229114532,
            0.009466610848903656,
            0.012133361771702766,
            0.01985965296626091,
            0.01815013587474823,
            0.024072054773569107,
            0.01729709841310978,
            -0.000542718917131424,
            0.028282519429922104,
            -0.01989859715104103,
            0.01105201430618763,
            -0.01845579780638218,
            -0.009551141411066055,
            0.007509335875511169,
            -0.010280449874699116,
            -0.030672747641801834,
            -0.021778367459774017,
            0.004458419047296047,
            -0.008250660263001919,
            0.0010449551045894623,
            -0.02267628163099289,
            0.028221772983670235,
            -0.026262711733579636,
            0.026708733290433884,
            -0.02962367981672287,
            0.0054525090381503105,
            -0.01746721938252449,
            0.02967263199388981,
            -0.001890739775262773,
            -0.018429413437843323,
            0.017756670713424683,
            -0.015709590166807175,
            0.028142204508185387,
            0.029456542804837227,
            -0.006934802979230881,
            0.018950775265693665,
            -0.023379411548376083,
            0.015644710510969162,
            0.006272009573876858,
            0.020740455016493797,
            -0.024891115725040436,
            -0.021218731999397278,
            0.014724761247634888,
            -0.017647188156843185,
            0.023463668301701546,
            -0.024997618049383163,
            -0.0027970217633992434,
            -0.020375151187181473,
            -0.02198568731546402,
            0.0019322186708450317,
            0.011224742978811264,
            0.02122444473206997,
            -0.02999958023428917,
            0.0021414724178612232,
            -0.02405083365738392,
            0.02743646875023842,
            -0.017909519374370575,
            0.02940966933965683,
            -0.0016074739396572113,
            -0.00012550503015518188,
            -0.02374262362718582,
            0.019956959411501884,
            -0.01586649939417839,
            0.0227828286588192,
            0.00925898551940918,
            0.013676393777132034,
            -0.016399838030338287,
            0.0198969766497612,
            -0.01101719867438078,
            -0.0013758707791566849,
            0.02031474933028221,
            0.0005270838737487793,
            0.025985414162278175,
            0.024463359266519547,
            0.027063744142651558,
            -0.020708724856376648,
            0.0009842199506238103,
            0.015092816203832626,
            -0.012993155047297478,
            -0.0037672221660614014,
            0.012570179998874664,
            -0.030438750982284546,
            0.004590805619955063,
            -0.014093119651079178,
            -0.015525788068771362,
            0.0026875208131968975,
            -0.020981479436159134,
            0.006588585674762726,
            -0.004659391939640045,
            0.00025524571537971497,
            -0.031025461852550507,
            -0.009670183062553406,
            0.00912027433514595,
            -0.01734917238354683,
            0.019377868622541428,
            -0.000752471387386322,
            0.0030623753555119038,
            0.020345158874988556,
            -0.006001286208629608,
            -0.020806092768907547,
            -0.01687580719590187,
            0.020639890804886818,
            0.012758169323205948,
            -0.027719374746084213,
            -0.010256296955049038,
            0.00024861577549017966,
            0.0009869672358036041,
            -0.01597476750612259,
            -0.024692917242646217,
            0.028848588466644287,
            -0.027798082679510117,
            -0.004164404701441526,
            0.029431268572807312,
            0.00620017945766449,
            0.027026303112506866,
            -0.019367102533578873,
            0.015290454961359501,
            -0.014447905123233795,
            0.023487024009227753,
            -0.02666177600622177,
            0.022084970027208328,
            -0.01491551473736763,
            0.0218915157020092,
            -0.01901903748512268,
            0.009859949350357056,
            -0.01842767931520939,
            -0.023275645449757576,
            -0.016899624839425087,
            0.028279578313231468,
            -0.015386201441287994,
            0.015156198292970657,
            -0.024556050077080727,
            -0.01857554167509079,
            -0.030865658074617386,
            0.026266615837812424,
            -0.007929906249046326,
            0.016767695546150208,
            0.00552265252918005,
            -0.00648312596604228,
            -0.011507345363497734,
            0.025738362222909927,
            0.01868705451488495,
            0.010822164826095104,
            -0.010943196713924408,
            0.011336380615830421,
            -0.023448217660188675,
            0.022726822644472122,
            0.01684970036149025,
            0.009532639756798744,
            0.013107865117490292,
            -0.002871315460652113,
            0.014294706284999847,
            -0.025979802012443542,
            -0.018037695437669754,
            -0.02472791075706482,
            -0.025271503254771233,
            0.02369263395667076,
            -0.021190740168094635,
            -0.013245168142020702,
            -0.01861684024333954,
            -0.02087109535932541,
            0.00439438596367836,
            -0.02278032898902893,
            0.017892004922032356,
            0.015515491366386414,
            -0.0122378533706069,
            0.001608680933713913,
            0.026429224759340286,
            -0.018194347620010376,
            0.022380851209163666,
            0.015785638242959976,
            0.010774659924209118,
            -0.02644268050789833,
            0.0005142660229466856,
            -0.01173710823059082,
            -0.003615604480728507,
            0.008098659105598927,
            -0.011420764960348606,
            -0.030029278248548508,
            0.024244066327810287,
            -0.023748215287923813,
            0.011595799587666988,
            -0.018219972029328346,
            -0.023532075807452202,
            0.01784636080265045,
            -0.014057889580726624,
            0.031028322875499725,
            0.016542160883545876,
            0.013600453734397888,
            0.024546070024371147,
            -0.003603976219892502,
            0.0006458386778831482,
            0.012490272521972656,
            0.00339517742395401,
            -0.011852654628455639,
            0.018781710416078568,
            0.011362713761627674,
            -0.015952734276652336,
            -0.02260124497115612,
            -0.011850427836179733,
            0.01700264774262905,
            0.017068419605493546,
            0.022981692105531693,
            -0.009232234209775925,
            -0.03174237534403801,
            -0.009607442654669285,
            0.014047261327505112,
            -0.0005568787455558777,
            -0.02978481724858284,
            0.00862122606486082,
            -0.01831822842359543,
            0.023989086970686913,
            0.005801000166684389,
            -0.027616433799266815,
            -0.02830064669251442,
            -0.0032691098749637604,
            -0.0017264299094676971,
            0.008385125547647476,
            0.02059614285826683,
            -0.020820854231715202,
            -0.008314065635204315,
            0.02893458679318428,
            0.008628830313682556,
            0.02286520227789879,
            0.02465030737221241,
            -0.021003171801567078,
            -0.0229397714138031,
            -0.019437536597251892,
            -0.013245227746665478,
            0.02685433253645897,
            -0.031838901340961456,
            0.024227328598499298,
            -0.021775832399725914,
            -0.010678645223379135,
            0.016454003751277924,
            0.01963581144809723,
            0.02059522084891796,
            0.005762044340372086,
            -0.002395492047071457,
            0.024370577186346054,
            -0.021190689876675606,
            -0.010724701918661594,
            0.0006932633114047348,
            0.003350490005686879,
            -0.028821386396884918,
            -0.021169237792491913,
            0.02621331810951233,
            0.005110330879688263,
            -0.029588546603918076,
            0.022767305374145508,
            -0.01867615431547165,
            -0.026049470528960228,
            0.004648241214454174,
            0.0070899128913879395,
            0.021293044090270996,
            0.0038094855844974518,
            -0.0016707165632396936,
            0.024371370673179626,
            0.007654947228729725,
            0.01691996306180954,
            -0.005444896407425404,
            0.025017380714416504,
            -0.012663464993238449,
            -0.017752598971128464,
            0.01113007590174675,
            -0.026456665247678757,
            -0.0087382011115551,
            -0.012438464909791946,
            0.017326030880212784,
            -0.02018132247030735,
            -0.030167344957590103,
            -0.019553299993276596,
            -0.003509427420794964,
            0.006919817999005318,
            0.024449005722999573,
            0.028047436848282814,
            0.006425708532333374,
            -0.021474864333868027,
            -0.025259021669626236,
            0.008369244635105133,
            0.012615312822163105,
            -0.009190518409013748,
            -0.027297286316752434,
            -0.013441371731460094,
            -0.02067355439066887,
            -0.005055730696767569,
            0.02304648421704769,
            0.0005688553210347891,
            -0.027899108827114105,
            0.02317463792860508,
            -0.008322161622345448,
            -0.024743445217609406,
            0.01844830811023712,
            -0.026051556691527367,
            -0.002577397972345352,
            -0.009473562240600586,
            0.0043280962854623795,
            0.02682512253522873,
            0.026597097516059875,
            0.004502313677221537,
            0.0020827017724514008,
            -0.011342759244143963,
            0.02320115827023983,
            -0.013567589223384857,
            0.02302781492471695,
            -0.014093413949012756,
            0.027562182396650314,
            -0.02717481181025505,
            -0.026386501267552376,
            0.01799110695719719,
            0.01883060485124588,
            0.014907211065292358,
            -0.018114812672138214,
            -0.006252162158489227,
            0.012915457598865032,
            0.00842268019914627,
            -0.022288430482149124,
            -0.02321135252714157,
            0.014205481857061386,
            0.015315122902393341,
            -0.02680371142923832,
            -0.025785421952605247,
            -0.012109547853469849,
            -0.019107311964035034,
            0.003345092758536339,
            -0.009608366526663303,
            -0.01165284588932991,
            1.76812827703543e-05,
            -0.026904575526714325,
            0.001321304589509964,
            -0.005645261611789465,
            0.007357782684266567,
            0.02531501092016697,
            -0.015093319118022919,
            -0.0011821262305602431,
            -0.018581319600343704,
            0.022330662235617638,
            -0.011511780321598053,
            0.007417536340653896,
            -0.026976536959409714,
            0.015644147992134094,
            0.000592011958360672,
            -0.02606440708041191,
            0.011433432810008526,
            -0.016119446605443954,
            -0.011595748364925385,
            0.025451598688960075,
            0.006562029477208853,
            -0.01835203915834427,
            0.024118175730109215,
            0.008208326064050198,
            0.0017374939052388072,
            0.0013069510459899902,
            0.008455571718513966,
            0.024097759276628494,
            0.005560670047998428,
            -0.014046376571059227,
            0.0004365183413028717,
            -0.02959667332470417,
            -0.014672067016363144,
            -0.03184821084141731,
            -0.0058252220042049885,
            -0.006251290440559387,
            -0.0012844279408454895,
            0.0252880547195673,
            -0.00041549649904482067,
            0.014817746356129646,
            -0.004296004772186279,
            0.015770230442285538,
            -0.030934937298297882,
            0.024213068187236786,
            0.013194646686315536,
            -0.007660992443561554,
            -0.029816608875989914,
            -0.023012811318039894,
            -0.0037188692949712276,
            -0.024378444999456406,
            -0.001253598602488637,
            0.006674975156784058,
            -0.026596833020448685,
            0.020254332572221756,
            0.011573906987905502,
            0.014159128069877625,
            -0.02207120507955551,
            -0.002203926909714937,
            0.020703494548797607,
            0.011371184140443802,
            -0.014284167438745499,
            0.02678167074918747,
            0.0036107106134295464,
            -0.016203895211219788,
            -0.00886555016040802,
            0.02781546860933304,
            -0.004755095578730106,
            0.004378369078040123,
            0.021227464079856873,
            -0.021210331469774246,
            -0.005928024649620056,
            0.0027431058697402477,
            -0.005372280720621347,
            0.007809575647115707,
            0.029245279729366302,
            0.014249768108129501,
            -0.0036929179914295673,
            0.0007796683930791914,
            0.018544334918260574,
            -0.010305437259376049,
            0.0027943530585616827,
            0.0012632422149181366,
            -0.0049177794717252254,
            0.025013521313667297,
            0.01200120896100998,
            -0.029818177223205566,
            0.009998932480812073,
            -0.005902186036109924,
            -0.014411590993404388,
            0.017240112647414207,
            -0.03214884549379349,
            0.007971437647938728,
            0.0004749186336994171,
            0.012518733739852905,
            -0.0043329522013664246,
            -0.0027497336268424988,
            -0.028546098619699478,
            -0.028821228072047234,
            0.007533875294029713,
            -0.030918855220079422,
            -0.027448557317256927,
            -0.019851285964250565,
            -0.010626651346683502,
            0.012587253004312515,
            -0.023336205631494522,
            -0.008219216950237751,
            -0.026291755959391594,
            -0.016039643436670303,
            -0.02917873114347458,
            -0.008923344314098358,
            -0.003723401576280594,
            -0.011693399399518967,
            -0.009938016533851624,
            -0.0300404354929924,
            -0.022076835855841637,
            0.004421483725309372,
            -0.003571200417354703,
            -0.013005424290895462,
            -0.002948399633169174,
            -0.011990558356046677,
            -0.015351328998804092,
            0.01689077913761139,
            -0.015291091054677963,
            -0.01666923612356186,
            -0.005545537918806076,
            0.008902380242943764,
            -0.026040298864245415,
            0.00787729024887085,
            -0.028556128963828087,
            -0.013374857604503632,
            -0.03183294087648392,
            0.0061558932065963745,
            -0.007389374077320099,
            0.019776009023189545,
            0.024821482598781586,
            -0.012592350132763386,
            -0.01682128570973873,
            0.005116872955113649,
            -0.016923006623983383,
            -0.010107394307851791,
            0.017072979360818863,
            0.003004498779773712,
            -0.0257597416639328,
            -0.031785767525434494,
            0.02343115769326687,
            -0.022772621363401413,
            -0.012454714626073837,
            0.018105942755937576,
            -0.014717359095811844,
            -0.008375734090805054,
            -0.020370274782180786,
            0.021153360605239868,
            -0.030737845227122307,
            0.015582974068820477,
            -0.000711795233655721,
            0.012304544448852539,
            0.02175995707511902,
            -0.029650621116161346,
            -0.03154313936829567,
            0.011679445393383503,
            -0.0016280467389151454,
            0.020087730139493942,
            0.0106576569378376,
            -0.022961637005209923,
            0.02617792785167694,
            -0.015525471419095993,
            -0.01011104416102171,
            -0.017871959134936333,
            -0.026293408125638962,
            0.013607491739094257,
            0.02185257151722908,
            0.0006728842854499817,
            0.026212412863969803,
            -0.01127229817211628,
            -0.0010577663779258728,
            -0.0036905035376548767,
            -0.02419966086745262,
            0.006576027721166611,
            -0.007312852889299393,
            -0.010334596037864685,
            -0.020635953173041344,
            0.016328483819961548,
            -0.00553154107183218,
            0.010225329548120499,
            -0.02930629253387451,
            -0.011324722319841385,
            -0.01163850724697113,
            0.0025192387402057648,
            0.015066694468259811,
            -0.021526101976633072,
            0.0010346658527851105,
            -0.023111745715141296,
            -0.02059701457619667,
            -0.0037156566977500916,
            0.013449003919959068,
            0.017880577594041824,
            0.003036441281437874,
            0.02016260474920273,
            -0.0007998645305633545,
            -0.013933214358985424,
            0.0033869408071041107,
            -0.021766524761915207,
            0.0033883266150951385,
            0.010111145675182343,
            0.0004606298462022096,
            -0.00875391811132431,
            -0.023158520460128784,
            0.019917653873562813,
            -0.00923696905374527,
            0.009964633733034134,
            -0.025507111102342606,
            -0.007843066938221455,
            -0.022853799164295197,
            0.011633588001132011,
            -0.00505418237298727,
            0.004169914871454239,
            -0.030798278748989105,
            -0.000629172136541456,
            -0.013588976114988327,
            -0.0030138790607452393,
            -0.019323281943798065,
            0.006769359111785889,
            -0.027663633227348328,
            -0.012421710416674614,
            -0.026951469480991364,
            0.007271546870470047,
            0.020524825900793076,
            0.009270673617720604,
            -0.00011775120219681412,
            0.014402434229850769,
            0.026855546981096268,
            -0.029870547354221344,
            0.0005116733373142779,
            0.008236149325966835,
            -0.021565277129411697,
            -0.024400826543569565,
            -0.00982234999537468,
            0.02315256930887699,
            0.010986373759806156,
            0.007837771438062191,
            0.004379525780677795,
            0.01561061292886734,
            0.023596392944455147,
            0.004871317185461521,
            -0.026708420366048813,
            -0.028649210929870605,
            0.028689876198768616,
            -0.030267998576164246,
            0.02861897274851799,
            -0.004684585612267256,
            0.01902354322373867,
            0.02951142191886902,
            -0.030289243906736374,
            0.030440736562013626,
            -0.008721569553017616,
            0.015203304588794708,
            -0.007628720253705978,
            -0.0075908564031124115,
            0.004823435563594103,
            -0.017621856182813644,
            -0.0042987726628780365,
            0.016004174947738647,
            0.010297596454620361,
            0.0060827163979411125,
            -0.0011832129675894976,
            -0.0019147098064422607,
            -0.006091002374887466,
            0.009348178282380104,
            0.021350717172026634,
            -0.011661365628242493,
            -0.002258648630231619,
            -0.02953537181019783,
            -0.014516385272145271,
            0.004988338798284531,
            0.024757228791713715,
            0.01845306158065796,
            -0.018029579892754555,
            0.02991853654384613,
            0.02006680890917778,
            0.015456963330507278,
            0.0037279725074768066,
            -0.009711428545415401,
            0.028228309005498886,
            0.02758760377764702,
            -0.020473629236221313,
            -0.006111215800046921,
            0.029046185314655304,
            0.009568016976118088,
            -0.018877215683460236,
            -0.030301282182335854,
            0.013776707462966442,
            0.021167263388633728,
            -0.002994861453771591,
            -0.011335248127579689,
            0.030108485370874405,
            0.030887547880411148,
            -0.002581629902124405,
            -0.0019542400259524584,
            0.011981443502008915,
            -0.0005725584924221039,
            0.011803694069385529,
            0.011364851146936417,
            0.0018521323800086975,
            -0.01203849259763956,
            -0.02669932320713997,
            0.029664363712072372,
            0.0282302126288414,
            0.0008292871643789113,
            0.010471586138010025,
            -0.010922446846961975,
            0.017353743314743042,
            0.004620078951120377,
            0.02751423418521881,
            -0.027411947026848793,
            0.024168701842427254,
            0.016108423471450806,
            -0.02355976402759552,
            0.012292416766285896,
            -0.02910306304693222,
            0.028524894267320633,
            0.023889943957328796,
            -0.008699029684066772
        ],
        [
            0.02597794122993946,
            -0.033908549696207047,
            -0.006143979728221893,
            -0.004968786146491766,
            -0.019867876544594765,
            0.001948979333974421,
            -0.014449004083871841,
            -0.0157930888235569,
            -0.021244050934910774,
            0.005701124202460051,
            -0.01840975135564804,
            -0.01627948507666588,
            0.02359374798834324,
            -0.012313194572925568,
            -0.04741455242037773,
            -0.02726762555539608,
            0.006610652897506952,
            0.041631635278463364,
            -0.006934836972504854,
            0.01720237545669079,
            0.007963399402797222,
            -0.014394386671483517,
            -0.018125414848327637,
            -0.02479928359389305,
            -0.01262671034783125,
            0.022511076182127,
            0.0018865879392251372,
            -0.0046333083882927895,
            -0.029628772288560867,
            -0.013626457192003727,
            -0.004961713217198849,
            -0.0060649155639112,
            0.022536758333444595,
            0.00787986721843481,
            0.027164258062839508,
            0.017911456525325775,
            0.016664834693074226,
            0.025895066559314728,
            0.012307197786867619,
            0.029146693646907806,
            0.029243536293506622,
            -0.04263054206967354,
            0.015088696964085102,
            -0.011470804922282696,
            0.03588073328137398,
            -0.029297124594449997,
            0.011278950609266758,
            -0.006879066117107868,
            0.010542149655520916,
            -0.02756665088236332,
            -0.01608443632721901,
            -0.026615381240844727,
            -0.00720599852502346,
            0.0013391253305599093,
            0.060470692813396454,
            -0.02050584740936756,
            -0.00023742597841192037,
            -0.001069282996468246,
            0.01935511641204357,
            -0.016989704221487045,
            0.02546657994389534,
            -0.010899320244789124,
            -0.02502886764705181,
            -0.006319109816104174,
            0.011587297543883324,
            0.0031291632913053036,
            -0.016724569723010063,
            -0.025262128561735153,
            0.003921262454241514,
            0.028397319838404655,
            0.003755044424906373,
            0.03522753342986107,
            0.025241628289222717,
            0.012252073734998703,
            0.010433568619191647,
            0.04049789533019066,
            0.03630894795060158,
            0.011123092845082283,
            -0.009923174977302551,
            -0.0016582823591306806,
            -0.020679013803601265,
            0.002032568911090493,
            -0.020830079913139343,
            0.015472736209630966,
            -0.023070503026247025,
            -0.016836905851960182,
            0.004829942714422941,
            -0.015953034162521362,
            0.0031912685371935368,
            0.012648427858948708,
            0.020189564675092697,
            0.01193254441022873,
            0.006805614102631807,
            0.039772264659404755,
            0.01720590516924858,
            0.03453948721289635,
            -0.030130093917250633,
            0.0023550130426883698,
            -0.047982342541217804,
            -0.022976579144597054,
            0.022914795204997063,
            0.029693150892853737,
            -0.0019900985062122345,
            -0.022493934258818626,
            -0.016522428020834923,
            0.0184417013078928,
            -0.007693542167544365,
            0.040510136634111404,
            0.019934101030230522,
            -0.02616647072136402,
            -0.036404941231012344,
            -0.018323294818401337,
            0.008110572583973408,
            0.003777335863560438,
            -0.012387077324092388,
            -0.011922522448003292,
            0.026353759691119194,
            0.019894564524292946,
            -7.700249989284202e-05,
            -0.01300663873553276,
            -0.02305709756910801,
            0.011886579915881157,
            -0.032899364829063416,
            0.03310660272836685,
            -0.009125803597271442,
            0.03867866098880768,
            -0.017567019909620285,
            -0.008807270787656307,
            -0.02605281211435795,
            0.025643056258559227,
            -0.021758511662483215,
            0.013521394692361355,
            0.015372130088508129,
            -0.03433707356452942,
            0.004789392463862896,
            0.014531432650983334,
            -0.04366161674261093,
            0.021697556599974632,
            -0.026738908141851425,
            -0.01676405593752861,
            0.01220007799565792,
            0.020787721499800682,
            0.0319824293255806,
            -0.007872557267546654,
            0.027316659688949585,
            0.03344978019595146,
            0.033366262912750244,
            -0.002891611075028777,
            -0.002475172746926546,
            0.0017548012547194958,
            0.04072482883930206,
            0.007986142300069332,
            -0.011797322891652584,
            0.033552490174770355,
            -0.023526713252067566,
            0.029014289379119873,
            -0.007691942621022463,
            0.00976021308451891,
            0.006122696213424206,
            0.022580880671739578,
            -0.014351727440953255,
            -0.011684009805321693,
            -0.03207006677985191,
            -0.011880327947437763,
            -0.01945469155907631,
            0.027096044272184372,
            0.01365639828145504,
            -0.0015631065471097827,
            -0.02019670233130455,
            -0.0044248527847230434,
            -0.019889557734131813,
            -0.027953943237662315,
            0.013669922947883606,
            0.0030948075000196695,
            -0.023876119405031204,
            -0.03096509538590908,
            0.00575860682874918,
            0.010460812598466873,
            0.008095920085906982,
            0.03751137852668762,
            0.011926643550395966,
            0.0012079974403604865,
            -0.0035799876786768436,
            0.009538259357213974,
            0.021548854187130928,
            0.017616577446460724,
            -0.033457644283771515,
            0.021838512271642685,
            0.016888568177819252,
            0.03697811812162399,
            0.014496643096208572,
            -0.011209658347070217,
            -0.008167367428541183,
            -0.023057548329234123,
            -0.017117589712142944,
            0.02510804310441017,
            0.009915624745190144,
            0.023375820368528366,
            0.01671009138226509,
            0.004838244989514351,
            0.004029884934425354,
            0.025719622150063515,
            0.010481879115104675,
            -0.0070456527173519135,
            0.002414165297523141,
            0.029733380302786827,
            -0.04367927089333534,
            -0.008090376853942871,
            -0.024952678009867668,
            0.03023107536137104,
            0.0324678011238575,
            0.021156324073672295,
            -0.03146299347281456,
            0.009174666367471218,
            -0.01975904032588005,
            0.005981369409710169,
            0.013546896167099476,
            -0.02218613401055336,
            -0.031351592391729355,
            0.026482630521059036,
            -0.01484962459653616,
            0.016161978244781494,
            0.00509234145283699,
            -0.02099979668855667,
            -0.014293622225522995,
            -0.04168638586997986,
            0.011999189853668213,
            -0.012869265861809254,
            -0.012174095958471298,
            0.025864969938993454,
            -0.00732188206166029,
            -0.013473330065608025,
            0.017145419493317604,
            0.028740884736180305,
            0.030285576358437538,
            0.01773311384022236,
            -0.03357362002134323,
            -0.00675614457577467,
            -0.006036727223545313,
            0.034770842641592026,
            0.003615519031882286,
            0.009929653257131577,
            0.03298315033316612,
            0.00973794236779213,
            -0.002503749681636691,
            0.016828658059239388,
            0.015638859942555428,
            -0.009703023359179497,
            0.005672377068549395,
            0.025700686499476433,
            0.004992351867258549,
            -0.00886393804103136,
            0.012142373248934746,
            -0.011408049613237381,
            -0.007953386753797531,
            -0.004240988288074732,
            -0.029074957594275475,
            -0.01172907929867506,
            0.02311619184911251,
            -0.000982796773314476,
            0.003061337862163782,
            0.00983089953660965,
            0.00045828879228793085,
            -0.010549154132604599,
            0.0019283368019387126,
            -0.018743501976132393,
            0.001993798417970538,
            -0.02407548762857914,
            -0.003815404837951064,
            -0.004439805634319782,
            0.028187047690153122,
            0.009147219359874725,
            0.004795797634869814,
            -0.01981407403945923,
            -0.016942808404564857,
            0.0032347398810088634,
            0.004333873745054007,
            0.022628216072916985,
            -0.011520571075379848,
            -0.00688042351976037,
            -0.011664661578834057,
            -0.002005977788940072,
            0.011150281876325607,
            0.03216053918004036,
            -0.021199150010943413,
            -0.0020674592815339565,
            0.03122834675014019,
            0.0327325277030468,
            0.008158310316503048,
            0.010750096291303635,
            -0.01775902695953846,
            0.018060624599456787,
            -0.0251937173306942,
            -0.009888832457363605,
            0.0034595434553921223,
            0.03271562606096268,
            0.01761484146118164,
            0.0005599047872237861,
            -0.006138463970273733,
            -0.016074206680059433,
            0.011062460020184517,
            -0.0031005158089101315,
            0.0061698174104094505,
            0.012413996271789074,
            -0.035883307456970215,
            -0.017612410709261894,
            0.01070509571582079,
            -0.007271323818713427,
            0.009269083850085735,
            0.0008910305332392454,
            0.02839377336204052,
            -0.0057736653834581375,
            -0.02154388464987278,
            0.010152342729270458,
            0.021510520949959755,
            -0.007917115464806557,
            -0.01786382682621479,
            -0.01415885891765356,
            -0.0047197239473462105,
            0.01915963925421238,
            0.019932640716433525,
            0.0336587131023407,
            0.014992832206189632,
            -0.03379961475729942,
            0.03323002904653549,
            -0.016233112663030624,
            0.019470447674393654,
            -0.008547830395400524,
            -0.0026479652151465416,
            0.010638845153152943,
            0.014159086160361767,
            -0.008473922498524189,
            0.024019965901970863,
            0.007329908665269613,
            0.018378395587205887,
            -0.023533225059509277,
            0.002309174742549658,
            0.013180405832827091,
            -0.005708256736397743,
            -0.018636856228113174,
            -0.02580050192773342,
            0.017596649006009102,
            0.034601908177137375,
            -0.008528076112270355,
            -0.010490421205759048,
            -0.031122038140892982,
            -0.01778208464384079,
            0.015781324356794357,
            0.01899843104183674,
            -0.0026807996910065413,
            0.0005671711405739188,
            0.02617136761546135,
            0.02121693827211857,
            -0.001996600767597556,
            -0.01396977435797453,
            -0.017985399812459946,
            0.03250521793961525,
            0.03227221220731735,
            0.02436385117471218,
            -0.006061963737010956,
            -0.01566782034933567,
            -0.00893605686724186,
            0.002591014839708805,
            0.013413886539638042,
            -0.018910164013504982,
            -0.011245772242546082,
            -0.023812249302864075,
            0.022466281428933144,
            -0.031010987237095833,
            0.014393323101103306,
            0.013804355636239052,
            -0.012613371945917606,
            -0.013158063404262066,
            0.0329722985625267,
            0.02484801784157753,
            -0.01059182733297348,
            -0.0021213958971202374,
            -0.019484911113977432,
            -0.0057581099681556225,
            0.02451387420296669,
            -0.017780480906367302,
            -0.02743065357208252,
            -0.007772921118885279,
            -0.02367146871984005,
            -0.0295312087982893,
            0.007136180065572262,
            0.022262075915932655,
            0.021443389356136322,
            0.020462850108742714,
            0.02590859867632389,
            0.00014891680621076375,
            -0.014482103288173676,
            -0.016310622915625572,
            0.025202857330441475,
            -0.020431621000170708,
            0.00800918135792017,
            -0.01761684939265251,
            0.0307247806340456,
            0.024052945896983147,
            0.0006835856474936008,
            -0.017352517694234848,
            0.007759788539260626,
            0.002314439043402672,
            0.0304335318505764,
            0.027025708928704262,
            -0.03065343387424946,
            0.0004321519809309393,
            0.010672129690647125,
            0.0052430033683776855,
            0.015627069398760796,
            0.004934786353260279,
            -0.03260870650410652,
            -0.025828927755355835,
            -0.012911087833344936,
            -0.02803175151348114,
            0.021671852096915245,
            0.0337546281516552,
            0.03377937898039818,
            0.016628535464406013,
            0.012525727041065693,
            0.002473552944138646,
            0.01766042225062847,
            -0.010781897231936455,
            -0.013937455601990223,
            -0.009363345801830292,
            0.02103530429303646,
            0.0054683866910636425,
            -0.02524780109524727,
            0.02581894025206566,
            0.03900361806154251,
            0.015074518509209156,
            0.020665807649493217,
            0.0292544923722744,
            0.002255884697660804,
            -0.024346845224499702,
            0.027628691866993904,
            -0.0021457273978739977,
            -0.023710288107395172,
            0.0011968505568802357,
            0.0026144233997911215,
            -0.02406279183924198,
            -0.019298862665891647,
            0.017934277653694153,
            0.01950995624065399,
            -0.004340499639511108,
            0.007842853665351868,
            0.021476449444890022,
            -0.03516610339283943,
            -0.019407710060477257,
            0.005769975017756224,
            0.023042019456624985,
            0.018652210012078285,
            -0.015502884052693844,
            0.0336054190993309,
            0.004768844693899155,
            0.02133837155997753,
            -0.03629324957728386,
            -0.02675575204193592,
            -0.013085405342280865,
            -0.029366634786128998,
            -0.029722612351179123,
            -0.0011667205253615975,
            -0.017891423776745796,
            0.025462787598371506,
            0.022809026762843132,
            0.012728888541460037,
            0.005292103625833988,
            0.025158870965242386,
            0.026476457715034485,
            -0.01203508023172617,
            -0.019603973254561424,
            -0.01845697872340679,
            -0.009636319242417812,
            0.0005894046043977141,
            -0.034723274409770966,
            -0.0171627439558506,
            -0.024769403040409088,
            -0.0036348230205476284,
            -0.01152065210044384,
            -0.01637798734009266,
            0.019234320148825645,
            0.013785785995423794,
            -0.01034252718091011,
            0.0014508499298244715,
            -0.02171153575181961,
            0.005471527576446533,
            -0.022753234952688217,
            0.014287552796304226,
            0.049874693155288696,
            -0.025853635743260384,
            0.021632136777043343,
            0.01685040444135666,
            -0.026891853660345078,
            -0.030177084729075432,
            -0.008502920158207417,
            -0.00840262696146965,
            0.0008689587702974677,
            0.02206994965672493,
            -0.022706585004925728,
            -0.010981637984514236,
            0.019742153584957123,
            -0.004544298630207777,
            0.00851801224052906,
            0.020640183240175247,
            -0.02519434504210949,
            0.033376164734363556,
            -0.006316744722425938,
            -0.0213956069201231,
            -0.0007024300866760314,
            -0.01266526523977518,
            -0.006634967867285013,
            -0.011082054115831852,
            0.032784074544906616,
            0.002844530390575528,
            0.018002595752477646,
            -0.01403715182095766,
            -0.004572541918605566,
            -0.014793830923736095,
            0.004013342782855034,
            0.026779459789395332,
            0.001129865413531661,
            -0.021523278206586838,
            -0.011426360346376896,
            -0.01948004588484764,
            0.03051779791712761,
            0.026989374309778214,
            -0.0030385081190615892,
            0.02631485089659691,
            0.02815549261868,
            -0.014919128268957138,
            0.006123131141066551,
            -0.02207844890654087,
            -0.030228374525904655,
            -0.024510569870471954,
            0.020560510456562042,
            -0.003729821415618062,
            -0.0221798587590456,
            0.008246264420449734,
            0.019357655197381973,
            0.003437693929299712,
            0.016655929386615753,
            -0.015718040987849236,
            -0.0210903100669384,
            0.029135331511497498,
            -0.008367050439119339,
            0.0049497997388243675,
            -0.021001210436224937,
            -0.004589306190609932,
            0.020260002464056015,
            -0.024448256939649582,
            -0.007657632231712341,
            0.004825201351195574,
            0.005161584354937077,
            0.0332091860473156,
            -0.03719152882695198,
            0.03189901262521744,
            -0.0016702578868716955,
            -0.0005301699857227504,
            -0.01742340624332428,
            0.015055585652589798,
            0.02892696112394333,
            -0.019737763330340385,
            -0.015293982811272144,
            -0.014217779040336609,
            -0.00110517384018749,
            0.0028594471514225006,
            0.011798313818871975,
            0.01762484386563301,
            -0.023719120770692825,
            0.013298334553837776,
            0.03144598379731178,
            -0.016617828980088234,
            -0.02833261899650097,
            0.0018703937530517578,
            0.007440369576215744,
            -0.017617441713809967,
            0.004488124046474695,
            0.019891873002052307,
            0.015950845554471016,
            0.022761480882763863,
            -0.02458023466169834,
            0.015182146802544594,
            0.017167678102850914,
            -0.007192890625447035,
            0.008399187587201595,
            0.0087327491492033,
            -0.01966783031821251,
            0.01657792367041111,
            0.0322132408618927,
            -0.02492741495370865,
            0.004068267997354269,
            0.01229496207088232,
            0.007218394428491592,
            0.017924297600984573,
            0.00825861468911171,
            -0.017500221729278564,
            -0.02297348529100418,
            0.030762873589992523,
            -0.030285559594631195,
            0.009370854124426842,
            -0.0067018624395132065,
            0.013828483410179615,
            0.017533916980028152,
            -0.006882451940327883,
            -0.017984477803111076,
            0.013521217741072178,
            -0.029135167598724365,
            0.0026638763956725597,
            -0.0059275575913488865,
            -0.012720035389065742,
            0.010861103422939777,
            0.029312627390027046,
            -0.012002333998680115,
            0.014392536133527756,
            -0.023594938218593597,
            -0.0021173011045902967,
            0.005653048865497112,
            -0.030835824087262154,
            0.018791424110531807,
            0.022883854806423187,
            0.01382454577833414,
            0.02518703229725361,
            -0.002058129757642746,
            0.03696512430906296,
            0.020054101943969727,
            -0.03044658713042736,
            -0.007673103362321854,
            0.018640466034412384,
            -0.024300239980220795,
            0.006656703073531389,
            0.0017599425045773387,
            -0.03844885528087616,
            0.018788600340485573,
            -0.003877572948113084,
            -0.017945876345038414,
            0.0256001316010952,
            0.02847098931670189,
            0.0005356105393730104,
            -0.02288609743118286,
            -0.002559133805334568,
            0.003828451270237565,
            -0.04994308203458786,
            -0.03838840499520302,
            -0.02131851203739643,
            -0.020744258537888527,
            -0.015407316386699677,
            0.04345278441905975,
            -0.025478342548012733,
            0.004768663551658392,
            0.034707583487033844,
            -0.027126330882310867,
            0.01671627163887024,
            -0.003858431475237012,
            -0.005331138614565134,
            0.026882288977503777,
            -0.021649619564414024,
            0.014912388287484646,
            0.011985279619693756,
            -0.006054670084267855,
            0.01922224834561348,
            0.017919298261404037,
            0.014355369843542576,
            0.03112667426466942,
            0.009026319719851017,
            -0.01853197254240513,
            0.00032800418557599187,
            -0.0171462781727314,
            0.028651338070631027,
            0.029987318441271782,
            0.022805316373705864,
            -0.007410849444568157,
            0.010928820818662643,
            -0.022393839433789253,
            0.030589772388339043,
            0.01868387497961521,
            -0.019677022472023964,
            0.025233983993530273,
            0.0006781474803574383,
            0.006907780654728413,
            0.014249649830162525,
            -0.01583210565149784,
            0.017513465136289597,
            0.018773678690195084,
            -0.006623284425586462,
            -0.010605891235172749,
            0.015625610947608948,
            0.006546911783516407,
            0.009941973723471165,
            0.034279193729162216,
            0.010675369761884212,
            -0.016105029731988907,
            -0.004177896771579981,
            -0.018246162682771683,
            -0.010760216973721981,
            0.006468042731285095,
            -0.014479173347353935,
            -0.02869134396314621,
            0.024245519191026688,
            0.00830348115414381,
            0.0059125060215592384,
            0.02013036236166954,
            -0.011887702159583569,
            0.02644004300236702,
            -0.009584219194948673,
            -0.021372923627495766,
            0.007355754729360342,
            -0.024206731468439102,
            -0.015430528670549393,
            -0.016927197575569153,
            0.036879852414131165,
            0.01543519925326109,
            0.029343079775571823,
            -0.0092755863443017,
            0.009018191136419773,
            0.03258169814944267,
            0.02170039527118206,
            0.029049187898635864,
            0.0024771650787442923,
            -0.013373819179832935,
            0.026715761050581932,
            0.01814204640686512,
            0.004285761620849371,
            0.005784668959677219,
            -0.003661868628114462,
            -0.019963474944233894,
            -0.024118250235915184,
            0.0252895075827837,
            0.0014266361249610782,
            0.011990905739367008,
            0.019153324887156487,
            0.01036878488957882,
            0.029133472591638565,
            -0.0031155808828771114,
            -0.02488127164542675,
            0.003200044622644782,
            0.010549094527959824,
            0.017632899805903435,
            0.006653762888163328,
            0.03064633347094059,
            0.015093154273927212,
            0.008628702722489834,
            0.026233486831188202,
            0.026720622554421425,
            -0.0009872146183624864,
            -0.02844281680881977,
            -0.02374596893787384,
            0.043028198182582855,
            -0.019666852429509163,
            -0.00600161449983716,
            0.01770584098994732,
            0.027212249115109444,
            0.012630796059966087,
            -0.013428451493382454,
            -0.04666074365377426,
            0.008051443845033646,
            0.024175278842449188,
            -0.016902796924114227,
            -0.03320195898413658,
            0.03676837310194969,
            -0.02363417111337185,
            -0.01383532676845789,
            -0.037435974925756454,
            -0.01564997062087059,
            -0.016498416662216187,
            -0.019342822954058647,
            -0.004581984598189592,
            -0.011591227725148201,
            -0.027439605444669724,
            0.005951153580099344,
            0.014643947593867779,
            0.012924029491841793,
            0.015759486705064774,
            -0.019771603867411613,
            -0.024843521416187286,
            0.002177934627979994,
            -0.000988886458799243,
            0.023192698135972023,
            -0.022245122119784355,
            0.0018609981052577496,
            -0.008029347285628319,
            -0.009690129198133945,
            -0.022745281457901,
            -0.004276550840586424,
            0.020908446982502937,
            0.031121009960770607,
            0.026422247290611267,
            0.003818904748186469,
            0.0008717100718058646,
            -0.005604159086942673,
            -0.0038326235953718424,
            0.020046979188919067,
            -0.031211895868182182,
            0.012515437789261341,
            -0.038974929600954056,
            -0.010564223863184452,
            0.030234070494771004,
            0.013965890742838383,
            0.03161004185676575,
            0.024300163611769676,
            0.013704505749046803,
            -0.02139776200056076,
            -0.014337525703012943,
            0.02519751526415348,
            -0.02239074371755123,
            -0.027008671313524246,
            -0.032906219363212585,
            0.017907433211803436,
            0.02764676883816719,
            -0.01589658483862877,
            -0.031066572293639183,
            0.013548686169087887,
            -0.021528547629714012,
            -0.014065438881516457,
            0.022117624059319496,
            0.017614468932151794,
            -0.014045953750610352,
            0.030327795073390007,
            0.0231259074062109,
            0.019366545602679253,
            0.012578991241753101,
            0.01841365173459053,
            0.0296513419598341,
            -0.005867172963917255,
            0.01704341359436512,
            -0.019336454570293427,
            -0.016286859288811684,
            -0.0033104256726801395,
            0.02876034937798977,
            -0.01514338981360197,
            -0.015415361151099205,
            -0.008854358457028866,
            0.008991502225399017,
            0.0073205470107495785,
            0.012851606123149395,
            -0.001098646316677332,
            0.030146682634949684,
            -0.015108396299183369,
            -0.0026822874788194895,
            0.002043380169197917,
            0.013145631179213524,
            0.017865361645817757,
            0.01019566785544157,
            0.024533020332455635,
            -0.017367945984005928,
            0.020056581124663353,
            0.024948270991444588,
            0.014276715926826,
            -0.004508705344051123,
            -0.009285066276788712,
            -0.015980925410985947,
            0.014921308495104313,
            -0.021131107583642006,
            -0.016681278124451637,
            0.02830711007118225,
            -0.017327776178717613,
            -0.020440971478819847,
            -0.032247841358184814,
            -0.015322200022637844,
            0.011786716058850288,
            0.013789867050945759,
            0.013842647895216942,
            -0.016290782019495964,
            -0.03623906895518303,
            0.033493902534246445,
            -0.01297228317707777,
            0.020636528730392456,
            -0.02002306468784809,
            0.024336783215403557,
            -0.029324306175112724,
            -0.005379984155297279,
            0.029221659526228905,
            -0.028180135414004326,
            0.022557327523827553,
            -0.03594062104821205,
            -0.022550150752067566,
            0.037722352892160416,
            -0.010379580780863762,
            -0.034067098051309586,
            -0.003289496526122093,
            0.008213957771658897,
            0.02553734928369522,
            -0.003954146057367325,
            0.029310990124940872,
            0.02503795549273491,
            0.005040302406996489,
            0.015576365403831005,
            0.031622033566236496,
            -0.03543104603886604,
            -0.007267854642122984,
            0.011890934780240059,
            -0.004997357726097107,
            0.022355657070875168,
            0.009405218064785004,
            -0.0014633545652031898,
            0.0007285942556336522,
            0.009895467199385166,
            0.005396820604801178,
            0.022455472499132156,
            0.028095891699194908,
            0.011190413497388363,
            -0.012401353567838669,
            0.0348261296749115,
            -0.008877396583557129,
            -0.03042544797062874,
            -0.01835087314248085,
            0.0163134653121233,
            -0.015157865360379219,
            0.029120810329914093,
            -0.0002790369908325374,
            0.01305469498038292,
            0.009773178957402706,
            0.0037083656061440706,
            0.01968916319310665,
            0.020681705325841904,
            -0.0064602987840771675,
            -0.023332519456744194,
            -0.002946658991277218,
            0.029330076649785042,
            0.000737329653929919,
            0.016639040783047676,
            -0.004931170959025621,
            0.025646818801760674,
            -0.007366434205323458,
            0.0027411843184381723,
            0.004909450653940439,
            -0.011771644465625286,
            -0.02204412780702114,
            0.008684233762323856,
            0.005209337454289198,
            0.0008854569750837982,
            -0.006298622582107782,
            -0.022474482655525208,
            0.007211878430098295,
            -0.03457745909690857,
            -0.0024147583171725273,
            0.029173344373703003,
            0.004640533588826656,
            0.0155863668769598,
            -0.01010204292833805,
            0.002810837933793664,
            0.0012380221160128713,
            0.02209315448999405,
            -0.01207537017762661,
            0.007254899479448795,
            0.018667997792363167,
            0.02471320703625679,
            -0.02053612284362316,
            -0.04018204286694527,
            -0.005848423112183809,
            -0.01465487852692604,
            -0.023007705807685852,
            -0.004046267364174128,
            0.0022987856063991785,
            0.011736249551177025,
            -0.008454252034425735,
            0.021173451095819473,
            0.01476308237761259,
            -0.01535401027649641,
            -0.017108291387557983,
            0.019163532182574272,
            -0.032035235315561295,
            0.048662539571523666,
            -0.011328856460750103,
            -0.010687734000384808,
            0.02965542860329151,
            -0.028670836240053177,
            -0.04919380694627762,
            -0.017417972907423973,
            -0.025171242654323578,
            -0.017049521207809448,
            0.027991583570837975,
            0.019065406173467636,
            0.020921915769577026,
            0.02665582485496998,
            0.018680671229958534,
            -0.005587418098002672,
            -0.03615732863545418,
            0.01802373118698597,
            -0.02571776695549488,
            0.0049813855439424515,
            -0.014681602828204632,
            0.005726195871829987,
            -0.0123131750151515,
            -0.019741658121347427,
            0.0027003660798072815,
            -0.030569598078727722,
            0.018239712342619896,
            -0.005955314729362726,
            -0.011868158355355263,
            -0.0004744992183987051,
            -0.01731870323419571,
            0.023572897538542747,
            0.03678363189101219,
            0.02174009382724762,
            -0.020676279440522194,
            -0.0024116525892168283,
            0.013997985050082207,
            -0.019125837832689285,
            -0.0129249794408679,
            0.03154769539833069,
            -0.026019642129540443,
            0.030313529074192047,
            0.01235736533999443,
            -0.010177098214626312,
            -0.02691354602575302,
            -0.0009173832950182259,
            0.022622758522629738,
            0.030507836490869522,
            0.005345162935554981,
            -0.001289417501538992,
            0.00900351908057928,
            0.02498944103717804,
            -0.0064468770287930965,
            0.03012656979262829,
            -0.03415703400969505,
            -0.018148619681596756,
            -0.013554207980632782,
            0.02477099560201168,
            -0.03635469079017639,
            0.0018464872846379876,
            -0.034072764217853546,
            0.02131478488445282,
            -0.002724277088418603,
            -0.03094303235411644
        ],
        [
            -0.004304065834730864,
            0.033830005675554276,
            -0.0029536152724176645,
            0.00034046772634610534,
            0.03083980642259121,
            -0.019262267276644707,
            -0.03236786648631096,
            -0.020211821421980858,
            -0.031584084033966064,
            0.021539531648159027,
            -0.010008488781750202,
            -0.0010881141060963273,
            -0.005720340646803379,
            -0.01852116920053959,
            0.0341414250433445,
            0.020712969824671745,
            0.004391263704746962,
            0.024725280702114105,
            -0.005190533120185137,
            0.012086411006748676,
            -0.008899078704416752,
            -0.041779663413763046,
            -0.016490373760461807,
            -0.05163231119513512,
            0.0004856502346228808,
            0.009310450404882431,
            -0.04438850283622742,
            -0.05081462487578392,
            -0.037451811134815216,
            -0.017634974792599678,
            0.001310076448135078,
            -0.010588970966637135,
            0.007776452228426933,
            0.03694359213113785,
            0.014275345019996166,
            -0.07093357294797897,
            -0.030799539759755135,
            0.006194761488586664,
            0.02167227305471897,
            0.02895326353609562,
            -0.01611299254000187,
            0.011011343449354172,
            0.004191813990473747,
            -0.0019508954137563705,
            0.03909919410943985,
            -0.004738262388855219,
            0.00965263694524765,
            0.07388439774513245,
            -0.016482720151543617,
            -0.011243094690144062,
            0.011848609894514084,
            0.021042805165052414,
            -0.02885034680366516,
            -0.03001515381038189,
            0.034593261778354645,
            -0.0019496778259053826,
            -0.053030624985694885,
            0.002277923049405217,
            0.0026268812362104654,
            0.03035597875714302,
            0.03687600791454315,
            -0.017210019752383232,
            -0.008349072188138962,
            -0.022046122699975967,
            -0.016563840210437775,
            -0.004877657629549503,
            0.015602383762598038,
            -0.02384313754737377,
            0.02155185677111149,
            0.01212574727833271,
            -0.022418977692723274,
            0.019431831315159798,
            -0.015451830811798573,
            0.020278869196772575,
            -0.04960838332772255,
            -0.006150566041469574,
            -0.011538168415427208,
            -0.01300568412989378,
            0.04434817656874657,
            -0.018319878727197647,
            0.015486959367990494,
            0.013679683208465576,
            0.006986674387007952,
            -0.02440533973276615,
            0.026100393384695053,
            0.0665774717926979,
            -0.01203282829374075,
            0.009676443412899971,
            -0.04843045026063919,
            -0.015048016794025898,
            -0.03847518190741539,
            0.010497544892132282,
            -0.016097543761134148,
            -0.10163961350917816,
            -0.06827118992805481,
            0.015745235607028008,
            -0.022669600322842598,
            -0.03725692629814148,
            -0.06089266017079353,
            -0.011814145371317863,
            -0.006729715503752232,
            0.017639923840761185,
            0.0010993793839588761,
            0.021047405898571014,
            -0.019463540986180305,
            -0.010441566817462444,
            0.017615344375371933,
            0.005347385071218014,
            0.004506412427872419,
            0.013370572589337826,
            -0.03465433046221733,
            0.00035754276905208826,
            -0.05823972448706627,
            0.05129970982670784,
            -0.05474592745304108,
            0.010529103688895702,
            -0.029025603085756302,
            -0.009265108034014702,
            -0.002091336762532592,
            0.02878960780799389,
            -0.015031236223876476,
            0.00746331550180912,
            -0.06246494501829147,
            -0.003823236096650362,
            -0.0516614206135273,
            -0.04322367534041405,
            -0.049249883741140366,
            -0.02263672836124897,
            -0.01455572061240673,
            -0.029678912833333015,
            0.020051101222634315,
            0.010429710149765015,
            0.045165516436100006,
            0.056946080178022385,
            -0.03230505809187889,
            -0.047603048384189606,
            -0.016713593155145645,
            0.08641769737005234,
            -0.026694146916270256,
            0.010706029832363129,
            0.004803712945431471,
            -0.01776806078851223,
            0.019450869411230087,
            -0.03189776837825775,
            0.08234527707099915,
            -0.024830961599946022,
            0.03740005940198898,
            0.03474506735801697,
            -0.023630021139979362,
            0.02267281897366047,
            0.010991368442773819,
            -0.01085742935538292,
            -0.028349606320261955,
            0.04935580864548683,
            0.04823770001530647,
            -0.08538433909416199,
            0.008381003513932228,
            -0.05857261270284653,
            -0.01646609604358673,
            -0.08143544942140579,
            0.011667465791106224,
            -0.02110176905989647,
            0.04606851935386658,
            -0.023966148495674133,
            0.033991746604442596,
            -0.0959862470626831,
            -0.0662756860256195,
            -0.031084144487977028,
            -0.04164751619100571,
            0.027559513226151466,
            0.0020924543496221304,
            0.06637287139892578,
            0.05590105056762695,
            0.023208580911159515,
            -0.02200465090572834,
            -0.0098684998229146,
            0.0017234591068699956,
            -0.041770342737436295,
            -0.013303380459547043,
            -0.005422176327556372,
            -0.030500218272209167,
            -0.03227132931351662,
            0.033915549516677856,
            -0.009936502203345299,
            -0.020723382011055946,
            -0.015374613925814629,
            -0.02004747837781906,
            0.0028407559730112553,
            0.0010142496321350336,
            0.00028855830896645784,
            0.024776486679911613,
            -0.013622885569930077,
            -0.07139600068330765,
            -0.03290852904319763,
            -0.01685483008623123,
            0.02703767456114292,
            0.051184140145778656,
            -0.022342093288898468,
            0.022099796682596207,
            0.026789484545588493,
            0.0010417522862553596,
            0.016801008954644203,
            -0.004116914235055447,
            -0.001732907723635435,
            -0.11504613608121872,
            0.014162551611661911,
            0.005356958135962486,
            -0.01452258974313736,
            -0.044886521995067596,
            5.5761425755918026e-05,
            0.009843784384429455,
            0.05440739169716835,
            -0.018447300419211388,
            -0.015866663306951523,
            0.04743806645274162,
            -0.02179632894694805,
            -0.008994822390377522,
            -0.041426025331020355,
            0.021933620795607567,
            -0.030042748898267746,
            0.024330386891961098,
            0.03342776745557785,
            -0.028877509757876396,
            0.03209901973605156,
            0.020975980907678604,
            0.006207190919667482,
            0.004578806925565004,
            -0.01204682793468237,
            -0.04426953196525574,
            -0.029116341844201088,
            -0.016651906073093414,
            -0.01967187225818634,
            0.0034593897871673107,
            -0.016075313091278076,
            0.015822429209947586,
            -0.02865009941160679,
            0.01057573314756155,
            0.02265242300927639,
            0.012691044248640537,
            -0.03272658586502075,
            0.02988622896373272,
            -0.012208967469632626,
            -0.0163332000374794,
            -0.009378883987665176,
            0.07773695886135101,
            -0.004060682374984026,
            -0.0031812498345971107,
            0.025691183283925056,
            0.01485886238515377,
            0.003357033710926771,
            -0.004034735728055239,
            -0.016361907124519348,
            0.00012722387327812612,
            -0.004532627761363983,
            -0.00955032929778099,
            -0.052096664905548096,
            0.028990164399147034,
            0.0453481562435627,
            -0.03628675267100334,
            0.008194412104785442,
            0.006943626329302788,
            -0.0015254532918334007,
            -0.0442684069275856,
            -0.027688689529895782,
            -0.02012738771736622,
            -0.0717335119843483,
            0.02530658058822155,
            0.0351715013384819,
            -0.0008279680041596293,
            -0.060206834226846695,
            0.019572976976633072,
            -0.04478449746966362,
            -0.00401206873357296,
            -0.062396492809057236,
            -0.06993168592453003,
            -0.027312884107232094,
            0.03883535787463188,
            -0.056772664189338684,
            0.0036114368122071028,
            -0.0038665872998535633,
            -0.06600044667720795,
            -0.008523519150912762,
            0.02308611385524273,
            0.02607470564544201,
            0.03106728568673134,
            -0.010480290278792381,
            -0.0551682710647583,
            -0.011518340557813644,
            -0.026427118107676506,
            -0.00286878296174109,
            0.027344275265932083,
            -0.02023269794881344,
            0.015523901209235191,
            0.025460435077548027,
            -0.01684792898595333,
            0.054278768599033356,
            0.03997102007269859,
            -0.025095375254750252,
            0.002341893967241049,
            -0.012833349406719208,
            -0.0007469711126759648,
            -0.06326588988304138,
            -0.010060995817184448,
            -0.008852723054587841,
            0.030654897913336754,
            0.08217828720808029,
            -0.017760146409273148,
            -0.0372273251414299,
            0.00705307861790061,
            0.05556906759738922,
            0.02222207747399807,
            -0.019349336624145508,
            -0.007379618007689714,
            0.011249746195971966,
            0.03447297587990761,
            0.01200891938060522,
            -0.0032988926395773888,
            0.02879197522997856,
            -0.03256510570645332,
            -0.03788204491138458,
            -0.02319413051009178,
            -0.0549716055393219,
            -0.03373013809323311,
            -0.03803298622369766,
            0.02406970225274563,
            -0.020026929676532745,
            -0.030205365270376205,
            0.027278762310743332,
            -0.028088601306080818,
            -0.03321678563952446,
            0.010381094180047512,
            -0.017966371029615402,
            0.01728132739663124,
            -0.060104917734861374,
            -0.0071763875894248486,
            -0.0044164410792291164,
            0.007945733144879341,
            -0.0079561872407794,
            0.04032817482948303,
            -0.03882617503404617,
            0.03594003617763519,
            -0.012844212353229523,
            -0.008287763223052025,
            -0.011042785830795765,
            0.0057416073977947235,
            0.034220583736896515,
            -0.020950691774487495,
            -0.003139676759019494,
            0.0159134678542614,
            -0.020091036334633827,
            0.022624250501394272,
            -0.015434387139976025,
            0.01825486682355404,
            -0.04160977154970169,
            -0.04148818552494049,
            -0.011754180304706097,
            -0.01616532728075981,
            -0.07687996327877045,
            0.019512012600898743,
            -0.021484317258000374,
            -0.008291645906865597,
            0.00017883586406242102,
            0.015611334703862667,
            -0.03289051726460457,
            0.054259706288576126,
            0.006119962316006422,
            -0.07731447368860245,
            -0.06153884902596474,
            -0.011440453119575977,
            0.0031304596923291683,
            -0.009067187085747719,
            -0.03423580899834633,
            0.021536285057663918,
            -0.03738672658801079,
            -0.015905117616057396,
            0.000374473660485819,
            0.004727285820990801,
            0.014238930307328701,
            -0.005362419877201319,
            0.034259483218193054,
            0.044171061366796494,
            -0.009265006519854069,
            -0.02801601029932499,
            -0.00023769334075041115,
            -0.07363449782133102,
            0.07016462087631226,
            -0.03180176019668579,
            0.024716604501008987,
            -0.01317936833947897,
            0.021529415622353554,
            0.008520710282027721,
            -0.04618830978870392,
            -0.009025446139276028,
            -0.016530288383364677,
            -0.03601594641804695,
            -0.019188042730093002,
            -0.015371991321444511,
            0.008428680710494518,
            0.0026370242703706026,
            0.04254636913537979,
            -0.0609399750828743,
            0.02153829298913479,
            0.021007219329476357,
            -0.03349050134420395,
            -0.00013911763380747288,
            -0.0017511402256786823,
            -0.04086991399526596,
            0.025977041572332382,
            0.012296856380999088,
            0.007024507969617844,
            -0.026161452755331993,
            0.03247880935668945,
            -0.03055877983570099,
            -0.0411144383251667,
            0.039127081632614136,
            -0.007505530491471291,
            0.0014745049411430955,
            0.014402268454432487,
            0.05548607558012009,
            0.029351087287068367,
            0.025637704879045486,
            0.0051458608359098434,
            0.04417914152145386,
            0.009604859165847301,
            -0.04955155402421951,
            -0.03470611199736595,
            -0.01137889176607132,
            0.051598574966192245,
            0.009436331689357758,
            -0.005606478545814753,
            -0.025748474523425102,
            0.02866089902818203,
            -0.020061327144503593,
            -0.03302895650267601,
            -0.011439042165875435,
            -0.003177434438839555,
            -0.09245940297842026,
            0.043748971074819565,
            -0.09379898756742477,
            0.029693247750401497,
            -0.021310247480869293,
            -0.004805265460163355,
            0.0038573704659938812,
            -0.05002172663807869,
            -0.005642324220389128,
            -0.017933864146471024,
            -0.01487882062792778,
            0.010386458598077297,
            -0.02442244067788124,
            0.029207834973931313,
            -0.008984414860606194,
            -0.007433931343257427,
            -0.060693323612213135,
            -0.025847455486655235,
            0.006531335413455963,
            0.0344964899122715,
            0.004745699930936098,
            -0.007910039275884628,
            -0.02018101140856743,
            0.022875379770994186,
            -0.06928974390029907,
            -0.0149482237175107,
            -0.06504400074481964,
            -0.004043660592287779,
            0.0005110822385177016,
            -0.0402618832886219,
            -0.007323532365262508,
            -0.03799870237708092,
            0.00024064529861789197,
            -0.05331997945904732,
            0.02672722563147545,
            0.011150226928293705,
            -0.004595746286213398,
            0.014851883985102177,
            0.026816822588443756,
            0.006368555594235659,
            -0.033268723636865616,
            0.010622255504131317,
            -0.020483653992414474,
            0.01814124546945095,
            0.018846169114112854,
            0.0037608137354254723,
            0.0010812878608703613,
            0.0026280665770173073,
            -0.006746876984834671,
            -0.028002936393022537,
            0.03622002899646759,
            -0.07371765375137329,
            -0.061227038502693176,
            -0.011920085176825523,
            -0.006193364504724741,
            0.02772541530430317,
            -0.011370033957064152,
            -0.09818645566701889,
            -0.0007070818683132529,
            0.041672900319099426,
            0.005505854729562998,
            0.01768200471997261,
            -0.018372738733887672,
            0.006550533697009087,
            0.02202986739575863,
            0.02168230526149273,
            -0.00567152164876461,
            -0.044256217777729034,
            -0.017892662435770035,
            -0.012413221411406994,
            0.021663477644324303,
            -0.02295777015388012,
            -0.04666353389620781,
            0.05285753682255745,
            -0.03358297422528267,
            0.037283141165971756,
            -0.03709489107131958,
            -0.08023311197757721,
            -0.040468569844961166,
            -0.003761691739782691,
            -0.04036499187350273,
            0.02722347527742386,
            -0.02702217362821102,
            -0.01208420004695654,
            -0.012838887982070446,
            0.007742530666291714,
            0.00751347653567791,
            -0.014317935332655907,
            -0.014181924983859062,
            0.036262694746255875,
            -0.02256917767226696,
            -0.015967179089784622,
            0.02761204168200493,
            -0.06937660276889801,
            0.011804500594735146,
            0.008512796834111214,
            -0.010575632564723492,
            -0.013873075135052204,
            -0.0003558807948138565,
            0.003258142853155732,
            0.0029763118363916874,
            -0.04599159583449364,
            -0.04823347181081772,
            0.022241974249482155,
            0.01399935968220234,
            -0.004083823412656784,
            0.06328011304140091,
            -0.029098549857735634,
            0.017553990706801414,
            0.049809541553258896,
            -0.02124686725437641,
            -0.028784923255443573,
            0.020317452028393745,
            0.06294739991426468,
            -0.014573776163160801,
            0.009866777807474136,
            -0.009534354321658611,
            0.02462991513311863,
            -0.023069698363542557,
            -0.03286980837583542,
            0.042267486453056335,
            -0.017964160069823265,
            0.01184883527457714,
            0.001493602991104126,
            -0.019200492650270462,
            0.02664368227124214,
            0.03484996780753136,
            -0.03345609828829765,
            -0.0028313221409916878,
            -0.03729693964123726,
            -0.05285085737705231,
            -0.012657100334763527,
            0.028039172291755676,
            0.045801594853401184,
            -0.019835663959383965,
            -0.058397822082042694,
            -0.009185277856886387,
            0.015004048123955727,
            0.025148905813694,
            -0.019335398450493813,
            -0.007178334053605795,
            0.01643417961895466,
            -0.018928471952676773,
            -0.005888037383556366,
            -0.0851311981678009,
            -0.04645172134041786,
            0.029469357803463936,
            -0.05031006783246994,
            0.010059812106192112,
            -0.00013068265980109572,
            -0.025012530386447906,
            0.0031248226296156645,
            0.004216971807181835,
            -0.0077031864784657955,
            -0.0021541209425777197,
            -0.055641092360019684,
            -0.05259085074067116,
            -0.03734414279460907,
            -0.042114295065402985,
            0.01570771634578705,
            0.04032780975103378,
            -0.023634174838662148,
            -0.012999563477933407,
            -0.047248970717191696,
            -0.002551733748987317,
            -0.07399002462625504,
            0.019188812002539635,
            0.033282797783613205,
            0.040449291467666626,
            -0.02438533864915371,
            -0.0013054899172857404,
            0.0036567465867847204,
            -0.05107501149177551,
            0.06413359940052032,
            -0.01300178561359644,
            -0.008058307692408562,
            -0.03420829400420189,
            -0.030985232442617416,
            0.0023225334007292986,
            -0.01830177754163742,
            -0.030191460624337196,
            0.026655683293938637,
            0.03395147994160652,
            -0.003336437977850437,
            0.019059812650084496,
            0.011134941130876541,
            0.007887590676546097,
            0.0004603974230121821,
            -0.03135951608419418,
            -0.039265215396881104,
            -0.02055114321410656,
            0.0005072269705124199,
            0.00016638431407045573,
            -0.006929864175617695,
            -0.06804641336202621,
            -0.007274884730577469,
            0.01576102152466774,
            0.028654154390096664,
            0.008073706179857254,
            -0.014349807985126972,
            -0.019952163100242615,
            -0.03951899707317352,
            0.031327761709690094,
            0.014692630618810654,
            0.014225449413061142,
            0.013228705152869225,
            0.037657711654901505,
            0.021252205595374107,
            -0.0003996372688561678,
            -0.011607956141233444,
            -0.01727556809782982,
            0.01821952871978283,
            0.008424252271652222,
            -0.016448818147182465,
            -0.018356962129473686,
            0.021447332575917244,
            -0.03911706805229187,
            -0.018294168636202812,
            -0.006103898864239454,
            0.0010915816528722644,
            0.05741387605667114,
            -0.0037580165080726147,
            0.02481883205473423,
            -0.001970643177628517,
            -0.021099833771586418,
            0.016624262556433678,
            0.02222285605967045,
            -0.007529528811573982,
            0.022067880257964134,
            -0.017932768911123276,
            -0.04417954012751579,
            0.00906465295702219,
            -0.01377371046692133,
            0.057195138186216354,
            0.019403010606765747,
            0.014327124692499638,
            0.0008524188888259232,
            0.006883170921355486,
            -0.00865092035382986,
            -0.056264907121658325,
            0.006361947860568762,
            0.00555599108338356,
            -0.034612592309713364,
            0.01632724143564701,
            -0.03528250381350517,
            0.0016826618229970336,
            0.007306202780455351,
            -0.0016009823884814978,
            0.04439776390790939,
            -0.007787859067320824,
            -0.03144608065485954,
            0.05751882120966911,
            0.018184218555688858,
            -0.09034116566181183,
            -0.020801706239581108,
            -0.06060434505343437,
            0.015053517185151577,
            0.03493506461381912,
            0.0046342769637703896,
            -0.0024996669963002205,
            -0.049930084496736526,
            -0.012376721017062664,
            -0.04120086506009102,
            0.00965598039329052,
            -0.022804994136095047,
            -0.002255066530779004,
            0.002604464069008827,
            -0.017251992598176003,
            0.020015282556414604,
            -0.06501612067222595,
            0.027499405667185783,
            -0.020594457164406776,
            0.009367993101477623,
            -0.061229873448610306,
            0.001021600910462439,
            0.021909967064857483,
            -0.010226242244243622,
            -0.0005674560088664293,
            -0.07242704927921295,
            -0.06149190291762352,
            -0.070005401968956,
            -0.0015843069413676858,
            -0.015174268744885921,
            -0.011516009457409382,
            -0.00458882562816143,
            0.0022440822795033455,
            -0.008566238917410374,
            -0.0563117079436779,
            -0.01895693503320217,
            -0.03529936075210571,
            0.0039322939701378345,
            -0.024421080946922302,
            -0.030981851741671562,
            -0.00044880411587655544,
            -0.0782468393445015,
            0.004560487810522318,
            0.004103945568203926,
            -0.012222434394061565,
            -0.006016237195581198,
            -0.0366208590567112,
            0.0007563763065263629,
            -0.0018283096142113209,
            -0.06679199635982513,
            0.01958254724740982,
            -0.009494618512690067,
            -0.07413937896490097,
            0.012736702337861061,
            -0.0006470787920989096,
            -0.0004658393154386431,
            -0.059288814663887024,
            -0.05574655532836914,
            -0.010623929090797901,
            0.009564707055687904,
            -0.011018907651305199,
            0.00869007222354412,
            -0.016475046053528786,
            -0.033643823117017746,
            0.04673855006694794,
            0.030407486483454704,
            0.007549230940639973,
            0.023290757089853287,
            -0.05155123770236969,
            0.014012806117534637,
            -0.04701503738760948,
            0.0003402159200049937,
            -0.081900954246521,
            0.051605209708213806,
            0.009393992833793163,
            -0.008288552984595299,
            0.009370226413011551,
            -0.012246040627360344,
            0.009437832981348038,
            0.014535792171955109,
            0.01907065138220787,
            0.03562793508172035,
            -0.029021093621850014,
            -0.010302279144525528,
            -0.012925979681313038,
            0.005502687767148018,
            0.06199518218636513,
            -0.08915753662586212,
            0.025893056765198708,
            -0.039212826639413834,
            0.015426967293024063,
            0.021530842408537865,
            0.0238733422011137,
            0.021177781745791435,
            0.03863934054970741,
            -0.037606172263622284,
            0.01327150035649538,
            -0.0033042901195585728,
            -0.016372328624129295,
            0.0034045351203531027,
            -0.024916227906942368,
            -0.0146499527618289,
            -0.007361787371337414,
            0.027751682326197624,
            0.010115845128893852,
            -0.06448300182819366,
            -0.07343994081020355,
            0.007622970268130302,
            -0.004575537983328104,
            0.007618241012096405,
            -0.007830416783690453,
            0.009140962734818459,
            0.06522197276353836,
            -0.03075212426483631,
            -0.018184533342719078,
            -0.01493025477975607,
            -0.038820888847112656,
            -4.96306313380046e-07,
            -0.006560344714671373,
            0.0012329834280535579,
            -0.011768531985580921,
            -0.01582260988652706,
            0.06560469418764114,
            0.04057558253407478,
            0.028236476704478264,
            -0.05875842273235321,
            0.002430036198347807,
            0.018066488206386566,
            0.02018210105597973,
            -0.04840557649731636,
            -0.02364627830684185,
            0.025575540959835052,
            -0.03205672651529312,
            -0.011019442230463028,
            -0.008022954687476158,
            -0.02794131636619568,
            0.00033522865851409733,
            0.005357934162020683,
            -0.030209101736545563,
            -0.01895439252257347,
            0.019671045243740082,
            0.014646281488239765,
            0.018305983394384384,
            -0.014944530092179775,
            -0.01104641705751419,
            0.027477750554680824,
            0.04153976961970329,
            0.05881930887699127,
            -0.03734776750206947,
            -0.04435286670923233,
            0.02667224034667015,
            0.0076065887697041035,
            0.007355360314249992,
            0.017374293878674507,
            0.04998358339071274,
            0.0034279644023627043,
            -0.022115331143140793,
            -0.02111383154988289,
            -0.026942197233438492,
            0.01996687985956669,
            0.04082020744681358,
            -0.010848527774214745,
            -0.00792351458221674,
            0.011663003824651241,
            -0.003544463310390711,
            0.0001713689707685262,
            0.010639981366693974,
            0.037574075162410736,
            0.05991154536604881,
            0.041516486555337906,
            -0.004633799195289612,
            0.027083346620202065,
            -0.07694078236818314,
            0.008812516927719116,
            -0.07394633442163467,
            -0.005509926471859217,
            -0.014065531082451344,
            -0.021226320415735245,
            0.05423039570450783,
            -0.014315227046608925,
            -0.03351277858018875,
            0.002898153616115451,
            0.004688652697950602,
            -0.0976596400141716,
            0.036527786403894424,
            -0.009065772406756878,
            -0.0030496176332235336,
            -0.0007604247075505555,
            -0.06104688346385956,
            -0.010321644134819508,
            -0.012154657393693924,
            0.006749143823981285,
            0.03379737585783005,
            -0.04158402606844902,
            0.005604385863989592,
            0.050177931785583496,
            -0.07371999323368073,
            -0.02292429469525814,
            0.08059469610452652,
            0.03400275111198425,
            -0.0077203912660479546,
            0.05477026849985123,
            -0.004715200047940016,
            0.021516915410757065,
            0.07715544104576111,
            -0.023073796182870865,
            0.04510005563497543,
            -0.020504005253314972,
            -0.06061609461903572,
            -0.011591387912631035,
            0.026255466043949127,
            0.05844094604253769,
            -0.030911091715097427,
            -0.02435673587024212,
            -0.03114556334912777,
            0.013498412445187569,
            -0.04680844396352768,
            0.02874556928873062,
            0.015006457455456257,
            0.012306738644838333,
            0.019283384084701538,
            -0.008710676804184914,
            -0.024638095870614052,
            -0.02612958662211895,
            0.010031484067440033,
            0.06756933033466339,
            -0.025763796642422676,
            0.035759590566158295,
            -0.02546563744544983,
            0.014565853402018547,
            -0.032386571168899536,
            -0.026437675580382347,
            0.039875369518995285,
            -0.023064495995640755,
            -0.006200815085321665,
            -0.0018841605633497238,
            -0.0104924775660038,
            -0.04377492889761925,
            0.007249133195728064,
            0.007090205326676369,
            0.0030006403103470802,
            0.026958588510751724,
            -0.018374130129814148,
            -0.016008920967578888,
            -0.04021602123975754,
            -0.03192543610930443,
            -0.013406088575720787,
            0.00807854812592268,
            -0.06748101115226746,
            0.025232352316379547,
            -0.02270493470132351,
            -0.008892154321074486,
            0.014735174365341663,
            -0.08338335156440735,
            -0.03647453710436821,
            -0.010804136283695698,
            0.03144458681344986,
            -0.04310135170817375,
            0.035151612013578415,
            -0.0037396703846752644,
            -0.002889846218749881,
            0.00917968899011612,
            0.007758508436381817,
            -0.030863795429468155,
            0.03798403590917587,
            -0.01867976039648056,
            0.009530608542263508,
            -0.03250890225172043,
            -0.03006315790116787,
            -0.06441463530063629,
            -0.05089785158634186,
            -0.04826543480157852,
            -0.03922684118151665,
            -0.061155661940574646,
            -0.013578146696090698,
            -0.021014172583818436,
            -0.020986592397093773,
            -0.01609846018254757,
            -0.01607266440987587,
            0.04312999173998833,
            -0.022683197632431984,
            -0.03662041574716568,
            -0.03442113846540451,
            -0.0013060009805485606,
            0.028333185240626335,
            -0.030485931783914566,
            -0.019059251993894577,
            -0.02569670043885708,
            -0.02463175542652607,
            -0.01901596039533615,
            -0.006871428340673447,
            -0.04226692393422127,
            0.017847640439867973,
            -0.016259362921118736,
            -0.03251204639673233,
            -0.0045487405732274055,
            -0.014114709571003914,
            -0.04427201300859451,
            0.0024977370630949736,
            0.004068488720804453,
            -0.02396422252058983,
            -0.024673594161868095,
            -0.020842337980866432,
            -0.07221268117427826,
            -0.018612481653690338,
            0.021729279309511185,
            -0.0695127546787262,
            -0.03914160281419754,
            0.004753102548420429,
            -0.011834234930574894,
            -0.00855085626244545,
            0.022696396335959435,
            0.052220169454813004,
            -0.017697980627417564,
            -0.014514991082251072,
            -0.02249090000987053,
            0.0007246572058647871,
            -0.032406069338321686,
            -0.007085241377353668,
            0.011416211724281311,
            0.012593881227076054,
            0.013599858619272709,
            -0.06653286516666412,
            0.04606812447309494,
            0.025622893124818802,
            0.06232569366693497,
            0.021278787404298782,
            0.024662969633936882,
            0.00898772943764925,
            -0.022527480497956276,
            0.03849242627620697,
            0.0018848375184461474,
            0.0010612827027216554,
            0.024995341897010803,
            0.05474928021430969
        ],
        [
            -0.03002333454787731,
            0.007790564559400082,
            0.02199667878448963,
            -0.023639187216758728,
            0.022225676104426384,
            -0.04834781587123871,
            -0.015498138032853603,
            -0.051087893545627594,
            -0.014177805744111538,
            -0.007821496576070786,
            -0.04972701892256737,
            0.025833705440163612,
            0.025992900133132935,
            -0.005133691243827343,
            -0.010275025852024555,
            -0.008989537134766579,
            -0.045690424740314484,
            -0.0285706277936697,
            0.022339429706335068,
            -0.02366555668413639,
            -0.04018792510032654,
            -0.014198862947523594,
            -0.02686299756169319,
            0.008918597362935543,
            -0.0017301901243627071,
            0.0047865966334939,
            -0.023385589942336082,
            0.015451256185770035,
            0.001389127573929727,
            0.04995562508702278,
            -0.011757448315620422,
            -0.008116822689771652,
            0.009931406006217003,
            -0.007048964034765959,
            0.018869148567318916,
            0.023771196603775024,
            -0.0018757206853479147,
            -0.020368551835417747,
            0.004828892648220062,
            0.027800465002655983,
            0.013592200353741646,
            -0.006136560346931219,
            -0.007356889545917511,
            -0.033118635416030884,
            0.0404064804315567,
            -0.022627338767051697,
            0.04153314605355263,
            0.038138099014759064,
            0.02470136620104313,
            -0.027330264449119568,
            0.006179600488394499,
            0.03351089358329773,
            0.03404446318745613,
            0.016750182956457138,
            0.00733059411868453,
            0.02362695336341858,
            0.021192437037825584,
            0.03747723624110222,
            -0.013456159271299839,
            -0.02686198242008686,
            -0.03952465578913689,
            0.021188607439398766,
            -0.025004185736179352,
            -0.023379554972052574,
            0.011304018087685108,
            -0.002617520047351718,
            -0.0017852296587079763,
            0.028409602120518684,
            0.018174918368458748,
            -0.025190431624650955,
            -0.025913337245583534,
            0.002042376669123769,
            0.013203077018260956,
            -0.01490093395113945,
            0.042039040476083755,
            0.06478875875473022,
            0.010728634893894196,
            0.004523392301052809,
            -0.02101610042154789,
            -0.01637318730354309,
            0.0018574314890429378,
            -0.0028851437382400036,
            -0.026524916291236877,
            0.010115822777152061,
            0.02754156105220318,
            0.024300018325448036,
            0.01963668502867222,
            -0.0015333930496126413,
            -0.007114092353731394,
            -0.04664710909128189,
            -0.008713844232261181,
            0.009887074120342731,
            0.005114962346851826,
            0.009049014188349247,
            0.02438678964972496,
            0.0115255918353796,
            0.009739593602716923,
            -0.0013570307055488229,
            -0.04329703748226166,
            0.003750105621293187,
            0.0031384869944304228,
            -0.005999733228236437,
            0.012384544126689434,
            -0.02475435473024845,
            -0.005219986662268639,
            0.010443013161420822,
            -0.03194762021303177,
            0.04320448264479637,
            0.007246466353535652,
            0.0029611261561512947,
            -0.011353299021720886,
            -0.00644348282366991,
            -0.02967812307178974,
            -0.0012260862858965993,
            -0.07090992480516434,
            0.0177319198846817,
            0.03273589536547661,
            -0.006777720060199499,
            0.01648627407848835,
            -0.009589671157300472,
            0.014882736839354038,
            -0.013047115877270699,
            -0.019031895324587822,
            0.015176239423453808,
            -0.00399546604603529,
            -0.020107422024011612,
            -0.047108788043260574,
            0.004101790953427553,
            0.010092095471918583,
            0.026671435683965683,
            -0.006094836629927158,
            -0.013190551660954952,
            -0.019679903984069824,
            0.0044405050575733185,
            0.03379909694194794,
            0.008795458823442459,
            -0.023021414875984192,
            0.019864868372678757,
            0.03288666531443596,
            -0.01219586469233036,
            -0.016212835907936096,
            -0.01885371282696724,
            0.03433449566364288,
            0.006579694338142872,
            -0.02200583554804325,
            -0.004270708654075861,
            -0.007036322262138128,
            -0.03629661723971367,
            -0.005283182021230459,
            0.022919513285160065,
            0.03224453702569008,
            0.0036595892161130905,
            -0.0017264083726331592,
            -0.0028108744882047176,
            0.012822618708014488,
            0.025361090898513794,
            0.003436903702095151,
            0.007880057208240032,
            0.0321408249437809,
            -0.00761698791757226,
            -0.026604218408465385,
            -0.023599522188305855,
            0.03815801814198494,
            0.008572246879339218,
            -0.019309738650918007,
            0.0016082348302006721,
            -0.007109265308827162,
            0.0023085055872797966,
            0.044559888541698456,
            0.0020526370499283075,
            -0.024506796151399612,
            0.00018607258971314877,
            0.011691750027239323,
            -0.00986657664179802,
            -0.05244130641222,
            -0.0058048260398209095,
            -0.018570899963378906,
            -0.012707851827144623,
            0.007881175726652145,
            0.01959342323243618,
            -0.02429256960749626,
            -0.008867410011589527,
            0.0473015271127224,
            -0.016776205971837044,
            0.02469031512737274,
            -0.01024613156914711,
            -0.030929014086723328,
            -0.015380118042230606,
            -0.004751609172672033,
            -0.0033526914194226265,
            -0.022727401927113533,
            0.0249274093657732,
            -0.005642071831971407,
            0.020468665286898613,
            -0.007773092947900295,
            -0.01854623295366764,
            0.026811324059963226,
            0.020104743540287018,
            0.01924002356827259,
            0.018658112734556198,
            0.021105123683810234,
            0.01134405005723238,
            -0.012619712390005589,
            -0.03169820457696915,
            0.021340955048799515,
            -0.005400564521551132,
            0.018791552633047104,
            -0.00944376178085804,
            0.0006227619014680386,
            -0.006670388393104076,
            0.022809648886322975,
            -0.018794851377606392,
            0.006679236888885498,
            -0.010430761612951756,
            -0.025024186819791794,
            0.009398714639246464,
            -0.017094532027840614,
            -0.039228405803442,
            0.018690215423703194,
            0.009975738823413849,
            -0.0056231399066746235,
            0.010168428532779217,
            -0.019846776500344276,
            0.007951784878969193,
            -2.6291181711712852e-05,
            -0.028526868671178818,
            0.021214528009295464,
            -0.029710756614804268,
            0.04059871286153793,
            -0.0033679758198559284,
            -0.04769008234143257,
            0.03686502203345299,
            -0.013703759759664536,
            -0.02335960976779461,
            -0.0016582855023443699,
            0.025791801512241364,
            -0.05275323614478111,
            0.0020774456206709146,
            0.006161573808640242,
            -0.006033499725162983,
            -0.024207675829529762,
            0.021290889009833336,
            0.03626573458313942,
            -0.015626275911927223,
            -0.0015102904289960861,
            0.020803216844797134,
            0.023417357355356216,
            -0.03713258355855942,
            -0.01680889166891575,
            0.02627658285200596,
            0.009521876461803913,
            0.012726311571896076,
            -0.04651772603392601,
            -0.023891225457191467,
            0.0354376919567585,
            0.02424081601202488,
            -0.01804385893046856,
            0.007623268757015467,
            -0.0038420725613832474,
            0.018431594595313072,
            0.0051731630228459835,
            -0.05664069205522537,
            -0.06705145537853241,
            -0.025129858404397964,
            0.03908789902925491,
            -0.002536063315346837,
            -0.019229460507631302,
            0.01913122646510601,
            0.03516767919063568,
            0.015965625643730164,
            -0.0033436533994972706,
            -0.018598120659589767,
            -0.021200109273195267,
            0.013273977674543858,
            0.045298218727111816,
            -0.019791921600699425,
            0.015007900074124336,
            -0.042517468333244324,
            -0.05373569577932358,
            0.009701979346573353,
            0.04264543578028679,
            0.014346148818731308,
            0.029721762984991074,
            0.01981324516236782,
            0.02375069260597229,
            -0.01663428172469139,
            -0.029445957392454147,
            0.013513376004993916,
            0.027293875813484192,
            -0.009286897256970406,
            0.02992633543908596,
            -0.013754286803305149,
            0.023644769564270973,
            -0.0006659531500190496,
            -0.008730202913284302,
            -0.008310665376484394,
            0.03178410604596138,
            0.025066068395972252,
            0.03270615264773369,
            -0.011248715221881866,
            -0.0034394299145787954,
            0.03214719146490097,
            0.013724930584430695,
            -0.026749126613140106,
            0.03951450437307358,
            0.05662209168076515,
            0.04576810821890831,
            -0.03800307586789131,
            0.013808724470436573,
            0.014147679321467876,
            0.05243058130145073,
            -0.013738594017922878,
            -0.004263658542186022,
            -0.02613007463514805,
            -0.023483244702219963,
            0.01430396270006895,
            -0.010913177393376827,
            -0.020470526069402695,
            -0.023993225768208504,
            -0.018571998924016953,
            0.015024720691144466,
            0.029397407546639442,
            -0.0006713050534017384,
            -0.01871967874467373,
            0.005159066058695316,
            0.011655732989311218,
            0.029827946797013283,
            -0.009492036886513233,
            0.007710443809628487,
            0.0014493841445073485,
            -0.04044869914650917,
            0.011464914306998253,
            -0.03004591539502144,
            0.017663897946476936,
            -0.012615106999874115,
            0.0033252371940761805,
            -0.021492034196853638,
            0.003917154856026173,
            0.0009250799776054919,
            -0.017291270196437836,
            0.029619058594107628,
            0.022511634975671768,
            0.028585080057382584,
            0.027900148183107376,
            -0.013362075202167034,
            0.005505342967808247,
            0.019071757793426514,
            -0.017892802134156227,
            -0.029553337022662163,
            -0.02408645674586296,
            -0.012262933887541294,
            0.002415553666651249,
            -0.017494594678282738,
            -0.005077727138996124,
            -0.0025140484794974327,
            -0.024187525734305382,
            -0.01866665668785572,
            -0.010104856453835964,
            -0.0071735079400241375,
            0.028450509533286095,
            0.023215722292661667,
            -0.01917831413447857,
            -0.06709799915552139,
            0.0003842633159365505,
            0.024678830057382584,
            -0.03160732984542847,
            0.024494152516126633,
            -0.016150517389178276,
            0.001881773117929697,
            0.027096357196569443,
            0.010237155482172966,
            -0.0038738714065402746,
            0.019806426018476486,
            -0.04277827963232994,
            0.017695214599370956,
            0.02551405131816864,
            6.972515166125959e-06,
            -0.034171830862760544,
            0.013871395960450172,
            0.02651067078113556,
            0.03486548364162445,
            0.02625451236963272,
            0.012412995100021362,
            0.02340731769800186,
            0.013859529048204422,
            0.04462064057588577,
            0.0030617057345807552,
            -0.0019478155300021172,
            -0.0015131019754335284,
            -0.0066710649989545345,
            0.031149623915553093,
            0.006300205364823341,
            0.019823599606752396,
            -0.006067559123039246,
            0.005224550608545542,
            0.014667339622974396,
            -0.011269482783973217,
            -0.011793949641287327,
            -0.01067555695772171,
            0.0021845337469130754,
            0.016239037737250328,
            0.010565634816884995,
            0.02836480177938938,
            0.013160659931600094,
            -0.007454048376530409,
            0.013903115876019001,
            0.0013478280743584037,
            0.0011620664736256003,
            0.017120931297540665,
            -0.00881799217313528,
            -0.01939729042351246,
            0.0350690633058548,
            0.0016140303341671824,
            0.048788830637931824,
            -0.0002145334437955171,
            -0.03405425697565079,
            -0.021290801465511322,
            0.07746311277151108,
            -0.009442981332540512,
            0.0072893016040325165,
            0.06454499065876007,
            -0.0013699985574930906,
            -0.010505057871341705,
            -0.01521306298673153,
            0.02270059660077095,
            -0.009677299298346043,
            -0.019724413752555847,
            -0.011684213764965534,
            0.007239901460707188,
            0.004084442276507616,
            0.006931278854608536,
            0.015311631374061108,
            -0.011678699404001236,
            0.040418487042188644,
            0.00577254593372345,
            -0.02136000245809555,
            -0.0035483501851558685,
            0.0011473579797893763,
            -0.006013243924826384,
            0.00615118769928813,
            0.023553647100925446,
            0.009289304725825787,
            -0.01759299635887146,
            -0.001573421759530902,
            -0.0013311556540429592,
            0.009589669294655323,
            -0.02800884284079075,
            -0.030743073672056198,
            -0.006399340927600861,
            -0.021440334618091583,
            -0.009643160738050938,
            0.0018110416131094098,
            0.0018691321602091193,
            -0.031409963965415955,
            0.03797547519207001,
            0.018594948574900627,
            0.0029694277327507734,
            -0.02857642062008381,
            -0.0027835285291075706,
            0.035511743277311325,
            -0.05665580928325653,
            0.03723069280385971,
            -0.019754355773329735,
            0.04490198940038681,
            0.022132650017738342,
            -0.0029693590477108955,
            -0.014444406144320965,
            0.03780735284090042,
            0.024211343377828598,
            -0.01985182985663414,
            -0.03790385276079178,
            -0.014130587689578533,
            -0.02769434079527855,
            0.004675062373280525,
            0.05536884814500809,
            0.023061603307724,
            0.02941257134079933,
            0.03532564267516136,
            -0.014098159968852997,
            -0.020392296835780144,
            -0.01616106927394867,
            0.015588161535561085,
            -0.05878400802612305,
            -0.025902584195137024,
            0.022438721731305122,
            -0.0005758482730016112,
            -0.03414006531238556,
            -0.0313219390809536,
            -0.07222425937652588,
            0.009459462948143482,
            -0.012417256832122803,
            0.0008861031965352595,
            0.011850092560052872,
            0.028909025713801384,
            0.003977929707616568,
            0.015622465871274471,
            0.03670290857553482,
            0.005362327676266432,
            -0.015825487673282623,
            0.016967477276921272,
            0.019325926899909973,
            0.03908068314194679,
            0.0013769293436780572,
            -0.046890031546354294,
            -0.01572701893746853,
            0.019518429413437843,
            -0.027781395241618156,
            -0.010068362578749657,
            0.0035479299258440733,
            0.02828592248260975,
            0.006719854194670916,
            0.004939038772135973,
            0.01513246726244688,
            0.026815874502062798,
            -0.0016340235015377402,
            0.0007292713853530586,
            0.02183697186410427,
            -0.016512852162122726,
            -0.031153479591012,
            -0.018201617524027824,
            -0.028259962797164917,
            -0.02185581997036934,
            0.011013961397111416,
            0.0230911485850811,
            -0.08191310614347458,
            -0.029671702533960342,
            -0.013598388992249966,
            0.027567576617002487,
            0.02562660351395607,
            0.008000641129910946,
            0.0007281985017471015,
            0.03234269097447395,
            -0.030825287103652954,
            0.014964385889470577,
            -0.023001858964562416,
            0.0106767937541008,
            -0.002541087567806244,
            -0.012722340412437916,
            -0.006784787867218256,
            -0.005048000253736973,
            0.016853278502821922,
            -0.010298937559127808,
            0.021092915907502174,
            -0.04228595271706581,
            -0.014613805338740349,
            0.02674715220928192,
            0.012748170644044876,
            0.018980590626597404,
            0.019359145313501358,
            -0.003933042753487825,
            0.03016265109181404,
            -0.014118077233433723,
            0.011721095070242882,
            0.039088498800992966,
            0.0029073962941765785,
            0.022731248289346695,
            0.0033588630612939596,
            -0.00935167446732521,
            -0.0039708418771624565,
            -0.014428464695811272,
            -0.01536878664046526,
            0.029272116720676422,
            -0.004191868007183075,
            -0.05589062720537186,
            -0.02563273347914219,
            0.020397279411554337,
            -0.028623618185520172,
            -0.005233316216617823,
            0.002580747939646244,
            0.023648543283343315,
            -0.006623126100748777,
            -0.015703193843364716,
            0.02656727470457554,
            0.005284748040139675,
            0.026923175901174545,
            0.02027663215994835,
            -0.010183091275393963,
            -0.018901387229561806,
            -0.008575158193707466,
            0.018472785130143166,
            0.002497604116797447,
            0.01889488846063614,
            0.02016078308224678,
            0.004735598340630531,
            -0.015763554722070694,
            0.029925527051091194,
            -0.00472236005589366,
            -0.007900758646428585,
            0.0030709689017385244,
            0.02888912335038185,
            0.015120248310267925,
            -0.005653477739542723,
            0.0053476607427001,
            -0.01915021240711212,
            -0.029212040826678276,
            0.0037130427081137896,
            0.021175654605031013,
            -0.02119225077331066,
            -0.002448340877890587,
            0.03377750515937805,
            -0.00332859274931252,
            0.002709009451791644,
            -0.020350510254502296,
            0.015923429280519485,
            -0.0185569878667593,
            0.02497391775250435,
            -0.02257366292178631,
            0.007852232083678246,
            0.026215383782982826,
            0.024891115725040436,
            0.025010135024785995,
            0.027718428522348404,
            0.021226495504379272,
            -0.03387892246246338,
            -0.010069487616419792,
            -0.010545972734689713,
            -0.024114694446325302,
            0.020282167941331863,
            0.0017496870132163167,
            0.0006027214112691581,
            -0.027324438095092773,
            -0.018219424411654472,
            -0.023974385112524033,
            -0.0175388902425766,
            0.01958533190190792,
            -0.04749325290322304,
            -0.010176904499530792,
            0.03241531550884247,
            -0.005343498662114143,
            -0.007814697921276093,
            -0.012997766025364399,
            0.0017880494706332684,
            0.014918435364961624,
            0.014750893227756023,
            0.013225670903921127,
            -0.04627794027328491,
            0.03400424122810364,
            -0.018807116895914078,
            -0.034095823764801025,
            -0.009663039818406105,
            0.01032798457890749,
            -0.026793865486979485,
            0.00566134275868535,
            0.03005480207502842,
            0.015701964497566223,
            0.019106807187199593,
            0.0055954959243535995,
            -0.006383604370057583,
            0.014684807509183884,
            0.0019319747807458043,
            0.018590735271573067,
            0.02377539500594139,
            0.014035504311323166,
            0.00494728796184063,
            0.02377062849700451,
            -0.012840823270380497,
            0.02451719529926777,
            0.002778216963633895,
            0.036277636885643005,
            -0.01922917366027832,
            0.029773803427815437,
            0.034199610352516174,
            0.009893907234072685,
            -0.012529217638075352,
            0.004732135683298111,
            0.009429849684238434,
            0.029127812013030052,
            -0.032040227204561234,
            -0.014921075664460659,
            0.007768638432025909,
            0.006662504747509956,
            -0.04017578065395355,
            0.016298087313771248,
            0.03550529479980469,
            -0.0011119922855868936,
            0.010012068785727024,
            0.0028217704966664314,
            0.0034103640355169773,
            -0.022722508758306503,
            0.00656540272757411,
            0.0037290360778570175,
            -0.009206385351717472,
            0.004021177068352699,
            -0.0072675226256251335,
            -0.03155039623379707,
            0.005867266096174717,
            0.03688949719071388,
            0.0033041543792933226,
            0.038557447493076324,
            0.06022229418158531,
            0.008598273620009422,
            0.03462456539273262,
            0.01926753856241703,
            -0.0031594661995768547,
            0.021758534014225006,
            -0.02285243570804596,
            -0.010370956733822823,
            -0.007524577900767326,
            -0.010484650731086731,
            0.03555509075522423,
            -0.007963933981955051,
            -0.0008088764734566212,
            -0.017864584922790527,
            -0.0013802247121930122,
            -0.006905736401677132,
            -0.0039044443983584642,
            0.013032321818172932,
            0.04623190686106682,
            -0.01924007572233677,
            -0.03929666429758072,
            -0.028857653960585594,
            0.013611786998808384,
            -0.025904612615704536,
            -0.00227540684863925,
            0.03310583159327507,
            -0.024520697072148323,
            -0.0019248115131631494,
            -0.002047237241640687,
            0.011088689789175987,
            0.003570742905139923,
            0.0069261533208191395,
            -0.008487313985824585,
            0.008584284223616123,
            0.00462938379496336,
            0.0022689281031489372,
            0.02888588421046734,
            -0.01025597658008337,
            -0.01983608305454254,
            0.004829112906008959,
            -0.035331424325704575,
            0.007681664079427719,
            -0.03511920943856239,
            0.014667971059679985,
            0.00866029504686594,
            -0.020952003076672554,
            0.0065837437286973,
            0.0102903563529253,
            0.006255495827645063,
            0.002543068490922451,
            0.028094900771975517,
            -0.0011478557717055082,
            -0.02873915247619152,
            0.016637180000543594,
            0.0023363830987364054,
            -0.018551919609308243,
            -0.006589201278984547,
            -0.03771612420678139,
            0.0018875934183597565,
            0.017981134355068207,
            0.017514260485768318,
            0.005815525539219379,
            -0.03154801204800606,
            -0.026198428124189377,
            0.04550294205546379,
            0.0114067904651165,
            0.005633941851556301,
            -0.0003317262453492731,
            -0.0008684302447363734,
            0.06072584539651871,
            0.01092708669602871,
            -0.043070241808891296,
            -0.019413424655795097,
            0.0036348493304103613,
            -0.0053946091793477535,
            -0.0166791845113039,
            0.007627683691680431,
            0.024950314313173294,
            0.007753308396786451,
            -0.032012924551963806,
            -0.022069308906793594,
            0.03169534355401993,
            0.014541643671691418,
            0.031095407903194427,
            0.009001825004816055,
            -0.007827243767678738,
            0.034013740718364716,
            0.04982822760939598,
            0.028231870383024216,
            0.014284203760325909,
            -0.005438697524368763,
            -0.036210428923368454,
            0.0015723855467513204,
            0.004628869239240885,
            0.04335446655750275,
            -0.00874652061611414,
            -0.02392406575381756,
            0.03379750996828079,
            0.011040071956813335,
            -0.018864663317799568,
            -0.03476092591881752,
            -0.025855451822280884,
            -0.008827266283333302,
            -0.019566480070352554,
            -0.0034884708002209663,
            -0.005186071619391441,
            0.012423219159245491,
            -0.034818824380636215,
            -0.018193617463111877,
            0.0010116639314219356,
            0.001245869672857225,
            0.012998641468584538,
            -0.0410471186041832,
            -0.030252421274781227,
            0.041499294340610504,
            -0.05990571901202202,
            0.06281141191720963,
            -0.026633424684405327,
            0.023874614387750626,
            -0.02768506109714508,
            -0.0025851926766335964,
            -0.028624016791582108,
            -0.01130630448460579,
            0.02791644260287285,
            -0.010079205967485905,
            -0.04101197049021721,
            0.014514273032546043,
            -0.025965053588151932,
            -0.00021132134133949876,
            0.004560784436762333,
            0.017370838671922684,
            0.016923824325203896,
            -0.05246668681502342,
            0.022871844470500946,
            0.02067931927740574,
            -0.0038820584304630756,
            0.030758079141378403,
            -0.00011749671830330044,
            -0.015020549297332764,
            0.0059797801077365875,
            0.021327052265405655,
            -0.011079446412622929,
            0.0026809442788362503,
            0.000682008103467524,
            -0.022449560463428497,
            -0.0002493447682354599,
            -0.01111073512583971,
            -0.012192832306027412,
            -0.021180031821131706,
            -0.0064836773090064526,
            -0.002703246660530567,
            0.02080247737467289,
            0.0264891367405653,
            -0.0037011862732470036,
            -0.009084388613700867,
            -0.01931409165263176,
            -0.020213451236486435,
            -0.000580412510316819,
            0.01405774150043726,
            0.0037420515436679125,
            0.0042779757641255856,
            -0.0044604032300412655,
            0.012881923466920853,
            0.008248751051723957,
            0.03224315494298935,
            0.03312927857041359,
            0.024507414549589157,
            0.007174958940595388,
            0.0295286625623703,
            0.01798379234969616,
            0.013900787569582462,
            -0.021748725324869156,
            0.0072611253708601,
            0.06109246239066124,
            -0.03498920425772667,
            -0.04070848226547241,
            0.027563510462641716,
            -0.013577264733612537,
            -0.007946968078613281,
            0.029768768697977066,
            -0.002846088260412216,
            -0.013445145450532436,
            0.0005624167388305068,
            -0.012191297486424446,
            -0.008090762421488762,
            -0.009919103235006332,
            0.00686825392767787,
            -0.0057280114851891994,
            -0.017383504658937454,
            -0.019923878833651543,
            0.007126081269234419,
            -0.012431278824806213,
            0.002049046568572521,
            0.025098169222474098,
            -0.026954438537359238,
            0.01130360271781683,
            0.016985315829515457,
            0.0033866369631141424,
            0.032596904784440994,
            0.016864920035004616,
            0.011441204696893692,
            0.033183060586452484,
            0.0076338909566402435,
            0.01897258684039116,
            0.018132245168089867,
            -0.003250971669331193,
            -0.013939651660621166,
            0.019009217619895935,
            -0.0012259534560143948,
            0.03858247399330139,
            0.022626494988799095,
            0.030523506924510002,
            0.028415506705641747,
            0.031739234924316406,
            -0.0023086904548108578,
            0.025798453018069267,
            -0.02996021881699562,
            -0.04098895564675331,
            -0.003155296668410301,
            -0.007978349924087524,
            0.007318750489503145,
            -0.03622673079371452,
            0.029454518109560013,
            -0.0029631590005010366,
            0.0073953429237008095,
            0.01833318918943405,
            -0.024770919233560562,
            -0.01860925368964672,
            -0.008864844217896461,
            -0.018377894535660744,
            -0.03326139599084854,
            0.007757766172289848,
            0.004120710771530867,
            -0.00575156370177865,
            -0.04369144141674042,
            0.0006518159061670303,
            -0.016888251528143883,
            0.014251446351408958,
            0.013557689264416695,
            -0.014159513637423515,
            -0.06584568321704865,
            -0.026623930782079697,
            -0.0037929632235318422,
            0.02856232225894928,
            0.016827359795570374,
            -0.03287240490317345,
            -0.007815765216946602,
            -0.015780294314026833,
            -0.03205815702676773,
            0.005762227810919285,
            -0.003570763161405921,
            0.001303073950111866,
            -0.020275117829442024,
            -0.0024670667480677366,
            0.021937474608421326,
            0.03644401580095291,
            0.029707109555602074,
            0.020982353016734123,
            0.02635353058576584,
            -0.02456711232662201,
            0.01742452383041382,
            -0.02912186272442341,
            0.01078906562179327,
            0.017798006534576416,
            0.01952558197081089,
            0.028478918597102165,
            -0.010543220676481724,
            -9.484580004937015e-06,
            0.023755211383104324,
            0.006663177628070116,
            0.017908215522766113,
            -0.017450645565986633,
            -0.011510485783219337,
            0.003118568565696478,
            0.015282563865184784,
            -0.024113472551107407,
            0.01988355442881584,
            -0.0328298918902874,
            0.030069399625062943,
            -0.018793223425745964,
            -0.003754823235794902,
            0.010325652547180653,
            -0.011534586548805237,
            0.02701166272163391,
            -0.019645795226097107,
            0.011480317451059818,
            0.017821943387389183,
            -0.00575833348557353,
            0.021449895575642586,
            -0.014555731788277626,
            0.028563516214489937,
            -0.007044925354421139,
            0.01245192252099514,
            0.024792585521936417,
            -0.037660863250494,
            -0.021976523101329803,
            0.00242928066290915,
            -0.015878014266490936,
            0.012804519385099411,
            -0.014510235749185085,
            0.026509473100304604,
            0.022977817803621292,
            -0.013593052513897419,
            -0.023219183087348938,
            0.02264360338449478,
            -0.014040934853255749,
            -0.014001819305121899,
            -0.02007371559739113,
            0.0014649394433945417,
            0.0007785113994032145,
            0.03227737545967102,
            -0.017285095527768135,
            0.025505732744932175,
            -0.02642529085278511,
            -0.009513569995760918,
            0.012847969308495522,
            0.024132780730724335,
            0.021818572655320168,
            0.05720467492938042,
            -0.030363405123353004,
            -0.0215435940772295,
            0.022076871246099472,
            0.026771901175379753,
            -0.010943368077278137,
            -0.0034499240573495626,
            0.012389437295496464,
            -0.030750203877687454,
            -0.0033291331492364407,
            0.02603364735841751,
            0.0031719335820525885,
            0.0011683950433507562,
            -0.00481000542640686,
            -0.03296545147895813,
            -0.004643175285309553,
            0.043849047273397446
        ],
        [
            0.022703250870108604,
            0.009884849190711975,
            -0.006432818714529276,
            -0.02627871185541153,
            0.01624109596014023,
            -0.008755317889153957,
            0.0007910773274488747,
            0.024686366319656372,
            0.016378343105316162,
            -0.021726135164499283,
            0.003763888031244278,
            -0.012866845354437828,
            -0.03971303626894951,
            -0.010458335280418396,
            0.0351380854845047,
            -0.011091007851064205,
            0.02386920526623726,
            -0.013509306125342846,
            -0.02932131662964821,
            -0.0014729993417859077,
            0.035376518964767456,
            0.0103120943531394,
            0.0169213954359293,
            0.005920688156038523,
            0.05114540457725525,
            -0.03177927806973457,
            0.018144242465496063,
            -0.015070450492203236,
            -0.02333199605345726,
            -0.013515794649720192,
            -0.028407694771885872,
            -0.006178940646350384,
            -0.025557445362210274,
            -0.006685170810669661,
            -0.01943320967257023,
            -0.01755967177450657,
            -0.009156265296041965,
            0.025802960619330406,
            -0.006283806171268225,
            0.020423926413059235,
            -0.014233160763978958,
            -0.021032556891441345,
            -0.009250961244106293,
            -0.022216016426682472,
            0.0217073205858469,
            0.01718123070895672,
            -0.004394500516355038,
            0.01954631134867668,
            -0.022694353014230728,
            0.005107404664158821,
            -0.0027662937063723803,
            -0.0005853476468473673,
            -0.03307602182030678,
            -0.015728799626231194,
            -0.02588951587677002,
            -0.0049743931740522385,
            -0.025422392413020134,
            -0.01693602278828621,
            -0.021594582125544548,
            -0.007600490469485521,
            0.03064967505633831,
            0.010203876532614231,
            -0.02169150300323963,
            -0.02069554664194584,
            0.010472742840647697,
            0.01626373454928398,
            0.015632109716534615,
            0.03134726732969284,
            0.03009818121790886,
            -0.021010296419262886,
            0.023886103183031082,
            -0.0031916871666908264,
            -0.03111105039715767,
            0.015332190319895744,
            0.019891202449798584,
            -0.03681119903922081,
            -0.025544559583067894,
            -0.008139708079397678,
            0.02305549755692482,
            -0.024297557771205902,
            0.014998331665992737,
            -0.017305757850408554,
            0.021892955526709557,
            0.020922943949699402,
            -0.00199046079069376,
            0.009115820750594139,
            0.00556437112390995,
            -0.008207837119698524,
            0.023882875218987465,
            0.03513903543353081,
            -0.029462967067956924,
            -0.02396904118359089,
            -0.011388429440557957,
            -0.0331384502351284,
            -0.010721652768552303,
            -0.018243037164211273,
            -0.01068015769124031,
            -0.023459134623408318,
            0.027395740151405334,
            0.030347295105457306,
            0.028230955824255943,
            0.00994283426553011,
            -0.01684524118900299,
            -0.0002494457003194839,
            0.0030706929974257946,
            -0.005729736294597387,
            -0.013069916516542435,
            -0.01744898222386837,
            0.0202141422778368,
            0.0028330578934401274,
            0.00901727844029665,
            0.03895013779401779,
            0.0021539200097322464,
            -0.022912561893463135,
            0.049281273037195206,
            0.006533684208989143,
            0.01271852571517229,
            -0.004299539607018232,
            -0.02354866825044155,
            0.01918082684278488,
            -0.03532242029905319,
            0.023863011971116066,
            0.04166224226355553,
            0.01373415719717741,
            0.013319572433829308,
            -0.012999897822737694,
            0.03209667652845383,
            -0.013432844541966915,
            -0.01700417511165142,
            0.02007286250591278,
            0.011047220788896084,
            0.018248965963721275,
            0.003457101061940193,
            0.028975646942853928,
            -0.0007753362297080457,
            0.004586030729115009,
            0.015214758925139904,
            -0.0032932788599282503,
            0.025856761261820793,
            0.012009849771857262,
            -0.004067645873874426,
            0.011404129676520824,
            0.009773933328688145,
            -0.005688374862074852,
            0.01782919652760029,
            0.03315863013267517,
            0.012335673905909061,
            0.018914001062512398,
            -0.010094021447002888,
            -0.035620592534542084,
            0.002729918109253049,
            -0.04030543193221092,
            0.00023480819072574377,
            -0.02237732522189617,
            0.011071083135902882,
            -0.044792138040065765,
            0.0028166761621832848,
            0.006177033297717571,
            0.02249854989349842,
            -0.02213747799396515,
            0.034087151288986206,
            0.030489085242152214,
            -0.030556607991456985,
            -0.020682241767644882,
            0.012006080709397793,
            0.02441481687128544,
            -0.033102527260780334,
            -0.010775238275527954,
            0.0005374164320528507,
            0.02456958405673504,
            0.014760047197341919,
            -0.0008965488523244858,
            0.0046367947943508625,
            -0.006843099370598793,
            -0.004384172149002552,
            -0.018019801005721092,
            0.03861210495233536,
            0.040200572460889816,
            0.015425761230289936,
            0.005341328680515289,
            -0.006975084077566862,
            0.006623590365052223,
            0.022994620725512505,
            -0.016645681113004684,
            0.008275803178548813,
            -0.005405556410551071,
            -0.01601305417716503,
            -0.01656975969672203,
            0.01722065731883049,
            -0.009352254681289196,
            -0.0035444889217615128,
            0.0004842296475544572,
            0.010764594189822674,
            -0.024298036471009254,
            0.03764180466532707,
            0.014542424120008945,
            0.025864331051707268,
            -0.02278059348464012,
            -0.02583480253815651,
            0.015251804143190384,
            0.02387453429400921,
            0.02220243401825428,
            -0.015836982056498528,
            0.02874060533940792,
            -0.014324906282126904,
            0.01798444427549839,
            0.031963806599378586,
            -0.0202222540974617,
            0.04099532961845398,
            0.018862280994653702,
            -0.02665887586772442,
            -0.01501160953193903,
            -0.027433151379227638,
            -0.002129798289388418,
            -0.025367503985762596,
            0.02503504976630211,
            0.002888904884457588,
            0.024146609008312225,
            -0.02522088587284088,
            -0.00916933361440897,
            -0.007060757838189602,
            -0.010875539854168892,
            -0.005331501364707947,
            0.003465539077296853,
            -0.0246171485632658,
            0.0004915964091196656,
            -0.03181740641593933,
            0.03271036595106125,
            -0.027766728773713112,
            0.025501251220703125,
            0.010935583151876926,
            -0.013118043541908264,
            0.016515983268618584,
            -0.0012829875340685248,
            0.013132119551301003,
            0.012284685857594013,
            0.01029203925281763,
            -0.013444687239825726,
            -0.01330029871314764,
            -0.03525732457637787,
            -0.002375109354034066,
            -0.0013096011243760586,
            -0.008409140631556511,
            -0.013647490181028843,
            -0.030843619257211685,
            0.011345961131155491,
            0.011833932250738144,
            -0.008999609388411045,
            0.002488169353455305,
            0.03429580107331276,
            0.006733345799148083,
            0.015872694551944733,
            -0.002622682834044099,
            -0.0044182464480400085,
            -0.015619976446032524,
            0.03362002968788147,
            0.03212938830256462,
            -0.024029670283198357,
            -0.019386783242225647,
            -0.028325337916612625,
            -0.012624061666429043,
            0.012266907840967178,
            -0.003803545143455267,
            0.028098249807953835,
            -0.015398377552628517,
            0.0023880614899098873,
            0.031087258830666542,
            0.013983180746436119,
            0.004365919157862663,
            0.003537768730893731,
            0.035007625818252563,
            -0.011097894981503487,
            -0.003940105903893709,
            0.007817941717803478,
            -0.03446948900818825,
            -0.027029581367969513,
            -0.04496535286307335,
            -0.0005960864946246147,
            0.010521738789975643,
            -0.006730794906616211,
            -0.023049646988511086,
            -0.0072240368463099,
            0.034739963710308075,
            0.017295727506279945,
            -0.01416006963700056,
            0.02230186015367508,
            0.007569745182991028,
            -0.027153218165040016,
            0.008445141837000847,
            -0.02437683567404747,
            0.017219260334968567,
            0.016977179795503616,
            -0.007220941595733166,
            0.019988955929875374,
            -0.020282451063394547,
            -0.017836835235357285,
            0.0006221887888386846,
            -0.008462975732982159,
            -0.037090349942445755,
            -0.0007607461884617805,
            -0.00760696642100811,
            -0.0022874914575368166,
            0.013637535274028778,
            -0.018579119816422462,
            0.010057984851300716,
            0.004892794881016016,
            0.0028528422117233276,
            -0.02935055084526539,
            -0.006956479046493769,
            -0.015371561981737614,
            0.01418555248528719,
            0.014731591567397118,
            0.01830819807946682,
            0.036533985286951065,
            0.04296880587935448,
            0.014585325494408607,
            -0.048545271158218384,
            0.020036717876791954,
            0.0016948734410107136,
            -0.012092700228095055,
            0.02005118690431118,
            -0.014650708064436913,
            0.007326019462198019,
            0.0029082433320581913,
            0.02715260535478592,
            0.020799290388822556,
            0.012323406524956226,
            -0.010289189405739307,
            0.004442219156771898,
            0.03851718455553055,
            0.015489842742681503,
            -0.023559870198369026,
            -0.0026933588087558746,
            0.011691838502883911,
            0.014135085977613926,
            0.0024182163178920746,
            0.01493997871875763,
            0.00677475705742836,
            -0.017088675871491432,
            -0.014727164059877396,
            -0.027325771749019623,
            0.0004353332333266735,
            -0.039549123495817184,
            -0.01868186518549919,
            -0.015799827873706818,
            0.02177017368376255,
            0.01777290739119053,
            0.004538405686616898,
            0.02133392170071602,
            0.017436979338526726,
            0.004379590507596731,
            0.01161216851323843,
            -0.00834027398377657,
            0.0441894605755806,
            -0.02948617748916149,
            0.01980709284543991,
            0.007809069007635117,
            -0.009659894742071629,
            0.033411044627428055,
            -0.0035588110331445932,
            0.013935446739196777,
            0.0066121588461101055,
            0.03902757912874222,
            0.02668854035437107,
            -0.01426429208368063,
            0.014203318394720554,
            -0.0023707516957074404,
            -0.029512757435441017,
            -0.005228024907410145,
            -0.02443963661789894,
            -0.01126431580632925,
            0.019356820732355118,
            0.013235993683338165,
            0.012376057915389538,
            -0.020901206880807877,
            0.0005459298263303936,
            0.00246025575324893,
            0.011077385395765305,
            0.025447150692343712,
            -0.004393409937620163,
            0.02817123383283615,
            -0.012696817517280579,
            -0.0207917932420969,
            -0.018317019566893578,
            0.027582168579101562,
            -0.0036742498632520437,
            -0.01739235781133175,
            0.049431946128606796,
            0.02439548820257187,
            -0.020975301042199135,
            0.001804240746423602,
            -0.025422602891921997,
            -0.01283759530633688,
            0.035774193704128265,
            0.05417843908071518,
            0.006943244021385908,
            0.004892997909337282,
            -0.020741159096360207,
            0.024647779762744904,
            0.023013079538941383,
            0.024548154324293137,
            -0.019488364458084106,
            0.035246625542640686,
            0.025362739339470863,
            0.020165732130408287,
            0.00474339397624135,
            -0.0068400148302316666,
            -0.024897688999772072,
            -0.019871557131409645,
            0.028759242966771126,
            -0.0007441555499099195,
            -0.015982991084456444,
            0.023061227053403854,
            -0.024662978947162628,
            -0.018756547942757607,
            0.036834027618169785,
            -0.033697184175252914,
            -0.03130446746945381,
            0.00831643957644701,
            -0.007671994622796774,
            0.008006730116903782,
            0.007934696041047573,
            0.011241608299314976,
            -0.01970679499208927,
            0.011060144752264023,
            0.006947304122149944,
            -0.007241570856422186,
            -0.017735624685883522,
            0.010665489360690117,
            -0.03765399754047394,
            -0.009646346792578697,
            0.024884438142180443,
            0.03430311009287834,
            0.01883242465555668,
            -0.012942229397594929,
            -0.011251181364059448,
            -0.030657842755317688,
            0.018490532413125038,
            0.020838243886828423,
            0.018412679433822632,
            0.004849584773182869,
            0.009198158048093319,
            -0.025295333936810493,
            -0.020834896713495255,
            -0.02473461627960205,
            0.013197083957493305,
            -0.01607893779873848,
            -0.01891620084643364,
            0.012719940394163132,
            -0.018040573224425316,
            -0.02679218351840973,
            0.017367951571941376,
            -0.0038798791356384754,
            -0.01739378087222576,
            0.011112064123153687,
            0.0150715047493577,
            0.004081514198333025,
            0.027299368754029274,
            0.0049149999395012856,
            -0.017410345375537872,
            -0.015998490154743195,
            0.024594375863671303,
            0.012720942497253418,
            -0.032047007232904434,
            0.0074982428923249245,
            -0.02370401844382286,
            0.004503408446907997,
            0.0013703962322324514,
            0.01647871918976307,
            -0.011107979342341423,
            -0.017672326415777206,
            0.001833330374211073,
            -0.0007284447201527655,
            -0.013648985885083675,
            0.013321197591722012,
            0.0026498641818761826,
            0.011095837689936161,
            -0.013070148415863514,
            -0.020568672567605972,
            0.034403055906295776,
            0.0049438197165727615,
            0.03026977926492691,
            -0.001724951434880495,
            0.01082742027938366,
            0.020885109901428223,
            -0.01246859971433878,
            -0.005692765116691589,
            0.009952858090400696,
            0.02205224707722664,
            -0.035998694598674774,
            0.008568513207137585,
            0.012716996483504772,
            0.013743633404374123,
            -0.008748160675168037,
            -0.02381169982254505,
            0.0192462969571352,
            0.004603206180036068,
            0.013648019172251225,
            0.018456673249602318,
            0.017314735800027847,
            -0.0015665364917367697,
            -0.03222731873393059,
            0.011575961485505104,
            -0.015634333714842796,
            -0.015166863799095154,
            -0.011119091883301735,
            -0.02027897536754608,
            -0.014002842828631401,
            -0.010627806186676025,
            -0.004446886945515871,
            0.03018537349998951,
            0.010633451864123344,
            -0.0005580515135079622,
            0.01682925969362259,
            0.024026134982705116,
            -0.0017179811839014292,
            -0.01197895035147667,
            -0.018847206607460976,
            -0.00327008287422359,
            0.03460974991321564,
            0.01315679308027029,
            -0.02297220006585121,
            0.01270230021327734,
            0.02454167604446411,
            -0.02612023614346981,
            0.015523419715464115,
            -0.008393061347305775,
            -0.028999192640185356,
            0.023473495617508888,
            0.009001913480460644,
            -0.02352651208639145,
            -0.039595045149326324,
            0.03923824802041054,
            -0.003414487699046731,
            -0.01443851925432682,
            0.003608197905123234,
            -0.01553250476717949,
            0.004911175463348627,
            -0.015856284648180008,
            -0.010733167640864849,
            0.006472759414464235,
            -0.004587135743349791,
            0.024614892899990082,
            0.027610180899500847,
            0.0048500229604542255,
            0.005331059452146292,
            -0.000810515892226249,
            0.03684626892209053,
            0.012901249341666698,
            0.03454064205288887,
            0.030784599483013153,
            -0.012519372627139091,
            -0.005090747028589249,
            0.009452645666897297,
            -0.01713460125029087,
            0.03774649649858475,
            -0.01695898547768593,
            0.006535245571285486,
            -0.0325232557952404,
            0.027750974521040916,
            0.003381346585229039,
            0.01358739659190178,
            0.015886478126049042,
            0.01450121309608221,
            -0.0026154478546231985,
            0.003060463583096862,
            -0.003994490951299667,
            -0.01510980911552906,
            -0.011247877962887287,
            0.021016426384449005,
            -0.02107906900346279,
            0.012048410251736641,
            0.01437461469322443,
            0.009641489014029503,
            0.027284394949674606,
            0.023884830996394157,
            -0.03284059092402458,
            0.014812137000262737,
            0.0301100742071867,
            0.023309607058763504,
            -0.02452395297586918,
            0.017680710181593895,
            0.026812631636857986,
            -0.008966985158622265,
            0.017491023987531662,
            -0.016057804226875305,
            -0.010154162533581257,
            0.00795993022620678,
            0.012457607313990593,
            0.009932576678693295,
            -0.00746220862492919,
            -0.007312633562833071,
            0.041406817734241486,
            -0.01419202983379364,
            -0.01605539582669735,
            -0.033119574189186096,
            -0.020549938082695007,
            0.010534849017858505,
            0.01976141892373562,
            -0.003923260141164064,
            -0.007743977475911379,
            0.005180319305509329,
            -0.0018201979110017419,
            0.025389444082975388,
            0.02308099903166294,
            -0.006206856574863195,
            0.0033459854312241077,
            -0.02127094939351082,
            -0.04009734094142914,
            0.03981323912739754,
            0.022198643535375595,
            0.005114931613206863,
            0.034260742366313934,
            0.020205514505505562,
            0.020492585375905037,
            0.02679516188800335,
            -0.004558437969535589,
            -0.0056160795502364635,
            -0.01815366931259632,
            -0.013265117071568966,
            -0.0022843796759843826,
            0.012173783965408802,
            -0.02312813699245453,
            0.026795251294970512,
            -0.015332886949181557,
            0.008347934111952782,
            0.004868008196353912,
            -0.01266852393746376,
            -0.012682183645665646,
            -0.013601942919194698,
            -0.004462879616767168,
            -0.008136452175676823,
            -0.007235316094011068,
            -0.013303008861839771,
            -0.020536651834845543,
            -0.03586423769593239,
            0.027577169239521027,
            0.005370576400309801,
            -0.0248112753033638,
            0.015767814591526985,
            -0.01941738836467266,
            0.0004701632133219391,
            0.007985440082848072,
            -0.0013278825208544731,
            -0.01689470186829567,
            -0.011667652986943722,
            -0.003702818648889661,
            0.0020333307329565287,
            0.027907980605959892,
            -0.026263780891895294,
            0.016496649011969566,
            -0.005695121828466654,
            0.009399166330695152,
            -0.006621466483920813,
            -0.012412535957992077,
            0.03106253780424595,
            -0.030181868001818657,
            0.030051957815885544,
            0.005417068488895893,
            0.016149697825312614,
            0.0007728971540927887,
            -0.025440890341997147,
            0.031174104660749435,
            -0.033280663192272186,
            0.008468949235975742,
            0.023216724395751953,
            -0.015562515705823898,
            0.014483112841844559,
            -0.01973338983952999,
            0.04276382178068161,
            -0.010915789753198624,
            -0.026045892387628555,
            -0.014408432878553867,
            -0.046231064945459366,
            -0.006345060188323259,
            -0.03000476211309433,
            0.030398884788155556,
            0.030045155435800552,
            -0.0040413676761090755,
            0.006366835907101631,
            0.039237529039382935,
            0.023839157074689865,
            0.02319587953388691,
            -0.019754985347390175,
            -0.008299375884234905,
            0.008949670009315014,
            -0.0035786423832178116,
            0.03167259693145752,
            -0.04381426423788071,
            -0.03378865495324135,
            -0.027785880491137505,
            -0.01640203967690468,
            -0.014230029657483101,
            0.02304353006184101,
            -0.009395585395395756,
            0.03426913172006607,
            -0.01189601793885231,
            -0.0033138596918433905,
            0.00516474898904562,
            0.024484431371092796,
            0.03406519815325737,
            -0.026562165468931198,
            -0.022222626954317093,
            0.016866927966475487,
            0.02675853669643402,
            0.024372652173042297,
            -0.017264975234866142,
            0.013241508975625038,
            0.02651975117623806,
            0.010686923749744892,
            -0.016755221411585808,
            0.022812800481915474,
            -0.02475990355014801,
            0.01641409657895565,
            -0.00013644341379404068,
            0.022133072838187218,
            0.014540261588990688,
            -0.02991877682507038,
            -0.011717032641172409,
            -0.021856749430298805,
            -0.030645938590168953,
            0.01125283446162939,
            -0.01558700017631054,
            0.0191209614276886,
            0.009600026533007622,
            0.01855519227683544,
            -0.0005192590760998428,
            0.015209409408271313,
            0.01354242768138647,
            0.000175265027792193,
            0.012281280010938644,
            0.011081978678703308,
            0.010510502383112907,
            0.014816626906394958,
            -0.015000942163169384,
            -0.013491862453520298,
            0.0011750489939004183,
            0.01627793349325657,
            -0.03842096030712128,
            -0.008453075774013996,
            0.014499879442155361,
            0.04510591924190521,
            -0.013963396660983562,
            0.015840958803892136,
            -0.01568487100303173,
            0.008814766071736813,
            -0.008394699543714523,
            -0.004669618792831898,
            0.012937127612531185,
            0.01569265127182007,
            0.01688935048878193,
            -0.010816081427037716,
            0.02402624301612377,
            0.007695856504142284,
            -0.013812645338475704,
            0.007338038645684719,
            -0.03234785422682762,
            0.017760762944817543,
            -0.006323174573481083,
            -0.02514379657804966,
            -0.011848325841128826,
            -0.026123089715838432,
            0.007967952638864517,
            0.012192800641059875,
            -0.024538908153772354,
            -0.028707806020975113,
            -0.0017027291469275951,
            0.00046472123358398676,
            0.016691498458385468,
            0.022701380774378777,
            -0.010953579097986221,
            0.006900184787809849,
            -0.006818060763180256,
            0.019941041246056557,
            0.02241419069468975,
            0.017818249762058258,
            0.013810318894684315,
            0.03103637881577015,
            -0.019612066447734833,
            0.02596387267112732,
            -0.024250578135252,
            0.02831154316663742,
            -0.029767578467726707,
            0.00946175679564476,
            0.00975716207176447,
            0.0034613660536706448,
            0.01943853497505188,
            -0.024575205519795418,
            -0.004602943081408739,
            -0.009254305623471737,
            0.026566380634903908,
            0.0060594049282372,
            -0.050735075026750565,
            0.007623522076755762,
            0.007515924982726574,
            0.01890915259718895,
            0.022499816492199898,
            0.02541193552315235,
            -0.008146028034389019,
            -0.012943965382874012,
            0.02383444458246231,
            0.0013868416426703334,
            0.01153095904737711,
            -0.026877325028181076,
            0.055449146777391434,
            -0.0059151919558644295,
            0.02694101259112358,
            0.015322749502956867,
            -0.012073248624801636,
            0.005371136590838432,
            0.004350235685706139,
            0.02636515162885189,
            0.007562312297523022,
            -0.020605262368917465,
            0.0005805290420539677,
            0.02461935393512249,
            0.012710093520581722,
            -0.004136775620281696,
            0.005139777436852455,
            0.012886610813438892,
            0.017557252198457718,
            0.028204943984746933,
            0.03004748560488224,
            -0.017867358401417732,
            0.014914981089532375,
            -0.0431923046708107,
            0.022005463019013405,
            -0.0065827579237520695,
            0.019711801782250404,
            0.001152198063209653,
            -0.02503156289458275,
            -0.012250460684299469,
            -0.0155419260263443,
            -0.00949142687022686,
            0.03950412943959236,
            0.01251357514411211,
            -0.0005819780635647476,
            -0.0006177160539664328,
            0.02354596182703972,
            -0.0040517239831388,
            0.010222685523331165,
            0.01807887852191925,
            -0.021120456978678703,
            0.002510523423552513,
            -0.00269838934764266,
            0.00712216692045331,
            -0.012286670506000519,
            0.0002620916347950697,
            0.004563614260405302,
            0.025584720075130463,
            -0.010271585546433926,
            0.001370033249258995,
            0.00190140085760504,
            0.01580282673239708,
            0.00015321822138503194,
            -4.621358311851509e-05,
            0.0005970216589048505,
            -0.007316086906939745,
            -0.017985809594392776,
            0.02090413123369217,
            0.028692536056041718,
            -0.008826340548694134,
            -0.04032148793339729,
            -0.02141496352851391,
            -0.03445976972579956,
            0.015485103242099285,
            -0.0010569891892373562,
            -0.033995840698480606,
            0.01357303187251091,
            0.02031026780605316,
            0.0123563501983881,
            0.0034277683589607477,
            -6.113273411756381e-05,
            -0.0029650500509887934,
            0.014725733548402786,
            -0.014058597385883331,
            -0.002322650980204344,
            0.058309271931648254,
            -0.0037739707622677088,
            0.006404467858374119,
            0.015444743447005749,
            0.013944676145911217,
            -0.02307645045220852,
            -0.021855954080820084,
            0.023579396307468414,
            -0.01828746497631073,
            0.00509444996714592,
            0.021568574011325836,
            -0.019080333411693573,
            0.022466983646154404,
            0.03175580874085426,
            0.024290090426802635,
            -0.008899020962417126,
            -0.018726693466305733,
            -0.019719485193490982,
            0.02278260886669159,
            0.03313601762056351,
            -0.009494922123849392,
            -0.025367671623826027,
            0.000335030781570822,
            -0.02922392077744007,
            0.0006411924841813743,
            -0.009696319699287415,
            0.015230899676680565,
            0.028686296194791794,
            0.017680276185274124,
            0.02652905322611332,
            -0.017708394676446915,
            -0.020780624821782112,
            -0.0011987611651420593,
            0.03352274373173714,
            0.021769041195511818,
            -0.004897449165582657,
            -0.0077216532081365585,
            0.004593879450112581,
            -0.019617702811956406,
            0.013529110699892044,
            -0.021501321345567703,
            0.03767620399594307,
            0.031696539372205734,
            -0.022601816803216934,
            -0.016314664855599403,
            0.017477557063102722,
            0.015176907181739807,
            0.041452474892139435,
            -0.009641697630286217,
            -0.01297299936413765,
            0.004903825465589762,
            -0.03217986226081848,
            0.03332402557134628,
            -0.002403239719569683,
            -0.01452972274273634,
            0.033121850341558456,
            -0.03505789116024971,
            0.015505705028772354,
            0.013895159587264061,
            0.027590325102210045,
            -0.008192637003958225,
            0.01604544371366501,
            -0.013243453577160835,
            0.02615543268620968,
            -0.0053088851273059845,
            -0.00029528504819609225,
            -0.02883654646575451,
            -0.03543586656451225,
            0.024461893364787102,
            -0.016949577257037163,
            -0.016975590959191322,
            0.01780804991722107,
            -0.029025502502918243,
            0.03968700021505356,
            0.024727854877710342,
            -0.01978548988699913,
            -0.002259502885863185,
            0.013894710689783096,
            0.023876944556832314,
            0.03146149590611458,
            0.028057768940925598,
            0.0024906806647777557,
            0.003772598458454013,
            -0.0008035052451305091,
            0.02938859723508358,
            0.0438576340675354,
            -0.006827250588685274,
            0.010821950621902943,
            -0.02442266047000885,
            0.008968432433903217,
            0.02430298738181591,
            0.011814124882221222,
            0.021863874047994614,
            -0.027825098484754562,
            -0.020495232194662094,
            0.018309080973267555,
            0.011222034692764282,
            0.03263978287577629,
            -0.0003022703167516738,
            0.050018444657325745,
            -0.0040562949143350124,
            0.014706660993397236,
            0.00404752604663372,
            0.027628889307379723,
            0.04029905050992966,
            0.013574667274951935,
            0.00993944238871336,
            -0.01680430956184864,
            -0.023512843996286392,
            -0.007872764021158218,
            0.00902540609240532,
            0.03188544139266014,
            -0.024244247004389763,
            0.015314723365008831,
            -0.002279558451846242,
            -0.005284610670059919,
            -0.02811281383037567,
            0.026198510080575943,
            0.007326316554099321,
            0.02901734784245491,
            -0.01755792461335659,
            0.023041721433401108,
            -0.02330181747674942,
            0.03333967551589012,
            -0.0014449520967900753,
            0.0318414606153965,
            -0.006506526377052069,
            0.0225033238530159,
            0.031548675149679184,
            0.007530959323048592,
            0.017453337088227272,
            0.03107489086687565,
            -0.032987505197525024,
            -0.011732026934623718,
            -0.01663239859044552,
            -0.011567847803235054,
            -0.00047715308028273284,
            0.008205813355743885,
            0.009667182341217995,
            -0.005633947439491749,
            -0.015488079749047756,
            0.03147399052977562,
            0.02287924848496914,
            0.021816041320562363,
            -0.008629840798676014,
            0.022087758406996727,
            -0.0046159131452441216
        ],
        [
            -0.02389306202530861,
            -0.018639883026480675,
            -0.0070685697719454765,
            -0.015354047529399395,
            -0.0023930310271680355,
            -0.00855673011392355,
            -0.04043764993548393,
            0.0007912278524599969,
            0.03764064237475395,
            0.010940361768007278,
            -0.04318012669682503,
            0.017849473282694817,
            -0.00024142512120306492,
            0.019937237724661827,
            -0.04523675888776779,
            -0.005969105288386345,
            -0.0019768457859754562,
            0.033163201063871384,
            0.025448111817240715,
            0.0008993498631753027,
            0.0035089680459350348,
            0.003519007470458746,
            -0.017633285373449326,
            0.03141232579946518,
            -0.0483718141913414,
            0.006113357841968536,
            -0.03922237828373909,
            -0.01818285509943962,
            0.009576866403222084,
            0.006894771009683609,
            0.03311433643102646,
            0.006098644807934761,
            0.026222387328743935,
            -0.00027893000515177846,
            0.0050192056223750114,
            0.01567341387271881,
            -0.00397329730913043,
            0.004838944412767887,
            0.006506395526230335,
            -0.015672221779823303,
            -0.012115717865526676,
            -0.02996188774704933,
            0.004904169589281082,
            0.022898171097040176,
            -0.016632990911602974,
            -0.018916266039013863,
            0.008766829036176205,
            -0.0515839047729969,
            0.002917693229392171,
            -0.02461523562669754,
            0.014683683402836323,
            0.016636768355965614,
            -0.004399020690470934,
            0.0038189247716218233,
            0.006755234207957983,
            -0.01605302281677723,
            -0.015674544498324394,
            -0.027434108778834343,
            0.03157610073685646,
            0.002039533108472824,
            0.03488481789827347,
            0.004902024753391743,
            -0.008005877956748009,
            -0.0005491999909281731,
            -0.021355316042900085,
            0.04112452268600464,
            0.010245854035019875,
            -0.012605877593159676,
            -0.028701288625597954,
            0.02873755805194378,
            0.015018288977444172,
            0.011962494812905788,
            -0.01630249060690403,
            -0.017057787626981735,
            0.024461308494210243,
            -0.017591746523976326,
            -0.01784801110625267,
            0.03097483329474926,
            -0.027371849864721298,
            -0.012474432587623596,
            0.008515294641256332,
            -0.07223907113075256,
            -0.0014102571876719594,
            -0.016480203717947006,
            -0.003987186122685671,
            0.04580582678318024,
            0.0025836238637566566,
            -0.013007864356040955,
            -0.01584131270647049,
            0.045625943690538406,
            0.01271189283579588,
            -0.03329598531126976,
            -0.005204115528613329,
            -0.03770125284790993,
            0.013823134824633598,
            -0.025814253836870193,
            0.04642416909337044,
            0.037005383521318436,
            -0.10366914421319962,
            0.01783689297735691,
            0.01986541412770748,
            -0.0010697528487071395,
            -0.005411776248365641,
            0.0008203058387152851,
            -0.01684492826461792,
            0.022135023027658463,
            0.026863180100917816,
            0.028314828872680664,
            0.01765153557062149,
            0.006500218063592911,
            -0.021388590335845947,
            -0.029695114120841026,
            -0.00921631045639515,
            0.02355320379137993,
            -0.0606563538312912,
            0.018751751631498337,
            -0.009672514162957668,
            -0.016164517030119896,
            0.00660391291603446,
            -0.0150282708927989,
            0.012348503805696964,
            -0.009155941195786,
            -0.017145123332738876,
            -0.009586803615093231,
            0.005622504279017448,
            -0.01835121400654316,
            -0.006438275333493948,
            0.010347471572458744,
            0.03477156534790993,
            -0.032743148505687714,
            -0.009612762369215488,
            0.016299713402986526,
            0.03630933165550232,
            0.013457651250064373,
            -0.02187662571668625,
            0.024031074717640877,
            0.025682035833597183,
            -0.0018334551714360714,
            -0.023951934650540352,
            0.024895472452044487,
            0.010154681280255318,
            0.036318983882665634,
            0.00826708972454071,
            -0.008974493481218815,
            0.008936933241784573,
            -0.0220024511218071,
            -0.003701182082295418,
            0.02613074891269207,
            0.00775883300229907,
            -0.020135685801506042,
            -0.010474283248186111,
            -0.02361656352877617,
            -0.0030388636514544487,
            0.033620379865169525,
            -0.006676431745290756,
            0.015340096317231655,
            -0.027036698535084724,
            0.023945823311805725,
            -0.0010649816831573844,
            -0.019834278151392937,
            -0.019313916563987732,
            0.01276574656367302,
            -0.008443675935268402,
            -0.012578231282532215,
            -0.0009116786532104015,
            0.0007101622759364545,
            -0.0030522667802870274,
            -0.03993332386016846,
            -0.017737403512001038,
            -0.031505875289440155,
            -0.019973760470747948,
            0.020327413454651833,
            -0.002818602602928877,
            0.005526308435946703,
            -0.021431151777505875,
            0.0020026755519211292,
            -0.02908921428024769,
            0.030250314623117447,
            -0.01384175568819046,
            0.004604647401720285,
            -0.0032305316999554634,
            0.010999412275850773,
            -0.00042880213004536927,
            0.039793066680431366,
            0.01820087805390358,
            0.002827482996508479,
            -0.03402881696820259,
            0.0009902919409796596,
            -0.02422492951154709,
            0.015121177770197392,
            -0.04111580178141594,
            0.00712237786501646,
            0.03374388441443443,
            0.011352219618856907,
            0.0025019156746566296,
            0.014436774887144566,
            0.031141940504312515,
            0.017107268795371056,
            0.020438846200704575,
            -0.0019331942312419415,
            0.017724722623825073,
            -0.018120408058166504,
            -0.030831560492515564,
            -0.007693305145949125,
            0.036448556929826736,
            0.0162656232714653,
            -0.008351457305252552,
            -0.021525459364056587,
            -0.03073718398809433,
            0.023663923144340515,
            -0.014267750084400177,
            -0.05725832283496857,
            -0.004016236402094364,
            0.0020258596632629633,
            0.013868060894310474,
            -0.01708470843732357,
            0.013414880260825157,
            -0.0197944063693285,
            -0.014259818010032177,
            -0.011583088897168636,
            0.018571307882666588,
            0.013692998327314854,
            0.019187286496162415,
            0.02367052249610424,
            -0.0016504646046087146,
            0.012933308258652687,
            0.02054368145763874,
            -0.0059655760414898396,
            0.0008297651074826717,
            0.008021936751902103,
            -0.02589108981192112,
            0.010443001985549927,
            0.01202450878918171,
            0.01734340563416481,
            -0.005490995477885008,
            -0.02877236157655716,
            0.04701846465468407,
            0.010954135097563267,
            -0.003948498517274857,
            -0.007641235366463661,
            0.005494141019880772,
            0.008907306008040905,
            -0.015333941206336021,
            -0.01084783673286438,
            0.019266758114099503,
            0.006245638243854046,
            0.02164277248084545,
            0.013682092539966106,
            0.017188888043165207,
            -0.02810990810394287,
            0.03029990941286087,
            0.010088542476296425,
            0.023997651413083076,
            0.035284873098134995,
            -0.0014391073491424322,
            -0.02344258315861225,
            -0.01746618002653122,
            0.0037439821753650904,
            0.02487325109541416,
            -0.003044222015887499,
            -0.005247391760349274,
            -0.03374214842915535,
            -0.011272664181888103,
            -0.00465863011777401,
            0.02872609905898571,
            -0.009390509687364101,
            0.025240277871489525,
            0.035529445856809616,
            0.007076318375766277,
            0.006189798936247826,
            -0.018317611888051033,
            0.016231751069426537,
            -0.021829837933182716,
            -0.02509772963821888,
            -0.0170181505382061,
            -0.0069385007955133915,
            0.0002887480368372053,
            0.024854246526956558,
            0.016411857679486275,
            0.011530088260769844,
            0.02086808905005455,
            0.02619829773902893,
            -0.028152158483862877,
            -0.005444589536637068,
            -0.013243382796645164,
            -0.011014210060238838,
            -0.021185284480452538,
            -0.0029535451903939247,
            -0.012897073291242123,
            -0.010171040892601013,
            0.024882523342967033,
            -0.003344301600009203,
            0.018590256571769714,
            0.0030652829445898533,
            0.018773362040519714,
            -0.018970424309372902,
            0.033277496695518494,
            -0.013941383920609951,
            -0.03148194029927254,
            -0.004389316774904728,
            -0.02452632412314415,
            -0.07461065798997879,
            0.009552529081702232,
            0.022505389526486397,
            -0.013038033619523048,
            0.0006533248233608902,
            0.008808097802102566,
            -0.02516922540962696,
            -0.045635852962732315,
            -0.02965015172958374,
            0.00594379473477602,
            -0.015554629266262054,
            0.00867245439440012,
            -0.02089880406856537,
            0.028975673019886017,
            0.0035359752364456654,
            0.015781233087182045,
            -0.015833182260394096,
            0.031832948327064514,
            0.032528266310691833,
            0.0013621626421809196,
            -0.02576375938951969,
            0.0008652688120491803,
            -0.02063320018351078,
            0.02326030470430851,
            -0.022829661145806313,
            0.019492996856570244,
            -0.026326024904847145,
            0.025896625593304634,
            0.03352540731430054,
            0.011768138967454433,
            -0.0283601563423872,
            -0.009616157971322536,
            -0.012820032425224781,
            -0.012915113009512424,
            0.02558138035237789,
            0.010734351351857185,
            0.02331625111401081,
            -0.03225202485918999,
            -0.010401067323982716,
            -0.0015679695643484592,
            0.0005997932748869061,
            0.01687324047088623,
            -0.0034658964723348618,
            -0.0005693135899491608,
            -0.0004924380336888134,
            0.003317165421321988,
            -0.017514435574412346,
            0.005116742569953203,
            -0.03596628084778786,
            0.012865313328802586,
            -0.037484463304281235,
            -0.03056642785668373,
            -0.0212931577116251,
            0.048828125,
            -0.00493578240275383,
            -0.02160661667585373,
            -0.019851498305797577,
            0.020161831751465797,
            -0.027791600674390793,
            -0.03317413851618767,
            0.017890118062496185,
            -0.0030721777584403753,
            0.02294052764773369,
            0.002181875752285123,
            -0.0006068068323656917,
            0.023961184546351433,
            0.020342454314231873,
            -0.019731132313609123,
            0.014298752881586552,
            0.013673805631697178,
            0.0025692868512123823,
            -0.007538742385804653,
            0.02156095765531063,
            -0.0038849906995892525,
            -0.03885219991207123,
            0.00909783411771059,
            -0.015005386434495449,
            0.005007259547710419,
            -0.01956961303949356,
            0.005894511006772518,
            0.0014524140860885382,
            -0.018941154703497887,
            -0.01191885955631733,
            0.0007345026242546737,
            -0.020640455186367035,
            0.027002599090337753,
            -0.0004435158334672451,
            0.009252145886421204,
            0.005233586300164461,
            -0.0015472809318453074,
            -0.02072904445230961,
            -0.03610258176922798,
            -0.038123853504657745,
            0.07385629415512085,
            -0.007976137101650238,
            -0.01442236453294754,
            -0.03012728877365589,
            0.01422752346843481,
            0.020800473168492317,
            0.04519171267747879,
            0.013769983313977718,
            0.002085563261061907,
            -0.029392439872026443,
            -0.026884712278842926,
            -0.013944365084171295,
            -0.020015330985188484,
            0.03891716152429581,
            0.01628589816391468,
            -0.025549152866005898,
            0.028997456654906273,
            0.03685664013028145,
            -0.01909669302403927,
            0.019884662702679634,
            0.02721337601542473,
            0.0343710295855999,
            -0.0149204321205616,
            -0.02380240522325039,
            0.011812913231551647,
            0.03389628976583481,
            -0.04895571991801262,
            0.018225667998194695,
            -0.041830454021692276,
            0.02144480124115944,
            0.02309824712574482,
            0.023347679525613785,
            -0.005985248368233442,
            0.031215526163578033,
            0.026214929297566414,
            -0.024133261293172836,
            0.010708333924412727,
            -0.02890065684914589,
            -0.002186162630096078,
            -0.025560755282640457,
            0.016629960387945175,
            -0.01318374089896679,
            -0.032142337411642075,
            -0.004065724089741707,
            -0.004053713288158178,
            -1.6002442862372845e-05,
            0.009498817846179008,
            -0.02386476844549179,
            0.015571549534797668,
            -0.013197463937103748,
            -0.03662151098251343,
            -0.03905756399035454,
            -0.007745277602225542,
            -0.07260120660066605,
            -0.027132324874401093,
            0.011085293255746365,
            -0.026576029136776924,
            0.014959721826016903,
            -0.07177174836397171,
            -0.026340311393141747,
            0.011157821863889694,
            0.036194004118442535,
            0.015364513732492924,
            -0.010593549348413944,
            -0.008137938566505909,
            -0.01471027173101902,
            0.00999370962381363,
            0.002932535484433174,
            -0.006283535156399012,
            -0.022730587050318718,
            0.026536384597420692,
            0.012179205194115639,
            -0.019796835258603096,
            -0.03680258244276047,
            0.020895106717944145,
            -0.019601810723543167,
            0.030641719698905945,
            0.03425212576985359,
            0.0003078375302720815,
            0.035035379230976105,
            -0.0010065340902656317,
            -0.014388593845069408,
            -0.016428567469120026,
            0.012643388472497463,
            -0.01034031342715025,
            0.0015332952607423067,
            0.005691444035619497,
            -0.00560001702979207,
            0.033814236521720886,
            0.028249848634004593,
            -0.06780761480331421,
            0.02490902878344059,
            -0.030798984691500664,
            -0.010764513164758682,
            -0.019338632002472878,
            0.02613133192062378,
            -0.0036892567295581102,
            0.02937404438853264,
            0.019992342218756676,
            0.027843615040183067,
            -0.029863303527235985,
            -0.004543997813016176,
            0.028640052303671837,
            0.01592443883419037,
            -0.015884218737483025,
            0.010547281242907047,
            -0.02988692931830883,
            -0.038171570748090744,
            -0.0020482183899730444,
            -0.00681141996756196,
            -0.01953786425292492,
            0.002725013066083193,
            0.02028835006058216,
            0.031676460057497025,
            0.012884017080068588,
            -0.03324371203780174,
            0.02579147182404995,
            0.04254326596856117,
            0.01296121347695589,
            0.03361394256353378,
            -0.02401384897530079,
            -0.02687583491206169,
            -0.02156818099319935,
            0.028130773454904556,
            -0.02347390353679657,
            0.02231091819703579,
            -0.022000957280397415,
            -0.023052219301462173,
            0.00886520091444254,
            0.03728563338518143,
            -0.007943573407828808,
            -0.025359168648719788,
            0.027759362012147903,
            0.004408174194395542,
            -0.005136537831276655,
            0.03603532537817955,
            -0.005371646489948034,
            0.012972087599337101,
            -0.008221813477575779,
            -0.008596056140959263,
            -0.0053840442560613155,
            -0.037360381335020065,
            0.01887310855090618,
            -0.01921369694173336,
            0.014555133879184723,
            0.02859194576740265,
            0.00801725685596466,
            0.02059832774102688,
            0.0164506696164608,
            0.001352243241854012,
            0.02538430318236351,
            -0.02352411486208439,
            0.007723360788077116,
            0.0282692089676857,
            0.016068775206804276,
            -0.007705203723162413,
            -0.02652706392109394,
            -0.017848506569862366,
            -0.028947364538908005,
            0.0018626829842105508,
            0.035123974084854126,
            0.010338018648326397,
            0.009279376827180386,
            -0.03175041824579239,
            0.02138373628258705,
            0.011666531674563885,
            -0.03424602374434471,
            0.007350608240813017,
            0.03030594065785408,
            -0.045867517590522766,
            -0.025154486298561096,
            -0.01296665333211422,
            -0.029237454757094383,
            -0.003022153628990054,
            -0.015689421445131302,
            0.006777279544621706,
            -0.026250259950757027,
            0.005579225718975067,
            -0.025326289236545563,
            -0.001702185720205307,
            0.019885540008544922,
            -0.005260510835796595,
            0.027270527556538582,
            -0.035124521702528,
            0.02506503462791443,
            0.006999263074249029,
            0.011460186913609505,
            -0.02836596965789795,
            0.026525691151618958,
            0.00490311486646533,
            0.00015917184646241367,
            0.0017696692375466228,
            -0.018620077520608902,
            0.004531999118626118,
            -0.015754370018839836,
            0.004394220188260078,
            -0.005358087830245495,
            -0.015504383482038975,
            0.02832205593585968,
            -0.005340054631233215,
            0.0013844004133716226,
            0.029578594490885735,
            0.01839674822986126,
            0.0024574757553637028,
            -0.0005553829250857234,
            -0.015789642930030823,
            -0.002587724942713976,
            0.004360751714557409,
            0.006037882063537836,
            -0.0024502347223460674,
            -0.020458444952964783,
            -0.040604785084724426,
            0.01041072141379118,
            0.030024182051420212,
            0.02407285012304783,
            0.010133803822100163,
            0.019460100680589676,
            -0.016516027972102165,
            0.01611563004553318,
            -0.018566882237792015,
            0.014288769103586674,
            0.02406139299273491,
            -0.022573919966816902,
            0.02983737178146839,
            -0.008020996116101742,
            0.0319242961704731,
            -0.016960421577095985,
            -0.02158748172223568,
            0.025677423924207687,
            0.0278974249958992,
            0.0010124038672074676,
            0.020495256409049034,
            0.017167706042528152,
            -0.009866530075669289,
            0.014730379916727543,
            0.010623577050864697,
            0.013041947968304157,
            -0.023698830977082253,
            0.028612367808818817,
            -0.03056330792605877,
            -0.013806340284645557,
            0.024920333176851273,
            -0.02864830009639263,
            -0.029809478670358658,
            -0.027352482080459595,
            0.004560389090329409,
            -0.04001696780323982,
            -0.015497298911213875,
            -0.05815030261874199,
            -0.021431831642985344,
            0.005047342274338007,
            -0.01736283116042614,
            -0.010547506622970104,
            0.014455342665314674,
            0.0011375406756997108,
            -0.021125303581357002,
            -0.016398102045059204,
            -0.021585166454315186,
            -0.0070166210643947124,
            -0.020765604451298714,
            -0.0004706604522652924,
            0.03770710155367851,
            -0.003897574730217457,
            0.02511381357908249,
            0.0017392300069332123,
            0.0012519547017291188,
            0.020239034667611122,
            0.03807640075683594,
            -0.021533701568841934,
            0.010528914630413055,
            0.006151877343654633,
            -0.008633516728878021,
            0.02440354973077774,
            0.011077247560024261,
            -0.028739260509610176,
            0.024145938456058502,
            -0.006675762124359608,
            0.013606901280581951,
            0.019644560292363167,
            -0.06904763728380203,
            0.03909270465373993,
            0.007453974336385727,
            -0.02407076209783554,
            0.024132777005434036,
            0.018405770882964134,
            -0.01949465647339821,
            -0.06793197244405746,
            -0.0346393883228302,
            -0.00047083248500712216,
            -0.034402996301651,
            -0.00025527412071824074,
            -0.008463823236525059,
            0.014979110099375248,
            0.029131945222616196,
            0.017962586134672165,
            -0.010613655671477318,
            -0.03886817768216133,
            -0.023341534659266472,
            -0.040499210357666016,
            0.013823796063661575,
            -0.021824542433023453,
            -0.011676781810820103,
            0.009685846976935863,
            -0.03959789127111435,
            0.0013617501826956868,
            -0.02380833961069584,
            0.002778866793960333,
            -0.020212752744555473,
            -0.029097290709614754,
            0.03473154082894325,
            0.014927821233868599,
            -0.03879286348819733,
            -0.03183472156524658,
            0.01101123820990324,
            0.007769562769681215,
            0.04697934165596962,
            0.013079090975224972,
            -0.030711671337485313,
            -0.01558542251586914,
            0.009654967114329338,
            0.021721763536334038,
            0.01865372620522976,
            -0.026908116415143013,
            -0.0475168339908123,
            -0.00030378648079931736,
            -0.020710064098238945,
            0.006864224560558796,
            -0.018591132014989853,
            0.005476005841046572,
            -0.0010822698241099715,
            0.019597096368670464,
            -0.022374112159013748,
            0.017498310655355453,
            -0.007795610930770636,
            -0.007448441348969936,
            -0.018420793116092682,
            -0.03928125277161598,
            0.009235739707946777,
            0.026580337435007095,
            0.029921066015958786,
            0.005478441249579191,
            0.010969513095915318,
            0.037967294454574585,
            -0.05549829453229904,
            -0.00314866635017097,
            -0.011013315059244633,
            0.022039024159312248,
            -0.01753375120460987,
            0.022777749225497246,
            -0.004022265784442425,
            -0.006069221068173647,
            -0.03525858744978905,
            0.026622872799634933,
            -0.02226056158542633,
            -0.00559625681489706,
            0.005559423938393593,
            0.023298056796193123,
            -0.0108232656493783,
            0.023351546376943588,
            -0.0034777596592903137,
            0.011423293501138687,
            -0.024493837729096413,
            -0.005252879112958908,
            0.018676945939660072,
            0.006181867327541113,
            0.012507450766861439,
            -0.026456672698259354,
            0.028167175129055977,
            -0.006947916466742754,
            0.013971086591482162,
            -0.031329356133937836,
            -0.016601210460066795,
            0.017993314191699028,
            -0.0058380854316055775,
            -0.01892584003508091,
            0.026980513706803322,
            0.01313878409564495,
            0.002414190210402012,
            0.015209824778139591,
            -0.022516906261444092,
            0.031538888812065125,
            0.02227870188653469,
            0.004502625670284033,
            -0.00032276142155751586,
            -0.019432514905929565,
            0.03014322929084301,
            -0.03800755366683006,
            -0.016905272379517555,
            -0.015163966454565525,
            0.017546895891427994,
            0.0034525487571954727,
            0.027732616290450096,
            -0.03585842251777649,
            0.023524418473243713,
            0.016960537061095238,
            -0.01725919172167778,
            -0.0015710759907960892,
            0.03157351166009903,
            -0.00019011714903172106,
            -0.021184010431170464,
            -0.011485123075544834,
            -0.048893559724092484,
            0.007531806360930204,
            0.02570338174700737,
            0.02278248779475689,
            0.012570742517709732,
            -0.007805973291397095,
            0.022674744948744774,
            -0.012138309888541698,
            -0.026725633069872856,
            0.014561524614691734,
            -0.01913352869451046,
            0.0054740263149142265,
            0.0014884659321978688,
            -0.02782714180648327,
            0.01059859897941351,
            -0.017033254727721214,
            0.013404782861471176,
            0.024916542693972588,
            -0.0050199138931930065,
            -0.02680565044283867,
            -0.016819242388010025,
            -0.0010270550847053528,
            -0.05153603479266167,
            -0.03272049129009247,
            -0.02989591285586357,
            -0.0025564765091985464,
            0.01422229502350092,
            0.006327604874968529,
            -0.03659087046980858,
            0.0015254952013492584,
            0.020773863419890404,
            0.0028867684304714203,
            -0.019903261214494705,
            -0.0025557964108884335,
            0.030385352671146393,
            0.012003066949546337,
            -0.010221965610980988,
            0.0010142381070181727,
            0.010252073407173157,
            -0.024686558172106743,
            -0.02794433943927288,
            -0.0030443870928138494,
            -0.00473659485578537,
            0.02755790948867798,
            -0.030209297314286232,
            0.014127722941339016,
            -0.006775083485990763,
            -0.043505799025297165,
            -0.005611838772892952,
            -0.027643539011478424,
            -0.004184382036328316,
            0.00828392431139946,
            -0.03970600292086601,
            -0.014731756411492825,
            -0.0011868226574733853,
            0.02001417800784111,
            -0.027438916265964508,
            -0.04562393203377724,
            -0.029262783005833626,
            -0.027220195159316063,
            0.025950191542506218,
            -0.01711284928023815,
            -0.0030813913326710463,
            -0.015467628836631775,
            0.014836445450782776,
            0.00985688529908657,
            0.014954783953726292,
            0.014730174094438553,
            -0.008023369126021862,
            0.02149241603910923,
            0.008844544179737568,
            0.0013551624724641442,
            0.027295751497149467,
            0.02293836884200573,
            -0.012189511209726334,
            -0.035536959767341614,
            -0.0031614822801202536,
            -0.04653330519795418,
            0.0010189422173425555,
            0.014345874078571796,
            -0.0033419399987906218,
            -0.04082230478525162,
            0.03743067383766174,
            0.0010420667240396142,
            -0.02404641918838024,
            0.024312645196914673,
            0.005980894900858402,
            0.011482072994112968,
            0.007128108758479357,
            -0.0013746534241363406,
            -0.02162322774529457,
            -0.006602554582059383,
            -0.0832488089799881,
            -0.004184905905276537,
            0.008823025040328503,
            -0.024722877889871597,
            0.027363086119294167,
            -0.0008313669823110104,
            0.030364690348505974,
            -0.004116140305995941,
            0.028611447662115097,
            0.012739856727421284,
            0.0008867502328939736,
            -0.01824285462498665,
            0.014034737832844257,
            -0.021178873255848885,
            0.013369124382734299,
            0.03502793610095978,
            0.017182933166623116,
            -0.00864676758646965,
            0.00022227680892683566,
            -0.03551303222775459,
            0.016810305416584015,
            -0.024536512792110443,
            0.014301489107310772,
            -0.028173403814435005,
            -0.01616320013999939,
            -0.017885716632008553,
            0.056921254843473434,
            -0.003598363371565938,
            0.023550638929009438,
            -0.005814670585095882,
            0.013796055689454079,
            -0.019128890708088875,
            -0.007075618952512741,
            0.004934760741889477,
            -0.022224724292755127,
            -0.022289138287305832,
            -0.02953057549893856,
            -0.08297356218099594,
            -0.009649432264268398,
            0.0015726623823866248,
            -0.021831581369042397,
            0.03722553700208664,
            3.172378637827933e-05,
            0.01696835644543171,
            -0.007985512726008892,
            -0.03334994241595268,
            -0.032804396003484726,
            0.03046295791864395,
            0.0068366690538823605,
            0.030567225068807602,
            -0.004557369276881218,
            -0.02200940251350403,
            0.020749259740114212,
            -0.017270103096961975,
            0.0003409100463613868,
            0.010349019430577755,
            0.0020716041326522827,
            0.025055550038814545,
            -0.017434101551771164,
            0.0037239508237689734,
            -0.027047408744692802,
            0.004925835411995649,
            -0.010326681658625603,
            0.036590274423360825,
            -0.02413708157837391,
            -0.03221309930086136,
            0.004390552174299955,
            -0.05450483784079552,
            -0.014089386910200119,
            0.015306410379707813,
            0.006852707825601101,
            -0.016453374177217484,
            0.0253972839564085,
            -0.036888882517814636,
            -0.0219836737960577,
            -0.003855122020468116,
            -0.04380563274025917,
            -0.014712752774357796,
            0.022856153547763824,
            0.03009471856057644,
            0.018113035708665848,
            0.015292785130441189,
            0.009949141182005405,
            0.01677672006189823,
            0.018784096464514732,
            0.01639796979725361,
            0.028392117470502853,
            0.013412301428616047,
            0.009980720467865467,
            0.030732616782188416,
            0.01426184456795454,
            0.015726791694760323,
            -0.03359723091125488,
            -0.031892355531454086,
            0.0018852287903428078,
            -0.035203080624341965,
            -0.00614322442561388,
            -0.008383803069591522,
            0.00736543582752347,
            -0.031278736889362335,
            0.007139144465327263,
            -0.0036047352477908134,
            0.0004101264348719269,
            -0.014511654153466225,
            -0.012795058079063892,
            -0.027592169120907784,
            -0.01647658459842205,
            0.001000648713670671,
            -0.039261382073163986,
            0.051560983061790466,
            -0.023125983774662018,
            -0.013889472931623459,
            0.026413846760988235,
            0.0004017071332782507,
            0.008155844174325466,
            -0.005629720166325569,
            -0.00012918197899125516,
            0.02040204219520092,
            0.007163395639508963,
            0.002814443316310644,
            -0.04378924518823624,
            -0.007341898046433926,
            -0.009103119373321533,
            0.008290314115583897,
            0.015061572194099426,
            0.01221109926700592,
            0.0037556185852736235,
            -0.03729190304875374,
            -0.05681176483631134,
            0.03690715134143829,
            -0.026366285979747772,
            -0.0013656094670295715,
            -0.0018943992909044027,
            -0.045479174703359604,
            0.009859181940555573,
            -0.013633030466735363,
            0.02014334872364998
        ],
        [
            0.011985701508820057,
            0.018699243664741516,
            -0.010038240812718868,
            -0.004465293139219284,
            -0.006898693274706602,
            0.009350131265819073,
            -0.021690046414732933,
            -0.006554622668772936,
            0.026496967300772667,
            0.02532985620200634,
            0.04996237903833389,
            0.025712106376886368,
            0.005471230950206518,
            0.026279129087924957,
            0.048021744936704636,
            0.0069643170572817326,
            0.01710117608308792,
            0.011889215558767319,
            0.00012028170749545097,
            0.03150157257914543,
            0.02313329465687275,
            -0.015892693772912025,
            0.03126741945743561,
            -0.04166407138109207,
            0.022369440644979477,
            -0.0052452702075243,
            0.005893155932426453,
            0.004561234265565872,
            0.01954287849366665,
            0.02649022825062275,
            -0.026171976700425148,
            0.009094116277992725,
            0.0040593259036540985,
            0.02386322431266308,
            0.019146548584103584,
            -0.006296750623732805,
            -0.010629500262439251,
            -0.03609050065279007,
            0.032312020659446716,
            -0.01916826330125332,
            0.017032792791724205,
            -0.025562763214111328,
            -0.02037285827100277,
            -0.023098422214388847,
            0.006645604968070984,
            -0.012047777883708477,
            -0.0008510397747159004,
            0.04052559286355972,
            -0.008719945326447487,
            0.05457407981157303,
            -0.012305506505072117,
            0.007089121732860804,
            -0.00931433867663145,
            -0.03674698621034622,
            -0.003042333060875535,
            -0.009851399809122086,
            0.030595071613788605,
            -0.021836986765265465,
            0.013646206818521023,
            -0.02468777447938919,
            -0.006355126854032278,
            0.03338392823934555,
            0.01460882555693388,
            0.020492788404226303,
            -0.019868146628141403,
            -0.012828403152525425,
            0.006583620794117451,
            -0.01775309070944786,
            0.0008854095940478146,
            0.030339734628796577,
            -0.0038453133311122656,
            -0.01698911562561989,
            0.03445114940404892,
            0.01279697846621275,
            0.005806471686810255,
            0.012354867532849312,
            0.010878786444664001,
            0.015165594406425953,
            0.045542847365140915,
            0.03141059726476669,
            0.031133564189076424,
            0.024740150198340416,
            -0.004451632034033537,
            0.03233029320836067,
            0.014636663720011711,
            -0.034450553357601166,
            -0.016935143619775772,
            0.0038542759139090776,
            -0.017264612019062042,
            0.02717544324696064,
            0.027934249490499496,
            0.016674015671014786,
            -0.021851325407624245,
            -0.00789906270802021,
            0.021667800843715668,
            0.012110979296267033,
            -0.008954665623605251,
            0.02943618968129158,
            0.018610170111060143,
            -0.015262329019606113,
            -0.010775794275105,
            -0.0037634572945535183,
            -0.034897856414318085,
            0.00015104217163752764,
            -0.005485196132212877,
            0.002386072650551796,
            0.011209461838006973,
            -0.03060603328049183,
            -0.005072083789855242,
            -0.028592681512236595,
            -0.02111118845641613,
            -0.0029200795106589794,
            0.01178896427154541,
            -0.014668995514512062,
            0.02453886717557907,
            -0.029098665341734886,
            0.01808345317840576,
            0.0233306847512722,
            0.025546373799443245,
            0.022209620103240013,
            0.029891934245824814,
            -0.003955700434744358,
            -0.004272024612873793,
            0.03372858092188835,
            -0.02514113485813141,
            0.02882121130824089,
            0.038619913160800934,
            0.02509680949151516,
            -0.0007237991085276008,
            0.02251170389354229,
            -0.003656805260106921,
            0.03412628173828125,
            0.014443336054682732,
            -0.012100104242563248,
            0.03115154057741165,
            0.016122978180646896,
            -0.023341182619333267,
            -0.022670803591609,
            0.02578548528254032,
            0.00888992752879858,
            0.010957914404571056,
            0.015552498400211334,
            -0.01638866402208805,
            -0.009714046493172646,
            -0.025644991546869278,
            -0.0064086345955729485,
            -0.0176389217376709,
            -0.004049827344715595,
            0.010294068604707718,
            0.009517712518572807,
            0.020027484744787216,
            0.00053504848619923,
            0.006387591361999512,
            0.005065765231847763,
            0.0315055288374424,
            -0.01458044908940792,
            -0.044818103313446045,
            0.019525818526744843,
            -0.00966066587716341,
            -0.004980459809303284,
            -0.02104446105659008,
            -0.016664965078234673,
            0.04116682708263397,
            -0.025833716616034508,
            -0.002929653273895383,
            -0.04744730517268181,
            -0.029273806139826775,
            0.024122286587953568,
            0.01906963810324669,
            0.01192800235003233,
            0.02503339573740959,
            -0.009345274418592453,
            0.0026427030097693205,
            0.045952364802360535,
            0.03154176101088524,
            -0.019510418176651,
            0.011501054279506207,
            0.019384928047657013,
            0.0178570244461298,
            -0.014837485738098621,
            0.023159589618444443,
            -0.0316968709230423,
            -0.02132326550781727,
            -0.02287120744585991,
            0.004302863497287035,
            -0.005043668206781149,
            -0.032871562987565994,
            -0.018125606700778008,
            0.030129162594676018,
            -0.018667109310626984,
            -0.023352619260549545,
            0.03851084038615227,
            -0.058962590992450714,
            -0.003310046624392271,
            0.014870481565594673,
            0.006727057509124279,
            -0.01390587817877531,
            -0.020752040669322014,
            0.028604656457901,
            -0.015084796585142612,
            -0.013400874100625515,
            0.00992062408477068,
            -0.014773414470255375,
            -0.042143721133470535,
            0.02531621791422367,
            0.034882280975580215,
            0.006392444018274546,
            0.0031608196441084146,
            -0.0070101069286465645,
            0.018715908750891685,
            0.026575695723295212,
            0.011672938242554665,
            -0.023112989962100983,
            -0.025875987485051155,
            -0.029835805296897888,
            0.02341979555785656,
            -0.006745328661054373,
            -0.03091033361852169,
            -0.006747279781848192,
            -0.0016750767827033997,
            -0.027575289830565453,
            0.017137140035629272,
            -0.006679806392639875,
            -0.001047958037815988,
            0.017804093658924103,
            0.010386158712208271,
            -0.004388096276670694,
            -0.011646735481917858,
            -0.03153442218899727,
            -0.012199360877275467,
            0.011019610799849033,
            -0.022619379684329033,
            0.011557935737073421,
            -0.028261004015803337,
            -0.011204860173165798,
            0.005991801153868437,
            -0.007043565157800913,
            -0.018456967547535896,
            -0.009346920996904373,
            0.009823394939303398,
            0.023948926478624344,
            0.013656636700034142,
            0.014199973084032536,
            0.015569397248327732,
            -0.040350381284952164,
            0.0049962932243943214,
            -0.009854326024651527,
            0.006258064415305853,
            -0.00203384249471128,
            0.038557082414627075,
            -0.0197531059384346,
            0.0020322520285844803,
            -0.011306749656796455,
            -0.017591485753655434,
            -0.01800418086349964,
            0.024945460259914398,
            0.0021657373290508986,
            0.017527107149362564,
            0.004163467790931463,
            0.05073027312755585,
            -0.030034231022000313,
            -0.024615446105599403,
            -0.043763384222984314,
            -0.0061053927056491375,
            0.00919723603874445,
            -0.03619331493973732,
            0.01697726733982563,
            -0.03193075209856033,
            0.000323839281918481,
            -0.032689791172742844,
            0.0011439654044806957,
            0.009012465365231037,
            -0.008684752508997917,
            0.03393258899450302,
            -0.025306180119514465,
            -0.014651449397206306,
            0.0013137941714376211,
            0.0183725506067276,
            0.011972906067967415,
            -0.001357721514068544,
            -0.001856768736615777,
            -0.007439232897013426,
            -0.012334117665886879,
            0.008410200476646423,
            -0.020971110090613365,
            -0.004103632643818855,
            0.021587930619716644,
            -0.009466399438679218,
            0.011573883704841137,
            0.02686126157641411,
            -0.02752630040049553,
            0.0359363928437233,
            0.01896900124847889,
            -0.0021143388003110886,
            -0.013697011396288872,
            -0.007353946566581726,
            -0.03270614892244339,
            -0.003425805363804102,
            -0.02663462981581688,
            -0.015840472653508186,
            0.03942641243338585,
            0.015181432478129864,
            -0.029588356614112854,
            0.029616404324769974,
            0.03405449166893959,
            -0.010332283563911915,
            -0.008609161712229252,
            -0.0169056486338377,
            -0.02012348175048828,
            0.0030618414748460054,
            -0.06253363937139511,
            -0.002010408556088805,
            0.009278065524995327,
            -0.0015393588691949844,
            0.03277056664228439,
            -0.0049609532579779625,
            0.01174522377550602,
            0.015805372968316078,
            -0.021384039893746376,
            0.012157758697867393,
            0.018691983073949814,
            0.0003966407384723425,
            0.007157017011195421,
            -0.01147749274969101,
            -0.020876655355095863,
            0.008731384761631489,
            0.02656898833811283,
            -0.018209297209978104,
            0.0031495431903749704,
            -0.011789960786700249,
            -0.01782619021832943,
            -0.0023493627086281776,
            0.01618795469403267,
            -0.004142973572015762,
            0.02128385193645954,
            0.013287846930325031,
            -0.008874665014445782,
            -0.03022683411836624,
            0.005326003767549992,
            0.02634856291115284,
            -0.03131522610783577,
            -0.010737168602645397,
            0.016842318698763847,
            -0.004290636163204908,
            -0.012106269598007202,
            0.013545741327106953,
            0.01653069630265236,
            -0.009248083457350731,
            -0.006954974960535765,
            -0.020572684705257416,
            -0.025485465303063393,
            -0.016318783164024353,
            0.00896817073225975,
            0.01443967130035162,
            -0.023647073656320572,
            0.023109592497348785,
            0.017772657796740532,
            -0.017972664907574654,
            -0.02279428020119667,
            0.008126849308609962,
            -0.03623538464307785,
            0.007274335715919733,
            0.017885174602270126,
            0.01669282279908657,
            -0.03231509402394295,
            -0.020122535526752472,
            -0.031193366274237633,
            0.012955674901604652,
            0.008627650327980518,
            -0.02523508481681347,
            0.0017710741376504302,
            0.026370782405138016,
            0.0020968113094568253,
            0.027464453130960464,
            0.012650410644710064,
            0.003905088873580098,
            0.027653081342577934,
            -0.00752468965947628,
            0.030723735690116882,
            0.004854969214648008,
            -0.01083158329129219,
            0.004145487677305937,
            -0.010168894194066525,
            -0.008150055073201656,
            -0.0034335190430283546,
            -0.015017776750028133,
            0.019817223772406578,
            -0.010605824179947376,
            -0.013280173763632774,
            -0.02120979316532612,
            -0.016355060040950775,
            -0.01991911232471466,
            0.039341431111097336,
            0.009286370128393173,
            -0.04402151703834534,
            -0.022146552801132202,
            -0.014787768945097923,
            0.023789478465914726,
            0.021867401897907257,
            -0.02108815126121044,
            0.0018476004479452968,
            -0.0052082412876188755,
            -0.00897949654608965,
            -0.04038035497069359,
            -0.01898951083421707,
            -0.016216065734624863,
            -0.017629696056246758,
            0.018896115943789482,
            0.0016782404854893684,
            -0.02068290114402771,
            0.007215053774416447,
            0.027792688459157944,
            -0.021031910553574562,
            0.025007786229252815,
            -0.013293449766933918,
            -0.025579100474715233,
            0.026140449568629265,
            -0.010652429424226284,
            -0.0036078759003430605,
            -0.009737447835505009,
            0.06353618949651718,
            -0.03424200415611267,
            -0.010102792643010616,
            -0.0023197135888040066,
            -0.004250617232173681,
            -0.010066449642181396,
            0.008635911159217358,
            0.02298954501748085,
            0.0008596028201282024,
            -0.005687994416803122,
            0.005685369949787855,
            -0.029413195326924324,
            0.03690553829073906,
            0.019268393516540527,
            -0.007373269647359848,
            -0.008559643290936947,
            0.0018956237472593784,
            -0.0079798037186265,
            -0.02436797134578228,
            0.020263081416487694,
            -0.0601259209215641,
            0.034763436764478683,
            0.03681435436010361,
            0.025805076584219933,
            0.027416352182626724,
            -0.027082864195108414,
            0.00768324313685298,
            -0.014422224834561348,
            -0.023000111803412437,
            0.03096967376768589,
            0.02261282689869404,
            0.0045613194815814495,
            -0.008258115500211716,
            0.014811787754297256,
            0.01086342427879572,
            0.02481558546423912,
            -0.043719567358493805,
            0.021923042833805084,
            0.013883096165955067,
            0.005193834193050861,
            0.031738005578517914,
            0.012753318063914776,
            -0.01364554837346077,
            -0.008219221606850624,
            -0.027932817116379738,
            -0.005648230202496052,
            -0.009747412987053394,
            -0.022266587242484093,
            -0.0013042438076809049,
            0.005413723178207874,
            -0.016838638111948967,
            0.014212405309081078,
            -0.006767880637198687,
            -0.021466786041855812,
            0.01207228284329176,
            0.00875813513994217,
            0.009966807439923286,
            -0.02592889592051506,
            0.019634123891592026,
            0.04264786094427109,
            -0.039932604879140854,
            -0.04285131394863129,
            0.0008816171903163195,
            -0.002956488635390997,
            0.007718612905591726,
            0.038534924387931824,
            0.024726228788495064,
            0.04013338312506676,
            0.03125062584877014,
            -0.01917213201522827,
            0.007804520428180695,
            0.009831746108829975,
            -0.00849344301968813,
            -0.0386769138276577,
            0.026426896452903748,
            -0.0027327174320816994,
            0.04538654163479805,
            -0.0023610698990523815,
            -0.034339092671871185,
            -0.004693519324064255,
            -0.02042338065803051,
            0.02450243942439556,
            0.03272015228867531,
            0.03189648687839508,
            -0.0021834324579685926,
            0.018723228946328163,
            -0.01454243715852499,
            -0.010236121714115143,
            -0.0013735243119299412,
            0.0001922961528180167,
            0.012570672668516636,
            0.013897932134568691,
            -0.02771241031587124,
            0.016283495351672173,
            0.03065798245370388,
            0.028997570276260376,
            -0.020526310428977013,
            -0.011967362836003304,
            0.00647349888458848,
            -0.00928674079477787,
            0.010413307696580887,
            0.02050595171749592,
            -0.01774522289633751,
            -0.01690191961824894,
            -0.0005736639141105115,
            0.03143879771232605,
            -0.013292851857841015,
            -0.0049326494336128235,
            -0.021623115986585617,
            0.012987462803721428,
            0.01906801573932171,
            -0.004649202339351177,
            0.02472846768796444,
            -0.02487913891673088,
            0.006399219855666161,
            0.030700767412781715,
            -0.02570592425763607,
            -0.023356767371296883,
            0.02240595780313015,
            0.010295972228050232,
            -0.013111509382724762,
            0.00794396735727787,
            -0.01159336231648922,
            0.039464425295591354,
            -0.01929488405585289,
            -0.00913532916456461,
            -0.014610804617404938,
            0.0012512356042861938,
            0.008299684152007103,
            -0.01073386613279581,
            0.006007731426507235,
            0.01617373898625374,
            -0.006029087584465742,
            -0.0024830810725688934,
            0.01519025955349207,
            -0.012480996549129486,
            0.004851390141993761,
            0.01969168893992901,
            0.016106855124235153,
            0.01983571983873844,
            -0.004928817041218281,
            0.015245026908814907,
            0.02617657743394375,
            -0.05692737549543381,
            0.018569517880678177,
            -0.03037562407553196,
            -0.026032475754618645,
            0.014264965429902077,
            -0.0187105480581522,
            0.012551968917250633,
            -0.01812683418393135,
            -0.018029194325208664,
            -0.030259577557444572,
            0.01709437184035778,
            -0.027016783133149147,
            0.005440151784569025,
            -0.007879932411015034,
            -0.03802838549017906,
            -0.012264453805983067,
            0.027961362153291702,
            0.0032612590584903955,
            0.011322032660245895,
            0.01787133514881134,
            0.03956121206283569,
            -0.02406163327395916,
            -0.012758955359458923,
            0.027462845668196678,
            0.015128637664020061,
            0.020586417987942696,
            0.008661453612148762,
            -0.030369240790605545,
            -0.0042798141948878765,
            0.012045838870108128,
            -0.001115592080168426,
            0.016609450802206993,
            0.011438255198299885,
            0.006296225823462009,
            -0.03154914826154709,
            0.019336000084877014,
            -0.001075688749551773,
            -0.04863330349326134,
            0.006165685597807169,
            0.00990033894777298,
            0.007844165898859501,
            0.033886704593896866,
            -0.007489069364964962,
            -0.019111739471554756,
            0.027710506692528725,
            0.006891910452395678,
            0.016374051570892334,
            0.0043718633241951466,
            -0.014220531098544598,
            -0.0053956275805830956,
            -0.019408611580729485,
            -0.015086926519870758,
            0.003354705171659589,
            0.002092663198709488,
            0.013419720344245434,
            -0.03158659115433693,
            -0.02207328751683235,
            0.027327878400683403,
            0.018189359456300735,
            0.021499089896678925,
            0.0058891563676297665,
            -0.010162447579205036,
            -0.01805509254336357,
            -0.020980335772037506,
            -0.007354616187512875,
            -0.002442506840452552,
            0.014327921904623508,
            -0.006551452446728945,
            0.033740270882844925,
            0.012260805815458298,
            0.02349521592259407,
            -0.004116517025977373,
            0.007994160056114197,
            -0.009589833207428455,
            -0.030124902725219727,
            -0.02497425675392151,
            -0.023773502558469772,
            -0.03381530940532684,
            -0.008633580058813095,
            0.007162300404161215,
            -0.03603511303663254,
            0.02453731559216976,
            -0.01801123470067978,
            -0.05659128725528717,
            -0.027816595509648323,
            0.028029631823301315,
            -0.0028731105849146843,
            0.02127581089735031,
            -0.028036702424287796,
            -0.008592612110078335,
            -0.004893883131444454,
            -0.008585069328546524,
            0.016389569267630577,
            -0.0025385578628629446,
            -0.01642751321196556,
            0.00896141491830349,
            -0.0038711647503077984,
            0.006892940495163202,
            -0.0024971317034214735,
            -0.026682697236537933,
            -0.007083226460963488,
            0.027961477637290955,
            0.00735748466104269,
            -0.015431893058121204,
            0.012086405418813229,
            0.004092581104487181,
            -0.012681144289672375,
            -0.039976101368665695,
            0.01111616101115942,
            -0.019788464531302452,
            0.011162715032696724,
            -0.019222576171159744,
            -0.005877892021089792,
            0.005785715766251087,
            -0.022023284807801247,
            -0.006118705961853266,
            0.021815011277794838,
            0.0035260531585663557,
            -0.01981753669679165,
            -0.0008861027890816331,
            -0.0009109913953579962,
            -0.017950957641005516,
            -0.007996201515197754,
            -0.0202683936804533,
            -0.00610279431566596,
            -0.009800095111131668,
            0.02406199276447296,
            0.05206309258937836,
            -0.018659206107258797,
            -0.02623273991048336,
            0.0265791155397892,
            -0.025202888995409012,
            -0.01485230028629303,
            0.0019272068748250604,
            -0.025025000795722008,
            -0.017794540151953697,
            0.021748898550868034,
            0.01671198196709156,
            0.010232025757431984,
            0.0038950250018388033,
            -0.02266419492661953,
            -0.03344219923019409,
            0.0034194940235465765,
            0.03035644255578518,
            0.00550528010353446,
            0.004986507818102837,
            0.017619801685214043,
            0.01043520774692297,
            0.02169189602136612,
            0.01231906097382307,
            0.03130600228905678,
            -0.008150987327098846,
            0.018931349739432335,
            0.03932783380150795,
            -0.05280652269721031,
            0.009430207312107086,
            -0.009866314008831978,
            0.0058229356072843075,
            -0.022512665018439293,
            -0.008746672421693802,
            0.03341330215334892,
            0.02858918532729149,
            -0.007760130800306797,
            0.03276347368955612,
            0.020878296345472336,
            0.012435573153197765,
            -0.032096777111291885,
            0.01949250139296055,
            0.014820819720625877,
            0.012203603982925415,
            -0.01654820889234543,
            -0.021999984979629517,
            -0.008681469596922398,
            0.020714137703180313,
            -0.020579399541020393,
            -0.03275727480649948,
            -0.017906082794070244,
            0.011958817951381207,
            0.043697550892829895,
            0.01380870956927538,
            0.003210311057046056,
            0.010488181374967098,
            0.014334183186292648,
            -0.02004360221326351,
            -0.03128477558493614,
            0.027855025604367256,
            -0.0168739166110754,
            -0.006944287568330765,
            0.0010726493783295155,
            0.00500139407813549,
            -0.029793649911880493,
            -0.0034523229114711285,
            0.022822707891464233,
            -0.03991395980119705,
            0.021864237263798714,
            0.010643665678799152,
            0.010759841650724411,
            -0.007418591529130936,
            -0.003566169645637274,
            0.014501703903079033,
            -0.005596378352493048,
            0.014264949597418308,
            -0.022736836224794388,
            0.013167690485715866,
            0.008921632543206215,
            0.023191330954432487,
            -0.006381669081747532,
            -0.00681278808042407,
            0.012149987742304802,
            -0.04523160308599472,
            0.000830487348139286,
            0.03455645218491554,
            0.018046177923679352,
            0.0011650444939732552,
            -0.05852220207452774,
            -0.020828237757086754,
            -0.0003939335292670876,
            0.01709163933992386,
            -0.014425285160541534,
            0.02101249247789383,
            -0.023705899715423584,
            0.005785144865512848,
            0.031054969877004623,
            0.03655392304062843,
            -0.019070463255047798,
            -0.016573240980505943,
            0.0001882326469058171,
            -0.0193200521171093,
            0.0023140343837440014,
            0.0067937057465314865,
            -0.0064057717099785805,
            0.03107197768986225,
            -0.024197766557335854,
            -0.00566214369609952,
            -0.008196298032999039,
            -0.0011958315735682845,
            0.019709672778844833,
            0.003849664004519582,
            0.019781015813350677,
            -0.015724238008260727,
            0.007005784660577774,
            0.028806034475564957,
            0.03334107622504234,
            0.010252842679619789,
            0.025303110480308533,
            -0.01378300879150629,
            -0.024426935240626335,
            -0.003916825633496046,
            0.020784802734851837,
            -0.010103151202201843,
            -0.02839498408138752,
            0.029144881293177605,
            0.01674056611955166,
            0.02420816756784916,
            0.03855743631720543,
            -0.004857533611357212,
            -0.01768302544951439,
            -0.009763558395206928,
            -0.037630196660757065,
            -0.018122708424925804,
            -0.01196077186614275,
            -0.01994449831545353,
            -0.023047499358654022,
            -0.010283301584422588,
            -0.015419453382492065,
            0.024421129375696182,
            -0.026169192045927048,
            0.0022168229334056377,
            -0.006074319127947092,
            -0.03424932435154915,
            -0.007261012680828571,
            -0.04517851769924164,
            0.0015448261983692646,
            0.02790379710495472,
            0.001244131475687027,
            -0.023378901183605194,
            -0.015431314706802368,
            0.01388417836278677,
            0.005640763323754072,
            0.001341325230896473,
            0.019079050049185753,
            0.0038541480898857117,
            0.023472938686609268,
            0.0015001862775534391,
            -0.012000173330307007,
            0.03511323779821396,
            -0.023198550567030907,
            0.010840464383363724,
            -0.015107830986380577,
            -0.003329442348331213,
            -0.0343167707324028,
            -0.01382070779800415,
            -0.020085029304027557,
            0.003661450929939747,
            -0.017795737832784653,
            -0.019792933017015457,
            0.021721160039305687,
            0.009375504218041897,
            -0.016203340142965317,
            -0.010671621188521385,
            -0.02526245266199112,
            -0.024922700598835945,
            0.02590886689722538,
            -0.02157362923026085,
            0.02399146556854248,
            0.014368203468620777,
            -0.00421439204365015,
            -0.024796662852168083,
            -0.029487811028957367,
            0.027938876301050186,
            0.03547816351056099,
            0.0086733503267169,
            0.00813009962439537,
            0.008719677105545998,
            -0.039023302495479584,
            -0.02782399021089077,
            -0.028483225032687187,
            0.014009438455104828,
            -0.03229479119181633,
            0.027337484061717987,
            0.0020146386232227087,
            0.006103724241256714,
            -0.007270887028425932,
            0.02811598964035511,
            -0.03800484910607338,
            0.008552602492272854,
            0.007859929464757442,
            0.025719700381159782,
            -0.029856529086828232,
            -0.049266207963228226,
            -0.01644056662917137,
            -0.009761505760252476,
            -0.01640358753502369,
            -0.005204788409173489,
            0.009047857485711575,
            -0.018677134066820145,
            -0.018743202090263367,
            0.0030803948175162077,
            -0.007612066809087992,
            -0.022058647125959396,
            -0.00683171208947897,
            -0.02764127217233181,
            -0.0013579231454059482,
            -0.012376287020742893,
            0.010049542412161827,
            -0.006713590119034052,
            -0.00454058637842536,
            0.00010227255552308634,
            -0.026793936267495155,
            -0.01160826813429594,
            0.002739549148827791,
            -0.04716821759939194,
            0.030249157920479774,
            0.014254548586905003,
            0.008442750200629234,
            -0.023454109206795692,
            -0.02656991221010685,
            -0.013019473291933537,
            0.01300573255866766,
            0.03624406456947327,
            0.028772952035069466,
            0.00458725169301033,
            -0.01430144626647234,
            -0.025681868195533752,
            0.029389554634690285,
            0.003906743135303259,
            0.0063823796808719635,
            0.020836755633354187,
            0.03289632871747017,
            0.027863631024956703,
            -0.015000327490270138,
            0.016314338892698288,
            0.010866742581129074,
            0.0007508274866268039,
            0.006809062324464321,
            0.016307620331645012,
            0.014491443522274494,
            -0.010824037715792656,
            -0.021579395979642868,
            -0.0038836151361465454,
            -0.007531596813350916,
            -0.0044100163504481316,
            0.01285084430128336,
            -0.02160181850194931,
            -0.03760434314608574,
            -0.01621471904218197,
            0.030322300270199776,
            -0.020489249378442764,
            -0.010041791014373302,
            -0.03082476370036602,
            0.018341343849897385,
            -0.0030249138362705708,
            0.0039635770954191685,
            -0.00598877901211381,
            -0.01030659768730402,
            0.04042153060436249,
            0.014672503806650639,
            0.023347603157162666,
            -0.013284273445606232,
            -0.03544596582651138,
            -0.05016220360994339,
            -0.0027419207617640495,
            0.018136484548449516,
            -0.017210498452186584,
            -0.007297561503946781,
            0.010468053631484509,
            -0.005817587953060865,
            0.0005707375821657479,
            0.009792550466954708,
            0.027826499193906784,
            -0.03973939269781113,
            -0.028779231011867523,
            0.020060932263731956,
            0.02483784593641758,
            -0.0066994610242545605,
            0.002397867850959301,
            0.02624725177884102,
            0.023137276992201805,
            0.020914342254400253,
            -0.05141878500580788,
            -0.004702345468103886,
            -0.0038547683507204056,
            0.03213406726717949,
            0.0066127232275903225,
            -0.019427796825766563,
            0.01750788278877735,
            -0.00598727073520422,
            0.013640257529914379,
            -0.005855035036802292,
            0.0246929582208395,
            -0.011535459198057652,
            0.013340020552277565,
            0.02726948820054531,
            -0.028061822056770325,
            0.012590928003191948,
            -0.0007323548779822886,
            -0.015938162803649902,
            -0.0057758125476539135,
            -0.011980254203081131,
            0.02744443714618683,
            0.02499847672879696,
            0.0016194897470995784,
            0.025790153071284294,
            0.02153603732585907,
            -0.057961273938417435,
            -0.003397422842681408,
            0.02054058387875557,
            0.018716255202889442,
            0.03412516042590141,
            -0.026289137080311775,
            0.035677600651979446,
            -0.007788099814206362,
            -0.009075873531401157,
            -0.03198428079485893,
            0.02634243480861187,
            -0.008466258645057678,
            -0.02562486007809639,
            0.013890845701098442,
            0.08054448664188385,
            0.02695760317146778,
            -0.005213343072682619
        ],
        [
            0.004368716850876808,
            -0.04358380660414696,
            -0.02267109975218773,
            0.0006505178171209991,
            -0.020467208698391914,
            -0.012467358261346817,
            9.330698958365247e-05,
            -0.022751083597540855,
            -0.03765455633401871,
            0.01762222684919834,
            0.013313984498381615,
            0.01048828475177288,
            0.036680374294519424,
            -0.03110239841043949,
            0.001666411990299821,
            -0.0393301285803318,
            -0.007867755368351936,
            -0.018676679581403732,
            0.010501278564333916,
            0.0061547597870230675,
            0.019851425662636757,
            0.0009245002875104547,
            0.017966199666261673,
            -0.010426611639559269,
            -0.0036170294042676687,
            0.028585202991962433,
            0.03106766566634178,
            -0.017189104110002518,
            -0.02412855066359043,
            -0.01383998803794384,
            -0.03411710262298584,
            -0.009290535002946854,
            0.029086653143167496,
            -0.02303834818303585,
            0.03474881872534752,
            -0.027502071112394333,
            -0.002319691702723503,
            -0.004950257483869791,
            0.02656983584165573,
            -0.018190568313002586,
            -0.01129502709954977,
            -0.015731869265437126,
            -0.001960637280717492,
            0.009339578449726105,
            -0.0072110616602003574,
            0.019878773018717766,
            0.02565796673297882,
            0.009848019108176231,
            -0.010941381566226482,
            -0.016573812812566757,
            0.012931806035339832,
            0.008437751792371273,
            0.020069405436515808,
            0.026797257363796234,
            -0.01788794808089733,
            -0.0013334713876247406,
            -0.037425462156534195,
            0.03584969788789749,
            -0.016595320776104927,
            -0.0222699623554945,
            0.035866305232048035,
            -0.00878065638244152,
            0.017194341868162155,
            -0.004683551844209433,
            0.029694540426135063,
            0.008477497845888138,
            0.006640622392296791,
            -0.01447251532226801,
            0.022556882351636887,
            0.005901322700083256,
            0.015026898123323917,
            0.003125934163108468,
            0.016638385131955147,
            -0.0016799416625872254,
            0.0342128649353981,
            0.018659289926290512,
            -0.005972004495561123,
            -0.01618547923862934,
            0.024010175839066505,
            0.028727972880005836,
            0.03677047789096832,
            0.024747641757130623,
            0.030628345906734467,
            0.02821826934814453,
            0.034991368651390076,
            0.008838729932904243,
            0.019937073811888695,
            -0.01587737537920475,
            0.024083539843559265,
            -0.029047850519418716,
            0.027561459690332413,
            -0.006321866065263748,
            -0.03767142444849014,
            0.008018258027732372,
            -0.02518915943801403,
            -0.01669754646718502,
            -0.028574563562870026,
            0.013171826489269733,
            0.07198517769575119,
            0.02601531520485878,
            0.01396072655916214,
            -0.019189579412341118,
            -0.03710386902093887,
            -0.008645478636026382,
            -0.01463386695832014,
            -0.01004023663699627,
            0.03248998522758484,
            -0.019121425226330757,
            -0.018132368102669716,
            0.032041192054748535,
            -0.023444782942533493,
            0.02016039378941059,
            -0.01758539490401745,
            -0.004347497597336769,
            0.03424772620201111,
            0.014601781964302063,
            0.01949038729071617,
            -0.013649742119014263,
            -0.03716560825705528,
            -0.03228189796209335,
            -0.014428726397454739,
            0.016238782554864883,
            -0.022080091759562492,
            0.0008750549168325961,
            0.016343655064702034,
            0.03035767935216427,
            -0.022607561200857162,
            -0.027719197794795036,
            0.004990862682461739,
            0.01341249980032444,
            0.037468574941158295,
            -0.029199985787272453,
            -0.0029258655849844217,
            0.014765862375497818,
            -0.016756650060415268,
            -0.019860198721289635,
            -0.006035459693521261,
            0.010778401046991348,
            0.019687151536345482,
            0.03177856653928757,
            0.014396706596016884,
            0.004303379915654659,
            -0.03201485797762871,
            0.03165373578667641,
            -0.019697047770023346,
            -0.009901979006826878,
            -0.02171359769999981,
            -0.007501775864511728,
            -0.037152018398046494,
            0.014486626721918583,
            -0.0062741925939917564,
            -0.024882152676582336,
            -0.03233807533979416,
            0.020906561985611916,
            -0.014311759732663631,
            -0.014177050441503525,
            -0.04794519022107124,
            0.010020012967288494,
            0.008451440371572971,
            -0.03836960345506668,
            0.0025158340577036142,
            0.019825469702482224,
            0.013986471109092236,
            -0.023265009745955467,
            -0.015563730150461197,
            -0.03827076032757759,
            -0.006696108262985945,
            0.019354810938239098,
            0.048780594021081924,
            0.006051765754818916,
            0.01954427734017372,
            0.01664675772190094,
            0.0124944057315588,
            0.04064790531992912,
            -0.021556636318564415,
            -0.00940217450261116,
            -0.03661280497908592,
            -0.009651555679738522,
            -0.059766486287117004,
            0.010919520631432533,
            -0.025387411937117577,
            0.0313606932759285,
            -0.009219770319759846,
            -0.025139354169368744,
            0.000385162653401494,
            0.013522539287805557,
            -0.00208409340120852,
            0.0074611022137105465,
            0.017408566549420357,
            -0.021050825715065002,
            -0.010564712807536125,
            0.011229992844164371,
            -0.03166884928941727,
            -0.02246902510523796,
            0.01380430068820715,
            -0.0071061523631215096,
            -0.03208862990140915,
            0.007148734759539366,
            -0.029258208349347115,
            -0.025088537484407425,
            -0.016210146248340607,
            0.035523317754268646,
            0.026156365871429443,
            -0.012922205962240696,
            -0.016942426562309265,
            0.029284650459885597,
            0.021093444898724556,
            -0.006857824511826038,
            -0.012406006455421448,
            0.008070659823715687,
            -0.039844293147325516,
            0.030025918036699295,
            -0.030731530860066414,
            -0.0018298807553946972,
            -0.01823064498603344,
            -0.02876828983426094,
            0.0226846095174551,
            0.0017425462137907743,
            0.04119143262505531,
            -0.0037947380915284157,
            0.02025180123746395,
            -5.6851436966098845e-05,
            -0.004956196062266827,
            -0.007641830947250128,
            0.03402283042669296,
            -0.00587171595543623,
            0.027965404093265533,
            0.024977214634418488,
            -0.004556569270789623,
            0.008578363806009293,
            0.011621527373790741,
            -0.013122829608619213,
            0.007939466275274754,
            -0.024204116314649582,
            -0.013513857498764992,
            -0.011118168011307716,
            -0.044075626879930496,
            0.015178407542407513,
            -0.02183227613568306,
            -0.005730798002332449,
            0.01142616756260395,
            -0.01662581041455269,
            -0.03776027634739876,
            0.018712718039751053,
            0.002608372364193201,
            -0.03333728387951851,
            -0.015687331557273865,
            0.025996588170528412,
            -0.0022368617355823517,
            -0.0036109574139118195,
            0.010979760438203812,
            0.0030202078633010387,
            0.019634872674942017,
            -0.013305677101016045,
            -0.013022595085203648,
            -0.010106148198246956,
            0.023758333176374435,
            0.02193571999669075,
            0.028089191764593124,
            0.008382368832826614,
            0.026338517665863037,
            -0.025914084166288376,
            -0.0072397529147565365,
            -0.026507433503866196,
            -0.009240260347723961,
            -0.015444939024746418,
            -0.012343022972345352,
            -0.012464541010558605,
            -0.013281593099236488,
            0.0281701497733593,
            0.0078063649125397205,
            -0.028526831418275833,
            0.03207108750939369,
            -0.011870642192661762,
            -0.014050642028450966,
            -0.031145544722676277,
            -0.024669768288731575,
            -0.005519500467926264,
            0.02629471942782402,
            0.007351726293563843,
            0.0314025916159153,
            0.018610714003443718,
            -0.007490635383874178,
            -0.00219543045386672,
            0.01624501682817936,
            0.009933684021234512,
            0.03332734480500221,
            0.015738241374492645,
            -0.023811867460608482,
            0.018935328349471092,
            0.023039115592837334,
            -0.02463928610086441,
            0.015816152095794678,
            -0.03395545110106468,
            -0.02981102652847767,
            -0.012157818302512169,
            -0.030206814408302307,
            0.03192148730158806,
            0.027169974520802498,
            -0.002794944914057851,
            -0.0062851496040821075,
            -0.009566236287355423,
            0.01770688407123089,
            0.0005560735007748008,
            -0.020757699385285378,
            0.010446080937981606,
            -0.003541484707966447,
            0.026690194383263588,
            0.01974848471581936,
            0.010648264549672604,
            -1.6355272236978635e-05,
            -0.009590044617652893,
            0.008077749982476234,
            0.013121277093887329,
            0.014892805367708206,
            0.013870138674974442,
            -0.0330706462264061,
            0.02336842752993107,
            0.009378643706440926,
            0.018321799114346504,
            -0.0015419058036059141,
            -0.013057760894298553,
            0.011172615922987461,
            -0.0503266379237175,
            0.022702399641275406,
            0.01335215661674738,
            -0.03172886371612549,
            -0.0013562102103605866,
            -0.02147676981985569,
            0.018687613308429718,
            -0.0035212617367506027,
            -0.010297022759914398,
            -2.709830914682243e-05,
            0.027036381885409355,
            0.0012259167851880193,
            -0.050208527594804764,
            -0.0002079070545732975,
            0.02254994586110115,
            0.02317841537296772,
            -0.001999996369704604,
            0.024218657985329628,
            -0.03437710180878639,
            -0.02337409183382988,
            0.014942657202482224,
            0.025771984830498695,
            0.015805121511220932,
            0.007061546668410301,
            0.016911476850509644,
            -0.011498839594423771,
            -0.006998190190643072,
            0.02086806297302246,
            -0.0029826790560036898,
            -0.029069771990180016,
            0.03871441259980202,
            -0.03569204732775688,
            -0.010789990425109863,
            0.020089030265808105,
            0.024107256904244423,
            0.039409294724464417,
            -0.012666979804635048,
            0.004627002868801355,
            0.030062854290008545,
            0.051144666969776154,
            -0.026159202679991722,
            -0.02151467278599739,
            0.020495576784014702,
            -0.037929240614175797,
            -0.004521889146417379,
            0.03120334818959236,
            -0.02642684057354927,
            0.01937142200767994,
            -0.02266790345311165,
            -0.008024928160011768,
            0.006587942596524954,
            -0.012549920938909054,
            -0.017793476581573486,
            -0.030755385756492615,
            -0.009344743564724922,
            -0.02465500682592392,
            0.029512109234929085,
            0.014313421212136745,
            0.012375307269394398,
            -0.02158644050359726,
            0.023107033222913742,
            0.017846936360001564,
            0.005220420658588409,
            -0.029863901436328888,
            -0.00888100080192089,
            0.04830632731318474,
            -0.012905845418572426,
            0.012794316746294498,
            -0.013251207768917084,
            -0.024230824783444405,
            0.013188308104872704,
            -0.01571843959391117,
            0.028833243995904922,
            -0.013712591491639614,
            0.001164291868917644,
            -0.0031940415501594543,
            0.005073789041489363,
            -0.05039350315928459,
            0.017066679894924164,
            0.010941523127257824,
            0.0043761469423770905,
            0.011455647647380829,
            0.014978726394474506,
            0.021298421546816826,
            0.02498478628695011,
            -0.0006621829234063625,
            0.014709552749991417,
            -0.017280714586377144,
            0.02932915836572647,
            0.04102252796292305,
            0.023297714069485664,
            -0.03078291192650795,
            0.007178788539022207,
            -0.022459840402007103,
            -0.015335659496486187,
            -0.006343481130897999,
            0.013574685901403427,
            -0.012222252786159515,
            -0.0009315302013419569,
            0.05193133279681206,
            0.02781667746603489,
            0.012290973216295242,
            -0.02306283265352249,
            -0.01986965350806713,
            -0.0033601033501327038,
            -0.030230490490794182,
            0.019121382385492325,
            -0.020732440054416656,
            0.0019312460208311677,
            0.010557814501225948,
            0.03637652099132538,
            0.026956791058182716,
            0.03863935172557831,
            0.01607409119606018,
            -0.021597405895590782,
            0.02071503736078739,
            0.014367585070431232,
            0.0013550245203077793,
            -0.01785907708108425,
            0.0067433761432766914,
            -0.04307212308049202,
            0.012897110544145107,
            0.014246290549635887,
            0.031950440257787704,
            0.06484469771385193,
            -0.011873498558998108,
            -0.0012602412607520819,
            -0.028770076110959053,
            0.036901429295539856,
            -0.05561603605747223,
            0.01639014109969139,
            -0.028699250891804695,
            0.00938243605196476,
            0.016067279502749443,
            0.002544865244999528,
            0.00919913500547409,
            0.024193523451685905,
            -0.005622878670692444,
            0.01291244849562645,
            0.02401590533554554,
            0.025301700457930565,
            -0.0052964654751122,
            0.020308086648583412,
            -0.02430695667862892,
            -0.03549778833985329,
            0.03363742306828499,
            0.02730395458638668,
            -0.022194642573595047,
            -0.0037394536193460226,
            -0.0164440106600523,
            0.013535630889236927,
            -0.019997334107756615,
            -0.008644351735711098,
            -0.0038852316793054342,
            0.005111674778163433,
            0.0048403008840978146,
            0.04051652550697327,
            -0.0026194429956376553,
            0.03170749545097351,
            -0.03716379404067993,
            -0.015340601094067097,
            0.05994265154004097,
            0.004631798714399338,
            -0.012889796867966652,
            0.006146786734461784,
            -0.07569704949855804,
            -0.045661479234695435,
            0.007756649982184172,
            -0.009692903608083725,
            -0.020945848897099495,
            -0.018388185650110245,
            0.016503874212503433,
            -0.01951184868812561,
            0.006863963790237904,
            -0.0201403945684433,
            -0.011159822344779968,
            0.03299599140882492,
            0.005621087737381458,
            0.026678897440433502,
            0.017795542255043983,
            0.0015901405131444335,
            0.008631936274468899,
            0.021871501579880714,
            0.011677186004817486,
            -0.012862646952271461,
            0.014116509817540646,
            0.030010879039764404,
            -0.017658958211541176,
            0.0063957939855754375,
            0.02122161164879799,
            -0.03829632326960564,
            0.019862759858369827,
            0.01953793689608574,
            0.016441194340586662,
            -0.006431352812796831,
            0.012455876916646957,
            0.02836300991475582,
            -0.016565047204494476,
            -0.012453044764697552,
            -0.014163798652589321,
            -0.03282774239778519,
            0.033969372510910034,
            -0.014717994257807732,
            0.06372520327568054,
            -0.021937565878033638,
            0.010835956782102585,
            0.0017337482422590256,
            -0.027311477810144424,
            -0.002912016585469246,
            0.04026719927787781,
            0.006038095336407423,
            6.343752465909347e-05,
            -0.025484317913651466,
            -0.014540825970470905,
            -0.03397850692272186,
            -0.02450854517519474,
            -0.006239753216505051,
            0.0286511592566967,
            -0.01791386306285858,
            -0.028619540855288506,
            -0.021566199138760567,
            0.027705075219273567,
            -0.0076977405697107315,
            -0.014142004773020744,
            -0.0026154040824621916,
            0.01823895052075386,
            0.006212207954376936,
            -0.007983817718923092,
            -0.014143405482172966,
            0.007713105529546738,
            -0.005241153761744499,
            -0.024734927341341972,
            -0.0010385367786511779,
            -0.013488315045833588,
            -0.005654985550791025,
            -0.02468486875295639,
            0.031150611117482185,
            -0.012916193343698978,
            0.03514361381530762,
            0.016843056306242943,
            0.021115288138389587,
            -0.019748635590076447,
            -0.033416152000427246,
            -0.00321149080991745,
            0.035228829830884933,
            0.021349666640162468,
            0.027699915692210197,
            0.029918497428297997,
            -0.015447924844920635,
            -0.03675896301865578,
            0.022964444011449814,
            0.033517856150865555,
            -0.004650898277759552,
            0.005105030257254839,
            0.014706632122397423,
            -0.036265164613723755,
            0.025425510480999947,
            -0.01412190217524767,
            0.02106918767094612,
            0.00771339563652873,
            -0.018314171582460403,
            -0.0223553404211998,
            -0.010134552605450153,
            0.014075234532356262,
            0.024203384295105934,
            0.024973945692181587,
            0.0017675284761935472,
            0.028962571173906326,
            -0.03585673123598099,
            0.02741152048110962,
            -0.026405388489365578,
            0.007436327636241913,
            0.0018200562335550785,
            -0.027620255947113037,
            0.00758366147056222,
            0.004868840333074331,
            -0.01848224736750126,
            -0.010573518462479115,
            -0.01810481771826744,
            -0.0068120453506708145,
            0.022502072155475616,
            -0.026709862053394318,
            0.012301888316869736,
            0.026583200320601463,
            -0.034996163100004196,
            -0.026157647371292114,
            0.016394788399338722,
            -0.0080119539052248,
            -0.01462151762098074,
            0.011729562655091286,
            0.01630520261824131,
            -0.025522321462631226,
            -0.011059756390750408,
            0.009706171229481697,
            0.028656646609306335,
            0.011884678155183792,
            -0.042393531650304794,
            -0.02986200712621212,
            -0.03213122859597206,
            0.026034504175186157,
            -0.015546164475381374,
            0.0011114183580502868,
            0.015280650928616524,
            0.0032757865265011787,
            0.030042005702853203,
            0.006723972503095865,
            0.027149664238095284,
            -0.029171349480748177,
            0.008057049475610256,
            0.0038909304421395063,
            -0.01199711486697197,
            0.023994194343686104,
            0.03090875968337059,
            -0.0031727885361760855,
            -0.020587759092450142,
            0.019220706075429916,
            0.0031470945104956627,
            0.02299957163631916,
            0.023638470098376274,
            -0.0499473512172699,
            0.01582673005759716,
            0.020689258351922035,
            0.0318007655441761,
            -0.00027138684527017176,
            -0.028737829998135567,
            0.00149262766353786,
            -0.02489885874092579,
            0.01400089543312788,
            0.027685042470693588,
            0.004100417718291283,
            0.008092700503766537,
            -0.006686339154839516,
            0.00569173926487565,
            0.030556414276361465,
            -0.029742281883955002,
            -0.00888811331242323,
            -0.003929409198462963,
            -0.05277182161808014,
            0.030194595456123352,
            0.017362968996167183,
            -0.011442236602306366,
            -0.02076372504234314,
            0.01778082177042961,
            -0.013029944151639938,
            -0.006951501127332449,
            -0.00833397451788187,
            -0.004991899710148573,
            -0.012622238136827946,
            0.0036646798253059387,
            -0.025966975837945938,
            0.027310417965054512,
            -0.028460420668125153,
            -0.010564056225121021,
            0.035812657326459885,
            0.008948237635195255,
            -0.020602280274033546,
            0.0011989299673587084,
            0.04588727653026581,
            0.0020984981674700975,
            0.01900193840265274,
            0.028135403990745544,
            0.013500483706593513,
            0.028829386457800865,
            0.0002388130233157426,
            -0.03171767294406891,
            0.016364824026823044,
            -0.02382553741335869,
            0.043922990560531616,
            0.0422486811876297,
            0.02709648199379444,
            0.03370733931660652,
            0.024223437532782555,
            -0.021527163684368134,
            -0.024953143671154976,
            0.02492116019129753,
            0.025469468906521797,
            0.008230495266616344,
            -0.004447558894753456,
            -0.017939046025276184,
            -0.02763787843286991,
            -0.01095210574567318,
            0.003318208735436201,
            -0.035076968371868134,
            0.020410960540175438,
            -0.016979949548840523,
            -0.031042734161019325,
            0.003777315840125084,
            0.014681548811495304,
            -0.01699734665453434,
            -0.01853305846452713,
            -0.011287208646535873,
            -0.015258300118148327,
            0.011625292710959911,
            -0.03045997954905033,
            -0.018544144928455353,
            -0.0037556879688054323,
            0.0254970770329237,
            0.00024956613196991384,
            -0.025698784738779068,
            -0.0038355558644980192,
            0.010822808369994164,
            0.005971236620098352,
            -0.024031907320022583,
            0.0253432709723711,
            0.009263663552701473,
            -0.012401282787322998,
            -0.014858686365187168,
            0.025684906169772148,
            0.02442295476794243,
            -0.003499268786981702,
            0.02565113827586174,
            -0.015564319677650928,
            0.015064844861626625,
            0.01310267485678196,
            -0.013293767347931862,
            -0.011261647567152977,
            -0.013525646179914474,
            -0.010066970251500607,
            0.02976316399872303,
            0.002234010025858879,
            -0.024768542498350143,
            0.009376661852002144,
            0.009531618095934391,
            0.014035707339644432,
            -0.011042754165828228,
            -0.0008742730715312064,
            0.01314306166023016,
            -0.016428984701633453,
            0.030985886231064796,
            0.03718152269721031,
            0.05672627314925194,
            -0.010766205377876759,
            -0.000842125853523612,
            -0.0063733747228980064,
            -0.010551425628364086,
            0.01643254980444908,
            0.016870925202965736,
            -0.014877830632030964,
            -0.026792122051119804,
            0.021944604814052582,
            0.024389570578932762,
            0.04208691045641899,
            0.01918553002178669,
            0.030297696590423584,
            0.007548035588115454,
            -0.004415287636220455,
            0.006240541115403175,
            -0.00950754527002573,
            -0.03132540360093117,
            -0.011042417958378792,
            -0.004563695285469294,
            0.03202887624502182,
            -0.0054818205535411835,
            0.009187432006001472,
            0.02472652681171894,
            -0.017766190692782402,
            0.01659790612757206,
            0.02572493813931942,
            -0.01945691555738449,
            0.029961295425891876,
            -0.004517936613410711,
            0.0207483172416687,
            -0.030052045360207558,
            -0.0211712047457695,
            0.01380698662251234,
            0.02279706858098507,
            -0.019957566633820534,
            0.032283201813697815,
            -0.021212704479694366,
            0.04942942038178444,
            0.0034737365785986185,
            -0.005258926656097174,
            -0.01720544695854187,
            -0.04819714277982712,
            0.0011769795091822743,
            0.0009523321059532464,
            -0.01868715137243271,
            -0.0306340754032135,
            -0.00615475932136178,
            0.004891131538897753,
            0.0017097597010433674,
            -0.0029136938974261284,
            -0.005717240273952484,
            -0.015137041918933392,
            0.021927809342741966,
            -0.010625059716403484,
            -0.008027426898479462,
            -0.007907019928097725,
            0.037602946162223816,
            -0.013218235224485397,
            -0.029497848823666573,
            0.035109248012304306,
            -0.009452012367546558,
            0.02109462022781372,
            -0.016062302514910698,
            0.0033905573654919863,
            -0.004136350005865097,
            -0.03043496049940586,
            0.015384119935333729,
            -0.011873507872223854,
            0.024904979392886162,
            -0.036813776940107346,
            0.019638683646917343,
            -0.019686495885252953,
            0.03179251402616501,
            -0.02887352555990219,
            -0.004621143452823162,
            0.023847438395023346,
            0.0004613419296219945,
            -0.027115901932120323,
            -0.015386822633445263,
            -0.026920486241579056,
            -0.01135301310569048,
            -0.01593380980193615,
            0.02757158689200878,
            -0.005343246273696423,
            0.02454964630305767,
            0.022562377154827118,
            -0.018597157672047615,
            0.013877786695957184,
            -0.030042817816138268,
            -0.017317600548267365,
            0.026654262095689774,
            0.027138378471136093,
            -0.0009885275503620505,
            -0.004120573867112398,
            -0.003906882368028164,
            -0.025738157331943512,
            -0.007787442300468683,
            0.020782269537448883,
            -0.01440830621868372,
            -0.006141013000160456,
            0.01179859135299921,
            -0.0006151489797048271,
            -0.0031031505204737186,
            0.000991919543594122,
            -0.008223534561693668,
            -0.03823409229516983,
            -0.01528820488601923,
            0.038231536746025085,
            -0.0338749997317791,
            0.0025360649451613426,
            0.028202928602695465,
            -0.004188521299511194,
            0.011954054236412048,
            -0.013685493730008602,
            -0.0076372092589735985,
            0.022168703377246857,
            -0.011549093760550022,
            0.009215557016432285,
            -0.010478903539478779,
            -0.019083771854639053,
            -0.020788557827472687,
            0.022525953128933907,
            0.003050851868465543,
            0.001255155773833394,
            -0.00562015688046813,
            -0.022322669625282288,
            -0.013469288125634193,
            -0.013273662887513638,
            0.01634053885936737,
            0.03199487924575806,
            -0.03655610978603363,
            -0.030657799914479256,
            0.021225417032837868,
            -0.0030397812370210886,
            -0.02372022718191147,
            0.032745763659477234,
            0.02676963619887829,
            0.008876405656337738,
            -0.009411673061549664,
            0.01073816791176796,
            0.03422582894563675,
            -0.005752592347562313,
            0.001868962193839252,
            0.037605345249176025,
            0.02457149140536785,
            -0.0031317889224737883,
            -0.00829504057765007,
            0.014173598960042,
            -0.012539858929812908,
            0.004084705375134945,
            -0.023823898285627365,
            -0.03459150716662407,
            -0.011904511600732803,
            0.02508600987493992,
            0.024905096739530563,
            0.005395101383328438,
            0.021283285692334175,
            0.020126206800341606,
            0.012633386068046093,
            0.036956481635570526,
            0.006002189591526985,
            0.01819607987999916,
            0.007084894459694624,
            -0.00670216791331768,
            0.021399090066552162,
            -0.015045198611915112,
            -0.009702421724796295,
            0.008795736357569695,
            -0.0004678152909036726,
            -0.014407653361558914,
            0.03978990018367767,
            -0.00436332868412137,
            -0.023135073482990265,
            -0.009218459017574787,
            0.0034378638956695795,
            0.023039616644382477,
            0.019017744809389114,
            0.011450407095253468,
            -0.05448571965098381,
            0.02057514153420925,
            -0.0018698597559705377,
            0.005074607208371162,
            -0.011116760782897472,
            0.024101117625832558,
            0.015389710664749146,
            0.03145903721451759,
            -0.0009054242982529104,
            0.0302131250500679,
            0.008136570453643799,
            -0.014589677564799786,
            0.024267638102173805,
            0.0005217587458901107,
            0.00767209567129612,
            -0.013837845996022224,
            -0.012168030254542828,
            0.004468707833439112,
            -0.025604037567973137,
            -0.037625592201948166,
            0.008957265876233578,
            -0.001043960452079773,
            -0.008252539671957493,
            0.005947657395154238,
            -0.031217219308018684,
            -0.03692450374364853,
            0.030186960473656654,
            -0.020071815699338913,
            0.023973818868398666,
            0.005098525434732437,
            -0.003211717586964369,
            -0.007675670552998781,
            -0.01378069631755352,
            -0.04163616523146629,
            -0.023579901084303856,
            0.019476449117064476,
            0.0006509544909931719,
            -0.014861349947750568,
            -0.010317319072782993,
            0.016463100910186768,
            0.029742632061243057,
            -0.00409667706117034,
            0.001215974916703999,
            -0.03382504731416702,
            0.005352744832634926,
            0.00034280779073014855,
            0.013636373914778233,
            -0.018633253872394562,
            0.0035692991223186255,
            0.010512585751712322,
            0.01653454452753067,
            0.001907668192870915,
            0.016573846340179443,
            -0.012747388333082199,
            0.007531970273703337,
            0.00411289231851697,
            -0.011321878992021084,
            -0.01590871252119541,
            -0.0035742498002946377,
            -0.013721588999032974,
            0.018722619861364365,
            0.005093345884233713,
            -0.005420743953436613,
            -0.03693757206201553,
            -0.01051668543368578,
            0.012228485196828842,
            0.029457688331604004,
            0.0252027940005064,
            0.011686773039400578,
            0.015330740250647068,
            -0.029194757342338562,
            -0.01502773817628622,
            0.006317620165646076,
            -0.006783494260162115,
            -0.00404446292668581,
            0.0011799591593444347,
            -0.01297962199896574,
            0.03224994242191315,
            0.0051759472116827965,
            -0.02043381705880165,
            -0.019095754250884056,
            -0.009666169062256813,
            0.03353381156921387,
            0.001844828831963241,
            -0.007609812542796135,
            -0.02361651323735714,
            -0.02493174374103546,
            0.03267219290137291,
            -0.013893929310142994,
            0.04133342206478119,
            0.018409060314297676
        ],
        [
            0.013813750818371773,
            -0.014789893291890621,
            -0.018972057849168777,
            -0.02000633254647255,
            0.01451918575912714,
            -0.007137229200452566,
            -0.021645165979862213,
            0.014195878989994526,
            -0.01836579106748104,
            -0.035328418016433716,
            0.0013466102536767721,
            0.002178746974095702,
            0.01949034258723259,
            -0.03623189404606819,
            -0.029106751084327698,
            0.012613519094884396,
            0.002484682248905301,
            0.007197415456175804,
            -0.035098783671855927,
            0.03560921922326088,
            -0.01461607776582241,
            0.017272859811782837,
            0.007021034136414528,
            -0.02209441177546978,
            -0.026243163272738457,
            -0.0028211919125169516,
            -0.061459172517061234,
            0.022329170256853104,
            -0.014084376394748688,
            0.021502621471881866,
            -0.01782994531095028,
            0.032255157828330994,
            -0.020516425371170044,
            -0.017518535256385803,
            0.024339523166418076,
            -0.029674245044589043,
            -0.015533648431301117,
            -0.023789606988430023,
            -0.003449351293966174,
            0.016025805845856667,
            0.0012881261063739657,
            0.020252233371138573,
            0.013705072924494743,
            -0.014495612122118473,
            -0.006566920783370733,
            0.0003082929761148989,
            0.03800255060195923,
            0.03825211897492409,
            -0.02723856270313263,
            0.02092749997973442,
            -0.02402602508664131,
            0.0132228322327137,
            0.03020777739584446,
            0.0007289568893611431,
            -0.004850949626415968,
            -0.03033672459423542,
            -0.019967209547758102,
            -0.025394273921847343,
            -0.01464793086051941,
            -0.004225363489240408,
            0.0295685026794672,
            0.017993349581956863,
            -0.0009355957736261189,
            -0.010555959306657314,
            0.0292517002671957,
            -0.025444921106100082,
            0.011631081812083721,
            0.00309549318626523,
            -0.004220569971948862,
            0.025265295058488846,
            0.025829525664448738,
            -0.007428301498293877,
            -0.011434964835643768,
            0.0017960225231945515,
            -0.02492450922727585,
            0.06224512681365013,
            -0.016043277457356453,
            -0.02802070789039135,
            -0.020291034132242203,
            0.0003196190227754414,
            -0.015741441398859024,
            -0.010947780683636665,
            -0.004504526033997536,
            0.003267803695052862,
            -0.010344455018639565,
            -0.008715321309864521,
            -0.024124424904584885,
            -0.05364418774843216,
            0.015495971776545048,
            -0.014981436543166637,
            0.010305634699761868,
            -0.002412172267213464,
            0.01926974393427372,
            0.020571667701005936,
            0.032446589320898056,
            0.020368333905935287,
            -0.02670283243060112,
            0.012018485926091671,
            -0.0061683375388383865,
            -0.035107675939798355,
            -0.040635846555233,
            -0.015675561502575874,
            -0.03450508788228035,
            -0.0033989923540502787,
            -0.014222938567399979,
            -0.03052755631506443,
            0.002587666502222419,
            -0.005812667775899172,
            0.012883507646620274,
            -0.006388459354639053,
            -0.0006896759150549769,
            0.005981058347970247,
            -0.005230695474892855,
            0.018263788893818855,
            0.0789656862616539,
            0.03394019603729248,
            0.007254603318870068,
            -0.027439510449767113,
            0.020532909780740738,
            -0.02875530533492565,
            -0.005360197741538286,
            -0.008405202999711037,
            -0.04603853076696396,
            0.02658970281481743,
            0.02058413065969944,
            0.008460762910544872,
            0.0291435644030571,
            0.023823553696274757,
            -0.025389866903424263,
            0.029384909197688103,
            0.0041139875538647175,
            0.0037204143591225147,
            -0.04219526797533035,
            -0.06857273727655411,
            0.0020222729071974754,
            -0.002777712419629097,
            0.021948255598545074,
            -0.01648430898785591,
            0.026070652529597282,
            0.018648548051714897,
            0.02194412425160408,
            0.008013203740119934,
            -0.007344736717641354,
            -0.02225792407989502,
            -0.009083283133804798,
            -0.0056128413416445255,
            0.0007338647847063839,
            0.005832446739077568,
            0.009339447133243084,
            0.023381488397717476,
            -0.017819920554757118,
            -0.014684977009892464,
            -0.0002701287157833576,
            -0.0070363678969442844,
            0.024535465985536575,
            0.00597761943936348,
            0.015589081682264805,
            -0.01257040724158287,
            -0.01240801252424717,
            -0.006179493851959705,
            0.029676375910639763,
            0.00934121385216713,
            -0.018944956362247467,
            -0.006277201697230339,
            0.02613796666264534,
            -0.06837467104196548,
            -0.02449082024395466,
            0.016586339101195335,
            0.011543788015842438,
            0.02782725915312767,
            -0.007186352275311947,
            -0.03232594206929207,
            -0.009207913652062416,
            -0.022452235221862793,
            0.002878772560507059,
            0.017370767891407013,
            -0.008152478374540806,
            0.0004963572137057781,
            0.000880143721587956,
            -0.02714695781469345,
            0.002738088835030794,
            -0.002127906773239374,
            -0.011265362612903118,
            0.020837852731347084,
            0.019444189965724945,
            0.016234463080763817,
            -0.01977069303393364,
            0.007836286909878254,
            0.021827995777130127,
            0.02138490416109562,
            -0.05123508721590042,
            0.011705074459314346,
            0.040712714195251465,
            -0.005480975843966007,
            0.007001920603215694,
            -0.00966433621942997,
            0.002250665333122015,
            0.008363625966012478,
            -0.03189082071185112,
            0.03311215341091156,
            -0.0029574257787317038,
            -0.03890170902013779,
            0.002559794345870614,
            0.01461467519402504,
            0.011227107606828213,
            0.03002164326608181,
            -0.00692130159586668,
            -0.06233523041009903,
            -0.026450930163264275,
            -0.027306312695145607,
            0.015885625034570694,
            0.03744692727923393,
            -0.023496326059103012,
            0.03221598267555237,
            0.019265973940491676,
            -0.02752709574997425,
            0.005147445946931839,
            0.012763859704136848,
            0.027325693517923355,
            0.033062584698200226,
            -0.013010197319090366,
            -0.009393898770213127,
            0.023786667734384537,
            -0.015896476805210114,
            -0.002799254609271884,
            0.05803798511624336,
            0.029336461797356606,
            -0.02344878949224949,
            0.003007476218044758,
            0.020607367157936096,
            -0.0009937463328242302,
            -0.011418590322136879,
            -0.005921704228967428,
            0.013760429807007313,
            0.013712156563997269,
            0.030308378860354424,
            -0.07118938863277435,
            0.026269106194376945,
            0.024671658873558044,
            -0.03209551051259041,
            0.02421284094452858,
            0.021629424765706062,
            0.03097565285861492,
            0.027326198294758797,
            -0.04102267324924469,
            0.02325991727411747,
            0.00845152884721756,
            0.0271602813154459,
            0.03242141008377075,
            0.023669352754950523,
            -0.02122664451599121,
            0.025064684450626373,
            -0.026846623048186302,
            -0.02009100280702114,
            0.007912569679319859,
            -0.024745630100369453,
            -0.014778505079448223,
            -0.026204006746411324,
            -0.01939702220261097,
            0.011973598971962929,
            0.03228326141834259,
            0.019771456718444824,
            0.012251597829163074,
            -0.009265673346817493,
            -0.031080560758709908,
            -0.0012831197818741202,
            0.03713788464665413,
            0.026799922809004784,
            -0.028942279517650604,
            -0.003140944056212902,
            -0.018709538504481316,
            0.007038154639303684,
            0.023157160729169846,
            0.01302243396639824,
            0.02839299663901329,
            -0.0071130734868347645,
            -0.010571405291557312,
            -0.014794379472732544,
            0.019322171807289124,
            -0.0002915874938480556,
            -0.02767917700111866,
            -0.019652077928185463,
            0.03244766965508461,
            0.024502070620656013,
            -0.013186394236981869,
            0.014503950253129005,
            0.017526233568787575,
            -0.02495831809937954,
            0.011286494322121143,
            0.015244895592331886,
            0.010557266883552074,
            -0.013981686905026436,
            0.019913293421268463,
            0.013648909516632557,
            0.011771720834076405,
            0.008744495920836926,
            -0.00626220041885972,
            0.01928562857210636,
            0.02951424941420555,
            -0.020592274144291878,
            0.0003182008513249457,
            -0.017331639304757118,
            -0.027074387297034264,
            0.01212447602301836,
            -0.027513980865478516,
            0.02772950381040573,
            -0.016538437455892563,
            0.019873764365911484,
            0.03686913102865219,
            -0.019767889752984047,
            -0.009082723408937454,
            -0.00022188830189406872,
            0.013354267925024033,
            -0.020483268424868584,
            0.029506241902709007,
            -0.019763000309467316,
            -0.008081752806901932,
            0.004729315172880888,
            0.0242747962474823,
            -0.01907283626496792,
            -0.022577479481697083,
            0.01538741122931242,
            -0.004691104404628277,
            0.015354413539171219,
            0.0037341685965657234,
            0.015826651826500893,
            -0.05257077142596245,
            -0.006970684509724379,
            -0.026199951767921448,
            -0.0009789722971618176,
            -0.04135284572839737,
            0.008250560611486435,
            0.02053993195295334,
            -0.0073295761831104755,
            0.004383233841508627,
            0.008945339359343052,
            0.00646207993850112,
            -0.007147164084017277,
            -0.006248382851481438,
            -0.0326041579246521,
            0.018544668331742287,
            -0.007463888730853796,
            0.05774255841970444,
            0.006128224078565836,
            -0.006134958937764168,
            0.010694878175854683,
            -0.03650245815515518,
            -0.0029956570360809565,
            0.019471725448966026,
            0.021156273782253265,
            0.014177648350596428,
            0.005513124633580446,
            0.01755879446864128,
            0.026494668796658516,
            0.007013954222202301,
            -0.015592905692756176,
            -0.006110041402280331,
            0.027360469102859497,
            0.0004676288808695972,
            0.006941883359104395,
            0.016217445954680443,
            0.036088235676288605,
            -0.04922236502170563,
            -0.008780459873378277,
            -0.002130391076207161,
            -0.023814020678400993,
            -0.007315836846828461,
            -0.01070532575249672,
            -0.0213960949331522,
            0.020706266164779663,
            0.005025024525821209,
            0.023593705147504807,
            -0.03187808766961098,
            -0.02122677117586136,
            -0.02003960683941841,
            -0.017657294869422913,
            -0.0266882311552763,
            0.028259066864848137,
            0.03145081177353859,
            0.028316045179963112,
            0.007366458885371685,
            0.030614234507083893,
            0.017568983137607574,
            0.003961582668125629,
            -0.018348611891269684,
            0.007907177321612835,
            0.02958342619240284,
            0.010962293483316898,
            0.01288886833935976,
            -0.009737486951053143,
            -0.01119532622396946,
            -0.031224263831973076,
            0.016530659049749374,
            0.01245646271854639,
            -0.07501712441444397,
            0.02249770238995552,
            0.013850521296262741,
            -0.006763877812772989,
            -0.006356970407068729,
            -0.06229614466428757,
            -0.01310853473842144,
            -0.03400498628616333,
            0.02032649889588356,
            0.025574257597327232,
            0.03362438082695007,
            0.026956502348184586,
            0.021994974464178085,
            -0.015516242943704128,
            0.031170569360256195,
            0.024494558572769165,
            -0.00016251679335255176,
            -0.00491571519523859,
            0.010378374718129635,
            -0.0009111196268349886,
            -0.03695036843419075,
            -0.005784351844340563,
            0.007229902315884829,
            0.025758180767297745,
            0.024811696261167526,
            0.017240896821022034,
            0.0061906492337584496,
            -0.01741459220647812,
            0.012613898143172264,
            0.01007186621427536,
            0.001706657581962645,
            -0.022981634363532066,
            0.02196604758501053,
            0.011568102985620499,
            0.02232879213988781,
            0.03693089261651039,
            -0.0022566351108253,
            0.013067512772977352,
            -0.025385217741131783,
            -0.023900045081973076,
            -0.016062503680586815,
            -0.0015340682584792376,
            0.006201531738042831,
            -0.040087804198265076,
            -0.026522653177380562,
            0.03314812108874321,
            -0.051617857068777084,
            0.016204066574573517,
            0.0284268818795681,
            -0.018004998564720154,
            0.008461765013635159,
            -0.002642877632752061,
            -0.002472969237715006,
            0.04931936785578728,
            0.03804052248597145,
            0.01748216710984707,
            -0.0072805508971214294,
            -0.01997021585702896,
            -0.017722105607390404,
            0.02486201748251915,
            0.009874887764453888,
            -0.008805557154119015,
            -0.020550036802887917,
            0.00031018955633044243,
            -0.02542329579591751,
            0.0065387855283916,
            0.002776745706796646,
            0.03129813075065613,
            -0.04097113385796547,
            -0.02835772931575775,
            -0.016157489269971848,
            0.02071976661682129,
            0.005535061005502939,
            0.003597296541556716,
            0.019388530403375626,
            -0.028107402846217155,
            -0.007267400156706572,
            0.00860204640775919,
            0.011874622665345669,
            0.013675113208591938,
            0.022822193801403046,
            0.033272285014390945,
            -0.007115108892321587,
            0.008358041755855083,
            0.029244743287563324,
            -0.009567429311573505,
            0.01832748018205166,
            -0.035447411239147186,
            0.0026932349428534508,
            0.01124474499374628,
            -0.017075344920158386,
            0.0036070821806788445,
            0.022075878456234932,
            0.02456093579530716,
            0.04260161891579628,
            -0.0026382978539913893,
            0.011751261539757252,
            -0.024213112890720367,
            0.019202031195163727,
            -0.03762553259730339,
            0.05268328636884689,
            0.007584812585264444,
            0.009287189692258835,
            0.021895533427596092,
            0.0004223461146466434,
            0.008840051479637623,
            -0.005747524555772543,
            -0.006298877764493227,
            0.008927847258746624,
            -0.005125053692609072,
            0.009191107004880905,
            -0.01623210310935974,
            -0.01135388482362032,
            -0.0008279461762867868,
            0.01333761028945446,
            0.022017382085323334,
            0.020694926381111145,
            -0.01350617129355669,
            -0.01950310543179512,
            -0.010867870412766933,
            0.021446077153086662,
            -0.006861330941319466,
            0.013932577334344387,
            0.025932012125849724,
            -0.006404755171388388,
            0.027173509821295738,
            0.01369366142898798,
            0.00877506285905838,
            0.01616411656141281,
            -0.03702447563409805,
            -0.011401520110666752,
            -0.001723750727251172,
            0.026578132063150406,
            -0.01953299157321453,
            0.02916778065264225,
            -0.024285711348056793,
            0.02251579239964485,
            -0.028120502829551697,
            0.03043384663760662,
            0.028628800064325333,
            0.014845852740108967,
            0.01356868539005518,
            -0.02536121942102909,
            0.024172790348529816,
            0.017317714169621468,
            -0.010736588388681412,
            -0.03320125862956047,
            0.014660364016890526,
            0.016634665429592133,
            0.04857400059700012,
            -0.030684882774949074,
            0.014929612167179585,
            0.029258543625473976,
            0.04246725142002106,
            0.0350506491959095,
            -0.01731734164059162,
            -0.011206245981156826,
            0.01830141246318817,
            0.004629128612577915,
            0.0013674302026629448,
            -0.001821376965381205,
            -0.010403257794678211,
            0.012084943242371082,
            -0.015623114071786404,
            0.004931557457894087,
            -0.024779105558991432,
            -0.004101956263184547,
            -0.00918083731085062,
            -0.011331365443766117,
            0.03151713311672211,
            -0.0004542313690762967,
            -0.022760890424251556,
            -0.004887469578534365,
            0.03264647349715233,
            0.004976108204573393,
            -0.026688922196626663,
            -0.008161346428096294,
            -0.0072222743183374405,
            0.01394032035022974,
            -0.02511575073003769,
            -0.00955923181027174,
            -0.009348446503281593,
            0.0019391216337680817,
            -0.0005475572543218732,
            -0.028714975342154503,
            0.010610118508338928,
            0.01904616504907608,
            -0.041551679372787476,
            0.008486463688313961,
            0.04022683948278427,
            -0.0014641077723354101,
            0.0033925098832696676,
            0.028151879087090492,
            -0.008850494399666786,
            0.029618440195918083,
            -0.0092311417683959,
            0.03113872930407524,
            -0.011162731796503067,
            -0.0419452004134655,
            -0.013454158790409565,
            -0.0015721246600151062,
            0.010336434468626976,
            -0.043897680938243866,
            0.01399332657456398,
            0.02901955135166645,
            0.0009885132312774658,
            -0.04585022106766701,
            0.005860341712832451,
            0.009885981678962708,
            -0.01574312336742878,
            -0.016958262771368027,
            0.0028393659740686417,
            0.01269015297293663,
            0.006876693572849035,
            0.015734566375613213,
            -0.05573156848549843,
            -0.017435666173696518,
            -0.010969891212880611,
            -0.003754209727048874,
            0.02338450774550438,
            -0.022542566061019897,
            0.01174960657954216,
            0.0051302723586559296,
            -0.037390563637018204,
            -0.00935135968029499,
            -0.0374392606317997,
            0.052914075553417206,
            -0.026880092918872833,
            0.029979731887578964,
            0.023304766044020653,
            -0.019852133467793465,
            -0.018698692321777344,
            -0.02708117850124836,
            0.012177643366158009,
            -0.02999013476073742,
            0.031162982806563377,
            -0.014770198613405228,
            -0.003826324362307787,
            0.0005530772032216191,
            0.035618457943201065,
            0.018049316480755806,
            -0.01395576074719429,
            -0.018710894510149956,
            -0.005796737968921661,
            0.015393363311886787,
            0.03426792472600937,
            -0.009787816554307938,
            -0.02013625204563141,
            0.005850433837622404,
            0.0014886404387652874,
            -0.019447211176156998,
            -0.013581442646682262,
            0.03882306441664696,
            -0.019162893295288086,
            0.031156420707702637,
            -0.0016736999386921525,
            -0.02816608175635338,
            0.007620226126164198,
            0.008234335109591484,
            0.013158195652067661,
            -0.014427628368139267,
            -0.0022187321446835995,
            -0.01235242746770382,
            0.017931438982486725,
            0.004870430566370487,
            0.035381365567445755,
            -0.027994055300951004,
            0.010983341373503208,
            0.025927705690264702,
            0.027920156717300415,
            -0.012551390565931797,
            -0.009616282768547535,
            -0.010337878949940205,
            0.019087137654423714,
            0.0270533487200737,
            0.011537495069205761,
            0.038281362503767014,
            -0.028858939185738564,
            -0.030438140034675598,
            -0.0061372979544103146,
            -0.030559198930859566,
            -0.005996018648147583,
            -0.014510296285152435,
            0.019601546227931976,
            0.006221237126737833,
            -0.011693486012518406,
            0.028557099401950836,
            -0.019046718254685402,
            -0.022530673071742058,
            0.03565433993935585,
            -0.013383280485868454,
            -0.017837809398770332,
            -0.0032459506765007973,
            0.026519158855080605,
            -0.017972508445382118,
            0.016078978776931763,
            0.018254103139042854,
            -0.016359727829694748,
            0.014881948009133339,
            0.007967755198478699,
            -0.00874350406229496,
            0.03813524544239044,
            -0.00471155159175396,
            0.015744011849164963,
            -0.008711849339306355,
            0.03417224436998367,
            0.0021721175871789455,
            -0.01920991949737072,
            0.011541096493601799,
            0.004516300745308399,
            -0.030810745432972908,
            -0.051098763942718506,
            -0.014708565548062325,
            0.01180637814104557,
            -0.03152228146791458,
            -0.008015286177396774,
            0.005498535465449095,
            -0.023227015510201454,
            -0.018134424462914467,
            -0.024531705304980278,
            0.01622345857322216,
            0.032519903033971786,
            0.005483946297317743,
            0.024360228329896927,
            0.01896902732551098,
            -0.005341770593076944,
            0.016749214380979538,
            -0.009506839327514172,
            0.02370365709066391,
            -0.0030882360879331827,
            -0.006547378841787577,
            0.030369609594345093,
            -0.018390541896224022,
            0.022468913346529007,
            0.023793194442987442,
            -0.028101865202188492,
            -0.018572155386209488,
            0.005617087706923485,
            -0.009722081944346428,
            0.010975356213748455,
            0.010561693459749222,
            0.02750299498438835,
            -0.0035177324898540974,
            0.0040518734604120255,
            -0.014712043106555939,
            -0.02689031884074211,
            -0.02631218358874321,
            -0.009331307373940945,
            -0.008024455979466438,
            0.009948787279427052,
            -0.037568338215351105,
            0.008787447586655617,
            0.033073339611291885,
            0.022659780457615852,
            0.018229100853204727,
            0.02734169363975525,
            0.012493973597884178,
            0.012096084654331207,
            -0.011441935785114765,
            -0.021602459251880646,
            -0.012446665205061436,
            0.005509244743734598,
            -0.037400223314762115,
            -0.007304071448743343,
            0.018574804067611694,
            0.01053408719599247,
            0.042550455778837204,
            -0.0017012708121910691,
            0.0035348411183804274,
            0.01889076828956604,
            -0.008867908269166946,
            0.016639085486531258,
            -0.0028181923553347588,
            -0.03106546774506569,
            0.02615715190768242,
            0.017565911635756493,
            -0.01383849699050188,
            0.0036204312928020954,
            -0.023372860625386238,
            -0.031409718096256256,
            0.0026434361934661865,
            0.03184004873037338,
            0.028779471293091774,
            0.01402776874601841,
            -0.03602563217282295,
            0.001035768073052168,
            -0.02238895185291767,
            0.018389573320746422,
            -0.02249356172978878,
            0.029510393738746643,
            0.02500726655125618,
            -0.02246055006980896,
            -0.012064436450600624,
            -0.00972248800098896,
            -0.006425485946238041,
            -0.00888931006193161,
            0.018786011263728142,
            0.01592632383108139,
            -0.016675377264618874,
            -0.010415821336209774,
            0.008109383285045624,
            -0.03363950550556183,
            0.0019199331291019917,
            0.00884285569190979,
            -0.031067166477441788,
            -0.010285183787345886,
            -0.0189508143812418,
            0.022516397759318352,
            -0.04195687174797058,
            0.0333331823348999,
            -0.009752348065376282,
            -0.038265034556388855,
            -0.04389342665672302,
            0.0016276108799502254,
            0.02601916529238224,
            -0.03168651834130287,
            0.021241677924990654,
            0.03178292512893677,
            -0.021583879366517067,
            0.024178531020879745,
            -0.009008720517158508,
            0.0016169532900676131,
            -0.012265092693269253,
            0.03721100836992264,
            0.01060488075017929,
            -0.032037388533353806,
            -0.018378840759396553,
            -0.0356178916990757,
            0.007935537956655025,
            -0.0063908942975103855,
            -0.014563469216227531,
            0.0040288567543029785,
            0.016573479399085045,
            0.012144078500568867,
            -0.015482917428016663,
            -0.01836060732603073,
            0.013444071635603905,
            0.008960949257016182,
            -0.02262190356850624,
            -0.015294807963073254,
            0.0034288999158889055,
            0.016242967918515205,
            -0.00964359100908041,
            -0.019478140398859978,
            0.014763754792511463,
            -0.011567285284399986,
            0.023263709619641304,
            -0.004440730903297663,
            0.022810986265540123,
            -0.0052344901487231255,
            0.031015779823064804,
            -0.015647655352950096,
            -0.012743815779685974,
            0.02708066813647747,
            0.01673748530447483,
            -0.0290693286806345,
            0.019524645060300827,
            -0.01640057936310768,
            0.00308949314057827,
            0.019009556621313095,
            0.005593165755271912,
            -0.006732230074703693,
            -0.01658308319747448,
            -0.016719449311494827,
            -0.025202229619026184,
            0.023011451587080956,
            -0.019439248368144035,
            -0.027268916368484497,
            0.04730195179581642,
            0.005305331200361252,
            0.002010851399973035,
            -0.013758146204054356,
            0.011145252734422684,
            0.012854683212935925,
            -0.031552620232105255,
            0.040161047130823135,
            -0.03205942362546921,
            0.01720256730914116,
            -0.0012056711129844189,
            -0.012214630842208862,
            0.011995503678917885,
            0.036303866654634476,
            0.03007165528833866,
            -0.032059818506240845,
            0.022192222997546196,
            0.005419120658189058,
            0.019108692184090614,
            -0.029649483039975166,
            0.027296945452690125,
            -0.024938588961958885,
            0.010396263562142849,
            -0.012365785427391529,
            0.005319632589817047,
            0.036345478147268295,
            -0.005737523082643747,
            -0.004748369567096233,
            -0.041572023183107376,
            0.007428827229887247,
            0.014392678625881672,
            0.04966948926448822,
            0.05568854510784149,
            -0.004688325338065624,
            0.002769485581666231,
            -0.032122883945703506,
            0.026729360222816467,
            0.0002845892740879208,
            -0.006693854462355375,
            0.02787155658006668,
            -0.005983560811728239,
            0.026847973465919495,
            0.003245760453864932,
            -0.0008393454481847584,
            0.012546940706670284,
            0.03162134438753128,
            -0.004555658902972937,
            0.04285874217748642,
            0.04344761744141579,
            -0.004544353112578392,
            0.033179350197315216,
            -0.035387054085731506,
            -0.034986499696969986,
            -0.015179219655692577,
            0.0010492110159248114,
            -0.027073552832007408,
            -0.009403400123119354,
            0.015542115084826946,
            0.014989597722887993,
            0.0308790635317564,
            -0.004984468221664429,
            -0.01090792752802372,
            -0.03330513834953308,
            0.01653377339243889,
            0.02881118468940258,
            -0.004130125977098942,
            0.028125477954745293,
            -0.0006785861332900822,
            0.002486399607732892,
            -0.03066272661089897,
            -0.017208866775035858,
            -0.035482361912727356,
            -0.04480072110891342,
            0.021673260256648064,
            0.01483889389783144,
            -0.011045750230550766,
            0.025534063577651978,
            0.030742105096578598,
            -0.021486973389983177,
            0.01758144050836563,
            -0.023154044523835182,
            0.0038163545541465282,
            0.01728895865380764,
            -0.027566328644752502,
            0.0193924680352211,
            -0.0024923309683799744,
            -0.002234786981716752,
            -0.018492739647626877,
            -0.022944344207644463,
            0.021730830892920494,
            0.019762594252824783,
            0.009577223099768162,
            0.04828030616044998,
            -0.05612482875585556,
            0.010527183301746845,
            0.0347040630877018,
            0.011426141485571861,
            -0.02826942503452301,
            -0.03144880384206772,
            0.03788715973496437,
            -0.0001866301754489541,
            -0.011470452882349491,
            0.017836060374975204,
            0.009972766041755676,
            0.0035194933880120516,
            -0.020541932433843613,
            0.03882457688450813,
            -0.003972096834331751,
            -0.03391284495592117,
            -0.017773831263184547,
            -0.025265319272875786,
            0.010363622568547726,
            -0.006125426851212978,
            -0.007221404928714037,
            0.002800955902785063,
            -0.016896769404411316,
            -0.03023618459701538,
            -0.003148213727399707,
            0.03472622111439705,
            -0.0034540428314357996,
            0.04116963967680931,
            0.021246394142508507,
            0.024500887840986252,
            -0.017388753592967987,
            -0.0006020241416990757,
            0.035381730645895004,
            -0.012185080908238888,
            0.027406347915530205,
            0.017522981390357018,
            0.019224416464567184,
            0.011972923763096333,
            -0.031358424574136734,
            -0.015813978388905525,
            -0.011623194441199303,
            -0.01310682948678732,
            -0.0021127909421920776,
            -0.015874529257416725,
            -0.018005279824137688,
            0.02337862364947796,
            0.011115491390228271,
            0.026755981147289276,
            -0.013630405068397522,
            0.006128737237304449,
            -0.027053602039813995,
            -0.013883237726986408,
            0.011083681136369705,
            -0.01844801940023899,
            -0.019254470244050026,
            -0.011327479965984821,
            -0.012427159585058689,
            -0.006318130996078253,
            0.017505744472146034,
            -0.011483891867101192,
            0.01951957680284977,
            -0.027264701202511787,
            -0.03147343173623085,
            -0.04082712158560753,
            -0.013832304626703262,
            0.027702689170837402,
            -0.016018956899642944,
            0.005698185879737139,
            -0.026540139690041542
        ],
        [
            -0.015849053859710693,
            -0.045420292764902115,
            -0.015492666512727737,
            -0.024951379746198654,
            0.03427884727716446,
            0.012437122873961926,
            -0.04894601181149483,
            0.024358129128813744,
            0.020333193242549896,
            0.0031974706798791885,
            0.012002581730484962,
            -0.03608257323503494,
            -0.00677946163341403,
            -0.03942752256989479,
            -0.0023040692321956158,
            -0.006176932714879513,
            -0.00755305215716362,
            0.03502603620290756,
            -0.025364728644490242,
            0.020552635192871094,
            -0.030544985085725784,
            0.014118910767138004,
            0.050026409327983856,
            0.016994651407003403,
            -0.017756441608071327,
            -0.0036662318743765354,
            -0.0632052943110466,
            0.02527475357055664,
            -0.027942785993218422,
            -0.02128966711461544,
            -0.009721411392092705,
            0.03917883336544037,
            0.037410836666822433,
            0.009160150773823261,
            -0.007250417955219746,
            0.02343125455081463,
            -0.013816115446388721,
            -0.023599375039339066,
            0.02564375102519989,
            0.033895935863256454,
            -0.002886382630094886,
            0.015759536996483803,
            0.04923092946410179,
            -0.008442503400146961,
            0.010210081934928894,
            0.007462987210601568,
            -0.028675807639956474,
            -0.019102711230516434,
            -0.02197151817381382,
            0.013063226826488972,
            0.02031789720058441,
            -0.025703758001327515,
            -0.04000989720225334,
            -0.014479742385447025,
            -0.01578202098608017,
            -0.015760531648993492,
            -0.004531224723905325,
            0.031441234052181244,
            0.00861730333417654,
            0.0238527599722147,
            0.031146137043833733,
            0.004786908160895109,
            0.020992133766412735,
            -0.006926610134541988,
            0.020575275644659996,
            -0.02300042100250721,
            -0.0019927253015339375,
            -0.012612638995051384,
            0.0029135607182979584,
            0.015204344876110554,
            0.011407563462853432,
            0.019068056717514992,
            0.026324767619371414,
            -0.03120667114853859,
            0.023929545655846596,
            -0.00016463255451526493,
            0.0035726206842809916,
            -0.008767105638980865,
            -0.011611138470470905,
            0.00890906248241663,
            0.01514357142150402,
            -0.055738043040037155,
            0.011589352041482925,
            0.0006592017016373575,
            0.0030910971108824015,
            -0.03271271660923958,
            0.012953492812812328,
            -0.05727149918675423,
            0.0019313349621370435,
            0.018887873739004135,
            -0.02775144763290882,
            0.008896812796592712,
            0.014219855889678001,
            0.04533756896853447,
            -0.00415393989533186,
            0.004718686453998089,
            0.0142696313560009,
            0.002458194736391306,
            -0.007017327006906271,
            -0.030665626749396324,
            -0.014257565140724182,
            -0.01133372075855732,
            -0.014931689016520977,
            -0.016528122127056122,
            -0.002994273556396365,
            0.00561754684895277,
            -6.674024189123884e-05,
            0.032042574137449265,
            0.010316918604075909,
            0.032354287803173065,
            0.004905070178210735,
            -0.0447380430996418,
            -0.008221149444580078,
            0.011921762488782406,
            0.009713456965982914,
            -0.008468839339911938,
            -0.01613924466073513,
            0.0013979197246953845,
            -0.009492656216025352,
            -0.021714774891734123,
            0.005778633523732424,
            -0.025087399408221245,
            -0.03433140367269516,
            -0.01329874899238348,
            0.025562457740306854,
            0.0032680281437933445,
            0.030310871079564095,
            0.018765436485409737,
            -0.03343838080763817,
            -0.027723366394639015,
            0.031064843758940697,
            -0.0018160509644076228,
            0.01168094389140606,
            -0.08266811817884445,
            0.030153954401612282,
            -0.011554370634257793,
            0.004373535979539156,
            0.00924702174961567,
            -0.012007107958197594,
            -0.016840899363160133,
            0.0038612757343798876,
            -0.008065273985266685,
            0.026262447237968445,
            0.035197824239730835,
            0.0093692597001791,
            0.006412096321582794,
            0.02610597386956215,
            0.014850740320980549,
            -0.025333257392048836,
            -0.014773600734770298,
            -0.006783342454582453,
            0.04217441380023956,
            0.028308508917689323,
            0.04685252159833908,
            0.006447962950915098,
            -0.01822037436068058,
            0.0013237041421234608,
            -0.02113310806453228,
            -0.026072580367326736,
            -0.010784097947180271,
            0.02664194256067276,
            -0.009394558146595955,
            0.027285991236567497,
            0.018588591367006302,
            -0.02328973077237606,
            -0.030521316453814507,
            0.027735302224755287,
            0.034743085503578186,
            0.027839377522468567,
            -0.023128528147935867,
            -0.004283073823899031,
            0.0421968474984169,
            0.02175739035010338,
            -0.0059676761738955975,
            -0.019140351563692093,
            0.0009953448316082358,
            -0.0255292821675539,
            0.028892401605844498,
            0.002126480685546994,
            -0.010684888809919357,
            -0.024433407932519913,
            -0.03360974043607712,
            -0.038633622229099274,
            0.016353324055671692,
            0.02261720411479473,
            -0.023216446861624718,
            -0.007089642807841301,
            0.021753963083028793,
            -0.029918154701590538,
            0.000836507068015635,
            -0.024720782414078712,
            -0.020822659134864807,
            -0.023815909400582314,
            -0.04011547937989235,
            -0.028033612295985222,
            0.02588720992207527,
            0.035948045551776886,
            -0.015752160921692848,
            0.02056276425719261,
            -0.02602730691432953,
            -0.01745944283902645,
            -0.01952665112912655,
            0.021682050079107285,
            -0.013029932044446468,
            0.0004067818517796695,
            0.017960118129849434,
            -0.01990341767668724,
            0.024231497198343277,
            -0.026908721774816513,
            0.012990987859666348,
            0.016885539516806602,
            0.04434708505868912,
            0.0002662551123648882,
            0.011281014420092106,
            0.014773700386285782,
            0.0067420294508337975,
            0.0028134684544056654,
            -0.0031303938012570143,
            3.1850133382249624e-05,
            0.029980435967445374,
            0.029142925515770912,
            0.028606511652469635,
            0.000674645125400275,
            0.005089566111564636,
            0.035247523337602615,
            0.02666144073009491,
            -0.029185963794589043,
            -0.020715687423944473,
            -0.00035059836227446795,
            -0.024406282231211662,
            0.0113736717030406,
            0.00895603932440281,
            0.02897713892161846,
            -0.011475828476250172,
            -0.04421607777476311,
            0.00557092996314168,
            -0.015293149277567863,
            0.00929412990808487,
            -0.019643470644950867,
            -0.027556221932172775,
            0.013001425191760063,
            -0.031059306114912033,
            0.015366222709417343,
            -0.0011908668093383312,
            -0.013567863963544369,
            -0.006211889907717705,
            0.018001826480031013,
            0.013734841719269753,
            -0.03176143765449524,
            -0.015013915486633778,
            -0.026999304071068764,
            0.022606121376156807,
            0.03175949677824974,
            0.0003537305456120521,
            -0.0020348315592855215,
            -0.002631096402183175,
            -0.023020051419734955,
            -0.03188807889819145,
            0.008334213867783546,
            0.023827863857150078,
            0.02918964996933937,
            0.023943791165947914,
            -0.001738375169225037,
            0.009493942372500896,
            7.796130375936627e-06,
            0.01174633763730526,
            0.018712695688009262,
            0.034956783056259155,
            0.0028116139583289623,
            0.017169591039419174,
            -0.005871626082807779,
            -0.005558646284043789,
            -0.01346191018819809,
            0.010265267454087734,
            -0.0037826155312359333,
            -0.030367646366357803,
            -0.025175029411911964,
            0.017938097938895226,
            0.008089324459433556,
            0.02889452874660492,
            0.006330840289592743,
            0.029044514521956444,
            -0.0008627978968434036,
            0.01249503530561924,
            0.032838188111782074,
            -0.006452692672610283,
            0.019545137882232666,
            0.014954558573663235,
            -0.013985730707645416,
            -0.017969714477658272,
            0.01540520042181015,
            -0.018177324905991554,
            -0.02180829644203186,
            -0.011749963276088238,
            0.025722742080688477,
            0.01589551940560341,
            0.007652949541807175,
            -0.014926590025424957,
            0.0400821678340435,
            -0.007532586809247732,
            -0.012807998806238174,
            -0.04120701551437378,
            -0.011730446480214596,
            -0.035550519824028015,
            -0.014842051081359386,
            0.05453409254550934,
            0.024426838383078575,
            -0.017567282542586327,
            -0.012930002994835377,
            -0.013081224635243416,
            -0.050928544253110886,
            0.0004308123898226768,
            0.0029040842782706022,
            -0.03224676102399826,
            -0.020286161452531815,
            -0.008554516360163689,
            0.013632015325129032,
            0.004022027365863323,
            0.007462752517312765,
            -0.01429139543324709,
            -0.02712135575711727,
            -0.00017706825747154653,
            -0.014615539461374283,
            -0.03331645205616951,
            -0.02354205958545208,
            0.009429876692593098,
            -0.04942622408270836,
            -0.01144922710955143,
            0.01165128592401743,
            -0.02382839284837246,
            -0.01588045433163643,
            -0.038421280682086945,
            -0.016107551753520966,
            0.011769791133701801,
            0.016475459560751915,
            0.007296593859791756,
            0.01637422852218151,
            -0.03339076414704323,
            0.02925868146121502,
            -0.03601810708642006,
            0.029770923778414726,
            0.025419168174266815,
            0.04202866181731224,
            -0.012285854667425156,
            -0.007139923516660929,
            -0.005115075968205929,
            -0.009440611116588116,
            0.01226925291121006,
            0.010713070631027222,
            0.016573229804635048,
            -0.01631118915975094,
            0.01570267602801323,
            0.040653035044670105,
            -0.002340339357033372,
            -0.005533358082175255,
            0.00837702862918377,
            0.012045048177242279,
            0.029747335240244865,
            0.037863947451114655,
            -0.005528387613594532,
            0.012923837639391422,
            0.014102199114859104,
            -0.010591153986752033,
            0.006557355634868145,
            0.042313102632761,
            0.013738657347857952,
            -0.025919035077095032,
            0.0012259474024176598,
            0.03601555898785591,
            -0.02086709253489971,
            0.023267682641744614,
            0.02188444323837757,
            0.023875124752521515,
            -0.017411937937140465,
            0.017701830714941025,
            0.004430350847542286,
            0.019022231921553612,
            0.011438220739364624,
            -0.021182900294661522,
            -0.0175621435046196,
            -0.009840031154453754,
            0.011365467682480812,
            -0.031209269538521767,
            0.007889403961598873,
            -0.05020540952682495,
            0.019505294039845467,
            -0.010750086978077888,
            -0.012627544812858105,
            -0.01036079227924347,
            0.013665392063558102,
            -0.001082184142433107,
            -0.007073093671351671,
            -0.009244979359209538,
            0.02243773639202118,
            -0.004849490709602833,
            0.03217533603310585,
            -0.014225042425096035,
            0.028853125870227814,
            0.020296430215239525,
            0.038988176733255386,
            -0.022475451231002808,
            0.02371925301849842,
            -0.008519524708390236,
            -0.024465052410960197,
            -0.017781121656298637,
            0.01611189916729927,
            0.01990935020148754,
            -0.0017907291185110807,
            -0.011259343475103378,
            0.019149715080857277,
            -0.0019059748155996203,
            0.012819459661841393,
            0.03558048978447914,
            -0.012726218439638615,
            -0.007024390622973442,
            -0.012724907137453556,
            -0.015001684427261353,
            0.031040575355291367,
            0.02543269656598568,
            -0.004350756295025349,
            -0.006518586538732052,
            0.018630020320415497,
            -0.0008302369969896972,
            -0.029063044115900993,
            -0.023624006658792496,
            0.07684210687875748,
            0.011777820996940136,
            0.028388481587171555,
            0.010508008301258087,
            0.022533172741532326,
            -0.009001505561172962,
            -0.022786401212215424,
            -0.004457767121493816,
            0.01728099398314953,
            0.0014667838113382459,
            -0.024241220206022263,
            0.040225811302661896,
            0.008978931233286858,
            0.0012430341448634863,
            0.002070291433483362,
            -0.02440156228840351,
            -0.02343655191361904,
            0.0023855629842728376,
            -0.026241373270750046,
            -0.006221554707735777,
            -0.021117597818374634,
            -0.016388796269893646,
            0.057038914412260056,
            -0.026271553710103035,
            0.002674461342394352,
            -0.029627572745084763,
            0.036342643201351166,
            -0.006876849103718996,
            0.013110934756696224,
            -0.015990344807505608,
            0.015659956261515617,
            0.037595730274915695,
            0.0002696341834962368,
            0.035208266228437424,
            -0.013665898703038692,
            0.017184963449835777,
            0.034168343991041183,
            -0.02346022240817547,
            -0.032879531383514404,
            0.02340940572321415,
            0.007873434573411942,
            0.004985525738447905,
            0.01097733061760664,
            -0.01912880875170231,
            -0.008676460944116116,
            0.01275020744651556,
            0.013795501552522182,
            0.0036597861908376217,
            0.036300092935562134,
            0.02471865899860859,
            0.007364050485193729,
            -0.007741841487586498,
            0.024665050208568573,
            0.03353622928261757,
            -0.004388927947729826,
            -0.023223137483000755,
            -0.0032701343297958374,
            -0.0036229968536645174,
            0.01142460759729147,
            -0.017830396071076393,
            -0.009071587584912777,
            -0.010470989160239697,
            -0.0007782058673910797,
            -0.07135476171970367,
            -0.00020876052440144122,
            -0.0017009670846164227,
            0.029114404693245888,
            -0.006028188858181238,
            0.027270624414086342,
            0.01890891045331955,
            0.02543226256966591,
            -0.013601657934486866,
            0.01827085018157959,
            -0.029934516176581383,
            -0.02377215400338173,
            -0.028278954327106476,
            0.0130870146676898,
            0.03426836431026459,
            0.0011607239721342921,
            -0.004450582433491945,
            0.018618660047650337,
            0.002875851234421134,
            -0.013808327727019787,
            0.025899190455675125,
            -0.025979477912187576,
            0.007320153992623091,
            0.012856514193117619,
            -0.024846335873007774,
            0.009917235933244228,
            0.014234863221645355,
            -0.034347694367170334,
            -0.010203688405454159,
            0.020443614572286606,
            -0.013910898007452488,
            0.012420636601746082,
            0.019382638856768608,
            -0.005100209731608629,
            -0.005521588027477264,
            -0.00030584222986362875,
            0.02318873070180416,
            -0.021173467859625816,
            -0.002897045109421015,
            0.001841021585278213,
            0.025877084583044052,
            0.016954902559518814,
            0.0018233091104775667,
            0.04538490250706673,
            0.025748973712325096,
            -0.009721904993057251,
            0.007727319374680519,
            0.007340440060943365,
            0.01832408457994461,
            -0.045297253876924515,
            -0.012629681266844273,
            -0.010003975592553616,
            -0.006391887553036213,
            -0.01991758495569229,
            0.026162153109908104,
            0.048643723130226135,
            -0.009702335111796856,
            -0.010213918052613735,
            0.011449111625552177,
            0.013143784366548061,
            0.0019154378678649664,
            0.032131128013134,
            -0.0021509339567273855,
            -0.0030347721185535192,
            0.018155086785554886,
            0.014139063656330109,
            -0.02259843610227108,
            0.022498607635498047,
            -0.004076248034834862,
            -0.021322432905435562,
            -0.0014574320521205664,
            0.020567769184708595,
            0.03251822665333748,
            -0.031156335026025772,
            -0.0010079960338771343,
            0.020950688049197197,
            0.011457078158855438,
            -0.02028430812060833,
            -0.021841954439878464,
            0.0023872042074799538,
            0.007581554353237152,
            -0.006951372604817152,
            0.010418400168418884,
            0.024972284212708473,
            -0.024529125541448593,
            0.022043852135539055,
            -0.00021841989655513316,
            0.004514207597821951,
            -0.016877681016921997,
            0.01091533713042736,
            0.002127666724845767,
            -0.019558202475309372,
            0.020917942747473717,
            0.03230898454785347,
            -0.01012514065951109,
            0.023589681833982468,
            0.009128942154347897,
            0.007532201241701841,
            -0.024537602439522743,
            0.03150719031691551,
            -0.0012461753794923425,
            0.04063374176621437,
            0.025173936039209366,
            -0.024196410551667213,
            -0.015866070985794067,
            0.03353062644600868,
            -0.01627383567392826,
            0.00981032196432352,
            -0.031731314957141876,
            0.02890707366168499,
            -0.028183629736304283,
            -0.027193214744329453,
            -0.03373844549059868,
            0.02402067556977272,
            -0.035983357578516006,
            -0.009714467450976372,
            0.027660230174660683,
            -0.03356187045574188,
            -0.006490892264991999,
            0.008467732928693295,
            0.0432860292494297,
            -0.012950984761118889,
            0.02857159450650215,
            -0.01691863313317299,
            0.0043859477154910564,
            0.03407831862568855,
            -0.0017682446632534266,
            0.02018669992685318,
            0.025185108184814453,
            -0.030369123443961143,
            -0.027667520567774773,
            0.0009306062711402774,
            0.0316285565495491,
            0.015841010957956314,
            -0.01008443534374237,
            0.015003480017185211,
            -0.0169384703040123,
            -0.004317639395594597,
            0.004966844338923693,
            0.01617375575006008,
            0.01581498794257641,
            0.006909763906151056,
            0.01693424955010414,
            0.016481274738907814,
            -0.032104820013046265,
            0.010796002112329006,
            -0.019515298306941986,
            -0.024580569937825203,
            0.023289741948246956,
            -7.523358362959698e-05,
            -0.025602517649531364,
            -0.008675316348671913,
            -0.02178005501627922,
            0.04462411627173424,
            0.03136001154780388,
            -0.026069575920701027,
            -0.008643458597362041,
            0.037253838032484055,
            -0.016700824722647667,
            -0.0222786832600832,
            0.03133745864033699,
            -0.013864822685718536,
            -0.028320269659161568,
            0.009871209971606731,
            -0.013156397268176079,
            -0.022888101637363434,
            -0.0532117523252964,
            -0.02995268814265728,
            -0.020773889496922493,
            -0.013926313258707523,
            -0.005457835737615824,
            0.0369444377720356,
            -0.02061060443520546,
            0.012688754126429558,
            0.03193267434835434,
            0.04059864580631256,
            0.018656330183148384,
            -0.009371249936521053,
            -0.02143564075231552,
            -0.02284429781138897,
            -0.021147672086954117,
            -0.0002567760238889605,
            -0.011101024225354195,
            -0.004698813892900944,
            -0.009707811288535595,
            0.009747573174536228,
            0.0032676912378519773,
            -0.02450578846037388,
            0.016626857221126556,
            -0.005154483951628208,
            -0.02644166350364685,
            0.00367525452747941,
            0.027649343013763428,
            0.005830750335007906,
            0.027472490444779396,
            -0.0356253981590271,
            0.025453316047787666,
            0.015989910811185837,
            -0.014233328402042389,
            -0.013761560432612896,
            -0.020019184798002243,
            0.013183718547224998,
            0.03494960069656372,
            -0.021587111055850983,
            0.0013173249317333102,
            0.004905703943222761,
            0.013864660635590553,
            -0.01800166815519333,
            0.022562319412827492,
            -0.019418736919760704,
            0.016693340614438057,
            0.021792219951748848,
            0.009666156023740768,
            0.022762402892112732,
            0.009333519265055656,
            -0.018017081543803215,
            0.011762254871428013,
            -0.020814970135688782,
            -0.012996556237339973,
            -0.01233435608446598,
            -0.010460667312145233,
            0.03727661073207855,
            0.025067254900932312,
            0.00080576614709571,
            0.008969707414507866,
            -0.028448082506656647,
            0.001671438803896308,
            0.010379715822637081,
            -0.058203551918268204,
            0.021113045513629913,
            0.011546540074050426,
            0.014060815796256065,
            -0.033240266144275665,
            0.018320031464099884,
            0.018826190382242203,
            -0.030511988326907158,
            -0.014700748957693577,
            0.020073674619197845,
            0.002678454853594303,
            1.2576238077599555e-05,
            -0.016742557287216187,
            -0.030678430572152138,
            -0.0013588967267423868,
            0.028131749480962753,
            0.0019341276492923498,
            0.02846161462366581,
            0.023323342204093933,
            -0.03373228758573532,
            -0.002400485333055258,
            -0.052094846963882446,
            -0.009558192454278469,
            0.028977766633033752,
            0.027726635336875916,
            -0.006039052736014128,
            0.00576395820826292,
            0.0005196438287384808,
            -0.033096179366111755,
            -0.02569468505680561,
            0.021108517423272133,
            0.01828850619494915,
            -0.001499485457316041,
            -0.0028503795620054007,
            0.02592381462454796,
            -0.01426748652011156,
            0.025749223306775093,
            -0.029855959117412567,
            -0.002378877019509673,
            -0.016909081488847733,
            -0.0061212279833853245,
            0.010868147946894169,
            -0.0060266233049333096,
            0.014771607704460621,
            0.009037533774971962,
            0.004973066505044699,
            0.01298093143850565,
            -0.009198658168315887,
            0.0230655949562788,
            -0.013160989619791508,
            0.01118897832930088,
            0.013448851183056831,
            -0.028036663308739662,
            0.023742016404867172,
            0.005551197566092014,
            -0.01989785023033619,
            -0.016506221145391464,
            -0.03528895974159241,
            -0.01280346978455782,
            0.008076372556388378,
            0.027826406061649323,
            -0.006935997866094112,
            -0.02396382763981819,
            0.022577520459890366,
            0.02126431092619896,
            0.03209858387708664,
            0.012806498445570469,
            0.00593217508867383,
            -0.006776587571948767,
            -0.046928904950618744,
            -0.04883034899830818,
            0.016875021159648895,
            0.006514147389680147,
            0.01643255725502968,
            -0.038955725729465485,
            0.024137699976563454,
            -0.01403056364506483,
            0.01298639178276062,
            -0.031214656308293343,
            -0.020119979977607727,
            0.02452431619167328,
            -0.006816747598350048,
            -0.021438714116811752,
            -0.00260365754365921,
            -0.05775197967886925,
            -0.0006986801163293421,
            0.01959390379488468,
            -0.00919184461236,
            -0.02055579237639904,
            -0.01419540774077177,
            -0.012440393678843975,
            -0.026124950498342514,
            -0.002410738728940487,
            -0.019271163269877434,
            -0.024259060621261597,
            -0.019497698172926903,
            -0.014126424677670002,
            -0.027863861992955208,
            -0.0028973000589758158,
            -0.026199398562312126,
            0.008283732458949089,
            0.027789097279310226,
            -0.016280556097626686,
            -0.014465847052633762,
            0.015856636688113213,
            -0.0022200827952474356,
            -0.015660235658288002,
            -0.04676045849919319,
            -0.005179117433726788,
            -0.012999328784644604,
            0.010540517047047615,
            0.029429582878947258,
            -0.017678938806056976,
            0.0004474639135878533,
            0.003185569541528821,
            -0.01599010080099106,
            0.029968325048685074,
            -0.015481901355087757,
            -0.027421405538916588,
            -0.005560315679758787,
            0.01614508032798767,
            -0.012905583716928959,
            -0.030723344534635544,
            0.014789620414376259,
            0.018050244078040123,
            -0.004090091213583946,
            0.02550572156906128,
            0.007011110894382,
            -0.02210514433681965,
            0.02117755450308323,
            -0.009669152088463306,
            -0.003165025031194091,
            -0.01687781885266304,
            0.02043948881328106,
            -0.015395993366837502,
            0.025866281241178513,
            -0.026024021208286285,
            -0.026320984587073326,
            -0.02665083296597004,
            0.004364792723208666,
            -0.008642582222819328,
            -0.03997711092233658,
            -0.010405060835182667,
            -0.027285071089863777,
            -0.0015778085216879845,
            -0.036534376442432404,
            0.01918545365333557,
            -0.017096921801567078,
            0.011875814758241177,
            -0.0007268705521710217,
            -0.008845129050314426,
            -0.005698940251022577,
            0.00225665420293808,
            -0.015131836757063866,
            0.020134713500738144,
            0.008853647857904434,
            -0.0352838970720768,
            0.008908265270292759,
            0.02334655076265335,
            0.029658833518624306,
            -0.007988168857991695,
            -0.006911799311637878,
            -0.011700501665472984,
            -0.002267817733809352,
            -0.03516126424074173,
            0.013034951873123646,
            -0.0009729721350595355,
            0.011174668557941914,
            0.029604613780975342,
            -0.024553710594773293,
            -0.016034726053476334,
            -0.009370540268719196,
            -0.014807486906647682,
            0.0117100914940238,
            -0.018697131425142288,
            0.00037742042331956327,
            -0.042116060853004456,
            -0.027128787711262703,
            -0.043142542243003845,
            0.004203363321721554,
            -0.006662909407168627,
            -0.021479109302163124,
            -0.011374838650226593,
            0.007876609452068806,
            0.053744878619909286,
            -0.010258357971906662,
            0.008316326886415482,
            0.006911586504429579,
            0.02212507836520672,
            -0.06835443526506424,
            -0.00701468251645565,
            0.01944323256611824,
            -0.014384990558028221,
            0.02096904069185257,
            0.0038417482282966375,
            -0.021515458822250366,
            -0.001979269552975893,
            -6.366733578033745e-05,
            -0.0507693737745285,
            0.0283376332372427,
            0.02897731587290764,
            -0.018041452392935753,
            -0.018149051815271378,
            -0.014195270836353302,
            0.027155259624123573,
            0.01743932254612446,
            0.03477420657873154,
            -0.02343060076236725,
            -0.05158533528447151,
            0.0078207952901721,
            -0.01371061708778143,
            -0.025487283244729042,
            0.03259637951850891,
            0.020545843988656998,
            0.009092134423553944,
            -0.0014434721088036895,
            0.026402849704027176,
            -0.02178914286196232,
            0.009352747350931168,
            0.03044445998966694,
            0.0012360219843685627,
            0.004869622178375721,
            0.01634652353823185,
            0.04164577275514603,
            -0.01961420476436615,
            0.0069809406995773315,
            -0.019681673496961594,
            0.016532383859157562,
            -0.028992468491196632,
            0.008940162137150764,
            0.014603137038648129,
            -0.03141438215970993,
            0.004391583614051342,
            -0.016761770471930504,
            -0.04372775927186012,
            0.033814914524555206,
            0.012682147324085236,
            0.02500457502901554,
            -0.010775684379041195,
            -0.030877256765961647,
            0.0031500482000410557,
            -0.004602361470460892,
            -0.022006450220942497,
            -0.03155145049095154,
            -0.036665141582489014,
            0.04613729193806648,
            -0.010498381219804287,
            -0.031831562519073486,
            0.028900915756821632,
            0.006289903074502945,
            0.006232558283954859,
            0.01708107255399227,
            -0.027010122314095497,
            -0.022857967764139175,
            -0.006488085258752108,
            0.011562356725335121,
            0.022959832102060318,
            0.0036006858572363853,
            -0.03283091261982918,
            -0.027867909520864487,
            -0.05043160170316696,
            -0.0008108445908874273,
            0.014027769677340984,
            -0.009515948593616486,
            -0.002008920069783926,
            0.006521951872855425,
            -0.04495152086019516,
            0.008582067675888538,
            0.0121688237413764,
            0.015139132738113403,
            0.02193460427224636,
            0.0029338200110942125,
            -0.008903434500098228,
            0.01678934134542942,
            -0.02207687869668007,
            -0.031079065054655075,
            -0.0002905269502662122,
            0.01913498528301716,
            0.01077400054782629,
            0.03237007558345795,
            0.015633737668395042,
            -0.00038290314842015505,
            0.0047991215251386166,
            -0.0069406405091285706,
            -0.007842941209673882,
            -0.019134312868118286,
            -0.02160031348466873,
            -0.002428752603009343,
            0.010029665194451809,
            -0.019072679802775383,
            0.02353849448263645,
            -0.03989088535308838,
            0.03214099630713463,
            0.002353177173063159,
            0.038161493837833405,
            -1.6072497601271607e-05,
            -0.009351073764264584,
            0.0060378024354577065,
            0.025324122980237007,
            -0.003667708719149232,
            0.01619308441877365,
            0.014286658726632595,
            -0.048961713910102844,
            0.027879297733306885,
            -0.018629688769578934,
            -0.06391233950853348,
            0.047444041818380356,
            -0.010462564416229725
        ],
        [
            0.032935746014118195,
            -0.002087051747366786,
            -0.013178946450352669,
            -0.014154823496937752,
            0.020003780722618103,
            0.020095916464924812,
            -0.03893185034394264,
            0.04371591657400131,
            -0.016583718359470367,
            -0.029611386358737946,
            -0.004266249481588602,
            0.027691906318068504,
            0.01873619481921196,
            0.0245766993612051,
            0.02624071203172207,
            -0.058416854590177536,
            -0.019085684791207314,
            -0.04340852051973343,
            -0.014152416959404945,
            -0.004579298198223114,
            -0.026472514495253563,
            0.0036216415464878082,
            0.0028736665844917297,
            0.022762538865208626,
            0.04114261642098427,
            -0.009714314714074135,
            0.007894361391663551,
            0.02233516238629818,
            -0.021784137934446335,
            0.0362061969935894,
            0.02457169070839882,
            -0.029643172398209572,
            -0.009716514497995377,
            -0.03504394739866257,
            0.00260517792776227,
            -0.003195946104824543,
            0.010559839196503162,
            -0.019238309934735298,
            0.025352835655212402,
            0.017432453110814095,
            0.019975893199443817,
            -0.007580464705824852,
            -0.016495972871780396,
            -0.03074106015264988,
            0.002644188469275832,
            -0.00977239478379488,
            -0.008999042212963104,
            0.002864862559363246,
            0.010793092660605907,
            0.013684051111340523,
            -0.015857631340622902,
            0.03610033169388771,
            0.014567339792847633,
            -0.05126234143972397,
            -0.016917916014790535,
            -0.003151595825329423,
            0.02374485321342945,
            0.018199197947978973,
            -0.025734219700098038,
            -0.0019165106350556016,
            -0.019956858828663826,
            0.0018666867399588227,
            0.02238662913441658,
            0.004436256363987923,
            0.022933203727006912,
            0.004817298147827387,
            -0.0442294217646122,
            -0.0059919897466897964,
            -0.014115407131612301,
            0.015949837863445282,
            0.03127780184149742,
            -0.0048112086951732635,
            -0.02619979716837406,
            -0.029958777129650116,
            -0.08015552163124084,
            0.05005524307489395,
            0.024374982342123985,
            -0.009246280416846275,
            -0.02274644747376442,
            0.006908350624144077,
            -0.015232903882861137,
            0.03313664719462395,
            -0.026659399271011353,
            0.002308848313987255,
            -0.011420723982155323,
            -0.01536745298653841,
            0.017873726785182953,
            -0.01859348639845848,
            -0.035085372626781464,
            0.01891549676656723,
            0.036453116685152054,
            -0.002293963450938463,
            0.005024274345487356,
            -0.004734777379781008,
            -0.004096013493835926,
            0.006045539863407612,
            0.004156859591603279,
            -0.010807003825902939,
            0.018552305176854134,
            -0.01919243484735489,
            0.017164332792162895,
            0.0009780755499377847,
            -0.02197306975722313,
            -0.036562930792570114,
            -0.013002108782529831,
            -0.038585059344768524,
            -0.009315255098044872,
            0.010855921544134617,
            0.02111823484301567,
            0.03450893238186836,
            -0.013476932421326637,
            -0.014699529856443405,
            -0.006876685656607151,
            -0.0037408238276839256,
            0.10325805842876434,
            -0.006771477870643139,
            0.029148271307349205,
            0.01046118326485157,
            -0.041316721588373184,
            0.009596631862223148,
            -0.023521069437265396,
            -0.012757777236402035,
            -0.06220369040966034,
            0.004174108151346445,
            -0.017390765249729156,
            0.027131877839565277,
            0.03332839161157608,
            0.0010443164501339197,
            -0.014375539496541023,
            -0.011976485140621662,
            0.0019645795691758394,
            -0.01659291423857212,
            0.011854536831378937,
            -0.04972504451870918,
            0.014727461151778698,
            0.029607439413666725,
            0.02064276859164238,
            -0.0242733396589756,
            0.002005778020247817,
            0.008137695491313934,
            0.01677381619811058,
            0.0004637341189663857,
            0.02536405250430107,
            -0.02342020533978939,
            -0.08409792929887772,
            0.006959258113056421,
            -0.008994292467832565,
            -0.016621218994259834,
            0.02521057240664959,
            0.029360683634877205,
            0.02595462277531624,
            0.01780031993985176,
            -0.023909656330943108,
            0.08888399600982666,
            0.030176198109984398,
            0.024381449446082115,
            0.02418545074760914,
            0.01664840616285801,
            -0.008533827029168606,
            -0.023588450625538826,
            0.027169562876224518,
            -0.009308413602411747,
            0.003236649092286825,
            -0.00959355104714632,
            -0.019967371597886086,
            -0.05310966446995735,
            0.0292622409760952,
            0.004872145131230354,
            -0.009973212145268917,
            -0.016996942460536957,
            0.02025540918111801,
            0.02217591181397438,
            0.012258811853826046,
            0.028900768607854843,
            0.023836009204387665,
            0.019717533141374588,
            -0.003116556676104665,
            0.02401808463037014,
            0.002744955476373434,
            -0.008675714023411274,
            -0.0033722142688930035,
            -0.01573801040649414,
            0.02844231389462948,
            -0.012636227533221245,
            0.018497038632631302,
            -0.02577206678688526,
            0.020597033202648163,
            0.006606029346585274,
            0.030545413494110107,
            0.020066814497113228,
            -0.041173726320266724,
            0.021915141493082047,
            -0.01080266386270523,
            0.0005450516473501921,
            -0.01700916700065136,
            0.024291543290019035,
            -0.01095171645283699,
            0.009169629774987698,
            -0.013550475239753723,
            0.012994888238608837,
            -0.00641056289896369,
            -0.006952345371246338,
            0.013682729564607143,
            -0.02036960981786251,
            -0.02116730995476246,
            0.03977351263165474,
            0.02694256603717804,
            -0.04158575087785721,
            -0.009015618823468685,
            0.023541806265711784,
            0.0065077077597379684,
            0.04958279803395271,
            0.02030414342880249,
            0.006340941414237022,
            -0.02555759809911251,
            0.01925036497414112,
            0.006499953102320433,
            -0.008446892723441124,
            0.012441962026059628,
            0.023935437202453613,
            -0.0350387804210186,
            -0.013327598571777344,
            0.009542522951960564,
            0.0318058580160141,
            0.03372335433959961,
            0.0786023959517479,
            0.02953827753663063,
            -0.023538751527667046,
            0.005710194818675518,
            0.017624203115701675,
            -0.025159738957881927,
            0.03376273065805435,
            -4.207892925478518e-05,
            -0.05069422721862793,
            0.035593096166849136,
            0.009235561825335026,
            -0.018729859963059425,
            0.008003459312021732,
            0.02166351117193699,
            -0.005422772374004126,
            0.011944734491407871,
            -0.002204766497015953,
            -0.002412527333945036,
            -0.02163911797106266,
            0.002738203387707472,
            0.016324631869792938,
            0.03732258826494217,
            0.0017914773197844625,
            0.010543256998062134,
            0.03799305856227875,
            0.00607395451515913,
            -0.026457088068127632,
            0.021328911185264587,
            0.002461980329826474,
            -0.030506068840622902,
            -0.022930122911930084,
            0.01503447350114584,
            -0.07249986380338669,
            0.03236931189894676,
            0.049906011670827866,
            0.00639380095526576,
            -0.010244771838188171,
            -0.008232571184635162,
            0.013337614946067333,
            -0.02476741001009941,
            0.028406266123056412,
            -0.029818590730428696,
            0.02822035923600197,
            0.018239829689264297,
            0.03189583122730255,
            -0.01599147915840149,
            -0.008779804222285748,
            -0.03205714002251625,
            0.007587332744151354,
            -0.03310292959213257,
            0.015841631218791008,
            0.027529889717698097,
            0.0041221440769732,
            0.012890268117189407,
            0.022596029564738274,
            0.012928563170135021,
            -0.028790777549147606,
            0.0041543422266840935,
            0.06310057640075684,
            0.010593489743769169,
            -0.01660032756626606,
            0.010601542890071869,
            0.06923422962427139,
            -0.01891832984983921,
            0.0019170682644471526,
            -0.02502325363457203,
            0.0087722297757864,
            0.016357913613319397,
            -0.0071482425555586815,
            -0.03501402214169502,
            0.004578731022775173,
            0.022750986739993095,
            0.02089455910027027,
            -0.02921752631664276,
            -0.03007257916033268,
            0.017511438578367233,
            -0.03576090931892395,
            0.024746987968683243,
            0.025896620005369186,
            -0.013443874195218086,
            -0.003077056724578142,
            0.01245634164661169,
            0.028242550790309906,
            0.020354323089122772,
            -0.03368718922138214,
            0.0012368959141895175,
            0.009011675603687763,
            0.021598950028419495,
            -0.02526669017970562,
            0.026742178946733475,
            0.006828382145613432,
            -0.019150037318468094,
            -0.019436966627836227,
            -0.006064100190997124,
            -0.017405224964022636,
            -0.016632454469799995,
            0.04309246689081192,
            0.014208446256816387,
            0.001021277392283082,
            -0.057861726731061935,
            -0.04985817149281502,
            -0.035310134291648865,
            0.016271568834781647,
            -0.024528292939066887,
            0.00022032034758012742,
            -0.023935401812195778,
            -0.0077240075916051865,
            0.012464839965105057,
            0.008895727805793285,
            0.017511343583464622,
            -0.004079440608620644,
            0.019307570531964302,
            -0.011159216985106468,
            0.038167379796504974,
            -0.006612114608287811,
            0.03679357096552849,
            0.004211743362247944,
            -0.007544092368334532,
            0.009880788624286652,
            -0.007276383228600025,
            -0.00700540654361248,
            0.002115881536155939,
            -0.009229563176631927,
            0.026464801281690598,
            0.024386802688241005,
            -0.010648055002093315,
            0.05095324665307999,
            0.02891084924340248,
            0.0022615056950598955,
            -0.018627502024173737,
            -0.02458188682794571,
            0.03685028478503227,
            -0.018843993544578552,
            -0.015865202993154526,
            -0.021660394966602325,
            -0.01504525076597929,
            -0.020293181762099266,
            0.004399148281663656,
            -0.01990594156086445,
            -0.026406357064843178,
            0.008455385453999043,
            -0.04359231889247894,
            -0.02774510346353054,
            0.017938833683729172,
            0.010331588797271252,
            -0.0006128718378022313,
            0.01784622110426426,
            -0.028184479102492332,
            -0.0016708907205611467,
            0.005100395530462265,
            0.021828074008226395,
            -0.02735982835292816,
            0.01055356115102768,
            0.01874295249581337,
            -0.03165147826075554,
            -0.03574449568986893,
            0.00192455283831805,
            -0.0014652597019448876,
            0.014488439075648785,
            0.015193350613117218,
            0.013580831699073315,
            -0.013425363227725029,
            -0.006560771260410547,
            0.012264249846339226,
            0.016126763075590134,
            0.004154536407440901,
            0.023792371153831482,
            0.013650384731590748,
            0.01475189346820116,
            -0.048311203718185425,
            0.021402638405561447,
            0.0026545741129666567,
            0.01592979021370411,
            0.0225827693939209,
            0.046299662441015244,
            -0.004055604804307222,
            -0.022537551820278168,
            -0.010115914046764374,
            -0.008044485002756119,
            -0.013904215767979622,
            -0.006727824918925762,
            -0.003060800489038229,
            -0.00019352015806362033,
            0.015831762924790382,
            -0.007848307490348816,
            0.03026716783642769,
            -0.02579677850008011,
            -0.02624010108411312,
            0.03721357136964798,
            -0.01715744286775589,
            0.018698800355196,
            0.01176485326141119,
            0.017435366287827492,
            0.030697226524353027,
            -0.0058957235887646675,
            -0.0007361061288975179,
            -0.0020905679557472467,
            0.01647651568055153,
            0.019675442948937416,
            0.02042018622159958,
            0.011381532996892929,
            -0.019790828227996826,
            0.02535909041762352,
            0.010972014628350735,
            0.026414766907691956,
            0.011558860540390015,
            -0.02839524671435356,
            -0.0027178716845810413,
            -0.020276090130209923,
            -0.011850318871438503,
            -0.050603773444890976,
            0.022805484011769295,
            -0.009391115978360176,
            -0.017592374235391617,
            0.01044333353638649,
            -0.07809989154338837,
            0.03320140019059181,
            0.037081029266119,
            -0.01211142260581255,
            -0.002549148863181472,
            -0.0013348773354664445,
            0.00675546657294035,
            0.08288384228944778,
            -0.01041774544864893,
            -0.00048825808335095644,
            -0.035825032740831375,
            -0.010919544845819473,
            -0.01953830197453499,
            0.0220155268907547,
            -0.04799411818385124,
            0.017723988741636276,
            -0.024769416078925133,
            -0.02308233641088009,
            -0.04030715674161911,
            -0.024249354377388954,
            -0.02112051472067833,
            0.01696707494556904,
            -0.03792819753289223,
            -0.03348132222890854,
            -0.0064831082709133625,
            0.011901524849236012,
            -0.0023168509360402822,
            0.0071170153096318245,
            -0.004212124738842249,
            0.023071223869919777,
            -0.02403016947209835,
            -0.01885547675192356,
            -0.02027450129389763,
            -0.03494666516780853,
            -0.003487943671643734,
            -0.028770167380571365,
            0.00946553610265255,
            -0.04566004499793053,
            0.0115713682025671,
            0.02267845720052719,
            0.004635124932974577,
            -0.012068629264831543,
            0.01664775237441063,
            -0.03040173090994358,
            0.0047240606509149075,
            -0.025596655905246735,
            -0.019515521824359894,
            0.02818036451935768,
            0.08893302083015442,
            0.009727521799504757,
            0.03756643831729889,
            0.003939351998269558,
            0.0041922577656805515,
            -0.002368431305512786,
            -0.0948447436094284,
            -0.013140333816409111,
            0.0539872869849205,
            -0.006013013888150454,
            0.013801212422549725,
            0.017660075798630714,
            -0.013047507032752037,
            0.021883852779865265,
            0.015674514696002007,
            0.03792122006416321,
            -0.03215451538562775,
            -0.007239691447466612,
            0.002886375179514289,
            -0.035186417400836945,
            -0.013217906467616558,
            -0.026667455211281776,
            -0.014442679472267628,
            -0.016122452914714813,
            -0.010372303426265717,
            -0.01964828185737133,
            0.010984203778207302,
            0.007162582594901323,
            -0.026257509365677834,
            0.02080627717077732,
            0.01075584627687931,
            -0.02459404245018959,
            0.008971959352493286,
            -0.013451472856104374,
            0.029237069189548492,
            -0.017517078667879105,
            -0.011808854527771473,
            0.030616024509072304,
            0.036163873970508575,
            0.007198335137218237,
            -0.014270275831222534,
            0.02755315974354744,
            0.009485185146331787,
            0.032561443746089935,
            0.03170455992221832,
            0.023258700966835022,
            0.013565179891884327,
            0.028207048773765564,
            0.030106423422694206,
            0.02902371436357498,
            0.03587448224425316,
            -0.004710000939667225,
            -0.01918904297053814,
            -0.0033151062671095133,
            0.009259223937988281,
            0.03182785212993622,
            0.010252286680042744,
            -0.024255018681287766,
            -0.01073992345482111,
            0.020500358194112778,
            0.015368379652500153,
            -0.03784696385264397,
            6.360485713230446e-05,
            0.022780362516641617,
            -0.021585039794445038,
            0.035665325820446014,
            -0.03967713192105293,
            -0.011155521497130394,
            0.012607842683792114,
            0.007792272605001926,
            0.016427138820290565,
            0.02755349688231945,
            -0.021265888586640358,
            0.016540978103876114,
            -0.01826067455112934,
            0.06447368860244751,
            0.013913716189563274,
            -0.001855484559200704,
            0.023998243734240532,
            0.017975451424717903,
            -0.045988116413354874,
            0.003257510717958212,
            -0.04451239854097366,
            -0.00044870845158584416,
            0.009388440288603306,
            -0.01093536801636219,
            0.0017273819539695978,
            -0.024328220635652542,
            0.030136456713080406,
            0.006348791532218456,
            0.009965169243514538,
            0.020801972597837448,
            -0.007390888873487711,
            -0.0015397194074466825,
            0.03361186012625694,
            0.01800793968141079,
            0.0036863391287624836,
            0.0151557931676507,
            0.0022648402955383062,
            0.016053281724452972,
            -0.022266017273068428,
            0.030436236411333084,
            -0.02219421975314617,
            -0.021831242367625237,
            -0.016116410493850708,
            -0.07461576163768768,
            -0.016755787655711174,
            -0.03686099126935005,
            0.010478121228516102,
            -0.01473013125360012,
            0.0012730802409350872,
            -0.04337553679943085,
            -0.045853666961193085,
            -0.04447055980563164,
            0.002624609274789691,
            -0.015628425404429436,
            0.007894706912338734,
            0.004586749244481325,
            0.00813122559338808,
            0.0022082424256950617,
            0.06919730454683304,
            -0.016189735382795334,
            -0.012542987242341042,
            -0.022032836452126503,
            0.007388079538941383,
            -0.020740266889333725,
            -0.018729805946350098,
            0.04528353735804558,
            0.011688305996358395,
            0.007389264181256294,
            0.025176605209708214,
            -0.004326977301388979,
            0.04862716421484947,
            0.00641437154263258,
            0.023335691541433334,
            0.0020606694743037224,
            -0.002089220564812422,
            0.03131067380309105,
            -0.02074155956506729,
            -0.010553454048931599,
            0.021322138607501984,
            0.01992475986480713,
            -0.00807501096278429,
            0.0164178516715765,
            0.022094689309597015,
            -0.01556399930268526,
            -0.007749456446617842,
            0.01652236469089985,
            0.02727355808019638,
            -0.0078143160790205,
            0.025677720084786415,
            -0.03309196978807449,
            -0.015970289707183838,
            -0.016119038686156273,
            0.04627201706171036,
            -0.06023703143000603,
            -0.028799371793866158,
            -0.008394602686166763,
            0.03577320650219917,
            -0.020631931722164154,
            -0.02669386938214302,
            -0.018971359357237816,
            -0.011629496701061726,
            -0.025574320927262306,
            0.026901187375187874,
            0.01679850183427334,
            0.0015548281371593475,
            -0.010112510062754154,
            -0.06234688684344292,
            -0.035728536546230316,
            -0.006404724903404713,
            0.010759249329566956,
            0.01304781623184681,
            -0.012579312548041344,
            -0.020431729033589363,
            0.05411822721362114,
            -0.009023269638419151,
            -0.0014262081822380424,
            0.020396023988723755,
            0.013992119580507278,
            0.0071467743255198,
            0.0028304404113441706,
            0.04467717185616493,
            -0.061346013098955154,
            0.014582589268684387,
            -0.014356506988406181,
            -0.01891787350177765,
            -0.03559796139597893,
            -0.025409864261746407,
            -0.03222530335187912,
            0.02109615132212639,
            0.004771683365106583,
            0.009756160899996758,
            0.02367778681218624,
            -0.02816898003220558,
            0.036164458841085434,
            0.024371813982725143,
            -0.024454569444060326,
            0.013603651896119118,
            0.024424469098448753,
            -0.00014890669262968004,
            -0.019464567303657532,
            0.09243877977132797,
            -0.05485164374113083,
            0.0007239478291012347,
            0.015044283121824265,
            -0.004941020160913467,
            0.008048809133470058,
            -0.022110164165496826,
            -0.027584031224250793,
            0.020973652601242065,
            0.00848420336842537,
            0.004693004302680492,
            0.04189744219183922,
            -0.01848842389881611,
            -0.007699925452470779,
            -0.03419990465044975,
            -0.013362823985517025,
            -0.015097463503479958,
            -0.03882991150021553,
            0.004012948367744684,
            -0.002356328535825014,
            -0.021704787388443947,
            0.031579576432704926,
            0.004578797146677971,
            0.018968096002936363,
            -0.0061821783892810345,
            0.030533451586961746,
            0.011224388144910336,
            0.011933961883187294,
            -0.0322585366666317,
            -0.012015174143016338,
            0.002559448592364788,
            -0.01122642308473587,
            0.0006138838944025338,
            0.019172199070453644,
            -0.012083555571734905,
            -0.01976635493338108,
            0.031761325895786285,
            -0.013447863981127739,
            0.01224321685731411,
            -0.024019166827201843,
            -0.03594136983156204,
            -0.02351248823106289,
            -0.0010181261459365487,
            0.001959343208000064,
            -0.023905431851744652,
            -0.0012084913905709982,
            0.01374417170882225,
            -0.01379626989364624,
            0.025890855118632317,
            0.027352875098586082,
            -0.0038284531328827143,
            -0.04090391844511032,
            0.005024380050599575,
            -0.005918615031987429,
            0.00858330074697733,
            -0.03038514405488968,
            0.06167740747332573,
            -0.005005396902561188,
            -0.004257894586771727,
            -0.014569839462637901,
            0.023274580016732216,
            -0.02292538806796074,
            0.00011638174328254536,
            -0.008810294792056084,
            -0.008605294860899448,
            -0.010405468754470348,
            0.012037545442581177,
            0.013435890898108482,
            0.03921625018119812,
            0.028731700032949448,
            -6.031802695360966e-05,
            -0.0742228627204895,
            0.0065953717567026615,
            -0.00322990445420146,
            -0.0011361303040757775,
            -0.0560745894908905,
            -0.021296706050634384,
            0.004173350054770708,
            0.015340232290327549,
            -0.041279856115579605,
            0.02279307134449482,
            -0.010752510279417038,
            -0.031185423955321312,
            -0.01767825148999691,
            -0.0007720607100054622,
            -0.00974234752357006,
            -0.013565441593527794,
            0.00226975092664361,
            -0.012680331245064735,
            -0.014275162480771542,
            -0.02710300125181675,
            -0.026198601350188255,
            0.004920771345496178,
            0.05561593547463417,
            -0.013397539034485817,
            0.02943510003387928,
            -0.06984802335500717,
            -0.026359450072050095,
            0.011747176758944988,
            -0.017127089202404022,
            -0.05400378257036209,
            -0.008352654054760933,
            0.01610386185348034,
            -0.004427664447575808,
            0.0006299562519416213,
            0.0032670171931385994,
            -0.0018931060330942273,
            -0.04347675293684006,
            0.008010409772396088,
            -0.004529924131929874,
            0.058525510132312775,
            0.015454874373972416,
            0.0223024170845747,
            -0.0043474724516272545,
            -0.012060743756592274,
            -0.014165308326482773,
            -0.02981632761657238,
            -0.03109217993915081,
            0.03709670901298523,
            -0.04808685928583145,
            0.018060628324747086,
            0.014114690944552422,
            -0.01854066178202629,
            0.0201541967689991,
            0.04427512735128403,
            0.013392233289778233,
            -0.02362166903913021,
            0.015322582796216011,
            -0.027116376906633377,
            -0.022264976054430008,
            0.015016031451523304,
            0.019402731209993362,
            -0.018819265067577362,
            -0.008803284727036953,
            -0.037579696625471115,
            0.02126595564186573,
            -0.04814155399799347,
            0.029687773436307907,
            -0.02204039879143238,
            0.014283564873039722,
            -0.013861092738807201,
            0.005642694421112537,
            -0.001663816045038402,
            -0.03897850960493088,
            0.0072282603941857815,
            0.021707409992814064,
            -0.028445851057767868,
            -0.014639649540185928,
            -0.010115339420735836,
            -0.028430668637156487,
            -0.053725212812423706,
            0.013723430223762989,
            0.008748534135520458,
            -0.005675421562045813,
            -0.020090097561478615,
            0.002479756250977516,
            0.003314030123874545,
            0.002242631744593382,
            0.019275624305009842,
            -0.0006639165803790092,
            -0.016275757923722267,
            -0.014019849710166454,
            -0.08292139321565628,
            -0.024383554235100746,
            0.05584394559264183,
            0.005720087327063084,
            0.020524611696600914,
            -0.03672953322529793,
            -0.019244154915213585,
            0.030319638550281525,
            0.03297354653477669,
            -0.012310253456234932,
            0.03612056374549866,
            -0.024506062269210815,
            0.019090929999947548,
            0.017678983509540558,
            0.011476368643343449,
            -0.022801099345088005,
            0.003608159953728318,
            -0.009533235803246498,
            -0.01126429159194231,
            0.007560274098068476,
            0.0024916036054491997,
            -0.030167117714881897,
            -0.006544630043208599,
            -0.009566839784383774,
            -0.0022723788861185312,
            -0.051957834511995316,
            0.002802107250317931,
            -0.028131557628512383,
            -0.01475367322564125,
            0.019013432785868645,
            -0.006451623514294624,
            -0.022740107029676437,
            0.012660454958677292,
            0.012758112512528896,
            0.04016879200935364,
            -0.0019184004049748182,
            0.007685646414756775,
            0.026199946179986,
            0.001681242953054607,
            -0.019956568256020546,
            -0.008993995375931263,
            0.025495389476418495,
            -0.028388382866978645,
            -0.00902889296412468,
            -0.015313895419239998,
            -0.009947029873728752,
            0.0041227154433727264,
            0.04686588793992996,
            0.01765020564198494,
            0.04139374941587448,
            -0.011112792417407036,
            -0.030590778216719627,
            -0.011424378491938114,
            -0.02540097013115883,
            -0.013332011178135872,
            -0.027065660804510117,
            0.01793058216571808,
            -0.00480344844982028,
            0.0083825858309865,
            -0.00123413756955415,
            -0.005801811348646879,
            -0.0009695104090496898,
            0.03066851571202278,
            -0.02749321423470974,
            0.01532800868153572,
            0.0060071502812206745,
            0.027854403480887413,
            0.026686126366257668,
            -0.030147042125463486,
            -0.032216060906648636,
            0.028731290251016617,
            -0.05014108121395111,
            -0.019134890288114548,
            0.013259408064186573,
            -0.024859784170985222,
            0.033198002725839615,
            -0.0151359299197793,
            0.060749601572752,
            0.020643386989831924,
            -0.0013348041102290154,
            -0.013069658540189266,
            -0.012933369725942612,
            -0.02660740539431572,
            0.026773817837238312,
            -0.036777570843696594,
            -0.008174318820238113,
            -0.0016072452999651432,
            -0.030350735411047935,
            -0.0031066241208463907,
            0.026560358703136444,
            -0.049300048500299454,
            -0.025061283260583878,
            0.021626578643918037,
            -0.0077241635881364346,
            0.004651503637433052,
            0.023326246067881584,
            -0.004304935690015554,
            0.0053034452721476555,
            -0.0784890279173851,
            0.029849685728549957,
            0.006827224511653185,
            0.0205005444586277,
            0.05288270488381386,
            -0.00578863313421607,
            0.023505788296461105,
            -0.013654286041855812,
            -0.004627862945199013,
            0.02705179713666439,
            -0.005575963295996189,
            0.024806464090943336,
            -0.008586963638663292,
            0.016577547416090965,
            0.031377118080854416,
            -0.0001943819661391899,
            0.012035351246595383,
            -0.0073189400136470795,
            -0.04047846794128418,
            0.008107026107609272,
            0.03173947334289551,
            -0.015028678812086582,
            0.004482340533286333,
            -0.005181126296520233,
            0.03318346291780472,
            -0.01561621855944395,
            0.04957322031259537,
            -0.032579049468040466,
            -0.00509480619803071,
            -0.018414044752717018,
            0.010624876245856285,
            -0.001764766639098525,
            0.06522653996944427,
            0.0023896314669400454,
            -0.04508829861879349,
            -0.004840453155338764,
            0.020811283960938454,
            -0.0348973423242569,
            -0.029404103755950928,
            -0.009868809022009373,
            -0.0068837720900774,
            0.002638384001329541,
            -0.005557252559810877,
            0.0008709392859600484,
            -0.01859857887029648,
            -0.027203762903809547,
            0.03265406936407089,
            -0.00936825480312109,
            -0.04552497714757919,
            0.02492908574640751,
            -0.0013090955326333642,
            0.019253361970186234,
            0.03720160573720932,
            0.02607440948486328,
            0.033251479268074036,
            -0.028800062835216522,
            0.02554175816476345,
            0.005487122572958469,
            0.014228454791009426,
            -0.006791043560951948,
            -0.010855237953364849,
            -0.0358077697455883,
            0.024953553453087807,
            -0.04912301525473595,
            -0.01992848515510559,
            -0.0006685623666271567,
            -0.02893555350601673,
            0.03451259434223175,
            -0.03448858484625816,
            0.00514880521222949,
            0.03410077840089798,
            0.027891630306839943,
            -0.03748077526688576,
            -0.009665017947554588
        ],
        [
            -0.0030160557944327593,
            0.009487716481089592,
            0.03373178467154503,
            0.015297370962798595,
            -0.004050363786518574,
            -0.022573523223400116,
            0.02023680880665779,
            -0.004862543661147356,
            -0.009967653080821037,
            0.022157827392220497,
            0.01417587697505951,
            -0.01608470268547535,
            0.014800009317696095,
            0.006410973146557808,
            -0.03651846945285797,
            -0.015728577971458435,
            -0.017948713153600693,
            -0.024332182481884956,
            0.019659724086523056,
            0.029282787814736366,
            -0.027100393548607826,
            0.014017465524375439,
            0.0411532036960125,
            -0.025440091267228127,
            0.03652181848883629,
            -0.030138224363327026,
            -0.0078389598056674,
            0.003051169915124774,
            0.011174638755619526,
            0.025493944063782692,
            0.0027488614432513714,
            0.01627812348306179,
            -0.018574252724647522,
            0.03515389934182167,
            0.006534320767968893,
            0.015517419204115868,
            0.029086491093039513,
            0.0032782270573079586,
            -0.0003849648346658796,
            -0.0035922338720411062,
            0.03078392893075943,
            0.04931385815143585,
            0.026180462911725044,
            -0.026227083057165146,
            0.023765742778778076,
            0.0476817712187767,
            -0.031369518488645554,
            -0.013201804831624031,
            0.009769441559910774,
            0.04697379097342491,
            -0.009033157490193844,
            0.005300972610712051,
            0.04129637032747269,
            0.02116485685110092,
            -0.022785406559705734,
            -0.0006859780987724662,
            0.029376497492194176,
            -8.436772623099387e-05,
            0.007663775235414505,
            -0.01184196025133133,
            -0.015423433855175972,
            -0.02229215018451214,
            -0.0008411032613366842,
            0.017447689548134804,
            0.017428193241357803,
            -0.014824400655925274,
            0.03941452130675316,
            0.015206421725451946,
            0.022823624312877655,
            0.014208057895302773,
            0.009174371138215065,
            0.004192775581032038,
            0.008910946547985077,
            0.006791473366320133,
            0.006866694428026676,
            -0.011295140720903873,
            -0.011165480129420757,
            -0.016962621361017227,
            -0.02125772275030613,
            0.026498617604374886,
            -0.012532305903732777,
            -0.0023192584048956633,
            -0.018974069505929947,
            0.0022008877713233232,
            0.03745422139763832,
            -0.02692415378987789,
            -0.023885658010840416,
            0.03045058064162731,
            0.035823240876197815,
            -0.01474877167493105,
            0.02314099855720997,
            -0.010070772841572762,
            0.000248182361247018,
            0.03505643084645271,
            -0.01024896465241909,
            -0.0028332753572613,
            0.006480986252427101,
            0.027783658355474472,
            0.030554218217730522,
            0.0025582406669855118,
            -0.028336603194475174,
            -0.0024784773122519255,
            0.0022033352870494127,
            -0.011614589020609856,
            0.0033263214863836765,
            -0.013888245448470116,
            0.028294390067458153,
            0.008034621365368366,
            -0.027955923229455948,
            0.025660233572125435,
            0.008989987894892693,
            -0.03067886084318161,
            0.011065791361033916,
            0.0016637487569823861,
            -0.03337349370121956,
            -0.014414110220968723,
            -0.0023416588082909584,
            -0.0027928436174988747,
            -0.03433782979846001,
            0.010898998007178307,
            0.02224665693938732,
            0.019880253821611404,
            0.01593923382461071,
            0.02763136848807335,
            0.0064767468720674515,
            0.02145717293024063,
            -0.028158942237496376,
            0.005213556345552206,
            0.0021397671662271023,
            -0.02295285277068615,
            -0.028869643807411194,
            0.013198154047131538,
            0.009988687932491302,
            -0.017947878688573837,
            -0.0223854910582304,
            -0.01984725147485733,
            -0.004144290462136269,
            -0.028359055519104004,
            0.013088004663586617,
            0.004247650038450956,
            -0.01992453821003437,
            -0.00658435607329011,
            -0.01108111534267664,
            -0.005664079915732145,
            -0.011208507232367992,
            -0.03711790218949318,
            -0.009686426259577274,
            0.008305616676807404,
            -0.02271489053964615,
            0.006024408154189587,
            -0.02185934968292713,
            -0.0325082428753376,
            0.0021444540470838547,
            -0.03182686120271683,
            0.009056368842720985,
            0.0394228920340538,
            -0.016271336004137993,
            -0.007041875272989273,
            -0.004527670331299305,
            -0.005479422397911549,
            0.014767368324100971,
            -0.020282747223973274,
            -0.016951892524957657,
            0.02483120933175087,
            0.014675159938633442,
            0.0080098956823349,
            -0.012796398252248764,
            0.02944866195321083,
            0.01387245487421751,
            -0.004452102351933718,
            0.00913030095398426,
            -0.02719726786017418,
            0.0186681617051363,
            -0.015319975093007088,
            -0.03172741457819939,
            -0.02499019354581833,
            0.023924261331558228,
            -0.0038351549301296473,
            0.010692179203033447,
            -0.02647150494158268,
            -0.0018481338629499078,
            0.02165151946246624,
            -0.007317455485463142,
            -0.02073327824473381,
            0.002701908815652132,
            0.0014499499229714274,
            0.008500064723193645,
            -0.023163896054029465,
            -0.013503224588930607,
            0.026365431025624275,
            0.026010796427726746,
            0.01340216863900423,
            0.019469670951366425,
            -0.0013224974973127246,
            0.021837085485458374,
            0.029735982418060303,
            -0.027840206399559975,
            -0.013941871002316475,
            -0.02531564049422741,
            -0.016162749379873276,
            -0.005708663258701563,
            0.024439716711640358,
            -0.03405702859163284,
            0.01941501721739769,
            0.021605122834444046,
            -0.037698790431022644,
            0.036760393530130386,
            -0.019889282062649727,
            -0.007223078049719334,
            0.0036113278474658728,
            0.014176668599247932,
            0.02062440663576126,
            0.026339543983340263,
            -0.013601160608232021,
            -0.01914653368294239,
            -0.00746462307870388,
            -0.0009169936529360712,
            -0.01568143628537655,
            -0.009596023708581924,
            -0.0018139105523005128,
            -0.012591592967510223,
            0.012256264686584473,
            -0.02336150035262108,
            0.028915226459503174,
            -0.014789476990699768,
            -0.02774614281952381,
            0.024024274200201035,
            -0.007978576235473156,
            0.04697568342089653,
            -0.0020692148245871067,
            0.018690459430217743,
            -0.02407197281718254,
            -0.025498198345303535,
            0.02370638959109783,
            -0.0055152117274701595,
            -0.01794510707259178,
            -0.06142931804060936,
            -0.0035427885595709085,
            -0.018388867378234863,
            -0.016263693571090698,
            0.0165256280452013,
            -0.011704400181770325,
            0.008696471340954304,
            0.013622802682220936,
            0.011490531265735626,
            -0.023293599486351013,
            -0.01836659014225006,
            -0.03096565045416355,
            0.004033008124679327,
            -0.011541611514985561,
            0.03213616833090782,
            0.004993649199604988,
            0.017786022275686264,
            -0.014562622644007206,
            0.006863574963063002,
            0.007645570673048496,
            0.03467205539345741,
            -0.040221937000751495,
            0.012904529459774494,
            -0.015956418588757515,
            -0.012011770159006119,
            -0.011754397302865982,
            0.03400546312332153,
            -0.009747520089149475,
            -0.03348587080836296,
            -0.02645917236804962,
            -0.0036731455475091934,
            0.016645951196551323,
            0.01524396799504757,
            0.012119815684854984,
            -0.032899387180805206,
            0.007071230094879866,
            0.012811535969376564,
            0.007734888233244419,
            -0.0157379861921072,
            0.015740295872092247,
            0.0031961279455572367,
            0.02516971342265606,
            -0.02442326210439205,
            -0.010958308354020119,
            0.02997754141688347,
            0.03197102248668671,
            0.009640909731388092,
            -0.016889838501811028,
            -0.011232448741793633,
            -0.02479168400168419,
            0.014081211760640144,
            -0.006809507962316275,
            0.005044386722147465,
            -0.023576410487294197,
            0.025719739496707916,
            0.021439826115965843,
            -0.014849487692117691,
            0.007061804179102182,
            -0.010531997308135033,
            0.0320584736764431,
            -0.021752363070845604,
            -0.0049833329394459724,
            -0.0004187056329101324,
            0.0092836394906044,
            0.020928869023919106,
            0.02663206309080124,
            -0.03156227618455887,
            -0.016866963356733322,
            0.033950693905353546,
            -0.008349736221134663,
            0.0023012314923107624,
            -0.026691406965255737,
            0.0319490060210228,
            0.025675244629383087,
            0.025501342490315437,
            0.018839677795767784,
            -0.016715282574295998,
            0.023170040920376778,
            -0.014101606793701649,
            -0.019428115338087082,
            0.018338248133659363,
            0.01842804253101349,
            0.0159289613366127,
            0.005486621055752039,
            0.007042536977678537,
            -0.03125401958823204,
            -0.011712408624589443,
            0.02709539234638214,
            -0.007981957867741585,
            -0.012457085773348808,
            -0.001439118874259293,
            -0.013527472503483295,
            0.03637358173727989,
            0.005508315749466419,
            -0.03493569791316986,
            0.021550701931118965,
            -0.014661503955721855,
            0.028119873255491257,
            0.0024758719373494387,
            -0.022156432271003723,
            -0.017416831105947495,
            -0.02344067208468914,
            -0.018500518053770065,
            -0.006394409574568272,
            0.0286512803286314,
            0.008668115362524986,
            0.030036328360438347,
            -0.01055945549160242,
            0.03187364712357521,
            -0.043367136269807816,
            -0.0058739627711474895,
            -0.007853238843381405,
            0.018604544922709465,
            -0.002952738432213664,
            0.0062897056341171265,
            -0.035058245062828064,
            -0.003302919212728739,
            0.02999221719801426,
            -0.029311953112483025,
            -0.016560334712266922,
            -0.02726779319345951,
            0.0037772946525365114,
            -0.018583005294203758,
            0.004589340183883905,
            -0.007299112156033516,
            0.010123278945684433,
            -0.024179870262742043,
            0.02496848627924919,
            -0.004981886595487595,
            0.0041602314449846745,
            0.02368113026022911,
            0.031269390136003494,
            0.029505470767617226,
            0.005431408993899822,
            -0.0167231522500515,
            -0.010149894282221794,
            0.022221511229872704,
            -0.02053482085466385,
            0.001826687017455697,
            0.0188816636800766,
            0.03141697868704796,
            -0.012839988805353642,
            -0.01606729067862034,
            -0.007649130187928677,
            -0.03618120029568672,
            0.013877435587346554,
            0.012426113709807396,
            -0.005372209474444389,
            -0.0027010359335690737,
            0.03715721145272255,
            -0.0015381935518234968,
            0.020135438069701195,
            -0.009883125312626362,
            0.011437525041401386,
            -0.027927812188863754,
            0.0058192177675664425,
            0.0028878015000373125,
            -0.00343602173961699,
            -0.06964529305696487,
            0.01785474456846714,
            -0.004076655488461256,
            -0.013610302470624447,
            0.025615269318223,
            0.013033194467425346,
            -0.04412011429667473,
            0.019611503928899765,
            0.011120134964585304,
            0.011414849199354649,
            0.017538245767354965,
            0.021688312292099,
            -0.0011853603646159172,
            -0.02707461640238762,
            -0.03189843147993088,
            0.013569923117756844,
            -0.02286398597061634,
            -0.03154289722442627,
            0.04533371701836586,
            0.010997257195413113,
            0.011234060861170292,
            -0.03385714069008827,
            -0.007770955096930265,
            0.026251791045069695,
            -0.025629552081227303,
            -0.008161528035998344,
            0.0318024605512619,
            -0.013832014985382557,
            0.008961190469563007,
            0.017088517546653748,
            -0.025215554982423782,
            0.01648012548685074,
            0.032165270298719406,
            -0.03077184595167637,
            0.005356132984161377,
            -0.014197583310306072,
            0.025956695899367332,
            0.012734995223581791,
            -0.017611263319849968,
            -0.029103681445121765,
            -0.016278795897960663,
            6.596234015887603e-05,
            0.007683104369789362,
            -0.016780631616711617,
            -0.010136143304407597,
            0.036099404096603394,
            0.016397329047322273,
            0.03439008817076683,
            -0.009701366536319256,
            0.025738921016454697,
            0.0017678551375865936,
            0.00919992383569479,
            0.03287984058260918,
            0.06768739223480225,
            -0.010458927601575851,
            0.021670598536729813,
            -0.009558403864502907,
            0.014209217391908169,
            0.02824675664305687,
            0.03334995359182358,
            0.011759311892092228,
            0.02698567695915699,
            -0.013212578371167183,
            -0.005264212377369404,
            -0.0033042572904378176,
            0.008134166710078716,
            -0.0035416153259575367,
            0.012212689034640789,
            -0.0019872167613357306,
            0.015535766258835793,
            0.029707076027989388,
            0.02165638841688633,
            -0.004523071926087141,
            0.008958728052675724,
            0.01339678093791008,
            0.003823087317869067,
            -0.031244879588484764,
            0.022359944880008698,
            0.011847718618810177,
            -0.017518777400255203,
            0.009717361070215702,
            0.024747952818870544,
            0.013239851221442223,
            -0.020395826548337936,
            0.019025923684239388,
            0.0042623006738722324,
            -0.03217673301696777,
            -0.0010639067040756345,
            0.01679639331996441,
            -0.015002677217125893,
            0.028375091031193733,
            -0.030735475942492485,
            0.018470756709575653,
            0.019353602081537247,
            0.02051663212478161,
            0.032880473881959915,
            0.03297314792871475,
            0.0074979388155043125,
            0.0064825816079974174,
            0.013012723997235298,
            0.049793750047683716,
            0.025788506492972374,
            0.027694402262568474,
            -0.023570580407977104,
            0.025944747030735016,
            -0.011468752287328243,
            -0.001446072361432016,
            0.05403371900320053,
            0.011471270583570004,
            -0.03972737863659859,
            -0.022916771471500397,
            -0.005538163240998983,
            0.02653282694518566,
            -0.03007964789867401,
            -0.011044775135815144,
            0.020735614001750946,
            -0.0060824197717010975,
            -0.0015594101278111339,
            0.021743249148130417,
            -0.0029143986757844687,
            0.024342376738786697,
            -0.018719889223575592,
            -0.0034992487635463476,
            -0.03736778721213341,
            -0.006654673255980015,
            -0.014416067861020565,
            0.00847203191369772,
            -0.0018959204899147153,
            0.007368401158601046,
            -0.03179100528359413,
            0.01242348738014698,
            0.019609419628977776,
            0.028457459062337875,
            -0.00258257775567472,
            -0.027877165004611015,
            -0.00915455725044012,
            -0.024879388511180878,
            -0.011124716140329838,
            -0.006781860254704952,
            0.0175386480987072,
            0.016628144308924675,
            0.00647927587851882,
            0.016116948798298836,
            0.012100682593882084,
            -0.0013542934320867062,
            -0.0018392307683825493,
            0.017252538353204727,
            0.015898630023002625,
            0.023532303050160408,
            0.009198140352964401,
            0.03266390040516853,
            0.02711312100291252,
            0.02737419493496418,
            -0.022927504032850266,
            -0.022255875170230865,
            0.010335504077374935,
            -0.018700750544667244,
            0.0008919509127736092,
            0.025530165061354637,
            0.00289364461787045,
            0.008412452414631844,
            -0.011446837335824966,
            0.0030911134090274572,
            0.027959736064076424,
            0.014219612814486027,
            0.024520155042409897,
            0.028921248391270638,
            -0.0059799980372190475,
            0.021473214030265808,
            -0.013020682148635387,
            0.02201034314930439,
            -0.005958515219390392,
            0.007625021506100893,
            0.03403317183256149,
            0.01883503422141075,
            -0.034439317882061005,
            0.021880805492401123,
            -0.021304653957486153,
            -0.0013713088119402528,
            -0.013171039521694183,
            -0.0038718099240213633,
            0.035906169563531876,
            -0.0099356509745121,
            0.017884917557239532,
            0.0173272006213665,
            -0.014998769387602806,
            -0.005233678966760635,
            -0.027360226958990097,
            -0.01729305274784565,
            0.03862651810050011,
            -0.02859964407980442,
            0.023718321695923805,
            0.007499661296606064,
            0.012453265488147736,
            -0.003853942733258009,
            0.03001156449317932,
            0.00812860019505024,
            -0.01480373926460743,
            -0.002915184246376157,
            -0.035563115030527115,
            -0.01583290286362171,
            0.01719730533659458,
            0.02827541157603264,
            0.02572494000196457,
            -0.0013935231836512685,
            0.02202279306948185,
            -0.010152887552976608,
            -0.008533710613846779,
            0.04032247141003609,
            -0.01640036329627037,
            0.027932975441217422,
            0.012534545734524727,
            -0.013820469379425049,
            -0.024968057870864868,
            -0.01288256049156189,
            -0.013226954266428947,
            0.02351680025458336,
            0.00938844308257103,
            -0.01991898939013481,
            0.009601451456546783,
            -0.03165030851960182,
            0.03557403385639191,
            0.001711502904072404,
            0.02734646014869213,
            -0.0248152744024992,
            0.03772865608334541,
            0.004090165253728628,
            -0.01743203029036522,
            -0.017096735537052155,
            -0.014597669243812561,
            -0.017835427075624466,
            0.016206661239266396,
            -0.021918196231126785,
            0.024136343970894814,
            -0.008249695412814617,
            -0.016354484483599663,
            0.0315372571349144,
            -0.029564661905169487,
            0.015768731012940407,
            0.0031022606417536736,
            0.019886601716279984,
            -0.010680032894015312,
            -0.003372970037162304,
            0.017562629655003548,
            0.020832933485507965,
            -0.018314415588974953,
            0.015838196501135826,
            -0.017651768401265144,
            -0.025156619027256966,
            0.020591963082551956,
            0.008672899566590786,
            0.009686228819191456,
            -0.004831927828490734,
            0.021107209846377373,
            -0.02614704519510269,
            -0.015913771465420723,
            0.004887104965746403,
            0.03439832478761673,
            -0.006384318228811026,
            0.009362568147480488,
            -0.006600761786103249,
            0.0009239786304533482,
            0.007097792811691761,
            0.02099844627082348,
            0.010111585259437561,
            0.014912248589098454,
            -0.030609823763370514,
            -0.013266339898109436,
            -0.013014239259064198,
            0.026935458183288574,
            0.022388271987438202,
            -0.004042721353471279,
            -0.03519592434167862,
            0.02677631564438343,
            0.0339057557284832,
            0.01804116740822792,
            0.02368052303791046,
            -0.022961340844631195,
            -0.023518310859799385,
            -0.015535116195678711,
            -0.020671794191002846,
            -0.007256250362843275,
            0.02632342092692852,
            -0.00726050091907382,
            0.024930031970143318,
            -0.0007485686801373959,
            0.034533265978097916,
            0.005026180297136307,
            0.0014368952251970768,
            0.010538614355027676,
            -0.025307415053248405,
            -0.022817449644207954,
            -0.02270307019352913,
            -0.011516411788761616,
            0.01770068146288395,
            -0.01625930331647396,
            0.025619765743613243,
            0.026993876323103905,
            0.012768857181072235,
            -0.006672106683254242,
            0.03303489089012146,
            0.0022128771524876356,
            -0.023963069543242455,
            0.003455566242337227,
            8.325262024300173e-05,
            -0.018507059663534164,
            -0.0173658337444067,
            -0.05230041593313217,
            0.02566242776811123,
            0.02286325767636299,
            0.014446524903178215,
            0.0012280254159122705,
            0.023192577064037323,
            -0.010336126200854778,
            0.01617981493473053,
            -0.03584875911474228,
            5.647386205964722e-05,
            -0.014804435893893242,
            0.025949861854314804,
            0.001905963639728725,
            0.014047649689018726,
            -0.01737792417407036,
            -0.01260885875672102,
            0.0009213555022142828,
            0.03129389137029648,
            -0.030541153624653816,
            0.039948996156454086,
            0.01872103102505207,
            -0.009280075319111347,
            0.024361494928598404,
            0.008820165880024433,
            -0.009820226579904556,
            0.009910487569868565,
            0.010725948959589005,
            -0.013156717643141747,
            0.005923119839280844,
            -0.020192937925457954,
            -0.007361804600805044,
            -0.04045967385172844,
            0.010323530994355679,
            -0.00015015904500614852,
            0.020341482013463974,
            -0.0030087230261415243,
            0.03648822009563446,
            -0.0328298918902874,
            0.0004081388469785452,
            -0.020465468987822533,
            0.0046354979276657104,
            -0.024672575294971466,
            -0.016456931829452515,
            0.015469059348106384,
            -0.025392303243279457,
            0.00925062969326973,
            0.002887807786464691,
            0.004033022094517946,
            -0.02828594110906124,
            -0.01050306111574173,
            0.013211144134402275,
            -0.00909117516130209,
            -0.03804769366979599,
            0.029807569459080696,
            0.018774595111608505,
            0.018063805997371674,
            -0.0003252013120800257,
            -0.009485949762165546,
            0.004770045634359121,
            -0.024268608540296555,
            -0.029971253126859665,
            -0.014005489647388458,
            0.022753996774554253,
            -0.017857300117611885,
            0.03613122180104256,
            0.01640915870666504,
            0.014013820327818394,
            -0.013491485267877579,
            0.00635713804513216,
            -0.03600542992353439,
            -0.03832006826996803,
            -0.02438948303461075,
            0.010820857249200344,
            -0.0064884088933467865,
            0.010557625442743301,
            0.04152260720729828,
            0.026418384164571762,
            0.020228926092386246,
            0.03339318186044693,
            0.03980564326047897,
            -0.0017915008356794715,
            0.0025484750512987375,
            -0.02219661884009838,
            0.01728561520576477,
            -0.023528628051280975,
            -0.005777636542916298,
            -0.019842024892568588,
            -0.011756068095564842,
            0.005263879429548979,
            -0.03363269567489624,
            0.002867593429982662,
            -0.028766656294465065,
            -0.01955348253250122,
            0.034596316516399384,
            -0.023411286994814873,
            0.0030134660191833973,
            0.014141952618956566,
            0.004828431643545628,
            -0.008828690275549889,
            -0.0022594239562749863,
            -0.02241426892578602,
            0.0004301592998672277,
            0.014379113912582397,
            -0.027273159474134445,
            -0.015620481222867966,
            0.006428562104701996,
            -0.0015901788137853146,
            0.0024143774062395096,
            -0.014928418211638927,
            0.030471941456198692,
            -0.012645071372389793,
            -0.004250338301062584,
            0.0074468012899160385,
            -0.014135628938674927,
            -0.024807533249258995,
            -0.013374727219343185,
            -0.0028452537953853607,
            0.007494451943784952,
            0.028308097273111343,
            -0.0141025111079216,
            0.01322902925312519,
            -0.02906336821615696,
            -0.011659753508865833,
            0.010088543407619,
            0.018274594098329544,
            -0.012259043753147125,
            -0.018856436014175415,
            0.013861981220543385,
            0.014993847347795963,
            -0.026177270337939262,
            0.025894423946738243,
            -0.03973127529025078,
            -0.016317317262291908,
            -0.023804431781172752,
            0.0021242524962872267,
            0.01482614316046238,
            0.021285077556967735,
            0.0005844688857905567,
            -0.0017223682953044772,
            -0.02149578183889389,
            0.02280859835445881,
            -0.01646278239786625,
            -0.004298488609492779,
            0.011592407710850239,
            -0.0030501794535666704,
            0.022594815120100975,
            0.012347436510026455,
            0.012251355685293674,
            -0.014169134199619293,
            -0.024344902485609055,
            -0.024733781814575195,
            0.05333305522799492,
            0.014801319688558578,
            -0.007534524891525507,
            0.0013646376319229603,
            0.005168510135263205,
            0.01706876792013645,
            0.03776159510016441,
            -0.023674694821238518,
            -0.02343718521296978,
            -0.017297590151429176,
            0.040274154394865036,
            0.015757804736495018,
            0.01717342808842659,
            0.024178888648748398,
            -0.0023211222141981125,
            0.01930168643593788,
            -8.141489524859935e-05,
            0.03846810758113861,
            0.019362982362508774,
            0.009679124690592289,
            0.023824434727430344,
            0.022090882062911987,
            0.01744052767753601,
            0.010195867158472538,
            0.027418263256549835,
            -0.02463640086352825,
            -0.009860681369900703,
            -0.004992542788386345,
            -0.013877468183636665,
            0.0011111601488664746,
            -0.02568274736404419,
            0.02345966547727585,
            0.0438922755420208,
            -0.031547047197818756,
            -0.017213042825460434,
            0.016237646341323853,
            -0.013611885719001293,
            -0.014187519438564777,
            -0.004648135043680668,
            -0.014662081375718117,
            0.027822792530059814,
            0.024149367585778236,
            0.030277159065008163,
            0.026387417688965797,
            0.010659943334758282,
            -0.0046408092603087425,
            -0.020773788914084435,
            0.0022996715269982815,
            0.006750000175088644,
            0.012822664342820644,
            -0.021807050332427025,
            0.00870614591985941,
            -0.00027749399305321276,
            -0.012987296096980572,
            -0.031694211065769196,
            0.021533027291297913,
            -0.0017600200371816754,
            0.020794173702597618,
            0.013383971527218819,
            -0.02976902946829796,
            -0.001762373954989016,
            0.004505824763327837,
            0.0103368004783988,
            -0.01462483499199152,
            -0.027910245582461357,
            0.006250972393900156,
            -0.01804903708398342,
            0.02632085047662258,
            -0.011506283655762672,
            0.020679879933595657,
            -0.005604732781648636,
            -0.02036457136273384,
            0.0504729188978672,
            0.013613885268568993,
            0.023135915398597717,
            0.02997051179409027,
            0.018405631184577942,
            -0.0030467736069113016,
            -0.010436474345624447,
            0.02131045050919056,
            -0.014907239936292171,
            0.036842454224824905,
            -0.03270886838436127,
            0.029297618195414543,
            0.02334146574139595,
            -0.03274645656347275,
            0.013309931382536888,
            0.032433927059173584,
            0.012265460565686226,
            -0.0031051987316459417,
            -0.020127300173044205,
            0.01805812306702137,
            -0.02970270998775959,
            0.014622524380683899,
            0.004180483054369688,
            -0.026781557127833366,
            0.006262355949729681,
            -0.024196984246373177,
            -0.005938537418842316,
            0.011077052913606167,
            0.018423717468976974,
            0.019808873534202576,
            -0.03475078195333481,
            0.006485783029347658,
            -0.01815328560769558,
            0.019190549850463867,
            -0.023405659943819046,
            0.01074850745499134,
            -0.03610274940729141,
            0.03117838315665722,
            -0.0006497863796539605,
            0.01798289455473423,
            -0.005597660318017006,
            -0.024244114756584167,
            -0.0005033937050029635,
            0.012752208858728409,
            0.014198928140103817,
            -0.026727747172117233,
            0.02311781607568264,
            -0.009338462725281715,
            0.018128186464309692,
            -0.013275171630084515,
            0.01291173230856657,
            0.024504564702510834,
            0.001363161369226873,
            -0.016986703500151634,
            0.012156525626778603,
            -0.008670701645314693,
            0.018020646646618843,
            -0.03512084111571312,
            0.02722790092229843,
            -0.011723235249519348,
            -0.02033655345439911,
            0.029997514560818672,
            -0.01795894466340542,
            -0.006375741679221392,
            -0.034639179706573486,
            -0.00899287685751915,
            -0.018298177048563957,
            -0.027369389310479164,
            0.012941068038344383,
            0.026177046820521355,
            0.027619414031505585,
            -0.003607151098549366,
            0.03802259638905525,
            0.012683282606303692,
            0.019777504727244377,
            -0.030234241858124733,
            -0.020795365795493126,
            -0.0019151995657011867,
            -0.01299496740102768,
            -0.014732839539647102,
            0.007318394258618355,
            -0.01782401278614998,
            0.01901364140212536,
            0.013629850000143051,
            0.01538530457764864,
            -0.015357637777924538,
            0.03313615918159485,
            -0.00042981759179383516,
            0.013754764571785927,
            0.01156183984130621,
            0.009607087820768356,
            0.023214420303702354,
            -0.03047322668135166,
            0.002651059767231345,
            -0.04778437316417694,
            0.013222523964941502,
            0.00017753335123416036,
            0.013072015717625618,
            -0.011326165869832039,
            0.028435049578547478
        ],
        [
            0.04012889787554741,
            0.012326384894549847,
            0.015549891628324986,
            -0.0240713432431221,
            -0.013360608369112015,
            0.02190987765789032,
            -0.01536433957517147,
            -0.014949045144021511,
            0.01762542873620987,
            -0.013303869403898716,
            0.020680304616689682,
            0.02122284285724163,
            -0.02538483962416649,
            4.056614125147462e-05,
            -0.00881675723940134,
            0.015839846804738045,
            0.04006849601864815,
            0.0007969913422130048,
            -0.0037626735866069794,
            -0.007174728903919458,
            0.018527867272496223,
            -0.007946141064167023,
            -0.03554427623748779,
            -0.019089635461568832,
            0.032303519546985626,
            -0.0407981313765049,
            -0.012239833362400532,
            0.00017000408843159676,
            -0.0025679003447294235,
            0.007095355074852705,
            0.010731787420809269,
            0.0030219084583222866,
            0.0122757563367486,
            0.013710510917007923,
            0.004386267624795437,
            -0.010741072706878185,
            0.02636065147817135,
            0.04747844487428665,
            -0.018395129591226578,
            0.026909876614809036,
            -0.016131849959492683,
            -0.012149465270340443,
            -0.02885531447827816,
            0.01127754244953394,
            0.006725245621055365,
            0.027788929641246796,
            -0.00670790346339345,
            -0.022145118564367294,
            0.0014380925567820668,
            -0.0029876173939555883,
            -0.016566840931773186,
            0.0052373833023011684,
            -0.005456829443573952,
            -0.0018308437429368496,
            -0.010503489524126053,
            0.012613484635949135,
            -0.03233718499541283,
            -0.018009621649980545,
            0.0347793847322464,
            0.02061856910586357,
            0.059328917413949966,
            -0.01046709530055523,
            0.004692018963396549,
            -0.020391052588820457,
            0.037023235112428665,
            -0.014770637266337872,
            0.018352173268795013,
            0.03432245925068855,
            0.01765226200222969,
            -0.022809820249676704,
            0.02560609206557274,
            0.014357097446918488,
            -0.01834358274936676,
            -0.013886983506381512,
            0.03430437296628952,
            -0.05634192377328873,
            -0.0013108645798638463,
            0.017504850402474403,
            0.021907489746809006,
            0.017850762233138084,
            0.025572555139660835,
            -0.010882188566029072,
            0.015535136684775352,
            -0.007166929543018341,
            0.002797133522108197,
            0.0037272628396749496,
            -0.004225925076752901,
            -0.01692315749824047,
            0.04414590448141098,
            -0.00895263534039259,
            0.017476772889494896,
            0.036484118551015854,
            -0.000368004955817014,
            -0.02348659746348858,
            -0.004700544290244579,
            0.026566240936517715,
            0.0025906190276145935,
            -0.03442024812102318,
            -0.012926243245601654,
            0.004850370343774557,
            -0.04502951726317406,
            0.025852395221590996,
            -0.0052954768761992455,
            0.016957206651568413,
            -0.008806341327726841,
            -0.0014804934617131948,
            0.008762056939303875,
            0.003160293912515044,
            0.04472987353801727,
            0.0277627594769001,
            -0.02523723803460598,
            0.01135028712451458,
            -0.018704600632190704,
            0.013006922788918018,
            0.0038402951322495937,
            0.03322245180606842,
            -0.032535068690776825,
            0.04203077778220177,
            0.00810349639505148,
            0.005932982545346022,
            -0.033752862364053726,
            0.026316732168197632,
            0.06354999542236328,
            0.023830827325582504,
            0.0033593543339520693,
            0.0007117294589988887,
            -0.012943037785589695,
            -0.010990796610713005,
            0.0009625133243389428,
            -0.007872446440160275,
            0.024083159863948822,
            -0.03611934557557106,
            0.058572083711624146,
            -0.006154565140604973,
            0.027542423456907272,
            -0.021951906383037567,
            0.024518514052033424,
            -0.033151738345623016,
            -0.03286542370915413,
            0.013189351186156273,
            0.015918653458356857,
            -0.013566420413553715,
            -0.023360179737210274,
            0.007976640947163105,
            -0.013839375227689743,
            0.061894383281469345,
            -0.02894432842731476,
            0.04680773615837097,
            -0.005341081880033016,
            -0.008127151988446712,
            0.011896959505975246,
            0.023918526247143745,
            -0.029158974066376686,
            -0.0456734374165535,
            0.031133480370044708,
            0.010314551182091236,
            0.005372724030166864,
            -0.03112424537539482,
            0.0019646589644253254,
            -0.004827816970646381,
            -0.0026182227302342653,
            0.031063467264175415,
            0.010678436607122421,
            0.032319843769073486,
            -0.023707039654254913,
            0.05623422563076019,
            0.017948981374502182,
            0.028508074581623077,
            -0.05500346049666405,
            0.0032949072774499655,
            -0.014981341548264027,
            0.0030933967791497707,
            -0.017718737944960594,
            -0.03995782881975174,
            -0.003613219130784273,
            -0.03111094981431961,
            0.02170621231198311,
            0.008000219240784645,
            0.01099309977144003,
            0.006845294032245874,
            -0.015735477209091187,
            -0.01650463417172432,
            -0.01241533737629652,
            0.025497207418084145,
            0.0055321077816188335,
            -0.015944626182317734,
            -0.0003125882940366864,
            0.021322151646018028,
            0.013965116813778877,
            0.010312484577298164,
            0.010519289411604404,
            -0.011979806236922741,
            0.04995373263955116,
            -0.014574234373867512,
            0.000854071753565222,
            -0.0007679705158807337,
            -0.021512800827622414,
            -0.013623816892504692,
            0.02552057057619095,
            0.02985229529440403,
            0.005851180292665958,
            -0.025790737941861153,
            -0.015492771752178669,
            0.012428117915987968,
            0.0169414971023798,
            0.03770999237895012,
            0.00019386725034564734,
            0.015692276880145073,
            0.0017213523387908936,
            0.004490431398153305,
            0.0336427204310894,
            -0.012488050386309624,
            -0.03426868095993996,
            0.008320956490933895,
            0.023793239146471024,
            -0.0019311434589326382,
            0.012833446264266968,
            0.011874030344188213,
            -0.014910804107785225,
            -0.00019624286505859345,
            -0.013743605464696884,
            -0.005441188812255859,
            -0.03112500160932541,
            0.029523517936468124,
            0.03510766848921776,
            0.04818316921591759,
            -0.03388894349336624,
            -0.021139508113265038,
            -0.028994273394346237,
            -0.007176247891038656,
            0.05256657302379608,
            -0.028715327382087708,
            0.014043893665075302,
            0.02430172823369503,
            0.026820633560419083,
            -0.02658269554376602,
            -0.010072564706206322,
            0.02535478211939335,
            -0.012293183244764805,
            0.00974408071488142,
            -0.04385365545749664,
            0.009516446851193905,
            -0.003003551857545972,
            0.016036121174693108,
            -0.006817215122282505,
            0.00506176333874464,
            0.004760485142469406,
            -0.027504459023475647,
            -0.0073792277835309505,
            0.021197808906435966,
            0.004416618961840868,
            -0.039616674184799194,
            0.009414022788405418,
            -0.03019101545214653,
            0.014858401380479336,
            -0.0314970538020134,
            -0.019881699234247208,
            -0.02424038201570511,
            0.03394020348787308,
            0.002025845693424344,
            -0.003313836408779025,
            0.010446880012750626,
            -0.018686169758439064,
            -0.030237451195716858,
            0.004105797968804836,
            0.06507138907909393,
            -0.02282969281077385,
            -0.024219883605837822,
            -0.02698901854455471,
            0.033466119319200516,
            0.025912165641784668,
            0.01736803725361824,
            -0.030306892469525337,
            -0.0008645254420116544,
            -0.051054202020168304,
            -0.006684130057692528,
            -0.029138732701539993,
            -0.0033887263853102922,
            0.012218676507472992,
            0.011824134737253189,
            -0.019699683412909508,
            -0.00806347094476223,
            -0.00832649227231741,
            0.000554969534277916,
            -0.023207100108265877,
            -0.009185759350657463,
            0.02087332494556904,
            -0.034502025693655014,
            0.013547712936997414,
            0.027280203998088837,
            0.0201076902449131,
            -0.007490872405469418,
            0.001848659012466669,
            0.018865609541535378,
            0.02734529599547386,
            0.007756098639219999,
            0.005203047301620245,
            -0.01290632039308548,
            0.01906958967447281,
            -0.017290081828832626,
            -0.005969900172203779,
            0.02175970934331417,
            0.01880665495991707,
            0.01909545063972473,
            -0.0031035670544952154,
            -0.026386257261037827,
            0.009271332994103432,
            -0.008516974747180939,
            0.03491685539484024,
            -0.011866533197462559,
            0.026386072859168053,
            0.011228715069591999,
            -5.074190994491801e-05,
            0.0301350224763155,
            -0.014051839709281921,
            -0.014349411241710186,
            -0.024042652919888496,
            0.008118554018437862,
            0.01271028257906437,
            -0.02915107272565365,
            0.03780583664774895,
            -0.019853752106428146,
            0.01563503034412861,
            -0.007040581665933132,
            -0.018418416380882263,
            -0.008794201537966728,
            0.026463884860277176,
            0.023475851863622665,
            -0.04233042150735855,
            0.013822931796312332,
            -0.002394845010712743,
            -0.05397120863199234,
            0.02790040522813797,
            0.01455121859908104,
            0.027688894420862198,
            -0.014697124250233173,
            -0.0034427025821059942,
            0.03955613449215889,
            -0.001468083937652409,
            -0.023083630949258804,
            0.009483994916081429,
            0.0177873894572258,
            0.02472856640815735,
            -0.02141125686466694,
            0.04125608503818512,
            -0.0514405332505703,
            -0.009723126888275146,
            -0.020974114537239075,
            0.009165343828499317,
            -0.02558514103293419,
            -0.008165042847394943,
            0.028100665658712387,
            -0.06452696770429611,
            0.024852070957422256,
            -0.006737290881574154,
            0.010538587346673012,
            -0.017656713724136353,
            -0.003404415911063552,
            0.0072315530851483345,
            0.004012320190668106,
            0.007440116256475449,
            -0.02540569379925728,
            -0.027103209868073463,
            -0.03424960374832153,
            -0.04886690154671669,
            -0.00965224951505661,
            -0.00746584590524435,
            -0.00037818343844264746,
            -0.014593120664358139,
            -0.014956310391426086,
            -0.03744050860404968,
            0.030035948380827904,
            -0.04756446182727814,
            -0.04010375961661339,
            -0.009530310519039631,
            -0.006827014498412609,
            0.018741363659501076,
            -0.03400448337197304,
            -0.04798975586891174,
            0.0010794686386361718,
            0.0015601975610479712,
            -0.002779398811981082,
            -0.00015333102783188224,
            -0.02020134963095188,
            0.044316936284303665,
            -0.034497324377298355,
            -0.0013197221560403705,
            0.01061897911131382,
            0.009537876583635807,
            0.02375861257314682,
            -0.01924493908882141,
            0.032320428639650345,
            -0.0006395626114681363,
            -0.009720249101519585,
            0.008173886686563492,
            0.018578052520751953,
            -0.01917642541229725,
            0.043082866817712784,
            0.03533368930220604,
            -0.04748088866472244,
            0.04789121448993683,
            -0.0010845515644177794,
            -0.021469110623002052,
            0.021384067833423615,
            0.014598966576159,
            -0.011993186548352242,
            -0.0017648657085373998,
            -0.03390266373753548,
            -0.0022059758193790913,
            -0.0007247816538438201,
            -0.018610022962093353,
            0.005352502688765526,
            0.006585642695426941,
            0.01938329078257084,
            0.029696259647607803,
            0.012191996909677982,
            0.023185551166534424,
            -0.0405159518122673,
            -0.02066262997686863,
            0.007153225131332874,
            -0.03415493294596672,
            -0.01828276924788952,
            -0.02325679175555706,
            -0.01250056829303503,
            0.05415499210357666,
            0.04436343535780907,
            -0.03429841250181198,
            -0.01175716333091259,
            0.013285993598401546,
            0.020087171345949173,
            -0.0016130057629197836,
            -0.0327797494828701,
            0.02672838419675827,
            -0.03622256964445114,
            -0.02718261443078518,
            -0.05102920159697533,
            0.0017622271552681923,
            0.028585560619831085,
            -0.03428016975522041,
            0.011040711775422096,
            -0.011514143086969852,
            -0.02243494614958763,
            0.021926408633589745,
            0.025995219126343727,
            0.012075685895979404,
            0.002321848412975669,
            0.03309367597103119,
            -0.036207132041454315,
            0.031790491193532944,
            -0.017968423664569855,
            -0.021876024082303047,
            0.03331618756055832,
            0.027132635936141014,
            0.001327758189290762,
            -0.011126670986413956,
            -0.029511544853448868,
            -0.018631093204021454,
            0.007530874572694302,
            -0.017472222447395325,
            -0.009898251853883266,
            0.0021408183965831995,
            0.002715595066547394,
            -0.020134776830673218,
            -0.0014694785932078958,
            -0.005537377670407295,
            0.01546717993915081,
            -0.01817568950355053,
            -0.03575540706515312,
            -0.038384534418582916,
            0.04489952325820923,
            -0.00037480436731129885,
            0.02183767780661583,
            -0.009150782600045204,
            -0.005081430077552795,
            -0.021430660039186478,
            0.008297226391732693,
            -0.008210831321775913,
            0.02242131158709526,
            0.0007691908976994455,
            -0.018788399174809456,
            -0.017119763419032097,
            0.020341379567980766,
            -0.008379744365811348,
            -0.022104967385530472,
            -0.0034024224150925875,
            0.017732035368680954,
            0.009820242412388325,
            0.024031829088926315,
            0.06706395000219345,
            0.005626270081847906,
            0.047827161848545074,
            -0.006524831987917423,
            -0.018997007980942726,
            -0.025116577744483948,
            -0.05506996810436249,
            0.027923451736569405,
            -0.016680188477039337,
            0.007656467147171497,
            -0.020886901766061783,
            0.0062342314049601555,
            -0.007611531764268875,
            0.030251633375883102,
            -0.030306179076433182,
            0.0010371728567406535,
            -0.011104965582489967,
            0.018094493076205254,
            0.023966679349541664,
            0.026685772463679314,
            -0.0034405982587486506,
            0.021691404283046722,
            -0.021164914593100548,
            -0.020786074921488762,
            -0.022981520742177963,
            -0.015134249813854694,
            0.012014313600957394,
            0.025089081376791,
            0.030804943293333054,
            0.012774666771292686,
            0.04048089310526848,
            0.027650011703372,
            0.037594765424728394,
            -0.0037432722747325897,
            -0.024019669741392136,
            -0.027763893827795982,
            0.02043857052922249,
            -0.002565586706623435,
            -0.008758815005421638,
            -0.0028208985459059477,
            -0.01008063368499279,
            0.0012445078464224935,
            -0.017764650285243988,
            0.00949306134134531,
            0.0035142116248607635,
            -0.004536788444966078,
            0.03359076753258705,
            0.0013802539324387908,
            -0.0008576010586693883,
            0.027481166645884514,
            -0.015074494294822216,
            -0.020518288016319275,
            0.023459864780306816,
            -0.02335355430841446,
            -0.04556536674499512,
            -0.01709815301001072,
            0.024769173935055733,
            -0.021145422011613846,
            -0.01922144740819931,
            0.04914121329784393,
            0.035240355879068375,
            -0.04176686704158783,
            -0.01355106383562088,
            0.004472771659493446,
            0.02702609822154045,
            0.005272067617624998,
            -0.016640106216073036,
            -0.018494298681616783,
            0.042919453233480453,
            0.013163277879357338,
            0.01429093349725008,
            -0.016242844983935356,
            0.036577388644218445,
            -0.008422117680311203,
            -0.0002679471508599818,
            0.0019086943939328194,
            0.021356752142310143,
            -0.00447875028476119,
            0.0011412919266149402,
            0.01589803583920002,
            0.015556194819509983,
            -0.021541301161050797,
            0.012067277915775776,
            -0.018463386222720146,
            -0.03576546534895897,
            -0.007804136723279953,
            -0.02798442356288433,
            -0.04944821074604988,
            0.002532275626435876,
            0.011772817000746727,
            0.04383844882249832,
            0.026095561683177948,
            -0.0037396401166915894,
            -0.0030239352490752935,
            -0.0339854471385479,
            -0.02392425574362278,
            -0.011358686722815037,
            -0.007127286400645971,
            -0.0039290981367230415,
            -0.014457846991717815,
            -0.02807818166911602,
            -0.029718201607465744,
            -0.02306975983083248,
            -0.0096437344327569,
            0.010789940133690834,
            0.008562728762626648,
            -0.004165166988968849,
            -0.005998906213790178,
            0.007779173087328672,
            0.00015338908997364342,
            -0.016710348427295685,
            0.023353533819317818,
            0.014344410039484501,
            -0.018256278708577156,
            -0.005341152660548687,
            0.0073185209184885025,
            -0.006568738725036383,
            -0.00790737010538578,
            -0.009604915976524353,
            0.02742660790681839,
            0.033625856041908264,
            -0.007928437553346157,
            0.011995813809335232,
            -0.009047708474099636,
            -0.013408035971224308,
            0.011419862508773804,
            0.01822330430150032,
            -0.011997771449387074,
            0.023157669231295586,
            -0.02524971216917038,
            0.008133960887789726,
            0.04629429429769516,
            0.027783693745732307,
            0.019825033843517303,
            -0.0066525861620903015,
            0.014873238280415535,
            -0.008016739040613174,
            -0.010895256884396076,
            0.008166554383933544,
            0.018957961350679398,
            -0.02390407584607601,
            -0.025795185938477516,
            0.03434079512953758,
            -0.008063338696956635,
            -0.021233554929494858,
            -0.02602548524737358,
            0.04779864475131035,
            -0.017130406573414803,
            0.022134970873594284,
            0.015200247056782246,
            0.04444917291402817,
            -0.008900131098926067,
            0.02693818509578705,
            -0.03368077054619789,
            -0.01372135616838932,
            0.033608317375183105,
            0.02882217802107334,
            -0.03088795766234398,
            0.019916919991374016,
            0.005136584397405386,
            0.02091873250901699,
            0.007322751916944981,
            -0.004637321922928095,
            -0.024334121495485306,
            -0.0022540506906807423,
            0.019796907901763916,
            0.00970233604311943,
            0.008057787083089352,
            -0.022694701328873634,
            -0.02448507957160473,
            0.015224684029817581,
            -0.0183574128895998,
            0.012543240562081337,
            0.019058020785450935,
            -0.018447088077664375,
            -0.005797280464321375,
            0.014848404563963413,
            -0.0033251442946493626,
            -0.013353150337934494,
            0.011530411429703236,
            0.03048657439649105,
            0.029349619522690773,
            -0.023409375920891762,
            0.012614886276423931,
            -0.0034609741996973753,
            -0.04610143601894379,
            -0.0028751299250870943,
            0.008750868029892445,
            0.015363057143986225,
            0.023584049195051193,
            -0.014995415695011616,
            0.01960991881787777,
            -0.005306872073560953,
            0.016100803390145302,
            0.001479782280512154,
            0.015880431979894638,
            -0.012954384088516235,
            -0.009691176004707813,
            0.03145598992705345,
            0.01994820311665535,
            0.02221323922276497,
            0.00035853765439242125,
            0.003951073158532381,
            -0.01174437627196312,
            0.0008618419524282217,
            0.02781510353088379,
            -0.017702465876936913,
            -0.0080574881285429,
            0.026054108515381813,
            -0.001795446965843439,
            0.02582406811416149,
            -0.013754107058048248,
            0.018846437335014343,
            0.02091541886329651,
            -0.0038744783960282803,
            -0.010685211047530174,
            -0.030875230208039284,
            -0.025890346616506577,
            -0.009807686321437359,
            -0.027441702783107758,
            0.005667342804372311,
            0.05101250112056732,
            -0.011956291273236275,
            -0.01898285001516342,
            0.01658792980015278,
            0.01436539925634861,
            -0.02889428660273552,
            0.0032433599699288607,
            0.05037931725382805,
            0.020135074853897095,
            -0.016298824921250343,
            0.033982183784246445,
            -0.0015717351343482733,
            0.00834870059043169,
            0.005971362814307213,
            -0.019953997805714607,
            -0.0006296248757280409,
            -0.014620242640376091,
            0.005097439046949148,
            0.025755859911441803,
            0.007401263806968927,
            0.0014154498931020498,
            0.044927868992090225,
            0.04636117443442345,
            -0.0006296063656918705,
            -0.013384961523115635,
            -0.03154965862631798,
            -0.014444735832512379,
            -0.02961699292063713,
            -0.02276253141462803,
            -0.022507283836603165,
            -0.0055658831261098385,
            -0.005434397142380476,
            -0.011986912228167057,
            0.020214589312672615,
            0.0034246514551341534,
            -0.01982400007545948,
            -0.04436526820063591,
            0.03649822250008583,
            -0.02226109616458416,
            0.041864171624183655,
            0.018008925020694733,
            0.01784772053360939,
            0.021429482847452164,
            -0.006234129425138235,
            0.011894650757312775,
            -0.016903899610042572,
            -0.02642674930393696,
            -0.05045728012919426,
            -0.013966486789286137,
            0.006456294097006321,
            -0.01920054852962494,
            0.03774775192141533,
            0.016078993678092957,
            -0.0016901955241337419,
            -0.0002509848854970187,
            0.04079601913690567,
            0.009970394894480705,
            -0.0127261346206069,
            0.01550204586237669,
            -0.004790188279002905,
            0.005001246463507414,
            -0.003581030061468482,
            -0.029579268768429756,
            0.021421723067760468,
            0.041095834225416183,
            -0.007449829950928688,
            -0.0039964779280126095,
            0.024992775171995163,
            -0.0025961268693208694,
            0.0033805679995566607,
            0.010642077773809433,
            -0.0034234398044645786,
            0.019392753019928932,
            0.013599423691630363,
            -0.03700625151395798,
            -0.01821051724255085,
            0.016400428488850594,
            -0.0005306850071065128,
            0.021742478013038635,
            -0.0045890710316598415,
            -0.009019577875733376,
            0.030835406854748726,
            -0.022623319178819656,
            -0.03872999548912048,
            0.029266443103551865,
            -0.020608004182577133,
            0.009779415093362331,
            -0.013908730819821358,
            -0.003821405116468668,
            -0.02235334925353527,
            0.03634180873632431,
            0.0159089844673872,
            0.020920978859066963,
            0.016504788771271706,
            -0.04072213172912598,
            0.005331599153578281,
            -0.03045462816953659,
            0.007320602890104055,
            0.000793084385804832,
            0.03247535601258278,
            0.00391653785482049,
            0.0213776845484972,
            0.023430218920111656,
            -0.037229616194963455,
            0.013703273609280586,
            -0.0006780431722290814,
            0.027181921526789665,
            -0.026212777942419052,
            0.03575725480914116,
            -0.025404442101716995,
            0.036777060478925705,
            -0.034212637692689896,
            0.013050377368927002,
            0.02712148241698742,
            -0.022275665774941444,
            -0.012220033444464207,
            0.00882954802364111,
            0.014540180563926697,
            0.02982591837644577,
            -0.0238033439964056,
            -0.014742966741323471,
            -0.03059808723628521,
            0.014758818782866001,
            -0.004894024692475796,
            0.01007793378084898,
            -0.013714760541915894,
            0.03247496485710144,
            -0.007686919998377562,
            0.0370153971016407,
            0.020988840609788895,
            0.00719474395737052,
            -0.02833264321088791,
            -0.0024866319727152586,
            -0.009361257776618004,
            0.01100379042327404,
            0.020459527149796486,
            0.03389957174658775,
            -0.026765571907162666,
            0.02032976597547531,
            0.008169500157237053,
            0.014931607991456985,
            0.0019737205002456903,
            0.02665495127439499,
            -0.043927308171987534,
            -0.03685374557971954,
            0.0010570327285677195,
            0.025624053552746773,
            0.01787441223859787,
            -0.0499141551554203,
            -0.04519731178879738,
            -0.003252787981182337,
            0.0056078736670315266,
            -0.03920699656009674,
            0.014714156277477741,
            -0.016195371747016907,
            0.04296967014670372,
            -0.022233635187149048,
            -0.015845144167542458,
            -0.007346475962549448,
            0.006739490665495396,
            0.005187475588172674,
            0.008922244422137737,
            0.028926778584718704,
            -0.015339987352490425,
            -0.029691923409700394,
            -0.02618355304002762,
            -0.01064030360430479,
            -0.008100287057459354,
            -0.03237220272421837,
            0.0019517886685207486,
            -0.006774180568754673,
            0.03963601216673851,
            -0.018709301948547363,
            0.03532755374908447,
            -0.02253580093383789,
            -0.006618157960474491,
            -0.02346663735806942,
            0.028186386451125145,
            -0.026599755510687828,
            0.025678128004074097,
            -0.0074487547390162945,
            0.006779814604669809,
            -0.00020326927187852561,
            0.029447272419929504,
            0.029378585517406464,
            -0.04765373468399048,
            -0.020938808098435402,
            -0.01201414130628109,
            -0.027133161202073097,
            0.011465413495898247,
            0.03298819810152054,
            0.016283191740512848,
            -0.024592943489551544,
            -0.024961624294519424,
            -0.017273809760808945,
            -2.0775527445948683e-05,
            0.03869537264108658,
            0.03437552973628044,
            -0.012414072640240192,
            0.007090585306286812,
            0.024057593196630478,
            0.0011435645865276456,
            -0.006120690610259771,
            -0.03464066609740257,
            0.02514074556529522,
            0.007876676507294178,
            -0.009223593398928642,
            0.010412465780973434,
            -0.033917900174856186,
            -9.672566375229508e-05,
            0.035702936351299286,
            0.017515961080789566,
            -0.009391792118549347,
            0.0169517882168293,
            0.015528901480138302,
            0.019666608422994614,
            0.027315331622958183,
            0.016367102041840553,
            0.008657649159431458,
            -0.027755143120884895,
            0.04798932746052742,
            0.026630409061908722,
            0.010394971817731857,
            -0.007658082991838455,
            0.0283975712954998,
            -0.0477200448513031,
            0.02233785204589367,
            -0.007747154217213392,
            -0.01507879700511694,
            -0.03276291489601135,
            -0.004716002382338047,
            0.01804819516837597,
            0.004616353195160627,
            -0.01110488548874855,
            -0.02862190641462803,
            0.021239999681711197,
            -0.03275064378976822,
            0.022835735231637955,
            -0.019816165789961815,
            0.024366598576307297,
            -0.009680202230811119,
            0.03073151968419552,
            0.009771174751222134,
            -0.016974516212940216,
            0.005739419721066952,
            0.007372553925961256,
            0.024373378604650497,
            0.014351270161569118,
            -0.01432245783507824,
            0.00021784388809464872,
            -0.00882275216281414,
            0.012004563584923744,
            0.01825222373008728,
            -0.003244023071601987,
            0.013256012462079525,
            -0.005262189544737339,
            -0.014567701146006584,
            0.03947443142533302,
            0.02380281873047352,
            0.032782286405563354,
            -0.0200380627065897,
            -0.01360230054706335,
            -0.013781770132482052,
            0.00014970626216381788,
            -0.005436580628156662,
            -0.007227551657706499,
            -0.011670544743537903,
            0.0005979057750664651,
            0.025212209671735764,
            0.021418679505586624,
            0.02021981216967106,
            -0.02765529416501522,
            0.002248257864266634,
            -0.00011251033720327541,
            0.028846899047493935,
            0.02401350438594818,
            -0.004924762062728405,
            -0.0212241280823946,
            0.03696834668517113,
            -0.03909162059426308,
            0.01603780873119831,
            -0.036159683018922806,
            -0.012655575759708881,
            -0.0032107240986078978,
            0.022013122215867043,
            -0.014645161107182503,
            0.036875296384096146,
            0.034719906747341156,
            0.022952383384108543,
            0.03495526686310768,
            0.006893501617014408,
            -0.006213285028934479,
            -0.0017345529049634933,
            0.023700756952166557,
            -0.00129505165386945,
            0.017748285084962845,
            -0.011233543045818806,
            -0.017465325072407722,
            0.014835433103144169,
            -0.022272318601608276,
            0.026846887543797493,
            -0.023914529010653496,
            0.048180002719163895,
            0.03236525133252144,
            -0.01206139288842678,
            -0.020015692338347435,
            -0.027864625677466393,
            0.012997799552977085,
            -0.020140990614891052,
            0.001341142924502492,
            0.023164432495832443,
            -0.017953407019376755,
            0.052220750600099564,
            0.004315749742090702,
            -0.02669735625386238
        ],
        [
            0.025685332715511322,
            -0.005491996183991432,
            0.0019461412448436022,
            -0.00905823428183794,
            0.025730496272444725,
            -0.003633505431935191,
            -0.0230809785425663,
            -0.0012556255096569657,
            -0.0011056371731683612,
            0.028191162273287773,
            0.00361822172999382,
            -0.026857689023017883,
            -0.019941959530115128,
            -0.004572691861540079,
            0.03423997387290001,
            0.015386424027383327,
            -0.03473237156867981,
            0.020763816311955452,
            -0.011120760813355446,
            0.028628939762711525,
            0.038785893470048904,
            -0.015462378039956093,
            0.011879540048539639,
            0.005864511709660292,
            0.051017433404922485,
            0.013007209636271,
            0.005694790277630091,
            -0.03710805997252464,
            -0.02267768420279026,
            -0.0055589633993804455,
            -0.004041688516736031,
            0.0017555138329043984,
            0.004547947086393833,
            0.013808698393404484,
            0.024770429357886314,
            0.02173997089266777,
            -0.006094362121075392,
            0.012691733427345753,
            -0.005159254651516676,
            0.023043038323521614,
            -0.03886495530605316,
            0.04146581515669823,
            0.01839066483080387,
            0.007981261238455772,
            0.02261544018983841,
            -0.029830655083060265,
            -0.020696792751550674,
            -0.0023115556687116623,
            0.026574669405817986,
            0.04025282338261604,
            0.018562188372015953,
            0.009572180919349194,
            0.003037662710994482,
            -0.015443436801433563,
            -0.024254918098449707,
            -0.011193298734724522,
            -0.03331319987773895,
            0.02325599268078804,
            0.0016678320243954659,
            -0.019286984577775,
            0.02057037688791752,
            -0.030069466680288315,
            -0.023119937628507614,
            0.022971492260694504,
            0.03514045104384422,
            -0.022767500951886177,
            0.013007976114749908,
            0.01592353545129299,
            -0.0009662771481089294,
            0.0007022166973911226,
            0.007091793231666088,
            0.014381127431988716,
            -0.007129640318453312,
            -0.02755754627287388,
            -0.05863162875175476,
            -0.01830231584608555,
            -0.01826285570859909,
            0.0011503350688144565,
            -0.003429238684475422,
            0.048260096460580826,
            -0.021579314023256302,
            -0.03398449718952179,
            -3.3841039112303406e-05,
            -0.01564929075539112,
            0.02943672053515911,
            -0.008923877961933613,
            0.013899609446525574,
            0.016507264226675034,
            -0.022156063467264175,
            -0.009976530447602272,
            0.0005881557590328157,
            -0.02326918952167034,
            -0.038122452795505524,
            -0.04991559311747551,
            -0.018896615132689476,
            -0.00038574080099351704,
            0.018237615004181862,
            -0.0009733311017043889,
            0.07998064905405045,
            0.024988051503896713,
            0.0037934836000204086,
            -0.05125316232442856,
            -0.04566052928566933,
            0.0160537026822567,
            0.01588735170662403,
            0.03956941142678261,
            -0.028174882754683495,
            0.010614621452987194,
            -0.038135282695293427,
            -0.005046479403972626,
            -0.03280147910118103,
            0.034549370408058167,
            0.022730611264705658,
            -0.012266882695257664,
            0.01612234301865101,
            -0.00346338446252048,
            -0.010875342413783073,
            -0.014601060189306736,
            -0.0011783576337620616,
            0.014530189335346222,
            0.0183075200766325,
            -0.01579258218407631,
            0.02789383940398693,
            0.027492335066199303,
            -0.015060033649206161,
            0.013572053983807564,
            0.021493708714842796,
            0.010977658443152905,
            -0.008975977078080177,
            -0.017756829038262367,
            0.03158775344491005,
            0.020941879600286484,
            -0.006365840323269367,
            -0.011772973462939262,
            0.006324910558760166,
            -0.029571648687124252,
            0.03603189438581467,
            0.044249095022678375,
            -0.014174314215779305,
            0.017333339899778366,
            -0.02287292666733265,
            0.018325921148061752,
            0.03067619539797306,
            0.026904655620455742,
            -0.007418494671583176,
            0.008750363253057003,
            -0.011727803386747837,
            0.02393323928117752,
            0.007136621978133917,
            -0.013215900398790836,
            -0.037745941430330276,
            -0.03208098188042641,
            0.0025332188233733177,
            0.0020298445597290993,
            -0.027271486818790436,
            0.02558245323598385,
            0.0257521104067564,
            0.007314774673432112,
            0.027564894407987595,
            -0.0025682791601866484,
            0.00904699508100748,
            0.0010831530671566725,
            -0.012977488338947296,
            0.01517278142273426,
            0.03525359928607941,
            0.047319281846284866,
            0.009874842129647732,
            0.010358421131968498,
            0.026882145553827286,
            0.02094225399196148,
            -0.02817659266293049,
            0.01599348522722721,
            -0.006218404974788427,
            -0.010012509301304817,
            0.005274048075079918,
            -0.022378472611308098,
            0.021274302154779434,
            0.019993986934423447,
            0.004554902669042349,
            -0.004137462005019188,
            -0.0329485647380352,
            -0.023709304630756378,
            0.020042143762111664,
            0.005283661652356386,
            -0.01646413467824459,
            -0.007796362042427063,
            0.011683141812682152,
            -0.017204269766807556,
            -0.04265216365456581,
            0.024705737829208374,
            -0.019492749124765396,
            0.014887441881000996,
            -0.04190337657928467,
            -0.016993490979075432,
            -0.00879958737641573,
            -0.03358093276619911,
            0.03359152004122734,
            0.0343240387737751,
            0.012163234874606133,
            0.008757691830396652,
            0.017622848972678185,
            -0.009480643086135387,
            0.005273216404020786,
            0.013030380941927433,
            0.039304692298173904,
            -0.01860860362648964,
            0.006351815070956945,
            0.026754427701234818,
            0.029687128961086273,
            -0.013212581165134907,
            -0.00651512062177062,
            -0.026032719761133194,
            -0.006227745674550533,
            0.0433134064078331,
            -0.023556457832455635,
            -0.010065000504255295,
            -0.03150508552789688,
            0.010470718145370483,
            0.004095426760613918,
            -0.025832757353782654,
            0.02498927339911461,
            -0.004006202798336744,
            -0.007098902016878128,
            0.00028620660305023193,
            0.000331608927808702,
            -0.04701286181807518,
            -0.04230848699808121,
            0.036052215844392776,
            -0.01867661625146866,
            0.02550778165459633,
            0.019840221852064133,
            0.006241201888769865,
            0.008985317312180996,
            -0.025272419676184654,
            0.013442138209939003,
            0.019805124029517174,
            0.021610833704471588,
            0.022334126755595207,
            0.0018119943561032414,
            0.0027511566877365112,
            0.02245301380753517,
            0.014593473635613918,
            -0.012554001063108444,
            -0.035384874790906906,
            -0.0257219560444355,
            -0.015893343836069107,
            0.02888817898929119,
            -0.026097316294908524,
            0.010957321152091026,
            -0.019544245675206184,
            0.02821102924644947,
            0.004782231990247965,
            0.019332589581608772,
            0.027963818982243538,
            -0.003452233737334609,
            0.008386539295315742,
            -0.012569302693009377,
            0.01219932734966278,
            -0.018006157130002975,
            -0.019369296729564667,
            0.01999514549970627,
            -0.02571265771985054,
            -0.014982561580836773,
            0.007825484499335289,
            0.0183428768068552,
            0.028115198016166687,
            0.016932187601923943,
            0.005101769231259823,
            0.040637142956256866,
            0.0018801764817908406,
            -0.008970537222921848,
            -0.021116331219673157,
            0.0015201180940493941,
            -0.020708564668893814,
            0.009008830413222313,
            -0.009372248314321041,
            -0.028600435703992844,
            -0.0026056310161948204,
            0.041469354182481766,
            0.017415203154087067,
            0.0073904795572161674,
            -0.02459806576371193,
            -0.023739337921142578,
            -0.04039648547768593,
            -0.019686467945575714,
            -0.009815998375415802,
            0.014547637663781643,
            -0.05358920246362686,
            -0.004009775351732969,
            -0.04219469800591469,
            -0.0015472634695470333,
            0.020995214581489563,
            0.012704341672360897,
            -0.004373581148684025,
            -0.010863550938665867,
            0.04832044243812561,
            -0.026703547686338425,
            -0.044980887323617935,
            -0.0059037040919065475,
            -0.034814879298210144,
            -0.017269982025027275,
            0.009498781524598598,
            -0.01418008841574192,
            0.02880975604057312,
            0.02693611942231655,
            0.06349577009677887,
            -0.009165452793240547,
            -0.012010009028017521,
            0.013282001949846745,
            0.025100979954004288,
            -0.02786228246986866,
            0.02634972333908081,
            0.016222449019551277,
            0.03395598754286766,
            0.006918095517903566,
            -0.021540412679314613,
            -0.028746604919433594,
            0.019201911985874176,
            0.027965260669589043,
            0.0041352794505655766,
            -0.025664877146482468,
            -0.03630337491631508,
            -0.05879771336913109,
            0.05283169448375702,
            -0.018510717898607254,
            -0.028918307274580002,
            -0.03035266138613224,
            -0.0349605418741703,
            0.008518657647073269,
            0.0005254130810499191,
            -0.006580524146556854,
            0.005170818418264389,
            0.007799127139151096,
            -0.029458101838827133,
            0.0012653458397835493,
            -0.006009162403643131,
            -0.0268841739743948,
            0.011915030889213085,
            -0.012773161754012108,
            0.0023100057151168585,
            0.016053486615419388,
            -0.0509682334959507,
            -0.056563977152109146,
            0.001374602667056024,
            0.03617322817444801,
            0.03317933529615402,
            0.0081945164129138,
            0.025786593556404114,
            0.015081753954291344,
            -0.008839020505547523,
            -0.019854532554745674,
            -0.006435106508433819,
            0.023375680670142174,
            0.04267580434679985,
            0.02450404316186905,
            -0.002740051131695509,
            0.016407469287514687,
            0.012147913686931133,
            0.03316011279821396,
            -0.004308780655264854,
            -0.019745849072933197,
            -0.012088336050510406,
            -0.023099683225154877,
            0.032459620386362076,
            -0.013979189097881317,
            -0.012073864229023457,
            -0.03798265755176544,
            0.015253718011081219,
            0.023432452231645584,
            0.024985436350107193,
            -0.0017595976823940873,
            0.013716425746679306,
            -0.015575619414448738,
            -0.013315044343471527,
            -0.029740983620285988,
            -0.037850722670555115,
            0.005686206743121147,
            0.05282860994338989,
            0.04270477220416069,
            0.020837057381868362,
            0.02921813540160656,
            0.029617436230182648,
            -0.02442777343094349,
            -0.014153126627206802,
            0.049047257751226425,
            0.0274678785353899,
            0.028902340680360794,
            -0.02347821369767189,
            0.031505368649959564,
            0.00705103250220418,
            -0.011987150646746159,
            0.035358328372240067,
            0.015894969925284386,
            0.017347730696201324,
            0.003087820252403617,
            -0.034764233976602554,
            0.025046421214938164,
            -0.05199616402387619,
            0.02327018231153488,
            0.011975006200373173,
            -0.048924770206213,
            -0.006438270211219788,
            0.007023851852864027,
            0.0012212233850732446,
            -0.004119047429412603,
            -0.03863172233104706,
            0.012559031136333942,
            0.024607304483652115,
            0.0181595329195261,
            0.024905232712626457,
            0.023912940174341202,
            -0.0066419378854334354,
            0.008413531817495823,
            -0.0027027749456465244,
            -0.005459767300635576,
            0.009936179965734482,
            -0.03313393145799637,
            -0.030021149665117264,
            0.057724568992853165,
            -0.018445979803800583,
            0.017518285661935806,
            0.0008986658649519086,
            0.0313824787735939,
            -0.012081765569746494,
            -0.008576438762247562,
            0.013708162121474743,
            0.0100792171433568,
            -0.020458705723285675,
            -0.011237137950956821,
            -0.03816616162657738,
            0.002387309679761529,
            -0.006607783026993275,
            -0.01425632182508707,
            0.02695906162261963,
            -0.015709275379776955,
            0.006157980766147375,
            -0.0071194833144545555,
            -0.02492799423635006,
            -0.004143507685512304,
            0.0023725396022200584,
            -0.038881223648786545,
            -0.011843846179544926,
            -0.020378151908516884,
            -0.016305051743984222,
            0.008438904769718647,
            -0.013992113061249256,
            0.024016287177801132,
            0.09127393364906311,
            0.03194025903940201,
            -0.010088886134326458,
            -0.02553439699113369,
            0.00095790543127805,
            0.04310913011431694,
            -0.020554952323436737,
            -0.008227581158280373,
            -0.023195065557956696,
            0.056905969977378845,
            -0.0032873377203941345,
            -0.03884408622980118,
            -0.021265171468257904,
            0.002107900334522128,
            -0.03599214926362038,
            -0.015015142038464546,
            0.003688252065330744,
            0.020575713366270065,
            -0.006814149674028158,
            0.019269203767180443,
            -0.03234199807047844,
            -0.00251201493665576,
            -0.01783773861825466,
            0.021887872368097305,
            0.015201018191874027,
            -0.0075012450106441975,
            0.0492078922688961,
            -0.006468482315540314,
            -0.021478785201907158,
            0.007144942879676819,
            -0.007405752316117287,
            0.028700964525341988,
            -0.024794647470116615,
            0.027334921061992645,
            0.040630366653203964,
            0.00948324054479599,
            0.0072245909832417965,
            0.02306540310382843,
            -0.0320369191467762,
            0.00975840725004673,
            -0.03275085985660553,
            -0.07502566277980804,
            -0.03616197034716606,
            -0.012721091508865356,
            -0.01580207049846649,
            0.016446612775325775,
            -0.009440174326300621,
            0.05548630282282829,
            0.005108457524329424,
            -0.018637318164110184,
            -0.0026317941956222057,
            0.019195450469851494,
            0.017141057178378105,
            0.005959449335932732,
            -0.024127090349793434,
            -0.02519696205854416,
            -0.006780791562050581,
            0.018817352131009102,
            0.002770769177004695,
            0.005238768644630909,
            0.001887594349682331,
            -0.029115796089172363,
            0.016661623492836952,
            0.0022839438170194626,
            0.04196988046169281,
            0.009059243835508823,
            -0.008135102689266205,
            0.024979367852211,
            0.016813917085528374,
            -0.02378283627331257,
            -0.013717059046030045,
            0.018358757719397545,
            0.01434785034507513,
            0.02851744368672371,
            0.020239828154444695,
            0.009885212406516075,
            -0.022758644074201584,
            0.018266210332512856,
            0.007504874374717474,
            0.019527174532413483,
            0.011692550033330917,
            0.005969461984932423,
            0.004879801534116268,
            0.002744319848716259,
            0.004324840381741524,
            0.010172219946980476,
            -0.012045426294207573,
            0.012617619708180428,
            -0.0013427172088995576,
            0.01005451288074255,
            0.002742710057646036,
            0.003878441173583269,
            0.001221443060785532,
            0.008543124422430992,
            -0.011353941634297371,
            0.01924017257988453,
            0.010113518685102463,
            0.03421506658196449,
            -0.040744297206401825,
            0.023887161165475845,
            0.020386606454849243,
            -0.005362294148653746,
            -0.0019652973860502243,
            0.006018044892698526,
            0.02821083925664425,
            -0.006846108473837376,
            -0.0107888700440526,
            0.005226056091487408,
            0.02883513830602169,
            0.02167125605046749,
            -0.0025062342174351215,
            0.01922774687409401,
            0.012100658379495144,
            -0.0034061907790601254,
            0.01382923312485218,
            -0.0248981062322855,
            -0.00530378008261323,
            0.021098177880048752,
            -0.04423535615205765,
            -0.0026364156510680914,
            0.004484415519982576,
            0.01106545701622963,
            0.0024030290078371763,
            0.007634107023477554,
            -0.019434960559010506,
            0.01412781048566103,
            -0.013914947398006916,
            0.03736673668026924,
            0.02659495174884796,
            0.015105904079973698,
            -0.012505375780165195,
            -0.017597781494259834,
            -0.020561546087265015,
            0.031061753630638123,
            0.037232499569654465,
            -0.015181663446128368,
            0.023531118407845497,
            0.01592116802930832,
            -0.0047230771742761135,
            -0.017821306362748146,
            -0.02256162278354168,
            0.030630795285105705,
            -0.01685435138642788,
            -0.0067105358466506,
            -0.027125973254442215,
            0.030390743166208267,
            -0.0003185560053680092,
            -0.003387155942618847,
            0.0026234695687890053,
            0.00043270504102110863,
            0.014754675328731537,
            -0.007917764596641064,
            0.0028121748473495245,
            0.04486948996782303,
            0.024056140333414078,
            -0.020917657762765884,
            -0.017084218561649323,
            -0.00022409914527088404,
            0.0269940085709095,
            0.014383357018232346,
            -0.02711641974747181,
            0.0008742922218516469,
            -0.02745695412158966,
            0.031078912317752838,
            -0.02194216474890709,
            0.01712772808969021,
            -0.02124463953077793,
            0.0006820131675340235,
            0.00866194162517786,
            0.02373916283249855,
            -0.033334553241729736,
            0.0042169224470853806,
            0.004126007668673992,
            -0.011243084445595741,
            -0.010843510739505291,
            0.0020214319229125977,
            0.030662888661026955,
            -0.028734756633639336,
            0.0020676227286458015,
            -0.008168219588696957,
            -0.029949229210615158,
            0.0022976382169872522,
            -0.002623179228976369,
            -0.002739884424954653,
            -0.022104963660240173,
            0.010105185210704803,
            -0.023904070258140564,
            0.004980605561286211,
            -0.04140603169798851,
            0.0277527067810297,
            -0.024812573567032814,
            -0.02857322059571743,
            0.022879408672451973,
            -0.03626428171992302,
            0.03355424478650093,
            0.0389711819589138,
            -0.0022952992003411055,
            -0.0005559197161346674,
            0.01716659963130951,
            -0.022730696946382523,
            0.00025874938000924885,
            0.020382877439260483,
            -0.027583764865994453,
            0.018941357731819153,
            0.01011406909674406,
            -0.0068112644366919994,
            0.024383481591939926,
            -0.023700717836618423,
            -0.024865051731467247,
            0.011359731666743755,
            -0.007905486971139908,
            -0.026328982785344124,
            -0.03134247288107872,
            -0.025844667106866837,
            -0.02173035219311714,
            -0.021452555432915688,
            -0.01716274581849575,
            0.02470952458679676,
            0.017711563035845757,
            -0.02059786580502987,
            -0.006154284812510014,
            -0.021514495834708214,
            -0.015497869811952114,
            0.0375787653028965,
            0.019144853577017784,
            0.021657582372426987,
            -0.02491750568151474,
            0.025180509313941002,
            -0.01844152808189392,
            0.0221441388130188,
            -0.006567714270204306,
            -0.003318984527140856,
            -0.017761019989848137,
            -0.008109948597848415,
            -0.0032495083287358284,
            -0.00031710416078567505,
            0.006601172965019941,
            -0.001956457970663905,
            0.0340508408844471,
            0.03412967920303345,
            0.004016800317913294,
            0.029843227937817574,
            -0.01678233966231346,
            -0.037633612751960754,
            0.015424800105392933,
            0.017647404223680496,
            -0.016581518575549126,
            0.005964758340269327,
            0.0050878035835921764,
            0.03409360349178314,
            0.017322657629847527,
            -0.02623620070517063,
            0.015103930607438087,
            -0.009679693728685379,
            -0.03789858892560005,
            0.02109966240823269,
            0.01637532189488411,
            -0.00460511539131403,
            -0.010676047764718533,
            0.005650246515870094,
            0.018996847793459892,
            0.029546936973929405,
            -0.033771730959415436,
            0.009358353912830353,
            -0.02711055986583233,
            -0.02420010231435299,
            -0.004508554004132748,
            0.02715272828936577,
            0.04006971791386604,
            0.015824029222130775,
            0.002031345386058092,
            0.030888548120856285,
            0.0005273208953440189,
            -0.0010884212097153068,
            0.020707622170448303,
            -0.016914721578359604,
            -0.005452235694974661,
            -0.022086448967456818,
            -0.017047952860593796,
            0.020074279978871346,
            -0.02572956308722496,
            0.017222711816430092,
            0.005310507025569677,
            0.018538398668169975,
            -0.030201440677046776,
            -0.0023136253003031015,
            0.028493253514170647,
            0.027165882289409637,
            -0.029975757002830505,
            0.009780184365808964,
            0.027484459802508354,
            -0.025332413613796234,
            0.028881901875138283,
            0.026741497218608856,
            0.045155949890613556,
            0.010343818925321102,
            -0.026000719517469406,
            0.024830054491758347,
            -0.013017244637012482,
            -0.020442454144358635,
            -0.011682447977364063,
            -0.03990279510617256,
            -0.004598929546773434,
            -0.002516478765755892,
            -0.019749831408262253,
            -0.02311398833990097,
            -0.001198293175548315,
            -0.011491327546536922,
            0.0038431312423199415,
            0.009937206283211708,
            0.011206314899027348,
            -0.039887767285108566,
            0.004833650309592485,
            0.013033741153776646,
            -0.037613652646541595,
            0.023208796977996826,
            -0.010814700275659561,
            0.035026878118515015,
            0.027055779471993446,
            -0.0350017324090004,
            0.02295541949570179,
            0.03392518311738968,
            0.02841167338192463,
            -0.007762564346194267,
            -0.02675911784172058,
            -0.032424196600914,
            -0.0028597486671060324,
            0.0075998855754733086,
            0.024479255080223083,
            -0.03308463469147682,
            0.00041963302646763623,
            -0.014616163447499275,
            -0.0017583807930350304,
            0.014773857779800892,
            0.00788766797631979,
            -0.02505776844918728,
            0.005398407578468323,
            -0.020982563495635986,
            0.002121923491358757,
            -0.03694348409771919,
            -0.018605031073093414,
            0.019286325201392174,
            0.00040015767444856465,
            -0.0286551620811224,
            -0.01820613443851471,
            -0.038070011883974075,
            -0.010111654177308083,
            0.023455800488591194,
            -0.05244789272546768,
            0.005424060858786106,
            -0.019764108583331108,
            -0.004498766735196114,
            -0.0013970882864668965,
            -0.007286736276000738,
            -0.005313911009579897,
            -0.0226471908390522,
            0.007167825475335121,
            0.02814122848212719,
            -0.0034906803630292416,
            0.01237039640545845,
            0.002032595220953226,
            0.02614782564342022,
            0.003849679371342063,
            -0.0178859643638134,
            -0.03163956105709076,
            0.006494530942291021,
            0.011721324175596237,
            -0.02872510999441147,
            -0.012342849746346474,
            -0.008972426876425743,
            -0.026634568348526955,
            0.01347014494240284,
            0.0009851542999967933,
            0.000578349397983402,
            -0.015412362292408943,
            -0.0262648556381464,
            -0.012669342570006847,
            -0.05116123706102371,
            0.007086978759616613,
            0.028391065075993538,
            -0.02615673840045929,
            -0.024509405717253685,
            -0.00017795962048694491,
            -0.022050069645047188,
            0.029407214373350143,
            0.005926116369664669,
            -0.024815531447529793,
            -0.001250395318493247,
            -0.016813604161143303,
            -0.027153197675943375,
            -0.0011614259565249085,
            0.02456919476389885,
            -0.026343977078795433,
            0.011901374906301498,
            -0.01937214843928814,
            -0.007748942822217941,
            0.018003784120082855,
            -0.00514735234901309,
            -0.00597841152921319,
            0.012969596311450005,
            0.0049371602945029736,
            0.04326200112700462,
            -0.018079126253724098,
            -0.01471842173486948,
            0.014991079457104206,
            0.023989446461200714,
            0.0076184142380952835,
            0.005635575391352177,
            0.017937185242772102,
            0.010943797416985035,
            0.004830388352274895,
            -0.018733160570263863,
            0.014962301589548588,
            -0.023211905732750893,
            -0.012236145325005054,
            -0.003086585784330964,
            0.027921369299292564,
            -0.005019416566938162,
            0.015837419778108597,
            -0.00492685753852129,
            0.01845165714621544,
            0.025099683552980423,
            -0.036731816828250885,
            0.0072708227671682835,
            0.021690143272280693,
            -0.015221226960420609,
            -0.012678567320108414,
            -0.013852999545633793,
            -0.004780002404004335,
            -0.02111259289085865,
            0.0067856041714549065,
            0.02600277215242386,
            0.00670649902895093,
            -0.04207069054245949,
            0.03144848346710205,
            -0.01704520918428898,
            -0.009237492457032204,
            0.003207781817764044,
            0.015230497345328331,
            -0.004962919279932976,
            0.059264473617076874,
            -0.017179114744067192,
            -0.014844668097794056,
            0.010514050722122192,
            0.0019266214221715927,
            0.03130132704973221,
            0.004125883337110281,
            0.0626223236322403,
            0.029582200571894646,
            0.026342052966356277,
            0.005122124683111906,
            -0.018297625705599785,
            0.005410417448729277,
            0.03747302293777466,
            0.02074010670185089,
            0.0127007020637393,
            -0.030879992991685867,
            0.056438982486724854,
            0.01898534595966339,
            -0.0021120484452694654,
            -0.02489588037133217,
            0.012557253241539001,
            -0.012769597582519054,
            0.012486979365348816,
            -0.0015141103649511933,
            0.00013044037041254342,
            0.02685909904539585,
            0.016697313636541367,
            0.010775923728942871,
            -0.004788608755916357,
            -0.003320610150694847,
            0.0002128347405232489,
            -0.024355176836252213,
            0.011652222834527493,
            0.0015867812326177955,
            0.005739676766097546,
            -0.040305931121110916,
            0.021848900243639946,
            -0.017785508185625076,
            -0.03354635089635849,
            -0.002141188131645322,
            0.012964324094355106,
            -0.02321464754641056,
            0.019630370661616325,
            -0.003924974240362644,
            0.018788626417517662,
            -0.051408667117357254,
            0.00392663711681962,
            0.018301701173186302,
            0.01433888915926218,
            -0.011399287730455399,
            -0.017092736437916756,
            0.009850268252193928,
            0.003180862171575427,
            -0.00946271512657404,
            -0.028471428900957108,
            0.029116882011294365,
            -0.015076344832777977,
            -0.03605659306049347,
            -0.026859553530812263,
            -0.016085373237729073,
            0.04101640358567238,
            0.023191403597593307,
            -0.045539677143096924,
            -0.014002894051373005,
            0.02826434187591076,
            -0.01324581727385521,
            0.05380524694919586,
            0.030478738248348236,
            0.009141913615167141,
            -0.008793653920292854,
            0.02067696489393711,
            -2.126008621416986e-05,
            -0.03920537978410721,
            0.02633107826113701,
            -0.014937232248485088,
            -0.016956573352217674,
            -0.03434836491942406,
            0.018020035699009895,
            0.005523044615983963,
            -0.026854392141103745,
            0.05176078900694847,
            -0.028909536078572273,
            0.014404927380383015,
            -0.025296812877058983,
            -0.0265218373388052,
            -0.029998166486620903,
            0.017854953184723854,
            -0.00470868032425642,
            -0.053641945123672485,
            0.003986819181591272,
            0.025876536965370178,
            -0.007377402391284704,
            0.01772516779601574,
            -0.010736915282905102,
            0.010064409114420414,
            0.003504446940496564,
            -0.012932423502206802,
            0.021301593631505966,
            0.008555952459573746,
            0.021625787019729614,
            0.018183402717113495,
            -0.019793331623077393,
            0.026585262268781662,
            0.0013827660586684942,
            0.01764996163547039,
            0.01738155633211136,
            -0.003403371898457408,
            -0.017909500747919083,
            0.03733768314123154,
            -0.007158113177865744,
            -0.014526762999594212,
            -0.009605885483324528,
            0.00359654170460999,
            0.0015482879243791103,
            -0.014640767127275467,
            0.0348273403942585,
            0.02048148773610592,
            -0.005358602851629257,
            0.024938253685832024,
            -0.03242624178528786,
            -0.014492537826299667,
            -0.007530298549681902,
            0.0005244063795544207,
            0.0017189776990562677,
            3.754380304599181e-05,
            0.010205578058958054,
            -0.022877080366015434,
            -0.018975701183080673,
            0.003308248007670045,
            -0.015239998698234558,
            0.0008324318332597613,
            -0.001169807743281126,
            0.029624145478010178,
            -0.03541192784905434,
            0.005561353173106909,
            0.03983880952000618
        ],
        [
            0.022350218147039413,
            0.026026267558336258,
            -0.014830128289759159,
            -0.02752017229795456,
            0.02608180232346058,
            -0.012234500609338284,
            0.026290029287338257,
            -0.019306374713778496,
            0.013304006308317184,
            0.014463434927165508,
            0.014762943610548973,
            0.007617498282343149,
            0.04856939986348152,
            -0.02165594883263111,
            -0.03295610100030899,
            0.03371252864599228,
            -0.0004278668202459812,
            -0.00043006351916119456,
            0.007374881766736507,
            -0.02320980653166771,
            -0.018750790506601334,
            0.014601330272853374,
            -0.024703970178961754,
            0.013748952187597752,
            -0.021285956725478172,
            0.021049192175269127,
            0.04211415722966194,
            -0.0034857119899243116,
            0.017945997416973114,
            0.001469131326302886,
            -0.004443400073796511,
            0.0002835642371792346,
            0.0004922406515106559,
            -0.0035148360766470432,
            0.0022248094901442528,
            -0.006843081209808588,
            -0.026252461597323418,
            -0.03141540288925171,
            -0.015852468088269234,
            0.01458519883453846,
            0.010908237658441067,
            0.0042592925019562244,
            -0.004176417365670204,
            0.0010680147679522634,
            0.0029893291648477316,
            0.04891417175531387,
            0.005664070136845112,
            0.023947877809405327,
            0.023877741768956184,
            -0.009802648797631264,
            0.015935981646180153,
            -0.013409899547696114,
            -0.013804550282657146,
            -0.042457930743694305,
            -0.006430739536881447,
            -0.0051888287998735905,
            0.03126651793718338,
            0.002012659329921007,
            0.02114059589803219,
            0.025870319455862045,
            -0.012264219112694263,
            -0.029333412647247314,
            0.015825675800442696,
            0.00719848508015275,
            -0.013930974528193474,
            0.020001446828246117,
            0.025422900915145874,
            0.010957261547446251,
            -0.02459266409277916,
            -0.011516259051859379,
            0.00843736995011568,
            -0.030592234805226326,
            0.009041081182658672,
            -0.014437727630138397,
            -0.06177947670221329,
            -0.004091012757271528,
            0.018611691892147064,
            -0.005690528079867363,
            -0.051978446543216705,
            -0.032001424580812454,
            -0.0031278622336685658,
            -0.004366166424006224,
            0.006258941721171141,
            0.00635487912222743,
            0.004040962550789118,
            0.02002299390733242,
            -0.004532743711024523,
            -0.013259613886475563,
            -0.03274433687329292,
            -0.035963401198387146,
            -0.013200000859797001,
            0.02963271364569664,
            -0.023474685847759247,
            -0.0311637781560421,
            0.014967728406190872,
            -0.026392662897706032,
            0.04107899218797684,
            0.016507351770997047,
            0.01027680654078722,
            0.021822446957230568,
            -0.003063441952690482,
            0.034495867788791656,
            -0.0002905453438870609,
            0.016905151307582855,
            0.017241422086954117,
            0.011521550826728344,
            0.0048410482704639435,
            -0.007463931571692228,
            0.02738315984606743,
            -0.002993370871990919,
            -0.028528276830911636,
            0.017458798363804817,
            -0.005667910445481539,
            0.000507621094584465,
            0.03458063304424286,
            0.002282962203025818,
            -0.005027320235967636,
            -0.002749646781012416,
            -0.007325478829443455,
            0.021273866295814514,
            0.035819582641124725,
            -0.0197101179510355,
            -0.04557861387729645,
            -0.0003758871171157807,
            -0.008589518256485462,
            -0.003938976209610701,
            -0.010415931232273579,
            0.020478716120123863,
            -0.0027893029619008303,
            -0.014012064784765244,
            0.028870316222310066,
            -0.0075565543957054615,
            -0.014328817836940289,
            0.04994223266839981,
            -0.01461261510848999,
            0.010064863599836826,
            -0.0167950801551342,
            0.04635195806622505,
            0.022002087906003,
            0.018870580941438675,
            -0.02805199846625328,
            0.024813037365674973,
            -0.03141070529818535,
            -0.01500978134572506,
            0.0388868972659111,
            0.008806491270661354,
            -0.012437638826668262,
            -0.008403540588915348,
            -0.013372709043323994,
            0.007322615943849087,
            -0.009417841210961342,
            -0.006081356666982174,
            -0.035492219030857086,
            0.0027139028534293175,
            -0.013887993060052395,
            0.007659406401216984,
            0.016880454495549202,
            0.0072788335382938385,
            0.022177815437316895,
            0.005581050179898739,
            0.009971436113119125,
            0.024111133068799973,
            0.015058125369250774,
            0.042143117636442184,
            -0.01581057906150818,
            -0.05472344532608986,
            -0.000993961002677679,
            0.008388523012399673,
            -0.03796489164233208,
            -0.009867308661341667,
            0.017582278698682785,
            0.0010729944333434105,
            -0.01615324430167675,
            0.0002700743789318949,
            0.01592756249010563,
            0.014149772003293037,
            0.0022861002944409847,
            0.003026754828169942,
            -0.05347554758191109,
            0.016707951202988625,
            0.02709805592894554,
            -0.033943817019462585,
            -0.015063800849020481,
            0.020206494256854057,
            0.026428423821926117,
            0.0015818029642105103,
            0.055633146315813065,
            0.02743581123650074,
            0.018797975033521652,
            0.03896778076887131,
            0.00931559782475233,
            -0.00758080231025815,
            0.01832679659128189,
            -0.0010063850786536932,
            0.014517523348331451,
            -0.0328814797103405,
            0.023523321375250816,
            -0.023974981158971786,
            0.01400576438754797,
            0.005995612125843763,
            -0.02126605063676834,
            -0.0036096081603318453,
            0.0018167722737416625,
            0.03324626758694649,
            -0.016200290992856026,
            -0.03176437318325043,
            -0.06425770372152328,
            -0.011375241912901402,
            0.017667263746261597,
            -0.015612811781466007,
            0.0009711956372484565,
            0.0035064094699919224,
            0.009689644910395145,
            -0.03323827311396599,
            -0.002120360964909196,
            -0.017002278938889503,
            0.033707164227962494,
            -0.03016076050698757,
            0.018292641267180443,
            0.014444938860833645,
            -0.003804284380748868,
            0.024885259568691254,
            -0.012769052758812904,
            -0.007459374610334635,
            0.013065404258668423,
            0.062137216329574585,
            -0.004613326862454414,
            0.01680821180343628,
            0.038887761533260345,
            0.024906137958168983,
            -0.008421673439443111,
            0.025026090443134308,
            -0.009954606182873249,
            -0.007481145206838846,
            0.021563075482845306,
            -0.006037593819200993,
            0.024141648784279823,
            -0.00961910467594862,
            -0.016859034076333046,
            0.004493034444749355,
            0.015761883929371834,
            0.020598577335476875,
            0.0034201964735984802,
            0.007329610176384449,
            -0.018846191465854645,
            -0.025202259421348572,
            0.018445724621415138,
            0.006382762920111418,
            -0.02864621765911579,
            0.002318437211215496,
            0.01571568287909031,
            -0.007792170625180006,
            -0.041025713086128235,
            -0.01637895777821541,
            0.022574730217456818,
            0.0042421575635671616,
            0.010888364166021347,
            0.023904502391815186,
            -0.010383700020611286,
            0.05354190990328789,
            0.021613864228129387,
            -0.039358630776405334,
            -0.022433266043663025,
            -0.0016358839347958565,
            0.01618042029440403,
            -0.01943598873913288,
            0.008523600175976753,
            0.024241244420409203,
            -0.013887067325413227,
            0.010436245240271091,
            -0.018546484410762787,
            0.01308780163526535,
            -0.021058689802885056,
            -0.019692203029990196,
            0.00433862442150712,
            0.004240376874804497,
            0.021996434777975082,
            0.021463820710778236,
            -0.0070631918497383595,
            -0.017046447843313217,
            -0.04273008927702904,
            0.019329102709889412,
            -0.013902172446250916,
            0.03720685467123985,
            0.02596369758248329,
            0.01182801928371191,
            0.0204935222864151,
            0.044011231511831284,
            0.015429339371621609,
            -0.01855584979057312,
            -0.019075943157076836,
            -0.013756060041487217,
            -0.026372551918029785,
            0.026823285967111588,
            -0.013817500323057175,
            0.014706217683851719,
            0.010827742516994476,
            -0.03625883907079697,
            0.010919778607785702,
            -0.0022436578292399645,
            0.017038771882653236,
            -0.030581949278712273,
            0.009914105758070946,
            -0.0018516785930842161,
            0.013376917690038681,
            -0.025477509945631027,
            0.038519591093063354,
            -0.02126721292734146,
            0.020614149048924446,
            -0.015452239662408829,
            -0.020978253334760666,
            0.0064780875109136105,
            0.025554727762937546,
            -0.033963676542043686,
            -0.006979476194828749,
            0.020423322916030884,
            0.04223025590181351,
            -0.02181633561849594,
            -0.0258816909044981,
            0.010360026732087135,
            0.02837924286723137,
            0.00260819005779922,
            -0.024230513721704483,
            -0.014792175963521004,
            0.021311240270733833,
            -0.020840641111135483,
            -0.04161118343472481,
            0.02082190290093422,
            -0.022519147023558617,
            -0.025037147104740143,
            0.03450477495789528,
            0.023763909935951233,
            -0.04396064952015877,
            0.03262026607990265,
            0.01491194311529398,
            0.015566116198897362,
            0.022729910910129547,
            0.02205544337630272,
            -0.024909095838665962,
            0.03355811908841133,
            -0.008211632259190083,
            -0.0001176938894786872,
            0.03373485058546066,
            -0.02667558752000332,
            -0.028182173147797585,
            0.03939373418688774,
            0.018448369577527046,
            -0.017969971522688866,
            -0.024690400809049606,
            0.03789478540420532,
            -0.004668985493481159,
            -0.007264321204274893,
            0.04064462333917618,
            -0.001793703529983759,
            0.03605126962065697,
            0.007891228422522545,
            -0.018545400351285934,
            -0.010365672409534454,
            -0.03842424228787422,
            0.0289760734885931,
            -0.032648589462041855,
            0.01955661177635193,
            -0.008937663398683071,
            0.018588747829198837,
            -0.0024714150931686163,
            0.008618454448878765,
            0.005018081981688738,
            -0.022882454097270966,
            0.026909111067652702,
            -0.004046838730573654,
            0.025685109198093414,
            -0.007085652090609074,
            0.027552876621484756,
            0.022373979911208153,
            -0.03062901832163334,
            -0.003000271273776889,
            0.035587459802627563,
            0.003230466740205884,
            0.0032732163090258837,
            -0.004126777872443199,
            0.025591539219021797,
            -0.027183465659618378,
            -0.005256149917840958,
            0.03146929293870926,
            0.008978038094937801,
            0.00700678676366806,
            -0.02794354408979416,
            -0.04882103204727173,
            0.0037781952414661646,
            -0.010233311913907528,
            -0.03684097155928612,
            0.015813594684004784,
            -0.024715378880500793,
            0.012590466998517513,
            -0.005921807140111923,
            0.0034323649015277624,
            -0.00676637003198266,
            -0.0010764384642243385,
            0.03257634863257408,
            -0.022980302572250366,
            0.015199459157884121,
            -0.00945332832634449,
            -0.025779346004128456,
            0.0071244798600673676,
            -0.01861731708049774,
            0.002188181970268488,
            -0.02094576321542263,
            -0.020409950986504555,
            0.014212810434401035,
            0.01905190572142601,
            0.009949480183422565,
            0.012226270511746407,
            0.023547694087028503,
            0.006986694876104593,
            0.00028179437504149973,
            0.00979370903223753,
            0.009826465509831905,
            0.04393822327256203,
            -0.015183293260633945,
            0.026502832770347595,
            0.015711788088083267,
            0.03925083950161934,
            -0.010070716962218285,
            0.008867812342941761,
            0.022978071123361588,
            -0.012978763319551945,
            -0.04096760228276253,
            0.023024754598736763,
            0.013179084286093712,
            0.00791165605187416,
            0.050011783838272095,
            0.007732130587100983,
            0.027172565460205078,
            -0.016598625108599663,
            0.003969305660575628,
            -0.018576746806502342,
            -0.032531291246414185,
            0.029345722869038582,
            0.030992891639471054,
            0.018064988777041435,
            -0.03625798597931862,
            0.022778432816267014,
            -0.0010796443093568087,
            -0.033649295568466187,
            -0.02809956669807434,
            0.01592366397380829,
            -0.011965458281338215,
            0.030637791380286217,
            0.012152329087257385,
            0.01785462535917759,
            -0.010573755949735641,
            0.0027580372989177704,
            -0.03466495871543884,
            -0.01614629477262497,
            -0.018989719450473785,
            -0.0233236663043499,
            -0.012377659790217876,
            0.03212684020400047,
            0.011639893986284733,
            -0.02267175167798996,
            0.010888060554862022,
            0.009613392874598503,
            0.018671853467822075,
            -0.031021635979413986,
            0.019229412078857422,
            0.038333822041749954,
            0.015720445662736893,
            0.020614398643374443,
            0.002511217026039958,
            -0.009517432190477848,
            0.0153367780148983,
            -0.028662392869591713,
            -0.01267115119844675,
            -0.028854986652731895,
            -0.02448861114680767,
            -0.00835522823035717,
            -0.0224609412252903,
            -0.037857264280319214,
            -0.018577225506305695,
            -0.013897120021283627,
            0.022022154182195663,
            -0.02605786919593811,
            -0.06424397230148315,
            -0.02630123682320118,
            0.014922444708645344,
            0.023345889523625374,
            0.029367530718445778,
            0.008222746662795544,
            -0.06352709978818893,
            0.01803523860871792,
            0.009406152181327343,
            0.010607033036649227,
            -0.003686700016260147,
            0.04630396142601967,
            0.035537511110305786,
            -0.0037013597320765257,
            0.04023418202996254,
            -0.0017759115435183048,
            -0.011917229741811752,
            -0.003791417460888624,
            0.014854732900857925,
            0.020435091108083725,
            0.034973375499248505,
            -0.012867500074207783,
            -0.002858915366232395,
            0.007566355634480715,
            0.021975213661789894,
            -0.005203224718570709,
            -0.002960146637633443,
            0.03361832723021507,
            0.024187905713915825,
            -0.019901694729924202,
            0.011793427169322968,
            -0.024901246652007103,
            -0.0005555293755605817,
            0.027210485190153122,
            0.03595063462853432,
            0.014165611937642097,
            -0.01874491013586521,
            0.024355903267860413,
            0.011935964226722717,
            0.021547038108110428,
            0.022008446976542473,
            0.007016307674348354,
            -0.03280557319521904,
            -0.014069540426135063,
            -0.09709300100803375,
            0.025476882234215736,
            -0.034978024661540985,
            0.05587706342339516,
            -0.03379718214273453,
            0.000752199674025178,
            -0.013190148398280144,
            -0.015311415307223797,
            -0.02427915297448635,
            0.01331235095858574,
            -0.00884756725281477,
            -0.0037068610545247793,
            -0.004281111992895603,
            -0.006924493703991175,
            0.00664056371897459,
            -0.02445865049958229,
            0.00495489127933979,
            0.026122696697711945,
            -0.013688144274055958,
            -0.015062689781188965,
            -0.0010679698316380382,
            -0.009036979638040066,
            0.01991400495171547,
            -0.02048417367041111,
            0.022744908928871155,
            0.019984278827905655,
            -0.007332610432058573,
            -0.0034708301536738873,
            0.004242932889610529,
            -0.024162519723176956,
            0.01903727278113365,
            -0.008294899016618729,
            0.010422098450362682,
            -0.03291698172688484,
            -0.016381630674004555,
            -0.01889878511428833,
            -0.005330568179488182,
            -0.03187520056962967,
            0.01377075258642435,
            -0.012509367428719997,
            0.023366427049040794,
            -0.004465391393750906,
            0.008589664474129677,
            -0.02715153805911541,
            -0.0330270417034626,
            -0.02254934050142765,
            -0.024051200598478317,
            -0.018239464610815048,
            -0.021912578493356705,
            -0.017384234815835953,
            0.010322848334908485,
            -0.0345948226749897,
            -0.02677682600915432,
            0.002031720243394375,
            0.02488899417221546,
            -0.04434717074036598,
            0.03022465668618679,
            -0.0034072324633598328,
            0.029312580823898315,
            -0.00815886165946722,
            0.01991608366370201,
            0.002263161586597562,
            0.019255829975008965,
            0.013664819300174713,
            -0.024331625550985336,
            -0.030237317085266113,
            0.015218915417790413,
            0.04097666218876839,
            -0.01668521948158741,
            -0.010708879679441452,
            0.013578131794929504,
            -0.009563537314534187,
            0.031214598566293716,
            -0.05177020654082298,
            -0.007191958371549845,
            0.042727213352918625,
            0.042955730110406876,
            0.033580247312784195,
            -0.023666726425290108,
            -0.006305992137640715,
            0.003956539556384087,
            -0.016451271250844002,
            0.050389569252729416,
            -0.01795198582112789,
            0.035114772617816925,
            0.010811707004904747,
            -0.01601879671216011,
            -0.003982217982411385,
            0.009542267769575119,
            -0.03139576315879822,
            -0.024202601984143257,
            -0.009754060767591,
            0.014955149963498116,
            -0.010018305853009224,
            0.03609800711274147,
            0.0149003891274333,
            -0.022057291120290756,
            -0.014279619790613651,
            1.222280025103828e-05,
            0.031049566343426704,
            -0.025357410311698914,
            -0.0010053839068859816,
            0.030828606337308884,
            -0.028013385832309723,
            -0.015429753810167313,
            0.03166082501411438,
            0.030623402446508408,
            -0.026347355917096138,
            0.012185645289719105,
            0.014489181339740753,
            -0.021536393091082573,
            0.030690761283040047,
            0.01977941207587719,
            0.009116525761783123,
            0.04115083068609238,
            0.004557223059237003,
            0.015063217841088772,
            -0.035208702087402344,
            0.022951653227210045,
            0.027455821633338928,
            -0.01005643978714943,
            -0.014717957004904747,
            -0.020135726779699326,
            -0.0022098396439105272,
            0.0001124849368352443,
            -0.0033142364118248224,
            -0.02096702717244625,
            0.019472982734441757,
            0.0289082583039999,
            0.014136465266346931,
            0.011145227588713169,
            -0.0003589077095966786,
            0.006803198717534542,
            0.010221872478723526,
            0.022583292797207832,
            -0.011249888688325882,
            -0.028518004342913628,
            -0.013874204829335213,
            -0.010545749217271805,
            -0.02491539530456066,
            0.01092199794948101,
            -0.01200410258024931,
            0.008990949019789696,
            -0.0037371190264821053,
            -0.008108719252049923,
            -0.019030777737498283,
            0.0146475275978446,
            0.043298181146383286,
            0.01039971224963665,
            0.02939252182841301,
            -0.01292331237345934,
            0.018880534917116165,
            -0.01694461889564991,
            -0.0002969881461467594,
            -0.01987115666270256,
            0.01569274626672268,
            0.01929394155740738,
            0.03063477762043476,
            0.03085387870669365,
            0.018267985433340073,
            0.006256589200347662,
            0.0009470012155361474,
            0.007535547483712435,
            -0.002850082702934742,
            0.05699322745203972,
            -0.031944021582603455,
            -0.04251815006136894,
            0.004807113204151392,
            -0.023978590965270996,
            -0.009475542232394218,
            -0.015274906530976295,
            0.011037640273571014,
            0.012244529090821743,
            -0.011210348457098007,
            0.001922055846080184,
            -0.006969870068132877,
            -0.012379170395433903,
            -0.02032485045492649,
            0.014940918423235416,
            -0.014754434116184711,
            -0.015038211829960346,
            -0.026022931560873985,
            0.019015362486243248,
            0.017675695940852165,
            -0.013302529230713844,
            0.014541399665176868,
            -0.006397591903805733,
            -0.01738128438591957,
            -0.04806609824299812,
            0.004965800326317549,
            -6.292858870438067e-06,
            -0.010909576900303364,
            -0.0179506354033947,
            -0.0026978361420333385,
            0.0021008045878261328,
            0.0056188516318798065,
            0.034241531044244766,
            -0.0008583072922192514,
            -0.018134459853172302,
            -0.0018055372638627887,
            0.010825520381331444,
            -0.007463865913450718,
            0.03218530863523483,
            -0.008708994835615158,
            0.009364294819533825,
            0.018832840025424957,
            -0.03007785603404045,
            0.008218938484787941,
            -0.03045920468866825,
            -0.019789643585681915,
            -0.006485989782959223,
            0.02330292947590351,
            0.02971556782722473,
            -0.04261460155248642,
            0.0040010614320635796,
            -0.03931137174367905,
            0.028534818440675735,
            0.01917407661676407,
            -0.0014760970370844007,
            -0.0008180097211152315,
            -0.05325135588645935,
            -0.025947516784071922,
            -0.010465381667017937,
            0.032632309943437576,
            -0.020892908796668053,
            0.008172492496669292,
            -0.007665421348065138,
            -0.036442868411540985,
            -0.02441507950425148,
            0.026040444150567055,
            -0.01736489124596119,
            0.02637440338730812,
            -0.004903254099190235,
            0.011224700137972832,
            -0.031222879886627197,
            0.0022208027075976133,
            0.005162862595170736,
            -0.018010083585977554,
            0.015295983292162418,
            -0.029108617454767227,
            -0.0007579700904898345,
            0.018939757719635963,
            -0.01791011542081833,
            -0.03077774867415428,
            0.016612784937024117,
            -0.01095107477158308,
            -0.00763572845607996,
            -0.021280227228999138,
            -0.006444524973630905,
            0.019317196682095528,
            -0.017463674768805504,
            -0.00398151995614171,
            0.029581408947706223,
            -0.02085239067673683,
            0.005012622103095055,
            -0.008910385891795158,
            0.0058987876400351524,
            -0.005463159643113613,
            -0.007390444632619619,
            0.01263717282563448,
            -0.026552394032478333,
            0.008152752183377743,
            0.01583133637905121,
            -0.018331628292798996,
            0.002722634468227625,
            -0.0011293068528175354,
            -0.0034832009114325047,
            0.0032456303015351295,
            0.0006654627504758537,
            -0.02972237952053547,
            0.006615950260311365,
            -0.0007567291031591594,
            -0.01786455325782299,
            -0.05818435177206993,
            0.04609566554427147,
            0.013088268227875233,
            0.005606510676443577,
            -0.014720804058015347,
            -0.028506256639957428,
            -0.057897452265024185,
            -0.020719291642308235,
            -0.007399634458124638,
            -0.0030144602060317993,
            -0.0005837833159603179,
            -0.0161374993622303,
            -0.015640417113900185,
            0.016583915799856186,
            0.011145029217004776,
            0.019669365137815475,
            -0.0064082699827849865,
            -0.003379396628588438,
            -0.00040397359407506883,
            0.01390831544995308,
            -0.055542849004268646,
            -0.0241092462092638,
            0.0007510274299420416,
            0.02820626087486744,
            -0.009755738079547882,
            -0.012957599945366383,
            -0.036836158484220505,
            -0.03370342403650284,
            -0.020738517865538597,
            0.021790454164147377,
            -0.03965867683291435,
            0.0008705221698619425,
            0.008387891575694084,
            0.00900275819003582,
            0.010408611036837101,
            -0.05886460468173027,
            0.010890930891036987,
            0.024649402126669884,
            0.019077587872743607,
            -0.02794087678194046,
            0.04981972649693489,
            -0.008958137594163418,
            0.018669305369257927,
            -0.015953868627548218,
            0.00416388176381588,
            0.027121730148792267,
            0.012193519622087479,
            0.03995765745639801,
            -0.012831887230277061,
            -0.0010055379243567586,
            -0.012590565718710423,
            -0.03295792639255524,
            0.03108843043446541,
            -0.03346705436706543,
            0.022637007758021355,
            0.02478998526930809,
            -0.026875510811805725,
            0.018158812075853348,
            -0.012587001547217369,
            0.011619916185736656,
            0.004086628090590239,
            -0.002701498568058014,
            0.01157818827778101,
            0.015422296710312366,
            -0.03672367334365845,
            0.004024005960673094,
            0.021138938143849373,
            -0.012978787533938885,
            -0.01902698539197445,
            -0.011637980118393898,
            -0.040280114859342575,
            0.010832610540091991,
            -0.014654948376119137,
            0.005851351656019688,
            -0.0040590763092041016,
            0.01991572044789791,
            0.0047512357123196125,
            0.0355989895761013,
            -0.026064898818731308,
            0.007982888258993626,
            0.024846751242876053,
            0.04025409370660782,
            0.0226829145103693,
            -0.021852409467101097,
            0.005174792371690273,
            -0.029882380738854408,
            -0.0007858552853576839,
            0.008255723863840103,
            0.03889303654432297,
            0.0126425726339221,
            0.019209926947951317,
            0.016612639650702477,
            -0.027930598706007004,
            -0.02870192937552929,
            -0.016737699508666992,
            0.004788583144545555,
            -0.008273632265627384,
            -0.03249527886509895,
            -0.03678000345826149,
            -0.02810569293797016,
            -0.01251411996781826,
            -0.023864824324846268,
            0.004072541370987892,
            -0.011449438519775867,
            -0.011965873651206493,
            -0.0386163592338562,
            -0.022913211956620216,
            0.015321297571063042,
            -0.01773105002939701,
            -0.010119330137968063,
            -0.01861758902668953,
            0.030339833348989487,
            -0.028986217454075813,
            0.02074936032295227,
            -0.02919047512114048,
            0.040379781275987625,
            -0.006368125788867474,
            -0.016926145181059837,
            0.013551363721489906,
            -0.0237168800085783,
            0.016590779647231102,
            0.01732478104531765,
            0.033944837749004364,
            -0.020933901891112328,
            -0.02100514806807041,
            -0.021945763379335403,
            -0.008770515210926533,
            -0.0014129088958725333,
            0.04286127910017967,
            -0.012278662994503975,
            -0.029157934710383415,
            -0.037037692964076996,
            0.0063616870902478695,
            -0.01976110227406025,
            0.031460437923669815,
            0.015605182386934757,
            -0.00429247971624136,
            -0.02136480063199997,
            -0.006664430256932974,
            0.0319259949028492,
            0.015255494974553585,
            -0.003779012244194746,
            -0.0038407635875046253,
            -0.07507672160863876,
            0.027898509055376053,
            -0.01997695490717888,
            0.009828210808336735,
            -0.022755075246095657,
            0.016854876652359962,
            0.01732431910932064,
            -0.007630282547324896,
            0.015754468739032745,
            -0.021391892805695534,
            -0.003989976365119219,
            0.014726834371685982,
            0.003722004359588027,
            -0.03707093372941017,
            -0.020017575472593307,
            0.004113325383514166,
            -0.011513873934745789,
            -0.032487425953149796,
            0.025873908773064613,
            -0.052099019289016724,
            -0.01560980360955,
            0.011871213093400002,
            0.01571384258568287,
            0.018427781760692596,
            -0.019920431077480316,
            -0.004732372239232063,
            0.010679599829018116,
            0.01754019781947136,
            0.024973073974251747,
            0.019084032624959946,
            -0.02764808014035225,
            -0.03331722319126129,
            0.014004966244101524,
            0.00894535705447197,
            -0.02038218080997467,
            0.03198115527629852,
            0.0018880496500059962,
            0.008563525974750519,
            0.020513707771897316,
            -0.017019644379615784,
            0.0006193970330059528,
            0.03109198436141014,
            0.015402142889797688,
            -0.00504626939073205,
            0.0002269542746944353,
            -0.003574208589270711,
            -0.006164465565234423,
            -0.002277467167004943,
            0.013151080347597599,
            -0.0009619088377803564,
            -0.01202180702239275,
            0.0031604724936187267,
            -0.00144335872028023,
            -0.05912543460726738,
            -0.005509550217539072,
            0.035626620054244995,
            0.03591468185186386,
            0.023765042424201965,
            0.009189436212182045,
            0.027052246034145355,
            0.016528453677892685,
            -0.020782826468348503,
            0.017675643786787987,
            0.02421960048377514,
            -0.011262240819633007,
            -0.04150563105940819,
            -0.00836084969341755,
            0.011560969986021519,
            9.626471000956371e-05,
            -0.013151734136044979,
            -0.0008214663248509169,
            0.010221447795629501,
            -0.01174230594187975,
            0.0010254948865622282,
            -0.013375469483435154,
            0.023912591859698296,
            0.033093251287937164,
            0.025685517117381096,
            0.016227271407842636,
            0.015107091516256332,
            0.044441938400268555,
            -0.01107383705675602
        ],
        [
            0.006442253943532705,
            0.007431611884385347,
            0.020777085795998573,
            0.01701684482395649,
            -0.021315665915608406,
            0.02301461435854435,
            -0.01934678480029106,
            0.0394284687936306,
            -0.011009231209754944,
            -0.0556989386677742,
            0.00499246921390295,
            -0.0020233907271176577,
            -0.020425423979759216,
            -0.006286788731813431,
            0.03468366339802742,
            0.0565098337829113,
            -0.010075890459120274,
            0.020394377410411835,
            -0.016023734584450722,
            0.003628098638728261,
            0.02581988088786602,
            -0.020810753107070923,
            0.015074159950017929,
            -0.026301728561520576,
            0.06527315825223923,
            0.0028003142215311527,
            0.0011986466124653816,
            0.002615567296743393,
            -0.03536933660507202,
            0.009552277624607086,
            -0.025904439389705658,
            0.0170423723757267,
            0.005102134775370359,
            0.002129161963239312,
            -0.039393939077854156,
            -0.013670103624463081,
            0.018289649859070778,
            -0.006445096340030432,
            -0.020283333957195282,
            0.02978321723639965,
            0.022493654862046242,
            0.011506804265081882,
            0.05111740902066231,
            0.025433484464883804,
            -0.0002985932515002787,
            0.03702520579099655,
            0.010076153092086315,
            0.04408176988363266,
            -0.035415638238191605,
            -0.010072916746139526,
            -0.044196538627147675,
            -0.005321695934981108,
            -0.06043107435107231,
            -0.061982885003089905,
            -0.004559377208352089,
            -0.009710748679935932,
            0.029297759756445885,
            -0.01205369085073471,
            0.005492608528584242,
            -0.015483681112527847,
            0.029515763744711876,
            -0.0076172081753611565,
            -0.018581271171569824,
            0.001373297767713666,
            -0.006043310277163982,
            0.0012163050705567002,
            -0.016468549147248268,
            0.007409907411783934,
            0.00824811216443777,
            -0.03217039629817009,
            -0.0374443344771862,
            -0.05205947905778885,
            -0.0036742319352924824,
            0.03337078541517258,
            -0.006485242396593094,
            0.00901760533452034,
            -0.02888287417590618,
            0.051419153809547424,
            -0.01078627910465002,
            -0.033311452716588974,
            0.021992135792970657,
            0.014233789406716824,
            0.026100555434823036,
            -0.018728889524936676,
            -0.008274258114397526,
            0.00292865838855505,
            -0.007858962751924992,
            -0.025720937177538872,
            -0.021204521879553795,
            -0.009027508087456226,
            0.0002535862731747329,
            0.020321715623140335,
            0.04629405960440636,
            -0.00030550043447874486,
            -0.03103945218026638,
            -0.029361942782998085,
            0.008409063331782818,
            -0.0397292785346508,
            -0.018890906125307083,
            0.005268095526844263,
            0.03564738482236862,
            -0.004276887513697147,
            -0.020245742052793503,
            0.023394014686346054,
            -0.005638364236801863,
            -0.0004896551836282015,
            -0.01547298301011324,
            -0.018490521237254143,
            0.033097490668296814,
            0.01813768409192562,
            0.006939105689525604,
            0.034268513321876526,
            0.01999569498002529,
            -0.017735719680786133,
            0.03873791918158531,
            -0.01318578701466322,
            -0.00924675352871418,
            -0.011412952095270157,
            0.015207755379378796,
            -0.024358579888939857,
            0.008897211402654648,
            0.0016915957676246762,
            0.024216413497924805,
            0.01266186311841011,
            -0.02261262945830822,
            -0.012756274081766605,
            0.002715477254241705,
            -0.005397025961428881,
            -0.02661309763789177,
            0.018149666488170624,
            -0.02385261468589306,
            0.011903706938028336,
            0.04414450377225876,
            0.05621611326932907,
            0.03923292085528374,
            -0.021269960328936577,
            -0.030479038134217262,
            0.018859542906284332,
            0.0048982929438352585,
            0.01689714752137661,
            -0.030668191611766815,
            0.018257243558764458,
            0.022925853729248047,
            -0.012315447442233562,
            0.03563439100980759,
            -0.0025005631614476442,
            0.005390173755586147,
            0.012530077248811722,
            0.022575609385967255,
            -0.024248607456684113,
            -0.03283906728029251,
            -0.033386580646038055,
            0.035807494074106216,
            0.005408549215644598,
            0.027554534375667572,
            0.006331350654363632,
            0.030822250992059708,
            0.004184077028185129,
            0.011975712142884731,
            -0.010430662892758846,
            0.009509927593171597,
            -0.051432713866233826,
            -0.010280906222760677,
            0.012026658281683922,
            0.013497982174158096,
            0.026473786681890488,
            -0.0683644711971283,
            -0.024232566356658936,
            -0.025104189291596413,
            -0.017284488305449486,
            0.015108972787857056,
            -0.0020887795835733414,
            -0.048766426742076874,
            0.05146650969982147,
            -0.01442433800548315,
            0.007285526487976313,
            0.02033400721848011,
            -0.007437750697135925,
            -0.029522685334086418,
            -0.04716259241104126,
            0.002863620175048709,
            -0.004139995668083429,
            0.06430494785308838,
            0.005104548763483763,
            -0.03704239800572395,
            0.03145848587155342,
            -0.03802914544939995,
            -0.03342406451702118,
            0.014939513988792896,
            -0.04153496399521828,
            0.0050656720995903015,
            0.029547646641731262,
            0.0145260663703084,
            -0.0029226241167634726,
            0.0020125864539295435,
            0.011399154551327229,
            0.03395751491189003,
            0.013239629566669464,
            -0.05662841722369194,
            0.0034312482457607985,
            -0.004416033625602722,
            0.019602352753281593,
            -0.028515750542283058,
            0.04090077057480812,
            0.0643070861697197,
            0.01697412133216858,
            0.0011090695625171065,
            -0.014628211967647076,
            -0.006775912828743458,
            0.02003045752644539,
            -0.0453573502600193,
            0.03847558796405792,
            -0.0009109310922212899,
            0.0010309013305231929,
            -0.022468730807304382,
            -0.018492748960852623,
            0.03125498443841934,
            0.015431259758770466,
            -0.018927771598100662,
            -0.013585682958364487,
            -0.029460318386554718,
            -0.010862733237445354,
            0.009575899690389633,
            0.009492438286542892,
            -0.0042466772720217705,
            0.007119226735085249,
            -0.026675034314393997,
            -0.01631849817931652,
            -0.009741593152284622,
            -0.002979590091854334,
            0.0055298092775046825,
            -0.020667701959609985,
            -0.03781871125102043,
            -0.012051688507199287,
            0.04759949818253517,
            0.03084629587829113,
            0.05254051834344864,
            -0.020115254446864128,
            -0.008840760216116905,
            0.03273531422019005,
            -0.019385362043976784,
            -0.024756193161010742,
            0.005652145016938448,
            -0.002488793106749654,
            -0.008216291666030884,
            0.0065899998880922794,
            -0.04589737951755524,
            0.01884813979268074,
            0.014929680153727531,
            0.034997306764125824,
            -0.016110427677631378,
            0.010695856995880604,
            0.006360500119626522,
            -0.02408544160425663,
            -0.00582887465134263,
            -0.0011520966654643416,
            -0.010815833695232868,
            0.017990240827202797,
            -0.0358860045671463,
            0.022391032427549362,
            0.024703634902834892,
            -0.0034608214627951384,
            0.020644044503569603,
            -0.030100412666797638,
            0.0060645816847682,
            -0.07136055082082748,
            0.015684962272644043,
            -0.01915166527032852,
            -0.0216695386916399,
            0.03127473592758179,
            0.04065326601266861,
            0.01248831395059824,
            0.003511332208290696,
            -0.02417347952723503,
            -0.05692019313573837,
            -0.008289567194879055,
            -0.04803314059972763,
            0.03148401901125908,
            0.0032928164582699537,
            -0.03541262075304985,
            -0.0048807584680616856,
            0.020878834649920464,
            0.00555008789524436,
            0.023814307525753975,
            -0.025699101388454437,
            0.0022540497593581676,
            -0.0008387861889787018,
            -0.005259895697236061,
            0.012391743250191212,
            -0.01559560839086771,
            0.027191679924726486,
            -0.007976902648806572,
            -0.014282255433499813,
            0.004557939246296883,
            0.018847471103072166,
            0.01621202565729618,
            0.043264228850603104,
            -0.02802596427500248,
            0.007123125717043877,
            0.02094772644340992,
            -0.014070487581193447,
            -0.003324821125715971,
            0.0016787938075140119,
            0.02989819273352623,
            0.028511136770248413,
            0.009957116097211838,
            -0.012243536300957203,
            -0.039803266525268555,
            -0.023027950897812843,
            0.00460375752300024,
            0.017850680276751518,
            -0.022589296102523804,
            0.010769438929855824,
            -0.012398548424243927,
            0.03632934391498566,
            -0.034430183470249176,
            0.0006221020594239235,
            0.004882811568677425,
            0.0028718875255435705,
            0.0007141619571484625,
            -0.005995690356940031,
            0.04139324650168419,
            0.005672062747180462,
            0.027166254818439484,
            0.025820637121796608,
            0.016166428104043007,
            0.07096196711063385,
            -0.012097170576453209,
            0.010333775542676449,
            -0.031696904450654984,
            0.03353685513138771,
            0.005025531630963087,
            0.031654711812734604,
            -0.023376820608973503,
            -0.007878757081925869,
            -0.00717358011752367,
            -0.02700767107307911,
            -0.04368774592876434,
            0.02656468003988266,
            -0.014410898089408875,
            0.005385482218116522,
            0.04243117943406105,
            -0.007476486265659332,
            -0.013632114976644516,
            0.00867882464081049,
            -6.608897092519328e-05,
            -0.012411043979227543,
            0.00043590489076450467,
            -0.01382390595972538,
            0.04195106402039528,
            0.034696999937295914,
            -0.002734436886385083,
            0.0172647126019001,
            0.007547674234956503,
            -0.05683717131614685,
            -0.0029744768980890512,
            -0.0013219995889812708,
            0.03804418817162514,
            0.01728610321879387,
            -0.009159957058727741,
            -0.030991675332188606,
            0.03494736924767494,
            0.044592633843421936,
            -0.008664363995194435,
            0.009269662201404572,
            -0.0274325180798769,
            0.017795056104660034,
            -0.04376509413123131,
            0.02805187739431858,
            -0.015219909138977528,
            0.016767781227827072,
            -0.01601850800216198,
            -0.01472720317542553,
            0.0055111986584961414,
            0.017873477190732956,
            0.013948838226497173,
            0.014186863787472248,
            0.03832109272480011,
            -0.008920480497181416,
            -0.007266991771757603,
            0.020541954785585403,
            -0.0032235889229923487,
            0.015805084258317947,
            -0.003577539697289467,
            -0.017987729981541634,
            -0.05602152273058891,
            -0.017699824646115303,
            0.03511875495314598,
            -0.03433657065033913,
            -0.024720510467886925,
            -0.018859190866351128,
            -0.010807890444993973,
            0.026797914877533913,
            0.03184743598103523,
            -0.027370015159249306,
            -0.01435079425573349,
            0.010260582901537418,
            -0.01145364809781313,
            -0.015226677991449833,
            0.03891735151410103,
            0.006960815284401178,
            0.02391681633889675,
            0.01826508529484272,
            -0.026441901922225952,
            -0.02100776694715023,
            -0.0040091401897370815,
            0.013905663974583149,
            -0.008949046954512596,
            0.001436844584532082,
            0.026109671220183372,
            -0.0233954768627882,
            0.0328114815056324,
            0.012084415182471275,
            -0.016308724880218506,
            0.043880198150873184,
            -0.021187027916312218,
            -0.0002788702549878508,
            0.011009016074240208,
            0.026173416525125504,
            -0.03639271855354309,
            -0.017688455060124397,
            -0.0399399995803833,
            -0.013942851684987545,
            -0.02538503147661686,
            -0.03153330460190773,
            -0.04115762189030647,
            -0.018615487962961197,
            0.02074115164577961,
            -0.0147728705778718,
            -0.011312807910144329,
            0.015711991116404533,
            -0.018166610971093178,
            0.011712833307683468,
            0.0007720431894995272,
            -0.051760926842689514,
            0.04365158453583717,
            -0.03553340584039688,
            0.03489150479435921,
            -0.04136498644948006,
            0.028585370630025864,
            0.03225862607359886,
            0.0036650861147791147,
            0.022093812003731728,
            -0.015682581812143326,
            0.011767814867198467,
            0.00711814546957612,
            -0.025502655655145645,
            -0.05650704726576805,
            0.013595773838460445,
            0.009456470608711243,
            0.0194543469697237,
            -0.010703298263251781,
            0.0048499805852770805,
            -0.00865650549530983,
            -0.013909262605011463,
            -0.00944498647004366,
            -0.09347140043973923,
            0.012307330965995789,
            0.0004431809938978404,
            -0.0075953658670187,
            0.009486556053161621,
            -0.018077757209539413,
            -0.00023296099971048534,
            0.015810512006282806,
            0.0027395926881581545,
            -0.008305108174681664,
            0.01936323381960392,
            0.0026537617668509483,
            -0.0019964270759373903,
            0.026242390275001526,
            0.02217129059135914,
            0.02198522910475731,
            -0.004542688373476267,
            0.044718608260154724,
            0.022221213206648827,
            0.0012823882279917598,
            -0.007846302352845669,
            -0.026196060702204704,
            0.006214383523911238,
            -0.004917124751955271,
            0.0010711513459682465,
            -0.059574954211711884,
            0.008374229073524475,
            -0.0018452308140695095,
            0.06438621133565903,
            0.02694096788764,
            -0.025897594168782234,
            -0.0156671404838562,
            0.03801855817437172,
            -0.0031066255178302526,
            0.0031730635091662407,
            -0.02816259302198887,
            0.01784629002213478,
            0.004812293220311403,
            0.005193687044084072,
            0.021122921258211136,
            -0.0009331164183095098,
            -0.00020021646923851222,
            0.019653592258691788,
            0.0073560927994549274,
            -0.017954640090465546,
            -0.006389139220118523,
            -0.032336533069610596,
            0.013981443829834461,
            -0.007246100343763828,
            -0.022654175758361816,
            -0.021775823086500168,
            -0.020097609609365463,
            0.03332770988345146,
            -0.007449792698025703,
            -0.015006719157099724,
            0.03955887258052826,
            0.012902182526886463,
            -0.030793972313404083,
            0.028573911637067795,
            0.009778011590242386,
            -0.018217502161860466,
            -0.009295823983848095,
            -0.035324689000844955,
            -0.009697678498923779,
            -0.018915675580501556,
            -0.02766392007470131,
            -0.018013428896665573,
            -0.008166910149157047,
            -0.03981322422623634,
            0.0534684881567955,
            0.002161631127819419,
            0.01687096618115902,
            0.012864521704614162,
            -0.016440553590655327,
            0.003948360215872526,
            -0.00929288100451231,
            0.03918113932013512,
            0.020427720621228218,
            0.012016584165394306,
            0.05514709651470184,
            0.0014064614661037922,
            0.025018543004989624,
            0.01579350419342518,
            -0.024484090507030487,
            -0.004384058527648449,
            -0.017982250079512596,
            0.031572967767715454,
            -0.06500324606895447,
            -0.00910649262368679,
            -0.007847803644835949,
            -0.03241092711687088,
            0.0617985799908638,
            0.024400385096669197,
            0.0046563888899981976,
            0.0364215262234211,
            -0.024298159405589104,
            -0.0004202155687380582,
            0.00103430962190032,
            0.035445697605609894,
            0.013161794282495975,
            0.00011095134686911479,
            0.025744488462805748,
            0.023586703464388847,
            0.022606268525123596,
            0.02296631410717964,
            -0.008362995460629463,
            -0.00899564940482378,
            -0.032171085476875305,
            -0.016642609611153603,
            -0.025157837197184563,
            -0.005719111766666174,
            -0.022963672876358032,
            -0.013440507464110851,
            0.03826719895005226,
            0.003257759613916278,
            -0.031935784965753555,
            -0.004280423279851675,
            0.023677730932831764,
            -0.03885661065578461,
            -0.010121704079210758,
            0.0007713754894211888,
            0.008991296403110027,
            0.025750959292054176,
            -0.019822826609015465,
            0.0077555482275784016,
            0.0545070543885231,
            -0.0017846599221229553,
            0.022594541311264038,
            0.0026240262668579817,
            -0.004131196066737175,
            0.02099098265171051,
            -0.021483808755874634,
            0.010850326158106327,
            -0.020220885053277016,
            -0.010996391996741295,
            0.018118370324373245,
            -0.013177413493394852,
            0.031035341322422028,
            0.016899624839425087,
            -0.036881234496831894,
            0.0016329927602782845,
            -0.013659009709954262,
            0.044131580740213394,
            0.014508465304970741,
            0.007222943939268589,
            -0.011013111099600792,
            0.04195769876241684,
            0.024191109463572502,
            -0.0006758551462553442,
            -0.017575209960341454,
            0.038967084139585495,
            0.030492886900901794,
            0.00549632403999567,
            0.02543650195002556,
            -0.015177397057414055,
            -0.012757684104144573,
            -0.03405269235372543,
            0.014739586971700191,
            0.041798293590545654,
            0.028206368908286095,
            -0.009397255256772041,
            0.0015766499564051628,
            0.02715359441936016,
            0.012387742288410664,
            -0.01726243644952774,
            0.007375415880233049,
            -0.02236173301935196,
            0.022367367520928383,
            -0.00846853107213974,
            -0.028396837413311005,
            0.047058869153261185,
            0.00852207001298666,
            -0.00447275722399354,
            0.00040822906885296106,
            0.015202820301055908,
            0.023195594549179077,
            0.003847843734547496,
            0.01722390204668045,
            0.0046547455713152885,
            -0.013439245522022247,
            0.03619919344782829,
            -0.02065717801451683,
            0.03248419612646103,
            -0.013278254307806492,
            -0.019308898597955704,
            0.017127616330981255,
            0.058362338691949844,
            -0.03042300045490265,
            0.0366322360932827,
            0.04203750193119049,
            -0.01699402742087841,
            0.007942999713122845,
            0.015187909826636314,
            0.008634358644485474,
            -0.0041189356707036495,
            0.003242022590711713,
            0.018366359174251556,
            0.008916661143302917,
            -0.0316956602036953,
            -0.015817351639270782,
            0.033943261951208115,
            -0.024965161457657814,
            -0.004055010620504618,
            0.012846107594668865,
            0.02295606955885887,
            -0.033222269266843796,
            -0.0005891984328627586,
            -0.006394119933247566,
            0.01567898690700531,
            -0.02088831551373005,
            -0.04871569573879242,
            0.0250001959502697,
            -0.024049188941717148,
            0.030537595972418785,
            0.0005582129815593362,
            -0.005554892588406801,
            0.014233601279556751,
            0.0026559983380138874,
            0.030993271619081497,
            -0.003470607800409198,
            0.007877602241933346,
            0.021090228110551834,
            -0.020275400951504707,
            -0.03016001544892788,
            -0.01272430457174778,
            0.005880408454686403,
            -0.052244290709495544,
            0.030397403985261917,
            -0.004330703988671303,
            0.01872945949435234,
            0.008540328592061996,
            0.053749874234199524,
            -0.07060655951499939,
            -0.007385702338069677,
            -0.015496408566832542,
            -0.016920389607548714,
            0.008307256735861301,
            0.031095536425709724,
            -0.03738681599497795,
            -0.0008924891008064151,
            -0.00590355833992362,
            -0.03105900250375271,
            0.038007430732250214,
            0.031428441405296326,
            -0.0062330192886292934,
            0.03763389587402344,
            0.013107054866850376,
            -0.0010015394072979689,
            -0.00929462630301714,
            -0.024240855127573013,
            -0.0013764705508947372,
            -0.022276073694229126,
            -0.03649476543068886,
            -0.010785055346786976,
            -0.02752736769616604,
            -0.010293848812580109,
            -0.01756148412823677,
            0.035162050276994705,
            -0.017295818775892258,
            -0.0247785747051239,
            -0.006275855004787445,
            0.009709559381008148,
            -0.006892543751746416,
            -0.008038396947085857,
            -0.03823838010430336,
            -0.027042444795370102,
            -0.00521613797172904,
            0.05265679210424423,
            0.0006662594969384372,
            -0.008346976712346077,
            -0.011128300800919533,
            0.01130798552185297,
            0.03357355669140816,
            -0.0348040834069252,
            -0.039702512323856354,
            0.028511401265859604,
            0.0008516241214238107,
            0.033102113753557205,
            -0.048564743250608444,
            0.0007998482324182987,
            -0.004875026643276215,
            -0.011428282596170902,
            -0.028770718723535538,
            -0.008951328694820404,
            -0.0015053530223667622,
            0.03053489327430725,
            0.008115083910524845,
            0.0017429220024496317,
            0.010092182084918022,
            0.009285173378884792,
            -0.05579045042395592,
            -0.0468745157122612,
            -0.009075099602341652,
            0.03568943589925766,
            0.009025183506309986,
            -0.028106985613703728,
            -0.006512251682579517,
            0.016728872433304787,
            -0.027562875300645828,
            -0.011380207724869251,
            0.02895432896912098,
            0.007183609530329704,
            -0.013949921354651451,
            -0.029264511540532112,
            0.011050407774746418,
            0.044172488152980804,
            -0.026497269049286842,
            -0.022358348593115807,
            -0.027320226654410362,
            0.03685814142227173,
            0.019723687320947647,
            0.02488827332854271,
            -0.028031764551997185,
            -0.016926974058151245,
            0.017579682171344757,
            0.004059776663780212,
            -0.03777771443128586,
            -0.04240937530994415,
            0.03205711767077446,
            0.005311342887580395,
            -0.0015355375362560153,
            0.06051906570792198,
            -0.002103228820487857,
            -0.006712818052619696,
            -0.010793641209602356,
            0.028836827725172043,
            0.0005358884809538722,
            -0.02554759755730629,
            0.005675443448126316,
            0.04753512144088745,
            0.05985682085156441,
            -0.008605560287833214,
            0.00900997780263424,
            -0.017270665615797043,
            -0.00019372280803509057,
            0.02654704824090004,
            -0.016144467517733574,
            0.01594945602118969,
            -0.011954857967793941,
            -0.04363679140806198,
            0.00141469098161906,
            0.029439205303788185,
            -0.0069214822724461555,
            0.021016746759414673,
            0.05313068628311157,
            -0.01844209060072899,
            0.0420001782476902,
            0.019938211888074875,
            -0.00712385680526495,
            0.03402520343661308,
            0.05903688818216324,
            -0.01199023611843586,
            0.0026628198102116585,
            -0.003929350525140762,
            0.023977674543857574,
            0.026476647704839706,
            -0.018909156322479248,
            -0.027489611878991127,
            -0.02539977990090847,
            -0.009639343246817589,
            -0.008375365287065506,
            -0.02993449755012989,
            -0.026308992877602577,
            0.012186352163553238,
            -0.005880921147763729,
            0.022727787494659424,
            0.027953045442700386,
            0.004259469918906689,
            0.06075907126069069,
            -0.015297873876988888,
            0.03957735747098923,
            -0.025802507996559143,
            -0.033139582723379135,
            0.0297698974609375,
            -0.002823288319632411,
            0.02688378468155861,
            -0.01140731479972601,
            -0.022899534553289413,
            0.015529453754425049,
            -0.029165880754590034,
            0.030314568430185318,
            0.01587391458451748,
            0.010824795812368393,
            0.01821257174015045,
            0.04434772953391075,
            0.01666755974292755,
            0.01773480325937271,
            0.0027012175414711237,
            0.020015869289636612,
            0.002149176085367799,
            0.0009196006576530635,
            -0.007293188013136387,
            -0.012067929841578007,
            0.04277464374899864,
            0.03132299333810806,
            0.02910899557173252,
            0.017818018794059753,
            -0.012031670659780502,
            0.016616398468613625,
            0.00022679846733808517,
            -0.013233326375484467,
            -0.031709522008895874,
            0.021800994873046875,
            -0.007569991052150726,
            -0.04348674416542053,
            -0.019356265664100647,
            0.008081863634288311,
            -0.03077491745352745,
            0.01242045871913433,
            -0.020811645314097404,
            0.013910298235714436,
            0.0028184119146317244,
            -0.0023318673484027386,
            0.03878258913755417,
            0.015384359285235405,
            0.010965652763843536,
            0.04213527590036392,
            -0.021854590624570847,
            0.004016690421849489,
            0.0039411368779838085,
            -0.00314547517336905,
            0.08169447630643845,
            -0.006098270416259766,
            -0.003723993431776762,
            0.019178012385964394,
            -0.01873396709561348,
            0.031344782561063766,
            0.017547447234392166,
            -0.039067115634679794,
            0.004384486470371485,
            -0.0006143407081253827,
            0.032860398292541504,
            -0.007150340359658003,
            0.008009548299014568,
            -0.008616980165243149,
            -0.022178735584020615,
            0.00849203672260046,
            0.04164084792137146,
            -0.01600157469511032,
            0.000939254998229444,
            0.02083638682961464,
            0.009880795143544674,
            -0.04268581047654152,
            0.0018476044060662389,
            0.008590273559093475,
            -0.01999693363904953,
            -0.021368948742747307,
            0.006644272245466709,
            0.044015396386384964,
            0.01963290572166443,
            -0.005286713596433401,
            0.00016459639300592244,
            0.01258345041424036,
            0.026266828179359436,
            -0.019384976476430893,
            -0.017721913754940033,
            -0.07944156229496002,
            0.012085079215466976,
            0.037498194724321365,
            -0.005361693445593119,
            0.02196800522506237,
            -0.01705779880285263,
            -0.004818437155336142,
            -0.0015213219448924065,
            0.00590355833992362,
            0.02197429910302162,
            -0.028036871924996376,
            0.043004464358091354,
            0.0263843834400177,
            0.0247667133808136,
            0.00853576697409153,
            0.004745238460600376,
            -0.009696781635284424,
            -0.0012268386781215668,
            -0.010477539151906967,
            0.02315707504749298,
            0.009440305642783642,
            0.005133623722940683,
            0.020906031131744385,
            0.003977504558861256,
            -0.011045129038393497,
            0.003602469339966774,
            -0.02947969175875187,
            0.0031202761456370354,
            0.008747909218072891,
            -0.018123062327504158,
            -0.009231680072844028,
            -0.003645100397989154,
            -0.004245814401656389,
            0.027657538652420044,
            -0.015217616222798824,
            -0.022288242354989052,
            -0.020624645054340363,
            0.026732251048088074,
            0.012395588681101799,
            0.0015370437176898122,
            0.04086989164352417,
            -0.006062723230570555,
            0.03916151449084282,
            0.0008175225229933858,
            0.00591635936871171,
            -0.013490278273820877,
            0.040146972984075546,
            0.01712752878665924,
            0.003080424852669239,
            -0.014406966976821423,
            0.03624213859438896,
            0.007405220530927181,
            0.01683180406689644,
            -0.03033127821981907,
            0.008402985520660877,
            -0.01930932141840458,
            -0.028893930837512016,
            0.03482722118496895,
            0.003250555833801627,
            -0.0021402069833129644,
            0.0011310408590361476,
            0.010417976416647434,
            -0.045304492115974426,
            -0.0156815554946661,
            0.024771064519882202,
            -0.03266829997301102,
            -0.0037573250010609627,
            -0.00801023654639721,
            -0.012663423083722591,
            -0.016299111768603325,
            -0.09145299345254898,
            -0.03111073561012745,
            -0.008305726572871208,
            0.028753362596035004,
            -0.02836422435939312,
            0.02905917726457119,
            0.015462453477084637,
            -0.01941569894552231,
            0.032494109123945236,
            0.03166186809539795,
            0.015385162085294724,
            0.0197133831679821,
            0.010228232480585575,
            0.01548523548990488,
            0.040897633880376816,
            -0.021391766145825386,
            0.011522527784109116,
            -0.016709839925169945,
            -0.03979920968413353,
            0.006373462732881308,
            0.016423478722572327,
            0.00776298763230443,
            -0.02325623109936714,
            0.022867675870656967,
            -0.004993286915123463,
            -0.01610410585999489,
            -0.03174879029393196,
            0.04112398996949196,
            -0.011053264141082764,
            0.008270292542874813,
            -0.045293089002370834,
            0.0017447269055992365,
            0.018061524257063866,
            0.024139152839779854,
            -0.012278836220502853,
            0.02277442067861557,
            -0.03166777640581131,
            0.00811127107590437,
            0.016323838382959366,
            0.015944886952638626,
            0.012925943359732628,
            0.018315505236387253
        ],
        [
            -0.015144459903240204,
            -0.0007695167441852391,
            -0.013563670217990875,
            -0.016728688031435013,
            0.013743753544986248,
            -0.02063000202178955,
            0.015968846157193184,
            0.04238218069076538,
            -0.010699518024921417,
            0.0086407121270895,
            0.016996916383504868,
            -0.011566766537725925,
            0.009124505333602428,
            -0.0005393401370383799,
            -0.011182942427694798,
            0.029462844133377075,
            -0.0044469451531767845,
            0.036186859011650085,
            0.017440909519791603,
            -0.009231945499777794,
            -0.003873733803629875,
            -0.019795959815382957,
            0.0036980120930820704,
            0.022977035492658615,
            0.03671129792928696,
            -0.009553657844662666,
            0.015554393641650677,
            0.015879159793257713,
            0.026557207107543945,
            0.018215307965874672,
            0.04053761065006256,
            -0.0018452571239322424,
            0.022221015766263008,
            0.011399319395422935,
            0.02840816043317318,
            0.018072666600346565,
            -0.029330890625715256,
            0.015176443383097649,
            -0.025072377175092697,
            0.010880154557526112,
            0.02906273677945137,
            0.03279489278793335,
            -0.014514431357383728,
            -0.007697927299886942,
            9.42357728490606e-05,
            0.019802669063210487,
            -0.014653824269771576,
            0.02798953652381897,
            0.009625579230487347,
            -0.016815057024359703,
            0.04471760615706444,
            0.02434668503701687,
            0.01883906126022339,
            -0.020333528518676758,
            -0.025329895317554474,
            0.016898423433303833,
            -0.017128832638263702,
            0.03472358360886574,
            -0.0017560776323080063,
            0.024123918265104294,
            -0.0125258918851614,
            0.013741395436227322,
            0.0069068074226379395,
            0.026371080428361893,
            8.881848043529317e-05,
            0.02745264396071434,
            -0.027607783675193787,
            -0.011818569153547287,
            -0.02418600209057331,
            -0.01672753319144249,
            0.016088014468550682,
            -0.018952108919620514,
            0.003135399427264929,
            -0.017319506034255028,
            0.011872590519487858,
            0.015121807344257832,
            -0.009783165529370308,
            -0.002045260276645422,
            -0.016843562945723534,
            0.008101196959614754,
            0.007797913160175085,
            0.01242916937917471,
            0.009131157770752907,
            0.017108285799622536,
            0.01780453324317932,
            -0.00017702327750157565,
            -0.007615640759468079,
            0.025856874883174896,
            -0.012276296503841877,
            -0.05411793664097786,
            -0.011280924081802368,
            -0.004441272467374802,
            -0.028741953894495964,
            0.026850581169128418,
            0.01133893895894289,
            0.005327560007572174,
            0.014013788662850857,
            0.003569245571270585,
            0.007012934889644384,
            0.02500651217997074,
            -0.0003433864039834589,
            -0.011717304587364197,
            0.001962557202205062,
            0.011996133252978325,
            0.009003235958516598,
            -0.006619063206017017,
            0.004398548975586891,
            -0.008493668399751186,
            -0.0038581080734729767,
            0.013250823132693768,
            -0.00044709292706102133,
            0.0029616630636155605,
            -0.0005672724801115692,
            -0.019056005403399467,
            -0.0027216437738388777,
            -0.012775507755577564,
            0.006847237702459097,
            -0.04388342797756195,
            -0.015554478392004967,
            -0.0052217403426766396,
            0.007161120418459177,
            -0.011610902845859528,
            0.0618731789290905,
            0.018449651077389717,
            -0.010044156573712826,
            -0.026472344994544983,
            0.0249918382614851,
            0.007848355919122696,
            0.019278351217508316,
            -0.02026309072971344,
            0.006087126675993204,
            0.039214957505464554,
            0.02133883535861969,
            0.014606978744268417,
            -0.014234470203518867,
            -0.0033336817286908627,
            0.014994731172919273,
            0.02057013101875782,
            0.019872291013598442,
            0.029690440744161606,
            -0.011373921297490597,
            -0.0060515329241752625,
            0.015917524695396423,
            -0.017462274059653282,
            -0.02089325524866581,
            -0.0036192755214869976,
            0.012286941520869732,
            0.007497582118958235,
            0.027516521513462067,
            -0.02689407393336296,
            -0.0024780170060694218,
            0.022707803174853325,
            0.026444507762789726,
            -0.010457167401909828,
            -0.002483972115442157,
            -0.033123623579740524,
            0.0037688652519136667,
            -0.010002127848565578,
            -0.010044575668871403,
            0.020367011427879333,
            0.029942048713564873,
            -0.008247239515185356,
            0.023670753464102745,
            -0.01141282171010971,
            0.04000309854745865,
            0.006366202607750893,
            -0.0014871450839564204,
            0.02273288555443287,
            -0.04414511099457741,
            0.011312420479953289,
            -0.009871010668575764,
            -0.00423403037711978,
            0.02357124723494053,
            -0.008371810428798199,
            0.03289913386106491,
            0.03701723366975784,
            -0.018994610756635666,
            0.033936165273189545,
            0.04094658046960831,
            -0.02998349815607071,
            0.009399492293596268,
            0.005237532779574394,
            0.015658248215913773,
            0.025171343237161636,
            0.03343171253800392,
            -0.02845696173608303,
            0.022702408954501152,
            0.026821011677384377,
            0.0033656777814030647,
            0.012706130743026733,
            0.02064618282020092,
            0.011069453321397305,
            -0.03990858420729637,
            -0.020652124658226967,
            0.014411576092243195,
            0.017430979758501053,
            0.02173471264541149,
            0.02777525782585144,
            0.021781964227557182,
            0.012192638590931892,
            0.016753604635596275,
            0.017043517902493477,
            0.0025165576953440905,
            0.0274373609572649,
            0.005197031889110804,
            0.002476614201441407,
            -0.004652559757232666,
            0.025172127410769463,
            0.03382712975144386,
            0.025104843080043793,
            0.018075784668326378,
            0.042205166071653366,
            0.028477385640144348,
            0.019297972321510315,
            -0.012928265146911144,
            -0.007515766657888889,
            -0.02810312621295452,
            -0.02783435396850109,
            -0.012356486171483994,
            -0.01825454831123352,
            0.016954462975263596,
            -0.022992003709077835,
            0.0027847979217767715,
            0.021819211542606354,
            0.000698683550581336,
            -0.04893616959452629,
            -0.04000874236226082,
            -0.026117118075489998,
            0.03530597314238548,
            -0.01000899076461792,
            -0.008828992024064064,
            -0.037758976221084595,
            0.0213862843811512,
            -0.02698458358645439,
            -0.02552938461303711,
            -0.0015137027949094772,
            0.015403440222144127,
            -0.02400173246860504,
            0.01193617656826973,
            0.026339523494243622,
            0.008612758480012417,
            -0.023983672261238098,
            0.010849380865693092,
            0.032044172286987305,
            -0.022706681862473488,
            0.003715150523930788,
            -0.01621248759329319,
            -0.02831498719751835,
            -0.010294524021446705,
            0.02391900308430195,
            0.01989779993891716,
            0.012306620366871357,
            0.004945386201143265,
            0.004350174218416214,
            -0.0183120034635067,
            0.013478483073413372,
            0.026308760046958923,
            0.02505931258201599,
            0.0020559539552778006,
            0.02179444395005703,
            -0.009870965965092182,
            0.0037578472401946783,
            -0.03712959215044975,
            -0.015921594575047493,
            -0.02887997217476368,
            0.021635720506310463,
            -0.02514318935573101,
            0.01837875507771969,
            -0.0025889866519719362,
            -0.03616369143128395,
            0.021311884745955467,
            0.02163568325340748,
            0.010814476758241653,
            -0.0059701488353312016,
            0.011602038517594337,
            0.025045618414878845,
            -0.008369742892682552,
            0.007480315398424864,
            0.02579374983906746,
            -0.022279292345046997,
            -0.024973884224891663,
            -0.016856679692864418,
            -0.013448483310639858,
            -0.00988847017288208,
            -0.02439749427139759,
            -0.001411016215570271,
            -0.03574715182185173,
            -0.01853802800178528,
            0.00556801725178957,
            0.004067212343215942,
            0.005238942801952362,
            0.008036665618419647,
            0.00522119365632534,
            0.007883287034928799,
            -0.006366285029798746,
            -0.016149217262864113,
            -0.007194207515567541,
            0.030996115878224373,
            -0.05012897774577141,
            0.012017807923257351,
            0.002249972429126501,
            0.00882354099303484,
            0.0275067538022995,
            -0.004524817690253258,
            -0.002072627656161785,
            0.014344724826514721,
            -0.01501447707414627,
            -0.011907174251973629,
            0.03949946537613869,
            -0.018937503919005394,
            0.006243059411644936,
            0.023004654794931412,
            -0.023871997371315956,
            0.02248346619307995,
            -0.019542453810572624,
            0.02047138474881649,
            0.013296714052557945,
            -0.02057630568742752,
            -0.026233425363898277,
            0.023888688534498215,
            0.004460450261831284,
            -0.03827052190899849,
            -0.027796905487775803,
            0.034642938524484634,
            0.018427863717079163,
            -0.00932269636541605,
            -0.019664447754621506,
            0.007582047022879124,
            0.01188188698142767,
            0.007603602018207312,
            0.028164632618427277,
            0.027971073985099792,
            0.0019117282936349511,
            -0.024759650230407715,
            -0.03383101150393486,
            -0.0017712797271087766,
            0.03467966616153717,
            0.014928949996829033,
            -0.015084569342434406,
            0.004583409987390041,
            0.023631369695067406,
            -0.005956414621323347,
            -0.01766337640583515,
            -0.02354426123201847,
            -0.01772163435816765,
            -0.0041120583191514015,
            -0.030043428763747215,
            0.03511345759034157,
            0.025511987507343292,
            0.013219920918345451,
            0.004005939234048128,
            0.004415883217006922,
            0.003777729347348213,
            0.0062951804138720036,
            -0.006447528023272753,
            0.013837527483701706,
            0.02523479424417019,
            0.011102719232439995,
            0.0005343318334780633,
            0.01850837469100952,
            -0.025102639570832253,
            0.0043343850411474705,
            -0.0012676919577643275,
            0.036683715879917145,
            0.03532268479466438,
            -0.016467614099383354,
            0.016382450237870216,
            0.016181789338588715,
            -0.00021402504353318363,
            0.0240671057254076,
            -0.008878642693161964,
            -0.007567404769361019,
            -0.006336982827633619,
            -0.013790803961455822,
            0.013163259252905846,
            0.01889091543853283,
            0.01060844212770462,
            -0.011237815022468567,
            -0.006853788625448942,
            -0.009280911646783352,
            0.000354290270479396,
            0.021460728719830513,
            0.024828126654028893,
            -0.0077053336426615715,
            0.005276241805404425,
            0.031525105237960815,
            0.03462836146354675,
            -0.007334794383496046,
            0.012310026213526726,
            -0.003122261492535472,
            -0.016013486310839653,
            -0.01939668320119381,
            -0.01427880022674799,
            0.03373716026544571,
            -0.030151350423693657,
            0.01297053974121809,
            0.011743198148906231,
            -0.02693217247724533,
            0.026312218979001045,
            -0.005962782073765993,
            0.008309315890073776,
            0.008074038662016392,
            -0.010967097245156765,
            0.0018956891726702452,
            -0.003942145965993404,
            -0.005484935827553272,
            -0.0089200958609581,
            -0.004735770169645548,
            -0.006938503589481115,
            0.004441335331648588,
            -0.017776913940906525,
            0.028824135661125183,
            0.012113482691347599,
            -0.04515143856406212,
            0.015262140892446041,
            0.011691619642078876,
            -0.030070211738348007,
            0.023647308349609375,
            -0.010079304687678814,
            0.01246207021176815,
            0.02546607330441475,
            -0.01558096893131733,
            0.04252193868160248,
            -0.022483570501208305,
            0.01867128163576126,
            -0.004112154711037874,
            -0.012394639663398266,
            -0.029434265568852425,
            0.030394941568374634,
            -0.019358599558472633,
            -0.022841576486825943,
            0.03391389176249504,
            -0.01847291924059391,
            -0.026812605559825897,
            0.007349795661866665,
            0.002147671999409795,
            -0.009231491014361382,
            -0.017625106498599052,
            0.02144414186477661,
            0.0014219465665519238,
            -0.00834902934730053,
            0.008009335957467556,
            -0.002323123160749674,
            -0.015850434079766273,
            -0.0013216870138421655,
            -0.028283659368753433,
            -0.012687187641859055,
            -0.022904392331838608,
            -0.020939890295267105,
            0.00931974034756422,
            0.012188371270895004,
            -0.002094692550599575,
            -0.0009665426914580166,
            0.026814712211489677,
            0.016298523172736168,
            0.027518557384610176,
            0.008081573061645031,
            -0.03242834657430649,
            0.030657127499580383,
            0.026434428989887238,
            -0.04526130110025406,
            0.029996808618307114,
            0.00638600206002593,
            0.0037572765722870827,
            -0.0010635574581101537,
            -0.029284821823239326,
            -0.03002334013581276,
            -0.0074538723565638065,
            0.026275042444467545,
            -0.0308699831366539,
            0.01707776077091694,
            0.01428412925451994,
            0.012088920921087265,
            0.0008978505502454937,
            0.02606400102376938,
            0.004192737862467766,
            0.018094085156917572,
            -0.016167376190423965,
            -0.00231308420188725,
            0.03368712589144707,
            0.016092829406261444,
            0.03693889454007149,
            0.0111821498721838,
            -0.02231932431459427,
            0.02371826581656933,
            0.01965707167983055,
            0.030803894624114037,
            0.012292422354221344,
            -0.048232294619083405,
            0.02625846490263939,
            0.007740733213722706,
            0.02033739537000656,
            0.010386191308498383,
            -0.0221293605864048,
            -0.008631883189082146,
            0.01663290709257126,
            -0.021654963493347168,
            -0.007071681320667267,
            -0.02135332301259041,
            0.019051464274525642,
            0.012165565975010395,
            0.028532687574625015,
            0.011817138642072678,
            -0.0012442378792911768,
            0.0008079870021902025,
            0.004624398425221443,
            -0.0043884883634746075,
            -0.014200312085449696,
            0.005594411864876747,
            -0.020298732444643974,
            -0.014483378268778324,
            -0.003727216739207506,
            -0.013222775422036648,
            -0.032357435673475266,
            -0.004987647291272879,
            0.0349469929933548,
            -0.01733543910086155,
            0.0032597684767097235,
            0.0269662756472826,
            -0.02644367329776287,
            -0.029314382001757622,
            0.0018325132550671697,
            -0.005775845143944025,
            -0.02930031716823578,
            -0.03165925294160843,
            -0.040565550327301025,
            0.008194277063012123,
            0.018365494906902313,
            -0.013882536441087723,
            0.017176553606987,
            0.021511567756533623,
            -0.0034186718985438347,
            -0.009822261519730091,
            -0.017425933852791786,
            0.001953737111762166,
            -0.02103429101407528,
            -0.014353825710713863,
            -0.03146744519472122,
            -0.009349738247692585,
            0.0375065840780735,
            -0.0005590272485278547,
            0.007650098763406277,
            0.035899415612220764,
            0.0028555579483509064,
            0.018365325406193733,
            -0.020525963976979256,
            0.004107076209038496,
            0.011945072561502457,
            0.011994385160505772,
            0.025729181244969368,
            -0.007268864195793867,
            0.007409466430544853,
            -0.03349731117486954,
            0.03138871490955353,
            0.0183856301009655,
            -0.026555867865681648,
            0.0072455317713320255,
            0.018860258162021637,
            0.0030710294377058744,
            0.0038195746019482613,
            0.024271802976727486,
            -0.02825446054339409,
            0.01207079365849495,
            0.04431631416082382,
            -0.005952404346317053,
            0.022748583927750587,
            -0.031094130128622055,
            0.02178581990301609,
            -0.0013993163593113422,
            0.045065321028232574,
            0.011204048991203308,
            -0.0038689293432980776,
            -0.022818291559815407,
            -0.009955122135579586,
            -0.022984610870480537,
            -0.014777075499296188,
            -0.02714889869093895,
            -0.004973588977009058,
            0.00569918891415,
            0.0008041883702389896,
            -0.018754377961158752,
            0.023429932072758675,
            -0.015076578594744205,
            0.005281494930386543,
            0.00864126905798912,
            -0.0017031552270054817,
            -0.022510813549160957,
            -0.005994031205773354,
            -0.005702833645045757,
            0.03516783192753792,
            -0.02318587340414524,
            0.021806713193655014,
            0.016209788620471954,
            -0.0172125231474638,
            0.009552364237606525,
            0.021653693169355392,
            0.010938351042568684,
            0.03626517578959465,
            0.02866223268210888,
            0.03173183277249336,
            -0.04216907545924187,
            0.01850935071706772,
            0.008472001180052757,
            0.011942313984036446,
            0.02189667895436287,
            0.00997553113847971,
            0.01664782129228115,
            -0.0016442871419712901,
            0.02372450940310955,
            0.012361881323158741,
            0.012094198726117611,
            0.029608849436044693,
            -0.0007606712752021849,
            0.004228685051202774,
            0.03599841892719269,
            -0.0020208354108035564,
            0.007551352959126234,
            -0.0010744434548541903,
            -0.021590815857052803,
            -0.02356581948697567,
            -0.029608450829982758,
            -0.015779461711645126,
            0.003479067236185074,
            0.022848308086395264,
            -0.0012446428881958127,
            -0.010357456281781197,
            0.025708090513944626,
            -0.027163632214069366,
            -0.012806259095668793,
            -0.016052864491939545,
            -0.004415038973093033,
            0.029671182855963707,
            0.008004948496818542,
            -0.018793076276779175,
            -0.004011334385722876,
            -0.014078446663916111,
            -0.03249753639101982,
            0.0158449187874794,
            0.010804212652146816,
            0.016391245648264885,
            0.019946841523051262,
            0.05693201348185539,
            0.05568789690732956,
            -0.010201375931501389,
            0.02260080724954605,
            -0.01612047664821148,
            -0.011506862007081509,
            -0.016910620033740997,
            -0.0003045453631784767,
            0.007427649572491646,
            -0.01966867968440056,
            0.02757888101041317,
            0.0015811503399163485,
            -0.021922187879681587,
            -0.015459513291716576,
            0.032027892768383026,
            -0.028003379702568054,
            0.027541527524590492,
            0.015076614916324615,
            -0.015092993155121803,
            0.018922928720712662,
            -0.027582582086324692,
            0.029787084087729454,
            -0.01644693687558174,
            0.02019496262073517,
            -0.002981926081702113,
            0.020941875874996185,
            -0.007625814992934465,
            0.015759222209453583,
            -0.02733895368874073,
            0.011122018098831177,
            -0.007692383136600256,
            0.026286404579877853,
            -0.012771607376635075,
            0.0037305678706616163,
            0.03111875057220459,
            -0.0028099634218961,
            0.003787944559007883,
            -0.020175786688923836,
            -0.025981703773140907,
            0.0077014099806547165,
            0.009644711390137672,
            0.006564693991094828,
            -0.020816339179873466,
            0.00782164465636015,
            0.015549825504422188,
            0.012323103845119476,
            0.031287696212530136,
            0.0069225323386490345,
            -0.023289527744054794,
            -0.010382061824202538,
            0.024054858833551407,
            0.019195348024368286,
            0.005319495219737291,
            -0.013265062123537064,
            0.018077578395605087,
            -0.003537846030667424,
            -0.0012267413549125195,
            -0.009798480197787285,
            -0.024796264246106148,
            -0.018649548292160034,
            -0.00594773655757308,
            -0.025912148877978325,
            -0.027439314872026443,
            0.02250874787569046,
            0.019356051459908485,
            0.03022928163409233,
            -0.01696326769888401,
            0.012230838648974895,
            -0.0229765847325325,
            0.029780562967061996,
            0.016009368002414703,
            0.034309420734643936,
            -0.001980876550078392,
            0.007863432168960571,
            -0.025411734357476234,
            0.012005307711660862,
            0.008927456103265285,
            -0.01437445916235447,
            -0.01609041355550289,
            -0.03928893059492111,
            0.030607502907514572,
            0.006956376601010561,
            -0.014244765974581242,
            -0.01450854167342186,
            0.0001708965573925525,
            0.03159061446785927,
            0.027738677337765694,
            -0.00469734612852335,
            0.026066120713949203,
            -0.03924734145402908,
            -0.0056928168050944805,
            -0.0034173785243183374,
            0.0015392095083370805,
            0.02554963156580925,
            -0.015443659387528896,
            0.0061037796549499035,
            -0.01648217812180519,
            -0.02785222791135311,
            -0.018401574343442917,
            0.0037850970402359962,
            -0.006242355331778526,
            0.012347009964287281,
            -0.027470558881759644,
            -0.02317209541797638,
            -0.0013678250834345818,
            -0.012446817941963673,
            -0.03275467827916145,
            0.009434089064598083,
            0.023141298443078995,
            0.006759961601346731,
            0.01365091372281313,
            -0.02707144245505333,
            0.02526058256626129,
            0.011262103915214539,
            0.01663971319794655,
            -0.017179373651742935,
            0.03434830531477928,
            -0.015231935307383537,
            0.009828021749854088,
            0.013256233185529709,
            -0.023448549211025238,
            0.03097451664507389,
            0.01274611335247755,
            -0.007554410491138697,
            0.010197382420301437,
            -0.0169114638119936,
            -0.002121930243447423,
            -0.004994232673197985,
            0.015090437605977058,
            -0.0024243821389973164,
            0.03211434930562973,
            -0.02608867920935154,
            0.03781367838382721,
            0.027310369536280632,
            -0.009741543792188168,
            -0.0254055205732584,
            0.01630485989153385,
            0.011554104276001453,
            -0.013706822879612446,
            -0.002944536739960313,
            0.0008458099327981472,
            -0.027412867173552513,
            0.021702857688069344,
            -0.026197295635938644,
            -0.0003834922390524298,
            -0.02061465010046959,
            -0.020725267007946968,
            -0.003224835731089115,
            0.006529968231916428,
            0.007192644290626049,
            0.02094954252243042,
            -0.008161281235516071,
            0.00090412562713027,
            -0.013661280274391174,
            0.019664231687784195,
            -0.022585522383451462,
            -0.0292038694024086,
            -0.025133952498435974,
            -0.01299995370209217,
            0.03415408730506897,
            0.03135601431131363,
            0.020511673763394356,
            -0.003651681123301387,
            -0.005747099407017231,
            -0.037652965635061264,
            0.009706804528832436,
            0.01374046877026558,
            0.025389323011040688,
            0.01925978809595108,
            -0.02597995474934578,
            0.00924480240792036,
            -0.015133155509829521,
            0.022905737161636353,
            -0.04177536070346832,
            -0.004127252381294966,
            0.02880074270069599,
            0.019384099170565605,
            0.00911766942590475,
            0.022530732676386833,
            0.004735657479614019,
            -0.0011904110433533788,
            -0.002139318035915494,
            0.02457720786333084,
            -0.016160182654857635,
            -0.018399745225906372,
            0.01621602103114128,
            0.029995007440447807,
            0.014859193004667759,
            0.029514553025364876,
            0.00033230497501790524,
            0.03250263258814812,
            0.0020471718162298203,
            -0.004704637452960014,
            0.046853020787239075,
            -0.005585592705756426,
            -0.013721643015742302,
            -0.023004524409770966,
            -0.017240770161151886,
            0.03014279343187809,
            0.027657147496938705,
            -0.009393061511218548,
            0.0009595919982530177,
            -0.00830772239714861,
            -0.007461976259946823,
            -0.025854894891381264,
            -0.00924450159072876,
            0.007931932806968689,
            -0.003659164998680353,
            0.026355933398008347,
            -0.030031241476535797,
            -0.0231508556753397,
            0.013088849373161793,
            -0.003944543190300465,
            0.022036945447325706,
            -0.011035742238163948,
            -0.005912232678383589,
            0.01776018552482128,
            -0.0024750682059675455,
            0.01123884879052639,
            -0.013201918452978134,
            0.013194170780479908,
            -0.006886722985655069,
            -0.0059300982393324375,
            -0.0011810080613940954,
            -0.02678879164159298,
            -0.01657293736934662,
            0.00541976373642683,
            0.02673746459186077,
            0.017352785915136337,
            -0.029314689338207245,
            -0.002552682999521494,
            -0.0022940782364457846,
            0.028902096673846245,
            0.017514046281576157,
            -0.00499435281381011,
            -0.013805746100842953,
            -0.01542083639651537,
            0.004183271899819374,
            0.023223131895065308,
            -0.005621765274554491,
            -0.018186010420322418,
            -0.008429876528680325,
            0.021797599270939827,
            0.006880870554596186,
            -0.007186457514762878,
            0.005052424967288971,
            -0.0007891443092375994,
            -0.04289766773581505,
            0.017537342384457588,
            0.0336434543132782,
            0.025296209380030632,
            0.020111162215471268,
            -0.00508324708789587,
            0.004636939615011215,
            -0.015590093098580837,
            0.008678155019879341,
            0.01025450974702835,
            -0.01970461755990982,
            -0.013769316487014294,
            -0.026148207485675812,
            -0.0216746237128973,
            -0.007465273141860962,
            -0.02664126642048359,
            -0.013327894732356071,
            0.03325343504548073,
            -0.012013344094157219,
            -0.0019561797380447388,
            0.029912395402789116,
            -0.024178534746170044,
            0.040372688323259354,
            -0.011785718612372875,
            -0.029460594058036804,
            0.045525260269641876,
            0.03609609603881836,
            0.02379733882844448,
            0.013954026624560356,
            0.004962180275470018,
            0.005074704997241497,
            0.0003885352343786508,
            0.009082003496587276,
            -0.008681412786245346,
            -0.01653737761080265,
            0.006995936390012503,
            0.030826209113001823,
            -0.01997641660273075,
            0.004216762725263834,
            0.010898828506469727,
            -0.01398937776684761,
            -0.0296828243881464,
            0.00196928926743567,
            -0.024003423750400543,
            0.00393206812441349,
            -0.019134623929858208,
            -0.005074320361018181,
            -0.010725660249590874,
            0.015871236100792885,
            -0.005637228488922119,
            0.024266237393021584,
            -0.0039676339365541935,
            0.006125928368419409,
            -0.012137206271290779,
            0.016578396782279015,
            -0.00965125486254692,
            -0.00616339361295104,
            0.015886837616562843,
            0.019206436350941658,
            0.03465437516570091,
            0.031150560826063156,
            0.027810821309685707,
            -0.013264753855764866,
            0.005183735862374306,
            0.027209462597966194,
            -0.0003624955425038934,
            0.03501256927847862,
            -0.01714589074254036,
            0.0012384485453367233,
            0.029019532725214958,
            0.027735885232686996,
            -0.005227908957749605,
            0.01793837733566761,
            -0.018025072291493416,
            0.007157923653721809,
            -0.02122299186885357,
            -0.035938091576099396,
            0.014356369152665138,
            -0.039236441254615784,
            0.03469794988632202,
            0.023785792291164398,
            -0.01632801815867424,
            0.026305530220270157,
            0.0004843921633437276,
            -0.006786542013287544,
            -0.009028015658259392,
            0.02646414190530777,
            -0.0025092754513025284,
            0.02722361497581005,
            -0.004049487877637148,
            0.021137062460184097,
            -0.0254030954092741,
            -0.000591799383983016,
            0.01617507077753544,
            -0.04042356461286545,
            -0.01052796095609665,
            0.034867286682128906,
            -0.019753331318497658,
            -0.00023849155695643276,
            -0.006732250098139048,
            0.03064432553946972,
            0.003744934918358922,
            -0.008831857703626156,
            -0.029229583218693733,
            0.0027387242298573256,
            -0.0157264806330204,
            -0.030169028788805008,
            -0.018999068066477776,
            -0.011664913035929203,
            0.01659274846315384,
            -0.02148689329624176,
            -0.028714869171380997,
            0.015954287722706795,
            -0.030257076025009155,
            -0.025808017700910568,
            0.02085525542497635,
            0.014643157832324505,
            0.03359521925449371,
            -0.02155962958931923,
            0.027747157961130142,
            0.03316484019160271,
            -0.005398947279900312,
            -0.035135556012392044,
            0.018742138519883156,
            0.03771456703543663,
            -0.009891290217638016,
            -0.012127344496548176,
            -0.005250208079814911,
            -0.024969790130853653,
            0.03677685931324959,
            0.022668106481432915,
            0.022351857274770737,
            -0.02216082625091076,
            0.02817186526954174,
            0.006776589434593916,
            -0.020271549001336098
        ],
        [
            0.021134762093424797,
            -0.006793404463678598,
            -0.029241295531392097,
            0.007945513352751732,
            0.011323447339236736,
            -0.030129941180348396,
            -0.02738671377301216,
            0.007116067688912153,
            0.025008536875247955,
            -0.008771143853664398,
            -0.006794283632189035,
            -0.018257904797792435,
            -0.004372318275272846,
            -0.01930852420628071,
            -0.00329327373765409,
            -0.0011856632772833109,
            0.005034337285906076,
            -0.02129683457314968,
            0.013272966258227825,
            0.011458632536232471,
            0.007214461453258991,
            0.026222072541713715,
            -0.01712716929614544,
            0.002891948912292719,
            0.025354476645588875,
            0.00965578481554985,
            0.017686521634459496,
            -0.031018288806080818,
            0.029053527861833572,
            -0.01664907857775688,
            0.012035866267979145,
            0.010217919014394283,
            0.024074172601103783,
            0.027497706934809685,
            0.012851662002503872,
            0.0052912915125489235,
            -0.011150103062391281,
            0.022202424705028534,
            0.016423938795924187,
            0.014729633927345276,
            0.02772403322160244,
            -0.018456369638442993,
            0.017056217417120934,
            0.0015056207776069641,
            0.03186333552002907,
            0.003625760320574045,
            -0.018705150112509727,
            -0.009428811259567738,
            -0.023292485624551773,
            0.004135987255722284,
            0.05113264545798302,
            0.03605092689394951,
            0.03184543550014496,
            -0.00017602034495212138,
            0.028025006875395775,
            -0.025265749543905258,
            -0.0054792542941868305,
            0.017197318375110626,
            -0.018411507830023766,
            0.010333262383937836,
            -0.023336926475167274,
            0.018923087045550346,
            -0.00521430280059576,
            0.025819314643740654,
            -0.022053495049476624,
            0.007621543947607279,
            -0.03484436497092247,
            -0.0166020505130291,
            -0.006056229583919048,
            0.016097798943519592,
            0.01954936794936657,
            -0.006981538608670235,
            0.03469141572713852,
            0.0146506791934371,
            -0.006227375473827124,
            0.018806777894496918,
            0.0022865738719701767,
            0.014095907099545002,
            -0.01923455111682415,
            0.028342805802822113,
            0.02556523308157921,
            0.018108738586306572,
            0.031047798693180084,
            0.011904867365956306,
            0.009569215588271618,
            0.0006748410523869097,
            -0.023569380864501,
            0.023473655804991722,
            -0.0275004543364048,
            -0.015365143306553364,
            0.016792889684438705,
            0.028044093400239944,
            0.013036604039371014,
            -0.0035332588013261557,
            -0.00832480750977993,
            0.021847117692232132,
            -0.005069179460406303,
            -0.009593842551112175,
            0.05232137814164162,
            0.0022516727913171053,
            0.012693149037659168,
            0.011588302440941334,
            -0.003389181802049279,
            0.02263055182993412,
            -0.004175254143774509,
            -0.003782762913033366,
            0.006689511239528656,
            0.03385688364505768,
            -0.00035416442551650107,
            0.0064782346598804,
            -0.013674139976501465,
            -0.014130360446870327,
            -0.02763350121676922,
            -0.023556454107165337,
            0.009685561060905457,
            -0.026988312602043152,
            0.03558764234185219,
            -0.04354904592037201,
            0.017543286085128784,
            -0.029720576480031013,
            0.008309883996844292,
            -0.021070491522550583,
            -0.03658159822225571,
            0.021768217906355858,
            -0.0005471918848343194,
            0.015294212847948074,
            0.007482501212507486,
            0.020175140351057053,
            0.01938779279589653,
            0.02005031518638134,
            -0.023343555629253387,
            0.013583988882601261,
            0.009068422950804234,
            -0.028769884258508682,
            -0.015813205391168594,
            0.007047978229820728,
            -0.0006721249083057046,
            0.02436208911240101,
            0.021904492750763893,
            0.02493804134428501,
            0.029108230024576187,
            0.00706489896401763,
            0.03402628004550934,
            -0.017682796344161034,
            0.0031282862182706594,
            -0.011326448060572147,
            0.02003606967628002,
            0.01320114266127348,
            0.012835031375288963,
            -0.0010115592740476131,
            -0.034489840269088745,
            -0.008524465374648571,
            0.014762365259230137,
            0.005106019787490368,
            0.018119407817721367,
            0.03979741781949997,
            -0.03763461485505104,
            0.013497195206582546,
            -0.00038918020436540246,
            -0.02509714476764202,
            0.039191193878650665,
            0.02278844825923443,
            0.017659654840826988,
            0.006768739316612482,
            -0.010984104126691818,
            -0.010266236960887909,
            0.016922634094953537,
            0.0028641391545534134,
            -0.02237510494887829,
            0.013031244277954102,
            -0.009909168817102909,
            -0.005462792236357927,
            0.022859439253807068,
            0.022292960435152054,
            0.005067207384854555,
            -0.027342267334461212,
            -0.019235989078879356,
            0.014396607875823975,
            0.02457568421959877,
            0.0036173786502331495,
            -0.001444719498977065,
            0.02605721727013588,
            0.020916495472192764,
            0.026759015396237373,
            0.012972595170140266,
            -0.0183858685195446,
            -0.007272559218108654,
            -0.00976567529141903,
            -0.01776803284883499,
            -0.027058031409978867,
            0.026321571320295334,
            -0.03055274672806263,
            -0.06288490444421768,
            0.006825306452810764,
            -0.00226794951595366,
            0.03318598493933678,
            -0.01942448690533638,
            -0.01977626606822014,
            -0.0020917989313602448,
            0.004626346752047539,
            0.001892367610707879,
            0.017653191462159157,
            -0.019512619823217392,
            -0.00536965811625123,
            -0.03651357814669609,
            0.01597662828862667,
            -0.01677536405622959,
            -0.011775965802371502,
            -0.02170179784297943,
            0.023635271936655045,
            0.013532303273677826,
            -0.019354376941919327,
            0.00991816446185112,
            -0.017807379364967346,
            -0.025919420644640923,
            -0.0040876553393900394,
            -0.0215276088565588,
            -0.005915870424360037,
            -0.004678939934819937,
            0.022687474265694618,
            0.01402846910059452,
            0.03884073719382286,
            -0.01058157254010439,
            0.011304990388453007,
            0.02335704118013382,
            -0.018592141568660736,
            0.013546288944780827,
            -0.010016938671469688,
            -0.005527841858565807,
            -0.030797872692346573,
            0.014532667584717274,
            -0.009407312609255314,
            0.003895101835951209,
            0.015651023015379906,
            0.0014489201130345464,
            0.01341212261468172,
            -0.010976259596645832,
            -0.005960350390523672,
            -0.021822918206453323,
            0.010568149387836456,
            -0.01638549007475376,
            0.028418738394975662,
            0.028828037902712822,
            0.02984008565545082,
            0.004073981661349535,
            0.020179281011223793,
            0.025726135820150375,
            0.007771921344101429,
            -0.004446573555469513,
            0.0025247212033718824,
            0.01630513370037079,
            0.032609205693006516,
            0.013402107171714306,
            -0.017477339133620262,
            0.0005845198757015169,
            0.002580289263278246,
            -0.014940598979592323,
            0.01951168291270733,
            -0.036772288382053375,
            0.03748719394207001,
            0.017324700951576233,
            0.0010654628276824951,
            0.006607698276638985,
            0.018544524908065796,
            -0.009321171790361404,
            0.006368890404701233,
            -0.008165747858583927,
            0.0025279365945607424,
            0.02509286068379879,
            -0.006123225670307875,
            0.025908151641488075,
            -0.01046952698379755,
            0.011322691105306149,
            0.040744662284851074,
            -0.019926948472857475,
            0.02852442115545273,
            -0.01801559329032898,
            0.01785188913345337,
            0.03002816066145897,
            -0.0026261508464813232,
            0.014036959037184715,
            -0.021114438772201538,
            0.023067058995366096,
            -0.008556000888347626,
            -0.0018675952451303601,
            0.028805112466216087,
            0.03487880900502205,
            0.011880655772984028,
            -0.00321199675090611,
            -0.021138666197657585,
            -0.024705344811081886,
            0.011553891934454441,
            0.006344007793813944,
            0.011054480448365211,
            -0.020483924075961113,
            0.0020493955817073584,
            0.0256972499191761,
            -0.008861626498401165,
            -0.010942148976027966,
            -0.021266277879476547,
            -0.012102371081709862,
            0.03611219674348831,
            0.015600688755512238,
            0.010772017762064934,
            0.007332395762205124,
            0.05915740877389908,
            -0.004905330948531628,
            -0.016110125929117203,
            -0.010903045535087585,
            0.040995191782712936,
            0.03201964497566223,
            0.007862841710448265,
            -0.025616934522986412,
            0.01783258467912674,
            0.031001711264252663,
            0.015071380883455276,
            -0.03723183646798134,
            0.008030114695429802,
            -0.021554382517933846,
            -0.021677007898688316,
            -0.028533194214105606,
            -0.04320766404271126,
            -0.014142907224595547,
            0.03060394711792469,
            0.004753748420625925,
            0.009741188026964664,
            -0.02553340047597885,
            -0.00902722217142582,
            0.02626974508166313,
            -0.027418076992034912,
            0.007412586826831102,
            0.02853981778025627,
            0.03514514118432999,
            -0.023062141612172127,
            0.02266739308834076,
            0.01897231861948967,
            0.031606923788785934,
            0.007224546279758215,
            -0.0016646619187667966,
            -0.020075233653187752,
            -0.0067029837518930435,
            -0.0429186075925827,
            -0.013315933756530285,
            -0.026233121752738953,
            0.024857692420482635,
            -0.02039831131696701,
            -0.0004520589136518538,
            0.018512975424528122,
            -0.03259599208831787,
            -0.0017481917748227715,
            -0.01195813249796629,
            -0.0035424346569925547,
            0.013033928349614143,
            0.007425750605762005,
            -0.0018487325869500637,
            0.023014996200799942,
            -0.0023862551897764206,
            0.0073469337075948715,
            0.02252746932208538,
            0.012119606137275696,
            0.008546924218535423,
            0.02038511261343956,
            -0.0012898966670036316,
            -0.015844441950321198,
            -0.01823597401380539,
            0.027175989001989365,
            -0.002977862488478422,
            -0.02552623674273491,
            -0.035007502883672714,
            0.01910308003425598,
            0.01179606281220913,
            0.010922341607511044,
            -0.013160559348762035,
            -0.030175402760505676,
            -0.021555786952376366,
            -0.02674984000623226,
            -0.008064568974077702,
            0.027941782027482986,
            0.01013266947120428,
            0.03345170617103577,
            -0.017798108980059624,
            0.028500299900770187,
            -0.01958600804209709,
            -0.002986752428114414,
            -0.042126137763261795,
            0.02445833943784237,
            -0.018562519922852516,
            -0.003935238346457481,
            0.038776662200689316,
            0.0172173660248518,
            0.02350686676800251,
            -0.038783960044384,
            0.013618102297186852,
            -0.021689148619771004,
            -0.03129376843571663,
            0.019589433446526527,
            -0.009289771318435669,
            0.003718739841133356,
            0.023079603910446167,
            0.017984336242079735,
            -0.02422371879220009,
            0.03485057130455971,
            -0.014463498257100582,
            0.014027019031345844,
            0.013610607013106346,
            0.001259531476534903,
            -0.005624530836939812,
            0.021594282239675522,
            0.03889554738998413,
            3.253231989219785e-05,
            -0.012230352498590946,
            0.008154171518981457,
            0.00316199311055243,
            0.021232550963759422,
            -0.0038547764997929335,
            0.02012917585670948,
            -0.028769562020897865,
            0.016230730339884758,
            -0.0156503114849329,
            0.006146468687802553,
            0.027458759024739265,
            -0.008616302162408829,
            0.022606363520026207,
            0.004383493214845657,
            -0.02029743231832981,
            -0.012680921703577042,
            -0.02763359434902668,
            -0.006398302968591452,
            0.017911570146679878,
            0.009080633521080017,
            0.02099648118019104,
            -0.0025535065215080976,
            0.022338872775435448,
            -0.01023049559444189,
            -0.017849374562501907,
            0.012727742083370686,
            -0.00023408030392602086,
            0.029201557859778404,
            -0.017194613814353943,
            -0.028431449085474014,
            -0.01625993475317955,
            -0.03357618302106857,
            -0.011847754009068012,
            -0.017812341451644897,
            -0.014930551871657372,
            -0.02535932883620262,
            -0.008422761224210262,
            0.03195910155773163,
            -0.003500881837680936,
            0.014582383446395397,
            0.02315422333776951,
            -0.015129742212593555,
            0.03528936952352524,
            0.024863895028829575,
            -0.019789941608905792,
            -0.01942230947315693,
            0.02020365372300148,
            -0.03667283430695534,
            0.029660603031516075,
            -0.004679062403738499,
            0.016998717561364174,
            -0.020301593467593193,
            0.0108891436830163,
            0.002460938412696123,
            0.02631865069270134,
            -0.0007620890974067152,
            -0.021198228001594543,
            -0.012386721558868885,
            -0.0034366236068308353,
            0.01179235428571701,
            -0.027871569618582726,
            -0.009531574323773384,
            0.03021254763007164,
            -0.010979379527270794,
            0.02744678035378456,
            -0.024932386353611946,
            0.0266644898802042,
            0.0252360962331295,
            0.006474701687693596,
            -0.026419706642627716,
            -0.025155656039714813,
            -0.011919736862182617,
            -0.02122456021606922,
            -0.0071387276984751225,
            0.04680467024445534,
            -0.011033483780920506,
            0.023790767416357994,
            -0.025030897930264473,
            -0.06408210843801498,
            0.010122507810592651,
            -0.03424447029829025,
            0.012129928916692734,
            0.01108426135033369,
            -0.0219301525503397,
            0.0462050661444664,
            0.019720332697033882,
            -0.021952297538518906,
            0.01369572151452303,
            0.0319143682718277,
            -0.007514543365687132,
            0.013506758026778698,
            0.007829518988728523,
            0.02615467831492424,
            0.01474036555737257,
            0.01913176290690899,
            -0.025134973227977753,
            -0.0017935136565938592,
            -0.031175488606095314,
            0.003812565002590418,
            0.02898169867694378,
            -0.0011900825193151832,
            0.003964082337915897,
            0.008602449670433998,
            -0.010788965038955212,
            0.01627969555556774,
            -0.0065092165023088455,
            0.0031354327220469713,
            0.015224330127239227,
            -0.018062414601445198,
            0.03713160380721092,
            0.02237865887582302,
            -0.012370149604976177,
            -0.018588585779070854,
            0.030865095555782318,
            0.027092158794403076,
            -0.001848815125413239,
            -0.006966211833059788,
            0.018403885886073112,
            0.03405533358454704,
            -0.005968024022877216,
            0.029353067278862,
            -0.041021645069122314,
            -0.030034353956580162,
            -0.023230642080307007,
            -0.014684927649796009,
            -0.0048438613303005695,
            0.005163019523024559,
            -0.009109570644795895,
            0.0033114661928266287,
            0.018037104979157448,
            0.01807011477649212,
            -0.0300607867538929,
            -0.0068601034581661224,
            0.0326278917491436,
            0.01537585724145174,
            -0.010848215781152248,
            0.02710936777293682,
            -0.011033056303858757,
            -0.007933956570923328,
            0.031728241592645645,
            -0.014069699682295322,
            0.015476133674383163,
            -0.0046421801671385765,
            -0.027420207858085632,
            0.016276204958558083,
            0.019552351906895638,
            0.007626425940543413,
            -0.0283682681620121,
            0.017284654080867767,
            -0.014046547003090382,
            0.0181142445653677,
            -0.007479994557797909,
            -0.001238389639183879,
            0.027764175087213516,
            0.0016632027691230178,
            0.016160128638148308,
            -0.05187349021434784,
            -0.02162100560963154,
            -0.013916419818997383,
            -0.0027203597128391266,
            -0.0020750672556459904,
            0.031486280262470245,
            0.032965514808893204,
            0.01741509884595871,
            -0.004352136980742216,
            -0.009964478202164173,
            0.015188709832727909,
            -0.01324757095426321,
            -0.009412271901965141,
            0.003008386120200157,
            0.008433318696916103,
            0.02413109317421913,
            0.005030166823416948,
            -0.025888148695230484,
            -0.025276118889451027,
            -0.036791011691093445,
            -0.01608581654727459,
            0.023760732263326645,
            -0.019633682444691658,
            -0.024719739332795143,
            -0.022856784984469414,
            0.015843268483877182,
            0.009049244225025177,
            -0.003510704031214118,
            -0.01510407030582428,
            -0.02091846615076065,
            0.011158610694110394,
            0.011800372041761875,
            0.01561849843710661,
            -0.023436658084392548,
            -0.029468132182955742,
            -0.030634688213467598,
            0.022116046398878098,
            0.007745636627078056,
            0.012490854598581791,
            -0.026464734226465225,
            -0.006676200777292252,
            -0.0027234083972871304,
            -0.010600664652884007,
            0.017776139080524445,
            -0.023687785491347313,
            0.02207971177995205,
            0.019567137584090233,
            -0.02478221245110035,
            -0.008937682025134563,
            0.008516273461282253,
            0.01615222357213497,
            0.010151294991374016,
            0.019861651584506035,
            0.006395706441253424,
            -0.05851302668452263,
            -0.0011822459055110812,
            -0.01742221787571907,
            -0.01983160339295864,
            -0.0020661999005824327,
            -0.03633039817214012,
            0.010870854370296001,
            0.024922331795096397,
            0.028754685074090958,
            0.006244331132620573,
            -0.027486031875014305,
            0.010614762082695961,
            -0.012881528586149216,
            0.024344002828001976,
            0.02811747044324875,
            -0.017363257706165314,
            -0.0166135523468256,
            0.006401474587619305,
            -0.02561747469007969,
            0.014752105809748173,
            -0.017472729086875916,
            0.02814912050962448,
            0.002674276940524578,
            0.0339910127222538,
            -0.014462846331298351,
            0.01720697432756424,
            0.0021718721836805344,
            0.02352532558143139,
            -0.007729832548648119,
            0.015723474323749542,
            -0.011983058415353298,
            0.006415441632270813,
            -0.014502116478979588,
            -0.023883158341050148,
            0.0015238169580698013,
            -0.019649475812911987,
            -0.014742286875844002,
            -0.010992677882313728,
            0.01158647146075964,
            -0.011581790633499622,
            0.006580951623618603,
            0.0016516983741894364,
            0.027928275987505913,
            0.022642262279987335,
            -0.016622323542833328,
            -0.013568964786827564,
            -0.00600134301930666,
            0.023370204493403435,
            -0.013015301898121834,
            -0.019065100699663162,
            0.03160318732261658,
            -0.038860660046339035,
            -0.010037532076239586,
            0.0012535328278318048,
            0.01601474918425083,
            -0.0427703894674778,
            -0.02448062039911747,
            0.02323799766600132,
            0.01439368724822998,
            0.03584380820393562,
            0.04266371577978134,
            0.02054712548851967,
            -0.012387269176542759,
            -0.020637724548578262,
            0.0013027399545535445,
            -0.0169210322201252,
            -0.0442204475402832,
            -0.015382739715278149,
            -0.0006798131507821381,
            0.009104941040277481,
            0.021420007571578026,
            0.020702999085187912,
            0.023576082661747932,
            0.0022784718312323093,
            0.003812509821727872,
            -0.014924868941307068,
            -0.009681559167802334,
            0.003101604524999857,
            -0.001022114884108305,
            0.01156538724899292,
            0.00947757251560688,
            -0.05499573051929474,
            -0.0035858452320098877,
            -0.02344745211303234,
            -0.013791040517389774,
            0.010453934781253338,
            0.0005174675025045872,
            0.02177397534251213,
            0.018010338768363,
            -0.008423318155109882,
            -0.021472731605172157,
            -0.013497493229806423,
            -0.009723002091050148,
            0.01914287731051445,
            0.009223122149705887,
            -0.003022159915417433,
            0.012085750699043274,
            -0.02325710467994213,
            -0.01638219505548477,
            -0.0046269455924630165,
            -0.026504669338464737,
            -0.002686489373445511,
            -0.017495308071374893,
            -0.04078510031104088,
            0.0319654643535614,
            0.025977663695812225,
            -0.020447606220841408,
            -0.013439626432955265,
            0.02275235950946808,
            -0.004261267837136984,
            -0.0024303679820150137,
            0.0016318176640197635,
            -0.005411933176219463,
            0.012066451832652092,
            0.01582970656454563,
            0.004320818465203047,
            -0.023270558565855026,
            -0.021492430940270424,
            -0.0006058399449102581,
            0.017849434167146683,
            0.03207101300358772,
            0.015522697009146214,
            -0.01181622501462698,
            -0.020920405164361,
            0.009040785022079945,
            0.02881547249853611,
            -0.03768545389175415,
            0.03133704513311386,
            -0.00729714147746563,
            -0.019656922668218613,
            -0.003270023036748171,
            0.013681583106517792,
            -0.023743275552988052,
            -0.007355701643973589,
            0.004247696604579687,
            0.002800863469019532,
            -0.0037333830259740353,
            -0.0021068446803838015,
            0.011497070081532001,
            0.009310727939009666,
            0.03292795270681381,
            -0.036014851182699203,
            0.013152699917554855,
            0.03390628471970558,
            -0.010952203534543514,
            -0.0008205678896047175,
            -0.02141338214278221,
            0.024346670135855675,
            -0.008858747780323029,
            0.023630045354366302,
            0.04172517731785774,
            0.011328314431011677,
            0.033574264496564865,
            -0.001970326993614435,
            -0.009301618672907352,
            -0.012103833258152008,
            -0.07463651150465012,
            -0.009157007560133934,
            0.01803990639746189,
            0.01227190624922514,
            -0.020832529291510582,
            -0.0019320010906085372,
            -0.004459259565919638,
            0.013248390518128872,
            0.001468184171244502,
            0.014459663070738316,
            -0.010517599992454052,
            -0.0163161288946867,
            -0.02311014011502266,
            -0.02836870774626732,
            -0.006626007612794638,
            -0.0027646783273667097,
            -0.004936549812555313,
            0.027067936956882477,
            -0.008367111906409264,
            0.010623732581734657,
            0.01930522546172142,
            0.028725162148475647,
            -0.01941547356545925,
            -0.03341492637991905,
            -0.03139350935816765,
            0.022032687440514565,
            -0.027368560433387756,
            0.016840210184454918,
            -0.017717232927680016,
            -0.04136788472533226,
            -0.013742277398705482,
            0.03332588076591492,
            0.04325439780950546,
            0.01981574483215809,
            -0.010805756784975529,
            -0.04206545278429985,
            0.006432277150452137,
            -0.01673450879752636,
            0.005821823608130217,
            -0.022877953946590424,
            0.022902175784111023,
            -0.02264435589313507,
            -0.002250639721751213,
            -0.012294580228626728,
            -0.032313961535692215,
            -0.015189854428172112,
            0.012228606268763542,
            -0.02814095839858055,
            -0.03134351596236229,
            -0.01594679243862629,
            0.012268095277249813,
            0.022169223055243492,
            -0.014323104172945023,
            -0.003726278431713581,
            -0.013914797455072403,
            -0.013690531253814697,
            -0.025063563138246536,
            0.0005807337583974004,
            0.026512742042541504,
            -0.014949701726436615,
            -0.015413284301757812,
            -0.028879711404442787,
            -0.02619164250791073,
            0.02762700617313385,
            -0.019623911008238792,
            0.017439914867281914,
            0.035861920565366745,
            -0.015196768566966057,
            0.015455741435289383,
            0.0002386827109148726,
            0.011224139481782913,
            -0.021904772147536278,
            0.021797997877001762,
            0.023966128006577492,
            0.03341451659798622,
            -0.00572544988244772,
            -0.0011986258905380964,
            -0.008385883644223213,
            -0.008213039487600327,
            0.0003062282921746373,
            0.013483860529959202,
            0.025586144998669624,
            0.0006686000851914287,
            0.007053684908896685,
            0.02313198894262314,
            0.028020989149808884,
            0.03202153742313385,
            0.028511878103017807,
            0.0009872576920315623,
            0.02639361098408699,
            0.005932713858783245,
            -0.001840936834923923,
            -0.017352163791656494,
            0.004770847037434578,
            0.03000926598906517,
            0.005239596590399742,
            -0.04502743482589722,
            0.0002974577364511788,
            0.01512738224118948,
            -0.006224161013960838,
            -0.035701196640729904,
            -0.041146352887153625,
            -0.01151952799409628,
            -0.023884642869234085,
            0.008375667035579681,
            0.03696923702955246,
            -0.03688214719295502,
            0.028144152835011482,
            0.022091802209615707,
            0.0060696834698319435,
            0.007923729717731476,
            -0.012627131305634975,
            0.010509960353374481,
            -0.019976165145635605,
            0.04145517572760582,
            -0.021915625780820847,
            -0.007397465407848358,
            0.03401259705424309,
            0.01981419138610363,
            0.0010738266864791512,
            -0.022702664136886597,
            -0.0009266996057704091,
            -0.023122629150748253,
            0.023859361186623573,
            -0.009923802688717842,
            -0.011380746029317379,
            -0.019749082624912262,
            -0.03664151951670647,
            0.023053189739584923,
            -0.015984322875738144,
            -0.03117886744439602,
            -0.01681877113878727,
            0.010255592875182629,
            -0.00769470538944006,
            0.03966335579752922,
            0.02434227615594864,
            -0.020158372819423676,
            -0.000354614807292819,
            0.01007099263370037,
            -0.011337933130562305,
            0.005593474488705397,
            0.03286297619342804,
            0.03418011590838432,
            0.02863943576812744,
            -0.029086224734783173,
            -0.0076305498369038105,
            0.004524191375821829,
            -0.021782690659165382,
            -0.009779360145330429,
            0.024595506489276886,
            0.01894502528011799,
            0.0041273003444075584,
            0.02365836501121521,
            -0.02781539410352707,
            -0.025005003437399864,
            0.010531489737331867,
            -0.0017553268698975444,
            -0.016309920698404312,
            -0.023624353110790253,
            0.0312650129199028,
            0.018718013539910316,
            0.003598973387852311,
            0.02509348839521408,
            -0.018321022391319275,
            -0.006042947061359882,
            -0.028676798567175865,
            -0.005484609864652157,
            -0.02600802108645439,
            0.027381177991628647,
            0.017982585355639458,
            -0.0019802930764853954,
            0.03243759274482727,
            -0.0033602009061723948,
            0.016361260786652565,
            -0.0194812323898077,
            -0.02867547981441021,
            -0.005751606542617083,
            0.01526132132858038,
            -0.053256042301654816,
            -0.023506294935941696,
            -0.013608196750283241,
            -0.004423382692039013,
            -0.03607804700732231,
            -0.024651916697621346,
            -0.02064414694905281,
            -0.011294638738036156,
            -0.004997574258595705,
            -0.008318128064274788,
            -0.0028680942486971617,
            0.027601249516010284,
            -0.020016036927700043,
            0.01303944829851389,
            0.00794266164302826,
            0.026990192010998726,
            -0.01587355136871338,
            -0.007990318350493908,
            0.020670128986239433,
            -0.023886632174253464,
            -0.0037966545205563307,
            0.029149804264307022,
            -0.008459316566586494,
            0.02437475137412548,
            0.01831158623099327,
            0.014651552774012089,
            0.0010105236433446407,
            -0.009169833734631538,
            0.028619565069675446,
            -0.021408652886748314,
            0.011307443492114544,
            0.013187908567488194,
            0.012460394762456417,
            -0.019593067467212677,
            0.006678794510662556,
            0.013940398581326008,
            0.012913646176457405,
            -0.003708677599206567,
            -0.0013012939598411322,
            -0.01395521592348814,
            -0.02425418235361576,
            -0.022073078900575638,
            -0.04847609996795654,
            -0.01687801256775856,
            0.019582880660891533,
            0.005620972253382206,
            -0.006917331833392382,
            -0.0008299574838019907,
            0.0014639691216871142,
            -0.005854386370629072,
            -0.002619209699332714,
            -0.010259591042995453,
            0.021366355940699577,
            -0.0030350673478096724,
            5.438488005893305e-05,
            0.014470517635345459,
            0.02806868776679039,
            -0.01741565205156803,
            0.006837820168584585,
            -0.021392202004790306,
            -0.028403399512171745,
            -0.0028594636823982,
            -0.023780914023518562,
            -0.011059918440878391,
            -0.0054638986475765705,
            -0.021832920610904694,
            0.022121312096714973,
            -0.01433820091187954,
            -0.006883959751576185,
            0.03312771022319794
        ],
        [
            -0.029505519196391106,
            0.024594319984316826,
            0.008135151118040085,
            0.011035962961614132,
            0.008414365351200104,
            0.006447949446737766,
            -0.041035592555999756,
            -0.005679543595761061,
            0.0363638773560524,
            0.016580181196331978,
            0.03155256062746048,
            -0.02738969400525093,
            0.001436313847079873,
            -0.005769867449998856,
            -0.050594035536050797,
            -0.05077594146132469,
            0.012948792427778244,
            0.017418984323740005,
            -0.03142023831605911,
            -0.0060791391879320145,
            -0.003200350096449256,
            0.0008989337948150933,
            -0.028987670317292213,
            0.01443561539053917,
            -0.06979893893003464,
            0.0017153016524389386,
            -0.08984766900539398,
            -0.003635573899373412,
            -0.03472756966948509,
            -0.002569223055616021,
            -0.01259775459766388,
            -0.020432276651263237,
            0.019927246496081352,
            -0.021205544471740723,
            -0.008813049644231796,
            0.023487236350774765,
            0.002966697793453932,
            0.02584981359541416,
            0.02439306490123272,
            0.0069329142570495605,
            0.01482683327049017,
            0.02896992675960064,
            0.004924378823488951,
            -0.017343753948807716,
            -0.05246365815401077,
            0.02719806507229805,
            0.012856941670179367,
            -0.006174915470182896,
            -0.011016888543963432,
            0.00382011360488832,
            0.03490559011697769,
            0.0028023405466228724,
            -0.033953651785850525,
            -0.03599251061677933,
            0.0097522446885705,
            -0.016375916078686714,
            -0.011360015720129013,
            -0.02869143709540367,
            0.0041985344141721725,
            -0.020728250965476036,
            0.024967214092612267,
            -0.0007826843066141009,
            0.011778880842030048,
            -0.01294926181435585,
            -0.007132458500564098,
            -0.008246582932770252,
            -0.02748703956604004,
            0.016955437138676643,
            -0.005333477631211281,
            -0.015697985887527466,
            0.01987353526055813,
            0.018254050984978676,
            -0.01318825501948595,
            0.029895827174186707,
            -0.026426533237099648,
            -0.00310496031306684,
            -0.027389314025640488,
            -0.03052784688770771,
            0.012064172886312008,
            0.010869353078305721,
            -0.005479215644299984,
            -0.0950501337647438,
            -0.0044183311983942986,
            -0.025062788277864456,
            -0.017695967108011246,
            0.05545581504702568,
            0.00570782832801342,
            0.004564835224300623,
            -0.007950990460813046,
            0.04748215153813362,
            -0.01334519311785698,
            0.009966103360056877,
            -0.004627066198736429,
            -0.0076899160631000996,
            -0.010729551315307617,
            0.012920055538415909,
            -0.030783237889409065,
            -0.0011720602633431554,
            -0.08858941495418549,
            0.026053035631775856,
            -0.016623418778181076,
            -0.042204368859529495,
            -0.0183951985090971,
            -0.023762879893183708,
            0.004314892925322056,
            -0.0021779474336653948,
            -0.004036619793623686,
            0.04763421416282654,
            0.015483414754271507,
            0.019657639786601067,
            -0.00585206039249897,
            -0.010792285203933716,
            0.006993100047111511,
            -0.014805435203015804,
            -0.0072015877813100815,
            0.028137583285570145,
            0.02454090677201748,
            -0.0237394068390131,
            0.009154985658824444,
            -0.01602904312312603,
            0.01210835762321949,
            0.013896126300096512,
            0.03856672719120979,
            -0.019925808534026146,
            0.02042039856314659,
            0.025561530143022537,
            0.03971904143691063,
            0.02963188849389553,
            0.022172119468450546,
            0.04155406728386879,
            0.016017358750104904,
            -0.01134918350726366,
            -0.0688575729727745,
            0.030923746526241302,
            0.012464480474591255,
            0.020797481760382652,
            0.015295518562197685,
            -0.006167518440634012,
            0.007916374132037163,
            0.01315841544419527,
            0.013686547055840492,
            -0.01315164752304554,
            -0.029472019523382187,
            0.029030464589595795,
            0.01611952856183052,
            -0.022328471764922142,
            0.018465494737029076,
            0.008431809954345226,
            -0.00746965641155839,
            0.01971205323934555,
            0.031737204641103745,
            -0.01832394115626812,
            -0.02028338797390461,
            -0.014014254324138165,
            -0.03217768669128418,
            0.040458519011735916,
            -0.04468167945742607,
            -0.04145079106092453,
            -0.04393596947193146,
            0.002641410334035754,
            0.022271929308772087,
            0.010821253061294556,
            0.033766116946935654,
            0.004880660679191351,
            0.038786616176366806,
            -0.019926507025957108,
            -0.006493286695331335,
            0.0038885336834937334,
            0.010532713495194912,
            -0.015611875802278519,
            0.02849520742893219,
            -0.05926837399601936,
            0.026361268013715744,
            0.03313804417848587,
            0.01813512109220028,
            -0.003912410698831081,
            0.03598947077989578,
            0.024947041645646095,
            0.004128472413867712,
            -0.03047822415828705,
            0.03527102619409561,
            -0.010574056766927242,
            0.023310774937272072,
            0.04072299972176552,
            -0.011946966871619225,
            0.022451311349868774,
            -0.03372819349169731,
            0.016434375196695328,
            0.002169640501961112,
            -0.01992523856461048,
            -0.02141902782022953,
            0.006265339907258749,
            -0.0421026349067688,
            -0.0011405122932046652,
            -0.019919823855161667,
            0.025928674265742302,
            0.0025675359647721052,
            -0.006839886773377657,
            0.022138820961117744,
            -0.012662364169955254,
            -0.022916333749890327,
            -0.0034457596484571695,
            0.016951510682702065,
            -0.012155875563621521,
            0.0006549601093865931,
            -0.0214376263320446,
            -0.01979762688279152,
            -0.07613818347454071,
            -0.007059010211378336,
            0.011113585904240608,
            -7.988021934579592e-06,
            -0.02646096795797348,
            -0.017447248101234436,
            0.018132392317056656,
            -0.035002656280994415,
            0.011338633485138416,
            -0.02640736848115921,
            0.006767498329281807,
            0.03448418155312538,
            -0.026691211387515068,
            -0.010176164098083973,
            0.016827108338475227,
            -0.028130296617746353,
            -1.4591046237910632e-05,
            -0.003986378666013479,
            0.07476051896810532,
            -0.009353664703667164,
            0.0017725266516208649,
            -0.041607946157455444,
            0.013239923864603043,
            0.0036890138871967793,
            -0.006663869600743055,
            0.009426528587937355,
            0.012001768685877323,
            -0.00935314130038023,
            -0.019722990691661835,
            -0.030871601775288582,
            0.009709217585623264,
            -0.023807888850569725,
            0.006087533663958311,
            -0.005558275151997805,
            -0.013480533845722675,
            -0.023541441187262535,
            0.02964182384312153,
            0.060672979801893234,
            -0.01408127136528492,
            -0.019228462129831314,
            0.022145187482237816,
            -0.014234453439712524,
            -0.04198984429240227,
            -0.01675455830991268,
            -0.010866464115679264,
            0.013765033334493637,
            -0.002368304878473282,
            -0.027002718299627304,
            -0.006727299187332392,
            0.01809784583747387,
            0.010999424383044243,
            0.017449961975216866,
            0.014480305835604668,
            -0.00498818838968873,
            0.03306974470615387,
            0.0027961265295743942,
            -0.005929568316787481,
            -0.009705271571874619,
            -0.05815132334828377,
            0.02157052606344223,
            0.022666359320282936,
            0.0014474188210442662,
            -0.02099681831896305,
            -0.021422822028398514,
            0.04090438783168793,
            0.026621419936418533,
            0.012417602352797985,
            0.016225066035985947,
            0.010035761632025242,
            0.025772441178560257,
            0.001137204235419631,
            0.0031923067290335894,
            -0.03395596146583557,
            0.055740565061569214,
            -0.005277258809655905,
            0.025206202641129494,
            0.015302433632314205,
            0.03275701776146889,
            0.021442580968141556,
            -0.01914330944418907,
            -0.013531637378036976,
            0.023164162412285805,
            0.008722621947526932,
            0.03416537493467331,
            -0.0021740279626101255,
            0.017821641638875008,
            0.03617141395807266,
            0.018741101026535034,
            -0.036285679787397385,
            0.0037984936498105526,
            0.032717153429985046,
            -0.009820905514061451,
            0.027245977893471718,
            0.016006797552108765,
            0.006874122191220522,
            -0.011537359096109867,
            0.036977704614400864,
            0.0027899041306227446,
            0.05195096135139465,
            0.022587768733501434,
            -0.0012727734865620732,
            -0.026841921731829643,
            -0.01500118337571621,
            -0.014049078337848186,
            0.007121518719941378,
            -0.0035218128468841314,
            0.018131831660866737,
            -0.016559461131691933,
            0.0038322273176163435,
            0.0036714873276650906,
            1.6043311916291714e-05,
            0.02236558496952057,
            -0.0355575866997242,
            -0.015761734917759895,
            -0.06954782456159592,
            -0.019206911325454712,
            0.012757339514791965,
            0.02267415076494217,
            -0.018922649323940277,
            -0.0008801601361483335,
            -0.02537539042532444,
            -0.009727902710437775,
            0.027822284027934074,
            0.007848205044865608,
            -0.04874443635344505,
            -0.005129873286932707,
            -0.02604812942445278,
            0.005786766763776541,
            -0.00911172665655613,
            -0.021260550245642662,
            -0.05303386598825455,
            0.0014568305341526866,
            -0.0486825630068779,
            0.026599662378430367,
            0.01831115409731865,
            -0.0006549318786710501,
            0.023588640615344048,
            -0.01882043667137623,
            0.046970169991254807,
            0.007374698296189308,
            -0.012910460121929646,
            -0.005186864640563726,
            -0.03909078612923622,
            0.03808625787496567,
            -0.03480423614382744,
            -0.10589633882045746,
            -0.03380298241972923,
            0.0066787488758563995,
            0.010997110046446323,
            0.014408716931939125,
            0.011016684584319592,
            -0.027193283662199974,
            -0.01423228345811367,
            0.022471072152256966,
            0.013025395572185516,
            -0.013624899089336395,
            0.015721693634986877,
            0.006999763194471598,
            0.002820197492837906,
            0.010450088419020176,
            0.0009939894080162048,
            -0.014575948007404804,
            -0.016450852155685425,
            -0.0025083934888243675,
            0.005942217539995909,
            -0.028436416760087013,
            0.028743823990225792,
            0.03021884150803089,
            -0.05448360741138458,
            0.0053349449299275875,
            0.024461302906274796,
            -0.08846461027860641,
            0.031895700842142105,
            0.04177619144320488,
            0.023452578112483025,
            0.007124170660972595,
            0.0022517007309943438,
            -0.023635681718587875,
            -0.005360655020922422,
            0.022673234343528748,
            0.012934885919094086,
            -0.03148471564054489,
            -0.03652682155370712,
            -0.027255689725279808,
            -0.03027862310409546,
            -0.018175087869167328,
            0.035894058644771576,
            -0.030486777424812317,
            0.017697462812066078,
            0.015071920119225979,
            -0.031773053109645844,
            0.0021488084457814693,
            0.004084537737071514,
            0.0014968073228374124,
            -0.028689241036772728,
            -0.016564443707466125,
            -8.941027772380039e-05,
            -0.03518107160925865,
            0.046602699905633926,
            0.009915714152157307,
            0.01889951340854168,
            -0.008960162289440632,
            -0.004708045162260532,
            -0.013388261198997498,
            0.03306668624281883,
            -0.00492504145950079,
            -0.0018611499108374119,
            -0.004352843388915062,
            -0.017514895647764206,
            -0.02074406109750271,
            0.017102207988500595,
            0.014550089836120605,
            -0.019026102498173714,
            0.025055957958102226,
            -0.030941221863031387,
            -0.062424518167972565,
            0.036554742604494095,
            -0.06642241030931473,
            0.005127142183482647,
            -0.0027017409447580576,
            -0.02521512098610401,
            -0.007521412335336208,
            0.02038457803428173,
            -0.039610374718904495,
            -0.01652134582400322,
            0.011595907621085644,
            0.009806779213249683,
            0.023947788402438164,
            0.01677202433347702,
            0.0006915486883372068,
            0.0210083220154047,
            -0.0017565162852406502,
            -0.019855616614222527,
            -0.011516178026795387,
            0.018567070364952087,
            0.01057014986872673,
            0.02965986356139183,
            0.027103958651423454,
            0.017058761790394783,
            -0.015617777593433857,
            -0.004431258421391249,
            -0.04197385907173157,
            -0.005871379282325506,
            -0.01696161925792694,
            -0.01841915398836136,
            -0.0022970964200794697,
            -0.01385725848376751,
            0.0008675644639879465,
            0.02327599562704563,
            0.005994634702801704,
            -0.012451937422156334,
            -0.008533138781785965,
            0.030174383893609047,
            0.02059311978518963,
            0.03146931156516075,
            -0.07329711318016052,
            -0.013176330365240574,
            -0.020352661609649658,
            0.014545924961566925,
            -0.039004579186439514,
            -0.00142073689494282,
            0.007063401397317648,
            0.002427799627184868,
            0.02433757483959198,
            0.0005410644225776196,
            0.005655207205563784,
            0.004517622757703066,
            0.011339135468006134,
            -0.054195698350667953,
            0.00461235549300909,
            0.004132261034101248,
            -0.011174602434039116,
            0.01890544779598713,
            -0.02150820381939411,
            -0.008604384027421474,
            -0.039458420127630234,
            0.019256211817264557,
            -0.008558560162782669,
            0.0004882916109636426,
            0.029284482821822166,
            0.030316635966300964,
            -0.018889419734477997,
            -0.06717261672019958,
            0.028209511190652847,
            0.013536450453102589,
            -0.00535756116732955,
            -0.0333935022354126,
            -0.06835033744573593,
            -0.015707438811659813,
            -0.011831928975880146,
            0.004546853713691235,
            -0.0068436553701758385,
            -0.020160209387540817,
            0.002504698932170868,
            -0.014530809596180916,
            -0.01673196628689766,
            -0.011540211737155914,
            0.005818142555654049,
            -0.004700070712715387,
            0.0016678889514878392,
            -0.009728992357850075,
            0.019992321729660034,
            -0.008885336108505726,
            -0.018998639658093452,
            0.02038378268480301,
            0.016587374731898308,
            -0.03894113749265671,
            0.02199903316795826,
            -0.01236064825206995,
            0.017074137926101685,
            0.006173427682369947,
            -0.011781510896980762,
            -0.006581803783774376,
            -0.03354335203766823,
            0.0288715623319149,
            -0.023578288033604622,
            -0.023992974311113358,
            -0.0039016057271510363,
            -0.030313393101096153,
            -0.01585228741168976,
            -0.025003774091601372,
            0.036454129964113235,
            0.0065132686868309975,
            -0.015143731608986855,
            0.02131800353527069,
            0.019770927727222443,
            0.031841032207012177,
            -0.031314462423324585,
            -0.0362718403339386,
            -0.0018152942648157477,
            0.034272003918886185,
            0.00022735304082743824,
            -0.026272915303707123,
            0.026479357853531837,
            -0.02278624288737774,
            0.004060913808643818,
            -0.029644405469298363,
            0.00887333694845438,
            -0.02008236013352871,
            -0.007919834926724434,
            0.01263581681996584,
            0.0129066351801157,
            0.04379957914352417,
            0.040376804769039154,
            0.00022571765293832868,
            -0.019396547228097916,
            0.038203686475753784,
            0.028586721047759056,
            0.020533109083771706,
            0.002441706135869026,
            0.012333584949374199,
            0.012379301711916924,
            0.00861381832510233,
            -0.039814092218875885,
            0.012708376161754131,
            0.0234270840883255,
            -0.04428871348500252,
            -0.03139423951506615,
            -0.0055399262346327305,
            -0.014393165707588196,
            -0.009605596773326397,
            -0.006278628017753363,
            -0.014349156990647316,
            0.005188062787055969,
            0.0243399515748024,
            -0.004307060036808252,
            0.023491615429520607,
            0.034161776304244995,
            0.01074021402746439,
            0.005276079289615154,
            0.03815479949116707,
            0.0076501513831317425,
            0.0050186337903141975,
            0.02186605893075466,
            0.027422472834587097,
            -0.005762917455285788,
            -0.03654538094997406,
            -0.020846635103225708,
            0.0022634754423052073,
            -0.02670113556087017,
            -0.025372527539730072,
            0.006668635178357363,
            0.01971477083861828,
            -0.01382213644683361,
            0.013717002235352993,
            0.00545791070908308,
            -0.012910928577184677,
            0.03515124320983887,
            0.032504621893167496,
            0.026535408571362495,
            0.02494192309677601,
            -0.0306731928139925,
            0.00875813513994217,
            0.034452006220817566,
            -0.04810729995369911,
            -0.010908461175858974,
            -0.025289250537753105,
            0.007963729090988636,
            -0.005055935587733984,
            0.033082593232393265,
            -0.03623640164732933,
            -0.010804164223372936,
            0.004087421111762524,
            -0.03170257806777954,
            0.008129814639687538,
            0.0037095346488058567,
            -0.024057792499661446,
            -0.000313217198709026,
            -0.011369863525032997,
            -0.006657611578702927,
            0.017325548455119133,
            0.0383467897772789,
            -0.060683559626340866,
            0.009608875960111618,
            -0.05495665222406387,
            0.007498137652873993,
            -0.02952536568045616,
            0.02306452952325344,
            -0.031975988298654556,
            -0.039212990552186966,
            -0.031477171927690506,
            0.01631791517138481,
            -0.016021693125367165,
            -0.0126159293577075,
            -0.0222967267036438,
            -0.039580345153808594,
            -0.018744179978966713,
            0.01097969338297844,
            -0.006593492813408375,
            -0.0018448263872414827,
            -0.04832300916314125,
            -0.05195165425539017,
            0.007356249261647463,
            -0.00951955933123827,
            -0.0059859054163098335,
            0.0017244938062503934,
            0.015865383669734,
            0.00967581756412983,
            0.032964520156383514,
            -0.03344609960913658,
            -0.022669777274131775,
            0.012088393792510033,
            -0.011599919758737087,
            -0.010635067708790302,
            0.004902438260614872,
            -0.009294840507209301,
            0.03998551517724991,
            -0.06192955747246742,
            0.03315473720431328,
            -0.010577593930065632,
            -0.013815104030072689,
            -0.016013508662581444,
            -0.02017204463481903,
            -0.028853420168161392,
            -0.014486836269497871,
            0.018506476655602455,
            0.0037082324270159006,
            0.03636259585618973,
            0.03515154495835304,
            0.0015054955147206783,
            0.027001192793250084,
            0.038345158100128174,
            0.01803845912218094,
            -0.03834562748670578,
            0.023707056418061256,
            -0.009161406196653843,
            -0.007164225447922945,
            -0.01782931573688984,
            -0.005988146178424358,
            0.011010021902620792,
            -0.015091575682163239,
            -0.0015872048679739237,
            -0.028501929715275764,
            0.019969942048192024,
            -0.04570959880948067,
            -0.023610444739460945,
            0.007476777769625187,
            -0.016904938966035843,
            0.025716632604599,
            -0.018636884167790413,
            -0.018464861437678337,
            -0.011033647693693638,
            0.05060628056526184,
            0.0357433557510376,
            -0.014558135531842709,
            -0.04986961930990219,
            0.0007918943301774561,
            -0.007344815880060196,
            0.022325947880744934,
            -0.0229121595621109,
            0.015474185347557068,
            0.020896906033158302,
            -0.005455590318888426,
            0.04327423498034477,
            -0.02590939961373806,
            -0.011646353639662266,
            -0.027621647343039513,
            -0.006827549077570438,
            0.007810117211192846,
            0.0011507152812555432,
            -0.018689511343836784,
            0.015117330476641655,
            -0.03271548077464104,
            0.0025222080294042826,
            -0.007838551886379719,
            0.002346680499613285,
            0.026484662666916847,
            0.04480309784412384,
            -0.009761311113834381,
            -0.08570162206888199,
            -0.030584795400500298,
            -0.03141156956553459,
            -0.02313140034675598,
            -0.03255350515246391,
            0.011270267888903618,
            -0.013296015560626984,
            -0.00817631185054779,
            0.012586677446961403,
            0.004343793727457523,
            -0.022860482335090637,
            -0.013244655914604664,
            -0.005051960702985525,
            0.021203579381108284,
            -0.06262226402759552,
            -0.026353001594543457,
            0.014758781529963017,
            0.026217197999358177,
            -0.04495001211762428,
            0.03448102995753288,
            -0.0008395566837862134,
            0.030638013035058975,
            -0.038336750119924545,
            0.032215289771556854,
            -0.014675830490887165,
            -0.00538506219163537,
            -0.019754331558942795,
            0.015619269572198391,
            0.04066034406423569,
            -0.059159182012081146,
            -0.035352420061826706,
            0.005980438087135553,
            -0.007238396443426609,
            0.030388550832867622,
            -0.029754607006907463,
            0.0017843383830040693,
            -0.01266426034271717,
            0.03678421303629875,
            -0.015290050767362118,
            0.0037811947986483574,
            0.01012401469051838,
            -0.00797567144036293,
            0.04535272344946861,
            0.025488747283816338,
            -0.017109990119934082,
            -0.03359741345047951,
            -0.017667952924966812,
            -0.026431426405906677,
            -0.015981310978531837,
            0.00022548786364495754,
            0.013183658011257648,
            0.0010352499084547162,
            -0.010396191850304604,
            -0.010278578847646713,
            -0.00882208812981844,
            -0.00438811955973506,
            -0.010907393880188465,
            0.04160478711128235,
            -0.010957911610603333,
            0.00924692302942276,
            -0.04489589110016823,
            -0.006372903008013964,
            0.030826885253190994,
            -0.010147633031010628,
            -0.022905750200152397,
            0.039100103080272675,
            -0.010739961639046669,
            0.005781965795904398,
            -0.015084902755916119,
            -0.01442594826221466,
            -0.025233503431081772,
            -0.026776477694511414,
            0.0014401415828615427,
            -0.00869539100676775,
            -0.012999190017580986,
            -0.0014999366831034422,
            0.033874597400426865,
            0.009966599754989147,
            -0.02177310921251774,
            -0.00959097221493721,
            -0.029717689380049706,
            0.019002581015229225,
            0.026046166196465492,
            -0.025995993986725807,
            0.016464317217469215,
            0.016921164467930794,
            -0.00020296682487241924,
            0.018874216824769974,
            0.03610152751207352,
            0.027557019144296646,
            -0.016689512878656387,
            -0.00966101698577404,
            0.014887157827615738,
            0.00859156996011734,
            -0.03803790360689163,
            -0.023549355566501617,
            0.01655658707022667,
            -0.004484223201870918,
            0.020160410553216934,
            -0.024605900049209595,
            -0.007310406304895878,
            -0.028417332097887993,
            -0.030492965131998062,
            0.043058715760707855,
            0.024207530543208122,
            0.023944715037941933,
            0.01962934248149395,
            -0.008105764165520668,
            -0.025661857798695564,
            -0.006734502036124468,
            0.027557412162423134,
            0.016119137406349182,
            0.0061856647953391075,
            0.023667916655540466,
            0.008890347555279732,
            -0.009799255058169365,
            0.04445752874016762,
            -0.02570776268839836,
            -0.026657452806830406,
            0.019205685704946518,
            0.012450486421585083,
            -0.015121115371584892,
            -0.009781631641089916,
            0.018589403480291367,
            -0.01907336711883545,
            0.02204337902367115,
            -0.007346931379288435,
            0.014911530539393425,
            -0.026382697746157646,
            -0.011501415632665157,
            -0.037286434322595596,
            0.019777940586209297,
            -0.023955557495355606,
            0.05003315210342407,
            0.03843940794467926,
            0.01906287483870983,
            -0.023117318749427795,
            0.035527024418115616,
            -0.012037464417517185,
            0.03841661289334297,
            0.02292441762983799,
            0.0011485492577776313,
            -0.009830317460000515,
            -0.027162989601492882,
            0.017174769192934036,
            -0.02532160095870495,
            -0.025913666933774948,
            0.02247679978609085,
            -0.004560863599181175,
            -0.018912825733423233,
            0.011760716326534748,
            0.03028888814151287,
            -0.027432475239038467,
            0.017366908490657806,
            -0.019512198865413666,
            0.03022225946187973,
            -0.015299626626074314,
            0.021539784967899323,
            0.03675050288438797,
            -0.023861832916736603,
            -0.03349993750452995,
            -0.008977453224360943,
            -0.017418384552001953,
            0.00934286043047905,
            0.00812047254294157,
            0.007839985191822052,
            0.02149168960750103,
            -0.00905655324459076,
            -0.018311001360416412,
            0.006425158120691776,
            0.005381889641284943,
            -0.04341788589954376,
            -0.0025546234101057053,
            0.03422590345144272,
            0.016583506017923355,
            0.005177621264010668,
            -0.029094338417053223,
            -0.03375685587525368,
            -0.07146432995796204,
            0.043245453387498856,
            0.016860220581293106,
            0.010720864869654179,
            -0.034013401716947556,
            -0.020481565967202187,
            -0.030057482421398163,
            0.03417859226465225,
            0.037128571420907974,
            -0.006099635735154152,
            -0.041417211294174194,
            0.0025024060159921646,
            0.0076612806878983974,
            -0.004648081958293915,
            0.040244586765766144,
            0.027986537665128708,
            -0.009583798237144947,
            -0.007951837033033371,
            -0.01482249703258276,
            -0.019012093544006348,
            0.011031579226255417,
            0.014585323631763458,
            -0.024814529344439507,
            -0.03086322918534279,
            -0.010814394801855087,
            0.035242967307567596,
            -0.009385528974235058,
            -0.004578105639666319,
            0.020922742784023285,
            -0.02080988883972168,
            0.015067795291543007,
            -0.0021043000742793083,
            -0.0008199540316127241,
            -0.04849914088845253,
            0.0062127127312123775,
            -0.008615415543317795,
            0.04049653559923172,
            -0.011406033299863338,
            0.02929459698498249,
            -0.0029165328014642,
            0.007011731155216694,
            -0.0036946421023458242,
            -0.022448359057307243,
            -0.0006116825388744473,
            0.029532162472605705,
            0.0166009534150362,
            0.0029005389660596848,
            0.020305944606661797,
            0.012814264744520187,
            -0.02789515256881714,
            0.009453431703150272,
            -0.015453693456947803,
            0.00840921513736248,
            0.04431527107954025,
            0.006324704270809889,
            -0.011122171767055988,
            0.01811392232775688,
            0.02298479527235031,
            -0.007456734776496887,
            0.02905791439116001,
            -0.024818236008286476,
            0.019312821328639984,
            -0.005001676734536886,
            -0.02506338618695736,
            0.036948591470718384,
            0.00672969501465559,
            -0.04701993986964226,
            -0.01572982594370842,
            -0.014257138594985008,
            -0.016452845185995102,
            -0.0056299264542758465,
            0.007741200737655163,
            0.0472467765212059,
            0.006337551400065422,
            0.008033103309571743,
            -0.020996931940317154,
            0.01035546325147152,
            -0.028291232883930206,
            0.006879158318042755,
            -0.011499304324388504,
            -0.041872818022966385,
            -0.023554159328341484,
            0.05694064125418663,
            -0.024986078962683678,
            0.03313109651207924,
            0.03258362039923668,
            0.011124715209007263,
            0.013736696913838387,
            -0.003046149853616953,
            -0.041952382773160934,
            -0.0053718192502856255,
            0.001495106378570199,
            0.021054338663816452,
            -0.0016254953807219863,
            0.02783999778330326,
            -0.028941815719008446,
            -0.016192875802516937,
            -0.019047634676098824,
            -0.018498100340366364,
            0.0018190855626016855,
            0.028587907552719116,
            -0.006670909933745861,
            -0.036402229219675064,
            -0.036804914474487305,
            -0.0017494367202743888,
            0.005733003374189138,
            -0.03118741326034069,
            0.02122347429394722,
            -0.014613636769354343,
            0.017607184126973152,
            0.030747264623641968,
            0.003834403119981289,
            0.02193933166563511,
            -0.03876638785004616,
            0.02950391359627247,
            0.032814111560583115,
            0.0011219247244298458,
            -0.04860633984208107,
            0.023927688598632812,
            0.009831302799284458,
            -0.023996451869606972,
            -0.01735762320458889,
            0.0225398987531662,
            -0.019808776676654816,
            -0.02024322934448719,
            0.028039248660206795,
            -0.004996107425540686,
            0.014899978414177895,
            0.008813055232167244,
            0.0187300406396389
        ],
        [
            0.04505254328250885,
            -0.028009548783302307,
            0.012468922883272171,
            -0.026571625843644142,
            -0.034566957503557205,
            0.023582255467772484,
            -0.0033110829535871744,
            -0.041459307074546814,
            -0.011054807342588902,
            0.014571571722626686,
            0.032160110771656036,
            -0.00694628432393074,
            -0.02876953035593033,
            -0.011917843483388424,
            -0.025735044851899147,
            0.036919161677360535,
            -0.03153412789106369,
            -0.00329371215775609,
            0.018443897366523743,
            0.014515840448439121,
            -0.017584938555955887,
            -0.004638454876840115,
            -0.0007828798261471093,
            -0.008631234988570213,
            0.020060459151864052,
            0.032543301582336426,
            0.023472363129258156,
            -0.012351619079709053,
            -0.0029283245094120502,
            -0.01076571550220251,
            0.01420594658702612,
            0.01705586537718773,
            0.01204114593565464,
            0.016708634793758392,
            0.020719831809401512,
            -0.026827706024050713,
            0.022237680852413177,
            -0.022394292056560516,
            -0.0006841948488727212,
            0.012962071225047112,
            0.025898335501551628,
            -0.011940724216401577,
            0.030126255005598068,
            0.013843582943081856,
            0.014727456495165825,
            -0.03621606528759003,
            -0.002370657632127404,
            0.020136123523116112,
            -0.02974390983581543,
            -0.029823290184140205,
            -0.026784032583236694,
            -0.008700575679540634,
            -0.03225497901439667,
            0.008005017414689064,
            0.008830510079860687,
            0.014882417395710945,
            0.008233989588916302,
            -0.01010866928845644,
            0.004808664787560701,
            -0.004663003608584404,
            -0.007959924638271332,
            -0.0063532316125929356,
            0.024705300107598305,
            0.014402921311557293,
            -0.05181435868144035,
            0.004150975961238146,
            -0.011582202278077602,
            0.02525789849460125,
            -0.034905508160591125,
            -0.0039746263064444065,
            0.006270730867981911,
            0.031320199370384216,
            0.029971202835440636,
            0.015160918235778809,
            -0.011541501618921757,
            0.045211005955934525,
            0.03891703486442566,
            -0.009178189560770988,
            -0.028608521446585655,
            -0.000172333195223473,
            -0.015450246632099152,
            -0.04151438549160957,
            0.014677772298455238,
            -0.023186426609754562,
            -0.014279447495937347,
            0.024582089856266975,
            -0.027589736506342888,
            -0.024831363931298256,
            -0.0004970291629433632,
            -0.06121549382805824,
            0.016327382996678352,
            -0.03311852738261223,
            -0.01751750148832798,
            0.032660841941833496,
            -0.04291200265288353,
            0.016431888565421104,
            0.021014712750911713,
            -0.03022104874253273,
            -0.0018887699116021395,
            0.0002721278870012611,
            0.03292505815625191,
            -0.03336438164114952,
            -0.015767375007271767,
            -0.008285777643322945,
            0.013955766335129738,
            -0.028055114671587944,
            0.023849116638302803,
            0.02233152464032173,
            -0.008000829257071018,
            -0.011478211730718613,
            0.011377235874533653,
            0.009883025661110878,
            -0.017419839277863503,
            0.017403123900294304,
            0.09968515485525131,
            -0.001157334423623979,
            -0.015217754058539867,
            0.030335022136569023,
            0.0017183690797537565,
            -0.017870226874947548,
            0.020923558622598648,
            -0.01124563254415989,
            -0.0011200782610103488,
            0.0017625151667743921,
            -0.03530663251876831,
            -0.02729358896613121,
            -0.03216448053717613,
            0.012592950835824013,
            0.013023080304265022,
            -0.012545268051326275,
            0.021449368447065353,
            -0.008672116324305534,
            0.029995128512382507,
            0.0017798322951421142,
            -0.013511530123651028,
            0.0022925841622054577,
            -0.0498359277844429,
            -0.017081068828701973,
            -0.00554793793708086,
            0.010273589752614498,
            0.030306512489914894,
            -0.004125503357499838,
            -0.029694683849811554,
            0.01719682849943638,
            0.009021853096783161,
            -0.010774200782179832,
            0.016505438834428787,
            0.008581560105085373,
            0.024885987862944603,
            0.01041113119572401,
            -0.012144146487116814,
            0.061172910034656525,
            -0.015102729201316833,
            0.06024743616580963,
            0.025871390476822853,
            -0.02592986635863781,
            0.018629681318998337,
            -0.008814070373773575,
            0.0052674803882837296,
            -0.04620969668030739,
            -0.026082003489136696,
            -0.027608804404735565,
            -0.018828198313713074,
            -0.018698032945394516,
            0.015403720550239086,
            0.0015182298375293612,
            0.013537554070353508,
            -0.016445208340883255,
            -0.019530540332198143,
            0.011699867434799671,
            0.029488427564501762,
            0.012104769237339497,
            -0.011312630027532578,
            0.0069711110554635525,
            0.005555611103773117,
            0.025342077016830444,
            -0.03269403800368309,
            -0.015451953746378422,
            -0.024336425587534904,
            -0.004197404254227877,
            -0.04468326270580292,
            0.01868189126253128,
            0.02155330404639244,
            -0.023608341813087463,
            0.03054974600672722,
            -0.02909841760993004,
            -0.026248503476381302,
            -0.011337639763951302,
            0.02376634068787098,
            0.011522548273205757,
            0.031242934986948967,
            -0.010542147792875767,
            0.0031812689267098904,
            -0.03137592971324921,
            -0.03476070240139961,
            -0.010177499614655972,
            0.0072846789844334126,
            -0.014623837545514107,
            -0.0009581680060364306,
            0.021068047732114792,
            0.02986755408346653,
            -0.00811916682869196,
            0.04898131638765335,
            -0.0036224527284502983,
            0.03681699186563492,
            -0.017868470400571823,
            -0.049005959182977676,
            -0.013980040326714516,
            -0.023409714922308922,
            0.025304539129137993,
            -0.034865472465753555,
            0.009468003176152706,
            -0.06506309658288956,
            0.0024306769482791424,
            0.003563741222023964,
            -0.0036171837709844112,
            0.01458097156137228,
            -0.007278726436197758,
            -0.011650973930954933,
            0.03163329139351845,
            -0.02047862857580185,
            -0.012132101692259312,
            0.007558679208159447,
            -0.017405586317181587,
            0.028820639476180077,
            -0.03761839494109154,
            0.05380726605653763,
            0.010385018773376942,
            -0.01704990677535534,
            -0.03137941285967827,
            -0.019080398604273796,
            -0.02409256063401699,
            0.03262817859649658,
            -0.01086214929819107,
            -0.005401748698204756,
            0.024328909814357758,
            -0.009834394790232182,
            -0.005599950905889273,
            0.002973224502056837,
            -0.006170834880322218,
            -0.024951191619038582,
            0.020807940512895584,
            0.019682805985212326,
            -0.02127976343035698,
            0.01401979848742485,
            0.015584560111165047,
            0.022545158863067627,
            -0.03923541307449341,
            -0.007559345103800297,
            -0.01309120375663042,
            -0.008450926281511784,
            0.0030792695470154285,
            -0.024525916203856468,
            -0.0023213508538901806,
            0.024838823825120926,
            0.0020379575435072184,
            -0.02725590392947197,
            0.019191380590200424,
            0.014281569048762321,
            0.04640663415193558,
            0.012116831727325916,
            0.00828384980559349,
            -0.04383598268032074,
            0.013621412217617035,
            -0.010610423050820827,
            -0.031141942366957664,
            0.008043347857892513,
            0.0319092683494091,
            0.029513854533433914,
            -0.004548125434666872,
            -0.04125228524208069,
            0.015835732221603394,
            0.011787956580519676,
            0.022992679849267006,
            -0.013497404754161835,
            -0.029057951644062996,
            0.008860703557729721,
            -0.005241794977337122,
            -0.0036773814354091883,
            -0.012170257978141308,
            -0.016012879088521004,
            0.03603248670697212,
            -0.02822917141020298,
            0.011755241081118584,
            -0.010967462323606014,
            -0.036318834871053696,
            -0.0272545013576746,
            0.0005954979569651186,
            0.02428600564599037,
            0.012332028709352016,
            -0.016716089099645615,
            0.007899343967437744,
            -0.001733556971885264,
            -0.00302305375225842,
            -0.00039795011980459094,
            -0.0124770263209939,
            0.0001631795021239668,
            0.03075057826936245,
            -0.0036981417797505856,
            -0.0036192559637129307,
            0.014491008594632149,
            -0.0007884856313467026,
            -0.02155616693198681,
            0.006176451221108437,
            0.018159369006752968,
            0.07043959200382233,
            0.014469834044575691,
            0.028632918372750282,
            -0.005394791252911091,
            -0.008034244179725647,
            -0.047826435416936874,
            0.0007191267795860767,
            -0.029935715720057487,
            -0.009159348905086517,
            0.038849178701639175,
            -0.015299837104976177,
            0.020264528691768646,
            0.004743758589029312,
            -0.011015720665454865,
            0.02624143660068512,
            -0.01442029606550932,
            -0.03852730989456177,
            0.007477192673832178,
            -0.059477467089891434,
            -0.01787518337368965,
            0.03459138795733452,
            -0.05357658118009567,
            -0.005441297776997089,
            0.006490624509751797,
            -0.04020209610462189,
            -0.010083791799843311,
            -3.5950473829871044e-05,
            -0.0007773321704007685,
            0.015844380483031273,
            -0.08274491876363754,
            0.024863149970769882,
            -0.0040902625769376755,
            -0.0027261485811322927,
            -0.008547932840883732,
            0.019522296264767647,
            -0.033496517688035965,
            0.02114163152873516,
            0.00031861875322647393,
            0.013780090026557446,
            0.004730517975986004,
            -0.00036379112862050533,
            -0.01873210445046425,
            0.025894341990351677,
            0.02452085353434086,
            0.035420313477516174,
            -0.012264877557754517,
            0.07567337900400162,
            -0.0062729124911129475,
            0.022938715294003487,
            0.022961318492889404,
            0.0009700987720862031,
            0.023344825953245163,
            0.002511260798200965,
            -0.011544893495738506,
            -0.01803259551525116,
            0.016917647793889046,
            0.00466868095099926,
            -0.022391989827156067,
            0.011285753920674324,
            0.012117939069867134,
            -0.017985263839364052,
            -0.038561757653951645,
            -0.0238626841455698,
            -0.014664593152701855,
            0.015252682380378246,
            -0.031480949372053146,
            -0.027369927614927292,
            -0.008448667824268341,
            -0.014609645120799541,
            -0.003713565180078149,
            -0.006446685176342726,
            0.033957984298467636,
            -0.025168076157569885,
            0.004731363151222467,
            -0.027969853952527046,
            -0.013772593811154366,
            0.023609280586242676,
            0.015300887636840343,
            0.03827165067195892,
            0.0101487897336483,
            -0.01939026080071926,
            0.024620164185762405,
            0.030499154701828957,
            -0.010204382240772247,
            -0.02820558100938797,
            -0.02839644066989422,
            -0.005630508530884981,
            0.05250591039657593,
            -0.026335755363106728,
            -0.0009183341753669083,
            -0.017353681847453117,
            0.017859645187854767,
            0.03617424517869949,
            0.020386751741170883,
            0.01439251471310854,
            -0.03839099779725075,
            0.011586888693273067,
            -0.0048258500173687935,
            0.01730775646865368,
            0.018755069002509117,
            -0.028535624966025352,
            -0.04023388773202896,
            0.024993035942316055,
            0.014758381061255932,
            -0.01345323957502842,
            0.003600137308239937,
            0.02261528931558132,
            0.015250694938004017,
            0.011007786728441715,
            -0.022281015291810036,
            -0.004476075526326895,
            -0.023530138656497,
            0.06049977242946625,
            0.022876471281051636,
            0.018908405676484108,
            0.06418129056692123,
            0.017505066469311714,
            0.0030905401799827814,
            -0.02387714944779873,
            -0.004134354647248983,
            0.004436520393937826,
            -0.02697325311601162,
            -0.006905795074999332,
            0.018628794699907303,
            0.0006454418180510402,
            -0.04116510972380638,
            -0.015096736140549183,
            0.0214404109865427,
            -0.016379069536924362,
            0.026327645406126976,
            0.02217155508697033,
            0.017963526770472527,
            -0.005538142751902342,
            -0.03231663256883621,
            0.007799269165843725,
            0.0330597460269928,
            0.04061448201537132,
            -0.01621442474424839,
            -0.036118846386671066,
            0.02824162133038044,
            -0.021369069814682007,
            -0.015161900781095028,
            -0.07029791921377182,
            -0.024336718022823334,
            -0.032450929284095764,
            -0.02772781439125538,
            0.0073259323835372925,
            0.004933458752930164,
            0.015742622315883636,
            0.013104063458740711,
            -0.023327825590968132,
            0.02691204845905304,
            -0.011211907491087914,
            0.022740554064512253,
            0.008143018931150436,
            -0.0017034565098583698,
            0.018699418753385544,
            -0.011426500976085663,
            0.011402000673115253,
            -0.011285759508609772,
            -0.012927561067044735,
            0.014362078160047531,
            -0.013734402135014534,
            -0.006419970188289881,
            -0.028271501883864403,
            0.02771035023033619,
            -0.029140902683138847,
            0.00601009838283062,
            0.009488041512668133,
            -0.0387212336063385,
            -0.017816226929426193,
            -0.024864228442311287,
            0.021760884672403336,
            -0.00650890963152051,
            -0.028632720932364464,
            -0.05534360185265541,
            -0.005844589322805405,
            -0.047358326613903046,
            0.023080872371792793,
            0.06420290470123291,
            -0.01636691391468048,
            0.017357468605041504,
            -0.014135806821286678,
            -0.10652947425842285,
            -0.02328657917678356,
            0.04789610579609871,
            -0.028306005522608757,
            0.012661660090088844,
            -0.016245268285274506,
            0.020088007673621178,
            0.03126170113682747,
            0.036554548889398575,
            -0.0025154068134725094,
            -0.03464619815349579,
            0.002182269934564829,
            -0.0007112801540642977,
            -0.009494950994849205,
            -0.029926568269729614,
            -0.010250248946249485,
            -0.03449976444244385,
            0.0059149907901883125,
            -0.02497790940105915,
            0.032555852085351944,
            0.0013469492550939322,
            -0.017033519223332405,
            -0.03010130114853382,
            -0.01710124872624874,
            -0.030858701094985008,
            -0.027199741452932358,
            0.0017696165014058352,
            -0.015568049624562263,
            0.0008165536564774811,
            0.02063547447323799,
            0.006022737827152014,
            -0.036962538957595825,
            0.0026683935429900885,
            0.011604946106672287,
            0.03219842165708542,
            -0.046507932245731354,
            -0.05107399448752403,
            -0.05387519672513008,
            -0.036136846989393234,
            0.00926366075873375,
            -0.020692942664027214,
            0.008735241368412971,
            -0.036857426166534424,
            -0.003471365896984935,
            -0.035360779613256454,
            -0.01603618450462818,
            -0.02881305105984211,
            -0.06097828969359398,
            -0.008795920759439468,
            -0.0452234148979187,
            0.004650631919503212,
            -0.015366929583251476,
            0.021675024181604385,
            -0.015515531413257122,
            0.004839254543185234,
            0.01892687752842903,
            0.013783984817564487,
            0.048111602663993835,
            0.0008980545098893344,
            -0.022353364154696465,
            -0.021874798461794853,
            0.02246900275349617,
            -0.008557567372918129,
            -0.018952086567878723,
            0.008779100142419338,
            0.016957024112343788,
            -0.04234154894948006,
            -0.018263814970850945,
            0.009711001999676228,
            -0.0320260114967823,
            0.013310780748724937,
            0.027323339134454727,
            0.022636374458670616,
            0.00905317161232233,
            -0.019036300480365753,
            0.02516859583556652,
            -0.024509893730282784,
            -0.030459005385637283,
            -0.0057571022771298885,
            0.030024977400898933,
            -0.017392372712492943,
            -0.021186187863349915,
            0.01170223020017147,
            -0.010620832443237305,
            -0.03935600072145462,
            -0.025172529742121696,
            0.010445615276694298,
            0.01579696126282215,
            0.04284963756799698,
            8.098632679320872e-05,
            0.012859266251325607,
            -0.007291840389370918,
            -0.013759881258010864,
            0.02447660081088543,
            -0.030990220606327057,
            0.013252723030745983,
            0.00770546356216073,
            0.024761410430073738,
            0.0002830747689586133,
            -0.015118943527340889,
            -0.014431631192564964,
            0.04078975319862366,
            -0.01513892412185669,
            -0.028075769543647766,
            0.020070668309926987,
            0.042212434113025665,
            -0.010174829512834549,
            0.015319435857236385,
            0.007503744214773178,
            0.006694250740110874,
            -0.021284572780132294,
            -0.06892181187868118,
            -0.05847696214914322,
            -0.020491832867264748,
            -0.010845291428267956,
            9.771345503395423e-05,
            0.03131137043237686,
            -0.01971529796719551,
            -0.018152102828025818,
            -0.0047026630491018295,
            0.0037938847672194242,
            0.013096988201141357,
            0.019676733762025833,
            -0.024596521630883217,
            0.02329774759709835,
            0.022220535203814507,
            0.04885944351553917,
            0.011411024257540703,
            0.020033128559589386,
            0.0010535788023844361,
            -0.004156386945396662,
            -0.022078480571508408,
            0.03547123074531555,
            0.020389148965477943,
            -0.017794635146856308,
            0.01649438589811325,
            0.010234354995191097,
            -0.0013944946695119143,
            -0.014167609624564648,
            0.0025606960989534855,
            -0.02752619795501232,
            -0.03966722637414932,
            -0.0025557400658726692,
            -0.011780665256083012,
            -0.008897807449102402,
            0.02288472093641758,
            -0.017739905044436455,
            -0.05903146415948868,
            0.00044113636249676347,
            -0.006064955145120621,
            0.030530519783496857,
            -0.02561420574784279,
            0.0028013838455080986,
            -0.020376799628138542,
            -0.025125999003648758,
            0.023752480745315552,
            0.028600594028830528,
            -0.025078533217310905,
            0.015666723251342773,
            -0.02580239437520504,
            0.014723163098096848,
            -0.0015697323251515627,
            -0.014407644048333168,
            0.02053976245224476,
            0.023961417376995087,
            -0.02556300349533558,
            -0.029012147337198257,
            -0.012825846672058105,
            0.011477261781692505,
            -0.008542570285499096,
            -0.013629375025629997,
            0.008229563944041729,
            -0.028813237324357033,
            0.03889094665646553,
            -0.027146006003022194,
            0.012081755325198174,
            0.01707148551940918,
            -0.029514124616980553,
            -0.0194395799189806,
            0.01010759174823761,
            0.0005645740311592817,
            -0.048901014029979706,
            -0.02280314825475216,
            -0.03880114108324051,
            -0.01877027004957199,
            -0.025262853130698204,
            -0.009833160787820816,
            -0.02522219717502594,
            0.01885080151259899,
            -0.022230025380849838,
            -0.010566538199782372,
            -0.02705145813524723,
            -0.01127883605659008,
            0.02245606854557991,
            0.03247969225049019,
            -0.006780538707971573,
            -0.018549839034676552,
            -0.03575387969613075,
            -0.008428835310041904,
            -0.038248881697654724,
            0.03309667482972145,
            -0.031511686742305756,
            -0.0026444278191775084,
            -0.031027063727378845,
            -0.0045995647087693214,
            -0.021171722561120987,
            0.01570151001214981,
            -0.008323374204337597,
            0.011704808101058006,
            0.02638515643775463,
            0.026061197742819786,
            0.01823907345533371,
            -0.009817183017730713,
            0.041916538029909134,
            -0.022610509768128395,
            0.02927517704665661,
            -0.030516590923070908,
            0.007118906360119581,
            0.03213677182793617,
            -0.033431291580200195,
            0.003881177632138133,
            0.0012955974088981748,
            -0.026619942858815193,
            -0.03937745839357376,
            0.021900268271565437,
            -0.0043936907313764095,
            0.018879704177379608,
            -0.00507935369387269,
            0.006643660832196474,
            0.020659247413277626,
            -0.01869402825832367,
            0.01523569319397211,
            0.009779862128198147,
            0.0036921584978699684,
            -0.015981076285243034,
            0.03937498480081558,
            0.010231421329081059,
            0.00010277325054630637,
            -0.014754459261894226,
            0.003749009221792221,
            0.025831161066889763,
            0.015969468280673027,
            0.018882498145103455,
            -0.022966857999563217,
            0.012924606911838055,
            -0.014100455678999424,
            -0.0012732973555102944,
            -0.02063748985528946,
            -0.011108659207820892,
            -0.021792661398649216,
            -0.03004484809935093,
            -0.016003109514713287,
            -0.03788650780916214,
            0.020418604835867882,
            0.021496012806892395,
            -0.007914325222373009,
            0.01103833969682455,
            -0.02377796359360218,
            0.057160310447216034,
            0.009027215652167797,
            -0.02647104673087597,
            -0.0125193540006876,
            -0.0026823203079402447,
            0.003467325819656253,
            0.02655927836894989,
            -0.01847895048558712,
            -0.031063057482242584,
            -0.05896742269396782,
            0.014866311103105545,
            -0.027600858360528946,
            -0.024418530985713005,
            -0.037150658667087555,
            -0.008763677440583706,
            -0.017554117366671562,
            -0.007927047088742256,
            0.015498998574912548,
            -0.010094833560287952,
            -0.013919143006205559,
            0.014072176069021225,
            0.02210858091711998,
            0.0012960892636328936,
            0.0037198089994490147,
            -0.0020713394042104483,
            -0.047227609902620316,
            0.029993465170264244,
            -0.03902513533830643,
            -0.021034907549619675,
            0.041311465203762054,
            0.01158186886459589,
            -0.048518210649490356,
            -0.043083883821964264,
            0.002129064407199621,
            -0.02560947835445404,
            -0.01429578848183155,
            0.017981011420488358,
            -0.024968042969703674,
            0.013053875416517258,
            -0.008472785353660583,
            -0.02383093349635601,
            -0.008996485732495785,
            -0.023733040317893028,
            0.0040649911388754845,
            0.02059800736606121,
            0.014961798675358295,
            -0.0021124957129359245,
            -0.04873012378811836,
            -0.03842441737651825,
            0.001999574014917016,
            -0.0006584399379789829,
            0.015232606790959835,
            0.01621715910732746,
            0.013065321370959282,
            -0.009592478163540363,
            0.03946400061249733,
            0.013507685624063015,
            -0.00824798084795475,
            0.007787016220390797,
            -0.0233464352786541,
            0.019915593788027763,
            0.0040503572672605515,
            -0.052449386566877365,
            -0.010431257076561451,
            0.03618413582444191,
            0.037302326411008835,
            -0.024646367877721786,
            0.01896868273615837,
            0.022905776277184486,
            0.01711878553032875,
            -0.019820649176836014,
            0.04447907954454422,
            -0.0199285876005888,
            0.024926157668232918,
            0.05000373348593712,
            0.0038021330256015062,
            -0.015204481780529022,
            0.014541503973305225,
            -0.046540502458810806,
            -0.006342100910842419,
            0.016764456406235695,
            0.010325295850634575,
            0.016998104751110077,
            0.007217551581561565,
            -0.047046348452568054,
            -0.01641138084232807,
            0.002694980939850211,
            -0.0012334606144577265,
            0.06030278652906418,
            0.02490747906267643,
            0.006940748076885939,
            -0.018351789563894272,
            -0.009951790794730186,
            0.023260079324245453,
            0.014039172790944576,
            0.032570138573646545,
            0.023658286780118942,
            0.0027327488642185926,
            -0.00801694393157959,
            -0.025149734690785408,
            -0.027739427983760834,
            0.009596538729965687,
            0.02790609747171402,
            0.01732763648033142,
            0.020398911088705063,
            0.012848290614783764,
            -0.04032621532678604,
            0.023597652092576027,
            -0.00830820668488741,
            -0.0001236483658431098,
            -0.02289717271924019,
            0.012926938012242317,
            -0.07091209292411804,
            0.03111368604004383,
            0.0029650689102709293,
            0.0012762976111844182,
            0.01703033782541752,
            -0.040763430297374725,
            -0.02413378655910492,
            0.027999475598335266,
            0.0018935785628855228,
            0.00899499747902155,
            0.00761434156447649,
            -0.03329936042428017,
            0.045319728553295135,
            0.010630084201693535,
            0.06522082537412643,
            0.01609034650027752,
            -0.016817616298794746,
            0.039654672145843506,
            -0.009727531112730503,
            -0.053544480353593826,
            -0.034879036247730255,
            -0.0074533564038574696,
            0.041994404047727585,
            0.03545691445469856,
            -0.035608261823654175,
            -0.0005860846140421927,
            -0.022170769050717354,
            0.006023618392646313,
            0.01827487163245678,
            -0.008681736886501312,
            -0.0200401209294796,
            0.030078912153840065,
            -0.015946857631206512,
            -0.020416995510458946,
            -0.010448677465319633,
            0.015641944482922554,
            -0.009352277964353561,
            0.05793168768286705,
            -0.003092922270298004,
            0.029040096327662468,
            0.025891467928886414,
            0.03863608464598656,
            0.00620824983343482,
            0.016708726063370705,
            0.030982285737991333,
            -0.015784326940774918,
            -0.03591465204954147,
            0.050614189356565475,
            0.011224066838622093,
            0.01680847629904747,
            0.024864250794053078,
            -0.01931058242917061,
            0.013652807101607323,
            -0.013443090952932835,
            0.019770441576838493,
            0.08385208994150162,
            0.027719682082533836,
            -0.047312840819358826,
            -0.0023186616599559784,
            -0.029886575415730476,
            -0.0260227732360363,
            -0.036937642842531204,
            0.018024932593107224,
            -0.006023466121405363,
            0.004164149984717369,
            0.004077487159520388,
            -0.03627409040927887,
            0.024077357724308968,
            0.0070365481078624725,
            -0.0013151831226423383,
            0.0027265369426459074,
            -0.004789951257407665,
            -0.01764426752924919,
            0.02697809971868992,
            0.02274109423160553,
            -0.05188276618719101,
            -0.05982203409075737,
            0.02878328412771225,
            0.015085100196301937,
            0.014450001530349255,
            -0.02480144053697586,
            0.01916331611573696,
            -0.008376909419894218,
            0.0008128137560561299,
            -0.03310929611325264,
            0.024066627025604248,
            -0.01675352081656456,
            -0.011397250927984715,
            -0.018910622224211693,
            -0.029184211045503616,
            0.006095500662922859,
            -0.01092863455414772,
            0.0077760908752679825,
            0.011833260767161846,
            -0.007436908781528473,
            0.00494420388713479,
            0.028753766790032387,
            0.012305657379329205,
            0.0248476080596447,
            -0.03321131318807602,
            0.04656362161040306,
            -0.026858936995267868,
            -0.020434053614735603,
            0.006926399189978838,
            -0.009513978846371174,
            -0.017192352563142776,
            0.016754182055592537,
            -0.0077120098285377026,
            -0.011262830346822739,
            -0.004877773113548756,
            -0.02712867595255375,
            -0.03626582399010658,
            0.0036731723230332136,
            0.019135702401399612,
            -0.008622007444500923,
            -0.028205886483192444,
            0.002765574026852846,
            0.020436128601431847,
            -0.034451887011528015,
            -0.023424774408340454,
            0.01940000243484974,
            -0.010691039264202118,
            0.03377319127321243,
            0.024497780948877335,
            0.005477868486195803,
            0.016493206843733788,
            -0.024381311610341072,
            0.015190227888524532,
            0.04715854674577713,
            -0.030575953423976898,
            -0.017813941463828087,
            0.007255616132169962,
            -0.054881732910871506,
            0.00243140896782279,
            -0.0024851399939507246,
            0.021061070263385773,
            0.008224133402109146,
            -0.06625062972307205,
            0.008425668813288212,
            -0.0025695243384689093,
            0.02344396337866783,
            0.015021498315036297,
            -0.0012699984945356846,
            0.04848181828856468,
            0.0041107493452727795,
            0.01792917028069496,
            -0.02926071546971798,
            0.010823301039636135,
            -0.03454358130693436,
            0.022172795608639717,
            -0.015837348997592926,
            0.024609290063381195,
            -0.015101651661098003,
            0.0010653428034856915,
            0.03444593772292137,
            0.03470224887132645,
            0.01107573788613081,
            -0.06187291070818901,
            0.0001327080826740712,
            0.0013293959200382233,
            -0.010072534903883934,
            -0.008661376312375069,
            -0.0026258081197738647,
            0.010653738863766193,
            0.030055955052375793,
            -0.02176346257328987
        ],
        [
            0.03685518354177475,
            -0.011021801270544529,
            0.018998418003320694,
            0.002919047139585018,
            0.01605682075023651,
            0.012477857060730457,
            0.02578928880393505,
            0.011072449386119843,
            -0.007170291151851416,
            0.0016053782310336828,
            0.031431496143341064,
            -0.012744192034006119,
            0.02619842067360878,
            0.02024376578629017,
            0.026847295463085175,
            0.0003100249159615487,
            0.011817446909844875,
            -0.0011724913492798805,
            -0.02750508114695549,
            -0.028932351619005203,
            0.01575494557619095,
            -0.034129053354263306,
            -0.011421768926084042,
            0.00868039857596159,
            0.003037711139768362,
            -0.016765590757131577,
            0.04731805622577667,
            0.0032900243531912565,
            0.016179226338863373,
            0.04541343078017235,
            0.00593645079061389,
            0.009577680379152298,
            -0.011083914898335934,
            0.00824729260057211,
            -0.013497559353709221,
            0.021649887785315514,
            0.009716187603771687,
            0.012402411550283432,
            0.031297773122787476,
            -0.03164983168244362,
            -0.008959299884736538,
            -0.02136816270649433,
            0.010874700732529163,
            -0.008932228200137615,
            0.00891545694321394,
            0.032283883541822433,
            0.042611122131347656,
            0.02354903519153595,
            0.0001519428042229265,
            -0.03299769386649132,
            -0.012683984823524952,
            0.015812411904335022,
            0.04737726226449013,
            0.0197537112981081,
            -0.04756234586238861,
            -0.019054660573601723,
            0.01611110009253025,
            0.019213711842894554,
            0.017291011288762093,
            0.030732184648513794,
            0.010523619130253792,
            -0.02970808371901512,
            -0.008533780463039875,
            -0.03012426383793354,
            0.011760220862925053,
            -0.03315311670303345,
            0.0048238118179142475,
            0.019892819225788116,
            0.019588971510529518,
            0.004244845360517502,
            -0.010925830341875553,
            0.02145790122449398,
            0.0017062462866306305,
            0.0024807469453662634,
            0.06047261133790016,
            -0.03969912230968475,
            0.03611905127763748,
            -0.014604410156607628,
            0.04364606365561485,
            0.0056932722218334675,
            0.020804135128855705,
            -0.00561551284044981,
            0.013820689171552658,
            -0.011193771846592426,
            -0.043181464076042175,
            -0.009195328690111637,
            0.0066084167920053005,
            -0.013157101348042488,
            0.03854261338710785,
            0.05347764864563942,
            -0.023236624896526337,
            0.012453431263566017,
            0.010764559730887413,
            -0.036562398076057434,
            0.03119196742773056,
            -0.013651400804519653,
            0.02107664756476879,
            -0.016120439395308495,
            0.02461753413081169,
            0.027684759348630905,
            -0.036219242960214615,
            0.01405174657702446,
            -0.021141573786735535,
            -0.026836656033992767,
            0.01734086126089096,
            -0.015991611406207085,
            -0.015627583488821983,
            -0.0031627174466848373,
            -0.008861836977303028,
            0.013885644264519215,
            -0.014609402045607567,
            0.016512004658579826,
            0.022860968485474586,
            0.005322390701621771,
            -0.003847828134894371,
            0.00707218935713172,
            -0.01202958170324564,
            -0.01591050997376442,
            -0.0005380315706133842,
            0.005826306063681841,
            0.015470320358872414,
            -0.028000645339488983,
            0.0560748428106308,
            0.02385012060403824,
            0.00883500836789608,
            -0.023351255804300308,
            0.04281390830874443,
            -0.015844648703932762,
            0.01077424269169569,
            0.021198900416493416,
            0.030825696885585785,
            -0.013798884116113186,
            -0.006071054842323065,
            -0.024129990488290787,
            -0.005525540094822645,
            -0.012010079808533192,
            -0.02581215091049671,
            -0.017635060474276543,
            0.03027043677866459,
            0.02040969207882881,
            -0.023655317723751068,
            0.01709078624844551,
            -0.020534083247184753,
            -0.013834692537784576,
            0.004501834511756897,
            0.04403860121965408,
            0.025715405121445656,
            0.026357702910900116,
            -0.034313950687646866,
            -0.001916209701448679,
            -0.01755566895008087,
            0.0109246876090765,
            -0.012452296912670135,
            -0.01003065425902605,
            0.0037927920930087566,
            0.0015486069023609161,
            -0.05095374584197998,
            0.028051484376192093,
            0.017935775220394135,
            0.0069493926130235195,
            -0.031636614352464676,
            -0.013973331078886986,
            0.011988785117864609,
            0.009172883816063404,
            0.025803344324231148,
            0.014963469468057156,
            -0.003458641702309251,
            0.004621847067028284,
            0.05334842577576637,
            -0.02806011587381363,
            0.0240478515625,
            0.0054054781794548035,
            -0.027817338705062866,
            -0.01437979657202959,
            -0.025559285655617714,
            -0.0303297471255064,
            -0.007161994464695454,
            -0.047908417880535126,
            -0.00533297797665,
            -0.013018912635743618,
            -0.027241699397563934,
            -0.008560690097510815,
            0.005181419663131237,
            -0.025958776473999023,
            -0.0034414324909448624,
            -0.00443947734311223,
            -0.011869385838508606,
            -0.022316621616482735,
            0.03606061637401581,
            -0.009461515583097935,
            0.024755343794822693,
            0.038521092385053635,
            0.01245859730988741,
            0.015579460188746452,
            -0.019332239404320717,
            -0.006191867403686047,
            -0.003334895707666874,
            -0.01895318366587162,
            0.03295646607875824,
            0.0038947470020502806,
            -0.007881115190684795,
            0.035037484019994736,
            0.010510142892599106,
            0.0231035053730011,
            0.04521984979510307,
            -0.01964062638580799,
            -0.03696075826883316,
            -0.00536063639447093,
            0.03187285736203194,
            0.0029979227110743523,
            0.02302258089184761,
            -0.02223243936896324,
            0.023660151287913322,
            -0.005889314692467451,
            0.01226760447025299,
            -0.02331753447651863,
            -0.01760672964155674,
            -0.013391866348683834,
            0.03345181420445442,
            0.025021275505423546,
            0.008466034196317196,
            0.02214173786342144,
            0.02117282897233963,
            -0.003973837476223707,
            0.028754018247127533,
            0.03461284935474396,
            0.03627234697341919,
            0.016562363132834435,
            0.025166265666484833,
            0.009054725989699364,
            0.040625669062137604,
            -0.0065710158087313175,
            0.01946711353957653,
            0.028835657984018326,
            0.027818260714411736,
            0.006147181149572134,
            0.04096934199333191,
            0.025722069665789604,
            -0.03137464448809624,
            0.028528138995170593,
            0.00566471042111516,
            -0.003758346429094672,
            -0.0012735119089484215,
            -0.008483690209686756,
            -0.03131543844938278,
            0.0004869907279498875,
            -0.014957615174353123,
            -0.03687768056988716,
            -0.0007192939519882202,
            -0.008696897886693478,
            0.029581118375062943,
            -0.033432457596063614,
            0.007394254207611084,
            -0.017460275441408157,
            -0.005269339773803949,
            0.02887420915067196,
            -0.012292170897126198,
            -0.0320148766040802,
            -0.05431704968214035,
            -0.0014469162560999393,
            -0.009828844107687473,
            -0.0049568344838917255,
            -0.004177270457148552,
            -0.02458016201853752,
            0.041559040546417236,
            0.010171962901949883,
            -0.0031931090634316206,
            0.013013352639973164,
            0.02635420672595501,
            0.03256002441048622,
            0.024334905669093132,
            -0.01689029671251774,
            0.014666209928691387,
            -0.017669886350631714,
            0.0015285275876522064,
            -0.014937112107872963,
            -0.006523353513330221,
            -0.04378439486026764,
            -0.010439776815474033,
            -0.0076804920099675655,
            0.015280607156455517,
            0.006821156945079565,
            -0.0003563406353350729,
            -0.008757074363529682,
            0.026795433834195137,
            -0.0049623423255980015,
            0.008679496124386787,
            0.006447302643209696,
            0.011083018966019154,
            -0.0070608532987535,
            0.002763245953246951,
            0.013905277475714684,
            0.010613108985126019,
            0.01631031557917595,
            0.01758602075278759,
            0.02593768574297428,
            -0.032523851841688156,
            -0.025426091626286507,
            0.038759518414735794,
            -0.0404149666428566,
            0.028987551108002663,
            0.016393225640058517,
            0.004272736143320799,
            -0.011531999334692955,
            0.013178294524550438,
            0.006554058287292719,
            -0.011598614044487476,
            -0.00599196320399642,
            0.026659829542040825,
            -0.017783913761377335,
            0.013757610693573952,
            0.03302054852247238,
            -0.00177036935929209,
            0.00697200046852231,
            -0.017382917925715446,
            -0.01985088922083378,
            0.0019844495691359043,
            0.014568083919584751,
            0.0017931164475157857,
            -0.029525479301810265,
            0.013425934128463268,
            0.06167589873075485,
            0.01258645299822092,
            -0.027171622961759567,
            -0.02445785328745842,
            0.01848869025707245,
            0.011293542571365833,
            -0.019013339653611183,
            -0.04035148769617081,
            0.006973596289753914,
            -0.01957927644252777,
            -0.011368615552783012,
            0.005008016247302294,
            0.010348162613809109,
            0.04657360166311264,
            0.044101063162088394,
            0.010125771164894104,
            -0.009758501313626766,
            -0.00479067862033844,
            0.04020681604743004,
            -0.012851269915699959,
            0.031225940212607384,
            -0.003197613637894392,
            -0.020778026431798935,
            -0.018083959817886353,
            -0.014135279692709446,
            -0.012766409665346146,
            -0.029173843562602997,
            -0.013825264759361744,
            0.005872547160834074,
            0.012010365724563599,
            0.010988988913595676,
            0.008914424106478691,
            0.005874690134078264,
            0.0013086451217532158,
            -0.03716020658612251,
            0.016714265570044518,
            0.002617416437715292,
            0.02724330686032772,
            -0.005040495656430721,
            -0.004564270842820406,
            -0.006109611596912146,
            0.024393051862716675,
            -0.01364463847130537,
            0.006745049264281988,
            -0.009098786860704422,
            -0.02842509187757969,
            0.012845231220126152,
            0.021489597856998444,
            -0.030951736494898796,
            -0.018046343699097633,
            0.013609432615339756,
            -0.026506779715418816,
            0.00106852687895298,
            -0.00671091815456748,
            0.0016117000486701727,
            0.001007191720418632,
            -0.04446056857705116,
            0.0025843263138085604,
            0.031114736571907997,
            -0.005182815715670586,
            0.010642347857356071,
            0.01912875659763813,
            0.03139100596308708,
            -0.011314332485198975,
            -0.026686182245612144,
            0.003327112179249525,
            -7.77472960180603e-05,
            -0.03589954972267151,
            -0.023144561797380447,
            -0.007128922734409571,
            0.014588057063519955,
            -0.008062058128416538,
            -0.012733597308397293,
            -0.005313490983098745,
            -0.00977670680731535,
            -0.008405526168644428,
            0.017628716304898262,
            0.024377573281526566,
            -0.05165911465883255,
            0.03260410204529762,
            0.011038782075047493,
            0.015789346769452095,
            0.009217427112162113,
            -0.024261150509119034,
            -0.039049725979566574,
            -0.015574872493743896,
            0.01991094835102558,
            0.006675645709037781,
            0.011001777835190296,
            -0.0015006999019533396,
            0.015458882786333561,
            -0.023359548300504684,
            -0.0521053820848465,
            0.0037087665405124426,
            0.012962721288204193,
            0.007218481507152319,
            -0.02194538153707981,
            -0.008118818514049053,
            -0.011026439256966114,
            -0.07532103359699249,
            -0.04925791919231415,
            0.026057343930006027,
            0.0243694931268692,
            -0.006477909162640572,
            -0.027802305296063423,
            -0.04903213307261467,
            0.02947882190346718,
            -0.028935648500919342,
            0.017070574685931206,
            -0.011549815535545349,
            0.024447061121463776,
            0.020761797204613686,
            -0.022957658395171165,
            0.02597753144800663,
            0.02663099765777588,
            -0.0377526730298996,
            -0.010043338872492313,
            0.03543448448181152,
            0.015865741297602654,
            0.04771105945110321,
            0.02820904180407524,
            0.03642994537949562,
            -0.0012240955838933587,
            -0.009483283385634422,
            -0.01861093007028103,
            0.008143248967826366,
            0.05394566059112549,
            0.007942955940961838,
            -0.002608943497762084,
            0.015323442406952381,
            -0.01700996793806553,
            0.019000299274921417,
            -0.03313180431723595,
            -0.00023963901912793517,
            -0.02955116704106331,
            0.0064520756714046,
            -0.0016221546102315187,
            0.03355003893375397,
            -0.010432335548102856,
            0.016366340219974518,
            0.017957907170057297,
            0.034540221095085144,
            0.028149999678134918,
            0.02371731400489807,
            0.0007152200560085475,
            0.004772212356328964,
            -0.03339771926403046,
            0.026077741757035255,
            0.011660871095955372,
            0.010257435031235218,
            -0.009402785450220108,
            -0.030299486592411995,
            -0.016685057431459427,
            -0.030621128156781197,
            -0.02635572850704193,
            0.016385981813073158,
            -0.023452719673514366,
            -0.010579775087535381,
            0.02257397770881653,
            0.00323349516838789,
            0.0027132234536111355,
            -0.009299015626311302,
            0.02630363404750824,
            0.01886754296720028,
            0.009976653382182121,
            0.006383493542671204,
            0.0010522007942199707,
            0.056616269052028656,
            -0.013244174420833588,
            0.044151581823825836,
            0.034909170120954514,
            0.026222432032227516,
            -0.06872331351041794,
            -0.0018750651506707072,
            0.016842233017086983,
            0.01751377433538437,
            -0.00040660472586750984,
            -0.027218248695135117,
            -0.003048959653824568,
            -0.005864603444933891,
            0.019117901101708412,
            -0.04854508861899376,
            0.038409166038036346,
            -0.01281370222568512,
            0.013400442898273468,
            0.014657113701105118,
            -0.011251256801187992,
            0.023033561185002327,
            0.0009275347692891955,
            0.008389136753976345,
            -0.0023303364869207144,
            -0.028863362967967987,
            0.038211192935705185,
            0.013665190897881985,
            -0.0028146952390670776,
            -0.006638839840888977,
            -0.020313160493969917,
            -0.01540228072553873,
            0.009916260838508606,
            0.011327769607305527,
            -0.024676131084561348,
            0.03132360801100731,
            -0.030755354091525078,
            -0.019524136558175087,
            0.007918099872767925,
            0.02094554528594017,
            -0.000486187229398638,
            -0.008817488327622414,
            -0.021940212696790695,
            -0.019305536523461342,
            -0.0367450974881649,
            0.03935638815164566,
            0.035993050783872604,
            -0.025671543553471565,
            0.015598217956721783,
            -0.0028143322560936213,
            0.020213250070810318,
            -0.008709060028195381,
            0.03125161677598953,
            0.00774497352540493,
            0.023043904453516006,
            -0.013881631195545197,
            0.010646524839103222,
            0.033845607191324234,
            -0.027418091893196106,
            -0.02400597743690014,
            0.0014987516915425658,
            0.008132144808769226,
            0.007211384829133749,
            0.02496092952787876,
            0.023272724822163582,
            0.019890248775482178,
            0.013904397375881672,
            -0.006706072948873043,
            -0.04199646785855293,
            -0.006503041367977858,
            0.008620397187769413,
            0.01600353978574276,
            -0.0275312140583992,
            -0.0009911024244502187,
            -0.01506362296640873,
            -0.017173508182168007,
            -0.0038513399194926023,
            -0.04432804137468338,
            0.016244731843471527,
            0.026435600593686104,
            -0.010981800965964794,
            -0.02659359946846962,
            0.009604759514331818,
            -0.006973627954721451,
            0.024480624124407768,
            -0.007392464205622673,
            -0.03044111654162407,
            -0.018304748460650444,
            -0.06680354475975037,
            0.011827053502202034,
            -0.017112726345658302,
            0.00954107753932476,
            -0.002139229094609618,
            -0.02379298396408558,
            -0.018060902133584023,
            0.01158076897263527,
            -0.030948277562856674,
            -0.03927968442440033,
            0.03281567618250847,
            -0.013245058245956898,
            -0.021329395473003387,
            0.02022286131978035,
            -0.023515574634075165,
            0.014775956980884075,
            0.024107446894049644,
            -0.02224224992096424,
            0.00790592934936285,
            -0.03432432562112808,
            -0.010173522867262363,
            -0.02089584805071354,
            -0.004743667785078287,
            -0.004633521661162376,
            -0.023221906274557114,
            0.05555719882249832,
            0.025762327015399933,
            0.0033751458395272493,
            0.02306859754025936,
            -0.013017315417528152,
            0.014378405176103115,
            -0.015915755182504654,
            0.003400356275960803,
            -0.023888004943728447,
            0.015160473994910717,
            -0.011786958202719688,
            -0.002265108749270439,
            -0.020136738196015358,
            -0.0010668672621250153,
            0.035151708871126175,
            -0.013867966830730438,
            0.024854084476828575,
            0.02144785225391388,
            0.015896061435341835,
            -0.026715068146586418,
            0.0151356291025877,
            0.013486342504620552,
            0.02172984555363655,
            0.02621093951165676,
            0.004498383495956659,
            0.022827988490462303,
            0.004225808661431074,
            -0.020680973306298256,
            0.025085508823394775,
            0.03012235090136528,
            -0.028592823073267937,
            0.031609077006578445,
            -0.0020192398224025965,
            0.026982232928276062,
            0.0043042306788265705,
            -0.0011308857938274741,
            -0.011605588719248772,
            -0.04154767468571663,
            -0.02515142783522606,
            0.020938249304890633,
            0.025246769189834595,
            -0.013488631695508957,
            0.01161869429051876,
            0.011365308426320553,
            0.012271767482161522,
            0.01760302297770977,
            0.04209045320749283,
            0.022277068346738815,
            0.02635292150080204,
            0.0077485027723014355,
            0.016574960201978683,
            0.0014423916582018137,
            0.0007855429430492222,
            -0.002195380860939622,
            0.021669328212738037,
            -0.021351832896471024,
            -0.009071191772818565,
            0.01077583059668541,
            0.027784207835793495,
            0.01406132336705923,
            0.037842921912670135,
            -0.009962533600628376,
            0.018630972132086754,
            -0.006939747836440802,
            -0.02084444835782051,
            0.010412932373583317,
            -0.02896539866924286,
            -0.005924049764871597,
            -0.029912395402789116,
            -0.014585659839212894,
            -0.003992574755102396,
            -0.03481707349419594,
            0.007741996087133884,
            0.028793904930353165,
            0.006807621568441391,
            0.032424986362457275,
            -0.012960611842572689,
            0.0003361481358297169,
            -0.013658225536346436,
            0.025955133140087128,
            0.011881178244948387,
            0.016624944284558296,
            -0.0009907727362588048,
            0.028651056811213493,
            -0.02840939722955227,
            -0.0026151903439313173,
            -0.04105592891573906,
            -0.010849613696336746,
            0.015072593465447426,
            0.013123082928359509,
            -0.024380860850214958,
            0.0010130222653970122,
            -0.000346815912052989,
            0.014758320525288582,
            -0.003530788468196988,
            0.020552344620227814,
            -0.015718957409262657,
            -0.01734877936542034,
            -0.004567960277199745,
            0.006569617427885532,
            -0.006943285930901766,
            0.008988853543996811,
            0.00370598491281271,
            0.02741694077849388,
            0.03194236382842064,
            0.009036460891366005,
            -0.008976423181593418,
            0.019142787903547287,
            0.011784922331571579,
            0.05290653556585312,
            -0.0002690272231120616,
            0.0013362839818000793,
            0.004071823321282864,
            -0.012840119190514088,
            -0.02108035609126091,
            0.034554675221443176,
            0.04150477796792984,
            -0.01083527971059084,
            0.007774933706969023,
            -0.029347505420446396,
            0.000875534606166184,
            0.02344346046447754,
            -0.016135198995471,
            0.02377883903682232,
            0.004573015961796045,
            0.018138203769922256,
            0.024986226111650467,
            -0.01690889708697796,
            0.0015772618353366852,
            0.015163642354309559,
            -6.184633821249008e-05,
            0.003796324133872986,
            0.013010320253670216,
            0.007732642348855734,
            0.018107945099473,
            0.022037919610738754,
            -0.006536276079714298,
            0.01962367072701454,
            0.028200170025229454,
            0.01616375334560871,
            -0.009905149228870869,
            -0.0012039437424391508,
            0.005006907042115927,
            -0.023209672421216965,
            -0.025747312232851982,
            0.023120077326893806,
            -0.07372705638408661,
            -0.027183808386325836,
            0.01661522127687931,
            -0.0032381985802203417,
            0.021463971585035324,
            -0.02052270621061325,
            -0.024156201630830765,
            0.025673680007457733,
            0.004285371396690607,
            -0.038976844400167465,
            -0.018844733014702797,
            -0.024409545585513115,
            0.04404231533408165,
            3.7448220524538556e-08,
            0.026067214086651802,
            0.037874188274145126,
            0.010708277113735676,
            -0.015277998521924019,
            0.039933089166879654,
            -0.029572241008281708,
            -0.004184963647276163,
            -0.013550582341849804,
            0.024580469354987144,
            0.017089320346713066,
            0.03042207844555378,
            0.024203242734074593,
            -0.011800572276115417,
            -0.022961542010307312,
            0.0278598815202713,
            -0.019796093925833702,
            0.050833556801080704,
            0.005754833109676838,
            -0.017131473869085312,
            0.015664497390389442,
            0.00602230429649353,
            0.0100474301725626,
            0.011510695330798626,
            0.030918726697564125,
            0.021136751398444176,
            0.006774276494979858,
            -0.00013913470320403576,
            0.003066508797928691,
            0.042103588581085205,
            -0.03909814730286598,
            -0.0148630291223526,
            -0.03897359222173691,
            -0.020722107961773872,
            0.031326774507761,
            -0.004376910626888275,
            0.021382300183176994,
            0.00550265796482563,
            0.033227112144231796,
            -0.007020460441708565,
            3.330497202114202e-05,
            -0.005673267412930727,
            -0.014599421061575413,
            0.03033139370381832,
            0.021372579038143158,
            0.03831983730196953,
            -0.0010611794423311949,
            -0.01682988367974758,
            -0.011790304444730282,
            -0.005420185159891844,
            -0.018636105582118034,
            0.011869614012539387,
            0.04205197095870972,
            -0.01767660863697529,
            -0.001855085021816194,
            0.01051327120512724,
            0.020942075178027153,
            -0.012544088996946812,
            0.023964431136846542,
            -0.014930302277207375,
            0.008807679638266563,
            -0.01466550026088953,
            -0.03462252765893936,
            0.00801625195890665,
            -0.008405682630836964,
            0.05178232491016388,
            0.02852623537182808,
            -0.00725336279720068,
            -0.022860294207930565,
            0.004513872787356377,
            -0.006128096953034401,
            -0.06826155632734299,
            0.03276829048991203,
            -0.033355601131916046,
            0.025932278484106064,
            -0.03759313374757767,
            0.010446615517139435,
            0.027875512838363647,
            0.03318437933921814,
            -0.014434035867452621,
            0.012106167152523994,
            0.02214273437857628,
            0.00659640459343791,
            -0.013538066297769547,
            -0.0032450235448777676,
            -0.007837687619030476,
            -0.027315214276313782,
            -0.0016871033003553748,
            0.00289232493378222,
            0.0015615804586559534,
            -0.016409479081630707,
            0.0206960067152977,
            -0.011623232625424862,
            -0.0405813567340374,
            -0.01040604617446661,
            0.011884992010891438,
            0.004215709865093231,
            0.0028755655512213707,
            0.011556243523955345,
            -0.03459096699953079,
            0.024599645286798477,
            -0.02051958627998829,
            -0.010703052394092083,
            -0.003843525657430291,
            0.0570584274828434,
            -0.01940382458269596,
            -0.005587324500083923,
            -0.012302514165639877,
            0.001667891163378954,
            -0.01391330361366272,
            0.0021376784425228834,
            0.00974129419773817,
            0.012462686747312546,
            0.020546244457364082,
            -0.005131044890731573,
            -0.032232124358415604,
            0.00897764042019844,
            -0.0021076886914670467,
            0.07464528828859329,
            -0.0119498735293746,
            0.014151321724057198,
            0.02915409579873085,
            0.02701997011899948,
            0.03441690653562546,
            -0.014567428268492222,
            0.008824408985674381,
            -0.006503204815089703,
            -0.0064010308124125,
            0.03573981672525406,
            -0.04120166599750519,
            -0.010796071961522102,
            -0.017911942675709724,
            -0.01157285925000906,
            -0.0209636427462101,
            -0.026899512857198715,
            0.025919580832123756,
            -0.0500173419713974,
            -0.003438407089561224,
            0.0041712247766554356,
            -0.00573635334149003,
            0.009463675320148468,
            0.01747135818004608,
            0.0353437177836895,
            -0.03825118765234947,
            0.04077817499637604,
            0.009428795427083969,
            -0.02570459246635437,
            -0.0028405613265931606,
            0.017700299620628357,
            -0.0065446351654827595,
            -0.026264941319823265,
            0.01672358065843582,
            0.011188704520463943,
            -0.020151734352111816,
            -0.020594384521245956,
            -0.016047073528170586,
            -0.023467902094125748,
            -0.026833469048142433,
            0.01726776733994484,
            -0.021084001287817955,
            -0.024807872250676155,
            -0.026543285697698593,
            0.031104937195777893,
            -0.0376838743686676,
            0.0007063593366183341,
            0.016710372641682625,
            -0.026927335187792778,
            0.004170241765677929,
            0.0215572789311409,
            0.00679403031244874,
            0.0225966963917017,
            -0.01851011998951435,
            0.012749233283102512,
            0.008824926801025867,
            0.027604373171925545,
            -0.015411649830639362,
            0.014954992569983006,
            0.02698228508234024,
            0.015150194987654686,
            -0.023230213671922684,
            -0.013358897529542446,
            0.008861674927175045,
            0.03654403239488602,
            0.03530142083764076,
            -0.00779683655127883,
            0.04881754145026207,
            0.021909384056925774,
            0.01897132582962513,
            -0.012732305563986301,
            0.011016715317964554,
            0.03303059563040733,
            -0.014168549329042435,
            -0.027971046045422554,
            -0.017092455178499222,
            -0.009869398549199104,
            0.040583107620477676,
            0.02197944186627865,
            -0.007415990810841322,
            -0.013585098087787628,
            -0.01934920996427536,
            0.025131195783615112,
            -0.014355160295963287,
            -0.014774464070796967,
            0.011997249908745289,
            -0.019619779661297798,
            0.03781268373131752,
            0.026542536914348602,
            0.005384672433137894,
            -0.019975246861577034,
            0.012420195154845715,
            0.0038611881900578737,
            -0.0043778871186077595,
            0.010036036372184753,
            0.013482678681612015,
            0.009953861124813557,
            -0.005612515844404697,
            0.01868993043899536,
            -0.01992204040288925,
            0.0076828449964523315,
            -0.017669526860117912,
            -0.02169223129749298,
            -0.02234344184398651,
            0.008369745686650276,
            0.018682992085814476,
            0.015100589953362942,
            -0.027968017384409904,
            0.00755640072748065,
            0.02496381290256977,
            0.003700290573760867,
            -0.023741072043776512,
            0.005316109862178564,
            -0.01783919893205166,
            -0.007647203281521797,
            0.008768422529101372,
            0.015964441001415253,
            0.007501251995563507,
            0.01628141850233078,
            -0.006495780311524868,
            0.029614528641104698,
            -0.006019229535013437,
            0.010586148127913475,
            0.010408667847514153,
            -0.026133740320801735,
            -0.012480359524488449,
            -0.0037631033919751644,
            -0.03023426979780197,
            -0.028332415968179703,
            0.011487435549497604,
            -0.004530495498329401,
            0.018682369962334633,
            0.02424723096191883,
            -0.021339131519198418,
            0.028363952413201332,
            -0.013549113646149635,
            -0.0030510996002703905,
            0.012349819764494896,
            0.02648722194135189,
            -0.020706646144390106,
            0.0040178559720516205,
            0.014176920056343079,
            0.00918320007622242,
            0.0121838990598917,
            -0.04810257628560066,
            -0.0005717631429433823,
            -0.015295335091650486
        ],
        [
            -0.011274363845586777,
            0.00592856016010046,
            -0.019864199683070183,
            0.022235114127397537,
            0.02428627386689186,
            0.023611851036548615,
            -0.005714595317840576,
            0.020560288801789284,
            -0.036648962646722794,
            0.02003871463239193,
            0.0131410276517272,
            0.014712509699165821,
            -0.007624293677508831,
            -0.01702348329126835,
            0.02524435892701149,
            0.03991008177399635,
            0.005510325543582439,
            0.026788577437400818,
            0.03118259087204933,
            -0.0004411327245179564,
            0.0035609009210020304,
            -0.01370079442858696,
            0.02345416694879532,
            -0.008471241220831871,
            0.03466474637389183,
            -0.0049305446445941925,
            0.023900248110294342,
            0.04674182087182999,
            -0.02795766294002533,
            -0.0056442138738930225,
            0.011649463325738907,
            -0.023912135511636734,
            -0.02414078637957573,
            0.013125776313245296,
            -0.010512095876038074,
            0.04283454269170761,
            0.004021038766950369,
            -0.01676524616777897,
            0.02318532019853592,
            -0.02448129653930664,
            0.022220371291041374,
            0.01117242407053709,
            0.015691226348280907,
            -0.0036659238394349813,
            0.016582535579800606,
            -0.01587713696062565,
            -0.04663285240530968,
            -0.03609282523393631,
            -0.033889222890138626,
            -0.032104551792144775,
            -0.043657150119543076,
            0.037770211696624756,
            0.00201027630828321,
            -0.015053806826472282,
            0.0025679946411401033,
            0.033885303884744644,
            -0.02636875957250595,
            0.019337834790349007,
            -0.029622036963701248,
            -0.006083947606384754,
            -0.037209320813417435,
            -0.023192035034298897,
            -0.005936828441917896,
            0.030231794342398643,
            -0.003932946361601353,
            -0.00605448754504323,
            -0.002378250239416957,
            -0.019306575879454613,
            0.012184512801468372,
            0.02669534832239151,
            0.008848501369357109,
            -0.006201478652656078,
            0.039573878049850464,
            -0.007344524376094341,
            -0.004605341237038374,
            0.042497050017118454,
            0.026869334280490875,
            0.0043483576737344265,
            -0.0009295008494518697,
            0.013039910234510899,
            0.03428441286087036,
            -0.03326897323131561,
            0.023103034123778343,
            0.022387685254216194,
            -0.010978597216308117,
            -0.01765848882496357,
            0.028192732483148575,
            -0.010315285995602608,
            -0.004254363477230072,
            -0.011564559303224087,
            0.018158718943595886,
            -0.0004956327029503882,
            -0.024563277140259743,
            0.048289693892002106,
            0.002783229574561119,
            0.005898361559957266,
            0.02311238832771778,
            -0.014508708380162716,
            -0.02278827875852585,
            0.02226717211306095,
            0.04702802747488022,
            -0.010089642368257046,
            -0.006309557240456343,
            0.01295349933207035,
            -0.005846399813890457,
            -0.0163924191147089,
            0.035358134657144547,
            -0.025549886748194695,
            -0.0176031906157732,
            -0.029550790786743164,
            -0.0001626352168386802,
            -0.02428472600877285,
            -0.0028634844347834587,
            0.02927732840180397,
            -0.06214390695095062,
            -0.015946483239531517,
            0.002387364860624075,
            0.02558225393295288,
            -0.0099744638428092,
            0.03947431221604347,
            -0.01886284351348877,
            -0.021657127887010574,
            0.029892219230532646,
            0.025563469156622887,
            -0.007913690991699696,
            -0.017033064737915993,
            0.006238494534045458,
            -0.01066287700086832,
            -0.0008524804143235087,
            -0.006507532671093941,
            0.019198382273316383,
            0.0010032771388068795,
            0.026321768760681152,
            0.03850496560335159,
            0.013919401913881302,
            -0.0054199364967644215,
            0.03722522407770157,
            -0.008022909983992577,
            0.0289742611348629,
            0.025243259966373444,
            -0.027211537584662437,
            -0.014982444234192371,
            0.027712520211935043,
            -0.00129334453959018,
            0.018130218610167503,
            -0.05010932311415672,
            0.017995374277234077,
            0.0039425441063940525,
            0.007154436782002449,
            0.019981468096375465,
            0.013866069726645947,
            -0.03153308108448982,
            0.009073350578546524,
            -0.011961477808654308,
            0.023511385545134544,
            -0.019937558099627495,
            0.029185917228460312,
            -0.0074731227941811085,
            0.03640715032815933,
            -0.03433295711874962,
            -0.021340031176805496,
            -0.0034059053286910057,
            0.02800535410642624,
            -0.027084028348326683,
            -0.020268278196454048,
            0.0019596104975789785,
            -0.06668117642402649,
            -0.012333989143371582,
            0.022085225209593773,
            -0.0050300536677241325,
            -0.005306372418999672,
            -0.00025911160628311336,
            0.006655646953731775,
            0.003654083237051964,
            -0.029603557661175728,
            -0.02756207250058651,
            -0.03926747664809227,
            -0.044178493320941925,
            0.01630479283630848,
            0.0032117189839482307,
            -0.008106313645839691,
            -0.0005801154184155166,
            0.006581113673746586,
            -0.010577877052128315,
            -0.01695985347032547,
            -0.0015580028994008899,
            0.02395082451403141,
            -0.019590403884649277,
            -0.021665170788764954,
            -0.014365186914801598,
            0.02595566026866436,
            0.010928872972726822,
            0.002862113993614912,
            0.03178178146481514,
            0.01385175809264183,
            -0.022228090092539787,
            -0.003886523889377713,
            -0.0021934874821454287,
            0.03455222398042679,
            0.009689060971140862,
            -0.018343718722462654,
            0.01315271481871605,
            -0.04398336261510849,
            0.018495753407478333,
            0.031661149114370346,
            -0.028942784294486046,
            0.05390297621488571,
            0.001427153474651277,
            0.012721727602183819,
            0.0016492769354954362,
            -0.028689362108707428,
            -0.009478341788053513,
            0.01406873669475317,
            -0.020455816760659218,
            -0.0008014378254301846,
            -0.011777096427977085,
            -0.014884589239954948,
            -0.023010987788438797,
            0.0012046212796121836,
            0.007584580685943365,
            -0.01728053204715252,
            0.023594150319695473,
            -0.04986413195729256,
            -0.010597518645226955,
            -0.029547488316893578,
            -0.015693731606006622,
            -0.0026273054536432028,
            -0.00988973118364811,
            0.0137779600918293,
            -0.017857670783996582,
            -0.005856616422533989,
            -0.0048485444858670235,
            0.03822477161884308,
            -0.006898131221532822,
            -0.02338528260588646,
            -0.0022044414654374123,
            -0.01748211495578289,
            0.012828621082007885,
            0.013871987350285053,
            -0.009355337359011173,
            0.023673472926020622,
            3.995801671408117e-05,
            -0.03350360319018364,
            0.02714824117720127,
            0.04586152359843254,
            -0.030478710308670998,
            -0.01996869221329689,
            -0.00823366641998291,
            -0.0063285306096076965,
            0.01918789930641651,
            0.005271244328469038,
            -0.021741021424531937,
            0.049042798578739166,
            -0.00510932132601738,
            -0.02668622136116028,
            -0.02791295200586319,
            -0.0175012294203043,
            0.036714136600494385,
            -0.0068161808885633945,
            0.0014526931336149573,
            -0.016797320917248726,
            -0.004250166472047567,
            0.005573763977736235,
            -0.02855544351041317,
            0.01568438671529293,
            -0.09151475131511688,
            -0.02294873259961605,
            -0.028823303058743477,
            0.0378035269677639,
            -0.012582449242472649,
            0.012754004448652267,
            -0.031313154846429825,
            -0.022011442109942436,
            -0.00194774370174855,
            -0.0010543424868956208,
            0.013706743717193604,
            -0.011632469482719898,
            0.023698942735791206,
            0.0027047472540289164,
            0.010344772599637508,
            0.02140771597623825,
            -0.006413720548152924,
            -0.024146227166056633,
            -0.016228336840867996,
            0.00900404341518879,
            0.002214510692283511,
            0.007278418634086847,
            0.0007952451123856008,
            -0.03340412676334381,
            -0.004246400203555822,
            0.02200859971344471,
            -0.018285203725099564,
            0.0007104598917067051,
            0.0005675400025211275,
            -0.01824348419904709,
            -0.004333226941525936,
            0.01922476477921009,
            -0.002781545277684927,
            -0.007099662907421589,
            -0.01939246617257595,
            -0.010576811619102955,
            0.033494796603918076,
            -0.038968656212091446,
            0.0005396048654802144,
            -0.008465518243610859,
            0.03825158625841141,
            0.0019411706598475575,
            -0.04915133863687515,
            0.023540230467915535,
            0.043758563697338104,
            -0.005883370526134968,
            -0.012481417506933212,
            -0.029071606695652008,
            -0.017854783684015274,
            -0.005587464198470116,
            -0.007312263827770948,
            -0.002086712745949626,
            0.020418651401996613,
            0.020834248512983322,
            -0.011678560636937618,
            0.003937607631087303,
            0.02280505932867527,
            -0.045746687799692154,
            0.036336030811071396,
            0.005342724733054638,
            0.030564941465854645,
            0.024719679728150368,
            0.006114783231168985,
            0.006978108547627926,
            0.009581029415130615,
            -0.0012827729806303978,
            1.756614619807806e-05,
            -0.025588994845747948,
            -0.01938483491539955,
            -0.012894301675260067,
            -0.0044511547312140465,
            0.023000966757535934,
            0.003693555947393179,
            0.0033266753889620304,
            -0.019841330125927925,
            -0.030932271853089333,
            -0.0083581218495965,
            -0.012296243570744991,
            0.013076785951852798,
            0.003396824700757861,
            0.06570137292146683,
            0.0019365543266758323,
            -0.028101768344640732,
            0.004219097550958395,
            0.011512614786624908,
            -0.026218844577670097,
            0.006686781998723745,
            0.03373158723115921,
            0.015044772066175938,
            -0.020429881289601326,
            -0.013258165679872036,
            -0.018784603103995323,
            0.04962209612131119,
            0.00623873807489872,
            0.023343592882156372,
            0.0158795528113842,
            -0.020926157012581825,
            -0.0192977637052536,
            0.0029804317746311426,
            0.02816791646182537,
            0.0015905381878837943,
            0.007521113846451044,
            -0.03705746307969093,
            0.031122475862503052,
            -0.004220763221383095,
            0.019901789724826813,
            -0.014992928132414818,
            0.027979977428913116,
            0.026589609682559967,
            -0.002655093790963292,
            -0.016173632815480232,
            -0.0074651469476521015,
            0.029942525550723076,
            -0.019047044217586517,
            0.02343897335231304,
            0.038013603538274765,
            0.01691036857664585,
            -0.02100994996726513,
            0.02503342367708683,
            -0.022478610277175903,
            0.053868647664785385,
            -0.026263082399964333,
            0.030348986387252808,
            -0.02459076978266239,
            -0.0011094451183453202,
            0.028670676052570343,
            0.02496683970093727,
            0.005955091677606106,
            0.007445965427905321,
            -0.06506845355033875,
            -0.0204560998827219,
            0.011019175872206688,
            -0.03624635562300682,
            0.024731704965233803,
            -0.005317019764333963,
            -0.06222696974873543,
            0.003949043340981007,
            0.01540983747690916,
            0.025600384920835495,
            0.038871992379426956,
            -0.0360247865319252,
            -0.03190247714519501,
            0.011742968112230301,
            0.009389108046889305,
            0.016000742092728615,
            -0.003976679872721434,
            0.022469952702522278,
            0.015122081153094769,
            0.03297605738043785,
            0.014030068181455135,
            0.03343266248703003,
            0.010758637450635433,
            0.04317237064242363,
            0.021688738837838173,
            0.018868228420615196,
            0.017335539683699608,
            -0.015706928446888924,
            -0.004671555012464523,
            -0.005528031848371029,
            -0.02653837390244007,
            0.030852805823087692,
            0.03960641473531723,
            0.0014755508163943887,
            -0.010258992202579975,
            -0.019270196557044983,
            0.02802555076777935,
            -0.006395311560481787,
            0.02343183010816574,
            0.04233941063284874,
            -0.0025911496486514807,
            0.02668091654777527,
            0.024713683873414993,
            0.013627876527607441,
            -0.040100984275341034,
            -0.02151101641356945,
            -0.010739199817180634,
            0.0065306308679282665,
            -6.18024860159494e-05,
            -0.031140385195612907,
            -0.002063664374873042,
            -0.012579711154103279,
            -0.008531572297215462,
            -0.0032465183176100254,
            -0.0026108860038220882,
            0.0294069554656744,
            -0.006287577096372843,
            0.028388498350977898,
            -0.005634638015180826,
            -0.024874519556760788,
            -0.050457388162612915,
            0.03428123518824577,
            -0.0015149054815992713,
            0.022808052599430084,
            0.018011417239904404,
            0.014379111118614674,
            7.766026828903705e-05,
            -0.019889770075678825,
            -0.036019112914800644,
            0.016424525529146194,
            -0.008321483619511127,
            -0.026893218979239464,
            0.008033252321183681,
            -0.036275189369916916,
            0.03128739073872566,
            -0.0350702628493309,
            -0.005283039528876543,
            -0.008741306141018867,
            0.020408211275935173,
            0.00014641739835496992,
            0.03681030124425888,
            0.012476286850869656,
            -0.009163089096546173,
            -0.007793231401592493,
            -0.01351888757199049,
            0.027438325807452202,
            0.042915813624858856,
            0.021259978413581848,
            -0.031411800533533096,
            -0.015322349965572357,
            -0.025898590683937073,
            -0.016950907185673714,
            -0.024286499246954918,
            -0.02166638895869255,
            -0.054552387446165085,
            0.025372864678502083,
            -0.041162729263305664,
            -0.026806697249412537,
            -0.006361398380249739,
            0.006798475980758667,
            0.02721787802875042,
            -0.0036451630294322968,
            0.02157006971538067,
            0.0030246709939092398,
            -0.02392740361392498,
            0.013032669201493263,
            -0.007407207041978836,
            -0.0034682380501180887,
            0.00404434883967042,
            -0.02356712892651558,
            0.020014239475131035,
            0.0140029676258564,
            -0.028865037485957146,
            -0.027103707194328308,
            -0.0014495752984657884,
            0.02029590681195259,
            -0.007554028183221817,
            0.03530149534344673,
            -0.0067640645429492,
            -0.014536521397531033,
            -0.021889634430408478,
            0.02168997749686241,
            -0.003516363212838769,
            -0.0324343666434288,
            -0.02331012859940529,
            0.0045572021044790745,
            -0.014978202991187572,
            -0.03363325446844101,
            -0.02288978174328804,
            -0.01749889738857746,
            -0.008906524628400803,
            -0.031547464430332184,
            0.04855068773031235,
            -0.003112837905064225,
            -0.019352566450834274,
            0.02985484153032303,
            0.008085941895842552,
            0.010951599106192589,
            0.0067762332037091255,
            0.014280482195317745,
            -0.010093276388943195,
            -0.013927901163697243,
            -0.02352963201701641,
            0.03436362370848656,
            -0.013939904980361462,
            0.019488753750920296,
            -0.019498974084854126,
            -0.013057544827461243,
            0.03695722669363022,
            -0.02649824507534504,
            0.013441630639135838,
            0.045333780348300934,
            -0.004998194519430399,
            0.03285718336701393,
            0.007863095961511135,
            -0.015493908897042274,
            0.03028179332613945,
            -0.007005130872130394,
            0.029847826808691025,
            -0.024940110743045807,
            0.021994920447468758,
            0.003649599850177765,
            -0.01905028149485588,
            0.014051957055926323,
            0.0038910333532840014,
            0.004110048525035381,
            0.024714160710573196,
            0.003475927747786045,
            -0.015167400240898132,
            0.06649292260408401,
            -0.008974446915090084,
            -0.025219187140464783,
            -0.022877918556332588,
            -0.025311633944511414,
            0.02060706913471222,
            0.04674121364951134,
            -0.03058003820478916,
            -0.04321647435426712,
            0.03153816983103752,
            -0.024963760748505592,
            0.024854235351085663,
            0.018286798149347305,
            0.03254462033510208,
            0.031928785145282745,
            -0.02584213763475418,
            -0.016492458060383797,
            0.01188085786998272,
            -0.005843817256391048,
            -0.015182430855929852,
            0.004455982707440853,
            0.012998376972973347,
            -0.033304326236248016,
            0.02734333463013172,
            -0.00791247095912695,
            0.009851311333477497,
            0.04142410308122635,
            0.03280762583017349,
            0.01236784365028143,
            -0.023699309676885605,
            -0.003174698445945978,
            0.00915515050292015,
            -0.008716120384633541,
            0.006912444718182087,
            -0.006438001524657011,
            0.002788362093269825,
            0.0267617329955101,
            0.015982918441295624,
            0.019772207364439964,
            0.007743842899799347,
            -0.005098114721477032,
            0.012865114025771618,
            0.048071734607219696,
            -0.019118815660476685,
            -0.02126607857644558,
            -0.01886267587542534,
            0.0008663302287459373,
            0.03546800836920738,
            -0.0355733260512352,
            0.009353622794151306,
            0.004852864425629377,
            0.01873205229640007,
            0.05074241757392883,
            0.01309756375849247,
            0.0075785317458212376,
            -0.02616713009774685,
            0.018635675311088562,
            -0.03166849538683891,
            -0.020387884229421616,
            -0.02059926465153694,
            -0.016977854073047638,
            0.026755331084132195,
            0.003988442476838827,
            0.022530395537614822,
            -0.02226429432630539,
            0.02579941786825657,
            0.005075356923043728,
            -0.01566985249519348,
            0.004836288746446371,
            0.013861793093383312,
            -0.012695093639194965,
            -0.01079667080193758,
            0.0034864379558712244,
            -0.0005946767632849514,
            -0.0007905669044703245,
            -0.006055761128664017,
            0.017696915194392204,
            -0.0491364486515522,
            0.0018970172386616468,
            0.050207849591970444,
            0.012396418489515781,
            0.02086079865694046,
            0.021150218322873116,
            -0.03291283920407295,
            0.024791445583105087,
            0.006701712496578693,
            0.022412361577153206,
            -0.0011238238075748086,
            0.004551587160676718,
            -0.005524148233234882,
            0.019826773554086685,
            -0.037330031394958496,
            -0.01587711274623871,
            0.01981513574719429,
            0.025700809434056282,
            -0.0259755440056324,
            0.03467428684234619,
            -0.012799588032066822,
            0.0030091365333646536,
            0.020740903913974762,
            0.03354805335402489,
            0.018648456782102585,
            -0.01689961738884449,
            0.005190767347812653,
            -0.007241425104439259,
            0.024539802223443985,
            -0.010101739317178726,
            0.0191496629267931,
            -0.03510568290948868,
            -0.023916497826576233,
            0.017771374434232712,
            -0.012016047723591328,
            0.0013764515751972795,
            -0.01380112860351801,
            0.001483952160924673,
            -0.009159669280052185,
            0.017110588029026985,
            0.009934451431035995,
            -0.008412758819758892,
            -0.005002014804631472,
            -0.03672623261809349,
            -0.015308985486626625,
            0.017885735258460045,
            0.05784307047724724,
            0.01842186413705349,
            0.02736007608473301,
            0.0370824970304966,
            0.025836681947112083,
            0.03219375014305115,
            0.010747076012194157,
            0.0020104774739593267,
            4.617331342160469e-06,
            0.001761468593031168,
            0.003521845443174243,
            0.007162728812545538,
            0.016266856342554092,
            0.026054665446281433,
            0.02272758074104786,
            -0.03716949000954628,
            0.037498027086257935,
            0.02200579270720482,
            0.02527674473822117,
            0.02384885586798191,
            0.012490196153521538,
            -0.019735228270292282,
            0.0028243090491741896,
            -0.03944960981607437,
            -0.0218038372695446,
            -0.003462348598986864,
            0.017136679962277412,
            0.019391758367419243,
            -0.02595093660056591,
            -0.013684545643627644,
            -0.055960606783628464,
            -0.020271914079785347,
            -0.009168674238026142,
            0.025914397090673447,
            0.030824216082692146,
            0.0011793917510658503,
            -0.008265932090580463,
            0.0013624407583847642,
            -0.018520932644605637,
            -0.024818764999508858,
            -0.02398463524878025,
            -0.01513791736215353,
            0.005803714040666819,
            0.02237890101969242,
            -0.013980405405163765,
            0.021516170352697372,
            0.023355839774012566,
            -0.005091761238873005,
            -0.03565937653183937,
            -0.04325808212161064,
            0.008281605318188667,
            -0.00023287256772164255,
            -0.016617899760603905,
            -0.007232745178043842,
            -0.03645996004343033,
            -0.02045813389122486,
            0.02383168414235115,
            0.002996227005496621,
            -0.018885120749473572,
            0.038209252059459686,
            -0.012859034352004528,
            0.023299386724829674,
            -0.01707061193883419,
            -0.024723488837480545,
            0.013946682214736938,
            -0.015128611586987972,
            -0.010306408628821373,
            0.006838749162852764,
            -0.024624256417155266,
            -0.021493297070264816,
            0.047415271401405334,
            -0.0017602338921278715,
            -0.03310580179095268,
            -0.012903665192425251,
            0.005540082231163979,
            0.003168955212458968,
            -0.004209154285490513,
            0.007801729720085859,
            -0.01847117394208908,
            0.04284024238586426,
            0.009194200858473778,
            -0.004472531843930483,
            0.0022237012162804604,
            0.01618596911430359,
            -0.0071718948893249035,
            -0.042750585824251175,
            -0.03074345737695694,
            -0.0118009764701128,
            0.026944860816001892,
            0.017653990536928177,
            -0.024936076253652573,
            0.055716004222631454,
            0.01630241982638836,
            -0.02307916432619095,
            0.046486806124448776,
            -0.027702869847416878,
            -0.02068694308400154,
            -0.03280574455857277,
            -0.005559300072491169,
            0.006999296601861715,
            -0.011997771449387074,
            0.015058750286698341,
            -0.008842838928103447,
            -0.01474800519645214,
            -0.03091215156018734,
            0.0004159538948442787,
            -0.004342290572822094,
            -0.01135566271841526,
            0.0027962177991867065,
            -0.03058597631752491,
            0.041848886758089066,
            0.016432244330644608,
            0.01477933768182993,
            -0.007067206781357527,
            -0.013883343897759914,
            -0.03706669062376022,
            0.020309193059802055,
            0.03688189759850502,
            -0.00798369012773037,
            0.02279977686703205,
            -0.015234537422657013,
            -0.007020319811999798,
            -0.009429753758013248,
            0.004752125591039658,
            0.01780925691127777,
            -0.023401618003845215,
            0.01038235705345869,
            0.029364462941884995,
            -0.02835763245820999,
            -0.0027772383764386177,
            0.024751853197813034,
            7.635071233380586e-05,
            -0.014180749654769897,
            0.04163028299808502,
            0.017680853605270386,
            -0.012713005766272545,
            -0.009697710163891315,
            0.02036506123840809,
            -0.010235055349767208,
            -0.012423036620020866,
            -0.004230524878948927,
            0.024320565164089203,
            -0.03162010386586189,
            0.006071318872272968,
            -0.0019388969521969557,
            0.01438748650252819,
            -0.00039344161632470787,
            -0.0033627012744545937,
            0.02282024174928665,
            -0.01170168537646532,
            -0.023076903074979782,
            0.014488443732261658,
            0.022378213703632355,
            0.020657861605286598,
            0.020149175077676773,
            -0.006922100204974413,
            0.011222601868212223,
            -0.0009912304813042283,
            -0.008216695860028267,
            -0.014245003461837769,
            -0.0072184959426522255,
            0.010498503223061562,
            0.0160540621727705,
            0.0009380253031849861,
            0.0034159955102950335,
            0.034495096653699875,
            0.03382962942123413,
            -0.0036898672115057707,
            0.019954774528741837,
            0.039474863559007645,
            -0.0055223931558430195,
            -0.011768193915486336,
            0.04773258417844772,
            0.014696771278977394,
            -0.02914551831781864,
            -0.005222986917942762,
            -0.0143401138484478,
            0.021655410528182983,
            0.012628733180463314,
            0.016890086233615875,
            0.016993030905723572,
            0.013303142040967941,
            0.03196296468377113,
            0.0006532514817081392,
            -0.027377339079976082,
            0.01338220201432705,
            0.09086030721664429,
            0.025258013978600502,
            -0.035257186740636826,
            0.016995428130030632,
            -0.022669406607747078,
            -0.008827353827655315,
            -0.023492101579904556,
            -0.024985965341329575,
            -0.05139213427901268,
            0.004710797220468521,
            -0.01697373203933239,
            0.012808197177946568,
            0.03372456505894661,
            0.029186081141233444,
            0.032501328736543655,
            0.04013122245669365,
            0.029785338789224625,
            0.018524572253227234,
            -0.024702096357941628,
            0.03369254991412163,
            0.0018294285982847214,
            -0.05095889791846275,
            0.030944446101784706,
            0.005342898424714804,
            0.006644057109951973,
            0.02545046992599964,
            0.016447726637125015,
            -0.02862306497991085,
            -0.0033624412026256323,
            0.006854860577732325,
            -0.040366675704717636,
            -0.019919343292713165,
            0.01335990522056818,
            -0.02349942922592163,
            -0.024054016917943954,
            0.031476594507694244,
            0.008991733193397522,
            0.004763427656143904,
            0.010022031143307686,
            0.023288169875741005,
            0.0012856355169788003,
            0.0235846396535635,
            0.0044663758017122746,
            -0.012401006184518337,
            0.0068695736117661,
            -0.02042417787015438,
            -1.0184094207943417e-05,
            0.021158605813980103,
            0.013386235572397709,
            -0.01307251863181591,
            0.048750802874565125,
            -0.036079805344343185,
            0.005390829872339964,
            -0.01431189849972725,
            -0.021069379523396492,
            0.014565308578312397,
            0.002353278687223792,
            0.0326123908162117,
            0.004236322827637196,
            -0.018256589770317078,
            0.017852511256933212,
            0.028269268572330475,
            0.04652111977338791,
            0.025220675393939018,
            0.008165842853486538,
            0.010477826930582523,
            0.02184639871120453,
            0.02099832519888878,
            0.024168984964489937,
            0.013204610906541348,
            -0.04781380295753479,
            0.0284616407006979,
            -0.027511512860655785,
            -0.009638449177145958,
            0.013955898582935333,
            -0.06080963835120201,
            -0.009441632777452469,
            0.018000327050685883,
            0.0026464397087693214,
            -0.022065266966819763,
            -0.008794213645160198,
            0.03966924920678139,
            -0.004819471389055252,
            0.017960309982299805,
            0.010666602291166782,
            0.0008931963820941746,
            -0.009257477708160877,
            -0.015214032493531704,
            -0.033922113478183746,
            -0.01312953419983387,
            -0.01838948391377926,
            -0.05621562898159027,
            0.004001392051577568,
            0.028413210064172745,
            0.04162747785449028,
            0.024129705503582954,
            0.0164832454174757,
            0.00983385555446148,
            -0.008046259172260761,
            0.013789661228656769,
            -0.037021249532699585,
            0.01187930814921856,
            0.015130143612623215,
            -0.016079124063253403,
            0.007895020768046379,
            0.0005392783787101507,
            0.0068797278217971325,
            -0.024827834218740463,
            -0.016785399988293648,
            0.005627866368740797,
            -0.0005362256779335439,
            0.020876411348581314,
            -0.009206959046423435,
            -0.01282590627670288,
            0.040001869201660156,
            0.013156093657016754,
            0.014814168214797974,
            -0.024554479867219925,
            -0.019375797361135483,
            0.017324192449450493,
            -0.03482787311077118,
            -0.02569982223212719,
            0.007560816127806902,
            0.02423432283103466,
            0.002929560374468565,
            -0.0014528647297993302,
            -0.0048690978437662125,
            -0.02399507164955139,
            0.02387762814760208,
            -0.0016536809271201491,
            0.005015063099563122,
            0.026639774441719055,
            -0.003945881500840187,
            -0.008519996888935566,
            -0.0219001192599535,
            -0.026975544169545174,
            0.008039601147174835,
            -0.0033240218181163073,
            0.00800029281526804,
            0.022822285071015358,
            0.007314791437238455,
            -0.011609626933932304,
            0.014337542466819286,
            0.006953598465770483,
            -0.028529830276966095,
            -0.00034838978899642825,
            -0.014993634074926376,
            0.02354227937757969,
            0.018662352114915848,
            -0.03830290585756302,
            0.033459387719631195,
            -0.01850821264088154
        ],
        [
            -0.025332624092698097,
            0.0103307431563735,
            0.017134079709649086,
            0.006495770998299122,
            -0.01951221190392971,
            0.04168206825852394,
            0.02995719201862812,
            0.011443860828876495,
            0.02541181445121765,
            0.028373761102557182,
            -0.02420494519174099,
            -0.0170778539031744,
            0.005668347235769033,
            -0.005609121639281511,
            -0.03177545964717865,
            0.006202267482876778,
            0.0274240393191576,
            0.023582765832543373,
            -0.004459144081920385,
            -0.03329937160015106,
            0.0064531173557043076,
            -0.030665036290884018,
            0.005179448984563351,
            0.018013251945376396,
            -0.022911489009857178,
            0.011089238338172436,
            0.03258446604013443,
            0.016334695741534233,
            -0.017904365435242653,
            -0.024587875232100487,
            -0.015115016140043736,
            -0.02411125786602497,
            0.03303976356983185,
            -0.006565956398844719,
            -0.015428613871335983,
            0.0038978392258286476,
            -0.027713129296898842,
            0.017287051305174828,
            0.023141726851463318,
            0.024873843416571617,
            0.029695309698581696,
            0.009774550795555115,
            0.02760365419089794,
            -0.017860740423202515,
            0.027581341564655304,
            -0.021733565255999565,
            -0.034287940710783005,
            -0.0331573411822319,
            0.023043755441904068,
            -0.026172615587711334,
            -0.03179479017853737,
            0.0048658777959644794,
            0.001263521728105843,
            -0.010985276661813259,
            0.01928783766925335,
            -0.005799217149615288,
            0.020135078579187393,
            -0.014078199863433838,
            -0.02981792949140072,
            0.01186272781342268,
            -0.008351326920092106,
            0.0006270261947065592,
            0.01580657809972763,
            -0.025732843205332756,
            -0.00300438585691154,
            0.013842275366187096,
            -0.02291492186486721,
            -0.027607060968875885,
            0.0034326843451708555,
            -0.004846073687076569,
            -0.004275498446077108,
            -0.006164561957120895,
            0.008785368874669075,
            -0.02207699976861477,
            -0.01603487692773342,
            -0.02587386593222618,
            0.02864661067724228,
            0.011160040274262428,
            -0.01871090568602085,
            -0.025591427460312843,
            -0.009848778136074543,
            -0.004000436048954725,
            -0.007315457798540592,
            0.03243160620331764,
            -0.014563823118805885,
            0.011875804513692856,
            0.021142393350601196,
            0.03803147375583649,
            -0.015249855816364288,
            -0.008282258175313473,
            0.010466063395142555,
            -0.00875998754054308,
            0.012633760459721088,
            0.008402756415307522,
            0.011056913062930107,
            -0.028225433081388474,
            0.018960019573569298,
            0.020909685641527176,
            -0.04148523136973381,
            0.001008160994388163,
            0.025901902467012405,
            0.005877907387912273,
            0.018509015440940857,
            0.030274467542767525,
            0.018819747492671013,
            0.03342766687273979,
            0.0036831428296864033,
            0.007707252632826567,
            -0.001125488430261612,
            0.014120995067059994,
            -0.005104432348161936,
            -0.02510303258895874,
            0.0301357451826334,
            0.015299948863685131,
            -0.029678689315915108,
            0.008986441418528557,
            0.011230064556002617,
            0.0013464847579598427,
            0.007191329263150692,
            -0.012997751124203205,
            0.0325385183095932,
            0.0030221142806112766,
            -0.016665060073137283,
            0.01938459649682045,
            0.008615798316895962,
            0.01603730022907257,
            0.016478516161441803,
            0.022410351783037186,
            -0.012704110704362392,
            0.005537575576454401,
            -0.0009795993100851774,
            0.04448535293340683,
            0.03939561918377876,
            0.009373350068926811,
            -0.017185406759381294,
            0.007669719867408276,
            -0.03032453916966915,
            0.006448724772781134,
            0.007127808406949043,
            -0.03225008770823479,
            0.028459088876843452,
            -0.03126104548573494,
            -0.006109414156526327,
            0.010129298083484173,
            -0.00787343643605709,
            -0.011479824781417847,
            0.020526831969618797,
            -0.02804993838071823,
            -0.01020028442144394,
            0.00522531708702445,
            -0.0029920253437012434,
            -0.0010271650971844792,
            -0.002606456633657217,
            0.03567146882414818,
            -0.006084596272557974,
            0.016261637210845947,
            0.0024299451615661383,
            -0.016350191086530685,
            0.00665789470076561,
            -0.04435902461409569,
            0.016452908515930176,
            0.004971023183315992,
            0.026793943718075752,
            0.030408233404159546,
            -0.013744408264756203,
            0.03260127082467079,
            0.021092526614665985,
            0.003071787068620324,
            0.04238561540842056,
            -0.019744526594877243,
            -0.008865869604051113,
            0.008184075355529785,
            0.006507590413093567,
            0.02774980291724205,
            0.018815677613019943,
            -0.013103514909744263,
            0.007871076464653015,
            0.013985930010676384,
            -0.0030611592810600996,
            -0.0025424484629184008,
            0.015269837342202663,
            -0.014847268350422382,
            0.027799014002084732,
            0.016275601461529732,
            0.01734362170100212,
            0.024740155786275864,
            0.0059241666458547115,
            0.0011508786119520664,
            0.010195610113441944,
            0.024392493069171906,
            0.03243781253695488,
            0.04408368468284607,
            0.010938156396150589,
            -0.02856476418673992,
            0.018334198743104935,
            -0.008335915394127369,
            0.044049300253391266,
            0.014628497883677483,
            0.022227583453059196,
            -0.015326403081417084,
            -0.016889769583940506,
            0.028837529942393303,
            -0.024379389360547066,
            0.012779531069099903,
            0.013180376030504704,
            -0.008931919932365417,
            -0.014346646144986153,
            0.014409244991838932,
            0.0316554456949234,
            -0.0302693210542202,
            -0.024839485064148903,
            0.03567412495613098,
            -0.0039013982750475407,
            0.00287057482637465,
            0.026688406243920326,
            -0.0026757027953863144,
            -0.012310411781072617,
            0.017514949664473534,
            0.018411291763186455,
            0.028512300923466682,
            -0.024680975824594498,
            -0.015760933980345726,
            -0.017823338508605957,
            -0.008558320812880993,
            0.028037594631314278,
            -0.021359985694289207,
            -0.0016642543487250805,
            0.012555425055325031,
            -0.020797880366444588,
            -0.016684724017977715,
            0.017994903028011322,
            0.001896564499475062,
            -0.0046101282350718975,
            0.025435367599129677,
            -0.0065490747801959515,
            0.034373193979263306,
            0.03824290633201599,
            -0.010410240851342678,
            -0.01732557825744152,
            -0.0035578880924731493,
            0.005686829332262278,
            -0.02443225122988224,
            0.028309766203165054,
            -0.029710032045841217,
            0.009436970576643944,
            -0.025652151554822922,
            -0.0012699869694188237,
            -0.013618117198348045,
            -0.0044957781210541725,
            0.007265989203006029,
            0.005845922976732254,
            -0.02993164025247097,
            0.019380396232008934,
            0.02710309624671936,
            0.025715313851833344,
            0.013632472604513168,
            0.023839395493268967,
            -0.004002435132861137,
            0.0074761309660971165,
            0.0007168282754719257,
            0.0007036939496174455,
            0.0038994040805846453,
            -0.01876743696630001,
            -0.01427657064050436,
            0.021600622683763504,
            0.0030536281410604715,
            0.00533576775342226,
            -0.004468665923923254,
            -0.007754932157695293,
            0.03674890100955963,
            0.009324908256530762,
            -0.012816996313631535,
            0.025787686929106712,
            -0.02652730420231819,
            0.003939795773476362,
            0.031021378934383392,
            0.023837866261601448,
            0.014265157282352448,
            0.02299194224178791,
            -0.024390297010540962,
            -0.02926817536354065,
            0.0324876569211483,
            -0.03177293762564659,
            -0.008263405412435532,
            -0.005725163500756025,
            -0.009111701510846615,
            -0.00331034604460001,
            0.010939255356788635,
            0.03251288831233978,
            -0.021941473707556725,
            0.024535726755857468,
            0.027682827785611153,
            -0.006118441000580788,
            0.005930016748607159,
            -0.014488925226032734,
            0.013511128723621368,
            0.010740574449300766,
            0.010796850547194481,
            -0.00603121193125844,
            0.008475788868963718,
            0.009732459671795368,
            -0.010532798245549202,
            -0.012446189299225807,
            -0.03083375282585621,
            0.021841470152139664,
            -0.020683446899056435,
            0.010877241380512714,
            -0.02051563747227192,
            -0.04238252341747284,
            0.017111966386437416,
            0.0014168325578793883,
            -0.019021568819880486,
            0.009217606857419014,
            0.007135363761335611,
            0.003127127420157194,
            -0.02432570420205593,
            -0.005971207283437252,
            0.011313690803945065,
            0.007180496118962765,
            0.010615218430757523,
            -0.01972443237900734,
            -0.013765227049589157,
            0.017366252839565277,
            0.03232026845216751,
            -0.00739138713106513,
            0.025284163653850555,
            -0.020275259390473366,
            0.019233321771025658,
            0.021379349753260612,
            -0.01181665901094675,
            0.019228018820285797,
            -0.024045664817094803,
            -0.01650838926434517,
            -0.021369503811001778,
            0.0007843552157282829,
            0.029300279915332794,
            0.029897281900048256,
            -0.009443211369216442,
            0.010515188798308372,
            -0.0317210778594017,
            0.0234951414167881,
            0.03954309970140457,
            -0.004086071625351906,
            0.01726667210459709,
            -0.028599455952644348,
            -0.007902713492512703,
            0.007025257218629122,
            -0.030824488028883934,
            -0.014215825125575066,
            0.016519146040081978,
            -0.02985210157930851,
            0.017374450340867043,
            0.021939506754279137,
            -0.02252439595758915,
            0.05834571644663811,
            -0.021279534325003624,
            -0.007549569476395845,
            -0.033520620316267014,
            -0.020032688975334167,
            -0.03198142349720001,
            0.02459331601858139,
            -0.029873814433813095,
            0.008790941908955574,
            0.005231873597949743,
            0.020221155136823654,
            -0.0005205239867791533,
            -0.016596654430031776,
            0.0035085619892925024,
            0.005530333146452904,
            -0.027791760861873627,
            0.025601735338568687,
            0.015465973876416683,
            0.055017679929733276,
            -0.006954110227525234,
            0.014854634180665016,
            -0.0073288255371153355,
            0.020771130919456482,
            -0.0012805515434592962,
            -0.013599652796983719,
            0.027443887665867805,
            -0.013010304421186447,
            -0.014344320632517338,
            0.020609749481081963,
            0.01833275891840458,
            0.034018225967884064,
            -0.021550174802541733,
            0.014728905633091927,
            -0.015032039023935795,
            -0.0026811452116817236,
            0.01899723894894123,
            -0.004269152879714966,
            -0.027306970208883286,
            -0.023355813696980476,
            0.0029207172337919474,
            0.06734912097454071,
            0.019712770357728004,
            -0.0013690305640920997,
            0.019977057352662086,
            0.027517056092619896,
            0.029020091518759727,
            0.01124042272567749,
            0.0004262790607754141,
            -0.010140461847186089,
            -0.006224228534847498,
            0.02258124202489853,
            -0.03927117586135864,
            -0.02619173191487789,
            0.0007931204745545983,
            -0.018112149089574814,
            0.021109702065587044,
            0.015724876895546913,
            0.021668769419193268,
            0.026209205389022827,
            0.0034485766664147377,
            0.00205543776974082,
            -0.023927347734570503,
            -0.007177256513386965,
            0.021451624110341072,
            -0.007140479516237974,
            -0.015592451207339764,
            -0.017388364300131798,
            0.005433471407741308,
            -0.010206934995949268,
            -0.016778049990534782,
            -0.019391581416130066,
            0.015536134131252766,
            -0.010904804803431034,
            -0.01824190840125084,
            0.002915444318205118,
            0.006626634392887354,
            -0.003782518208026886,
            -0.010889474302530289,
            -0.008074908517301083,
            0.012326806783676147,
            0.02540118619799614,
            0.01971396431326866,
            0.029989076778292656,
            -0.013317424803972244,
            0.016652682796120644,
            -0.0029241512529551983,
            -0.01880117878317833,
            0.02148020826280117,
            0.003823555074632168,
            0.010293758474290371,
            -0.013783440925180912,
            -0.006919104140251875,
            -0.0008217779104597867,
            -0.025033630430698395,
            0.027969343587756157,
            0.025033796206116676,
            -0.018599623814225197,
            0.02873304672539234,
            -0.0246356800198555,
            -0.011226961389183998,
            -0.013056408613920212,
            0.004051425959914923,
            -0.03160875290632248,
            0.033462852239608765,
            -0.021699676290154457,
            0.016636094078421593,
            -0.017863472923636436,
            -0.019000615924596786,
            -0.007086688186973333,
            -0.008941449224948883,
            0.019450105726718903,
            0.02032236009836197,
            0.03729229047894478,
            -0.019780881702899933,
            0.028782811015844345,
            0.02462167665362358,
            -0.008435945957899094,
            0.021294765174388885,
            0.025576360523700714,
            0.03171153366565704,
            0.016757212579250336,
            0.02395305410027504,
            0.010311336256563663,
            -0.007952408865094185,
            -0.021074729040265083,
            -0.009749024175107479,
            -0.02699694223701954,
            0.010878209955990314,
            0.011450527235865593,
            0.024321921169757843,
            -0.00820877868682146,
            0.021916840225458145,
            -0.02965872921049595,
            0.010955548845231533,
            -0.018295541405677795,
            -0.015405427664518356,
            -0.021072443574666977,
            0.02692756988108158,
            -0.03225613757967949,
            0.011918291449546814,
            -0.0020589709747582674,
            -0.010368170216679573,
            -0.023778866976499557,
            0.0045442222617566586,
            0.023881854489445686,
            0.022043302655220032,
            0.012664442881941795,
            0.02071223221719265,
            -0.03553483262658119,
            0.013867276720702648,
            0.027279863134026527,
            -0.0024200461339205503,
            0.0155348414555192,
            -0.00036156518035568297,
            0.025082163512706757,
            -0.027430670335888863,
            -0.008277622982859612,
            0.04451337084174156,
            -0.0035676108673214912,
            -0.0005597764393314719,
            -0.04603152722120285,
            -0.020407643169164658,
            0.0267177764326334,
            0.021382160484790802,
            -0.022842152044177055,
            -0.01872674748301506,
            0.015182100236415863,
            -0.029677191749215126,
            -0.007108594756573439,
            -0.006832893937826157,
            -0.03276300057768822,
            0.03449530899524689,
            0.03995485603809357,
            -0.01064673438668251,
            -0.016470175236463547,
            -0.010856571607291698,
            -0.021537622436881065,
            0.03441346064209938,
            0.016098717227578163,
            0.0043703303672373295,
            -0.005825400818139315,
            0.038830749690532684,
            0.022382626309990883,
            -0.02549993246793747,
            -0.009704750962555408,
            -0.018015753477811813,
            0.01637379452586174,
            -0.02896670252084732,
            0.00659950403496623,
            -0.03536408767104149,
            -0.004047417547553778,
            -0.0035870231222361326,
            -0.011419220827519894,
            0.02093745954334736,
            -0.007232776377350092,
            -0.0380527563393116,
            0.023445378988981247,
            0.018007637932896614,
            -0.0029680717270821333,
            0.013164438307285309,
            0.008838486857712269,
            -0.01640673540532589,
            0.01437581330537796,
            -0.018311893567442894,
            -0.013238540850579739,
            -0.029329847544431686,
            0.03194772079586983,
            -0.01950051076710224,
            0.00774765620008111,
            -0.045587606728076935,
            0.004653125535696745,
            0.008890382945537567,
            -0.014957028441131115,
            0.013804866001009941,
            0.034356631338596344,
            0.012847807258367538,
            -0.030489487573504448,
            -0.018933288753032684,
            0.0161353200674057,
            -0.019540658220648766,
            -0.019201219081878662,
            0.02394123747944832,
            0.014907331205904484,
            0.03491653874516487,
            0.012872680090367794,
            -0.01606670953333378,
            0.0031513990834355354,
            -0.011725076474249363,
            0.020632952451705933,
            -0.007100364658981562,
            0.03920358419418335,
            0.0022290581837296486,
            0.01083978358656168,
            -0.029875926673412323,
            -0.018375148996710777,
            0.010202084667980671,
            -0.020278822630643845,
            0.028973029926419258,
            0.0022944146767258644,
            0.014158693142235279,
            0.01590052619576454,
            0.002691693603992462,
            0.005063152872025967,
            0.025500981137156487,
            0.016472849994897842,
            0.03479854017496109,
            -0.019605526700615883,
            -0.009690409526228905,
            -0.015228770673274994,
            0.03303111717104912,
            -0.02893950790166855,
            0.005738346371799707,
            -0.016798054799437523,
            -0.005899664480239153,
            0.018772922456264496,
            -0.027796292677521706,
            -0.018530521541833878,
            -0.00782794039696455,
            -0.02794869802892208,
            0.01445815246552229,
            -0.014444888569414616,
            -0.006895953323692083,
            0.0038214304950088263,
            -0.0034601029474288225,
            -0.0027758884243667126,
            0.027981773018836975,
            -0.026265710592269897,
            -0.009570708498358727,
            0.029056992381811142,
            -0.021958008408546448,
            0.015504450537264347,
            -0.025251084938645363,
            -0.024564621970057487,
            0.032947130501270294,
            -0.01745903678238392,
            0.009016391821205616,
            -0.0018405348528176546,
            0.009697018191218376,
            -0.014545959420502186,
            0.015240284614264965,
            -0.021033434197306633,
            0.01190978940576315,
            -0.012049449607729912,
            0.008909651078283787,
            -0.01894061267375946,
            -0.016255632042884827,
            0.015428667888045311,
            0.026298275217413902,
            0.039252083748579025,
            -0.0016141755040735006,
            -0.009743751026690006,
            -0.008676904253661633,
            -0.0373670756816864,
            -0.035185713320970535,
            0.025219010189175606,
            0.006049375515431166,
            -0.001320997136645019,
            0.00404374347999692,
            -0.0266611035913229,
            0.0005478451494127512,
            -0.00148677546530962,
            0.028613250702619553,
            0.0038210104685276747,
            -0.013860322535037994,
            0.029017871245741844,
            0.01637313887476921,
            0.01544914674013853,
            0.00828442070633173,
            -0.024700356647372246,
            0.025715447962284088,
            0.008876356296241283,
            -0.003688686527311802,
            0.004156940616667271,
            -0.012214073911309242,
            0.013135614804923534,
            0.0015348878223448992,
            -0.00866785366088152,
            0.004379153251647949,
            0.017918985337018967,
            -0.046634115278720856,
            0.019423123449087143,
            0.02279219776391983,
            -0.019009986892342567,
            0.0077177658677101135,
            0.014033256098628044,
            0.029627345502376556,
            -0.034763775765895844,
            -0.014056840911507607,
            0.029480114579200745,
            -0.031170550733804703,
            0.02039233036339283,
            0.018468104302883148,
            0.04263317957520485,
            0.017591062933206558,
            -0.001290061161853373,
            0.015340079553425312,
            0.004465814214199781,
            0.0012643934460356832,
            0.0018864249577745795,
            -0.020663408562541008,
            0.02774176374077797,
            0.03546763211488724,
            -0.003700532717630267,
            -0.000867200898937881,
            0.017926570028066635,
            0.00728667201474309,
            0.011808252893388271,
            -0.018848855048418045,
            -0.016285670921206474,
            0.020878398790955544,
            -0.012470479123294353,
            -0.008709285408258438,
            -0.006892711389809847,
            -0.009507748298346996,
            -0.0148360226303339,
            0.01877271570265293,
            0.0233147032558918,
            0.01741967909038067,
            0.019885994493961334,
            -0.022823527455329895,
            0.018740076571702957,
            -0.030162779614329338,
            0.02480834163725376,
            -0.009787657298147678,
            0.007723038550466299,
            0.02107517048716545,
            -0.024258136749267578,
            0.021480288356542587,
            -0.013481024652719498,
            -0.011356283910572529,
            -0.011673900298774242,
            -0.000688985746819526,
            0.004541128408163786,
            -0.02647652104496956,
            -0.0038257150445133448,
            0.013645770959556103,
            0.02080105058848858,
            0.025315798819065094,
            0.002488267607986927,
            0.0031644203700125217,
            0.004941342864185572,
            0.009488075040280819,
            -0.030743826180696487,
            0.00343201938085258,
            -0.04317103326320648,
            0.0039017414674162865,
            0.02298000268638134,
            0.03475353121757507,
            0.026596898213028908,
            0.013546253554522991,
            -0.02011125534772873,
            -0.03191491961479187,
            0.009423117153346539,
            0.01244765892624855,
            -0.018028955906629562,
            0.01288169901818037,
            -0.0060094804503023624,
            -0.0225426834076643,
            0.019903196021914482,
            0.0032855914905667305,
            0.02167016640305519,
            0.026943949982523918,
            -0.008950271643698215,
            -0.029092280194163322,
            -0.026093168184161186,
            0.00020650475926231593,
            0.0029640395659953356,
            -0.01307157427072525,
            -0.017745688557624817,
            -0.002939174883067608,
            0.01475443784147501,
            0.007492879405617714,
            0.02233240380883217,
            -0.019924256950616837,
            0.028361260890960693,
            0.030331192538142204,
            0.00030358764342963696,
            -0.023947127163410187,
            0.01909986138343811,
            0.005114702042192221,
            0.027776161208748817,
            0.005849914159625769,
            0.03110756352543831,
            0.023275593295693398,
            -0.023211659863591194,
            0.004881368018686771,
            0.04079943522810936,
            0.023282216861844063,
            0.026056842878460884,
            0.0009737578802742064,
            0.002169641200453043,
            -0.029366716742515564,
            -0.06047747656702995,
            0.007311569061130285,
            0.023668546229600906,
            0.012182659469544888,
            0.0025247123558074236,
            0.012677663005888462,
            0.012538393959403038,
            -0.003700107103213668,
            -0.02429012767970562,
            -0.017948979511857033,
            -0.00880425050854683,
            -0.026242950931191444,
            -0.00541601562872529,
            0.0216381773352623,
            -0.006892507895827293,
            0.013557740487158298,
            0.044292811304330826,
            -0.019907088950276375,
            0.013195681385695934,
            0.011151528917253017,
            0.04160038381814957,
            0.012319016270339489,
            -0.008497107774019241,
            0.0008439332596026361,
            -0.027580566704273224,
            -0.0170853603631258,
            -0.017662396654486656,
            -0.004619930405169725,
            0.006790385581552982,
            0.012273064814507961,
            -0.002908335067331791,
            -0.015477603301405907,
            -0.0019609173759818077,
            0.0175990741699934,
            0.024170970544219017,
            0.02414080500602722,
            0.028640560805797577,
            -0.006583427079021931,
            0.008975772187113762,
            -0.02031431905925274,
            0.011546495370566845,
            0.02668243832886219,
            0.02182418666779995,
            0.0027621390763670206,
            -0.011367006227374077,
            0.0024828265886753798,
            -0.010128757916390896,
            -0.012960820458829403,
            0.032190825790166855,
            -0.0007758059655316174,
            0.006946895737200975,
            -0.0001220798003487289,
            0.010453027673065662,
            0.02736019156873226,
            -0.016761040315032005,
            0.0010893630096688867,
            -0.013693137094378471,
            -0.015057633630931377,
            -0.009851384907960892,
            -0.02973051555454731,
            -0.005337079521268606,
            -0.005006692837923765,
            -0.0065804398618638515,
            -0.028119418770074844,
            0.022040750831365585,
            -0.027852019295096397,
            0.014096379280090332,
            0.0059104557149112225,
            -0.016170352697372437,
            -0.019232748076319695,
            -0.022944625467061996,
            0.027697619050741196,
            0.0037231200840324163,
            -0.02360386960208416,
            -0.025106916204094887,
            0.012232199311256409,
            0.015821505337953568,
            0.0041666338220238686,
            0.038498759269714355,
            0.01378805935382843,
            0.017761778086423874,
            -0.00298350490629673,
            0.013466923497617245,
            -0.04198428988456726,
            -0.01869005523622036,
            -0.01305149681866169,
            -0.01152040809392929,
            0.015946807339787483,
            0.005529961548745632,
            -0.02183741144835949,
            0.005942387040704489,
            0.01652650162577629,
            0.022122463211417198,
            -0.0038170856423676014,
            -0.019011711701750755,
            -0.008290248923003674,
            0.005952776875346899,
            0.013022465631365776,
            0.004080158658325672,
            0.025100305676460266,
            0.031752001494169235,
            -0.060728080570697784,
            0.04050639271736145,
            0.0319722518324852,
            -0.00728788273409009,
            -0.003251610090956092,
            -0.011026084423065186,
            -0.007686528842896223,
            -0.024840762838721275,
            -0.008205159567296505,
            0.027199670672416687,
            0.006115362048149109,
            0.018825603649020195,
            0.0333319716155529,
            -0.026342280209064484,
            0.019638046622276306,
            0.026262076571583748,
            -0.012995284050703049,
            0.015667133033275604,
            0.02035273052752018,
            -0.0003308322629891336,
            0.0004525597905740142,
            0.01635959930717945,
            -0.016201648861169815,
            -0.014070362783968449,
            -0.029355496168136597,
            0.018989097326993942,
            0.021517373621463776,
            -0.013762330636382103,
            0.028955398127436638,
            0.02330976538360119,
            0.0037193051539361477,
            -0.029498925432562828,
            0.009608817286789417,
            0.02746100164949894,
            -0.013560844585299492,
            0.021195394918322563,
            -0.021158093586564064,
            0.0019324730383232236,
            0.02373141050338745,
            0.026470445096492767,
            0.010218449868261814,
            0.001303257653489709,
            0.027099965140223503,
            0.028156984597444534,
            0.01207482349127531,
            -0.030228719115257263,
            0.00012591021368280053,
            -0.007502150721848011,
            -0.03059237450361252,
            -0.025403281673789024,
            -0.02591957151889801,
            0.024407386779785156,
            0.0410294346511364,
            0.018965674564242363,
            0.0290087703615427,
            -0.02803409844636917,
            -0.008663186803460121,
            0.020075218752026558,
            -0.022213639691472054,
            -0.005755145568400621,
            -0.047207679599523544,
            0.01027472224086523,
            0.00849604606628418,
            0.01103219948709011,
            0.02495415322482586,
            -0.006322909612208605,
            0.03495432808995247,
            -0.003661448135972023,
            0.02318553812801838,
            0.00033223090576939285,
            -0.027808891609311104,
            0.004530101548880339,
            -0.012080006301403046,
            -0.04166571795940399,
            -0.025626886636018753,
            -0.027415728196501732,
            -0.009491289034485817,
            0.017477750778198242,
            -0.033240944147109985,
            0.0401342548429966,
            -0.027676241472363472,
            -0.02599506266415119,
            0.018748994916677475,
            -0.009145624935626984,
            -0.021668579429388046,
            -0.0213449839502573,
            0.007569603156298399,
            -0.0471414178609848,
            -0.00796392560005188,
            0.000977749819867313,
            0.017602309584617615,
            -0.026896195486187935,
            0.009761837311089039,
            0.01280731800943613,
            -0.022528203204274178,
            -0.05060715228319168,
            0.02410179190337658,
            0.004646506626158953,
            0.01119789108633995,
            0.025697235018014908,
            0.019952435046434402,
            0.02251439541578293,
            -0.02603612281382084,
            0.011206697672605515,
            0.018147239461541176,
            -0.007594649214297533,
            -0.028443943709135056,
            0.02178214304149151,
            0.0017778888577595353,
            0.05053455010056496,
            0.02279454655945301,
            0.020717719569802284,
            0.02495354227721691,
            -0.01098440121859312,
            0.03888259828090668,
            0.009498420171439648,
            0.010567751713097095,
            0.009667806327342987,
            -0.016469310969114304,
            -0.0273634884506464,
            -0.03641953691840172,
            0.0012713944306597114,
            0.014361153356730938,
            0.017040777951478958,
            0.03732314333319664,
            -0.026654815301299095,
            -0.001182045554742217,
            0.00011226746573811397,
            -0.006139352917671204,
            0.035952016711235046,
            -0.025759819895029068,
            0.017775658518075943,
            0.017503483220934868,
            0.009066116996109486,
            -0.0029020155780017376,
            0.01118270494043827,
            -0.019038762897253036
        ],
        [
            -0.012226331047713757,
            -0.015050050802528858,
            -0.008234112523496151,
            0.009057468734681606,
            0.014117592014372349,
            -0.0017419663490727544,
            0.022391391918063164,
            -0.007732216268777847,
            0.003816646756604314,
            0.026543064042925835,
            0.029695626348257065,
            0.010964114218950272,
            -0.030817203223705292,
            0.022009512409567833,
            -0.08908172696828842,
            0.06526913493871689,
            0.030987510457634926,
            -0.011767355725169182,
            0.0421929806470871,
            -0.014328367076814175,
            -0.025152146816253662,
            0.0015274430625140667,
            -0.012027046643197536,
            0.023127246648073196,
            -0.03786206245422363,
            0.0002357708290219307,
            0.018996847793459892,
            0.03089839592576027,
            0.019889231771230698,
            -0.026379581540822983,
            -0.004236889537423849,
            -0.02185196429491043,
            0.011670812033116817,
            -0.012332990765571594,
            -0.0027796265203505754,
            -0.019668804481625557,
            0.0033718172926455736,
            -0.028299864381551743,
            0.0006031779339537024,
            0.030368121340870857,
            0.02604903094470501,
            0.03802596405148506,
            0.006563056726008654,
            -0.005228230729699135,
            -0.0401008315384388,
            -0.030518842861056328,
            -0.03626704216003418,
            0.017757348716259003,
            0.03438566252589226,
            -0.002748035127297044,
            -0.010700740851461887,
            0.013485266827046871,
            -0.015971152111887932,
            -0.0005381308728829026,
            0.010719445534050465,
            -0.008914456702768803,
            0.005113724619150162,
            0.014912420883774757,
            0.0224603284150362,
            0.027850527316331863,
            0.023560572415590286,
            0.004365133121609688,
            0.008281230926513672,
            -0.018632356077432632,
            -0.010746803134679794,
            -0.011765713803470135,
            0.05729370936751366,
            -0.006362750194966793,
            0.04957524314522743,
            0.004370157606899738,
            0.010076767764985561,
            -0.013243318535387516,
            -0.035324789583683014,
            -0.002031429670751095,
            0.01041343342512846,
            0.035540152341127396,
            -0.031435709446668625,
            0.013079781085252762,
            0.009516693651676178,
            -0.027502702549099922,
            -0.01635224185883999,
            -0.060773566365242004,
            0.021900398656725883,
            0.009581430815160275,
            0.03822548687458038,
            0.02052057348191738,
            -0.008602469228208065,
            -0.0071357237175107,
            0.001801254227757454,
            -0.06471405178308487,
            0.029061894863843918,
            0.02917936071753502,
            -0.048347555100917816,
            0.06789016723632812,
            -0.009632346220314503,
            -0.01979578286409378,
            0.020634785294532776,
            -0.011933805420994759,
            0.04001511633396149,
            -0.027193615213036537,
            0.03603116050362587,
            -0.06591440737247467,
            0.028878044337034225,
            0.0038853108417242765,
            -0.018151503056287766,
            0.03209106624126434,
            0.027748145163059235,
            0.010570666752755642,
            0.0398288369178772,
            -0.023725781589746475,
            0.014743752777576447,
            0.029860297217965126,
            -0.03061855211853981,
            -0.04446381703019142,
            -0.01009910088032484,
            -0.040732286870479584,
            -0.0076584648340940475,
            -0.04270738363265991,
            -0.010278420522809029,
            0.004276699386537075,
            -0.009035920724272728,
            -0.028808718547225,
            -0.014067635871469975,
            0.029730482026934624,
            0.016727007925510406,
            -0.024046730250120163,
            -0.04016399383544922,
            -0.014861224219202995,
            -0.0329369455575943,
            0.019428566098213196,
            0.01433525513857603,
            -0.013252388685941696,
            0.0032802270725369453,
            -0.016668427735567093,
            0.019488519057631493,
            -0.0038094862829893827,
            -0.044745881110429764,
            -0.028345726430416107,
            0.026906898245215416,
            -0.016176598146557808,
            0.03385653346776962,
            -0.018434707075357437,
            0.006901291199028492,
            -0.0021467057522386312,
            0.018699515610933304,
            -0.01702832244336605,
            0.027137378230690956,
            -0.023862184956669807,
            0.023176899179816246,
            -0.021189769729971886,
            -0.01200414914637804,
            0.003334394656121731,
            0.001639284542761743,
            -0.0340006984770298,
            -0.01716047339141369,
            -0.0047479537315666676,
            -0.0025504224468022585,
            0.009875670075416565,
            -0.018757235258817673,
            -0.0014559464761987329,
            0.04746663197875023,
            -0.01895856112241745,
            0.037586845457553864,
            -0.012789955362677574,
            -0.0009871802758425474,
            -0.003415648825466633,
            -0.014444800093770027,
            0.012684940360486507,
            0.014748914167284966,
            -0.025010645389556885,
            0.006349284667521715,
            0.006314968224614859,
            0.01678253524005413,
            -0.03448536619544029,
            -0.01832314394414425,
            -0.006461043376475573,
            -0.012656605802476406,
            0.0009506936185061932,
            -0.021078072488307953,
            -0.04708480462431908,
            0.057551540434360504,
            -0.007695377804338932,
            -0.00891069695353508,
            0.020338336005806923,
            -0.02565845102071762,
            -0.01704859733581543,
            0.026475708931684494,
            -0.011088293977081776,
            -0.007974985986948013,
            0.021135075017809868,
            -0.018836582079529762,
            0.02215658873319626,
            -0.03744451701641083,
            0.024978047236800194,
            -0.0189695842564106,
            -0.03316888585686684,
            0.02600392885506153,
            0.006837479304522276,
            -0.02245495840907097,
            0.007925579324364662,
            -0.04250207915902138,
            -0.03898615017533302,
            -0.04614892229437828,
            0.036015331745147705,
            0.022809848189353943,
            -0.0013244965812191367,
            0.018137648701667786,
            -0.03524886444211006,
            -0.019241556525230408,
            -0.013990391977131367,
            -0.031582850962877274,
            -0.033045921474695206,
            0.018217869102954865,
            -0.005632014945149422,
            -0.03178045526146889,
            0.0355958454310894,
            0.021362055093050003,
            -0.006343530025333166,
            -0.017347192391753197,
            0.015104320831596851,
            0.022642379626631737,
            0.05633474141359329,
            -0.0012706035049632192,
            -0.0010945308022201061,
            -0.04298492521047592,
            0.030638130381703377,
            -0.0017503791023045778,
            -0.029324132949113846,
            0.04306074604392052,
            -0.02188054285943508,
            0.001322361291386187,
            0.008932936005294323,
            -0.01457946840673685,
            0.03611740842461586,
            0.03252479434013367,
            -0.028848880901932716,
            -0.0482667051255703,
            -0.02776174806058407,
            0.026245906949043274,
            0.0026628400664776564,
            0.014007079415023327,
            0.0014438327634707093,
            0.005821273662149906,
            0.032213207334280014,
            0.05029890313744545,
            -0.02639884315431118,
            -0.01669595204293728,
            -0.012440984137356281,
            0.009948518127202988,
            -0.01624682918190956,
            -0.007483919616788626,
            -0.0041355532594025135,
            -0.04107263684272766,
            -0.035558704286813736,
            -0.006503859534859657,
            0.035615261644124985,
            0.021939652040600777,
            -0.0056175231002271175,
            -0.011912339366972446,
            0.040595196187496185,
            -0.01838398352265358,
            -0.034361157566308975,
            -0.003827333217486739,
            0.03454684838652611,
            0.017973557114601135,
            0.0030546654015779495,
            0.025530068203806877,
            -0.005029862746596336,
            -0.0021417555399239063,
            -0.007324473932385445,
            0.0224920604377985,
            0.020687086507678032,
            0.0073847174644470215,
            0.029571400955319405,
            0.0016311020590364933,
            -0.0012427732581272721,
            0.04015149921178818,
            -0.0009862188016995788,
            0.0031429030932486057,
            0.027893489226698875,
            -0.004749426618218422,
            -0.014910749159753323,
            0.013235516846179962,
            0.0054088253527879715,
            0.01747100241482258,
            0.027735533192753792,
            0.01976555585861206,
            0.019380291923880577,
            -0.003164262743666768,
            -0.023889465257525444,
            -0.005088467616587877,
            0.02527502551674843,
            -0.01055138185620308,
            -0.004397401586174965,
            -0.023134972900152206,
            -0.03154415637254715,
            0.03724922239780426,
            0.004244677722454071,
            0.00045727635733783245,
            0.03698034957051277,
            0.012070870026946068,
            0.0003905546327587217,
            -0.012360922992229462,
            -0.024648698046803474,
            5.510264600161463e-05,
            0.02437809854745865,
            -0.020756317302584648,
            -0.04562902823090553,
            -0.00016711940406821668,
            -0.027174830436706543,
            0.00352096906863153,
            0.03468317911028862,
            -0.023883935064077377,
            0.019645195454359055,
            0.01041316892951727,
            0.017810199409723282,
            0.02000218816101551,
            0.031080953776836395,
            0.007114251144230366,
            -0.02590704895555973,
            0.018268804997205734,
            0.04849984869360924,
            -0.01780337281525135,
            -0.05525309965014458,
            5.392490129452199e-05,
            0.03801369294524193,
            -0.05348897725343704,
            0.009908598847687244,
            -0.004247034899890423,
            -0.018816011026501656,
            -0.0085358377546072,
            0.03514311462640762,
            -0.0330314002931118,
            0.01726682111620903,
            -0.036564912647008896,
            -0.042826972901821136,
            0.005347778555005789,
            0.0013347645290195942,
            0.020462367683649063,
            0.008912977762520313,
            -0.0037866977509111166,
            0.01760123111307621,
            0.05370395630598068,
            -0.012512403540313244,
            0.0055058179423213005,
            -0.0003181082720402628,
            0.027398159727454185,
            0.006699188146740198,
            -0.04022105038166046,
            -0.0101223299279809,
            0.013447016477584839,
            -0.03580285981297493,
            -0.004894554149359465,
            0.0059159910306334496,
            0.025669444352388382,
            -0.00386420963332057,
            -0.03713872283697128,
            0.02768070623278618,
            0.004367497283965349,
            0.03285064920783043,
            0.017162669450044632,
            0.0188307948410511,
            -0.015918085351586342,
            -0.021421736106276512,
            0.015815330669283867,
            0.013919010758399963,
            0.0035473874304443598,
            -0.0016403375193476677,
            0.003598678158596158,
            0.045740947127342224,
            0.032609011977910995,
            -0.01648467220366001,
            0.002609352581202984,
            -0.00047620604163967073,
            -0.005104973446577787,
            -0.03903178870677948,
            0.03892000392079353,
            0.03376441076397896,
            -0.0005809848662465811,
            0.0077064079232513905,
            0.003224616404622793,
            -0.01254221796989441,
            0.014883404597640038,
            0.043588150292634964,
            0.021879369392991066,
            -0.03751486912369728,
            0.041017767041921616,
            0.026794085279107094,
            -0.03267192095518112,
            -0.002242186339572072,
            -0.01007895078510046,
            0.02912263572216034,
            -0.03203630819916725,
            -0.01380114071071148,
            -0.021943658590316772,
            -0.020076235756278038,
            0.004840820096433163,
            0.017473913729190826,
            -0.017050612717866898,
            -0.029682490974664688,
            -0.002868402050808072,
            -0.008868137374520302,
            0.025758160278201103,
            0.0021134805865585804,
            0.012673679739236832,
            0.007887838408350945,
            -0.021459190174937248,
            0.01425627339631319,
            0.008700723759829998,
            -0.01221939455717802,
            -0.005693152081221342,
            0.007515906821936369,
            0.0067840381525456905,
            0.023102402687072754,
            -0.03060643933713436,
            -0.007753309328109026,
            0.008464344777166843,
            0.007943934760987759,
            -0.024402249604463577,
            -0.022907409816980362,
            0.03235260397195816,
            -0.033638667315244675,
            -0.0347103513777256,
            0.004518283996731043,
            0.03701471909880638,
            -0.011371013708412647,
            -0.03630441799759865,
            0.02665482647716999,
            -0.004691025707870722,
            0.020502472296357155,
            -0.008321570232510567,
            0.017415713518857956,
            0.028740620240569115,
            0.03252372518181801,
            -0.029624130576848984,
            0.037812989205121994,
            -0.007844016887247562,
            0.005212738178670406,
            0.002897353144362569,
            -0.010555190034210682,
            0.02239496074616909,
            0.018578236922621727,
            -0.02764156647026539,
            0.028412088751792908,
            -0.006581686902791262,
            0.013827798888087273,
            -0.002071833238005638,
            -0.06994374841451645,
            -0.026182878762483597,
            0.00551065756008029,
            0.011815845966339111,
            -0.019707417115569115,
            0.023947402834892273,
            -0.027788158506155014,
            0.03184410557150841,
            0.034046903252601624,
            0.027716703712940216,
            -0.020264316350221634,
            -0.04141879081726074,
            0.03745154291391373,
            0.0075288438238203526,
            -0.0034228248987346888,
            0.033262211829423904,
            -0.002154711866751313,
            -0.02484266459941864,
            0.011879057623445988,
            0.028222190216183662,
            -0.003809252055361867,
            0.02778034098446369,
            0.028951304033398628,
            0.023301493376493454,
            0.003633433487266302,
            -0.016319887712597847,
            -0.03752392157912254,
            0.0027083263266831636,
            -0.020256884396076202,
            0.001822612015530467,
            0.0441666878759861,
            0.015482447110116482,
            0.003418151056393981,
            -0.015892373397946358,
            0.018745917826890945,
            0.007566615007817745,
            -0.030019573867321014,
            -0.000394768372643739,
            0.015577519312500954,
            -0.0021444708108901978,
            -0.016365090385079384,
            -0.07915221899747849,
            0.022545624524354935,
            -0.034148890525102615,
            -0.011119703762233257,
            0.015881720930337906,
            0.005846509709954262,
            0.016713889315724373,
            -0.015986593440175056,
            0.004852632991969585,
            -0.007256273180246353,
            -0.029159968718886375,
            -0.013589215464890003,
            -0.023850776255130768,
            -0.06244147568941116,
            -0.022386861965060234,
            -0.05601778253912926,
            0.031898368149995804,
            -0.006733572110533714,
            -0.0025015966966748238,
            0.00252167833968997,
            0.016713714227080345,
            0.01778494007885456,
            0.011861585080623627,
            -0.02593993954360485,
            -0.02285919152200222,
            -0.026327181607484818,
            0.009996459819376469,
            -0.00163209845777601,
            0.009390763007104397,
            -0.031617481261491776,
            -0.0036074433010071516,
            -0.0286087803542614,
            -0.03609980270266533,
            0.016072308644652367,
            -0.023435093462467194,
            0.007799521088600159,
            -0.02622045762836933,
            -0.008129392750561237,
            -0.01678253896534443,
            -0.004909302107989788,
            -0.010028289631009102,
            0.01681629754602909,
            0.013469058088958263,
            0.01121467724442482,
            -0.02730332687497139,
            0.029136767610907555,
            0.028460931032896042,
            -0.043288473039865494,
            0.002346095396205783,
            0.019162673503160477,
            -0.0005993814556859434,
            0.019154150038957596,
            -0.03261059895157814,
            0.012226243503391743,
            -0.003546323161572218,
            0.0019283361034467816,
            0.012692735530436039,
            -0.013019056990742683,
            0.006245041266083717,
            -0.0299531240016222,
            -0.00010654976358637214,
            0.025103429332375526,
            0.03355501592159271,
            -0.017509182915091515,
            -0.020560381934046745,
            -0.008555816486477852,
            0.040682971477508545,
            0.015365826897323132,
            0.03669562563300133,
            -0.036392632871866226,
            0.02392551302909851,
            0.016556933522224426,
            -0.00957141350954771,
            0.0002825029951054603,
            0.0010855181608349085,
            0.02522471733391285,
            -0.027991607785224915,
            0.03340134024620056,
            0.018537970259785652,
            0.00939161702990532,
            -0.003673307131975889,
            -0.006772832479327917,
            0.0012067216448485851,
            -0.011654669418931007,
            -0.012974560260772705,
            -0.0035500144585967064,
            0.012881877832114697,
            -0.01811845786869526,
            0.03257816657423973,
            0.010176392272114754,
            0.005357834510505199,
            -0.007206049747765064,
            -0.017371607944369316,
            0.03665996715426445,
            -0.013532972894608974,
            0.012042406015098095,
            0.027190253138542175,
            -0.02477508969604969,
            0.009080286137759686,
            0.0032803849317133427,
            0.008884171955287457,
            0.014687320217490196,
            0.021037453785538673,
            -0.024740906432271004,
            -0.0065619186498224735,
            0.00812981091439724,
            -0.016948051750659943,
            0.012001106515526772,
            0.04262641817331314,
            -0.012106700800359249,
            -0.016919149085879326,
            0.0474657379090786,
            -0.012706698849797249,
            0.02933444082736969,
            -0.006002776324748993,
            -0.030697884038090706,
            -0.03284119442105293,
            0.003297111950814724,
            0.030188867822289467,
            0.028783945366740227,
            0.010703378356993198,
            0.03472799435257912,
            0.01216500997543335,
            0.0028175602201372385,
            0.013110071420669556,
            0.03940150514245033,
            -0.0186971053481102,
            -0.026715055108070374,
            0.03838527947664261,
            -0.017983686178922653,
            0.01109624095261097,
            -0.001126659451983869,
            -0.04147009924054146,
            0.021563183516263962,
            -0.03070179745554924,
            0.015656763687729836,
            0.0190433282405138,
            0.018579281866550446,
            0.018212057650089264,
            -0.05987223610281944,
            0.016812441870570183,
            0.016107741743326187,
            0.023562537506222725,
            -0.008779349736869335,
            0.013387433253228664,
            -0.03353888541460037,
            -0.05434724688529968,
            0.032111432403326035,
            0.021665599197149277,
            -0.006390128284692764,
            -0.00448709586635232,
            0.034469280391931534,
            0.04401349648833275,
            0.049944158643484116,
            0.05111420154571533,
            -0.047258488833904266,
            -0.009300566278398037,
            -0.03177964314818382,
            -0.06649912893772125,
            -0.03861996531486511,
            -0.039769187569618225,
            0.007346152327954769,
            0.019119396805763245,
            0.022123055532574654,
            -0.009726985357701778,
            -0.015915311872959137,
            0.00911864172667265,
            -0.0014354089507833123,
            -0.028591329231858253,
            0.012490532360970974,
            -0.03553301841020584,
            0.010633567348122597,
            0.007208884693682194,
            0.006358336191624403,
            0.029798319563269615,
            -0.0062863328494131565,
            -0.016637345775961876,
            0.023324495181441307,
            -0.00661180866882205,
            -0.013713723048567772,
            0.0036966102197766304,
            0.007019935641437769,
            -0.0187884122133255,
            -0.03214670717716217,
            0.030413439497351646,
            0.00565160671249032,
            0.021900244057178497,
            0.03860698267817497,
            0.021871648728847504,
            -0.031047411262989044,
            0.033061329275369644,
            0.0028243879787623882,
            -0.02623687870800495,
            0.03461993858218193,
            -0.014488349668681622,
            0.008862077258527279,
            -0.0026941518299281597,
            0.012576589360833168,
            0.014448726549744606,
            0.015673821792006493,
            -0.011346099898219109,
            0.03468216210603714,
            -0.021507341414690018,
            -0.025831451639533043,
            0.019474761560559273,
            -0.035617101937532425,
            -0.016659216955304146,
            0.022507812827825546,
            0.021855633705854416,
            0.009812726639211178,
            -0.009353128261864185,
            0.0140471076592803,
            -0.01195599790662527,
            -0.03058747388422489,
            0.026318641379475594,
            0.024894047528505325,
            -0.03128587454557419,
            -0.009824796579778194,
            -0.015077154152095318,
            0.03759658336639404,
            0.03586229681968689,
            0.01734338141977787,
            0.009564741514623165,
            0.009176306426525116,
            0.00940333865582943,
            -0.007779515814036131,
            0.03323327749967575,
            -0.03257845714688301,
            0.02750154584646225,
            0.004009358584880829,
            0.023843001574277878,
            0.00128774787299335,
            -0.025469385087490082,
            -0.0011423475807532668,
            -0.006867268588393927,
            0.005340095143765211,
            0.00487514678388834,
            0.0036384775303304195,
            0.017942950129508972,
            -0.008081881329417229,
            0.008214333094656467,
            -0.024430466815829277,
            -0.03459840640425682,
            -0.024021467193961143,
            0.008730271831154823,
            0.01914091221988201,
            0.03543977811932564,
            -0.03084748610854149,
            -0.003137399209663272,
            0.030951272696256638,
            0.02057267725467682,
            -0.002712393645197153,
            0.02411525323987007,
            -0.01136518083512783,
            -0.017040586099028587,
            0.005108433309942484,
            -0.03031284175813198,
            0.044893380254507065,
            -0.041743453592061996,
            -0.005118709523230791,
            -0.018224326893687248,
            0.0021588641684502363,
            -0.01328145433217287,
            -0.03894335404038429,
            0.027986576780676842,
            0.04613906145095825,
            -0.02085067518055439,
            0.03435365483164787,
            -0.011663069948554039,
            0.013880417682230473,
            0.005368673242628574,
            -0.014490846544504166,
            -0.0031883122865110636,
            -0.04508233442902565,
            0.0066361380741000175,
            -0.00026180557324551046,
            0.0027573027182370424,
            -0.012660275213420391,
            0.005153955426067114,
            -0.0009539884049445391,
            -0.029057610780000687,
            -0.021007472649216652,
            0.015343951992690563,
            -0.04027622193098068,
            0.03249180689454079,
            0.019739946350455284,
            -0.03370809555053711,
            0.008056361228227615,
            0.009169312193989754,
            0.0286555178463459,
            -0.026413103565573692,
            -0.0009219745988957584,
            -0.008180171251296997,
            -0.0008960723644122481,
            -0.014827577397227287,
            0.013524442911148071,
            -0.010302695445716381,
            0.018473627045750618,
            -0.0011278047459200025,
            0.05433911085128784,
            0.029284901916980743,
            0.004303907975554466,
            0.04596971347928047,
            -0.022286145016551018,
            0.008134362287819386,
            -0.008758089505136013,
            0.030549313873052597,
            -0.05259769409894943,
            0.0033724345266819,
            -0.03326550871133804,
            0.029388366267085075,
            0.010539987124502659,
            -0.019864454865455627,
            -0.023491574451327324,
            -0.030620288103818893,
            0.015122409909963608,
            -0.0005903790588490665,
            0.04093501716852188,
            0.004461192991584539,
            -0.010622779838740826,
            -0.024685505777597427,
            0.03389766439795494,
            0.01478871051222086,
            0.016156403347849846,
            0.020909257233142853,
            0.02557949163019657,
            0.0295309629291296,
            -0.02182338945567608,
            0.0019081973005086184,
            0.0014402446104213595,
            0.008103812113404274,
            -0.019407017156481743,
            0.009248587302863598,
            -0.012738917954266071,
            0.006716625299304724,
            -0.004444735590368509,
            -0.013633711263537407,
            0.01663459837436676,
            -0.025568269193172455,
            0.020107535645365715,
            0.017464229837059975,
            0.012980611063539982,
            0.003853410482406616,
            -0.007138804066926241,
            -0.029288263991475105,
            0.007270568050444126,
            -0.011867267079651356,
            0.016550255939364433,
            0.02962934970855713,
            -0.009341442957520485,
            0.004894050303846598,
            -0.00308564817532897,
            0.00896969623863697,
            -0.008042718283832073,
            0.009535687044262886,
            0.006643505766987801,
            0.017209025099873543,
            0.004772969521582127,
            0.006792034488171339,
            -0.024261243641376495,
            0.04513798654079437,
            0.02775295451283455,
            -0.0011373332235962152,
            -0.0005068081663921475,
            0.0016090653371065855,
            -0.013407470658421516,
            -0.011688190512359142,
            0.019257331266999245,
            -0.0238334983587265,
            0.04175059124827385,
            -0.023791735991835594,
            -0.029489615932106972,
            -0.0351598784327507,
            0.03523382917046547,
            -0.0006598961190320551,
            -0.021611427888274193,
            -0.013880809769034386,
            -0.006502895150333643,
            -0.019702481105923653,
            -0.01651790365576744,
            -0.04143589362502098,
            0.025640740990638733,
            -0.009659788571298122,
            -0.00912527646869421,
            0.02557981200516224,
            0.023930564522743225,
            0.019113145768642426,
            0.01941951923072338,
            0.02303692139685154,
            -0.050385333597660065,
            0.026805654168128967,
            -0.00864627119153738,
            0.02789260819554329,
            -0.035316113382577896,
            0.013508734293282032,
            0.01447693258523941,
            -0.007648860104382038,
            0.004755212459713221,
            -0.020099936053156853,
            -0.006696153897792101,
            -0.030014382675290108,
            -0.02380898781120777,
            0.04552976042032242,
            -0.01097901538014412,
            -0.04958430677652359,
            0.01601485349237919,
            0.04003404080867767,
            -0.019099324941635132,
            -0.029964031651616096,
            -0.002326910151168704,
            0.030655303969979286,
            -0.02833498828113079,
            0.011964919976890087,
            0.02888939343392849,
            0.04733900725841522,
            0.027621211484074593,
            -0.008530089631676674,
            -0.0408950038254261,
            -0.02030658908188343,
            -0.046996477991342545,
            -0.01905609481036663,
            0.002055941615253687,
            -0.011651157401502132,
            -0.02152271382510662,
            -0.014541729353368282,
            0.029431365430355072,
            0.023633122444152832,
            0.024337494745850563,
            -0.0008761280332691967,
            -0.009834094904363155,
            -0.019677378237247467,
            -0.012262394651770592,
            0.01780657470226288,
            -0.001639487803913653,
            -0.0070265852846205235,
            0.005101798102259636,
            -0.05165686085820198,
            -0.029350651428103447,
            0.0016121300868690014,
            0.009372228756546974,
            -0.01544404961168766,
            0.0064702690578997135,
            -0.021477606147527695,
            0.010255949571728706,
            -0.003935841843485832,
            0.01620563119649887,
            -0.006542686838656664,
            0.034680645912885666,
            0.024599477648735046,
            0.01422467827796936,
            -0.005927099846303463,
            -0.009026751853525639,
            0.00837403628975153,
            -0.007140594068914652,
            -0.028763294219970703,
            -0.0228049848228693,
            0.01252944115549326,
            -0.004540049936622381,
            -0.036488745361566544,
            0.0003673324245028198,
            -0.06505906581878662,
            -0.019173596054315567,
            0.012378072366118431,
            0.00026858269120566547,
            -0.02199210226535797,
            0.02023896761238575,
            -0.0017965110018849373,
            0.014569902792572975,
            -0.00617197947576642,
            0.0009883000748232007,
            0.015525088645517826,
            0.015777498483657837,
            -0.0010391168761998415,
            -0.008577614091336727,
            0.011154696345329285,
            -0.05288132652640343,
            -0.037393201142549515,
            -0.009015019983053207,
            -0.026062071323394775,
            0.03487660363316536,
            -0.028258206322789192,
            -0.012860032729804516,
            -0.017914265394210815,
            0.025102166458964348,
            0.014443453401327133,
            0.005789739079773426,
            -0.013586046174168587,
            0.0037007550708949566,
            0.011299784295260906,
            -0.01037269365042448,
            0.004533900413662195,
            -0.020374147221446037,
            -0.028297720476984978,
            -0.00920072477310896,
            -0.03224336355924606,
            0.01635606959462166,
            -0.009614504873752594,
            0.009019263088703156,
            -0.007596319541335106,
            -0.033750467002391815,
            0.027069320902228355,
            -0.023795058950781822,
            -0.005804937798529863,
            0.011614616960287094,
            -0.006281511392444372,
            0.0010295863030478358,
            -0.011626646853983402,
            -0.0017244856571778655,
            0.012219847179949284,
            0.020519353449344635,
            -0.033606160432100296,
            -0.008610748685896397,
            0.04628611356019974,
            -0.011822642758488655,
            0.004731405526399612,
            -0.007104293443262577,
            -0.022651417180895805,
            0.007029498927295208,
            0.0006219135248102248,
            -0.01112725306302309,
            0.032971497625112534,
            -0.01590440422296524,
            -0.012607681564986706,
            0.027601104229688644,
            0.011360368691384792,
            -0.02077200450003147,
            -0.0016937315231189132,
            0.007703459355980158,
            -0.0014805275714024901,
            0.019707422703504562,
            0.02059081383049488,
            0.005181286484003067,
            0.0036564916372299194,
            0.000559643842279911,
            0.02668060176074505,
            0.032075103372335434
        ],
        [
            -0.010381508618593216,
            -0.01795269548892975,
            -0.02016504853963852,
            -0.015278605744242668,
            -0.014138326980173588,
            0.03153121471405029,
            -0.022200563922524452,
            0.01055176742374897,
            0.0031918876338750124,
            0.025224197655916214,
            -0.00032199505949392915,
            0.02717043273150921,
            -0.014244919642806053,
            -0.03137346729636192,
            -0.05598330870270729,
            0.05854048207402229,
            -0.004909213166683912,
            0.01691298559308052,
            -0.004416227340698242,
            0.02835051715373993,
            -0.014331929385662079,
            -0.010102100670337677,
            0.006441021338105202,
            -0.04153016209602356,
            -0.011721091344952583,
            -0.03646137937903404,
            0.0065646483562886715,
            0.030667778104543686,
            0.02794245071709156,
            -0.04077451676130295,
            0.005323147866874933,
            -0.007869524881243706,
            -0.034591857343912125,
            -0.01529686339199543,
            0.00787154957652092,
            -0.008929679170250893,
            0.01765199564397335,
            -0.01624324358999729,
            -0.0022889557294547558,
            0.005657803732901812,
            -0.02640540339052677,
            -0.0051167551428079605,
            0.027796272188425064,
            -0.028165707364678383,
            0.034266699105501175,
            0.010915020480751991,
            0.025963526219129562,
            0.010583831928670406,
            0.019358914345502853,
            0.0227303858846426,
            -0.05033614858984947,
            -0.017890596762299538,
            -0.028407514095306396,
            -0.00501444935798645,
            -0.027950530871748924,
            0.017279023304581642,
            0.026996033266186714,
            -0.024414081126451492,
            -0.025573858991265297,
            0.027934188023209572,
            0.009056954644620419,
            -0.0019987081177532673,
            -0.008230923675000668,
            0.032217834144830704,
            -0.009659521281719208,
            0.027174126356840134,
            0.07557031512260437,
            -0.0038024522364139557,
            -0.014271456748247147,
            0.02933477982878685,
            -0.021293343976140022,
            0.015807021409273148,
            -0.01682673953473568,
            -0.008062487468123436,
            -0.016586005687713623,
            0.02897479385137558,
            0.023057613521814346,
            -0.00505048967897892,
            -0.06294915080070496,
            0.00030005621374584734,
            0.013848689384758472,
            -0.033992353826761246,
            -0.005105540622025728,
            -0.013809283263981342,
            -0.02561935968697071,
            0.00016270502237603068,
            0.02301104925572872,
            0.02233361452817917,
            -0.011261682957410812,
            -0.03914369270205498,
            0.031130537390708923,
            -0.0030580954626202583,
            -0.04587554931640625,
            -0.011671598069369793,
            -0.004024018533527851,
            -0.01556931808590889,
            -0.009171157144010067,
            -0.044907357543706894,
            0.0772041529417038,
            -0.01277195755392313,
            0.009888947941362858,
            -0.026757245883345604,
            0.024926356971263885,
            -0.02385972999036312,
            0.01109104324132204,
            0.028065795078873634,
            -0.015889449045062065,
            -0.031104283407330513,
            -0.00942192878574133,
            0.024770909920334816,
            -0.0023683335166424513,
            0.031146058812737465,
            0.005874037276953459,
            -0.03181064873933792,
            0.06236913055181503,
            -0.015774119645357132,
            -0.00853955838829279,
            -0.026942571625113487,
            0.02416175790131092,
            -0.011529640294611454,
            -0.0065335603430867195,
            0.021627184003591537,
            -0.014432703144848347,
            -0.01519582699984312,
            0.007116706110537052,
            0.008464719168841839,
            -0.012970863841474056,
            -0.02113879658281803,
            -0.02719748578965664,
            -0.029554562643170357,
            -0.016372257843613625,
            -0.010287796147167683,
            -0.06268975138664246,
            -0.023692883551120758,
            0.03688550740480423,
            0.0017703830963000655,
            -0.062358178198337555,
            -0.033896394073963165,
            -0.011917917989194393,
            -0.05773331969976425,
            -0.00793113186955452,
            -0.01558014377951622,
            -0.050638969987630844,
            0.011073578149080276,
            -0.004323302302509546,
            0.020069334656000137,
            -0.001965791219845414,
            0.015123788267374039,
            0.01169362012296915,
            0.0038205611053854227,
            -0.02696717157959938,
            -0.019908573478460312,
            -0.0066735162399709225,
            -0.034704990684986115,
            -0.026351500302553177,
            0.03519325330853462,
            0.02192281186580658,
            -0.019190754741430283,
            -0.007359744980931282,
            -0.05106007680296898,
            -0.0025581042282283306,
            -0.03336802497506142,
            0.022764956578612328,
            0.00952985230833292,
            -0.03249306604266167,
            -0.03474486619234085,
            -0.0347820483148098,
            -0.014911988750100136,
            0.012052226811647415,
            0.014161333441734314,
            -0.024419445544481277,
            -0.03778436407446861,
            0.018841298297047615,
            0.008181117475032806,
            -0.00965890847146511,
            -0.016000377014279366,
            0.01425975002348423,
            0.0027693607844412327,
            0.01263649296015501,
            -0.0007096188492141664,
            0.013225721195340157,
            -0.01664995215833187,
            -0.015596501529216766,
            0.03011249378323555,
            -0.020690180361270905,
            0.024204086512327194,
            -0.006580064073204994,
            0.006320742890238762,
            -0.007769950199872255,
            0.023420672863721848,
            -0.02607767842710018,
            -0.0042808218859136105,
            -0.060045719146728516,
            0.00022535267635248601,
            -0.007656644098460674,
            0.01700335182249546,
            0.024652808904647827,
            -0.006781092379242182,
            -0.021861698478460312,
            -0.033267632126808167,
            0.014780008234083652,
            0.026920504868030548,
            -0.004052411764860153,
            0.010288727469742298,
            0.031056489795446396,
            0.006368809379637241,
            -0.002946365624666214,
            -0.025081757456064224,
            0.0056373244151473045,
            -0.010554499924182892,
            -0.04601612687110901,
            -0.016653425991535187,
            -0.02485472522675991,
            0.0007306128973141313,
            -0.020572001114487648,
            -0.008488420397043228,
            0.03392307087779045,
            -0.0010395555291324854,
            -9.713211329653859e-05,
            0.013540034182369709,
            0.0007106061093509197,
            -0.0003200863429810852,
            0.0022650323808193207,
            0.012989611364901066,
            -0.02919095754623413,
            0.027857987210154533,
            -0.01652664877474308,
            0.002805786207318306,
            0.02613099478185177,
            0.03419625386595726,
            -0.00472825625911355,
            -0.026753051206469536,
            -0.04092144966125488,
            0.0011519003892317414,
            0.027191106230020523,
            -0.029827117919921875,
            -0.013787331059575081,
            -0.02021884359419346,
            0.018227064982056618,
            -0.0016206054715439677,
            -0.008467921987175941,
            -0.030019938945770264,
            0.03095119260251522,
            -0.0011985324090346694,
            -0.05020761117339134,
            0.01072738878428936,
            -0.009321523830294609,
            0.020780595019459724,
            -0.009587832726538181,
            -0.011835254728794098,
            0.028492361307144165,
            -0.014924964867532253,
            -0.016261102631688118,
            -0.048834532499313354,
            0.0050576673820614815,
            0.03387054055929184,
            0.012647830881178379,
            -0.015137099660933018,
            -0.03394971042871475,
            0.009778417646884918,
            0.018495067954063416,
            -0.03047889843583107,
            -0.04166649654507637,
            0.03447667881846428,
            0.01606380008161068,
            -0.02245674841105938,
            -0.0075151617638766766,
            -0.008011176250874996,
            -0.0017679405864328146,
            -0.02116880565881729,
            0.020058628171682358,
            -0.01672527752816677,
            -0.01401417888700962,
            0.03096531517803669,
            -0.015365729108452797,
            0.010120580904185772,
            -0.014533398672938347,
            0.012745305895805359,
            -0.005852682050317526,
            -0.017535284161567688,
            0.027166035026311874,
            -0.04116109386086464,
            0.019500430673360825,
            0.055229928344488144,
            -0.011405344121158123,
            -0.0048650405369699,
            0.006196556147187948,
            -0.010075932368636131,
            -0.033372871577739716,
            0.01824384555220604,
            -0.0018794817151501775,
            0.016829095780849457,
            0.002725126687437296,
            -0.010279976762831211,
            -0.03347325325012207,
            0.016677342355251312,
            -0.00467376783490181,
            -0.013771990314126015,
            0.026641428470611572,
            0.031099561601877213,
            -0.007257016841322184,
            0.03280272334814072,
            -0.006946261040866375,
            -0.033815208822488785,
            -0.007615300826728344,
            -0.013921966776251793,
            0.019842125475406647,
            -0.06646735221147537,
            -0.020494654774665833,
            0.029253467917442322,
            -0.03972184658050537,
            -0.018232420086860657,
            0.015427912585437298,
            -0.006178697571158409,
            -0.0215794425457716,
            0.03892016038298607,
            -0.011805628426373005,
            0.00769410515204072,
            -0.026177408173680305,
            -0.02081896737217903,
            -0.00208452926017344,
            -0.004093007184565067,
            -0.022419726476073265,
            0.011488130316138268,
            0.02391505241394043,
            -0.013364240527153015,
            -0.001996399834752083,
            0.0039393105544149876,
            0.014985507354140282,
            0.0011636960553005338,
            0.028980812057852745,
            -0.0032709406223148108,
            -0.0316333994269371,
            0.012750398367643356,
            0.026268450543284416,
            -0.04817203804850578,
            -0.006133588962256908,
            0.020025193691253662,
            -0.03107397072017193,
            -0.0013051668647676706,
            -0.008690953254699707,
            -0.0306947473436594,
            0.024440335109829903,
            0.006133606657385826,
            0.00818443764001131,
            -0.025493476539850235,
            0.015980763360857964,
            0.0035835690796375275,
            -0.014854741282761097,
            0.004512642975896597,
            -0.02539190463721752,
            -0.040606576949357986,
            0.009138938970863819,
            -0.014282452873885632,
            -0.018725601956248283,
            0.028074128553271294,
            -0.005617282819002867,
            -0.010437700897455215,
            -0.002037636237218976,
            0.007182896137237549,
            -0.02097504399716854,
            -0.0007153167971409857,
            0.005041272379457951,
            0.013172535225749016,
            -0.038160908967256546,
            -0.0015846395399421453,
            0.03120206482708454,
            0.0013966499827802181,
            0.020378651097416878,
            0.025162767618894577,
            0.017130054533481598,
            -0.0009515467681922019,
            -0.02112726867198944,
            0.011889833025634289,
            0.034079425036907196,
            -0.06458980590105057,
            0.022353770211338997,
            -0.035817135125398636,
            0.02986573427915573,
            -0.001477368758060038,
            -0.007697966881096363,
            -0.0061041004955768585,
            0.009180319495499134,
            -0.009477938525378704,
            0.028326798230409622,
            -0.034236181527376175,
            0.021064190194010735,
            -0.012312515638768673,
            -0.02441375143826008,
            0.007335847709327936,
            4.027686009067111e-05,
            -0.00018515402916818857,
            -0.013163432478904724,
            0.018496479839086533,
            0.007577916607260704,
            0.021475685760378838,
            -0.011354762129485607,
            0.027969250455498695,
            0.02810646966099739,
            -0.02703561633825302,
            -0.016392763704061508,
            0.024208983406424522,
            -0.014856125228106976,
            0.005985584110021591,
            -0.030084943398833275,
            0.008346465416252613,
            0.013176650740206242,
            0.0005458742380142212,
            0.01903478056192398,
            -0.015254627913236618,
            0.02710409089922905,
            -0.001314951223321259,
            0.013406788930296898,
            -0.003549593035131693,
            -0.034436989575624466,
            -0.005014553666114807,
            0.01680944487452507,
            0.01963501051068306,
            -0.05093343183398247,
            0.01721172407269478,
            0.022581877186894417,
            -0.0334971658885479,
            0.0035566494334489107,
            -0.008782449178397655,
            0.025718482211232185,
            0.041245780885219574,
            0.008712423965334892,
            -0.05697163939476013,
            -0.014216883108019829,
            0.01491736900061369,
            0.003911199048161507,
            0.015325238928198814,
            -0.0015696319751441479,
            -0.04625740647315979,
            0.019171815365552902,
            -0.0034590198192745447,
            0.019348355010151863,
            -0.012551330029964447,
            0.015592915005981922,
            -0.01845720037817955,
            -0.0050974697805941105,
            -0.0031390453223139048,
            0.008141380734741688,
            -0.021225450560450554,
            -0.006654068361967802,
            0.029213346540927887,
            0.019162090495228767,
            -0.07232064008712769,
            0.005431474652141333,
            -0.004083320498466492,
            0.03630942851305008,
            -0.0012375186197459698,
            0.03356964513659477,
            -0.03436348959803581,
            0.025495387613773346,
            -0.012934079393744469,
            0.01681394875049591,
            0.01631690002977848,
            0.009788627736270428,
            -0.0036306765396147966,
            -0.01351540070027113,
            -0.0003306088037788868,
            -0.007365844678133726,
            0.02184208482503891,
            -0.02975066937506199,
            0.01999705284833908,
            0.03086770512163639,
            -0.0199386365711689,
            -0.005698170978575945,
            0.0033073893282562494,
            -0.014135567471385002,
            0.03572848066687584,
            -0.011182627640664577,
            -0.02041439525783062,
            -0.006044999696314335,
            0.02868664637207985,
            -0.01846475712954998,
            0.03523904085159302,
            0.03472486138343811,
            -0.08481535315513611,
            0.0019518989138305187,
            0.011317645199596882,
            -0.05404032766819,
            0.005295789800584316,
            0.014634761027991772,
            0.008871073834598064,
            -0.010126865468919277,
            0.026895301416516304,
            -0.0710877925157547,
            0.022573038935661316,
            0.019326409325003624,
            0.0320182740688324,
            0.02078908123075962,
            -0.0027095619589090347,
            0.006540860049426556,
            -0.032395970076322556,
            0.042867228388786316,
            0.0017317809397354722,
            -0.013999846763908863,
            -0.027618709951639175,
            0.0058469087816774845,
            -0.06920156627893448,
            -0.00339196203276515,
            -0.0030331674497574568,
            -0.0041506034322083,
            -0.021966248750686646,
            -0.03575200214982033,
            -0.0016879384638741612,
            -0.03589792922139168,
            0.026051456108689308,
            0.010878105647861958,
            -0.03289585933089256,
            0.014422286301851273,
            -0.05369460582733154,
            -0.009051311761140823,
            0.03466695174574852,
            0.045650459825992584,
            -0.020979201421141624,
            0.033150382339954376,
            -0.005372608546167612,
            -0.028575748205184937,
            -0.011435710825026035,
            -0.01624462381005287,
            0.00048554784734733403,
            -0.01902916096150875,
            0.0007980966474860907,
            -0.02347361482679844,
            0.006301671266555786,
            0.014783551916480064,
            0.0076307132840156555,
            0.01589260622859001,
            0.022772232070565224,
            0.011552275158464909,
            0.0071238502860069275,
            -0.0215983334928751,
            -0.014728219248354435,
            -0.021338509395718575,
            -0.022623078897595406,
            -0.023190150037407875,
            0.025300603359937668,
            -0.017298832535743713,
            -0.012895707041025162,
            0.00033931955113075674,
            0.00852765329182148,
            0.01692967303097248,
            -0.004988282453268766,
            -0.03131622448563576,
            0.010915109887719154,
            -0.03507041558623314,
            0.0020719761960208416,
            0.03561053425073624,
            -0.03330659866333008,
            0.026962660253047943,
            0.009844672866165638,
            0.010576453991234303,
            0.021243538707494736,
            -0.01202753558754921,
            -0.01733187772333622,
            0.020633326843380928,
            -0.009633694775402546,
            0.011387796141207218,
            -0.015164775773882866,
            0.010951723903417587,
            -0.012611297890543938,
            -0.018977267667651176,
            0.04149087518453598,
            -0.0053549278527498245,
            0.014665142633020878,
            0.03803445026278496,
            -0.013312597759068012,
            -0.007606556173413992,
            0.029145583510398865,
            -0.02085178904235363,
            0.028761187568306923,
            -0.013325783424079418,
            0.0357554592192173,
            0.01367186475545168,
            -0.0005677674780599773,
            -0.012736828997731209,
            0.014031353406608105,
            0.037290968000888824,
            -0.004880544729530811,
            0.025834688916802406,
            0.008707760833203793,
            -0.011921794153749943,
            -0.02785434201359749,
            0.02390332706272602,
            -0.008829810656607151,
            0.004799323622137308,
            0.03406335413455963,
            0.012318888679146767,
            -0.04752184450626373,
            0.014270666055381298,
            -0.01186319999396801,
            0.03141450136899948,
            0.0014477564254775643,
            -0.00959651730954647,
            0.02513967454433441,
            -0.007706955075263977,
            -0.02981683425605297,
            0.02877025306224823,
            0.013881273567676544,
            -0.0020383496303111315,
            -0.008984686806797981,
            0.002955740550532937,
            -0.020173925906419754,
            0.014643832109868526,
            0.00885970052331686,
            -0.013400830328464508,
            0.03321303799748421,
            -0.0038273760583251715,
            -0.018699029460549355,
            0.03327721357345581,
            0.02906973287463188,
            0.029456792399287224,
            0.007784539368003607,
            0.01434754766523838,
            -0.03899254649877548,
            -0.006771626882255077,
            0.0311288945376873,
            -0.017316909506917,
            -0.0065979864448308945,
            0.014994695782661438,
            0.012940010987222195,
            0.025706302374601364,
            -0.01603785715997219,
            0.0021622288040816784,
            -0.04371095448732376,
            -0.02756425365805626,
            0.003315209411084652,
            0.00411040848121047,
            -0.002768444363027811,
            0.021151136606931686,
            -0.0073570264503359795,
            0.02389753796160221,
            -0.024642137810587883,
            0.03604216128587723,
            0.028159819543361664,
            0.02663653902709484,
            0.02089635282754898,
            0.034459665417671204,
            -0.02592155523598194,
            -0.01754007115960121,
            -0.04752866551280022,
            -0.0009436672553420067,
            -0.015643488615751266,
            -0.020426956936717033,
            0.01084567978978157,
            0.0006928647635504603,
            -0.012435478158295155,
            -0.014330882579088211,
            0.023619873449206352,
            0.022003963589668274,
            -0.026050306856632233,
            0.025597238913178444,
            -0.008707009255886078,
            -0.025741050019860268,
            0.02938425913453102,
            -0.014117746613919735,
            0.0074250646866858006,
            0.017658716067671776,
            0.011637006886303425,
            -0.015906406566500664,
            -0.02298790030181408,
            0.010137428529560566,
            0.0035830808337777853,
            0.02689182572066784,
            0.02322460524737835,
            0.011123262345790863,
            0.016431448981165886,
            0.025526151061058044,
            -0.01645759679377079,
            0.032500285655260086,
            0.03145303949713707,
            -0.005747525952756405,
            -0.002944256877526641,
            0.03063592128455639,
            0.009730592370033264,
            -0.025633899495005608,
            -0.004332666750997305,
            0.028138883411884308,
            -0.026751991361379623,
            0.03123527206480503,
            -0.01109262090176344,
            0.01202906109392643,
            -0.007820536382496357,
            -0.05075675621628761,
            -0.015934454277157784,
            0.0032050716690719128,
            0.004113514441996813,
            0.030195344239473343,
            0.024863306432962418,
            -0.0014453032054007053,
            -0.010172612965106964,
            0.018850287422537804,
            -0.02973213605582714,
            -0.018819859251379967,
            0.0245373398065567,
            0.019389431923627853,
            -0.001669994555413723,
            0.0024946376215666533,
            -0.00432915473356843,
            -0.020194295793771744,
            0.020870862528681755,
            -0.003566879080608487,
            -0.03338019922375679,
            0.014545469544827938,
            0.008037441410124302,
            0.00719332043081522,
            -0.021890198811888695,
            0.0002987597545143217,
            -0.019543208181858063,
            0.018657919019460678,
            -0.001976334024220705,
            -0.023770619183778763,
            -0.012363960966467857,
            0.07190035283565521,
            -0.0457448847591877,
            -0.02470974810421467,
            0.0042381491512060165,
            0.003649448975920677,
            0.016954364255070686,
            -0.00267535587772727,
            0.0338045135140419,
            0.0006792740896344185,
            0.0003271701280027628,
            -0.0019113088492304087,
            0.019246308133006096,
            0.016278032213449478,
            0.008112002164125443,
            -0.019014570862054825,
            -0.006087883375585079,
            0.006492001935839653,
            -0.019013097509741783,
            -0.0003994015569332987,
            -0.0065599121153354645,
            -0.012874833308160305,
            -0.01253447961062193,
            -0.0020579686388373375,
            -0.06742974370718002,
            0.02768494188785553,
            -0.0009611471323296428,
            -0.011078462935984135,
            0.017105571925640106,
            0.02964787930250168,
            0.00012115213758079335,
            -0.008460490964353085,
            -0.024821575731039047,
            0.0011124523589387536,
            -0.0051751695573329926,
            0.029063958674669266,
            -0.02912074699997902,
            0.03366047888994217,
            -0.041187629103660583,
            0.0033720762003213167,
            0.005499078892171383,
            -0.005416828207671642,
            -0.00603006174787879,
            -0.01238873228430748,
            0.0268249548971653,
            -0.03870175778865814,
            0.01754351519048214,
            0.018670132383704185,
            0.02203603833913803,
            -0.02705477923154831,
            -0.0036460065748542547,
            -0.016832349821925163,
            0.02542109601199627,
            -0.024411503225564957,
            -0.03952324762940407,
            -0.02503063715994358,
            -0.007673169020563364,
            -0.012820413336157799,
            -0.04693024978041649,
            0.029685072600841522,
            -0.04657650366425514,
            0.006471173372119665,
            -0.04720579832792282,
            -0.0019241839181631804,
            0.015026122331619263,
            -0.018996985629200935,
            0.008952721953392029,
            -0.004696960560977459,
            -0.00795204471796751,
            0.01069764606654644,
            -0.0008864974952302873,
            -0.020205894485116005,
            0.012732750736176968,
            -0.0012841725256294012,
            -0.014807584695518017,
            0.00022888831153977662,
            -0.022123534232378006,
            0.0427761971950531,
            0.020970841869711876,
            -0.0231610294431448,
            -0.01539281290024519,
            0.019569260999560356,
            0.01187488716095686,
            0.0052891387604177,
            -0.0013027047971263528,
            -0.011457189917564392,
            -0.0033158401492983103,
            0.0011442156974226236,
            -0.0038809501565992832,
            -0.057096563279628754,
            0.012257075868546963,
            -0.00010530922736506909,
            -0.0038515774067491293,
            0.015521440654993057,
            -5.791967123514041e-05,
            -0.00039505810127593577,
            -0.034002289175987244,
            0.008195235393941402,
            -0.020262552425265312,
            -0.025620637461543083,
            0.025985026732087135,
            -0.0036560185253620148,
            0.023617155849933624,
            0.022858722135424614,
            0.020509105175733566,
            -0.04172372817993164,
            -0.042213067412376404,
            0.01175222359597683,
            -0.00035677605774253607,
            -0.004606929607689381,
            0.006045592483133078,
            0.024956336244940758,
            -0.013882992789149284,
            0.033873967826366425,
            0.012677637860178947,
            -0.0005846660933457315,
            -0.014968919567763805,
            -0.02158958837389946,
            0.0015515966806560755,
            -0.009497582912445068,
            -0.015833524987101555,
            -0.00808108039200306,
            -0.0005530999042093754,
            0.03622310236096382,
            -0.004532236605882645,
            0.016552375629544258,
            -0.022365791723132133,
            -0.031074142083525658,
            0.02497696503996849,
            0.021290248259902,
            -0.01041478756815195,
            -0.012523556128144264,
            -0.008506343699991703,
            0.02003619261085987,
            0.014221123419702053,
            -0.025603225454688072,
            -0.004849292803555727,
            -0.02831171825528145,
            -0.01857898384332657,
            -0.0011008557630702853,
            0.01930680125951767,
            -0.03620554879307747,
            -0.013286195695400238,
            -0.006473917979747057,
            0.006748138926923275,
            -0.007308431901037693,
            -0.001928819459863007,
            0.027483411133289337,
            -0.053764890879392624,
            -0.04115902632474899,
            -0.014702145010232925,
            -0.004355276003479958,
            -0.0031390462536364794,
            -0.021768581122159958,
            -0.009325308725237846,
            0.012562979944050312,
            -0.013763071037828922,
            0.02924571931362152,
            -0.002276758197695017,
            -0.03014342486858368,
            0.04043713957071304,
            0.02938559092581272,
            -0.048884134739637375,
            -0.026749998331069946,
            0.0260186567902565,
            0.04155057668685913,
            -0.011138693429529667,
            0.02112925425171852,
            0.017529550939798355,
            0.006691164802759886,
            0.008002209477126598,
            0.019755493849515915,
            0.05586732178926468,
            -0.0013451535487547517,
            0.019181154668331146,
            0.010048633441329002,
            0.010859881527721882,
            -0.010211439803242683,
            0.0194106288254261,
            -0.0010045238304883242,
            -0.0030878812540322542,
            0.009126272052526474,
            -0.02431940659880638,
            0.004660679958760738,
            0.02284916117787361,
            0.010250394232571125,
            0.01771990954875946,
            0.03379921615123749,
            0.029325930401682854,
            0.006675356067717075,
            0.016854051500558853,
            -0.020626142621040344,
            0.030254961922764778,
            -0.005504167173057795,
            -0.012389464303851128,
            0.005271649453788996,
            0.010023479349911213,
            0.01958557777106762,
            -0.03920046240091324,
            0.01391646545380354,
            0.02463507279753685,
            -0.05326780304312706,
            -0.023337140679359436,
            -0.018722552806138992,
            -0.023724734783172607,
            -0.02483707293868065,
            -0.013182610273361206,
            0.0009517690050415695,
            -0.022165359929203987,
            0.030783381313085556,
            -0.02252589352428913,
            0.021882623434066772,
            -0.0031766784377396107,
            -0.0021214752923697233,
            -0.024083493277430534,
            0.011048730462789536,
            -0.029787853360176086,
            -0.027623362839221954,
            -0.023417048156261444,
            -0.05621560662984848,
            -0.0011925904545933008,
            0.004196783062070608,
            0.010547365061938763,
            -0.010721207596361637,
            -0.03535696491599083,
            -0.009147623553872108,
            -0.02580810897052288,
            -0.018253374844789505,
            -0.01643328182399273,
            -0.03727363422513008,
            -0.01831192336976528,
            -0.025296488776803017,
            0.024031978100538254,
            -0.05957088619470596,
            0.028037315234541893,
            0.006294617895036936,
            -0.0022021380718797445,
            0.008498345501720905,
            0.03161093592643738,
            0.006643646862357855,
            -0.0006788074970245361,
            -0.017925189808011055,
            0.002734825015068054,
            0.03181305155158043,
            -0.031314752995967865,
            -0.008775950409471989,
            -0.0012819787953048944,
            -0.023626765236258507,
            0.003581596538424492,
            -0.04515698924660683,
            -0.0200016051530838,
            -0.05477523058652878,
            0.029437733814120293,
            0.012631502002477646,
            0.018291465938091278,
            -0.026990927755832672,
            0.02854740247130394,
            -0.02429485134780407,
            -0.0250060074031353,
            -0.03988303989171982,
            -0.006775532383471727,
            -0.03334248811006546,
            0.018225084990262985,
            0.03130827844142914,
            0.023640600964426994,
            0.024089623242616653,
            0.02826758101582527,
            -0.00475703040137887,
            -0.03125103563070297,
            -0.006075515877455473,
            -0.00856817327439785,
            -0.005135864485055208,
            -0.01875309646129608,
            0.011700106784701347,
            -0.016490109264850616,
            0.03747890144586563,
            0.016931133344769478,
            0.037887394428253174,
            0.020782174542546272,
            0.025637874379754066,
            -0.03454640880227089,
            0.02036011964082718,
            -0.013843189924955368,
            0.0026116985827684402,
            0.00466008810326457,
            0.00999471079558134,
            -0.027592070400714874,
            -0.016139555722475052,
            -0.005759498104453087,
            0.004710210021585226,
            -0.0013859086902812123,
            0.018170300871133804,
            -0.019521858543157578,
            -0.024684743955731392,
            -0.02766483835875988,
            0.018771030008792877,
            -0.0029250578954815865,
            -0.0016107124974951148,
            0.027688488364219666,
            -0.045907601714134216,
            -0.030016081407666206,
            -0.015332616865634918,
            -0.012020603753626347,
            -0.01699470728635788,
            0.004343561828136444,
            -0.03230956941843033,
            -0.014622431248426437,
            0.021402699872851372,
            0.003066537668928504
        ],
        [
            -0.0025497684255242348,
            0.015506746247410774,
            0.031802330166101456,
            0.0046394988894462585,
            -0.015733176842331886,
            0.019982224330306053,
            0.028550434857606888,
            -0.009912299923598766,
            0.0070405094884335995,
            -0.015016237273812294,
            0.018753739073872566,
            0.039429035037755966,
            -0.023194847628474236,
            -0.008961226791143417,
            -0.0024094637483358383,
            0.052922360599040985,
            0.014511128887534142,
            -0.013031364418566227,
            0.006618135143071413,
            -0.026930727064609528,
            -0.002345741493627429,
            -0.026621034368872643,
            -0.0026298747397959232,
            -0.007260309066623449,
            -0.006888942793011665,
            -0.028284285217523575,
            0.013263782486319542,
            -0.003545619547367096,
            0.023151889443397522,
            -0.019789116457104683,
            -0.03190842643380165,
            -0.0022252369672060013,
            0.005560422316193581,
            -0.012198179960250854,
            -0.03051346354186535,
            0.02451915666460991,
            0.02284306474030018,
            0.030331594869494438,
            0.019227011129260063,
            -0.009799321182072163,
            -0.011207870207726955,
            0.010714775882661343,
            0.02930300310254097,
            0.007741418667137623,
            -0.01389512699097395,
            0.0333353653550148,
            -0.010305405594408512,
            0.004387946799397469,
            0.004956310149282217,
            0.02789226360619068,
            0.014382682740688324,
            0.009727324359118938,
            0.008017062209546566,
            0.01060087326914072,
            -0.0018317337380722165,
            -0.0012994715943932533,
            0.019820913672447205,
            0.02328275889158249,
            0.020829938352108,
            0.020321479067206383,
            0.04116660729050636,
            -0.020039157941937447,
            0.010066172108054161,
            0.029271773993968964,
            0.02368571236729622,
            0.024672310799360275,
            0.03414357453584671,
            0.007645541802048683,
            0.007008146960288286,
            0.01518044713884592,
            -0.02546161599457264,
            -0.013904685154557228,
            0.023630036041140556,
            0.005662726704031229,
            0.00954479444772005,
            0.020507868379354477,
            -0.021904483437538147,
            0.027092738077044487,
            -0.003931949380785227,
            0.014692779630422592,
            -0.014640126377344131,
            -0.031808022409677505,
            -0.023476451635360718,
            -0.01755039393901825,
            0.00405263202264905,
            0.03353535756468773,
            0.027092278003692627,
            0.0014270670944824815,
            -0.01117037609219551,
            0.026064394041895866,
            -0.030674636363983154,
            0.001993004232645035,
            -0.009569386020302773,
            -0.017681855708360672,
            0.011064564809203148,
            0.013387998566031456,
            -0.004229231737554073,
            -0.0097267534583807,
            -0.025987762957811356,
            -0.016315579414367676,
            -0.019088027998805046,
            0.023484207689762115,
            -0.0022285699378699064,
            0.03098660707473755,
            0.02626294642686844,
            0.028614787384867668,
            0.0011713509447872639,
            0.024689236655831337,
            0.03599782660603523,
            0.028182247653603554,
            -0.00035988297895528376,
            -0.01063330378383398,
            0.03701169043779373,
            -0.0008997365366667509,
            0.0243989285081625,
            -0.01744716987013817,
            -0.010640265420079231,
            0.02269717864692211,
            0.003481747815385461,
            -0.018156182020902634,
            -0.008960316888988018,
            -0.017783161252737045,
            0.04380285367369652,
            0.031247863546013832,
            0.006314615719020367,
            0.018135270103812218,
            0.017317499965429306,
            0.021707814186811447,
            0.015548893250524998,
            0.027443047612905502,
            -0.018330931663513184,
            -0.027689361944794655,
            0.04875845089554787,
            0.03700738027691841,
            -0.027210688218474388,
            0.013870572671294212,
            0.012145021930336952,
            -0.028330743312835693,
            -0.021320970728993416,
            0.007982060313224792,
            -0.0209698136895895,
            0.010926431976258755,
            0.005351796746253967,
            -0.018589908257126808,
            0.022620368748903275,
            0.0257748793810606,
            0.007945247925817966,
            -0.0010623835260048509,
            0.015853967517614365,
            0.015580597333610058,
            0.026447081938385963,
            -0.014886651188135147,
            -0.016297582536935806,
            0.002281643683090806,
            0.022758014500141144,
            -0.03664492815732956,
            -0.02065247856080532,
            -0.02148553356528282,
            0.01768558844923973,
            -0.010534657165408134,
            -0.024474259465932846,
            -0.010465976782143116,
            0.007052311673760414,
            0.04066195338964462,
            -0.005060460884124041,
            -0.008088706061244011,
            0.005579058546572924,
            -0.010948730632662773,
            -0.04761718958616257,
            -0.012545309029519558,
            -0.007113107480108738,
            -0.015726378187537193,
            -0.019010094925761223,
            -0.036958128213882446,
            0.021810468286275864,
            0.0260598286986351,
            0.02374819666147232,
            0.01202858705073595,
            -0.009493260644376278,
            -0.0056477999314665794,
            -0.005213318392634392,
            -0.02287660352885723,
            0.004918352235108614,
            0.020322801545262337,
            -0.005354241468012333,
            -0.012972424738109112,
            0.01634134352207184,
            0.016693297773599625,
            0.012059581466019154,
            0.011373809538781643,
            -0.006518456619232893,
            -0.0020014268811792135,
            -0.004430284257978201,
            0.0006469984073191881,
            0.0048589203506708145,
            -0.029475579038262367,
            0.033512648195028305,
            -0.013853400014340878,
            0.013804894872009754,
            0.033672917634248734,
            0.031147325411438942,
            0.01659536361694336,
            -0.014100570231676102,
            0.017629973590373993,
            0.0315881185233593,
            -0.004324596840888262,
            0.0061232601292431355,
            -0.006673621945083141,
            0.01315129455178976,
            0.022281719371676445,
            0.016304826363921165,
            0.0009859385900199413,
            0.012129850685596466,
            -0.01241970993578434,
            -0.013345138169825077,
            0.021165234968066216,
            -0.013595905154943466,
            -0.00024324732657987624,
            0.02032702974975109,
            0.027834247797727585,
            -0.0034362319856882095,
            -0.022500932216644287,
            0.006869500502943993,
            0.008911684155464172,
            -0.004088892601430416,
            0.009950721636414528,
            0.007422314491122961,
            0.032002437859773636,
            -0.01225472241640091,
            -0.009439466521143913,
            0.008802931755781174,
            -0.03749668970704079,
            -0.009019728749990463,
            -0.019169563427567482,
            0.012412925250828266,
            -0.0023613963276147842,
            0.021894043311476707,
            0.03519255667924881,
            0.030471600592136383,
            0.032713137567043304,
            0.018270164728164673,
            -0.00312650203704834,
            -0.018032453954219818,
            -0.0014025779673829675,
            -0.025374609977006912,
            0.02825305052101612,
            0.01749945618212223,
            -0.012411577627062798,
            -0.013707628473639488,
            0.037993256002664566,
            -0.009783859364688396,
            -0.0016858330927789211,
            -0.0032638022676110268,
            0.003583244513720274,
            0.012631944380700588,
            0.031622253358364105,
            -0.019526898860931396,
            0.015859661623835564,
            0.045702047646045685,
            0.013555208221077919,
            0.005106610711663961,
            -0.011563321575522423,
            -0.0092376209795475,
            0.029527630656957626,
            0.027893725782632828,
            0.020814916118979454,
            0.031032893806695938,
            0.03332337737083435,
            -0.033142998814582825,
            0.010349281132221222,
            -0.016395995393395424,
            -0.019765790551900864,
            0.02894539013504982,
            -0.025623494759202003,
            0.01097148284316063,
            -0.007616163697093725,
            0.008366559632122517,
            0.029029298573732376,
            0.02780321054160595,
            0.0230922382324934,
            -0.005641483701765537,
            0.011199199594557285,
            0.004938420373946428,
            -0.006151843816041946,
            -0.015411692671477795,
            -0.013710862025618553,
            -0.022860391065478325,
            0.001708492636680603,
            -0.008806036785244942,
            0.001288982923142612,
            0.002884464105591178,
            -0.0014170001959428191,
            -0.02346210367977619,
            0.011864163912832737,
            0.0035993224009871483,
            0.029500087723135948,
            0.005742299370467663,
            0.03238788619637489,
            -0.0015109548112377524,
            0.005645895376801491,
            0.028298664838075638,
            -0.024160848930478096,
            -0.02296280674636364,
            0.003886824008077383,
            -0.02687135711312294,
            -0.03622438386082649,
            -0.027487745508551598,
            -0.03006606176495552,
            -0.03154183179140091,
            -0.05171217396855354,
            0.01233761664479971,
            -0.02516285516321659,
            0.03125099465250969,
            0.033288903534412384,
            0.0013692098436877131,
            -0.013235676102340221,
            -0.015863128006458282,
            0.032077644020318985,
            -0.007672408130019903,
            0.025986334308981895,
            -0.019372381269931793,
            0.018691634759306908,
            0.008849894627928734,
            0.019369376823306084,
            -0.02133084647357464,
            0.03963794186711311,
            -0.013575876131653786,
            0.009764878079295158,
            0.02552107907831669,
            0.025140540674328804,
            -0.012907641008496284,
            -0.012043635360896587,
            -0.0005681322072632611,
            0.005889739375561476,
            0.012613357044756413,
            0.020805373787879944,
            0.014846679754555225,
            0.00583231495693326,
            0.002135033020749688,
            -0.011292461305856705,
            -0.015223131515085697,
            0.02058829739689827,
            0.02365180104970932,
            -0.023890942335128784,
            0.018879199400544167,
            -0.021956149488687515,
            0.00855795294046402,
            0.009164588525891304,
            -0.009021801874041557,
            0.00374528462998569,
            0.0126882903277874,
            -0.007163457106798887,
            -0.008878281340003014,
            0.030509203672409058,
            0.015633119270205498,
            0.02092277631163597,
            0.0017810848075896502,
            -0.012263386510312557,
            -0.010383137501776218,
            -0.015463045798242092,
            0.009274568408727646,
            0.00349618261680007,
            0.029878530651330948,
            -0.011314529925584793,
            0.03215683996677399,
            0.02181420288980007,
            -0.028251580893993378,
            0.030085904523730278,
            0.019537372514605522,
            0.0014375639148056507,
            -0.006968335248529911,
            -0.011479241773486137,
            -0.023466233164072037,
            8.607521886005998e-05,
            -0.0007716886466369033,
            -0.020872022956609726,
            0.006866783834993839,
            0.02374310977756977,
            -0.021329578012228012,
            0.016769755631685257,
            -0.001478502876125276,
            -0.030365420505404472,
            -0.018661227077245712,
            -0.022820301353931427,
            0.029498709365725517,
            0.018535520881414413,
            -0.0014221600722521544,
            0.020650381222367287,
            -0.0026400620117783546,
            -0.02678626775741577,
            0.023731373250484467,
            -0.006800923030823469,
            0.01702922210097313,
            -0.026317797601222992,
            0.026629721745848656,
            0.010681746527552605,
            -0.02044118009507656,
            0.022114695981144905,
            -0.025040743872523308,
            -0.005546273197978735,
            0.021565821021795273,
            0.020049145445227623,
            0.0033429807517677546,
            -0.0024157706648111343,
            -0.005549117457121611,
            0.011141939088702202,
            0.022950181737542152,
            -0.019043145701289177,
            0.016607210040092468,
            0.01254481915384531,
            0.014823098666965961,
            -0.018353072926402092,
            -0.0006689883884973824,
            -0.02922723814845085,
            -0.021703679114580154,
            0.014556369744241238,
            0.02527553401887417,
            0.0008388832211494446,
            -0.03192933276295662,
            0.03992108255624771,
            -0.03245852515101433,
            -0.01678233966231346,
            -0.013468519784510136,
            0.03531726822257042,
            0.015139257535338402,
            0.0020869337022304535,
            -0.03380682319402695,
            -0.023534834384918213,
            0.02539658360183239,
            0.016464311629533768,
            -0.029150204733014107,
            0.010881793685257435,
            0.038045935332775116,
            -0.0031603423412889242,
            0.020459145307540894,
            0.010568322613835335,
            0.028543313965201378,
            0.0046045477502048016,
            -0.020501356571912766,
            0.030419353395700455,
            -0.01478591002523899,
            0.0064088706858456135,
            0.014184731058776379,
            0.025760719552636147,
            0.03471620753407478,
            -0.002680956618860364,
            0.018171435222029686,
            -0.018097981810569763,
            -0.0031788330525159836,
            -0.02663620561361313,
            0.013979392126202583,
            -0.006351076066493988,
            0.00955285970121622,
            -0.013871320523321629,
            0.004743402823805809,
            -0.0010772331152111292,
            -0.04520923271775246,
            0.012873499654233456,
            -0.02621227316558361,
            -0.00024842561106197536,
            0.016120390966534615,
            -0.00417967326939106,
            0.02773008681833744,
            0.01530339103192091,
            -0.006380970124155283,
            -0.024493049830198288,
            0.003231929847970605,
            -0.021718740463256836,
            0.018001804128289223,
            -0.011002314276993275,
            0.0221555158495903,
            0.035877905786037445,
            -0.026677189394831657,
            0.0032496177591383457,
            -0.01337786391377449,
            0.026329252868890762,
            0.02976033091545105,
            -0.008357668295502663,
            -0.0018659881316125393,
            0.017537584528326988,
            0.007885880768299103,
            0.015054315328598022,
            0.04630549997091293,
            0.028477977961301804,
            0.03187095746397972,
            -0.020046168938279152,
            -0.02452642284333706,
            0.017904022708535194,
            0.04101027175784111,
            0.011217474937438965,
            -0.023950882256031036,
            0.009746014140546322,
            0.0013779151486232877,
            0.0029208671767264605,
            0.02208731323480606,
            -0.015813330188393593,
            0.01314041018486023,
            -0.009108261205255985,
            -0.009714406915009022,
            -0.01971089281141758,
            0.022045882418751717,
            0.016825608909130096,
            0.008437336422502995,
            -0.015420441515743732,
            0.025058390572667122,
            0.025857459753751755,
            0.0010873363353312016,
            -0.021868636831641197,
            0.019701024517416954,
            0.013910848647356033,
            -0.023256076499819756,
            0.0051752799190580845,
            -0.02551228366792202,
            0.013349458575248718,
            0.03449420630931854,
            -0.0015551268588751554,
            -0.014296351931989193,
            0.0008455906645394862,
            0.0270625501871109,
            0.004476753994822502,
            0.03317316994071007,
            -0.014164715074002743,
            0.026888122782111168,
            -0.023670818656682968,
            -0.0334470272064209,
            0.005059243179857731,
            -0.03750729188323021,
            0.0218067429959774,
            -0.0204907339066267,
            -0.028475822880864143,
            -0.011535069905221462,
            0.02390761859714985,
            -0.026158304885029793,
            -0.014432413503527641,
            0.0071134078316390514,
            -0.0038828407414257526,
            0.021257949993014336,
            -0.01368529349565506,
            0.004414836876094341,
            0.033008873462677,
            -0.0006240360089577734,
            0.010890799574553967,
            0.007279848679900169,
            -0.0014044480631127954,
            -0.004909951705485582,
            -0.041823454201221466,
            -0.022862136363983154,
            0.04158913716673851,
            -0.007396255619823933,
            -0.018120132386684418,
            0.030989494174718857,
            0.03245111554861069,
            0.009696375578641891,
            0.031980741769075394,
            0.015962913632392883,
            -0.0032079187221825123,
            0.005840752273797989,
            0.006010192912071943,
            0.0179674681276083,
            0.03469051048159599,
            0.018406108021736145,
            0.0332256555557251,
            0.02466234378516674,
            -0.011252805590629578,
            -0.003231044625863433,
            0.011324362829327583,
            -0.031589556485414505,
            -0.01904519647359848,
            0.010003145784139633,
            0.02431686967611313,
            0.01558627188205719,
            0.014802560210227966,
            -0.004859918728470802,
            -0.005301500204950571,
            0.007955892942845821,
            -0.032387714833021164,
            -0.024745510891079903,
            0.022747697308659554,
            0.03837178274989128,
            0.02620961330831051,
            0.00710166385397315,
            -0.004248275421559811,
            0.011169119738042355,
            -0.02282026596367359,
            0.008584733121097088,
            -0.019346410408616066,
            0.02122264727950096,
            -0.014768376015126705,
            0.03594331070780754,
            0.023126456886529922,
            0.025893453508615494,
            0.004443062003701925,
            0.00620653061196208,
            -0.00020351201237645,
            -0.018814295530319214,
            -0.03538653627038002,
            -0.035317618399858475,
            -0.00010724065214162692,
            -0.01559234969317913,
            0.0021977548021823168,
            0.01586945727467537,
            0.012380820699036121,
            0.005975241772830486,
            0.013278109021484852,
            0.023467211052775383,
            0.006762201432138681,
            0.028464196249842644,
            -0.014755234122276306,
            0.034308988600969315,
            -0.029826149344444275,
            0.006068240385502577,
            -0.024564679712057114,
            -0.023139098659157753,
            -0.011755543760955334,
            0.03700416535139084,
            0.0312715582549572,
            0.007360381074249744,
            0.016157617792487144,
            0.013643674552440643,
            0.0519096776843071,
            -0.003081745468080044,
            -0.015618023462593555,
            -0.027607526630163193,
            -0.005564464256167412,
            0.019588248804211617,
            0.02738962322473526,
            -0.01244737021625042,
            0.008658286184072495,
            0.013557321391999722,
            0.012981368228793144,
            -0.01938178576529026,
            0.0010894590523093939,
            0.020906144753098488,
            0.02161317691206932,
            0.01820884644985199,
            -0.03485282510519028,
            -0.022808369249105453,
            -0.018133796751499176,
            0.021163277328014374,
            0.026069626212120056,
            0.016582442447543144,
            0.012374370358884335,
            0.03214874863624573,
            -0.030079975724220276,
            0.0021729799918830395,
            -0.015310731716454029,
            -0.011568233370780945,
            0.01297825574874878,
            -0.016059106215834618,
            -0.02701917104423046,
            -0.015222717076539993,
            0.0044195870868861675,
            0.018107740208506584,
            -0.026511404663324356,
            0.024874789640307426,
            0.0003273930342402309,
            -0.02620624378323555,
            -0.007515731267631054,
            0.011046294122934341,
            -0.021177617833018303,
            0.029553579166531563,
            -0.021784283220767975,
            -0.01098327711224556,
            -0.004120261874049902,
            0.030418636277318,
            -0.006292666308581829,
            -0.027972225099802017,
            -0.007480084430426359,
            0.00794186256825924,
            -0.014899026602506638,
            -0.003503718413412571,
            0.034318894147872925,
            -0.012180681340396404,
            -0.02837405540049076,
            0.009050324559211731,
            0.02735345996916294,
            0.02757074683904648,
            -0.025528883561491966,
            0.016827354207634926,
            -0.01810626871883869,
            -0.007518508471548557,
            0.01707015559077263,
            0.0031075195875018835,
            0.013346674852073193,
            -0.019532421603798866,
            0.00559185491874814,
            -0.02242407388985157,
            0.02726893313229084,
            0.0010133449686691165,
            -0.016401445493102074,
            -0.009951580315828323,
            -0.03394192084670067,
            0.029379375278949738,
            -0.011365419253706932,
            0.030905161052942276,
            0.0023661411833018064,
            0.028801070526242256,
            -0.02116452343761921,
            0.026969224214553833,
            -0.013364480808377266,
            0.031226620078086853,
            0.026405325159430504,
            -0.02167406864464283,
            0.03174157813191414,
            -0.03253665938973427,
            -0.014277632348239422,
            0.0060260724276304245,
            -0.03939859941601753,
            0.019673660397529602,
            0.017455486580729485,
            -0.02396649681031704,
            -0.021954189985990524,
            0.017423542216420174,
            -0.010168494656682014,
            0.00894417054951191,
            -0.018591074272990227,
            0.04528966173529625,
            -0.029533153399825096,
            -0.02491666004061699,
            0.013143428601324558,
            0.0225987508893013,
            -0.01762300357222557,
            0.007235799916088581,
            -0.011448710225522518,
            0.03519546240568161,
            0.015715230256319046,
            -0.02442610077559948,
            0.0016511681023985147,
            0.01051308959722519,
            0.021458253264427185,
            -0.0002262773341499269,
            0.029767021536827087,
            0.027947958558797836,
            -0.014753354713320732,
            0.012417305260896683,
            0.03843135014176369,
            0.02169010601937771,
            0.0029599645640701056,
            -0.032320234924554825,
            0.020376186817884445,
            0.014364467933773994,
            0.0020855735056102276,
            0.016948144882917404,
            -0.003085050033405423,
            0.019399583339691162,
            -0.02844470925629139,
            0.05625832825899124,
            -0.011766733601689339,
            0.0024747271090745926,
            0.0332641564309597,
            -0.02915007621049881,
            0.02121308445930481,
            -0.028885258361697197,
            -0.00517126452177763,
            0.02133643440902233,
            -0.013987993821501732,
            -0.0167207233607769,
            -0.004380498547106981,
            0.029554439708590508,
            -0.03444238752126694,
            -0.0028121424838900566,
            0.027396565303206444,
            0.03350424766540527,
            0.015097719617187977,
            0.0026213389355689287,
            -0.018016831949353218,
            0.03081008419394493,
            0.02225247398018837,
            0.003990786615759134,
            -0.006814774125814438,
            -0.015692396089434624,
            0.003910645842552185,
            0.0047549717128276825,
            0.03670711815357208,
            -0.0208126213401556,
            0.014220732264220715,
            0.012271269224584103,
            7.302184530999511e-05,
            0.018533777445554733,
            0.032452695071697235,
            0.016797296702861786,
            0.008327113464474678,
            -0.018935110419988632,
            -0.000995806185528636,
            0.016276026144623756,
            -0.01596510410308838,
            0.004133809357881546,
            -0.009430691599845886,
            -0.013466638512909412,
            0.033525172621011734,
            -0.035520896315574646,
            -0.006586398929357529,
            -0.041553329676389694,
            -0.005730173084884882,
            0.00903006736189127,
            -0.007386877201497555,
            0.04712758585810661,
            0.001793146482668817,
            -0.024615641683340073,
            -0.01587992161512375,
            0.0003812053182628006,
            0.005327889230102301,
            0.03191933408379555,
            -0.05174523964524269,
            0.0011273532873019576,
            -0.032767828553915024,
            0.030721815302968025,
            -0.004107749089598656,
            0.004298882558941841,
            -0.010905595496296883,
            0.017811303958296776,
            -0.010148990899324417,
            0.020046493038535118,
            -0.015741240233182907,
            -0.03345794975757599,
            0.012039110995829105,
            0.002688380889594555,
            0.013076617382466793,
            0.020953796803951263,
            -0.031762413680553436,
            0.02164245769381523,
            -0.005441534798592329,
            -0.007447178475558758,
            0.023546434938907623,
            0.027406584471464157,
            -0.02194961905479431,
            -0.00542623782530427,
            -0.029210444539785385,
            -0.008012119680643082,
            0.029648521915078163,
            -0.013063623569905758,
            0.004262333735823631,
            0.009260790422558784,
            0.007358493749052286,
            0.009127777069807053,
            -0.015885135158896446,
            0.04196007922291756,
            0.02123977057635784,
            0.01537998951971531,
            0.01106190588325262,
            0.007736759725958109,
            0.01648743636906147,
            0.0159866102039814,
            -0.0011240675812587142,
            0.027618976309895515,
            0.01063553337007761,
            -0.018471399322152138,
            0.027545234188437462,
            0.03511914610862732,
            0.00905061699450016,
            0.012291149236261845,
            0.029059629887342453,
            -0.03567013889551163,
            -0.03269181400537491,
            0.0016182386316359043,
            0.023670854046940804,
            -0.034689124673604965,
            -0.01698688603937626,
            -0.007905500940978527,
            0.0026963434647768736,
            0.0243470948189497,
            -0.0034503282513469458,
            0.002611842006444931,
            0.0009220679057762027,
            -0.0014862612588331103,
            0.00449213245883584,
            -0.00906264130026102,
            0.03087233193218708,
            -0.0232189130038023,
            0.013755657710134983,
            0.010604067705571651,
            0.006428116466850042,
            0.03576896712183952,
            0.010852796956896782,
            0.03752010688185692,
            0.02196601778268814,
            0.025074420496821404,
            0.006244970019906759,
            -0.01371863391250372,
            -0.023358700796961784,
            0.0219682939350605,
            -0.02985367178916931,
            0.014029686339199543,
            -0.016553644090890884,
            0.018689002841711044,
            -0.023004943504929543,
            0.008012712933123112,
            -0.001953213009983301,
            -0.0235625971108675,
            0.014118469320237637,
            -0.020367128774523735,
            0.024248449131846428,
            -0.003978845663368702,
            0.0027784190606325865,
            0.002012335229665041,
            -0.02549596317112446,
            0.003098349319770932,
            -0.009167574346065521,
            0.0153861278668046,
            0.019014449790120125,
            -0.019486691802740097,
            0.013308367691934109,
            0.004632083233445883,
            0.026205526664853096,
            0.0011863027466461062,
            -0.0017004150431603193,
            0.04336833581328392,
            -0.0040245745331048965,
            -0.009957408532500267,
            -0.015871327370405197,
            -0.03343382105231285,
            -0.013959895819425583,
            -0.00635182298719883,
            0.0479140505194664,
            -0.017940649762749672,
            -0.0011754919541999698,
            0.002372127491980791,
            -0.014044473879039288,
            -0.003551284084096551,
            0.027015579864382744,
            -0.029337652027606964,
            -0.0377170704305172,
            0.010368074290454388,
            0.007634310517460108,
            0.021864457055926323,
            0.00455941678956151,
            -0.01667572371661663,
            0.032932549715042114,
            -0.016857298091053963,
            -0.0008071071351878345,
            -0.003695117076858878,
            -0.0027870857156813145,
            0.019381083548069,
            -0.02169044502079487,
            -0.029408937320113182,
            0.023917021229863167,
            0.003947053104639053,
            0.03055536188185215,
            0.002742865588515997,
            -0.019155262038111687,
            -0.02385113015770912,
            -0.02455095387995243,
            0.01251212041825056,
            -0.03633309155702591,
            -0.029189791530370712,
            -0.023366179317235947,
            -0.00935603491961956,
            -0.02572553977370262,
            -0.018923647701740265,
            0.029323291033506393,
            0.034819699823856354,
            -0.01195515412837267,
            0.010743756778538227,
            -0.023355230689048767,
            0.02767547219991684,
            0.003877719398587942,
            -0.00239679217338562,
            -0.016342567279934883,
            -0.0026515128556638956,
            -0.007062526419758797,
            0.02877582237124443,
            0.043165601789951324,
            0.034360121935606,
            -0.037725869566202164,
            0.019588705152273178,
            0.021792950108647346,
            0.019207071512937546,
            -0.012654921971261501,
            0.0304966252297163,
            0.02331884391605854,
            0.013342560268938541,
            0.01927320659160614,
            0.0007475699530914426,
            0.01852034591138363,
            0.021756842732429504,
            -0.02664840966463089,
            0.026441089808940887,
            -0.011178413406014442,
            0.02799335867166519,
            -0.010159727185964584,
            0.025132326409220695,
            -0.01569964922964573,
            -0.040232643485069275,
            0.01610191911458969,
            0.026267653331160545,
            0.010084123350679874,
            -0.013112612999975681,
            -0.02956243045628071,
            0.002883068984374404,
            0.017414631322026253,
            0.006251539569348097,
            -0.020906494930386543,
            -0.03172890469431877,
            -0.004027511924505234,
            -0.0202794186770916,
            -0.0012344964779913425,
            0.05033664032816887,
            0.00779235502704978,
            0.005971819628030062,
            0.009468473494052887,
            0.017981670796871185,
            -0.0074303774163126945,
            -0.006472528912127018,
            -0.0032689666841179132,
            0.0037493696436285973,
            -0.02407125011086464,
            0.019409282132983208,
            -0.021829964593052864,
            0.005837991833686829,
            0.014261224307119846,
            -0.02652653679251671,
            0.05913475155830383,
            -0.015563691966235638,
            0.025447560474276543,
            -0.020802432671189308,
            -0.027305539697408676,
            -0.0024977303110063076,
            -0.0117776645347476,
            0.029794203117489815,
            0.0007100136717781425,
            -0.0029680300503969193,
            -0.007034591399133205,
            0.00016274373047053814,
            -0.0012686678674072027
        ],
        [
            0.04903537034988403,
            -0.015163829550147057,
            0.03920888528227806,
            -0.04152794927358627,
            -0.018995853140950203,
            -0.12407336384057999,
            0.03638048097491264,
            -0.0409165695309639,
            -0.058422721922397614,
            -0.01903088390827179,
            0.012761718593537807,
            0.03251839056611061,
            -0.004319945350289345,
            -0.019817350432276726,
            -0.04099085181951523,
            0.03622259572148323,
            0.04124598205089569,
            0.021488914266228676,
            0.01461623702198267,
            0.014547601342201233,
            0.032758064568042755,
            0.0034150334540754557,
            -0.01933909021317959,
            -0.002222541021183133,
            -0.02728951908648014,
            -0.003448046278208494,
            0.003708238946273923,
            -0.0018713022582232952,
            0.022274645045399666,
            0.011016231030225754,
            -0.023492397740483284,
            0.005846542771905661,
            0.028583090752363205,
            0.021265177056193352,
            0.023159384727478027,
            0.0039042967837303877,
            0.02701236866414547,
            -0.0008852105820551515,
            0.01634347066283226,
            0.014237298630177975,
            0.001189235714264214,
            -0.008007591590285301,
            -0.045509304851293564,
            -0.02489008940756321,
            -0.012109250761568546,
            0.021859966218471527,
            -0.027067650109529495,
            -0.005447295494377613,
            0.006135841365903616,
            -0.031115975230932236,
            -0.0017615929245948792,
            -0.03174278885126114,
            0.03020915947854519,
            -0.02340967021882534,
            -0.009645573794841766,
            0.004704505205154419,
            -0.05203566700220108,
            -0.02001762017607689,
            -0.044950932264328,
            0.004688626155257225,
            0.013599627651274204,
            -0.010403572581708431,
            0.00863189809024334,
            -0.008754614740610123,
            -0.07026547938585281,
            -0.02027077041566372,
            0.012929209508001804,
            -0.011326623149216175,
            -0.022706780582666397,
            -0.00211399607360363,
            0.029448961839079857,
            0.024523114785552025,
            0.03349211439490318,
            -0.02664032019674778,
            0.019272712990641594,
            0.027087215334177017,
            0.04233507812023163,
            0.0006702705286443233,
            -0.042782802134752274,
            -0.024313468486070633,
            0.02218719758093357,
            0.02437133714556694,
            0.008855396881699562,
            0.012514217756688595,
            -0.01287303026765585,
            0.014406204223632812,
            -0.0030444390140473843,
            0.044780515134334564,
            -0.012198401615023613,
            -0.06307981163263321,
            -0.03666883334517479,
            -0.030785975977778435,
            0.02532706968486309,
            -0.005437940824776888,
            0.00884553324431181,
            -0.014337182976305485,
            0.09457425773143768,
            0.03971301391720772,
            0.03446582332253456,
            0.0364980511367321,
            -0.02063925750553608,
            -0.01555722951889038,
            0.027679722756147385,
            0.03324776142835617,
            -0.011124549433588982,
            -0.005599556025117636,
            0.030618775635957718,
            0.017972609028220177,
            -0.0026503773406147957,
            -0.04910293221473694,
            -0.004156023263931274,
            0.04045877978205681,
            -0.025276752188801765,
            -0.0018829924520105124,
            0.03184444084763527,
            -0.015028529800474644,
            -0.012012461200356483,
            -0.016284290701150894,
            -0.01498707290738821,
            0.02028837613761425,
            -0.016879087314009666,
            0.009806511923670769,
            0.044232990592718124,
            0.006168759893625975,
            0.03010661154985428,
            0.024446560069918633,
            -0.02854936756193638,
            0.0012145835207775235,
            0.022092485800385475,
            -0.008094740100204945,
            0.01598932221531868,
            0.04055984690785408,
            0.030671782791614532,
            0.04402828589081764,
            -0.0047625582665205,
            0.0047910320572555065,
            0.02875073440372944,
            0.012529928237199783,
            0.022311003878712654,
            -0.014986397698521614,
            0.0157545767724514,
            -0.027144569903612137,
            0.008311865851283073,
            0.0026691663078963757,
            0.016892172396183014,
            -0.010979107581079006,
            -0.011909271590411663,
            -0.005471259821206331,
            0.03719581291079521,
            -0.0063637117855250835,
            0.004610856529325247,
            0.037524402141571045,
            -0.007159871980547905,
            0.049409158527851105,
            -0.04138395935297012,
            -0.03148471936583519,
            0.0025133094750344753,
            -0.014086500741541386,
            -0.0015336999204009771,
            -0.06183648109436035,
            -0.051367126405239105,
            0.002411169232800603,
            0.022450050339102745,
            0.020363789051771164,
            -0.03175903484225273,
            -0.025221578776836395,
            0.010479564778506756,
            -0.002703556790947914,
            -0.010427343659102917,
            0.004785352852195501,
            0.031437940895557404,
            -0.006983128841966391,
            0.007584784645587206,
            0.01677854359149933,
            0.012916091829538345,
            0.03470730036497116,
            -0.062144506722688675,
            -0.02217095158994198,
            -0.02308194525539875,
            -0.009128998965024948,
            0.037947602570056915,
            0.02073722891509533,
            0.052128490060567856,
            0.029765130952000618,
            0.010948154143989086,
            -0.005688172299414873,
            0.030090218409895897,
            0.011457228101789951,
            -0.036192331463098526,
            0.00918397307395935,
            -0.020525142550468445,
            0.025910425931215286,
            0.031226832419633865,
            0.036451540887355804,
            -0.031224511563777924,
            0.034659452736377716,
            0.008380653336644173,
            0.017982210963964462,
            0.006244719959795475,
            0.020274870097637177,
            -0.006832842715084553,
            0.0036703317891806364,
            0.04042904078960419,
            0.008204588666558266,
            0.023979969322681427,
            0.00027925102040171623,
            0.016892509534955025,
            0.013218743726611137,
            0.05992882698774338,
            -0.03345185145735741,
            0.01900181546807289,
            0.017936965450644493,
            -0.013459574431180954,
            -0.009771889075636864,
            -0.0015537417493760586,
            -0.01472728792577982,
            -0.03120979480445385,
            -0.03250281512737274,
            -0.022257551550865173,
            0.001969584496691823,
            0.026666317135095596,
            -0.052553318440914154,
            -0.000984763726592064,
            0.006261779461055994,
            0.002670057350769639,
            -0.010809392668306828,
            -0.0019213103223592043,
            0.007495138794183731,
            0.0011545962188392878,
            -0.0201882254332304,
            -0.013517793267965317,
            0.021517157554626465,
            0.001990624936297536,
            0.019009007140994072,
            -0.03693213686347008,
            0.015752121806144714,
            0.05788927897810936,
            -0.025666432455182076,
            -0.0002144405007129535,
            -0.007377078756690025,
            0.014934594742953777,
            -0.012042388319969177,
            -0.03317480906844139,
            -0.0023122532293200493,
            -0.014570116065442562,
            0.013253106735646725,
            0.0032566224690526724,
            0.0038202248979359865,
            0.02661077119410038,
            0.020735496655106544,
            0.0044786701910197735,
            -0.008214559406042099,
            0.05680709332227707,
            -0.009909730404615402,
            -2.658216908457689e-05,
            -0.017202744260430336,
            -0.0342339463531971,
            0.04190251603722572,
            0.028669483959674835,
            -0.030363136902451515,
            0.014532827772200108,
            -0.024557005614042282,
            0.03590606898069382,
            -0.00883186049759388,
            -0.007857134565711021,
            0.0042472411878407,
            0.01759214513003826,
            0.027205079793930054,
            0.0038084189873188734,
            -0.031114943325519562,
            -0.003435034304857254,
            -0.020380886271595955,
            0.025311380624771118,
            0.004128310363739729,
            -0.014091704040765762,
            -0.012232753448188305,
            0.015141845680773258,
            -0.014892074279487133,
            0.03743429854512215,
            0.017822982743382454,
            -0.03802495449781418,
            0.0020661151502281427,
            0.025259321555495262,
            -0.007189528550952673,
            0.03894820064306259,
            -0.011903839185833931,
            -0.0014857057249173522,
            0.027768291532993317,
            0.0335812121629715,
            0.012984911911189556,
            0.005421099252998829,
            -0.007343617733567953,
            0.02746352180838585,
            0.004078475758433342,
            -0.03501927852630615,
            -0.0001854119182098657,
            0.021726813167333603,
            -0.03251489996910095,
            -0.026652805507183075,
            -0.007757484447211027,
            -0.02143600769340992,
            0.01096716895699501,
            -0.02633642964065075,
            -0.034784890711307526,
            0.007622323930263519,
            0.05211080238223076,
            -0.05398605763912201,
            -0.022553959861397743,
            0.002677801763638854,
            0.008743271231651306,
            -0.022580008953809738,
            -0.007604590151458979,
            -0.03400811553001404,
            -0.025174079462885857,
            -0.028192926198244095,
            0.017213325947523117,
            0.015921367332339287,
            0.030649332329630852,
            -0.023598728701472282,
            0.02334655076265335,
            0.0026687481440603733,
            0.0025532864965498447,
            0.0152475256472826,
            -0.04524540528655052,
            0.030094105750322342,
            0.0258550513535738,
            -0.07489141076803207,
            0.006250700913369656,
            0.026403069496154785,
            -0.016554977744817734,
            0.02867080643773079,
            0.02137376181781292,
            0.003405422903597355,
            0.008992746472358704,
            -0.04476795345544815,
            -0.04736517742276192,
            -0.02685864455997944,
            0.015434426255524158,
            -0.002617838326841593,
            0.001204379484988749,
            0.020617172122001648,
            0.025003882125020027,
            -0.027963994070887566,
            -0.01005768496543169,
            -0.00553916348144412,
            0.06556756794452667,
            8.025579154491425e-05,
            0.03635674715042114,
            0.011311226524412632,
            -0.07453141361474991,
            -0.004096376709640026,
            0.023125817999243736,
            0.009835421107709408,
            0.00271622184664011,
            0.030331343412399292,
            -0.01004776544868946,
            0.013791966252028942,
            -0.04276612773537636,
            0.02300640568137169,
            0.025141790509223938,
            0.0003414376697037369,
            0.015866726636886597,
            0.03323470801115036,
            -0.02727588824927807,
            0.0031468241941183805,
            0.017390519380569458,
            0.0053002359345555305,
            0.029679445549845695,
            0.009242106229066849,
            -0.00923226773738861,
            0.019771931692957878,
            -0.020107939839363098,
            0.004284490831196308,
            0.015199432149529457,
            0.015862585976719856,
            0.01044443342834711,
            0.03825107589364052,
            0.0281891617923975,
            0.03246794268488884,
            -0.024297533556818962,
            0.02711065113544464,
            -0.02151893824338913,
            0.011437184177339077,
            -0.0035580783151090145,
            0.03778664022684097,
            0.013724745251238346,
            -0.0007341638556681573,
            -0.020752977579832077,
            0.051116447895765305,
            -0.026065364480018616,
            0.0018364470452070236,
            -0.006052270997315645,
            -0.039246536791324615,
            -0.007539285346865654,
            0.03321697935461998,
            0.006557806394994259,
            0.03228252753615379,
            -0.0009464264730922878,
            0.03598235920071602,
            0.011735931970179081,
            0.005066896788775921,
            0.027563830837607384,
            0.014636140316724777,
            0.026216616854071617,
            0.03072044439613819,
            -0.007387407589703798,
            -0.04702677205204964,
            0.003389259334653616,
            0.00036303760134615004,
            0.011742504313588142,
            -0.013873027637600899,
            0.02857813984155655,
            0.025233998894691467,
            0.016586115583777428,
            -0.030898313969373703,
            0.02391754649579525,
            -0.0290822796523571,
            -0.016404878348112106,
            0.026359250769019127,
            0.01236078143119812,
            -0.01472708024084568,
            -0.006801862735301256,
            0.07692766189575195,
            -0.010742866434156895,
            -0.005215053912252188,
            0.05236967280507088,
            -0.05697930231690407,
            0.028082741424441338,
            -0.005153344478458166,
            -0.05753173679113388,
            -0.0033562358003109694,
            -0.024131203070282936,
            0.03285973519086838,
            0.07244867086410522,
            -0.013434055261313915,
            0.02544899471104145,
            -0.02487233094871044,
            0.027628518640995026,
            -0.0019181381212547421,
            -0.0165861863642931,
            0.012925597839057446,
            0.04224316403269768,
            0.00549486605450511,
            -0.02163468487560749,
            0.024717826396226883,
            0.006363005843013525,
            0.026796884834766388,
            -0.021401522681117058,
            0.023979436606168747,
            -0.013344833627343178,
            0.0028874045237898827,
            0.03586350008845329,
            -0.03455595299601555,
            0.015204968862235546,
            -0.010140303522348404,
            -0.011559874750673771,
            0.0018098314758390188,
            -0.009873980656266212,
            0.015350802801549435,
            -0.0065884278155863285,
            -0.0627961978316307,
            -0.00642069848254323,
            0.04625912010669708,
            -0.03655722364783287,
            0.013995508663356304,
            0.03658248111605644,
            0.014801643788814545,
            -0.03782210499048233,
            -0.007338396739214659,
            -0.0173494890332222,
            0.010351966135203838,
            0.02317127212882042,
            0.011642233468592167,
            -0.008500032126903534,
            -0.008650331757962704,
            -0.0038506025448441505,
            0.0031170835718512535,
            0.0030166797805577517,
            -0.0438615158200264,
            0.023993581533432007,
            0.041550394147634506,
            0.013253111392259598,
            0.038114722818136215,
            0.03821611404418945,
            -0.00450929393991828,
            0.01907976157963276,
            -0.026266129687428474,
            -0.019958026707172394,
            -0.042971037328243256,
            0.0049645500257611275,
            -0.03429440036416054,
            0.004049426876008511,
            0.007334042340517044,
            0.07333220541477203,
            0.051504865288734436,
            0.051433026790618896,
            0.02803141623735428,
            -0.0050203511491417885,
            -0.005406397394835949,
            0.034622590988874435,
            0.014371995814144611,
            0.02608853206038475,
            0.005742231849581003,
            -0.0018207946559414268,
            -0.011031988076865673,
            -0.0118283461779356,
            -0.012391318567097187,
            0.006510092411190271,
            0.021873367950320244,
            0.02760270982980728,
            -0.033008329570293427,
            0.014520494267344475,
            -0.03177998587489128,
            0.013236785307526588,
            -0.04502715542912483,
            -0.04048749804496765,
            0.016721639782190323,
            0.038186199963092804,
            0.01573888398706913,
            0.01885087601840496,
            -0.026749305427074432,
            0.03679237514734268,
            0.008772959001362324,
            0.016319647431373596,
            0.012006922625005245,
            0.024450363591313362,
            0.06427387148141861,
            0.012848210521042347,
            0.029494108632206917,
            0.013679608702659607,
            0.01378960907459259,
            0.010166055522859097,
            0.02801501378417015,
            0.01701725833117962,
            -0.009606407023966312,
            -0.043497998267412186,
            0.011708936654031277,
            -0.05735265091061592,
            0.007052088156342506,
            -0.008176997303962708,
            -0.010829612612724304,
            0.002784293843433261,
            0.019223693758249283,
            0.0014787224354222417,
            0.022252634167671204,
            -0.01691274158656597,
            0.014733702875673771,
            0.023282025009393692,
            -0.0034249539021402597,
            -0.03358060121536255,
            -0.04482220113277435,
            -0.025391705334186554,
            -0.020235231146216393,
            -0.020765051245689392,
            -0.0031112474389374256,
            0.005883687641471624,
            -0.021430009976029396,
            -0.02551162987947464,
            0.02327558770775795,
            0.013373101130127907,
            0.00841376930475235,
            -0.019740648567676544,
            -0.0016763720195740461,
            0.03570256382226944,
            -0.019335579127073288,
            0.017762914299964905,
            -0.00789559818804264,
            -0.015781288966536522,
            -0.01596122793853283,
            -0.004132311791181564,
            -0.004367355722934008,
            -0.032708797603845596,
            -0.024818673729896545,
            0.041688885539770126,
            0.04991045966744423,
            0.017366357147693634,
            0.03488341346383095,
            -0.011227347888052464,
            -0.002941810991615057,
            -0.01268509030342102,
            0.025647303089499474,
            0.02576923742890358,
            0.011806044727563858,
            0.02978183887898922,
            0.012339949607849121,
            0.002208022167906165,
            -0.005697136744856834,
            0.03597496822476387,
            -0.013126494362950325,
            -0.007255823817104101,
            0.0313483364880085,
            -0.005795155186206102,
            -0.00811179168522358,
            -0.019048282876610756,
            0.018239708617329597,
            -0.019521020352840424,
            0.017856260761618614,
            0.0029704479966312647,
            -0.011220664717257023,
            -0.017278408631682396,
            -0.05776491016149521,
            0.012767133302986622,
            -0.02689470164477825,
            0.02876894548535347,
            -0.004136922303587198,
            -0.07891269028186798,
            0.020558662712574005,
            0.03220539167523384,
            0.01949450559914112,
            0.02828771062195301,
            0.03163481131196022,
            0.00230735563673079,
            0.029350630939006805,
            -0.03488780930638313,
            0.03692751005291939,
            0.0419948548078537,
            -0.022693898528814316,
            0.0026630908250808716,
            -0.029872719198465347,
            0.03509530797600746,
            0.016782520338892937,
            0.03458225727081299,
            0.025703899562358856,
            -0.014280813746154308,
            -0.012306910008192062,
            -0.011908896267414093,
            0.01804104633629322,
            -0.030627518892288208,
            0.05326669290661812,
            0.03523637354373932,
            -0.043317828327417374,
            -0.01156546175479889,
            -0.00985221192240715,
            0.01729179173707962,
            -0.045641545206308365,
            0.01207884680479765,
            -0.022339005023241043,
            0.016339104622602463,
            0.021841557696461678,
            0.010056385770440102,
            0.04223773628473282,
            0.014615997672080994,
            -0.027131913229823112,
            0.035652045160532,
            0.02161213383078575,
            0.027085190638899803,
            -0.04605346545577049,
            0.015404900535941124,
            0.013862229883670807,
            -0.0048435404896736145,
            -0.008732826448976994,
            -0.02708587981760502,
            0.01335407979786396,
            -0.042498599737882614,
            0.04826423525810242,
            0.003789023496210575,
            0.007718310225754976,
            0.009851750917732716,
            -0.0009795762598514557,
            0.0421898178756237,
            0.049992527812719345,
            -0.010337503626942635,
            -0.009846017695963383,
            0.019291112199425697,
            -0.011350883170962334,
            -0.026493841782212257,
            -0.004265934228897095,
            -0.012781048193573952,
            -0.03918890282511711,
            0.005261701066046953,
            0.0583663173019886,
            0.03694118186831474,
            -0.002538529224693775,
            -0.008773230947554111,
            -0.0017463709227740765,
            -0.01633812114596367,
            -0.007430247496813536,
            0.011844990774989128,
            0.018911464139819145,
            0.03186493739485741,
            0.0034826043993234634,
            0.01400834135711193,
            0.009503427892923355,
            -0.05525923892855644,
            0.003959350287914276,
            -0.011293971911072731,
            0.06500302255153656,
            -0.005731094162911177,
            0.031055638566613197,
            -0.00022000256285537034,
            -0.005202942993491888,
            -0.006569511257112026,
            -0.03798827528953552,
            0.036505360156297684,
            0.037683721631765366,
            -0.02089022286236286,
            0.011419584974646568,
            0.024434607475996017,
            0.0070264521054923534,
            -0.014543278142809868,
            0.0008511752239428461,
            -0.010896053165197372,
            -0.02982717752456665,
            0.026365000754594803,
            0.010584483854472637,
            0.0036297484766691923,
            -0.0022871627006679773,
            0.009795373305678368,
            0.012086676433682442,
            -0.010037421248853207,
            -0.04642622172832489,
            0.012058531865477562,
            0.01900077424943447,
            0.013341606594622135,
            -0.022802088409662247,
            -0.01735297404229641,
            0.042505163699388504,
            -0.038742151111364365,
            0.006698928773403168,
            0.020364277064800262,
            0.02665845677256584,
            0.01169226411730051,
            0.028431974351406097,
            0.03369934856891632,
            -0.03041957877576351,
            -0.006863268557935953,
            0.0013972856104373932,
            -0.011880344711244106,
            -0.02315407060086727,
            -0.009082373231649399,
            -0.010846273973584175,
            -0.01999194547533989,
            0.01415728684514761,
            -0.022852446883916855,
            0.006613192614167929,
            -0.005811209790408611,
            0.017184726893901825,
            -0.03990362584590912,
            -0.01016827579587698,
            -0.011932644993066788,
            -0.031349051743745804,
            -0.0008780311327427626,
            0.01369828637689352,
            -0.01761801354587078,
            -0.061636731028556824,
            0.03556687757372856,
            0.02050372213125229,
            0.01628141663968563,
            -0.00313268369063735,
            0.014258455485105515,
            -0.0057922969572246075,
            0.0046998425386846066,
            -0.014729524031281471,
            0.04430975019931793,
            0.008114537224173546,
            -0.02235749363899231,
            0.028187420219182968,
            0.04454486444592476,
            0.005007237661629915,
            -0.06303203850984573,
            -0.008309424854815006,
            -0.011839408427476883,
            0.027471816167235374,
            -0.013475297950208187,
            0.025365086272358894,
            0.02197865955531597,
            0.023039700463414192,
            0.003029169049113989,
            -0.02655348740518093,
            0.014538832008838654,
            0.022924693301320076,
            -0.023159150034189224,
            -0.00846023764461279,
            0.0038946326822042465,
            0.008262130431830883,
            0.029196269810199738,
            0.03073141910135746,
            -0.0016080827917903662,
            -0.015156001783907413,
            0.011443798430263996,
            0.049677204340696335,
            -0.03777926415205002,
            -0.005995758343487978,
            0.04793144017457962,
            0.03298526257276535,
            -0.017367087304592133,
            0.01964620314538479,
            -0.02992301806807518,
            -0.004538507666438818,
            -0.0006559843895956874,
            0.0369078665971756,
            -0.013778146356344223,
            -0.023337000980973244,
            -0.007659438997507095,
            0.0054453294724226,
            -0.02531975880265236,
            -0.06298821419477463,
            -0.03348084166646004,
            -0.04912567138671875,
            -0.014564999379217625,
            0.00871018972247839,
            0.029238710179924965,
            0.028702156618237495,
            0.00905978586524725,
            -0.016881784424185753,
            -0.06865574419498444,
            -0.06702824681997299,
            0.024509593844413757,
            0.023747563362121582,
            0.022413121536374092,
            -0.0418933629989624,
            0.031380463391542435,
            0.025492625311017036,
            0.026175949722528458,
            -0.043538693338632584,
            -0.015328253619372845,
            -0.021301869302988052,
            0.02178807742893696,
            0.03611208498477936,
            -0.025974564254283905,
            -0.007004525046795607,
            -0.007358208764344454,
            0.014454240910708904,
            -0.006018754094839096,
            -0.002182644559070468,
            -0.009664228186011314,
            -0.010841029696166515,
            0.02586665190756321,
            -0.009519663639366627,
            -0.03845103085041046,
            0.04037930443882942,
            0.0030364319682121277,
            0.052946750074625015,
            -0.0015438062837347388,
            -0.04044223576784134,
            0.005788922309875488,
            -0.020548008382320404,
            -0.030809864401817322,
            0.019486049190163612,
            0.03893990442156792,
            0.015081517398357391,
            0.03743939846754074,
            0.02348184585571289,
            -0.003191883908584714,
            0.006638759281486273,
            -0.03060317598283291,
            0.02053380012512207,
            -0.02223086915910244,
            -0.011455044150352478,
            0.05211210250854492,
            0.009652636013925076,
            -0.00280934851616621,
            -0.008572603575885296,
            0.031450096517801285,
            -0.03312522917985916,
            -0.05730675533413887,
            -0.019447462633252144,
            0.029929185286164284,
            -0.01928004063665867,
            0.034911565482616425,
            -0.021264631301164627,
            -0.0066474624909460545,
            0.005831357091665268,
            -0.06998773664236069,
            -0.012553590349853039,
            0.009009599685668945,
            -0.02396644651889801,
            -0.006912731099873781,
            0.0028283491265028715,
            -0.04953084886074066,
            -0.028629574924707413,
            -0.03032694011926651,
            -3.174486482748762e-05,
            0.04259030520915985,
            -0.049070533365011215,
            -0.01745148003101349,
            -0.014941658824682236,
            -0.02067677490413189,
            -0.005693906452506781,
            0.029917331412434578,
            -0.03354416415095329,
            0.02709074132144451,
            -0.004965611733496189,
            0.0047485907562077045,
            0.04659428820014,
            0.00263286754488945,
            0.02079160511493683,
            0.035556625574827194,
            -0.02353273704648018,
            0.014614587649703026,
            0.0365435853600502,
            -0.007129329722374678,
            -0.004124870058149099,
            -0.0063531361520290375,
            0.0063638631254434586,
            0.031795673072338104,
            0.030692974105477333,
            0.01162585336714983,
            0.03252096474170685,
            0.045465659350156784,
            0.01231537014245987,
            -0.06387470662593842,
            0.019349101930856705,
            0.03019122965633869,
            0.03871835395693779,
            0.024657176807522774,
            -0.017311351373791695,
            0.017224276438355446,
            -0.026209644973278046,
            0.00893836934119463,
            0.021242713555693626,
            0.012109923176467419,
            0.01941528357565403,
            0.032238926738500595,
            -0.02735162526369095,
            -0.03899416700005531,
            0.06053084135055542,
            -0.023969098925590515,
            0.004694984760135412,
            -0.02068837732076645,
            0.0251128152012825,
            0.031459491699934006,
            -0.03325413540005684,
            -0.027742847800254822,
            0.014365684241056442,
            -0.019552165642380714,
            -0.019332971423864365,
            -0.010732553899288177,
            -0.023084567859768867,
            -0.04984588176012039,
            -0.011809379793703556,
            -0.006639308761805296,
            0.007259119302034378,
            -0.037810202687978745,
            0.0435786210000515,
            -0.008304520510137081,
            0.03355777636170387,
            -0.014083231799304485,
            -0.018059367313981056,
            0.009249169379472733,
            0.03099057264626026,
            -0.05863029137253761,
            -0.03395460918545723,
            0.009235592558979988,
            -0.024866243824362755,
            0.004015970975160599,
            -0.0055657606571912766,
            -0.006049616727977991,
            -0.007707613054662943,
            0.033101387321949005,
            0.03871053084731102,
            0.008086442947387695,
            -0.036981549113988876,
            0.012672511860728264,
            0.009547000750899315,
            0.03038891777396202,
            -0.016622398048639297,
            0.02147175744175911,
            -0.023828713223338127,
            -0.04670180380344391,
            -0.022647913545370102,
            0.017985926941037178,
            -0.02233305387198925,
            -0.027966713532805443,
            -0.024579545482993126,
            0.01331374142318964,
            -0.03538363054394722,
            -0.001145674497820437,
            0.05545108765363693,
            -0.007999314926564693,
            0.014384393580257893,
            -0.014790941961109638,
            -0.0040916455909609795,
            0.009408164769411087,
            0.018098250031471252,
            -0.009405005723237991,
            0.00808682106435299,
            0.0028092071879655123,
            0.006011022254824638,
            -0.029972761869430542,
            -0.0005987429758533835,
            0.018773704767227173,
            0.028071340173482895,
            0.024187752977013588,
            0.029296599328517914,
            0.019280634820461273,
            -0.013333682902157307,
            -0.022541388869285583,
            -0.016715019941329956,
            -0.018646756187081337,
            0.017126111313700676,
            -0.027923835441470146,
            -0.021560678258538246,
            0.03801381215453148,
            -0.0005623344914056361,
            -0.041427500545978546,
            0.014185132458806038,
            -0.005182253196835518,
            -0.04766491428017616,
            -0.008485020138323307,
            0.045559048652648926,
            0.03140915557742119,
            -0.008379906415939331,
            0.005386060103774071,
            0.027130838483572006,
            0.02667851559817791,
            -0.02054649032652378,
            0.021621141582727432,
            0.0011786808026954532,
            0.01725643500685692,
            0.03633436560630798,
            -0.027315964922308922,
            0.032700635492801666,
            0.003240856807678938,
            0.024625863879919052,
            -0.006423298269510269,
            -0.017784075811505318,
            -0.03131130337715149,
            0.03805307671427727,
            0.037962619215250015,
            0.015714827924966812,
            0.020220493897795677,
            -0.035961657762527466,
            -0.010173177346587181
        ],
        [
            0.028824210166931152,
            0.0181269608438015,
            -0.02834254875779152,
            0.03714308142662048,
            0.004620686173439026,
            -0.032822683453559875,
            -0.004880321677774191,
            -0.02416052855551243,
            0.005078825633972883,
            -0.011868144385516644,
            0.019151993095874786,
            0.008460793644189835,
            0.036582835018634796,
            0.006180512253195047,
            -0.01246372889727354,
            -0.0006550870020873845,
            -0.0130661865696311,
            0.016845665872097015,
            -0.014901421964168549,
            -0.0037671776954084635,
            0.0009079426527023315,
            0.01957123912870884,
            -0.022048471495509148,
            -0.0325145423412323,
            0.010223448276519775,
            -0.02485877461731434,
            -0.025346234440803528,
            0.005468893330544233,
            0.024394502863287926,
            -0.0055650281719863415,
            0.026265516877174377,
            0.0019995253533124924,
            0.019102375954389572,
            0.013418849557638168,
            -0.016027729958295822,
            -0.018596086651086807,
            0.012595348060131073,
            0.002892749384045601,
            0.011275084689259529,
            -0.006074076052755117,
            0.028099270537495613,
            -0.009512986056506634,
            -0.010473296977579594,
            0.02138447016477585,
            0.02275029942393303,
            -0.02008606679737568,
            -0.006753815803676844,
            0.01753394305706024,
            0.014257597737014294,
            0.0035383712965995073,
            -0.008221015334129333,
            0.030153686180710793,
            0.021396923810243607,
            -0.02277723141014576,
            -0.024910686537623405,
            0.031847093254327774,
            -0.0028111750725656748,
            0.026547178626060486,
            -0.002629833994433284,
            0.018823355436325073,
            0.01608048751950264,
            0.004667854402214289,
            -0.0011832988820970058,
            0.022154180333018303,
            0.026724694296717644,
            -0.009226353839039803,
            0.003978668246418238,
            -0.009465161710977554,
            -0.01711379364132881,
            -0.0092945396900177,
            0.02228967286646366,
            0.00641933549195528,
            -0.03174898028373718,
            -0.0012988317757844925,
            0.005305290687829256,
            -0.003019017167389393,
            -0.025936076417565346,
            0.03203856572508812,
            -0.020858928561210632,
            0.02226094715297222,
            0.009203053079545498,
            -0.0067602600902318954,
            -0.008103194646537304,
            0.020060909911990166,
            -0.005940054077655077,
            -0.03092520870268345,
            0.021965231746435165,
            0.01884969137609005,
            -0.020716138184070587,
            -0.02504465915262699,
            0.00020382931688800454,
            0.0027006526943296194,
            0.01969047635793686,
            0.0019778436981141567,
            -0.00944542046636343,
            0.0056170690804719925,
            -0.02213355526328087,
            0.00961493980139494,
            0.012479417957365513,
            -0.017399173229932785,
            -0.0030419821850955486,
            0.018968192860484123,
            -0.014577669091522694,
            -0.020017500966787338,
            -0.0023372278083115816,
            -0.037662215530872345,
            -0.03057050332427025,
            -0.032148879021406174,
            0.02327674999833107,
            0.018901919946074486,
            0.0453798770904541,
            0.01123665552586317,
            0.013332481496036053,
            -0.011301717720925808,
            0.013657809235155582,
            0.008358393795788288,
            0.030864853411912918,
            -0.0028373366221785545,
            -0.012711236253380775,
            0.012812954373657703,
            -0.029034562408924103,
            -0.000996039598248899,
            -0.02834402397274971,
            0.0013492082944139838,
            0.0028275575023144484,
            0.021169986575841904,
            -0.008003580383956432,
            0.022365983575582504,
            0.009248951449990273,
            -0.013826098293066025,
            0.002179462695494294,
            -0.005866787862032652,
            -0.017046203836798668,
            -0.009496001526713371,
            -0.03150477260351181,
            -0.028143854811787605,
            0.0219098050147295,
            0.003126224037259817,
            0.025265894830226898,
            -0.007030458189547062,
            0.018740389496088028,
            0.011159563437104225,
            0.006623079534620047,
            -0.007593994494527578,
            0.0037871890235692263,
            -0.008291839621961117,
            0.019428063184022903,
            0.010924122296273708,
            -0.024719633162021637,
            0.012401341460645199,
            0.02052248641848564,
            -0.024163680151104927,
            0.021907545626163483,
            -0.03706583380699158,
            0.019991323351860046,
            0.018152711912989616,
            0.006408532615751028,
            -0.020083194598555565,
            -0.0017708379309624434,
            -0.024137355387210846,
            0.014749989844858646,
            -0.010845798067748547,
            -0.019230572506785393,
            0.010409279726445675,
            -0.018374081701040268,
            -0.05126699060201645,
            0.01598191261291504,
            -0.05779951065778732,
            0.011658759787678719,
            -0.016083458438515663,
            0.033933259546756744,
            -0.04805975407361984,
            -0.04378548637032509,
            0.00852493941783905,
            -0.0047142417170107365,
            0.009329650551080704,
            0.0018577512819319963,
            0.03536251559853554,
            0.012328753247857094,
            0.02821449749171734,
            0.021887999027967453,
            -0.003618749091401696,
            -0.006919311825186014,
            -0.03103294037282467,
            -0.012120037339627743,
            0.022731363773345947,
            0.011175554245710373,
            0.021044857800006866,
            -0.01861564628779888,
            0.034110069274902344,
            -0.017938358709216118,
            0.020374730229377747,
            -0.005674436688423157,
            -0.03555454686284065,
            0.0009327505249530077,
            -0.024184655398130417,
            -0.011691282503306866,
            0.028747614473104477,
            -0.0017742278287187219,
            -0.0012516308343037963,
            -0.018259231001138687,
            0.017201699316501617,
            -0.020501254126429558,
            -0.026358865201473236,
            0.008198116905987263,
            0.033853303641080856,
            -0.00317538995295763,
            -0.009034693241119385,
            -0.01628177799284458,
            -0.026615124195814133,
            0.027049418538808823,
            -0.0014009022852405906,
            -0.025966353714466095,
            -0.019603822380304337,
            0.0076690069399774075,
            -0.01581832394003868,
            0.004126005806028843,
            0.00869521964341402,
            0.027871355414390564,
            -0.015326520428061485,
            -0.018425749614834785,
            -0.0011997245019301772,
            -0.00736084533855319,
            -0.03484615311026573,
            -0.0012755384668707848,
            0.0012082533212378621,
            -0.007065266836434603,
            -0.004420417360961437,
            -0.00830540619790554,
            0.02438424900174141,
            -0.014356273226439953,
            -0.024337030947208405,
            0.018885863944888115,
            0.02441335842013359,
            -0.027704110369086266,
            -0.02314811386168003,
            0.014410804025828838,
            0.012981534935534,
            -0.01267580408602953,
            0.020249906927347183,
            -0.014670176431536674,
            0.010977902449667454,
            -0.0049518560990691185,
            0.006085032597184181,
            -0.022096004337072372,
            0.02497655153274536,
            0.010579878464341164,
            0.030723288655281067,
            0.030371790751814842,
            0.01381800975650549,
            -0.017464060336351395,
            -0.0038920650258660316,
            -0.034084271639585495,
            0.02107996866106987,
            0.019347593188285828,
            0.02323908917605877,
            0.023911593481898308,
            -0.022208286449313164,
            -0.011410241015255451,
            0.031237909570336342,
            4.095058829989284e-05,
            -0.0306581798940897,
            -0.03391275182366371,
            -0.015556074678897858,
            0.01201233733445406,
            -0.021632404997944832,
            0.013290469534695148,
            0.016135631129145622,
            0.004920264706015587,
            -0.015124737285077572,
            0.04100000858306885,
            0.007984330877661705,
            -0.005526077933609486,
            0.041015733033418655,
            0.0011772515717893839,
            -0.03371848538517952,
            0.026232987642288208,
            -0.01364200096577406,
            -0.002553551457822323,
            -0.023945149034261703,
            -0.003415406448766589,
            0.005592936649918556,
            0.0005492393393069506,
            -0.04113629460334778,
            0.011344429105520248,
            0.029728898778557777,
            -0.029052497819066048,
            0.025334034115076065,
            -0.0262202937155962,
            0.024827254936099052,
            -0.022844675928354263,
            -0.028648484498262405,
            0.020456327125430107,
            0.01034542080014944,
            -0.0028041463810950518,
            0.027327265590429306,
            -0.007856648415327072,
            0.017120342701673508,
            -0.0051683830097317696,
            -0.02411113865673542,
            -0.006393725052475929,
            0.040945038199424744,
            -0.021741734817624092,
            -0.016165411099791527,
            0.023405231535434723,
            -0.02772197127342224,
            0.009772283025085926,
            -0.024430735036730766,
            -0.03253978490829468,
            -0.01493372768163681,
            -0.0369020514190197,
            0.02784017100930214,
            -0.02683342806994915,
            -0.01983507163822651,
            0.027976518496870995,
            -0.01469327975064516,
            0.02850663848221302,
            0.025056732818484306,
            0.0028138370253145695,
            0.019557343795895576,
            -0.012520909309387207,
            0.02532542310655117,
            -0.012502351775765419,
            -0.008991203270852566,
            -0.014567866921424866,
            -0.009219559840857983,
            0.012732299976050854,
            -0.028750518336892128,
            -0.0048521957360208035,
            0.0032505716662853956,
            0.014206424355506897,
            -0.022818056866526604,
            0.029757371172308922,
            -0.00813031755387783,
            0.013552971184253693,
            -0.033665236085653305,
            0.011350072920322418,
            0.017109068110585213,
            -0.013787473551928997,
            0.009092104621231556,
            0.026755161583423615,
            0.018750641494989395,
            -0.03340882062911987,
            -0.027297625318169594,
            0.02752513252198696,
            -0.019579924643039703,
            0.05017417296767235,
            -0.01312234252691269,
            -0.012072766199707985,
            -0.016778524965047836,
            -0.016807248815894127,
            0.016002938151359558,
            0.02943866327404976,
            -0.012741238810122013,
            -0.015644706785678864,
            -0.01257940474897623,
            -0.0017367658438161016,
            0.03782067075371742,
            0.02684151940047741,
            0.026933865621685982,
            0.033379945904016495,
            0.017487218603491783,
            0.03678898885846138,
            -0.0355459600687027,
            0.0027396699879318476,
            0.004695887211710215,
            0.050206977874040604,
            -0.02665090560913086,
            0.0038519708905369043,
            0.02023179829120636,
            0.013047095388174057,
            -0.008421754464507103,
            0.005912979133427143,
            -0.02425692416727543,
            -0.014756616204977036,
            0.0385265089571476,
            -0.0032943065743893385,
            -0.01795979030430317,
            0.04303593933582306,
            0.00987867172807455,
            0.02668517455458641,
            -0.001371662481687963,
            0.014667575247585773,
            0.019909704104065895,
            0.006631573662161827,
            0.00861882884055376,
            0.00786988902837038,
            -0.0018009251216426492,
            -0.011919556185603142,
            0.016534904018044472,
            -0.02307162992656231,
            -0.02126992680132389,
            0.0012504537589848042,
            -0.0038512798491865396,
            -0.027312524616718292,
            -0.01322405319660902,
            -0.00038780958857387304,
            -0.004172125831246376,
            0.02456919103860855,
            -0.003497568191960454,
            -0.02641230635344982,
            -0.009966785088181496,
            0.01768985390663147,
            0.01539815403521061,
            0.006365154404193163,
            -0.021341955289244652,
            0.03061142936348915,
            -0.011816606856882572,
            0.02892271615564823,
            -0.02444581501185894,
            0.010763276368379593,
            -0.002518151421099901,
            -0.02408718504011631,
            -0.03449743613600731,
            -0.023863960057497025,
            0.028597021475434303,
            0.011821048334240913,
            0.024991873651742935,
            0.010243491269648075,
            -0.016669606789946556,
            0.03088957443833351,
            -0.04067396745085716,
            0.03546646609902382,
            0.03149795904755592,
            0.009649638086557388,
            -0.038443975150585175,
            -0.00752432132139802,
            -0.0386149138212204,
            -0.020686175674200058,
            0.012457006610929966,
            0.0033175202552229166,
            -0.010112113319337368,
            -9.867938933894038e-05,
            0.023093201220035553,
            0.023727398365736008,
            0.006147292442619801,
            0.024437692016363144,
            0.00916979368776083,
            0.02339744381606579,
            -0.027087312191724777,
            0.008486432954668999,
            -0.03603542596101761,
            0.04125552996993065,
            0.016017163172364235,
            0.00667461846023798,
            0.03017415478825569,
            -0.01679197885096073,
            0.02591947838664055,
            -0.016546238213777542,
            -0.005658883135765791,
            -0.0313846692442894,
            -0.0002716381859499961,
            -0.0027596582658588886,
            -0.018832245841622353,
            -0.0014580308925360441,
            0.025003664195537567,
            -0.019008103758096695,
            -0.00028256780933588743,
            0.03963981196284294,
            -0.0010615078499540687,
            0.03270221874117851,
            0.003360381815582514,
            -0.0025169046130031347,
            0.012157994322478771,
            0.00030943445744924247,
            -0.018787739798426628,
            0.014191110618412495,
            -0.013364527374505997,
            -0.02379801869392395,
            0.02193654142320156,
            0.002834124956279993,
            0.01572161167860031,
            0.013114072382450104,
            -0.0024854103103280067,
            -0.02930031158030033,
            -0.004214635118842125,
            0.0076733543537557125,
            0.013906782492995262,
            -0.003061368828639388,
            -0.010527592152357101,
            -0.0183700080960989,
            -0.046355221420526505,
            -0.03198269382119179,
            -0.007232533302158117,
            4.69940532639157e-05,
            0.014650885947048664,
            0.026530534029006958,
            0.032625772058963776,
            -0.03331472724676132,
            -0.02740432135760784,
            0.021387023851275444,
            -0.007053047884255648,
            0.013408050872385502,
            0.0073888846673071384,
            -0.003690622514113784,
            -0.030974797904491425,
            -0.031565189361572266,
            -0.006493876688182354,
            0.01696937344968319,
            -0.005048910155892372,
            0.03470904007554054,
            -0.02893013320863247,
            0.014457283541560173,
            0.004783329088240862,
            0.019612975418567657,
            -0.014961153268814087,
            0.007991507649421692,
            -0.01496958453208208,
            0.023542534559965134,
            -0.027079185470938683,
            0.0006230325670912862,
            -0.050905805081129074,
            -0.003843429032713175,
            -0.015272822231054306,
            0.022659048438072205,
            0.0034713461063802242,
            0.003511887975037098,
            0.007812622003257275,
            0.018702656030654907,
            -0.018842797726392746,
            0.018911872059106827,
            0.022921698167920113,
            -0.007709029596298933,
            0.018235115334391594,
            0.018437150865793228,
            3.832591755781323e-05,
            0.015160461887717247,
            -0.023072492331266403,
            -0.024832013994455338,
            -0.020849475637078285,
            -0.019986974075436592,
            -0.0204630084335804,
            -0.010444185696542263,
            -0.008760541677474976,
            -0.012801270931959152,
            -0.01377932820469141,
            0.02536105550825596,
            0.012537449598312378,
            -0.04184587672352791,
            -0.0017320469487458467,
            0.014309442602097988,
            -0.0027974899858236313,
            0.034889571368694305,
            0.004143100697547197,
            0.009919355623424053,
            0.035329531878232956,
            -0.02050679735839367,
            -0.05036427453160286,
            0.01294491346925497,
            -0.02115696668624878,
            -0.024737434461712837,
            0.012212956324219704,
            -0.02438017725944519,
            0.015972036868333817,
            0.0058698104694485664,
            0.01355015393346548,
            -0.008510240353643894,
            0.013699596747756004,
            -0.029993485659360886,
            0.022240635007619858,
            0.030852967873215675,
            -0.0009382928255945444,
            -0.00885800737887621,
            -0.051086682826280594,
            -0.020878851413726807,
            0.02637620083987713,
            0.023168452084064484,
            0.008960932493209839,
            -0.00532591762021184,
            0.006555513944476843,
            0.026363486424088478,
            0.01699575036764145,
            0.02472751773893833,
            -0.015001745894551277,
            -0.0034718764945864677,
            -0.02658281847834587,
            0.031339261680841446,
            -0.02038172446191311,
            -0.01963919959962368,
            -0.01194598339498043,
            0.029531721025705338,
            -0.0288899764418602,
            0.038799259811639786,
            -0.026955267414450645,
            0.02430025115609169,
            0.024013128131628036,
            -0.011757596395909786,
            -0.02612503431737423,
            0.019136279821395874,
            -0.0006935229175724089,
            0.00886628683656454,
            0.016462232917547226,
            0.0019261541310697794,
            0.015345427207648754,
            0.018106475472450256,
            -0.009920130483806133,
            -0.01839560642838478,
            -0.009394064545631409,
            0.010876788757741451,
            0.01942717656493187,
            -0.03446325659751892,
            -0.005073644686490297,
            -0.015303544700145721,
            0.002533088903874159,
            0.0286448635160923,
            -0.018555061891674995,
            0.005438561085611582,
            0.02208034135401249,
            -0.03200064226984978,
            -0.013966058380901814,
            0.01899808831512928,
            0.028515266254544258,
            0.0012906843330711126,
            0.024049747735261917,
            -0.012946155853569508,
            -0.03469159081578255,
            0.00461373059079051,
            0.00773790804669261,
            0.012246709316968918,
            -0.002071568975225091,
            0.0047490233555436134,
            0.018870636820793152,
            0.02130385860800743,
            0.001859252923168242,
            0.023819366469979286,
            -0.025267988443374634,
            0.02549990452826023,
            -0.00466108787804842,
            0.0005954679800197482,
            0.01941012218594551,
            5.294171569403261e-05,
            0.0011733713326975703,
            -0.022893885150551796,
            -0.002716091927140951,
            0.010725305415689945,
            0.0270018819719553,
            0.017098169773817062,
            0.026130862534046173,
            0.006391533184796572,
            -0.007199104409664869,
            0.02167425863444805,
            -0.06462733447551727,
            -0.04122569411993027,
            0.0064370590262115,
            -0.02372325025498867,
            0.00468648923560977,
            0.030239610001444817,
            -0.0022669113241136074,
            -0.011870128102600574,
            0.026877708733081818,
            -0.02004966512322426,
            -0.01575917936861515,
            0.031622130423784256,
            0.002382635371759534,
            -0.01235632412135601,
            0.021995289251208305,
            -0.03363305330276489,
            0.03240232914686203,
            -0.027239102870225906,
            0.0054231914691627026,
            0.008895228616893291,
            0.007959749549627304,
            -0.01328387949615717,
            0.009744872339069843,
            -0.023998571559786797,
            0.0034028554800897837,
            -0.010436991229653358,
            0.010225912556052208,
            0.009147942997515202,
            -0.02967202477157116,
            0.027423042804002762,
            -0.04594181850552559,
            -0.027030333876609802,
            0.014477632939815521,
            -0.01909821853041649,
            0.03579827398061752,
            0.0018332692561671138,
            0.019396930932998657,
            -0.024864738807082176,
            -0.028113091364502907,
            0.02217732183635235,
            -0.028915874660015106,
            0.025304896757006645,
            -0.01814993843436241,
            -0.0043624467216432095,
            0.014638560824096203,
            -0.06262892484664917,
            0.02542804554104805,
            0.003973884508013725,
            0.018499962985515594,
            0.038872577250003815,
            -0.017413796856999397,
            -0.032788392156362534,
            0.02450217865407467,
            0.0013862597988918424,
            -0.04257219284772873,
            0.0038388557732105255,
            -0.027273215353488922,
            -0.01316270511597395,
            -0.012962219305336475,
            0.028440671041607857,
            0.022535817697644234,
            0.01437599491328001,
            -0.027972498908638954,
            0.006192762870341539,
            0.013343350030481815,
            0.010400993749499321,
            0.0020066318102180958,
            0.001560870441608131,
            -0.02091432735323906,
            -0.03073985129594803,
            0.008757985197007656,
            0.02891397848725319,
            0.020616546273231506,
            0.03110642172396183,
            -0.016752896830439568,
            -0.010012398473918438,
            -0.021910732612013817,
            -0.028527528047561646,
            -0.020430633798241615,
            -0.014377801679074764,
            -0.018681619316339493,
            -0.023999707773327827,
            0.01524752750992775,
            -0.023145977407693863,
            -0.0013805667404085398,
            0.02418888360261917,
            0.03972351923584938,
            0.02789742313325405,
            0.011661846190690994,
            -0.005117175169289112,
            -0.030735943466424942,
            -0.02175738476216793,
            0.005159189458936453,
            -0.03325618803501129,
            -0.014271441847085953,
            -0.016487622633576393,
            -0.011575546115636826,
            0.05452515557408333,
            -0.01187844481319189,
            0.02469560131430626,
            -0.004263637587428093,
            -0.011587492190301418,
            0.014814119786024094,
            0.024574093520641327,
            -0.021846462041139603,
            0.033257827162742615,
            -0.02590358443558216,
            0.01844056323170662,
            0.007294082548469305,
            -0.014472348615527153,
            0.034024350345134735,
            0.03195687010884285,
            -0.021622734144330025,
            -0.022190269082784653,
            0.016738371923565865,
            0.008342021144926548,
            0.058969754725694656,
            0.01591973938047886,
            -0.024963445961475372,
            -0.06324951350688934,
            -0.021260390058159828,
            -0.003031264990568161,
            -0.038119081407785416,
            0.0031685756985098124,
            -0.031173691153526306,
            0.030591243878006935,
            -0.007994048297405243,
            -0.024014178663492203,
            0.006622426677495241,
            0.015565105713903904,
            0.04261171817779541,
            0.031940340995788574,
            -0.01026778295636177,
            -0.006456020753830671,
            -0.01962066814303398,
            0.029638981446623802,
            -0.040030304342508316,
            -0.018659371882677078,
            0.02271081879734993,
            0.013599468395113945,
            -0.0005393092869780958,
            0.008661243133246899,
            -0.014221995137631893,
            -0.005914324428886175,
            -0.014579464681446552,
            -0.010268614627420902,
            -0.030428707599639893,
            -0.03167812526226044,
            -0.008827420882880688,
            0.007838578894734383,
            0.024277126416563988,
            -0.01864788495004177,
            -0.015636591240763664,
            -0.011547056958079338,
            0.04137209802865982,
            0.01827116496860981,
            -0.013061034493148327,
            0.00466505391523242,
            -0.0012951157987117767,
            -0.0197009090334177,
            -0.011902771890163422,
            0.019032403826713562,
            0.029144354164600372,
            0.021947339177131653,
            -0.006982622668147087,
            0.004414880182594061,
            0.009600772522389889,
            0.024222366511821747,
            -0.021552719175815582,
            0.029360201209783554,
            0.004676942713558674,
            0.011274966411292553,
            0.0033459605183452368,
            -0.01754922606050968,
            0.018813349306583405,
            -0.019599590450525284,
            -0.008180942386388779,
            -0.0021627950482070446,
            0.014367767609655857,
            -0.025932565331459045,
            0.025391193106770515,
            0.026357974857091904,
            -0.003941582981497049,
            0.01661360077559948,
            0.011013939045369625,
            0.00013105921971146017,
            0.00839491467922926,
            0.017500028014183044,
            -0.020073488354682922,
            -0.00387143949046731,
            0.027856184169650078,
            -0.012607881799340248,
            0.0040110996924340725,
            -0.08248947560787201,
            -0.005124596878886223,
            -0.002384534338489175,
            0.02896302565932274,
            -0.011840597726404667,
            0.004356931895017624,
            0.012982221320271492,
            -0.01583828590810299,
            0.01722264476120472,
            -0.0027149091474711895,
            -0.008708163164556026,
            0.007018788252025843,
            0.0026983383577317,
            -0.016097577288746834,
            0.02922241948544979,
            -0.03684978559613228,
            0.023540865629911423,
            0.01717507839202881,
            0.007940663024783134,
            0.008193803019821644,
            0.03901335224509239,
            -0.029363175854086876,
            -0.020173801109194756,
            -0.0012093327241018414,
            0.032019082456827164,
            -0.026425519958138466,
            -0.0030807156581431627,
            0.004732296336442232,
            0.010335242375731468,
            0.02389383129775524,
            -0.03623810037970543,
            -0.0034728115424513817,
            0.02810344472527504,
            -0.02148657850921154,
            -0.023096857592463493,
            -0.01996137760579586,
            0.02704307995736599,
            0.028081031516194344,
            0.04385576769709587,
            0.0231919102370739,
            0.006264975760132074,
            -0.013588438741862774,
            0.004466257989406586,
            0.0023996862582862377,
            0.0016894069267436862,
            0.02739003300666809,
            0.031368549913167953,
            -0.002627864945679903,
            -0.017206363379955292,
            -0.022688228636980057,
            -0.04165639728307724,
            -0.0013178816298022866,
            -0.024421820417046547,
            0.01802353747189045,
            -0.0024094656109809875,
            0.025423305109143257,
            0.006727010942995548,
            -0.02182452566921711,
            0.022746415808796883,
            0.01609686203300953,
            -0.002370102796703577,
            -0.029896501451730728,
            0.017335521057248116,
            -0.004248700570315123,
            -0.02097393199801445,
            -0.014140483923256397,
            -0.023603852838277817,
            0.01275195088237524,
            0.011501558125019073,
            -0.022996721789240837,
            -0.011857371777296066,
            0.018464118242263794,
            -0.0033138568978756666,
            0.004632140044122934,
            0.03857298940420151,
            0.01070878840982914,
            -0.003837263211607933,
            0.03053600899875164,
            -0.014011289924383163,
            -0.052823878824710846,
            -0.000497778004501015,
            -0.028705811128020287,
            -0.030239572748541832,
            -0.01632070727646351,
            0.009628849104046822,
            0.012942047789692879,
            -0.03281351178884506,
            0.026730407029390335,
            0.00995989516377449,
            0.024787429720163345,
            0.014910858124494553,
            -0.05908329039812088,
            0.011815452016890049,
            -0.03144266456365585,
            0.010216033086180687,
            -0.005112655460834503,
            -0.02115780860185623,
            0.004199759103357792,
            0.00289217964746058,
            0.012392851524055004,
            -0.022393783554434776,
            -0.0048166243359446526,
            0.03044287860393524,
            -0.008541499264538288,
            0.0029683792963624,
            -0.03551334887742996,
            0.006274079903960228,
            0.00946379080414772,
            -0.013421257957816124,
            0.029727671295404434,
            -0.05642208456993103,
            0.01022870559245348,
            0.0194223765283823,
            0.03695007786154747,
            -0.0020804437808692455,
            -0.01847393438220024,
            -0.01601380668580532,
            -0.019896375015378,
            0.012677213177084923,
            -0.011197180487215519,
            0.041707489639520645,
            0.0035860552452504635,
            -0.01185125857591629,
            -0.025753367692232132,
            0.03785258159041405,
            0.022781826555728912,
            0.019371071830391884,
            0.014752920717000961,
            0.00043714052299037576,
            0.009638851508498192,
            0.026679741218686104,
            0.03323035314679146,
            -0.048852235078811646,
            0.012868054211139679,
            0.018499847501516342,
            0.022850120440125465,
            -0.019181028008461,
            0.014441223815083504,
            0.015374071896076202,
            -0.013235789723694324,
            0.016003280878067017,
            0.004535859450697899,
            -0.0042730714194476604,
            0.02213294804096222,
            0.027544407173991203,
            0.024012457579374313,
            -0.012636392377316952,
            0.0017871311865746975,
            0.042532291263341904,
            0.010209226980805397,
            0.020912181586027145,
            -0.018575813621282578,
            0.023035088554024696,
            -1.3917406249674968e-05,
            -0.019450275227427483,
            -0.01433237548917532,
            -0.017364826053380966,
            0.008261051960289478,
            -0.006523997522890568,
            -0.015906497836112976,
            0.00849537830799818,
            -0.003840966382995248,
            -0.0013914796290919185,
            -0.004959041718393564,
            0.004178204108029604,
            0.030244965106248856,
            -0.02614133059978485,
            0.01979033462703228,
            0.009190772660076618,
            -0.023012496531009674,
            -0.036165930330753326,
            -0.012733994983136654,
            -0.003204863052815199,
            0.036729976534843445,
            -0.01980944536626339,
            0.008726431056857109,
            0.01246856339275837,
            -0.027104951441287994,
            -0.007526807952672243,
            0.00883292406797409,
            0.0013483250513672829,
            0.007368566934019327,
            -0.03358198329806328,
            0.014788193628191948,
            -0.03960509970784187,
            0.01455321442335844,
            0.007288177963346243,
            0.016182098537683487,
            0.03453804925084114,
            0.013115981593728065
        ],
        [
            0.01519019901752472,
            0.032028958201408386,
            0.011785087175667286,
            -0.05545441433787346,
            -0.023204565048217773,
            0.009519204497337341,
            0.010990878567099571,
            -0.034013666212558746,
            -0.02421940490603447,
            0.005603642202913761,
            0.0007809647941030562,
            -0.019915292039513588,
            0.03179524466395378,
            -0.0037808723282068968,
            0.007994597777724266,
            -0.046158984303474426,
            -0.0035854533780366182,
            -0.019129546359181404,
            -0.00642949715256691,
            0.0042601400054991245,
            0.0391232892870903,
            -0.00371347414329648,
            0.05277203395962715,
            -0.005794009193778038,
            -0.012329354882240295,
            0.03720100596547127,
            0.008074484765529633,
            -0.06627778708934784,
            0.018859270960092545,
            0.0255619864910841,
            -0.024408798664808273,
            -0.018763305619359016,
            -0.004989100620150566,
            0.020590966567397118,
            -0.020933780819177628,
            0.00886664167046547,
            0.02272430993616581,
            0.023798801004886627,
            0.03632060065865517,
            -0.012730376794934273,
            -0.0018091623205691576,
            0.02460855431854725,
            -0.023894183337688446,
            0.029506321996450424,
            -0.028282826766371727,
            0.02832847833633423,
            0.006227979436516762,
            -0.014822943136096,
            -0.012912440113723278,
            0.04489372670650482,
            0.01309183333069086,
            -0.01322888396680355,
            0.016956431791186333,
            0.0368586964905262,
            -0.04685501754283905,
            -0.03310573473572731,
            -0.003403537208214402,
            0.034018322825431824,
            -0.016832442954182625,
            0.020416518673300743,
            -0.010805903933942318,
            -0.007407486438751221,
            0.009271807968616486,
            0.02352834865450859,
            -0.013280129060149193,
            -0.037409909069538116,
            -0.009041044861078262,
            0.0022816238924860954,
            -0.015577609650790691,
            -0.016426922753453255,
            -0.017222370952367783,
            -0.0060335611924529076,
            0.022917499765753746,
            0.01560614537447691,
            -0.004830107092857361,
            0.0055046300403773785,
            0.014061612077057362,
            -0.0272373054176569,
            0.014525760896503925,
            0.005625247489660978,
            0.030785810202360153,
            -0.007563310209661722,
            0.023525962606072426,
            0.013828599825501442,
            0.008341320790350437,
            0.023635495454072952,
            -0.006244547199457884,
            0.049854427576065063,
            0.009113214910030365,
            -0.019903549924492836,
            -0.0260168369859457,
            -0.0015277527272701263,
            0.002193703083321452,
            -0.08180459588766098,
            0.013197718188166618,
            -0.011840532533824444,
            -0.03997886925935745,
            -0.03610816225409508,
            0.04018494486808777,
            -0.026046497747302055,
            -0.03459316864609718,
            -0.02215946838259697,
            0.009096641093492508,
            0.02819868177175522,
            -0.025064850226044655,
            0.01180278044193983,
            0.03096279501914978,
            0.011638317257165909,
            0.009429345838725567,
            -0.000247253745328635,
            0.04504035785794258,
            -0.001271696644835174,
            -0.0450756661593914,
            -0.01785019040107727,
            -0.04190303757786751,
            0.028896234929561615,
            -0.024976566433906555,
            -0.008688307367265224,
            -0.0039777737110853195,
            -0.008205571211874485,
            0.03149925544857979,
            0.003931462299078703,
            -0.0442427359521389,
            -0.025510026142001152,
            -0.003459855215623975,
            -0.015476297587156296,
            -0.0007587861618958414,
            0.010330119170248508,
            -0.02636156976222992,
            -0.012283358722925186,
            0.012971063144505024,
            -0.011205395683646202,
            0.015764936804771423,
            0.0166497603058815,
            -0.020404482260346413,
            -0.024453483521938324,
            0.03084339201450348,
            -0.00955622922629118,
            0.005822008941322565,
            0.02342822402715683,
            -0.004504724405705929,
            -0.0002406065323157236,
            0.02711091935634613,
            -0.04486775025725365,
            0.003048769198358059,
            -0.03345200791954994,
            0.03800051286816597,
            -0.008384191431105137,
            0.03383602946996689,
            0.0026746231596916914,
            -0.005769531708210707,
            -0.012417485937476158,
            -0.04649314284324646,
            -0.01106543280184269,
            -0.00148474529851228,
            -0.02250477485358715,
            -0.03039107657968998,
            -0.04311235621571541,
            0.025771064683794975,
            -0.005249400157481432,
            0.02138764224946499,
            0.005155617371201515,
            0.027189558371901512,
            -0.00677052466198802,
            -0.006691807880997658,
            -0.01726675219833851,
            -0.0015221280045807362,
            0.008901529014110565,
            0.028022339567542076,
            -0.029561221599578857,
            0.024700457230210304,
            0.02417345531284809,
            0.025014040991663933,
            -0.0016845227219164371,
            0.0017579508712515235,
            0.011931235902011395,
            -0.014128773473203182,
            0.018920481204986572,
            -0.003574689384549856,
            -0.019908878952264786,
            0.033747732639312744,
            -0.010437184944748878,
            0.0006504982593469322,
            -0.004650057293474674,
            0.005404777359217405,
            0.030197657644748688,
            -0.0320744514465332,
            -0.00286914617754519,
            0.010303432121872902,
            0.014112809672951698,
            0.026754463091492653,
            -0.01836708001792431,
            -0.027102701365947723,
            0.01629810594022274,
            0.024247411638498306,
            0.017991814762353897,
            0.021273573860526085,
            0.009600170888006687,
            0.008607282303273678,
            0.007481625769287348,
            0.030157582834362984,
            0.023559870198369026,
            0.0019205114804208279,
            0.018607305362820625,
            0.043760284781455994,
            -0.04692768678069115,
            -0.02545376867055893,
            -0.03514248877763748,
            -0.008990487083792686,
            0.002657749690115452,
            0.001639665919356048,
            -0.019696641713380814,
            0.05645042285323143,
            0.0010646587470546365,
            0.002535810461267829,
            0.0233378317207098,
            0.031907107681035995,
            0.0335221029818058,
            0.016489896923303604,
            -0.03310056030750275,
            -0.020137110725045204,
            -0.009079711511731148,
            0.05133691430091858,
            0.024136176332831383,
            0.024888301268219948,
            -0.053044188767671585,
            -0.013338970020413399,
            -0.021030044183135033,
            0.047142144292593,
            -0.0024710772559046745,
            0.03352433070540428,
            -0.026990562677383423,
            -0.004542912356555462,
            0.03628818318247795,
            0.005033034831285477,
            0.036761511117219925,
            -0.023488571867346764,
            -0.003887258702889085,
            0.011867614462971687,
            -0.019215906038880348,
            0.008531369268894196,
            0.007738787215203047,
            0.029058553278446198,
            0.019236331805586815,
            -0.003567952662706375,
            0.0021362584084272385,
            0.01120228972285986,
            -0.01792752742767334,
            -0.022469528019428253,
            0.012815686874091625,
            0.013225734233856201,
            -0.021528106182813644,
            0.00550058763474226,
            -0.012507304549217224,
            0.02365337871015072,
            0.018798893317580223,
            -0.009286802262067795,
            -0.014114770106971264,
            -0.03312790021300316,
            -0.010309051722288132,
            0.012393740005791187,
            0.004446116741746664,
            0.008892502635717392,
            0.012420972809195518,
            -0.008646511472761631,
            -0.0812639370560646,
            -0.02351788990199566,
            -0.015228214673697948,
            -0.0094484006986022,
            -0.013392345048487186,
            0.007928168401122093,
            0.008130066096782684,
            0.00273560406640172,
            0.02185758203268051,
            0.020123975351452827,
            -0.015143918804824352,
            0.019957372918725014,
            0.006562136113643646,
            -0.014971993863582611,
            -0.00042140434379689395,
            -0.018040457740426064,
            0.028630539774894714,
            -0.019266152754426003,
            0.00996733084321022,
            -0.01762794889509678,
            -0.0007664389559067786,
            -0.04706321284174919,
            -0.04786674678325653,
            -0.004438554402440786,
            -0.0043122973293066025,
            0.018668675795197487,
            -0.0024436796084046364,
            -0.009305303916335106,
            -0.029857322573661804,
            -0.005934780463576317,
            0.017819181084632874,
            0.02733234129846096,
            0.009772108867764473,
            0.011162527836859226,
            -0.004060409031808376,
            -0.020551707595586777,
            0.00767912995070219,
            0.012602366507053375,
            -0.018373459577560425,
            0.039277058094739914,
            0.019497854635119438,
            0.025464890524744987,
            -0.02664794772863388,
            -0.019525401294231415,
            0.042462192475795746,
            -0.005551345180720091,
            0.018889930099248886,
            -0.001966256182640791,
            -0.02631787396967411,
            0.01460323017090559,
            -0.05345841497182846,
            0.01853064075112343,
            0.028552988544106483,
            -0.04155459627509117,
            0.004283868707716465,
            -0.010921931825578213,
            0.05589941516518593,
            -0.016713794320821762,
            -0.018236840143799782,
            0.00475086085498333,
            0.01622300036251545,
            0.017248423770070076,
            -0.02249324694275856,
            -0.04871807247400284,
            -0.006731558591127396,
            0.03050874173641205,
            0.005331011954694986,
            -0.037415698170661926,
            -0.04637707769870758,
            -0.011947362683713436,
            -0.025363506749272346,
            -0.0038710255175828934,
            -0.00830957479774952,
            0.03865405544638634,
            0.012681999243795872,
            0.04712323471903801,
            0.004672605078667402,
            -0.0055233146995306015,
            0.0011109766783192754,
            0.0286549162119627,
            0.012205099686980247,
            -0.007356594782322645,
            -0.008021675050258636,
            0.003272262867540121,
            -0.021513091400265694,
            0.045149676501750946,
            0.005073533393442631,
            -0.010609384626150131,
            0.0300629660487175,
            -0.027750475332140923,
            0.0035976520739495754,
            0.0021975641138851643,
            0.009471717290580273,
            0.018158139660954475,
            0.014025327749550343,
            0.023943351581692696,
            -0.018163995817303658,
            -0.018322205170989037,
            0.024824293330311775,
            -0.0054595815017819405,
            -0.016088226810097694,
            0.00931607186794281,
            -0.03385717049241066,
            0.02850438840687275,
            -0.0214784424751997,
            -0.018689261749386787,
            -0.03570183739066124,
            0.03437419235706329,
            -0.049111414700746536,
            0.011999464593827724,
            -0.025053415447473526,
            0.0286481361836195,
            0.014530489221215248,
            0.05211181938648224,
            0.015146284364163876,
            0.025312701240181923,
            -0.005030505359172821,
            -0.011412401683628559,
            -0.005724089220166206,
            -0.052493780851364136,
            0.02811909094452858,
            -0.015405193902552128,
            -0.00253814784809947,
            0.008187239989638329,
            -0.003786808578297496,
            -0.004523447714745998,
            0.02663465030491352,
            -0.03736037760972977,
            0.04260082542896271,
            0.024531537666916847,
            0.01917385496199131,
            -0.01674211584031582,
            0.0018911054357886314,
            0.009287145920097828,
            0.00028682377887889743,
            -0.012721617706120014,
            0.03179753199219704,
            -0.01057642512023449,
            0.006462239194661379,
            0.004496445879340172,
            -0.006028072442859411,
            0.012745985761284828,
            -0.001915259868837893,
            0.0281655453145504,
            -0.015792550519108772,
            -0.0258322861045599,
            0.019251761958003044,
            0.01304444670677185,
            -0.014712418429553509,
            -0.00316947721876204,
            0.021090887486934662,
            0.011769291013479233,
            -0.04075213521718979,
            -0.0010369131341576576,
            -0.010211911052465439,
            -0.07683786749839783,
            -0.06500859558582306,
            -0.002309438306838274,
            0.011366759426891804,
            -0.003082012291997671,
            -0.0400068499147892,
            -0.01224413700401783,
            0.026102671399712563,
            0.023369602859020233,
            0.015375388786196709,
            -0.015444543212652206,
            -0.021252401173114777,
            0.011537265032529831,
            0.0005629923543892801,
            0.027714209631085396,
            0.026562629267573357,
            0.0029998430982232094,
            0.002101282589137554,
            -0.061308614909648895,
            0.009961297735571861,
            -0.007521063555032015,
            -0.021734483540058136,
            -0.02748691663146019,
            0.04483611509203911,
            -0.026264535263180733,
            -0.02197585254907608,
            0.0012247782433405519,
            0.059507984668016434,
            0.004115867428481579,
            -0.0352153442800045,
            -0.029792798683047295,
            -0.003941026050597429,
            -0.060194481164216995,
            0.01270353700965643,
            -0.012816246598958969,
            0.010284246876835823,
            0.018326152116060257,
            0.00826197862625122,
            -0.018621547147631645,
            0.005076513160020113,
            0.02290937304496765,
            -0.0170932374894619,
            -0.010528655722737312,
            -5.491104730026564e-06,
            -0.008530835621058941,
            0.011243144981563091,
            0.03306348994374275,
            -0.021394189447164536,
            -0.010838625021278858,
            -0.002723234472796321,
            0.009454278275370598,
            -0.011724244803190231,
            -0.020582111552357674,
            0.01956721767783165,
            0.060168687254190445,
            -0.01009051501750946,
            -0.011570844799280167,
            -0.007981454022228718,
            -0.03156951814889908,
            -0.007148442789912224,
            -7.960609218571335e-05,
            -0.005077256355434656,
            0.012041589245200157,
            0.03398881107568741,
            -0.020050127059221268,
            0.004538312554359436,
            0.025415891781449318,
            0.002113234717398882,
            -0.07757525891065598,
            -0.013499661348760128,
            0.024884656071662903,
            -0.013906985521316528,
            -0.019515734165906906,
            0.055491212755441666,
            0.03097630850970745,
            0.022775923833251,
            -0.01802065782248974,
            0.014679950661957264,
            0.025187786668539047,
            0.023814478889107704,
            -0.0013201235560700297,
            0.026486719027161598,
            -0.032436490058898926,
            0.009939722716808319,
            -0.014058138243854046,
            0.025440240278840065,
            0.011269062757492065,
            -0.009052792564034462,
            0.01979999616742134,
            0.0064753214828670025,
            0.0001227979955729097,
            -0.02744366228580475,
            -0.023260485380887985,
            0.03391649201512337,
            -0.015047441236674786,
            -0.018202876672148705,
            -0.026299530640244484,
            -0.0255966167896986,
            -0.010310020297765732,
            -0.004154710564762354,
            0.023186033591628075,
            -0.013127835467457771,
            -0.006802766118198633,
            0.001877293107099831,
            0.056388188153505325,
            -0.038015417754650116,
            0.058384303003549576,
            -0.016637839376926422,
            -0.006589474156498909,
            -0.0313473604619503,
            -0.025468863546848297,
            -0.0796656534075737,
            -0.005887547507882118,
            -0.02379467710852623,
            0.02733783982694149,
            0.02757037989795208,
            0.014795288443565369,
            -0.014716030098497868,
            -0.046681247651576996,
            -0.0154624804854393,
            0.004492695443332195,
            -0.021720271557569504,
            -0.008679939433932304,
            0.016467032954096794,
            0.017151497304439545,
            -0.0719522014260292,
            -0.0139065096154809,
            -0.09716878086328506,
            0.021479221060872078,
            -0.01951032690703869,
            0.027926355600357056,
            -0.020985687151551247,
            -0.04441653937101364,
            -0.003332768799737096,
            -0.032821640372276306,
            -0.033512014895677567,
            -0.05916537716984749,
            -0.014258359558880329,
            0.037370502948760986,
            0.006771583575755358,
            -0.03774121776223183,
            -0.011849178932607174,
            0.015460413880646229,
            -0.013818539679050446,
            -0.005962125025689602,
            -0.015378247015178204,
            0.02889406867325306,
            -0.0003796979726757854,
            0.031891461461782455,
            0.00341204390861094,
            0.028273027390241623,
            -0.03251519799232483,
            -0.048018213361501694,
            -0.009311135858297348,
            0.02607642486691475,
            -0.038664691150188446,
            0.013687226921319962,
            0.0434725247323513,
            0.021395020186901093,
            -0.029773013666272163,
            -0.01148773543536663,
            -0.0065637859515845776,
            0.0231410451233387,
            -0.002074992982670665,
            -0.024924010038375854,
            0.01964316889643669,
            -0.0047456491738557816,
            -0.030105093494057655,
            -0.02503231354057789,
            0.025144387036561966,
            -0.020253092050552368,
            0.001162561820819974,
            0.0191121194511652,
            -0.010991884395480156,
            -0.04266408085823059,
            0.017114944756031036,
            0.009383085183799267,
            0.0021263454109430313,
            0.029980763792991638,
            -0.020970597863197327,
            -0.03363465145230293,
            0.038489930331707,
            -0.015496529638767242,
            -0.005338483490049839,
            0.022006183862686157,
            -0.030844565480947495,
            0.00970164593309164,
            -0.03902081400156021,
            -0.02876264601945877,
            -0.04335012286901474,
            -0.02348010241985321,
            0.021322304382920265,
            -0.03147091716527939,
            0.008247759193181992,
            0.028076523914933205,
            -0.0064363605342805386,
            0.030261322855949402,
            -0.00694219209253788,
            0.026063283905386925,
            0.002512359293177724,
            -0.019122587516903877,
            -0.025016706436872482,
            -0.0042473189532756805,
            0.016189247369766235,
            -0.006761397235095501,
            -0.05369593948125839,
            0.020078061148524284,
            0.01680414006114006,
            0.02525494620203972,
            -0.00032217748230323195,
            0.023415300995111465,
            0.017832664772868156,
            -0.026941606774926186,
            0.016197042539715767,
            -0.005037440452724695,
            0.0005328517290763557,
            -0.006965578068047762,
            -0.034203290939331055,
            0.026172490790486336,
            0.04112231731414795,
            -0.031576186418533325,
            -0.03356854245066643,
            0.0013993926113471389,
            0.03129884973168373,
            0.03207758441567421,
            -0.005436812527477741,
            -0.007012675516307354,
            0.004971850197762251,
            0.018869485706090927,
            0.019125178456306458,
            0.03565825894474983,
            0.03167037293314934,
            -0.018685728311538696,
            -0.002746875863522291,
            -0.017716748639941216,
            -0.02228604629635811,
            0.03163432702422142,
            -0.013520387001335621,
            -0.003382989438250661,
            -0.018161533400416374,
            -0.05488840490579605,
            0.022162562236189842,
            0.029456118121743202,
            0.02339031919836998,
            -0.005279283504933119,
            -0.019713284447789192,
            0.02497960440814495,
            -0.03727186471223831,
            -0.03415082395076752,
            -0.033898621797561646,
            0.02999233268201351,
            -0.0679885596036911,
            0.013578659854829311,
            0.03833599016070366,
            0.037791769951581955,
            0.02721335180103779,
            -0.027356529608368874,
            -0.017623940482735634,
            0.03465414419770241,
            0.03802471235394478,
            0.053601037710905075,
            -0.014943853951990604,
            -0.024373166263103485,
            -0.025451309978961945,
            -0.016179703176021576,
            -0.01941164955496788,
            -0.0033284544479101896,
            -0.0031526025850325823,
            0.03153175115585327,
            -0.02641230821609497,
            0.0527956597507,
            0.020692646503448486,
            -0.03225672245025635,
            -0.005662140436470509,
            0.033917926251888275,
            -0.05486488342285156,
            0.012172848917543888,
            -0.009586984291672707,
            0.009461939334869385,
            0.004877065308392048,
            -0.038785941898822784,
            0.007187546230852604,
            -0.037901975214481354,
            0.0042080357670784,
            0.03584230691194534,
            0.007288982160389423,
            -0.022969771176576614,
            -0.013286431320011616,
            0.0019508292898535728,
            0.01572561450302601,
            -0.02311895601451397,
            -0.03721857815980911,
            -0.012381022796034813,
            -0.01819768361747265,
            0.0212646946310997,
            -0.0300533939152956,
            0.05306084081530571,
            0.018407266587018967,
            0.0019441851181909442,
            -0.017807431519031525,
            0.0030131821986287832,
            0.01037707831710577,
            0.011459952220320702,
            0.00805638823658228,
            -0.02075226977467537,
            0.002111476846039295,
            0.014115540310740471,
            0.028987815603613853,
            0.02557072974741459,
            -0.023292148485779762,
            0.04136999696493149,
            -0.012667983770370483,
            0.007490991614758968,
            -0.0032226734329015017,
            0.004730573855340481,
            0.009196233935654163,
            0.00900043174624443,
            0.02611433155834675,
            -0.0037003252655267715,
            0.024981755763292313,
            -0.0437801331281662,
            0.027317669242620468,
            0.03474239259958267,
            -0.005036740098148584,
            -0.027179554104804993,
            -0.011017081327736378,
            -0.03647299110889435,
            0.005648779682815075,
            -0.011408369056880474,
            -0.025217052549123764,
            0.019034773111343384,
            -0.01949300430715084,
            -0.024024447426199913,
            -0.0018598957685753703,
            -0.026202723383903503,
            0.025873644277453423,
            -7.049743726383895e-05,
            0.02943529188632965,
            -0.026137936860322952,
            -0.00824817642569542,
            0.027964282780885696,
            0.04924532026052475,
            -0.00035820328048430383,
            -0.00851800199598074,
            0.012585152871906757,
            0.026720920577645302,
            -0.016261408105492592,
            0.0025270506739616394,
            0.001187595073133707,
            -0.015739938244223595,
            0.004070825409144163,
            -0.03092893399298191,
            -0.026059379801154137,
            -0.016471676528453827,
            0.027442190796136856,
            -0.00655203964561224,
            0.0536051020026207,
            -0.00019851454999297857,
            0.017683546990156174,
            0.009619236923754215,
            -0.006715842057019472,
            -0.017700636759400368,
            0.019955575466156006,
            -0.025086984038352966,
            0.005664984695613384,
            -0.036244817078113556,
            -0.04865744337439537,
            0.016372093930840492,
            -0.02561553195118904,
            0.026347855105996132,
            -0.007640783675014973,
            -0.023329535499215126,
            -0.011619120836257935,
            -0.02604466862976551,
            0.014814686961472034,
            0.021858254447579384,
            -0.02630726434290409,
            0.035292744636535645,
            -0.01545959897339344,
            -0.011634575203061104,
            -0.008599306456744671,
            -0.025259288027882576,
            -0.002616236452013254,
            -0.05296950042247772,
            -0.049819882959127426,
            -0.01933627761900425,
            -0.05010711029171944,
            0.013962335884571075,
            -0.01409253291785717,
            -0.03862585872411728,
            -0.0516776368021965,
            -0.036735277622938156,
            0.016607176512479782,
            0.026782257482409477,
            -0.022338256239891052,
            0.02212420105934143,
            0.02102004550397396,
            0.024721965193748474,
            -0.03678303584456444,
            0.004729393403977156,
            0.007701505906879902,
            -0.014073368161916733,
            -0.005892297253012657,
            0.007817922160029411,
            0.04704228788614273,
            -0.010488784871995449,
            -0.010887427255511284,
            0.0031265530269593,
            -0.024302057921886444,
            0.036275919526815414,
            0.03326796367764473,
            0.014894641004502773,
            -0.001888891914859414,
            0.027823004871606827,
            -0.022278249263763428,
            0.005385876167565584,
            -0.005762665998190641,
            0.014450620859861374,
            -0.008685006760060787,
            -0.03971478343009949,
            -0.013727457262575626,
            0.024002503603696823,
            -0.03148524835705757,
            0.006811720319092274,
            0.03012895956635475,
            -0.03356315940618515,
            -0.027848560363054276,
            0.0025125397369265556,
            0.007148574106395245,
            -0.0002352106530452147,
            0.005523484665900469,
            0.012813750654459,
            0.025310905650258064,
            -0.04499199613928795,
            -0.020311038941144943,
            -0.015171433798968792,
            0.02217233180999756,
            0.0212263073772192,
            0.016012122854590416,
            0.003800269914790988,
            -0.0473841056227684,
            -0.009714766405522823,
            -0.005808217916637659,
            0.027567841112613678,
            0.020640481263399124,
            0.03050260990858078,
            0.014275466091930866,
            -0.023961257189512253,
            -0.017688751220703125,
            -0.013597981072962284,
            -0.03591889888048172,
            -0.023636097088456154,
            -0.049711789935827255,
            0.02073313668370247,
            -0.016092661768198013,
            0.022829925641417503,
            0.001494540716521442,
            -0.040908847004175186,
            0.021706921979784966,
            0.033808380365371704,
            0.005479189567267895,
            -0.007879321463406086,
            0.04460548982024193,
            0.0012761757243424654,
            0.011422248557209969,
            -0.009723705239593983,
            -0.028102312237024307,
            0.0695587769150734,
            -0.020250573754310608,
            -0.01725856587290764,
            0.03277638182044029,
            0.028601819649338722,
            0.004083140287548304,
            -0.04213612526655197,
            0.02499457262456417,
            -0.009507748298346996,
            -0.02179192379117012,
            0.06989635527133942,
            0.01140537392348051,
            -0.04229129105806351,
            0.02141398936510086,
            0.011380886659026146,
            -0.011252546682953835,
            -0.024312125518918037,
            0.006983072496950626,
            -0.004090696573257446,
            -0.026406461372971535,
            -0.00996730849146843,
            -0.025085091590881348,
            0.011368900537490845,
            0.03093043528497219,
            -0.01915852539241314,
            0.019855910912156105,
            -0.04701661691069603,
            -0.08069679886102676,
            0.015464148484170437,
            0.001911400118842721,
            -0.017573440447449684,
            0.014038867317140102,
            -0.02140590362250805,
            -0.02435639128088951,
            0.02570074424147606,
            -0.020451543852686882,
            0.014954415149986744,
            0.01690583862364292,
            0.043994635343551636,
            0.02485654689371586,
            0.004893744830042124,
            0.01001854706555605,
            -0.022230330854654312,
            -0.024730311706662178,
            0.018316874280571938,
            -0.038739800453186035,
            0.01042174268513918,
            0.015686385333538055,
            0.030073313042521477,
            0.012311620637774467,
            -0.02469502203166485,
            0.01944590173661709,
            -0.006775910500437021,
            0.0028721485286951065,
            -0.008534344844520092,
            -0.0021732794120907784,
            0.020509082823991776,
            -0.009540143422782421,
            -0.008480479940772057,
            0.01758686453104019,
            0.05830724164843559,
            0.026050733402371407,
            -0.005792231298983097,
            -0.008658441714942455,
            -0.031283531337976456,
            -0.019629517570137978,
            -0.023197000846266747,
            -0.012101023457944393,
            -0.02801130712032318,
            -0.03363152593374252,
            -0.006560368929058313,
            0.06031440943479538,
            -0.041188795119524,
            -0.01482801791280508,
            -0.017018035054206848,
            0.00452357018366456,
            -0.0215789582580328,
            0.0030177945736795664,
            -0.016760071739554405,
            0.00845041498541832,
            0.00327447522431612,
            0.015364712104201317,
            -0.043864767998456955,
            0.00964687205851078,
            -0.011723781004548073,
            -0.02289547771215439,
            0.02688610926270485,
            0.00601164111867547,
            0.02430274337530136,
            -0.02602888084948063,
            -0.00377476355060935,
            -0.015618919394910336,
            0.022890586405992508,
            -0.015420006588101387,
            0.01167786680161953,
            0.057374775409698486,
            0.014533841982483864,
            0.04628274217247963,
            0.02672836370766163,
            -0.014766624197363853,
            0.02978195995092392,
            -0.017232974991202354,
            0.012503032572567463,
            0.0027903523296117783,
            -0.03429633006453514,
            -0.0037788900081068277,
            -0.024360252544283867,
            -0.027191806584596634,
            -0.02050803042948246,
            -0.009962315671145916,
            0.012914602644741535,
            -0.008865656331181526,
            0.012546760961413383,
            -0.017829343676567078,
            -0.08483900874853134,
            -0.01728059910237789,
            -0.0012813134817406535,
            -0.007498085964471102,
            -0.006903242785483599,
            0.002420480828732252,
            0.014362603425979614,
            0.008040075190365314,
            0.009904464706778526,
            0.009285707958042622,
            -0.0120381535962224,
            0.002891835756599903,
            0.006442827172577381,
            -0.008007892407476902,
            -0.02086476795375347,
            -0.0022332335356622934,
            0.00793373491615057,
            -0.036150671541690826,
            0.004031440708786249,
            0.019038312137126923,
            -0.015881091356277466,
            -0.010865144431591034,
            -0.0054027666337788105
        ],
        [
            -0.0155809810385108,
            -0.02540723979473114,
            0.02524610236287117,
            -0.014828473329544067,
            -0.0012371052289381623,
            -0.004600252024829388,
            0.02523105964064598,
            0.017093589529395103,
            -0.01223283912986517,
            0.0007336381240747869,
            0.00715289544314146,
            -0.015654172748327255,
            0.012874789535999298,
            -0.002084810985252261,
            0.04902114346623421,
            0.002417781623080373,
            0.016887977719306946,
            -0.03530413657426834,
            -0.0035449157003313303,
            -0.006914522498846054,
            -0.025955604389309883,
            0.01785588078200817,
            -0.01977689191699028,
            -0.03554341197013855,
            0.03154018521308899,
            0.03238416835665703,
            0.033707402646541595,
            0.008772661909461021,
            -0.0007682982250116765,
            -0.018418194726109505,
            0.013872270472347736,
            0.02078299969434738,
            0.014899707399308681,
            0.012176554650068283,
            -0.015540722757577896,
            -0.007627799175679684,
            0.013418926857411861,
            -0.013975988142192364,
            -0.002131206216290593,
            -0.019719835370779037,
            0.033771365880966187,
            0.02007809281349182,
            0.014775274321436882,
            0.0213294867426157,
            -0.024127772077918053,
            -0.015383713878691196,
            -0.025805823504924774,
            0.0035982970148324966,
            0.005281798541545868,
            -0.039227601140737534,
            0.031230060383677483,
            0.025960300117731094,
            -0.019817600026726723,
            -0.0003210171707905829,
            -0.030163608491420746,
            -0.022419823333621025,
            0.01050481852144003,
            -0.022933226078748703,
            0.018118012696504593,
            -0.019744254648685455,
            -0.01926453784108162,
            0.023798316717147827,
            -0.0009247364359907806,
            0.03169901669025421,
            0.015675891190767288,
            0.0027136688586324453,
            -0.018189050257205963,
            -0.024219680577516556,
            -0.020537007600069046,
            0.015159194357693195,
            0.006772966589778662,
            -0.010240118950605392,
            -0.0038620696868747473,
            0.026249229907989502,
            -0.026336204260587692,
            -0.0033265480305999517,
            0.020771894603967667,
            -0.024880580604076385,
            0.043262239545583725,
            -0.017783379182219505,
            -0.012829291634261608,
            -0.01185494102537632,
            -0.00010832794941961765,
            0.027201736345887184,
            0.010175243951380253,
            -0.04132260009646416,
            -0.005227311979979277,
            -0.022451508790254593,
            -0.007485237903892994,
            0.024383308365941048,
            0.027143534272909164,
            -0.02638598345220089,
            0.043293263763189316,
            -0.007152535952627659,
            0.00024256634060293436,
            -0.03236338868737221,
            -0.02586524188518524,
            0.019642004743218422,
            0.039166875183582306,
            0.016116434708237648,
            0.008056887425482273,
            0.02107519842684269,
            -0.006997339427471161,
            -0.013447050005197525,
            -0.024195101112127304,
            -0.03231000527739525,
            0.020236365497112274,
            -0.029663002118468285,
            -0.037108249962329865,
            0.008306276984512806,
            0.0006676575867459178,
            -0.01104351133108139,
            -0.021832117810845375,
            -0.033097196370363235,
            0.020315170288085938,
            -0.02409287728369236,
            0.033821891993284225,
            0.030078906565904617,
            0.006111988332122564,
            -0.024610741063952446,
            0.022764287889003754,
            0.011849980801343918,
            0.014375273138284683,
            -0.0014063571579754353,
            -0.0008192426175810397,
            -0.03754400461912155,
            0.05690349265933037,
            -0.021116556599736214,
            -0.023769866675138474,
            0.0424833819270134,
            0.028394518420100212,
            -0.0029257151763886213,
            0.033535584807395935,
            -0.03829578310251236,
            0.01061136182397604,
            0.02511090785264969,
            0.015906313434243202,
            0.01175768207758665,
            0.026780877262353897,
            -0.029518507421016693,
            -0.02921908162534237,
            0.002508970210328698,
            0.004113127943128347,
            -0.024233505129814148,
            0.0025754624512046576,
            0.015878435224294662,
            0.0016546199331060052,
            -0.005951275583356619,
            -0.01110971812158823,
            0.026281503960490227,
            -0.03295435756444931,
            0.06259563565254211,
            0.014310173690319061,
            -0.01901840977370739,
            0.02689460851252079,
            -0.03815047815442085,
            -0.0523211807012558,
            0.00928716640919447,
            0.002452476415783167,
            0.017352785915136337,
            0.026244955137372017,
            0.020561957731842995,
            0.000732980843167752,
            -0.01477830670773983,
            -0.02259015291929245,
            0.02885446883738041,
            -0.038730595260858536,
            -0.034152038395404816,
            0.014049524441361427,
            0.0038596228696405888,
            -0.02086189016699791,
            -0.018390841782093048,
            -0.05762896314263344,
            0.03880365192890167,
            -0.005554390139877796,
            0.04400307685136795,
            -0.014818871393799782,
            -0.028158169239759445,
            0.04307150840759277,
            0.013896374963223934,
            -0.03205643966794014,
            -0.0009035064140334725,
            -0.0030104422476142645,
            -0.018385283648967743,
            -0.01066421065479517,
            0.02297869697213173,
            -0.01168441865593195,
            0.029918154701590538,
            -0.030255725607275963,
            -0.006521833129227161,
            0.01850247196853161,
            -0.008412063121795654,
            -0.027081679552793503,
            -0.028936445713043213,
            0.007144945207983255,
            0.014151988551020622,
            0.02230171486735344,
            -0.007034936919808388,
            0.009242251515388489,
            0.004092606250196695,
            -0.022993488237261772,
            0.024546416476368904,
            -0.03350658342242241,
            -0.009249826893210411,
            0.0003096346335951239,
            -0.014498299919068813,
            -0.012494443915784359,
            0.013605278916656971,
            0.0070481328293681145,
            0.01941908337175846,
            0.0035388662945479155,
            -0.0006258516805246472,
            0.04049500450491905,
            -0.006814486347138882,
            0.015524853020906448,
            0.010873290710151196,
            0.014688698574900627,
            -0.00831256341189146,
            -0.0018061702139675617,
            -0.015076251700520515,
            -0.030643280595541,
            -0.018540361896157265,
            -0.052667997777462006,
            0.013526984490454197,
            0.007815314456820488,
            -0.05600862577557564,
            -0.048680275678634644,
            0.026599539443850517,
            0.0193812046200037,
            -0.013141460716724396,
            -0.01535520888864994,
            0.0467376783490181,
            -0.024999815970659256,
            0.01868082582950592,
            -0.024655893445014954,
            0.004426202736794949,
            0.037073537707328796,
            -0.010331361554563046,
            -0.0007320204749703407,
            0.021488044410943985,
            -0.009040048345923424,
            -0.0013705716701224446,
            -0.002805496798828244,
            0.033911410719156265,
            0.01508809719234705,
            0.006022570189088583,
            0.01531719509512186,
            -0.011070976965129375,
            0.003063247771933675,
            -0.01234856154769659,
            -0.011033738031983376,
            -0.0315202921628952,
            -0.002559273736551404,
            -0.021852346137166023,
            -0.009782681241631508,
            0.012677010148763657,
            -0.002793013583868742,
            -0.011527085676789284,
            -0.0487743616104126,
            -0.012942629866302013,
            -0.03839479386806488,
            0.00404256721958518,
            -0.023336004465818405,
            -0.023610036820173264,
            0.03408375754952431,
            -0.01465684175491333,
            0.004848774056881666,
            -0.008628933690488338,
            0.002462635049596429,
            -0.04443804919719696,
            -0.022972142323851585,
            -0.020261546596884727,
            0.026211434975266457,
            0.0034631926100701094,
            -0.04455331340432167,
            0.019206203520298004,
            -0.03629878908395767,
            -0.0016490481793880463,
            -0.0353434681892395,
            0.010358424857258797,
            -0.002390835201367736,
            0.014822623692452908,
            0.020808646455407143,
            0.02582279033958912,
            -0.022069329395890236,
            -0.0023032790049910545,
            0.00020158858387731016,
            -0.026400655508041382,
            0.038473911583423615,
            0.024344343692064285,
            0.02078360691666603,
            0.0139685133472085,
            -0.006361500825732946,
            -0.019538674503564835,
            0.00019599753431975842,
            -0.014190815389156342,
            0.0062253535725176334,
            0.024981187656521797,
            -0.047499846667051315,
            -0.0007467731484211981,
            -0.00875040888786316,
            -0.011001868173480034,
            -0.03648125380277634,
            0.02034962922334671,
            -0.002601525280624628,
            0.0005276612937450409,
            0.014691216871142387,
            -0.005511904135346413,
            -0.007086200639605522,
            -0.012927201576530933,
            0.005562978330999613,
            0.0007200220134109259,
            0.004477987065911293,
            -0.019357819110155106,
            0.020869778469204903,
            -0.006493389140814543,
            0.028924519196152687,
            0.008358343504369259,
            -0.02584351971745491,
            0.017945868894457817,
            -0.001178473001345992,
            -0.06602144241333008,
            -0.003846032777801156,
            0.06272836029529572,
            0.0108876321464777,
            -0.0010262442519888282,
            0.06257889419794083,
            -0.01368228904902935,
            0.03776678442955017,
            0.0003611496358644217,
            -0.01941765286028385,
            0.005633351393043995,
            -0.010675963945686817,
            0.020445093512535095,
            0.045569680631160736,
            0.03537995368242264,
            -0.03392227739095688,
            -0.0191040001809597,
            0.02940382808446884,
            0.02057979255914688,
            -0.017343943938612938,
            0.032951466739177704,
            -0.023450007662177086,
            -0.007480056956410408,
            -0.01655726693570614,
            0.023390794172883034,
            0.025908702984452248,
            0.020927662029862404,
            0.000556372688151896,
            -0.027907567098736763,
            0.04261801019310951,
            -0.02419430762529373,
            0.025428324937820435,
            -0.032382842153310776,
            -0.0218659657984972,
            0.01157640852034092,
            0.00338174169883132,
            0.032782696187496185,
            0.018714025616645813,
            0.025466006249189377,
            -0.028837524354457855,
            -0.02335093729197979,
            -0.038018591701984406,
            0.00021788895537611097,
            -0.002610892988741398,
            0.005138017237186432,
            -0.014114316552877426,
            0.0017218892462551594,
            -0.0012122170301154256,
            0.015372298657894135,
            -0.04817882552742958,
            -0.021476684138178825,
            -0.012188033200800419,
            0.02233755774796009,
            -0.018510108813643456,
            0.014588595367968082,
            0.025908056646585464,
            -0.020752571523189545,
            0.0016441016923636198,
            0.010581913404166698,
            0.0019462833879515529,
            0.004750728141516447,
            -0.009076542221009731,
            0.029663320630788803,
            0.025143006816506386,
            0.04109643027186394,
            -0.024358481168746948,
            0.01991141587495804,
            -0.009473377838730812,
            0.007484916131943464,
            -0.02347537875175476,
            0.03851746767759323,
            0.030297497287392616,
            0.008059804327785969,
            0.025048788636922836,
            -0.03450622409582138,
            -0.0013014768483117223,
            0.03068496845662594,
            -0.004535841755568981,
            0.021953430026769638,
            0.023296860978007317,
            0.008436561562120914,
            -0.019159691408276558,
            0.028728175908327103,
            0.01909683831036091,
            -0.02340310625731945,
            0.006033048965036869,
            -0.014379497617483139,
            0.008140122517943382,
            -0.01849396899342537,
            -0.00813186913728714,
            0.02229888178408146,
            -0.0041234842501580715,
            -0.020816581323742867,
            0.004925950895994902,
            0.004280759021639824,
            0.021217575296759605,
            -0.024103263393044472,
            0.010339498519897461,
            0.026157215237617493,
            0.02464514598250389,
            -0.007221386302262545,
            -0.04935026541352272,
            0.012802456505596638,
            -0.00703686336055398,
            -0.044741515070199966,
            0.005422152578830719,
            0.01862771436572075,
            -0.014540797099471092,
            -0.011621610261499882,
            -0.027191834524273872,
            0.036928657442331314,
            0.018033837899565697,
            -0.03590951859951019,
            -0.020259350538253784,
            -0.011686587706208229,
            -0.013897376134991646,
            -0.008916334249079227,
            0.02670135907828808,
            0.02028350718319416,
            0.04038514941930771,
            0.013505442067980766,
            0.02929704636335373,
            0.028716422617435455,
            -0.01600368693470955,
            -0.016180047765374184,
            0.03568468615412712,
            -0.07381927967071533,
            0.009705050848424435,
            0.01005627028644085,
            -0.0015476384432986379,
            0.01388741098344326,
            -0.02945755235850811,
            -0.028601208701729774,
            -0.013623780570924282,
            -0.01408883836120367,
            -0.005314248614013195,
            -0.016009045764803886,
            0.003193011973053217,
            -0.00778756570070982,
            0.0467894971370697,
            0.02408446930348873,
            0.015179472044110298,
            0.04916376993060112,
            -0.019256534054875374,
            -0.023520132526755333,
            0.019465571269392967,
            -0.005357054527848959,
            -0.023243611678481102,
            -0.0276273712515831,
            -0.03870541602373123,
            -0.01763346791267395,
            0.008490345440804958,
            -0.01679322123527527,
            -0.008076254278421402,
            0.020507952198386192,
            -0.017644008621573448,
            0.013645979575812817,
            0.01684466563165188,
            -0.030213704332709312,
            -0.028956633061170578,
            0.0035018716007471085,
            -0.017754942178726196,
            0.024371417239308357,
            -0.012465528212487698,
            0.00040694104973226786,
            -0.023124005645513535,
            0.018639154732227325,
            0.001303826691582799,
            -0.04292131960391998,
            0.0005253449198789895,
            -0.012160435318946838,
            0.02803780511021614,
            -0.02771812491118908,
            0.005810385569930077,
            -0.03529120981693268,
            -0.007203369867056608,
            -0.028156541287899017,
            0.0013765356270596385,
            -0.007973665371537209,
            0.010197312571108341,
            0.0018982365727424622,
            -0.01578882709145546,
            -0.013845367357134819,
            -0.02489369548857212,
            0.00023423248785547912,
            -0.0070151593536138535,
            0.022975796833634377,
            -0.017781797796487808,
            -0.03444579988718033,
            0.015606477856636047,
            0.00471844058483839,
            -0.01983051747083664,
            -0.004501644987612963,
            0.01662215031683445,
            0.024762095883488655,
            0.02833278849720955,
            0.01334146223962307,
            -0.029483133926987648,
            -0.02067256160080433,
            -0.009964865632355213,
            -0.025871481746435165,
            -3.4061918086081278e-06,
            0.013169466517865658,
            -0.016574813053011894,
            -0.024993887171149254,
            0.04799072816967964,
            0.00852766539901495,
            -0.017594844102859497,
            -0.03026348352432251,
            0.015680894255638123,
            0.0023348359391093254,
            -0.0011580880964174867,
            -0.011751588433980942,
            0.02354319579899311,
            0.05300141125917435,
            -0.011074925772845745,
            0.009509694762527943,
            -0.002941390499472618,
            -0.020101547241210938,
            0.027508549392223358,
            -0.009482172317802906,
            -0.013512897305190563,
            0.018703291192650795,
            0.02046423964202404,
            0.04675574600696564,
            0.03527701646089554,
            -0.020494859665632248,
            -0.01073131151497364,
            0.011971047148108482,
            0.01110476441681385,
            -0.01006979588419199,
            -0.015224006026983261,
            0.01726328581571579,
            0.006339743733406067,
            -0.02638387121260166,
            -0.037066902965307236,
            -0.014139646664261818,
            0.026047490537166595,
            0.016356665641069412,
            0.00858734454959631,
            -0.02180701680481434,
            -0.030521217733621597,
            0.0032600013073533773,
            0.013273905962705612,
            -0.010409506037831306,
            0.00017439614748582244,
            -0.01864837296307087,
            -0.02244850993156433,
            0.004054212477058172,
            0.027024874463677406,
            -0.0009652887238189578,
            0.008026975207030773,
            0.035739146173000336,
            0.013029907830059528,
            0.023863395676016808,
            0.02227344736456871,
            0.014397685416042805,
            0.030147628858685493,
            0.03358825668692589,
            0.023769428953528404,
            -0.009610364213585854,
            -0.0005204767221584916,
            0.0038011656142771244,
            -0.017486557364463806,
            -0.0032318232115358114,
            0.022596554830670357,
            -0.013377577997744083,
            -0.03700178861618042,
            0.030982298776507378,
            -0.022639550268650055,
            0.02507542073726654,
            0.012971154414117336,
            0.0005246114451438189,
            0.005521571263670921,
            -0.009038619697093964,
            -0.0019849929958581924,
            -0.02351437136530876,
            0.035494860261678696,
            0.04082703962922096,
            -0.0012969577219337225,
            0.0065504079684615135,
            -0.03318795561790466,
            0.005771486088633537,
            0.01866805925965309,
            -0.008509434759616852,
            -0.012085311114788055,
            -0.02357310801744461,
            0.03118904121220112,
            -0.008596058003604412,
            0.016097160056233406,
            -0.027707431465387344,
            0.018192987889051437,
            -0.024553000926971436,
            -0.014819570817053318,
            -0.012311602011322975,
            -0.01887613534927368,
            0.00322465761564672,
            -0.013686834834516048,
            -0.0024663142394274473,
            0.017141535878181458,
            -0.012096867896616459,
            -0.01093139499425888,
            -0.04149923846125603,
            -0.0073614660650491714,
            -0.017371652647852898,
            -0.016569143161177635,
            0.020335428416728973,
            0.02907911129295826,
            0.006423964165151119,
            -0.038860827684402466,
            0.026034008711576462,
            0.002671106718480587,
            0.025400692597031593,
            0.03250279277563095,
            0.016897838562726974,
            -0.011392480693757534,
            0.014986713416874409,
            -0.009378857910633087,
            -0.0032545735593885183,
            0.013054890558123589,
            0.01210173312574625,
            -0.008745989762246609,
            -0.020952021703124046,
            0.004090814385563135,
            0.011913499794900417,
            -0.016041232272982597,
            -0.006121405400335789,
            -0.03157024085521698,
            0.006916932296007872,
            0.01901763677597046,
            -8.820031507639214e-05,
            -0.020099401473999023,
            -0.02702370285987854,
            0.0292144026607275,
            -0.01613815501332283,
            0.0007300066063180566,
            0.004358517937362194,
            -0.00015139361494220793,
            -0.005145980976521969,
            0.010857800953090191,
            0.028641605749726295,
            -0.00783525500446558,
            0.013123244047164917,
            0.018584275618195534,
            -0.020051689818501472,
            -0.01819700561463833,
            -0.0004010177217423916,
            0.004699287936091423,
            -0.032899387180805206,
            0.019722992554306984,
            -0.058477286249399185,
            -0.003451165510341525,
            -0.04318506643176079,
            0.010511080734431744,
            -0.007807361893355846,
            -0.02993345633149147,
            -0.03355373814702034,
            0.00918014906346798,
            -0.0245174840092659,
            -0.00035503465915098786,
            -0.01600048691034317,
            -0.032694827765226364,
            0.009260072372853756,
            0.025362955406308174,
            -0.021655159071087837,
            -0.012303357943892479,
            0.01774052530527115,
            -0.032021503895521164,
            -0.021678056567907333,
            -0.02787584625184536,
            -0.03430971875786781,
            -0.02617557719349861,
            -0.04224027320742607,
            -0.0006579129258170724,
            0.04672115296125412,
            -0.0016950417775660753,
            -0.008098626509308815,
            -0.029502296820282936,
            0.005554418079555035,
            0.011769269593060017,
            0.027342254295945168,
            -0.013281970284879208,
            -0.02815392054617405,
            0.04380546137690544,
            0.013773063197731972,
            0.02032420039176941,
            0.01670776680111885,
            0.00773455947637558,
            -0.03322327509522438,
            0.025918368250131607,
            0.006302072200924158,
            0.0070038349367678165,
            -0.009478901512920856,
            0.02241126261651516,
            -0.003702850779518485,
            0.029099078848958015,
            -0.009183564223349094,
            -0.024825192987918854,
            -0.02970842830836773,
            -0.003207974601536989,
            0.014216689392924309,
            0.015450382605195045,
            0.019409507513046265,
            -0.026649966835975647,
            0.01433172170072794,
            -0.02456311322748661,
            -0.019733216613531113,
            0.02167152799665928,
            -0.003998580854386091,
            -0.020125452429056168,
            -0.022858064621686935,
            -0.01980256289243698,
            -0.0013836042489856482,
            0.023255379870533943,
            -0.0034328303299844265,
            0.003054381813853979,
            0.012724427506327629,
            -0.04307296872138977,
            -0.022727584466338158,
            0.003960778936743736,
            -0.0120548065751791,
            -0.023871812969446182,
            0.029535919427871704,
            0.019195212051272392,
            -0.006243641022592783,
            -0.05590810254216194,
            -0.05297056958079338,
            -0.0070501347072422504,
            0.006565378047525883,
            -0.025304239243268967,
            0.011860417202115059,
            -0.007399665657430887,
            -0.04379218816757202,
            0.017077647149562836,
            0.026843763887882233,
            -0.0131980050355196,
            0.0141313960775733,
            -0.044253792613744736,
            0.03380032628774643,
            -0.0035158214159309864,
            -0.001362573355436325,
            -0.029414888471364975,
            -0.00908866710960865,
            -0.025859875604510307,
            0.020791493356227875,
            -0.0038317455910146236,
            0.004727091174572706,
            0.036380793899297714,
            0.026771945878863335,
            -0.01819119229912758,
            0.05831776186823845,
            -0.007356240414083004,
            -0.004688790533691645,
            -0.013237176463007927,
            0.04030926153063774,
            -0.053553543984889984,
            0.023838263005018234,
            0.004724724683910608,
            0.024575650691986084,
            -0.006476336158812046,
            -0.012768746353685856,
            -0.013712332583963871,
            0.019490232691168785,
            -0.004509324207901955,
            0.003728461917489767,
            0.02732136659324169,
            0.0045292191207408905,
            0.018234914168715477,
            0.017243659123778343,
            -0.0332171656191349,
            0.011584222316741943,
            -0.02969209849834442,
            -0.005190398544073105,
            -0.0033310349099338055,
            0.02319973148405552,
            -0.00618765689432621,
            0.0012870777864009142,
            -0.0035927684511989355,
            0.022761721163988113,
            -0.021092619746923447,
            -0.0013658038806170225,
            0.0262032151222229,
            0.008523696102201939,
            0.002902899868786335,
            -0.013525658287107944,
            -0.005489130038768053,
            -0.006072222720831633,
            -0.03183374181389809,
            -0.010418674908578396,
            0.024366410449147224,
            0.03169798105955124,
            -0.0006525024655275047,
            -0.013710051774978638,
            0.037327490746974945,
            0.008317257277667522,
            0.0006186082027852535,
            -0.03164462000131607,
            0.03056628629565239,
            0.03210205212235451,
            0.0037026701029390097,
            -0.03595297783613205,
            0.012672324664890766,
            -0.01703987829387188,
            0.037340886890888214,
            0.020478658378124237,
            0.034818246960639954,
            -0.003167503047734499,
            -0.006436899770051241,
            -0.013726596720516682,
            -0.017464669421315193,
            0.020576825365424156,
            -0.019300296902656555,
            -0.02981719560921192,
            -0.0015734522603452206,
            0.03398064523935318,
            0.0018553589470684528,
            0.0012310637393966317,
            -0.020332667976617813,
            0.00895613431930542,
            -0.00018938393623102456,
            -0.0023686096537858248,
            -0.0014082459965720773,
            -0.03649526834487915,
            0.017728673294186592,
            0.021716643124818802,
            0.029363403096795082,
            -5.709906326956116e-05,
            -0.0004246460448484868,
            -0.008051211945712566,
            -0.0063324891962111,
            0.0355321504175663,
            -0.00904660765081644,
            -0.02719813957810402,
            -0.033241335302591324,
            0.031328391283750534,
            0.03599858283996582,
            0.0033264930825680494,
            -0.00468924455344677,
            0.02989533729851246,
            0.013460087589919567,
            0.020131893455982208,
            -0.007355640176683664,
            0.03540096804499626,
            0.0005810142611153424,
            0.013380995951592922,
            0.029577845707535744,
            0.012997920624911785,
            -0.01301711704581976,
            0.04114384204149246,
            0.027195625007152557,
            -0.030482610687613487,
            0.04782659932971001,
            -0.03189298138022423,
            -0.015239576809108257,
            0.013166666962206364,
            -0.01398118119686842,
            0.0311108510941267,
            -0.013445042073726654,
            -0.02426604926586151,
            -0.03147328644990921,
            0.0017846947303041816,
            0.0013475625310093164,
            -0.005504967644810677,
            -0.028016451746225357,
            0.010652088560163975,
            -0.02828960493206978,
            -0.0011526481248438358,
            0.010132803581655025,
            -0.006587916985154152,
            -0.00647755665704608,
            -0.016705797985196114,
            -0.012575559318065643,
            0.004730226472020149,
            -0.016990484669804573,
            -0.003895289497449994,
            0.011358045972883701,
            0.009151711128652096,
            -0.016517186537384987,
            -0.025410404428839684,
            -0.012146965600550175,
            -0.008364331908524036,
            0.001978793879970908,
            0.004342639818787575,
            0.01160904299467802,
            0.007399414200335741,
            0.009477172046899796,
            0.004145543556660414,
            0.02011694945394993,
            -0.009637522511184216,
            -0.01230508554726839,
            -0.013634895905852318,
            0.009437594562768936,
            0.015928609296679497,
            -0.016556283459067345,
            0.003909024875611067,
            0.024745909497141838,
            0.006846986245363951,
            -0.022431250661611557,
            0.011819994077086449,
            0.022212062031030655,
            -0.05132667347788811,
            -0.02241053245961666,
            0.0008295138250105083,
            0.05025200918316841,
            0.02156131900846958,
            0.03232351318001747,
            0.025326905772089958,
            0.018881600350141525,
            0.040264327079057693,
            -0.024142703041434288,
            0.011907615698873997,
            0.01736416667699814,
            0.03243706747889519,
            0.04898977279663086,
            -0.0021648856345564127,
            -0.024078616872429848,
            0.02791546657681465,
            -0.02090977318584919,
            -0.024775641039013863,
            -0.03346232324838638,
            0.0184185653924942,
            0.012674365192651749,
            -0.010070856660604477,
            -0.034337256103754044,
            0.011402083560824394,
            0.007462302688509226,
            0.005622669123113155,
            0.01610380783677101,
            -0.024790171533823013,
            0.001979100052267313,
            -0.019165534526109695,
            -0.005439234897494316,
            0.020790087059140205,
            -0.00396307185292244,
            0.04544994235038757,
            0.008888158947229385,
            0.0017345157684758306,
            -0.009972254745662212,
            0.027024703100323677,
            -0.029918234795331955,
            -0.0536547489464283,
            -0.02186940237879753,
            0.037479061633348465,
            0.011295306496322155,
            0.006396545097231865,
            -0.006491717882454395,
            0.022506171837449074,
            -0.014593207277357578,
            0.03231056034564972,
            0.02601901814341545,
            0.020184731110930443,
            0.012978571467101574,
            0.01709464006125927,
            0.007973399944603443,
            -0.01162709854543209,
            -0.027242792770266533,
            -0.013924515806138515,
            0.008154159411787987,
            0.024661464616656303,
            0.002344363834708929,
            -0.005382831674069166,
            -0.005148065742105246,
            -0.024873681366443634,
            -0.025257080793380737,
            -0.01935112290084362,
            0.01373133435845375,
            0.029800981283187866,
            -0.020686201751232147,
            -0.021850058808922768,
            -0.008274782449007034,
            -0.03141997754573822,
            -0.036955833435058594,
            0.024234820157289505,
            -0.002004688372835517,
            0.0007885781233198941,
            0.03807460516691208,
            -0.008146877400577068,
            0.016383200883865356,
            0.02491520531475544,
            -0.024011710658669472,
            -0.013044241815805435,
            0.012774193659424782,
            -0.002343574305996299,
            -0.001220932463183999,
            -0.005692104808986187,
            -0.030571533367037773,
            -0.0162087082862854,
            -0.030637523159384727,
            0.018302569165825844,
            -0.010788929648697376,
            -0.002330627292394638,
            0.022376326844096184,
            -0.017349470406770706,
            0.012026376090943813,
            0.0031735661905258894,
            0.0028517725877463818,
            0.032220203429460526,
            0.00409875949844718,
            -0.00476487074047327,
            -0.00048061530105769634,
            -0.036358121782541275,
            0.03292737156152725,
            0.009538493119180202
        ],
        [
            0.011344119906425476,
            0.0203921590000391,
            -0.01836203970015049,
            -0.02990712784230709,
            -0.003162079257890582,
            0.029048673808574677,
            0.02538134716451168,
            0.050945281982421875,
            -0.002331965370103717,
            0.010260194540023804,
            -0.02242707833647728,
            -0.017026886343955994,
            0.021994685754179955,
            -0.045331891626119614,
            0.014308673329651356,
            -0.009892062284052372,
            0.03784032538533211,
            0.008733176626265049,
            0.03727242350578308,
            -0.02982383780181408,
            0.016084346920251846,
            0.0019155469490215182,
            0.016743585467338562,
            -0.039866700768470764,
            0.028531258925795555,
            -0.012720969505608082,
            0.03164416179060936,
            0.02372604049742222,
            -0.036300335079431534,
            -0.02346901409327984,
            0.03054739162325859,
            -0.01301354356110096,
            -0.004996266216039658,
            -0.016662970185279846,
            0.003330175532028079,
            0.009920419193804264,
            0.01747293956577778,
            -0.019089186564087868,
            -0.014038707129657269,
            -0.009602387435734272,
            0.0074632735922932625,
            0.01739940419793129,
            0.028118276968598366,
            -0.006094424054026604,
            -0.007267545908689499,
            0.0029787414241582155,
            -0.03370102494955063,
            0.009277643635869026,
            0.010758288204669952,
            -4.656275450543035e-06,
            0.010322877205908298,
            0.030251365154981613,
            -0.034880585968494415,
            -0.0053784651681780815,
            0.0045089093036949635,
            -0.021728599444031715,
            0.02251838520169258,
            -0.010882860980927944,
            -0.028712037950754166,
            0.006441355682909489,
            -0.04074052721261978,
            0.012159756384789944,
            0.03289157152175903,
            -0.016572393476963043,
            0.030949393287301064,
            -0.006042052526026964,
            -0.022442305460572243,
            -0.026840224862098694,
            0.011549797840416431,
            -0.008584778755903244,
            0.013025141321122646,
            -0.011060320772230625,
            0.020000943914055824,
            0.01155296340584755,
            -0.005100225564092398,
            -0.004860688466578722,
            0.01369196642190218,
            -0.01825278252363205,
            0.019310956820845604,
            0.00015193923900369555,
            0.007035666611045599,
            0.015694033354520798,
            0.0335177443921566,
            -0.020945170894265175,
            0.017959756776690483,
            -0.022467290982604027,
            0.011331764981150627,
            0.027112066745758057,
            0.001322433934547007,
            -0.012494304217398167,
            0.014553246088325977,
            0.015292787924408913,
            0.021425563842058182,
            0.030137846246361732,
            0.009728127159178257,
            -0.0058188894763588905,
            -0.016266915947198868,
            0.01989421434700489,
            0.024551570415496826,
            0.014310252852737904,
            -0.004160997457802296,
            0.024013863876461983,
            0.0036477085668593645,
            -0.004860022570937872,
            -0.011050616391003132,
            0.026108235120773315,
            0.007998760789632797,
            0.008208105340600014,
            -0.029747996479272842,
            -0.0009366183076053858,
            0.03931642323732376,
            0.004141523037105799,
            -0.028575634583830833,
            -0.003822128986939788,
            0.014275901019573212,
            0.008497192524373531,
            -0.02729453332722187,
            0.048465777188539505,
            0.027334678918123245,
            0.03633137419819832,
            0.01563238725066185,
            -0.003209298010915518,
            0.021295025944709778,
            -0.017433064058423042,
            -0.029715117067098618,
            -0.005726751871407032,
            -0.005341406911611557,
            -0.020891599357128143,
            0.0075693088583648205,
            -0.02742977999150753,
            0.006070524919778109,
            0.005551650654524565,
            -0.014859380200505257,
            -0.0002850911987479776,
            0.005185901653021574,
            -0.020768703892827034,
            -0.018570154905319214,
            0.0160980187356472,
            0.023486152291297913,
            -0.005526422522962093,
            0.022172383964061737,
            0.005691555794328451,
            0.011067886836826801,
            0.0037788499612361193,
            0.03636785224080086,
            -0.0146435322239995,
            0.027120238170027733,
            -0.016652453690767288,
            0.030737310647964478,
            0.013023007661104202,
            0.007338305469602346,
            -0.0018838871037587523,
            -0.0014245660277083516,
            -0.010176357813179493,
            -0.03168065845966339,
            0.0032525130081921816,
            -0.014686113223433495,
            0.010411920957267284,
            0.004666146356612444,
            0.004461145959794521,
            -0.023924777284264565,
            0.03389493003487587,
            0.044938430190086365,
            0.011679338291287422,
            0.012994716875255108,
            -0.01403998862951994,
            -0.017708631232380867,
            0.03327075392007828,
            0.002111546928063035,
            0.01026118453592062,
            0.015711311250925064,
            0.0012430509086698294,
            -0.032875921577215195,
            0.02088943123817444,
            0.019477684050798416,
            0.018000202253460884,
            -0.0026176520623266697,
            -0.00991150550544262,
            -0.007472666446119547,
            0.031133383512496948,
            -0.025107491761446,
            -0.024748073890805244,
            -0.01106238178908825,
            0.02533436194062233,
            0.006265915930271149,
            -0.003893789369612932,
            -0.017649469897150993,
            0.0062004863284528255,
            0.013519363477826118,
            0.02492407150566578,
            0.017946438863873482,
            0.01853400096297264,
            0.03400680050253868,
            0.013812183402478695,
            -0.019645515829324722,
            -0.009101898409426212,
            -0.012263381853699684,
            -0.02733132615685463,
            0.024379633367061615,
            0.016101332381367683,
            -0.017050061374902725,
            -0.031109491363167763,
            -0.028758512809872627,
            -0.003568094689399004,
            0.020834140479564667,
            -0.016254842281341553,
            0.024453425779938698,
            -0.0051670679822564125,
            0.03930514678359032,
            -0.009984465315937996,
            0.02061648666858673,
            -0.02305222675204277,
            -0.013051135465502739,
            0.0005442292313091457,
            -0.006030731834471226,
            -0.034511953592300415,
            0.015888461843132973,
            0.00974046066403389,
            0.021551448851823807,
            0.02954011596739292,
            0.012141820974647999,
            -0.013935726135969162,
            -0.007673709653317928,
            -0.015574119985103607,
            0.015175016596913338,
            -0.04437455162405968,
            0.028105340898036957,
            -0.0024696385953575373,
            0.011406255885958672,
            0.015204817056655884,
            0.032767243683338165,
            0.02822616882622242,
            0.005772694014012814,
            0.01522042229771614,
            0.002156376838684082,
            -0.021508824080228806,
            0.00626604538410902,
            -0.01919335126876831,
            -0.029995935037732124,
            0.0034026089124381542,
            0.03871551528573036,
            0.02693573385477066,
            -0.033485934138298035,
            0.00554463267326355,
            0.01058223657310009,
            0.020368970930576324,
            0.03906220570206642,
            -0.007296938914805651,
            0.024195024743676186,
            -0.024607989937067032,
            -0.027906915172934532,
            -0.013206787407398224,
            0.01782657951116562,
            -0.0011075856164097786,
            -0.031501736491918564,
            -0.02434690296649933,
            0.006851418409496546,
            -0.00871337577700615,
            0.03322033956646919,
            -0.04176415503025055,
            0.0004946578410454094,
            0.006776994559913874,
            -0.0021015116944909096,
            0.028183504939079285,
            -0.017809415236115456,
            -0.029794342815876007,
            0.017710033804178238,
            -0.014286220073699951,
            0.003843817161396146,
            0.033003002405166626,
            -0.013397407718002796,
            0.028987769037485123,
            0.02764825150370598,
            0.031490787863731384,
            -0.0331781804561615,
            0.009564945474267006,
            -0.013185149058699608,
            -0.025529809296131134,
            0.021042319014668465,
            0.0027002806309610605,
            -0.03236549720168114,
            -0.010403355583548546,
            -0.023002328351140022,
            -0.03088400326669216,
            0.015486408025026321,
            -0.011714793741703033,
            -0.009210314601659775,
            -0.03431803733110428,
            0.009154134429991245,
            -0.024183837696909904,
            -0.03368651494383812,
            0.018284618854522705,
            0.003505157073959708,
            0.006091418210417032,
            0.008967356756329536,
            -0.0019098496995866299,
            -0.016748948022723198,
            0.0005791721632704139,
            0.011081063188612461,
            -0.020690415054559708,
            -0.020128777250647545,
            0.012501032091677189,
            -0.01660316437482834,
            0.0016646997537463903,
            -0.012738712131977081,
            -0.009557023644447327,
            -0.021744363009929657,
            0.0004586776776704937,
            -0.0031006571371108294,
            -0.0009115981520153582,
            -0.02309883013367653,
            -0.038913801312446594,
            -0.0062797944992780685,
            -0.016875535249710083,
            -0.014973106794059277,
            -0.012423960492014885,
            0.01795801892876625,
            -0.018700744956731796,
            0.00761546241119504,
            0.03430894762277603,
            -0.008583670482039452,
            -0.04999532178044319,
            -0.02361386828124523,
            0.006188107654452324,
            -0.018204089254140854,
            0.0158123467117548,
            -0.022098109126091003,
            -0.008531015366315842,
            0.03523615747690201,
            -0.01355533953756094,
            0.010576723143458366,
            -0.009320528246462345,
            0.02822214737534523,
            0.027773398905992508,
            -0.018691454082727432,
            0.007365567144006491,
            0.030980119481682777,
            0.012822197750210762,
            0.015037985518574715,
            -0.02627568505704403,
            0.00678592175245285,
            -0.005342717748135328,
            -0.03995693847537041,
            0.029958870261907578,
            0.011298050172626972,
            0.059218522161245346,
            0.0032894236501306295,
            -0.028408991172909737,
            -0.013938887976109982,
            0.02589314803481102,
            0.0017952144844457507,
            -0.03055320866405964,
            0.015418587252497673,
            -0.0015761185204610229,
            0.026393627747893333,
            -0.0030309713911265135,
            -0.01639925129711628,
            -0.019603395834565163,
            0.03529820963740349,
            1.6840233001857996e-05,
            -0.01473674550652504,
            -0.03163297101855278,
            -0.03048011101782322,
            0.0010230469051748514,
            0.01798112504184246,
            0.013183597475290298,
            -0.029843749478459358,
            0.021411988884210587,
            0.031290456652641296,
            0.022346585988998413,
            -0.018726790323853493,
            -0.024035265669226646,
            0.016225086525082588,
            -0.0031972939614206553,
            0.014664888381958008,
            0.03283364698290825,
            0.005987471900880337,
            0.0017726332880556583,
            -0.0020238880533725023,
            0.027135862037539482,
            0.03541988134384155,
            0.010805858299136162,
            -0.007203369401395321,
            -0.0056885890662670135,
            -0.007628422696143389,
            -0.007076444569975138,
            -0.034002382308244705,
            0.005064045079052448,
            0.011264222674071789,
            0.037420131266117096,
            0.010971552692353725,
            0.04928545281291008,
            0.02722259797155857,
            0.02889830246567726,
            0.0690939798951149,
            0.013689409010112286,
            -0.008158124051988125,
            0.01700633205473423,
            0.02479749172925949,
            0.009270141832530499,
            -0.010751812718808651,
            0.018924862146377563,
            -0.006005095783621073,
            0.00675426609814167,
            -0.00018442673899699003,
            -0.013840279541909695,
            -0.0059465873055160046,
            -0.013068192638456821,
            -0.004354074131697416,
            -0.013279742561280727,
            -0.0030074496753513813,
            0.030422838404774666,
            -0.001453009550459683,
            0.027696415781974792,
            0.017311690375208855,
            0.018617426976561546,
            -0.012456588447093964,
            -0.021259166300296783,
            0.04765208438038826,
            -0.02206742763519287,
            0.03694835305213928,
            -0.005040192976593971,
            0.003699576249346137,
            0.005678580142557621,
            0.016352377831935883,
            -0.0001629214093554765,
            -0.019816193729639053,
            -0.008487428538501263,
            0.003882795572280884,
            -0.013118719682097435,
            0.007038221228867769,
            0.00903790257871151,
            0.000594545213971287,
            0.008558272384107113,
            0.007516404148191214,
            0.031364619731903076,
            0.01803360879421234,
            -0.030580995604395866,
            -0.018314596265554428,
            0.02178327925503254,
            -0.012537861242890358,
            0.017550652846693993,
            -0.017734345048666,
            -0.010971734300255775,
            0.00647578714415431,
            0.006552600767463446,
            -0.01688031107187271,
            0.013853747397661209,
            0.01440821960568428,
            -0.003064913209527731,
            0.005325100384652615,
            0.025896932929754257,
            0.013460162095725536,
            -0.032169267535209656,
            0.007112844381481409,
            -0.001457361620850861,
            -0.0012215068563818932,
            -0.006634840276092291,
            0.021162832155823708,
            -0.04461236670613289,
            0.008789912797510624,
            -0.014265713281929493,
            0.022811364382505417,
            0.019837090745568275,
            -0.01715945266187191,
            0.014176998287439346,
            0.03772348165512085,
            0.02210407145321369,
            -0.019222646951675415,
            -0.008716237731277943,
            -0.0091749532148242,
            -0.02314596250653267,
            -0.023918693885207176,
            0.02872329391539097,
            -0.02306579053401947,
            -0.011414091102778912,
            -0.03485327214002609,
            -0.029013991355895996,
            0.0053933835588395596,
            0.007876013405621052,
            -0.027543865144252777,
            0.012569227255880833,
            -0.057769060134887695,
            0.011757846921682358,
            0.027153871953487396,
            0.030625885352492332,
            -0.010763135738670826,
            0.010183291509747505,
            -0.017954831942915916,
            0.03210258483886719,
            -0.012633509002625942,
            -0.013790825381875038,
            0.0206011775881052,
            0.005361201707273722,
            0.0013622025726363063,
            -0.01809925027191639,
            -0.0017050168244168162,
            -0.0026137821841984987,
            -0.03486380726099014,
            0.010773930698633194,
            -0.01856585033237934,
            0.01537336315959692,
            -0.03531740978360176,
            -0.03106974996626377,
            -0.0023165929596871138,
            0.017470546066761017,
            -0.025434646755456924,
            0.01682422123849392,
            0.010718774981796741,
            -0.008334428071975708,
            -0.000535572471562773,
            -0.011394384317100048,
            0.026780344545841217,
            -0.012802297249436378,
            0.018431343138217926,
            0.00780614884570241,
            -0.013257429003715515,
            0.002682838821783662,
            -0.042838204652071,
            0.02550009824335575,
            -0.02543087862432003,
            0.005258841905742884,
            0.017271824181079865,
            0.007238832302391529,
            -0.008630476891994476,
            -0.011202259920537472,
            0.043413881212472916,
            0.024320635944604874,
            0.012030527926981449,
            -0.02355765551328659,
            -0.021644502878189087,
            0.00569724990054965,
            0.04014016315340996,
            -0.01659553125500679,
            -0.035964347422122955,
            0.020907023921608925,
            -0.02756882831454277,
            0.005326495971530676,
            -0.01619799993932247,
            0.03225933015346527,
            0.031712163239717484,
            0.03354454040527344,
            0.013188589364290237,
            0.029966266825795174,
            0.005707064177840948,
            -0.02520226687192917,
            0.02365959621965885,
            0.022629741579294205,
            0.008576402440667152,
            -0.004123068880289793,
            0.017650162801146507,
            0.010287383571267128,
            0.01601843535900116,
            0.015541239641606808,
            0.007253873627632856,
            -0.03158840909600258,
            0.0061349724419415,
            -0.0032860548235476017,
            -0.0016618822701275349,
            -0.016451172530651093,
            0.042973775416612625,
            0.025435658171772957,
            0.011242426000535488,
            -0.006015380844473839,
            0.005062158219516277,
            0.024271992966532707,
            0.017068563029170036,
            0.023286594077944756,
            -0.006163191515952349,
            -0.0017108297906816006,
            -0.014279107563197613,
            0.006182702258229256,
            0.012374158948659897,
            0.014761424623429775,
            0.018501244485378265,
            -0.020692437887191772,
            0.04385285824537277,
            0.033439751714468,
            0.007625383324921131,
            0.005232020281255245,
            -0.024258928373456,
            -0.013973107561469078,
            -0.004330557305365801,
            -0.012383324094116688,
            -0.021829878911376,
            0.0148078678175807,
            -0.01537712849676609,
            0.020411793142557144,
            0.015508261509239674,
            0.016510922461748123,
            -0.020473580807447433,
            0.005014255177229643,
            -0.028974996879696846,
            -0.006904856767505407,
            0.0037971464917063713,
            -0.0013299392303451896,
            -0.022784534841775894,
            0.03926550969481468,
            -0.0028242196422070265,
            0.04060576856136322,
            0.006312202662229538,
            -0.00683855963870883,
            0.030000969767570496,
            -0.012416782788932323,
            -0.014944130554795265,
            0.034037474542856216,
            -0.029961708933115005,
            0.014012076891958714,
            0.021752800792455673,
            0.003933411091566086,
            -0.03374239429831505,
            -0.024519989266991615,
            -0.02148771472275257,
            -0.003137672320008278,
            -0.02072945423424244,
            0.024042554199695587,
            -0.02311057038605213,
            0.004848136566579342,
            -0.018588798120617867,
            0.03521977737545967,
            -0.036830056458711624,
            0.020241672173142433,
            0.0056028179824352264,
            -0.015458514913916588,
            -0.02297227457165718,
            0.014427711255848408,
            0.0038186488673090935,
            0.015128632076084614,
            0.021705567836761475,
            0.02311922237277031,
            -0.008043949492275715,
            -0.021520858630537987,
            0.027947653084993362,
            -0.028171176090836525,
            -0.029693152755498886,
            0.006066081114113331,
            -0.01178021915256977,
            0.0030830265022814274,
            -0.0026587187312543392,
            -0.007210741750895977,
            -0.017171885818243027,
            -0.00048661421169526875,
            0.017699379473924637,
            0.022484958171844482,
            0.0018707839772105217,
            0.011604337021708488,
            -0.04008295014500618,
            -0.02841240167617798,
            0.006722766440361738,
            -0.022559212520718575,
            -0.005383429583162069,
            -0.015404073521494865,
            -0.004914304707199335,
            -0.015747208148241043,
            0.027110716328024864,
            -0.005569834262132645,
            -0.02022448554635048,
            0.013726724311709404,
            -0.019927022978663445,
            -0.01593855768442154,
            -0.016823988407850266,
            0.010798187926411629,
            -0.007866641506552696,
            -0.007942360825836658,
            0.005944922566413879,
            0.011500675231218338,
            0.010877136141061783,
            -0.022385140880942345,
            -0.03661779686808586,
            -0.031260691583156586,
            0.014431881718337536,
            -0.024376630783081055,
            -0.029084710404276848,
            -0.022432919591665268,
            -0.012896689586341381,
            0.009109457023441792,
            -0.014643065631389618,
            0.007164513226598501,
            0.010587677359580994,
            0.03108787164092064,
            0.02256685681641102,
            -0.019803397357463837,
            0.02315482683479786,
            -0.0016895660664886236,
            0.02959345281124115,
            0.013598861172795296,
            0.010285904631018639,
            -0.0502483956515789,
            0.0256568044424057,
            -0.028113726526498795,
            0.05620883032679558,
            0.033104922622442245,
            0.0018167394446209073,
            -0.0342852883040905,
            0.02120118960738182,
            0.0043061659671366215,
            0.02620873786509037,
            -0.012927912175655365,
            0.017260177060961723,
            -0.023450816050171852,
            -0.0037540311459451914,
            0.02096446044743061,
            0.022339031100273132,
            -0.021363813430070877,
            0.031531766057014465,
            -0.0109575055539608,
            -0.005816907621920109,
            0.04767840728163719,
            0.030317887663841248,
            0.009986860677599907,
            -0.006029038690030575,
            -0.01261979155242443,
            0.00598069978877902,
            -0.026296023279428482,
            -0.018332256004214287,
            0.0025538653135299683,
            0.019088556990027428,
            0.017210116609930992,
            -0.027244098484516144,
            0.01128514762967825,
            -0.019183969125151634,
            0.009826126508414745,
            -0.0072382776997983456,
            0.020560361444950104,
            0.009889508597552776,
            -0.04533820599317551,
            0.032851673662662506,
            -0.033172983676195145,
            -0.005032413639128208,
            -0.021325398236513138,
            -0.006419711280614138,
            0.0179593525826931,
            0.03126995638012886,
            -0.002090446650981903,
            0.01474811788648367,
            -0.003739365842193365,
            0.012798961251974106,
            -0.029547573998570442,
            -0.022354720160365105,
            -0.026940127834677696,
            -0.01886662282049656,
            -0.0010232672793790698,
            -0.002067008288577199,
            -0.003529454581439495,
            0.026918673887848854,
            0.010110201314091682,
            0.008518604561686516,
            -0.007065402343869209,
            -0.01958620361983776,
            -0.006789879873394966,
            0.025841262191534042,
            0.015887917950749397,
            0.00020002991368528455,
            0.030818836763501167,
            -0.0009207637049257755,
            0.0027932284865528345,
            0.008718707598745823,
            0.04813399165868759,
            -0.006927673239260912,
            0.015809552744030952,
            0.035955481231212616,
            0.02910890057682991,
            -0.009309741668403149,
            0.031095661222934723,
            0.021362384781241417,
            0.026673223823308945,
            -0.019179170951247215,
            0.019633537158370018,
            0.031549349427223206,
            -0.026108531281352043,
            -0.038324929773807526,
            -0.004210660699754953,
            0.009464680217206478,
            -0.03915276750922203,
            -0.008386417292058468,
            -0.014829462394118309,
            0.03867558389902115,
            0.03722422569990158,
            -0.00956121739000082,
            -0.0016994485631585121,
            0.02186512015759945,
            -0.012048843316733837,
            -0.0028910499531775713,
            0.00939461961388588,
            0.01978645659983158,
            0.027700776234269142,
            0.006404456216841936,
            0.013217939995229244,
            -0.019450506195425987,
            -0.009042007848620415,
            0.03464500978589058,
            -0.018208323046565056,
            -0.01657654158771038,
            0.019808202981948853,
            0.018443897366523743,
            0.0008892068290151656,
            -0.005163116380572319,
            0.011192886158823967,
            0.015876002609729767,
            0.01526251807808876,
            -0.015439827926456928,
            -0.03323525935411453,
            0.01400613784790039,
            0.01128360629081726,
            0.001956090098246932,
            -0.0188616830855608,
            0.01759692281484604,
            -0.012854831293225288,
            0.005310077220201492,
            0.02066771499812603,
            0.03907713294029236,
            0.03951335325837135,
            0.03379204124212265,
            0.027791503816843033,
            0.02704782225191593,
            -0.025225985795259476,
            0.02843446098268032,
            -0.01935523934662342,
            -0.0223387498408556,
            0.006348552647978067,
            -0.01123603992164135,
            0.02758273482322693,
            0.015229995362460613,
            0.007268325425684452,
            -0.02512640506029129,
            0.01215161569416523,
            -0.02693399228155613,
            0.026966238394379616,
            -0.007883934304118156,
            0.009000936523079872,
            0.017490951344370842,
            -0.01313081569969654,
            -0.005433354992419481,
            -0.0019021371845155954,
            0.05037219822406769,
            0.01491220761090517,
            0.007864701561629772,
            0.0014252461260184646,
            0.0307289008051157,
            0.046448029577732086,
            0.025570983067154884,
            0.007624901365488768,
            0.026814520359039307,
            0.03182685375213623,
            0.02526772767305374,
            -0.0005771754076704383,
            0.015202019363641739,
            0.033823709934949875,
            -0.021297473460435867,
            -0.011581946164369583,
            0.01405512634664774,
            0.02441178262233734,
            0.0058502997271716595,
            0.010552984662353992,
            0.008425306528806686,
            0.025395633652806282,
            0.007736630272120237,
            0.03151959925889969,
            -0.002235058229416609,
            0.033907584846019745,
            -0.021874086931347847,
            0.042283255606889725,
            -0.0005461897817440331,
            -0.01056514773517847,
            0.034553300589323044,
            -0.02483576536178589,
            0.023559054359793663,
            -0.047772519290447235,
            -0.031233718618750572,
            0.0010503304656594992,
            0.03249821066856384,
            -0.03447466716170311,
            0.042010433971881866,
            0.016332099214196205,
            -0.021795306354761124,
            -0.0175075214356184,
            0.02749011479318142,
            0.041689682751894,
            0.004367033950984478,
            0.011499650776386261,
            -0.020565196871757507,
            0.030279751867055893,
            0.030996616929769516,
            0.0026516630314290524,
            0.030719811096787453,
            -0.01129967626184225,
            0.000522041053045541,
            -0.02406579628586769,
            -0.015044691041111946,
            0.004580128937959671,
            -0.010883702896535397,
            0.013542992062866688,
            0.03966432437300682,
            -0.009633599780499935,
            -0.0220694150775671,
            0.002100497717037797,
            0.020195642486214638,
            0.0018452260410413146,
            -0.031164679676294327,
            -0.013013537041842937,
            0.027759458869695663,
            -0.016851944848895073,
            -0.010958186350762844,
            0.01737401820719242,
            -0.029334023594856262,
            0.022852910682559013,
            0.04779960215091705,
            0.03416571766138077,
            0.016175979748368263,
            0.015142807736992836,
            0.016133245080709457,
            0.007431468926370144,
            -0.004862924572080374,
            -0.027004169300198555,
            -0.04213938117027283,
            -0.018053313717246056,
            0.020352184772491455,
            0.004757670685648918,
            0.020681729540228844,
            -0.005347495898604393,
            0.009383303113281727,
            -0.02243105322122574,
            -0.0035631710197776556,
            0.002066262997686863,
            0.0322122722864151,
            0.023404007777571678,
            -0.02397315576672554,
            0.015434516593813896,
            0.006706950254738331,
            0.007378677371889353,
            0.0015979609452188015,
            0.01357273943722248,
            0.005101390182971954,
            -0.009291055612266064,
            0.007090147119015455,
            0.008662547916173935,
            -0.024188246577978134,
            0.036053743213415146,
            0.023547900840640068,
            -0.008470354601740837,
            0.021178975701332092,
            -0.04263198748230934,
            -0.024899158626794815,
            -0.015279187820851803,
            0.026248173788189888,
            0.006847236771136522,
            0.005180117674171925,
            -0.005564382299780846,
            -0.011310662142932415,
            -0.007017415948212147,
            -0.006288154982030392,
            -0.02480706013739109,
            -0.0015282423701137304,
            -0.01414596289396286,
            0.053164903074502945,
            0.027474794536828995,
            -0.019921818748116493,
            -0.007741236127912998,
            0.04169463738799095,
            -0.03232165053486824,
            -0.011974884197115898,
            -0.010061384178698063,
            0.015850959345698357,
            -0.027827270328998566,
            -0.01828700304031372,
            0.01629723235964775,
            0.03680194914340973,
            0.02450498379766941,
            -0.008757457137107849,
            0.01725379191339016,
            0.023497028276324272,
            -0.020186148583889008,
            0.013309748843312263,
            -0.03661657124757767,
            0.0177159383893013,
            0.0061295670457184315,
            0.03307589888572693,
            0.0316464826464653,
            -0.029704593122005463,
            -0.008370306342840195,
            0.04141886904835701,
            0.002965374616906047,
            0.007667909376323223,
            -0.015445683151483536,
            0.00013457627210300416,
            0.017906490713357925,
            -0.021497437730431557,
            0.016141850501298904,
            0.026217417791485786,
            0.015776952728629112,
            0.016643989831209183,
            0.004285654053092003,
            0.02776394411921501,
            0.016434047371149063,
            -0.008846759796142578,
            0.03365015238523483,
            0.04079565405845642,
            0.02446119114756584,
            0.020122427493333817,
            -0.021557485684752464,
            -0.023033661767840385,
            -0.02808651328086853,
            0.00011651664681266993,
            -0.027715129777789116,
            -0.01038312166929245,
            0.006611591670662165,
            0.020566755905747414,
            0.02317925915122032,
            0.02153933234512806,
            -0.004568143282085657,
            0.02383817546069622,
            -0.02659086510539055,
            -0.015193323604762554,
            0.0026502148248255253,
            0.009301163256168365,
            -0.02424723096191883,
            0.019207274541258812,
            0.0038071689195930958,
            0.010239568538963795,
            0.002348960842937231,
            0.004961403552442789,
            -0.013372673653066158,
            0.05475321784615517,
            -0.023309744894504547
        ],
        [
            -0.015571012161672115,
            0.01344635896384716,
            0.013404172845184803,
            -0.0206752959638834,
            0.01887146569788456,
            0.04187106713652611,
            0.02938573993742466,
            -0.0254205334931612,
            -0.0291262399405241,
            0.012984875589609146,
            -0.008017810061573982,
            0.018060751259326935,
            -0.0018898973939940333,
            0.004503670148551464,
            0.020657218992710114,
            -0.013384351506829262,
            0.00031706388108432293,
            -0.0010162921389564872,
            0.0020436362829059362,
            0.028459928929805756,
            -0.024716489017009735,
            0.00011029543384211138,
            -0.05908370018005371,
            0.038997676223516464,
            -0.027075983583927155,
            0.031326841562986374,
            0.005533934570848942,
            0.02624930627644062,
            0.010202082805335522,
            0.012023904360830784,
            0.02672717534005642,
            0.00040112415445037186,
            -0.007957722060382366,
            -0.012262312695384026,
            -0.00299211242236197,
            -0.03454624116420746,
            -0.01166547555476427,
            0.012179925106465816,
            -0.003901728894561529,
            -0.022809725254774094,
            0.01804932951927185,
            0.007306965533643961,
            0.010279552079737186,
            -0.023083098232746124,
            -0.023670559749007225,
            -0.03856201469898224,
            0.0061478447169065475,
            -0.014321348629891872,
            0.021940240636467934,
            0.008024067617952824,
            0.028752896934747696,
            -0.025977889075875282,
            0.0022706747986376286,
            -0.011429805308580399,
            0.03769232705235481,
            0.017547298222780228,
            0.019636552780866623,
            0.004832272883504629,
            -0.016141386702656746,
            -0.004341234918683767,
            -0.04630172252655029,
            0.028312360867857933,
            -0.014506991021335125,
            0.028506038710474968,
            -0.027304155752062798,
            -0.030078871175646782,
            -0.028433002531528473,
            0.031745683401823044,
            0.013649366796016693,
            0.00792419258505106,
            0.015469989739358425,
            -0.0352935828268528,
            6.850563659099862e-05,
            0.019367674365639687,
            0.04312627390027046,
            0.0001746633934089914,
            -0.024425704032182693,
            -0.009942365810275078,
            4.986593467037892e-07,
            0.0055086612701416016,
            0.01007504016160965,
            0.01554113533347845,
            -0.030650028958916664,
            -0.0063652717508375645,
            0.028198137879371643,
            0.002886918606236577,
            -0.009645170532166958,
            0.03305312618613243,
            -0.02918056771159172,
            -0.0336691252887249,
            -0.03032432310283184,
            -0.022193780168890953,
            -0.01634424924850464,
            -0.016153624281287193,
            0.019368795678019524,
            0.03709694370627403,
            0.0055603873915970325,
            -0.02358301170170307,
            -0.024668224155902863,
            0.03912491351366043,
            -0.02063576504588127,
            -0.009839016944169998,
            0.013837181031703949,
            -0.00378120387904346,
            0.01964621990919113,
            -0.02195538394153118,
            -0.0038994052447378635,
            0.010520734824240208,
            0.010812334716320038,
            -0.03151474520564079,
            -0.011517315171658993,
            0.02243887260556221,
            -0.016805380582809448,
            -0.031497176736593246,
            0.019543897360563278,
            0.0379350408911705,
            0.012622322887182236,
            0.015896128490567207,
            -0.003982842899858952,
            -0.023875579237937927,
            -0.007476853672415018,
            -0.011068101972341537,
            0.02040010690689087,
            0.02864932268857956,
            0.023494968190789223,
            -0.03391993045806885,
            -0.027480661869049072,
            -0.026216041296720505,
            0.027599647641181946,
            -0.004018832929432392,
            -0.029090190306305885,
            -0.02716146782040596,
            -0.027473412454128265,
            -0.05093211680650711,
            -0.028499430045485497,
            0.026646766811609268,
            -0.01315863523632288,
            -0.006951605901122093,
            -0.005646961275488138,
            0.04886331036686897,
            0.0030597804579883814,
            -0.03142022714018822,
            -0.02997027151286602,
            -0.012042907066643238,
            -0.032814715057611465,
            0.012308214791119099,
            0.04480818659067154,
            -0.02552308328449726,
            0.02525847963988781,
            -0.02624187432229519,
            0.018935194239020348,
            -0.008591366931796074,
            -0.004776499699801207,
            -0.033067792654037476,
            0.02380751632153988,
            -0.04355815798044205,
            -0.029417328536510468,
            0.01724400371313095,
            0.00911103468388319,
            -0.02092711441218853,
            -0.007423760369420052,
            -0.01951701194047928,
            0.021526621654629707,
            0.03286907076835632,
            0.022937752306461334,
            0.02057797648012638,
            -0.030351100489497185,
            0.0049739861860871315,
            -0.002130310982465744,
            0.011821242980659008,
            0.026021046563982964,
            -0.025493407621979713,
            0.0008303826907649636,
            -0.025601353496313095,
            -0.020187072455883026,
            -0.022380970418453217,
            0.0004773855325765908,
            -0.005730870645493269,
            -0.05146712064743042,
            -0.013149287551641464,
            -0.011812562122941017,
            0.02712535485625267,
            -0.015163381583988667,
            0.016680222004652023,
            0.02078614942729473,
            -0.015638722106814384,
            0.004389687906950712,
            0.011137831956148148,
            0.014859302900731564,
            0.018803667277097702,
            -0.029665697365999222,
            -0.003194032236933708,
            -0.007628112565726042,
            -0.0031231145840138197,
            -0.010925167240202427,
            0.021509919315576553,
            0.01189667358994484,
            -0.005978246685117483,
            -0.011151318438351154,
            -0.0020688013173639774,
            -0.013386080972850323,
            -0.02359841763973236,
            0.028427915647625923,
            0.008856234140694141,
            -0.015620307065546513,
            -0.005237085744738579,
            0.016785969957709312,
            -0.01912376843392849,
            -0.020987922325730324,
            0.012839453294873238,
            -0.021863579750061035,
            -0.01899890787899494,
            -0.01931724138557911,
            0.019099624827504158,
            -0.02282421477138996,
            0.016384579241275787,
            0.032765090465545654,
            -0.025562288239598274,
            -0.024008529260754585,
            -0.02998616360127926,
            0.01087191142141819,
            -0.007917797192931175,
            0.014383235946297646,
            0.000387880252674222,
            -0.022696856409311295,
            0.0038619639817625284,
            -0.010166190564632416,
            -0.0036493714433163404,
            0.001820306177251041,
            -0.012024928815662861,
            0.0032309135422110558,
            0.022337324917316437,
            0.036457307636737823,
            -0.006932721473276615,
            -0.042197395116090775,
            0.030287818983197212,
            -0.02340887114405632,
            0.021508188918232918,
            0.028955908492207527,
            0.015299305319786072,
            -0.01870611496269703,
            0.013567985035479069,
            0.020834026858210564,
            -0.010893494822084904,
            0.005770436022430658,
            -0.019760990515351295,
            -0.0027623693458735943,
            0.029419198632240295,
            0.035150155425071716,
            0.029727887362241745,
            0.001360753900371492,
            0.025240130722522736,
            -0.04130489379167557,
            0.005898378789424896,
            0.019695648923516273,
            0.022204985842108727,
            -0.014706774614751339,
            0.011915263719856739,
            0.017707068473100662,
            -0.0050584436394274235,
            -0.013695409521460533,
            -0.00167617027182132,
            -0.017749205231666565,
            0.01671118475496769,
            -0.02734997123479843,
            -0.019223222509026527,
            0.02123126946389675,
            0.019445359706878662,
            -0.03332596644759178,
            0.024429576471447945,
            0.02988337352871895,
            -0.01646805927157402,
            0.01643947698175907,
            0.013501615263521671,
            0.02484099380671978,
            0.0030463917646557093,
            0.009984680451452732,
            -0.012864507734775543,
            -0.02347184531390667,
            0.02151663787662983,
            0.00348697230219841,
            0.03159702569246292,
            0.016682790592312813,
            -0.0007661579293198884,
            -0.01873883605003357,
            0.019545113667845726,
            0.018914392217993736,
            0.006795300170779228,
            0.009678873233497143,
            -0.0024284017272293568,
            -0.0007242118590511382,
            -0.02334853820502758,
            -0.023242859169840813,
            -0.012297688983380795,
            0.00817565806210041,
            -0.024061409756541252,
            -0.017084281891584396,
            0.032305460423231125,
            -0.0016862725606188178,
            -0.02283654175698757,
            0.01300895307213068,
            0.020348966121673584,
            0.0007162702968344092,
            -0.02255280315876007,
            0.004169159568846226,
            0.004769441671669483,
            0.04555938020348549,
            -0.012832876294851303,
            0.009284097701311111,
            -0.020114369690418243,
            0.012225012294948101,
            0.011028725653886795,
            0.02783026173710823,
            0.03177579119801521,
            -0.005707267206162214,
            -0.02007724903523922,
            0.0214396882802248,
            0.034074679017066956,
            -0.00404001260176301,
            0.011124766431748867,
            0.019322795793414116,
            0.03920048475265503,
            0.029850643128156662,
            -0.061452679336071014,
            -0.005917160771787167,
            0.004643793683499098,
            0.004332830663770437,
            0.012166395783424377,
            0.016449378803372383,
            -0.013177083805203438,
            -0.022749921306967735,
            -0.009212852455675602,
            -0.05282963067293167,
            0.026981569826602936,
            -0.021003782749176025,
            -0.018194379284977913,
            -0.02252270095050335,
            -0.010651418007910252,
            0.01181484293192625,
            -0.025546131655573845,
            0.040665581822395325,
            0.029740508645772934,
            0.0014598366105929017,
            -0.025248847901821136,
            -0.03158816695213318,
            -0.04708698391914368,
            0.016910143196582794,
            0.019056864082813263,
            0.022964268922805786,
            -0.026300758123397827,
            0.02640630304813385,
            -0.01941816881299019,
            -0.00025503768119961023,
            -0.001812009490095079,
            -0.05963945388793945,
            -0.02678554505109787,
            -0.0027489664498716593,
            0.00427861837670207,
            -0.008047840557992458,
            -0.031189294531941414,
            -0.012907280586659908,
            0.028420090675354004,
            -0.05579742044210434,
            0.03042932040989399,
            0.007291596848517656,
            0.020424703136086464,
            0.049406748265028,
            -0.021781209856271744,
            -0.00031120615312829614,
            -0.006731840781867504,
            0.03554147854447365,
            0.02842620760202408,
            -0.001387479598633945,
            -0.024182254448533058,
            -0.02890232764184475,
            -0.031099757179617882,
            -0.010300125926733017,
            -0.007402023766189814,
            0.011033212766051292,
            -0.0037965974770486355,
            0.02965608611702919,
            -0.008294563740491867,
            -0.02407168224453926,
            0.012148179113864899,
            -0.036120735108852386,
            -0.018067574128508568,
            -0.017483988776803017,
            -0.009970341809093952,
            -0.004782913252711296,
            -0.02184813655912876,
            9.039056021720171e-05,
            -0.003840849967673421,
            -0.020886128768324852,
            0.013651827350258827,
            -0.009439481422305107,
            0.0030132043175399303,
            0.01038292609155178,
            0.017643434926867485,
            -0.023336708545684814,
            -0.016732199117541313,
            -0.008387136273086071,
            -0.03135990351438522,
            -0.01008788775652647,
            0.005902502685785294,
            0.008268543519079685,
            0.010320622473955154,
            -0.010280744172632694,
            -0.04190622642636299,
            -0.01678568311035633,
            0.0009799490217119455,
            0.009451673366129398,
            0.006622226908802986,
            -0.036474794149398804,
            0.0247823353856802,
            0.025073768571019173,
            -0.030003949999809265,
            0.0015178222674876451,
            0.019474366679787636,
            0.0019035210134461522,
            0.0043017808347940445,
            -0.006970465648919344,
            0.02231251448392868,
            -0.03196559101343155,
            0.022144638001918793,
            -0.04141172766685486,
            -0.06763365119695663,
            -0.026925576850771904,
            0.035035546869039536,
            -0.02171342261135578,
            -0.008340088650584221,
            -0.014498610980808735,
            -0.025214780122041702,
            0.013039452955126762,
            0.008419950492680073,
            0.020878199487924576,
            0.03295404464006424,
            -0.016940511763095856,
            0.008933333680033684,
            -0.01419044565409422,
            -0.002822870621457696,
            -0.05435732379555702,
            0.003076717024669051,
            -0.007380625698715448,
            0.008042744360864162,
            0.0013045360101386905,
            0.007671165745705366,
            -0.00357609405182302,
            -0.05619923770427704,
            -0.026009729132056236,
            -0.011072829365730286,
            -0.01647954434156418,
            -0.014033649116754532,
            0.021602479740977287,
            0.006954404059797525,
            0.008325906470417976,
            0.009440368041396141,
            -0.017136016860604286,
            0.003973189741373062,
            0.0032118770759552717,
            0.028314223513007164,
            -0.005204967688769102,
            -0.021921010687947273,
            -0.008912703953683376,
            0.0012525434140115976,
            0.019257230684161186,
            -0.025506973266601562,
            -0.006675940006971359,
            -0.017964374274015427,
            -0.0053271339274942875,
            0.005727533716708422,
            0.0006146662053652108,
            0.0022918202448636293,
            0.020502151921391487,
            0.016708804294466972,
            0.030713411048054695,
            -0.008352193050086498,
            0.008526417426764965,
            -0.036779407411813736,
            0.0012806414160877466,
            -0.0011576734250411391,
            -0.00407216465100646,
            0.019896604120731354,
            -0.011287515051662922,
            -0.02366960234940052,
            -0.04356664419174194,
            -0.006939667277038097,
            -0.03527960181236267,
            -0.014921259135007858,
            -0.04550370201468468,
            0.00866607390344143,
            -0.02223910205066204,
            0.005065412726253271,
            -0.02434472180902958,
            0.016567282378673553,
            -0.057866424322128296,
            0.009566286578774452,
            -0.044071659445762634,
            0.029580840840935707,
            0.021137919276952744,
            -0.013502009212970734,
            0.004972794558852911,
            -0.036285366863012314,
            0.03383094444870949,
            0.01378940511494875,
            -0.028434118255972862,
            0.023588581010699272,
            -0.028680307790637016,
            0.0006885866750963032,
            -0.02157025784254074,
            -0.03177141770720482,
            0.011047665029764175,
            0.029739709571003914,
            0.02595732919871807,
            0.013041657395660877,
            -0.0007938457420095801,
            0.01726352795958519,
            -0.012757239863276482,
            -0.011435098946094513,
            -0.013625831343233585,
            -0.026604337617754936,
            -0.035600803792476654,
            -0.02078884094953537,
            -0.019119996577501297,
            0.022324468940496445,
            -0.018296390771865845,
            -0.023658111691474915,
            -0.042750876396894455,
            -0.00801892764866352,
            -0.020581092685461044,
            0.019639980047941208,
            -0.0071604871191084385,
            0.010765651240944862,
            0.019394511356949806,
            0.022687720134854317,
            0.031458813697099686,
            -0.040051668882369995,
            0.017032813280820847,
            0.029370173811912537,
            0.041766759008169174,
            -0.003231567097827792,
            0.0075539350509643555,
            0.020518416538834572,
            -0.008600729517638683,
            -0.04446208104491234,
            -0.01871633157134056,
            -0.0050459960475564,
            0.022542782127857208,
            -0.015930913388729095,
            0.018844682723283768,
            -0.008870166726410389,
            0.002498476766049862,
            0.011486991308629513,
            -0.024594981223344803,
            -0.025581808760762215,
            -0.019398335367441177,
            0.01195511408150196,
            0.012749423272907734,
            -0.004132571164518595,
            -0.013224536553025246,
            -0.012720759958028793,
            -0.0021509709767997265,
            -0.019499382004141808,
            -0.026831254363059998,
            -0.01886010356247425,
            -0.019974175840616226,
            0.026627808809280396,
            0.04570426419377327,
            0.005409812089055777,
            0.023403087630867958,
            -0.010316306725144386,
            0.025434205308556557,
            0.003969975281506777,
            0.024020494893193245,
            0.017463086172938347,
            0.013604004867374897,
            0.009110335260629654,
            -0.005195556208491325,
            -0.041308239102363586,
            0.002540377201512456,
            0.02353711798787117,
            0.00471819844096899,
            -0.03566580265760422,
            0.019551267847418785,
            0.02235398069024086,
            0.030599825084209442,
            -0.002700859447941184,
            -0.0006593259749934077,
            -0.02287997677922249,
            0.00973438285291195,
            -0.02199121192097664,
            0.022192053496837616,
            -0.023805690929293633,
            0.010738284327089787,
            0.020918788388371468,
            0.024325726553797722,
            -0.018677925691008568,
            -0.028730470687150955,
            -0.01228390634059906,
            0.027473924681544304,
            0.007616331800818443,
            0.010394680313766003,
            0.02598666585981846,
            0.009296149015426636,
            0.027851784601807594,
            -0.0022979825735092163,
            -0.00011376947804819793,
            0.03244238346815109,
            -0.02984691970050335,
            -0.03604200482368469,
            -0.03887716680765152,
            0.028403958305716515,
            0.004732268396764994,
            0.008008182048797607,
            0.012087325565516949,
            0.014276104047894478,
            0.010372328571975231,
            0.025654617697000504,
            0.027834348380565643,
            0.016106970608234406,
            -0.05738784372806549,
            0.022121554240584373,
            0.0023905655834823847,
            -0.02515318989753723,
            0.01774289831519127,
            -0.020451722666621208,
            -0.014927820302546024,
            -0.036934804171323776,
            0.01128560584038496,
            -0.0382666289806366,
            -0.015145888552069664,
            0.004495943430811167,
            -0.008731911890208721,
            -0.027738327160477638,
            -0.009983908385038376,
            -0.030279168859124184,
            -0.01473725214600563,
            -0.007627082522958517,
            0.013140418566763401,
            -0.026857780292630196,
            0.020355550572276115,
            -0.001520862802863121,
            -0.03102710098028183,
            -0.012506138533353806,
            0.017601141706109047,
            -0.008548343554139137,
            -0.008842389099299908,
            0.042672231793403625,
            0.02934005670249462,
            0.007118165958672762,
            0.013513649813830853,
            -0.0021804976277053356,
            0.02106907032430172,
            0.007990053854882717,
            -0.017056647688150406,
            0.029043549671769142,
            -0.02405780740082264,
            0.006674358621239662,
            0.031091103330254555,
            0.00111487228423357,
            0.026613201946020126,
            0.019252296537160873,
            -0.007800120394676924,
            0.015192510560154915,
            -0.01374137308448553,
            -0.01622834987938404,
            -0.02443665638566017,
            -0.015601307153701782,
            -0.011678531765937805,
            -0.00967495795339346,
            -0.022958653047680855,
            -0.04047342389822006,
            -0.006101775914430618,
            0.009690217673778534,
            0.029677819460630417,
            0.009568913839757442,
            0.03900175169110298,
            -0.025856440886855125,
            0.019813798367977142,
            0.0012927743373438716,
            0.008408485911786556,
            0.015833644196391106,
            -0.005570476874709129,
            -0.01377890259027481,
            0.008373460732400417,
            0.0407576709985733,
            0.0011183421593159437,
            -0.023661168292164803,
            -0.02901887148618698,
            0.038784291595220566,
            0.020028769969940186,
            0.0005177486455067992,
            -0.01632748544216156,
            -0.014697530306875706,
            0.0047511751763522625,
            -0.006164469290524721,
            0.03777525573968887,
            -0.0014242432080209255,
            -0.0013364977203309536,
            -0.014300322160124779,
            -0.032161906361579895,
            0.01869313046336174,
            -0.00302189402282238,
            -0.026785962283611298,
            -0.017849449068307877,
            0.027747148647904396,
            -0.0023861806839704514,
            0.012776928953826427,
            0.007537411991506815,
            0.021799061447381973,
            -0.0007857175078243017,
            0.005830723326653242,
            0.034830477088689804,
            -0.021043241024017334,
            0.008301784284412861,
            0.008656135760247707,
            0.030964870005846024,
            -0.08097508549690247,
            -0.004910055547952652,
            0.00939785037189722,
            -0.0033927420154213905,
            -0.00903412513434887,
            -0.02110625058412552,
            -0.013925934210419655,
            0.015816790983080864,
            -0.001086136675439775,
            0.02430754341185093,
            0.011588486842811108,
            -0.002876176033169031,
            0.03130727261304855,
            0.011839261278510094,
            0.018366944044828415,
            -0.018757138401269913,
            -0.03492189198732376,
            -0.014481037855148315,
            -0.007112331222742796,
            -0.02802743948996067,
            -0.017715802416205406,
            -0.023052003234624863,
            0.027923230081796646,
            0.003829781897366047,
            -0.00617789663374424,
            -0.0026767300441861153,
            -0.004570536315441132,
            0.0090480362996459,
            -0.022456416860222816,
            -0.023266445845365524,
            -0.05141943693161011,
            0.0026195186655968428,
            0.023441536352038383,
            0.02127743698656559,
            0.02704295888543129,
            0.005728290416300297,
            0.027138860896229744,
            -0.0402778685092926,
            0.0032142901327461004,
            -0.010275335982441902,
            0.029956981539726257,
            -0.06363262981176376,
            -0.007192843593657017,
            -0.004409290850162506,
            0.016690248623490334,
            0.029086345806717873,
            -0.019405029714107513,
            0.0032388060353696346,
            -0.021294405683875084,
            -0.01245896052569151,
            -0.013335615396499634,
            -0.02561814710497856,
            -0.005899176001548767,
            -0.03897939994931221,
            -0.012675602920353413,
            0.01609228178858757,
            -0.017024565488100052,
            0.025621447712183,
            0.009769574739038944,
            0.027069484815001488,
            -0.01271038968116045,
            -0.07222217321395874,
            -0.0007754580583423376,
            0.004848324693739414,
            -0.02945677563548088,
            -0.025893300771713257,
            -0.025005338713526726,
            -0.0030896684620529413,
            0.02558250166475773,
            -0.009914573282003403,
            0.02992181107401848,
            0.006458500400185585,
            0.01680317521095276,
            0.0019638738594949245,
            0.009181282483041286,
            -0.028705500066280365,
            -0.007914954796433449,
            0.012728321366012096,
            -0.0007153528276830912,
            -0.01763690821826458,
            -0.005696034990251064,
            0.014350878074765205,
            -0.003691468620672822,
            -0.0247612614184618,
            1.419827913196059e-05,
            0.014193250797688961,
            0.023894177749753,
            0.004662096966058016,
            0.01554311066865921,
            0.04095802828669548,
            0.012509800493717194,
            0.02346745692193508,
            -0.016221677884459496,
            0.008121841587126255,
            -0.014471655711531639,
            0.027095874771475792,
            -0.021887756884098053,
            0.030458837747573853,
            -0.02588173747062683,
            -0.0011805352987721562,
            0.007315990049391985,
            0.019900135695934296,
            0.025465162470936775,
            0.008104395121335983,
            0.01848730817437172,
            0.00614826800301671,
            0.012452799826860428,
            -0.016382435336709023,
            0.04551790654659271,
            0.029165375977754593,
            0.009365632198750973,
            0.032647985965013504,
            -0.016055136919021606,
            -0.015285461209714413,
            0.005740419030189514,
            -0.022207748144865036,
            -0.036102112382650375,
            -0.02526649460196495,
            0.015452656894922256,
            -0.006658169440925121,
            -0.025492604821920395,
            0.03629441559314728,
            0.02199428901076317,
            -0.0546446330845356,
            0.00206943042576313,
            -0.0005831352900713682,
            0.013978167437016964,
            0.020382024347782135,
            -0.002437910297885537,
            0.020155202597379684,
            0.021816883236169815,
            0.017370738089084625,
            -0.007804380729794502,
            0.0323663093149662,
            0.009775235317647457,
            0.0029895200859755278,
            0.008544676937162876,
            0.025130145251750946,
            -0.025238974019885063,
            -0.019494809210300446,
            0.0014894725754857063,
            0.03732597455382347,
            -0.023441532626748085,
            0.010834365151822567,
            -0.00010691009811125696,
            -0.006094601470977068,
            0.002562212524935603,
            0.017015937715768814,
            0.03452856466174126,
            -0.01260413695126772,
            -0.002095384057611227,
            -0.01603633537888527,
            -0.0070381429977715015,
            -0.013592618517577648,
            -0.008028688840568066,
            0.012452773749828339,
            0.019328298047184944,
            0.019159385934472084,
            -0.02558712475001812,
            0.004494451452046633,
            0.0021469288039952517,
            -0.06942873448133469,
            0.00029158761026337743,
            0.02129554934799671,
            -0.011903979815542698,
            -0.03516664355993271,
            0.01368091069161892,
            0.008192245848476887,
            0.018518861383199692,
            0.00016588867583777755,
            -0.026385797187685966,
            -0.036409635096788406,
            -0.013514135032892227,
            -0.019944556057453156,
            0.008512099273502827,
            -0.03060190938413143,
            -0.01877420023083687,
            0.04109115153551102,
            -0.01858985237777233,
            0.02338539995253086,
            -0.02985258586704731,
            -0.008903230540454388,
            0.035706885159015656,
            -0.020000919699668884,
            0.008183935657143593,
            0.02103574573993683,
            0.02325979806482792,
            -0.031095711514353752,
            -0.01464348565787077,
            -0.03244594484567642,
            0.0027074909303337336,
            0.014198091812431812,
            -0.023064184933900833,
            -0.017172543331980705,
            0.0203385092318058,
            -0.0003173602162860334,
            -0.0015813662903383374,
            -0.015917008742690086,
            -0.02626015618443489,
            -0.02845470979809761,
            -0.017576592043042183,
            -0.022548701614141464,
            0.013469922356307507,
            0.012643033638596535,
            -0.023930305615067482,
            -0.023170586675405502,
            0.01592598296701908,
            -0.03418906405568123,
            -0.03454830124974251,
            -0.01141580380499363,
            -0.0050897011533379555,
            -0.0029488452710211277,
            -0.0063237352296710014,
            -0.022264163941144943,
            -0.022840989753603935,
            0.005187194794416428,
            -0.030983611941337585,
            0.006277502980083227,
            0.008126665838062763,
            -0.017558349296450615,
            0.018657928332686424,
            0.026795119047164917,
            0.024330200627446175,
            -0.006679029203951359,
            0.008508410304784775,
            0.0024618797469884157,
            0.04427757486701012,
            0.02878967672586441,
            0.0017874655313789845,
            -0.02380102314054966,
            0.019369585439562798,
            -0.0045099868439137936,
            -0.024870680645108223,
            -0.02322145737707615,
            -0.053982943296432495,
            0.021938202902674675,
            0.011433466337621212,
            -0.038810938596725464,
            0.007797429338097572,
            -0.03888672590255737,
            0.010676556266844273,
            0.03137381002306938,
            -0.01090475358068943,
            0.02054721862077713,
            0.048406362533569336,
            -0.022524608299136162,
            -0.0466342493891716,
            0.026279354467988014,
            0.010249431245028973,
            0.00490889186039567,
            0.015486669726669788,
            0.025770241394639015,
            -0.005493549630045891,
            0.012810028158128262,
            0.029365789145231247,
            -0.013436426408588886,
            0.012332899495959282,
            0.012953626923263073,
            0.03302137926220894,
            0.005944923032075167,
            -0.024725034832954407,
            0.023353727534413338,
            -0.016925537958741188,
            0.016224270686507225,
            0.0342763289809227,
            0.030387647449970245,
            -0.04774472862482071,
            -0.005101044196635485,
            0.010724436491727829,
            0.019005583599209785,
            -0.006603565998375416,
            -0.02230529673397541,
            0.009175353683531284,
            0.021173052489757538,
            -0.005582829471677542,
            0.018899887800216675,
            0.02803860977292061,
            0.01715989224612713,
            -0.0030404613353312016,
            -0.02226960100233555,
            -0.03774520754814148,
            -0.0035611384082585573,
            -0.05163586884737015,
            0.04165066033601761,
            -0.013676849193871021,
            -0.00894373469054699,
            -0.04938000813126564,
            0.019628869369626045,
            0.03118046000599861,
            0.0233022328466177,
            -0.002190437400713563,
            -0.02396341785788536,
            0.003236412536352873,
            -0.03305651247501373,
            0.004670500755310059,
            0.015672991052269936,
            -0.0036132987588644028,
            -0.01955220475792885,
            0.025991518050432205,
            -0.012836992740631104,
            -0.01825789548456669,
            0.007744987029582262,
            -0.0028346404433250427,
            0.0023884435649961233,
            -0.035780493170022964,
            -0.07102971524000168,
            0.024986157193779945,
            0.015049844980239868
        ],
        [
            0.038985542953014374,
            0.009074787609279156,
            0.01495238859206438,
            0.03765382617712021,
            -0.01768873818218708,
            -0.024488795548677444,
            0.011972258798778057,
            0.01440860889852047,
            -0.011204228736460209,
            0.02656131237745285,
            -0.010714461095631123,
            0.024217670783400536,
            0.02059881016612053,
            0.028362996876239777,
            0.00039134526741690934,
            -0.016627874225378036,
            -0.018411824479699135,
            0.023137634620070457,
            0.02003699168562889,
            0.02773093618452549,
            -0.0181356780230999,
            0.005253637675195932,
            0.018583040684461594,
            -0.01789945363998413,
            -0.035800281912088394,
            -0.018312228843569756,
            -0.005392277613282204,
            0.0030072967056185007,
            0.016915997490286827,
            -0.01857062615454197,
            -0.03508644551038742,
            0.021057335659861565,
            -0.006054495926946402,
            -0.00074947060784325,
            -0.03444918990135193,
            -0.00373065541498363,
            0.011306485161185265,
            0.03507362678647041,
            0.017484288662672043,
            -0.02443530783057213,
            -0.0006186261307448149,
            -0.04214407876133919,
            0.014015134423971176,
            -0.01072766911238432,
            -0.0025061643682420254,
            0.025040635839104652,
            -0.013368732295930386,
            0.020585933700203896,
            -0.010563638061285019,
            -0.01237217616289854,
            -0.02192053571343422,
            0.003824608400464058,
            0.02180507406592369,
            -0.01116514764726162,
            0.008985628373920918,
            -0.01260173600167036,
            -0.01721402071416378,
            -0.011986816301941872,
            -0.00559725658968091,
            0.031107598915696144,
            0.005232098512351513,
            0.02596062235534191,
            0.019027551636099815,
            -0.013194097205996513,
            -0.011794491671025753,
            -0.019049596041440964,
            0.03667150065302849,
            0.009748226031661034,
            -0.0014428120339289308,
            0.01256070751696825,
            0.004309383220970631,
            -0.01844312809407711,
            0.0029189148917794228,
            -0.02553899772465229,
            -0.0029112526681274176,
            -0.014334384351968765,
            0.015310518443584442,
            0.0261452104896307,
            -0.007891116663813591,
            0.005704549141228199,
            0.03212831914424896,
            0.007421438582241535,
            -0.009258545003831387,
            -0.003162924898788333,
            -0.005390333477407694,
            -0.012416784651577473,
            -0.0015718343202024698,
            0.006069408264011145,
            -0.004694008734077215,
            0.035845234990119934,
            -0.01900251768529415,
            0.022784734144806862,
            0.0223480723798275,
            0.011709216050803661,
            0.024932464584708214,
            0.03372447192668915,
            -0.01854851096868515,
            -0.00022985300165601075,
            -0.048137858510017395,
            -0.028593558818101883,
            0.009046810679137707,
            0.02979292720556259,
            -0.003152275923639536,
            -0.020542938262224197,
            0.009286697953939438,
            -0.02239815890789032,
            -0.01667863130569458,
            0.010671015828847885,
            0.02277049608528614,
            0.023070860654115677,
            0.005795720033347607,
            0.00923541747033596,
            0.02826002798974514,
            0.015979690477252007,
            -0.02535005286335945,
            -0.022864647209644318,
            -0.019798243418335915,
            0.022881539538502693,
            0.017828319221735,
            -0.015424150973558426,
            -0.021830830723047256,
            -0.021335875615477562,
            0.018050210550427437,
            0.01999540999531746,
            0.027477405965328217,
            0.02270478941500187,
            0.011381841264665127,
            0.014192957431077957,
            0.026406802237033844,
            0.004883015062659979,
            -0.024800995364785194,
            -0.006419464945793152,
            0.05100646987557411,
            -0.018713053315877914,
            0.027527039870619774,
            -0.023348988965153694,
            -0.005809611640870571,
            -0.045715365558862686,
            -0.016864746809005737,
            0.01118987426161766,
            -0.0014875930501148105,
            -0.02385902777314186,
            -0.008359473198652267,
            0.028307314962148666,
            0.0012210920685902238,
            0.0314776711165905,
            -0.004886603448539972,
            0.003920033574104309,
            0.003061824943870306,
            -0.011119277216494083,
            0.009450682438910007,
            -0.04109872505068779,
            -0.022254951298236847,
            -0.025536663830280304,
            0.01027990784496069,
            0.018362179398536682,
            0.0119971027597785,
            -0.03017658181488514,
            0.023830898106098175,
            0.00983161199837923,
            0.025061244145035744,
            0.02749280072748661,
            -0.03425189480185509,
            0.018101871013641357,
            0.021317768841981888,
            0.005684797652065754,
            -0.0062150196172297,
            0.007680907845497131,
            0.02240402065217495,
            0.02859429083764553,
            -0.0001761388557497412,
            0.01497793011367321,
            -0.032439105212688446,
            -0.04402472823858261,
            0.0025661278050392866,
            0.018009191378951073,
            0.013347743079066277,
            -0.026549749076366425,
            -0.006287761498242617,
            0.024843746796250343,
            0.027144087478518486,
            -0.026052139699459076,
            -0.002969206077978015,
            -0.0044020661152899265,
            -0.026413321495056152,
            0.011534257791936398,
            -0.029443468898534775,
            -0.024840857833623886,
            -0.01382681168615818,
            -0.0049179596826434135,
            -0.012110678479075432,
            0.029325377196073532,
            0.025594225153326988,
            -0.003260477911680937,
            0.008817056193947792,
            0.009505346417427063,
            0.015046318992972374,
            -0.016035493463277817,
            0.027976790443062782,
            -0.024437636137008667,
            -0.007627540733665228,
            -0.010859517380595207,
            -0.025680340826511383,
            -0.029437677934765816,
            0.05056849122047424,
            0.019657248631119728,
            -0.012494516558945179,
            0.0294354185461998,
            -0.028053244575858116,
            0.010986539535224438,
            0.02631210722029209,
            -0.02662978693842888,
            0.014627545140683651,
            -0.0036948148626834154,
            0.026203978806734085,
            0.01704414188861847,
            -0.008115298114717007,
            0.03025471232831478,
            0.013154430314898491,
            0.004202997777611017,
            -0.01535668782889843,
            -0.023548083379864693,
            0.02112826332449913,
            -0.01416280772536993,
            -0.014187853783369064,
            0.010364961810410023,
            0.027590755373239517,
            -0.027546651661396027,
            0.011466923169791698,
            -0.02464292198419571,
            0.017475169152021408,
            0.019354211166501045,
            0.020248791202902794,
            -0.01650005392730236,
            0.010000849142670631,
            -0.016307048499584198,
            0.009875940158963203,
            -0.019543329253792763,
            0.018806496635079384,
            0.021516140550374985,
            0.0018690115539357066,
            -0.0020740649197250605,
            0.026927467435598373,
            0.032875996083021164,
            0.029796069487929344,
            0.0012040339643135667,
            -0.0347527340054512,
            0.02568831481039524,
            -0.01044069416821003,
            -0.02242356725037098,
            0.004954062402248383,
            0.002889294410124421,
            -0.0014095613732933998,
            -0.021039200946688652,
            0.0026743083726614714,
            0.005674472078680992,
            0.028118479996919632,
            -0.01065127458423376,
            0.03491652011871338,
            -0.0006292269681580365,
            -0.019348841160535812,
            0.019517825916409492,
            -0.010771587491035461,
            0.04500369355082512,
            0.012037170119583607,
            0.02287161350250244,
            0.023562774062156677,
            0.003718860214576125,
            0.01822849176824093,
            -0.01103188470005989,
            0.02480960451066494,
            0.005286103114485741,
            0.018057329580187798,
            0.024938413873314857,
            0.028624925762414932,
            0.009081187658011913,
            0.00404853280633688,
            0.0005267797387205064,
            -0.021619997918605804,
            0.01867150329053402,
            -0.010432618670165539,
            -0.0037011976819485426,
            0.026660587638616562,
            -0.03475922718644142,
            0.0027874198276549578,
            0.02183476835489273,
            -0.009401156567037106,
            0.0038493990432471037,
            -0.029169445857405663,
            -0.00651707174256444,
            -0.02678474225103855,
            0.016125023365020752,
            0.01681838370859623,
            0.01999097503721714,
            0.028126206248998642,
            0.0015631724381819367,
            0.01023768074810505,
            -0.018722131848335266,
            -0.015974167734384537,
            0.02410086616873741,
            -0.012203936465084553,
            0.009404967539012432,
            -0.010723907500505447,
            0.006833958439528942,
            -0.009966320358216763,
            -0.03734883666038513,
            0.02754613757133484,
            -0.008144795894622803,
            0.007390081416815519,
            0.007351100444793701,
            0.01202821172773838,
            0.016036933287978172,
            -0.012035656720399857,
            0.01192423701286316,
            0.006737137213349342,
            0.01805168204009533,
            0.03047579899430275,
            0.03122757375240326,
            0.02034551277756691,
            -0.022401418536901474,
            0.01975271850824356,
            0.017816122621297836,
            -0.015037201344966888,
            -0.05107470601797104,
            -0.02013208530843258,
            -0.015242351219058037,
            -0.012761110439896584,
            -0.013835159130394459,
            0.004898752551525831,
            -0.016932107508182526,
            -0.007279098033905029,
            -0.008656064979732037,
            0.026468783617019653,
            0.004429020453244448,
            0.013675619848072529,
            0.026866639032959938,
            -0.0016431635012850165,
            0.026817956939339638,
            -0.031816571950912476,
            -0.02074563130736351,
            0.016955973580479622,
            -0.002087872475385666,
            0.027383899316191673,
            -0.027357570827007294,
            -0.019171055406332016,
            -0.029044197872281075,
            0.020596886053681374,
            -0.01669926755130291,
            -0.010247137397527695,
            -0.018441980704665184,
            0.032471995800733566,
            0.0028001386672258377,
            -0.01930520497262478,
            0.019030336290597916,
            -0.0012937822612002492,
            0.00840004812926054,
            0.03099822998046875,
            -0.010147006250917912,
            0.008995849639177322,
            0.0130160478875041,
            0.007955932058393955,
            -0.011784672737121582,
            0.020477421581745148,
            -0.016960972920060158,
            0.023449266329407692,
            0.00787715520709753,
            -0.020629657432436943,
            0.006205930840224028,
            0.006211380939930677,
            0.01799389347434044,
            -0.006390104070305824,
            0.0193118155002594,
            -0.011149099096655846,
            -0.016744880005717278,
            0.0009910583030432463,
            -0.0012771070469170809,
            0.00623528566211462,
            -0.040138617157936096,
            -0.02907431870698929,
            -0.027748189866542816,
            -0.0464334636926651,
            -0.02420005202293396,
            -0.007384090218693018,
            -0.023451002314686775,
            0.00349011761136353,
            0.018333666026592255,
            0.011093990877270699,
            0.004815574269741774,
            -0.008786224760115147,
            -0.008629442192614079,
            -0.008619049564003944,
            0.008142618462443352,
            -0.01619042083621025,
            -0.030636796727776527,
            0.0029085474088788033,
            0.017321545630693436,
            0.03153035789728165,
            -0.016940215602517128,
            -0.023961396887898445,
            -0.013381698168814182,
            0.0330117866396904,
            -0.008915649726986885,
            0.002013289835304022,
            0.026568768545985222,
            0.007618199568241835,
            -0.032202303409576416,
            -0.021112745627760887,
            -0.030338244512677193,
            -0.028813034296035767,
            -0.009982638992369175,
            -0.012189124710857868,
            -0.037758082151412964,
            -0.03878069296479225,
            -0.016734659671783447,
            0.010399111546576023,
            0.0016023070784285665,
            0.0025324036832898855,
            0.0026986890006810427,
            -0.02053234912455082,
            0.024916866794228554,
            -0.0030124147888273,
            -0.013051798567175865,
            -0.018023286014795303,
            0.008275807835161686,
            0.026083102449774742,
            0.0006619293708354235,
            -0.016035519540309906,
            -0.02442021109163761,
            0.02969285659492016,
            0.01916320063173771,
            0.012305522337555885,
            0.004186829086393118,
            0.03130172938108444,
            -0.03347961977124214,
            0.023406904190778732,
            0.012305933982133865,
            0.0064046853221952915,
            -0.026842232793569565,
            -0.03381473571062088,
            -0.009229891933500767,
            0.0019618566147983074,
            -0.007930959574878216,
            -0.006949310190975666,
            0.028068767860531807,
            0.02796509675681591,
            0.021968768909573555,
            0.015832485631108284,
            -0.020327793434262276,
            -0.03130403906106949,
            -0.023047596216201782,
            0.008520325645804405,
            0.025505980476737022,
            0.0176292285323143,
            0.009157116524875164,
            0.0012561302864924073,
            -0.001466102316044271,
            -0.06580536812543869,
            -0.022214222699403763,
            0.021127674728631973,
            0.01944703981280327,
            -0.012964059598743916,
            0.003879631171002984,
            0.004471804480999708,
            -0.018380654975771904,
            0.0002970903879031539,
            -0.023742621764540672,
            -0.005200840998440981,
            0.0384131483733654,
            -0.025451375171542168,
            0.023801222443580627,
            -0.02468445710837841,
            -0.004989487584680319,
            -0.01927005685865879,
            -0.009758704341948032,
            -0.017716560512781143,
            -0.013610243797302246,
            -0.002789595164358616,
            -0.01849226839840412,
            0.008520943112671375,
            0.0014093068893998861,
            -0.00672860536724329,
            0.012415396049618721,
            0.05482085421681404,
            0.015753375366330147,
            0.0035785783547908068,
            -0.005084519740194082,
            0.008042335510253906,
            0.019699882715940475,
            0.0771675631403923,
            0.026006149128079414,
            -0.00024363108968827873,
            -0.025623226538300514,
            0.022498395293951035,
            -0.038170941174030304,
            0.0046065011993050575,
            -0.0041026584804058075,
            0.020431360229849815,
            -0.008803674951195717,
            -0.008864996023476124,
            0.0224522203207016,
            0.014991899952292442,
            0.004583850968629122,
            -0.009530422277748585,
            -0.0033316335175186396,
            0.0009068803046829998,
            0.031180381774902344,
            0.009395808912813663,
            -0.01531285885721445,
            0.006285659968852997,
            -0.020818538963794708,
            0.0277685709297657,
            0.0023903255350887775,
            0.009701232425868511,
            -0.02513599768280983,
            -0.011228875257074833,
            0.018104400485754013,
            -0.021915746852755547,
            0.02517639845609665,
            -0.003097532782703638,
            -0.03051898255944252,
            -0.023008190095424652,
            0.015330266207456589,
            0.030351795256137848,
            0.024659262970089912,
            -0.013668293133378029,
            0.0026175782550126314,
            -0.05339059978723526,
            -0.005221185274422169,
            -0.012009477242827415,
            0.015497269108891487,
            0.010934185236692429,
            0.026627806946635246,
            0.013745281845331192,
            0.004139797296375036,
            -0.02780422754585743,
            -0.021440818905830383,
            -0.031763847917318344,
            0.01940540410578251,
            0.00829453393816948,
            0.03635012358427048,
            0.0032131164334714413,
            0.01827302575111389,
            -0.013034739531576633,
            -0.007885517552495003,
            0.019843755289912224,
            0.02758365124464035,
            -0.033761005848646164,
            0.02213108167052269,
            -0.010950698517262936,
            -0.0022297862451523542,
            -0.02606741525232792,
            0.01829349249601364,
            0.02539971098303795,
            0.017455240711569786,
            -0.02350318245589733,
            -0.0019742532167583704,
            0.01208348199725151,
            -0.006364610977470875,
            0.022700002416968346,
            -0.001232452574186027,
            -0.008286013267934322,
            -0.018012013286352158,
            0.025944186374545097,
            -0.05455562472343445,
            -0.018116552382707596,
            -0.012482649646699429,
            0.01113907340914011,
            0.028798796236515045,
            0.025611335411667824,
            -0.0038512772880494595,
            0.028683489188551903,
            0.02384958788752556,
            -0.024981122463941574,
            0.025997335091233253,
            -0.03214317560195923,
            -0.0006426931358873844,
            -0.005625046789646149,
            0.0027290149591863155,
            0.04155934229493141,
            -0.024526963010430336,
            0.02376026287674904,
            -0.0073968395590782166,
            -0.01547277718782425,
            -0.01700597256422043,
            0.009022499434649944,
            -0.014987870119512081,
            -0.0029868234414607286,
            -0.007946634665131569,
            0.016805093735456467,
            0.01900496706366539,
            0.02663295529782772,
            0.02828211523592472,
            -0.002261196728795767,
            0.03232630714774132,
            0.0241140965372324,
            0.002526565920561552,
            0.008947770111262798,
            -0.022949587553739548,
            -0.0042097982950508595,
            -0.009648038074374199,
            -0.025337865576148033,
            -0.033169567584991455,
            -0.00543839018791914,
            0.0073925224132835865,
            -0.02262628637254238,
            0.005259589292109013,
            0.01700977236032486,
            -0.007720701862126589,
            0.03134433552622795,
            0.004582425579428673,
            -0.00812577549368143,
            0.02583366073668003,
            0.023116791620850563,
            -0.026960795745253563,
            -0.010860511101782322,
            0.0053745112381875515,
            -0.021150697022676468,
            -0.0001566888822708279,
            -0.0030364273115992546,
            0.026920592412352562,
            0.018255507573485374,
            -0.015080061741173267,
            0.0027637891471385956,
            0.00877433829009533,
            -0.02752915397286415,
            0.023751985281705856,
            0.03279147297143936,
            0.03102361597120762,
            -0.013419175520539284,
            0.023351192474365234,
            0.037356726825237274,
            0.0004357050929684192,
            0.018607215955853462,
            0.02582116238772869,
            0.012465521693229675,
            -0.007502203341573477,
            -0.005805106367915869,
            -0.011334353126585484,
            0.0034505806397646666,
            -0.0018858476541936398,
            0.0340486615896225,
            -0.027535347267985344,
            -0.014618592336773872,
            -0.02365993894636631,
            0.02143949642777443,
            0.00898362509906292,
            0.04773207753896713,
            -0.010483487509191036,
            0.01712397299706936,
            0.03428173437714577,
            0.027151528745889664,
            -0.03308165445923805,
            -0.008918472565710545,
            -0.011397755704820156,
            0.035879526287317276,
            -0.02581542544066906,
            0.039879210293293,
            0.01387790497392416,
            0.02658652886748314,
            -0.015657823532819748,
            0.047052282840013504,
            0.02562107890844345,
            0.014156308025121689,
            -0.0060006193816661835,
            0.001189155620522797,
            -0.014081782661378384,
            -0.024301573634147644,
            -0.01223982684314251,
            0.007756887003779411,
            -0.009043826721608639,
            0.007331051863729954,
            0.022395549342036247,
            0.025423211976885796,
            -0.010408560745418072,
            0.02437455579638481,
            -0.011883771046996117,
            0.002911052666604519,
            -0.009430088102817535,
            0.014953697100281715,
            0.0018533294787630439,
            -0.024921156466007233,
            -0.025265734642744064,
            0.02829102799296379,
            -0.01702081598341465,
            -0.029990024864673615,
            -0.021730773150920868,
            0.01736041158437729,
            -0.010645585134625435,
            0.03482919931411743,
            0.013152801431715488,
            -0.04883192852139473,
            0.0019955169409513474,
            -0.007269585505127907,
            -0.008141950704157352,
            -0.01989707350730896,
            0.027343597263097763,
            0.03152712062001228,
            -0.005236570257693529,
            0.03138159215450287,
            -0.012810258194804192,
            -0.0049517955631017685,
            0.005669399630278349,
            -0.02739882469177246,
            -0.015203005634248257,
            0.010913207195699215,
            -0.010027259588241577,
            -0.01070786640048027,
            0.018869560211896896,
            -0.0199773870408535,
            -0.00041369209066033363,
            0.01263451762497425,
            -0.009623508900403976,
            0.022774713113904,
            -0.005823406856507063,
            -0.013562509790062904,
            -0.007363291922956705,
            0.01249344740062952,
            -0.04574212804436684,
            0.022377563640475273,
            -0.019794480875134468,
            0.01921561360359192,
            -0.023324832320213318,
            -0.005460754968225956,
            -0.014034870080649853,
            -0.01804272271692753,
            0.006316248327493668,
            0.025496402755379677,
            -0.005909537430852652,
            0.03359230235219002,
            0.008463287726044655,
            -0.020817620679736137,
            -0.02938404120504856,
            -0.008500123396515846,
            0.03157716244459152,
            0.0008266245713457465,
            0.041259754449129105,
            -0.010917485691606998,
            -0.007840398699045181,
            0.004264138173311949,
            0.002308492548763752,
            0.030050121247768402,
            0.0121125103905797,
            0.02345949038863182,
            -0.012965032830834389,
            -0.02267576940357685,
            0.00861632265150547,
            -0.013187957927584648,
            -0.038073938339948654,
            -0.007832873612642288,
            0.024952931329607964,
            0.006920062005519867,
            0.006621004547923803,
            0.02038896270096302,
            -0.03099534474313259,
            0.003207005327567458,
            -0.017279433086514473,
            -0.020108496770262718,
            -0.08197491616010666,
            -0.01849987544119358,
            0.012385714799165726,
            0.0063812704756855965,
            0.04558072239160538,
            0.026324665173888206,
            -0.015414055436849594,
            0.00629468634724617,
            -0.0075574833899736404,
            -0.0029324728529900312,
            -0.015366805717349052,
            0.0016753331292420626,
            -0.01911921240389347,
            0.006631061900407076,
            0.007033376023173332,
            0.00879206508398056,
            0.017461158335208893,
            0.019884413108229637,
            -0.016094237565994263,
            -0.004676912911236286,
            -0.048155106604099274,
            -0.022329412400722504,
            0.01698741316795349,
            0.031136181205511093,
            -0.025094768032431602,
            0.026306210085749626,
            0.009098158217966557,
            -0.01292052399367094,
            0.023273207247257233,
            0.007670480292290449,
            0.03338753432035446,
            -0.021417051553726196,
            0.03920823708176613,
            -0.03099549561738968,
            -0.031400084495544434,
            -0.022593578323721886,
            0.020423434674739838,
            0.03145764023065567,
            0.009612997062504292,
            0.0009919332806020975,
            0.016351383179426193,
            0.017028452828526497,
            0.0013950408902019262,
            -5.1557399274315685e-05,
            -0.04152749851346016,
            0.027088046073913574,
            -0.048127271234989166,
            0.0073034618981182575,
            -0.012262925505638123,
            -0.003098860150203109,
            -0.0024855437222868204,
            0.019586531445384026,
            0.0045915283262729645,
            -0.009933555498719215,
            0.0045445566065609455,
            0.024226123467087746,
            0.032180801033973694,
            -0.006836207117885351,
            0.031534913927316666,
            -0.01934877596795559,
            0.01910630241036415,
            0.007043097168207169,
            0.001621049246750772,
            0.014422577805817127,
            0.026048755273222923,
            -0.0029813707806169987,
            -0.023852365091443062,
            0.04161433130502701,
            -0.01774822361767292,
            0.01736902818083763,
            0.005638833157718182,
            0.023790797218680382,
            -0.018775885924696922,
            -0.011997977271676064,
            0.02161576971411705,
            -0.02044178918004036,
            -0.01725555770099163,
            -0.015601413324475288,
            -0.022245384752750397,
            -0.021225059404969215,
            0.0012551607796922326,
            -0.01214773952960968,
            -0.034874070435762405,
            -0.022625600919127464,
            0.029094833880662918,
            -0.018523426726460457,
            -0.024993648752570152,
            0.015478904359042645,
            0.0009588198736310005,
            -0.012462788261473179,
            -0.01773482747375965,
            -0.0308663472533226,
            -0.015055893920361996,
            0.013899344019591808,
            -0.030491342768073082,
            -0.029183831065893173,
            -0.00719192810356617,
            0.005026540718972683,
            0.007972890511155128,
            -0.012575951404869556,
            0.0021880636923015118,
            0.003081724513322115,
            -0.011093072593212128,
            -0.011160077527165413,
            -0.020202049985527992,
            0.024455944076180458,
            -0.011061452329158783,
            0.0009834442753344774,
            -0.008305208757519722,
            -0.015812113881111145,
            -0.02047448232769966,
            0.010505324229598045,
            -0.0030385905411094427,
            0.003160513238981366,
            0.02571915276348591,
            0.005185190588235855,
            -0.013598055578768253,
            0.011957810260355473,
            0.04175226390361786,
            -0.008034085854887962,
            0.002895700978115201,
            0.021150490269064903,
            -0.011294431984424591,
            -0.012523552402853966,
            -0.03872962296009064,
            0.000579572282731533,
            -0.0001924926764331758,
            0.02293950878083706,
            0.015046842396259308,
            -0.014024166390299797,
            -0.007769664283841848,
            -0.01587020605802536,
            0.02031942829489708,
            0.00460406020283699,
            0.0009839865379035473,
            -0.011143278330564499,
            0.0031827837228775024,
            0.009702840819954872,
            0.02730785310268402,
            0.0214493740350008,
            -0.022144397720694542,
            0.022130785509943962,
            0.008801242336630821,
            -0.03084426373243332,
            0.01116272620856762,
            -0.010490616783499718,
            -0.015263616107404232,
            -0.03029060736298561,
            -0.021018145605921745,
            0.0022396498825401068,
            0.00951411947607994,
            0.021093405783176422,
            0.01556558907032013,
            0.01317393034696579,
            0.02621251530945301,
            0.0020448602735996246,
            -0.0037159023340791464,
            -0.02693844400346279,
            -0.015590664930641651,
            0.0007702057482674718,
            -0.007226595655083656,
            -0.019171092659235,
            -0.007559047546237707,
            -0.008282603695988655,
            -0.03004656732082367,
            -0.015660932287573814,
            0.016969043761491776,
            -0.03579509258270264,
            -0.04067576304078102,
            0.015413420274853706,
            -0.020436380058526993,
            0.014713289216160774,
            -0.005136583931744099,
            0.027119465172290802,
            0.009676618501543999,
            0.011126209981739521,
            0.04235990345478058,
            -0.008823556825518608,
            -0.021835340186953545,
            -0.004929686896502972,
            -0.0259750597178936,
            -0.02070377580821514,
            0.032106898725032806,
            0.018128804862499237,
            0.020153138786554337,
            0.0008499928517267108,
            -0.01928994245827198,
            0.020859023556113243,
            0.020560894161462784,
            -0.03668226674199104,
            -0.0023903497494757175,
            -0.0003759146202355623,
            0.015115965157747269,
            0.0397004634141922,
            0.019893907010555267,
            0.012454037554562092,
            -0.02696731500327587,
            -0.01900903321802616,
            0.01717475987970829,
            -0.006353198550641537,
            -0.02318664640188217,
            -0.01243529748171568,
            -0.01858861744403839,
            0.009124902077019215,
            0.01082542072981596,
            0.02098459005355835,
            -0.0226291436702013,
            0.014686151407659054,
            -0.02347736991941929,
            -0.009962588548660278,
            0.008291104808449745,
            0.01552901417016983,
            -0.030144860967993736,
            -0.019653186202049255,
            0.03218696638941765,
            -0.016217505559325218,
            -0.02395600639283657,
            0.011197907850146294,
            0.0005095347878523171,
            0.02105512097477913,
            0.013694683089852333,
            0.022328713908791542,
            -0.017786571756005287,
            -0.03632533177733421,
            -0.015173588879406452,
            0.026330631226301193,
            -0.014721579849720001,
            -0.034896042197942734,
            0.02588515542447567,
            -0.014474998228251934,
            0.020607806742191315,
            0.006770068779587746,
            0.01973586715757847,
            0.009368868544697762,
            0.013083701021969318,
            0.018759334459900856,
            -0.006720267236232758,
            -0.00976706761866808,
            0.0465046688914299,
            0.03519003465771675,
            -0.01081325113773346,
            -0.009713903069496155,
            0.01718340627849102,
            -0.02759850025177002,
            0.0017863822868093848,
            0.00829174742102623,
            0.007811085786670446,
            0.004595799371600151,
            0.004365073051303625,
            0.025546176359057426,
            0.015270059928297997,
            -0.02685614861547947,
            -0.016313700005412102,
            -0.014471704140305519,
            0.03297613561153412,
            -0.01597193442285061,
            -0.02056010067462921,
            0.0071347979828715324,
            -0.005143259651958942,
            -0.011558995582163334,
            0.01923191361129284,
            0.02606975845992565,
            0.022140657529234886,
            0.008788538165390491,
            0.036023158580064774,
            -0.019378043711185455,
            -0.03114664927124977
        ],
        [
            -0.02315584011375904,
            -0.011068543419241905,
            -0.03616762533783913,
            -0.03761998564004898,
            0.011777492240071297,
            0.021482285112142563,
            0.02256809175014496,
            0.0226341113448143,
            0.0153864910826087,
            0.0034403842873871326,
            -0.023089254274964333,
            0.009770458564162254,
            0.037315934896469116,
            0.006270152982324362,
            -0.017834773287177086,
            0.043525971472263336,
            0.0280167069286108,
            -0.012510363943874836,
            -0.0031946476083248854,
            -0.03283219039440155,
            -0.005842071492224932,
            0.017942916601896286,
            0.03303223103284836,
            0.0056579336524009705,
            -0.01085151731967926,
            0.030307898297905922,
            0.02880358323454857,
            -0.00522382790222764,
            0.040223848074674606,
            -0.029802067205309868,
            0.043652862310409546,
            -0.04105754941701889,
            0.0046390690840780735,
            -0.05755181983113289,
            0.029057592153549194,
            -0.03742410987615585,
            -0.001219267607666552,
            -0.007840811274945736,
            -0.0226349588483572,
            -0.04010886698961258,
            -0.005582394544035196,
            0.024018684402108192,
            -0.041499920189380646,
            0.0010327845811843872,
            -0.023392204195261,
            0.037604767829179764,
            -0.012275604531168938,
            0.000497882894705981,
            0.020053626969456673,
            0.010925548151135445,
            -0.03347233682870865,
            0.0015974404523149133,
            -0.0045301965437829494,
            -0.021435968577861786,
            0.0022500830236822367,
            -0.03144672140479088,
            0.03638356551527977,
            -0.017692703753709793,
            0.015300112776458263,
            0.01717265509068966,
            -0.0187965240329504,
            -0.025835739448666573,
            -0.008146506734192371,
            -0.0016663228161633015,
            0.01450728252530098,
            -0.0010734444949775934,
            0.02891363762319088,
            0.007630218751728535,
            0.0025686798617243767,
            -0.042169734835624695,
            0.022055795416235924,
            0.0032612336799502373,
            -0.018510453402996063,
            0.01944063976407051,
            -0.051678478717803955,
            0.0038729074876755476,
            -0.015819253399968147,
            0.05232962965965271,
            0.0037255524657666683,
            -0.030079428106546402,
            0.0011539185652509332,
            0.06429419666528702,
            -0.026896493509411812,
            0.03615843877196312,
            -0.0034541087225079536,
            -0.03310072794556618,
            0.01964782178401947,
            0.035302307456731796,
            -0.01305821817368269,
            0.001009610015898943,
            -0.0025761667639017105,
            0.009792803786695004,
            0.0029055699706077576,
            -0.04664028808474541,
            0.02434813790023327,
            -0.022154247388243675,
            0.03541697561740875,
            -0.026554150506854057,
            0.04779495671391487,
            -0.018954573199152946,
            -0.004976476542651653,
            0.05805777758359909,
            0.01388639397919178,
            -0.0024997114669531584,
            0.00991173554211855,
            -0.006107944529503584,
            -0.02601144276559353,
            -0.025519859045743942,
            0.056826651096343994,
            0.018545076251029968,
            -0.003239853074774146,
            0.029697146266698837,
            -0.0005005743587389588,
            -0.011967701837420464,
            -0.00457608699798584,
            0.015806864947080612,
            -0.010456067509949207,
            -0.0067804972641170025,
            -0.020979614928364754,
            -0.03121809847652912,
            0.03311378136277199,
            -0.010408350266516209,
            -0.043861933052539825,
            -0.05595986545085907,
            0.004335801117122173,
            -0.024847853928804398,
            0.028887633234262466,
            -0.009295351803302765,
            -0.011785471811890602,
            0.02861064113676548,
            -0.00954648107290268,
            0.017285944893956184,
            0.012145522981882095,
            0.010767500847578049,
            -0.02303088642656803,
            0.0426909364759922,
            0.020261360332369804,
            0.05883711948990822,
            -0.049725037068128586,
            0.01117616705596447,
            0.004035544581711292,
            0.01625034026801586,
            -0.020149536430835724,
            -0.0015979546587914228,
            0.01899287849664688,
            -0.0311760101467371,
            -0.015536333434283733,
            0.02153945341706276,
            -0.011651035398244858,
            -0.04831584170460701,
            -0.02353653311729431,
            0.009111888706684113,
            -0.04325556010007858,
            -0.08581577986478806,
            -0.01247290801256895,
            0.041897714138031006,
            -0.024597380310297012,
            0.033159468322992325,
            -0.03206849843263626,
            -0.0044004409573972225,
            0.010185076855123043,
            0.02573206275701523,
            0.02771741710603237,
            0.0008215882699005306,
            0.026399098336696625,
            -0.05625016614794731,
            -0.01448681391775608,
            -0.04490796849131584,
            -0.007798125967383385,
            -0.03893342986702919,
            -0.022829687222838402,
            0.0007735255640000105,
            -0.026454301550984383,
            -0.03499554470181465,
            0.033237386494874954,
            0.008610581047832966,
            0.031978774815797806,
            0.019399821758270264,
            0.020730001851916313,
            -0.011542015708982944,
            -0.015136050991714,
            -0.03825734928250313,
            0.01993713527917862,
            -0.027803685516119003,
            -0.027928270399570465,
            -0.0014084798749536276,
            0.06416533887386322,
            -0.030756033957004547,
            0.001988228876143694,
            -0.03439607471227646,
            -0.00656620878726244,
            0.028481047600507736,
            -0.030924584716558456,
            0.021758537739515305,
            0.024775099009275436,
            0.0047346968203783035,
            0.00018759888189379126,
            -0.005939194932579994,
            -0.017188524827361107,
            -0.027903825044631958,
            0.008584448136389256,
            -0.02848430722951889,
            -0.025657210499048233,
            0.0021942255552858114,
            -0.046288538724184036,
            0.01863463968038559,
            0.009387224912643433,
            0.006278802640736103,
            0.03587229177355766,
            -0.014277308247983456,
            -0.013766374439001083,
            -0.01554072741419077,
            0.006241337861865759,
            -0.014952712692320347,
            0.03687243536114693,
            -0.02706700935959816,
            0.015622136183083057,
            -0.015245591290295124,
            0.02799547091126442,
            0.0035429513081908226,
            -0.027474533766508102,
            -0.01731211319565773,
            0.013982819393277168,
            -0.008078526705503464,
            -0.023126689717173576,
            0.051316019147634506,
            0.006965321954339743,
            0.03381618857383728,
            0.025648079812526703,
            -0.014132662676274776,
            -0.0336349681019783,
            -0.015549697913229465,
            0.009954900480806828,
            -0.02752198651432991,
            -0.008800692856311798,
            -0.025868458673357964,
            0.044890522956848145,
            -0.024422600865364075,
            0.026060976088047028,
            -0.027943622320890427,
            -0.04078695923089981,
            -0.008044986985623837,
            0.03300149738788605,
            -0.005780685693025589,
            0.03714903071522713,
            0.007373180240392685,
            0.02666238322854042,
            0.013431760482490063,
            0.001973445527255535,
            0.010390223003923893,
            0.01658407412469387,
            0.025892963632941246,
            -0.050025634467601776,
            -0.019787225872278214,
            0.015753934159874916,
            0.025035427883267403,
            -0.01341155357658863,
            0.02677750773727894,
            0.005267643835395575,
            0.026664748787879944,
            -0.012814538553357124,
            0.02882525697350502,
            0.0006586146773770452,
            -0.015816571190953255,
            -0.01719730533659458,
            -0.0416632816195488,
            -0.025986304506659508,
            -0.03028876706957817,
            -0.029264407232403755,
            -0.08355259895324707,
            0.007475132122635841,
            0.025315454229712486,
            -0.020396152511239052,
            0.025382036343216896,
            0.017560841515660286,
            0.022081537172198296,
            0.032886311411857605,
            -0.0009202900109812617,
            0.008243096061050892,
            -0.014536217786371708,
            0.0011707161320373416,
            0.02605736441910267,
            -0.014567695558071136,
            0.0413944236934185,
            -0.03504602611064911,
            -0.026889348402619362,
            -0.026097247377038002,
            0.03465520590543747,
            0.013774257153272629,
            -0.04015078768134117,
            -0.011552274227142334,
            0.015910912305116653,
            -0.005802234169095755,
            0.004668476991355419,
            -0.003334441687911749,
            0.016964083537459373,
            0.033165961503982544,
            0.014735858887434006,
            -0.04821785166859627,
            -0.014203035272657871,
            0.015197793953120708,
            -0.019956819713115692,
            -0.004065630026161671,
            -0.019975269213318825,
            0.006026121322065592,
            -0.04708888381719589,
            0.0098414970561862,
            0.02143755927681923,
            0.011872397735714912,
            -0.04602339491248131,
            0.008605506271123886,
            0.02409847266972065,
            -0.03183765336871147,
            -0.005703267641365528,
            -0.019640523940324783,
            0.011913125403225422,
            -0.015363655984401703,
            -0.019203845411539078,
            -0.01768956519663334,
            0.03361887484788895,
            -0.017016597092151642,
            -0.04970052093267441,
            0.007960328832268715,
            0.025749510154128075,
            -0.0045267026871442795,
            0.017369743436574936,
            0.03762218728661537,
            -0.01762058213353157,
            0.0017176286783069372,
            -0.002500282134860754,
            0.03667747601866722,
            -0.005490155424922705,
            -0.03518456965684891,
            0.02446443773806095,
            -0.0037299219984561205,
            0.04742824658751488,
            -0.013531467877328396,
            0.011896953918039799,
            0.013073689304292202,
            0.018628355115652084,
            -0.018611039966344833,
            0.036983586847782135,
            0.020657893270254135,
            0.003006312996149063,
            -0.027877306565642357,
            0.02934722602367401,
            -0.023228803649544716,
            -0.006731485482305288,
            0.0017711941618472338,
            0.007296528667211533,
            0.008686828427016735,
            -0.03659450635313988,
            0.03815745189785957,
            -0.059588320553302765,
            0.03377308323979378,
            0.00794256292283535,
            0.016660649329423904,
            -0.042074691504240036,
            -0.03320514038205147,
            -0.027544384822249413,
            -0.023894526064395905,
            0.0028837721329182386,
            0.03913215547800064,
            0.020166480913758278,
            -0.00951351411640644,
            0.0322982482612133,
            -0.013317791745066643,
            0.02235378883779049,
            0.003315570065751672,
            0.01033428031951189,
            -0.05789537355303764,
            0.010269707068800926,
            0.01410745270550251,
            -0.013336600735783577,
            -0.02147403173148632,
            0.014659453183412552,
            0.034761421382427216,
            0.0007873012218624353,
            0.020495466887950897,
            -0.004102269187569618,
            0.0006507608341053128,
            0.005446788854897022,
            -0.0184229277074337,
            0.00231114961206913,
            0.01690431497991085,
            0.007531133946031332,
            0.005956748034805059,
            -0.02045862190425396,
            -0.005772452801465988,
            0.011944008991122246,
            -0.006900556851178408,
            0.03178991749882698,
            -0.03864213824272156,
            0.005033497698605061,
            0.07146249711513519,
            -0.01660717837512493,
            -0.02348685823380947,
            -0.005697605665773153,
            0.0252549909055233,
            0.03814660385251045,
            0.008485397323966026,
            0.008161014877259731,
            0.02823975495994091,
            0.016679907217621803,
            0.001989134354516864,
            0.023763179779052734,
            -0.016496477648615837,
            0.009729025885462761,
            0.01955656334757805,
            -0.011256207711994648,
            -0.005632447544485331,
            -0.02987842820584774,
            0.0004583487461786717,
            0.01580698788166046,
            -0.024386219680309296,
            0.0034209867008030415,
            -0.01740858517587185,
            -0.06634789705276489,
            0.01347764115780592,
            -0.028166020289063454,
            -0.03438090160489082,
            -0.02157401852309704,
            0.0426776297390461,
            -0.012645960785448551,
            0.010518398135900497,
            -0.009357127360999584,
            0.001935599953867495,
            0.03752363845705986,
            -0.00378487934358418,
            0.0076062604784965515,
            0.023260077461600304,
            -0.021922994405031204,
            -0.003001582808792591,
            -0.003390772268176079,
            0.0021343259140849113,
            0.0012498695868998766,
            -0.010417032986879349,
            0.01653439551591873,
            -0.02012338489294052,
            -0.03156963735818863,
            -0.0005756435566581786,
            -0.04691514000296593,
            -0.029168833047151566,
            0.008854052983224392,
            -0.04014894366264343,
            0.007813066244125366,
            0.021795770153403282,
            -0.03960046544671059,
            0.03244146704673767,
            -0.011109867133200169,
            0.008605304174125195,
            -0.02267245575785637,
            0.00492932228371501,
            -0.02496568113565445,
            -0.042069174349308014,
            -0.016472842544317245,
            0.011596622876822948,
            0.024756966158747673,
            0.0265798382461071,
            0.01102698128670454,
            -0.01789398305118084,
            -0.03942904993891716,
            0.028469782322645187,
            0.004851971752941608,
            0.0018120798049494624,
            0.023209307342767715,
            -0.0331830196082592,
            -0.024272063747048378,
            0.017653578892350197,
            -0.009493024088442326,
            0.004151604603976011,
            0.009165843948721886,
            -0.02272353693842888,
            -0.00039545464096590877,
            0.02219475619494915,
            0.016182906925678253,
            0.01990175060927868,
            -0.002081615850329399,
            -0.024524817243218422,
            -0.027325790375471115,
            -0.017709217965602875,
            -0.01230036374181509,
            -0.047118257731199265,
            -0.029089663177728653,
            -0.0281648188829422,
            0.021243812516331673,
            -0.005502399988472462,
            -0.009984202682971954,
            -0.07084553688764572,
            -0.018577685579657555,
            0.015204784460365772,
            -0.009717044420540333,
            -0.019838567823171616,
            0.00021729983564000577,
            0.006900215987116098,
            -0.04727788269519806,
            0.035149071365594864,
            -0.024401690810918808,
            0.017891759052872658,
            0.012327494099736214,
            0.03493767976760864,
            0.003469364484772086,
            0.007537421770393848,
            0.04568590968847275,
            -0.02536674216389656,
            0.017443325370550156,
            0.021005455404520035,
            0.02779337204992771,
            -0.03023594059050083,
            0.025874946266412735,
            0.013644653372466564,
            -0.020571082830429077,
            -0.025730183348059654,
            0.031035643070936203,
            0.0067650331184268,
            0.016343120485544205,
            -0.02536848746240139,
            -0.013566648587584496,
            -0.023871881887316704,
            0.015768907964229584,
            -0.012044528499245644,
            -0.02808908000588417,
            -0.03338371589779854,
            0.0034163957461714745,
            -0.023370610550045967,
            -0.026372291147708893,
            -0.046494122594594955,
            0.005395569838583469,
            -0.02813614346086979,
            0.052681680768728256,
            0.009937095455825329,
            0.014610815793275833,
            -0.022653469815850258,
            0.0053419023752212524,
            -0.0007461487548425794,
            0.03528578579425812,
            0.013035486452281475,
            0.01763913594186306,
            0.009715442545711994,
            -0.02986798621714115,
            0.006301785819232464,
            -0.0032530499156564474,
            -0.007820612750947475,
            -0.02546636201441288,
            0.001757818623445928,
            -0.04165605828166008,
            -0.006290864665061235,
            -0.06684523820877075,
            -0.021421117708086967,
            0.02666667103767395,
            -0.01392921432852745,
            -0.0005778880440630019,
            0.014544130302965641,
            0.005097683984786272,
            -0.0008105100132524967,
            0.0036019471008330584,
            0.009149412624537945,
            0.018229065462946892,
            -0.020519448444247246,
            0.019366402179002762,
            -0.02149481326341629,
            -0.01738363318145275,
            0.009988699108362198,
            0.0019495844608172774,
            0.01751076430082321,
            0.0067169698886573315,
            -0.06201555207371712,
            -0.035781629383563995,
            -0.020945163443684578,
            -0.0122429383918643,
            0.008390745148062706,
            -0.009350884705781937,
            -0.020987259224057198,
            -0.028173940256237984,
            -0.04323424771428108,
            -0.0318809412419796,
            -0.023565905168652534,
            0.008765279315412045,
            -0.020355425775051117,
            0.009175245650112629,
            0.024589337408542633,
            -0.002876696176826954,
            0.01656327024102211,
            -0.019176291301846504,
            0.005682122893631458,
            -0.022274121642112732,
            -0.023722616955637932,
            -0.035919059067964554,
            -0.011188386008143425,
            -0.032035358250141144,
            -0.030601587146520615,
            -0.004189199302345514,
            0.037363357841968536,
            -0.006571503821760416,
            -0.013581129722297192,
            0.020591601729393005,
            0.009396689012646675,
            -0.030190972611308098,
            0.02294883504509926,
            -0.0445229634642601,
            -0.020234454423189163,
            0.007501763291656971,
            -0.009310717694461346,
            -0.03792192414402962,
            0.005176408216357231,
            0.032791052013635635,
            -0.0216587632894516,
            -0.03870372101664543,
            0.03132649138569832,
            0.037722788751125336,
            -0.03201036527752876,
            0.00735391303896904,
            0.025444427505135536,
            0.01663367636501789,
            -0.028115637600421906,
            0.010015005245804787,
            -0.017725368961691856,
            0.031451139599084854,
            -0.025662876665592194,
            -0.0023038347717374563,
            -0.0156956035643816,
            -0.013343428261578083,
            -0.002084113657474518,
            -0.020703867077827454,
            0.009970113635063171,
            0.03010948747396469,
            -0.020698148757219315,
            0.00919480063021183,
            0.027432164177298546,
            0.007394791580736637,
            0.009714581072330475,
            0.0336812399327755,
            9.53402923187241e-05,
            -0.010594739578664303,
            0.025857076048851013,
            -0.012529991567134857,
            -0.026567775756120682,
            0.003171497257426381,
            0.042551860213279724,
            0.021270137280225754,
            0.025605935603380203,
            0.011392914690077305,
            -0.031822673976421356,
            0.02772030606865883,
            0.006581501103937626,
            0.0003097908047493547,
            -0.023420395329594612,
            0.005054329056292772,
            0.001600930467247963,
            -0.004503870382905006,
            0.022560719400644302,
            0.0357857309281826,
            -0.010725948959589005,
            0.005430076736956835,
            0.007781227584928274,
            -0.004032740369439125,
            -0.059333913028240204,
            0.022290300577878952,
            0.027231967076659203,
            -0.006872423458844423,
            -0.04333895072340965,
            0.01641889661550522,
            -0.01590573601424694,
            -0.0015194842126220465,
            -0.004233955405652523,
            0.014098415151238441,
            0.004790801089257002,
            -0.004064517095685005,
            -0.011493113823235035,
            -0.03325643390417099,
            -0.038278158754110336,
            0.004263808019459248,
            -0.01492749247699976,
            0.036753300577402115,
            0.013738996349275112,
            -0.025149598717689514,
            -0.02922525443136692,
            0.01231839507818222,
            -0.002055020071566105,
            0.012850643135607243,
            -0.035081468522548676,
            0.015708917751908302,
            -0.01394882332533598,
            0.02345328778028488,
            -0.011132228188216686,
            0.014976466074585915,
            -0.031418219208717346,
            -0.02356812357902527,
            -0.01826746016740799,
            -0.006346443202346563,
            0.046811919659376144,
            -0.026147227734327316,
            0.013376635499298573,
            -0.03391048312187195,
            -0.004280161578208208,
            -0.025970572605729103,
            0.005947441793978214,
            -0.030663874000310898,
            -0.025580916553735733,
            -0.008266509510576725,
            -0.005730580538511276,
            0.016737213358283043,
            0.02350473403930664,
            0.014057227410376072,
            0.009281259961426258,
            -0.018045369535684586,
            -0.03019133023917675,
            0.02550482004880905,
            -0.026086989790201187,
            -0.01055541168898344,
            0.01757568120956421,
            0.005485194269567728,
            -0.020675428211688995,
            -0.03430735692381859,
            0.02765467017889023,
            -0.010390336625277996,
            0.04273620992898941,
            -0.003148110816255212,
            0.0024543702602386475,
            -0.015837473794817924,
            -0.022017432376742363,
            -0.025608470663428307,
            0.00722045311704278,
            -0.0002971875946968794,
            0.018081774935126305,
            0.014618610963225365,
            0.0006698266370221972,
            -0.03213070333003998,
            0.026268601417541504,
            -0.006500594317913055,
            0.046850915998220444,
            0.007964057847857475,
            -0.023088326677680016,
            -0.013686118647456169,
            -0.004949650727212429,
            0.027737371623516083,
            0.017379842698574066,
            0.00957710575312376,
            0.04312470927834511,
            -0.0020505483262240887,
            -0.022301461547613144,
            -0.024319279938936234,
            -0.047476597130298615,
            -0.025158418342471123,
            0.03140744939446449,
            -0.030409403145313263,
            -0.018274739384651184,
            0.023745009675621986,
            -0.01373162679374218,
            -0.023432092741131783,
            0.0020415380131453276,
            0.02123643271625042,
            0.010809662751853466,
            0.016863632947206497,
            -0.030896944925189018,
            0.01955472119152546,
            0.003992896992713213,
            0.06934231519699097,
            -0.07626842707395554,
            -0.024320267140865326,
            -0.04587845876812935,
            -0.06390340626239777,
            0.006797469686716795,
            -0.01194704044610262,
            0.008979381993412971,
            -0.07398685067892075,
            -0.035520024597644806,
            0.008088165894150734,
            0.005325019359588623,
            -0.012133641168475151,
            -0.02753794565796852,
            0.033783234655857086,
            0.043325819075107574,
            -0.0074281347915530205,
            -0.007347178179770708,
            0.015355532988905907,
            -0.030100658535957336,
            -0.06192876771092415,
            -0.011560889892280102,
            0.01916671358048916,
            -0.009371096268296242,
            0.007993249222636223,
            -0.015678446739912033,
            0.04431162029504776,
            0.01216911245137453,
            -0.014163991436362267,
            0.02324311062693596,
            0.017358891665935516,
            0.04321027174592018,
            -0.013128471560776234,
            0.003135358216241002,
            -0.016269972547888756,
            -0.0009758839732967317,
            0.03937363252043724,
            0.006904301233589649,
            0.0017882059328258038,
            0.05085961893200874,
            -0.012308834120631218,
            -0.0005783234955742955,
            0.00025582071975804865,
            0.029218878597021103,
            0.02557668648660183,
            0.04547201842069626,
            0.028936054557561874,
            -0.04461687058210373,
            -0.04130559042096138,
            -0.01429972518235445,
            0.023261984810233116,
            0.007184743415564299,
            -0.01706342212855816,
            0.009456450119614601,
            0.03291554003953934,
            0.020920448005199432,
            -0.014890461228787899,
            -0.019008122384548187,
            0.021656539291143417,
            -0.032106418162584305,
            -0.0160414669662714,
            -0.013840137980878353,
            -0.02553788386285305,
            -0.047497376799583435,
            0.036891862750053406,
            0.01169662270694971,
            -0.012939254753291607,
            -0.04374699667096138,
            0.02033872716128826,
            0.016545573249459267,
            0.020223522558808327,
            0.00470957113429904,
            0.01190685573965311,
            0.038386035710573196,
            -0.03316359221935272,
            -0.031103866174817085,
            -0.03636443614959717,
            0.014961068518459797,
            -0.03657863661646843,
            -0.014184023253619671,
            0.02802029810845852,
            0.009751330129802227,
            -0.0542462021112442,
            -0.04329707846045494,
            -0.06318417936563492,
            0.008479628711938858,
            0.0009283710387535393,
            0.010543028824031353,
            -0.018687281757593155,
            -0.0020451752934604883,
            0.008309888653457165,
            -0.012028010562062263,
            -0.0019006612710654736,
            -0.0063207256607711315,
            0.023388320580124855,
            0.0033069795463234186,
            -0.02450980246067047,
            0.019369158893823624,
            -0.03377692028880119,
            -0.0037683285772800446,
            0.03533535450696945,
            0.024924300611019135,
            -0.0007865306688472629,
            0.04132060706615448,
            0.0374186672270298,
            -0.0038217760156840086,
            -0.0074385409243404865,
            -0.02976277470588684,
            0.028868339955806732,
            0.028414975851774216,
            0.004589672666043043,
            0.02831587940454483,
            -0.011602422222495079,
            0.010405503213405609,
            0.016316872090101242,
            -0.04170839115977287,
            -0.01550481840968132,
            -0.013388944789767265,
            0.02502012439072132,
            0.03294628486037254,
            -0.08812356740236282,
            0.020401401445269585,
            0.0008115481468848884,
            0.010766413994133472,
            -0.0018427653703838587,
            0.0013176911743357778,
            0.01746843010187149,
            -0.06863504648208618,
            0.004277074709534645,
            -0.02696428820490837,
            -0.04442918673157692,
            -0.025660861283540726,
            -0.010446389205753803,
            0.019305771216750145,
            0.03972192108631134,
            -0.036976732313632965,
            0.008985764347016811,
            0.0005984871531836689,
            -0.004491948522627354,
            0.013643626123666763,
            -0.04007191210985184,
            -0.007514101453125477,
            -0.03923247754573822,
            -0.020240092650055885,
            0.02657877281308174,
            0.025297483429312706,
            0.07423288375139236,
            0.020741697400808334,
            0.02788441628217697,
            0.014839332550764084,
            -0.0583362951874733,
            0.02869224175810814,
            -0.016466474160552025,
            0.029563212767243385,
            -0.007780659943819046,
            -0.00015816849190741777,
            -0.040423862636089325,
            0.013324320316314697,
            -0.01658296398818493,
            0.013137508183717728,
            0.021617760881781578,
            -0.0174403116106987,
            0.02814670279622078,
            0.011322625912725925,
            0.019207194447517395,
            -0.00843722838908434,
            0.012271659448742867,
            -0.04651645943522453,
            -0.026066111400723457,
            -0.04537642002105713,
            0.019791394472122192,
            -0.007056130561977625,
            -0.01652916707098484,
            0.03164754435420036,
            0.026426561176776886,
            0.009458272717893124,
            -0.016927318647503853,
            -0.0007815227145329118,
            -0.03717086464166641,
            -0.005227538291364908,
            0.0013074898160994053,
            -0.0013030624249950051,
            -0.045350588858127594,
            0.006014720071107149,
            0.015868186950683594,
            -0.04425187036395073,
            0.01918090134859085,
            0.004899139050394297,
            0.013807577081024647,
            0.037984494119882584,
            0.027663597837090492,
            0.002573449397459626,
            0.009921683929860592,
            0.0018028466729447246,
            -0.037639278918504715,
            -0.018980221822857857,
            0.02951349876821041,
            -0.023371724411845207,
            -0.015353741124272346,
            0.02986118197441101,
            -0.0038562510162591934,
            -0.032042182981967926,
            -0.0017651694361120462,
            0.028184685856103897,
            -0.053131572902202606,
            -0.03434833884239197,
            0.007958109490573406,
            0.003616845700889826,
            0.024356406182050705,
            0.04185977950692177,
            -0.005778648890554905,
            -0.0544622540473938,
            0.03768417611718178,
            0.03768391162157059,
            -0.029475368559360504,
            0.003914013504981995,
            -0.003731210483238101,
            -0.011027587577700615,
            -0.006268635392189026,
            -0.032562077045440674,
            -0.0031516312155872583,
            0.022763924673199654,
            0.0285898819565773,
            -0.054281216114759445,
            0.004137672949582338,
            0.06142294034361839,
            0.003633648855611682,
            -0.014590698294341564,
            0.006659988313913345,
            -0.004115359857678413,
            0.017882615327835083,
            0.04004239663481712,
            0.018470481038093567,
            -0.013451891951262951,
            0.026950478553771973,
            -0.010392268188297749,
            -0.03381730243563652,
            -0.03397473320364952,
            -0.0686010792851448,
            0.05396661162376404,
            0.00011028040898963809,
            0.03723176568746567,
            0.03320165351033211,
            0.015577754005789757,
            -0.01085252407938242,
            -0.004979670513421297,
            -0.026671815663576126,
            -0.010861939750611782,
            -0.013001561164855957,
            0.001693099969998002,
            0.023645594716072083,
            -0.01565561257302761,
            0.008687174879014492,
            -0.03673836216330528,
            0.007409801706671715,
            -0.023990990594029427,
            -0.01848478987812996,
            0.03935326635837555,
            -0.02967706508934498,
            0.02658759616315365,
            0.00475348299369216,
            0.03145967051386833,
            -0.031496189534664154,
            0.01694810390472412,
            -0.03235812112689018,
            0.02510436251759529,
            0.012507160194218159
        ],
        [
            -0.026743464171886444,
            0.029644161462783813,
            -0.00504909735172987,
            -0.012682284228503704,
            0.013975162990391254,
            -0.016681840643286705,
            -0.017291003838181496,
            -0.005834278184920549,
            0.035186558961868286,
            0.004655900876969099,
            -0.04381132125854492,
            -0.011922875419259071,
            0.0030650871340185404,
            -0.0011290088295936584,
            0.004090733360499144,
            -0.0355539545416832,
            0.04253639280796051,
            0.024870360270142555,
            -0.024966929107904434,
            0.008792859502136707,
            -0.020899448543787003,
            0.028230100870132446,
            -0.014680009335279465,
            0.00995751190930605,
            -0.026440681889653206,
            -0.02387920208275318,
            0.023256856948137283,
            0.02870412915945053,
            0.0004167503211647272,
            0.028166115283966064,
            0.0010680498089641333,
            -0.0013821320608258247,
            -0.04135305434465408,
            -0.0158020481467247,
            -0.0007335452828556299,
            0.008552582003176212,
            0.00022978988999966532,
            0.0018189692636951804,
            -0.0207944568246603,
            0.0012028180062770844,
            -0.029729221016168594,
            -0.027194401249289513,
            -0.012636414729058743,
            0.009100886061787605,
            0.035825859755277634,
            -0.011705652810633183,
            -0.029126843437552452,
            -0.05489860847592354,
            0.027035491541028023,
            -0.003989230841398239,
            0.030893176794052124,
            0.02545556053519249,
            0.012422189116477966,
            0.00036633361014537513,
            0.04849216714501381,
            0.025519903749227524,
            0.016110440716147423,
            -0.015649205073714256,
            -0.021200858056545258,
            -0.014766942709684372,
            0.029316475614905357,
            -0.013199648819863796,
            -0.02166232466697693,
            0.02411235310137272,
            -0.011138483881950378,
            0.038732659071683884,
            -0.04242918640375137,
            0.018288658931851387,
            -0.022604890167713165,
            -0.0002206296194344759,
            0.009494935162365437,
            0.021329674869775772,
            -0.0323016382753849,
            0.01205932442098856,
            -0.03918134793639183,
            0.044430941343307495,
            0.05085165426135063,
            -0.030433472245931625,
            -0.03506334498524666,
            0.03028297796845436,
            0.006965653970837593,
            0.012715932913124561,
            0.01893613673746586,
            -0.014950930140912533,
            -0.027004612609744072,
            0.008934883400797844,
            -0.009187934920191765,
            0.02710668370127678,
            0.012311059981584549,
            0.009730374440550804,
            0.035947199910879135,
            0.020349426195025444,
            -0.02422936074435711,
            0.00322420964948833,
            -0.014157706871628761,
            -0.02670270949602127,
            0.021471163257956505,
            0.018795406445860863,
            0.0050860303454101086,
            0.007151925005018711,
            0.010302749462425709,
            -0.001487061264924705,
            0.006244224961847067,
            -0.0162773709744215,
            0.008464302867650986,
            0.018397696316242218,
            0.015498822554945946,
            -0.020708849653601646,
            -0.015872560441493988,
            -0.0037714962381869555,
            0.0510106198489666,
            0.005477102939039469,
            0.009363701567053795,
            -0.001805442152544856,
            -0.04165210202336311,
            -0.016285903751850128,
            0.0157722570002079,
            0.023098642006516457,
            -0.01784249022603035,
            -0.01424582302570343,
            -0.009370497427880764,
            0.0010507303522899747,
            -0.02426045574247837,
            0.006992989219725132,
            0.026483019813895226,
            -0.03429815173149109,
            0.01919194683432579,
            0.022024651989340782,
            0.022480422630906105,
            0.011524091474711895,
            -0.008047600276768208,
            -0.05001286417245865,
            0.024746228009462357,
            0.017418714240193367,
            -0.012013666331768036,
            -0.01090854313224554,
            0.03271184489130974,
            0.029977992177009583,
            -0.03370807319879532,
            -0.018719779327511787,
            0.013416686095297337,
            -0.001543935271911323,
            -0.01607324928045273,
            -0.010702204890549183,
            -0.011529694311320782,
            0.02671264111995697,
            -0.03277333453297615,
            0.004828497301787138,
            0.017340565100312233,
            -0.037218719720840454,
            -0.01286666002124548,
            -0.06054007634520531,
            0.028647465631365776,
            -0.06264983117580414,
            0.01084368396550417,
            0.025726761668920517,
            -0.015824690461158752,
            -0.010505013167858124,
            -0.026373999193310738,
            -0.003099599154666066,
            0.007720268331468105,
            0.010746562853455544,
            -0.016734085977077484,
            0.015926264226436615,
            0.0008272666018456221,
            -0.016674650833010674,
            -0.004791954532265663,
            0.039893683046102524,
            -0.003826456144452095,
            -0.0038364247884601355,
            0.006956098135560751,
            -0.02479197084903717,
            -0.024388205260038376,
            0.017419280484318733,
            -0.019820453599095345,
            0.0054393005557358265,
            0.019411157816648483,
            -0.012965702451765537,
            0.01646485924720764,
            0.0067858463153243065,
            -0.021232828497886658,
            -0.013380506075918674,
            -0.04722442105412483,
            0.0016185878776013851,
            -0.009677361696958542,
            -0.01969790644943714,
            -0.019595257937908173,
            -0.0009344490244984627,
            -0.02506534568965435,
            0.002247449941933155,
            -0.028917649760842323,
            0.02966989390552044,
            -0.01146624144166708,
            -0.018309079110622406,
            -0.008075287565588951,
            0.0057940976694226265,
            -0.019865062087774277,
            -0.00740553205832839,
            0.011198499239981174,
            0.004378882236778736,
            0.0070832474157214165,
            0.016051003709435463,
            -0.07768429815769196,
            -0.006391183473169804,
            -0.021587317809462547,
            0.012856995686888695,
            0.0290853139013052,
            0.05416562780737877,
            0.03721296787261963,
            -0.0008108667097985744,
            0.023470858111977577,
            -0.020548226311802864,
            0.011866501532495022,
            0.021000497043132782,
            -0.02520439773797989,
            0.002824030816555023,
            -0.009189974516630173,
            -0.026741541922092438,
            -0.015825610607862473,
            -0.032822251319885254,
            -0.041033633053302765,
            -0.04568956419825554,
            -0.01593225821852684,
            0.011402151547372341,
            -0.016529638320207596,
            -0.03616925701498985,
            -0.025983327999711037,
            0.014583481475710869,
            0.004598062019795179,
            0.004489322658628225,
            0.017530307173728943,
            0.01826103962957859,
            0.016440700739622116,
            0.02229127287864685,
            0.02207821235060692,
            -0.03250342607498169,
            -0.014996256679296494,
            0.03318954259157181,
            -0.004906250163912773,
            -0.006129800342023373,
            -0.012388533912599087,
            -0.02303796261548996,
            0.004384619649499655,
            0.031269561499357224,
            -0.026418516412377357,
            0.010889698751270771,
            -0.008390815928578377,
            0.027585003525018692,
            -0.0085520688444376,
            -0.029188871383666992,
            -0.026056723669171333,
            0.018216349184513092,
            0.04226573929190636,
            -0.013094478286802769,
            0.0028091762214899063,
            -0.012525915168225765,
            -0.021152352914214134,
            -0.006593339145183563,
            0.014466852881014347,
            0.004956419579684734,
            -0.04857037588953972,
            -0.017101837322115898,
            -0.004775849170982838,
            -0.002968056593090296,
            0.03505770117044449,
            -0.005652603227645159,
            0.011198670603334904,
            0.003849111497402191,
            0.0056454637087881565,
            0.028213229030370712,
            0.024341518059372902,
            0.001551153720356524,
            -0.0012242563534528017,
            0.011421386152505875,
            -0.02181493677198887,
            0.016504181548953056,
            -0.00792509876191616,
            0.03173325955867767,
            0.03638114780187607,
            0.010088693350553513,
            0.03682846203446388,
            -0.0049743326380848885,
            -0.02696244604885578,
            -0.004206313285976648,
            0.001743325381539762,
            -0.005029742140322924,
            -0.00075394386658445,
            0.031216276809573174,
            0.011567668989300728,
            -0.012237342074513435,
            -0.0045427498407661915,
            -0.006821977440267801,
            -0.03455600515007973,
            0.03456588834524155,
            -0.015976203605532646,
            0.004031673539429903,
            0.019499992951750755,
            -0.032064758241176605,
            0.04341970756649971,
            -0.022906260564923286,
            0.03432062268257141,
            -0.025358764454722404,
            0.03432497754693031,
            0.029545443132519722,
            -0.0008493906352669001,
            -0.03666749596595764,
            -0.006123118568211794,
            0.012541127391159534,
            0.017055343836545944,
            -0.034916989505290985,
            0.0011445485288277268,
            0.007307739462703466,
            0.01676633395254612,
            -0.015709202736616135,
            0.02996131032705307,
            0.05218043178319931,
            -0.01997050642967224,
            -0.011517885141074657,
            -0.005914316978305578,
            0.031762365251779556,
            -0.006656175944954157,
            -0.014718658290803432,
            0.006039284635335207,
            0.04660753160715103,
            0.012108691036701202,
            0.019136350601911545,
            0.012599305249750614,
            -0.008164082653820515,
            0.016045134514570236,
            0.03226330131292343,
            -0.01909235306084156,
            -0.0204132292419672,
            0.027553526684641838,
            0.012168859131634235,
            0.014990062452852726,
            0.020931700244545937,
            -0.01025876309722662,
            -0.034249208867549896,
            -0.01046495046466589,
            0.017905963584780693,
            0.011299114674329758,
            0.0462607704102993,
            -0.011074261739850044,
            -0.028167830780148506,
            0.024451885372400284,
            -0.05815678462386131,
            0.016719652339816093,
            0.01365212257951498,
            0.016974428668618202,
            -0.03054955042898655,
            -0.022876165807247162,
            -0.04867773875594139,
            0.006691747810691595,
            0.023820726200938225,
            0.006771261338144541,
            0.018723316490650177,
            -0.02947453036904335,
            -0.020830219611525536,
            0.04227818176150322,
            0.011751330457627773,
            0.009768207557499409,
            -0.015140709467232227,
            0.005577225238084793,
            0.025183403864502907,
            -0.031733814626932144,
            0.017629221081733704,
            0.013595920987427235,
            -0.018442194908857346,
            -0.04800030216574669,
            0.0031113007571548223,
            0.015454882755875587,
            0.017375996336340904,
            0.01102740503847599,
            0.0427117757499218,
            0.015484615229070187,
            0.013441555202007294,
            -0.013630160130560398,
            0.03477402776479721,
            0.011343666352331638,
            0.029198868200182915,
            -0.04404064267873764,
            0.02944072149693966,
            -0.010065588168799877,
            -0.0002280944463564083,
            -0.0056206113658845425,
            0.024377234280109406,
            -0.02133776620030403,
            0.04591917246580124,
            0.0038238512352108955,
            0.004547468852251768,
            0.02539598010480404,
            0.03759237006306648,
            -0.016039898619055748,
            0.011257917620241642,
            0.03286759927868843,
            0.023021817207336426,
            0.007282441481947899,
            -0.04392924904823303,
            0.0074860150925815105,
            0.01689581759274006,
            -0.014790507033467293,
            -0.009722414426505566,
            -0.028303923085331917,
            0.033536527305841446,
            0.0018226527608931065,
            0.010229228995740414,
            -0.012615876272320747,
            -0.04755878075957298,
            0.010477735660970211,
            -0.0011705056531354785,
            -0.006899530533701181,
            -0.031361524015665054,
            -0.015952879562973976,
            -0.025836704298853874,
            0.03359111025929451,
            0.015011032111942768,
            -0.03278020769357681,
            0.016213534399867058,
            -0.016551796346902847,
            0.008734936825931072,
            0.0547015517950058,
            -0.010192114859819412,
            -0.04145921766757965,
            -0.012851329520344734,
            -0.0433066263794899,
            0.004342884756624699,
            0.02244705893099308,
            0.0022929480765014887,
            -0.026705436408519745,
            -0.01930268667638302,
            0.022585147991776466,
            0.0032157020177692175,
            0.015237911604344845,
            0.026764295995235443,
            0.0023771380074322224,
            0.008729973807930946,
            0.013001703657209873,
            -0.01575903780758381,
            0.02782256156206131,
            0.0003420307475607842,
            0.010609692893922329,
            -0.012194372713565826,
            -0.009176687337458134,
            0.033122748136520386,
            -0.0013268266338855028,
            0.008711702190339565,
            -0.007913379929959774,
            0.0039859553799033165,
            0.03802080452442169,
            0.03420570120215416,
            0.0008492898778058589,
            0.01887209340929985,
            -0.008870244957506657,
            -0.015982599928975105,
            -0.01371799223124981,
            -0.024791868403553963,
            -0.004647708497941494,
            0.016460319980978966,
            0.031134504824876785,
            0.018619507551193237,
            0.03697599843144417,
            0.0011785044334828854,
            0.02802002988755703,
            -0.011134994216263294,
            -0.00900073442608118,
            0.008179301396012306,
            -0.036960501223802567,
            -0.03614253178238869,
            0.02344467118382454,
            -0.016779113560914993,
            0.006752773653715849,
            -0.011217149905860424,
            -0.013491909950971603,
            0.0190206840634346,
            0.02279208041727543,
            -0.022414512932300568,
            0.029269102960824966,
            0.005399112123996019,
            0.0007736487896181643,
            0.03579762950539589,
            0.008119296282529831,
            0.004880603868514299,
            -0.003828539978712797,
            -0.002370696747675538,
            -0.02395024709403515,
            -0.03187938407063484,
            -0.008093126118183136,
            0.005752477794885635,
            0.0763259306550026,
            -0.01691073551774025,
            0.01409965567290783,
            -0.019256355240941048,
            0.000963953381869942,
            0.008938472718000412,
            0.01202999148517847,
            -0.006242815405130386,
            -0.013754158280789852,
            -0.02633141539990902,
            -0.022302795201539993,
            0.01892484724521637,
            0.017754459753632545,
            -0.027741361409425735,
            0.002323496388271451,
            -0.004164469428360462,
            -0.0016626063734292984,
            -0.005092368461191654,
            -0.0024343025870621204,
            -0.031287264078855515,
            -0.003467361442744732,
            -0.02566586062312126,
            0.027298325672745705,
            -0.0056221699342131615,
            0.02874823659658432,
            0.032038792967796326,
            0.04666200280189514,
            0.007780259940773249,
            -0.01231424231082201,
            0.03415529057383537,
            -0.020543625578284264,
            0.009088659659028053,
            0.016554394736886024,
            0.015359189361333847,
            0.015231248922646046,
            0.023897383362054825,
            0.006893642246723175,
            -0.0036819970700889826,
            0.046623192727565765,
            0.016633247956633568,
            -0.00021409415057860315,
            -0.011356298811733723,
            0.02170545794069767,
            -0.006764325313270092,
            -0.008770759217441082,
            0.026142360642552376,
            -0.02658521570265293,
            0.05404002219438553,
            -0.0070815482176840305,
            0.001468655769713223,
            -0.0020290634129196405,
            -0.00928886141628027,
            0.024355649948120117,
            -0.021204311400651932,
            -0.008573898114264011,
            0.012055173516273499,
            0.014276299625635147,
            -0.031084207817912102,
            -0.019543640315532684,
            0.01734038069844246,
            0.02470686472952366,
            -0.000683043384924531,
            0.020364444702863693,
            -0.024711187928915024,
            -0.0127218933776021,
            0.00619962764903903,
            -0.024162575602531433,
            0.038962047547101974,
            0.022330589592456818,
            0.008287538774311543,
            -0.008620374836027622,
            0.022915774956345558,
            0.021270129829645157,
            -0.029001783579587936,
            -0.0008390935254283249,
            0.0007251248462125659,
            -0.021948562934994698,
            -0.00784236192703247,
            -0.03929214924573898,
            -0.012363170273602009,
            -0.011829917319118977,
            -0.030225863680243492,
            -0.011482245288789272,
            -0.01543551404029131,
            -0.023258300498127937,
            0.029009655117988586,
            -0.03959307447075844,
            0.017200462520122528,
            0.02346058562397957,
            0.014437093399465084,
            0.020905913785099983,
            0.006853380706161261,
            -0.021681325510144234,
            0.019787322729825974,
            0.0005063447169959545,
            -0.012205430306494236,
            0.012785195372998714,
            0.008547659032046795,
            0.008828231133520603,
            -0.022374767810106277,
            0.006815441884100437,
            -0.009522367268800735,
            0.006263066548854113,
            -0.022138414904475212,
            0.006890579126775265,
            -0.023252718150615692,
            0.0407821387052536,
            -0.017418501898646355,
            -0.039043281227350235,
            0.03210151940584183,
            -0.029789309948682785,
            -0.015802793204784393,
            -0.0015120567986741662,
            0.03322485834360123,
            -0.033339973539114,
            -0.009882254526019096,
            0.01956990733742714,
            0.030194757506251335,
            -0.005175060126930475,
            0.025444291532039642,
            0.012521030381321907,
            0.015766257420182228,
            0.012655088678002357,
            -0.03050295077264309,
            -0.014881973154842854,
            0.02918430231511593,
            0.027634067460894585,
            0.03086586855351925,
            -0.010803867131471634,
            -0.0007914045127108693,
            0.012462401762604713,
            -0.015106167644262314,
            -0.0370137020945549,
            -0.014351177960634232,
            0.015442408621311188,
            0.004577049519866705,
            0.035472322255373,
            -0.0023229592479765415,
            -0.01876681298017502,
            -0.013565375469624996,
            0.021879402920603752,
            -0.028689617291092873,
            -0.023122776299715042,
            -0.026656251400709152,
            0.03638523072004318,
            -0.04638782888650894,
            0.00023265958589036018,
            0.024773241952061653,
            -0.006451108492910862,
            -0.023835841566324234,
            -0.021886805072426796,
            0.018646325916051865,
            -0.004019856918603182,
            -0.01599193923175335,
            -0.0005281022749841213,
            -0.0558670349419117,
            -0.03081483207643032,
            -0.0018070521764457226,
            0.0073563288897275925,
            0.016299476847052574,
            -0.028481896966695786,
            -0.022574126720428467,
            -0.004514569416642189,
            0.028444048017263412,
            0.014420115388929844,
            -0.006601911038160324,
            -0.008525442332029343,
            -0.016681240871548653,
            0.009824004955589771,
            -0.0010063189547508955,
            0.00332289794459939,
            -0.03668881952762604,
            0.01835709437727928,
            0.008528545498847961,
            0.008742955513298512,
            -0.018371468409895897,
            0.022676346823573112,
            0.00741885881870985,
            0.010102233849465847,
            0.003607220249250531,
            0.006427509244531393,
            0.0023654561955481768,
            -0.021656030789017677,
            -0.037426698952913284,
            -0.025286484509706497,
            -0.02174410969018936,
            -0.029528340324759483,
            -0.03094116598367691,
            -0.007136174011975527,
            -0.03812042251229286,
            -0.013358138501644135,
            -0.01025733444839716,
            0.011350956745445728,
            0.0004495841858442873,
            -0.010409137234091759,
            0.019953113049268723,
            0.02601829171180725,
            0.003248176071792841,
            0.014905703254044056,
            0.014178345911204815,
            -0.008424730040133,
            0.011466716416180134,
            -0.025545891374349594,
            0.012471693567931652,
            -0.024844767525792122,
            -0.005481802858412266,
            -0.036211032420396805,
            0.009650836698710918,
            -0.019054129719734192,
            -0.013673932291567326,
            -0.01995047926902771,
            -0.009162905625998974,
            -0.019891122356057167,
            -0.025030843913555145,
            -0.011444585397839546,
            0.024259788915514946,
            0.0002708431566134095,
            0.02633286826312542,
            0.014806458726525307,
            0.003398013999685645,
            0.02600761316716671,
            -0.004756501875817776,
            -0.0015368914464488626,
            -0.007871527224779129,
            0.002355339238420129,
            -0.02189563401043415,
            -0.011805303394794464,
            0.026347262784838676,
            0.012470905669033527,
            -0.020705370232462883,
            0.02630501799285412,
            0.021549388766288757,
            0.01993914134800434,
            -0.0026321904733777046,
            -0.0234686266630888,
            0.0009210919379256666,
            0.005719281267374754,
            -0.005702488124370575,
            -0.02775713801383972,
            -0.007639689836651087,
            0.015924211591482162,
            -0.009867810644209385,
            0.0073659177869558334,
            0.024875937029719353,
            0.005018910393118858,
            -0.009365695528686047,
            0.015714654698967934,
            -0.006272622849792242,
            -0.02113492228090763,
            -0.010040434077382088,
            0.015381083823740482,
            0.012619979679584503,
            0.031062889844179153,
            -0.010160095058381557,
            -0.029416538774967194,
            -0.014569567516446114,
            0.00559762679040432,
            0.02945810556411743,
            0.05255195498466492,
            -0.03416575491428375,
            -0.0018211613642051816,
            0.011682615615427494,
            -0.023309217765927315,
            0.02077162265777588,
            0.026373250409960747,
            -0.01514868438243866,
            0.01729957014322281,
            0.004296964965760708,
            -0.0014929950702935457,
            0.05286169424653053,
            -0.0048057809472084045,
            0.008750859647989273,
            0.005928648170083761,
            -0.03997618332505226,
            -0.01984492875635624,
            -0.018076404929161072,
            0.006039149593561888,
            0.012164258398115635,
            -0.02525339275598526,
            0.012604082934558392,
            -0.028343748301267624,
            0.0005139600834809244,
            -0.03583540767431259,
            0.007426803465932608,
            0.0024648599792271852,
            0.011856458149850368,
            -0.03497101739048958,
            -0.05892179161310196,
            0.022185910493135452,
            0.031951479613780975,
            0.0017237609717994928,
            0.010209214873611927,
            0.025303293019533157,
            0.023145997896790504,
            0.0017720229225233197,
            -0.00747870234772563,
            -0.026630332693457603,
            0.036448825150728226,
            -0.004661343060433865,
            0.00040657020872458816,
            0.017446046695113182,
            -0.02475910820066929,
            0.014828893356025219,
            -0.018906136974692345,
            0.0021315929479897022,
            -0.03670942783355713,
            0.006382152903825045,
            0.009162187576293945,
            -0.020704222843050957,
            0.023248711600899696,
            -0.009016407653689384,
            0.008622903376817703,
            0.008860706351697445,
            0.0117119699716568,
            -0.006507990416139364,
            0.004642718005925417,
            -0.022840183228254318,
            0.006412368733435869,
            -0.034814924001693726,
            -0.0016161274397745728,
            0.02913813479244709,
            0.025236928835511208,
            -0.02861635759472847,
            -0.061140596866607666,
            -0.0015045171603560448,
            0.023880140855908394,
            0.03239399939775467,
            -0.006681902799755335,
            -0.008973304182291031,
            -0.039873626083135605,
            -0.0094843665137887,
            -0.005614215042442083,
            0.005435481667518616,
            -0.02043190412223339,
            0.0007061658543534577,
            0.016304515302181244,
            -0.025548627600073814,
            -0.023801451548933983,
            -0.029278909787535667,
            -0.011274370364844799,
            0.015448990277945995,
            -0.012726700864732265,
            0.029803186655044556,
            0.011542530730366707,
            0.004912431817501783,
            0.006142888218164444,
            0.012426288798451424,
            0.013424059376120567,
            0.013674594461917877,
            -0.028440238907933235,
            -0.008425307460129261,
            -0.025308983400464058,
            0.002043722430244088,
            -0.01835119165480137,
            0.015641575679183006,
            -0.034864943474531174,
            -0.019923636689782143,
            0.014864129014313221,
            0.028380069881677628,
            0.003300304990261793,
            -0.01309712789952755,
            -0.02290705218911171,
            0.02538682520389557,
            -0.009110861457884312,
            0.01566103659570217,
            0.03192780539393425,
            0.004782110918313265,
            -0.014968330971896648,
            -0.0013118945062160492,
            0.0028619454242289066,
            0.01682947389781475,
            -0.011655914597213268,
            0.032585158944129944,
            0.034319233149290085,
            -0.03871703892946243,
            0.032769858837127686,
            -0.027186978608369827,
            0.00731654092669487,
            0.006690469570457935,
            -0.008976172655820847,
            0.02198556624352932,
            0.021497974172234535,
            -0.03135913982987404,
            0.011722520925104618,
            0.04140765964984894,
            -0.023229176178574562,
            0.010030616074800491,
            0.010635213926434517,
            0.008503158576786518,
            0.06542764604091644,
            0.01151749026030302,
            -0.026819484308362007,
            -0.026384763419628143,
            -0.0051834252662956715,
            0.010771761648356915,
            -0.010379786603152752,
            0.018957573920488358,
            -0.01061379536986351,
            -0.011150376871228218,
            -0.030372191220521927,
            0.029721548780798912,
            -0.007798978593200445,
            -0.021116120740771294,
            0.0108787277713418,
            -0.009484450332820415,
            -0.0028583582025021315,
            -0.04313851147890091,
            -0.01265653595328331,
            0.02784169465303421,
            0.00924383569508791,
            0.013295665383338928,
            -0.06414652615785599,
            -0.049392469227313995,
            0.03282589837908745,
            0.0020925907883793116,
            -0.004622626584023237,
            -0.02875172533094883,
            -0.006409467663615942,
            0.01008212473243475,
            -0.0010683495784178376,
            0.033839281648397446,
            -0.00945236161351204,
            0.00047678343253210187,
            0.03135409206151962,
            0.0013092691078782082,
            -0.02654312551021576,
            0.004012170247733593,
            0.022394292056560516,
            -0.013792125508189201,
            -0.013383232988417149,
            0.033689308911561966,
            -0.02398444153368473,
            0.032210081815719604,
            0.027606377378106117,
            0.005289990454912186,
            0.007992657832801342,
            -0.019701950252056122,
            -0.010947564616799355,
            -0.02499147318303585,
            0.005827237386256456,
            -0.012978860177099705,
            0.008686789311468601,
            0.029495378956198692,
            -0.008429806679487228,
            -0.000409805856179446,
            0.020671343430876732,
            0.036739327013492584,
            -0.005628414452075958,
            -0.011381182819604874,
            -0.01680375635623932,
            -0.011117213405668736,
            -0.004169256892055273,
            -0.03714001178741455,
            0.0034988070838153362,
            -0.029331866651773453,
            0.028272127732634544,
            0.030484484508633614,
            0.015007196925580502,
            -0.02342582494020462,
            -0.03427700325846672,
            0.018793674185872078,
            -0.03327017277479172,
            0.03154889866709709,
            -0.040264394134283066,
            0.03394073247909546,
            0.01602613367140293,
            0.040355291217565536,
            -0.026632271707057953,
            0.024116262793540955,
            -0.005572683643549681,
            -0.0005742330686189234,
            -0.006670065689831972,
            0.04796614125370979,
            -0.02738993428647518,
            0.04230592027306557,
            0.04297963157296181,
            0.022377213463187218,
            0.02125905081629753,
            0.04014921188354492,
            0.031863022595644,
            0.007591677829623222,
            -0.018184952437877655,
            0.02647893689572811,
            -0.01768305152654648,
            0.029386699199676514,
            -0.00725267268717289,
            0.022243458777666092,
            0.023522771894931793,
            0.015514960512518883,
            0.023016560822725296,
            -0.004660715814679861,
            0.00881344173103571,
            -0.01403121743351221,
            0.012074016965925694,
            -0.02773420140147209,
            -0.010191505774855614,
            -0.01905170828104019,
            -0.02490372210741043,
            0.038779255002737045,
            0.006079130806028843,
            -0.005331220105290413,
            0.020484089851379395,
            0.004751348402351141,
            0.019200602546334267,
            0.03971785306930542,
            -0.030417419970035553,
            0.004426652565598488,
            0.004287950228899717,
            0.05686170607805252,
            0.008378573693335056,
            0.027352524921298027,
            0.014225557446479797,
            -0.011278915219008923,
            0.012825512327253819,
            0.002271450124680996,
            -0.02104053646326065,
            0.039411209523677826,
            -0.021219031885266304,
            -0.022208258509635925,
            0.010087389498949051,
            -0.024659419432282448,
            0.019168922677636147,
            -0.012835058383643627,
            0.012419379316270351,
            0.010484213940799236,
            0.0020450372248888016,
            -0.0632202997803688,
            -0.04522247239947319,
            -0.023182053118944168,
            0.0019158113282173872,
            0.027288084849715233,
            -0.02145371213555336,
            -0.024186257272958755,
            0.029138099402189255,
            0.002269393065944314,
            -0.01601102575659752
        ],
        [
            0.027369145303964615,
            0.019087040796875954,
            0.01952134445309639,
            -0.007920481264591217,
            -0.007533323485404253,
            -0.03377310559153557,
            -0.006159151904284954,
            -0.01691664382815361,
            -0.024850212037563324,
            -0.013808644376695156,
            0.017511485144495964,
            -0.01190499123185873,
            0.017903264611959457,
            -0.04609960317611694,
            -0.04147734493017197,
            -0.021435823291540146,
            -0.012960966676473618,
            0.001507574343122542,
            -0.00841976236552,
            0.02083706669509411,
            0.03329436853528023,
            0.020879533141851425,
            0.02497115731239319,
            0.028891591355204582,
            0.008074811659753323,
            -0.026127072051167488,
            -0.00927827600389719,
            -0.03911484405398369,
            -0.04642385616898537,
            0.015018518082797527,
            -0.024109454825520515,
            -0.025358641520142555,
            0.0023153419606387615,
            0.033017873764038086,
            0.007716747000813484,
            -0.05685466155409813,
            -0.000646835716906935,
            0.020622245967388153,
            0.03560873121023178,
            0.039263591170310974,
            0.04401581361889839,
            -0.006146084051579237,
            0.0046934462152421474,
            -0.010258759371936321,
            -0.01904207654297352,
            0.009993242099881172,
            0.022786861285567284,
            0.002079311292618513,
            -0.030037926509976387,
            0.010250803083181381,
            -0.03507459536194801,
            -0.004786230623722076,
            0.04600217193365097,
            0.006019257940351963,
            -0.03693263977766037,
            -0.014263355173170567,
            -0.024699890986084938,
            0.021894583478569984,
            -0.014195301569998264,
            -0.015022128820419312,
            -0.0407818928360939,
            0.016849245876073837,
            0.034516651183366776,
            0.04033922404050827,
            0.010585526935756207,
            -0.020934518426656723,
            0.027328340336680412,
            -0.004317306447774172,
            0.0019522403599694371,
            0.009896689094603062,
            -0.011016078293323517,
            0.019122414290905,
            0.005183496978133917,
            -0.018202275037765503,
            0.02049821801483631,
            -0.032480012625455856,
            -0.009393051266670227,
            -0.03026430308818817,
            -0.018787244334816933,
            -0.03566141799092293,
            -0.015277397818863392,
            -0.029925229027867317,
            -0.0004388323286548257,
            0.03063739649951458,
            0.022561805322766304,
            -0.006255460437387228,
            0.017441511154174805,
            0.004136853385716677,
            -0.009037753567099571,
            -0.03961164131760597,
            -0.03890753909945488,
            0.02812681719660759,
            0.023586081340909004,
            -0.033020008355379105,
            0.011898855678737164,
            0.021589061245322227,
            -0.036538902670145035,
            -0.042603831738233566,
            0.010221732780337334,
            0.015731044113636017,
            0.006279865745455027,
            -0.008176851086318493,
            -0.026209447532892227,
            -0.017668358981609344,
            0.022855110466480255,
            -0.008770844899117947,
            -0.009226643480360508,
            -0.0005814442411065102,
            -0.012062855064868927,
            0.005660003516823053,
            0.004536930005997419,
            0.005477783270180225,
            -0.01142340898513794,
            0.03018299676477909,
            0.06344065815210342,
            0.011781366541981697,
            0.016071291640400887,
            -0.025838209316134453,
            -0.02448996715247631,
            -0.008518898859620094,
            0.009027750231325626,
            0.011560325510799885,
            -0.0074105337262153625,
            -0.0019190142629668117,
            0.014701752923429012,
            0.04225008189678192,
            -0.04048897325992584,
            0.013724595308303833,
            -0.03625386953353882,
            0.020991668105125427,
            0.022238481789827347,
            0.025332195684313774,
            -0.043025948107242584,
            0.05455348640680313,
            0.007566949352622032,
            -0.029139503836631775,
            -0.002727657090872526,
            -0.03697879984974861,
            0.025990545749664307,
            0.03517547994852066,
            -0.005859518889337778,
            -0.016246099025011063,
            -0.018449857831001282,
            -0.03669930621981621,
            0.02846178226172924,
            -0.02620319463312626,
            0.014169299043715,
            0.016437308862805367,
            0.028565380722284317,
            0.04555656760931015,
            0.0092771602794528,
            0.03570704907178879,
            -0.02969188429415226,
            0.054086968302726746,
            0.02417461760342121,
            0.010957288555800915,
            -0.014033272862434387,
            -0.013449013233184814,
            -0.008638688363134861,
            -0.030621787533164024,
            -0.006006138864904642,
            -0.02058437280356884,
            0.0018229749985039234,
            -0.017141586169600487,
            0.035689037293195724,
            -0.016865406185388565,
            -0.02885511703789234,
            0.0021366383880376816,
            -0.0030396084766834974,
            -0.019817793741822243,
            0.006124221254140139,
            0.003538815537467599,
            0.04470221698284149,
            0.033620212227106094,
            -0.030025241896510124,
            0.011209631338715553,
            -0.005933322012424469,
            -0.010880178771913052,
            -0.04026489704847336,
            -0.001177284517325461,
            0.018479475751519203,
            -0.007857839576900005,
            0.015023083426058292,
            -0.004508000332862139,
            -0.002404827857390046,
            0.008258149027824402,
            0.03512056916952133,
            0.03738207742571831,
            0.00614957045763731,
            0.016390258446335793,
            -0.019958371296525,
            0.043297164142131805,
            -0.016876257956027985,
            -0.004558187909424305,
            0.01906261220574379,
            0.011982107535004616,
            0.02759556844830513,
            -0.03796148672699928,
            -0.0010635636281222105,
            -0.01585260033607483,
            0.02475127764046192,
            -0.017650729045271873,
            0.03198464214801788,
            0.02208292856812477,
            -0.087131068110466,
            -0.031051717698574066,
            -0.03913406282663345,
            -0.0245972890406847,
            -0.02311670407652855,
            -0.013523350469768047,
            -0.0024218596518039703,
            0.04758525267243385,
            -0.04365995526313782,
            -0.03685106709599495,
            0.029771817848086357,
            0.020859630778431892,
            -0.00025457338779233396,
            0.027915239334106445,
            -0.008123473264276981,
            0.007290839217603207,
            0.018315598368644714,
            0.009409314952790737,
            0.008653473109006882,
            0.025574952363967896,
            0.031990427523851395,
            -0.060124218463897705,
            0.018329957500100136,
            -0.03916790708899498,
            -0.02111123874783516,
            -0.028788698837161064,
            -0.0037806376349180937,
            -0.0167906004935503,
            0.03354505077004433,
            0.014752712100744247,
            0.0021049384959042072,
            0.04376310110092163,
            -0.057853423058986664,
            -0.019789744168519974,
            -0.032734520733356476,
            -0.053443629294633865,
            0.03461147099733353,
            0.026041537523269653,
            -0.01064588874578476,
            0.00410911301150918,
            -0.001722232555039227,
            -0.00596373388543725,
            0.03632207214832306,
            0.03538580611348152,
            0.016831334680318832,
            0.016920356079936028,
            0.021370792761445045,
            -0.01939007267355919,
            -0.05815752595663071,
            -0.025181740522384644,
            -0.022554850205779076,
            -0.039719462394714355,
            0.003067662473767996,
            0.03363315016031265,
            -0.06397339701652527,
            0.006456808652728796,
            -0.0020832582376897335,
            -0.04097580164670944,
            -0.0729503482580185,
            -0.01004069484770298,
            -0.06676128506660461,
            -0.04236455634236336,
            -0.005613145884126425,
            0.02428463101387024,
            -0.029007578268647194,
            -0.025179369375109673,
            -0.01976441778242588,
            0.019906584173440933,
            0.034578919410705566,
            0.004248267970979214,
            -0.04107781872153282,
            -0.04987354204058647,
            -0.004622860345989466,
            0.017348332330584526,
            0.028075575828552246,
            0.022559145465493202,
            -0.03206067904829979,
            -0.01139847096055746,
            0.010385608300566673,
            0.01876896433532238,
            -0.006305788643658161,
            -0.004389733541756868,
            -0.026706237345933914,
            0.010172680951654911,
            -0.02693420648574829,
            -0.010470116510987282,
            0.010433289222419262,
            -0.06324904412031174,
            0.02475479617714882,
            0.011224383488297462,
            -0.01950744353234768,
            -0.011132272891700268,
            0.002956654876470566,
            0.05757065862417221,
            -0.0211542509496212,
            0.008653745986521244,
            -0.03366990014910698,
            -0.020650502294301987,
            0.00023311331460718066,
            -0.038743261247873306,
            0.028513336554169655,
            0.05447061359882355,
            0.020202433690428734,
            0.01607714593410492,
            0.015008902177214622,
            0.051368776708841324,
            -0.011761260218918324,
            -0.027481868863105774,
            -0.03889927268028259,
            -0.04014373570680618,
            -0.023482095450162888,
            -0.013259360566735268,
            0.01441774982959032,
            -0.018863627687096596,
            0.00921201054006815,
            0.007570289541035891,
            -0.006143651437014341,
            0.011752292513847351,
            0.03838951513171196,
            -0.026361040771007538,
            0.02239031158387661,
            0.012312456965446472,
            -0.025601034983992577,
            0.02102055773139,
            -0.030466273427009583,
            -0.044234003871679306,
            0.03505057096481323,
            -0.016792479902505875,
            -0.037825196981430054,
            -0.05250230059027672,
            -0.024939920753240585,
            -0.02998913824558258,
            0.023624449968338013,
            0.03141067922115326,
            0.020072342827916145,
            0.019860606640577316,
            0.034297049045562744,
            -0.002836920553818345,
            0.029533717781305313,
            0.019960539415478706,
            -0.01226293109357357,
            0.054738327860832214,
            -0.012530253268778324,
            -0.02402963675558567,
            0.01582041010260582,
            0.017849896103143692,
            -0.015273489989340305,
            0.00045587183558382094,
            -0.035091716796159744,
            0.001484812586568296,
            -0.020956989377737045,
            -0.019147401675581932,
            0.028187036514282227,
            -0.025296704843640327,
            0.040211766958236694,
            0.0011622902238741517,
            0.0047761923633515835,
            -0.004751291591674089,
            0.03749142959713936,
            -0.008531635627150536,
            0.01994866319000721,
            0.03770405054092407,
            -0.06243377923965454,
            -0.034025151282548904,
            0.01839635521173477,
            0.005500870291143656,
            0.01477397046983242,
            0.023640630766749382,
            0.0002593990066088736,
            -0.004529174417257309,
            -0.00032915378687903285,
            -0.03981803357601166,
            -0.021182162687182426,
            -0.010144228115677834,
            0.0505564846098423,
            -0.004567885771393776,
            0.016134703531861305,
            0.0158651415258646,
            -0.022137867286801338,
            0.00810442864894867,
            -0.052190061658620834,
            -0.057045795023441315,
            -0.027635125443339348,
            -0.013783227652311325,
            0.026020236313343048,
            0.03047606721520424,
            -0.024004744365811348,
            -0.009091915562748909,
            -0.02066032961010933,
            0.015062849968671799,
            -0.028077656403183937,
            -0.007245921995490789,
            -0.004033684264868498,
            0.04389911890029907,
            -0.010731548070907593,
            0.0023165473248809576,
            -0.05154285579919815,
            0.031950756907463074,
            -0.0295520331710577,
            -0.024983040988445282,
            -0.025084050372242928,
            0.023273704573512077,
            -0.017809949815273285,
            -0.010378426872193813,
            0.042940106242895126,
            0.0183654073625803,
            -0.01741741970181465,
            -0.01997208781540394,
            0.011598234996199608,
            0.006503396667540073,
            0.008342674933373928,
            -0.0018732192693278193,
            -0.019688544794917107,
            0.02802124246954918,
            0.010562684386968613,
            0.010258598253130913,
            0.042602237313985825,
            0.012786698527634144,
            -0.0008940521511249244,
            -0.017038315534591675,
            -0.039605237543582916,
            -0.017021063715219498,
            -0.030324112623929977,
            -0.0030638903845101595,
            -0.0306974146515131,
            -0.024232961237430573,
            -0.007092947140336037,
            -0.04212294891476631,
            0.025709092617034912,
            0.05236664414405823,
            0.015661928802728653,
            -0.00998843926936388,
            -0.04754729941487312,
            0.035031747072935104,
            -0.05635540559887886,
            0.030811894685029984,
            -0.03316374495625496,
            0.0487622506916523,
            0.03648901730775833,
            -0.05938347801566124,
            -0.0025031217373907566,
            0.045268505811691284,
            0.02096414752304554,
            0.03004460781812668,
            -0.014688580296933651,
            -0.020450400188565254,
            0.01829836145043373,
            0.004222510382533073,
            -0.03804235905408859,
            -0.014182349666953087,
            -0.044990088790655136,
            -0.005862131714820862,
            0.02794307842850685,
            -0.008474751375615597,
            -0.004685655701905489,
            0.022453375160694122,
            -0.02490532025694847,
            -0.0127166248857975,
            -0.009716223925352097,
            0.023569170385599136,
            -0.024986974895000458,
            0.011001383885741234,
            -0.020413167774677277,
            -0.03056519851088524,
            -0.012607181444764137,
            -0.019034620374441147,
            0.002438405528664589,
            0.017604326829314232,
            -0.03166179731488228,
            -0.04579982906579971,
            0.006987389177083969,
            -0.01694498397409916,
            -0.00720254285261035,
            -0.007651663850992918,
            -0.00784962810575962,
            -0.008403955027461052,
            -0.026204455643892288,
            -0.04459221288561821,
            -0.07075910270214081,
            0.03530466929078102,
            0.017458034679293633,
            0.01563923992216587,
            -0.007967538200318813,
            -0.010420897975564003,
            -0.05797957628965378,
            -0.05533474683761597,
            0.011963670141994953,
            -0.01855115406215191,
            0.0029790611006319523,
            -0.06032964214682579,
            0.016218462958931923,
            -0.019206877797842026,
            -0.0018613152205944061,
            -0.019033867865800858,
            0.01386448834091425,
            0.01714336685836315,
            0.008455007337033749,
            0.04390402138233185,
            0.008297677151858807,
            -0.01826360635459423,
            0.027267416939139366,
            -0.012590731494128704,
            0.027929849922657013,
            0.011609619483351707,
            6.355778896249831e-05,
            0.031639665365219116,
            -0.010146833956241608,
            -0.026255931705236435,
            -0.006211899686604738,
            -0.07275068759918213,
            -0.0331265963613987,
            -0.016626931726932526,
            -0.03346604108810425,
            -0.005553937051445246,
            0.030841445550322533,
            0.0003690372104756534,
            -0.004434592090547085,
            0.031471673399209976,
            0.0127033656463027,
            -0.02990134060382843,
            -0.009090633131563663,
            -0.003606520825996995,
            -0.046370916068553925,
            -0.05215063318610191,
            -0.02274189703166485,
            -0.02323426492512226,
            -0.0272211916744709,
            -0.004083662293851376,
            -0.02144492231309414,
            0.03509459272027016,
            -0.024702131748199463,
            -0.013925760984420776,
            0.032876837998628616,
            -0.009356643073260784,
            -0.017439572140574455,
            0.019787147641181946,
            0.0004880523483734578,
            -0.01136026531457901,
            -0.04232244938611984,
            -0.04904237389564514,
            -0.014383790083229542,
            -0.00941583514213562,
            0.03602917492389679,
            -0.050325993448495865,
            0.010071487165987492,
            0.030028972774744034,
            -0.02309749461710453,
            0.021783463656902313,
            -0.027187854051589966,
            0.01702919602394104,
            -0.0510297529399395,
            0.006906758062541485,
            -0.003404285991564393,
            0.007741020061075687,
            0.02971252053976059,
            0.027733396738767624,
            -0.03511076420545578,
            0.029299989342689514,
            -0.01977575570344925,
            0.006912412121891975,
            -0.02407759800553322,
            0.0011420872760936618,
            0.01354742981493473,
            0.009766019880771637,
            0.013110684230923653,
            0.003328358754515648,
            -0.004263753537088633,
            0.002478493144735694,
            -0.04407476633787155,
            -0.00931576918810606,
            0.02735295705497265,
            -0.045605115592479706,
            0.0249169934540987,
            -0.0009777176892384887,
            -0.006866901181638241,
            -0.0006050216616131365,
            -0.039749592542648315,
            -0.013336659409105778,
            0.02590734325349331,
            0.008591332472860813,
            -0.010141728445887566,
            0.023178618401288986,
            0.04491332918405533,
            0.027390873059630394,
            -0.02701275423169136,
            -0.0017629670910537243,
            -0.012047519907355309,
            -0.02824506349861622,
            -0.027106566354632378,
            0.004567222204059362,
            -0.002114725299179554,
            0.0015492946840822697,
            0.0034758220426738262,
            -0.015320620499551296,
            -0.02530656009912491,
            -0.03569337725639343,
            -0.028584342449903488,
            -0.015683216974139214,
            0.0032635019160807133,
            -0.012485905550420284,
            0.005763720255345106,
            -0.03906087949872017,
            0.011925265192985535,
            0.0009844000451266766,
            -0.014529488049447536,
            -0.030390216037631035,
            0.018958212807774544,
            -0.05053501948714256,
            -0.029594244435429573,
            -0.011519805528223515,
            -0.0005965515738353133,
            -0.028678281232714653,
            0.03047778643667698,
            0.034824274480342865,
            -0.022716928273439407,
            -0.028815841302275658,
            0.036337196826934814,
            -0.05120129510760307,
            -0.01526324450969696,
            0.02471911907196045,
            -0.01790226809680462,
            -0.0029800208285450935,
            -0.0247981958091259,
            -0.012293308973312378,
            -0.023493973538279533,
            0.014342566952109337,
            -0.027805089950561523,
            0.002064558444544673,
            0.0006833839579485357,
            0.028061509132385254,
            -0.009976670145988464,
            -0.05474753677845001,
            0.011853686533868313,
            0.0253741554915905,
            -0.005429461132735014,
            -0.004771329928189516,
            -0.00018338588415645063,
            0.006619527470320463,
            -0.016316374763846397,
            0.05295422673225403,
            -0.018446611240506172,
            -0.008045473136007786,
            0.020005609840154648,
            0.02461777813732624,
            -0.020301444455981255,
            0.011845746077597141,
            -0.006801167968660593,
            0.01739252172410488,
            0.0068281469866633415,
            -0.037454135715961456,
            -0.007299493998289108,
            0.015538804233074188,
            0.01854865252971649,
            -0.01546107791364193,
            0.012337619438767433,
            0.0023646343033760786,
            -0.020419316366314888,
            0.023818742483854294,
            0.0029307138174772263,
            0.04916240647435188,
            0.004638581071048975,
            -0.011133775115013123,
            0.022777944803237915,
            0.023199930787086487,
            -0.00038325643981806934,
            0.02207832783460617,
            0.03285693749785423,
            -0.04996420815587044,
            0.0032924760598689318,
            0.04549252241849899,
            0.006191550754010677,
            0.008326672948896885,
            0.030273186042904854,
            0.003859314601868391,
            0.026683026924729347,
            0.021575354039669037,
            0.01628728397190571,
            -0.01434878259897232,
            -0.025137290358543396,
            -0.033902935683727264,
            -0.012006109580397606,
            -0.03358861431479454,
            0.0013163350522518158,
            -0.032453082501888275,
            0.011178623884916306,
            -0.05674010142683983,
            0.004620416089892387,
            -0.03556935861706734,
            0.047784820199012756,
            0.022743284702301025,
            -0.01082353014498949,
            -0.031590212136507034,
            -0.014888104051351547,
            0.01024524588137865,
            -0.005421718582510948,
            -0.02477209083735943,
            0.02077116072177887,
            -0.01449200976639986,
            -0.013622310012578964,
            0.0051395827904343605,
            0.012222480028867722,
            -0.044607874006032944,
            -0.010298079811036587,
            -0.012841244228184223,
            -0.07530402392148972,
            -0.009505264461040497,
            -0.012552822008728981,
            -0.036090604960918427,
            -0.05733565613627434,
            0.01559798326343298,
            0.029796166345477104,
            -0.019714247435331345,
            -0.04851466417312622,
            0.03453637287020683,
            -0.002234414219856262,
            -0.012697366066277027,
            -0.010694967582821846,
            0.010326802730560303,
            -0.00506657874211669,
            -0.0002475748769938946,
            0.004063245840370655,
            0.011689991690218449,
            -0.019188661128282547,
            0.008956842124462128,
            0.016276134178042412,
            0.00431385962292552,
            0.012684623710811138,
            0.018803225830197334,
            -0.04117486625909805,
            0.027837445959448814,
            -0.03954493999481201,
            -0.03509210795164108,
            -0.0038658627308905125,
            -0.020959364250302315,
            0.011912218295037746,
            -0.002191769890487194,
            -0.040054455399513245,
            -0.008423344232141972,
            -0.007723116781562567,
            0.05317151919007301,
            0.00923430547118187,
            -0.04390985146164894,
            -0.04741142690181732,
            0.023657815530896187,
            -0.04145000874996185,
            0.02159307524561882,
            -0.013795225881040096,
            -0.030746592208743095,
            -0.0031712742056697607,
            0.05410240963101387,
            -0.011940950527787209,
            -0.0030217051971703768,
            -0.018025577068328857,
            0.0014932459453120828,
            -0.02550446055829525,
            -0.01601955108344555,
            0.03744123503565788,
            -0.047779083251953125,
            -0.00732136145234108,
            -0.04502299427986145,
            0.0371575653553009,
            -0.033271048218011856,
            -0.00937242154031992,
            0.02466374821960926,
            0.00746701005846262,
            0.011415362358093262,
            0.01858181320130825,
            -0.06608757376670837,
            0.06212976202368736,
            -0.04150059074163437,
            0.014554032124578953,
            0.02101697027683258,
            -0.0032477478962391615,
            0.008423871360719204,
            -0.003972701262682676,
            0.02182258479297161,
            -0.03992282226681709,
            0.020816076546907425,
            0.00602687569335103,
            0.006820091512054205,
            -0.002637222409248352,
            0.005522719118744135,
            0.003926877863705158,
            -0.01382889412343502,
            0.011723438277840614,
            -0.03576205298304558,
            0.035747576504945755,
            0.002829235279932618,
            0.045477334409952164,
            -0.010306340642273426,
            0.000617542362306267,
            -0.021192392334342003,
            -0.024225903674960136,
            0.01681877300143242,
            -0.023421093821525574,
            -0.01174404751509428,
            -0.0019689693581312895,
            0.050324946641922,
            -0.033775780349969864,
            0.020505905151367188,
            -0.026937905699014664,
            -0.054965801537036896,
            0.03883880004286766,
            0.02927909977734089,
            0.004211275838315487,
            -0.024541474878787994,
            0.01664886064827442,
            -0.005331043154001236,
            0.000781407521571964,
            -0.009889093227684498,
            -0.05643639713525772,
            -0.013297791592776775,
            0.029998227953910828,
            0.007674281485378742,
            -0.016755174845457077,
            -0.013475942425429821,
            0.014304077252745628,
            0.014584941789507866,
            0.0005843373364768922,
            -0.026312507688999176,
            -0.009487367235124111,
            0.015555336140096188,
            -0.032800182700157166,
            0.005684667732566595,
            -0.04662307724356651,
            0.005505878012627363,
            0.04124288633465767,
            -0.0025193952023983,
            -0.018043948337435722,
            -0.0057814582251012325,
            -0.019016699865460396,
            -0.01650862768292427,
            -0.020699625834822655,
            0.04463418573141098,
            -0.018643036484718323,
            0.027534423395991325,
            0.015142073854804039,
            -0.005221743602305651,
            0.015627101063728333,
            0.03324057534337044,
            -0.02558133378624916,
            -0.005205899942666292,
            -0.025556061416864395,
            -0.009827838279306889,
            -0.016254747286438942,
            0.03596073389053345,
            -0.009136456996202469,
            -0.0005395814660005271,
            0.042807403951883316,
            -0.02604028582572937,
            -0.02366992086172104,
            -0.007428428623825312,
            -0.03827014937996864,
            0.017851708456873894,
            -0.011109359562397003,
            0.0003688346187118441,
            0.0014471011236310005,
            0.009480703622102737,
            0.03335070237517357,
            -0.016270466148853302,
            0.031512580811977386,
            0.019071070477366447,
            0.032547496259212494,
            -0.02040492556989193,
            0.027898136526346207,
            -0.024594556540250778,
            -0.0003167312825098634,
            -0.007852336391806602,
            -0.05024544894695282,
            0.010189835913479328,
            0.03360837697982788,
            0.009234599769115448,
            0.005850704852491617,
            -0.019115328788757324,
            -0.04000307247042656,
            0.006991396192461252,
            0.01845025084912777,
            0.005070749204605818,
            -0.01862436719238758,
            0.014077381230890751,
            0.0268652755767107,
            -0.01733064092695713,
            0.009868171997368336,
            0.018105555325746536,
            0.018245043233036995,
            0.0028673771303147078,
            0.0081119230017066,
            0.016472019255161285,
            0.03552430123090744,
            -0.013120490126311779,
            0.00041496724588796496,
            0.010289677418768406,
            0.023164328187704086,
            -0.08337754011154175,
            0.03501322120428085,
            0.044858064502477646,
            0.056532129645347595,
            -0.01328274980187416,
            0.020206261426210403,
            0.006157577037811279,
            -0.005915445275604725,
            0.0456971600651741,
            0.0014227324863895774,
            -0.00419203843921423,
            -0.01663217693567276,
            0.019307658076286316,
            -0.01265120692551136,
            -0.01845964603126049,
            0.03795936703681946,
            -0.06771869212388992,
            0.008534173481166363,
            -0.024684900417923927,
            -0.01386773120611906,
            -0.051030587404966354,
            0.00513274734839797,
            -0.02168416790664196,
            0.04145212471485138,
            0.025548452511429787,
            -0.041298385709524155,
            0.010846088640391827,
            -0.03807041794061661,
            -0.01707599125802517,
            -0.00901614036411047,
            0.009755817241966724,
            0.024424677714705467,
            -0.030885323882102966,
            0.012857286259531975,
            -0.02470853552222252,
            0.013529790565371513,
            0.02300013042986393,
            -0.0372343473136425,
            0.033725421875715256,
            -0.007598203141242266,
            -0.014245334081351757,
            0.016556015238165855,
            0.02151215635240078,
            -0.017866069450974464,
            0.009525160305202007,
            -0.020442631095647812,
            0.005968628916889429,
            -0.0019478724570944905,
            0.022178910672664642,
            0.010289966128766537,
            0.009527667425572872,
            -0.02512449584901333,
            0.0213654525578022,
            -0.049177683889865875,
            -0.017825836315751076,
            0.000782814051490277,
            0.002508982317522168,
            0.022565040737390518,
            0.009703774936497211,
            0.01818137615919113,
            0.03635852783918381,
            -0.03324182331562042,
            -0.02324872836470604,
            -0.03417368233203888,
            0.04589572921395302,
            -0.028205860406160355,
            -0.007648170925676823,
            -0.009495147503912449,
            0.024859337136149406,
            -0.012415386736392975,
            -0.01760033145546913,
            0.03634919226169586,
            0.004521744325757027,
            0.002373207826167345,
            0.021896883845329285,
            0.014365430921316147,
            -0.007008504122495651,
            0.00379977747797966,
            0.00400112010538578,
            -0.045056454837322235,
            -0.020823847502470016,
            -0.022951819002628326,
            -0.008736543357372284,
            0.021566571667790413,
            0.01566595397889614,
            0.04365210235118866,
            -0.011090738698840141,
            0.010531345382332802,
            0.013186316005885601,
            0.013928084634244442,
            0.002421328565105796,
            0.02026122622191906,
            -0.001076276763342321,
            -0.05416242405772209,
            -0.040448594838380814,
            -0.047667499631643295,
            0.02933538891375065,
            0.013701172545552254,
            -0.025518689304590225,
            -0.04762805998325348,
            -0.050393279641866684,
            0.03078603558242321,
            -0.05148852989077568,
            0.01628463715314865,
            -0.03386685252189636,
            0.03449355438351631,
            -0.01480013970285654,
            -0.03404818847775459,
            0.031967893242836,
            0.03050922602415085,
            0.06015290319919586,
            -0.0060270256362855434,
            -0.029129071161150932,
            0.010859842412173748,
            -0.03415738046169281,
            0.011601418256759644,
            -0.0009797306265681982,
            0.009562590159475803,
            -0.017144981771707535,
            -0.04134145379066467,
            -0.027123108506202698,
            -0.008183743804693222,
            0.0333249606192112,
            0.05526404082775116,
            -0.026289544999599457,
            0.040883779525756836,
            -0.01856508105993271
        ],
        [
            -0.0008172914967872202,
            0.0025618188083171844,
            0.008116262964904308,
            6.653550371993333e-05,
            -0.021282903850078583,
            0.0004888689727522433,
            0.007958529517054558,
            -0.02704682946205139,
            0.025595437735319138,
            -0.003619083669036627,
            0.003298376454040408,
            -0.004392608534544706,
            -0.010996882803738117,
            0.04828198626637459,
            -0.011424597352743149,
            0.030411191284656525,
            0.030303554609417915,
            -0.015472532249987125,
            -0.017459196969866753,
            -0.016863932833075523,
            -0.0090436777099967,
            0.02682626247406006,
            -0.0283003281801939,
            0.02644776552915573,
            -0.04395133629441261,
            -0.023649606853723526,
            -0.0411405935883522,
            -0.02594868279993534,
            -0.013938110321760178,
            0.02916608564555645,
            -0.01039937511086464,
            0.017987774685025215,
            0.027010072022676468,
            0.006559291388839483,
            0.01927993632853031,
            -0.013421348296105862,
            0.028566524386405945,
            0.026425639167428017,
            0.0383511446416378,
            -0.03733852133154869,
            -0.016392983496189117,
            -0.014319640584290028,
            0.04460909962654114,
            -0.014062626287341118,
            0.00035277349525131285,
            -0.028018342331051826,
            0.0008850960875861347,
            -0.006287471856921911,
            -0.003794965101405978,
            -0.035046059638261795,
            -0.010402101092040539,
            -0.00919269397854805,
            0.039037905633449554,
            0.031653519719839096,
            -0.00800392683595419,
            -0.0025048237293958664,
            0.0015598209574818611,
            0.02888084203004837,
            0.01641000621020794,
            0.008680990897119045,
            -0.02009689062833786,
            0.0010120128281414509,
            0.03678353503346443,
            0.03079240396618843,
            0.02179674245417118,
            0.014648585580289364,
            0.04028062894940376,
            0.028996380046010017,
            -0.019580597057938576,
            -0.0006574420258402824,
            -0.02738151326775551,
            0.03025735542178154,
            0.00916134100407362,
            -0.013118002563714981,
            0.07714234292507172,
            0.004527832381427288,
            -0.04573511332273483,
            0.018595591187477112,
            0.03923238813877106,
            0.04437018930912018,
            0.020934389904141426,
            0.01727871410548687,
            -0.014159849844872952,
            0.025155626237392426,
            0.007189151830971241,
            0.016163058578968048,
            0.0026509857270866632,
            -0.05199376493692398,
            0.026653969660401344,
            -0.005204095505177975,
            -0.023083627223968506,
            -0.006208499893546104,
            0.03168153762817383,
            -0.00083689927123487,
            0.0033066123723983765,
            -0.02939942479133606,
            0.00405590096488595,
            -0.008044012822210789,
            -0.07590508460998535,
            -0.03383025899529457,
            -0.011459168046712875,
            -0.04079049080610275,
            -0.01917673461139202,
            0.033323902636766434,
            -0.005133353173732758,
            -0.016750643029808998,
            0.013850655406713486,
            0.005411794409155846,
            0.06095165014266968,
            0.012516570277512074,
            -0.057736270129680634,
            0.004103794693946838,
            -0.02402958832681179,
            0.00656497897580266,
            -0.02017633616924286,
            -0.006498909089714289,
            -0.001646737102419138,
            0.021504554897546768,
            0.01409839652478695,
            0.02045370824635029,
            -0.021649381145834923,
            -0.023515047505497932,
            0.03045782819390297,
            -0.013199342414736748,
            0.022526713088154793,
            0.042577460408210754,
            0.032937467098236084,
            -0.013480749912559986,
            0.015180283226072788,
            -0.0003153718134853989,
            -0.012416374869644642,
            -0.027902092784643173,
            -0.009335055015981197,
            0.010813720524311066,
            0.02828741818666458,
            -0.0025243605487048626,
            -0.003851956455036998,
            0.01653898134827614,
            -0.005923187360167503,
            0.02626011334359646,
            0.02924656867980957,
            0.032818447798490524,
            0.051902711391448975,
            -0.01713690534234047,
            -0.001721126027405262,
            0.011767597869038582,
            0.015542613342404366,
            0.0021136989817023277,
            0.030446337535977364,
            -0.005046649370342493,
            -0.04504616931080818,
            -0.0409187451004982,
            -0.04326947033405304,
            -0.051414068788290024,
            0.011607993394136429,
            0.020592795684933662,
            0.04450727999210358,
            0.022186169400811195,
            0.009484180249273777,
            -0.006405854597687721,
            0.021292854100465775,
            -0.00429183105006814,
            0.014359107241034508,
            -0.020780248567461967,
            0.03665830194950104,
            -0.011541582643985748,
            -0.031037267297506332,
            0.032002754509449005,
            0.0049179368652403355,
            -0.027657685801386833,
            0.00968586839735508,
            -0.01643492467701435,
            0.04608619213104248,
            -0.0020069226156920195,
            0.028424231335520744,
            0.010766792111098766,
            -0.019006380811333656,
            -0.008665257133543491,
            -0.0010269057238474488,
            -0.008201508782804012,
            0.03199608623981476,
            0.008783170953392982,
            -0.01765447109937668,
            -0.0014902163529768586,
            -0.02074103243649006,
            0.010639533400535583,
            -0.047125499695539474,
            -0.028175050392746925,
            0.022293996065855026,
            -0.022698944434523582,
            -0.008272416889667511,
            -0.022106312215328217,
            0.08308346569538116,
            -0.012575507164001465,
            0.02928762324154377,
            -0.0031015516724437475,
            -0.010186085477471352,
            -0.03284380957484245,
            -0.01273354236036539,
            0.008259681984782219,
            0.024201631546020508,
            0.02010985091328621,
            0.0187991913408041,
            -0.028745761141180992,
            -0.11579029262065887,
            0.011520191095769405,
            0.031119171530008316,
            -0.024296961724758148,
            -0.03410130366683006,
            0.004408190026879311,
            -0.01814984902739525,
            -0.026252325624227524,
            0.002106372034177184,
            0.019962284713983536,
            -0.032438065856695175,
            -0.005630486644804478,
            0.03145357221364975,
            -0.003973941318690777,
            -0.02776588499546051,
            0.004915391560643911,
            -0.0265551395714283,
            -0.03284651041030884,
            0.01881011202931404,
            0.02819770947098732,
            0.019434399902820587,
            0.06041814759373665,
            0.03834746405482292,
            -0.022427354007959366,
            -0.010969833470880985,
            -0.0219398383051157,
            0.01660105772316456,
            0.015384276397526264,
            0.02604619599878788,
            0.0033179037272930145,
            0.007264771498739719,
            0.006367813795804977,
            0.012859402224421501,
            -0.00293579138815403,
            -0.019195828586816788,
            0.005982053466141224,
            -0.018010945990681648,
            -0.007714264094829559,
            0.02865496464073658,
            0.014075592160224915,
            0.0046523138880729675,
            -0.030044130980968475,
            -0.03680059313774109,
            0.004923878237605095,
            -0.004814803600311279,
            4.891912976745516e-05,
            0.004803578369319439,
            -0.007473938167095184,
            0.04194200411438942,
            -0.008482439443469048,
            -0.001235668663866818,
            -0.02155836671590805,
            -0.005364871118217707,
            0.008951216004788876,
            -0.008890705183148384,
            0.049442876130342484,
            0.011350670829415321,
            0.024270638823509216,
            -0.04807908087968826,
            -0.03258529677987099,
            0.04011482745409012,
            -0.020158804953098297,
            -0.00911586545407772,
            -0.00844962801784277,
            0.023385386914014816,
            0.01058102585375309,
            0.0007863927166908979,
            0.028389805927872658,
            0.026623735204339027,
            -0.018974028527736664,
            0.004541135858744383,
            -0.03692213445901871,
            -0.03173751011490822,
            -0.022190405055880547,
            -0.03461744636297226,
            -0.01523877028375864,
            0.02434946410357952,
            0.01606181636452675,
            -0.02937810681760311,
            0.0035934667102992535,
            -0.031892307102680206,
            -0.021323835477232933,
            0.02652985043823719,
            -0.006392602808773518,
            0.01457238383591175,
            -0.039339855313301086,
            0.019816407933831215,
            0.01050373911857605,
            0.0007583728875033557,
            -0.019424544647336006,
            -0.0032926902640610933,
            -0.04151755943894386,
            0.030989132821559906,
            0.03293812274932861,
            0.0050902473740279675,
            0.0027853171341121197,
            0.022972142323851585,
            -0.027876341715455055,
            0.009368729777634144,
            0.0037215042393654585,
            0.004137962590903044,
            -0.021510079503059387,
            0.04796977713704109,
            -0.0018276965711265802,
            -0.0062696803361177444,
            0.0251787006855011,
            0.0076275784522295,
            -0.009879919700324535,
            0.00957494881004095,
            0.015376084484159946,
            -0.024014916270971298,
            0.04385100305080414,
            0.023405110463500023,
            -0.0065712695941329,
            -0.0015704635297879577,
            0.017444610595703125,
            -0.015471302904188633,
            -0.014284217730164528,
            0.011909530498087406,
            -0.015909450128674507,
            -0.01194386649876833,
            0.03072248212993145,
            0.003001556731760502,
            -0.0072926408611238,
            -0.04107308387756348,
            -0.02961079031229019,
            -0.012326743453741074,
            -0.008706594817340374,
            -0.0075319791212677956,
            -0.015194136649370193,
            0.023510443046689034,
            0.020585069432854652,
            -0.024834981188178062,
            0.0013179725501686335,
            -0.008192350156605244,
            -0.03073102980852127,
            0.03718847781419754,
            0.043273426592350006,
            0.03967290744185448,
            0.01343691535294056,
            -0.016881095245480537,
            -0.023568497970700264,
            -0.028504330664873123,
            0.01527186669409275,
            -0.010082949884235859,
            -0.0008910460746847093,
            0.027736760675907135,
            0.002727371407672763,
            -0.0034027276560664177,
            0.0028012660332024097,
            -0.015699442476034164,
            0.01973174884915352,
            0.029198942705988884,
            -0.04532206058502197,
            -0.021492881700396538,
            -0.01654290035367012,
            -0.003603866556659341,
            -0.0043092211708426476,
            0.006158897187560797,
            -0.02264714241027832,
            -0.011316148564219475,
            -0.03456424921751022,
            -0.01534350961446762,
            -0.0278838612139225,
            0.005496186204254627,
            -0.013738501816987991,
            0.0006551034166477621,
            -0.021917156875133514,
            -0.0005674452404491603,
            0.04100313037633896,
            -0.027050411328673363,
            0.003664438147097826,
            0.009637824259698391,
            -0.031029287725687027,
            0.00914650596678257,
            -0.007188072428107262,
            -0.016255034133791924,
            0.027842307463288307,
            -0.011544118635356426,
            -0.010965597815811634,
            -0.004202444106340408,
            0.043607644736766815,
            0.03069617785513401,
            -0.007864902727305889,
            -0.05808467045426369,
            0.012629086151719093,
            0.005548990797251463,
            -0.012099111452698708,
            0.02573922835290432,
            0.026946784928441048,
            -0.06925390660762787,
            0.019644470885396004,
            -0.02576521784067154,
            0.02330075576901436,
            0.02471877448260784,
            -0.019624244421720505,
            0.0410013273358345,
            0.024609621614217758,
            -0.01387043111026287,
            0.02508481964468956,
            0.021579710766673088,
            0.01649441197514534,
            -0.021948127076029778,
            -0.032148271799087524,
            0.01895369030535221,
            0.02137400209903717,
            -0.0038205464370548725,
            0.01637430675327778,
            -0.008743924088776112,
            0.01795751228928566,
            0.033992454409599304,
            -0.005636983551084995,
            -0.02029450051486492,
            -0.0665232464671135,
            0.0033478836994618177,
            0.02035910077393055,
            -0.023898936808109283,
            -0.007236096542328596,
            -0.0013501743087545037,
            -0.01228155568242073,
            -0.013013672083616257,
            0.06817453354597092,
            0.002869644667953253,
            0.012888009659945965,
            0.009743642061948776,
            0.025098200887441635,
            -0.024953870102763176,
            0.025091540068387985,
            -0.005376327782869339,
            -0.014068405143916607,
            0.009629727341234684,
            -0.04127832502126694,
            -0.005980598274618387,
            0.011366727761924267,
            0.006434401962906122,
            0.005587523337453604,
            0.014679597690701485,
            -0.03799733519554138,
            -0.013214861042797565,
            0.046022187918424606,
            0.009626039303839207,
            0.005462934263050556,
            0.025758713483810425,
            -0.05524081364274025,
            -0.005301725119352341,
            0.02736014500260353,
            -0.029545294120907784,
            -0.014001085422933102,
            -0.008424661122262478,
            0.012813874520361423,
            -0.01153895165771246,
            0.032715097069740295,
            -0.018585914745926857,
            -0.004861210007220507,
            -0.009889337234199047,
            -0.022677281871438026,
            0.041992757469415665,
            -0.03469257801771164,
            -0.01671721041202545,
            -0.005881403107196093,
            -0.008593926206231117,
            -0.041849978268146515,
            -0.03614149987697601,
            -0.022450266405940056,
            -0.01686561107635498,
            0.03525722399353981,
            0.0035758463200181723,
            -0.03184479475021362,
            -0.04039865359663963,
            0.04842520132660866,
            -0.02224423736333847,
            0.005703271832317114,
            0.011360920034348965,
            0.00013928543194197118,
            0.019738197326660156,
            0.017413992434740067,
            -0.008097060024738312,
            -0.004081045277416706,
            0.04839165508747101,
            0.0016930849524214864,
            0.00555557943880558,
            0.026636211201548576,
            -0.032294608652591705,
            0.001195280347019434,
            0.07687286287546158,
            0.004562910180538893,
            0.0007831775001250207,
            -0.01303279772400856,
            -0.0056098951026797295,
            0.00862888339906931,
            -0.002387766493484378,
            -0.00019547872943803668,
            -0.012130877934396267,
            0.015843931585550308,
            0.020484501495957375,
            -0.014286870136857033,
            0.02320493757724762,
            -0.007885334081947803,
            -0.021897923201322556,
            0.03233065456151962,
            0.024503638967871666,
            0.011539879254996777,
            -0.017527185380458832,
            -0.004667667672038078,
            -0.017745908349752426,
            0.02819022722542286,
            -0.015113361179828644,
            0.042781684547662735,
            0.009916575625538826,
            -0.027553027495741844,
            -0.0035249246284365654,
            0.009883392602205276,
            0.008013618178665638,
            0.03952755033969879,
            0.006412792485207319,
            -0.005327051505446434,
            -0.005857966374605894,
            0.015706835314631462,
            0.005907691083848476,
            0.02018953673541546,
            0.01674777828156948,
            0.01339360699057579,
            -0.02314917743206024,
            -0.016831204295158386,
            -0.022981993854045868,
            -0.003913360182195902,
            0.02809782139956951,
            -0.05188731104135513,
            -0.01592344604432583,
            0.026955872774124146,
            0.015189504250884056,
            -0.01706787943840027,
            -0.017146162688732147,
            -0.03149070218205452,
            0.003454789752140641,
            -0.005629895720630884,
            -0.0034962890204042196,
            0.011366251856088638,
            -0.028920963406562805,
            0.00580568565055728,
            -0.00021898657723795623,
            -0.038968317210674286,
            -0.010357563383877277,
            0.07929186522960663,
            -0.0018281488446518779,
            0.018369223922491074,
            0.011710821650922298,
            0.03591836988925934,
            -0.011519855819642544,
            -0.01148870587348938,
            -0.004673419985920191,
            -0.0038730590604245663,
            -0.010603073984384537,
            0.021140843629837036,
            0.008964229375123978,
            0.00779034523293376,
            0.006458197254687548,
            0.014477134682238102,
            0.0059561412781476974,
            0.021154271438717842,
            0.020626887679100037,
            0.018218642100691795,
            0.01964137889444828,
            -0.018508650362491608,
            -0.010156059637665749,
            -0.011333031579852104,
            -0.01128560770303011,
            0.00013410774408839643,
            -0.014879155904054642,
            -0.012391413561999798,
            -0.05035132169723511,
            -0.022528238594532013,
            0.013532565906643867,
            0.028584841638803482,
            0.036520227789878845,
            0.027746297419071198,
            -0.003328744089230895,
            -0.010421782732009888,
            0.022945042699575424,
            0.02719036303460598,
            -0.04187994822859764,
            0.022997532039880753,
            0.012061857618391514,
            0.011854618787765503,
            0.009138815104961395,
            -0.03172380477190018,
            0.01765725389122963,
            0.02319532446563244,
            -0.0012624571099877357,
            -0.029729517176747322,
            0.02042200416326523,
            -0.005697478540241718,
            0.0061995829455554485,
            0.0022782590240240097,
            0.029791785404086113,
            0.006370251998305321,
            0.02905040979385376,
            0.005988110788166523,
            0.009885935112833977,
            0.004604962654411793,
            -0.005285246297717094,
            -0.035732921212911606,
            -0.03621058538556099,
            0.0020643684547394514,
            -0.03340009227395058,
            -0.043106772005558014,
            -0.01669090986251831,
            0.02395527996122837,
            0.018436335027217865,
            -0.01974293775856495,
            -0.027253488078713417,
            0.04159676283597946,
            -0.00915310624986887,
            0.016365356743335724,
            0.021879153326153755,
            -0.016464611515402794,
            0.00255919829942286,
            -0.006638858933001757,
            0.012573469430208206,
            0.0002700446348171681,
            -0.022502675652503967,
            -0.012743290513753891,
            -0.008561906404793262,
            -0.00333846895955503,
            -0.02810799516737461,
            0.02597055397927761,
            0.020787397399544716,
            0.023991359397768974,
            -0.018002191558480263,
            0.031800832599401474,
            0.00897358451038599,
            -0.00989910401403904,
            -0.0027780288364738226,
            -0.008200411684811115,
            -0.021058479323983192,
            -0.01804162561893463,
            -0.022403359413146973,
            0.04108145833015442,
            -0.030651770532131195,
            0.02807020954787731,
            0.034088946878910065,
            -0.029294904321432114,
            0.05894084647297859,
            0.018289294093847275,
            -0.007125616539269686,
            0.006323040928691626,
            0.02827422320842743,
            0.004737190902233124,
            -0.01876392401754856,
            -0.013996477238833904,
            -0.00166116573382169,
            0.0026781342457979918,
            0.008739335462450981,
            0.01950685679912567,
            0.018841102719306946,
            0.006279831752181053,
            0.020312301814556122,
            0.018696386367082596,
            -0.01661011576652527,
            -0.02142883837223053,
            0.014214927330613136,
            0.021076742559671402,
            0.030155781656503677,
            0.01890203356742859,
            0.010688736103475094,
            -0.005925856065005064,
            -0.03789914771914482,
            0.05040464922785759,
            0.007599673233926296,
            -0.010220997035503387,
            0.011057642288506031,
            -0.011178970336914062,
            -0.01671239174902439,
            0.037514444440603256,
            0.032841190695762634,
            0.019247991964221,
            -0.03438688814640045,
            -0.035133589059114456,
            0.010701838880777359,
            0.008561513386666775,
            -0.028067931532859802,
            0.014240557327866554,
            -0.008766827173531055,
            0.012224366888403893,
            0.040687937289476395,
            0.0039209481328725815,
            -0.01999732106924057,
            -0.0023325153160840273,
            0.0018656406318768859,
            -0.006565146613866091,
            0.03242369741201401,
            0.036390118300914764,
            0.007378765847533941,
            -0.005782065447419882,
            -0.024541039019823074,
            0.012211371213197708,
            0.0071504926308989525,
            0.013302083127200603,
            -0.01218755729496479,
            0.023590493947267532,
            0.0021832820493727922,
            -0.02885564975440502,
            0.016679223626852036,
            0.013569808565080166,
            0.025367584079504013,
            -0.00483517674729228,
            0.023310039192438126,
            -0.02099824883043766,
            0.004463718738406897,
            -0.02981424704194069,
            0.03414363041520119,
            -0.010398784652352333,
            -0.07056975364685059,
            0.009537429548799992,
            0.0027729501016438007,
            -0.013480008579790592,
            0.0010535875335335732,
            -0.028299883008003235,
            -0.016483835875988007,
            0.007772271055728197,
            0.008443405851721764,
            0.021227821707725525,
            0.010828759521245956,
            -0.012539613991975784,
            -0.03343648090958595,
            -0.01389055885374546,
            0.03206145018339157,
            0.018616020679473877,
            0.04042118415236473,
            -0.00022258056560531259,
            0.016739550977945328,
            0.019659699872136116,
            0.015005787834525108,
            0.012497242540121078,
            -0.04020079970359802,
            0.007793863769620657,
            -0.02159350924193859,
            -0.018198207020759583,
            -0.024611670523881912,
            -0.001761582214385271,
            0.02062511444091797,
            -0.04441611468791962,
            0.02429102547466755,
            0.006713581271469593,
            0.03098475933074951,
            -0.02649218589067459,
            0.00955776497721672,
            -0.02222711592912674,
            0.03215641900897026,
            -0.012274269014596939,
            -0.025548303499817848,
            -0.01129225455224514,
            -0.01787528768181801,
            -0.04401044175028801,
            0.028490183874964714,
            -0.007596043404191732,
            0.03197656199336052,
            -0.05205171927809715,
            -0.004557856358587742,
            0.020315807312726974,
            0.01989118382334709,
            -0.00484585203230381,
            -0.03147735074162483,
            0.013878975994884968,
            -0.008083799853920937,
            -0.003443956607952714,
            0.009570601396262646,
            -0.0016295650275424123,
            0.0006932674441486597,
            0.001429751398973167,
            0.00017145948368124664,
            -0.013589105568826199,
            0.024830687791109085,
            0.0012290248414501548,
            -0.02804172970354557,
            -0.013111169449985027,
            -0.0014543974539265037,
            0.014702602289617062,
            -0.026638509705662727,
            0.02598675899207592,
            -0.006123840343207121,
            -0.027982724830508232,
            0.009056405164301395,
            -0.010687755420804024,
            -0.003679857589304447,
            0.03259202465415001,
            0.0031841937452554703,
            0.020908992737531662,
            0.03562478721141815,
            -0.015909425914287567,
            -0.059422463178634644,
            -0.008173719979822636,
            -0.007732318248599768,
            -0.0010920643107965589,
            -0.030510833486914635,
            0.04964831471443176,
            0.010683251544833183,
            0.0031392707023769617,
            -0.02081330120563507,
            -0.07386916130781174,
            -0.007961287163197994,
            -0.042242538183927536,
            0.0298930536955595,
            -0.01604441925883293,
            -0.0014329222030937672,
            -0.022116683423519135,
            -0.03970581293106079,
            0.009518901817500591,
            -0.008575820364058018,
            0.006260954774916172,
            -0.005439335014671087,
            -0.02145073190331459,
            -0.026154810562729836,
            -0.02785184234380722,
            -0.0036585191264748573,
            0.03651518002152443,
            0.038825973868370056,
            0.014175345189869404,
            -0.025231782346963882,
            0.020031750202178955,
            -0.012037758715450764,
            -0.014674093574285507,
            -0.01869991049170494,
            -0.023722752928733826,
            -0.01821909099817276,
            -0.03113495744764805,
            -0.0018684371607378125,
            0.005581061355769634,
            -0.020212322473526,
            0.018446577712893486,
            0.015479867346584797,
            -0.012635447084903717,
            0.0288456529378891,
            0.02478720061480999,
            -0.0151452561840415,
            -0.020656531676650047,
            0.026387447491288185,
            -0.013628896325826645,
            -0.022152431309223175,
            -0.029280314221978188,
            0.0027276091277599335,
            0.0173015333712101,
            -0.005666028242558241,
            0.0006776906666345894,
            -0.006516349036246538,
            -0.018692785874009132,
            0.030335957184433937,
            -0.024954471737146378,
            0.0035760547034442425,
            -0.043173450976610184,
            0.001707475516013801,
            0.02592802420258522,
            -0.03960590437054634,
            -0.02832726761698723,
            0.025357428938150406,
            -0.016042547300457954,
            0.001554250717163086,
            0.01869143359363079,
            0.018352430313825607,
            0.024833058938384056,
            0.014717411249876022,
            0.0006194732850417495,
            0.0005324725061655045,
            0.024904649704694748,
            0.02015051245689392,
            -0.021866094321012497,
            0.023452283814549446,
            -0.013816696591675282,
            -0.009456601925194263,
            0.01826607435941696,
            -0.004494318272918463,
            0.042904820293188095,
            -0.017749279737472534,
            -0.032824039459228516,
            0.032978300005197525,
            0.026009229943156242,
            -0.0014561054995283484,
            -0.04578734561800957,
            0.004654524847865105,
            -0.019897541031241417,
            0.020218249410390854,
            0.006501370575278997,
            0.028190214186906815,
            -0.0701146349310875,
            -0.01469795498996973,
            -0.03227442130446434,
            0.037772610783576965,
            -0.0117532629519701,
            0.043397121131420135,
            -0.0018898554844781756,
            -0.009983440861105919,
            0.001300416886806488,
            0.013357061892747879,
            -0.027190541848540306,
            -0.002251510974019766,
            -0.030145764350891113,
            -0.011590611189603806,
            -0.006591092795133591,
            -0.009631698951125145,
            0.0339764840900898,
            -0.01309027150273323,
            0.014609315432608128,
            -0.008916028775274754,
            0.003889039158821106,
            -0.004195696208626032,
            0.01950489729642868,
            0.0021171648986637592,
            0.023715144023299217,
            -5.9680663980543613e-05,
            0.013633114285767078,
            0.003246150678023696,
            0.032708317041397095,
            0.01649940386414528,
            -0.02533024363219738,
            -0.025421004742383957,
            0.01067445520311594,
            0.004738944116979837,
            -0.010312325321137905,
            0.0020750572439283133,
            -0.01045890524983406,
            -0.03570658341050148,
            0.021482262760400772,
            0.003683835966512561,
            0.001739280647598207,
            -0.004962780047208071,
            0.04742550104856491,
            -0.0060018799267709255,
            -0.03719104453921318,
            0.02100268378853798,
            -0.015203038230538368,
            -0.032535530626773834,
            0.022348104044795036,
            -0.017033835873007774,
            -0.00482529029250145,
            -0.0392133966088295,
            0.0352664552628994,
            0.0017715165158733726,
            0.00019394545233808458,
            -0.0164802186191082,
            -0.005926160607486963,
            0.034568414092063904,
            -0.02391977235674858,
            0.013307931832969189,
            0.0052110967226326466,
            0.027489490807056427,
            -0.02850298397243023,
            -0.009230226278305054,
            -0.003069268772378564,
            0.009556454606354237,
            0.00921611487865448,
            -0.06395035982131958,
            0.00659854244440794,
            0.030999774113297462,
            0.019598007202148438,
            -0.005831911228597164,
            -0.011130626313388348,
            0.005029925610870123,
            0.018275924026966095,
            0.01264761108905077,
            0.018695220351219177,
            0.03086092509329319,
            -0.01324845664203167,
            0.008921390399336815,
            0.016073668375611305,
            0.01366789173334837,
            -0.00744249951094389,
            -0.0027515816036611795,
            -0.028926195576786995,
            -0.004510562401264906,
            0.012178649194538593,
            0.007149797864258289,
            0.009666346944868565,
            -0.035365115851163864,
            0.022239090874791145,
            0.012165754102170467,
            -0.015845831483602524,
            -0.016358964145183563,
            -0.019231224432587624,
            0.04076592996716499,
            -0.004901869688183069,
            0.007543482817709446,
            -0.00015603809151798487,
            0.021932458505034447,
            -0.018382709473371506,
            0.00940064899623394,
            0.02981218881905079,
            -0.004932387266308069,
            0.02479313500225544,
            -0.008831161074340343,
            -0.008005877956748009,
            0.026529492810368538,
            0.029782027006149292,
            -0.01506197638809681,
            -0.015702130272984505,
            -0.018916403874754906,
            -0.015307996422052383,
            -0.007603517267853022,
            -0.02986227534711361,
            -0.03889857977628708,
            -0.006657115183770657,
            -0.00710314279422164,
            -0.026923179626464844,
            0.006317273247987032,
            0.022834377363324165,
            0.005983739160001278,
            -0.020410500466823578,
            0.02627088502049446,
            -0.0010534272296354175,
            -0.015123574994504452,
            -0.01727171801030636,
            -0.039026133716106415,
            -0.04675084352493286,
            -0.0010757566196843982,
            0.028274545446038246,
            -0.031001321971416473,
            -0.0038291625678539276,
            -0.015064455568790436,
            0.027164367958903313,
            0.016200188547372818,
            -0.01944117620587349
        ],
        [
            0.02845068648457527,
            0.024937910959124565,
            0.015451616607606411,
            0.012040500529110432,
            -0.016633817926049232,
            -0.005136852152645588,
            -0.020016778260469437,
            -0.04509297385811806,
            -0.020209480077028275,
            -0.02558404766023159,
            6.994778232183307e-05,
            0.012164844200015068,
            -0.02567913755774498,
            -0.0010866397060453892,
            0.00668800575658679,
            0.010227364487946033,
            -0.0182514451444149,
            -0.015523568727076054,
            -0.011877800337970257,
            0.003914061933755875,
            -0.027113603428006172,
            -0.03168366849422455,
            0.00015978141163941473,
            0.014688938856124878,
            0.018688978627324104,
            -0.02191678248345852,
            -0.008499572053551674,
            -0.04984273016452789,
            -0.0237803366035223,
            0.025710992515087128,
            -0.011025404557585716,
            -0.011325737461447716,
            0.012859433889389038,
            0.019288228824734688,
            0.010708951391279697,
            -0.0115074273198843,
            0.010543372482061386,
            0.03161289170384407,
            0.024250658228993416,
            -0.01964561827480793,
            -0.008237607777118683,
            -0.022866657003760338,
            0.007802486419677734,
            -0.00220425589941442,
            -0.018427947536110878,
            -0.015103520825505257,
            0.03478579968214035,
            0.03388587757945061,
            -0.009545856155455112,
            -0.008182776160538197,
            -0.0228416807949543,
            0.006169361062347889,
            0.0023746711667627096,
            -0.03239252045750618,
            -0.008564461953938007,
            -0.018149247393012047,
            0.02551707811653614,
            0.019077517092227936,
            0.01703229546546936,
            0.013756882399320602,
            0.0035330166574567556,
            0.025626471266150475,
            0.010075725615024567,
            0.029268907383084297,
            -0.016336435452103615,
            -0.0020619728602468967,
            -0.009900716133415699,
            0.03222658485174179,
            0.0413229763507843,
            -0.02820754051208496,
            -0.0047895940952003,
            -0.004234278574585915,
            -0.005635958630591631,
            0.010551751591265202,
            0.03537246212363243,
            0.01629471778869629,
            0.009428056888282299,
            -0.006410782225430012,
            -0.018417099490761757,
            0.0023309842217713594,
            0.0012998185120522976,
            -0.01949472166597843,
            -0.0010871674166992307,
            0.003961352165788412,
            0.020192598924040794,
            0.006987021304666996,
            -0.007566605694591999,
            0.0236972626298666,
            -0.010443409904837608,
            0.018186373636126518,
            0.019179601222276688,
            -0.010191243141889572,
            -0.013954592868685722,
            -0.01567390002310276,
            0.0034770178608596325,
            -0.03140450641512871,
            0.01374693214893341,
            -0.0015826160088181496,
            -0.028341101482510567,
            -0.015864266082644463,
            0.028059370815753937,
            -0.005538770463317633,
            0.033437538892030716,
            -0.024381102994084358,
            0.022275924682617188,
            0.008906005881726742,
            -0.01662489026784897,
            0.014239922165870667,
            0.01690974459052086,
            -0.016626909375190735,
            0.008225718513131142,
            -0.06856868416070938,
            -0.035090550780296326,
            -0.0262885931879282,
            0.03778599947690964,
            0.0043523721396923065,
            0.033287566155195236,
            0.03213357552886009,
            -0.00393100269138813,
            0.02185705490410328,
            -0.005764237605035305,
            -0.02661735936999321,
            -0.03525716811418533,
            0.0359848327934742,
            -0.017399737611413002,
            -0.023835040628910065,
            -0.026365229859948158,
            -0.03403538092970848,
            -0.01725853979587555,
            0.0270099937915802,
            -0.013809173367917538,
            0.04305218905210495,
            0.018934175372123718,
            -0.014152361080050468,
            0.00879589281976223,
            0.034620922058820724,
            -0.018947139382362366,
            -0.02664119377732277,
            -0.04429611936211586,
            0.02167886309325695,
            0.02101929672062397,
            0.02883869968354702,
            -0.029315998777747154,
            0.003828119020909071,
            -0.0030290009453892708,
            0.04055459797382355,
            -0.028477372601628304,
            -0.003152830759063363,
            0.00581783801317215,
            0.00031128927366808057,
            0.033150769770145416,
            0.016088414937257767,
            -0.00036966358311474323,
            0.05583607777953148,
            0.015329855494201183,
            0.0007072318694554269,
            0.04569731280207634,
            0.04048223793506622,
            -0.04113918915390968,
            0.003678367706015706,
            0.015213252045214176,
            -0.017990410327911377,
            -0.029078377410769463,
            0.030641963705420494,
            -0.011175410822033882,
            -0.006535722408443689,
            -0.05256352946162224,
            -0.031150726601481438,
            -0.001973872072994709,
            0.018547771498560905,
            0.02767168916761875,
            -0.009360572323203087,
            -0.05275348946452141,
            -0.008108875714242458,
            -0.0008147915941663086,
            -0.0004693224036600441,
            -0.006007448770105839,
            0.013701530173420906,
            -0.029248753562569618,
            0.001501209451816976,
            -0.02375788614153862,
            -0.016958435997366905,
            -0.03768027946352959,
            0.0028853528201580048,
            0.030128074809908867,
            -0.009463577531278133,
            -0.02477100118994713,
            0.00488592404872179,
            0.018953194841742516,
            -0.015835857018828392,
            0.003434019163250923,
            0.0026542008854448795,
            0.0051378183998167515,
            0.03362530842423439,
            0.026709025725722313,
            0.023405464366078377,
            0.03150470554828644,
            -0.000977828400209546,
            0.009527750313282013,
            -0.029495537281036377,
            0.00860296655446291,
            0.0037319567054510117,
            0.019142553210258484,
            0.0036957249976694584,
            0.015160910785198212,
            0.005394068546593189,
            -0.04667271301150322,
            0.02207428589463234,
            0.005072040483355522,
            -0.0010986595880240202,
            0.00749547453597188,
            0.019986510276794434,
            -0.008271010592579842,
            -0.018538769334554672,
            0.032290223985910416,
            -0.024318713694810867,
            0.023663140833377838,
            0.022578584030270576,
            0.029069863259792328,
            0.005607203114777803,
            0.0211299117654562,
            0.022131159901618958,
            -0.023678738623857498,
            -0.013841762207448483,
            0.022261403501033783,
            0.0659756064414978,
            0.008242958225309849,
            -0.029536403715610504,
            0.004087931476533413,
            0.032571449875831604,
            0.024983050301671028,
            -0.03419560194015503,
            0.016303179785609245,
            -0.01324474811553955,
            0.013057026080787182,
            0.023198017850518227,
            0.013787316158413887,
            -0.023747242987155914,
            -0.01408274844288826,
            0.039891138672828674,
            -0.011940227821469307,
            0.010929624550044537,
            -0.019341910257935524,
            -0.018760813400149345,
            0.03808554634451866,
            0.016013305634260178,
            -0.007927199825644493,
            0.0008555446402169764,
            0.01259196549654007,
            0.0028324993327260017,
            0.010596639476716518,
            -0.002426170278340578,
            -0.02502252720296383,
            -0.025011083111166954,
            -0.003933499567210674,
            -0.005903357174247503,
            -0.030527830123901367,
            0.008380194194614887,
            -0.025550706312060356,
            0.03407198190689087,
            -0.03514840081334114,
            -0.02840682677924633,
            -0.04651359096169472,
            -0.023460248485207558,
            -0.02196381241083145,
            -0.0743323341012001,
            0.007096966728568077,
            0.014795558527112007,
            -0.002267242409288883,
            0.026394328102469444,
            -0.019462011754512787,
            -0.022354930639266968,
            -0.02011125348508358,
            -0.03443243354558945,
            -0.025644291192293167,
            -0.006755114533007145,
            -0.021748464554548264,
            0.0018099982989951968,
            0.006256516557186842,
            -0.0210981834679842,
            -0.014966157265007496,
            -0.01330183818936348,
            0.015383887104690075,
            0.02386973612010479,
            -0.03388915956020355,
            -0.027143409475684166,
            0.026463260874152184,
            0.0679641142487526,
            0.0017899087397381663,
            0.01604262925684452,
            -0.016878768801689148,
            -0.016731442883610725,
            -0.004553801845759153,
            0.02976839430630207,
            -0.016598457470536232,
            0.018356923013925552,
            0.04642122611403465,
            0.03559694439172745,
            -0.005836567375808954,
            0.014188137836754322,
            0.005098267458379269,
            -0.0011636310955509543,
            0.0065258340910077095,
            0.008012856356799603,
            -0.01737956516444683,
            0.0017127847531810403,
            0.0021436004899442196,
            0.03563898801803589,
            0.008362463675439358,
            -0.0019597315695136786,
            0.03952180594205856,
            -0.007586756721138954,
            -0.022641850635409355,
            -0.017053930088877678,
            0.024076303467154503,
            0.003251102287322283,
            -0.02328404411673546,
            0.023219633847475052,
            -0.023538578301668167,
            -0.021876441314816475,
            -0.01889059506356716,
            -0.06076468899846077,
            0.04426931217312813,
            -0.03831632435321808,
            -0.006445776671171188,
            0.027126284316182137,
            -0.0071549867279827595,
            0.04359176382422447,
            -0.048802684992551804,
            0.025240017101168633,
            -0.0075815534219145775,
            0.03057095967233181,
            0.01835140399634838,
            -0.008818773552775383,
            0.012423606589436531,
            -0.019852159544825554,
            0.002592504722997546,
            -0.016775039955973625,
            0.006344798486679792,
            0.02801414020359516,
            0.0016027227975428104,
            -0.02483171597123146,
            0.022813407704234123,
            -0.01497722789645195,
            0.020759468898177147,
            0.03844931721687317,
            0.008107349276542664,
            0.01759425736963749,
            -0.004145249258726835,
            -0.0073859477415680885,
            -0.037554461508989334,
            0.04379454255104065,
            -0.012928773649036884,
            0.023855384439229965,
            -0.02914874069392681,
            0.013100942596793175,
            0.019034285098314285,
            -0.021025927737355232,
            -0.02134319208562374,
            0.0009071069653145969,
            -0.0028033950366079807,
            -0.004134488757699728,
            -0.004364385269582272,
            0.029205743223428726,
            -0.02425273135304451,
            0.0005091455532237887,
            -0.023815736174583435,
            -0.03349629417061806,
            -0.01251805666834116,
            0.007438528351485729,
            -0.02326194941997528,
            0.022136179730296135,
            -0.03750351071357727,
            -0.006721717771142721,
            -0.024161674082279205,
            -0.032449863851070404,
            0.015331905335187912,
            0.020926661789417267,
            -0.003961535170674324,
            -0.02287459746003151,
            0.059124141931533813,
            -0.006984627339988947,
            0.03495064377784729,
            -0.021182727068662643,
            -0.03350718319416046,
            -0.016327084973454475,
            0.015842637047171593,
            0.006249403581023216,
            -0.01933344267308712,
            -0.0015134654240682721,
            0.005656072404235601,
            -0.0045254044234752655,
            0.03111933171749115,
            0.01581517420709133,
            -0.015183479525148869,
            -0.004951167851686478,
            0.008099689148366451,
            -0.0055830348283052444,
            0.030967243015766144,
            -0.011110397055745125,
            0.015467038378119469,
            0.01712144911289215,
            -0.021588744595646858,
            -0.0071440269239246845,
            0.01047440990805626,
            -0.02745610848069191,
            0.004172574728727341,
            0.018632065504789352,
            0.03139343112707138,
            -0.006704803556203842,
            -0.01661750301718712,
            -0.013105202466249466,
            -0.018840400502085686,
            -0.021500667557120323,
            -0.00774342892691493,
            -0.023844951763749123,
            0.012677590362727642,
            -0.004109285306185484,
            0.024315686896443367,
            -0.016368933022022247,
            0.01145982462912798,
            -0.009840218350291252,
            -0.016500651836395264,
            0.03312717750668526,
            -0.015752054750919342,
            -0.020886041224002838,
            -0.02683331072330475,
            0.009606420062482357,
            -0.010015913285315037,
            -0.012284480966627598,
            -0.02313273772597313,
            0.025816824287176132,
            0.012690925039350986,
            -0.05712946876883507,
            0.00851491093635559,
            0.023218294605612755,
            -0.048448994755744934,
            0.035807207226753235,
            -0.008567691780626774,
            0.029114512726664543,
            -0.04481268674135208,
            -0.0023823620285838842,
            0.019242919981479645,
            0.024791259318590164,
            0.001020842813886702,
            0.0060707456432282925,
            -0.030602136626839638,
            -0.07453019917011261,
            0.003607593011111021,
            -0.021026035770773888,
            0.018839433789253235,
            -0.035431478172540665,
            -0.03288848698139191,
            0.02328096516430378,
            -0.018490063026547432,
            -0.007249928545206785,
            -0.007011835463345051,
            0.0040890369564294815,
            -0.002550405915826559,
            0.008197246119379997,
            -0.0041578677482903,
            0.020103594288229942,
            0.021515846252441406,
            -0.007448007818311453,
            -0.00896531343460083,
            0.022030718624591827,
            0.009950682520866394,
            0.007015100214630365,
            -0.019123924896121025,
            -0.016976941376924515,
            0.036017678678035736,
            -0.017889516428112984,
            0.024555159732699394,
            0.02595335990190506,
            -0.041168130934238434,
            -0.002527420176193118,
            0.04309197887778282,
            -0.008084746077656746,
            0.03127545118331909,
            -0.025055982172489166,
            -0.028412945568561554,
            -0.02895372547209263,
            -0.0063263666816055775,
            0.02814352698624134,
            -0.01900443248450756,
            -0.00718240812420845,
            0.026336485520005226,
            -0.023156894370913506,
            0.04438503459095955,
            0.022187400609254837,
            0.01977885514497757,
            -3.7081485061207786e-05,
            -0.01604885794222355,
            -0.04242038354277611,
            0.017132675275206566,
            -0.010770770721137524,
            -0.0024588285014033318,
            -0.007052531000226736,
            0.0176546610891819,
            0.018736086785793304,
            0.017843978479504585,
            0.0013096749316900969,
            -0.030794184654951096,
            0.0032409578561782837,
            0.016743319109082222,
            -0.02823905646800995,
            -0.02718055061995983,
            -0.022493109107017517,
            0.00869173277169466,
            0.004657447803765535,
            0.029323257505893707,
            0.047214627265930176,
            0.007920034229755402,
            -0.0016068051336333156,
            -0.03686594218015671,
            0.017252430319786072,
            0.0341629795730114,
            -0.01816059835255146,
            -0.010038971900939941,
            -0.02591862715780735,
            -0.029023656621575356,
            0.008970887400209904,
            0.004383288789540529,
            -0.035814836621284485,
            -0.027428634464740753,
            0.0008510873303748667,
            -0.07573252171278,
            -0.019275138154625893,
            0.026574987918138504,
            0.04614531621336937,
            0.017624886706471443,
            0.043909210711717606,
            -0.045275211334228516,
            0.016447599977254868,
            0.03494781628251076,
            -0.02763558365404606,
            -0.023625614121556282,
            -0.03808736428618431,
            -0.017460588365793228,
            -0.020099764689803123,
            -0.0164576955139637,
            -0.0002856048522517085,
            -0.0395558625459671,
            -0.01734844408929348,
            0.016745256260037422,
            -0.036660321056842804,
            0.01663336344063282,
            0.04673011600971222,
            -0.0017638904973864555,
            0.02302670292556286,
            0.03217922896146774,
            -0.016655268147587776,
            0.002642708597704768,
            0.02752435952425003,
            -0.01986159197986126,
            0.01121208444237709,
            -0.0317138209939003,
            -0.0017320382175967097,
            -0.007968961261212826,
            -0.0250643789768219,
            0.01745602674782276,
            0.02392229065299034,
            0.02655511535704136,
            0.01709369383752346,
            0.013556019403040409,
            0.007499361876398325,
            0.01653958670794964,
            -0.022701160982251167,
            -0.007900954224169254,
            0.03167986497282982,
            0.024554716423153877,
            -0.009411964565515518,
            0.01849391497671604,
            -0.004273341968655586,
            0.005308623891323805,
            -0.022587850689888,
            0.005164457950741053,
            -0.007849213667213917,
            0.05437207594513893,
            0.008110844530165195,
            -0.025713296607136726,
            0.009863012470304966,
            -0.024442480877041817,
            -0.006305955350399017,
            0.014593971893191338,
            0.00653854850679636,
            0.022022271528840065,
            0.016430502757430077,
            0.029461244121193886,
            0.015858521685004234,
            -0.0048494222573935986,
            0.00849949661642313,
            0.033248431980609894,
            0.03829607367515564,
            0.010457354597747326,
            -0.0063577382825315,
            -0.001762012136168778,
            -0.023278117179870605,
            0.02175595797598362,
            -0.07009171694517136,
            -0.024715986102819443,
            -0.006870512384921312,
            0.022895611822605133,
            0.015157315880060196,
            0.026095673441886902,
            -0.028124937787652016,
            0.03685266524553299,
            -0.0028999049682170153,
            0.006818464957177639,
            -0.013848637230694294,
            0.037599414587020874,
            -0.01935230754315853,
            -0.014442270621657372,
            0.00962865725159645,
            0.005681427661329508,
            -0.012783415615558624,
            -0.027435628697276115,
            0.014198053628206253,
            -0.003361834678798914,
            -0.0008863555267453194,
            -0.009888327680528164,
            -0.01140275876969099,
            0.007019981741905212,
            -0.03224027901887894,
            -0.007563559804111719,
            0.019699107855558395,
            0.022378383204340935,
            -0.012443182058632374,
            -0.02622333914041519,
            0.019188866019248962,
            -0.01928195357322693,
            0.002199362963438034,
            0.015446044504642487,
            0.004660173784941435,
            0.036374282091856,
            -0.007563854102045298,
            0.006407557055354118,
            0.05243353918194771,
            0.030314527451992035,
            0.004761340096592903,
            0.023316804319620132,
            0.008321860805153847,
            0.018056703731417656,
            0.0023254023399204016,
            0.02801925502717495,
            0.019395943731069565,
            0.020992223173379898,
            0.014566812664270401,
            -0.012551436200737953,
            0.025263503193855286,
            0.02692633494734764,
            0.02406240440905094,
            0.006042714696377516,
            -0.02172621712088585,
            0.02585836686193943,
            0.0009779263054952025,
            -0.01681641675531864,
            0.0342247299849987,
            0.039502449333667755,
            -0.005812809336930513,
            -0.05680986866354942,
            0.03107459470629692,
            -0.020280182361602783,
            0.0027145470958203077,
            0.02056027017533779,
            0.012212397530674934,
            -0.019694730639457703,
            0.0044492436572909355,
            0.009794875979423523,
            0.022060463204979897,
            -0.03535686060786247,
            0.022911056876182556,
            -0.0077018458396196365,
            -0.016995253041386604,
            0.01593928411602974,
            0.014535420574247837,
            0.009555566124618053,
            0.019328011199831963,
            0.018610280007123947,
            -0.008039990440011024,
            0.03158850967884064,
            -0.011324877850711346,
            0.02412208542227745,
            0.008093739859759808,
            -0.00872374139726162,
            -0.01131573598831892,
            0.024002283811569214,
            0.008962973952293396,
            0.014741885475814342,
            -0.026207812130451202,
            0.011133771389722824,
            -0.012972872704267502,
            0.03750479221343994,
            -0.019734341651201248,
            0.018598457798361778,
            0.030095858499407768,
            -0.025098497048020363,
            -0.00982833094894886,
            -0.01995830424129963,
            -0.018401630222797394,
            -0.012798136100172997,
            0.016629792749881744,
            0.02569076046347618,
            -0.014778449200093746,
            0.011325801722705364,
            9.15834098123014e-05,
            0.030419761314988136,
            -0.02941581979393959,
            0.012242134660482407,
            -0.02802262082695961,
            0.01393362507224083,
            0.012939102947711945,
            -0.013652957044541836,
            0.014982474036514759,
            -0.012770291417837143,
            0.017414579167962074,
            -0.02841135486960411,
            0.008634447120130062,
            0.003532265778630972,
            -0.014311186969280243,
            0.023543642833828926,
            -0.004905883688479662,
            -0.006838558707386255,
            0.016678232699632645,
            -0.010620488785207272,
            0.025115851312875748,
            0.007707179058343172,
            0.011085755191743374,
            0.002768522361293435,
            0.025158299133181572,
            0.02266499027609825,
            0.01228508073836565,
            0.020120181143283844,
            0.0008134092204272747,
            -0.02192063443362713,
            0.0250758808106184,
            -0.02013256773352623,
            -0.009353690780699253,
            -0.01663598045706749,
            -0.012707049027085304,
            0.028859177604317665,
            0.016445618122816086,
            0.026207759976387024,
            0.02651367336511612,
            0.012031815014779568,
            0.0010899576591327786,
            -0.005283847916871309,
            -0.0012214267626404762,
            0.028137922286987305,
            -0.016340453177690506,
            0.020405292510986328,
            0.013014866970479488,
            0.019848857074975967,
            -0.008308016695082188,
            0.013189928606152534,
            0.025627080351114273,
            0.043428342789411545,
            0.028117157518863678,
            0.010054443962872028,
            -0.023837508633732796,
            0.0413384884595871,
            -0.014120999723672867,
            -0.005788448732346296,
            -0.03221196308732033,
            0.011961061507463455,
            -0.03059002198278904,
            -0.005882148630917072,
            0.0007622147677466273,
            0.0030949024949222803,
            -0.010278565809130669,
            -0.0198932196944952,
            0.010907966643571854,
            -0.017701590433716774,
            -0.030059615150094032,
            -0.02194354310631752,
            -0.0195518359541893,
            -0.04593551903963089,
            -0.01907588727772236,
            0.025707639753818512,
            0.019635828211903572,
            -0.03430937975645065,
            0.023025628179311752,
            0.030026597902178764,
            -0.01569845713675022,
            0.004491920582950115,
            -0.020860031247138977,
            0.017399519681930542,
            -0.012024479918181896,
            -0.008928867056965828,
            -0.006345198955386877,
            0.027594763785600662,
            0.013415046967566013,
            0.023062091320753098,
            -0.033803973346948624,
            -0.06665397435426712,
            0.03384913504123688,
            0.011394398286938667,
            0.007692741695791483,
            -0.0030687639955431223,
            -0.022056328132748604,
            0.007509519811719656,
            0.009339540265500546,
            -0.006190563552081585,
            0.017850985750555992,
            0.012027719058096409,
            -0.044233858585357666,
            0.04830888286232948,
            -0.028951168060302734,
            -0.022110946476459503,
            0.04160240292549133,
            0.04475386068224907,
            0.004931237548589706,
            0.03281664475798607,
            0.00197770562954247,
            0.0374615304172039,
            -0.006211272906512022,
            0.026465320959687233,
            0.00504577299579978,
            0.0196151752024889,
            -0.018206436187028885,
            0.022775117307901382,
            0.01469365507364273,
            0.0016393105033785105,
            0.027416201308369637,
            -0.039905350655317307,
            -0.0008831753511913121,
            0.02197922207415104,
            -0.02830537036061287,
            -0.014294969849288464,
            0.03177569806575775,
            0.004679211415350437,
            0.004633032251149416,
            0.03626972436904907,
            0.016340456902980804,
            0.05207866430282593,
            -0.03614111617207527,
            -0.015145808458328247,
            0.008449779823422432,
            0.011027603410184383,
            0.03893611952662468,
            -0.0259240809828043,
            0.010582029819488525,
            -0.045738816261291504,
            0.0168132521212101,
            -0.0014067639131098986,
            -0.018327465280890465,
            -0.014364663511514664,
            0.039714664220809937,
            0.013188540004193783,
            -0.021136300638318062,
            -0.00905584916472435,
            0.011186291463673115,
            0.008948147296905518,
            -0.00938413292169571,
            -0.013842443004250526,
            0.008384419605135918,
            -0.01584596559405327,
            -0.01895228773355484,
            0.015144613571465015,
            -0.01623256504535675,
            -0.004156216513365507,
            -0.00864448118954897,
            0.0016959905624389648,
            -0.03287637233734131,
            -0.007529840804636478,
            -0.00252236588858068,
            0.02153158187866211,
            -0.030382122844457626,
            0.026075752452015877,
            -0.020637689158320427,
            0.04606590420007706,
            0.023870395496487617,
            -0.006014248356223106,
            -0.0009112380212172866,
            0.011986842378973961,
            0.017167015001177788,
            -0.004345293156802654,
            0.04833822324872017,
            0.036528196185827255,
            -0.018706064671278,
            0.050007812678813934,
            -0.006321888417005539,
            0.022273235023021698,
            -0.013006089255213737,
            -0.0021602632477879524,
            0.02117837406694889,
            0.008122864179313183,
            0.04421853646636009,
            -0.026232922449707985,
            -0.024738643318414688,
            0.003765847533941269,
            0.0389091819524765,
            -0.02916799485683441,
            0.04822571575641632,
            -0.0321592316031456,
            0.0017023630207404494,
            -0.020365167409181595,
            -0.027755286544561386,
            0.02095039188861847,
            0.044531501829624176,
            -0.005077071953564882,
            0.014005858451128006,
            0.017419088631868362,
            0.0013861957704648376,
            -0.022183913737535477,
            -0.015677625313401222,
            0.009829435497522354,
            -0.002858729101717472,
            0.027732448652386665,
            -0.004389606881886721,
            0.013803837820887566,
            -0.027735354378819466,
            0.00837591290473938,
            -0.005778227932751179,
            0.036095984280109406,
            -0.019049327820539474,
            -0.0016444915672764182,
            0.022393105551600456,
            0.00035446096444502473,
            0.0048873466439545155,
            0.0072408257983624935,
            0.0019341529114171863,
            -0.025832854211330414,
            -0.02367972768843174,
            0.03158559277653694,
            -0.009923605248332024,
            -0.020768074318766594,
            0.0069987173192203045,
            0.0009077584254555404,
            -0.02113470435142517,
            -0.025038359686732292,
            0.035920921713113785,
            -0.008762517012655735,
            0.010013395920395851,
            -0.015937330201268196,
            -0.019405115395784378,
            -0.008759244345128536,
            -0.01477807480841875,
            -0.02220454066991806,
            -0.04898994788527489,
            -0.011484058573842049,
            -0.010242990218102932,
            -0.032274845987558365,
            0.044362250715494156,
            0.013461357913911343,
            0.00883147306740284,
            0.02298128791153431,
            -0.0015905887121334672,
            -0.02073376253247261,
            -0.008561073802411556,
            -0.024931201711297035,
            0.015665875747799873,
            0.008377425372600555,
            0.005121609196066856,
            0.046475909650325775,
            -0.005112767219543457,
            -0.012662772089242935,
            0.01643494889140129,
            -0.027232468128204346,
            0.02762952819466591,
            0.006954287178814411,
            -0.03470572456717491,
            0.0006755304057151079,
            0.01184679102152586,
            -0.023999081924557686,
            0.025644289329648018,
            -0.03614591807126999,
            0.00985143706202507,
            -0.04146513715386391,
            0.0022709963377565145,
            -0.005641069728881121,
            0.01008785143494606,
            0.005791744217276573,
            0.004262892529368401,
            -0.02143327333033085,
            0.009846256114542484,
            -0.030945731326937675,
            -0.02710961364209652,
            0.0007788820657879114,
            0.013912906870245934,
            0.0018887531477957964,
            0.011191913858056068,
            0.012591849081218243,
            0.005400106310844421,
            -0.0011775511084124446,
            0.014156806282699108,
            0.004694635979831219,
            0.022878604009747505,
            -0.00744468392804265,
            0.01719389110803604,
            0.013786992989480495,
            -0.01068651769310236,
            -0.023492565378546715,
            -4.3821015424327925e-05,
            0.002494737273082137,
            -0.05447673797607422,
            -0.0674198716878891,
            0.02936706319451332,
            0.022857608273625374,
            0.024642419070005417,
            -0.031378645449876785,
            -0.01669991947710514,
            -0.03437415882945061,
            -0.001155958161689341,
            -0.018138686195015907,
            -0.02583209238946438,
            0.014439373277127743,
            0.010109405033290386,
            -0.009160528890788555,
            -0.002280774060636759,
            0.012599960900843143,
            0.04431144520640373,
            0.0054398481734097,
            0.018062973394989967,
            -0.025164518505334854,
            -0.003250022418797016,
            0.003940457943826914,
            0.03383604809641838,
            -0.014278866350650787,
            -0.009291527792811394,
            -0.015829678624868393,
            0.014279824681580067,
            -0.008831411600112915,
            -0.0021376251243054867
        ],
        [
            -0.024854307994246483,
            0.03382588550448418,
            0.00019636002252809703,
            -0.028096863999962807,
            -0.021286997944116592,
            -0.055815454572439194,
            0.005481329280883074,
            -0.06242471560835838,
            -0.03422080725431442,
            -0.018918709829449654,
            0.042047176510095596,
            0.034203361719846725,
            0.01206989586353302,
            0.005784459877759218,
            0.007397414185106754,
            -0.023530369624495506,
            -0.001056254142895341,
            -0.016020534560084343,
            -0.009763326495885849,
            -0.032679539173841476,
            -0.02897324226796627,
            -0.010767273604869843,
            -0.06799141317605972,
            -0.01575181633234024,
            0.02450941689312458,
            -0.02778921276330948,
            0.01033518835902214,
            -0.021294882521033287,
            0.012351895682513714,
            -0.0037319783587008715,
            -0.008181694895029068,
            0.04282423108816147,
            -0.003262509359046817,
            0.009635082446038723,
            -0.014739627949893475,
            0.026746530085802078,
            -0.016227025538682938,
            -0.018246645107865334,
            0.02276547998189926,
            -0.02089882269501686,
            0.002861280692741275,
            0.014340669848024845,
            0.039978478103876114,
            0.02264401875436306,
            -0.05269618332386017,
            0.01667061634361744,
            0.022705020383000374,
            -0.01734044961631298,
            -0.00992815662175417,
            0.018706584349274635,
            0.040605951100587845,
            0.0220758356153965,
            0.053197044879198074,
            -0.008597423322498798,
            -0.04593304544687271,
            0.026367835700511932,
            -0.03844152390956879,
            0.017898548394441605,
            0.012524929828941822,
            0.01013315562158823,
            0.023746153339743614,
            0.019819961860775948,
            0.030978012830018997,
            0.016610162332654,
            0.014396739192306995,
            -0.04812433198094368,
            0.011007219552993774,
            -0.004061379935592413,
            0.013259301893413067,
            -0.03933875262737274,
            0.0038663065060973167,
            0.0017482254188507795,
            -0.0016105285612866282,
            0.021978791803121567,
            -0.006761759519577026,
            0.02096330001950264,
            0.02037268504500389,
            -0.037025086581707,
            0.0023637122940272093,
            0.0030374557245522738,
            -0.020974403247237206,
            0.04083986207842827,
            0.007802923675626516,
            0.03199281170964241,
            -0.02823287807404995,
            -0.005301062949001789,
            -0.021628614515066147,
            -0.021001901477575302,
            0.003037441521883011,
            0.028062527999281883,
            0.019876878708600998,
            0.00010484002268640324,
            0.003626739140599966,
            0.027094533666968346,
            0.012928469106554985,
            0.04563309624791145,
            0.015543443150818348,
            0.024342263117432594,
            0.03724927082657814,
            -0.0042205206118524075,
            -0.031044693663716316,
            -0.008058094419538975,
            -0.014214484952390194,
            -0.003254244104027748,
            -0.002543048933148384,
            0.0059224823489785194,
            -0.011284573934972286,
            -0.017363054677844048,
            -0.02806594781577587,
            -0.014118868857622147,
            0.024069659411907196,
            0.03881369158625603,
            0.0067924270406365395,
            0.028756851330399513,
            0.04109172150492668,
            -0.023338891565799713,
            -0.01033383421599865,
            0.03259000554680824,
            0.007250235881656408,
            0.03918677195906639,
            0.017573343589901924,
            0.015707477927207947,
            -0.050952501595020294,
            0.04035242646932602,
            -0.008526686578989029,
            0.023290228098630905,
            -0.043656039983034134,
            0.007457331754267216,
            -0.030502740293741226,
            0.008040889166295528,
            0.023874713107943535,
            -0.002640885766595602,
            -0.026908839121460915,
            -0.04935038089752197,
            0.030886391177773476,
            0.016370756551623344,
            -0.01561253983527422,
            -0.035101428627967834,
            0.015428708866238594,
            0.035117316991090775,
            0.003958850167691708,
            -0.0387599878013134,
            0.02144603803753853,
            -0.010631492361426353,
            -0.03693584352731705,
            0.03022512048482895,
            0.032980144023895264,
            -0.005002416670322418,
            -0.02784970961511135,
            0.010761581361293793,
            -0.0037591527216136456,
            0.05708344653248787,
            0.017042117193341255,
            0.027154605835676193,
            0.013418908230960369,
            0.03244714438915253,
            7.62548588681966e-05,
            -0.014122022315859795,
            -0.005022276192903519,
            -0.029225127771496773,
            0.010092136450111866,
            -0.007524395827203989,
            -0.01547570712864399,
            -0.020817454904317856,
            -0.02221173420548439,
            -0.05217183381319046,
            0.025229372084140778,
            0.0222722589969635,
            0.011903158389031887,
            -0.008326266892254353,
            -0.01615501381456852,
            -0.035931263118982315,
            -0.020815929397940636,
            -0.0033964335452765226,
            0.020698150619864464,
            0.0287788063287735,
            0.0021436489187180996,
            0.0380631648004055,
            -0.045725736767053604,
            -0.023418109863996506,
            -0.015224567614495754,
            -0.013246150687336922,
            -0.0013171249302104115,
            -0.009323596023023129,
            0.030610341578722,
            0.014135041274130344,
            -0.03781210631132126,
            0.008272754028439522,
            0.03676220029592514,
            0.0018874789820984006,
            -0.03203121945261955,
            0.00964091531932354,
            0.002804070943966508,
            -0.03425408899784088,
            -0.014669415540993214,
            0.025162851437926292,
            -0.03551993891596794,
            -0.00431195879355073,
            0.029375411570072174,
            0.02611270174384117,
            0.005446059163659811,
            -0.011053160764276981,
            0.030658448114991188,
            -0.008423533290624619,
            0.010606068186461926,
            0.02437785640358925,
            -0.02736005000770092,
            -0.03225918486714363,
            0.003031569067388773,
            -0.029125455766916275,
            -0.0009070487576536834,
            0.011618653312325478,
            -0.0077781109139323235,
            -0.02472052164375782,
            -0.0030267771799117327,
            -0.03042241558432579,
            -0.023637209087610245,
            -0.016779938712716103,
            0.01735750027000904,
            -0.012527107261121273,
            -0.01277107186615467,
            -0.020166510716080666,
            -0.034407053142786026,
            0.0001724486646708101,
            0.02446722611784935,
            -0.06912147253751755,
            0.036765068769454956,
            -0.0006043605972081423,
            -0.018165428191423416,
            0.013000701554119587,
            -0.01739596389234066,
            -0.011992882005870342,
            -0.016689619049429893,
            0.0001038111950038001,
            -0.008679562248289585,
            0.01675071381032467,
            0.004322342574596405,
            -0.0032804496586322784,
            -0.04841161519289017,
            -0.006440503057092428,
            0.028180014342069626,
            0.018333183601498604,
            -0.005614669062197208,
            -0.00044039508793503046,
            0.007423111237585545,
            -0.013008886948227882,
            -0.023237666115164757,
            -0.023007627576589584,
            0.018963532522320747,
            -0.03205490857362747,
            0.018198959529399872,
            0.0018638836918398738,
            0.03435568884015083,
            -0.029545262455940247,
            -0.016506537795066833,
            0.012536874040961266,
            -0.04052118584513664,
            -0.03116198629140854,
            0.0005605053738690913,
            0.016984818503260612,
            0.036254528909921646,
            0.015398021787405014,
            0.020624907687306404,
            -0.035589899867773056,
            0.006661699619144201,
            0.053123969584703445,
            -0.014089154079556465,
            -0.002939445897936821,
            0.0076775201596319675,
            0.040089260786771774,
            -0.02839110791683197,
            0.028370531275868416,
            -0.00794800091534853,
            -0.026571298018097878,
            -0.03559020534157753,
            -0.007086621597409248,
            0.0296737402677536,
            0.0046838028356432915,
            0.016979575157165527,
            0.027765870094299316,
            -0.019041476771235466,
            -0.022092383354902267,
            -0.024624645709991455,
            0.0010886172531172633,
            -0.010430162772536278,
            0.015331709757447243,
            0.02233797498047352,
            0.01935439184308052,
            0.011740465648472309,
            -0.024924831464886665,
            0.006602907087653875,
            0.029106589034199715,
            0.02534746192395687,
            -0.013451870530843735,
            -0.008926313370466232,
            -0.012711811810731888,
            -0.04464604705572128,
            0.019741088151931763,
            -0.007839343510568142,
            0.015186302363872528,
            0.02344653755426407,
            -0.028462735936045647,
            -0.016704268753528595,
            0.027273306623101234,
            0.038047973066568375,
            0.018155235797166824,
            -0.02921934239566326,
            0.01767471805214882,
            -0.0028273763600736856,
            0.06769752502441406,
            -0.01654365099966526,
            -0.02824845165014267,
            -0.012282210402190685,
            0.023689623922109604,
            -0.011010600253939629,
            0.008560461923480034,
            0.022349752485752106,
            0.03225034847855568,
            -0.032036155462265015,
            -0.018890298902988434,
            -0.008582786656916142,
            -0.02989864908158779,
            -0.007523818407207727,
            -0.018067797645926476,
            0.024299966171383858,
            -0.02208486944437027,
            0.014559130184352398,
            0.014039087109267712,
            0.02242019958794117,
            -0.031565696001052856,
            -0.0107951108366251,
            0.02058437094092369,
            -0.048195935785770416,
            -0.0338783860206604,
            0.02536618523299694,
            -0.009674501605331898,
            -0.020675018429756165,
            -0.002083931351080537,
            0.01632651314139366,
            -0.01544698141515255,
            -0.0038591327611356974,
            0.004630545154213905,
            -0.03466592729091644,
            0.02076897956430912,
            0.023970890790224075,
            -0.025886768475174904,
            0.060940664261579514,
            0.009858750738203526,
            -0.0068858303129673,
            -0.017235402017831802,
            -0.023560037836432457,
            0.04347697272896767,
            -0.03960035368800163,
            0.007192947436124086,
            0.017576999962329865,
            -0.014323009178042412,
            -0.029432784765958786,
            -0.02593759261071682,
            0.019597012549638748,
            -0.001578324125148356,
            0.030395543202757835,
            -0.009848381392657757,
            -0.0038506172131747007,
            0.02859133668243885,
            -0.03148141875863075,
            -0.030177298933267593,
            0.026577305048704147,
            -0.004949942696839571,
            -0.009580045938491821,
            0.03322329744696617,
            0.032010942697525024,
            -0.017578663304448128,
            -0.014071911573410034,
            0.035630568861961365,
            -0.03456345573067665,
            -0.015115399844944477,
            0.03514106571674347,
            0.015858039259910583,
            -0.0071175540797412395,
            -0.033673305064439774,
            0.03540526703000069,
            0.02749887853860855,
            0.01091729011386633,
            0.005639467854052782,
            0.018393833190202713,
            -0.04337945953011513,
            0.03235713392496109,
            -0.05745204910635948,
            0.039946865290403366,
            0.033301252871751785,
            -0.03941817581653595,
            0.035326793789863586,
            -0.02567010000348091,
            -0.0413818284869194,
            0.018781373277306557,
            0.017738793045282364,
            0.00010947692499030381,
            0.0147329643368721,
            0.021012380719184875,
            0.028607666492462158,
            0.012117266654968262,
            -0.02374761551618576,
            0.021375101059675217,
            0.01052466593682766,
            0.032229505479335785,
            0.018067045137286186,
            -0.016864674165844917,
            -0.01817975379526615,
            0.014821200631558895,
            0.01621279865503311,
            0.0018185497028753161,
            -0.0336909219622612,
            0.009278825484216213,
            0.04887448251247406,
            -0.019118869677186012,
            0.017166800796985626,
            -0.0031746914610266685,
            0.012930016964673996,
            -0.020035631954669952,
            -0.025031859055161476,
            -0.07835512608289719,
            -0.037781402468681335,
            0.028483599424362183,
            0.0008683872874826193,
            0.031178433448076248,
            0.004784100688993931,
            -0.0036361035890877247,
            0.009271448478102684,
            -0.005978868342936039,
            -0.017131060361862183,
            0.008999506011605263,
            0.0002351140574319288,
            -0.016660176217556,
            -0.015541396103799343,
            -0.0023943139240145683,
            0.04108363389968872,
            0.04772891849279404,
            -0.0011234111152589321,
            0.02338279038667679,
            0.04855028912425041,
            -0.022418344393372536,
            -0.01995268650352955,
            -0.013224611058831215,
            -0.032193783670663834,
            0.012541343457996845,
            0.018960779532790184,
            -0.01549653522670269,
            0.026664739474654198,
            0.01785879395902157,
            -0.009067554958164692,
            0.0037277494557201862,
            0.030604999512434006,
            0.06013694778084755,
            0.0322539322078228,
            -0.0035178582184016705,
            -0.0017433922039344907,
            0.04193144664168358,
            0.011713285930454731,
            0.014998490922152996,
            0.043629419058561325,
            0.027062565088272095,
            -0.028402939438819885,
            -0.0386631116271019,
            0.01532412227243185,
            -0.0033848208840936422,
            0.0013459841720759869,
            -0.030843989923596382,
            -0.036021701991558075,
            0.009478392079472542,
            0.023852508515119553,
            -0.043204281479120255,
            -0.0031741433776915073,
            -0.012262735515832901,
            0.035210803151130676,
            0.0018563744379207492,
            0.026182929053902626,
            -0.032808080315589905,
            0.017781827598810196,
            -0.013379469513893127,
            0.01611591875553131,
            0.004933319054543972,
            -0.002770951483398676,
            -0.006268344819545746,
            0.0007229120819829404,
            0.07272665202617645,
            -0.0066742002964019775,
            0.021584220230579376,
            0.004828091710805893,
            -0.03312305733561516,
            -0.013339237309992313,
            0.02316340059041977,
            -0.020654894411563873,
            -0.026799822226166725,
            0.006169761065393686,
            -0.021342596039175987,
            -0.012646413408219814,
            0.004229140933603048,
            -0.01948300004005432,
            -0.03536181151866913,
            -0.017712654545903206,
            0.005960751790553331,
            -0.010369018651545048,
            -0.01663822866976261,
            -0.0293838232755661,
            0.01793719083070755,
            0.01952645555138588,
            0.03258354589343071,
            0.007900903932750225,
            0.007444301620125771,
            0.025333670899271965,
            -0.004831239115446806,
            0.034479979425668716,
            -0.018675662577152252,
            -0.01067170687019825,
            -0.04129603132605553,
            0.008178732357919216,
            -0.0259109977632761,
            0.011767101474106312,
            0.00996589008718729,
            -0.01183271687477827,
            0.025777149945497513,
            0.037093088030815125,
            0.009374509565532207,
            0.014744809828698635,
            0.019420821219682693,
            0.010562321171164513,
            0.059748586267232895,
            -0.023416966199874878,
            -0.02202034182846546,
            -0.026225630193948746,
            -0.018171926960349083,
            0.03769813850522041,
            0.06960105895996094,
            -0.012319348752498627,
            0.017012687399983406,
            0.04696748033165932,
            -0.03172464668750763,
            -0.005556646268814802,
            -0.03188992291688919,
            0.003526032669469714,
            0.025340458378195763,
            -0.0051057301461696625,
            0.006212992127984762,
            0.024910161271691322,
            -0.026933498680591583,
            0.025196101516485214,
            -0.012538023293018341,
            -0.028734153136610985,
            0.022815994918346405,
            0.016169201582670212,
            0.007411519065499306,
            0.018764734268188477,
            0.04070626199245453,
            -0.0002435788483126089,
            0.026294607669115067,
            0.017113232985138893,
            -0.03259173780679703,
            0.012129443697631359,
            0.01933303289115429,
            0.004830083344131708,
            0.005646129138767719,
            0.02777538076043129,
            0.023191241547465324,
            0.019420022144913673,
            0.028111029416322708,
            -0.012989997863769531,
            0.03625283017754555,
            0.007216370664536953,
            -0.046404577791690826,
            0.032433003187179565,
            -0.03256084397435188,
            0.01781383529305458,
            0.013708513230085373,
            0.026445720344781876,
            0.02413373626768589,
            0.01879953406751156,
            -0.019487466663122177,
            0.05047346279025078,
            -0.0307097677141428,
            -0.011018036864697933,
            0.004258067812770605,
            0.02464315854012966,
            0.03138139471411705,
            0.02488994039595127,
            0.015076220966875553,
            0.033500805497169495,
            0.013471195474267006,
            0.01826772466301918,
            -0.035679545253515244,
            -0.008543301373720169,
            0.0035672825761139393,
            0.01310356892645359,
            -0.02440628781914711,
            -0.018848776817321777,
            -0.01856580562889576,
            0.0006154184229671955,
            -0.024720648303627968,
            -0.034993961453437805,
            0.0018103449838235974,
            0.008922659792006016,
            0.03176841512322426,
            -0.059121064841747284,
            0.05723421648144722,
            0.04067493602633476,
            -0.03961130604147911,
            0.0060070110484957695,
            -0.011151795275509357,
            -0.018656296655535698,
            0.012164607644081116,
            0.012244204059243202,
            -0.0009221300133503973,
            -0.02698983997106552,
            0.011850195936858654,
            0.0032102488912642,
            -0.034528668969869614,
            -0.029260102659463882,
            0.026352839544415474,
            -0.024958791211247444,
            0.019051572307944298,
            -0.02858249843120575,
            0.014564715325832367,
            0.006997667718678713,
            -0.027194682508707047,
            -0.017622455954551697,
            0.002869821386411786,
            0.012530829757452011,
            0.006770842708647251,
            -0.02039327472448349,
            -0.029110588133335114,
            0.02634402923285961,
            -0.024955805391073227,
            -0.020375864580273628,
            -0.03440103679895401,
            0.005395563784986734,
            -0.004366637673228979,
            0.029226671904325485,
            0.003953307401388884,
            -0.04492570832371712,
            -0.0008677515434101224,
            -0.011027757078409195,
            0.025082116946578026,
            0.01749514415860176,
            -0.04854170233011246,
            0.0207131989300251,
            -0.026775140315294266,
            -0.021657265722751617,
            0.02939203381538391,
            -0.025865621864795685,
            -0.003501612925902009,
            -0.0018594834255054593,
            -0.006832815241068602,
            -0.014299564994871616,
            0.03212425112724304,
            0.019013158977031708,
            -0.010844535194337368,
            0.025406941771507263,
            0.005909299943596125,
            -0.007055835332721472,
            -0.008057257160544395,
            -0.006542571354657412,
            0.02510538138449192,
            0.032357633113861084,
            -0.016832668334245682,
            0.0034167098347097635,
            0.004161498509347439,
            0.02136892080307007,
            -0.003154508303850889,
            -0.006521978881210089,
            -0.03714350610971451,
            -0.019664566963911057,
            -0.005238191224634647,
            -0.020723018795251846,
            0.017787396907806396,
            -0.03124096430838108,
            -0.025542985647916794,
            -0.014048518612980843,
            0.002724115736782551,
            -0.006656915880739689,
            0.0043519590981304646,
            0.039365097880363464,
            0.03954959288239479,
            0.00017053329793270677,
            0.025102484971284866,
            -0.027621857821941376,
            -0.020131250843405724,
            0.027322813868522644,
            -0.014527982100844383,
            -0.02416286990046501,
            0.008935272693634033,
            -0.012341056019067764,
            0.007242177613079548,
            0.0037171272560954094,
            0.022307652980089188,
            0.03335466980934143,
            0.008493981324136257,
            0.03348470479249954,
            -0.01021626964211464,
            0.026863403618335724,
            -0.0003197264450136572,
            0.008276478387415409,
            0.013634845614433289,
            0.027110649272799492,
            0.015289487317204475,
            -0.010853839106857777,
            -0.012410679832100868,
            -0.05362033098936081,
            -0.0035197963006794453,
            -0.025312531739473343,
            -0.024600863456726074,
            0.011803761124610901,
            -0.02834182418882847,
            0.02606717310845852,
            -0.016543548554182053,
            -0.024730099365115166,
            0.03077184222638607,
            0.032241445034742355,
            -0.02283012866973877,
            -0.0025470193941146135,
            0.026723435148596764,
            -0.030325474217534065,
            0.01638674922287464,
            0.0036689031403511763,
            0.029525386169552803,
            0.028979197144508362,
            0.014613878913223743,
            -0.025956107303500175,
            -0.010474621318280697,
            0.021475233137607574,
            0.006850965786725283,
            -0.029177766293287277,
            0.022579792886972427,
            0.0031012867111712694,
            0.00810446497052908,
            -0.01828761026263237,
            -0.02532275579869747,
            0.015216791070997715,
            -0.056397777050733566,
            -0.046679481863975525,
            -0.026056772097945213,
            0.03644082322716713,
            0.0013015763834118843,
            0.0381641648709774,
            -0.0031765911262482405,
            0.000523721391800791,
            0.02814498543739319,
            0.011865824460983276,
            0.020224928855895996,
            -0.01383246947079897,
            0.0199800506234169,
            -0.022186217829585075,
            0.0031013870611786842,
            -0.002834578976035118,
            -0.009649120271205902,
            0.0023625094909220934,
            0.0287006925791502,
            0.006323708686977625,
            -0.04777688533067703,
            0.04324950650334358,
            0.0040422831661999226,
            0.0007249485934153199,
            -0.017925798892974854,
            0.0005145139875821769,
            0.019553547725081444,
            0.017690051347017288,
            0.03255387023091316,
            0.024802913889288902,
            -0.01962723396718502,
            0.02508385106921196,
            0.005384520627558231,
            -0.04926605895161629,
            -0.014898337423801422,
            -0.03353601694107056,
            -0.006044846028089523,
            0.010633396916091442,
            0.001960420748218894,
            0.012925054877996445,
            -0.0004961087834089994,
            0.005766908172518015,
            -0.03434647247195244,
            -0.016603652387857437,
            0.03953474387526512,
            -0.007663541939109564,
            0.057436395436525345,
            -0.006491855252534151,
            -0.03011474944651127,
            -0.01916438713669777,
            0.033951740711927414,
            -0.0004698244156315923,
            0.001974827842786908,
            0.013525151647627354,
            0.03202439099550247,
            -0.011426692828536034,
            -0.0028213104233145714,
            0.023706642910838127,
            0.03246458247303963,
            0.03325250744819641,
            -0.08279767632484436,
            0.0034865466877818108,
            -0.014847200363874435,
            0.03920644894242287,
            -0.013536904007196426,
            0.02049563266336918,
            -0.023457970470190048,
            0.0009575033909641206,
            0.046056583523750305,
            0.04257388412952423,
            -0.007485071662813425,
            -0.01279366947710514,
            0.004025991540402174,
            0.010055139660835266,
            -0.0010501999640837312,
            -0.0003853726666420698,
            -0.017525048926472664,
            -0.0030568991787731647,
            0.03135896101593971,
            0.005155483260750771,
            -0.001451767166145146,
            0.02976435050368309,
            0.010363348759710789,
            -0.005062142387032509,
            -0.022446835413575172,
            -0.0428185872733593,
            0.026674844324588776,
            0.0028676011133939028,
            0.04030827805399895,
            0.005444087088108063,
            -0.004673691466450691,
            0.012780758552253246,
            0.012677024118602276,
            0.02281244657933712,
            0.018894005566835403,
            -0.0007356841233558953,
            0.04914556443691254,
            0.00920312199741602,
            0.03871311992406845,
            -0.0019784800242632627,
            0.015280459076166153,
            0.005147308576852083,
            -0.043747372925281525,
            -0.009001894854009151,
            0.03271869942545891,
            0.022116079926490784,
            0.03629858046770096,
            -0.004892079159617424,
            -0.02159988321363926,
            0.001645153621211648,
            -0.022580111399292946,
            0.008165474981069565,
            -0.03737775608897209,
            0.016830403357744217,
            -0.009810865856707096,
            0.016923435032367706,
            0.03450377658009529,
            0.00426715612411499,
            0.03310028836131096,
            0.029197685420513153,
            0.023058094084262848,
            0.011941668577492237,
            0.008091441355645657,
            0.029618460685014725,
            -0.02957405149936676,
            -0.048638664186000824,
            -5.1280287152621895e-05,
            -0.006137312855571508,
            0.022985918447375298,
            -0.030812405049800873,
            0.012799824588000774,
            -0.019548920914530754,
            -0.020083408802747726,
            0.04793127253651619,
            -0.0040764822624623775,
            -0.0035044027026742697,
            -0.04931284487247467,
            0.024314701557159424,
            -0.027657441794872284,
            0.015312379226088524,
            0.01638237200677395,
            0.02396797202527523,
            0.0015313627663999796,
            -0.02644358202815056,
            0.011928454041481018,
            -0.006163458339869976,
            0.07918935269117355,
            -0.0321698896586895,
            -0.00365583342500031,
            -0.008606821298599243,
            -0.02849579229950905,
            0.00942146871238947,
            -0.03833303228020668,
            -0.0007233783253468573,
            0.03581523895263672,
            -0.009672722779214382,
            0.05670445039868355,
            0.02679918333888054,
            0.00492339301854372,
            -0.00191872485447675,
            -0.021197548136115074,
            -0.026133529841899872,
            0.004249857738614082,
            -0.009432888589799404,
            0.03629534691572189,
            0.014819812029600143,
            -0.011085438542068005,
            0.035603683441877365,
            0.041165996342897415,
            -0.024516869336366653,
            0.03125537186861038,
            0.03126519173383713,
            0.015881743282079697,
            -0.02199416048824787,
            0.009577415883541107,
            0.011381427757441998,
            -0.012334002181887627,
            -0.0005636795540340245,
            -0.03255094587802887,
            0.020671142265200615,
            0.021057141944766045,
            -0.02931969426572323,
            -0.011753580532968044,
            -0.006672123447060585,
            -0.013182110153138638,
            -0.0002875378413591534,
            0.02660253643989563,
            -0.031188026070594788,
            0.0007786687347106636,
            -0.010767425410449505,
            -0.007301007863134146,
            0.02202782966196537,
            -0.016169993206858635,
            -0.006318407133221626,
            -0.024388814345002174,
            0.04756813496351242,
            0.021735740825533867,
            0.019863037392497063,
            0.029395924881100655,
            -0.026151621714234352,
            0.03441029042005539,
            0.015809670090675354,
            -0.0011325868545100093,
            -0.011024419218301773,
            0.01711777038872242,
            -0.0029444508254528046,
            0.06575250625610352,
            -0.018989404663443565,
            0.05030597001314163,
            0.00799096655100584,
            0.005368460435420275,
            0.026157967746257782,
            0.009522263891994953,
            -0.04286951944231987,
            -0.0032217453699558973,
            0.00018614409782458097,
            0.0049614873714745045,
            0.009539678692817688,
            0.026365460827946663,
            -0.008319668471813202,
            -0.03631295636296272,
            0.03440932184457779,
            -0.02801506407558918,
            0.017610883340239525,
            -0.004509321879595518,
            0.01008918322622776,
            0.021788690239191055,
            0.0140193160623312,
            0.03139995411038399,
            0.03188332915306091,
            -0.025604963302612305,
            -0.0338420569896698,
            0.015791818499565125,
            0.016272397711873055,
            -0.01998826116323471,
            -0.015913905575871468,
            0.028557593002915382,
            -0.006456051953136921,
            0.029550371691584587,
            -0.020801331847906113,
            -0.01274838112294674,
            -0.023372165858745575,
            0.003223828971385956,
            -0.0183635875582695,
            0.043086063116788864,
            0.011324048973619938,
            0.060223668813705444,
            -0.016211194917559624,
            -0.03288087621331215,
            -0.024033227935433388,
            -0.01718202419579029,
            -0.0037512427661567926,
            -0.002430762629956007,
            -0.03239961341023445,
            -0.01942272298038006,
            0.025742821395397186,
            -0.026012159883975983,
            -0.008297305554151535,
            -0.008990991860628128,
            0.010422120802104473,
            0.0012501409510150552,
            0.006141688209027052,
            -0.02510044537484646,
            -0.015318610705435276,
            0.009924660436809063,
            -0.02623828686773777,
            -0.02617962844669819,
            -0.030884692445397377,
            0.057034507393836975,
            -0.009170821867883205,
            -0.002080374862998724,
            0.04961142688989639,
            -0.02058827318251133,
            -0.020455602556467056,
            0.03982393443584442,
            -0.013573757372796535,
            0.03810722380876541,
            0.015645228326320648,
            0.03218793123960495,
            0.06095351278781891,
            -0.026828987523913383,
            0.005726932547986507,
            0.020002365112304688,
            0.030381297692656517,
            -0.017670705914497375,
            -0.03823692351579666,
            0.03230063244700432,
            -0.00467638298869133
        ],
        [
            0.022899139672517776,
            0.023691412061452866,
            -0.02322491817176342,
            0.008891848847270012,
            -0.019054589793086052,
            0.029780050739645958,
            0.016217561438679695,
            0.019902516156435013,
            0.00981266237795353,
            0.022926809266209602,
            0.015276946127414703,
            -0.024401668459177017,
            0.03958217054605484,
            0.01580497808754444,
            -0.010389412753283978,
            0.013474720530211926,
            0.03090658038854599,
            -0.00605482142418623,
            0.006818563211709261,
            -0.02907676436007023,
            0.005800096318125725,
            -0.025593595579266548,
            -0.013790344819426537,
            -0.013980215415358543,
            0.006838414818048477,
            0.03262374922633171,
            0.018005361780524254,
            0.014176864176988602,
            0.030487291514873505,
            0.00034380063880234957,
            -0.019802259281277657,
            -0.029054682701826096,
            0.027135619893670082,
            0.02811213955283165,
            0.020388733595609665,
            0.016014130786061287,
            0.004429093562066555,
            0.016437141224741936,
            0.014582994394004345,
            -0.012186053209006786,
            0.010246068239212036,
            -0.02893727272748947,
            0.015231892466545105,
            -0.027669448405504227,
            -0.007335756905376911,
            -0.018593680113554,
            0.015621657483279705,
            -0.020545652136206627,
            -0.024317672476172447,
            -0.04952352121472359,
            -0.027265047654509544,
            -0.008224992081522942,
            0.004290066193789244,
            0.001492266426794231,
            -0.040946975350379944,
            -0.002480030059814453,
            -0.000499460322316736,
            -0.013526277616620064,
            0.012675393372774124,
            -0.022108571603894234,
            0.0024676909670233727,
            0.0003129650140181184,
            -0.012755663134157658,
            -0.013267992064356804,
            -0.01763959228992462,
            -0.006087074987590313,
            0.014505431987345219,
            0.015880178660154343,
            -0.039329372346401215,
            -0.02744780294597149,
            -0.005683234892785549,
            -0.022584011778235435,
            -0.015791334211826324,
            0.0068916622549295425,
            0.004510975442826748,
            -0.058572497218847275,
            0.011172016151249409,
            0.01646200381219387,
            0.02005169913172722,
            0.015895238146185875,
            0.017696870490908623,
            0.0018849314656108618,
            0.0062304395250976086,
            0.019713331013917923,
            -0.020729221403598785,
            0.019091662019491196,
            0.0305483415722847,
            -0.0009766691364347935,
            -0.044724203646183014,
            -0.009767290204763412,
            -0.004308636765927076,
            -0.004900922998785973,
            -0.03282767906785011,
            -0.016496414318680763,
            -0.02437141165137291,
            -0.009199481457471848,
            0.030467564240098,
            0.022332916036248207,
            0.015594235621392727,
            -0.0049165901727974415,
            -0.033675093203783035,
            -0.0010773440590128303,
            0.04058605432510376,
            -0.009866880252957344,
            0.021096136420965195,
            -0.007905266247689724,
            -0.0025411357637494802,
            0.004506106022745371,
            0.011052721180021763,
            -0.030700799077749252,
            0.011649279855191708,
            -0.007750442251563072,
            -0.009022069163620472,
            -0.006844242103397846,
            0.0623270682990551,
            0.0007618865347467363,
            -0.01776129938662052,
            -0.03143634274601936,
            0.006406406406313181,
            -0.005978303495794535,
            0.009527556598186493,
            0.013538653962314129,
            -0.030696135014295578,
            0.024587180465459824,
            -0.02406548149883747,
            -0.010273916646838188,
            -0.004945005755871534,
            0.02975030057132244,
            0.016517529264092445,
            -0.02632468193769455,
            -0.028633344918489456,
            -0.0032359426841139793,
            -0.010828505270183086,
            0.011830151081085205,
            -0.00273484131321311,
            -0.021519506350159645,
            0.02344704233109951,
            -0.001804935745894909,
            -0.03458322212100029,
            0.018092164769768715,
            -0.010017486289143562,
            -0.019485503435134888,
            0.016578640788793564,
            -0.002082566265016794,
            0.015174983069300652,
            -0.021480543538928032,
            -0.008342944085597992,
            -0.008879069238901138,
            -0.0019979230128228664,
            0.005155174061655998,
            0.04031245410442352,
            0.03317868337035179,
            -0.016068069264292717,
            0.018674440681934357,
            0.021497588604688644,
            -0.021194640547037125,
            0.005669272504746914,
            0.007899763062596321,
            -0.01552998460829258,
            0.004448949359357357,
            -0.019331365823745728,
            0.015350201167166233,
            0.0025823088362812996,
            -0.01157804299145937,
            -0.019965503364801407,
            -0.003526165382936597,
            0.004137265961617231,
            -0.019429344683885574,
            -0.0010107842972502112,
            -0.017791319638490677,
            0.004827103577554226,
            0.008970482274889946,
            -0.026500452309846878,
            0.03979235514998436,
            0.0034929222892969847,
            0.0011838112259283662,
            0.008473895490169525,
            0.02372192218899727,
            0.00011688726954162121,
            0.018839458003640175,
            0.020913874730467796,
            -0.001253223279491067,
            -0.011030962690711021,
            -0.005962496157735586,
            -0.005506351124495268,
            -0.0018002429278567433,
            -0.006517536006867886,
            0.018504297360777855,
            0.010452049784362316,
            0.02030891552567482,
            -0.02738182805478573,
            0.024361250922083855,
            -0.04199424386024475,
            0.023868974298238754,
            -0.0029336276929825544,
            -0.018434014171361923,
            -0.01852317526936531,
            0.01393031794577837,
            0.019436577335000038,
            -0.010595036670565605,
            0.031182343140244484,
            -0.003259207820519805,
            0.0331960991024971,
            -0.03499757498502731,
            0.014126626774668694,
            0.023840539157390594,
            -0.02480890229344368,
            -0.026868006214499474,
            -0.006384453270584345,
            -0.00871989969164133,
            -0.014687644317746162,
            0.02469240501523018,
            0.03501961752772331,
            0.00517646037042141,
            -0.011898800730705261,
            0.011938242241740227,
            -0.002494333777576685,
            0.014865375123918056,
            -0.021819138899445534,
            -0.03515736013650894,
            -0.009834995493292809,
            0.010250670835375786,
            -0.014914654195308685,
            -0.02693476527929306,
            0.03022403083741665,
            0.058930788189172745,
            0.03162599727511406,
            0.01378292590379715,
            0.0190043393522501,
            -0.006250940263271332,
            -0.0025777851697057486,
            -0.018288452178239822,
            0.025113748386502266,
            0.023964693769812584,
            -0.03349055349826813,
            0.007593594957143068,
            0.02248699776828289,
            -0.007729163859039545,
            -0.013802255503833294,
            -0.01952197775244713,
            0.007484029978513718,
            0.02500530704855919,
            0.0017857998609542847,
            0.033977311104536057,
            0.006580796558409929,
            0.0029446324333548546,
            0.02308754064142704,
            -0.0027202884666621685,
            0.0009452893864363432,
            0.014198197983205318,
            -0.00227826158516109,
            0.017822163179516792,
            -0.023117883130908012,
            0.03111090138554573,
            0.009163489565253258,
            0.02239299565553665,
            0.0016981217777356505,
            0.00914938934147358,
            -0.014469986781477928,
            0.021501624956727028,
            -0.0053871069103479385,
            0.001994722755625844,
            0.013951829634606838,
            0.020054230466485023,
            -0.028886878862977028,
            -0.020290514454245567,
            0.001225283951498568,
            0.03293521702289581,
            -0.005210537929087877,
            -0.01864442229270935,
            0.03469925373792648,
            -0.018786456435918808,
            -0.03201746940612793,
            0.0015016687102615833,
            -0.014355730265378952,
            0.01650276780128479,
            -0.033038631081581116,
            -0.018736854195594788,
            0.03133793920278549,
            0.02792268805205822,
            0.02201962284743786,
            0.033959049731492996,
            -0.0005646585486829281,
            -0.013078820891678333,
            -0.028284059837460518,
            -0.02433752827346325,
            -0.021153779700398445,
            -0.005666826386004686,
            0.026548083871603012,
            0.022619204595685005,
            0.02563239075243473,
            0.02599707804620266,
            -0.0296507366001606,
            -0.01012984849512577,
            -0.026801124215126038,
            0.03120448812842369,
            0.013970672152936459,
            0.028968408703804016,
            -0.026696979999542236,
            -0.013130743987858295,
            0.003233707044273615,
            -0.03695068135857582,
            -0.034062426537275314,
            0.01670350693166256,
            0.02014411985874176,
            0.008850835263729095,
            -0.013204672373831272,
            0.022985532879829407,
            -0.021023336797952652,
            0.012978939339518547,
            -0.04571274295449257,
            0.013581209816038609,
            -0.003465472487732768,
            0.004130905494093895,
            0.008078372105956078,
            -0.0066195144318044186,
            0.026290303096175194,
            -0.03555840626358986,
            0.0306970477104187,
            0.017979634925723076,
            -0.005860408768057823,
            0.06486035883426666,
            0.002150648506358266,
            -0.062073055654764175,
            -0.021415621042251587,
            0.0059019471518695354,
            -0.02889552153646946,
            -0.015244252048432827,
            0.011497192084789276,
            0.02256215736269951,
            -0.019561992958188057,
            -0.024488339200615883,
            -0.030629657208919525,
            -0.016750402748584747,
            0.01518910750746727,
            -0.014468223787844181,
            0.015141461975872517,
            0.041043125092983246,
            0.021412964910268784,
            0.014908826909959316,
            -0.01568225584924221,
            0.015566873364150524,
            0.04199788346886635,
            0.026271121576428413,
            -0.006080984137952328,
            -0.03732282668352127,
            -0.037415970116853714,
            0.010090671479701996,
            0.03253421559929848,
            0.06755919754505157,
            0.028253791853785515,
            0.060028061270713806,
            0.010225129313766956,
            0.0075110928155481815,
            0.02126936987042427,
            -0.01839587092399597,
            0.015533313155174255,
            -0.009802543558180332,
            0.0238647498190403,
            0.0028764817398041487,
            0.01323160994797945,
            -0.029736768454313278,
            1.7078824384952895e-05,
            0.013395995832979679,
            0.00042867279262281954,
            0.017810631543397903,
            0.0005820836522616446,
            -0.017333952710032463,
            -0.012791264802217484,
            0.002513627288863063,
            0.01761634834110737,
            -0.022640913724899292,
            -0.02507764659821987,
            -0.010318515822291374,
            -0.005527100060135126,
            0.0024347829166799784,
            -0.02434457279741764,
            -0.007199806161224842,
            -0.00766305485740304,
            0.007133962586522102,
            -0.0025322868023067713,
            -0.0007565547130070627,
            -0.0014946359442546964,
            0.00011654554691631347,
            0.014083517715334892,
            0.005115126725286245,
            -0.0027513650711625814,
            -0.05133339762687683,
            0.013939882628619671,
            0.005680611357092857,
            0.0170066449791193,
            -0.0032960991375148296,
            -0.005400942172855139,
            -0.008994956500828266,
            0.014340687543153763,
            0.027109714224934578,
            0.010506230406463146,
            0.003231070237234235,
            -0.00665750028565526,
            0.01734338141977787,
            -0.0017021666280925274,
            -0.027568833902478218,
            -0.009860897436738014,
            -0.03715895116329193,
            -0.03217955306172371,
            0.010330921970307827,
            -0.011613809503614902,
            -0.0352809876203537,
            0.010081343352794647,
            -0.04035988450050354,
            0.0012211883440613747,
            -0.011741159483790398,
            -0.0067617017775774,
            0.025416823104023933,
            0.016717301681637764,
            0.027022384107112885,
            0.019267478957772255,
            -0.027452804148197174,
            0.011341100558638573,
            0.0005496996454894543,
            -0.0589495524764061,
            0.015605892054736614,
            -0.002476366702467203,
            0.0026918172370642424,
            -0.026406973600387573,
            -0.03485816717147827,
            0.010261009447276592,
            -0.019042665138840675,
            0.02509693056344986,
            0.03344060480594635,
            0.0275579746812582,
            0.014588624238967896,
            -0.012287670746445656,
            -0.0074812727980315685,
            -0.014588222838938236,
            -0.027606748044490814,
            0.005998170468956232,
            -0.008813814260065556,
            0.01663839817047119,
            0.013386117294430733,
            -0.0006757644005119801,
            0.03772272542119026,
            0.02106720581650734,
            0.005407910794019699,
            0.014448320493102074,
            -0.022518208250403404,
            -0.007462970446795225,
            -0.06371945142745972,
            0.01034620776772499,
            -0.016602404415607452,
            0.013376014307141304,
            -0.006768836174160242,
            -0.041304245591163635,
            0.007515948731452227,
            -0.005811907816678286,
            -0.01867266744375229,
            -0.004549287259578705,
            0.007758898660540581,
            -0.010173448361456394,
            0.023711221292614937,
            0.001497988123446703,
            0.0034725205041468143,
            0.010393643751740456,
            -0.07500956952571869,
            0.006688627880066633,
            0.03179514408111572,
            0.0005093620857223868,
            -0.037494029849767685,
            -0.02715027891099453,
            0.025629909709095955,
            -0.016784340143203735,
            0.005707628559321165,
            -0.011270085349678993,
            0.002731683664023876,
            0.007197631988674402,
            -0.05365818366408348,
            -0.01452038437128067,
            0.017499806359410286,
            -0.03166581690311432,
            0.008121867664158344,
            0.04422227665781975,
            -0.007197578903287649,
            0.016162659972906113,
            0.026700764894485474,
            -0.03578881919384003,
            -0.008186566643416882,
            -0.007684887852519751,
            -0.021278703585267067,
            -0.04438520222902298,
            0.010356612503528595,
            -0.021407799795269966,
            -0.024134717881679535,
            -0.025450900197029114,
            -0.019529562443494797,
            0.0048127369955182076,
            -0.02635844610631466,
            0.03337578848004341,
            0.026496969163417816,
            0.02532223053276539,
            -0.005434285383671522,
            -0.003988189622759819,
            -0.0044570970349013805,
            -0.03204978629946709,
            0.01665586419403553,
            0.010699985548853874,
            0.024060778319835663,
            0.03122703731060028,
            0.010771074332296848,
            0.009112954139709473,
            -0.0364040844142437,
            -0.025340748950839043,
            -0.03086320124566555,
            -0.029813997447490692,
            0.032211244106292725,
            0.004068596754223108,
            0.03128046542406082,
            -0.024941232055425644,
            -0.02886572852730751,
            0.0031078853644430637,
            0.028563402593135834,
            0.002930863993242383,
            -0.022991104051470757,
            -0.018922921270132065,
            0.022410472854971886,
            0.03381369635462761,
            -0.02586953341960907,
            -0.0027731258887797594,
            0.00038928218418732285,
            -0.014201846905052662,
            -0.005052872933447361,
            0.035025518387556076,
            0.015229701064527035,
            -0.03226463124155998,
            -0.006226350087672472,
            -0.02388136088848114,
            0.05752849578857422,
            0.014625024981796741,
            0.01156417466700077,
            -0.008214517496526241,
            0.016811663284897804,
            -0.014814561232924461,
            0.037966761738061905,
            0.017245354130864143,
            -0.019442366436123848,
            0.021901773288846016,
            -0.023249443620443344,
            0.01394864171743393,
            -0.05552429333329201,
            0.01667090132832527,
            -0.02652670070528984,
            -0.016282083466649055,
            -0.013141359202563763,
            0.0034953493159264326,
            0.009113590233027935,
            0.012322030030190945,
            -0.0156257301568985,
            -0.0022004267666488886,
            -0.011837827041745186,
            -0.028239374980330467,
            0.008348479866981506,
            -0.02616344951093197,
            -0.008842919953167439,
            0.01863075979053974,
            0.023415250703692436,
            0.009868381544947624,
            -0.004599354695528746,
            -0.0028638208750635386,
            0.0086609972640872,
            0.008810079656541348,
            0.026814112439751625,
            -0.03458290547132492,
            -0.012447682209312916,
            -0.0032871237490326166,
            -0.009239367209374905,
            0.009105542674660683,
            -0.02012794278562069,
            -0.034553173929452896,
            0.013447949662804604,
            0.029844097793102264,
            0.015054143033921719,
            0.023415541276335716,
            -0.006085521541535854,
            -0.003584903199225664,
            -0.036707062274217606,
            -0.007178734987974167,
            0.0012215226888656616,
            -0.01716807670891285,
            0.02781176008284092,
            -0.022220270708203316,
            0.011761593632400036,
            -0.011864371597766876,
            -0.006075157783925533,
            0.006938198581337929,
            -0.002897022059187293,
            0.005066230893135071,
            0.016531726345419884,
            -0.02055056393146515,
            -0.023268409073352814,
            0.00511936703696847,
            0.018247980624437332,
            -0.03858521580696106,
            0.029163844883441925,
            0.004474367946386337,
            0.0031456835567951202,
            -0.02666555717587471,
            -0.033304329961538315,
            -0.0034328862093389034,
            0.027846308425068855,
            0.0025150682777166367,
            -0.014625933021306992,
            -0.0142822265625,
            0.028763875365257263,
            0.029558729380369186,
            -0.02905069664120674,
            -0.005706985481083393,
            -0.02148968167603016,
            -0.028692571446299553,
            0.01121759694069624,
            -0.020545966923236847,
            0.006907718721777201,
            0.022521957755088806,
            0.013911393471062183,
            0.0025219134986400604,
            -0.018093915656208992,
            0.0257091224193573,
            -0.01723642461001873,
            0.0230847280472517,
            0.006904052570462227,
            0.03005456179380417,
            0.007085954770445824,
            0.00800439715385437,
            -0.0014317372115328908,
            0.03373688459396362,
            -0.028227930888533592,
            0.01630857214331627,
            0.03212300315499306,
            -0.04197235032916069,
            -0.0057642110623419285,
            0.03658907487988472,
            -0.031875018030405045,
            0.029693227261304855,
            0.01362156867980957,
            -0.0053143748082220554,
            0.046349912881851196,
            -0.003285678569227457,
            0.03318166360259056,
            -0.0016891182167455554,
            0.02034257911145687,
            -0.010578702203929424,
            -0.029043493792414665,
            0.0029587619937956333,
            0.018184775486588478,
            0.022360514849424362,
            0.028761746361851692,
            0.02971390262246132,
            -0.00021746999118477106,
            0.030893638730049133,
            0.0017186879413202405,
            0.0016484494553878903,
            0.021525317803025246,
            0.0032572082709521055,
            0.010887403041124344,
            -0.0252700075507164,
            -0.020390722900629044,
            -0.02183099277317524,
            0.027802933007478714,
            0.00991634652018547,
            0.026240983977913857,
            0.022379152476787567,
            -0.03482983261346817,
            0.018930699676275253,
            0.0673975795507431,
            -0.02056565321981907,
            -0.023660482838749886,
            0.023398632183670998,
            -0.017699241638183594,
            -0.01059763040393591,
            -0.014933803118765354,
            0.009250275790691376,
            -0.027524640783667564,
            0.02152629941701889,
            -0.01339822355657816,
            0.028155390173196793,
            -0.005167272407561541,
            0.006776057183742523,
            -0.0307020116597414,
            0.032194141298532486,
            -0.03390016406774521,
            -0.026724088937044144,
            -0.007152592297643423,
            0.0004055523604620248,
            -0.030300118029117584,
            0.0007413908606395125,
            -0.022425934672355652,
            -0.018143920227885246,
            -0.030078032985329628,
            -0.0016646577278152108,
            0.02055874839425087,
            -0.009710506536066532,
            0.006987363565713167,
            0.02184963785111904,
            0.015728885307908058,
            0.017308443784713745,
            -0.02620292827486992,
            -0.023638993501663208,
            -0.01044556312263012,
            -0.03493237867951393,
            0.00164936447981745,
            -0.01783214882016182,
            0.022583024576306343,
            -0.02607288584113121,
            -0.024449793621897697,
            0.002561153145506978,
            0.021635277196764946,
            -0.015832994133234024,
            -0.010967436246573925,
            0.0023461710661649704,
            -0.015905149281024933,
            0.014672699384391308,
            0.02660807967185974,
            0.01458034012466669,
            0.008945845998823643,
            0.03164985775947571,
            0.0671037882566452,
            0.002771826460957527,
            -0.019100025296211243,
            -0.027976641431450844,
            0.014610725454986095,
            0.025304753333330154,
            0.021724261343479156,
            0.03157176077365875,
            0.023173203691840172,
            -0.0070765321142971516,
            0.0013341265730559826,
            -0.013279631733894348,
            -0.009426889009773731,
            0.029090646654367447,
            -0.017199989408254623,
            -0.014246293343603611,
            0.022101176902651787,
            0.008998195640742779,
            -0.01839214563369751,
            -0.010884953662753105,
            -0.01044562365859747,
            0.01919405907392502,
            -0.02563566341996193,
            -0.023552406579256058,
            0.020152755081653595,
            -0.029713504016399384,
            0.02427639812231064,
            0.0026969232130795717,
            0.03344316780567169,
            -0.006870684213936329,
            -0.0746484249830246,
            0.028151163831353188,
            -0.017859913408756256,
            -0.012398416176438332,
            0.03239280730485916,
            -0.0182453915476799,
            0.004368909634649754,
            -0.012859025038778782,
            -0.0069542559795081615,
            -0.02709517814218998,
            -0.015363043174147606,
            0.03403251990675926,
            -0.02554565854370594,
            0.02131626382470131,
            0.0079679936170578,
            -0.0072874403558671474,
            0.007245512213557959,
            -0.015367631800472736,
            -0.005176657810807228,
            0.029994411394000053,
            -0.00772940693423152,
            0.004660488106310368,
            -0.014798901975154877,
            -0.0025266301818192005,
            -0.056075893342494965,
            -0.009256071411073208,
            0.030845679342746735,
            0.02047346904873848,
            0.02558540366590023,
            0.024746011942625046,
            0.018073100596666336,
            -0.010788269340991974,
            0.024238575249910355,
            -0.02595898136496544,
            0.006508080754429102,
            -0.0016149107832461596,
            0.04700837284326553,
            0.017591489478945732,
            -0.0044259256683290005,
            0.03602876514196396,
            0.030817434191703796,
            0.020592670887708664,
            -0.021453456953167915,
            0.010590462014079094,
            -0.03237932175397873,
            0.0369558110833168,
            -0.043042514473199844,
            0.009684053249657154,
            -0.0383148118853569,
            -0.00915122777223587,
            -0.016372213140130043,
            -0.015827756375074387,
            0.020845388993620872,
            -0.02992742322385311,
            0.014839030802249908,
            -0.026124272495508194,
            0.041566990315914154,
            -0.020464982837438583,
            0.033322740346193314,
            -0.006513094063848257,
            0.009090667590498924,
            0.000878155289683491,
            0.019564315676689148,
            0.031616922467947006,
            0.011203853413462639,
            -0.031010432168841362,
            -0.01980811357498169,
            0.012967136688530445,
            0.01098238117992878,
            0.01019223127514124,
            0.04739000275731087,
            -0.053983233869075775,
            0.03430783003568649,
            -0.010036812163889408,
            -0.009699954651296139,
            0.024478839710354805,
            0.007355495356023312,
            0.012461923062801361,
            -0.028910048305988312,
            0.004040009807795286,
            0.025054432451725006,
            -0.059058777987957,
            -0.008082118816673756,
            -0.01429617591202259,
            0.021612044423818588,
            0.009365273639559746,
            -0.028699420392513275,
            -0.002565462375059724,
            0.03067433461546898,
            0.01906404457986355,
            -0.0064486609771847725,
            0.013829722069203854,
            0.02859129197895527,
            0.002530239522457123,
            -0.045793041586875916,
            -0.05660765618085861,
            0.007212799042463303,
            0.0036736938636749983,
            -0.015338658355176449,
            0.015097953379154205,
            0.01723450794816017,
            0.003352244384586811,
            -0.02551032230257988,
            0.007164620794355869,
            -0.012777606956660748,
            -0.00871728453785181,
            0.0004514619358815253,
            -0.0037326104938983917,
            -0.01893584057688713,
            0.03646417707204819,
            0.03269141539931297,
            -0.0080809211358428,
            -0.027813617140054703,
            0.00758631294593215,
            6.966198998270556e-05,
            -0.0005189552903175354,
            0.002937148092314601,
            0.02184516377747059,
            -0.03429815545678139,
            -0.01403205655515194,
            0.011545776389539242,
            -0.02920570597052574,
            0.012963785789906979,
            0.017126109451055527,
            0.0428440235555172,
            0.016921617090702057,
            -0.02371407113969326,
            -0.0309185441583395,
            0.009227427653968334,
            0.03096439316868782,
            -0.01702960580587387,
            -0.02897457219660282,
            0.029409872367978096,
            -0.02550383098423481,
            -0.03342064842581749,
            -0.008765941485762596,
            0.010058645159006119,
            -0.008837572298943996,
            -0.0008152778609655797,
            -0.02592647075653076,
            0.01622043550014496,
            -0.01656654104590416,
            -0.011120036244392395,
            0.02728395164012909,
            -0.0010426589287817478,
            -0.00014741682389285415,
            0.0376904234290123,
            0.014182884246110916,
            -0.015918541699647903,
            -0.01463137473911047,
            0.004364538937807083,
            0.004145307000726461,
            -0.040607303380966187,
            0.013294046744704247,
            0.024765117093920708,
            0.01915019191801548,
            0.02078847959637642,
            -0.021575720980763435,
            0.03518785536289215,
            -0.020785078406333923,
            -0.014837506227195263,
            -0.06255533546209335,
            -0.013783548958599567,
            0.009039890952408314,
            0.030312184244394302,
            -0.028148429468274117,
            0.000572252320125699,
            0.01764179766178131,
            -0.0107643473893404,
            0.0026588805485516787,
            -0.0031814174726605415,
            -0.015867404639720917,
            -0.026924125850200653,
            -0.015732787549495697,
            0.014098132960498333,
            0.03724953532218933,
            -0.033163219690322876,
            0.021578779444098473,
            0.0002470467006787658,
            -0.03591345623135567,
            0.02515139989554882,
            0.010337628424167633,
            -0.01909090019762516,
            -0.02584613487124443,
            0.013389919884502888,
            -0.002355618868023157,
            0.03861316666007042,
            0.02131350338459015,
            0.0159517340362072,
            -0.019774284213781357,
            -0.029118387028574944,
            -0.03078964352607727,
            0.02664090134203434,
            0.006841783411800861,
            -0.04515908658504486,
            0.01784578152000904,
            0.01057528704404831,
            0.05404878780245781,
            -0.025059446692466736,
            -0.0010277637047693133,
            0.006342717446386814,
            -0.002897218568250537,
            -0.016804970800876617,
            -0.011680771596729755,
            -0.021190833300352097,
            0.03085506334900856,
            0.00400786055251956,
            0.020545395091176033,
            -0.02983761765062809,
            0.014437882229685783,
            0.031203383579850197,
            0.008299840614199638,
            0.0231353547424078,
            0.011984572745859623,
            0.008880913257598877,
            0.020748034119606018,
            0.034044984728097916,
            0.008608502335846424,
            0.000702946912497282,
            0.010065224021673203,
            0.005066942423582077,
            -0.0278975497931242,
            -0.038604985922575,
            0.02352641522884369,
            -0.014243120327591896,
            0.02592443861067295,
            0.01538457814604044,
            -0.04667675867676735,
            0.03450116515159607,
            0.005717784631997347,
            0.015269685536623001,
            0.021361634135246277,
            -0.003424485679715872,
            0.01051325537264347,
            -0.007614223752170801,
            -0.0008310654666274786,
            -0.0024771203752607107,
            -0.013699186034500599,
            -0.025936344638466835,
            -0.024452265352010727,
            0.011098749004304409,
            0.025544466450810432,
            0.012889288365840912,
            -0.012174636125564575,
            5.6278575357282534e-05,
            -0.004594419617205858,
            0.02038211189210415,
            -0.006628042086958885,
            -0.01234875526279211,
            0.012559382244944572,
            -0.022198129445314407,
            -0.01349030714482069,
            0.000982847297564149,
            0.014861044473946095,
            0.03186846897006035,
            0.004639339633285999,
            0.02824944630265236,
            -0.00039225866203196347,
            -0.02383410558104515,
            0.016400691121816635,
            0.015180069021880627,
            -0.009188699536025524,
            0.01733621023595333,
            0.0005618630675598979,
            -0.0032066793646663427,
            0.003432248020544648,
            -0.0054645841009914875,
            0.017935436218976974,
            -0.06370791047811508,
            -0.02016756869852543,
            0.03066154569387436
        ],
        [
            -0.012021382339298725,
            -0.0024708150885999203,
            -0.03008943609893322,
            -0.03141051530838013,
            0.030621027573943138,
            0.011760208755731583,
            -0.0023426227271556854,
            0.013170410878956318,
            0.0056692673824727535,
            0.025067338719964027,
            -0.0310892965644598,
            0.0072369868867099285,
            0.010973815806210041,
            -0.01718730852007866,
            0.0020303374622017145,
            0.03724422678351402,
            -0.007003406994044781,
            -0.001808919245377183,
            0.00140665122307837,
            -0.009116937406361103,
            0.017729077488183975,
            -0.0006517500733025372,
            0.013758276589214802,
            0.04346536472439766,
            0.00303734396584332,
            0.011653794907033443,
            -0.059007879346609116,
            -0.04351069778203964,
            -0.023458240553736687,
            0.03190726414322853,
            0.02064448408782482,
            -0.0031843422912061214,
            0.01423503179103136,
            0.036913566291332245,
            -0.007698757108300924,
            -0.006120958831161261,
            0.027348840609192848,
            0.01622161455452442,
            0.02412853017449379,
            -0.0018693951424211264,
            -0.012733074836432934,
            -0.014767716638743877,
            0.02935102954506874,
            -0.020784813910722733,
            -0.015024915337562561,
            -0.005711110774427652,
            -0.03142936900258064,
            -0.048742104321718216,
            -0.02776705101132393,
            -0.004375628661364317,
            -0.00538304727524519,
            0.02025548927485943,
            -0.0016610801685601473,
            -0.011272395960986614,
            -0.022386010736227036,
            0.011565669439733028,
            -0.010928879491984844,
            -0.021351424977183342,
            -0.001617247937247157,
            0.03325503692030907,
            0.0006742039695382118,
            -0.026986490935087204,
            -0.009565040469169617,
            0.031060367822647095,
            0.023406043648719788,
            0.013302566483616829,
            -0.03540736436843872,
            0.0004009921394754201,
            0.0343448668718338,
            -0.04278382286429405,
            0.00894487090408802,
            0.030594127252697945,
            0.03463002294301987,
            0.0036604006309062243,
            0.024628378450870514,
            -0.007487354334443808,
            -0.03436080738902092,
            0.013708444312214851,
            -0.02010716125369072,
            0.009874282404780388,
            -0.015278535895049572,
            -0.00025456544244661927,
            0.0372544601559639,
            -0.0035982949193567038,
            -0.00459705013781786,
            0.017496677115559578,
            -0.020723514258861542,
            -0.03285646066069603,
            -0.02780887484550476,
            -0.0037237880751490593,
            -0.025945626199245453,
            -0.017144232988357544,
            0.010290563106536865,
            -0.039488036185503006,
            -0.004126667510718107,
            -0.004756009206175804,
            0.008654248900711536,
            -0.008356156758964062,
            -0.09792128205299377,
            -0.026615509763360023,
            0.026301125064492226,
            0.005867173429578543,
            -0.015791166573762894,
            0.002718833740800619,
            0.013447651639580727,
            0.025519197806715965,
            0.03173748776316643,
            -0.006250171456485987,
            0.013448268175125122,
            -0.004330361727625132,
            -0.03903011977672577,
            -0.02610461227595806,
            0.003912584390491247,
            0.004925331566482782,
            0.0020721741020679474,
            -0.008109462447464466,
            0.03137397766113281,
            -0.016261981800198555,
            0.011801494285464287,
            0.007134566083550453,
            0.013904411345720291,
            0.008104910142719746,
            0.011728646233677864,
            0.017332011833786964,
            0.006153720896691084,
            0.018841726705431938,
            -0.025484910234808922,
            -0.007556757424026728,
            -0.008258787915110588,
            -0.001905132899992168,
            -0.028935162350535393,
            -0.003480045823380351,
            -0.03438424691557884,
            0.024987289682030678,
            -0.02926524728536606,
            0.02590106427669525,
            0.03178933635354042,
            0.008909957483410835,
            -0.005747372750192881,
            0.03410154581069946,
            -0.014622623100876808,
            -0.030584825202822685,
            0.013842862099409103,
            0.017727907747030258,
            0.0004574557242449373,
            -0.04051224887371063,
            -0.008299180306494236,
            0.029829705134034157,
            -0.025723854079842567,
            0.03184103965759277,
            0.00907604955136776,
            -0.029489144682884216,
            -0.007066676858812571,
            0.01593436300754547,
            0.027618585154414177,
            0.02671090140938759,
            0.008416539989411831,
            -0.010607260279357433,
            0.007705779280513525,
            0.032819826155900955,
            -0.008151538670063019,
            0.004813733510673046,
            -0.007206253241747618,
            0.0024505278561264277,
            0.019969506189227104,
            0.0235590897500515,
            0.008486716076731682,
            -0.005017445422708988,
            0.02433009259402752,
            0.012740282341837883,
            -0.009280027821660042,
            0.01816355250775814,
            -0.002782208379358053,
            0.014409911818802357,
            0.017960574477910995,
            0.017375636845827103,
            0.02291857637465,
            0.011541392654180527,
            -0.012010200880467892,
            -0.0025184531696140766,
            0.00463151978328824,
            -0.024815421551465988,
            0.030923230573534966,
            0.010802408680319786,
            -0.03280116990208626,
            0.02321700192987919,
            0.02700800634920597,
            -0.03201897814869881,
            -0.04391174763441086,
            -0.0039803930558264256,
            -0.0015855777310207486,
            -0.004212952218949795,
            -0.0349368155002594,
            0.023614130914211273,
            0.021984891965985298,
            0.007046246901154518,
            0.018102863803505898,
            -0.015244523994624615,
            0.027142956852912903,
            0.0006875899853184819,
            0.008578610606491566,
            -0.025415988638997078,
            -0.026944603770971298,
            0.040624868124723434,
            -0.0901884213089943,
            -0.02719411626458168,
            -0.027043120935559273,
            0.024183163419365883,
            -0.012509768828749657,
            -0.021040629595518112,
            0.010819707065820694,
            -0.02950936369597912,
            -0.0063588619232177734,
            -0.015094771049916744,
            -0.01354932226240635,
            -0.015595139935612679,
            -0.034501682966947556,
            0.01743459142744541,
            0.006216817069798708,
            0.022210324183106422,
            -0.00791157130151987,
            0.02694631740450859,
            0.02865849994122982,
            0.020764529705047607,
            -0.0018886434845626354,
            0.06830710172653198,
            0.049484848976135254,
            0.030496882274746895,
            -0.037331901490688324,
            0.02994627319276333,
            -0.03506048768758774,
            -0.008815297856926918,
            -0.011109469458460808,
            -0.013379473239183426,
            0.001983865164220333,
            -0.0019348595524206758,
            0.017753243446350098,
            -0.01631072349846363,
            0.014019475318491459,
            -0.013548530638217926,
            0.03799230977892876,
            -0.0082590626552701,
            0.019084526225924492,
            0.02602892369031906,
            0.0026356149464845657,
            0.007635536137968302,
            0.02306642383337021,
            -0.005297556519508362,
            0.025059835985302925,
            -0.025653915479779243,
            -0.021479150280356407,
            0.02797226794064045,
            0.012982018291950226,
            0.028984274715185165,
            -0.006678630597889423,
            0.025947310030460358,
            -0.038028378039598465,
            0.041477181017398834,
            -0.005393774248659611,
            0.03132885321974754,
            -0.005564466118812561,
            0.02125147171318531,
            0.012350790202617645,
            0.015474830754101276,
            0.02575347013771534,
            -0.03318735212087631,
            0.012356876395642757,
            0.01872735098004341,
            0.020696992054581642,
            -0.017015274614095688,
            -0.0016291727079078555,
            0.024932879954576492,
            -0.008371960371732712,
            0.0005335338646546006,
            0.013095716014504433,
            0.010399005375802517,
            0.02282853052020073,
            0.01500679086893797,
            0.01683812215924263,
            -0.0007281648577190936,
            -0.01100870966911316,
            -0.004705619532614946,
            0.013905812054872513,
            -0.019714482128620148,
            0.007948814891278744,
            -0.0016201388789340854,
            0.00183232338167727,
            0.0393795445561409,
            -0.01162900310009718,
            -0.023569181561470032,
            -0.034118857234716415,
            0.01052397396415472,
            -0.027998901903629303,
            0.00587273808196187,
            0.02588283270597458,
            -0.02758585661649704,
            0.0162507351487875,
            0.006244281306862831,
            -0.03944046422839165,
            0.02673807181417942,
            0.026948509737849236,
            -0.005703833419829607,
            0.03705263510346413,
            0.004750585183501244,
            0.02767871879041195,
            0.018560754135251045,
            0.021191272884607315,
            0.0004663359432015568,
            -0.021706050261855125,
            0.0016464006621390581,
            0.0008836316410452127,
            0.003995908889919519,
            -0.03014417178928852,
            -0.013053234666585922,
            0.007846804335713387,
            -0.012103382498025894,
            -0.029149550944566727,
            0.01694348268210888,
            0.013901478610932827,
            0.004309783689677715,
            -0.01554152648895979,
            -0.038977302610874176,
            -0.028601163998246193,
            0.030091028660535812,
            -0.019417786970734596,
            0.01717403344810009,
            0.021616656333208084,
            -0.0106862997636199,
            -0.020534895360469818,
            -0.0019821752794086933,
            -0.0015475950203835964,
            -0.0066149793565273285,
            -0.04513676092028618,
            -0.0009905507322400808,
            -0.01725565828382969,
            -0.004379482939839363,
            -0.0002981043071486056,
            -0.03476370871067047,
            0.02255553938448429,
            -0.012628204189240932,
            -0.01445214357227087,
            -0.03134901076555252,
            0.005538626573979855,
            0.004802148789167404,
            0.011692941188812256,
            0.04989542067050934,
            -0.01944192498922348,
            0.019570793956518173,
            0.0210727471858263,
            0.006798191927373409,
            0.0003967974043916911,
            -0.08063582330942154,
            0.028150761500000954,
            0.002566564129665494,
            0.011110972613096237,
            -0.0025989015121012926,
            -0.01249981764703989,
            -0.01701691374182701,
            -0.0023571818601340055,
            0.015803514048457146,
            0.0020542365964502096,
            0.021646179258823395,
            -0.0025468170642852783,
            0.02450266107916832,
            -0.015315859578549862,
            0.015744367614388466,
            -0.02481495961546898,
            -0.030846847221255302,
            -0.026358451694250107,
            -0.0024132942780852318,
            -0.01477872859686613,
            0.03216118365526199,
            -0.011252699419856071,
            0.009699348360300064,
            0.02470657043159008,
            -0.02219763584434986,
            -0.018198292702436447,
            0.03910340741276741,
            -0.01964406482875347,
            0.018410446122288704,
            0.05422867834568024,
            0.016832556575536728,
            -0.021911369636654854,
            -0.012980258092284203,
            -0.05464247614145279,
            -0.02742590196430683,
            -0.02650519460439682,
            0.019176069647073746,
            -0.02496975101530552,
            -0.004342237953096628,
            -0.011120030656456947,
            0.008849806152284145,
            0.001273784670047462,
            -0.004325213376432657,
            0.0074596405029296875,
            0.00111534190364182,
            -0.0019180172821506858,
            0.012583054602146149,
            -0.02402833104133606,
            0.026992913335561752,
            0.022100204601883888,
            -0.0003951765538658947,
            0.006153615191578865,
            0.0016585057601332664,
            0.006425222847610712,
            0.018362516537308693,
            0.01631498523056507,
            0.02010093815624714,
            0.0040757739916443825,
            0.03716714307665825,
            -0.011127272620797157,
            0.016457216814160347,
            -0.026531342417001724,
            -0.017760569229722023,
            0.04679214581847191,
            -0.013139734044671059,
            0.020316388458013535,
            0.001937664463184774,
            -0.009429866448044777,
            0.006510596256703138,
            -0.01537100039422512,
            0.025083228945732117,
            -0.020288044586777687,
            -0.017596986144781113,
            0.0037675173953175545,
            -0.03151245787739754,
            -0.008640209212899208,
            -0.00027488882187753916,
            -0.015088990330696106,
            0.012894089333713055,
            0.020050490275025368,
            -0.03696148470044136,
            0.020471885800361633,
            -0.011478446424007416,
            -0.00039079930866137147,
            -0.015123793855309486,
            0.014423605985939503,
            0.0026815191376954317,
            0.01663672737777233,
            -0.019502587616443634,
            0.010244011878967285,
            -0.046064142137765884,
            0.007741570472717285,
            -0.01646219566464424,
            -0.028847133740782738,
            0.011674351058900356,
            0.027145568281412125,
            -0.02844955027103424,
            0.014090332202613354,
            0.01058220211416483,
            0.016266416758298874,
            0.02308771200478077,
            -0.033528078347444534,
            0.0045225112698972225,
            -0.008542896248400211,
            -0.008946122601628304,
            0.0028124675154685974,
            -0.024147039279341698,
            0.021125873550772667,
            -0.004618623293936253,
            0.004854824393987656,
            -0.013617012649774551,
            0.0022522739600390196,
            0.0036246427334845066,
            -0.010257098823785782,
            0.014105569571256638,
            -0.04766179993748665,
            -0.028435176238417625,
            0.02823023684322834,
            -0.011182955466210842,
            0.009131322614848614,
            -0.01844966597855091,
            -0.009396844543516636,
            0.002213266910985112,
            -0.006144961342215538,
            0.03890470787882805,
            -0.020775198936462402,
            0.020493483170866966,
            -0.018103130161762238,
            0.00341850146651268,
            0.009696130640804768,
            -0.0156579427421093,
            -0.043760232627391815,
            0.031732697039842606,
            0.009639762341976166,
            -0.01645411178469658,
            0.02889765426516533,
            -0.01158315222710371,
            -0.04651995748281479,
            -0.001120841596275568,
            -0.02389794960618019,
            -0.023984277620911598,
            -0.016606708988547325,
            0.028681887313723564,
            0.016079841181635857,
            -0.023511679843068123,
            -0.007738677319139242,
            -0.01475878432393074,
            -0.014284257777035236,
            -0.015360681340098381,
            -0.003533800132572651,
            -0.04204370081424713,
            0.028087850660085678,
            0.033732034265995026,
            0.02361568622291088,
            0.014764303341507912,
            -0.0010665456065908074,
            0.002235999796539545,
            -0.007966890931129456,
            0.004299309570342302,
            0.00835126917809248,
            0.029207618907094002,
            -0.010780966840684414,
            0.021716207265853882,
            0.0020747720263898373,
            -0.0321381650865078,
            0.00807128194719553,
            0.013425212353467941,
            -0.010644112713634968,
            -0.01980419270694256,
            -0.019482290372252464,
            -0.01960870809853077,
            -0.031149614602327347,
            -0.002498440910130739,
            -0.03829128295183182,
            -0.04224064201116562,
            -0.017796475440263748,
            -0.019084757193922997,
            0.007780066225677729,
            0.03600623831152916,
            0.018611207604408264,
            0.014293814077973366,
            -0.0293715987354517,
            0.023376470431685448,
            0.013295012526214123,
            -0.042188480496406555,
            -0.001790825743228197,
            -0.01722288504242897,
            -0.006850670091807842,
            0.02075296826660633,
            0.026154547929763794,
            -0.0024423652794212103,
            -0.010284599848091602,
            -0.002743901452049613,
            -0.03428594022989273,
            -0.00937271025031805,
            -0.0071154930628836155,
            0.011404319666326046,
            0.014249333180487156,
            -0.015862083062529564,
            0.01605166494846344,
            0.012874783016741276,
            -0.0076322369277477264,
            0.003511770162731409,
            0.009326633997261524,
            0.010009543970227242,
            0.023915553465485573,
            -0.021774524822831154,
            -0.0014753490686416626,
            -0.006927736569195986,
            -0.02454916015267372,
            -0.0014946311712265015,
            -0.008414974436163902,
            0.008257780224084854,
            -0.02634700946509838,
            0.014295102097094059,
            -0.029525382444262505,
            0.00043075522989965975,
            0.007891906425356865,
            0.012413734570145607,
            0.001028049155138433,
            -0.02451341040432453,
            -0.022252094000577927,
            0.02189897559583187,
            0.009130235761404037,
            -0.01761016622185707,
            -0.02353186532855034,
            0.011278939433395863,
            0.013591548427939415,
            0.020378798246383667,
            0.002673250390216708,
            -0.0016640060348436236,
            0.01566973142325878,
            0.00012617289030458778,
            -0.006368257105350494,
            0.007989014498889446,
            -0.003849609289318323,
            0.010712732560932636,
            -0.02185159921646118,
            -0.021020174026489258,
            0.03687906265258789,
            0.021750131621956825,
            0.030815253034234047,
            0.02082165889441967,
            -0.006371905095875263,
            -0.014915450476109982,
            0.009181194938719273,
            -0.003948308527469635,
            0.03586290776729584,
            -0.005897813942283392,
            -0.024743609130382538,
            0.003690507495775819,
            -0.026678163558244705,
            -0.025625014677643776,
            0.013093637302517891,
            -0.02319609560072422,
            -0.02037818729877472,
            -0.014784752391278744,
            -0.031018976122140884,
            -0.010910050012171268,
            -0.008700705133378506,
            -0.009108806028962135,
            0.008808370679616928,
            0.00043696747161448,
            -0.0033788171131163836,
            0.028570249676704407,
            -0.0002719653130043298,
            -0.007985196076333523,
            -0.016244368627667427,
            -0.0278551634401083,
            -0.059862494468688965,
            -0.03488592430949211,
            -0.01925518736243248,
            -0.02066567912697792,
            0.010287893936038017,
            -0.003905709832906723,
            0.02370976284146309,
            -0.036439914256334305,
            0.023855140432715416,
            0.030509840697050095,
            -0.005067762918770313,
            0.002627529203891754,
            -0.021814361214637756,
            -0.013149648904800415,
            -0.024725882336497307,
            0.008465591818094254,
            -0.03832332044839859,
            0.02200433611869812,
            -0.01251442451030016,
            -0.00464372243732214,
            0.02751775085926056,
            -0.03859062120318413,
            0.016376646235585213,
            0.004167801234871149,
            0.016152439638972282,
            0.03575768321752548,
            -0.021976901218295097,
            0.02496572956442833,
            -0.020664764568209648,
            0.007001492660492659,
            0.0010259252740070224,
            0.002334142103791237,
            -0.015087625943124294,
            0.006428123917430639,
            0.015617802739143372,
            0.033888161182403564,
            0.011110388673841953,
            0.012263062410056591,
            0.036860328167676926,
            -0.004344986751675606,
            -0.020912330597639084,
            0.02989572286605835,
            0.008238543756306171,
            -0.012643945403397083,
            -0.007235265336930752,
            -0.0051900711841881275,
            0.017246317118406296,
            0.0065180398523807526,
            -0.00704106455668807,
            0.02649104595184326,
            -0.011216642335057259,
            0.03124627098441124,
            0.011319694109261036,
            -0.02537631429731846,
            0.0042784507386386395,
            0.00034869505907408893,
            -0.021248096600174904,
            -0.016118571162223816,
            0.04088892787694931,
            0.023282475769519806,
            0.00672496622428298,
            0.026323379948735237,
            0.0008042161935009062,
            -0.0178381334990263,
            0.037846196442842484,
            0.014992988668382168,
            0.013678064569830894,
            -0.0003047619538847357,
            0.012160702608525753,
            0.013683794997632504,
            -0.043057553470134735,
            0.00725237000733614,
            0.005520411301404238,
            -0.00017299388127867132,
            0.005393114872276783,
            0.03834768757224083,
            0.009007045067846775,
            0.014160139486193657,
            -0.016999689862132072,
            0.012815425172448158,
            -0.014962979592382908,
            -0.015467165037989616,
            0.026916544884443283,
            0.01983555033802986,
            -0.01231853011995554,
            -0.011724615469574928,
            -0.03349677845835686,
            -0.009809593670070171,
            -0.006896371487528086,
            0.01718800887465477,
            -0.022707493975758553,
            0.026813842356204987,
            0.03792695328593254,
            -0.014777816832065582,
            -0.025799673050642014,
            -0.00026821435312740505,
            -0.022245056927204132,
            0.012021929956972599,
            0.002100727055221796,
            0.006484068930149078,
            -0.025682175531983376,
            -0.020597046241164207,
            0.013340998440980911,
            -0.03767680004239082,
            -0.025373289361596107,
            -0.013158919289708138,
            0.026048114523291588,
            -0.014762329868972301,
            -0.025465624406933784,
            -0.014253721572458744,
            0.022348416969180107,
            -0.004637144971638918,
            -0.009031950496137142,
            0.003113685641437769,
            -0.03860997036099434,
            0.017730114981532097,
            0.0022792513482272625,
            -0.012671536765992641,
            0.0031999133061617613,
            0.006027368362993002,
            0.021321887150406837,
            0.026038747280836105,
            -0.017383398488163948,
            -0.0005990253994241357,
            -0.027513867244124413,
            -0.02330981381237507,
            -0.03503083810210228,
            0.022856730967760086,
            0.008820765651762486,
            0.00943332351744175,
            -0.014551408588886261,
            -0.010991393588483334,
            0.024497520178556442,
            0.0021469746716320515,
            0.02230593003332615,
            0.035096555948257446,
            -0.006032294128090143,
            0.015131134539842606,
            0.02488153800368309,
            -0.020150020718574524,
            0.01428509596735239,
            -0.0338728204369545,
            -0.019209101796150208,
            -0.002354074502363801,
            0.011425909586250782,
            -0.0021672307047992945,
            -0.02064008265733719,
            0.013942237943410873,
            -0.010729406960308552,
            -0.011156804859638214,
            0.008758995682001114,
            0.015153089538216591,
            -0.003030471969395876,
            0.019417760893702507,
            -0.024893244728446007,
            0.00888423528522253,
            -0.034736305475234985,
            0.03296227753162384,
            0.018225837498903275,
            0.01485514547675848,
            -0.004229712300002575,
            0.02781372331082821,
            -0.016353683546185493,
            -0.017380809411406517,
            0.005290886852890253,
            0.028697023168206215,
            0.012071157805621624,
            0.035035789012908936,
            -0.045426663011312485,
            -0.023058883845806122,
            0.02942829206585884,
            -0.019209420308470726,
            0.02777603082358837,
            -0.03499813750386238,
            -0.006670468952506781,
            -0.032986417412757874,
            -0.006090014707297087,
            -0.001455715042538941,
            0.01108421478420496,
            -0.020970771089196205,
            -0.019787203520536423,
            -0.008356651291251183,
            -0.024626297876238823,
            -0.01623358204960823,
            -0.044521331787109375,
            -0.029910655692219734,
            -0.007936165668070316,
            8.190915832528844e-05,
            -0.009746713563799858,
            0.0032496999483555555,
            -0.006503645796328783,
            -0.013115194626152515,
            0.002456269459798932,
            0.0025679331738501787,
            -0.017442312091588974,
            0.027741188183426857,
            0.0034003632608801126,
            -0.017647383734583855,
            -0.003891606582328677,
            -0.003355544526129961,
            -0.036595311015844345,
            0.020067282021045685,
            -0.03082662634551525,
            0.03515079244971275,
            0.008915156126022339,
            -0.02648099884390831,
            -0.008542299270629883,
            -0.018610700964927673,
            0.009950283914804459,
            -0.004237037617713213,
            -0.015894528478384018,
            0.026717763394117355,
            0.027979107573628426,
            -0.017227882519364357,
            0.034427691251039505,
            0.021375304087996483,
            -0.0034097526222467422,
            -0.017602281644940376,
            0.018714670091867447,
            0.03594488650560379,
            -0.021424656733870506,
            -0.010428514331579208,
            0.024402828887104988,
            0.040817879140377045,
            -0.005205939058214426,
            0.011742619797587395,
            0.005657619331032038,
            0.036390114575624466,
            0.01774946041405201,
            0.02651325985789299,
            -0.025800377130508423,
            -0.009579702280461788,
            0.005108734592795372,
            -0.03011803701519966,
            -0.018230129033327103,
            -0.0074834758415818214,
            -0.018429040908813477,
            0.0018452599178999662,
            0.03398565202951431,
            -0.003920495975762606,
            0.020451834425330162,
            0.015114286914467812,
            0.020901087671518326,
            0.0024567795917391777,
            -0.001791930990293622,
            0.03278328478336334,
            0.017908859997987747,
            0.018470024690032005,
            0.0026882013771682978,
            -0.027826067060232162,
            0.0002824842231348157,
            0.005061587784439325,
            -0.031171895563602448,
            -0.009427378885447979,
            0.030791154131293297,
            0.04548170045018196,
            -0.016066424548625946,
            0.006661672610789537,
            0.03375552222132683,
            0.027823535725474358,
            -0.012360380962491035,
            0.008025752380490303,
            0.00043309436296112835,
            -0.008868574164807796,
            -0.041284117847681046,
            0.027654852718114853,
            -0.02377500757575035,
            -0.04257733374834061,
            0.02368212305009365,
            0.02365642786026001,
            0.03396670147776604,
            -0.010348196141421795,
            -0.0026537475641816854,
            -0.023616213351488113,
            -0.009512422606348991,
            -0.009247013367712498,
            0.028033144772052765,
            -0.026558350771665573,
            -0.014204118400812149,
            -0.03312103822827339,
            -0.024365512654185295,
            -0.025476813316345215,
            0.014844817109405994,
            0.01565220206975937,
            0.03549995645880699,
            0.01689104363322258,
            -0.017115846276283264,
            0.0019415607675909996,
            0.012215782888233662,
            -0.0044501544907689095,
            -0.037126414477825165,
            0.015854882076382637,
            0.023910144343972206,
            -0.00024457875406369567,
            -0.0027901504654437304,
            0.013571150600910187,
            0.01199771836400032,
            0.02517308108508587,
            -0.009549678303301334,
            -0.009108212776482105,
            0.014705941081047058,
            0.003483628388494253,
            0.007844965904951096,
            -0.0036175462882965803,
            -0.015312339179217815,
            0.005561014637351036,
            -0.03105437010526657,
            0.010645506903529167,
            0.0295000858604908,
            -0.021126676350831985,
            -0.022168898954987526,
            -0.018251368775963783,
            -0.010927933268249035,
            0.01576930843293667,
            0.0033590805251151323,
            -0.0020145901944488287,
            0.016429465264081955,
            0.02404733933508396,
            0.009158345870673656,
            0.004472521133720875,
            -0.0027943309396505356,
            0.002756659407168627,
            0.013685788959264755,
            0.02796202152967453,
            0.01071392372250557,
            0.026825476437807083,
            -0.022957507520914078,
            0.0012930920347571373,
            -0.009201811626553535,
            -0.00018707096751313657,
            -0.008104683831334114,
            0.025577649474143982,
            0.002856987761333585,
            0.013797485269606113,
            0.004915459081530571,
            0.02601650357246399,
            -0.023857735097408295,
            -0.009819571860134602,
            -0.046286385506391525,
            -0.002308756345883012,
            -0.02718442864716053,
            -0.039420053362846375,
            0.018100397661328316,
            0.012582473456859589,
            -0.030319632962346077,
            0.02580999955534935,
            0.024566154927015305,
            0.0008118418045341969,
            0.022610273212194443,
            0.01944756880402565,
            0.01731228455901146,
            -0.010435404255986214,
            0.0076629240065813065,
            0.02798674814403057,
            -0.014155106619000435,
            0.018457166850566864,
            0.006232217885553837,
            -0.00972311943769455,
            0.02403714694082737,
            -0.010033529251813889,
            -0.010429677553474903,
            -0.020086614415049553,
            0.010598533786833286,
            -0.017107922583818436,
            0.033224646002054214,
            0.01258578710258007,
            0.023276131600141525,
            -0.01823766529560089,
            0.02697950415313244,
            -0.0027543753385543823,
            0.013404385186731815,
            0.03292810171842575,
            0.022271225228905678,
            0.01829640008509159,
            -0.019678998738527298,
            -0.04253016784787178,
            -0.024490464478731155,
            0.014782548882067204,
            0.011252776719629765,
            -0.008916200138628483,
            -0.032680947333574295,
            -0.009372697211802006,
            0.00557818403467536,
            -0.02457263693213463,
            -0.045467063784599304,
            -0.029118552803993225,
            0.011729096993803978,
            0.019039880484342575,
            -0.010000993497669697,
            0.005240611266344786,
            -0.0210769884288311,
            0.006396387238055468,
            0.008972963318228722,
            -0.013599167577922344,
            0.03106212429702282,
            -0.018730882555246353,
            0.018985258415341377,
            -0.024993309751152992,
            -0.021225254982709885,
            -0.0018457998521625996,
            -0.0038553925696760416,
            -0.03905292600393295,
            0.032393842935562134,
            -0.010209360159933567
        ],
        [
            -0.0048522911965847015,
            -0.01603107526898384,
            0.02577892877161503,
            -0.016507282853126526,
            0.029523815959692,
            -0.04172896966338158,
            0.012939339503645897,
            -0.021009288728237152,
            0.03485456854104996,
            0.0068677193485200405,
            -0.013219374231994152,
            0.004257091321051121,
            -0.019747914746403694,
            0.0015702744713053107,
            -0.04108773544430733,
            0.010217410512268543,
            -0.035191215574741364,
            0.00940391793847084,
            -0.01966225542128086,
            -0.009789034724235535,
            0.011393305845558643,
            0.015941476449370384,
            -0.02581707201898098,
            0.021339276805520058,
            0.03785444051027298,
            -0.0061387256719172,
            0.029639720916748047,
            -0.024270666763186455,
            -0.002385747153311968,
            -0.043544694781303406,
            -0.021794989705085754,
            0.013104096055030823,
            -0.028204673901200294,
            -0.022675378248095512,
            0.00119585613720119,
            -0.003978710621595383,
            -0.033129360526800156,
            -0.010024848394095898,
            -0.016837941482663155,
            0.00441915076225996,
            0.013508341275155544,
            -0.008151273243129253,
            -0.019705671817064285,
            -0.02648910880088806,
            -0.01577814668416977,
            -0.016973081976175308,
            0.021667616441845894,
            0.04835465922951698,
            -0.027397779747843742,
            -0.03290357440710068,
            0.0387587770819664,
            0.004833179526031017,
            -0.08052948862314224,
            -0.007385172415524721,
            0.04404333606362343,
            0.0152876116335392,
            0.009576598182320595,
            0.02126145549118519,
            -0.02321094274520874,
            -0.020690396428108215,
            0.0040028952062129974,
            0.01527592446655035,
            -0.028982646763324738,
            -0.007384324446320534,
            0.004126721527427435,
            -0.01002368051558733,
            -0.020229538902640343,
            0.02900758571922779,
            0.020013974979519844,
            -0.009166456758975983,
            0.017194673418998718,
            0.0011249985545873642,
            0.012865375727415085,
            -0.009564841166138649,
            0.04608381167054176,
            0.027502987533807755,
            0.008163530379533768,
            -0.00830760970711708,
            -0.032384954392910004,
            -0.02274329960346222,
            -0.0025095376186072826,
            -0.04167098179459572,
            0.026910802349448204,
            -0.027882561087608337,
            0.021444108337163925,
            -0.008868453092873096,
            -0.04503502696752548,
            -0.008630529046058655,
            0.0034073435235768557,
            -0.008097886107861996,
            0.0215303935110569,
            0.0236034095287323,
            -0.05058855563402176,
            0.02668945863842964,
            -0.03078175149857998,
            -0.032398708164691925,
            0.010877006687223911,
            -0.017772851511836052,
            -0.06570892781019211,
            -0.014849306084215641,
            -0.02886815182864666,
            0.004968937952071428,
            0.015932636335492134,
            -0.0002736388414632529,
            -0.0019826889038085938,
            -0.01933828555047512,
            -0.012025181204080582,
            -0.03345460072159767,
            0.0068609341979026794,
            -0.02361355908215046,
            -0.03679488226771355,
            0.003118660533800721,
            -0.002176418900489807,
            -0.02658773772418499,
            -0.06086912378668785,
            0.009164873510599136,
            -0.029766665771603584,
            0.015356839634478092,
            0.004634056240320206,
            0.02753318101167679,
            -0.04331878200173378,
            0.03530122712254524,
            0.04521569609642029,
            0.03393901139497757,
            0.02470342442393303,
            0.030012592673301697,
            -0.0007089621503837407,
            0.009910674765706062,
            -0.016236089169979095,
            0.013902715407311916,
            0.006535045802593231,
            0.016501832753419876,
            -0.013784118928015232,
            -0.005764436442404985,
            -0.04363483563065529,
            0.007514423690736294,
            -0.005581162869930267,
            -0.007805743254721165,
            -0.013167125172913074,
            -0.021133100613951683,
            -0.01848495937883854,
            0.012816599570214748,
            0.03266644850373268,
            0.015200982801616192,
            -0.015030214563012123,
            0.024358026683330536,
            0.042107954621315,
            0.021745840087532997,
            0.0151061424985528,
            -0.005430929828435183,
            0.012932791374623775,
            0.04395236819982529,
            0.028030844405293465,
            0.031709570437669754,
            0.03096957318484783,
            -0.0047714789398014545,
            0.0010941630462184548,
            0.0011248734081164002,
            -0.017621004953980446,
            -0.03166987746953964,
            0.0037366438191384077,
            -0.025285417214035988,
            0.0025964740198105574,
            0.013513151556253433,
            -0.003855574643239379,
            0.02988060936331749,
            -0.0029037347994744778,
            0.022265657782554626,
            -9.703854448162019e-05,
            0.01336810365319252,
            0.02763436548411846,
            -0.016035204753279686,
            -0.02242361381649971,
            -0.03257115185260773,
            0.0028177283238619566,
            0.03524799272418022,
            0.008758663199841976,
            0.030116582289338112,
            0.022608984261751175,
            0.015018276870250702,
            0.01015475858002901,
            0.011298519559204578,
            0.03478975594043732,
            0.020690850913524628,
            -0.030259741470217705,
            0.008404902182519436,
            0.02622940205037594,
            0.0019575555343180895,
            0.029238374903798103,
            -0.002627758076414466,
            0.05018676072359085,
            0.02122691087424755,
            -0.032654859125614166,
            -0.011422308161854744,
            0.00836899969726801,
            0.013727174140512943,
            0.010045956820249557,
            -0.012609212659299374,
            -0.01367843709886074,
            0.018582843244075775,
            0.004521084018051624,
            0.014178798533976078,
            -0.0017998218536376953,
            -0.023531518876552582,
            0.013535047881305218,
            -0.0003268117143306881,
            -0.003929740283638239,
            -0.022753095254302025,
            -0.028501052409410477,
            0.0017107607563957572,
            -0.015035685151815414,
            -0.0003237646596971899,
            -0.003465489484369755,
            0.03291354700922966,
            0.004170313477516174,
            0.03088904358446598,
            0.011694885790348053,
            -0.01924515701830387,
            0.027833973988890648,
            -0.007780177518725395,
            0.02022738941013813,
            0.03909682109951973,
            -0.004618785344064236,
            -0.011840037070214748,
            0.03587891161441803,
            0.02778724581003189,
            0.0030625839717686176,
            0.013532925397157669,
            -0.00871802493929863,
            0.031695712357759476,
            0.024161284789443016,
            -0.004567141178995371,
            -0.02607930637896061,
            0.039340097457170486,
            -0.030612077564001083,
            -0.02228151448071003,
            -0.047450318932533264,
            -0.025140956044197083,
            -0.024545609951019287,
            0.04414065554738045,
            -0.01658802665770054,
            -0.005123125854879618,
            0.04163084179162979,
            -0.014871660619974136,
            0.025421859696507454,
            0.012912245467305183,
            0.012051686644554138,
            -0.0013634376227855682,
            -0.0310630202293396,
            0.016672389581799507,
            0.028156161308288574,
            0.007301681209355593,
            0.006077211815863848,
            0.02212546393275261,
            -0.02102501131594181,
            -0.0009940116433426738,
            -0.014727368019521236,
            0.015137062408030033,
            0.03238436579704285,
            -0.005581702571362257,
            0.030233269557356834,
            0.02620374970138073,
            0.014047822915017605,
            0.0034923255443573,
            0.007030162028968334,
            -0.005794160068035126,
            -0.02412935346364975,
            0.023937776684761047,
            -0.027264609932899475,
            0.025559403002262115,
            -0.015641886740922928,
            0.013330372050404549,
            0.0014000015798956156,
            0.016298914328217506,
            0.039908260107040405,
            0.004971323534846306,
            0.013966958969831467,
            0.02233087457716465,
            -0.029505759477615356,
            0.02572745457291603,
            0.00346957053989172,
            -0.020033542066812515,
            -0.014718905091285706,
            0.0317009836435318,
            -0.01032483484596014,
            0.0008244847995229065,
            0.02528311312198639,
            0.030221816152334213,
            0.028172306716442108,
            0.01582692191004753,
            0.024694666266441345,
            -0.013987994752824306,
            0.016389843076467514,
            0.0024579123128205538,
            0.021653080359101295,
            -0.029542438685894012,
            0.006699322257190943,
            0.0126617681235075,
            -0.00303118908777833,
            0.005160829052329063,
            0.009006190113723278,
            4.079599602846429e-05,
            -0.0021443429868668318,
            0.020347073674201965,
            0.029454480856657028,
            -0.02638726495206356,
            0.030364157631993294,
            -0.018349485471844673,
            -0.0012145680375397205,
            -0.045378461480140686,
            0.029119782149791718,
            -0.0005370677099563181,
            0.013437635265290737,
            -0.013360421173274517,
            -0.015441570430994034,
            0.03491587936878204,
            0.0352359265089035,
            -0.002258152235299349,
            -0.007844441570341587,
            0.018671320751309395,
            -0.013693477027118206,
            0.04173048213124275,
            0.020486274734139442,
            -0.05294027552008629,
            -0.020182712003588676,
            0.04374187812209129,
            -0.04163813963532448,
            0.03751479089260101,
            0.01889512687921524,
            0.018351124599575996,
            -0.014501985162496567,
            -0.021731272339820862,
            0.004350909497588873,
            0.03592785820364952,
            0.0036367413122206926,
            -0.00326844141818583,
            -0.01610047183930874,
            -0.035086460411548615,
            -0.00150755001232028,
            -0.010477145202457905,
            -0.02841484546661377,
            -0.012491224333643913,
            0.02752436138689518,
            0.03854646533727646,
            -0.010515104979276657,
            0.035702429711818695,
            -0.0173154529184103,
            0.00994055438786745,
            0.02191903255879879,
            0.02573838271200657,
            -0.02652747556567192,
            0.03160839155316353,
            -0.019469598308205605,
            -0.02180320955812931,
            0.01978795789182186,
            0.02954925410449505,
            0.03748808056116104,
            -0.00862923450767994,
            0.01704883575439453,
            0.016672557219862938,
            0.012235837988555431,
            -0.010484402067959309,
            0.030313147231936455,
            -0.027238313108682632,
            0.007742818910628557,
            0.016751963645219803,
            0.00958269089460373,
            -0.01289792824536562,
            0.009148163720965385,
            0.000703787081874907,
            0.022966718301177025,
            0.026473121717572212,
            -0.012315967120230198,
            0.029988408088684082,
            0.013688294216990471,
            0.0021995806600898504,
            -0.01164789218455553,
            0.00805503036826849,
            0.031537313014268875,
            0.003600077470764518,
            -0.042701780796051025,
            0.013513095676898956,
            0.018329784274101257,
            -0.008398551493883133,
            0.033658869564533234,
            0.01611744984984398,
            0.01917961984872818,
            -0.023154865950345993,
            -0.002545840572565794,
            -0.020855072885751724,
            0.029546672478318214,
            0.004488600417971611,
            -0.026650501415133476,
            0.00783812627196312,
            -0.0643291249871254,
            0.02147345058619976,
            0.024596868082880974,
            -0.019295135512948036,
            0.0056043751537799835,
            0.028608599677681923,
            -0.005089848767966032,
            0.011999225243926048,
            -0.001396517502143979,
            0.0066885449923574924,
            -0.0013432027772068977,
            0.030334368348121643,
            0.0008583383169025183,
            0.007948996499180794,
            -0.016482854261994362,
            0.013245297595858574,
            -0.01782262697815895,
            0.017917940393090248,
            -0.03490360081195831,
            0.02787737175822258,
            -0.028804097324609756,
            -0.02727382816374302,
            0.025205742567777634,
            0.004863666370511055,
            0.002657080302014947,
            -0.0058874390088021755,
            -0.04127814993262291,
            -0.017650939524173737,
            -0.06407269090414047,
            0.02582504227757454,
            0.03457830473780632,
            0.008366933092474937,
            0.017301499843597412,
            0.0037263056728988886,
            0.015649249777197838,
            0.009649551473557949,
            0.015743330121040344,
            -0.01110883429646492,
            0.04455505684018135,
            -0.040501318871974945,
            0.02304220385849476,
            0.003610704094171524,
            -0.009402692317962646,
            0.01174191478639841,
            0.014934266917407513,
            -0.04627954214811325,
            -0.00010707519686548039,
            -0.01705249957740307,
            0.02015654370188713,
            0.007473556790500879,
            0.01258007250726223,
            0.03468675538897514,
            -0.0278509259223938,
            -0.0359029695391655,
            -0.017333228141069412,
            -0.022618411108851433,
            0.008920985274016857,
            -0.01182954479008913,
            0.0005765027599409223,
            -0.0231647752225399,
            0.025288142263889313,
            -0.01848217099905014,
            0.02383599989116192,
            0.022114014253020287,
            -0.01004511583596468,
            -0.027247019112110138,
            -0.03316254913806915,
            -0.0187368206679821,
            -0.0031071023549884558,
            -0.02963423728942871,
            -0.01093381829559803,
            -0.0007811595569364727,
            0.029712090268731117,
            0.014403233304619789,
            0.012154895812273026,
            0.027063878253102303,
            0.027803514152765274,
            -0.012272513471543789,
            -0.015281075611710548,
            -0.000606076733674854,
            0.01380743645131588,
            -0.02296886034309864,
            -0.003495003329589963,
            0.006988314911723137,
            0.044430676847696304,
            0.012256153859198093,
            -0.017268063500523567,
            0.013056880794465542,
            0.00289113144390285,
            0.026214126497507095,
            -0.0006638953345827758,
            0.01125798188149929,
            -0.02386980876326561,
            -0.031014470383524895,
            0.05487485229969025,
            -0.021106122061610222,
            -0.012431340292096138,
            0.00987979955971241,
            0.02758108265697956,
            -0.020666243508458138,
            -0.003367586759850383,
            0.02440023422241211,
            0.02899016998708248,
            0.029214512556791306,
            -0.0022884851787239313,
            0.026181606575846672,
            -0.023911988362669945,
            -0.024039682000875473,
            0.013641905039548874,
            0.0034666857682168484,
            0.029814228415489197,
            0.02253778465092182,
            0.006060346495360136,
            0.02947036363184452,
            -0.018440738320350647,
            -0.015609724447131157,
            -0.015304728411138058,
            -0.04324743524193764,
            0.024412624537944794,
            0.00713526364415884,
            0.016475750133395195,
            -0.017253564670681953,
            -0.01765410043299198,
            -0.017767051234841347,
            -0.019572891294956207,
            0.014169896021485329,
            0.011041039600968361,
            0.008511870168149471,
            0.0002989900822285563,
            -0.022667478770017624,
            0.027952468022704124,
            -0.004447909537702799,
            -0.008329967968165874,
            -0.005508659407496452,
            -0.013577359728515148,
            -0.016305364668369293,
            -0.006715850438922644,
            0.001239123521372676,
            -0.019369957968592644,
            0.01866246946156025,
            0.02216639742255211,
            -0.030083848163485527,
            0.005983953829854727,
            0.03128476068377495,
            -0.023450426757335663,
            0.016003886237740517,
            0.016451392322778702,
            0.020862506702542305,
            0.0018774863565340638,
            -0.05978287383913994,
            -0.004309433046728373,
            0.020781349390745163,
            0.013467679731547832,
            -0.005948412232100964,
            0.015429376624524593,
            -0.03366481140255928,
            0.024697957560420036,
            0.008196849375963211,
            -0.0009957990841940045,
            0.008493722416460514,
            -0.018090760335326195,
            0.0174564179033041,
            0.028328943997621536,
            0.003153450321406126,
            0.003203789470717311,
            0.038930557668209076,
            -0.026365498080849648,
            -0.019254250451922417,
            0.030869275331497192,
            -0.011454683728516102,
            0.00020833424059674144,
            0.025179479271173477,
            -0.028140315786004066,
            0.028584590181708336,
            0.02407468482851982,
            -0.018344713374972343,
            -0.004803886637091637,
            -0.013795229606330395,
            -0.02656954899430275,
            -0.002263573696836829,
            0.036302659660577774,
            -0.029157355427742004,
            0.01736728474497795,
            -0.025539331138134003,
            -0.0029630488716065884,
            0.021926499903202057,
            0.02441726252436638,
            -0.0409574881196022,
            -0.021984487771987915,
            -0.02464277669787407,
            -0.0326174832880497,
            -0.05238398537039757,
            -0.02478371188044548,
            0.039207011461257935,
            0.003614091081544757,
            0.028786709532141685,
            -0.005378266796469688,
            0.023627864196896553,
            -0.011463231407105923,
            0.03163329139351845,
            -0.0008004895062185824,
            -0.01927696168422699,
            0.051801055669784546,
            0.019391288980841637,
            -0.015591579489409924,
            0.011447764933109283,
            -0.040039628744125366,
            0.008573867380619049,
            6.605777161894366e-05,
            0.018595950677990913,
            -0.015786083415150642,
            0.00018596839800011367,
            -0.011642367579042912,
            -0.02355429343879223,
            0.029444904997944832,
            -0.009145183488726616,
            -0.0001435951271560043,
            -0.02501692809164524,
            -0.007715180981904268,
            -0.007751212455332279,
            0.02588013745844364,
            -0.042201004922389984,
            0.00768221216276288,
            -0.0017032766481861472,
            -0.017580078914761543,
            0.021916890516877174,
            0.0012128783855587244,
            0.027061451226472855,
            -0.01583293452858925,
            -0.0065374188125133514,
            0.0005982494330964983,
            -0.021899165585637093,
            -0.003851991845294833,
            -0.013179095461964607,
            -0.01931614987552166,
            0.005291637033224106,
            0.032754719257354736,
            0.016124507412314415,
            0.003937141504138708,
            -0.012895436957478523,
            0.01216660626232624,
            0.03424724191427231,
            -0.009133894927799702,
            0.023162376135587692,
            0.017122402787208557,
            -0.022032009437680244,
            0.040107034146785736,
            -0.014514054171741009,
            0.0027960925363004208,
            -0.06395640224218369,
            -0.028748691082000732,
            -0.002032585209235549,
            0.034229159355163574,
            -0.01642697863280773,
            0.02443993277847767,
            -0.0037836702540516853,
            0.019995996728539467,
            -0.029397597536444664,
            -0.023249635472893715,
            0.0024367105215787888,
            0.029343506321310997,
            0.009816912934184074,
            -0.02650608867406845,
            -0.007521829567849636,
            -0.00036404014099389315,
            -0.008746202103793621,
            -0.01911262795329094,
            -0.03479526564478874,
            0.03185074031352997,
            0.027401279658079147,
            0.0058873845264315605,
            -0.0014815493486821651,
            0.0247169341892004,
            0.007383132353425026,
            0.011381272226572037,
            -9.79466421995312e-05,
            0.019748371094465256,
            0.0037048172671347857,
            -0.013530721887946129,
            0.05376018211245537,
            -0.00834173709154129,
            -0.008296988904476166,
            0.004430775064975023,
            -0.022378388792276382,
            0.024524804204702377,
            -0.03325583413243294,
            -0.037838373333215714,
            -0.012372528202831745,
            0.018196530640125275,
            0.03311264514923096,
            -0.013903677463531494,
            -0.0023790516424924135,
            -0.04401744529604912,
            0.014526118524372578,
            -0.009485066868364811,
            -0.020138883963227272,
            -0.015314619988203049,
            0.023196540772914886,
            -0.0053792353719472885,
            -0.012271126732230186,
            0.021132655441761017,
            -0.03801479563117027,
            0.023667655885219574,
            -0.011634023860096931,
            0.009584887884557247,
            0.017347067594528198,
            0.010244210250675678,
            -0.021445022895932198,
            0.03270627558231354,
            0.02559799514710903,
            -0.019628139212727547,
            -9.11158203962259e-05,
            0.02138202264904976,
            -0.018012288957834244,
            0.0272545013576746,
            -0.029615143314003944,
            0.03610564395785332,
            0.022884225472807884,
            0.02473209798336029,
            0.010091062635183334,
            -0.01959729939699173,
            -0.015413214452564716,
            0.03408126160502434,
            0.011640884913504124,
            0.011997752822935581,
            0.016501354053616524,
            0.02970895916223526,
            0.009661559946835041,
            0.018621448427438736,
            -0.014131763018667698,
            0.01752675138413906,
            -0.013055502437055111,
            0.029042592272162437,
            -0.01577139087021351,
            -0.01461238507181406,
            -0.010020765475928783,
            0.00169507903046906,
            -0.014640623703598976,
            0.013592753559350967,
            0.014727121219038963,
            0.04574944078922272,
            0.0008459849050268531,
            0.0072299824096262455,
            0.02571040205657482,
            0.054369181394577026,
            0.010724012739956379,
            0.025519171729683876,
            0.020967626944184303,
            -0.0027131710667163134,
            0.0007621568511240184,
            -0.01517042052000761,
            -0.0267521683126688,
            0.038851987570524216,
            -0.011635961942374706,
            -0.025426076725125313,
            0.015980709344148636,
            0.01800099015235901,
            -0.024571672081947327,
            0.02504320628941059,
            -0.047186870127916336,
            -0.008566620759665966,
            0.024529581889510155,
            -0.0021130936220288277,
            -0.06462004035711288,
            0.01622748002409935,
            0.023635974153876305,
            -0.020415686070919037,
            0.02283383533358574,
            0.04063090682029724,
            0.025494711473584175,
            -0.04578179121017456,
            0.004166468977928162,
            -0.01254392508417368,
            0.028905419632792473,
            -0.021713243797421455,
            -0.0228778924793005,
            0.03774666041135788,
            -0.002796330489218235,
            0.004719493445008993,
            0.04118810221552849,
            -0.032366879284381866,
            0.00554442685097456,
            0.013622689992189407,
            -0.04615641012787819,
            -0.014713051728904247,
            -0.003944560419768095,
            0.02878424897789955,
            0.02827576920390129,
            -0.009468489326536655,
            -0.005218554753810167,
            0.028342630714178085,
            -0.019367607310414314,
            0.008120070211589336,
            0.030428670346736908,
            0.047681745141744614,
            0.009030347689986229,
            -0.017364058643579483,
            -0.01755535416305065,
            0.005251279566437006,
            0.02017812617123127,
            -0.023063788190484047,
            -0.0065782940946519375,
            0.020847279578447342,
            -0.013091160915791988,
            0.010343070141971111,
            0.0236688032746315,
            0.014646782539784908,
            -0.013321317732334137,
            -0.002799197332933545,
            0.013196557760238647,
            0.0004353525582700968,
            -0.011507140472531319,
            -0.01425542775541544,
            0.015549038536846638,
            -0.004982261452823877,
            0.006766943261027336,
            -0.044174935668706894,
            -0.006183788646012545,
            0.013690189458429813,
            0.013715752400457859,
            -0.02018662914633751,
            0.016896046698093414,
            -0.020133350044488907,
            0.00033242086647078395,
            0.00912807323038578,
            -0.011756683699786663,
            0.02045736275613308,
            -0.03457420691847801,
            0.04021650552749634,
            -0.0005109394551254809,
            -0.005159519147127867,
            -0.00721863703802228,
            -0.010110456496477127,
            0.010174267925322056,
            -0.005071068182587624,
            -0.008995898067951202,
            -0.012540003284811974,
            0.006160478573292494,
            -0.0187799371778965,
            0.00879501923918724,
            -0.024769019335508347,
            0.028586501255631447,
            -0.005962986499071121,
            0.00815888587385416,
            -0.004740208853036165,
            0.0014708169037476182,
            -0.0321665033698082,
            -0.004955495707690716,
            -0.04197169840335846,
            0.03685447573661804,
            0.03103325515985489,
            0.008091084659099579,
            0.005849798675626516,
            0.006497255526483059,
            -0.028957536444067955,
            -0.0251651369035244,
            0.023029997944831848,
            0.01639208011329174,
            -0.009253197349607944,
            0.009026008658111095,
            0.0038215748500078917,
            -0.0068130227737128735,
            0.012551593594253063,
            0.03333311527967453,
            -0.039676912128925323,
            -0.03772923722863197,
            0.02285008691251278,
            0.0103257792070508,
            -0.07734032720327377,
            -0.030085794627666473,
            -0.018762623891234398,
            -0.017586342990398407,
            -0.01771831326186657,
            0.00023823941592127085,
            -0.028460118919610977,
            0.0254483874887228,
            -0.00605475390329957,
            -0.030714126303792,
            -0.029267510399222374,
            0.02419135719537735,
            0.007085954770445824,
            0.02603844180703163,
            0.00980081781744957,
            -0.08174512535333633,
            0.01880662702023983,
            0.032944802194833755,
            -0.00010658510291250423,
            -0.01516786403954029,
            -0.003007221966981888,
            0.015587547793984413,
            -0.01878749206662178,
            0.0042565264739096165,
            0.027216805145144463,
            0.030331771820783615,
            0.030532902106642723,
            -0.007432010490447283,
            -0.014016980305314064,
            -0.029579773545265198,
            -0.0699475035071373,
            0.014176069758832455,
            0.014779562130570412,
            0.02260643057525158,
            -0.02593427523970604,
            0.007864762097597122,
            0.008348144590854645,
            -0.005182522349059582,
            -0.01973334141075611,
            0.008544889278709888,
            0.03942935913801193,
            -0.012101476080715656,
            -0.009088239632546902,
            -0.030347928404808044,
            0.011116869747638702,
            -0.051153238862752914,
            -0.0008145018364302814,
            0.012747170403599739,
            -0.04497414082288742,
            0.021290434524416924,
            -0.034228771924972534,
            -0.005270078312605619,
            0.05325694754719734,
            0.017225973308086395,
            0.01630353182554245,
            0.00811966322362423,
            -0.0030826248694211245,
            -0.01659751683473587,
            -0.014477888122200966,
            0.017494022846221924,
            0.026244957000017166,
            -0.0031818924471735954,
            -0.0036559738218784332,
            -0.02179587632417679,
            0.020906558260321617,
            0.006218307185918093,
            -0.03407750278711319,
            0.02804306522011757,
            0.00966197531670332,
            -0.01924378052353859,
            0.0029792310670018196,
            -0.005351864267140627,
            -0.01607607863843441,
            0.01943027228116989,
            -0.01742449402809143,
            -0.017012640833854675,
            0.023933378979563713,
            -0.025193601846694946,
            0.023972447961568832,
            0.0018684251699596643,
            0.02177996002137661,
            -4.412418638821691e-05,
            0.030428605154156685,
            0.006726287771016359,
            -0.022532083094120026,
            -0.005509085021913052,
            -0.04803445562720299,
            0.019621364772319794,
            0.01284845918416977,
            0.0002494465734343976,
            0.0030499077402055264,
            0.014168063178658485,
            -0.008034534752368927,
            -0.015117332339286804,
            -0.007710253354161978,
            -0.02121707610785961,
            0.0032508892472833395,
            -0.008170939050614834,
            -0.02384604886174202,
            -0.034040167927742004,
            -0.010481171309947968,
            -0.007191396784037352,
            0.007441955152899027,
            -0.04703721031546593,
            -0.006079133599996567,
            0.03752625733613968,
            -0.02538101002573967,
            -0.006718544755131006,
            0.016909467056393623,
            -0.010416303761303425,
            -0.014729204587638378,
            0.011602349579334259,
            -0.012147105298936367,
            -0.0012510119704529643,
            0.012851160019636154,
            0.023480543866753578,
            -0.02070780284702778,
            0.022203316912055016,
            -0.03094419278204441,
            0.02409539930522442,
            -0.0203415434807539,
            -0.008428916335105896,
            -0.020335275679826736,
            0.016992807388305664,
            -0.038708653301000595,
            -0.015205728821456432,
            -0.00431146239861846,
            -0.0038531858008354902,
            -0.008768085390329361,
            -0.003560647601261735,
            -0.008024805225431919,
            0.03558805212378502,
            0.026451271027326584,
            -0.01567148230969906,
            0.02067691832780838,
            -0.014983742497861385,
            -0.03265269100666046,
            -0.02945910207927227,
            -0.023851754143834114,
            0.02050558477640152,
            -0.008182435296475887,
            0.009233083575963974,
            0.008125994354486465,
            -0.009724379517138004,
            0.004591974429786205,
            -0.02883763425052166,
            0.03683657571673393,
            0.012501434423029423,
            -0.002103254897519946,
            0.03434772416949272,
            0.007901299744844437,
            -0.02575606480240822,
            0.003577076131477952,
            -0.0020633956883102655,
            -0.0028494163416326046,
            0.004157767631113529,
            0.018796565011143684,
            0.014806111343204975,
            0.005931383464485407,
            -0.004970476496964693,
            -0.022860076278448105,
            0.021774033084511757
        ],
        [
            -0.018378283828496933,
            -0.03448590263724327,
            -0.01680396869778633,
            0.043274763971567154,
            0.00869797170162201,
            0.015622791834175587,
            0.02777678519487381,
            -0.017156964167952538,
            0.0220255758613348,
            -0.022392714396119118,
            0.0018000762211158872,
            0.005485385190695524,
            0.023008186370134354,
            0.028490526601672173,
            0.03694170340895653,
            -0.0007002924103289843,
            -0.044095657765865326,
            0.028197554871439934,
            0.018912537023425102,
            -0.036880604922771454,
            -0.014673281461000443,
            -0.03943758085370064,
            0.028060726821422577,
            0.014979994855821133,
            -0.014085083268582821,
            0.045821357518434525,
            0.006320655345916748,
            0.040417950600385666,
            -0.046505406498909,
            -0.03422479331493378,
            -0.021037058904767036,
            -0.0062732091173529625,
            -0.009840956889092922,
            0.002373630180954933,
            0.019056245684623718,
            -0.04364322870969772,
            0.016711393371224403,
            -0.01302950456738472,
            0.004261375404894352,
            0.01607435941696167,
            0.0202687568962574,
            0.021096687763929367,
            0.02102283202111721,
            -0.008817166090011597,
            0.02291715517640114,
            0.03596116974949837,
            -0.007779593113809824,
            0.018273914232850075,
            -0.025531085208058357,
            -0.06769516319036484,
            -0.034684918820858,
            0.03761826455593109,
            -0.03472454100847244,
            -0.00661555677652359,
            0.037194326519966125,
            0.03767949342727661,
            -0.014101031236350536,
            0.010195807553827763,
            -0.00625962158665061,
            0.010492373257875443,
            -0.04337020218372345,
            0.016183851286768913,
            0.014687255956232548,
            0.003145290771499276,
            -0.004491849336773157,
            0.004675059113651514,
            -0.0224414374679327,
            -0.003985990304499865,
            0.05365707352757454,
            -0.018795860931277275,
            -0.021071726456284523,
            -0.026757294312119484,
            0.011661617085337639,
            -0.010752002708613873,
            0.007473298814147711,
            -0.0066679297015070915,
            -0.021698730066418648,
            -0.03425144776701927,
            0.009737462736666203,
            -0.0232912115752697,
            0.017016422003507614,
            0.036032725125551224,
            0.009613257832825184,
            -0.004797324538230896,
            -0.035913024097681046,
            0.013767565600574017,
            0.014138664118945599,
            0.016785630956292152,
            0.004880111664533615,
            -0.0577528178691864,
            -0.011384404264390469,
            -0.006249300204217434,
            -0.0300137922167778,
            0.028740378096699715,
            -0.013389102183282375,
            -0.07199619710445404,
            0.013166732154786587,
            0.04121716320514679,
            -0.05693582445383072,
            -0.020739063620567322,
            0.017064426094293594,
            0.0017180278664454818,
            -0.028373919427394867,
            0.0017661236925050616,
            0.008276157081127167,
            -0.02005564421415329,
            -0.007781432010233402,
            0.026561159640550613,
            0.0003932897816412151,
            -0.008502444252371788,
            0.0001741937594488263,
            -0.0728495791554451,
            -0.016837703064084053,
            -0.012719395570456982,
            0.06370650976896286,
            0.022401655092835426,
            0.03172164410352707,
            0.01964459754526615,
            0.012935708276927471,
            0.03875061497092247,
            0.03496034815907478,
            0.021448742598295212,
            -0.06134733557701111,
            -0.022494366392493248,
            0.020507613196969032,
            -0.027078380808234215,
            0.03033372201025486,
            -0.02358979545533657,
            0.000611805880907923,
            0.020525144413113594,
            -0.005293064750730991,
            0.011325327679514885,
            -0.02014341577887535,
            -0.016088221222162247,
            -0.01671607978641987,
            -0.027394693344831467,
            -0.08377458155155182,
            0.0030461803544312716,
            -0.030797939747571945,
            -0.00822039321064949,
            0.0008273839484900236,
            0.028705868870019913,
            -0.036403853446245193,
            -0.006600453983992338,
            -0.004227236844599247,
            0.031101403757929802,
            0.003614343237131834,
            -0.0034212227910757065,
            0.05286802351474762,
            -0.009604769758880138,
            0.015288491733372211,
            0.057720787823200226,
            -0.024410076439380646,
            0.054091524332761765,
            0.02903214655816555,
            0.002342157531529665,
            -0.01897912658751011,
            -0.02760239876806736,
            0.027519188821315765,
            -0.019170021638274193,
            0.0015931014204397798,
            -0.032158687710762024,
            0.020810263231396675,
            -0.04044949263334274,
            0.01791868358850479,
            -0.05869962275028229,
            -0.0649462640285492,
            0.0287433173507452,
            -0.013332311995327473,
            0.005082979332655668,
            0.010501224547624588,
            0.040572017431259155,
            -0.05685081332921982,
            0.017939627170562744,
            -0.0037389842327684164,
            0.017122946679592133,
            -0.0389028899371624,
            0.014529848471283913,
            0.03105003386735916,
            -0.018090005964040756,
            -0.034011296927928925,
            0.024287397041916847,
            -0.020690888166427612,
            -0.0039450800977647305,
            -0.02823636122047901,
            0.031669653952121735,
            -0.04920618236064911,
            -0.02508181519806385,
            -0.007999967783689499,
            -0.031133413314819336,
            0.011600284837186337,
            -0.009356160648167133,
            0.008584615774452686,
            -0.01248882245272398,
            -0.022060347720980644,
            0.02913210168480873,
            -0.016885101795196533,
            0.022679965943098068,
            0.018149686977267265,
            0.011123161762952805,
            -0.017235085368156433,
            0.00019152167078573257,
            -0.056082990020513535,
            -0.0270200464874506,
            -0.05671025812625885,
            -0.008400377817451954,
            -0.004681769758462906,
            -0.01411685161292553,
            -0.044890910387039185,
            -0.002019071253016591,
            -0.01737452670931816,
            0.010653480887413025,
            0.00962925236672163,
            0.011645189486443996,
            0.026485994458198547,
            0.006463846657425165,
            -0.027350950986146927,
            -0.03514176234602928,
            -0.0019423753255978227,
            -0.025493215769529343,
            0.020027553662657738,
            -0.007868116721510887,
            -0.003871478606015444,
            0.008994550444185734,
            -0.031352393329143524,
            -0.04824206605553627,
            0.03548388183116913,
            -0.030415676534175873,
            0.004487562924623489,
            -0.037740301340818405,
            -0.01919504441320896,
            0.03398467227816582,
            -0.01866566576063633,
            -0.0051465872675180435,
            -0.03534466400742531,
            0.02651461586356163,
            -0.081541508436203,
            -0.0435306578874588,
            -0.044683102518320084,
            -0.026507416740059853,
            0.00449792668223381,
            -0.01759137399494648,
            0.01530502550303936,
            0.004052292089909315,
            0.04080396518111229,
            -0.0293643306940794,
            -0.008715798147022724,
            0.02433193475008011,
            0.005615645553916693,
            0.015313674695789814,
            -0.005627619568258524,
            0.055818911641836166,
            -0.030188746750354767,
            0.026495134457945824,
            0.01559491641819477,
            0.0016744780587032437,
            -0.006593890953809023,
            -0.013474823907017708,
            0.05404938757419586,
            -0.010821161791682243,
            -0.013149794191122055,
            0.04271186515688896,
            0.019460279494524002,
            -0.0027685000095516443,
            0.01251702569425106,
            -0.03696284070611,
            -0.002378369914367795,
            -0.032052259892225266,
            0.028112461790442467,
            -0.01783711090683937,
            -0.04802447557449341,
            0.0037430759984999895,
            0.010655784048140049,
            -0.019467921927571297,
            0.028054779395461082,
            0.006845465395599604,
            0.03996003046631813,
            0.02463478408753872,
            -0.03970284387469292,
            -0.06769020855426788,
            0.026759782806038857,
            -0.01569509133696556,
            -0.026169419288635254,
            0.017113005742430687,
            -0.024474743753671646,
            -0.02058659866452217,
            0.01075760368257761,
            -0.03783353418111801,
            0.013035261072218418,
            -0.005620548967272043,
            -8.992033690446988e-05,
            -0.029224267229437828,
            -0.020254647359251976,
            0.02266049012541771,
            0.03324620798230171,
            -0.016328472644090652,
            -0.02867414988577366,
            0.021957550197839737,
            0.05415742099285126,
            0.03484136983752251,
            -0.05056612566113472,
            -0.01906835287809372,
            -0.02434159256517887,
            0.023752469569444656,
            -0.02176227793097496,
            0.027913786470890045,
            0.0046095289289951324,
            0.02621818333864212,
            -0.010828942991793156,
            0.04000042751431465,
            0.043856874108314514,
            0.013255498372018337,
            0.012103067710995674,
            0.013906849548220634,
            0.003922204952687025,
            -0.02036035992205143,
            0.029704377055168152,
            -0.017605261877179146,
            -0.04027194529771805,
            -0.04161671921610832,
            -0.05439203605055809,
            -0.0517536923289299,
            0.03699410706758499,
            0.01020027231425047,
            0.007975788787007332,
            0.028836412355303764,
            0.05399281159043312,
            0.026760077103972435,
            0.00039286958053708076,
            -0.005094120744615793,
            0.022479090839624405,
            0.03814007714390755,
            0.01794440671801567,
            0.012952196411788464,
            0.03182024508714676,
            0.02171226218342781,
            -0.0015540176536887884,
            -0.024459755048155785,
            0.020797953009605408,
            0.017007680609822273,
            0.023922769352793694,
            -0.026700908318161964,
            0.0008505899459123611,
            0.020590562373399734,
            0.04532480984926224,
            0.03315207362174988,
            -0.05940520390868187,
            0.013035125099122524,
            -0.03683808073401451,
            0.019835228100419044,
            -0.03651190549135208,
            -0.07603901624679565,
            0.029415274038910866,
            -0.027706878259778023,
            0.0032101115211844444,
            -0.025155723094940186,
            -0.026380417868494987,
            0.03180677071213722,
            0.0053188009187579155,
            -0.01717565208673477,
            0.016072392463684082,
            -0.01493105012923479,
            -0.002787245437502861,
            0.04003563150763512,
            -0.01871120184659958,
            -0.0012956365244463086,
            0.019186750054359436,
            -0.0005362632218748331,
            -0.025965016335248947,
            -0.01676408387720585,
            -0.034756626933813095,
            -0.011532296426594257,
            0.04020063206553459,
            0.03936685249209404,
            -0.0017549528274685144,
            0.0008258788730017841,
            0.007539336569607258,
            0.0016788106877356768,
            0.049782246351242065,
            -0.005072799511253834,
            0.0016548073617741466,
            -0.03309594467282295,
            -0.03690087050199509,
            0.021266959607601166,
            -0.015687692910432816,
            0.0002495415392331779,
            -0.008953341282904148,
            0.024031002074480057,
            -0.03543185070157051,
            0.038053225725889206,
            0.005325075704604387,
            -0.032318294048309326,
            0.020113475620746613,
            0.018375001847743988,
            -0.07951591163873672,
            0.01736418902873993,
            -0.013748401775956154,
            0.03039224073290825,
            -0.030814366415143013,
            -0.07303163409233093,
            -0.02868499979376793,
            -0.02013276517391205,
            0.014328398741781712,
            0.008474905975162983,
            0.02424277924001217,
            0.03073841705918312,
            -0.009040356613695621,
            0.014431098476052284,
            0.03617281839251518,
            0.03127734363079071,
            -0.02284819632768631,
            0.011016901582479477,
            0.0013167004799470305,
            -0.00914070662111044,
            0.04489477351307869,
            0.001996550476178527,
            0.017850400879979134,
            0.09816993772983551,
            0.01271155383437872,
            -0.033059194684028625,
            0.019658707082271576,
            -0.029448453336954117,
            -0.0847521424293518,
            0.001275761635042727,
            -0.022313276305794716,
            0.007473943755030632,
            0.07125045359134674,
            0.0480354018509388,
            -0.028179962188005447,
            0.03266278654336929,
            -0.045027174055576324,
            -0.00022221391554921865,
            0.007597305346280336,
            0.01215148065239191,
            0.004622704349458218,
            0.04978715255856514,
            0.03339352831244469,
            0.0031548987608402967,
            0.0017647427739575505,
            0.04015934467315674,
            -0.028772592544555664,
            0.0029078659135848284,
            -0.006295567844063044,
            0.0176842100918293,
            -0.020127475261688232,
            -0.001586779486387968,
            -0.03420416638255119,
            0.0686572939157486,
            -0.0038842177018523216,
            -0.014373354613780975,
            -0.003901999443769455,
            0.02024073153734207,
            -0.010826097801327705,
            0.008967499248683453,
            -0.03345475718379021,
            0.03841093182563782,
            -0.008536739274859428,
            0.013376669958233833,
            -0.037513695657253265,
            -0.021042725071310997,
            -0.027016950771212578,
            -0.047965288162231445,
            -0.015608636662364006,
            0.04853939265012741,
            0.017645778134465218,
            -0.0062203253619372845,
            -0.014229384250938892,
            0.05326955020427704,
            0.025582265108823776,
            -0.0017500324174761772,
            -0.017658023163676262,
            0.010690493509173393,
            0.00730986800044775,
            -0.013162623159587383,
            0.028955329209566116,
            0.024859853088855743,
            0.030211206525564194,
            0.029087930917739868,
            -0.012022892013192177,
            0.0056637926027178764,
            -0.05058126151561737,
            0.028046131134033203,
            -0.045259520411491394,
            -0.021928591653704643,
            0.03539736568927765,
            -0.010045390576124191,
            -0.029919486492872238,
            -0.035881321877241135,
            -0.045256566256284714,
            -0.01499567087739706,
            0.004490946419537067,
            0.003994893282651901,
            -0.011932511813938618,
            -0.01912800595164299,
            0.03254496678709984,
            0.01242335420101881,
            0.040913842618465424,
            -0.030316971242427826,
            -0.004220238886773586,
            0.024681227281689644,
            -0.02383836731314659,
            0.007493872195482254,
            -0.007875040173530579,
            -0.0583098903298378,
            -0.013395248912274837,
            0.025229696184396744,
            -0.03289736062288284,
            0.005244548432528973,
            -0.0025553859304636717,
            -0.057610515505075455,
            0.021856987848877907,
            0.04768190160393715,
            -0.01921621896326542,
            0.01859625242650509,
            -0.032472118735313416,
            0.008139962330460548,
            -0.00045159258297644556,
            -0.022385012358427048,
            -0.00485369423404336,
            0.009162797592580318,
            -0.04370580241084099,
            -0.028563903644680977,
            -0.024659451097249985,
            0.0011527107562869787,
            0.032350461930036545,
            -0.028866086155176163,
            0.026404734700918198,
            0.010039163753390312,
            0.01959974691271782,
            -0.06726497411727905,
            0.03192203491926193,
            0.016208134591579437,
            -0.03460656479001045,
            0.005147006828337908,
            -0.04248640686273575,
            -0.013249495066702366,
            -0.0049312240444123745,
            -0.028391845524311066,
            -0.018945472314953804,
            -0.016998765990138054,
            0.038059573620557785,
            0.00911001767963171,
            0.007968964986503124,
            0.006974705960601568,
            0.03363899886608124,
            0.046159397810697556,
            0.011127416975796223,
            0.026201484724879265,
            0.0013782823225483298,
            0.016224844381213188,
            0.015626994892954826,
            0.015519694425165653,
            0.007761264219880104,
            0.007573581300675869,
            0.017844023182988167,
            -0.019369183108210564,
            -0.04759569838643074,
            -0.001795192714780569,
            0.0060596042312681675,
            0.010917489416897297,
            -0.006201710551977158,
            -0.020914580672979355,
            -0.008891385048627853,
            -0.015543697401881218,
            0.02609110064804554,
            -0.003444527741521597,
            -0.04296010360121727,
            0.013222645968198776,
            0.0478169247508049,
            0.022465456277132034,
            0.012676498852670193,
            -0.008655388839542866,
            0.025815553963184357,
            -0.006174260750412941,
            0.08311353623867035,
            0.014340098947286606,
            0.01716589741408825,
            -0.0056024882942438126,
            0.002749641425907612,
            -0.053057651966810226,
            -0.03523171693086624,
            0.021126532927155495,
            -0.0012731031747534871,
            -0.006787012331187725,
            -0.0027131636161357164,
            -0.00011466924479464069,
            -0.016320988535881042,
            -0.04442184045910835,
            -0.003935782238841057,
            -0.007630246225744486,
            0.048765480518341064,
            -0.004451125394552946,
            -0.008891889825463295,
            0.02910391427576542,
            0.012101812288165092,
            -0.023798761889338493,
            0.029954826459288597,
            -0.008617017418146133,
            0.017994428053498268,
            0.054709356278181076,
            0.02539505437016487,
            -0.01737278513610363,
            0.02231404557824135,
            -0.03871571645140648,
            -0.013821200467646122,
            0.00919597502797842,
            -0.017452215775847435,
            -0.013872397132217884,
            -0.009904892183840275,
            -0.008251738734543324,
            -0.012483712285757065,
            -0.01472767349332571,
            0.03576098755002022,
            0.016287900507450104,
            0.018368562683463097,
            -0.037406422197818756,
            0.01293835137039423,
            0.020396344363689423,
            0.020101182162761688,
            0.006953119300305843,
            -0.0553504079580307,
            -0.042303018271923065,
            -0.022906027734279633,
            -0.024521419778466225,
            -0.02481524832546711,
            -0.02560574747622013,
            -0.012165786698460579,
            0.009715503081679344,
            0.0047349995002150536,
            0.019027581438422203,
            -0.041256390511989594,
            -0.007800414226949215,
            -0.0032838215120136738,
            0.019857849925756454,
            0.01166535448282957,
            -0.04213370755314827,
            -0.01580657809972763,
            -0.06118713319301605,
            -0.00221260660327971,
            0.02045310102403164,
            -0.00319529022090137,
            -0.03652649745345116,
            0.015499087050557137,
            -0.010181654244661331,
            -0.0018965572817251086,
            -0.007313525769859552,
            -0.04128618165850639,
            -0.016585785895586014,
            0.039524417370557785,
            -0.02308768965303898,
            0.025476716458797455,
            0.013574719429016113,
            -0.05560698360204697,
            -0.02886899560689926,
            0.0025492727290838957,
            -0.0057108583860099316,
            0.06446828693151474,
            -0.00473669171333313,
            0.023864535614848137,
            -0.01473724003881216,
            0.019504187628626823,
            0.009905034676194191,
            0.017424071207642555,
            -0.002997597446665168,
            0.001138063962571323,
            0.0301665011793375,
            -0.003794317366555333,
            0.00788820069283247,
            0.03445800021290779,
            -0.0286041758954525,
            0.0016041958006098866,
            -0.010241127572953701,
            -0.013989264145493507,
            -0.043692611157894135,
            0.0199769027531147,
            -0.005097304470837116,
            -0.054299838840961456,
            0.024307606741786003,
            -0.017442604526877403,
            0.005193913355469704,
            0.04663616418838501,
            0.03569892793893814,
            0.017027750611305237,
            -0.010439511388540268,
            -0.060062382370233536,
            0.03041808493435383,
            0.0038660401478409767,
            -0.032108161598443985,
            -0.01982704922556877,
            0.0268259234726429,
            0.008140837773680687,
            0.02658121846616268,
            0.06544229388237,
            -0.012728824280202389,
            0.025243638083338737,
            0.014556278474628925,
            0.014849863946437836,
            -0.03682095929980278,
            -0.006988381035625935,
            -0.005115625914186239,
            0.02482900582253933,
            -0.027469802647829056,
            0.01108214259147644,
            0.03387119248509407,
            0.004775345325469971,
            -0.007347490638494492,
            -0.03439601883292198,
            -0.013764960691332817,
            0.008651325479149818,
            -0.016018925234675407,
            0.04758113622665405,
            0.0035565572325140238,
            0.007936501875519753,
            -0.003887395141646266,
            -0.0036904008593410254,
            -0.05197310075163841,
            -0.05139397829771042,
            0.01148685161024332,
            -0.017754627391695976,
            -0.008791478350758553,
            -0.0005352834705263376,
            0.021266935393214226,
            0.0035978362429887056,
            0.013677441515028477,
            0.025819677859544754,
            -0.01678542234003544,
            0.00015025102766230702,
            -0.05513199046254158,
            -0.009048993699252605,
            0.01001905370503664,
            -0.05954962596297264,
            0.025033030658960342,
            0.015585314482450485,
            -0.002514234045520425,
            0.011066297069191933,
            0.002503166673704982,
            -0.00240572658367455,
            -0.015780039131641388,
            -0.021195264533162117,
            0.03757280483841896,
            -0.011056593619287014,
            0.010559999383985996,
            -0.03085867315530777,
            0.00796204712241888,
            0.025714905932545662,
            -0.03211332485079765,
            -0.04627338796854019,
            0.012892489321529865,
            -0.027452783659100533,
            0.02113247476518154,
            0.02675851248204708,
            -0.03264349699020386,
            0.0025707774329930544,
            0.046179838478565216,
            -0.03249743953347206,
            -0.0175610538572073,
            0.020099684596061707,
            -0.0421067513525486,
            -0.07475462555885315,
            0.004722756799310446,
            -0.02294996939599514,
            -0.0305368322879076,
            0.004806228447705507,
            0.00935802236199379,
            0.020770162343978882,
            0.02742421068251133,
            -0.043406978249549866,
            0.016210993751883507,
            -0.010491861961781979,
            0.02689364179968834,
            0.009975757449865341,
            -0.03605588525533676,
            0.008811160922050476,
            -0.024436231702566147,
            -0.022739747539162636,
            0.025515886023640633,
            0.009894592687487602,
            0.048023805022239685,
            -0.03671317175030708,
            -0.005725538823753595,
            0.03329433873295784,
            0.027101783081889153,
            0.02280796691775322,
            -0.06617468595504761,
            -0.03187097609043121,
            -0.004990322515368462,
            -0.006275323685258627,
            0.027937358245253563,
            -0.025209510698914528,
            -0.005482459906488657,
            0.005308336578309536,
            0.010558510199189186,
            -0.02040306106209755,
            -0.049342475831508636,
            -0.024395529180765152,
            0.028830887749791145,
            0.03317754715681076,
            -0.045396603643894196,
            -0.022011611610651016,
            -0.004844375420361757,
            -0.05231728404760361,
            -0.024362841621041298,
            -0.02228768728673458,
            0.0391358807682991,
            -0.044964682310819626,
            9.108613085118122e-06,
            0.029180660843849182,
            -0.0007806936046108603,
            -0.06538107991218567,
            -0.03128618374466896,
            0.01759001612663269,
            -0.062386706471443176,
            0.00817551277577877,
            -0.011737601831555367,
            0.0338471345603466,
            -0.001357580185867846,
            -0.0014733911957591772,
            -0.011498202569782734,
            -0.003468587761744857,
            0.05501699820160866,
            0.018904339522123337,
            -0.049561381340026855,
            0.0024934387765824795,
            -0.018971337005496025,
            -0.013775261119008064,
            0.0006497966242022812,
            0.02048555575311184,
            0.009726868011057377,
            -0.023463618010282516,
            -0.0071287439204752445,
            0.0007188969175331295,
            -0.00012706748384516686,
            -0.0185294970870018,
            -0.0010729903588071465,
            0.0687422901391983,
            -0.06556832790374756,
            0.020150620490312576,
            0.006534322630614042,
            -0.026712564751505852,
            -0.0162496455013752,
            0.010785205289721489,
            -0.022353583946824074,
            0.02123503014445305,
            0.02985932119190693,
            -0.012125179171562195,
            -0.042544350028038025,
            0.004643404856324196,
            0.0009724643314257264,
            0.004700822755694389,
            -0.007204880006611347,
            -0.012915149331092834,
            0.02028028480708599,
            0.036516085267066956,
            0.008478814736008644,
            -0.00042812267201952636,
            0.0040513984858989716,
            -0.016805453225970268,
            0.02982516773045063,
            0.07870931923389435,
            -0.015721909701824188,
            -0.09236952662467957,
            -0.00635173637419939,
            -0.01605178229510784,
            0.0038572773337364197,
            0.02063019387423992,
            0.012378165498375893,
            -0.007471759337931871,
            -0.002230557147413492,
            -0.007390325423330069,
            0.04230991750955582,
            -0.004083176143467426,
            0.01657123491168022,
            -0.035989634692668915,
            0.04404755309224129,
            -0.016893669962882996,
            0.023811127990484238,
            -0.011280284263193607,
            -0.008925402536988258,
            0.023994671180844307,
            -0.027232576161623,
            0.004609817638993263,
            0.023686550557613373,
            0.023056505247950554,
            0.030772563070058823,
            0.024254608899354935,
            -0.05495039373636246,
            0.02236069366335869,
            -0.008968804031610489,
            0.006195849273353815,
            0.021663105115294456,
            -0.002029511844739318,
            0.008296437561511993,
            0.028088383376598358,
            0.06240074336528778,
            -0.05086715891957283,
            0.011885570362210274,
            0.03893328085541725,
            -0.03272630274295807,
            0.020833350718021393,
            0.04096297174692154,
            0.0421726256608963,
            0.011604712344706059,
            0.03173740953207016,
            -0.04279574006795883,
            0.030222700908780098,
            0.03706367313861847,
            0.014951911754906178,
            -0.0015743463300168514,
            0.015251953154802322,
            -0.025742417201399803,
            0.01958542875945568,
            -0.06409410387277603,
            -0.013190262950956821,
            0.03432069718837738,
            0.03393298014998436,
            -0.011868812143802643,
            0.027455823495984077,
            -0.03608176112174988,
            -0.005254045594483614,
            0.010456711985170841,
            -0.024678725749254227,
            0.004404918756335974,
            -0.0011791923316195607,
            -0.01332864910364151,
            0.05340757593512535,
            -0.011015952564775944,
            -0.010428858920931816,
            -0.012868957594037056,
            -0.020661363378167152,
            0.019520923495292664,
            -0.041095905005931854,
            -0.00437199417501688,
            -0.007644030265510082,
            -0.007290669251233339,
            0.00419475045055151,
            -0.015739206224679947,
            0.01303938776254654,
            -0.042708657681941986,
            0.040417999029159546,
            0.019057879224419594,
            0.0036230108235031366,
            -0.016082940623164177,
            -0.021866532042622566,
            0.019804012030363083,
            -0.010641258209943771,
            0.0008310604025609791,
            -0.0020479958038777113,
            0.008551817387342453,
            0.00890564825385809,
            0.013417242094874382,
            -0.027667677029967308,
            -0.011557643301784992,
            -0.003197983605787158,
            -0.0025331221986562014,
            0.015969593077898026,
            0.00923930760473013,
            -0.009968552738428116,
            0.010172581300139427,
            -0.037262212485075,
            -0.012156973592936993,
            -0.01684994250535965,
            -0.06018780171871185,
            0.03298843279480934,
            -0.02143622189760208,
            -0.05597594007849693,
            0.01793735660612583,
            0.044582072645425797,
            -0.028191765770316124,
            -0.025409342721104622,
            3.22760820381518e-06,
            -1.3609776033263188e-05,
            0.015822796151041985,
            -0.0016197010409086943,
            0.038236841559410095,
            -0.050936099141836166,
            0.0012525165220722556,
            -0.05230395123362541,
            -0.04245024919509888,
            0.0008818411151878536,
            -0.007985816337168217,
            0.028305599465966225,
            -0.004017734434455633,
            0.010819414630532265,
            -0.040909357368946075,
            0.005920774769037962,
            -0.03168748319149017,
            0.006754052359610796,
            0.017463263124227524,
            0.011392418295145035,
            -0.029705850407481194,
            0.023420259356498718,
            0.01219925470650196,
            -0.01038444135338068,
            0.021950457245111465,
            0.006252012215554714,
            0.03105335310101509,
            -0.057914476841688156,
            0.01936974935233593,
            0.003432768862694502,
            -0.008433426730334759,
            -0.024490687996149063,
            0.062183696776628494,
            -0.01094275712966919,
            -0.004139551427215338,
            -0.001558938529342413,
            0.0223214253783226,
            -0.03980236500501633,
            -0.025830106809735298,
            0.05114422366023064,
            -0.03039998561143875,
            -0.006289868149906397,
            -0.009367124177515507,
            -0.006760111544281244,
            -0.0263399425894022,
            0.026131294667720795,
            -0.06109798327088356,
            -0.018655691295862198,
            -0.020497217774391174,
            0.013591427356004715,
            0.00046741688856855035,
            0.03161510080099106,
            0.027853097766637802,
            -0.0006769835017621517,
            0.07055985927581787
        ],
        [
            0.017343709245324135,
            0.0035771895200014114,
            -0.022079696878790855,
            -0.007993576116859913,
            0.012402660213410854,
            0.023257870227098465,
            -0.04602310061454773,
            -0.00403544120490551,
            -0.013547263108193874,
            -0.008946020156145096,
            -0.0002166534395655617,
            0.015444879420101643,
            0.04817323014140129,
            -0.03878303989768028,
            0.0013323836028575897,
            -0.04863167554140091,
            -0.03067122958600521,
            0.018270161002874374,
            -0.030627116560935974,
            0.0024979915469884872,
            0.043629296123981476,
            0.021473003551363945,
            -0.006225190591067076,
            -0.012812419794499874,
            0.010542640462517738,
            -0.021984145045280457,
            -0.022818611934781075,
            0.002865992719307542,
            -0.046476900577545166,
            -0.02372829243540764,
            -0.017972923815250397,
            0.001323205535300076,
            0.00465512927621603,
            0.018737390637397766,
            0.04969014972448349,
            0.01863827556371689,
            -0.00884952861815691,
            -0.003829812165349722,
            -0.015908364206552505,
            -0.023791546002030373,
            0.016996027901768684,
            -0.009158952161669731,
            0.010651715099811554,
            -0.02420290745794773,
            0.03727735951542854,
            -0.006027699913829565,
            0.00024745691916905344,
            -0.03183843940496445,
            0.008423665538430214,
            0.05047297477722168,
            -0.01465926505625248,
            -0.05239740014076233,
            -0.02590031735599041,
            -0.007499872241169214,
            0.012796919792890549,
            0.018665403127670288,
            -0.009694444015622139,
            -0.02666534297168255,
            -0.014306191354990005,
            -0.029346289113163948,
            0.023625072091817856,
            0.0029004819225519896,
            0.004614728502929211,
            -0.0004932141164317727,
            -0.008523761294782162,
            0.044354695826768875,
            0.03090127930045128,
            -0.03029009886085987,
            0.02670224756002426,
            -0.0035915346816182137,
            -0.02704203873872757,
            -0.021695148199796677,
            -0.0013373661786317825,
            -0.030501047149300575,
            -0.017949597910046577,
            0.021935230121016502,
            -0.04212542250752449,
            -0.04987562820315361,
            0.001886072102934122,
            0.0341373085975647,
            0.007173400837928057,
            -0.0023570372723042965,
            -0.013366884551942348,
            -0.009051308035850525,
            0.004864300601184368,
            0.036240361630916595,
            0.022832252085208893,
            0.034237802028656006,
            0.004302477464079857,
            0.031635530292987823,
            -0.02274521440267563,
            0.017187487334012985,
            -0.010240532457828522,
            -0.016943005844950676,
            0.024261245504021645,
            0.004319337662309408,
            0.007678533438593149,
            0.005080412141978741,
            -0.10717170685529709,
            0.03430134803056717,
            0.010644843801856041,
            0.014899167232215405,
            0.00488307373598218,
            -0.04081301763653755,
            0.015337102115154266,
            -0.010256461799144745,
            0.018410008400678635,
            0.023278115317225456,
            0.04966980218887329,
            0.020480159670114517,
            -0.03075948730111122,
            0.0029876965563744307,
            0.017346877604722977,
            -0.003020326606929302,
            0.008126972243189812,
            -0.011797545477747917,
            -0.018998803570866585,
            -0.03075508214533329,
            0.021452315151691437,
            0.006665663328021765,
            0.029675409197807312,
            -0.0010701916180551052,
            -0.07864055782556534,
            -0.0019056815654039383,
            -0.02500668726861477,
            0.003047277219593525,
            0.014130666851997375,
            0.016583865508437157,
            -0.01677757501602173,
            -0.024017268791794777,
            0.024073218926787376,
            0.003036431036889553,
            -0.06400656700134277,
            0.054498568177223206,
            0.01480005495250225,
            -0.022991523146629333,
            0.024068208411335945,
            0.01682496815919876,
            -0.02552674524486065,
            -0.008956661447882652,
            -0.007001738529652357,
            0.018905021250247955,
            -0.017268888652324677,
            -0.011721872724592686,
            0.048807334154844284,
            0.013574252836406231,
            0.03878166526556015,
            -0.001770487055182457,
            -0.029666118323802948,
            -0.028605405241250992,
            0.04219566285610199,
            -0.0768614113330841,
            -0.028725944459438324,
            0.05696902424097061,
            0.014202911406755447,
            0.03677527606487274,
            0.01588675007224083,
            -0.013516281731426716,
            -0.006002680864185095,
            -0.06670743972063065,
            0.026061320677399635,
            0.030934859067201614,
            0.020032711327075958,
            -0.01347343623638153,
            0.022383172065019608,
            -0.0032108959276229143,
            0.02057303860783577,
            -0.007182379253208637,
            0.0023692704271525145,
            0.009819415397942066,
            0.003968613222241402,
            0.022816212847828865,
            0.05214381217956543,
            -0.004272597376257181,
            -0.0002678156888578087,
            0.011197681538760662,
            0.004128572065383196,
            0.03717023506760597,
            0.015377535484731197,
            0.05501094087958336,
            -0.010781681165099144,
            -0.006039190571755171,
            0.02068491093814373,
            0.004916674457490444,
            -0.044429320842027664,
            -0.00043728138552978635,
            -0.03860961273312569,
            -0.022501735016703606,
            -0.02656932733952999,
            0.01945490948855877,
            -0.07959199696779251,
            0.0067996671423316,
            -0.0411098450422287,
            -0.0010493086883798242,
            0.022451572120189667,
            -0.031148342415690422,
            0.028666021302342415,
            -0.004963090643286705,
            -0.003858407260850072,
            -0.03399614244699478,
            -0.0009378583054058254,
            -0.010917148552834988,
            0.010445669293403625,
            0.0323607362806797,
            0.043565064668655396,
            0.008909091353416443,
            -0.01898251287639141,
            -0.04546661302447319,
            0.004305952228605747,
            -0.03773602470755577,
            -0.028340421617031097,
            -0.030219845473766327,
            -0.04476529359817505,
            -0.006402306258678436,
            -0.011826680973172188,
            -0.016867591068148613,
            0.0034769156482070684,
            -0.007033380679786205,
            -0.02899313159286976,
            -0.012925529852509499,
            0.002490080427378416,
            -0.05197863653302193,
            0.031739190220832825,
            0.009380924515426159,
            0.0021881868597120047,
            0.034123633056879044,
            -0.030031410977244377,
            0.011728862300515175,
            -0.03303298354148865,
            -0.03165631741285324,
            0.012431145645678043,
            0.03971607983112335,
            -0.01004279125481844,
            -0.03306446224451065,
            0.012694225646555424,
            -0.0069433096796274185,
            0.0847281813621521,
            -0.01029164157807827,
            0.014504535123705864,
            -0.043766994029283524,
            0.019481142982840538,
            0.03427491709589958,
            -0.019981103017926216,
            -0.006455366499722004,
            -0.034343406558036804,
            -0.035726148635149,
            -0.013811176642775536,
            0.017255714163184166,
            -0.016702355816960335,
            -0.03967086225748062,
            -0.01872939057648182,
            -0.003906646743416786,
            0.025277959182858467,
            -0.00263807806186378,
            -0.019960656762123108,
            -0.03946251794695854,
            -0.01835917867720127,
            0.0006096648285165429,
            -0.005829326342791319,
            0.006887050811201334,
            -0.006760269869118929,
            -0.0008667509537190199,
            0.01858668215572834,
            -0.02901722490787506,
            -0.01990250125527382,
            0.03467079997062683,
            0.0163143128156662,
            -0.0038596398662775755,
            0.010310372337698936,
            -0.03382719308137894,
            0.03258955851197243,
            0.02597670443356037,
            0.00605838792398572,
            -0.013110657222568989,
            -0.038432393223047256,
            -0.021175287663936615,
            0.0024672981817275286,
            -0.017080821096897125,
            -0.009442140348255634,
            -0.0012521268799901009,
            0.0207531601190567,
            0.03624460846185684,
            -0.05392397567629814,
            -0.0026548116002231836,
            0.02289007231593132,
            -0.02111046202480793,
            -0.035861171782016754,
            0.03895953670144081,
            0.0013645791914314032,
            0.006513108499348164,
            0.008215260691940784,
            -0.03624538332223892,
            -0.001331613282673061,
            -0.056205760687589645,
            0.007592187728732824,
            -0.01460780669003725,
            0.021506551653146744,
            -0.020343344658613205,
            0.04372555390000343,
            -0.005956056993454695,
            0.04616852477192879,
            -0.012746396474540234,
            -0.012365707196295261,
            -0.0002306452952325344,
            -0.0034513534046709538,
            -0.052664149552583694,
            0.015689190477132797,
            -0.029489092528820038,
            0.008767879568040371,
            0.03636011481285095,
            -0.010078554973006248,
            -0.001715680118650198,
            0.03022654727101326,
            -0.013990559615194798,
            0.0008779923082329333,
            0.05289154872298241,
            0.025451242923736572,
            -0.001591612002812326,
            -0.008642980828881264,
            0.01472499966621399,
            0.02367909625172615,
            0.004443833604454994,
            -0.0014293838758021593,
            -0.0266572292894125,
            0.021882010623812675,
            -0.004180517513304949,
            -0.041180431842803955,
            -0.007869868539273739,
            0.0027195164002478123,
            -0.029596585780382156,
            0.019127508625388145,
            -0.020075375214219093,
            -0.019967954605817795,
            0.00501966942101717,
            -0.01266435720026493,
            0.025694256648421288,
            -0.015539951622486115,
            -0.0011178271379321814,
            -0.03319176658987999,
            -0.01386392954736948,
            -0.010185698047280312,
            -0.010972756892442703,
            0.02312804013490677,
            0.0021370025351643562,
            -0.02330981008708477,
            -0.02040674164891243,
            -0.02040976844727993,
            0.018628986552357674,
            -0.019273795187473297,
            -0.008928517811000347,
            0.02709181234240532,
            -0.032330017536878586,
            -0.0028599353972822428,
            0.02611778862774372,
            0.028423629701137543,
            -0.011863225139677525,
            -0.018007846549153328,
            -0.03076905943453312,
            -0.018077578395605087,
            0.004707279149442911,
            -0.007369923871010542,
            -0.00591568648815155,
            0.03408635035157204,
            -0.03241404891014099,
            0.015292862430214882,
            0.020422518253326416,
            -0.026858776807785034,
            0.029213713482022285,
            -0.02688349224627018,
            0.010188127867877483,
            0.02251669019460678,
            -0.005894248373806477,
            0.01717281900346279,
            -0.011216048151254654,
            -0.005451287142932415,
            0.009666734375059605,
            0.008605738170444965,
            -0.025392139330506325,
            -0.04398458078503609,
            0.030713345855474472,
            0.009131956845521927,
            0.009580865502357483,
            0.00813961960375309,
            0.017121700569987297,
            0.03958664834499359,
            0.009914402849972248,
            0.026034671813249588,
            0.02008056640625,
            -0.014265784062445164,
            0.005146191455423832,
            -0.0054114749655127525,
            -0.002478155307471752,
            -0.009765985421836376,
            0.014964899979531765,
            0.05841631442308426,
            0.00854638870805502,
            -0.03387463837862015,
            0.002720574615523219,
            0.008468317799270153,
            0.0067594218999147415,
            -0.010543400421738625,
            0.03077036701142788,
            -0.003754710080102086,
            0.018160026520490646,
            -0.04084184020757675,
            0.0026194301899522543,
            -0.028606075793504715,
            -0.017802294343709946,
            0.028202446177601814,
            -0.001926411408931017,
            -0.02007695659995079,
            -0.006831292528659105,
            -0.02672407031059265,
            0.0056492630392313,
            0.05568923056125641,
            0.030270297080278397,
            -0.03311796486377716,
            0.0010422763880342245,
            -0.013692516833543777,
            -0.004748594481498003,
            0.03128276392817497,
            -0.018874486908316612,
            0.05046889930963516,
            0.018830377608537674,
            -0.00857713632285595,
            0.06283488124608994,
            0.01546610239893198,
            -0.028125908225774765,
            -0.02023155428469181,
            -0.007308559957891703,
            -0.020361047238111496,
            -0.010083692148327827,
            0.006822880357503891,
            0.06370244175195694,
            0.006609112024307251,
            -0.03299620375037193,
            0.0021936555858701468,
            0.012409593909978867,
            -0.02682514861226082,
            0.023842550814151764,
            0.018617846071720123,
            0.04013175517320633,
            -0.029189463704824448,
            -0.021044623106718063,
            -0.038709066808223724,
            0.016225872561335564,
            0.011004780419170856,
            -0.07809089124202728,
            -2.6177243853453547e-05,
            0.03201731666922569,
            0.019654888659715652,
            0.025261705741286278,
            -0.01187482662498951,
            -0.021363351494073868,
            0.0058845230378210545,
            0.0006958136218599975,
            -0.03934374824166298,
            -0.02986585535109043,
            -0.029469601809978485,
            -0.039478372782468796,
            0.0047833481803536415,
            0.026179373264312744,
            -0.0022849226370453835,
            -0.03351500257849693,
            0.02996229939162731,
            0.0012604417279362679,
            0.012177645228803158,
            -0.04781252518296242,
            0.010685322806239128,
            0.01135183498263359,
            0.005012116860598326,
            0.015912413597106934,
            0.025283485651016235,
            -0.00013034598669037223,
            0.001919458038173616,
            0.011988920159637928,
            0.013132419437170029,
            0.016387805342674255,
            0.042675066739320755,
            0.0024399831891059875,
            -0.0014626298798248172,
            -0.011292616836726665,
            0.019189460203051567,
            0.0077543677762150764,
            -0.005660874303430319,
            0.018831614404916763,
            -0.04224724695086479,
            -0.024150311946868896,
            -0.07152099907398224,
            -0.009086297824978828,
            -0.04247868061065674,
            -0.0014715878060087562,
            -0.00731359189376235,
            -0.0022818692959845066,
            -0.05407990515232086,
            0.0185715164989233,
            0.04833132028579712,
            -0.02188285067677498,
            0.026062989607453346,
            -0.00033674793667159975,
            0.026008622720837593,
            0.0067915418185293674,
            -0.008238673210144043,
            0.011762200854718685,
            0.03323318436741829,
            -0.01985633373260498,
            -0.008412832394242287,
            -0.02649642340838909,
            0.01902940683066845,
            -0.016161592677235603,
            -0.03305386006832123,
            0.03835279494524002,
            -0.029477277770638466,
            -0.0021445429883897305,
            -0.01911068521440029,
            -0.03870300203561783,
            -0.021036891266703606,
            -0.02035674639046192,
            0.03374668210744858,
            0.0005241537000983953,
            0.009563976898789406,
            0.029596740379929543,
            -0.006007760763168335,
            0.011517197825014591,
            -0.04166484251618385,
            0.015559564344584942,
            0.017529862001538277,
            -0.026091838255524635,
            -0.015406952239573002,
            -0.023301705718040466,
            -0.02454637549817562,
            0.03484431281685829,
            0.016851745545864105,
            0.023354895412921906,
            0.012796442955732346,
            -0.0008778469054959714,
            -0.029076065868139267,
            0.012151705101132393,
            -0.0114380968734622,
            -0.02323765680193901,
            -0.022410085424780846,
            -0.004339135717600584,
            -0.01779158040881157,
            -0.017313973978161812,
            0.013469859026372433,
            -0.010310049168765545,
            -0.012590822763741016,
            -0.045529965311288834,
            0.0068517387844622135,
            0.012752587907016277,
            -0.032141175121068954,
            0.007936005480587482,
            0.028371887281537056,
            0.011398539878427982,
            0.002795139793306589,
            -0.021107612177729607,
            0.03028121590614319,
            -0.0013941092183813453,
            0.009390678256750107,
            -0.014654956758022308,
            -0.008743423968553543,
            -0.0025279989931732416,
            0.01285835262387991,
            -0.017096631228923798,
            0.02615484781563282,
            0.005418732762336731,
            -0.0023565387818962336,
            -0.02455081418156624,
            -0.023293938487768173,
            -0.017461858689785004,
            0.0075627276673913,
            0.04100378230214119,
            -0.0318630114197731,
            0.02244003675878048,
            0.012914733961224556,
            -0.025341905653476715,
            -0.010732932016253471,
            0.006571651436388493,
            -0.03458981588482857,
            0.022780461236834526,
            -0.031568389385938644,
            -0.00670734653249383,
            0.03322698548436165,
            0.015522605739533901,
            0.019391348585486412,
            0.0070993839763104916,
            0.017901116982102394,
            0.001215753029100597,
            -0.005612288601696491,
            0.010755069553852081,
            -0.017327776178717613,
            -0.007419637870043516,
            -0.017861636355519295,
            0.027239110320806503,
            0.029353266581892967,
            0.007594641298055649,
            -0.03852727636694908,
            0.016723893582820892,
            -0.010368602350354195,
            -0.008797498419880867,
            0.08727942407131195,
            -0.02405412495136261,
            -0.06964029371738434,
            -0.0158245787024498,
            0.00490604480728507,
            -0.0019119831267744303,
            0.03171428292989731,
            0.0013243301073089242,
            0.0011448648292571306,
            -0.0011065516155213118,
            -0.020136065781116486,
            -0.018626747652888298,
            0.005014844238758087,
            -0.04447462782263756,
            0.021623022854328156,
            0.031011952087283134,
            0.01674075610935688,
            0.03153229132294655,
            0.01782079227268696,
            0.0038503205869346857,
            -0.004015248268842697,
            -0.022730791941285133,
            -0.019194569438695908,
            -0.010881687514483929,
            0.00982363149523735,
            0.0009579798788763583,
            0.014690730720758438,
            0.057397421449422836,
            0.030175859108567238,
            -0.006389378570020199,
            -0.010565588250756264,
            -0.030063826590776443,
            -0.005308628547936678,
            0.008747660554945469,
            0.005832886788994074,
            0.046315211802721024,
            -0.012992702424526215,
            -0.054070547223091125,
            -0.03449605405330658,
            -0.020973103120923042,
            0.008032694458961487,
            -0.01620526984333992,
            -0.032537855207920074,
            -0.01342272013425827,
            -0.034237053245306015,
            0.013138682581484318,
            -0.013425858691334724,
            0.014167931862175465,
            0.04103302210569382,
            0.015869682654738426,
            0.014904075302183628,
            -0.028151914477348328,
            0.02818266674876213,
            0.009466680698096752,
            -0.0049554589204490185,
            -0.0165119431912899,
            0.06294047832489014,
            -0.004704864230006933,
            0.011602946557104588,
            0.023691127076745033,
            0.02060217224061489,
            -0.03299330174922943,
            -0.03897647187113762,
            0.03939402103424072,
            -0.027194859459996223,
            0.025212522596120834,
            0.027355046942830086,
            -0.005381444469094276,
            -0.01452732551842928,
            -0.0012403408763930202,
            -0.03873591870069504,
            0.04067340865731239,
            -0.0070806811563670635,
            -0.028716135770082474,
            -0.0025611829478293657,
            -0.029383638873696327,
            -0.02659101039171219,
            -0.014596203342080116,
            -0.016895858570933342,
            -0.02482946589589119,
            0.01325799711048603,
            0.0014750465052202344,
            -0.026553014293313026,
            0.012577801942825317,
            -0.027210786938667297,
            -0.0016385008348152041,
            0.023173769935965538,
            -0.012815626338124275,
            0.008816064335405827,
            -0.007474406622350216,
            0.058296699076890945,
            -0.014435846358537674,
            0.02585083618760109,
            -0.014607561752200127,
            -0.012082352302968502,
            -0.026274070143699646,
            0.019229192286729813,
            0.01029130257666111,
            -0.027459770441055298,
            0.01472486462444067,
            0.0059109702706336975,
            0.010544060729444027,
            0.02095220237970352,
            0.027403585612773895,
            -0.016466932371258736,
            -0.02606974169611931,
            -0.0030630065593868494,
            0.004737066570669413,
            0.03907066583633423,
            0.017106937244534492,
            0.00405486673116684,
            -0.0016570885200053453,
            -0.013865930959582329,
            -0.02881002612411976,
            0.007284305989742279,
            -0.013906571082770824,
            -0.04541333019733429,
            -0.009838140569627285,
            -0.040798041969537735,
            0.013339504599571228,
            -0.006794285029172897,
            -0.03131193295121193,
            -0.008229263126850128,
            0.049309201538562775,
            0.014547964558005333,
            0.022452814504504204,
            -0.027889162302017212,
            -0.025793137028813362,
            -0.018897971138358116,
            -0.022152939811348915,
            0.009336216375231743,
            0.0103434594348073,
            0.005022469907999039,
            -0.017699239775538445,
            -0.025548629462718964,
            0.043027639389038086,
            0.01814436726272106,
            -0.021327810361981392,
            0.02359059639275074,
            0.00196157512255013,
            0.016761232167482376,
            0.018204551190137863,
            0.013538365252315998,
            0.0017601076979190111,
            0.026922600343823433,
            -0.029542403295636177,
            0.045255959033966064,
            0.0554799810051918,
            -0.01087831798940897,
            -0.008090261369943619,
            -0.020730923861265182,
            -0.0034843813627958298,
            0.026346741244196892,
            0.023382551968097687,
            0.008163261227309704,
            0.015077452175319195,
            -0.021570948883891106,
            -0.00568973645567894,
            -0.014331717975437641,
            -0.007798801176249981,
            -0.06184988468885422,
            0.043900661170482635,
            0.012438512407243252,
            -0.04997459426522255,
            -0.001018768991343677,
            0.005233738571405411,
            -0.0030897296965122223,
            -0.007943346165120602,
            -0.0357993058860302,
            0.026378702372312546,
            -0.037285421043634415,
            0.022204380482435226,
            0.028322424739599228,
            -0.005024464335292578,
            0.006449911743402481,
            -0.02176499553024769,
            -0.0047582658007740974,
            -0.007412586361169815,
            -0.012972695752978325,
            0.019525591284036636,
            -0.004259294830262661,
            0.002135420683771372,
            -0.027666890993714333,
            0.0349377803504467,
            -0.0384991280734539,
            0.002936017932370305,
            0.028464408591389656,
            -0.014883046969771385,
            0.054117705672979355,
            -0.03948486968874931,
            0.021848179399967194,
            0.01117771863937378,
            -0.0013095269678160548,
            -0.06534184515476227,
            0.003277617273852229,
            -0.03675420582294464,
            0.014170925132930279,
            0.01614508591592312,
            0.005186413414776325,
            -0.002003306057304144,
            0.05110161378979683,
            -0.007114972919225693,
            0.015011794865131378,
            -0.0059377336874604225,
            -0.03216877579689026,
            0.017755139619112015,
            -0.022730428725481033,
            0.00848348531872034,
            0.026344990357756615,
            -0.01617223583161831,
            -0.03517723083496094,
            -0.004026833456009626,
            -0.05064133554697037,
            0.015606371685862541,
            -0.020187756046652794,
            -0.016282569617033005,
            0.010137893259525299,
            -0.015081937424838543,
            0.02258111722767353,
            -0.01837974600493908,
            0.00561073562130332,
            0.0013879146426916122,
            -0.03181539848446846,
            -0.03996501863002777,
            0.009632786735892296,
            0.03276385739445686,
            -0.040033675730228424,
            -0.006832042708992958,
            -0.015434172935783863,
            0.008266275748610497,
            0.02483062446117401,
            -0.02952071838080883,
            0.049713652580976486,
            0.01645006611943245,
            -0.03617867827415466,
            0.02380099520087242,
            0.02019093744456768,
            -0.0023597409017384052,
            -0.03530428558588028,
            0.025724200531840324,
            0.012081335298717022,
            0.030967220664024353,
            0.014770640060305595,
            -0.008050885051488876,
            0.018574325367808342,
            0.030453341081738472,
            0.008132955059409142,
            -0.021908223628997803,
            0.005061911419034004,
            -0.029482048004865646,
            0.018377188593149185,
            0.0006164415390230715,
            -0.060399580746889114,
            -0.016715096309781075,
            -0.03324347734451294,
            0.03477673977613449,
            0.0214048121124506,
            -0.034839730709791183,
            -0.0054843430407345295,
            -0.04446813836693764,
            0.011115522123873234,
            0.014276630245149136,
            0.015839416533708572,
            0.0075032650493085384,
            0.02413945272564888,
            0.02702203206717968,
            0.020595625042915344,
            0.014464513398706913,
            -0.005082208663225174,
            -0.009318744763731956,
            -0.024994662031531334,
            -0.017207253724336624,
            -0.036128588020801544,
            -0.020027553662657738,
            -0.021148577332496643,
            0.022157058119773865,
            -0.031337592750787735,
            0.032775867730379105,
            0.0041310349479317665,
            -0.027429796755313873,
            0.016020392999053,
            0.037743281573057175,
            -0.00726157333701849,
            -0.02180640585720539,
            0.02615075744688511,
            -0.03194161131978035,
            0.018831785768270493,
            0.017952557653188705,
            0.01686522737145424,
            -0.000184846663614735,
            -0.0025119802448898554,
            -0.00145581248216331,
            -0.0006458348943851888,
            -0.04249945282936096,
            -0.026220759376883507,
            0.02766602672636509,
            -0.004909871146082878,
            0.018206864595413208,
            0.03386194258928299,
            -0.029092421755194664,
            -0.008956341072916985,
            -0.01885012350976467,
            0.028949793428182602,
            0.02785959094762802,
            -0.03432701900601387,
            0.038901813328266144,
            -0.006068504881113768,
            0.01912764459848404,
            0.02124142274260521,
            0.04264551028609276,
            -0.018808994442224503,
            0.01411913800984621,
            -0.05802673473954201,
            0.025931546464562416,
            0.03837604820728302,
            -0.011785361915826797,
            0.021138837561011314,
            0.002687217202037573,
            0.01584307849407196,
            -0.006839287932962179,
            -0.021320154890418053,
            -0.02415337599813938,
            -0.03492724895477295,
            0.001143094152212143,
            0.009892052970826626,
            -0.028650254011154175,
            -0.02871132455766201,
            0.02403547614812851,
            0.003337710862979293,
            0.032008036971092224,
            0.011265902779996395,
            0.01539081521332264,
            0.021250763908028603,
            0.025170329958200455,
            0.03755233809351921,
            -0.04588581621646881,
            0.017565743997693062,
            0.008986555971205235,
            -0.009570962749421597,
            0.04826992750167847,
            0.023545468226075172,
            0.020059050992131233,
            0.009990655817091465,
            -0.012040942907333374,
            0.03843245655298233,
            0.006381019949913025,
            -0.0022941865026950836,
            -0.010423018597066402,
            -0.01005276944488287,
            -0.012375914491713047,
            0.04678283631801605,
            -0.017971206456422806,
            0.017791206017136574,
            0.011671116575598717,
            0.02945818193256855,
            -0.0378803014755249,
            0.0031038732267916203,
            0.012216298840939999,
            0.02735185995697975,
            0.017741676419973373,
            0.03459461033344269,
            0.013594098389148712,
            0.0216675978153944,
            -0.023869192227721214,
            -0.00026716876891441643,
            -0.013987681828439236,
            0.008581920526921749,
            -0.032249566167593,
            0.06326615810394287,
            0.0026489088777452707,
            -0.015462015755474567,
            -0.00950432475656271,
            -0.025839779525995255,
            0.021625712513923645,
            -0.04824119061231613,
            -0.0028420756570994854,
            -0.01869659498333931,
            0.0313154011964798,
            0.025685006752610207,
            -0.03556583449244499,
            -0.035583000630140305,
            -0.0009463577880524099,
            -0.007734269369393587,
            -0.008421579375863075,
            0.038707226514816284,
            0.027051713317632675,
            0.0209104735404253,
            0.0005588206695392728,
            -0.019945932552218437,
            -0.004924410488456488,
            -0.02834772877395153,
            -0.029991833493113518,
            -0.007941052317619324,
            0.018188724294304848,
            -0.02020685374736786,
            0.026054561138153076,
            0.07146041840314865,
            -0.017297795042395592,
            0.04797607287764549,
            -0.010904110036790371,
            -0.005278461147099733,
            0.031502630561590195,
            0.026012476533651352,
            0.007805872708559036,
            -0.007956401444971561,
            0.01747232675552368,
            -0.04421456903219223,
            0.017408661544322968,
            0.010000759735703468,
            0.012096217833459377,
            -0.01222914271056652,
            -0.009659815579652786,
            0.019748292863368988,
            -0.0322580561041832,
            0.03242504969239235,
            -0.024233922362327576,
            -0.022156590595841408,
            -0.04171617329120636,
            -0.011392677202820778,
            -0.015970658510923386,
            0.017225101590156555,
            0.018480638042092323,
            0.02453063800930977,
            -0.04717782139778137
        ],
        [
            -0.01125080231577158,
            0.004259787034243345,
            -0.009474107064306736,
            -0.027240460738539696,
            0.03382011502981186,
            0.012300300411880016,
            -0.004470850806683302,
            -0.003462908323854208,
            -0.014314640313386917,
            -0.030311090871691704,
            0.022757789120078087,
            -0.02891765721142292,
            0.026720650494098663,
            -0.013072500936686993,
            0.02658216655254364,
            -0.01490376703441143,
            -0.0001853191788541153,
            0.030335823073983192,
            -0.018525496125221252,
            -0.006928152404725552,
            0.04070297256112099,
            0.020929094403982162,
            0.014002958312630653,
            -0.008711768314242363,
            0.00034275042708031833,
            -0.022950703278183937,
            -0.019782811403274536,
            0.003645382821559906,
            0.018129831179976463,
            0.0011388473212718964,
            0.0009566746302880347,
            -0.00822270754724741,
            0.036619704216718674,
            0.026072539389133453,
            -0.02329651638865471,
            -0.01569543592631817,
            0.027888759970664978,
            0.009896323084831238,
            0.005136186257004738,
            0.024986617267131805,
            -0.01650785468518734,
            0.024835871532559395,
            -0.029887612909078598,
            -0.026162710040807724,
            -0.0220845527946949,
            0.010589697398245335,
            -0.014746064320206642,
            -0.008622772991657257,
            0.03178780898451805,
            0.014928281307220459,
            -0.004939062055200338,
            0.005245107226073742,
            -0.00798239279538393,
            -0.00016612187027931213,
            0.0009339122916571796,
            0.004864079412072897,
            -0.01690596528351307,
            -0.055531881749629974,
            0.017279531806707382,
            0.00782450009137392,
            -0.010884519666433334,
            0.02237078733742237,
            -0.02035355381667614,
            -0.0076645752415061,
            0.023752359673380852,
            -0.02051239088177681,
            -0.03305046632885933,
            -0.01231340877711773,
            0.0016528588021174073,
            -0.021376296877861023,
            0.02752457745373249,
            -0.016421210020780563,
            0.007508454378694296,
            -0.013458948582410812,
            -0.022352661937475204,
            -0.014483071863651276,
            0.0012953116092830896,
            0.017054535448551178,
            0.012182132340967655,
            0.01693405583500862,
            0.0006568562239408493,
            0.009457781910896301,
            0.023201003670692444,
            0.0340493842959404,
            -0.021130165085196495,
            -0.030757753178477287,
            0.009237993508577347,
            0.0318034328520298,
            0.005431327503174543,
            0.029965413734316826,
            0.004805820994079113,
            -0.023721352219581604,
            0.005123295821249485,
            -0.03316691517829895,
            -0.034114062786102295,
            -0.019810620695352554,
            0.017444411292672157,
            -0.032946646213531494,
            -0.010913104750216007,
            0.025290124118328094,
            0.01821068674325943,
            -0.0012087641516700387,
            0.018313005566596985,
            0.023601893335580826,
            -0.02441222220659256,
            -0.015633244067430496,
            0.006153982598334551,
            -0.01458186749368906,
            0.02000976912677288,
            -0.019286271184682846,
            0.006336754187941551,
            0.004285917617380619,
            -0.010845085605978966,
            0.0023610051721334457,
            0.03569163382053375,
            -0.01772800087928772,
            0.02026974968612194,
            -0.003126198425889015,
            -0.0016363946488127112,
            0.028730548918247223,
            -0.020218586549162865,
            -0.0001445373345632106,
            0.02247542329132557,
            0.004333927296102047,
            -0.005948225501924753,
            -0.011440052650868893,
            -0.005950494669377804,
            -0.02143997699022293,
            -0.010482480749487877,
            0.0008384493994526565,
            0.029120996594429016,
            0.034061722457408905,
            -0.018574370071291924,
            0.011079622432589531,
            -0.013278482481837273,
            0.005064142402261496,
            0.014748550951480865,
            -0.014532688073813915,
            0.03388012573122978,
            -0.020736997947096825,
            0.0037510478869080544,
            -0.0260210782289505,
            -0.03820863738656044,
            0.015200231224298477,
            0.022484848275780678,
            0.026573678478598595,
            -0.017546620219945908,
            0.011151203885674477,
            0.029209574684500694,
            -0.028757374733686447,
            -0.007914273999631405,
            0.012374934740364552,
            -0.0055928900837898254,
            0.02257363870739937,
            0.003598120529204607,
            0.01032959297299385,
            0.0057283490896224976,
            0.00788353942334652,
            -0.0016017862362787127,
            -0.02140187658369541,
            0.01431755069643259,
            -0.026388373225927353,
            0.022821923717856407,
            0.03125660866498947,
            0.0013856604928150773,
            -0.0004415553412400186,
            -0.0324714370071888,
            -0.018571961671113968,
            -0.004719545599073172,
            0.03204835206270218,
            -0.017133960500359535,
            -0.006653790362179279,
            -9.392955689691007e-05,
            -0.0009200393687933683,
            0.04559134319424629,
            -0.02765088900923729,
            -0.015565348789095879,
            0.01245703175663948,
            -0.017662253230810165,
            -0.00021993006521370262,
            -0.02554347738623619,
            0.016327090561389923,
            -0.03666406869888306,
            0.003406811272725463,
            -0.012545624747872353,
            -0.027832046151161194,
            0.0177670419216156,
            -0.016846146434545517,
            -0.005528900306671858,
            -0.018152449280023575,
            -0.03768137842416763,
            0.021839607506990433,
            0.06592066586017609,
            -0.006086828652769327,
            0.006190336309373379,
            0.009614305570721626,
            0.009674753993749619,
            -0.017804080620408058,
            0.010251322761178017,
            -0.007848257198929787,
            0.016550200060009956,
            -0.032234076410532,
            0.04767836630344391,
            0.021552694961428642,
            -0.01459968090057373,
            0.027625419199466705,
            0.0015617279568687081,
            -0.013758602552115917,
            0.04360075667500496,
            -0.018673615530133247,
            -0.028470853343605995,
            -0.019590239971876144,
            0.014551399275660515,
            0.009458288550376892,
            -0.014694660902023315,
            0.009220906533300877,
            0.008276207372546196,
            0.014361921697854996,
            -0.027444878593087196,
            -0.021334676072001457,
            0.025591183453798294,
            -0.009256387129426003,
            -0.018217137083411217,
            -0.02168009802699089,
            0.011381901800632477,
            0.02604498527944088,
            0.0050094542093575,
            0.025747736915946007,
            0.009469467215240002,
            0.0032155103981494904,
            0.0033770345617085695,
            -0.027968375012278557,
            0.009590590372681618,
            0.009785753674805164,
            0.0030078203417360783,
            -0.017940597608685493,
            0.007747814524918795,
            0.00869899895042181,
            0.025630544871091843,
            -0.010822005569934845,
            -0.0007871650159358978,
            -0.0071488963440060616,
            -0.0261160247027874,
            0.02506900206208229,
            -0.01910942792892456,
            -0.03163899853825569,
            0.01650349050760269,
            -0.0019138791831210256,
            0.01849033311009407,
            -0.0022416759748011827,
            -0.024602074176073074,
            0.005261202342808247,
            0.007160646375268698,
            0.04226969555020332,
            0.023337775841355324,
            0.01894238032400608,
            -0.024448582902550697,
            0.040606092661619186,
            0.002182918367907405,
            0.018536580726504326,
            0.0013553939061239362,
            0.016390761360526085,
            -0.02376173809170723,
            -0.011028960347175598,
            -0.019982680678367615,
            0.007930198684334755,
            0.006780458148568869,
            0.0011841304367408156,
            0.013440222479403019,
            0.024517040699720383,
            0.014528940431773663,
            -0.033028312027454376,
            0.02922743745148182,
            -0.005256884265691042,
            -0.03393804654479027,
            0.0024512505624443293,
            0.028948094695806503,
            -0.010402788408100605,
            0.014490936882793903,
            -0.007030894979834557,
            -0.0018723970279097557,
            0.015117271803319454,
            -0.004365820903331041,
            -0.034800004214048386,
            0.021271128207445145,
            -0.008592907339334488,
            -0.002083441009745002,
            -8.138940029311925e-05,
            0.010296318680047989,
            0.03332366794347763,
            0.038783520460128784,
            0.02880704402923584,
            -0.006299993954598904,
            0.00042340176878497005,
            -0.025806637480854988,
            -0.039657704532146454,
            0.00308080087415874,
            -0.022569991648197174,
            0.009988317266106606,
            -0.018870415166020393,
            -0.013201847672462463,
            -0.04508098587393761,
            0.025045350193977356,
            -0.020224539563059807,
            0.02383452281355858,
            0.002206729957833886,
            -0.029884478077292442,
            0.03843355551362038,
            0.010767251253128052,
            -0.03790632262825966,
            0.002675871131941676,
            0.020927179604768753,
            0.019697267562150955,
            -0.011979078873991966,
            -0.023943006992340088,
            -0.001514615723863244,
            0.009571905247867107,
            0.015166029334068298,
            0.006531077902764082,
            0.035744067281484604,
            -0.006117512937635183,
            -0.003135074395686388,
            0.015639157965779305,
            -0.00780629925429821,
            0.03555646911263466,
            -0.01631583459675312,
            -0.012181542813777924,
            -0.025582650676369667,
            0.01290876790881157,
            0.035242997109889984,
            0.024401886388659477,
            0.013811781071126461,
            -0.05202426016330719,
            -0.0037604488898068666,
            -0.01649382710456848,
            -0.005595244932919741,
            0.012508532963693142,
            0.0018720310181379318,
            0.03254839777946472,
            0.024539902806282043,
            0.005426420364528894,
            -0.0035214563831686974,
            -0.01020162645727396,
            0.007289722561836243,
            0.007704205811023712,
            0.04915186017751694,
            -0.0014674053527414799,
            -0.0015132772969081998,
            0.010221273638308048,
            -0.039622191339731216,
            0.003997305408120155,
            -0.010625633411109447,
            -0.028869619593024254,
            0.020395521074533463,
            0.03266896679997444,
            0.005853061098605394,
            -0.037415891885757446,
            -0.03062085062265396,
            -0.028555328026413918,
            0.024249359965324402,
            -0.00043363095028325915,
            -0.004350124392658472,
            -0.02556445077061653,
            0.012018336914479733,
            -0.008351519703865051,
            -0.019071213901042938,
            -0.02133789286017418,
            -0.027444912120699883,
            0.02499425783753395,
            0.010955399833619595,
            0.02495032548904419,
            -0.03196897357702255,
            -0.011848131194710732,
            0.0037926624063402414,
            0.025900717824697495,
            0.0047613149508833885,
            -0.014041166752576828,
            0.011383792385458946,
            0.029595287516713142,
            0.01615794003009796,
            0.07517272233963013,
            -0.013429581187665462,
            0.0010201922850683331,
            -0.024789361283183098,
            -0.008109323680400848,
            -0.02167394384741783,
            0.0025185507256537676,
            -0.01405645813792944,
            -0.02552287094295025,
            -0.008959193713963032,
            0.002303699729964137,
            0.03778330609202385,
            0.017024589702486992,
            0.009479483589529991,
            0.07034033536911011,
            0.033032216131687164,
            -0.025027263909578323,
            -0.026248488575220108,
            -0.005562128499150276,
            -0.015229376032948494,
            -0.01863497495651245,
            -0.008604342117905617,
            -0.015476941131055355,
            -0.004939267411828041,
            -0.010295445099473,
            0.027207495644688606,
            0.013291445560753345,
            -0.01641603745520115,
            0.006420916877686977,
            -0.007460289169102907,
            -0.016566026955842972,
            -0.001261774217709899,
            -0.030871139839291573,
            0.0069898278452456,
            0.010458194650709629,
            -0.0006621124339289963,
            -0.029320331290364265,
            -0.004528691526502371,
            -0.009987101890146732,
            -0.00414102990180254,
            0.003644776763394475,
            0.0072432346642017365,
            0.0006135977455414832,
            -0.018914230167865753,
            -0.032489098608493805,
            -0.02943343296647072,
            0.01012429129332304,
            -0.020030662417411804,
            -0.01802864857017994,
            0.007980551570653915,
            -0.015924911946058273,
            -0.00028286586166359484,
            -0.02087966725230217,
            0.018383406102657318,
            0.0063857329078018665,
            -0.009028819389641285,
            -0.03096156008541584,
            0.0004555461637210101,
            -0.006401199847459793,
            -0.025089051574468613,
            -0.012693610973656178,
            -0.02901872806251049,
            0.01078244298696518,
            -0.041309330612421036,
            0.0022287361789494753,
            0.00953116174787283,
            -0.026339229196310043,
            -0.042824190109968185,
            5.880301614524797e-06,
            0.0017652490641921759,
            -0.027543731033802032,
            0.012631477788090706,
            -0.007386010140180588,
            -0.014963534660637379,
            0.005092699080705643,
            -0.0033354333136230707,
            -0.021248986944556236,
            0.0033212993294000626,
            0.03696716949343681,
            -0.004778012633323669,
            -0.006838722620159388,
            -0.027259618043899536,
            0.02154068648815155,
            -0.014950533397495747,
            -0.0060065072029829025,
            0.017744742333889008,
            0.02452857419848442,
            -0.015311120077967644,
            -0.026772692799568176,
            0.01258863415569067,
            0.023665916174650192,
            -0.018182052299380302,
            -0.01719331368803978,
            0.012129257433116436,
            0.008068644441664219,
            -0.060096222907304764,
            0.02361755445599556,
            0.005285837221890688,
            0.004468520171940327,
            -0.00025919105974026024,
            -0.0014530237531289458,
            0.007798935752362013,
            -0.028863606974482536,
            -0.004976874217391014,
            -0.04005638509988785,
            0.031818754971027374,
            -0.009628008119761944,
            -0.003291606204584241,
            -0.0430605411529541,
            -0.004782678093761206,
            0.02208041027188301,
            0.0021128766238689423,
            0.003780993400141597,
            -0.006400530692189932,
            -0.0015714503824710846,
            -0.030992619693279266,
            0.017160041257739067,
            -0.009252229705452919,
            0.030577179044485092,
            0.01659875176846981,
            0.032849546521902084,
            -0.033825527876615524,
            -0.018812892958521843,
            0.01345093734562397,
            -0.022782359272241592,
            -0.008142638951539993,
            -0.019917359575629234,
            0.015533647499978542,
            -0.001485432730987668,
            -0.00761827128008008,
            0.02767948992550373,
            0.01839752122759819,
            0.00808196235448122,
            0.02610265463590622,
            -0.00784118752926588,
            0.030504342168569565,
            0.0021960779558867216,
            -0.015282168984413147,
            -0.02120731957256794,
            0.027023185044527054,
            0.011987634003162384,
            0.02317643351852894,
            -0.011816243641078472,
            0.03465809300541878,
            -0.005183564964681864,
            -0.005031137261539698,
            -0.001088994205929339,
            -0.008015112020075321,
            0.035189125686883926,
            0.04151667654514313,
            -0.026071764528751373,
            -4.5230393880046904e-05,
            -0.00999888963997364,
            -0.022332126274704933,
            0.03240795433521271,
            -0.002215578919276595,
            0.007867167703807354,
            0.0012430892093107104,
            -0.02601531147956848,
            0.005549788009375334,
            -0.013327676802873611,
            0.015379182063043118,
            0.038765329867601395,
            -0.03825914487242699,
            -0.028549954295158386,
            -0.007412800565361977,
            -0.00017967353051062673,
            0.0030173498671501875,
            -0.0029861219227313995,
            -0.00042523982119746506,
            -0.02345438487827778,
            -0.009516984224319458,
            -0.00010200160613749176,
            0.01399352215230465,
            0.016343392431735992,
            -0.008509285748004913,
            -0.02072759158909321,
            0.028505338355898857,
            -0.0029894053004682064,
            0.029777321964502335,
            0.007664079312235117,
            0.012737305834889412,
            0.02833099663257599,
            -0.010171628557145596,
            -0.02770211547613144,
            -0.023188939318060875,
            -0.0025335955433547497,
            0.02562444843351841,
            0.02674158290028572,
            0.015325314365327358,
            -0.001232396811246872,
            -0.035175297409296036,
            -0.0055910320952534676,
            0.007457386702299118,
            0.017269980162382126,
            0.013423199765384197,
            -0.018596261739730835,
            0.008736773394048214,
            -0.03032614476978779,
            -0.016212496906518936,
            -0.002199713606387377,
            0.033463578671216965,
            0.00817154161632061,
            0.00825447216629982,
            -0.005059600342065096,
            -0.017229830846190453,
            0.0006347305024974048,
            0.0272205863147974,
            -0.02134689874947071,
            -0.02913208119571209,
            0.013612210750579834,
            -0.010581249371170998,
            0.018499385565519333,
            0.053562477231025696,
            0.009947040118277073,
            0.030811261385679245,
            -0.03334340825676918,
            0.011516922153532505,
            0.032397255301475525,
            0.009611230343580246,
            -0.001504154410213232,
            -0.007511158473789692,
            0.024457426741719246,
            0.0015147686935961246,
            0.015947576612234116,
            0.01495457999408245,
            0.011080170050263405,
            0.02570921555161476,
            0.014106269925832748,
            0.038704466074705124,
            -0.006359017454087734,
            0.03028898499906063,
            -0.02099066600203514,
            0.029087843373417854,
            0.011688737198710442,
            -0.01334190834313631,
            -0.016801122575998306,
            0.02184574492275715,
            0.02557661570608616,
            0.010518228635191917,
            0.009538914076983929,
            -0.032433316111564636,
            0.027348719537258148,
            -0.0022196732461452484,
            -0.0066236285492777824,
            -0.007849560119211674,
            -0.010217687115073204,
            -0.029461562633514404,
            0.002357983263209462,
            0.03244088590145111,
            -0.023816972970962524,
            0.04138632491230965,
            0.02059413306415081,
            -0.042814258486032486,
            -0.013775826431810856,
            0.01936362311244011,
            0.007273440714925528,
            -0.01298689004033804,
            0.026837332174181938,
            0.018205231055617332,
            0.00838651042431593,
            0.0007037133909761906,
            0.02450959011912346,
            0.024030830711126328,
            -0.00526666734367609,
            0.018076829612255096,
            -0.035591594874858856,
            0.021284302696585655,
            -0.018134845420718193,
            -0.017707860097289085,
            -0.002185502089560032,
            -0.0005851119640283287,
            -0.031613789498806,
            -0.007986952550709248,
            0.021678512915968895,
            -0.008723948150873184,
            0.028916453942656517,
            -0.010798335075378418,
            -0.03420134261250496,
            0.005512936040759087,
            -0.01716376282274723,
            -0.0050155469216406345,
            -0.004021581262350082,
            -0.034817080944776535,
            -0.02150101587176323,
            0.029932526871562004,
            -0.014466139487922192,
            0.01221503783017397,
            0.003853235626593232,
            0.01608378067612648,
            -0.00276719918474555,
            -0.004267792217433453,
            0.015642555430531502,
            0.00013703556032851338,
            0.003409988945350051,
            0.010874719358980656,
            -0.015022293664515018,
            -0.02337612770497799,
            0.02802184410393238,
            0.013280889019370079,
            -0.028461787849664688,
            0.0026554418727755547,
            -0.0007038451731204987,
            0.01817932352423668,
            -0.003983105532824993,
            0.01991518773138523,
            -0.0005063036223873496,
            -0.03127051144838333,
            0.013598484918475151,
            0.017035283148288727,
            -0.0032340986654162407,
            -0.03378111124038696,
            -0.05087946355342865,
            0.0192448440939188,
            0.008620516397058964,
            -0.017488526180386543,
            0.024876780807971954,
            -0.018338145688176155,
            0.006523575633764267,
            -0.026469919830560684,
            0.027743473649024963,
            0.006442143116146326,
            -0.002331739291548729,
            0.029593247920274734,
            0.0027013947255909443,
            0.0070116533897817135,
            -0.032912977039813995,
            0.037926580756902695,
            -0.008502568118274212,
            0.012322412803769112,
            -0.02511368691921234,
            -0.014751419425010681,
            -0.034369971603155136,
            0.0024200445041060448,
            0.0185691025108099,
            -0.009227070026099682,
            0.00356887374073267,
            -0.007421194575726986,
            0.0313095785677433,
            0.00014866354467812926,
            0.010747508145868778,
            0.017284631729125977,
            0.01382469292730093,
            0.025517895817756653,
            0.015201129950582981,
            0.002245989628136158,
            0.0007799319573678076,
            -0.01678595319390297,
            0.01820671185851097,
            0.023750606924295425,
            -0.010971075855195522,
            -0.02567020244896412,
            0.007075656671077013,
            0.0038369062822312117,
            -0.04681462422013283,
            -0.021157003939151764,
            -0.0011680908501148224,
            0.010937880724668503,
            0.03680146858096123,
            -0.008730108849704266,
            -0.03117353841662407,
            -0.01311343815177679,
            -0.0014775673625990748,
            0.026899665594100952,
            0.03108855150640011,
            -0.015388751402497292,
            -0.029818108305335045,
            0.013120738789439201,
            -0.012008960358798504,
            -0.028383862227201462,
            -0.032567884773015976,
            0.03570021316409111,
            -0.0040653422474861145,
            0.05684591829776764,
            0.033480096608400345,
            -0.016619624570012093,
            0.01512094121426344,
            0.007118592970073223,
            -0.01690768264234066,
            0.004728407133370638,
            0.03077108971774578,
            -0.009869126603007317,
            0.006958524696528912,
            -0.01551839243620634,
            0.0428110733628273,
            0.018281850963830948,
            0.027342982590198517,
            0.002696566516533494,
            -0.02124013379216194,
            -0.003720800159499049,
            0.009457087144255638,
            -0.030448393896222115,
            -0.009116408415138721,
            -0.02109459973871708,
            0.014707495458424091,
            0.03234301507472992,
            0.006911335978657007,
            0.01815139129757881,
            0.002668431494385004,
            -0.024401213973760605,
            -0.01880088448524475,
            -0.01747949793934822,
            0.0028101718053221703,
            0.013235612772405148,
            -0.02113868109881878,
            0.004814622923731804,
            -0.013688038103282452,
            0.0005747243412770331,
            -0.026245487853884697,
            -0.01984897255897522,
            0.03880494832992554,
            0.00672914320603013,
            0.033242061734199524,
            -0.02001717872917652,
            0.02600550651550293,
            0.0003028236096724868,
            0.009601960889995098,
            0.0053421491757035255,
            -0.0010532912565395236,
            -0.02160860039293766,
            -0.004817144479602575,
            -0.0123076057061553,
            0.03444262593984604,
            0.023964332416653633,
            0.030510149896144867,
            0.027613570913672447,
            -0.045632150024175644,
            -0.015911081805825233,
            0.02352241799235344,
            -0.00204581581056118,
            -0.018159346655011177,
            0.009537572972476482,
            -0.016041655093431473,
            0.005652879364788532,
            0.002002969617024064,
            0.0002730705891735852,
            0.030634142458438873,
            -0.0409807451069355,
            0.00030201711342670023,
            -0.028507856652140617,
            0.003330337582156062,
            0.02940056286752224,
            -0.013492046855390072,
            0.01622353307902813,
            -0.0032381454948335886,
            -0.017928078770637512,
            0.0009251665323972702,
            0.005206066183745861,
            -0.015227565541863441,
            -0.0165958683937788,
            0.005272154696285725,
            -0.0007454376900568604,
            0.02321162261068821,
            -0.016795845702290535,
            -0.0018319234950467944,
            -0.009601625613868237,
            0.007903165183961391,
            0.00012541908654384315,
            0.028604451566934586,
            0.0019255754305049777,
            -0.024482591077685356,
            -0.018363244831562042,
            0.029470443725585938,
            0.012659834697842598,
            -0.017353784292936325,
            -0.009237031452357769,
            0.04099174588918686,
            0.0077288649044930935,
            -0.0064466651529073715,
            0.02045218087732792,
            0.009747866541147232,
            0.022863702848553658,
            -0.02271478995680809,
            -0.0012310064630582929,
            -0.014123393222689629,
            -0.02715485729277134,
            0.036698855459690094,
            0.033953920006752014,
            0.03135477378964424,
            -0.030102038756012917,
            0.027625905349850655,
            0.019310925155878067,
            0.011618142947554588,
            0.03141694515943527,
            -0.004255205858498812,
            -0.009480456821620464,
            -0.02090240828692913,
            0.002741474425420165,
            0.02920704334974289,
            -0.026380836963653564,
            -0.0027901041321456432,
            -0.03340352699160576,
            0.04326139762997627,
            0.02327190339565277,
            -0.0063348375260829926,
            -0.0003383220173418522,
            -0.006233419757336378,
            0.07030658423900604,
            0.023248907178640366,
            0.016558831557631493,
            -0.00544087216258049,
            0.03224918246269226,
            0.017952119931578636,
            0.013313147239387035,
            -0.020034782588481903,
            0.0026194844394922256,
            -0.008354191668331623,
            0.011471954174339771,
            0.007077018730342388,
            -0.0032325254287570715,
            0.02575019560754299,
            0.00884927250444889,
            -0.014158456586301327,
            -0.009669002145528793,
            -0.009312503039836884,
            0.028069794178009033,
            0.02329239435493946,
            -0.03778797760605812,
            -0.01870405301451683,
            0.016180744394659996,
            -0.03101169504225254,
            -0.00908749457448721,
            0.0057592554949223995,
            0.022284945473074913,
            0.01074241753667593,
            0.006297246087342501,
            0.033207718282938004,
            -0.02309584990143776,
            0.018310151994228363,
            -0.013371472246944904,
            0.01513613574206829,
            0.03279935568571091,
            0.006870679557323456,
            -0.009893528185784817,
            -0.03540806099772453,
            -0.017458271235227585,
            -0.0071008093655109406,
            -0.0195989441126585,
            -0.009383125230669975,
            0.02241593226790428,
            0.03738832473754883,
            0.031216487288475037,
            0.013767466880381107,
            0.02240154705941677,
            -0.02164839580655098,
            -0.013369561173021793,
            0.003093380481004715,
            0.004602228756994009,
            -0.024000877514481544,
            0.022563010454177856,
            -0.00212352373637259,
            0.018224962055683136,
            -0.03179704025387764,
            -0.03310064226388931,
            0.003671234240755439,
            0.012659178115427494,
            -0.009231286123394966,
            -0.0203982163220644,
            -0.023526692762970924,
            0.01251261681318283,
            -0.008640920743346214,
            -0.016178075224161148,
            -0.012494326569139957,
            0.008634550496935844,
            -0.01531957183033228,
            -0.002055492950603366,
            0.022336265072226524,
            0.015353321097791195,
            0.006644525099545717,
            0.0017616042168810964,
            0.01045007724314928,
            0.01535787619650364,
            -0.02122795581817627,
            0.0002512636419851333,
            0.02094939351081848,
            -0.015458112582564354,
            -0.007662629708647728,
            -0.007055578287690878,
            0.010717358440160751,
            0.027806684374809265,
            0.015378064475953579,
            -0.04191955551505089,
            0.04142073169350624,
            -0.005468183662742376,
            0.009945187717676163,
            0.02793247625231743,
            0.04406648501753807,
            -0.0013629138702526689,
            0.0016535099130123854,
            0.0048148417845368385,
            0.002715560607612133,
            0.009210467338562012,
            0.005468939431011677,
            0.012025989592075348,
            0.02318473532795906,
            -0.02348773553967476,
            0.01391702052205801,
            0.03602573648095131,
            -0.010511158965528011,
            -0.0013267083559185266,
            -0.0010612099431455135,
            0.028446095064282417,
            0.028280673548579216,
            -0.02045336738228798,
            0.039250437170267105,
            -0.0018389674369245768,
            -0.021367952227592468,
            0.0306033156812191,
            -0.021155521273612976,
            0.029339399188756943,
            -0.0011940140975639224,
            0.012518533505499363,
            0.006717655807733536,
            0.014226221479475498,
            -0.014846588484942913,
            -0.019125837832689285,
            -0.020415496081113815,
            -0.0011335909366607666,
            0.03699212148785591,
            0.009030528366565704,
            -0.029624508693814278,
            0.0256133321672678,
            0.008825764991343021,
            0.006010879762470722,
            0.03482019901275635,
            0.027327490970492363,
            -0.0317571796476841,
            0.017518766224384308,
            0.018455276265740395,
            0.005888557992875576,
            -0.0019668755121529102,
            -0.007157627493143082,
            -0.02704019658267498,
            0.007756006438285112,
            0.04048984497785568,
            -0.04690460115671158,
            -0.006992648355662823,
            0.02825370617210865,
            -0.01898173801600933,
            0.02572987787425518,
            0.034923702478408813,
            -0.02705315314233303,
            0.0003828731132671237,
            -0.009852170944213867
        ],
        [
            0.032166361808776855,
            -0.011885019950568676,
            0.02299150452017784,
            -0.015216974541544914,
            0.0013591678580269217,
            0.011336913332343102,
            -0.03870243579149246,
            -0.0160629004240036,
            0.02378428354859352,
            0.03621811419725418,
            0.04153350368142128,
            -0.02899176813662052,
            0.04014722630381584,
            -0.01874573901295662,
            0.04306834191083908,
            -0.028562556952238083,
            -0.040254976600408554,
            0.01627444475889206,
            0.004281765315681696,
            -0.02364432066679001,
            0.02916429005563259,
            0.010493877343833447,
            -0.009608866646885872,
            -0.003943875897675753,
            0.029023870825767517,
            0.01236017793416977,
            -0.00857691653072834,
            -0.03220615163445473,
            -0.017851930111646652,
            -0.028541939333081245,
            0.022926125675439835,
            0.024011842906475067,
            -0.004300037398934364,
            -0.014153080061078072,
            -0.016120297834277153,
            -0.05136914178729057,
            0.026133352890610695,
            0.00020414807659108192,
            0.0034448474179953337,
            0.01754298247396946,
            0.026703951880335808,
            0.01168579887598753,
            -0.016476251184940338,
            -0.015044077299535275,
            -0.01651838794350624,
            0.003442201530560851,
            0.024033138528466225,
            -0.009638001210987568,
            0.03828725963830948,
            -0.004892943426966667,
            0.024344000965356827,
            -0.013119375333189964,
            0.005438045598566532,
            0.005531045608222485,
            0.013594640418887138,
            0.004002838861197233,
            0.03782448545098305,
            0.00046390085481107235,
            -0.008782804943621159,
            0.004288418218493462,
            0.03117666020989418,
            -0.022616973146796227,
            -0.029524583369493484,
            0.03433416038751602,
            -0.0012277498608455062,
            0.005964063573628664,
            0.026190752163529396,
            0.007223169319331646,
            0.00804749596863985,
            0.006930419709533453,
            -0.007793406955897808,
            0.0021069939248263836,
            0.027589263394474983,
            -0.0031361521687358618,
            -0.022132936865091324,
            0.0014122489374130964,
            0.03779982775449753,
            -0.0010296058608219028,
            0.001274065813049674,
            -0.028929829597473145,
            -0.026037706062197685,
            -0.014196415431797504,
            -0.024465901777148247,
            -0.0014931136975064874,
            -0.03286418691277504,
            -0.006216863170266151,
            -0.0010334389517083764,
            0.007029678672552109,
            -0.029217025265097618,
            -0.0314619317650795,
            0.014834849163889885,
            0.021535178646445274,
            -0.004494463559240103,
            -0.017796723172068596,
            0.008964435197412968,
            0.011869006790220737,
            0.009632606990635395,
            0.01695394143462181,
            0.0032279840670526028,
            -0.03316184878349304,
            -0.008037662133574486,
            0.03073951229453087,
            0.008775116875767708,
            -0.01850588247179985,
            -0.01623825915157795,
            -0.04618588835000992,
            -0.002316591329872608,
            0.026224808767437935,
            0.007293428294360638,
            -0.010547956451773643,
            0.02125406265258789,
            -0.0057329717092216015,
            0.014017440378665924,
            0.02358138933777809,
            0.04738738760352135,
            -0.0017711959080770612,
            0.027725471183657646,
            -0.009777482599020004,
            -0.013640965335071087,
            0.0268835611641407,
            0.016891997307538986,
            -0.0054674348793923855,
            -0.04764320328831673,
            -0.012760704383254051,
            0.026844331994652748,
            -0.01222692709416151,
            0.039427850395441055,
            0.008390198461711407,
            -0.025835668668150902,
            0.00529863266274333,
            0.02924339845776558,
            0.035438016057014465,
            0.0015198055189102888,
            0.05527150258421898,
            -0.006614138837903738,
            -0.0010249052429571748,
            0.027040621265769005,
            0.035637013614177704,
            0.012103579938411713,
            -0.019132262095808983,
            0.02419877238571644,
            -0.0011499659158289433,
            -0.03903382644057274,
            0.012078219093382359,
            0.026515770703554153,
            -0.018694264814257622,
            0.023935500532388687,
            0.02038651332259178,
            0.005377703346312046,
            0.005903366021811962,
            0.020607931539416313,
            -0.0072352029383182526,
            -0.008425375446677208,
            0.040108051151037216,
            0.043350156396627426,
            -0.013267907314002514,
            -0.029287952929735184,
            -0.011764014139771461,
            -0.0373011939227581,
            -0.013762615621089935,
            0.008825618773698807,
            0.0007028941181488335,
            0.012644884176552296,
            -0.034026823937892914,
            -0.021734800189733505,
            -0.016194481402635574,
            -0.027141092345118523,
            -0.04429557919502258,
            -0.022258391603827477,
            0.024791236966848373,
            0.015326284803450108,
            0.013248276896774769,
            -0.03231906518340111,
            0.040307946503162384,
            -0.0034347926266491413,
            0.01038176380097866,
            -0.01270429790019989,
            -0.005130372941493988,
            -0.025573888793587685,
            0.006608489900827408,
            0.016517359763383865,
            -0.03259247913956642,
            -0.031722672283649445,
            -0.011296565644443035,
            0.021402066573500633,
            -0.0016469822730869055,
            0.01648121513426304,
            0.025386950001120567,
            -0.0024498929269611835,
            -0.030093250796198845,
            -0.012768350541591644,
            0.03190027177333832,
            -0.0258563831448555,
            -0.029044492170214653,
            -0.01718711666762829,
            -0.017549758777022362,
            -0.043184470385313034,
            -0.001488919253461063,
            0.02960491180419922,
            0.033159248530864716,
            -0.0022728319745510817,
            -0.010047673247754574,
            0.03993582725524902,
            -0.020388638600707054,
            -0.05128081142902374,
            0.012955829501152039,
            -0.0031675470527261496,
            -0.019438207149505615,
            -0.027480002492666245,
            0.020208150148391724,
            0.010178317315876484,
            0.01331587415188551,
            -0.012278614565730095,
            0.03416626900434494,
            0.03145270422101021,
            -0.021274926140904427,
            -0.018500177189707756,
            -0.01315006148070097,
            -0.008176394738256931,
            0.01811373420059681,
            0.012122978456318378,
            -0.03264382854104042,
            -0.024731703102588654,
            0.017664223909378052,
            0.023997388780117035,
            -0.023741409182548523,
            -0.008301856927573681,
            -0.019073307514190674,
            0.04750305786728859,
            0.007003900595009327,
            -0.018987061455845833,
            0.026230063289403915,
            0.0020882876124233007,
            0.021316062659025192,
            -0.022551648318767548,
            -0.021969806402921677,
            -0.020738786086440086,
            -0.01664695329964161,
            -0.02492087334394455,
            0.010020297020673752,
            0.032398972660303116,
            0.011810000985860825,
            0.02481735125184059,
            0.02029385417699814,
            -0.01633756048977375,
            0.02720869705080986,
            -0.014049057848751545,
            0.025828318670392036,
            -0.005045199301093817,
            0.033283792436122894,
            0.02119726873934269,
            0.01511930488049984,
            -0.054123278707265854,
            0.01044988539069891,
            0.02208561822772026,
            -0.01748473569750786,
            -0.03073013201355934,
            0.0072016180492937565,
            -0.014326874166727066,
            0.06256701052188873,
            -0.01625140756368637,
            0.020703066140413284,
            -0.03751081973314285,
            -0.042972762137651443,
            -0.0371769443154335,
            -0.004861451219767332,
            0.013572092168033123,
            0.016422772780060768,
            -0.010473084636032581,
            -0.01849422976374626,
            -0.009536568075418472,
            -0.0045680096372962,
            -0.007215303368866444,
            -0.0019271568162366748,
            -0.004155123606324196,
            -0.03676974028348923,
            -0.013515540398657322,
            0.015585464425384998,
            -0.02108723670244217,
            -0.03959718719124794,
            -0.031756069511175156,
            -0.01706826500594616,
            0.030847737565636635,
            0.026558812707662582,
            0.020018359646201134,
            0.025428535416722298,
            -0.01801997609436512,
            0.011623990722000599,
            -0.03067658096551895,
            -0.01072617992758751,
            0.016768882051110268,
            -0.031383950263261795,
            -0.01982835866510868,
            -0.0018559704767540097,
            0.004119722172617912,
            -0.018852785229682922,
            -0.004221896640956402,
            -0.0014379159547388554,
            0.0183896254748106,
            -0.028915472328662872,
            -0.007881187833845615,
            -0.01661093533039093,
            0.004856545012444258,
            0.02103658951818943,
            0.026663130149245262,
            0.01127619668841362,
            -0.0109687689691782,
            0.00016989021969493479,
            0.01639011688530445,
            0.01773912087082863,
            0.01296115294098854,
            -0.0030346871353685856,
            0.02337697148323059,
            0.021115247160196304,
            0.03135339915752411,
            0.008869822137057781,
            0.00902736559510231,
            -0.025340478867292404,
            0.005280525889247656,
            -0.00027603626949712634,
            -0.016684480011463165,
            -0.020444994792342186,
            0.0007538267527706921,
            0.010578189976513386,
            -0.006603293586522341,
            -0.024347443133592606,
            0.0004930682480335236,
            0.004875947721302509,
            -0.0017000364605337381,
            0.028884191066026688,
            0.026597987860441208,
            -0.026745527982711792,
            -0.01714707724750042,
            -0.016568338498473167,
            -0.0008093696087598801,
            -0.013173778541386127,
            -0.007144642062485218,
            -0.026146143674850464,
            0.0009784512221813202,
            0.003777295583859086,
            -0.010026122443377972,
            -0.03697928786277771,
            -0.02211155742406845,
            -0.029403548687696457,
            -0.005437263287603855,
            0.01820877194404602,
            0.013955014757812023,
            0.007966277189552784,
            -0.020051725208759308,
            0.015999482944607735,
            -0.010288858786225319,
            0.004068039357662201,
            0.028198951855301857,
            0.02003595605492592,
            0.003892972832545638,
            -0.01562783680856228,
            0.026940729469060898,
            0.02233293652534485,
            0.004385511856526136,
            -0.005763165187090635,
            -0.016636040061712265,
            -0.013872969895601273,
            0.006397578399628401,
            -0.01806807890534401,
            -0.0035733303520828485,
            0.010769342072308064,
            -0.008161085657775402,
            -0.004436784889549017,
            -0.0001597892405698076,
            -0.02249094285070896,
            -0.009017733857035637,
            -0.019069362431764603,
            0.03734956309199333,
            -0.0029092004988342524,
            -0.025529786944389343,
            -0.01938304491341114,
            -0.02153845690190792,
            0.016337286680936813,
            -0.008689307607710361,
            0.015436639077961445,
            0.020750219002366066,
            -0.02765241265296936,
            0.024368124082684517,
            -0.014588351361453533,
            -0.037561435252428055,
            0.0041915071196854115,
            0.022883782163262367,
            -0.04446306824684143,
            0.03542592376470566,
            -0.020586276426911354,
            -0.008537658490240574,
            0.029365146532654762,
            0.022520167753100395,
            -0.011486881412565708,
            0.011133183725178242,
            -0.018733104690909386,
            0.010616080835461617,
            -0.021351691335439682,
            0.015315045602619648,
            -0.01896166242659092,
            -0.04633020609617233,
            -0.0009871929651126266,
            -0.030207481235265732,
            0.028976773843169212,
            0.006908887531608343,
            0.0397576205432415,
            -0.019250568002462387,
            0.03480526804924011,
            0.036627981811761856,
            -0.00785128865391016,
            0.008253689855337143,
            -0.017062027007341385,
            -0.028398819267749786,
            -0.048521094024181366,
            0.01520150899887085,
            0.019151197746396065,
            -0.02381439507007599,
            -0.031781598925590515,
            0.013224753551185131,
            -0.017272843047976494,
            0.010875431820750237,
            0.0077262152917683125,
            0.03941582888364792,
            0.003701247740536928,
            -0.00876146275550127,
            -0.03477085009217262,
            0.015954172238707542,
            0.01584642194211483,
            0.01257722731679678,
            0.0021218257024884224,
            -0.0023755780421197414,
            -0.022312292829155922,
            -0.0016075224848464131,
            0.019866835325956345,
            0.005613056942820549,
            0.03427617996931076,
            -0.046242356300354004,
            0.002653535222634673,
            -0.012518852949142456,
            0.018591977655887604,
            0.010545271448791027,
            0.025679251179099083,
            0.034055694937705994,
            0.016445590183138847,
            -0.006768480408936739,
            0.006175309419631958,
            -0.01568259485065937,
            -0.10910115391016006,
            0.0277219507843256,
            0.02221921645104885,
            -0.00645994208753109,
            -0.0069238534197211266,
            0.009208060801029205,
            -0.02329985424876213,
            0.01144657377153635,
            -0.019828151911497116,
            0.010623267851769924,
            -0.017066368833184242,
            -0.0030428608879446983,
            -0.001704422291368246,
            -0.004338961560279131,
            0.030353818088769913,
            0.008255962282419205,
            -0.0035371584817767143,
            -0.001625636825338006,
            -0.0051835328340530396,
            0.011113736778497696,
            0.006096973549574614,
            -0.0017541148699820042,
            0.0043281628750264645,
            -0.013449862599372864,
            0.027049778029322624,
            -0.0035462002269923687,
            -0.05742708221077919,
            -0.011041131801903248,
            0.00025998297496698797,
            -0.005716203711926937,
            -0.02293642796576023,
            -0.007269974797964096,
            -0.00305322022177279,
            -0.033950451761484146,
            0.03196709603071213,
            -0.06887694448232651,
            0.02868170477449894,
            -0.0118334935978055,
            0.025373881682753563,
            0.033359162509441376,
            -0.00942428968846798,
            -0.07611912488937378,
            -0.022887125611305237,
            0.014095094054937363,
            0.018682654947042465,
            0.026589836925268173,
            0.02040117047727108,
            0.00226619397290051,
            -0.0026812192518264055,
            -0.004439180251210928,
            0.012100358493626118,
            0.0001718396379146725,
            0.02867324836552143,
            0.04074113443493843,
            0.01423619408160448,
            0.005217116326093674,
            0.031899478286504745,
            -0.023179348558187485,
            0.00766269164159894,
            0.0007041933131404221,
            0.029521647840738297,
            -0.03276592865586281,
            0.03253096714615822,
            0.0246613509953022,
            -0.008322921581566334,
            0.015303224325180054,
            0.015717167407274246,
            0.033709023147821426,
            0.006716874428093433,
            -0.0018041223520413041,
            -0.01967490278184414,
            0.0007365014171227813,
            -0.024004818871617317,
            -0.016662949696183205,
            0.003800309030339122,
            0.012745373882353306,
            -0.011840214021503925,
            -0.007773322984576225,
            -0.03106089122593403,
            0.023686261847615242,
            -0.0022773253731429577,
            -0.01801740936934948,
            -0.014129466377198696,
            -0.023084469139575958,
            0.023535626009106636,
            -0.023495523259043694,
            -0.020212814211845398,
            -0.022078169509768486,
            0.007556450087577105,
            -0.03346015140414238,
            -0.02107863686978817,
            -0.020159514620900154,
            -0.024034183472394943,
            0.03484335541725159,
            0.03425656259059906,
            0.01675194501876831,
            0.018060017377138138,
            -0.008951804600656033,
            -0.012856801971793175,
            0.023967554792761803,
            -0.02829321101307869,
            0.005604707635939121,
            -0.010442705824971199,
            -0.01141725480556488,
            0.004328244365751743,
            0.005411507561802864,
            -0.00687150564044714,
            -0.06071681156754494,
            -0.007410429883748293,
            -0.0017782638315111399,
            -0.02713867276906967,
            -0.023832358419895172,
            -0.029918629676103592,
            0.008209781721234322,
            0.020718632265925407,
            -0.02860599383711815,
            -0.012898554094135761,
            -0.006209146697074175,
            -0.024981025606393814,
            -0.03070737235248089,
            -0.011195732280611992,
            -0.00832788459956646,
            -0.021989872679114342,
            0.0019788285717368126,
            -0.015357413329184055,
            -0.004221179988235235,
            -0.0029764093924313784,
            0.029570866376161575,
            -0.008107512257993221,
            0.011427748017013073,
            -0.010043046437203884,
            -0.017286252230405807,
            -0.025539936497807503,
            0.026268180459737778,
            -0.002361837076023221,
            0.018084894865751266,
            0.004817679058760405,
            0.015585463494062424,
            0.03226097673177719,
            0.023554569110274315,
            0.028240973129868507,
            0.019327806308865547,
            -0.02006191946566105,
            -0.009067106992006302,
            0.024248391389846802,
            -0.011600627563893795,
            0.03893475607037544,
            0.027283363044261932,
            0.012141900137066841,
            0.012262867763638496,
            0.014928202144801617,
            0.002555329818278551,
            0.009586677886545658,
            0.0024754488840699196,
            -0.007004294544458389,
            0.03296680748462677,
            -0.005203039851039648,
            0.02217061072587967,
            -0.017524098977446556,
            0.002789399353787303,
            0.014912726357579231,
            -0.03647439181804657,
            0.007520483806729317,
            0.019563790410757065,
            -0.0216851606965065,
            0.013790326192975044,
            0.01878180541098118,
            0.018050100654363632,
            -0.0317821204662323,
            -0.02359112538397312,
            -0.0037919448222965,
            0.025460533797740936,
            0.018466608598828316,
            0.0249518733471632,
            0.015241646207869053,
            0.018422067165374756,
            -0.022045552730560303,
            0.005257346201688051,
            -0.02557915449142456,
            -0.005899098236113787,
            0.025771329179406166,
            0.019834104925394058,
            -0.03641797602176666,
            -0.033752135932445526,
            0.021639136597514153,
            -0.011745213530957699,
            0.007761787157505751,
            -0.012502122670412064,
            0.03350267931818962,
            0.025906819850206375,
            -0.018405217677354813,
            0.037547577172517776,
            -0.025410505011677742,
            -0.010748174041509628,
            -0.019551893696188927,
            -0.027167152613401413,
            -0.005894312635064125,
            -0.024381592869758606,
            -0.03555547818541527,
            -0.03496493399143219,
            -0.025146162137389183,
            -0.014068753458559513,
            0.00870613195002079,
            0.012137784622609615,
            0.027134966105222702,
            0.001203175401315093,
            -0.03349127620458603,
            -0.041033025830984116,
            0.004804800730198622,
            -0.02706880494952202,
            -0.003238925477489829,
            0.014131818898022175,
            -0.021686434745788574,
            -0.053780749440193176,
            0.015448225662112236,
            0.009141513146460056,
            0.012604260817170143,
            -0.02030899003148079,
            0.02369261346757412,
            -0.01547544077038765,
            -0.02748246118426323,
            0.018323082476854324,
            0.026093846186995506,
            0.013701774179935455,
            -0.04472266882658005,
            0.024477681145071983,
            0.03368527442216873,
            -0.010242258198559284,
            -0.02516571246087551,
            0.01422831043601036,
            0.031527742743492126,
            0.010187185369431973,
            -0.01608709990978241,
            0.04165010526776314,
            -0.008091865107417107,
            -0.025252515450119972,
            0.0031821527518332005,
            -0.0015839324332773685,
            0.02400345914065838,
            -0.04402460530400276,
            -0.02468455769121647,
            0.02203160896897316,
            -0.022090332582592964,
            0.025115378201007843,
            -0.07117902487516403,
            -0.01850985176861286,
            0.027771998196840286,
            -0.011288435198366642,
            -0.029862336814403534,
            0.014382901601493359,
            -0.0065619866363704205,
            -0.02332111820578575,
            0.009081048890948296,
            0.004142603371292353,
            0.016953211277723312,
            -0.005240220110863447,
            0.02016853727400303,
            -0.024472257122397423,
            0.017608214169740677,
            -0.007953046821057796,
            -0.018399406224489212,
            0.0017435869667679071,
            6.557448796229437e-05,
            -0.019095871597528458,
            0.018816350027918816,
            -0.023225931450724602,
            -0.011200051754713058,
            0.01824425347149372,
            0.03562215343117714,
            -0.01123703271150589,
            -0.00794297643005848,
            -0.0047450256533920765,
            0.005732994060963392,
            0.005937952548265457,
            0.02992236241698265,
            -0.02996291033923626,
            0.05004815757274628,
            0.01961924508213997,
            -0.015939634293317795,
            -0.005239239893853664,
            0.027129793539643288,
            -0.02749088779091835,
            -0.018387500196695328,
            0.025914674624800682,
            -0.019867848604917526,
            -0.042144667357206345,
            -0.010526529513299465,
            -0.02775469981133938,
            0.004350522067397833,
            0.014248842373490334,
            -0.04036129638552666,
            0.05417719855904579,
            0.02570324018597603,
            -0.010632508434355259,
            -0.02672947756946087,
            -0.017021438106894493,
            -0.002457973314449191,
            -0.00599136296659708,
            -0.04496653005480766,
            -0.037230126559734344,
            -0.008887368254363537,
            0.005964429583400488,
            0.028118329122662544,
            -0.01596110686659813,
            0.002530139870941639,
            -0.014350026845932007,
            0.05214342474937439,
            -0.012790367938578129,
            0.04203640669584274,
            -0.019444329664111137,
            0.045560322701931,
            -0.01886773481965065,
            0.04553835093975067,
            -0.005072734318673611,
            -0.04201061651110649,
            0.030345452949404716,
            0.020062295719981194,
            0.03421274572610855,
            -0.046071458607912064,
            0.010607895441353321,
            -0.028154252097010612,
            0.030730867758393288,
            -0.0396377332508564,
            -0.02047692984342575,
            0.018065018579363823,
            0.032464541494846344,
            -0.010290587320923805,
            -0.02643563412129879,
            0.028899824246764183,
            -0.02027846872806549,
            -0.01830211654305458,
            -0.015063309110701084,
            0.02603727951645851,
            -0.022857172414660454,
            0.004501065704971552,
            0.016972674056887627,
            0.004200128372758627,
            0.0007191901095211506,
            0.04325591027736664,
            0.0350770466029644,
            -0.0007748589850962162,
            -0.012687056325376034,
            0.012651804834604263,
            0.02744794636964798,
            0.022273583337664604,
            0.025276347994804382,
            -0.029667410999536514,
            -0.012465110048651695,
            0.001388395088724792,
            0.006692133378237486,
            0.029194990172982216,
            0.017501745373010635,
            -0.00405186926946044,
            -0.0005552544025704265,
            0.03829371929168701,
            -0.037923045456409454,
            0.05403153970837593,
            -0.033975571393966675,
            -0.027678225189447403,
            -0.018004775047302246,
            0.0065056802704930305,
            0.024536635726690292,
            -0.0013564073015004396,
            0.001302454387769103,
            -0.003475429955869913,
            0.0017714378191158175,
            -0.02353806607425213,
            -0.015828806906938553,
            -0.011086676269769669,
            -0.032146304845809937,
            -0.005404243245720863,
            0.03196614608168602,
            -0.006513225380331278,
            -0.0014112936332821846,
            0.007753628306090832,
            0.016894109547138214,
            -0.021015848964452744,
            0.0021135348360985518,
            0.003836361225694418,
            0.011164908297359943,
            0.029038649052381516,
            0.0299434345215559,
            -0.004686407279223204,
            0.0011948122410103679,
            0.0283138919621706,
            -0.035960275679826736,
            -0.031043535098433495,
            0.020926134660840034,
            0.02781103551387787,
            -0.001293688896112144,
            0.02466019243001938,
            -0.0070660412311553955,
            -0.02356095053255558,
            0.008118459954857826,
            0.031351421028375626,
            -0.028960075229406357,
            -0.01016590092331171,
            0.015266040340065956,
            0.014248713850975037,
            -0.0035852824803441763,
            -0.0355050191283226,
            -0.03324131667613983,
            0.013213135302066803,
            -0.015544235706329346,
            0.020743265748023987,
            0.030081257224082947,
            -0.022084631025791168,
            0.02546052448451519,
            -0.04480008780956268,
            0.012731898576021194,
            0.012447603046894073,
            -0.001964931609109044,
            0.0034420632291585207,
            0.031687311828136444,
            -0.008837473578751087,
            0.027050720527768135,
            0.0479031577706337,
            0.013590983115136623,
            0.028572415933012962,
            -0.0322321392595768,
            0.0008162428275682032,
            -0.009143638424575329,
            -0.04493565484881401,
            0.040008794516325,
            -0.02164117433130741,
            -0.0014949090545997024,
            0.02511145919561386,
            0.0007338756113313138,
            0.025249380618333817,
            0.021066876128315926,
            -0.006442908197641373,
            0.006858467124402523,
            0.02990655042231083,
            0.029067236930131912,
            0.03261404484510422,
            -0.0314687117934227,
            0.01526501215994358,
            -0.01871422305703163,
            -0.02437777630984783,
            0.0017968409229069948,
            0.06708604097366333,
            0.018359985202550888,
            0.00371575728058815,
            -0.014566881582140923,
            -0.006051035597920418,
            -0.026254314929246902,
            -0.02230573445558548,
            -0.0003316916699986905,
            -0.02832111530005932,
            -0.035504039376974106,
            0.012159695848822594,
            0.025721805170178413,
            -0.01140863262116909,
            0.00340589159168303,
            0.02851342223584652,
            0.02670906111598015,
            0.005207504611462355,
            0.007656455971300602,
            0.04747052863240242,
            0.030890893191099167,
            0.01615464687347412,
            0.013107306323945522,
            -0.010775838978588581,
            0.01621527224779129,
            -0.002423976780846715,
            0.047557249665260315,
            -0.011177239008247852,
            0.025459017604589462,
            0.024845868349075317,
            0.007063656579703093,
            0.028032103553414345,
            -0.012983608059585094,
            -0.019617607817053795,
            0.01448164414614439,
            -0.030089013278484344,
            -0.024002958089113235,
            0.006555602420121431,
            -0.0003253089089412242,
            -0.005518789403140545,
            0.00395438726991415,
            0.0212906114757061,
            0.01363723911345005,
            0.015533576719462872,
            -0.01211648527532816,
            -0.03406580537557602,
            -0.013161704875528812,
            0.016235124319791794,
            0.00034025145578198135,
            0.020935140550136566,
            -0.02450978383421898,
            -0.027866007760167122,
            -0.033098042011260986,
            -0.03722808510065079,
            0.008116593584418297,
            0.01977972872555256,
            -0.0006883085588924587,
            -0.021452728658914566,
            0.040354955941438675,
            -0.0218508280813694,
            0.025703156366944313,
            0.014417213387787342,
            0.012379327788949013,
            -0.016791153699159622,
            -0.029432199895381927,
            0.027214188128709793,
            -0.033132705837488174,
            -0.006254023406654596,
            -0.0013524573296308517,
            0.007599730044603348,
            0.005021692719310522,
            -0.03688885644078255,
            -0.003749922849237919,
            0.0012162203202024102,
            -0.02094726823270321,
            -0.03109162114560604,
            -0.017885666340589523,
            0.04786653816699982,
            -0.005298853386193514,
            0.000844195659738034,
            -0.004429299384355545,
            -0.023633195087313652,
            -0.019216911867260933,
            0.01870577409863472,
            -0.018579645082354546,
            -0.0047004250809550285,
            0.0002922538260463625,
            0.015390339307487011,
            0.027507595717906952,
            0.021153219044208527,
            -0.043231334537267685,
            -0.012146683409810066,
            -0.006614397745579481,
            -0.0063011907041072845,
            -0.01904086209833622,
            -0.0007811647956259549,
            0.03399696946144104,
            -0.009948555380105972,
            0.03022785671055317,
            0.027066297829151154,
            0.04521229490637779,
            0.024593299254775047,
            0.031925320625305176,
            -0.016859380528330803,
            -0.01589493826031685,
            0.02080284059047699,
            -0.04442740976810455,
            -0.02663428708910942,
            -0.007712373044341803,
            -0.023784644901752472,
            -0.029371855780482292,
            0.0003264045517425984,
            0.03312354162335396,
            0.011028560809791088,
            0.03323771059513092,
            -0.0210343599319458,
            0.022445078939199448,
            -0.012958108447492123,
            -4.116004129173234e-05,
            0.010649296455085278,
            0.023790378123521805,
            0.01496935449540615,
            0.02897300012409687,
            -0.021489067003130913,
            0.005813421681523323,
            0.007037349045276642,
            0.01146556157618761,
            0.027068212628364563,
            0.03102404624223709,
            0.0041245571337640285,
            0.01043353695422411,
            -0.02404140867292881,
            0.018925735726952553,
            0.023073827847838402,
            0.0024793967604637146,
            0.00583750382065773,
            0.04289071634411812,
            0.02609182707965374
        ],
        [
            -0.013624592684209347,
            -0.014146334491670132,
            -0.006160755641758442,
            -0.017786499112844467,
            0.0014171733055263758,
            0.021120551973581314,
            0.027256648987531662,
            -0.017442742362618446,
            0.0011660180753096938,
            0.00492458138614893,
            -0.017061669379472733,
            0.03866039216518402,
            0.02022867277264595,
            -0.059956200420856476,
            -0.02506699226796627,
            0.01879979483783245,
            0.008006873540580273,
            -0.03727596625685692,
            0.01200398150831461,
            -0.024335285648703575,
            -0.011663501150906086,
            0.013677127659320831,
            0.002260213252156973,
            -0.014134516008198261,
            0.0024809816386550665,
            0.004973473027348518,
            -0.01136596966534853,
            -0.04032815992832184,
            0.036972787231206894,
            0.010276821441948414,
            0.004000549670308828,
            0.0011057498631998897,
            0.0025779721327126026,
            -0.041783832013607025,
            0.0192707609385252,
            -0.020015103742480278,
            0.007281741127371788,
            -0.0037263743579387665,
            0.002505817450582981,
            -0.029121123254299164,
            0.005830465815961361,
            -0.03340480104088783,
            -0.00994889996945858,
            -0.00225994479842484,
            0.014784572646021843,
            -0.029351554811000824,
            -0.02655159868299961,
            -0.036791589111089706,
            -0.021004507318139076,
            0.04183546081185341,
            0.014974390156567097,
            -0.004882704000920057,
            0.0009958366863429546,
            0.03498117998242378,
            -0.04366115853190422,
            -0.029805144295096397,
            0.004329397808760405,
            0.03331262990832329,
            -0.00199021864682436,
            0.028930814936757088,
            0.003914298955351114,
            0.0032332190312445164,
            -0.050573330372571945,
            -0.02107665129005909,
            -0.011351495049893856,
            0.012442183680832386,
            0.0369427390396595,
            0.00927222054451704,
            -0.02677462249994278,
            -0.0016577811911702156,
            0.03057127073407173,
            -0.04811115935444832,
            -0.03481867164373398,
            -0.02095336653292179,
            -0.05706018954515457,
            -0.0034171610604971647,
            -0.008299986831843853,
            0.018938541412353516,
            -0.01770397089421749,
            0.05096639320254326,
            -0.019090410321950912,
            -0.0026931881438940763,
            -0.037585578858852386,
            -0.02832436002790928,
            0.03352697938680649,
            -0.027316059917211533,
            0.023796256631612778,
            -0.03951628506183624,
            0.016986163333058357,
            -0.012641912326216698,
            0.004802369978278875,
            -0.03340527415275574,
            -0.019701534882187843,
            -0.01290888525545597,
            -0.022380005568265915,
            0.012039510533213615,
            0.022785935550928116,
            0.0005758913466706872,
            0.045864228159189224,
            0.006685137748718262,
            -0.012572715990245342,
            0.027991920709609985,
            -0.029702292755246162,
            -0.023103905841708183,
            -0.0008315641316585243,
            -0.03008357249200344,
            -0.015592998825013638,
            -0.023741060867905617,
            0.04977156221866608,
            9.322170080849901e-05,
            0.024770069867372513,
            0.038037434220314026,
            0.0009816634701564908,
            -0.012888696976006031,
            -0.03734658285975456,
            -0.014065759256482124,
            0.01608399674296379,
            -0.050521500408649445,
            0.012045235373079777,
            0.012682702392339706,
            0.03127109259366989,
            0.0021347696892917156,
            -0.08403811603784561,
            -0.02800213173031807,
            -0.02934144251048565,
            -0.03372913971543312,
            0.05698458477854729,
            0.0018833419308066368,
            -0.01659441739320755,
            -0.02039407193660736,
            -0.003160586580634117,
            -0.027394354343414307,
            0.027198685333132744,
            -0.06089382991194725,
            -0.010666843503713608,
            0.018255071714520454,
            -0.013436681590974331,
            0.038050826638936996,
            -0.03092767484486103,
            0.0010474319569766521,
            -0.037366315722465515,
            -0.01420849934220314,
            0.017585691064596176,
            0.006741066463291645,
            0.05049808695912361,
            -0.060057029128074646,
            -0.03070201724767685,
            0.0134163498878479,
            -0.03906543552875519,
            0.0034397393465042114,
            -0.06499848514795303,
            -0.03362935408949852,
            -0.024465907365083694,
            -0.06355549395084381,
            -0.03752104938030243,
            -0.0015104247722774744,
            -0.033382587134838104,
            -0.035971492528915405,
            0.04591512680053711,
            0.026182932779192924,
            0.03082217462360859,
            0.027223272249102592,
            0.04251159355044365,
            0.040237776935100555,
            -0.021253643557429314,
            -0.062007177621126175,
            0.005067252553999424,
            0.020757565274834633,
            -0.010272722691297531,
            -0.014489848166704178,
            0.007402293384075165,
            0.012415251694619656,
            -0.03907910734415054,
            -0.04405996948480606,
            0.0375869981944561,
            0.00546360295265913,
            -0.020932592451572418,
            0.0033504634629935026,
            0.031161317601799965,
            -0.018112681806087494,
            -0.013621492311358452,
            -0.003726664697751403,
            0.017700331285595894,
            -0.021014446392655373,
            -0.017870372161269188,
            -0.030187534168362617,
            0.0043707252480089664,
            -0.05144047364592552,
            -0.07114885002374649,
            -0.023594258353114128,
            0.045766204595565796,
            0.0017064247513189912,
            -0.010561410337686539,
            0.0009362865821458399,
            -0.024876410141587257,
            -0.05040976032614708,
            0.001758194644935429,
            0.02098027803003788,
            0.0258957352489233,
            0.027966266497969627,
            -0.008479980751872063,
            0.026920733973383904,
            -0.062197647988796234,
            -0.0302293598651886,
            0.07315286248922348,
            0.026508333161473274,
            -0.026042187586426735,
            -0.0009004492312669754,
            -0.06349152326583862,
            0.00624128757044673,
            0.0474257692694664,
            -0.022526318207383156,
            0.020798608660697937,
            -0.05737195536494255,
            0.03231583535671234,
            -0.0169418603181839,
            -0.02801864966750145,
            -0.042114511132240295,
            -0.018299037590622902,
            0.014842072501778603,
            0.02626480534672737,
            0.019080787897109985,
            -0.015335697680711746,
            0.00522989546880126,
            0.010141909122467041,
            0.0117036746814847,
            0.005292788613587618,
            0.0073289647698402405,
            -0.009485891088843346,
            -0.002723642624914646,
            -0.0017016143538057804,
            -0.0002502329589333385,
            -0.01270555891096592,
            -0.04859599098563194,
            -0.026822896674275398,
            -0.03212275356054306,
            -0.014261203818023205,
            0.012551792897284031,
            -0.030488556250929832,
            0.01941613480448723,
            -0.03649776428937912,
            -0.008795198053121567,
            -0.028389763087034225,
            0.004904425237327814,
            -0.013019884005188942,
            -0.019941579550504684,
            -0.01514300238341093,
            0.004901933949440718,
            0.010871545411646366,
            -0.052567653357982635,
            0.031046990305185318,
            -0.03992733731865883,
            0.030645066872239113,
            -0.03167717903852463,
            0.015273724682629108,
            -0.026774335652589798,
            0.03211989253759384,
            0.03705492243170738,
            -0.044758059084415436,
            -0.003775418270379305,
            -0.03026004508137703,
            -0.03733348473906517,
            0.02108641155064106,
            0.026966217905282974,
            0.04020656645298004,
            -0.04706161841750145,
            -0.024996038526296616,
            0.01989573985338211,
            -0.011597182601690292,
            0.025003641843795776,
            0.030146028846502304,
            -0.060973551124334335,
            -0.018208058550953865,
            -0.004527672193944454,
            -0.009834982454776764,
            -0.017866481095552444,
            -0.004402610473334789,
            0.022151775658130646,
            0.0325179323554039,
            -0.023405475541949272,
            0.026008203625679016,
            0.008672216907143593,
            0.02381173148751259,
            0.01948881335556507,
            0.00792733859270811,
            0.008232194930315018,
            -0.0022434929851442575,
            -0.037581998854875565,
            0.012558648362755775,
            0.022253958508372307,
            -0.025744125247001648,
            0.014533891342580318,
            0.011284051463007927,
            -0.04654460400342941,
            0.010873619467020035,
            0.02093343809247017,
            -0.05122809857130051,
            -0.0002818294451572001,
            0.0008660772582516074,
            -0.04483377933502197,
            -0.015845032408833504,
            0.03579552471637726,
            -0.04450298473238945,
            -0.029768334701657295,
            -0.01909790001809597,
            0.06236368790268898,
            -0.043477654457092285,
            -0.033224210143089294,
            -0.013996226713061333,
            -0.02101130038499832,
            -0.00968420971184969,
            -0.017784669995307922,
            0.022214865311980247,
            0.019292553886771202,
            -0.004341355059295893,
            -0.0557437464594841,
            0.019861135631799698,
            -0.010977916419506073,
            0.03426806256175041,
            -0.0035438556224107742,
            -0.040903978049755096,
            0.03152325004339218,
            -0.046914540231227875,
            -0.056191254407167435,
            0.00447325361892581,
            -0.02419774793088436,
            -0.017802266404032707,
            -0.020653557032346725,
            -0.015122229233384132,
            0.008117415942251682,
            -0.05369551479816437,
            0.0284336619079113,
            0.01237418781965971,
            -0.024134965613484383,
            -0.013600190170109272,
            -0.05829457938671112,
            -0.008133186027407646,
            0.03613052889704704,
            0.005811365321278572,
            -0.005502516403794289,
            -0.030576929450035095,
            0.027982352301478386,
            0.002308988943696022,
            -0.018489524722099304,
            -0.03164919838309288,
            -0.021118618547916412,
            0.018887925893068314,
            -0.030208023265004158,
            -0.012156268581748009,
            0.007037341594696045,
            0.025908757001161575,
            -0.05335477739572525,
            0.004545994568616152,
            -0.02031366340816021,
            0.005615387111902237,
            -0.003012979170307517,
            0.03186904266476631,
            0.02015519142150879,
            -0.05173578858375549,
            0.052178602665662766,
            0.0034447526559233665,
            -0.01318325661122799,
            -0.02392403967678547,
            0.03108597733080387,
            -0.006530338432639837,
            0.024312352761626244,
            -0.004376678727567196,
            0.013364909216761589,
            -0.03375490382313728,
            -0.026627836748957634,
            -0.015599208883941174,
            -0.009073636494576931,
            0.003659277455881238,
            -0.03972854092717171,
            -0.061659157276153564,
            0.027436446398496628,
            0.012781147845089436,
            0.022646620869636536,
            -0.04956244304776192,
            0.026111340150237083,
            -0.10846588760614395,
            -0.014162929728627205,
            0.013881145976483822,
            0.006106899585574865,
            0.023269860073924065,
            0.025108886882662773,
            0.01033979095518589,
            -0.021724438294768333,
            -0.003263998543843627,
            -0.009891106747090816,
            0.03629961982369423,
            0.032579369843006134,
            -0.014978003688156605,
            0.005396603140980005,
            0.00848536379635334,
            -0.012811471708118916,
            -0.0023346797097474337,
            -0.052459340542554855,
            -0.008505874313414097,
            0.06549646705389023,
            -0.045780058950185776,
            -0.04632044956088066,
            -0.003070792416110635,
            -0.008973564021289349,
            0.0066789183765649796,
            -0.024635672569274902,
            -0.03199475258588791,
            0.012211657129228115,
            0.018060989677906036,
            0.00920793041586876,
            -0.007275234442204237,
            0.04477081447839737,
            -0.005839959718286991,
            0.011016197502613068,
            -0.016768140718340874,
            -0.0019051532726734877,
            -0.010154291056096554,
            0.008391033858060837,
            -0.0043106419034302235,
            -0.011108947917819023,
            -0.07885763794183731,
            -0.04337506741285324,
            0.0705135315656662,
            0.02008468471467495,
            -0.019775018095970154,
            -0.04439062997698784,
            -0.019256362691521645,
            0.07449888437986374,
            0.002485086442902684,
            -0.006890157703310251,
            -0.01104093436151743,
            0.016967125236988068,
            -0.02332231216132641,
            0.01777505688369274,
            -0.030755743384361267,
            -0.005809646099805832,
            0.0371360182762146,
            -0.04165950417518616,
            -0.03648420795798302,
            -0.055259305983781815,
            0.050761524587869644,
            -0.04844348505139351,
            -0.002847188152372837,
            0.03160630911588669,
            -0.013070511631667614,
            -0.03693808987736702,
            0.02106127329170704,
            0.003813324496150017,
            -0.0263034887611866,
            0.025921475142240524,
            -0.00021185397054068744,
            -0.006574254482984543,
            0.035569269210100174,
            -0.012147382833063602,
            0.02920631319284439,
            0.006978570017963648,
            0.03060336224734783,
            0.034640125930309296,
            0.03552864119410515,
            -0.023160995915532112,
            0.035775359719991684,
            -0.029117072001099586,
            0.024125592783093452,
            -0.040068186819553375,
            -0.03438737243413925,
            -0.045581281185150146,
            -0.04251962527632713,
            0.02160867489874363,
            -0.060903556644916534,
            -0.010228165425360203,
            -0.03878466784954071,
            -0.037288323044776917,
            -0.029985828325152397,
            0.0246462132781744,
            -0.07133769989013672,
            -0.07600230723619461,
            -0.020339297130703926,
            0.07521083205938339,
            0.023309016600251198,
            0.0009335337672382593,
            0.020790966227650642,
            0.01798241026699543,
            0.0176350399851799,
            -0.03136294335126877,
            -0.057462360709905624,
            -0.015058166347444057,
            -0.002197348279878497,
            0.021316928789019585,
            -0.02320803329348564,
            -0.00867529772222042,
            -0.045495081692934036,
            0.021303463727235794,
            -0.03864554315805435,
            -0.03504237160086632,
            0.03407909721136093,
            -0.009886500425636768,
            -0.04754556342959404,
            -0.026242008432745934,
            0.009893040172755718,
            0.012366630136966705,
            0.01758410781621933,
            -0.02235187403857708,
            -0.02412060648202896,
            0.046499695628881454,
            0.028828710317611694,
            0.03310389444231987,
            0.017456332221627235,
            -0.009612351655960083,
            0.020118746906518936,
            -0.0009829483460634947,
            -0.01485470961779356,
            -0.0830286517739296,
            0.0057640476152300835,
            -0.012759225443005562,
            0.066118024289608,
            0.0075738332234323025,
            0.008926672860980034,
            0.008063899353146553,
            0.02505720779299736,
            0.006796170026063919,
            0.052416060119867325,
            -0.024781430140137672,
            0.015245173126459122,
            0.0031111184507608414,
            -0.017859896644949913,
            -0.007996139116585255,
            0.028946811333298683,
            0.0283192228525877,
            -0.006769490893930197,
            -0.08103948831558228,
            0.00023782358039170504,
            -0.004867582581937313,
            -0.009471372701227665,
            -0.028229454532265663,
            -0.038212526589632034,
            -0.016480987891554832,
            0.015845470130443573,
            0.01351669430732727,
            0.003487698035314679,
            0.030602995306253433,
            -0.012817921116948128,
            -0.00042129645589739084,
            -0.06565054506063461,
            0.07363761216402054,
            -0.00884192530065775,
            -0.06075844541192055,
            -0.013263422064483166,
            -0.006131905131042004,
            -0.0011412171879783273,
            0.036009177565574646,
            0.02075299806892872,
            0.012583333067595959,
            -0.007238336838781834,
            0.0005068198661319911,
            -0.030916852876544,
            -0.02810056507587433,
            -0.044037073850631714,
            -0.053146325051784515,
            0.0012861040886491537,
            0.020285602658987045,
            -0.012151909060776234,
            0.026508459821343422,
            -0.021476970985531807,
            -0.026476899161934853,
            -0.023841850459575653,
            -0.05941200256347656,
            0.04340609908103943,
            0.01672896184027195,
            -0.08608086407184601,
            0.024699702858924866,
            -0.01696799509227276,
            -0.00017655092233326286,
            -0.0448819138109684,
            0.005170351825654507,
            -0.053419411182403564,
            -0.035685423761606216,
            -0.006754107307642698,
            -0.030282890424132347,
            -0.008852330036461353,
            -0.030086584389209747,
            0.032562255859375,
            0.021354613825678825,
            0.019516563042998314,
            0.0016412322875112295,
            0.007582883816212416,
            -0.0025167190469801426,
            -0.02610849402844906,
            0.017457004636526108,
            0.006226843688637018,
            -0.012510063126683235,
            -0.04344761371612549,
            0.004222755320370197,
            -0.04113193601369858,
            -0.01791704073548317,
            -0.010849492624402046,
            -0.006663336418569088,
            -0.009390282444655895,
            0.02619064599275589,
            0.0031434849370270967,
            0.0010915077291429043,
            -0.030774934217333794,
            -0.042777176946401596,
            0.0017629419453442097,
            -0.046255018562078476,
            0.03766429051756859,
            -0.010049963369965553,
            -0.027028406038880348,
            0.025276515632867813,
            -0.025997744873166084,
            0.03923288732767105,
            -0.0024719981011003256,
            -0.01013729814440012,
            -0.03974005952477455,
            -0.015588643029332161,
            -0.030600108206272125,
            0.017834186553955078,
            -0.05487152561545372,
            0.015130226500332355,
            -0.04051683470606804,
            -0.009566300548613071,
            -0.04809505492448807,
            0.018501389771699905,
            0.030959133058786392,
            0.0289131049066782,
            0.0092905443161726,
            -0.01755560003221035,
            0.03906225040555,
            -0.024456044659018517,
            0.02305690385401249,
            -0.0035017021000385284,
            -0.03170778974890709,
            0.025157367810606956,
            0.03659921884536743,
            -0.07727033644914627,
            0.0071348948404192924,
            -0.009880351833999157,
            -0.029424099251627922,
            0.014932344667613506,
            -0.0032056565396487713,
            0.01788982003927231,
            0.0055967336520552635,
            0.0019422671757638454,
            -0.056091103702783585,
            -0.0028640180826187134,
            -0.027152124792337418,
            0.01724613830447197,
            0.06503880769014359,
            0.018884649500250816,
            -0.0007717814296483994,
            -0.038498736917972565,
            -0.0007613935740664601,
            0.00013983451935928315,
            -0.0063368589617311954,
            0.008761046454310417,
            -0.013863378204405308,
            0.024757353588938713,
            0.021378373727202415,
            -0.0005198755534365773,
            -0.022952008992433548,
            -0.012424043379724026,
            -0.031222954392433167,
            0.005583230406045914,
            -0.008620069362223148,
            -0.0125646460801363,
            -0.004620095249265432,
            0.011042493395507336,
            -0.021760430186986923,
            -0.027695685625076294,
            -0.009694341570138931,
            0.003117501502856612,
            0.029618004336953163,
            -0.026102375239133835,
            -0.04001295194029808,
            0.023541763424873352,
            -0.022144554182887077,
            -0.0221718642860651,
            -0.016823522746562958,
            0.03352659195661545,
            -0.022170964628458023,
            0.03482424467802048,
            0.027550527825951576,
            -0.018303589895367622,
            0.01977050118148327,
            0.03686876595020294,
            -0.010289029218256474,
            0.04304094240069389,
            -0.04936760291457176,
            0.0051766629330813885,
            -0.01732843555510044,
            0.011432825587689877,
            -0.056596752256155014,
            0.004159524571150541,
            -0.03962143883109093,
            0.0017327109817415476,
            0.024898210540413857,
            0.022184249013662338,
            -0.01954878307878971,
            -0.00488056056201458,
            -0.014310280792415142,
            0.017870571464300156,
            -0.007810602430254221,
            -0.020776499062776566,
            0.010013892315328121,
            -0.013935628347098827,
            0.058527715504169464,
            0.05879786238074303,
            -0.013813083991408348,
            -0.005429022945463657,
            0.0037698904052376747,
            0.011898437514901161,
            -0.009213907644152641,
            0.0032985254656523466,
            -0.026724766939878464,
            -0.02517499215900898,
            0.00029258892755024135,
            -0.013474684208631516,
            0.03057369962334633,
            -0.023506639525294304,
            -0.03009287267923355,
            -0.026049872860312462,
            0.04784994572401047,
            0.02701202780008316,
            -0.05702237784862518,
            0.013066243380308151,
            0.022971821948885918,
            -0.01168728619813919,
            -0.014343387447297573,
            -0.003790880786255002,
            -0.042547617107629776,
            -0.027885250747203827,
            0.04169965535402298,
            -0.0186186283826828,
            0.008772216737270355,
            -0.032278675585985184,
            -0.000994945759885013,
            -0.03691466525197029,
            -0.002990094246342778,
            -0.002833797363564372,
            -0.006246570032089949,
            0.007725900504738092,
            -0.02551497519016266,
            -0.005557268857955933,
            0.016872119158506393,
            -0.02844979055225849,
            -0.030487975105643272,
            0.018314173445105553,
            0.01652534492313862,
            -0.04425254091620445,
            -0.00851459614932537,
            -0.013078469783067703,
            -0.008862227201461792,
            0.0004904633387923241,
            -0.005504566244781017,
            -0.028857316821813583,
            -0.02824956178665161,
            0.0024424302391707897,
            0.029892422258853912,
            0.016953302547335625,
            -0.015050284564495087,
            -0.003773580538108945,
            0.016875289380550385,
            -0.06797885149717331,
            -0.008292688056826591,
            0.04084193333983421,
            0.006013769190758467,
            -0.048702728003263474,
            -0.005570203065872192,
            0.011238054372370243,
            0.01371549442410469,
            -0.031461358070373535,
            0.03163183853030205,
            0.053867992013692856,
            -0.07075434178113937,
            -0.0018855818780139089,
            -0.05127343162894249,
            -0.007955965586006641,
            0.008094756864011288,
            -0.006224633194506168,
            0.0001374567800667137,
            0.02162094973027706,
            -0.026989974081516266,
            0.015347746200859547,
            -0.0410357229411602,
            0.05014268308877945,
            -0.042417172342538834,
            -0.030600327998399734,
            -0.029235323891043663,
            -0.018144402652978897,
            0.03961601108312607,
            0.03052937425673008,
            -0.01660100743174553,
            0.002370087895542383,
            -0.02715926244854927,
            0.017932623624801636,
            0.0468142144382,
            -0.009446305222809315,
            -0.0174300167709589,
            -0.032122813165187836,
            -0.01210982259362936,
            0.008023173548281193,
            -0.01425250992178917,
            -0.03703567013144493,
            -0.007516658864915371,
            0.0120436642318964,
            0.014601490460336208,
            -0.023496730253100395,
            -0.05496899038553238,
            0.02145078033208847,
            -0.009911218658089638,
            -0.016984138637781143,
            -0.010874705389142036,
            0.04188363626599312,
            -0.006990562658756971,
            -0.05368306115269661,
            -0.03463699668645859,
            0.024354416877031326,
            0.012318560853600502,
            -0.024146175011992455,
            -0.04353058710694313,
            -0.009541550651192665,
            0.058426883071660995,
            0.010882798582315445,
            -0.04405588656663895,
            -0.017851000651717186,
            -0.009881582111120224,
            -0.010897727683186531,
            0.037189334630966187,
            0.014765512198209763,
            0.019741373136639595,
            0.006605722475796938,
            0.013281830586493015,
            -0.005741133354604244,
            -0.03281360864639282,
            0.022111617028713226,
            -0.07642781734466553,
            -0.00868105236440897,
            0.010415211319923401,
            0.01571280136704445,
            -0.03717201203107834,
            -0.051699887961149216,
            -0.018917575478553772,
            -0.050690606236457825,
            -0.024241367354989052,
            -0.0069702379405498505,
            0.013326873071491718,
            0.035168927162885666,
            -0.038004159927368164,
            0.00255732424557209,
            0.006941128056496382,
            0.03715530037879944,
            -0.02830020897090435,
            -0.04624396190047264,
            0.042043328285217285,
            -0.04391641169786453,
            -0.013927308842539787,
            -0.02993680350482464,
            0.00829510297626257,
            -0.04139561951160431,
            -0.018945058807730675,
            -0.033140961080789566,
            0.06151646003127098,
            -0.019385207444429398,
            -0.06464854627847672,
            0.03235304355621338,
            -0.00885910913348198,
            0.022127898409962654,
            -0.015772802755236626,
            -0.020610542967915535,
            0.013753228820860386,
            -0.0013680829433724284,
            -0.022523419931530952,
            0.034175653010606766,
            0.018671123310923576,
            -0.008711247704923153,
            0.0003513020637910813,
            -0.07563495635986328,
            -0.035117607563734055,
            -0.027229653671383858,
            0.007801754865795374,
            0.022797372192144394,
            -0.09295400232076645,
            0.01815170608460903,
            0.02564663626253605,
            0.014992443844676018,
            -0.0021165753714740276,
            -0.021106364205479622,
            0.008575743064284325,
            -0.03724738582968712,
            -0.03453979641199112,
            0.0014760354533791542,
            0.05991300567984581,
            0.001987418159842491,
            0.0034970801789313555,
            -0.04011436179280281,
            0.05839879438281059,
            0.02607550472021103,
            -0.00033345536212436855,
            0.07331123948097229,
            -0.036771468818187714,
            -0.03965967521071434,
            0.02982926182448864,
            0.027638835832476616,
            -0.05127670615911484,
            0.028997644782066345,
            -0.0021896108519285917,
            -0.048769738525152206,
            0.006722698453813791,
            -0.027091285213828087,
            -0.018156984820961952,
            -0.010037499479949474,
            0.014454876072704792,
            0.018544156104326248,
            -0.004957793280482292,
            -0.01096095610409975,
            -0.04842720925807953,
            0.025526518002152443,
            0.038729794323444366,
            0.0006996436277404428,
            0.004967852961272001,
            0.009184066206216812,
            0.006201684474945068,
            0.0014964148867875338,
            -0.010448021814227104,
            -0.013542578555643559,
            0.0018794347997754812,
            -0.0116494195535779,
            -0.03259757533669472,
            0.01897912658751011,
            0.014965075999498367,
            0.0013597733341157436,
            -0.0012060253648087382,
            0.0030992517713457346,
            -0.028109755367040634,
            0.032939568161964417,
            -0.0021059494465589523,
            -0.031338952481746674,
            -0.014105059206485748,
            0.0007756134727969766,
            0.005407053045928478,
            0.00940005388110876,
            -0.015628252178430557,
            0.010480674915015697,
            -0.0010146520799025893,
            -0.0030367367435246706,
            -0.015526189468801022,
            -0.004920692648738623,
            -0.0011297473683953285,
            -0.032741159200668335,
            -0.022349895909428596,
            0.030448347330093384,
            0.08139496296644211,
            -0.02174275740981102,
            0.01305121649056673,
            0.02559891901910305,
            -0.034501370042562485,
            0.016039185225963593,
            -0.0197050329297781,
            0.01896209456026554,
            0.07407781481742859,
            -0.03506317362189293,
            0.0037675732746720314,
            0.0481632761657238,
            0.024072391912341118,
            -0.012794754467904568,
            -0.0006340612308122218,
            0.03299769014120102,
            -0.013261147774755955,
            0.025093451142311096,
            0.020570646971464157,
            -0.031695619225502014,
            -0.04422904551029205,
            -0.016400273889303207,
            -0.001966161886230111,
            0.021465284749865532,
            0.03163786232471466,
            -0.025170521810650826,
            0.02411293238401413,
            -0.021363642066717148,
            -0.02586754597723484,
            -0.01937352493405342,
            0.00042100672726519406,
            -0.04129348695278168,
            -0.005149347707629204,
            -0.019285162910819054,
            -0.00777574023231864,
            -0.01595289260149002,
            -0.050958301872015,
            -0.0028449678793549538,
            0.022020287811756134,
            0.0037209196016192436,
            0.004135454073548317,
            -0.03277222812175751,
            -0.0004805921344086528,
            -0.033378876745700836,
            -0.031403347849845886,
            0.004223554860800505,
            0.02188928984105587,
            0.01085569616407156,
            -0.06139140576124191,
            0.013706887140870094,
            -0.0016317289555445313,
            -0.09834444522857666,
            -0.02843751199543476,
            0.012969289906322956,
            0.04297729209065437,
            -0.06559870392084122,
            -0.02993270382285118,
            0.0271287951618433,
            -0.057062674313783646,
            0.03763991594314575,
            0.01455867849290371,
            -0.005191815551370382,
            -0.00015035568503662944,
            0.0040836031548678875,
            0.009672384709119797,
            -0.026066431775689125,
            -0.018214266747236252,
            -0.023157726973295212,
            0.010691439732909203,
            -0.025989316403865814,
            0.03857045620679855,
            -0.02889687940478325,
            -0.06649796664714813,
            -0.041576772928237915
        ],
        [
            -0.033687856048345566,
            0.004173170775175095,
            -0.022038130089640617,
            -0.021392948925495148,
            -0.002380476100370288,
            0.007219519000500441,
            -0.021650975570082664,
            -0.012660099193453789,
            -0.003350639482960105,
            -0.008614787831902504,
            0.014558093622326851,
            -0.03308556601405144,
            0.009550251066684723,
            0.01178075186908245,
            -0.02342124469578266,
            -0.05970286205410957,
            -0.01156245730817318,
            -0.0028508121613413095,
            -0.06562240421772003,
            0.020531924441456795,
            -0.0025282499846071005,
            -0.0033670663833618164,
            0.025124579668045044,
            -0.01364012062549591,
            5.314089867169969e-05,
            0.016600672155618668,
            -0.01900305785238743,
            -0.024617904797196388,
            -0.03553786501288414,
            0.011620711535215378,
            -0.017054403200745583,
            0.008496008813381195,
            0.02836703136563301,
            0.0006988286622799933,
            -0.012798100709915161,
            0.03944975510239601,
            -0.02810530923306942,
            0.0337945930659771,
            -0.013026684522628784,
            0.009716903790831566,
            0.025309469550848007,
            -0.032301176339387894,
            -0.010368452407419682,
            -0.008313330821692944,
            -0.005938813555985689,
            0.03123093955218792,
            0.004854370839893818,
            0.041591741144657135,
            0.014523904770612717,
            0.04332663491368294,
            -0.007374902255833149,
            -0.008088013157248497,
            -0.004639505874365568,
            0.009827069006860256,
            0.03752077743411064,
            0.006953143980354071,
            0.02829810604453087,
            0.020583979785442352,
            0.02465849369764328,
            -0.030345873907208443,
            -0.001808781293220818,
            -0.013749769888818264,
            -0.037250205874443054,
            0.03789808973670006,
            0.04009880870580673,
            0.012437796220183372,
            -0.0656992644071579,
            -0.02318752370774746,
            -0.0029663708992302418,
            -0.03737812861800194,
            -0.04457738623023033,
            0.00249886023811996,
            0.022827276960015297,
            -0.005201623309403658,
            0.005732365883886814,
            0.036562107503414154,
            -0.016474461182951927,
            -0.028137188404798508,
            0.0055663748644292355,
            -0.031872112303972244,
            0.007982549257576466,
            -0.036159273236989975,
            -0.030697332695126534,
            -0.035104524344205856,
            -0.0046435995027422905,
            -0.006726003717631102,
            0.01937885582447052,
            0.0188677366822958,
            -0.0032912541646510363,
            0.03296002373099327,
            0.018157673999667168,
            0.02003156952559948,
            0.025851979851722717,
            -0.006151836831122637,
            0.02154545858502388,
            0.0029610483907163143,
            -0.04490058496594429,
            0.006085083819925785,
            -0.07253780215978622,
            0.005991585552692413,
            0.043381061404943466,
            0.07986858487129211,
            0.03497512266039848,
            0.006171482615172863,
            -0.020318081602454185,
            0.024196632206439972,
            -0.008757156319916248,
            0.0006419864948838949,
            -0.03622692450881004,
            0.03426128253340721,
            0.00371951749548316,
            -0.011592943221330643,
            -0.0024817485827952623,
            0.026287168264389038,
            -0.07256509363651276,
            0.020829131826758385,
            0.042076803743839264,
            0.04605386406183243,
            -0.03135170787572861,
            -0.015582697466015816,
            -0.0022561021614819765,
            0.009923925623297691,
            -0.017879363149404526,
            -0.011107420548796654,
            0.027171866968274117,
            0.026160800829529762,
            0.02309643104672432,
            0.017898740246891975,
            -0.020894385874271393,
            0.004330267198383808,
            -0.05897343158721924,
            -0.038815781474113464,
            -0.028744157403707504,
            0.0074648549780249596,
            -0.01611221581697464,
            -0.060556914657354355,
            0.02047230303287506,
            -0.002497964771464467,
            -0.05053240433335304,
            0.028438081964850426,
            -0.00765613978728652,
            -0.014790727756917477,
            -0.002129175001755357,
            0.022487470880150795,
            -0.013745782896876335,
            -0.016018355265259743,
            -0.031161144375801086,
            -0.015274859964847565,
            0.0047857072204351425,
            0.00630571274086833,
            -0.06709477305412292,
            -0.0023690660018473864,
            0.0032375252339988947,
            -0.0368499681353569,
            0.013695930130779743,
            0.044718947261571884,
            -0.017880737781524658,
            0.013779514469206333,
            0.017048778012394905,
            -0.06148562207818031,
            -0.02014986239373684,
            0.023547915741801262,
            -0.09450247138738632,
            -0.015480339527130127,
            0.017105812206864357,
            -0.04920713230967522,
            -0.004141679964959621,
            -0.00017709833628032357,
            0.05183913931250572,
            0.017193375155329704,
            -0.016125565394759178,
            0.014967159368097782,
            -0.02657674252986908,
            -0.005014172289520502,
            0.011989342048764229,
            0.03462855517864227,
            0.028898542746901512,
            -0.007954388856887817,
            0.0009987526573240757,
            -0.04888511449098587,
            0.010801655240356922,
            0.01962057501077652,
            -0.004750061314553022,
            -0.016771210357546806,
            -0.04778113588690758,
            -0.015936177223920822,
            0.003584543475881219,
            -0.003245052183046937,
            -0.023628434166312218,
            -0.0366055928170681,
            -0.04627877101302147,
            -0.03288848325610161,
            0.04142186790704727,
            0.018090924248099327,
            -0.006757519673556089,
            -0.006719051860272884,
            -0.006894807331264019,
            -0.007054420188069344,
            -0.04771072417497635,
            -0.017911337316036224,
            0.012102566659450531,
            -0.06466301530599594,
            -0.021016983315348625,
            -0.01778128184378147,
            -0.03588367998600006,
            0.0041964673437178135,
            -0.00211786269210279,
            -0.05449042096734047,
            0.015193644911050797,
            0.005946792662143707,
            -0.021210065111517906,
            -0.006449212320148945,
            -0.027496837079524994,
            -0.029082296416163445,
            -0.05179078131914139,
            -0.0046119182370603085,
            0.034765154123306274,
            -0.015049387700855732,
            0.00694091385230422,
            -0.02448091469705105,
            -0.03971090540289879,
            0.033626507967710495,
            0.011156723834574223,
            0.03307102248072624,
            -0.013147269375622272,
            0.05160083249211311,
            0.007725669536739588,
            -0.037470344454050064,
            0.020977981388568878,
            0.022789349779486656,
            0.03036048635840416,
            0.012643380090594292,
            0.02614469639956951,
            0.02361101284623146,
            0.0033574707340449095,
            0.01934846304357052,
            -0.007420312613248825,
            0.03498906269669533,
            -0.008799416013062,
            -0.0023620736319571733,
            -0.005682343617081642,
            0.004256152547895908,
            -0.02455879934132099,
            0.038366418331861496,
            0.014346711337566376,
            -0.022217901423573494,
            -0.03713087737560272,
            0.03544989973306656,
            -0.025169476866722107,
            -0.0455770380795002,
            0.003729234216734767,
            0.021210087463259697,
            0.022682642564177513,
            0.005600260570645332,
            -0.02269616909325123,
            0.010132203809916973,
            -0.013067103922367096,
            -0.007410665974020958,
            0.02771846204996109,
            0.017615633085370064,
            0.0317557118833065,
            0.009912541136145592,
            0.013200805522501469,
            0.001809646375477314,
            -0.06267212331295013,
            -0.00671657919883728,
            0.0043975356966257095,
            0.03118004836142063,
            -0.01116004679352045,
            -0.007488923147320747,
            0.039238158613443375,
            0.004623794928193092,
            -0.025795385241508484,
            -0.0002362844388699159,
            3.1879138987278566e-05,
            0.007743267808109522,
            0.015044399537146091,
            0.010365244001150131,
            -0.0034264526329934597,
            -0.0072306077927351,
            0.03006858192384243,
            0.00452739791944623,
            0.00660660257562995,
            -0.0025876760482788086,
            -0.0060518174432218075,
            0.015194485895335674,
            0.013020439073443413,
            0.02528318576514721,
            0.00032719442970119417,
            0.01804754137992859,
            -0.015508192591369152,
            -0.04361725226044655,
            0.014087940566241741,
            0.00302793993614614,
            -0.042317867279052734,
            0.027185512706637383,
            0.03451862931251526,
            -0.014913061633706093,
            0.026051050052046776,
            -0.018741855397820473,
            0.0021338360384106636,
            0.019679255783557892,
            0.004445655271410942,
            -0.008420840837061405,
            -0.007812038995325565,
            -0.05767153948545456,
            0.008351027965545654,
            0.003419027430936694,
            -8.55955368024297e-05,
            0.008041085675358772,
            0.022538037970662117,
            0.008029627613723278,
            0.01880667917430401,
            0.02050197310745716,
            0.030580485239624977,
            0.017757536843419075,
            0.02380526065826416,
            -0.0074187153950333595,
            0.000136491289595142,
            0.019312316551804543,
            0.025450319051742554,
            -0.007239739876240492,
            0.001491489470936358,
            -0.0023151838686317205,
            0.00030413566855713725,
            0.025085754692554474,
            -0.05645570531487465,
            -0.009418128989636898,
            0.035498134791851044,
            0.0017298717284575105,
            0.014802266843616962,
            -0.026825785636901855,
            -0.0040157572366297245,
            0.0031042005866765976,
            -0.014167804270982742,
            -0.004814986139535904,
            -0.01760031282901764,
            0.03044956363737583,
            0.011229952797293663,
            -0.04632985219359398,
            -0.015639619901776314,
            0.03883356600999832,
            -0.028894243761897087,
            -0.016567977145314217,
            0.007392458152025938,
            -0.01973741315305233,
            -0.0029054393526166677,
            -0.02292225882411003,
            -0.004957070108503103,
            0.055285148322582245,
            -0.04916892945766449,
            -0.049556758254766464,
            -0.06476864218711853,
            0.018276505172252655,
            0.02729029953479767,
            -0.006406194996088743,
            -0.011018223129212856,
            0.03967798501253128,
            -0.019299328327178955,
            -0.03367415815591812,
            0.00222202530130744,
            -0.01705809496343136,
            0.004956152755767107,
            -0.009352017194032669,
            -0.0026193491648882627,
            -0.009223449043929577,
            0.0019177106441929936,
            0.01457502692937851,
            0.009289794601500034,
            0.0281455609947443,
            0.025275900959968567,
            0.003953800071030855,
            -0.0002475764777045697,
            0.0009548597154207528,
            0.01189090684056282,
            0.01424307469278574,
            -0.0406583771109581,
            -0.026555685326457024,
            -0.02309299446642399,
            -0.003893402637913823,
            0.012781496159732342,
            -0.004366824869066477,
            0.013281765393912792,
            -0.025262663140892982,
            -0.013662111014127731,
            0.011180279776453972,
            0.029777325689792633,
            -0.03601072356104851,
            0.013313664123415947,
            -0.006767181679606438,
            0.0002604474138934165,
            0.009385678917169571,
            0.03133198246359825,
            0.005996218882501125,
            -0.03787345066666603,
            -0.013124802149832249,
            -0.02008804865181446,
            0.039114657789468765,
            0.024392271414399147,
            0.021730827167630196,
            -0.009435044601559639,
            -0.022065382450819016,
            -0.01704574190080166,
            -0.020350487902760506,
            0.01903950795531273,
            -0.019164791330695152,
            0.013676242902874947,
            0.003420674940571189,
            0.03579069674015045,
            -0.03619152680039406,
            -0.006324067246168852,
            -0.024198031052947044,
            0.010820405557751656,
            0.001504185376688838,
            0.006050191819667816,
            -0.0017519521061331034,
            -0.038947347551584244,
            0.0379873588681221,
            -0.04083234444260597,
            0.008425763808190823,
            0.03829357028007507,
            0.022637221962213516,
            0.000683354795910418,
            -0.009057549759745598,
            -0.044638317078351974,
            -0.009741133078932762,
            0.0162323247641325,
            -0.0029427348636090755,
            -0.023428170010447502,
            0.025048304349184036,
            0.007146336603909731,
            0.027336787432432175,
            -0.027888895943760872,
            0.017830923199653625,
            -0.00845734030008316,
            -0.01135309785604477,
            0.004832725040614605,
            0.026817642152309418,
            0.030150461941957474,
            0.0249470304697752,
            -0.02665228396654129,
            -0.028365429490804672,
            -0.013578122481703758,
            0.00604981416836381,
            0.005090868566185236,
            0.017037929967045784,
            0.028054503723978996,
            0.04492533951997757,
            0.0008289845427498221,
            -0.015433759428560734,
            -0.005969127174466848,
            -0.05255350470542908,
            -0.05677032470703125,
            0.03421657904982567,
            0.013423153199255466,
            0.011440643109381199,
            -0.06738632917404175,
            -0.019142717123031616,
            0.00581809738650918,
            0.0025857628788799047,
            0.02260497212409973,
            0.014581424184143543,
            -0.06460962444543839,
            -0.03890250623226166,
            0.0366404727101326,
            -0.02272554486989975,
            0.010293642058968544,
            -0.04267114773392677,
            -0.026783185079693794,
            0.012723864987492561,
            0.005282299127429724,
            -0.0260507483035326,
            0.015761177986860275,
            0.03069295361638069,
            -0.008381620980799198,
            -0.006193690467625856,
            -0.011738007888197899,
            0.005447832401841879,
            0.04364277049899101,
            -0.02666354924440384,
            0.007143627852201462,
            -0.0750136524438858,
            -0.009401176124811172,
            -0.011346162296831608,
            0.015571312978863716,
            -0.0659387856721878,
            -0.000989771680906415,
            -0.005086936056613922,
            0.11313777416944504,
            0.0008000020170584321,
            0.0032435590401291847,
            -0.006354981102049351,
            0.0022824483457952738,
            0.01983199454843998,
            -0.10697796940803528,
            -0.01430146861821413,
            0.02132072113454342,
            0.013112193904817104,
            -0.008531087078154087,
            -0.034071099013090134,
            -0.009054603986442089,
            -0.0020331975538283587,
            0.014200381003320217,
            0.00562615180388093,
            0.015777956694364548,
            -0.022500570863485336,
            -0.01921037957072258,
            0.010854585096240044,
            -0.008072123862802982,
            0.007997104898095131,
            -0.0250904131680727,
            0.02787943370640278,
            0.041484806686639786,
            0.013378864154219627,
            0.016624629497528076,
            -0.007463018875569105,
            -0.018628904595971107,
            0.024066267535090446,
            -0.013179921545088291,
            -0.02128729037940502,
            0.0161215178668499,
            -0.018737079575657845,
            -0.021978206932544708,
            0.02723492681980133,
            0.028191732242703438,
            0.03218794986605644,
            -0.036636292934417725,
            -0.05199500918388367,
            -0.02281627245247364,
            0.01607592962682247,
            0.018523305654525757,
            -0.0022614477202296257,
            0.0022923103533685207,
            0.03220224007964134,
            0.005284569691866636,
            0.06873371452093124,
            0.009259914048016071,
            0.00823885016143322,
            0.012769854627549648,
            -0.03893417492508888,
            -0.011420290917158127,
            0.023118766024708748,
            0.020580053329467773,
            0.040862686932086945,
            -0.022933699190616608,
            0.015674689784646034,
            -0.010055692866444588,
            -0.007384878117591143,
            -0.011396506801247597,
            0.0006798750255256891,
            0.018647007644176483,
            0.010154272429645061,
            -0.013976139947772026,
            0.03503094241023064,
            0.015793783590197563,
            -0.005910652689635754,
            -0.004754018038511276,
            -0.009426053613424301,
            -0.010376591235399246,
            0.024829773232340813,
            0.03880976140499115,
            -0.005420057103037834,
            -0.022147435694932938,
            -0.026725823059678078,
            0.004159222822636366,
            -0.015284632332623005,
            -0.02502650022506714,
            -0.008491093292832375,
            0.0009383165743201971,
            0.0004748015198856592,
            -0.0007338759605772793,
            0.015036770142614841,
            0.003515123389661312,
            -0.01839740015566349,
            -0.007334441412240267,
            0.0032067163847386837,
            -0.029626620933413506,
            0.03844964876770973,
            0.0029900448862463236,
            -0.019571082666516304,
            -0.0018018352566286922,
            -0.0343921072781086,
            0.024456841871142387,
            -0.04383234679698944,
            0.02097485214471817,
            0.07745353132486343,
            0.030025290325284004,
            -0.010278865694999695,
            0.012933914549648762,
            -0.013552073389291763,
            -0.012262329459190369,
            -0.010032285004854202,
            -0.0017859014915302396,
            -0.02884070947766304,
            0.02366182766854763,
            0.01846223510801792,
            -0.02848648838698864,
            0.023672783747315407,
            -0.02117214910686016,
            0.02643376961350441,
            -0.02432839199900627,
            0.01435224898159504,
            0.013545895926654339,
            0.0029334533028304577,
            0.006394718773663044,
            0.022771034389734268,
            0.011386970058083534,
            0.02217572182416916,
            -0.04856696352362633,
            -0.04800327494740486,
            0.011819572187960148,
            -0.016301967203617096,
            0.02298056147992611,
            -0.0035986497532576323,
            -0.002421450102701783,
            0.030006637796759605,
            0.004521161317825317,
            -0.055037204176187515,
            0.01994599960744381,
            -0.0039707962423563,
            0.026658426970243454,
            -0.0032136496156454086,
            0.019489724189043045,
            -0.00981076993048191,
            -0.019357800483703613,
            0.0074473097920417786,
            0.04232332110404968,
            0.016552874818444252,
            0.005987404379993677,
            0.006598776206374168,
            0.03218555450439453,
            -0.0005907532759010792,
            -0.005011063069105148,
            -0.031074346974492073,
            0.020394129678606987,
            0.008226281963288784,
            -0.01085882168263197,
            0.013822236098349094,
            0.019373279064893723,
            -0.023002633824944496,
            0.03223119676113129,
            -0.04035186767578125,
            -0.04679611325263977,
            -0.05110229179263115,
            -0.005634877365082502,
            -0.006494538392871618,
            0.028497042134404182,
            -0.02662236988544464,
            0.0052324458956718445,
            -0.010502559132874012,
            0.0314897745847702,
            0.0006693617324344814,
            -0.0021156545262783766,
            0.03143848478794098,
            0.01585971936583519,
            -0.004992294125258923,
            -0.003856159746646881,
            -0.011499432846903801,
            0.028171895071864128,
            0.001388265867717564,
            -0.004645133391022682,
            0.055235061794519424,
            -0.014822822995483875,
            -0.08600109815597534,
            -0.010682606138288975,
            0.013106864877045155,
            0.038058746606111526,
            0.011899435892701149,
            0.025637924671173096,
            -0.015583972446620464,
            0.004783384036272764,
            0.02863639034330845,
            0.03264390677213669,
            -0.013354044407606125,
            0.0041960617527365685,
            -0.01489220280200243,
            -0.0010337689891457558,
            0.005098406691104174,
            0.014348330907523632,
            -0.02963166870176792,
            0.028711751103401184,
            0.005517118144780397,
            0.014389285817742348,
            -0.01053786650300026,
            -0.016795465722680092,
            -0.022231776267290115,
            0.0047872234135866165,
            0.018331745639443398,
            -0.06908493489027023,
            0.02074301615357399,
            -0.05681103095412254,
            0.011826710775494576,
            0.0088350186124444,
            0.011396246030926704,
            -0.018925584852695465,
            0.026567982509732246,
            0.030885476619005203,
            -0.03623492270708084,
            0.008340644650161266,
            0.047431547194719315,
            0.02910975180566311,
            0.01119257602840662,
            -0.0066612293012440205,
            -0.012964477762579918,
            0.01186018530279398,
            -0.020156897604465485,
            0.026808997616171837,
            -0.0033747246488928795,
            0.012359796091914177,
            -0.036125052720308304,
            0.022769717499613762,
            -0.01766849122941494,
            -0.006685489788651466,
            0.008569030091166496,
            -0.0014985144371166825,
            -0.0126650994643569,
            -0.05084393545985222,
            0.021461952477693558,
            0.005511595401912928,
            0.0038206432946026325,
            -0.029885193333029747,
            -0.018068009987473488,
            -0.018492339178919792,
            -0.04660822078585625,
            0.020844165235757828,
            0.017544865608215332,
            -0.023112060502171516,
            -0.036535654217004776,
            0.01976429671049118,
            0.01566719077527523,
            -0.03349582105875015,
            0.009860224090516567,
            -0.02532019093632698,
            -0.020634977146983147,
            0.0016617977526038885,
            0.013391935266554356,
            0.01677371747791767,
            -0.030641423538327217,
            0.009307139553129673,
            -0.018591921776533127,
            -0.0184350423514843,
            -0.024187302216887474,
            -0.03805556893348694,
            0.018770044669508934,
            0.009927310049533844,
            -0.018438074737787247,
            0.0307024996727705,
            -0.0060310326516628265,
            -0.03656885400414467,
            -0.011092709377408028,
            -0.026294000446796417,
            -0.010930432938039303,
            0.038999393582344055,
            -0.003609442850574851,
            -0.02775433659553528,
            0.03334790840744972,
            0.00044593086931854486,
            0.006658912170678377,
            0.02813314087688923,
            0.022992799058556557,
            -0.011924016289412975,
            -0.0027901565190404654,
            0.006580487824976444,
            -0.06454610079526901,
            -0.07428663223981857,
            -0.008825826458632946,
            0.009071540087461472,
            0.012291009537875652,
            -0.04657921567559242,
            -0.0020475336350500584,
            -0.007255610078573227,
            0.004802292212843895,
            0.03613680973649025,
            0.005717842373996973,
            0.016795439645648003,
            0.015596839599311352,
            0.04783843457698822,
            -0.006358475424349308,
            0.027260391041636467,
            0.02403775043785572,
            -0.008588897995650768,
            0.0010041693458333611,
            -0.005743558052927256,
            0.03774654492735863,
            -0.030023779720067978,
            -0.01344510167837143,
            -0.014617564156651497,
            -0.01569732278585434,
            -0.04294483736157417,
            -0.012586399912834167,
            0.0025725599844008684,
            0.009893134236335754,
            -0.009116295725107193,
            0.009094281122088432,
            -0.0036810659803450108,
            -0.036425065249204636,
            0.00787577498704195,
            0.02104969695210457,
            0.012036717496812344,
            -0.010909532196819782,
            5.938841059105471e-05,
            0.005977373570203781,
            -0.02640325389802456,
            0.009004107676446438,
            0.036237724125385284,
            0.018790923058986664,
            -0.0008735708543099463,
            0.003784427186474204,
            0.005061932373791933,
            0.02441292814910412,
            -0.028671622276306152,
            -0.059872232377529144,
            -0.025913940742611885,
            0.023425007238984108,
            0.043873220682144165,
            0.010518659837543964,
            0.011383622884750366,
            -0.06018026918172836,
            -0.027618177235126495,
            0.0036050621420145035,
            0.032569415867328644,
            0.015270779840648174,
            0.025231320410966873,
            0.017216859385371208,
            -0.04703113064169884,
            -0.02845384180545807,
            0.026304565370082855,
            0.01872064545750618,
            0.020212268456816673,
            0.01569000631570816,
            0.024120716378092766,
            -0.045963551849126816,
            -0.04224839061498642,
            0.03641020134091377,
            -0.04993896931409836,
            -0.040002092719078064,
            -0.015065534971654415,
            -0.003222800325602293,
            -0.030361415818333626,
            -0.02199852094054222,
            -0.01619662158191204,
            -0.05951080098748207,
            0.006029203999787569,
            -0.06824419647455215,
            -0.022633986547589302,
            0.04023844376206398,
            0.00839001964777708,
            0.02182691916823387,
            -0.001583938836120069,
            0.0038291672244668007,
            -0.00614140322431922,
            0.00579539779573679,
            -0.0669514387845993,
            -0.003650951199233532,
            -0.002828408731147647,
            -0.011426328681409359,
            -0.006285947281867266,
            -0.0224075298756361,
            0.029176248237490654,
            -0.03717727214097977,
            0.037581898272037506,
            -0.01327736210078001,
            0.00823732279241085,
            -0.008169728331267834,
            0.03612661734223366,
            0.018796300515532494,
            0.022520262748003006,
            -0.007741331588476896,
            -0.017809029668569565,
            0.03877539187669754,
            -0.010151874274015427,
            0.009089224971830845,
            0.00960590597242117,
            -0.005120407789945602,
            -0.0063440268859267235,
            0.02286006137728691,
            0.027117399498820305,
            -0.018856188282370567,
            0.013947689905762672,
            -0.016015365719795227,
            -0.011599932797253132,
            0.0004857418534811586,
            0.01763135753571987,
            0.017863847315311432,
            -0.00028395766275934875,
            -0.024063745513558388,
            -0.012506970204412937,
            -0.01564139686524868,
            0.010612459853291512,
            -0.01658138446509838,
            -0.027153942734003067,
            -0.001739397761411965,
            -0.018658814951777458,
            0.03662395477294922,
            -0.10614489763975143,
            0.005793499760329723,
            -0.01144365407526493,
            -0.013894211500883102,
            -0.004251012112945318,
            -0.02994992583990097,
            -0.0077422321774065495,
            0.022125741466879845,
            -0.011027525179088116,
            -0.0014909467427060008,
            -0.020885109901428223,
            0.01943027973175049,
            0.0490560308098793,
            -0.05528531223535538,
            0.010317286476492882,
            0.020799461752176285,
            -0.015174224972724915,
            -0.025789134204387665,
            -0.004862882662564516,
            0.014708749018609524,
            0.045180633664131165,
            0.03843546286225319,
            -0.010316415689885616,
            -0.011754247359931469,
            -0.01005215011537075,
            0.023278439417481422,
            0.010648696683347225,
            0.01752624846994877,
            -0.014010181650519371,
            -0.005907954648137093,
            -0.0006777314119972289,
            0.0108912019059062,
            -0.0072615910321474075,
            -0.025251207873225212,
            -0.011898409575223923,
            -0.00555697875097394,
            0.016469450667500496,
            -0.021211057901382446,
            0.030102655291557312,
            0.03132912889122963,
            -0.01615598239004612,
            0.009452619589865208,
            0.026635123416781425,
            0.021109163761138916,
            0.038657236844301224,
            -0.02186775952577591,
            -0.040167924016714096,
            0.03213281184434891,
            -0.024780992418527603,
            0.018946077674627304,
            0.004902897868305445,
            -0.021444475278258324,
            -0.02461446449160576,
            0.011155330576002598,
            -0.00020775807206518948,
            -0.03563879057765007,
            -0.035570669919252396,
            0.008842336013913155,
            0.003894935129210353,
            -0.03266187012195587,
            -0.013847142457962036,
            -0.0675174668431282,
            -0.021887633949518204,
            0.014012970961630344,
            -0.023812629282474518,
            0.028418321162462234,
            0.02845989167690277,
            0.05044468119740486,
            0.01475299708545208,
            -0.010569899342954159,
            0.00043553634895943105,
            -0.049569349735975266,
            0.03533724322915077,
            -0.006165411323308945,
            -0.023829752579331398,
            0.02723882906138897,
            -0.011488339863717556,
            -0.0068712118081748486,
            0.0313992016017437,
            0.009634735062718391,
            -0.022109994664788246,
            -0.004782233852893114,
            0.044783297926187515,
            -0.024470524862408638,
            -0.00931993592530489,
            -0.011773243546485901,
            0.013840067200362682,
            0.028682198375463486,
            -0.03725438565015793,
            0.02413993701338768,
            0.010723738931119442,
            0.02731255814433098,
            0.011528515256941319,
            -0.008667729794979095,
            -0.016346190124750137,
            0.01316471491008997,
            0.028952889144420624,
            0.01678401231765747,
            0.004373698495328426,
            -0.0184822715818882,
            0.033549513667821884,
            -0.029719725251197815,
            -0.03253411501646042,
            0.00509369233623147,
            -0.010390839539468288,
            -0.017869094386696815,
            -0.017152711749076843,
            0.011534071527421474,
            -0.05565783381462097,
            0.009886390529572964,
            0.01109985914081335,
            0.008888766169548035,
            0.030987907201051712,
            -0.016494620591402054,
            0.03102777525782585,
            0.01966155506670475,
            0.014610200189054012,
            -0.018091196194291115,
            0.030005721375346184,
            -0.0008311147103086114,
            -0.07776100188493729,
            -0.005970961879938841,
            0.007217322010546923,
            0.030998265370726585,
            -0.00833041314035654,
            0.012182953767478466,
            -0.015255387872457504,
            0.020665323361754417,
            0.020803963765501976,
            0.023709876462817192
        ],
        [
            0.03669140860438347,
            0.005137202329933643,
            0.002918076468631625,
            -0.0156546737998724,
            0.02323058992624283,
            0.036846864968538284,
            -0.020493924617767334,
            0.04728049412369728,
            0.02081921137869358,
            0.002752321306616068,
            0.01513981819152832,
            0.019135594367980957,
            0.025693504139780998,
            -0.013876897282898426,
            0.005111390259116888,
            0.04275017976760864,
            0.03427726402878761,
            0.024110356345772743,
            -0.02263428270816803,
            -0.02415153943002224,
            0.036231812089681625,
            0.01573479175567627,
            0.03664341941475868,
            -0.014693084172904491,
            -0.0023889613803476095,
            0.008155765943229198,
            0.009512430056929588,
            -0.019048776477575302,
            -0.016959093511104584,
            0.0065228575840592384,
            -0.0035806181840598583,
            -0.01736517809331417,
            0.005578020587563515,
            -0.03432341292500496,
            -0.025765495374798775,
            0.011081758886575699,
            0.028850292786955833,
            -0.012149176560342312,
            -0.01978757046163082,
            -0.024081092327833176,
            0.016226086765527725,
            -0.004295107908546925,
            0.02311628870666027,
            -0.013816094025969505,
            -0.022920604795217514,
            -0.028996478766202927,
            0.027186430990695953,
            -0.00955082755535841,
            -0.01646885834634304,
            -0.0015595377190038562,
            -0.032800447195768356,
            -0.013412030413746834,
            0.008566350676119328,
            -0.030878763645887375,
            -0.03840628266334534,
            0.03433921933174133,
            -0.02370333857834339,
            0.00884904246777296,
            0.011340105906128883,
            -0.0062485262751579285,
            -0.014382734894752502,
            0.0076715112663805485,
            -0.02059282176196575,
            0.029378511011600494,
            0.004777743015438318,
            0.006669897120445967,
            0.030838660895824432,
            0.026630213484168053,
            0.006021135952323675,
            0.01567709632217884,
            0.02843492664396763,
            -0.014146052300930023,
            -0.02736952155828476,
            0.02457677759230137,
            -0.029229022562503815,
            0.01401943527162075,
            0.0014347798423841596,
            0.0029221554286777973,
            0.03966065123677254,
            0.004150031600147486,
            -0.004061067942529917,
            0.016361983492970467,
            0.002004195237532258,
            0.0020547315943986177,
            -0.02989966981112957,
            -0.03769080713391304,
            -0.019823826849460602,
            -0.021327508613467216,
            -0.002274524886161089,
            0.02999340556561947,
            0.012625008821487427,
            0.011356246657669544,
            0.028306931257247925,
            -0.015011487528681755,
            0.03993123769760132,
            -0.022495649755001068,
            0.001133211306296289,
            -0.027423176914453506,
            -0.006226405501365662,
            0.017995459958910942,
            0.005887082312256098,
            0.012793089263141155,
            -0.014038228429853916,
            0.008358624763786793,
            -0.006260913796722889,
            -0.014047744683921337,
            0.020214835181832314,
            0.0027134683914482594,
            0.01997493766248226,
            0.02379838563501835,
            0.02476656064391136,
            -0.019472191110253334,
            -0.0035605779848992825,
            -0.0018179897451773286,
            0.05039024353027344,
            -0.004133515525609255,
            -0.006763708312064409,
            -0.014766400679945946,
            -0.005273197311908007,
            -0.0021930341608822346,
            0.002245859941467643,
            -0.009172892197966576,
            0.03742381930351257,
            -0.02096543088555336,
            0.033209674060344696,
            0.025142475962638855,
            0.009346626698970795,
            0.03015625849366188,
            0.025906093418598175,
            0.004436127841472626,
            -0.014598974026739597,
            0.006079765968024731,
            0.027004534378647804,
            0.027545064687728882,
            0.02149551920592785,
            0.004699254874140024,
            0.026970745995640755,
            0.002875023288652301,
            -0.007062412798404694,
            -0.010175405070185661,
            -0.002538942964747548,
            0.020772505551576614,
            0.021762339398264885,
            -0.0383295901119709,
            -0.022161975502967834,
            -0.003276217496022582,
            0.004698955919593573,
            -0.007228624541312456,
            -0.006059188395738602,
            0.0007975521730259061,
            0.007154947146773338,
            -0.03440959006547928,
            0.010153202340006828,
            -0.016531705856323242,
            0.015831710770726204,
            0.002876675920560956,
            -0.020520465448498726,
            0.022567981854081154,
            0.005350116640329361,
            0.0026235340628772974,
            0.0030462597496807575,
            0.0011791260913014412,
            -0.0029183898586779833,
            0.021491609513759613,
            0.006481182295829058,
            -0.011255082674324512,
            -0.012745412066578865,
            -0.0021581891924142838,
            -0.00728175463154912,
            -0.019310854375362396,
            0.02840535342693329,
            -0.008805567398667336,
            -0.029117124155163765,
            0.028881333768367767,
            0.03957260772585869,
            0.021405987441539764,
            -0.009169732220470905,
            -0.020675884559750557,
            0.03189365565776825,
            -0.025039564818143845,
            0.004207813646644354,
            -0.0011082995915785432,
            0.015258075669407845,
            -0.009287478402256966,
            0.025102827697992325,
            -0.01980549469590187,
            0.0221900325268507,
            -0.009017725475132465,
            -0.010060773231089115,
            -0.018497126176953316,
            -0.009593379683792591,
            0.02674790471792221,
            0.011509501375257969,
            0.019097715616226196,
            -0.028502948582172394,
            -0.01950684003531933,
            -0.023563850671052933,
            -0.016319656744599342,
            -0.004002357367426157,
            -0.013424498029053211,
            0.012827558442950249,
            0.018065132200717926,
            0.016482407227158546,
            0.025530384853482246,
            0.02942485734820366,
            0.007705858442932367,
            0.0011378099443390965,
            0.020630883052945137,
            0.0031855625566095114,
            0.01699203997850418,
            0.030259577557444572,
            0.03593418747186661,
            -0.02120991423726082,
            0.011160883121192455,
            -0.0012589377583935857,
            -0.011176289059221745,
            0.02888016402721405,
            -0.0004208030004519969,
            -0.007692229002714157,
            0.0248164851218462,
            -0.0014679123414680362,
            0.011837109923362732,
            0.0018708568532019854,
            -0.0026705169584602118,
            -0.02650727890431881,
            0.043137166649103165,
            -0.004757268354296684,
            0.0036015373189002275,
            0.01305180974304676,
            0.01953785866498947,
            -0.0008856062777340412,
            -0.0070971096865832806,
            -0.017844974994659424,
            -0.015207172371447086,
            0.009154980070888996,
            -0.017665553838014603,
            0.05234938859939575,
            -0.015325887128710747,
            0.024177424609661102,
            -0.008711474947631359,
            -0.006691322196274996,
            -0.003418219741433859,
            0.014324113726615906,
            -0.0007695145905017853,
            -0.00721689173951745,
            0.028685126453638077,
            -0.021329689770936966,
            0.013476748019456863,
            -0.015810765326023102,
            -0.02962774597108364,
            -0.01850265823304653,
            -0.03465461730957031,
            0.009095367044210434,
            0.014190142042934895,
            0.00762307969853282,
            -0.003098077606409788,
            0.008386409841477871,
            0.03796083852648735,
            -0.009498263709247112,
            0.005506581161171198,
            -0.020447518676519394,
            -0.00043592092697508633,
            0.02449272945523262,
            0.018479609861969948,
            -0.007558143697679043,
            -0.026123177260160446,
            0.034719645977020264,
            -0.018450750038027763,
            -0.005363378673791885,
            -0.007170914672315121,
            0.0020078173838555813,
            -0.02565261907875538,
            0.006832738872617483,
            0.021525206044316292,
            0.010700860060751438,
            0.0006637659971602261,
            0.015216846019029617,
            0.009081987664103508,
            0.023398427292704582,
            0.021009255200624466,
            0.0111723393201828,
            0.014538951218128204,
            -0.019784869626164436,
            0.0007592844194732606,
            0.016282280907034874,
            -0.0037770967464894056,
            -0.018622640520334244,
            0.007555718999356031,
            -0.013216424733400345,
            0.028501063585281372,
            -0.0017317868769168854,
            -0.012802466750144958,
            -0.029057294130325317,
            0.015125054866075516,
            0.021809399127960205,
            0.025018230080604553,
            -0.01904791034758091,
            -0.0015249778516590595,
            -0.010400724597275257,
            -0.023186570033431053,
            -0.005717223044484854,
            -0.00013914189185015857,
            -0.006945060100406408,
            0.016933750361204147,
            0.00528872711583972,
            0.003120599314570427,
            0.010472866706550121,
            -0.0031153964810073376,
            -0.036626916378736496,
            -0.02961447648704052,
            -0.00275359652005136,
            0.015102761797606945,
            0.002236122265458107,
            0.0004045017412863672,
            0.02493716962635517,
            -0.016187289729714394,
            0.03113526478409767,
            -0.017379051074385643,
            0.012534398585557938,
            -0.026584381237626076,
            -0.026507766917347908,
            0.03442983701825142,
            0.023483144119381905,
            0.012523206882178783,
            0.02330573834478855,
            -0.011930184438824654,
            -0.03524285554885864,
            0.013743102550506592,
            -0.0260904673486948,
            -0.019731862470507622,
            0.0022191007155925035,
            0.0034842118620872498,
            -0.02105104736983776,
            -0.0067567601799964905,
            0.027750616893172264,
            -0.03670790418982506,
            -0.006608530413359404,
            -0.004188596270978451,
            0.0028949871193617582,
            0.025615906342864037,
            0.0035903521347790956,
            -0.0027194020804017782,
            0.012959787622094154,
            -0.02418079413473606,
            -0.02194354496896267,
            -0.0070876870304346085,
            0.006437355652451515,
            -0.026937643066048622,
            0.028381554409861565,
            -0.005410768557339907,
            0.029410818591713905,
            0.011132092215120792,
            0.022587673738598824,
            0.0068550677970051765,
            0.02048465609550476,
            -0.003732600947842002,
            0.0006454211543314159,
            0.017921673133969307,
            -0.013789518736302853,
            0.018288390710949898,
            -0.035056065768003464,
            0.020248789340257645,
            -0.025724219158291817,
            0.02716154418885708,
            0.014836405403912067,
            -0.009502365253865719,
            -0.0430290512740612,
            -0.026686429977416992,
            0.017812950536608696,
            0.010553289204835892,
            -0.0011907322332262993,
            0.009454998187720776,
            0.01743554323911667,
            -0.005182435270398855,
            -0.01909624971449375,
            -0.01368039846420288,
            0.026090672239661217,
            0.007163605652749538,
            0.006886140909045935,
            0.01066179946064949,
            0.03829829767346382,
            -0.010763270780444145,
            -0.002958259778097272,
            0.028516128659248352,
            0.04316158965229988,
            0.03880060464143753,
            0.02083122916519642,
            0.0175341684371233,
            0.021494876593351364,
            0.006099391262978315,
            0.015343034639954567,
            0.03714197874069214,
            0.01233959011733532,
            -0.020328376442193985,
            0.012198745273053646,
            0.004512627609074116,
            0.003338316222652793,
            -0.013583333231508732,
            0.017987431958317757,
            -5.708468597731553e-05,
            0.024690937250852585,
            0.020300300791859627,
            0.025014963001012802,
            0.006184550933539867,
            0.008413943462073803,
            0.0017654256662353873,
            0.010547869838774204,
            -0.0020174586679786444,
            -0.0049772621132433414,
            0.002876276383176446,
            0.0014325305819511414,
            -0.002682879799976945,
            0.02041211724281311,
            -0.00781826302409172,
            0.017496580258011818,
            -0.01187178771942854,
            -0.0170474573969841,
            -0.0013060432393103838,
            0.02873731590807438,
            0.01654260978102684,
            0.004348736722022295,
            0.006460628472268581,
            -0.004495198838412762,
            -0.006714650429785252,
            -0.020889071747660637,
            -0.02069077081978321,
            -0.03423388674855232,
            -0.015424823388457298,
            0.005065931472927332,
            -0.011597588658332825,
            0.016565414145588875,
            0.0010890369303524494,
            -0.0060027590952813625,
            0.04523511230945587,
            0.024848682805895805,
            -0.04401344805955887,
            0.021666882559657097,
            0.019230855628848076,
            0.026856904849410057,
            0.013143188320100307,
            -0.020473631098866463,
            -0.00887572206556797,
            0.019756048917770386,
            -0.03762689605355263,
            0.014023683033883572,
            0.015352523885667324,
            -0.003642172086983919,
            0.05493458732962608,
            0.030421653762459755,
            0.005772750824689865,
            0.03149343654513359,
            0.01779831573367119,
            -0.0019317687256261706,
            0.008621531538665295,
            -0.018327435478568077,
            -0.016020793467760086,
            -0.016252465546131134,
            0.03047293610870838,
            -0.020315609872341156,
            0.025538021698594093,
            -0.017201725393533707,
            -0.0007442290079779923,
            -0.0013542782980948687,
            -0.014289028011262417,
            0.011579425074160099,
            -0.006566282361745834,
            0.010624175891280174,
            0.017290148884058,
            -0.027583014219999313,
            0.00031452145776711404,
            0.0195164754986763,
            -0.016697969287633896,
            -0.01984660141170025,
            -0.023320434615015984,
            0.011043897829949856,
            0.024075660854578018,
            -0.008198405615985394,
            -0.012167817912995815,
            -0.004793138708919287,
            -0.024706240743398666,
            -0.015542290173470974,
            -0.02100958116352558,
            0.04332934692502022,
            0.01774783246219158,
            -0.01986672915518284,
            0.008265729993581772,
            0.022768449038267136,
            0.02120671235024929,
            0.055072519928216934,
            0.030308950692415237,
            0.014688747003674507,
            -0.0210485327988863,
            0.019758237525820732,
            0.014515294693410397,
            0.00420607253909111,
            0.005367741920053959,
            -0.0025446137879043818,
            0.0006034617545083165,
            0.007209575269371271,
            -0.01184503547847271,
            0.019305622205138206,
            0.008253950625658035,
            0.00029410459683276713,
            0.034830618649721146,
            0.026834163814783096,
            -0.004766417667269707,
            0.0002470290055498481,
            0.007305113133043051,
            0.009873369708657265,
            0.029475795105099678,
            0.011847410351037979,
            0.03245219960808754,
            0.0012161132181063294,
            -0.027354571968317032,
            0.01023052167147398,
            -0.004200514871627092,
            0.02098761685192585,
            0.028971398249268532,
            -0.0020947610028088093,
            -0.012141812592744827,
            0.006393040996044874,
            0.018488887697458267,
            0.006537887267768383,
            -0.008296125568449497,
            -0.02329006791114807,
            0.021597793325781822,
            -0.014602904208004475,
            0.0180460587143898,
            0.01691962219774723,
            -0.017318641766905785,
            0.015378949232399464,
            -0.0064214556477963924,
            0.004290587268769741,
            0.029014116153120995,
            0.0006729442393407226,
            -0.022161656990647316,
            0.007951389066874981,
            -0.018701789900660515,
            0.023277223110198975,
            0.0038113093469291925,
            -0.021561196073889732,
            -3.864380778395571e-05,
            0.0052245138213038445,
            0.03499363362789154,
            -0.02720048278570175,
            -0.03301019221544266,
            0.011131731793284416,
            0.0019778795540332794,
            0.005839646328240633,
            -0.021258477121591568,
            -0.019821086898446083,
            0.024170121178030968,
            -0.0049328189343214035,
            -0.015181382186710835,
            -0.014182382263243198,
            0.019686350598931313,
            0.011930858716368675,
            -0.019512096419930458,
            0.009360835887491703,
            -0.023177431896328926,
            -0.02103133499622345,
            0.00019527784024830908,
            -0.026957103982567787,
            -0.027709592133760452,
            0.0001104205657611601,
            -0.018003961071372032,
            -0.004420489072799683,
            -0.014971926808357239,
            -0.025564637035131454,
            -0.0258136298507452,
            0.01736597716808319,
            0.0109107606112957,
            0.027150116860866547,
            0.003942593932151794,
            -0.02085249125957489,
            -0.03321206569671631,
            0.0002420768141746521,
            0.01713648810982704,
            -0.024905284866690636,
            0.010403991676867008,
            -0.029184728860855103,
            0.013233151286840439,
            0.0200041476637125,
            0.020564986392855644,
            -0.017525386065244675,
            -0.007845994085073471,
            0.015667391940951347,
            -0.021164074540138245,
            0.0032342856284230947,
            0.022334953770041466,
            0.006321032997220755,
            -0.02623274363577366,
            0.010137869976460934,
            0.01566131040453911,
            0.02550983428955078,
            -0.005769183859229088,
            0.007683970965445042,
            0.009157050400972366,
            0.0228994470089674,
            0.011267954483628273,
            0.02369769662618637,
            -0.0141042685136199,
            0.0065529607236385345,
            -0.008714196272194386,
            -0.025735342875123024,
            0.001972396159544587,
            0.014880949631333351,
            -0.018506251275539398,
            0.040288347750902176,
            -0.0010187489679083228,
            -0.005149190314114094,
            -0.010122835636138916,
            -0.01753370463848114,
            -0.0043360283598303795,
            0.014032076112926006,
            0.04084641486406326,
            -0.026667213067412376,
            0.018923964351415634,
            -0.014673171564936638,
            -0.007641123607754707,
            -0.0019287260947749019,
            0.0156013248488307,
            -0.024232622236013412,
            0.015848303213715553,
            -0.02181771770119667,
            0.03860519826412201,
            0.029323313385248184,
            0.030251886695623398,
            0.03966357931494713,
            -0.006074897944927216,
            -0.012292486615478992,
            0.025408053770661354,
            0.033780042082071304,
            -0.039765626192092896,
            0.0012264709221199155,
            -0.016388382762670517,
            -0.020073499530553818,
            -0.014910964295268059,
            0.028880760073661804,
            0.02516518346965313,
            -0.020687367767095566,
            -0.01138829905539751,
            0.009715347550809383,
            0.01175026223063469,
            0.022536667063832283,
            -0.0037746985908597708,
            -0.008545679971575737,
            -0.008026449009776115,
            0.008070486597716808,
            -0.012648667208850384,
            0.02822551503777504,
            0.027487311512231827,
            0.0017834262689575553,
            -0.005728872958570719,
            -0.0033074833918362856,
            -0.01472847443073988,
            -0.028986528515815735,
            0.005738706793636084,
            0.008638687431812286,
            0.02219773456454277,
            0.026417696848511696,
            -0.020735573023557663,
            -0.010337033309042454,
            -0.02209540642797947,
            0.0035377806052565575,
            -0.029651183634996414,
            0.007476264610886574,
            -0.002032333752140403,
            0.030608035624027252,
            0.02391224354505539,
            0.0017863033572211862,
            0.0065649827010929585,
            -0.03255433589220047,
            -0.022835377603769302,
            0.000866739428602159,
            0.004596267361193895,
            -0.02655189298093319,
            0.00959139596670866,
            -0.03510730713605881,
            -0.013855971395969391,
            0.0207519493997097,
            0.0010236315429210663,
            -0.015575438737869263,
            0.0037009769584983587,
            0.028846079483628273,
            -0.031485218554735184,
            -0.01630139723420143,
            0.020358096808195114,
            -0.011675450019538403,
            -0.00577315129339695,
            0.0064131710678339005,
            0.014126100577414036,
            -0.027735363692045212,
            0.01780136115849018,
            -0.011063408106565475,
            0.014876740984618664,
            0.01849001832306385,
            -0.023418918251991272,
            -0.008477878756821156,
            0.008241182193160057,
            0.02835584059357643,
            -0.009729450568556786,
            0.011415213346481323,
            0.0015773945488035679,
            0.0009830232011154294,
            0.013405759818851948,
            -0.010406175628304482,
            0.03501056879758835,
            0.00450710067525506,
            0.03319268673658371,
            -0.02468114346265793,
            0.028493929654359818,
            -0.01801842264831066,
            0.008908320218324661,
            -0.03153974190354347,
            0.01581285335123539,
            0.02513311244547367,
            -0.02846200205385685,
            -0.013144350610673428,
            -0.018211612477898598,
            0.012937040999531746,
            0.029580887407064438,
            0.005836070515215397,
            0.011556830257177353,
            0.021320616826415062,
            0.006695885211229324,
            0.012743835337460041,
            -0.0040008979849517345,
            -0.025035211816430092,
            -0.024523034691810608,
            0.01983969286084175,
            0.0006515018758364022,
            0.0014236423885449767,
            -0.023293698206543922,
            0.002587556140497327,
            -0.012739872559905052,
            -0.01835714280605316,
            -0.003338832641020417,
            -0.012965578585863113,
            0.013324318453669548,
            -0.021705200895667076,
            0.014726070687174797,
            -0.014070424251258373,
            0.0020368352998048067,
            0.016432074829936028,
            -0.013535541482269764,
            -0.009384643286466599,
            -0.045521873980760574,
            0.02821454219520092,
            0.001959308749064803,
            0.02725984901189804,
            -0.0006187557592056692,
            0.015737729147076607,
            -0.015165385790169239,
            0.023023655638098717,
            -0.02647993713617325,
            -0.004071497358381748,
            0.030344586819410324,
            -0.001917990855872631,
            0.02176058664917946,
            -0.02321951650083065,
            0.0005653069820255041,
            -0.02579553797841072,
            -0.008172323927283287,
            -0.0010283526498824358,
            -0.004904561676084995,
            -0.0012550444807857275,
            -0.029879095032811165,
            0.025766560807824135,
            0.0337454117834568,
            -0.022137070074677467,
            0.001142973080277443,
            0.016500163823366165,
            -0.023671401664614677,
            8.295742009067908e-05,
            0.014257646165788174,
            0.019872410222887993,
            0.03239702433347702,
            0.001374930259771645,
            0.0075364806689321995,
            0.03084966354072094,
            0.01034625619649887,
            0.010208232328295708,
            -0.017680075019598007,
            -0.009874289855360985,
            0.03175114095211029,
            -0.0413341224193573,
            0.006128659006208181,
            -0.02061358280479908,
            0.00873285811394453,
            0.006891203112900257,
            -0.008980733342468739,
            -0.008219915442168713,
            0.02828594297170639,
            -0.012517635710537434,
            0.01250950526446104,
            0.025581369176506996,
            -0.039400599896907806,
            0.0284718070179224,
            -0.018338564783334732,
            0.00406750850379467,
            0.0012519354932010174,
            0.03479187935590744,
            0.020198406651616096,
            0.008880222216248512,
            -0.019143879413604736,
            0.01594964601099491,
            0.029567701742053032,
            -0.004312644712626934,
            0.02097378671169281,
            0.018853431567549706,
            0.032008882611989975,
            0.009663240984082222,
            -0.006535662803798914,
            0.03216639161109924,
            -0.011798666790127754,
            0.004701697733253241,
            0.0012006328906863928,
            0.019873443990945816,
            -0.018326058983802795,
            -0.02009383961558342,
            -0.015024503692984581,
            0.01681259647011757,
            0.03006349317729473,
            -0.0013688792241737247,
            0.0032567365560680628,
            -0.0074408333748579025,
            0.008894571103155613,
            0.011112076230347157,
            0.026166168972849846,
            0.027010245248675346,
            0.017098985612392426,
            -0.0001187734815175645,
            0.029211601242423058,
            -0.05463440343737602,
            -0.030094506219029427,
            -0.018429957330226898,
            0.031099529936909676,
            0.011409186758100986,
            0.006855934392660856,
            -0.006176181137561798,
            0.013575541786849499,
            -0.001959978835657239,
            0.011094660498201847,
            0.012959570623934269,
            0.006172397639602423,
            0.006307652220129967,
            -0.028197111561894417,
            0.001780234044417739,
            0.019650988280773163,
            0.017630867660045624,
            -0.019690001383423805,
            0.011924201622605324,
            -0.030298219993710518,
            0.013994045555591583,
            0.030330104753375053,
            0.008827926591038704,
            0.000597020611166954,
            -0.032406773418188095,
            0.0201861634850502,
            -0.00237464951351285,
            -0.029270121827721596,
            -0.04236309602856636,
            0.010326228104531765,
            -0.005604398902505636,
            0.004686512053012848,
            0.01040387712419033,
            0.00466568348929286,
            -0.0011812523007392883,
            -0.00842664111405611,
            -0.027685940265655518,
            -0.03349464386701584,
            0.012790821492671967,
            0.027222340926527977,
            -0.006631169468164444,
            0.00044060454820282757,
            0.010652364231646061,
            0.0013842094922438264,
            -0.0005835764459334314,
            -0.013055999763309956,
            0.023586250841617584,
            -0.028422405943274498,
            0.01668233796954155,
            -0.021106699481606483,
            0.014918792061507702,
            -0.02123836614191532,
            0.0013705040328204632,
            -0.006928032264113426,
            0.00458556367084384,
            -0.0008613078971393406,
            0.001954819308593869,
            -0.02702798880636692,
            -0.0011085481382906437,
            0.005574916489422321,
            0.01918724738061428,
            0.004036468919366598,
            -0.0021743038669228554,
            -0.0006170418346300721,
            -0.03758795186877251,
            0.0009865787578746676,
            -0.002621190855279565,
            -0.001701817847788334,
            0.0019678687676787376,
            0.02894004061818123,
            -0.03601018711924553,
            -0.024478813633322716,
            0.04873701557517052,
            0.020215673372149467,
            0.024891532957553864,
            0.0038472667802125216,
            0.023273468017578125,
            -0.029618965461850166,
            0.003953409381210804,
            0.005280797835439444,
            -0.02955879084765911,
            -0.01626976579427719,
            0.025419125333428383,
            -0.008734461851418018,
            0.003918353468179703,
            -0.015786312520503998,
            0.014666065573692322,
            0.007214004173874855,
            0.027663065120577812,
            -0.016898902133107185,
            -0.002768091158941388,
            -0.030583025887608528,
            0.003783450461924076,
            -0.023075401782989502,
            -0.007625489961355925,
            0.031870242208242416,
            0.009906779043376446,
            -0.03329730033874512,
            -0.024046216160058975,
            -0.0032695974223315716,
            -0.0032631356734782457,
            -0.01693136617541313,
            0.026741329580545425,
            0.01821271702647209,
            0.005631525535136461,
            0.02033233270049095,
            0.020043175667524338,
            -0.011003644205629826,
            -0.014745896682143211,
            0.01780831627547741,
            0.021279942244291306,
            0.016235975548624992,
            0.008768046274781227,
            -0.04070454090833664,
            -0.013690504245460033,
            0.005486118141561747,
            0.022643500939011574,
            0.013150284066796303,
            -0.002839016728103161,
            -0.014017879031598568,
            0.004472005181014538,
            -0.016863778233528137,
            0.012257641181349754,
            0.01183610875159502,
            0.030978882685303688,
            -0.010072103701531887,
            0.006234741769731045,
            -0.027527686208486557,
            -0.01507330872118473,
            -0.02025887370109558,
            -0.01273596752434969,
            -0.0060697924345731735,
            0.02523939497768879,
            0.009446837939321995,
            -0.004510365892201662,
            0.015371052548289299,
            -0.002986063016578555,
            -0.005917039699852467,
            0.014277244918048382,
            -0.010770195163786411,
            -0.02059895545244217,
            -0.010854864493012428,
            0.025996744632720947,
            0.009484054520726204,
            -0.024146806448698044,
            -0.027455441653728485,
            0.018074946478009224,
            -0.016730407252907753,
            -0.0011808952549472451,
            -0.011416439898312092,
            0.03412769362330437,
            0.030724892392754555,
            0.023250989615917206,
            -0.012646044604480267,
            0.026957940310239792,
            -0.00028068156098015606,
            0.01975029706954956,
            -0.009104003198444843,
            0.025829030200839043,
            0.028274111449718475,
            -0.004802744369953871,
            -0.012699104845523834,
            -0.016546593979001045,
            0.001417890191078186,
            0.008878185413777828,
            0.011827830225229263,
            -0.014207920990884304,
            0.01595677249133587,
            0.004998262971639633,
            0.002351366216316819,
            0.0022202583495527506,
            0.018708933144807816,
            0.005693943705409765,
            -0.042168229818344116,
            -0.01481803972274065,
            0.002623020438477397,
            -0.027683570981025696,
            -0.0028074097353965044,
            -0.020433492958545685,
            -0.024045180529356003,
            0.027670864015817642,
            0.00957085657864809,
            -0.012158763594925404,
            -0.005885654594749212,
            0.04030819237232208,
            0.01622302457690239,
            0.012578300200402737,
            0.03144192323088646,
            -0.022590160369873047,
            -0.02038496918976307
        ],
        [
            -0.009687786921858788,
            -0.01318949181586504,
            0.008967999368906021,
            -0.01224470604211092,
            -0.005810196977108717,
            0.0162504892796278,
            -0.011009971611201763,
            0.031297389417886734,
            0.011265840381383896,
            -0.009319699369370937,
            0.03811236470937729,
            -0.013446511700749397,
            -0.020918430760502815,
            0.02847921848297119,
            0.02083374746143818,
            -0.038074057549238205,
            0.03593863546848297,
            0.030066873878240585,
            0.006712124217301607,
            -0.010782694444060326,
            0.022511815652251244,
            0.013488897122442722,
            0.046796765178442,
            -0.04275451973080635,
            0.04889889061450958,
            0.030270464718341827,
            0.0024264256935566664,
            -0.02004261314868927,
            -0.022119689732789993,
            0.011252392083406448,
            -0.03449414670467377,
            0.01486449409276247,
            -0.015032093971967697,
            -0.02220226638019085,
            0.0017794735031202435,
            0.011169392615556717,
            -0.014928258024156094,
            0.00840743724256754,
            -0.005815303418785334,
            0.01428709365427494,
            0.005162542220205069,
            -0.02574867196381092,
            -0.0039892946369946,
            -0.018501590937376022,
            0.026770900934934616,
            0.019683213904500008,
            -0.00645693764090538,
            -0.027880162000656128,
            -0.013292951509356499,
            0.022284818813204765,
            -0.010522395372390747,
            -0.011543826200067997,
            0.04290676862001419,
            -0.004896471742540598,
            -0.02361339144408703,
            -0.005371436942368746,
            -0.033368900418281555,
            0.018269451335072517,
            0.017788048833608627,
            -0.023697176948189735,
            0.00017356881289742887,
            0.007897992618381977,
            0.034196026623249054,
            -0.0009610787383280694,
            -0.005270897876471281,
            0.008252344094216824,
            0.0008117645047605038,
            -0.019495857879519463,
            0.027724839746952057,
            0.02675510384142399,
            -0.0263519324362278,
            0.011772737838327885,
            0.023984184488654137,
            -0.03177957236766815,
            -0.019031809642910957,
            -0.020458484068512917,
            0.029430154711008072,
            -0.014273222535848618,
            0.06416688859462738,
            -0.006862979847937822,
            0.004934391006827354,
            -0.02205457165837288,
            -0.025781752541661263,
            -0.02713041380047798,
            -0.01576109416782856,
            0.016088267788290977,
            3.7260731915012e-05,
            -0.016866445541381836,
            0.024844296276569366,
            0.02176574617624283,
            -0.038598641753196716,
            -0.028855055570602417,
            0.02423013560473919,
            -0.06333063542842865,
            0.015184786170721054,
            -0.009434056468307972,
            0.02596496045589447,
            -0.02108270488679409,
            0.024235038086771965,
            -0.027698757126927376,
            -0.01899135299026966,
            -0.05083121731877327,
            -0.02303995005786419,
            -0.009874890558421612,
            -0.029325591400265694,
            0.02663150243461132,
            0.016399020329117775,
            -0.009174112230539322,
            0.016649922356009483,
            -0.013156911358237267,
            0.010562589392066002,
            -0.02769964560866356,
            -0.0040901340544223785,
            0.009750574827194214,
            -0.01882338896393776,
            -0.020589185878634453,
            -0.01937961019575596,
            -0.005055700428783894,
            0.020198753103613853,
            -0.013430053368210793,
            -0.006705765146762133,
            0.011527333408594131,
            -0.012613799422979355,
            0.012004194781184196,
            -0.028480691835284233,
            -0.0021787448786199093,
            -0.01187021192163229,
            0.013269956223666668,
            -0.029708506539463997,
            -0.024047406390309334,
            -0.025825249031186104,
            -0.006661696825176477,
            0.01986304298043251,
            0.05117928236722946,
            0.01160784438252449,
            0.008257090114057064,
            0.018924053758382797,
            0.05234353616833687,
            0.005569102242588997,
            -0.015031400136649609,
            -0.02805202081799507,
            -0.015011672861874104,
            0.028799396008253098,
            0.017271647229790688,
            0.00013770550140179694,
            0.003717058803886175,
            0.013612430542707443,
            0.03556846082210541,
            -0.014533886685967445,
            0.021881908178329468,
            0.02938447892665863,
            -0.03454739972949028,
            -0.045726433396339417,
            0.039993174374103546,
            -0.014785312116146088,
            -0.005300644785165787,
            0.03947197273373604,
            -0.015043206512928009,
            -0.008099228143692017,
            -0.03390968590974808,
            -0.021841611713171005,
            0.031330790370702744,
            0.052945081144571304,
            0.002245044568553567,
            0.024271942675113678,
            -0.025693554431200027,
            0.007075201254338026,
            0.036721181124448776,
            -0.008176754228770733,
            0.022619307041168213,
            -0.0061133624985814095,
            0.007080201059579849,
            0.023180652409791946,
            0.020180143415927887,
            0.032238006591796875,
            0.03408214822411537,
            0.04267454892396927,
            -0.009164291433990002,
            -0.011746475473046303,
            0.012855114415287971,
            0.03684603050351143,
            -0.024012144654989243,
            0.009569372981786728,
            0.03525558486580849,
            0.00920790247619152,
            -0.010406406596302986,
            0.0025226762518286705,
            -0.024841487407684326,
            -0.02042648196220398,
            0.01611960679292679,
            -0.021036634221673012,
            -0.002134508453309536,
            0.07576413452625275,
            0.02744954265654087,
            -0.002814799314364791,
            -0.018567483872175217,
            0.015561678446829319,
            -0.0416269414126873,
            0.03372209146618843,
            -0.015233314596116543,
            -0.015485997311770916,
            -0.019157974049448967,
            -0.01083805225789547,
            0.01380444597452879,
            -0.010840426199138165,
            -0.014529607258737087,
            0.06881120800971985,
            0.0110828448086977,
            0.014810271561145782,
            -0.030284367501735687,
            0.024820895865559578,
            -0.024325532838702202,
            0.00427915109321475,
            -0.015197407454252243,
            -0.013106954284012318,
            0.013355433009564877,
            0.028080061078071594,
            -0.015466030687093735,
            -0.03183291107416153,
            -0.003744607325643301,
            0.003411333542317152,
            -0.014554610475897789,
            0.022720275446772575,
            0.012705733068287373,
            0.02136891335248947,
            0.011089835315942764,
            -0.024903543293476105,
            0.012304292060434818,
            -0.030997540801763535,
            -0.014227106235921383,
            0.007054418791085482,
            0.023562485352158546,
            0.0013455019798129797,
            0.005835510790348053,
            0.01725527085363865,
            0.015082672238349915,
            0.04832325130701065,
            0.01802590861916542,
            -0.025095418095588684,
            -0.013624094426631927,
            -0.026393722742795944,
            -0.008320742286741734,
            0.0007161392131820321,
            0.01708829402923584,
            0.01932322233915329,
            0.029380546882748604,
            -0.010605712421238422,
            0.022648552432656288,
            -0.011008190922439098,
            -0.02850312739610672,
            0.026694366708397865,
            -0.02843901515007019,
            0.05862364172935486,
            -0.001789882779121399,
            -0.020856304094195366,
            -0.017598478123545647,
            0.016473587602376938,
            0.043603867292404175,
            0.04250265285372734,
            0.044932469725608826,
            0.027159707620739937,
            -0.029287051409482956,
            0.011678609997034073,
            -0.004217094741761684,
            0.024654870852828026,
            0.05447274073958397,
            -0.03031971864402294,
            -0.008325784467160702,
            0.0010037707397714257,
            0.0010973031166940928,
            -0.011876305565237999,
            0.00945272296667099,
            -0.01183464378118515,
            -0.0457942932844162,
            -0.011164627969264984,
            -0.01087027508765459,
            0.0009381059207953513,
            0.0163627490401268,
            -0.002894265577197075,
            0.00729373237118125,
            0.009680354036390781,
            0.02035846747457981,
            -0.030537692829966545,
            0.0542093887925148,
            -0.013553268276154995,
            -0.025789473205804825,
            -0.0052816905081272125,
            0.025508472695946693,
            -0.01000150851905346,
            0.001381479436531663,
            0.009138154797255993,
            -0.00953031424432993,
            0.02488778345286846,
            0.008280238136649132,
            0.008697528392076492,
            0.024183155968785286,
            -0.00251587200909853,
            -0.012165028601884842,
            0.008494849316775799,
            -0.01045328937470913,
            -0.005498749669641256,
            0.006512426305562258,
            -0.015460288152098656,
            0.023793520405888557,
            0.012847775593400002,
            -0.024851562455296516,
            -0.03332609683275223,
            -0.01766403391957283,
            -0.035482920706272125,
            -0.036981090903282166,
            0.029847878962755203,
            -0.026539547368884087,
            -0.023555390536785126,
            -0.025892654433846474,
            0.010870740748941898,
            -0.029227612540125847,
            -0.007135821506381035,
            0.02005174197256565,
            0.0008046692237257957,
            0.008279182016849518,
            0.006640539970248938,
            0.008582825772464275,
            -0.01615064963698387,
            0.02696608379483223,
            0.02757708728313446,
            0.0034835811238735914,
            -0.0831020325422287,
            0.03413506597280502,
            -0.00798380933701992,
            0.021853504702448845,
            0.011386079713702202,
            -0.006889229640364647,
            0.0006272803293541074,
            0.013842047192156315,
            -0.017145968973636627,
            -0.03220629319548607,
            0.022108575329184532,
            0.016572581604123116,
            0.004785186145454645,
            -0.014073205180466175,
            0.025007443502545357,
            0.02869585156440735,
            0.03492571786046028,
            -0.018152859061956406,
            -0.017627183347940445,
            0.07219290733337402,
            0.0069021908566355705,
            -0.009421460330486298,
            -0.00775194214656949,
            -0.00873277336359024,
            0.019232677295804024,
            -0.020236274227499962,
            -0.02936342917382717,
            -0.01755664497613907,
            0.019539624452590942,
            -0.003730243304744363,
            -0.037790510803461075,
            -0.02389545552432537,
            0.019706852734088898,
            0.008400523103773594,
            -0.02499411813914776,
            0.027995795011520386,
            0.039631061255931854,
            0.01952546462416649,
            0.03973938897252083,
            -0.01126493327319622,
            -0.017924657091498375,
            0.0028544848319143057,
            -0.030315030366182327,
            0.014982011169195175,
            -0.007223987486213446,
            0.009200097993016243,
            0.02524668537080288,
            0.001708849100396037,
            -0.0008234056294895709,
            -0.02328634448349476,
            -0.018392110243439674,
            0.003906309604644775,
            0.013722488656640053,
            -0.01767490617930889,
            0.014377264305949211,
            0.022636666893959045,
            -0.02813265472650528,
            0.018808750435709953,
            0.04788439720869064,
            0.015286863781511784,
            0.019248273223638535,
            0.06872029602527618,
            -0.01554521732032299,
            -0.0053390865214169025,
            -0.03486835956573486,
            -0.030268970876932144,
            -0.030162842944264412,
            0.026545239612460136,
            0.0229689572006464,
            0.012411895208060741,
            0.019781744107604027,
            0.03630831465125084,
            0.025397220626473427,
            0.016802502796053886,
            0.013542534783482552,
            0.004884279798716307,
            -0.028599433600902557,
            0.012866759672760963,
            0.008355875499546528,
            -0.01680692285299301,
            -0.013561151921749115,
            -0.03175756335258484,
            0.02476636692881584,
            -0.03219527006149292,
            0.005720639601349831,
            0.011629483662545681,
            0.044900860637426376,
            0.013132160529494286,
            0.02332407794892788,
            -0.0007521337829530239,
            0.02093784138560295,
            -0.017849618569016457,
            -0.028733868151903152,
            -0.02451154962182045,
            -0.03212006762623787,
            0.03032366745173931,
            -0.0025441660545766354,
            0.01815006509423256,
            0.036599669605493546,
            0.06421317905187607,
            -0.02510683983564377,
            0.0017986465245485306,
            -0.000700976001098752,
            -0.02967747114598751,
            1.3335207768250257e-05,
            0.012090662494301796,
            0.02018541842699051,
            -0.014454265125095844,
            -0.010903074406087399,
            0.014469155110418797,
            -6.400138954631984e-05,
            -0.008633656427264214,
            -0.012383390218019485,
            0.012011499144136906,
            0.04522240534424782,
            0.0441632978618145,
            -0.019827889278531075,
            0.03510477393865585,
            0.02138815075159073,
            0.019873905926942825,
            0.027330908924341202,
            0.008841647766530514,
            0.031369540840387344,
            0.048295002430677414,
            0.029082469642162323,
            0.012707961723208427,
            0.008393742144107819,
            0.01415044441819191,
            -0.020647156983613968,
            0.011888673529028893,
            -0.007672499865293503,
            0.034226443618535995,
            0.024768320843577385,
            -0.016805749386548996,
            0.00403514364734292,
            0.028488224372267723,
            0.018757205456495285,
            -0.011186514981091022,
            -0.0284841638058424,
            -0.019363218918442726,
            0.01509883627295494,
            -0.043504174798727036,
            0.014994376339018345,
            0.0075648389756679535,
            0.03204391896724701,
            0.007728533819317818,
            -0.01280316710472107,
            -0.04217514395713806,
            0.0009629430714994669,
            0.021397974342107773,
            -0.008959540165960789,
            0.0011438336223363876,
            0.03021198883652687,
            -0.002067354042083025,
            -0.004992475267499685,
            0.03652317449450493,
            0.02178249880671501,
            -0.007041155826300383,
            0.013885833323001862,
            0.04239022359251976,
            -0.047772545367479324,
            -0.006177852395921946,
            0.06305228918790817,
            0.055147018283605576,
            -0.03292500972747803,
            -0.02661566622555256,
            0.005073297768831253,
            0.04288586974143982,
            0.03499263525009155,
            -0.003254576353356242,
            0.0011438665678724647,
            0.015807565301656723,
            0.005583031568676233,
            0.007945560850203037,
            -0.003004644066095352,
            -0.007907931692898273,
            -0.026270432397723198,
            -0.039222367107868195,
            -0.028098076581954956,
            -0.008347203955054283,
            -0.0006084087071940303,
            0.024764910340309143,
            0.023733843117952347,
            -0.01925237663090229,
            -0.005766310729086399,
            0.020130524411797523,
            -0.008621333166956902,
            -0.015586781315505505,
            -0.0378861203789711,
            -0.029128078371286392,
            0.018221436068415642,
            -0.0013022329658269882,
            0.008085987530648708,
            0.020419932901859283,
            0.0017983268480747938,
            -0.010015933774411678,
            -0.004604415036737919,
            0.006707031279802322,
            0.002315475605428219,
            0.043821368366479874,
            0.05040867626667023,
            -0.003323530312627554,
            0.02644510194659233,
            -0.030561503022909164,
            0.009201234206557274,
            -0.02048361860215664,
            0.0454292893409729,
            0.012815047986805439,
            0.006459092255681753,
            0.0006884036120027304,
            -0.02368103712797165,
            -0.0006750634638592601,
            -0.01243168767541647,
            -0.024670304730534554,
            0.020433703437447548,
            -0.02555510774254799,
            0.031010136008262634,
            0.009785730391740799,
            0.02066344954073429,
            -0.010436108335852623,
            -0.0026120184920728207,
            0.030742140486836433,
            -0.01349511556327343,
            -0.01169138215482235,
            0.02469228394329548,
            -0.03247775882482529,
            0.01192454807460308,
            -0.0028518775943666697,
            -0.02317049540579319,
            -0.011044162325561047,
            -0.0018636435270309448,
            -0.02833125926554203,
            0.006576481740921736,
            -0.02770816721022129,
            0.005278449505567551,
            0.023715782910585403,
            -0.011268780566751957,
            -0.015444628894329071,
            -0.012973243370652199,
            -0.005436914972960949,
            -0.0037846295163035393,
            0.00012140189210185781,
            -0.00479367608204484,
            -0.009277870878577232,
            0.026518022641539574,
            -0.00883618276566267,
            0.011285156011581421,
            0.013448979705572128,
            -0.018703779205679893,
            -0.05143103376030922,
            0.031461119651794434,
            0.017516370862722397,
            -0.0069369105622172356,
            -0.0038011600263416767,
            0.004124089144170284,
            -0.024740353226661682,
            -0.012419695034623146,
            0.030171725898981094,
            0.018377766013145447,
            -0.022361421957612038,
            0.03508720174431801,
            -0.013007581233978271,
            -0.0029871377628296614,
            -0.03092879429459572,
            0.015570512041449547,
            0.01886693760752678,
            -0.013786842115223408,
            0.0022317904513329268,
            -0.003428903641179204,
            -0.03002234362065792,
            -0.0015834018122404814,
            0.02529553882777691,
            0.00671540480107069,
            0.024735774844884872,
            0.039516646414995193,
            0.0048593333922326565,
            -0.013808132149279118,
            0.017277907580137253,
            -0.002638856880366802,
            0.021382860839366913,
            -0.026596959680318832,
            -0.008609890937805176,
            0.04501684010028839,
            0.02863168530166149,
            -0.005255695898085833,
            0.011824327521026134,
            -0.010085923597216606,
            -0.009118034504354,
            0.020662479102611542,
            0.03415646404027939,
            0.026696687564253807,
            -0.03309354931116104,
            0.03218645974993706,
            -0.0029078563675284386,
            -0.05060332641005516,
            -0.014895522966980934,
            0.023003987967967987,
            -0.020476549863815308,
            -0.014983702450990677,
            0.002654067473486066,
            -0.01589288003742695,
            -0.021678145974874496,
            -0.0017719168681651354,
            0.004012262914329767,
            0.03182562440633774,
            -0.017134664580225945,
            -0.010241448879241943,
            -0.015961451455950737,
            -0.019508805125951767,
            0.028363483026623726,
            -0.02376575581729412,
            -0.009117466397583485,
            0.006503945216536522,
            0.018974944949150085,
            0.004085564520210028,
            0.0070040421560406685,
            -0.01025496143847704,
            0.03720889240503311,
            0.009661698713898659,
            0.021670782938599586,
            0.004088055808097124,
            0.01703059859573841,
            0.0028991042636334896,
            -0.0014197073178365827,
            0.02407623641192913,
            -0.015776732936501503,
            -0.009778006002306938,
            -0.0053272671066224575,
            0.015953056514263153,
            0.0010096636833623052,
            0.06742876023054123,
            -0.010962299071252346,
            0.03172840550541878,
            0.03170909732580185,
            0.033333614468574524,
            -0.018600260838866234,
            -0.021225985139608383,
            -0.001619870774447918,
            -0.024302048608660698,
            -0.0030985455960035324,
            -0.019867731258273125,
            -0.01403419766575098,
            -0.029146932065486908,
            0.03523564338684082,
            0.0007875667652115226,
            0.023282011970877647,
            0.011701212264597416,
            0.009186633862555027,
            0.005592714063823223,
            0.00634159566834569,
            0.02565060742199421,
            0.020069696009159088,
            -0.03470927104353905,
            -0.003782241838052869,
            -0.012452458962798119,
            -0.026657581329345703,
            -0.015691516920924187,
            -0.015014241449534893,
            0.01259585376828909,
            0.021381882950663567,
            0.05407310649752617,
            -0.013561086729168892,
            0.03157341480255127,
            0.026084117591381073,
            0.023284507915377617,
            -0.011432262137532234,
            -0.03207964077591896,
            -0.013800565153360367,
            -0.008568350225687027,
            -0.009405748918652534,
            -0.018843354657292366,
            -0.0054413448087871075,
            -0.011321897618472576,
            0.005311290733516216,
            0.007543556392192841,
            0.0038071947637945414,
            -0.013546914793550968,
            0.043312907218933105,
            -0.013422382064163685,
            0.015305299311876297,
            -0.012543235905468464,
            -0.024135366082191467,
            0.04554218426346779,
            0.05608341470360756,
            -0.02610364928841591,
            -0.026022939011454582,
            -0.010342842899262905,
            -0.044014569371938705,
            0.02515428513288498,
            -0.0326479971408844,
            0.05160918086767197,
            0.028512820601463318,
            -0.014699180610477924,
            -0.019528592005372047,
            0.0037338717374950647,
            -0.02221428044140339,
            -0.02065250463783741,
            0.05869166553020477,
            -0.014861778356134892,
            0.02066384069621563,
            -0.013352704234421253,
            0.02726559154689312,
            0.02026350237429142,
            0.01209621224552393,
            -0.021045224741101265,
            0.011318046599626541,
            0.026278013363480568,
            -0.004855351522564888,
            -0.0210700836032629,
            0.0253685861825943,
            -0.024587268009781837,
            -0.011752755381166935,
            -0.01140163466334343,
            5.984716335660778e-05,
            -0.019016390666365623,
            0.030869826674461365,
            0.00858858972787857,
            0.019153879955410957,
            -0.018446719273924828,
            -0.055497411638498306,
            0.011678701266646385,
            0.03245699405670166,
            0.033599577844142914,
            -0.0216694213449955,
            0.015287273563444614,
            -0.03096061572432518,
            -0.0020329246763139963,
            -0.003663486335426569,
            0.01073623076081276,
            -0.010115684010088444,
            0.01860816776752472,
            -0.01521717756986618,
            -0.018306033685803413,
            -0.004150315187871456,
            0.008941142819821835,
            0.04566371813416481,
            -0.0006178970215842128,
            0.0277040246874094,
            -0.006606158334761858,
            0.03328269347548485,
            -0.013047151267528534,
            0.014524667523801327,
            0.01607660949230194,
            0.03418581560254097,
            -0.005337887909263372,
            -0.007092765998095274,
            -0.025948818773031235,
            0.05115802586078644,
            -0.007401745766401291,
            0.03206305950880051,
            0.0173646192997694,
            -0.002116868505254388,
            0.01197480596601963,
            0.02058996632695198,
            0.01046312041580677,
            -0.02244091033935547,
            -0.00510550057515502,
            0.029687119647860527,
            0.0073595233261585236,
            -0.01636875979602337,
            0.0028334353119134903,
            0.023051897063851357,
            -0.013899864628911018,
            0.020205669105052948,
            0.03610499948263168,
            0.0281768050044775,
            0.02369408868253231,
            -0.0379430390894413,
            0.00022964317759033293,
            -0.023725418373942375,
            -0.04351140931248665,
            0.03673071414232254,
            0.004066772758960724,
            0.027497991919517517,
            0.008522331714630127,
            -0.023256590589880943,
            -0.02139948680996895,
            0.026250142604112625,
            0.025235315784811974,
            0.018691428005695343,
            0.005763077177107334,
            0.022657198831439018,
            0.012753276154398918,
            -0.03990825638175011,
            0.0013424187200143933,
            -0.016976656392216682,
            -0.03312533721327782,
            0.021635623648762703,
            0.016211288049817085,
            -0.016558310016989708,
            0.017348313704133034,
            0.004248826764523983,
            0.002788340672850609,
            -0.022806111723184586,
            0.003393681952729821,
            -0.014097373001277447,
            0.011443591676652431,
            0.017198989167809486,
            0.053849004209041595,
            0.0020739862229675055,
            -0.014146056026220322,
            0.02224951796233654,
            -0.024222271516919136,
            -0.034292638301849365,
            0.02501665987074375,
            0.01477406919002533,
            0.0026958088856190443,
            0.007527823559939861,
            0.0010845708893612027,
            0.01772168278694153,
            0.03178541734814644,
            -0.0013281325809657574,
            0.005196062847971916,
            -0.001087135635316372,
            0.0063382540829479694,
            -0.021828539669513702,
            0.009918141178786755,
            0.042828384786844254,
            -0.03832827880978584,
            0.026805110275745392,
            -0.002926949877291918,
            0.018693620339035988,
            -0.02407357282936573,
            0.017204059287905693,
            0.0021004134323447943,
            0.003363349474966526,
            0.023490197956562042,
            -0.04522046819329262,
            -0.03164344280958176,
            0.000725074321962893,
            -0.011843965388834476,
            0.018689116463065147,
            -0.004929882939904928,
            -0.02443472109735012,
            -0.024760933592915535,
            -0.015644781291484833,
            0.02517694979906082,
            -0.00592194264754653,
            0.03200925886631012,
            -0.002408746164292097,
            -0.007081686519086361,
            -0.012360434979200363,
            -0.02208961918950081,
            -0.019984226673841476,
            0.015661822631955147,
            0.020334500819444656,
            0.002954913070425391,
            0.04830358177423477,
            0.031113769859075546,
            -0.010214547626674175,
            0.0010797048453241587,
            0.04279114678502083,
            0.014828305691480637,
            -0.018450848758220673,
            0.024505235254764557,
            0.031135298311710358,
            0.008666175417602062,
            0.022689180448651314,
            0.03647966682910919,
            0.003559358883649111,
            0.02503693476319313,
            0.06356105953454971,
            -0.013217456638813019,
            0.024082716554403305,
            -0.006765337660908699,
            -0.024453124031424522,
            0.030519740656018257,
            -0.010537869296967983,
            0.04112081974744797,
            0.011112889274954796,
            -0.001676014275290072,
            -0.013873960822820663,
            -0.019354842603206635,
            -0.031157340854406357,
            -0.012015631422400475,
            0.006825768388807774,
            -0.014397628605365753,
            0.0073244101367890835,
            0.0033401730470359325,
            0.015898777171969414,
            0.037948813289403915,
            0.007928840816020966,
            0.01751398853957653,
            0.005076933652162552,
            0.006154616363346577,
            -0.022087594494223595,
            -0.046254586428403854,
            0.045280978083610535,
            -0.01708413101732731,
            -0.005285327322781086,
            0.029309941455721855,
            -0.036864422261714935,
            -0.019157586619257927,
            0.005854921415448189,
            -0.017987387254834175,
            0.00618917727842927,
            0.02613433636724949,
            0.03797217831015587,
            0.07868137955665588,
            -0.0029286087956279516,
            0.00018691808509174734,
            0.01907631754875183,
            0.011652209796011448,
            0.005423626862466335,
            -0.027786001563072205,
            -0.035703521221876144,
            -0.00890631228685379,
            0.020950818434357643,
            0.016608096659183502,
            -0.0017079699318856,
            0.034184057265520096,
            -0.0030265802051872015,
            0.0030859557446092367,
            0.05474511906504631,
            -0.008339133113622665,
            -0.015501151792705059,
            0.054076436907052994,
            0.025164643302559853,
            0.05463747680187225,
            0.0010719284182414412,
            0.008015032857656479,
            0.013059962540864944,
            -0.009318745695054531,
            0.016940217465162277,
            0.04478316381573677,
            -0.035800568759441376,
            0.015900861471891403,
            -0.006501874420791864,
            0.012387610971927643,
            0.026961714029312134,
            -0.0024524687323719263,
            -0.015648052096366882,
            0.045822449028491974,
            0.03456376865506172,
            -0.006430557928979397,
            -0.022153187543153763,
            0.01635957881808281,
            -0.004586501978337765,
            0.03128843754529953,
            -0.012790360487997532,
            0.04544518515467644,
            0.00010605597344692796,
            0.00646342895925045,
            0.0025920220650732517,
            -0.02480209991335869,
            0.02143118716776371,
            -0.026126766577363014,
            0.02506079711019993,
            0.02230515144765377,
            0.014980928041040897,
            0.04508030787110329,
            0.024940112605690956,
            0.021680433303117752,
            -0.009633831679821014,
            -0.02211548201739788,
            -0.001578756608068943,
            0.03466050699353218,
            -0.01045158039778471,
            -0.0012271073646843433,
            -0.02862110361456871,
            0.014021906070411205,
            -0.026701441034674644,
            0.016705062240362167,
            0.00143840117380023,
            -0.016364553943276405,
            -0.021715981885790825,
            0.014200317673385143,
            -0.0008414586773142219,
            0.030142953619360924,
            0.038950659334659576,
            0.004043848719447851,
            0.009796487167477608,
            0.03380356729030609,
            -0.01647338457405567,
            -0.01850142702460289,
            0.0043707373552024364,
            0.037989821285009384,
            0.0036064395681023598,
            0.021532902494072914,
            0.00021630410628858954,
            -0.015854693949222565,
            0.025679321959614754,
            0.009252754971385002,
            0.0063152252696454525,
            0.03780246526002884,
            -0.027354387566447258,
            0.022647174075245857,
            6.623111403314397e-06,
            0.006967390421777964,
            -0.029999691992998123,
            0.0022129248827695847,
            -0.038400616496801376,
            0.01183084212243557,
            -0.009019642136991024,
            0.020733868703246117,
            0.039009079337120056,
            -0.011559272184967995,
            -0.024129284545779228
        ],
        [
            -0.010654873214662075,
            -0.010659031569957733,
            -0.003131729317829013,
            0.010615769773721695,
            0.006375732831656933,
            -0.012231465429067612,
            -0.0017681634053587914,
            -0.01509522832930088,
            -0.02819519117474556,
            0.012403245083987713,
            -0.005811908282339573,
            -0.004714576527476311,
            -0.024677513167262077,
            0.02042287029325962,
            0.04989888146519661,
            -0.008549823425710201,
            0.02252086065709591,
            0.016317665576934814,
            0.020254362374544144,
            0.024011701345443726,
            0.012053065001964569,
            -0.00945831649005413,
            -0.03931652009487152,
            0.02065311186015606,
            0.0349573977291584,
            0.03544057160615921,
            -0.02369660884141922,
            -0.011202177964150906,
            -0.009118861518800259,
            0.014289372600615025,
            0.025626810267567635,
            0.025057680904865265,
            -0.007337496615946293,
            -0.03004423901438713,
            0.022067593410611153,
            0.012581946328282356,
            -0.012858161702752113,
            -0.003181513398885727,
            0.01674528606235981,
            -0.022037524729967117,
            -0.024396691471338272,
            -0.01774262823164463,
            0.019855061545968056,
            0.024976367130875587,
            0.01390166487544775,
            0.020236127078533173,
            -0.0015623364597558975,
            0.041399288922548294,
            0.0013792718527838588,
            -0.01643279194831848,
            0.023786338046193123,
            0.00017419597133994102,
            0.004751870408654213,
            0.0517638735473156,
            0.03110364079475403,
            -0.005250788759440184,
            0.012335583567619324,
            -0.005621058866381645,
            0.033160045742988586,
            0.024298638105392456,
            0.029334470629692078,
            0.004290638025850058,
            -0.02510475181043148,
            0.013862360268831253,
            0.010340389795601368,
            0.0021091471426188946,
            -0.035871412605047226,
            -0.009170706383883953,
            -0.024369368329644203,
            -0.0013119924115017056,
            -0.008725902996957302,
            -0.010999108664691448,
            0.004238815046846867,
            -0.018551792949438095,
            0.011491140350699425,
            -0.027121178805828094,
            -0.04504120722413063,
            0.012342615984380245,
            -0.004253664053976536,
            0.02474083937704563,
            0.013426825404167175,
            0.008021702989935875,
            -0.013535358011722565,
            -0.036788467317819595,
            0.005082456395030022,
            0.019741326570510864,
            -0.002013668417930603,
            -0.010604176670312881,
            0.02244475856423378,
            0.0037551249843090773,
            0.017477840185165405,
            -0.01309366337954998,
            0.02871173806488514,
            0.028383169323205948,
            -0.026631109416484833,
            0.028887217864394188,
            0.022014424204826355,
            -0.011091591790318489,
            -0.028703559190034866,
            -0.013492150232195854,
            0.008104301057755947,
            -0.004212403669953346,
            0.028965219855308533,
            0.023617198690772057,
            0.03537716716527939,
            0.012605560943484306,
            0.0018195142038166523,
            -0.0012001084396615624,
            -0.01451538223773241,
            0.022542724385857582,
            0.04726136475801468,
            -0.01596122980117798,
            0.020619167014956474,
            0.029944974929094315,
            -0.03976435214281082,
            -0.006177166942507029,
            -0.016924317926168442,
            -0.031106822192668915,
            0.02088209055364132,
            0.019584516063332558,
            0.008177164010703564,
            -0.0022531854920089245,
            -0.03514226898550987,
            0.02013593167066574,
            0.01751588098704815,
            0.017027320340275764,
            -0.02833642065525055,
            0.005967792123556137,
            0.029166409745812416,
            -0.0006265542469918728,
            -0.006326431408524513,
            -0.022190328687429428,
            0.0002833718026522547,
            0.011992832645773888,
            0.011557838879525661,
            0.021800965070724487,
            0.02798006311058998,
            -0.00568082369863987,
            0.03228883817791939,
            -0.01807226799428463,
            -0.02113262750208378,
            0.004800369497388601,
            0.026539023965597153,
            0.00034712592605501413,
            -0.019119570031762123,
            -0.02349182590842247,
            -0.013846988789737225,
            0.007968761958181858,
            0.014060298912227154,
            -0.018184039741754532,
            -0.02051069587469101,
            0.02825663425028324,
            -0.004916935693472624,
            0.04952435940504074,
            0.017677636817097664,
            0.011677613481879234,
            -0.0016023728530853987,
            0.006708940025418997,
            -0.023919444531202316,
            -0.014206946827471256,
            0.006915336009114981,
            0.014145136810839176,
            -0.009062116965651512,
            -0.02344578132033348,
            0.02346552535891533,
            -0.018263058736920357,
            0.002020095242187381,
            -0.049698688089847565,
            -0.018960487097501755,
            -0.0010105813853442669,
            -0.02057245373725891,
            -0.023175856098532677,
            0.022395821288228035,
            0.001179474638774991,
            0.014656038954854012,
            0.02918844483792782,
            -0.01178065687417984,
            -0.007876233197748661,
            -0.002806723117828369,
            -0.0031647048890590668,
            -0.0014432931784540415,
            0.024763479828834534,
            0.02938481606543064,
            -0.013661018572747707,
            0.005626223981380463,
            -0.023999929428100586,
            -0.012667857110500336,
            -0.030350182205438614,
            -0.011566352099180222,
            0.026621393859386444,
            -0.009098529815673828,
            -0.052172839641571045,
            -0.031281277537345886,
            -0.019758369773626328,
            0.0030838449019938707,
            0.0005906453006900847,
            0.016082726418972015,
            -0.01872461847960949,
            0.00799741130322218,
            0.001315513625741005,
            0.028228625655174255,
            -0.0021348963491618633,
            -0.0035576883237808943,
            0.018215781077742577,
            0.0022756762336939573,
            -0.014466879889369011,
            0.04210646077990532,
            -0.029154088348150253,
            0.015165381133556366,
            -0.004095893818885088,
            -0.03276537358760834,
            0.0028290096670389175,
            0.001127068535424769,
            -0.017726117745041847,
            0.016157079488039017,
            0.0031118763145059347,
            0.003566059749573469,
            -0.00535177905112505,
            -0.01377783715724945,
            -0.0036507893819361925,
            0.006944227032363415,
            -0.007243272848427296,
            -0.01242253091186285,
            0.016976650804281235,
            -0.026597527787089348,
            0.04934287071228027,
            0.0008476593066006899,
            0.009127790108323097,
            0.02123798243701458,
            0.00812757108360529,
            0.00833457987755537,
            -0.02852434292435646,
            -0.0149675402790308,
            0.02478901855647564,
            -0.0020132134668529034,
            0.02732791379094124,
            -0.024996722117066383,
            -0.00601830892264843,
            -0.006871786899864674,
            -0.023313241079449654,
            -0.01720411516726017,
            -0.023520346730947495,
            -0.013366837054491043,
            0.027975168079137802,
            -0.02803006023168564,
            -0.023683538660407066,
            -0.009998295456171036,
            -0.031580422073602676,
            -0.007477511186152697,
            0.006394201423972845,
            -0.01824021339416504,
            0.017681771889328957,
            0.016442451626062393,
            0.005304568912833929,
            0.037655770778656006,
            -0.012907884083688259,
            0.004396731965243816,
            -0.029834341257810593,
            0.036291174590587616,
            0.03491520509123802,
            0.034593094140291214,
            0.0011158939450979233,
            0.00020653571118600667,
            -0.010201440192759037,
            -0.0033106605987995863,
            0.051111653447151184,
            -0.028319496661424637,
            -0.0220874585211277,
            -0.026516111567616463,
            -0.01833268254995346,
            0.01922658458352089,
            0.039045240730047226,
            0.01440990250557661,
            0.02535029500722885,
            0.008656992577016354,
            0.00228033191524446,
            -0.0026421919465065002,
            -0.026839427649974823,
            0.000399619631934911,
            0.0060174958780407906,
            0.025140786543488503,
            -0.013034850358963013,
            -0.02507501281797886,
            0.018531110137701035,
            -0.03460923209786415,
            -0.003706972347572446,
            -0.0054423194378614426,
            0.03312639147043228,
            0.0005949395126663148,
            0.017881829291582108,
            0.01795724593102932,
            0.018708856776356697,
            0.0218870360404253,
            0.030524523928761482,
            0.012145753018558025,
            0.009868618100881577,
            0.028092171996831894,
            0.02109132520854473,
            0.039462536573410034,
            0.006453188601881266,
            0.019602350890636444,
            0.02026398852467537,
            0.007863793522119522,
            -0.025812728330492973,
            0.03048979677259922,
            -0.027032693848013878,
            0.05313966050744057,
            -0.030975211411714554,
            0.0051172999665141106,
            -0.009099327959120274,
            0.045837316662073135,
            0.028278745710849762,
            0.007279928773641586,
            0.004956156015396118,
            -0.0268357265740633,
            0.014869985170662403,
            0.004955817945301533,
            0.001966075971722603,
            0.02971159853041172,
            -0.031144697219133377,
            -0.017402129247784615,
            -0.004104167688637972,
            -0.04045412689447403,
            -0.01603223942220211,
            0.021391786634922028,
            -0.016207387670874596,
            -0.015074084512889385,
            0.01913987100124359,
            0.023995036259293556,
            0.028222957625985146,
            0.008142787031829357,
            -0.02118515409529209,
            0.016454854980111122,
            -0.0032469627913087606,
            0.014603408984839916,
            0.03164134547114372,
            0.021772639825940132,
            0.0038054483011364937,
            0.013285364955663681,
            0.03077503852546215,
            0.004979737568646669,
            0.04388410598039627,
            -0.06538638472557068,
            0.020176025107502937,
            -0.014626025222241879,
            -0.022551797330379486,
            -0.005912722088396549,
            0.007952851243317127,
            0.02795466035604477,
            0.00694985780864954,
            0.03751534968614578,
            -0.0026711602695286274,
            -0.015596994198858738,
            0.011548386886715889,
            -0.029131826013326645,
            0.008719885721802711,
            0.020951608195900917,
            0.010348506271839142,
            -0.0035495769698172808,
            -0.011543088592588902,
            0.02406405098736286,
            0.026278531178832054,
            0.005980565678328276,
            -0.017092721536755562,
            -0.025705037638545036,
            -0.012401825748383999,
            -0.0013407055521383882,
            -0.02962265908718109,
            -0.05018080770969391,
            0.028300859034061432,
            0.017328031361103058,
            -0.015448752790689468,
            -0.014504760503768921,
            0.0016222932608798146,
            -0.0009619444026611745,
            0.01903681270778179,
            -0.007128674071282148,
            -0.012254334054887295,
            0.011708084493875504,
            -0.021330423653125763,
            -0.05293957144021988,
            0.006396376993507147,
            -0.028817003592848778,
            -0.02252257987856865,
            0.01127272192388773,
            -0.0026643036399036646,
            -0.026676200330257416,
            0.025235239416360855,
            0.0053225429728627205,
            0.010145791806280613,
            0.005248016212135553,
            -0.043672844767570496,
            -0.007768876850605011,
            -0.019314028322696686,
            -0.05503903701901436,
            -0.016794653609395027,
            0.005157100968062878,
            0.023175649344921112,
            0.004299396649003029,
            0.032835330814123154,
            0.0006691633025184274,
            -0.03078589029610157,
            0.02466505952179432,
            0.006064820569008589,
            0.03074757568538189,
            -0.03740181773900986,
            0.008870304562151432,
            0.03527672588825226,
            0.014798566699028015,
            -0.023079851642251015,
            -0.03221018239855766,
            -0.012339974753558636,
            -0.024069948121905327,
            -0.05230098217725754,
            0.03697666898369789,
            -0.00022466990048997104,
            -0.005277201067656279,
            0.011410543695092201,
            0.02263445407152176,
            -0.00579196959733963,
            0.001237755292095244,
            -0.030853118747472763,
            -0.02388385869562626,
            -0.01033399160951376,
            0.03578972443938255,
            -0.028317715972661972,
            0.02051575854420662,
            0.028468148782849312,
            -0.02680627442896366,
            0.020037123933434486,
            -0.030045723542571068,
            0.013423721306025982,
            -0.024409577250480652,
            -0.014768049120903015,
            -0.029374444857239723,
            -0.001024706056341529,
            -0.0481078065931797,
            0.012427818961441517,
            0.020515335723757744,
            0.0077867647632956505,
            0.012676582671701908,
            0.01038277056068182,
            -0.04893723130226135,
            0.01645674929022789,
            0.035127341747283936,
            -0.0014837750932201743,
            -0.03991348668932915,
            0.019426831975579262,
            0.03360067307949066,
            -0.00159829028416425,
            0.022428931668400764,
            0.018183428794145584,
            -0.0005200572777539492,
            0.020230721682310104,
            0.03107103332877159,
            0.021457459777593613,
            -0.027471916750073433,
            0.007400344591587782,
            -0.008519556373357773,
            0.024011507630348206,
            0.02136026881635189,
            -0.020821835845708847,
            0.027421439066529274,
            0.02355962060391903,
            -0.000191561906831339,
            0.006818593014031649,
            -0.039478980004787445,
            -0.0004638817918021232,
            -0.016996368765830994,
            0.0010994458571076393,
            0.0019002393819391727,
            0.01543373428285122,
            0.008104106411337852,
            0.00704135512933135,
            -0.023209568113088608,
            -0.018397649750113487,
            -0.00722524244338274,
            0.0011176599655300379,
            -0.004362390376627445,
            0.03723827376961708,
            0.03629797324538231,
            -0.03037869557738304,
            0.04312042519450188,
            0.031922563910484314,
            0.033755917102098465,
            -0.050964608788490295,
            -0.027174081653356552,
            -0.008401300758123398,
            0.042813755571842194,
            0.016281917691230774,
            -0.007983813993632793,
            -0.026396693661808968,
            -0.01542173232883215,
            0.021510863676667213,
            -0.06666179746389389,
            -0.017417283728718758,
            0.0379500612616539,
            -0.014217538759112358,
            -0.01356594543904066,
            0.009994328953325748,
            -0.02936582826077938,
            0.00704980781301856,
            0.035744477063417435,
            0.009311271831393242,
            -0.0130436597391963,
            0.013406929560005665,
            -0.0117063382640481,
            -0.010830284096300602,
            0.027122771367430687,
            0.007015774492174387,
            0.03409881889820099,
            0.02877386286854744,
            -0.009340337477624416,
            0.0396653451025486,
            0.031429409980773926,
            0.023308396339416504,
            0.02486870251595974,
            0.004714102949947119,
            0.004953837487846613,
            0.02480134554207325,
            0.025472326204180717,
            0.002188017824664712,
            -0.004831834230571985,
            0.03837798908352852,
            -0.024163059890270233,
            0.00045404236880131066,
            -0.0652606263756752,
            -0.019759517163038254,
            0.02608495205640793,
            0.02838722988963127,
            -0.024286754429340363,
            -0.010886860080063343,
            -0.03232146054506302,
            -0.007596368435770273,
            0.007965067401528358,
            0.04097704961895943,
            0.0043943230994045734,
            -0.001469047274440527,
            -0.012675032950937748,
            -0.0016420178581029177,
            -0.008117683231830597,
            0.006097115110605955,
            -0.03900660574436188,
            -0.011064227670431137,
            0.014517414383590221,
            -0.02140015736222267,
            0.01436055451631546,
            0.02492050640285015,
            0.020401211455464363,
            0.015513390302658081,
            -0.012924529612064362,
            0.004470470827072859,
            0.03876269608736038,
            0.008509473875164986,
            0.021792251616716385,
            0.02219894342124462,
            -0.0006482650642283261,
            -0.017261255532503128,
            -0.02426457405090332,
            0.011558656580746174,
            0.024613870307803154,
            -0.017763758078217506,
            -0.026067199185490608,
            0.03618424013257027,
            0.025726521387696266,
            0.045225732028484344,
            -0.018783992156386375,
            -0.026456212624907494,
            -0.029021363705396652,
            0.004875741433352232,
            -0.02120029181241989,
            0.01522068027406931,
            0.012268876656889915,
            0.005742313805967569,
            0.008527047000825405,
            0.02613380365073681,
            0.022579994052648544,
            -0.0066923112608492374,
            0.006662315223366022,
            0.01602942682802677,
            -0.006620852742344141,
            -0.0072522712871432304,
            0.04056301712989807,
            -0.06571165472269058,
            -0.0009436245309188962,
            -0.0005287934909574687,
            0.0032220978755503893,
            -0.023463841527700424,
            -0.02557375282049179,
            0.0323496051132679,
            0.02093108557164669,
            0.021403998136520386,
            0.019095128402113914,
            -0.030172457918524742,
            0.03217043727636337,
            -0.007461193948984146,
            0.0005712282727472484,
            -0.03376821056008339,
            0.02105182409286499,
            0.01141047477722168,
            -0.03126390650868416,
            0.049905262887477875,
            0.0031004459597170353,
            -0.0023567532189190388,
            0.004277084022760391,
            -0.02899991348385811,
            0.024466581642627716,
            -0.0071960031054914,
            -0.020072320476174355,
            0.0018582938937470317,
            0.00479612173512578,
            -0.02530171163380146,
            0.013567094691097736,
            -0.018667025491595268,
            -0.0015770141035318375,
            -0.0029855649918317795,
            0.016619648784399033,
            0.0011603871826082468,
            -0.028561929240822792,
            -0.03485656529664993,
            0.012186547741293907,
            -0.005291363224387169,
            0.030321478843688965,
            -0.025087177753448486,
            -0.0008275127620436251,
            0.004406413063406944,
            0.026133498176932335,
            -0.01581226848065853,
            -0.00865770410746336,
            0.035540804266929626,
            0.015745921060442924,
            0.0170147642493248,
            0.008870010264217854,
            -0.018367178738117218,
            0.0081979651004076,
            -0.023198861628770828,
            -0.01967138797044754,
            -0.015821313485503197,
            -0.0011754755396395922,
            -0.018661389127373695,
            0.019407475367188454,
            -0.01467225607484579,
            0.003176568541675806,
            0.01788889802992344,
            0.03407977148890495,
            0.007295998744666576,
            0.058367930352687836,
            -0.012149695307016373,
            -0.01516379788517952,
            0.028846358880400658,
            -0.0003923368058167398,
            0.016521736979484558,
            -0.024764345958828926,
            -0.00027338857762515545,
            0.0067338962107896805,
            -0.013136494904756546,
            0.011097726412117481,
            0.009110833518207073,
            -0.0031131154391914606,
            -0.02483239769935608,
            0.019901197403669357,
            0.010063224472105503,
            -0.02728920429944992,
            -0.0013012660201638937,
            -0.0004778651928063482,
            0.017744455486536026,
            -0.013496775180101395,
            -0.02835559844970703,
            0.0032277388963848352,
            0.017376108095049858,
            0.03734177350997925,
            0.03166882321238518,
            -0.017422204837203026,
            0.007189776748418808,
            0.0015667056431993842,
            0.0017534219659864902,
            -0.0008459824020974338,
            -0.02789236605167389,
            -0.005514653865247965,
            -0.02706175111234188,
            -0.0250354316085577,
            -0.027062295004725456,
            0.020551053807139397,
            0.030488215386867523,
            -0.019490592181682587,
            0.017690785229206085,
            -0.04129013046622276,
            0.024044426158070564,
            0.04380402714014053,
            0.023783370852470398,
            0.014343385584652424,
            -0.0253706444054842,
            -0.020153315737843513,
            0.002435767324641347,
            0.02190242148935795,
            -0.0021444433368742466,
            0.008326265029609203,
            0.015857519581913948,
            0.0036236008163541555,
            0.03354379162192345,
            -0.006944325286895037,
            0.04206680506467819,
            0.007082226220518351,
            0.003991037141531706,
            0.019967487081885338,
            0.017677752301096916,
            -0.012080671265721321,
            0.022224195301532745,
            0.00553663307800889,
            -0.022833243012428284,
            0.0016245031729340553,
            0.014282019808888435,
            0.020442085340619087,
            -0.013404116965830326,
            0.004457924980670214,
            0.0014860109658911824,
            0.01462720986455679,
            0.007853586226701736,
            0.005828360095620155,
            0.023831982165575027,
            0.020143363624811172,
            0.03538282588124275,
            0.01638544164597988,
            -0.005385862197726965,
            -0.0020098492968827486,
            0.030126648023724556,
            -0.0208569522947073,
            -0.013082614168524742,
            -0.037974849343299866,
            0.006539593450725079,
            -0.016939667984843254,
            0.024910161271691322,
            -0.0025618490763008595,
            0.022871796041727066,
            0.011033428832888603,
            0.029024319723248482,
            -0.023196710273623466,
            -0.014524114318192005,
            -0.0027016971725970507,
            -0.0007059910567477345,
            0.01721607893705368,
            -0.012420167215168476,
            -0.02493082545697689,
            -0.038008563220500946,
            0.024893656373023987,
            0.027758033946156502,
            0.012346910312771797,
            -0.01989361084997654,
            -0.004176929593086243,
            0.02555660530924797,
            -0.027646329253911972,
            -0.004139772150665522,
            -0.009739506989717484,
            -0.02407454513013363,
            0.0022579797077924013,
            0.022361386567354202,
            0.00838717631995678,
            0.029891256242990494,
            0.039021946489810944,
            0.028490323573350906,
            -0.038410961627960205,
            0.014339227229356766,
            0.03942845016717911,
            -0.011303904466331005,
            0.0042905621230602264,
            -0.017939185723662376,
            -0.017127836123108864,
            -0.002112358808517456,
            -0.010287309065461159,
            -0.008367103524506092,
            0.0074883331544697285,
            -0.016344089061021805,
            -0.0026563815772533417,
            0.013504574075341225,
            -0.012447190470993519,
            0.042149756103754044,
            -0.03166039288043976,
            -0.01690616086125374,
            0.022477133199572563,
            -0.00994685385376215,
            0.010632777586579323,
            -0.01719651371240616,
            -0.008990981616079807,
            0.01680695451796055,
            0.004966145846992731,
            0.0037751358468085527,
            0.006961524486541748,
            0.025799458846449852,
            0.0062980749644339085,
            -0.04724446311593056,
            -0.007399989292025566,
            0.015153579413890839,
            -0.02208012528717518,
            -0.0010687903268262744,
            -0.05288061872124672,
            -0.019610896706581116,
            -0.010868300683796406,
            0.012776918709278107,
            -0.0022712922655045986,
            -0.010762711986899376,
            0.00656901765614748,
            0.030115826055407524,
            0.022979147732257843,
            -0.06393633037805557,
            0.022309595718979836,
            0.018976500257849693,
            -0.015261341817677021,
            0.006365569308400154,
            0.02834465727210045,
            0.025137513875961304,
            -0.04877404496073723,
            0.03782670199871063,
            0.006929007824510336,
            -0.019709771499037743,
            0.02817351743578911,
            -0.01561599038541317,
            8.344510570168495e-05,
            0.0023710995446890593,
            0.03439481928944588,
            0.02167169190943241,
            0.005240611266344786,
            -0.033996205776929855,
            0.03674516826868057,
            -0.0032573130447417498,
            0.023730065673589706,
            0.006912370212376118,
            0.008643635548651218,
            -0.008719462901353836,
            -0.0157833993434906,
            -0.030972767621278763,
            0.03040851466357708,
            -0.011016912758350372,
            0.015125424601137638,
            -0.02473055012524128,
            -0.00459550553932786,
            0.01486764382570982,
            -0.019213572144508362,
            -0.022766554728150368,
            -0.002203415147960186,
            0.007294117007404566,
            0.010133418254554272,
            0.0299408957362175,
            -0.012404533103108406,
            -0.024187207221984863,
            -0.007047328632324934,
            0.02541392296552658,
            0.03625720366835594,
            -0.005157478153705597,
            0.02934429980814457,
            0.027405675500631332,
            0.012417839840054512,
            0.02369767613708973,
            0.026187965646386147,
            -0.017115185037255287,
            0.029836149886250496,
            0.022195732221007347,
            -0.008479893207550049,
            -0.03925265744328499,
            0.026351766660809517,
            -0.01039117481559515,
            0.026283957064151764,
            0.023709062486886978,
            3.082054536207579e-05,
            0.008105996064841747,
            -0.028377842158079147,
            0.018729958683252335,
            -0.027305729687213898,
            -0.019767923280596733,
            -0.017254538834095,
            -0.02449757605791092,
            -0.03408755734562874,
            0.024436313658952713,
            -0.02419351600110531,
            -0.0302562415599823,
            0.008373812772333622,
            0.008552671410143375,
            0.014249254949390888,
            -0.0723458081483841,
            -0.012064649723470211,
            -0.00968377199023962,
            0.017895320430397987,
            0.007054741960018873,
            0.01747913844883442,
            0.019706228747963905,
            0.018459152430295944,
            -0.0009988616220653057,
            0.004067020956426859,
            0.028872497379779816,
            0.006716019939631224,
            0.003924158867448568,
            -0.046263791620731354,
            0.011381602846086025,
            -0.004845399875193834,
            0.05810919404029846,
            -0.007556102238595486,
            -0.0017673756228759885,
            0.001057999674230814,
            -0.015641558915376663,
            0.021512584760785103,
            -0.008466779254376888,
            -0.024240029975771904,
            -0.024631472304463387,
            -0.026384349912405014,
            -0.053388867527246475,
            0.010097218677401543,
            0.002531611593440175,
            -0.005091823637485504,
            -0.0016392001416534185,
            -0.027147134765982628,
            -0.006802381947636604,
            -0.0035751517862081528,
            0.012420792132616043,
            -0.006111433729529381,
            0.0245199091732502,
            0.02037597820162773,
            0.019489198923110962,
            0.01075596734881401,
            0.004484128672629595,
            0.008038685657083988,
            -0.024162326008081436,
            0.004061688203364611,
            0.01133100874722004,
            -0.003677924396470189,
            0.011021058075129986,
            -0.024588756263256073,
            0.004582410212606192,
            0.014261208474636078,
            -0.07353124022483826,
            0.019106468185782433,
            0.017737260088324547,
            0.028970524668693542,
            0.02651825360953808,
            -0.02092408947646618,
            0.030857259407639503,
            0.0013292536605149508,
            0.01262584887444973,
            -0.003376055508852005,
            0.022516749799251556,
            0.01728726364672184,
            0.041335329413414,
            0.013063388876616955,
            -0.028952162712812424,
            -0.0010343112517148256,
            0.005419252905994654,
            0.006849903147667646,
            0.013060003519058228,
            0.010400078259408474,
            0.02202017419040203,
            0.02615765668451786,
            -0.03929366171360016,
            0.006405471358448267,
            -0.02399812825024128,
            0.03166719526052475,
            0.005476807709783316,
            0.017843428999185562,
            -0.051473598927259445,
            0.003192306263372302,
            0.014273432083427906,
            0.03680011257529259,
            0.0036033601500093937,
            0.017245503142476082,
            -0.002472579712048173,
            -0.007578334305435419,
            -0.003722269320860505,
            0.017026901245117188,
            -0.014811689965426922,
            -0.028322957456111908,
            -0.008103030733764172,
            0.05774763599038124,
            0.0022629115264862776,
            0.0210464745759964,
            -0.02649122290313244,
            0.0077264076098799706,
            -0.01740073598921299,
            -0.030244501307606697,
            -0.031896889209747314,
            0.052732277661561966,
            0.017153514549136162,
            0.03033793531358242,
            -0.01215618010610342,
            0.0009249373688362539,
            -0.025789471343159676,
            -0.002851185156032443,
            0.019361462444067,
            -0.026517201215028763,
            0.0038811133708804846,
            -0.006264724303036928,
            0.030573565512895584,
            -0.015470822341740131,
            0.008907086215913296,
            -0.006706535816192627,
            -0.02849411964416504,
            -0.020063238218426704,
            -0.0003235924814362079,
            -0.017380280420184135,
            0.026619596406817436,
            -0.00871447753161192,
            0.002653597854077816,
            -0.01968509890139103,
            -0.009630798362195492,
            -0.03808806091547012,
            -0.01312898937612772,
            0.006485035642981529,
            0.013078441843390465,
            -0.006041772663593292,
            -0.02575163170695305,
            -0.024586958810687065,
            0.005117898341268301,
            -0.005533314775675535,
            -0.0031390637159347534,
            1.5697931303293444e-05,
            0.027206948027014732,
            -0.027943270280957222,
            0.015039930120110512,
            0.0437178798019886,
            -0.0230433139950037,
            0.024599596858024597,
            -0.03195326402783394,
            -0.019850296899676323,
            -0.02086753398180008,
            -0.04807590693235397,
            -0.0056401630863547325,
            -0.012850738130509853,
            0.018827272579073906
        ],
        [
            0.014668750576674938,
            -0.04587583616375923,
            -0.0164368636906147,
            0.012385847978293896,
            -0.023825600743293762,
            0.02321637235581875,
            -0.02794269099831581,
            -0.005472338758409023,
            0.015547771006822586,
            0.00684139970690012,
            9.234374010702595e-05,
            0.015146625228226185,
            -0.028451332822442055,
            -0.011729407124221325,
            0.030397724360227585,
            -0.01852809637784958,
            0.003200458362698555,
            0.006488461513072252,
            -0.025264201685786247,
            0.012693430297076702,
            -0.018948212265968323,
            0.019657157361507416,
            0.04856841266155243,
            -0.02138245850801468,
            0.05248642340302467,
            0.005851318594068289,
            -0.007251516450196505,
            -0.01573152467608452,
            -0.03366604447364807,
            0.018971985206007957,
            0.004948748275637627,
            0.024652274325489998,
            0.00408661924302578,
            0.027938449755311012,
            -0.002912600291892886,
            -0.03219343349337578,
            0.02084442786872387,
            0.006834670901298523,
            0.026747971773147583,
            -0.008892237208783627,
            0.03852994740009308,
            0.026250632479786873,
            0.03234682232141495,
            -0.02178172953426838,
            0.050251517444849014,
            0.028247656300663948,
            0.013103620149195194,
            0.023024871945381165,
            -0.029075412079691887,
            -0.018230676651000977,
            -0.02134641632437706,
            -0.02560211904346943,
            -0.03379768878221512,
            -0.03376388177275658,
            0.022938398644328117,
            -0.0022957180626690388,
            -0.030959561467170715,
            0.015721144154667854,
            0.008513023145496845,
            0.014135324396193027,
            0.0400635227560997,
            -0.012512489221990108,
            -0.022613216191530228,
            -0.026740411296486855,
            -0.017908494919538498,
            0.01265061553567648,
            -0.030559705570340157,
            0.01047753170132637,
            0.008522638119757175,
            -0.014406325295567513,
            0.008102819323539734,
            -0.011524004861712456,
            0.016358032822608948,
            -0.015942072495818138,
            -0.001694851671345532,
            -0.010334474965929985,
            -0.013143951073288918,
            -0.004343461245298386,
            0.023715676739811897,
            0.01928071305155754,
            -0.02211877517402172,
            0.006124069914221764,
            -0.02908930741250515,
            -0.021797165274620056,
            -0.029698360711336136,
            0.0029991178307682276,
            -0.012902041897177696,
            -0.03561703860759735,
            0.023377826437354088,
            0.003589439205825329,
            0.012561796233057976,
            0.026543887332081795,
            0.02591211348772049,
            -0.006966382265090942,
            0.034040581434965134,
            -0.0038745177444070578,
            0.012177939526736736,
            0.01583612896502018,
            0.0036649873945862055,
            0.02777072787284851,
            0.007024344988167286,
            0.01082650013267994,
            -0.017283029854297638,
            0.01988857425749302,
            0.002626956207677722,
            -0.004127503838390112,
            0.02750677615404129,
            -0.029224907979369164,
            0.026084095239639282,
            0.025811336934566498,
            0.015006929636001587,
            0.02071935124695301,
            -0.0018190228147432208,
            -0.007948260754346848,
            0.01409002672880888,
            0.02646728791296482,
            -0.006424218416213989,
            0.03506874665617943,
            0.006638063583523035,
            0.0009730859310366213,
            0.015606542117893696,
            0.009500239975750446,
            0.04321004077792168,
            -0.015989219769835472,
            -0.02045198157429695,
            0.015334106981754303,
            -0.01681102067232132,
            0.029292063787579536,
            0.01972486637532711,
            -0.002860749140381813,
            -0.010564863681793213,
            -0.02295011654496193,
            -0.004816532135009766,
            -0.016712596639990807,
            0.020776337012648582,
            -0.04257076978683472,
            -0.01552955899387598,
            0.012972360476851463,
            -0.009176181629300117,
            -0.029631970450282097,
            -0.023417208343744278,
            0.004215960390865803,
            0.023194221779704094,
            0.02192515693604946,
            0.03273319825530052,
            -0.005447490140795708,
            0.027684343978762627,
            0.035130783915519714,
            -0.0008926607551984489,
            -0.007265681866556406,
            -0.008652351796627045,
            0.027947193011641502,
            0.031385697424411774,
            0.008850443176925182,
            0.03723536804318428,
            0.017867516726255417,
            0.007893707603216171,
            0.022706251591444016,
            0.01734396070241928,
            0.008557227440178394,
            -0.022233430296182632,
            -0.028804436326026917,
            -0.005268833599984646,
            -0.028217710554599762,
            -0.002290528966113925,
            0.034567300230264664,
            0.041701365262269974,
            -0.01921185851097107,
            -0.026414161548018456,
            0.030009742826223373,
            0.009345189668238163,
            -0.019219888374209404,
            -0.037078432738780975,
            -0.032176077365875244,
            0.000763044401537627,
            -0.0015156954759731889,
            0.03358246386051178,
            0.004873871803283691,
            0.007311366498470306,
            -0.019563663750886917,
            -0.0018543666228652,
            0.016262872144579887,
            -0.02525111474096775,
            0.024944951757788658,
            0.01751960813999176,
            0.03416313976049423,
            -0.031156595796346664,
            -0.002847430994734168,
            0.02453000470995903,
            -0.011914384551346302,
            -0.012350843288004398,
            0.02231176197528839,
            -0.003456203266978264,
            -0.031158454716205597,
            -0.011784801259636879,
            0.02954140119254589,
            -0.026665212586522102,
            0.008997565135359764,
            0.006387015338987112,
            -0.036354586482048035,
            -0.023565152660012245,
            -0.004205471370369196,
            0.00618585292249918,
            -0.01735800877213478,
            0.03636777773499489,
            -0.0008736222516745329,
            0.010456612333655357,
            -0.00011809423449449241,
            0.02159184217453003,
            -0.029511822387576103,
            0.015692325308918953,
            -0.02214128151535988,
            0.0062982505187392235,
            -0.009072968736290932,
            -0.016596810892224312,
            0.015206013806164265,
            0.00695337587967515,
            -0.02337055653333664,
            0.0018304920522496104,
            0.003875075839459896,
            -0.02732871286571026,
            0.004229001235216856,
            -0.023395691066980362,
            0.035037245601415634,
            0.034524135291576385,
            -0.03931216523051262,
            -0.04020882025361061,
            -0.030807457864284515,
            -0.038699571043252945,
            0.024082543328404427,
            0.011589471250772476,
            -0.022536875680088997,
            -0.006693199276924133,
            -0.02254321426153183,
            0.011894646100699902,
            -0.005036727525293827,
            0.023219674825668335,
            -0.01442088931798935,
            -0.012603926472365856,
            0.014823006466031075,
            -0.02928212471306324,
            -0.020256148651242256,
            0.025768892839550972,
            0.018731899559497833,
            -0.004329768009483814,
            0.0027779683005064726,
            -0.02997211553156376,
            0.006177643314003944,
            0.016452977433800697,
            0.00796944834291935,
            -0.01958836056292057,
            -0.03399311751127243,
            0.022708626464009285,
            0.027743225917220116,
            -0.008288702927529812,
            -0.006128694396466017,
            -0.015019945800304413,
            -0.01187854539602995,
            -0.005115101113915443,
            -0.03146693855524063,
            -0.022978726774454117,
            0.03062489442527294,
            -0.004807651974260807,
            0.007237871177494526,
            0.013054508715867996,
            0.0386408269405365,
            0.025129500776529312,
            -0.01616057939827442,
            0.025587335228919983,
            -0.007929789833724499,
            0.0026018978096544743,
            -0.0036594292614609003,
            0.00604556268081069,
            -0.016128886491060257,
            -0.030777115374803543,
            0.002182525349780917,
            0.005721091292798519,
            0.015550848096609116,
            0.00022348649508785456,
            0.024771636351943016,
            -0.004452819004654884,
            0.03671501576900482,
            0.02867554873228073,
            0.021732833236455917,
            0.007170828524976969,
            -0.002598665188997984,
            -0.0011070469627156854,
            -0.043031129986047745,
            0.027635397389531136,
            0.04050867632031441,
            0.013733071275055408,
            -0.013411266729235649,
            -0.016391132026910782,
            -0.004805074073374271,
            0.014386512339115143,
            -0.012130227871239185,
            0.009555867873132229,
            -0.015591908246278763,
            -0.015469467267394066,
            0.021760810166597366,
            -0.015494954772293568,
            0.03210368752479553,
            0.014672641642391682,
            0.02026679553091526,
            -0.03335152566432953,
            -0.0011579011334106326,
            -0.03384086862206459,
            0.03573558107018471,
            -0.019681785255670547,
            -0.014736556448042393,
            0.00923451129347086,
            0.02180805429816246,
            -0.01393688190728426,
            0.009728297591209412,
            -0.005951280239969492,
            0.023090271279215813,
            0.012464978732168674,
            0.005783841945230961,
            -0.025026284158229828,
            0.018535366281867027,
            0.02462921291589737,
            -0.004157597199082375,
            -0.005662203766405582,
            0.020498182624578476,
            0.02775566279888153,
            -0.033097680658102036,
            0.04836106300354004,
            0.03801862150430679,
            -0.0034832071978598833,
            -0.02985282428562641,
            -0.013784163631498814,
            -0.04300064593553543,
            0.047856654971838,
            -0.004183071665465832,
            0.0038460984360426664,
            0.03609655797481537,
            -0.0220868568867445,
            -0.028533753007650375,
            0.03515530377626419,
            -0.03724467754364014,
            -0.01679748110473156,
            -0.008929293602705002,
            0.014066948555409908,
            0.026976928114891052,
            0.009521091356873512,
            -0.012322907336056232,
            0.014288810081779957,
            0.022998180240392685,
            -0.005678532179445028,
            -0.003758951323106885,
            0.008347566239535809,
            -0.010050463490188122,
            0.015924910083413124,
            -0.01328823808580637,
            -0.011982204392552376,
            0.0023972815833985806,
            0.02444658800959587,
            -0.009461380541324615,
            0.027069203555583954,
            -0.0018410165794193745,
            0.023565812036395073,
            0.025814048945903778,
            0.018346186727285385,
            -0.026381155475974083,
            -0.009215093217790127,
            0.014392112381756306,
            0.01878274977207184,
            0.030201120302081108,
            -0.0006240540533326566,
            -0.016744643449783325,
            -0.019343798980116844,
            -0.022579967975616455,
            0.01858467608690262,
            0.005399898625910282,
            0.032074231654405594,
            0.030041364952921867,
            0.008608597330749035,
            -0.00867553148418665,
            -0.003474986180663109,
            -0.0043666972778737545,
            -0.010453639551997185,
            -0.023199746385216713,
            0.03111821971833706,
            0.0015607820823788643,
            0.040512118488550186,
            0.012821617536246777,
            -0.02183966524899006,
            0.027905574068427086,
            0.024812614545226097,
            -0.020534634590148926,
            -0.008477306924760342,
            -0.046845778822898865,
            -0.004970896057784557,
            0.03049679473042488,
            0.05499361455440521,
            -0.006810010876506567,
            0.022664841264486313,
            0.004874831065535545,
            -0.019842566922307014,
            0.008708395063877106,
            0.02299678698182106,
            0.018638359382748604,
            0.0006683630053885281,
            -0.02023870125412941,
            0.02534603327512741,
            0.007506258320063353,
            -0.01573885791003704,
            -0.01376438234001398,
            0.021132925525307655,
            -0.007231436669826508,
            -0.024980992078781128,
            0.029241537675261497,
            -0.041024431586265564,
            0.02061077021062374,
            0.000581675092689693,
            0.022806743159890175,
            0.013580074533820152,
            0.01466607116162777,
            0.020836109295487404,
            0.01960434950888157,
            -0.015893323346972466,
            -0.008537991903722286,
            -0.03397459164261818,
            -0.010589045472443104,
            0.020724905654788017,
            0.027858108282089233,
            0.022749869152903557,
            -0.03252612426877022,
            0.020064759999513626,
            0.01514582522213459,
            -0.025196081027388573,
            0.004911383613944054,
            0.0121341897174716,
            0.03570619970560074,
            -0.0027078900020569563,
            0.02881360985338688,
            -0.005247277673333883,
            0.01285689976066351,
            -0.0068757119588553905,
            0.03765842318534851,
            0.03118186816573143,
            0.0011736340820789337,
            -0.003533747047185898,
            -0.029721014201641083,
            -0.004461181815713644,
            -0.03846632316708565,
            0.029964474961161613,
            -0.0916445404291153,
            -0.02212345227599144,
            -0.024007925763726234,
            -0.004357608035206795,
            0.0019200514070689678,
            0.0413699671626091,
            0.019084475934505463,
            -0.018605930730700493,
            -0.021237103268504143,
            0.004244902171194553,
            0.006484947632998228,
            -0.00529229873791337,
            0.016274891793727875,
            0.011191333644092083,
            -0.004077740479260683,
            0.006369359791278839,
            0.03156862035393715,
            -0.0032176703680306673,
            0.026662036776542664,
            0.021154634654521942,
            0.015383142977952957,
            0.016956139355897903,
            -0.007398474961519241,
            -0.014887010678648949,
            -0.001550782355479896,
            0.008422243408858776,
            0.030926235020160675,
            -0.00274330610409379,
            0.03183147683739662,
            0.011470846831798553,
            -0.005327166523784399,
            0.03248726204037666,
            0.029769092798233032,
            0.017596060410141945,
            0.006097008474171162,
            0.020780375227332115,
            0.001485248445533216,
            0.003306694095954299,
            0.018929576501250267,
            -0.024922531098127365,
            -0.017811842262744904,
            0.0442100428044796,
            0.024305302649736404,
            -0.01565585471689701,
            -0.0002799023932311684,
            -0.011738365516066551,
            0.015146680176258087,
            0.017181608825922012,
            0.024161728098988533,
            0.0003404903691262007,
            -0.027954185381531715,
            -0.023486565798521042,
            -0.028674283996224403,
            -0.008040986955165863,
            0.02584800496697426,
            0.005881855729967356,
            -0.015417554415762424,
            0.009690859355032444,
            -0.03121030703186989,
            0.001964515307918191,
            0.002650221111252904,
            -0.0010542100062593818,
            -0.052380211651325226,
            -0.005255840718746185,
            0.032032519578933716,
            0.011521192267537117,
            -0.030298426747322083,
            -0.009373996406793594,
            -0.0280876774340868,
            -0.0017250347882509232,
            -0.026618540287017822,
            0.0032224785536527634,
            0.0036523647140711546,
            -0.0210227370262146,
            -0.030127953737974167,
            0.003283682744950056,
            -0.004025512840598822,
            -0.006766191683709621,
            -0.0031895767897367477,
            0.020024891942739487,
            -0.016789739951491356,
            -0.0025599426589906216,
            -0.013605030253529549,
            0.023233721032738686,
            -0.0016288274200633168,
            0.004470726475119591,
            0.031088104471564293,
            0.018290523439645767,
            0.003940887283533812,
            -0.009088150225579739,
            -0.011185234412550926,
            -0.004314543213695288,
            -0.004394538700580597,
            0.027310874313116074,
            0.027526728808879852,
            0.015373002737760544,
            0.009488510899245739,
            -0.010287756100296974,
            0.005145276430994272,
            -0.022493086755275726,
            0.020611124113202095,
            0.0073048449121415615,
            -0.025044677779078484,
            0.02367362566292286,
            -0.01234524603933096,
            0.018133854493498802,
            -0.011464114300906658,
            -0.019980628043413162,
            0.029283011332154274,
            -0.00420716404914856,
            -0.010781396180391312,
            0.028937745839357376,
            0.023701343685388565,
            0.01968158222734928,
            0.030538277700543404,
            -0.005743216723203659,
            -0.033405013382434845,
            -0.01758134923875332,
            0.023818932473659515,
            -0.003039134666323662,
            -0.002970761153846979,
            -0.011048499494791031,
            0.03322901204228401,
            -0.022000132128596306,
            -0.02962646260857582,
            -0.011907724663615227,
            0.0290832556784153,
            -0.029980558902025223,
            -0.02589733898639679,
            0.021349160000681877,
            -0.025988010689616203,
            0.02511194534599781,
            -0.005624675657600164,
            -0.032300397753715515,
            0.03084259107708931,
            -0.012687929905951023,
            -0.006180564872920513,
            0.018443875014781952,
            -0.003869748907163739,
            0.008507533930242062,
            -0.013259928673505783,
            -0.013759683817625046,
            0.019497180357575417,
            -0.007667262107133865,
            0.035558879375457764,
            0.0018667379627004266,
            0.014938492327928543,
            0.016293300315737724,
            -0.02210037037730217,
            -0.03154391050338745,
            -0.006369170267134905,
            -0.0039361147210001945,
            0.02467457205057144,
            0.014504793100059032,
            0.02010619454085827,
            -0.03125210478901863,
            -0.0165147315710783,
            -0.022806433960795403,
            -0.01349592488259077,
            0.027362138032913208,
            0.0217447392642498,
            0.010053997859358788,
            0.013750412501394749,
            0.010651906952261925,
            0.018500590696930885,
            0.01222982257604599,
            0.027925940230488777,
            0.0037156431935727596,
            0.02108941227197647,
            0.026006072759628296,
            0.0375935435295105,
            0.01881340891122818,
            0.02867739647626877,
            -0.03099919855594635,
            0.007016933988779783,
            0.029532991349697113,
            -0.00022695647203363478,
            -0.010137398727238178,
            -0.010034595616161823,
            -0.010075545869767666,
            -0.023210331797599792,
            -0.033940136432647705,
            0.008783395402133465,
            0.04973552003502846,
            0.021638954058289528,
            -0.00811736099421978,
            0.031093137338757515,
            0.04831281304359436,
            0.019888227805495262,
            -0.004664341453462839,
            -0.013582389801740646,
            -0.0061793760396540165,
            -0.014269780367612839,
            -0.005920459982007742,
            0.0006264901603572071,
            0.008750703185796738,
            -0.060813792049884796,
            0.009541292674839497,
            -0.02014675736427307,
            -0.045109931379556656,
            0.011584341526031494,
            0.004151453729718924,
            0.023885589092969894,
            -0.010707792825996876,
            -0.0024110868107527494,
            0.018983779475092888,
            -0.02634791098535061,
            -0.028937170282006264,
            0.019414590671658516,
            -0.001240232028067112,
            -0.009749438613653183,
            0.03394021466374397,
            0.004010491538792849,
            -0.00859751831740141,
            -0.006764812394976616,
            -0.012212404981255531,
            0.012514133006334305,
            0.02627340704202652,
            -0.002276943763718009,
            -0.04003168269991875,
            0.005040043964982033,
            0.011596049182116985,
            -0.02028726041316986,
            0.007942590862512589,
            0.010599826462566853,
            0.011679993011057377,
            0.0056715551763772964,
            -0.010871116071939468,
            0.02099408581852913,
            0.0010495273163542151,
            -0.005755397956818342,
            -0.040452927350997925,
            0.013744388706982136,
            -0.014417389407753944,
            -0.022061970084905624,
            -0.0024249888956546783,
            0.01713334582746029,
            -0.015613452531397343,
            0.0008650230593048036,
            0.039315465837717056,
            -0.007945221848785877,
            -0.0027694096788764,
            0.007268805988132954,
            -0.023644493892788887,
            0.003429217729717493,
            0.03521311655640602,
            0.03243955224752426,
            -0.003339445684105158,
            0.03335828334093094,
            0.0044190105982124805,
            0.016458341851830482,
            -0.023435896262526512,
            0.018823282793164253,
            0.006464749574661255,
            -0.02801923267543316,
            0.007555303629487753,
            0.008203992620110512,
            3.102735354332253e-05,
            -0.0010813189437612891,
            0.012147272005677223,
            0.015581315383315086,
            0.02188405767083168,
            0.001859384123235941,
            -0.025653034448623657,
            0.02657049335539341,
            0.04746222868561745,
            0.0040433104149997234,
            -0.004883825313299894,
            0.0047582718543708324,
            0.056021545082330704,
            0.02688160538673401,
            -0.015158267691731453,
            0.006209729239344597,
            -0.01899152621626854,
            0.014185302890837193,
            -0.009128361940383911,
            -0.009759025648236275,
            -0.018425432965159416,
            0.013947295024991035,
            -0.02890283241868019,
            0.020445017144083977,
            -0.010924427770078182,
            0.01211232878267765,
            0.0077273971401154995,
            0.027592424303293228,
            0.025696564465761185,
            -0.010070840828120708,
            -0.023167215287685394,
            0.021924901753664017,
            -0.025127721950411797,
            0.031126152724027634,
            -0.016421113163232803,
            0.013139390386641026,
            0.021636495366692543,
            0.004665880464017391,
            8.616333798272535e-05,
            -0.004854283761233091,
            0.0010571957100182772,
            0.014970398508012295,
            0.0643521323800087,
            -0.052152011543512344,
            -0.007096637971699238,
            0.020217515528202057,
            0.011646909639239311,
            -0.004277458880096674,
            0.006334264297038317,
            0.006288448348641396,
            0.02226770669221878,
            0.02505658008158207,
            0.0005297419265843928,
            0.024094995111227036,
            -0.002594494028016925,
            -0.0087105268612504,
            0.0033749202266335487,
            0.009700528346002102,
            0.0008799663628451526,
            -0.02564745955169201,
            0.008675104938447475,
            0.015449853613972664,
            0.0237446092069149,
            0.020697886124253273,
            0.02854255959391594,
            -0.0017344889929518104,
            -0.009446050971746445,
            0.02217434160411358,
            0.003931509796530008,
            -0.002005558693781495,
            -0.0037018107250332832,
            -0.017311910167336464,
            0.040878135710954666,
            0.037051036953926086,
            -0.0194404199719429,
            -0.018334388732910156,
            -0.0021640791092067957,
            -0.02170811966061592,
            0.04294527694582939,
            -0.035637058317661285,
            -0.03435397893190384,
            -0.0057315765880048275,
            0.006903874687850475,
            0.016190141439437866,
            0.017757730558514595,
            0.040723785758018494,
            0.016971273347735405,
            0.03196267411112785,
            -0.04067245498299599,
            -0.027293499559164047,
            -0.024352682754397392,
            0.03912908583879471,
            0.004756617359817028,
            -0.026141565293073654,
            0.010481824167072773,
            0.03312798961997032,
            0.03368726745247841,
            -0.0461324043571949,
            0.029723411425948143,
            0.014944951049983501,
            -0.022064754739403725,
            -0.01829012855887413,
            0.0007709850324317813,
            0.015306774526834488,
            0.018839159980416298,
            0.002707814099267125,
            0.021750183776021004,
            0.00829989742487669,
            0.006479167845100164,
            -0.023188309744000435,
            -0.01939953863620758,
            0.01855304092168808,
            0.02037619799375534,
            0.019203519448637962,
            -0.012193136848509312,
            0.022671064361929893,
            0.026820141822099686,
            -0.028776442632079124,
            0.033839549869298935,
            0.022793492302298546,
            0.02340429276227951,
            0.0355706624686718,
            -0.013722706586122513,
            -0.021251000463962555,
            -0.006094856187701225,
            -0.01881043054163456,
            0.005581664387136698,
            -0.005700338631868362,
            0.019634801894426346,
            -0.005883392412215471,
            0.0577520914375782,
            0.02718459442257881,
            -0.020600086078047752,
            0.00968930870294571,
            0.00821978971362114,
            0.00028081145137548447,
            -0.02271491475403309,
            -0.008356761187314987,
            0.013135852292180061,
            0.024237480014562607,
            0.015441148541867733,
            -0.022644048556685448,
            -0.029270227998495102,
            -0.002355696866288781,
            -0.039004482328891754,
            -0.02424977347254753,
            -0.02589041367173195,
            0.01093214564025402,
            0.014821667224168777,
            -0.02938353270292282,
            -0.015077716670930386,
            0.02285650372505188,
            -0.002172688487917185,
            -0.02172028087079525,
            0.02103177271783352,
            -0.014510068111121655,
            0.01209754217416048,
            -0.020485101267695427,
            -0.037063416093587875,
            0.02931676059961319,
            0.025377875193953514,
            0.03170020133256912,
            -0.012747847475111485,
            -0.027593333274126053,
            0.008452991023659706,
            -0.03387003019452095,
            -0.00432793190702796,
            -0.03429334610700607,
            0.011097249574959278,
            0.017428401857614517,
            0.011317169293761253,
            0.005049084313213825,
            0.02675795741379261,
            0.01845664344727993,
            -0.020186567679047585,
            -0.004750941414386034,
            -0.01035307627171278,
            -0.013896097429096699,
            -0.013593500480055809,
            0.033331070095300674,
            -0.0126876812428236,
            -0.006556011736392975,
            0.029057100415229797,
            0.015903905034065247,
            0.03324852138757706,
            0.0006662457599304616,
            -0.008137506432831287,
            0.011564312502741814,
            0.00943349301815033,
            0.03351953253149986,
            -0.06717916578054428,
            -0.021652698516845703,
            0.03659514710307121,
            -0.011942191049456596,
            -0.04088878631591797,
            -0.019986947998404503,
            -0.008134945295751095,
            0.01171520072966814,
            0.022309547290205956,
            -0.017900777980685234,
            -0.007485945709049702,
            0.01506512239575386,
            -0.002397423144429922,
            -0.0178186297416687,
            0.0034167615231126547,
            0.038278646767139435,
            -0.03821345791220665,
            -0.02004583552479744,
            0.004670305177569389,
            0.001313180779106915,
            -0.003207152709364891,
            -0.009288191795349121,
            0.03211347386240959,
            -0.007570202462375164,
            0.03161239251494408,
            -0.03005915693938732,
            -0.03198027238249779,
            -0.017980534583330154,
            0.02408595010638237,
            0.0191043708473444,
            0.04981403797864914,
            0.0008657231810502708,
            -0.005140742287039757,
            -0.020502354949712753,
            -0.016032962128520012,
            0.03786269575357437,
            0.004625375848263502,
            0.032376039773225784,
            0.018244778737425804,
            0.022807320579886436,
            0.033714063465595245,
            0.009979629889130592,
            0.009002447128295898,
            -0.018648339435458183,
            0.017353452742099762,
            0.009252234362065792,
            -0.030929362401366234,
            0.015594394877552986,
            -0.007549291476607323,
            -0.022405710071325302,
            -0.02801000140607357,
            0.02317080646753311,
            -0.0008102822466753423,
            -0.061943139880895615,
            0.025558821856975555,
            -0.013873737305402756,
            0.020597267895936966,
            -0.026376957073807716,
            0.023661524057388306,
            -0.03165411576628685,
            -0.01501384936273098,
            -0.017230669036507607,
            0.00584766548126936,
            0.0074127293191850185,
            -0.013217379339039326,
            0.020887227728962898,
            0.020234663039445877,
            -0.019953979179263115,
            0.007939187809824944,
            -0.020755859091877937,
            -0.009943940676748753,
            -0.02118912898004055,
            0.036304157227277756,
            -0.016823871061205864,
            -0.02769305370748043,
            0.007801694795489311,
            0.02944890782237053,
            -0.01648593693971634,
            0.02265363559126854,
            -0.0333278551697731,
            -0.040311336517333984,
            0.01669239066541195,
            -0.024750733748078346,
            -0.024487648159265518,
            0.01320144534111023,
            0.02055194601416588,
            -0.016362469643354416,
            -0.008477319963276386,
            -0.04079631343483925,
            -0.018592016771435738,
            -0.021992571651935577,
            -0.0008557441178709269,
            0.007934004068374634,
            -0.003452033270150423,
            0.013094846159219742,
            -0.01945038139820099,
            0.03215280547738075,
            -0.024722125381231308,
            -0.022984664887189865,
            0.009522205218672752,
            0.01617736741900444,
            0.003877257462590933,
            0.060364529490470886,
            -0.04112251102924347,
            0.0386403426527977,
            -0.006750951055437326,
            0.015682796016335487,
            -0.008300060406327248,
            -0.011492587625980377,
            -0.0036384237464517355,
            -0.02240588702261448,
            0.03999602794647217,
            0.018400121480226517,
            -0.02681805193424225,
            0.022009633481502533,
            -0.012650619260966778,
            -0.021430205553770065,
            0.04923073947429657,
            -0.005950456485152245,
            -0.04839403182268143,
            0.017174912616610527,
            0.011685091070830822,
            -0.006849935743957758,
            0.03537173569202423,
            -0.04382716119289398,
            0.0035052094608545303,
            0.007495391182601452,
            -0.009652281180024147,
            -0.017501303926110268,
            -0.016488533467054367
        ],
        [
            -0.006014246493577957,
            -0.053110308945178986,
            0.0008978152181953192,
            0.006639318540692329,
            -0.008319689892232418,
            -0.04415638744831085,
            -0.019584856927394867,
            -0.010833035223186016,
            0.01130315475165844,
            0.028641441836953163,
            0.003596631344407797,
            0.018702590838074684,
            0.00810178741812706,
            0.01108588557690382,
            -0.040536683052778244,
            0.00887732207775116,
            0.005100939888507128,
            0.02235488034784794,
            0.03685581684112549,
            0.011108429171144962,
            0.011715481989085674,
            -0.0054340725764632225,
            0.02567434310913086,
            -0.02963348850607872,
            -0.020645666867494583,
            -0.01509795431047678,
            0.01594104804098606,
            -0.002786024007946253,
            0.023369915783405304,
            -0.004889396484941244,
            -0.0010119735961779952,
            -0.024422194808721542,
            -0.011904042214155197,
            0.03508616238832474,
            -0.018204176798462868,
            -0.05696854367852211,
            0.014759549871087074,
            0.00044801345211453736,
            0.002427727682515979,
            0.0011995580280199647,
            -0.013954201713204384,
            -0.0004434060538187623,
            0.012744365260004997,
            0.021878445520997047,
            0.004521239548921585,
            0.018635950982570648,
            -0.012314067222177982,
            -0.04479505866765976,
            -0.010431917384266853,
            -0.035606056451797485,
            -0.03838162496685982,
            -0.0034668201114982367,
            0.02276809886097908,
            0.024349622428417206,
            -0.010126913897693157,
            -0.008209241554141045,
            -0.010418279096484184,
            0.031477414071559906,
            0.01979472115635872,
            -0.0017720628529787064,
            -0.012020875699818134,
            -0.01873488910496235,
            0.008356745354831219,
            -0.006391944829374552,
            -0.028663141652941704,
            -0.011753369122743607,
            0.03774347901344299,
            0.003566684667021036,
            0.04279142618179321,
            0.0019101183861494064,
            0.018045222386717796,
            0.04032320901751518,
            -0.01750006340444088,
            -0.00039781868690624833,
            -0.007867571897804737,
            0.008313139900565147,
            0.004574218764901161,
            -0.0067976792342960835,
            -0.003964387346059084,
            0.026415405794978142,
            -0.018750494346022606,
            -0.053571511059999466,
            -0.0024949274957180023,
            0.020159702748060226,
            0.013983857817947865,
            0.012856042943894863,
            -0.010339650325477123,
            0.020047852769494057,
            -0.021345950663089752,
            0.010186359286308289,
            0.008736655116081238,
            -0.014215218834578991,
            -0.007733853999525309,
            -0.012657699175179005,
            -0.016810128465294838,
            -0.011026228778064251,
            0.03507310152053833,
            -0.005978461820632219,
            0.03149006515741348,
            -0.010292880237102509,
            -0.010719744488596916,
            0.0352204293012619,
            0.011208569630980492,
            0.016858281567692757,
            -0.02415302023291588,
            0.00862363912165165,
            -0.02901970036327839,
            -0.026544585824012756,
            0.007752686273306608,
            -0.0193454809486866,
            0.0251323115080595,
            0.02211187407374382,
            -0.014776438474655151,
            -0.010411611758172512,
            -0.03512491658329964,
            0.029181351885199547,
            0.0069807530380785465,
            0.03918832167983055,
            0.025761820375919342,
            0.004564895294606686,
            0.017206858843564987,
            0.005223505198955536,
            -0.04969814419746399,
            0.013898503966629505,
            0.01393252331763506,
            -0.023250484839081764,
            -0.001780190970748663,
            -0.011940213851630688,
            -0.02599823847413063,
            0.026934316381812096,
            -0.009473869577050209,
            -0.017922328785061836,
            -0.0365452878177166,
            -0.00011644492769846693,
            0.010889317840337753,
            0.017952706664800644,
            0.02853419817984104,
            0.018074221909046173,
            0.03958531841635704,
            -0.0009532449766993523,
            -0.007554039359092712,
            0.009059394709765911,
            0.005058044567704201,
            -0.022581737488508224,
            0.021302618086338043,
            0.0016497866017743945,
            0.03366338089108467,
            -0.008010177873075008,
            -0.02075342833995819,
            -0.01694740168750286,
            -0.02627583034336567,
            0.009852436371147633,
            -0.04157044738531113,
            -0.04401809349656105,
            0.009113010950386524,
            -0.024495312944054604,
            0.04105193167924881,
            -0.033580753952264786,
            0.021878549829125404,
            -0.030969073995947838,
            -0.00538794556632638,
            -0.011811010539531708,
            0.03234246373176575,
            -0.03294450417160988,
            -0.020283706486225128,
            -0.016223959624767303,
            -0.017025457695126534,
            0.0250070933252573,
            0.034334998577833176,
            0.03138558194041252,
            -0.022392287850379944,
            -0.002287370152771473,
            -0.0029787556268274784,
            0.006067821756005287,
            0.009261376224458218,
            0.010567043907940388,
            0.00988383311778307,
            -0.03855167329311371,
            0.03917313367128372,
            0.01648792438209057,
            -0.0072897206991910934,
            0.024797411635518074,
            -0.029385630041360855,
            -0.022090531885623932,
            -0.012653090991079807,
            -0.0032817719038575888,
            -0.005531419534236193,
            0.022568583488464355,
            -0.010645866394042969,
            -0.016429660841822624,
            0.022327888756990433,
            -0.01762508787214756,
            -0.08014073967933655,
            0.03337140753865242,
            -0.002050244016572833,
            -0.012459858320653439,
            0.04024570435285568,
            -0.009903077967464924,
            -0.0066403578966856,
            -0.005590020678937435,
            -0.0331808365881443,
            -0.013576407916843891,
            -0.009124871343374252,
            -0.0028158335480839014,
            0.030767226591706276,
            -0.050637923181056976,
            0.04309377446770668,
            0.008647418580949306,
            -0.017994383350014687,
            0.01003048662096262,
            0.013380336575210094,
            -0.027116013690829277,
            0.06897785514593124,
            -0.008988885208964348,
            0.030962202697992325,
            -0.021408112719655037,
            0.004517176654189825,
            0.033811721950769424,
            0.020997611805796623,
            -0.022650666534900665,
            0.02395528182387352,
            0.0014540426200255752,
            -0.024631407111883163,
            0.03463514521718025,
            -0.010245936922729015,
            -0.025800490751862526,
            -0.005182275548577309,
            -0.02344474568963051,
            0.059190355241298676,
            0.022767232730984688,
            0.005069200415164232,
            -0.04979309067130089,
            -0.0058104656636714935,
            -0.0006195700261741877,
            0.018322868272662163,
            0.031265988945961,
            -0.07176365703344345,
            -0.03420671075582504,
            -0.027912361547350883,
            0.010972878895699978,
            -0.013703272677958012,
            0.006640306208282709,
            -0.005784914363175631,
            0.007843739353120327,
            0.048673830926418304,
            0.005642605014145374,
            0.014471422880887985,
            -0.022568870335817337,
            0.00986836664378643,
            -0.01721912808716297,
            -0.010902553796768188,
            -0.021815892308950424,
            0.020500369369983673,
            0.019591521471738815,
            0.027452100068330765,
            -0.024361059069633484,
            -0.010951542295515537,
            0.011104829609394073,
            -0.027357807382941246,
            -0.03973929211497307,
            -0.043727267533540726,
            0.034361328929662704,
            0.0017723992932587862,
            -0.024379676207900047,
            -0.0045448485761880875,
            -0.009973375126719475,
            0.030618125572800636,
            0.0008272546110674739,
            -0.014152903109788895,
            -0.05407533049583435,
            -0.02419632114470005,
            -0.0399712398648262,
            0.0021698996424674988,
            -0.013193744234740734,
            0.01899239793419838,
            -0.011584327556192875,
            0.018460078164935112,
            0.007694684434682131,
            0.03670978546142578,
            -0.04870135709643364,
            -0.06228413060307503,
            0.004182359203696251,
            0.0026639977004379034,
            -0.03771791234612465,
            0.007510102353990078,
            -0.018959563225507736,
            -0.03957807272672653,
            -0.0393509715795517,
            -0.005843165330588818,
            0.01853150688111782,
            0.023924734443426132,
            0.011925830505788326,
            -0.028724446892738342,
            0.02121950127184391,
            0.031778011471033096,
            0.018734466284513474,
            0.019555291160941124,
            -0.005608364474028349,
            0.007142764516174793,
            -0.026273254305124283,
            -0.0007870427216403186,
            -0.003003732766956091,
            -0.018086135387420654,
            -0.015118123963475227,
            -0.007891440764069557,
            -0.001778501202352345,
            0.02038610354065895,
            -0.006046164780855179,
            -0.04841000959277153,
            0.02238018810749054,
            0.03374870866537094,
            0.032320521771907806,
            0.002203540410846472,
            0.0025122491642832756,
            0.026021122932434082,
            -0.008771485649049282,
            -0.011817507445812225,
            -0.01157268788665533,
            -0.0042477319948375225,
            -0.03735487908124924,
            0.009349571540951729,
            0.020243093371391296,
            -0.016369052231311798,
            0.023641420528292656,
            -0.011527945287525654,
            0.0025269705802202225,
            0.019412169232964516,
            -0.006141498684883118,
            0.007757954299449921,
            -0.03297196701169014,
            -0.0017874805489555001,
            0.027552029117941856,
            -0.021676652133464813,
            -0.017006073147058487,
            0.0018759723752737045,
            -0.013222604990005493,
            0.034138165414333344,
            -0.003378850407898426,
            -0.029660359025001526,
            -0.016217771917581558,
            0.0037337420508265495,
            0.01260339841246605,
            -0.010287786833941936,
            0.013197875581681728,
            0.025971606373786926,
            -0.0050927563570439816,
            -0.05519813299179077,
            0.008003747090697289,
            0.022363919764757156,
            0.010398775339126587,
            -0.0013345591723918915,
            -0.03847599774599075,
            -0.006369725801050663,
            -0.03052195906639099,
            -0.021306710317730904,
            0.005342681426554918,
            0.0032099755480885506,
            -0.008756975643336773,
            -0.01128925196826458,
            0.013620072975754738,
            -0.015010254457592964,
            -0.007195534184575081,
            -0.046929847449064255,
            0.0035120160318911076,
            0.032228585332632065,
            0.01754247583448887,
            -0.04523392766714096,
            -0.015513556078076363,
            0.014003463089466095,
            0.019787689670920372,
            -0.026387721300125122,
            0.0018228342523798347,
            -0.03271116688847542,
            0.040133435279130936,
            -0.03510697931051254,
            0.0012209272244945168,
            0.02847590111196041,
            -0.025171509012579918,
            0.044974103569984436,
            0.053633566945791245,
            -0.01720298081636429,
            -0.006663109175860882,
            0.03338926285505295,
            0.021515382453799248,
            0.041217654943466187,
            -0.002227934543043375,
            -0.025093702599406242,
            -0.023190367966890335,
            -0.0029075085185468197,
            0.03889283910393715,
            0.023815196007490158,
            0.014908267185091972,
            -0.021598411723971367,
            -0.004072256851941347,
            -8.425544365309179e-05,
            0.032377224415540695,
            0.022873396053910255,
            0.010016262531280518,
            -0.023547790944576263,
            0.024819640442728996,
            -0.04423489794135094,
            0.0030097186099737883,
            -0.022852834314107895,
            -0.013548728078603745,
            0.013900090008974075,
            0.010300612077116966,
            0.016702767461538315,
            0.0006789169856347144,
            -0.028140604496002197,
            0.010622847825288773,
            -0.004666724242269993,
            0.011086112819612026,
            0.008207612670958042,
            0.03148734197020531,
            -0.0500994548201561,
            0.02204091288149357,
            0.006896739825606346,
            -0.027443839237093925,
            -0.013938749209046364,
            0.023990685120224953,
            0.031322479248046875,
            -0.028269769623875618,
            -0.006273760925978422,
            -0.007441727444529533,
            -0.028487756848335266,
            0.002440453041344881,
            0.04567835479974747,
            -0.02691429853439331,
            0.023208675906062126,
            0.012645012699067593,
            0.0007496654870919883,
            0.03298742324113846,
            0.016935883089900017,
            0.01685066521167755,
            -0.030521465465426445,
            0.041598841547966,
            -0.005299149081110954,
            -0.02635764144361019,
            -0.014637931250035763,
            -0.006269319448620081,
            -0.019235266372561455,
            0.03478856384754181,
            -0.031040532514452934,
            0.021567650139331818,
            0.010348259471356869,
            0.009535551071166992,
            -0.0003011598309967667,
            0.06567281484603882,
            0.012494610622525215,
            -0.07154812663793564,
            -0.006431326270103455,
            0.03266973793506622,
            -0.01233232207596302,
            -0.017822321504354477,
            0.0001954288745764643,
            0.033186979591846466,
            0.035536158829927444,
            -0.01596161350607872,
            -0.03244921565055847,
            0.03902634605765343,
            0.038482099771499634,
            -0.006083028391003609,
            -0.009144240990281105,
            0.029184887185692787,
            0.02195245586335659,
            0.006025142502039671,
            0.007927787490189075,
            0.037549879401922226,
            0.0030164900235831738,
            0.024193888530135155,
            0.01079511921852827,
            -0.01595599763095379,
            -0.025853848084807396,
            0.011189157143235207,
            -0.0002496846718713641,
            0.027575034648180008,
            0.015712659806013107,
            -0.014412933960556984,
            -0.011517549864947796,
            -0.019461341202259064,
            0.019860276952385902,
            -0.0035392753779888153,
            0.02318291924893856,
            0.0026345406658947468,
            -0.019847530871629715,
            -0.01695435866713524,
            -0.02820257842540741,
            -0.0560695119202137,
            -0.047426488250494,
            0.01309827622026205,
            0.018257133662700653,
            0.009225171990692616,
            -0.015712903812527657,
            0.06321471184492111,
            0.046755027025938034,
            0.0009255274198949337,
            0.009209622628986835,
            0.008658685721457005,
            -0.019564874470233917,
            0.03943129628896713,
            -0.0030406336300075054,
            -0.002416078234091401,
            -0.008885185234248638,
            -0.03171171620488167,
            -0.033869579434394836,
            -0.006019029766321182,
            0.01788886822760105,
            -0.01836966536939144,
            0.005923976190388203,
            -0.005531584843993187,
            -0.02117752470076084,
            0.014619189314544201,
            0.021118903532624245,
            0.0012569490354508162,
            -0.016779940575361252,
            0.02271692268550396,
            0.03220381215214729,
            -0.0018476180266588926,
            -0.01810663565993309,
            -0.007973899133503437,
            0.006687004119157791,
            -0.013372763060033321,
            0.005222417414188385,
            0.0036629559472203255,
            0.03182980790734291,
            -0.0065900101326406,
            0.03667958453297615,
            -0.034929610788822174,
            0.020174521952867508,
            -0.05018135532736778,
            -0.012767116539180279,
            -0.03666118532419205,
            -0.03867733106017113,
            -0.030396029353141785,
            -0.024858349934220314,
            0.012469897046685219,
            -0.0049275183118879795,
            -0.009134532883763313,
            -0.0022078955080360174,
            0.015179523266851902,
            -0.00515488488599658,
            0.011991741135716438,
            0.005805121269077063,
            -0.019808219745755196,
            0.02575600892305374,
            -0.057982999831438065,
            0.03674580901861191,
            -0.02301817014813423,
            0.02063509076833725,
            -0.005498297978192568,
            0.02008623257279396,
            0.02479671686887741,
            -0.018785137683153152,
            0.03640245646238327,
            -0.059681251645088196,
            -0.028970923274755478,
            -0.018059290945529938,
            0.01600351184606552,
            0.0065636225044727325,
            0.025054078549146652,
            -0.027029501274228096,
            0.002609846880659461,
            0.03549865633249283,
            0.004929277580231428,
            -0.04823745787143707,
            -0.017995033413171768,
            -0.03836875408887863,
            -0.020756831392645836,
            0.006659389939159155,
            -0.017330486327409744,
            0.022823238745331764,
            -0.03189312294125557,
            -0.014443797059357166,
            -0.019966192543506622,
            0.0329379141330719,
            -0.021802138537168503,
            0.012436952441930771,
            0.010688436217606068,
            0.025462206453084946,
            -0.017101913690567017,
            0.007394322659820318,
            0.027383852750062943,
            -0.014604460448026657,
            -0.011552590876817703,
            0.01569892279803753,
            -0.018998971208930016,
            0.02024099789559841,
            0.02402278035879135,
            -0.0022202664986252785,
            0.002048415830358863,
            -0.006724957842379808,
            0.005882711615413427,
            -0.01300130970776081,
            -0.015896767377853394,
            -0.042259134352207184,
            0.0011220519663766026,
            -0.01513104047626257,
            -0.000145958925713785,
            -0.0019160747760906816,
            0.00013452385610435158,
            0.008444558829069138,
            0.01530628651380539,
            0.018219389021396637,
            0.0004962294478900731,
            0.01883813738822937,
            -0.019245119765400887,
            0.003317420370876789,
            0.024861320853233337,
            0.01939709112048149,
            -0.02289697900414467,
            -0.007553020492196083,
            -0.022085009142756462,
            -0.022126995027065277,
            0.030897749587893486,
            -0.02116517350077629,
            0.0005387583514675498,
            0.0221798587590456,
            0.01555303018540144,
            -0.011783882975578308,
            0.011421557515859604,
            -0.062429383397102356,
            -0.03956687077879906,
            0.018631987273693085,
            -0.030106963589787483,
            -0.0023720182944089174,
            -0.04416760429739952,
            -0.0020977845415472984,
            0.014062667265534401,
            0.023070648312568665,
            -0.000704921898432076,
            0.004744024481624365,
            0.02962278574705124,
            0.014042469672858715,
            0.025409018620848656,
            -0.024262050166726112,
            -0.022707078605890274,
            -0.01605343073606491,
            0.03090607561171055,
            -0.011499128304421902,
            -0.0012594587169587612,
            -0.010723065584897995,
            -0.005723554641008377,
            -0.02803368680179119,
            0.00013930484419688582,
            0.03358950838446617,
            -0.003610022831708193,
            -0.056265391409397125,
            0.01186510268598795,
            0.021008333191275597,
            0.011302299797534943,
            0.027076534926891327,
            -0.01847016252577305,
            -0.047809332609176636,
            -0.00469159334897995,
            -0.06045617163181305,
            0.0202779620885849,
            -0.024384604766964912,
            -0.01849278248846531,
            -0.009475541301071644,
            0.008332549594342709,
            -0.07307490706443787,
            -0.023600025102496147,
            0.016923874616622925,
            -0.005561340134590864,
            0.03088470920920372,
            0.030290374532341957,
            0.010018064640462399,
            0.02620673179626465,
            -0.02751668356359005,
            -0.023277249187231064,
            0.017165249213576317,
            -0.0430864542722702,
            -0.03028039261698723,
            0.017815619707107544,
            0.02887316793203354,
            0.013175465166568756,
            0.025994306430220604,
            0.028776539489626884,
            0.022287430241703987,
            0.028092369437217712,
            -0.003056504298001528,
            -0.033890094608068466,
            -0.02210131101310253,
            -0.01062534935772419,
            -0.023397764191031456,
            -0.007732938975095749,
            0.0706004649400711,
            0.01755720004439354,
            -0.012113133445382118,
            -0.054165396839380264,
            0.03150264173746109,
            -0.03610469400882721,
            -0.006936387624591589,
            0.022359542548656464,
            0.02429627627134323,
            0.00954417698085308,
            -0.018570926040410995,
            0.02235965058207512,
            0.046172525733709335,
            0.01103348471224308,
            0.008129763416945934,
            -0.032800134271383286,
            0.003509538248181343,
            -0.014593596570193768,
            -0.019725441932678223,
            -0.023411497473716736,
            0.02131405845284462,
            0.013578804209828377,
            -0.01478492096066475,
            -0.006817552261054516,
            -0.024154527112841606,
            0.01291918195784092,
            0.03847362846136093,
            0.018909169360995293,
            -0.02912287786602974,
            -0.023474156856536865,
            0.012384146451950073,
            0.02080264501273632,
            0.0002689319080673158,
            0.023865606635808945,
            0.01947268471121788,
            0.018696758896112442,
            0.0231906920671463,
            -0.026824144646525383,
            0.005739938002079725,
            -0.0216365996748209,
            0.003673928789794445,
            -0.01112152636051178,
            0.01204032264649868,
            0.017308877781033516,
            -0.006294856313616037,
            0.0024517786223441362,
            0.004144339822232723,
            -0.0031280929688364267,
            0.01695854961872101,
            -0.005409822333604097,
            0.010504399426281452,
            0.01699724979698658,
            -0.0038253692910075188,
            -0.013631990179419518,
            -0.029970021918416023,
            -0.008286254480481148,
            0.02003241330385208,
            -0.04127282649278641,
            0.008954566903412342,
            -0.042118847370147705,
            -0.01230575516819954,
            0.006823427975177765,
            -0.009700626134872437,
            0.03447937220335007,
            0.02068605273962021,
            -0.025232374668121338,
            -0.025975866243243217,
            -0.062053579837083817,
            0.0016281729331240058,
            -0.03273037075996399,
            0.03144144266843796,
            -0.027260780334472656,
            -0.052480392158031464,
            0.03348284587264061,
            0.023522356525063515,
            -0.042477305978536606,
            -0.004418021533638239,
            0.02733764424920082,
            -0.0007324910839088261,
            -0.03961168974637985,
            0.017537428066134453,
            -0.022805912420153618,
            -0.004643368534743786,
            -0.004129311535507441,
            0.0073762694373726845,
            -0.045680899173021317,
            0.008059630170464516,
            -0.044382430613040924,
            0.01992071606218815,
            0.006467293947935104,
            0.007531668525189161,
            -0.026052728295326233,
            0.008959115482866764,
            -0.008734156377613544,
            -0.017483947798609734,
            -0.009277759119868279,
            -0.014302674680948257,
            -0.02920394577085972,
            0.005019823554903269,
            0.00422793161123991,
            -0.049278587102890015,
            -0.008049494586884975,
            -0.020570162683725357,
            -0.0063783288933336735,
            -0.003995850216597319,
            0.004597937222570181,
            -0.03860190883278847,
            -0.048309169709682465,
            0.00771754514425993,
            -0.04225437715649605,
            0.004647010937333107,
            -0.020483434200286865,
            0.008397620171308517,
            0.018906505778431892,
            -0.06275784969329834,
            -0.0011470969766378403,
            -0.014066929928958416,
            -0.0450882613658905,
            -0.045327361673116684,
            0.017482835799455643,
            0.03991672769188881,
            -0.025979207828640938,
            -0.033810291439294815,
            0.00995915662497282,
            -0.016643276438117027,
            0.012339123524725437,
            -0.006405235268175602,
            0.013429481536149979,
            -0.03604728728532791,
            0.01600760407745838,
            0.026756364852190018,
            0.010307997465133667,
            -0.01793157123029232,
            0.009919485077261925,
            -0.028748739510774612,
            0.0031581243965774775,
            0.0027267863042652607,
            -0.016137931495904922,
            0.026355113834142685,
            -0.024078387767076492,
            0.03536910191178322,
            -0.027511287480592728,
            -0.04115894064307213,
            -0.022081976756453514,
            -0.00040094958967529237,
            0.02082977071404457,
            -0.0080453185364604,
            -0.0025956693571060896,
            0.01473814807832241,
            -0.007339655887335539,
            -0.0015296273631975055,
            -0.0019088995177298784,
            -0.007108096964657307,
            0.02220134064555168,
            0.00444995379075408,
            0.008610375225543976,
            0.020339176058769226,
            -0.009177018888294697,
            0.0018581482581794262,
            -0.04209846258163452,
            0.01882958970963955,
            -0.01370976958423853,
            0.0011597916018217802,
            0.003082600189372897,
            -0.026067201048135757,
            0.014119284227490425,
            0.01301910076290369,
            0.0020436220802366734,
            0.02350124716758728,
            -0.0028581940568983555,
            -0.027064550668001175,
            0.008191421627998352,
            0.017075352370738983,
            0.038981858640909195,
            -0.027012523263692856,
            0.0078553082421422,
            -0.022635387256741524,
            -0.007408628705888987,
            0.03259151056408882,
            -0.006912454031407833,
            -0.014006873592734337,
            0.01585214026272297,
            -0.015123776160180569,
            -0.025819081813097,
            0.037149183452129364,
            -0.00345771387219429,
            0.012931497767567635,
            0.014853155240416527,
            0.01278337836265564,
            -0.03294385224580765,
            0.024243073537945747,
            -0.07072681933641434,
            0.02449870854616165,
            -0.007217900827527046,
            -0.018394114449620247,
            0.021290497854351997,
            -0.037839073687791824,
            -0.0038723584730178118,
            0.03389944136142731,
            -0.011562327854335308,
            0.030668888241052628,
            0.023670518770813942,
            0.03588959202170372,
            -0.025066683068871498,
            0.012421623803675175,
            0.02939891815185547,
            0.011167027056217194,
            -0.019074155017733574,
            0.0342099629342556,
            -0.034413453191518784,
            -0.014046589843928814,
            0.07948201149702072,
            0.01712687872350216,
            -0.020920976996421814,
            0.04845990613102913,
            0.0008547050529159606,
            -0.0005478935781866312,
            0.008838740177452564,
            0.029273301362991333,
            -0.05626692250370979,
            -0.003477914724498987,
            -0.03994936868548393,
            -0.02022079937160015,
            0.02051484026014805,
            0.005682115908712149,
            0.012629734352231026,
            -0.02906065061688423,
            -0.05005043372511864,
            -0.006959741469472647,
            -0.0039786724373698235,
            -0.02421971783041954,
            -0.007774004712700844,
            -0.004118661861866713,
            0.011867825873196125,
            0.030216757208108902,
            -0.006722598802298307,
            -0.025138264521956444,
            -0.02004397287964821,
            0.02928030863404274,
            -0.015783853828907013,
            0.023062895983457565,
            0.04047975316643715,
            -0.007198448292911053,
            0.00390009768307209,
            -0.027627458795905113,
            -0.011127687059342861,
            0.002734741196036339,
            0.0067471605725586414,
            -0.017022157087922096,
            -0.026388322934508324,
            -0.01645965501666069,
            -0.03322121873497963,
            -0.04099182039499283,
            0.009312312118709087,
            0.03344309702515602,
            0.016366194933652878,
            -0.0407852828502655,
            -0.017318978905677795,
            0.0010112730087712407,
            0.006463523954153061,
            -0.009813209064304829,
            -0.03730959817767143,
            -0.0016650011530146003,
            -0.007916668429970741,
            -0.005711839534342289,
            0.02749045565724373,
            -0.024671005085110664,
            0.0015961243771016598,
            -0.032399747520685196,
            -0.024851404130458832,
            -0.02847784198820591,
            0.0013308061752468348,
            -0.02669009007513523,
            -0.012136229313910007,
            -0.0014570950297638774,
            0.03820209950208664,
            -0.033091768622398376,
            -0.010763795115053654,
            -0.020996425300836563,
            0.01901080273091793,
            0.011566982604563236,
            0.008069472387433052,
            0.04166484996676445,
            0.007722227834165096,
            -0.015439994633197784,
            0.017466966062784195,
            -0.013404065743088722,
            -0.0028289540205150843,
            0.00018294417532160878,
            0.02283562906086445,
            -0.02602793648838997,
            0.015089116990566254,
            0.004778705071657896,
            -0.033826448023319244,
            -0.014668774791061878,
            -0.03916972875595093,
            0.014249264262616634,
            0.019591189920902252,
            -0.0032079173251986504,
            -0.006710733287036419,
            0.034114547073841095,
            -0.01232613530009985,
            0.0033217554446309805,
            -0.007546273060142994,
            -0.0030032743234187365,
            -0.029416080564260483,
            -0.02407349832355976,
            0.008563855662941933,
            0.006658218801021576,
            0.008436196483671665,
            -0.0066602835431694984,
            -0.02673032507300377,
            0.007883287034928799,
            0.02796992100775242,
            0.015517921186983585,
            -0.020867053419351578,
            -0.02194085903465748,
            -0.021920377388596535,
            -0.03373878076672554,
            -0.01669416017830372,
            0.01177089661359787,
            0.027268756181001663,
            -0.020368032157421112,
            0.04376165196299553,
            0.019133668392896652,
            0.01714729517698288,
            -0.028900913894176483,
            -0.008584725670516491,
            0.025243736803531647,
            -0.03493649140000343,
            -0.02429233491420746,
            -0.018616074696183205,
            -0.0033490280620753765,
            0.0032158701214939356,
            0.021943235769867897,
            -0.021871203556656837,
            -0.01688455045223236,
            0.011805842630565166
        ],
        [
            0.02431824803352356,
            -0.003764902474358678,
            0.0073217544704675674,
            0.001917527406476438,
            0.01280124206095934,
            0.04227425530552864,
            0.026500217616558075,
            -0.01302507147192955,
            0.02920626290142536,
            0.008681368082761765,
            0.0035204198211431503,
            -0.02910969778895378,
            0.013670284301042557,
            -0.00028754505910910666,
            -0.03728121891617775,
            -0.023082176223397255,
            0.014246231876313686,
            0.034659840166568756,
            0.02078394591808319,
            -0.008653497323393822,
            0.02732553891837597,
            0.02465142123401165,
            0.03830922394990921,
            -0.005490847397595644,
            0.001738381222821772,
            -0.0009714851621538401,
            0.031683746725320816,
            0.033611636608839035,
            -0.04254467785358429,
            0.03553789108991623,
            0.030305925756692886,
            0.03363249450922012,
            0.024168889969587326,
            -0.009536157362163067,
            0.023280052468180656,
            0.026416057720780373,
            -0.00852157361805439,
            0.014021008275449276,
            0.015155529603362083,
            0.028190867975354195,
            -0.00022498858743347228,
            0.00820851605385542,
            0.005606725811958313,
            -0.029647285118699074,
            -0.05146399885416031,
            0.011306985281407833,
            0.01504549104720354,
            -0.02386770397424698,
            0.024009589105844498,
            -0.05020258203148842,
            0.003152234712615609,
            0.0024405550211668015,
            0.00718212453648448,
            -0.028182094916701317,
            0.006392664276063442,
            -0.006201521493494511,
            0.015837043523788452,
            0.007026275619864464,
            -0.007386405952274799,
            0.017401784658432007,
            -0.03885606676340103,
            -0.009852668270468712,
            -0.014630254358053207,
            -0.002550179837271571,
            0.0028292634524405003,
            0.024858789518475533,
            -0.008359808474779129,
            -0.015230201184749603,
            0.03831766918301582,
            -0.008228759281337261,
            -0.03220347687602043,
            -0.011441146954894066,
            0.02824057638645172,
            0.01956569030880928,
            0.01038663275539875,
            -0.014639878645539284,
            0.024242328479886055,
            0.017977213487029076,
            0.031349748373031616,
            -0.004431809764355421,
            -0.03431566059589386,
            -0.0318676121532917,
            0.027299264445900917,
            -0.02011234313249588,
            0.025348100811243057,
            -0.004957872908562422,
            -0.031729258596897125,
            0.0007646025042049587,
            -0.015635883435606956,
            0.010745992884039879,
            -0.02873893454670906,
            -0.019411718472838402,
            -0.001435710466466844,
            -0.015827693045139313,
            -0.02458529733121395,
            0.00957297720015049,
            -0.011955132707953453,
            0.0350809209048748,
            -0.011195440776646137,
            0.029680220410227776,
            0.004153190180659294,
            0.0055826036259531975,
            0.017799466848373413,
            0.008603882044553757,
            -0.03223545104265213,
            0.020564986392855644,
            -0.025720708072185516,
            -0.027769554406404495,
            -0.004580003675073385,
            -0.02918902039527893,
            -0.00580983143299818,
            -0.03381980583071709,
            0.013057611882686615,
            -0.0272380281239748,
            0.007177184801548719,
            0.025502316653728485,
            -0.03046329878270626,
            0.02871222421526909,
            0.0020416267216205597,
            0.02531912550330162,
            0.009549260139465332,
            -0.018100321292877197,
            -0.036948706954717636,
            -0.0017498089000582695,
            -0.005012689623981714,
            -0.005863148253411055,
            0.025709426030516624,
            0.021473204717040062,
            -0.017088783904910088,
            0.016170885413885117,
            -0.02011977881193161,
            -0.0002671899273991585,
            -0.0025567011907696724,
            -0.013037921860814095,
            0.035912297666072845,
            -0.034833114594221115,
            -0.018168602138757706,
            0.005827117245644331,
            0.027557697147130966,
            0.03368024528026581,
            0.02693016082048416,
            -0.01141792070120573,
            0.015119086019694805,
            0.0024974343832582235,
            -0.02101029083132744,
            -0.017348458990454674,
            0.021846957504749298,
            -0.03812813386321068,
            -0.02946477383375168,
            0.034493979066610336,
            0.028210463002324104,
            0.012655584141612053,
            -0.0008153532980941236,
            0.0054993703961372375,
            -0.0014279272872954607,
            -0.006347242742776871,
            -0.0029728810768574476,
            0.032112617045640945,
            0.01905781775712967,
            -0.050667911767959595,
            0.036916572600603104,
            -0.0046626063995063305,
            0.011579861864447594,
            -0.0059537459164857864,
            -0.016498897224664688,
            -0.04301270842552185,
            0.005005083046853542,
            0.0634208545088768,
            0.05528487265110016,
            -0.0032244944013655186,
            0.012512440793216228,
            -0.004147108178585768,
            0.05746874585747719,
            0.03044363297522068,
            0.002789316466078162,
            -0.013200119137763977,
            0.03300973400473595,
            0.014458460733294487,
            -0.01673865132033825,
            -0.02181345224380493,
            -0.0014504094142466784,
            0.014344642870128155,
            -0.012543017975986004,
            -0.029479051008820534,
            -0.011685757897794247,
            0.00966581143438816,
            -0.0064306678250432014,
            -0.010502279736101627,
            0.018673930317163467,
            -0.009935020469129086,
            -0.0017916960641741753,
            0.0101559953764081,
            0.06154424697160721,
            0.0009080980089493096,
            0.004767586011439562,
            0.015152337960898876,
            -0.018190456554293633,
            0.0040618726052343845,
            0.002875091042369604,
            -0.03018038533627987,
            -0.01200863067060709,
            0.013591987080872059,
            0.03725814074277878,
            -0.019839977845549583,
            0.0026561934500932693,
            0.0004073355521541089,
            0.023132802918553352,
            -0.004233831074088812,
            -0.02240278571844101,
            0.014955584891140461,
            0.029674051329493523,
            0.0008547883480787277,
            0.0439719632267952,
            0.0037367490585893393,
            -0.018322886899113655,
            -0.0003349956532474607,
            -0.024886485189199448,
            0.024038633331656456,
            0.018211206421256065,
            -0.021425964310765266,
            0.01924336515367031,
            -0.0022244739811867476,
            0.025072848424315453,
            0.029575610533356667,
            0.028640558943152428,
            -0.027039894834160805,
            -0.00539151206612587,
            0.004201687406748533,
            0.01893707364797592,
            0.004751307889819145,
            -0.017716092988848686,
            0.030520722270011902,
            0.006312744691967964,
            0.018661217764019966,
            -0.038890279829502106,
            0.014348548837006092,
            0.0036298816557973623,
            0.024845151230692863,
            -0.007516120560467243,
            -0.00223165494389832,
            0.013007720932364464,
            -0.026681674644351006,
            -0.027414340525865555,
            0.0075803170911967754,
            0.036819133907556534,
            0.007212583441287279,
            0.006257453467696905,
            0.0066683036275208,
            -0.013811275362968445,
            -0.0018856729147955775,
            0.014716226607561111,
            0.020791146904230118,
            0.04024394974112511,
            0.01883758045732975,
            -0.031226886436343193,
            -0.03061394952237606,
            0.01749265193939209,
            0.029718996956944466,
            0.009571877308189869,
            -0.04206260293722153,
            -0.032672103494405746,
            -0.010320636443793774,
            0.03545878827571869,
            -0.017256468534469604,
            0.006839503534138203,
            0.00478035444393754,
            0.009036929346621037,
            0.012646207585930824,
            0.01433138269931078,
            0.0076407454907894135,
            -0.0008887953008525074,
            0.024289825931191444,
            0.014203427359461784,
            0.008679164573550224,
            0.0298091359436512,
            -0.003068304620683193,
            0.011027575470507145,
            0.03490545600652695,
            0.010957176797091961,
            0.023772070184350014,
            -0.01142108254134655,
            -0.0366685651242733,
            -0.01605279929935932,
            -0.015327815897762775,
            0.008686844259500504,
            0.026361485943198204,
            0.013676568865776062,
            -0.006376173812896013,
            0.00423689978197217,
            -0.004679438658058643,
            -0.007972445338964462,
            -0.04730480536818504,
            -0.002854689257219434,
            0.0059118689969182014,
            -0.014459486119449139,
            -0.026337813585996628,
            -0.005741707514971495,
            -0.025333501398563385,
            -0.017490362748503685,
            -0.02261717990040779,
            0.029798557981848717,
            0.01580304652452469,
            0.01685480959713459,
            -0.01697908341884613,
            0.022008266299962997,
            0.010780622251331806,
            0.0031757887918502092,
            0.004005724098533392,
            0.012256449088454247,
            -0.02401364967226982,
            -0.06893345713615417,
            0.014979165978729725,
            -0.02968382090330124,
            0.003426619339734316,
            0.012020781636238098,
            0.0007709784549660981,
            -0.0188983716070652,
            -0.033250030130147934,
            -0.025356587022542953,
            0.028501274064183235,
            -0.021874215453863144,
            -0.0012448563938960433,
            -0.0024583302438259125,
            0.025661135092377663,
            0.025308780372142792,
            0.007536142133176327,
            -0.018618905916810036,
            0.015324567444622517,
            -0.01313017588108778,
            0.015882059931755066,
            -0.019264142960309982,
            -0.01708788238465786,
            -0.045467719435691833,
            -0.008005380630493164,
            -0.03924378380179405,
            -6.644739187322557e-05,
            -0.02977615036070347,
            -0.014247581362724304,
            -0.010067927651107311,
            -0.013684786856174469,
            -0.029200809076428413,
            0.0016520792851224542,
            -0.005070979241281748,
            -0.02134600654244423,
            -0.01646948605775833,
            0.03540194779634476,
            -0.04206046834588051,
            0.0014289090177044272,
            0.028640560805797577,
            0.03166646137833595,
            -0.04272841662168503,
            -0.006794421002268791,
            0.02152017317712307,
            0.014837276190519333,
            -0.0007859496399760246,
            -0.01193601731210947,
            -0.02408698759973049,
            0.019016101956367493,
            -0.0191557165235281,
            -0.014185224659740925,
            0.026332831010222435,
            0.006277767010033131,
            -0.006363925524055958,
            0.02423071675002575,
            0.03540584444999695,
            0.020408378913998604,
            0.007198214530944824,
            0.005124919582158327,
            0.0024558112490922213,
            -0.0004956059856340289,
            0.021277036517858505,
            -0.0020305600482970476,
            0.00548055674880743,
            0.0006620943895541131,
            -0.009406163357198238,
            -0.019254878163337708,
            -0.019100969657301903,
            0.021951191127300262,
            0.049107443541288376,
            -0.011208024807274342,
            0.041086722165346146,
            -0.004777413792908192,
            0.023282941430807114,
            0.02337418682873249,
            0.015195660293102264,
            -0.012103002518415451,
            -0.02945294976234436,
            0.006481912452727556,
            -0.02545386366546154,
            0.04268631711602211,
            -0.024425577372312546,
            -0.010590719059109688,
            -0.028239397332072258,
            0.009897200390696526,
            -0.03684306889772415,
            0.019010156393051147,
            -0.014097919687628746,
            -0.014488950371742249,
            -0.00855674035847187,
            -0.014850107952952385,
            -0.0271136611700058,
            -0.012632268480956554,
            -0.0029304074123501778,
            0.030520424246788025,
            0.011973702348768711,
            -0.01605873741209507,
            0.02800131030380726,
            -0.0042812032625079155,
            -0.0008039817330427468,
            -0.0087131941691041,
            0.02049233950674534,
            -0.015265743248164654,
            0.046000849455595016,
            0.001612673164345324,
            0.034289270639419556,
            -0.000965480285231024,
            0.00530375586822629,
            0.009323830716311932,
            0.026851370930671692,
            -0.021541066467761993,
            -0.019788578152656555,
            0.030029643326997757,
            0.013539819046854973,
            -0.015265759080648422,
            0.011660434305667877,
            0.004368467256426811,
            -0.028913896530866623,
            0.019123373553156853,
            0.02124151587486267,
            0.0009148591198027134,
            0.0036441588308662176,
            0.0271286703646183,
            -0.028224529698491096,
            0.016263054683804512,
            0.0209992416203022,
            0.01893502101302147,
            0.027987489476799965,
            0.0075774830766022205,
            -0.026544958353042603,
            -0.0028827558271586895,
            -0.020971085876226425,
            -0.008384104818105698,
            -0.002995316404849291,
            0.008436155505478382,
            0.018218936398625374,
            0.006534668151289225,
            0.0034826325718313456,
            0.07210937887430191,
            -0.011590383015573025,
            0.0018053348176181316,
            0.008172458969056606,
            0.021085334941744804,
            -0.031763773411512375,
            0.02945823222398758,
            -0.03163553401827812,
            -0.024059610441327095,
            0.0009672784362919629,
            0.013708092272281647,
            0.009310023859143257,
            0.0033121739979833364,
            0.016611862927675247,
            0.035022005438804626,
            0.017865480855107307,
            0.02493184804916382,
            -0.0025161432567983866,
            0.011856191791594028,
            0.03856626898050308,
            0.039089128375053406,
            -0.0217050202190876,
            -0.003225972643122077,
            -0.007175153121352196,
            -0.02974545583128929,
            -0.025762073695659637,
            -0.032545723021030426,
            0.02519475296139717,
            -0.04224313050508499,
            0.007590445224195719,
            0.022194642573595047,
            0.02885446324944496,
            0.03685874491930008,
            -0.017872795462608337,
            0.019440630450844765,
            0.0187480840831995,
            -0.014186245389282703,
            -0.010968866758048534,
            -0.024329563602805138,
            0.025735732167959213,
            0.0012336604995653033,
            -0.08378264307975769,
            0.0333428755402565,
            -0.0002811380836647004,
            -0.0004994200426153839,
            -0.020256197080016136,
            -0.015786921605467796,
            0.03295820578932762,
            -0.02972027100622654,
            -0.02278597094118595,
            -0.01086941547691822,
            0.013695568777620792,
            0.01937292143702507,
            0.025278974324464798,
            -0.02449529618024826,
            0.01882942020893097,
            -0.0015141963958740234,
            -0.016682539135217667,
            0.010341577231884003,
            0.004042504355311394,
            -0.019241444766521454,
            0.01891813986003399,
            0.008081319741904736,
            0.012956587597727776,
            0.0061766887083649635,
            -0.02614179253578186,
            0.0047109536826610565,
            0.012728764675557613,
            0.007049159612506628,
            0.009160253219306469,
            0.0015382458223029971,
            -0.012564858421683311,
            -0.0036248560063540936,
            0.0010892460122704506,
            -0.029818935319781303,
            -0.028295522555708885,
            0.015021231956779957,
            0.02931957133114338,
            -0.006829218007624149,
            0.04035139083862305,
            0.032405391335487366,
            -0.008027471601963043,
            -0.008764822967350483,
            0.02083110809326172,
            0.037790365517139435,
            0.006334315054118633,
            0.03040177933871746,
            0.0008785096579231322,
            -0.01770884357392788,
            -0.00340300053358078,
            -0.011013356037437916,
            -0.014671934768557549,
            0.022448930889368057,
            0.01287094596773386,
            -0.011853668838739395,
            0.050578270107507706,
            -0.01503979042172432,
            0.015413644723594189,
            -0.02725606970489025,
            0.005594111979007721,
            -0.019862202927470207,
            0.023788170889019966,
            0.0032162254210561514,
            0.019278593361377716,
            0.01753264293074608,
            -0.006572907790541649,
            0.012909354642033577,
            0.021334365010261536,
            0.001636345754377544,
            0.02276233397424221,
            -0.016245603561401367,
            0.031428076326847076,
            0.01643853634595871,
            0.012965764850378036,
            0.020868360996246338,
            0.002641598228365183,
            0.00015309927402995527,
            0.03802742063999176,
            0.014383727684617043,
            0.0031317737884819508,
            0.03396329656243324,
            -0.008030975237488747,
            0.033830348402261734,
            0.005443056114017963,
            0.014162453822791576,
            -0.005577334202826023,
            0.01102381944656372,
            0.020526116713881493,
            0.007480978034436703,
            0.03647558018565178,
            -0.002745355013757944,
            -0.04157230630517006,
            0.023032857105135918,
            0.007306297775357962,
            -0.003516213037073612,
            -0.03906892240047455,
            0.013410406187176704,
            0.03370208293199539,
            0.025556501001119614,
            -0.002454580506309867,
            -0.014263611286878586,
            0.013285602442920208,
            0.030825037509202957,
            -0.0027783866971731186,
            -0.04742123931646347,
            -0.017625359818339348,
            -0.027123870328068733,
            -0.005140454042702913,
            0.013813326135277748,
            -0.02664859965443611,
            -0.007112239953130484,
            0.018638767302036285,
            -0.006429531145840883,
            -0.020574819296598434,
            -0.014837577939033508,
            -0.014072833582758904,
            0.03634236752986908,
            0.005776015110313892,
            0.004460766911506653,
            -0.02527925930917263,
            0.005657061468809843,
            0.01137912180274725,
            -0.01555944886058569,
            0.015124525874853134,
            -0.0031035065185278654,
            -0.0017045986605808139,
            -0.0154122244566679,
            -0.023404119536280632,
            0.027348585426807404,
            -0.021827107295393944,
            0.013235842809081078,
            0.027741603553295135,
            0.009094231761991978,
            -0.029152261093258858,
            0.004169493447989225,
            0.02831863984465599,
            -0.01721552386879921,
            0.01715248078107834,
            -0.008104407228529453,
            -0.014513986185193062,
            0.01351919025182724,
            -0.027303030714392662,
            -0.011751885525882244,
            -0.00843274500221014,
            -0.0310216024518013,
            0.020725730806589127,
            0.027021251618862152,
            0.026657572016119957,
            -0.02840588241815567,
            0.01255883276462555,
            -0.011803876608610153,
            -0.012583750300109386,
            -0.03262068331241608,
            0.011004146188497543,
            0.033098701387643814,
            0.04188092052936554,
            0.014494730159640312,
            -0.008022607304155827,
            0.009063838049769402,
            -0.06581898778676987,
            0.0008345143287442625,
            0.03284977376461029,
            0.013098236173391342,
            0.003683963092043996,
            0.02237711101770401,
            -0.022681381553411484,
            -0.02504706382751465,
            -0.030975118279457092,
            -0.0054274448193609715,
            0.024975454434752464,
            -0.006558137014508247,
            -0.017791984602808952,
            0.028663652017712593,
            -0.041317399591207504,
            0.0006888947100378573,
            -0.02367393858730793,
            0.010778245516121387,
            0.03097931481897831,
            0.01569940708577633,
            -0.006728483363986015,
            0.009077045135200024,
            -0.016110099852085114,
            -0.02472606487572193,
            -0.0021412058267742395,
            0.02614867128431797,
            -0.03225819021463394,
            -0.0013428253587335348,
            0.011019422672688961,
            -0.01968429982662201,
            7.184337300714105e-05,
            -0.026244163513183594,
            0.006534882355481386,
            -0.013469448313117027,
            -0.02791457623243332,
            0.026623832061886787,
            0.0015049549983814359,
            -0.02983901835978031,
            0.030309703201055527,
            0.02111317403614521,
            0.04636687412858009,
            -0.048036810010671616,
            -0.018153030425310135,
            -0.036191970109939575,
            0.04794818162918091,
            0.01771778240799904,
            0.0001581003307364881,
            0.009069793857634068,
            0.02561257779598236,
            -0.0008873066399246454,
            0.021661387756466866,
            0.016786886379122734,
            0.0135479886084795,
            -0.01722267083823681,
            0.004381836391985416,
            -0.018025178462266922,
            0.0033855794463306665,
            -0.0054257274605333805,
            -0.014858095906674862,
            -0.013149048201739788,
            0.022454291582107544,
            -0.015256337821483612,
            -0.0041107661090791225,
            0.022433001548051834,
            -0.020642558112740517,
            0.029314633458852768,
            -0.00017355586169287562,
            -0.00017945037689059973,
            0.017985345795750618,
            -0.017088523134589195,
            -0.08018658310174942,
            0.0017576923128217459,
            0.013587853871285915,
            0.00965116173028946,
            -0.02597542479634285,
            0.027842065319418907,
            -0.011028026230633259,
            -0.004250361118465662,
            -0.02326197549700737,
            -0.02215808443725109,
            0.004317868035286665,
            -0.025957968086004257,
            0.006082909181714058,
            -0.005533124320209026,
            0.0036101466976106167,
            -0.009128781966865063,
            -0.020481688901782036,
            0.0186417568475008,
            0.006010991055518389,
            -0.024180661886930466,
            -0.008027122355997562,
            -0.007734803948551416,
            -0.020268186926841736,
            -0.02276187390089035,
            -0.015439126640558243,
            -0.016682997345924377,
            -0.003615780733525753,
            -0.0038133133202791214,
            0.005722853355109692,
            0.011743712238967419,
            -0.018107911571860313,
            0.020851003006100655,
            -0.01391078531742096,
            0.0036465912126004696,
            0.011632026173174381,
            0.00872410461306572,
            0.005855103489011526,
            0.019419770687818527,
            -0.019332339987158775,
            -0.028208430856466293,
            0.01966155506670475,
            -0.009066256694495678,
            0.012802149169147015,
            -0.01409622747451067,
            -0.015654752030968666,
            0.03262783959507942,
            -0.009534521028399467,
            -0.009745453484356403,
            -0.002001509303227067,
            0.011828182265162468,
            0.024996690452098846,
            -0.015466644428670406,
            -0.03494611755013466,
            0.014169769361615181,
            0.015506311319768429,
            -0.04660448059439659,
            -0.02745204232633114,
            0.004018939565867186,
            -0.019311439245939255,
            0.0387674942612648,
            0.00041697704000398517,
            0.0557689294219017,
            -0.0016612834297120571,
            -0.023159079253673553,
            0.01573093794286251,
            -0.004233776591718197,
            0.023792054504156113,
            -0.03166818618774414,
            -0.024231942370533943,
            0.005454385653138161,
            -0.032125066965818405,
            0.029877226799726486,
            0.0011804215610027313,
            -0.027114978060126305,
            0.004670226946473122,
            -0.01820051297545433,
            -0.025372063741087914,
            -0.04362356662750244,
            0.01746906340122223,
            -0.004169084131717682,
            0.013121590949594975,
            -0.017857680097222328,
            0.010949920862913132,
            -0.028760014101862907,
            -0.014747352339327335,
            -0.00016855979629326612,
            0.01939936727285385,
            -0.02793266996741295,
            0.014850500039756298,
            -0.0028820380102843046,
            -0.018360473215579987,
            0.021727999672293663,
            -0.024755526334047318,
            0.00825209729373455,
            -0.02039143815636635,
            -0.04371875151991844,
            0.01739131473004818,
            0.0029125779401510954,
            -0.022798174992203712,
            -0.013882429338991642,
            -0.012192738242447376,
            -0.029049528762698174,
            0.03090369887650013,
            -0.03375941142439842,
            -0.027935035526752472,
            -0.019778648391366005,
            -0.009594266302883625,
            0.009919952601194382,
            0.029029875993728638,
            -0.009925734251737595,
            -0.03033866174519062,
            -0.0070852357894182205,
            -0.030791301280260086,
            0.007301586214452982,
            -0.0050979601219296455,
            0.02228330448269844,
            -0.006462451536208391,
            -0.003273540874943137,
            -0.035062480717897415,
            -0.028951654210686684,
            -0.018519993871450424,
            -0.009384223259985447,
            -0.011465669609606266,
            0.006433446425944567,
            -0.005404939409345388,
            0.006741059012711048,
            -0.014591733925044537,
            -0.008468253538012505,
            -0.005005398765206337,
            -0.03238879516720772,
            -0.012949330732226372,
            0.04648382216691971,
            -0.00650604534894228,
            0.001433870173059404,
            -0.013950061984360218,
            -0.002422155812382698,
            0.021888602524995804,
            -0.023087425157427788,
            0.02228143811225891,
            0.023763112723827362,
            0.013022666797041893,
            -0.022376803681254387,
            0.02185249701142311,
            0.02810768596827984,
            0.01985185593366623,
            0.00884332973510027,
            0.033977627754211426,
            0.012359344400465488,
            0.055205054581165314,
            -0.015819979831576347,
            0.007205794099718332,
            -0.0031775604002177715,
            -0.007672728504985571,
            0.024123815819621086,
            -0.04338156059384346,
            -0.03225040063261986,
            0.029442960396409035,
            -0.010917186737060547,
            -0.005857111886143684,
            -0.021952101960778236,
            -0.00454098591580987,
            0.04464615136384964,
            -0.013504978269338608,
            0.0015265336260199547,
            -0.03037872537970543,
            0.012660045176744461,
            -0.0038998043164610863,
            -0.02718127705156803,
            0.020078957080841064,
            -0.0006072504329495132,
            0.003439247375354171,
            0.011709480546414852,
            -0.01308759581297636,
            -0.015294213779270649,
            -0.0013254117220640182,
            0.020308783277869225,
            -0.0053081875666975975,
            -0.04009642079472542,
            0.04301745817065239,
            0.014286133460700512,
            0.022180233150720596,
            -0.041418638080358505,
            -0.022880323231220245,
            0.012264990247786045,
            0.04473799839615822,
            -0.01954825222492218,
            -0.02336243726313114,
            0.019305884838104248,
            0.011909686028957367,
            -0.02280568517744541,
            -0.017296364530920982,
            -0.005591532681137323,
            -0.009427383542060852,
            0.005746607668697834,
            0.013164149597287178,
            -0.0004198132664896548,
            -0.0030488520860671997,
            0.005155365914106369,
            -0.055054664611816406,
            -0.02888646349310875,
            -0.016712838783860207,
            -0.01984034851193428,
            0.027370305731892586,
            0.005461660213768482,
            0.0008742542704567313,
            0.008389385417103767,
            -0.001353478874079883,
            -0.05229588598012924,
            -0.022996822372078896,
            0.036154549568891525,
            -0.01144933607429266,
            0.002166842110455036,
            0.005614150315523148,
            0.0013716768007725477,
            -0.02593742497265339,
            -0.004818293731659651,
            0.00033154862467199564,
            -0.011989466845989227,
            -0.008572078309953213,
            -0.027758175507187843,
            -0.007112008053809404,
            0.025486350059509277,
            0.017600178718566895,
            0.016289668157696724,
            -0.0204387865960598,
            0.00953480415046215,
            0.01341329701244831,
            -0.003527720458805561,
            0.020884567871689796,
            -0.029428277164697647,
            -0.01755877211689949,
            -0.014544847421348095,
            -0.00936565175652504,
            0.018072791397571564,
            -0.039391450583934784,
            0.024847855791449547,
            -0.007676187437027693,
            0.031253084540367126,
            -0.0046463897451758385,
            -0.024403752759099007,
            0.018036844208836555,
            -0.026499632745981216,
            -0.034543100744485855,
            0.0007875131559558213,
            0.010023116134107113,
            -0.025295572355389595,
            0.0037922337651252747,
            -0.023293698206543922,
            -0.0020253353286534548,
            0.005776489153504372,
            0.01874704286456108,
            -0.012532980181276798,
            -0.049035072326660156,
            -0.025155751034617424,
            0.027010535821318626,
            0.012596553191542625,
            -0.007609720807522535,
            0.00928776990622282,
            -0.01312116626650095,
            -0.002213388215750456,
            -0.027895871549844742,
            0.008506087586283684,
            0.009072598069906235,
            0.023105371743440628,
            0.011063258163630962,
            0.0010239972034469247,
            0.0194309800863266,
            0.007408744189888239,
            -0.02749679982662201,
            0.03429841250181198,
            -0.027317920699715614,
            0.033197056502103806,
            0.0004610320902429521,
            0.0034694301430135965,
            -0.014698033221065998,
            0.014222241006791592,
            0.0340038537979126,
            0.00886642187833786,
            0.02215711958706379,
            0.03909319266676903,
            0.00733535410836339,
            -0.019465984776616096,
            -0.004525967873632908,
            0.01760544255375862,
            0.024606186896562576,
            0.002258528955280781,
            0.033103033900260925,
            0.009101873263716698,
            0.004766213241964579,
            0.02687135525047779,
            -0.019315820187330246,
            -0.009159175679087639,
            0.02110966481268406,
            0.032364554703235626,
            0.02015559934079647,
            -0.02456972561776638,
            -0.00966264121234417,
            0.0010047998512163758,
            0.04174550995230675,
            0.026164840906858444,
            0.02124926447868347,
            -0.004684609826654196,
            -0.04238863289356232,
            0.029137128964066505,
            0.022871365770697594,
            -0.04371190816164017,
            0.03800760582089424,
            -0.02089344523847103
        ],
        [
            0.007898758165538311,
            -0.023426465690135956,
            0.013835239224135876,
            0.009175833314657211,
            -0.012579396367073059,
            0.013794089667499065,
            -0.036644041538238525,
            -0.03791407123208046,
            0.005074535962194204,
            -0.028837691992521286,
            -0.04176269471645355,
            0.011480359360575676,
            0.04781727492809296,
            -0.027142660692334175,
            0.010811288841068745,
            -0.04473419114947319,
            -0.02917836420238018,
            -0.028486108407378197,
            -0.008601127192378044,
            0.0026255894917994738,
            -0.043551988899707794,
            -0.004540187306702137,
            -0.01903967559337616,
            0.014947139658033848,
            0.024178562685847282,
            -0.020856577903032303,
            -0.052552931010723114,
            0.018772874027490616,
            0.01976824924349785,
            0.00074181699892506,
            0.03917524963617325,
            -0.013717493042349815,
            -0.01588980108499527,
            -0.014552057720720768,
            0.03709627687931061,
            0.035462141036987305,
            -0.03775804862380028,
            0.020570263266563416,
            -0.02830374240875244,
            0.03313072398304939,
            -0.010391141287982464,
            0.013309121131896973,
            -0.012213974259793758,
            -0.010619966313242912,
            -0.011978356167674065,
            -0.028983308002352715,
            0.03132544457912445,
            -0.02096882462501526,
            -0.035163331776857376,
            0.009548339061439037,
            -0.01684357412159443,
            0.025253169238567352,
            0.036632321774959564,
            -0.008075094781816006,
            -0.0012302586110308766,
            0.0011523265857249498,
            -0.006035415455698967,
            0.007710881531238556,
            -0.01889108493924141,
            0.03033665008842945,
            0.013976351357996464,
            -0.013729103840887547,
            0.026834558695554733,
            0.009610660374164581,
            0.009049013257026672,
            -0.0033035853412002325,
            0.04493698850274086,
            0.0004892784054391086,
            -0.0010259377304464579,
            0.007230300456285477,
            0.00789759773761034,
            0.025170905515551567,
            -0.005927282851189375,
            -0.0036510482896119356,
            0.01650388166308403,
            0.013571470975875854,
            0.003365397686138749,
            0.00213055987842381,
            -0.04030828922986984,
            0.011172555387020111,
            0.004161202814429998,
            0.003722619963809848,
            0.02724984660744667,
            0.02111154980957508,
            0.02128474786877632,
            -0.024204624816775322,
            0.005668455734848976,
            -0.03411151096224785,
            -0.00680507579818368,
            0.006841248366981745,
            0.006798019632697105,
            -0.02474256418645382,
            0.0023913246113806963,
            0.0661267414689064,
            -0.008799216710031033,
            -0.01934429630637169,
            0.01917753368616104,
            0.001675501698628068,
            -0.04817378893494606,
            0.013737007044255733,
            -0.021258408203721046,
            0.011055925861001015,
            -0.022995833307504654,
            -0.010109415277838707,
            0.019099170342087746,
            0.004074577707797289,
            -0.020973755046725273,
            0.013113251887261868,
            -0.020882131531834602,
            -0.015015387907624245,
            0.012330318801105022,
            -0.05688637122511864,
            -0.01566222496330738,
            0.03320985287427902,
            -0.03205472230911255,
            0.007049499079585075,
            -0.024036429822444916,
            -0.02274715155363083,
            0.025481052696704865,
            -0.015760984271764755,
            0.02738509513437748,
            -0.00037714000791311264,
            -0.04332829639315605,
            0.02999395877122879,
            -0.01914692297577858,
            0.016694191843271255,
            0.00020575612143147737,
            0.007168110925704241,
            -0.02363857626914978,
            0.012803826481103897,
            0.027106506749987602,
            0.016290128231048584,
            -0.03920088708400726,
            -0.034977447241544724,
            -0.01963132992386818,
            0.02983972616493702,
            0.008466089144349098,
            -0.03446635976433754,
            -0.03659546375274658,
            -0.014742461033165455,
            -0.003313525812700391,
            0.030246663838624954,
            -0.0198915284126997,
            0.03854638338088989,
            -0.02898581512272358,
            -0.008020967245101929,
            2.5878667656797916e-05,
            0.00882020778954029,
            0.010548186488449574,
            -0.007859770208597183,
            -0.0007614873466081917,
            0.04727013781666756,
            0.017713651061058044,
            0.0007245717570185661,
            -0.001136920996941626,
            0.009216023609042168,
            -0.011070910841226578,
            -0.003993486054241657,
            0.03588421270251274,
            -0.03179984912276268,
            -0.022339530289173126,
            -0.005528351292014122,
            -0.008957191370427608,
            0.018046194687485695,
            0.0006163765792734921,
            -0.010423927567899227,
            0.02820350043475628,
            -0.031929705291986465,
            0.014411712065339088,
            -0.012131143361330032,
            -0.010941345244646072,
            0.006186885293573141,
            0.014559977687895298,
            -0.013909821398556232,
            -0.016631651669740677,
            -0.015012501738965511,
            -0.02334948629140854,
            -0.025723209604620934,
            0.03398793563246727,
            0.021653950214385986,
            0.01005469635128975,
            0.029964037239551544,
            -0.027541257441043854,
            -0.02138471230864525,
            -0.0011515916557982564,
            0.008866501040756702,
            -0.05957549065351486,
            0.02262437902390957,
            0.016000906005501747,
            -0.017215589061379433,
            0.03310425207018852,
            -0.0394214428961277,
            -0.02849770523607731,
            -0.01223102305084467,
            -0.03084758296608925,
            0.01674111932516098,
            0.00868348777294159,
            0.02628171630203724,
            -0.00545116513967514,
            -0.0009582173661328852,
            -0.03755864500999451,
            0.008339355699717999,
            0.01272831205278635,
            0.01287336740642786,
            0.015686726197600365,
            0.010989639908075333,
            0.002459457144141197,
            -0.024853510782122612,
            -0.044697105884552,
            -0.014230499044060707,
            -0.014808890409767628,
            -0.011219493113458157,
            0.03794457018375397,
            0.019157428294420242,
            0.0013370933011174202,
            -0.003498683450743556,
            -0.012918415479362011,
            0.015330242924392223,
            0.027728160843253136,
            -0.018786514177918434,
            0.011725471355021,
            0.005622322205454111,
            0.04188252612948418,
            0.03354072570800781,
            0.004389896523207426,
            -0.039857473224401474,
            0.03254973143339157,
            -0.03671637922525406,
            0.016746576875448227,
            0.018162770196795464,
            -0.040842775255441666,
            -0.013586568646132946,
            -0.021239059045910835,
            -0.026083221659064293,
            -0.010822770185768604,
            -0.008016886189579964,
            -0.046104684472084045,
            0.0314849354326725,
            0.021404800936579704,
            -0.0013185422867536545,
            -0.02569795772433281,
            -0.005184100940823555,
            0.026040831580758095,
            -0.003986465744674206,
            0.045289844274520874,
            0.0038174737710505724,
            -0.021660909056663513,
            -0.02847922220826149,
            -0.024280177429318428,
            -0.01082092896103859,
            0.03234671801328659,
            0.03024367056787014,
            0.003272244008257985,
            0.00898975133895874,
            0.0037752145435661077,
            0.002851915080100298,
            0.0292418971657753,
            -0.00397992180660367,
            -0.019706502556800842,
            0.006492755375802517,
            -0.0065759471617639065,
            0.030164452269673347,
            0.022301403805613518,
            -0.008003727532923222,
            -0.025402158498764038,
            0.017823610454797745,
            -0.03392026200890541,
            -0.01917884312570095,
            0.009826140478253365,
            -0.004461350850760937,
            0.02478998340666294,
            -0.018145542591810226,
            0.030379729345440865,
            0.01860881596803665,
            0.0038897048216313124,
            0.03217047080397606,
            0.02986670471727848,
            -0.0013064797967672348,
            0.01648307964205742,
            -0.0035750670358538628,
            0.018598094582557678,
            -0.017525838688015938,
            0.01267758384346962,
            0.007926356047391891,
            0.005666250362992287,
            -0.008177285082638264,
            0.013735179789364338,
            0.006588620133697987,
            0.026795735582709312,
            0.01807689107954502,
            0.008754778653383255,
            0.007616149727255106,
            -0.028802059590816498,
            -0.01452755555510521,
            0.01949630118906498,
            0.009316825307905674,
            0.022001584991812706,
            0.016757309436798096,
            0.027633732184767723,
            -0.018600214272737503,
            -0.008580855093896389,
            0.016119180247187614,
            0.01572427712380886,
            -0.008815419860184193,
            -7.373451080638915e-05,
            0.058611415326595306,
            0.010470950044691563,
            -0.0153553681448102,
            0.03264526650309563,
            0.00283814943395555,
            0.02617213875055313,
            0.010430392809212208,
            0.03253684192895889,
            -0.034874800592660904,
            -0.015917431563138962,
            -0.019485076889395714,
            0.012590472586452961,
            -0.024952512234449387,
            -0.0027484993916004896,
            -0.006491679698228836,
            0.0010853993007913232,
            -0.009720046073198318,
            0.012912213802337646,
            -0.004493324086070061,
            -0.0365314781665802,
            -0.03445148468017578,
            -0.010766752064228058,
            -0.010838811285793781,
            -0.014289884828031063,
            0.011230340227484703,
            -0.0034981968346983194,
            0.002795375417917967,
            0.037481945008039474,
            0.02654651179909706,
            -0.00238567846827209,
            -0.007204663939774036,
            0.012504592537879944,
            0.017022933810949326,
            0.001757240854203701,
            -0.012175191193819046,
            0.012445393018424511,
            -0.022982997819781303,
            -0.031398240476846695,
            0.02219727262854576,
            0.009776056744158268,
            -0.0581958070397377,
            0.010570785962045193,
            0.0027896540705114603,
            -0.029739178717136383,
            -0.022516973316669464,
            -0.005500819068402052,
            0.007710987702012062,
            0.025007274001836777,
            0.00440958933904767,
            0.0028633377514779568,
            -0.021737420931458473,
            -0.02653653547167778,
            0.027493251487612724,
            -0.014227831736207008,
            -0.0037859869189560413,
            -0.007457625586539507,
            0.0034217077773064375,
            -0.03727491572499275,
            -0.03222707286477089,
            0.018615884706377983,
            -0.030772309750318527,
            -0.0007598477532155812,
            -0.01826559565961361,
            -0.009584144689142704,
            -0.019582686945796013,
            0.0017957133240997791,
            -0.003532537491992116,
            -0.010842283256351948,
            -0.016640163958072662,
            -0.03186121582984924,
            -0.008912750519812107,
            0.007599958684295416,
            -0.02719876356422901,
            -0.00650388328358531,
            0.043245259672403336,
            0.025033704936504364,
            0.022374659776687622,
            0.013051699846982956,
            -0.006670237518846989,
            -0.06400308758020401,
            0.017293306067585945,
            -0.01934899017214775,
            0.0012553660199046135,
            0.03860407695174217,
            -0.018698470667004585,
            0.02739887312054634,
            0.000664354010950774,
            0.023892665281891823,
            -0.02334311604499817,
            -0.05881008133292198,
            0.04299488291144371,
            0.003765694797039032,
            -3.060434028157033e-05,
            0.0024274163879454136,
            0.005144508555531502,
            -0.009396650828421116,
            -0.030534198507666588,
            -0.009771306999027729,
            0.012343619018793106,
            0.02177118882536888,
            -0.0009041648008860648,
            -0.010223723016679287,
            0.016674574464559555,
            0.012616950087249279,
            0.005204758141189814,
            0.026838969439268112,
            -0.03029806539416313,
            0.01653815060853958,
            0.028538016602396965,
            -0.010223178192973137,
            -0.03912852331995964,
            -0.008114850148558617,
            0.0011165314354002476,
            0.0040249302983284,
            -0.025203296914696693,
            0.02869592420756817,
            0.026499193161725998,
            0.045264456421136856,
            0.022580040618777275,
            -0.006313975900411606,
            -0.02489270083606243,
            -0.012452253140509129,
            0.027021139860153198,
            0.009545872919261456,
            0.024010371416807175,
            -0.011687331832945347,
            -0.00521843833848834,
            -0.036544449627399445,
            -0.00017338033649139106,
            0.01668250747025013,
            0.021367765963077545,
            -0.007681917864829302,
            0.013837364502251148,
            -0.01804523542523384,
            0.031028293073177338,
            -0.008352000266313553,
            0.026277367025613785,
            -0.020321166142821312,
            0.018729455769062042,
            0.005543048493564129,
            -0.03451773524284363,
            -0.02758496254682541,
            0.006926740054041147,
            0.014266898855566978,
            -0.042305152863264084,
            -0.02165835350751877,
            0.004530707839876413,
            -0.021425655111670494,
            0.024378981441259384,
            0.04996584728360176,
            0.028341589495539665,
            0.01362471655011177,
            -0.04820600524544716,
            0.00779151264578104,
            -0.0084720179438591,
            0.013455873355269432,
            0.01994527131319046,
            0.0016638152301311493,
            0.042256902903318405,
            -0.016534455120563507,
            0.01388277392834425,
            -0.012159448117017746,
            0.0493369922041893,
            -0.02096683718264103,
            -0.0342920683324337,
            -0.0007396185537800193,
            0.018466811627149582,
            0.040456537157297134,
            0.002098571741953492,
            0.02563929185271263,
            0.015959007665514946,
            0.02009284496307373,
            -0.004730198532342911,
            -0.005209577735513449,
            0.018503306433558464,
            -0.0009758123778738081,
            -0.03621703013777733,
            0.004328759387135506,
            0.028058698400855064,
            0.01330313365906477,
            -0.05795992910861969,
            -0.011174667626619339,
            -0.027610598132014275,
            -0.06860373914241791,
            0.024798842146992683,
            -0.01687171310186386,
            -0.023759013041853905,
            0.017598550766706467,
            -0.008187894709408283,
            0.006286799442023039,
            -0.0070834034122526646,
            0.01874714158475399,
            0.006231734063476324,
            -0.014747819863259792,
            -0.017759645357728004,
            0.02813463658094406,
            0.030924465507268906,
            -0.007684905081987381,
            -0.04319962486624718,
            -0.011996686458587646,
            0.0220650527626276,
            -0.007174977567046881,
            0.015527394600212574,
            -0.03420785814523697,
            -0.008476962335407734,
            0.026298098266124725,
            -0.007979858666658401,
            0.014433170668780804,
            0.026684898883104324,
            0.005896377842873335,
            0.005938660353422165,
            0.01807270757853985,
            -0.02093350701034069,
            0.0008174511604011059,
            -0.000468547863420099,
            0.02619904838502407,
            0.0046235048212111,
            -0.022454673424363136,
            -0.015243525616824627,
            0.007324250880628824,
            0.0018465326866135001,
            0.003110635792836547,
            0.018957966938614845,
            0.0184138435870409,
            -0.010409298352897167,
            -0.017052441835403442,
            0.0038489405997097492,
            -0.00718455296009779,
            0.00250001042149961,
            0.02759518474340439,
            0.01893647201359272,
            -0.028446651995182037,
            -0.030743297189474106,
            -0.030471226200461388,
            -0.005505852401256561,
            -0.010625854134559631,
            0.005984768737107515,
            0.0045340945944190025,
            -0.002724159276112914,
            -0.025672459974884987,
            0.039787087589502335,
            -0.01509142853319645,
            0.012962881475687027,
            -0.006247241050004959,
            -0.03244053199887276,
            0.02479579858481884,
            0.012443717569112778,
            0.02318604849278927,
            -0.015016511082649231,
            0.00534240435808897,
            0.0309651717543602,
            -0.006649097427725792,
            -0.005177185405045748,
            0.004473178181797266,
            0.026430385187268257,
            -0.014146760106086731,
            -0.00552770122885704,
            -0.02310550957918167,
            -0.021928982809185982,
            0.005503161810338497,
            0.018050529062747955,
            0.01367261540144682,
            0.00607316056266427,
            0.02073197439312935,
            0.02068566530942917,
            0.021897586062550545,
            0.025125354528427124,
            0.02741507813334465,
            -0.009649722836911678,
            0.006752922665327787,
            0.007805124390870333,
            0.006519821472465992,
            0.017918791621923447,
            0.013517618179321289,
            0.01643514819443226,
            -0.023042116314172745,
            0.006699432618916035,
            0.0056488425470888615,
            -0.01837298274040222,
            0.023307273164391518,
            0.0058098589070141315,
            -0.019746581092476845,
            -0.006593729835003614,
            -0.02368617244064808,
            0.04299188032746315,
            0.03313580900430679,
            0.0025009356904774904,
            0.02190830372273922,
            -0.03469022363424301,
            0.006508674938231707,
            0.04195401072502136,
            0.03422783315181732,
            -0.032663390040397644,
            -0.0019419303862378001,
            -0.01853318139910698,
            -0.01968594640493393,
            0.0014249735977500677,
            0.00021500574075616896,
            0.024171976372599602,
            -0.021053630858659744,
            0.0032453511375933886,
            -0.010308882221579552,
            0.020235693082213402,
            0.01113899052143097,
            -0.03673151880502701,
            0.02075047977268696,
            0.012213814072310925,
            -0.033702947199344635,
            0.03191504254937172,
            -0.05206561088562012,
            -0.03234221786260605,
            -0.010789109393954277,
            -0.009114141575992107,
            -0.019709842279553413,
            -0.047457125037908554,
            0.019067509099841118,
            -0.018253948539495468,
            -0.01778067648410797,
            0.006243975833058357,
            0.036174993962049484,
            -0.03439066559076309,
            -0.01767144724726677,
            -0.0007125696283765137,
            -0.046014271676540375,
            -0.01754763163626194,
            -0.014100580476224422,
            -0.011503144167363644,
            -0.007751121651381254,
            0.004686005879193544,
            0.032110005617141724,
            -0.008725492283701897,
            0.010723593644797802,
            0.014904413372278214,
            0.0023961770348250866,
            -0.019159220159053802,
            -0.015394249930977821,
            -0.022098546847701073,
            -0.0020327584352344275,
            0.014406593516469002,
            -0.028977176174521446,
            -0.015668213367462158,
            -0.012988612987101078,
            -0.013856332749128342,
            0.024141239002346992,
            0.032288674265146255,
            -0.013762402348220348,
            -0.005024604499340057,
            0.025852521881461143,
            -0.007209940813481808,
            0.008226546458899975,
            0.014659486711025238,
            -0.010641221888363361,
            0.03741883486509323,
            0.013684358447790146,
            -0.012247544713318348,
            0.023571481928229332,
            0.0189853273332119,
            0.02249188907444477,
            -0.012857560068368912,
            -0.013797215186059475,
            -0.03234446793794632,
            -0.010135971009731293,
            0.012685204856097698,
            -0.028049690648913383,
            -0.020525282248854637,
            -0.016382072120904922,
            -0.01486598327755928,
            -0.01621604524552822,
            0.03170175105333328,
            0.022338032722473145,
            -0.02489311434328556,
            -0.004787820857018232,
            0.015374974347651005,
            -0.010536891408264637,
            -0.012049626559019089,
            -0.01892683655023575,
            0.0012569953687489033,
            -0.014881334267556667,
            0.020133845508098602,
            0.00985142681747675,
            0.02039262279868126,
            0.006292826496064663,
            -0.0062443967908620834,
            0.00969446636736393,
            0.01750241033732891,
            0.05263131484389305,
            0.026630021631717682,
            0.004613692406564951,
            -0.007672454230487347,
            0.023896854370832443,
            -0.02415587566792965,
            0.01589546725153923,
            -0.01370325032621622,
            -0.0057359724305570126,
            0.005542296916246414,
            0.024542199447751045,
            -0.04699466750025749,
            0.008582892827689648,
            0.0012987111695110798,
            0.0061144703067839146,
            0.01855929009616375,
            0.007962926290929317,
            -0.02888665348291397,
            0.0013313433155417442,
            0.022499339655041695,
            -0.01618444174528122,
            -0.030621157959103584,
            0.035866957157850266,
            0.015631912276148796,
            0.01936141587793827,
            0.002490060171112418,
            -0.04359174147248268,
            -0.014347480610013008,
            0.013041612692177296,
            0.0073762922547757626,
            -0.004261044319719076,
            0.010732726193964481,
            -0.019739098846912384,
            -0.021498901769518852,
            -0.01704307086765766,
            0.02671744115650654,
            -0.0019012568518519402,
            -0.01145035307854414,
            -0.015312573872506618,
            -0.015552442520856857,
            0.01408675778657198,
            -0.006811399944126606,
            0.010525882244110107,
            0.02602699212729931,
            0.01819945126771927,
            -0.022678237408399582,
            0.012197880074381828,
            0.005671256687492132,
            0.030411573126912117,
            -0.011750428006052971,
            0.015172725543379784,
            -0.044834863394498825,
            -0.006510062143206596,
            0.009796489030122757,
            0.004263657610863447,
            0.030217012390494347,
            0.011534128338098526,
            0.007226988673210144,
            -0.010128873400390148,
            0.029888741672039032,
            -0.009060711599886417,
            0.0009790608892217278,
            0.03174138441681862,
            -0.06054260581731796,
            0.02494736760854721,
            0.0022311722859740257,
            0.006236501969397068,
            0.010809995234012604,
            -0.013031789101660252,
            0.011488360352814198,
            -0.02723253332078457,
            -0.027255330234766006,
            -0.022088242694735527,
            -0.0017645363695919514,
            0.019647834822535515,
            7.50673862057738e-05,
            0.007063213735818863,
            0.02026783674955368,
            -0.0015909923240542412,
            -0.02019873633980751,
            0.04548376798629761,
            -0.023127786815166473,
            0.027066582813858986,
            -0.012156520038843155,
            -0.020290786400437355,
            0.010391801595687866,
            -0.044022951275110245,
            -0.05950941890478134,
            0.029527228325605392,
            0.03592284396290779,
            -0.006337944883853197,
            0.002538460772484541,
            0.030868535861372948,
            -0.028914151713252068,
            0.007018010597676039,
            -0.0326799638569355,
            0.032127413898706436,
            0.026937542483210564,
            -0.045110028237104416,
            0.0012308545410633087,
            -0.03467941656708717,
            -0.023991988971829414,
            0.008143735118210316,
            -0.000556844926904887,
            -0.015105320140719414,
            -0.017112791538238525,
            0.02627117931842804,
            0.0059639448300004005,
            -0.010788416489958763,
            -0.025252778083086014,
            -0.006609243340790272,
            -0.026065731421113014,
            -0.043251194059848785,
            0.003735355334356427,
            -0.05692619830369949,
            0.021688586100935936,
            -0.0259266160428524,
            -0.029704345390200615,
            -0.03179928660392761,
            0.01771821640431881,
            0.03515646979212761,
            -0.028546402230858803,
            0.013673693872988224,
            -0.025317125022411346,
            0.010916076600551605,
            0.017231276258826256,
            -0.010398347862064838,
            -0.0071323406882584095,
            0.007379080634564161,
            0.04237163066864014,
            0.01956089958548546,
            0.0009645546087995172,
            -0.007759476080536842,
            -0.028849022462964058,
            -0.01265402790158987,
            0.0379190593957901,
            0.021261557936668396,
            0.023985400795936584,
            0.007529412396252155,
            -0.010285935364663601,
            0.011201273649930954,
            -0.00026062066899612546,
            -0.01737806759774685,
            0.016260439530014992,
            0.002965986728668213,
            -0.0035300797317177057,
            -0.018363559618592262,
            0.013301205821335316,
            0.0010839867172762752,
            0.05056425556540489,
            0.008729425258934498,
            -0.033748697489500046,
            -0.009169770404696465,
            -0.02544257417321205,
            -0.019551390781998634,
            -0.00022271352645475417,
            0.006585527677088976,
            -0.00217251293361187,
            -0.04068661108613014,
            -0.008471743203699589,
            0.030136162415146828,
            -0.03232019767165184,
            0.06934528052806854,
            -0.005034200847148895,
            -0.01370013877749443,
            -0.003626392222940922,
            0.020755944773554802,
            0.04697786271572113,
            -0.009630708955228329,
            0.019132597371935844,
            0.007787729613482952,
            0.019323961809277534,
            0.032440848648548126,
            -0.03835688903927803,
            0.000555021280888468,
            -0.022553374990820885,
            0.005756935570389032,
            -0.02159487083554268,
            0.010236714035272598,
            0.03560035675764084,
            0.00653877342119813,
            0.006243821233510971,
            0.0007828426896594465,
            -0.03354186192154884,
            -0.01245238445699215,
            0.015799306333065033,
            -0.019996225833892822,
            -0.09236954152584076,
            -0.005729749333113432,
            -0.01001050602644682,
            -0.011208799667656422,
            0.04581756517291069,
            -0.00544604379683733,
            0.03623710200190544,
            0.025013644248247147,
            -0.028335658833384514,
            -0.021259859204292297,
            0.0006434114184230566,
            -0.008937477134168148,
            -0.007871835492551327,
            -0.00158143718726933,
            0.013330013491213322,
            0.02902873232960701,
            0.04335407167673111,
            -0.050115916877985,
            0.009709499776363373,
            0.01855495013296604,
            -0.012345140799880028,
            0.007284160237759352,
            -0.03317531198263168,
            0.013141307979822159,
            0.024662822484970093,
            0.0027763789985328913,
            -0.01720840483903885,
            0.027546897530555725,
            -0.06216531991958618,
            -0.010485049337148666,
            0.007626654114574194,
            -0.007585950195789337,
            0.005992173217236996,
            0.005089072044938803,
            0.0024045510217547417,
            0.018819821998476982,
            0.014937288127839565,
            0.027937091886997223,
            0.022744355723261833,
            0.041478633880615234,
            0.0005631445674225688,
            0.0025789286009967327,
            0.023644372820854187,
            -0.03075077384710312,
            0.03868155553936958,
            0.027436668053269386,
            -0.04762132838368416,
            -0.0013170918682590127,
            -0.02467266283929348,
            -0.024066261947155,
            -0.024329407140612602,
            0.025365423411130905,
            -0.01982983946800232,
            0.003933204337954521,
            0.0005264332285150886,
            0.006671659182757139,
            -0.012454647570848465,
            -0.021008199080824852,
            -0.009184610098600388,
            -0.016026394441723824,
            -0.022164074704051018,
            0.0052119349129498005,
            0.007257937453687191,
            -0.0035735811106860638,
            -0.022054165601730347,
            0.002480893861502409,
            -0.0008609542273916304,
            0.01565098762512207,
            0.0286222156137228,
            -0.007645791862159967,
            -0.019506078213453293,
            -0.020825741812586784,
            -0.007652164436876774,
            0.01404490601271391,
            0.00579468160867691,
            0.004780315328389406,
            0.006338572129607201,
            -0.04239627718925476,
            -0.024213984608650208,
            0.009161395020782948,
            -0.02043730579316616,
            -0.010075246915221214,
            0.009857951663434505,
            -0.03365599364042282,
            -0.008527519181370735,
            -0.026649070903658867,
            -0.005773036740720272,
            -0.03806174173951149,
            0.0030439631082117558,
            -0.053463153541088104,
            0.027696259319782257,
            -0.003481735475361347,
            0.0015681310323998332,
            -0.0215374156832695,
            0.018624937161803246,
            0.012383010238409042,
            -0.030204791575670242,
            -0.011553484946489334,
            0.03794709965586662,
            -0.033939287066459656,
            -0.007520940154790878,
            0.019163643941283226,
            0.03398098796606064,
            -0.02677055075764656,
            -0.01274779997766018,
            0.029110940173268318,
            0.004728126805275679,
            -0.03702353313565254,
            -0.005816069431602955,
            -0.03191982954740524,
            0.01999780163168907,
            0.01668681390583515,
            0.002612124662846327,
            -0.02315249852836132,
            -0.004448201972991228,
            -0.006166073493659496,
            -0.0015523405745625496,
            -0.03003709204494953,
            -0.030364105477929115,
            -0.005006999708712101,
            -0.031907837837934494,
            -0.003098218236118555,
            0.001167613547295332,
            0.012815449386835098,
            0.015993986278772354,
            -0.03742305934429169,
            -0.02428496442735195,
            -0.03391433134675026,
            -0.012661438435316086,
            0.028452839702367783,
            0.032254286110401154,
            -0.03387877345085144,
            0.022321533411741257,
            0.019074678421020508,
            0.0366571880877018,
            -0.01351374015212059,
            -0.027262305840849876,
            -0.023523002862930298,
            0.009150521829724312,
            -0.027681821957230568,
            -0.027936482802033424,
            -0.049942534416913986,
            0.0165267251431942,
            0.001709871692582965,
            -0.0038430362474173307,
            -0.039479177445173264,
            0.025770405307412148
        ],
        [
            0.010947342030704021,
            0.029065249487757683,
            0.02068847417831421,
            -0.02525998093187809,
            -0.009232694283127785,
            0.008351136930286884,
            0.033199816942214966,
            0.028025370091199875,
            0.006227544508874416,
            0.018398374319076538,
            0.014581944793462753,
            0.0042181797325611115,
            0.0395626425743103,
            0.002075436757877469,
            0.034791965037584305,
            0.01054860558360815,
            0.0014480955433100462,
            -0.00035864589153788984,
            0.03076537325978279,
            0.02313411608338356,
            0.02333836629986763,
            0.031792052090168,
            -0.007061433512717485,
            0.019436173141002655,
            0.00645931763574481,
            -0.004470380954444408,
            0.005140256602317095,
            -0.04360608384013176,
            -0.0012071332894265652,
            0.008060515858232975,
            -0.010431330651044846,
            -0.007023083046078682,
            -0.01682375557720661,
            -0.032924652099609375,
            -0.016397491097450256,
            0.0037381197325885296,
            -0.028220372274518013,
            0.005142460577189922,
            0.02396489679813385,
            -0.0008047152077779174,
            0.010006788186728954,
            -0.0357358418405056,
            -0.049851592630147934,
            0.008410519920289516,
            0.04026753455400467,
            -0.021559467539191246,
            -0.02740882709622383,
            0.06059303507208824,
            -0.00964047946035862,
            -0.0029493002220988274,
            0.022367816418409348,
            0.031987499445676804,
            0.02029353380203247,
            -0.01238158531486988,
            0.036889512091875076,
            -0.0318414643406868,
            0.033014193177223206,
            -0.020119791850447655,
            0.00427698390558362,
            0.01259296853095293,
            -0.01615067757666111,
            -0.012941225431859493,
            -0.022611163556575775,
            0.01914035715162754,
            -0.014125996269285679,
            -0.0068990024738013744,
            -0.007852272130548954,
            0.0003768283932004124,
            0.0037580537609755993,
            -0.012629436329007149,
            -0.01684563420712948,
            -0.0360831692814827,
            0.02323833480477333,
            0.027887238189578056,
            0.027509016916155815,
            0.01963953860104084,
            0.014474944211542606,
            0.02523796632885933,
            0.0006072866963222623,
            -0.022482773289084435,
            -0.008817012421786785,
            3.371920684003271e-05,
            0.02013462595641613,
            -0.019423702731728554,
            0.022620458155870438,
            -0.018416598439216614,
            0.025571053847670555,
            0.0013732108054682612,
            -0.04235776886343956,
            -0.020024767145514488,
            0.020989589393138885,
            -0.0072436039336025715,
            -0.043764930218458176,
            -0.042229313403367996,
            0.020229337736964226,
            0.010030042380094528,
            0.04372180253267288,
            -0.043808817863464355,
            -0.05019494518637657,
            -0.007837297394871712,
            0.020339716225862503,
            0.03228425234556198,
            -0.00838847178965807,
            -0.01649721898138523,
            -0.003359739203006029,
            -0.029131973162293434,
            -0.03714829310774803,
            0.026848582550883293,
            0.022308504208922386,
            -0.0019210773753002286,
            0.03611350059509277,
            0.021650049835443497,
            -0.034710582345724106,
            -0.02338508516550064,
            0.005874529015272856,
            -0.03121405653655529,
            -0.022604525089263916,
            -0.028293443843722343,
            -0.015722716227173805,
            -0.00991629995405674,
            0.022818155586719513,
            0.02797563746571541,
            -0.022133946418762207,
            0.009253891184926033,
            0.005091539118438959,
            0.02192908525466919,
            0.02734423242509365,
            -0.024664463475346565,
            0.01938238926231861,
            -0.03664611279964447,
            -0.008524001576006413,
            -0.0006429164204746485,
            0.0004042510408908129,
            -0.005722001660615206,
            -0.0014397159684449434,
            0.009403025731444359,
            -0.023462876677513123,
            0.009175497107207775,
            0.0183879267424345,
            0.020091231912374496,
            0.024877669289708138,
            -0.004572829697281122,
            -0.011682040058076382,
            0.023500824347138405,
            -0.02480451762676239,
            -0.017957473173737526,
            0.0011381778167560697,
            -0.038313064724206924,
            0.0066874073818326,
            -0.022856710478663445,
            -0.010066312737762928,
            -0.03823462501168251,
            0.021085789427161217,
            0.043066058307886124,
            -0.0199106577783823,
            0.001189792645163834,
            0.018484244123101234,
            0.02251410484313965,
            0.010482599027454853,
            0.03368217125535011,
            0.03798742964863777,
            -0.011175389401614666,
            -0.024350987747311592,
            0.0026520874816924334,
            0.009461416862905025,
            0.04088594764471054,
            -0.045229438692331314,
            -0.01820271648466587,
            0.0017300221370533109,
            0.018972452729940414,
            0.0020456011407077312,
            0.036139681935310364,
            -0.009395906701683998,
            0.0134878009557724,
            -0.02290147729218006,
            -0.017823604866862297,
            -0.02148592472076416,
            0.017840581014752388,
            0.01023457758128643,
            0.02354205958545208,
            -0.009535565972328186,
            -0.0025964812375605106,
            -0.011226014234125614,
            0.026112085208296776,
            0.01447989884763956,
            -0.006963242311030626,
            0.047582272440195084,
            0.006975078489631414,
            -0.004271672107279301,
            0.007980537600815296,
            0.034739620983600616,
            0.0051748245023190975,
            -0.009615612216293812,
            0.021188529208302498,
            0.004702191799879074,
            0.00019434608111623675,
            0.015229926444590092,
            0.01677982695400715,
            0.01951800286769867,
            -0.0021079694852232933,
            0.028528688475489616,
            -0.010562630370259285,
            -0.01651967130601406,
            0.006845903117209673,
            -0.021016132086515427,
            -0.008081762120127678,
            0.004206587094813585,
            0.012474758550524712,
            -0.023732902482151985,
            0.0016090384451672435,
            -0.026470940560102463,
            0.023323925212025642,
            0.004812365863472223,
            -0.028488393872976303,
            0.004449666943401098,
            -0.01021714136004448,
            -0.0054732440039515495,
            -0.020762572064995766,
            0.007713832892477512,
            0.011528708972036839,
            -0.004259877372533083,
            -0.023121869191527367,
            0.022768160328269005,
            -0.01701788604259491,
            0.0018590182298794389,
            0.028579730540513992,
            0.01697894185781479,
            0.0317794568836689,
            -0.011447147466242313,
            -0.009778081439435482,
            -0.04060850664973259,
            -0.004017881583422422,
            -0.030740665271878242,
            -0.023305458948016167,
            -0.04554923251271248,
            0.024365665391087532,
            0.045257095247507095,
            -0.013610350899398327,
            0.030252747237682343,
            0.04086139798164368,
            0.0003067518409807235,
            0.01171730738133192,
            0.015025760047137737,
            -0.0035723422188311815,
            -0.0009650491992942989,
            -0.0292862206697464,
            0.01140697579830885,
            0.026582473888993263,
            -0.028720537200570107,
            0.011043328791856766,
            0.02919892407953739,
            -0.019224734976887703,
            -0.0133964279666543,
            -0.013908355496823788,
            0.030845563858747482,
            -0.01933007687330246,
            0.011139786802232265,
            0.0088792210444808,
            0.0027866018936038017,
            -0.002661298494786024,
            0.009964194148778915,
            -0.02113880030810833,
            -0.04337893798947334,
            0.028628703206777573,
            0.00597028061747551,
            -0.027197610586881638,
            -0.007616356015205383,
            -0.006529336329549551,
            -0.016740798950195312,
            -0.005515499506145716,
            -0.010424083098769188,
            -0.03950364142656326,
            -0.022007830440998077,
            -0.004638985730707645,
            0.019606051966547966,
            -0.02072405070066452,
            0.04725712165236473,
            0.015760857611894608,
            0.010713733732700348,
            -0.02346407249569893,
            0.02873929589986801,
            -0.028460802510380745,
            -0.0218654815107584,
            0.020466098561882973,
            0.006687010172754526,
            0.020919961854815483,
            0.021259313449263573,
            0.024986039847135544,
            -0.005279640667140484,
            -0.013929657638072968,
            -0.008045315742492676,
            0.019602689892053604,
            -0.004747659899294376,
            0.029160505160689354,
            0.009540229104459286,
            -0.01765715517103672,
            0.0075964126735925674,
            -0.05030515044927597,
            0.016492700204253197,
            -0.030629893764853477,
            -0.027188153937458992,
            0.02403533086180687,
            0.02016429975628853,
            0.02849833481013775,
            -0.022273259237408638,
            -0.03640410676598549,
            0.01020318828523159,
            0.01062094047665596,
            0.008635340258479118,
            0.021867752075195312,
            -0.019929245114326477,
            0.016827577725052834,
            0.015585089102387428,
            -0.01597638428211212,
            -0.032283660024404526,
            0.02406415343284607,
            0.02657497674226761,
            0.008350709453225136,
            -0.0035644390154629946,
            -0.020558567717671394,
            -0.029255034402012825,
            0.03178827092051506,
            0.0015283048851415515,
            -0.03362073749303818,
            -0.004620903637260199,
            0.021750083193182945,
            -0.012975561432540417,
            -0.003735823091119528,
            -0.04405523091554642,
            -0.01569214090704918,
            -0.017844395712018013,
            0.04292772337794304,
            -0.02405569516122341,
            0.0019642722327262163,
            -0.028008393943309784,
            0.0022326307371258736,
            0.02381606586277485,
            0.007423431612551212,
            0.0069326614029705524,
            0.029065683484077454,
            -0.026309462264180183,
            -0.010475436225533485,
            -0.04190114140510559,
            -0.010901521891355515,
            -0.03799072653055191,
            0.01593870297074318,
            0.023043232038617134,
            0.003927022218704224,
            0.003127060830593109,
            0.028332291170954704,
            -0.011595300398766994,
            -0.016298698261380196,
            -0.0057905204594135284,
            -0.004662756342440844,
            -0.007830342277884483,
            0.005865779239684343,
            0.011639030650258064,
            0.02135879173874855,
            -0.009527040645480156,
            0.01548359077423811,
            0.004217357840389013,
            0.00974329560995102,
            -0.05354616045951843,
            0.025904685258865356,
            -0.03556744381785393,
            -0.0225236676633358,
            0.036554738879203796,
            -0.0216378066688776,
            -0.009605864994227886,
            0.022804560139775276,
            -0.036497581750154495,
            0.01885647140443325,
            -0.032280463725328445,
            0.009146223776042461,
            -0.040144190192222595,
            0.02450169436633587,
            -0.010525173507630825,
            -0.017358964309096336,
            -0.013809412717819214,
            -0.015646597370505333,
            -0.06679819524288177,
            -0.015185350552201271,
            0.012954692356288433,
            -0.02504732646048069,
            -0.027352679520845413,
            -0.012911710888147354,
            -0.013503661379218102,
            0.0009534661658108234,
            0.015173533000051975,
            -0.01871132291853428,
            -0.004800441674888134,
            0.02243215963244438,
            -0.019312480464577675,
            -0.026346076279878616,
            0.007987085729837418,
            0.015471896156668663,
            0.021272333338856697,
            -0.017795104533433914,
            -0.003210935276001692,
            -0.012234129942953587,
            0.01432078517973423,
            0.020251590758562088,
            -0.0036889114417135715,
            0.017272524535655975,
            0.012573426589369774,
            -0.03290599584579468,
            0.03193565085530281,
            -0.019059743732213974,
            -0.008715597912669182,
            0.023952828720211983,
            -0.02959754504263401,
            0.02003367990255356,
            0.009260207414627075,
            -0.004405762068927288,
            0.012325635179877281,
            0.0036095513496547937,
            0.029272058978676796,
            -0.00845193862915039,
            0.019137712195515633,
            -0.01809341460466385,
            -0.04471394047141075,
            -0.03454272821545601,
            0.015514595434069633,
            0.0034441149327903986,
            0.010252815671265125,
            -0.0028753948863595724,
            -0.012678798288106918,
            0.02015007473528385,
            0.0320550799369812,
            -0.0024373740889132023,
            0.01162381935864687,
            -0.0018967126961797476,
            -0.027861542999744415,
            0.028424682095646858,
            -0.003603453515097499,
            0.0032553840428590775,
            0.002970749279484153,
            0.005690806545317173,
            0.009887509047985077,
            0.004289162345230579,
            0.010415998287498951,
            -0.02324317768216133,
            -0.010422571562230587,
            -0.02751566283404827,
            -0.008915022015571594,
            0.005917761474847794,
            0.005201796535402536,
            -0.06375265121459961,
            0.032930053770542145,
            0.007125396281480789,
            0.019303029403090477,
            0.0037871194072067738,
            0.00401761569082737,
            0.020195817574858665,
            0.011882586404681206,
            -0.025465743616223335,
            0.020364360883831978,
            0.020409412682056427,
            0.018773160874843597,
            0.01898089237511158,
            -0.008870065212249756,
            0.015076401643455029,
            0.049331944435834885,
            -0.01795193739235401,
            0.03072730079293251,
            -0.022761978209018707,
            0.013812658376991749,
            -0.008915763348340988,
            -0.04235462471842766,
            -0.017410658299922943,
            -0.009833261370658875,
            0.02953531965613365,
            0.00044111275929026306,
            -0.0468297004699707,
            0.00906457006931305,
            0.025743836537003517,
            0.006678922567516565,
            -0.00461228983476758,
            0.012123769149184227,
            0.01790189929306507,
            -0.03675979748368263,
            -0.01698075421154499,
            -0.06974773108959198,
            0.027018124237656593,
            -0.0026179023552685976,
            0.03579875826835632,
            -0.01356332004070282,
            0.0197394210845232,
            -0.08828388154506683,
            -0.015549524687230587,
            -0.05350406840443611,
            0.011415230110287666,
            0.010673847049474716,
            -0.018543029204010963,
            -0.010627458803355694,
            0.014383653178811073,
            -0.022743811830878258,
            -0.0068560075014829636,
            0.017211101949214935,
            -0.015416508540511131,
            0.034194111824035645,
            -0.041787777096033096,
            0.029550960287451744,
            0.019734369590878487,
            -0.005795137956738472,
            -0.027004532516002655,
            -0.032421231269836426,
            0.008972644805908203,
            0.0012351348996162415,
            0.020509030669927597,
            0.021257122978568077,
            -0.022554870694875717,
            -0.015111942775547504,
            -0.015549110248684883,
            -0.005152788944542408,
            0.02154715731739998,
            -0.005832791328430176,
            0.007106953766196966,
            -0.00449491199105978,
            -0.017554467543959618,
            -0.035243768244981766,
            -0.012583842501044273,
            0.014059855602681637,
            0.007642541546374559,
            -0.012606804259121418,
            -0.003752856981009245,
            -0.05131590738892555,
            -0.022653013467788696,
            -0.03594954311847687,
            0.010924055241048336,
            -0.002658297074958682,
            0.0318288579583168,
            -0.005029969848692417,
            -0.00800401158630848,
            -0.007720278110355139,
            -0.01941300556063652,
            -0.022704236209392548,
            0.0002199653536081314,
            0.017261216416954994,
            -0.005798650905489922,
            0.013812307268381119,
            0.020647842437028885,
            0.01576082594692707,
            -0.006983642466366291,
            -0.0251275934278965,
            0.010981778614223003,
            0.0018971875542774796,
            -0.00585429510101676,
            0.021784896031022072,
            -0.011215467937290668,
            0.02281525731086731,
            0.02687869593501091,
            0.007522536441683769,
            -0.01101568341255188,
            -0.028467468917369843,
            0.010658768005669117,
            0.012545685283839703,
            -0.019283434376120567,
            -0.022428927943110466,
            -0.0024466642644256353,
            -0.025961320847272873,
            0.014821572229266167,
            0.01761290244758129,
            0.045935239642858505,
            0.008357633836567402,
            0.0020973419304937124,
            -0.0011033177142962813,
            -0.015904316678643227,
            0.023920368403196335,
            -0.007738207466900349,
            0.0024797411169856787,
            0.00463610514998436,
            0.03417184203863144,
            -0.02543831802904606,
            -0.006257663015276194,
            -0.02410624735057354,
            0.006589173339307308,
            -0.006034977734088898,
            -0.04654286429286003,
            0.003047329606488347,
            0.016570258885622025,
            0.016966765746474266,
            0.0124581353738904,
            0.0007930077845230699,
            0.010055415332317352,
            0.002209489466622472,
            0.013734010048210621,
            -0.016677405685186386,
            -0.02042056992650032,
            0.006251095328480005,
            0.007246819790452719,
            -0.019069451838731766,
            0.000251229212153703,
            0.01734551601111889,
            0.019616615027189255,
            -0.0012927345233038068,
            0.012801116332411766,
            -0.02932371012866497,
            0.037857796996831894,
            -0.04510527476668358,
            0.007879029028117657,
            -0.0010190350003540516,
            -0.013513466343283653,
            0.019524218514561653,
            0.0204425361007452,
            -0.0032572911586612463,
            -0.0245363712310791,
            -0.023750295862555504,
            0.03139926865696907,
            0.0026943450793623924,
            -0.026973647996783257,
            -0.007069023791700602,
            0.009429173544049263,
            -0.008949078619480133,
            0.01270846463739872,
            -0.01204616203904152,
            0.010965111665427685,
            -0.0121116628870368,
            0.010501846671104431,
            -0.011273927986621857,
            -0.04988480731844902,
            0.03563295677304268,
            -0.026291586458683014,
            0.0026889918372035027,
            -0.023954808712005615,
            -0.03030531294643879,
            -0.004377192351967096,
            -0.017487050965428352,
            0.04381873086094856,
            0.008439498953521252,
            -0.07402615249156952,
            -0.01594078168272972,
            0.029907887801527977,
            -0.03344511240720749,
            0.044357430189847946,
            0.021450389176607132,
            0.024979297071695328,
            -0.004240353591740131,
            0.02999919094145298,
            -0.02338067628443241,
            0.037774041295051575,
            0.008377051912248135,
            0.04867127910256386,
            -0.021971967071294785,
            -0.011630224995315075,
            -0.0068617938086390495,
            -0.018330911174416542,
            0.018478237092494965,
            0.028627512976527214,
            0.013916854746639729,
            0.008906868286430836,
            0.014988604933023453,
            -0.0004427912936080247,
            -0.03237687051296234,
            -0.01329465489834547,
            -0.00914517231285572,
            -0.00916304625570774,
            -0.015388073399662971,
            0.030383087694644928,
            -0.022744953632354736,
            -0.006216336507350206,
            -0.0015277513302862644,
            -0.018855977803468704,
            0.01938060112297535,
            0.0039076609537005424,
            0.027302443981170654,
            0.026510991156101227,
            0.02404724806547165,
            0.01627359539270401,
            -0.03229618817567825,
            -0.007795017212629318,
            0.0008511183550581336,
            -0.006590656936168671,
            0.037649136036634445,
            0.023887351155281067,
            0.011311952024698257,
            -0.027256954461336136,
            -0.00021908456983510405,
            0.009048037230968475,
            0.008619338274002075,
            -0.020366564393043518,
            0.04496079683303833,
            0.03435888886451721,
            0.01446487009525299,
            -0.011513727717101574,
            0.01819545589387417,
            -0.0037420280277729034,
            -0.03183085098862648,
            0.02535942569375038,
            -0.007102389354258776,
            -0.0073144459165632725,
            -0.020189838483929634,
            0.02094012312591076,
            -0.022163743153214455,
            0.0015286561101675034,
            0.02440512366592884,
            0.02677294611930847,
            -0.014132386073470116,
            -0.015907492488622665,
            0.0022848364897072315,
            -0.03113626502454281,
            0.016249073669314384,
            -0.013605901040136814,
            -0.023757724091410637,
            -0.014491305686533451,
            0.003989561926573515,
            0.016007080674171448,
            0.017924455925822258,
            -0.033574774861335754,
            -0.02501472271978855,
            0.0035644134040921926,
            -0.01155618205666542,
            0.01555501390248537,
            0.01906716823577881,
            0.0007354008848778903,
            0.004982385318726301,
            0.0065179504454135895,
            -0.01946290209889412,
            0.025016646832227707,
            -0.01264488697052002,
            -0.001854269183240831,
            -0.018982091918587685,
            -0.009866648353636265,
            -0.007345643825829029,
            -0.000419810094172135,
            0.02341289445757866,
            0.008915774524211884,
            0.0003790101909544319,
            0.01217943150550127,
            -0.027138184756040573,
            0.010530754923820496,
            -0.041951410472393036,
            -0.016296276822686195,
            0.0013544075191020966,
            0.00010582988034002483,
            -0.03173179551959038,
            0.004387826193124056,
            -0.0064980448223650455,
            0.029966622591018677,
            -0.011822053231298923,
            -0.006713788490742445,
            0.024185216054320335,
            0.01316564716398716,
            -0.013465152122080326,
            -0.032725218683481216,
            -0.018669025972485542,
            -0.08128245174884796,
            0.028872035443782806,
            0.026386762037873268,
            0.014466800726950169,
            0.014143624342978,
            0.006618862971663475,
            -0.003390183439478278,
            0.016406601294875145,
            -0.01753474585711956,
            0.0158807672560215,
            0.013824178837239742,
            0.037680938839912415,
            0.03368695452809334,
            -0.013962846249341965,
            -0.00024589255917817354,
            0.03395356610417366,
            0.037304386496543884,
            0.02918548323214054,
            0.02795758843421936,
            0.03477028012275696,
            0.016066906973719597,
            0.010342712514102459,
            -0.018676966428756714,
            -0.004608395975083113,
            0.024377895519137383,
            0.003790498711168766,
            -0.022420553490519524,
            -0.08245885372161865,
            -0.012140316888689995,
            0.016335368156433105,
            -0.005458185914903879,
            -0.0004916187026537955,
            0.0044152685441076756,
            0.02148059383034706,
            0.002584157045930624,
            -0.0022318833507597446,
            -0.03759315237402916,
            -0.014953439123928547,
            -0.017946694046258926,
            0.03941754251718521,
            -0.009428435005247593,
            0.02652624249458313,
            -0.018741244450211525,
            0.006462814752012491,
            0.00720701040700078,
            0.024783404543995857,
            0.023604605346918106,
            0.048773281276226044,
            -0.0349651575088501,
            0.010111766867339611,
            0.02598179318010807,
            -0.01495338324457407,
            -0.014054992236196995,
            0.006250004284083843,
            0.04310797154903412,
            0.0017409606371074915,
            0.011122198775410652,
            0.009826300665736198,
            -0.007423199713230133,
            0.026916146278381348,
            -0.007201738655567169,
            -0.02497032657265663,
            -0.004512991290539503,
            0.031866442412137985,
            -0.003424519207328558,
            0.0308203287422657,
            0.011276586912572384,
            -0.020902976393699646,
            -0.018236331641674042,
            0.017468297854065895,
            -0.01570945791900158,
            0.01569465361535549,
            0.015118446201086044,
            -0.015797460451722145,
            -0.029331523925065994,
            0.028263108804821968,
            -0.027048712596297264,
            0.02384142391383648,
            -0.012651474215090275,
            -0.01183885708451271,
            0.032810114324092865,
            0.023226501420140266,
            -0.002403747523203492,
            -0.01968572475016117,
            0.012985458597540855,
            0.015518330968916416,
            0.011803585104644299,
            0.0314796157181263,
            -0.023134728893637657,
            -0.0013613227056339383,
            -0.019476722925901413,
            0.013665890321135521,
            0.01934436708688736,
            -0.06731609255075455,
            -0.010575463995337486,
            -0.004309363197535276,
            -0.01978410594165325,
            -0.00438506156206131,
            0.0015493851387873292,
            0.01391148753464222,
            0.01124200876802206,
            -0.0006633606390096247,
            0.0038274263497442007,
            0.0010503891389817,
            -0.020703023299574852,
            -0.012559625320136547,
            -0.015816854313015938,
            -0.019943173974752426,
            0.037565357983112335,
            -0.0026507689617574215,
            0.01320184487849474,
            -0.006120224017649889,
            0.012002010829746723,
            -0.020130418241024017,
            0.008818043395876884,
            -0.015652265399694443,
            -0.010617445223033428,
            -0.03055589646100998,
            -0.03129015490412712,
            -0.022931165993213654,
            0.014165895991027355,
            0.04700745642185211,
            0.028200576081871986,
            -0.02948370948433876,
            -0.033620767295360565,
            -0.0006802002899348736,
            -0.005645759403705597,
            0.025397691875696182,
            -0.01696721278131008,
            0.03006437048316002,
            -0.030691850930452347,
            0.013627732172608376,
            0.0351930670440197,
            -0.025594275444746017,
            -0.022685209289193153,
            0.015029268339276314,
            0.005381751339882612,
            0.0024453289806842804,
            -0.0268226508051157,
            -0.01406626496464014,
            0.010920776054263115,
            -0.03219473734498024,
            -0.016572266817092896,
            -0.006534533109515905,
            0.016969356685876846,
            -0.02165328711271286,
            -0.03311328589916229,
            0.007687294855713844,
            -0.0004405067302286625,
            -0.06292551010847092,
            0.027951369062066078,
            -0.05925503745675087,
            -0.012818501330912113,
            0.0005362138617783785,
            0.030766841024160385,
            -0.01794467680156231,
            0.0038413810543715954,
            -0.015699325129389763,
            0.04844711720943451,
            -0.02070162631571293,
            0.006732266861945391,
            -0.02762068808078766,
            0.006517822854220867,
            -0.0043162815272808075,
            -0.03197293356060982,
            -7.426012598443776e-05,
            -0.011610941030085087,
            -0.029320217669010162,
            -0.004840080626308918,
            0.020665910094976425,
            -0.03824739530682564,
            0.033960066735744476,
            -0.025749042630195618,
            -0.014249694533646107,
            -0.024662474170327187,
            0.0008512242930009961,
            0.0028195707127451897,
            0.008364991284906864,
            -0.025467971339821815,
            -0.043098293244838715,
            -0.028559541329741478,
            0.015920273959636688,
            -0.011751269921660423,
            0.01571372151374817,
            -0.021044880151748657,
            -0.01237420178949833,
            -0.024569835513830185,
            0.020789217203855515,
            -0.006811907049268484,
            0.006268157623708248,
            -0.024756768718361855,
            -0.003165213391184807,
            -0.005783858709037304,
            -0.001736795762553811,
            -0.03377556800842285,
            -6.648260750807822e-05,
            -0.009719117544591427,
            0.01471966877579689,
            0.02850605547428131,
            0.025583041831851006,
            0.005320975556969643,
            -0.011086996644735336,
            0.01433749869465828,
            0.01677701063454151,
            -0.006766164675354958,
            -0.02443273924291134,
            -0.019909152761101723,
            -0.003858221461996436,
            0.0328877717256546,
            0.033181771636009216,
            0.025114648044109344,
            0.020879928022623062,
            -0.029402093961834908,
            0.041303109377622604,
            0.028628623113036156,
            -0.007175831124186516,
            0.020482655614614487,
            -0.021690290421247482,
            -0.02596791461110115,
            0.00870529655367136,
            -0.03086162731051445,
            0.01664891466498375,
            -0.07724696397781372,
            0.001050163060426712,
            -0.0007277546683326364,
            -0.003712181467562914,
            -0.03331412002444267,
            0.025629691779613495,
            -0.005059521179646254,
            0.02093997225165367,
            -0.00937297660857439,
            -0.014102405868470669,
            -0.019278928637504578,
            0.004559159278869629,
            -0.004481831565499306,
            -0.03597990423440933,
            -0.028049485757946968,
            0.02052847109735012,
            0.0028949491679668427,
            -0.01067613810300827,
            0.033234331756830215,
            -0.03693702071905136,
            0.027861274778842926,
            0.025737620890140533,
            -0.013646528124809265,
            0.00020543381106108427,
            0.005547080188989639,
            0.027169881388545036,
            -0.012215935625135899,
            -0.0160039234906435,
            0.0044762687757611275,
            -0.001678566331975162,
            -0.06278111785650253,
            0.016968876123428345,
            -0.0321580246090889,
            -0.009111575782299042,
            0.009849471971392632,
            -0.003706006333231926,
            -0.03407939895987511,
            0.015874186530709267,
            -0.0036071096546947956,
            -0.029916197061538696,
            -0.01511256955564022,
            0.0027012969367206097,
            -0.005766524467617273,
            0.02066066674888134,
            0.01080331765115261,
            -0.019596874713897705,
            0.05103421211242676,
            -0.033247210085392,
            0.02160090208053589,
            -0.02101946622133255,
            0.03152714669704437,
            -0.01455831341445446,
            0.030130473896861076,
            -0.0314178429543972,
            0.023489873856306076,
            -0.016724465414881706
        ],
        [
            -0.0048569380305707455,
            -0.01178892981261015,
            0.02471223473548889,
            0.0214417465031147,
            -0.01708422601222992,
            0.006258352193981409,
            -0.004106293898075819,
            -0.012904844246804714,
            0.03493887931108475,
            -0.008991345763206482,
            0.0327104777097702,
            -0.04014003276824951,
            0.03095066361129284,
            0.026683857664465904,
            -0.06174125894904137,
            0.0066307527013123035,
            0.01713545061647892,
            -0.004625597503036261,
            0.01416708156466484,
            -0.012825008481740952,
            0.011901856400072575,
            -0.012359533458948135,
            -0.02996566891670227,
            -0.03572377189993858,
            0.0070704189129173756,
            0.01087868120521307,
            -0.025729401037096977,
            0.015380901284515858,
            -0.03637553006410599,
            -0.028605448082089424,
            -0.007629015017300844,
            0.03911067172884941,
            0.005498559679836035,
            -0.001055754255503416,
            0.013738001696765423,
            -0.024686763063073158,
            0.007904140278697014,
            0.01386979129165411,
            0.04725328087806702,
            0.0010002049384638667,
            -0.0010584064293652773,
            0.02757447212934494,
            0.02420940436422825,
            -0.01617734134197235,
            -0.0017960523255169392,
            -0.011544897221028805,
            -0.014178495854139328,
            -0.07667982578277588,
            0.02115359529852867,
            0.013688500039279461,
            -0.025806445628404617,
            -0.022223565727472305,
            -0.007835470139980316,
            0.021684516221284866,
            0.007115621119737625,
            -0.012499605305492878,
            -0.009956960566341877,
            -0.019991299137473106,
            0.0054446556605398655,
            -0.0176544152200222,
            -0.005010323133319616,
            0.018960656598210335,
            0.02779906801879406,
            -0.03116755746304989,
            0.004885109607130289,
            0.014907722361385822,
            0.024288348853588104,
            0.03150557726621628,
            -0.01199729647487402,
            -0.023860521614551544,
            -0.009449547156691551,
            0.015149942599236965,
            0.019698157906532288,
            -0.010574666783213615,
            0.029869379475712776,
            0.02298801764845848,
            -0.03621172159910202,
            -0.032037440687417984,
            -0.028948334977030754,
            -0.01652555540204048,
            -0.012731768190860748,
            -0.025978969410061836,
            0.010771878063678741,
            0.021777017042040825,
            -0.02658652886748314,
            -0.012360219843685627,
            0.027744529768824577,
            0.004474414512515068,
            0.023288482800126076,
            -0.027065400034189224,
            -0.025352975353598595,
            0.02666938677430153,
            0.0008571124053560197,
            0.021129168570041656,
            0.014616217464208603,
            0.029318543151021004,
            -0.015303359366953373,
            -0.03282926604151726,
            0.024239417165517807,
            0.026261305436491966,
            0.004174146801233292,
            -0.037938039749860764,
            0.01387624442577362,
            0.0007239485858008265,
            -0.008027072064578533,
            -0.014244270510971546,
            0.019977137446403503,
            0.0014423365937545896,
            0.07848469913005829,
            -0.009365909732878208,
            -0.029229065403342247,
            -0.051452189683914185,
            0.028652049601078033,
            -0.0021492205560207367,
            -0.038284290581941605,
            0.02641976997256279,
            -0.026142582297325134,
            0.008712954819202423,
            -0.034604836255311966,
            -0.035023562610149384,
            0.0012720812810584903,
            -0.006070652045309544,
            0.001645263284444809,
            0.03263241797685623,
            -0.03658442571759224,
            0.03954537585377693,
            0.0002485446457285434,
            0.00830689538270235,
            -0.0189269557595253,
            0.0026435195468366146,
            0.034722696989774704,
            -0.026552611961960793,
            -0.010149587877094746,
            -0.0007764630718156695,
            0.03153149038553238,
            0.017855912446975708,
            -0.0066355434246361256,
            0.01893429271876812,
            0.025415493175387383,
            -0.004833850543946028,
            -0.007550826296210289,
            -0.005619486793875694,
            -0.019486019387841225,
            0.023104902356863022,
            0.0294451043009758,
            0.047113530337810516,
            -0.02372412569820881,
            0.03703689202666283,
            0.007406692486256361,
            0.008680278435349464,
            -0.027067312970757484,
            0.005343674216419458,
            -0.012336635030806065,
            -0.03138459846377373,
            0.009463943541049957,
            -0.021804040297865868,
            -0.04672110453248024,
            -0.017780108377337456,
            0.0187888965010643,
            -0.01242853607982397,
            0.02015981823205948,
            0.011484582908451557,
            0.03283033147454262,
            -0.013868042267858982,
            0.003436958882957697,
            -0.0171345267444849,
            -0.025075724348425865,
            -0.00959038082510233,
            -0.0068364934995770454,
            0.013336200267076492,
            0.004689742345362902,
            -0.025357887148857117,
            -0.006435243412852287,
            0.014493389055132866,
            -0.013238412328064442,
            0.00477650947868824,
            -0.040545884519815445,
            0.037982430309057236,
            0.004659287165850401,
            -0.0018997357692569494,
            0.005818828009068966,
            0.011238020844757557,
            -0.006584539078176022,
            -0.0016862450866028666,
            -0.006712701637297869,
            -0.007456920109689236,
            -0.02640737220644951,
            0.03707095608115196,
            0.0034688070882111788,
            -0.028545698150992393,
            -0.040151700377464294,
            -0.017071224749088287,
            0.05041222274303436,
            0.005341161508113146,
            0.029923738911747932,
            -0.0119789969176054,
            0.0035515252966433764,
            0.014594980515539646,
            -0.00767050264403224,
            0.0015557107981294394,
            0.01161174476146698,
            0.021492112427949905,
            0.010157193057239056,
            -0.014856331050395966,
            0.004797128029167652,
            -0.019101690500974655,
            -0.0252995602786541,
            -0.04130011424422264,
            -0.006679118610918522,
            -0.01294886413961649,
            0.0029490964952856302,
            -0.03356805443763733,
            0.00686967559158802,
            -0.04257405921816826,
            0.013685395941138268,
            -0.032285481691360474,
            0.006950450595468283,
            0.02783738262951374,
            0.008026456460356712,
            -0.029714221134781837,
            0.003433400997892022,
            0.03568475693464279,
            -0.002548372372984886,
            -0.0039032292552292347,
            -0.008817911148071289,
            -0.020680274814367294,
            -0.01718924753367901,
            -0.00032345278305001557,
            -0.04070625081658363,
            -0.02493821084499359,
            -0.007912220433354378,
            -0.026566823944449425,
            -0.01622212491929531,
            -0.008906147442758083,
            -0.04427275434136391,
            -0.027551760897040367,
            -0.03860299289226532,
            -0.009188544936478138,
            0.025339419022202492,
            -0.004804796539247036,
            0.019903775304555893,
            0.02192501723766327,
            0.011421173810958862,
            0.01478109322488308,
            0.0434204526245594,
            -0.022163815796375275,
            -0.03597234562039375,
            0.03000713512301445,
            -0.022240398451685905,
            -0.008769250474870205,
            0.012127481400966644,
            0.012997826561331749,
            0.03111172281205654,
            -0.011567466892302036,
            0.02360028214752674,
            -0.01599990203976631,
            0.02557183802127838,
            0.006962359882891178,
            0.018948504701256752,
            0.03503742069005966,
            -0.030884964391589165,
            -0.013683672063052654,
            -0.0025203146506100893,
            0.008733121678233147,
            -0.04739765822887421,
            -0.024571137502789497,
            0.00790431909263134,
            0.008017857559025288,
            0.0071890209801495075,
            0.020504964515566826,
            -0.03269469738006592,
            0.016873769462108612,
            0.044735707342624664,
            0.0034357872791588306,
            -0.00885947234928608,
            0.00523269921541214,
            -0.03511674329638481,
            0.020898262038826942,
            0.0038403996732085943,
            -0.011248751543462276,
            -0.014041934162378311,
            -0.028488483279943466,
            0.00470033660531044,
            -0.036839161068201065,
            0.027695950120687485,
            -0.010872123762965202,
            -0.0087030790746212,
            0.030100375413894653,
            0.007633507717400789,
            0.01997930370271206,
            0.015175125561654568,
            0.011067389510571957,
            0.009233850054442883,
            0.023309195414185524,
            0.013422299176454544,
            -0.02684566006064415,
            -0.01835867576301098,
            0.05249901860952377,
            0.01940467581152916,
            0.0012188461842015386,
            -0.009047524072229862,
            -0.019287539646029472,
            0.03144649416208267,
            0.01473950408399105,
            0.0011086028534919024,
            -0.04481003060936928,
            0.0396692119538784,
            -0.035130105912685394,
            -0.01894417405128479,
            0.008700565434992313,
            -0.003701989771798253,
            -0.0002503438445273787,
            -0.022176971659064293,
            0.021144790574908257,
            0.015262485481798649,
            0.0030026903841644526,
            -0.032308705151081085,
            0.014481168240308762,
            0.012082727625966072,
            0.015987709164619446,
            -0.03281767666339874,
            -0.049207936972379684,
            -0.015270655043423176,
            -0.003736231243237853,
            -0.02267473191022873,
            -0.01020215917378664,
            0.022789664566516876,
            -0.002890585456043482,
            -0.020047621801495552,
            -0.007688537240028381,
            0.0213446244597435,
            -0.0402887798845768,
            -0.032905273139476776,
            -0.011512845754623413,
            -0.0033844802528619766,
            0.008019762113690376,
            -0.0033880744595080614,
            -0.039949338883161545,
            0.02796480618417263,
            -0.014758016914129257,
            -0.015022344887256622,
            -0.001518498407676816,
            0.019507119432091713,
            0.023794468492269516,
            0.048252616077661514,
            0.0034928685054183006,
            -0.03518950939178467,
            -0.033721163868904114,
            -0.009836665354669094,
            -0.02351640909910202,
            0.02658289112150669,
            -0.017004860565066338,
            -0.03481648862361908,
            -0.022333519533276558,
            0.01270938292145729,
            -0.04101036861538887,
            0.005857405252754688,
            0.023047324270009995,
            0.00020211699302308261,
            0.01911577582359314,
            0.0015984970377758145,
            0.013257202692329884,
            0.01869628205895424,
            -0.0008199570002034307,
            0.0027317453641444445,
            -0.0284471046179533,
            -0.03673520311713219,
            -0.014186895452439785,
            -0.010362482629716396,
            0.025688042864203453,
            -0.008107248693704605,
            0.018440864980220795,
            -0.014027925208210945,
            0.030769510194659233,
            0.01511294674128294,
            -0.05305960401892662,
            -0.014668207615613937,
            0.027030661702156067,
            -0.029954837635159492,
            -0.0042540994472801685,
            0.02992449700832367,
            -0.007363245356827974,
            0.007441030349582434,
            -0.00037550879642367363,
            0.01104081142693758,
            0.009503891691565514,
            0.03418399393558502,
            0.019279712811112404,
            -0.028284747153520584,
            -0.01195369753986597,
            0.010504464618861675,
            -0.007651431020349264,
            0.02348429337143898,
            0.03183605521917343,
            -0.08576317876577377,
            -0.0036442067939788103,
            -0.02218805067241192,
            0.04470367729663849,
            0.029975511133670807,
            -0.03192955255508423,
            -0.004146718420088291,
            -0.010286443866789341,
            0.011682955548167229,
            -0.01273722667247057,
            -0.007586401887238026,
            0.03790220990777016,
            0.030948830768465996,
            -0.023858675733208656,
            0.02702183462679386,
            -0.006155415438115597,
            -0.036482688039541245,
            0.025409385561943054,
            -0.009912276640534401,
            0.012769775465130806,
            -0.03425147011876106,
            0.021162014454603195,
            0.03541587293148041,
            0.00514990696683526,
            -0.0162986908107996,
            0.0073425667360424995,
            -0.0036827882286161184,
            -0.027110332623124123,
            -0.03139791637659073,
            0.03121182695031166,
            0.027984095737338066,
            -0.03088812530040741,
            0.042872216552495956,
            -0.004515542648732662,
            0.008017206564545631,
            0.031763870269060135,
            -0.0015023262239992619,
            0.017205484211444855,
            -0.001071278820745647,
            -0.05556393787264824,
            0.02882556989789009,
            -0.00669478764757514,
            -0.00345857092179358,
            0.0024825825821608305,
            0.014964552596211433,
            -0.0027877269312739372,
            0.03615206107497215,
            0.021094126626849174,
            0.014264005236327648,
            0.017926611006259918,
            -0.011605647392570972,
            0.03135770931839943,
            0.0030802597757428885,
            0.0407610759139061,
            -0.016931919381022453,
            0.018556321039795876,
            -0.01010632049292326,
            0.007588319014757872,
            -0.010457859374582767,
            0.009007001295685768,
            -0.015978369861841202,
            -0.019927799701690674,
            -0.00415271520614624,
            0.00803897064179182,
            -0.00816142838448286,
            -0.004908753093332052,
            0.013988914899528027,
            0.015228239819407463,
            -0.08928234130144119,
            -0.03418094664812088,
            -0.018353836610913277,
            -0.03925411030650139,
            -0.029802804812788963,
            -0.007908293046057224,
            0.010928300209343433,
            0.019154993817210197,
            0.004733311478048563,
            0.003705093637108803,
            0.023380210623145103,
            0.04318952560424805,
            0.05369984731078148,
            0.0030883662402629852,
            -0.0217935498803854,
            -0.018989715725183487,
            -0.018162047490477562,
            0.0028057261370122433,
            0.007335093338042498,
            -0.03428882360458374,
            0.017272308468818665,
            -0.004964012652635574,
            0.013726385310292244,
            0.015203692018985748,
            0.009898976422846317,
            0.04393363744020462,
            0.06395267695188522,
            -0.026752376928925514,
            0.025417283177375793,
            -0.025579990819096565,
            0.007507127709686756,
            -0.00277573405764997,
            -0.045133840292692184,
            0.017631707713007927,
            -0.031890396028757095,
            0.018993685021996498,
            0.02420559525489807,
            -0.025539975613355637,
            -0.002064858330413699,
            -0.04477838799357414,
            0.01659153588116169,
            -0.03485354036092758,
            0.028556348755955696,
            0.010341518558561802,
            -0.003216720186173916,
            -0.033313050866127014,
            -0.01762353628873825,
            0.018430989235639572,
            -0.0024360641837120056,
            0.00019806525961030275,
            -0.005691469646990299,
            -0.01488981768488884,
            0.020182158797979355,
            0.01891930401325226,
            -0.01273277960717678,
            0.03086433745920658,
            0.020670004189014435,
            0.02269151248037815,
            0.020707160234451294,
            0.024410946294665337,
            -0.02192133478820324,
            0.014574924483895302,
            0.01492240745574236,
            0.009120145812630653,
            0.0492001473903656,
            0.0042991554364562035,
            0.01619916409254074,
            -0.02827114425599575,
            -0.017382895573973656,
            -0.01283964142203331,
            0.0031582231167703867,
            0.0089954175055027,
            0.028650298714637756,
            -0.04760989174246788,
            0.041172824800014496,
            -0.004974992945790291,
            0.006118217948824167,
            0.007434362079948187,
            -0.001215251861140132,
            0.007055597845464945,
            0.003868791041895747,
            0.014749869704246521,
            0.020221520215272903,
            0.0007855778094381094,
            0.007488685194402933,
            0.022873075678944588,
            -0.013851563446223736,
            0.02602064236998558,
            -0.014273463748395443,
            -0.0037396622356027365,
            -0.036210689693689346,
            0.018558179959654808,
            0.013865643180906773,
            0.024603448808193207,
            0.04203033819794655,
            0.012468134984374046,
            -0.02704486809670925,
            -0.0021489367354661226,
            0.00014155407552607358,
            -0.025250950828194618,
            0.017350908368825912,
            -0.021448401734232903,
            0.008011315017938614,
            -0.007255908567458391,
            -0.017058109864592552,
            -0.009577536024153233,
            -0.00020826683612540364,
            -0.018362903967499733,
            0.0023322231136262417,
            0.028330188244581223,
            -0.032847821712493896,
            0.011313404887914658,
            -0.041106466203927994,
            0.03301098197698593,
            -0.0184636190533638,
            0.022221487015485764,
            0.002874652622267604,
            0.025880711153149605,
            -0.007422363385558128,
            0.004289362113922834,
            0.03686827793717384,
            -0.012519714422523975,
            -0.03306204825639725,
            -0.017120802775025368,
            0.019169196486473083,
            -0.0015951424138620496,
            0.0028049449902027845,
            0.026496799662709236,
            0.007432182319462299,
            -0.014073836617171764,
            0.02194669097661972,
            -0.03430323675274849,
            0.03524339944124222,
            -0.0038258845452219248,
            -0.01446177065372467,
            0.01363629661500454,
            0.008700577542185783,
            0.00608868757262826,
            -0.009247718378901482,
            -0.053608860820531845,
            0.0020461410749703646,
            0.013596377335488796,
            -0.03216507285833359,
            -0.016812657937407494,
            0.017005573958158493,
            -0.01274790521711111,
            -0.04487296938896179,
            -0.008914290927350521,
            3.582383942557499e-05,
            0.00966554880142212,
            0.006250966340303421,
            0.03444071486592293,
            0.0019851382821798325,
            0.005784422624856234,
            0.032578397542238235,
            -0.02046281285583973,
            0.032194480299949646,
            -0.06493975222110748,
            0.04392775893211365,
            -0.02769191563129425,
            -0.0010972097516059875,
            0.03178771957755089,
            0.00656004948541522,
            0.008558404631912708,
            0.00861169770359993,
            -0.03818655386567116,
            -0.001419268548488617,
            0.04038570076227188,
            -0.014914787374436855,
            0.02465682476758957,
            -0.019325489178299904,
            0.03399860858917236,
            -0.006402493454515934,
            0.025208409875631332,
            -0.013216467574238777,
            -0.017451945692300797,
            -0.019773364067077637,
            -0.003535208757966757,
            -0.006626888643950224,
            -0.04811765253543854,
            0.010702782310545444,
            0.016396749764680862,
            0.03942582756280899,
            -0.003755404381081462,
            0.028329553082585335,
            -0.011782363057136536,
            -0.028449315577745438,
            0.009892085567116737,
            -0.016590507701039314,
            -0.02335643209517002,
            0.0024097715504467487,
            -0.0015294353943318129,
            -0.01006875466555357,
            -0.014603862538933754,
            0.01307479478418827,
            -0.015363318845629692,
            -0.017175525426864624,
            0.0388687327504158,
            -0.021003924310207367,
            0.015390180982649326,
            0.03448084369301796,
            -0.0004824679053854197,
            0.0413019135594368,
            0.006911291275173426,
            -0.0023816099856048822,
            0.041633348912000656,
            0.008562981151044369,
            0.011836801655590534,
            -0.008977056480944157,
            0.05575517192482948,
            -0.01448108907788992,
            0.06308140605688095,
            0.004933093674480915,
            0.03444080427289009,
            -0.023992953822016716,
            -0.0028847267385572195,
            0.00558325694873929,
            -0.012097629718482494,
            -0.007765655871480703,
            0.007722384762018919,
            0.03280042111873627,
            0.03146740049123764,
            0.011303137056529522,
            0.03076399862766266,
            -0.027193063870072365,
            -0.02709919586777687,
            0.006428191438317299,
            0.03525063395500183,
            -0.006566248368471861,
            0.02712586335837841,
            0.01526219118386507,
            0.007697656285017729,
            -0.006559550762176514,
            0.007583911530673504,
            0.022604234516620636,
            0.03480178490281105,
            0.029383650049567223,
            -0.011835104785859585,
            0.016682814806699753,
            0.023093432188034058,
            -0.021786609664559364,
            -0.01608302630484104,
            0.02471630647778511,
            -0.044286925345659256,
            -0.016157357022166252,
            -0.016035933047533035,
            -0.021670624613761902,
            -0.024942422285676003,
            -0.005685565993189812,
            -0.011204151436686516,
            0.0030939243733882904,
            -0.013299456797540188,
            0.01028401032090187,
            -0.026803024113178253,
            -0.03007045015692711,
            0.0031326967291533947,
            -0.020363252609968185,
            -0.00038982235128059983,
            0.005337883718311787,
            -0.008262469433248043,
            -0.02490171417593956,
            -0.026432042941451073,
            0.029499350115656853,
            -0.015697866678237915,
            -0.0230586975812912,
            0.00580754317343235,
            0.03401251137256622,
            -0.03776096925139427,
            -0.016707295551896095,
            -0.0044565885327756405,
            0.008774067275226116,
            0.011518777348101139,
            -0.014766481705009937,
            0.035082485526800156,
            -0.03636069595813751,
            -0.02059105783700943,
            -0.008595400489866734,
            0.013453159481287003,
            -0.00802916381508112,
            0.014318183995783329,
            -0.026281459257006645,
            0.0066878641955554485,
            0.013837762176990509,
            -0.032619159668684006,
            -0.011940965428948402,
            -0.005170406308025122,
            0.0175956878811121,
            0.016726311296224594,
            0.027323845773935318,
            0.0030921564903110266,
            -0.015108502469956875,
            0.013230443000793457,
            0.02252724952995777,
            0.019678901880979538,
            0.003585671540349722,
            -0.019406521692872047,
            0.006763193290680647,
            0.020111804828047752,
            0.026133893057703972,
            0.021460698917508125,
            0.0003709396405611187,
            0.007631821092218161,
            -0.02345232665538788,
            0.036105018109083176,
            0.015839645639061928,
            0.006895286496728659,
            0.0061074839904904366,
            -0.016477912664413452,
            0.03808650001883507,
            -0.007874472066760063,
            -0.012472029775381088,
            0.03796924650669098,
            0.02326871268451214,
            0.0019202141556888819,
            0.0036308527924120426,
            -0.014638149179518223,
            0.009108763188123703,
            -0.013667223043739796,
            -0.010348591953516006,
            -0.01589357480406761,
            -0.01076066866517067,
            0.010471508838236332,
            -0.0033360118977725506,
            0.007972175255417824,
            0.036106448620557785,
            0.030264100059866905,
            -0.035618796944618225,
            0.01817311719059944,
            0.025773076340556145,
            0.0007267507608048618,
            -0.0016677016392350197,
            0.013919863849878311,
            -0.030650058761239052,
            0.004791887477040291,
            -8.908162271836773e-05,
            -0.011846059933304787,
            -0.013603214174509048,
            0.01545301079750061,
            -0.0027910894714295864,
            -0.011862785555422306,
            -0.0148097462952137,
            0.01471440214663744,
            0.01836678571999073,
            0.024980733171105385,
            0.0020189506467431784,
            -0.006937210448086262,
            -0.020031273365020752,
            -0.02892131730914116,
            0.023509301245212555,
            0.011786368675529957,
            -0.008731162175536156,
            -1.841907578636892e-05,
            0.04584061726927757,
            -0.016835210844874382,
            0.024602318182587624,
            -0.025252342224121094,
            -0.028329862281680107,
            -0.007844568230211735,
            0.026009829714894295,
            0.009541474282741547,
            0.0004444369697012007,
            0.00597058329731226,
            0.0029178510885685682,
            -0.049145352095365524,
            -0.028405027464032173,
            -0.018784256651997566,
            -0.030299585312604904,
            -0.019433518871665,
            -0.008005415089428425,
            0.030170639976859093,
            0.009645048528909683,
            -0.001234778086654842,
            -0.05946289002895355,
            -0.006999807432293892,
            0.0024086195044219494,
            -0.030229082331061363,
            -0.0003111330734100193,
            0.01498280931264162,
            -0.00453680194914341,
            -0.022692030295729637,
            0.030032921582460403,
            0.007604554761201143,
            -0.0008647112408652902,
            -0.015050254762172699,
            -0.008248367346823215,
            0.012667780742049217,
            0.03809405490756035,
            -0.014127339236438274,
            0.011465253308415413,
            -0.02454083226621151,
            -0.029857972636818886,
            0.013949315063655376,
            -0.006273616570979357,
            0.04893902689218521,
            -0.008807365782558918,
            0.0033373564947396517,
            -0.0004914348246529698,
            -0.014123388566076756,
            -0.00047293814714066684,
            -0.030525511130690575,
            0.030344249680638313,
            0.011455664411187172,
            0.036097243428230286,
            -0.008473947644233704,
            0.019129112362861633,
            0.03351527452468872,
            0.01062912866473198,
            0.04921956732869148,
            0.03708646818995476,
            -0.010810787789523602,
            0.008229183964431286,
            -0.01124945655465126,
            -0.0057856421917676926,
            -0.020934706553816795,
            0.005930852610617876,
            -0.0010217343224212527,
            0.018258515745401382,
            0.002947888569906354,
            -0.01076201256364584,
            -0.023809809237718582,
            0.01612619310617447,
            0.010492210276424885,
            0.011546419933438301,
            -0.010469517670571804,
            0.00043504757923074067,
            0.0479426383972168,
            0.03383734077215195,
            0.012998922728002071,
            0.0006929274532012641,
            0.018128491938114166,
            0.007953397929668427,
            -0.0092316260561347,
            -0.05349503830075264,
            0.009169717319309711,
            -0.017663462087512016,
            -0.01869669184088707,
            -0.015913261100649834,
            -0.025187652558088303,
            -0.0593184158205986,
            0.0246319267898798,
            0.017039986327290535,
            -0.0037358184345066547,
            -0.02481037750840187,
            0.025679830461740494,
            0.02377994917333126,
            -0.02990800142288208,
            0.022212596610188484,
            0.038722917437553406,
            -0.0012310189194977283,
            -0.025107579305768013,
            0.016248680651187897,
            -0.01934160478413105,
            0.009340065531432629,
            0.0014884009724482894,
            -0.0005614496767520905,
            -0.004975737538188696,
            0.030620118603110313,
            0.026775741949677467,
            0.02931699901819229,
            0.018951790407299995,
            0.0072296177968382835,
            -0.005030300933867693,
            -0.03057914227247238,
            -0.031355127692222595,
            0.02503862790763378,
            -0.017820976674556732,
            0.027100717648863792,
            0.024049369618296623,
            -0.011702971532940865,
            -0.013561016879975796,
            -0.003370236838236451,
            -0.033381469547748566,
            -0.023235321044921875,
            0.004192600958049297,
            0.03053637221455574,
            0.019682778045535088,
            -0.016893688589334488,
            -0.008681677281856537,
            -0.00310956547036767,
            -0.02815532684326172,
            0.016816597431898117,
            -0.02654634602367878,
            0.008728126995265484,
            -0.01029413565993309,
            0.0037101353518664837,
            -0.008442495949566364,
            -0.007347132079303265,
            -0.03311862424015999,
            -0.002706420375034213,
            0.0009726800490170717,
            0.026612363755702972,
            0.023026321083307266,
            -0.017452308908104897,
            -0.03651571646332741,
            0.015091258101165295,
            0.024603424593806267,
            -0.020794829353690147,
            0.014488373883068562,
            -0.02237866260111332,
            0.0006002468289807439,
            0.0017495041247457266,
            -0.018313361331820488,
            0.011904322542250156,
            0.01026022620499134,
            0.024397531524300575,
            -0.01946515217423439,
            0.008431650698184967,
            -0.003397611202672124,
            -0.033313073217868805,
            0.021198701113462448,
            -0.013245517387986183,
            -0.008801092393696308,
            0.011683030053973198,
            -0.025235310196876526,
            0.03457772731781006,
            0.017494462430477142,
            -0.023515593260526657,
            0.04719911888241768,
            0.04230291023850441,
            0.03013445809483528,
            0.046107735484838486,
            -0.030672768130898476,
            -0.014829488471150398,
            0.02290775440633297,
            -0.007871324196457863,
            0.01462236512452364,
            -0.01991919055581093,
            -0.021517418324947357,
            -0.034966032952070236,
            -0.0135283712297678,
            0.042237211018800735,
            0.00637059798464179,
            0.04600541666150093,
            0.006811054889112711,
            -0.07781991362571716,
            0.006172465160489082,
            0.00960294995456934,
            -0.007225619629025459,
            0.004503097850829363,
            0.01668294332921505,
            -0.04317142814397812,
            0.011366722173988819,
            -0.013816875405609608,
            0.020270101726055145,
            0.026468245312571526,
            0.03122173622250557,
            -0.014098972082138062,
            -0.017791496589779854,
            -0.025383727625012398,
            -0.046146322041749954,
            0.04539176821708679,
            -0.019745590165257454,
            0.0017344442894682288,
            -0.04191172868013382,
            -0.023694099858403206,
            0.035253360867500305,
            -0.023691954091191292,
            0.03190578892827034,
            -0.014346611686050892,
            0.01764167658984661,
            0.012450535781681538,
            -0.00691820215433836
        ],
        [
            0.012406647205352783,
            -0.008382964879274368,
            0.029574453830718994,
            0.017476577311754227,
            -0.009152110666036606,
            -0.02929595485329628,
            -0.0296784657984972,
            -0.015347489155828953,
            0.019972985610365868,
            0.01221474725753069,
            -0.010659859515726566,
            0.01851888746023178,
            -0.012586248107254505,
            0.0007628779858350754,
            0.01599082536995411,
            -0.045513276010751724,
            -0.008048377931118011,
            -0.022351188585162163,
            -0.02111905999481678,
            0.028822647407650948,
            0.008347314782440662,
            0.010918999090790749,
            0.007202707231044769,
            -0.025119245052337646,
            -0.007566496264189482,
            0.0266751516610384,
            -0.016688751056790352,
            -0.030500907450914383,
            0.01541940774768591,
            -0.010430041700601578,
            0.013983089476823807,
            -0.022749146446585655,
            -0.0113207483664155,
            0.03378114849328995,
            0.012977320700883865,
            0.01565832644701004,
            0.02449183166027069,
            0.0003384804876986891,
            -0.024363741278648376,
            -0.0003433729871176183,
            -0.014117542654275894,
            -0.015261492691934109,
            0.01893707737326622,
            0.012557443231344223,
            -0.043603185564279556,
            -0.034070324152708054,
            -0.0037154406309127808,
            0.00023221092124003917,
            0.004443081561475992,
            -0.004174171481281519,
            0.00960460677742958,
            0.00045077744289301336,
            0.0438184030354023,
            -0.022612314671278,
            0.02543949894607067,
            0.0009851133218035102,
            -0.02561168000102043,
            0.00878257304430008,
            0.021052537485957146,
            -0.014033169485628605,
            0.01498835813254118,
            -0.015850242227315903,
            -0.010515178553760052,
            -0.02400048077106476,
            -0.037021152675151825,
            0.020826758816838264,
            0.017573993653059006,
            0.015792062506079674,
            -0.007052700500935316,
            -0.02815495803952217,
            0.01196579821407795,
            -0.028613412752747536,
            -0.024121740832924843,
            0.031163115054368973,
            0.0062178634107112885,
            -0.008765258826315403,
            -0.01723819226026535,
            0.02451137639582157,
            -0.0009381943382322788,
            0.0357629768550396,
            -0.010988451540470123,
            0.024578608572483063,
            0.033923134207725525,
            -0.019241265952587128,
            -0.020067991688847542,
            0.01756511814892292,
            0.010248021222651005,
            -0.03439083322882652,
            0.012737788259983063,
            0.004795968532562256,
            0.01653503254055977,
            -0.019111130386590958,
            0.03305846452713013,
            0.01370579469949007,
            0.01167434360831976,
            0.03600301221013069,
            -0.04448459669947624,
            -0.03202953562140465,
            -0.0044897450134158134,
            -0.015458903275430202,
            -0.03834648057818413,
            -0.006996124982833862,
            -0.024269819259643555,
            -0.019420957192778587,
            0.03301389887928963,
            -0.006847221404314041,
            0.025850774720311165,
            -0.002748985541984439,
            0.014601174741983414,
            -0.0016419459134340286,
            0.02852938510477543,
            -0.013792946003377438,
            -0.037095338106155396,
            -0.011241360567510128,
            -0.00047472675214521587,
            -0.017395202070474625,
            0.0005595775437541306,
            -0.011234560050070286,
            0.033414989709854126,
            0.0031894075218588114,
            -0.0008186561171896756,
            -0.00044041097862645984,
            0.05215783044695854,
            0.030088333413004875,
            -0.005517526064068079,
            0.03072732873260975,
            -0.02850165031850338,
            0.034993357956409454,
            0.001149724586866796,
            -0.008535249158740044,
            0.027985423803329468,
            0.013154618442058563,
            -0.010430831462144852,
            -0.011636116541922092,
            0.017019329592585564,
            0.031777165830135345,
            0.019737079739570618,
            -0.015211067162454128,
            0.002356638666242361,
            -0.014817629009485245,
            -0.010398291051387787,
            0.001560495118610561,
            -0.015592064708471298,
            0.02111922763288021,
            0.01345162931829691,
            -0.035407815128564835,
            0.008389566093683243,
            0.022220993414521217,
            -0.006292852573096752,
            -0.03615595027804375,
            0.006020134314894676,
            -0.050846487283706665,
            0.028299568220973015,
            -0.025068411603569984,
            -0.016769014298915863,
            -0.015164798125624657,
            0.009139763191342354,
            -0.006961481645703316,
            0.0019283799920231104,
            0.042929667979478836,
            0.031896837055683136,
            -0.02426430769264698,
            -0.009218203835189342,
            -0.022105908021330833,
            0.00821873638778925,
            0.02141440100967884,
            -0.014129755087196827,
            0.00785666424781084,
            -0.05522332713007927,
            0.024402745068073273,
            0.0037660992238670588,
            0.03845661133527756,
            -0.0014821714721620083,
            -0.016904739663004875,
            0.0017778828041628003,
            0.025144152343273163,
            -0.011464475654065609,
            -0.0396147221326828,
            -0.03048798441886902,
            0.017386235296726227,
            -0.03006044216454029,
            0.022267645224928856,
            -0.01469067856669426,
            0.003866930492222309,
            0.026929236948490143,
            -0.009831852279603481,
            -0.02482069469988346,
            -0.019514650106430054,
            -0.028318751603364944,
            0.0075701638124883175,
            0.023959185928106308,
            0.002093460178002715,
            0.002241050126031041,
            -0.011493133381009102,
            -0.006117993034422398,
            0.03297136351466179,
            0.020573092624545097,
            0.0008184022735804319,
            -0.035939160734415054,
            0.038471147418022156,
            -0.01090433169156313,
            0.004661949817091227,
            -0.010283313691616058,
            0.02249949984252453,
            -0.006090349983423948,
            0.037296365946531296,
            -0.029125729575753212,
            0.013668140396475792,
            -0.03664056956768036,
            0.0046167559921741486,
            -0.009162764996290207,
            0.008048211224377155,
            -0.013555406592786312,
            -0.03509467467665672,
            0.010000876151025295,
            -0.020796697586774826,
            0.02008349634706974,
            -2.892468728532549e-05,
            -0.01740669459104538,
            -0.006086133420467377,
            0.02147519774734974,
            -0.0029690114315599203,
            0.0249281395226717,
            0.0008543233852833509,
            0.03061859682202339,
            0.0009457959095016122,
            0.002090234775096178,
            -0.003404529532417655,
            -0.0056850784458220005,
            -0.004433142486959696,
            0.021338848397135735,
            -0.00278907036408782,
            0.0005672683473676443,
            0.03317883238196373,
            -0.02224012464284897,
            -0.007762639783322811,
            0.01849748007953167,
            0.0018919777357950807,
            -0.019680557772517204,
            0.012331184931099415,
            -0.02970483899116516,
            0.019934309646487236,
            0.025911036878824234,
            0.006459984462708235,
            -0.0014022624818608165,
            -0.006391763687133789,
            -0.02498013898730278,
            0.028279496356844902,
            -0.0031062113121151924,
            -0.023235555738210678,
            0.008739471435546875,
            0.022965965792536736,
            0.0002589549985714257,
            0.006154239643365145,
            0.01788194850087166,
            -0.004061632789671421,
            0.02162124589085579,
            0.03003726154565811,
            -0.015110943466424942,
            0.012613923288881779,
            -0.002500020433217287,
            0.02297985926270485,
            0.025858983397483826,
            0.016200872138142586,
            0.012105018831789494,
            -0.015162618830800056,
            0.017896901816129684,
            -0.009372066706418991,
            -0.026543686166405678,
            -0.0028222789987921715,
            0.04103502258658409,
            0.020730620250105858,
            -0.011838722042739391,
            -0.0030860397964715958,
            -0.030716558918356895,
            0.0010217245435342193,
            -0.040202561765909195,
            0.02749704383313656,
            -0.005118377506732941,
            -0.02507726289331913,
            0.014695325866341591,
            -0.017848560586571693,
            0.027636976912617683,
            0.010338357649743557,
            -0.004463641904294491,
            -0.0016450142720714211,
            -0.0068740458227694035,
            0.0021627454552799463,
            0.02310497686266899,
            0.01385478489100933,
            0.014792222529649734,
            0.009290894493460655,
            -0.008168499916791916,
            -0.014471394941210747,
            0.01936846226453781,
            0.01244498509913683,
            -0.027285750955343246,
            0.0035942676477134228,
            0.015310710296034813,
            0.01584681123495102,
            0.015301467850804329,
            0.03883552551269531,
            0.025060389190912247,
            0.025537246838212013,
            -0.009406162425875664,
            0.017207173630595207,
            0.02097208984196186,
            0.04087388515472412,
            0.0014228465734049678,
            0.025602828711271286,
            -0.01906469836831093,
            -7.79056063038297e-06,
            0.024667715653777122,
            0.03722750395536423,
            0.014916887506842613,
            -0.04292890802025795,
            -0.004756325390189886,
            -0.008162916637957096,
            0.013356946408748627,
            0.01710926927626133,
            0.03694382682442665,
            0.03197687491774559,
            -0.021438870579004288,
            -0.005204505752772093,
            0.020218107849359512,
            0.010451708920300007,
            0.019338887184858322,
            -0.01855146698653698,
            -0.013707366771996021,
            -0.01964384689927101,
            -0.03613993152976036,
            0.008748958818614483,
            0.0020782360807061195,
            -0.005226211156696081,
            0.0018098823493346572,
            0.01729019172489643,
            0.016771871596574783,
            0.02626621723175049,
            0.013213921338319778,
            -0.020144658163189888,
            0.023689433932304382,
            0.024209998548030853,
            0.052597884088754654,
            -0.01611095480620861,
            0.018105531111359596,
            -0.019550491124391556,
            0.00031852914253249764,
            -0.00729956803843379,
            0.008472484536468983,
            0.0037011627573519945,
            0.01195646170526743,
            -0.004666547290980816,
            -0.014103356748819351,
            0.014383948408067226,
            0.0062535409815609455,
            0.024792663753032684,
            0.008020272478461266,
            0.0357058160007,
            0.0045452541671693325,
            0.02442999556660652,
            -0.010038217529654503,
            -0.005781190935522318,
            0.020966216921806335,
            0.010694623924791813,
            -0.004089788068085909,
            -0.01393523532897234,
            0.009973886422812939,
            0.024002349004149437,
            -0.03159482777118683,
            0.00929665844887495,
            0.004844773560762405,
            0.03401770442724228,
            -0.024896765127778053,
            -0.020589951425790787,
            0.03854947164654732,
            -0.011198953725397587,
            -0.03681730106472969,
            -0.025115234777331352,
            -0.024823511019349098,
            -0.014453304000198841,
            -0.011537516489624977,
            0.00331695843487978,
            0.021100793033838272,
            -0.014833359979093075,
            0.02215181291103363,
            0.018029257655143738,
            0.017471281811594963,
            0.019191913306713104,
            0.014531007036566734,
            -0.018651818856596947,
            0.0218899417668581,
            -0.03447249159216881,
            -0.02064753696322441,
            0.02818070538341999,
            0.013025132939219475,
            -0.0068055191077291965,
            -0.0186583511531353,
            -0.000469270977191627,
            -0.027340790256857872,
            -0.025719035416841507,
            -0.005460463929921389,
            -0.024828510358929634,
            0.019105257466435432,
            0.03445354849100113,
            0.01037183403968811,
            0.008204519748687744,
            -0.02105174958705902,
            -0.03298686817288399,
            0.022040920332074165,
            -0.014082740992307663,
            -0.026905588805675507,
            0.0017933618510141969,
            -0.03020436502993107,
            0.026743169873952866,
            0.030325526371598244,
            0.02809799648821354,
            0.02213049679994583,
            -0.03805940970778465,
            0.0015100894961506128,
            -0.010881333611905575,
            -0.06445856392383575,
            -0.026821637526154518,
            0.022867368534207344,
            0.002207955112680793,
            0.010807446204125881,
            -0.01935473270714283,
            0.016219625249505043,
            -0.036069851368665695,
            0.007190600503236055,
            -0.020827563479542732,
            -0.026801934465765953,
            0.024622075259685516,
            0.01487059984356165,
            -0.03774601221084595,
            -0.015268087387084961,
            -0.020933862775564194,
            -0.006890469696372747,
            0.011896810494363308,
            -0.01254964992403984,
            -0.0067520346492528915,
            0.03152315691113472,
            0.02757936529815197,
            -0.020572228357195854,
            0.028680212795734406,
            0.011347074992954731,
            0.026923028752207756,
            -0.003362296149134636,
            -0.06777536123991013,
            -0.02877512387931347,
            -0.029765913262963295,
            -0.003295989939942956,
            0.002443981356918812,
            0.009629222564399242,
            0.016681509092450142,
            -0.0050786943174898624,
            0.02035233937203884,
            -0.03511296585202217,
            0.017017900943756104,
            0.011412980034947395,
            0.01268655527383089,
            -0.0074891261756420135,
            -0.03309241309762001,
            0.0016824810300022364,
            -0.033515553921461105,
            0.018406551331281662,
            -0.007894081063568592,
            -0.03445824608206749,
            0.0023477727081626654,
            0.022978508844971657,
            -0.007783233188092709,
            -0.027167363092303276,
            0.0024780803360044956,
            -0.007329288870096207,
            0.0061063203029334545,
            0.00527144642546773,
            -0.009468953125178814,
            0.01832031086087227,
            -0.0029531968757510185,
            -0.003919116221368313,
            -0.0062517267651855946,
            0.016726650297641754,
            0.015316170640289783,
            -0.010903723537921906,
            0.026592876762151718,
            -0.0023384972009807825,
            0.019040271639823914,
            -0.011804582551121712,
            0.030287303030490875,
            -0.02327052690088749,
            -0.009686378762125969,
            0.009452691301703453,
            -0.012518860399723053,
            0.014943047426640987,
            -0.0285250935703516,
            -0.044333454221487045,
            0.01513814739882946,
            -0.016353648155927658,
            -0.02356991358101368,
            0.03245477378368378,
            0.01844821684062481,
            0.02631191350519657,
            0.010350150987505913,
            0.017313310876488686,
            -0.01654198206961155,
            -0.02482595667243004,
            -0.010963796637952328,
            0.015492893755435944,
            0.02601919136941433,
            0.005913714878261089,
            -0.016987154260277748,
            -0.01704465039074421,
            0.001105866744183004,
            -0.0013982829404994845,
            -0.0049090078100562096,
            0.00471184216439724,
            -0.01751904934644699,
            -0.015964578837156296,
            0.003570541040971875,
            0.029785340651869774,
            -0.017046554014086723,
            0.03517362102866173,
            0.00826206523925066,
            0.014784988947212696,
            0.010199809446930885,
            0.03983104228973389,
            -0.019162636250257492,
            -0.026371194049715996,
            -0.0058028968051075935,
            0.008834993466734886,
            0.009251347742974758,
            -0.017791351303458214,
            0.011724410578608513,
            -0.015535526908934116,
            -0.0033877764362841845,
            0.0007762774475850165,
            -0.02585875242948532,
            0.0035083976108580828,
            0.036378443241119385,
            -0.006093975156545639,
            -0.018998710438609123,
            -0.02167879231274128,
            -0.007706465199589729,
            0.006944608874619007,
            -0.004670858383178711,
            0.011373058892786503,
            9.921586024574935e-05,
            0.016263144090771675,
            -0.020701950415968895,
            0.023281468078494072,
            0.017633706331253052,
            -0.000628762471023947,
            0.018441535532474518,
            -0.007248701062053442,
            -0.021162373945116997,
            -0.02619457244873047,
            -0.0219033882021904,
            -0.023480333387851715,
            0.029813531786203384,
            -0.002827971475198865,
            0.018617458641529083,
            0.00818541832268238,
            0.015481531620025635,
            -0.015380176715552807,
            0.005117109976708889,
            0.01840863935649395,
            0.011365511454641819,
            0.016839198768138885,
            0.0035829651169478893,
            0.023945720866322517,
            -0.015022143721580505,
            0.0006518865702673793,
            0.001320895622484386,
            0.024958046153187752,
            0.017761198803782463,
            0.012240732088685036,
            -0.007890949957072735,
            -0.022809186950325966,
            0.014968943782150745,
            0.006040411535650492,
            -0.011491314508020878,
            0.03435029461979866,
            -0.028417253866791725,
            -0.030053189024329185,
            0.021135220304131508,
            0.005585238337516785,
            -0.00876524392515421,
            -0.016435133293271065,
            0.009084044024348259,
            -0.01016642153263092,
            -0.02414621226489544,
            -0.005697022192180157,
            0.02493327483534813,
            0.02200290746986866,
            -0.034332580864429474,
            0.009222006425261497,
            -0.012288649566471577,
            -0.016053076833486557,
            -0.01285806205123663,
            -0.017984453588724136,
            -0.00581183610484004,
            0.01351349800825119,
            0.012356352061033249,
            0.015818852931261063,
            -0.019025761634111404,
            -0.017629945650696754,
            -0.0333232507109642,
            0.00577661395072937,
            -0.010386059992015362,
            -0.013877775520086288,
            -0.008201014250516891,
            0.022852018475532532,
            0.03685085102915764,
            -0.013406364247202873,
            -0.001833450747653842,
            -0.029129602015018463,
            -0.003528393805027008,
            0.017296480014920235,
            0.026760324835777283,
            0.010881941765546799,
            -0.02141636237502098,
            0.017578473314642906,
            -0.023490462452173233,
            -0.0036468803882598877,
            0.010226898826658726,
            -0.0038659514393657446,
            0.026779653504490852,
            -0.006059698294848204,
            -0.02100793644785881,
            -0.004940327722579241,
            0.005461731925606728,
            -0.005406699143350124,
            0.019204068928956985,
            -0.028000086545944214,
            -0.02226836606860161,
            -0.008931993506848812,
            0.029907749965786934,
            -0.004495440050959587,
            0.002104744780808687,
            0.01963227428495884,
            -0.012386270798742771,
            -0.033280231058597565,
            -0.00028259819373488426,
            -0.039475779980421066,
            0.02335270121693611,
            0.012863039039075375,
            -0.03104664757847786,
            0.037658046931028366,
            0.026356345042586327,
            0.022549845278263092,
            -0.002552967518568039,
            0.01766042597591877,
            -0.020413845777511597,
            0.01019078679382801,
            0.0407840758562088,
            -0.014159377664327621,
            0.03508051484823227,
            -0.008671325631439686,
            -0.008213784545660019,
            0.0307137593626976,
            -0.02678602561354637,
            0.01460268534719944,
            -0.0036265458911657333,
            -0.012085148133337498,
            -0.024422606453299522,
            0.01377476379275322,
            0.019917836412787437,
            0.013801347464323044,
            0.00839950144290924,
            0.04285486415028572,
            -0.004018475767225027,
            -0.00868275947868824,
            0.007394795771688223,
            -0.004296867176890373,
            0.03329227492213249,
            0.010986202396452427,
            0.002226077951490879,
            -0.019153453409671783,
            -0.005912256892770529,
            -0.007373063825070858,
            -0.025060690939426422,
            0.0036753355525434017,
            0.028089316561818123,
            -0.030979912728071213,
            -0.027906384319067,
            -0.02321421168744564,
            0.03899415209889412,
            5.459918611450121e-05,
            -0.008688266389071941,
            -0.029915882274508476,
            0.011774610728025436,
            -0.027076328173279762,
            -0.006540308240801096,
            -0.03895437717437744,
            0.020851612091064453,
            0.0013274661032482982,
            -0.010099753737449646,
            0.027253100648522377,
            0.003203079802915454,
            0.030331535264849663,
            0.01119035854935646,
            0.032751455903053284,
            -0.02409599907696247,
            -0.002613670192658901,
            0.025502976030111313,
            0.029876356944441795,
            0.028604844585061073,
            0.018397413194179535,
            -0.009983536787331104,
            -0.029278431087732315,
            0.011293905787169933,
            -0.02251381054520607,
            0.029215894639492035,
            -0.0004448692488949746,
            0.029079407453536987,
            -0.01431532483547926,
            0.0030221056658774614,
            -0.0379132404923439,
            0.006736554205417633,
            -0.01332063041627407,
            0.03560209646821022,
            -0.020711440593004227,
            0.0006120101315900683,
            -0.027423502877354622,
            0.028004394844174385,
            0.020013345405459404,
            -0.02039613202214241,
            0.030809767544269562,
            0.0218050479888916,
            0.026497578248381615,
            0.03377614915370941,
            0.016283569857478142,
            0.0022223072592169046,
            -0.006852967664599419,
            0.017119185999035835,
            0.03127788007259369,
            0.0007585237617604434,
            -0.001091260346584022,
            -0.0005733262514695525,
            0.01956358179450035,
            0.03267425298690796,
            -0.027244536206126213,
            0.03303170204162598,
            -0.01900193840265274,
            0.00452052615582943,
            0.007531613577157259,
            -0.029441017657518387,
            -0.003940890543162823,
            0.012738358229398727,
            0.025014713406562805,
            -0.006551836617290974,
            -0.010356388054788113,
            0.0359041728079319,
            0.02595115453004837,
            -0.008782090619206429,
            -0.008620701730251312,
            -0.016743194311857224,
            -0.0028088907711207867,
            -0.02638009563088417,
            -0.010684637352824211,
            -0.011702976189553738,
            -0.005189220421016216,
            0.003121965564787388,
            -0.009295624680817127,
            0.014478350058197975,
            0.03767544776201248,
            0.04576517641544342,
            0.009005310013890266,
            -0.00604273984208703,
            0.030645595863461494,
            -0.013085010461509228,
            -0.005077457055449486,
            0.031022366136312485,
            -0.004625977482646704,
            0.010865071788430214,
            -0.008068188093602657,
            -0.025419052690267563,
            -0.0071879499591887,
            -0.01913873292505741,
            0.04061456397175789,
            -0.005189348477870226,
            -0.017173288390040398,
            -0.016271596774458885,
            0.005780867766588926,
            -5.396290362114087e-05,
            0.001095253974199295,
            -0.033675722777843475,
            0.036941081285476685,
            -0.0008639663574285805,
            -0.002237216103821993,
            -0.003623891156166792,
            -0.023072652518749237,
            -0.012462995015084743,
            -0.0022639089729636908,
            -0.031005755066871643,
            -0.0041093239560723305,
            -0.013211052864789963,
            0.003382631577551365,
            -0.007108659017831087,
            -0.012780910357832909,
            0.03660785034298897,
            -0.030597291886806488,
            -0.024196846410632133,
            0.01706627383828163,
            -0.021167851984500885,
            -0.003392445854842663,
            0.03823481872677803,
            -0.019651638343930244,
            0.03117469511926174,
            0.01917422190308571,
            -0.019717909395694733,
            0.030190832912921906,
            -0.004528457298874855,
            -0.021512191742658615,
            0.004767227917909622,
            -0.03385030850768089,
            -0.02046109177172184,
            -0.0068215904757380486,
            -0.011164291761815548,
            0.012770810164511204,
            -0.025217069312930107,
            0.003963638562709093,
            0.015980221331119537,
            -0.013157508336007595,
            0.02488528937101364,
            0.049803800880908966,
            0.03641751781105995,
            -0.00764665100723505,
            0.021660737693309784,
            0.014843790791928768,
            0.02466730959713459,
            -0.026723328977823257,
            -0.029511641710996628,
            0.006031966768205166,
            -0.010845810174942017,
            0.028220688924193382,
            -0.022704795002937317,
            -0.010579787194728851,
            0.01633964292705059,
            0.001824833219870925,
            -0.03428403660655022,
            -0.0075303977355360985,
            -0.016030484810471535,
            0.02995290979743004,
            -0.015606679022312164,
            0.002703531412407756,
            -0.00882053654640913,
            -0.02036304771900177,
            0.0237958375364542,
            -0.0022117868065834045,
            -0.03008737601339817,
            -0.003892860608175397,
            -0.002320924773812294,
            -0.012948602437973022,
            -0.01721113547682762,
            -0.008797754533588886,
            -0.02625952661037445,
            -0.008968029171228409,
            -0.008134210482239723,
            -0.006259352900087833,
            -0.02070821262896061,
            0.020804980769753456,
            0.00485588051378727,
            0.01029895804822445,
            -0.003935191780328751,
            0.003388951998203993,
            0.015353463590145111,
            0.023516925051808357,
            -0.030873792245984077,
            0.0377470888197422,
            -0.0058285328559577465,
            -0.009404022246599197,
            -0.015918534249067307,
            -0.02665041573345661,
            -0.028532080352306366,
            -0.02881564386188984,
            -0.015061900950968266,
            0.009688063524663448,
            0.035079918801784515,
            0.011744420044124126,
            0.029998544603586197,
            -0.006822895258665085,
            0.003336892230436206,
            -0.005511023569852114,
            -0.02682219073176384,
            0.0005478374077938497,
            -0.00033634190913289785,
            -0.03064798191189766,
            -0.0068414295092225075,
            0.005013149697333574,
            0.0011358663905411959,
            0.0180350411683321,
            -0.01732124201953411,
            0.015385918319225311,
            0.030665839090943336,
            -0.003558801021426916,
            -0.01673642359673977,
            0.005365673918277025,
            0.016701191663742065,
            -0.022708319127559662,
            0.011486435309052467,
            -0.024043379351496696,
            0.021326376125216484,
            0.011486543342471123,
            -0.014589531347155571,
            -0.0019053666619583964,
            0.009935485199093819,
            -0.025175537914037704,
            -0.022637350484728813,
            -0.011699356138706207,
            0.004055822268128395,
            0.003182998625561595,
            -0.014433388598263264,
            -0.0160382017493248,
            -0.005225162021815777,
            -0.02085375227034092,
            -0.028351319953799248,
            0.000841432367451489,
            0.011732032522559166,
            -0.020151395350694656,
            -0.006992371752858162,
            -0.0005197548889555037,
            0.0019255164079368114,
            0.007562458980828524,
            -0.000259226537309587,
            0.02084287814795971,
            0.02934301644563675,
            0.03262508660554886,
            0.00127111142501235,
            -0.007374913897365332,
            0.020615149289369583,
            0.02963073179125786,
            0.019604071974754333,
            -0.012335442006587982,
            0.02294016070663929,
            7.292302325367928e-05,
            -0.03886644169688225,
            0.012042194604873657,
            0.005118928849697113,
            0.0237943846732378,
            -0.005584981758147478,
            -0.004244746640324593,
            0.0336681604385376,
            0.035653308033943176,
            0.013144432567059994,
            -0.0032992286141961813,
            0.02118423953652382,
            0.023756345734000206,
            -0.024493128061294556,
            -0.024602940306067467,
            -0.000814251892734319,
            0.02633015811443329,
            0.001704750582575798,
            0.03931396082043648,
            -0.037901103496551514,
            -0.056957874447107315,
            0.014323611743748188,
            0.0073286001570522785,
            -0.011873387731611729,
            0.005659854970872402,
            0.004268056713044643,
            -0.009078235365450382,
            -0.0034883019980043173,
            0.0122386384755373,
            -0.0023106078151613474,
            -0.02470490150153637,
            -0.021266896277666092,
            -0.022525474429130554,
            0.012565615586936474,
            -0.012578205205500126,
            -0.013373279944062233,
            0.029882295057177544,
            0.008637678809463978,
            0.037984706461429596,
            0.017323777079582214,
            0.020293015986680984,
            0.010747783817350864,
            -0.0007066132966428995,
            0.010972107760608196,
            0.007923290133476257,
            -0.007731115911155939,
            -0.041009172797203064,
            -0.010477143339812756,
            0.03151161968708038,
            0.006830656435340643,
            0.006483494304120541,
            0.027765735983848572,
            -0.04207118973135948,
            -0.001905651530250907,
            -0.0329403430223465,
            0.012658079154789448,
            0.0262268278747797,
            0.022468065842986107,
            -0.010109917260706425,
            -0.024517489597201347,
            -0.017183883115649223,
            0.03301243111491203,
            0.01510749664157629,
            -0.02599763683974743,
            -0.002893003635108471,
            0.03047477826476097,
            -0.026906883344054222,
            0.01077478751540184,
            -0.005482000764459372,
            0.03702115640044212,
            -0.030701955780386925,
            0.028787625953555107,
            -0.007538169622421265,
            0.028534268960356712,
            -0.03556162491440773,
            -0.0259244367480278,
            -0.012107327580451965,
            -0.03674754500389099,
            -0.008264460600912571,
            -0.015392332337796688,
            -0.023389508947730064,
            -0.007614164147526026,
            0.022274231538176537,
            -5.4210009693633765e-05,
            -0.008616355247795582,
            0.013298863545060158,
            0.016252823173999786,
            -0.03402629867196083,
            0.003982416354119778,
            -0.013985835947096348
        ],
        [
            -0.023223018273711205,
            -0.007047407794743776,
            0.025322051718831062,
            -0.004970085341483355,
            0.01938665099442005,
            -0.013605426996946335,
            -0.005501324776560068,
            -0.027545148506760597,
            -0.004774882458150387,
            -0.004568424541503191,
            -0.010441490449011326,
            -0.013956844806671143,
            -0.054938774555921555,
            0.016000745818018913,
            0.0306556336581707,
            0.00036017681122757494,
            0.010326762683689594,
            -0.013150629587471485,
            -0.057221420109272,
            -0.02506016194820404,
            -0.03225158900022507,
            -0.02462947554886341,
            -0.017601747065782547,
            -0.02280549518764019,
            0.023906897753477097,
            0.01239610556513071,
            0.021870601922273636,
            -0.002357315272092819,
            -0.001457141013815999,
            -0.003328850260004401,
            -0.034503236413002014,
            0.024386217817664146,
            -0.002961491234600544,
            -0.026118455454707146,
            -0.011708186939358711,
            0.03552430495619774,
            0.012066375464200974,
            0.024169739335775375,
            0.008767107501626015,
            0.02801048941910267,
            -0.012434832751750946,
            -0.008998685516417027,
            0.03096170909702778,
            -0.00529843382537365,
            0.03341070935130119,
            0.03548898547887802,
            0.01472740713506937,
            -0.03402988612651825,
            -0.013210904784500599,
            0.025384079664945602,
            -0.0011094985529780388,
            -0.042092740535736084,
            -0.04752834141254425,
            -0.01772407814860344,
            0.023209843784570694,
            0.022525446489453316,
            -0.023979997262358665,
            -0.028217196464538574,
            -0.0040726494044065475,
            -0.02353203110396862,
            0.019586483016610146,
            -0.008588214404881,
            -0.01983622834086418,
            -0.011600027792155743,
            0.01731734164059162,
            0.04782747104763985,
            -0.026061678305268288,
            0.03371770307421684,
            -0.007128218188881874,
            -0.002252111677080393,
            0.036884605884552,
            -0.017936252057552338,
            0.021795684471726418,
            0.023190530017018318,
            0.03568721190094948,
            0.013222635723650455,
            0.01263462845236063,
            0.004038800019770861,
            0.001636166824027896,
            0.014379855245351791,
            -0.023172428831458092,
            0.015549925155937672,
            0.0156138576567173,
            0.005966960918158293,
            -0.021399736404418945,
            0.007663593627512455,
            -0.02544582448899746,
            -0.021201735362410545,
            0.04440494254231453,
            -0.005930580198764801,
            -0.010822989046573639,
            -0.022180918604135513,
            -0.03003692254424095,
            -0.0029905817937105894,
            0.0169454887509346,
            0.016212960705161095,
            0.023654425516724586,
            -0.00938875786960125,
            0.02070312760770321,
            -0.00072516780346632,
            0.01721367985010147,
            0.030766194686293602,
            0.040538351982831955,
            -0.04263993352651596,
            -0.02080380544066429,
            0.03766869381070137,
            0.018267666921019554,
            0.008347775787115097,
            0.011327850632369518,
            0.02276846207678318,
            -0.00545539753511548,
            0.050153765827417374,
            0.023736463859677315,
            0.02097754180431366,
            -0.06453610211610794,
            0.01011225488036871,
            -0.0006098448066040874,
            -0.008545412681996822,
            -7.544011896243319e-05,
            0.00951725896447897,
            -0.010648244060575962,
            0.0035379892215132713,
            0.0014835138572379947,
            0.00842213537544012,
            0.01098044216632843,
            0.014678253792226315,
            0.0226530060172081,
            0.02809181809425354,
            -0.008220812305808067,
            0.003764227032661438,
            0.005813108291476965,
            0.01670621894299984,
            0.011884963139891624,
            0.02282552979886532,
            0.012288708239793777,
            -0.02362002804875374,
            -0.025293568149209023,
            0.014527416788041592,
            0.022840561345219612,
            -0.02790931798517704,
            -0.025888897478580475,
            -0.02740669809281826,
            -0.006784379947930574,
            0.0245406161993742,
            0.023360295221209526,
            0.0016812589019536972,
            0.008570347912609577,
            -0.0015267982380464673,
            0.006645441520959139,
            0.01726095750927925,
            -0.00827481783926487,
            -0.05983720347285271,
            -0.004130308981984854,
            -0.012876633554697037,
            0.015338183380663395,
            0.01537037268280983,
            0.02869386412203312,
            0.01774357073009014,
            -0.0005627939244732261,
            0.0321856327354908,
            -0.026645105332136154,
            -0.018223373219370842,
            0.016446609050035477,
            -0.020849423483014107,
            -0.0008465049904771149,
            0.008811401203274727,
            -0.018918681889772415,
            -0.02114548347890377,
            0.006073459051549435,
            -0.029430314898490906,
            0.010343829169869423,
            -0.011643834412097931,
            0.009899205528199673,
            -0.041046980768442154,
            -0.03709021210670471,
            0.02924194745719433,
            0.02050860784947872,
            -0.006329800933599472,
            0.034512098878622055,
            0.03849257156252861,
            -0.005433118902146816,
            -0.0010279169073328376,
            0.02890920639038086,
            0.004002989269793034,
            -0.015628289431333542,
            0.01764736697077751,
            -0.01540210098028183,
            0.021051989868283272,
            -0.03450603783130646,
            0.007203101180493832,
            -0.015932654961943626,
            0.01155086513608694,
            0.014295504428446293,
            0.026580817997455597,
            -0.019831132143735886,
            0.023562075570225716,
            0.012006509117782116,
            0.008954659104347229,
            0.02423100918531418,
            0.00595895666629076,
            -0.019647816196084023,
            0.03210526332259178,
            -0.03203015401959419,
            -0.0029415488243103027,
            0.00512436730787158,
            -0.026181716471910477,
            -0.015310023911297321,
            0.03982728719711304,
            -0.0028843155596405268,
            0.003958116751164198,
            0.014810079708695412,
            -0.003459808649495244,
            0.017062092199921608,
            0.028331568464636803,
            -0.013349858112633228,
            -0.015550611540675163,
            0.021673129871487617,
            0.002404474886134267,
            0.0008534155203960836,
            0.0028617060743272305,
            -0.0153620894998312,
            -0.022921094670891762,
            0.024145448580384254,
            -0.001157477148808539,
            0.0038122767582535744,
            -0.03066716529428959,
            -0.05472283437848091,
            0.019943440333008766,
            -0.013668018393218517,
            0.008910282514989376,
            -0.025502601638436317,
            -0.019834397360682487,
            -0.013103959150612354,
            0.0009282921091653407,
            0.06486201286315918,
            0.007135722320526838,
            0.009286660701036453,
            0.0043495818972587585,
            0.0238493662327528,
            -0.0008257614681497216,
            -0.024603743106126785,
            0.02734730951488018,
            0.015351243317127228,
            -0.0054647610522806644,
            -0.05583430454134941,
            -0.006488848011940718,
            -0.01922307163476944,
            -0.003313890192657709,
            0.0005483149434439838,
            0.002439092844724655,
            0.015056773088872433,
            -0.03239135444164276,
            0.0187715794891119,
            0.029353057965636253,
            -0.012597290799021721,
            -0.023395800963044167,
            0.007037232629954815,
            -0.013632790185511112,
            0.04875889793038368,
            0.004345356486737728,
            0.034946639090776443,
            -0.02621953748166561,
            0.010439329780638218,
            -0.015965536236763,
            0.027927806600928307,
            -0.006449985317885876,
            0.0042191906832158566,
            -0.025903550907969475,
            -0.016427883878350258,
            0.01800704561173916,
            -0.01629604771733284,
            0.018441928550601006,
            0.013897091150283813,
            0.008685305714607239,
            -0.02346065081655979,
            0.01243570540100336,
            -0.012570868246257305,
            0.018407676368951797,
            -0.03151119500398636,
            -0.021975327283143997,
            0.05082828551530838,
            0.015275814570486546,
            -0.028742600232362747,
            0.02313355728983879,
            -0.03169172629714012,
            0.02413768135011196,
            0.027478042989969254,
            -0.00935107097029686,
            -0.0023015213664621115,
            -0.004340055864304304,
            0.013496650382876396,
            0.06079043820500374,
            0.007802731357514858,
            0.0026334915310144424,
            0.0036121660377830267,
            -0.014180566184222698,
            0.021569279953837395,
            -0.007331459783017635,
            2.001104803639464e-05,
            -0.005652372259646654,
            0.010109937749803066,
            0.050617195665836334,
            -0.000876996957231313,
            0.012031814083456993,
            -0.015539363026618958,
            -0.04677265137434006,
            0.00422369409352541,
            0.005859397817403078,
            -0.010553097352385521,
            -0.007845068350434303,
            0.032909296452999115,
            -0.009876132942736149,
            0.023029537871479988,
            -0.02718120813369751,
            0.031455010175704956,
            -0.02836681343615055,
            -0.013545885682106018,
            0.0003491054230835289,
            -0.005720681976526976,
            0.0055265664122998714,
            0.03702117130160332,
            -0.01390288770198822,
            -0.009123032912611961,
            -0.03755341097712517,
            0.02726314589381218,
            0.02831314131617546,
            -0.0020605530589818954,
            -0.01480840239673853,
            0.002362076425924897,
            0.00020892239990644157,
            0.005491098389029503,
            -0.024144325405359268,
            0.005724725779145956,
            0.011064494960010052,
            -0.013875380158424377,
            -0.004751875065267086,
            -0.015313968062400818,
            -0.02474425733089447,
            -0.00439197663217783,
            0.03136759251356125,
            0.01639334112405777,
            -0.020421095192432404,
            0.03167204186320305,
            0.017463725060224533,
            0.028639888390898705,
            -0.050709713250398636,
            0.004929137881845236,
            0.037865541875362396,
            0.028847776353359222,
            -0.016260404139757156,
            -0.03209565579891205,
            -0.007266462780535221,
            0.01401506457477808,
            -0.00022554460156243294,
            0.005639000795781612,
            0.036011796444654465,
            -0.005567878019064665,
            -0.016512637957930565,
            -0.01312951184809208,
            -0.01777845248579979,
            -0.0009131394908763468,
            0.009560919366776943,
            0.03723892197012901,
            -0.006151196546852589,
            -0.007681028451770544,
            0.012461332604289055,
            0.03968007117509842,
            0.008590025827288628,
            -0.02356575056910515,
            -0.011939125135540962,
            0.006327763665467501,
            -0.0006424838793464005,
            -0.01645744778215885,
            0.034273382276296616,
            0.018866289407014847,
            0.029759615659713745,
            0.023719122633337975,
            -0.02474886178970337,
            0.007055672816932201,
            0.016688687726855278,
            -0.05388021096587181,
            0.014408083632588387,
            -0.011608648113906384,
            -0.01337475050240755,
            0.010095353238284588,
            -0.0299723818898201,
            0.013779117725789547,
            -0.011089385487139225,
            -0.002052129479125142,
            -0.031062742695212364,
            0.009448707103729248,
            -0.010152501054108143,
            0.0331718735396862,
            0.005143388174474239,
            0.015531050972640514,
            0.037352751940488815,
            0.020436987280845642,
            -0.0054639666341245174,
            0.01855330355465412,
            0.01135921198874712,
            -0.004888463299721479,
            0.004640806466341019,
            0.03131578862667084,
            -0.004376647528260946,
            -0.03402257710695267,
            0.02251485362648964,
            -0.012949598953127861,
            -0.018235554918646812,
            0.021565189585089684,
            -0.006366930436342955,
            0.022479550912976265,
            -0.025234853848814964,
            0.005210671573877335,
            -0.006963504943996668,
            0.020668499171733856,
            -0.0033146769274026155,
            0.027593271806836128,
            0.020279916003346443,
            -0.05444104224443436,
            0.017220675945281982,
            -0.00888760108500719,
            -0.029335107654333115,
            0.03381970524787903,
            -0.018329506739974022,
            0.004083289299160242,
            0.011236297897994518,
            -0.008336087688803673,
            0.017105672508478165,
            0.004373717121779919,
            -0.01939782314002514,
            0.003949776291847229,
            -0.015926508232951164,
            0.03308914974331856,
            -0.03860776126384735,
            -0.026286866515874863,
            0.03603826090693474,
            -0.0025636476930230856,
            0.003967421595007181,
            0.033844608813524246,
            -0.014607802033424377,
            0.028197966516017914,
            0.024093665182590485,
            0.008442926220595837,
            0.02093515358865261,
            0.020800994709134102,
            -0.010487462393939495,
            -0.0021756752394139767,
            -0.06634554266929626,
            0.01345584075897932,
            -0.017503196373581886,
            0.011164008639752865,
            -0.014829684980213642,
            -0.00793696753680706,
            0.015141717158257961,
            0.020269088447093964,
            -0.007342135068029165,
            0.013203532434999943,
            -0.011135504581034184,
            0.0064851874485611916,
            -0.001975785242393613,
            -0.012719064019620419,
            0.0006989702815189958,
            0.03183372691273689,
            -0.003950630780309439,
            -0.013223562389612198,
            0.009782391600310802,
            0.0018397043459117413,
            0.017216399312019348,
            -0.008903837762773037,
            0.011180596426129341,
            0.023449089378118515,
            -0.013826117850840092,
            -0.011975697241723537,
            -0.047315843403339386,
            -0.0337536558508873,
            -0.013818182051181793,
            0.008476135320961475,
            0.016742542386054993,
            0.027731316164135933,
            0.010174033232033253,
            0.032892998307943344,
            0.04892902821302414,
            0.035154182463884354,
            -0.007739941123872995,
            -0.01357981190085411,
            0.03902685269713402,
            -0.01768132485449314,
            -0.025252822786569595,
            0.059487298130989075,
            -0.0015843696892261505,
            -0.019770795479416847,
            0.024613231420516968,
            -0.002563913119956851,
            0.027474045753479004,
            0.02691410295665264,
            0.028293462470173836,
            0.02278546616435051,
            0.01953050307929516,
            -0.02915886975824833,
            0.002922042040154338,
            -0.011620289646089077,
            -0.010207907296717167,
            -0.023736746981739998,
            0.0073735411278903484,
            0.01984209567308426,
            -0.030676038935780525,
            -0.023789001628756523,
            0.01902267150580883,
            0.024459198117256165,
            -0.02951471135020256,
            -0.024902021512389183,
            -0.014745405875146389,
            0.0034702373668551445,
            0.04070115089416504,
            -0.002195349894464016,
            0.021202869713306427,
            0.02268865704536438,
            0.00675502885133028,
            -0.013356152921915054,
            -0.008781252428889275,
            0.000845226226374507,
            -0.018331602215766907,
            0.017983004450798035,
            0.016599174588918686,
            -0.009887322783470154,
            0.0060378932394087315,
            -0.015067975968122482,
            -0.018148893490433693,
            0.042244475334882736,
            -0.004297023639082909,
            -0.014429465867578983,
            0.005052110645920038,
            0.000233444370678626,
            -0.008921434171497822,
            -0.0009472601814195514,
            0.05969816818833351,
            -0.02761363983154297,
            -0.01854020357131958,
            0.03203180432319641,
            -0.03197415545582771,
            -0.02148766629397869,
            -0.0057657393626868725,
            -0.007479419931769371,
            0.025584714487195015,
            0.0075873215682804585,
            0.020242510363459587,
            0.01977582648396492,
            0.01595461554825306,
            0.0031723727006465197,
            -0.00043601071229204535,
            0.01655747927725315,
            -0.02694648504257202,
            0.01368758175522089,
            0.017813827842473984,
            0.010340707376599312,
            -0.013298910111188889,
            0.018547512590885162,
            -0.00335966725833714,
            -0.00882895477116108,
            -0.007795826997607946,
            0.03320678696036339,
            -0.029495468363165855,
            0.022936755791306496,
            -0.00942390225827694,
            -0.010902993381023407,
            0.01153974886983633,
            -0.012198074720799923,
            0.039127416908741,
            -0.003364827251061797,
            -0.038353558629751205,
            -0.009108643047511578,
            0.0018445210298523307,
            -0.007425044663250446,
            0.022665083408355713,
            -0.006456779316067696,
            0.02126416750252247,
            0.0020123037975281477,
            0.021145662292838097,
            0.03616975247859955,
            0.029160093516111374,
            0.02097143605351448,
            -0.037946633994579315,
            -0.0420406237244606,
            0.013191966339945793,
            0.010606191121041775,
            -0.004492655862122774,
            -0.029668569564819336,
            0.00420741643756628,
            0.008209762163460255,
            0.035937003791332245,
            -0.008125468157231808,
            -0.003681928152218461,
            0.01758095622062683,
            -0.019123250618577003,
            -0.013843312859535217,
            0.020908262580633163,
            0.015043381601572037,
            -0.022698624059557915,
            -0.03242373466491699,
            -0.003271430265158415,
            0.037867702543735504,
            0.0044810157269239426,
            0.024978693574666977,
            -0.04622510448098183,
            -0.012231641449034214,
            0.01225198246538639,
            -0.01621762104332447,
            -0.004010165110230446,
            -0.005346432328224182,
            -0.004802255425602198,
            -0.029894331470131874,
            -0.022328684106469154,
            0.003662183415144682,
            0.013637963682413101,
            0.017634551972150803,
            -0.02151271142065525,
            0.02725749835371971,
            0.01051640696823597,
            0.022447479888796806,
            0.0399281270802021,
            -0.0022309627383947372,
            -0.0030454613734036684,
            -0.005979366600513458,
            0.007460681721568108,
            -0.006801544222980738,
            -0.009648931212723255,
            0.01761215552687645,
            0.010564424097537994,
            -0.004188878927379847,
            0.010036163032054901,
            -0.009244675748050213,
            0.011011665686964989,
            0.015533562749624252,
            -0.003173790406435728,
            0.04477204009890556,
            -0.00546308746561408,
            0.03420627489686012,
            -0.011194747872650623,
            0.004948086105287075,
            -0.030174078419804573,
            -0.008054320700466633,
            -0.018888480961322784,
            -0.0033729695715010166,
            -0.09104302525520325,
            -0.011672687716782093,
            0.012435603886842728,
            0.029617687687277794,
            -0.019016806036233902,
            -0.032234806567430496,
            -0.010103045031428337,
            -0.012543842196464539,
            -0.008407166227698326,
            -0.01522011123597622,
            0.01653244160115719,
            -0.008828229270875454,
            0.015259215608239174,
            0.021261965855956078,
            0.016026156023144722,
            0.00022080671624280512,
            0.0327657014131546,
            0.024403143674135208,
            -0.043312836438417435,
            -0.029791289940476418,
            0.022434953600168228,
            0.019271893426775932,
            0.006878476124256849,
            -0.001978071639314294,
            -0.016835710033774376,
            -0.01784910075366497,
            0.02787448838353157,
            0.02545786276459694,
            0.006333986297249794,
            0.016246100887656212,
            -0.008880140259861946,
            -0.028877675533294678,
            0.009780524298548698,
            0.003335455898195505,
            0.0025797304697334766,
            -0.0074191587045788765,
            -0.024141598492860794,
            -0.02467009425163269,
            -0.0028541656211018562,
            -0.007650026120245457,
            0.018018698319792747,
            0.02186085470020771,
            0.001570131629705429,
            0.0035795385483652353,
            -0.008745498023927212,
            -0.004073537420481443,
            0.013189680874347687,
            -0.0017731116386130452,
            0.02645961567759514,
            -0.02127591148018837,
            0.01844341680407524,
            0.009701807983219624,
            -0.006810702849179506,
            0.029005559161305428,
            -0.026034001260995865,
            0.01466729398816824,
            0.011869669891893864,
            0.009478545747697353,
            0.01875816099345684,
            -0.009088453836739063,
            -0.009671398438513279,
            0.0034177470952272415,
            0.03049406409263611,
            -0.01941937953233719,
            -0.015823373571038246,
            0.041938021779060364,
            0.015388095751404762,
            0.009688238613307476,
            0.02918640896677971,
            -0.016534805297851562,
            -0.022341912612318993,
            -0.002082050545141101,
            0.00488776620477438,
            0.0114602604880929,
            -0.0037514653522521257,
            -0.005707971751689911,
            -0.0277577992528677,
            0.04266059026122093,
            0.03624296188354492,
            -0.02324613928794861,
            -0.007890927605330944,
            0.0021788370795547962,
            0.01427486352622509,
            -0.004212637897580862,
            -0.025927675887942314,
            0.024667035788297653,
            0.01528260763734579,
            0.03325720503926277,
            -0.01042882353067398,
            0.013017428107559681,
            0.008424154482781887,
            -0.02953137643635273,
            -0.02143895998597145,
            0.03335915505886078,
            0.030969448387622833,
            -0.05131030082702637,
            -0.011408834718167782,
            0.011442139744758606,
            0.028114058077335358,
            0.009196226485073566,
            0.00774761475622654,
            0.03221721947193146,
            0.016712091863155365,
            0.02607613615691662,
            -0.019765706732869148,
            -0.01566845364868641,
            0.032971687614917755,
            -0.006452501285821199,
            -0.02581745572388172,
            -0.0011258682934567332,
            -0.045447297394275665,
            0.00015477118722628802,
            0.006769788451492786,
            0.023552928119897842,
            -0.049228567630052567,
            0.0031080280896276236,
            -0.002709364052861929,
            0.025853337720036507,
            0.03203925862908363,
            0.006027900148183107,
            0.03505313768982887,
            -0.022592121735215187,
            0.04174203425645828,
            0.003911469131708145,
            -0.011334968730807304,
            0.022639025002717972,
            -0.03559830039739609,
            -0.05557464063167572,
            0.004548709839582443,
            0.029163958504796028,
            0.03265004977583885,
            -0.003974393010139465,
            -0.05307677760720253,
            0.023610956966876984,
            -0.011688830330967903,
            -0.0184511486440897,
            -0.011126447468996048,
            -0.007849526591598988,
            0.022466080263257027,
            0.01540933083742857,
            -0.016276463866233826,
            0.0224847923964262,
            0.0377250611782074,
            0.03498005494475365,
            -0.002678023651242256,
            0.0019964096136391163,
            -0.0036598313599824905,
            0.02525283396244049,
            0.006719926837831736,
            -0.038929007947444916,
            -0.027401871979236603,
            0.019729413092136383,
            -0.011627049185335636,
            -0.00818353146314621,
            0.01639857329428196,
            -0.004051147028803825,
            0.04060327634215355,
            -0.004721781704574823,
            -0.009869072586297989,
            0.0020770172122865915,
            -0.001168554648756981,
            -0.040919236838817596,
            0.006060662213712931,
            0.02030394971370697,
            0.01125248335301876,
            -0.01492554135620594,
            -0.002682220656424761,
            -0.006998680531978607,
            0.030650541186332703,
            0.019636599346995354,
            -0.01011879462748766,
            0.025504643097519875,
            -0.007618085481226444,
            0.012771557085216045,
            -0.008760598488152027,
            -0.01248167548328638,
            0.004298949148505926,
            0.00696256197988987,
            -0.02883283421397209,
            -0.02413412556052208,
            -0.0010184000711888075,
            0.02594304084777832,
            0.03918609768152237,
            -0.025427518412470818,
            -0.014572348445653915,
            0.028732983395457268,
            0.004641082603484392,
            -0.02040921151638031,
            -0.026152925565838814,
            0.022106390446424484,
            -0.018059195950627327,
            0.012977058999240398,
            0.030227618291974068,
            0.0366276316344738,
            -0.009063106030225754,
            0.022365884855389595,
            -0.014414525590837002,
            -0.010821551084518433,
            0.0271710567176342,
            0.007565251085907221,
            0.008159805089235306,
            -0.006493617780506611,
            0.03126409649848938,
            -0.0225229412317276,
            0.02450994960963726,
            -0.028391430154442787,
            -0.021678805351257324,
            0.0019946685060858727,
            -0.008828872814774513,
            0.018776407465338707,
            0.023821013048291206,
            -0.03939402848482132,
            0.015281140804290771,
            0.019393205642700195,
            -0.03068958967924118,
            -0.016569525003433228,
            0.018675556406378746,
            0.018829096108675003,
            -0.011844078078866005,
            -0.034687235951423645,
            0.0402088426053524,
            0.0032005668617784977,
            -0.012990007176995277,
            -0.010242781601846218,
            0.013186206109821796,
            0.015913978219032288,
            -0.006006642710417509,
            0.022551940754055977,
            0.008016802370548248,
            0.0007413554121740162,
            -0.02637178637087345,
            -0.018423669040203094,
            -0.026628505438566208,
            -0.006803145632147789,
            -0.021465230733156204,
            0.00961233675479889,
            -0.008491624146699905,
            0.02595215104520321,
            -0.024225618690252304,
            0.013361888006329536,
            -0.02015160396695137,
            0.005902487318962812,
            -0.013083108700811863,
            0.007121826522052288,
            0.0252530537545681,
            0.00368511606939137,
            0.01491424348205328,
            0.0475735105574131,
            -0.041707925498485565,
            -0.059601686894893646,
            0.023100566118955612,
            -0.04860938712954521,
            -0.0006158181931823492,
            -0.013435229659080505,
            0.026522880420088768,
            -0.024893425405025482,
            -0.03585301712155342,
            0.030326878651976585,
            0.02897859923541546,
            0.03542671352624893,
            -0.03119402937591076,
            0.036143865436315536,
            0.0018805619329214096,
            0.0262036994099617,
            -0.0020271744579076767,
            0.006987155415117741,
            0.03266581892967224,
            -0.0320272259414196,
            0.007424706127494574,
            -0.03345445916056633,
            0.017052870243787766,
            -0.005119169130921364,
            -0.002696176990866661,
            -0.0333462730050087,
            0.01293272990733385,
            -0.01203883532434702,
            -0.019934894517064095,
            -0.024952542036771774,
            0.016927342861890793,
            -0.000926708453334868,
            0.03460336849093437,
            0.024942364543676376,
            0.03267640992999077,
            0.002973731840029359,
            -0.01496682595461607,
            -0.0024659200571477413,
            0.00238682609051466,
            0.005814549047499895,
            0.006624601315706968,
            0.02842196822166443,
            0.00798957608640194,
            -0.015384136699140072,
            -0.010049455799162388,
            0.023156385868787766,
            -0.010866265743970871,
            0.030999504029750824,
            0.007918714545667171,
            0.023564966395497322,
            0.020825592800974846,
            0.009281127713620663,
            -0.013333902694284916,
            -0.015140402130782604,
            0.00783862080425024,
            -0.025598697364330292,
            -0.01203136332333088,
            0.007099753245711327,
            -0.004728320520371199,
            0.0002970659115817398,
            -0.014495129697024822,
            -0.005124190356582403,
            -0.020293157547712326,
            0.014703607186675072,
            0.05229770392179489,
            -0.01728920079767704,
            -0.029502563178539276,
            0.016135811805725098,
            -0.002765466459095478,
            -0.019930345937609673,
            0.0071935453452169895,
            0.032488156110048294,
            0.03300406038761139,
            0.025131892412900925,
            0.005055529065430164,
            -0.001321314601227641,
            -0.006546407472342253,
            0.008059038780629635,
            0.00444677984341979,
            -0.0012625626986846328,
            -0.002240746980533004,
            -0.023148562759160995,
            -0.0300226379185915,
            0.006410714238882065,
            -0.027491938322782516,
            0.01223758514970541,
            0.008564338088035583,
            0.03322305530309677,
            0.017942488193511963,
            -0.035164009779691696,
            0.02375788986682892,
            -0.020092198625206947,
            -0.009787939488887787,
            0.020323215052485466,
            0.02041046880185604,
            -0.00623334152624011,
            -0.011852338910102844,
            -0.021319154649972916,
            0.015325585380196571,
            0.026450717821717262,
            0.0023923602420836687,
            -0.007199862040579319,
            0.0015420746058225632,
            -0.008888348937034607,
            0.04762451350688934,
            0.06294621527194977,
            0.03099530190229416,
            -0.004899042658507824,
            0.006523360963910818,
            0.015790367498993874,
            -0.011499938555061817,
            0.006486956495791674,
            -0.016550863161683083,
            -0.01384803932160139,
            -0.026748493313789368,
            -0.015473810024559498,
            -0.01060825027525425,
            -0.02173435688018799,
            0.022445883601903915,
            -0.021455924957990646,
            0.03861293941736221,
            -0.03007308766245842,
            -0.013531310483813286,
            0.02257714234292507,
            0.005294119473546743,
            -0.020883258432149887,
            0.02487839013338089,
            -0.002939742524176836,
            0.011972922831773758,
            -0.05864820256829262,
            0.027356397360563278,
            0.022828539833426476,
            0.012547367252409458
        ],
        [
            -0.03118283860385418,
            0.02038872055709362,
            -0.015082243829965591,
            -0.004910550080239773,
            -0.029810801148414612,
            -0.012485269457101822,
            0.010367615148425102,
            -0.008725393563508987,
            -0.00162293273024261,
            -0.026710806414484978,
            0.021852925419807434,
            0.017642224207520485,
            0.021012766286730766,
            0.029423613101243973,
            0.020145583897829056,
            -0.019136471673846245,
            0.0016759490827098489,
            -0.02865622378885746,
            0.028264891356229782,
            -0.02911228872835636,
            -0.023657307028770447,
            0.000820276967715472,
            0.005986914038658142,
            -0.023915909230709076,
            -0.02248508110642433,
            -0.007062971591949463,
            0.020357949659228325,
            0.028315994888544083,
            0.02588549070060253,
            0.017007479444146156,
            -0.02284317836165428,
            -0.014910269528627396,
            -0.021765796467661858,
            0.01973397471010685,
            0.012532438151538372,
            0.008297347463667393,
            0.024551328271627426,
            -0.01711018942296505,
            -0.02560729905962944,
            -0.027629703283309937,
            -0.006784378085285425,
            0.012271538376808167,
            -0.01484600082039833,
            0.005621112883090973,
            -0.009421217255294323,
            -0.02901851013302803,
            0.008890372700989246,
            0.018648948520421982,
            0.020239224657416344,
            -0.004920801147818565,
            0.0020110926125198603,
            0.01033971831202507,
            0.013437096029520035,
            -0.0288308784365654,
            0.0007087151752784848,
            0.02705181948840618,
            -0.02129022777080536,
            -0.028119824826717377,
            0.01773718185722828,
            5.788849011878483e-05,
            0.019000092521309853,
            -0.00556857930496335,
            -0.011659026145935059,
            0.018476063385605812,
            0.028570124879479408,
            -0.022395264357328415,
            -0.018794910982251167,
            -0.026859503239393234,
            -0.019045401364564896,
            0.02049855701625347,
            -0.02182113192975521,
            -0.024636445567011833,
            -0.013943368569016457,
            -0.011250078678131104,
            -0.014266226440668106,
            -0.006113964598625898,
            -0.024531925097107887,
            -0.008711670525372028,
            0.0031715163495391607,
            0.021553318947553635,
            0.007744600996375084,
            0.03081706166267395,
            -0.005313437897711992,
            -0.006909094750881195,
            -0.03151509165763855,
            -0.014272569678723812,
            -0.006702767685055733,
            -0.030478812754154205,
            -0.020741930231451988,
            0.0022380403243005276,
            0.0014148169429972768,
            -0.027351567521691322,
            -0.03065267950296402,
            -0.006943985819816589,
            -0.013173006474971771,
            -0.00546703115105629,
            -0.009000645019114017,
            0.006177572067826986,
            0.0022849179804325104,
            -0.02459423989057541,
            -0.0225474014878273,
            -0.02110176533460617,
            0.017631301656365395,
            0.027205774560570717,
            0.025227634236216545,
            -0.01587843708693981,
            0.0276234969496727,
            0.024822482839226723,
            -0.024957869201898575,
            -0.010174044407904148,
            0.018400492146611214,
            -0.0031461261678487062,
            -0.03198470547795296,
            0.02648037299513817,
            -0.030624033883213997,
            -0.01491121668368578,
            -0.02005290612578392,
            0.007509187329560518,
            0.011401540599763393,
            -0.016509687528014183,
            -0.008755741640925407,
            -0.005964350420981646,
            -0.020051736384630203,
            -0.023578284308314323,
            -0.02602398209273815,
            -0.0007071888539940119,
            0.007668348494917154,
            -0.0026164669543504715,
            -0.004898461047559977,
            0.01679801195859909,
            -0.0024014662485569715,
            0.010348174721002579,
            0.014434605836868286,
            -0.0022836693096905947,
            -0.001197140314616263,
            0.02034047618508339,
            -0.029654251411557198,
            0.011047237552702427,
            0.009029421955347061,
            -0.03227091208100319,
            0.01704825647175312,
            0.028058001771569252,
            0.009689846076071262,
            -0.005256603006273508,
            0.012417984195053577,
            -0.004405684769153595,
            -0.01709398813545704,
            -0.004548001568764448,
            0.025979354977607727,
            0.010110558941960335,
            0.03200031444430351,
            -0.009662602096796036,
            -0.01974394917488098,
            0.008890465833246708,
            -0.008909519761800766,
            0.0001376794243697077,
            0.0032337401062250137,
            0.005058608017861843,
            -0.027832195162773132,
            -0.00012576566950883716,
            0.00431340467184782,
            0.028960412368178368,
            -0.0007754068938083947,
            -0.008963908068835735,
            0.023226995021104813,
            -0.030062057077884674,
            0.020147191360592842,
            -0.023909758776426315,
            0.008898125030100346,
            -0.0056281727738678455,
            0.007715014740824699,
            0.019261633977293968,
            -0.03044278733432293,
            -0.024592658504843712,
            -0.01427583210170269,
            9.692873572930694e-05,
            0.012963191606104374,
            0.0246084276586771,
            0.017365744337439537,
            -0.03084855154156685,
            -0.0066597964614629745,
            -0.021153897047042847,
            0.011464674025774002,
            -0.02416508086025715,
            0.031011544167995453,
            -0.007782803848385811,
            -0.023893337696790695,
            -0.019754452630877495,
            0.01687106117606163,
            0.0010464515071362257,
            -0.02175157144665718,
            -0.014664185233414173,
            -0.024781830608844757,
            -0.028518470004200935,
            0.007080026436597109,
            0.02288990467786789,
            -0.0319085419178009,
            0.025273580104112625,
            -0.02004733309149742,
            -0.013225418515503407,
            0.02619396708905697,
            0.010346353985369205,
            0.011832297779619694,
            -0.019447628408670425,
            0.00018727027054410428,
            0.029734468087553978,
            -0.025923442095518112,
            -0.024753926321864128,
            0.002997043076902628,
            0.004935724660754204,
            -0.018559735268354416,
            0.0020332655403763056,
            -0.016843456774950027,
            -0.012009954079985619,
            0.018117224797606468,
            0.005428279750049114,
            -0.013297798112034798,
            -0.021696871146559715,
            0.0014446309069171548,
            0.016035661101341248,
            -0.0042974925599992275,
            -0.015132264234125614,
            0.012056042440235615,
            -0.026212483644485474,
            -0.03203284367918968,
            -0.00521516241133213,
            -0.016345713287591934,
            -0.01241481676697731,
            -0.015358546748757362,
            -0.01943851448595524,
            -0.01471173670142889,
            0.005394062027335167,
            0.003736319486051798,
            -0.020625140517950058,
            -0.0063304603099823,
            -0.02681819535791874,
            -0.006480663549154997,
            0.028957409784197807,
            0.00414289440959692,
            0.013814825564622879,
            0.02810945361852646,
            0.015908436849713326,
            -0.009675145149230957,
            -4.429652835824527e-05,
            0.025753861293196678,
            -0.03227723017334938,
            0.01610233075916767,
            -0.017996054142713547,
            -0.01726708747446537,
            -0.009149917401373386,
            -0.00851064920425415,
            0.01287398673593998,
            0.02506708726286888,
            0.013693900778889656,
            0.0014671101234853268,
            0.009388967417180538,
            -0.019043534994125366,
            -0.0015252144075930119,
            -0.01593732088804245,
            -0.018931791186332703,
            -0.0050842356868088245,
            0.008974225260317326,
            -0.029278108850121498,
            -0.005558673292398453,
            0.009152268059551716,
            -0.013738571666181087,
            -0.00875873677432537,
            0.007817819714546204,
            -0.018454302102327347,
            0.0018241619691252708,
            0.008066490292549133,
            0.01663457602262497,
            -0.014345028437674046,
            0.022951703518629074,
            -0.008899055421352386,
            -0.015061263926327229,
            -0.0022240951657295227,
            0.00918496772646904,
            0.029150119051337242,
            -0.0004025239322800189,
            0.018632104620337486,
            -0.020048104226589203,
            0.003085203468799591,
            -0.027073416858911514,
            0.0004542141396086663,
            0.000729779654648155,
            -0.006952471099793911,
            0.015010974369943142,
            -0.0111690079793334,
            0.003319256240502,
            -0.019385207444429398,
            0.011859625577926636,
            -0.022941511124372482,
            0.029454097151756287,
            -0.0007010027766227722,
            -0.006513198837637901,
            -0.005488811060786247,
            0.013401876203715801,
            0.00016597582725808024,
            -0.013266868889331818,
            0.0008676461293362081,
            0.017243631184101105,
            -0.023674143478274345,
            -0.013563687913119793,
            0.011799986474215984,
            -0.02584797516465187,
            -0.0015715964836999774,
            0.030346430838108063,
            -0.023808002471923828,
            0.023503122851252556,
            -0.0009258406353183091,
            0.030575234442949295,
            0.02119937725365162,
            -0.010470367036759853,
            -0.014355920255184174,
            -0.01426518615335226,
            -0.019667651504278183,
            -0.020282365381717682,
            0.00954418908804655,
            0.01562431175261736,
            0.02797408029437065,
            0.028624892234802246,
            -0.02171763777732849,
            0.027333565056324005,
            0.006736057810485363,
            -0.012356498278677464,
            0.012538472190499306,
            -0.028837505728006363,
            -0.02634548209607601,
            -0.00849794689565897,
            -0.019160829484462738,
            -0.022926025092601776,
            -0.014879202470183372,
            -0.008579972200095654,
            -0.011427752673625946,
            -0.02031182311475277,
            -0.018283775076270103,
            0.004446001723408699,
            -0.018940668553113937,
            0.013093111105263233,
            0.029669715091586113,
            -0.01990080624818802,
            0.023625312373042107,
            -0.011633259244263172,
            -0.014955195598304272,
            -0.016309134662151337,
            0.025906674563884735,
            0.003012410830706358,
            -0.026911968365311623,
            -0.017913244664669037,
            -0.026867911219596863,
            -0.028710003942251205,
            0.0059308577328920364,
            0.010980772785842419,
            0.01804884895682335,
            -0.02274811640381813,
            0.01916969008743763,
            -0.01015295460820198,
            -0.004463445860892534,
            -0.0012041799491271377,
            0.02432626485824585,
            -0.020179200917482376,
            -0.022248974069952965,
            -0.0053018927574157715,
            -0.0028138759080320597,
            -0.004295669961720705,
            -0.0183961633592844,
            0.024324389174580574,
            -0.03192438930273056,
            0.017398251220583916,
            0.0043606325052678585,
            0.006461321376264095,
            0.018563561141490936,
            0.021145612001419067,
            -0.019989285618066788,
            0.02873796597123146,
            0.023290524259209633,
            0.005178504157811403,
            -0.021821895614266396,
            0.01722044125199318,
            -0.012136265635490417,
            -0.021199991926550865,
            -0.028718136250972748,
            -0.009721423499286175,
            0.0116813275963068,
            0.014766054227948189,
            -0.02310950867831707,
            -0.005488548427820206,
            -0.011831318028271198,
            0.023209361359477043,
            -0.02267467975616455,
            0.013855699449777603,
            -0.004652203060686588,
            -0.0247359499335289,
            0.021739931777119637,
            0.005222659558057785,
            -0.011344756931066513,
            -0.028284955769777298,
            0.010325964540243149,
            -0.020420938730239868,
            0.02006511390209198,
            -0.005385354161262512,
            -0.009068595245480537,
            -0.0015425918390974402,
            0.01845768839120865,
            -0.017267193645238876,
            0.008212734945118427,
            0.02577115036547184,
            -0.006295239087194204,
            -0.017655422911047935,
            0.010476751253008842,
            -0.02463381364941597,
            0.008455188013613224,
            0.01825488917529583,
            0.015204343013465405,
            -0.012272819876670837,
            -0.029145505279302597,
            0.012154201976954937,
            -0.013841057196259499,
            -0.01716672070324421,
            -0.006709787994623184,
            -0.0052560605108737946,
            -0.003324981778860092,
            -0.012143165804445744,
            -0.00464309798553586,
            -0.027431275695562363,
            -0.02341582253575325,
            -0.0146583691239357,
            0.02389797568321228,
            0.002207283629104495,
            0.01184906531125307,
            -0.02026323229074478,
            -0.028485063463449478,
            0.012873372063040733,
            0.024756895378232002,
            0.013077947311103344,
            -0.0026593850925564766,
            0.006672440562397242,
            0.008782511577010155,
            0.027737006545066833,
            -0.01802014373242855,
            -0.006654308643192053,
            -0.01106769498437643,
            0.010477364994585514,
            -0.03020642139017582,
            0.027416836470365524,
            -0.023487944155931473,
            -0.007119156885892153,
            -0.014642507769167423,
            -0.0003258716897107661,
            -0.0015284839319065213,
            0.01646335981786251,
            -0.0071398415602743626,
            0.019733833149075508,
            -0.015615868382155895,
            -0.030515071004629135,
            0.01823953539133072,
            -0.00500923627987504,
            0.013319028541445732,
            -0.000944490369874984,
            -0.0038100220263004303,
            -0.024048542603850365,
            -0.03108484111726284,
            -0.02551734447479248,
            -0.012411484494805336,
            0.013056323863565922,
            -0.007103805895894766,
            -0.01563606783747673,
            0.01505071111023426,
            -0.022256776690483093,
            -0.028734369203448296,
            0.020808640867471695,
            0.0165098849684,
            -0.02500496432185173,
            0.009756278246641159,
            -0.0027518474962562323,
            -0.014779625460505486,
            -0.02605457417666912,
            0.018540356308221817,
            0.017560990527272224,
            0.028816521167755127,
            -0.023993460461497307,
            0.014942522160708904,
            0.0057968017645180225,
            0.0021813511848449707,
            0.02877841517329216,
            0.0028015905991196632,
            -0.008455597795546055,
            0.010747615247964859,
            -0.016252269968390465,
            -0.02787562645971775,
            -0.01080253068357706,
            -0.009784298948943615,
            0.014536693692207336,
            -0.0004915962927043438,
            -0.003992170095443726,
            0.02019944228231907,
            -0.01181851141154766,
            0.0017855504993349314,
            -0.021967127919197083,
            -0.004902985878288746,
            -0.013079419732093811,
            -0.0010154411429539323,
            -0.02650773711502552,
            0.020850008353590965,
            0.013783926144242287,
            -0.018146401271224022,
            -0.022143740206956863,
            0.014317784458398819,
            -0.027310118079185486,
            0.024435270577669144,
            0.012675076723098755,
            -0.020941032096743584,
            0.011645534075796604,
            0.016386618837714195,
            -0.031233174726366997,
            0.0011772530851885676,
            -0.008204768411815166,
            0.02681747078895569,
            -0.005408296827226877,
            0.029610389843583107,
            -0.01929401606321335,
            0.02003253437578678,
            0.020936548709869385,
            0.017679831013083458,
            0.02887251414358616,
            0.022899312898516655,
            -0.026189137250185013,
            0.004907186608761549,
            -0.027290627360343933,
            -0.01893828622996807,
            0.014603947289288044,
            0.029066704213619232,
            -0.004605401307344437,
            0.024437827989459038,
            0.004280660301446915,
            -0.00010494866000954062,
            0.004686616361141205,
            -0.02748688869178295,
            0.012486462481319904,
            -0.010670655407011509,
            0.025650938972830772,
            -0.006001810543239117,
            0.017005886882543564,
            0.0002215374552179128,
            -0.016860172152519226,
            -0.004261410795152187,
            0.02097388356924057,
            0.027824608609080315,
            -0.025089100003242493,
            -0.031677357852458954,
            0.0032397937029600143,
            -0.02336990460753441,
            -0.006677194498479366,
            0.017489714547991753,
            -0.006641944404691458,
            -0.02851027250289917,
            -0.013705234974622726,
            0.017658356577157974,
            -0.014688102528452873,
            0.02673233114182949,
            -0.028649233281612396,
            0.013366865925490856,
            0.02565114200115204,
            0.018966704607009888,
            0.020639432594180107,
            -0.0087444968521595,
            0.01361805759370327,
            0.007752900943160057,
            -0.00791411567479372,
            0.005358621012419462,
            -0.004745406098663807,
            0.01735054887831211,
            -0.018006455153226852,
            0.0012908466160297394,
            0.011661317199468613,
            0.02139151468873024,
            -0.009780746884644032,
            0.004831766709685326,
            -0.003970274236053228,
            0.008639940060675144,
            -0.00959110539406538,
            -0.00893696304410696,
            0.010792813263833523,
            0.0051633622497320175,
            0.022226981818675995,
            0.006505834870040417,
            0.01643393561244011,
            0.004434430506080389,
            -0.014683212153613567,
            -0.032080162316560745,
            0.005464585963636637,
            0.0007492434815503657,
            -0.022384416311979294,
            -0.008917424827814102,
            -0.028545696288347244,
            -0.004859606735408306,
            0.0031882349867373705,
            -0.008371356874704361,
            -0.01280390378087759,
            0.023080922663211823,
            -0.0047181942500174046,
            -0.006997019052505493,
            0.01766783744096756,
            -0.012692089192569256,
            -0.0031126718968153,
            -0.029891004785895348,
            -0.004737618379294872,
            0.029983922839164734,
            0.007878749631345272,
            0.026339439675211906,
            -0.0098770372569561,
            -0.00047247574548237026,
            0.01489853486418724,
            0.020754501223564148,
            0.02121053636074066,
            -0.012462141923606396,
            -0.027685413137078285,
            -0.02854776382446289,
            -0.0029826334211975336,
            0.0018066748743876815,
            0.007475049234926701,
            -0.009001832455396652,
            -0.029230894520878792,
            0.024750014767050743,
            0.0012148990062996745,
            0.013792174868285656,
            -0.002379285404458642,
            0.021445445716381073,
            0.0063254390843212605,
            -0.00464272778481245,
            -0.015029835514724255,
            -0.001151998876594007,
            0.008925290778279305,
            -0.010815544053912163,
            -0.005319039337337017,
            -0.007972405292093754,
            0.02202940359711647,
            -0.03264534845948219,
            -0.022376781329512596,
            0.006540830247104168,
            0.026789546012878418,
            0.012328647077083588,
            -0.01585635170340538,
            0.026885101571679115,
            0.018704449757933617,
            -0.006690770387649536,
            0.013806234113872051,
            -0.025598501786589622,
            -0.007131821941584349,
            0.01628137193620205,
            0.008175081573426723,
            -0.02273326925933361,
            0.020619742572307587,
            -0.03142973408102989,
            -0.019916826859116554,
            -0.02251310832798481,
            0.001503942534327507,
            -0.02724362351000309,
            -0.013335666619241238,
            -0.015449212864041328,
            0.026672974228858948,
            0.013085506856441498,
            0.021559035405516624,
            0.010614714585244656,
            0.024996938183903694,
            0.027309179306030273,
            0.025210587307810783,
            0.021203620359301567,
            -0.006265903823077679,
            -0.0103987455368042,
            0.017430104315280914,
            -0.0019659348763525486,
            0.0007140157395042479,
            -0.03038913384079933,
            -0.008117953315377235,
            0.02034524269402027,
            -0.016986791044473648,
            0.026108311489224434,
            0.021558580920100212,
            -0.021051114425063133,
            0.015386589802801609,
            0.010102270171046257,
            -0.0019739908166229725,
            -0.017373500391840935,
            -0.030276672914624214,
            0.005194405559450388,
            -0.01199269201606512,
            -0.019274674355983734,
            0.016552366316318512,
            -0.006232652813196182,
            0.030115287750959396,
            0.019664397463202477,
            -0.03222912177443504,
            -0.003797447308897972,
            0.013996056281030178,
            -0.03025159239768982,
            -0.0026282116305083036,
            -0.01923588663339615,
            0.020670026540756226,
            0.02607068233191967,
            0.008731167763471603,
            -0.012993116863071918,
            0.0006844637100584805,
            -0.02009383589029312,
            -0.009121500886976719,
            -0.01491519808769226,
            0.013177290558815002,
            -0.0008030560566112399,
            0.028754401952028275,
            0.0003213731979485601,
            -0.021155403926968575,
            0.01800166629254818,
            0.025551477447152138,
            -0.0028435641434043646,
            -0.03043975867331028,
            0.0281080212444067,
            -0.003245568834245205,
            0.004621455445885658,
            -0.007266112137585878,
            0.020544858649373055,
            -0.006346081383526325,
            0.01899329014122486,
            -0.003692091442644596,
            -0.0046088979579508305,
            -0.013302739709615707,
            0.009027406573295593,
            0.008954689837992191,
            -0.028623808175325394,
            -0.0018315998604521155,
            -0.001961745088919997,
            -0.02652919851243496,
            -0.02420894242823124,
            -0.012742524966597557,
            0.028066230937838554,
            0.0006126016960479319,
            -0.008137060329318047,
            -0.0035026390105485916,
            0.017643503844738007,
            0.017227275297045708,
            0.011699859984219074,
            -0.02747919037938118,
            -0.005333553068339825,
            -0.004510566126555204,
            0.00041453319136053324,
            0.027470771223306656,
            -0.02751811221241951,
            0.009802025742828846,
            0.013799123466014862,
            0.0276023019105196,
            -0.01743754744529724,
            0.029159292578697205,
            0.025273790583014488,
            0.028096649795770645,
            0.011932699009776115,
            0.014087813906371593,
            -0.004387889057397842,
            0.01613173447549343,
            -0.012291593477129936,
            -0.021982578560709953,
            0.011231988668441772,
            -0.008914753794670105,
            0.025066401809453964,
            -0.0119937127456069,
            -0.022667622193694115,
            -0.0214545838534832,
            -0.020939191803336143,
            -0.02235817350447178,
            0.02651284448802471,
            0.029621725901961327,
            -0.005643405485898256,
            0.01977701112627983,
            0.010005761869251728,
            -0.030888326466083527,
            0.02100546844303608,
            0.028378278017044067,
            0.0004805543867405504,
            0.023897558450698853,
            0.012006076984107494,
            0.009143747389316559,
            0.0025255493819713593,
            -0.00019879582396242768,
            -0.023829078301787376,
            -0.0017466547433286905,
            0.02976243570446968,
            -0.003225588006898761,
            0.012500409036874771,
            -0.007582042366266251,
            0.015902109444141388,
            -0.026238471269607544,
            0.015077362768352032,
            -0.019057197496294975,
            -0.011923102661967278,
            -0.00644603231921792,
            -0.017944876104593277,
            -0.001858241856098175,
            -0.02968314290046692,
            -0.004952325019985437,
            -0.013330717571079731,
            -0.0037341241259127855,
            0.02085837908089161,
            -0.0269822645932436,
            -0.0007455244194716215,
            -0.03129518777132034,
            0.02012045867741108,
            0.0008475984213873744,
            0.028140034526586533,
            -0.009063559584319592,
            0.009951968677341938,
            0.0012769039021804929,
            -0.015398911200463772,
            0.022956565022468567,
            -0.0012613385915756226,
            -0.006676785182207823,
            0.009577185846865177,
            0.009048091247677803,
            -0.030480576679110527,
            0.0032699245493859053,
            -0.0288783460855484,
            -0.023708444088697433,
            0.022435054183006287,
            0.0033403942361474037,
            -0.022101663053035736,
            0.012368839234113693,
            0.00236675888299942,
            0.0019978429190814495,
            -0.0009523682529106736,
            0.021621223539114,
            -0.029357947409152985,
            -0.026894407346844673,
            0.017065636813640594,
            -0.01602029986679554,
            0.02276936173439026,
            -0.03160182386636734,
            -0.005822821520268917,
            -0.01572812907397747,
            0.009677099995315075,
            -0.022176144644618034,
            0.02014049142599106,
            -0.029326889663934708,
            0.02927984669804573,
            0.003689608070999384,
            -0.0024893491063266993,
            -0.022146668285131454,
            0.0037304479628801346,
            -0.019609354436397552,
            -0.030852898955345154,
            0.008909013122320175,
            0.014560848474502563,
            0.025026045739650726,
            0.02868899516761303,
            -0.009532100521028042,
            -0.028151847422122955,
            0.009246587753295898,
            0.021334964781999588,
            0.002069610869511962,
            -0.025595461949706078,
            0.015546025708317757,
            0.026755472645163536,
            -0.003571111708879471,
            -0.021080249920487404,
            0.018917417153716087,
            0.0009777841623872519,
            0.0040709348395466805,
            -0.01488728541880846,
            -0.026515059173107147,
            -0.010950995609164238,
            0.0025253042113035917,
            -0.007568310480564833,
            0.026696013286709785,
            -0.003138192929327488,
            -0.0047804443165659904,
            0.01952996477484703,
            -0.00030468107433989644,
            0.02224031090736389,
            -0.019342096522450447,
            -0.007843299768865108,
            0.0033798031508922577,
            -0.029312649741768837,
            0.015591247007250786,
            0.009174567647278309,
            -0.021552421152591705,
            0.020542562007904053,
            0.025476092472672462,
            0.0033860725816339254,
            -0.011711277067661285,
            0.00494508258998394,
            -0.02534865215420723,
            0.012715213932096958,
            -0.00874034222215414,
            0.027224794030189514,
            0.023137196898460388,
            0.019269216805696487,
            0.024306437000632286,
            -0.0003635366738308221,
            -0.01316637359559536,
            -0.005486409645527601,
            -0.023849857971072197,
            -0.021239042282104492,
            -0.028092239052057266,
            0.008919119834899902,
            0.02230255678296089,
            -0.026478497311472893,
            0.021570783108472824,
            -0.0008506019366905093,
            -0.014828532002866268,
            -0.010109842754900455,
            0.02643076330423355,
            -0.020464228466153145,
            0.011064907535910606,
            -0.024443306028842926,
            0.008219084702432156,
            0.02567046321928501,
            0.014621159993112087,
            0.011588186025619507,
            -0.002637606579810381,
            -0.017912358045578003,
            0.020513875409960747,
            -0.02394554577767849,
            0.003966143354773521,
            0.02230667881667614,
            -0.022797999903559685,
            0.008197507821023464,
            0.016653023660182953,
            -0.01089344173669815,
            -0.02839643321931362,
            -0.0029349608812481165,
            -0.0006068350630812347,
            -0.008048954419791698,
            -0.02446550689637661,
            0.02434311993420124,
            0.013725321739912033,
            0.005076068919152021,
            -0.024241536855697632,
            0.013722033239901066,
            -0.00844642799347639,
            -0.016476692631840706,
            -0.015886040404438972,
            0.0014738531317561865,
            -0.0015233412850648165,
            0.019617469981312752,
            0.0034141994547098875,
            0.02615162916481495,
            0.0010606693103909492,
            0.003228581277653575,
            -0.0006721712416037917,
            0.012591540813446045,
            0.018527645617723465,
            0.02869710512459278,
            -0.03112012892961502,
            0.028312310576438904,
            0.023751329630613327,
            -0.014782743528485298,
            0.005815146490931511,
            -0.008008136413991451,
            0.023481322452425957,
            0.023562777787446976,
            -0.0035801343619823456,
            0.01132785901427269,
            0.01116522029042244,
            0.019186364486813545,
            -0.003687647869810462,
            0.014914575032889843,
            0.010504995472729206,
            -0.0019424185156822205,
            0.02621227502822876,
            0.011608445085585117,
            -0.004019274841994047,
            0.025514118373394012,
            -0.002054827753454447,
            -0.00734342448413372,
            -0.0230136476457119,
            -0.00374422175809741,
            -0.028591733425855637,
            -0.0017810119315981865,
            -0.03164932504296303,
            -0.008144949562847614,
            0.023183291777968407,
            -0.027042698115110397,
            -0.013212923891842365,
            -0.004832438193261623,
            0.024440938606858253,
            0.01367094460874796,
            0.01897595077753067,
            -0.0036061133723706007,
            -0.025698047131299973,
            -0.016378402709960938,
            -0.016307231038808823,
            -0.02670574188232422,
            -0.021448565647006035,
            -0.01907796785235405,
            -0.00969689805060625,
            0.01747785322368145,
            0.011517793871462345,
            0.0035305318888276815,
            0.006243131123483181,
            0.031616292893886566,
            -0.0286981463432312,
            -0.023641079664230347,
            0.02256939187645912,
            -0.009024534374475479,
            -0.019217466935515404,
            0.024721017107367516,
            -0.012662610039114952,
            -0.005355139262974262,
            -0.011080898344516754,
            -0.01587836444377899,
            0.0159413143992424,
            -0.023879515007138252,
            -0.006652146577835083,
            0.030573474243283272,
            -0.006858615204691887,
            0.011288303881883621,
            0.007109729573130608,
            -0.008157361298799515,
            -0.004783725365996361,
            -0.00518028624355793,
            -0.0006881689769215882,
            -0.010861707851290703,
            -0.032120611518621445,
            -0.0013849983224645257,
            -0.027098260819911957,
            0.02722601220011711,
            -0.00397937186062336
        ],
        [
            0.010774689726531506,
            0.01530579011887312,
            0.018260082229971886,
            0.026925090700387955,
            -0.014879151247441769,
            -0.06321054697036743,
            -0.02186177857220173,
            -0.048604391515254974,
            -0.017304597422480583,
            0.025911906734108925,
            0.012254113331437111,
            0.033386316150426865,
            0.022267255932092667,
            0.03795068711042404,
            -0.004521916154772043,
            0.011686082929372787,
            -0.022102223709225655,
            0.022675098851323128,
            0.023524025455117226,
            -0.008585452102124691,
            -0.02080494910478592,
            -0.005681151524186134,
            -0.05459308624267578,
            -0.026408037170767784,
            -0.02350354753434658,
            0.014803907833993435,
            0.038120947778224945,
            -0.02445572428405285,
            0.0036842564586549997,
            0.024183867499232292,
            -0.03346516191959381,
            -0.034482166171073914,
            0.021029679104685783,
            -0.0022164073307067156,
            0.028110122308135033,
            -0.011717346496880054,
            -0.01360260508954525,
            0.02422882802784443,
            0.01927727460861206,
            0.008264239877462387,
            -0.022228537127375603,
            -0.027212368324398994,
            0.011883441358804703,
            0.008883126080036163,
            -0.0227882731705904,
            -0.007237935438752174,
            -0.01367153599858284,
            0.0762295126914978,
            0.0016695116646587849,
            -0.02166963368654251,
            -0.013766678981482983,
            -0.033226627856492996,
            0.04489821568131447,
            0.013299010694026947,
            -0.036883629858493805,
            -0.0020267039071768522,
            -0.039567120373249054,
            -0.019479909911751747,
            0.016974152997136116,
            0.03047504648566246,
            -0.006313003599643707,
            0.016114946454763412,
            -0.011249152012169361,
            0.0014498509699478745,
            -0.04649437591433525,
            -0.006171955727040768,
            0.020951956510543823,
            -0.015321558341383934,
            -0.02373693324625492,
            0.029799412935972214,
            -0.004003193695098162,
            -0.022305067628622055,
            0.01715952157974243,
            0.011126130819320679,
            0.006520726252347231,
            0.01714375615119934,
            -0.010488193482160568,
            -0.04713740944862366,
            0.029019171372056007,
            -0.016565963625907898,
            0.004398132674396038,
            -0.01899755373597145,
            0.01933240331709385,
            -0.020568404346704483,
            -0.04054143279790878,
            0.034647900611162186,
            0.023070408031344414,
            -0.04610695317387581,
            0.009186416864395142,
            -0.016477681696414948,
            0.009455648250877857,
            0.030061636120080948,
            0.009847619570791721,
            -0.04116055369377136,
            -0.0009421829017810524,
            -0.001207213383167982,
            -0.02890263870358467,
            -0.04838962480425835,
            -0.034324269741773605,
            -0.02072690799832344,
            -0.018124883994460106,
            -0.022407490760087967,
            -0.007762058638036251,
            0.020936429500579834,
            -0.021014250814914703,
            -0.01949787698686123,
            0.0023216267582029104,
            -0.00525273010134697,
            -0.0696253553032875,
            0.01691626012325287,
            0.004984594881534576,
            -0.005540007725358009,
            -0.01917423866689205,
            -0.009373950771987438,
            0.0240559633821249,
            0.01326847542077303,
            0.003570609726011753,
            -0.01864011213183403,
            -0.022913292050361633,
            0.026144862174987793,
            -0.013560646213591099,
            -0.008636325597763062,
            0.0006829822086729109,
            -0.023294782266020775,
            -0.04081223905086517,
            0.009008859284222126,
            -0.07766212522983551,
            0.009089058265089989,
            0.004321557469666004,
            0.0024499690625816584,
            0.04084073007106781,
            0.006922716274857521,
            -0.04585768282413483,
            -0.02315049059689045,
            0.022899936884641647,
            0.016858743503689766,
            -0.028379324823617935,
            -0.022099077701568604,
            0.028570981696248055,
            0.02148544415831566,
            -0.02804979495704174,
            -0.020950371399521828,
            0.01819864846765995,
            0.0362488217651844,
            -0.03636682778596878,
            0.035486701875925064,
            0.03450634330511093,
            0.006908386014401913,
            0.03334398940205574,
            -0.014527994208037853,
            0.007397701498121023,
            0.03663821145892143,
            0.020813195034861565,
            0.02460164949297905,
            0.020419131964445114,
            -0.005421333014965057,
            -0.02126770094037056,
            0.013823795132339,
            -0.0034182448871433735,
            -0.004526806529611349,
            -0.003314750734716654,
            0.012179483659565449,
            0.007154380436986685,
            -0.0006833833758719265,
            -0.008399722166359425,
            0.021038377657532692,
            -0.04062502458691597,
            -0.010552486404776573,
            -0.0378190279006958,
            -0.0037113844882696867,
            0.01182169932872057,
            -0.011763229966163635,
            0.01148189790546894,
            0.005781525745987892,
            -0.007608478423207998,
            0.018710248172283173,
            -0.010306385345757008,
            -0.0009455942781642079,
            -0.08079123497009277,
            -0.029994411394000053,
            -0.0021927724592387676,
            -0.01037079282104969,
            0.019302072003483772,
            -0.021578308194875717,
            -0.00312229385599494,
            -0.008200123906135559,
            0.013362389989197254,
            0.029820771887898445,
            0.014049223624169827,
            0.00510011101141572,
            0.0031970120035111904,
            0.02175695262849331,
            -0.044293198734521866,
            0.025762805715203285,
            0.009264142252504826,
            0.006414486560970545,
            -0.03076828457415104,
            0.02371698059141636,
            0.009990151971578598,
            0.027617819607257843,
            -0.019572868943214417,
            -0.025089498609304428,
            0.029895702376961708,
            0.033257074654102325,
            -0.030574798583984375,
            -0.004706215113401413,
            -0.021054532378911972,
            0.01243514008820057,
            0.0003521585022099316,
            -0.015160304494202137,
            0.016466151922941208,
            0.008906962350010872,
            -0.023977385833859444,
            0.01860535517334938,
            0.0038070015143603086,
            0.02208813466131687,
            0.01769152097404003,
            -0.0013961010845378041,
            0.024848612025380135,
            0.009050223045051098,
            -0.020824121311306953,
            -0.0010787470964714885,
            0.05502774193882942,
            -0.003694686572998762,
            -0.003910766914486885,
            -0.010221699252724648,
            0.02649194374680519,
            -0.008519867435097694,
            -0.04076267033815384,
            -0.027154501527547836,
            -0.013277791440486908,
            -0.04246046021580696,
            0.011900113895535469,
            -0.021157147362828255,
            -0.04073375463485718,
            0.008291438221931458,
            0.0907447561621666,
            -0.014468361623585224,
            -0.014894400723278522,
            -0.004600638523697853,
            -0.021679634228348732,
            0.0070196534506976604,
            0.03207654133439064,
            -0.016412805765867233,
            0.014682045206427574,
            0.019919171929359436,
            0.00797763280570507,
            -0.016588866710662842,
            0.024947822093963623,
            0.009992687962949276,
            0.011577443219721317,
            0.011068413965404034,
            -0.0717369019985199,
            -0.01615266129374504,
            0.006222181022167206,
            0.005930058192461729,
            0.0011628782376646996,
            0.009394571185112,
            -0.02828054688870907,
            0.009557330049574375,
            0.047105573117733,
            -0.016534868627786636,
            -0.08131302893161774,
            0.005009890999644995,
            -0.0017235858831554651,
            -0.01940195821225643,
            -0.017068708315491676,
            0.02676023729145527,
            -0.0041000270284712315,
            0.012575041502714157,
            0.007859422825276852,
            0.0449991337954998,
            0.002053692704066634,
            -0.04109574854373932,
            -0.001019695308059454,
            0.011906004510819912,
            0.012974427081644535,
            -0.011774541810154915,
            -0.04795215278863907,
            0.03555243834853172,
            -0.042378563433885574,
            -0.019197212532162666,
            -0.003086462849751115,
            -0.010490857996046543,
            0.015997711569070816,
            -0.018228713423013687,
            0.004109383560717106,
            0.024440227076411247,
            -0.008533155545592308,
            0.005616961047053337,
            0.0031657216604799032,
            0.03672509267926216,
            0.005582224577665329,
            -0.007764326874166727,
            -0.0188142117112875,
            -0.006312085315585136,
            0.011457963846623898,
            0.03615297004580498,
            -0.06891139596700668,
            -0.002829675329849124,
            -0.03988993912935257,
            -0.013841699808835983,
            0.013939056545495987,
            0.03992243483662605,
            -0.006422911770641804,
            0.030485210940241814,
            0.0030818977393209934,
            0.04937318339943886,
            0.020779281854629517,
            0.02748599648475647,
            0.037497226148843765,
            0.00838748924434185,
            0.001467423397116363,
            -0.009291081689298153,
            -0.0036419159732759,
            0.03347198665142059,
            0.022457851096987724,
            0.008230704814195633,
            -0.02962563745677471,
            -0.021307501941919327,
            -0.0020618741400539875,
            0.009454016573727131,
            -0.015439300797879696,
            -0.039395008236169815,
            0.018753506243228912,
            -0.009026608429849148,
            0.023865239694714546,
            0.02940467558801174,
            -0.06073885038495064,
            0.018895896151661873,
            -0.0397532694041729,
            0.03393625095486641,
            -0.014149252325296402,
            0.006199032533913851,
            0.015236161649227142,
            -0.0009142889175564051,
            0.008816393092274666,
            0.007789808325469494,
            -0.03165973350405693,
            0.00326364254578948,
            -0.01019639428704977,
            0.0015938730211928487,
            -0.006365513429045677,
            0.030947865918278694,
            -0.032293133437633514,
            -0.013841821812093258,
            0.06616461277008057,
            -0.030180079862475395,
            0.011189502663910389,
            -0.04104490205645561,
            0.020036369562149048,
            0.004346383269876242,
            0.004941347986459732,
            0.01324380375444889,
            -0.03723016008734703,
            -0.017364371567964554,
            -0.005384115502238274,
            0.048782844096422195,
            0.01511408668011427,
            -0.0027626960072666407,
            -0.0008957782993093133,
            0.018212730064988136,
            0.010136688128113747,
            0.011540028266608715,
            0.020430075004696846,
            0.012021091766655445,
            -0.01690535433590412,
            -0.02025161124765873,
            -0.0006808674079366028,
            -0.014003017917275429,
            0.011598121374845505,
            -0.026611734181642532,
            -0.0020687791984528303,
            -0.052563875913619995,
            -0.023153306916356087,
            -0.022281790152192116,
            -0.004829869605600834,
            0.015037241391837597,
            0.017118239775300026,
            0.02728213369846344,
            0.028609441593289375,
            0.010309889912605286,
            -0.01904482953250408,
            0.015218338929116726,
            -0.012730751186609268,
            -0.07020968198776245,
            -0.015727180987596512,
            -0.0029319992754608393,
            0.016400745138525963,
            0.011571768671274185,
            -0.04494247958064079,
            -0.020026570186018944,
            0.025396205484867096,
            -0.025866683572530746,
            -0.001052595442160964,
            -0.013185219839215279,
            -0.028901904821395874,
            0.00045365962432697415,
            0.0035717301070690155,
            -0.029953045770525932,
            0.07376910001039505,
            -0.006382090505212545,
            -0.01862267591059208,
            0.0309317447245121,
            -0.03162810578942299,
            0.02265743352472782,
            0.02153133600950241,
            0.03404156118631363,
            -0.012072120793163776,
            -0.02602853998541832,
            -0.023432081565260887,
            0.016397610306739807,
            0.010187098756432533,
            -0.04178450629115105,
            0.0028013905975967646,
            -0.013964269310235977,
            -0.005179605912417173,
            0.03911551088094711,
            0.015152696520090103,
            0.03171244636178017,
            -0.03680159151554108,
            0.022616339847445488,
            0.07280252128839493,
            -0.0014063880080357194,
            -0.031131409108638763,
            -0.03946991637349129,
            -0.07110800594091415,
            0.006079486571252346,
            -0.020835325121879578,
            -0.04545356333255768,
            0.007028577849268913,
            -0.0353701189160347,
            0.03870855271816254,
            0.02470444142818451,
            0.025403641164302826,
            0.023654568940401077,
            -0.06694193184375763,
            -0.013592938892543316,
            -0.024652455002069473,
            0.03888922557234764,
            -0.016482675448060036,
            -0.011702739633619785,
            0.00339505891315639,
            -0.019222212955355644,
            0.033969245851039886,
            -0.0019985432736575603,
            0.019595859572291374,
            -0.06835339963436127,
            0.004775378853082657,
            0.006048375274986029,
            0.001971339574083686,
            0.020968375727534294,
            0.006083974149078131,
            0.011340056546032429,
            0.05216696485877037,
            -0.007182582281529903,
            -0.0356910414993763,
            0.0005908091552555561,
            0.008828741498291492,
            0.028500109910964966,
            -0.05725277215242386,
            -0.006828546989709139,
            -0.049730606377124786,
            -0.011471453122794628,
            0.011984524317085743,
            -0.01950838603079319,
            0.020948093384504318,
            -0.031008001416921616,
            0.02485613524913788,
            -0.00746353343129158,
            0.005374331027269363,
            0.007471674587577581,
            0.004834053106606007,
            0.00547921285033226,
            0.0035613165237009525,
            0.014298559166491032,
            0.0016798797296360135,
            -0.0260586217045784,
            -0.0101588424295187,
            -0.009955532848834991,
            -0.02720554545521736,
            -0.003262229496613145,
            -0.013918096199631691,
            0.022250905632972717,
            0.019061710685491562,
            0.030802400782704353,
            0.007335923612117767,
            -0.007477808278053999,
            0.010011973790824413,
            -0.04720144718885422,
            -0.008202029392123222,
            0.0334053598344326,
            -0.027882862836122513,
            -0.04893343895673752,
            0.01433240994811058,
            -0.013329901732504368,
            0.03404291346669197,
            -0.011495182290673256,
            -0.016595078632235527,
            0.0313497893512249,
            -0.014548932202160358,
            0.0019901052583009005,
            -0.017251934856176376,
            0.030448509380221367,
            -0.006222780793905258,
            0.006647849455475807,
            -0.014603068120777607,
            0.02577548660337925,
            -0.0009219018393196166,
            0.006186412647366524,
            -0.03392566367983818,
            -0.010611067526042461,
            -0.022143177688121796,
            0.006572586018592119,
            -0.02836509235203266,
            0.011602243408560753,
            -0.005861492361873388,
            -0.0054622432217001915,
            0.011939363554120064,
            -0.018185613676905632,
            0.0064690327271819115,
            0.031089134514331818,
            0.006151105277240276,
            -0.0011641246965155005,
            -0.06642064452171326,
            0.035273898392915726,
            -0.08803711086511612,
            -0.013914375565946102,
            -0.027039557695388794,
            -0.028805986046791077,
            -0.03523902967572212,
            0.027722423896193504,
            -0.012917944230139256,
            0.004586396738886833,
            -0.01090349443256855,
            0.010215829126536846,
            0.026729075238108635,
            -0.010394499637186527,
            -0.04137319698929787,
            -0.005056560039520264,
            -0.006119735073298216,
            0.0027266673278063536,
            0.025302181020379066,
            -0.008890180848538876,
            -0.01782016083598137,
            -0.02430342696607113,
            -0.017985250800848007,
            0.019759634509682655,
            -0.015396741218864918,
            0.035767700523138046,
            0.023637983947992325,
            0.022935526445508003,
            0.006521511822938919,
            0.03504221513867378,
            -0.011731023900210857,
            0.017038773745298386,
            -0.023196043446660042,
            0.01810000091791153,
            -0.013670550659298897,
            -0.030503584071993828,
            -0.0374695360660553,
            -0.0020479336380958557,
            -0.02825682796537876,
            -0.036040667444467545,
            0.02668140083551407,
            0.017122521996498108,
            -0.011472737416625023,
            0.02768099680542946,
            0.0005377852357923985,
            -0.052709922194480896,
            -0.0003042472235392779,
            -0.03582620620727539,
            -0.003414770355448127,
            -0.026605235412716866,
            -0.020969843491911888,
            -0.03563323989510536,
            0.029617054387927055,
            -0.01370213832706213,
            -0.0702812597155571,
            -0.03140289708971977,
            0.014961617067456245,
            0.019065791741013527,
            0.006070216652005911,
            -0.03818122297525406,
            -0.013273202814161777,
            0.030642099678516388,
            -0.0322321318089962,
            0.024267803877592087,
            0.026052989065647125,
            -0.01936068944633007,
            -0.006260524969547987,
            0.011676962487399578,
            -0.04533800855278969,
            0.03391130268573761,
            0.026747241616249084,
            -0.011013660579919815,
            -0.002825408009812236,
            -0.015407606028020382,
            0.03417186811566353,
            -0.021494252607226372,
            -0.018214324489235878,
            -0.018289731815457344,
            0.03730248659849167,
            -0.020691899582743645,
            -0.011873963288962841,
            -0.019101183861494064,
            -0.01738535240292549,
            -0.015905702486634254,
            -0.01667146570980549,
            0.025188595056533813,
            -0.024517904967069626,
            0.018587615340948105,
            -0.011406565085053444,
            0.024942172691226006,
            -6.576936721103266e-05,
            -0.055743347853422165,
            -0.019561680033802986,
            -0.011772660538554192,
            0.010052203200757504,
            -0.03078821673989296,
            0.05073942616581917,
            0.006818915251642466,
            0.018639832735061646,
            0.0010453314753249288,
            -0.010385842062532902,
            -0.022835787385702133,
            -0.04537046328186989,
            0.0024297956842929125,
            0.013857112266123295,
            0.02602963149547577,
            -0.009723077528178692,
            -0.022191129624843597,
            -0.010079441592097282,
            0.03049779310822487,
            -0.0148048410192132,
            -0.016000444069504738,
            0.004562040790915489,
            0.013910114765167236,
            -0.008690863847732544,
            0.013651613146066666,
            0.028477007523179054,
            -0.007330920547246933,
            0.0021475027315318584,
            0.017043553292751312,
            -0.00618387944996357,
            -0.037405163049697876,
            0.05202455818653107,
            0.013604425825178623,
            -0.029418490827083588,
            -0.02406630478799343,
            0.01542588695883751,
            0.00864335522055626,
            0.020623870193958282,
            -0.03872577100992203,
            0.039463479071855545,
            -0.013545229099690914,
            -0.018529877066612244,
            0.017466895282268524,
            -0.02100384794175625,
            -0.01754189282655716,
            0.019071945920586586,
            0.013907302170991898,
            0.0036867507733404636,
            0.02695617265999317,
            -0.02452269196510315,
            -0.03418352082371712,
            0.0034103496000170708,
            -0.033627431839704514,
            -0.009055187925696373,
            -0.021064508706331253,
            -0.012453273870050907,
            -0.0589466392993927,
            -0.019503498449921608,
            0.05146391689777374,
            0.005854444112628698,
            -0.0025739737320691347,
            -0.03132766857743263,
            0.02242341637611389,
            0.017825720831751823,
            0.02100048027932644,
            0.01443201582878828,
            0.034451380372047424,
            -0.004192140884697437,
            -0.0013891559792682528,
            0.013515522703528404,
            -0.005880705546587706,
            -0.033433303236961365,
            -0.042374324053525925,
            -0.007303100079298019,
            -0.015303023159503937,
            0.00974134262651205,
            0.03739903122186661,
            0.048468153923749924,
            0.03283287584781647,
            0.02643556334078312,
            0.0033764292020350695,
            0.004725925624370575,
            0.02975918911397457,
            -0.009435140527784824,
            0.016652291640639305,
            0.013474847190082073,
            -0.015909049659967422,
            0.004930709023028612,
            -0.0006151993293315172,
            -0.010397403500974178,
            -0.033007070422172546,
            -0.025392498821020126,
            0.029309207573533058,
            -0.04441125690937042,
            -0.0025771877262741327,
            -0.0265988577157259,
            0.022882385179400444,
            -0.07702316343784332,
            -0.021645423024892807,
            0.03775057941675186,
            -0.023529112339019775,
            -0.008898946456611156,
            0.02139061689376831,
            -0.032514505088329315,
            0.00046131465933285654,
            -0.0018780590035021305,
            -0.047240547835826874,
            -0.01108368206769228,
            0.0505499392747879,
            0.037777531892061234,
            -0.010358616709709167,
            0.004466156009584665,
            0.031183112412691116,
            0.01619495265185833,
            -0.027511173859238625,
            0.007275131531059742,
            -0.0026592055801302195,
            -0.013219908811151981,
            -0.0064557576552033424,
            -0.02827528305351734,
            -0.0026584253646433353,
            -0.03270949050784111,
            -0.014667273499071598,
            0.012984763830900192,
            -0.004885907284915447,
            -0.029609311372041702,
            0.011987454257905483,
            0.03465859964489937,
            0.009721538051962852,
            -0.024223947897553444,
            -0.04412221908569336,
            -0.034635841846466064,
            0.020060904324054718,
            -0.021691201254725456,
            -0.003781758015975356,
            -0.02827443741261959,
            -0.009939341805875301,
            -0.036857377737760544,
            0.02992602251470089,
            -0.028225401416420937,
            0.0071382056921720505,
            -0.008832820691168308,
            -0.004449042957276106,
            -0.0022739279083907604,
            -0.036477092653512955,
            -0.014653048478066921,
            0.0058244364336133,
            0.027448143810033798,
            0.012354923412203789,
            0.0118282875046134,
            -0.02416922338306904,
            -0.017161959782242775,
            -0.03566635027527809,
            0.01723599247634411,
            -0.0003317922819405794,
            0.03067765012383461,
            0.012098274193704128,
            -0.004930829629302025,
            -0.008255678229033947,
            0.011896306648850441,
            -0.023555586114525795,
            0.006787720136344433,
            0.005385087337344885,
            -0.03363543003797531,
            -0.006899882107973099,
            0.011240833438932896,
            -0.019872218370437622,
            0.025295710191130638,
            -0.006381666287779808,
            -0.017959868535399437,
            0.019123876467347145,
            0.050698041915893555,
            0.008221684023737907,
            0.03229530155658722,
            -0.020552562549710274,
            -0.010237474925816059,
            0.014740566723048687,
            0.004860841203480959,
            -0.011389125138521194,
            -0.014792630448937416,
            0.008546481840312481,
            -0.05473551154136658,
            0.023197513073682785,
            -0.017701415345072746,
            0.0062871272675693035,
            0.03226075321435928,
            -0.005421272944658995,
            -0.011037830263376236,
            0.06157480180263519,
            0.015141305513679981,
            -0.015294461511075497,
            -0.010940635576844215,
            -0.002106496598571539,
            0.01715032383799553,
            0.04064495489001274,
            -0.03696659207344055,
            0.06480783969163895,
            0.010649856179952621,
            -0.01891251653432846,
            -0.0535275898873806,
            -0.017371566966176033,
            0.010581961832940578,
            0.015233035199344158,
            -0.016157498583197594,
            0.01133773010224104,
            -0.04066317155957222,
            -0.04029254615306854,
            -0.02350670099258423,
            -0.004683417268097401,
            0.016756344586610794,
            0.004616814665496349,
            0.028216911479830742,
            0.01991911046206951,
            -0.02307550236582756,
            0.020569415763020515,
            -0.023038484156131744,
            -0.015812424942851067,
            0.005556801334023476,
            -0.013379734009504318,
            -0.03660748526453972,
            0.012167263776063919,
            -5.1043618441326544e-05,
            0.038485102355480194,
            -0.012932507321238518,
            0.00206265551969409,
            -0.005412492901086807,
            0.027323449030518532,
            0.019455552101135254,
            0.028542008250951767,
            0.03450876101851463,
            -0.006547677330672741,
            -0.032383255660533905,
            0.0022237892262637615,
            -0.003689588513225317,
            0.02339811623096466,
            -0.025596456602215767,
            0.008336370810866356,
            -0.021241482347249985,
            0.005882706493139267,
            -0.042007166892290115,
            0.011056520976126194,
            -0.009610697627067566,
            0.0021763143595308065,
            -0.023549892008304596,
            0.03232218325138092,
            0.010763254947960377,
            0.03200796619057655,
            0.0028161308728158474,
            -0.08317408710718155,
            -0.02829640358686447,
            0.02185583859682083,
            -0.03342106565833092,
            0.017246469855308533,
            0.004443875979632139,
            0.023249346762895584,
            -0.013965039514005184,
            -0.018246693536639214,
            -0.05128005892038345,
            0.004782109521329403,
            -0.010223480872809887,
            0.01929534785449505,
            0.025421984493732452,
            0.006240257993340492,
            0.034209754317998886,
            0.03203899413347244,
            0.05240380018949509,
            0.025200476869940758,
            -0.02970828302204609,
            -0.012184238992631435,
            0.006243027746677399,
            -0.018139474093914032,
            0.000830007076729089,
            -0.00029605207964777946,
            0.018136467784643173,
            0.007320767268538475,
            0.03434564545750618,
            0.03802923858165741,
            -0.01375120971351862,
            0.037463754415512085,
            0.009944431483745575,
            -0.024100856855511665,
            -0.006153682246804237,
            -0.06261150538921356,
            0.020559100434184074,
            0.035697150975465775,
            -0.012541016563773155,
            -0.033240530639886856,
            0.03235138952732086,
            -0.006141007412225008,
            -0.006024420727044344,
            0.016860075294971466,
            0.03128785267472267,
            0.02521819807589054,
            -0.003883163444697857,
            -0.016072891652584076,
            -0.03781844303011894,
            -0.0042547984048724174,
            0.02936566434800625,
            -0.013919691555202007,
            -0.013959736563265324,
            -0.03677646815776825,
            0.017956946045160294,
            -0.024311384186148643,
            0.010965325869619846,
            0.002093168906867504,
            -0.01857917197048664,
            0.01459613349288702,
            -0.011006536893546581,
            -0.0030042538419365883,
            -0.03554237633943558,
            0.006523515097796917,
            0.021900542080402374,
            0.011858944781124592,
            -0.011485445313155651,
            0.01962183602154255,
            0.028190942481160164,
            0.023945277556777,
            0.004010104574263096,
            -0.01678307354450226,
            0.016125736758112907,
            -0.02033383958041668,
            -0.0007005746010690928,
            0.017071548849344254,
            -0.025202522054314613,
            0.004687722306698561,
            0.019938984885811806,
            0.012926749885082245,
            0.025252027437090874,
            -0.014946742914617062,
            0.0020755573641508818,
            -0.004999285098165274,
            -0.012616674415767193,
            0.061412349343299866,
            -0.04078901186585426,
            -0.01044800877571106,
            -0.02200540155172348,
            0.012393423356115818,
            0.02325480617582798,
            0.015102175995707512,
            -0.016658345237374306,
            0.008180773817002773,
            -0.02016286551952362,
            0.0005920020630583167,
            0.029808631166815758,
            0.013966850936412811,
            0.0024221583735197783,
            -0.019439376890659332,
            -0.021259235218167305,
            0.014121063984930515,
            0.011532858945429325,
            0.006513649132102728,
            -0.023092370480298996,
            -0.017640532925724983,
            0.02473478578031063,
            0.019840257242321968,
            -0.0339934416115284,
            0.025821346789598465,
            0.015897054225206375,
            0.002163922879844904,
            -0.013039590790867805,
            -0.014789491891860962,
            -0.04784601181745529,
            0.0035762388724833727,
            -0.04202655702829361,
            0.015769746154546738,
            0.017959272488951683,
            0.03663361072540283,
            0.02103556878864765,
            -0.02556019462645054,
            0.013162211515009403,
            -0.008160693570971489,
            -0.04436110705137253,
            -0.020832538604736328,
            -0.009849293157458305,
            -0.00811457633972168,
            -0.02248833328485489,
            -0.017950601875782013,
            0.026182370260357857,
            0.005471257027238607,
            0.016265997663140297,
            -0.05756848305463791,
            -0.04388122633099556,
            0.0036237938329577446,
            0.003261827165260911,
            -0.013324636034667492,
            0.002339233411476016,
            0.036544542759656906,
            0.023675676435232162,
            0.012233532965183258,
            0.014332178048789501,
            -0.01781656965613365,
            -0.0187247134745121,
            0.05510123074054718,
            -0.026042498648166656,
            0.011565057560801506,
            -0.013000916689634323,
            -0.012837531045079231,
            0.007149483542889357,
            -0.010206995531916618,
            0.02972889319062233,
            0.004878682550042868,
            -0.02936060167849064,
            -0.014382520690560341,
            0.017825031653046608,
            -0.0013294981326907873,
            0.04289339855313301,
            -0.045251019299030304,
            0.0018894214881584048,
            0.009435654617846012
        ],
        [
            -0.03907247632741928,
            -0.019515350461006165,
            -0.01500204298645258,
            -0.029798630625009537,
            0.015353336930274963,
            0.004077517427504063,
            0.019098395481705666,
            0.017664572224020958,
            0.002649290021508932,
            -0.007839725352823734,
            -0.022581489756703377,
            -0.009280200116336346,
            -0.022359367460012436,
            0.015100788325071335,
            -0.002269996562972665,
            0.007235297467559576,
            0.008497064001858234,
            0.03155528008937836,
            0.015994030982255936,
            -0.002431997563689947,
            -0.022196443751454353,
            0.009674186818301678,
            0.013606014661490917,
            -0.022062623873353004,
            -0.0551348440349102,
            0.023603836074471474,
            0.024188164621591568,
            -0.011000088416039944,
            -0.022456662729382515,
            -0.01339160930365324,
            -0.009878714568912983,
            0.02838183380663395,
            0.030615685507655144,
            0.03900924324989319,
            0.028895488008856773,
            0.01631397008895874,
            -0.031853433698415756,
            0.022270068526268005,
            -0.014213315211236477,
            -0.02757345698773861,
            -0.015826240181922913,
            0.025398651137948036,
            0.006370846647769213,
            0.03458068519830704,
            -0.02677251026034355,
            -0.02122923918068409,
            0.014608913101255894,
            0.0068362122401595116,
            0.008073756471276283,
            -0.02765430510044098,
            0.01125999167561531,
            -0.02987668290734291,
            -0.019172873347997665,
            0.014430730603635311,
            -0.008800232782959938,
            -0.03161798417568207,
            0.01516809593886137,
            -0.03132108971476555,
            0.03307885304093361,
            0.02628830447793007,
            0.01852184534072876,
            0.006859284359961748,
            0.025826234370470047,
            -0.010424621403217316,
            -0.006997746415436268,
            -0.01586075685918331,
            -0.008443841710686684,
            -0.013529011979699135,
            -0.020223742350935936,
            0.020427290350198746,
            0.026788974180817604,
            0.004507390782237053,
            -0.021070446819067,
            -0.0052225422114133835,
            -0.0018445384921506047,
            -0.008897715248167515,
            -0.0046658883802592754,
            -0.021269837394356728,
            -0.018488425761461258,
            0.018377184867858887,
            0.02649526111781597,
            -0.005723854061216116,
            0.00025050173280760646,
            -0.018868347629904747,
            -0.028723305091261864,
            0.026841016486287117,
            0.000639180711004883,
            -0.008326717652380466,
            -0.029306083917617798,
            0.031054433435201645,
            -0.021887633949518204,
            0.006118352059274912,
            0.012945635244250298,
            -0.022233691066503525,
            -0.009185568429529667,
            0.024423891678452492,
            -0.0063313511200249195,
            0.00835652370005846,
            -0.008023027330636978,
            -0.028382718563079834,
            -0.025140369310975075,
            0.021969767287373543,
            -0.015271478332579136,
            -0.01857343502342701,
            0.02272089384496212,
            -0.006418031174689531,
            -0.010428809560835361,
            -0.011850333772599697,
            -0.01683737523853779,
            0.026044636964797974,
            -0.01600315421819687,
            -0.015635540708899498,
            0.022013375535607338,
            -0.028153972700238228,
            -0.03155985474586487,
            0.01964481547474861,
            0.017443157732486725,
            -0.009324058890342712,
            0.020192382857203484,
            0.02723400481045246,
            0.005472380667924881,
            -0.018839828670024872,
            -0.03952743485569954,
            -0.01748044602572918,
            0.02078438550233841,
            -0.0015687037957832217,
            -0.014220092445611954,
            -0.025590576231479645,
            0.024510206654667854,
            -0.008502562530338764,
            0.024894079193472862,
            0.005025137681514025,
            -0.0309964120388031,
            0.029041310772299767,
            0.008803275413811207,
            0.005974586587399244,
            -0.007262657396495342,
            -0.008464549668133259,
            -0.008523360826075077,
            0.018271412700414658,
            -0.017439503222703934,
            -0.0006250599981285632,
            -0.00016322507872246206,
            0.014581886120140553,
            -0.012466372922062874,
            0.008572578430175781,
            0.02933615632355213,
            0.002159271389245987,
            -0.020204799249768257,
            0.00937864650040865,
            0.03603886812925339,
            0.0005941603449173272,
            0.0035767110530287027,
            0.003957123029977083,
            -0.022519081830978394,
            0.022801533341407776,
            -0.005023106001317501,
            -0.020412996411323547,
            -0.022369075566530228,
            0.0032721131574362516,
            -0.013742274604737759,
            -0.02286772057414055,
            -0.00046659886720590293,
            -0.010117419995367527,
            0.018960412591695786,
            0.019423818215727806,
            0.010542166419327259,
            -0.013759921304881573,
            -0.0052614351734519005,
            0.004904629662632942,
            -0.005804014857858419,
            0.006944695953279734,
            0.024967623874545097,
            -0.0007439456530846655,
            -0.008751904591917992,
            0.0329926572740078,
            -0.020963476970791817,
            -0.011997574009001255,
            0.013249852694571018,
            0.017360957339406013,
            0.03609428554773331,
            -0.0037636091001331806,
            -0.02525593899190426,
            0.023844175040721893,
            -0.017222028225660324,
            0.02551993727684021,
            0.02853367291390896,
            0.014715880155563354,
            -0.02513449639081955,
            0.004076418466866016,
            0.01215450931340456,
            0.03792254999279976,
            0.03776048123836517,
            -0.005064999684691429,
            0.01848851889371872,
            0.024695884436368942,
            -0.0173894502222538,
            0.02576424926519394,
            -0.025050366297364235,
            -0.019295046105980873,
            -0.015334924682974815,
            -0.0031679999083280563,
            0.04197763279080391,
            0.005973295774310827,
            -0.025947073474526405,
            -0.024668505415320396,
            -0.030494462698698044,
            -0.018984325230121613,
            -0.009653541259467602,
            -0.00815782230347395,
            0.010676388628780842,
            -0.002353930613026023,
            -0.02358366549015045,
            0.02439994551241398,
            0.02575335092842579,
            -0.0019947760738432407,
            0.017693595960736275,
            -0.023565199226140976,
            0.021693198010325432,
            0.0129691855981946,
            -0.008252901025116444,
            -0.015624593012034893,
            -0.013889191672205925,
            0.011135763488709927,
            0.019720640033483505,
            -0.012526417151093483,
            0.01418345421552658,
            -0.01558905839920044,
            -0.024210378527641296,
            -0.010016367770731449,
            -0.0031029314268380404,
            -0.008445207960903645,
            -0.018518105149269104,
            0.008047510869801044,
            -0.02050233632326126,
            -0.0123438136652112,
            0.004591590724885464,
            -0.021608812734484673,
            -0.021340692415833473,
            0.01208165381103754,
            -0.010526769794523716,
            0.028591834008693695,
            0.028144365176558495,
            -0.010272053070366383,
            0.028202665969729424,
            -0.008594430051743984,
            0.02154507301747799,
            0.025542523711919785,
            0.002615876030176878,
            0.018559204414486885,
            0.020129602402448654,
            0.021706270053982735,
            -0.022431163117289543,
            0.011362892575562,
            -0.027404122054576874,
            -0.00918844435364008,
            -0.023068390786647797,
            0.0009474001708440483,
            -0.002076373202726245,
            0.029918523505330086,
            0.01191182155162096,
            0.016457706689834595,
            0.017344437539577484,
            -0.021004440262913704,
            -0.003855176270008087,
            0.01378787960857153,
            0.015312965959310532,
            0.004897657316178083,
            0.026686782017350197,
            0.012738333083689213,
            -0.014219721779227257,
            0.009221464395523071,
            0.033065468072891235,
            0.01981520466506481,
            -0.023140061646699905,
            0.02139306254684925,
            0.035678498446941376,
            0.02509053610265255,
            -0.023259036242961884,
            0.020616810768842697,
            -0.023713218048214912,
            -0.02583005279302597,
            -0.011640515178442001,
            -0.018134459853172302,
            0.021656356751918793,
            0.024011529982089996,
            -0.02920544520020485,
            -0.020855581387877464,
            0.02780429646372795,
            -0.02925642766058445,
            0.03037976287305355,
            -0.024306904524564743,
            0.0022347348276525736,
            0.006855370942503214,
            -0.014241076074540615,
            -0.003883737837895751,
            -0.020390737801790237,
            -0.025473540648818016,
            0.0019462137715891004,
            0.013465418480336666,
            -0.013422820717096329,
            -0.020068133249878883,
            0.020964786410331726,
            0.018351852893829346,
            0.023585278540849686,
            0.016726084053516388,
            -0.0020425624679774046,
            -0.027661407366394997,
            0.005519069731235504,
            -0.009195994585752487,
            -0.026942161843180656,
            0.02611815556883812,
            -0.02159084938466549,
            -0.02287968248128891,
            0.006311166565865278,
            -0.01371338777244091,
            0.008699839934706688,
            0.029124386608600616,
            0.01465193647891283,
            -0.031933948397636414,
            -0.010061655193567276,
            0.0027418839745223522,
            -0.017003197222948074,
            -0.02197195403277874,
            0.03170236572623253,
            -0.018240660429000854,
            -0.00040436387644149363,
            0.019647404551506042,
            0.022331561893224716,
            -0.02910897508263588,
            0.015432778745889664,
            -0.02760070003569126,
            -0.016309604048728943,
            0.009710474871098995,
            0.020241010934114456,
            0.005644689314067364,
            0.00745416572317481,
            0.02910376340150833,
            0.027611779049038887,
            -0.01937633752822876,
            0.024064648896455765,
            0.01758808270096779,
            -0.012365022674202919,
            -0.024263720959424973,
            -0.0024657719768583775,
            0.027596207335591316,
            -0.02203253097832203,
            0.020243309438228607,
            -0.023169096559286118,
            -0.03005119599401951,
            -0.022215871140360832,
            0.0015285052359104156,
            -0.007165873423218727,
            0.006106353364884853,
            0.019047647714614868,
            0.011351998895406723,
            -0.021958939731121063,
            -0.02774307318031788,
            0.020310377702116966,
            0.02188192307949066,
            0.022862927988171577,
            -0.02334653027355671,
            0.017760032787919044,
            1.840061850089114e-05,
            -0.007526872213929892,
            -0.012295782566070557,
            -0.02091277204453945,
            -0.008561139926314354,
            0.00515437126159668,
            -0.014602485112845898,
            -0.021562691777944565,
            -0.009884187951683998,
            0.0075443340465426445,
            -0.002654408570379019,
            0.005994746461510658,
            0.032941076904535294,
            0.035174112766981125,
            -0.0024287088308483362,
            0.006601656321436167,
            -0.02817700244486332,
            0.013154378160834312,
            -0.02703274041414261,
            -0.02757694013416767,
            0.002519311849027872,
            -0.010661931708455086,
            0.011961622163653374,
            0.014865010045468807,
            0.022310899570584297,
            0.008573228493332863,
            -0.00990843866020441,
            0.025568533688783646,
            -0.010616010054945946,
            0.009213291108608246,
            0.012938681058585644,
            0.001107303542084992,
            0.013043323531746864,
            -0.01841031387448311,
            0.003346603363752365,
            -0.0023558815009891987,
            0.026883674785494804,
            -0.0031560491770505905,
            0.0047699385322630405,
            -0.023411668837070465,
            0.009268507361412048,
            -0.0022402398753911257,
            0.015017618425190449,
            0.009979590773582458,
            -0.009271063841879368,
            0.022809645161032677,
            -0.031237250193953514,
            -0.006585866212844849,
            -0.02397533878684044,
            -0.012316437438130379,
            0.018182676285505295,
            -0.007221508305519819,
            0.001910084392875433,
            0.012816975824534893,
            -0.040791187435388565,
            -0.02320089004933834,
            -0.023908322677016258,
            0.020978551357984543,
            -0.01815386489033699,
            -0.033329714089632034,
            -0.008422176353633404,
            0.007571551948785782,
            -0.011716289445757866,
            -0.004015638027340174,
            -0.006544509436935186,
            0.0010539370123296976,
            -0.0063409628346562386,
            0.0218545850366354,
            0.02735791727900505,
            0.021515483036637306,
            0.000415610644267872,
            -0.02013253979384899,
            -0.0010036674793809652,
            -0.014042725786566734,
            0.027209969237446785,
            0.013668731786310673,
            0.025614095851778984,
            0.030356183648109436,
            0.010962346568703651,
            -0.017759043723344803,
            -0.0006875150138512254,
            0.011327735148370266,
            0.01709963195025921,
            0.0023385591339319944,
            -0.03617921471595764,
            -0.025373267009854317,
            0.007059574592858553,
            -0.002569527132436633,
            0.03308522701263428,
            -0.006695861928164959,
            0.019661350175738335,
            -0.024789398536086082,
            -0.016612239181995392,
            0.03826431557536125,
            0.002195413690060377,
            -0.005315321031957865,
            0.021566197276115417,
            -0.0044239885173738,
            0.00862117763608694,
            -0.037886809557676315,
            0.008643388748168945,
            0.0035526477731764317,
            -0.012395462952554226,
            0.005041180178523064,
            -0.016604451462626457,
            0.010638301260769367,
            0.022004706785082817,
            0.03461526334285736,
            -0.006892463658004999,
            0.01618685945868492,
            0.0142342709004879,
            0.00231669913046062,
            -0.045605968683958054,
            -0.023354243487119675,
            0.012782219797372818,
            -0.022602582350373268,
            0.02409304492175579,
            -0.006380101200193167,
            0.02620125748217106,
            0.000408932042773813,
            -0.001710117794573307,
            -0.019242744892835617,
            0.010976082645356655,
            0.010750590823590755,
            0.02029483951628208,
            -0.026012584567070007,
            0.022908182814717293,
            -0.0023506097495555878,
            0.018819596618413925,
            -0.015708616003394127,
            0.01155091729015112,
            0.020778747275471687,
            -0.029241466894745827,
            -0.005137311760336161,
            -0.01983894594013691,
            -0.010060402564704418,
            0.033280111849308014,
            0.005946592893451452,
            0.029071882367134094,
            0.005864874925464392,
            -0.023896200582385063,
            0.01680699549615383,
            0.022450115531682968,
            0.023551782593131065,
            0.010889988392591476,
            0.027611717581748962,
            -0.003487298497930169,
            0.016009027138352394,
            0.009257948026061058,
            0.03306717798113823,
            0.028210435062646866,
            0.012984933331608772,
            -0.02049541100859642,
            -0.010498673655092716,
            0.01895061880350113,
            0.016076037660241127,
            -0.028325466439127922,
            -0.0009404306183569133,
            0.009076880291104317,
            0.001173483207821846,
            -0.011722766794264317,
            0.025447411462664604,
            0.0021452733781188726,
            -0.018196402117609978,
            -0.025764137506484985,
            0.017026636749505997,
            0.015928080305457115,
            -0.015065941028296947,
            0.006793436128646135,
            0.0074138520285487175,
            -0.008278711698949337,
            0.00458659790456295,
            0.03429782763123512,
            0.012380843050777912,
            -0.011492341756820679,
            -0.020301319658756256,
            -0.02906353399157524,
            -0.014640082605183125,
            0.008642150089144707,
            -0.008411470800638199,
            -0.04217933490872383,
            -0.0001422894565621391,
            0.02450583316385746,
            0.012847227975726128,
            -0.005451779812574387,
            0.001528859487734735,
            0.033554598689079285,
            -0.008457290008664131,
            -0.028712620958685875,
            0.02015797235071659,
            0.008294911123812199,
            -0.015537519007921219,
            -0.00959096197038889,
            0.006405148189514875,
            -0.016764968633651733,
            -0.0200184416025877,
            0.022829266265034676,
            0.002587134251371026,
            0.02767183817923069,
            0.0088474340736866,
            0.013891654089093208,
            0.03039385937154293,
            0.004784650634974241,
            0.003954064566642046,
            -0.030748825520277023,
            -0.009342292323708534,
            -0.009508125483989716,
            0.02611651085317135,
            0.019264401867985725,
            -0.0008840637165121734,
            0.006620724685490131,
            0.02928011491894722,
            -0.029094604775309563,
            -0.008476773276925087,
            0.038810845464468,
            0.003309626830741763,
            -0.022059472277760506,
            0.017461972311139107,
            -0.0417231060564518,
            -0.01680646650493145,
            0.01859472692012787,
            -0.011549191549420357,
            0.01138154324144125,
            -0.03487085923552513,
            -0.005738996434956789,
            0.014563478529453278,
            -0.004425772000104189,
            -0.03267063573002815,
            -0.010964605025947094,
            0.0049826037138700485,
            -0.010481939651072025,
            0.024338483810424805,
            -0.011709463782608509,
            -0.006657168734818697,
            -0.013369322754442692,
            -0.019756222143769264,
            -0.005091649480164051,
            0.034147221595048904,
            -0.033504582941532135,
            -0.01533546857535839,
            -0.0006097308942116797,
            -0.020940156653523445,
            0.027712663635611534,
            -0.01307882834225893,
            -0.036792222410440445,
            -0.019075516611337662,
            0.01602318324148655,
            0.005355579312890768,
            -0.0263931006193161,
            0.006066009867936373,
            0.004971254151314497,
            -0.030773166567087173,
            -0.01424864400178194,
            -0.024619026109576225,
            -0.010041197761893272,
            -0.009528863243758678,
            0.012478156015276909,
            0.006264474242925644,
            0.029656682163476944,
            0.02404959872364998,
            0.01769142597913742,
            0.03193645179271698,
            -0.02339082397520542,
            -0.031211107969284058,
            0.010624931193888187,
            0.022259553894400597,
            0.028255226090550423,
            -0.03645903244614601,
            -0.017861617729067802,
            -0.016265224665403366,
            -0.009527495130896568,
            -0.02615361660718918,
            -0.01637239195406437,
            0.023932743817567825,
            -0.019940508529543877,
            -0.0007467532996088266,
            -0.020289158448576927,
            0.009642415679991245,
            0.0011145483003929257,
            -0.015150214545428753,
            -0.0015065036714076996,
            0.027555309236049652,
            0.036881282925605774,
            0.020225882530212402,
            0.01745130680501461,
            -0.028326092287898064,
            0.009800354950129986,
            0.006618593819439411,
            0.014487211592495441,
            -0.030945749953389168,
            0.0054596648551523685,
            -0.04936651140451431,
            0.022930903360247612,
            0.01602178066968918,
            0.026590870693325996,
            -0.022709017619490623,
            -0.024455061182379723,
            0.0008134082308970392,
            0.010636867955327034,
            -0.0057321893982589245,
            0.016520384699106216,
            -0.02000005729496479,
            0.02285700850188732,
            -0.017997775226831436,
            -0.012365642003715038,
            0.005256165284663439,
            -0.022644469514489174,
            -0.00993718858808279,
            -0.04753644019365311,
            -0.006077441852539778,
            -0.007101384457200766,
            0.02500966191291809,
            -0.007942065596580505,
            -0.01365443505346775,
            -0.01431471947580576,
            0.002109798602759838,
            0.01759740337729454,
            -0.02768712490797043,
            0.024783393368124962,
            0.03548409417271614,
            0.004395122639834881,
            0.0048716021701693535,
            0.017717011272907257,
            -0.01556917279958725,
            -0.02138081006705761,
            0.02623998560011387,
            -0.012117987498641014,
            -0.019327282905578613,
            -0.0033965122420340776,
            0.00884665735065937,
            0.006473890971392393,
            -0.025793107226490974,
            -0.03466470167040825,
            -0.0073058572597801685,
            -0.01235169731080532,
            0.008386274799704552,
            -0.004089624620974064,
            -0.0008871559984982014,
            -0.011480797082185745,
            0.02846234105527401,
            0.013258973136544228,
            0.004544862080365419,
            -0.018657570704817772,
            0.0006406463799066842,
            -0.004574799910187721,
            -0.029384702444076538,
            -0.0015739293303340673,
            0.01044878363609314,
            -0.024889318272471428,
            -0.018792130053043365,
            0.023160528391599655,
            0.025896204635500908,
            -0.010216610506176949,
            -0.041077833622694016,
            0.007902505807578564,
            0.007626553066074848,
            -0.01464090682566166,
            0.040558189153671265,
            -0.011563802137970924,
            0.0035920816008001566,
            0.021576978266239166,
            -0.008261075243353844,
            -0.025539323687553406,
            -0.027819868177175522,
            -0.0303840022534132,
            -0.020797863602638245,
            -0.012866638600826263,
            0.01912396401166916,
            0.02761155180633068,
            -0.004537169821560383,
            0.017942769452929497,
            -0.0062390645034611225,
            -0.03498319163918495,
            -0.009451083838939667,
            0.020759450271725655,
            -0.013031454756855965,
            0.019634580239653587,
            0.021138958632946014,
            -0.016058299690485,
            0.0160242710262537,
            -0.003533631097525358,
            -0.009668443351984024,
            -0.029203182086348534,
            -0.034308020025491714,
            -0.02226669155061245,
            -0.028101202100515366,
            0.014815473929047585,
            0.022279737517237663,
            0.016169175505638123,
            -0.0027680674102157354,
            0.05531252920627594,
            0.010076254606246948,
            0.0013789585791528225,
            -0.023502515628933907,
            0.02154829353094101,
            -0.017211617901921272,
            0.005607358179986477,
            -0.01744789257645607,
            -0.030117006972432137,
            -0.017032679170370102,
            -0.014549514278769493,
            0.01744156703352928,
            0.02042142115533352,
            0.008490508422255516,
            0.018283553421497345,
            -0.004176906310021877,
            -0.019458286464214325,
            -0.020815478637814522,
            0.02902214415371418,
            0.03388646990060806,
            -0.004292810335755348,
            0.029465384781360626,
            -0.022132303565740585,
            -0.019961386919021606,
            -0.004970611073076725,
            -0.01603185199201107,
            -0.00578052643686533,
            0.01325942948460579,
            0.028604617342352867,
            0.011173204518854618,
            -0.013127955608069897,
            -0.018839696422219276,
            0.0213006604462862,
            0.012032638303935528,
            0.020092686638236046,
            0.010114093311131,
            -0.005031500943005085,
            0.04021357744932175,
            -0.019784951582551003,
            0.019600674510002136,
            -0.037523604929447174,
            0.0041385977528989315,
            -0.0027238577604293823,
            0.007415889296680689,
            0.00421426584944129,
            0.027061747387051582,
            0.01837572641670704,
            0.011559434235095978,
            0.0035560077521950006,
            -0.015169168822467327,
            -0.014957373030483723,
            -0.03143974766135216,
            0.04541585221886635,
            0.03626604750752449,
            0.019360145553946495,
            -0.016658073291182518,
            0.013478880748152733,
            -0.023084288462996483,
            -0.0011381189106032252,
            0.02513960376381874,
            -0.005254079587757587,
            -0.0013088610721752048,
            0.0072143650613725185,
            0.012183062732219696,
            0.0037337103858590126,
            -0.004710149485617876,
            -0.017968060448765755,
            0.02789275534451008,
            0.02387680672109127,
            -0.004806577693670988,
            -0.009581445716321468,
            0.03913063928484917,
            -0.008194230496883392,
            0.01790768653154373,
            -0.009624957107007504,
            0.006498824339359999,
            0.028555167838931084,
            -0.0054169693030416965,
            -0.0050804633647203445,
            0.008160768076777458,
            -0.014052269048988819,
            -0.009967909194529057,
            -0.020787866786122322,
            -0.016582276672124863,
            -0.024857942014932632,
            -0.012513641268014908,
            0.000437191833043471,
            0.02022617496550083,
            -0.00781485065817833,
            0.025636101141572,
            -0.006783835124224424,
            0.02664378471672535,
            -0.004258406814187765,
            -0.03072494827210903,
            -0.0026636882685124874,
            -0.024927156046032906,
            -0.006499830167740583,
            -0.018296102061867714,
            -0.0018564045894891024,
            -0.013613278977572918,
            0.028796806931495667,
            -0.00042554858373478055,
            0.0032617163378745317,
            -0.012537901289761066,
            0.024003349244594574,
            0.031092779710888863,
            -0.006407978944480419,
            -0.020928606390953064,
            0.012392191216349602,
            0.01978488266468048,
            -0.009307879954576492,
            0.0021269479766488075,
            0.0008256432483904064,
            0.013650280423462391,
            0.006745052523910999,
            -0.016270868480205536,
            0.0298463124781847,
            -0.01880171149969101,
            -0.022593693807721138,
            0.002988620661199093,
            -0.0005039337556809187,
            -0.00902541447430849,
            -0.00515326950699091,
            -0.00906605739146471,
            0.048388462513685226,
            -0.003921995405107737,
            0.0010391261894255877,
            0.03531652316451073,
            0.029082242399454117,
            -0.017266282811760902,
            -0.02743031084537506,
            -0.008260956965386868,
            0.006094248965382576,
            -0.01758430525660515,
            -0.010620499961078167,
            -0.015584475360810757,
            -0.0030491426587104797,
            0.010013054125010967,
            -0.012355917133390903,
            -0.007534069940447807,
            -0.012629437260329723,
            -0.014907407574355602,
            0.008485105820000172,
            -0.022916996851563454,
            -0.009793290868401527,
            -0.012263484299182892,
            0.011131598614156246,
            0.015609444119036198,
            0.022665629163384438,
            -0.014688086695969105,
            -0.007949260994791985,
            0.008240889757871628,
            -0.026615416631102562,
            0.020603036507964134,
            -0.03335418924689293,
            0.017414897680282593,
            0.008158226497471333,
            0.010455413721501827,
            0.010534705594182014,
            0.006028186995536089,
            -0.0007164973067119718,
            -0.01540855411440134,
            0.018200937658548355,
            -0.0038515839260071516,
            -0.007806790992617607,
            0.011689603328704834,
            0.00900198519229889,
            0.02960430271923542,
            -0.012370447628200054,
            -0.000903031905181706,
            -0.03036649525165558,
            0.000952886592131108,
            -0.020432045683264732,
            0.019932296127080917,
            -0.01076977513730526,
            0.015818413347005844,
            0.014014842920005322,
            0.03194012492895126,
            0.023184884339571,
            0.020005397498607635,
            -0.00415717251598835,
            0.008725116960704327,
            -0.03577958047389984,
            -0.007015721872448921,
            0.025880075991153717,
            -0.027774982154369354,
            -0.0310831256210804,
            0.008442163467407227,
            -0.030087262392044067,
            0.0348583422601223,
            -0.011952105909585953,
            0.019966721534729004,
            0.002969213994219899,
            -0.025822125375270844,
            -0.016795314848423004,
            -0.00945649016648531,
            -0.025876224040985107,
            0.023075319826602936,
            -0.018581844866275787,
            0.01816279999911785,
            0.016455931589007378,
            0.0022452797275036573,
            -0.028757695108652115,
            -0.02590956538915634,
            0.030268991366028786,
            0.019820192828774452,
            0.0170163381844759,
            -0.017499590292572975,
            -0.005321703385561705,
            0.037393927574157715,
            -0.01834680885076523,
            0.034588366746902466,
            0.020433278754353523,
            0.021229932084679604,
            0.00014810007996857166,
            -0.001057700952515006,
            -0.01896132342517376,
            0.008140303194522858,
            -0.028074804693460464,
            -0.0029610302299261093,
            0.026228750124573708,
            -0.018457666039466858,
            -0.01406714878976345,
            0.017058134078979492,
            -0.012473615817725658,
            0.0085173724219203,
            -0.02525133267045021,
            0.02469470351934433,
            -0.0023972787894308567,
            0.026803379878401756,
            -0.026436740532517433,
            0.017093893140554428,
            0.017169419676065445,
            0.034261394292116165,
            0.024112384766340256,
            -0.022500349208712578,
            0.01838754303753376,
            -0.01616162434220314,
            0.00506171677261591,
            0.01627221517264843,
            0.019329961389303207,
            -0.012778687290847301,
            -0.004887888673692942,
            -0.05296682193875313,
            0.022051304578781128,
            0.016291426494717598,
            0.025917740538716316,
            0.030337052419781685,
            -0.01797199621796608,
            0.011036094278097153,
            0.020683692768216133,
            0.027778426185250282,
            -0.0024550219532102346,
            -0.003985334187746048,
            -0.017365455627441406,
            0.025912290439009666,
            -0.008854873478412628,
            -0.02076527290046215,
            -0.014155983924865723,
            0.01943860575556755,
            0.008063498884439468,
            -0.006966837681829929,
            0.012293393723666668,
            0.00469962740316987,
            -0.013316242024302483,
            -0.007851011119782925,
            0.012066547758877277,
            0.008405972272157669,
            0.016383880749344826,
            0.028147991746664047,
            -0.022627294063568115
        ],
        [
            0.00501242745667696,
            0.0015763365663588047,
            -0.005832848604768515,
            0.001624971511773765,
            -0.018439147621393204,
            -0.026089508086442947,
            0.01160395797342062,
            -0.0014981181593611836,
            -0.018592625856399536,
            -0.009159448556602001,
            -0.009743704460561275,
            0.002170000923797488,
            0.010534487664699554,
            -0.01631089858710766,
            -0.0008047774899750948,
            -0.06885840743780136,
            -0.04270319640636444,
            0.010995332151651382,
            -0.06142790988087654,
            0.014409808441996574,
            -0.0085514010861516,
            -0.01966150291264057,
            -0.002603297121822834,
            -0.0004604975983966142,
            0.03788270428776741,
            -0.026612069457769394,
            0.004358943551778793,
            -0.011260895989835262,
            -0.01827320083975792,
            -0.024853624403476715,
            -0.02004355937242508,
            0.018409505486488342,
            -0.03908512741327286,
            -0.034085992723703384,
            0.0024912890512496233,
            0.009812713600695133,
            -0.0241179671138525,
            0.0020445273257791996,
            -0.01760655827820301,
            0.02473307028412819,
            0.006492202635854483,
            0.016882089897990227,
            -0.001964581198990345,
            -0.005692240782082081,
            -0.00188258639536798,
            -0.004282996524125338,
            -0.04042213410139084,
            -0.0056825364008545876,
            0.006653785239905119,
            0.00623626122251153,
            -0.015586127527058125,
            0.02889326401054859,
            0.025879016146063805,
            -0.008854721672832966,
            0.011116481386125088,
            0.02190832979977131,
            0.04241510108113289,
            0.0563761368393898,
            0.00422091456130147,
            -0.011549933813512325,
            0.030280668288469315,
            0.013064865954220295,
            0.005624289624392986,
            0.013899987563490868,
            -0.0031495641451328993,
            0.01645873673260212,
            -0.028880920261144638,
            0.03484518826007843,
            -0.02426794543862343,
            -0.026408037170767784,
            0.02994261309504509,
            -0.01906576193869114,
            -0.008146699517965317,
            0.01789260096848011,
            0.013977977447211742,
            0.015215489082038403,
            -0.0398750863969326,
            0.004185865633189678,
            -0.002118124859407544,
            0.04077627509832382,
            -0.0018256730400025845,
            0.040378592908382416,
            0.002956910990178585,
            0.011238368228077888,
            -0.021027933806180954,
            0.016612183302640915,
            0.008977326564490795,
            0.03388356789946556,
            0.034668855369091034,
            0.020502233877778053,
            -0.022392289713025093,
            -0.031078971922397614,
            -0.00103557831607759,
            -0.007445092778652906,
            -0.011471765115857124,
            -0.016406580805778503,
            0.013690127991139889,
            0.018981538712978363,
            -0.0070647126995027065,
            0.00859872903674841,
            0.009516935795545578,
            0.026957059279084206,
            0.005347465164959431,
            0.026576820760965347,
            0.014652114361524582,
            0.0013575596967712045,
            -0.012199627235531807,
            0.015278083272278309,
            0.026935501024127007,
            0.0009189395350404084,
            -0.004082490224391222,
            -0.03073726035654545,
            -0.010548918507993221,
            0.015267457813024521,
            -0.05254233255982399,
            0.007371260318905115,
            0.03828907757997513,
            -0.030888240784406662,
            0.016908926889300346,
            -0.03944316506385803,
            0.028182512149214745,
            -0.015673723071813583,
            0.04415610805153847,
            -0.007204909808933735,
            0.011872434988617897,
            -0.006847739685326815,
            -0.0176216010004282,
            -0.004184960853308439,
            0.016308993101119995,
            -0.024363873526453972,
            -0.012751052156090736,
            -0.027553608641028404,
            0.042353756725788116,
            0.02059943787753582,
            0.020778167992830276,
            0.01009004283696413,
            -0.02223537676036358,
            -0.03300768882036209,
            0.026735637336969376,
            -0.01527998223900795,
            -0.01206655241549015,
            -0.023430025205016136,
            0.007362463977187872,
            0.013919148594141006,
            0.02814950793981552,
            -0.0008522485732100904,
            -0.031561024487018585,
            -0.010495945811271667,
            0.033059459179639816,
            0.018587077036499977,
            0.014392402023077011,
            0.032222501933574677,
            0.019028304144740105,
            -0.02656182274222374,
            0.0354953296482563,
            -0.024689748883247375,
            0.0013952709268778563,
            0.02342521771788597,
            0.027391042560338974,
            0.0034532106947153807,
            -0.04059482738375664,
            -0.027392825111746788,
            0.0012772593181580305,
            -0.01196824386715889,
            0.006157278083264828,
            0.022111384198069572,
            0.03641389682888985,
            0.020906249061226845,
            0.04423077777028084,
            0.023176521062850952,
            0.014017104171216488,
            0.019037822261452675,
            0.012852835468947887,
            -0.0063588120974600315,
            -0.011128400452435017,
            0.031529344618320465,
            0.0032418323680758476,
            -0.0023825610987842083,
            -0.0036476915702223778,
            -0.019257061183452606,
            -0.002441947814077139,
            -0.013062470592558384,
            0.022744057700037956,
            0.024882977828383446,
            -0.004524612333625555,
            0.03493286296725273,
            -0.035706255584955215,
            0.02347164787352085,
            -0.010964114218950272,
            -0.014313345775008202,
            -0.02932155877351761,
            -0.008106756955385208,
            -0.060528263449668884,
            -0.023058664053678513,
            -0.015223427675664425,
            0.002756996313109994,
            -0.003557725576683879,
            0.011980301700532436,
            -0.0200670026242733,
            -0.0010808415245264769,
            -0.02606247179210186,
            -0.002079540863633156,
            0.01803840510547161,
            0.008796489797532558,
            0.04264487698674202,
            -0.008579319342970848,
            -0.01580728031694889,
            -0.019700055941939354,
            0.02482820674777031,
            0.014380823820829391,
            0.022577088326215744,
            -0.02548336237668991,
            -0.007879921235144138,
            0.0024788808077573776,
            -0.04932833090424538,
            0.0037361907307058573,
            -0.008748860098421574,
            -0.02531719021499157,
            -0.0009450280340388417,
            -0.04181354120373726,
            0.020932378247380257,
            -0.04172389954328537,
            0.020726902410387993,
            -0.021106557920575142,
            0.006136578042060137,
            0.007255226839333773,
            -0.026668475940823555,
            -0.0356224961578846,
            -0.003472333075478673,
            -0.020845450460910797,
            -0.025969896465539932,
            0.033696819096803665,
            0.037833619862794876,
            -0.019211748614907265,
            -0.013919906690716743,
            0.011332312598824501,
            -0.0016260698903352022,
            0.005464053712785244,
            -0.016228191554546356,
            0.0058350590988993645,
            -0.012156648561358452,
            -0.022548964247107506,
            -0.024989424273371696,
            0.0015555588761344552,
            -0.035822320729494095,
            0.016388412564992905,
            -0.00010776736598927528,
            -0.008074944838881493,
            -0.006861448287963867,
            -0.0010930197313427925,
            0.004511720966547728,
            -0.030067862942814827,
            0.015122396871447563,
            0.016364693641662598,
            -0.008479679003357887,
            -0.01855337992310524,
            -0.020710229873657227,
            -0.011537101119756699,
            0.042559754103422165,
            0.005458353087306023,
            0.014176981523633003,
            0.039707332849502563,
            0.025296932086348534,
            -0.01576528698205948,
            0.02282586507499218,
            0.037128034979104996,
            -0.017574576660990715,
            -0.027998821809887886,
            -0.008203570730984211,
            0.020472556352615356,
            0.0093696229159832,
            0.016517287120223045,
            -0.004673757124692202,
            0.02024904638528824,
            0.012385576032102108,
            0.0023086636792868376,
            -0.015463809482753277,
            0.0037562698125839233,
            0.004453560803085566,
            -0.0007943943492136896,
            0.004027725663036108,
            0.01567109115421772,
            0.029570382088422775,
            -0.018044203519821167,
            0.004864427726715803,
            0.0040708277374506,
            -0.0016783390892669559,
            0.01303177047520876,
            0.017930883914232254,
            -0.016026660799980164,
            -0.01192090380936861,
            0.032973192632198334,
            -0.022704163566231728,
            0.03071148507297039,
            -0.030879030004143715,
            0.0016151618910953403,
            0.023121628910303116,
            0.005844279658049345,
            0.047054510563611984,
            -0.00048648129450157285,
            0.030210968106985092,
            0.04324191436171532,
            0.012781305238604546,
            -0.0005288019892759621,
            -0.024820663034915924,
            -0.01644696108996868,
            0.015498663298785686,
            -0.028979428112506866,
            -0.00129408307839185,
            0.026604533195495605,
            0.022313255816698074,
            0.008586639538407326,
            -0.0013817236758768559,
            0.013838584534823895,
            0.028620520606637,
            -0.01989736780524254,
            -0.052023932337760925,
            0.000479804613860324,
            0.01507005374878645,
            -0.01405454520136118,
            -0.016106853261590004,
            -0.02741825021803379,
            0.02449672296643257,
            0.039303459227085114,
            -0.004708186723291874,
            -0.03183085843920708,
            0.018975121900439262,
            -0.029785949736833572,
            0.030720962211489677,
            -0.02192271128296852,
            -0.012678669765591621,
            0.004893200937658548,
            -0.021534457802772522,
            -0.007569616660475731,
            0.02122001349925995,
            0.01642557606101036,
            -0.0035072886385023594,
            0.004565369803458452,
            0.0282555241137743,
            -0.0022994019091129303,
            -0.015739236027002335,
            0.03504306077957153,
            -0.018703358247876167,
            -0.015820635482668877,
            0.025300923734903336,
            -0.04958648234605789,
            0.016550337895751,
            -0.0032629615161567926,
            -0.026056531816720963,
            -0.022252682596445084,
            -0.0048380205407738686,
            -0.05034925416111946,
            9.265726839657873e-05,
            -0.017933061346411705,
            0.006266907323151827,
            0.033735040575265884,
            -0.023686863481998444,
            0.02793065458536148,
            0.01422880683094263,
            0.007059778086841106,
            0.030767811462283134,
            -0.013198128901422024,
            -0.017737649381160736,
            0.006106238346546888,
            0.01890261471271515,
            -0.04268669709563255,
            -0.028557443991303444,
            -0.004727266728878021,
            -0.02746281400322914,
            0.0018166137160733342,
            0.01026360783725977,
            -0.003929175436496735,
            0.0127480523660779,
            0.02733372151851654,
            -0.008577537722885609,
            0.0022284749429672956,
            -0.04413754865527153,
            0.010723297484219074,
            -0.01646248623728752,
            0.004307149909436703,
            -0.044963493943214417,
            -0.012554539367556572,
            -0.02661838009953499,
            0.019601086154580116,
            0.007105405442416668,
            -0.016390936449170113,
            -0.007307891733944416,
            0.014766011387109756,
            -0.011755133047699928,
            0.04538726061582565,
            0.01869422197341919,
            0.015903230756521225,
            0.021974628791213036,
            -0.00460865767672658,
            -0.036772239953279495,
            0.0319325253367424,
            -0.02362045645713806,
            -0.03404004126787186,
            -0.022872518748044968,
            -0.02322334423661232,
            0.030832936987280846,
            0.01971816085278988,
            -0.013949957676231861,
            0.029221002012491226,
            -0.006997984368354082,
            0.03304022178053856,
            -0.021144017577171326,
            0.03920658305287361,
            0.02855893038213253,
            -0.029822105541825294,
            -0.002981455996632576,
            -0.041105903685092926,
            0.002340499544516206,
            0.02540416084229946,
            -0.007488989271223545,
            -0.0014299898175522685,
            0.007044889964163303,
            0.009776381775736809,
            -0.0047636437229812145,
            -0.022559111937880516,
            0.010094833560287952,
            0.03499777615070343,
            0.015189855359494686,
            0.003587815910577774,
            -0.013638914562761784,
            -0.006394874770194292,
            0.027666406705975533,
            -0.01565268635749817,
            0.009686348959803581,
            0.04523537680506706,
            0.010490071959793568,
            0.03217262029647827,
            -0.00967958103865385,
            -0.058910734951496124,
            0.034245867282152176,
            -0.021689733490347862,
            0.020371919497847557,
            0.029437558725476265,
            0.008967085741460323,
            0.03193201869726181,
            0.012874558568000793,
            -0.0019207142759114504,
            -0.0259524155408144,
            0.03915003687143326,
            -0.015536685474216938,
            0.02593640796840191,
            0.023684842512011528,
            0.05034216120839119,
            -0.010592149570584297,
            0.009441587142646313,
            0.008869708515703678,
            0.009267277084290981,
            0.005161182954907417,
            0.014070458710193634,
            0.01451160479336977,
            0.002839823719114065,
            -0.05017485469579697,
            -0.05413789302110672,
            -0.012453610077500343,
            -0.016690021380782127,
            -0.007815110497176647,
            0.01682298071682453,
            -0.04237567260861397,
            0.0035495348274707794,
            -0.014855431392788887,
            0.02092931792140007,
            -0.0067269750870764256,
            -0.024456340819597244,
            0.006392830051481724,
            0.0342772975564003,
            0.023107394576072693,
            -0.028215574100613594,
            -0.020949937403202057,
            0.025161784142255783,
            0.017112972214818,
            -0.017947131767868996,
            0.0134096909314394,
            0.016947388648986816,
            0.03360789269208908,
            0.013052665628492832,
            0.009714104235172272,
            0.02053816244006157,
            0.02338242717087269,
            -0.004566161893308163,
            -0.02079627476632595,
            -0.07309722155332565,
            0.027076074853539467,
            0.02093438245356083,
            0.06272505223751068,
            0.04691189154982567,
            0.03271561488509178,
            -0.021232936531305313,
            0.0032852215226739645,
            -0.019294392317533493,
            -0.05152880400419235,
            0.016571253538131714,
            0.020332982763648033,
            -0.012109240517020226,
            0.019525831565260887,
            0.01267966441810131,
            -0.020549150183796883,
            0.031164107844233513,
            0.016925817355513573,
            0.0024366630241274834,
            0.010425182059407234,
            0.02400977350771427,
            0.005759245716035366,
            0.019354894757270813,
            0.015027509070932865,
            0.03143627196550369,
            -0.011584658175706863,
            -0.01954437792301178,
            0.010281766764819622,
            -0.004137510899454355,
            -0.010428588837385178,
            0.010741244070231915,
            -0.01884380355477333,
            0.01067863218486309,
            -0.023195762187242508,
            3.628460763138719e-05,
            0.03519970551133156,
            -0.02016042359173298,
            0.020160650834441185,
            0.02611551061272621,
            0.005806584842503071,
            0.030727902427315712,
            0.01230282336473465,
            0.017384596168994904,
            -0.017375070601701736,
            -0.017804615199565887,
            -0.009670514613389969,
            0.02479615807533264,
            0.02721051499247551,
            0.021053412929177284,
            0.023272186517715454,
            -0.013316964730620384,
            0.011172422207891941,
            -0.0023055770434439182,
            0.023070700466632843,
            -0.020273510366678238,
            0.014684363268315792,
            0.005149499047547579,
            0.03282686322927475,
            0.024315765127539635,
            0.006461679004132748,
            0.023178542032837868,
            0.033948350697755814,
            0.014912002719938755,
            0.009089930914342403,
            -0.01649441383779049,
            -0.02266998216509819,
            0.006778071168810129,
            0.001661808812059462,
            0.039076536893844604,
            0.005748510826379061,
            -0.017813991755247116,
            0.008970635943114758,
            -0.02194821648299694,
            0.02541159652173519,
            -0.026987235993146896,
            0.006689528468996286,
            0.01821272447705269,
            0.0049671209417283535,
            -0.0032172088976949453,
            0.022958161309361458,
            -0.003353019477799535,
            -0.04292215034365654,
            0.006363960448652506,
            0.003683432936668396,
            0.020845001563429832,
            0.002465390833094716,
            -0.016839176416397095,
            -0.02220030501484871,
            0.01306008081883192,
            0.004678187891840935,
            0.012934839352965355,
            0.023081136867403984,
            0.04789578169584274,
            -0.0020830524154007435,
            -0.005406220909208059,
            -7.113820902304724e-05,
            -0.003578194649890065,
            -0.0014113615034148097,
            -0.026155803352594376,
            -0.018642853945493698,
            0.01134069450199604,
            0.016519203782081604,
            0.013937774114310741,
            0.00020508294983301312,
            -0.00958118587732315,
            0.01523085031658411,
            -0.0233161523938179,
            0.0006953705451451242,
            0.005715282633900642,
            -0.00951318722218275,
            0.02207818627357483,
            0.02508372813463211,
            -0.004099182318896055,
            0.017193136736750603,
            -0.0004921400686725974,
            -0.0006326873553916812,
            -0.004015231039375067,
            -0.016812080517411232,
            0.011246223002672195,
            -0.0045690713450312614,
            0.016600223258137703,
            0.005315111484378576,
            -0.025321058928966522,
            0.03576643392443657,
            -0.0326436422765255,
            -0.035906072705984116,
            0.009357321076095104,
            -0.026637427508831024,
            0.02336087077856064,
            -0.03280956670641899,
            0.02522115223109722,
            0.02359001711010933,
            0.02967713586986065,
            0.011748683638870716,
            -0.04116855561733246,
            0.03758751600980759,
            -0.019457977265119553,
            0.019599568098783493,
            -0.0033561589661985636,
            -0.0025171367451548576,
            -0.00031853120890446007,
            -0.02501412108540535,
            0.00878504291176796,
            -0.023371627554297447,
            0.0029235780239105225,
            0.003423631191253662,
            0.021765219047665596,
            0.012885303236544132,
            0.020955340936779976,
            -0.020237836986780167,
            -0.019398774951696396,
            0.005449126940220594,
            -0.00226899073459208,
            -0.004195486661046743,
            0.02977890521287918,
            -0.011841099709272385,
            -0.037625353783369064,
            -0.010392624884843826,
            -0.010525534860789776,
            0.015157772228121758,
            0.04199809953570366,
            -0.013839439488947392,
            -0.018319671973586082,
            0.029186101630330086,
            -0.02121264487504959,
            0.004468134604394436,
            0.005513613577932119,
            -0.020854953676462173,
            -0.027634473517537117,
            0.026395248249173164,
            0.01522047445178032,
            0.060233406722545624,
            -0.0047500282526016235,
            -0.0070236846804618835,
            0.02091243863105774,
            0.03551143407821655,
            0.00428901007398963,
            -0.00277315778657794,
            0.026201052591204643,
            -0.012136273086071014,
            0.025249598547816277,
            -0.005746286362409592,
            0.029881848022341728,
            -0.023539790883660316,
            0.00019620232342276722,
            0.006381515879184008,
            -0.0015617500757798553,
            0.01068925205618143,
            -0.006148050539195538,
            -0.02768540382385254,
            -0.022012116387486458,
            -0.003680161666125059,
            0.006847089622169733,
            -0.026892121881246567,
            0.0018382749985903502,
            0.017688343301415443,
            -0.01418926753103733,
            -0.03359003737568855,
            0.017565755173563957,
            -0.00048650053213350475,
            0.011190278455615044,
            0.011715578846633434,
            0.011192589066922665,
            0.029330654069781303,
            0.01569310948252678,
            -0.005486936774104834,
            0.00014986404858063906,
            -0.016067558899521828,
            0.007326887454837561,
            0.006367099937051535,
            0.03896471485495567,
            0.008967887610197067,
            0.0266860481351614,
            -0.019503716379404068,
            0.03383786603808403,
            0.025188971310853958,
            0.03812137618660927,
            0.030525386333465576,
            -0.013504698872566223,
            0.005503643304109573,
            -0.01676017791032791,
            0.0237120408564806,
            -0.0186386089771986,
            0.03189069405198097,
            -0.007797543425112963,
            0.016629952937364578,
            -0.021598748862743378,
            -0.0143244294449687,
            -0.013869768008589745,
            0.02492416836321354,
            0.0193206537514925,
            -0.0651109591126442,
            -0.00555166183039546,
            0.029162628576159477,
            0.015437929891049862,
            -0.01752372272312641,
            0.038513991981744766,
            0.017027752473950386,
            0.009765946306288242,
            -0.006506863981485367,
            -0.005330789368599653,
            -0.0022049243561923504,
            0.004948527552187443,
            -0.012834526598453522,
            0.020201437175273895,
            0.023027488961815834,
            -0.012084262445569038,
            0.02849511429667473,
            0.020048676058650017,
            0.028624119237065315,
            -0.013652224093675613,
            0.005513019859790802,
            0.007010730914771557,
            -0.07261119037866592,
            -0.019397670403122902,
            0.03522787243127823,
            0.0010490674758329988,
            -0.02575199119746685,
            0.021240849047899246,
            0.028946727514266968,
            -0.016054833307862282,
            -0.006267967168241739,
            0.06107241287827492,
            -0.006512173917144537,
            -0.014166662469506264,
            -0.008905707858502865,
            0.0028869325760751963,
            0.04376008361577988,
            -0.0181207787245512,
            -0.012321948073804379,
            -0.02349333092570305,
            0.01468981709331274,
            -0.025989042595028877,
            0.02016044408082962,
            -0.005330652929842472,
            0.02070370316505432,
            0.022573761641979218,
            -0.014547688886523247,
            0.04648962616920471,
            0.008469266816973686,
            0.01573488488793373,
            0.016977697610855103,
            0.03275003656744957,
            0.02823290415108204,
            -0.02441440522670746,
            -0.013531001284718513,
            -0.022763391956686974,
            -0.015549194999039173,
            0.0447113998234272,
            0.011873426847159863,
            -0.04971287027001381,
            0.026173988357186317,
            0.009097342379391193,
            -0.003218443365767598,
            0.005612475797533989,
            -0.012635140679776669,
            0.029706167057156563,
            -0.000550175434909761,
            -0.00968170166015625,
            -0.0036651550326496363,
            0.008658915758132935,
            -0.0006972255650907755,
            -0.012185751460492611,
            -0.005232116207480431,
            0.007454526610672474,
            0.028494667261838913,
            -0.013880998827517033,
            -0.008020477369427681,
            -0.015667978674173355,
            -0.026398247107863426,
            0.023634808138012886,
            0.022247742861509323,
            -0.005889952182769775,
            -0.0029740205500274897,
            -0.011369992978870869,
            0.040032096207141876,
            0.019701357930898666,
            -0.006257919128984213,
            -0.02470621094107628,
            0.006677232217043638,
            0.032060932368040085,
            0.01777752861380577,
            -0.016799986362457275,
            -0.00974871963262558,
            0.024380380287766457,
            -0.039577044546604156,
            0.015641216188669205,
            -0.03283693268895149,
            -0.00784302968531847,
            0.047233354300260544,
            -0.0012414534576237202,
            0.00629881676286459,
            0.0031645498238503933,
            -0.0031857381109148264,
            0.002694258699193597,
            -0.024684598669409752,
            0.012630408629775047,
            0.01742527447640896,
            -0.014615620486438274,
            0.026206890121102333,
            0.03112025558948517,
            -0.005995116196572781,
            0.03477312996983528,
            0.015458096750080585,
            -0.004762175027281046,
            0.008746556006371975,
            -0.027762092649936676,
            0.008017012849450111,
            0.017691217362880707,
            0.0004747522179968655,
            -0.02965431846678257,
            0.0010341942543163896,
            0.0012221215292811394,
            -0.004816657863557339,
            -0.0006649178103543818,
            0.02000534161925316,
            0.03302657604217529,
            0.021832140162587166,
            -0.007725503295660019,
            -0.0018849343759939075,
            -0.0026450683362782,
            -0.004968937486410141,
            -0.03876301646232605,
            0.014158620499074459,
            0.008582652546465397,
            -0.030588852241635323,
            -0.035824667662382126,
            0.01736793853342533,
            -0.016017010435461998,
            0.01065827812999487,
            0.011073709465563297,
            -0.006111496593803167,
            0.01742323860526085,
            -0.005265690851956606,
            0.018757661804556847,
            0.005839652381837368,
            0.03006955422461033,
            0.02050524577498436,
            0.037214312702417374,
            0.009475541301071644,
            -0.037562303245067596,
            -0.004460210911929607,
            0.023368004709482193,
            0.0037654200568795204,
            0.008619301952421665,
            0.011399215087294579,
            0.02271086536347866,
            -0.046182237565517426,
            -0.04548909142613411,
            0.0150772575289011,
            -0.034904394298791885,
            0.023954667150974274,
            -0.03797048330307007,
            -0.029579197987914085,
            -0.01888703741133213,
            0.0006178148905746639,
            0.015536831691861153,
            -0.011190636083483696,
            -0.043945785611867905,
            0.021929580718278885,
            0.017967112362384796,
            -0.014875910244882107,
            -0.04671395942568779,
            0.0145350256934762,
            0.02093128301203251,
            -0.004159916657954454,
            0.04195132851600647,
            0.03433744236826897,
            0.0031366387847810984,
            -0.024731382727622986,
            0.018573610112071037,
            0.035800013691186905,
            -0.014897544868290424,
            0.012087630107998848,
            0.025832347571849823,
            -0.027578862383961678,
            0.02285696566104889,
            0.01329794805496931,
            -0.048110127449035645,
            -0.016116317361593246,
            -0.012352794408798218,
            -0.050223276019096375,
            -0.007734379265457392,
            0.03130214661359787,
            0.0004782607138622552,
            0.0037880507297813892,
            0.0167258121073246,
            0.018556248396635056,
            -0.01176802534610033,
            0.04090011119842529,
            -0.0214754119515419,
            0.0034253390040248632,
            0.02256995439529419,
            0.006415214389562607,
            -0.010908002965152264,
            -0.008302344009280205,
            0.035494375973939896,
            -0.008228931576013565,
            -0.011389516294002533,
            -0.027172889560461044,
            0.0014019720256328583,
            0.009331787005066872,
            -0.006524050608277321,
            0.0028353463858366013,
            0.04339705407619476,
            -0.008997302502393723,
            -0.009442116133868694,
            -0.01730656996369362,
            -0.023071900010108948,
            0.02825465425848961,
            0.027585625648498535,
            0.022886430844664574,
            0.0019797750283032656,
            -0.019014770165085793,
            -0.002646124456077814,
            -0.0016927452525123954,
            -0.01066907960921526,
            0.038051728159189224,
            0.026895929127931595,
            0.03117254748940468,
            0.030846666544675827,
            0.006551617290824652,
            0.005066038575023413,
            0.030387556180357933,
            0.019937779754400253,
            -0.02006695605814457,
            -0.013376780785620213,
            0.014451210387051105,
            -0.014954573474824429,
            -0.036423876881599426,
            0.024465078487992287,
            0.019634030759334564,
            -0.0442015565931797,
            0.004875934217125177,
            -0.01209393609315157,
            0.019101856276392937,
            -0.007838510908186436,
            0.006555576808750629,
            -0.004806954879313707,
            0.01539119053632021,
            -0.031991928815841675,
            -0.015254426747560501,
            -0.041495874524116516,
            -0.002800963120535016,
            0.00692341011017561,
            -0.019786637276411057,
            0.009483776055276394,
            0.014849039725959301,
            -0.03330716863274574,
            0.016132136806845665,
            -0.03200468420982361,
            -0.032068882137537,
            -0.00970930140465498,
            -0.012570032849907875,
            0.0158586073666811,
            -0.01726623997092247,
            -0.002189806429669261,
            -0.04389475658535957,
            0.005310397129505873,
            0.022844774648547173,
            0.004576581530272961,
            -0.0145976347848773,
            0.006851574406027794,
            -0.023551419377326965,
            -0.006455236580222845,
            -0.0013135963818058372,
            -0.006935582961887121,
            0.04184143617749214,
            -0.0008514061919413507,
            -0.008269690908491611,
            0.06072021275758743,
            0.031711503863334656,
            0.005569060333073139,
            0.019699208438396454,
            0.00840786937624216,
            0.020018387585878372,
            0.01127915270626545,
            0.02884320355951786,
            -0.024519704282283783,
            0.02306625060737133,
            0.01689683087170124,
            0.016994494944810867,
            0.011494932696223259,
            -0.022595208138227463,
            -0.0008773327572271228,
            0.02489384263753891,
            -0.022429632022976875,
            0.018817035481333733,
            -0.005402860697358847,
            -0.012616504915058613,
            0.013067161664366722,
            0.034090377390384674,
            0.020810838788747787,
            -0.037291355431079865,
            -0.014818190596997738,
            -0.03359933942556381,
            -0.015065796673297882,
            -0.03965432569384575,
            0.004699467681348324
        ],
        [
            0.0047080316580832005,
            0.014147991314530373,
            -0.005432507488876581,
            0.02586950920522213,
            0.019589612260460854,
            -0.007580648176372051,
            -0.029684487730264664,
            -0.02156480960547924,
            0.027562201023101807,
            -0.0022846029605716467,
            0.033003728836774826,
            -0.025230247527360916,
            0.03842097893357277,
            0.01695600524544716,
            -0.014892296865582466,
            0.007628665771335363,
            0.01704573631286621,
            0.011351430788636208,
            -0.04784625396132469,
            0.016130942851305008,
            0.03382282331585884,
            0.0201543178409338,
            -0.012208550237119198,
            -0.02818986400961876,
            0.028399134054780006,
            -0.02991619147360325,
            0.010665257461369038,
            0.017863748595118523,
            0.02632630057632923,
            0.024279803037643433,
            -0.0548829510807991,
            0.003160715103149414,
            0.023294584825634956,
            0.018318980932235718,
            0.030908748507499695,
            -0.030951444059610367,
            -0.006175060756504536,
            0.01909533143043518,
            -0.024265596643090248,
            -0.008753156289458275,
            -0.016310302540659904,
            0.01508628111332655,
            -0.004266880918294191,
            -0.017896108329296112,
            0.01756621152162552,
            0.006761158350855112,
            0.029947321861982346,
            0.013563873246312141,
            0.006349943112581968,
            -0.02136562205851078,
            -0.01384318433701992,
            -0.006852609571069479,
            -0.006766832433640957,
            0.03607013821601868,
            -0.052691780030727386,
            0.025737697258591652,
            0.01991848647594452,
            0.018619542941451073,
            0.035832129418849945,
            -2.262047019030433e-05,
            0.0013408800587058067,
            -0.03501154109835625,
            0.025212151929736137,
            9.951826359611005e-05,
            0.024028349667787552,
            -0.03733107075095177,
            0.04322635754942894,
            0.01510718371719122,
            0.0011729965917766094,
            -0.010021516121923923,
            -0.01828405261039734,
            -0.01397096086293459,
            0.0304450411349535,
            0.011981434188783169,
            0.022343473508954048,
            -0.04319547489285469,
            0.007743274327367544,
            -0.0198697280138731,
            0.036828961223363876,
            -0.010235292837023735,
            -0.026377562433481216,
            -0.015858719125390053,
            -0.007953817956149578,
            -0.0007338728755712509,
            2.729729203565512e-05,
            -0.009035695344209671,
            0.005831052549183369,
            0.015648918226361275,
            0.002976417075842619,
            -0.011249505914747715,
            -0.020663956180214882,
            0.008359256200492382,
            -0.003493168391287327,
            0.008902314119040966,
            0.013731691054999828,
            0.04606242850422859,
            0.007248906884342432,
            0.020062174648046494,
            0.07637059688568115,
            0.003087397664785385,
            -0.0006204292294569314,
            -0.058244142681360245,
            -0.034444939345121384,
            -0.016328413039445877,
            -0.01658792234957218,
            0.025633269920945168,
            -0.025062061846256256,
            -0.0036354544572532177,
            0.060264140367507935,
            -0.021391702815890312,
            -0.018845701590180397,
            0.01443030871450901,
            -0.025802643969655037,
            -0.029559338465332985,
            -0.010291830636560917,
            0.014986206777393818,
            0.025257980450987816,
            0.05137208476662636,
            -0.028636356815695763,
            -0.003458995372056961,
            0.01732812076807022,
            -0.015562317334115505,
            0.0014102660352364182,
            -0.01833658665418625,
            -0.016062434762716293,
            0.01692018285393715,
            0.0033989334478974342,
            0.028722601011395454,
            0.030733468011021614,
            0.033640503883361816,
            0.016087409108877182,
            0.0006249048165045679,
            -0.003664076328277588,
            0.005983041133731604,
            0.0207248292863369,
            0.032854821532964706,
            0.01442643254995346,
            0.014287985861301422,
            -0.004473516251891851,
            -0.006460409611463547,
            -0.03309262543916702,
            0.015041124075651169,
            0.00828597042709589,
            0.01890919916331768,
            0.024559583514928818,
            0.03100891225039959,
            -0.014151399955153465,
            0.04339031130075455,
            -0.01938139647245407,
            0.03115745633840561,
            -0.01918976940214634,
            -0.02551097609102726,
            0.009826796129345894,
            -0.020281191915273666,
            -0.00851379707455635,
            0.02080407366156578,
            0.044859837740659714,
            0.0053640007972717285,
            0.024842893704771996,
            0.002978990552946925,
            -0.02866240218281746,
            -0.00920555554330349,
            0.01585995778441429,
            0.009907657280564308,
            0.014750184491276741,
            -0.0019570670556277037,
            0.001807310269214213,
            -0.03154213726520538,
            0.02397124096751213,
            -0.05445646122097969,
            -0.044415753334760666,
            0.03549553081393242,
            -0.00043189467396587133,
            -0.0012437787372618914,
            -0.007552270777523518,
            -0.014436649158596992,
            0.028113313019275665,
            -0.039199091494083405,
            0.000804523762781173,
            0.00366735621355474,
            -0.002323373919352889,
            -0.0011911573819816113,
            0.04094702750444412,
            -0.011795399710536003,
            0.03131581470370293,
            0.0162044670432806,
            0.00305052287876606,
            -0.03077627345919609,
            0.010563899762928486,
            -0.008275831118226051,
            -0.06453721970319748,
            0.018427064642310143,
            0.04728347435593605,
            0.017920196056365967,
            0.03407234698534012,
            -0.011722967028617859,
            -0.04513074830174446,
            -0.028716035187244415,
            -0.0030077926348894835,
            0.02014378271996975,
            0.01972443237900734,
            0.0006228442653082311,
            0.000548466807231307,
            0.03365621343255043,
            0.09668775647878647,
            0.010010506957769394,
            0.008784761652350426,
            -0.0009657923365011811,
            -0.03559420257806778,
            0.0026458639185875654,
            4.267750773578882e-05,
            -0.003919003996998072,
            0.011665052734315395,
            -0.010173819959163666,
            -0.03255802020430565,
            -0.036480244249105453,
            -0.001345720374956727,
            0.04555707797408104,
            -0.002755245193839073,
            -0.01955445297062397,
            -0.021010352298617363,
            -0.04854637756943703,
            0.01955088973045349,
            0.03902239724993706,
            0.029651129618287086,
            0.014384671114385128,
            0.020168712362647057,
            0.02624838799238205,
            -0.008242088370025158,
            -0.003681752597913146,
            -0.006879819557070732,
            0.016472049057483673,
            -0.012823140248656273,
            -0.003972546197474003,
            0.02775716595351696,
            0.020511198788881302,
            -0.03294779360294342,
            -0.015771539881825447,
            -0.020707150921225548,
            0.008945073001086712,
            2.15431755350437e-05,
            0.004090604372322559,
            -0.029842793941497803,
            0.003738612635061145,
            -0.04736107215285301,
            -0.012986545450985432,
            -0.03882041573524475,
            -0.0244594719260931,
            -0.012241041287779808,
            -0.03738609701395035,
            -0.013146840035915375,
            0.004166159778833389,
            0.05519699305295944,
            -0.013927004300057888,
            0.02094242349267006,
            -0.009779439307749271,
            -0.03803291544318199,
            0.005322303622961044,
            0.03232093155384064,
            0.02805381827056408,
            -0.007113364525139332,
            0.020275037735700607,
            0.04442895948886871,
            -0.01804032362997532,
            0.02447499893605709,
            -0.00539015419781208,
            -0.004390034358948469,
            0.024243071675300598,
            -0.03923271223902702,
            0.006785492412745953,
            0.006754088215529919,
            -0.04249925911426544,
            -0.02116616629064083,
            0.020852867513895035,
            -0.048291172832250595,
            -0.03300543129444122,
            0.03338486701250076,
            0.01743641123175621,
            0.025524014607071877,
            0.021419301629066467,
            -0.005082531366497278,
            0.0029177539981901646,
            0.006327858194708824,
            0.024855533614754677,
            -0.016949083656072617,
            -0.008475681766867638,
            -0.003186270594596863,
            0.01670902781188488,
            0.012971407733857632,
            -0.019540276378393173,
            0.014511713758111,
            0.010752680711448193,
            -0.02571241557598114,
            0.003752574324607849,
            0.014500463381409645,
            0.004869632422924042,
            -0.027729908004403114,
            0.0031029502861201763,
            0.04228401184082031,
            -0.00289147743023932,
            0.025835860520601273,
            0.006761757656931877,
            -0.009441301226615906,
            0.003743409411981702,
            0.0022570204455405474,
            -0.03213417902588844,
            0.013164659030735493,
            -0.024266472086310387,
            -0.04913163185119629,
            -0.005865979008376598,
            0.008491972461342812,
            0.01502126082777977,
            0.000925098720472306,
            -0.023632371798157692,
            -0.029690241441130638,
            -0.07524585723876953,
            0.010896923020482063,
            -0.0019885865040123463,
            0.020968172699213028,
            0.006130662281066179,
            -0.020333126187324524,
            0.018101658672094345,
            0.026577726006507874,
            -0.09667140245437622,
            0.029069671407341957,
            0.011579815298318863,
            0.002384989522397518,
            -0.001823189202696085,
            -0.021135445684194565,
            0.033329837024211884,
            0.023552294820547104,
            -0.011349372565746307,
            0.02436261624097824,
            0.00356881832703948,
            0.035444896668195724,
            -0.009208176285028458,
            0.0033511051442474127,
            0.0045578922145068645,
            -0.02275477722287178,
            0.017709331586956978,
            -0.002805492840707302,
            0.002162832533940673,
            0.04031512141227722,
            -0.026031767949461937,
            0.02052474208176136,
            -0.045955684036016464,
            -0.03881164267659187,
            -0.016766129061579704,
            -0.014836468733847141,
            -0.023794423788785934,
            0.01970207691192627,
            -0.006628721486777067,
            -0.005765877198427916,
            -0.01815439946949482,
            0.023448089137673378,
            -0.005661743693053722,
            -0.020468026399612427,
            0.02049161307513714,
            -0.011442434974014759,
            0.02960519678890705,
            0.026050696149468422,
            -0.01776936650276184,
            0.025167418643832207,
            0.05113297328352928,
            -0.010774277150630951,
            -0.016504669561982155,
            -0.028465956449508667,
            -0.011227701790630817,
            0.00393934641033411,
            -0.014875116758048534,
            -0.0011067052837461233,
            0.01003015972673893,
            0.022830255329608917,
            -0.006311869248747826,
            0.011518964543938637,
            -0.002517274348065257,
            -0.004162000957876444,
            0.015590754337608814,
            0.019425975158810616,
            -0.053850945085287094,
            -0.028639309108257294,
            -0.021984316408634186,
            0.010130206122994423,
            -0.019119685515761375,
            -0.0006673297611996531,
            0.010253154672682285,
            0.003672068240121007,
            -0.09941577166318893,
            0.0034078964963555336,
            -0.004861684050410986,
            -0.015442518517374992,
            0.005745815113186836,
            0.0071335784159600735,
            0.023317813873291016,
            -0.02253354899585247,
            -0.01520085334777832,
            0.033782944083213806,
            0.04550906643271446,
            0.002437473740428686,
            0.001979315187782049,
            0.02164495177567005,
            0.01435979176312685,
            0.02161003276705742,
            -0.006082260049879551,
            -0.0017187711782753468,
            0.008483351208269596,
            0.005195778328925371,
            -0.027045616880059242,
            -0.027671964839100838,
            0.03279481828212738,
            0.0027233457658439875,
            0.003969456534832716,
            0.010444775223731995,
            0.0384037010371685,
            -0.002993254456669092,
            -0.016797170042991638,
            -0.020750274881720543,
            0.0010548274731263518,
            -0.029539436101913452,
            -0.014282657764852047,
            -0.05664001777768135,
            0.0038673067465424538,
            0.017020797356963158,
            -0.017171086743474007,
            0.04697704687714577,
            -0.00034474965650588274,
            0.007561449892818928,
            -0.005958103109151125,
            -0.005546017084270716,
            -0.030786167830228806,
            -0.01498507708311081,
            -0.018280286341905594,
            -0.0135299451649189,
            -0.01525355689227581,
            0.0340292789041996,
            -0.026205699890851974,
            0.0528932586312294,
            -0.017435044050216675,
            0.007673615124076605,
            0.009155651554465294,
            0.030008330941200256,
            -0.004900651052594185,
            -0.015532094985246658,
            0.0028799092397093773,
            -0.03178440034389496,
            0.02028619684278965,
            -0.024445224553346634,
            0.05277450755238533,
            0.029157057404518127,
            0.02724618837237358,
            0.0008648533257655799,
            0.023762881755828857,
            -0.028281616047024727,
            0.04128389433026314,
            -0.023739857599139214,
            -0.003992293030023575,
            0.015734316781163216,
            -0.0077035091817379,
            0.019922886043787003,
            0.042618922889232635,
            0.04671398550271988,
            -0.0005198157159611583,
            0.02876915968954563,
            -0.04094087705016136,
            0.017621077597141266,
            0.007007824722677469,
            -0.019798370078206062,
            -0.06793402135372162,
            -0.011811800301074982,
            0.009429714642465115,
            -0.015118318609893322,
            -0.015455781482160091,
            -0.007200208958238363,
            0.04200921580195427,
            -0.015174989588558674,
            -0.03019789233803749,
            0.01708216778934002,
            0.03188947215676308,
            -0.010434488765895367,
            0.04116925597190857,
            0.002129735192283988,
            0.016783611848950386,
            0.019473861902952194,
            0.0027791515458375216,
            -0.022127341479063034,
            0.029424617066979408,
            -0.04034465178847313,
            0.03235144168138504,
            -0.0634969174861908,
            0.004583214875310659,
            0.027398353442549706,
            -0.0017423424869775772,
            0.01911209151148796,
            0.0028584462124854326,
            -0.007683328352868557,
            -0.016922656446695328,
            -0.015771204605698586,
            -0.0020915737841278315,
            0.007844960317015648,
            -0.021967027336359024,
            -0.006302233785390854,
            -0.0017375719035044312,
            -0.013964015990495682,
            -0.018465885892510414,
            0.008486242033541203,
            0.03231550008058548,
            -0.020328082144260406,
            0.0026525305584073067,
            0.036930397152900696,
            0.03572678938508034,
            0.033400628715753555,
            -0.011448612436652184,
            -0.010796329006552696,
            -0.019942298531532288,
            -0.059203486889600754,
            -0.003949739038944244,
            -0.02139262482523918,
            -0.0067068906500935555,
            -0.019371476024389267,
            0.004200301133096218,
            0.01538542564958334,
            -0.014401061460375786,
            0.0030781154055148363,
            0.008078504353761673,
            0.04461045563220978,
            -0.027358336374163628,
            0.05644570291042328,
            -0.041176825761795044,
            0.01621609926223755,
            -0.004512176848948002,
            0.031958695501089096,
            0.004929529502987862,
            0.013785799033939838,
            0.018477117642760277,
            0.030343040823936462,
            -0.04037472605705261,
            -0.053318724036216736,
            0.009674688801169395,
            0.007239187136292458,
            -0.03942006081342697,
            0.008781183511018753,
            -0.028921552002429962,
            -0.0286532174795866,
            0.019282830879092216,
            0.013726339675486088,
            0.06286181509494781,
            -0.0289931483566761,
            0.014778189361095428,
            0.013552256859838963,
            0.00653875432908535,
            0.005504352506250143,
            0.0058200000785291195,
            0.010483695194125175,
            -0.061110761016607285,
            -0.010513310320675373,
            0.01687188819050789,
            -0.0189324039965868,
            -0.029196009039878845,
            0.03152255713939667,
            -0.03140401095151901,
            0.004985326435416937,
            0.02433542162179947,
            0.02513568103313446,
            -0.012113039381802082,
            -0.017814630642533302,
            -0.047991495579481125,
            0.0234183669090271,
            0.03627153858542442,
            0.014529443345963955,
            -0.01686020754277706,
            0.015660692006349564,
            0.017395444214344025,
            0.0006061901221983135,
            0.009909681044518948,
            -7.511049489039578e-07,
            -0.012721375562250614,
            -0.010111900977790356,
            0.01109751220792532,
            0.02736092358827591,
            0.01333654299378395,
            -0.006239829584956169,
            -0.0004924454260617495,
            -0.02630782499909401,
            0.04302530735731125,
            -0.016818620264530182,
            0.017880471423268318,
            0.04543441906571388,
            0.015377171337604523,
            0.011231816373765469,
            0.0005387426936067641,
            -0.00908072292804718,
            0.00045552290976047516,
            0.006968146190047264,
            -0.02740367501974106,
            -0.022626172751188278,
            0.012258815579116344,
            -0.018671395257115364,
            0.016941461712121964,
            0.015050585381686687,
            0.031122962012887,
            -0.003230396658182144,
            -0.0049920761957764626,
            0.04925009235739708,
            0.03480859845876694,
            -0.011486846022307873,
            -0.017407385632395744,
            0.02919243462383747,
            0.0034760579001158476,
            0.03384949266910553,
            -0.01776723377406597,
            -0.034222561866045,
            0.005478174425661564,
            -0.030663641169667244,
            -0.027311595156788826,
            -0.032564837485551834,
            0.020192069932818413,
            0.010668839327991009,
            -0.025595491752028465,
            -0.019112568348646164,
            0.028169671073555946,
            -0.025056738406419754,
            -0.003938168287277222,
            0.02371441200375557,
            0.0009147125529125333,
            -0.023485761135816574,
            -0.021435439586639404,
            0.01630450412631035,
            -0.0290901530534029,
            0.043160680681467056,
            0.030086509883403778,
            -0.02014898881316185,
            0.04179089516401291,
            0.0017959983088076115,
            0.015823857858777046,
            -0.0015370289329439402,
            0.01523606013506651,
            -0.01345240417867899,
            -0.007194492034614086,
            0.04383468255400658,
            0.02990964613854885,
            -0.019722076132893562,
            -0.04866388067603111,
            0.0013916974421590567,
            0.05449608340859413,
            0.012233452871441841,
            -0.023759208619594574,
            -0.037179190665483475,
            -0.01696557179093361,
            0.010187476873397827,
            -0.018339283764362335,
            0.02408086508512497,
            -0.004095397889614105,
            0.0056722876615822315,
            0.017955424264073372,
            -0.018287938088178635,
            -0.016003230586647987,
            -0.0017829651478677988,
            0.0023995463270694017,
            0.029934929683804512,
            -0.02314554899930954,
            -0.009449969045817852,
            0.012361363507807255,
            0.0141341183334589,
            0.002833495382219553,
            0.013087620958685875,
            0.03402906283736229,
            -0.014432857744395733,
            -0.0018896920373663306,
            -0.015069727785885334,
            0.01109555084258318,
            0.026782726868987083,
            0.03347038850188255,
            0.003060425166040659,
            -0.06784527003765106,
            0.021837377920746803,
            -0.02191699482500553,
            0.01231935154646635,
            -0.00901776272803545,
            -0.00814405083656311,
            -0.010465218685567379,
            0.0016492289723828435,
            0.013920304365456104,
            -0.007076125126332045,
            0.0015508376527577639,
            0.02341543324291706,
            0.010946052148938179,
            0.04851877689361572,
            0.006334471981972456,
            0.027205418795347214,
            -0.010583218187093735,
            0.04107952117919922,
            0.030383281409740448,
            -0.009940430521965027,
            0.024498112499713898,
            -0.014104552567005157,
            -0.025517836213111877,
            0.026678981259465218,
            -0.02694050595164299,
            0.0006269016303122044,
            -0.02667262591421604,
            -0.011992879211902618,
            -0.01075051911175251,
            -0.029010925441980362,
            -0.03379657119512558,
            -0.01594047248363495,
            0.020697301253676414,
            -0.027606436982750893,
            -0.011076048016548157,
            0.013047254644334316,
            0.018646424636244774,
            -0.007440182380378246,
            -0.021378016099333763,
            0.0132129592821002,
            -0.006811331957578659,
            -0.043622374534606934,
            -0.00441141938790679,
            0.057437047362327576,
            0.0063280584290623665,
            -0.009100347757339478,
            0.009845603257417679,
            0.0034461983013898134,
            0.010986775159835815,
            0.015480566769838333,
            0.0026746976654976606,
            0.024942995980381966,
            0.021642139181494713,
            -0.025404775515198708,
            -0.0065247779712080956,
            0.03276871517300606,
            0.010019238106906414,
            -0.00519148213788867,
            0.02765893004834652,
            0.013808316551148891,
            0.007295126095414162,
            -0.04665762558579445,
            -0.012449881061911583,
            0.005071858409792185,
            -0.050701744854450226,
            -0.042896583676338196,
            0.02659054473042488,
            0.0225136186927557,
            0.0095133101567626,
            -0.021756669506430626,
            0.01622329093515873,
            0.00808519497513771,
            -0.0004407485539559275,
            -0.03256896883249283,
            0.027963006868958473,
            -0.01792776957154274,
            -0.006409239023923874,
            0.005624766927212477,
            -0.007327544502913952,
            -0.036214642226696014,
            0.007677164860069752,
            -0.010281925089657307,
            0.00870656780898571,
            -0.019286800175905228,
            -0.00968980323523283,
            -0.007866691797971725,
            0.024206509813666344,
            -0.018955208361148834,
            0.02252686768770218,
            -0.05072368308901787,
            0.013810791075229645,
            0.031299691647291183,
            0.039149072021245956,
            -0.04310540854930878,
            -0.023671643808484077,
            0.022126661613583565,
            -0.010768873617053032,
            -0.037586014717817307,
            -0.04922937974333763,
            -0.01118729542940855,
            0.012406113557517529,
            0.023912232369184494,
            -0.0014164457097649574,
            0.05193508043885231,
            0.012731618247926235,
            0.01480166707187891,
            -0.036472536623477936,
            0.008061385713517666,
            0.0007084282697178423,
            0.025805721059441566,
            0.014781169593334198,
            -0.00115980114787817,
            0.023407088592648506,
            0.008537307381629944,
            0.008423739112913609,
            0.011462157592177391,
            0.011772504076361656,
            0.025210954248905182,
            -0.006511958781629801,
            0.011344129219651222,
            -0.009556426666676998,
            0.009908413514494896,
            0.003672783263027668,
            -0.05550391227006912,
            0.0370723120868206,
            0.0315900519490242,
            0.015690796077251434,
            0.008368351496756077,
            -0.00149294373113662,
            0.025010978803038597,
            0.027103101834654808,
            0.004034506157040596,
            0.008352069184184074,
            -0.01285712793469429,
            -0.01440642774105072,
            0.030065620318055153,
            0.02301172912120819,
            -0.0019407174549996853,
            0.0006934544071555138,
            -0.0075329323299229145,
            -0.027592435479164124,
            0.040507107973098755,
            -0.0026528972666710615,
            0.017590157687664032,
            0.0019060205668210983,
            0.008621176704764366,
            -0.003785162465646863,
            -0.0611199326813221,
            0.026505474001169205,
            0.0062369187362492085,
            0.003100991016253829,
            -0.02220637910068035,
            0.05477017164230347,
            0.012509633786976337,
            -0.02738168276846409,
            -0.015546921640634537,
            -0.0037078983150422573,
            0.00016770795627962798,
            -0.03236899524927139,
            0.013222163543105125,
            -0.00341344578191638,
            -0.04393800348043442,
            -0.015258235856890678,
            0.023994633927941322,
            0.043535009026527405,
            -0.016249196603894234,
            -0.00975137110799551,
            0.012250503525137901,
            0.020474757999181747,
            -0.008725522086024284,
            -0.033032823354005814,
            0.005280583165585995,
            0.009791177697479725,
            -0.004683442879468203,
            0.04113515838980675,
            -0.04324967414140701,
            -0.027164112776517868,
            0.03028068132698536,
            -0.017081303521990776,
            0.0033846739679574966,
            -0.028141463175415993,
            -0.02257925271987915,
            -0.019355153664946556,
            -0.005271757021546364,
            -0.013463787734508514,
            -0.004251161124557257,
            -0.011880648322403431,
            -0.001305445795878768,
            -0.034055981785058975,
            0.022445669397711754,
            0.026455530896782875,
            -0.010932601056993008,
            -0.03436842933297157,
            0.026503918692469597,
            0.033061433583498,
            0.002534680999815464,
            0.0368209108710289,
            -0.005148686468601227,
            -0.01439459528774023,
            -0.05237041786313057,
            0.037866003811359406,
            0.021516121923923492,
            0.05627838894724846,
            -0.00020127520838286728,
            0.02877986617386341,
            -0.0057951160706579685,
            -0.020112408325076103,
            0.0052601066417992115,
            -0.026633402332663536,
            0.03634855896234512,
            -0.0197583194822073,
            0.04244297742843628,
            0.02800762839615345,
            -0.006028285250067711,
            0.019354691728949547,
            -0.006435332354158163,
            0.0026464390102773905,
            0.022366249933838844,
            -0.012432332150638103,
            0.04686174914240837,
            -0.02918955497443676,
            -0.002645439002662897,
            -0.014757194556295872,
            -0.00333857792429626,
            0.04681806266307831,
            -0.015474231913685799,
            -0.03605791926383972,
            0.037555500864982605,
            -0.004046253859996796,
            -0.009630422107875347,
            0.018489308655261993,
            0.000621706887613982,
            0.023491669446229935,
            0.036554522812366486,
            0.017169032245874405,
            0.017395230010151863,
            0.013689697720110416,
            0.03423793241381645,
            0.014093182049691677,
            -0.04450124502182007,
            -0.03995325043797493,
            -0.081840381026268,
            0.03495970368385315,
            0.011573515832424164,
            0.009000393562018871,
            -0.004429031629115343,
            -0.00123168108984828,
            -0.007164371199905872,
            -0.014018713496625423,
            0.02070644311606884,
            0.023007342591881752,
            0.0712563544511795,
            -0.004626283422112465,
            -0.008152300491929054,
            -0.009105909615755081,
            0.02630028687417507,
            -0.03130491450428963,
            0.020748408511281013,
            -0.010679129511117935,
            -0.024962257593870163,
            -0.017574861645698547,
            0.012035488151013851,
            0.002951159607619047,
            -0.01462201401591301,
            -0.019469602033495903,
            -0.0197549257427454,
            0.034239280968904495,
            -0.015801534056663513,
            0.0014356100000441074,
            -0.005833331495523453,
            0.01513827033340931,
            -0.003318067407235503,
            0.018876492977142334,
            0.035074811428785324,
            -0.0020788086112588644,
            0.02375495620071888,
            -0.013678941875696182,
            -0.005318462383002043,
            0.017994025722146034,
            -0.00956481508910656,
            0.0005441791727207601,
            0.03658945858478546,
            -0.017775194719433784,
            0.004807410761713982,
            0.02739817276597023,
            0.01097145490348339,
            -0.01103202160447836,
            0.007663932628929615,
            0.04339207336306572,
            0.02799821086227894,
            -0.02665667049586773,
            0.010402865707874298,
            -0.010962896049022675,
            0.004218702204525471,
            -0.011040021665394306,
            0.001941585447639227,
            -0.03429057449102402,
            -0.010139167308807373,
            -0.0025017245206981897,
            -0.04426030069589615,
            -0.022139616310596466,
            -0.017001276835799217,
            0.003522134618833661,
            0.010479247197508812,
            -0.0031835970003157854,
            0.01362774707376957,
            -0.01233723759651184,
            0.013800039887428284,
            0.008330310694873333,
            0.02903652936220169,
            0.005397025030106306,
            -0.013200938701629639,
            -0.02610400691628456,
            -0.008683688938617706,
            0.02784860134124756,
            0.006158085539937019,
            -0.013430872932076454,
            -0.02160383202135563,
            0.010205133818089962,
            0.00022365464246831834,
            -0.010892495512962341,
            -0.03045232780277729,
            0.05761248990893364,
            0.01741022616624832,
            0.019668295979499817,
            -0.030980318784713745,
            0.017999235540628433,
            -0.0039961631409823895,
            -0.0667487308382988,
            -0.024716004729270935,
            -0.009973966516554356,
            0.03610796481370926,
            -0.02553839236497879,
            -0.011009590700268745,
            0.030171344056725502,
            -0.043572790920734406,
            0.030522486194968224,
            0.016165539622306824,
            0.029604490846395493,
            -0.012637697160243988,
            0.03444307669997215,
            0.021849224343895912,
            0.025073256343603134,
            0.004587912932038307,
            -0.024449216201901436,
            0.008222612552344799,
            0.00904271099716425,
            0.0229435283690691,
            -0.03640680015087128,
            0.018994782119989395,
            0.024217864498496056
        ],
        [
            -0.0291519146412611,
            -0.03200410306453705,
            0.016660576686263084,
            0.005491855554282665,
            0.02321891114115715,
            0.03567494451999664,
            -0.01922471448779106,
            0.00831010565161705,
            0.0025263167917728424,
            -0.003224493470042944,
            0.005508133210241795,
            0.02459445595741272,
            -0.03250543028116226,
            0.002596447244286537,
            0.02024674415588379,
            -0.018787255510687828,
            0.03713330999016762,
            -0.011184277944266796,
            0.01307442132383585,
            0.030032875016331673,
            0.031034985557198524,
            0.029094066470861435,
            -0.019961033016443253,
            -0.013426884077489376,
            0.019682759419083595,
            0.02011684514582157,
            -0.05127408728003502,
            -0.012050217017531395,
            -0.004420124925673008,
            -0.006052382290363312,
            -0.01515231654047966,
            -0.024443047121167183,
            0.03199945390224457,
            0.006481967866420746,
            -0.017361653968691826,
            0.00038018266786821187,
            -0.008887935429811478,
            -0.013612627051770687,
            -0.026053249835968018,
            0.022708775475621223,
            0.006068139337003231,
            -0.017174258828163147,
            -0.011694732122123241,
            0.024535909295082092,
            -0.0292735006660223,
            0.024725615978240967,
            -0.025260210037231445,
            0.006557171698659658,
            0.008486348204314709,
            -0.039206746965646744,
            0.01978941448032856,
            -0.011811167933046818,
            -0.033734679222106934,
            -0.03869333118200302,
            -0.00529807573184371,
            0.0022250935435295105,
            0.009208486415445805,
            -0.038202449679374695,
            0.021248964592814445,
            -0.021864136680960655,
            0.0153138916939497,
            0.02538532391190529,
            -0.010615634731948376,
            0.012165913358330727,
            0.0185341015458107,
            0.012936337850987911,
            -0.008463430218398571,
            -0.006000237539410591,
            0.029366975650191307,
            0.02365216426551342,
            0.025822985917329788,
            0.017788369208574295,
            -0.003888848004862666,
            -0.018781984224915504,
            0.0030115991830825806,
            -0.008457956835627556,
            -0.030233724042773247,
            -0.030503755435347557,
            0.004230470862239599,
            -0.055581457912921906,
            -0.019357973709702492,
            -0.0158594511449337,
            0.010089286603033543,
            0.032769825309515,
            -0.006970816757529974,
            0.00830864068120718,
            0.004356085788458586,
            0.005618261639028788,
            0.025258811190724373,
            0.020472999662160873,
            -0.006073369178920984,
            0.024009697139263153,
            -0.008267348632216454,
            -0.004123212769627571,
            -0.004015862476080656,
            0.005669100675731897,
            0.004135128576308489,
            -0.02357957698404789,
            -0.08522593230009079,
            0.023624006658792496,
            -0.006966401357203722,
            -0.03225257620215416,
            0.016720188781619072,
            0.0028657761868089437,
            -0.0122765451669693,
            -0.008598682470619678,
            0.03782927244901657,
            0.015598724596202374,
            0.013040249235928059,
            0.0005106116295792162,
            -0.0015492159873247147,
            -0.035581547766923904,
            -0.012756012380123138,
            0.007576142903417349,
            -0.0011235220590606332,
            0.028410544618964195,
            -0.022737912833690643,
            0.020629635080695152,
            0.031180057674646378,
            -0.030794231221079826,
            -0.01731266640126705,
            0.0051828669384121895,
            -0.0028588124550879,
            -0.01859639398753643,
            -0.00643232511356473,
            0.016302671283483505,
            -0.012461481615900993,
            0.024771910160779953,
            -0.008831270970404148,
            -0.007961414754390717,
            -0.024080166593194008,
            -0.022398987784981728,
            -0.004353656433522701,
            0.007499565836042166,
            0.018937673419713974,
            -0.005444005597382784,
            0.019831357523798943,
            0.031669750809669495,
            -0.03906261548399925,
            -0.006378917954862118,
            -0.01651558093726635,
            -0.011464093811810017,
            -0.004689904861152172,
            0.03294505923986435,
            0.04494696110486984,
            0.04270734637975693,
            -0.0030346382409334183,
            0.030858730897307396,
            -0.000919025915209204,
            0.016762953251600266,
            -0.001468104193918407,
            0.013989322818815708,
            0.04167339950799942,
            0.02689352072775364,
            -0.00774628110229969,
            -0.002486714394763112,
            0.02275203913450241,
            0.00445485208183527,
            -0.04792536422610283,
            0.010912907309830189,
            0.015157817862927914,
            -0.013743342831730843,
            -0.005832985043525696,
            -0.029047193005681038,
            -0.016858626157045364,
            -0.0011546894675120711,
            0.017754128202795982,
            -0.02687150426208973,
            -0.037830106914043427,
            0.03567863255739212,
            -0.01814327947795391,
            0.03431690111756325,
            -0.0319487638771534,
            0.009646281599998474,
            -0.012159645557403564,
            0.010572118684649467,
            0.02344498224556446,
            0.02198520302772522,
            -0.021484268829226494,
            0.01774287223815918,
            -0.012922818772494793,
            -0.021221201866865158,
            -0.05034441128373146,
            0.03657390549778938,
            -0.006555043160915375,
            0.011756056919693947,
            -0.038503821939229965,
            -0.01865704357624054,
            -0.010819274932146072,
            -0.022154323756694794,
            0.021188808605074883,
            0.028352072462439537,
            0.044523127377033234,
            -0.032645709812641144,
            -0.009671214036643505,
            0.011270546354353428,
            0.019009975716471672,
            -0.0002867392613552511,
            0.017977332696318626,
            0.005771442782133818,
            -0.0027214225847274065,
            -0.012675528414547443,
            0.030327187851071358,
            0.02710036374628544,
            0.03169025108218193,
            -0.006526929792016745,
            -0.027337368577718735,
            0.012214776128530502,
            -0.004915123339742422,
            0.01715182512998581,
            -0.010443317703902721,
            0.016526753082871437,
            0.002892611548304558,
            0.04052388668060303,
            0.007664816919714212,
            0.01163154561072588,
            -0.029102090746164322,
            -0.020168477669358253,
            0.01572634093463421,
            0.03401394933462143,
            0.0024303575046360493,
            -0.0017238723812624812,
            -0.01507424097508192,
            0.007119390647858381,
            0.030663924291729927,
            0.03489027917385101,
            -0.012647964991629124,
            0.008667811751365662,
            0.007091816980391741,
            -0.019376497715711594,
            0.017303969711065292,
            -0.006076638121157885,
            -0.012852687388658524,
            -0.012612491846084595,
            0.023137856274843216,
            -0.025280142202973366,
            0.03679846227169037,
            0.02937261387705803,
            -0.0011124563170596957,
            0.014120237901806831,
            -0.025376558303833008,
            -0.012868252582848072,
            0.010953080840408802,
            -0.018126439303159714,
            0.041255299001932144,
            -0.01784803532063961,
            0.02715623565018177,
            0.0072385407984256744,
            0.020256273448467255,
            0.016816243529319763,
            0.025314703583717346,
            0.03443438559770584,
            0.0119928615167737,
            0.03760577365756035,
            -0.02636442892253399,
            0.00371758546680212,
            -0.00021027569891884923,
            0.007185404188930988,
            -0.014087780378758907,
            0.0182102769613266,
            -0.006158367730677128,
            -0.015190832316875458,
            -0.027874089777469635,
            0.011885672807693481,
            -0.029826190322637558,
            0.019984286278486252,
            -0.0064214076846838,
            0.006271714344620705,
            0.010510420426726341,
            0.013512668199837208,
            0.003519201884046197,
            0.02193732187151909,
            -0.003847655141726136,
            -0.00021970899251755327,
            0.029054876416921616,
            0.025490321218967438,
            0.005102090537548065,
            -0.022438442334532738,
            0.009038183838129044,
            -0.002308166353031993,
            -0.00881942082196474,
            0.030057162046432495,
            -0.018456244841217995,
            -0.027304962277412415,
            -0.020440736785531044,
            -0.028263969346880913,
            0.029294492676854134,
            -0.014429238624870777,
            0.014084838330745697,
            0.015720125287771225,
            0.0128009133040905,
            -0.042508747428655624,
            0.007142224349081516,
            -0.010682103224098682,
            -0.014373578131198883,
            -0.0010728661436587572,
            0.0026016910560429096,
            0.013261733576655388,
            -0.020666679367423058,
            0.0020695726852864027,
            0.010289241559803486,
            0.02772069163620472,
            -0.016496438533067703,
            0.010961911641061306,
            0.020985648036003113,
            -0.03600889816880226,
            -0.015393978916108608,
            -0.010996386408805847,
            -0.0017459855880588293,
            0.0008963498403318226,
            -0.006201650481671095,
            -0.01956331916153431,
            0.030004899948835373,
            -0.008367327041924,
            -0.011281891725957394,
            0.026782143861055374,
            -0.006207625847309828,
            -0.023374496027827263,
            -0.012543224729597569,
            0.030257446691393852,
            0.005033377092331648,
            0.015543696470558643,
            -0.00909766647964716,
            0.0025729890912771225,
            -0.0002841676468960941,
            0.01527104340493679,
            -0.006425634957849979,
            0.011079722084105015,
            -0.017611797899007797,
            0.014478485099971294,
            -0.011474625207483768,
            -0.03348774462938309,
            -0.018288610503077507,
            0.005006854422390461,
            0.015196575783193111,
            -0.024348435923457146,
            0.025185726583003998,
            -0.008832849562168121,
            0.03252192959189415,
            -0.03366156667470932,
            0.03007642552256584,
            -0.019434284418821335,
            0.0196006428450346,
            -0.012507910840213299,
            -0.00019328951020725071,
            -0.02191702462732792,
            0.01668405719101429,
            -0.017519090324640274,
            0.011414497159421444,
            0.009997880086302757,
            0.028690900653600693,
            -0.01428290270268917,
            0.00868956372141838,
            0.0034852579701691866,
            0.045804962515830994,
            0.016942158341407776,
            0.004143599886447191,
            -0.024758024141192436,
            0.0005662096664309502,
            -0.0005724320653825998,
            -0.009837512858211994,
            0.01933419145643711,
            -0.022231968119740486,
            -0.010211432352662086,
            0.027594856917858124,
            0.02850843220949173,
            -0.025576714426279068,
            0.00832946877926588,
            0.00932721234858036,
            0.018955552950501442,
            -0.005164221860468388,
            0.03369411453604698,
            -0.01953905262053013,
            0.020764537155628204,
            -3.177256076014601e-05,
            -0.011965888552367687,
            -0.01409686729311943,
            -0.02520490624010563,
            0.012455363757908344,
            0.02562510035932064,
            0.024520032107830048,
            -0.017349036410450935,
            0.03357749059796333,
            -0.0028597749769687653,
            0.010026679374277592,
            -0.01885330304503441,
            -0.01603294536471367,
            0.0013741551665589213,
            0.027411403134465218,
            0.0057403272949159145,
            -0.010512635111808777,
            -0.03676493465900421,
            -0.020304912701249123,
            -0.007993485778570175,
            0.08377989381551743,
            -0.0003846553445328027,
            -0.022943565621972084,
            -0.018402209505438805,
            -0.005912130698561668,
            0.033164434134960175,
            0.01812431588768959,
            -0.018916599452495575,
            0.018569502979516983,
            -0.005988600663840771,
            0.002064197789877653,
            -0.006567313335835934,
            0.024020062759518623,
            -0.0069432444870471954,
            -0.0068494523875415325,
            -0.024492790922522545,
            0.022546375170350075,
            -0.0244001392275095,
            -0.032210275530815125,
            -0.016934648156166077,
            0.04364693537354469,
            0.030695540830492973,
            0.018946874886751175,
            -0.018667399883270264,
            0.008031711913645267,
            -0.009609334170818329,
            0.003752532647922635,
            0.006504108663648367,
            -0.01931370608508587,
            -0.010057968087494373,
            -0.0065985568799078465,
            0.014146637171506882,
            -0.03125676140189171,
            0.03058711066842079,
            0.021981114521622658,
            -0.00041391566628590226,
            0.005396073684096336,
            0.01751268468797207,
            0.02769138664007187,
            -0.01201290637254715,
            -0.02392599731683731,
            -0.03501221910119057,
            0.031809523701667786,
            0.0281201284378767,
            -0.030877618119120598,
            -0.035095904022455215,
            -0.012178285978734493,
            0.00873622577637434,
            0.04152053967118263,
            -0.024597667157649994,
            0.014827759005129337,
            0.012905114330351353,
            -0.01755816675722599,
            -0.04280070587992668,
            0.006640076171606779,
            -0.016232898458838463,
            -0.021965790539979935,
            -0.003749330062419176,
            0.00383503595367074,
            0.007775028236210346,
            -0.0033575950656086206,
            0.0013650492765009403,
            0.02029971219599247,
            0.023701252415776253,
            -0.0251761544495821,
            -0.01014768611639738,
            0.006044880952686071,
            0.003519365331158042,
            -0.010471180081367493,
            -0.0052069551311433315,
            0.009419440291821957,
            0.02475235052406788,
            0.01902855932712555,
            -0.01614607311785221,
            -0.006620112806558609,
            0.019316092133522034,
            0.011813185177743435,
            -0.015743844211101532,
            0.0063921380788087845,
            0.012249072082340717,
            -0.011858591809868813,
            -0.018393732607364655,
            0.010760248638689518,
            -0.00497328769415617,
            -0.0008354489109478891,
            -0.022418173030018806,
            -0.007346578408032656,
            0.00875357910990715,
            0.006648166570812464,
            0.02165788970887661,
            0.007926324382424355,
            -0.0049283793196082115,
            0.01180387195199728,
            -0.024887999519705772,
            -0.042305171489715576,
            -0.002630589995533228,
            0.033182259649038315,
            0.0022557415068149567,
            0.018826179206371307,
            0.0047683920711278915,
            -0.04006355255842209,
            -0.018190322443842888,
            0.007653452455997467,
            0.006933199241757393,
            0.038632288575172424,
            -0.015536214224994183,
            -0.02290591225028038,
            -0.04749881476163864,
            -0.023185018450021744,
            0.02490103244781494,
            0.017599623650312424,
            0.003939725458621979,
            -0.014477436430752277,
            0.01983613520860672,
            0.02469865418970585,
            -0.004987269639968872,
            -0.0027577225118875504,
            0.050160109996795654,
            0.019413139671087265,
            -0.028483232483267784,
            -0.023155756294727325,
            -0.0038833601865917444,
            0.020123371854424477,
            -0.027825238183140755,
            0.0037763952277600765,
            -0.006623418536037207,
            -0.021080918610095978,
            -0.0216206107288599,
            -0.024210844188928604,
            -0.022109294310212135,
            -0.013733360916376114,
            0.003606457496061921,
            -0.004322810098528862,
            0.03194927051663399,
            0.003967327065765858,
            0.027265198528766632,
            -0.018254300579428673,
            -0.00624510645866394,
            -0.007886101491749287,
            -0.012613235041499138,
            0.007940324954688549,
            -0.030420469120144844,
            -0.0207099299877882,
            0.021076958626508713,
            -0.020297326147556305,
            0.010484857484698296,
            -0.006471061147749424,
            -0.020375831052660942,
            0.019635984674096107,
            0.00042979131103493273,
            0.028106065467000008,
            0.008599227294325829,
            -0.02568822354078293,
            0.0026874348986893892,
            0.0316484160721302,
            0.0132299130782485,
            0.02594316191971302,
            0.0035494444891810417,
            0.015595211647450924,
            -0.0016788571374490857,
            0.023392274975776672,
            0.029557252302765846,
            0.0001110690864152275,
            0.031351134181022644,
            -0.026829175651073456,
            0.008715936914086342,
            -0.032191596925258636,
            0.018290724605321884,
            -0.005951135419309139,
            -0.052149128168821335,
            0.004418632481247187,
            0.018839586526155472,
            -0.029187984764575958,
            0.03287311643362045,
            -0.02327284775674343,
            0.04102659225463867,
            0.02363607846200466,
            -0.02321575954556465,
            0.03126811608672142,
            -0.009559684433043003,
            0.0032749702222645283,
            -0.03341608867049217,
            0.01633874885737896,
            -0.006835709325969219,
            0.013804877176880836,
            0.0009490516385994852,
            -0.0215317215770483,
            -0.015332072041928768,
            -0.0347834937274456,
            -0.0014826931292191148,
            -0.01775258220732212,
            0.01191946491599083,
            -0.0002688380191102624,
            0.03307498246431351,
            0.02212178148329258,
            -0.005256832577288151,
            0.019055519253015518,
            -0.016276570037007332,
            -0.009374057874083519,
            -0.0009167008101940155,
            0.006085491273552179,
            -0.01927981711924076,
            0.008177719078958035,
            -0.0007839283207431436,
            -0.018093110993504524,
            -0.016678787767887115,
            0.020055964589118958,
            -0.040495313704013824,
            -0.004780524875968695,
            -0.0012825687881559134,
            0.004539137706160545,
            -0.00198237388394773,
            0.029552774503827095,
            0.029006246477365494,
            0.041417501866817474,
            -0.05374835804104805,
            0.013966104947030544,
            0.03601090982556343,
            0.028007419779896736,
            0.024724461138248444,
            -0.030427221208810806,
            0.02697022818028927,
            0.016583692282438278,
            0.007349137682467699,
            0.014301208779215813,
            0.016994286328554153,
            -0.03506338596343994,
            0.024920212104916573,
            -0.008042216300964355,
            0.0289869736880064,
            -0.005113233812153339,
            0.008580338209867477,
            0.024454327300190926,
            0.030930601060390472,
            0.01214422658085823,
            0.005194459576159716,
            0.012021428905427456,
            -0.014486049301922321,
            0.01486397534608841,
            -0.013381739147007465,
            0.006193968001753092,
            0.00871916301548481,
            -0.03042135201394558,
            0.007102938834577799,
            0.015134247951209545,
            -0.004115632269531488,
            -0.0010069063864648342,
            -0.01815919205546379,
            -0.012786978855729103,
            -0.014365126378834248,
            0.011230767704546452,
            -0.01817561313509941,
            -0.03161383047699928,
            0.027059761807322502,
            0.010713865980505943,
            -0.003434163751080632,
            -0.0019936056341975927,
            0.02723042108118534,
            -0.026556704193353653,
            0.027572376653552055,
            -0.013042869977653027,
            -0.003658310743048787,
            0.03804456442594528,
            0.03842911496758461,
            0.03631770983338356,
            -0.0161841232329607,
            -0.0031300559639930725,
            -0.030932551249861717,
            0.006863909307867289,
            0.0018583060009405017,
            0.02349283918738365,
            0.006040765903890133,
            0.024554915726184845,
            0.03413945809006691,
            -0.01589873433113098,
            -0.022069532424211502,
            -0.02456929348409176,
            0.020379209890961647,
            -0.01433319877833128,
            -0.027472399175167084,
            -0.017483187839388847,
            -0.023810960352420807,
            -0.03144127130508423,
            -0.010503138415515423,
            -0.0016170172020792961,
            -0.009558211080729961,
            -0.025701280683279037,
            -0.010429279878735542,
            -0.028763169422745705,
            -0.019848495721817017,
            -0.00020948481687810272,
            0.027699727565050125,
            -0.013910415582358837,
            0.03667263314127922,
            0.012546048499643803,
            -0.02839505486190319,
            -0.004858372267335653,
            -0.014778154902160168,
            -0.02283974178135395,
            0.03524002805352211,
            -0.00039596346323378384,
            0.017624322324991226,
            0.007314765825867653,
            -0.03350403159856796,
            0.007528773974627256,
            -0.0015072490787133574,
            -0.021361175924539566,
            0.0034748201724141836,
            -0.027284784242510796,
            -0.017596622928977013,
            -0.0341581329703331,
            -0.03229064121842384,
            -0.009451729245483875,
            0.01755787804722786,
            0.0267933402210474,
            0.05190948769450188,
            -0.01287145633250475,
            0.0036904728040099144,
            0.013565832749009132,
            -0.02267695963382721,
            -0.017461538314819336,
            -0.027392148971557617,
            -0.046369053423404694,
            0.007302314043045044,
            -0.02168041467666626,
            0.02947954460978508,
            -0.030920613557100296,
            0.015056445263326168,
            0.015732688829302788,
            0.02338932640850544,
            -0.016262704506516457,
            -0.023252367973327637,
            0.03248026221990585,
            -0.020947715267539024,
            0.01962728053331375,
            0.027342481538653374,
            0.0026425959076732397,
            0.040301550179719925,
            -0.011698855087161064,
            0.016441253945231438,
            -0.009258895181119442,
            -0.00827589351683855,
            0.005597266834229231,
            0.014031938277184963,
            -0.0170347411185503,
            -0.014718384481966496,
            0.024366596713662148,
            0.025618603453040123,
            0.008713223040103912,
            -0.016149990260601044,
            0.03194913640618324,
            -0.021615654230117798,
            0.046822987496852875,
            0.010945084504783154,
            0.035139575600624084,
            -0.008958074264228344,
            0.017832083627581596,
            -0.016040151938796043,
            -0.007042274810373783,
            0.0017058317316696048,
            0.020924607291817665,
            0.005553515162318945,
            -0.025976471602916718,
            0.03345230966806412,
            -0.015610666945576668,
            0.0047600362449884415,
            0.01813710853457451,
            0.022947557270526886,
            0.004114958457648754,
            -0.037801649421453476,
            -0.032526955008506775,
            -0.011320683173835278,
            -0.020854640752077103,
            -0.006098839454352856,
            -0.004071218427270651,
            0.04658326134085655,
            0.01547539234161377,
            0.030417239293456078,
            0.013215814717113972,
            -0.024205246940255165,
            0.006881270557641983,
            0.031057529151439667,
            0.002302526729181409,
            0.027901990339159966,
            0.010481898672878742,
            0.03174600377678871,
            -0.014539513736963272,
            -0.004651987459510565,
            -0.011932316236197948,
            -0.029949329793453217,
            -0.012860939837992191,
            0.01616833172738552,
            -0.0015749629819765687,
            0.010716021992266178,
            -0.007038251496851444,
            0.046142615377902985,
            -0.015483261086046696,
            -0.007741211447864771,
            -0.012574185617268085,
            -0.01667233370244503,
            -0.01573967933654785,
            -0.017434969544410706,
            0.019299257546663284,
            0.004896571394056082,
            -0.007843435741961002,
            0.01621934026479721,
            -0.0016924419905990362,
            0.019977174699306488,
            0.023674868047237396,
            -0.013950983993709087,
            0.012488946318626404,
            -0.029955988749861717,
            0.0315982960164547,
            0.015489591285586357,
            -0.01623777113854885,
            -0.003418253967538476,
            -0.029584890231490135,
            -0.029319588094949722,
            -0.0269022174179554,
            0.017659073695540428,
            -0.03562533110380173,
            0.003729442600160837,
            0.017914246767759323,
            0.005678527057170868,
            -0.02712024748325348,
            -0.024830570444464684,
            -0.012174984440207481,
            0.04586002230644226,
            0.028556667268276215,
            -0.030785521492362022,
            -0.002873348305001855,
            -0.01182562205940485,
            0.01624983921647072,
            -0.00016708151088096201,
            -0.014174829237163067,
            0.008050283417105675,
            -0.013480735942721367,
            0.01108535286039114,
            0.020563792437314987,
            0.028944754973053932,
            0.009342657402157784,
            -0.0055002933368086815,
            0.040248993784189224,
            -0.020963864400982857,
            0.005075689870864153,
            -0.0283590666949749,
            0.01386560034006834,
            0.003516743890941143,
            -0.0029315361753106117,
            -0.007941676303744316,
            0.032436080276966095,
            0.007896573282778263,
            0.0018877958646044135,
            0.03533728048205376,
            -0.0036515265237540007,
            0.00285685365088284,
            -0.006810016464442015,
            -0.016331590712070465,
            -0.034734416753053665,
            -0.007308063097298145,
            -0.024204319342970848,
            -0.027529152110219002,
            0.020229190587997437,
            -0.029504962265491486,
            -0.025276144966483116,
            0.012204291298985481,
            0.008478785865008831,
            -0.010828053578734398,
            -0.029047202318906784,
            -0.03513843193650246,
            0.04294518008828163,
            0.0005766935064457357,
            0.03169548511505127,
            -0.022727763280272484,
            0.010838300921022892,
            -0.03392820805311203,
            0.037934936583042145,
            -0.004361562430858612,
            0.013386249542236328,
            -0.004591960459947586,
            -0.00428643636405468,
            0.03856935352087021,
            -0.013809330761432648,
            0.03660120069980621,
            0.02810518443584442,
            0.030919473618268967,
            0.031305015087127686,
            0.0021468030754476786,
            -0.01362480316311121,
            0.029050681740045547,
            0.013548917137086391,
            0.002815620508044958,
            0.016555925831198692,
            -0.014397046528756618,
            0.004883466754108667,
            0.0031477974262088537,
            -0.031126108020544052,
            -0.023706680163741112,
            0.008978351950645447,
            0.028531190007925034,
            -0.06215742975473404,
            0.02747541479766369,
            0.013207623735070229,
            -0.005575438495725393,
            0.017382478341460228,
            -0.012419387698173523,
            -0.021562034264206886,
            0.0316137820482254,
            0.007991338148713112,
            -0.005732676014304161,
            -0.0034268975723534822,
            0.002708529820665717,
            0.01748093217611313,
            -0.019325070083141327,
            0.005993357393890619,
            -0.007914629764854908,
            -0.02258152700960636,
            0.026509039103984833,
            0.003959215246140957,
            0.05540614202618599,
            -0.035896025598049164,
            0.01949072815477848,
            0.019314099103212357,
            0.02380470000207424,
            0.031273726373910904,
            -0.01675344444811344,
            0.007682862691581249,
            0.007889567874372005,
            -0.007024453952908516,
            0.012001297436654568,
            -0.01954464055597782,
            0.015027334913611412,
            0.0002389080182183534,
            -0.029182562604546547,
            0.033619727939367294,
            -0.024308975785970688,
            0.0304739847779274,
            0.008353980258107185,
            -0.00016798147407826036,
            -0.03143443912267685,
            0.008403420448303223,
            -0.010016217827796936,
            0.025562375783920288,
            0.015931181609630585,
            -0.01135372556746006,
            -0.017033923417329788,
            -0.02021271549165249,
            -0.014546404592692852,
            0.0020135955419391394,
            0.015943391248583794,
            -0.020523540675640106,
            -0.011748197488486767,
            -0.012232102453708649,
            -0.011993758380413055,
            0.0013395948335528374,
            -0.02339371107518673,
            0.040636684745550156,
            0.009890778921544552,
            -0.021782338619232178,
            -0.02371891215443611,
            0.02328995242714882,
            -0.04002895578742027,
            -0.0033367974683642387,
            -0.027548927813768387,
            -0.01099418755620718,
            -0.005629002116620541,
            -0.006706831511110067,
            0.004633093252778053,
            -0.008109867572784424,
            -0.04689401015639305,
            0.017515959218144417,
            -0.028724702075123787,
            0.0460515059530735,
            0.03458339720964432,
            -0.0011632085079327226,
            0.010174380615353584,
            -0.007074475754052401,
            -0.02018466405570507,
            -0.0016339755384251475,
            0.0012880617287009954,
            -0.0323110893368721,
            -0.02479962632060051,
            0.02467922680079937,
            -0.022120386362075806,
            -0.01028043869882822,
            -0.006787198130041361,
            0.01713271625339985,
            -0.013671395368874073,
            0.005011115223169327,
            -0.022051502019166946,
            0.00997105147689581,
            -0.003778827143833041,
            -0.0029107851441949606,
            -0.023946531116962433,
            -0.017860688269138336,
            -0.02775871939957142,
            0.005493342876434326,
            -0.02505839429795742,
            0.029430238530039787,
            0.02087128534913063,
            0.020087162032723427,
            -0.04749874398112297,
            0.04607589542865753,
            0.0037769905757158995,
            0.011385724879801273,
            0.02099524438381195,
            -0.032258279621601105,
            -0.004132268950343132,
            0.0023269960656762123,
            0.013847083784639835,
            -0.010204169899225235,
            -0.008469977416098118,
            0.004101841244846582,
            0.014336563646793365,
            -0.02198091521859169,
            0.0003890340740326792,
            -0.025670893490314484,
            -0.00899689830839634,
            0.006873100530356169,
            -0.022308794781565666,
            -0.026582209393382072,
            -0.039567165076732635,
            0.03830084949731827,
            -0.024078141897916794,
            0.0072540477849543095,
            0.001491338131017983,
            0.017327697947621346,
            -0.02928970567882061,
            -0.007523061241954565,
            -0.015647586435079575
        ],
        [
            -0.02949182130396366,
            -0.012271232903003693,
            0.01695624180138111,
            0.01209395844489336,
            0.0033375199418514967,
            0.023421917110681534,
            0.022759461775422096,
            -0.0024399845860898495,
            0.036403339356184006,
            0.028773875907063484,
            0.005323194898664951,
            -0.0014438501093536615,
            0.023526707664132118,
            0.0333535335958004,
            0.006964758038520813,
            0.016271932050585747,
            -0.0033478604163974524,
            0.00107585487421602,
            -0.0020319202449172735,
            0.002614754717797041,
            -0.05138309672474861,
            -0.015716584399342537,
            -0.02175680547952652,
            -0.008052250370383263,
            -0.02944243513047695,
            -0.0042137159034609795,
            -0.0526774637401104,
            0.021512994542717934,
            0.010612476617097855,
            -0.03357235714793205,
            0.02170437015593052,
            -0.03856118768453598,
            0.03735915198922157,
            0.01736505888402462,
            -0.003824231680482626,
            -0.029090236872434616,
            0.029574891552329063,
            -0.03382819518446922,
            -0.01813807338476181,
            0.024357454851269722,
            0.020176365971565247,
            -0.021589413285255432,
            0.01981629990041256,
            0.04333283752202988,
            0.021938282996416092,
            -0.000186733333976008,
            0.0288800410926342,
            0.02101507969200611,
            -0.02957591414451599,
            -0.07714147120714188,
            -0.02013290487229824,
            -0.013694503344595432,
            -0.04889121651649475,
            0.010121646337211132,
            -0.02224176935851574,
            -0.008570107631385326,
            0.019392337650060654,
            -0.034289851784706116,
            -0.01688077300786972,
            0.0167139433324337,
            -0.009615789167582989,
            0.011218080297112465,
            -0.007342944853007793,
            -0.0008812198066152632,
            -0.02349722385406494,
            0.021187597885727882,
            -0.0037574335001409054,
            0.017974598333239555,
            0.002819135319441557,
            -0.031910765916109085,
            0.024610936641693115,
            -0.007187169045209885,
            0.03394244238734245,
            -0.0007342635071836412,
            0.021991297602653503,
            -0.05132381618022919,
            0.015111935324966908,
            -0.0230732262134552,
            0.02001931704580784,
            -0.04438020661473274,
            0.0033163921907544136,
            -0.030166389420628548,
            -0.007720747496932745,
            0.0061120702885091305,
            0.008318637497723103,
            0.03203568607568741,
            -0.01538430992513895,
            -0.03577215597033501,
            -0.017038516700267792,
            -0.016072265803813934,
            0.0019185298588126898,
            0.008257470093667507,
            -0.025772668421268463,
            0.022548913955688477,
            0.0026397595647722483,
            -0.021759020164608955,
            -0.005548085086047649,
            -0.030725494027137756,
            -0.044449593871831894,
            -0.03225710988044739,
            -0.006234912201762199,
            -0.013189518824219704,
            -0.04000354930758476,
            -0.00919465534389019,
            0.007546504493802786,
            0.0016845196951180696,
            0.03671247139573097,
            0.026747794821858406,
            0.011743483133614063,
            -0.014245428144931793,
            -0.03555277734994888,
            -0.013936333358287811,
            -0.025724148377776146,
            0.029397716745734215,
            0.03895578905940056,
            0.027684364467859268,
            0.02312002144753933,
            0.031163662672042847,
            -0.02740955725312233,
            0.028030985966324806,
            -0.026107143610715866,
            0.004941218998283148,
            0.01316202711313963,
            0.01871911808848381,
            -0.021534830331802368,
            -0.004483683966100216,
            0.03990207985043526,
            -0.028063809499144554,
            -0.022960970178246498,
            -0.0011626281775534153,
            0.033914003521203995,
            0.0398763045668602,
            -0.015302568674087524,
            -0.04106491431593895,
            -0.015738587826490402,
            0.02461276762187481,
            0.0037731153424829245,
            -0.036620378494262695,
            -0.003377447836101055,
            0.03192056342959404,
            0.027811560779809952,
            0.020257333293557167,
            0.001777511090040207,
            0.03232438862323761,
            0.02069239877164364,
            -0.0068881516344845295,
            0.013552356511354446,
            0.011720448732376099,
            -0.013187937438488007,
            -0.01563771814107895,
            0.036916449666023254,
            0.04779984429478645,
            0.009053201414644718,
            0.03126011788845062,
            0.00968934502452612,
            0.00244188797660172,
            -0.025798499584197998,
            0.00025433581322431564,
            -0.010253348387777805,
            -0.009191309101879597,
            0.03327825292944908,
            -0.022361528128385544,
            0.0033892635256052017,
            -0.02038310095667839,
            0.017377987504005432,
            -0.0019833934493362904,
            -0.053194187581539154,
            0.01347129512578249,
            0.01805606484413147,
            -0.02410193905234337,
            0.03887781500816345,
            -0.00043951955740340054,
            -0.03993367403745651,
            0.010629376396536827,
            -0.02629968151450157,
            0.01903623901307583,
            0.028266791254281998,
            0.004295296035706997,
            0.01653723232448101,
            -0.013256370089948177,
            0.038990478962659836,
            -0.00022908402024768293,
            -0.05001123994588852,
            -0.03140198439359665,
            -0.007352777756750584,
            -0.025508692488074303,
            -0.01985923945903778,
            -0.004613775759935379,
            0.00616817781701684,
            0.02825000323355198,
            0.014246645383536816,
            0.02564757503569126,
            -0.017751598730683327,
            -6.052762546460144e-05,
            -0.019486354663968086,
            0.03728841617703438,
            0.028607772663235664,
            -0.014396165497601032,
            0.029697608202695847,
            0.0008957051322795451,
            -0.02149978280067444,
            0.027206216007471085,
            0.04072265326976776,
            0.010223028250038624,
            -0.06732265651226044,
            -0.01920275017619133,
            -0.05240261182188988,
            0.02626299113035202,
            -0.027718517929315567,
            -0.0014199528377503157,
            -0.03850696608424187,
            -0.0043816897086799145,
            -0.011303799226880074,
            -0.0029398654587566853,
            0.027602069079875946,
            -0.0038744863122701645,
            -0.014455582946538925,
            -0.00633010920137167,
            -0.006325093563646078,
            0.023523898795247078,
            0.02951502427458763,
            -0.0074401600286364555,
            -0.032874949276447296,
            -0.006555638276040554,
            -0.024806655943393707,
            0.0017665647901594639,
            0.05205172300338745,
            -0.022492075338959694,
            -0.008773265406489372,
            -0.015600286424160004,
            0.026602335274219513,
            0.0357670821249485,
            0.010427200235426426,
            -0.012411924079060555,
            0.014273871667683125,
            -0.005660160444676876,
            -0.040093276649713516,
            -0.01679619587957859,
            -0.03755730763077736,
            -0.03056715428829193,
            0.031149933114647865,
            0.030173731967806816,
            -0.005799235310405493,
            -0.008966317400336266,
            0.03375804051756859,
            -0.017188185825943947,
            -0.025342583656311035,
            0.02144835703074932,
            -0.004405373241752386,
            0.03342762961983681,
            0.033150408416986465,
            -0.0032260394655168056,
            -0.03286333382129669,
            0.010771808214485645,
            0.005084506701678038,
            -0.02009289525449276,
            -0.0190292876213789,
            -0.005781689193099737,
            -0.01136454101651907,
            0.03420562669634819,
            -0.0603598952293396,
            0.036106403917074203,
            -0.035100892186164856,
            0.023476824164390564,
            0.015054908581078053,
            -0.060615912079811096,
            -0.003705669194459915,
            -0.017039600759744644,
            -0.004066357854753733,
            0.013583412393927574,
            -0.005139714572578669,
            -0.020447731018066406,
            -0.0041347467340528965,
            -0.01910027302801609,
            0.020637046545743942,
            0.022619467228651047,
            0.005379272624850273,
            -0.025614885613322258,
            -0.02316613495349884,
            -0.06242607533931732,
            -0.03858814015984535,
            0.012319988571107388,
            0.02865106426179409,
            0.037757325917482376,
            0.018368365243077278,
            -0.017787639051675797,
            -0.023638609796762466,
            -0.030101502314209938,
            -0.010580991394817829,
            -0.032172154635190964,
            -0.029245546087622643,
            0.0006470239604823291,
            -0.02504427544772625,
            0.04262334480881691,
            0.039611876010894775,
            0.010626955889165401,
            0.015061676502227783,
            -0.024850374087691307,
            0.00552402576431632,
            -0.011162973940372467,
            -0.040986787527799606,
            0.0007471089484170079,
            -0.03994082286953926,
            0.029200701043009758,
            0.006879821885377169,
            0.04716375842690468,
            0.03188307583332062,
            0.041727736592292786,
            0.0257679782807827,
            0.021436773240566254,
            -0.021785857155919075,
            -0.008322680369019508,
            -0.02767505869269371,
            0.0022825300693511963,
            0.011936215683817863,
            -0.03963199630379677,
            -0.010315950028598309,
            0.028240488842129707,
            -0.012184197083115578,
            -0.02107320912182331,
            -0.01691947504878044,
            -0.04274462163448334,
            0.008322184905409813,
            0.02315770648419857,
            0.0015659468481317163,
            -0.012058133259415627,
            -0.021157339215278625,
            -0.012075348757207394,
            0.023723499849438667,
            -0.009175236336886883,
            -0.007733335718512535,
            -0.014314847066998482,
            -0.0006696754135191441,
            0.015727972611784935,
            0.02831904962658882,
            0.014840311370790005,
            0.012136553414165974,
            -0.00028100033523514867,
            -0.005733898375183344,
            -0.009080619551241398,
            0.0024715035688132048,
            0.03131232410669327,
            0.022447435185313225,
            -0.01083332858979702,
            0.011612222529947758,
            0.01504607405513525,
            -0.0077396295964717865,
            -0.031052475795149803,
            0.007125974632799625,
            0.045292288064956665,
            -0.04214085638523102,
            -0.021136291325092316,
            -0.021205641329288483,
            -0.0038249988574534655,
            -0.028994912281632423,
            -0.01378624327480793,
            -0.011264029890298843,
            0.01079825684428215,
            0.007387660909444094,
            -0.015970680862665176,
            0.025320902466773987,
            -0.02147926576435566,
            -0.0330209918320179,
            0.007667951751500368,
            -0.009529665112495422,
            -0.0017316269222646952,
            -0.052550043910741806,
            -0.06768576055765152,
            -0.012211847119033337,
            -0.01035265251994133,
            0.018874241039156914,
            0.00910025741904974,
            -0.007962070405483246,
            0.017231248319149017,
            -0.022672217339277267,
            0.02337515726685524,
            0.025575755164027214,
            0.034845609217882156,
            0.029984990134835243,
            -0.014435499906539917,
            -0.01804245077073574,
            -0.004384367261081934,
            -0.002185784513130784,
            0.008318311534821987,
            -0.007935142144560814,
            -0.03369629755616188,
            0.013230438344180584,
            0.011070089414715767,
            -0.007994493469595909,
            0.016917727887630463,
            -0.0190926231443882,
            -0.01731128618121147,
            0.018940504640340805,
            0.00034640994272194803,
            0.033936046063899994,
            0.003328481689095497,
            -0.004748377948999405,
            0.04215041920542717,
            -0.0001595860521774739,
            -0.041687749326229095,
            -0.0705324336886406,
            -0.0028909703250974417,
            0.02613651566207409,
            0.02538364753127098,
            0.037218548357486725,
            -0.016258182004094124,
            0.0077019669115543365,
            0.019692758098244667,
            -0.0019047623500227928,
            0.019787244498729706,
            0.017099695280194283,
            -0.028763841837644577,
            -0.008379469625651836,
            0.017309950664639473,
            0.008931230753660202,
            -0.03302140161395073,
            0.000975287111941725,
            0.022723756730556488,
            -0.003974775783717632,
            -0.019632132723927498,
            0.0379958339035511,
            -0.013693217188119888,
            -0.023159664124250412,
            -0.00966044794768095,
            0.010462308302521706,
            0.009872570633888245,
            0.041008807718753815,
            0.014841519296169281,
            0.008819841779768467,
            0.053009066730737686,
            -0.003574004862457514,
            -0.024236900731921196,
            -0.0070694442838430405,
            -0.04769797995686531,
            0.03918859362602234,
            0.02484007179737091,
            -0.00351279741153121,
            0.01054802443832159,
            -0.0009481027373112738,
            0.037370048463344574,
            -0.04891776293516159,
            0.031756363809108734,
            0.03478953242301941,
            0.016945382580161095,
            0.037576328963041306,
            -0.002165000420063734,
            0.02279592864215374,
            -0.06420764327049255,
            0.01679234765470028,
            -0.00754545396193862,
            -0.019010065123438835,
            -0.02730625867843628,
            0.05148416385054588,
            -0.02228226140141487,
            -0.02613488957285881,
            0.0006684144027531147,
            -0.004611711949110031,
            -0.03430945426225662,
            -0.038186829537153244,
            -0.011907263658940792,
            0.025380471721291542,
            0.010085311718285084,
            -0.03136198967695236,
            0.015725526958703995,
            -0.04334825277328491,
            -0.013681511394679546,
            0.022474713623523712,
            0.02838301844894886,
            0.015064184553921223,
            0.018109148368239403,
            -0.01648726500570774,
            -0.0030306049156934023,
            0.0033995951525866985,
            -0.0023407761473208666,
            -0.01090316940099001,
            0.02901012822985649,
            0.006162818055599928,
            0.03254614770412445,
            -0.01576041616499424,
            -0.0037630379665642977,
            -0.06382708251476288,
            0.027270959690213203,
            0.0069501688703894615,
            -0.019841166213154793,
            0.04385250434279442,
            -0.011511770077049732,
            0.00500026810914278,
            -0.024678567424416542,
            0.0027337416540831327,
            -0.07218004763126373,
            -0.006255356594920158,
            -0.021826492622494698,
            -0.02327910251915455,
            -0.06205753982067108,
            0.003119722008705139,
            0.0034455503337085247,
            -0.006231651175767183,
            -0.036304961889982224,
            0.008566051721572876,
            -0.02173033356666565,
            -0.013939809054136276,
            -0.014253797940909863,
            -0.015975400805473328,
            -0.016183942556381226,
            -0.007111100479960442,
            0.019423065707087517,
            0.020763954147696495,
            0.0117409098893404,
            -0.014733873307704926,
            -0.02945159375667572,
            -0.014155121520161629,
            0.02654886804521084,
            0.017550647258758545,
            -0.003911525011062622,
            0.00717094587162137,
            -0.02015131711959839,
            -0.02302221953868866,
            0.004339129664003849,
            -0.015118740499019623,
            -0.010123703628778458,
            -0.0026683781761676073,
            -0.02180613949894905,
            0.03244549036026001,
            -0.003070524428039789,
            0.00606406107544899,
            -0.01733473502099514,
            -0.010526265017688274,
            0.01893288642168045,
            -0.008954571560025215,
            -0.023459166288375854,
            -0.020093103870749474,
            0.038909994065761566,
            -0.013313420116901398,
            -0.0199492946267128,
            -0.0043971845880150795,
            0.017803683876991272,
            -0.011239469982683659,
            -0.023508602753281593,
            -0.034789979457855225,
            -0.011038435623049736,
            0.0017585211899131536,
            0.004951491020619869,
            -0.030113596469163895,
            0.019980905577540398,
            0.030809734016656876,
            0.026565738022327423,
            -0.00020867145212832838,
            -0.0021804054267704487,
            0.0026272654067724943,
            0.0033869438339024782,
            0.009724242612719536,
            -0.016402211040258408,
            0.01938238926231861,
            0.030620936304330826,
            0.011743254028260708,
            -0.0044538225047290325,
            -0.015841476619243622,
            -0.00288093788549304,
            0.03580755740404129,
            -0.015028122812509537,
            0.006730683147907257,
            0.023250309750437737,
            -0.0032034909818321466,
            -0.00526450015604496,
            0.005654030945152044,
            -0.041667476296424866,
            0.029084185138344765,
            -0.005234245210886002,
            -0.0031100118067115545,
            0.00817896332591772,
            -0.005110904108732939,
            -0.03609413653612137,
            0.02064170502126217,
            0.0040296087972819805,
            -0.0011001041857525706,
            0.010554441250860691,
            -0.005224657244980335,
            0.0300410445779562,
            -0.002511662198230624,
            0.008361924439668655,
            0.00013292295625433326,
            -0.020976565778255463,
            0.02406226471066475,
            0.007597037591040134,
            0.030356671661138535,
            -0.0063710324466228485,
            0.019557280465960503,
            0.030387455597519875,
            0.0011492985067889094,
            0.013276711106300354,
            0.02093278057873249,
            0.015476386994123459,
            -0.008507028222084045,
            -0.018946660682559013,
            -0.0004892903962172568,
            -0.018171077594161034,
            -0.01756187155842781,
            -0.03473920002579689,
            0.0036501314025372267,
            -0.02340240776538849,
            0.006605240982025862,
            -0.042463839054107666,
            -6.202569784363732e-05,
            -0.01736319437623024,
            0.00735894963145256,
            -0.00057609734358266,
            0.0019639881793409586,
            0.005820303224027157,
            -0.005574281793087721,
            -0.02841360494494438,
            0.01683082990348339,
            -0.001533661619760096,
            -0.02808852307498455,
            -0.003494709962978959,
            0.018830612301826477,
            -0.01746310479938984,
            -0.01187874749302864,
            0.001576141221448779,
            0.0071716830134391785,
            -0.012779195792973042,
            -0.0009904211619868875,
            -0.028474830090999603,
            0.023082558065652847,
            0.002223141025751829,
            -0.020437659695744514,
            -0.02598937228322029,
            0.01527451816946268,
            0.005973179358989,
            -0.03580981120467186,
            -0.01808266155421734,
            -0.025103935971856117,
            -0.017162898555397987,
            0.010261277668178082,
            -0.003148665651679039,
            -0.023282453417778015,
            0.017788996919989586,
            0.028459101915359497,
            -0.0476793497800827,
            0.0047681392170488834,
            0.004201526753604412,
            0.023436378687620163,
            0.03280293568968773,
            0.006554430816322565,
            -0.020421460270881653,
            0.00637354701757431,
            -0.021322108805179596,
            -0.061330877244472504,
            0.031851548701524734,
            0.032039593905210495,
            0.026876045390963554,
            0.022517267614603043,
            0.03387538716197014,
            -0.022796062752604485,
            0.003078179433941841,
            -0.055314093828201294,
            -0.014489318244159222,
            -0.006698172073811293,
            -0.0015415115049108863,
            0.025313161313533783,
            0.03644949942827225,
            -0.04198643937706947,
            0.014493685215711594,
            0.00010882261994993314,
            0.03432951495051384,
            -0.005872933194041252,
            -0.027441494166851044,
            -0.03760158270597458,
            -0.00791485607624054,
            0.022809507325291634,
            -0.016683733090758324,
            0.0007746899500489235,
            0.020866187289357185,
            -0.029828151687979698,
            -0.03854869306087494,
            0.02665603719651699,
            0.02531750686466694,
            -0.038827136158943176,
            -0.020705942064523697,
            0.001015460235066712,
            -0.017553066834807396,
            -0.014019285328686237,
            0.03314875811338425,
            -0.020293332636356354,
            -0.011101393960416317,
            -0.010084853507578373,
            -0.029551351442933083,
            -0.061731889843940735,
            0.001675986684858799,
            -0.00819122139364481,
            0.00938627403229475,
            0.011296832002699375,
            -0.012672829441726208,
            0.05819546431303024,
            0.017556993290781975,
            0.013409876264631748,
            -0.03480878844857216,
            -0.0478859469294548,
            -0.02649056911468506,
            -0.006574343424290419,
            -0.0059030260890722275,
            -0.017708322033286095,
            0.019513661041855812,
            0.04500877112150192,
            0.0033002938143908978,
            -0.014081877656280994,
            -0.02967601828277111,
            -0.006569782271981239,
            -0.0063275499269366264,
            -0.008697069250047207,
            -0.03891018405556679,
            0.005811511538922787,
            0.0212852843105793,
            0.005188106559216976,
            0.0022018069867044687,
            0.021147245541214943,
            -0.02874024771153927,
            -0.04738526791334152,
            0.027815308421850204,
            -0.024031780660152435,
            0.0020865651313215494,
            -0.030218182131648064,
            0.008084651082754135,
            0.028268903493881226,
            -0.018524175509810448,
            0.014524439349770546,
            -0.01013745553791523,
            0.028728198260068893,
            0.006792876403778791,
            -0.024197420105338097,
            -0.014820278622210026,
            0.007189844734966755,
            0.020830048248171806,
            0.02325906977057457,
            0.021281423047184944,
            0.005393567029386759,
            -0.01955178938806057,
            -0.02921847440302372,
            -0.025596899911761284,
            0.0010617540683597326,
            0.008875474333763123,
            0.0002072168717859313,
            -0.01140633411705494,
            0.009088012389838696,
            0.03646181523799896,
            0.03461170569062233,
            0.000318679929478094,
            -0.006217649672180414,
            0.024827349931001663,
            -0.01458764635026455,
            -0.02310163341462612,
            0.029932620003819466,
            -0.030024467036128044,
            0.014185995794832706,
            0.0054955980740487576,
            0.017120659351348877,
            -0.012227147817611694,
            0.005356244742870331,
            -0.00906984694302082,
            0.013469018042087555,
            -0.0026687507051974535,
            0.021003345027565956,
            -0.052272625267505646,
            0.012036439962685108,
            -0.004024829715490341,
            0.017136096954345703,
            0.0032454838510602713,
            -0.023753169924020767,
            0.004679318983107805,
            -0.019525112584233284,
            0.01415259949862957,
            0.023107005283236504,
            -0.01986934244632721,
            -0.06859610974788666,
            -0.06628916412591934,
            -0.012386457994580269,
            0.042793236672878265,
            -0.058736495673656464,
            -0.0052907029166817665,
            -0.009444592520594597,
            0.007334583438932896,
            -0.012508915737271309,
            -0.015651453286409378,
            0.036858122795820236,
            -0.014411681331694126,
            -0.01630237139761448,
            -0.013316014781594276,
            0.026947038248181343,
            0.04570288211107254,
            -0.02785426937043667,
            0.04094545915722847,
            -0.08694066852331161,
            -0.018757633864879608,
            0.01952287368476391,
            -0.009697076864540577,
            -0.0777617022395134,
            -0.018122978508472443,
            0.026905979961156845,
            0.005261473823338747,
            0.006808390840888023,
            -0.0012449994683265686,
            0.01875469833612442,
            -0.00813614297658205,
            -0.01553413923829794,
            0.013173897750675678,
            0.0515710823237896,
            -0.0367673896253109,
            0.013563570566475391,
            0.019390007480978966,
            -0.03173084184527397,
            -0.008466042578220367,
            0.033749356865882874,
            -0.05212390050292015,
            -0.007235773839056492,
            -0.028614776208996773,
            0.06122155115008354,
            0.016940433531999588,
            0.02967970073223114,
            -0.0202813558280468,
            0.028730811551213264,
            0.030929945409297943,
            -0.016403544694185257,
            -0.06490543484687805,
            -0.006232289131730795,
            0.0009431903599761426,
            -0.026277542114257812,
            0.0024041517172008753,
            -0.003020183416083455,
            0.018484532833099365,
            0.004872855264693499,
            -0.005220119375735521,
            -0.0047622909769415855,
            -0.014066027477383614,
            -0.005102341063320637,
            -0.05454180762171745,
            0.016814202070236206,
            -0.023250676691532135,
            -0.020670494064688683,
            -0.02898440882563591,
            -0.00859830155968666,
            0.024351101368665695,
            0.008459703996777534,
            0.031201772391796112,
            -0.027667783200740814,
            0.03584713861346245,
            -0.028346946462988853,
            0.002765428740531206,
            -0.039826177060604095,
            -0.002034446457400918,
            0.01952855847775936,
            0.007843919098377228,
            0.004285035189241171,
            0.03839492052793503,
            0.0034642480313777924,
            -0.019645405933260918,
            0.011191023513674736,
            0.0279777180403471,
            -0.027861161157488823,
            0.03715336322784424,
            0.019938502460718155,
            0.0415305532515049,
            0.01949431747198105,
            -0.03220228850841522,
            0.012031476944684982,
            0.03830123320221901,
            0.04413248971104622,
            0.01932402141392231,
            0.01057826541364193,
            -0.024899287149310112,
            0.026798788458108902,
            0.005534674972295761,
            0.05134177580475807,
            0.018660174682736397,
            -0.042099159210920334,
            -0.02949528396129608,
            0.003060944378376007,
            0.0010625417344272137,
            0.022831695154309273,
            -2.6321629320591455e-06,
            -0.02517114020884037,
            0.0048291380517184734,
            -0.012218523770570755,
            0.0018492521485313773,
            -0.06315570324659348,
            -0.015213548205792904,
            -0.0015956033021211624,
            -0.04044262319803238,
            0.029517915099859238,
            -0.01646794006228447,
            0.05451071634888649,
            -0.004719387739896774,
            0.019030233845114708,
            -0.0026964934077113867,
            0.0247479397803545,
            0.039645709097385406,
            -0.016185306012630463,
            -0.010476077906787395,
            -0.009098969399929047,
            0.005709735210984945,
            -0.00969090685248375,
            0.0300176702439785,
            0.018450703471899033,
            0.0010210073087364435,
            -0.023904521018266678,
            -0.0085291201248765,
            0.017855551093816757,
            0.01299541350454092,
            0.02606450580060482,
            0.02447935938835144,
            0.02044488489627838,
            -0.004686689004302025,
            -0.002446729689836502,
            0.011440439149737358,
            -0.01952422969043255,
            -0.032217931002378464,
            0.057654257863759995,
            0.012585379183292389,
            0.014084666036069393,
            -0.0062637003138661385,
            -0.018434656783938408,
            -0.014690959826111794,
            0.0029629990458488464,
            -0.034572310745716095,
            -0.01649751327931881,
            -0.03315689042210579,
            -0.02413330040872097,
            -0.018891267478466034,
            -0.004183143377304077,
            -0.000857102102600038,
            0.011743309907615185,
            0.04080834612250328,
            -0.0012120208702981472,
            0.00566600076854229,
            0.028759358450770378,
            -0.001984531059861183,
            -0.00744876591488719,
            0.021295487880706787,
            -0.02058352716267109,
            -0.010389881208539009,
            -0.0029864213429391384,
            0.00366535154171288,
            0.017663706094026566,
            -0.015895109623670578,
            0.029617493972182274,
            0.009986434131860733,
            0.05247729644179344,
            0.0019147131824865937,
            0.010406489484012127,
            -0.04342280700802803,
            0.013392279855906963,
            0.029830247163772583,
            -0.048057813197374344,
            -0.0007483988883905113,
            0.013495859690010548,
            -0.01520121656358242,
            -0.019178254529833794,
            0.017743540927767754,
            0.0015664366073906422,
            0.042639944702386856,
            -0.04078856483101845,
            0.025181477889418602,
            0.011595837771892548,
            -0.004610226955264807,
            -0.016659023240208626,
            -0.022975806146860123,
            0.0359765999019146,
            0.0017683213809505105,
            -0.006729083135724068,
            -0.00012929538206662983,
            -0.04023582115769386,
            0.015442807227373123,
            -0.01655496470630169,
            -0.01419250201433897,
            -0.03594039008021355,
            0.037055935710668564,
            -0.007329948712140322,
            -0.001635426771827042,
            -0.0965951606631279,
            0.019577549770474434,
            -0.02097424492239952,
            -0.023680556565523148,
            0.03588800132274628,
            0.017725510522723198,
            -0.013369830325245857,
            0.025068800896406174,
            -0.07153081893920898,
            0.025532251223921776,
            0.05743740499019623,
            0.0032897458877414465,
            0.01883653923869133,
            0.017012743279337883,
            -0.02568230777978897,
            0.017983831465244293,
            0.017726581543684006,
            0.03600837290287018,
            -0.007902699522674084,
            -0.020460095256567,
            -0.0226482842117548,
            -0.02076188288629055,
            0.029874345287680626,
            0.0008305338560603559,
            0.004533814731985331,
            0.012059323489665985,
            -0.0200730562210083,
            -0.01729876548051834,
            -0.018157972022891045,
            0.025473065674304962,
            -0.0061524310149252415,
            -0.030370332300662994,
            0.02553735300898552,
            0.006663241423666477,
            0.010901223868131638,
            0.02359529212117195,
            -0.030647387728095055,
            -0.006881769746541977,
            0.007092960178852081,
            -0.03517932444810867,
            -0.017342887818813324,
            0.0310360100120306,
            -0.003570850472897291,
            0.016063330695033073,
            -0.027119699865579605,
            -0.005713119171559811,
            0.04737132787704468,
            -0.0006742518744431436
        ],
        [
            -0.006797330919653177,
            0.015468515455722809,
            -0.03142077103257179,
            -0.01917768456041813,
            -0.017161915078759193,
            -0.0035990835167467594,
            -0.005022240336984396,
            0.02181667648255825,
            0.01788756065070629,
            0.008023037575185299,
            0.009097427129745483,
            -0.03680374100804329,
            -0.0062450929544866085,
            0.051269322633743286,
            0.08401835709810257,
            -0.017079558223485947,
            -0.0369919128715992,
            0.022187594324350357,
            -0.006605186499655247,
            -0.007413472514599562,
            -0.02150959149003029,
            0.011984070762991905,
            -0.023470517247915268,
            0.04746319726109505,
            0.017275752499699593,
            0.01471338327974081,
            0.040655914694070816,
            0.03436819091439247,
            0.05073488876223564,
            -0.01704547181725502,
            -0.017097879201173782,
            0.022582609206438065,
            -0.0441439114511013,
            0.017692292109131813,
            -0.008801874704658985,
            0.025511791929602623,
            -0.00949609000235796,
            -0.004344450309872627,
            0.0016004344215616584,
            0.015905605629086494,
            -0.00429943623021245,
            -0.0017277287552133203,
            -0.028886966407299042,
            -0.02340037189424038,
            0.03538065403699875,
            0.014357293024659157,
            -0.0058050332590937614,
            0.03989125415682793,
            -0.008307394571602345,
            0.023373162373900414,
            -0.035222332924604416,
            0.012167315930128098,
            -0.015080704353749752,
            0.025454413145780563,
            -0.01891281083226204,
            0.016781777143478394,
            0.03819616138935089,
            -0.019853917881846428,
            -0.00799698755145073,
            0.024714820086956024,
            -0.03878144919872284,
            0.015717683359980583,
            0.023557031527161598,
            -0.0026296365540474653,
            -0.02304699458181858,
            0.03143986687064171,
            -0.04557161405682564,
            0.00685813557356596,
            -0.030370820313692093,
            0.022403238341212273,
            -0.038179803639650345,
            0.020761577412486076,
            0.02616364136338234,
            -0.010987712070345879,
            0.00782065186649561,
            -0.03464522957801819,
            -0.01082589104771614,
            0.03484764322638512,
            0.0064052920788526535,
            0.014488985762000084,
            0.031163984909653664,
            0.027912521734833717,
            0.011314524337649345,
            -0.022730182856321335,
            -0.020665226504206657,
            0.0033478462137281895,
            0.02223329059779644,
            0.02934560738503933,
            -0.06939788907766342,
            0.004062767140567303,
            0.005873761605471373,
            0.007544856518507004,
            -0.015360210090875626,
            -0.0193913783878088,
            -0.053976256400346756,
            -0.05060803145170212,
            0.09048061072826385,
            -0.025506289675831795,
            0.03259504958987236,
            0.00874521303921938,
            -0.002356607699766755,
            0.04120737314224243,
            0.018108299002051353,
            -0.01025405153632164,
            -0.013965294696390629,
            -0.029649779200553894,
            -0.024872340261936188,
            -0.008271431550383568,
            -0.0812184065580368,
            -0.015002849511802197,
            0.0053686522878706455,
            0.023209713399410248,
            -0.010361239314079285,
            -0.011428259313106537,
            -0.03559837117791176,
            -0.01956246979534626,
            0.015879269689321518,
            -0.01501390989869833,
            0.0327911451458931,
            0.018327003344893456,
            0.03634725138545036,
            0.019081667065620422,
            0.002028681803494692,
            0.020055606961250305,
            0.007546422071754932,
            -0.03955620154738426,
            0.01970648393034935,
            0.015645485371351242,
            -0.00726869935169816,
            -0.03759005665779114,
            0.013206662610173225,
            0.005035931710153818,
            0.02935870923101902,
            0.014703195542097092,
            -0.015122988261282444,
            0.013714944012463093,
            -0.008335449732840061,
            0.0332697257399559,
            -0.0062551843002438545,
            0.010369953699409962,
            -0.024080337956547737,
            -0.017642956227064133,
            0.02809595689177513,
            0.01334341336041689,
            -0.0244978629052639,
            -0.07665035128593445,
            -0.05109844356775284,
            -0.055872466415166855,
            0.02186463214457035,
            -0.03644057363271713,
            -0.03565149009227753,
            -0.03121693804860115,
            0.032542768865823746,
            0.014337017200887203,
            -0.017514312639832497,
            0.00029770287801511586,
            0.04820050671696663,
            0.04302012547850609,
            0.02860882878303528,
            0.0014231296954676509,
            0.029559919610619545,
            -0.01621554233133793,
            -0.04208534210920334,
            -0.03807882219552994,
            0.04098767042160034,
            -0.06077183410525322,
            -0.025048833340406418,
            0.027024714276194572,
            -0.00961372721940279,
            -0.019277364015579224,
            -0.025462841615080833,
            0.06394195556640625,
            0.023446159437298775,
            0.005450650118291378,
            0.015733642503619194,
            -0.06811640411615372,
            -0.0258872602134943,
            -0.0024668765254318714,
            0.007933996617794037,
            -0.01131619792431593,
            -0.003937241621315479,
            0.019540660083293915,
            -0.03354867547750473,
            0.007726968266069889,
            0.03106318973004818,
            0.02868005260825157,
            0.012174474075436592,
            -0.0180337056517601,
            0.02070585824549198,
            -0.019079025834798813,
            0.024986470118165016,
            0.0035826165694743395,
            -0.02184930630028248,
            0.00017915957141667604,
            -0.029508274048566818,
            -0.013547630980610847,
            0.02934444136917591,
            -0.026287803426384926,
            -0.03610100969672203,
            -0.0324743427336216,
            0.007729883771389723,
            -0.018245218321681023,
            -0.014828821644186974,
            -0.002525590592995286,
            -0.01374815683811903,
            -0.01610632613301277,
            0.08750444650650024,
            0.04096163064241409,
            0.033694151788949966,
            -0.037280481308698654,
            0.010346688330173492,
            0.02627682127058506,
            0.0020527541637420654,
            -0.012340111657977104,
            0.02376067079603672,
            0.027350476011633873,
            -0.019304098561406136,
            0.005005345214158297,
            -0.03238334879279137,
            -0.004847553092986345,
            -0.006514736916869879,
            0.04190497845411301,
            0.04617795720696449,
            0.0009847342735156417,
            0.012314720079302788,
            -0.009828416630625725,
            -0.00515085319057107,
            -0.03376739099621773,
            0.008985834196209908,
            0.006379011087119579,
            -0.0014162476873025298,
            0.012243212200701237,
            -0.015474778600037098,
            -0.037096429616212845,
            -0.020444393157958984,
            -0.01237037219107151,
            -0.01036134734749794,
            0.01383432187139988,
            -0.00035715551348403096,
            -0.008005651645362377,
            0.0034469147212803364,
            0.007636355236172676,
            0.024784207344055176,
            -0.00397115433588624,
            0.03641211614012718,
            -0.02128005586564541,
            -0.008705160580575466,
            0.012647021561861038,
            -0.01104586198925972,
            0.0010646875016391277,
            -0.024476677179336548,
            0.005479414481669664,
            -0.010522029362618923,
            -0.01061339769512415,
            -0.014850234612822533,
            -0.0027463524602353573,
            0.030473114922642708,
            -0.060489192605018616,
            -0.041318949311971664,
            -0.018039634451270103,
            0.0019215475767850876,
            -0.0249433983117342,
            0.005394082050770521,
            0.04392830282449722,
            -0.038844965398311615,
            0.014895115979015827,
            -0.01814529113471508,
            0.001185550238005817,
            -0.00045896603842265904,
            -0.042277850210666656,
            0.011630475521087646,
            -0.007728212047368288,
            0.009933036752045155,
            0.02335362322628498,
            -0.0014716300647705793,
            0.002729072468355298,
            -0.01961813122034073,
            0.013159391470253468,
            -0.0042489757761359215,
            0.0064952922984957695,
            -0.003468439681455493,
            -0.03371422365307808,
            -0.003587057115510106,
            -0.012088117189705372,
            -0.04645247012376785,
            0.014301328919827938,
            0.012649759650230408,
            -0.004095739684998989,
            0.012875283136963844,
            -0.005007656291127205,
            -0.010146036744117737,
            -0.01433501485735178,
            0.017027942463755608,
            -0.025858614593744278,
            0.028056446462869644,
            -0.005947776138782501,
            -0.0030845149885863066,
            -0.04210057854652405,
            -0.018229790031909943,
            -0.0032948756124824286,
            -0.004027379211038351,
            -0.015053517185151577,
            -0.01943124458193779,
            0.02491789683699608,
            0.02638697624206543,
            -0.026369396597146988,
            -0.0039332290180027485,
            -0.0028024474158883095,
            -0.011891309171915054,
            -0.01647869497537613,
            -0.020423900336027145,
            -0.007368982769548893,
            0.002581553300842643,
            -0.020916730165481567,
            0.013464649207890034,
            0.03223982825875282,
            0.01606127806007862,
            -0.00025003310292959213,
            -0.016339238733053207,
            0.0026566628366708755,
            0.017716683447360992,
            -0.014957231469452381,
            -0.021958904340863228,
            0.04893697053194046,
            -0.009868817403912544,
            -0.04134682938456535,
            0.01565409265458584,
            -0.06705835461616516,
            -0.025233691558241844,
            -0.019973445683717728,
            -0.06659383326768875,
            0.018048595637083054,
            0.013627706095576286,
            -0.005041749682277441,
            0.05164414644241333,
            -0.005608916748315096,
            0.000335177406668663,
            -0.021595465019345284,
            0.0362221896648407,
            -0.007107339799404144,
            0.011917690746486187,
            -0.039392609149217606,
            -0.01831826940178871,
            0.01949751190841198,
            -0.0020926338620483875,
            -0.007517530117183924,
            0.018664980307221413,
            -0.003000500611960888,
            0.021097660064697266,
            0.024138525128364563,
            -0.07082101702690125,
            0.024672197178006172,
            0.027444060891866684,
            -0.0002234327548649162,
            -0.04415866732597351,
            0.007520768325775862,
            0.022030280902981758,
            -0.0001875599118648097,
            0.014096921309828758,
            -0.01928391493856907,
            -0.019649174064397812,
            0.02306978963315487,
            -0.020970245823264122,
            0.011541355401277542,
            -0.03634032607078552,
            -0.034703176468610764,
            0.031644225120544434,
            0.011628536507487297,
            -0.08171464502811432,
            0.027342576533555984,
            0.028226006776094437,
            -0.005642714444547892,
            0.028760552406311035,
            0.0019141713855788112,
            0.018451986834406853,
            0.01719919592142105,
            -0.04437361657619476,
            -0.006388574838638306,
            0.021885404363274574,
            -0.008102579973638058,
            -0.07177414000034332,
            -0.03835282102227211,
            -0.028514733538031578,
            -0.022285204380750656,
            0.024129435420036316,
            -0.00686550373211503,
            0.014851219020783901,
            -0.035539284348487854,
            0.0012228479608893394,
            0.015411029569804668,
            -0.008823689073324203,
            -0.0017729210667312145,
            -0.004547261167317629,
            0.0015216362662613392,
            0.012416784651577473,
            0.003386251861229539,
            -0.0035342315677553415,
            -0.006386257242411375,
            -0.012334982864558697,
            0.0074362559244036674,
            -0.03485638648271561,
            -0.00162350048776716,
            0.008319751359522343,
            -0.036015916615724564,
            0.009955844841897488,
            -0.02048821747303009,
            -0.014902637340128422,
            0.053479257971048355,
            -0.010355309583246708,
            -0.04106742888689041,
            -0.02410809136927128,
            0.025379223749041557,
            0.014405136927962303,
            -0.02725451998412609,
            -0.0015191928250715137,
            -0.0284697525203228,
            0.020575599744915962,
            0.013234631158411503,
            0.01437616627663374,
            -0.02369372919201851,
            0.028047559782862663,
            -0.023053260520100594,
            -0.059224050492048264,
            -0.02482909895479679,
            -0.05500499904155731,
            0.009785020723938942,
            -0.03531276807188988,
            -0.06743025034666061,
            -0.006827587261795998,
            -0.017722556367516518,
            0.008157318457961082,
            0.026304947212338448,
            -0.02406838722527027,
            0.0031760449055582285,
            -0.024863671511411667,
            -0.029878024011850357,
            -0.015718666836619377,
            0.03442716225981712,
            0.010914278216660023,
            0.025919010862708092,
            -0.07574394345283508,
            -0.04758806526660919,
            -0.05745821073651314,
            -0.016216659918427467,
            -0.02639828249812126,
            -0.03747664764523506,
            -0.023687930777668953,
            -0.03773092105984688,
            0.014918481931090355,
            -0.017086734995245934,
            -0.02023160830140114,
            -0.004485970828682184,
            0.02251267060637474,
            0.014975588768720627,
            0.018696915358304977,
            0.0047292765229940414,
            -0.050983402878046036,
            -0.03086732141673565,
            -0.0172338355332613,
            -0.02072107046842575,
            0.0029510350432246923,
            -0.010773372836411,
            0.04830550029873848,
            0.014507807791233063,
            0.021506868302822113,
            -0.0014580006245523691,
            -0.0016956990584731102,
            -0.0021316700149327517,
            -0.014193126931786537,
            -0.00907208863645792,
            0.017656462267041206,
            -0.005966297350823879,
            0.026635529473423958,
            -0.042859483510255814,
            0.010538831353187561,
            0.04616083204746246,
            0.03887313976883888,
            -0.05037616193294525,
            -0.03269967809319496,
            0.015412970446050167,
            0.027303820475935936,
            -0.022183779627084732,
            0.03914028778672218,
            -0.006116910371929407,
            -0.025519752874970436,
            -0.04354480281472206,
            0.014830948784947395,
            0.023168353363871574,
            -0.06865429878234863,
            0.05570824816823006,
            -0.014388478361070156,
            -0.010917272418737411,
            0.005315491929650307,
            0.05665349215269089,
            -0.06883544474840164,
            -0.008715076372027397,
            -0.04911700263619423,
            0.012727669440209866,
            -0.02929713763296604,
            0.015482771210372448,
            0.012140299193561077,
            0.04233243688941002,
            -0.004681020975112915,
            0.043684907257556915,
            -0.006539876107126474,
            0.027352679520845413,
            0.03209778666496277,
            -0.012546888552606106,
            -0.06431728601455688,
            0.03650231286883354,
            0.004725589416921139,
            -0.019474053755402565,
            -0.02320595271885395,
            0.023945258930325508,
            0.045124899595975876,
            -0.00842389464378357,
            0.03108580783009529,
            0.022829242050647736,
            -0.04948607459664345,
            -0.04071858525276184,
            -0.0035060388036072254,
            -0.032483480870723724,
            0.00776464119553566,
            -0.0006256090709939599,
            -0.00818402599543333,
            -0.04591827094554901,
            -0.03338104486465454,
            0.023250700905919075,
            0.018643593415617943,
            0.040290724486112595,
            -0.037333764135837555,
            -0.014729942195117474,
            -0.03249160200357437,
            -0.03293837234377861,
            0.011284057050943375,
            0.014425432309508324,
            -0.03743436187505722,
            -0.017133207991719246,
            -0.002997901989147067,
            0.006436994764953852,
            0.007147118449211121,
            -0.01253216527402401,
            0.01651998609304428,
            -0.04199725389480591,
            -0.005473457742482424,
            -0.01682206615805626,
            0.018874498084187508,
            -0.035193875432014465,
            -0.01421072892844677,
            -0.012258028611540794,
            -0.03740718960762024,
            -0.0029232099186629057,
            -0.0019855364225804806,
            -0.007097629830241203,
            -0.006455541588366032,
            0.014391079545021057,
            -0.013762613758444786,
            -0.009070632047951221,
            0.010210510343313217,
            -0.012426840141415596,
            0.007211606949567795,
            -0.04613274708390236,
            0.011462004855275154,
            0.02779197506606579,
            0.017820794135332108,
            0.013068481348454952,
            0.01744920201599598,
            -0.028393913060426712,
            0.00838079210370779,
            -0.014331421814858913,
            -0.041979774832725525,
            -0.013196537271142006,
            0.018634503707289696,
            -0.015172845683991909,
            0.0044268774800002575,
            0.0003446522750891745,
            -0.01855626329779625,
            0.01953107677400112,
            -0.0698467418551445,
            -0.008267137221992016,
            0.03183932974934578,
            -0.006624698638916016,
            -0.05408274009823799,
            0.0035197739489376545,
            0.019193779677152634,
            -0.05020160600543022,
            0.008519194088876247,
            0.021250974386930466,
            -0.01230386272072792,
            -0.025585362687706947,
            0.045958373695611954,
            0.017249124124646187,
            0.009032558649778366,
            -0.02060769312083721,
            -0.03130622208118439,
            0.04145955294370651,
            0.032765332609415054,
            0.03598393127322197,
            -0.0030381702817976475,
            0.02634728141129017,
            0.013457255437970161,
            0.035060811787843704,
            -0.018975794315338135,
            -0.011272336356341839,
            -0.0008620427688583732,
            -0.009246151894330978,
            -0.018100330606102943,
            0.015689440071582794,
            -0.06006624177098274,
            -0.010106327012181282,
            -0.003116447012871504,
            -0.023279717192053795,
            0.0009238382917828858,
            -0.009355798363685608,
            -0.028278114274144173,
            0.037644945085048676,
            -0.016083624213933945,
            0.04396809637546539,
            0.02414230816066265,
            -0.011086405254900455,
            0.004999904427677393,
            0.011987805366516113,
            -0.00849691778421402,
            -0.028151679784059525,
            -0.015706239268183708,
            0.014176057651638985,
            0.01693173684179783,
            0.04816485941410065,
            -0.03173576295375824,
            0.01955464296042919,
            -0.016544947400689125,
            0.0349152497947216,
            -0.0005557196564041078,
            0.026298627257347107,
            0.021011970937252045,
            0.02147490158677101,
            0.0008144458988681436,
            0.04892003908753395,
            0.02118232287466526,
            0.0020745592191815376,
            0.026455288752913475,
            0.05136715993285179,
            -0.021827274933457375,
            -0.04232634976506233,
            -1.9519804482115433e-05,
            -0.012575964443385601,
            0.023159516975283623,
            0.027484925463795662,
            -0.015301557257771492,
            0.02690507099032402,
            -0.014138100668787956,
            0.0030423470307141542,
            0.017460832372307777,
            0.03025859221816063,
            -0.0022511149290949106,
            -0.0037679008673876524,
            -0.015633782371878624,
            0.028251543641090393,
            0.0027008827310055494,
            -0.05620806664228439,
            0.00811817217618227,
            0.017394552007317543,
            0.014495707117021084,
            -0.0030647546518594027,
            -0.02251901477575302,
            -0.00603719474747777,
            0.044894393533468246,
            0.02245917357504368,
            -0.0010242459829896688,
            -0.005763927940279245,
            -0.022290153428912163,
            -0.023717274889349937,
            0.036573100835084915,
            0.01105138473212719,
            -0.02086779847741127,
            -0.007764716632664204,
            -0.01456623524427414,
            -0.014137890189886093,
            -0.016907528042793274,
            0.01105519663542509,
            -0.019102122634649277,
            -0.0037515037693083286,
            -0.01532779261469841,
            -0.0016926066018640995,
            0.021500110626220703,
            0.025606302544474602,
            0.04366008937358856,
            0.0033212315756827593,
            -0.04631628468632698,
            -0.019590390846133232,
            -0.006714139133691788,
            -0.008810512721538544,
            -0.03594443202018738,
            0.012664135545492172,
            -0.03364815562963486,
            -0.007048662286251783,
            -0.0055921352468431,
            -0.03984123840928078,
            -0.029523029923439026,
            0.016198020428419113,
            -0.0009763156995177269,
            -0.001597147318534553,
            0.006364620290696621,
            -0.022397123277187347,
            -0.041516371071338654,
            0.019401466473937035,
            -0.018977366387844086,
            0.013517304323613644,
            0.015723003074526787,
            -0.04770927503705025,
            0.014813204295933247,
            0.025481369346380234,
            -0.022103099152445793,
            0.006277915555983782,
            -0.03216096758842468,
            0.01150629110634327,
            -0.0897991955280304,
            -0.0043436260893940926,
            -0.004051273688673973,
            -0.011824950575828552,
            -0.026559388265013695,
            0.024678578600287437,
            0.009566007182002068,
            -0.026887573301792145,
            0.020742954686284065,
            0.012650685384869576,
            -0.002878833096474409,
            -0.01113317534327507,
            -0.027611026540398598,
            0.004877017345279455,
            -0.014175240881741047,
            -0.055545877665281296,
            -0.02301238477230072,
            -0.009315749630331993,
            0.04054165259003639,
            -0.05618899315595627,
            -0.010215705260634422,
            0.005024903453886509,
            -0.024860979989171028,
            -0.0025621529202908278,
            -0.018193509429693222,
            0.005519623402506113,
            0.011731344275176525,
            -0.007962318137288094,
            -0.018780335783958435,
            -0.008116050623357296,
            -0.04691828787326813,
            -0.02280707098543644,
            -0.024741755798459053,
            -0.017456160858273506,
            0.011031113564968109,
            -0.009093875996768475,
            -0.026986416429281235,
            0.015838321298360825,
            -0.033023588359355927,
            -0.04538886994123459,
            0.06201336532831192,
            -0.003265881910920143,
            -0.0171156357973814,
            -0.015129071660339832,
            -0.036859262734651566,
            -0.015215534716844559,
            0.015817083418369293,
            -0.006621887441724539,
            0.03075188770890236,
            -0.013786692172288895,
            -0.005229003261774778,
            -0.014748611487448215,
            0.005940315779298544,
            -0.00520949624478817,
            0.04033844545483589,
            0.03723780810832977,
            0.005326665472239256,
            -0.06156599149107933,
            -0.016032513231039047,
            -0.003918755799531937,
            0.03111567348241806,
            0.004362733103334904,
            -0.019635485485196114,
            -0.0018233427545055747,
            -0.0346347838640213,
            -0.006946631241589785,
            -0.0410318598151207,
            -0.04931643232703209,
            -0.029248476028442383,
            -0.0056313867680728436,
            0.023150013759732246,
            -0.050683457404375076,
            -0.01480789389461279,
            -0.03445355221629143,
            0.04073614254593849,
            0.024761948734521866,
            -0.0370008759200573,
            0.0092562111094594,
            0.016524655744433403,
            -0.00810935441404581,
            0.028331518173217773,
            -0.021804679185152054,
            0.0009650661959312856,
            0.040492333471775055,
            0.011930453591048717,
            -0.02057805471122265,
            0.010435620322823524,
            0.0025221137329936028,
            0.015352090820670128,
            0.0269227996468544,
            -0.03272966668009758,
            -0.0564282089471817,
            -0.025983933359384537,
            0.07740571349859238,
            0.05003399774432182,
            0.01965104229748249,
            0.035519372671842575,
            -0.027169767767190933,
            -0.05577418580651283,
            -0.031660981476306915,
            0.030805910006165504,
            -0.008785735815763474,
            -0.008368449285626411,
            -0.027181435376405716,
            0.016901450231671333,
            0.021763650700449944,
            -0.03625025600194931,
            0.041181184351444244,
            0.037341419607400894,
            0.029148565605282784,
            -0.0016028161626309156,
            0.001896417117677629,
            0.03497476875782013,
            0.004043444991111755,
            0.015587144531309605,
            0.026013970375061035,
            -0.004252790007740259,
            0.02915985882282257,
            0.02192632667720318,
            -0.06235004588961601,
            0.02757362835109234,
            0.008963462896645069,
            0.0011602622689679265,
            0.018344387412071228,
            -0.03935932368040085,
            0.01575816050171852,
            -0.021216221153736115,
            -0.016729364171624184,
            0.028422269970178604,
            0.012503223493695259,
            0.03475384786725044,
            0.014366495423018932,
            -0.014507584273815155,
            0.024659816175699234,
            -0.025382358580827713,
            0.01956493780016899,
            -0.026476487517356873,
            0.011830595321953297,
            0.036493588238954544,
            -0.006471967790275812,
            0.005217083264142275,
            0.027087273076176643,
            0.0026882546953856945,
            0.010351788252592087,
            -0.01444428414106369,
            0.0012896409025415778,
            -0.024731509387493134,
            0.02883996069431305,
            -0.053936079144477844,
            0.01619650609791279,
            0.0012456707190722227,
            0.021320952102541924,
            0.014558741822838783,
            -0.008513419888913631,
            -0.07436471432447433,
            -0.009569150395691395,
            0.023331906646490097,
            0.010276829823851585,
            0.0046398695558309555,
            -0.018101109191775322,
            -0.007103565614670515,
            0.01943383924663067,
            -0.002409345703199506,
            0.001815028372220695,
            -0.04848254472017288,
            0.02614283747971058,
            -0.048948757350444794,
            -0.003516069147735834,
            0.018138255923986435,
            -0.006628591101616621,
            -0.03783294931054115,
            -0.01735946536064148,
            0.014874527230858803,
            -0.03947015851736069,
            0.012594133615493774,
            -0.02510375902056694,
            -0.017763298004865646,
            -0.02985973097383976,
            -0.03672021999955177,
            0.010349473915994167,
            -0.03172379732131958,
            0.040294598788022995,
            -0.048677653074264526,
            -0.008162032812833786,
            0.015063907019793987,
            -0.010066707618534565,
            0.020228438079357147,
            0.03768671303987503,
            0.01716824620962143,
            -0.09903834015130997,
            -0.007812427822500467,
            -0.016693351790308952,
            -0.01975550875067711,
            0.0022224178537726402,
            0.0019043885404244065,
            -0.026947693899273872,
            0.0011144436430186033,
            0.03134996071457863,
            -0.01275455392897129,
            0.011438182555139065,
            -0.018212178722023964,
            -0.020247971639037132,
            -0.00512598967179656,
            0.011364175006747246,
            0.0057726213708519936,
            0.041172388941049576,
            0.0010649152100086212,
            -0.09678700566291809,
            0.04808162525296211,
            0.008616155944764614,
            -0.015154371038079262,
            0.005046844016760588,
            -0.01637542061507702,
            -0.007217566482722759,
            -0.0009392460924573243,
            -0.0026847259141504765,
            -0.010700007900595665,
            0.03337802737951279,
            0.014047469012439251,
            0.013341440819203854,
            0.016138728708028793,
            -0.0011988740880042315,
            -0.0011498038657009602,
            0.004418426658958197,
            -0.029739249497652054,
            0.015679361298680305,
            0.04605099558830261,
            0.012475159019231796,
            0.004606346599757671,
            0.0240471288561821,
            0.006822007708251476,
            -0.02359185181558132,
            -0.007866475731134415,
            -0.020954769104719162,
            -0.0251684058457613,
            0.008220577612519264,
            0.03393546864390373,
            -0.05430959165096283,
            -0.015902549028396606,
            0.00354767101816833,
            -0.009201024658977985,
            0.025961603969335556,
            0.021331660449504852,
            -0.003559401258826256,
            0.00733307795599103,
            -0.07843755930662155,
            -0.03986763209104538,
            0.009144383482635021,
            -0.03342384845018387,
            0.010740137659013271,
            -0.013548936694860458,
            -0.008047617971897125,
            -0.037629298865795135,
            -0.025117814540863037,
            0.034163352102041245,
            0.008461101911962032,
            0.020396310836076736,
            0.02089216373860836,
            0.03266952931880951,
            0.016101373359560966,
            -0.01879117451608181,
            -0.02935226447880268,
            -0.024713659659028053,
            -0.02000603638589382,
            -0.005930393002927303,
            -0.034980107098817825,
            -0.05331307649612427,
            0.017138075083494186,
            -0.03305542841553688,
            -0.016925325617194176,
            -0.047933392226696014,
            -0.015525782480835915,
            -0.02704712375998497,
            0.03558772802352905,
            0.01662641018629074,
            0.02864588238298893,
            0.02125171385705471,
            0.02942989207804203,
            -0.005442489869892597,
            -0.018204549327492714,
            -0.07612582296133041,
            0.014813916757702827,
            0.04066329076886177,
            0.03142375871539116,
            -0.04045867919921875,
            0.01653096079826355,
            0.019728899002075195,
            -0.02272602543234825,
            0.05955469235777855,
            -0.02371758036315441,
            0.022372275590896606,
            -0.03145354613661766,
            -0.018295807763934135,
            0.02767486870288849,
            -0.02877098321914673,
            0.0006202562944963574,
            -0.02640753611922264,
            0.005971725098788738,
            -0.03493864834308624,
            -0.019647398963570595,
            0.0029481875244528055,
            0.010370920412242413,
            0.0014110371703281999,
            0.010435933247208595,
            -0.004175585228949785,
            -0.12832942605018616,
            -0.030134931206703186,
            0.0016737360274419188
        ],
        [
            0.019340163096785545,
            -0.01763107068836689,
            -0.021972116082906723,
            0.04527188837528229,
            0.013027635402977467,
            0.009621379896998405,
            0.03976180776953697,
            -0.017933666706085205,
            -0.0010002116905525327,
            -0.009193462319672108,
            -0.026879822835326195,
            -0.0030446869786828756,
            -0.01976596936583519,
            0.01961311511695385,
            -0.06019691750407219,
            0.02161039412021637,
            -0.00991977658122778,
            -0.016034850850701332,
            -0.02456323429942131,
            -0.02234993316233158,
            -0.009798611514270306,
            0.01999199204146862,
            0.012177946977317333,
            -0.018458779901266098,
            -0.03519080579280853,
            -0.01589209772646427,
            0.051949139684438705,
            -0.006241333670914173,
            0.015281329862773418,
            -0.006233429070562124,
            -0.030713772401213646,
            -0.012060504406690598,
            0.017203643918037415,
            -0.03495339676737785,
            0.009998497553169727,
            0.038069870322942734,
            0.0008361484506167471,
            0.01532858144491911,
            -0.033996548503637314,
            -0.010918091051280499,
            -0.03700720891356468,
            -0.03960477188229561,
            0.05931568145751953,
            -0.021758288145065308,
            0.002877800492569804,
            -0.01868957094848156,
            0.0028123778756707907,
            -0.018512127920985222,
            0.022000951692461967,
            0.012170297093689442,
            0.014404944144189358,
            0.009164349175989628,
            0.009721806272864342,
            -0.010607605800032616,
            0.01109415851533413,
            -0.0006664847023785114,
            -0.01999857649207115,
            0.015193404629826546,
            -0.013584233820438385,
            -0.006072061136364937,
            -0.01952308788895607,
            -0.038140442222356796,
            0.013433299958705902,
            -0.07032295316457748,
            -0.01734299771487713,
            -0.01984856091439724,
            -0.013880562037229538,
            0.00979740358889103,
            -0.00822459813207388,
            -0.018481420353055,
            -0.0017217963468283415,
            -0.0036176317371428013,
            0.01997373439371586,
            0.030364079400897026,
            0.008715840056538582,
            0.000522074697073549,
            0.018936442211270332,
            -0.01953202672302723,
            0.04585554823279381,
            0.029625916853547096,
            0.02439814992249012,
            -0.01355956494808197,
            -0.004573538433760405,
            -0.023859212175011635,
            -0.00338639784604311,
            -0.02320758067071438,
            0.025672931224107742,
            0.031167257577180862,
            0.002274720696732402,
            0.03682098910212517,
            -0.044433124363422394,
            -0.006082509178668261,
            0.01170516386628151,
            -0.037427715957164764,
            -0.026428010314702988,
            -0.022316239774227142,
            0.019257642328739166,
            0.002396180760115385,
            0.043344467878341675,
            0.03582042455673218,
            -0.04808502644300461,
            -0.05340474471449852,
            -0.034827083349227905,
            -0.025837300345301628,
            -0.01008229423314333,
            -0.022426044568419456,
            -0.04715719446539879,
            0.014926077798008919,
            0.02659858763217926,
            0.015102881006896496,
            -0.04963384196162224,
            -0.029781898483633995,
            -0.008007671684026718,
            0.034400537610054016,
            0.004682623781263828,
            0.004967720713466406,
            0.016097433865070343,
            0.05127788335084915,
            -0.0021798559464514256,
            0.008001923561096191,
            0.003068367950618267,
            -0.020787591114640236,
            0.06454494595527649,
            0.025658564642071724,
            -0.0347210094332695,
            0.012257860042154789,
            0.011721679009497166,
            0.006846549455076456,
            0.015207618474960327,
            0.009663956239819527,
            -0.011816808022558689,
            -0.025787057355046272,
            0.015814373269677162,
            0.004666280001401901,
            0.020962141454219818,
            0.00011632439418463036,
            0.0012118882732465863,
            0.021781988441944122,
            -0.000872192729730159,
            -0.024097749963402748,
            0.03245898336172104,
            -0.029358496889472008,
            -0.004756225738674402,
            -0.017668619751930237,
            0.00898809265345335,
            -0.006937656085938215,
            -0.035763949155807495,
            -0.03849867358803749,
            -0.005425894632935524,
            -0.02211502566933632,
            -0.02403424121439457,
            -0.015955455601215363,
            0.03278550133109093,
            -0.046322986483573914,
            0.006656189449131489,
            -0.01951538771390915,
            -0.005607245024293661,
            -0.007672519888728857,
            -0.0009499259176664054,
            -0.047944050282239914,
            -0.011419468559324741,
            0.040777094662189484,
            -0.010270738042891026,
            0.019455401226878166,
            -0.011234011501073837,
            0.018009738996624947,
            0.005613965913653374,
            0.0033577384892851114,
            0.0004466943209990859,
            -0.018608879297971725,
            -0.018695611506700516,
            0.047839630395174026,
            0.01338657271116972,
            -0.027651088312268257,
            0.008622664026916027,
            -0.018042387440800667,
            -0.0025826245546340942,
            -0.013816147111356258,
            -0.02545352280139923,
            -0.032212864607572556,
            0.032542143017053604,
            -0.011894905008375645,
            0.026994042098522186,
            -0.008245390839874744,
            0.004577078856527805,
            -0.021979760378599167,
            -0.014403151348233223,
            0.011129907332360744,
            0.020084142684936523,
            0.013083292171359062,
            -0.0032845353707671165,
            0.011894344352185726,
            -0.028267018496990204,
            -0.02384038269519806,
            0.009840846993029118,
            -0.021230895072221756,
            0.02345646545290947,
            0.010045932605862617,
            -0.02390472963452339,
            0.0451313778758049,
            -0.028680091723799706,
            0.0037827009800821543,
            0.008213499560952187,
            0.0069633484818041325,
            0.03470104932785034,
            -0.0036538357380777597,
            0.02632470428943634,
            -0.009617036208510399,
            0.0020741885527968407,
            0.017366938292980194,
            -0.005319735035300255,
            0.0016439090250059962,
            -0.03606123849749565,
            0.001063961535692215,
            0.016058750450611115,
            0.013913820497691631,
            0.02036515809595585,
            -0.019711535423994064,
            0.025774531066417694,
            -0.01188110001385212,
            0.023355280980467796,
            0.01274934597313404,
            0.00028190299053676426,
            0.0347180999815464,
            0.027993781492114067,
            -0.008649117313325405,
            -0.009605570696294308,
            0.012236726470291615,
            0.017016958445310593,
            0.012090571224689484,
            -0.015090362168848515,
            -0.024580487981438637,
            0.0007633633213117719,
            0.0015999573515728116,
            -0.0279758982360363,
            0.01657017692923546,
            -0.0031777049880474806,
            0.010277988389134407,
            -0.00165791145991534,
            0.015827864408493042,
            -0.001827785512432456,
            0.01055050641298294,
            -0.002776535227894783,
            0.018800511956214905,
            -0.03313756734132767,
            -0.016630705446004868,
            0.007050871849060059,
            -0.07162519544363022,
            0.037788502871990204,
            -0.02315911464393139,
            -0.0021945552434772253,
            -0.009885935112833977,
            0.03615094721317291,
            0.003000921569764614,
            -0.0017060453537851572,
            -0.0027294200845062733,
            0.0276919174939394,
            -0.030833493918180466,
            0.004577783867716789,
            -0.06307236105203629,
            0.02760101482272148,
            0.04043159633874893,
            -0.012805243022739887,
            -0.008626905269920826,
            -0.001564958249218762,
            0.00423318101093173,
            0.019232584163546562,
            -0.03165362402796745,
            -0.021057290956377983,
            -0.01868678629398346,
            0.0008585746982134879,
            -0.021957410499453545,
            -0.0051842546090483665,
            -0.015005703084170818,
            0.020393937826156616,
            -0.00996457226574421,
            -0.00875665619969368,
            0.015543628484010696,
            0.0005601287703029811,
            0.00948721170425415,
            -0.004906047601252794,
            0.009347915649414062,
            0.041583940386772156,
            0.004160325508564711,
            -0.03367840498685837,
            -0.01244500745087862,
            0.0009876847034320235,
            -0.03686744347214699,
            0.012861965224146843,
            0.012974167242646217,
            0.018478190526366234,
            -0.0001436455495422706,
            -0.021975671872496605,
            -0.002386878477409482,
            0.0007510866271331906,
            -0.022648021578788757,
            -0.029054157435894012,
            -0.04389845207333565,
            -0.04173135757446289,
            0.007937842048704624,
            0.028575902804732323,
            0.010617178864777088,
            -0.001061566756106913,
            -0.01975369080901146,
            0.01822422631084919,
            -0.007586165796965361,
            0.004136263392865658,
            -0.04860689118504524,
            -0.024518348276615143,
            -0.05479801073670387,
            -0.028256716206669807,
            0.02599146030843258,
            0.017416082322597504,
            -0.014757645316421986,
            -0.02645707130432129,
            -0.0309749748557806,
            0.01203722320497036,
            0.010212080553174019,
            0.019632605835795403,
            0.01034480705857277,
            -0.006507809739559889,
            -0.021491728723049164,
            -0.039519648998975754,
            -0.017571449279785156,
            0.012612458318471909,
            -0.005676063243299723,
            -0.005938950926065445,
            -0.00267219846136868,
            -0.0015934581169858575,
            -0.001004819292575121,
            0.012973605655133724,
            0.004630770068615675,
            0.027591347694396973,
            0.0246049165725708,
            -0.022685565054416656,
            0.02660142257809639,
            0.01189104188233614,
            0.021913258358836174,
            -0.002433666493743658,
            -0.026390448212623596,
            0.010885129682719707,
            -0.030928313732147217,
            0.04617530107498169,
            0.013789488933980465,
            0.02115902677178383,
            -0.004066829569637775,
            -0.0322103425860405,
            -0.02481897361576557,
            0.02798498421907425,
            0.034710802137851715,
            -0.005071920808404684,
            -0.04549354687333107,
            -0.0002879314124584198,
            -0.014656132087111473,
            -0.04117072373628616,
            -0.008354841731488705,
            0.013806306757032871,
            0.00029007549164816737,
            0.010346684604883194,
            0.0007739035645499825,
            -0.017191490158438683,
            0.02144731394946575,
            0.05221518501639366,
            -0.017372261732816696,
            0.012244483456015587,
            -0.0015565836802124977,
            -0.03678727522492409,
            0.00043457577703520656,
            0.03522665053606033,
            -0.014864901080727577,
            -0.02715994231402874,
            -0.01988818682730198,
            -0.00410230690613389,
            0.008198609575629234,
            -0.008223715238273144,
            -0.04461362585425377,
            -0.010353311896324158,
            0.004352320451289415,
            0.02003340795636177,
            -0.013508932664990425,
            0.009956995956599712,
            -0.016747742891311646,
            0.01658383011817932,
            -0.008637568913400173,
            0.007582883816212416,
            -0.013945229351520538,
            0.021921923384070396,
            -0.008826307952404022,
            0.02119484916329384,
            -0.015379833057522774,
            0.005276606883853674,
            0.04682223126292229,
            0.0009540642495267093,
            0.009566928260028362,
            -0.026177801191806793,
            -0.010051959194242954,
            0.014359998516738415,
            0.0010135070187970996,
            0.03068726509809494,
            0.0031819280702620745,
            -0.003896536072716117,
            -0.02676680125296116,
            0.0074073877185583115,
            0.009164687246084213,
            -0.027095917612314224,
            -0.06291666626930237,
            0.02588656172156334,
            -0.020046601071953773,
            0.0311944130808115,
            -0.0029372884891927242,
            -0.004781410098075867,
            0.02697540819644928,
            0.04833656921982765,
            0.005401651840656996,
            -0.012394777499139309,
            0.023775950074195862,
            -0.004534941632300615,
            -0.018922382965683937,
            0.027044542133808136,
            0.02199840359389782,
            0.004049992188811302,
            0.0023398352786898613,
            0.029988938942551613,
            -0.017206359654664993,
            0.021123209968209267,
            -0.005491893272846937,
            0.04328339174389839,
            -0.00701847905293107,
            0.007873660884797573,
            0.01314952690154314,
            0.028739074245095253,
            0.03473113849759102,
            0.015010920353233814,
            -0.04330617934465408,
            0.009536253288388252,
            0.021158726885914803,
            0.016149621456861496,
            0.0173033457249403,
            0.004059997852891684,
            0.018217643722891808,
            -0.01791416108608246,
            -0.021327104419469833,
            0.01557132974267006,
            -0.010597053915262222,
            0.036279186606407166,
            -0.028038041666150093,
            -0.0069871018640697,
            0.07497190684080124,
            -0.010910695418715477,
            -0.01439445000141859,
            -0.020580817013978958,
            -0.02734898403286934,
            -0.051623955368995667,
            -3.678275606944226e-05,
            -0.0034415724221616983,
            -0.0393291711807251,
            -0.02520822547376156,
            -0.022017227485775948,
            -0.01637686789035797,
            0.012881356291472912,
            -0.021236412227153778,
            -0.004677504301071167,
            0.020246388390660286,
            -0.032242413610219955,
            -0.03531983867287636,
            0.03482876345515251,
            0.019210441038012505,
            0.028419379144906998,
            -0.029022028669714928,
            -0.019001340493559837,
            0.014317731373012066,
            0.01270361803472042,
            -0.021486643701791763,
            -0.003121797228232026,
            0.006684846710413694,
            0.02771705947816372,
            -0.00723640713840723,
            -0.007057939190417528,
            -0.021716156974434853,
            0.028103012591600418,
            0.012386704795062542,
            0.010256224311888218,
            0.03494509682059288,
            0.002894628793001175,
            -0.020945396274328232,
            -0.03519811853766441,
            -0.0012516491115093231,
            0.004605664871633053,
            0.09298978745937347,
            -0.006029411684721708,
            0.030120251700282097,
            0.002053474774584174,
            -0.02257259376347065,
            0.00818500854074955,
            -0.014705811627209187,
            0.014335275627672672,
            -0.026395326480269432,
            0.004157420247793198,
            0.022480661049485207,
            0.01043330691754818,
            0.0207373034209013,
            -0.010057810693979263,
            0.0039688171818852425,
            -0.014317921362817287,
            -0.021762963384389877,
            -0.002350708469748497,
            0.023933682590723038,
            -0.003158011008054018,
            -0.01040625385940075,
            0.01904609426856041,
            -0.013834462501108646,
            0.008401809260249138,
            -0.02815418504178524,
            -0.030594002455472946,
            -0.002592615317553282,
            0.010741356760263443,
            -0.009126774966716766,
            0.04080607742071152,
            -0.021348465234041214,
            0.0262475423514843,
            0.005973305553197861,
            -0.02732972800731659,
            0.03306092694401741,
            -0.012844262644648552,
            0.028363237157464027,
            0.0212529469281435,
            -0.0768209844827652,
            -0.03907134011387825,
            0.013523229397833347,
            -0.011668957769870758,
            -0.013918274082243443,
            -0.02527712471783161,
            0.03264707326889038,
            -0.018693875521421432,
            0.027101585641503334,
            -0.05175604671239853,
            -0.007945917546749115,
            -0.01030645426362753,
            0.027883194386959076,
            0.024820592254400253,
            -0.013273068703711033,
            0.024381060153245926,
            0.019102483987808228,
            0.013666496612131596,
            -0.0157616026699543,
            -0.024732541292905807,
            -0.018948731943964958,
            0.022226864472031593,
            0.011118099093437195,
            -0.01661594584584236,
            -0.017120270058512688,
            0.026859233155846596,
            -0.024205928668379784,
            0.005533310119062662,
            -0.01728021167218685,
            0.02858811616897583,
            0.016111932694911957,
            0.022649358958005905,
            -0.012165160849690437,
            -0.021976197138428688,
            -0.02801617793738842,
            -0.023786086589097977,
            -0.006673760246485472,
            0.013670648448169231,
            0.03657401353120804,
            0.011809390038251877,
            -0.046626076102256775,
            0.021585753187537193,
            0.0217277854681015,
            0.014986311085522175,
            -0.0033936440013349056,
            -0.020350296050310135,
            0.02524452470242977,
            0.03393888846039772,
            0.01287241280078888,
            -0.015048341825604439,
            -0.02860870212316513,
            0.026175126433372498,
            0.007458280771970749,
            0.018495256081223488,
            0.0030278919730335474,
            0.013023744337260723,
            0.011187482625246048,
            -0.04292171820998192,
            -0.009285403415560722,
            0.017640821635723114,
            3.1787621992407367e-06,
            0.02504272572696209,
            -0.014458725228905678,
            0.0222457442432642,
            -0.01785142719745636,
            -0.015828629955649376,
            -0.007622768171131611,
            -0.008589192293584347,
            0.0004455144517123699,
            0.01562866009771824,
            -0.013463801704347134,
            0.0016536497278138995,
            -0.008513418026268482,
            -0.031800154596567154,
            -0.020722681656479836,
            0.03611833602190018,
            -0.032846953719854355,
            0.0025033073034137487,
            0.018474092707037926,
            -0.02932840958237648,
            0.021874021738767624,
            0.01746242679655552,
            0.0014827892882749438,
            -0.012393426150083542,
            0.028960436582565308,
            -0.023616857826709747,
            -0.019949469715356827,
            -0.0028989517595618963,
            0.01888732984662056,
            0.03788148984313011,
            0.006949937902390957,
            0.0036149148363620043,
            0.024191943928599358,
            0.01484599057585001,
            -0.019018419086933136,
            0.011910637840628624,
            0.017185378819704056,
            -0.005655509885400534,
            -0.022135311737656593,
            0.0064188409596681595,
            -0.03572650998830795,
            7.695602107560262e-05,
            -0.012257031165063381,
            0.005339933559298515,
            -0.0047598122619092464,
            0.010695782490074635,
            -0.013881764374673367,
            -0.0001791978720575571,
            -0.04616767540574074,
            0.01913580112159252,
            0.016034478321671486,
            0.002408071421086788,
            0.015124917030334473,
            -0.018019383773207664,
            -0.016285410150885582,
            -0.008162536658346653,
            -0.06897903233766556,
            0.0032790040131658316,
            -0.022171717137098312,
            0.04369143024086952,
            -0.0025668125599622726,
            0.00677364319562912,
            -0.0048229931853711605,
            0.024381358176469803,
            -0.011072111316025257,
            -0.031396977603435516,
            0.009934194386005402,
            0.0051204790361225605,
            0.03026207722723484,
            -0.016750816255807877,
            0.02683526650071144,
            -0.02348911575973034,
            0.029104676097631454,
            -0.005088279955089092,
            0.03392454981803894,
            -0.029367361217737198,
            -0.016994383186101913,
            -0.03603651002049446,
            0.03758353367447853,
            -0.0008632033714093268,
            -0.01209783274680376,
            -0.0009801125852391124,
            -0.026895007118582726,
            0.0028143697418272495,
            -0.03730485588312149,
            0.02429235726594925,
            -0.005838243756443262,
            -0.035232063382864,
            0.001502716215327382,
            -0.002614759374409914,
            0.02897028811275959,
            -0.0033257813192903996,
            -0.010690136812627316,
            0.0229849424213171,
            0.009575653821229935,
            -0.00021988825756125152,
            0.016299786046147346,
            -0.0025621051900088787,
            -0.009903211146593094,
            -0.009579842910170555,
            0.012508472427725792,
            0.01864057593047619,
            0.01939067244529724,
            -0.03651609271764755,
            -0.04263237118721008,
            -0.05300610512495041,
            0.004929646383970976,
            -0.009434185922145844,
            0.030299663543701172,
            -0.002148096216842532,
            0.020845312625169754,
            -0.030804594978690147,
            0.004001612775027752,
            0.038654714822769165,
            0.036401282995939255,
            -0.042087264358997345,
            0.03147643804550171,
            -0.009239341132342815,
            0.02512381598353386,
            -0.03196801617741585,
            -0.0068775382824242115,
            0.01632257178425789,
            -0.0023319588508456945,
            -0.024868464097380638,
            -0.02598164975643158,
            0.03635769337415695,
            -0.04436130449175835,
            -0.014250647276639938,
            -0.006968945264816284,
            0.004602504428476095,
            0.04282220080494881,
            -0.023731494322419167,
            -0.0013668404426425695,
            0.013090366497635841,
            0.021856704726815224,
            0.021139206364750862,
            0.016478076577186584,
            -0.05098488926887512,
            -0.015901843085885048,
            -0.010910648852586746,
            0.004420061595737934,
            0.02485731802880764,
            -0.011955603957176208,
            0.008565026335418224,
            0.022405380383133888,
            -0.019498774781823158,
            -0.011632305569946766,
            -0.002284714952111244,
            0.033410150557756424,
            -0.014553860761225224,
            0.008744175545871258,
            -0.03160245716571808,
            -0.019755467772483826,
            0.023461660370230675,
            0.025134723633527756,
            0.006361599545925856,
            -0.009733105078339577,
            -0.020788270980119705,
            0.008506761863827705,
            -0.006944779306650162,
            -0.02703612484037876,
            -0.006403633393347263,
            0.012669871561229229,
            -0.027480145916342735,
            0.014844262041151524,
            -0.011864094994962215,
            -0.005990531295537949,
            -0.06095936894416809,
            -0.008027025498449802,
            0.02886131778359413,
            -0.01302351988852024,
            -0.017590856179594994,
            -0.010981249623000622,
            -0.006785683799535036,
            0.023131564259529114,
            0.02561003528535366,
            0.01318525243550539,
            0.04407026246190071,
            -0.030497195199131966,
            -0.02199956215918064,
            0.007198451552540064,
            0.024701645597815514,
            -0.007100359536707401,
            0.013593092560768127,
            0.01116980891674757,
            -0.005842495243996382,
            -0.056180622428655624,
            0.019594991579651833,
            0.026248926296830177,
            0.00922470260411501,
            -0.04625074937939644,
            -0.009041168726980686,
            -0.01740347221493721,
            -0.005982405040413141,
            -0.020509084686636925,
            0.023622488602995872,
            0.04247631877660751,
            0.019000548869371414,
            0.034356456249952316,
            -0.041744984686374664,
            -0.01816975511610508,
            -0.034030020236968994,
            0.04582330584526062,
            -0.009923173114657402,
            -0.015881802886724472,
            0.016249343752861023,
            -0.04164128750562668,
            -0.029055582359433174,
            -0.0023023239336907864,
            0.007369665894657373,
            0.015442331321537495,
            -0.0013692326610907912,
            -0.006177844945341349,
            0.00399002805352211,
            -0.02839386835694313,
            -0.023206625133752823,
            -0.009814567863941193,
            -0.02837374620139599,
            0.10238714516162872,
            -0.034420378506183624,
            0.03806265816092491,
            -0.02126924879848957,
            0.01001344807446003,
            -0.0013178805820643902,
            -0.0842071995139122,
            -0.012020195834338665,
            -0.00391047541052103,
            0.02393202856183052,
            0.015591920353472233,
            0.02213253080844879,
            0.004601554479449987,
            -0.03993500769138336,
            -0.005046199541538954,
            0.0006440322031266987,
            0.0011755707673728466,
            -0.03517283499240875,
            0.039742834866046906,
            -0.021797196939587593,
            -0.000962768099270761,
            -0.01777239888906479,
            0.03180232644081116,
            0.004193643573671579,
            -0.02881258726119995,
            -0.01728767529129982,
            0.00971130095422268,
            0.002573461038991809,
            -0.011351445689797401,
            0.014805184677243233,
            0.0005172736709937453,
            -0.015409191139042377,
            0.01851079985499382,
            -0.0225736815482378,
            -0.021320870146155357,
            0.020759342238307,
            -0.01346622221171856,
            -0.023865144699811935,
            -0.012033043429255486,
            -0.026137592270970345,
            -0.011104411445558071,
            0.023798605427145958,
            0.02077307552099228,
            0.0005502511630766094,
            0.013764695264399052,
            0.014169348403811455,
            -0.030460650101304054,
            0.012155970558524132,
            -0.003085070988163352,
            0.02263851836323738,
            -0.04292928799986839,
            0.013047350570559502,
            -0.001864727819338441,
            0.02456318587064743,
            0.010859266854822636,
            -0.012864556163549423,
            0.04244919866323471,
            0.024592943489551544,
            0.0032187358010560274,
            0.01068117842078209,
            -0.012310895137488842,
            -0.031743571162223816,
            -0.004866157192736864,
            0.00595594197511673,
            -0.011043892242014408,
            0.009840826503932476,
            -0.007463586051017046,
            0.034375086426734924,
            -0.04173431545495987,
            0.010836698114871979,
            -0.022007303312420845,
            -0.002382800215855241,
            0.001523215789347887,
            -0.049094315618276596,
            -0.008642947301268578,
            0.0018491388764232397,
            -0.003964142873883247,
            -0.000994526781141758,
            -0.054804615676403046,
            -4.457821705727838e-05,
            -0.0041467114351689816,
            -0.001555275171995163,
            0.013296021148562431,
            0.043814342468976974,
            -0.010756663978099823,
            -0.015806471928954124,
            -0.00751348165795207,
            0.016401421278715134,
            0.0346364863216877,
            -0.013498915359377861,
            0.033088479191064835,
            0.005799836479127407,
            0.004981816280633211,
            -0.019148413091897964,
            0.0003654889587778598,
            0.01793329417705536,
            0.006176434922963381,
            -0.022246582433581352,
            0.012625311501324177,
            -0.030334440991282463,
            -0.004986300598829985,
            0.018724976107478142,
            0.03486795723438263,
            -0.011440939269959927,
            -0.026621216908097267,
            -0.01508496142923832,
            0.016217490658164024,
            -0.022069787606596947,
            -0.018018638715147972,
            0.05576763674616814,
            -0.04913537949323654,
            0.02956720069050789,
            0.05358700826764107,
            0.009979652240872383,
            -0.013116993941366673,
            -0.020198510959744453,
            0.0033507775515317917,
            -0.034963686019182205,
            0.01857992634177208,
            -0.027503471821546555,
            0.057592134922742844,
            -0.0003845254541374743,
            -0.006704429164528847,
            -0.028854062780737877,
            -0.009797098115086555,
            0.06436427682638168,
            0.030247120186686516,
            0.022612271830439568,
            -0.03105185180902481,
            -0.06062047556042671,
            0.006143012084066868,
            0.028837978839874268,
            -0.01489112339913845,
            0.04275761917233467,
            -0.02538222447037697,
            0.02662726491689682,
            -0.01671908237040043,
            0.0035483092069625854,
            -0.028471073135733604,
            0.013544503599405289,
            -0.01889718510210514,
            -0.013457589782774448,
            0.002056230790913105,
            0.004273922648280859,
            -0.022053586319088936,
            -0.023127153515815735,
            -0.003484890563413501,
            0.02889499068260193,
            0.018175719305872917,
            0.023996524512767792,
            -0.015519293025135994,
            0.024961460381746292,
            0.02047833241522312,
            -0.01582038402557373,
            0.014428346417844296,
            0.016533151268959045,
            -0.0033436156809329987,
            0.03223096951842308,
            -0.02627546526491642,
            -0.036041002720594406,
            -0.022823749110102654,
            -0.0027005872689187527,
            0.010598467662930489,
            -0.008368155919015408,
            0.03780801221728325,
            -0.04098490998148918,
            -0.007989875040948391,
            0.028468944132328033,
            0.004500247538089752,
            0.011212213896214962,
            0.04117094352841377,
            -0.013579499907791615,
            -0.02610746957361698,
            -0.016697844490408897,
            0.013411663472652435,
            0.021285997703671455,
            0.0006343748536892235,
            0.026607073843479156,
            -0.010438906960189342,
            0.040918245911598206,
            -0.024401256814599037,
            -0.03030519001185894,
            -0.03812509775161743,
            -0.014117243699729443,
            -0.012184074148535728,
            0.03890819847583771,
            -0.03607301414012909,
            0.01921769045293331,
            -0.030190367251634598,
            0.03619951382279396,
            -0.056086380034685135,
            -0.004773449618369341,
            -0.04644713178277016,
            -0.0074104247614741325,
            0.017818408086895943,
            -0.005733394995331764,
            0.007726638112217188,
            -0.026499342173337936,
            -0.010485701262950897,
            -0.0009750562603585422,
            0.010997148230671883,
            -0.008133728988468647,
            0.00708418944850564,
            6.7871942519559525e-06,
            0.024732131510972977,
            -0.006317377556115389,
            -0.006926039699465036,
            -0.03509103134274483,
            0.015214325860142708,
            0.011543461121618748,
            0.0024302068632096052,
            0.012826364487409592,
            0.010323470458388329,
            0.011986220255494118,
            0.0017949986504390836,
            0.022926075384020805,
            -0.002042636973783374,
            -0.03513652831315994,
            0.0077026840299367905,
            0.015213514678180218
        ],
        [
            -0.03729056566953659,
            -0.0028453506529331207,
            -0.0038033293094486,
            0.0014729826943948865,
            -0.022946510463953018,
            0.0023808511905372143,
            -0.022970454767346382,
            -0.028301777318120003,
            5.838850120198913e-05,
            0.004670536145567894,
            -0.029910460114479065,
            0.012808023020625114,
            -0.012599124573171139,
            0.037584349513053894,
            0.021552590653300285,
            0.0021955270785838366,
            0.008647728711366653,
            0.04891008511185646,
            -0.003232341492548585,
            0.03263672813773155,
            -0.03119790554046631,
            0.03011314757168293,
            -0.015093037858605385,
            0.025168240070343018,
            0.0036479311529546976,
            0.004046448040753603,
            -0.02581523172557354,
            -0.028806746006011963,
            -0.042965006083250046,
            0.025362640619277954,
            0.035034362226724625,
            -0.006518206093460321,
            0.015343787148594856,
            -0.010945458896458149,
            -0.014962551183998585,
            -0.012198043055832386,
            -0.032189689576625824,
            0.007787027396261692,
            0.014179772697389126,
            0.0012389787007123232,
            -0.032802946865558624,
            -0.006208452396094799,
            0.018277408555150032,
            -0.01942497305572033,
            0.012022897601127625,
            -0.0035651596263051033,
            0.0191513504832983,
            -0.03682655468583107,
            0.01165380235761404,
            -0.013448277488350868,
            0.0154380789026618,
            0.027503179386258125,
            -0.010079450905323029,
            -0.03445757180452347,
            0.013038788922131062,
            -0.007800237741321325,
            -0.0006169406115077436,
            -0.004634240176528692,
            -0.019250785931944847,
            0.018332043662667274,
            0.033336762338876724,
            -0.008677871897816658,
            -0.010186678729951382,
            0.03622935712337494,
            -0.007893656380474567,
            0.026747435331344604,
            0.004022602923214436,
            0.01077317539602518,
            0.026927340775728226,
            0.006012364756315947,
            -0.009367797523736954,
            -0.00034412997774779797,
            0.007776319514960051,
            0.01616542972624302,
            0.010916030965745449,
            -0.039807695895433426,
            -0.038530007004737854,
            -0.0063172574155032635,
            0.018472427502274513,
            -0.002091155154630542,
            -0.022745002061128616,
            0.017200777307152748,
            -0.003672320395708084,
            0.009019249118864536,
            0.01001845020800829,
            0.051368676126003265,
            0.022069184109568596,
            -0.0033918104600161314,
            0.027695661410689354,
            0.029024967923760414,
            -0.02219225838780403,
            0.023121491074562073,
            0.021874651312828064,
            0.019130127504467964,
            0.029608409851789474,
            0.013228483498096466,
            0.004503913223743439,
            0.01348086167126894,
            -0.06893966346979141,
            0.022136079147458076,
            -0.02074545994400978,
            0.0043432037346065044,
            -0.02726241946220398,
            0.013303462415933609,
            0.022942468523979187,
            0.005180445034056902,
            0.0030291087459772825,
            0.02183603122830391,
            -0.013006784953176975,
            0.02207261137664318,
            -0.03183896839618683,
            0.013376994989812374,
            0.02658218704164028,
            0.022336583584547043,
            -0.04178943857550621,
            0.03582281619310379,
            -4.218064350425266e-05,
            -0.019193973392248154,
            0.02020702324807644,
            -0.025403039529919624,
            -0.027065612375736237,
            0.01096862368285656,
            0.024160778149962425,
            0.03009345941245556,
            0.03034231998026371,
            0.01731867343187332,
            -3.96430050386698e-06,
            0.004671361297369003,
            -0.003402345348149538,
            -0.0045319716446101665,
            0.017627520486712456,
            -0.04259101673960686,
            0.012651677243411541,
            -0.01486560981720686,
            -0.028863511979579926,
            -0.03282059356570244,
            0.014768142253160477,
            0.03535448759794235,
            0.02599748596549034,
            -0.04068493843078613,
            0.012975111603736877,
            0.017160749062895775,
            -0.008158518001437187,
            0.008178582414984703,
            -0.002788008889183402,
            -0.012108289636671543,
            0.008072360418736935,
            -0.005238125566393137,
            -0.00823273602873087,
            -0.014242881909012794,
            -0.0157481636852026,
            0.027806049212813377,
            -0.01242426410317421,
            0.03553396463394165,
            0.04218988120555878,
            0.006455820519477129,
            0.02275877259671688,
            0.005487190559506416,
            -0.032155610620975494,
            -0.020309550687670708,
            -0.021316662430763245,
            -0.012408985756337643,
            0.013165556825697422,
            0.007156922947615385,
            0.03565824404358864,
            0.029884062707424164,
            0.01014742162078619,
            -0.028760148212313652,
            -0.001586039667017758,
            0.021307244896888733,
            0.029967986047267914,
            0.031018458306789398,
            0.028604041785001755,
            0.0018472577212378383,
            -0.027089037001132965,
            -0.02407970279455185,
            0.014736340381205082,
            -0.016821296885609627,
            0.019275160506367683,
            -0.019301483407616615,
            0.0002198887086706236,
            -0.03119160421192646,
            0.018824975937604904,
            -0.020067403092980385,
            0.016375968232750893,
            -0.0195835679769516,
            -0.034686099737882614,
            -0.01708618737757206,
            0.004968584515154362,
            0.020387914031744003,
            -0.0056975227780640125,
            0.005253763869404793,
            -0.022728504613041878,
            0.0024431427009403706,
            -0.011018368415534496,
            -0.028727347031235695,
            0.0033821272663772106,
            -0.003253436181694269,
            0.014742308296263218,
            0.000319095968734473,
            0.01873219944536686,
            0.019134078174829483,
            0.010026712901890278,
            0.030639907345175743,
            0.0015895664691925049,
            0.03510245308279991,
            0.012103295885026455,
            -0.026636704802513123,
            0.012164164334535599,
            0.022168269380927086,
            0.024271711707115173,
            0.02993132919073105,
            -0.004687359090894461,
            -0.01444457471370697,
            -0.03277790546417236,
            0.03195154294371605,
            -0.005326291546225548,
            -0.013872942887246609,
            0.02409506030380726,
            -0.019279146566987038,
            -0.022464970126748085,
            0.0041505140252411366,
            -0.004704613704234362,
            -0.015932360664010048,
            -0.005687918048352003,
            0.054147861897945404,
            0.011540019884705544,
            0.019187824800610542,
            0.0027046017348766327,
            0.03557414561510086,
            0.0200650654733181,
            -0.026691175997257233,
            -0.006037004757672548,
            -0.00816846452653408,
            0.023937439545989037,
            -0.021177075803279877,
            0.029843304306268692,
            -0.018434656783938408,
            0.017997514456510544,
            -0.012867662124335766,
            -0.0350579097867012,
            0.00807861890643835,
            -0.0032554264180362225,
            -0.009080682881176472,
            -0.026372337713837624,
            0.024308130145072937,
            -0.004531343467533588,
            -0.0208770539611578,
            -0.014676697552204132,
            0.01809294894337654,
            0.025710873305797577,
            -0.0024914226960390806,
            -0.01734434999525547,
            -0.018468210473656654,
            -0.006378968711942434,
            0.000508260156493634,
            -0.001177096040919423,
            0.023284032940864563,
            0.013516183942556381,
            -0.013176627457141876,
            -0.02632232941687107,
            0.007747967261821032,
            -0.02390550822019577,
            0.03570521995425224,
            -0.01129975076764822,
            -0.006356470752507448,
            -0.02229555882513523,
            0.017254620790481567,
            0.016574643552303314,
            -0.002283289097249508,
            -0.004706671927124262,
            -0.03748731315135956,
            0.027680203318595886,
            0.007434206549078226,
            0.007894709706306458,
            0.009472873993217945,
            0.0012973527191206813,
            0.005388136487454176,
            0.007529927883297205,
            0.003003327175974846,
            0.019774625077843666,
            0.023077482357621193,
            0.015285867266356945,
            -0.014029199257493019,
            -0.03370876982808113,
            -0.03361453115940094,
            -0.017570164054632187,
            -0.009842061437666416,
            0.008329497650265694,
            -0.004429535940289497,
            0.023699011653661728,
            -0.03478208929300308,
            -0.02258940227329731,
            0.03324338048696518,
            0.004668478854000568,
            -0.03716970980167389,
            -0.0037746019661426544,
            -0.005032534711062908,
            -0.015526565723121166,
            0.007882216945290565,
            -0.00018512447422835976,
            -0.04440145567059517,
            0.020935237407684326,
            -0.014444776810705662,
            0.024565834552049637,
            0.025012951344251633,
            -0.010827199555933475,
            0.011671855114400387,
            0.027483295649290085,
            -0.0007243767031468451,
            0.03263218328356743,
            0.031192800030112267,
            -0.026235327124595642,
            0.02345436066389084,
            0.02384854666888714,
            -0.020692383870482445,
            0.02232491411268711,
            -0.01869064010679722,
            -0.009644703008234501,
            -0.031960442662239075,
            0.008625833317637444,
            -0.016390569508075714,
            -0.013693371787667274,
            0.03076004795730114,
            -0.00946520920842886,
            -0.018787750974297523,
            -0.003185225185006857,
            0.00341217708773911,
            0.011852110736072063,
            -0.006120364181697369,
            -0.016542837023735046,
            7.217469101306051e-05,
            -0.008261497132480145,
            -0.02491905726492405,
            0.02335510402917862,
            0.04284068942070007,
            0.018609868362545967,
            -0.013269565999507904,
            0.0032087292056530714,
            -0.032235026359558105,
            0.0167172159999609,
            -0.010792700573801994,
            -0.037074014544487,
            0.008070886135101318,
            -0.0026629522908478975,
            0.011547895148396492,
            -0.012362451292574406,
            0.025469794869422913,
            0.020648524165153503,
            0.009095225483179092,
            0.015212698839604855,
            0.0168299600481987,
            -0.008849195204675198,
            0.013608906418085098,
            -0.01707558147609234,
            -0.020691027864813805,
            0.016680987551808357,
            0.016062838956713676,
            -0.0015454702079296112,
            -0.022825319319963455,
            -0.02394392341375351,
            -0.020627226680517197,
            0.027137141674757004,
            -0.01978357322514057,
            0.03394591435790062,
            0.0011296119773760438,
            -0.005657557398080826,
            0.0021844657603651285,
            -0.03352765366435051,
            -0.0256312508136034,
            -0.04506239295005798,
            -0.0175166055560112,
            -0.05274535343050957,
            0.036757804453372955,
            0.015133794397115707,
            0.013454239815473557,
            0.014497692696750164,
            0.021658096462488174,
            -0.01841619610786438,
            -0.02121608331799507,
            0.009469623677432537,
            0.004451056011021137,
            0.028093017637729645,
            0.020763739943504333,
            0.01520959846675396,
            -0.034798115491867065,
            -0.0060176546685397625,
            -0.012121821753680706,
            -0.01808677613735199,
            0.014727071858942509,
            0.011739867739379406,
            -0.036743585020303726,
            0.02884998545050621,
            0.0006127261440269649,
            -0.013020823709666729,
            -0.017433492466807365,
            0.02698664739727974,
            -0.0291434358805418,
            -0.008363848552107811,
            0.023952921852469444,
            0.016295690089464188,
            0.0001695006067166105,
            0.02414502389729023,
            -0.008753851987421513,
            -0.0209510438144207,
            0.006989696994423866,
            -0.002842356450855732,
            0.004830130375921726,
            -0.012755751609802246,
            0.0056063164956867695,
            0.00731717050075531,
            -0.0013374786358326674,
            0.027550922706723213,
            0.032908473163843155,
            -0.027902035042643547,
            0.028797456994652748,
            0.03172926977276802,
            -0.02440677024424076,
            0.007085354067385197,
            -0.01976500265300274,
            -0.03991188108921051,
            0.012394066900014877,
            -0.05772455409169197,
            0.017815591767430305,
            -0.025101549923419952,
            -0.0074343448504805565,
            0.017197443172335625,
            0.02387930452823639,
            -0.004005719441920519,
            -0.017940575256943703,
            0.011412440799176693,
            0.005299357231706381,
            0.0032466512639075518,
            -0.0378975048661232,
            0.007119584362953901,
            -0.04782416298985481,
            0.024965696036815643,
            0.00046510438551194966,
            -0.024371128529310226,
            -0.012548867613077164,
            0.01170805748552084,
            -0.009922820143401623,
            0.009181814268231392,
            -0.008904930204153061,
            0.012148982845246792,
            -0.023883875459432602,
            -0.005935030989348888,
            -0.026688745245337486,
            0.0075862836092710495,
            -0.01872158795595169,
            0.04748808592557907,
            -0.03332439810037613,
            -0.012146052904427052,
            0.03509397804737091,
            -0.03536008670926094,
            -0.006617576349526644,
            -0.015845904126763344,
            0.009078810922801495,
            -0.00330906780436635,
            0.040513891726732254,
            -0.03770999610424042,
            -0.03579533472657204,
            -0.028316648676991463,
            0.007294589187949896,
            -0.03984013944864273,
            -0.03132389485836029,
            0.004429630935192108,
            -0.052868496626615524,
            0.0072631617076694965,
            0.012298611924052238,
            -0.005530011374503374,
            -0.009541257284581661,
            0.02203269861638546,
            -0.0029969310853630304,
            -0.04403693228960037,
            0.010357186198234558,
            0.015640685334801674,
            -0.02294505015015602,
            0.01529789436608553,
            0.011215770617127419,
            0.03206950053572655,
            0.0009361888514831662,
            0.02069242112338543,
            0.002461925847455859,
            -0.0033725539688020945,
            -0.021340815350413322,
            -0.02283770963549614,
            0.006384262815117836,
            0.020289592444896698,
            -0.027481190860271454,
            0.005570647306740284,
            0.021432362496852875,
            -0.011716586537659168,
            -0.0011223088949918747,
            -0.03640871122479439,
            0.015681549906730652,
            0.01234766561537981,
            0.009380379691720009,
            0.027048926800489426,
            0.010167526081204414,
            -0.02615438401699066,
            0.02501547709107399,
            0.04291164129972458,
            -0.008195793256163597,
            -0.02288948930799961,
            0.023886892944574356,
            -0.01657165214419365,
            0.015613166615366936,
            -0.01450358610600233,
            -0.021403295919299126,
            -0.0016169464215636253,
            0.018010063096880913,
            -0.014174184761941433,
            -0.030196672305464745,
            -0.02471238374710083,
            -0.016826506704092026,
            0.028333568945527077,
            0.02192107029259205,
            0.021485736593604088,
            0.0023337663151323795,
            -0.019599823281168938,
            -0.02033657766878605,
            0.0409524030983448,
            0.01067762915045023,
            -0.012274547480046749,
            -0.014267620630562305,
            -0.047905586659908295,
            0.025340259075164795,
            -0.02984723448753357,
            0.004706194624304771,
            -0.006012359634041786,
            -0.02276385948061943,
            -0.012048119679093361,
            -0.032783955335617065,
            0.006469376850873232,
            -0.004207069054245949,
            -0.008961793966591358,
            -0.002726394683122635,
            0.030800586566329002,
            0.011262496002018452,
            -0.03098674863576889,
            0.016106992959976196,
            -0.006625552196055651,
            -0.025068072602152824,
            -0.00654731597751379,
            -0.03703439235687256,
            -0.02519569918513298,
            0.022596189752221107,
            0.029139848425984383,
            0.002130629029124975,
            -0.0010217181406915188,
            0.006135001312941313,
            -0.004085450433194637,
            0.014589326456189156,
            -0.025035398080945015,
            0.03512369841337204,
            0.009608644060790539,
            0.023412000387907028,
            0.025778386741876602,
            0.028532840311527252,
            -0.025890877470374107,
            0.02662867121398449,
            -0.003076909575611353,
            -0.03629124537110329,
            -0.014292417094111443,
            0.013309873640537262,
            -0.02826268970966339,
            -0.004913415759801865,
            -0.0019859299063682556,
            -0.009411492384970188,
            -0.008573722094297409,
            0.028642263263463974,
            0.014362214133143425,
            0.006647680886089802,
            0.0038552519399672747,
            -0.00992774497717619,
            0.01982557214796543,
            -0.008975939825177193,
            -0.02541334182024002,
            0.01975598931312561,
            0.016190722584724426,
            -0.0028755273669958115,
            -0.004642226733267307,
            -0.020023781806230545,
            -0.003308199578896165,
            -0.03165130317211151,
            0.021768299862742424,
            -0.018551673740148544,
            0.029936835169792175,
            0.03967781364917755,
            0.04361258074641228,
            0.04526058956980705,
            -0.014257451519370079,
            0.03388906270265579,
            0.019773399457335472,
            -0.02040211297571659,
            0.010932949371635914,
            -0.0298237893730402,
            0.005795294418931007,
            -0.0024466959293931723,
            -0.02443760260939598,
            0.01890181005001068,
            0.0076999724842607975,
            0.0033074559178203344,
            -0.011851644143462181,
            -0.02948850393295288,
            0.010095803067088127,
            -0.016633151099085808,
            0.015772156417369843,
            -0.02733740769326687,
            0.006861280184239149,
            -0.024723052978515625,
            0.007241019047796726,
            -0.0087685352191329,
            -0.008559862151741982,
            0.002548251999542117,
            -0.0017480110982432961,
            -0.03048848547041416,
            -0.017657754942774773,
            -0.013549902476370335,
            -0.007897228933870792,
            -0.02255917526781559,
            0.003697957843542099,
            -0.021202227100729942,
            0.024791300296783447,
            0.0095551498234272,
            0.005944292992353439,
            0.02891286090016365,
            -0.005951277911663055,
            -0.0011517746606841683,
            0.017347052693367004,
            -0.005002794321626425,
            -0.02852259762585163,
            0.038089826703071594,
            -0.032988350838422775,
            0.02528940513730049,
            -0.050376784056425095,
            -0.01608256623148918,
            -0.02270047552883625,
            0.01568342000246048,
            0.003290197579190135,
            0.008963708765804768,
            0.0003358147223480046,
            -0.006515365093946457,
            0.040460433810949326,
            -0.00748347956687212,
            -0.026689788326621056,
            0.013873378746211529,
            -0.002000470645725727,
            0.03487096354365349,
            -0.024244042113423347,
            0.00748297618702054,
            0.028318976983428,
            -0.022056518122553825,
            -0.0128921028226614,
            0.02806541882455349,
            0.023480767384171486,
            0.02360881119966507,
            0.040867991745471954,
            0.02231789380311966,
            -0.02864016219973564,
            -0.03263343125581741,
            -0.020740360021591187,
            -0.010585527867078781,
            0.03460728004574776,
            0.025670530274510384,
            0.03889056295156479,
            0.01927441731095314,
            -0.027888502925634384,
            -2.8921869670739397e-05,
            -0.04014383256435394,
            0.01125437580049038,
            0.0035528033040463924,
            -0.04640193656086922,
            0.0014090734766796231,
            -0.004205375909805298,
            -0.012923295609652996,
            -0.005152144934982061,
            0.03526429831981659,
            -0.009119692258536816,
            -0.05065128207206726,
            -0.028186844661831856,
            -0.003562633413821459,
            -0.01502892468124628,
            -0.0040308646857738495,
            -0.02230120822787285,
            0.010463596321642399,
            0.02829066477715969,
            0.015570133924484253,
            0.016402216628193855,
            -0.0410778746008873,
            0.007494280114769936,
            0.039539583027362823,
            0.02636072225868702,
            -4.015925514977425e-05,
            0.024215999990701675,
            0.03449857607483864,
            0.01775500364601612,
            -0.0074763912707567215,
            0.013953453861176968,
            -0.0015568539965897799,
            -0.024631734937429428,
            0.02570457197725773,
            0.020386936143040657,
            -0.01747901737689972,
            -0.009485932067036629,
            -0.017791112884879112,
            -0.012280842289328575,
            -0.005649312399327755,
            0.019707603380084038,
            -0.028129922226071358,
            0.032166942954063416,
            -0.011678040958940983,
            -0.024550486356019974,
            -0.017043033614754677,
            0.014192280359566212,
            -0.05642277002334595,
            -0.01013419684022665,
            0.003064602380618453,
            0.014789031818509102,
            0.007893388159573078,
            0.028423607349395752,
            -0.01768517680466175,
            -0.012633255682885647,
            -0.0012810654006898403,
            -0.005149677861481905,
            0.013147047720849514,
            -0.02228105440735817,
            -0.0011327945394441485,
            0.00011313345021335408,
            -0.0049252575263381,
            0.031783558428287506,
            0.031164418905973434,
            0.017648642882704735,
            -0.031247999519109726,
            -0.009428988210856915,
            -0.02637270651757717,
            -0.027063630521297455,
            -0.04939524084329605,
            0.027551287785172462,
            0.005604014731943607,
            -0.01508639007806778,
            -0.0005163501482456923,
            -0.026167428120970726,
            -0.017114151269197464,
            0.007677288725972176,
            0.010517572052776814,
            0.010066760703921318,
            0.038777004927396774,
            0.027901776134967804,
            -0.007649844046682119,
            -0.0025441322941333055,
            -0.011562560684978962,
            -0.0534801259636879,
            -0.01035870797932148,
            0.024627724662423134,
            -0.020990047603845596,
            -0.025240305811166763,
            -0.022221820428967476,
            0.011316025629639626,
            0.016231732442975044,
            -0.017518416047096252,
            0.03363167494535446,
            0.026455845683813095,
            0.01490783877670765,
            -0.027218874543905258,
            -1.8469971109880134e-05,
            -0.010624239221215248,
            -0.019183741882443428,
            0.03190886601805687,
            -0.02843587100505829,
            -0.006477791350334883,
            -0.03911816328763962,
            0.021307669579982758,
            -0.02011231705546379,
            -0.02197052165865898,
            -0.06408005207777023,
            -0.04551676660776138,
            0.020964939147233963,
            -0.009306101128458977,
            0.022813372313976288,
            0.018149498850107193,
            0.0031332867220044136,
            -0.005333996377885342,
            0.013102677650749683,
            -0.0016926220851019025,
            0.013370102271437645,
            0.02359365113079548,
            0.02793744020164013,
            -0.005200463347136974,
            0.005212745163589716,
            0.01400819607079029,
            -0.01718137599527836,
            0.026638410985469818,
            -0.020746206864714622,
            0.0014150894712656736,
            -0.020173463970422745,
            0.02038157358765602,
            -0.02019011601805687,
            -0.0005731298006139696,
            -0.0074956114403903484,
            0.02246205322444439,
            -0.019659806042909622,
            -0.0715997964143753,
            -0.020878827199339867,
            0.03503525257110596,
            -0.022237339988350868,
            -0.011106275953352451,
            -0.005596174858510494,
            -0.0016836895374581218,
            -0.03195647895336151,
            0.02834930829703808,
            0.029006697237491608,
            -0.013835901394486427,
            -0.015976496040821075,
            0.03647124022245407,
            0.02432384341955185,
            0.029108623042702675,
            -0.002777296584099531,
            0.04717416688799858,
            -0.043129246681928635,
            -0.003807940986007452,
            0.033037859946489334,
            0.0037353034131228924,
            -0.018369261175394058,
            0.011898172087967396,
            0.013588637113571167,
            -0.002753384644165635,
            -0.0038144257850944996,
            -0.02559501677751541,
            0.03168841823935509,
            0.014590736478567123,
            0.03186052292585373,
            -0.0070259952917695045,
            0.053659699857234955,
            -0.037902653217315674,
            0.017468078061938286,
            -0.01252078078687191,
            -0.016428444534540176,
            0.028091691434383392,
            0.005488921422511339,
            -0.019543670117855072,
            -0.02024867944419384,
            0.01966043747961521,
            -0.00832659937441349,
            -0.010503883473575115,
            0.003974493592977524,
            0.010836786590516567,
            0.01993078738451004,
            -0.001197125413455069,
            -0.012098025530576706,
            -0.015120754949748516,
            0.004962464328855276,
            0.018445029854774475,
            -0.021342318505048752,
            -0.013857296667993069,
            0.00626685656607151,
            0.004712386522442102,
            -0.031944431364536285,
            0.019841326400637627,
            -0.012675940059125423,
            -0.016494661569595337,
            -0.022228993475437164,
            0.009132541716098785,
            -0.040169164538383484,
            0.024478131905198097,
            -0.003396635642275214,
            0.013025684282183647,
            -0.003640949260443449,
            -0.0047410232946276665,
            0.01516752876341343,
            -0.02173907868564129,
            -0.07845301181077957,
            -0.024005327373743057,
            0.047547586262226105,
            0.0443350188434124,
            -0.022814365103840828,
            -0.029707301408052444,
            -0.002645420143380761,
            -0.02085348777472973,
            -0.0030270437709987164,
            -0.018449528142809868,
            -0.008504442870616913,
            0.004982590209692717,
            -0.011389875784516335,
            0.01700235903263092,
            0.006483813747763634,
            -0.04349491372704506,
            0.028759045526385307,
            0.03221842274069786,
            0.015350496396422386,
            0.013765758834779263,
            0.028680188581347466,
            -0.013911630026996136,
            -0.04304146766662598,
            0.022572405636310577,
            0.009010553359985352,
            -0.01887432672083378,
            -0.011957558803260326,
            -0.0010866525117307901,
            -0.0033854374196380377,
            0.01643812470138073,
            0.018857263028621674,
            -0.060712628066539764,
            0.04006098583340645,
            0.004658312536776066,
            -0.007850700058043003,
            0.009087124839425087,
            -0.009964725002646446,
            0.002117031952366233,
            -0.03985932096838951,
            0.016505608335137367,
            0.015348837710916996,
            0.04873928427696228,
            0.022112760692834854,
            0.0030194201972335577,
            -0.02748558670282364,
            -0.018486851826310158,
            0.0027172286063432693,
            0.008687540888786316,
            -0.0061678350903093815,
            -0.012929599732160568,
            -0.009072968736290932,
            -0.013791889883577824,
            -0.044916268438100815,
            -0.022341269999742508,
            -0.016289493069052696,
            -4.084370084456168e-05,
            0.03622440621256828,
            0.03118693269789219,
            -0.018988292664289474,
            0.027255255728960037,
            -0.022941939532756805,
            -0.00882870052009821,
            0.005912515800446272,
            -0.004125006962567568,
            0.002314571524038911,
            -0.006936156656593084,
            -0.0030078114941716194,
            -0.011117193847894669,
            0.028905192390084267,
            -0.01421875599771738,
            -0.025979572907090187,
            0.005172962788492441,
            -0.005567427724599838,
            0.023033631965517998,
            -0.01925135962665081,
            -0.028993183746933937,
            0.018375501036643982,
            0.026109617203474045,
            -0.013481445610523224,
            0.017268668860197067,
            0.015045630745589733,
            -0.0004940073704347014,
            -0.05764107406139374,
            -0.0013910616980865598,
            -0.021748777478933334,
            -0.02358206920325756,
            -0.050917431712150574,
            0.017611920833587646,
            -0.020958052948117256,
            -0.022188322618603706,
            -0.05852627381682396,
            0.007572273723781109,
            -0.023469651117920876,
            7.434975850628689e-05,
            -0.004893055651336908,
            0.02525794878602028,
            -0.009895884431898594,
            0.03902316465973854,
            -0.006047508213669062,
            -0.013736383058130741,
            0.01509969960898161,
            0.018109336495399475,
            0.009201228618621826,
            0.025794945657253265,
            0.03474780172109604,
            -0.012079492211341858,
            0.03733396530151367,
            0.024170761927962303,
            -0.04561357572674751,
            0.01406148076057434,
            -0.05487257242202759,
            0.001788324094377458,
            0.01979154162108898,
            0.03624587133526802,
            0.03783280774950981,
            -0.028742482885718346,
            -0.01686166413128376,
            -0.000528765085618943,
            0.0028380458243191242,
            0.008125551976263523,
            -0.0007112284656614065,
            -0.02299576625227928,
            -0.027094118297100067,
            0.0019560239743441343,
            0.03937278687953949,
            -0.00891947653144598,
            -0.02742106467485428,
            -0.015183779411017895,
            0.005700036883354187,
            -0.01115821860730648,
            0.006677936762571335,
            0.017368126660585403,
            -0.0016514186281710863,
            0.015887800604104996,
            -0.001025900593958795,
            -0.027728034183382988,
            -0.017159629613161087,
            -0.007853593677282333,
            0.0233236663043499,
            0.028572216629981995,
            0.017993606626987457,
            -0.00852932222187519,
            0.003455385798588395,
            0.016683919355273247,
            0.02360253967344761,
            0.017299646511673927,
            -0.03505409136414528,
            0.03208144009113312,
            -0.037986308336257935,
            -0.007304764352738857,
            -0.033926237374544144,
            -0.01900443062186241
        ],
        [
            0.012405984103679657,
            0.029679469764232635,
            -0.020731190219521523,
            -0.040199000388383865,
            0.010983366519212723,
            -0.04428602010011673,
            0.026292851194739342,
            -0.026091882959008217,
            -0.014233902096748352,
            -0.025814903900027275,
            0.018438903614878654,
            0.027518559247255325,
            -0.018630383536219597,
            -0.03283475711941719,
            -0.01802949793636799,
            0.034964557737112045,
            0.01357700489461422,
            -0.011474167928099632,
            -0.040366947650909424,
            -0.01933557540178299,
            -0.02797454595565796,
            0.02586868777871132,
            -0.05693821609020233,
            0.013620244339108467,
            0.01811598800122738,
            0.03102925792336464,
            -0.046973321586847305,
            0.012224491685628891,
            0.024725355207920074,
            0.026307281106710434,
            0.00796749908477068,
            -0.017143284901976585,
            -0.008982773870229721,
            0.007748784031718969,
            -0.025810955092310905,
            0.021977193653583527,
            -0.03499415144324303,
            -0.004318666644394398,
            0.0348980613052845,
            -0.015085156075656414,
            0.024780847132205963,
            -0.0032559100072830915,
            -0.007891962304711342,
            0.004150065127760172,
            0.000626899185590446,
            -0.03206124156713486,
            -0.02041710540652275,
            -0.009008655324578285,
            -0.008715573698282242,
            0.006080520339310169,
            -0.00909600593149662,
            0.009977199137210846,
            0.009859489277005196,
            -0.016710184514522552,
            -0.01917818933725357,
            0.028312847018241882,
            0.012563812546432018,
            0.011415764689445496,
            -0.002067373599857092,
            -0.004647658206522465,
            0.025983210653066635,
            0.008429697714745998,
            -0.008670669980347157,
            0.024985792115330696,
            -0.01439399179071188,
            -0.0157790444791317,
            0.038132403045892715,
            -0.012349767610430717,
            0.011456177569925785,
            -0.002092822687700391,
            0.020669108256697655,
            0.025628864765167236,
            -0.017370685935020447,
            0.02836477942764759,
            0.028787968680262566,
            0.05493326112627983,
            0.010514769703149796,
            0.0038245231844484806,
            -0.03208880126476288,
            0.004514655098319054,
            -0.017868639901280403,
            -0.020632252097129822,
            0.021845722571015358,
            0.024592775851488113,
            -0.024485426023602486,
            -0.003034074092283845,
            -0.00891224667429924,
            0.01616588607430458,
            0.0075139496475458145,
            -0.06086456775665283,
            -0.00976725947111845,
            -0.016556764021515846,
            0.0013644096907228231,
            0.012175336480140686,
            -0.013963529840111732,
            -0.0033916179090738297,
            0.0011803451925516129,
            0.017014438286423683,
            -0.012260756455361843,
            0.022990528494119644,
            -0.0187582578510046,
            -0.040843065828084946,
            0.010475576855242252,
            0.009403578005731106,
            0.0018584438366815448,
            -0.03282511234283447,
            -0.013656023889780045,
            0.014035198837518692,
            0.029091496020555496,
            0.019320525228977203,
            -0.011387037113308907,
            -0.013777296058833599,
            0.009703106246888638,
            -0.01883845031261444,
            0.013946841470897198,
            0.0028125825338065624,
            0.015050877816975117,
            -0.054247044026851654,
            0.029716864228248596,
            -0.021345917135477066,
            0.014395557343959808,
            -0.003328698454424739,
            -0.030058130621910095,
            0.022239459678530693,
            -0.0013047619722783566,
            0.039653223007917404,
            -0.010652358643710613,
            0.0010162311373278499,
            0.02316674031317234,
            -0.00842790026217699,
            0.02122998796403408,
            0.011365109123289585,
            -0.018084192648530006,
            -0.02741103060543537,
            -0.0013716238318011165,
            -0.021063311025500298,
            -0.01894732564687729,
            0.006261131726205349,
            0.005576799623668194,
            -0.020749827846884727,
            0.030784543603658676,
            0.017057498916983604,
            -0.03838862478733063,
            0.028110401704907417,
            0.013534275814890862,
            -0.018154293298721313,
            -0.024695847183465958,
            0.007232035510241985,
            -0.010323895141482353,
            0.00999105628579855,
            -0.026249652728438377,
            0.03593642637133598,
            0.020209232345223427,
            -0.025184985250234604,
            0.012616022489964962,
            0.024648742750287056,
            0.01814320683479309,
            0.04845419526100159,
            -0.004709219094365835,
            0.04368815943598747,
            0.02236029878258705,
            -0.0271670650690794,
            0.028340376913547516,
            0.010994449257850647,
            -0.029291795566678047,
            -0.019877394661307335,
            0.015717796981334686,
            -0.01376311480998993,
            0.0135759636759758,
            -0.0042394306510686874,
            0.012455659918487072,
            -0.02658122591674328,
            0.004661594983190298,
            -0.015960145741701126,
            0.008703023195266724,
            -0.002746672835201025,
            -0.02543863281607628,
            0.004449815489351749,
            -0.00783021841198206,
            0.007930010557174683,
            0.027911551296710968,
            -0.003647453151643276,
            0.00764742586761713,
            0.01823444478213787,
            0.003364135744050145,
            0.02672269195318222,
            0.004024701192975044,
            -0.02976004034280777,
            -0.007840443402528763,
            0.008190293796360493,
            -0.035735081881284714,
            -0.016490386798977852,
            6.310272874543443e-05,
            -0.006072032265365124,
            0.01681392826139927,
            -0.006632460746914148,
            0.03286530077457428,
            0.0064843990840017796,
            0.023454725742340088,
            -0.024444885551929474,
            0.010170189663767815,
            -0.0042948294430971146,
            0.0481799840927124,
            0.03201533481478691,
            -0.013149806298315525,
            0.025349363684654236,
            -0.03781157732009888,
            -0.024915581569075584,
            -0.00893484428524971,
            -0.013968258164823055,
            -0.014249484054744244,
            -0.01635691151022911,
            0.016468774527311325,
            -0.0011732474667951465,
            0.003925814758986235,
            0.01595662161707878,
            -0.02382824383676052,
            0.03693004325032234,
            -0.019827937707304955,
            -0.01940053142607212,
            -0.031833622604608536,
            0.03343668580055237,
            0.0424455851316452,
            -0.0011206537019461393,
            0.009356072172522545,
            -0.06339617818593979,
            0.0300050787627697,
            0.0038333721458911896,
            0.020010126754641533,
            0.023158911615610123,
            -0.004461073782294989,
            -0.025991639122366905,
            -0.025930263102054596,
            0.006516642402857542,
            -0.02850363589823246,
            -0.014639918692409992,
            0.014765803702175617,
            0.03550995513796806,
            -0.0005617745919153094,
            0.030599741265177727,
            0.03460361436009407,
            0.020699171349406242,
            0.018726814538240433,
            0.029062746092677116,
            -0.017118239775300026,
            -0.02578110247850418,
            0.01700182817876339,
            0.0044090356677770615,
            0.00835513323545456,
            0.025982286781072617,
            0.023652788251638412,
            -0.02027103118598461,
            -0.045442745089530945,
            0.01956864632666111,
            -0.0007378868758678436,
            -0.0015005395980551839,
            0.02786094881594181,
            0.01803961955010891,
            -0.01855302043259144,
            0.042644698172807693,
            -0.04495462030172348,
            -0.040089529007673264,
            0.01712561957538128,
            0.01081036776304245,
            -0.03711351752281189,
            -0.01724214106798172,
            0.026306893676519394,
            0.01450349111109972,
            -0.023855308070778847,
            0.02285766415297985,
            -0.0051788827404379845,
            0.0017048238078132272,
            -0.00398120516911149,
            -0.012132576666772366,
            0.0041648647747933865,
            0.001641567563638091,
            0.01933305524289608,
            -0.021096324548125267,
            -0.0022664868738502264,
            0.0006180439377203584,
            0.03778413310647011,
            -0.005127568729221821,
            0.023668428882956505,
            -0.022676872089505196,
            0.0030027818866074085,
            0.01886405609548092,
            0.01325279101729393,
            -0.017191851511597633,
            0.03220034018158913,
            0.019086038693785667,
            0.0016657704254612327,
            0.01876300573348999,
            -0.02302977815270424,
            0.0025222781114280224,
            0.017641909420490265,
            -0.014906177297234535,
            -0.009439672343432903,
            -0.013319006189703941,
            0.02798476815223694,
            0.0036036348901689053,
            -0.02004651352763176,
            0.029123686254024506,
            -0.029246976599097252,
            -0.03035730868577957,
            0.01903347671031952,
            0.02928036078810692,
            0.03082047402858734,
            -0.0011142537696287036,
            -0.015289046801626682,
            -0.02914152853190899,
            0.001920545706525445,
            -0.012064885348081589,
            -0.0192243829369545,
            -0.015800319612026215,
            -0.007876560091972351,
            -0.03936805948615074,
            -0.013353020884096622,
            -0.0038794921711087227,
            0.003839818760752678,
            -0.027591628953814507,
            0.00019069410336669534,
            0.010829858481884003,
            0.007266213186085224,
            -0.06632855534553528,
            0.027555080130696297,
            -0.025298546999692917,
            -0.0027022233698517084,
            -0.01508284267038107,
            -0.005680257920175791,
            0.024716028943657875,
            -0.0011911223409697413,
            -0.017557071521878242,
            0.001519171055406332,
            0.03129377216100693,
            -0.0013549901777878404,
            0.03282390534877777,
            0.03654129430651665,
            -0.00013558128557633609,
            0.008441882207989693,
            0.02871028147637844,
            0.022218696773052216,
            0.020859697833657265,
            0.0023976750671863556,
            -0.019337588921189308,
            0.01536182314157486,
            0.013845345005393028,
            -0.0319189578294754,
            -0.016718076542019844,
            -0.025134725496172905,
            -0.0044301943853497505,
            -0.023880600929260254,
            0.018328383564949036,
            -0.018158478662371635,
            -0.016330469399690628,
            0.015548435971140862,
            0.00869782641530037,
            0.004375800956040621,
            0.012308736331760883,
            -0.01446577813476324,
            -0.02421177364885807,
            0.010776677168905735,
            -0.0315217599272728,
            0.020680289715528488,
            -0.00298882438801229,
            0.040506236255168915,
            -0.01170350145548582,
            0.05003172904253006,
            0.035330578684806824,
            -0.016472429037094116,
            -0.0024331286549568176,
            0.01624438725411892,
            -0.01788092777132988,
            -0.029180889949202538,
            0.02042730711400509,
            -0.015142428688704967,
            -0.0013209906173869967,
            0.011984184384346008,
            -0.004746238701045513,
            -0.019635682925581932,
            0.03519401699304581,
            0.014865739271044731,
            0.008845391683280468,
            -0.027436768636107445,
            0.018284080550074577,
            -0.04085614159703255,
            -0.017192233353853226,
            0.009081166237592697,
            -0.03964605927467346,
            0.030326509848237038,
            0.009830726310610771,
            -0.022997109219431877,
            -0.012668249197304249,
            0.00288940267637372,
            -0.0036254210863262415,
            -0.029908347874879837,
            0.037407275289297104,
            -0.0011283028870821,
            0.003342284355312586,
            -0.02411074936389923,
            0.010635455138981342,
            0.010799717158079147,
            -0.013617090880870819,
            -0.022397706285119057,
            0.005320739466696978,
            0.00032983740675263107,
            0.04002991318702698,
            -0.03059072047472,
            0.011322980746626854,
            -0.026009760797023773,
            0.011270788498222828,
            -0.029827788472175598,
            -0.03740248084068298,
            -0.026589643210172653,
            -0.0027949262876063585,
            -0.00485205790027976,
            0.01920926198363304,
            -0.013777676038444042,
            0.033004749566316605,
            0.014722847379744053,
            -0.02892233431339264,
            0.012084770947694778,
            0.020624347031116486,
            -0.012281933799386024,
            0.02121765911579132,
            -0.02593228593468666,
            -0.05937882140278816,
            -0.024914545938372612,
            0.022125639021396637,
            0.003980228211730719,
            0.012537742033600807,
            0.030867185443639755,
            0.026779385283589363,
            0.0021327966824173927,
            0.006551156286150217,
            -0.01721389777958393,
            -0.036396872252225876,
            -0.012134387157857418,
            -0.013940131291747093,
            0.004819443449378014,
            0.015041874721646309,
            0.01382983848452568,
            -0.02450941689312458,
            0.008596538566052914,
            -0.00852442067116499,
            -0.018735885620117188,
            0.0040428414940834045,
            0.01227596215903759,
            -0.0013494304148480296,
            0.006247388198971748,
            -0.027313828468322754,
            -0.024268094450235367,
            -0.013332938775420189,
            -0.031178828328847885,
            0.008012862876057625,
            0.007816198281943798,
            -0.015921566635370255,
            -0.03387133404612541,
            -0.026334106922149658,
            -0.02896829880774021,
            -0.015230500139296055,
            -0.010001030750572681,
            -0.008376812562346458,
            0.012822872027754784,
            0.003579295240342617,
            -0.018660729750990868,
            -0.0028295062948018312,
            -0.026489391922950745,
            -0.034072499722242355,
            -0.013525974936783314,
            0.029079250991344452,
            0.013377528637647629,
            0.01670452021062374,
            -0.022309884428977966,
            0.006471556611359119,
            0.03773186355829239,
            0.0413772314786911,
            -0.008488488383591175,
            0.023902900516986847,
            -0.02421509474515915,
            -0.005271892994642258,
            0.046384625136852264,
            -0.0262739434838295,
            0.007360097486525774,
            0.019379546865820885,
            -0.04276387766003609,
            0.011288749054074287,
            0.002694879425689578,
            9.437389962840825e-05,
            -0.09656012058258057,
            0.011013246141374111,
            0.013822225853800774,
            0.027426118031144142,
            -0.01852159947156906,
            0.04090742766857147,
            0.01493996661156416,
            -0.040142595767974854,
            0.01782887615263462,
            -0.017205072566866875,
            0.012097422033548355,
            0.016513235867023468,
            -0.016550112515687943,
            -0.0008554108790121973,
            0.03550063073635101,
            -0.0075102574191987514,
            0.006515843328088522,
            -0.008137593977153301,
            0.026387905701994896,
            0.020356418564915657,
            -0.02566353976726532,
            0.002140161581337452,
            -0.006827403325587511,
            -0.050563324242830276,
            0.022177040576934814,
            0.004245455376803875,
            -0.010987305082380772,
            -0.02433113008737564,
            0.030635930597782135,
            0.023427080363035202,
            0.024508008733391762,
            0.011145847849547863,
            -0.0232697743922472,
            -0.0025980821810662746,
            0.024006707593798637,
            0.021227063611149788,
            0.01360382977873087,
            -0.019520672038197517,
            -0.04974031448364258,
            0.014103390276432037,
            -0.02334316074848175,
            0.03498229756951332,
            0.02316473424434662,
            -0.012328775599598885,
            -0.05165791139006615,
            -0.012982627376914024,
            0.02452825754880905,
            0.0008919136598706245,
            -0.01183108240365982,
            -0.02183525823056698,
            0.0036841840483248234,
            0.020010150969028473,
            0.024055115878582,
            0.014358492568135262,
            -0.007372262887656689,
            -0.02849840745329857,
            0.01455051451921463,
            0.015492458827793598,
            -0.01960490085184574,
            0.0042868321761488914,
            -0.017752451822161674,
            -0.0011368258856236935,
            0.014070886187255383,
            0.002995244460180402,
            -0.028793897479772568,
            -0.020015684887766838,
            -0.017644688487052917,
            -0.01934545300900936,
            -0.028154758736491203,
            -0.014539027586579323,
            0.025185799226164818,
            0.02215622551739216,
            -0.010829146951436996,
            -0.033145722001791,
            0.010609987191855907,
            -0.055997610092163086,
            0.03473515436053276,
            -0.00696464441716671,
            0.03982899710536003,
            0.03785553202033043,
            0.009466009214520454,
            0.02526000887155533,
            -0.0024641314521431923,
            0.0006397886900231242,
            -0.008635113947093487,
            0.027152076363563538,
            -0.040073007345199585,
            -0.0025044570211321115,
            0.008022531867027283,
            0.02301216870546341,
            -0.0011749707628041506,
            -0.0265670083463192,
            0.023431874811649323,
            -0.020974360406398773,
            0.004877547733485699,
            0.012319076806306839,
            -0.015036261640489101,
            -0.019860461354255676,
            0.032350484281778336,
            0.009212931618094444,
            0.012166501022875309,
            0.020428940653800964,
            0.043394193053245544,
            -0.009211522527039051,
            0.016274569556117058,
            0.031008098274469376,
            -0.00024643406504765153,
            -0.014110725373029709,
            0.022486431524157524,
            -0.024960607290267944,
            0.011951561085879803,
            0.0012610080884769559,
            -0.014244959689676762,
            0.014612194150686264,
            -0.027789708226919174,
            -0.0024771206080913544,
            -0.020399421453475952,
            -0.009269134141504765,
            0.007954785600304604,
            0.036348335444927216,
            0.015333742834627628,
            -0.013224925845861435,
            0.030591720715165138,
            0.0040139430202543736,
            -0.026375379413366318,
            -0.032401192933321,
            -0.017260976135730743,
            -0.010437621735036373,
            0.011675474233925343,
            -0.014583760872483253,
            0.006185164675116539,
            -0.025494089350104332,
            0.0051947785541415215,
            -0.025716068223118782,
            -0.006539791822433472,
            -0.011333918198943138,
            -0.0254865400493145,
            -0.025217730551958084,
            0.03676482290029526,
            0.003076848341152072,
            -0.008103710599243641,
            -0.03449270874261856,
            0.02376447804272175,
            0.0014690448297187686,
            -0.012389980256557465,
            -0.0017577871913090348,
            -0.022429000586271286,
            -0.009009667672216892,
            0.024449976161122322,
            -0.006898606661707163,
            -0.0235888808965683,
            0.025485577061772346,
            -0.0165865495800972,
            -0.038170114159584045,
            -0.029260622337460518,
            0.04028279706835747,
            0.027480440214276314,
            0.0014631480444222689,
            -0.0028443550691008568,
            -0.000987805426120758,
            0.022222120314836502,
            -0.013188585638999939,
            0.02205975353717804,
            0.013744315132498741,
            -0.002663538558408618,
            -0.004803290590643883,
            -0.02207040600478649,
            -0.01592913269996643,
            0.01004123780876398,
            -0.02273855172097683,
            -0.021981962025165558,
            0.011250752955675125,
            -0.01904885657131672,
            0.003514602081850171,
            0.001713123987428844,
            0.019784875214099884,
            -0.01746280863881111,
            -0.01608194410800934,
            -0.006558283697813749,
            0.004867058712989092,
            -0.029921500012278557,
            -0.00911808107048273,
            0.01376985851675272,
            0.035323552787303925,
            -0.016476130113005638,
            0.0026866868138313293,
            -0.016135238111019135,
            0.01446213573217392,
            0.019910035654902458,
            0.026935139670968056,
            0.008949780836701393,
            0.006703516934067011,
            0.001986029325053096,
            -0.014609177596867085,
            -0.004160507582128048,
            -0.02262861467897892,
            0.00952953565865755,
            0.03314729034900665,
            -0.04077935218811035,
            0.034041307866573334,
            -0.024785559624433517,
            -0.005685184616595507,
            0.036344122141599655,
            -0.02785523794591427,
            -0.023750992491841316,
            0.027892427518963814,
            0.02400992065668106,
            0.0013689572224393487,
            -0.011860865168273449,
            0.006893625482916832,
            0.008530966006219387,
            0.02307930961251259,
            0.007910918444395065,
            -0.021237146109342575,
            -0.01995215378701687,
            0.0036655517760664225,
            0.02953694388270378,
            0.0327611118555069,
            0.008060644380748272,
            0.014291265048086643,
            -0.021754736080765724,
            0.026691509410738945,
            -0.00248160888440907,
            -0.004064858425408602,
            0.013145229779183865,
            0.010477903299033642,
            0.022332940250635147,
            0.03090503439307213,
            -0.009597222320735455,
            0.013785031624138355,
            0.009500380605459213,
            -0.020670371130108833,
            0.00862639769911766,
            0.001298004761338234,
            0.006412514951080084,
            0.020548785105347633,
            -0.023879399523139,
            -0.021363189443945885,
            -0.02934836409986019,
            -0.03255044296383858,
            -0.010228516533970833,
            0.0273740254342556,
            -0.01284656673669815,
            -0.027343042194843292,
            -0.0025587903801351786,
            -0.007561299949884415,
            0.006374463438987732,
            0.02334548905491829,
            0.017380546778440475,
            -0.03009982220828533,
            -0.0006851532380096614,
            -0.027351923286914825,
            0.010126607492566109,
            0.0018206262029707432,
            0.01921342872083187,
            -0.0068531096912920475,
            -0.044780269265174866,
            0.009773660451173782,
            -0.03317243978381157,
            -0.0048976377584040165,
            0.020926060155034065,
            -0.018942873924970627,
            0.02486490085721016,
            0.01139204390347004,
            0.03234630450606346,
            -0.004806873854249716,
            0.01654377207159996,
            -0.005957481916993856,
            0.020212223753333092,
            0.02835678867995739,
            -0.0012035464169457555,
            -0.02646033465862274,
            -0.03208070620894432,
            0.023699859157204628,
            0.020024504512548447,
            -0.007155487779527903,
            0.01814662665128708,
            0.019495487213134766,
            0.013898313976824284,
            0.025009380653500557,
            -0.03342211991548538,
            0.011275513097643852,
            -0.0022141863591969013,
            -0.02307063527405262,
            -0.00881450716406107,
            0.022666068747639656,
            -0.032178156077861786,
            -0.006186273414641619,
            -0.0217935461550951,
            -0.04447658360004425,
            0.018264323472976685,
            0.017074119299650192,
            -0.026379495859146118,
            0.020713716745376587,
            -0.03604988753795624,
            -0.03010709583759308,
            0.007743244059383869,
            0.014546990394592285,
            -0.01864650286734104,
            -0.0027745976112782955,
            -0.0415092296898365,
            0.0010856922017410398,
            0.024788545444607735,
            0.027607358992099762,
            0.010953709483146667,
            -0.006679191719740629,
            0.0012839261908084154,
            0.03885498642921448,
            -0.009248361922800541,
            0.028227368369698524,
            -0.007288177963346243,
            -0.027136703953146935,
            -0.059953801333904266,
            0.012447885237634182,
            0.011548466980457306,
            -0.011542933993041515,
            -0.03962395340204239,
            -0.032737113535404205,
            -0.0057797301560640335,
            0.021998224779963493,
            -0.013889545574784279,
            -0.014047904871404171,
            0.0027240137569606304,
            0.022469423711299896,
            -0.017843911424279213,
            -0.01566733419895172,
            -0.010948759503662586,
            0.032806359231472015,
            0.0112885357812047,
            0.03407593443989754,
            0.010670081712305546,
            -0.000699666969012469,
            -0.035660650581121445,
            -0.005489289294928312,
            -0.006181128788739443,
            0.0069969394244253635,
            -0.007422298658639193,
            0.026071349158883095,
            0.012490540742874146,
            0.02658766508102417,
            -0.010406607761979103,
            -0.025221386924386024,
            -0.0027179450262337923,
            0.0049706753343343735,
            -0.017743060365319252,
            -0.02657078392803669,
            -0.05400518327951431,
            -0.0470443032681942,
            -0.019925110042095184,
            -0.0146930031478405,
            0.001103082555346191,
            -0.000854696030728519,
            0.020150160416960716,
            -0.01833568885922432,
            -0.02169193886220455,
            -0.01232939213514328,
            0.003923617769032717,
            -0.0003271451569162309,
            -0.026743579655885696,
            0.001703375717625022,
            0.02489883080124855,
            -0.03299299255013466,
            0.012696832418441772,
            -0.014912595972418785,
            -0.006247157230973244,
            0.012623232789337635,
            -0.008384235203266144,
            0.0038056031335145235,
            0.0032707187347114086,
            -0.010785396210849285,
            0.0008372128941118717,
            0.009998415596783161,
            0.014167881570756435,
            -0.03299291804432869,
            0.0633678287267685,
            -0.03315822780132294,
            0.013757388107478619,
            0.013104148209095001,
            0.02672737091779709,
            -0.02479536272585392,
            0.004188164137303829,
            0.02553025633096695,
            -0.04121698811650276,
            0.006288934499025345,
            -0.009305281564593315,
            0.00511788809671998,
            -0.03613283485174179,
            -0.0020339598413556814,
            0.00879812240600586,
            -0.0004644370637834072,
            0.021786712110042572,
            0.020946644246578217,
            0.012551604770123959,
            0.018086258322000504,
            0.02714633382856846,
            -0.003195670200511813,
            0.010651478543877602,
            -0.004727442283183336,
            0.005794165190309286,
            0.008900308981537819,
            -0.04359942674636841,
            -0.02364981919527054,
            -0.03256945312023163,
            -0.00940533448010683,
            0.019021252170205116,
            0.000635735341347754,
            0.007844941690564156,
            -0.014106067828834057,
            -0.017270248383283615,
            -0.01664547063410282,
            0.019361969083547592,
            -0.005800074432045221,
            -0.0020327656529843807,
            0.023832300677895546,
            0.01943245902657509,
            -0.0009620452183298767,
            -0.011315323412418365,
            -0.024793807417154312,
            -0.026926301419734955,
            -0.024029303342103958,
            0.0028431008104234934,
            0.01796986535191536,
            0.0024430123157799244,
            -0.033855024725198746,
            0.03302523493766785,
            -0.004190391395241022,
            0.02112417295575142,
            -0.017397643998265266,
            0.014969415962696075,
            0.021512171253561974,
            -0.015548959374427795,
            0.008760507218539715,
            -0.01877075806260109,
            0.005747026763856411,
            -0.025301601737737656,
            -0.03876568004488945,
            -0.024347269907593727,
            -0.03113531321287155,
            0.005137698724865913,
            0.0071553513407707214,
            0.020694388076663017,
            -0.027073156088590622,
            0.02920849435031414,
            0.0062499577179551125,
            -0.016025392338633537,
            -0.01588510349392891,
            0.020923474803566933,
            0.007718777284026146,
            -0.010502140037715435,
            0.0006036272970959544,
            -0.020439058542251587,
            0.03155968338251114,
            -0.022233737632632256,
            -0.023951668292284012,
            -0.04324165731668472,
            0.03241529315710068,
            -0.06495850533246994,
            0.018389232456684113,
            0.02129543572664261,
            -0.007102981675416231,
            0.026184169575572014,
            0.004625965375453234,
            0.019624067470431328,
            -0.0019382186001166701,
            0.004459393210709095,
            -0.04140730947256088,
            0.007079261355102062,
            -0.04534607008099556,
            0.012618773616850376,
            -0.005095471628010273,
            -0.022380700334906578,
            -0.03228620067238808,
            -0.0025959403719753027,
            -0.014119758270680904,
            -0.008462893776595592,
            -0.01936475560069084,
            0.022492842748761177,
            -0.008416121825575829,
            -0.030925555154681206,
            -0.0027199622709304094,
            0.010644914582371712,
            -0.01726757176220417,
            0.0009065737249329686,
            -0.002295064041391015,
            -0.015679676085710526,
            0.0022005673963576555,
            0.008164830505847931,
            0.022333448752760887,
            0.006960744969546795,
            -0.006088540889322758,
            -0.023689711466431618,
            -0.00541251664981246,
            0.01608114130795002,
            0.0041817557066679,
            -0.03379583731293678,
            -0.022544460371136665,
            0.0037239037919789553,
            0.026438886299729347,
            -0.03198132663965225,
            0.009620625525712967,
            0.020400486886501312,
            -0.023158716037869453,
            -0.025275882333517075,
            0.008220952935516834,
            0.02938050404191017,
            -0.004896583966910839,
            0.012658401392400265,
            -0.027007196098566055,
            -0.009354428388178349,
            0.01253307145088911,
            -0.011350098997354507,
            -0.017257176339626312,
            0.02075466886162758,
            0.019624948501586914,
            -0.04166059195995331,
            -0.024365259334445,
            -0.028926897794008255,
            0.009608268737792969,
            0.0035740837920457125,
            0.014678695239126682,
            -0.0038494491018354893,
            0.01843612641096115,
            -0.021735072135925293,
            0.00844227522611618,
            -0.011607451364398003,
            -0.013737721368670464,
            -0.02464144304394722,
            0.023154038935899734,
            0.023785507306456566,
            -0.013545773923397064,
            -0.01867041364312172,
            0.008776912465691566,
            0.015371031127870083,
            -0.03998149558901787,
            -0.005556068383157253,
            0.018089141696691513
        ],
        [
            -0.02315465360879898,
            -2.05255564651452e-05,
            0.03216598182916641,
            0.029937004670500755,
            0.020637355744838715,
            -0.020205968990921974,
            -0.030167121440172195,
            -0.009252069517970085,
            -0.020173078402876854,
            -0.021285895258188248,
            -0.0190063938498497,
            0.029442600905895233,
            -0.030288659036159515,
            -0.021286215633153915,
            -0.003750653238967061,
            -0.009250586852431297,
            -0.0034660552628338337,
            0.004398060496896505,
            0.01477231364697218,
            -0.01586480252444744,
            0.006728741340339184,
            0.005098674446344376,
            -0.009066348895430565,
            0.0011615671683102846,
            0.014674312435090542,
            0.005264135077595711,
            -0.03893778845667839,
            -0.01483704149723053,
            -0.02862568572163582,
            0.009984324686229229,
            -0.006270335987210274,
            0.03404542803764343,
            0.0006476824637502432,
            -0.005273895803838968,
            0.033663857728242874,
            -0.004742535762488842,
            0.010541158728301525,
            0.013658128678798676,
            -0.012766942381858826,
            0.03331708535552025,
            -0.014470367692410946,
            0.003765500383451581,
            0.01232232991605997,
            -0.022865498438477516,
            -0.0097922058776021,
            0.024146925657987595,
            -0.0268747266381979,
            0.03959175944328308,
            0.028699515387415886,
            0.0071821329183876514,
            -0.03087308257818222,
            -0.010210160166025162,
            -0.013277295976877213,
            -0.012925560586154461,
            0.0021739336661994457,
            0.022571757435798645,
            -0.012364249676465988,
            0.004298409912735224,
            0.019226856529712677,
            -0.014603635296225548,
            0.04429471865296364,
            0.010377580299973488,
            -0.01688189059495926,
            0.022135525941848755,
            0.014701442793011665,
            -0.027525994926691055,
            -0.029832590371370316,
            0.02994232252240181,
            -0.0020281290635466576,
            -0.021527739241719246,
            0.034807901829481125,
            -0.009883353486657143,
            -0.0051589058712124825,
            0.027991292998194695,
            0.03174085170030594,
            0.0022914973087608814,
            0.015192368999123573,
            -0.000681739707943052,
            -0.004286712966859341,
            -0.006136603653430939,
            -0.026868898421525955,
            0.0026110715698450804,
            -0.023055661469697952,
            -0.004229546058923006,
            0.0016900358023121953,
            0.005078648682683706,
            0.014393807388842106,
            -0.028292035683989525,
            -0.008393517695367336,
            0.012534123845398426,
            -0.019786493852734566,
            -0.012788957916200161,
            0.013055456802248955,
            -0.0008905428112484515,
            0.02023005671799183,
            0.027913689613342285,
            -0.0001755180855980143,
            0.025903522968292236,
            -0.03779603913426399,
            -0.012599630281329155,
            -0.013320311903953552,
            0.0059765055775642395,
            -0.0009053873363882303,
            -0.0021449336782097816,
            0.030429624021053314,
            -0.004825437441468239,
            -0.01007956825196743,
            0.030193934217095375,
            -0.0016889169346541166,
            0.01146221999078989,
            0.015010903589427471,
            0.007251400966197252,
            -0.0028936299495399,
            0.029831325635313988,
            -0.000738290196750313,
            -0.011494812555611134,
            0.006357068195939064,
            0.008344032801687717,
            -0.002153680892661214,
            -0.012322151102125645,
            -0.0034441642928868532,
            0.01607644371688366,
            -0.020869990810751915,
            0.027141163125634193,
            0.01559135876595974,
            0.038380879908800125,
            -0.010697629302740097,
            -0.006596176885068417,
            -0.022878292948007584,
            0.003387739881873131,
            -0.009537643752992153,
            -0.012689550407230854,
            0.04159059748053551,
            -0.033891305327415466,
            0.0037511244881898165,
            -0.015499775297939777,
            -0.020539069548249245,
            -0.009583670645952225,
            -0.0036286506801843643,
            -0.01267639733850956,
            -0.023486139252781868,
            0.024633493274450302,
            0.026391800493001938,
            0.0364893414080143,
            0.0023560684639960527,
            0.022728465497493744,
            0.018157918006181717,
            -0.001545241684652865,
            -0.002144863596186042,
            -0.016432706266641617,
            0.0006749308086000383,
            -0.003223458305001259,
            0.02819046564400196,
            0.047022853046655655,
            0.038347646594047546,
            0.022051675245165825,
            -0.002940782345831394,
            0.01051629800349474,
            -0.009854087606072426,
            -0.02008328214287758,
            0.012705891393125057,
            -0.00718553364276886,
            -0.034280985593795776,
            0.022489670664072037,
            0.01693635992705822,
            0.02818734385073185,
            0.002802283503115177,
            0.028230739757418633,
            -0.005240741651505232,
            -0.025266660377383232,
            -0.01975248008966446,
            -0.007876257412135601,
            -0.03069586120545864,
            0.02095675840973854,
            -0.0012177455937489867,
            -0.0018305956618860364,
            0.0186175387352705,
            -0.01938161998987198,
            0.036689642816782,
            0.03740406781435013,
            0.03332383558154106,
            0.012466849759221077,
            -0.00928199291229248,
            0.019563931971788406,
            0.02912045083940029,
            -0.014745310880243778,
            -0.011732093058526516,
            0.00492972694337368,
            0.010697084479033947,
            0.015139054507017136,
            0.010706378147006035,
            -0.014834746718406677,
            -0.006297354120761156,
            -0.013842248357832432,
            -0.0008071546908468008,
            0.01295922975987196,
            0.0321175791323185,
            -0.03494692221283913,
            0.009565111249685287,
            0.020949145779013634,
            0.0018976636929437518,
            0.0021868643816560507,
            -0.007395245600491762,
            -0.0073108552023768425,
            0.0013941606739535928,
            0.029071684926748276,
            -0.010679787024855614,
            -0.012123103253543377,
            -0.019467631354928017,
            0.01653369702398777,
            0.0006516568828374147,
            0.01969863660633564,
            -0.039078932255506516,
            -0.02439109981060028,
            0.034827638417482376,
            -0.010798008181154728,
            0.023028362542390823,
            -0.009182093665003777,
            0.015194252133369446,
            -6.900091102579609e-05,
            0.016808776184916496,
            -0.023001637309789658,
            0.0008663825574330986,
            0.01832745596766472,
            -0.0016045396914705634,
            -0.0615880973637104,
            0.01700275018811226,
            0.029395993798971176,
            -0.016440648585557938,
            0.010311718098819256,
            0.01982233114540577,
            -0.006817677989602089,
            0.005248621571809053,
            0.008935888297855854,
            0.045925408601760864,
            -0.018485918641090393,
            -0.03294326364994049,
            -0.0027544398326426744,
            0.021947383880615234,
            0.0046019102446734905,
            -0.006196028087288141,
            0.0264681838452816,
            0.008961622603237629,
            0.028921782970428467,
            -0.0037155067548155785,
            -0.027379320934414864,
            -0.00815506000071764,
            -0.01868056133389473,
            -0.006556030362844467,
            -0.007509162183851004,
            -0.029769932851195335,
            0.01888573169708252,
            -0.006150360684841871,
            -0.024271851405501366,
            -0.0044171628542244434,
            -0.0067670210264623165,
            -0.008101078681647778,
            -0.016828259453177452,
            -0.012835889123380184,
            0.027338501065969467,
            0.029849359765648842,
            -0.01672522909939289,
            0.021005114540457726,
            0.0062539842911064625,
            -0.007714357692748308,
            -0.014616402797400951,
            -0.020521078258752823,
            -0.019242556765675545,
            -0.014809735119342804,
            -0.027164911851286888,
            -0.020464476197957993,
            -0.005115155130624771,
            -0.005606375634670258,
            0.0027992622926831245,
            -0.014067459851503372,
            -0.03163354843854904,
            0.02026328444480896,
            0.019500138238072395,
            0.010077382437884808,
            -0.0167398564517498,
            0.035471491515636444,
            -0.012957463040947914,
            -0.005136553663760424,
            -0.012142743915319443,
            0.027569187805056572,
            0.008173642680048943,
            0.010437490418553352,
            0.0165411289781332,
            0.002017929684370756,
            0.025206008926033974,
            0.010204341262578964,
            0.029359063133597374,
            0.0205081757158041,
            0.03152205049991608,
            -0.01759626902639866,
            0.01796506717801094,
            -0.015524812042713165,
            -0.028305834159255028,
            0.007118586916476488,
            -0.016268135979771614,
            -0.012663534842431545,
            -0.007444082759320736,
            -0.02899007312953472,
            0.03483675420284271,
            0.004466580227017403,
            0.02366023324429989,
            -0.011939612217247486,
            -0.008635137230157852,
            0.010808849707245827,
            0.02797592431306839,
            -0.009843697771430016,
            -0.014464917592704296,
            0.01908285915851593,
            0.008314493112266064,
            0.020894957706332207,
            -0.024262016639113426,
            -0.02724727801978588,
            0.006847120821475983,
            0.013493240810930729,
            0.0023161808494478464,
            0.015914414077997208,
            -0.006850461941212416,
            -0.02116161212325096,
            -0.039992429316043854,
            0.019258806481957436,
            0.006595228798687458,
            0.0003025752666871995,
            0.024275779724121094,
            -0.029573289677500725,
            -0.01815042644739151,
            -0.01828136295080185,
            0.024083325639367104,
            0.00997275672852993,
            -0.016454804688692093,
            0.024390047416090965,
            0.007396640721708536,
            -0.004576513078063726,
            -0.01563645340502262,
            0.01834884099662304,
            0.022282138466835022,
            0.01710062474012375,
            0.013788464479148388,
            -0.01964455097913742,
            0.03145427256822586,
            -0.003020766656845808,
            -0.05168260633945465,
            0.0343070849776268,
            0.018052374944090843,
            0.0003104011120740324,
            0.017289984971284866,
            -0.02808605507016182,
            0.009198975749313831,
            -0.03694198280572891,
            -0.030073583126068115,
            0.007225120905786753,
            -0.028445303440093994,
            0.009313425049185753,
            -0.0013794719707220793,
            0.012184562161564827,
            0.005651373416185379,
            0.03404947370290756,
            0.02127096615731716,
            0.013038369826972485,
            0.0034059544559568167,
            -0.02130119688808918,
            0.015930911526083946,
            0.040185268968343735,
            0.007164218463003635,
            0.022718580439686775,
            0.027734652161598206,
            0.018819237127900124,
            0.030642030760645866,
            0.004281692672520876,
            -0.02684662491083145,
            0.006552135571837425,
            0.01821163296699524,
            -0.01914479024708271,
            -0.020472431555390358,
            -0.0035368569660931826,
            0.03749839961528778,
            -0.023980461061000824,
            0.006742114666849375,
            0.01993662491440773,
            -0.02394794672727585,
            -0.014407618902623653,
            0.024361174553632736,
            0.003523722290992737,
            0.020339863374829292,
            0.02564137987792492,
            0.003955699969083071,
            0.03234264627099037,
            -0.014196866191923618,
            -0.014230072498321533,
            -0.019158687442541122,
            -0.024789974093437195,
            -0.005892053712159395,
            0.02622390165925026,
            0.017816321924328804,
            -0.009225871413946152,
            0.008406245149672031,
            -0.00856021512299776,
            -0.02685345895588398,
            0.004027848597615957,
            -0.01294134370982647,
            -0.01206047274172306,
            -0.02097606658935547,
            0.0015483739553019404,
            0.01812918856739998,
            -0.0077555677853524685,
            -0.01275425124913454,
            -0.029189031571149826,
            0.014904793351888657,
            -0.003862065030261874,
            -0.02377837337553501,
            -0.0037309431936591864,
            -0.008811046369373798,
            -0.014030751772224903,
            0.039416756480932236,
            0.009280419908463955,
            0.01212734542787075,
            -0.0029130808543413877,
            0.023130308836698532,
            0.04649711027741432,
            0.003178388811647892,
            -0.028022857382893562,
            0.04765481874346733,
            0.020850397646427155,
            -0.014169959351420403,
            -0.006446512881666422,
            0.013820848427712917,
            -0.007900538854300976,
            0.015499047003686428,
            0.020854663103818893,
            -0.010508667677640915,
            -0.000243910399149172,
            0.022876661270856857,
            -0.003438348649069667,
            -0.00474517559632659,
            0.0010795443085953593,
            0.027866795659065247,
            0.02378878928720951,
            0.005110203288495541,
            0.02569393999874592,
            0.030657988041639328,
            0.014709863811731339,
            0.004507598467171192,
            -0.01267293468117714,
            -0.06884532421827316,
            -0.0010382654145359993,
            -0.043607018887996674,
            0.007805231027305126,
            -0.005034354981034994,
            -0.010850464925169945,
            0.006387535482645035,
            0.033901918679475784,
            -0.0014673930127173662,
            -0.019690945744514465,
            0.0014989920891821384,
            -0.013830302283167839,
            0.022986406460404396,
            0.010644071735441685,
            0.0028931405395269394,
            -0.021645186468958855,
            -0.00608820328488946,
            0.0203911941498518,
            0.001310987863689661,
            -0.004061878193169832,
            0.03262050449848175,
            0.025070250034332275,
            0.021743033081293106,
            0.016437720507383347,
            0.030834082514047623,
            -0.020861385390162468,
            -0.006999891251325607,
            0.010277783498167992,
            -0.025560490787029266,
            -0.019288169220089912,
            0.04042642563581467,
            -0.016311198472976685,
            0.01546777319163084,
            -0.01760260760784149,
            -0.016050081700086594,
            0.006693566218018532,
            0.02024747058749199,
            0.02030181884765625,
            0.009697839617729187,
            0.030012788251042366,
            -0.02894791029393673,
            0.07413964718580246,
            0.005849928129464388,
            0.009528770111501217,
            0.020678726956248283,
            0.03356786444783211,
            -0.004854554310441017,
            0.009653167799115181,
            0.00037137832259759307,
            -0.00886029563844204,
            0.002587190829217434,
            0.02959878370165825,
            0.006739145610481501,
            0.01072878297418356,
            0.018988478928804398,
            -0.01800648309290409,
            -0.0243640448898077,
            -0.03928103297948837,
            0.03164491802453995,
            0.033826421946287155,
            -0.009167179465293884,
            -0.0021662944927811623,
            0.027697687968611717,
            0.01370441634207964,
            -0.0310800913721323,
            0.0052592032589018345,
            -0.013293992727994919,
            -0.011698921211063862,
            0.013585448265075684,
            0.012029615230858326,
            -0.019536130130290985,
            0.00598191050812602,
            -0.0005733814323320985,
            0.03214316815137863,
            -0.017295660451054573,
            0.02042732574045658,
            0.0003839685523416847,
            -0.018487347289919853,
            0.014365291222929955,
            0.012810150161385536,
            0.016362197697162628,
            -0.027164703235030174,
            -0.011735992506146431,
            0.008821969851851463,
            0.022424491122364998,
            -0.0375891774892807,
            0.017173100262880325,
            0.029789255931973457,
            0.023442573845386505,
            0.024387232959270477,
            0.011028246954083443,
            -0.027906149625778198,
            -0.001677743042819202,
            -0.028195641934871674,
            -0.012662678025662899,
            0.00816357135772705,
            0.03895625099539757,
            -0.0168613251298666,
            0.06367255002260208,
            0.028915250673890114,
            -0.0048905652947723866,
            -0.002147706225514412,
            0.009761463850736618,
            -0.01390314195305109,
            -0.028074517846107483,
            0.027679122984409332,
            -0.009791563265025616,
            0.01791295036673546,
            -0.013190889731049538,
            0.03760436549782753,
            -0.013205171562731266,
            -0.013692623004317284,
            -0.023445025086402893,
            0.004654729273170233,
            -0.005523645784705877,
            -0.013593821786344051,
            -0.028901224955916405,
            0.009641094133257866,
            0.030092773959040642,
            0.011389603838324547,
            -0.026447882875800133,
            0.0234173983335495,
            0.019222266972064972,
            -0.017099814489483833,
            0.013136306777596474,
            -0.024058669805526733,
            0.0017238572472706437,
            -0.01890525408089161,
            -0.014603950083255768,
            -0.0015178743051365018,
            0.0038610990159213543,
            0.04472567141056061,
            0.008687801659107208,
            0.027065103873610497,
            -0.01690489612519741,
            -0.001561712590046227,
            0.01966319978237152,
            -0.008195308037102222,
            0.02758982591331005,
            -0.02150670439004898,
            -0.007908683270215988,
            -0.013310638256371021,
            -0.011179417371749878,
            -0.013234171085059643,
            0.03340230509638786,
            -0.007723918184638023,
            0.03417052701115608,
            0.025085991248488426,
            0.025849860161542892,
            -0.021010594442486763,
            0.025946099311113358,
            -0.009542635641992092,
            -0.005788347218185663,
            -0.041736721992492676,
            0.003240274963900447,
            0.028068633750081062,
            0.01983989030122757,
            0.005749896168708801,
            0.007503449451178312,
            0.01758885756134987,
            0.025341983884572983,
            -0.024933697655797005,
            0.020469561219215393,
            -0.0053541031666100025,
            0.013511738739907742,
            0.006306203082203865,
            0.01138673909008503,
            -0.006317932158708572,
            -0.03262869641184807,
            0.027591856196522713,
            -0.032960548996925354,
            -0.0216585174202919,
            -0.004294214770197868,
            -0.007509025279432535,
            -0.022854987531900406,
            -0.006253842264413834,
            -0.009367397055029869,
            0.00185447558760643,
            -0.014263140968978405,
            -0.007874367758631706,
            0.027128878980875015,
            -0.013044261373579502,
            -0.03338272497057915,
            0.034178268164396286,
            -0.03189387172460556,
            -0.027715714648365974,
            -0.00040820828871801496,
            -0.0038213259540498257,
            -0.03475642949342728,
            0.027361996471881866,
            0.006213985849171877,
            -0.013357098214328289,
            0.001919138478115201,
            -0.005693353246897459,
            -0.024380553513765335,
            0.01735619083046913,
            0.011255918070673943,
            -0.0006480063311755657,
            0.020128363743424416,
            -0.005034457426518202,
            0.019686728715896606,
            -0.016722597181797028,
            0.01049603521823883,
            -0.019533328711986542,
            0.020755918696522713,
            0.028780722990632057,
            0.006289752200245857,
            -0.02652992308139801,
            -0.017612986266613007,
            0.01417110301554203,
            0.007202032953500748,
            0.024355903267860413,
            -0.018009310588240623,
            -0.017281262204051018,
            0.01905852183699608,
            -0.009623758494853973,
            0.03284233435988426,
            0.0038941253442317247,
            -0.011377308517694473,
            0.008288971148431301,
            0.01606159470975399,
            0.014287850819528103,
            -0.01991787552833557,
            -0.0011349973501637578,
            -0.02671569585800171,
            0.01011507399380207,
            -0.019852492958307266,
            -0.014655191451311111,
            0.0029647296760231256,
            0.018156152218580246,
            0.021693004295229912,
            -0.02829335443675518,
            0.02504841797053814,
            0.0013787237694486976,
            -0.03019910864531994,
            -0.016727687790989876,
            -0.009385835379362106,
            0.020859623327851295,
            -0.008146204054355621,
            0.012034168466925621,
            0.002720808144658804,
            0.0229952409863472,
            -0.01887657307088375,
            0.023166675120592117,
            0.014484629966318607,
            0.029088741168379784,
            0.02772459015250206,
            0.0280217956751585,
            -0.004372877534478903,
            -0.022809868678450584,
            0.008374402299523354,
            0.02677256055176258,
            0.00560437748208642,
            -0.010133220814168453,
            0.0018763327971100807,
            0.028047727420926094,
            0.008409406989812851,
            0.006486380472779274,
            -0.04119003564119339,
            -0.02749832160770893,
            -0.028553588315844536,
            0.007725220639258623,
            0.027036765590310097,
            0.024945074692368507,
            0.00311255338601768,
            0.008128195069730282,
            -0.004740533884614706,
            -0.00020614397362805903,
            -0.02979624830186367,
            0.010430576279759407,
            0.01413011085242033,
            0.0040482995100319386,
            -0.027471566572785378,
            0.022369645535945892,
            0.027496924623847008,
            0.0002425155689707026,
            -0.012485523708164692,
            -0.023212555795907974,
            -0.020024633035063744,
            0.028339259326457977,
            -0.0006806240417063236,
            0.018318122252821922,
            0.014322292059659958,
            0.010311173275113106,
            0.01231408305466175,
            0.02220562845468521,
            0.016074223443865776,
            0.004073742311447859,
            0.02441277913749218,
            -0.006537140347063541,
            0.023031171411275864,
            0.027082659304142,
            -0.01545995194464922,
            -0.007575606927275658,
            0.02509482391178608,
            0.012514113448560238,
            -0.026908017694950104,
            0.029190247878432274,
            0.009480927139520645,
            0.03835887089371681,
            -0.0008688145317137241,
            -0.004207638092339039,
            -0.02594427950680256,
            -0.02588333562016487,
            -0.008510546758770943,
            -0.02828923799097538,
            -0.0064404672011733055,
            -0.028818130493164062,
            -0.022838331758975983,
            0.01252746395766735,
            -0.001490697730332613,
            0.027514087036252022,
            0.03185972571372986,
            0.011206932365894318,
            -0.031310975551605225,
            -0.024026969447731972,
            -0.020201995968818665,
            0.0004029391857329756,
            -0.023840399459004402,
            -0.008083241060376167,
            -0.0005826902925036848,
            -0.016998616978526115,
            -0.008496166206896305,
            0.023147419095039368,
            0.024043936282396317,
            0.036431584507226944,
            0.0014644692419096828,
            -0.004715974908322096,
            -0.014248502440750599,
            -0.010506292805075645,
            -0.006314405240118504,
            -0.022987835109233856,
            -0.004822933580726385,
            -0.005304467398673296,
            -0.01886015012860298,
            -0.00147532660048455,
            -0.026198532432317734,
            -0.02904064580798149,
            -0.011827363632619381,
            -0.00314059155061841,
            0.009418661706149578,
            -0.03813083842396736,
            0.002746643265709281,
            -0.015065784566104412,
            0.029523702338337898,
            0.006018796935677528,
            -0.05017455667257309,
            0.0005605184705927968,
            -0.01882345974445343,
            0.03141225129365921,
            0.028076907619833946,
            0.012344625778496265,
            0.0016029209364205599,
            0.012735195457935333,
            0.026841167360544205,
            -0.04028557240962982,
            -0.021550340577960014,
            0.014954550191760063,
            0.007131778169423342,
            -0.018793052062392235,
            -0.021752413362264633,
            0.021139275282621384,
            -0.004576173145323992,
            -0.0012812906643375754,
            0.029833070933818817,
            -0.011117132380604744,
            -0.02266084775328636,
            -0.0003707104770001024,
            -0.031252626329660416,
            0.0013076860923320055,
            0.03689337149262428,
            0.009831154718995094,
            -0.006120550911873579,
            0.017208125442266464,
            -0.0008163490565493703,
            0.023100443184375763,
            -0.03227473422884941,
            -0.022013382986187935,
            -0.003219868987798691,
            0.0028479609172791243,
            0.005990688689053059,
            -0.013463727198541164,
            -0.01139687281101942,
            -0.00398167222738266,
            -0.02196018397808075,
            -0.008922488428652287,
            0.018635792657732964,
            -0.005389238707721233,
            -0.0030964601319283247,
            -0.010174841620028019,
            -0.018611442297697067,
            -0.028218869119882584,
            -0.018392764031887054,
            -0.001816656906157732,
            -0.007892164401710033,
            -0.001193248899653554,
            0.002731533255428076,
            0.007602947764098644,
            -0.008249600417912006,
            -0.013929530046880245,
            0.02389376610517502,
            -0.03216052055358887,
            0.004898485727608204,
            -0.01615014672279358,
            0.012775002978742123,
            -0.0208931602537632,
            -0.03312072530388832,
            -0.0014964762376621366,
            0.027903901413083076,
            0.011847943998873234,
            0.013682774268090725,
            0.007274080067873001,
            0.025555917993187904,
            -0.039916008710861206,
            0.01446566078811884,
            -0.02540346421301365,
            0.021923048421740532,
            0.015287117101252079,
            -0.008467214182019234,
            -0.01988723687827587,
            0.01710590533912182,
            0.024859344586730003,
            -0.018103305250406265,
            -0.01192356739193201,
            0.024568455293774605,
            -0.007867821492254734,
            -0.037242334336042404,
            0.022721506655216217,
            -0.014355212450027466,
            -0.02137015387415886,
            -0.04258948937058449,
            0.014239972457289696,
            -0.013438579626381397,
            -0.0118246553465724,
            -0.013749749399721622,
            -0.02537309005856514,
            0.007479970809072256,
            -0.00041614979272708297,
            -0.024656124413013458,
            0.007820036262273788,
            0.018900074064731598,
            0.002228576922789216,
            0.008086000569164753,
            -0.01653057523071766,
            0.02234555222094059,
            0.0005562119768001139,
            0.04776806756854057,
            0.01363570336252451,
            0.015773942694067955,
            0.026332510635256767,
            -0.0032520436216145754,
            -0.004347230773419142,
            0.015187917277216911,
            0.009720562025904655,
            0.03380400314927101,
            0.011579839512705803,
            -0.008991345763206482,
            0.021364424377679825,
            0.013744380325078964,
            0.017894761636853218,
            0.012874008156359196,
            0.01304587908089161,
            0.0052705914713442326,
            -0.026171743869781494,
            -0.02207588404417038,
            0.0036796391941607,
            0.03659484162926674,
            0.03041001409292221,
            0.020072532817721367,
            -0.0015576123259961605,
            -0.011802688241004944,
            0.026727261021733284,
            0.018082940950989723,
            -0.005306508392095566,
            -0.02899480052292347,
            0.014723031781613827,
            0.02320515550673008,
            0.0037914535496383905,
            -0.024015504866838455,
            -0.009813257493078709,
            0.016689935699105263,
            0.015155291184782982,
            0.01578550972044468,
            -0.026349442079663277,
            -0.006602566689252853,
            -0.012429017573595047,
            -0.0279002133756876,
            0.006469622254371643,
            0.018095185980200768,
            0.016801992431282997,
            0.0240793377161026,
            0.022316904738545418,
            0.030373917892575264,
            -0.002758773509413004,
            -0.009615732356905937,
            -0.018980948254466057,
            0.011419500224292278,
            -0.009001096710562706,
            -0.02586866356432438,
            -0.01723872311413288,
            -0.023669511079788208,
            0.01854637823998928,
            0.007214702665805817,
            -0.002779388800263405,
            0.007886053062975407,
            -0.004093844443559647,
            -0.0305308997631073,
            -0.0057914238423109055,
            0.036106135696172714,
            0.009790773503482342,
            -0.01759941689670086,
            0.002299655694514513,
            -0.013531061820685863,
            -0.02365189976990223,
            0.019165437668561935,
            0.004788760095834732,
            -0.02846684865653515,
            0.011032486334443092,
            0.0014496035873889923,
            -0.048927191644907,
            -0.001492347801104188,
            -0.013749646954238415,
            -0.015112346038222313,
            0.008930720388889313,
            0.03064962662756443,
            -0.006963816937059164,
            0.005300792865455151,
            0.01990870013833046,
            -0.004076193552464247,
            -0.03825416788458824,
            0.02215638756752014,
            0.01064202468842268,
            0.007839726284146309,
            0.021071556955575943,
            -0.012778252363204956,
            0.02194199338555336,
            0.01814517192542553,
            -0.03416438028216362,
            0.014325224794447422,
            0.01154313888400793,
            0.0029043923132121563,
            0.013137994334101677,
            0.02287544682621956,
            0.015632711350917816,
            -0.025013715028762817,
            0.022438112646341324,
            0.007301829289644957,
            -0.002594543620944023,
            -0.005226850509643555,
            0.01966589130461216,
            -0.019366929307579994,
            -0.01989295892417431,
            -0.0024451364297419786,
            0.028173986822366714,
            -0.004224592354148626,
            0.03363322466611862,
            -0.0015316151548177004,
            0.007495132740586996,
            -0.018736910074949265,
            0.01622571423649788,
            0.014937332831323147,
            -0.015186524949967861,
            -0.013450137339532375,
            0.001775345648638904,
            0.022495398297905922,
            0.013132859952747822,
            0.021744025871157646,
            -0.006290397606790066,
            -0.031141214072704315,
            -0.01518581248819828,
            0.0049818819388747215,
            0.0034877907019108534,
            -0.02337944507598877,
            -0.021078558638691902,
            0.030859967693686485,
            -0.025844013318419456,
            -0.032889556139707565
        ],
        [
            0.0025316125247627497,
            0.04220130667090416,
            0.036965470761060715,
            0.0038403887301683426,
            -0.020367685705423355,
            -0.03972354531288147,
            0.034747008234262466,
            0.018958091735839844,
            -0.020415952429175377,
            0.00037864461774006486,
            -0.0283510759472847,
            0.012252304702997208,
            -0.011084516532719135,
            0.0271023940294981,
            -0.07955353707075119,
            -0.008051358163356781,
            0.02419252134859562,
            0.0025444107595831156,
            -0.05432514473795891,
            -0.03804757818579674,
            -0.019975796341896057,
            0.013417541980743408,
            -0.009978353045880795,
            -0.007212930358946323,
            -0.011884542182087898,
            -0.001976560801267624,
            -0.019710013642907143,
            0.007613752502948046,
            0.00028602409292943776,
            0.0006097671575844288,
            0.012685024179518223,
            -0.0011438917135819793,
            0.002552969614043832,
            0.0012294696643948555,
            -0.011764577589929104,
            -0.012761007063090801,
            0.03445282205939293,
            -0.0024714989122003317,
            0.009228748269379139,
            0.0164535753428936,
            0.004147169180214405,
            -0.0017021320527419448,
            -0.015355457551777363,
            0.00015695931506343186,
            0.06726766377687454,
            -0.020252803340554237,
            -0.0012522453907877207,
            0.03853757679462433,
            -0.0012227126862853765,
            -0.030727382749319077,
            0.012162783183157444,
            -0.048801906406879425,
            -0.05195450782775879,
            0.024731721729040146,
            0.012259501032531261,
            -0.02351655252277851,
            -0.008993984200060368,
            -0.10905046761035919,
            0.010694073513150215,
            0.01971212401986122,
            -0.0076380386017262936,
            0.0006495695561170578,
            -0.02401505969464779,
            -0.014723779633641243,
            0.01829534024000168,
            -0.0036176994908601046,
            0.005065881181508303,
            0.028436115011572838,
            -0.00022181461099535227,
            0.021890688687562943,
            0.013762302696704865,
            0.006568418350070715,
            -0.025718113407492638,
            -0.0157243050634861,
            -0.00012235301255714148,
            0.026236332952976227,
            -0.004828978329896927,
            0.004209551028907299,
            -0.025507306680083275,
            -0.021343961358070374,
            -0.019987402483820915,
            -0.022335508838295937,
            0.021731415763497353,
            0.0202977005392313,
            -0.007476835511624813,
            -0.013313979841768742,
            0.01807270012795925,
            0.015462485142052174,
            0.023746347054839134,
            -0.01312317419797182,
            -0.010071841068565845,
            -0.03674159198999405,
            -0.0389651320874691,
            0.031299129128456116,
            0.004060115199536085,
            0.0413207970559597,
            -0.023785559460520744,
            0.007632707245647907,
            -0.0758572667837143,
            0.027455270290374756,
            -0.002041205298155546,
            0.08526796102523804,
            0.021318530663847923,
            -0.023824304342269897,
            -0.01547389104962349,
            0.025545157492160797,
            -0.009852978400886059,
            -0.02309941127896309,
            -0.01934286765754223,
            -0.009941077791154385,
            0.011063891462981701,
            -0.011111472733318806,
            0.03748216852545738,
            -0.005893636029213667,
            -0.09514431655406952,
            0.004318506456911564,
            0.028560271486639977,
            -0.02631717547774315,
            0.03943978622555733,
            -0.024015387520194054,
            -0.009927692823112011,
            -0.004027203191071749,
            -0.05942866951227188,
            -0.028953121975064278,
            0.017420664429664612,
            0.03457263112068176,
            -0.01235960889607668,
            -0.025615660473704338,
            0.0186072438955307,
            0.019436359405517578,
            0.0035269958898425102,
            0.009275523945689201,
            0.0007585824350826442,
            0.002550604986026883,
            -0.03741764649748802,
            0.014434886164963245,
            0.01783064566552639,
            0.0008101181010715663,
            -0.0035678166896104813,
            -0.04293418675661087,
            0.024541981518268585,
            0.022616010159254074,
            0.002526551019400358,
            -0.012433166615664959,
            -0.015253006480634212,
            -0.033783044666051865,
            0.003631352912634611,
            -0.0015368361491709948,
            -0.016186732798814774,
            0.013733861036598682,
            0.040441740304231644,
            0.04521699622273445,
            0.011879575438797474,
            -5.383433017414063e-05,
            -0.016317306086421013,
            0.03540165722370148,
            0.028604302555322647,
            0.0048774247989058495,
            -0.09236768633127213,
            0.02282773144543171,
            -0.0002699118049349636,
            -0.007672634441405535,
            -0.027280496433377266,
            0.03638509660959244,
            0.005286551080644131,
            0.013798261992633343,
            0.011503485962748528,
            -0.018664564937353134,
            0.007089958526194096,
            -0.03789319843053818,
            -0.03280692547559738,
            -0.012005295604467392,
            -0.03639410436153412,
            -0.006564836483448744,
            -0.038297463208436966,
            -0.018879570066928864,
            0.00899420864880085,
            0.01517318096011877,
            0.003942917566746473,
            0.06162169575691223,
            0.0222715362906456,
            -0.028018217533826828,
            0.02336958982050419,
            0.039036110043525696,
            0.011376838199794292,
            0.009791120886802673,
            0.006510453764349222,
            0.00038312675314955413,
            -0.07048618793487549,
            0.018290206789970398,
            -0.031456198543310165,
            0.04867379739880562,
            -0.006127974949777126,
            -0.010168454609811306,
            0.020153889432549477,
            0.004672128241509199,
            0.0020979249384254217,
            0.01851038448512554,
            0.013194283470511436,
            -0.03863032907247543,
            -0.004483768250793219,
            0.009684574790298939,
            -0.030694128945469856,
            -0.01651913858950138,
            -0.003883064491674304,
            0.03486238047480583,
            -0.008422013372182846,
            -0.024140654131770134,
            0.020493630319833755,
            0.040525440126657486,
            0.025745924562215805,
            0.0001552160392748192,
            -0.004020847845822573,
            0.014894404448568821,
            0.031199542805552483,
            0.002012762473896146,
            -0.006220864597707987,
            0.0166139118373394,
            -0.02924029901623726,
            0.01317509263753891,
            -0.006893904879689217,
            0.0055497814901173115,
            -0.0012225518003106117,
            -0.015349319204688072,
            -0.023699108511209488,
            0.016240421682596207,
            -0.04602526128292084,
            -0.00261768139898777,
            0.020995723083615303,
            0.009907347150146961,
            -0.03137839958071709,
            0.014388623647391796,
            -0.0410175696015358,
            0.020832713693380356,
            -0.0015843752771615982,
            0.015410508029162884,
            -0.009522038511931896,
            -0.009735728614032269,
            0.02752033621072769,
            0.04167858511209488,
            -0.006742167752236128,
            -0.007077476475387812,
            0.017990989610552788,
            0.02305620163679123,
            -0.026089651510119438,
            0.0013304650783538818,
            -0.002820586785674095,
            0.010897723957896233,
            -0.015826459974050522,
            -0.030573716387152672,
            -0.010015740990638733,
            -0.010487564839422703,
            0.0018816714873537421,
            -0.023599691689014435,
            -0.009363953024148941,
            0.0012590741971507668,
            0.00369796110317111,
            -0.030072113499045372,
            0.036764610558748245,
            -0.0043394300155341625,
            0.052855901420116425,
            0.03286335989832878,
            0.04116172716021538,
            -0.013464922085404396,
            0.01259651966392994,
            0.023124756291508675,
            -0.03521298989653587,
            0.019921371713280678,
            -0.0454055555164814,
            -0.026651231572031975,
            -0.007167454343289137,
            -0.020509270951151848,
            -0.015153716318309307,
            -0.0019428330706432462,
            -0.006873150356113911,
            0.035661861300468445,
            -0.022783387452363968,
            -0.019025159999728203,
            0.03271699696779251,
            -0.009561772458255291,
            0.035188283771276474,
            0.03673115000128746,
            -0.0001225915621034801,
            0.04126180335879326,
            0.014206278137862682,
            0.03252961486577988,
            0.010937578976154327,
            0.052419524639844894,
            -0.0019120918586850166,
            0.004121977370232344,
            -0.013713080435991287,
            -0.003957992419600487,
            -0.001328861340880394,
            -0.01961994543671608,
            0.033449068665504456,
            0.006676682271063328,
            -0.023920541629195213,
            0.022277768701314926,
            -0.015509005635976791,
            -0.017106156796216965,
            0.01578904502093792,
            0.00011620993609540164,
            0.016337169334292412,
            -0.021140608936548233,
            -0.023614346981048584,
            -0.08744508028030396,
            0.015136846341192722,
            0.007394479587674141,
            -0.02622874639928341,
            0.00218527321703732,
            -0.009858164936304092,
            0.010046540759503841,
            0.003313831752166152,
            0.0008262782357633114,
            0.008816421031951904,
            0.020486656576395035,
            0.0026685050688683987,
            0.034379251301288605,
            -0.01180480606853962,
            0.0070181600749492645,
            0.027998583391308784,
            0.04632354900240898,
            0.04133529216051102,
            -0.022889258340001106,
            -0.016408083960413933,
            0.017482828348875046,
            -0.054705239832401276,
            0.008732825517654419,
            0.02720678597688675,
            -0.007504686247557402,
            0.014570435509085655,
            -0.02534179762005806,
            0.012580638751387596,
            0.008052283897995949,
            0.008349333889782429,
            -0.0376560315489769,
            -0.04890032112598419,
            -0.04940095916390419,
            0.004266387317329645,
            0.007679085247218609,
            0.034730665385723114,
            0.057420261204242706,
            0.002612704411149025,
            -0.02569921873509884,
            0.010314160026609898,
            -0.0008061242988333106,
            -0.00825203862041235,
            -0.008759286254644394,
            4.351896495791152e-05,
            -0.014335005544126034,
            0.021686075255274773,
            0.013916360214352608,
            0.004543619696050882,
            -0.023634029552340508,
            -0.002171649131923914,
            -0.024373944848775864,
            0.006454060785472393,
            0.02705533429980278,
            -0.025317037478089333,
            0.014230283908545971,
            0.0193722415715456,
            -0.03091450035572052,
            0.010227077640593052,
            0.008276381529867649,
            0.014345640316605568,
            -0.009757551364600658,
            -0.011553316377103329,
            0.03425600752234459,
            -0.025738714262843132,
            0.01904298923909664,
            0.01844552904367447,
            0.006234622094780207,
            -0.01759006828069687,
            0.037376098334789276,
            0.01773845963180065,
            0.01445721834897995,
            0.008999057114124298,
            0.015260586515069008,
            -0.033052343875169754,
            -0.016696495935320854,
            0.011375940404832363,
            -0.021027034148573875,
            -0.004402065649628639,
            0.015171484090387821,
            -0.010900604538619518,
            -0.004992606583982706,
            -0.023089827969670296,
            -0.011741879396140575,
            0.016928324475884438,
            -0.017597107216715813,
            -0.018556836992502213,
            -0.022864650934934616,
            -0.02934992127120495,
            0.0012099075829610229,
            0.0361236035823822,
            -0.02475854940712452,
            -0.019837267696857452,
            -0.011252669617533684,
            -0.031204156577587128,
            0.013043805956840515,
            -0.005345968995243311,
            -0.00973797868937254,
            0.008575976826250553,
            0.00852308701723814,
            0.013066302053630352,
            0.03910829871892929,
            -0.013126039877533913,
            -4.911819632980041e-05,
            0.030746623873710632,
            -0.011336334981024265,
            0.014170963317155838,
            0.010487031191587448,
            -0.009747943840920925,
            0.025179747492074966,
            0.030113868415355682,
            0.004411442670971155,
            -0.007339044474065304,
            -0.012051373720169067,
            -0.06155464053153992,
            0.032228875905275345,
            -0.01920655556023121,
            -0.014723623171448708,
            -0.026879772543907166,
            -0.03374682366847992,
            0.03447975590825081,
            -0.0341547429561615,
            0.018314620479941368,
            0.04392094910144806,
            -0.0035510999150574207,
            0.006385537330061197,
            -0.017732273787260056,
            0.004503208678215742,
            -0.016808126121759415,
            -0.006738987285643816,
            -0.012885445728898048,
            -0.01730009727180004,
            -0.01863301359117031,
            0.014977269805967808,
            -0.0005943047581240535,
            -0.018793068826198578,
            0.02645873837172985,
            0.0234917551279068,
            -0.022747734561562538,
            0.007002087775617838,
            -0.026301098987460136,
            -0.006682118866592646,
            0.016689276322722435,
            -0.09506693482398987,
            0.0007789442897774279,
            0.03100481815636158,
            -0.005443589296191931,
            -0.013065456412732601,
            0.028277695178985596,
            -0.0064810533076524734,
            0.042076949030160904,
            0.001127633498981595,
            0.012163073755800724,
            -0.007450276520103216,
            -0.014525262638926506,
            -0.0013042503269389272,
            0.0046182540245354176,
            -0.007573324255645275,
            0.033272452652454376,
            -0.03096400760114193,
            0.02128102257847786,
            0.03299710899591446,
            0.017717648297548294,
            -0.007215585559606552,
            -0.0018220838392153382,
            -0.001456924364902079,
            -0.02032843977212906,
            -0.0010234724031761289,
            0.03561953827738762,
            0.003013456705957651,
            -0.0008505134610459208,
            -0.03422030061483383,
            0.01440473459661007,
            0.042705971747636795,
            0.03481963649392128,
            0.008121833205223083,
            0.04015287011861801,
            0.0008811181178316474,
            0.0019068210385739803,
            0.033353619277477264,
            -0.00042845640564337373,
            -0.0033591189421713352,
            -0.007452214602380991,
            0.02750452794134617,
            -0.04442821815609932,
            0.012001853436231613,
            0.002409645589068532,
            0.0017358704935759306,
            0.01894291676580906,
            -0.008192946203052998,
            0.02147943153977394,
            0.014000874012708664,
            0.02175566926598549,
            0.03154719993472099,
            -0.015334810130298138,
            -0.009915294125676155,
            -0.02603060007095337,
            -0.08022541552782059,
            0.015742024406790733,
            -0.03495662659406662,
            0.0028200161177664995,
            0.01006009615957737,
            0.033054932951927185,
            0.0028439643792808056,
            0.015536313876509666,
            -0.011831146664917469,
            0.02384691871702671,
            -0.03235524147748947,
            1.1916808034584392e-05,
            0.02275604009628296,
            -0.005778028164058924,
            0.023954814299941063,
            -0.006592622958123684,
            -0.019287992268800735,
            -0.002642660401761532,
            0.006264716386795044,
            0.025678817182779312,
            -0.02146133780479431,
            0.0006187311373651028,
            0.010128575377166271,
            -0.021285241469740868,
            -0.010280258022248745,
            -0.03210124000906944,
            -0.0008552465005777776,
            0.015333861112594604,
            0.017716582864522934,
            0.030476471409201622,
            -0.004123808816075325,
            -0.035984545946121216,
            0.007297529373317957,
            -0.024818794801831245,
            0.027292411774396896,
            0.015620882622897625,
            0.003701696405187249,
            0.0026141791604459286,
            -0.0027847709134221077,
            -0.014233254827558994,
            -0.017028141766786575,
            -6.289795419434085e-05,
            -0.035879481583833694,
            -0.03573820739984512,
            0.05695812404155731,
            0.02124384604394436,
            -0.010644868016242981,
            -0.02205943502485752,
            0.01446533203125,
            -0.0004766818892676383,
            0.017825467512011528,
            -0.015374088659882545,
            -0.039543069899082184,
            -0.013821008615195751,
            0.014217565767467022,
            -0.020185738801956177,
            -0.03496108576655388,
            -0.036970354616642,
            -0.027231689542531967,
            -0.03508997708559036,
            -0.023871395736932755,
            -0.02582152932882309,
            0.027543434873223305,
            -0.018042434006929398,
            -0.01219235360622406,
            0.02786867693066597,
            -0.005149023607373238,
            -0.008778251707553864,
            -0.0023153023794293404,
            -0.0006630076095461845,
            -0.01693417690694332,
            0.0264890119433403,
            0.02244921401143074,
            0.0133036645129323,
            -0.029610145837068558,
            -0.015069374814629555,
            0.0013521244982257485,
            -0.01687559299170971,
            0.01145651564002037,
            0.019678182899951935,
            -0.029798375442624092,
            -0.033588528633117676,
            0.020213844254612923,
            -0.019494816660881042,
            -0.02280963771045208,
            -0.0023819615598767996,
            -0.03257347643375397,
            -0.006164205726236105,
            -0.0015272712334990501,
            -0.023724986240267754,
            0.004388072062283754,
            -0.015599417500197887,
            -0.001024306402541697,
            0.028407979756593704,
            0.020735014230012894,
            -0.024337470531463623,
            -0.0196251068264246,
            0.011165929026901722,
            0.031039558351039886,
            0.022496238350868225,
            0.036479003727436066,
            -0.05033678561449051,
            -0.04317021369934082,
            0.026170523837208748,
            -0.005122313741594553,
            -0.027915075421333313,
            0.014634804800152779,
            0.006903553381562233,
            0.014953010715544224,
            0.0001947296696016565,
            0.011568968184292316,
            0.019515851512551308,
            0.021045759320259094,
            0.005858474411070347,
            -0.010519329458475113,
            -0.01821797713637352,
            -0.007377633824944496,
            0.007726843003183603,
            0.032808855175971985,
            0.025484634563326836,
            0.010086307302117348,
            -0.027689654380083084,
            -0.0127863809466362,
            0.037715595215559006,
            -0.008554783649742603,
            0.027175698429346085,
            0.042587291449308395,
            0.03304487094283104,
            0.03637652099132538,
            0.013754277490079403,
            0.02767600119113922,
            0.022672593593597412,
            -0.044606853276491165,
            0.013819413259625435,
            0.03593892976641655,
            0.0020233772229403257,
            0.010523530654609203,
            -0.016519850119948387,
            -0.027649683877825737,
            -0.002627401379868388,
            -0.020504232496023178,
            -0.004183915443718433,
            -0.07049300521612167,
            0.004355588927865028,
            -0.0003766217560041696,
            0.001856459304690361,
            -0.029933147132396698,
            0.029156522825360298,
            0.03658028692007065,
            0.037672869861125946,
            -0.012328295037150383,
            0.028929494321346283,
            -0.016305401921272278,
            0.013507058843970299,
            0.029136396944522858,
            0.016306787729263306,
            -0.019686929881572723,
            -0.003431841265410185,
            -0.004659614525735378,
            0.009530361741781235,
            -0.004586065653711557,
            -0.029602153226733208,
            0.00570652773603797,
            -0.013709604740142822,
            0.023070108145475388,
            0.025026673451066017,
            0.0035926757846027613,
            0.00800679624080658,
            -0.011947359889745712,
            -0.0007186110597103834,
            0.03177817538380623,
            0.025480227544903755,
            -0.03609338030219078,
            -0.020338013768196106,
            -0.03990376368165016,
            0.023812755942344666,
            0.027473395690321922,
            0.01593116670846939,
            -0.00360727496445179,
            -0.043612852692604065,
            0.022557973861694336,
            -0.00808775145560503,
            -0.023959707468748093,
            -0.0014648622600361705,
            -0.01938542351126671,
            -0.017697403207421303,
            0.015852442011237144,
            -0.024848099797964096,
            -0.017795823514461517,
            -0.03280327096581459,
            -0.013153600506484509,
            0.016953162848949432,
            -0.01310171838849783,
            -0.021639341488480568,
            0.0299831535667181,
            0.013024370186030865,
            -0.0022797775454819202,
            -0.0055647543631494045,
            0.0018556006252765656,
            0.008180465549230576,
            0.00023135228548198938,
            0.003293760819360614,
            -0.0029570688493549824,
            -0.038128454238176346,
            0.016186507418751717,
            -0.03832864761352539,
            0.02969462424516678,
            -0.011106542311608791,
            0.018876267597079277,
            -0.016310982406139374,
            0.016005825251340866,
            -0.013350773602724075,
            -0.028099453076720238,
            -0.005905758589506149,
            -0.015801526606082916,
            -0.004631705582141876,
            -0.027051642537117004,
            -0.004208113998174667,
            0.030597325414419174,
            0.018751904368400574,
            0.033717475831508636,
            -0.00142870272975415,
            0.018584132194519043,
            0.03488420322537422,
            0.0003543189959600568,
            -0.01949596218764782,
            -0.0036871975753456354,
            -0.023455578833818436,
            0.037018705159425735,
            -0.020546408370137215,
            0.008276904001832008,
            0.04680509492754936,
            0.018513791263103485,
            -0.00917314738035202,
            -0.009432127699255943,
            0.03588246926665306,
            0.011063518933951855,
            0.029346982017159462,
            -0.009974511340260506,
            0.02723732218146324,
            0.035248853266239166,
            -0.03859562799334526,
            -0.012872662395238876,
            -0.008487598039209843,
            -0.02134966477751732,
            0.05147155746817589,
            0.050436101853847504,
            -0.033900924026966095,
            0.015562567859888077,
            -0.0413416363298893,
            0.018807366490364075,
            -0.018558718264102936,
            0.00894908607006073,
            -0.022740360349416733,
            0.017395535483956337,
            -0.004631692077964544,
            -0.01718452200293541,
            0.027581937611103058,
            -0.010727955028414726,
            -0.019338468089699745,
            0.005870041903108358,
            0.010547760874032974,
            -0.023379677906632423,
            0.02677132375538349,
            -0.025978747755289078,
            -0.005089296959340572,
            -0.016379792243242264,
            -0.0028042716439813375,
            0.012424436397850513,
            0.005392214749008417,
            -0.018611781299114227,
            0.003979822620749474,
            0.01098477654159069,
            0.01626390777528286,
            -0.03293372318148613,
            0.014390641823410988,
            -0.03335379436612129,
            0.0005621456657536328,
            -0.016184916719794273,
            0.00815886352211237,
            0.013057101517915726,
            0.006603952031582594,
            -0.0077455793507397175,
            0.024344241246581078,
            0.003547819098457694,
            0.04630786553025246,
            -0.03946458548307419,
            0.01160616148263216,
            -0.04025465250015259,
            0.046710025519132614,
            0.02868420071899891,
            0.0014773422153666615,
            0.013876515440642834,
            -0.007674359250813723,
            -0.03852856904268265,
            0.016341861337423325,
            -0.02165757678449154,
            -0.017379632219672203,
            -0.025059934705495834,
            0.01836925372481346,
            0.005832708906382322,
            0.04409784823656082,
            0.0008897638763301075,
            -0.03419867157936096,
            0.027768291532993317,
            -0.01337762176990509,
            -0.013317112810909748,
            -0.03276565298438072,
            0.01693357527256012,
            -0.027199184522032738,
            -0.0032614406663924456,
            -0.001326529192738235,
            0.014584299176931381,
            -0.004583083093166351,
            -0.011797732673585415,
            0.019821615889668465,
            0.006061513442546129,
            0.0009031528607010841,
            0.024236038327217102,
            -0.020291611552238464,
            0.017033137381076813,
            -0.002064982196316123,
            -0.00026711123064160347,
            0.03337344527244568,
            0.01917348988354206,
            0.012546410784125328,
            -0.011562560684978962,
            0.001859404961578548,
            -0.009948628023266792,
            -0.009953330270946026,
            -0.027679726481437683,
            0.04283403605222702,
            0.012811469845473766,
            0.0027113165706396103,
            -0.011436116881668568,
            0.018798088654875755,
            0.014619947411119938,
            -0.027529221028089523,
            -0.01169863436371088,
            -0.027936168015003204,
            -0.012607238255441189,
            -0.011954987421631813,
            -0.031393036246299744,
            0.005293614696711302,
            0.028113320469856262,
            -0.006906734313815832,
            0.01223980076611042,
            0.007101922295987606,
            -0.01840817742049694,
            0.011290946044027805,
            0.023159030824899673,
            0.008498921059072018,
            -0.025664813816547394,
            -0.01880011335015297,
            -0.019917361438274384,
            0.00770633714273572,
            0.03142198547720909,
            0.030354054644703865,
            0.018413975834846497,
            -0.0031649465672671795,
            0.03323205187916756,
            -0.03160136565566063,
            -0.029461683705449104,
            -0.04424678906798363,
            -0.02963298372924328,
            -0.01151758898049593,
            -0.005750868935137987,
            0.01991632580757141,
            -0.022552596405148506,
            0.010304741561412811,
            0.0012210655258968472,
            -0.017918294295668602,
            -0.06214508041739464,
            -0.027195528149604797,
            0.04875519499182701,
            -0.0116730947047472,
            0.0010009013349190354,
            -0.008092375472187996,
            -0.02263736166059971,
            0.031217889860272408,
            0.0012898979475721717,
            0.02174052782356739,
            -0.021598579362034798,
            -0.0168568454682827,
            -0.009977110661566257,
            0.028256310150027275,
            -0.05571601167321205,
            -0.0064383880235254765,
            -0.028357675299048424,
            0.0017596015240997076,
            -0.04371977970004082,
            -0.06463196128606796,
            -0.014381961897015572,
            -0.03948985040187836,
            0.03714967891573906,
            -0.025202732533216476,
            -0.006057178135961294,
            0.022718966007232666,
            0.007167295552790165,
            -0.0005804251995868981,
            -0.010866583324968815,
            0.03885866329073906,
            0.021202223375439644,
            0.047294408082962036,
            0.000917125609703362,
            -0.013472388498485088,
            0.0029055122286081314,
            0.017739158123731613,
            -0.010534586384892464,
            -0.04292456805706024,
            0.018914958462119102,
            -0.03644741326570511,
            0.006539755035191774,
            -0.03263019025325775,
            0.009439208544790745,
            -0.02555348351597786,
            -0.02603212185204029,
            0.009535658173263073,
            0.005967860575765371,
            0.0008884415146894753,
            0.010187816806137562,
            0.009786655195057392,
            0.00033965680631808937,
            -0.04128655046224594,
            0.020493030548095703,
            0.002777996938675642,
            -0.019750647246837616,
            -0.026511134579777718,
            0.0010655031073838472,
            -0.023759525269269943,
            0.03030046448111534,
            0.006800026632845402,
            0.031115015968680382,
            0.03191191703081131,
            -0.016312681138515472,
            -0.029876817017793655,
            0.0011216240236535668,
            -0.004517367575317621,
            0.0269983671605587,
            -0.01646849699318409,
            0.02023593708872795,
            0.04648009315133095,
            0.04858503118157387,
            -0.021027207374572754,
            -0.01107937004417181,
            0.016929687932133675,
            -0.011892430484294891,
            -0.026293136179447174,
            0.006025541108101606,
            0.06054425239562988,
            0.006840527057647705,
            0.004081700462847948,
            0.02384251169860363,
            -0.05961044132709503,
            -0.041862256824970245,
            -0.017306949943304062,
            0.009087933227419853,
            -0.01190629880875349,
            0.00445503881201148,
            0.026266174390912056,
            0.020975036546587944,
            0.05350848659873009,
            -0.004472259432077408,
            0.016637660562992096,
            -7.575841300422326e-05,
            -0.04486539587378502,
            -0.024272996932268143,
            0.0006103011546656489,
            0.0025560040958225727,
            -0.002218370558694005,
            -0.03163117915391922,
            -0.02705054171383381,
            -0.003558262251317501,
            -0.022073514759540558,
            -0.013903463259339333,
            0.035355303436517715,
            -0.02540779486298561,
            -0.0026683160103857517,
            -4.774575063493103e-05,
            -0.05517195537686348,
            0.007752848323434591,
            -0.03334071859717369,
            0.016131194308400154,
            0.008173161186277866,
            0.018116548657417297,
            0.03717529773712158,
            -0.021148908883333206,
            -0.03460312262177467,
            -0.018574772402644157,
            -0.007397817447781563,
            0.024876102805137634,
            0.005234908778220415,
            -0.014160804450511932,
            -0.0012685490073636174,
            0.0231480710208416,
            0.05115916579961777,
            0.004105236381292343,
            0.0013380618765950203,
            0.02294563502073288,
            0.014372685924172401,
            -0.0020072392653673887,
            -0.0015813658246770501,
            0.014037244021892548,
            0.01676931045949459,
            0.01742124930024147,
            -0.029578495770692825,
            -0.018957708030939102,
            0.0015163985081017017,
            -0.015157441608607769,
            0.012309479527175426,
            0.04476158320903778,
            0.02099526859819889,
            0.0031008084770292044,
            -0.002233227016404271,
            -0.0020281898323446512,
            0.025173185393214226,
            -0.020648615434765816,
            -0.017934270203113556,
            -0.001147167175076902,
            -0.0034858747385442257,
            -0.03818408027291298,
            -0.005606221966445446,
            0.003678558859974146
        ],
        [
            -0.03080047108232975,
            0.0198710598051548,
            -0.023688726127147675,
            -0.037345096468925476,
            -0.03723142296075821,
            0.07763886451721191,
            -0.014300007373094559,
            -0.027001935988664627,
            -0.020261608064174652,
            0.030045676976442337,
            0.03136011213064194,
            -0.011131263338029385,
            -0.011898710392415524,
            -0.016005242243409157,
            -0.04117943346500397,
            0.03547227010130882,
            -0.006874056067317724,
            -0.0006996982847340405,
            -0.003341658040881157,
            -0.010665643960237503,
            -0.011418521404266357,
            -0.01898966357111931,
            -0.012996627949178219,
            0.011848141439259052,
            0.042977821081876755,
            0.001340169575996697,
            -0.06640125066041946,
            0.0065676928497850895,
            0.03073189966380596,
            0.018935954198241234,
            0.0025116123724728823,
            -0.01430380530655384,
            -0.00250728870742023,
            0.023213058710098267,
            0.01163000613451004,
            0.007314061746001244,
            0.017441589385271072,
            -0.008877147920429707,
            0.015253252349793911,
            0.0024730134755373,
            0.047910794615745544,
            0.01583506539463997,
            0.024542108178138733,
            0.006707801017910242,
            0.0014122558059170842,
            0.0024927807971835136,
            0.007368603255599737,
            0.024168888106942177,
            -0.00031435812707059085,
            0.02691979520022869,
            -0.045036446303129196,
            -0.016908282414078712,
            0.043739233165979385,
            0.028242669999599457,
            -0.039091818034648895,
            0.002371737966313958,
            0.002792554907500744,
            0.022480007261037827,
            -0.003974239341914654,
            0.02290135808289051,
            0.006183415185660124,
            0.009801357053220272,
            0.00850057601928711,
            -0.008489744737744331,
            -0.038338553160429,
            -0.020543405786156654,
            0.01500421017408371,
            0.019345764070749283,
            -0.016454467549920082,
            -0.012444197200238705,
            -0.012719938531517982,
            0.0034215564373880625,
            -0.03623233363032341,
            0.0025334260426461697,
            -0.005812636576592922,
            -0.009601891040802002,
            -0.024542763829231262,
            0.013478810898959637,
            -0.02536609023809433,
            -0.006410941481590271,
            0.0029953483026474714,
            0.01900460757315159,
            -0.008353852666914463,
            -0.016655398532748222,
            -0.008332202211022377,
            -0.012874259613454342,
            0.02444680780172348,
            -0.01240711472928524,
            -0.03768850862979889,
            -0.03219757601618767,
            0.038745634257793427,
            -0.018835928291082382,
            0.016487566754221916,
            0.0028237062506377697,
            0.0049105677753686905,
            -0.024242766201496124,
            -0.008560320362448692,
            -0.01497988123446703,
            -0.06656444072723389,
            0.015456359833478928,
            0.01876295916736126,
            0.012133420445024967,
            -0.028150783851742744,
            0.02911582589149475,
            0.018023556098341942,
            -0.0007085513789206743,
            -0.008117729797959328,
            0.04517229273915291,
            0.008786207064986229,
            0.0001964976981980726,
            0.011152436956763268,
            0.015711689367890358,
            -0.0035652401857078075,
            -0.03236273676156998,
            -0.01694405823945999,
            0.02059459127485752,
            -0.016375765204429626,
            0.002513293642550707,
            -0.016057653352618217,
            -0.003998511470854282,
            0.01384822279214859,
            -0.031152896583080292,
            -0.07476803660392761,
            0.012545064091682434,
            -0.03210798278450966,
            0.03422454372048378,
            0.027049129828810692,
            -0.018768897280097008,
            0.005765436217188835,
            0.005396476946771145,
            0.02940707840025425,
            0.015679065138101578,
            -0.04581743851304054,
            -0.019353995099663734,
            -0.0017535072984173894,
            -0.0024004222359508276,
            -0.038432806730270386,
            -0.010107913054525852,
            0.022065743803977966,
            0.007907986640930176,
            -0.02971186488866806,
            0.02407587505877018,
            0.021115653216838837,
            -0.004301322624087334,
            -0.008946084417402744,
            -0.036032821983098984,
            -0.05631289258599281,
            0.010438397526741028,
            -0.03873734921216965,
            0.03190014883875847,
            -0.027867501601576805,
            0.028068944811820984,
            -0.0452137365937233,
            -0.033410023897886276,
            -0.007731924764811993,
            0.02055472694337368,
            0.020930692553520203,
            -0.023358136415481567,
            -0.01359123457223177,
            0.006333712954074144,
            -0.023142604157328606,
            -0.0017433898756280541,
            0.016263170167803764,
            0.0027345127891749144,
            0.029091576114296913,
            -0.045631490647792816,
            -0.013453720137476921,
            -0.01720651425421238,
            -0.023726273328065872,
            -0.029297981411218643,
            -0.027973731979727745,
            -0.005055428948253393,
            0.038824357092380524,
            -0.015158518217504025,
            -0.026212478056550026,
            0.050896525382995605,
            0.0005151587538421154,
            -0.025046203285455704,
            0.02597617916762829,
            0.021984387189149857,
            0.05106157064437866,
            0.005943250376731157,
            -0.04571433737874031,
            -0.03760421276092529,
            -0.005041176453232765,
            -0.008519130758941174,
            -0.013691525906324387,
            -0.007966809906065464,
            0.005643894430249929,
            -0.0034667730797082186,
            0.0013633209746330976,
            -0.0011971107451245189,
            -0.022278456017374992,
            0.007458335720002651,
            0.012094894424080849,
            0.01649646647274494,
            0.006175030022859573,
            -0.021109551191329956,
            0.02911986969411373,
            -0.020418444648385048,
            -0.0034204174298793077,
            0.029023775830864906,
            0.01816246099770069,
            0.019743891432881355,
            -0.05487515777349472,
            -0.027129877358675003,
            -0.036477699875831604,
            -0.02128864824771881,
            -0.023933328688144684,
            0.0077437167055904865,
            1.1044845450669527e-05,
            -0.06968856602907181,
            0.0017781553324311972,
            -0.03891303762793541,
            0.017749682068824768,
            -0.005657655652612448,
            0.008668240159749985,
            -0.00027770231827162206,
            -0.010239893570542336,
            -0.016972577199339867,
            0.03361358121037483,
            0.02143864519894123,
            -0.017909226939082146,
            -0.029926970601081848,
            -0.024635709822177887,
            0.015094218775629997,
            -0.0009813006035983562,
            -0.027800293639302254,
            -0.04111155867576599,
            0.010108526796102524,
            -0.01770719327032566,
            0.04212263971567154,
            0.006593293510377407,
            -0.019876083359122276,
            0.04389440268278122,
            0.002857067622244358,
            -0.038976117968559265,
            -0.008083796128630638,
            0.014931355603039265,
            -0.013683835975825787,
            0.014697430655360222,
            0.02151753194630146,
            0.01126506831496954,
            -0.01416859496384859,
            0.0020082765258848667,
            -0.007306529209017754,
            -0.010525129735469818,
            0.03347211703658104,
            -0.026207394897937775,
            -0.017526965588331223,
            0.035646870732307434,
            -0.012825732119381428,
            -0.018124990165233612,
            -0.041872963309288025,
            -0.028091849759221077,
            0.027636440470814705,
            0.004440427757799625,
            0.0185556560754776,
            -0.011692068539559841,
            0.025890130549669266,
            0.006030077580362558,
            -0.07146912068128586,
            -0.05614517256617546,
            0.013145487755537033,
            0.0023287199437618256,
            0.011167911812663078,
            0.02328486740589142,
            0.001138852909207344,
            0.025369415059685707,
            0.009210709482431412,
            -0.004068074282258749,
            -0.011265357956290245,
            -0.01304857712239027,
            0.014569101855158806,
            0.029442744329571724,
            0.003073421772569418,
            0.010628852993249893,
            0.0016120373038575053,
            -0.0192177165299654,
            0.0028844333719462156,
            0.012471715919673443,
            -0.01546130608767271,
            0.0022093276493251324,
            0.007658484857529402,
            0.029522856697440147,
            -0.037062183022499084,
            -0.017280839383602142,
            0.015231648460030556,
            -0.010247605852782726,
            0.02628442645072937,
            -0.022516971454024315,
            0.002911909017711878,
            0.02053699642419815,
            0.030544770881533623,
            0.02825326845049858,
            -0.009513302706182003,
            0.014792084693908691,
            0.013201075606048107,
            -0.0428641177713871,
            -0.012011338025331497,
            -0.005519410595297813,
            0.012228352017700672,
            0.027624810114502907,
            -0.04149742051959038,
            0.02027689851820469,
            0.013623569160699844,
            0.043272264301776886,
            -0.0378575436770916,
            -0.006178607698529959,
            -0.0013632618356496096,
            0.02574741095304489,
            -0.016574155539274216,
            -0.007292415015399456,
            -0.036913804709911346,
            0.016776204109191895,
            0.052228495478630066,
            -0.005255109164863825,
            -0.026652252301573753,
            0.025843214243650436,
            -0.025842556729912758,
            0.02392452582716942,
            -0.054123926907777786,
            -0.01621982641518116,
            -0.028455330058932304,
            0.01641176827251911,
            -0.045778434723615646,
            0.019282806664705276,
            -0.023656003177165985,
            0.004786795470863581,
            -0.006819884292781353,
            0.01207505352795124,
            -0.013108366169035435,
            0.0072235423140227795,
            -0.009162507019937038,
            0.02173016592860222,
            -0.012392613105475903,
            -0.010798770934343338,
            0.03800563886761665,
            0.008828870952129364,
            -0.0016303815646097064,
            0.012885970063507557,
            -0.005677887238562107,
            0.031186213716864586,
            0.009757804684340954,
            0.015228599309921265,
            0.04387509822845459,
            0.03541550785303116,
            0.0029133390635252,
            -0.02239955961704254,
            0.006493991706520319,
            0.008997902274131775,
            -0.032086338847875595,
            0.0023743289057165384,
            0.002108941087499261,
            0.03265221416950226,
            -0.0077788084745407104,
            -0.017707930877804756,
            -0.0465591661632061,
            -0.02137128822505474,
            -0.03095710277557373,
            0.026683378964662552,
            -0.020446471869945526,
            -0.005417528096586466,
            0.022831518203020096,
            -0.007053511217236519,
            0.02372412197291851,
            -0.02511603757739067,
            -0.02747790515422821,
            0.03743186965584755,
            -0.003969347570091486,
            -0.020496424287557602,
            -0.02370341680943966,
            0.02435830421745777,
            -0.008238975889980793,
            0.004579166881740093,
            0.020012518391013145,
            0.015692535787820816,
            -0.00999713409692049,
            -0.012424365617334843,
            0.03606920316815376,
            -0.02141392230987549,
            -0.012230858206748962,
            -0.007567858323454857,
            0.001768179121427238,
            -0.04947923868894577,
            0.02492624893784523,
            -0.005001434125006199,
            0.006294627208262682,
            -0.03126290813088417,
            -0.0072378311306238174,
            0.01521538756787777,
            -0.003300860058516264,
            -0.031532835215330124,
            0.003751958254724741,
            -0.08968406915664673,
            -0.00635974807664752,
            -0.016632020473480225,
            0.0033096724655479193,
            0.008382191881537437,
            -0.003311110194772482,
            -0.03303450345993042,
            0.024150915443897247,
            0.0037035371642559767,
            -0.011711390689015388,
            -0.002616211771965027,
            -0.03491298854351044,
            0.01245552022010088,
            -0.005911033134907484,
            0.01125755999237299,
            0.027607498690485954,
            0.022724241018295288,
            -0.009686303324997425,
            -0.002631891518831253,
            0.004814751446247101,
            -0.06257836520671844,
            0.016686199232935905,
            0.018092580139636993,
            -0.01743157021701336,
            -0.0329078771173954,
            -0.012553513050079346,
            0.025213349610567093,
            -0.06647054105997086,
            0.02875499613583088,
            -0.020568285137414932,
            -0.02922048605978489,
            -0.005185479763895273,
            -0.01683495193719864,
            0.008646957576274872,
            0.005194526165723801,
            0.008592995814979076,
            -0.0035825634840875864,
            0.020921222865581512,
            0.03547098860144615,
            -0.04031176120042801,
            0.022126764059066772,
            -0.013620843179523945,
            0.0060126157477498055,
            0.03345596790313721,
            0.005580494180321693,
            0.03081006556749344,
            0.023107165470719337,
            0.038924917578697205,
            -0.006209049839526415,
            0.020680585876107216,
            0.0005314709851518273,
            0.012458824552595615,
            -0.02049112133681774,
            -0.04871121421456337,
            0.027905017137527466,
            -0.00655614398419857,
            0.023843830451369286,
            0.03865613415837288,
            -0.013197788037359715,
            0.022163424640893936,
            0.03866453468799591,
            0.02153269574046135,
            -0.03763336315751076,
            -0.028309959918260574,
            -0.019735947251319885,
            0.012260103598237038,
            0.020301641896367073,
            0.03984507918357849,
            -0.04428170248866081,
            0.018979180604219437,
            -0.05132264271378517,
            0.007654912769794464,
            -0.03311189264059067,
            0.015139169059693813,
            -0.02461046725511551,
            -0.011712978594005108,
            0.0034913853742182255,
            0.002028944669291377,
            0.007527515757828951,
            -0.006342647131532431,
            0.037955522537231445,
            0.003907422535121441,
            0.017392313107848167,
            0.00510728033259511,
            0.01332992222160101,
            0.005259211175143719,
            -0.009163876995444298,
            -0.014756486751139164,
            -0.002438225317746401,
            -0.030436603352427483,
            0.03537146747112274,
            -0.0025985362008213997,
            0.01229956466704607,
            0.015955615788698196,
            -0.0169922336935997,
            0.03978615254163742,
            0.00809362344443798,
            0.017889002338051796,
            -0.02522408962249756,
            0.022245017811655998,
            0.0618024542927742,
            0.012065748684108257,
            0.026907218620181084,
            -0.007627173792570829,
            0.028963731601834297,
            0.014511426910758018,
            -0.00040960145997814834,
            0.028543870896100998,
            0.0050695487298071384,
            -0.022727496922016144,
            -0.003953980281949043,
            0.002833935199305415,
            -0.011286543682217598,
            -0.001604689285159111,
            0.004720373544842005,
            0.04813380539417267,
            0.0068778363056480885,
            -0.011346601881086826,
            0.0008306897943839431,
            -0.009154990315437317,
            0.006891585420817137,
            0.024616988375782967,
            0.012332889251410961,
            -0.0005752539727836847,
            -0.0021617328748106956,
            -0.004186747130006552,
            -0.018759481608867645,
            0.011812852695584297,
            0.029327044263482094,
            -0.03920121490955353,
            -0.009792349301278591,
            -0.03391497954726219,
            -0.05311815068125725,
            -0.022486163303256035,
            0.020847365260124207,
            -0.03257731720805168,
            0.004532058257609606,
            -0.02481653541326523,
            -0.01774132438004017,
            0.010142664425075054,
            -0.012563086114823818,
            -0.021778525784611702,
            0.005648571066558361,
            -0.03911513462662697,
            -0.006882600020617247,
            0.02787512168288231,
            0.0027819001115858555,
            -0.029453875496983528,
            -0.04305071011185646,
            0.002778938040137291,
            0.007667806465178728,
            -0.039210572838783264,
            -0.012692326679825783,
            -0.028682073578238487,
            0.0059628416784107685,
            0.018879054114222527,
            0.023315325379371643,
            0.009719494730234146,
            -0.040659576654434204,
            -0.0009018444688990712,
            -0.04617255926132202,
            0.005907521583139896,
            0.0019410453969612718,
            -0.007041412405669689,
            -0.009246589615941048,
            0.00816186610609293,
            -0.030160296708345413,
            -0.013984202407300472,
            0.013095632195472717,
            -0.028953928500413895,
            -0.026044601574540138,
            0.0428641177713871,
            0.007069042883813381,
            -0.0035246587358415127,
            -0.014140233397483826,
            0.01315165776759386,
            0.013015218079090118,
            0.031765468418598175,
            -0.048139385879039764,
            -0.020690809935331345,
            -0.03815507888793945,
            -0.03329607471823692,
            0.006761129945516586,
            0.008937813341617584,
            -0.0005960735725238919,
            0.002431462751701474,
            0.0173979289829731,
            0.028614507988095284,
            0.02562418207526207,
            0.011323556303977966,
            -0.048507098108530045,
            0.025545159354805946,
            0.013189559802412987,
            0.0015739547088742256,
            -0.025927813723683357,
            0.043153222650289536,
            -0.008922457695007324,
            0.025700926780700684,
            0.012508982792496681,
            -0.010550943203270435,
            -0.00022285434533841908,
            -0.01399470679461956,
            0.004288776777684689,
            0.000613163283560425,
            -0.017474597319960594,
            -0.0011393645545467734,
            0.03306835889816284,
            -0.011391764506697655,
            0.012617413885891438,
            0.002255059778690338,
            -0.02599995583295822,
            -0.03314168378710747,
            -0.028254708275198936,
            0.019202424213290215,
            -0.021695684641599655,
            -0.015833089128136635,
            0.030886011198163033,
            -0.03144902363419533,
            -0.03985749930143356,
            -0.01577630266547203,
            0.024724101647734642,
            0.021572064608335495,
            -0.015499180182814598,
            -0.009949110448360443,
            -0.013081678189337254,
            -0.036043889820575714,
            -0.039993755519390106,
            -0.04027744010090828,
            -0.020379912108182907,
            -0.014977365732192993,
            0.034067925065755844,
            -0.07102616876363754,
            -0.016913767904043198,
            -0.042705830186605453,
            -0.01687602885067463,
            -0.030291635543107986,
            0.022156961262226105,
            0.01214616745710373,
            -0.023758169263601303,
            -0.011731913313269615,
            0.022908193990588188,
            -0.0319049172103405,
            0.009997616522014141,
            0.013626240193843842,
            0.004861010704189539,
            -0.023445652797818184,
            0.006121501792222261,
            0.00271749054081738,
            -0.009169486351311207,
            0.06716658920049667,
            0.028664279729127884,
            -0.03550304099917412,
            0.027192402631044388,
            -0.007436164189130068,
            -0.013967077247798443,
            0.030528409406542778,
            -0.03268342465162277,
            -0.018300868570804596,
            -0.0028158838395029306,
            -0.026417061686515808,
            -0.01925559900701046,
            0.02385750599205494,
            -0.006715287454426289,
            -0.0008009927114471793,
            0.0026099742390215397,
            0.023535599932074547,
            0.02840454690158367,
            0.012088825926184654,
            0.003808862529695034,
            -0.019023604691028595,
            -0.0037008682265877724,
            0.039535440504550934,
            -0.036593567579984665,
            -0.02439955249428749,
            -0.028112635016441345,
            -0.0036406470462679863,
            -0.04703178629279137,
            0.039354562759399414,
            -0.03877415135502815,
            0.03428816795349121,
            0.02520935609936714,
            0.005569632165133953,
            0.0034158891066908836,
            0.008149208500981331,
            0.017434347420930862,
            0.017167774960398674,
            -0.007760376203805208,
            -0.04359583556652069,
            0.03016858547925949,
            -0.0009123804047703743,
            -0.05257074162364006,
            -0.006890411488711834,
            0.03753412514925003,
            0.020569385960698128,
            0.03462100401520729,
            0.0074229855090379715,
            0.052940160036087036,
            -0.028434667736291885,
            0.020838778465986252,
            0.024643046781420708,
            0.015170078724622726,
            0.014195707626640797,
            0.012837201356887817,
            0.012623670510947704,
            -0.01621660403907299,
            0.008715033531188965,
            -0.019072718918323517,
            -0.02541872300207615,
            -0.0077818953432142735,
            -0.020271962508559227,
            -0.012980761006474495,
            -0.03677362576127052,
            0.01041605044156313,
            -0.028274329379200935,
            -0.02524583786725998,
            0.011648300103843212,
            -0.014975614845752716,
            0.006581393536180258,
            0.03235761448740959,
            -0.013261382468044758,
            -0.005828842520713806,
            0.03015432506799698,
            -0.012414183467626572,
            -0.005798392929136753,
            -0.031447503715753555,
            0.01549345813691616,
            -0.019305182620882988,
            -0.022570785135030746,
            -0.01578391343355179,
            -0.0011133024236187339,
            -0.01513118576258421,
            0.02780582383275032,
            -0.019332509487867355,
            0.031289175152778625,
            -0.015766775235533714,
            -0.03988196328282356,
            -0.008685348555445671,
            -0.022954851388931274,
            0.00484042800962925,
            0.004325384274125099,
            0.017676491290330887,
            0.0011247039074078202,
            0.0027975512202829123,
            -0.013634778559207916,
            -0.05482672527432442,
            -0.01844804361462593,
            -0.041812870651483536,
            -0.026810485869646072,
            0.033714842051267624,
            -0.025893880054354668,
            -0.010189947672188282,
            0.022686902433633804,
            -0.05720330774784088,
            0.002056566998362541,
            0.008667750284075737,
            0.01989777758717537,
            0.02724212221801281,
            -0.008862377144396305,
            0.02065027505159378,
            -0.023921119049191475,
            -0.030676456168293953,
            0.0309958066791296,
            -0.029569102451205254,
            -0.06782877445220947,
            0.029311537742614746,
            -0.003607372520491481,
            -0.01707403175532818,
            -0.004643937107175589,
            -0.012804369442164898,
            -0.015496127307415009,
            -0.028849422931671143,
            -0.02564995177090168,
            -0.013643154874444008,
            -0.01715916581451893,
            -0.0195072740316391,
            -0.0005746739916503429,
            -0.009880604222416878,
            -0.027623312547802925,
            -0.02020399644970894,
            0.0010618019150570035,
            -0.0029415918979793787,
            -0.025938306003808975,
            -0.04960815608501434,
            -0.047472525388002396,
            0.01741304248571396,
            -0.009485913440585136,
            0.032885413616895676,
            -0.02132609114050865,
            0.01744071952998638,
            0.0033583492040634155,
            -0.0425589419901371,
            0.02592223696410656,
            0.0487440750002861,
            0.0034024801570922136,
            -0.01984236389398575,
            0.015461304225027561,
            -0.024108007550239563,
            -0.005149488802999258,
            0.0469491109251976,
            -0.015333748422563076,
            -0.025558222085237503,
            -0.034743666648864746,
            -0.06688707321882248,
            0.022905642166733742,
            0.004700358957052231,
            -0.03566964343190193,
            -0.0022673055063933134,
            -0.0072961426340043545,
            -0.04033465310931206,
            -0.017601516097784042,
            -0.024732613936066628,
            0.03288331627845764,
            -0.005856908857822418,
            0.03799803555011749,
            -0.0008763978257775307,
            0.032764680683612823,
            0.01319776102900505,
            -0.040697138756513596,
            0.023417487740516663,
            -0.03280622512102127,
            0.01362042035907507,
            0.020736675709486008,
            -0.026327386498451233,
            0.024393457919359207,
            -0.012115495279431343,
            0.004774956032633781,
            0.02481398545205593,
            -0.00822779443114996,
            0.003925052471458912,
            0.0455353707075119,
            -0.016619384288787842,
            -0.03187664970755577,
            -0.031393345445394516,
            -0.028839826583862305,
            -0.008824730291962624,
            0.024989718571305275,
            0.008764061145484447,
            0.015057285316288471,
            0.0027745028492063284,
            -0.007886776700615883,
            -0.021430127322673798,
            -0.0258172694593668,
            0.024584978818893433,
            0.006223703734576702,
            -0.011529701761901379,
            -0.011427615769207478,
            0.016414841637015343,
            0.013300466351211071,
            -0.010064688511192799,
            -0.017047608271241188,
            -0.018374823033809662,
            -0.0028584774117916822,
            -0.01049042772501707,
            0.021769380196928978,
            0.0085469214245677,
            -0.015431707724928856,
            0.002160131698474288,
            -0.0011031770845875144,
            0.03434016555547714,
            -0.018399646505713463,
            0.029965752735733986,
            0.009795739315450191,
            -0.00789442379027605,
            -0.024527451023459435,
            -0.0023083616979420185,
            0.03241274878382683,
            0.024976534768939018,
            0.009538648650050163,
            0.036996591836214066,
            -0.005176127422600985,
            -0.005217263009399176,
            0.01412267703562975,
            0.0023310224059969187,
            0.00934771727770567,
            -0.0010116808116436005,
            0.006785838399082422,
            0.017752451822161674,
            -0.02185501903295517,
            0.01120874285697937,
            0.050043340772390366,
            0.03416883200407028,
            0.04062264412641525,
            0.019436562433838844,
            -0.04955918341875076,
            0.020703211426734924,
            0.0007695741951465607,
            -0.018421031534671783,
            -0.04117685183882713,
            -0.05875629559159279,
            -0.032259564846754074,
            -0.01134919561445713,
            0.019149484112858772,
            -0.022973069921135902,
            0.0021442221477627754,
            0.010996884666383266,
            0.019544683396816254,
            -0.01666105166077614,
            -0.0035918254870921373,
            0.01855461113154888,
            -0.02355233021080494,
            -0.001412157784216106,
            0.0006778478855267167,
            0.022757893428206444,
            -0.0364316888153553,
            0.014284891076385975,
            -0.014440053142607212,
            -0.024009784683585167,
            -0.007075889967381954,
            -0.03379281982779503,
            0.0286716241389513,
            0.015408018603920937,
            -0.012057569809257984,
            -0.0180490892380476,
            0.007741158828139305,
            -0.04388312250375748,
            -0.02298545464873314,
            -0.027038976550102234,
            0.000818745931610465,
            0.021603036671876907,
            0.015009413473308086,
            0.011972598731517792,
            0.0070181372575461864,
            -0.01996602676808834,
            0.030895734205842018,
            -0.0031206270214170218,
            -0.02098502404987812,
            0.016062989830970764,
            0.007658189628273249,
            -0.0190766341984272,
            -0.015906058251857758,
            0.01785724051296711,
            -0.03476106747984886,
            0.001488318433985114,
            0.08479278534650803,
            -0.019373711198568344,
            -0.020816883072257042,
            0.009166712872684002,
            0.015679288655519485,
            -0.0265901330858469,
            0.03632957488298416,
            -0.06392740458250046,
            -0.01333375833928585,
            -0.021814169362187386,
            0.010950719006359577,
            0.004634651821106672,
            0.029455864802002907,
            -0.011504515074193478,
            -0.004764493089169264,
            -0.007752804551273584,
            -0.011969653889536858,
            0.001597866415977478,
            -0.015278490260243416,
            -0.009371119551360607,
            0.05296814814209938,
            -0.011070608161389828,
            -0.01210042741149664,
            -0.022805245593190193,
            0.01770223118364811,
            -0.03489408269524574,
            0.008097036741673946,
            -0.01281878724694252,
            0.010794182308018208,
            -0.0328722782433033,
            -0.004177868831902742,
            0.020171063020825386,
            -0.0019931879360228777,
            0.022779151797294617,
            -0.0005728842807002366,
            -0.027182456105947495,
            0.054518427699804306,
            -0.007211097050458193,
            0.014792359434068203,
            -0.050249408930540085,
            -0.011138583533465862,
            0.015082666650414467,
            0.024248400703072548,
            -0.018179338425397873,
            0.007977333851158619,
            -0.03432517498731613,
            -0.032615505158901215,
            -0.016793951392173767,
            0.026475058868527412,
            -0.024942249059677124,
            -0.007058863062411547,
            0.01753951609134674,
            0.02155010774731636,
            0.011515885591506958,
            0.02605227380990982,
            0.007780750747770071,
            -0.038087550550699234,
            -0.021825475618243217,
            0.018508397042751312,
            0.04821193590760231,
            -0.017304355278611183,
            0.019293297082185745,
            -0.0047265589237213135,
            0.033045634627342224,
            -0.04257623478770256,
            -0.030443986877799034,
            0.0076690977439284325,
            0.02675076574087143,
            -0.03281654790043831,
            -0.04620343819260597,
            0.018761951476335526,
            -0.00748048909008503,
            0.012740169651806355,
            -0.022616900503635406,
            -0.039158593863248825,
            0.004123108461499214,
            0.012423461303114891,
            -0.02423521690070629,
            -0.017233680933713913,
            -0.03659181669354439,
            0.05088489502668381,
            -0.001796453958377242,
            -0.009910071268677711,
            0.021854326128959656,
            0.007327280007302761,
            -0.03656928613781929,
            0.011715002357959747,
            -0.027366381138563156,
            0.012814891524612904,
            -0.03991672769188881,
            -0.03070092760026455,
            0.0007195912767201662,
            0.01570182293653488,
            0.002108736429363489,
            -0.023188121616840363,
            -0.020034000277519226,
            0.024734526872634888
        ],
        [
            0.015533775091171265,
            -0.02724377065896988,
            -0.019678151234984398,
            -0.010874890722334385,
            -0.02458079718053341,
            -0.0018149854149669409,
            0.0014659285079687834,
            -0.015705984085798264,
            -0.007006450556218624,
            -0.011878075078129768,
            0.00421184441074729,
            0.002948175184428692,
            0.026122309267520905,
            0.00355281843803823,
            -0.02118268981575966,
            -0.01789318583905697,
            -0.0087532177567482,
            -0.006587211042642593,
            0.0283434446901083,
            0.03237470984458923,
            -0.030725784599781036,
            0.025404106825590134,
            0.013329951092600822,
            -0.00800780113786459,
            -0.03326022997498512,
            -0.028491128236055374,
            -0.03088170848786831,
            0.014823867939412594,
            -0.008106999099254608,
            -0.011550070717930794,
            0.012273845262825489,
            0.022161047905683517,
            0.01245491299778223,
            -0.0017583499429747462,
            -0.01945623569190502,
            0.044235870242118835,
            -0.011158681474626064,
            0.007419032976031303,
            0.011297944001853466,
            -0.011910256929695606,
            0.003277840791270137,
            0.006014576181769371,
            -0.011094214394688606,
            0.025898663327097893,
            0.02272670716047287,
            0.003351249499246478,
            -0.015090015716850758,
            -0.00606550881639123,
            0.0072600929997861385,
            0.0261982511729002,
            0.04615582898259163,
            0.004000909626483917,
            0.001707635703496635,
            0.002237579319626093,
            -0.024315226823091507,
            0.004096727818250656,
            -0.010538937523961067,
            0.03360995650291443,
            0.009999909438192844,
            -0.0004391742404550314,
            -0.001309884712100029,
            -0.028266124427318573,
            -0.003078135196119547,
            -0.03774424269795418,
            -0.0054948944598436356,
            -0.007548408582806587,
            -0.005258818622678518,
            0.0002088016626657918,
            0.02009035460650921,
            -0.02696395479142666,
            0.02978501468896866,
            0.01321540679782629,
            -0.01724827289581299,
            -0.019613558426499367,
            0.03717697411775589,
            -0.010252252221107483,
            0.0023299166932702065,
            -0.02216421440243721,
            0.019319459795951843,
            0.030264845117926598,
            0.025165945291519165,
            -0.023044096305966377,
            0.014999650418758392,
            0.020076405256986618,
            0.03168009966611862,
            0.002478667302057147,
            0.020590616390109062,
            -0.009449170902371407,
            -0.024177594110369682,
            1.952915908987052e-06,
            0.012960811145603657,
            0.011325621046125889,
            -0.006320879328995943,
            0.021526167169213295,
            -0.0339573472738266,
            0.01626327820122242,
            0.01720636710524559,
            -0.01969909481704235,
            0.04787192493677139,
            0.010832056403160095,
            0.020385833457112312,
            -0.024941682815551758,
            -0.008560191839933395,
            -0.016577783972024918,
            0.02241181582212448,
            0.003124069422483444,
            0.0020262282341718674,
            -0.020672935992479324,
            0.02976067177951336,
            -0.013700922019779682,
            0.022274862974882126,
            0.0009161964990198612,
            0.00405686954036355,
            0.006947600748389959,
            -0.032298434525728226,
            0.008179180324077606,
            -0.022138820961117744,
            -0.0402442142367363,
            0.019482264295220375,
            -0.033416733145713806,
            -0.0234061311930418,
            -0.015805359929800034,
            -0.018468182533979416,
            -0.012690916657447815,
            0.029602162539958954,
            -0.021611815318465233,
            -0.00913873128592968,
            -0.021096717566251755,
            -0.004126129671931267,
            0.024358311668038368,
            0.034059807658195496,
            -0.03774995356798172,
            -0.022461658343672752,
            -0.003245551837608218,
            0.001205803593620658,
            0.018028605729341507,
            0.01400176715105772,
            -0.03527103364467621,
            0.013602965511381626,
            -0.00769325764849782,
            -0.0007348415092565119,
            -0.024591587483882904,
            0.02973579801619053,
            -0.010756298899650574,
            -0.020238064229488373,
            0.017318543046712875,
            -0.007353764493018389,
            -0.031588029116392136,
            -0.021357597783207893,
            0.032624565064907074,
            0.019429916515946388,
            0.029926585033535957,
            -0.024803796783089638,
            0.01265017967671156,
            0.019832368940114975,
            -0.011854635551571846,
            -0.004050124436616898,
            -0.013786258175969124,
            0.01937572844326496,
            0.010324817150831223,
            0.02973586693406105,
            0.022177690640091896,
            0.018414674326777458,
            -0.009091271087527275,
            -0.022615231573581696,
            0.005251134280115366,
            0.01791294850409031,
            0.019358519464731216,
            0.008060961961746216,
            0.01949986442923546,
            0.006761893164366484,
            -0.0022341045551002026,
            0.04778958484530449,
            -0.03564274683594704,
            -0.012660045176744461,
            0.03204252943396568,
            0.001810138113796711,
            0.016246043145656586,
            0.028084447607398033,
            -0.019461892545223236,
            -0.0022869447711855173,
            -0.0112606817856431,
            0.022264065220952034,
            0.029200533404946327,
            -0.005765188951045275,
            0.006866989657282829,
            0.007374233100563288,
            0.023576106876134872,
            0.017115727066993713,
            0.01518571563065052,
            -0.009021569974720478,
            -0.029891256242990494,
            -0.011388582177460194,
            0.03461851924657822,
            -0.003947424236685038,
            -0.015632078051567078,
            -0.0035363209899514914,
            0.02662254497408867,
            -0.003017719369381666,
            0.00014720681065227836,
            -0.04397355020046234,
            0.032144080847501755,
            -0.04296613484621048,
            0.029581300914287567,
            0.02557991072535515,
            -0.034503478556871414,
            -0.007460367400199175,
            -0.024302847683429718,
            0.02519955113530159,
            0.019705424085259438,
            -0.017457924783229828,
            -0.012407932430505753,
            0.006035953760147095,
            -0.02128119394183159,
            0.0006223368691280484,
            0.013466720469295979,
            0.016963308677077293,
            0.02860601432621479,
            0.03404099494218826,
            -0.0009390179184265435,
            -0.014534521847963333,
            0.033383578062057495,
            0.0025059611070901155,
            -0.027639800682663918,
            0.0005589126376435161,
            -0.007718238513916731,
            -0.019209714606404305,
            -0.010256647132337093,
            -0.025466006249189377,
            0.0328204520046711,
            -0.020716633647680283,
            -0.00480246776714921,
            -0.014442291110754013,
            0.020916864275932312,
            0.006067843642085791,
            -0.008427495136857033,
            -0.024097619578242302,
            -0.0057294308207929134,
            0.017938531935214996,
            -0.014262421987950802,
            0.013464808464050293,
            -0.009947540238499641,
            0.028966227546334267,
            -0.010235904715955257,
            -0.006795462220907211,
            0.026875371113419533,
            0.0037057765293866396,
            0.014880187809467316,
            -0.016765732318162918,
            0.017052505165338516,
            0.0026238257996737957,
            -0.003810497699305415,
            -0.019782915711402893,
            -0.02624025009572506,
            0.011798928491771221,
            -0.019626634195446968,
            -0.004282001871615648,
            -0.02894536219537258,
            -0.026165233924984932,
            -0.0058349682949483395,
            0.010549227707087994,
            0.02622051164507866,
            -0.005311743821948767,
            0.022199740633368492,
            0.023983808234333992,
            0.036854907870292664,
            -0.028047747910022736,
            0.029903549700975418,
            0.009174995124340057,
            0.015756068751215935,
            -0.021001916378736496,
            -0.028725214302539825,
            0.002130055334419012,
            -0.0009201234206557274,
            0.005983793642371893,
            0.03666672855615616,
            -0.028898291289806366,
            -0.013774892315268517,
            -0.00788870733231306,
            0.009639441967010498,
            0.015915483236312866,
            -0.03255225345492363,
            0.01863081380724907,
            0.015433551743626595,
            0.010993528179824352,
            0.03130119666457176,
            0.01585182175040245,
            0.013995837420225143,
            0.020895015448331833,
            0.02300603874027729,
            -0.028689701110124588,
            -0.0020055591594427824,
            -0.019267113879323006,
            -0.019973861053586006,
            -0.001765945227816701,
            -0.02792985551059246,
            -0.01327197253704071,
            -0.02362283132970333,
            -0.011427929624915123,
            0.01861695945262909,
            -0.0022627890575677156,
            -0.017503440380096436,
            0.030699456110596657,
            0.025256404653191566,
            -0.0007748359930701554,
            0.03694502264261246,
            0.03821961209177971,
            0.01672980561852455,
            0.017161626368761063,
            -0.023496154695749283,
            0.0032557444646954536,
            0.010247735306620598,
            -0.00690075708553195,
            0.0024274210445582867,
            -0.014425165951251984,
            0.01327188964933157,
            -0.0011429586447775364,
            -0.008845241740345955,
            -0.01929231733083725,
            -0.0213385671377182,
            0.0011565971653908491,
            0.007413448300212622,
            -0.012076294049620628,
            0.02546943537890911,
            -0.005765371490269899,
            0.026894021779298782,
            -0.024411097168922424,
            0.02504853531718254,
            0.027846721932291985,
            0.01756291650235653,
            0.011196739040315151,
            -0.00123947998508811,
            0.00022762037406209856,
            -0.02123355120420456,
            -0.0062947748228907585,
            -0.012109186500310898,
            -0.029593288898468018,
            0.014354027807712555,
            0.008909310214221478,
            -0.01182278711348772,
            0.00542519660666585,
            0.018401069566607475,
            -0.002116752788424492,
            -0.008527416735887527,
            0.016355959698557854,
            0.019180364906787872,
            0.01702077127993107,
            -0.020484887063503265,
            -0.013592013157904148,
            -0.002725308993831277,
            0.006227526348084211,
            -0.033789727836847305,
            0.026291772723197937,
            -0.02160986326634884,
            -0.01314621139317751,
            -0.026561109349131584,
            -0.018504958599805832,
            -0.02769799903035164,
            0.010884457267820835,
            -0.007353419903665781,
            0.03114418499171734,
            -0.024794261902570724,
            -0.02082696557044983,
            0.005784207955002785,
            0.019788235425949097,
            0.024362578988075256,
            0.02534549869596958,
            -0.0022539799101650715,
            -0.02639569342136383,
            -0.01594574563205242,
            0.03655174374580383,
            0.03147078678011894,
            -0.010263808071613312,
            -0.024986732751131058,
            0.0037320898845791817,
            -0.012677017599344254,
            -0.022126398980617523,
            -0.015201075933873653,
            0.009386971592903137,
            0.009122681804001331,
            0.0007607399020344019,
            -0.013656877912580967,
            -0.024226900190114975,
            0.003194245044142008,
            0.00999496504664421,
            0.008664557710289955,
            0.002817164408043027,
            -0.008634771220386028,
            -0.027856921777129173,
            0.0033420794643461704,
            0.00559208681806922,
            0.010474632494151592,
            0.006531235296279192,
            0.017026260495185852,
            -0.07347886264324188,
            0.007437405176460743,
            0.015110081061720848,
            -0.016730880364775658,
            0.00374598684720695,
            -0.004845338873565197,
            -0.029902396723628044,
            -0.004804070573300123,
            0.012333198450505733,
            -0.016242576763033867,
            -0.01178986020386219,
            0.008836611174046993,
            -0.012401564046740532,
            0.04251503944396973,
            0.031315743923187256,
            0.0004251173522789031,
            -0.012238215655088425,
            -0.02388664521276951,
            0.011345750652253628,
            -0.02373214066028595,
            0.009437656961381435,
            -0.0017044186824932694,
            0.017968779429793358,
            -0.0190159622579813,
            0.020977197214961052,
            -0.017278039827942848,
            -0.03254945948719978,
            0.026000365614891052,
            -0.006878518965095282,
            0.013732780702412128,
            0.0012452147202566266,
            -0.0039655305445194244,
            0.0261228010058403,
            0.0020286550279706717,
            -0.0262749120593071,
            -0.0035412113647907972,
            -0.005911769811064005,
            0.01547359861433506,
            0.018686959519982338,
            -0.03344855457544327,
            -0.023991934955120087,
            0.01039679441601038,
            0.0189292524009943,
            -0.02661840245127678,
            -0.007172041572630405,
            -0.0173108521848917,
            -0.022116193547844887,
            0.022560512647032738,
            0.02001362107694149,
            -0.010374771431088448,
            0.026138726621866226,
            0.03732036054134369,
            0.03034450113773346,
            0.05776837468147278,
            -0.042786192148923874,
            0.019827472046017647,
            0.014388317242264748,
            -0.01370306871831417,
            0.005221896804869175,
            0.0031134120654314756,
            0.022179778665304184,
            0.027345918118953705,
            -0.015011562034487724,
            -0.03608298674225807,
            0.008250185288488865,
            0.002750229788944125,
            -0.011331336572766304,
            0.0345429927110672,
            0.0014155851677060127,
            0.030023252591490746,
            -0.02500210702419281,
            -0.026380060240626335,
            -0.022698944434523582,
            0.007247564848512411,
            -0.018314987421035767,
            0.021063683554530144,
            0.0014733325224369764,
            -0.020563120022416115,
            -0.01560734398663044,
            -0.02063162997364998,
            0.0003028343489859253,
            0.018500342965126038,
            -0.02664838917553425,
            -0.009821568615734577,
            -0.0036530180368572474,
            -0.01669381558895111,
            0.012502115219831467,
            0.007616003975272179,
            0.059593141078948975,
            -0.009345680475234985,
            0.010711816139519215,
            0.027006443589925766,
            -0.03027922846376896,
            0.013465015217661858,
            -0.03920658677816391,
            -0.02173122577369213,
            0.004117182921618223,
            0.031347233802080154,
            -0.023273969069123268,
            -0.03411997854709625,
            -0.019589051604270935,
            0.00716411042958498,
            0.04169821739196777,
            0.02754821442067623,
            -0.013748668134212494,
            -0.014667646028101444,
            0.024136343970894814,
            0.00597212091088295,
            0.035443950444459915,
            -0.028990454971790314,
            -0.02411082573235035,
            0.026080572977662086,
            0.006139733828604221,
            -0.019413186237215996,
            0.0005974334198981524,
            -0.02074846811592579,
            0.007851284928619862,
            -0.000240199122345075,
            0.005794693250209093,
            -0.029545918107032776,
            -0.006303983740508556,
            -0.009511412121355534,
            0.03383754566311836,
            0.013461763970553875,
            0.023660041391849518,
            0.032168805599212646,
            -0.006668080110102892,
            -0.02620905451476574,
            -0.01929418183863163,
            -0.0018992397235706449,
            0.0571259930729866,
            0.005261316895484924,
            -0.04647619277238846,
            -0.020599061623215675,
            -0.002765158424153924,
            0.021337633952498436,
            0.02460300177335739,
            -0.0376342236995697,
            0.0007382120820693672,
            0.019762303680181503,
            0.023936307057738304,
            -0.028151188045740128,
            0.014230404980480671,
            -0.003548010718077421,
            0.019306523725390434,
            0.0014180527068674564,
            -0.026775378733873367,
            -0.018490750342607498,
            -0.02574947290122509,
            -0.0001913121377583593,
            0.01858830451965332,
            -0.0020687817595899105,
            0.0029010234866291285,
            -0.0014936832012608647,
            -0.00837060995399952,
            0.029811521992087364,
            0.02207140065729618,
            0.006329976487904787,
            0.012096031568944454,
            -0.003357894951477647,
            0.025407932698726654,
            -0.025842629373073578,
            0.028841713443398476,
            -0.002339642494916916,
            -0.027652498334646225,
            0.023944946005940437,
            -0.005769478157162666,
            -0.029618384316563606,
            0.01082564890384674,
            0.010195651091635227,
            0.030903063714504242,
            -0.016178634017705917,
            0.025959718972444534,
            0.005085428711026907,
            0.015350958332419395,
            -0.021191589534282684,
            0.02889712154865265,
            -0.019188998267054558,
            0.006976244039833546,
            0.025760913267731667,
            0.012183086946606636,
            -0.011964559555053711,
            -0.003136824583634734,
            0.002258673310279846,
            -0.026632295921444893,
            -0.004282642155885696,
            -0.020441774278879166,
            0.01366394478827715,
            -0.013627845793962479,
            0.03681962564587593,
            0.026417991146445274,
            0.028514044359326363,
            0.010904385708272457,
            0.007789544761180878,
            0.0029033757746219635,
            0.0005220351158641279,
            0.03509657457470894,
            0.017628777772188187,
            -0.02439415454864502,
            -0.011407132260501385,
            0.028739530593156815,
            -0.021554015576839447,
            0.01588468998670578,
            0.03864458203315735,
            0.025590190663933754,
            -0.013810945674777031,
            -0.0014037091750651598,
            0.028272725641727448,
            -0.008312784135341644,
            -0.025771494954824448,
            -0.012028153985738754,
            -0.021840039640665054,
            0.013716692104935646,
            -0.006063827779144049,
            -0.015015549957752228,
            0.021967705339193344,
            -0.01655234582722187,
            0.017392819747328758,
            -0.01803656667470932,
            -0.02814132161438465,
            -0.016660893335938454,
            -0.035620078444480896,
            -0.016602933406829834,
            0.01183111872524023,
            -0.012070135213434696,
            -0.054938964545726776,
            -0.005782210733741522,
            -0.008110255934298038,
            0.0006813406944274902,
            -0.032762929797172546,
            0.001809425069950521,
            -0.013996832072734833,
            -0.002810533158481121,
            0.026166167110204697,
            -0.015126078389585018,
            0.011191455647349358,
            0.01694480888545513,
            -0.011362836696207523,
            0.0011545589659363031,
            0.022302908822894096,
            -0.026860635727643967,
            -0.016024967655539513,
            -0.003548647742718458,
            0.030103731900453568,
            -0.007589633110910654,
            -0.01739555038511753,
            0.012055170722305775,
            0.007636563386768103,
            -0.002619227860122919,
            0.0004950191359966993,
            0.007524416316300631,
            -0.006113460753113031,
            0.03287634253501892,
            0.018017778173089027,
            -0.0077315629459917545,
            -0.0008105568704195321,
            -0.013406166806817055,
            -0.010686946101486683,
            -0.02381632849574089,
            0.034218888729810715,
            -0.0014879116788506508,
            0.007254869677126408,
            -0.01207667589187622,
            -0.03000986948609352,
            -0.004418633878231049,
            0.025559106841683388,
            -0.005284016020596027,
            -0.02441791072487831,
            0.008848978206515312,
            -0.029169462621212006,
            0.029889240860939026,
            0.005910116247832775,
            0.0036304674576967955,
            -0.016350898891687393,
            -0.015785686671733856,
            0.0005324772209860384,
            0.04546552151441574,
            0.003262267215177417,
            0.02529105544090271,
            0.029319625347852707,
            0.015864456072449684,
            -0.013740042224526405,
            -0.011011289432644844,
            -0.004038389306515455,
            0.021343890577554703,
            0.0034341823775321245,
            -0.017503170296549797,
            -0.014637917280197144,
            -0.029934491962194443,
            -0.011586112901568413,
            -0.02856023795902729,
            0.03934372216463089,
            0.0012090053642168641,
            -0.04101569950580597,
            -0.02196112461388111,
            0.011099315248429775,
            -0.011589203961193562,
            -0.030844809487462044,
            -0.010288450866937637,
            -0.021728219464421272,
            -0.00353018706664443,
            0.01762067712843418,
            -0.023989975452423096,
            0.027630269527435303,
            0.02304595150053501,
            0.015565161593258381,
            -0.009063832461833954,
            0.0011331632267683744,
            -0.003899928415194154,
            -0.013827751390635967,
            0.0015902373706921935,
            -0.002239248249679804,
            -0.012252083979547024,
            0.0010426572989672422,
            -0.02192143350839615,
            -0.007805986795574427,
            -0.009159890003502369,
            -0.01661328412592411,
            -0.03477242961525917,
            -0.019160328432917595,
            -0.010099867358803749,
            0.004413448739796877,
            0.007042002398520708,
            0.03317476063966751,
            -0.022078532725572586,
            -0.033765699714422226,
            -0.035522617399692535,
            -0.0032149599865078926,
            0.026727188378572464,
            0.007216956466436386,
            0.03413395211100578,
            0.02182716131210327,
            0.02806956134736538,
            0.007353761233389378,
            0.005648615770041943,
            0.011927883140742779,
            -0.04821028187870979,
            -0.009809254668653011,
            -0.004840663634240627,
            0.020090922713279724,
            0.015536325052380562,
            0.009981642477214336,
            -0.02155592106282711,
            -0.023307399824261665,
            -0.003174749668687582,
            -0.016710354015231133,
            -0.01247686892747879,
            0.03625733032822609,
            -0.009201617911458015,
            -0.01798073574900627,
            -0.00826839730143547,
            0.04486041143536568,
            -0.020192628726363182,
            -0.02532065473496914,
            0.012979738414287567,
            0.011545298621058464,
            0.0030108042992651463,
            0.012206287123262882,
            -0.006968463771045208,
            0.00549709890037775,
            -0.012665973044931889,
            -0.00043785679736174643,
            0.03316442668437958,
            -0.00034307833993807435,
            -0.004182200878858566,
            -0.025837378576397896,
            0.025073541328310966,
            -0.054314348846673965,
            0.02078585885465145,
            0.039589766412973404,
            0.0024252000730484724,
            0.011592886410653591,
            -0.0013392673572525382,
            0.0037915329448878765,
            0.013896124437451363,
            -0.03619803488254547,
            0.04137503728270531,
            0.009730175137519836,
            0.020922396332025528,
            0.047853559255599976,
            0.025536388158798218,
            0.03542671352624893,
            -0.004555963911116123,
            -0.0008068904862739146,
            0.011716971173882484,
            -0.027868274599313736,
            -0.018911229446530342,
            0.0322720967233181,
            -0.007989696227014065,
            -0.02236885577440262,
            -0.027158863842487335,
            0.015835070982575417,
            0.024584900587797165,
            0.006056193727999926,
            -0.023548195138573647,
            -0.03070826083421707,
            -0.022837501019239426,
            -0.0027944669127464294,
            -0.01348148100078106,
            0.03157985955476761,
            -9.471138764638454e-05,
            -0.029966259375214577,
            -0.0016584775876253843,
            -0.013634015806019306,
            -0.02404077723622322,
            -0.00018644070951268077,
            -0.02114148624241352,
            0.002354559488594532,
            0.027104314416646957,
            0.023187659680843353,
            -0.02826092019677162,
            -0.019920868799090385,
            0.0030762345995754004,
            -0.0005389136495068669,
            0.012521754018962383,
            -0.0026961013209074736,
            -0.03752439469099045,
            -0.021580010652542114,
            0.026743082329630852,
            -0.016174841672182083,
            -0.012591779232025146,
            0.032783929258584976,
            0.015696143731474876,
            0.0017541967099532485,
            -0.012926696799695492,
            0.0012545341160148382,
            0.007367650978267193,
            -0.008959643542766571,
            -0.01945866085588932,
            0.01916571892797947,
            0.02363383211195469,
            -0.043117087334394455,
            -0.007281484082341194,
            0.0209132619202137,
            0.031120022758841515,
            0.013742141425609589,
            -0.005387448240071535,
            -0.027817238122224808,
            -0.0014936703955754638,
            -0.021474462002515793,
            0.01835349015891552,
            -0.02329016663134098,
            0.006396708078682423,
            0.006609383039176464,
            0.01746019907295704,
            0.03731783106923103,
            -0.013940822333097458,
            -0.016354134306311607,
            0.019159991294145584,
            0.001852918998338282,
            -0.006266919896006584,
            0.026149798184633255,
            -0.0052979690954089165,
            0.02125266194343567,
            0.02467978000640869,
            0.01487521082162857,
            -0.027834564447402954,
            -0.0012644525850191712,
            0.018594397231936455,
            0.0018701754743233323,
            0.02398049831390381,
            -0.022316161543130875,
            0.023282663896679878,
            0.010330592282116413,
            0.002298756269738078,
            -0.0018645162926986814,
            -0.012794696725904942,
            -0.004890867043286562,
            -0.01631310023367405,
            -0.026584388688206673,
            -0.022685416042804718,
            -0.020332355052232742,
            0.0004971849848516285,
            0.009088445454835892,
            -0.009113715961575508,
            0.029254384338855743,
            0.00984111987054348,
            0.006580343935638666,
            -0.0404183492064476,
            -0.015490483492612839,
            0.009758073836565018,
            -0.02683200314640999,
            0.00454622833058238,
            -0.010582971386611462,
            0.021325480192899704,
            -0.02429848164319992,
            -0.011784867383539677,
            -0.000981473014689982,
            0.002900894032791257,
            0.008887934498488903,
            -0.010457083582878113,
            0.0016032764688134193,
            0.008891875855624676,
            0.039173390716314316,
            -0.027547534555196762,
            -0.027670156210660934,
            0.01675863191485405,
            -0.00870517361909151,
            -0.0003910026862286031,
            -0.023232413455843925,
            0.03515725955367088,
            0.0041651553474366665,
            -0.024775423109531403,
            -0.006638907827436924,
            0.018278101459145546,
            0.0017932250630110502,
            -0.03922009840607643,
            -0.021182050928473473,
            -0.004700490273535252,
            0.0238548144698143,
            0.03910282999277115,
            -0.013208575546741486,
            0.013091652654111385,
            -0.030680280178785324,
            -0.018248068168759346,
            -0.00859681237488985,
            -0.023720601573586464,
            -0.003815578995272517,
            0.0008994881063699722,
            -0.022039439529180527,
            -0.0023455130867660046,
            0.024059360846877098,
            -0.008869768120348454,
            0.01729493774473667,
            0.0021412321366369724,
            0.023284204304218292,
            0.017027700319886208,
            0.02437019906938076,
            0.02866058237850666,
            -0.04270845651626587,
            -0.0004794736742042005,
            0.03112836740911007,
            0.025926856324076653,
            0.005524459760636091,
            -0.027694758027791977,
            -0.03377547115087509,
            -0.024823034182190895,
            -0.02377191185951233,
            -0.006216556765139103,
            0.03551332652568817,
            0.020068619400262833,
            0.019869834184646606,
            0.018534157425165176,
            0.003696814412251115,
            0.031191179528832436,
            0.002742643002420664,
            0.032278649508953094,
            0.03062364272773266,
            0.015146306715905666,
            0.013772592879831791,
            0.012760849669575691,
            -0.027916336432099342,
            -0.02640562504529953,
            0.016346300020813942,
            -0.01832675375044346,
            -0.01392268855124712,
            -0.05694127082824707,
            0.0031296827364712954,
            0.007822965271770954,
            0.014337772503495216,
            0.002740347059443593,
            -0.037128835916519165,
            -0.015172507613897324,
            0.005797684658318758,
            -0.002333791693672538,
            -0.02521018125116825,
            0.01790180243551731,
            -0.006758078467100859,
            0.008653414435684681,
            0.04242812842130661,
            -0.029665907844901085,
            -0.02914891205728054,
            0.00637233117595315,
            0.011411488987505436,
            -0.0025789542123675346,
            -0.03381736949086189,
            0.01545159611850977,
            -0.007634190376847982,
            -0.000871327065397054,
            0.019153840839862823,
            -0.018146002665162086,
            0.02134592831134796,
            0.015406511723995209,
            -0.0017808490665629506,
            0.026700319722294807,
            0.018337508663535118,
            0.005813640542328358,
            0.0054701161570847034,
            -0.05498554930090904,
            0.0158475823700428,
            0.008549032732844353,
            -0.02134520187973976,
            -0.019950764253735542,
            0.017653007060289383,
            -0.029949842020869255,
            0.00584329292178154,
            0.017432162538170815,
            0.022069260478019714,
            0.01622079871594906,
            0.02202632464468479,
            -0.004288746044039726,
            -6.580802437383682e-05,
            0.0010823782067745924,
            -0.015953777357935905,
            -0.013094292022287846,
            0.03263305500149727,
            0.0030975581612437963,
            -0.036522142589092255,
            -0.032112643122673035,
            -0.01764579862356186,
            0.030316635966300964,
            0.018440527841448784,
            0.021200725808739662,
            0.004120411816984415,
            -0.029783951118588448,
            0.013068105094134808,
            -0.019225070253014565,
            0.017687354236841202,
            0.03154490143060684,
            0.024624977260828018,
            0.0013641674304381013,
            0.023571334779262543,
            0.018329543992877007,
            -0.0021687198895961046,
            0.018206721171736717,
            0.005281407386064529,
            -0.024990037083625793,
            -0.022645335644483566,
            -0.02549944445490837,
            0.040690768510103226
        ],
        [
            -0.00845866184681654,
            0.0029645764734596014,
            0.027620261535048485,
            0.00450426060706377,
            0.021530717611312866,
            0.008515818975865841,
            -0.011306194588541985,
            0.025562182068824768,
            0.017060011625289917,
            -0.019504867494106293,
            -0.019077694043517113,
            0.007953462190926075,
            0.0017714642453938723,
            -0.03012991137802601,
            -0.006281819194555283,
            0.01079467497766018,
            0.013798771426081657,
            0.023231670260429382,
            0.030956551432609558,
            0.004063207656145096,
            -0.02629997394979,
            -0.0069715711288154125,
            0.02142183668911457,
            -0.024033892899751663,
            0.029734501615166664,
            -0.01075071096420288,
            -0.01240166649222374,
            0.0008295389707200229,
            -0.019940869882702827,
            -0.005348574370145798,
            -0.010510592721402645,
            0.007136903237551451,
            -0.016591746360063553,
            -0.029242534190416336,
            -0.009016333147883415,
            -0.020317334681749344,
            -0.007116889581084251,
            -0.008865938521921635,
            -0.01952972635626793,
            0.02701983042061329,
            -0.01685187593102455,
            0.00925870705395937,
            -0.029596081003546715,
            0.024302367120981216,
            -0.016064206138253212,
            -0.007114784326404333,
            0.0017149019986391068,
            -0.023232966661453247,
            0.004513170104473829,
            -0.01913599483668804,
            0.011029144749045372,
            -0.029082121327519417,
            0.009130217134952545,
            0.01052132248878479,
            0.02445116452872753,
            0.026614634320139885,
            0.002888857387006283,
            0.02107592113316059,
            -0.030375845730304718,
            0.007823213934898376,
            -0.004420750308781862,
            0.004127863794565201,
            -0.005833189468830824,
            0.004464331548660994,
            -0.030239887535572052,
            -0.027795974165201187,
            0.030527208000421524,
            0.011270137503743172,
            0.0245840921998024,
            -0.020277367904782295,
            0.01598811149597168,
            0.02803080342710018,
            -0.021146873012185097,
            0.02290230058133602,
            0.009300503879785538,
            -0.019417816773056984,
            0.01867840066552162,
            0.007051775231957436,
            8.07635751698399e-06,
            0.008029848337173462,
            -0.0021825379226356745,
            -0.0029453407041728497,
            0.02959132380783558,
            -0.02296677604317665,
            0.023436641320586205,
            -0.025600049644708633,
            -0.023264234885573387,
            0.0032215462997555733,
            -0.018114276230335236,
            -0.013231373392045498,
            0.029486455023288727,
            0.00010069225390907377,
            -0.028977442532777786,
            -0.024760140106081963,
            -0.023480940610170364,
            -0.018050434067845345,
            -0.016196804121136665,
            0.00319994380697608,
            0.006646648049354553,
            -0.027402125298976898,
            -0.027137869969010353,
            -0.02161877602338791,
            0.014785957522690296,
            -0.0012870730133727193,
            -0.0028023580089211464,
            -0.027748843654990196,
            -0.023617126047611237,
            -0.018964869901537895,
            0.023047134280204773,
            0.014113184995949268,
            -0.023121122270822525,
            0.023753022775053978,
            -0.0007953123422339559,
            -0.026733769103884697,
            -0.028880145400762558,
            0.024982109665870667,
            0.015224668197333813,
            0.012792903929948807,
            -0.01958390884101391,
            0.0077148727141320705,
            0.028746671974658966,
            -0.02524176985025406,
            -0.02861299179494381,
            0.00778550049290061,
            -0.02482735924422741,
            0.011936090886592865,
            -0.014884178526699543,
            -0.02440490573644638,
            -0.02026188001036644,
            -0.0027997104916721582,
            -0.025646107271313667,
            0.012061554938554764,
            -0.01956998184323311,
            -0.0027157894801348448,
            -0.008620529435575008,
            0.0170305036008358,
            -0.02075072005391121,
            -0.020978877320885658,
            -0.03008177876472473,
            -0.021611841395497322,
            -0.01895817555487156,
            0.028836265206336975,
            0.018486538901925087,
            0.017537308856844902,
            -0.003584083402529359,
            0.015669921413064003,
            -0.026069987565279007,
            -0.006521584931761026,
            -0.01331251673400402,
            0.01848442479968071,
            -0.02405639737844467,
            0.0002728936669882387,
            -0.03212766349315643,
            0.008085384033620358,
            -0.030064407736063004,
            -0.017510266974568367,
            0.017615020275115967,
            0.002877067308872938,
            -0.0011882997350767255,
            0.015325655229389668,
            0.021659059450030327,
            -0.015145836398005486,
            0.0020542098209261894,
            -0.01281045377254486,
            -0.011449810117483139,
            0.017136545851826668,
            -0.03122609853744507,
            0.012365465983748436,
            -0.01302382629364729,
            0.002935451688244939,
            0.0162523053586483,
            0.01191735826432705,
            0.019847525283694267,
            -0.024571364745497704,
            0.011018838733434677,
            -0.009278704412281513,
            -0.019617127254605293,
            -0.005476061720401049,
            -0.008730513975024223,
            0.005710851866751909,
            -0.024504035711288452,
            -0.020964598283171654,
            0.02753528207540512,
            -0.004667827859520912,
            0.021140610799193382,
            0.030011560767889023,
            -0.028442606329917908,
            -0.014854717068374157,
            0.014772405847907066,
            0.010079987347126007,
            0.024782001972198486,
            0.021705763414502144,
            0.024246037006378174,
            -0.025286415591835976,
            0.030012262985110283,
            0.00745761813595891,
            -0.023264074698090553,
            0.022724561393260956,
            -0.020620472729206085,
            0.0035507099237293005,
            -0.02876201830804348,
            -0.02191375009715557,
            0.004076331853866577,
            -0.01969139836728573,
            0.0067437379620969296,
            -0.022503290325403214,
            0.008632278069853783,
            -0.018991561606526375,
            -0.009687668643891811,
            -0.016891347244381905,
            0.01595250517129898,
            0.014902300201356411,
            -0.005653938744217157,
            0.005238181445747614,
            -0.025457313284277916,
            0.029593544080853462,
            0.011493227444589138,
            -0.029239365831017494,
            0.021657872945070267,
            -0.008133159019052982,
            0.02042790688574314,
            -0.02392350696027279,
            -0.031622834503650665,
            0.026947174221277237,
            -0.028885258361697197,
            0.0051446110010147095,
            0.007032137364149094,
            -0.026549775153398514,
            -0.032374344766139984,
            0.017916128039360046,
            -0.024411562830209732,
            -0.02478993497788906,
            0.009751975536346436,
            4.967756467522122e-05,
            -0.0001964672701433301,
            -0.015741797164082527,
            0.002909100614488125,
            0.01785442605614662,
            -0.015660390257835388,
            -0.030729463323950768,
            -0.01943298801779747,
            -0.029089458286762238,
            -0.0206146277487278,
            0.004163843579590321,
            -0.003873743349686265,
            0.016810454428195953,
            0.01830468513071537,
            0.010596225969493389,
            -0.005911417305469513,
            0.004377505276352167,
            -0.022918645292520523,
            0.009818914346396923,
            0.014044571667909622,
            0.00787808932363987,
            0.01003737561404705,
            -0.018412500619888306,
            0.013789748772978783,
            -0.026607124134898186,
            -0.009633047506213188,
            -0.008753178641200066,
            0.028602711856365204,
            0.02378375269472599,
            0.0133693041279912,
            -0.018877273425459862,
            -0.01914818212389946,
            -0.028393857181072235,
            -0.009164106100797653,
            -0.03044361062347889,
            0.006877149920910597,
            -0.004235570318996906,
            -0.01489991508424282,
            -0.022907953709363937,
            0.019397348165512085,
            0.026291590183973312,
            0.007081606425344944,
            -0.011928735300898552,
            -0.009628642350435257,
            -0.012585916556417942,
            0.027608606964349747,
            -0.01538484450429678,
            0.014954926446080208,
            -0.009975661523640156,
            -0.018223648890852928,
            -0.012416784651577473,
            -0.025470763444900513,
            -0.0034585888497531414,
            0.009100573137402534,
            0.02874733693897724,
            0.02722088061273098,
            -0.030868014320731163,
            -0.02817501686513424,
            0.008300808258354664,
            -0.015580903738737106,
            -0.030034083873033524,
            -0.008694447576999664,
            -0.027127448469400406,
            0.0059905992820858955,
            0.023809660226106644,
            -0.012490462511777878,
            -0.00763958552852273,
            0.02863859012722969,
            0.026234768331050873,
            -0.018369324505329132,
            0.007114587351679802,
            0.02945694699883461,
            -0.003304722486063838,
            -0.01909729838371277,
            0.027335865423083305,
            -0.0014175238320603967,
            -0.027757903560996056,
            0.006567896343767643,
            0.021158777177333832,
            0.007174684200435877,
            -0.005531270522624254,
            -0.026015235111117363,
            -0.021852195262908936,
            -0.0105139696970582,
            -0.01224299892783165,
            0.019992806017398834,
            -0.023264190182089806,
            0.005280560813844204,
            0.029467105865478516,
            0.026830613613128662,
            -0.01208309456706047,
            -0.011341561563313007,
            0.020165996626019478,
            0.0022278388496488333,
            -0.02311144582927227,
            0.029714230448007584,
            0.012276164256036282,
            -0.02293459139764309,
            -0.02616507187485695,
            0.005183642264455557,
            0.015780510380864143,
            0.01605234481394291,
            -0.012802675366401672,
            0.004805923905223608,
            0.030011070892214775,
            0.02805199660360813,
            -0.020632274448871613,
            -0.01872769370675087,
            0.028520740568637848,
            0.013057242147624493,
            -0.0034518118482083082,
            -0.005607916507869959,
            -0.02626454457640648,
            0.02964647486805916,
            -0.015534821897745132,
            0.017663929611444473,
            0.029128341004252434,
            0.022612720727920532,
            0.008433915674686432,
            -0.017475033178925514,
            -0.02841079980134964,
            0.023365268483757973,
            -0.024009302258491516,
            -0.012384319677948952,
            0.014067232608795166,
            0.0181802399456501,
            -0.021931808441877365,
            -0.018541106954216957,
            0.027151696383953094,
            -0.025494616478681564,
            -0.02501295693218708,
            -0.007816407829523087,
            -0.016337530687451363,
            -0.003002447308972478,
            -0.029902979731559753,
            0.02208145521581173,
            -0.009632052853703499,
            -0.03189396113157272,
            -0.02890709973871708,
            -0.008284922689199448,
            -0.020050641149282455,
            0.015447331592440605,
            -0.02572409063577652,
            -0.01714741624891758,
            -0.005273464601486921,
            -0.021945789456367493,
            -0.02414015308022499,
            0.0036304290406405926,
            0.0038462162483483553,
            -0.01596512645483017,
            0.0073345438577234745,
            0.020134754478931427,
            -0.018039286136627197,
            0.03001118078827858,
            -0.027404163032770157,
            0.0026685975026339293,
            0.0013398828450590372,
            0.028843244537711143,
            0.0026546656154096127,
            -0.02643325924873352,
            -0.010734681971371174,
            0.023093096911907196,
            0.01954253576695919,
            0.0206553116440773,
            -0.013649171218276024,
            -0.03212329372763634,
            0.014501897618174553,
            0.013126720674335957,
            0.008015370927751064,
            -0.027672281488776207,
            -0.007952229119837284,
            -0.03106936253607273,
            0.024922028183937073,
            0.0238906592130661,
            0.013934735208749771,
            0.02425866201519966,
            -0.022590329870581627,
            -0.026822779327630997,
            -0.01696643978357315,
            0.015154003165662289,
            0.005623537115752697,
            0.012762639671564102,
            -0.02683381922543049,
            0.02266639657318592,
            0.003405825700610876,
            0.01846395991742611,
            -0.028274813666939735,
            -0.0011039102682843804,
            0.009018096141517162,
            -0.00270133838057518,
            0.028741398826241493,
            0.0048283846117556095,
            -0.025487933307886124,
            -0.017763525247573853,
            0.00036838994128629565,
            -0.0017177821137011051,
            -0.03178905323147774,
            -0.0317002609372139,
            0.02249395288527012,
            0.007400397676974535,
            0.02298462763428688,
            -0.032005976885557175,
            -0.02682223916053772,
            -0.007911857217550278,
            0.012029890902340412,
            0.004687499720603228,
            0.030466675758361816,
            0.017318639904260635,
            0.030108636245131493,
            -0.02986576408147812,
            0.011354261077940464,
            0.0029428445268422365,
            -0.026294955983757973,
            0.001786384149454534,
            -0.02257259376347065,
            -0.014691317453980446,
            -0.02158297598361969,
            -0.0011169416829943657,
            -0.02483600378036499,
            -0.00846062321215868,
            0.008143246173858643,
            0.008604132570326328,
            0.01532080490142107,
            0.02796831540763378,
            0.007867482490837574,
            -0.006239704787731171,
            0.010348577052354813,
            0.01809912919998169,
            0.015374882146716118,
            -0.012478437274694443,
            0.03067135252058506,
            -0.013546589761972427,
            -0.018861230462789536,
            -0.01791578345000744,
            -0.02975921705365181,
            -0.019782358780503273,
            0.009324627928435802,
            0.02535136602818966,
            -0.02831471897661686,
            -0.024429457262158394,
            0.004667383152991533,
            -0.027998369187116623,
            0.0031200721859931946,
            -0.003141309367492795,
            0.02347427047789097,
            -0.019609881564974785,
            -0.004986915271729231,
            0.01648770458996296,
            -0.003078733803704381,
            -0.008148685097694397,
            -0.02580704167485237,
            -0.00407696608453989,
            -0.029372937977313995,
            0.021677538752555847,
            -0.022170551121234894,
            -0.01477857306599617,
            -0.00024244448286481202,
            -0.029599741101264954,
            -0.006433715578168631,
            -0.00448053190484643,
            -0.026450015604496002,
            -0.025234777480363846,
            -0.023760143667459488,
            -0.027647312730550766,
            -0.009296359494328499,
            -0.011894079856574535,
            -0.004547987598925829,
            0.018836000934243202,
            0.02764720842242241,
            -0.02900494635105133,
            0.008555692620575428,
            -0.022511133924126625,
            -0.010786257684230804,
            0.025184765458106995,
            -0.018741536885499954,
            -0.0017978694522753358,
            0.008629072457551956,
            -0.02783605456352234,
            -0.006615376099944115,
            -0.012269617058336735,
            -0.014270227402448654,
            0.027767499908804893,
            -0.026863735169172287,
            0.015312757343053818,
            0.004584794398397207,
            0.027068696916103363,
            0.010476003400981426,
            0.0110299251973629,
            -0.014974168501794338,
            -0.03242552652955055,
            -0.012435490265488625,
            -0.0016772340750321746,
            0.014554835855960846,
            -0.0007104683318175375,
            -0.016324695199728012,
            -0.0059036812745034695,
            -0.015230395831167698,
            0.020227862522006035,
            0.021709119901061058,
            -0.0019370722584426403,
            0.0056125326082110405,
            0.017627978697419167,
            0.023256491869688034,
            0.007328044157475233,
            -0.007404019590467215,
            -0.015813741832971573,
            0.007499465253204107,
            -0.00997182261198759,
            0.020381681621074677,
            0.0033122070599347353,
            -0.009089677594602108,
            0.019637003540992737,
            -0.022561782971024513,
            -0.029988687485456467,
            0.0016718122642487288,
            -0.009911387227475643,
            0.015643075108528137,
            -0.014908621087670326,
            0.01247798465192318,
            0.025715745985507965,
            -0.031875498592853546,
            -0.016471657902002335,
            -0.0081150121986866,
            -0.02588450163602829,
            -0.012716233730316162,
            0.02703012339770794,
            0.0167332012206316,
            -0.028639810159802437,
            0.017186960205435753,
            -0.031282100826501846,
            0.0038384762592613697,
            -0.004452718887478113,
            0.028092218562960625,
            -0.006279714871197939,
            0.008061617612838745,
            -0.016003455966711044,
            -0.011407428421080112,
            -0.006825269665569067,
            -0.028518594801425934,
            -0.019615190103650093,
            0.02104705199599266,
            -0.02207384817302227,
            -0.028226209804415703,
            0.014723152853548527,
            -0.016289902850985527,
            0.01867639645934105,
            0.026007650420069695,
            0.013987741433084011,
            -0.02136482670903206,
            0.008275727741420269,
            0.010366137139499187,
            0.015617644414305687,
            0.004321213811635971,
            -0.014023096300661564,
            0.016836822032928467,
            -0.022683287039399147,
            0.028124678879976273,
            -0.0241654384881258,
            -0.016451969742774963,
            0.027304118499159813,
            -0.02437100186944008,
            -0.003906583879143,
            -0.008782660588622093,
            -0.005237361881881952,
            -0.0032255060505121946,
            -0.021737026050686836,
            0.014362208545207977,
            -0.018787847831845284,
            0.007310017012059689,
            0.005240316037088633,
            0.031887806951999664,
            -0.023625606670975685,
            -0.008550583384931087,
            0.014910060912370682,
            -0.02945256419479847,
            0.017646845430135727,
            0.02824568562209606,
            -0.010619924403727055,
            0.02856547012925148,
            0.004695368465036154,
            -0.03202979639172554,
            -0.00024362243129871786,
            -0.02981681562960148,
            -0.012044671922922134,
            -0.021154439076781273,
            -0.029199786484241486,
            0.01713002473115921,
            0.021615125238895416,
            -0.022334786131978035,
            0.029463443905115128,
            -0.015059676952660084,
            -0.007762708701193333,
            0.027858559042215347,
            -0.025143491104245186,
            0.009667830541729927,
            0.026239236816763878,
            -0.026779526844620705,
            -0.001836004201322794,
            -0.03020593896508217,
            0.010452097281813622,
            0.01196407712996006,
            0.02439836785197258,
            0.0011617804411798716,
            0.008662023581564426,
            0.008467928506433964,
            0.00225566397421062,
            -0.03007335774600506,
            -0.02942827343940735,
            0.02204120345413685,
            -0.011386988684535027,
            0.0048485808074474335,
            -0.02298843488097191,
            -0.009218758903443813,
            0.015390777960419655,
            0.0045159002766013145,
            -0.025091039016842842,
            -0.006540322210639715,
            -0.015858478844165802,
            -0.005802990403026342,
            0.01956906169652939,
            0.02080897055566311,
            -0.03062986396253109,
            -0.017767339944839478,
            -0.01714230701327324,
            -0.029079994186758995,
            -0.025005824863910675,
            0.010850678198039532,
            -0.005578788463026285,
            -0.007290771696716547,
            -0.016274625435471535,
            -0.004581702873110771,
            -0.02378886006772518,
            -0.009610941633582115,
            -0.026988938450813293,
            -0.013379616662859917,
            -0.014921448193490505,
            -0.004436850547790527,
            0.02902183122932911,
            -0.011827387847006321,
            0.012529820203781128,
            0.02604534476995468,
            -0.010710754431784153,
            0.012344160117208958,
            -0.029324153438210487,
            0.005953875835984945,
            -0.01927868463099003,
            0.012729430571198463,
            -0.012660682201385498,
            -0.01722649671137333,
            0.0017073546769097447,
            -0.016325710341334343,
            -0.022941473871469498,
            -0.025873472914099693,
            0.012785165570676327,
            -0.03057151660323143,
            0.028368547558784485,
            -0.027151083573698997,
            0.016849156469106674,
            0.028497418388724327,
            -0.006475215777754784,
            0.024781756103038788,
            -0.005379303824156523,
            0.021688047796487808,
            -0.01563054323196411,
            -0.00028342317091301084,
            0.02925337664783001,
            -0.003508435795083642,
            0.020804138854146004,
            0.01381515059620142,
            0.028649546205997467,
            -0.029713168740272522,
            0.012713099829852581,
            -0.020022647455334663,
            -0.027324318885803223,
            0.013271570205688477,
            -0.00489983893930912,
            0.01611754111945629,
            0.023358860984444618,
            0.00592372240498662,
            0.002043376909568906,
            0.027579499408602715,
            -0.012994840741157532,
            0.02719852142035961,
            -0.005565729923546314,
            -0.01000102236866951,
            -0.011816565878689289,
            -0.024538658559322357,
            -0.02789841778576374,
            0.006798150017857552,
            0.021633626893162727,
            0.027392296120524406,
            -0.022024257108569145,
            0.00613909587264061,
            -0.026070693507790565,
            0.02985016256570816,
            -0.03137031942605972,
            0.012550313957035542,
            0.028875255957245827,
            0.023715753108263016,
            0.01417196448892355,
            0.014072295278310776,
            0.017973674461245537,
            0.019402721896767616,
            0.030024362727999687,
            0.026772337034344673,
            0.01045858021825552,
            0.0056413281708955765,
            -0.013859123922884464,
            0.018871674314141273,
            -0.016744382679462433,
            -0.017980370670557022,
            -0.028110463172197342,
            0.006813118699938059,
            -0.005306615494191647,
            -0.0017530694603919983,
            -0.02424776926636696,
            -0.013828066177666187,
            0.02857271395623684,
            -0.019293470308184624,
            0.005616352427750826,
            0.00902591273188591,
            0.005173093639314175,
            -0.009592846035957336,
            -0.020867204293608665,
            -0.026437679305672646,
            0.020079106092453003,
            -0.01192447729408741,
            -0.0035158912651240826,
            -0.02695278450846672,
            0.024938389658927917,
            -0.007559062447398901,
            0.012386300601065159,
            0.017081614583730698,
            0.02028946951031685,
            0.014879683963954449,
            0.0007528392015956342,
            -0.018857967108488083,
            -0.006559316068887711,
            0.010788775980472565,
            0.028399275615811348,
            -0.026153290644288063,
            -0.0017829511780291796,
            0.012607818469405174,
            0.009608674794435501,
            0.019383013248443604,
            0.009016412310302258,
            -0.008176540024578571,
            0.017245717346668243,
            -0.0073572788387537,
            0.0021369033493101597,
            -0.00856309849768877,
            -0.006643350701779127,
            0.003224920481443405,
            -0.006513931788504124,
            0.005762001965194941,
            -0.009344925172626972,
            -0.0045858509838581085,
            -0.013592792674899101,
            0.019143614917993546,
            0.019937336444854736,
            0.012899779714643955,
            -0.02618243172764778,
            -0.002342973370105028,
            -0.013439088128507137,
            0.027750466018915176,
            -0.017014609649777412,
            0.02861250750720501,
            -0.011075439862906933,
            -0.019703736528754234,
            -0.02418026141822338,
            0.004142231773585081,
            0.017592858523130417,
            -0.0031990199349820614,
            -0.026789195835590363,
            -0.00342006073333323,
            0.0019773629028350115,
            -0.028172150254249573,
            -0.004483281634747982,
            0.00957636907696724,
            -0.01632351614534855,
            -0.022987818345427513,
            -0.027868565171957016,
            -0.02908894047141075,
            -0.030798697844147682,
            -0.016464393585920334,
            -0.02079865336418152,
            0.001958050997927785,
            -0.0287514366209507,
            -0.006551511585712433,
            0.028913646936416626,
            -0.008610033430159092,
            -0.01577593758702278,
            -0.007543288171291351,
            0.0030941986478865147,
            -0.027005497366189957,
            0.009596344083547592,
            0.029666977003216743,
            -0.007826357148587704,
            0.029411161318421364,
            -0.015297266654670238,
            -0.026280170306563377,
            -0.010020354762673378,
            -0.02861328050494194,
            0.014671716839075089,
            -0.0073885563760995865,
            0.01708383485674858,
            -0.018597276881337166,
            -0.020996203646063805,
            -0.02202427014708519,
            0.008956752717494965,
            -0.015649335458874702,
            -0.02513839490711689,
            -0.031067844480276108,
            -0.024709096178412437,
            -0.007699314039200544,
            -0.009317989461123943,
            -0.02382657304406166,
            0.0007670918712392449,
            -0.0028510568663477898,
            0.016319114714860916,
            -0.011030749417841434,
            -0.002726790262386203,
            0.011769487522542477,
            0.017306122928857803,
            -0.007023257669061422,
            0.010281297378242016,
            0.020338090136647224,
            0.02919982746243477,
            0.0012368194293230772,
            -0.013731617480516434,
            -0.00787367019802332,
            -0.010233685374259949,
            -0.02153712883591652,
            0.027954164892435074,
            0.024580631405115128,
            -0.01050224993377924,
            -0.016649818047881126,
            0.027613386511802673,
            -0.024207664653658867,
            0.019714146852493286,
            0.0016184155829250813,
            0.0031825655605643988,
            -0.03057735785841942,
            0.022466197609901428,
            0.012461027130484581,
            -0.024833351373672485,
            0.02757614105939865,
            0.027830801904201508,
            -0.02955220639705658,
            0.028530145063996315,
            0.008620845153927803,
            -0.03168642893433571,
            0.022401640191674232,
            -0.025917917490005493,
            -0.017394788563251495,
            0.02380581945180893,
            -0.005938821472227573,
            0.030348442494869232,
            0.01715651899576187,
            -0.017304975539445877,
            0.024073541164398193,
            0.022608768194913864,
            -0.0017937695374712348,
            0.02015652507543564,
            0.02487434260547161,
            0.01317223347723484,
            0.00840792153030634,
            -0.004686260130256414,
            0.012135297991335392,
            0.02609153650701046,
            -0.026694567874073982,
            -0.01791495643556118,
            0.004265297204256058,
            -0.031284675002098083,
            -0.00603117048740387,
            -0.023417910560965538,
            0.015702031552791595,
            0.0006461493321694434,
            0.0006748256273567677,
            0.0057541136629879475,
            0.026083458214998245,
            0.022309599444270134,
            0.011626905761659145,
            0.010739809833467007,
            -0.010868910700082779,
            -0.017129164189100266,
            -0.0020419771317392588,
            -0.009097452275454998,
            -0.005530214868485928,
            -0.000308970280457288,
            -0.02145872637629509,
            -0.02533600479364395,
            -0.019279342144727707,
            -0.005577473901212215,
            -0.029920831322669983,
            0.029820196330547333,
            0.013113570399582386,
            -0.029418863356113434,
            -0.0007027318351902068,
            -0.015679318457841873,
            -0.00031304373987950385,
            -0.028962410986423492,
            0.015169157646596432,
            0.009433041326701641,
            0.013585001230239868,
            0.0018298932118341327,
            -0.010228686034679413,
            -0.030472800135612488,
            0.02376263588666916,
            -0.006591515149921179,
            -0.015705447643995285,
            -0.002436534035950899,
            -0.0020704076159745455,
            0.025696657598018646,
            -0.03190968558192253,
            -0.003911127801984549,
            -0.0037340372800827026,
            -0.01435945276170969,
            0.02234455570578575,
            -0.013356810435652733,
            0.014424711465835571,
            0.029206102713942528,
            -0.0015324890846386552,
            -0.01910805143415928,
            -0.021440163254737854,
            0.010491528548300266,
            0.009244048036634922,
            0.02956082858145237,
            -0.026540245860815048,
            -0.031419944018125534,
            -0.007402596529573202,
            -0.01611216552555561,
            -0.011593217961490154,
            0.014946050010621548,
            -0.02561403438448906,
            -0.00559249147772789,
            -0.00026932384935207665,
            -0.024237705394625664,
            0.002438316121697426,
            -0.026177983731031418,
            -0.020941145718097687,
            -0.015415871515870094,
            -0.010892328806221485,
            0.008858811110258102,
            -0.0016889298567548394,
            -0.029524311423301697,
            0.006680916529148817,
            -0.004482203163206577,
            0.008904864080250263,
            0.019484538584947586,
            0.023935532197356224,
            -0.02119225077331066,
            0.026692954823374748,
            -0.005460649728775024,
            0.0018063215538859367,
            0.011137128807604313,
            0.003856457071378827,
            -0.029363073408603668,
            0.018024533987045288,
            0.015288906171917915,
            -0.01275523193180561,
            0.024019474163651466,
            -0.022878021001815796,
            0.005401755683124065,
            0.010684513486921787,
            -0.018412455916404724,
            0.007104271091520786,
            0.014278407208621502,
            0.016954191029071808,
            -0.004898136015981436,
            0.02630670741200447,
            -0.02407068945467472,
            0.005400878842920065,
            0.001104600029066205,
            0.029248367995023727,
            0.016280420124530792,
            -0.01128433272242546,
            0.01852126605808735,
            0.009374444372951984,
            0.01902790181338787,
            0.005631683859974146,
            0.01971498504281044,
            -0.02113061398267746,
            0.020344583317637444,
            -0.0171507578343153,
            -0.019013581797480583,
            0.004476455505937338,
            -0.027441566810011864,
            0.0017003156244754791,
            0.01307191327214241,
            -0.01362640131264925
        ],
        [
            -0.006679909769445658,
            0.03808834031224251,
            -0.012456601485610008,
            0.032913606613874435,
            0.0032102453988045454,
            -0.04843704774975777,
            0.02634318917989731,
            0.015076006762683392,
            -0.030255228281021118,
            0.03176455199718475,
            0.011300822719931602,
            -0.02556881681084633,
            0.04583466053009033,
            0.0029725469648838043,
            -0.03358498215675354,
            -0.025668218731880188,
            -0.012752356007695198,
            -0.02952471375465393,
            0.01252701599150896,
            -0.027711236849427223,
            -0.06495276838541031,
            -0.01829487271606922,
            0.00663484912365675,
            0.04256586357951164,
            -0.04677483066916466,
            -0.009345753118395805,
            0.014409689232707024,
            0.027485528960824013,
            0.002049168338999152,
            -0.04488278925418854,
            -0.020735016092658043,
            -0.019026119261980057,
            -0.005503063090145588,
            0.010910512879490852,
            0.01949141174554825,
            0.03444128483533859,
            -0.02190747670829296,
            0.007837862707674503,
            -0.005885500460863113,
            -0.021372323855757713,
            -0.04488494247198105,
            -0.025431586429476738,
            0.004915159661322832,
            -0.016884006559848785,
            -0.0055452329106628895,
            -0.024659592658281326,
            0.03080327995121479,
            -0.023821579292416573,
            0.013820034451782703,
            -0.007537487428635359,
            0.06332839280366898,
            -0.05089018866419792,
            -0.05116143822669983,
            -0.00496775982901454,
            0.047473762184381485,
            0.0022778159473091364,
            -0.02909909188747406,
            -0.009739321656525135,
            0.014479526318609715,
            -0.001951491110958159,
            -0.05261848494410515,
            0.026941213756799698,
            0.031209301203489304,
            -0.054970093071460724,
            -0.008087808266282082,
            -0.06925471872091293,
            -0.013810684904456139,
            -0.04753078520298004,
            0.017041731625795364,
            0.01923898048698902,
            -0.03645826876163483,
            0.02034168504178524,
            0.020251546055078506,
            -0.03141988068819046,
            0.04738316684961319,
            -0.01426554936915636,
            -0.02907554619014263,
            -0.002222120063379407,
            0.046448707580566406,
            0.021847078576683998,
            0.036158181726932526,
            0.013592731207609177,
            0.010041273199021816,
            0.005648123100399971,
            0.031271517276763916,
            -0.002373367315158248,
            -0.0007991254678927362,
            -0.005762692075222731,
            0.010252642445266247,
            0.01979151926934719,
            -0.0005064583383500576,
            0.014548839069902897,
            0.007721981033682823,
            -0.034673478454351425,
            -0.034806910902261734,
            -0.025987328961491585,
            -0.03723475709557533,
            -0.023819519206881523,
            -0.08341442048549652,
            0.007440195884555578,
            -0.0689457431435585,
            0.010403857566416264,
            -0.00921760406345129,
            -0.01953965052962303,
            0.02936445362865925,
            -0.012203910388052464,
            0.006388070527464151,
            0.04261915758252144,
            -0.005292496643960476,
            -0.05261437967419624,
            -0.010399413295090199,
            0.00012927812349516898,
            0.01430069375783205,
            -0.0010444052750244737,
            0.009527425281703472,
            0.011831948533654213,
            -0.03396192938089371,
            -0.018819138407707214,
            -0.017033128067851067,
            -0.009122731164097786,
            0.022528940811753273,
            0.0020911991596221924,
            -0.020584912970662117,
            0.03931982442736626,
            0.009467951953411102,
            0.02326206862926483,
            -0.0031458318699151278,
            0.013353751972317696,
            -0.025353094562888145,
            0.016626648604869843,
            -0.017728982493281364,
            0.012256218120455742,
            -0.033038195222616196,
            0.060054950416088104,
            0.0024738116189837456,
            0.04418853670358658,
            -0.03405919671058655,
            -0.03389353305101395,
            0.008751370944082737,
            -0.0228007473051548,
            -0.023378735408186913,
            -0.028293203562498093,
            0.03578254580497742,
            0.03256519138813019,
            -0.031237628310918808,
            0.0021176410373300314,
            0.034372251480817795,
            -0.01239943876862526,
            -0.003014146350324154,
            0.004333812743425369,
            0.011423401534557343,
            0.008435717783868313,
            0.021115867421030998,
            0.008254812099039555,
            0.008756752125918865,
            0.004247516859322786,
            0.005277827847748995,
            -0.004356246907263994,
            -0.04508299008011818,
            -0.013269161805510521,
            0.0125588895753026,
            0.01585693657398224,
            0.029946234077215195,
            0.01645268313586712,
            0.0401490218937397,
            0.021279126405715942,
            -0.06785820424556732,
            0.02830452471971512,
            -0.017239896580576897,
            0.0045469217002391815,
            -0.015104996040463448,
            -0.016856279224157333,
            -0.010026713833212852,
            -0.0020899467635899782,
            -0.026294110342860222,
            -0.0017876779893413186,
            -0.009517046622931957,
            -0.02787688933312893,
            0.00019417137082200497,
            0.01710538938641548,
            0.030107177793979645,
            -0.026868533343076706,
            0.05047949030995369,
            -0.0018130531534552574,
            -0.011066445149481297,
            0.037231169641017914,
            0.045667052268981934,
            -0.009418181143701077,
            -0.02667294070124626,
            0.020912379026412964,
            0.01596023142337799,
            -0.006853791419416666,
            -0.0340958870947361,
            0.010923498310148716,
            -0.035259947180747986,
            0.03342926502227783,
            0.02280648984014988,
            -0.03237885236740112,
            -0.03313165530562401,
            -0.0067843664437532425,
            -0.051482368260622025,
            -0.022666651755571365,
            0.058088477700948715,
            -0.025162098929286003,
            -0.013052196241915226,
            -0.008361337706446648,
            0.033398356288671494,
            -0.03091622143983841,
            0.006046652793884277,
            -0.002667281311005354,
            0.009843944571912289,
            -0.015547805465757847,
            -0.1020946055650711,
            0.022139007225632668,
            -0.022457387298345566,
            0.030720803886651993,
            0.01334171649068594,
            -0.04876084625720978,
            -0.008642616681754589,
            0.009232554584741592,
            0.010530414059758186,
            0.04916423559188843,
            0.01336746197193861,
            -0.013274488970637321,
            0.033177535980939865,
            0.06371612101793289,
            0.03759245574474335,
            -0.005977637134492397,
            -0.033964335918426514,
            0.0029266588389873505,
            0.03150826320052147,
            0.029407305642962456,
            -0.0288262739777565,
            -0.011805057525634766,
            -0.054090309888124466,
            0.010947437025606632,
            0.04979478195309639,
            0.013421757146716118,
            -0.02891550026834011,
            -0.01903185248374939,
            -0.014524810947477818,
            0.02887887693941593,
            -0.030275452882051468,
            0.03356148302555084,
            0.039269525557756424,
            -0.03504075109958649,
            0.00773262232542038,
            0.0023033390752971172,
            0.009256408549845219,
            -0.012937144376337528,
            -0.05399825796484947,
            0.01207902655005455,
            -0.010530641302466393,
            -0.02885388769209385,
            -0.01933114416897297,
            -0.05323697254061699,
            -0.007742758374661207,
            -0.004236860200762749,
            -0.02131829410791397,
            -0.006959063466638327,
            0.01157761923968792,
            -0.0026505079586058855,
            -0.007500171195715666,
            -0.01667889952659607,
            -0.017168330028653145,
            0.010259229689836502,
            -0.006668471731245518,
            -0.00587138207629323,
            0.02110471948981285,
            -0.023586777970194817,
            -0.017443064600229263,
            0.0014884293777868152,
            0.008426281623542309,
            -0.00894156377762556,
            0.03942212089896202,
            -0.019193047657608986,
            0.027474015951156616,
            -0.011447165161371231,
            -0.04014436528086662,
            -0.007999719120562077,
            0.013051841408014297,
            -0.02619481086730957,
            0.04080577194690704,
            0.007062854710966349,
            0.009955287910997868,
            0.018116842955350876,
            0.02570786140859127,
            0.0138181047514081,
            0.0007428176468238235,
            -0.01850067265331745,
            0.014291362836956978,
            0.0159139521420002,
            0.019160758703947067,
            0.018801966682076454,
            -0.051603276282548904,
            -0.042353298515081406,
            0.009520948864519596,
            0.0328485332429409,
            -0.060325849801301956,
            0.006054242141544819,
            -0.018199220299720764,
            0.018348222598433495,
            0.007551334332674742,
            0.026630304753780365,
            0.007833956740796566,
            0.04633524268865585,
            -0.006451386958360672,
            -0.015660947188735008,
            0.024264395236968994,
            0.024585196748375893,
            -0.02298642508685589,
            0.014794455841183662,
            0.02002704329788685,
            0.020299414172768593,
            -0.003504608292132616,
            0.020739341154694557,
            -0.030482396483421326,
            -0.0038909786380827427,
            -0.034087885171175,
            -0.01986539736390114,
            0.01320118922740221,
            0.028715034946799278,
            0.009909017011523247,
            0.01031020749360323,
            0.007391782011836767,
            -0.007002826780080795,
            -4.769693623529747e-05,
            0.02245921641588211,
            0.004332583863288164,
            -0.023588532581925392,
            -0.030350839719176292,
            -0.0008902325644157827,
            -0.031740348786115646,
            0.014668552204966545,
            0.02574910596013069,
            0.05236770212650299,
            -0.0058166892267763615,
            -0.005713562946766615,
            -0.021643141284585,
            0.021558891981840134,
            0.010389317758381367,
            0.03182670474052429,
            0.002956271870061755,
            -0.008246106095612049,
            -0.029046472162008286,
            0.027869950979948044,
            -0.00827416218817234,
            0.03197320178151131,
            -8.659558807266876e-05,
            -0.015711035579442978,
            0.007696325890719891,
            0.04139479994773865,
            -0.05271586775779724,
            0.03263500705361366,
            -0.031101727858185768,
            0.029662854969501495,
            -0.008366525173187256,
            -0.026946907863020897,
            0.017342254519462585,
            0.035344354808330536,
            0.0002832429890986532,
            -0.017754102125763893,
            0.0011454930063337088,
            -0.023513691499829292,
            0.032181382179260254,
            0.023849470540881157,
            0.008595791645348072,
            -0.010666891932487488,
            -0.025884239003062248,
            0.03425443917512894,
            0.021370144560933113,
            -0.008663672022521496,
            0.008551356382668018,
            -0.020691026002168655,
            0.012809478677809238,
            -0.050077322870492935,
            -0.0007893222500570118,
            0.04010791704058647,
            0.026939060539007187,
            0.01902512088418007,
            0.06133205443620682,
            -0.003574291942641139,
            0.02922840043902397,
            -0.020299138501286507,
            -0.013377860188484192,
            0.030535509809851646,
            -0.01508085336536169,
            -0.012845512479543686,
            0.00944911502301693,
            0.003929526545107365,
            -0.02215609699487686,
            -0.023590166121721268,
            -0.027091819792985916,
            -0.021335700526833534,
            -0.06847262382507324,
            -0.01272364892065525,
            -0.004754731431603432,
            -0.057437095791101456,
            0.023693405091762543,
            -0.025771617889404297,
            0.04750772565603256,
            -0.023702537640929222,
            -0.011975070461630821,
            -0.010297554545104504,
            -0.05328064039349556,
            0.007344876881688833,
            -0.032360516488552094,
            0.02091720886528492,
            -0.0226916316896677,
            -0.0014649733202531934,
            -0.019469138234853745,
            0.015177751891314983,
            -0.016444725915789604,
            0.010157546028494835,
            0.038615114986896515,
            0.02936815470457077,
            0.018082091584801674,
            -0.009773250669240952,
            -0.02069094032049179,
            -0.023761451244354248,
            0.012629527598619461,
            0.04421084001660347,
            0.005708273500204086,
            -0.026209164410829544,
            0.023982947692275047,
            -0.07801144570112228,
            -0.03963123634457588,
            0.007674285210669041,
            0.022120509296655655,
            -0.007002001628279686,
            -0.00015524454647675157,
            -0.02091936394572258,
            0.0014607938937842846,
            0.005907527171075344,
            0.020505299791693687,
            0.011560571379959583,
            0.0021248641423881054,
            -0.0319218635559082,
            0.029141463339328766,
            0.021592965349555016,
            0.029239557683467865,
            -0.00975457951426506,
            0.009903294034302235,
            -0.021014105528593063,
            0.028923697769641876,
            0.020474528893828392,
            0.021791227161884308,
            -0.06650325655937195,
            -0.01452457532286644,
            0.03285032883286476,
            -0.004873432219028473,
            0.0019560013897717,
            -0.02535523660480976,
            -0.035827141255140305,
            0.012960686348378658,
            -0.014391251839697361,
            -0.023285692557692528,
            -0.014410228468477726,
            -0.035023096948862076,
            -0.028733687475323677,
            0.03050987236201763,
            0.0008194524561986327,
            0.03464055061340332,
            -0.04991745948791504,
            0.017452755942940712,
            0.014489032328128815,
            0.00046211524750106037,
            0.018684493377804756,
            0.028700845316052437,
            -0.00573861226439476,
            0.011213093064725399,
            0.001156731159426272,
            0.005381666589528322,
            -0.0175905991345644,
            0.03302258998155594,
            -0.023237230256199837,
            0.01255196612328291,
            -0.021907923743128777,
            0.029562832787632942,
            0.03175856173038483,
            -0.03923889994621277,
            -0.020943578332662582,
            0.02918301708996296,
            -0.011846732348203659,
            -0.05286082997918129,
            0.03330874443054199,
            0.014737559482455254,
            0.013779365457594395,
            -0.09061241149902344,
            -0.0036441953852772713,
            0.009335272945463657,
            -0.02568407915532589,
            0.012607868760824203,
            -0.03665415942668915,
            -0.06753632426261902,
            0.00473378412425518,
            0.04010709002614021,
            -0.010554572567343712,
            -0.04125533625483513,
            0.004596004728227854,
            -0.019168924540281296,
            -0.04069183021783829,
            0.03331881761550903,
            0.031403783708810806,
            -0.01639573462307453,
            0.004335826262831688,
            0.021510478109121323,
            -0.0020043926779180765,
            -0.0019922538194805384,
            -0.01386717613786459,
            -0.01954091712832451,
            -0.04748837277293205,
            0.014127766713500023,
            0.04171697050333023,
            0.02007615938782692,
            0.022273756563663483,
            -0.012685946188867092,
            0.009721748530864716,
            0.0022706380113959312,
            -0.005747334565967321,
            -0.030527334660291672,
            0.026813479140400887,
            -0.021901845932006836,
            0.013627922162413597,
            0.0009156933519989252,
            -0.025878015905618668,
            -0.017049558460712433,
            -0.022420598194003105,
            -0.0227498821914196,
            0.05836053565144539,
            0.01281103678047657,
            0.022878050804138184,
            -0.07517055422067642,
            0.02765020541846752,
            0.015238802880048752,
            -0.07855662703514099,
            0.009835737757384777,
            0.012034691870212555,
            -0.005570271983742714,
            -0.024728527292609215,
            -0.030678465962409973,
            -0.028490733355283737,
            0.016085578128695488,
            -0.0068451822735369205,
            0.02494560368359089,
            0.07283420115709305,
            0.026497501879930496,
            -0.021870991215109825,
            -0.033247560262680054,
            0.03276066854596138,
            -0.04052183777093887,
            -0.0008117903489619493,
            0.04110782966017723,
            -0.029354771599173546,
            0.05195288360118866,
            0.018958143889904022,
            -0.010982594452798367,
            0.0010892070131376386,
            -0.01612083800137043,
            -0.0014014054322615266,
            -0.02501484379172325,
            -0.029576119035482407,
            -0.01909005641937256,
            0.013831975869834423,
            -0.006609490606933832,
            0.031293176114559174,
            0.06097552180290222,
            -0.026821015402674675,
            0.031856656074523926,
            -0.017101971432566643,
            -0.0031033915001899004,
            0.017676418647170067,
            -0.004937880672514439,
            0.03146639093756676,
            0.002742114942520857,
            -0.014953658916056156,
            0.00561946677044034,
            -0.019889570772647858,
            -0.040896669030189514,
            -0.021885959431529045,
            -0.03871981427073479,
            -0.03931364044547081,
            -0.023252055048942566,
            0.030285324901342392,
            0.008236667141318321,
            -0.01970301941037178,
            -0.01784006506204605,
            0.01085734274238348,
            0.03280830383300781,
            -0.034886620938777924,
            -0.008728645741939545,
            -0.0032916932832449675,
            -0.018242450430989265,
            -0.011728143319487572,
            -0.01926017552614212,
            0.015399767085909843,
            0.013686713762581348,
            0.003089023055508733,
            -0.012132550589740276,
            0.011261792853474617,
            -0.09114126861095428,
            -0.014267869293689728,
            0.004490625113248825,
            0.026102570816874504,
            0.027009017765522003,
            -0.026847340166568756,
            0.03449932858347893,
            0.015382111072540283,
            0.005893542431294918,
            0.04755045473575592,
            0.02281845174729824,
            0.014822905883193016,
            0.0235610194504261,
            -0.07190986722707748,
            -0.005940134171396494,
            -0.03950410708785057,
            -0.024560054764151573,
            0.009230769239366055,
            -0.003933537285774946,
            -0.03611809015274048,
            0.00935222115367651,
            0.04304204508662224,
            0.01815171167254448,
            -0.027764568105340004,
            -0.03644491359591484,
            -0.012520506046712399,
            0.025149157270789146,
            0.011568636633455753,
            -0.03209032118320465,
            0.005105099640786648,
            0.004165606573224068,
            -0.048990603536367416,
            -0.007751252967864275,
            0.025081880390644073,
            -0.0663188174366951,
            0.04460505396127701,
            0.016321605071425438,
            0.031655289232730865,
            -0.006080772262066603,
            0.01987004280090332,
            0.01758827641606331,
            0.06451660394668579,
            -0.0030273813754320145,
            -0.012389046140015125,
            -0.003089960664510727,
            -0.01066697109490633,
            0.02159265987575054,
            0.00609100004658103,
            0.015166928991675377,
            0.008062312379479408,
            0.029342995956540108,
            -0.004655866418033838,
            -0.005400362424552441,
            0.011211809702217579,
            0.06792627274990082,
            -0.02513798326253891,
            0.04661155119538307,
            0.01851571351289749,
            0.009793064557015896,
            0.0023578715045005083,
            0.043473195284605026,
            -0.0219285748898983,
            0.0073739755898714066,
            0.0022616959176957607,
            0.029420902952551842,
            -0.044766925275325775,
            0.02295248955488205,
            0.008933628909289837,
            0.010058945044875145,
            -0.022396065294742584,
            -0.01794808730483055,
            0.0342901237308979,
            -0.01505253091454506,
            0.03388870880007744,
            0.008399215526878834,
            -0.0245388001203537,
            -0.006313880905508995,
            0.013081081211566925,
            0.002032274380326271,
            0.010299475863575935,
            -0.014853043481707573,
            0.012859044596552849,
            -0.028492212295532227,
            0.02948487550020218,
            0.03652530908584595,
            -0.0233360156416893,
            0.012602749280631542,
            -0.04347170516848564,
            0.0026295960415154696,
            0.026243414729833603,
            0.022286653518676758,
            0.014606514014303684,
            -0.0009467647178098559,
            0.0008776407921686769,
            0.029504800215363503,
            0.018878744915127754,
            -0.006406825967133045,
            -0.014401888474822044,
            -0.01973935216665268,
            -0.0035810060799121857,
            0.002640143036842346,
            -0.010560819879174232,
            0.022999361157417297,
            0.030012482777237892,
            -0.003524039639160037,
            0.02529669739305973,
            0.03269723057746887,
            0.009669862687587738,
            -0.002791963517665863,
            0.005541711580008268,
            -0.005161253269761801,
            -0.010854760184884071,
            -0.03711903840303421,
            -0.020745616406202316,
            0.023674922063946724,
            -0.0414927639067173,
            0.019816191866993904,
            -0.0022028458770364523,
            -0.0315396748483181,
            0.006132137030363083,
            -0.03200642392039299,
            -0.00700029730796814,
            0.03386259451508522,
            -0.003555327421054244,
            -0.017535967752337456,
            0.012390908785164356,
            0.014557224698364735,
            -0.02074652537703514,
            -0.0015606879023835063,
            -0.03601450100541115,
            0.005539498291909695,
            -0.0630103275179863,
            -0.022818442434072495,
            0.0183121170848608,
            -0.012452599592506886,
            -0.028715956956148148,
            0.006366376299411058,
            -0.020593995228409767,
            0.027381308376789093,
            0.01079616229981184,
            -0.03520406037569046,
            0.01181752234697342,
            -0.028540421277284622,
            0.049170251935720444,
            -0.017590824514627457,
            -0.013212323188781738,
            -0.060720209032297134,
            0.014186661690473557,
            -0.024260416626930237,
            0.022725654765963554,
            -0.006710750516504049,
            0.007649972569197416,
            -0.0013077713083475828,
            0.013963217847049236,
            -0.012294208630919456,
            0.03708883002400398,
            0.03818388283252716,
            -0.018536774441599846,
            -0.025338858366012573,
            0.013181366957724094,
            0.03786231577396393,
            0.014083289541304111,
            0.017935290932655334,
            -0.03537770360708237,
            0.023939702659845352,
            0.03237480670213699,
            0.0007685668533667922,
            0.012902346439659595,
            -0.025331007316708565,
            0.0013037164462730289,
            -0.008732440881431103,
            0.0017768763937056065,
            -0.005412551108747721,
            0.033734001219272614,
            0.02209034003317356,
            -0.011979720555245876,
            -0.012694117613136768,
            -0.022119825705885887,
            -0.01736222393810749,
            -0.021790677681565285,
            -2.6539166356087662e-05,
            0.024820251390337944,
            0.04330908879637718,
            0.040768712759017944,
            -0.005568959750235081,
            -0.01991632767021656,
            0.028156928718090057,
            0.018176546320319176,
            -0.00870079267770052,
            -0.04480966180562973,
            0.014702443964779377,
            0.016128791496157646,
            0.018443454056978226,
            -0.03608493506908417,
            -0.00617928896099329,
            0.0006893134559504688,
            0.009174574166536331,
            -0.02304544672369957,
            -0.002522068563848734,
            -0.011125456541776657,
            -0.0017457414651289582,
            -0.00242473348043859,
            -0.0018622737843543291,
            0.021112684160470963,
            -0.034364957362413406,
            -0.02851242385804653,
            -0.052531272172927856,
            -0.012925634160637856,
            -0.0261547788977623,
            0.02256772294640541,
            -0.01599828340113163,
            0.04106331989169121,
            0.01409345492720604,
            -0.03008185140788555,
            0.02928605116903782,
            -0.01617078110575676,
            0.011067474260926247,
            -0.0148545503616333,
            0.027852237224578857,
            -0.03364809229969978,
            0.03633886203169823,
            -0.0221486147493124,
            0.016991550102829933,
            -0.012710321694612503,
            0.013434446416795254,
            -0.00479245837777853,
            -0.034238871186971664,
            9.219782441505231e-06,
            0.0020541560370475054,
            -0.0057345847599208355,
            0.006987929809838533,
            -0.036494795233011246,
            0.02007407322525978,
            0.004381502978503704,
            0.07819894701242447,
            0.0020031980238854885,
            -0.023927919566631317,
            -0.022458931431174278,
            0.011782975867390633,
            0.054792847484350204,
            0.016939539462327957,
            0.0028600268997251987,
            0.030214982107281685,
            -0.009646425023674965,
            -0.02862052619457245,
            0.01747872121632099,
            0.014674357138574123,
            -0.010137108154594898,
            -0.018136916682124138,
            0.036682866513729095,
            -0.025722680613398552,
            0.02752726897597313,
            -0.07339029759168625,
            -0.020954634994268417,
            -0.035275474190711975,
            -0.01141559612005949,
            -0.011713153682649136,
            0.010557316243648529,
            0.029353756457567215,
            0.03223048150539398,
            0.03596063330769539,
            0.034700922667980194,
            -0.02214795909821987,
            0.021740738302469254,
            -0.004948411136865616,
            -0.038145892322063446,
            0.019002461805939674,
            0.026311617344617844,
            -0.015346885658800602,
            0.03494570776820183,
            -0.06384802609682083,
            0.015396550297737122,
            0.047124896198511124,
            -0.0575828030705452,
            0.034688882529735565,
            0.01733887381851673,
            0.006265055388212204,
            0.0009382923017255962,
            -0.024483773857355118,
            0.004857266321778297,
            -0.023178864270448685,
            0.024812044575810432,
            0.04898738116025925,
            -0.01902012713253498,
            0.00025523913791403174,
            -0.0036464547738432884,
            0.013004414737224579,
            0.017908820882439613,
            -0.016908718273043633,
            7.270931382663548e-05,
            -0.011398719623684883,
            2.7744785256800242e-05,
            -0.030727749690413475,
            0.012444904074072838,
            -0.0054742866195738316,
            -0.022721637040376663,
            -0.011009891517460346,
            -0.018234696239233017,
            -0.005309582222253084,
            0.034015968441963196,
            0.0019494564039632678,
            0.0004794966953340918,
            0.02223670855164528,
            0.016842667013406754,
            -0.010207678191363811,
            0.024374602362513542,
            -0.022151028737425804,
            0.0005385037511587143,
            -0.006935484707355499,
            -0.013681096024811268,
            0.013542743399739265,
            0.009274465031921864,
            0.012745420448482037,
            -0.003744443878531456,
            -0.01358069758862257,
            0.022862834855914116,
            0.03195302188396454,
            -0.05159713700413704,
            -0.006149738561362028,
            -0.01605367474257946,
            -0.002927070716395974,
            0.017999202013015747,
            0.040354419499635696,
            -0.047314684838056564,
            -0.023720692843198776,
            0.0016134667675942183,
            0.03331369906663895,
            0.020369458943605423,
            -0.02240763045847416,
            -0.0009394310181960464,
            0.011881081387400627,
            -0.0013615802163258195,
            0.034822478890419006,
            -0.01950499229133129,
            0.005374742206186056,
            -0.002196568064391613,
            -0.03650571405887604,
            -0.0162339024245739,
            -0.0014475520001724362,
            0.0206115934997797,
            -0.00485919788479805,
            -0.02147560752928257,
            0.01316517498344183,
            0.04376119747757912,
            0.0033122857566922903,
            -0.029068876057863235,
            -0.00043102505151182413,
            0.010533114895224571,
            -0.01968085952103138,
            -0.03912261128425598,
            -0.024527324363589287,
            0.022890305146574974,
            -0.014149985276162624,
            0.0025189246516674757,
            -0.02138395980000496,
            0.043860796838998795,
            0.003158487845212221,
            0.014629747718572617,
            -0.026147186756134033,
            -0.0020571730565279722,
            0.01786782220005989,
            -0.013134061358869076,
            -0.011572475545108318,
            0.028027916327118874,
            0.03210901468992233,
            -0.0296854879707098,
            -0.030247444286942482,
            -0.01636446826159954,
            -0.036567144095897675,
            -0.015212544240057468,
            0.016835466027259827,
            0.02871670573949814,
            0.04039226099848747,
            0.002014532685279846,
            0.007170680910348892,
            -0.04742377996444702,
            0.03344458341598511,
            -0.023135334253311157,
            -0.012943707406520844,
            -0.011097119189798832,
            0.012268676422536373,
            0.03816716745495796,
            -0.003213707357645035,
            -0.03880741819739342,
            -0.030896298587322235,
            0.0022481794003397226,
            0.04251152276992798,
            -0.013222185894846916,
            0.05051325261592865,
            -0.008480094373226166,
            -0.0006067441427148879,
            -0.011732557788491249,
            -0.0183543860912323,
            -0.005361943040043116,
            0.01874399557709694,
            -0.011265166103839874,
            -0.05952738597989082,
            -0.03216147795319557,
            -0.046705011278390884,
            -0.022206824272871017,
            -0.003107094205915928,
            0.0338967964053154,
            0.03402581438422203,
            -0.007778126746416092,
            0.00024780293460935354,
            -0.007199524901807308,
            -0.014781014993786812,
            0.007782070431858301,
            -0.0024157899897545576,
            -0.006807403173297644,
            -0.005689810961484909,
            -0.054879311472177505,
            0.052529096603393555,
            -0.018907945603132248,
            0.019385594874620438,
            -0.06080150231719017,
            0.0022751351352781057,
            -0.010377763770520687,
            0.028251023963093758,
            -0.009840890765190125,
            0.01512594148516655,
            -0.034211646765470505,
            -0.03732217103242874,
            0.01509314589202404
        ],
        [
            -0.03073616698384285,
            -0.02622678503394127,
            0.016742950305342674,
            -0.01559816300868988,
            -0.02680131420493126,
            -0.011366798542439938,
            -0.012783598154783249,
            -0.004473383072763681,
            -0.020777754485607147,
            -0.0008121839491650462,
            0.011238687671720982,
            -0.00019105988030787557,
            0.012114245444536209,
            -0.0446816086769104,
            0.02202318236231804,
            -0.03978743776679039,
            -0.020934447646141052,
            0.02433108165860176,
            -0.01919003762304783,
            0.032070837914943695,
            -0.009986641816794872,
            0.029023712500929832,
            0.057028599083423615,
            0.018515348434448242,
            -0.02146836183965206,
            0.01818830892443657,
            -0.024317795410752296,
            -0.04268563538789749,
            0.030312521383166313,
            0.00037578510819002986,
            0.004402796737849712,
            0.007938963361084461,
            0.0010362117318436503,
            0.005527898669242859,
            -0.013605247251689434,
            -0.04085257276892662,
            0.020315885543823242,
            -0.015418373979628086,
            0.005660058464854956,
            -0.02966919168829918,
            0.019693776965141296,
            0.013583573512732983,
            0.0019299874547868967,
            0.012925385497510433,
            -0.017252827063202858,
            -0.018780631944537163,
            -0.0028533197473734617,
            -0.001292151166126132,
            0.0145517997443676,
            -0.0108856912702322,
            0.037794459611177444,
            -0.012047077529132366,
            0.024137908592820168,
            0.017663436010479927,
            -0.09682554006576538,
            -0.0009097770671360195,
            -0.003993737045675516,
            0.01912393420934677,
            -0.029423058032989502,
            0.019291400909423828,
            -0.00962277501821518,
            -0.0008935123914852738,
            0.02475730888545513,
            -0.020640522241592407,
            -0.04630483314394951,
            -0.05084171146154404,
            0.013489377684891224,
            -0.002409393899142742,
            -0.006583208218216896,
            -0.03465661406517029,
            -0.01278493832796812,
            0.003923796582967043,
            0.03346662595868111,
            -0.02052377350628376,
            -0.011847415938973427,
            0.00674354238435626,
            0.012289781123399734,
            0.00015587444067932665,
            0.037455081939697266,
            -0.018386900424957275,
            -0.022345377132296562,
            0.01953119784593582,
            -0.018392398953437805,
            0.012634558603167534,
            -0.019850831478834152,
            0.03277376666665077,
            0.001262663514353335,
            0.008298653177917004,
            0.031493160873651505,
            -0.009459280408918858,
            -0.023845668882131577,
            -0.01925491727888584,
            0.013608369044959545,
            -0.03308665007352829,
            -0.0026984442956745625,
            0.008861716836690903,
            -0.03257374092936516,
            -0.028141137212514877,
            0.03563525527715683,
            -0.01943041943013668,
            0.02179817296564579,
            0.006334375590085983,
            -0.02933833748102188,
            -0.012406248599290848,
            0.008339118212461472,
            -0.04387649521231651,
            0.024271221831440926,
            -0.0047558811493217945,
            0.04166591912508011,
            -0.021492760628461838,
            0.06602998077869415,
            0.004000973887741566,
            -0.03542214632034302,
            0.004144590813666582,
            -0.020557932555675507,
            -0.02605806477367878,
            -0.011006385087966919,
            0.03820172697305679,
            -0.015743428841233253,
            -0.01824011467397213,
            0.03267460688948631,
            -0.012322495691478252,
            -0.01999371312558651,
            -0.019422143697738647,
            0.0057304175570607185,
            0.0012113633565604687,
            -0.006356108468025923,
            -0.022872650995850563,
            0.007483322639018297,
            0.0011804806999862194,
            0.02709333412349224,
            -0.01918022707104683,
            -0.0275269765406847,
            -0.0076934839598834515,
            0.013942544348537922,
            0.026366816833615303,
            0.04020128771662712,
            -0.0009249501163139939,
            0.012007283046841621,
            0.0002607657224871218,
            -0.0026973949279636145,
            0.016591526567935944,
            -0.03122098185122013,
            -0.02014528587460518,
            0.03028951957821846,
            0.005212135147303343,
            -0.006445405539125204,
            0.007879906333982944,
            0.0007907578838057816,
            -0.02115067094564438,
            0.017387602478265762,
            0.006215333938598633,
            -0.024585701525211334,
            0.02367723360657692,
            0.01357194036245346,
            -0.0040886253118515015,
            -0.011778594925999641,
            0.029853027313947678,
            0.037395115941762924,
            -0.00710074370726943,
            0.039090387523174286,
            0.02281114272773266,
            0.01388181746006012,
            0.032735053449869156,
            0.011861845850944519,
            -0.024114325642585754,
            -0.03673461079597473,
            -0.05036807805299759,
            0.025120073929429054,
            -0.01624717190861702,
            -0.032066043466329575,
            -0.0037355036474764347,
            0.017662063241004944,
            0.03440706431865692,
            0.017280837520956993,
            -0.021766627207398415,
            -0.03614009916782379,
            -0.041865695267915726,
            -0.032023075968027115,
            0.001538182725198567,
            -0.0062951291911304,
            0.01693805307149887,
            -0.02216414362192154,
            -0.03147824853658676,
            0.033018309623003006,
            0.03681949898600578,
            -0.02944987453520298,
            0.005430877674371004,
            0.03260187804698944,
            0.02003648690879345,
            -0.0074408226646482944,
            -0.02516036108136177,
            -0.06805077195167542,
            0.013414304703474045,
            0.0017926559085026383,
            -0.017192669212818146,
            -0.009563930332660675,
            -0.02098396047949791,
            -0.013601417653262615,
            0.013791111297905445,
            0.013517308980226517,
            0.029844101518392563,
            -0.019121132791042328,
            0.02660895697772503,
            0.027950208634138107,
            0.001797872013412416,
            0.008778386749327183,
            -0.02509959228336811,
            -0.026771189644932747,
            0.027121171355247498,
            0.016148269176483154,
            -0.013801082968711853,
            -0.0014924160204827785,
            -0.002342026447877288,
            0.0280792023986578,
            0.011157488450407982,
            -0.010993222706019878,
            -0.020489193499088287,
            0.014615355990827084,
            -0.029938846826553345,
            -0.020902080461382866,
            0.03470669314265251,
            0.028210973367094994,
            0.020076170563697815,
            -0.01111916359513998,
            -0.06940704584121704,
            0.04795532673597336,
            0.003847934538498521,
            0.03940727934241295,
            0.017841694876551628,
            0.012183566577732563,
            -0.005225989036262035,
            -0.005272450391203165,
            -0.010466133244335651,
            -0.05955924093723297,
            -0.009941047057509422,
            0.056819766759872437,
            -0.029671750962734222,
            -0.03149453178048134,
            0.02951802685856819,
            -0.01618601568043232,
            -0.018469449132680893,
            0.014096402563154697,
            -0.03456497937440872,
            0.012899174354970455,
            0.015381441451609135,
            -0.03527192026376724,
            -0.029084717854857445,
            0.021992312744259834,
            0.012523272074759007,
            0.03480370715260506,
            -0.018527012318372726,
            -0.031359028071165085,
            0.01765419915318489,
            -0.007681189104914665,
            -0.027425168082118034,
            -0.02208542637526989,
            -0.003653409192338586,
            -0.022385863587260246,
            -0.04250003397464752,
            0.042160842567682266,
            -0.003918233793228865,
            -0.02206842042505741,
            -0.03904290869832039,
            -0.016311151906847954,
            -0.08064477890729904,
            -0.006097826641052961,
            -0.013070128858089447,
            -0.022630898281931877,
            -0.015146540477871895,
            0.013344913721084595,
            0.007810317445546389,
            0.020693527534604073,
            0.03738320991396904,
            -0.043460533022880554,
            -0.018870463594794273,
            0.02775966189801693,
            -0.0364079475402832,
            -0.007973060943186283,
            0.025251934304833412,
            -0.014197026379406452,
            0.037639912217855453,
            -0.008760437369346619,
            -0.004630169831216335,
            0.03173932805657387,
            -0.02382473088800907,
            -0.01905185729265213,
            -0.05212097242474556,
            0.015031076036393642,
            -0.03181937336921692,
            0.022222524508833885,
            -0.007589873392134905,
            -0.01994633488357067,
            -0.029663588851690292,
            0.019688094034790993,
            -0.0036819372326135635,
            -0.026591341942548752,
            0.016925524920225143,
            0.007498495746403933,
            -0.004477325826883316,
            -0.024059033021330833,
            -0.016506103798747063,
            0.0019115936011075974,
            0.0005398885696195066,
            -0.010203550569713116,
            0.05575031042098999,
            0.016926292330026627,
            0.029124580323696136,
            -0.018451953306794167,
            0.009915749542415142,
            -0.0498448871076107,
            0.027399510145187378,
            0.003482384141534567,
            -0.03168610855937004,
            -0.006165508646517992,
            -0.02108270861208439,
            -0.009283045306801796,
            -0.005844453349709511,
            -0.005277159623801708,
            -0.015760289505124092,
            0.009163349866867065,
            0.018036579713225365,
            -0.018500305712223053,
            -0.050873205065727234,
            0.028678933158516884,
            0.030057935044169426,
            0.03376372531056404,
            0.0023513175547122955,
            -0.03137985244393349,
            0.01068572886288166,
            0.006368341855704784,
            -0.026962442323565483,
            -0.004068370908498764,
            -0.03909648582339287,
            0.019949574023485184,
            -0.022303393110632896,
            0.006859558168798685,
            0.040571995079517365,
            0.013590817339718342,
            0.01671651378273964,
            0.007712380029261112,
            -0.0059470622800290585,
            0.0002266665396746248,
            0.025641661137342453,
            -0.01726563833653927,
            0.010120882652699947,
            -0.030215846374630928,
            -0.002154512098059058,
            -0.010531546548008919,
            -0.04116779938340187,
            0.0047700549475848675,
            0.01432125922292471,
            -0.001606885576620698,
            -0.010215778835117817,
            -0.016545163467526436,
            -0.004516323562711477,
            -0.03335283324122429,
            0.014211077243089676,
            -0.0020565784070640802,
            0.004823376890271902,
            0.01092500053346157,
            -0.002884072717279196,
            0.028108883649110794,
            0.037394776940345764,
            -0.028328804299235344,
            -0.02207646705210209,
            -0.01068581361323595,
            -0.01268110889941454,
            0.0009581030462868512,
            -0.005382881034165621,
            -0.021034834906458855,
            -0.02148745395243168,
            -0.0037023695185780525,
            -0.018719680607318878,
            0.0031267625745385885,
            -0.030990801751613617,
            -0.018702104687690735,
            0.008749495260417461,
            0.018230009824037552,
            0.0719699040055275,
            0.04725523293018341,
            0.003995344042778015,
            0.006516714580357075,
            0.008428554981946945,
            -0.023572972044348717,
            -0.00582694448530674,
            0.017527787014842033,
            -0.026187468320131302,
            -0.008161856792867184,
            -0.004832618869841099,
            0.035337548702955246,
            0.015039159916341305,
            0.01789076253771782,
            0.02304813079535961,
            0.026650507003068924,
            -0.00962185300886631,
            0.00604350958019495,
            0.005985917057842016,
            0.014319327659904957,
            -0.04092797264456749,
            0.03370615467429161,
            -0.003682604758068919,
            0.02637617476284504,
            0.017466461285948753,
            0.0007742376765236259,
            -0.032473694533109665,
            -0.014148869551718235,
            -0.005033191759139299,
            -0.03148438036441803,
            0.016938084736466408,
            -0.0024862652644515038,
            0.0020571532659232616,
            0.005274333991110325,
            0.01863344945013523,
            0.003243088722229004,
            -0.01293690875172615,
            0.02531679905951023,
            0.01386624202132225,
            0.006157150957733393,
            -0.014787192456424236,
            -0.027249442413449287,
            -0.016009991988539696,
            0.031007638201117516,
            -0.01508090365678072,
            0.02752167545258999,
            -0.047816380858421326,
            -0.02714233286678791,
            -0.017798442393541336,
            0.009168613702058792,
            -0.016820942983031273,
            0.038399890065193176,
            0.028879700228571892,
            -0.02161555550992489,
            -0.041806772351264954,
            0.005349150858819485,
            -0.010705097578465939,
            -0.007744628470391035,
            -0.03268807753920555,
            -0.03803607076406479,
            -0.005331007298082113,
            -0.024187952280044556,
            0.0020599605049937963,
            0.029116692021489143,
            0.02477644756436348,
            -0.015079911798238754,
            0.013921141624450684,
            -0.008437487296760082,
            -0.002873925259336829,
            0.015454364009201527,
            0.006863901391625404,
            -0.013002890162169933,
            0.02230687253177166,
            -0.03997822850942612,
            -0.015181275084614754,
            -0.005328488536179066,
            0.007925205864012241,
            0.040148478001356125,
            0.01933571882545948,
            0.032815515995025635,
            0.01204148679971695,
            0.030394690111279488,
            0.021018842235207558,
            -0.008278477005660534,
            -0.015481063164770603,
            -0.00234945653937757,
            -0.040276288986206055,
            -0.026879524812102318,
            0.0012911168159916997,
            0.005644927266985178,
            0.016126560047268867,
            -0.0029406787361949682,
            0.004718787968158722,
            -0.025860382243990898,
            0.01824238710105419,
            0.034944601356983185,
            0.025442026555538177,
            0.007620869670063257,
            0.019047940149903297,
            -0.03962497413158417,
            0.010367045179009438,
            0.014732235111296177,
            -0.009988964535295963,
            0.003137046005576849,
            -0.0018728218274191022,
            -0.021984269842505455,
            -0.015184185467660427,
            0.00813021045178175,
            -0.010468149557709694,
            -0.028384758159518242,
            0.018274223431944847,
            0.013535170815885067,
            0.025808138772845268,
            0.005670516286045313,
            -0.017662737518548965,
            0.015619156882166862,
            0.0019443415803834796,
            0.016518261283636093,
            -0.025446448475122452,
            -0.020214499905705452,
            0.021025676280260086,
            -0.02352958917617798,
            0.012918727472424507,
            -0.02514738030731678,
            -0.011511032469570637,
            -0.01809653267264366,
            0.00026254021213389933,
            -0.018858429044485092,
            0.018721381202340126,
            0.010914777405560017,
            0.03253110870718956,
            -0.0001750343944877386,
            -0.05153083801269531,
            0.029055576771497726,
            0.027294840663671494,
            -0.005614636465907097,
            -0.03357001394033432,
            0.007465285714715719,
            -0.0011321926722303033,
            -0.038080714643001556,
            -0.048923201858997345,
            -0.02506626956164837,
            -0.005129198078066111,
            0.005675718653947115,
            0.03109610266983509,
            0.010890637524425983,
            -0.019930468872189522,
            0.0947125032544136,
            -0.006384202279150486,
            -0.028162885457277298,
            0.006354326382279396,
            -0.017796572297811508,
            -0.04204113408923149,
            -0.02296331338584423,
            0.01839461550116539,
            -0.014566128142178059,
            0.017633792012929916,
            -0.02455369010567665,
            -0.013214360922574997,
            -0.019489288330078125,
            0.0016431356780231,
            0.0041481261141598225,
            -0.014530021697282791,
            -0.015247426927089691,
            0.02352260984480381,
            -0.02173263020813465,
            -0.037616562098264694,
            -0.008851631544530392,
            -0.050594527274370193,
            0.010495446622371674,
            0.0014111793134361506,
            -0.025139857083559036,
            -0.001408969983458519,
            -0.0049373391084373,
            0.030360188335180283,
            -0.01145170908421278,
            -0.01983065716922283,
            -0.03995411843061447,
            -0.0274437814950943,
            0.00924406386911869,
            0.006274248473346233,
            -0.007049460429698229,
            -0.009364316239953041,
            -0.00833149068057537,
            0.028682535514235497,
            -0.05368918180465698,
            -0.010919845663011074,
            0.02603956311941147,
            -0.018735073506832123,
            -0.004273454193025827,
            -0.023726128041744232,
            0.004407793749123812,
            -0.007432086393237114,
            -0.02845132164657116,
            -0.047796208411455154,
            -0.026519853621721268,
            -0.01977856084704399,
            0.03522775322198868,
            0.042553987354040146,
            0.05398143082857132,
            -0.0403209812939167,
            0.0160964448004961,
            0.011745316907763481,
            0.03242084011435509,
            0.009649976156651974,
            0.0221572183072567,
            0.012415056116878986,
            -0.006338937673717737,
            -0.017191419377923012,
            -0.031123662367463112,
            -0.00953007023781538,
            0.01782303862273693,
            0.015606296248733997,
            -0.013781662099063396,
            0.020209629088640213,
            0.009745319373905659,
            -0.014910009689629078,
            -0.021204203367233276,
            0.02744676172733307,
            -0.018247466534376144,
            -0.016804611310362816,
            0.013635873794555664,
            0.0012043747119605541,
            -0.007688065059483051,
            -0.0030189738608896732,
            0.012678887695074081,
            -4.703050944954157e-05,
            -0.0034334161318838596,
            0.0276446845382452,
            -0.03711264580488205,
            0.007628617342561483,
            0.02414206601679325,
            0.007922697812318802,
            -0.01743200235068798,
            0.008787422440946102,
            0.02081357128918171,
            -0.030128126963973045,
            -0.007892293855547905,
            -0.00493982108309865,
            -0.02936439961194992,
            -0.002623488660901785,
            0.002841734094545245,
            -0.005276505835354328,
            0.03066699206829071,
            -0.026049014180898666,
            0.012491610832512379,
            -0.022382596507668495,
            -0.013451308943331242,
            0.018405141308903694,
            -0.002660739468410611,
            0.023606272414326668,
            0.022048495709896088,
            -0.020195765420794487,
            -0.019292589277029037,
            0.014970870688557625,
            0.0023114082869142294,
            -0.017489653080701828,
            -0.031542155891656876,
            0.017424361780285835,
            -0.016483355313539505,
            0.025873830541968346,
            0.016720561310648918,
            -0.0397447906434536,
            0.0017429270083084702,
            0.015776265412569046,
            -0.001394362305290997,
            0.0227571502327919,
            0.03915706276893616,
            0.024453047662973404,
            0.027130287140607834,
            0.009969226084649563,
            -0.017336536198854446,
            -0.013467085547745228,
            -0.05080169811844826,
            -0.006682056002318859,
            -0.04239008203148842,
            -0.0020742611959576607,
            0.016136305406689644,
            -0.02231833152472973,
            -0.012505699880421162,
            -0.012479516677558422,
            -0.0460764616727829,
            0.022493338212370872,
            -0.017573639750480652,
            0.03326774388551712,
            -0.01281993929296732,
            -0.0023749414831399918,
            -0.010474853217601776,
            0.02238377369940281,
            0.015805449336767197,
            -0.015675216913223267,
            0.0039565530605614185,
            -0.043500158935785294,
            0.03648807853460312,
            0.014903261326253414,
            0.01896633766591549,
            -0.01798965036869049,
            -0.02804248034954071,
            -0.01038008276373148,
            -0.002698166063055396,
            0.013485771603882313,
            0.033084988594055176,
            0.007200710475444794,
            -0.0025592383462935686,
            -0.01035638153553009,
            -0.023709842935204506,
            0.018618416041135788,
            -0.016142040491104126,
            -0.02733701653778553,
            0.0209268219769001,
            -0.01074335165321827,
            0.05004185065627098,
            0.047266386449337006,
            -0.03938044235110283,
            0.028623569756746292,
            0.031912483274936676,
            -0.040010616183280945,
            -0.010746707208454609,
            -0.02054998092353344,
            0.017628982663154602,
            0.021145088598132133,
            -0.034337904304265976,
            -0.006979532074183226,
            -0.007684111129492521,
            0.007872083224356174,
            0.031981900334358215,
            0.02022968977689743,
            0.020433666184544563,
            -0.00916993897408247,
            0.00044388556852936745,
            0.014289425686001778,
            -0.01825134828686714,
            -0.04197663813829422,
            0.00615064287558198,
            -0.0037258670199662447,
            0.02777901664376259,
            0.03222374990582466,
            0.024691417813301086,
            0.043093759566545486,
            0.010089169256389141,
            -0.02753196842968464,
            -0.009520407766103745,
            0.00889354944229126,
            0.014668290503323078,
            0.002383168786764145,
            -0.00039339816430583596,
            -0.0011604087194427848,
            0.0002682814665604383,
            0.0005649842205457389,
            -0.010332731530070305,
            -0.00497758574783802,
            0.0024841269478201866,
            -0.028574034571647644,
            0.01584959402680397,
            0.033272646367549896,
            0.02343112602829933,
            0.009033586829900742,
            0.002795208478346467,
            -0.0361606664955616,
            -0.007256960030645132,
            -0.018710248172283173,
            -0.028156759217381477,
            -0.002581571461632848,
            0.026047751307487488,
            0.03163884952664375,
            -0.033420901745557785,
            0.013151563704013824,
            -0.038095779716968536,
            -0.005813482217490673,
            0.0015765813877806067,
            -0.015843961387872696,
            -0.006618211045861244,
            -0.007192893885076046,
            -0.009139526635408401,
            -0.04411787539720535,
            0.0291756521910429,
            -0.004935974720865488,
            -0.007956311106681824,
            0.026271123439073563,
            0.024538641795516014,
            -0.018682308495044708,
            0.007294530048966408,
            -0.0031252505723387003,
            0.021614542230963707,
            0.006188687402755022,
            -0.0012556310975924134,
            0.04584672302007675,
            -0.027430931106209755,
            0.009472595527768135,
            0.00018957302381750196,
            0.016039349138736725,
            0.022120336070656776,
            -0.03569110110402107,
            0.018054157495498657,
            -0.024917593225836754,
            0.002155671129003167,
            0.050671614706516266,
            -0.008617652580142021,
            -0.042616382241249084,
            0.015846196562051773,
            -0.027047935873270035,
            0.044909097254276276,
            0.02308622933924198,
            -0.008140342310070992,
            0.01757277548313141,
            -0.012442570179700851,
            -0.056794531643390656,
            -0.046758681535720825,
            -0.009729547426104546,
            0.013453696854412556,
            0.05296732857823372,
            -0.003725508227944374,
            0.015350754372775555,
            -0.022128185257315636,
            -0.0022624025586992502,
            0.02430606074631214,
            -0.034596655517816544,
            -0.011972975917160511,
            0.00030959188006818295,
            -0.03122788295149803,
            0.015242290683090687,
            -0.07967052608728409,
            -0.03380441665649414,
            -0.03446439281105995,
            -0.02430427446961403,
            0.008791066706180573,
            0.011428924277424812,
            -0.0325532890856266,
            0.03292159363627434,
            -0.008525602519512177,
            -0.03492222726345062,
            0.05304461717605591,
            -0.034513577818870544,
            -0.01922314427793026,
            0.019519802182912827,
            0.01285585854202509,
            0.020581558346748352,
            0.016707664355635643,
            -0.037722427397966385,
            -0.030838236212730408,
            -0.018431859090924263,
            -0.030871588736772537,
            -0.015221938490867615,
            0.025600720196962357,
            0.013861282728612423,
            0.004995799623429775,
            0.03360171988606453,
            0.0005910035106353462,
            -0.023892974480986595,
            0.024662986397743225,
            0.025317538529634476,
            -0.04010246694087982,
            0.019668448716402054,
            -0.057932838797569275,
            0.039302244782447815,
            -0.025269480422139168,
            0.020234450697898865,
            -0.011922446079552174,
            0.009460109286010265,
            0.015739882364869118,
            -0.023282187059521675,
            0.02739354409277439,
            0.010675641708076,
            0.021093688905239105,
            0.02430466003715992,
            -0.00974159874022007,
            -0.0445321649312973,
            -0.027109486982226372,
            0.011628168635070324,
            -0.008762819692492485,
            0.01644817180931568,
            0.0073169502429664135,
            -0.021695636212825775,
            -0.008694492280483246,
            -0.0029409322887659073,
            -0.029513083398342133,
            -0.02189377136528492,
            -0.031409502029418945,
            -0.004177427850663662,
            0.049391720443964005,
            -0.00628234725445509,
            0.00188864313531667,
            -0.014445152133703232,
            0.03392605483531952,
            0.08266029506921768,
            0.015309600159525871,
            -0.010573584586381912,
            0.019995126873254776,
            -0.013274469412863255,
            -0.00807233341038227,
            0.02336236461997032,
            -0.011655470356345177,
            -0.012856746092438698,
            -0.014196920208632946,
            0.021706510335206985,
            -0.044998958706855774,
            -0.0063018882647156715,
            0.010101371444761753,
            -0.024694304913282394,
            -0.017345188185572624,
            0.01792863756418228,
            0.014205428771674633,
            -0.010740334168076515,
            0.030552025884389877,
            -0.005425088573247194,
            0.007270526140928268,
            -0.0496700145304203,
            0.02573658898472786,
            0.031326774507761,
            -0.00906143058091402,
            0.03179360553622246,
            0.0013744411990046501,
            0.01571854017674923,
            -0.000136018919874914,
            0.0015971949324011803,
            -0.007224901579320431,
            -0.023771153762936592,
            0.011325585655868053,
            0.013952367939054966,
            -0.009809259325265884,
            -0.04369213804602623,
            0.011921724304556847,
            -0.0306936576962471,
            0.02502497099339962,
            0.00526009825989604,
            -0.021323345601558685,
            -0.01654314436018467,
            0.013058164156973362,
            0.0022467097733169794,
            0.0032492931932210922,
            0.022264555096626282,
            0.015592480078339577,
            0.008326555602252483,
            0.007006749976426363,
            -0.04292865842580795,
            -0.0541934072971344,
            0.002165751066058874,
            0.054178379476070404,
            -0.004002396948635578,
            0.014982626773416996,
            -0.0035927423741668463,
            -0.017739955335855484,
            0.021901480853557587,
            0.01113002747297287,
            0.018605869263410568,
            0.013883574865758419,
            0.011513172648847103,
            0.0008515826775692403,
            -0.00908057764172554,
            0.03791692107915878,
            -0.03688894212245941,
            -0.030193490907549858,
            -0.014716997742652893,
            -0.03566357120871544,
            -0.019366320222616196,
            0.06528887897729874,
            0.021959632635116577,
            0.03804585337638855,
            -0.015144548378884792,
            0.013885070569813251,
            -0.02167539671063423,
            0.02380199171602726,
            -0.003215487115085125,
            -0.024289041757583618,
            -0.003442650893703103,
            -0.03915191814303398,
            -0.010098856873810291,
            -0.005778131075203419,
            0.005141887813806534,
            -0.006147813517600298,
            -0.022118279710412025,
            0.01442142017185688,
            0.010473974049091339,
            0.018046719953417778,
            0.0016616994980722666,
            0.03334704786539078,
            0.019581908360123634,
            0.005902732256799936,
            -0.0058845030143857,
            0.06448380649089813,
            -0.03121080808341503,
            -0.0018824493745341897,
            0.008623328059911728,
            0.025057122111320496,
            -0.009090403094887733,
            -0.008035418577492237,
            0.028253043070435524,
            -0.04473463445901871,
            -0.005425846204161644,
            0.006083485204726458,
            -0.025324730202555656,
            -0.022223489359021187,
            0.01767697185277939,
            -0.01489250548183918,
            0.02154456079006195,
            0.018330920487642288,
            -0.0026218374259769917,
            -0.05025526136159897,
            -0.008128184825181961,
            -0.032814718782901764,
            -0.007213943637907505,
            -0.019735177978873253,
            -0.002781391842290759,
            0.026224372908473015,
            0.011296667158603668,
            0.023603657260537148,
            -0.015529287047684193,
            -0.022079147398471832,
            0.011501792818307877,
            0.007202912587672472,
            -0.008913259021937847,
            -0.0507173016667366,
            0.0021016073878854513,
            0.02137618698179722,
            -0.03930499777197838,
            0.015778932720422745,
            -0.007587212137877941,
            -0.016375310719013214,
            0.01086599100381136,
            0.005070064682513475,
            0.02452215924859047,
            0.007608519867062569,
            -0.05431344360113144,
            -0.0033821980468928814,
            -0.004682542756199837,
            0.00020204305474180728,
            -0.015827056020498276,
            0.015895957127213478,
            0.015088616870343685,
            0.01270974613726139,
            0.03174137324094772,
            0.01569868065416813,
            0.007176769431680441,
            0.01623324304819107,
            -0.021911226212978363,
            0.00023586052702739835,
            -0.03208195045590401,
            0.00899121817201376,
            -0.015006261877715588,
            -0.0068232216872274876,
            0.024119216948747635,
            0.048027049750089645,
            -0.049271322786808014,
            0.036854878067970276,
            -0.04705074429512024
        ],
        [
            -0.011522452346980572,
            -0.012490175664424896,
            0.0014550412306562066,
            -0.0019411527318879962,
            -0.0042756651528179646,
            0.03740622475743294,
            0.014787781983613968,
            -0.0393262654542923,
            -0.008301995694637299,
            -0.020562143996357918,
            -0.056881342083215714,
            -0.011122374795377254,
            -0.017246337607502937,
            0.01505883689969778,
            -0.026895271614193916,
            -0.0019732594955712557,
            -0.026599913835525513,
            -0.01085857953876257,
            -0.04565117135643959,
            -0.022029360756278038,
            -0.02945401333272457,
            0.030279578641057014,
            0.05575146898627281,
            -0.013679356314241886,
            -0.004688283894211054,
            -0.04328424483537674,
            0.007529090624302626,
            -0.026658611372113228,
            0.037104956805706024,
            0.03996075317263603,
            -0.0319996140897274,
            0.009963191114366055,
            -0.02366112731397152,
            -0.010825477540493011,
            0.023066919296979904,
            0.05132788047194481,
            -0.04211924225091934,
            -0.02401096001267433,
            0.0035750707611441612,
            0.014035897329449654,
            -0.029705744236707687,
            0.01985066384077072,
            0.03658081963658333,
            -0.018544849008321762,
            -0.024365520104765892,
            -0.0010508345440030098,
            0.024634841829538345,
            -0.010535156354308128,
            -0.024702223017811775,
            -0.04458579793572426,
            0.012597517110407352,
            0.0006949444650672376,
            0.017434271052479744,
            -0.008207379840314388,
            -0.04388183727860451,
            -0.00672112125903368,
            0.0034203436225652695,
            0.024781091138720512,
            0.010822247713804245,
            -0.0026489938609302044,
            0.01612732745707035,
            0.02229294180870056,
            0.045273035764694214,
            -0.0057416995987296104,
            0.013125406578183174,
            0.018491575494408607,
            0.0057109505869448185,
            0.003703746246173978,
            -0.03579986095428467,
            -0.02008073218166828,
            -0.006434538867324591,
            0.02250525914132595,
            0.031686700880527496,
            0.022919822484254837,
            0.04887732118368149,
            -0.0037535701412707567,
            -0.0014307348756119609,
            0.017197836190462112,
            0.02214137651026249,
            0.026192810386419296,
            -0.013589347712695599,
            0.050017617642879486,
            -0.006888196803629398,
            -0.026867665350437164,
            -0.0020807485561817884,
            -0.005743746180087328,
            0.03273281455039978,
            0.0020869034342467785,
            0.024769561365246773,
            -0.001958152512088418,
            0.030491940677165985,
            0.020283102989196777,
            0.024044735357165337,
            0.03367253765463829,
            0.0037796511314809322,
            0.011494257487356663,
            -0.019681936129927635,
            -0.011383610777556896,
            0.00421241857111454,
            -0.023347653448581696,
            -0.008787640370428562,
            0.01679336279630661,
            -0.02399432845413685,
            -0.01017541904002428,
            -0.02942393161356449,
            0.03500225022435188,
            0.01012885756790638,
            -0.021796032786369324,
            -0.008413209579885006,
            -0.007800429128110409,
            -0.03804502636194229,
            -0.017284812405705452,
            0.012155965901911259,
            -0.017180636525154114,
            -0.016527250409126282,
            -0.011238490231335163,
            0.018448464572429657,
            -0.03142095357179642,
            0.01718197762966156,
            -0.02470727078616619,
            0.011652473360300064,
            0.027610348537564278,
            -0.028344742953777313,
            0.01089327409863472,
            -0.007815215736627579,
            0.01334595587104559,
            0.029311461374163628,
            0.015064563602209091,
            0.012412150390446186,
            -0.005952523089945316,
            -0.0275870468467474,
            -0.04651883617043495,
            -0.030574679374694824,
            -0.0007417931337840855,
            -0.03586430102586746,
            0.004068394191563129,
            -0.010127125307917595,
            -0.016747545450925827,
            0.0016933218576014042,
            -0.024956203997135162,
            0.0006783859571442008,
            -0.03357848897576332,
            0.019866788759827614,
            0.018794933333992958,
            -0.042556487023830414,
            -0.02117682248353958,
            -0.02335340902209282,
            -0.034593742340803146,
            -0.028595436364412308,
            0.011698818765580654,
            0.016604816541075706,
            0.007010613102465868,
            0.004627421498298645,
            -0.08253767341375351,
            0.0055298879742622375,
            0.026122650131583214,
            0.050322141498327255,
            -0.020355738699436188,
            0.0549454428255558,
            -0.007130813784897327,
            -0.02168470434844494,
            -0.03286868706345558,
            -0.02645181678235531,
            0.006329111289232969,
            -0.015040012076497078,
            0.002256978303194046,
            0.04232465848326683,
            -0.02939452975988388,
            0.014112377539277077,
            -0.03235742077231407,
            -0.025624416768550873,
            0.01252453587949276,
            0.05822506919503212,
            0.0003594763402361423,
            -0.013612535782158375,
            0.03592076897621155,
            0.02980400063097477,
            -0.008170336484909058,
            0.03266535699367523,
            -0.04020583629608154,
            0.006434726994484663,
            -0.013836804777383804,
            -0.01543518342077732,
            -0.011224987916648388,
            0.016768503934144974,
            -0.011150739155709743,
            -0.008174067363142967,
            -0.004850772209465504,
            -0.019221672788262367,
            0.015488688834011555,
            0.002888549119234085,
            0.012400949373841286,
            -0.06943143159151077,
            -0.012701090425252914,
            0.020507797598838806,
            0.026483627036213875,
            0.012921122834086418,
            0.018657974898815155,
            -0.02328924648463726,
            0.021083848550915718,
            -0.023335343226790428,
            -0.017081577330827713,
            -0.0432673804461956,
            0.0218220055103302,
            -0.012041905894875526,
            -0.005810741800814867,
            -0.010863736271858215,
            0.0012038658605888486,
            -0.008087696507573128,
            0.006093060597777367,
            -0.0010688824113458395,
            -0.015446719713509083,
            0.007297945208847523,
            0.018231963738799095,
            -0.02017364278435707,
            0.0019098363118246198,
            -0.005479880142956972,
            -0.020522799342870712,
            -0.00734123308211565,
            -0.013924052938818932,
            -0.010074956342577934,
            0.021836858242750168,
            0.01435765903443098,
            -0.0045958333648741245,
            0.021388258785009384,
            0.04748537763953209,
            0.045516300946474075,
            0.023966506123542786,
            -0.018430892378091812,
            -0.0023441636003553867,
            -0.03760000318288803,
            0.01593697816133499,
            0.0325007438659668,
            -0.0005045707221142948,
            0.027384331449866295,
            0.022279424592852592,
            -0.05434992164373398,
            -0.025559717789292336,
            0.0011875506024807692,
            -0.012140847742557526,
            -0.0003504330525174737,
            0.019090432673692703,
            0.030073067173361778,
            -0.03411184623837471,
            -0.0045645572245121,
            -0.022267626598477364,
            -0.026747295632958412,
            -0.04370449483394623,
            0.02178325690329075,
            -0.019609136506915092,
            -0.020789051428437233,
            0.030013304203748703,
            0.0007450205739587545,
            -0.016339963302016258,
            -0.02286035381257534,
            -0.01348404586315155,
            0.009925007820129395,
            -0.027262616902589798,
            0.020337538793683052,
            0.0007805708446539938,
            0.006622418761253357,
            0.006371148396283388,
            0.03249243646860123,
            0.012732750736176968,
            0.027747174724936485,
            0.020071959123015404,
            0.019229557365179062,
            -0.03688865154981613,
            -0.010924064554274082,
            -0.0038456053007394075,
            -0.030289288610219955,
            0.0030857776291668415,
            -0.03419363871216774,
            0.03528999537229538,
            0.037022050470113754,
            0.03949589282274246,
            0.013356942683458328,
            0.016538944095373154,
            -0.0009428467019461095,
            -0.0250062495470047,
            0.06287840753793716,
            -0.011740408837795258,
            -0.0017882679821923375,
            0.04099954664707184,
            0.0006375444936566055,
            -0.006189269945025444,
            0.026326114311814308,
            0.0573183111846447,
            -0.028684252873063087,
            0.005910705775022507,
            0.02480485662817955,
            -0.019497815519571304,
            0.012038751505315304,
            -0.019867325201630592,
            0.029638921841979027,
            0.005825677886605263,
            -0.009079989977180958,
            0.01355596724897623,
            -0.0016256968956440687,
            -0.026868881657719612,
            -0.01930582895874977,
            0.030377402901649475,
            0.018935874104499817,
            0.01652773842215538,
            0.0022825642954558134,
            0.001216331496834755,
            -0.0028625752311199903,
            0.001726676244288683,
            0.015474860556423664,
            0.0012991499388590455,
            0.04779820516705513,
            0.022769557312130928,
            0.012171669863164425,
            -0.036405161023139954,
            -0.028706226497888565,
            -0.009383952245116234,
            0.0027739829383790493,
            -0.02453995868563652,
            -0.01874157227575779,
            -0.009066128171980381,
            0.01085299625992775,
            -0.012171897105872631,
            0.06139175966382027,
            -0.02634189836680889,
            -0.02974419854581356,
            0.01956848055124283,
            0.031324177980422974,
            -0.0015479963039979339,
            0.010913771577179432,
            0.021015744656324387,
            -0.002045477507635951,
            0.024220598861575127,
            -0.03680574148893356,
            0.0347776785492897,
            0.028516363352537155,
            0.028164619579911232,
            0.014239316806197166,
            -0.0036645575892180204,
            -0.011851905845105648,
            -0.021932918578386307,
            0.03073541261255741,
            0.009055125527083874,
            0.018604980781674385,
            -0.011078056879341602,
            0.008485617116093636,
            -0.07434457540512085,
            -0.02019423618912697,
            0.01832445152103901,
            0.02956749126315117,
            0.024882636964321136,
            -0.02591099962592125,
            -0.005667755380272865,
            -0.007776264566928148,
            0.03322496637701988,
            -0.004279880318790674,
            -0.010144410654902458,
            0.006920086219906807,
            -0.041369177401065826,
            0.013831251300871372,
            -0.042556215077638626,
            -0.00019214602070860565,
            0.0066155302338302135,
            0.02126644365489483,
            0.022027743980288506,
            0.03963170945644379,
            -0.008199428208172321,
            0.026319747790694237,
            -0.02542325109243393,
            0.009849201887845993,
            0.005919021088629961,
            -0.032018400728702545,
            0.007866610772907734,
            -0.025900207459926605,
            0.01962873712182045,
            0.009691010229289532,
            0.01779392920434475,
            0.007087583653628826,
            -0.006104111671447754,
            0.01117788814008236,
            -0.019031032919883728,
            0.015308246947824955,
            0.027225874364376068,
            -0.016711220145225525,
            0.002909070812165737,
            0.0038297660648822784,
            -0.03194178640842438,
            0.012020782567560673,
            -0.014065875671803951,
            -0.017426948994398117,
            0.008917449042201042,
            0.006837704684585333,
            -0.015342703089118004,
            0.004142641555517912,
            0.005225141998380423,
            -0.004383448511362076,
            0.022446254268288612,
            -0.02083195559680462,
            -0.0031024394556879997,
            0.025499358773231506,
            0.0020358022302389145,
            0.003013045759871602,
            0.020019136369228363,
            -0.01764802075922489,
            0.007407492958009243,
            -0.011732488870620728,
            -0.0016501471400260925,
            0.029592236503958702,
            -0.006702542304992676,
            0.00833688210695982,
            -0.0182131789624691,
            0.0031654234044253826,
            -0.0464833602309227,
            0.011229104362428188,
            0.0633632019162178,
            -0.006710967049002647,
            -0.041882991790771484,
            0.012370285578072071,
            -0.023959236219525337,
            -0.036267511546611786,
            -0.0015152654377743602,
            -0.004167541861534119,
            0.038496553897857666,
            0.0006319452659226954,
            -0.014856469817459583,
            -0.010940165258944035,
            -0.011199352331459522,
            0.012856418266892433,
            0.017337076365947723,
            0.025306129828095436,
            -0.01114105898886919,
            -0.0017542321002110839,
            -0.004832322243601084,
            0.006112449802458286,
            -0.02672145701944828,
            -0.002938266610726714,
            0.015053533017635345,
            0.01174786314368248,
            -0.010705404914915562,
            0.024149945005774498,
            0.019568320363759995,
            0.004294969607144594,
            0.003201441839337349,
            -0.031798578798770905,
            0.03762339800596237,
            0.027345336973667145,
            0.008985122665762901,
            0.021611714735627174,
            0.019467506557703018,
            0.032532818615436554,
            0.01894664578139782,
            -0.010252438485622406,
            -0.018269071355462074,
            0.003503774991258979,
            -0.01814056560397148,
            0.007050677668303251,
            0.03922094404697418,
            -0.037913642823696136,
            -0.0015959562733769417,
            -0.01782313734292984,
            0.02879752591252327,
            -0.012492784298956394,
            -0.025966739282011986,
            0.016287704929709435,
            0.0023440797813236713,
            -0.014033758081495762,
            0.025869833305478096,
            0.015623131766915321,
            0.017660340294241905,
            -0.013577454723417759,
            -0.027902653440833092,
            0.019855597987771034,
            -0.03647971525788307,
            0.015102692879736423,
            0.0006439177086576819,
            -0.05053500458598137,
            -0.009841621853411198,
            -0.00760128628462553,
            0.018467608839273453,
            -0.015020478516817093,
            0.02799242176115513,
            -0.013103815726935863,
            0.0103078568354249,
            -0.021122939884662628,
            -0.022433867678046227,
            0.00923184771090746,
            -0.014814561232924461,
            -0.025856981053948402,
            -0.014726945199072361,
            0.005660372786223888,
            0.01746150478720665,
            0.035638969391584396,
            0.016344470903277397,
            -0.033864084631204605,
            0.03170889988541603,
            0.011806357651948929,
            0.01603899709880352,
            0.01735103316605091,
            -0.0049809785559773445,
            0.033814311027526855,
            -0.004788643680512905,
            0.014174338430166245,
            0.011665788479149342,
            0.03293129801750183,
            -0.01739504188299179,
            -0.004775864537805319,
            -0.012351816520094872,
            0.023135384544730186,
            -0.00478399870917201,
            -0.022566549479961395,
            0.021403754130005836,
            0.023185675963759422,
            -0.017958132550120354,
            -0.004117161966860294,
            -0.0027288261335343122,
            -0.015225895680487156,
            0.01372164860367775,
            0.0037135856691747904,
            0.010321184992790222,
            -0.0007622740231454372,
            0.009273351170122623,
            0.03616546094417572,
            -0.005345257930457592,
            0.008648215793073177,
            -0.0007093081367202103,
            0.07663910835981369,
            -0.028914058580994606,
            0.0021247221156954765,
            0.0068346597254276276,
            0.007894223555922508,
            0.041141606867313385,
            0.015512808226048946,
            0.022763490676879883,
            -0.03849497810006142,
            -0.02060755528509617,
            0.031245674937963486,
            -0.014974407851696014,
            -0.03211607411503792,
            -0.021127844229340553,
            0.006571603938937187,
            -0.033638983964920044,
            -0.001234866795130074,
            -0.006056773476302624,
            -0.01735725998878479,
            -0.007452892605215311,
            0.011995051056146622,
            -0.07446614652872086,
            -0.013888983055949211,
            -0.040915682911872864,
            0.009653951972723007,
            0.016826605424284935,
            -0.0300447978079319,
            0.020182784646749496,
            0.00805086363106966,
            -0.008932455442845821,
            -0.012638634070754051,
            0.01611248217523098,
            0.003097302047535777,
            0.021443048492074013,
            0.01194592472165823,
            0.00953749381005764,
            -0.002230977639555931,
            -0.02311784215271473,
            0.003819367615506053,
            0.0096592977643013,
            -0.012716908939182758,
            0.038051359355449677,
            -0.01453061867505312,
            0.037827018648386,
            -0.023406365886330605,
            -0.020685339346528053,
            -0.001509551191702485,
            -0.016568085178732872,
            -0.015300100669264793,
            -0.025647083297371864,
            -0.012703274376690388,
            -0.004504171200096607,
            -0.014886613003909588,
            -0.032466135919094086,
            0.01054640393704176,
            0.015373192727565765,
            0.024703633040189743,
            0.007474097423255444,
            -0.031878016889095306,
            0.016674283891916275,
            -0.016146255657076836,
            -0.01172318123281002,
            0.022205300629138947,
            -0.0029070554301142693,
            0.00845152698457241,
            0.019385717809200287,
            -0.019054604694247246,
            0.006518046837300062,
            0.0037410594522953033,
            -0.029152145609259605,
            0.009550411254167557,
            0.004400467965751886,
            0.04067283496260643,
            -0.0258987657725811,
            -0.0039468794129788876,
            0.023044399917125702,
            -0.0008330196142196655,
            0.006574416067451239,
            0.02209797501564026,
            -0.02253815345466137,
            0.010657384060323238,
            -0.00835308339446783,
            -0.02695629745721817,
            -0.0382840521633625,
            -0.0332590751349926,
            0.007311290130019188,
            -0.009097551926970482,
            0.02385975979268551,
            0.024199260398745537,
            0.0010731243528425694,
            -0.010893142782151699,
            -0.033085547387599945,
            0.01445536594837904,
            0.02988695167005062,
            -0.0056896633468568325,
            -0.019163809716701508,
            0.007151150610297918,
            -0.03654074668884277,
            0.02675667405128479,
            -0.023416979238390923,
            0.028429005295038223,
            -0.02369084022939205,
            -0.0472533255815506,
            0.03162912651896477,
            -0.0024242177605628967,
            0.01223503053188324,
            -0.00648146029561758,
            -0.01688467152416706,
            0.002077569952234626,
            0.013027728535234928,
            0.0249447263777256,
            -0.013032902963459492,
            0.0028193413745611906,
            0.03643369674682617,
            -0.0317687913775444,
            -0.024014413356781006,
            -0.009582399390637875,
            0.004283549729734659,
            -0.04269246384501457,
            0.04349083825945854,
            -0.017849931493401527,
            0.02515939436852932,
            0.047377534210681915,
            0.007491514086723328,
            0.02606874331831932,
            0.026327094063162804,
            0.02584134228527546,
            -0.025489866733551025,
            0.0046543339267373085,
            0.013330308720469475,
            0.002846603514626622,
            0.009780274704098701,
            0.04644947871565819,
            0.016410047188401222,
            0.04820752143859863,
            -0.022638477385044098,
            0.0011630381923168898,
            0.025897497311234474,
            -0.013059860095381737,
            -0.01278818678110838,
            0.01644514687359333,
            0.002971615409478545,
            -0.011691977269947529,
            0.02657085470855236,
            0.00206176214851439,
            0.02120903506875038,
            -0.035973139107227325,
            0.026200145483016968,
            -0.02310178242623806,
            -0.06622324883937836,
            0.020093927159905434,
            -0.03982533887028694,
            0.02578248269855976,
            0.0004256984975654632,
            -0.013155977241694927,
            3.556055889930576e-05,
            0.008141291327774525,
            0.015539918094873428,
            -0.032835911959409714,
            0.014081674627959728,
            -0.03085358440876007,
            0.011243720538914204,
            0.019783496856689453,
            -0.04522564634680748,
            0.023140916600823402,
            0.0076499804854393005,
            0.004451192449778318,
            0.027478812262415886,
            0.0212851632386446,
            0.015765249729156494,
            -0.025313444435596466,
            0.01087068673223257,
            0.0030400389805436134,
            0.01832551136612892,
            0.008321061730384827,
            0.03903549909591675,
            -0.02330743335187435,
            -0.02720768190920353,
            -0.027392365038394928,
            0.018981589004397392,
            0.011468145065009594,
            0.04437299445271492,
            0.0010521472431719303,
            0.01637851446866989,
            0.0020114746876060963,
            0.023291023448109627,
            -0.002046366687864065,
            0.02644309587776661,
            -0.03204530477523804,
            -0.013133645057678223,
            0.02288459613919258,
            0.007811563555151224,
            0.00584371155127883,
            -0.025156883522868156,
            0.00894943904131651,
            -0.020385654643177986,
            -0.0022504746448248625,
            0.0022727488540112972,
            -0.004414260387420654,
            -0.005053043831139803,
            -0.00014228491636458784,
            0.0019528103293851018,
            0.0016366617055609822,
            0.011899342760443687,
            -0.0016657774103805423,
            -0.03548765927553177,
            0.013557139784097672,
            0.018987083807587624,
            -0.007793969940394163,
            0.02058725617825985,
            0.011668707244098186,
            -0.002897649072110653,
            -0.00144304393325001,
            -0.014846858568489552,
            0.020868759602308273,
            -0.012902933172881603,
            0.0063268099911510944,
            -0.007271361071616411,
            -0.026600001379847527,
            0.01821107044816017,
            -0.03364449366927147,
            0.007092826999723911,
            -0.0016907680546864867,
            0.03564556688070297,
            -0.004339025821536779,
            -0.017160434275865555,
            0.02004256844520569,
            -0.0001780232269084081,
            -0.02445392869412899,
            0.022945458069443703,
            0.008617798797786236,
            0.01301691122353077,
            0.03427184000611305,
            -0.022718576714396477,
            -0.008723560720682144,
            0.029543664306402206,
            0.03017379902303219,
            -0.009497838094830513,
            -0.04682186618447304,
            -0.0019145465921610594,
            -0.030473804101347923,
            -0.035958245396614075,
            0.012193538248538971,
            -0.0007084262906573713,
            0.00840864796191454,
            -0.014537569135427475,
            -0.06703344732522964,
            -0.03589276969432831,
            0.012844984419643879,
            0.04738691821694374,
            0.017339706420898438,
            -0.04470263049006462,
            -0.021751778200268745,
            0.016961777582764626,
            0.03141053020954132,
            -0.018484944477677345,
            0.034491389989852905,
            0.008189926855266094,
            -0.015270517207682133,
            0.01952476054430008,
            -0.02853775955736637,
            0.011951616033911705,
            0.035703688859939575,
            0.011629851534962654,
            -0.015539699234068394,
            0.02791033685207367,
            -0.0060559953562915325,
            0.02277221716940403,
            -0.0019845308270305395,
            -0.025906501337885857,
            0.03424329683184624,
            -0.0023528658784925938,
            -0.029871942475438118,
            -0.006527503952383995,
            -0.02724188193678856,
            0.03426138684153557,
            0.013394707813858986,
            -0.043866049498319626,
            -0.009982133284211159,
            -0.025218041613698006,
            -0.016895946115255356,
            -0.0005879586096853018,
            -0.002047318033874035,
            0.01290003303438425,
            0.0009160502813756466,
            0.025563836097717285,
            -0.03175462782382965,
            0.006365180015563965,
            0.023376114666461945,
            -0.038463134318590164,
            0.039920441806316376,
            -0.022500932216644287,
            -0.007141123525798321,
            0.0036254434380680323,
            0.004346896428614855,
            0.01227861549705267,
            -0.024061590433120728,
            -0.023172272369265556,
            -0.017878342419862747,
            -0.009546955116093159,
            0.010959585197269917,
            0.0351627916097641,
            -0.0010632610647007823,
            -0.02236209250986576,
            -0.002466806909069419,
            0.02674218639731407,
            0.022350171580910683,
            -0.019082937389612198,
            0.025111211463809013,
            -0.006820180919021368,
            -0.0011720142792910337,
            0.019131572917103767,
            0.009399566799402237,
            0.002250328892841935,
            0.017900079488754272,
            0.034113988280296326,
            -0.004171389155089855,
            -0.01531807892024517,
            -0.03002285398542881,
            -0.014195620082318783,
            0.008791650645434856,
            -0.013748720288276672,
            -0.02822980098426342,
            -0.032319385558366776,
            -0.029294390231370926,
            -0.012280828319489956,
            0.0060185715556144714,
            -0.011045071296393871,
            0.02702566422522068,
            0.025331927463412285,
            0.028908388689160347,
            -0.02592349238693714,
            -0.028006263077259064,
            -0.0058720409870147705,
            0.046338509768247604,
            -0.021336594596505165,
            -0.007172286044806242,
            0.007388352882117033,
            0.03921720013022423,
            0.027253245934844017,
            -0.0027731829322874546,
            0.016232088208198547,
            -0.00990272406488657,
            0.019917059689760208,
            0.017176879569888115,
            0.03127225115895271,
            -0.009571541100740433,
            0.019875159487128258,
            -0.056698691099882126,
            0.04628610983490944,
            -0.00550509849563241,
            -0.005636966321617365,
            -0.021352827548980713,
            -0.0624668225646019,
            0.005564276594668627,
            0.019956350326538086,
            -0.03096519224345684,
            -0.031161736696958542,
            -0.035099755972623825,
            0.00986529141664505,
            -0.00496643828228116,
            0.029859183356165886,
            -0.03147486224770546,
            -0.028231488540768623,
            0.02394743822515011,
            0.016352079808712006,
            0.029202047735452652,
            0.040375225245952606,
            0.004106474574655294,
            -0.04212133586406708,
            -0.01373202446848154,
            -0.018028661608695984,
            -0.003454378806054592,
            -0.028913795948028564,
            0.022358708083629608,
            0.0008546357857994735,
            -0.027826569974422455,
            0.022519761696457863,
            0.012827498838305473,
            -0.05326536297798157,
            0.016283463686704636,
            -0.038385059684515,
            -0.04013841226696968,
            0.028267914429306984,
            0.018606944009661674,
            -0.006324897054582834,
            -0.01541074924170971,
            0.015047814697027206,
            0.00958158913999796,
            -0.004075072705745697,
            -0.010790953412652016,
            0.006875298917293549,
            -0.0027816363144665956,
            -0.013816459104418755,
            0.027679171413183212,
            0.01675315946340561,
            -0.005622279364615679,
            0.010992574505507946,
            0.012313026003539562,
            -0.014031225815415382,
            0.03610546886920929,
            0.019611120223999023,
            0.017920274287462234,
            0.03299983590841293,
            -0.004952510353177786,
            -0.034991584718227386,
            -0.011898224242031574,
            0.01078907959163189,
            -0.028348030522465706,
            0.017816662788391113,
            0.011723164469003677,
            0.006220678798854351,
            -0.03474334254860878,
            -0.030572837218642235,
            -0.011064763180911541,
            -0.00029875256586819887,
            0.0005791420699097216,
            -0.01462486945092678,
            0.03547774255275726,
            0.006857933010905981,
            0.003328140825033188,
            0.009807592257857323,
            0.034805938601493835,
            0.008274069055914879,
            0.026617156341671944,
            -0.005192560609430075,
            -0.011003070510923862,
            -0.03536367788910866,
            -0.03556569293141365,
            0.02025080844759941,
            -0.011399859562516212,
            0.032713133841753006,
            0.01656840182840824,
            -0.004970107693225145,
            -5.044184490543557e-06,
            0.025419345125555992,
            -0.0020926736760884523,
            0.028487514704465866,
            0.04289769381284714,
            0.00701967254281044,
            0.03228873014450073,
            0.01464355643838644,
            -0.016752971336245537,
            0.020537199452519417,
            -0.01996385119855404,
            0.00781816802918911,
            0.006810982711613178,
            -0.0013195272767916322,
            -0.023978935554623604,
            -0.006380734499543905,
            0.0003228744026273489,
            0.0017797311302274466,
            -0.013151094317436218,
            0.02027920074760914,
            0.023205019533634186,
            -0.029990212991833687,
            -0.01880919560790062,
            -0.01380961760878563,
            -0.01443546637892723,
            0.012724791653454304,
            -0.031297218054533005,
            -0.017081568017601967,
            0.00019283610163256526,
            -0.005300735589116812,
            0.01983073726296425,
            -0.02333385869860649,
            0.019023705273866653,
            -0.00980523694306612,
            0.01742832362651825,
            0.00014261003525462002,
            -0.026835234835743904,
            0.040995486080646515,
            -0.02889728546142578,
            0.008738997392356396,
            -0.012423727661371231,
            0.02971014939248562,
            -0.03612210974097252,
            -0.07971695810556412,
            -0.015757741406559944,
            0.023875853046774864,
            -0.033296409994363785,
            -0.043907731771469116,
            0.004277084022760391,
            0.0013156203785911202,
            -0.03234068304300308,
            -0.02940281108021736,
            0.010995523072779179,
            -0.006986202672123909,
            0.006222777534276247,
            0.021745922043919563,
            0.021214287728071213,
            0.027253635227680206,
            0.0001465644600102678,
            -0.02317872829735279,
            0.023884041234850883,
            0.021265942603349686,
            -0.05585096776485443,
            -0.03485080227255821,
            -0.010991673916578293,
            0.011758608743548393
        ],
        [
            0.007164391223341227,
            0.04344138503074646,
            0.010590717196464539,
            -0.0102077079936862,
            -0.014765899628400803,
            -0.08280856162309647,
            -0.034717362374067307,
            0.019295794889330864,
            0.02334485948085785,
            0.011709203943610191,
            0.021861804649233818,
            0.02068917267024517,
            -0.028560295701026917,
            -0.025709787383675575,
            -0.001863140263594687,
            0.006164061836898327,
            -0.01718912273645401,
            -0.051659345626831055,
            -0.01311073824763298,
            -0.026487743481993675,
            0.018426857888698578,
            0.004441762343049049,
            0.050580207258462906,
            -0.01825033128261566,
            0.033362310379743576,
            -0.015727022662758827,
            0.006155329756438732,
            -0.057113636285066605,
            0.026419086381793022,
            -0.012758594937622547,
            -0.018852287903428078,
            0.005505011882632971,
            0.029384084045886993,
            -0.008605316281318665,
            0.013169033452868462,
            0.013972981832921505,
            -0.015944259241223335,
            -0.010340075939893723,
            -0.008434327319264412,
            -0.001972380792722106,
            -0.03342662751674652,
            -0.05742786452174187,
            -0.02935188077390194,
            0.01073379721492529,
            -0.009095844812691212,
            0.004347182810306549,
            -0.017719389870762825,
            -0.025737294927239418,
            -0.045471325516700745,
            0.01648242026567459,
            -0.01664719544351101,
            0.020267626270651817,
            0.03908820450305939,
            0.039607349783182144,
            -0.059820495545864105,
            -0.0668032243847847,
            -0.05236687511205673,
            -0.025063276290893555,
            0.03491275757551193,
            0.018568245694041252,
            -0.021753299981355667,
            0.00926324725151062,
            -0.019784966483712196,
            -0.02122855745255947,
            -0.03940195217728615,
            -0.042557064443826675,
            0.03649897873401642,
            0.01300879754126072,
            -0.004521228838711977,
            -0.00414817500859499,
            0.03576716408133507,
            0.02820465713739395,
            0.01707448624074459,
            -0.021595630794763565,
            0.031745363026857376,
            -0.04000598192214966,
            0.008177891373634338,
            -0.05293912813067436,
            -0.006493878085166216,
            0.005168658681213856,
            0.017207728698849678,
            -0.02492693066596985,
            -0.01620899699628353,
            0.020853115245699883,
            -0.020365035161376,
            0.02478676103055477,
            0.003314102767035365,
            -0.04304351285099983,
            -0.008875105530023575,
            0.006551872473210096,
            0.004819895140826702,
            -0.012356641702353954,
            0.01644096150994301,
            -0.04292143136262894,
            -0.017275605350732803,
            -0.00149494968354702,
            -0.03128356486558914,
            -0.03951485455036163,
            0.004704718478024006,
            0.035131048411130905,
            -0.038919705897569656,
            0.021166406571865082,
            -0.02046314626932144,
            0.03425762429833412,
            0.022096656262874603,
            -0.05787162855267525,
            -0.014418845996260643,
            0.018280480057001114,
            -0.0571906752884388,
            -0.03916727378964424,
            0.05671456456184387,
            -0.03824184089899063,
            -0.03976363316178322,
            0.005756278522312641,
            -0.01815033331513405,
            -0.011252195574343204,
            0.013717432506382465,
            -0.027010949328541756,
            0.0028081845957785845,
            -0.019258609041571617,
            0.009681531228125095,
            0.033690620213747025,
            0.0458691380918026,
            -0.01174809318035841,
            0.009542476385831833,
            0.014232972636818886,
            0.0449855662882328,
            0.015342925675213337,
            -0.035673655569553375,
            -0.04370264336466789,
            0.03375352546572685,
            0.013542297296226025,
            0.003208871465176344,
            0.009976932778954506,
            -7.968154386617243e-05,
            -0.015584391541779041,
            0.0025478526949882507,
            -0.015157202258706093,
            -0.013502788729965687,
            -0.022913893684744835,
            -0.0243050679564476,
            -0.011148784309625626,
            0.043684106320142746,
            -0.0017316403100267053,
            -0.07197173684835434,
            -0.03485977649688721,
            -0.03484282270073891,
            0.051848988980054855,
            -0.04328824579715729,
            0.034292615950107574,
            -0.01357437763363123,
            -0.009975273162126541,
            0.03251165524125099,
            0.014187322929501534,
            -0.06483245640993118,
            -0.040280357003211975,
            0.011633164249360561,
            0.020538531243801117,
            -0.017765525728464127,
            0.020041873678565025,
            0.04041291028261185,
            0.009953227825462818,
            0.0241435207426548,
            0.024133898317813873,
            0.003250027308240533,
            0.05023973062634468,
            -0.009243883192539215,
            -0.0660724937915802,
            0.02124992571771145,
            0.03471358120441437,
            -0.01551143266260624,
            0.00519068818539381,
            0.014058930799365044,
            -0.023288782685995102,
            -0.029345031827688217,
            -0.0012382353888824582,
            -0.028346827253699303,
            -0.038508012890815735,
            -0.006770386826246977,
            0.03386565297842026,
            0.005294411908835173,
            0.013178719207644463,
            -0.006398744415491819,
            -0.037752069532871246,
            0.010155082680284977,
            -0.02369837649166584,
            0.030055643990635872,
            0.02227378636598587,
            0.00363343209028244,
            0.0054960898123681545,
            -0.00530725484713912,
            0.022583836689591408,
            -0.02615879476070404,
            -0.025226827710866928,
            0.011299869045615196,
            0.0003376924723852426,
            -0.04395242780447006,
            0.004046690184623003,
            0.024218399077653885,
            -0.010212701745331287,
            -0.018512889742851257,
            0.031970538198947906,
            0.03303106129169464,
            0.015633830800652504,
            -0.026906056329607964,
            -0.005640305113047361,
            -0.034819021821022034,
            -0.004354517906904221,
            -0.020561477169394493,
            -0.0037993863224983215,
            -0.02481093443930149,
            -0.009691007435321808,
            -0.003859763965010643,
            -0.012741388753056526,
            0.019125625491142273,
            0.022668173536658287,
            -0.016922760754823685,
            0.012257486581802368,
            0.03157356381416321,
            0.004759528208523989,
            -0.022852899506688118,
            0.008440796285867691,
            -0.014358332380652428,
            0.017748739570379257,
            0.03392649441957474,
            0.0050691282376646996,
            -0.018679732456803322,
            0.008085262030363083,
            -0.0356990322470665,
            -0.0006078145815990865,
            -0.02335602045059204,
            0.006897761952131987,
            -0.005233642179518938,
            -0.023255696520209312,
            -0.08010289818048477,
            0.003332488238811493,
            0.082555390894413,
            -0.004663404077291489,
            -0.03565661236643791,
            -0.012237509712576866,
            0.02618812769651413,
            0.022487998008728027,
            -0.03094932809472084,
            0.009477782994508743,
            -0.022505128756165504,
            -0.017181338742375374,
            0.04083701968193054,
            0.0064187198877334595,
            0.03227642923593521,
            0.017336836084723473,
            0.0326334685087204,
            -0.04535142704844475,
            0.002156078116968274,
            -0.029470086097717285,
            0.0038203729782253504,
            -0.02743612974882126,
            -0.00823902990669012,
            0.03242677450180054,
            0.012563120573759079,
            0.04775502160191536,
            0.0043242876417934895,
            0.013907356187701225,
            0.06545515358448029,
            -0.006628573872148991,
            -0.0071493107825517654,
            -0.01619887910783291,
            0.006850924342870712,
            -0.017880408093333244,
            0.005144687369465828,
            -0.05501260980963707,
            -0.05527787283062935,
            0.02440159022808075,
            -0.021620986983180046,
            0.013109077699482441,
            -0.037220049649477005,
            -0.010593087412416935,
            -0.02393846958875656,
            -0.041064683347940445,
            0.01247845683246851,
            -0.021863693371415138,
            0.045102860778570175,
            -0.009130053222179413,
            0.024683276191353798,
            0.025809600949287415,
            -0.00963173620402813,
            -0.010438164696097374,
            -0.0030350012239068747,
            0.020000187680125237,
            0.009895597584545612,
            -0.01856316067278385,
            0.001296518836170435,
            0.034453678876161575,
            0.026684749871492386,
            -0.0177372507750988,
            -0.010108551941812038,
            -0.04856870695948601,
            -0.04892856627702713,
            0.00946418009698391,
            -0.025770118460059166,
            -0.023924844339489937,
            -0.01472767535597086,
            -0.027140511199831963,
            -0.01659316197037697,
            -0.0591052770614624,
            -0.012935817241668701,
            0.028981277719140053,
            -0.04773735627532005,
            0.056568145751953125,
            -0.03929696977138519,
            -0.014735291711986065,
            0.01323977392166853,
            -0.019160723313689232,
            -0.021629733964800835,
            0.021613217890262604,
            -0.04075488820672035,
            -0.010291770100593567,
            -0.029587769880890846,
            0.022719979286193848,
            0.009287402033805847,
            -0.013329826295375824,
            -0.0062585691921412945,
            -0.04927140846848488,
            -0.03328295052051544,
            -0.024950256571173668,
            -0.016514044255018234,
            -0.03946739062666893,
            0.02041783183813095,
            -0.01857065223157406,
            -0.046658169478178024,
            0.022340930998325348,
            -0.04260452091693878,
            -0.03263726085424423,
            0.012186908163130283,
            -0.009098395705223083,
            0.022095274180173874,
            0.004768617916852236,
            0.012608274817466736,
            0.028972920030355453,
            0.005953846033662558,
            -0.0026409118436276913,
            0.00011207801435375586,
            -0.014196361415088177,
            0.010451581329107285,
            -0.015479729510843754,
            -0.05592666566371918,
            0.011664612218737602,
            -0.010218556970357895,
            0.005394181702286005,
            0.01123090647161007,
            -0.04300038143992424,
            0.0372670479118824,
            0.00412397738546133,
            0.0042503573931753635,
            -0.025332011282444,
            -0.037567246705293655,
            -0.06149347871541977,
            0.03513514623045921,
            0.014992083422839642,
            -0.006091228220611811,
            0.015293227508664131,
            -0.0026290907990187407,
            0.0003578151226975024,
            0.023782338947057724,
            0.009700439870357513,
            0.03003879450261593,
            -0.022828593850135803,
            0.017240352928638458,
            -0.034709151834249496,
            -0.003014038549736142,
            -0.02444128319621086,
            -0.01366365235298872,
            -0.023130182176828384,
            0.024210453033447266,
            -0.04099343717098236,
            -0.02874724380671978,
            0.03178209811449051,
            -0.008724410086870193,
            -0.021715274080634117,
            -0.06753945350646973,
            0.057293687015771866,
            0.07258554548025131,
            0.030772054567933083,
            0.013600067235529423,
            0.011497381143271923,
            0.012230020947754383,
            -0.02303599938750267,
            -0.023528706282377243,
            0.0015545471105724573,
            -0.0112735815346241,
            -0.0012210417771711946,
            -0.024201935157179832,
            -0.005853906739503145,
            0.018295446410775185,
            -0.025739002972841263,
            -0.026796158403158188,
            0.0107545992359519,
            -0.02714918740093708,
            0.023278670385479927,
            0.010404089465737343,
            -0.04122311621904373,
            0.05794433504343033,
            -0.03875883296132088,
            -0.014407834969460964,
            -0.014157180674374104,
            0.014640356414020061,
            -0.0668489933013916,
            -0.027692515403032303,
            -0.021252404898405075,
            0.0047891754657030106,
            0.021574117243289948,
            -0.019436271861195564,
            -0.016538839787244797,
            0.018391432240605354,
            -0.02500443533062935,
            -0.009697936475276947,
            0.004844385664910078,
            0.04088371992111206,
            0.0204173531383276,
            0.01410018652677536,
            -0.007731511257588863,
            -0.09503038972616196,
            -0.040169551968574524,
            0.07175852358341217,
            -0.02358773536980152,
            0.012952283024787903,
            0.02791566029191017,
            -0.0526387095451355,
            -0.009480438195168972,
            0.03256980702280998,
            -0.05659763887524605,
            -0.008474408648908138,
            -0.015533286146819592,
            0.02675648219883442,
            -0.013533475808799267,
            0.011983740143477917,
            -0.016583610326051712,
            0.026612060144543648,
            -0.054578542709350586,
            0.011942939832806587,
            -0.014029542915523052,
            0.017929499968886375,
            0.028624719008803368,
            0.002573045901954174,
            0.004615058656781912,
            -0.031435608863830566,
            -0.019438184797763824,
            0.005505023058503866,
            0.03457654267549515,
            -0.009157360531389713,
            -0.03503118082880974,
            -0.016559908166527748,
            0.04010293632745743,
            -0.085224948823452,
            -0.012425514869391918,
            -0.021418586373329163,
            -0.02719911001622677,
            -0.00917867012321949,
            0.0016946961404755712,
            -0.018195198848843575,
            0.014564675278961658,
            -0.0060254354029893875,
            0.0032656448893249035,
            0.016427356749773026,
            -0.014421287924051285,
            -0.04468955099582672,
            0.016054177656769753,
            0.0009686803678050637,
            -0.056158315390348434,
            0.002008858835324645,
            0.0035006520338356495,
            -0.051124457269907,
            -0.014384279027581215,
            0.013360628858208656,
            0.01488566305488348,
            0.02606574259698391,
            -0.04173571616411209,
            -0.010893319733440876,
            -0.0055915419943630695,
            -0.0411580353975296,
            -0.017680270597338676,
            0.013022947125136852,
            0.030319800600409508,
            0.030695417895913124,
            -0.022620096802711487,
            -0.06830926239490509,
            0.010764106176793575,
            0.027923833578824997,
            0.01544487290084362,
            0.04754533991217613,
            -0.012698415666818619,
            0.004709135740995407,
            -0.0009306045831181109,
            0.01461139414459467,
            -0.008900715038180351,
            0.00722542917355895,
            -0.004884365946054459,
            0.00028362523880787194,
            0.004814732354134321,
            -0.011679605580866337,
            -0.005497278179973364,
            0.012504919432103634,
            -0.014563249424099922,
            -0.005376428831368685,
            0.0006794515065848827,
            -0.019336853176355362,
            -0.010800907388329506,
            0.029700737446546555,
            0.021419573575258255,
            -0.02071343921124935,
            0.0645722821354866,
            -0.00358651508577168,
            0.0051656183786690235,
            0.0024366576690226793,
            0.03196791186928749,
            0.04076584428548813,
            0.009715858846902847,
            0.0034069756511598825,
            -0.04610152542591095,
            -0.03351995348930359,
            -0.02583150938153267,
            0.006716377567499876,
            0.03918443247675896,
            -0.0006839114357717335,
            -0.01797744631767273,
            0.020696971565485,
            0.013438917696475983,
            0.09656848758459091,
            -0.01876729726791382,
            0.004286895040422678,
            0.025021567940711975,
            -0.00941772386431694,
            -0.03126460313796997,
            -0.05990978330373764,
            0.013852491043508053,
            -0.007851467467844486,
            -0.0034832346718758345,
            0.025929562747478485,
            0.02279646508395672,
            -0.002271962119266391,
            0.03706562519073486,
            0.002328957198187709,
            0.007509865798056126,
            -0.02451111003756523,
            -0.00818730890750885,
            -0.0007572696194984019,
            0.019187554717063904,
            0.02614561654627323,
            -0.03382837772369385,
            0.027484478428959846,
            0.031456802040338516,
            0.012554872781038284,
            0.022405458614230156,
            -0.0444674976170063,
            0.004779305309057236,
            0.0004811814578715712,
            -0.015615674667060375,
            -0.016174785792827606,
            -0.04159427806735039,
            0.003484530607238412,
            -0.002961980178952217,
            -0.013461294583976269,
            0.04085845872759819,
            -0.0022221833933144808,
            0.03092537261545658,
            0.006667681038379669,
            0.030209315940737724,
            0.026597417891025543,
            -0.005058320239186287,
            -0.013348196633160114,
            0.01632753387093544,
            -0.008464410901069641,
            -0.020270932465791702,
            -0.05822703614830971,
            -0.015219920314848423,
            -0.01909787766635418,
            -0.018923399969935417,
            0.017938857898116112,
            -0.05573120713233948,
            -0.00800219364464283,
            -0.014018408954143524,
            -0.01626931130886078,
            -0.0045183212496340275,
            -0.005673929117619991,
            -0.0376918762922287,
            -0.0440157987177372,
            -0.02662699483335018,
            0.04235604405403137,
            0.013076012022793293,
            -0.017056426033377647,
            0.022495275363326073,
            -0.009231716394424438,
            0.037178535014390945,
            -0.017590416595339775,
            0.017625296488404274,
            -0.03677911311388016,
            -0.010111222043633461,
            -0.05172460526227951,
            0.014964562840759754,
            0.007501542568206787,
            0.0060920752584934235,
            -0.10452782362699509,
            -0.007682939525693655,
            -0.025866102427244186,
            0.010454206727445126,
            -0.006768837571144104,
            0.012357475236058235,
            -0.004796288907527924,
            -0.023170769214630127,
            -0.009550247341394424,
            0.0363859087228775,
            -0.02097024954855442,
            -0.01624269224703312,
            0.010097632184624672,
            -0.00962157268077135,
            -0.027046535164117813,
            -0.014049312099814415,
            -0.027259230613708496,
            -0.012081041932106018,
            -0.005165839102119207,
            -0.01213248074054718,
            0.020445574074983597,
            0.003926647361367941,
            -0.02966589853167534,
            0.017383871600031853,
            -0.019275201484560966,
            0.012482921592891216,
            -0.009072016924619675,
            -0.01085633970797062,
            0.022979341447353363,
            0.03018767014145851,
            0.005487444810569286,
            -0.022434331476688385,
            -0.03299134969711304,
            -0.0065067787654697895,
            -0.005211597308516502,
            0.007485947571694851,
            0.011409330181777477,
            -0.010931427590548992,
            0.024702928960323334,
            0.028874294832348824,
            -0.0057551926001906395,
            -0.006785518955439329,
            -0.08323827385902405,
            0.05407184362411499,
            -0.004322309512645006,
            -0.020687103271484375,
            -0.005716897547245026,
            0.03277355059981346,
            -0.028412727639079094,
            0.03092190995812416,
            0.022618116810917854,
            0.009750938974320889,
            -0.022446999326348305,
            0.022220171988010406,
            0.018118254840373993,
            -0.01053324993699789,
            -0.010924766771495342,
            0.027046598494052887,
            -0.04750910401344299,
            -0.010590472258627415,
            0.0037282167468219995,
            0.009039360098540783,
            -0.005607664585113525,
            0.003998013213276863,
            -0.016674580052495003,
            -0.015976136550307274,
            -0.03140723705291748,
            -0.006011846009641886,
            0.015556946396827698,
            -0.0008875032654032111,
            0.032259754836559296,
            0.0018182791536673903,
            -0.022014472633600235,
            0.020745163783431053,
            0.03409957513213158,
            -0.002126261591911316,
            -0.006309173535555601,
            -0.01201533805578947,
            0.007724263705313206,
            0.001036701025441289,
            -0.010322055779397488,
            0.006422002334147692,
            0.011897270567715168,
            -0.025380641222000122,
            -0.038274187594652176,
            -0.001351686310954392,
            0.02713058702647686,
            -0.030366316437721252,
            -0.01965493895113468,
            0.041082579642534256,
            0.017018351703882217,
            -0.052302367985248566,
            -0.014757840894162655,
            -0.0058416337706148624,
            0.01907762885093689,
            0.04619186371564865,
            -0.031165560707449913,
            0.005574612878262997,
            -0.012322920374572277,
            -0.01668187417089939,
            -0.02081330679357052,
            0.020642448216676712,
            -0.061309147626161575,
            0.001308402162976563,
            0.029988247901201248,
            0.0025168508291244507,
            0.010917109437286854,
            -0.0037976631429046392,
            -0.03988136351108551,
            0.04385650157928467,
            0.0051812888123095036,
            -0.015451782383024693,
            -0.006379775237292051,
            -0.010413258336484432,
            0.034965869039297104,
            -0.02690901979804039,
            0.007590441033244133,
            -0.010473213158547878,
            0.02808176353573799,
            -0.017047200351953506,
            -0.018441520631313324,
            -0.004280942492187023,
            0.006182205397635698,
            0.008135360665619373,
            0.034910764545202255,
            -0.03234553709626198,
            0.009674139320850372,
            0.04019482061266899,
            -0.01730160042643547,
            -0.02581837587058544,
            -0.014501350931823254,
            -0.05377925932407379,
            -0.0216949675232172,
            0.012493478134274483,
            0.010986961424350739,
            0.017848040908575058,
            -0.001165248453617096,
            0.0005045784055255353,
            -0.056281477212905884,
            0.028146643191576004,
            -0.024991553276777267,
            -0.016276663169264793,
            0.02860102429986,
            -0.02267145924270153,
            -0.026417724788188934,
            0.018126623705029488,
            -0.012129900977015495,
            0.018021000549197197,
            -0.0033847871236503124,
            0.002121131867170334,
            0.03446871042251587,
            0.024450328201055527,
            0.004327083006501198,
            -0.03443611040711403,
            -0.005084887146949768,
            -0.039347853511571884,
            0.026914041489362717,
            -0.003946442157030106,
            -0.012008780613541603,
            -0.024425016716122627,
            0.03958453983068466,
            0.07544329017400742,
            0.024516839534044266,
            0.012330505065619946,
            0.021140892058610916,
            0.007907331921160221,
            -0.0105917789041996,
            -0.015632158145308495,
            -0.0025601633824408054,
            0.019315972924232483,
            0.025931524112820625,
            0.014693479053676128,
            -0.02408691868185997,
            -0.0034309832844883204,
            -0.014111999422311783,
            -0.043107178062200546,
            -0.012767805717885494,
            -0.03109166771173477,
            -0.029057897627353668,
            -0.012128729373216629,
            0.043412964791059494,
            -0.017584647983312607,
            -0.01340849231928587,
            0.00266085471957922,
            0.0326194204390049,
            -0.03209796920418739,
            0.008748635649681091,
            -0.0028269740287214518,
            0.0010976792545989156,
            -0.0069328355602920055,
            -0.018018726259469986,
            -0.05024338513612747,
            0.010121439583599567,
            -0.032198693603277206,
            -0.0062333969399333,
            0.0363859124481678,
            -0.05840121954679489,
            0.04656558111310005,
            -0.029756750911474228,
            0.02394869551062584,
            -0.05281030386686325,
            0.057331256568431854,
            0.03084642067551613,
            -0.039953481405973434,
            -0.026072625070810318,
            0.005478815641254187,
            -0.052359357476234436,
            0.02338409423828125,
            0.06707797944545746,
            -0.03493547812104225,
            0.018049634993076324,
            0.022707661613821983,
            0.010710882022976875,
            0.012596196494996548,
            0.02086612954735756,
            -0.016859205439686775,
            0.010420378297567368,
            0.015198315493762493,
            -0.02250029519200325,
            0.02669178880751133,
            0.07230784744024277,
            -0.0028363538440316916,
            -0.0008981401333585382,
            0.011106868274509907,
            0.05484149232506752,
            0.007759886793792248,
            0.02262984961271286,
            -0.012023470364511013,
            -0.0017820396460592747,
            -0.0003312645712867379,
            -0.007623834535479546,
            0.018324702978134155,
            0.03105088695883751,
            -0.0033808352891355753,
            -0.0633593499660492,
            0.032123059034347534,
            -0.046911220997571945,
            0.042359862476587296,
            -0.010786238126456738,
            -0.02782110497355461,
            -0.013001758605241776,
            0.043640460819005966,
            -0.007744794711470604,
            0.02037164568901062,
            0.01621975563466549,
            -0.006934100296348333,
            0.0018578689778223634,
            0.02473774552345276,
            -0.0013171832542866468,
            0.014226570725440979,
            -0.010071706026792526,
            0.03170917555689812,
            -0.007570918649435043,
            -0.001511758309789002,
            -0.04131289944052696,
            -0.03986132889986038,
            -0.02689533866941929,
            -0.018433699384331703,
            -0.03328067809343338,
            0.009221920743584633,
            0.004378532990813255,
            -0.0004215209628455341,
            0.07249545305967331,
            0.0026307699736207724,
            0.009214479476213455,
            0.02441791072487831,
            -0.007070557679980993,
            -0.04260048642754555,
            -0.01586952619254589,
            0.021236563101410866,
            0.010354125872254372,
            0.010538244619965553,
            -0.017508000135421753,
            -0.0025675694923847914,
            0.032826364040374756,
            0.05160341411828995,
            -0.011893872171640396,
            -0.007075522094964981,
            -0.009656217880547047,
            0.023830611258745193,
            0.0860300287604332,
            -0.006218328140676022,
            0.04001954197883606,
            0.0003212838200852275,
            0.00962937343865633,
            0.012646245770156384,
            0.01717596873641014,
            0.02238255739212036,
            -0.026462532579898834,
            0.008395331911742687,
            -0.004774893168359995,
            0.002203691750764847,
            -0.006774439476430416,
            -0.010129441507160664,
            -0.004850539844483137,
            -0.031141234561800957,
            0.01054561696946621,
            -0.042936936020851135,
            0.027613816782832146,
            -0.02458505518734455,
            -0.03514264151453972,
            0.009194956161081791,
            -0.018030758947134018,
            -0.012829561717808247,
            0.001324597280472517,
            0.022791454568505287,
            -0.021716643124818802,
            0.02379480004310608,
            -0.02595127373933792,
            0.003307781647890806,
            0.0016032250132411718,
            -0.021258719265460968,
            0.02229030430316925,
            0.006864042021334171,
            -0.033758681267499924,
            0.01784299872815609,
            0.020504480227828026,
            -0.009394867345690727,
            -0.01575472578406334,
            -0.009608651511371136,
            0.01721077412366867,
            -0.05821703001856804,
            -0.06362748146057129,
            0.00077455845894292,
            0.027283407747745514,
            -0.009364213794469833,
            0.021698011085391045,
            0.014134690165519714,
            0.027750268578529358,
            -0.003357703099027276,
            -0.014756511896848679,
            0.02695312909781933,
            0.016417523846030235,
            0.011511102318763733,
            0.02127676084637642,
            0.020109068602323532,
            -0.0027119340375065804,
            0.032132599502801895,
            -0.002663356950506568,
            -0.012053081765770912,
            0.013723439536988735,
            -0.033188752830028534,
            -0.035339318215847015,
            0.006505516357719898,
            -0.036227818578481674,
            0.014329723082482815,
            0.0025564434472471476,
            0.022429559379816055,
            -0.014852587133646011,
            0.03190658614039421,
            0.019832594320178032,
            0.03702171891927719,
            0.005375048611313105,
            -0.008455129340291023,
            0.0004657967947423458,
            0.015406230464577675,
            -0.015656856819987297,
            -0.0021447453182190657,
            0.053236380219459534,
            -0.01250804029405117,
            -0.0456576831638813,
            0.0058001442812383175,
            0.012753969989717007,
            -0.021375762298703194,
            0.044840008020401,
            0.029617607593536377,
            -0.019910776987671852,
            -0.004100319463759661,
            0.03812140226364136,
            -0.053825799375772476,
            -0.027030285447835922,
            -0.012218029238283634,
            -0.00929353665560484,
            -0.0188643466681242,
            0.0024508065544068813,
            0.011172567494213581,
            0.06574556976556778,
            -0.027370551601052284,
            -0.004674775991588831,
            -0.014839117415249348,
            0.0011428173165768385,
            0.04266558215022087,
            0.030430346727371216,
            -0.01645965501666069,
            -0.05720476061105728,
            0.0015409357147291303,
            -0.05158993601799011,
            0.010059742256999016,
            0.006559604313224554,
            -0.011500069871544838,
            -0.05945722013711929,
            -0.05772537738084793,
            -0.03835853561758995,
            -0.005527257453650236,
            -0.0373138003051281,
            0.07853426039218903,
            -0.03277998045086861,
            0.023022903129458427,
            -0.09428966790437698,
            0.028703365474939346,
            -0.023211730644106865,
            0.006295494269579649,
            0.005071205087006092,
            -0.02122795395553112,
            -0.009704608470201492,
            -0.011585119180381298,
            0.0006999020115472376,
            0.035724885761737823,
            0.012616454623639584,
            0.01761169731616974,
            0.043980225920677185,
            0.0006274913321249187,
            0.038164980709552765,
            -0.0022470178082585335,
            0.008292488753795624,
            -0.06580366939306259,
            0.008326036855578423,
            -0.021503280848264694,
            0.008355432190001011,
            0.006706052925437689,
            -0.016672974452376366,
            0.026400387287139893,
            -0.04373713582754135,
            -0.006686764303594828
        ],
        [
            0.014852304011583328,
            -0.02201414667069912,
            -0.048132576048374176,
            -0.014713529497385025,
            0.03365400433540344,
            -0.07685491442680359,
            0.004741255193948746,
            -0.01497684232890606,
            -0.020829135552048683,
            -0.024134816601872444,
            -0.01977728307247162,
            0.01932038553059101,
            0.04017506167292595,
            -0.016561422497034073,
            -0.012672306969761848,
            -0.016368934884667397,
            0.02551538497209549,
            0.006957394070923328,
            -0.010569538921117783,
            -0.011922886595129967,
            0.006033093202859163,
            -0.01438817661255598,
            -0.005139170214533806,
            0.008966585621237755,
            -0.003976241685450077,
            0.01603563316166401,
            0.05845505744218826,
            0.005061473697423935,
            0.005187423899769783,
            0.013118892908096313,
            -0.039260488003492355,
            -0.001322324387729168,
            0.007490526884794235,
            0.002443856094032526,
            -0.0009456831030547619,
            -0.03394800424575806,
            -0.009687889367341995,
            0.004504528362303972,
            0.02278587780892849,
            0.03071950003504753,
            -0.010539938695728779,
            0.003098635468631983,
            -0.021836746484041214,
            -0.01769324205815792,
            0.005470306612551212,
            0.021992187947034836,
            0.021985061466693878,
            -0.06104576587677002,
            -0.025879696011543274,
            0.014351136982440948,
            -0.0061858720146119595,
            0.025918351486325264,
            0.03059784695506096,
            0.00023753596178721637,
            -0.019234437495470047,
            -0.023839836940169334,
            -0.028110617771744728,
            0.012446788139641285,
            -0.029793834313750267,
            -0.030419807881116867,
            -0.015513542108237743,
            -0.011394032277166843,
            0.03408593684434891,
            -0.005187055561691523,
            -0.009460323490202427,
            -0.03198212385177612,
            0.040093183517456055,
            -0.010774515569210052,
            0.0021169439423829317,
            -0.017084460705518723,
            -0.011180927976965904,
            0.015215810388326645,
            -0.008939752355217934,
            0.024872640147805214,
            0.023368440568447113,
            0.031760066747665405,
            0.025669923052191734,
            0.04614691808819771,
            -0.023813320323824883,
            0.03807663545012474,
            0.02078378200531006,
            -0.0008969846530817449,
            0.026131967082619667,
            -0.010573646984994411,
            -0.0020651505328714848,
            -0.012087086215615273,
            -0.004335015080869198,
            0.000984440790489316,
            0.02152647078037262,
            -0.005908389575779438,
            -0.04655584320425987,
            -0.02338237874209881,
            0.012143665924668312,
            0.02688726596534252,
            0.014668818563222885,
            0.004530900623649359,
            -0.025937866419553757,
            0.02470315247774124,
            0.08267217874526978,
            -0.014989525079727173,
            0.03007102943956852,
            0.017541686072945595,
            -0.01364127267152071,
            0.030661895871162415,
            -0.010553865693509579,
            0.010695621371269226,
            0.010622616857290268,
            0.002325343666598201,
            -0.007622809614986181,
            -0.028278889134526253,
            -0.004523956682533026,
            -0.018142057582736015,
            -0.017855092883110046,
            -0.006177251692861319,
            -0.007069064769893885,
            -0.006416568998247385,
            0.024505717679858208,
            0.0028969543054699898,
            -0.02641001157462597,
            -0.010314220562577248,
            -0.0006810767226852477,
            -0.03730085864663124,
            -0.022052088752388954,
            0.0036385601852089167,
            0.02899276092648506,
            -0.025174129754304886,
            -0.026371384039521217,
            -0.005437664221972227,
            -0.008272879756987095,
            -0.004214969929307699,
            0.006606438662856817,
            -0.048314303159713745,
            0.0002055193472187966,
            0.00432410417124629,
            -0.02249195985496044,
            0.043524160981178284,
            -0.03792807087302208,
            -0.006391145288944244,
            0.01934952661395073,
            -0.0017475889762863517,
            0.007382811978459358,
            -0.03840063884854317,
            -0.021251466125249863,
            0.013898232951760292,
            -0.015176738612353802,
            -0.027345724403858185,
            -0.029212748631834984,
            -0.005241377744823694,
            -0.046221163123846054,
            0.005413358565419912,
            -0.009425239637494087,
            -0.010193060152232647,
            0.002708765445277095,
            -0.04177439212799072,
            0.003808291396126151,
            0.013337639160454273,
            -0.026925450190901756,
            0.040933869779109955,
            0.0361967459321022,
            0.039650943130254745,
            -0.01874426193535328,
            0.0060050575993955135,
            -0.01764613203704357,
            -0.01066095195710659,
            -0.03044137731194496,
            0.030752554535865784,
            0.007978510111570358,
            -0.023257829248905182,
            0.0054475488141179085,
            0.032897815108299255,
            0.027322381734848022,
            -0.012603595852851868,
            -0.01924838498234749,
            -0.037246447056531906,
            -0.02042388916015625,
            -0.0014024855336174369,
            -0.04293985292315483,
            -0.04078102484345436,
            -0.018155567348003387,
            0.005227683112025261,
            -0.01170794852077961,
            -0.00908987782895565,
            -0.014306914992630482,
            0.00814728531986475,
            0.01068086363375187,
            0.018469596281647682,
            0.001107911579310894,
            -0.017882036045193672,
            0.002740616677328944,
            -0.015390891581773758,
            0.0012454470852389932,
            0.039373140782117844,
            -0.018765946850180626,
            -0.003677466418594122,
            -0.015251722186803818,
            -0.024798044934868813,
            0.030028294771909714,
            0.032085999846458435,
            -0.011298924684524536,
            -0.030679352581501007,
            0.006372848991304636,
            0.014291874133050442,
            0.042665209621191025,
            0.003319009207189083,
            0.05657505989074707,
            0.03341657668352127,
            -0.0341605506837368,
            0.0005107676843181252,
            -0.009772266261279583,
            0.008871743455529213,
            -0.022188154980540276,
            -0.014736153185367584,
            0.010476312600076199,
            -0.03087039850652218,
            -0.009490379132330418,
            0.002792143961414695,
            0.02177615836262703,
            -0.018048565834760666,
            0.007797034457325935,
            -0.01833193190395832,
            -0.024248981848359108,
            -0.027251403778791428,
            0.01540074497461319,
            0.011523007415235043,
            0.012741590850055218,
            -0.013866918161511421,
            0.0003514867858029902,
            0.03782644495368004,
            0.011707423254847527,
            0.01126535702496767,
            0.006161359138786793,
            -0.05390056595206261,
            -0.010057676583528519,
            0.0064558787271380424,
            0.007664279546588659,
            0.0004725972539745271,
            -0.0242912620306015,
            -0.011979523114860058,
            -0.026433762162923813,
            0.006331977900117636,
            0.008880233392119408,
            -0.02099430374801159,
            -0.009077365510165691,
            -0.02107042446732521,
            -0.015156482346355915,
            0.025515101850032806,
            -0.01518170814961195,
            -0.041280947625637054,
            0.03135062754154205,
            0.027767363935709,
            -0.005201311316341162,
            0.01666092313826084,
            -0.03270161524415016,
            -0.02322544902563095,
            0.004972806666046381,
            -0.003122091293334961,
            -0.02378036454319954,
            -0.007092747371643782,
            0.03206878900527954,
            -0.03301604464650154,
            -0.019359540194272995,
            0.04170144349336624,
            0.06148830056190491,
            -0.00923888012766838,
            -0.00734442425891757,
            0.028493911027908325,
            0.004977319855242968,
            -0.032993875443935394,
            -0.04194336012005806,
            -0.010114450938999653,
            0.0013492609141394496,
            0.012973847799003124,
            -0.02745097689330578,
            -0.0018850199412554502,
            0.00524535309523344,
            -0.018524911254644394,
            0.008309607394039631,
            0.01304586324840784,
            0.032806869596242905,
            -0.03607918694615364,
            -0.006912297569215298,
            0.024120135232806206,
            -0.019472641870379448,
            -0.027128875255584717,
            -0.023477619513869286,
            0.018848534673452377,
            0.027931347489356995,
            -0.007487779948860407,
            0.009013458155095577,
            0.0411485880613327,
            0.029696932062506676,
            -0.027544232085347176,
            0.006793063599616289,
            0.007877214811742306,
            0.04154180362820625,
            0.03582751378417015,
            -0.028018150478601456,
            0.0015310742892324924,
            0.020882153883576393,
            0.007996682077646255,
            -0.04533405229449272,
            0.010586906224489212,
            -0.012964851222932339,
            -0.010663465596735477,
            0.01555080246180296,
            -0.010051975958049297,
            -0.008014717139303684,
            0.011008299887180328,
            -0.026539282873272896,
            -0.02549421228468418,
            -0.015662172809243202,
            0.022380733862519264,
            0.0033643438946455717,
            0.01847461797297001,
            0.011390669271349907,
            -0.003288182895630598,
            0.024086853489279747,
            0.01109194103628397,
            -0.028951464220881462,
            0.013470713049173355,
            0.02987428568303585,
            0.03704358637332916,
            0.027980731800198555,
            -0.02209785208106041,
            0.016307765617966652,
            0.0004346827045083046,
            0.012521715834736824,
            -0.027743590995669365,
            0.024645762518048286,
            -0.0057595958933234215,
            -0.031154094263911247,
            0.02467661164700985,
            -0.02422471158206463,
            -0.016457106918096542,
            0.011309274472296238,
            0.011996045708656311,
            0.03242356330156326,
            0.04468657448887825,
            -0.037370797246694565,
            -0.0047829244285821915,
            0.03127354383468628,
            0.012114769779145718,
            -0.009447024203836918,
            0.019129972904920578,
            0.035702113062143326,
            -0.00934950727969408,
            -0.04626759886741638,
            0.012289986945688725,
            -0.028620464727282524,
            -0.01675051636993885,
            -0.002357014687731862,
            0.00953853502869606,
            -0.010884827934205532,
            0.020802069455385208,
            0.012247768230736256,
            -0.015787970274686813,
            -0.008669700473546982,
            0.025777673348784447,
            0.010950267314910889,
            0.027115048840641975,
            0.008480595424771309,
            -0.01981307379901409,
            0.0007444555521942675,
            0.008983827196061611,
            0.003698863321915269,
            -0.03209622576832771,
            -0.025182953104376793,
            -0.00553815346211195,
            0.006569337099790573,
            0.027009811252355576,
            -0.010204995982348919,
            0.021758416667580605,
            -0.03634924814105034,
            -0.016067497432231903,
            -0.02323930710554123,
            0.0062652877531945705,
            0.020441247150301933,
            -0.005514262709766626,
            0.010410602204501629,
            -0.005748550873249769,
            -0.06107359752058983,
            -0.0031560633797198534,
            0.011887338943779469,
            -0.010361234657466412,
            0.01673256978392601,
            -0.042007360607385635,
            -0.028460031375288963,
            -0.03269171714782715,
            -0.00854069646447897,
            -0.024432512000203133,
            0.021696006879210472,
            -0.0015345634892582893,
            0.02298494428396225,
            -0.029660018160939217,
            -0.0009246584959328175,
            0.006531400140374899,
            0.003838736331090331,
            0.0007279861019924283,
            -0.010007361881434917,
            -0.022130846977233887,
            -0.011891638860106468,
            -0.011862416751682758,
            0.009974841959774494,
            0.0019310484640300274,
            -0.0037728026509284973,
            -0.040614187717437744,
            0.031544279307127,
            0.009782708249986172,
            0.015317334793508053,
            0.0261693112552166,
            0.012264210730791092,
            -0.04418906942009926,
            0.008949869312345982,
            0.023435568436980247,
            -0.04070408642292023,
            -0.04406821355223656,
            0.0010402369080111384,
            0.04204937815666199,
            -0.012109932489693165,
            -0.00367035367526114,
            -0.022237656638026237,
            -0.028517140075564384,
            0.0018398879328742623,
            0.016830066218972206,
            -0.008418376557528973,
            -0.04634206369519234,
            -0.015486638993024826,
            -0.018612390384078026,
            0.016330232843756676,
            0.004689624533057213,
            -0.004255377221852541,
            -0.018897950649261475,
            0.01784779690206051,
            -0.0014997137477621436,
            -0.003999766428023577,
            0.032005101442337036,
            0.014487010426819324,
            -0.028766782954335213,
            -0.0020950352773070335,
            0.032952215522527695,
            0.0018678297055885196,
            0.017952974885702133,
            0.03134317323565483,
            -0.032545994967222214,
            0.02789396233856678,
            0.020993610844016075,
            0.014947919175028801,
            0.01896517165005207,
            0.01524893008172512,
            -0.05947712063789368,
            0.013758700340986252,
            -0.02055894210934639,
            -0.00833916012197733,
            -0.02452470175921917,
            -0.022901274263858795,
            -0.003244927618652582,
            -0.036141108721494675,
            0.022274434566497803,
            -0.018116634339094162,
            0.02287829853594303,
            0.015015613287687302,
            0.008024036884307861,
            0.04780878871679306,
            0.03523062914609909,
            0.03340032696723938,
            0.016293000429868698,
            0.04164458066225052,
            -0.01587739773094654,
            -0.014477002434432507,
            0.014862342737615108,
            0.019184349104762077,
            -0.009331045672297478,
            0.044758282601833344,
            -0.019026003777980804,
            -0.035153985023498535,
            0.015997176989912987,
            0.03331802785396576,
            -0.01994139701128006,
            0.0160134956240654,
            -0.037539493292570114,
            0.038705870509147644,
            -0.022262005135416985,
            -0.014450541697442532,
            0.010236651636660099,
            -0.03390495106577873,
            0.01686672307550907,
            0.019782869145274162,
            -0.00862618163228035,
            -0.0063890013843774796,
            -0.01194129977375269,
            0.01802067644894123,
            -0.002619019942358136,
            -0.004176200833171606,
            -0.009656505659222603,
            0.020285217091441154,
            -0.011080739088356495,
            -0.0007592742331326008,
            0.03160978481173515,
            0.03016131930053234,
            0.02327875792980194,
            -0.021512847393751144,
            0.01038880180567503,
            0.014772703871130943,
            -0.005152301862835884,
            -0.030830439180135727,
            0.026225019246339798,
            0.014007948338985443,
            0.010692602954804897,
            0.032902248203754425,
            0.012033515609800816,
            -0.021061480045318604,
            -0.05039672553539276,
            -0.009376080706715584,
            -0.017394009977579117,
            0.0029576909728348255,
            0.014048432931303978,
            0.01566264033317566,
            -0.025395017117261887,
            -0.004543365444988012,
            0.003082272596657276,
            -0.0049934000708162785,
            0.009044915437698364,
            0.010476197116076946,
            -0.0011945924488827586,
            0.05257614329457283,
            -0.029029572382569313,
            0.06024980545043945,
            -0.01126817800104618,
            0.011803516186773777,
            0.02481459453701973,
            0.029231654480099678,
            0.06181029602885246,
            0.002336641540750861,
            -0.02399224229156971,
            0.02164316363632679,
            0.06401907652616501,
            -0.03034902550280094,
            -0.006404670421034098,
            -0.034077610820531845,
            0.015151577070355415,
            -0.006412386428564787,
            0.023898467421531677,
            -0.03297729790210724,
            -0.0015513511607423425,
            0.00012833041546400636,
            0.013925221748650074,
            -0.013887563720345497,
            -0.011799657717347145,
            -0.03125583380460739,
            0.0035738388542085886,
            0.016906706616282463,
            0.030906254425644875,
            -0.05019332841038704,
            -0.016128400340676308,
            -0.0459359809756279,
            0.02690543793141842,
            -0.036385297775268555,
            -0.009184051305055618,
            -0.0010333138052374125,
            0.0087908199056983,
            0.01216678973287344,
            0.03760359808802605,
            -0.01379278115928173,
            0.013761063106358051,
            0.022679224610328674,
            0.017578626051545143,
            7.168347656261176e-05,
            0.03329391032457352,
            -0.03520604595541954,
            0.002999799093231559,
            0.012213215231895447,
            -0.04560720548033714,
            0.012284348718822002,
            0.0017667445354163647,
            -0.0358201265335083,
            -0.015850162133574486,
            -0.010264921002089977,
            -0.034154217690229416,
            0.01569145731627941,
            0.030816584825515747,
            -0.0070035080425441265,
            -0.03334379941225052,
            0.009470999240875244,
            0.04443589597940445,
            0.019687814638018608,
            -0.004838435910642147,
            0.022564347833395004,
            -0.0061042578890919685,
            0.02979401871562004,
            -0.02617589756846428,
            -0.0007978130597621202,
            0.024441318586468697,
            -0.03362381085753441,
            -0.014047034084796906,
            -0.03312971815466881,
            0.020698362961411476,
            0.017861666157841682,
            -0.015866704285144806,
            0.0050028604455292225,
            0.01544985268265009,
            -0.030470477417111397,
            0.0161978118121624,
            0.012903999537229538,
            0.009542323648929596,
            -0.022733327001333237,
            -0.02652989700436592,
            0.031447634100914,
            0.025742381811141968,
            0.044405438005924225,
            -0.019431259483098984,
            -0.00765295559540391,
            -0.005766321439296007,
            -0.00925388466566801,
            -0.008635059930384159,
            -0.02317982167005539,
            0.00849838089197874,
            0.027386358007788658,
            0.020519539713859558,
            -0.022459791973233223,
            0.003993166610598564,
            0.016614880412817,
            -0.029428567737340927,
            -0.022884313017129898,
            0.03657440468668938,
            -0.015968143939971924,
            -0.025271689519286156,
            -0.012255856767296791,
            -0.014381700195372105,
            -0.0048521291464567184,
            0.022482741624116898,
            -0.04105553776025772,
            -0.021366411820054054,
            -0.005490397103130817,
            0.011401369236409664,
            0.018876928836107254,
            -0.02566066011786461,
            0.03403669595718384,
            0.025408567860722542,
            0.019670812413096428,
            -0.0208472590893507,
            0.003293145913630724,
            0.012100059539079666,
            0.0017862067325040698,
            0.025875292718410492,
            -0.0040231444872915745,
            -0.03026754781603813,
            -0.02800564281642437,
            -0.008228386752307415,
            -0.03406526520848274,
            0.02312427945435047,
            -0.026808785274624825,
            0.020773952826857567,
            -0.0110880546271801,
            -0.02891532890498638,
            -0.01306804921478033,
            0.0029836962930858135,
            -0.04587161913514137,
            0.023617783561348915,
            0.011007536202669144,
            -0.009042581543326378,
            -0.02388850785791874,
            -0.03231620416045189,
            -0.013058619573712349,
            0.031187668442726135,
            0.017198992893099785,
            0.020486293360590935,
            -0.015705233439803123,
            -0.014717170968651772,
            -0.005414738319814205,
            0.015849541872739792,
            0.02307894453406334,
            0.023178881034255028,
            0.022793859243392944,
            0.03251891955733299,
            0.024110810831189156,
            0.028133656829595566,
            0.01623210497200489,
            -0.013110615313053131,
            0.016031110659241676,
            -0.02261069416999817,
            0.0038402059581130743,
            0.029223138466477394,
            0.005312822759151459,
            -0.02119603380560875,
            -0.03190097585320473,
            0.025558851659297943,
            0.03562788665294647,
            -0.023091871291399002,
            -0.015310983173549175,
            -0.02982369065284729,
            0.0034648783039301634,
            0.0009190777782350779,
            -0.011371152475476265,
            -0.0067809950560331345,
            0.02450154535472393,
            0.01073953416198492,
            0.011034218594431877,
            0.0041573005728423595,
            0.007610709872096777,
            -0.01017351821064949,
            -0.007359681185334921,
            0.014257258735597134,
            0.007856507785618305,
            -0.011790649965405464,
            0.034909043461084366,
            0.00646241195499897,
            -0.016196493059396744,
            0.015502492897212505,
            -0.023969903588294983,
            -0.006283213384449482,
            -0.0074727777391672134,
            -0.000795315601862967,
            -0.0016860762843862176,
            -0.026904309168457985,
            -0.037993066012859344,
            0.011864357627928257,
            0.06828787177801132,
            0.0400192029774189,
            -0.011188026517629623,
            -0.021352089941501617,
            -0.0034178446512669325,
            0.02804562635719776,
            0.013810383155941963,
            -0.018965156748890877,
            -0.0062444149516522884,
            0.017246954143047333,
            -0.02997962012887001,
            -0.02736184187233448,
            0.008163342252373695,
            -0.012350221164524555,
            -0.0021954290568828583,
            0.020090876147150993,
            0.005520602688193321,
            -0.010385439731180668,
            -0.0025842352770268917,
            0.009947068057954311,
            -0.023332323879003525,
            -0.029786400496959686,
            0.003737471532076597,
            -0.0074382382445037365,
            -0.015486062504351139,
            -0.021384015679359436,
            0.026627613231539726,
            -0.01867680437862873,
            -0.0031923805363476276,
            0.0032610876951366663,
            0.06587253510951996,
            0.03152790665626526,
            0.04066973924636841,
            0.016935883089900017,
            -0.01110430434346199,
            -0.016726668924093246,
            -0.019993705675005913,
            -0.04961147531867027,
            0.0180274099111557,
            0.015068571083247662,
            0.00305272382684052,
            0.0008453019545413554,
            -0.018238751217722893,
            -0.027176804840564728,
            0.008294422179460526,
            0.02491280809044838,
            -0.03406529501080513,
            0.005979968700557947,
            0.02120422013103962,
            -0.024628862738609314,
            -0.016416452825069427,
            -0.01376858726143837,
            -0.004840286448597908,
            -0.03317798301577568,
            -0.009738835506141186,
            -0.020697584375739098,
            -0.046282343566417694,
            0.011125083081424236,
            0.024754490703344345,
            -0.015144611708819866,
            -0.0003616305475588888,
            -0.02656266838312149,
            0.00030300617800094187,
            -0.023619212210178375,
            0.005644205957651138,
            -0.0032366348896175623,
            0.009417531080543995,
            -0.01374860480427742,
            -0.014398030936717987,
            0.0027552598621696234,
            -0.006191680673509836,
            -0.02834756299853325,
            0.020677315071225166,
            0.0026358109898865223,
            0.011903626844286919,
            -0.010789349675178528,
            0.0042184144258499146,
            0.004880140069872141,
            0.02704993076622486,
            0.025924604386091232,
            0.023884305730462074,
            0.022321198135614395,
            0.03202642872929573,
            0.0016361864982172847,
            -0.03287922590970993,
            -0.01388524193316698,
            0.02462342008948326,
            -0.009900075383484364,
            -0.020977580919861794,
            0.020127486437559128,
            0.020866157487034798,
            -0.0173542108386755,
            -0.023878680542111397,
            0.0363372303545475,
            0.009160718880593777,
            -0.017920175567269325,
            0.005944604054093361,
            -0.022061889991164207,
            -0.00637049600481987,
            0.034379202872514725,
            -0.004999601282179356,
            -0.01213616132736206,
            0.04808507114648819,
            -0.0240302924066782,
            -0.06144889444112778,
            -0.025859391316771507,
            0.016099629923701286,
            0.013054725714027882,
            0.013717399910092354,
            0.029310157522559166,
            0.020004726946353912,
            0.007766884285956621,
            0.016732439398765564,
            0.0015427388716489077,
            -0.019362976774573326,
            0.02616025134921074,
            -0.016900917515158653,
            0.003536664880812168,
            -0.02255520224571228,
            -0.03525758162140846,
            0.040273454040288925,
            -0.008509818464517593,
            0.014676613733172417,
            -0.009050866588950157,
            -0.025689108297228813,
            -0.005753134377300739,
            0.005421445704996586,
            0.0034688410814851522,
            0.0003580085758585483,
            0.010145867243409157,
            -0.004329546354711056,
            -0.004538280889391899,
            -0.0020859483629465103,
            0.005780127365142107,
            0.0075596654787659645,
            -0.0016146887792274356,
            0.02977643348276615,
            0.031609825789928436,
            0.0007741063600406051,
            0.007166285067796707,
            -0.002976296003907919,
            -0.00708359107375145,
            0.014300033450126648,
            0.016632551327347755,
            -0.01968410052359104,
            -0.03569710627198219,
            0.040192291140556335,
            -0.026774995028972626,
            -0.015348157845437527,
            0.019998935982584953,
            -0.015337594784796238,
            -0.007727655582129955,
            0.019219228997826576,
            -0.01345769688487053,
            -0.018876472488045692,
            -0.02704903483390808,
            -0.04168326035141945,
            -0.016263077035546303,
            -0.04491489753127098,
            0.016043400391936302,
            0.018972037360072136,
            -0.021877368912100792,
            0.02227730117738247,
            0.03196118399500847,
            0.01175108551979065,
            -0.043059077113866806,
            0.021864118054509163,
            0.0165737085044384,
            0.024681098759174347,
            0.019127709791064262,
            -0.008370504714548588,
            -0.01652742549777031,
            -0.0014438055222854018,
            0.017488110810518265,
            -0.02398942969739437,
            -0.015332416631281376,
            0.02960890159010887,
            -0.01611848548054695,
            -0.030307043343782425,
            0.0011695019202306867,
            -0.0009598612668924034,
            0.02637704275548458,
            0.008395694196224213,
            -0.03007410652935505,
            -0.005933890584856272,
            0.026515938341617584,
            0.01843869313597679,
            -0.022277839481830597,
            0.0070657008327543736,
            -0.007039845921099186,
            -0.03950899466872215,
            -0.0170559361577034,
            0.018073922023177147,
            -0.016367511823773384,
            -0.0010332047240808606,
            -0.028291232883930206,
            0.03300398215651512,
            -0.0025922046042978764,
            -0.005873044487088919,
            0.023449385538697243,
            -0.02851792611181736,
            0.02478697896003723,
            -0.013112055137753487,
            0.02135191485285759,
            0.028913047164678574,
            0.02570527419447899,
            0.009327026084065437,
            0.007668125443160534,
            -0.015446280129253864,
            -0.005499196704477072,
            0.002907728310674429,
            0.026763154193758965,
            0.03230965510010719,
            -0.02747936174273491,
            0.019751204177737236,
            0.028320983052253723,
            0.016860920935869217,
            -0.006925121881067753,
            -0.013452434912323952,
            0.03719865903258324,
            0.005415655672550201,
            -0.014904712326824665,
            0.00515165226534009,
            -0.007360588293522596,
            0.034978099167346954,
            0.03685937821865082,
            -0.009032642468810081,
            -0.02233549766242504,
            -0.053167205303907394,
            0.029257792979478836,
            -0.0038782116025686264,
            -0.08520416170358658,
            -0.013601000420749187,
            0.034294355660676956,
            -0.0012946388451382518,
            -0.004622693173587322,
            0.016780897974967957,
            -0.03263256698846817,
            0.02102523483335972,
            -0.02553255297243595,
            -0.015129107050597668,
            -0.019182369112968445,
            0.007520253770053387,
            -0.031585488468408585,
            -0.005555382929742336,
            0.028213998302817345,
            -0.011256629601120949,
            0.01994183100759983,
            0.04440144822001457,
            -0.01131159346550703,
            -0.02599533274769783,
            0.008588726632297039,
            -0.010945159010589123,
            -0.002947759348899126,
            -0.022309744730591774,
            0.007675261702388525,
            -0.019504353404045105,
            0.024055413901805878,
            0.02948993816971779,
            -0.010243155993521214,
            -0.020394019782543182,
            0.017974020913243294,
            -0.0005763649241998792,
            0.020713437348604202,
            -0.002843911061063409,
            -0.022936880588531494,
            0.006146083120256662,
            0.006725670769810677,
            -0.010882481932640076,
            -0.027929319068789482,
            -0.003933851607143879,
            -0.0023216132540255785,
            -0.004921387415379286,
            -0.0026196592953056097,
            -0.020447513088583946,
            0.01338318269699812,
            -0.00283867958933115,
            0.024317078292369843,
            0.015870194882154465,
            0.01622880809009075,
            0.01910961978137493,
            0.02608083188533783,
            -0.05444708094000816,
            0.03179352357983589,
            0.02521124668419361,
            -0.049712829291820526,
            -0.01523649226874113,
            -0.004130795132368803,
            0.00609593978151679,
            0.005526961293071508,
            0.009002186357975006,
            0.004427335225045681,
            -0.0432937853038311,
            -0.0004293602833058685,
            0.023447763174772263,
            0.011506935581564903,
            0.014318941161036491,
            0.023382097482681274,
            0.012109136208891869,
            -0.010079482570290565,
            0.02768458053469658,
            -0.03016992285847664,
            -0.0453622043132782,
            -0.015638120472431183,
            -0.012360876426100731,
            0.030802184715867043,
            0.012214895337820053,
            0.00732803950086236
        ],
        [
            0.011738847941160202,
            0.03422931581735611,
            -4.107248969376087e-05,
            0.0030139656737446785,
            0.015374571084976196,
            0.03520777076482773,
            -0.006264265161007643,
            -0.024894967675209045,
            -0.014579012989997864,
            0.003958598710596561,
            -0.00026649353094398975,
            -0.018360458314418793,
            -0.03856489062309265,
            -0.021273845806717873,
            0.02515789121389389,
            0.022170651704072952,
            0.02903592959046364,
            0.010669528506696224,
            0.0016581413801759481,
            -0.027885677292943,
            0.033989209681749344,
            -0.025577394291758537,
            -0.03281533345580101,
            -0.010994581505656242,
            0.00531811872497201,
            -0.03064720146358013,
            -0.01536248903721571,
            0.0017563500441610813,
            0.030132029205560684,
            -0.0015928681241348386,
            0.023735659196972847,
            0.023504097014665604,
            0.021030927076935768,
            0.006338267587125301,
            -0.012004895135760307,
            -0.037963856011629105,
            -0.006646959111094475,
            -0.008529193699359894,
            -0.013852936215698719,
            -0.02752271667122841,
            0.007555093616247177,
            0.0039205835200846195,
            -0.009698797017335892,
            0.03527054935693741,
            -0.022817764431238174,
            -0.03980956971645355,
            -0.027982112020254135,
            -0.040340617299079895,
            -0.013731008395552635,
            -0.020167149603366852,
            -0.020228851586580276,
            0.019155370071530342,
            0.000804632727522403,
            0.04203787446022034,
            0.030965805053710938,
            -0.01631661131978035,
            -0.013058430515229702,
            -0.03725654259324074,
            0.0034578528720885515,
            0.027450120076537132,
            -0.010762102901935577,
            0.026889266446232796,
            0.03481251746416092,
            0.04165645316243172,
            0.007867090404033661,
            0.03996429964900017,
            -0.009524442255496979,
            -0.030122525990009308,
            0.010930290445685387,
            -0.009452776983380318,
            0.010497392155230045,
            -0.006162066478282213,
            -0.029676439240574837,
            0.005202239844948053,
            -0.03330371528863907,
            -0.019629579037427902,
            0.021074997261166573,
            0.018441351130604744,
            -0.0012261108495295048,
            -0.0027262528892606497,
            0.010672114789485931,
            0.03142867609858513,
            -0.006144389975816011,
            -0.029812941327691078,
            0.03322182968258858,
            0.007753618527203798,
            0.001483836444094777,
            -0.01111210510134697,
            -0.015174569562077522,
            0.005001562647521496,
            -0.02059789188206196,
            0.026941321790218353,
            0.015614962205290794,
            -0.013283670879900455,
            -0.019019784405827522,
            0.011637119576334953,
            -0.010373985394835472,
            0.022176746279001236,
            -0.019787490367889404,
            0.0042068324983119965,
            0.034061919897794724,
            -0.010788637213408947,
            -0.015379562973976135,
            -0.024036338552832603,
            0.03491174057126045,
            -0.019082527607679367,
            0.03248133137822151,
            -0.02701309137046337,
            -0.04828066751360893,
            0.0027315777260810137,
            0.011883605271577835,
            -0.016844727098941803,
            0.024493811652064323,
            -0.023733103647828102,
            0.04982413351535797,
            0.007569152396172285,
            -0.029247362166643143,
            0.022449331358075142,
            -0.005283304024487734,
            -0.011412877589464188,
            -0.019915670156478882,
            -0.006147260311990976,
            -0.04407599940896034,
            -0.006206789053976536,
            -0.007845048792660236,
            0.0031853679101914167,
            0.02569746971130371,
            0.022073108702898026,
            0.010437965393066406,
            0.013353422284126282,
            0.02314869686961174,
            0.006672240793704987,
            0.010616225190460682,
            -0.021547876298427582,
            0.02667524479329586,
            0.029079891741275787,
            -0.018924785777926445,
            -0.011955579742789268,
            0.03379356488585472,
            -0.02524884045124054,
            0.024572569876909256,
            0.01666981913149357,
            0.022590510547161102,
            0.000742266362067312,
            -0.025129981338977814,
            0.01956700161099434,
            0.001492057228460908,
            -0.030015762895345688,
            0.0056955390609800816,
            0.03904136270284653,
            0.009186209179461002,
            -0.007612788584083319,
            -0.027151918038725853,
            -0.005314416252076626,
            0.023580389097332954,
            0.02988627552986145,
            -0.02501986362040043,
            -0.004162066616117954,
            -0.028693079948425293,
            0.019306913018226624,
            0.023632749915122986,
            0.017179323360323906,
            0.011969642713665962,
            0.001950717531144619,
            0.019786302000284195,
            0.02310338243842125,
            -0.023137379437685013,
            0.033517058938741684,
            0.007778002414852381,
            -0.02710663713514805,
            -0.0327192023396492,
            -0.0347604937851429,
            -0.041501522064208984,
            0.029990682378411293,
            -0.026831751689314842,
            0.002042357111349702,
            -0.0028312671929597855,
            0.008870049379765987,
            -0.014191901311278343,
            0.03666660934686661,
            0.0022650614846497774,
            0.030888693407177925,
            -0.020631927996873856,
            -0.0020878759678453207,
            -0.0230820644646883,
            -0.01654960587620735,
            0.028456801548600197,
            -0.019201060757040977,
            -0.02339688502252102,
            -0.016118405386805534,
            0.012331094592809677,
            -0.026488887146115303,
            -0.04732745513319969,
            -0.02401932142674923,
            0.022350531071424484,
            0.0029209444765001535,
            -0.011345415376126766,
            -0.004665067885071039,
            0.02578502893447876,
            -0.014641517773270607,
            -0.02071569301187992,
            -0.024603547528386116,
            0.01577320508658886,
            0.003316832473501563,
            0.0041910442523658276,
            0.008403855375945568,
            0.010791820473968983,
            0.019275572150945663,
            -0.008326234295964241,
            -0.01749115064740181,
            -0.01235515158623457,
            -0.06687617301940918,
            0.0017953150672838092,
            0.03032366745173931,
            -0.0007674965891055763,
            -0.02268061600625515,
            -0.032836247235536575,
            -0.0027749633882194757,
            0.015856031328439713,
            0.0027972913812845945,
            -0.015699569135904312,
            0.024766860529780388,
            0.01887335069477558,
            -0.021455232053995132,
            0.01284439954906702,
            -0.03492007032036781,
            0.03210369870066643,
            -0.01611495390534401,
            -0.0067244297824800014,
            0.028074907138943672,
            -0.019123181700706482,
            -0.026904422789812088,
            0.007529002148658037,
            -0.020756389945745468,
            0.032213520258665085,
            0.02925751730799675,
            0.024600176140666008,
            0.015853844583034515,
            0.029425155371427536,
            -0.008222350850701332,
            0.016955861821770668,
            -0.009930533356964588,
            -0.02183862216770649,
            -0.021938003599643707,
            -0.021305223926901817,
            -0.025291986763477325,
            -0.013219263404607773,
            0.0327187180519104,
            -0.018176244571805,
            0.009974723681807518,
            0.010423594154417515,
            -0.006465526297688484,
            -0.025837618857622147,
            0.0039034541696310043,
            -0.003503953106701374,
            -0.01631876640021801,
            0.023500746116042137,
            0.01508262101560831,
            -0.010029342956840992,
            -0.011896435171365738,
            -0.017591694369912148,
            -0.004165487829595804,
            -0.0015122606419026852,
            0.014484560117125511,
            -0.015489313751459122,
            0.013799774460494518,
            -0.013052484951913357,
            0.009052278473973274,
            -0.024580033496022224,
            -0.020857468247413635,
            0.023519761860370636,
            0.002367966342717409,
            0.03578489273786545,
            -0.010721593163907528,
            0.010331399738788605,
            -0.011904628947377205,
            -0.01669042743742466,
            -0.007930068299174309,
            0.013431170955300331,
            0.015401693060994148,
            0.0007034693844616413,
            0.008347160182893276,
            0.012810432352125645,
            -0.0055361599661409855,
            -0.02694227360188961,
            -0.00463911471888423,
            0.027908656746149063,
            -0.06767256557941437,
            -0.030383797362446785,
            -0.015035907737910748,
            0.024230748414993286,
            0.01810198836028576,
            0.011417125351727009,
            -0.020913636311888695,
            0.03154855966567993,
            0.029777586460113525,
            -0.01016015000641346,
            -0.019307605922222137,
            -0.011725963093340397,
            0.017911110073328018,
            0.012514924630522728,
            0.021313350647687912,
            0.0009741850662976503,
            -0.012280239723622799,
            0.010778109543025494,
            -0.01583571918308735,
            -0.021906912326812744,
            0.008197726681828499,
            0.010050649754703045,
            0.03642303869128227,
            -0.015291571617126465,
            0.014828016981482506,
            0.022576119750738144,
            0.00312389200553298,
            0.01206876803189516,
            -0.001219630823470652,
            -0.004424761049449444,
            -0.01392366923391819,
            0.027645990252494812,
            0.003909976687282324,
            0.020839396864175797,
            0.01505306176841259,
            0.0030943716410547495,
            0.018503762781620026,
            0.014410809613764286,
            -0.02897551842033863,
            -0.006049674469977617,
            -0.042505186051130295,
            0.011860992759466171,
            0.015761366114020348,
            0.014592308551073074,
            0.01113063097000122,
            -0.007318322081118822,
            -0.007835237309336662,
            0.01947552151978016,
            -0.03308475762605667,
            -0.026313595473766327,
            -0.02135332114994526,
            -0.0120606804266572,
            0.03521698713302612,
            -0.024561194702982903,
            -0.040046822279691696,
            -0.0450630784034729,
            -0.021361900493502617,
            -0.02305917628109455,
            -0.008253923617303371,
            0.04204707220196724,
            0.013842836022377014,
            -0.015509764663875103,
            -0.0036200182512402534,
            -0.018904058262705803,
            -0.013634889386594296,
            -0.009893192909657955,
            -0.025426847860217094,
            -0.013290675356984138,
            0.02233062870800495,
            -0.02271074242889881,
            0.015345240011811256,
            -0.0008016092469915748,
            -0.016000766307115555,
            -0.0254827830940485,
            -0.0014748011017218232,
            -0.0038345472421497107,
            -0.009468368254601955,
            -0.02263735421001911,
            0.016096310690045357,
            0.04077903553843498,
            0.02299022302031517,
            -0.032336823642253876,
            -0.019826026633381844,
            -0.02072727680206299,
            -0.02667962945997715,
            0.03082544356584549,
            -0.03211287409067154,
            0.015812857076525688,
            0.029599176719784737,
            0.005174476653337479,
            0.0002776825276669115,
            -0.02088511362671852,
            -0.008381242863833904,
            -0.007676928769797087,
            0.014661695808172226,
            -0.0212152861058712,
            0.013744308613240719,
            -0.02801712602376938,
            -0.018723690882325172,
            0.002637137658894062,
            0.02202366106212139,
            -0.001780424383468926,
            0.03130406513810158,
            0.02247643657028675,
            0.04030585289001465,
            -0.008758565410971642,
            -0.025240717455744743,
            0.041163694113492966,
            0.022789105772972107,
            -0.003843099344521761,
            0.0021335668861865997,
            0.016302740201354027,
            -0.013454699888825417,
            -0.03347066044807434,
            0.01887301541864872,
            -0.0208442322909832,
            0.005406764801591635,
            0.006248340010643005,
            -0.006445621140301228,
            0.012536250986158848,
            -0.013289876282215118,
            -0.005493009462952614,
            0.034999847412109375,
            -0.01633044146001339,
            -0.009956266731023788,
            0.0033986433409154415,
            -0.018723279237747192,
            0.027323925867676735,
            0.010666893795132637,
            -0.026809321716427803,
            0.01618296280503273,
            0.0036079161800444126,
            0.017018189653754234,
            0.025090264156460762,
            0.0014447274152189493,
            0.028418904170393944,
            0.010351302102208138,
            -0.0192143265157938,
            0.025353966280817986,
            -0.026026522740721703,
            -0.020999334752559662,
            0.030399208888411522,
            0.0012847527395933867,
            0.014553934335708618,
            -0.004574868828058243,
            -0.04650859907269478,
            0.003880284260958433,
            -0.013798889704048634,
            0.02759857289493084,
            0.012125339359045029,
            0.006263974122703075,
            -0.020089320838451385,
            -0.026065349578857422,
            0.009372767992317677,
            -0.04756145551800728,
            0.014451907947659492,
            -0.033514346927404404,
            0.0025783146265894175,
            0.004484372679144144,
            -0.006668341811746359,
            -0.027850933372974396,
            0.03909584507346153,
            -0.031492896378040314,
            -0.03440491110086441,
            0.04674925655126572,
            0.01059984602034092,
            -0.01265689730644226,
            0.012584746815264225,
            0.016853924840688705,
            0.04076816514134407,
            0.028295928612351418,
            0.018102426081895828,
            0.005985303316265345,
            -0.013745983131229877,
            -0.012151085771620274,
            -0.003659225068986416,
            0.005619587376713753,
            0.007390215527266264,
            0.01256947498768568,
            0.0006113143172115088,
            0.04220964014530182,
            0.019272513687610626,
            0.011507213115692139,
            0.03146202489733696,
            0.009139825589954853,
            0.01537562906742096,
            0.008659301325678825,
            -0.005846376996487379,
            -0.027422282844781876,
            -0.01483963243663311,
            0.02484293095767498,
            0.008098915219306946,
            0.0032754738349467516,
            -0.011206411756575108,
            -0.014119967818260193,
            -0.05581118166446686,
            -0.02836199291050434,
            0.001533676404505968,
            0.006835545413196087,
            0.0258665531873703,
            0.0009576257434673607,
            -0.06886032968759537,
            0.0114426389336586,
            -0.049960099160671234,
            0.002448329469189048,
            0.0077225626446306705,
            0.026539074257016182,
            0.009845173917710781,
            -0.020837577059864998,
            0.012505580671131611,
            -0.01691088266670704,
            0.02708732709288597,
            -0.03158774971961975,
            -0.003933653235435486,
            0.012306230142712593,
            0.01810341514647007,
            -0.030945036560297012,
            0.031130695715546608,
            -0.026352856308221817,
            -0.033403538167476654,
            0.013384232297539711,
            -0.013362868689000607,
            0.028883827850222588,
            0.009501726366579533,
            0.01535232737660408,
            0.022005833685398102,
            0.04118125140666962,
            0.028532471507787704,
            0.03516290336847305,
            0.0024062241427600384,
            0.0008894489728845656,
            -0.003152102930471301,
            -0.0009824313456192613,
            -0.018224572762846947,
            0.018709927797317505,
            -0.028654349967837334,
            0.02169291488826275,
            -0.011405020020902157,
            -0.03668886050581932,
            0.02080630324780941,
            0.006267255637794733,
            0.01000936608761549,
            0.0014127796748653054,
            0.001654218533076346,
            -0.011007302440702915,
            -0.009378285147249699,
            -0.011357239447534084,
            0.026920437812805176,
            0.04414975270628929,
            0.023450888693332672,
            0.0034918803721666336,
            -0.008927308954298496,
            -0.001828566426411271,
            0.022344538941979408,
            -0.02434595115482807,
            0.03303231671452522,
            -0.0010245455196127295,
            0.010630219243466854,
            0.013733909465372562,
            -0.012960946187376976,
            -0.04148387163877487,
            0.0038205632008612156,
            -0.021983135491609573,
            0.020289771258831024,
            0.0068647791631519794,
            0.006902134045958519,
            -0.03963477164506912,
            -0.026767713949084282,
            0.003514103824272752,
            0.014537219889461994,
            0.010522832162678242,
            -0.01427487749606371,
            -0.01067958865314722,
            -0.005433536134660244,
            -0.0198968555778265,
            0.02067526802420616,
            0.03930320218205452,
            -0.020242517814040184,
            0.002604474313557148,
            -0.031326066702604294,
            -0.006323416251689196,
            0.020355021581053734,
            -0.021806765347719193,
            0.019121507182717323,
            0.02242213673889637,
            -0.010508731938898563,
            -0.023870982229709625,
            -0.008671962656080723,
            -0.0010401096660643816,
            0.00026242074090987444,
            -0.0228095855563879,
            0.0033985169138759375,
            -0.026363616809248924,
            -0.024674557149410248,
            -0.004440242424607277,
            -0.029215510934591293,
            -0.003084723139181733,
            0.008509853854775429,
            -0.023752283304929733,
            0.02836563065648079,
            0.01998484879732132,
            -0.030360357835888863,
            0.008280900306999683,
            -0.027511736378073692,
            -0.016197532415390015,
            0.028222275897860527,
            0.004595273640006781,
            -0.011380499228835106,
            0.016218531876802444,
            -0.004951877985149622,
            0.03183719888329506,
            0.027975397184491158,
            0.021983491256833076,
            0.054875776171684265,
            0.03481138497591019,
            -0.004596487153321505,
            -0.012712385505437851,
            -0.008587582968175411,
            -0.022004015743732452,
            -0.012793476693332195,
            0.015264309011399746,
            -0.03572752699255943,
            -0.006925376132130623,
            0.007961396127939224,
            0.027507496997714043,
            0.02145223692059517,
            0.023167364299297333,
            -0.030423888936638832,
            0.012775352224707603,
            -0.0184245016425848,
            0.013731518760323524,
            0.020244194194674492,
            -0.011185185983777046,
            -0.03138928860425949,
            0.014655346050858498,
            0.02049962431192398,
            -0.028963549062609673,
            0.027155382558703423,
            0.011981138028204441,
            0.02196972258388996,
            0.0009367414168082178,
            -0.009743690490722656,
            -0.04303088039159775,
            0.020168619230389595,
            0.026240654289722443,
            -0.011588910594582558,
            -0.05336465686559677,
            -0.011425389908254147,
            -0.0286417193710804,
            0.031804852187633514,
            0.006264340598136187,
            0.02026967890560627,
            0.0036195041611790657,
            0.021749421954154968,
            0.05921714007854462,
            0.03061145916581154,
            0.03529634326696396,
            0.009870017878711224,
            -0.029291121289134026,
            0.010885512456297874,
            0.026452941820025444,
            0.029474101960659027,
            0.005062153097242117,
            0.003685035277158022,
            -0.012416580691933632,
            0.017713751643896103,
            -0.01814536191523075,
            -0.0014420077204704285,
            0.017875205725431442,
            -0.01922948844730854,
            0.017969302833080292,
            -0.005327904596924782,
            -5.7032666518352926e-05,
            -0.020857881754636765,
            -0.01004608254879713,
            0.018394341692328453,
            -0.006623095367103815,
            -0.02210463210940361,
            0.02550751529633999,
            0.009480352513492107,
            0.0023495054338127375,
            -0.014071383513510227,
            -0.02378392219543457,
            -0.008189240470528603,
            -0.0205935500562191,
            0.000526356277987361,
            -0.034100279211997986,
            -0.011543313041329384,
            -0.0010992292081937194,
            -0.01219203881919384,
            0.014572846703231335,
            0.01962059736251831,
            0.010556105524301529,
            0.016777217388153076,
            0.02937532588839531,
            0.031775202602148056,
            0.012528423219919205,
            -0.02509988658130169,
            -0.010466941632330418,
            -0.003890818916261196,
            -0.005598759278655052,
            0.010717063210904598,
            0.0076329344883561134,
            0.004043781664222479,
            -0.011362363584339619,
            -0.034571949392557144,
            0.00041295666596852243,
            -0.012788006104528904,
            0.0028210091404616833,
            0.030483897775411606,
            -0.017584044486284256,
            0.027826735749840736,
            0.007411573082208633,
            0.02379351295530796,
            -0.048909980803728104,
            0.014955190010368824,
            -0.03598564490675926,
            0.020639488473534584,
            0.026112787425518036,
            -0.015032879076898098,
            -0.01945810765028,
            -0.005676706787198782,
            -0.023273559287190437,
            -0.008362066000699997,
            0.008440349251031876,
            0.002272275974974036,
            -0.016992514953017235,
            0.02234441041946411,
            0.030649561434984207,
            -0.05410826951265335,
            -0.007354689296334982,
            -0.02225544862449169,
            0.014835361391305923,
            0.021800654008984566,
            -0.021777303889393806,
            -0.008275551721453667,
            0.01581088826060295,
            0.013131527230143547,
            -0.03887521103024483,
            -0.014786064624786377,
            0.014753277413547039,
            -0.02106746844947338,
            0.014807461760938168,
            0.03737270087003708,
            -0.0319315567612648,
            0.008434472605586052,
            -0.003003582125529647,
            -0.012753191404044628,
            0.01905137486755848,
            0.026157431304454803,
            -0.0025447707157582045,
            0.03315487131476402,
            0.0288026574999094,
            -0.02139974571764469,
            -0.02215295471251011,
            -0.01921187900006771,
            -0.019334683194756508,
            -0.012042881920933723,
            -0.022465668618679047,
            0.027410045266151428,
            -0.01572813279926777,
            -0.023590702563524246,
            -0.0009100025636143982,
            -0.01812160760164261,
            0.007697142194956541,
            -0.0014340172056108713,
            0.024321844801306725,
            0.0029619194101542234,
            -0.009844288229942322,
            -0.015470415353775024,
            0.027656635269522667,
            -0.05705103278160095,
            0.01749548502266407,
            -0.01640300266444683,
            0.004821250215172768,
            -0.02728470414876938,
            -0.03244677186012268,
            0.011512777768075466,
            0.006637495011091232,
            -0.005872704554349184,
            -0.0040347762405872345,
            -0.0016137122875079513,
            0.008927647955715656,
            -0.02813352271914482,
            0.0321393683552742,
            0.032476793974637985,
            -0.03392511606216431,
            -0.004103950224816799,
            -0.024524377658963203,
            0.007144886534661055,
            -0.006151324138045311,
            0.02818409726023674,
            -0.004095715004950762,
            0.01619553193449974,
            0.02486906573176384,
            -0.01931156776845455,
            -0.02887110225856304,
            0.0031133219599723816,
            0.021709294989705086,
            0.014862908981740475,
            -0.004762433934956789,
            -0.007504027336835861,
            0.006052332930266857,
            0.03707651048898697,
            0.010342925786972046,
            -0.010779070667922497,
            -0.05188293755054474,
            0.005053355358541012,
            0.018864965066313744,
            0.0035839874763041735,
            0.03359037637710571,
            0.03290657699108124,
            0.012195900082588196,
            0.002433431800454855,
            0.017788099125027657,
            -0.014274811372160912,
            0.05037115141749382,
            0.010350006632506847,
            -0.018820246681571007,
            0.006555579137057066,
            -0.009573720395565033,
            0.01655573584139347,
            0.025170529261231422,
            0.016725383698940277,
            -0.026303641498088837,
            -0.005834623705595732,
            -0.012662539258599281,
            0.0041801175102591515,
            0.014737963676452637,
            0.01089311484247446,
            -0.010127240791916847,
            0.010212467983365059,
            -0.0016915242886170745,
            0.012206736952066422,
            0.017929717898368835,
            -0.0028838817961513996,
            -0.012160765007138252,
            -0.0005331182037480175,
            0.006136336829513311,
            -0.010426432825624943,
            -0.005759251303970814,
            0.0111282579600811,
            0.0076303365640342236,
            -0.031576696783304214,
            0.02054600417613983,
            0.0067223249934613705,
            -0.004751027561724186,
            0.033129047602415085,
            0.025794582441449165,
            -0.030551256611943245,
            0.03425031155347824,
            0.00554318493232131,
            -0.05689645931124687,
            0.028950827196240425,
            -0.0015069229993969202,
            0.036035824567079544,
            0.007304135710000992,
            -0.014466146938502789,
            0.0225808247923851,
            -0.025447823107242584,
            0.0017082237172871828,
            -0.012570930644869804,
            0.0006711390451528132,
            -0.0012863053707405925,
            -0.020574800670146942,
            -0.0015663839876651764,
            0.030450889840722084,
            -0.010007187724113464,
            -0.025121327489614487,
            0.008419779129326344,
            -0.002503996714949608,
            0.04282151907682419,
            0.034391529858112335,
            0.02043616957962513,
            -0.017267631366848946,
            -0.010544723831117153,
            0.03837702050805092,
            -0.026896273717284203,
            0.017745601013302803,
            -0.01878245174884796,
            -0.012325559742748737,
            -0.014026624150574207,
            0.0033107323106378317,
            0.0032396651804447174,
            0.027070431038737297,
            -0.00025290262419730425,
            -0.0076210713014006615,
            0.00891892146319151,
            -0.029233841225504875,
            0.01535447221249342,
            0.01548907719552517,
            -0.0218523982912302,
            -0.02347387559711933,
            -0.02174091525375843,
            -0.009358741343021393,
            -0.008182884193956852,
            0.017277566716074944,
            -0.0525730662047863,
            0.0254511795938015,
            0.024559730663895607,
            -0.01660040207207203,
            0.02443191036581993,
            0.018862409517169,
            -0.02149789221584797,
            -3.630050923675299e-05,
            -0.022032838314771652,
            -0.027288561686873436,
            0.02843923680484295,
            -0.008319949731230736,
            0.0018522333120927215,
            0.02633570320904255,
            0.01946369558572769,
            -0.017313608899712563,
            0.012003735639154911,
            -0.019715826958417892,
            0.0232944805175066,
            0.03411203995347023,
            0.018087023869156837,
            -0.020053861662745476,
            -0.030958985909819603,
            0.010422387160360813,
            -0.020913124084472656,
            -0.00696460111066699,
            0.0010906698880717158,
            0.010276763699948788,
            0.011770166456699371,
            -0.009070619940757751,
            -4.089188587386161e-05,
            -0.003837685799226165,
            -0.0013655184302479029,
            0.006376028526574373,
            0.008448365144431591,
            -0.026906659826636314,
            0.03071972355246544,
            0.010846065357327461,
            0.013430857099592686,
            -0.0033448091708123684,
            -0.014593775384128094,
            0.02757812850177288,
            -0.019157467409968376,
            0.011808563023805618,
            0.014843176119029522,
            0.015165368095040321,
            0.008795800618827343,
            0.0006969571695663035,
            0.007383361924439669,
            -0.0017850602744147182,
            -0.012029102072119713,
            0.0011196190025657415,
            0.0006799667025916278,
            0.03275170177221298,
            -0.03501663729548454,
            0.03576996177434921,
            -0.009873753413558006,
            0.010728176683187485,
            0.0029515193309634924,
            0.017663413658738136,
            0.0016412838594987988,
            -0.024627437815070152,
            0.0350593701004982,
            -0.007866635918617249,
            0.02234363742172718,
            0.011977938935160637,
            -0.0013114322209730744,
            -0.001017231261357665,
            0.03153261914849281,
            -0.0364447645843029,
            -0.00013770908117294312,
            -0.01104237325489521,
            0.02341342531144619,
            0.009788001887500286,
            -0.016699792817234993,
            0.01604384370148182,
            0.0025933971628546715,
            -0.020423414185643196,
            0.025935916230082512,
            0.025644797831773758,
            -0.042748600244522095,
            0.041288845241069794,
            -0.0189986415207386,
            -0.016062144190073013,
            0.01758991926908493,
            0.0047284760512411594,
            0.03386659547686577,
            0.030332064256072044,
            -0.030925260856747627,
            0.006976967211812735,
            0.02743588015437126,
            0.014057028107345104,
            0.057246506214141846,
            -0.0016321472357958555,
            -0.012717926874756813,
            0.01691233366727829,
            0.01583731546998024,
            -0.03353887423872948,
            0.0006538448506034911,
            -0.022738169878721237,
            -0.0006200961652211845,
            0.007721825037151575,
            0.0004154908820055425,
            -0.009166811592876911,
            0.031822241842746735,
            -0.019913289695978165,
            -0.012424979358911514,
            0.006502156611531973,
            8.688312664162368e-05,
            -0.030891692265868187,
            0.00730277132242918,
            -0.036910634487867355,
            0.022549916058778763,
            0.0007410449325107038,
            0.003114056307822466,
            0.016753463074564934,
            -0.030915914103388786,
            0.01997988298535347,
            -0.009807179681956768,
            -0.021139422431588173,
            0.026980312541127205,
            0.025899574160575867,
            0.015124560333788395,
            -0.021530285477638245,
            -0.018815739080309868,
            -0.0014180532889440656,
            -0.006337870378047228,
            0.016176333650946617,
            0.01734386943280697,
            0.03423730283975601,
            0.028882170096039772,
            0.00349653628654778,
            0.012027835473418236,
            0.007928779348731041,
            -0.00417164945974946,
            -0.009329236112535,
            0.014894956722855568,
            0.025507625192403793,
            0.05222976207733154,
            -0.0004065505345351994
        ],
        [
            -0.03338629752397537,
            0.0005291218985803425,
            0.029941163957118988,
            -0.017064964398741722,
            0.0026028051506727934,
            -0.046756986528635025,
            0.033907610923051834,
            0.015981819480657578,
            0.0005639269948005676,
            0.01348927617073059,
            -0.012587225064635277,
            -0.005962313152849674,
            -0.02366597019135952,
            -0.008692021481692791,
            -0.06512072682380676,
            0.029111210256814957,
            0.0056015201844275,
            -0.02111908793449402,
            -0.026796234771609306,
            -0.0010486860992386937,
            0.02310328185558319,
            -0.0034565513487905264,
            0.04378914833068848,
            -0.011232353746891022,
            0.011611757799983025,
            -0.016034165397286415,
            0.04150404408574104,
            -0.01458733156323433,
            -0.024191681295633316,
            -0.033202171325683594,
            -0.008501755073666573,
            0.013932738453149796,
            0.005372138228267431,
            -0.012085270136594772,
            0.008784908801317215,
            -0.014685355126857758,
            0.010090329684317112,
            -0.008672541938722134,
            0.017428673803806305,
            -0.01944194920361042,
            0.021676326170563698,
            -0.004900472238659859,
            0.013661768287420273,
            0.028547609224915504,
            -0.004700175020843744,
            -0.02334032952785492,
            0.006942277308553457,
            0.00064082135213539,
            0.03662177175283432,
            0.014642140828073025,
            0.0296441949903965,
            -0.02021101303398609,
            -0.01683555170893669,
            0.005374161526560783,
            -0.03430628776550293,
            -0.01286520715802908,
            -0.01094451267272234,
            -0.02198599837720394,
            -0.012788312509655952,
            0.012089102528989315,
            -0.024689029902219772,
            -0.01141666155308485,
            -0.0006880999426357448,
            0.004749310202896595,
            -0.033731769770383835,
            0.0016004962380975485,
            0.012899412773549557,
            0.005782177206128836,
            0.015313527546823025,
            0.0005594592075794935,
            0.0041527897119522095,
            0.014654994942247868,
            0.0084547009319067,
            0.020272113382816315,
            0.010188973508775234,
            -0.008618900552392006,
            -0.04349507391452789,
            -0.022039320319890976,
            0.023981887847185135,
            0.0031978762708604336,
            -0.008992855437099934,
            -0.014086928218603134,
            -0.00929851271212101,
            -0.01111688744276762,
            0.02750752493739128,
            -0.02729860506951809,
            -0.033474378287792206,
            -0.0007930193678475916,
            -0.01799379661679268,
            0.006514156702905893,
            0.007925226353108883,
            0.03138517588376999,
            -0.01731163263320923,
            -0.0020252871327102184,
            0.005742853973060846,
            -0.04086865112185478,
            -0.0016841792967170477,
            -0.018764542415738106,
            0.06106508895754814,
            0.015552476979792118,
            0.018619762733578682,
            -0.001071590930223465,
            -0.012873388826847076,
            0.0011931147892028093,
            -0.00782120693475008,
            0.04941454157233238,
            -0.01095062680542469,
            -0.015315599739551544,
            0.020214861258864403,
            -0.016170566901564598,
            -0.006973049603402615,
            0.013497958891093731,
            0.002762298099696636,
            -0.016644038259983063,
            0.03234872967004776,
            -0.021206306293606758,
            -0.0026759689208120108,
            0.024420054629445076,
            -0.0013464787043631077,
            -0.017017720267176628,
            -0.00024087028577923775,
            0.01639321818947792,
            -0.003685878124088049,
            0.002369755180552602,
            0.011657370254397392,
            0.012028980068862438,
            -0.020393937826156616,
            0.01992391049861908,
            -0.017389295622706413,
            -0.007859006524085999,
            -0.024335984140634537,
            -0.006011907011270523,
            -0.020721202716231346,
            -0.05269342288374901,
            -0.004046784248203039,
            -0.021156063303351402,
            -0.016447888687253,
            -0.05079531669616699,
            -0.032894521951675415,
            -0.05026724562048912,
            0.01871040090918541,
            0.031082145869731903,
            0.015281940810382366,
            0.012121380306780338,
            0.01733679138123989,
            -0.005292913876473904,
            0.004684680141508579,
            -0.03300230950117111,
            0.040150370448827744,
            -0.037126410752534866,
            -0.034474827349185944,
            -0.026771387085318565,
            0.025151027366518974,
            -0.01855608820915222,
            -0.008159970864653587,
            -0.0018681578803807497,
            -0.0002616449201013893,
            -0.024609170854091644,
            0.0178709514439106,
            -0.030863460153341293,
            -0.021971939131617546,
            0.022312238812446594,
            0.00990347284823656,
            -0.0012053855461999774,
            -0.004947482608258724,
            0.012357293628156185,
            -0.03889971598982811,
            0.024253128096461296,
            -0.008260045200586319,
            -0.013989640399813652,
            0.019230401143431664,
            0.027227113023400307,
            0.01966073177754879,
            -0.027268782258033752,
            0.02200949192047119,
            -0.008339903317391872,
            -0.02648337371647358,
            0.006221664138138294,
            0.0032574892975389957,
            -0.017609553411602974,
            -0.046756040304899216,
            0.005293079651892185,
            -0.02603342942893505,
            0.000785520882345736,
            0.01764153130352497,
            -0.007678499445319176,
            0.01141655258834362,
            0.007092065177857876,
            0.031258177012205124,
            0.0031450618989765644,
            -0.03871473670005798,
            -0.017911648377776146,
            -0.0637672170996666,
            -0.01138851884752512,
            0.009378341026604176,
            -0.00425560399889946,
            0.02628951705992222,
            0.003373070852831006,
            -0.01831125095486641,
            0.0238606296479702,
            0.002683396218344569,
            -0.007318638730794191,
            -0.034821756184101105,
            -0.010596862062811852,
            0.016736548393964767,
            0.001897588837891817,
            0.055448420345783234,
            -0.051409367471933365,
            -0.0049487752839922905,
            0.0006855462561361492,
            0.01775609329342842,
            -0.0022770811337977648,
            -0.023580513894557953,
            -0.01363888569176197,
            -0.01402035728096962,
            -0.004115233197808266,
            -0.00510798767209053,
            0.02426394261419773,
            -0.025214774534106255,
            0.007786598522216082,
            0.031160671263933182,
            0.030673593282699585,
            -0.0049471138045191765,
            -0.02672971412539482,
            0.012410713359713554,
            0.06621852517127991,
            0.013761506415903568,
            0.010780753567814827,
            -0.04094429314136505,
            -0.010124711319804192,
            0.01830097660422325,
            0.002126421080902219,
            0.005222713574767113,
            -0.010441313497722149,
            -0.0066935052163898945,
            -0.019892791286110878,
            -0.03151559457182884,
            0.004362526349723339,
            0.0382155105471611,
            0.03519970551133156,
            -0.0031354990787804127,
            0.0014586884062737226,
            -0.0219278447329998,
            0.0077077713795006275,
            -0.015464351512491703,
            0.01988321915268898,
            -0.02536882273852825,
            -0.02950189635157585,
            0.017256371676921844,
            -0.012113460339605808,
            0.024680688977241516,
            0.005906237289309502,
            -0.004071257077157497,
            0.0017686007777228951,
            0.007815088145434856,
            0.018936799839138985,
            -0.021167194470763206,
            0.009896797128021717,
            -0.03719010949134827,
            0.007168472278863192,
            -0.021115384995937347,
            0.010437962599098682,
            -0.05676854029297829,
            -0.022339407354593277,
            0.0346459299325943,
            0.05137494578957558,
            0.027438968420028687,
            -0.005365753546357155,
            -0.007414575666189194,
            0.009069697000086308,
            -0.024034367874264717,
            0.01214758213609457,
            -0.011200039647519588,
            0.019671007990837097,
            0.036510907113552094,
            -0.01904165744781494,
            -0.014673620462417603,
            -0.0044928682036697865,
            0.017746761441230774,
            -0.002490012673661113,
            -0.023385340347886086,
            -0.042502403259277344,
            -0.01781139336526394,
            0.037949979305267334,
            0.018898339942097664,
            0.024457797408103943,
            0.00701972097158432,
            -0.04034970700740814,
            0.019402191042900085,
            -0.015023428946733475,
            -0.03875837102532387,
            0.01430927962064743,
            0.0225777979940176,
            0.04370792210102081,
            0.007929920218884945,
            0.0050336867570877075,
            0.023852530866861343,
            0.042044151574373245,
            -0.050381507724523544,
            -0.005899609532207251,
            -0.013208884745836258,
            -0.011207337491214275,
            0.0219484344124794,
            -0.001144884037785232,
            -0.02268923632800579,
            -0.0049959165044128895,
            -0.03878844156861305,
            -0.058893270790576935,
            -0.02588515914976597,
            -0.043193839490413666,
            0.0004916871548630297,
            -0.007119542919099331,
            -0.0056108711287379265,
            -0.01959840953350067,
            -0.024524517357349396,
            -0.0022439241874963045,
            -0.015229208394885063,
            0.03429047763347626,
            0.015493245795369148,
            -0.006181080359965563,
            0.024377742782235146,
            -0.04969818517565727,
            -0.03111344762146473,
            -0.01992889679968357,
            0.01787959225475788,
            -0.016584355384111404,
            0.007166434079408646,
            -0.008608154021203518,
            -0.04143596068024635,
            0.017918555065989494,
            -0.025176923722028732,
            0.035393256694078445,
            -0.03032623417675495,
            -0.00603235000744462,
            -0.042817484587430954,
            0.0008976336102932692,
            -0.013596695847809315,
            -0.009180383756756783,
            0.006122211460024118,
            0.021011117845773697,
            0.00661134859547019,
            -0.012758682481944561,
            0.047517091035842896,
            0.029103217646479607,
            -0.011959058232605457,
            0.029221750795841217,
            -0.010190129280090332,
            0.0017309073591604829,
            0.010481699369847775,
            0.03469090908765793,
            -0.011464865878224373,
            -0.048762205988168716,
            -0.022390853613615036,
            -0.009814238175749779,
            0.027111800387501717,
            0.003639182774350047,
            0.0008916687220335007,
            0.011353741399943829,
            0.025075191631913185,
            -0.011771264486014843,
            -0.014710926450788975,
            0.012101470492780209,
            0.02287943847477436,
            -0.008026480674743652,
            0.01549314334988594,
            -0.01195914950221777,
            0.021393775939941406,
            0.010109467431902885,
            -0.02327731065452099,
            -0.0001268561463803053,
            -0.008942083455622196,
            0.029680605977773666,
            -0.05238790437579155,
            0.004384135827422142,
            0.018177559599280357,
            -0.016476329416036606,
            -0.00048292195424437523,
            0.029981667175889015,
            0.026894204318523407,
            0.006266510579735041,
            -0.007534915581345558,
            0.024527929723262787,
            0.018308669328689575,
            -0.022931022569537163,
            0.02442813850939274,
            0.01945515163242817,
            -0.005957222077995539,
            -0.010397708974778652,
            -0.021297425031661987,
            -0.03391334041953087,
            0.00041805030195973814,
            -0.01015259325504303,
            0.008188406005501747,
            -0.014245830476284027,
            0.011594047769904137,
            -0.02398480474948883,
            0.0005364567623473704,
            -0.01301169116050005,
            0.0029596183449029922,
            -0.003851603716611862,
            0.03731134533882141,
            0.0019161630189046264,
            0.014522875659167767,
            0.017195528373122215,
            -0.0023045968264341354,
            -0.005699139088392258,
            0.01739787682890892,
            0.030487654730677605,
            0.022853855043649673,
            -0.006210602819919586,
            0.02552507258951664,
            0.04826309159398079,
            0.023322610184550285,
            -0.04150470346212387,
            -0.029620865359902382,
            -0.011950661428272724,
            -0.002064748667180538,
            -0.009929964318871498,
            -0.013960886746644974,
            0.023398322984576225,
            -0.015382600948214531,
            -0.011240649968385696,
            -0.029667505994439125,
            -0.0008797307964414358,
            0.03423861041665077,
            -0.005414200015366077,
            -0.03260248526930809,
            -0.01409214735031128,
            -0.000974687107373029,
            -0.007504547480493784,
            0.006992747075855732,
            0.029930327087640762,
            -0.02534363605082035,
            -0.015081974677741528,
            0.023009030148386955,
            0.0015695670153945684,
            -0.028165070340037346,
            -0.0002721605997066945,
            0.022358093410730362,
            -0.012179776094853878,
            -0.0053551378659904,
            -0.028248006477952003,
            -0.012557701207697392,
            -0.01823771558701992,
            0.012086446397006512,
            -0.012450392358005047,
            0.020424041897058487,
            0.028741858899593353,
            -0.016307417303323746,
            -0.00892413780093193,
            0.032070010900497437,
            -0.0021195109002292156,
            -0.014888442121446133,
            0.025581108406186104,
            0.017522742971777916,
            0.023193590342998505,
            -0.007798179052770138,
            -0.008372844196856022,
            0.019370123744010925,
            -0.005003579426556826,
            -0.02154332771897316,
            -0.012672298587858677,
            0.003998684696853161,
            -0.03222639113664627,
            0.02166164666414261,
            0.016654785722494125,
            -0.03429281339049339,
            -0.012969274073839188,
            -0.017441852018237114,
            0.010744137689471245,
            -0.022028351202607155,
            0.025483528152108192,
            -0.010552311316132545,
            -0.03982236236333847,
            0.017447354272007942,
            -0.011907650157809258,
            -0.012987417168915272,
            0.025077875703573227,
            -0.0217320304363966,
            -0.03661660850048065,
            -0.00025984871899709105,
            0.004222069401293993,
            0.06097107008099556,
            0.021353088319301605,
            0.056709691882133484,
            0.029685484245419502,
            0.01083100214600563,
            -0.025720160454511642,
            0.003998534753918648,
            -0.012706450186669827,
            -0.0031468274537473917,
            -0.03420994430780411,
            -0.003175692167133093,
            0.016970394179224968,
            0.024567026644945145,
            -0.002813716884702444,
            0.026972541585564613,
            -0.0008966242312453687,
            0.007223369553685188,
            0.0239324402064085,
            -0.013885365799069405,
            -0.0026740923058241606,
            -0.01743985526263714,
            0.00015532331599388272,
            -0.002562193665653467,
            0.000606623652856797,
            0.011166727170348167,
            0.0013460739282891154,
            0.016413994133472443,
            -0.020680135115981102,
            0.027607155963778496,
            0.028825880959630013,
            0.014833930879831314,
            -0.03619445860385895,
            -0.03382624685764313,
            -0.004440464545041323,
            -0.013129400089383125,
            0.008252457715570927,
            0.027355452999472618,
            -0.002032109536230564,
            0.016877008602023125,
            0.01460949331521988,
            0.01229117438197136,
            -0.0047200447879731655,
            -0.008103212341666222,
            -0.02318311482667923,
            -0.038092389702796936,
            -0.014275647699832916,
            0.01068998035043478,
            0.016956938430666924,
            0.027664845809340477,
            -0.02364163286983967,
            0.004930865950882435,
            0.02550135739147663,
            -0.026910271495580673,
            -0.048893895000219345,
            -0.004793973173946142,
            -0.03883296251296997,
            0.024433359503746033,
            0.021036725491285324,
            -0.007829762063920498,
            -0.011623386293649673,
            0.0032038434874266386,
            0.006883262190967798,
            -0.006554370280355215,
            -0.04340441897511482,
            0.017173245549201965,
            -0.003650121623650193,
            0.0023373598232865334,
            0.019023340195417404,
            0.03522917255759239,
            -0.012345955707132816,
            0.00990674551576376,
            0.010630148462951183,
            -0.022682825103402138,
            0.00980887096375227,
            -0.016582442447543144,
            0.02439289353787899,
            0.011872498318552971,
            0.008698053658008575,
            0.018647944554686546,
            0.005526025779545307,
            -0.0008662082254886627,
            0.008964910171926022,
            0.011438489891588688,
            0.04448994621634483,
            -0.025432921946048737,
            -0.0036050041671842337,
            -0.010383487679064274,
            0.03624079003930092,
            -0.01976631209254265,
            0.022765638306736946,
            0.011790485121309757,
            0.025590259581804276,
            -0.009439433924853802,
            0.006996072828769684,
            -0.02931221015751362,
            -0.02901536040008068,
            -0.013549481518566608,
            -0.010451667942106724,
            0.004065121989697218,
            0.01874700002372265,
            0.006186411250382662,
            0.01728961616754532,
            0.008330333046615124,
            -0.02118522673845291,
            0.030550003051757812,
            0.023179737851023674,
            0.0004116135824006051,
            -0.010989963077008724,
            -0.005606293212622404,
            -0.04772821441292763,
            0.015192081220448017,
            -0.00169661664403975,
            -0.013214089907705784,
            0.022883597761392593,
            0.01703617163002491,
            0.002152342814952135,
            0.0036807050928473473,
            -0.008038078434765339,
            -0.02117905020713806,
            0.036602530628442764,
            -0.022209126502275467,
            -0.010226788930594921,
            0.0003911654348485172,
            0.006420286372303963,
            0.01879379153251648,
            0.006285093724727631,
            -0.014643690548837185,
            -0.00859237089753151,
            0.03322437033057213,
            -0.025613170117139816,
            0.03384728357195854,
            -0.0121731236577034,
            0.001717210398055613,
            0.044752515852451324,
            -0.02163214422762394,
            0.0016710866475477815,
            0.01909063570201397,
            -0.03807166591286659,
            -0.025113096460700035,
            0.017570115625858307,
            -0.012166149914264679,
            -0.03139306604862213,
            -0.009778346866369247,
            -0.01672561839222908,
            0.006907805800437927,
            -0.020601075142621994,
            0.019500060006976128,
            -0.030827831476926804,
            -0.02017218805849552,
            0.014085632748901844,
            0.02773493155837059,
            0.029749736189842224,
            -0.018019508570432663,
            0.03223026916384697,
            -0.00494385464116931,
            0.0132347596809268,
            0.021527567878365517,
            -0.014927113428711891,
            -0.02945331111550331,
            0.018621105700731277,
            -0.02195633575320244,
            -0.055122826248407364,
            -0.026867832988500595,
            -0.026513056829571724,
            -0.034151263535022736,
            0.028667427599430084,
            0.001935013453476131,
            0.020794257521629333,
            -0.027063697576522827,
            -0.025277690961956978,
            0.0325670950114727,
            -0.008992282673716545,
            0.02846645936369896,
            -0.016805823892354965,
            -0.06105650216341019,
            0.011179224587976933,
            0.026230812072753906,
            0.01755477301776409,
            0.04078890383243561,
            -0.030547983944416046,
            0.004183292854577303,
            -0.009978363290429115,
            -0.016890129074454308,
            0.026169758290052414,
            -0.0060592591762542725,
            0.04074393957853317,
            -0.014748393557965755,
            -0.022753024473786354,
            0.0023826705291867256,
            -0.002493493026122451,
            0.011554745957255363,
            -0.028545096516609192,
            0.017267122864723206,
            0.021021034568548203,
            0.036556921899318695,
            -0.01695157214999199,
            -0.0243093091994524,
            0.007644033525139093,
            0.025361036881804466,
            0.025623243302106857,
            0.013704786077141762,
            -0.02378198318183422,
            -0.013748908415436745,
            -0.021643277257680893,
            0.02416953817009926,
            -0.020046480000019073,
            0.021767472848296165,
            0.01013909187167883,
            -0.020558957010507584,
            -0.029787516221404076,
            0.030727429315447807,
            -0.03022908605635166,
            0.016931524500250816,
            0.013415060006082058,
            -0.002362203551456332,
            0.016946882009506226,
            0.036756839603185654,
            -0.007499494589865208,
            -0.02202741615474224,
            9.451719233766198e-05,
            -0.007605505175888538,
            -0.014838785864412785,
            -0.05260729417204857,
            0.011017435230314732,
            -0.020396312698721886,
            -0.019178051501512527,
            -0.02849464863538742,
            -0.014821790158748627,
            0.013403543271124363,
            -0.021755080670118332,
            -0.023311592638492584,
            -0.0010449758265167475,
            -0.021387189626693726,
            0.006404463667422533,
            0.038724761456251144,
            0.016447020694613457,
            -0.02497810311615467,
            -0.0074752517975866795,
            -0.00016787082131486386,
            0.010733755305409431,
            -0.025817643851041794,
            -0.01606079936027527,
            -0.023509006947278976,
            0.039959218353033066,
            -0.010508162900805473,
            0.021623630076646805,
            -0.012001764960587025,
            0.012978918850421906,
            -0.00875817984342575,
            -0.014663460664451122,
            0.032251257449388504,
            -0.010745475068688393,
            -0.014238664880394936,
            -0.014634651131927967,
            0.007176421117037535,
            -0.009695143438875675,
            0.0323391854763031,
            -0.01301720179617405,
            -0.0028259751852601767,
            -0.02877791039645672,
            -0.024299928918480873,
            -0.02184813655912876,
            -0.013575275428593159,
            0.0126174446195364,
            -0.047033440321683884,
            -0.05308244749903679,
            0.01367027685046196,
            0.01912275142967701,
            -0.005975860636681318,
            0.02454180270433426,
            -0.008397809229791164,
            0.015663042664527893,
            0.019461942836642265,
            0.024060508236289024,
            -0.029822463169693947,
            0.022166984155774117,
            0.002207477344200015,
            -0.04013625904917717,
            0.04456153139472008,
            -0.010646706447005272,
            -0.035315390676259995,
            -0.006124203093349934,
            -0.03469182178378105,
            -0.014283970929682255,
            -0.016865652054548264,
            -0.0025840220041573048,
            -0.03127790614962578,
            0.022133851423859596,
            0.03548619896173477,
            -0.04541613161563873,
            0.025908218696713448,
            -0.02091149240732193,
            0.021130181849002838,
            0.0018041795119643211,
            -0.033628370612859726,
            0.022759398445487022,
            -0.010316982865333557,
            0.011988735757768154,
            0.04003049433231354,
            0.005531418137252331,
            -0.027601689100265503,
            -0.0005325173842720687,
            0.03068522922694683,
            0.01731548272073269,
            0.027962205931544304,
            0.02855590730905533,
            0.043957196176052094,
            0.02398877590894699,
            0.028691139072179794,
            0.023687517270445824,
            0.011086481623351574,
            0.019707519561052322,
            -0.008887547068297863,
            -0.004875261802226305,
            0.006279879715293646,
            0.0019103474915027618,
            -0.01271915901452303,
            -0.02596871368587017,
            0.02820974960923195,
            -0.005251860246062279,
            -0.008558936417102814,
            -0.03849851340055466,
            -0.026266183704137802,
            -0.011048045940697193,
            -0.017294252291321754,
            -0.016206802800297737,
            0.017870357260107994,
            -0.02098536305129528,
            0.0018249929416924715,
            -0.003034129273146391,
            0.035550232976675034,
            0.015074159018695354,
            -0.01934455707669258,
            0.009705083444714546,
            0.002139090793207288,
            -0.03301654011011124,
            0.04486667737364769,
            0.016124850139021873,
            -0.0025678658857941628,
            -0.024704044684767723,
            -0.027330972254276276,
            -0.004041879903525114,
            -0.042848020792007446,
            -0.01177478488534689,
            -0.011294655501842499,
            0.007824381813406944,
            -0.014358793385326862,
            0.010202471166849136,
            0.016210904344916344,
            -0.012416520155966282,
            -0.007567544002085924,
            -0.00463209068402648,
            0.018066829070448875,
            -0.009646125137805939,
            -0.018149912357330322,
            0.0015790645265951753,
            0.02341582253575325,
            -0.03611817583441734,
            0.007929494604468346,
            -0.004814848303794861,
            -0.0017455457709729671,
            0.011057611554861069,
            0.029074592515826225,
            0.02699831686913967,
            0.02770274132490158,
            0.01817806251347065,
            0.04622093588113785,
            -0.009834859520196915,
            0.039018239825963974,
            -0.00032249680953100324,
            0.0025645557325333357,
            0.0439479723572731,
            -0.0037613536696881056,
            0.03747757524251938,
            -0.029902473092079163,
            0.0011869928566738963,
            0.030319925397634506,
            0.009775004349648952,
            0.025751082226634026,
            -0.0029475062619894743,
            -0.014392958953976631,
            -0.05004031956195831,
            0.0092528210952878,
            0.03021092899143696,
            0.0353725366294384,
            0.007211013231426477,
            -0.031904686242341995,
            0.001953667961061001,
            -0.03054952435195446,
            0.018858307972550392,
            0.0021119017619639635,
            -0.0049757626838982105,
            0.004650544375181198,
            -0.014368929900228977,
            -0.005726126488298178,
            -0.04013539105653763,
            0.0003745337307918817,
            -0.02624424733221531,
            -0.019657187163829803,
            -0.023835862055420876,
            0.02021065168082714,
            -0.010820981115102768,
            -0.032115306705236435,
            -0.008943614549934864,
            -0.009228957816958427,
            0.04091200232505798,
            -0.012508084997534752,
            -0.01877223327755928,
            0.03232633322477341,
            -0.031483013182878494,
            0.006913954392075539,
            0.00806331541389227,
            -0.004258046858012676,
            -0.0027987039647996426,
            0.019985469058156013,
            -0.007619043346494436,
            -0.028483165428042412,
            -0.022658778354525566,
            -0.022466013208031654,
            -0.0070743742398917675,
            0.010607501491904259,
            -0.019638199359178543,
            -0.0053920503705739975,
            0.03031967207789421,
            -0.0039666625671088696,
            0.013913110829889774,
            -0.013798743486404419,
            -0.025294171646237373,
            -0.02477431297302246,
            0.00498402863740921,
            -0.01930408552289009,
            -0.06552904844284058,
            0.027666518464684486,
            -0.021974870935082436,
            0.0038755463901907206,
            0.02583388425409794,
            0.01779221184551716,
            -0.013927443884313107,
            0.028840752318501472,
            -0.018466142937541008,
            -0.019462035968899727,
            -0.016292737796902657,
            -0.02437606081366539,
            0.029168138280510902,
            0.0027646247763186693,
            0.04938039928674698,
            0.02062860317528248,
            0.02648105099797249,
            0.01461774855852127,
            0.0024064292665570974,
            0.028760278597474098,
            -0.0001683698792476207,
            -0.030612189322710037,
            0.029073474928736687,
            -0.012883930467069149,
            0.03749017417430878,
            0.002370739821344614,
            -0.045717544853687286,
            0.009197520092129707,
            0.01722509041428566,
            -0.026594171300530434,
            -0.028220241889357567,
            0.02424943447113037,
            -0.012627217918634415,
            -0.026814932003617287,
            0.01369499322026968,
            -0.021017275750637054,
            -0.0071802628226578236,
            -0.0217523705214262,
            -0.022805823013186455,
            0.0032481469679623842,
            0.004169418476521969,
            -0.01562095619738102,
            -0.0004821358306799084,
            -0.021690690889954567,
            0.018000802025198936,
            0.043396104127168655,
            -0.023775668814778328,
            0.01143275760114193,
            -0.036287736147642136,
            -0.035672981292009354,
            -0.06553338468074799,
            -0.006134985014796257,
            -0.027309279888868332,
            -0.02439047209918499,
            -0.0244690403342247,
            -0.07354050874710083,
            0.010367441922426224,
            0.036055680364370346,
            0.022014733403921127,
            0.0072491224855184555,
            0.024023791775107384,
            -0.0037486101500689983,
            -0.00992520246654749,
            -0.050505299121141434,
            -0.01576412282884121,
            0.03885441645979881,
            0.01519587729126215,
            0.017892491072416306,
            0.009365645237267017,
            -0.05988220125436783,
            -0.017106885090470314,
            -0.0013675922527909279,
            0.023038752377033234,
            -0.021491268649697304,
            -0.02261263318359852,
            0.014542194083333015,
            -0.0025336681865155697,
            0.0029964360874146223,
            0.03200222924351692,
            -0.00823198538273573,
            -0.0258072167634964,
            0.020039338618516922,
            0.0023006743285804987,
            -0.04728633910417557,
            0.020538128912448883,
            0.0012908641947433352,
            0.0012337302323430777,
            0.023593032732605934,
            -0.012148864567279816,
            -0.03582563251256943,
            0.03579804301261902,
            0.021731412038207054,
            0.03486233204603195,
            -0.016513098031282425,
            -0.05372636765241623,
            0.01663283072412014,
            -0.03878381848335266,
            -0.03092479333281517,
            0.020427724346518517,
            -0.012715941295027733,
            0.011002643965184689,
            -0.03370366618037224,
            -0.016156265512108803,
            -0.021174143999814987,
            -0.0038224707823246717,
            0.03216957300901413,
            0.01393364742398262,
            0.03018380142748356,
            0.02094450779259205,
            -0.015756357461214066,
            -0.018875882029533386
        ],
        [
            0.02466185949742794,
            0.01781424880027771,
            0.022760285064578056,
            -0.004984708037227392,
            -0.010261839255690575,
            -0.02938622608780861,
            0.02360897697508335,
            0.02478586696088314,
            -0.019181467592716217,
            0.030370041728019714,
            -0.0018629367696121335,
            0.010323208756744862,
            0.02822534367442131,
            0.02229483425617218,
            0.003623862052336335,
            0.00941377505660057,
            -0.0316024087369442,
            -0.010073959827423096,
            0.022910133004188538,
            0.02387106791138649,
            -0.026592131704092026,
            -0.026025429368019104,
            -0.0008761027711443603,
            -0.018736163154244423,
            0.012242160737514496,
            -0.012742827646434307,
            0.017537953332066536,
            -0.007648433558642864,
            0.01379434671252966,
            -0.02374614030122757,
            -0.0005345505196601152,
            0.010430346243083477,
            -0.002534821629524231,
            -0.0026706289499998093,
            0.016503656283020973,
            0.05920158699154854,
            0.026498334482312202,
            -0.011116690933704376,
            0.006571687757968903,
            0.001945982687175274,
            -0.012704258784651756,
            -0.003852810012176633,
            0.008335637860000134,
            0.024143872782588005,
            0.017474839463829994,
            0.04072147235274315,
            -0.02180667221546173,
            -4.585217175190337e-05,
            -0.014679308980703354,
            -0.01926264725625515,
            0.0275468360632658,
            -0.018983174115419388,
            0.033558040857315063,
            -0.011780180968344212,
            -0.025018082931637764,
            -0.009768837131559849,
            -0.001491730334237218,
            0.03672417253255844,
            0.006192178465425968,
            0.024261493235826492,
            0.01828085631132126,
            -0.023894811049103737,
            0.03732125833630562,
            -0.003805444808676839,
            0.006344262510538101,
            0.0025110729038715363,
            0.014788074418902397,
            0.009665466845035553,
            -0.023287517949938774,
            0.021924182772636414,
            0.01759864017367363,
            -0.02033870667219162,
            0.008201295509934425,
            0.027307111769914627,
            0.032011762261390686,
            0.01560024544596672,
            0.006867320742458105,
            0.022142356261610985,
            0.009759566746652126,
            0.042120713740587234,
            0.021523714065551758,
            -0.02359609492123127,
            0.032636016607284546,
            0.017574207857251167,
            0.006913218647241592,
            0.03507422283291817,
            -0.009062757715582848,
            0.030200965702533722,
            0.015958240255713463,
            0.007977264933288097,
            0.007345243822783232,
            0.024805942550301552,
            0.0011832847958430648,
            0.033690765500068665,
            -0.011926135048270226,
            0.017513835802674294,
            0.016600025817751884,
            0.0013654131907969713,
            0.0013210780452936888,
            0.016610177233815193,
            -0.00035333988489583135,
            0.03120725229382515,
            -0.009827127680182457,
            0.0013341184239834547,
            0.0021424763835966587,
            0.030534988269209862,
            0.026654159650206566,
            -0.025618979707360268,
            0.039982374757528305,
            -0.016369802877306938,
            -0.02871265448629856,
            0.008151720277965069,
            0.005075902212411165,
            0.02308155596256256,
            -0.03471699357032776,
            -0.0024333405308425426,
            -0.03060096688568592,
            -0.00603996217250824,
            -0.0051599168218672276,
            0.0020580843556672335,
            0.004655859898775816,
            -0.0033628467936068773,
            0.006571647245436907,
            -0.010458064265549183,
            -0.02278047800064087,
            0.004880370572209358,
            -0.021659398451447487,
            0.013369151391088963,
            0.019799208268523216,
            0.026085611432790756,
            0.01181927789002657,
            -0.015622086822986603,
            -0.034566111862659454,
            -0.00821048766374588,
            0.026061663404107094,
            0.020679987967014313,
            0.008482321165502071,
            -0.026674849912524223,
            0.01389921922236681,
            -0.03227598965167999,
            0.007088807411491871,
            -0.011270123533904552,
            0.00812460295855999,
            -0.007099556270986795,
            0.012462676502764225,
            0.027843117713928223,
            0.012308944016695023,
            -0.02141507901251316,
            -0.009901169687509537,
            -0.024969739839434624,
            -0.016560539603233337,
            -0.023030510172247887,
            -0.031663063913583755,
            -0.03327193856239319,
            0.016885755583643913,
            -0.03224441036581993,
            -0.019374417141079903,
            -0.022184209898114204,
            0.05427968129515648,
            0.016951560974121094,
            -0.020389771088957787,
            0.017016582190990448,
            0.02538229525089264,
            0.008294654078781605,
            0.016545897349715233,
            0.006657152436673641,
            0.016560876742005348,
            -0.005452525336295366,
            0.01808304525911808,
            -0.005025004968047142,
            -0.004384330008178949,
            0.006095384247601032,
            0.041444648057222366,
            -0.007125383708626032,
            -0.013218625448644161,
            -0.014663400128483772,
            -0.01743297465145588,
            -0.004032147116959095,
            -0.026988249272108078,
            0.004522948991507292,
            -0.0163549967110157,
            0.03236966207623482,
            0.01269496325403452,
            -0.02140131965279579,
            0.02095542661845684,
            0.005074630491435528,
            0.00889773853123188,
            0.03184114024043083,
            0.010815340094268322,
            -8.671981777297333e-05,
            0.03602688014507294,
            -0.015058699063956738,
            -0.017557747662067413,
            -0.01718946546316147,
            0.0033652973361313343,
            -0.01945752091705799,
            -0.008971829898655415,
            -0.012555019930005074,
            -0.01972598023712635,
            -0.00254259561188519,
            0.0017846045084297657,
            -0.014700573869049549,
            -0.02827434614300728,
            -0.008462448604404926,
            -0.008877440355718136,
            -0.008921186439692974,
            0.015397262759506702,
            0.01799033209681511,
            -0.008674053475260735,
            0.01887921802699566,
            0.009864799678325653,
            0.013913402333855629,
            -0.009298966266214848,
            -0.012040944769978523,
            0.0160423181951046,
            0.03598380833864212,
            0.04175940901041031,
            -0.015654543414711952,
            -0.002897334285080433,
            0.015155993402004242,
            0.02778511680662632,
            0.011619809083640575,
            0.030608445405960083,
            0.020012257620692253,
            0.015443796291947365,
            0.039827048778533936,
            -0.0018373931525275111,
            -0.006863979157060385,
            0.02370634861290455,
            -0.023841412737965584,
            0.003994273021817207,
            0.01878015138208866,
            -0.03012533113360405,
            0.03272433578968048,
            -0.02179328352212906,
            -0.01948326826095581,
            -0.018273573368787766,
            0.0003972943814005703,
            0.032953228801488876,
            -0.00670156255364418,
            -0.02826726622879505,
            0.025941956788301468,
            -0.002974901581183076,
            0.03332775831222534,
            0.029620258137583733,
            0.0010894719744101167,
            -0.03776712715625763,
            -0.015645869076251984,
            -0.023998882621526718,
            -0.010056641884148121,
            0.02304670214653015,
            -0.025113385170698166,
            0.009429835714399815,
            -0.012531358748674393,
            -0.021745506674051285,
            0.03195171803236008,
            -0.017166849225759506,
            -0.000702529854606837,
            0.008124982938170433,
            0.010690083727240562,
            -0.016819870099425316,
            0.018852774053812027,
            0.02870076708495617,
            -0.0051558050327003,
            0.03501475602388382,
            -0.004738328512758017,
            0.019071530550718307,
            0.020129496231675148,
            0.01594695635139942,
            0.014310434460639954,
            -0.002976824063807726,
            0.018888287246227264,
            -0.023689132183790207,
            0.01848803274333477,
            -0.0019685649313032627,
            0.023053696379065514,
            0.02301638200879097,
            -0.007062613498419523,
            0.011703002266585827,
            -0.019601674750447273,
            0.035564787685871124,
            -0.004634296055883169,
            -0.0005271159461699426,
            0.0018299325602129102,
            -0.006596941500902176,
            -0.006678739096969366,
            0.02813565358519554,
            0.01746402680873871,
            -0.008771312423050404,
            -0.006426465697586536,
            -0.026974648237228394,
            0.015623555518686771,
            0.022229360416531563,
            0.008246712386608124,
            -0.02195555716753006,
            -0.0009726474527269602,
            0.018170960247516632,
            0.021083703264594078,
            -0.01775268465280533,
            -0.02252473123371601,
            0.02402290515601635,
            -0.007526943925768137,
            0.007764996029436588,
            0.027779527008533478,
            0.015505120158195496,
            0.01060960628092289,
            0.01829950325191021,
            -0.006327768787741661,
            0.02023000828921795,
            -0.011562848463654518,
            0.04294102266430855,
            -0.018386036157608032,
            0.024037718772888184,
            -0.03238383308053017,
            0.023121800273656845,
            0.005639030132442713,
            -0.005021051038056612,
            0.03454461693763733,
            0.01228402741253376,
            -0.002969890832901001,
            -0.004973252769559622,
            0.01339150220155716,
            0.009895754046738148,
            0.01691313646733761,
            -0.008391816169023514,
            0.001300572301261127,
            -0.0016713924705982208,
            0.028495997190475464,
            -0.0014736098237335682,
            -0.014791762456297874,
            0.008307956159114838,
            0.0017406526021659374,
            0.005631138104945421,
            0.026311833411455154,
            0.0038896503392606974,
            -0.005795482080429792,
            0.03308252990245819,
            -0.01652013137936592,
            -0.025393281131982803,
            -0.013444468379020691,
            0.010909815318882465,
            -0.02719850279390812,
            0.030040791258215904,
            0.0006372882635332644,
            0.02333250641822815,
            0.014280878007411957,
            -0.032784607261419296,
            0.0016714887460693717,
            0.0057634408585727215,
            -0.008807351812720299,
            -0.03168848901987076,
            -0.03299390524625778,
            0.004732127301394939,
            -0.009977956302464008,
            -0.0029442948289215565,
            0.018224958330392838,
            -0.009747246280312538,
            -0.040496595203876495,
            0.013940485194325447,
            -0.02156532183289528,
            -0.029550818726420403,
            -0.0076942224986851215,
            -0.020865876227617264,
            0.04055357351899147,
            0.02385205216705799,
            0.005183147266507149,
            -0.014763730578124523,
            -0.001234420808032155,
            -0.027739837765693665,
            0.0343262180685997,
            0.024921387434005737,
            -0.016491126269102097,
            0.013504493981599808,
            -0.015196781605482101,
            0.005025498103350401,
            -0.03371211886405945,
            0.013809015974402428,
            0.006105627398937941,
            0.008141491562128067,
            -0.02990688756108284,
            -0.011904735118150711,
            -0.028082313016057014,
            0.01948961615562439,
            -0.00963269080966711,
            0.01677597127854824,
            0.0026534113567322493,
            -0.006316003855317831,
            0.036271851509809494,
            0.0005048764869570732,
            -0.0071764676831662655,
            -0.019424406811594963,
            -0.0035248491913080215,
            0.02383469045162201,
            0.025983067229390144,
            -0.03806797415018082,
            0.013847664929926395,
            0.03171176090836525,
            0.022013219073414803,
            -0.000790311605669558,
            -0.019145382568240166,
            -0.013790319673717022,
            -0.01794782653450966,
            -0.008313843049108982,
            -0.023301465436816216,
            0.011847754009068012,
            -0.023480737581849098,
            -0.02106630615890026,
            0.029385244473814964,
            -0.005695910658687353,
            0.02568480558693409,
            -0.027736928313970566,
            0.003759226994588971,
            0.022485079243779182,
            -0.007948452606797218,
            -0.03092537447810173,
            0.008836483582854271,
            -0.021564215421676636,
            0.029072051867842674,
            -0.0053339023143053055,
            0.02281208708882332,
            0.01861400716006756,
            0.00023881593369878829,
            -0.01927114836871624,
            0.03050689399242401,
            0.04279331490397453,
            0.018082331866025925,
            0.012326729483902454,
            0.031112056225538254,
            -0.017493242397904396,
            0.03378598392009735,
            -0.02205667644739151,
            -0.01633036695420742,
            -0.008194789290428162,
            -0.009652642533183098,
            0.01109317597001791,
            0.0017913474002853036,
            0.013923630118370056,
            0.019183166325092316,
            0.030336590483784676,
            0.0052439970895648,
            -0.0076814983040094376,
            -0.010508942417800426,
            -0.011309336870908737,
            0.01936541497707367,
            0.015526996925473213,
            -0.005467355716973543,
            -0.028910797089338303,
            0.058931246399879456,
            -0.008865018375217915,
            -0.010091567412018776,
            0.03409262374043465,
            0.010463741607964039,
            0.02112659625709057,
            0.027000734582543373,
            -0.009346147999167442,
            -0.012743948958814144,
            -0.008539929986000061,
            -0.02852381207048893,
            0.013405511155724525,
            0.03498891741037369,
            -0.0280521959066391,
            0.030404359102249146,
            -0.015912726521492004,
            0.0371115617454052,
            -0.008901610970497131,
            0.019996393471956253,
            0.006494143512099981,
            -0.005148251075297594,
            0.02407880686223507,
            -0.02855420671403408,
            -0.027877995744347572,
            -0.022288430482149124,
            -0.006785936187952757,
            -0.00024692629813216627,
            -0.019910329952836037,
            0.0397273413836956,
            0.025492731481790543,
            0.020411575213074684,
            -0.0032643405720591545,
            0.017249558120965958,
            0.016688140109181404,
            -0.002825361443683505,
            0.028074268251657486,
            -0.0048101176507771015,
            -0.001920095644891262,
            0.028451047837734222,
            -0.038642290979623795,
            -0.013914708979427814,
            0.006375964265316725,
            -0.001130608026869595,
            0.03155996650457382,
            -0.029353458434343338,
            -0.016575252637267113,
            -0.024086926132440567,
            -0.013956627808511257,
            0.026465503498911858,
            0.014805462211370468,
            0.0006793413194827735,
            -0.015463263727724552,
            -0.01241158414632082,
            0.01094192173331976,
            0.03120267018675804,
            -0.021340055391192436,
            -0.006199067458510399,
            -0.017025051638484,
            -0.014683895744383335,
            -0.005120742600411177,
            0.01943659968674183,
            -0.022880591452121735,
            -0.017608460038900375,
            0.02963916026055813,
            0.024322152137756348,
            -0.0005899849347770214,
            0.01927441731095314,
            -0.03006572462618351,
            -0.00767330639064312,
            0.007361780386418104,
            0.014839581213891506,
            0.016874706372618675,
            0.020927511155605316,
            0.004913723096251488,
            0.006651603151112795,
            0.010014832019805908,
            -0.024665389209985733,
            0.0016502091893926263,
            0.013293310068547726,
            -0.024092044681310654,
            -0.020631898194551468,
            -0.009834859520196915,
            0.002873720834031701,
            -0.014809297397732735,
            0.019224509596824646,
            0.012457088567316532,
            0.008753634057939053,
            -0.005751958582550287,
            0.041200459003448486,
            0.006373666226863861,
            -0.01400135736912489,
            0.010701765306293964,
            0.010081883519887924,
            -0.032351765781641006,
            0.0017386527033522725,
            -0.03397303447127342,
            0.002727519255131483,
            0.00019458786118775606,
            -0.03460777550935745,
            -0.030694104731082916,
            0.028251081705093384,
            0.009020121768116951,
            0.01712178811430931,
            0.031186087056994438,
            0.00017419990035705268,
            0.025325283408164978,
            0.023982835933566093,
            0.027373524382710457,
            -0.0025262166745960712,
            0.011827423237264156,
            0.009204860776662827,
            0.020894436165690422,
            0.030302682891488075,
            0.011661133728921413,
            -0.01954510062932968,
            0.02244463562965393,
            0.008167038671672344,
            -0.015474983491003513,
            0.023068083450198174,
            -0.020030401647090912,
            -0.003692925674840808,
            -0.00844466034322977,
            -0.0071606822311878204,
            -0.011672173626720905,
            -0.021815823391079903,
            -0.019569814205169678,
            0.010796235874295235,
            0.0010309021454304457,
            0.015332206152379513,
            0.007517235353589058,
            0.01016797125339508,
            0.009377739392220974,
            -0.023707453161478043,
            0.0214046873152256,
            -0.029117852449417114,
            0.0068880608305335045,
            -0.001532680238597095,
            0.022031085565686226,
            0.013091783039271832,
            0.010140977799892426,
            0.0301828533411026,
            -0.010365945287048817,
            0.04314104840159416,
            0.007209268864244223,
            0.03408512845635414,
            0.012851886451244354,
            -0.03347306698560715,
            -0.0061409203335642815,
            -0.027986101806163788,
            0.03025377169251442,
            0.03541009873151779,
            -0.0034504528157413006,
            -0.009760751388967037,
            0.01103950198739767,
            -0.0005995391984470189,
            0.006368196103721857,
            -0.00017165957251563668,
            -0.005063090939074755,
            -0.006954881362617016,
            0.008355711586773396,
            -0.023297136649489403,
            -0.01807686686515808,
            0.036083709448575974,
            0.00284225563518703,
            -0.01575608365237713,
            0.006019379943609238,
            -0.028334161266684532,
            0.01303399819880724,
            0.02217421680688858,
            -0.007935715839266777,
            -0.028241999447345734,
            0.031067701056599617,
            -0.018431290984153748,
            -0.02179725468158722,
            0.028584331274032593,
            -0.026537958532571793,
            -0.03295447304844856,
            0.013218158856034279,
            0.01937674917280674,
            -0.02488452009856701,
            0.019076773896813393,
            0.019425194710493088,
            -0.011047132313251495,
            0.007511423900723457,
            -0.008014669641852379,
            -0.016274992376565933,
            0.000893307151272893,
            0.004500699695199728,
            -0.013125111348927021,
            -0.030225392431020737,
            0.03548586368560791,
            0.010716822929680347,
            0.030940186232328415,
            0.02253952994942665,
            0.016080154106020927,
            0.03201145678758621,
            -0.009277028031647205,
            0.024714360013604164,
            -0.01475139893591404,
            0.03868190571665764,
            -0.015664145350456238,
            0.013995824381709099,
            0.024427752941846848,
            -0.014724988490343094,
            0.015045723877847195,
            -0.024570347741246223,
            0.01699763722717762,
            0.02738036774098873,
            0.027083246037364006,
            0.018387602642178535,
            -0.0028557099867612123,
            -0.01575513184070587,
            0.03669444099068642,
            0.005541992373764515,
            0.018732529133558273,
            -0.001736090169288218,
            0.014645409770309925,
            -0.0013221977278590202,
            -0.013520091772079468,
            0.01886160857975483,
            0.032531142234802246,
            0.023316627368330956,
            0.0269140787422657,
            -0.01729438826441765,
            0.02053937502205372,
            -0.020946942269802094,
            0.004685369320213795,
            0.00209547346457839,
            0.025258202105760574,
            -0.02023478038609028,
            -0.0008051656768657267,
            0.02679469808936119,
            -0.026986878365278244,
            -0.004828630480915308,
            0.020304139703512192,
            0.030594032257795334,
            -0.01076804380863905,
            -0.026481321081519127,
            0.004210371989756823,
            -0.02728438563644886,
            -0.01310713216662407,
            0.010160916484892368,
            0.044965364038944244,
            -0.013242627494037151,
            -0.03324403613805771,
            0.011680527590215206,
            0.0030256968457251787,
            0.0003902449388988316,
            0.030307229608297348,
            -0.0025395795237272978,
            0.03478109836578369,
            -0.027356984093785286,
            0.0059180292300879955,
            -0.02947833016514778,
            0.029545705765485764,
            0.02872166596353054,
            0.030677974224090576,
            0.011108026839792728,
            -0.0065576802007853985,
            0.0027441014535725117,
            -0.02786739356815815,
            -0.015722593292593956,
            -0.0007834677235223353,
            0.014103108085691929,
            0.008923950605094433,
            -0.029956236481666565,
            -0.01914455182850361,
            0.0045408266596496105,
            -0.0035909805446863174,
            0.06580840796232224,
            -0.03223918005824089,
            -0.03182022646069527,
            0.014903038740158081,
            0.023950429633259773,
            -0.01653595082461834,
            0.01980067975819111,
            -0.04195452108979225,
            -0.024669939652085304,
            -0.010431841015815735,
            0.006334106437861919,
            -0.021839994937181473,
            0.009229661896824837,
            0.024037275463342667,
            -0.033397044986486435,
            0.011195626109838486,
            0.03529541566967964,
            0.011534515768289566,
            0.03491934388875961,
            -0.006138097960501909,
            -0.006668951828032732,
            -0.004180981311947107,
            -0.037537798285484314,
            -0.016659734770655632,
            -0.008005104027688503,
            0.010732200928032398,
            0.021622994914650917,
            0.015902794897556305,
            -0.016021011397242546,
            -0.007267975248396397,
            -0.011583936400711536,
            0.01968342810869217,
            -0.005376631394028664,
            -0.002404336119070649,
            -0.011643040925264359,
            -0.02495315857231617,
            -0.01879415102303028,
            -0.02433282509446144,
            0.01923246495425701,
            -0.004341599997133017,
            -0.015496978536248207,
            -0.06687803566455841,
            0.028932087123394012,
            -0.016896510496735573,
            0.014879952184855938,
            0.03689326345920563,
            0.03270760551095009,
            0.01800999976694584,
            -0.0016537136398255825,
            -0.013750673271715641,
            0.02295868843793869,
            0.017634136602282524,
            -0.0120690343901515,
            0.019110849127173424,
            0.017627663910388947,
            0.008678613230586052,
            -0.006947740912437439,
            0.01825842820107937,
            -0.023013105615973473,
            0.044838614761829376,
            0.011170059442520142,
            0.008653420023620129,
            0.00531941931694746,
            0.019895566627383232,
            0.039319247007369995,
            0.02472112514078617,
            -0.03194423392415047,
            -0.02671283297240734,
            0.007297935429960489,
            0.01281534880399704,
            -0.00516971480101347,
            -0.0014428631402552128,
            -0.01814306527376175,
            -0.027723437175154686,
            -0.03183349594473839,
            -0.01209699735045433,
            -0.020766694098711014,
            0.03175476938486099,
            -0.015854736790060997,
            0.023342443630099297,
            0.005135017447173595,
            0.010265270248055458,
            0.010026293806731701,
            0.0183574128895998,
            0.0017773950239643455,
            -0.003129903692752123,
            -0.02656674198806286,
            -0.02611447498202324,
            0.009405074641108513,
            0.020061926916241646,
            -0.027705645188689232,
            -0.0012274261098355055,
            0.018462473526597023,
            0.024180661886930466,
            0.007810699753463268,
            -0.012378615327179432,
            -0.022492151707410812,
            -0.0050428383983671665,
            0.035167597234249115,
            0.015989776700735092,
            -0.023442599922418594,
            0.029804063960909843,
            -0.004660807549953461,
            0.023618454113602638,
            0.005974212195724249,
            -0.03217452019453049,
            0.003685625735670328,
            0.014521636068820953,
            0.04083250090479851,
            0.012052556499838829,
            0.019204912707209587,
            -0.022817688062787056,
            -0.006204517558217049,
            0.021314091980457306,
            0.0035644180607050657,
            0.03609585389494896,
            -0.04966481029987335,
            0.01825573481619358,
            0.02131950855255127,
            0.008649937808513641,
            0.009717639535665512,
            0.0083430465310812,
            -0.01310880109667778,
            -7.212620403151959e-05,
            -0.007009109482169151,
            -0.02117292582988739,
            -0.025130825117230415,
            0.011643529869616032,
            -0.015095897950232029,
            -0.02146960236132145,
            -0.0044875238090753555,
            -0.033988114446401596,
            0.003514993702992797,
            0.030415724962949753,
            0.0005711069097742438,
            -0.029725678265094757,
            0.01969270035624504,
            0.014010203070938587,
            0.022716986015439034,
            0.031860388815402985,
            0.02372586913406849,
            0.0029569563921540976,
            -0.004487896803766489,
            -0.02222411520779133,
            0.00010686900350265205,
            0.02175748161971569,
            0.0029935925267636776,
            -0.02964690700173378,
            0.0029628053307533264,
            -0.02436378039419651,
            0.03101111389696598,
            -0.019706865772604942,
            -0.02266802452504635,
            0.015373998321592808,
            -0.016692930832505226,
            0.022279096767306328,
            -0.05550524219870567,
            -0.001846988219767809,
            -0.01336535345762968,
            0.027028970420360565,
            -0.02349880523979664,
            -0.043544963002204895,
            -0.011315258219838142,
            0.011269563809037209,
            0.015705648809671402,
            0.009785163216292858,
            -0.004057138226926327,
            0.02860179916024208,
            0.003568811807781458,
            -0.025299834087491035,
            -0.012911250814795494,
            0.006690774578601122,
            -0.0047349170781672,
            0.028869740664958954,
            0.010370881296694279,
            -0.024144664406776428,
            -0.017694149166345596,
            0.009874737821519375,
            0.012381207197904587,
            0.019684379920363426,
            0.028344744816422462,
            -0.02300676330924034,
            0.02284863032400608,
            0.028155585750937462,
            -0.03800706937909126,
            -0.006100575439631939,
            -0.011329635046422482,
            -0.02675224095582962,
            0.027132809162139893,
            -0.02893214486539364,
            -0.027496209368109703,
            0.02444053813815117,
            0.022476162761449814,
            -0.006348039023578167,
            0.031131627038121223,
            -0.03972870856523514,
            0.015389821492135525,
            0.006986918393522501,
            0.01957867480814457,
            0.011090804822742939,
            -0.005767442286014557,
            -0.007187211886048317,
            0.019941724836826324,
            -0.009487050585448742,
            0.017896728590130806,
            0.001037844456732273,
            -0.023035572841763496,
            -0.037424515932798386,
            -0.00937205646187067,
            -0.008537957444787025,
            0.03720485791563988,
            -0.0018865002784878016,
            0.00405104411765933,
            -0.027022166177630424,
            0.019961444661021233,
            -0.017845673486590385,
            -0.021160051226615906,
            0.041141949594020844,
            -0.01736336760222912,
            0.017185797914862633,
            -0.007005146238952875,
            -0.02197226695716381,
            -0.028281252831220627,
            -0.02550746686756611,
            0.01981184259057045,
            -0.010009699501097202,
            0.010495995171368122,
            -0.02008405327796936,
            0.01296963356435299,
            -0.02487175166606903,
            -0.03227704018354416,
            0.014141586609184742,
            -0.027002692222595215,
            0.02951809950172901,
            -0.056728050112724304,
            0.016974415630102158,
            0.0249341931194067,
            0.03214053437113762,
            0.0006363627035170794,
            0.0021870355121791363,
            -0.0013790972298011184,
            -0.014465251006186008,
            -0.03998839482665062,
            0.01870318315923214,
            -0.008269079960882664,
            -0.016099713742733,
            -0.014599299989640713,
            0.024615371599793434,
            -0.014290842227637768,
            -0.03381095081567764,
            -0.003508667927235365,
            -0.023115502670407295,
            0.023979226127266884,
            0.02259608544409275,
            0.013978034257888794,
            0.018651040270924568,
            -0.011193991638720036,
            0.006558271590620279,
            -0.01037530042231083,
            0.00313391350209713,
            -0.01744217984378338,
            0.02848796360194683,
            -0.0019536511972546577,
            -0.02078305557370186,
            0.01787111908197403,
            0.01379392296075821,
            0.0066270059905946255,
            0.009515943005681038,
            -0.039281927049160004,
            0.03827730938792229,
            -0.007962213829159737,
            0.029301220551133156,
            0.02111264504492283,
            0.013410408981144428,
            0.0005581182194873691,
            -0.014407090842723846,
            0.008905339054763317,
            0.01718715764582157,
            0.025670811533927917,
            0.0394144281744957,
            0.00546990754082799,
            0.02467108890414238,
            0.012812628410756588,
            -0.02932223305106163,
            0.02318747714161873,
            0.014478654600679874,
            -0.02905176393687725,
            0.029480857774615288,
            0.018723495304584503,
            0.012408300302922726,
            0.0028535134624689817,
            -0.02014956809580326,
            0.0006848308257758617,
            0.020297151058912277,
            -0.009333252906799316,
            0.020424479618668556,
            -0.015554330311715603,
            -0.0217803455889225,
            0.01033235713839531,
            -0.009920353069901466,
            -0.012691209092736244,
            0.018576765432953835,
            -0.007324717473238707,
            0.015430934727191925,
            -0.008534887805581093,
            -0.014080805703997612,
            0.00014808097330387682,
            0.015575025230646133
        ],
        [
            -0.007436042185872793,
            0.018863404169678688,
            -0.00040962890489026904,
            0.031163040548563004,
            0.015487134456634521,
            0.05240732431411743,
            -0.039453569799661636,
            -0.003556396346539259,
            0.02895396202802658,
            0.02452823519706726,
            -0.006967164110392332,
            -0.03162572160363197,
            0.031141705811023712,
            -0.01599436067044735,
            -0.040784552693367004,
            6.077588068365003e-07,
            -0.02427714318037033,
            -0.03174417465925217,
            -0.008364749141037464,
            -0.01912780851125717,
            0.03410781919956207,
            -0.012773997150361538,
            -0.01613183692097664,
            0.008436846546828747,
            -0.033203259110450745,
            -0.01279139332473278,
            -0.017750991508364677,
            -0.006774003617465496,
            0.02855055034160614,
            0.017659440636634827,
            0.00951141957193613,
            0.014048279263079166,
            -0.00488751195371151,
            0.028871161863207817,
            0.014134818688035011,
            -0.03543829545378685,
            0.028947707265615463,
            0.023555073887109756,
            -0.01121183205395937,
            -0.02447785623371601,
            0.0147297577932477,
            -0.019397452473640442,
            0.007312080357223749,
            0.022215066477656364,
            -0.022489942610263824,
            0.0029411858413368464,
            -0.012357744388282299,
            0.026489343494176865,
            -0.025204123929142952,
            -0.007505911402404308,
            0.02360393851995468,
            -0.01780960150063038,
            6.980298348935321e-05,
            -0.01384078711271286,
            0.013563285581767559,
            -0.004863124806433916,
            -0.002381430007517338,
            0.010894493199884892,
            -0.01270801480859518,
            0.00335027021355927,
            0.021854981780052185,
            0.028974344953894615,
            0.022209670394659042,
            -0.02122141793370247,
            0.024969706311821938,
            -0.0276788379997015,
            0.03174770995974541,
            0.01914937235414982,
            0.006175699178129435,
            0.007367498707026243,
            -0.024065418168902397,
            -0.010817618109285831,
            -0.028402525931596756,
            -0.016901923343539238,
            0.0334077924489975,
            -0.019925106316804886,
            0.007634658832103014,
            -0.04432668536901474,
            -0.017008239403367043,
            0.028977787122130394,
            -0.004360010381788015,
            0.029554005712270737,
            0.00365890609100461,
            0.010286164470016956,
            -0.03380599990487099,
            0.014216749928891659,
            -0.016122309491038322,
            0.010768022388219833,
            0.028934145346283913,
            0.03175107389688492,
            0.01432675588876009,
            -0.0010607779258862138,
            -0.02117045596241951,
            0.04308297112584114,
            -0.0361139141023159,
            0.005029718857258558,
            -0.02985094115138054,
            -0.023730088025331497,
            0.06359665095806122,
            -0.0331464484333992,
            -0.019783658906817436,
            0.0038684371393173933,
            0.03387182205915451,
            0.032093703746795654,
            -0.022211965173482895,
            0.023325348272919655,
            0.02335858903825283,
            0.02281329035758972,
            -0.012016836553812027,
            -0.022606370970606804,
            0.024608952924609184,
            0.011575939133763313,
            0.0040992614813148975,
            0.018972761929035187,
            0.05661405995488167,
            0.018587050959467888,
            0.0160039234906435,
            0.007961420342326164,
            -0.022476378828287125,
            0.020976409316062927,
            0.008520698174834251,
            -0.01654829829931259,
            -0.046985916793346405,
            -0.03958139196038246,
            0.00947012472897768,
            -0.010044010356068611,
            -0.002006486291065812,
            -0.0024900923017412424,
            -0.022315222769975662,
            0.01440048310905695,
            -0.005326519720256329,
            0.027190888300538063,
            -0.021859977394342422,
            0.011009027250111103,
            0.01714472286403179,
            0.0018937424756586552,
            0.03932630270719528,
            0.03038843721151352,
            0.009515096433460712,
            0.015338124707341194,
            -0.005782112944871187,
            0.010883769020438194,
            -0.024879414588212967,
            -0.013375754468142986,
            0.01861792989075184,
            0.017839794978499413,
            -0.014545835554599762,
            0.020389508455991745,
            -0.008144696243107319,
            0.010006150230765343,
            -0.005516284145414829,
            -0.07105153799057007,
            0.02087264135479927,
            0.022949915379285812,
            0.019830621778964996,
            0.01984090730547905,
            0.010561950504779816,
            0.012833824381232262,
            0.02324153669178486,
            0.01250455528497696,
            -0.0016075934981927276,
            0.01753273978829384,
            0.008065101690590382,
            -0.00207807426340878,
            -0.012902146205306053,
            0.0017803150694817305,
            -0.0531434565782547,
            -0.011040078476071358,
            -0.013550368137657642,
            -0.017326515167951584,
            -0.027516426518559456,
            -0.029735038056969643,
            -0.012775166891515255,
            -0.013097408227622509,
            0.0005966007593087852,
            0.028302716091275215,
            0.019049959257245064,
            -0.008285469375550747,
            0.026543376967310905,
            0.011200670152902603,
            -0.0005465757567435503,
            -0.010534413158893585,
            0.03963558003306389,
            -0.02087343856692314,
            0.018548037856817245,
            0.012930678203701973,
            -0.015252107754349709,
            -0.015999510884284973,
            -0.02846330776810646,
            -0.01127488911151886,
            -0.006666251923888922,
            0.009404256008565426,
            -0.01933123730123043,
            0.011575856246054173,
            -0.003537390846759081,
            -6.267925346037373e-05,
            -0.021662473678588867,
            0.025451114401221275,
            -0.020597271621227264,
            -0.01825696974992752,
            0.005841640755534172,
            0.006058892235159874,
            -0.03080752119421959,
            0.032204125076532364,
            0.016904465854167938,
            0.01453416608273983,
            0.032141149044036865,
            -0.007892362773418427,
            -0.04906649887561798,
            0.0010796469869092107,
            -0.012343230657279491,
            -0.045336976647377014,
            0.026029912754893303,
            0.004405998159199953,
            -0.024918172508478165,
            -0.010153735056519508,
            0.0010873100254684687,
            -0.011040549725294113,
            -0.02822158858180046,
            -0.033717356622219086,
            0.02061905898153782,
            -0.04396393895149231,
            -0.022159364074468613,
            0.008621571585536003,
            -0.021974312141537666,
            -0.03543856367468834,
            0.032660529017448425,
            0.02019185945391655,
            -0.01346223521977663,
            0.020067362114787102,
            0.013274820521473885,
            0.012834812514483929,
            -0.004821778275072575,
            -0.014270557090640068,
            0.030439503490924835,
            0.012193799018859863,
            -0.010258397087454796,
            0.01395026221871376,
            -0.02471480704843998,
            0.0024775604251772165,
            0.03021438606083393,
            -0.02718786895275116,
            -0.03063959628343582,
            -0.034571073949337006,
            -0.04301082342863083,
            0.017475998029112816,
            -0.009651768021285534,
            0.004702125210314989,
            0.009474237449467182,
            0.03542498126626015,
            0.02671983651816845,
            0.015725722536444664,
            0.0028708670288324356,
            0.018788862973451614,
            -0.01003990601748228,
            0.011047241277992725,
            0.00482360040768981,
            0.006451931782066822,
            0.016089648008346558,
            -0.004684313666075468,
            -0.009049240499734879,
            0.02256130613386631,
            -0.037676237523555756,
            -0.013969212770462036,
            -0.00426767161116004,
            0.0041411747224628925,
            -0.0027219064068049192,
            -0.025248652324080467,
            0.035994574427604675,
            0.04387049004435539,
            0.025848641991615295,
            -0.006931188516318798,
            0.014102902263402939,
            0.026504481211304665,
            -0.04049595445394516,
            0.00016296893591061234,
            -0.013981830328702927,
            -0.0032315461430698633,
            -0.023194488137960434,
            -0.003200093051418662,
            -0.008415652438998222,
            0.019623776897788048,
            -0.00447426550090313,
            0.040543392300605774,
            0.023117180913686752,
            0.0241166353225708,
            0.02637336403131485,
            -0.007530154660344124,
            -0.019299158826470375,
            -0.031550705432891846,
            -0.02709859609603882,
            -0.0006031376542523503,
            0.03292103484272957,
            -0.032240431755781174,
            -0.007645922247320414,
            0.0012001078575849533,
            -0.008598490618169308,
            -0.018818801268935204,
            0.018643014132976532,
            0.010792048647999763,
            0.01182329561561346,
            0.006554541178047657,
            -0.019897881895303726,
            0.0015859206905588508,
            -0.016743194311857224,
            -0.021009473130106926,
            0.01140292827039957,
            -0.057836711406707764,
            -0.0015345659339800477,
            0.018524685874581337,
            -0.011932482942938805,
            0.02711787447333336,
            0.02368098497390747,
            -0.016073720529675484,
            0.024036338552832603,
            -0.014149353839457035,
            -0.02105681784451008,
            0.03409910202026367,
            0.025045614689588547,
            0.028119297698140144,
            0.012115390039980412,
            0.0348617359995842,
            0.01694883406162262,
            -0.03586827963590622,
            -0.021384678781032562,
            0.006407858803868294,
            0.047471970319747925,
            -0.03196894749999046,
            0.02742009423673153,
            -0.003906396683305502,
            -0.011492122896015644,
            -0.026607831940054893,
            0.012133256532251835,
            -0.02317952550947666,
            -0.01923423260450363,
            0.0017139873234555125,
            -0.04024522751569748,
            -0.01268815714865923,
            -0.03141936659812927,
            0.022136256098747253,
            -0.03270352631807327,
            -0.017196955159306526,
            0.02274182066321373,
            -0.022835886105895042,
            0.01236682664602995,
            0.003769936040043831,
            -0.0032213563099503517,
            0.010097776539623737,
            0.0010254625231027603,
            -0.025699792429804802,
            0.0025209218729287386,
            -0.004831145517528057,
            -0.004096824210137129,
            -0.034938350319862366,
            -0.028769580647349358,
            0.0023960755206644535,
            0.0053564077243208885,
            0.019624993205070496,
            -0.02583358995616436,
            -0.013739353977143764,
            -0.012260169722139835,
            -0.02341669239103794,
            -0.0307217575609684,
            -0.0018950642552226782,
            0.01202542707324028,
            -0.005952722858637571,
            0.021130327135324478,
            0.022674305364489555,
            0.03134166821837425,
            -0.012723316438496113,
            -0.011805311776697636,
            0.020529864355921745,
            0.027918923646211624,
            -0.004266965668648481,
            -0.03258206695318222,
            -0.02844247967004776,
            0.015104958787560463,
            0.007410392165184021,
            -0.0006811586790718138,
            -0.011600201949477196,
            0.012124136090278625,
            -0.008439410477876663,
            -0.02453305758535862,
            -0.03161204978823662,
            0.024176878854632378,
            -0.003845914965495467,
            -0.0011259636376053095,
            -0.006195386406034231,
            -0.03727147355675697,
            0.037551913410425186,
            0.029728388413786888,
            0.03493737056851387,
            0.022894032299518585,
            0.015094648115336895,
            0.03341593220829964,
            0.008144482970237732,
            0.028634658083319664,
            0.010761523619294167,
            -0.013090240769088268,
            0.0124205956235528,
            0.024590156972408295,
            -0.007645876612514257,
            -0.03195978328585625,
            -0.0241289921104908,
            -0.00795383658260107,
            0.014564130455255508,
            0.02529454044997692,
            -0.03690987080335617,
            0.02681625820696354,
            0.0035620895214378834,
            -0.004279164131730795,
            0.013207390904426575,
            -0.010768786072731018,
            -0.025681233033537865,
            0.024334242567420006,
            0.021996604278683662,
            0.02847917191684246,
            0.03636626526713371,
            0.008246307261288166,
            0.02829280123114586,
            0.01934104412794113,
            -0.01969718188047409,
            0.03828202933073044,
            -0.009509655646979809,
            0.0080033540725708,
            -0.015642577782273293,
            0.0157394427806139,
            0.0027687374968081713,
            -0.02021370641887188,
            0.018228819593787193,
            0.02575990930199623,
            -0.029426680877804756,
            -0.018756097182631493,
            0.017214953899383545,
            0.011974085122346878,
            -0.021471699699759483,
            -0.0017359035555273294,
            -0.018812134861946106,
            0.001383703900501132,
            0.022874752059578896,
            0.043374642729759216,
            -0.04830397292971611,
            -0.005933987442404032,
            0.012526421807706356,
            0.022979088127613068,
            0.0076152971014380455,
            0.024682076647877693,
            -0.057733453810214996,
            0.0338541641831398,
            0.003247742773965001,
            -0.03326756879687309,
            -0.021835818886756897,
            -0.003472384065389633,
            -0.01005297340452671,
            -0.00945286639034748,
            0.011372456327080727,
            -0.0019279781263321638,
            0.017240554094314575,
            -0.022375941276550293,
            0.021693792194128036,
            -0.013729369267821312,
            -0.005569805391132832,
            -0.0051252879202365875,
            -0.03257254138588905,
            0.007555431220680475,
            0.009664409793913364,
            0.007822834886610508,
            0.009443782269954681,
            0.011781072244048119,
            -0.01702471449971199,
            -0.018297821283340454,
            -0.024348260834813118,
            0.016276245936751366,
            0.03112509846687317,
            0.0033952449448406696,
            0.015276790596544743,
            -0.008700037375092506,
            -0.01702449470758438,
            0.026342932134866714,
            0.010459410957992077,
            -0.02436257153749466,
            0.017311692237854004,
            0.016307039186358452,
            -0.03134414926171303,
            -0.009890800341963768,
            0.04230735823512077,
            0.02625344507396221,
            0.003060320159420371,
            -0.03966127336025238,
            0.0006125149084255099,
            0.02236911654472351,
            -0.004190226551145315,
            -0.03366036340594292,
            0.014873210340738297,
            -0.027471795678138733,
            -0.02972482703626156,
            0.04501260817050934,
            0.02213127724826336,
            -0.00866510160267353,
            0.002957924036309123,
            0.017579644918441772,
            -0.05032653734087944,
            -0.015865111723542213,
            0.017086979001760483,
            -0.023337526246905327,
            0.005232702009379864,
            -0.02362695522606373,
            -0.021147917956113815,
            -0.001188233494758606,
            0.008785690180957317,
            0.015523268841207027,
            -0.026171734556555748,
            0.02002864144742489,
            -0.011392263695597649,
            0.032503850758075714,
            0.03010685369372368,
            0.02596425637602806,
            -0.020393352955579758,
            0.02123154327273369,
            -0.024600498378276825,
            0.014165026135742664,
            0.01635904237627983,
            -0.02898593246936798,
            -0.01981409266591072,
            0.034741152077913284,
            -0.015900246798992157,
            0.023531105369329453,
            -0.016333945095539093,
            -0.024872776120901108,
            0.017031753435730934,
            -0.0020172588992863894,
            -0.011355276219546795,
            -0.009187383577227592,
            -0.006669897120445967,
            -0.005195995327085257,
            -0.022773247212171555,
            0.005792515352368355,
            0.01972564123570919,
            -0.011149642057716846,
            0.004539004527032375,
            -0.01342631783336401,
            -0.001243128441274166,
            -0.024789566174149513,
            -0.0068282559514045715,
            0.010706487111747265,
            0.02467893622815609,
            0.012751452624797821,
            -0.004084930755198002,
            -0.03402159735560417,
            0.03870256990194321,
            0.0026351879350841045,
            0.019380465149879456,
            0.0335470587015152,
            0.0012602388160303235,
            0.000112498426460661,
            0.011554509401321411,
            0.028948510065674782,
            -0.017348838970065117,
            -0.02105317823588848,
            0.01824033446609974,
            -0.001233780523762107,
            -0.008435147814452648,
            -0.015151753090322018,
            -0.011071683838963509,
            0.025058161467313766,
            -0.004620081279426813,
            0.0036446116864681244,
            -0.017554065212607384,
            -0.00578338885679841,
            0.017145592719316483,
            -0.02802467718720436,
            0.027735795825719833,
            -0.010737921111285686,
            0.011623051948845387,
            0.014845858328044415,
            -0.01028056163340807,
            0.012343283742666245,
            -0.020105402916669846,
            -0.002333751181140542,
            -0.03219126909971237,
            0.01954611949622631,
            0.02255728282034397,
            -0.000164385128300637,
            0.04250068962574005,
            0.01397791225463152,
            -0.03005518764257431,
            -0.01517847366631031,
            0.028294678777456284,
            -0.018682852387428284,
            -0.032914381474256516,
            -0.017928333953022957,
            0.03992225229740143,
            -0.03241484984755516,
            0.0016610530437901616,
            -0.019382145255804062,
            -0.00041696184780448675,
            0.012367908842861652,
            -0.008999903686344624,
            0.004338807892054319,
            0.037633590400218964,
            0.023567071184515953,
            -0.014886780641973019,
            -0.016967421397566795,
            -0.016523029655218124,
            0.007105371914803982,
            0.005676423665136099,
            0.010639313608407974,
            0.029969166964292526,
            0.0028966465033590794,
            0.04974188655614853,
            0.006118373945355415,
            0.017234021797776222,
            5.48809657630045e-05,
            -0.028257470577955246,
            -0.03192165493965149,
            -0.005692256614565849,
            0.009002018719911575,
            -0.001567429630085826,
            0.0008888646261766553,
            0.058411307632923126,
            -0.012793503701686859,
            -0.0004964485415257514,
            -0.01663825660943985,
            0.0023302161134779453,
            -0.026161622256040573,
            0.016404082998633385,
            0.018872950226068497,
            -0.008061692118644714,
            -0.007094867993146181,
            -0.008662010543048382,
            -0.010666804388165474,
            0.021072961390018463,
            -0.027314845472574234,
            0.012766329571604729,
            0.05610843375325203,
            -0.007093813735991716,
            -0.006128245033323765,
            0.01617591083049774,
            -0.01978849060833454,
            -0.017878105863928795,
            -0.020025819540023804,
            -0.0005313093424774706,
            -0.010672860778868198,
            0.029173148795962334,
            0.0042355540208518505,
            -0.03966069594025612,
            -0.0015577750746160746,
            0.0020525685977190733,
            -0.012232768349349499,
            0.008362077176570892,
            -0.02404608018696308,
            0.036194730550050735,
            -0.02479366585612297,
            0.02121066488325596,
            0.012203261256217957,
            -0.01699874736368656,
            -0.03029133751988411,
            0.004255867097526789,
            -0.030838092789053917,
            0.0010740405414253473,
            0.031503740698099136,
            0.040893830358982086,
            -0.025224030017852783,
            -0.006173806264996529,
            0.03136260434985161,
            0.019757814705371857,
            -0.02569854073226452,
            0.02910665050148964,
            0.028776943683624268,
            0.0036212815903127193,
            -0.0013603318948298693,
            -0.039777010679244995,
            -0.02261117286980152,
            -0.002020989079028368,
            0.03314749523997307,
            0.0012321264948695898,
            -0.010673630982637405,
            -0.03031831979751587,
            0.007453723344951868,
            0.0012434793170541525,
            0.034770358353853226,
            0.0009506191127002239,
            0.01850128546357155,
            0.01474266592413187,
            0.028959639370441437,
            0.029069513082504272,
            0.018010087311267853,
            0.022714657709002495,
            0.010469120927155018,
            0.008305167779326439,
            0.025754733011126518,
            0.016151336953043938,
            -0.03544420748949051,
            0.008619454689323902,
            -0.022655988112092018,
            0.006456620991230011,
            -0.001647920929826796,
            -0.008704863488674164,
            -0.01889544166624546,
            -0.036726582795381546,
            0.0004476219473872334,
            -0.01780669391155243,
            0.011820496059954166,
            0.02076343446969986,
            -0.02149614505469799,
            0.03745396062731743,
            -0.01493627019226551,
            0.018595868721604347,
            0.02293303608894348,
            -0.020950552076101303,
            -0.0070886933244764805,
            0.002368927001953125,
            -0.002501077950000763,
            -0.028204461559653282,
            0.008725236169993877,
            0.039627157151699066,
            -0.0032881933730095625,
            0.03789300471544266,
            -0.014617056585848331,
            0.01236638892441988,
            0.03616030886769295,
            0.02438514493405819,
            0.010056646540760994,
            -0.011760403402149677,
            -0.0062097362242639065,
            -0.03413347899913788,
            0.035163093358278275,
            -0.004797979258000851,
            0.0012735784985125065,
            -0.016623694449663162,
            -0.009959086775779724,
            0.0017653905088081956,
            -0.012481450103223324,
            0.02844042330980301,
            -0.006695946678519249,
            0.0034417458809912205,
            0.006316679529845715,
            -0.03015618957579136,
            -0.007160861976444721,
            -0.018548671156167984,
            -0.027968620881438255,
            -0.0008927698363550007,
            0.023889727890491486,
            -0.0072659701108932495,
            0.013794749043881893,
            -0.009632224217057228,
            -0.0005402868846431375,
            -0.008548932150006294,
            0.0005000673118047416,
            0.010766876861453056,
            -0.0018448398914188147,
            -0.006653818767517805,
            -0.006447111256420612,
            -0.012660830281674862,
            0.0136571591719985,
            -0.026524659246206284,
            -0.01615767739713192,
            0.012796242721378803,
            -0.0288565531373024,
            -0.02688901498913765,
            -0.03156760334968567,
            -0.04140803962945938,
            -0.022882889956235886,
            -0.011109704151749611,
            0.02533656358718872,
            0.006667982321232557,
            -0.02359403669834137,
            -0.01113876886665821,
            0.005139186512678862,
            0.020885279402136803,
            -0.027512917295098305,
            0.02854926884174347,
            -0.002094391966238618,
            -0.044250208884477615,
            -0.018682166934013367,
            -0.05074216425418854,
            -0.05131801217794418,
            0.05265677720308304,
            0.013263971544802189,
            0.035176608711481094,
            0.005675426684319973,
            -0.030222151428461075,
            0.017565272748470306,
            0.013398327864706516,
            -0.03557690978050232,
            -0.013258763588964939,
            0.018912052735686302,
            -0.01187728438526392,
            -0.01829243265092373,
            0.024027368053793907,
            -0.02484774775803089,
            0.028493713587522507,
            -0.05527408421039581,
            0.011152188293635845,
            0.02844989113509655,
            0.017714180052280426,
            0.0018319764640182257,
            0.03292417153716087,
            0.011224886402487755,
            0.005912694148719311,
            0.0020110770128667355,
            -0.012486782856285572,
            -0.005879088770598173,
            0.0038235781248658895,
            -0.023901253938674927,
            -0.016222169622778893,
            0.003994874190539122,
            0.01996026746928692,
            0.011899583041667938,
            -0.03376740962266922,
            0.087242491543293,
            0.03488287329673767,
            -0.009099737741053104,
            0.016078036278486252,
            0.006880900822579861,
            -0.00216079642996192,
            -0.0012178802862763405,
            -0.010074031539261341,
            -0.018298892304301262,
            0.0068031069822609425,
            -0.00034030244569294155,
            0.020601630210876465,
            -0.0035650008358061314,
            -0.02323891781270504,
            0.0014382994268089533,
            0.00873563066124916,
            0.008218725211918354,
            0.0006694406038150191,
            0.02426750771701336,
            -0.02599450945854187,
            -0.0037293885834515095,
            0.02411748468875885,
            -0.028063444420695305,
            0.006245905067771673,
            -0.008138461969792843,
            0.018499599769711494,
            -0.006601888220757246,
            0.027578003704547882,
            -0.030899250879883766,
            -0.011866125278174877,
            -0.004582976922392845,
            -0.05115894973278046,
            0.008044006302952766,
            -0.006643111817538738,
            0.007315791677683592,
            -0.00358653930015862,
            0.01908463053405285,
            -0.04501698911190033,
            0.009701523929834366,
            0.032818082720041275,
            -0.022431712597608566,
            0.006322176661342382,
            0.005653319414705038,
            0.01708381623029709,
            -0.026394376531243324,
            0.016815152019262314,
            0.0013660418335348368,
            0.023235982283949852,
            0.04148480296134949,
            0.008738464675843716,
            0.03516389802098274,
            0.01821194961667061,
            -0.05366046354174614,
            -0.013475813902914524,
            -0.005789879709482193,
            0.025269765406847,
            -0.021107640117406845,
            -0.020545801147818565,
            -0.020693307742476463,
            -0.01486907061189413,
            0.040660738945007324,
            -0.025196749716997147,
            0.02589493617415428,
            -0.023461714386940002,
            -0.0033741274382919073,
            0.002306360984221101,
            -0.012414188124239445,
            0.07916931062936783,
            -0.01673463173210621,
            -0.015280170366168022,
            0.0048676771111786366,
            0.013923613354563713,
            -0.005191299598664045,
            -0.01656191796064377,
            -0.013721118681132793,
            -0.02505617029964924,
            0.019572220742702484,
            0.06556516885757446,
            -0.025853894650936127,
            0.005163596011698246,
            0.04256834089756012,
            0.03410613164305687,
            -0.007828938774764538,
            -0.011254868470132351,
            0.029816221445798874,
            0.015543043613433838,
            -0.006298972759395838,
            0.007203931454569101,
            -0.0013405465288087726,
            -0.0353725403547287,
            0.024611856788396835,
            -0.007742897141724825,
            -0.03122212365269661,
            0.0075462390668690205,
            -0.04333896562457085,
            -0.006035779602825642,
            0.01049481425434351,
            0.01609828881919384,
            -0.015144778415560722,
            -0.010345565155148506,
            0.000807981239631772,
            -0.012930179014801979,
            0.0194504726678133,
            -0.04301613196730614,
            -0.05835709348320961,
            0.016554081812500954,
            0.04414626210927963,
            0.021456576883792877,
            -0.03211451321840286,
            0.01596834510564804,
            0.017453758046030998,
            -0.03742367401719093,
            -0.010919548571109772,
            0.021582264453172684,
            -0.003235040232539177,
            0.028678786009550095,
            0.007864601910114288,
            0.01575261540710926,
            0.01908981427550316,
            -0.002368729095906019,
            0.0007414837018586695,
            -0.004122346173971891,
            -0.025926195085048676,
            -0.023761911317706108,
            0.034462183713912964,
            0.008277501910924911,
            -0.016189444810152054,
            -0.021840892732143402,
            -0.028796397149562836,
            -0.03852548077702522,
            -0.005252794828265905,
            0.030291402712464333,
            -0.0321810320019722,
            0.010911726392805576,
            -0.01221211813390255,
            -0.004970469977706671,
            -0.018305471166968346,
            0.0043267738074064255,
            0.006131334695965052,
            -0.03334192931652069,
            0.008315814658999443,
            0.009659281000494957,
            -0.026919716969132423,
            0.018243616446852684,
            0.027999846264719963,
            0.0066843414679169655,
            0.006108432076871395,
            0.03469694405794144,
            0.054108817130327225,
            0.012741065584123135,
            0.011479190550744534,
            -0.01110269594937563,
            0.05426150932908058,
            0.03734646737575531,
            -0.018203232437372208,
            0.024206014350056648,
            -0.017713740468025208,
            0.007345525547862053,
            0.004979485180228949,
            -0.002960021374747157,
            0.007181763183325529,
            0.01805686391890049,
            -0.016305886209011078,
            0.029268478974699974,
            -0.002371336566284299,
            0.00987542886286974,
            -0.04351130127906799,
            -0.0061512840911746025,
            -0.01576206646859646,
            -0.006400012411177158,
            -0.013209154829382896,
            0.049807388335466385,
            0.01934509538114071,
            -0.02887837588787079,
            -0.002871549455448985,
            0.026582786813378334,
            0.022641612216830254,
            0.0028659470845013857,
            -0.01630064845085144,
            -0.016950612887740135,
            0.023176267743110657,
            -0.011662132106721401,
            0.02361605130136013,
            -0.027201944962143898,
            -0.022772179916501045,
            -0.021541396155953407,
            -0.01073756068944931,
            0.00931276474148035,
            -0.003584101563319564,
            -0.03238425776362419,
            0.019083712249994278,
            0.022723868489265442,
            0.02716154232621193,
            -0.019360272213816643,
            0.015980755910277367,
            -0.016176749020814896,
            0.016363386064767838,
            0.004861051682382822,
            -0.01346752978861332,
            -0.01892545074224472,
            0.01835690811276436,
            -0.02416803129017353,
            0.023889895528554916,
            -0.004605480469763279,
            -0.007846512831747532,
            -0.07098069787025452,
            -0.011777739971876144,
            0.01722651906311512,
            0.03840296342968941,
            -0.02695988304913044,
            0.013991350308060646,
            -0.024786313995718956,
            0.0609414242208004,
            0.004873955622315407
        ],
        [
            0.043028056621551514,
            -0.012529181316494942,
            -0.004352840129286051,
            -0.015954626724123955,
            0.016695978119969368,
            0.025438478216528893,
            -0.016730831936001778,
            0.018300989642739296,
            0.004554073791950941,
            0.021197039633989334,
            -0.010359028354287148,
            -0.02956286258995533,
            -0.01083664782345295,
            0.026552053168416023,
            0.0032016506884247065,
            0.040650174021720886,
            -0.01739223301410675,
            0.003470940515398979,
            -0.02213422581553459,
            0.003282728139311075,
            0.009947534650564194,
            0.00019702997815329581,
            -0.03101896122097969,
            0.0038005446549504995,
            0.0026470106095075607,
            -0.01808188296854496,
            -0.016454646363854408,
            0.013508222997188568,
            0.00468103913590312,
            0.005517228506505489,
            0.009454899467527866,
            -0.015082991681993008,
            0.03183787688612938,
            0.027261657640337944,
            0.02116973139345646,
            0.029356759041547775,
            -0.02886033058166504,
            -0.00752731179818511,
            0.03450959548354149,
            0.027894435450434685,
            -0.0342269204556942,
            -0.034158460795879364,
            -0.004175484646111727,
            0.0035779166501015425,
            -0.017863834276795387,
            -0.03331999480724335,
            0.01661151461303234,
            -0.01926729828119278,
            0.006146993022412062,
            0.031056411564350128,
            0.008099609985947609,
            0.022888824343681335,
            0.009579415433108807,
            -0.04483442008495331,
            0.020273635163903236,
            0.018370123580098152,
            -0.011395891197025776,
            0.006186537444591522,
            -0.0315556637942791,
            -0.0016712461365386844,
            -0.0381646454334259,
            0.015382749028503895,
            -0.03503318876028061,
            -0.013274899683892727,
            -0.017735380679368973,
            0.03243085741996765,
            0.030199598520994186,
            0.011749476194381714,
            -0.014468081295490265,
            -0.005198797211050987,
            0.009577813558280468,
            -0.0071178218349814415,
            0.0027821434196084738,
            -0.013197838328778744,
            -0.008166193962097168,
            -0.0144831333309412,
            -0.014995495788753033,
            -0.03323851153254509,
            -0.023909064009785652,
            0.011426601558923721,
            -0.0064025758765637875,
            0.0001779120648279786,
            -0.002601762767881155,
            0.03748827055096626,
            0.02548571117222309,
            -0.01656251773238182,
            -0.007767174858599901,
            0.0154408048838377,
            -0.022233372554183006,
            -0.031293630599975586,
            0.016304083168506622,
            0.022022873163223267,
            0.024305548518896103,
            -0.022162416949868202,
            -0.021566150709986687,
            -0.023864388465881348,
            0.022168787196278572,
            0.010638425126671791,
            -0.04870229214429855,
            -0.027521202340722084,
            0.008312077261507511,
            0.02892383188009262,
            -0.02205774188041687,
            0.006586273666471243,
            0.01272656675428152,
            -0.024228082969784737,
            -0.004373414907604456,
            0.018473856151103973,
            -0.03357658535242081,
            0.02887851931154728,
            -0.006602553650736809,
            0.008904355578124523,
            0.03047574684023857,
            -0.009605774655938148,
            0.026870056986808777,
            0.011147568002343178,
            0.030203858390450478,
            0.006536449771374464,
            -0.019502967596054077,
            -0.016717655584216118,
            -0.020310120657086372,
            -0.02555726282298565,
            -0.015163465403020382,
            -0.002955067902803421,
            0.014920057728886604,
            0.031230710446834564,
            -0.014867386780679226,
            0.006451870780438185,
            0.022362899035215378,
            0.006258873734623194,
            0.001211070572026074,
            0.038820717483758926,
            -0.03712034970521927,
            0.019054308533668518,
            0.016116458922624588,
            -0.027840185910463333,
            -0.04429738223552704,
            0.02924252301454544,
            -0.02150743268430233,
            0.005918057169765234,
            0.018118301406502724,
            -0.02730606496334076,
            0.03025805950164795,
            0.026241466403007507,
            -0.021396135911345482,
            0.028203602880239487,
            -0.02442024275660515,
            0.014259140938520432,
            0.013713073916733265,
            0.03698386251926422,
            -0.00049719208618626,
            0.056959059089422226,
            -0.030330242589116096,
            0.04267030209302902,
            0.007698518689721823,
            0.024368366226553917,
            0.010210331529378891,
            0.01431353110820055,
            -0.009898639284074306,
            -0.009197045117616653,
            0.033211272209882736,
            -0.0352737233042717,
            0.0007961402297951281,
            -0.02875388413667679,
            0.018763482570648193,
            0.008825041353702545,
            -0.0037684342823922634,
            -0.007964753545820713,
            -0.007760090287774801,
            0.009805832989513874,
            -0.0024087894707918167,
            -0.030707895755767822,
            -0.03285319730639458,
            0.024914003908634186,
            -0.005246561951935291,
            0.021138286218047142,
            0.030306454747915268,
            -0.010934551246464252,
            0.030665654689073563,
            -0.0018746915739029646,
            0.007711328100413084,
            -0.028139732778072357,
            0.023516278713941574,
            -0.008489392697811127,
            0.013512063771486282,
            0.004850730299949646,
            0.02639523521065712,
            0.004630179610103369,
            -0.011859862133860588,
            0.004498225636780262,
            -0.03160485252737999,
            0.009518097154796124,
            -0.03711710497736931,
            0.019843988120555878,
            -0.021230582147836685,
            0.0083415312692523,
            -0.01496256422251463,
            -0.0038941751699894667,
            0.0192020982503891,
            0.005402348469942808,
            0.03328412398695946,
            0.014034406282007694,
            0.01515083760023117,
            0.018098536878824234,
            -0.07056597620248795,
            -0.0033300670329481363,
            0.00035073913750238717,
            0.013046245090663433,
            0.003190701361745596,
            0.029736163094639778,
            0.005435759201645851,
            0.004635781515389681,
            0.009787625633180141,
            0.029766226187348366,
            -0.022915206849575043,
            0.03121759556233883,
            -0.005966870579868555,
            -0.02734232507646084,
            -0.01425817608833313,
            0.0380353182554245,
            0.004730558488518,
            -0.016789084300398827,
            -0.018883010372519493,
            0.03084554150700569,
            -0.022096669301390648,
            0.014678502455353737,
            0.03171653673052788,
            0.01598704792559147,
            -0.01028930488973856,
            -0.024691017344594002,
            0.022687815129756927,
            0.042816951870918274,
            -0.022405989468097687,
            -0.02469933219254017,
            0.029630152508616447,
            0.023150626569986343,
            0.04427236691117287,
            -0.0045029534958302975,
            -0.005615308880805969,
            0.02297716774046421,
            -0.0174120906740427,
            -0.03124273754656315,
            0.026557667180895805,
            0.02720605954527855,
            -0.019189877435564995,
            0.03149236738681793,
            -0.00299479509703815,
            0.025694971904158592,
            -0.029330745339393616,
            0.0338633731007576,
            -0.0030654622241854668,
            0.02962011657655239,
            -0.035920239984989166,
            0.011328883469104767,
            0.01162999588996172,
            0.0005242495681159198,
            0.0032923740800470114,
            0.00732410280033946,
            0.022502152249217033,
            0.046208616346120834,
            -0.025937914848327637,
            0.02638653852045536,
            -0.015437320806086063,
            -0.033065952360630035,
            -0.00883625540882349,
            0.044483888894319534,
            -0.014904273673892021,
            -0.016976822167634964,
            0.024145379662513733,
            -0.006766858976334333,
            0.022908160462975502,
            0.028732283040881157,
            -0.03258734941482544,
            0.0056372457183897495,
            -0.037601351737976074,
            -0.009845668449997902,
            0.005727824755012989,
            0.021522216498851776,
            -0.018457014113664627,
            -0.0002507760364096612,
            -0.005611830856651068,
            -0.019571363925933838,
            -0.0036362982355058193,
            0.05245596542954445,
            0.017832646146416664,
            -0.012639006599783897,
            -0.008423889055848122,
            0.06037799268960953,
            0.029615389183163643,
            -0.031355079263448715,
            -0.005906296893954277,
            -0.01489673275500536,
            0.009641110897064209,
            0.017131255939602852,
            -0.02105594426393509,
            -0.013879574835300446,
            0.044737808406353,
            0.032237011939287186,
            0.004127513151615858,
            0.016697905957698822,
            0.011823365464806557,
            -0.017998946830630302,
            0.006954084150493145,
            -0.006989371031522751,
            0.02848954312503338,
            0.02735430747270584,
            0.010433902032673359,
            0.019728977233171463,
            0.03994251415133476,
            -0.0006248828140087426,
            0.03868862986564636,
            0.024443021044135094,
            -0.00945599190890789,
            -0.012888752855360508,
            -0.007195314858108759,
            0.041669078171253204,
            -0.007617127150297165,
            0.012947667390108109,
            0.024594498798251152,
            0.022701695561408997,
            0.012525364756584167,
            0.00038632555515505373,
            -0.019877098500728607,
            0.04096466675400734,
            0.01204616017639637,
            0.016320448368787766,
            -0.006583068519830704,
            0.012182450853288174,
            -0.011479351669549942,
            0.025677483528852463,
            0.0074839238077402115,
            0.03313985466957092,
            -0.01895633339881897,
            -0.011643934063613415,
            0.0027050436474382877,
            0.006272354628890753,
            -0.021101681515574455,
            0.025638854131102562,
            -0.027295101433992386,
            0.02671121619641781,
            -0.018827028572559357,
            0.029674476012587547,
            -0.033883336931467056,
            0.011381921358406544,
            0.007513505406677723,
            0.011169246397912502,
            0.02548791654407978,
            -0.017957139760255814,
            -0.01308518461883068,
            -0.01949712075293064,
            -0.03755843639373779,
            0.010918639600276947,
            -0.016722526401281357,
            0.019830340519547462,
            -0.0027873506769537926,
            -0.0027475678361952305,
            -0.003326148260384798,
            -0.004376013297587633,
            0.010996194556355476,
            -0.040957141667604446,
            0.013712071813642979,
            0.023580575361847878,
            0.017004165798425674,
            0.013234470039606094,
            0.008530151098966599,
            0.037295836955308914,
            -0.0032783655915409327,
            -0.002872658660635352,
            -0.012091565877199173,
            0.0018328713485971093,
            -0.014749941416084766,
            0.002836025319993496,
            0.03865515813231468,
            0.029861662536859512,
            -0.020433790981769562,
            -0.01104646548628807,
            0.01684480719268322,
            0.041267279535532,
            -0.008066043257713318,
            -0.03627336025238037,
            0.017507150769233704,
            -0.03158872574567795,
            0.010926669463515282,
            -0.01591011881828308,
            0.0009523582994006574,
            -0.016071602702140808,
            0.005551809910684824,
            -0.027557745575904846,
            0.012331990525126457,
            0.028556732460856438,
            -0.034525446593761444,
            -0.04319971427321434,
            -0.011729063466191292,
            -0.014626193791627884,
            -0.02302561327815056,
            -0.014710352756083012,
            -0.0001797456934582442,
            -0.028568904846906662,
            0.027951393276453018,
            0.00994646642357111,
            0.007234304212033749,
            -0.02860158495604992,
            -0.0012581456685438752,
            0.00887203123420477,
            0.004939751233905554,
            0.02058960124850273,
            -0.020192518830299377,
            0.008561191149055958,
            0.004405269864946604,
            0.019882548600435257,
            0.0208783820271492,
            -0.009027209132909775,
            0.030440034344792366,
            -0.012426728382706642,
            -0.010835686698555946,
            -0.012551313266158104,
            0.013939977623522282,
            -0.006824283394962549,
            0.031201884150505066,
            -0.022244831547141075,
            0.0418231263756752,
            -0.014788721688091755,
            0.005414988379925489,
            0.010585074312984943,
            -0.018714293837547302,
            0.013645702041685581,
            0.017271583899855614,
            -0.024565832689404488,
            -0.021534858271479607,
            0.029679756611585617,
            -0.0011196944396942854,
            0.011294536292552948,
            -0.0004909352865070105,
            -0.006366555579006672,
            0.010094542987644672,
            -0.014882189221680164,
            -0.004965652246028185,
            -0.011108404956758022,
            -0.0035972432233393192,
            0.034084927290678024,
            -0.006373939104378223,
            0.034687187522649765,
            -0.009082702919840813,
            -0.0047501204535365105,
            -0.019893502816557884,
            0.008420782163739204,
            0.016164787113666534,
            -0.03758619725704193,
            -0.01819612830877304,
            -0.007776240818202496,
            -0.004040786996483803,
            -0.021045800298452377,
            0.025381037965416908,
            -0.003873886540532112,
            -0.028467411175370216,
            0.029556704685091972,
            -0.010621529072523117,
            -0.036870963871479034,
            0.00812335405498743,
            0.011084413155913353,
            0.025257578119635582,
            0.002999840769916773,
            0.003249293426051736,
            0.01825704611837864,
            0.022046741098165512,
            -0.020554151386022568,
            0.020901737734675407,
            -0.04732975363731384,
            0.0022540888749063015,
            0.024577563628554344,
            -0.003915514796972275,
            0.022486919537186623,
            0.01472514122724533,
            -0.018083583563566208,
            -0.0270957350730896,
            0.017744749784469604,
            0.024967873468995094,
            -0.004856014158576727,
            0.024668289348483086,
            0.02870948426425457,
            -0.01065770536661148,
            0.02122945711016655,
            0.01423840131610632,
            -0.006577197927981615,
            -0.0050133997574448586,
            -0.011353441514074802,
            -0.007777086459100246,
            0.01733827032148838,
            0.036879293620586395,
            -0.00015050344518385828,
            -0.02769807167351246,
            0.00899797398597002,
            0.00029377706232480705,
            0.024546900764107704,
            0.01147462148219347,
            0.0017671188106760383,
            0.028405796736478806,
            -0.023355957120656967,
            -0.014819991774857044,
            -0.00973379984498024,
            0.020592359825968742,
            -0.019343458116054535,
            -0.02239958941936493,
            -0.025593722239136696,
            0.024952445179224014,
            -0.00597554724663496,
            -0.004853563383221626,
            -0.019773250445723534,
            -0.0038437792100012302,
            0.027150729671120644,
            0.005097182467579842,
            0.03447230905294418,
            -0.033804621547460556,
            0.010579909197986126,
            -0.021332142874598503,
            0.02184133045375347,
            0.021240316331386566,
            0.018253982067108154,
            0.02448960207402706,
            -0.0305076465010643,
            -0.04220433905720711,
            -0.025567760691046715,
            0.0279189832508564,
            0.029010355472564697,
            -0.04223760589957237,
            -0.0062156617641448975,
            -0.061855390667915344,
            -0.007939625531435013,
            -0.019181281328201294,
            -0.020350471138954163,
            -0.013001514598727226,
            0.000592479482293129,
            -0.04117996618151665,
            0.019554199650883675,
            0.005277568474411964,
            -0.012169264256954193,
            0.007389932870864868,
            -0.029735662043094635,
            0.023412633687257767,
            -0.031171973794698715,
            -0.03164311498403549,
            0.0012058594729751348,
            0.02119343727827072,
            -0.004549379926174879,
            0.009253254160284996,
            -0.040646910667419434,
            -0.0033092026133090258,
            0.012413041666150093,
            0.011922363191843033,
            0.020622853189706802,
            -0.0303873922675848,
            0.007190352771431208,
            -0.017601879313588142,
            0.019472824409604073,
            -0.024532068520784378,
            -0.009892275556921959,
            -0.004846425727009773,
            -0.004044500179588795,
            0.037432942539453506,
            0.017863702028989792,
            0.022609218955039978,
            0.021044040098786354,
            -0.0021170941181480885,
            -0.008000903762876987,
            0.034863244742155075,
            -0.010602622292935848,
            0.021313464269042015,
            -0.0062816864810884,
            0.036099787801504135,
            0.019722027704119682,
            -0.017563743516802788,
            -0.0008229668601416051,
            0.016978444531559944,
            -0.030341869220137596,
            0.01908096671104431,
            0.004478581715375185,
            0.01285205502063036,
            -0.010991085320711136,
            -0.01271486934274435,
            -0.017909880727529526,
            0.021922782063484192,
            -0.0026071621105074883,
            0.03246470168232918,
            0.021719686686992645,
            -0.002176349051296711,
            0.0071557085029780865,
            -0.01642642728984356,
            -0.00337884109467268,
            0.005556904710829258,
            -0.0194921363145113,
            -0.02320319041609764,
            -0.005423087161034346,
            -0.0047146910801529884,
            0.045243460685014725,
            -0.014071974903345108,
            0.02235567197203636,
            0.0015837408136576414,
            -0.033002421259880066,
            0.005869965068995953,
            0.0013503528898581862,
            -0.014811383560299873,
            0.004792308434844017,
            -0.019912606105208397,
            0.02103368751704693,
            -0.002024312037974596,
            0.031456366181373596,
            0.0174410343170166,
            -0.02243119105696678,
            0.02293115109205246,
            -0.0022509347181767225,
            -0.014720264822244644,
            0.011811798438429832,
            -0.010487272404134274,
            0.020096519961953163,
            0.0058840131387114525,
            -0.028410708531737328,
            -0.01297233160585165,
            -0.026891697198152542,
            0.02089238539338112,
            -0.04286353662610054,
            -0.035079341381788254,
            -0.043683283030986786,
            0.019312385469675064,
            0.004048977978527546,
            0.01735248789191246,
            -0.015876449644565582,
            -0.022192129865288734,
            0.042249325662851334,
            0.010644512251019478,
            -0.041082337498664856,
            -0.007424246985465288,
            0.01490039937198162,
            0.006117262411862612,
            -0.0019755058456212282,
            0.01967075653374195,
            -0.018130900338292122,
            -0.030597923323512077,
            0.0012997430749237537,
            -0.024166297167539597,
            0.0077237654477357864,
            -0.030836280435323715,
            0.02273196540772915,
            0.0387527197599411,
            -0.04681898280978203,
            -0.025741254910826683,
            0.006874078884720802,
            0.011471213772892952,
            0.02442104183137417,
            -0.011202773079276085,
            -0.013577526435256004,
            0.02866065874695778,
            0.005562012083828449,
            -0.015494094230234623,
            0.019619083032011986,
            -0.006825194228440523,
            0.02353450283408165,
            0.02108188159763813,
            -0.0061863199807703495,
            -0.018548771739006042,
            -0.0018709097057580948,
            -0.025406602770090103,
            -0.007878056727349758,
            0.020618457347154617,
            0.010610181838274002,
            -0.0024042329750955105,
            0.02910691499710083,
            0.050704993307590485,
            0.027562303468585014,
            -0.005731427110731602,
            -0.019170142710208893,
            -0.03863250091671944,
            -0.015486284159123898,
            -0.033147335052490234,
            -0.06505923718214035,
            -0.024934230372309685,
            -0.0096001997590065,
            0.01833096705377102,
            0.02536870539188385,
            -0.020944291725754738,
            -0.01607312448322773,
            -0.013018863275647163,
            -0.01013374887406826,
            0.025041718035936356,
            -0.007031151093542576,
            -0.012785105966031551,
            0.018027780577540398,
            0.0012942641042172909,
            0.018071070313453674,
            0.01580950617790222,
            -0.012262320145964622,
            0.03270474448800087,
            -0.03646380081772804,
            0.023264551535248756,
            -0.000854233221616596,
            0.02913825213909149,
            0.03577793762087822,
            -0.015087146311998367,
            -0.011681394651532173,
            -0.024652181193232536,
            0.022211508825421333,
            0.03181047365069389,
            -0.013644085265696049,
            0.01398785226047039,
            0.012745285406708717,
            -0.01869359239935875,
            -0.059786077588796616,
            0.0037696061190217733,
            -0.03157857805490494,
            -0.006602962967008352,
            -0.003353775478899479,
            0.0036124982871115208,
            -0.00315495440736413,
            0.004147087223827839,
            -0.004670156631618738,
            0.02945059910416603,
            -0.02018885686993599,
            -0.06536362320184708,
            0.009261045604944229,
            -0.0010295017855241895,
            -0.011678732931613922,
            -0.03950798138976097,
            -0.019756605848670006,
            -0.019297679886221886,
            -0.001200880273245275,
            0.00975058600306511,
            0.0009601651690900326,
            -0.005618010647594929,
            -0.014042920432984829,
            0.021837275475263596,
            -0.034438036382198334,
            -0.03116779960691929,
            -0.004070138093084097,
            0.003123639849945903,
            -0.0047045922838151455,
            -0.0402320958673954,
            0.012928159907460213,
            -0.005983319133520126,
            -0.014468803070485592,
            -0.006322730798274279,
            0.01792561076581478,
            -0.0036436093505471945,
            -0.04241598770022392,
            0.013892613351345062,
            0.03048669546842575,
            0.025250665843486786,
            -0.021110715344548225,
            -0.05582460016012192,
            0.031235260888934135,
            -0.005402741022408009,
            -0.019857781007885933,
            -0.012962003238499165,
            0.016462834551930428,
            -0.02620757929980755,
            -0.018801052123308182,
            0.0026179752312600613,
            -0.01797916740179062,
            0.04146495461463928,
            -0.02981492504477501,
            0.001612609950825572,
            0.02354392781853676,
            -0.004564850591123104,
            -0.047235239297151566,
            0.030112702399492264,
            0.022879980504512787,
            0.014698324725031853,
            0.006008564494550228,
            0.01758779026567936,
            -0.01689957268536091,
            -0.016614018008112907,
            0.014034662395715714,
            0.021573757752776146,
            -0.03259934484958649,
            -0.004081299528479576,
            0.028174851089715958,
            -0.02467796951532364,
            3.174860466970131e-05,
            -0.07536644488573074,
            -0.041529469192028046,
            0.03517070785164833,
            0.009437664411962032,
            0.000621979997958988,
            -0.004221347160637379,
            -0.029876206070184708,
            -0.02113345079123974,
            -0.018690094351768494,
            0.011733721010386944,
            -0.006265031639486551,
            -0.0011420304654166102,
            -0.04129398986697197,
            -0.010881134308874607,
            0.031408414244651794,
            -0.01594684273004532,
            0.02814488112926483,
            -0.013092073611915112,
            -0.060293521732091904,
            -0.008373113349080086,
            0.003620818257331848,
            -0.00861890334635973,
            -0.006160891614854336,
            -0.001509759109467268,
            0.015033219940960407,
            0.03375139459967613,
            0.019333558157086372,
            0.009963402524590492,
            -0.03745351731777191,
            -0.006652485113590956,
            -0.024622995406389236,
            -0.03021988645195961,
            0.03000059351325035,
            0.048215046525001526,
            -0.016734333708882332,
            -0.00945767667144537,
            -0.03177012503147125,
            -0.021837161853909492,
            0.011652118526399136,
            -0.021218474954366684,
            -0.008940627798438072,
            0.032671526074409485,
            -0.014021198265254498,
            -0.007364706136286259,
            -0.0001396565930917859,
            -0.0017098983516916633,
            0.01292950939387083,
            0.016443852335214615,
            -0.026484573259949684,
            -0.0038568717427551746,
            -0.012538678012788296,
            -0.012548784725368023,
            0.009459804743528366,
            -0.016992216929793358,
            0.023159585893154144,
            0.0035018117632716894,
            0.021195396780967712,
            0.0015327248256653547,
            0.021165471524000168,
            0.02973131835460663,
            -0.027873601764440536,
            0.021204732358455658,
            -0.02604924887418747,
            0.039042308926582336,
            0.02248941920697689,
            0.025969646871089935,
            -0.008628111332654953,
            0.029163438826799393,
            0.024241432547569275,
            0.009062327444553375,
            -0.006986437831073999,
            0.029186831787228584,
            0.02521705813705921,
            -0.0062681580893695354,
            0.027293244376778603,
            -0.001765060005709529,
            0.04360666126012802,
            -0.008934399113059044,
            0.03677033260464668,
            0.008990950882434845,
            -0.010649770498275757,
            0.029514241963624954,
            -0.01373620517551899,
            -0.01584564335644245,
            0.01438284944742918,
            0.0007568245637230575,
            -0.03869159519672394,
            -0.010979508981108665,
            0.024759918451309204,
            0.004465175326913595,
            0.00698616448789835,
            0.012982559390366077,
            -0.018282277509570122,
            0.00817165244370699,
            0.006786310579627752,
            -0.002119361888617277,
            -0.0746641755104065,
            0.017493166029453278,
            0.0392909049987793,
            -0.016391903162002563,
            -0.012477481737732887,
            -0.05615994706749916,
            -0.01364726759493351,
            0.02028834819793701,
            -0.023398220539093018,
            0.03077121451497078,
            -0.01610175520181656,
            -0.02977755293250084,
            -0.00641435943543911,
            -0.02574961632490158,
            0.024806663393974304,
            -0.047445837408304214,
            0.025299547240138054,
            0.010718397796154022,
            -0.02363806962966919,
            -0.011372237466275692,
            -0.03372175991535187,
            -0.009565040469169617,
            -0.04051820561289787,
            0.01586105115711689,
            0.004128171131014824,
            0.025970736518502235,
            -0.023968324065208435,
            0.01941930688917637,
            -0.004583627916872501,
            0.018838630989193916,
            0.04233778640627861,
            -0.0325409471988678,
            0.020015209913253784,
            0.011844252236187458,
            0.024559130892157555,
            0.03387823700904846,
            -0.021283218637108803,
            0.018920516595244408,
            -0.02765335887670517,
            -0.03395841270685196,
            0.021590253338217735,
            -0.028045497834682465,
            0.03660797327756882,
            -0.008679039776325226,
            -0.015649983659386635,
            -0.020511865615844727,
            -0.014535083435475826,
            0.01690567284822464,
            0.013105930760502815,
            -0.0008045948925428092,
            0.023985259234905243,
            -0.014110689051449299,
            -0.0072521064430475235,
            -0.020359491929411888,
            -0.01826244778931141,
            0.03515271469950676,
            0.022914990782737732,
            0.012082437984645367,
            0.021932687610387802,
            -0.03310219943523407,
            -0.0004128966247662902,
            0.036312732845544815,
            -0.020894300192594528,
            0.03345745429396629,
            0.027897851541638374,
            0.010254276916384697,
            -0.004403210245072842,
            -0.029255259782075882,
            0.0017388085834681988,
            0.019839685410261154,
            -0.005785858258605003,
            0.015617238357663155,
            0.05388263985514641,
            -0.016273926943540573,
            0.010785444639623165,
            0.02841704711318016,
            0.0034139424096792936,
            -0.006638073828071356,
            -0.046024445444345474,
            0.034208111464977264,
            0.014393714256584644,
            -0.004825976677238941,
            -0.034295763820409775,
            0.024664325639605522,
            -0.0176619291305542,
            0.0017628046916797757,
            -0.03243275359272957,
            -0.01492507103830576,
            0.03229124844074249,
            -0.022883985191583633,
            -0.009298590943217278,
            -0.027167830616235733,
            -0.02216154709458351,
            0.020901639014482498,
            0.007340441457927227,
            -0.013349784538149834,
            -0.004240912850946188,
            -0.012000581249594688,
            -0.006744188256561756,
            0.015276609919965267,
            -0.005959318485110998,
            -0.007486692629754543,
            -0.0012842777650803328,
            0.022157054394483566,
            0.004489369224756956,
            0.0030024521984159946,
            -0.0141238272190094,
            -0.01394533459097147,
            0.005691754166036844,
            0.014155525714159012,
            -0.05663806572556496,
            -0.0027417466044425964,
            -0.029879910871386528,
            0.022317884489893913,
            0.03485431894659996,
            -0.014976357109844685,
            0.009839020669460297,
            0.02018597163259983,
            -0.011412808671593666,
            0.005543557461351156,
            -0.016590071842074394,
            -0.02292739972472191,
            0.01955574005842209,
            -0.007087794132530689,
            -0.08052059262990952,
            0.0014517089584842324,
            0.012626178562641144,
            -0.010442415252327919,
            0.027163740247488022,
            0.014261233620345592,
            -0.012698735110461712,
            0.019194243475794792,
            -0.008365829475224018,
            0.0029986596200615168,
            0.011675464920699596,
            -0.006748083047568798,
            -0.004616084974259138,
            0.018298013135790825,
            0.0017415384063497186,
            0.011214867234230042,
            -0.024660829454660416,
            0.0074582574889063835,
            0.008832357823848724,
            0.01300620473921299,
            -0.019032975658774376,
            -0.024566978216171265,
            0.000673244590871036,
            -0.01197606697678566,
            -0.02715739980340004,
            0.015020833350718021,
            -0.028637148439884186,
            0.01873255893588066
        ],
        [
            -0.02974405512213707,
            -0.0358491986989975,
            0.030018417164683342,
            0.015745943412184715,
            0.01700022630393505,
            -0.017049316316843033,
            0.005123550537973642,
            -0.03291696310043335,
            0.019731376320123672,
            -0.0007937827613204718,
            -0.0031922783236950636,
            0.027712609618902206,
            -0.01153676025569439,
            0.01168198324739933,
            0.0006067719659768045,
            -0.0977221205830574,
            -0.0023600764106959105,
            -0.013135215267539024,
            -0.030145032331347466,
            0.0016868761740624905,
            -0.020599113777279854,
            0.0059793503023684025,
            -0.010748066008090973,
            -0.034160397946834564,
            0.017885005101561546,
            -0.01904429867863655,
            -0.059748079627752304,
            0.03371585160493851,
            0.0071977656334638596,
            0.02676755003631115,
            0.02258041687309742,
            0.02708582952618599,
            -0.0263296477496624,
            0.0075847976841032505,
            0.015261095017194748,
            0.04920506104826927,
            -0.03245093300938606,
            -0.00791013054549694,
            -0.008870339952409267,
            -0.015576642006635666,
            -0.009546833112835884,
            0.014105351641774178,
            0.03599921613931656,
            0.023992257192730904,
            -0.051529619842767715,
            -0.028668981045484543,
            0.010203228332102299,
            0.034325357526540756,
            0.03303981572389603,
            0.030680982396006584,
            0.017547542229294777,
            -0.04217660799622536,
            -0.024722114205360413,
            -0.023434093222022057,
            0.017228584736585617,
            0.01298199687153101,
            -0.005987321492284536,
            -0.012804945930838585,
            0.012302787974476814,
            0.012128681875765324,
            0.03813731670379639,
            -0.022971317172050476,
            -0.01600041054189205,
            0.001080230693332851,
            0.04402809217572212,
            -0.00876188836991787,
            -0.0008499462273903191,
            -0.021399440243840218,
            0.02658398263156414,
            -0.018717559054493904,
            0.02551705576479435,
            0.006178677547723055,
            -0.006070432253181934,
            -0.023664530366659164,
            0.020822465419769287,
            -0.01745406910777092,
            0.006423344835639,
            -0.0222636628895998,
            0.019408078864216805,
            -0.00944259762763977,
            0.008322617970407009,
            0.06970696151256561,
            0.03164192661643028,
            -0.0008314105798490345,
            -0.02942810207605362,
            0.00766240106895566,
            0.009516982361674309,
            0.02427034080028534,
            0.01305923517793417,
            0.03905138373374939,
            -0.018243296071887016,
            0.00020736730948556215,
            -0.017302587628364563,
            0.004755844362080097,
            0.02692391909658909,
            0.01195212360471487,
            0.018980810418725014,
            0.01490072812885046,
            0.018477490171790123,
            0.0067850155755877495,
            0.023343142122030258,
            -0.0715598464012146,
            -0.0020008424762636423,
            -0.01651749387383461,
            0.03358599171042442,
            0.008559701032936573,
            -0.016265133395791054,
            0.009711689315736294,
            0.01987072452902794,
            0.03350834175944328,
            -0.032774921506643295,
            0.03499520942568779,
            -0.007127606775611639,
            0.010948452167212963,
            -0.015300031751394272,
            -0.031556546688079834,
            -0.018927717581391335,
            0.024100998416543007,
            0.007309568580240011,
            -0.008247990161180496,
            -0.05802995711565018,
            -0.006542856805026531,
            -0.038010966032743454,
            0.004304289817810059,
            0.01789977215230465,
            -0.024744683876633644,
            -0.023541146889328957,
            -0.021511344239115715,
            -0.0007734284736216068,
            -0.028547784313559532,
            -0.016205651685595512,
            0.018508214503526688,
            -0.04767688736319542,
            0.013472610153257847,
            -0.042915865778923035,
            -0.0267269778996706,
            0.03197398781776428,
            0.00046643245150335133,
            0.012103278189897537,
            -0.02197164297103882,
            0.005673233885318041,
            0.020767919719219208,
            0.003022802295163274,
            -0.020598096773028374,
            -0.0005893163033761084,
            0.025345096364617348,
            0.023289229720830917,
            -0.009463492780923843,
            0.02487766183912754,
            0.0359630212187767,
            -0.0005082283751107752,
            -0.011302648112177849,
            0.031092718243598938,
            0.011096818372607231,
            -0.009152351878583431,
            0.020637284964323044,
            0.03763915225863457,
            -0.017274271696805954,
            -0.05739117041230202,
            -0.05870647728443146,
            0.024341952055692673,
            -0.01266859844326973,
            0.010278335772454739,
            0.0070381760597229,
            -0.01926496997475624,
            -0.019813839346170425,
            0.049377430230379105,
            0.028404537588357925,
            0.05237089470028877,
            -0.007145478390157223,
            0.0017590629868209362,
            -0.046090833842754364,
            0.0057689473032951355,
            -0.008560399524867535,
            0.0059335678815841675,
            -0.01314383652061224,
            -0.0009674701141193509,
            0.00865641888231039,
            0.009414453990757465,
            0.01216040924191475,
            -0.021721268072724342,
            -0.007069744635373354,
            0.006782964803278446,
            -0.014873024076223373,
            -0.048271045088768005,
            0.036433953791856766,
            -0.06993357092142105,
            -0.013343983329832554,
            0.00514038372784853,
            -0.019601108506321907,
            -0.04985720291733742,
            -0.015894947573542595,
            0.07858619093894958,
            -0.016437953338027,
            0.0034844414331018925,
            -0.016471676528453827,
            -0.029614372178912163,
            -0.03402973711490631,
            -0.011354583315551281,
            -0.035849906504154205,
            -0.03362734243273735,
            -0.05201337859034538,
            0.01314356829971075,
            0.03992263227701187,
            0.003321720752865076,
            0.009147229604423046,
            -0.01125290710479021,
            -0.01729968562722206,
            -0.02289559133350849,
            -0.03748118504881859,
            0.026659516617655754,
            -0.0023875839542597532,
            0.0003315141948405653,
            0.02881441079080105,
            -0.0016427022637799382,
            -0.039917487651109695,
            0.01044105552136898,
            -0.010354803875088692,
            0.010013382881879807,
            -0.00997460912913084,
            -0.04417470097541809,
            -0.00856703519821167,
            -0.006656861398369074,
            0.0331992506980896,
            0.008725672960281372,
            -0.024775026366114616,
            -0.023292003199458122,
            -0.005174017511308193,
            0.0029458769131451845,
            0.006924737710505724,
            0.01191335916519165,
            0.001585359568707645,
            0.008934655226767063,
            0.007666195277124643,
            0.01311879325658083,
            0.01610642299056053,
            -0.001417642692103982,
            0.01391216553747654,
            0.029876304790377617,
            -0.03879186511039734,
            0.004209171049296856,
            0.018935028463602066,
            -0.009121809154748917,
            0.029084404930472374,
            -0.04541397467255592,
            -0.027991551905870438,
            -0.017788035795092583,
            0.0033710033167153597,
            -0.008919140323996544,
            -0.032670989632606506,
            -0.05143000930547714,
            -0.02051178179681301,
            -0.006194343790411949,
            0.017633285373449326,
            0.00333500187844038,
            -0.020630214363336563,
            0.015535397455096245,
            -0.03158961609005928,
            0.05008344724774361,
            -0.01691419631242752,
            0.03535320609807968,
            0.04182056710124016,
            0.02130187675356865,
            -0.04707362875342369,
            -0.050904229283332825,
            -0.0056754290126264095,
            -0.002001103712245822,
            0.03138596937060356,
            -0.04480939358472824,
            0.02763480134308338,
            0.010471368208527565,
            -0.01450476422905922,
            0.03752163425087929,
            -0.023662079125642776,
            0.023314448073506355,
            0.0026759293396025896,
            0.01870093122124672,
            0.03963407874107361,
            -0.00452062301337719,
            0.0336705707013607,
            -0.0094539700075984,
            -1.2058369065925945e-05,
            -0.016999434679746628,
            0.01967989094555378,
            -0.019144847989082336,
            -0.0021437411196529865,
            -0.01464039646089077,
            0.04986807331442833,
            0.02156892418861389,
            0.025375178083777428,
            0.008736650459468365,
            -0.025269759818911552,
            0.0282424408942461,
            -0.04370543733239174,
            0.007632537744939327,
            0.0077560353092849255,
            0.01556448731571436,
            -0.009314189665019512,
            -0.012689411640167236,
            0.01446532178670168,
            -0.01629606820642948,
            -0.01643800549209118,
            0.03234443441033363,
            0.002779363887384534,
            -0.03576921671628952,
            -0.12131260335445404,
            0.01205312367528677,
            -0.025313735008239746,
            -0.0034579241182655096,
            -0.002820129506289959,
            0.036971315741539,
            -0.020537061616778374,
            -0.004366368055343628,
            -0.016755541786551476,
            -0.0074640740640461445,
            -0.015483822673559189,
            0.0005789509159512818,
            0.029758017510175705,
            -0.01972467079758644,
            -0.03225993737578392,
            0.0248100608587265,
            0.02742377482354641,
            -0.005509188864380121,
            0.0015465999022126198,
            -0.003960139118134975,
            -0.02557893469929695,
            -0.021672863513231277,
            -0.02876696176826954,
            0.04661178961396217,
            -0.004085115622729063,
            0.01822715438902378,
            -0.004556168802082539,
            -0.02309565246105194,
            -0.014168931171298027,
            0.02273077331483364,
            -0.000687234103679657,
            0.0106748566031456,
            -0.06382303684949875,
            -0.036118775606155396,
            -0.07107499986886978,
            0.007783276494592428,
            -0.01507354062050581,
            0.024060633033514023,
            0.02236846648156643,
            0.0364370234310627,
            -0.06381594389677048,
            0.009197159670293331,
            -0.0008293919381685555,
            0.0019242943963035941,
            0.016575660556554794,
            -0.0043640341609716415,
            0.021804962307214737,
            -0.03682322055101395,
            0.012933479622006416,
            0.002259183907881379,
            0.0025558052584528923,
            -0.028812410309910774,
            0.0034704534336924553,
            0.03565525263547897,
            -0.03656537085771561,
            -0.007435866165906191,
            0.001512813731096685,
            0.021242249757051468,
            0.026791049167513847,
            -0.009544710628688335,
            -0.01517708133906126,
            -0.020592108368873596,
            0.0125012481585145,
            0.004947714973241091,
            -0.022626575082540512,
            0.021531978622078896,
            -0.018161747604608536,
            0.012392154894769192,
            -0.0023360035847872496,
            -0.002175173256546259,
            0.038192786276340485,
            -0.008323405869305134,
            0.0129119036719203,
            0.038969818502664566,
            0.021578270941972733,
            -0.042500145733356476,
            -0.010360348038375378,
            0.02446831203997135,
            0.009028867818415165,
            -0.005466827191412449,
            -0.006174818146973848,
            0.03363294526934624,
            0.014243519864976406,
            -0.007052662316709757,
            -0.05232197046279907,
            -0.02898360788822174,
            -0.04389341175556183,
            0.010136999189853668,
            0.00929082278162241,
            0.017773263156414032,
            0.026262322440743446,
            0.01884913071990013,
            -0.007291985210031271,
            0.022737868130207062,
            0.08241856843233109,
            0.025631997734308243,
            -0.0023266004864126444,
            0.011089923791587353,
            -0.03343107923865318,
            -0.02360718324780464,
            0.034247491508722305,
            0.0030402822885662317,
            -0.04877667874097824,
            0.010127201676368713,
            -0.012327076867222786,
            -0.00402243435382843,
            -0.0440378338098526,
            0.012586751021444798,
            0.01025011669844389,
            -0.005335099529474974,
            0.009582629427313805,
            0.008889829739928246,
            0.014173425734043121,
            -0.015308727510273457,
            -0.04447711259126663,
            0.017425380647182465,
            0.02419847436249256,
            0.0544462725520134,
            0.01575312949717045,
            0.04309187829494476,
            0.021954793483018875,
            0.024071944877505302,
            0.022246144711971283,
            -0.023376287892460823,
            -0.004742915276437998,
            0.03474520519375801,
            -0.01643426902592182,
            -0.009217253886163235,
            -0.018246455118060112,
            0.032136283814907074,
            -8.134841664286796e-06,
            0.0302949920296669,
            0.003770493669435382,
            0.030551044270396233,
            -0.003658862551674247,
            -0.005423268768936396,
            -0.014368104748427868,
            -5.459618478198536e-06,
            -0.03817460685968399,
            0.015444417484104633,
            0.018071267753839493,
            0.004050091374665499,
            0.0750349909067154,
            0.03480455279350281,
            0.03676086664199829,
            -0.03256070986390114,
            0.025126755237579346,
            -0.00828468706458807,
            -0.01058252900838852,
            0.014804095029830933,
            0.020951593294739723,
            -0.02613247185945511,
            -0.0269677322357893,
            0.04652070254087448,
            -0.02920169197022915,
            0.02413800358772278,
            -0.00726684182882309,
            -0.035556331276893616,
            -0.04547256976366043,
            0.03360113501548767,
            -0.01481220405548811,
            0.021497851237654686,
            -0.03779394552111626,
            -0.007657693233340979,
            0.020272258669137955,
            0.03337480127811432,
            0.015298138372600079,
            0.0012031515361741185,
            0.0058469404466450214,
            0.012336920015513897,
            0.03253201022744179,
            -0.013621985912322998,
            0.029313253238797188,
            0.012180604040622711,
            0.02839708887040615,
            -0.010733780451118946,
            -0.04381220415234566,
            0.01217231247574091,
            -0.04553917795419693,
            -0.020977258682250977,
            -0.005865576211363077,
            -0.04125127196311951,
            0.008458792231976986,
            0.04985903576016426,
            0.03949253633618355,
            0.00518030347302556,
            -0.031663987785577774,
            0.010936901904642582,
            0.008064779452979565,
            -0.03176939859986305,
            0.01365176122635603,
            -0.01897375099360943,
            0.0007522564264945686,
            -0.025968901813030243,
            -0.0264494139701128,
            -0.042681366205215454,
            -0.003751586191356182,
            0.033980388194322586,
            -0.028040578588843346,
            0.02897367812693119,
            -0.0341254360973835,
            -0.0041696010157465935,
            -0.02565237134695053,
            0.024801133200526237,
            -0.01580272801220417,
            -0.03623374551534653,
            -0.01934392936527729,
            0.018951816484332085,
            0.0326775424182415,
            0.008531062863767147,
            -0.022053120657801628,
            -0.00615522637963295,
            0.028371267020702362,
            0.04060346260666847,
            0.03403674066066742,
            -0.0048555247485637665,
            -0.006729540880769491,
            -0.032092057168483734,
            0.008168097585439682,
            0.022472817450761795,
            0.02558087557554245,
            0.054974012076854706,
            -0.025826461613178253,
            0.008944145403802395,
            -0.003453049808740616,
            0.0052580214105546474,
            -0.005881171673536301,
            0.05296900495886803,
            -0.005764329340308905,
            0.004892475437372923,
            0.10982618480920792,
            -0.005155643448233604,
            0.03690455108880997,
            0.0032629049383103848,
            0.004844185430556536,
            -0.04140471667051315,
            -0.014125213027000427,
            0.00815595779567957,
            0.046928368508815765,
            0.015421312302350998,
            0.024452652782201767,
            0.011793543584644794,
            0.02864246442914009,
            0.016698427498340607,
            -0.019103005528450012,
            0.005597533658146858,
            -0.037551090121269226,
            -0.014350068755447865,
            -0.0033598351292312145,
            0.020694054663181305,
            -0.011739923618733883,
            0.041920121759176254,
            0.024540510028600693,
            0.005763482768088579,
            0.0009765434078872204,
            0.018264243379235268,
            0.026512444019317627,
            -0.005629316903650761,
            -0.047439128160476685,
            0.03924395143985748,
            0.006145394407212734,
            0.02599756419658661,
            0.019131876528263092,
            0.014421594329178333,
            0.009535131976008415,
            -0.004309240262955427,
            0.05076906085014343,
            0.029590029269456863,
            -0.025261087343096733,
            -0.021402699872851372,
            0.02499263361096382,
            -0.005303388461470604,
            0.03641271963715553,
            0.03207911178469658,
            -0.016585281118750572,
            0.0025163432583212852,
            -0.021137211471796036,
            0.0457552932202816,
            -0.037391941994428635,
            0.0012621525675058365,
            -0.013652423396706581,
            0.010055406019091606,
            -0.005754820071160793,
            -0.042042169719934464,
            -0.040433190762996674,
            -0.017778072506189346,
            -0.011846871115267277,
            0.030372988432645798,
            -0.003956983331590891,
            0.023377345874905586,
            -0.019446052610874176,
            0.02658986672759056,
            0.0002295822196174413,
            -0.00030790630262345076,
            0.04260994866490364,
            0.03402920439839363,
            -0.015824997797608376,
            0.00623745983466506,
            0.02135409228503704,
            -0.03706978261470795,
            0.014100863598287106,
            -0.005398078355938196,
            -0.004498245194554329,
            0.05126535892486572,
            0.04216131195425987,
            -0.04364456981420517,
            0.019396571442484856,
            -0.012111975811421871,
            -0.017742743715643883,
            -0.0109420670196414,
            0.02529321424663067,
            -0.017225703224539757,
            0.0037191491574048996,
            0.0013597615761682391,
            0.013419663533568382,
            0.010661118663847446,
            -0.027929073199629784,
            0.027833007276058197,
            0.0014146189205348492,
            0.017521269619464874,
            -0.03408342972397804,
            -0.01902276650071144,
            -0.027247628197073936,
            -0.04844522103667259,
            -0.024568935856223106,
            0.022039731964468956,
            -0.02663896419107914,
            0.00020662069437094033,
            -0.021078037098050117,
            0.026304567232728004,
            -0.018940960988402367,
            0.015396101400256157,
            0.011731386184692383,
            -0.0553593784570694,
            0.010470325127243996,
            -0.029467390850186348,
            -0.021782301366329193,
            -0.004334523808211088,
            -0.042969804257154465,
            -0.011294608935713768,
            0.010639186948537827,
            -0.008813727647066116,
            -0.04422808438539505,
            -0.024548428133130074,
            -0.013599795289337635,
            0.020334267988801003,
            0.00030318883364088833,
            0.0011021490208804607,
            -0.0004899919149465859,
            0.01406610943377018,
            -0.011856338009238243,
            -0.024462765082716942,
            0.00932917557656765,
            -0.04489418864250183,
            0.01670493371784687,
            0.0338565930724144,
            -0.04736965522170067,
            -0.025193268433213234,
            -0.028345700353384018,
            -0.01550662238150835,
            0.003107637632638216,
            0.016073016449809074,
            0.0009108576341532171,
            0.02131817489862442,
            0.03427766263484955,
            0.04126487299799919,
            -0.01907339319586754,
            -0.015609786845743656,
            -0.024206623435020447,
            -0.003540406236425042,
            -0.011972995474934578,
            -0.0355246476829052,
            0.015312100760638714,
            -0.03871201351284981,
            -0.0016938888002187014,
            0.010154922492802143,
            -0.04805644601583481,
            -0.039158713072538376,
            -0.0034386536572128534,
            -0.011523653753101826,
            0.012822916731238365,
            0.03680599853396416,
            0.018325475975871086,
            -0.04170014336705208,
            0.042438805103302,
            0.011320962570607662,
            0.02882424183189869,
            0.020488254725933075,
            -0.011587978340685368,
            0.0013808541698381305,
            0.04394716024398804,
            -0.01999286562204361,
            -0.007885848172008991,
            0.03166859969496727,
            -0.006186441518366337,
            0.039076678454875946,
            -0.01973857171833515,
            0.017347153276205063,
            -0.024330683052539825,
            -0.00665636919438839,
            -0.0021595682483166456,
            -0.006258536130189896,
            -0.01950298808515072,
            -0.006113552488386631,
            0.015514843165874481,
            0.005557083059102297,
            0.029190199449658394,
            0.02522030659019947,
            0.011888246983289719,
            0.00019213990890420973,
            0.017095673829317093,
            -0.0018603112548589706,
            -0.0071928054094314575,
            0.02457083761692047,
            -0.02673712559044361,
            -0.02541801892220974,
            -0.013816841877996922,
            -0.03387093171477318,
            0.025963084772229195,
            0.028701363131403923,
            -0.01687854900956154,
            -0.018955035135149956,
            -0.020085887983441353,
            0.040117163211107254,
            0.01517296303063631,
            0.052694179117679596,
            0.013443240895867348,
            -0.014805370010435581,
            -0.01413313951343298,
            -0.04398481920361519,
            0.030472515150904655,
            0.006719287950545549,
            0.008272110484540462,
            -0.02287040650844574,
            -0.015674855560064316,
            0.021752050146460533,
            0.007897431030869484,
            0.01521176379173994,
            -0.010818262584507465,
            0.013636600226163864,
            -0.009042265824973583,
            0.06622391194105148,
            0.0053769443184137344,
            -0.003147431882098317,
            0.021234266459941864,
            0.0026330226100981236,
            -0.008548110723495483,
            0.018183477222919464,
            0.04805611073970795,
            0.01836000382900238,
            0.02128966525197029,
            -0.013221210800111294,
            -0.007599523290991783,
            0.024548767134547234,
            -0.01096363365650177,
            0.00042883865535259247,
            0.015100140124559402,
            -0.021743902936577797,
            -0.007861469872295856,
            -0.020817359909415245,
            0.016565566882491112,
            0.018603332340717316,
            0.018138110637664795,
            -0.03377686068415642,
            -0.007336040027439594,
            -0.031582970172166824,
            0.019334079697728157,
            -0.008775878697633743,
            0.03950336575508118,
            -0.03989887610077858,
            -0.05036480724811554,
            0.03189406171441078,
            0.0027450916822999716,
            -0.010790329426527023,
            -0.022113807499408722,
            0.041349031031131744,
            0.036126453429460526,
            -0.009390391409397125,
            0.038014788180589676,
            0.021320147439837456,
            0.026661504060029984,
            -0.02075028046965599,
            0.02485705353319645,
            -0.0295884869992733,
            0.042787835001945496,
            -0.015622603707015514,
            -0.01929398439824581,
            -0.015194162726402283,
            -0.02468976564705372,
            0.015965206548571587,
            -0.022654898464679718,
            0.011972195468842983,
            -0.027679327875375748,
            0.030291469767689705,
            0.023645611479878426,
            -0.005008918233215809,
            0.027783237397670746,
            -0.042642511427402496,
            0.032269187271595,
            -0.001979139633476734,
            0.045049041509628296,
            0.05628444626927376,
            0.017589205875992775,
            -0.004452296998351812,
            0.018914874643087387,
            0.033125899732112885,
            0.0035838207695633173,
            0.004675611853599548,
            0.01403946615755558,
            0.0030918156262487173,
            0.0005881648976355791,
            0.03496870398521423,
            -0.045410238206386566,
            -0.015296339057385921,
            0.020502299070358276,
            -0.01589227095246315,
            0.0011151271173730493,
            -0.03467385843396187,
            -0.011333221569657326,
            0.0236427653580904,
            -0.012692323885858059,
            -0.02563183568418026,
            -0.04647095128893852,
            0.017892876639962196,
            -0.011287117376923561,
            0.03983664512634277,
            -0.033838868141174316,
            0.019146518781781197,
            0.04215666651725769,
            -0.015859432518482208,
            0.02040708251297474,
            -0.008611678145825863,
            0.019231321290135384,
            -0.0075642927549779415,
            -0.004195554181933403,
            0.006410942412912846,
            -0.016397511586546898,
            0.02992774173617363,
            -0.033144813030958176,
            -0.016166549175977707,
            0.04007907584309578,
            -0.018826395273208618,
            0.004752884153276682,
            -0.0009994737338274717,
            -0.021668054163455963,
            0.02802395634353161,
            -0.005627961829304695,
            0.06632483750581741,
            -0.026242487132549286,
            -0.01773117110133171,
            -0.01701793260872364,
            0.027123525738716125,
            -0.02186749316751957,
            -0.005385237745940685,
            -0.023095183074474335,
            -0.02588300034403801,
            -0.022872235625982285,
            -0.01785707287490368,
            0.02554655820131302,
            0.024548936635255814,
            -0.017764899879693985,
            0.038201652467250824,
            -0.008830815553665161,
            0.010947822593152523,
            -0.0048480285331606865,
            0.011407757177948952,
            -0.020582709461450577,
            0.06667117029428482,
            -0.019855141639709473,
            -0.02295408770442009,
            0.03200634941458702,
            0.009148221462965012,
            0.040580205619335175,
            -0.03505747765302658,
            -0.03231660649180412,
            0.02521180734038353,
            -0.00949039775878191,
            -0.0033651748672127724,
            0.01751044951379299,
            -0.029837384819984436,
            0.01415994856506586,
            0.005744147580116987,
            -0.0034107426181435585,
            0.047833532094955444,
            0.005238191224634647,
            -0.02987431176006794,
            0.0033094193786382675,
            -0.05872625112533569,
            -0.046006012707948685,
            -0.014539176598191261,
            -0.02180626057088375,
            0.038516007363796234,
            -0.01822039484977722,
            -0.01778377778828144,
            -0.030892929062247276,
            -0.013484771363437176,
            0.022906702011823654,
            -0.02005566656589508,
            -0.052844349294900894,
            0.00034395066904835403,
            -0.0020406804978847504,
            -0.029754195362329483,
            -0.003997606225311756,
            -0.016681892797350883,
            0.03542226925492287,
            -0.009102685377001762,
            0.04452747479081154,
            0.009774427860975266,
            0.02826869487762451,
            0.0019146230770274997,
            0.029917839914560318,
            0.03333070129156113,
            0.00536847859621048,
            -0.011610484682023525,
            0.024073833599686623,
            -0.011914740316569805,
            0.026662152260541916,
            0.013424239121377468,
            -0.004817608278244734,
            0.007532036397606134,
            0.024711402133107185,
            0.005661108531057835,
            -0.03826000913977623,
            -0.03408076614141464,
            0.011842850595712662,
            0.03319522365927696,
            0.01168294157832861,
            0.04997609183192253,
            0.020078683272004128,
            -0.015163736417889595,
            -0.02693174220621586,
            0.018504420295357704,
            0.005475662183016539,
            0.030454007908701897,
            0.011898113414645195,
            -0.036371733993291855,
            -0.025770213454961777,
            0.036821991205215454,
            0.018180403858423233,
            0.02930791676044464,
            0.008557502180337906,
            -0.010231216438114643,
            -0.006949503906071186,
            0.006455259397625923,
            -0.07522117346525192,
            0.012698006816208363,
            -0.0037981753703206778,
            -0.00680883601307869,
            0.005104822106659412,
            -0.011529315263032913,
            -0.026340320706367493,
            0.02287730574607849,
            0.01274492684751749,
            -0.025631004944443703,
            -0.004208566155284643,
            -0.05078975856304169,
            -0.00531456945464015,
            0.012539415620267391,
            0.0024942022282630205,
            0.01171918399631977,
            -0.0058178226463496685,
            -0.01013254839926958,
            0.00802646018564701,
            -0.018081143498420715,
            -0.014345605857670307,
            0.0007184937712736428,
            0.016930168494582176,
            -0.01271363627165556,
            -0.017112048342823982,
            -0.018170282244682312,
            -0.030113309621810913,
            0.03800193965435028,
            0.0021557186264544725,
            -0.011243602260947227,
            0.009431959129869938,
            -0.012328076176345348,
            -0.007220829837024212,
            -0.05167509987950325,
            -0.034322988241910934,
            0.042276594787836075,
            -0.028176814317703247,
            0.010901996865868568,
            -0.0002901984262280166,
            -0.00658209016546607,
            -0.03080507554113865,
            0.04405592381954193,
            -0.001123775728046894,
            0.0292619951069355,
            -0.0009260423830710351,
            0.004772595129907131,
            0.027001332491636276,
            0.08564350754022598,
            -0.06133757159113884,
            0.03397494927048683,
            0.00214376300573349,
            -0.015938693657517433,
            0.011195698752999306,
            -0.0037512206472456455,
            -0.014309793710708618,
            -0.013270756229758263,
            0.020031221210956573,
            -0.00980830192565918,
            0.031308043748140335,
            0.021934406831860542,
            0.009566111490130424,
            0.05019436404109001,
            -0.06295495480298996,
            -0.025556111708283424,
            0.011210848577320576,
            0.02483714371919632,
            0.018744945526123047,
            0.0035969477612525225,
            -0.025302458554506302,
            -0.04619571939110756,
            0.010842489078640938,
            0.015127229504287243,
            0.0028885947540402412,
            0.040091510862112045,
            -0.0029700403101742268
        ],
        [
            -0.010674468241631985,
            0.00747464457526803,
            -0.0117186913266778,
            0.01876821368932724,
            -0.01376473717391491,
            -0.024442363530397415,
            0.009584615007042885,
            -0.0016168943839147687,
            0.032561685889959335,
            -0.01111085806041956,
            -0.012244737707078457,
            -0.011018548160791397,
            -0.03439728543162346,
            -0.008558952249586582,
            -0.051308974623680115,
            -0.011351167224347591,
            -0.011240844614803791,
            -0.0034484367351979017,
            0.01613948866724968,
            -0.014250009320676327,
            -0.0061148954555392265,
            -0.027432827278971672,
            0.0036354479379951954,
            -0.006561780348420143,
            -0.02338017337024212,
            0.0007082177908159792,
            -0.027328014373779297,
            -0.001395157421939075,
            -0.026204537600278854,
            0.022457320243120193,
            0.0040917834267020226,
            0.031151847913861275,
            0.03317297250032425,
            -0.012661801651120186,
            0.029402559623122215,
            -0.008010058663785458,
            0.014818893745541573,
            0.03903656452894211,
            -0.019349217414855957,
            0.002794179366901517,
            0.023794181644916534,
            -0.01229908037930727,
            0.013652196153998375,
            0.016752824187278748,
            -0.053873345255851746,
            0.0061607807874679565,
            0.022803165018558502,
            -0.030227992683649063,
            0.0010257911635562778,
            0.03293959051370621,
            -0.002727251034229994,
            -0.016006821766495705,
            -0.011461133137345314,
            -0.009656430222094059,
            -0.017541997134685516,
            0.026471545919775963,
            0.007478645071387291,
            -0.022268380969762802,
            0.017975155264139175,
            -0.0008166174520738423,
            -0.02011476643383503,
            -0.026100585237145424,
            -0.025629043579101562,
            0.028960267081856728,
            0.0015812754863873124,
            0.011691967956721783,
            0.005446336697787046,
            -0.018287375569343567,
            0.029414178803563118,
            0.01450478658080101,
            0.03449641540646553,
            -0.015474784187972546,
            -0.016940506175160408,
            0.027791470289230347,
            0.007494464050978422,
            0.014058813452720642,
            -0.005015164148062468,
            0.0013442260678857565,
            -0.0321931317448616,
            -0.01685399003326893,
            -0.011879928410053253,
            -0.006342793349176645,
            -0.018726244568824768,
            0.00945180281996727,
            0.028740596026182175,
            -0.005503795575350523,
            0.01820356957614422,
            -0.02768363058567047,
            -0.0058316015638411045,
            -0.046391360461711884,
            0.029833482578396797,
            -0.021070117130875587,
            0.026714088395237923,
            -0.023596392944455147,
            -0.012691417708992958,
            0.0012259845389053226,
            0.043147165328264236,
            -0.005138309672474861,
            -0.011491287499666214,
            -0.02410651370882988,
            -0.009758448228240013,
            -0.01384820882230997,
            0.020742077380418777,
            0.020945090800523758,
            -0.009435937739908695,
            -0.016073159873485565,
            0.042696207761764526,
            0.008622021414339542,
            0.02337847463786602,
            0.005841087084263563,
            -0.01746777445077896,
            -0.014776322059333324,
            0.025831252336502075,
            0.023085515946149826,
            0.035413891077041626,
            0.014086421579122543,
            0.0017215120606124401,
            0.020338479429483414,
            0.0018253104062750936,
            0.02639278955757618,
            -0.014623302035033703,
            -0.01931367814540863,
            0.03450958430767059,
            0.0036018232349306345,
            -0.019026782363653183,
            0.014436774887144566,
            0.01599137671291828,
            0.03075457736849785,
            -0.026501398533582687,
            -0.013912676833570004,
            0.00970256607979536,
            0.018355024978518486,
            0.008516819216310978,
            0.0004145785642322153,
            -0.027584120631217957,
            0.029671844094991684,
            -0.012877077795565128,
            -0.024206258356571198,
            0.0015791453188285232,
            -0.002176751848310232,
            -0.019139407202601433,
            0.02856275625526905,
            0.015929309651255608,
            0.020601913332939148,
            0.007006777450442314,
            0.0011993853840976954,
            0.02518993429839611,
            0.03296039626002312,
            -0.013483044691383839,
            -0.015251589007675648,
            0.017780106514692307,
            0.0064710392616689205,
            0.03683175891637802,
            -0.03322911635041237,
            -0.03916114568710327,
            -0.04417040944099426,
            0.002938707359135151,
            0.005885421298444271,
            -0.008715123869478703,
            -0.013819104060530663,
            0.007983505725860596,
            0.017264369875192642,
            0.03592800721526146,
            -0.005111092701554298,
            -0.026926765218377113,
            0.04489564150571823,
            -0.02259252965450287,
            -0.028253167867660522,
            -0.04026947170495987,
            -0.0042027137242257595,
            0.02315901219844818,
            0.022280583158135414,
            -0.005970655009150505,
            -0.026946434751152992,
            0.008871637284755707,
            0.03248966112732887,
            0.003545640967786312,
            0.01454258058220148,
            -0.03135518729686737,
            -0.02471817657351494,
            0.007467292249202728,
            -0.01253326702862978,
            0.007123205810785294,
            -0.026982048526406288,
            0.02649490162730217,
            -0.02876661904156208,
            -0.010179624892771244,
            0.03004542738199234,
            0.00812558550387621,
            0.006889893673360348,
            -0.012372239492833614,
            0.019544262439012527,
            0.03270232304930687,
            -0.00752020999789238,
            -0.023093057796359062,
            -0.034744348376989365,
            -0.0027884466107934713,
            -0.02209051325917244,
            -0.00858224742114544,
            0.016855614259839058,
            0.030060745775699615,
            0.01364208199083805,
            0.006962266750633717,
            0.021018866449594498,
            0.03255156800150871,
            0.007524628192186356,
            -0.013124136254191399,
            -0.021955540403723717,
            -0.01884213276207447,
            -0.023858897387981415,
            -0.028041549026966095,
            -0.008577698841691017,
            0.024461328983306885,
            -0.03655915707349777,
            0.02852759324014187,
            0.0042314487509429455,
            0.022145116701722145,
            -0.008734130300581455,
            -0.008603294380009174,
            0.003858000971376896,
            0.011807667091488838,
            0.04203852638602257,
            -0.015588734298944473,
            -0.024853181093931198,
            -0.016701731830835342,
            0.026430264115333557,
            0.007959471084177494,
            0.018125038594007492,
            -0.04731971397995949,
            -0.007455726154148579,
            0.032840095460414886,
            -0.01715015433728695,
            0.025141682475805283,
            -0.02040606364607811,
            0.027760228142142296,
            -0.03577645495533943,
            -0.03156985715031624,
            -0.006877364125102758,
            -0.01701260171830654,
            -0.019144469872117043,
            -0.018326640129089355,
            0.025420056656003,
            -0.014396489597856998,
            0.014606649987399578,
            0.019709352403879166,
            0.0003671877202577889,
            0.018874961882829666,
            -0.011591950431466103,
            0.010267597623169422,
            0.013323796913027763,
            -0.032348986715078354,
            -0.007550581358373165,
            0.013883594423532486,
            -0.011442278511822224,
            -0.0034516179002821445,
            0.02552974410355091,
            0.01669287495315075,
            -0.021393602713942528,
            0.03803243115544319,
            0.021847005933523178,
            -0.01898529939353466,
            0.022699670866131783,
            -0.007171288598328829,
            -0.00655094999819994,
            0.023875080049037933,
            -0.004793111700564623,
            0.006877719424664974,
            -0.00815601646900177,
            -0.01645439676940441,
            0.03813331574201584,
            -0.03940301015973091,
            0.02146274410188198,
            0.009063052013516426,
            -0.025415128096938133,
            -0.02346312813460827,
            0.008951003663241863,
            -0.01986224390566349,
            -0.017566226422786713,
            -0.008127259090542793,
            0.012957696802914143,
            0.0027913623489439487,
            -0.016820518299937248,
            -0.022612374275922775,
            -0.020059097558259964,
            0.02680877223610878,
            0.02029252052307129,
            0.026366425678133965,
            0.003819381818175316,
            0.03140665590763092,
            0.02593194879591465,
            -0.014131089672446251,
            0.028031539171934128,
            0.0001920123177114874,
            0.01898585632443428,
            -0.005166857037693262,
            -5.750789205194451e-05,
            0.033344998955726624,
            0.014139351435005665,
            -0.03232518211007118,
            0.007536244112998247,
            -0.01354467123746872,
            -0.018723266199231148,
            -0.007604035548865795,
            -0.02188018336892128,
            -0.028488749638199806,
            0.030829710885882378,
            -0.02060522884130478,
            -0.02721114084124565,
            -0.0003065472701564431,
            -0.01810212805867195,
            0.028074629604816437,
            0.041116081178188324,
            -0.005246203858405352,
            0.008640469051897526,
            -0.00704812491312623,
            -0.0007081934018060565,
            0.0005768631817772985,
            0.0035322217736393213,
            0.030876969918608665,
            -0.014322631992399693,
            0.0307343527674675,
            0.02412167564034462,
            0.012295078486204147,
            0.0071121458895504475,
            0.035122811794281006,
            0.009434505365788937,
            -0.007745609153062105,
            0.04392074793577194,
            -0.012423962354660034,
            -0.014780108816921711,
            -0.01620851457118988,
            -0.024281136691570282,
            -0.0056727915070950985,
            -0.0018223070073872805,
            -0.00811487901955843,
            -0.014567701146006584,
            -0.028844427317380905,
            0.04228920117020607,
            0.025458795949816704,
            -0.022380294278264046,
            -0.010315225459635258,
            0.03908349201083183,
            0.048617132008075714,
            0.0033130235970020294,
            -0.0082259401679039,
            -0.01034262403845787,
            -0.0332249291241169,
            0.019533339887857437,
            -0.03045348823070526,
            0.02128485031425953,
            0.0019114927854388952,
            -0.010983126237988472,
            0.013565204106271267,
            -0.02203654684126377,
            0.006984896957874298,
            -0.013775411993265152,
            0.02550956979393959,
            0.016681192442774773,
            -0.026013465598225594,
            0.00824370700865984,
            0.005492460913956165,
            -0.015477155335247517,
            0.01648467220366001,
            -0.0038003232330083847,
            0.026271317154169083,
            0.0018021550495177507,
            0.004664007108658552,
            0.01478206180036068,
            -0.02569565363228321,
            0.025956884026527405,
            0.0004146362189203501,
            0.01778569258749485,
            -0.03612114116549492,
            -0.016960741952061653,
            0.018501197919249535,
            -0.033056795597076416,
            0.01811383105814457,
            -0.03359674662351608,
            0.014842047356069088,
            -0.022090164944529533,
            0.026051566004753113,
            0.014883339405059814,
            -0.020690137520432472,
            -0.014843911863863468,
            0.009117688983678818,
            -0.04841619357466698,
            -0.017198173329234123,
            -0.00724654458463192,
            -0.01590188778936863,
            0.007498966529965401,
            0.011631290428340435,
            0.010272433049976826,
            0.01871037483215332,
            -0.013088793493807316,
            0.05570215731859207,
            0.007764256559312344,
            0.005431442055851221,
            -0.015473696403205395,
            0.011178172193467617,
            0.0004062999796587974,
            0.020692454650998116,
            -0.01244839932769537,
            -0.02157171443104744,
            0.030426079407334328,
            -0.0005386982229538262,
            -0.024247964844107628,
            -0.022191422060132027,
            -0.026635486632585526,
            0.021281803026795387,
            -0.03040129877626896,
            0.017890486866235733,
            0.001181296189315617,
            -0.024307815358042717,
            -0.012914200313389301,
            0.004776455461978912,
            -0.020373158156871796,
            -0.005919320974498987,
            -0.01184324361383915,
            -0.03378497064113617,
            0.028341157361865044,
            -0.00160883367061615,
            0.032884303480386734,
            -0.00962503906339407,
            -0.009489495307207108,
            0.03787153959274292,
            0.006960007362067699,
            -0.0005825608968734741,
            -0.010639753192663193,
            0.02410188876092434,
            -0.016461890190839767,
            -0.02047480084002018,
            -0.006830157246440649,
            0.026834864169359207,
            -0.017326366156339645,
            -0.023790661245584488,
            0.0011753353755921125,
            0.010335806757211685,
            0.00010085299436468631,
            -0.03128917142748833,
            0.011760791763663292,
            -0.005239307414740324,
            -0.029972441494464874,
            0.015872571617364883,
            0.008244423195719719,
            -0.007224117871373892,
            -0.004955538548529148,
            -0.0005527237663045526,
            -0.08446502685546875,
            -0.016645098105072975,
            -0.0024216247256845236,
            0.0255420058965683,
            -0.023106146603822708,
            -0.03496488556265831,
            -0.022210964933037758,
            0.014587792567908764,
            0.027688104659318924,
            0.012372179888188839,
            0.02444453537464142,
            0.02667822688817978,
            -0.00641423836350441,
            -0.02062385156750679,
            0.01144400704652071,
            -0.002361780498176813,
            -0.001162552391178906,
            -0.001779911806806922,
            0.02726796641945839,
            0.026623409241437912,
            -0.021244877949357033,
            -0.012514766305685043,
            -0.017653021961450577,
            -0.025590023025870323,
            -0.019415266811847687,
            0.0355597622692585,
            0.0061067803762853146,
            0.01802305318415165,
            0.05159588158130646,
            0.01832728087902069,
            -0.021882399916648865,
            -0.026060309261083603,
            -0.010193966329097748,
            0.0040804240852594376,
            0.0014788404805585742,
            0.02577751874923706,
            -0.024775147438049316,
            -0.055184219032526016,
            0.01990901306271553,
            0.00033406802685931325,
            0.02338472381234169,
            0.026859549805521965,
            0.013322505168616772,
            -0.023532766848802567,
            0.02659328281879425,
            0.02533726952970028,
            0.008714786730706692,
            -0.026432016864418983,
            0.028912976384162903,
            -0.03059624880552292,
            0.018136287108063698,
            0.03505401313304901,
            0.016162393614649773,
            -0.0076806978322565556,
            -0.003999354783445597,
            -0.02841344103217125,
            -0.056134603917598724,
            -0.0056768679060041904,
            0.0073513733223080635,
            0.007956074550747871,
            0.02452818490564823,
            -0.003389639314264059,
            -0.0070400545373559,
            0.010954365134239197,
            0.0024296273477375507,
            0.002104987623170018,
            -0.03829747810959816,
            -0.01638084463775158,
            0.026675621047616005,
            0.014156720601022243,
            -0.015184571966528893,
            0.015448142774403095,
            -0.009937049821019173,
            0.00441187946125865,
            4.284913302399218e-06,
            0.019273541867733,
            -0.0348396934568882,
            0.021236591041088104,
            -0.033273063600063324,
            0.021244898438453674,
            0.013736333698034286,
            0.028591951355338097,
            0.04114984720945358,
            -0.006161609664559364,
            0.0016370618250221014,
            -0.03902110457420349,
            0.015456669963896275,
            0.038832761347293854,
            -0.04780028015375137,
            0.017120851203799248,
            -0.04329456761479378,
            0.019053492695093155,
            -0.0022787947673350573,
            -0.028928659856319427,
            0.0004880692868027836,
            -0.028835637494921684,
            -0.010346921160817146,
            0.017686301842331886,
            -0.04353700950741768,
            -0.00232596043497324,
            -0.007612245157361031,
            -0.03757484257221222,
            0.017844146117568016,
            0.008690670132637024,
            -0.01690911501646042,
            -0.004309189971536398,
            -0.01230490580201149,
            0.007457886356860399,
            -0.0026852618902921677,
            -0.020069219172000885,
            0.01267603412270546,
            0.024751799181103706,
            0.026204513385891914,
            0.017906276509165764,
            -0.010796290822327137,
            -0.02178497239947319,
            -0.015818927437067032,
            -0.01986495405435562,
            0.016410529613494873,
            0.0016593507025390863,
            -0.017507461830973625,
            0.015982594341039658,
            0.0053310636430978775,
            -0.007392250932753086,
            -0.012955336831510067,
            -0.006705158390104771,
            0.010394171811640263,
            0.019480857998132706,
            -0.00314605375751853,
            -0.00441468833014369,
            0.010703195817768574,
            0.037950482219457626,
            0.0075721414759755135,
            0.015086088329553604,
            -0.023509418591856956,
            -0.01399168185889721,
            0.03942051902413368,
            0.0182243250310421,
            0.015439039096236229,
            -0.002651175484061241,
            -0.017109742388129234,
            -0.025389764457941055,
            -0.02232936955988407,
            0.01920248381793499,
            -0.014283588156104088,
            0.023570938035845757,
            -0.024431057274341583,
            0.02197267673909664,
            0.033335503190755844,
            -0.01590377278625965,
            -0.01425863802433014,
            -0.018811218440532684,
            -0.023871861398220062,
            -0.017223184928297997,
            -0.01254037395119667,
            -0.003326214849948883,
            -0.03305261209607124,
            0.004823924507945776,
            -0.02701551280915737,
            -0.0026904644910246134,
            -0.01705326698720455,
            0.043585456907749176,
            -0.0068792798556387424,
            0.00042602120083756745,
            0.032352812588214874,
            0.019438203424215317,
            0.005349503364413977,
            -0.026333441957831383,
            -0.025982141494750977,
            0.029442565515637398,
            -0.031762897968292236,
            -0.003448238829150796,
            0.006142573896795511,
            -0.025621362030506134,
            0.009894073009490967,
            0.0006744153797626495,
            0.04091162979602814,
            -0.03374633938074112,
            0.012472113594412804,
            -0.022065946832299232,
            -0.005318314768373966,
            -0.015256914310157299,
            0.04031120240688324,
            -0.01415801327675581,
            -0.007660229690372944,
            -0.00580417038872838,
            0.01890476793050766,
            -0.016999123618006706,
            0.012406314723193645,
            0.01846228539943695,
            -0.003000476863235235,
            -0.03383198007941246,
            0.0019454237772151828,
            0.01111757755279541,
            -0.0322285033762455,
            0.03338554501533508,
            0.045164402574300766,
            0.0007331632077693939,
            0.01726597175002098,
            -0.01101447269320488,
            0.008851930499076843,
            0.007951806299388409,
            0.02451656013727188,
            0.008741532452404499,
            -0.014152773655951023,
            -0.014658927917480469,
            0.01927155628800392,
            0.015774177387356758,
            -0.0025701660197228193,
            -0.017985539510846138,
            0.038286712020635605,
            0.01675673946738243,
            0.028438899666070938,
            0.030039405450224876,
            0.005260359030216932,
            0.017169252038002014,
            0.002265756484121084,
            -0.01612795516848564,
            0.00992000661790371,
            -0.019146166741847992,
            0.009427990764379501,
            0.002942189108580351,
            -0.020861657336354256,
            -0.02235398069024086,
            -0.005839153658598661,
            -0.024004103615880013,
            -0.013059787452220917,
            0.02658647485077381,
            0.0024249886628240347,
            -0.016677644103765488,
            -0.02312927134335041,
            -0.023438937962055206,
            0.01620825193822384,
            -0.0015662253135815263,
            -0.003916300367563963,
            0.007559704128652811,
            -0.02223598212003708,
            0.02348601445555687,
            0.023640332743525505,
            0.013751942664384842,
            -0.009876959025859833,
            0.022248907014727592,
            -0.02353077568113804,
            -0.02649862691760063,
            0.02165522426366806,
            0.028294196352362633,
            -0.012242784723639488,
            -0.0026155428495258093,
            0.010283485054969788,
            0.013398864306509495,
            0.02240946888923645,
            0.017063278704881668,
            -0.026664741337299347,
            0.037342049181461334,
            0.021816063672304153,
            0.005599779076874256,
            0.011321642436087132,
            0.022515811026096344,
            -0.022990191355347633,
            0.031243624165654182,
            0.0003385536256246269,
            0.0035966953728348017,
            0.03622697293758392,
            0.015412939712405205,
            0.009003117680549622,
            0.046670280396938324,
            -0.027301963418722153,
            -0.0017276130383834243,
            0.016795523464679718,
            -0.027790723368525505,
            0.014174162410199642,
            0.0002957789693027735,
            0.009009314700961113,
            -0.03776383772492409,
            0.00915619172155857,
            -0.012691723182797432,
            0.020318107679486275,
            -0.008808672428131104,
            0.03056339919567108,
            -0.02986879274249077,
            -0.010927252471446991,
            0.0003496224817354232,
            -0.00536990026012063,
            -0.007174591068178415,
            -0.015191620215773582,
            0.03230838477611542,
            -0.021512538194656372,
            0.022763699293136597,
            0.030189400538802147,
            0.023797577247023582,
            -0.012243276461958885,
            0.02115613967180252,
            0.0007847319357097149,
            -0.002943658037111163,
            -0.00610111840069294,
            0.013174631632864475,
            0.030462998896837234,
            0.021307000890374184,
            -0.0002787730481941253,
            0.02795521356165409,
            -0.0005641483585350215,
            0.00094681914197281,
            -0.02793843485414982,
            0.0027491545770317316,
            -0.02169647626578808,
            0.030278773978352547,
            -0.054631322622299194,
            0.03180643543601036,
            0.027943456545472145,
            0.03602590039372444,
            -0.009670889936387539,
            0.013348422013223171,
            -0.011632503941655159,
            0.0029111390467733145,
            -0.028195085003972054,
            -0.01937847025692463,
            -0.024998808279633522,
            -0.023655429482460022,
            0.007388273719698191,
            0.009344000369310379,
            0.000395074428524822,
            0.009601694531738758,
            0.005892288871109486,
            0.0007469792617484927,
            -0.01286685187369585,
            -0.0324670635163784,
            -0.017299754545092583,
            -0.027834326028823853,
            -0.016118694096803665,
            0.0006449759239330888,
            -0.029693445190787315,
            0.0033933811355382204,
            0.0168540570884943,
            0.0027541611343622208,
            -0.024792544543743134,
            0.035456717014312744,
            -0.01420770213007927,
            -0.013035086914896965,
            0.009526371955871582,
            -0.006261447910219431,
            -0.013093349523842335,
            0.01893565058708191,
            0.0436696894466877,
            -0.006390952039510012,
            -0.02841797098517418,
            -0.023939307779073715,
            -0.03961733356118202,
            0.018287718296051025,
            -0.01892753504216671,
            -0.012445700354874134,
            -0.007179643958806992,
            0.01909826695919037,
            0.030316777527332306,
            -0.04585661739110947,
            0.004379462916404009,
            -0.025075364857912064,
            -0.044211480766534805,
            -0.009327230043709278,
            0.000345752778230235,
            0.02411595731973648,
            -0.028189007192850113,
            0.01298233401030302,
            0.02436249516904354,
            0.007558447774499655,
            -0.01474536582827568,
            0.01761738769710064,
            0.03397197276353836,
            0.0023762560449540615,
            0.02170104719698429,
            -0.016537779942154884,
            -0.014338154345750809,
            -0.014032919891178608,
            0.028977587819099426,
            0.0017946241423487663,
            0.00640293350443244,
            -0.009286114014685154,
            -0.005542667582631111,
            -0.02599828876554966,
            0.028812268748879433,
            0.017102787271142006,
            -0.029097668826580048,
            0.02852085418999195,
            -0.01118891779333353,
            -0.0016183751868084073,
            0.029887625947594643,
            -0.023270180448889732,
            0.015624215826392174,
            -0.018476435914635658,
            -0.04108358174562454,
            0.01119606476277113,
            0.00599072128534317,
            0.03119044564664364,
            0.014726182445883751,
            -0.01125251967459917,
            0.02979726530611515,
            -0.030449792742729187,
            0.028526602312922478,
            -0.019091837108135223,
            0.00016532260633539408,
            -0.0022845189087092876,
            0.03589879348874092,
            0.003357302164658904,
            0.0035112379118800163,
            0.0045372494496405125,
            0.011372886598110199,
            -0.003955273423343897,
            0.03216596692800522,
            -0.044130295515060425,
            0.03462812677025795,
            0.01985686644911766,
            -0.011938333511352539,
            -0.011947257444262505,
            0.027274195104837418,
            -0.021041957661509514,
            -0.013494919054210186,
            -0.010114516131579876,
            -0.02557053044438362,
            0.0040173656307160854,
            0.032423075288534164,
            0.0018927257042378187,
            -0.02811710722744465,
            0.0041568707674741745,
            -0.023424537852406502,
            -0.00453149015083909,
            0.02750428579747677,
            -0.0303682629019022,
            0.00599255133420229,
            0.025582030415534973,
            0.013159300200641155,
            0.025105588138103485,
            0.005764305591583252,
            -0.0181129053235054,
            0.011500830762088299,
            0.016310056671500206,
            -0.008858736604452133,
            0.001212010276503861,
            -0.0018718685023486614,
            0.013174309395253658,
            -0.011507444083690643,
            -0.005839616525918245,
            0.013437910936772823,
            -0.0311080664396286,
            0.02286982536315918,
            0.019554290920495987,
            0.02746555209159851,
            -0.019025461748242378,
            0.003932356834411621,
            -0.04045715928077698,
            0.006378029938787222,
            -0.025437215343117714,
            0.017799513414502144,
            0.006662018597126007,
            -0.013835898600518703,
            -0.029780007898807526,
            -0.008254260756075382,
            -0.006649060640484095,
            0.011638105846941471,
            -0.041745953261852264,
            -0.016441110521554947,
            -0.0236623864620924,
            0.0240141823887825,
            0.004749859217554331,
            0.0032348991371691227,
            0.01516833994537592,
            -0.037460457533597946,
            -0.025553958490490913,
            -0.008709416724741459,
            -0.04397621750831604,
            0.0075833844020962715,
            0.0007812517578713596,
            0.027092738077044487,
            -0.004559512250125408,
            0.03174293786287308,
            0.010854954831302166,
            0.011054014787077904,
            0.016883118078112602,
            -0.012300814501941204,
            0.012556755915284157,
            0.007441954221576452,
            0.03345880284905434,
            -0.0061275167390704155,
            0.016401255503296852,
            0.020223546773195267,
            0.03483308106660843,
            -0.005482409615069628,
            0.0015879507409408689,
            -0.03271915018558502,
            0.021101966500282288,
            -0.016388537362217903,
            -0.015084011480212212,
            0.03462817892432213,
            0.023197811096906662,
            0.03428128734230995,
            0.014125196263194084,
            -0.02492719702422619,
            -0.008889224380254745,
            -0.014908050186932087,
            -0.007982034236192703,
            -0.02150776982307434,
            -0.010199801065027714,
            -0.06798835843801498,
            -0.029664257541298866,
            0.0018423345172777772,
            0.05634777992963791,
            0.029730135574936867,
            -0.02017260529100895,
            0.008119786158204079,
            -0.0056742397136986256,
            -0.01599700376391411,
            -0.020623160526156425,
            -0.0006591067649424076,
            0.04804002121090889,
            -0.04926514998078346,
            0.009553990326821804,
            0.023285504430532455,
            0.017064053565263748,
            0.00841394905000925,
            -0.008057125844061375,
            -0.007911719381809235,
            0.02857956476509571,
            0.01352181751281023,
            0.014337324537336826,
            0.004878135863691568,
            -0.025584999471902847,
            0.006023592781275511,
            0.023751001805067062,
            0.02678045630455017,
            -0.02452661655843258,
            -0.028386671096086502,
            0.03957972675561905,
            0.01256166398525238,
            0.02921929582953453,
            0.003185160690918565,
            0.0019486186793074012,
            0.029866864904761314,
            0.014638044871389866,
            0.025486381724476814,
            0.03120318055152893,
            -0.025813739746809006,
            -0.01595441810786724,
            0.014660267159342766,
            0.0013887656386941671,
            0.022290581837296486,
            0.018225934356451035,
            -0.014889328740537167,
            -0.0011272481642663479,
            0.02760281227529049,
            -0.009041511453688145,
            -0.007368331775069237,
            0.0010019687470048666,
            0.016693780198693275,
            0.00048707754467613995,
            0.004727215971797705,
            0.005960449576377869,
            -0.013854808174073696,
            -0.02937311679124832,
            0.03256024792790413,
            0.02338612824678421,
            0.02712668664753437,
            0.007757161278277636,
            -0.0025146056432276964,
            0.0029035040643066168,
            0.011790001764893532,
            -0.017726317048072815,
            -0.013065206818282604,
            0.026965854689478874,
            -0.000222516551730223,
            -0.01383282896131277,
            -0.028032924979925156,
            0.004451761022210121,
            -0.017660759389400482,
            -0.025442088022828102,
            0.018335862085223198,
            -0.017157120630145073,
            -0.03361346200108528,
            -0.01616668701171875,
            -0.016333559527993202
        ],
        [
            0.002819814020767808,
            -0.020006271079182625,
            0.01802065409719944,
            -0.008113057352602482,
            0.0019759994465857744,
            -0.038521040230989456,
            -0.009607555344700813,
            -0.0056932768784463406,
            -0.0007361072348430753,
            0.000543507921975106,
            0.030938351526856422,
            -0.02686201222240925,
            0.014281381852924824,
            -0.032720908522605896,
            0.022303635254502296,
            0.018882647156715393,
            0.010806496255099773,
            0.016781775280833244,
            -0.010674036107957363,
            -0.029003296047449112,
            0.014228778891265392,
            -0.015726083889603615,
            -0.04601415619254112,
            0.004446766339242458,
            -0.007766702212393284,
            0.011464878916740417,
            -0.023785971105098724,
            0.02389095537364483,
            -0.008648731745779514,
            0.008910940028727055,
            -0.0016157578211277723,
            0.03048880584537983,
            -0.024106178432703018,
            -0.01389599870890379,
            -0.010133315809071064,
            0.004426485393196344,
            0.008055324666202068,
            0.014516972005367279,
            -0.0037005399353802204,
            0.027338553220033646,
            0.0016305868048220873,
            0.03596305102109909,
            -0.004716231022030115,
            0.022633567452430725,
            -0.004075902979820967,
            0.03288613259792328,
            -0.018068335950374603,
            -0.017396805807948112,
            -0.006190708372741938,
            -0.036778245121240616,
            -0.007820108905434608,
            -0.016837652772665024,
            0.02196044847369194,
            0.03619666397571564,
            0.07293158769607544,
            0.023487212136387825,
            -0.0392366424202919,
            -0.02369200810790062,
            0.03343585878610611,
            0.015204346738755703,
            -0.02064863219857216,
            -0.02333150804042816,
            0.025773389264941216,
            -0.023248061537742615,
            0.024073773995041847,
            -0.01885097846388817,
            0.005384607240557671,
            -0.007539575919508934,
            -0.025744901970028877,
            -0.022257456555962563,
            0.008947891183197498,
            -0.0012854933738708496,
            -0.019829124212265015,
            -0.02162955142557621,
            -0.016440702602267265,
            -0.007111880462616682,
            -0.03420325368642807,
            0.007653407286852598,
            0.012533418834209442,
            -0.03604579344391823,
            -0.018646009266376495,
            0.04866831377148628,
            0.0318337008357048,
            -0.026295268908143044,
            0.031213659793138504,
            -0.038296714425086975,
            0.004515171051025391,
            0.01029989868402481,
            -0.007554140407592058,
            -0.017293108627200127,
            0.03214889019727707,
            -0.023309076204895973,
            0.031202688813209534,
            0.012323598377406597,
            -0.00480740936473012,
            0.032777540385723114,
            -0.008702317252755165,
            -0.016216861084103584,
            -0.024113178253173828,
            0.009786742739379406,
            0.00876699574291706,
            -0.001982835354283452,
            -0.02687157317996025,
            0.028536483645439148,
            0.012198153883218765,
            -0.02890210598707199,
            0.020425302907824516,
            0.007029705215245485,
            -0.028567228466272354,
            -0.017210979014635086,
            -0.008102825842797756,
            0.009964594617486,
            0.02132820524275303,
            0.015374991111457348,
            0.038300152868032455,
            -0.0013899856712669134,
            -0.004846704658120871,
            -0.007203659974038601,
            0.021766021847724915,
            -0.028486328199505806,
            0.022565368562936783,
            0.00600219564512372,
            -0.04399504512548447,
            -0.018292782828211784,
            0.012744583189487457,
            0.006227470934391022,
            0.01562355738133192,
            0.014374339953064919,
            -0.02593064308166504,
            0.013924319297075272,
            0.025431493297219276,
            0.021157292649149895,
            -0.06602318584918976,
            -0.07684415578842163,
            0.006409195717424154,
            -0.010711370967328548,
            0.018919723108410835,
            -0.00962880626320839,
            0.027615854516625404,
            -0.005097332876175642,
            -0.025355590507388115,
            -0.0008088780450634658,
            0.004970303736627102,
            0.017874358221888542,
            -9.086813952308148e-05,
            -0.014919355511665344,
            0.0456446036696434,
            -0.036796972155570984,
            0.02961880899965763,
            -0.00211612437851727,
            0.024077409878373146,
            -0.0030547960195690393,
            0.015067549422383308,
            0.03774639219045639,
            0.025684429332613945,
            0.023496435955166817,
            0.004680674523115158,
            0.005577184725552797,
            0.005312340799719095,
            -0.06266554445028305,
            0.029693584889173508,
            -0.014007151126861572,
            0.001791519345715642,
            -0.00749718165025115,
            -0.010795478709042072,
            -0.04201342165470123,
            0.004431467037647963,
            -0.004582799971103668,
            0.02404741570353508,
            -0.010357223451137543,
            -0.0016419636085629463,
            -0.00661642849445343,
            0.04897208511829376,
            0.005531975533813238,
            0.00011836332123493776,
            -0.016509173437952995,
            -0.008484907448291779,
            -0.010852170176804066,
            0.011845351196825504,
            0.01890772022306919,
            -0.035524483770132065,
            0.02710754983127117,
            -0.018750328570604324,
            -0.011698790825903416,
            0.012150799855589867,
            -0.0004002059285994619,
            0.015624409541487694,
            0.0061713638715445995,
            0.04026484861969948,
            -0.013684081844985485,
            0.014419488608837128,
            0.00859566405415535,
            -0.013087768107652664,
            -0.013867948204278946,
            -0.015361160039901733,
            -0.009125868789851665,
            -0.022211579605937004,
            0.017941545695066452,
            0.010364591144025326,
            -0.005982642527669668,
            0.013471623882651329,
            -0.008606710471212864,
            0.0239240899682045,
            0.010406284593045712,
            -0.026359455659985542,
            0.03244123235344887,
            -0.006884646601974964,
            -0.0328170545399189,
            -0.03278743475675583,
            -0.011159117333590984,
            -0.02069159224629402,
            -0.000638154218904674,
            -0.023895442485809326,
            0.02131563611328602,
            0.014222520403563976,
            -0.015191767364740372,
            -0.013350648805499077,
            0.01479540765285492,
            -0.0017221771413460374,
            -0.0030150481034070253,
            0.02000977098941803,
            0.06003858149051666,
            0.029818551614880562,
            0.008032195270061493,
            -0.013494525104761124,
            -0.04575926810503006,
            0.02616644650697708,
            -0.008764071390032768,
            -0.012306742370128632,
            -0.020232927054166794,
            0.017967525869607925,
            0.028241293504834175,
            -0.021680455654859543,
            0.01906193047761917,
            -0.013316946104168892,
            0.020987214520573616,
            -0.010146130807697773,
            0.03311742842197418,
            -0.006371581926941872,
            -0.0295688696205616,
            -0.016684476286172867,
            -0.006473148707300425,
            0.00217652739956975,
            -0.027763307094573975,
            -0.010913833044469357,
            0.016148647293448448,
            0.014362694695591927,
            -0.003951604478061199,
            -0.012427806854248047,
            -0.000842099660076201,
            0.005423503927886486,
            0.0308232344686985,
            -0.029931409284472466,
            0.015870913863182068,
            -0.006228857208043337,
            -0.01771407015621662,
            -0.022975437343120575,
            -0.013339200057089329,
            -0.0015457198023796082,
            -0.034354325383901596,
            -0.02086450159549713,
            -0.021118752658367157,
            0.04197843000292778,
            -0.030068447813391685,
            -0.005610095802694559,
            0.007283080834895372,
            0.0266957376152277,
            -0.029337918385863304,
            -0.007016116287559271,
            0.02885531075298786,
            0.01854880340397358,
            -0.0017051348695531487,
            0.03138650208711624,
            -0.017076876014471054,
            0.0520428828895092,
            -0.022080162540078163,
            0.044609636068344116,
            0.033918093889951706,
            -0.010952003300189972,
            -0.017618512734770775,
            -0.00797758623957634,
            0.008017828688025475,
            -0.02545124478638172,
            -0.002269834280014038,
            0.015175254084169865,
            0.02841362915933132,
            0.03375060856342316,
            0.00842561200261116,
            0.0005555218085646629,
            0.0315655916929245,
            0.019950512796640396,
            -0.039566200226545334,
            -0.017579013481736183,
            0.04502696916460991,
            -0.00600961409509182,
            0.01037760078907013,
            -0.007302191574126482,
            0.010801617056131363,
            0.04537351056933403,
            -0.0013607508735731244,
            -0.0371059775352478,
            0.003624213393777609,
            -0.00040413433453068137,
            -0.006654550787061453,
            -0.00776646938174963,
            0.020049594342708588,
            -0.021181058138608932,
            0.0438048392534256,
            0.03305990993976593,
            0.021393105387687683,
            0.026990361511707306,
            -0.03442952036857605,
            0.021810714155435562,
            -0.008405573666095734,
            -0.042646024376153946,
            0.02458260767161846,
            -0.010470138862729073,
            0.017586946487426758,
            -0.014619697816669941,
            -0.014598825946450233,
            0.03627926483750343,
            -0.0015745986020192504,
            0.0022071790881454945,
            -0.005826130509376526,
            -0.007935911417007446,
            -0.015551945194602013,
            0.04299374297261238,
            -0.01207451056689024,
            -0.011596362106502056,
            0.02181069366633892,
            0.0005843618419021368,
            -0.007281912956386805,
            -0.022595584392547607,
            -0.005447691306471825,
            -0.031683530658483505,
            -0.008338051848113537,
            0.015724480152130127,
            -0.003804001957178116,
            -0.03476341813802719,
            -0.02182416059076786,
            -0.03244746848940849,
            -0.019056497141718864,
            -0.006231202743947506,
            0.0019066346576437354,
            -0.004516024142503738,
            0.022582950070500374,
            0.01637125574052334,
            0.031139696016907692,
            -0.013133451342582703,
            -0.00047196587547659874,
            -0.01101372204720974,
            0.024262135848402977,
            0.0017958529060706496,
            -0.008126640692353249,
            -0.005679926369339228,
            -0.022118695080280304,
            -0.03188854828476906,
            0.006009141448885202,
            0.012157745659351349,
            -0.013024582527577877,
            0.028742168098688126,
            0.019438177347183228,
            0.024195177480578423,
            -0.01676749251782894,
            -0.013132656924426556,
            -0.030770916491746902,
            0.013960053212940693,
            -0.00016672356287017465,
            -0.0008780775242485106,
            -0.001211264985613525,
            0.017842158675193787,
            0.017067108303308487,
            0.003711592871695757,
            -0.007826903834939003,
            -0.02054380252957344,
            -0.033516980707645416,
            0.018498428165912628,
            -0.024200286716222763,
            -0.018828939646482468,
            0.025739803910255432,
            0.010386460460722446,
            0.012855315580964088,
            0.017617233097553253,
            0.0239020474255085,
            -0.01989995874464512,
            0.006823217496275902,
            -0.02052067406475544,
            0.000833281665109098,
            -0.003576132236048579,
            -0.0006594730075448751,
            -0.02888445183634758,
            -0.02906685136258602,
            -0.016770586371421814,
            -0.01587356999516487,
            0.009535124525427818,
            0.03745611011981964,
            0.016919631510972977,
            0.026864584535360336,
            -0.034581951797008514,
            0.010358171537518501,
            0.0011192105012014508,
            0.027408912777900696,
            0.019596945494413376,
            0.016148248687386513,
            0.006828226149082184,
            0.0050077917985618114,
            0.03633303940296173,
            -0.021919459104537964,
            0.011347413063049316,
            -0.02136736549437046,
            -0.0005861695972271264,
            0.009522875770926476,
            -0.015552402473986149,
            0.02713952586054802,
            0.003691414138302207,
            -0.004228716250509024,
            0.006987910717725754,
            -0.023428242653608322,
            0.021036863327026367,
            -0.03491143882274628,
            0.011886578984558582,
            0.01572527550160885,
            0.007096882909536362,
            0.01636335998773575,
            0.0012159859761595726,
            -0.03382689878344536,
            0.027286168187856674,
            -0.016002096235752106,
            0.0031690665055066347,
            -0.017155446112155914,
            0.03798280283808708,
            0.008041963912546635,
            0.02229475788772106,
            -0.01158757321536541,
            0.00794194545596838,
            0.061764057725667953,
            0.0024638844188302755,
            -0.011652509681880474,
            -0.009239503182470798,
            0.02716691978275776,
            -0.01849519833922386,
            -0.019374165683984756,
            0.012147512286901474,
            -0.06183576583862305,
            -0.02590600773692131,
            -0.004234962165355682,
            -0.02069837413728237,
            -0.02377289906144142,
            0.017406214028596878,
            -0.0418863445520401,
            0.005730437580496073,
            0.016197893768548965,
            0.018461070954799652,
            0.001297028735280037,
            -0.0025548932608217,
            -0.024930603802204132,
            0.03924119099974632,
            0.03322048857808113,
            0.024998286738991737,
            0.0015370812034234405,
            0.014926658943295479,
            0.014955947175621986,
            0.004520976450294256,
            0.025029543787240982,
            0.027025680989027023,
            0.021649450063705444,
            -0.02690424583852291,
            0.0618622750043869,
            -0.008849122561514378,
            -0.033390432596206665,
            -0.02580048330128193,
            -0.007036141585558653,
            0.013031798414885998,
            -0.002820331370458007,
            -0.004119454883038998,
            0.04911879450082779,
            -0.0066741034388542175,
            0.01769864372909069,
            -0.0030682459473609924,
            0.011713793501257896,
            0.02538701705634594,
            -0.0072355554439127445,
            -0.01757797785103321,
            0.029070617631077766,
            0.02009231597185135,
            0.001366746029816568,
            -0.030716968700289726,
            0.013100185431540012,
            -0.08124960958957672,
            0.015597366727888584,
            0.04347366839647293,
            0.01825207658112049,
            0.027882972732186317,
            0.03544235974550247,
            0.021123450249433517,
            -0.023327896371483803,
            0.016742777079343796,
            0.028319044038653374,
            0.01856328547000885,
            0.019649287685751915,
            0.023298773914575577,
            0.0022165486589074135,
            0.003989463206380606,
            0.016933901235461235,
            0.029464473947882652,
            0.017133047804236412,
            -0.026382073760032654,
            0.023680908605456352,
            0.02189311385154724,
            0.024602010846138,
            0.0013312726514413953,
            0.039582762867212296,
            -0.013280448503792286,
            0.014309117570519447,
            0.011109564453363419,
            -0.032652392983436584,
            0.004907845053821802,
            -0.022905196994543076,
            -0.02617940865457058,
            -0.01574275642633438,
            0.009805253706872463,
            0.01351997908204794,
            0.00965527631342411,
            0.0018749975133687258,
            0.021839704364538193,
            0.008893066085875034,
            0.030319146811962128,
            0.007051107939332724,
            0.03546985611319542,
            0.030175823718309402,
            0.010123859159648418,
            0.02871319279074669,
            0.015712758526206017,
            0.035472072660923004,
            0.03295883908867836,
            0.022520000115036964,
            0.022386601194739342,
            -0.028640063479542732,
            0.008758781477808952,
            0.020598506554961205,
            -0.03258615359663963,
            0.011207248084247112,
            0.0283106230199337,
            -0.010419245809316635,
            -0.03426823392510414,
            0.035452742129564285,
            0.00427614850923419,
            -0.03143766149878502,
            0.002704351209104061,
            0.0017293444834649563,
            0.001624461729079485,
            0.012257816269993782,
            0.02094525471329689,
            -0.02938472107052803,
            -0.002169032348319888,
            -0.006911443080753088,
            0.02193501591682434,
            0.03595292568206787,
            0.02225591242313385,
            -0.00533458823338151,
            0.025039436295628548,
            0.010286497883498669,
            -0.01516446191817522,
            -0.003055982757359743,
            0.006777255795896053,
            0.0033624519128352404,
            0.02002817392349243,
            0.04059009253978729,
            -0.028666622936725616,
            0.023980066180229187,
            0.007384684402495623,
            0.001649817917495966,
            -0.009553533047437668,
            -0.018557511270046234,
            -0.0039262534119188786,
            0.034107424318790436,
            0.02430187352001667,
            0.02232625149190426,
            0.023048095405101776,
            -0.0159183070063591,
            0.01624377816915512,
            -0.04992663487792015,
            -0.024294434115290642,
            -0.015789726749062538,
            -0.02578084170818329,
            0.03140958026051521,
            0.032500315457582474,
            -0.008806750178337097,
            0.03265753760933876,
            -0.026698142290115356,
            0.030161067843437195,
            -0.028605369850993156,
            0.007440331857651472,
            -0.016561008989810944,
            0.005869985092431307,
            0.03345963731408119,
            0.048887308686971664,
            -0.013643305748701096,
            0.004327685106545687,
            0.05348166823387146,
            0.029211895540356636,
            0.0010338092688471079,
            0.030049653723835945,
            0.02565576881170273,
            0.012997927144169807,
            -0.0013453169958665967,
            -0.001831810805015266,
            -0.03255549073219299,
            0.010783631354570389,
            -0.027004528790712357,
            0.023541592061519623,
            -0.02077418379485607,
            -0.015332900919020176,
            -0.00675729988142848,
            -0.05146455764770508,
            -0.0006115620490163565,
            -0.0025502878706902266,
            0.005510139744728804,
            -0.02503015100955963,
            -0.010314030572772026,
            -0.00559203838929534,
            0.011204508133232594,
            -0.01418532058596611,
            0.025044582784175873,
            -0.005447247065603733,
            0.010310511104762554,
            -0.03165506571531296,
            0.029809923842549324,
            -0.001247858046554029,
            0.03881817311048508,
            0.034952085465192795,
            -0.025485293939709663,
            -0.020325442776083946,
            0.0019844642374664545,
            0.043603017926216125,
            0.00013866119843441993,
            0.0429961122572422,
            0.010160627774894238,
            0.024717027321457863,
            -0.029943693429231644,
            0.04547302797436714,
            0.008168538101017475,
            -0.03468451276421547,
            0.03830329701304436,
            -0.01601354219019413,
            -0.0152185820043087,
            -0.026461118832230568,
            0.016019903123378754,
            -0.01031551230698824,
            -0.0024378199595957994,
            -0.0041073695756495,
            0.014190461486577988,
            0.029955213889479637,
            0.013837945647537708,
            0.015413886867463589,
            0.029217060655355453,
            0.03306691348552704,
            0.006731719709932804,
            -0.009976733475923538,
            -0.015044362284243107,
            -0.019035108387470245,
            -0.004018647596240044,
            -0.011756863445043564,
            0.028310492634773254,
            0.008683616295456886,
            -0.03692484274506569,
            0.025906933471560478,
            0.012566638179123402,
            -0.017017222940921783,
            -0.015118318609893322,
            0.0393037423491478,
            0.033896081149578094,
            0.020964812487363815,
            0.04386148974299431,
            0.005323680583387613,
            0.022749587893486023,
            0.01280267909169197,
            0.011282065883278847,
            0.011257884092628956,
            0.03232378512620926,
            0.04773833975195885,
            0.0060568093322217464,
            -0.02703956700861454,
            -0.0038521597161889076,
            0.027060616761446,
            -0.01096044760197401,
            0.036001965403556824,
            -0.04139366000890732,
            -0.0005467084120027721,
            0.013303659856319427,
            0.03205743804574013,
            -0.004077147226780653,
            -0.011631758883595467,
            -0.004201734438538551,
            -0.026690328493714333,
            0.014342540875077248,
            -0.015396827831864357,
            0.023208357393741608,
            0.015546283684670925,
            -0.003959799651056528,
            -0.019079122692346573,
            -0.007033430505543947,
            0.025619840249419212,
            -0.027774428948760033,
            0.00842985324561596,
            -0.025718141347169876,
            0.003965963143855333,
            0.029788948595523834,
            -0.04303359240293503,
            0.015825044363737106,
            0.01344072911888361,
            -0.004415885079652071,
            0.034764911979436874,
            -0.004582047928124666,
            -0.007512148004025221,
            -0.0229007750749588,
            -0.025583717972040176,
            0.04401647672057152,
            0.012042555026710033,
            0.01799064502120018,
            0.008290814235806465,
            0.033542122691869736,
            0.03525848686695099,
            0.03318726643919945,
            -0.02555747888982296,
            -0.01599651388823986,
            -0.014545590616762638,
            -0.017282545566558838,
            0.027152597904205322,
            0.02031267061829567,
            0.004575046245008707,
            0.0065970346331596375,
            -0.04424819350242615,
            -0.018457159399986267,
            -0.00871821865439415,
            -0.0073859491385519505,
            -0.027850192040205002,
            -0.02081449143588543,
            0.002097279066219926,
            0.005865930113941431,
            -0.03511100262403488,
            0.00941791757941246,
            -0.028801586478948593,
            0.04503018036484718,
            -0.002413070760667324,
            -0.03630458191037178,
            -0.02722620591521263,
            0.030966658145189285,
            -0.022918296977877617,
            -0.007909851148724556,
            -0.019892511889338493,
            0.022925816476345062,
            0.029109062626957893,
            0.009837926365435123,
            -0.021742045879364014,
            -0.029629750177264214,
            0.005329773761332035,
            0.014689251780509949,
            -0.029552055522799492,
            -0.017454050481319427,
            0.02276446856558323,
            -0.031216386705636978,
            7.962391100591049e-05,
            -0.0316326841711998,
            -0.009911704808473587,
            -0.0010439861798658967,
            0.0002296318853041157,
            -0.020415252074599266,
            -0.02560338005423546,
            -0.0027548756916075945,
            0.0006398703553713858,
            0.01528689730912447,
            -0.013296232558786869,
            0.0066144103184342384,
            0.031044812873005867,
            -0.0004170046013314277,
            -0.018874548375606537,
            -0.00012748813605867326,
            0.02702837623655796,
            -0.00551422405987978,
            0.00558628560975194,
            0.0007521745865233243,
            0.0018817911623045802,
            0.0051927645690739155,
            0.01710263267159462,
            -0.01210060715675354,
            -0.006586950737982988,
            0.017148595303297043,
            0.0038158914539963007,
            -0.007037080358713865,
            0.03574514016509056,
            0.002245582640171051,
            0.024296894669532776,
            -0.004155161324888468,
            -0.010956165380775928,
            0.010455082170665264,
            -0.03127465397119522,
            -0.022646833211183548,
            -0.01644551381468773,
            -0.009033731184899807,
            -0.05243993550539017,
            -0.0005558336270041764,
            -0.004893931560218334,
            0.0028732612263411283,
            -0.017270049080252647,
            -0.0008999626152217388,
            -0.007534177973866463,
            0.016745101660490036,
            -0.03651086241006851,
            -0.014765575528144836,
            0.030873669311404228,
            -0.03587315231561661,
            0.0005262072081677616,
            -0.016870513558387756,
            -0.003215630305930972,
            -0.016196804121136665,
            -0.002996525028720498,
            -0.0042403098195791245,
            -0.0033440212719142437,
            0.01457799132913351,
            0.024878649041056633,
            -0.002996284980326891,
            0.01679067499935627,
            0.006178856361657381,
            0.031707096844911575,
            -0.0013407738879323006,
            -0.025424808263778687,
            -0.02131950668990612,
            -0.005356477573513985,
            -0.007759289350360632,
            0.018457001075148582,
            0.01479981280863285,
            0.04007011279463768,
            -0.004331226926296949,
            0.03585472330451012,
            -0.010669039562344551,
            -0.0065910471603274345,
            0.015779225155711174,
            0.025628047063946724,
            0.024690283462405205,
            0.00908507127314806,
            0.006507779937237501,
            0.039820872247219086,
            0.010340720415115356,
            0.013315360061824322,
            0.011048694141209126,
            0.0010832163970917463,
            0.030620316043496132,
            -0.03996758535504341,
            -0.027056947350502014,
            -0.012775921262800694,
            0.01109897531569004,
            0.03395753353834152,
            0.028839539736509323,
            -0.009987210854887962,
            -0.014222540892660618,
            0.011636053211987019,
            0.009997926652431488,
            -0.023849667981266975,
            -0.0079344492405653,
            -0.000220537738641724,
            0.01860954612493515,
            0.012490970082581043,
            -0.02144499495625496,
            0.014726356603205204,
            0.03199555724859238,
            -0.004729245789349079,
            0.03606231510639191,
            -0.011833138763904572,
            0.013184738345444202,
            0.024837065488100052,
            -0.04226118326187134,
            0.05385033041238785,
            -0.004922421649098396,
            -0.02031739428639412,
            -0.006524732336401939,
            -0.006617821287363768,
            0.05814226344227791,
            0.02406315878033638,
            0.012417221441864967,
            0.008827785961329937,
            -0.01744125969707966,
            -0.01757410168647766,
            0.018896421417593956,
            -0.022565020248293877,
            -0.0008432540926150978,
            -0.026565155014395714,
            -0.030996078625321388,
            -0.02936207689344883,
            0.01846683956682682,
            0.0007066088728606701,
            -0.02153661474585533,
            0.02542608231306076,
            0.007749078795313835,
            -0.004714496433734894,
            -0.024935832247138023,
            0.020886849611997604,
            -0.04102253168821335,
            0.02786080725491047,
            0.011004908010363579,
            0.010308707132935524,
            -0.024203089997172356,
            -0.006202889606356621,
            0.020736949518322945,
            0.048472221940755844,
            -0.04974471777677536,
            -0.03002280183136463,
            -0.006652609445154667,
            0.03110974282026291,
            -0.019567446783185005,
            0.0041850050911307335,
            -0.013416243717074394,
            0.010293122380971909,
            0.02027185447514057,
            -0.037257857620716095,
            -0.018962018191814423,
            -0.01211361400783062,
            0.019174203276634216,
            -0.021384136751294136,
            0.022394580766558647,
            0.0041738576255738735,
            -0.006759284064173698,
            0.033125847578048706,
            0.004480001050978899,
            -0.022182796150445938,
            0.03872881829738617,
            -0.0010678813559934497,
            -0.014884558506309986,
            -0.0036951624788343906,
            -0.014468830078840256,
            -0.010621418245136738,
            0.011639827862381935,
            0.003846224630251527,
            0.002112818183377385,
            0.036614447832107544,
            -0.006155364215373993,
            -0.005676899570971727,
            -0.013304678723216057,
            -0.015177539549767971,
            -0.002422973746433854,
            -0.03174227848649025,
            -0.04487278684973717,
            0.017526663839817047,
            0.03924275562167168,
            -0.04305262118577957,
            0.038409724831581116,
            0.04512084275484085,
            0.0033497228287160397,
            -0.012521550059318542,
            0.0008132960647344589,
            0.003133879043161869,
            -0.01723347418010235,
            0.004126228392124176,
            0.0054864115081727505,
            0.0017185008618980646,
            0.01241716556251049,
            -0.019116487354040146,
            0.0127848070114851,
            -0.008119351230561733,
            -0.0009868036722764373,
            0.010634314268827438,
            -0.012940608896315098,
            0.017599785700440407,
            0.042163703590631485,
            0.013256175443530083,
            0.0343925841152668,
            -0.022859884425997734,
            -0.021102910861372948,
            0.0077612558379769325,
            0.031284719705581665,
            0.02246144786477089,
            0.01288027036935091,
            0.01814616285264492,
            -0.001353363855741918,
            -0.004495760891586542,
            0.016961760818958282,
            -0.06782743334770203,
            0.019900420680642128,
            0.02354784682393074,
            -0.01979256421327591,
            -0.02819485403597355,
            -0.010460307821631432,
            -0.007074047811329365,
            0.02130878157913685,
            -0.04358914494514465,
            -0.02678656205534935,
            -0.040849003940820694,
            -0.016934923827648163,
            0.02128385752439499,
            -0.004391380585730076,
            0.008635642938315868,
            0.012785329483449459,
            0.007613599766045809,
            0.01118478924036026,
            0.03221942484378815,
            -0.016788341104984283,
            -0.013806977309286594,
            0.03159742057323456,
            -0.010808016173541546,
            -0.02319895103573799,
            0.0023235331755131483,
            -0.00011451052705524489,
            -0.01686946675181389,
            -0.00757932523265481,
            -0.03272979333996773,
            0.03997865319252014,
            0.004412677139043808,
            0.03400901332497597,
            -0.027904147282242775,
            0.007276124786585569,
            0.024411482736468315,
            -0.0027205480728298426,
            -0.03504597768187523,
            0.018496161326766014,
            -0.001529571134597063,
            0.014865661039948463,
            -0.005963359959423542,
            -7.724863826297224e-05,
            -0.019205234944820404,
            0.007221036124974489,
            0.011004320345818996,
            -0.03665159270167351,
            0.035813990980386734,
            0.023577142506837845
        ],
        [
            0.002869383664801717,
            -0.002555666957050562,
            -0.022131040692329407,
            -0.017919013276696205,
            0.011529386974871159,
            -0.03121449425816536,
            -0.032159388065338135,
            0.004094304051250219,
            0.011365924030542374,
            0.030054882168769836,
            -0.0328800342977047,
            0.03328557685017586,
            0.03159010410308838,
            0.027420755475759506,
            0.011197283864021301,
            -0.0334942489862442,
            0.00509239686653018,
            -0.012180663645267487,
            -0.004084283486008644,
            -0.005445276387035847,
            0.015244533307850361,
            -0.015684161335229874,
            0.005539946723729372,
            0.019796473905444145,
            -0.015958156436681747,
            0.0293736569583416,
            -0.031069401651620865,
            -0.014537001959979534,
            0.02311164326965809,
            0.04666963964700699,
            -0.026180880144238472,
            0.009905637241899967,
            0.026716791093349457,
            -0.006035529542714357,
            0.006000538822263479,
            0.008615246042609215,
            -0.022289864718914032,
            -0.02248065173625946,
            -0.02911784127354622,
            -0.02227148786187172,
            -0.033944547176361084,
            0.03540324047207832,
            0.01380934752523899,
            0.007824437692761421,
            -0.0013590301387012005,
            -0.032714348286390305,
            0.0051514385268092155,
            0.008843849413096905,
            0.010792938061058521,
            -0.05410271883010864,
            -0.013709518127143383,
            0.02610204555094242,
            0.014508089981973171,
            0.025299744680523872,
            -0.04771856218576431,
            -0.03919460251927376,
            0.01718762144446373,
            0.02737918309867382,
            0.0033475880045443773,
            0.029327580705285072,
            0.006822855211794376,
            -0.010256298817694187,
            0.016395987942814827,
            0.03188953548669815,
            0.021873438730835915,
            -0.02958729676902294,
            0.014486340805888176,
            0.02858452871441841,
            -0.019957013428211212,
            -0.013772852718830109,
            0.013962586410343647,
            -0.0034199515357613564,
            0.021210692822933197,
            0.026319019496440887,
            -0.007235769648104906,
            0.00547740189358592,
            -0.0193773340433836,
            0.038583025336265564,
            0.021147944033145905,
            0.010465724393725395,
            0.0207756906747818,
            -0.05197565630078316,
            -0.01725880429148674,
            0.018161064013838768,
            0.012816818431019783,
            0.03430278226733208,
            -0.005874258000403643,
            0.026313338428735733,
            0.0049438863061368465,
            0.017496224492788315,
            -0.01419959869235754,
            0.01430924329906702,
            0.013582593761384487,
            0.02163054421544075,
            0.01178235374391079,
            -0.008093724027276039,
            -0.027086220681667328,
            -0.020194951444864273,
            -0.050524961203336716,
            0.03189369663596153,
            0.006712623406201601,
            0.06882394105195999,
            0.026140136644244194,
            -0.01228969544172287,
            -0.0008689866517670453,
            0.01386214978992939,
            -4.30547479481902e-05,
            0.008631929755210876,
            0.03805696964263916,
            0.016906583681702614,
            -0.02978912740945816,
            0.0024407573509961367,
            -0.035134512931108475,
            0.015905987471342087,
            0.02114216983318329,
            0.01974724419414997,
            0.01312633603811264,
            0.028077419847249985,
            -0.044926129281520844,
            -0.00944062415510416,
            0.004325465299189091,
            0.017624475061893463,
            -0.005938220303505659,
            -0.03181387111544609,
            0.013376184739172459,
            0.036571331322193146,
            -0.02082483097910881,
            -0.012985775247216225,
            -0.010840597562491894,
            -0.021188892424106598,
            0.005068688187748194,
            -0.035699162632226944,
            0.011956499889492989,
            -0.06087426096200943,
            0.000871812691912055,
            0.022175680845975876,
            0.015360371209681034,
            -0.011711443774402142,
            -0.004320372827351093,
            -0.0036646516527980566,
            -0.01833864115178585,
            0.0044759176671504974,
            0.0264887735247612,
            0.02897600829601288,
            -0.024956243112683296,
            -0.005377465859055519,
            0.006566235795617104,
            0.014368659816682339,
            0.019918859004974365,
            -0.007455445826053619,
            0.025195471942424774,
            0.038981515914201736,
            0.03294830024242401,
            0.024140557274222374,
            -0.020663797855377197,
            -0.017267601564526558,
            -0.02134609781205654,
            0.00655324524268508,
            0.006500015035271645,
            0.03104543313384056,
            0.028578834608197212,
            -0.019721902906894684,
            0.010654577985405922,
            0.02767067216336727,
            0.00868042279034853,
            0.041010111570358276,
            0.0210264939814806,
            0.0022922938223928213,
            0.028952568769454956,
            -0.027258126065135002,
            -0.005511533468961716,
            -0.005395290907472372,
            -0.014769655652344227,
            0.029779473319649696,
            -0.010883723385632038,
            0.013288377784192562,
            0.00229293922893703,
            -0.0007720033754594624,
            0.004042453598231077,
            0.0504322350025177,
            -0.019975950941443443,
            0.0070825801230967045,
            -0.005776406265795231,
            -0.02591749280691147,
            0.00788907054811716,
            0.018122168257832527,
            -0.009561577811837196,
            0.026879876852035522,
            -0.028143513947725296,
            -0.014863911084830761,
            -0.027729816734790802,
            0.011423405259847641,
            -0.018968725576996803,
            -0.022338462993502617,
            0.028299761936068535,
            0.016298314556479454,
            0.019358180463314056,
            0.0035538470838218927,
            -0.025262031704187393,
            -0.015219233930110931,
            0.009637605398893356,
            -0.00021305143309291452,
            0.038504939526319504,
            -0.03261711075901985,
            -0.0007960261427797377,
            -0.014052744023501873,
            -0.023972222581505775,
            0.007226430345326662,
            -0.02866499312222004,
            0.017432548105716705,
            0.021217405796051025,
            -0.024135049432516098,
            -0.019686833024024963,
            -0.016999654471874237,
            0.002659372752532363,
            0.007085894234478474,
            0.009234379976987839,
            -0.012710455805063248,
            0.01617613062262535,
            -0.004757820628583431,
            -0.02101508155465126,
            0.009029650129377842,
            -0.03395935893058777,
            0.029849445447325706,
            0.03467564657330513,
            0.07558762282133102,
            0.030534489080309868,
            0.0069942171685397625,
            -0.0018132557161152363,
            0.016813063994050026,
            -0.040916405618190765,
            -0.004158427938818932,
            0.01298016868531704,
            -0.029959823936223984,
            -0.023719049990177155,
            0.009612235240638256,
            0.018265536054968834,
            0.016076475381851196,
            -0.016031380742788315,
            -0.015033983625471592,
            0.00032508655567653477,
            -0.01287081092596054,
            -0.02780570462346077,
            -0.011214172467589378,
            0.003740804735571146,
            -0.02486746944487095,
            -0.003714021760970354,
            0.003408413613215089,
            -0.024116095155477524,
            0.016698453575372696,
            -0.005575033836066723,
            0.020086953416466713,
            -0.02551061101257801,
            0.01988029107451439,
            0.029489224776625633,
            -0.021415090188384056,
            0.016374658793210983,
            -0.03429052606225014,
            0.009159748442471027,
            0.014879030175507069,
            0.03412781283259392,
            -0.017539246007800102,
            0.025528492406010628,
            -0.03340975195169449,
            -0.0018302351236343384,
            -0.030025597661733627,
            0.022895317524671555,
            0.004186189733445644,
            0.019961584359407425,
            -0.023748857900500298,
            0.0013796745333820581,
            0.03432638198137283,
            -0.01623344048857689,
            -0.00848446600139141,
            0.024212157353758812,
            0.01886688359081745,
            -0.032205600291490555,
            -0.02359783463180065,
            -0.02354895882308483,
            -0.012388408184051514,
            0.018220311030745506,
            0.049882423132658005,
            -0.002095731906592846,
            -0.007623958867043257,
            0.023403840139508247,
            -0.012478057295084,
            0.011696123518049717,
            0.018663465976715088,
            -0.012109003961086273,
            0.019691716879606247,
            0.022275760769844055,
            -0.007678624242544174,
            0.014925920404493809,
            0.01215680968016386,
            0.0015230466378852725,
            0.011869226582348347,
            0.003789556911215186,
            -0.008801795542240143,
            -0.0427919402718544,
            0.01253914088010788,
            -0.01614665985107422,
            0.04057380557060242,
            0.019708583131432533,
            -0.024112295359373093,
            -0.02541295811533928,
            0.02653173729777336,
            -0.011923529207706451,
            0.03428565710783005,
            -0.044905245304107666,
            0.02010122872889042,
            -0.0005202114698477089,
            -0.02692265808582306,
            -0.02121046371757984,
            -0.017672330141067505,
            -0.024919824674725533,
            -0.009022349491715431,
            -0.011952961795032024,
            -0.019581418484449387,
            -0.0124299805611372,
            0.001577559974975884,
            0.01357399020344019,
            0.041185468435287476,
            0.0022747640032321215,
            -0.009710571728646755,
            -0.024508528411388397,
            -0.006733447313308716,
            -0.01801352947950363,
            -0.023625940084457397,
            0.004004788119345903,
            -0.0010701046558097005,
            -0.03748885542154312,
            0.01323974970728159,
            0.002753522479906678,
            -0.0051985979080200195,
            -0.00843789428472519,
            -0.015483113005757332,
            0.02044610306620598,
            0.021512404084205627,
            0.0044097681529819965,
            -0.02854854427278042,
            0.027949174866080284,
            0.010123890824615955,
            0.024220634251832962,
            0.015487500466406345,
            0.011329351924359798,
            -0.020344505086541176,
            -0.024848492816090584,
            -0.023035552352666855,
            -0.00018007593462243676,
            0.005344985984265804,
            -0.011724919080734253,
            0.04365607351064682,
            -0.015163118951022625,
            0.022310195490717888,
            0.007570009678602219,
            -0.030449794605374336,
            0.004356629215180874,
            -0.002593269571661949,
            0.00934490654617548,
            0.02478109486401081,
            0.016255630180239677,
            -0.008467648178339005,
            0.003256260883063078,
            -0.04078366607427597,
            -0.02825530618429184,
            -0.026207685470581055,
            0.025632264092564583,
            0.02709001861512661,
            0.029264824464917183,
            0.017360949888825417,
            0.021139094606041908,
            0.023813579231500626,
            -0.016004757955670357,
            -0.0067915357649326324,
            0.0006795316585339606,
            0.032387878745794296,
            -0.0339387021958828,
            -0.028070801869034767,
            -0.059460267424583435,
            0.0028415718115866184,
            0.0061449212953448296,
            0.02855556085705757,
            0.0053704651072621346,
            0.006326555740088224,
            -0.027600599452853203,
            -0.019681503996253014,
            -0.014356234110891819,
            -0.06551807373762131,
            -0.0021882213186472654,
            0.020099908113479614,
            -0.001111980527639389,
            0.01851777173578739,
            -0.024111861363053322,
            0.0011635207338258624,
            0.041192397475242615,
            0.01991374045610428,
            0.012276501394808292,
            0.00765023659914732,
            0.0042095184326171875,
            0.010391946882009506,
            0.02214670367538929,
            -0.0012664556270465255,
            -0.0011719026369974017,
            -0.016716599464416504,
            -0.017946967855095863,
            0.033928997814655304,
            0.02707526460289955,
            -0.009604942053556442,
            0.0162521842867136,
            0.01565917208790779,
            0.019696636125445366,
            0.011622835882008076,
            0.012362702749669552,
            0.03297526389360428,
            0.013178168796002865,
            0.002839731052517891,
            -0.012910422869026661,
            0.01821255497634411,
            0.009633772075176239,
            0.02631007507443428,
            -0.06146543100476265,
            0.016771048307418823,
            -0.023077331483364105,
            0.01190380472689867,
            -0.016095679253339767,
            -0.047121044248342514,
            0.020343927666544914,
            -0.004238237161189318,
            -0.0012896963162347674,
            0.024244187399744987,
            0.04881073907017708,
            -0.026662297546863556,
            -0.025785740464925766,
            -0.049121808260679245,
            0.01739562302827835,
            -0.007999434135854244,
            -0.012021942995488644,
            -0.01268582884222269,
            -0.014019305817782879,
            0.034148287028074265,
            -0.012772991321980953,
            -0.004118928220123053,
            0.005691491067409515,
            0.012464996427297592,
            -0.02343273162841797,
            -0.013435517437756062,
            -0.015259183943271637,
            -0.06796691566705704,
            0.0402764193713665,
            -0.029411805793642998,
            -0.00943063572049141,
            0.014929569326341152,
            -0.017227565869688988,
            -0.029236946254968643,
            0.010197700001299381,
            0.015665117651224136,
            0.02222002111375332,
            0.0011330177076160908,
            0.024425700306892395,
            -0.012949877418577671,
            -0.02393369935452938,
            0.011857480742037296,
            0.03704339638352394,
            0.015872357413172722,
            -0.0030235766898840666,
            0.00976176094263792,
            0.015246600843966007,
            -0.009722619317471981,
            0.01173922698944807,
            -0.019613653421401978,
            0.0363403782248497,
            -0.0328875407576561,
            0.028424179181456566,
            0.05432603135704994,
            -0.005440472159534693,
            -0.03862771764397621,
            0.005757796112447977,
            0.015050585381686687,
            0.025293221697211266,
            -0.02111445553600788,
            0.008879266679286957,
            -0.020812934264540672,
            -0.04242272675037384,
            0.0036830094177275896,
            -0.05522359535098076,
            0.004870305769145489,
            -0.016951657831668854,
            0.014004225842654705,
            -0.06468350440263748,
            0.025531306862831116,
            -0.010711255483329296,
            0.027260519564151764,
            0.03359571844339371,
            0.007102799601852894,
            -0.04539041221141815,
            0.014446813613176346,
            -0.016268515959382057,
            -0.016874263063073158,
            -0.010001865215599537,
            -0.010288666933774948,
            0.018459061160683632,
            0.06454906612634659,
            -0.016328545287251472,
            -0.022541644051671028,
            -0.025475401431322098,
            -0.015269340015947819,
            0.03848196938633919,
            0.02973034977912903,
            -0.0011534917866811156,
            -0.03396524488925934,
            0.017444977536797523,
            -0.01538705825805664,
            0.003233177587389946,
            0.021878933534026146,
            0.006023060530424118,
            0.0032585272565484047,
            -0.003553935559466481,
            0.014677496626973152,
            -0.0016169992741197348,
            0.01402568444609642,
            -0.017176706343889236,
            0.011264286004006863,
            -0.0017725907964631915,
            0.037155117839574814,
            0.008054214529693127,
            -0.02852775529026985,
            0.03739425539970398,
            -0.020304149016737938,
            -0.022474471479654312,
            0.008496035821735859,
            0.013710874132812023,
            0.025680236518383026,
            -0.040089841932058334,
            0.0048459614627063274,
            -0.0056084804236888885,
            -0.003908632323145866,
            0.019898125901818275,
            -0.01724485494196415,
            0.007901884615421295,
            -0.028351644054055214,
            -0.0221552811563015,
            0.02307209186255932,
            -0.007577434182167053,
            0.004689262248575687,
            -0.0011117953108623624,
            0.009908621199429035,
            -0.025573033839464188,
            -0.05833764374256134,
            0.014897420071065426,
            -0.019172590225934982,
            -0.015802985057234764,
            0.027921264991164207,
            -0.016492096707224846,
            -0.008486286737024784,
            0.004187093116343021,
            -0.024007366970181465,
            -0.05292056128382683,
            -0.01794648915529251,
            -0.031117796897888184,
            0.01729874685406685,
            0.0241331048309803,
            -0.011182000860571861,
            0.00794227048754692,
            -0.003873981535434723,
            0.02484697662293911,
            0.007977873086929321,
            0.030540812760591507,
            -0.01938202790915966,
            0.007487595546990633,
            0.02563783898949623,
            0.002267579548060894,
            -0.03575723245739937,
            0.008967443369328976,
            0.029870280995965004,
            0.024609936401247978,
            0.006828102748841047,
            0.012030857615172863,
            -0.023806657642126083,
            -0.010130658745765686,
            -0.0115420026704669,
            -0.0228370800614357,
            -0.0081403199583292,
            0.004555592779070139,
            0.022539932280778885,
            0.03466937318444252,
            -0.0069148484617471695,
            0.019049571827054024,
            0.01777741126716137,
            -0.017329195514321327,
            0.022220419719815254,
            -0.0005890533211641014,
            -0.01495309080928564,
            0.02325623109936714,
            0.015160971321165562,
            -0.0007264327141456306,
            0.014450258575379848,
            -0.017621494829654694,
            0.005990235600620508,
            -0.009746471419930458,
            0.009192895144224167,
            -0.057425908744335175,
            -0.004253862891346216,
            -0.015344264917075634,
            -0.009030818939208984,
            0.005347579717636108,
            -0.012483825907111168,
            -0.024174155667424202,
            0.01540700439363718,
            0.03495940938591957,
            0.0004735897819045931,
            -0.03820422291755676,
            0.012862118892371655,
            0.0015764535637572408,
            0.0025976202450692654,
            0.010595309548079967,
            0.0037833289243280888,
            0.0005013082991354167,
            -0.00161576340906322,
            -0.02071065455675125,
            0.02706960216164589,
            0.007723833434283733,
            -0.010239540599286556,
            -0.023488394916057587,
            -0.00958463829010725,
            0.01987563632428646,
            0.011159895919263363,
            0.027494966983795166,
            0.0055258795619010925,
            0.0007233053911477327,
            0.02596675045788288,
            0.0124789047986269,
            -0.024105779826641083,
            0.03553671017289162,
            0.003287769388407469,
            0.0431658998131752,
            -0.012941701337695122,
            0.02236643061041832,
            -0.003969376441091299,
            0.017933955416083336,
            -0.004778128582984209,
            0.015236474573612213,
            -0.026427999138832092,
            0.0023226779885590076,
            0.03605921193957329,
            0.005758118815720081,
            0.009254097938537598,
            -0.0068408469669520855,
            -0.01841271109879017,
            -0.0016975828912109137,
            0.026206735521554947,
            0.027651691809296608,
            0.02236940711736679,
            -0.010852095670998096,
            -0.024579038843512535,
            -0.003922462463378906,
            0.037012044340372086,
            0.0064845033921301365,
            0.0005180616863071918,
            0.035122815519571304,
            -0.027914514765143394,
            0.010802613571286201,
            0.024753255769610405,
            0.02341775968670845,
            -0.0008038763771764934,
            0.02137908898293972,
            -0.02646283432841301,
            0.0008529989863745868,
            0.000914512318558991,
            -0.025490840896964073,
            -0.02458399161696434,
            0.002494885353371501,
            0.048797424882650375,
            -0.015787970274686813,
            0.031083347275853157,
            0.03379317745566368,
            -0.05829467251896858,
            -0.010041252709925175,
            -0.007775906473398209,
            0.008102848194539547,
            -0.02142888493835926,
            -0.03375566005706787,
            -0.009465808980166912,
            0.026448050513863564,
            0.02332262136042118,
            0.001316610025241971,
            -0.02652612142264843,
            -0.016579005867242813,
            -0.004666581749916077,
            -0.027223804965615273,
            0.0016126465052366257,
            -0.012643154710531235,
            -0.006500974763184786,
            0.021836256608366966,
            0.015871964395046234,
            -0.02226337045431137,
            -0.01230850163847208,
            0.018317678943276405,
            -0.007796315010637045,
            0.016151806339621544,
            -0.010418854653835297,
            -0.017017977312207222,
            0.020174572244286537,
            0.004113488364964724,
            0.027486462146043777,
            -0.005118643864989281,
            0.018635988235473633,
            -0.019691741093993187,
            0.013547185808420181,
            0.010693931020796299,
            0.000960035715252161,
            -0.005725577939301729,
            -0.012209686450660229,
            0.009486421011388302,
            -0.021706152707338333,
            0.017208563163876534,
            0.0038505380507558584,
            -0.0412665419280529,
            -0.003454103134572506,
            -0.0044755167327821255,
            0.015910165384411812,
            0.013004356995224953,
            0.0168488509953022,
            0.034509893506765366,
            0.03465307503938675,
            -0.0031745871528983116,
            0.01943620853126049,
            0.026555703952908516,
            -0.0017324662767350674,
            0.013930542394518852,
            0.01918456144630909,
            0.04112252965569496,
            0.00759332999587059,
            0.009459837339818478,
            0.0232858844101429,
            0.018739473074674606,
            -0.0026839578058570623,
            0.041497282683849335,
            0.021545635536313057,
            -0.03457259014248848,
            -0.01479532103985548,
            0.0008517571841366589,
            -0.0028886976651847363,
            -0.02320864051580429,
            -0.001062519382685423,
            0.011498099192976952,
            -0.01429623644798994,
            0.027419760823249817,
            0.0390470065176487,
            0.025117386132478714,
            0.035587210208177567,
            0.012007404118776321,
            -0.00486834766343236,
            -0.024727463722229004,
            -0.05440114438533783,
            -0.004256496671587229,
            0.003645604709163308,
            0.010857641696929932,
            0.005806132219731808,
            -0.04951712489128113,
            -0.01940314844250679,
            -0.022784970700740814,
            0.006051537115126848,
            -0.02251548506319523,
            -0.012846017256379128,
            -0.011723392643034458,
            0.010725703090429306,
            0.024153629317879677,
            -0.00557284289970994,
            0.01996450126171112,
            -0.02082417719066143,
            -0.007702511269599199,
            -0.01917591318488121,
            -0.010894538834691048,
            0.0012670992873609066,
            0.0009787800954654813,
            -0.009802517481148243,
            0.006318668369203806,
            -0.028251321986317635,
            0.009994604624807835,
            0.03107830323278904,
            -0.017620226368308067,
            -0.022501327097415924,
            0.03242429718375206,
            -0.008327893912792206,
            -0.014380707405507565,
            0.008601874113082886,
            -0.012426740489900112,
            -0.013660511933267117,
            -0.021594351157546043,
            -0.014823042787611485,
            -0.010479152202606201,
            -0.019328629598021507,
            0.01929609850049019,
            -0.03621060773730278,
            0.005040482152253389,
            0.01913386397063732,
            -0.019854679703712463,
            -0.023573124781250954,
            0.0018868051702156663,
            -0.03454403206706047,
            -0.02831868827342987,
            0.0022854104172438383,
            0.0061966595239937305,
            0.00035862578079104424,
            -0.056965794414281845,
            0.01906997710466385,
            -0.025234635919332504,
            -0.01022402010858059,
            -0.02304043620824814,
            0.014473561197519302,
            0.013070080429315567,
            -0.02536768466234207,
            0.011379411444067955,
            0.02953924983739853,
            0.007195678073912859,
            0.015649056062102318,
            0.0230350811034441,
            -0.020985614508390427,
            -0.008607522584497929,
            0.012934928759932518,
            -0.04166244715452194,
            -0.0026566581800580025,
            0.035273000597953796,
            -0.02444401942193508,
            -0.04990949481725693,
            -0.007655917666852474,
            0.020867127925157547,
            -0.0038558479864150286,
            0.01892811246216297,
            -0.021826189011335373,
            0.02321307547390461,
            0.012837332673370838,
            0.03296763077378273,
            0.032355498522520065,
            -0.007157694082707167,
            -0.006483667064458132,
            -0.020382653921842575,
            -0.008891550824046135,
            0.012318483553826809,
            -0.0066812229342758656,
            0.024448776617646217,
            -0.00965231191366911,
            -0.022023377940058708,
            -0.04155050218105316,
            0.0005664446507580578,
            0.0005264742649160326,
            0.015266645699739456,
            -0.039011549204587936,
            -0.037204161286354065,
            -0.020944863557815552,
            0.015459765680134296,
            -0.019599737599492073,
            -0.03637958690524101,
            -0.01393760833889246,
            -0.005847707856446505,
            -0.011182703077793121,
            -0.0020653021056205034,
            -0.011866931803524494,
            0.008590418845415115,
            0.011035487987101078,
            -0.033627260476350784,
            -0.00600265059620142,
            0.05777411907911301,
            0.023431360721588135,
            -0.007250406313687563,
            -0.01991399936378002,
            0.0028674111235886812,
            0.013449758291244507,
            0.0019317419501021504,
            -0.03315899893641472,
            -0.014448643662035465,
            0.019264260306954384,
            -0.022297967225313187,
            -0.0256610419601202,
            0.011646948754787445,
            0.02305636927485466,
            0.020133519545197487,
            -0.04397556930780411,
            0.02662091702222824,
            0.019989827647805214,
            0.02082459069788456,
            0.03167957067489624,
            -0.006667685694992542,
            0.01095952745527029,
            0.016464874148368835,
            0.011687256395816803,
            0.017226072028279305,
            -0.04749036580324173,
            0.014140562154352665,
            -0.022183194756507874,
            -0.021908050402998924,
            -0.0012675588950514793,
            -0.00651344982907176,
            -0.0020530354231595993,
            -0.008370837196707726,
            0.01944013126194477,
            -0.012732045724987984,
            -0.010289429686963558,
            0.0003453395329415798,
            -0.02463126927614212,
            0.050788067281246185,
            0.02466823346912861,
            0.0083751967176795,
            -0.02187647484242916,
            -0.022761410102248192,
            -0.043632593005895615,
            0.025861456990242004,
            -0.04143718630075455,
            0.021551446989178658,
            -0.02156287431716919,
            -0.021398646757006645,
            0.01379886083304882,
            -0.022484151646494865,
            -0.020788418129086494,
            -0.04323995113372803,
            0.00676281563937664,
            -0.0017761144554242492,
            -0.006187576800584793,
            0.010886039584875107,
            -0.018146945163607597,
            0.02019047923386097,
            0.028538351878523827,
            7.925787213025615e-05,
            0.02864946983754635,
            -0.0036456335801631212,
            0.027818378061056137,
            -0.02609281614422798,
            -0.016815602779388428,
            0.009392967447638512,
            -0.02845485880970955,
            0.023612167686223984,
            0.02693694643676281,
            0.02244872972369194,
            0.01702733337879181,
            0.03868575766682625,
            0.006032563280314207,
            -0.030133279040455818,
            -0.03868268057703972,
            0.01598113775253296,
            0.02214644104242325,
            0.0019724476151168346,
            -0.01626586727797985,
            0.01839965209364891,
            -0.020885152742266655,
            -0.05482560396194458,
            -0.011539080180227757,
            0.006171700078994036,
            -0.031044375151395798,
            0.023888858035206795,
            0.005018483381718397,
            0.06136145070195198,
            -0.026253042742609978,
            -0.002713794820010662,
            -0.002399206394329667,
            -0.013266174122691154,
            -0.0025014758575707674,
            0.023574165999889374,
            0.004172195214778185,
            0.022129537537693977,
            -0.01623469963669777,
            -0.0037549787666648626,
            0.01252700760960579,
            0.020824536681175232,
            0.014458217658102512,
            0.03048415668308735,
            0.025202296674251556,
            -0.025575269013643265,
            -0.012269859202206135,
            -0.02433864213526249,
            -0.010707370936870575,
            0.01643313840031624,
            -0.027823390439152718,
            0.0251128152012825,
            0.028683599084615707,
            0.002816230058670044,
            -0.013632162474095821,
            0.004885753616690636,
            0.01894209161400795,
            -0.015849081799387932,
            0.00787136610597372,
            -0.028218822553753853,
            0.0033776515629142523,
            0.021590251475572586,
            0.022960836067795753,
            0.003989847376942635,
            -0.004068014677613974,
            -0.01210781279951334,
            -0.02100464142858982,
            -0.02230403944849968,
            0.008259192109107971,
            -0.018252480775117874,
            -0.020707519724965096,
            -0.023186150938272476,
            0.02053600177168846,
            0.026804961264133453,
            -0.024026483297348022,
            0.02689436264336109,
            -0.016888272017240524,
            -0.017543679103255272,
            0.0020617926493287086,
            -0.019365260377526283,
            -0.024780385196208954,
            0.02990354411303997,
            -0.02421213872730732,
            0.004175946582108736,
            0.024751784279942513,
            0.020115988329052925,
            0.0018741157837212086,
            -0.012274990789592266,
            0.004858887288719416,
            0.024900022894144058,
            -0.025517726317048073,
            -0.013591394759714603,
            -0.009690707549452782,
            -0.006470401771366596,
            0.031682197004556656,
            0.024895820766687393,
            0.0260921660810709,
            -0.006123836617916822,
            0.014382028952240944,
            -0.0040179588831961155,
            -0.07607390731573105,
            -0.005671154707670212,
            -0.020234854891896248
        ],
        [
            0.012163517996668816,
            0.03400309756398201,
            -0.003563063684850931,
            -0.019097650423645973,
            0.010844185948371887,
            0.020754754543304443,
            -0.026792706921696663,
            0.04960142821073532,
            0.018101206049323082,
            0.0051840064115822315,
            -0.034738823771476746,
            -0.039408810436725616,
            -0.002271857811138034,
            -0.011755451560020447,
            0.02395465224981308,
            -0.013111158274114132,
            0.025554900988936424,
            0.002162233693525195,
            -0.02851857617497444,
            -0.013281376101076603,
            -0.007003170903772116,
            -0.011865141801536083,
            0.02102149836719036,
            -0.012984201312065125,
            0.01749321259558201,
            0.03118784911930561,
            -0.030715739354491234,
            -0.017528750002384186,
            0.021617528051137924,
            -0.051947563886642456,
            -0.035437166690826416,
            -0.013043131679296494,
            -0.019760355353355408,
            -0.016942812129855156,
            0.029090875759720802,
            0.030835477635264397,
            0.023586351424455643,
            0.01073674950748682,
            -0.02932833507657051,
            0.014419334009289742,
            -0.017765317112207413,
            0.03928209841251373,
            0.008661750704050064,
            -0.014907991513609886,
            -0.053130414336919785,
            0.0196077860891819,
            0.008919395506381989,
            -0.07223773747682571,
            0.023574726656079292,
            0.03209691494703293,
            0.010857724584639072,
            -0.044201888144016266,
            0.0038091863971203566,
            0.014835299924015999,
            0.02666175365447998,
            0.0031549117993563414,
            -0.021553926169872284,
            0.06156415492296219,
            -0.023251106962561607,
            0.013045433908700943,
            -0.026392417028546333,
            0.028153598308563232,
            -0.015060709789395332,
            -0.01934300735592842,
            0.023846248164772987,
            -0.014447278343141079,
            -0.02315465360879898,
            -0.0036438414826989174,
            -0.0262753926217556,
            -0.00905159953981638,
            -0.023128999397158623,
            -0.009873399510979652,
            0.03696652129292488,
            -0.026763303205370903,
            -0.017032194882631302,
            -0.011209686286747456,
            0.016083765774965286,
            -0.0283554308116436,
            0.028874846175312996,
            0.013089800253510475,
            0.016093691810965538,
            0.019256414845585823,
            0.032311778515577316,
            -0.029864363372325897,
            -0.014044961892068386,
            -0.018401557579636574,
            0.007150538731366396,
            0.017381491139531136,
            -0.043244365602731705,
            0.027077898383140564,
            0.0014565789606422186,
            0.00824020430445671,
            0.017139332368969917,
            -0.02629884146153927,
            -0.003199004102498293,
            0.029651062563061714,
            0.028270209208130836,
            -0.004638046957552433,
            0.04293224588036537,
            -0.026264574378728867,
            -0.023835182189941406,
            -0.0267726369202137,
            -0.039649005979299545,
            -0.025740869343280792,
            0.0064566368237137794,
            -0.011028049513697624,
            -0.01948157139122486,
            0.012019082903862,
            0.019759688526391983,
            -0.019989734515547752,
            -0.022805139422416687,
            0.013880019076168537,
            -0.0320199579000473,
            0.0026637010741978884,
            0.02759982831776142,
            0.008673390373587608,
            0.02957962639629841,
            -0.009586180560290813,
            0.04324852675199509,
            -0.013249698095023632,
            0.007684751879423857,
            -0.02609056606888771,
            0.016244860365986824,
            -0.006215489935129881,
            -0.024520060047507286,
            -0.01977924257516861,
            -0.010882256552577019,
            -0.024849165230989456,
            0.006629586219787598,
            0.022248465567827225,
            0.01821621134877205,
            -0.012301076203584671,
            -0.047365788370370865,
            -0.007042067591100931,
            0.014909637160599232,
            -0.01714184507727623,
            -0.007218074053525925,
            -0.027438245713710785,
            -0.010778707452118397,
            0.02219291776418686,
            0.0010292860679328442,
            -0.016874117776751518,
            0.005539780016988516,
            -0.019214056432247162,
            -0.05358532816171646,
            -0.028382984921336174,
            -0.014714434742927551,
            0.019191205501556396,
            0.0050939335487782955,
            0.02159857377409935,
            0.002746602986007929,
            -0.05950644239783287,
            0.0022877908777445555,
            0.050390418618917465,
            0.01679306849837303,
            0.013275590725243092,
            -0.0023258794099092484,
            0.01705211028456688,
            0.04329686239361763,
            -0.0006228471174836159,
            0.02207730896770954,
            0.022458890452980995,
            0.03399808704853058,
            -0.03347401320934296,
            -0.014196203090250492,
            -0.004940846934914589,
            -0.005114096682518721,
            0.0343409962952137,
            0.048982877284288406,
            -0.019523758441209793,
            0.010448100976645947,
            0.03213745355606079,
            -0.0017819765489548445,
            0.026446714997291565,
            0.011940953321754932,
            0.001158026047050953,
            0.001201969338580966,
            0.024730561301112175,
            -0.01507523749023676,
            -0.017865289002656937,
            -0.008944308385252953,
            -0.0042450204491615295,
            0.06422927975654602,
            0.012761146761476994,
            -0.03656661510467529,
            0.021620597690343857,
            0.009176915511488914,
            0.0017550982302054763,
            -0.008664234541356564,
            -0.0011377590708434582,
            -0.03482647240161896,
            0.01913057453930378,
            -0.03086242638528347,
            -0.01338939368724823,
            0.02014751546084881,
            0.003071630373597145,
            -0.020612582564353943,
            0.024878157302737236,
            0.02102295495569706,
            -0.013643153011798859,
            -0.006106740329414606,
            0.03852824121713638,
            -0.02023584023118019,
            -0.018656030297279358,
            -0.015762783586978912,
            -0.015065179206430912,
            0.0021945249754935503,
            -0.04379763826727867,
            0.015499432571232319,
            0.016259124502539635,
            0.02587440051138401,
            -0.024897856637835503,
            0.007946530357003212,
            0.015052215196192265,
            -0.08209477365016937,
            -0.013233170844614506,
            -0.020941708236932755,
            -0.0019264438888058066,
            0.008439966477453709,
            -0.05800189822912216,
            -0.001681919558905065,
            0.012847044505178928,
            -0.00777986878529191,
            -0.01753329113125801,
            0.023620355874300003,
            -0.0049654655158519745,
            0.0018478557467460632,
            -0.039079345762729645,
            0.035245344042778015,
            0.01942746341228485,
            -0.005497767589986324,
            0.03680756315588951,
            -0.024653127416968346,
            0.019937654957175255,
            -0.022820699959993362,
            0.040500327944755554,
            0.0009922515600919724,
            -0.024823127314448357,
            -0.02127671055495739,
            -0.05298518016934395,
            0.035111840814352036,
            -0.006473017856478691,
            0.009557271376252174,
            -0.00019832565158139914,
            0.01761360839009285,
            -0.008341295644640923,
            -0.025036560371518135,
            -0.01277573499828577,
            0.011306511238217354,
            0.0013692394131794572,
            -0.011900781653821468,
            -0.036055855453014374,
            0.037488069385290146,
            0.025570571422576904,
            0.009056956507265568,
            -0.007802496664226055,
            0.0061180004850029945,
            0.01237579807639122,
            -0.02207561954855919,
            -0.03235267102718353,
            -0.02436293102800846,
            -0.0008978832047432661,
            0.03836778923869133,
            -0.00021862615540158004,
            0.023240327835083008,
            -0.03880063444375992,
            0.017526092007756233,
            0.0007876232266426086,
            0.034147635102272034,
            0.00546700693666935,
            -0.004325788468122482,
            0.0123299490660429,
            -0.00164791161660105,
            0.024524712935090065,
            0.0036365364212542772,
            0.015831466764211655,
            -0.009631204418838024,
            0.00894071999937296,
            0.023875804618000984,
            0.01655956730246544,
            0.014884723350405693,
            -0.002162275603041053,
            -0.0251507218927145,
            0.011234437115490437,
            0.022729959338903427,
            -0.025624534115195274,
            -0.003896943060681224,
            0.06507757306098938,
            0.004456690512597561,
            -0.027297070249915123,
            -0.023404793813824654,
            0.03589414060115814,
            -0.02081487514078617,
            0.026675643399357796,
            0.027977081015706062,
            0.00721068074926734,
            0.01151573471724987,
            0.022801939398050308,
            0.002228489611297846,
            0.007245482876896858,
            0.024452468380331993,
            -0.01694350317120552,
            0.030420107766985893,
            0.007299198303371668,
            0.034159913659095764,
            0.05075560882687569,
            0.01773124933242798,
            0.02175336889922619,
            0.005058784503489733,
            -0.011650154367089272,
            0.03277982026338577,
            0.025539565831422806,
            0.023956773802638054,
            0.010878728702664375,
            -0.021684961393475533,
            0.0023406341206282377,
            -0.037101082503795624,
            0.006233994849026203,
            -0.007178612053394318,
            -0.031679101288318634,
            -0.014776963740587234,
            -0.010349176824092865,
            -0.004340764135122299,
            0.052578363567590714,
            -0.023665517568588257,
            -0.006610853131860495,
            -0.03399640694260597,
            0.0016704770969226956,
            0.007954873144626617,
            0.007553770672529936,
            -0.013498468324542046,
            0.023688340559601784,
            -0.04893272742629051,
            0.013388056308031082,
            0.01300657819956541,
            0.004814798943698406,
            0.016889501363039017,
            -0.04492739215493202,
            0.02248450741171837,
            -0.01092454046010971,
            0.02957305870950222,
            0.01636562868952751,
            0.002147277817130089,
            -0.022976698353886604,
            -0.02100704051554203,
            -0.018626900389790535,
            0.023418337106704712,
            -0.003221220104023814,
            0.03865869715809822,
            -0.005526089109480381,
            0.0031661551911383867,
            -0.058899469673633575,
            -0.07526715844869614,
            -0.00042189264786429703,
            -0.0341731533408165,
            -0.00518031045794487,
            0.006064493674784899,
            -0.027032963931560516,
            -0.0013275532983243465,
            -0.023172972723841667,
            0.022881610319018364,
            -0.010642336681485176,
            -0.029201434925198555,
            0.0415785126388073,
            0.011882079765200615,
            -0.017407964915037155,
            0.009802761487662792,
            -0.012352933175861835,
            -0.008452149108052254,
            -0.010544928722083569,
            0.011840403079986572,
            -0.029544834047555923,
            0.029740097001194954,
            -0.027939770370721817,
            -0.014877134002745152,
            0.044080059975385666,
            -0.005553890019655228,
            -0.007139754947274923,
            0.00968251470476389,
            0.0032762414775788784,
            0.013130052015185356,
            0.012378606013953686,
            -0.01905975677073002,
            0.03383724018931389,
            -0.04962234944105148,
            0.03011675365269184,
            -0.014939487911760807,
            0.007538457866758108,
            -0.011201312765479088,
            0.0033080719877034426,
            0.00019094179151579738,
            -0.026359323412179947,
            -0.00530903460457921,
            -0.011696522124111652,
            -0.06519179791212082,
            -0.030575336888432503,
            -0.035734061151742935,
            -0.08409523218870163,
            -0.03758164495229721,
            0.014446417801082134,
            0.02422763593494892,
            0.031348321586847305,
            -0.011278658173978329,
            0.020473577082157135,
            -0.0039068302139639854,
            0.0024055130779743195,
            0.0101429158821702,
            0.0053593749180436134,
            -0.027053888887166977,
            0.03145045042037964,
            -0.013329033739864826,
            -0.01863468624651432,
            -0.02389274723827839,
            0.01420111395418644,
            0.0036302621010690928,
            0.023087188601493835,
            0.02259817346930504,
            -0.016440441831946373,
            0.04520530253648758,
            -0.011635692790150642,
            0.006599097978323698,
            0.020925289019942284,
            -0.011734701693058014,
            0.02809387445449829,
            -0.02609974518418312,
            -0.006630416959524155,
            0.029292821884155273,
            -0.053176283836364746,
            -0.022020934149622917,
            0.028829112648963928,
            -0.011865101754665375,
            0.026695000007748604,
            -0.02860279567539692,
            0.008892549201846123,
            0.0009521499741822481,
            0.02378339320421219,
            -0.0058555928990244865,
            0.015536095947027206,
            -0.011306103318929672,
            0.009148960001766682,
            -0.03588145971298218,
            0.01803726702928543,
            0.006466242950409651,
            -0.005001618526875973,
            0.03376981243491173,
            -0.028319576755166054,
            -0.032839611172676086,
            0.08302389085292816,
            -0.014355170540511608,
            0.0020418299827724695,
            0.007618447300046682,
            -0.017171842977404594,
            0.04868508502840996,
            0.014974765479564667,
            0.03133704885840416,
            -0.004561691544950008,
            0.0028246394358575344,
            -0.05895806476473808,
            0.01828094944357872,
            -0.014777221716940403,
            -0.024423349648714066,
            -0.009451507590711117,
            -0.023831920698285103,
            0.03136543184518814,
            0.004573137033730745,
            -0.03274241089820862,
            0.0016281668795272708,
            0.036816976964473724,
            0.021162528544664383,
            0.014455162920057774,
            -8.084868750302121e-05,
            0.01656818389892578,
            0.021679474040865898,
            -0.009030460380017757,
            -0.001612271647900343,
            0.010246404446661472,
            0.02237982302904129,
            -0.020397335290908813,
            -0.027097120881080627,
            -0.0035060688387602568,
            -0.03257150202989578,
            0.03418857604265213,
            -0.00025211655884049833,
            -0.010631882585585117,
            -0.03748567774891853,
            0.007290656678378582,
            -0.03259672969579697,
            0.02267897129058838,
            -0.046159714460372925,
            0.016686534509062767,
            -0.01969168521463871,
            -0.017085039988160133,
            0.01119946502149105,
            -0.03594069555401802,
            -0.009827197529375553,
            0.008601180277764797,
            0.008266094140708447,
            -0.026848262175917625,
            0.016912182793021202,
            -0.0195681881159544,
            0.016813894733786583,
            0.03822319209575653,
            0.008479634299874306,
            0.0013908043038100004,
            0.014484263956546783,
            -0.006745488382875919,
            0.027948841452598572,
            -0.007928423583507538,
            0.007292231544852257,
            -0.0021983187180012465,
            0.009493403136730194,
            0.004385957028716803,
            -0.015860093757510185,
            -0.01543342974036932,
            -0.004099065903574228,
            0.00691298907622695,
            -0.025830065831542015,
            -0.02831188589334488,
            -0.012005218304693699,
            0.008459520526230335,
            -0.005480894353240728,
            0.014942873269319534,
            0.004618670325726271,
            0.022273831069469452,
            -0.034213848412036896,
            -0.04543565958738327,
            -0.0077371736988425255,
            0.04974643141031265,
            -0.02424188144505024,
            -0.010714228264987469,
            0.01742764189839363,
            -0.0521789975464344,
            0.026831716299057007,
            -0.0038880063220858574,
            0.012948701158165932,
            -0.002670676913112402,
            0.022655703127384186,
            0.006220765877515078,
            0.02772093378007412,
            0.0020134621299803257,
            -0.0011871324386447668,
            -0.016869699582457542,
            0.022869402542710304,
            0.02964734472334385,
            -0.027660084888339043,
            0.027994675561785698,
            0.02149943821132183,
            -0.025213824585080147,
            0.014928402379155159,
            -0.009534749202430248,
            -0.015922360122203827,
            -0.015822699293494225,
            0.030910203233361244,
            0.015428957529366016,
            0.003056717338040471,
            -0.007008417975157499,
            -0.022786550223827362,
            -0.03045886941254139,
            0.004221560433506966,
            0.007568259257823229,
            -0.02071806974709034,
            -0.009575233794748783,
            -0.012132959440350533,
            0.02244638465344906,
            0.014507371932268143,
            -0.004082555882632732,
            -0.003728405339643359,
            -0.0029380430933088064,
            -0.002413953887298703,
            0.0033692708238959312,
            -0.009102212265133858,
            0.0002714110887609422,
            -0.029193038120865822,
            -0.01744154840707779,
            0.0026058403309434652,
            -0.01620032638311386,
            -0.0003783755237236619,
            0.023841585963964462,
            -0.01069620717316866,
            0.03343842178583145,
            0.02852996066212654,
            0.018190179020166397,
            -0.0010272389044985175,
            0.03062954545021057,
            0.009539720602333546,
            -0.02359355241060257,
            -0.017003964632749557,
            -0.02947079949080944,
            0.017480427399277687,
            0.015354116447269917,
            -0.012527385726571083,
            -0.0005837377975694835,
            0.00994977355003357,
            -0.047777555882930756,
            0.013824397698044777,
            -0.019836820662021637,
            0.017819412052631378,
            -0.011853881180286407,
            -0.02311035431921482,
            -0.052956704050302505,
            0.00477902265265584,
            0.0074934945441782475,
            0.01654614694416523,
            0.032699838280677795,
            -0.013008736073970795,
            0.031877532601356506,
            0.041008610278367996,
            -0.014519195072352886,
            -0.005850478075444698,
            0.04493468999862671,
            0.025613147765398026,
            0.023343443870544434,
            -0.004961788654327393,
            -0.012944878078997135,
            0.022959833964705467,
            -0.00963841937482357,
            0.006301115266978741,
            -0.032442886382341385,
            -0.02776724100112915,
            -0.03914037346839905,
            -0.05581582337617874,
            -0.0224494319409132,
            -0.00199156254529953,
            -0.014388405717909336,
            0.033519234508275986,
            -0.01400776207447052,
            0.029579544439911842,
            -0.0004981777747161686,
            -0.02676347643136978,
            -0.009594620205461979,
            -0.007642974145710468,
            0.028414493426680565,
            0.020999815315008163,
            0.018905259668827057,
            -0.003165616886690259,
            0.007593722548335791,
            -0.015450693666934967,
            -0.0003378736728336662,
            0.0051763602532446384,
            0.016778288409113884,
            -0.014052577316761017,
            0.009806345216929913,
            0.02668660320341587,
            -0.020328417420387268,
            0.017855916172266006,
            -0.013845372013747692,
            0.022206885740160942,
            -0.01963428035378456,
            0.01887405477464199,
            -0.005301522556692362,
            -0.001774965669028461,
            -0.0035404337104409933,
            -0.017078012228012085,
            -0.0009032083326019347,
            0.028677990660071373,
            -0.015354498289525509,
            0.06073826178908348,
            -0.02479993924498558,
            -0.023156754672527313,
            0.015362579375505447,
            0.00539243733510375,
            -0.05179006606340408,
            -0.028915386646986008,
            0.020139124244451523,
            -0.02248803898692131,
            -0.016744079068303108,
            0.027241531759500504,
            0.011832435615360737,
            -0.004877155646681786,
            -0.003781846957281232,
            -0.010485406965017319,
            0.02257002703845501,
            0.009636908769607544,
            -0.01707560569047928,
            -0.018346989527344704,
            0.0044577354565262794,
            -0.004753326531499624,
            0.005188532639294863,
            -0.009942426346242428,
            0.027790945023298264,
            0.023403694853186607,
            0.02397235482931137,
            -0.0016776749398559332,
            0.004396883305162191,
            0.003915226086974144,
            0.013715269044041634,
            -0.029023686423897743,
            0.01395597867667675,
            -0.013552688993513584,
            -0.004443684127181768,
            -0.0019473506836220622,
            0.041372619569301605,
            -0.012004902586340904,
            0.01261324156075716,
            -0.012137198820710182,
            -0.008295517414808273,
            0.019207123667001724,
            -0.019515544176101685,
            -0.015169019810855389,
            0.04894115775823593,
            0.015048236586153507,
            0.01680007018148899,
            -0.009023908525705338,
            -0.023725280538201332,
            0.020778344944119453,
            -0.002624017419293523,
            0.0032290189992636442,
            0.0005960813723504543,
            0.021054014563560486,
            0.0025460063479840755,
            -0.02003082074224949,
            -0.023973869159817696,
            -0.0018286806298419833,
            -0.013609346002340317,
            0.03414911404252052,
            -0.03363851085305214,
            -0.016616620123386383,
            -0.014425302855670452,
            0.015569102950394154,
            -0.030418619513511658,
            -0.028985895216464996,
            -0.009269943460822105,
            -0.01458531990647316,
            0.0025168289430439472,
            0.015134552493691444,
            0.008136186748743057,
            -0.03241415694355965,
            0.013542450964450836,
            -0.029795249924063683,
            0.009632007218897343,
            -0.017154483124613762,
            -0.03230830654501915,
            0.02507723867893219,
            0.010085701942443848,
            -0.010417005978524685,
            0.02225903980433941,
            -0.0004072403535246849,
            -0.010054729878902435,
            -0.03881039842963219,
            0.02497502788901329,
            -0.009480428881943226,
            -0.018538685515522957,
            -0.01816270872950554,
            0.003382793627679348,
            0.030022744089365005,
            0.029983874410390854,
            -0.09776224195957184,
            0.03003753535449505,
            0.007669543847441673,
            -0.009116003289818764,
            -0.023960305377840996,
            -0.02213256247341633,
            0.006585441995412111,
            -0.030689239501953125,
            -0.02412911131978035,
            0.012088754214346409,
            0.035281576216220856,
            -0.02510799840092659,
            0.01423599012196064,
            -0.002651349874213338,
            -0.02753736451268196,
            -0.0025150831788778305,
            -0.04380569979548454,
            0.040427546948194504,
            -0.016588447615504265,
            -0.01364574208855629,
            0.022121001034975052,
            -0.0021561195608228445,
            -0.04481111094355583,
            0.0016049600671976805,
            0.016499657183885574,
            0.016159262508153915,
            0.009316188283264637,
            0.035117678344249725,
            -0.015515284612774849,
            -0.0010635072831064463,
            -0.02966942824423313,
            -0.03655586764216423,
            0.015606352128088474,
            -0.011819660663604736,
            0.009443012066185474,
            0.007769651710987091,
            0.010725175030529499,
            0.008552206680178642,
            -0.026820074766874313,
            0.010533241555094719,
            -0.004994443152099848,
            -0.02536569908261299,
            -0.03267189860343933,
            0.002700579585507512,
            -0.007945134304463863,
            -0.009007973596453667,
            0.009004746563732624,
            0.00920805148780346,
            -0.017264414578676224,
            0.021892661228775978,
            -0.02012340910732746,
            -0.006309186108410358,
            0.01673363894224167,
            0.011168058030307293,
            0.03941580280661583,
            0.007105841301381588,
            -0.03335640951991081,
            0.014049858786165714,
            0.020303189754486084,
            -0.018417630344629288,
            -0.01393422856926918,
            -0.018175702542066574,
            0.011706425808370113,
            0.03254249691963196,
            0.02230718545615673,
            -0.02539546601474285,
            0.016634071245789528,
            0.002432269277051091,
            0.01127004437148571,
            -0.00729360431432724,
            -0.036508310586214066,
            -0.01059179287403822,
            -0.03638002648949623,
            0.016850372776389122,
            -0.030994171276688576,
            -0.0028588527347892523,
            -0.0074570695869624615,
            -0.007967709563672543,
            0.03337259963154793,
            -0.05404259264469147,
            -0.02496066316962242,
            -0.0006848949124105275,
            -0.011664940975606441,
            -0.00330733647570014,
            -0.016597898676991463,
            0.002410157583653927,
            -0.008297830820083618,
            0.021959060803055763,
            0.084348164498806,
            0.02233378402888775,
            0.02347745932638645,
            -0.02785874530673027,
            0.01856747828423977,
            0.024422964081168175,
            -0.027019048109650612,
            0.016018548980355263,
            0.012399530038237572,
            -0.012743134051561356,
            -0.011618837714195251,
            -0.01257066335529089,
            0.013831790536642075,
            -0.00688865827396512,
            -0.0013570025330409408,
            0.010256036184728146,
            0.02777101658284664,
            0.029858818277716637,
            0.02296583727002144,
            0.005913525354117155,
            -0.04296383261680603,
            -0.022663431242108345,
            -0.02619394101202488,
            -0.015977200120687485,
            0.011232764460146427,
            -0.027667973190546036,
            -0.02042369544506073,
            0.005688376259058714,
            0.03804738074541092,
            -7.363468466792256e-05,
            0.00578208127990365,
            -0.034335922449827194,
            0.030771218240261078,
            0.019290342926979065,
            -0.009895574301481247,
            -0.008067832328379154,
            -0.037104327231645584,
            0.00941353291273117,
            0.015911977738142014,
            -0.0072487229481339455,
            -0.02742464654147625,
            -0.046299949288368225,
            -0.012635830789804459,
            0.05193738639354706,
            -0.010995445773005486,
            0.01621946319937706,
            -0.014934972859919071,
            0.009990285150706768,
            0.03402335196733475,
            0.007077232468873262,
            0.06221908703446388,
            -0.0201740562915802,
            -0.019161876291036606,
            0.049968354403972626,
            -0.011142943054437637,
            0.016285957768559456,
            -0.008532519452273846,
            0.024911150336265564,
            0.019809138029813766,
            -0.029817888513207436,
            0.05865335837006569,
            0.01719760149717331,
            -0.010986371897161007,
            -0.02550136111676693,
            -0.01883471943438053,
            0.005622609984129667,
            0.01440972089767456,
            0.010048373602330685,
            -0.01289180014282465,
            0.004179187119007111,
            0.031422171741724014,
            0.013131619431078434,
            -0.028102174401283264,
            -0.014180119149386883,
            -0.011202946305274963,
            0.027177905663847923,
            0.0021455634851008654,
            0.029663484543561935,
            0.0028729198966175318,
            -0.01262210588902235,
            0.04948927462100983,
            -0.0025909850373864174,
            0.015435772016644478,
            -0.0243968665599823,
            -0.015559498220682144,
            -0.022132573649287224,
            0.018211429938673973,
            -0.0023891772143542767,
            -0.0012502138270065188,
            -0.0554870069026947,
            0.0031592007726430893,
            0.008030269294977188,
            -0.019056206569075584,
            0.007362665608525276,
            -0.003939881920814514,
            0.010939860716462135,
            -0.01634896546602249,
            -0.024622958153486252,
            0.03290535509586334,
            0.05381385236978531,
            0.010347692295908928,
            0.028833499178290367,
            -0.04779485613107681,
            -0.025253601372241974,
            -0.010524734854698181,
            0.016107527539134026,
            0.030868280678987503,
            0.01513378694653511,
            0.006340337917208672,
            -0.028239216655492783,
            0.04033862054347992,
            -0.009622389450669289,
            0.024182124063372612,
            0.02198774367570877,
            -0.018502051010727882,
            0.010507133789360523,
            -0.0020919623784720898,
            -0.008173837326467037,
            0.02429962158203125,
            0.009919283911585808,
            0.004246299620717764,
            0.025207560509443283,
            0.005523986648768187,
            0.005428559146821499,
            0.017779100686311722,
            0.02953002043068409,
            -0.017696544528007507,
            -0.054009053856134415,
            -0.018335815519094467,
            0.0035898645874112844,
            -0.018759917467832565,
            -0.022966740652918816,
            0.03745470196008682,
            0.015802299603819847,
            0.057720430195331573,
            -0.010886912234127522,
            0.02808823250234127,
            0.044353920966386795,
            0.024064581841230392,
            0.01613108068704605,
            -0.025819959118962288,
            0.016294457018375397,
            0.007632385473698378,
            0.0025807558558881283,
            0.021590963006019592,
            0.01036568358540535,
            -0.017654558643698692,
            0.02150689624249935,
            -0.06556475162506104,
            -0.03153780475258827,
            -0.04099798575043678,
            0.01690499112010002,
            0.028260523453354836,
            0.03477444127202034,
            0.028176136314868927,
            -0.015070363879203796,
            0.007668639533221722,
            -0.011076632887125015,
            0.030720878392457962,
            -0.009854847565293312,
            0.010496134869754314,
            0.004939172882586718,
            -0.06653769314289093,
            -0.044029951095581055,
            -0.021231744438409805,
            0.0017505018040537834,
            0.02507842890918255,
            0.03554572910070419,
            0.03852993994951248,
            0.014188596047461033,
            0.04467722028493881,
            -0.02368742786347866,
            0.004297691397368908,
            0.03809615969657898,
            0.029831482097506523,
            -0.0446794293820858,
            0.042210523039102554,
            -0.05056842789053917,
            0.015698514878749847,
            -0.054265640676021576,
            0.0374593660235405,
            0.023689277470111847,
            0.010177351534366608,
            -0.021617615595459938,
            -0.018906915560364723,
            0.016376372426748276,
            -0.00020648256759159267,
            0.03614458069205284,
            -0.002822953974828124,
            -0.023423612117767334
        ],
        [
            -0.016776783391833305,
            0.014403888955712318,
            -0.0008783822413533926,
            0.02180488407611847,
            -0.02267400547862053,
            -0.02635706588625908,
            -0.01682000420987606,
            -0.022309545427560806,
            -0.020810024812817574,
            0.02586490660905838,
            0.004323690664023161,
            -0.006819184869527817,
            0.005108857527375221,
            0.011594418436288834,
            0.011912064626812935,
            -0.03179273009300232,
            0.014644569717347622,
            -0.001076765125617385,
            0.0102427639067173,
            0.01615769788622856,
            -0.005275780335068703,
            0.013027730397880077,
            -0.02448943629860878,
            0.012639889493584633,
            0.01131942868232727,
            -0.021553542464971542,
            -0.011860031634569168,
            0.02678520232439041,
            0.01241404376924038,
            0.016706746071577072,
            0.012222317047417164,
            0.007067717146128416,
            -0.010636731050908566,
            -0.022416235879063606,
            0.018977809697389603,
            0.0211692675948143,
            0.007998262532055378,
            -0.015516278333961964,
            0.012764707207679749,
            0.005232471041381359,
            -0.024584881961345673,
            0.01948937401175499,
            -0.002760950243100524,
            0.004444706719368696,
            0.021141745150089264,
            -0.030324803665280342,
            0.012206040322780609,
            -0.0017683092737570405,
            -0.020556863397359848,
            0.009296964854001999,
            0.00977516919374466,
            0.02626226097345352,
            0.02420501410961151,
            0.023272525519132614,
            0.003395330160856247,
            0.0032428614795207977,
            0.005757762119174004,
            -0.00016307436453644186,
            0.012275652028620243,
            -0.015573863871395588,
            -0.022247476503252983,
            0.0040565235540270805,
            -0.027131181210279465,
            0.014218373224139214,
            0.01455342210829258,
            0.014653539285063744,
            -0.011761429719626904,
            -0.007321510463953018,
            -0.019017670303583145,
            -0.02190217934548855,
            0.021723303943872452,
            0.022554654628038406,
            0.011992949061095715,
            0.0060628908686339855,
            -0.01424281857907772,
            -0.010836961679160595,
            0.021880436688661575,
            -0.019766803830862045,
            0.029294408857822418,
            0.007683869451284409,
            0.012038004584610462,
            -0.027465974912047386,
            -0.02375086396932602,
            -0.025451868772506714,
            -0.012154828757047653,
            -0.011590630747377872,
            -0.02934863418340683,
            0.013871468603610992,
            0.020524796098470688,
            -0.026195116341114044,
            -0.0021514545660465956,
            0.02780861407518387,
            0.014117736369371414,
            -0.030656710267066956,
            0.021272283047437668,
            -0.0028142950031906366,
            -0.018665146082639694,
            0.017384760081768036,
            -0.006572511047124863,
            0.006405268330127001,
            0.024828586727380753,
            -0.003874243004247546,
            -0.027204900979995728,
            0.010846526362001896,
            -0.02244526892900467,
            0.006801150739192963,
            0.006774473935365677,
            -0.018747856840491295,
            0.014223095029592514,
            -0.006635432597249746,
            -0.006434682756662369,
            0.026085129007697105,
            -0.02315709926187992,
            0.028024904429912567,
            0.027937045320868492,
            -0.01114393025636673,
            -0.012214144691824913,
            0.018455589190125465,
            -0.025881238281726837,
            0.014049012213945389,
            0.01940421760082245,
            0.02447471395134926,
            -0.004960507154464722,
            -0.019647013396024704,
            -0.000919366895686835,
            -0.03140496462583542,
            -0.0060638803988695145,
            -0.02151346206665039,
            0.020294837653636932,
            0.026835044845938683,
            -0.01242008339613676,
            -0.027280820533633232,
            -0.024353545159101486,
            0.01884951815009117,
            -0.013603616505861282,
            -0.031215988099575043,
            -0.02791415899991989,
            -0.009172908961772919,
            -0.027328312397003174,
            0.023167243227362633,
            -0.02858458086848259,
            0.023210221901535988,
            -0.028117135167121887,
            -0.0023843306116759777,
            -0.008157448843121529,
            0.008842403069138527,
            -0.00066292320843786,
            -0.016784029081463814,
            -0.0007963894749991596,
            0.019960056990385056,
            0.026424702256917953,
            0.027867218479514122,
            -0.02626497484743595,
            -0.018412640318274498,
            0.025459416210651398,
            -0.005889462307095528,
            0.006946357898414135,
            0.013177328743040562,
            0.008027096278965473,
            -0.02414584718644619,
            0.025018978863954544,
            0.02353399246931076,
            0.008192669600248337,
            -0.031296294182538986,
            0.0007613201742060483,
            -0.021690309047698975,
            0.019464438781142235,
            -0.02730974182486534,
            0.017631612718105316,
            -0.0006706314161419868,
            0.01904887706041336,
            0.010449741035699844,
            0.01761830598115921,
            0.02920687012374401,
            0.01818074658513069,
            0.019963599741458893,
            0.02753581665456295,
            -0.014126557856798172,
            -0.00309864804148674,
            -0.029685648158192635,
            0.027938149869441986,
            -0.016841666772961617,
            0.016617806628346443,
            0.0058763036504387856,
            0.011378680355846882,
            0.026523549109697342,
            0.029026176780462265,
            -0.007056508678942919,
            0.007082927040755749,
            -0.014764230698347092,
            0.024051226675510406,
            -0.005021270830184221,
            0.011667078360915184,
            0.02447425201535225,
            -0.00494420574977994,
            -0.009856990538537502,
            -0.006198916118592024,
            -0.016209037974476814,
            0.010909941047430038,
            -0.0054201059974730015,
            -0.015184840187430382,
            0.02545701526105404,
            -0.02961183525621891,
            0.02133188769221306,
            -0.012285873293876648,
            0.02252364158630371,
            -0.017200879752635956,
            -0.028108805418014526,
            0.00018369783356320113,
            0.020770687609910965,
            -0.01992049813270569,
            -0.02336617186665535,
            -0.012698384933173656,
            0.019297387450933456,
            -0.010331336408853531,
            -0.02985703945159912,
            -0.02511192113161087,
            0.016631055623292923,
            -0.026796918362379074,
            0.012492690235376358,
            -0.00932314246892929,
            0.019279859960079193,
            -0.021263744682073593,
            0.019128747284412384,
            0.0158451646566391,
            -0.006975161377340555,
            -0.004647150170058012,
            0.020024295896291733,
            0.0008714795112609863,
            0.025152985006570816,
            -0.029300082474946976,
            -0.003610163927078247,
            -0.023211419582366943,
            -0.030910974368453026,
            -0.008664410561323166,
            -0.004922647960484028,
            -0.018427841365337372,
            -0.011136936955153942,
            0.008773501962423325,
            -0.026449162513017654,
            -0.009225857444107533,
            0.01713264361023903,
            -0.014352627098560333,
            -0.020314272493124008,
            0.009333883412182331,
            -0.0006091827526688576,
            -0.012251769192516804,
            -0.015071982517838478,
            -0.008620276115834713,
            -0.03215884789824486,
            -0.013932310044765472,
            -0.002113922033458948,
            0.0009457006817683578,
            0.0060028494335711,
            -0.014015065506100655,
            -0.0026056221686303616,
            0.02738989144563675,
            -0.02701549604535103,
            0.029108023270964622,
            -0.031138718128204346,
            -0.014275542460381985,
            -0.012590952217578888,
            0.02152804099023342,
            -0.030260957777500153,
            -0.027772799134254456,
            0.004305506125092506,
            -0.0014255051501095295,
            -0.026806149631738663,
            -0.023335909470915794,
            0.003593958681449294,
            0.0018655462190508842,
            -0.025658244267106056,
            0.0016336319968104362,
            -0.02463533729314804,
            0.02478916570544243,
            -0.016802595928311348,
            -0.0027158386074006557,
            0.018089037388563156,
            -0.03087574616074562,
            0.017078028991818428,
            -0.03215211629867554,
            0.0033601783215999603,
            -0.029213447123765945,
            -0.011923477053642273,
            0.01887757144868374,
            0.013772026635706425,
            -0.008207383565604687,
            0.013601284474134445,
            0.015724189579486847,
            0.014642606489360332,
            -4.115204865229316e-05,
            -0.02659524232149124,
            -0.009548894129693508,
            0.020186208188533783,
            -0.026574142277240753,
            0.0017006413545459509,
            -0.025081563740968704,
            -0.030317850410938263,
            0.003186848247423768,
            0.027585171163082123,
            -0.0032077941577881575,
            0.014418050646781921,
            0.019665010273456573,
            0.026317885145545006,
            0.026998814195394516,
            0.00664489483460784,
            0.023719755932688713,
            0.02811592072248459,
            0.0030262270011007786,
            0.002823424991220236,
            -0.026852641254663467,
            -0.03167565539479256,
            -0.010314259678125381,
            -0.031455159187316895,
            -0.013320894911885262,
            -0.027999958023428917,
            -0.032184816896915436,
            0.009126242250204086,
            0.0017424017423763871,
            0.008022138848900795,
            0.012577972374856472,
            -0.012202400714159012,
            0.006346754264086485,
            0.011704056523740292,
            -0.004847892560064793,
            -0.001650437479838729,
            -0.02879277616739273,
            0.000985745689831674,
            0.01864606887102127,
            0.0007343556499108672,
            0.01641790196299553,
            0.02774003893136978,
            0.0025321058928966522,
            0.020973902195692062,
            0.023718714714050293,
            0.026931773871183395,
            -0.010395784862339497,
            -0.030842021107673645,
            0.019710231572389603,
            0.0015872474759817123,
            -0.0017935862997546792,
            0.02214633673429489,
            -0.006977886892855167,
            0.015830909833312035,
            0.006451897788792849,
            0.01495401468127966,
            -0.028267446905374527,
            0.023136485368013382,
            -0.001480628503486514,
            0.005213534459471703,
            -0.02510845847427845,
            0.026365384459495544,
            -0.031160419806838036,
            0.006650406401604414,
            -0.0012167913373559713,
            0.007209107279777527,
            0.001134518999606371,
            -0.011663787066936493,
            0.020737245678901672,
            -0.008555867709219456,
            -0.0077611361630260944,
            -0.017230842262506485,
            -0.025155628100037575,
            0.0013339139986783266,
            -0.02106296643614769,
            0.020942214876413345,
            -0.011078081093728542,
            0.02344723418354988,
            0.0296867024153471,
            -0.0045202565379440784,
            0.00506205391138792,
            -0.013480950146913528,
            0.02602413296699524,
            -0.016266319900751114,
            0.0247199609875679,
            0.022690316662192345,
            -0.006994396448135376,
            0.01463107019662857,
            -0.031402140855789185,
            -0.0010903128422796726,
            0.0028953691944479942,
            0.00027380810934118927,
            -0.024747375398874283,
            -0.00870631355792284,
            -0.016760095953941345,
            -0.028970833867788315,
            0.02467988058924675,
            0.01442445907741785,
            -0.017005398869514465,
            0.0035548622254282236,
            -0.017691636458039284,
            0.028604716062545776,
            -0.02061532251536846,
            0.0038542672991752625,
            0.001249019056558609,
            -0.03102909028530121,
            0.02210371568799019,
            0.025049909949302673,
            0.013234156183898449,
            -0.020345797762274742,
            -0.017255034297704697,
            0.019146721810102463,
            0.02320934273302555,
            0.018778998404741287,
            -0.012792270630598068,
            0.029964525252580643,
            -0.014381165616214275,
            -0.029845882207155228,
            -0.011679071933031082,
            0.011861988343298435,
            -0.009733099490404129,
            -0.002728875260800123,
            0.006161779630929232,
            -0.012422079220414162,
            0.02704543061554432,
            -0.018189629539847374,
            0.003021489130333066,
            0.02932637371122837,
            -0.02569698542356491,
            -0.004025216214358807,
            -0.0008623860776424408,
            0.010886930860579014,
            -0.00928705558180809,
            0.006001296918839216,
            -0.003673303872346878,
            0.023504134267568588,
            0.0011880131205543876,
            -0.021381251513957977,
            -0.0106278071179986,
            -0.0248001366853714,
            -0.016901159659028053,
            0.0005826622946187854,
            0.023998010903596878,
            0.002672918140888214,
            -0.019806701689958572,
            0.00017913521151058376,
            -0.01763884164392948,
            0.010882382281124592,
            0.019660692662000656,
            -0.0029063334222882986,
            0.0020686211064457893,
            0.02410580962896347,
            -0.030117526650428772,
            0.009785346686840057,
            0.015811465680599213,
            -0.010593635030090809,
            0.015185925178229809,
            0.011987053789198399,
            0.007712110877037048,
            -0.030151300132274628,
            0.029985804110765457,
            0.01990635320544243,
            0.02049696445465088,
            0.011443286202847958,
            -0.025821981951594353,
            0.015524015761911869,
            0.01680782437324524,
            0.008556624874472618,
            -0.020633473992347717,
            -0.026841435581445694,
            -0.007384710945188999,
            0.004200470168143511,
            -0.02318112552165985,
            -0.029656460508704185,
            -0.022998442873358727,
            -0.011288710869848728,
            -0.011640076525509357,
            0.001646780758164823,
            5.88549482927192e-05,
            0.011238344013690948,
            0.011405534110963345,
            -0.00396628025919199,
            0.009741419926285744,
            -0.024246420711278915,
            0.02556987851858139,
            0.015721339732408524,
            -0.003494521137326956,
            -0.01597036048769951,
            -0.02275579795241356,
            -0.020500611513853073,
            -0.014468330889940262,
            -0.01429681945592165,
            0.005013138055801392,
            0.018309133127331734,
            0.01791943982243538,
            0.0009000934078358114,
            -0.00048037609667517245,
            0.009821558371186256,
            -0.013815334066748619,
            0.00923927128314972,
            0.005901237018406391,
            0.0291922464966774,
            0.004009965341538191,
            0.007930820807814598,
            -0.018819335848093033,
            0.013354957103729248,
            -0.030953899025917053,
            0.027964161708950996,
            -0.012004759162664413,
            0.00542089669033885,
            0.024404825642704964,
            0.029167989268898964,
            -0.0066988239996135235,
            -0.023725228384137154,
            -0.02780536748468876,
            -0.008179283700883389,
            0.027837300673127174,
            -0.008170802146196365,
            0.018141265958547592,
            -0.00507311662659049,
            -0.0059187463484704494,
            0.0006586567615158856,
            0.0037074864376336336,
            0.0010728202760219574,
            0.024060837924480438,
            -0.014425667934119701,
            -0.02784535475075245,
            -0.014895103871822357,
            0.0027344117406755686,
            -0.02940588817000389,
            -0.02614581026136875,
            0.029512304812669754,
            -0.022172529250383377,
            -0.01784195750951767,
            -0.025164015591144562,
            -0.030590159818530083,
            -0.008456893265247345,
            -0.016037991270422935,
            0.0025118053890764713,
            -0.0019903667271137238,
            -0.006138325203210115,
            -0.01493337843567133,
            -0.00861569307744503,
            0.013756810687482357,
            -0.003909051883965731,
            -0.014528966508805752,
            0.005321718752384186,
            0.017611199989914894,
            -0.0293506421148777,
            -0.005442705005407333,
            0.029628483578562737,
            -0.014995566569268703,
            0.0065424698404967785,
            -0.020448114722967148,
            -0.006181284319609404,
            -0.022331755608320236,
            -0.0017928931629285216,
            0.025834575295448303,
            -0.0010384289780631661,
            -0.02817658707499504,
            -0.03076360933482647,
            0.01863623782992363,
            0.0003334230568725616,
            -0.007255252450704575,
            -0.030998410657048225,
            -0.03190247714519501,
            -0.003762215143069625,
            0.004333227872848511,
            -0.009823196567595005,
            0.02103157714009285,
            0.015878766775131226,
            0.010157093405723572,
            -0.020846588537096977,
            0.009644188918173313,
            -0.03173449635505676,
            -0.01189885288476944,
            0.018670793622732162,
            -0.02200118452310562,
            -0.021804407238960266,
            -0.016490554437041283,
            -0.022562403231859207,
            0.026031536981463432,
            0.019713029265403748,
            -0.012903496623039246,
            -0.024126337841153145,
            0.021629344671964645,
            0.006705820560455322,
            -0.020195702090859413,
            0.006977219134569168,
            -0.016844667494297028,
            -0.022858217358589172,
            -0.027290012687444687,
            -0.022440901026129723,
            0.0024018953554332256,
            -0.019601475447416306,
            0.009359060786664486,
            0.029059307649731636,
            0.017318665981292725,
            0.02230493351817131,
            0.0015128632076084614,
            -0.025889631360769272,
            0.02052675373852253,
            0.01789432391524315,
            0.003089270554482937,
            0.013322988525032997,
            0.004319666884839535,
            -0.03163053095340729,
            -0.007533990778028965,
            -0.006493767257779837,
            -0.012676449492573738,
            0.011939957737922668,
            -0.01529744453728199,
            -0.008303812704980373,
            -0.013563604094088078,
            0.015782291069626808,
            -0.008418881334364414,
            0.008387491106987,
            -0.01656484417617321,
            -0.024545466527342796,
            -0.028890790417790413,
            -0.028924651443958282,
            -0.0257953442633152,
            -0.020276442170143127,
            0.010433632880449295,
            0.02034939080476761,
            0.007215034682303667,
            -0.02395150437951088,
            0.012648114003241062,
            -0.010718894191086292,
            0.016483623534440994,
            -0.022828862071037292,
            -0.00804843194782734,
            -0.018199637532234192,
            -0.005045962519943714,
            -0.001006568782031536,
            0.014333552680909634,
            -0.030236322432756424,
            -0.0014579779235646129,
            -0.016327671706676483,
            -0.01940005086362362,
            -0.02427542582154274,
            0.020551402121782303,
            0.024648958817124367,
            0.01482255570590496,
            -0.024454517289996147,
            0.004675194621086121,
            0.011556223966181278,
            -0.011192521080374718,
            -0.024331090971827507,
            0.005646585952490568,
            -0.02530675195157528,
            -0.010415114462375641,
            -0.026510320603847504,
            0.030184481292963028,
            -0.007512240204960108,
            0.024100925773382187,
            0.014776160940527916,
            -0.017882756888866425,
            0.016996081918478012,
            0.028726939111948013,
            -0.012182249687612057,
            0.022287530824542046,
            -0.025088895112276077,
            -0.0188426673412323,
            0.01578751765191555,
            -0.007014560047537088,
            -0.022509384900331497,
            -0.029218625277280807,
            0.005767784547060728,
            0.0026555275544524193,
            0.004983965307474136,
            0.018334990367293358,
            -0.029568003490567207,
            0.02970404550433159,
            -0.02984243631362915,
            0.01033825147897005,
            -0.022097859531641006,
            -0.025825709104537964,
            -0.026451805606484413,
            0.02259857766330242,
            -0.017113227397203445,
            -0.02994738146662712,
            0.016111286357045174,
            0.013689341023564339,
            -0.029691781848669052,
            0.011529475450515747,
            0.025808580219745636,
            0.0010380825260654092,
            -0.026685550808906555,
            0.022500552237033844,
            0.013369882479310036,
            -0.016969993710517883,
            -0.022102292627096176,
            0.022928688675165176,
            0.018726959824562073,
            0.004642730578780174,
            0.030520599335432053,
            0.01632576808333397,
            -0.016527552157640457,
            -0.027438610792160034,
            0.015156105160713196,
            -0.006376763340085745,
            0.009024498052895069,
            -0.008097444660961628,
            0.01644519716501236,
            -0.002090215915814042,
            0.0025682803243398666,
            0.01687593199312687,
            0.029143333435058594,
            -0.0036759404465556145,
            0.013202766887843609,
            -0.015564240515232086,
            -0.0004429151595104486,
            0.01632595621049404,
            0.025996848940849304,
            -0.028016936033964157,
            -0.01926412247121334,
            0.021827664226293564,
            0.02653856948018074,
            0.011502580717206001,
            -0.026377273723483086,
            -0.0209202840924263,
            0.02704804018139839,
            -0.026031026616692543,
            0.018890175968408585,
            -0.014601531438529491,
            0.0037843436002731323,
            -0.01043214276432991,
            -0.007686105091124773,
            -0.02902454324066639,
            0.011113660410046577,
            -0.019170010462403297,
            0.013594608753919601,
            0.020901907235383987,
            -0.02787182480096817,
            -0.015697097405791283,
            -0.030480576679110527,
            -0.017670122906565666,
            0.02406850829720497,
            0.02789008803665638,
            -0.027240188792347908,
            -0.009632201865315437,
            -0.029535816982388496,
            0.008707801811397076,
            -0.020313257351517677,
            -0.022129490971565247,
            -0.0270237997174263,
            0.0037690079770982265,
            0.014315279200673103,
            -0.016651038080453873,
            -0.026292946189641953,
            -0.026565182954072952,
            0.01537894457578659,
            -0.02684151753783226,
            -0.017312457785010338,
            -0.003119412809610367,
            0.0021977105643600225,
            0.02881661057472229,
            0.004033620934933424,
            0.019076909869909286,
            -0.018803702667355537,
            -0.025565562769770622,
            -0.021577131003141403,
            0.017064588144421577,
            0.00495029566809535,
            -0.01455637812614441,
            0.008841615170240402,
            0.026944994926452637,
            0.025924809277057648,
            -0.004050735849887133,
            0.00378379225730896,
            -0.030022278428077698,
            -0.011356696486473083,
            -0.00687782559543848,
            -0.022293666377663612,
            -0.02194315381348133,
            0.007076071109622717,
            -0.02773198112845421,
            0.013788534328341484,
            -0.0186610110104084,
            -0.025804094970226288,
            -0.023838989436626434,
            -0.02098713628947735,
            0.021413207054138184,
            -0.019161049276590347,
            0.016030605882406235,
            -0.006094094831496477,
            0.013021991588175297,
            -0.0032508752774447203,
            -0.015433442778885365,
            0.013868875801563263,
            0.010747884400188923,
            0.006651968229562044,
            -0.004405095707625151,
            -0.025632310658693314,
            -0.007946924306452274,
            -0.0011379964416846633,
            -0.007763856090605259,
            -0.027833588421344757,
            -0.001322135329246521,
            0.00042424225830473006,
            0.026509152725338936,
            -0.007482878863811493,
            0.0014573668595403433,
            -0.007166970521211624,
            -0.004654652439057827,
            0.00190389109775424,
            -0.022395385429263115,
            -0.026933398097753525,
            -0.02433772198855877,
            0.010332200676202774,
            -0.029013095423579216,
            6.75451010465622e-05,
            0.022797757759690285,
            -0.005860599223524332,
            -0.011908963322639465,
            0.02930709347128868,
            0.022694407030940056,
            -0.010050872340798378,
            0.010192926973104477,
            0.028286488726735115,
            -0.0051461560651659966,
            -0.028438199311494827,
            0.024984536692500114,
            -0.026302926242351532,
            0.014346178621053696,
            -4.7029276174725965e-05,
            -0.01859346032142639,
            0.013746273703873158,
            -0.01373499445617199,
            0.01905910298228264,
            -0.03175724297761917,
            0.0038873888552188873,
            -0.012492743320763111,
            -0.0017794212326407433,
            0.02757386304438114,
            -0.02797710709273815,
            -0.015320532955229282,
            0.002755502937361598,
            0.018069971352815628,
            0.023015841841697693,
            -0.01483222097158432,
            -0.032044194638729095,
            -0.013356577605009079,
            0.013292316347360611,
            -0.011982438154518604,
            -0.022675106301903725,
            -0.026840168982744217,
            -0.029629960656166077,
            -0.01142179500311613,
            0.007622629404067993,
            0.01935678720474243,
            -0.0064499229192733765,
            0.02862399071455002,
            -0.007082507945597172,
            0.004680444020777941,
            -0.020384391769766808,
            0.0136471688747406,
            0.0177755244076252,
            -0.01596716418862343,
            -0.025689411908388138,
            0.012138514779508114,
            -0.02154737524688244,
            -0.032124973833560944,
            -0.013275006785988808,
            -0.028819331899285316,
            0.022376589477062225,
            -0.01234594825655222,
            0.02855750545859337,
            -0.0179891400039196,
            0.0010700926650315523,
            -0.023499855771660805,
            0.02481093630194664,
            -0.0032603852450847626,
            0.02440120279788971,
            0.02700028568506241,
            -0.019678674638271332,
            -0.017710043117403984,
            -0.02897811494767666,
            -0.02837993949651718,
            -0.025178667157888412,
            -0.03172478452324867,
            0.020851697772741318,
            0.01919178105890751,
            0.006148721557110548,
            -0.01905093342065811,
            -0.023967048153281212,
            0.005728692281991243,
            -0.02523971162736416,
            -0.016436006873846054,
            0.003280437085777521,
            0.014825568534433842,
            -0.0022012386471033096,
            0.0288425050675869,
            -0.02703949250280857,
            -0.02579878270626068,
            -0.01098477840423584,
            0.020876357331871986,
            -0.002999470569193363,
            -0.0007247054018080235,
            0.02529146522283554,
            -0.021902235224843025,
            0.0149544021114707,
            -0.03219667822122574,
            0.011060748249292374,
            0.008462082594633102,
            0.022496599704027176,
            -0.029033564031124115,
            -0.015990622341632843,
            0.020070329308509827,
            -0.009870072826743126,
            0.021770650520920753,
            -0.007635391782969236,
            0.0010184088023379445,
            0.03015553019940853,
            -0.019461682066321373,
            -0.018684156239032745,
            0.015580195933580399,
            -0.0012462964514270425,
            0.01825784519314766,
            0.004441533237695694,
            0.012288096360862255,
            -0.007328900508582592,
            0.0013532154262065887,
            0.00554887717589736,
            0.0013579308288171887,
            -0.001371206482872367,
            -0.0050953105092048645,
            0.026911495253443718,
            0.009472675621509552,
            0.026682715862989426,
            0.010053211823105812,
            0.013620343059301376,
            -0.0321463868021965,
            0.014851373620331287,
            -0.02718496136367321,
            -0.02139909565448761,
            -0.020115632563829422,
            0.004938871134072542,
            0.02164706215262413,
            -0.021893270313739777,
            -0.02241424284875393,
            0.02309400960803032,
            -0.03093438781797886,
            -0.022768978029489517,
            -0.012559968046844006,
            0.02466362714767456,
            0.024056503549218178,
            -0.019253019243478775,
            -0.0017757295863702893,
            -0.009383969940245152,
            -0.023110387846827507,
            0.010984854772686958,
            -0.006722826045006514,
            -0.02348226308822632,
            -0.010557304136455059,
            0.02976909652352333,
            -0.02617216669023037,
            0.005134940147399902,
            0.0009189968113787472,
            -0.015318848192691803,
            0.0043418328277766705,
            0.021955430507659912,
            -0.02509503997862339,
            -0.004613854456692934,
            -0.029765479266643524,
            -0.03184768557548523,
            -0.004494939465075731,
            -0.0016291849315166473,
            0.011622186750173569,
            -0.010803031735122204,
            0.001113447593525052,
            0.02777279168367386,
            0.010447010397911072,
            0.012131630443036556,
            -0.02092221938073635,
            0.02406720630824566,
            -0.02931729331612587,
            -0.012845106422901154,
            0.009449233300983906,
            -0.004274397622793913,
            0.011168534867465496,
            0.01529470644891262,
            -0.007088020909577608,
            0.02682403288781643,
            0.011472400277853012,
            -0.009170490317046642,
            0.010439370758831501,
            -0.008550301194190979,
            -0.02956557273864746,
            0.01848195120692253,
            -0.006665292661637068,
            -0.013269671238958836,
            -0.006210502237081528,
            -0.014761578291654587,
            -0.01855314150452614,
            -0.008983730338513851,
            -0.0063553196378052235,
            0.002425264334306121,
            -0.011899661272764206,
            0.011749586090445518,
            -0.0072479285299777985,
            -0.010982684791088104,
            -0.004184846766293049,
            0.009683388285338879,
            0.0010149817680940032,
            0.007944496348500252,
            -0.0259585939347744,
            -0.0071667954325675964,
            0.02674262411892414,
            -0.0013934922171756625,
            -7.778691724524833e-06,
            -0.024064987897872925,
            0.004480261821299791,
            -0.029133722186088562,
            0.02950315549969673,
            -0.01628599688410759,
            0.027677007019519806,
            -0.013459625653922558,
            -0.02017081156373024,
            0.001342514413408935,
            0.012637767940759659,
            -0.012189186178147793,
            0.02005551941692829,
            -0.030587129294872284,
            0.012001452036201954,
            -0.019120918586850166,
            0.017400164157152176,
            0.0050727897323668,
            -0.014352560974657536,
            -0.0029385839588940144,
            -0.0021188706159591675,
            0.003813308198004961,
            0.029171451926231384
        ],
        [
            0.002894139150157571,
            0.02349846437573433,
            0.030896592885255814,
            -0.030581308528780937,
            -0.029871121048927307,
            -0.028353003785014153,
            -0.004161675460636616,
            -0.025497913360595703,
            0.023563945665955544,
            -0.004153569228947163,
            0.01618398167192936,
            -0.033648520708084106,
            0.01389636006206274,
            0.0094040771946311,
            -0.012804502621293068,
            0.0007726253825239837,
            -0.010187413543462753,
            -0.006878092419356108,
            -0.03869880735874176,
            0.010217418894171715,
            -0.018929608166217804,
            -0.012364698573946953,
            0.00019185848941560835,
            -0.0012602517381310463,
            -0.001435675541870296,
            -0.017873642966151237,
            -0.014092816971242428,
            -0.029807066544890404,
            0.013665950857102871,
            -0.014256307855248451,
            -0.021143713966012,
            0.01620638556778431,
            -0.014846942387521267,
            0.009123886935412884,
            -0.012541696429252625,
            0.0018751624738797545,
            -0.015537861734628677,
            0.0032171497587114573,
            -0.034095682203769684,
            0.018457045778632164,
            0.012158768251538277,
            0.005955347325652838,
            0.027823934331536293,
            0.024281099438667297,
            -0.03189152851700783,
            0.030080080032348633,
            0.0025338130071759224,
            0.009783009998500347,
            0.01213349774479866,
            0.025347400456666946,
            -0.029047898948192596,
            -0.01517911534756422,
            0.0009735861676745117,
            -0.031437624245882034,
            0.02452770620584488,
            -0.03497889265418053,
            -0.016540229320526123,
            -0.022195301949977875,
            0.009754105471074581,
            -0.0031903248745948076,
            0.028775153681635857,
            -0.009305993095040321,
            0.00902468804270029,
            0.010283355601131916,
            -0.013543613255023956,
            0.010037800297141075,
            -0.03874555975198746,
            0.006815850734710693,
            0.013520844280719757,
            -0.0028150693979114294,
            0.006741613615304232,
            -0.0026946822181344032,
            -0.012339398264884949,
            0.009249045513570309,
            0.05136517435312271,
            0.01017743069678545,
            0.029041225090622902,
            -0.014565345831215382,
            -0.03802682086825371,
            0.0164884552359581,
            0.0008978399564512074,
            -0.025641733780503273,
            0.017375290393829346,
            -0.009345285594463348,
            0.007712880615144968,
            -0.02564573846757412,
            0.02749856933951378,
            0.010114395059645176,
            -0.02023305930197239,
            0.005923463497310877,
            0.020527953281998634,
            -0.026308158412575722,
            -0.02961456961929798,
            -0.004583630245178938,
            -0.001622120151296258,
            0.004835779778659344,
            0.030511489138007164,
            0.030324706807732582,
            -0.04791336879134178,
            -0.0029229228384792805,
            -0.002505207434296608,
            -0.023120436817407608,
            0.008998246863484383,
            -0.00976537261158228,
            -0.01344779971987009,
            -0.00035309212398715317,
            -0.0033938558772206306,
            -0.01829054392874241,
            -0.006254282779991627,
            0.021483194082975388,
            0.0235320832580328,
            -0.004650370217859745,
            0.014186644926667213,
            -0.008002147078514099,
            0.0447380430996418,
            0.012903175316751003,
            -0.00246680760756135,
            0.0021339149679988623,
            -0.014507781714200974,
            -0.0042197005823254585,
            0.01851213537156582,
            -0.022157033905386925,
            -0.03711841255426407,
            -0.022019458934664726,
            0.019910527393221855,
            -0.03307121619582176,
            0.004701272118836641,
            0.0035302303731441498,
            0.02234456315636635,
            -0.011135933920741081,
            -0.021971210837364197,
            0.010422221384942532,
            -0.02026744931936264,
            0.0184906255453825,
            -0.008197055198252201,
            0.027209142223000526,
            -0.029329245910048485,
            0.02931392565369606,
            0.0014873527688905597,
            -0.00701771629974246,
            -0.009645861573517323,
            -0.02343636378645897,
            0.0034331120550632477,
            -0.00835496000945568,
            0.012355823069810867,
            -0.030177464708685875,
            -0.02419218234717846,
            0.02771720662713051,
            -0.024776943027973175,
            -0.02897672727704048,
            0.01433038990944624,
            0.045352041721343994,
            -0.03354383260011673,
            0.012246055528521538,
            -0.03541048243641853,
            0.016995327547192574,
            -0.03133914992213249,
            0.006366708315908909,
            -0.0022490019910037518,
            0.020011257380247116,
            0.018305344507098198,
            0.00022326540783979,
            -0.0215707179158926,
            0.019723521545529366,
            0.003390396013855934,
            0.03703063353896141,
            0.01758691482245922,
            -0.025958461686968803,
            0.03271380811929703,
            0.005448357667773962,
            0.026513192802667618,
            -0.029751373454928398,
            -0.027682911604642868,
            0.00420031463727355,
            0.025381388142704964,
            0.014389422722160816,
            0.013531358912587166,
            -0.0041835131123661995,
            -0.038177620619535446,
            0.01701793260872364,
            0.0068410360254347324,
            -0.02945038676261902,
            -0.06052108481526375,
            -0.007154243532568216,
            0.006300638429820538,
            -0.02507144585251808,
            0.029603315517306328,
            0.010456075891852379,
            -0.022645538672804832,
            -0.004581162240356207,
            -0.014887581579387188,
            0.034477829933166504,
            -0.06488315761089325,
            -0.024056047201156616,
            0.024812867864966393,
            0.029159318655729294,
            0.04393066093325615,
            0.025996698066592216,
            -0.001263010548427701,
            -0.033962149173021317,
            -0.01825101114809513,
            -0.0023436625488102436,
            0.035199057310819626,
            0.023026201874017715,
            -0.07032529264688492,
            0.007065666373819113,
            -0.054803695529699326,
            -0.01976148784160614,
            0.02289016917347908,
            -0.010824904777109623,
            -0.023969115689396858,
            -0.01794087514281273,
            -0.0504579097032547,
            -0.019080201163887978,
            0.021517911925911903,
            0.0041485745459795,
            -0.030334921553730965,
            0.024850407615303993,
            -0.019993895664811134,
            0.015114553272724152,
            0.01947803795337677,
            -0.00518545089289546,
            0.01774321310222149,
            0.00035737341386266053,
            -0.027511505410075188,
            -0.03959731012582779,
            0.029347646981477737,
            0.0017669060034677386,
            0.01585782691836357,
            0.026310915127396584,
            -0.03758247569203377,
            -0.01950143091380596,
            0.0008058467647060752,
            0.029282281175255775,
            -0.008833473548293114,
            0.02233240380883217,
            -0.010042441077530384,
            -0.009516092017292976,
            -0.007279722485691309,
            0.02604738064110279,
            -0.00474183913320303,
            0.005384881980717182,
            -0.017795681953430176,
            0.0017816162435337901,
            -0.011348878033459187,
            -0.04245494678616524,
            -0.013260859064757824,
            -0.0017048903973773122,
            0.020348330959677696,
            0.010003232397139072,
            0.028897244483232498,
            -0.02501494809985161,
            -0.0397021621465683,
            0.0007595219067297876,
            0.029104752466082573,
            0.029768364503979683,
            0.0031194500625133514,
            -0.0220407135784626,
            0.015810126438736916,
            -0.005081976298242807,
            -0.005492002237588167,
            0.011170933023095131,
            0.03861231729388237,
            0.008024272508919239,
            -0.008087078109383583,
            0.005602637771517038,
            0.020441478118300438,
            0.010158536955714226,
            0.002150484826415777,
            0.019795052707195282,
            -0.005917011294513941,
            -0.015811115503311157,
            -0.0057671694085001945,
            0.026946667581796646,
            -0.03021584078669548,
            -0.03031133860349655,
            -0.013727327808737755,
            -0.009331326000392437,
            0.027090448886156082,
            -0.01038386020809412,
            0.01389478612691164,
            0.030504612252116203,
            0.017603658139705658,
            -0.02872094139456749,
            -0.0003254211042076349,
            0.002593268407508731,
            0.004973356146365404,
            0.0016487067332491279,
            -0.023345274850726128,
            0.014683576300740242,
            -0.010357165709137917,
            0.011813942342996597,
            -0.010228483006358147,
            -0.015522829256951809,
            -0.02139490842819214,
            -0.019445238634943962,
            0.030445009469985962,
            -0.026441393420100212,
            0.029772117733955383,
            0.01418555248528719,
            0.0041745989583432674,
            -0.05706928297877312,
            0.004187676124274731,
            0.012078077532351017,
            -0.024059448391199112,
            -0.048483964055776596,
            0.01132651511579752,
            -0.010146882385015488,
            -0.014941358007490635,
            -0.02240266092121601,
            -0.03010275773704052,
            -0.01593899540603161,
            0.030184529721736908,
            -0.013081682845950127,
            0.018243087455630302,
            0.0231460090726614,
            -0.018163319677114487,
            0.017977509647607803,
            -0.015101615339517593,
            -0.010533394291996956,
            -0.014682445675134659,
            -0.009153386577963829,
            0.03183810040354729,
            -0.07388809323310852,
            -0.010133328847587109,
            0.004502386320382357,
            -0.010189533233642578,
            -0.0275880116969347,
            0.015036790631711483,
            0.02310471050441265,
            0.010156895965337753,
            0.0017977497773244977,
            -0.017317570745944977,
            0.02840958535671234,
            0.0016643971903249621,
            -0.025666115805506706,
            -0.007165557239204645,
            -0.003150483127683401,
            0.001572099863551557,
            -0.013644897378981113,
            0.018430007621645927,
            -0.01574012264609337,
            0.019662437960505486,
            0.005804604385048151,
            0.0021833577193319798,
            0.004531501792371273,
            -0.01284743007272482,
            0.027523010969161987,
            -0.003974332474172115,
            0.010160928592085838,
            -0.024496056139469147,
            -0.034368377178907394,
            -0.0031852072570472956,
            0.009321027435362339,
            -0.0041681923903524876,
            -0.0387045294046402,
            -0.017627673223614693,
            -0.02023821324110031,
            0.0026385621167719364,
            -0.005496027879416943,
            0.018221361562609673,
            -0.0034650596790015697,
            0.014796862378716469,
            0.0027803077828139067,
            0.02143525891005993,
            -0.02281477302312851,
            0.019198618829250336,
            0.0011534952791407704,
            -0.021779825910925865,
            -0.006226058583706617,
            0.012491472996771336,
            0.0037982745561748743,
            0.01103598065674305,
            0.02807227522134781,
            0.01577153243124485,
            0.02194366604089737,
            0.02449750155210495,
            -0.0019311940995976329,
            0.004746150225400925,
            -0.010080510750412941,
            0.028154633939266205,
            -0.019266217947006226,
            -0.01656932570040226,
            -0.025508489459753036,
            -0.007076964247971773,
            -0.008331592194736004,
            -0.014857860282063484,
            -0.03226703032851219,
            0.0252174511551857,
            -0.013216146267950535,
            0.019126253202557564,
            0.009010898880660534,
            -0.01580331102013588,
            -0.003239954821765423,
            -0.007191296201199293,
            0.0077965157106518745,
            -0.023126743733882904,
            -0.002552724676206708,
            -0.0120554743334651,
            -0.0018772740149870515,
            -0.008785455487668514,
            -0.017125828191637993,
            0.025070425122976303,
            0.007841020822525024,
            -0.03912617638707161,
            -0.026298847049474716,
            -0.02303166873753071,
            -0.013945036567747593,
            -0.013648216612637043,
            -0.013658479787409306,
            -0.0035159257240593433,
            -0.007658176589757204,
            -0.01410588063299656,
            0.022394483909010887,
            0.03414824977517128,
            -0.0004502086667343974,
            -0.010158894583582878,
            0.04188661649823189,
            0.007430849131196737,
            0.018307561054825783,
            -0.012764282524585724,
            0.005817611236125231,
            -0.05156271159648895,
            0.020020274445414543,
            -0.0024818661622703075,
            -0.02910170890390873,
            -0.041689470410346985,
            0.003167941467836499,
            -0.003698450280353427,
            0.011311693117022514,
            0.031087763607501984,
            -0.02465757541358471,
            -0.008929447270929813,
            0.022174496203660965,
            -0.0046182177029550076,
            -0.041080813854932785,
            -0.02401409111917019,
            0.029935097321867943,
            -0.026950497180223465,
            -0.044822610914707184,
            0.01865638978779316,
            0.02588512748479843,
            -0.04629753530025482,
            -0.015152721665799618,
            -0.023873891681432724,
            -0.005924870725721121,
            -0.025775011628866196,
            0.07287794351577759,
            -0.02665974758565426,
            -0.02142123319208622,
            -0.013087423518300056,
            0.028212150558829308,
            -0.009705182164907455,
            -0.0010222923010587692,
            0.0036716219037771225,
            -0.03619423136115074,
            -0.023563135415315628,
            -0.03076814115047455,
            0.002938619116321206,
            -0.018231691792607307,
            -0.02042199857532978,
            -0.01240873709321022,
            0.033312972635030746,
            -0.012466139160096645,
            0.010608955286443233,
            -0.008370103314518929,
            0.020962418988347054,
            0.018362045288085938,
            0.020646892488002777,
            0.020026681944727898,
            0.019112015143036842,
            0.0214243084192276,
            0.008108432404696941,
            -0.017884083092212677,
            0.028120925650000572,
            -0.028608346357941628,
            -0.008518810383975506,
            0.004207357298582792,
            -0.018280060961842537,
            -0.03720192611217499,
            -0.021542798727750778,
            0.011000956408679485,
            -0.028018159791827202,
            -0.0009703512187115848,
            0.050899848341941833,
            -0.020055292174220085,
            -0.022434890270233154,
            -0.01865706592798233,
            0.03235815465450287,
            -0.0031950040720403194,
            -0.012505360879004002,
            -0.033325303345918655,
            0.007564797531813383,
            0.0024602958001196384,
            -0.045707304030656815,
            -0.014752821065485477,
            -0.030238570645451546,
            0.0338185653090477,
            -0.014118313789367676,
            -0.027558114379644394,
            0.018125727772712708,
            -0.0424732081592083,
            -0.03452547267079353,
            0.008920094929635525,
            0.014614230021834373,
            0.009940270334482193,
            -0.002663287101313472,
            -0.010376685298979282,
            -0.018352502956986427,
            -0.01768987625837326,
            0.025329776108264923,
            -0.049736879765987396,
            -0.013328608125448227,
            0.02389039844274521,
            -0.021726781502366066,
            0.008393417112529278,
            0.002652390394359827,
            -0.006784722674638033,
            0.032964348793029785,
            -0.011839868500828743,
            0.012921989895403385,
            -0.007825267501175404,
            0.01741425134241581,
            -0.02956155128777027,
            0.06346472352743149,
            0.012662750668823719,
            -0.032009873539209366,
            -0.006378276273608208,
            0.006569380406290293,
            0.06617535650730133,
            0.011721368879079819,
            -0.0032625331077724695,
            0.04769347980618477,
            0.023051485419273376,
            -0.028683697804808617,
            0.010647744871675968,
            -0.043927859514951706,
            0.015078187920153141,
            -0.020486358553171158,
            0.0008806491969153285,
            0.007760039065033197,
            0.02199159562587738,
            -0.006933075375854969,
            -0.003773423144593835,
            -0.006999843288213015,
            -0.029077373445034027,
            0.033953431993722916,
            -0.030718836933374405,
            -0.025153987109661102,
            -0.016711676493287086,
            0.018569454550743103,
            -0.028004473075270653,
            -0.023464525118470192,
            -0.028422804549336433,
            0.03220800682902336,
            0.0016929828561842442,
            -0.01093907468020916,
            0.010013063438236713,
            -0.03186441585421562,
            -0.004872053861618042,
            -0.0344751700758934,
            0.023352419957518578,
            -0.006433425471186638,
            0.012262940406799316,
            -0.010041303932666779,
            0.014662361703813076,
            -0.010841635055840015,
            -0.022726871073246002,
            0.0366782546043396,
            0.004793716594576836,
            -0.0306708961725235,
            0.006872445344924927,
            -0.007750984281301498,
            -0.020884620025753975,
            0.008845793083310127,
            -0.01308937557041645,
            -0.022984260693192482,
            0.021150123327970505,
            -0.0015185526572167873,
            -0.016517646610736847,
            -0.005396944470703602,
            -0.02969954162836075,
            0.019619053229689598,
            -0.002634830540046096,
            0.001232568291015923,
            0.030462656170129776,
            -0.004874151200056076,
            -0.02560327760875225,
            0.03078904189169407,
            -0.009229985065758228,
            0.015853838995099068,
            -0.030730698257684708,
            0.020203737542033195,
            -0.007935122586786747,
            -0.008869188837707043,
            0.02087172120809555,
            0.04381943494081497,
            0.02012324519455433,
            0.020614473149180412,
            0.019634831696748734,
            0.0318048931658268,
            0.008740287274122238,
            0.0010928870178759098,
            -0.02881285361945629,
            -0.03234803304076195,
            0.02012779377400875,
            0.003957700449973345,
            0.023505140095949173,
            -0.013264082372188568,
            -0.04032029211521149,
            0.03841637447476387,
            0.02304958738386631,
            0.0017095142975449562,
            0.00792994350194931,
            0.004207790829241276,
            0.018274296075105667,
            0.023857861757278442,
            0.02982071042060852,
            -0.0052246395498514175,
            -0.002816205844283104,
            -0.03214472532272339,
            -0.023616190999746323,
            0.008227195590734482,
            -0.006574408616870642,
            0.022739168256521225,
            0.041916023939847946,
            0.017154542729258537,
            -0.0231794286519289,
            0.02549239993095398,
            0.015349623747169971,
            -0.025185704231262207,
            -0.0031810083892196417,
            -0.006511612795293331,
            0.030357934534549713,
            0.027510052546858788,
            0.02582031860947609,
            0.027216285467147827,
            0.018366554751992226,
            0.03203029930591583,
            -0.03924109786748886,
            0.012458066456019878,
            0.02097024954855442,
            0.03807708993554115,
            -0.000782843038905412,
            -0.015949703752994537,
            0.021160563454031944,
            -0.06075388565659523,
            0.00021591009863186628,
            0.03330514580011368,
            0.007860413752496243,
            0.008196898736059666,
            0.030293239280581474,
            0.007412704173475504,
            0.005851957481354475,
            0.013984805904328823,
            -0.017067722976207733,
            -0.03257587552070618,
            0.006001979112625122,
            0.008868096396327019,
            0.023579606786370277,
            -0.00218137726187706,
            -0.012368724681437016,
            0.003605400677770376,
            0.0171097032725811,
            -0.016563063487410545,
            -0.019783977419137955,
            -0.006019624415785074,
            -0.008098996244370937,
            -0.008087088353931904,
            0.0013164824340492487,
            -0.029378896579146385,
            0.0013161347014829516,
            0.0012300738599151373,
            -0.039231155067682266,
            -0.038645874708890915,
            -0.03732872009277344,
            -0.007910680957138538,
            0.013520170003175735,
            -0.015671486034989357,
            -0.019975801929831505,
            0.06098612770438194,
            0.019074684008955956,
            0.015044182538986206,
            -0.019637148827314377,
            0.019684962928295135,
            0.023373648524284363,
            0.01575259491801262,
            0.017179125919938087,
            -0.013022411614656448,
            -0.0089836111292243,
            0.015471363440155983,
            0.01373362261801958,
            0.0196389090269804,
            -0.02177274413406849,
            -0.029023081064224243,
            -0.012161258608102798,
            0.015232465229928493,
            0.028684355318546295,
            0.002024569781497121,
            -0.019956322386860847,
            -0.030443526804447174,
            -0.014821154065430164,
            0.0051806289702653885,
            0.007463550195097923,
            -0.010188236832618713,
            0.027264351025223732,
            0.020189952105283737,
            -0.029116220772266388,
            0.024691006168723106,
            0.007468928582966328,
            0.014883501455187798,
            -0.015309653244912624,
            0.020732127130031586,
            -0.02291533350944519,
            -0.02738836035132408,
            0.025018224492669106,
            -0.01474730484187603,
            0.01654914766550064,
            0.0040822625160217285,
            9.001867874758318e-05,
            0.0036876043304800987,
            -0.022215459495782852,
            -0.019284961745142937,
            0.02032047137618065,
            -0.024710379540920258,
            0.021216122433543205,
            -0.03774761036038399,
            -0.0021586373914033175,
            0.014902200549840927,
            0.002873081248253584,
            0.001630811020731926,
            -0.00044157091178931296,
            0.013695930130779743,
            0.006449418142437935,
            0.0038187443278729916,
            0.017185775563120842,
            -0.025770239531993866,
            -0.0035527513828128576,
            0.003687795717269182,
            0.0014436477795243263,
            -0.010577919892966747,
            0.00592123344540596,
            0.01913611590862274,
            -0.004073884338140488,
            -0.0033221011981368065,
            -0.0060756513848900795,
            -0.05829313397407532,
            -0.002303465735167265,
            -0.03387824445962906,
            -0.030398229137063026,
            -0.02541414089500904,
            -0.02058068849146366,
            0.01792788691818714,
            -0.03069622628390789,
            -0.023820936679840088,
            -0.009118144400417805,
            0.004412863403558731,
            0.05442295968532562,
            0.02878684177994728,
            0.0012119263410568237,
            -0.06808377057313919,
            -0.06270701438188553,
            -0.030439866706728935,
            -0.039608560502529144,
            0.030614763498306274,
            -0.017673060297966003,
            -0.0017766124801710248,
            -0.02569298818707466,
            0.009308681823313236,
            0.005489395000040531,
            0.010733225382864475,
            -0.00746318232268095,
            -0.02011892758309841,
            -0.03626258298754692,
            -0.014105193316936493,
            -0.0051193335093557835,
            0.025607548654079437,
            -0.02434326894581318,
            0.0019422966288402677,
            -0.009587692096829414,
            -0.029664814472198486,
            -0.01744898408651352,
            -0.026846978813409805,
            0.017568504437804222,
            0.021324079483747482,
            0.010138037614524364,
            -0.02768120728433132,
            0.006179808173328638,
            0.013278414495289326,
            0.019068490713834763,
            -0.0044711739756166935,
            0.025100164115428925,
            0.040141209959983826,
            0.013750861398875713,
            0.018581843003630638,
            -0.03715432807803154,
            -0.03513696417212486,
            -0.026096783578395844,
            0.0013397365109995008,
            -0.019632214680314064,
            -0.024279853329062462,
            -0.0013242203276604414,
            -0.003717295592650771,
            0.01618734933435917,
            0.013182245194911957,
            -0.02274513430893421,
            0.00011259598250035197,
            0.017567887902259827,
            0.003064942080527544,
            0.048465147614479065,
            0.0055669015273451805,
            -0.027759617194533348,
            -0.00027111961389891803,
            -0.005394774954766035,
            0.019160272553563118,
            0.01933935098350048,
            0.029014453291893005,
            -0.0354786142706871,
            0.03472985699772835,
            -0.037594109773635864,
            -0.033700402826070786,
            0.03064342774450779,
            0.013501545414328575,
            0.02796829491853714,
            -0.015201923437416553,
            -0.002459166804328561,
            0.013794368132948875,
            0.02874894253909588,
            0.019778598099946976,
            -0.005770390387624502,
            0.024383049458265305,
            0.005844188388437033,
            -0.0028451778925955296,
            0.024247735738754272,
            -0.08057896047830582,
            0.006932605989277363,
            -0.014081837609410286,
            0.021162768825888634,
            0.022460807114839554,
            0.00029111921321600676,
            -0.033189453184604645,
            0.0036180962342768908,
            -0.022320996969938278,
            -0.04023435339331627,
            -0.0237913578748703,
            0.00811692513525486,
            0.0013709724880754948,
            -0.04559933766722679,
            0.03291274234652519,
            -0.026225628331303596,
            -0.020763836801052094,
            0.019922420382499695,
            -0.03840763121843338,
            -0.017377514392137527,
            0.03266512602567673,
            -0.02474175952374935,
            -0.018543709069490433,
            -0.01415084209293127,
            0.008388081565499306,
            0.003454159712418914,
            0.020701266825199127,
            0.017192361876368523,
            0.0034021814353764057,
            -0.025940973311662674,
            0.013839258812367916,
            -0.007854493334889412,
            0.0006725648418068886,
            0.005826489068567753,
            -0.01781608723104,
            -0.021982960402965546,
            0.05967901647090912,
            0.010048625990748405,
            -0.017420778051018715,
            -0.04267187789082527,
            0.024096449837088585,
            0.02227410115301609,
            0.02484065853059292,
            0.0518067367374897,
            0.022315455600619316,
            0.012789968401193619,
            0.030489595606923103,
            0.02336069568991661,
            -0.0019229671452194452,
            -0.001805878127925098,
            -0.036751266568899155,
            0.01576893776655197,
            0.005650216713547707,
            -0.012924262322485447,
            0.03466615453362465,
            -0.01775151677429676,
            -0.05429249629378319,
            -0.030381230637431145,
            -0.02007177658379078,
            -0.01752823032438755,
            -0.016763027757406235,
            -0.006563354749232531,
            0.015456472523510456,
            -0.017112255096435547,
            0.005900300573557615,
            0.008046042174100876,
            -0.03141024336218834,
            0.027245499193668365,
            0.03316391259431839,
            0.004858721047639847,
            -0.007751347962766886,
            0.012035961262881756,
            -0.013237481005489826,
            -0.041386280208826065,
            0.0161895751953125,
            0.005932827014476061,
            0.01405493076890707,
            0.02715279906988144,
            0.0474373996257782,
            0.014133216813206673,
            -0.006179595831781626,
            -0.0013703504810109735,
            0.014903880655765533,
            0.03174983710050583,
            0.006086898501962423,
            -0.02547328919172287,
            0.03335142135620117,
            0.011710031889379025,
            -0.03280126303434372,
            0.020561272278428078,
            0.0073565770871937275,
            -0.03282930329442024,
            0.013644534163177013,
            -0.006562752183526754,
            -0.0003446638002060354,
            -0.03080233559012413,
            -0.027924640104174614,
            0.021129192784428596,
            -0.014397094026207924,
            -0.048684362322092056,
            -0.015196610242128372,
            0.007389532867819071,
            -0.002216395689174533,
            0.0013989811995998025,
            0.02667924575507641,
            -0.02355276234447956,
            0.022638866677880287,
            0.017103256657719612,
            -0.016342679038643837,
            0.02398413047194481,
            -0.01944354921579361,
            0.015709903091192245,
            -0.030437719076871872,
            0.027766641229391098,
            -0.02200421504676342,
            -0.02352055534720421,
            -0.004602150991559029,
            -0.012290201149880886,
            0.0015887869521975517,
            0.021430615335702896,
            -0.000517082866281271,
            0.006395814940333366,
            -0.0244961678981781,
            0.02250327356159687,
            -0.04282251000404358,
            -0.033072635531425476,
            0.013911000452935696,
            0.01234978623688221,
            -0.023413628339767456,
            -0.01413175743073225,
            0.012644232250750065,
            -0.018260449171066284,
            0.0051234387792646885,
            0.0012553503038361669,
            0.01650177128612995,
            -0.02212712913751602,
            0.0029126007575541735,
            -0.03956764191389084,
            -0.01753954216837883,
            -0.017067020758986473,
            0.01287863776087761,
            -0.018773207440972328,
            0.02155519463121891,
            0.009214208461344242,
            0.030434725806117058,
            -0.01795962080359459,
            0.03505644574761391,
            0.014781168662011623,
            -0.008202984929084778,
            0.026030700653791428,
            9.245231922250241e-05,
            0.011188652366399765,
            0.05279351770877838,
            0.028803786262869835,
            -0.027215586975216866,
            -0.010416229255497456,
            0.01738058775663376,
            -0.030565381050109863,
            -0.022443223744630814,
            0.0016699577681720257,
            -0.017658118158578873,
            0.01711900718510151,
            -0.03220053389668465,
            0.026790011674165726,
            0.05926287919282913,
            -0.00440826965495944,
            -0.016984647139906883,
            0.018281450495123863,
            0.03193861246109009,
            -0.03252730518579483,
            -0.03627101331949234,
            0.017827993258833885,
            0.005201254040002823,
            -0.026119213551282883,
            0.007457190193235874,
            -0.02920263260602951,
            -0.02396967262029648,
            -0.003278760937973857,
            -0.03527773916721344,
            0.008001121692359447,
            -0.0016903121722862124,
            0.032209139317274094,
            0.03287535533308983,
            -0.021055595949292183,
            -0.01637868396937847,
            -0.019534215331077576,
            0.04401600360870361,
            -0.0005216464633122087,
            0.0006211481522768736,
            -0.020901691168546677
        ],
        [
            -0.02094360999763012,
            0.030254388228058815,
            0.026870962232351303,
            0.03597285598516464,
            -0.0011754324659705162,
            -0.018568655475974083,
            -0.01405609492212534,
            0.025044048205018044,
            -0.009326809085905552,
            0.029783356934785843,
            0.013763176277279854,
            -0.012757912278175354,
            -0.02677042968571186,
            0.03297789394855499,
            0.07022326439619064,
            0.021284697577357292,
            0.0023420017678290606,
            -0.01688809134066105,
            -0.021605418995022774,
            -0.02046026475727558,
            -0.018154751509428024,
            0.01592938043177128,
            -0.007242762017995119,
            0.022794345393776894,
            0.03211631625890732,
            -0.022727709263563156,
            0.00481984531506896,
            -0.007690455764532089,
            -0.023608606308698654,
            0.017443018034100533,
            -0.01787865161895752,
            0.03297624737024307,
            0.020738668739795685,
            -0.015557130798697472,
            -0.02079194411635399,
            0.044194675981998444,
            0.0077775283716619015,
            -0.013508426956832409,
            0.016688289120793343,
            -0.012611936777830124,
            0.012610296718776226,
            0.020152317360043526,
            0.018188806250691414,
            -0.02486405521631241,
            0.03500746190547943,
            0.03075607866048813,
            0.011132296174764633,
            0.020260905846953392,
            -0.01979496143758297,
            0.007953718304634094,
            0.0235613901168108,
            -0.019951382651925087,
            -0.013470127247273922,
            -0.026200221851468086,
            -0.024360762909054756,
            0.03669597953557968,
            -0.012476719915866852,
            0.028704363852739334,
            0.012226738967001438,
            -0.03626204654574394,
            -0.009186817333102226,
            0.028090601786971092,
            -0.005235041491687298,
            -0.009248831309378147,
            0.03310028836131096,
            -0.007804969325661659,
            0.002472115680575371,
            -0.0181189626455307,
            0.024568986147642136,
            -0.025242136791348457,
            -0.005500734783709049,
            0.0017501269467175007,
            0.003172372467815876,
            -0.013642570935189724,
            -0.02726435847580433,
            -0.01681053824722767,
            -0.0028392875101417303,
            0.013690092600882053,
            0.03399967402219772,
            -0.0023686522617936134,
            0.02194265089929104,
            0.011283676140010357,
            0.024120772257447243,
            -0.00023473547480534762,
            0.004433022812008858,
            0.011883613653481007,
            -0.026974469423294067,
            0.01806449331343174,
            -0.006570938043296337,
            -0.010649592615664005,
            -0.01081904023885727,
            0.007015223614871502,
            0.033653952181339264,
            -0.013892573304474354,
            0.023198967799544334,
            -0.0027879218105226755,
            0.009675334207713604,
            0.03666707128286362,
            0.004736688453704119,
            0.009428012184798717,
            0.004112826660275459,
            -0.014522443525493145,
            -0.01885569840669632,
            -0.02402140200138092,
            0.021965399384498596,
            -0.005897626280784607,
            0.029703743755817413,
            -0.007514205761253834,
            -0.017140764743089676,
            -0.02437719516456127,
            0.026376189664006233,
            0.00458883959800005,
            -0.004735277965664864,
            0.02521582320332527,
            -0.04110843315720558,
            0.02257400192320347,
            -0.019507508724927902,
            0.0364825464785099,
            -0.00923321396112442,
            -0.009321426041424274,
            -0.01218142919242382,
            -0.029787948355078697,
            0.05067559331655502,
            -0.009935414418578148,
            0.04213739559054375,
            -0.008101257495582104,
            -0.022728661075234413,
            0.01808948628604412,
            -0.020431924611330032,
            0.016516726464033127,
            0.03761659562587738,
            0.012116769328713417,
            0.03436070308089256,
            0.04861864447593689,
            -0.019514163956046104,
            0.0035840128548443317,
            0.0034772383514791727,
            0.005386875011026859,
            0.0025745390448719263,
            0.005448658484965563,
            -0.012436860240995884,
            0.019699251279234886,
            -0.023896412923932076,
            0.032070890069007874,
            -0.022850502282381058,
            0.050847914069890976,
            -0.026623304933309555,
            0.020553741604089737,
            -0.019968882203102112,
            0.022168267518281937,
            0.0167365912348032,
            -0.0027463398873806,
            0.02269204705953598,
            -0.02568008005619049,
            -0.024754418060183525,
            -0.001118540414609015,
            0.020905250683426857,
            0.005328197032213211,
            -0.022607309743762016,
            0.020611347630620003,
            0.005601426120847464,
            -0.022796453908085823,
            -0.008267802186310291,
            -0.008478081785142422,
            0.010288870893418789,
            -0.006472606677561998,
            0.026351632550358772,
            -0.02095296047627926,
            0.01282462291419506,
            -0.025866378098726273,
            0.029249217361211777,
            0.01328675914555788,
            -0.03492917865514755,
            -0.015455619432032108,
            -0.01057082787156105,
            0.015906086191534996,
            0.020056283101439476,
            -0.0060891686007380486,
            0.019210927188396454,
            0.01707877404987812,
            -0.009527265094220638,
            0.0025554827880114317,
            -0.022605210542678833,
            0.033492255955934525,
            0.017757778987288475,
            -0.025557594373822212,
            0.016916634514927864,
            0.022704247385263443,
            -0.023265205323696136,
            0.022024542093276978,
            0.00042972827213816345,
            -0.018145715817809105,
            0.036218367516994476,
            -0.004812389612197876,
            0.001353576430119574,
            0.0317726768553257,
            -0.00026019549113698304,
            0.019049309194087982,
            0.007767741102725267,
            0.01145400945097208,
            0.005524524487555027,
            0.03590033948421478,
            0.012489461340010166,
            -0.01569748856127262,
            0.017728859558701515,
            0.0237131267786026,
            0.04476790502667427,
            0.02190437726676464,
            0.026263585314154625,
            -0.029738623648881912,
            -0.021753404289484024,
            0.04814836010336876,
            -0.025832679122686386,
            0.02894587069749832,
            0.03255412355065346,
            0.0020660553127527237,
            0.029933197423815727,
            -0.027079131454229355,
            -0.028209805488586426,
            -0.026853160932660103,
            -0.025954173877835274,
            -0.0010508494451642036,
            0.01774459518492222,
            -0.03956896439194679,
            -0.017750412225723267,
            0.01459529623389244,
            -0.04489309713244438,
            0.004473868291825056,
            -0.021572228521108627,
            0.023308176547288895,
            0.018086906522512436,
            0.019032586365938187,
            -0.00026324254577048123,
            0.0223266389220953,
            0.014286372810602188,
            0.0019037906313315034,
            0.005365962628275156,
            0.03134884312748909,
            -0.002015710109844804,
            -0.028438247740268707,
            0.014888894744217396,
            -0.007857304997742176,
            -0.021906763315200806,
            -0.021557651460170746,
            -0.027839209884405136,
            0.004460698459297419,
            -0.02942650392651558,
            -0.01369043905287981,
            0.0026106275618076324,
            0.014046096242964268,
            -0.015850448980927467,
            -0.02333703637123108,
            0.002270331373438239,
            0.005719888489693403,
            -0.020644957199692726,
            -0.025522708892822266,
            -0.013665825128555298,
            -0.03208613395690918,
            -0.011616279371082783,
            -0.005204525776207447,
            0.020120203495025635,
            -0.008137280121445656,
            -0.0027926003094762564,
            -0.021356945857405663,
            0.03992874175310135,
            0.011306166648864746,
            -0.004089090507477522,
            -0.023487765341997147,
            -0.009687487967312336,
            0.009981944225728512,
            0.00029014903702773154,
            0.024105101823806763,
            -0.011511115357279778,
            -0.002326761605218053,
            -0.04930340498685837,
            0.030727677047252655,
            -0.015999559313058853,
            0.0021004045847803354,
            -0.00150820252019912,
            0.02584178000688553,
            0.046873290091753006,
            0.0026863792445510626,
            0.004127346444875002,
            0.02410385012626648,
            -0.03591780737042427,
            0.02952667325735092,
            -0.02826094441115856,
            -0.02708740346133709,
            -0.01440935768187046,
            -0.013575426302850246,
            -0.019062165170907974,
            0.021303299814462662,
            -0.007405624259263277,
            -0.0006716152420267463,
            0.0056072743609547615,
            -0.003810644382610917,
            -0.021316468715667725,
            0.025715786963701248,
            0.005866335239261389,
            -0.003246316919103265,
            -0.019089194014668465,
            -0.002690864261239767,
            -0.018057500943541527,
            0.034850358963012695,
            0.009902151301503181,
            -0.006708740256726742,
            -0.026288893073797226,
            0.03632485866546631,
            -0.030359556898474693,
            -0.017096195369958878,
            -0.0012220669304952025,
            -0.02853788249194622,
            0.008006814867258072,
            0.014354593120515347,
            -0.028960343450307846,
            0.01123793050646782,
            -0.02604714408516884,
            -0.020412469282746315,
            0.009472626261413097,
            -0.016616754233837128,
            -0.003598398994654417,
            -0.018050605431199074,
            -0.03484521806240082,
            0.03341259807348251,
            -0.021162739023566246,
            -0.019857944920659065,
            0.0015927175991237164,
            -0.022398075088858604,
            0.023817967623472214,
            -0.01747877337038517,
            0.047833360731601715,
            -0.022982053458690643,
            0.013166261836886406,
            0.00022304555750451982,
            -0.005656185559928417,
            -0.006113230250775814,
            0.011469520628452301,
            -0.007144239265471697,
            -0.02357289008796215,
            0.012211399152874947,
            -0.035964056849479675,
            -0.0047144354321062565,
            -0.03874058276414871,
            0.01793346367776394,
            -0.014929414726793766,
            -0.007852548733353615,
            0.01923150010406971,
            -0.02320243790745735,
            0.02740778587758541,
            -0.01307599525898695,
            0.04007226973772049,
            -0.013737334869801998,
            -0.017050493508577347,
            -0.00085706717800349,
            0.031246811151504517,
            -0.0003622770600486547,
            -0.015968505293130875,
            0.008084006607532501,
            -0.006775595247745514,
            -0.0033011145424097776,
            -0.029400480911135674,
            0.001415991224348545,
            0.015097241848707199,
            -0.012224351987242699,
            -0.00950815249234438,
            -0.011736731976270676,
            0.009817131794989109,
            -0.0067425984889268875,
            0.011393384076654911,
            0.003273336449638009,
            0.023691413924098015,
            0.017959512770175934,
            0.025029907003045082,
            0.031221924349665642,
            0.020606255158782005,
            0.024099960923194885,
            0.020931752398610115,
            0.0283801406621933,
            -0.0272692684084177,
            -0.0075324769131839275,
            0.015666548162698746,
            0.021141275763511658,
            0.013174238614737988,
            0.01524986233562231,
            0.01622137613594532,
            0.003973620943725109,
            -0.024586815387010574,
            0.04509821906685829,
            0.0024289595894515514,
            -0.03077508509159088,
            0.02275986596941948,
            0.025061072781682014,
            0.04066169634461403,
            -0.017040986567735672,
            -0.01683010160923004,
            -0.00426982156932354,
            0.00661149388179183,
            0.02168954350054264,
            0.014447939582169056,
            -0.02978399023413658,
            0.03295302391052246,
            0.011957786045968533,
            0.020408516749739647,
            0.011036481708288193,
            -0.004385160282254219,
            0.0017990164924412966,
            -0.006189216393977404,
            0.02777094766497612,
            0.0005502313724718988,
            0.013773195445537567,
            0.017034340649843216,
            0.022055605426430702,
            -0.022709323093295097,
            0.0011741791386157274,
            -0.024275878444314003,
            0.0017965375445783138,
            -0.02865315042436123,
            0.022324809804558754,
            0.009703551419079304,
            -0.0037378445267677307,
            -0.008415652438998222,
            0.029353724792599678,
            -0.030174193903803825,
            -0.0031515518203377724,
            -0.01957087032496929,
            0.007774143945425749,
            0.0076166559010744095,
            0.020412370562553406,
            -0.0073487586341798306,
            0.0014733474235981703,
            0.008312690071761608,
            0.004589830990880728,
            -0.004564725793898106,
            0.01576763018965721,
            -0.0240367129445076,
            -0.02452845871448517,
            0.0025382786989212036,
            0.0068913837894797325,
            -0.014166063629090786,
            -0.012792956084012985,
            0.006794644519686699,
            0.03156226500868797,
            0.019586319103837013,
            0.011159236542880535,
            0.036856308579444885,
            -0.026121139526367188,
            0.0009246285771951079,
            0.01933836191892624,
            0.03552781790494919,
            0.002891083713620901,
            -0.011796004138886929,
            -0.004796887747943401,
            -0.028651153668761253,
            0.024603361263871193,
            -0.0039653354324400425,
            0.021354325115680695,
            -0.017167581245303154,
            0.023711636662483215,
            -0.02568800374865532,
            -0.008623041212558746,
            0.02778717875480652,
            0.019652264192700386,
            0.0032218180131167173,
            -0.0027000063564628363,
            0.027093255892395973,
            -0.0061293779872357845,
            0.02336716279387474,
            0.007575100753456354,
            0.021489227190613747,
            0.02922460064291954,
            -0.018691930919885635,
            0.03180907294154167,
            -0.012653159908950329,
            -0.014641789719462395,
            0.02425178699195385,
            0.01758899912238121,
            -0.0070358384400606155,
            0.011634737253189087,
            0.02097097598016262,
            0.03213362395763397,
            -0.005756111349910498,
            -0.014532690867781639,
            0.05239037796854973,
            -0.001829029293730855,
            0.02774125710129738,
            0.025610821321606636,
            -0.001093643601052463,
            0.01721319369971752,
            0.0376269705593586,
            0.01878339797258377,
            0.04105424880981445,
            0.01737537980079651,
            -0.020039241760969162,
            -0.012106729671359062,
            0.02306762896478176,
            0.03267217054963112,
            -0.011576944962143898,
            0.028818657621741295,
            -0.011278001591563225,
            -0.0003189992858096957,
            0.024178138002753258,
            0.024377167224884033,
            -0.011034647934138775,
            -0.007082210388034582,
            -0.02067059837281704,
            -0.00460151256993413,
            -0.019066154956817627,
            -0.004232748877257109,
            0.01981331780552864,
            0.0035884524695575237,
            0.019409557804465294,
            0.02538861706852913,
            -0.01973717287182808,
            0.017604060471057892,
            -0.005073982756584883,
            0.001534916809760034,
            -0.003261402016505599,
            -0.0016887624515220523,
            0.033504463732242584,
            0.0027211604174226522,
            0.03180423751473427,
            0.017719920724630356,
            0.03310172259807587,
            -0.018881600350141525,
            -0.00415579229593277,
            0.02422698773443699,
            -0.001430495292879641,
            0.014073021709918976,
            -0.012477139942348003,
            -0.013036714866757393,
            -0.013425573706626892,
            -0.006951857823878527,
            0.0253805760294199,
            0.0063412669114768505,
            0.019954154267907143,
            0.020742136985063553,
            0.021798094734549522,
            0.016858862712979317,
            -0.03066292032599449,
            -0.013260236009955406,
            0.034665584564208984,
            -0.019272495061159134,
            -0.01708473451435566,
            -0.023044845089316368,
            -0.029056096449494362,
            0.03207822144031525,
            0.0007342362077906728,
            0.03984281048178673,
            -0.0044065904803574085,
            -0.0168248750269413,
            0.015927784144878387,
            0.006451327819377184,
            0.015920812264084816,
            -0.006526880897581577,
            -0.02302081137895584,
            -0.003271721303462982,
            -0.016679715365171432,
            -0.006514905486255884,
            -0.0015520598972216249,
            -0.018071681261062622,
            0.0068345689214766026,
            0.02849535085260868,
            0.002244708128273487,
            0.030332069844007492,
            0.005287818145006895,
            0.01627177558839321,
            -0.012521430850028992,
            0.019849343225359917,
            0.02472820319235325,
            -0.019881995394825935,
            -0.026636580005288124,
            0.04011218622326851,
            0.02354530803859234,
            -0.022422976791858673,
            0.0040966528467834,
            -0.010069474577903748,
            -0.012592712417244911,
            0.02194235846400261,
            -0.014227005653083324,
            0.01257872674614191,
            -0.020187905058264732,
            -0.013255753554403782,
            0.023255087435245514,
            0.0014604347525164485,
            -0.016479115933179855,
            0.02401338331401348,
            -0.0014910183381289244,
            0.027927756309509277,
            -0.006704126484692097,
            0.01819695346057415,
            -0.006670474074780941,
            0.02277827076613903,
            0.0020050019957125187,
            -0.008849350735545158,
            -0.02254788763821125,
            -0.00741595821455121,
            -0.002163937082514167,
            -0.022002296522259712,
            -0.026813596487045288,
            0.014134212397038937,
            0.018883991986513138,
            0.013777229003608227,
            0.0075569297187030315,
            0.022367579862475395,
            -0.027729958295822144,
            0.003932686988264322,
            -0.005770339630544186,
            -0.020703010261058807,
            -0.02571689896285534,
            0.01532407384365797,
            0.013685728423297405,
            -0.026896895840764046,
            -0.02511574514210224,
            -0.028579792007803917,
            0.007707836572080851,
            0.0044722179882228374,
            0.004026115871965885,
            0.020662575960159302,
            0.028443321585655212,
            -0.00876440666615963,
            -0.007719297427684069,
            0.037250421941280365,
            -0.0086643286049366,
            -0.020754924044013023,
            0.020610330626368523,
            -0.00885237380862236,
            0.021710960194468498,
            -0.013006332330405712,
            0.006615614518523216,
            -0.0069892797619104385,
            0.0029725038912147284,
            0.018268601968884468,
            -0.003447899827733636,
            -0.02883722260594368,
            0.01167240645736456,
            0.015288672409951687,
            0.012284962460398674,
            -0.04207921773195267,
            0.007862468250095844,
            0.02093583531677723,
            0.008463037200272083,
            -0.03242044523358345,
            0.04323757439851761,
            0.007392565254122019,
            0.007533314172178507,
            0.012517519295215607,
            0.045719537883996964,
            0.01272064633667469,
            0.0009373496868647635,
            -0.02521434985101223,
            -0.022345110774040222,
            -0.016574816778302193,
            0.006500916555523872,
            -0.021823391318321228,
            -0.02171579748392105,
            -0.0260838083922863,
            0.04187591001391411,
            -0.015517438761889935,
            0.011066243052482605,
            -0.005575636401772499,
            -0.003933947533369064,
            -0.03237263485789299,
            -0.011426769196987152,
            0.006033125799149275,
            -0.007902394980192184,
            0.006206848192960024,
            -0.0008425356354564428,
            -0.0042373077012598515,
            0.009186116978526115,
            0.02094143256545067,
            -0.006619930267333984,
            -0.020061546936631203,
            -0.0352712906897068,
            0.02706342563033104,
            -0.01518036238849163,
            0.025788599625229836,
            0.014881470240652561,
            -0.03191361203789711,
            -0.03171282634139061,
            0.016192998737096786,
            -0.017433883622288704,
            -0.03274025768041611,
            -0.014669477008283138,
            0.00957523100078106,
            -0.014341930858790874,
            0.012942398898303509,
            0.00858364999294281,
            0.011728436686098576,
            -0.03293167054653168,
            0.013252371922135353,
            -0.04302588105201721,
            0.010195829905569553,
            -0.018631312996149063,
            0.0022900744806975126,
            -0.019761964678764343,
            -0.005475249141454697,
            0.010903502814471722,
            -0.000492058286909014,
            -0.03550050035119057,
            -0.004821679089218378,
            0.00046102190390229225,
            0.02403377741575241,
            -0.005618629977107048,
            -0.016684310510754585,
            -0.02921181730926037,
            0.01424431148916483,
            0.013925420120358467,
            0.029920509085059166,
            0.0005484913708642125,
            0.05246439576148987,
            -0.003410465782508254,
            0.02889929711818695,
            -0.006570678204298019,
            -0.020424040034413338,
            0.02702241949737072,
            0.020644227042794228,
            0.0014338414184749126,
            -0.030308466404676437,
            0.010763532482087612,
            -0.026431424543261528,
            -0.00218264595605433,
            0.003064581658691168,
            -0.03223910555243492,
            -0.0035508398432284594,
            0.003141016000881791,
            -0.0019938629120588303,
            -0.007634733337908983,
            0.027818432077765465,
            -0.012679778970777988,
            -0.028539737686514854,
            -0.014755137264728546,
            -0.007706570439040661,
            0.029381001368165016,
            -0.025985578075051308,
            0.005111127160489559,
            -0.00982698891311884,
            -0.036025237292051315,
            0.018651483580470085,
            0.01191822998225689,
            0.024265605956315994,
            -0.00423330208286643,
            0.04173538461327553,
            0.03669283539056778,
            -0.02553342655301094,
            0.03164273872971535,
            -0.02762150578200817,
            0.026065118610858917,
            -0.019443372264504433,
            -0.027491336688399315,
            -0.014109225012362003,
            -0.018276982009410858,
            -0.019628148525953293,
            -0.003909587860107422,
            0.037065841257572174,
            -0.020643465220928192,
            -0.02365189418196678,
            0.010156871750950813,
            -0.014732887037098408,
            0.025069070979952812,
            0.013708018697798252,
            0.00718466192483902,
            0.053437504917383194,
            0.01872549206018448,
            -0.0090510044246912,
            0.009230924770236015,
            0.02274232916533947,
            -0.021904530003666878,
            0.031395357102155685,
            0.020286880433559418,
            -0.02458575740456581,
            -0.02805223874747753,
            -0.016750982031226158,
            0.027305549010634422,
            0.0003042662574443966,
            -0.008644216693937778,
            0.019356828182935715,
            0.053981680423021317,
            0.04098236560821533,
            0.004827211610972881,
            0.0008560570422559977,
            0.004362589679658413,
            -0.01682652346789837,
            0.02224286086857319,
            -0.010543063282966614,
            0.015932133421301842,
            0.0037244560662657022,
            0.007404177915304899,
            0.01157882809638977,
            0.015673192217946053,
            0.014665978029370308,
            -0.019963817670941353,
            0.027265172451734543,
            -0.014716759324073792,
            0.016493069007992744,
            -0.028453044593334198,
            0.024780642241239548,
            0.022925226017832756,
            0.02352214977145195,
            0.0057980651035904884,
            0.00908541027456522,
            -0.008826015517115593,
            0.03516220673918724,
            -0.01768041029572487,
            0.008228049613535404,
            0.02910498157143593,
            0.024704504758119583,
            0.011492924764752388,
            0.008103888481855392,
            0.0011892621405422688,
            0.016147149726748466,
            0.044724367558956146,
            0.010961591266095638,
            -0.004540519323199987,
            -0.025118252262473106,
            0.02664388157427311,
            0.015817463397979736,
            -0.021532010287046432,
            0.003013551700860262,
            -0.0039724987000226974,
            -0.0016528490232303739,
            -0.017633942887187004,
            0.03247363120317459,
            0.025371914729475975,
            -0.032968323677778244,
            -0.02377755008637905,
            0.017639189958572388,
            -0.011429915204644203,
            0.010970385745167732,
            -0.024321895092725754,
            -0.009150492027401924,
            0.04105587303638458,
            -0.007509394083172083,
            -0.00395496329292655,
            -0.018657609820365906,
            -0.018594590947031975,
            0.021695489063858986,
            -0.024692479521036148,
            0.035377245396375656,
            -0.02017044648528099,
            -0.030619949102401733,
            0.015470743179321289,
            -0.0025493688881397247,
            0.013928401283919811,
            -0.006102759391069412,
            0.026644140481948853,
            -0.0024020953569561243,
            -0.011125270277261734,
            0.03002275712788105,
            0.015886878594756126,
            0.008841969072818756,
            -0.009884818457067013,
            -0.025028757750988007,
            0.0193963423371315,
            -0.014697256498038769,
            0.00321475719101727,
            -0.002733473666012287,
            0.014395470730960369,
            -0.018101278692483902,
            -0.01793682388961315,
            0.020443135872483253,
            0.006554247811436653,
            -0.004458713345229626,
            -0.017994893714785576,
            0.020850781351327896,
            0.013270987197756767,
            -0.01070636697113514,
            -0.025314735248684883,
            0.00763299036771059,
            0.012911049649119377,
            0.022578703239560127,
            -0.021411990746855736,
            -0.035357024520635605,
            -0.004254968836903572,
            -0.0331142358481884,
            0.015269443392753601,
            0.027685245499014854,
            0.0016327588818967342,
            0.049215786159038544,
            0.000302300468320027,
            0.027318472042679787,
            0.002895029727369547,
            -0.009688084945082664,
            0.021902011707425117,
            0.023001939058303833,
            0.026313619688153267,
            0.01558990590274334,
            0.028365163132548332,
            0.04185156524181366,
            0.03083004802465439,
            0.009886488318443298,
            -0.02464744821190834,
            0.014104022644460201,
            0.010861233808100224,
            0.004712496884167194,
            -0.012520921416580677,
            -0.024363230913877487,
            -0.018118469044566154,
            0.007521416991949081,
            -0.015263755805790424,
            0.021585185080766678,
            0.01525927521288395,
            -0.031516533344984055,
            -0.0011881855316460133,
            0.01102992333471775,
            0.02072392776608467,
            0.0756661668419838,
            0.01395274605602026,
            0.004433942027390003,
            -0.022865070030093193,
            0.02221093513071537,
            -0.04843399301171303,
            0.02779284492135048,
            -0.0026584824081510305,
            -0.010870140045881271,
            -0.0127145666629076,
            0.018140384927392006,
            -0.004165417980402708,
            0.028977643698453903,
            0.0043173860758543015,
            0.012323707342147827,
            0.02156675048172474,
            0.030574778094887733,
            0.032984063029289246,
            0.014723990112543106,
            0.010033206082880497,
            -0.0004953564493916929,
            -0.0035994311328977346,
            -0.030750567093491554,
            0.023537131026387215,
            0.014471013098955154,
            0.025717196986079216,
            0.02522938884794712,
            -0.015609309077262878,
            0.02648264542222023,
            0.005253423936665058,
            0.021499590948224068,
            -0.01792013645172119,
            0.007671714760363102,
            -0.021183518692851067,
            -0.004066402558237314,
            0.03589272126555443,
            0.01297491043806076,
            0.04191935434937477,
            -0.0050560408271849155,
            0.02760491333901882,
            -0.014245192520320415,
            -0.024570047855377197,
            0.029516737908124924,
            0.002621263964101672,
            -0.012977923266589642,
            0.038727208971977234,
            -0.010136905126273632,
            -0.024510812014341354,
            -0.007835406810045242,
            0.016726423054933548,
            -0.0013024677755311131,
            0.012174544855952263,
            -0.013035224750638008,
            0.0043418388813734055,
            -0.004527953919023275,
            0.01624106429517269,
            0.027373291552066803,
            0.01707513816654682,
            -0.03669832646846771,
            -0.011158037930727005,
            0.010074404999613762,
            0.014120211824774742,
            -0.013296026736497879,
            0.03332680091261864,
            -0.006409808993339539,
            -0.020289484411478043,
            0.006161888130009174,
            0.02293943054974079,
            0.02289467304944992,
            0.007702506612986326,
            -0.00423016119748354,
            0.005839489866048098,
            0.01905321516096592,
            0.03021935187280178,
            -0.02092183567583561,
            0.0201120488345623,
            0.011351970955729485,
            0.03748186677694321,
            0.011216453276574612,
            -0.010749557986855507,
            -0.0015242475783452392,
            0.002652503317221999,
            -0.0071890573017299175,
            -0.004836944863200188,
            0.0021656302269548178,
            -0.03165983036160469,
            0.014383800327777863,
            0.024832528084516525,
            -0.006481072399765253,
            0.014537091366946697,
            0.04175269976258278,
            -0.026453495025634766,
            0.018840957432985306,
            0.017561668530106544,
            0.016884390264749527,
            -0.007443161215633154,
            0.019451750442385674,
            0.028247728943824768,
            -0.029132110998034477,
            -0.01777091808617115,
            0.010576961562037468,
            0.011068305000662804,
            -0.026016876101493835,
            0.022174645215272903,
            -0.006578438449651003,
            -0.027898412197828293,
            0.032902542501688004,
            -0.022292139008641243,
            -0.02520257793366909,
            0.004167273640632629,
            0.018165403977036476,
            0.00026294856797903776,
            0.027493327856063843,
            0.006037424318492413,
            0.005828666500747204,
            -0.03619381785392761,
            0.04104399308562279,
            -0.025390518829226494,
            -0.018577001988887787
        ],
        [
            0.007628546096384525,
            0.01059299148619175,
            -0.0033937618136405945,
            0.028765209019184113,
            -0.024614127352833748,
            -0.002347084926441312,
            -0.0021041110157966614,
            0.016715629026293755,
            -0.02306586131453514,
            -0.03733691945672035,
            0.04181947186589241,
            -0.0312618687748909,
            -0.017703957855701447,
            0.012520410120487213,
            -0.030970152467489243,
            0.03239644318819046,
            0.01876845583319664,
            0.019818833097815514,
            0.0013771450612694025,
            -0.011827852576971054,
            -0.03130630776286125,
            -0.010569960810244083,
            -0.01633201725780964,
            -0.0005398154607973993,
            0.05018911138176918,
            0.02551574632525444,
            0.02879614196717739,
            -0.011965211480855942,
            0.012154007330536842,
            -0.006900626700371504,
            0.008529572747647762,
            0.004827657248824835,
            0.013511746190488338,
            -0.00850617978721857,
            0.01962353102862835,
            0.008278721943497658,
            0.024269845336675644,
            0.012771588750183582,
            -0.001930392812937498,
            0.0008770584827288985,
            -0.012439480982720852,
            0.02691705711185932,
            0.0069402726367115974,
            0.004581550136208534,
            -0.021899210289120674,
            -0.025954479351639748,
            -0.024160241708159447,
            0.04306303709745407,
            0.008342121727764606,
            0.02425052598118782,
            0.014914958737790585,
            -0.01734805479645729,
            0.037928834557533264,
            0.03236859291791916,
            -0.03784223645925522,
            0.010796726681292057,
            0.02567867934703827,
            0.0197798702865839,
            -0.026183418929576874,
            0.015402029268443584,
            0.003926331643015146,
            -0.018174514174461365,
            -0.002641405211761594,
            0.008292780257761478,
            -0.030242208391427994,
            0.028540533035993576,
            0.0339852049946785,
            0.013114153407514095,
            -0.026947665959596634,
            0.011321096681058407,
            0.0014895644271746278,
            -0.007914756424725056,
            -0.014686226844787598,
            0.005600043572485447,
            0.029032811522483826,
            0.025239162147045135,
            -0.0017197993583977222,
            0.023023677989840508,
            0.003911423496901989,
            0.011211170814931393,
            -0.01879097707569599,
            0.016482621431350708,
            -0.02704373560845852,
            -0.011757615953683853,
            -0.011866308748722076,
            0.03278660401701927,
            0.01911308988928795,
            0.01287537906318903,
            0.02249598503112793,
            -0.013565702363848686,
            -0.043563321232795715,
            0.010525817982852459,
            0.005936160683631897,
            -0.011160694994032383,
            -0.020859505981206894,
            -0.029487062245607376,
            0.03124644048511982,
            -0.015561267733573914,
            -0.021759536117315292,
            -0.018590575084090233,
            0.042236097157001495,
            0.02975856326520443,
            0.017374051734805107,
            -0.02121157757937908,
            -0.005409092642366886,
            0.0032145578879863024,
            0.007854575291275978,
            0.01963384449481964,
            0.029803739860653877,
            -0.0020599165000021458,
            -0.017872221767902374,
            0.007511314935982227,
            -0.008710828609764576,
            -0.024430889636278152,
            0.02912241593003273,
            0.0169222354888916,
            0.0015262229135259986,
            -0.055519770830869675,
            0.0008934980141930282,
            -0.03906651958823204,
            -0.0019048142712563276,
            0.010938198305666447,
            -0.02125847525894642,
            0.02579323761165142,
            0.004586672876030207,
            -0.011007596738636494,
            0.015511549077928066,
            0.016293950378894806,
            0.010994121432304382,
            0.003069837111979723,
            0.02448473498225212,
            -0.00870516523718834,
            -0.05638999119400978,
            0.012372065335512161,
            -0.0001679693814367056,
            -0.022217584773898125,
            -0.022332092747092247,
            -0.002264426089823246,
            -0.02054574340581894,
            -0.025398191064596176,
            -0.028418682515621185,
            -0.01810489222407341,
            -0.01566520519554615,
            -0.012226010672748089,
            -0.022894393652677536,
            -0.03858712688088417,
            0.022584091871976852,
            0.013011529110372066,
            -0.011291071772575378,
            0.026969460770487785,
            -0.025909004732966423,
            -0.017376083880662918,
            0.01960725337266922,
            0.011162921786308289,
            0.016227860003709793,
            -0.0188103336840868,
            -0.006359354127198458,
            -0.052733298391103745,
            -0.017723724246025085,
            -0.04105353355407715,
            -0.03253290802240372,
            -0.01766141876578331,
            0.025968709960579872,
            -0.02716054394841194,
            0.020296623930335045,
            -0.017240839079022408,
            0.03293813019990921,
            0.029204055666923523,
            -0.037501245737075806,
            -0.031382277607917786,
            0.02203802764415741,
            0.02510818839073181,
            -0.00592509051784873,
            0.01582879014313221,
            0.0069849309511482716,
            0.02307954989373684,
            0.025339901447296143,
            -0.009300456382334232,
            0.0341467410326004,
            -0.010062404908239841,
            0.012680644169449806,
            0.019349848851561546,
            0.018037524074316025,
            0.0337933748960495,
            -0.03721046820282936,
            0.03265942633152008,
            -0.010777926072478294,
            0.01213554386049509,
            -0.022967783734202385,
            0.016421843320131302,
            -0.0047981697134673595,
            -0.011881892569363117,
            -0.07890934497117996,
            -0.019718145951628685,
            0.02622407115995884,
            -0.03073791414499283,
            -0.00833804439753294,
            0.02630925551056862,
            -0.0072104912251234055,
            -0.006084043066948652,
            -0.027116812765598297,
            -0.008693914860486984,
            -0.004291580058634281,
            -0.03623776137828827,
            0.03453641012310982,
            -0.025796866044402122,
            0.07085103541612625,
            -0.015054026618599892,
            0.02289932407438755,
            -0.025503937155008316,
            -0.017554568126797676,
            0.012620149180293083,
            -0.027595363557338715,
            -0.009855649434030056,
            0.00689734285697341,
            0.023439958691596985,
            -0.009900194592773914,
            0.016261329874396324,
            0.022532081231474876,
            0.031004685908555984,
            0.030183866620063782,
            -0.03413368761539459,
            0.02661600522696972,
            0.009419023990631104,
            -0.004888471215963364,
            0.041040707379579544,
            -0.024921435862779617,
            0.0077212112955749035,
            -4.5701021008426324e-05,
            0.01841440424323082,
            -0.005501600448042154,
            -0.0020849613938480616,
            -0.0010845907963812351,
            -0.012008429504930973,
            -0.00826194230467081,
            -0.0014492615591734648,
            0.006348972208797932,
            -0.02431463450193405,
            -0.010818560607731342,
            0.019119475036859512,
            -0.017034178599715233,
            0.02212359569966793,
            0.005893295630812645,
            0.003552665002644062,
            0.0053958105854690075,
            -0.0006350345793180168,
            -0.030362412333488464,
            -0.018736140802502632,
            -0.013052391819655895,
            -0.011226859875023365,
            0.026826905086636543,
            -0.016863496974110603,
            0.00723821297287941,
            0.020816057920455933,
            0.020810235291719437,
            0.014481307938694954,
            -0.038093771785497665,
            -0.023089181631803513,
            -0.01776248589158058,
            0.05640523508191109,
            -0.030905092135071754,
            -0.020327502861618996,
            -0.020978081971406937,
            0.016581764444708824,
            0.005327886436134577,
            0.0042490651831030846,
            0.0248800627887249,
            -0.010133657604455948,
            -0.0381956472992897,
            -0.0053540500812232494,
            -0.022517239674925804,
            -0.011480357497930527,
            0.012705731205642223,
            -0.018324971199035645,
            0.017713194712996483,
            -0.025531265884637833,
            -0.005298166535794735,
            -0.006247251760214567,
            -0.012408631853759289,
            0.015747420489788055,
            -0.0027039111591875553,
            0.0008605993934907019,
            -0.02620355226099491,
            0.013850349932909012,
            -0.014494630508124828,
            -0.006949096452444792,
            -0.03085952438414097,
            0.04023035615682602,
            0.02214999869465828,
            -0.03319152444601059,
            -0.01281854510307312,
            -0.030980942770838737,
            -0.028997240588068962,
            0.023363502696156502,
            -0.025623701512813568,
            0.005865086801350117,
            -0.00842906441539526,
            0.03489314019680023,
            0.017238732427358627,
            -0.018350768834352493,
            0.017768394201993942,
            -0.005861165467649698,
            -0.03249914571642876,
            -0.03387456759810448,
            0.026654457673430443,
            -0.03341040015220642,
            -0.011438937857747078,
            -0.05026596039533615,
            0.0023727859370410442,
            0.012080298736691475,
            0.025688964873552322,
            -0.035596687346696854,
            0.02059297077357769,
            -0.0032561609987169504,
            -0.0035571116022765636,
            0.031754933297634125,
            0.01869538240134716,
            -0.015095224604010582,
            -0.021658331155776978,
            -0.02012876607477665,
            -0.01823863573372364,
            0.0412520170211792,
            0.05373824015259743,
            0.012251568026840687,
            0.02845008485019207,
            0.009773940779268742,
            -0.007277708034962416,
            -0.006436486262828112,
            0.015013761818408966,
            0.0041935499757528305,
            0.02670140750706196,
            0.0041771880351006985,
            0.03498566523194313,
            -0.03774731606245041,
            0.01998564600944519,
            -0.00032340240431949496,
            -0.020549675449728966,
            -0.021549100056290627,
            0.019392557442188263,
            -0.011245664209127426,
            -0.016823258250951767,
            -0.021399272605776787,
            0.010604890063405037,
            -0.005365008022636175,
            -0.03780554607510567,
            -0.028576087206602097,
            -0.0009026031475514174,
            -0.012638560496270657,
            0.014517512172460556,
            0.033967941999435425,
            -0.035139281302690506,
            -0.0082970280200243,
            0.05522054061293602,
            -0.0071019562892615795,
            -0.019314665347337723,
            -0.01486144308000803,
            0.013706717640161514,
            0.02331533655524254,
            -0.015956105664372444,
            0.02694116346538067,
            -0.01854516752064228,
            -0.0029342519119381905,
            0.03466058149933815,
            0.02363688312470913,
            0.033459387719631195,
            0.014392111450433731,
            -0.003438100451603532,
            0.02094068005681038,
            -0.028482692316174507,
            0.020974209532141685,
            0.021571677178144455,
            -0.016108494251966476,
            -0.0033289087004959583,
            0.025932155549526215,
            -0.020619980990886688,
            0.014017056673765182,
            0.021693674847483635,
            0.010992937721312046,
            0.0014952857745811343,
            0.014947067946195602,
            0.013748416677117348,
            0.006924492307007313,
            -0.03386819735169411,
            -0.011254445649683475,
            0.005231095012277365,
            0.002077201148495078,
            0.017768019810318947,
            0.0013231112388893962,
            -0.016887545585632324,
            0.00016959731874521822,
            0.00801295880228281,
            0.0028568091802299023,
            0.01496032439172268,
            0.02496817149221897,
            -0.037094950675964355,
            0.01253835391253233,
            0.00946828443557024,
            -0.04958198219537735,
            -0.022320888936519623,
            -0.011567886918783188,
            -0.011856082826852798,
            0.022018736228346825,
            -0.02645128220319748,
            -0.009538053534924984,
            -0.0322149433195591,
            0.01687607541680336,
            -0.0008257919107563794,
            -0.007908256724476814,
            -0.023788249120116234,
            0.006240623537451029,
            -0.03301462531089783,
            0.038719095289707184,
            0.02876879647374153,
            0.0323534794151783,
            0.00042274637962691486,
            0.01072312518954277,
            0.0031893395353108644,
            -0.015860339626669884,
            -0.01850489154458046,
            -0.002030598931014538,
            -0.011769924312829971,
            -0.03670214116573334,
            0.029317688196897507,
            0.004527951590716839,
            0.0292064156383276,
            0.044207654893398285,
            -0.0007813473348505795,
            0.019052252173423767,
            0.01911177858710289,
            0.017961889505386353,
            -0.026609057560563087,
            -0.004768396727740765,
            0.04359874874353409,
            0.018788913264870644,
            -0.019494758918881416,
            0.03439141437411308,
            -0.007356429938226938,
            0.03721637278795242,
            -0.012300779111683369,
            0.01932746358215809,
            0.024922611191868782,
            0.026661621406674385,
            0.01179542951285839,
            0.00836185459047556,
            0.00639167707413435,
            -0.003680140944197774,
            0.007459876127541065,
            -0.03172026574611664,
            0.024269182235002518,
            0.0038389181718230247,
            -0.010386685840785503,
            0.012926540337502956,
            0.053529612720012665,
            -0.0030914240051060915,
            0.019871119409799576,
            0.019597694277763367,
            0.00027248859987594187,
            -0.017222659662365913,
            0.0051829940639436245,
            0.004022399894893169,
            0.02912154607474804,
            -0.012416766956448555,
            0.02303871512413025,
            0.034335844218730927,
            0.0069742584601044655,
            -0.019524645060300827,
            0.009262584149837494,
            -0.02282704971730709,
            -0.0025792093947529793,
            -0.02806934528052807,
            0.03636468946933746,
            -0.0012185754021629691,
            0.012172698974609375,
            -0.040333665907382965,
            0.027892284095287323,
            0.023061338812112808,
            0.018743472173810005,
            0.037090592086315155,
            0.01123503316193819,
            -0.004003588575869799,
            -0.024312961846590042,
            -0.0345003604888916,
            0.02099205181002617,
            0.016360357403755188,
            0.048098232597112656,
            0.03360316529870033,
            -0.029474180191755295,
            -0.019610747694969177,
            -0.003909704275429249,
            0.028778277337551117,
            -0.03730606660246849,
            -0.028050873428583145,
            0.004114883951842785,
            -0.020989369601011276,
            0.0533408597111702,
            -0.012612958438694477,
            -0.0017121643759310246,
            -0.014311871491372585,
            0.006684356834739447,
            -0.008409632369875908,
            -0.012363358400762081,
            -0.003856882220134139,
            0.022114107385277748,
            -0.06195170804858208,
            -0.005184534937143326,
            -0.01566123031079769,
            -0.03298591822385788,
            -0.019193457439541817,
            0.020888492465019226,
            0.00477700075134635,
            -0.004525141324847937,
            -0.011825230903923512,
            0.00959527213126421,
            0.011756912805140018,
            -0.007505421526730061,
            -0.015449412167072296,
            0.007618728093802929,
            0.03513246402144432,
            -0.020904652774333954,
            0.011669076979160309,
            -0.026647819206118584,
            0.010526686906814575,
            0.030235135927796364,
            0.02619144506752491,
            -0.03012007288634777,
            0.04263877123594284,
            -0.06581701338291168,
            0.047032829374074936,
            -0.01153965387493372,
            -0.0699346736073494,
            0.00812273658812046,
            0.005892116576433182,
            -0.03767160326242447,
            -0.0005753987352363765,
            -0.03431534394621849,
            -0.02356700971722603,
            -0.005217527970671654,
            0.00013764429604634643,
            0.03425523266196251,
            -0.01319461315870285,
            -0.009694921784102917,
            -0.03275219723582268,
            -0.005335996393114328,
            -0.030657600611448288,
            0.004462215583771467,
            -0.030063241720199585,
            0.009080640971660614,
            -0.018033042550086975,
            0.002533514052629471,
            -0.024105923250317574,
            -0.02663593553006649,
            0.02856755256652832,
            -0.005576240364462137,
            -0.038780782371759415,
            -0.033770397305488586,
            -0.01968601532280445,
            0.01466990727931261,
            0.0038870596326887608,
            0.0181968342512846,
            -0.01607505790889263,
            0.03237593546509743,
            -0.020864836871623993,
            0.012844905257225037,
            0.03044126369059086,
            0.019590390846133232,
            -0.016344916075468063,
            0.005701662506908178,
            -0.014174805954098701,
            0.002733897417783737,
            0.02867412008345127,
            -0.007971887476742268,
            -0.014444573782384396,
            0.019398078322410583,
            -0.006619394291192293,
            -0.0167095847427845,
            -0.006150609813630581,
            -0.006172506604343653,
            0.025817830115556717,
            0.01986345276236534,
            -0.024546509608626366,
            0.0028640758246183395,
            -0.025612711906433105,
            -0.020725635811686516,
            0.029885947704315186,
            -0.020608946681022644,
            0.01711479388177395,
            0.003739158157259226,
            0.00822871271520853,
            0.0014286895748227835,
            -0.005913828033953905,
            0.008803353644907475,
            -0.0250407662242651,
            -0.01378145907074213,
            -0.022628847509622574,
            -0.01577126421034336,
            -0.0045391591265797615,
            -0.007396791595965624,
            0.019163167104125023,
            0.023626940324902534,
            0.02389986626803875,
            -0.011600668542087078,
            0.019655898213386536,
            -0.030323980376124382,
            0.006042176857590675,
            -0.022845126688480377,
            -0.049557920545339584,
            0.009361045435070992,
            -0.00421385420486331,
            0.004121207166463137,
            0.017226018011569977,
            -0.005977382883429527,
            0.0010816592257469893,
            0.006305116228759289,
            -0.02136620134115219,
            0.027779266238212585,
            -0.005151958670467138,
            -0.00033322355011478066,
            -0.03328852355480194,
            -0.029274994507431984,
            -0.029964083805680275,
            -0.03649042919278145,
            0.030944351106882095,
            -0.026590291410684586,
            0.02854800410568714,
            0.01642131432890892,
            -0.04081452265381813,
            0.02550463192164898,
            0.018980706110596657,
            -0.0218027513474226,
            0.020072802901268005,
            0.026897938922047615,
            0.030603516846895218,
            0.005799995269626379,
            -0.020174000412225723,
            -0.053698569536209106,
            0.014506768435239792,
            0.013615384697914124,
            0.0045642368495464325,
            0.023560399189591408,
            -0.0015203808434307575,
            -0.011667399667203426,
            -0.014007559046149254,
            0.01110775489360094,
            -0.009552582167088985,
            -0.009591844864189625,
            0.004404755774885416,
            -0.00513573968783021,
            0.004777086898684502,
            -0.022112153470516205,
            0.02314886450767517,
            0.03371380269527435,
            -0.052471909672021866,
            0.010848441161215305,
            0.013067469000816345,
            -0.007793269120156765,
            0.032464805990457535,
            0.035019271075725555,
            -0.02254946529865265,
            0.03039158508181572,
            -0.0004892421420663595,
            0.0340140238404274,
            0.02843504585325718,
            -0.030494417995214462,
            0.04526014253497124,
            0.025655260309576988,
            0.021496737375855446,
            -0.01526253018528223,
            0.0010990304872393608,
            0.004529266152530909,
            -0.004078640136867762,
            -0.009686309844255447,
            -0.0033654100261628628,
            -0.01090836152434349,
            0.0047889030538499355,
            0.014576159417629242,
            0.03729676082730293,
            0.0037327746395021677,
            0.0097748301923275,
            -0.013621353544294834,
            0.00873672217130661,
            0.023335972800850868,
            -0.01924571581184864,
            0.020194515585899353,
            -0.014847836457192898,
            -0.004191953223198652,
            -0.030712848529219627,
            -0.0035868447739630938,
            0.021775901317596436,
            -0.010592322796583176,
            0.005391022656112909,
            0.043684180825948715,
            0.02230234630405903,
            0.030070841312408447,
            -0.008978527039289474,
            -0.026838725432753563,
            -0.002022006083279848,
            0.016427332535386086,
            0.007830223999917507,
            -0.02045252174139023,
            0.0036301545333117247,
            0.027897654101252556,
            -0.000675172486808151,
            0.011255277320742607,
            0.01817193441092968,
            -0.009630490094423294,
            -0.00443230988457799,
            0.021720679476857185,
            -0.02617526240646839,
            0.005162516608834267,
            0.020495356991887093,
            -0.02494751289486885,
            -0.0017666834173724055,
            0.004952588118612766,
            0.016850920394062996,
            0.005485106259584427,
            -0.004585944581776857,
            0.022950196638703346,
            -0.025156686082482338,
            -0.024269936606287956,
            0.011328373104333878,
            -0.04655780270695686,
            -0.03509390726685524,
            -0.03564167022705078,
            0.056944865733385086,
            -0.013637065887451172,
            0.03561879321932793,
            -0.026387643069028854,
            0.01922323927283287,
            -0.0011518072569742799,
            0.015374467708170414,
            -0.027931706979870796,
            -0.021470924839377403,
            0.016162732616066933,
            -0.03565949946641922,
            -0.002661757869645953,
            0.031639695167541504,
            0.004828623495995998,
            -0.016743840649724007,
            0.05183195322751999,
            -0.008430448360741138,
            -0.005332303233444691,
            -0.0035369882825762033,
            0.016526149585843086,
            -0.02217087149620056,
            0.02929621934890747,
            0.008519492112100124,
            -0.03405056893825531,
            0.03194912523031235,
            -0.040705032646656036,
            -0.008838542737066746,
            -0.011654510162770748,
            -0.010547983460128307,
            -0.02374926023185253,
            0.07571975886821747,
            -0.01440038625150919,
            0.003001352772116661,
            -0.023201869800686836,
            -0.017911063507199287,
            0.030409613624215126,
            0.021183067932724953,
            0.047509271651506424,
            0.027323324233293533,
            0.012849249877035618,
            -0.00420465599745512,
            -0.02893972583115101,
            -0.004543526563793421,
            -0.0014123108703643084,
            -0.023624828085303307,
            0.0039553954266011715,
            -0.0021146400831639767,
            0.012051756493747234,
            0.0029992100317031145,
            -0.005807877518236637,
            -0.000610283634159714,
            0.01287273783236742,
            -0.006004410330206156,
            0.016996163874864578,
            0.0346171073615551,
            -0.025933317840099335,
            -0.03431497886776924,
            -0.0050664423033595085,
            0.00398644944652915,
            -0.03630684316158295,
            -0.009436650201678276,
            -0.02299845777451992,
            -0.025823531672358513,
            0.041677266359329224,
            -0.009668671526014805,
            -0.009362714365124702,
            9.428933844901621e-05,
            -0.00516680721193552,
            -0.009852061048150063,
            0.020762108266353607,
            -0.022912411019206047,
            0.009763505309820175,
            -0.011160966008901596,
            -0.02781635709106922,
            0.0013113449094817042,
            0.006052514538168907,
            -0.02677709050476551,
            0.009188382886350155,
            0.029975557699799538,
            -0.0009231664589606225,
            0.06384498625993729,
            -0.04178664833307266,
            -0.021613821387290955,
            0.015011616982519627,
            0.024390941485762596,
            0.014753133058547974,
            0.015344821847975254,
            0.0069344537332654,
            0.014444300904870033,
            -0.018093349412083626,
            -0.02198364958167076,
            0.02625497244298458,
            0.006734043825417757,
            -0.027478717267513275,
            0.02767019532620907,
            -0.020889408886432648,
            -0.017017565667629242,
            -0.013422961346805096,
            -0.0311009231954813,
            -0.002242227317765355,
            -0.027928711846470833,
            0.008410371840000153,
            0.01327856071293354,
            -0.02340514399111271,
            -0.0008255868451669812,
            -0.013446301221847534,
            -0.009291539900004864,
            0.04507376253604889,
            0.006594802252948284,
            -0.016514092683792114,
            -0.03365876525640488,
            0.023748481646180153,
            0.016869554296135902,
            0.02964753285050392,
            0.02269265055656433,
            0.027184708043932915,
            0.0336737334728241,
            0.01529755163937807,
            -0.0247366763651371,
            -0.03037080727517605,
            -0.02555476687848568,
            -0.0279445368796587,
            -0.02277018129825592,
            0.007025573868304491,
            -0.0085263317450881,
            -0.018268367275595665,
            -0.008395467884838581,
            -0.031796395778656006,
            -0.015925273299217224,
            0.06761528551578522,
            -0.030471915379166603,
            0.0012196768075227737,
            0.014628046192228794,
            -0.0192424189299345,
            -0.02918987348675728,
            0.04192328825592995,
            0.006001094821840525,
            -0.013631784357130527,
            0.003246578387916088,
            0.0011742645874619484,
            -0.010722664184868336,
            -0.009714554995298386,
            0.021377986297011375,
            0.022664954885840416,
            0.02951442264020443,
            0.020706910640001297,
            0.024092793464660645,
            0.0176711305975914,
            0.004567498341202736,
            -0.015966910868883133,
            0.00908696185797453,
            0.04344026371836662,
            0.037555914372205734,
            0.004670698661357164,
            0.02668614685535431,
            0.028389189392328262,
            0.018747124820947647,
            0.02515084110200405,
            0.009309608489274979,
            0.01752387173473835,
            -0.014613412320613861,
            -0.016518739983439445,
            -0.03545548766851425,
            0.01920478790998459,
            0.025246387347579002,
            -0.006826137192547321,
            0.00043091081897728145,
            -0.018432922661304474,
            0.032762352377176285,
            -0.01320989616215229,
            0.004215788096189499,
            0.08290420472621918,
            0.0031800242140889168,
            0.014538105577230453,
            0.01814909651875496,
            -0.024690527468919754,
            0.01496339961886406,
            -0.010955830104649067,
            -0.00966165866702795,
            -0.004418309777975082,
            0.012401701882481575,
            0.04528773948550224,
            0.03606472909450531,
            -0.03055136278271675,
            0.0178108848631382,
            0.006571135018020868,
            0.024645807221531868,
            0.01631968282163143,
            0.025070102885365486,
            -0.007728711701929569,
            0.007120310328900814,
            0.016464751213788986,
            -0.0027457214891910553,
            -0.012438353151082993,
            -0.0019658501259982586,
            0.0048696380108594894,
            -0.01794356293976307,
            0.01090334914624691,
            0.009972178377211094,
            -0.00941402930766344,
            -0.02101982571184635,
            0.02599567174911499,
            -0.018973592668771744,
            0.03239583969116211,
            0.01584845781326294,
            0.0068561844527721405,
            0.009692588821053505,
            0.022343935444951057,
            0.022671811282634735,
            0.022230496630072594,
            -0.013372994028031826,
            0.02414294332265854,
            -0.014438033103942871,
            -0.0035518724471330643,
            -0.02158772572875023,
            -0.0038644757587462664,
            -0.01579689048230648,
            0.018731821328401566,
            0.013670441694557667,
            -0.007529479451477528,
            0.035610273480415344,
            0.007847392931580544,
            0.017448220402002335,
            -0.0008455592906102538,
            0.05017652362585068,
            -8.855288615450263e-05,
            0.026888234540820122,
            0.006695839110761881,
            -0.027153540402650833,
            0.001964395632967353,
            0.004365974571555853,
            0.023317772895097733,
            -0.009010388515889645,
            -0.014637946151196957,
            0.04072409123182297,
            0.04572768881917,
            0.015074362978339195,
            -0.03699110820889473,
            0.01533801294863224,
            0.006623585242778063,
            0.015383879654109478,
            0.02518889121711254,
            -0.013956570997834206,
            -0.024053798988461494,
            -0.027413317933678627,
            -0.008509461767971516,
            -0.02473943494260311,
            -0.029661567881703377,
            -0.018758192658424377,
            -0.010265715420246124,
            -0.020330490544438362,
            0.005907286889851093,
            0.0028459839522838593,
            0.006410548463463783,
            0.027657363563776016,
            -0.013573761098086834,
            -0.016739405691623688,
            -0.03406951576471329,
            -0.00935351476073265,
            -0.021174607798457146,
            -0.02333563007414341,
            -0.011188569478690624,
            0.019312046468257904,
            0.01283684466034174,
            0.02055130898952484,
            -0.006388495676219463,
            -0.008708844892680645,
            -0.032920632511377335,
            0.02137009985744953,
            -0.01484692096710205,
            -0.038248639553785324,
            -0.030949369072914124,
            0.03323781117796898,
            -0.0030857643578201532,
            -0.009554404765367508,
            -0.004352413583546877,
            0.023490242660045624,
            -0.02302193082869053,
            0.005092500243335962,
            0.02795657515525818,
            -0.011240845546126366,
            -0.006247417535632849,
            0.025896988809108734,
            -0.029311297461390495,
            0.0105626555159688,
            -0.019010093063116074,
            -0.00830759759992361,
            -0.01309066079556942,
            0.021854234859347343,
            -0.0006886263145133853,
            0.023854855448007584,
            0.012311618775129318,
            0.008322094567120075,
            0.01698899269104004,
            -0.015112058259546757,
            -0.002840300090610981,
            -0.0014187489869073033,
            -0.04075689986348152,
            0.03166273236274719,
            -0.022428134456276894,
            0.009086393751204014
        ],
        [
            -0.02839195355772972,
            -0.008697906509041786,
            0.004508402198553085,
            -0.040554799139499664,
            -0.015629179775714874,
            0.024131057783961296,
            0.003743756329640746,
            -0.01872086338698864,
            -0.01752818189561367,
            0.026983482763171196,
            -0.007250874303281307,
            -0.0029586844611912966,
            0.03420615196228027,
            0.02074226550757885,
            0.048488836735486984,
            -0.018211621791124344,
            0.035770729184150696,
            -0.04701320827007294,
            -0.015285513363778591,
            -0.0025682731065899134,
            -0.018318768590688705,
            0.0017072007758542895,
            -0.0177371297031641,
            0.01897096447646618,
            0.030662354081869125,
            0.004771933425217867,
            -0.06581780314445496,
            -0.026575949043035507,
            -0.03158998861908913,
            -0.01471372414380312,
            -0.002120374934747815,
            -0.028538886457681656,
            0.0070175048895180225,
            -0.013469785451889038,
            0.026784643530845642,
            -0.014318183995783329,
            0.025950154289603233,
            -0.008186891674995422,
            -0.006486809346824884,
            -0.03557804971933365,
            -0.08292127400636673,
            -0.01890365220606327,
            0.03958682715892792,
            -0.00949573889374733,
            -0.02858729101717472,
            -0.012777063064277172,
            0.03436555713415146,
            0.03276505321264267,
            -0.026120500639081,
            0.022237734869122505,
            0.01173752173781395,
            -0.012183311395347118,
            0.06511789560317993,
            0.026319749653339386,
            0.02387968823313713,
            -0.008744061924517155,
            0.014611983671784401,
            -0.003271590219810605,
            0.02517339214682579,
            -0.008972033858299255,
            -0.017458394169807434,
            0.02611888200044632,
            0.02712338976562023,
            0.0034271220210939646,
            0.019614573568105698,
            0.036428242921829224,
            0.047309473156929016,
            0.010711774230003357,
            -0.015831543132662773,
            -0.02253687009215355,
            -0.008879663422703743,
            -0.04959707334637642,
            -0.014086905866861343,
            -0.005784079432487488,
            0.016006391495466232,
            -0.005506453569978476,
            -0.028982218354940414,
            0.010026490315794945,
            0.04596802219748497,
            0.03209187090396881,
            0.007033074740320444,
            0.058415934443473816,
            -0.024349719285964966,
            -0.015592738054692745,
            -0.03427596390247345,
            0.050731491297483444,
            0.03550101816654205,
            0.00954975001513958,
            -0.003642226569354534,
            0.005958836991339922,
            0.010238352231681347,
            0.008070308715105057,
            -0.0032731432002037764,
            -0.005635740701109171,
            0.025716014206409454,
            0.04151274636387825,
            -0.027640296146273613,
            0.01959032006561756,
            -0.04518783465027809,
            0.014658787287771702,
            -0.00764936488121748,
            -0.014754099771380424,
            0.023357998579740524,
            0.0009386743768118322,
            -0.016138097271323204,
            -0.006080042105168104,
            0.037035565823316574,
            0.04881183058023453,
            0.006438363343477249,
            0.004476483445614576,
            0.004946591332554817,
            0.02876690961420536,
            0.009044460020959377,
            -0.005561915226280689,
            -0.01180257461965084,
            0.006154682021588087,
            -0.02150190807878971,
            -0.023652616888284683,
            0.0028568620327860117,
            -0.01291755959391594,
            -0.013671142049133778,
            -0.025156866759061813,
            -0.044992465525865555,
            0.011602869257330894,
            -0.037580352276563644,
            0.03308849781751633,
            0.02472301386296749,
            -0.014003519900143147,
            -0.017531823366880417,
            -0.019795425236225128,
            -0.002275655744597316,
            -0.042557958513498306,
            -0.01947813294827938,
            0.004260924179106951,
            0.010765166953206062,
            -0.029968949034810066,
            -0.002725158352404833,
            0.015736503526568413,
            0.002872804179787636,
            0.040878452360630035,
            0.025030367076396942,
            0.039070747792720795,
            0.003558633616194129,
            0.00955143477767706,
            0.02276415377855301,
            0.027004731819033623,
            -0.040663737803697586,
            -0.007876789197325706,
            -0.01172610092908144,
            -0.026244616135954857,
            0.01302686333656311,
            0.008370406925678253,
            -0.05954725295305252,
            0.018771223723888397,
            0.04432341456413269,
            0.01632150076329708,
            -0.010048212483525276,
            -0.00016200816025957465,
            -0.028784312307834625,
            -0.030546648427844048,
            -0.015410524792969227,
            0.01756175421178341,
            -0.022542722523212433,
            0.013374438509345055,
            0.014447499997913837,
            -0.0713644027709961,
            0.013810123316943645,
            -0.029797418043017387,
            0.0035451501607894897,
            -0.013640948571264744,
            -0.010855353437364101,
            0.0197303406894207,
            0.025652728974819183,
            0.019120734184980392,
            0.018190009519457817,
            0.020422806963324547,
            0.005258394870907068,
            0.04351956397294998,
            -0.0005347345722839236,
            -0.012574943713843822,
            0.017135988920927048,
            -0.008965741842985153,
            -0.007711487822234631,
            -0.01022843923419714,
            -0.005953946150839329,
            0.00211415090598166,
            -0.00574826542288065,
            0.011605961248278618,
            0.003738145111128688,
            -0.009497051127254963,
            -0.007010230794548988,
            -0.05636679753661156,
            0.0033031890634447336,
            -0.02840632013976574,
            0.012233786284923553,
            -0.0475088469684124,
            0.02450554072856903,
            -0.025658724829554558,
            -0.03397449851036072,
            0.02872854843735695,
            -0.010140390135347843,
            -0.014150720089673996,
            -0.02922435663640499,
            -0.018405864015221596,
            -0.04295876994729042,
            0.0041868616826832294,
            0.0054540173150599,
            -0.04317538067698479,
            -0.019998984411358833,
            0.010268266312777996,
            0.02537558414041996,
            0.023482581600546837,
            -0.021520597860217094,
            0.014409830793738365,
            0.01046082004904747,
            0.013710333965718746,
            -0.02025710605084896,
            0.02886076457798481,
            0.004730053711682558,
            -0.008152347058057785,
            -0.03133862093091011,
            -0.01050203200429678,
            -0.023254116997122765,
            -0.028296377509832382,
            0.007031743880361319,
            0.035176411271095276,
            0.007310103625059128,
            0.00513873714953661,
            -0.016452837735414505,
            -0.004577550105750561,
            0.016901666298508644,
            -0.021243957802653313,
            -0.002566414652392268,
            0.03336622193455696,
            -0.01311207190155983,
            0.022399993613362312,
            0.04905249923467636,
            0.02094380371272564,
            0.022945553064346313,
            0.006635748315602541,
            -0.008096401579678059,
            0.012052521109580994,
            0.0024414747022092342,
            -0.008589938282966614,
            0.009122096933424473,
            0.03283752128481865,
            -0.01945197395980358,
            0.012488282285630703,
            0.007924734614789486,
            0.01332639530301094,
            0.030468514189124107,
            0.006887982599437237,
            0.03195948898792267,
            -0.015021894127130508,
            -0.018598467111587524,
            0.026668747887015343,
            -0.004018999636173248,
            -0.014298627153038979,
            -0.009089075960218906,
            -0.01921193115413189,
            0.010336454957723618,
            -0.02158914878964424,
            -0.002788871992379427,
            0.02167973667383194,
            0.039526715874671936,
            0.002108369953930378,
            0.011882047168910503,
            0.005951236467808485,
            -0.027285872027277946,
            -0.022307654842734337,
            0.05990196391940117,
            6.951368413865566e-05,
            -0.01526406966149807,
            -0.01969025284051895,
            -0.02750520035624504,
            -0.04678225889801979,
            -0.029626496136188507,
            -0.030980486422777176,
            -0.033524446189403534,
            -0.009520834311842918,
            0.03335944190621376,
            -0.014584549702703953,
            -0.032685063779354095,
            0.008054947480559349,
            0.02583146281540394,
            -0.018359757959842682,
            -0.042977362871170044,
            0.03542812168598175,
            5.328002316673519e-06,
            -0.02564564161002636,
            0.0059324451722204685,
            -0.008831056766211987,
            0.019456924870610237,
            -0.03175933286547661,
            0.04557737708091736,
            -0.042445890605449677,
            0.03240843489766121,
            -0.010529711842536926,
            0.00695966649800539,
            -0.0023207440972328186,
            0.012936044484376907,
            -0.05137266591191292,
            -0.010958848521113396,
            -0.014294593594968319,
            0.007020480465143919,
            -0.012378799729049206,
            0.03686162829399109,
            -0.006191645748913288,
            0.027560213580727577,
            0.02594643086194992,
            -0.01113093364983797,
            0.03828718140721321,
            -0.019987475126981735,
            -0.030325112864375114,
            0.010382923297584057,
            0.04889632761478424,
            -0.021372368559241295,
            0.024249618873000145,
            -0.009341760538518429,
            0.020306963473558426,
            0.004291687160730362,
            -0.04596167430281639,
            -0.019579578191041946,
            -0.07004941254854202,
            0.03457972779870033,
            -0.01904102973639965,
            -0.027920600026845932,
            -0.027293244376778603,
            -0.010825756005942822,
            -0.03503767028450966,
            0.02749326080083847,
            0.021697387099266052,
            -0.02595346048474312,
            0.007206968497484922,
            -0.02515714429318905,
            -0.03425170108675957,
            -0.012517844326794147,
            0.03408113121986389,
            -0.007866023108363152,
            -0.013030248694121838,
            -0.010964115150272846,
            0.023286929354071617,
            0.017802247777581215,
            0.012582516297698021,
            0.01371641643345356,
            -0.03548269718885422,
            0.007240667007863522,
            -0.005300925578922033,
            0.023997388780117035,
            -0.030841538682579994,
            0.052650392055511475,
            0.0072333007119596004,
            0.03107699193060398,
            -0.0173657163977623,
            -0.016220392659306526,
            -0.0054916441440582275,
            -0.02100384421646595,
            -0.002555334707722068,
            -0.030956842005252838,
            0.027541538700461388,
            0.03140149638056755,
            0.004754061810672283,
            0.05969064682722092,
            -0.012132551521062851,
            0.03186649829149246,
            -0.03856683149933815,
            0.016937430948019028,
            -0.0024673312436789274,
            -0.018066471442580223,
            -0.0039003414567559958,
            -0.045975830405950546,
            -0.0057661086320877075,
            -0.046122174710035324,
            0.0012190043926239014,
            0.007784612011164427,
            0.018345188349485397,
            -0.02162439376115799,
            0.004373759496957064,
            -0.07911359518766403,
            0.014684542082250118,
            -0.04748227819800377,
            -0.027587009593844414,
            -0.015575154684484005,
            -0.020579535514116287,
            0.009701977483928204,
            -0.03454287350177765,
            0.03166765347123146,
            0.00857388786971569,
            0.0038169855251908302,
            0.006584715563803911,
            0.006749925669282675,
            -0.05098823085427284,
            -0.025930695235729218,
            -0.013365295715630054,
            -0.011759408749639988,
            -0.04774497076869011,
            0.02421615459024906,
            0.0004536720225587487,
            0.019673975184559822,
            0.04773179069161415,
            -0.003161125583574176,
            -0.015776226297020912,
            0.005028397310525179,
            0.03517322242259979,
            0.031731512397527695,
            0.011879675090312958,
            -0.04251478984951973,
            0.014453532174229622,
            -0.02185451239347458,
            0.03051230125129223,
            -0.0008888508891686797,
            -0.010654925368726254,
            -0.016638031229376793,
            0.028912918642163277,
            0.03570166230201721,
            -0.03134424239397049,
            -0.007806569803506136,
            -0.0005279991310089827,
            0.03228509798645973,
            0.006567775271832943,
            -0.03901350498199463,
            -0.05157545208930969,
            0.04775511845946312,
            -0.008249957114458084,
            0.029479380697011948,
            0.04484765976667404,
            0.009268773719668388,
            0.013113466091454029,
            0.015532570891082287,
            -0.006317217368632555,
            -0.007977892644703388,
            -0.012427112087607384,
            0.02066883072257042,
            -0.04753163084387779,
            -0.02448653057217598,
            -0.04592997208237648,
            0.003741406835615635,
            0.027097322046756744,
            0.011756881140172482,
            -0.009298156946897507,
            0.01641598343849182,
            -0.003582239616662264,
            -0.015380854718387127,
            -0.003980335313826799,
            -0.028126416727900505,
            -0.022512873634696007,
            -0.028737856075167656,
            -0.054869454354047775,
            -0.015007836744189262,
            0.02980589121580124,
            0.01802033931016922,
            0.010431657545268536,
            0.023320766165852547,
            -0.021392444148659706,
            -0.007325934711843729,
            0.032118864357471466,
            -0.04194844514131546,
            0.0031906908843666315,
            0.04623394086956978,
            -0.001661065500229597,
            -0.012475712224841118,
            -0.03596295788884163,
            -0.010226086713373661,
            0.020580299198627472,
            -0.019125616177916527,
            -0.047860924154520035,
            -0.07015276700258255,
            -0.03222432732582092,
            0.020563358440995216,
            6.340840627672151e-05,
            0.021993817761540413,
            -0.03917451947927475,
            -0.036737434566020966,
            0.053840041160583496,
            -0.0031380881555378437,
            0.04764917120337486,
            -0.019059039652347565,
            0.00541741494089365,
            0.007893734611570835,
            0.0015924745239317417,
            -0.03972679004073143,
            0.01844501681625843,
            0.012239417992532253,
            0.017031650990247726,
            -0.012771730311214924,
            0.016155870631337166,
            -0.012599739246070385,
            0.021260907873511314,
            0.017453238368034363,
            -0.022767622023820877,
            0.02839537151157856,
            0.01927425153553486,
            -0.014811348170042038,
            0.016874616965651512,
            0.011600852012634277,
            0.02946809120476246,
            -0.005114351399242878,
            -0.03412951901555061,
            -0.007681950926780701,
            -0.020457185804843903,
            0.03356253728270531,
            -0.04156307131052017,
            -0.0049555920995771885,
            0.023944485932588577,
            0.02891521342098713,
            -0.02982773631811142,
            0.00665369862690568,
            -0.018870675936341286,
            -0.019855817779898643,
            0.029967788606882095,
            0.03458089381456375,
            -0.02904299646615982,
            -0.016421359032392502,
            -0.025012336671352386,
            -0.01339372806251049,
            0.008131398819386959,
            0.008467460982501507,
            -0.014212097972631454,
            0.002188611077144742,
            -0.009117158129811287,
            0.014538091607391834,
            -0.010828575119376183,
            0.027780920267105103,
            -0.034718211740255356,
            -0.002619377104565501,
            0.03589511662721634,
            -0.006716352887451649,
            -0.019996318966150284,
            0.04382041096687317,
            -0.011836376041173935,
            -0.009278474375605583,
            -0.026274515315890312,
            0.030194906517863274,
            0.020550468936562538,
            -0.0140359653159976,
            -0.012514794245362282,
            -0.015562525019049644,
            0.009345213882625103,
            -0.0077013373374938965,
            -0.0037236835341900587,
            0.019276652485132217,
            0.01615157723426819,
            -0.04137974604964256,
            0.04176446050405502,
            0.009474269114434719,
            -0.05937628075480461,
            0.00680632796138525,
            0.004790261387825012,
            0.025332946330308914,
            0.028157737106084824,
            0.008129590190947056,
            -0.014240950345993042,
            0.037101831287145615,
            0.011243406683206558,
            0.0045956880785524845,
            0.016588587313890457,
            0.008525706827640533,
            0.02656792849302292,
            0.02849578857421875,
            -0.025382481515407562,
            -0.027441585436463356,
            0.009150235913693905,
            0.006025421898812056,
            -0.010920205153524876,
            -0.01923656463623047,
            -0.004979412071406841,
            0.006439190357923508,
            0.000777052016928792,
            -0.01773998886346817,
            0.04456416517496109,
            -0.0001746178459143266,
            0.039163921028375626,
            0.0027937404811382294,
            -0.008257050067186356,
            -0.00793370883911848,
            -0.051836151629686356,
            0.016986677423119545,
            0.012315116822719574,
            -0.008875114843249321,
            0.013896123506128788,
            -0.017650946974754333,
            -0.01368781365454197,
            -0.0024768393486738205,
            0.006154804024845362,
            -0.046345990151166916,
            0.04114361107349396,
            0.008050062693655491,
            0.005106097552925348,
            -0.01754344254732132,
            0.002453922526910901,
            -0.031033536419272423,
            0.030182886868715286,
            0.002727626357227564,
            -0.03116205520927906,
            -0.0034757754765450954,
            -0.008160632103681564,
            0.01202123798429966,
            -0.041683610528707504,
            -0.012993416748940945,
            0.03739212080836296,
            0.026210419833660126,
            -0.03128409758210182,
            -0.029720881953835487,
            0.012770385481417179,
            0.00764291500672698,
            -0.04419916868209839,
            -0.02321271039545536,
            -0.005671723745763302,
            -0.026148900389671326,
            0.041655268520116806,
            0.019423408433794975,
            -0.0226282998919487,
            -0.023260992020368576,
            0.022461358457803726,
            0.024694543331861496,
            0.012287934310734272,
            0.022662002593278885,
            -0.027081863954663277,
            0.033075589686632156,
            -0.02575688436627388,
            0.01740819774568081,
            0.016642481088638306,
            -0.004113893955945969,
            -0.007816707715392113,
            0.02112259343266487,
            0.013577047735452652,
            0.0031331228092312813,
            0.0011993016814813018,
            0.009284613654017448,
            0.010828076861798763,
            0.022907495498657227,
            0.03163905814290047,
            -0.0037296873051673174,
            0.041174113750457764,
            0.04498136043548584,
            0.04352431744337082,
            0.021847542375326157,
            0.039253585040569305,
            0.033163756132125854,
            0.024356944486498833,
            0.03948257863521576,
            0.00583238247781992,
            -0.03848722577095032,
            0.06097735837101936,
            0.021023724228143692,
            0.030884506180882454,
            0.03424164652824402,
            0.007929321378469467,
            -0.024573715403676033,
            0.001925157499499619,
            0.027698485180735588,
            0.003171102609485388,
            -0.024050334468483925,
            -0.01995064690709114,
            0.0012768300948664546,
            -0.0027557644061744213,
            0.026551013812422752,
            0.014737197197973728,
            -0.02037172205746174,
            -0.03394847735762596,
            -5.2884905016981065e-05,
            0.02489355206489563,
            0.008955688215792179,
            0.021839676424860954,
            0.010684305801987648,
            -0.017645228654146194,
            0.04599069803953171,
            0.01908881962299347,
            -0.01931815780699253,
            -0.011265799403190613,
            -0.01993737928569317,
            0.02180897258222103,
            -0.020431440323591232,
            0.00444528553634882,
            -0.012376563623547554,
            0.042399998754262924,
            -0.0193651020526886,
            0.017730453982949257,
            0.010634859092533588,
            -0.00034790291101671755,
            -0.0023786150850355625,
            -0.00012054177932441235,
            0.024079937487840652,
            0.021022651344537735,
            -0.023890504613518715,
            -0.0005706932279281318,
            -0.011256828904151917,
            0.027782078832387924,
            0.017697153612971306,
            -0.02700917050242424,
            0.002453515073284507,
            -0.025263873860239983,
            0.024606522172689438,
            0.00878007709980011,
            0.01792886294424534,
            -0.020999854430556297,
            0.004308362025767565,
            -0.00523630203679204,
            -0.02064790017902851,
            0.004588004667311907,
            -0.043817583471536636,
            0.055672187358140945,
            0.005812032148241997,
            0.027048032730817795,
            0.01960032247006893,
            -0.03238892927765846,
            0.000491115206386894,
            0.02530677616596222,
            -0.016007084399461746,
            -0.0024955072440207005,
            0.019509699195623398,
            0.0012755445204675198,
            0.0019762604497373104,
            0.021562425419688225,
            0.029377607628703117,
            0.016961848363280296,
            -0.024885406717658043,
            0.03711824491620064,
            -0.005062976852059364,
            0.01213573943823576,
            -0.0033423968125134706,
            -0.0013599063968285918,
            -0.03764811530709267,
            -0.006269550416618586,
            0.023984737694263458,
            -0.0013516140170395374,
            -0.026735512539744377,
            0.05413253977894783,
            -0.003545181592926383,
            0.016348684206604958,
            0.020421963185071945,
            0.030413329601287842,
            -0.03230597451329231,
            -0.0268003661185503,
            0.002556847408413887,
            0.03704896196722984,
            -0.02213090844452381,
            -0.018222354352474213,
            -0.0011971587082371116,
            -0.008411615155637264,
            -0.02501021884381771,
            0.026263533160090446,
            -0.021068789064884186,
            0.015603899955749512,
            0.013457490131258965,
            -0.021925821900367737,
            0.04120078682899475,
            0.011468716897070408,
            -0.009980089031159878,
            -0.014794315211474895,
            0.033843670040369034,
            0.0021276557818055153,
            0.0004090370493941009,
            -0.033007483929395676,
            0.02419518493115902,
            0.03109579347074032,
            0.01686820387840271,
            -0.013192372396588326,
            0.0019608139991760254,
            -0.0001681578141869977,
            0.0022753477096557617,
            0.006247074343264103,
            0.031460534781217575,
            0.023677688091993332,
            -8.565699681639671e-05,
            -0.04311935603618622,
            0.010378903709352016,
            -0.00273849256336689,
            0.005688113626092672,
            -0.005103420931845903,
            0.011244745925068855,
            0.028477806597948074,
            0.019372770562767982,
            0.016781942918896675,
            -0.022839359939098358,
            -0.0198337621986866,
            -0.022914335131645203,
            0.011657689698040485,
            0.017820486798882484,
            -0.00991001445800066,
            0.01274337712675333,
            0.022298457100987434,
            0.013327741995453835,
            0.024624334648251534,
            -0.03525853902101517,
            0.025836801156401634,
            0.06605630367994308,
            0.00981786847114563,
            0.03867487609386444,
            -0.03283587843179703,
            0.025597790256142616,
            0.001980248373001814,
            0.012703139334917068,
            0.01606973633170128,
            -0.011639023199677467,
            -0.0027087226044386625,
            -0.04665972664952278,
            0.02369578368961811,
            -0.030916722491383553,
            0.02131063863635063,
            -0.010403463616967201,
            0.030155407264828682,
            -0.06653843075037003,
            -0.01230293232947588,
            0.023083213716745377,
            0.016277099028229713,
            -0.022690976038575172,
            0.014752056449651718,
            0.033890500664711,
            -0.000675040006171912,
            -0.007865873165428638,
            -0.0071679954417049885,
            -0.022257650271058083,
            -0.034395039081573486,
            0.016769887879490852,
            -0.013535935431718826,
            0.0020193886011838913,
            0.04301254078745842,
            -0.03688203915953636,
            0.03640717267990112,
            0.02301550656557083,
            0.006557134445756674,
            0.011949914507567883,
            -0.023293279111385345,
            0.036082152277231216,
            -0.012059093452990055,
            -0.02139364555478096,
            -0.019287968054413795,
            -0.002958279103040695,
            0.009029297158122063,
            -0.0033905161544680595,
            -0.0025523395743221045,
            -0.009601397439837456,
            -0.01541454903781414,
            0.03116091713309288,
            -0.03703923523426056,
            0.02451157011091709,
            0.012903072871267796,
            -0.02751687541604042,
            -0.005814741365611553,
            -0.025334535166621208,
            -0.01997349038720131,
            -0.014177274890244007,
            -0.03937305882573128,
            0.011547123081982136,
            -0.026681354269385338,
            -0.03470900282263756,
            -0.009790928103029728,
            0.006583037320524454,
            -0.014526267535984516,
            -0.005356370005756617,
            -0.012725603766739368,
            -0.0029092240147292614,
            -0.04056583344936371,
            0.014514409005641937,
            0.035375334322452545,
            -0.024452339857816696,
            -0.013177810236811638,
            0.012907413765788078,
            -0.024246031418442726,
            0.00035109632881358266,
            0.0024810817558318377,
            0.004982697311788797,
            0.010522800497710705,
            0.023645944893360138,
            0.015544715337455273,
            0.008521824143826962,
            -0.002115040086209774,
            -0.020406028255820274,
            0.04179680347442627,
            0.04489888623356819,
            0.02291366644203663,
            -0.0014422591775655746,
            0.01779632270336151,
            0.0036026155576109886,
            -0.007771783974021673,
            -0.013072018511593342,
            -0.00454562297090888,
            0.006355843506753445,
            -0.025583909824490547,
            -0.0026726447977125645,
            -0.0008431991445831954,
            0.023938406258821487,
            -0.009130396880209446,
            -0.026063144207000732,
            -0.0035231246147304773,
            0.0027484253514558077,
            0.0023790751583874226,
            -0.008082742802798748,
            -0.0271541029214859,
            0.035234786570072174,
            0.02470734715461731,
            0.031642813235521317,
            0.029274431988596916,
            -0.04142875596880913,
            -0.005084617994725704,
            -0.000768451951444149,
            -0.015794262290000916,
            0.03102502040565014,
            -0.0010887936223298311,
            -0.04016663879156113,
            -0.009728668257594109,
            -0.024455197155475616,
            -0.024588894098997116,
            0.006012556608766317,
            0.004788386169821024,
            2.185284574807156e-05,
            -0.0007567553548142314,
            0.036241382360458374,
            0.030896052718162537,
            -0.0041234358213841915,
            0.016050802543759346,
            -0.039981186389923096,
            0.016680562868714333,
            -0.0027942995075136423,
            -0.014578917995095253,
            -0.022124063223600388,
            -0.0165187269449234,
            0.01244328822940588,
            0.026333948597311974,
            -0.02096542902290821,
            -0.022131340578198433,
            -0.04365293309092522,
            -0.0008318449836224318,
            -0.023251911625266075,
            -0.026550695300102234,
            -0.009841397404670715,
            -0.06533674150705338,
            -0.017398972064256668,
            0.0511859655380249,
            0.04527033492922783,
            0.018556218594312668,
            0.02378659136593342,
            -0.03024187684059143,
            -0.020572463050484657,
            -0.0035599665716290474,
            0.026233995333313942,
            0.004482027608901262,
            0.01702088490128517,
            0.022018175572156906,
            -0.025633670389652252,
            -0.0038539874367415905,
            0.00446853693574667,
            -0.03353912755846977,
            0.01388601865619421,
            -0.005412810482084751,
            0.0032051990274339914,
            0.027512433007359505,
            0.0026648312341421843,
            0.007260885555297136,
            0.0016867517260834575,
            0.005462909583002329,
            0.017401648685336113,
            -0.011548040434718132,
            -0.023535119369626045,
            -0.023605577647686005,
            0.009744636714458466,
            -0.012223818339407444,
            0.0023088736925274134,
            -0.001911877770908177,
            0.03152838349342346,
            0.03989090025424957,
            -0.023143263533711433,
            0.009391668252646923,
            -0.00011827976413769647,
            0.01157942321151495,
            0.002392819384112954,
            -0.010160581208765507,
            0.013020459562540054,
            -0.00787945557385683,
            0.025043416768312454,
            0.009023399092257023,
            0.004053870216012001,
            -0.00999782606959343,
            0.004660818260163069,
            0.016240691766142845,
            0.052534155547618866,
            0.027800239622592926,
            0.004312973003834486,
            -0.03017573058605194,
            -0.007413769606500864,
            0.03622641786932945,
            0.02389543689787388,
            -0.01814982295036316,
            -0.002774797612801194,
            0.01091898512095213,
            0.010956176556646824,
            0.01949123665690422,
            -0.022085605189204216,
            -0.011333546601235867,
            -0.001432935823686421,
            0.004117760807275772,
            -0.00542497867718339,
            -0.020324932411313057,
            -0.008864366449415684,
            0.006853396538645029,
            -0.018781259655952454,
            0.013522827066481113,
            0.03517830744385719,
            0.03402691334486008,
            -0.027004782110452652,
            -0.031018070876598358,
            -0.026803474873304367,
            -0.032481446862220764,
            -0.0156708937138319,
            -0.013566701672971249,
            -0.00965134333819151,
            0.0428723506629467,
            -0.005799905396997929,
            0.03558971732854843,
            -0.005082396324723959,
            -0.009112096391618252,
            0.0022378193680197,
            0.024440042674541473,
            -0.030027315020561218,
            0.023006519302725792,
            0.010588797740638256,
            -0.03258378431200981,
            0.019761275500059128,
            -0.007248779758810997,
            -0.042016901075839996,
            0.036748599261045456,
            0.009805519133806229,
            -0.027427013963460922
        ],
        [
            0.03508463501930237,
            0.00943850725889206,
            0.0009502851753495634,
            -0.00812553521245718,
            0.01622246392071247,
            -0.0204910971224308,
            0.01963048428297043,
            -0.004436158575117588,
            -0.017864707857370377,
            0.020484978333115578,
            -0.019848627969622612,
            0.024035977199673653,
            0.020825985819101334,
            -0.03665822371840477,
            0.00012026698823319748,
            -0.014535904861986637,
            -0.023093126714229584,
            -0.022537173703312874,
            0.006813570391386747,
            0.008419020101428032,
            -0.007139256689697504,
            0.0025827093049883842,
            -0.0005353939486667514,
            -0.016162514686584473,
            0.04739576205611229,
            -0.02065364271402359,
            0.02263500541448593,
            0.0036068649496883154,
            -0.003385790390893817,
            0.004395568277686834,
            0.023642057552933693,
            -0.017864534631371498,
            -7.537294732173905e-05,
            -0.019554482772946358,
            0.01052333414554596,
            0.012892200611531734,
            -0.006629114970564842,
            0.012173422612249851,
            -0.010549896396696568,
            -0.02162812650203705,
            0.013580704107880592,
            -0.013598064891994,
            0.019304264336824417,
            0.010767306201159954,
            0.0013370263623073697,
            0.009474537335336208,
            -0.03395404666662216,
            0.04346718266606331,
            0.015188857913017273,
            0.0038872442673891783,
            0.01399332843720913,
            -0.014188184402883053,
            -0.00530979735776782,
            0.02863049879670143,
            -0.025892775505781174,
            -0.024314679205417633,
            -1.313842676609056e-05,
            0.03426269069314003,
            -0.03783969208598137,
            0.01924670673906803,
            -0.00481665413826704,
            0.004256047308444977,
            0.007993103004992008,
            0.021800512447953224,
            0.0022660763934254646,
            -0.02341429889202118,
            0.009199701249599457,
            0.010905466042459011,
            -0.023411095142364502,
            0.020978113636374474,
            0.006946152541786432,
            -0.010524552315473557,
            0.014050851576030254,
            -0.017616940662264824,
            0.052436549216508865,
            -0.03731098398566246,
            0.004876019898802042,
            0.02820458821952343,
            -0.03435159847140312,
            0.001979096792638302,
            -0.001380390371195972,
            -0.02686208114027977,
            -0.022349901497364044,
            -0.0074224816635251045,
            -0.000851616554427892,
            0.030200665816664696,
            0.017385100945830345,
            0.02506847307085991,
            0.03121306374669075,
            -0.05952833965420723,
            0.01657800003886223,
            -0.01311461627483368,
            0.0027381156105548143,
            -0.021269280463457108,
            -0.0010955872712656856,
            -0.008874073624610901,
            0.014823687262833118,
            -0.025085803121328354,
            -0.026198411360383034,
            0.0035588815808296204,
            -0.001232221140526235,
            0.025145625695586205,
            0.031767334789037704,
            -0.021725431084632874,
            0.025272920727729797,
            -0.01309856679290533,
            0.00439746817573905,
            -0.009168348275125027,
            -0.016688277944922447,
            -0.0293281227350235,
            -0.03171853348612785,
            0.009726024232804775,
            -0.003842414589598775,
            -0.0008732456481084228,
            -0.025017453357577324,
            -0.022251460701227188,
            0.032945550978183746,
            -0.003935153596103191,
            -0.0039050676859915257,
            -0.011232147924602032,
            -0.015824003145098686,
            0.010814003646373749,
            0.04775603488087654,
            0.0013603534316644073,
            -0.015612603165209293,
            -0.025453705340623856,
            -0.03448300063610077,
            0.0013594202464446425,
            0.012816903181374073,
            -0.009756538085639477,
            0.029851967468857765,
            -0.009505834430456161,
            -0.00563841313123703,
            0.0048057097010314465,
            0.012267027981579304,
            0.032372910529375076,
            -0.019060829654335976,
            0.015543770045042038,
            0.015835987403988838,
            0.022590680047869682,
            -0.024965787306427956,
            0.0012120965402573347,
            0.03854154050350189,
            0.012100541032850742,
            -0.008066577836871147,
            -0.010575137101113796,
            0.005545246414840221,
            0.025299979373812675,
            0.02165815979242325,
            0.03227235749363899,
            0.014557886868715286,
            0.01905335672199726,
            -0.008975082077085972,
            0.009135423228144646,
            0.034001320600509644,
            -0.034883663058280945,
            -0.035387132316827774,
            0.02253790758550167,
            -0.01432840432971716,
            0.013270514085888863,
            0.0036790892481803894,
            -0.012883736751973629,
            0.014606217853724957,
            -0.015403545461595058,
            -0.015419155359268188,
            0.062217216938734055,
            -0.0047597032971680164,
            -0.0017690124223008752,
            0.022243091836571693,
            0.0021062116138637066,
            0.02510715462267399,
            -0.015466594137251377,
            -0.045138757675886154,
            0.007084074895828962,
            0.0004940197104588151,
            -0.02455355040729046,
            0.009227754548192024,
            0.03893459215760231,
            0.014801054261624813,
            0.022600650787353516,
            -0.023510389029979706,
            0.015566994436085224,
            0.014532743953168392,
            -0.016377568244934082,
            -0.013833610340952873,
            -0.013194061815738678,
            -0.018988316878676414,
            0.02034112438559532,
            -0.025853881612420082,
            -0.0028130244463682175,
            -0.016707584261894226,
            0.003823578590527177,
            -0.036272674798965454,
            0.02446897141635418,
            -0.020821530371904373,
            -0.0325455516576767,
            -0.014317193999886513,
            0.0069932532496750355,
            0.030143579468131065,
            0.02773384377360344,
            0.03133330121636391,
            0.0392889603972435,
            0.0005323052755557001,
            -0.0184994675219059,
            0.040481749922037125,
            -0.007981304079294205,
            0.026100261136889458,
            0.011718163266777992,
            0.021676622331142426,
            -0.022755594924092293,
            -0.029176829382777214,
            0.02506440132856369,
            0.006280956324189901,
            -0.03517749160528183,
            0.010036169551312923,
            -0.023005064576864243,
            -0.0006446713814511895,
            -0.027677567675709724,
            -0.014441938139498234,
            0.01729959063231945,
            0.014185232110321522,
            0.015049420297145844,
            -0.014649447984993458,
            -0.005370007362216711,
            0.001235689502209425,
            -0.02497860975563526,
            0.0011929492466151714,
            0.03522390127182007,
            0.0031574598979204893,
            0.011878329329192638,
            -0.029530322179198265,
            -0.005206583067774773,
            0.0044799004681408405,
            -0.021928980946540833,
            -0.03346958011388779,
            0.004497043788433075,
            0.027369478717446327,
            0.0015444683376699686,
            0.015137161128222942,
            0.003060672665014863,
            0.00525693129748106,
            0.008240212686359882,
            -0.01634068600833416,
            0.009043697267770767,
            -0.01177856232970953,
            -0.0058211637660861015,
            -0.016673794016242027,
            0.020238030701875687,
            -0.008864723145961761,
            0.02591744251549244,
            0.012760206125676632,
            0.008457226678729057,
            0.010824977420270443,
            0.026472270488739014,
            -0.020913347601890564,
            -0.018547531217336655,
            0.02094988152384758,
            0.031169278547167778,
            -0.020923011004924774,
            -0.010319522581994534,
            0.012928334064781666,
            -0.028903791680932045,
            -0.012100030668079853,
            0.02230481430888176,
            0.023800065740942955,
            -0.005803338717669249,
            -0.003521347651258111,
            0.0039398325607180595,
            0.014210911467671394,
            0.013544698245823383,
            0.01316154282540083,
            0.0259639173746109,
            0.01594683527946472,
            -0.0032413911540061235,
            -0.022580504417419434,
            -0.00891279336065054,
            -0.025143275037407875,
            -0.013514709658920765,
            0.0052843401208519936,
            -0.019271930679678917,
            0.020417723804712296,
            0.03405965864658356,
            0.006675004959106445,
            -0.014365192502737045,
            -0.021814346313476562,
            -0.02253042533993721,
            -0.03397156298160553,
            0.01751990057528019,
            -0.01309675071388483,
            0.028001466765999794,
            0.004628781229257584,
            -0.011383217759430408,
            0.007029461674392223,
            0.02297392301261425,
            -0.020597683265805244,
            0.02746722474694252,
            0.023028777912259102,
            -0.009711058810353279,
            0.03591575101017952,
            -0.02390948310494423,
            -0.02107272297143936,
            0.022052433341741562,
            0.0013806691858917475,
            -0.019286366179585457,
            0.029950091615319252,
            -0.006252066232264042,
            0.0032306252978742123,
            -0.0057768444530665874,
            -0.023267360404133797,
            0.03309281915426254,
            -0.04194338992238045,
            0.03256688266992569,
            -0.025086428970098495,
            -0.02174317091703415,
            0.029761984944343567,
            -0.002301555359736085,
            0.014697662554681301,
            -0.018657876178622246,
            -0.02037862502038479,
            -0.0011204564943909645,
            0.022282812744379044,
            0.0033311631996184587,
            0.019784487783908844,
            0.005884372629225254,
            0.0050264266319572926,
            0.0014759772457182407,
            -0.0010339001892134547,
            0.015312234871089458,
            0.008496726863086224,
            0.01312114018946886,
            0.020785953849554062,
            -0.019985638558864594,
            0.016015369445085526,
            -0.0043322439305484295,
            0.009618151001632214,
            -0.001076970947906375,
            -0.02445896528661251,
            -0.011414634995162487,
            0.003915787674486637,
            0.012780029326677322,
            -0.014613346196711063,
            -0.010509506799280643,
            -0.006901863031089306,
            -0.0018144726054742932,
            0.008548622950911522,
            0.002987199230119586,
            0.028987597674131393,
            0.009981944225728512,
            -0.005541262216866016,
            -0.01819448173046112,
            0.013104138895869255,
            0.04204278066754341,
            -0.0014012920437380672,
            0.00928714033216238,
            0.023918593302369118,
            -0.0008049015887081623,
            0.0033926749601960182,
            -0.03104921616613865,
            0.0005031304317526519,
            0.0033567894715815783,
            -0.011762561276555061,
            0.017739219591021538,
            0.02663562446832657,
            0.0356241874396801,
            -0.020291414111852646,
            0.002396577037870884,
            -0.050390660762786865,
            0.018676020205020905,
            0.032646965235471725,
            0.03021172247827053,
            0.020084930583834648,
            0.02222033590078354,
            -0.003611065912991762,
            0.004413734655827284,
            -0.004596253391355276,
            -0.02241016924381256,
            0.0020104527939110994,
            0.031112654134631157,
            -0.0007860094774514437,
            -0.011497780680656433,
            -0.014755817130208015,
            0.02859952300786972,
            -0.009454411454498768,
            -0.02606300637125969,
            0.02001216635107994,
            -0.021608633920550346,
            0.01293871272355318,
            -0.012362574227154255,
            0.01903095282614231,
            -0.004878554493188858,
            -0.021983545273542404,
            0.020134171470999718,
            -0.037727564573287964,
            0.029214216396212578,
            0.013775976374745369,
            0.016129368916153908,
            0.022716494277119637,
            0.005718977656215429,
            0.026166535913944244,
            0.024574823677539825,
            0.039577286690473557,
            0.01908857561647892,
            0.025801854208111763,
            -0.014763557352125645,
            0.0009501624153926969,
            0.008783991448581219,
            -0.009286811575293541,
            0.0008261329494416714,
            0.0062859440222382545,
            -0.010872521437704563,
            -0.022434052079916,
            0.0007770897354930639,
            -0.01895456574857235,
            0.014803137630224228,
            -0.036769673228263855,
            0.009149713441729546,
            -0.00751039944589138,
            -0.046400535851716995,
            -0.02100820653140545,
            0.0025547114200890064,
            0.03265583515167236,
            -0.008644705638289452,
            -0.019221877679228783,
            0.001656741020269692,
            0.0118110291659832,
            0.01185466255992651,
            0.011614615097641945,
            0.00010595966159598902,
            0.03431853652000427,
            -0.005533029790967703,
            -0.04538176208734512,
            -0.02580425888299942,
            0.02760700136423111,
            0.011298689991235733,
            -0.008737421594560146,
            -0.023953992873430252,
            0.031967684626579285,
            -0.03406381979584694,
            0.004397760611027479,
            0.0044930498115718365,
            0.042276881635189056,
            0.014991002157330513,
            -0.012204734608530998,
            0.03553764522075653,
            -0.01325307972729206,
            -0.015063086524605751,
            -0.013943145051598549,
            -0.033060088753700256,
            -0.018795115873217583,
            0.020023267716169357,
            -0.023983467370271683,
            -0.01777738332748413,
            -0.00748595641925931,
            0.014405861496925354,
            -0.011248230002820492,
            -0.02221098728477955,
            -0.02084946446120739,
            0.009953535161912441,
            0.015668591484427452,
            0.0057554179802536964,
            -0.0013935285387560725,
            0.010279039852321148,
            0.004106913227587938,
            0.0015724430559203029,
            0.011514849960803986,
            -0.016414646059274673,
            0.024373328313231468,
            0.004458476789295673,
            0.02497204951941967,
            0.002089546527713537,
            -0.019975345581769943,
            0.03323554992675781,
            -0.031604256480932236,
            0.019434327259659767,
            0.01075600367039442,
            -0.02157546579837799,
            0.0034813310485333204,
            0.011554202996194363,
            -0.02153858169913292,
            0.016835788264870644,
            -0.012877929955720901,
            -0.0029877384658902884,
            0.004381868056952953,
            0.008845043368637562,
            0.02579880692064762,
            -0.008905594237148762,
            -0.009145902469754219,
            -0.018957048654556274,
            -0.007722805719822645,
            0.02243259735405445,
            0.013496670871973038,
            0.024590156972408295,
            0.01057977881282568,
            -0.05750855430960655,
            0.031924933195114136,
            0.007313082925975323,
            0.0029830795247107744,
            0.011873345822095871,
            0.013594408519566059,
            -0.022411691024899483,
            0.014216473326086998,
            -0.009056593291461468,
            -0.030438266694545746,
            -0.042809706181287766,
            0.010903481394052505,
            -0.011235451325774193,
            0.015142197720706463,
            -0.005524477455765009,
            0.012076985090970993,
            -0.008728698827326298,
            -0.012978575192391872,
            -0.02285126782953739,
            -0.026721101254224777,
            -0.01956736110150814,
            0.012381856329739094,
            -0.004612697288393974,
            -0.023453976958990097,
            0.02114599198102951,
            -0.023251662030816078,
            -0.012984087690711021,
            -0.003179344814270735,
            -0.0073417010717093945,
            -0.003100415924564004,
            0.015320255421102047,
            0.0033766371197998524,
            -0.023401161655783653,
            0.001517858006991446,
            0.03205367177724838,
            -0.00806194543838501,
            -0.044865742325782776,
            -0.020218338817358017,
            -0.03590826690196991,
            -0.027383826673030853,
            -0.03141779452562332,
            0.021091211587190628,
            -0.027609487995505333,
            0.010472227819263935,
            0.021282438188791275,
            -0.0034242954570800066,
            0.028113480657339096,
            -0.020510613918304443,
            -0.0017492122715339065,
            -0.022389866411685944,
            -0.011765777133405209,
            -0.018512843176722527,
            0.04492341727018356,
            0.02409473806619644,
            0.011928902938961983,
            -0.017664805054664612,
            -0.00812884233891964,
            0.019008848816156387,
            -0.00010509168350836262,
            0.009684057906270027,
            0.005045654717832804,
            -0.0037816581316292286,
            -0.03589366003870964,
            -0.022812919691205025,
            -0.00022901732882019132,
            -0.018767056986689568,
            0.009446301497519016,
            0.007701240014284849,
            -0.009180134162306786,
            0.01352778635919094,
            -0.0032437690533697605,
            -0.004341134801506996,
            -0.03059391863644123,
            0.01338177639991045,
            -0.006786078680306673,
            -0.006337133701890707,
            0.03837726265192032,
            -0.01131450291723013,
            -0.025598734617233276,
            -0.004539210814982653,
            -0.02971133030951023,
            0.02466536872088909,
            0.0024613323621451855,
            0.017144853249192238,
            0.009364757686853409,
            -0.009485900402069092,
            -0.020213235169649124,
            0.006981579586863518,
            0.0026263403706252575,
            0.004589390475302935,
            0.004970598500221968,
            -0.022390468046069145,
            -0.005593393463641405,
            0.005247814115136862,
            0.030684232711791992,
            0.023759936913847923,
            0.004191461019217968,
            0.006285469979047775,
            -0.020545275881886482,
            -0.014267041347920895,
            0.02272856794297695,
            0.01961187645792961,
            -0.017160804942250252,
            -0.01355649158358574,
            -0.015459656715393066,
            0.026188939809799194,
            -0.012346905656158924,
            0.01783597655594349,
            0.027672382071614265,
            -0.017214903607964516,
            0.002005932154133916,
            0.016423942521214485,
            0.005670685786753893,
            0.006372973322868347,
            0.034833334386348724,
            -0.027178915217518806,
            0.020647410303354263,
            0.006133114453405142,
            0.022355621680617332,
            0.03470047563314438,
            -0.01321046706289053,
            0.03513369709253311,
            -0.02403654344379902,
            -0.020888064056634903,
            0.008211483247578144,
            -0.013682086952030659,
            0.03374365717172623,
            0.008589440025389194,
            0.009969775564968586,
            0.011622563004493713,
            -0.006300504319369793,
            0.03190832585096359,
            -0.001534898765385151,
            0.02176792360842228,
            -0.006729408632963896,
            0.01758786290884018,
            0.005634578876197338,
            -0.001022611279040575,
            0.0037334966473281384,
            0.0179727915674448,
            0.007893550209701061,
            0.016058553010225296,
            -0.03595828637480736,
            0.016007810831069946,
            -0.030655061826109886,
            -0.004565480165183544,
            0.03174637258052826,
            -0.019210822880268097,
            0.019492482766509056,
            0.008350360207259655,
            -0.019974686205387115,
            0.012984011322259903,
            0.02290148101747036,
            0.0010477574542164803,
            -0.017768502235412598,
            0.026068609207868576,
            0.004509256221354008,
            -0.009544286876916885,
            -0.013957562856376171,
            -0.028623713180422783,
            -0.03434234857559204,
            -0.02410547435283661,
            -0.006111664231866598,
            0.010510814376175404,
            -0.032139457762241364,
            0.02605624496936798,
            -0.025235122069716454,
            0.0032716072164475918,
            0.02244158834218979,
            -0.028762318193912506,
            0.03518039733171463,
            0.018686313182115555,
            -0.016045797616243362,
            -0.02328828163444996,
            -0.02555653639137745,
            0.021419452503323555,
            0.021223632618784904,
            -0.007872105576097965,
            -0.002115974435582757,
            0.030675174668431282,
            -0.004103098064661026,
            -0.002222351497039199,
            0.029084522277116776,
            -0.023535655811429024,
            -0.013654741458594799,
            -0.0044509670697152615,
            0.03177304193377495,
            0.015957118943333626,
            0.02507730759680271,
            -0.005457737483084202,
            0.011393383145332336,
            0.020406002178788185,
            -0.02027844451367855,
            -0.03150336071848869,
            0.017130626365542412,
            0.035028278827667236,
            0.029689081013202667,
            0.021700875833630562,
            0.013110027648508549,
            -0.0030760429799556732,
            0.013453835621476173,
            -0.022551702335476875,
            -0.01273620780557394,
            0.014301598072052002,
            0.011556773446500301,
            -0.02503715083003044,
            0.032482996582984924,
            -0.029685039073228836,
            0.019627785310149193,
            0.012391520664095879,
            0.009876342490315437,
            -0.021727120503783226,
            -0.01902323216199875,
            0.029850931838154793,
            0.003440412925556302,
            -0.002301691100001335,
            0.025741977617144585,
            0.012890206649899483,
            0.024518053978681564,
            0.009209766983985901,
            -0.001535800751298666,
            0.011172028258442879,
            -0.004010574426501989,
            0.035873349756002426,
            -0.0133585873991251,
            0.009212441742420197,
            0.003956836648285389,
            0.019396958872675896,
            0.00554735166952014,
            -0.03384430333971977,
            -0.04255214333534241,
            -0.0006785995210520923,
            0.002199247246608138,
            0.010508435778319836,
            0.027517398819327354,
            0.01690753921866417,
            0.003426182549446821,
            -0.016690220683813095,
            0.0051923771388828754,
            0.016673777252435684,
            -0.02980278991162777,
            -0.029775867238640785,
            0.013533025048673153,
            0.0013506381073966622,
            -0.011638081632554531,
            -0.02678588032722473,
            -0.028198115527629852,
            0.007279380690306425,
            0.00848005898296833,
            -0.01302447821944952,
            0.009045492857694626,
            0.011971214786171913,
            -0.012473464012145996,
            0.021315883845090866,
            0.01709839701652527,
            0.01743210107088089,
            0.009306405670940876,
            -0.007910823449492455,
            0.017972806468605995,
            -0.0329640693962574,
            0.024303842335939407,
            0.023850422352552414,
            -0.0015401458367705345,
            -0.013271821662783623,
            -0.06931641697883606,
            -0.005829000845551491,
            0.017241789028048515,
            0.007272869348526001,
            0.03387583792209625,
            0.01794428564608097,
            0.04268540441989899,
            -0.023270128294825554,
            -0.0337076410651207,
            -0.03224842995405197,
            0.01404197420924902,
            -0.020233334973454475,
            -0.016574261710047722,
            -0.004925170447677374,
            0.005794909782707691,
            0.012068250216543674,
            0.03743487969040871,
            -0.017107805237174034,
            0.031746700406074524,
            0.016858214512467384,
            -0.003910000901669264,
            -0.0006725384737364948,
            -0.001391950761899352,
            -0.004328588489443064,
            -0.00975186936557293,
            -0.03073308803141117,
            -0.009885547682642937,
            0.010493103414773941,
            -0.02828793227672577,
            -0.004336812999099493,
            0.0315975546836853,
            0.047788310796022415,
            0.021737150847911835,
            0.011208577081561089,
            0.015776529908180237,
            0.04111200571060181,
            0.018099041655659676,
            -0.020065899938344955,
            0.021869255229830742,
            0.005209846422076225,
            0.008995425887405872,
            -0.021498026326298714,
            -0.020326189696788788,
            0.0379628911614418,
            0.016839461401104927,
            0.011829282157123089,
            0.03498927131295204,
            0.0020351449493318796,
            0.022546039894223213,
            -0.007776100188493729,
            0.03481509909033775,
            0.003417767584323883,
            0.01936131902039051,
            0.002345490735024214,
            0.05084408447146416,
            0.005977414082735777,
            0.01979268714785576,
            -0.0221489816904068,
            0.0002584361645858735,
            -0.017779972404241562,
            -0.0022236465010792017,
            -0.034304842352867126,
            -0.023450180888175964,
            0.0015130604151636362,
            -0.03667491674423218,
            0.020656997337937355,
            -0.0016525335377082229,
            -0.03327566385269165,
            0.018771622329950333,
            0.003110776422545314,
            -0.025828341022133827,
            0.02688637748360634,
            0.02677440084517002,
            0.0004173283523414284,
            0.028610270470380783,
            -0.039269477128982544,
            -0.002648408990353346,
            0.025691760703921318,
            -0.014789490960538387,
            0.010542046278715134,
            0.016087932512164116,
            -0.0048491763882339,
            -0.02207358367741108,
            0.00660858815535903,
            0.004648775793612003,
            0.0034130471758544445,
            0.02923695743083954,
            0.023784447461366653,
            0.0240836963057518,
            0.03164767846465111,
            -0.01047611702233553,
            -0.02508321776986122,
            0.008851198479533195,
            -0.0016088096890598536,
            -0.018419621512293816,
            0.008049679920077324,
            0.020090598613023758,
            0.024523915722966194,
            -0.001091894693672657,
            -0.02316107042133808,
            -0.0046726493164896965,
            0.022420493885874748,
            0.02279295213520527,
            0.00839515682309866,
            0.030281120911240578,
            0.006104521453380585,
            0.0072301654145121574,
            0.014504915103316307,
            0.03177724406123161,
            0.0021924679167568684,
            -0.00903257355093956,
            0.031214304268360138,
            0.03567235544323921,
            0.021148202940821648,
            0.02209678664803505,
            -0.007227703928947449,
            -0.023100145161151886,
            -0.0038247236516326666,
            -0.01011617761105299,
            0.020292013883590698,
            0.03064083866775036,
            -0.02952641434967518,
            0.017624439671635628,
            0.008195349015295506,
            -0.012732431292533875,
            -0.020874377340078354,
            0.018138566985726357,
            0.018008802086114883,
            0.03265853971242905,
            0.027313141152262688,
            0.024154655635356903,
            0.022268138825893402,
            0.030592024326324463,
            0.013869883492588997,
            0.030090613290667534,
            0.0006057322025299072,
            -0.03011871688067913,
            -0.012685499154031277,
            0.004179168492555618,
            0.022461898624897003,
            0.02930341847240925,
            0.03372018411755562,
            0.006943067070096731,
            -0.012423638254404068,
            0.005142873153090477,
            -0.004434536211192608,
            0.002010149648413062,
            -0.0044111269526183605,
            0.049877703189849854,
            -0.0006777378148399293,
            0.0345204696059227,
            -0.004122394602745771,
            -0.015927432104945183,
            0.01271139271557331,
            -0.012574391439557076,
            0.0021033971570432186,
            0.025165565311908722,
            0.013293731026351452,
            -0.014745709486305714,
            0.010687869042158127,
            -0.0016598034417256713,
            -0.013943011872470379,
            0.0008399965008720756,
            -0.017734702676534653,
            -0.02542797476053238,
            -0.075672946870327,
            0.019175076857209206,
            0.055680517107248306,
            -0.021822132170200348,
            -0.012568360194563866,
            -0.016455719247460365,
            -0.01063794270157814,
            0.0021061270963400602,
            0.0066000595688819885,
            -0.002566169248893857,
            0.0076806568540632725,
            -0.03790205344557762,
            -0.03671962767839432,
            -8.595050894655287e-05,
            0.0035490975715219975,
            -0.029391761869192123,
            -0.014030166901648045,
            0.03424113616347313,
            0.024445191025733948,
            -0.013204497285187244,
            0.04299624264240265,
            -0.007092026062309742,
            -0.014924612827599049,
            -0.013418695889413357,
            0.008553169667720795,
            -0.011028146371245384,
            0.03056321293115616,
            0.025382138788700104,
            0.03196519985795021,
            -0.012957114726305008,
            -0.03240879625082016,
            0.005680194590240717,
            0.005540173500776291,
            -0.017866652458906174,
            -0.020691297948360443,
            0.01988976076245308,
            -0.02648860774934292,
            -0.009558591060340405,
            0.024791140109300613,
            0.02153928205370903,
            0.00501681724563241,
            -0.016697736456990242,
            -0.015767034143209457,
            -0.018619423732161522,
            -0.05780946835875511,
            -0.02317207120358944,
            -0.016119161620736122,
            -0.005231780000030994,
            -0.010866226628422737,
            0.025767287239432335,
            -0.03470947965979576,
            0.002827164949849248,
            0.03895429149270058,
            0.027617214247584343,
            -0.018811581656336784,
            0.010167150758206844,
            -0.015409726649522781,
            -0.016638845205307007,
            -0.02336769923567772,
            0.020620547235012054,
            0.015450779348611832,
            -0.019354820251464844,
            0.009014910086989403,
            0.011640296317636967,
            0.0004967274726368487,
            -0.0006525684730149806,
            -0.001223956816829741,
            -0.013980492949485779,
            -0.014137433841824532,
            -0.007965130731463432,
            0.00452327961102128,
            -0.016134625300765038,
            -0.014394852332770824,
            0.04961738362908363,
            -0.009874312207102776,
            0.011694494634866714,
            0.047280509024858475,
            -0.010096578858792782,
            0.0018279843498021364,
            0.01664145104587078,
            0.00779340323060751,
            0.021788250654935837,
            0.01507691852748394,
            -0.0043764724396169186,
            -0.0223844014108181,
            -0.042463816702365875,
            -0.009675656445324421,
            0.016803046688437462,
            0.0011817493941634893,
            0.04919366165995598,
            0.012035137042403221,
            0.00794265791773796,
            0.0064864614978432655,
            -0.025820406153798103,
            0.01652720756828785,
            -0.003908426035195589,
            0.016141047701239586,
            -0.025826992467045784,
            -0.020728260278701782,
            0.047783222049474716,
            0.004999066237360239,
            -0.01718074083328247
        ],
        [
            -0.03996894881129265,
            0.03789381682872772,
            -0.014367243275046349,
            0.005160548258572817,
            -0.027474598959088326,
            -0.0025567503180354834,
            -0.016791826114058495,
            0.026649435982108116,
            -0.01147503312677145,
            0.0026829803828150034,
            0.025989580899477005,
            -0.011585061438381672,
            -0.014173753559589386,
            -0.0035574238281697035,
            -0.0379796028137207,
            0.015267355367541313,
            0.024148685857653618,
            -0.029169272631406784,
            0.02956705540418625,
            4.5962759031681344e-05,
            0.015299838967621326,
            -0.016483254730701447,
            -0.008307146839797497,
            -0.0014393272576853633,
            -0.021396491676568985,
            0.006519933231174946,
            -0.02313384599983692,
            -0.012191340327262878,
            -0.019703513011336327,
            0.0010178574593737721,
            0.017730729654431343,
            0.012698843143880367,
            -0.003615978406742215,
            -0.021411076188087463,
            0.008077243342995644,
            -0.015492565929889679,
            0.01386723481118679,
            -0.013082523830235004,
            0.007940764538943768,
            0.0022959443740546703,
            0.008080335333943367,
            0.03964689001441002,
            -0.00511582987383008,
            -0.030553476884961128,
            -0.003476409474387765,
            0.03977467492222786,
            -0.0038602612912654877,
            -0.03702954202890396,
            0.020467033609747887,
            0.040330663323402405,
            0.008403961546719074,
            0.026496894657611847,
            0.04692662134766579,
            0.02966935746371746,
            -0.02583429031074047,
            0.01390782743692398,
            0.03037497214972973,
            -0.00493601756170392,
            0.009837723337113857,
            0.028164181858301163,
            -0.02980419248342514,
            -0.019735772162675858,
            -0.011875578202307224,
            0.014914136379957199,
            0.008167760446667671,
            -0.005519148427993059,
            -0.02608811855316162,
            -0.011689819395542145,
            0.01341723557561636,
            0.02369142509996891,
            -0.0011724160285666585,
            -0.006879880093038082,
            -0.02685995027422905,
            0.00204594898968935,
            -0.04426536709070206,
            -0.04659116268157959,
            0.03276911377906799,
            0.019673798233270645,
            -0.03088456578552723,
            0.023543929681181908,
            0.030512090772390366,
            -0.007913903333246708,
            -0.02499881386756897,
            0.012666085734963417,
            -0.01969381608068943,
            -0.023389320820569992,
            0.020297115668654442,
            0.014954525977373123,
            -0.026662923395633698,
            -0.06979960948228836,
            0.013965454883873463,
            -0.025522960349917412,
            0.037495046854019165,
            -0.003752473508939147,
            -0.0258971955627203,
            -0.01081380806863308,
            -0.01701395958662033,
            -0.014321683906018734,
            0.04580355063080788,
            -0.023542219772934914,
            0.035079583525657654,
            -0.014380686916410923,
            0.00995003804564476,
            0.03094804845750332,
            0.008547114208340645,
            -0.022858954966068268,
            0.03568696603178978,
            -0.010710927657783031,
            -0.024389976635575294,
            0.012506703846156597,
            -0.0024495399557054043,
            0.016100652515888214,
            0.01606442779302597,
            0.00014367449330165982,
            -0.027092402800917625,
            0.005075422115623951,
            0.028502559289336205,
            -0.021640971302986145,
            0.0034945926163345575,
            0.00952621828764677,
            0.0178829412907362,
            0.016753925010561943,
            -0.02130378782749176,
            -0.015541859902441502,
            -0.006762809120118618,
            -0.006237743888050318,
            0.03603178262710571,
            -0.02875768207013607,
            0.033784303814172745,
            -0.025800339877605438,
            0.014366612769663334,
            0.008792942389845848,
            -0.043901462107896805,
            0.029037563130259514,
            -0.02948235534131527,
            0.00852212868630886,
            0.023015843704342842,
            0.022757187485694885,
            0.010045584291219711,
            0.036754779517650604,
            -0.03063753992319107,
            0.007910773158073425,
            -0.03647737577557564,
            -0.02370573952794075,
            0.018742932006716728,
            0.01440494041889906,
            0.0012546044308692217,
            -0.036680907011032104,
            0.015988629311323166,
            0.0049551404081285,
            -0.008092181757092476,
            -0.024098092690110207,
            -0.011384682729840279,
            -0.0646807998418808,
            -0.03849026560783386,
            -0.0023516470100730658,
            -0.04390043765306473,
            -0.01070945430546999,
            0.005417798645794392,
            -0.01778171956539154,
            -0.012393862940371037,
            0.02764485590159893,
            0.006207101047039032,
            0.030729297548532486,
            -0.003914108499884605,
            -0.04587697610259056,
            0.024473488330841064,
            -0.01705208793282509,
            -0.01804237626492977,
            0.033133551478385925,
            0.003415313782170415,
            -0.008790302090346813,
            0.013010845519602299,
            -0.009698435664176941,
            -0.00823560543358326,
            -0.023540934547781944,
            -0.02370963990688324,
            0.026423966512084007,
            0.027595186606049538,
            -0.022537516430020332,
            0.0108431875705719,
            -4.7927158448146656e-05,
            -0.007846972905099392,
            -0.003790653310716152,
            0.01932140439748764,
            0.029701299965381622,
            0.026661820709705353,
            -0.006121061276644468,
            0.027699245139956474,
            -0.012485156767070293,
            -0.013960247859358788,
            -0.022165261209011078,
            -0.034539222717285156,
            -0.006493022665381432,
            0.01389406155794859,
            0.009865140542387962,
            -0.011424641124904156,
            0.02166738361120224,
            0.013043963350355625,
            0.0388365164399147,
            0.018375111743807793,
            0.028670085594058037,
            -0.021572433412075043,
            0.016072629019618034,
            -0.013986777514219284,
            0.005804660264402628,
            -0.006810327060520649,
            0.02218305878341198,
            0.017970412969589233,
            0.020957663655281067,
            -0.012022885493934155,
            -0.03277484327554703,
            -0.012550855986773968,
            0.012872747145593166,
            -0.007476722355931997,
            0.009876858443021774,
            -0.008472493849694729,
            0.027930159121751785,
            0.015085127204656601,
            0.009680766612291336,
            -0.0347941592335701,
            -0.02481754496693611,
            -0.033264823257923126,
            -0.024778634309768677,
            -0.0031451298855245113,
            -0.07551123201847076,
            0.0076154558919370174,
            -0.010015257634222507,
            0.026548221707344055,
            0.02732045203447342,
            -0.034550078213214874,
            0.013058130629360676,
            0.03328469768166542,
            -0.016789397224783897,
            -0.021614491939544678,
            -0.01946203224360943,
            -0.006776015739887953,
            -0.014171738177537918,
            -0.00016518945631105453,
            0.016136465594172478,
            0.036402080208063126,
            -0.028625179082155228,
            -0.018292376771569252,
            0.020368436351418495,
            -0.05031881108880043,
            -0.0026631755754351616,
            -0.010853730142116547,
            -0.0015025138854980469,
            0.013522249646484852,
            -0.027328234165906906,
            0.009030579589307308,
            -0.01926742121577263,
            -0.06043881177902222,
            0.021704353392124176,
            6.773697532480583e-05,
            0.02195875160396099,
            -0.006371099501848221,
            0.031731538474559784,
            -0.014733470045030117,
            0.021338550373911858,
            -0.01120319589972496,
            0.009979385882616043,
            -0.05173895135521889,
            -0.025952201336622238,
            0.027309497818350792,
            -0.037359677255153656,
            0.018856357783079147,
            -0.0037084012292325497,
            0.02824837528169155,
            -0.051985904574394226,
            -0.0032262559980154037,
            -0.0007866191444918513,
            0.006729129236191511,
            0.0181741826236248,
            -0.009135489352047443,
            0.004763700999319553,
            0.0007078612688928843,
            0.015165502205491066,
            -0.01852618157863617,
            -0.017136024311184883,
            -0.05474056303501129,
            0.008992807939648628,
            0.033559441566467285,
            -0.04402897134423256,
            0.015952860936522484,
            0.022537941113114357,
            -0.03917206823825836,
            -0.01538038719445467,
            0.03654848784208298,
            -0.020525110885500908,
            -0.028415126726031303,
            -0.004535738844424486,
            -0.022630684077739716,
            0.028288207948207855,
            -0.02931203506886959,
            0.00035695158294402063,
            0.02265755459666252,
            0.03908146917819977,
            -0.03188646584749222,
            0.024401305243372917,
            0.028072383254766464,
            -0.0013495530001819134,
            0.00914254691451788,
            -0.010033942759037018,
            0.023793615400791168,
            0.025935057550668716,
            0.008084063418209553,
            -0.024393560364842415,
            0.007790316827595234,
            0.013977205380797386,
            0.011591970920562744,
            -0.020695671439170837,
            0.022332753986120224,
            0.005618975032120943,
            0.02580096572637558,
            0.007485551759600639,
            -0.00430580647662282,
            0.00959500391036272,
            -0.012513358145952225,
            0.002582069719210267,
            0.015290437266230583,
            -0.0352778285741806,
            0.005079368129372597,
            0.05946867913007736,
            -0.019389254972338676,
            -0.027780424803495407,
            0.0024523630272597075,
            -0.02991418167948723,
            0.025401117280125618,
            -0.00384079129435122,
            0.021239882335066795,
            0.02884252928197384,
            0.009787221439182758,
            -0.033116523176431656,
            0.02736736461520195,
            -0.02910422347486019,
            0.0077926903031766415,
            -0.025912649929523468,
            -0.009739294648170471,
            -0.0002365240070503205,
            0.01585477963089943,
            -0.02307709865272045,
            -0.0367380753159523,
            0.0012671459699049592,
            -0.01217747200280428,
            0.04573291167616844,
            0.030035383999347687,
            0.010315768420696259,
            -0.008173507638275623,
            -0.013007191009819508,
            0.031432803720235825,
            -0.03179546818137169,
            0.02417765185236931,
            -0.0038100271485745907,
            0.0010303753660991788,
            -0.008687677793204784,
            -0.03280740976333618,
            -0.015867386013269424,
            -0.00949886068701744,
            0.017584331333637238,
            -0.007186876144260168,
            0.016656234860420227,
            -0.030043751001358032,
            -0.01468586828559637,
            0.03883606567978859,
            -0.0026538753882050514,
            -0.008534916676580906,
            0.021159401163458824,
            0.008579900488257408,
            -0.004079980310052633,
            -0.012857833877205849,
            0.0021886483300477266,
            0.02749895118176937,
            -0.0062492405995726585,
            0.00574654433876276,
            -0.03462179750204086,
            0.025999518111348152,
            0.023414993658661842,
            0.03192397579550743,
            0.02492275834083557,
            0.032613739371299744,
            -0.02537340484559536,
            -0.00020942943228874356,
            -0.02982867881655693,
            0.018360964953899384,
            0.0218497421592474,
            0.022229617461562157,
            -0.012987042777240276,
            0.004816860891878605,
            0.03480551391839981,
            -0.026891881600022316,
            0.03394448012113571,
            -0.003989087417721748,
            0.03058929182589054,
            0.024601111188530922,
            -0.02419758029282093,
            -0.01093974243849516,
            0.01538949552923441,
            0.0036130445078015327,
            0.004699537996202707,
            -0.02382192574441433,
            0.012512086890637875,
            0.025725340470671654,
            -0.018570270389318466,
            -0.019700482487678528,
            0.017366154119372368,
            0.03738805651664734,
            0.008349369280040264,
            0.013790133409202099,
            -0.018307020887732506,
            -0.04880349338054657,
            0.028745947405695915,
            -0.0058400980196893215,
            -0.05724954232573509,
            -0.038378696888685226,
            0.021551266312599182,
            -0.0037804993335157633,
            0.001873063505627215,
            0.03590649738907814,
            -0.01517765037715435,
            -0.00678970804437995,
            -0.04905164986848831,
            0.036729779094457626,
            0.01173307467252016,
            0.016278166323900223,
            -0.015759088099002838,
            -0.02280367910861969,
            0.0067273699678480625,
            0.021188151091337204,
            0.007198291830718517,
            0.011099678464233875,
            -0.01640453189611435,
            -0.007740742526948452,
            0.04845188930630684,
            -0.01810566335916519,
            -0.01927773840725422,
            -0.04891682416200638,
            0.0057896776124835014,
            0.01368330791592598,
            0.006010956130921841,
            -0.0389464870095253,
            0.01308607030659914,
            -0.025859113782644272,
            0.001505005289800465,
            0.006998865865170956,
            -0.021839259192347527,
            0.015118911862373352,
            -0.03536302223801613,
            0.029842844232916832,
            0.029763685539364815,
            0.028452014550566673,
            -0.02122395485639572,
            -0.026014896109700203,
            0.012806705199182034,
            -0.013829314149916172,
            0.017875589430332184,
            -0.0002038137026829645,
            -0.012986104004085064,
            0.0005046247970312834,
            -0.00649180356413126,
            0.03421575203537941,
            -0.0043902830220758915,
            -0.011430971324443817,
            0.021259216591715813,
            0.026660015806555748,
            0.015629367902874947,
            -0.02148411050438881,
            -0.03236701712012291,
            0.024458885192871094,
            0.007969554513692856,
            -0.020062563940882683,
            -0.004672016482800245,
            -0.02330140955746174,
            0.014094353653490543,
            0.02326478250324726,
            0.018210262060165405,
            0.02927037701010704,
            -0.04135817661881447,
            -0.017056996002793312,
            -0.0021019072737544775,
            -0.003964714705944061,
            0.015125340782105923,
            -0.026288213208317757,
            -0.021789226680994034,
            0.0004909599665552378,
            0.0034804537426680326,
            0.00152396725025028,
            0.01973077654838562,
            -0.12484957277774811,
            0.03751590847969055,
            0.003628967795521021,
            -0.012924657203257084,
            -0.023642368614673615,
            0.023513495922088623,
            0.0022396589629352093,
            -0.02844495140016079,
            -0.02155119553208351,
            -0.02393447794020176,
            -0.005017759744077921,
            0.019945474341511726,
            0.0033237014431506395,
            -0.003800341859459877,
            -0.01770203933119774,
            -0.009163531474769115,
            0.033233702182769775,
            0.015554707497358322,
            -0.017819255590438843,
            0.02202988602221012,
            -0.005142664536833763,
            -0.0025133404415100813,
            -0.022842610254883766,
            -0.019220035523176193,
            0.006873246282339096,
            -0.023518407717347145,
            -0.006549994461238384,
            -0.01086505502462387,
            -0.027520060539245605,
            0.02623286470770836,
            -0.007438009139150381,
            -0.00693827448412776,
            -0.03133700415492058,
            3.2305935746990144e-05,
            -0.001943372655659914,
            -0.018052123486995697,
            -0.06719020009040833,
            -0.03670467063784599,
            -0.03165378421545029,
            0.0006297607906162739,
            0.037011876702308655,
            -0.02533513307571411,
            0.016714729368686676,
            -0.03980081155896187,
            -0.002223995281383395,
            -0.024769369512796402,
            -0.004294729791581631,
            0.0038998627569526434,
            -0.007889749482274055,
            -0.013316620141267776,
            0.03800894320011139,
            0.018002334982156754,
            -0.005688032601028681,
            0.020522747188806534,
            0.004585433751344681,
            -0.011247225105762482,
            -0.01889718323945999,
            -0.02736338973045349,
            0.03163602575659752,
            -0.005033781751990318,
            0.008178673684597015,
            0.010215147398412228,
            -0.02182840369641781,
            0.027615848928689957,
            0.004635407589375973,
            0.004712600260972977,
            0.01892828568816185,
            -0.017573954537510872,
            -0.03385017067193985,
            -0.019678300246596336,
            0.008974182419478893,
            0.004247244913130999,
            0.01390087977051735,
            0.008664751425385475,
            0.022654490545392036,
            -0.0036365785636007786,
            0.009920322336256504,
            0.024155268445611,
            -0.025943521410226822,
            -0.006941375322639942,
            0.011993490159511566,
            -0.015865929424762726,
            0.03373940661549568,
            -0.021778715774416924,
            -0.010880282148718834,
            -0.019150186330080032,
            0.020606061443686485,
            -0.0018060867441818118,
            0.00904603861272335,
            0.013769482262432575,
            -0.016105102375149727,
            -0.0242093987762928,
            -0.01825268566608429,
            0.0024295132607221603,
            -0.008912911638617516,
            0.038092341274023056,
            0.016393078491091728,
            0.023855574429035187,
            0.03302551805973053,
            0.00897527951747179,
            -0.011197545565664768,
            -0.0034233261831104755,
            -0.025227785110473633,
            0.00959029234945774,
            0.035044990479946136,
            -0.019023122265934944,
            0.02860194817185402,
            -0.03826070576906204,
            -0.008928826078772545,
            -0.013709855265915394,
            -0.011158246546983719,
            -0.009989058598876,
            0.022190146148204803,
            -0.025912711396813393,
            0.0003050310187973082,
            -0.0023665339685976505,
            -0.01768261007964611,
            0.00249807583168149,
            -0.000796921260189265,
            0.009220119565725327,
            -0.03794790431857109,
            0.015511687844991684,
            -0.026037991046905518,
            0.03450638800859451,
            -0.022962771356105804,
            0.02895900048315525,
            -0.005510563962161541,
            0.007876401767134666,
            -0.03742817044258118,
            0.005573433358222246,
            0.005989331752061844,
            -0.00670046079903841,
            -0.04273131117224693,
            -0.024701515212655067,
            0.026451773941516876,
            -0.027287360280752182,
            -0.028079675510525703,
            -0.009307589381933212,
            0.033152658492326736,
            -0.04551570862531662,
            0.0004848182725254446,
            -0.030016200616955757,
            -0.044062137603759766,
            0.004371884744614363,
            -0.011708006262779236,
            -0.0207899808883667,
            -0.024533869698643684,
            -0.010944346897304058,
            0.017376186326146126,
            0.002800601301714778,
            -0.015639441087841988,
            0.015105378814041615,
            -0.007298274431377649,
            0.030049514025449753,
            -0.049619901925325394,
            0.002443517092615366,
            -0.003470104420557618,
            0.00578894792124629,
            -0.011287013068795204,
            0.0015712096355855465,
            -0.026903199031949043,
            -0.015779441222548485,
            0.004114367533475161,
            0.011126849800348282,
            -0.022480197250843048,
            -0.022763460874557495,
            -0.020925112068653107,
            0.027408607304096222,
            -0.008367045782506466,
            -0.02013891376554966,
            -0.025157595053315163,
            0.010686882771551609,
            -0.014866268262267113,
            0.005554086063057184,
            -0.022876355797052383,
            -0.026271823793649673,
            0.024174217134714127,
            0.013732231222093105,
            -0.03187817707657814,
            -0.019506171345710754,
            -0.00392955681309104,
            0.012556709349155426,
            -0.002459267619997263,
            -0.04734252765774727,
            -0.025358065962791443,
            -0.015835708007216454,
            0.01726188324391842,
            -0.0194289218634367,
            -0.028209464624524117,
            -0.007157337851822376,
            0.003551297355443239,
            -0.021942362189292908,
            0.04592275992035866,
            0.012990695424377918,
            0.008649700321257114,
            -0.021484261378645897,
            0.0002447819570079446,
            -0.021296536549925804,
            -0.02265555039048195,
            0.034244224429130554,
            0.01674039661884308,
            0.010063674300909042,
            0.03217627853155136,
            -0.020728321745991707,
            -0.018451765179634094,
            0.028549330309033394,
            0.03182954713702202,
            -0.01678355783224106,
            -0.011032733134925365,
            -0.014101442880928516,
            0.0071543240919709206,
            -0.00011818252096418291,
            0.011067932471632957,
            -0.01655009388923645,
            -0.030235441401600838,
            -0.030715739354491234,
            -0.02586192451417446,
            0.0010031535057350993,
            -0.01006097812205553,
            0.031333692371845245,
            0.004863955080509186,
            0.018556080758571625,
            -0.0038415726739913225,
            0.03302355110645294,
            0.021574759855866432,
            0.004322736524045467,
            0.019032150506973267,
            -0.01755685731768608,
            0.041300706565380096,
            -0.009027766063809395,
            -0.012984110042452812,
            0.0358777791261673,
            0.011408246122300625,
            0.020426098257303238,
            0.01270600687712431,
            -0.0073091499507427216,
            0.0007599134114570916,
            0.013885743916034698,
            -0.00754895992577076,
            0.02380911260843277,
            -0.044487401843070984,
            0.025853725150227547,
            0.006031261757016182,
            -0.0144829535856843,
            0.028048688545823097,
            -0.03531050309538841,
            0.007718794047832489,
            0.017691871151328087,
            -0.004518384579569101,
            0.02456352673470974,
            0.008489280939102173,
            -0.0350961871445179,
            -0.02259056828916073,
            -0.013302515260875225,
            -0.02451259084045887,
            -0.015725936740636826,
            -0.0024220356717705727,
            0.02421734295785427,
            -0.04823138564825058,
            -0.02888365462422371,
            0.01082070916891098,
            0.03195459395647049,
            0.0063319941982626915,
            0.02588699944317341,
            -0.0058354479260742664,
            0.021540075540542603,
            0.02139255218207836,
            0.010086847469210625,
            -0.030896686017513275,
            0.005838159006088972,
            -0.05061856284737587,
            0.026005273684859276,
            -0.02272631600499153,
            0.0055399066768586636,
            0.023414580151438713,
            -0.009525613859295845,
            0.026686949655413628,
            0.017582563683390617,
            0.0337112732231617,
            -0.008413201197981834,
            -0.027583789080381393,
            -0.0092263612896204,
            -0.023712188005447388,
            0.031091080978512764,
            0.0027168283704668283,
            -0.007381906732916832,
            0.01617334969341755,
            -0.008683326654136181,
            -0.014048666693270206,
            -0.027336614206433296,
            0.010802211239933968,
            0.021122576668858528,
            0.007312997709959745,
            0.029417507350444794,
            -0.04353683441877365,
            0.027256811037659645,
            -0.025743233039975166,
            0.015486391261219978,
            -0.0013938606716692448,
            0.000405599334044382,
            -0.00608368543908,
            0.025802006945014,
            0.0030179331079125404,
            0.010660416446626186,
            0.0129205621778965,
            -0.012733928859233856,
            0.007505285087972879,
            -0.002288329880684614,
            -0.030114538967609406,
            -0.010317052714526653,
            0.003180593252182007,
            -0.04370957240462303,
            0.033211901783943176,
            0.00026762005290947855,
            -0.00306310853920877,
            -0.0007884631049819291,
            -0.044912900775671005,
            0.01022842712700367,
            -0.018593382090330124,
            0.021615706384181976,
            0.010194354690611362,
            -0.018575899302959442,
            0.03861913084983826,
            0.011705681681632996,
            0.00925079733133316,
            0.02307315729558468,
            -0.011593137867748737,
            0.03291706740856171,
            0.020159153267741203,
            0.016705162823200226,
            0.0064931283704936504,
            -0.03161313757300377,
            0.014093577861785889,
            -0.02349192462861538,
            -0.03841402754187584,
            0.034166932106018066,
            0.0151187339797616,
            -0.02660226821899414,
            0.019069761037826538,
            0.0181653443723917,
            0.013227466493844986,
            0.008407707326114178,
            0.014798787422478199,
            -0.019887816160917282,
            -0.017502909526228905,
            -0.03787055239081383,
            0.00016908491670619696,
            -0.0009185203234665096,
            -0.004515018314123154,
            0.01978226564824581,
            0.035114847123622894,
            -0.057067714631557465,
            0.008062153123319149,
            -0.03939298167824745,
            -0.004818986169993877,
            0.03521621972322464,
            -0.002336047124117613,
            -0.026395168155431747,
            0.023674799129366875,
            -0.00949103757739067,
            0.013723020441830158,
            -0.021625516936182976,
            0.005640921648591757,
            0.02384454943239689,
            0.021517105400562286,
            0.018795229494571686,
            0.019382217898964882,
            -0.01289538387209177,
            -0.018298104405403137,
            0.005845521576702595,
            -0.023084884509444237,
            0.01943265274167061,
            -0.003797141369432211,
            0.04055676609277725,
            0.018679043278098106,
            -0.01600608043372631,
            -0.028795048594474792,
            -0.01474693976342678,
            0.024765774607658386,
            0.011829234659671783,
            -0.021072423085570335,
            0.04389987140893936,
            -0.015707748010754585,
            0.04035189002752304,
            0.042165279388427734,
            0.017449062317609787,
            0.03083719126880169,
            -0.017529401928186417,
            -0.018981920555233955,
            -0.04192483425140381,
            0.03416333720088005,
            0.00804807897657156,
            0.006308169104158878,
            -0.045300766825675964,
            -0.007870695553719997,
            -0.0037533740978688,
            0.013968048617243767,
            0.020856332033872604,
            0.02766941674053669,
            0.053434401750564575,
            0.014940383844077587,
            0.03079775534570217,
            -0.012629535980522633,
            0.02586352825164795,
            0.022209296002984047,
            -0.008999529294669628,
            0.04880736023187637,
            -0.01673833280801773,
            0.0022441623732447624,
            0.01671544276177883,
            0.002402336336672306,
            -0.02598825842142105,
            0.0043772864155471325,
            0.025916405022144318,
            -0.005918469745665789,
            -0.018510201945900917,
            0.019122807309031487,
            0.004379946272820234,
            0.013772604987025261,
            -0.022730542346835136,
            -0.021287180483341217,
            -0.018726851791143417,
            0.027907824143767357,
            -0.008789526298642159,
            0.022394105792045593,
            0.007077510934323072,
            0.03401995077729225,
            0.026275742799043655,
            0.013510631397366524,
            -0.007762386929243803,
            -0.020262472331523895,
            -0.026187920942902565,
            -0.032899852842092514,
            0.027943400666117668,
            0.017305629327893257,
            -0.00952073559165001,
            -0.025508562102913857,
            -0.002019947860389948,
            0.007878681644797325,
            0.007176994811743498,
            -0.016209078952670097,
            0.0027539373841136694,
            0.03440145030617714,
            -0.025011833757162094,
            -0.00543572660535574,
            0.03748326376080513,
            -0.0018716966733336449,
            -0.014852015301585197,
            -0.011511352844536304,
            -0.020204123109579086,
            0.005567653104662895,
            -0.03890629857778549,
            -0.03669288381934166,
            0.010977917350828648,
            -0.023927956819534302,
            -0.001254782429896295,
            -0.0020790989510715008,
            0.01820736937224865,
            0.016553616151213646,
            0.021376751363277435,
            -0.007193796336650848,
            -0.023872768506407738,
            -0.02434411458671093,
            0.0035242182202637196,
            0.013331536203622818,
            -0.018626516684889793,
            0.03510750085115433,
            0.013876200653612614,
            -0.03203978016972542,
            -0.021575607359409332,
            0.017463073134422302,
            0.014380965381860733,
            -0.014839009381830692,
            0.02144334465265274,
            -0.04417300969362259,
            0.0317658968269825,
            0.016297347843647003,
            0.028844334185123444,
            -0.014014001004397869,
            0.03148196265101433,
            0.04662851244211197,
            0.02106533758342266,
            -0.0022248299792408943,
            -0.011208614334464073,
            -0.011205333285033703,
            0.006640250328928232,
            0.02038707584142685,
            0.005561725236475468,
            0.02565586194396019,
            0.021735280752182007,
            0.016953865066170692,
            -0.02144014649093151,
            0.0071798451244831085,
            0.03808247670531273,
            0.01939058117568493,
            0.007242648862302303,
            0.03196745738387108,
            -0.022861074656248093,
            -0.009387311525642872,
            0.019746050238609314,
            -0.010070365853607655,
            0.024663327261805534,
            -0.014293715357780457,
            0.016809437423944473,
            -0.02811821922659874,
            -0.030801091343164444,
            0.01387436781078577,
            -0.059946462512016296,
            -0.027979811653494835,
            0.012510331347584724,
            0.006018889602273703,
            -0.00290182838216424,
            -0.02451203763484955,
            0.017920346930623055,
            -0.016435621306300163,
            0.004705756437033415,
            0.019671155139803886,
            -0.026483485475182533,
            0.0005777353653684258,
            0.04183381423354149,
            0.03017684444785118,
            0.015437452122569084,
            -0.029873406514525414,
            0.009629148989915848,
            0.0008840091177262366,
            -0.016925502568483353,
            -0.011685546487569809,
            -0.009935881942510605,
            0.01317500974982977,
            -0.014133269898593426,
            0.014540798030793667,
            -0.017479337751865387,
            -0.020221421495079994,
            0.0323764868080616,
            0.016681261360645294,
            0.025250144302845
        ],
        [
            -0.001439399435184896,
            0.03345763310790062,
            -0.03305843099951744,
            -0.021905899047851562,
            0.023197229951620102,
            -0.0539122149348259,
            0.006158428266644478,
            0.01075996458530426,
            0.00791974551975727,
            0.02049962803721428,
            0.04939509928226471,
            -0.010362351313233376,
            -0.009748933836817741,
            0.027593357488512993,
            -0.06656459718942642,
            -0.04946335032582283,
            -0.0005463926936499774,
            -0.018387794494628906,
            -0.013014454394578934,
            0.004715044982731342,
            -0.002865410642698407,
            -0.008368018083274364,
            0.020025130361318588,
            0.022525357082486153,
            0.057108283042907715,
            -0.0025584260001778603,
            -0.03065417893230915,
            0.020383013412356377,
            0.02343318797647953,
            -0.03899171203374863,
            0.0014705258654430509,
            -0.03270246461033821,
            0.010124251246452332,
            -0.016129493713378906,
            0.02774914912879467,
            0.013907641172409058,
            -0.009401500225067139,
            0.03922835364937782,
            -0.00415473897010088,
            -0.018701229244470596,
            -0.031376324594020844,
            -0.059776365756988525,
            -0.014453754760324955,
            -0.0010608629090711474,
            -0.020847970619797707,
            0.0040044900961220264,
            -0.008636916056275368,
            -0.01938641630113125,
            0.00048082188004627824,
            0.005039011128246784,
            0.026078565046191216,
            0.02596045471727848,
            -0.04212578386068344,
            0.00994372833520174,
            0.019560569897294044,
            0.023277992382645607,
            0.0021525549236685038,
            -0.02538713812828064,
            0.016221528872847557,
            -0.005511401686817408,
            0.02489958144724369,
            -0.0198708176612854,
            -0.024372776970267296,
            0.00034307819441892207,
            -0.003106502117589116,
            -0.01378193125128746,
            -0.035472769290208817,
            0.035239964723587036,
            -0.0008447288419120014,
            0.02795914001762867,
            -0.019324136897921562,
            -0.00018466563778929412,
            -0.022202156484127045,
            0.023605981841683388,
            0.016316188499331474,
            -0.0648815929889679,
            0.0074328272603452206,
            -0.021330881863832474,
            -0.004915986675769091,
            0.009833711199462414,
            0.00012641037756111473,
            -0.041222892701625824,
            -0.0171931441873312,
            0.022004928439855576,
            -0.00771445082500577,
            0.016667263582348824,
            0.002750946441665292,
            -0.004950568079948425,
            -0.0036975389812141657,
            -0.040130365639925,
            -0.0073294625617563725,
            0.02923022396862507,
            -0.03707066923379898,
            -0.04702635481953621,
            0.04070930927991867,
            0.010603492148220539,
            -0.0029964898712933064,
            0.030686311423778534,
            -0.0814381092786789,
            0.020476140081882477,
            0.0020135061349719763,
            0.030397482216358185,
            0.03792226314544678,
            -0.03386659547686577,
            -0.017654990777373314,
            0.0016160417580977082,
            0.030145088210701942,
            -0.024357136338949203,
            0.007900785654783249,
            0.021369582042098045,
            0.01862623728811741,
            0.020866116508841515,
            0.003996511455625296,
            0.019281011074781418,
            -0.03755693882703781,
            0.025874897837638855,
            0.007671413477510214,
            0.0012924291659146547,
            -0.01782255806028843,
            0.008068108931183815,
            -0.013901310041546822,
            -0.01284789852797985,
            0.08954697102308273,
            -0.023853031918406487,
            0.01466150302439928,
            0.03600179776549339,
            0.030291801318526268,
            -0.017702609300613403,
            -0.0031107168179005384,
            0.013018203899264336,
            0.027453694492578506,
            0.03878931701183319,
            -0.011407946236431599,
            0.006384685169905424,
            0.0012993133859708905,
            0.0017628284404054284,
            0.010479087010025978,
            0.016933059319853783,
            0.013928743079304695,
            0.021558277308940887,
            0.032627642154693604,
            0.018010424450039864,
            -0.0004757074639201164,
            -0.019611040130257607,
            0.02409505844116211,
            0.009821495041251183,
            0.02546852082014084,
            -0.007180841639637947,
            0.02874518744647503,
            -0.019578125327825546,
            0.015646815299987793,
            0.05354185029864311,
            0.03846479207277298,
            -0.004939579870551825,
            0.01800091378390789,
            -0.000573858676943928,
            0.04124779254198074,
            0.030650651082396507,
            -0.03579390421509743,
            0.017656929790973663,
            -0.018011197447776794,
            0.009914573282003403,
            -0.024372994899749756,
            -0.027282070368528366,
            0.026442555710673332,
            0.04120954871177673,
            0.0667644590139389,
            0.027828484773635864,
            -0.005349691957235336,
            0.0008121338905766606,
            -0.0163600854575634,
            0.01752536930143833,
            -0.013369967229664326,
            -0.022126751020550728,
            -0.013549234718084335,
            -0.035946305841207504,
            -0.017361383885145187,
            -0.021299198269844055,
            -0.03217681869864464,
            0.012512069195508957,
            0.00479878718033433,
            -0.012100688181817532,
            0.026584988459944725,
            0.034454818814992905,
            0.0029730668757110834,
            0.016380032524466515,
            0.0026312589179724455,
            0.007985955104231834,
            -0.027098678052425385,
            -0.01118334848433733,
            -0.01424871850758791,
            -0.03380104526877403,
            0.012992695905268192,
            0.011780192144215107,
            0.014285487122833729,
            0.010174276307225227,
            -0.021397840231657028,
            -0.03495585173368454,
            -0.01123110856860876,
            0.024214603006839752,
            -0.019489454105496407,
            0.03373314440250397,
            0.028380872681736946,
            -0.029074883088469505,
            0.030126428231596947,
            0.03795141354203224,
            0.06629659235477448,
            0.033819861710071564,
            0.005725698079913855,
            -0.035652488470077515,
            0.025433391332626343,
            0.006993776187300682,
            0.011081064119935036,
            0.00748750614002347,
            0.0353567972779274,
            0.02459023706614971,
            -0.0013134826440364122,
            -0.015081707388162613,
            0.008667646907269955,
            -0.006365490145981312,
            -0.007781441789120436,
            -0.011051004752516747,
            0.029792392626404762,
            0.017875513061881065,
            -0.015358425676822662,
            -0.03769688308238983,
            -0.04920763522386551,
            -0.02253461629152298,
            -0.014656031504273415,
            -0.0005919148097746074,
            -0.001197021920233965,
            -0.024478362873196602,
            0.02959173172712326,
            0.025728393346071243,
            -0.03365594521164894,
            -0.030327385291457176,
            0.024979813024401665,
            -0.006874438375234604,
            -0.02391190454363823,
            -0.027333350852131844,
            -0.009631218388676643,
            0.02112637087702751,
            -0.028645239770412445,
            0.02309795282781124,
            0.00987931713461876,
            -0.02283724583685398,
            0.0011759608751162887,
            0.038718827068805695,
            -0.013033611699938774,
            -0.003719308879226446,
            -0.024808019399642944,
            -0.0026731148827821016,
            -0.005858504679054022,
            -0.012543856166303158,
            0.024254869669675827,
            0.004568031057715416,
            0.02328156679868698,
            -0.04691063612699509,
            0.02543535828590393,
            -0.013762288726866245,
            0.014847245998680592,
            0.031741827726364136,
            0.031358134001493454,
            -0.02079549804329872,
            0.0005288011161610484,
            0.028766723349690437,
            0.026104995980858803,
            0.0007222974090836942,
            0.02829701453447342,
            -0.07089553028345108,
            0.031816933304071426,
            0.04406226798892021,
            0.02342754788696766,
            -0.05478127673268318,
            -0.05505586043000221,
            0.00859084352850914,
            0.012970572337508202,
            -0.012362295761704445,
            -0.0029159137047827244,
            -0.03083285689353943,
            0.042301613837480545,
            -0.008707993663847446,
            0.033942751586437225,
            -0.02776973880827427,
            0.005790628492832184,
            -0.004153053276240826,
            -0.004709698725491762,
            0.026492413133382797,
            -0.013023413717746735,
            -0.006153976544737816,
            -0.012884479016065598,
            -0.007019710727035999,
            0.02648383006453514,
            0.01603672094643116,
            -0.011408181861042976,
            0.008731245063245296,
            -0.0033888202160596848,
            0.006935045123100281,
            0.009482589550316334,
            0.027289824560284615,
            0.012780508026480675,
            -0.01782098598778248,
            -0.01417643204331398,
            -0.01275563333183527,
            -0.02042207308113575,
            -0.00838074553757906,
            -0.00010998759535141289,
            0.023961542174220085,
            -0.006449432112276554,
            -0.010719488374888897,
            -0.025055445730686188,
            -0.015008528716862202,
            0.014521578326821327,
            -0.01437960471957922,
            -0.02269602194428444,
            0.038527246564626694,
            -0.009112405590713024,
            0.03410407900810242,
            -0.0035093016922473907,
            0.014976724050939083,
            -0.022407902404665947,
            -0.0020316769368946552,
            -0.02457028068602085,
            0.011920204386115074,
            0.010373455472290516,
            -0.017546067014336586,
            0.04141317307949066,
            0.0030961204320192337,
            0.013829736039042473,
            -0.015233222395181656,
            -0.007815806195139885,
            0.0011633356334641576,
            -0.013898584991693497,
            -0.02875244989991188,
            0.024616915732622147,
            0.020256957039237022,
            0.04255539923906326,
            -0.021767253056168556,
            -0.01198238879442215,
            -0.024092597886919975,
            -0.0015048912027850747,
            0.027319494634866714,
            -0.0050303740426898,
            -0.01468575932085514,
            -0.016086122021079063,
            0.0014311863342300057,
            0.03192796930670738,
            0.016467737033963203,
            -0.013689341954886913,
            -0.002296269405633211,
            0.017107512801885605,
            0.013400164432823658,
            0.01101598609238863,
            -0.01261916197836399,
            0.028726359829306602,
            0.0021525933407247066,
            -0.024966126307845116,
            0.007344069425016642,
            0.02228900045156479,
            -0.011723171919584274,
            0.021542152389883995,
            0.02577008306980133,
            0.02297423593699932,
            0.003970063291490078,
            0.02074899710714817,
            0.02765313722193241,
            -0.029169674962759018,
            -0.02516200765967369,
            0.033162642270326614,
            0.021041661500930786,
            -0.04008689522743225,
            -0.018841613084077835,
            -0.034922193735837936,
            0.025331923738121986,
            0.009111718274652958,
            0.006831996142864227,
            0.018810832872986794,
            0.006783081218600273,
            -0.005174044985324144,
            -0.007708800956606865,
            0.029600083827972412,
            -0.02285088784992695,
            0.03713826462626457,
            0.024487635120749474,
            0.030071154236793518,
            -0.02124546281993389,
            -0.030386611819267273,
            0.03169453516602516,
            -0.017747262492775917,
            -0.012539077550172806,
            -0.00561573076993227,
            0.028981726616621017,
            -0.013062942773103714,
            0.028314702212810516,
            0.04748325049877167,
            0.025682296603918076,
            -0.016967635601758957,
            0.006438767071813345,
            0.034653909504413605,
            -0.007581844925880432,
            0.044254422187805176,
            -0.012541588395833969,
            -0.020141098648309708,
            -0.027676863595843315,
            -0.021547116339206696,
            -0.011378966271877289,
            -0.03599514439702034,
            0.016228772699832916,
            -0.010395742021501064,
            -0.008417746983468533,
            -0.0067099882289767265,
            -0.0002535496896598488,
            -0.01168806478381157,
            0.007687513250857592,
            0.010576683096587658,
            0.008163034915924072,
            0.03289565071463585,
            -0.09298036247491837,
            -0.0038543108385056257,
            0.015326353721320629,
            -0.03875424712896347,
            0.006853749044239521,
            -0.02188248746097088,
            0.02281845547258854,
            -0.003879820229485631,
            0.03903944790363312,
            -0.0027988969814032316,
            -0.01883089542388916,
            -0.019185980781912804,
            -0.023198185488581657,
            -0.030938468873500824,
            -0.021648185327649117,
            0.037736330181360245,
            0.044074974954128265,
            0.041362687945365906,
            -0.008274496532976627,
            -0.009700728580355644,
            -0.005237967241555452,
            0.0207901019603014,
            -0.02200440876185894,
            0.03466285765171051,
            -0.01942402683198452,
            0.02133147604763508,
            -0.0182937178760767,
            -0.0018617076566442847,
            -0.018618879839777946,
            -0.02608688361942768,
            -0.09895007312297821,
            -0.01083527971059084,
            -0.006875114515423775,
            -0.02736528217792511,
            0.03370869532227516,
            0.00598299503326416,
            -0.012694835662841797,
            -0.018590999767184258,
            -0.016246095299720764,
            -0.009577902033925056,
            -0.023451130837202072,
            0.0009258956415578723,
            -0.028025014325976372,
            0.012438230216503143,
            -0.021386943757534027,
            -0.010783147998154163,
            0.025244342163205147,
            0.010362549684941769,
            0.0074597611092031,
            -0.015610074624419212,
            0.005547351203858852,
            -0.026995396241545677,
            0.01660984940826893,
            0.0012499105650931597,
            0.03299013897776604,
            0.001567940809763968,
            -0.010953128337860107,
            -0.04149023815989494,
            0.006755629554390907,
            0.021918432787060738,
            0.02198532037436962,
            -0.027657683938741684,
            -0.010515427216887474,
            0.03773772716522217,
            0.026338614523410797,
            0.0165014136582613,
            0.0077395811676979065,
            0.02951473370194435,
            0.01535766664892435,
            -0.02062040939927101,
            -0.009635984897613525,
            -0.06885754317045212,
            0.037668198347091675,
            -0.011614272370934486,
            -0.004086666740477085,
            -0.021426303312182426,
            -0.023418253287672997,
            -0.05653633549809456,
            0.004701610654592514,
            -0.0267353355884552,
            -0.007878892123699188,
            -0.022761279717087746,
            -0.015442437492311,
            0.024357914924621582,
            -0.01852956786751747,
            -0.01644587703049183,
            0.012015809305012226,
            -0.006511152721941471,
            0.0017437092028558254,
            0.023817142471671104,
            -0.020206836983561516,
            -0.02553868107497692,
            0.0005457124207168818,
            -0.005799069534987211,
            -0.007095231208950281,
            -0.036253299564123154,
            0.03804723545908928,
            -0.004868312738835812,
            -0.022621218115091324,
            -0.009270035661756992,
            0.022314831614494324,
            0.007101230323314667,
            0.0028904869686812162,
            -0.024853739887475967,
            0.03521931171417236,
            0.007308351341634989,
            -0.0195845328271389,
            -0.051933400332927704,
            -0.0508955717086792,
            0.03839445486664772,
            -0.0007693301304243505,
            0.02414563298225403,
            0.03505517169833183,
            -0.032797470688819885,
            0.038680244237184525,
            -0.011996832676231861,
            0.015488582663238049,
            0.0006703984108753502,
            -0.022056015208363533,
            0.004837447311729193,
            0.013953613117337227,
            -0.04631319269537926,
            0.031837623566389084,
            -0.011705074459314346,
            0.005287960171699524,
            0.009225082583725452,
            0.0029480934608727694,
            0.01820797473192215,
            0.008111836388707161,
            -0.006347526330500841,
            0.015913480892777443,
            -0.00676921708509326,
            -0.003896236652508378,
            -0.031567614525556564,
            -0.0123106325045228,
            0.004024451598525047,
            0.0018611850682646036,
            -0.01186343189328909,
            0.015986129641532898,
            -0.028367038816213608,
            -0.030427686870098114,
            -0.007627524901181459,
            0.029574209824204445,
            -0.006330258212983608,
            0.02598867565393448,
            0.012033404782414436,
            0.005699377506971359,
            -0.026007644832134247,
            -0.00027241214411333203,
            0.028996244072914124,
            -0.018699847161769867,
            0.006268536206334829,
            0.007870795205235481,
            -0.02767281047999859,
            0.0011659349547699094,
            -0.035507526248693466,
            -0.0005497463280335069,
            0.015755901113152504,
            -0.04423468932509422,
            0.020667387172579765,
            0.0271212849766016,
            0.004760880023241043,
            0.0035941668320447206,
            0.016128377988934517,
            0.015854960307478905,
            -0.03529028967022896,
            -0.014118361286818981,
            -0.028747407719492912,
            -0.0270028505474329,
            0.022726185619831085,
            -0.010065553709864616,
            -0.028094442561268806,
            0.010309519246220589,
            0.01600456051528454,
            0.02706640213727951,
            0.036759186536073685,
            -0.0046547213569283485,
            -0.012780031189322472,
            -0.02498333714902401,
            -0.02809220552444458,
            -0.006225446704775095,
            0.03131624311208725,
            0.023627936840057373,
            0.019186506047844887,
            0.016508227214217186,
            -0.059670425951480865,
            -0.033547770231962204,
            0.02342878095805645,
            -0.028123993426561356,
            0.01901160553097725,
            -0.02988317795097828,
            0.00916738249361515,
            -0.0031137738842517138,
            -0.026979370042681694,
            -0.03035832941532135,
            0.012353788129985332,
            -0.027301864698529243,
            0.001266620820388198,
            0.015316024422645569,
            -0.007191547192633152,
            -0.012359833344817162,
            0.03457927331328392,
            0.02285163663327694,
            0.01827792264521122,
            0.02803906239569187,
            0.013261336833238602,
            0.005461442284286022,
            -0.050123680382966995,
            0.023108581081032753,
            0.00911526195704937,
            -0.03266586735844612,
            0.009598981589078903,
            -0.035697851330041885,
            0.036288488656282425,
            -0.0016932125436142087,
            0.007411561440676451,
            -0.011641884222626686,
            0.023620350286364555,
            0.018625611439347267,
            -0.023685818538069725,
            -0.005422315094619989,
            0.01778482273221016,
            -0.0334334559738636,
            0.02255437523126602,
            0.044093504548072815,
            0.014977967366576195,
            -0.00941199529916048,
            0.01484496146440506,
            -0.06078613921999931,
            0.026330631226301193,
            -0.012598351575434208,
            -0.0029681832529604435,
            0.017448989674448967,
            0.034676216542720795,
            -0.008034675382077694,
            0.017050370573997498,
            -0.02874797210097313,
            0.006178314797580242,
            -0.013108436949551105,
            0.026054125279188156,
            0.025902453809976578,
            0.017365463078022003,
            0.010632778517901897,
            -0.0024837942328304052,
            0.006212055683135986,
            0.022170262411236763,
            0.014031357131898403,
            0.029267702251672745,
            0.017635060474276543,
            -0.012583635747432709,
            -0.002669076668098569,
            -0.008748314343392849,
            0.009805619716644287,
            -0.021177802234888077,
            0.01247061975300312,
            -0.009818140417337418,
            -0.002228426979854703,
            -0.004223451018333435,
            -0.009535441175103188,
            0.01863466575741768,
            0.002395058050751686,
            -0.01206317637115717,
            0.03279087692499161,
            -0.06761615723371506,
            0.0158525500446558,
            0.0054849362932145596,
            -0.0008728624088689685,
            0.02457922324538231,
            0.0030307325068861246,
            -0.0032542410772293806,
            0.034764986485242844,
            0.01261863112449646,
            0.021804019808769226,
            -0.016246173530817032,
            -0.012386925518512726,
            -0.048194628208875656,
            0.004645420704036951,
            0.00728285638615489,
            -0.0263578612357378,
            0.036275677382946014,
            -0.03300517797470093,
            -0.028426380828022957,
            -0.023406023159623146,
            0.026639658957719803,
            0.01693647727370262,
            -0.03353872150182724,
            0.0034892314579337835,
            0.00550474738702178,
            0.018152831122279167,
            -0.020681604743003845,
            -0.03669489547610283,
            -0.023199310526251793,
            0.0358552448451519,
            -0.011692359112203121,
            -0.011621985584497452,
            0.0030454923398792744,
            0.013564777560532093,
            0.014981506392359734,
            0.010332386940717697,
            0.029177013784646988,
            -0.007205457426607609,
            -0.00829465314745903,
            0.03485747054219246,
            0.020575063303112984,
            -0.006052144803106785,
            0.003474954515695572,
            -0.0484112910926342,
            0.0219337847083807,
            0.01977538876235485,
            -0.007773278281092644,
            0.011416753754019737,
            -0.0395604632794857,
            0.01559316087514162,
            0.036075152456760406,
            -0.007356232963502407,
            0.01232853066176176,
            -0.015930766239762306,
            -0.036326561123132706,
            0.02338298410177231,
            0.009331611916422844,
            -0.015390065498650074,
            -0.021277569234371185,
            0.017922116443514824,
            -0.005206901580095291,
            0.03308903053402901,
            -0.0019412377150729299,
            0.014128979295492172,
            0.018446236848831177,
            -0.03273311257362366,
            0.06946077197790146,
            -0.04129153490066528,
            0.007034001871943474,
            -0.003094717627391219,
            0.020206114277243614,
            -0.014880003407597542,
            -0.0026124382857233286,
            0.02344689890742302,
            0.024521077051758766,
            -0.01578414998948574,
            0.020592765882611275,
            0.029451368376612663,
            0.004990227986127138,
            -0.00408218102529645,
            -0.022303344681859016,
            0.018774455413222313,
            0.02447715774178505,
            0.007548152469098568,
            0.00887666828930378,
            -0.019844166934490204,
            -0.015586075372993946,
            -0.026200564578175545,
            0.002424377715215087,
            0.010812847875058651,
            -0.023910749703645706,
            -0.05031774938106537,
            0.02481219172477722,
            0.05647692456841469,
            0.016505707055330276,
            0.024342812597751617,
            -0.033719319850206375,
            0.013205421157181263,
            -0.004580735694617033,
            -0.028715766966342926,
            0.02252546139061451,
            -0.024837994948029518,
            0.011844840832054615,
            -0.003787088440731168,
            0.011787190102040768,
            0.006790513638406992,
            -0.01699347421526909,
            0.0020314380526542664,
            -0.0012028224300593138,
            0.005698624532669783,
            0.03495118021965027,
            0.027793660759925842,
            -0.017887374386191368,
            -0.034206077456474304,
            -0.0013621787074953318,
            0.05003024637699127,
            0.004348366986960173,
            -0.006681791972368956,
            -0.012549515813589096,
            0.016481632366776466,
            -0.005065176170319319,
            0.031927358359098434,
            0.02706165611743927,
            0.04621950909495354,
            -0.047900669276714325,
            -0.03201863542199135,
            -0.03545677661895752,
            -0.004190418869256973,
            0.030472584068775177,
            0.000934545008931309,
            -0.019569069147109985,
            0.028898239135742188,
            0.033978767693042755,
            -0.0002880478568840772,
            0.010099725797772408,
            0.015849152579903603,
            0.01811170019209385,
            -0.026338428258895874,
            0.0051454477943480015,
            0.02668401598930359,
            0.004680512938648462,
            -0.009806073270738125,
            0.025900080800056458,
            -0.0069675203412771225,
            -0.043131023645401,
            0.04240111634135246,
            -0.0063858190551400185,
            -0.005561240948736668,
            -0.02970924600958824,
            -0.029297733679413795,
            0.0004119780787732452,
            0.016155308112502098,
            -0.030277162790298462,
            0.014802790246903896,
            0.025310473516583443,
            -0.023356325924396515,
            0.01739504188299179,
            0.034753505140542984,
            -0.02825259417295456,
            -0.005845642648637295,
            0.023120738565921783,
            0.00155256234575063,
            0.0012939423322677612,
            -0.012700977735221386,
            0.016645418480038643,
            0.001826751627959311,
            0.0011226069182157516,
            0.021937772631645203,
            -0.007757354062050581,
            0.030251918360590935,
            0.00944571103900671,
            -0.019514713436365128,
            -0.015999333932995796,
            -0.019760483875870705,
            -0.004350120667368174,
            0.021099992096424103,
            -0.03189165145158768,
            -0.04290308803319931,
            -0.004109408240765333,
            -0.0171262938529253,
            -0.03578260540962219,
            0.01993521675467491,
            -0.015254455618560314,
            0.0037245203275233507,
            -0.015790220350027084,
            -0.015245513059198856,
            0.02035323902964592,
            0.011875086463987827,
            0.027784602716565132,
            0.003744653658941388,
            0.0022730559576302767,
            -0.01923394575715065,
            -0.0044736010022461414,
            -0.04984132945537567,
            0.013873995281755924,
            0.024819783866405487,
            0.03191813454031944,
            -0.031317997723817825,
            -0.017336905002593994,
            -0.00017689070955384523,
            0.00025516378809697926,
            0.024264022707939148,
            0.018369833007454872,
            0.007126583717763424,
            0.03337349370121956,
            -0.02080041915178299,
            0.02920464426279068,
            0.03230705112218857,
            -0.015219486318528652,
            0.013622594065964222,
            -0.0002309435949428007,
            0.009125807322561741,
            0.001886539626866579,
            0.014577536843717098,
            0.008381817489862442,
            -0.017363030463457108,
            0.01090993732213974,
            0.02295994758605957,
            -0.013482359237968922,
            -0.005071525927633047,
            -0.011195044033229351,
            -0.004414776805788279,
            0.028408968821167946,
            -0.041252829134464264,
            -0.04993997886776924,
            0.05860879644751549,
            -0.002230104524642229,
            0.019547967240214348,
            -0.005100710317492485,
            -0.030814573168754578,
            -0.04330608248710632,
            0.014539205469191074,
            -0.002587907714769244,
            0.04437732696533203,
            -0.05413006246089935,
            0.006949219387024641,
            0.013530438765883446,
            -0.04430530592799187,
            -0.029029395431280136,
            -0.0014008323196321726,
            0.0025277717504650354,
            -0.01896608993411064,
            -0.010615146718919277,
            0.04318481683731079,
            -0.030792415142059326,
            -0.009214059449732304,
            -0.019609088078141212,
            0.005146706011146307,
            0.022531090304255486,
            0.07133600115776062,
            0.00485047185793519,
            -0.00644892081618309,
            -0.0004864193615503609,
            0.014018046669661999,
            0.029434790834784508,
            -0.011943371035158634,
            0.012890053912997246,
            0.017086302861571312,
            -0.001207084977068007,
            -0.033459972590208054,
            0.030451899394392967,
            -0.00686557637527585,
            0.06787049025297165,
            -0.018767351284623146,
            0.01470869593322277,
            -0.009816547855734825,
            0.011629248037934303,
            0.029636912047863007,
            0.02718251384794712,
            -0.013770041987299919,
            0.03127568960189819,
            -0.02475057914853096,
            -0.016392963007092476,
            -0.005597511772066355,
            -0.023687658831477165,
            0.02157512493431568,
            0.02165013737976551,
            -0.007060965523123741,
            -0.027439111843705177,
            0.019147025421261787,
            -0.03652339428663254,
            -0.01031423732638359,
            0.02048056572675705,
            -0.023784324526786804,
            0.03737048804759979,
            0.02659706585109234,
            -0.06495514512062073,
            0.0027498577255755663,
            0.013470085337758064,
            0.0036823719274252653,
            -0.028077302500605583,
            0.033294834196567535,
            0.0025522944051772356,
            -0.007394671440124512,
            0.008526920340955257,
            0.025841841474175453,
            0.02642149105668068,
            -0.0216873437166214,
            -0.014928261749446392,
            -0.008175620809197426,
            -0.04594777151942253,
            0.019144698977470398,
            0.031216632574796677,
            0.034232236444950104,
            -0.0002247987868031487,
            0.01782669685781002,
            -0.006477333605289459,
            -0.028544966131448746,
            0.02789674513041973,
            0.0015317685902118683,
            0.0038346268702298403,
            0.002864444861188531,
            0.002416314324364066,
            -0.007436131592839956,
            -0.017109425738453865,
            0.00885801948606968,
            0.025784986093640327,
            -0.02771124057471752,
            0.017653774470090866,
            -0.0011099267285317183,
            -0.028645841404795647,
            0.016562484204769135,
            0.02011001668870449,
            0.011687123216688633,
            0.009210983291268349,
            -0.022614868357777596,
            0.005233860574662685,
            -0.011941407807171345,
            0.007125512696802616,
            -0.03487439826130867,
            0.032941725105047226,
            0.0008182403980754316,
            0.017768414691090584,
            -0.016600172966718674,
            -0.0053736320696771145,
            -0.0752309039235115,
            -0.015394850634038448,
            -0.001838609343394637,
            -0.015554710291326046,
            0.017725668847560883,
            -0.01478713657706976,
            0.01825583167374134,
            -0.0051893070340156555,
            -0.024329567328095436
        ],
        [
            0.010861702263355255,
            0.0037757582031190395,
            0.022380851209163666,
            0.00955645740032196,
            0.0009712969767861068,
            0.03963468223810196,
            0.001311308122240007,
            0.00900803692638874,
            -0.024721400812268257,
            0.03654783219099045,
            0.03191843256354332,
            -0.04012247174978256,
            0.028780560940504074,
            -0.034865520894527435,
            -0.04512724652886391,
            -0.012621848843991756,
            -0.014755734242498875,
            0.03146873041987419,
            -0.006922485772520304,
            0.017356710508465767,
            0.017693771049380302,
            0.0012127159861847758,
            0.021389564499258995,
            -0.045759640634059906,
            -0.011101095005869865,
            -0.02135799266397953,
            -0.011593480594456196,
            0.019211260601878166,
            -0.02395537681877613,
            -0.038303688168525696,
            0.007006597705185413,
            0.014607432298362255,
            0.02926219254732132,
            0.009107263758778572,
            -0.004075955133885145,
            0.0055939313024282455,
            0.029550503939390182,
            -0.03580033406615257,
            0.03473898023366928,
            -0.02235838770866394,
            -0.010799488052725792,
            -0.010934319347143173,
            -0.0029791255947202444,
            -0.002460251096636057,
            -0.00748506560921669,
            0.05155544728040695,
            0.019290441647171974,
            -0.026754580438137054,
            0.025810828432440758,
            0.004387137480080128,
            -0.015939602628350258,
            -0.006201299373060465,
            0.028394503518939018,
            -0.010684765875339508,
            -0.0005303104990161955,
            0.006142267491668463,
            0.006419512443244457,
            -0.0006383201107382774,
            0.01917586848139763,
            -0.010756880044937134,
            -0.03044472448527813,
            -0.0035475180484354496,
            -0.0023472097236663103,
            0.018120402470231056,
            -0.033485911786556244,
            -0.03882664442062378,
            0.019367672502994537,
            0.024893607944250107,
            0.006506033707410097,
            0.016359787434339523,
            0.018251454457640648,
            -0.016992419958114624,
            0.02074526995420456,
            -0.02414621226489544,
            -0.00995053444057703,
            -0.009672757238149643,
            0.04123590141534805,
            -0.004781278315931559,
            -0.012343632988631725,
            0.015313207171857357,
            0.02776302956044674,
            -0.032758478075265884,
            0.023232851177453995,
            0.023430200293660164,
            -0.006948000285774469,
            0.01256327610462904,
            0.027195720002055168,
            0.012222766876220703,
            -0.0057707070372998714,
            0.011715198867022991,
            -0.016676081344485283,
            0.0032105734571814537,
            0.016383621841669083,
            -0.03066432848572731,
            0.03373207524418831,
            -0.006655832752585411,
            0.008922274224460125,
            -0.011236184276640415,
            0.014827313832938671,
            0.008289848454296589,
            -0.04401969909667969,
            0.026238080114126205,
            0.001115329097956419,
            0.02810811437666416,
            0.014095875434577465,
            -0.0142201604321599,
            0.012121891602873802,
            0.004768253769725561,
            0.03312301263213158,
            -0.011616343632340431,
            0.015459227375686169,
            0.0059854635037481785,
            -0.004699507728219032,
            -0.030576251447200775,
            0.015321887098252773,
            -0.00815596804022789,
            0.026940489187836647,
            0.001643404713831842,
            -0.0055693406611680984,
            0.0341787151992321,
            -0.007365542929619551,
            0.017873341217637062,
            -0.012952135875821114,
            0.0007902203360572457,
            0.006439259275794029,
            -0.008571372367441654,
            0.032879509031772614,
            0.004019980784505606,
            0.03199576586484909,
            0.03950585797429085,
            0.006402879953384399,
            0.01851755380630493,
            -0.018497835844755173,
            0.0023371221031993628,
            -0.0006844316958449781,
            -0.005193150602281094,
            -0.01215387787669897,
            -0.011764591559767723,
            0.004093389492481947,
            0.0329875722527504,
            -0.010619573295116425,
            0.027465151622891426,
            0.017268069088459015,
            0.015687646344304085,
            0.04522401839494705,
            -0.003969183191657066,
            0.00968887284398079,
            0.01719558984041214,
            -0.00921050924807787,
            0.026372352614998817,
            -0.027054116129875183,
            -0.03976817801594734,
            -0.009815176948904991,
            -0.02076701819896698,
            -0.03088618628680706,
            0.009104645811021328,
            0.014104403555393219,
            0.01999407261610031,
            0.01188124343752861,
            0.006593718193471432,
            0.020520081743597984,
            -0.013099340721964836,
            0.026067672297358513,
            -0.0014398006023839116,
            -0.021685203537344933,
            -0.023345109075307846,
            0.008092749863862991,
            -0.036226626485586166,
            0.026235513389110565,
            -0.008131955750286579,
            -0.0006599155603908002,
            0.017944393679499626,
            0.009568384848535061,
            0.007298093289136887,
            0.011064558289945126,
            -0.000586507492698729,
            -0.033458687365055084,
            -0.0017824857495725155,
            0.03233817592263222,
            0.006472358014434576,
            -0.015341023914515972,
            0.04463354870676994,
            0.02974885143339634,
            -0.03553694486618042,
            0.009967029094696045,
            -0.021443653851747513,
            -0.024847742170095444,
            0.031783707439899445,
            -0.0037753810174763203,
            0.032886240631341934,
            0.021567203104496002,
            0.020029254257678986,
            -0.006436657160520554,
            -0.015137279406189919,
            -0.03358476236462593,
            -0.04023231193423271,
            -0.021199285984039307,
            0.02155151590704918,
            0.038727767765522,
            -0.02003227360546589,
            0.028517717495560646,
            0.003889104351401329,
            -0.02631821483373642,
            0.020999960601329803,
            -0.0201919823884964,
            0.004541400820016861,
            0.008673341013491154,
            0.027574356645345688,
            -0.024179119616746902,
            -0.031208287924528122,
            -0.004616233054548502,
            0.006520296912640333,
            0.005764440633356571,
            -0.00968407653272152,
            0.02357782982289791,
            -0.024186579510569572,
            -0.007057605776935816,
            0.013197864405810833,
            0.006070727948099375,
            -0.00648351339623332,
            -0.01080628763884306,
            0.04392305016517639,
            0.01582338474690914,
            0.022743355482816696,
            0.016461588442325592,
            -0.03540666401386261,
            -0.008354797959327698,
            0.014392223209142685,
            0.022344548255205154,
            0.004127130378037691,
            0.023834507912397385,
            0.04639124125242233,
            -0.002693064045161009,
            -0.021215908229351044,
            -0.033445168286561966,
            -0.0066765486262738705,
            0.008775590918958187,
            0.012529569678008556,
            -0.022786738350987434,
            0.017354458570480347,
            -0.01515812799334526,
            -0.0019490884151309729,
            -0.041474197059869766,
            -0.025926552712917328,
            -0.02349645271897316,
            -0.027601661160588264,
            -0.035394877195358276,
            -0.006043212953954935,
            -0.017699582502245903,
            -0.006258336827158928,
            0.016255531460046768,
            -0.04725491628050804,
            0.015525782480835915,
            -0.03159298002719879,
            0.008950088173151016,
            -0.02576129138469696,
            0.038492970168590546,
            0.027545597404241562,
            0.025435110554099083,
            0.010528200305998325,
            0.020536843687295914,
            0.00502528203651309,
            0.031077591702342033,
            -0.0035332515835762024,
            -0.014000434428453445,
            -0.008323343470692635,
            0.023705193772912025,
            -0.03163822740316391,
            -0.009937869384884834,
            -0.024231960996985435,
            -0.01964651420712471,
            -0.027950705960392952,
            -0.022097308188676834,
            -0.010867426171898842,
            -0.022805646061897278,
            -0.023405063897371292,
            0.008191610686480999,
            -0.0011708467500284314,
            -0.020450184121727943,
            -0.0014409886207431555,
            -0.02683270163834095,
            0.007059465162456036,
            -0.02773367427289486,
            0.007551085669547319,
            -0.008452655747532845,
            0.028375931084156036,
            0.02790658362209797,
            -0.0373956672847271,
            -0.006693161558359861,
            0.008556587621569633,
            -0.018661191686987877,
            0.007043946534395218,
            -0.009076784364879131,
            -0.006705211941152811,
            -0.0074222576804459095,
            0.01669282093644142,
            -0.013548262417316437,
            -0.03189468011260033,
            0.006854195147752762,
            -0.03145929425954819,
            -0.015488306991755962,
            0.015943080186843872,
            0.003143587149679661,
            -0.009743532165884972,
            0.028714273124933243,
            0.02948911115527153,
            -0.01024214643985033,
            -0.010418299585580826,
            -0.027815639972686768,
            0.04476925730705261,
            -0.047763437032699585,
            -0.016164429485797882,
            -0.006884107366204262,
            -0.0253694336861372,
            0.007860434241592884,
            -0.039931520819664,
            -0.008768054656684399,
            0.024079374969005585,
            0.014617452397942543,
            0.02698071487247944,
            0.023045917972922325,
            0.04561108723282814,
            -0.008780010975897312,
            0.017546575516462326,
            -0.0204777829349041,
            0.035534489899873734,
            -0.03581224009394646,
            0.021998604759573936,
            0.03498227521777153,
            -0.013252710923552513,
            0.02813396416604519,
            0.018124287948012352,
            -0.000979073578491807,
            -0.004356784280389547,
            -0.012233859859406948,
            0.018876051530241966,
            0.0196850523352623,
            0.01604585535824299,
            0.019523540511727333,
            0.03426037356257439,
            0.017118925228714943,
            -0.007013977970927954,
            0.048290789127349854,
            -0.019027763977646828,
            -0.012985501438379288,
            0.010875467211008072,
            -0.03429357707500458,
            -0.030327335000038147,
            -0.015409298241138458,
            0.004545268137007952,
            0.028200043365359306,
            0.005983208306133747,
            0.017933199182152748,
            -0.008951021358370781,
            -0.029403503984212875,
            0.010693470016121864,
            0.0002682946214918047,
            -0.041610054671764374,
            0.015052033588290215,
            0.01877427101135254,
            0.017276616767048836,
            0.03414176031947136,
            0.008893325924873352,
            0.00644306605681777,
            -0.005122229922562838,
            -0.021672384813427925,
            -0.028751583769917488,
            0.03875204920768738,
            -0.02201601304113865,
            0.0025628595612943172,
            0.013516592793166637,
            0.027089569717645645,
            0.024848992004990578,
            -0.0033141232561320066,
            -0.012987041845917702,
            0.015108178369700909,
            0.016523655503988266,
            -0.023980384692549706,
            -0.0025670400355011225,
            0.03489326685667038,
            0.0060975332744419575,
            0.02984992042183876,
            -0.023861609399318695,
            -0.002607665490359068,
            -0.005766118410974741,
            0.04272124916315079,
            -0.010453127324581146,
            0.04742933064699173,
            0.017509154975414276,
            0.03877143934369087,
            0.03809785470366478,
            0.010039987973868847,
            -0.016581635922193527,
            0.031245272606611252,
            -0.0065063899382948875,
            0.002773076528683305,
            0.012649535201489925,
            0.011902162805199623,
            -0.006443777587264776,
            -0.04045506566762924,
            0.004178212024271488,
            0.013985790312290192,
            -0.014073419384658337,
            -0.0006408810731954873,
            -0.02111346460878849,
            -0.029584519565105438,
            -0.004127920605242252,
            0.010962151922285557,
            -0.01605648174881935,
            -0.02849537506699562,
            -0.028785955160856247,
            -0.02619115076959133,
            -0.014003302901983261,
            0.03851604461669922,
            7.103387906681746e-05,
            -0.02310940809547901,
            0.02561824955046177,
            -0.01780940219759941,
            -0.026789993047714233,
            0.0010243551805615425,
            -0.009504895657300949,
            0.01582893170416355,
            0.010055487975478172,
            0.004585414659231901,
            0.01022911537438631,
            -0.02382325381040573,
            0.0033836206421256065,
            0.004013427998870611,
            -0.008411443792283535,
            -0.0241682231426239,
            -0.001588022569194436,
            0.03838344290852547,
            -0.025200052186846733,
            -0.031210632994771004,
            -0.008839257061481476,
            0.042630475014448166,
            -0.009297936223447323,
            -0.0015859890263527632,
            0.038889024406671524,
            -0.02164899930357933,
            -0.01617112010717392,
            0.012937034480273724,
            -0.02329414151608944,
            0.013248572126030922,
            0.01980903372168541,
            -0.0039030946791172028,
            0.03573942929506302,
            0.01566033624112606,
            0.02568618766963482,
            0.026522351428866386,
            -0.003478625323623419,
            -0.01648572087287903,
            -0.006913450080901384,
            -0.03323723003268242,
            -0.015397090464830399,
            0.02249796874821186,
            0.02513296715915203,
            -0.0041770958341658115,
            0.003246357198804617,
            0.015244516544044018,
            0.03186419606208801,
            -0.0038266603369265795,
            -0.0026101181283593178,
            0.049245283007621765,
            -0.03427321836352348,
            0.009863401763141155,
            0.022824808955192566,
            0.010317280888557434,
            -0.020963475108146667,
            -0.004715565126389265,
            -0.013608505949378014,
            -0.02473199926316738,
            0.01287836767733097,
            -0.01004886906594038,
            -0.03077220916748047,
            -0.03955420106649399,
            -0.033618200570344925,
            0.02650284394621849,
            -0.001840722979977727,
            -0.033533111214637756,
            -0.012228807434439659,
            -0.004201280418783426,
            -0.012392889708280563,
            -0.026533666998147964,
            -0.04646442085504532,
            0.016881175339221954,
            0.022758914157748222,
            0.0174474585801363,
            -0.013511240482330322,
            0.03822973370552063,
            -0.012731637805700302,
            0.00564579525962472,
            -0.014310305006802082,
            -0.021137936040759087,
            0.04104427248239517,
            0.010979962535202503,
            -0.012430869974195957,
            -0.023501312360167503,
            0.004357814323157072,
            0.025934873148798943,
            0.025603152811527252,
            -0.013775237835943699,
            -0.01470321323722601,
            -0.025137625634670258,
            0.0002449956664349884,
            0.016783826053142548,
            0.01837225630879402,
            0.001615647692233324,
            0.020762817934155464,
            0.008340763859450817,
            0.030497971922159195,
            0.0025595477782189846,
            0.0037132662255316973,
            0.007671343628317118,
            0.039779409766197205,
            -0.00329847470857203,
            0.04429204761981964,
            -0.0029268432408571243,
            -0.015690291300415993,
            0.0046673244796693325,
            -0.0027180807664990425,
            0.00804974790662527,
            -0.023202436044812202,
            -0.03205050155520439,
            0.019336048513650894,
            0.011303296312689781,
            0.03168480470776558,
            -0.02415032498538494,
            0.00534889567643404,
            -0.035831209272146225,
            0.014527149498462677,
            -0.009283187799155712,
            -0.003930836450308561,
            0.009302939288318157,
            0.018868859857320786,
            0.014812452718615532,
            -0.016321633011102676,
            0.03269549086689949,
            0.006952131167054176,
            -0.00780836958438158,
            -0.034110959619283676,
            -0.004313931334763765,
            0.02565392479300499,
            0.055515944957733154,
            -0.0054746633395552635,
            -0.028229011222720146,
            0.0036601307801902294,
            -0.005850127432495356,
            0.011486813426017761,
            0.024876996874809265,
            -0.0175966527312994,
            -0.03672264143824577,
            0.008740931749343872,
            0.008028294891119003,
            0.026614435017108917,
            0.014029494486749172,
            0.033690325915813446,
            -0.031344544142484665,
            0.031001584604382515,
            0.021117884665727615,
            0.029655052348971367,
            -0.027623610571026802,
            -0.005686923395842314,
            -0.03120329976081848,
            -0.029891109094023705,
            0.003691167337819934,
            0.002717601601034403,
            -0.020577220246195793,
            -0.013356287032365799,
            -0.014167762361466885,
            -0.016477450728416443,
            0.0037262632977217436,
            0.03677975758910179,
            -0.02475654147565365,
            -0.014064665883779526,
            -0.00553138880059123,
            0.03043813817203045,
            -0.01881258934736252,
            0.019969124346971512,
            0.02363765425980091,
            -0.011463847942650318,
            0.0020587993785738945,
            0.01246101688593626,
            0.04179813712835312,
            -0.012520396150648594,
            -0.01616949588060379,
            0.025837954133749008,
            -0.011904177255928516,
            -0.021678315475583076,
            -0.01807086355984211,
            0.026065882295370102,
            0.010998744517564774,
            -0.012172278016805649,
            0.03573301061987877,
            -0.0104684978723526,
            0.023004455491900444,
            0.007375144865363836,
            0.00438159704208374,
            0.025296248495578766,
            -0.016823776066303253,
            -0.02650499902665615,
            0.024662600830197334,
            0.0036631287075579166,
            -0.020605692639946938,
            0.021708130836486816,
            -0.004219789989292622,
            0.018262319266796112,
            0.013995744287967682,
            -0.009843428619205952,
            -0.021294107660651207,
            -0.008431689813733101,
            -0.034726835787296295,
            -0.0026910107117146254,
            -0.032156430184841156,
            -0.008357103914022446,
            0.009248977527022362,
            0.01758752577006817,
            -0.0017791418358683586,
            0.021965643391013145,
            -0.012401261366903782,
            -0.025076579302549362,
            0.0160385612398386,
            0.021898586302995682,
            -0.02265447936952114,
            -0.02130851335823536,
            0.02867494896054268,
            0.021020879969000816,
            -0.020224183797836304,
            0.009602002799510956,
            -0.007333020679652691,
            -0.02033076621592045,
            -0.02520507015287876,
            -0.01960943639278412,
            -0.013738238252699375,
            -0.012764382176101208,
            0.019568054005503654,
            -0.019333215430378914,
            0.00724179670214653,
            -0.01853066496551037,
            0.01191733032464981,
            0.018115835264325142,
            -0.009475179947912693,
            -0.027800187468528748,
            -0.0017922280821949244,
            -0.010424495674669743,
            0.021117541939020157,
            -0.014771539717912674,
            -0.015997612848877907,
            0.03447047621011734,
            0.017313091084361076,
            0.0246452447026968,
            0.031733930110931396,
            0.007288305554538965,
            0.009125510230660439,
            0.0172251146286726,
            -0.018180495128035545,
            0.042460374534130096,
            -0.00410122936591506,
            0.007980221882462502,
            0.020438259467482567,
            -0.0034821070730686188,
            0.014301983639597893,
            0.02040841616690159,
            -0.007622379343956709,
            0.016902931034564972,
            0.021678581833839417,
            -0.016489380970597267,
            0.00386445433832705,
            -0.0314091332256794,
            0.011272145435214043,
            0.013083094730973244,
            0.019548989832401276,
            -0.023790258914232254,
            0.014054868370294571,
            -0.006276249419897795,
            0.004116514232009649,
            0.016149435192346573,
            -0.014490874484181404,
            -0.018864957615733147,
            -0.007860176265239716,
            0.024388261139392853,
            -0.025232188403606415,
            0.020479006692767143,
            0.018902141600847244,
            0.024811532348394394,
            -0.01297797355800867,
            0.025604836642742157,
            0.003362577874213457,
            0.014258353970944881,
            -0.011438369750976562,
            0.02709762006998062,
            -0.002893059281632304,
            -0.011416414752602577,
            -0.02245970256626606,
            0.0034633525647222996,
            0.016950009390711784,
            0.02220439352095127,
            -0.007236275356262922,
            0.014832703396677971,
            -0.00013742003648076206,
            -0.04289151355624199,
            -0.015301377512514591,
            0.012840352952480316,
            -0.02388564497232437,
            0.039014026522636414,
            -0.028261367231607437,
            -0.02362479642033577,
            -0.004457642789930105,
            0.014141466468572617,
            0.03371327370405197,
            0.02292194403707981,
            -0.017967555671930313,
            -0.004521572031080723,
            0.0020304333884269,
            -0.022652843967080116,
            0.0052245683036744595,
            -0.015401463024318218,
            0.049792755395174026,
            -0.0006960487808100879,
            -0.00343975517898798,
            0.037262823432683945,
            -0.015538372099399567,
            -0.022167541086673737,
            -0.01782885566353798,
            0.015014266595244408,
            0.0045455340296030045,
            -0.002558025298640132,
            -0.006119684316217899,
            -0.025623761117458344,
            -0.01834203116595745,
            0.019664756953716278,
            0.025840267539024353,
            0.020332707092165947,
            -0.0007082030060701072,
            0.01372897531837225,
            0.005884532816708088,
            0.00031792986555956304,
            0.012010710313916206,
            0.013548260554671288,
            -0.0025150959845632315,
            -0.03423948585987091,
            -0.025944745168089867,
            0.02438213862478733,
            -0.035535912960767746,
            -0.023752059787511826,
            -0.037916187196969986,
            0.0013638632372021675,
            -0.014258936047554016,
            0.03240200877189636,
            0.015254313126206398,
            -0.0026644764002412558,
            0.019333885982632637,
            -0.008380058221518993,
            -0.05197574198246002,
            -0.00857224315404892,
            -0.018732978031039238,
            -0.005524370353668928,
            -0.00486529478803277,
            -0.04888680577278137,
            0.028717605397105217,
            -0.01937035098671913,
            0.026968469843268394,
            0.0016714920056983829,
            0.007383446209132671,
            -0.03346645459532738,
            0.01301515381783247,
            0.017707228660583496,
            0.017972994595766068,
            0.038381800055503845,
            -0.011983591131865978,
            -0.042244985699653625,
            -0.000828493619337678,
            0.024811267852783203,
            -0.01601739041507244,
            0.013260568492114544,
            0.003058273810893297,
            -0.03438729792833328,
            -0.07529309391975403,
            -0.03729413077235222,
            0.012620280496776104,
            0.048569124191999435,
            -0.022540336474776268,
            0.02176583930850029,
            0.03399191424250603,
            0.019426381215453148,
            0.006634209305047989,
            -0.029770074412226677,
            0.01687781885266304,
            -0.015840187668800354,
            0.045256469398736954,
            -0.035444412380456924,
            0.020405152812600136,
            -0.01862546056509018,
            0.0015008853515610099,
            0.027540963143110275,
            0.007866851054131985,
            0.023057570680975914,
            0.014337399043142796,
            -0.026801694184541702,
            -0.032413553446531296,
            -0.04288088157773018,
            -0.029953528195619583,
            -0.023598939180374146,
            -0.025909164920449257,
            -0.06152632087469101,
            -0.025038830935955048,
            -0.007719856221228838,
            0.008075583726167679,
            -0.012053776532411575,
            -0.032758817076683044,
            0.05203629285097122,
            -0.018693502992391586,
            0.01333501935005188,
            0.023785648867487907,
            -0.014949151314795017,
            -0.012576673179864883,
            -0.009605906903743744,
            0.024063561111688614,
            -0.017084181308746338,
            -0.049895528703927994,
            -0.02993515506386757,
            0.011255779303610325,
            0.024732200428843498,
            -0.007090241648256779,
            0.0008959181141108274,
            -0.0016679641557857394,
            0.008029070682823658,
            0.028373470529913902,
            -0.026431769132614136,
            -0.006178544368594885,
            0.03361561521887779,
            0.010964054614305496,
            -0.05622580274939537,
            0.003146278904750943,
            -0.03951249271631241,
            0.023410707712173462,
            -0.014610875397920609,
            0.020613545551896095,
            -0.020801452919840813,
            0.024400023743510246,
            -0.008841811679303646,
            -0.0012012891238555312,
            0.00340656703338027,
            0.0393463559448719,
            -0.019459528848528862,
            0.0229744091629982,
            -0.00864738505333662,
            0.016798771917819977,
            0.023508194833993912,
            -0.0018833663780242205,
            -0.004195890389382839,
            -0.0025019401218742132,
            -0.002151337917894125,
            -0.0029224613681435585,
            0.01927560567855835,
            -0.014742446132004261,
            -0.0017270438838750124,
            0.03104335069656372,
            0.02154654823243618,
            -0.0010437280870974064,
            0.0007179612293839455,
            0.018735839053988457,
            0.040484897792339325,
            0.06162576749920845,
            0.03503810241818428,
            -0.013689813204109669,
            0.013524630106985569,
            -0.004376242868602276,
            -0.004938505124300718,
            0.032292816787958145,
            0.018099570646882057,
            0.010695774108171463,
            -0.03806833550333977,
            0.037576738744974136,
            -0.03538591042160988,
            -0.030977658927440643,
            -0.018229298293590546,
            -0.05022076889872551,
            0.006931021809577942,
            0.00015576888108626008,
            0.00230058329179883,
            -0.01215312723070383,
            0.015138251706957817,
            -0.01832646317780018,
            0.019208192825317383,
            0.002213280415162444,
            0.027488360181450844,
            0.0351371206343174,
            0.004354897886514664,
            -0.017785094678401947,
            0.0013962045777589083,
            0.0034911278635263443,
            0.011720451526343822,
            -0.04218130186200142,
            -0.00027052327641285956,
            0.026906656101346016,
            -0.01960967481136322,
            0.04090990126132965,
            -0.0044758496806025505,
            0.02285679429769516,
            0.01618993654847145,
            -0.030432134866714478,
            0.008340933360159397,
            -0.001756375189870596,
            -0.04311680793762207,
            -0.042124271392822266,
            0.013241257518529892,
            0.0003893226385116577,
            -0.04085344448685646,
            -0.03642360493540764,
            0.022191762924194336,
            0.015993256121873856,
            -0.021023361012339592,
            0.01007966697216034,
            0.0032279687002301216,
            -0.015361194498836994,
            0.013727935962378979,
            -0.005911773536354303,
            -0.01513520535081625,
            -0.018907766789197922,
            -0.0015914200339466333,
            -0.011638329364359379,
            0.013037806376814842,
            0.04362385347485542,
            0.035161036998033524,
            0.004684946499764919,
            0.025459518656134605,
            0.009834571741521358,
            0.010980033315718174,
            -0.037571266293525696,
            0.0013813796686008573,
            0.02965044416487217,
            -0.014961318112909794,
            0.045382171869277954,
            0.004794899374246597,
            0.012836014851927757,
            -0.0020980294793844223,
            0.0020390842109918594,
            -0.02244052290916443,
            0.01049360167235136,
            3.8527552533196285e-05,
            0.039903197437524796,
            -0.05080094560980797,
            0.02967769280076027,
            -0.004953492432832718,
            0.0201429296284914,
            -0.03170783817768097,
            -0.038088757544755936,
            0.007712295278906822,
            0.020464593544602394,
            0.010176136158406734,
            0.02119561657309532,
            0.013040629215538502,
            -0.000169864171766676,
            0.01725642755627632,
            0.01605631224811077,
            -0.013588661327958107,
            0.03357699140906334,
            -7.706536416662857e-05,
            -0.027306705713272095,
            -0.006896195933222771,
            0.02388438768684864,
            -0.039513807743787766,
            0.04845670983195305,
            0.028604982420802116,
            -0.00843848753720522,
            -0.049320757389068604,
            0.023252399638295174,
            0.004153091460466385,
            -0.041377387940883636,
            -0.025665156543254852,
            0.00956922210752964,
            -0.014430605806410313,
            0.02257431112229824,
            0.0178730059415102,
            -0.019481724128127098,
            -0.01994304731488228,
            0.009728782810270786,
            0.023574549704790115,
            0.0035248559433966875,
            -0.02463403157889843,
            -0.013058454729616642,
            0.030393002554774284,
            0.030464911833405495,
            -0.013148771598935127,
            0.017752766609191895,
            0.033306073397397995,
            -0.00423597265034914,
            0.03062921017408371,
            -0.0033490643836557865,
            0.0005509902257472277,
            0.018411021679639816,
            0.015368469059467316,
            -0.0005143822054378688,
            -0.016857217997312546,
            -0.0010538717033341527,
            0.007633274886757135,
            0.020971303805708885,
            0.024172130972146988,
            0.019574657082557678,
            -0.029515914618968964,
            -0.02850620448589325,
            0.011136835440993309,
            -0.012568294070661068,
            0.018026888370513916,
            -0.001618614885956049,
            0.01654164493083954,
            -0.0012638451298698783,
            -0.007417356129735708,
            1.206856995850103e-05,
            -0.018365751951932907,
            -0.009831196628510952,
            -0.024364110082387924,
            -0.0066757830791175365,
            0.012913360260426998,
            0.007815982215106487,
            -0.021061021834611893,
            -0.006057532038539648,
            -0.014132005162537098,
            -0.004803495481610298,
            -0.007827161811292171,
            -0.009222022257745266,
            0.013383276760578156,
            -0.02447636052966118
        ],
        [
            0.007704372983425856,
            0.03557802736759186,
            -0.029057728126645088,
            0.016788551583886147,
            -0.010101656429469585,
            -0.012996053323149681,
            -0.03313526511192322,
            -0.036706965416669846,
            -0.015332862734794617,
            -0.00830878410488367,
            -0.01971898227930069,
            0.029343074187636375,
            -0.03206409886479378,
            -0.005356637295335531,
            0.035185884684324265,
            0.011422866955399513,
            0.02111729606986046,
            0.020147819072008133,
            -0.01776423119008541,
            -0.0035925402771681547,
            0.0115817217156291,
            -0.021438168361783028,
            -0.023641612380743027,
            -0.0252974983304739,
            -0.047013700008392334,
            0.02385328710079193,
            -0.014259817078709602,
            0.027119243517518044,
            0.021140137687325478,
            0.027471309527754784,
            -0.024385342374444008,
            0.01823432743549347,
            0.01417508814483881,
            0.030869238078594208,
            -0.017104091122746468,
            0.026201453059911728,
            -0.032225292176008224,
            -0.0020302259363234043,
            -0.023303989320993423,
            -0.02125733718276024,
            -0.02901631034910679,
            0.0013008045498281717,
            -0.012838831171393394,
            0.017154507339000702,
            0.015547416172921658,
            0.0010481044882908463,
            -0.004540963564068079,
            0.0031463976483792067,
            0.00099164096172899,
            -0.009651098400354385,
            0.0007777365390211344,
            0.029429983347654343,
            -0.02034415677189827,
            -0.009213641285896301,
            0.0322970449924469,
            0.018738465383648872,
            -0.014003262855112553,
            0.0019287423929199576,
            0.02021479234099388,
            -0.019156180322170258,
            0.0050266035832464695,
            -0.013341237790882587,
            0.0146709643304348,
            0.0039194077253341675,
            0.0007584457634948194,
            -0.021496448665857315,
            0.01681135594844818,
            0.02107773721218109,
            -0.005907094571739435,
            0.024114252999424934,
            0.025402287021279335,
            -3.598810872063041e-05,
            0.026584474369883537,
            0.019421199336647987,
            -0.029810570180416107,
            0.039038293063640594,
            -0.014372054487466812,
            -0.03481309860944748,
            -0.03286365792155266,
            0.021512167528271675,
            -0.0064779468812048435,
            -0.004548597149550915,
            0.0020739964675158262,
            0.018358103930950165,
            -0.009186116978526115,
            0.02994564361870289,
            -0.030711526051163673,
            0.01203365158289671,
            0.004412803333252668,
            0.06869746744632721,
            -0.0023778153117746115,
            0.002625733148306608,
            -0.021400384604930878,
            -0.011927044950425625,
            0.01720436103641987,
            0.022437728941440582,
            -0.01742679812014103,
            -0.006040122359991074,
            -0.024535102769732475,
            -0.0030974179971963167,
            -0.009019318968057632,
            -0.0268822330981493,
            0.03775154799222946,
            1.9815666746580973e-05,
            0.005697401240468025,
            -0.00024454283993691206,
            -0.017222188413143158,
            0.021723918616771698,
            -0.006459459662437439,
            -0.028854724019765854,
            0.031773246824741364,
            0.02145407721400261,
            0.012506647035479546,
            -0.0016298763221129775,
            -0.034328509122133255,
            0.026340724900364876,
            0.004547860939055681,
            -0.027585014700889587,
            0.029904048889875412,
            -0.007205500733107328,
            -0.0028174009639769793,
            0.02430027537047863,
            0.046947844326496124,
            -0.018485868349671364,
            -0.023802394047379494,
            0.012732258066534996,
            0.009790020994842052,
            0.009846637025475502,
            0.00993154663592577,
            0.01639329083263874,
            -0.009341252036392689,
            -0.005838491953909397,
            -0.026863455772399902,
            0.029359115287661552,
            -0.006510871462523937,
            -0.00500440550968051,
            -0.009735893458127975,
            -0.00730984378606081,
            0.025645440444350243,
            -0.028187358751893044,
            -0.011015744879841805,
            -0.010975837707519531,
            -0.04113687947392464,
            1.2904321920359507e-05,
            -0.059874191880226135,
            -0.022107992321252823,
            -0.017531607300043106,
            -0.02952098846435547,
            -1.3813195437251125e-05,
            0.01806469075381756,
            0.030850686132907867,
            -0.030146608129143715,
            0.04042690247297287,
            0.008474240079522133,
            0.02249521017074585,
            -0.01908138021826744,
            -0.0010649163741618395,
            -0.019396016374230385,
            0.0066871652379632,
            0.022051388397812843,
            -0.032614126801490784,
            0.013313762843608856,
            -0.02876981720328331,
            0.026425568386912346,
            0.01651822403073311,
            0.044145215302705765,
            0.05651339888572693,
            -0.06194942444562912,
            0.02774372324347496,
            0.028778165578842163,
            -0.02818390540778637,
            -0.032616037875413895,
            0.0030030265916138887,
            0.013879207894206047,
            0.026555923745036125,
            0.0077409762889146805,
            -0.01757633686065674,
            0.014059689827263355,
            -0.017382550984621048,
            0.01937081478536129,
            0.04042494297027588,
            0.008007436990737915,
            -0.020557502284646034,
            -0.027057847008109093,
            -0.009185376577079296,
            0.012964925728738308,
            0.024461746215820312,
            -0.009943859651684761,
            0.003898375667631626,
            -0.017792310565710068,
            0.01926477998495102,
            0.005066404584795237,
            -0.046630244702100754,
            -0.013279237784445286,
            -0.010218819603323936,
            0.03471929579973221,
            -0.032325539737939835,
            0.03020365722477436,
            0.0035472752060741186,
            -0.017837516963481903,
            0.00022400723537430167,
            -0.01787196472287178,
            0.017242498695850372,
            0.004205416422337294,
            0.01897331513464451,
            0.004461117088794708,
            -0.017581241205334663,
            0.025446098297834396,
            0.009544306434690952,
            0.006984050385653973,
            -0.002487752353772521,
            -0.015946263447403908,
            0.0217171311378479,
            -0.009320712648332119,
            0.02016095072031021,
            0.02818414568901062,
            0.01875210367143154,
            0.007453127298504114,
            0.028437616303563118,
            -0.026246223598718643,
            -0.014015479013323784,
            -0.018902001902461052,
            -0.04501776397228241,
            -0.04590735584497452,
            0.004013048950582743,
            0.07884681969881058,
            0.003347679739817977,
            0.020981626585125923,
            0.020024675875902176,
            0.025560297071933746,
            -0.011123129166662693,
            0.01758720353245735,
            0.019978884607553482,
            -0.00011750981502700597,
            -0.011156232096254826,
            0.022425174713134766,
            -0.014111620374023914,
            0.014985345304012299,
            -0.01462570670992136,
            -0.014462165534496307,
            -0.03616182133555412,
            -0.019058017060160637,
            -0.006354036275297403,
            0.005827509798109531,
            0.018617475405335426,
            0.03490164503455162,
            0.015023755840957165,
            -0.0529676117002964,
            -0.026074297726154327,
            -0.037593141198158264,
            0.005662346724420786,
            0.015274044126272202,
            0.04458869248628616,
            0.016877515241503716,
            0.03215866535902023,
            -0.025868438184261322,
            0.01754816249012947,
            -0.030537497252225876,
            0.03181702271103859,
            -0.03416965529322624,
            -0.013702824711799622,
            0.01548217236995697,
            0.0227909367531538,
            0.02743510715663433,
            0.02039567567408085,
            0.006582383532077074,
            0.01946195214986801,
            -0.020803915336728096,
            -0.02286893129348755,
            -0.017007505521178246,
            0.006400460842996836,
            0.025321265682578087,
            -0.0005244225030764937,
            -0.026508860290050507,
            0.03068952076137066,
            0.009115881286561489,
            -0.0014506221050396562,
            -0.010257412679493427,
            0.008675592020154,
            0.013842510059475899,
            0.028471553698182106,
            0.009845178574323654,
            0.03245018422603607,
            -0.004552067723125219,
            0.011772189289331436,
            -0.030629126355051994,
            -0.010405933484435081,
            -0.009940199553966522,
            0.030690455809235573,
            0.00436923373490572,
            -0.0086642662063241,
            0.016382601112127304,
            -0.012272552587091923,
            -0.006098541896790266,
            0.020578553900122643,
            -0.03438660874962807,
            0.0319054014980793,
            0.020449457690119743,
            -0.013760431669652462,
            -0.024485033005475998,
            -0.011487336829304695,
            0.011053670197725296,
            -0.01144458819180727,
            0.03168727084994316,
            -0.014596940949559212,
            -0.004161938093602657,
            -0.008144257590174675,
            0.014135300181806087,
            -0.02743365615606308,
            -0.03375427797436714,
            -0.002499932888895273,
            -0.011387656442821026,
            -0.01368653029203415,
            -0.028313174843788147,
            0.002897073281928897,
            0.01735120825469494,
            0.013120487332344055,
            0.018368354067206383,
            0.003984854090958834,
            -0.02518092468380928,
            -0.019360188394784927,
            0.02401444874703884,
            -0.013035011477768421,
            0.005026631988584995,
            0.011808459646999836,
            -0.008841372095048428,
            -0.00751302856951952,
            0.026968879625201225,
            -0.024519773200154305,
            -0.023434599861502647,
            -0.002785393502563238,
            0.009499464184045792,
            -0.006777689326554537,
            -0.018739355728030205,
            0.03647064417600632,
            -0.012479241006076336,
            0.02806471474468708,
            0.02864895574748516,
            -0.013060678727924824,
            0.03529084473848343,
            0.005756243597716093,
            -0.025245456025004387,
            0.010530900210142136,
            -0.0031801010482013226,
            0.0006121745682321489,
            -0.021103713661432266,
            0.03939688950777054,
            0.02724207192659378,
            -0.009366802871227264,
            -0.06388701498508453,
            0.029112132266163826,
            -0.011882810853421688,
            -0.023082543164491653,
            -0.03628450259566307,
            0.04194127023220062,
            0.03684833645820618,
            -0.0194516833871603,
            -0.00695290369912982,
            -0.0017788390396162868,
            -0.0028796251863241196,
            0.01536047924309969,
            -0.02370891161262989,
            -0.020239975303411484,
            -0.02435874380171299,
            -0.03562741354107857,
            -0.0005187437636777759,
            0.000960382167249918,
            -0.01906898058950901,
            -0.029398463666439056,
            0.0005917649250477552,
            -0.0042277853935956955,
            0.02115907520055771,
            -0.007653845474123955,
            -0.01319940760731697,
            0.00711715966463089,
            -0.009936302900314331,
            -0.009618561714887619,
            -0.022433409467339516,
            -0.0110431844368577,
            -0.0021729692816734314,
            0.016240693628787994,
            -0.0062755076214671135,
            -0.00767676392570138,
            0.007067100610584021,
            0.01922609657049179,
            -0.01670081540942192,
            0.004759439267218113,
            0.03740924596786499,
            0.011727770790457726,
            -0.02585151046514511,
            0.026381589472293854,
            -0.02660404145717621,
            -0.030936632305383682,
            -0.03117901273071766,
            0.04554857313632965,
            -0.004837491549551487,
            -0.033011939376592636,
            -0.025216713547706604,
            -0.024602048099040985,
            -0.010703139007091522,
            0.04206452518701553,
            0.025834403932094574,
            0.003701227717101574,
            -0.029284320771694183,
            -0.00424471590667963,
            0.024571824818849564,
            0.005224963650107384,
            -0.01933734305202961,
            -0.021667055785655975,
            -0.0067871008068323135,
            -0.040106602013111115,
            -0.024159589782357216,
            -0.02126859873533249,
            0.014155040495097637,
            -0.026794614270329475,
            -0.018574921414256096,
            0.023746289312839508,
            -0.015250918455421925,
            0.0029132820200175047,
            0.017142105847597122,
            -0.012795791029930115,
            -0.009282343089580536,
            -0.04724074900150299,
            -0.023774323984980583,
            0.018218068405985832,
            0.017721138894557953,
            -0.01532559935003519,
            0.013296914286911488,
            -0.020062630996108055,
            0.0007239320548251271,
            -0.02030223049223423,
            0.0014147779438644648,
            0.029952365905046463,
            -0.024201413616538048,
            0.004995519295334816,
            -0.018092364072799683,
            -0.014089314267039299,
            0.020226245746016502,
            -0.017565825954079628,
            0.015389799140393734,
            0.012108629569411278,
            0.026318838819861412,
            -0.0036765504628419876,
            -0.005277383606880903,
            -0.02536480315029621,
            -0.003894045017659664,
            0.020139288157224655,
            -0.04254276677966118,
            -0.03999124467372894,
            0.0011061374098062515,
            -0.007411748170852661,
            0.0053963856771588326,
            0.02914493717253208,
            -0.02055913582444191,
            0.0476260781288147,
            -0.010101281106472015,
            -0.011266501620411873,
            0.008396741934120655,
            -0.036553673446178436,
            0.01395698543637991,
            0.040911633521318436,
            0.01476582046598196,
            -0.02043435536324978,
            0.01042428519576788,
            0.004456418100744486,
            -0.008879536762833595,
            -0.01427674014121294,
            0.025456583127379417,
            0.012354851700365543,
            0.02932746522128582,
            0.027323191985487938,
            0.009719939902424812,
            -0.017839811742305756,
            -0.04357392340898514,
            0.024818751960992813,
            0.0044126552529633045,
            0.026933517307043076,
            0.0012563387863337994,
            0.006608801893889904,
            -0.005020329263061285,
            -0.007814300246536732,
            0.004802579525858164,
            -0.07115215808153152,
            0.015201368369162083,
            -0.06751269102096558,
            -0.026288460940122604,
            -0.02379634976387024,
            -0.020714525133371353,
            0.03609685227274895,
            -0.000808399636298418,
            0.02939239889383316,
            -0.030831458047032356,
            -0.018765294924378395,
            0.04657802730798721,
            0.029771294444799423,
            -0.006496594753116369,
            0.0248182974755764,
            -0.00020883911929558963,
            -0.015215094201266766,
            -0.019666364416480064,
            0.022498609498143196,
            0.011747178621590137,
            -0.018605293706059456,
            -0.0029404861852526665,
            -0.02351515181362629,
            -0.023113319650292397,
            0.02863030880689621,
            -0.02445831149816513,
            0.0009536542347632349,
            0.025133371353149414,
            -0.03086942434310913,
            -0.012784740887582302,
            -0.019899388775229454,
            0.04115244746208191,
            0.02608487755060196,
            -0.02602081000804901,
            0.018780332058668137,
            -0.017938988283276558,
            0.013768551871180534,
            0.014747598208487034,
            0.003588446881622076,
            -0.012452934868633747,
            0.023868681862950325,
            0.009662442840635777,
            -0.01873348467051983,
            -0.0017574336379766464,
            -0.026067649945616722,
            0.021620552986860275,
            0.0037071274127811193,
            0.0019582663662731647,
            0.016767533496022224,
            -0.011595183052122593,
            -0.012518106028437614,
            0.02840794436633587,
            0.02401461824774742,
            -0.028052164241671562,
            0.02145696058869362,
            0.018789440393447876,
            0.01882544718682766,
            -0.00651379581540823,
            0.02866567298769951,
            0.01415395550429821,
            -0.02865292690694332,
            -0.05992065742611885,
            -0.009041794575750828,
            0.0187352504581213,
            0.003842467674985528,
            -0.03265530988574028,
            -0.021746383979916573,
            -0.04447073116898537,
            0.0261002779006958,
            0.0037280507385730743,
            0.0028541930951178074,
            0.016527699306607246,
            0.027637416496872902,
            0.014052913524210453,
            -0.014712318778038025,
            0.024640662595629692,
            0.008720725774765015,
            0.0070586418733000755,
            -0.013342674821615219,
            0.014296980574727058,
            0.015576544217765331,
            -0.0893159955739975,
            0.015729960054159164,
            0.035817645490169525,
            0.019006315618753433,
            0.006631035357713699,
            0.004009440541267395,
            -0.03720533475279808,
            0.01260426640510559,
            0.0009885873878374696,
            -0.025718916207551956,
            -0.022030696272850037,
            0.018315250054001808,
            0.016822580248117447,
            0.009041844867169857,
            -0.019378600642085075,
            0.0027024701703339815,
            0.019593549892306328,
            0.01761469803750515,
            -0.018238749355077744,
            -0.02831236645579338,
            0.013562732376158237,
            0.02188423089683056,
            -0.024481313303112984,
            -0.01488391775637865,
            0.0035203304141759872,
            -0.016299761831760406,
            0.011522195301949978,
            -0.037366874516010284,
            0.006039269734174013,
            -0.008935978636145592,
            -0.019687755033373833,
            0.02076585404574871,
            0.022796455770730972,
            0.01937883347272873,
            -0.018193533644080162,
            -0.022430863231420517,
            0.02518484927713871,
            -0.02863774262368679,
            -0.02615162916481495,
            -0.007965000346302986,
            -0.03073795884847641,
            -0.025438176468014717,
            0.006085632834583521,
            0.023281948640942574,
            -0.013947004452347755,
            0.0054237800650298595,
            0.0031090385746210814,
            -0.022646958008408546,
            -0.0015734535409137607,
            0.014318810775876045,
            0.025066375732421875,
            -0.013846827670931816,
            -0.07256831228733063,
            -0.005825365893542767,
            -0.010873534716665745,
            -0.01974675990641117,
            0.0328533835709095,
            -0.0019996343180537224,
            0.03975431248545647,
            -0.018139448016881943,
            -0.004392308183014393,
            0.005225384142249823,
            -0.008806810714304447,
            0.025186484679579735,
            -0.021543415263295174,
            0.034873947501182556,
            -0.019263941794633865,
            -0.008870566263794899,
            0.007354439236223698,
            0.02029002644121647,
            -0.004946397617459297,
            -0.05480039864778519,
            -0.01811177469789982,
            0.027170563116669655,
            -0.03907546028494835,
            -0.04315042868256569,
            -0.021516388282179832,
            0.008924025110900402,
            -0.016141263768076897,
            0.020588869228959084,
            0.03531195968389511,
            0.015123571269214153,
            0.029082264751195908,
            0.00969659723341465,
            -0.0031238256487995386,
            -0.011564726941287518,
            -0.005287325941026211,
            0.006649856921285391,
            0.018340785056352615,
            0.017044782638549805,
            -0.002233481267467141,
            -0.0023469089064747095,
            -0.03389902412891388,
            -0.0014568286715075374,
            -0.032862771302461624,
            -0.04554938152432442,
            0.0016044887015596032,
            0.04096106439828873,
            -0.01904086396098137,
            0.016138864681124687,
            -0.02363922819495201,
            -0.017983753234148026,
            -0.011239951476454735,
            0.037836577743291855,
            0.020130202174186707,
            -0.010491269640624523,
            0.006425612140446901,
            -0.029793567955493927,
            -0.0119420625269413,
            -0.005316352471709251,
            0.008601426146924496,
            -0.02014400251209736,
            0.009013421833515167,
            0.0278260987251997,
            -0.015097838826477528,
            0.0203340332955122,
            -0.03032376803457737,
            0.0024708721321076155,
            0.015539410524070263,
            -0.02168727107346058,
            0.03564739227294922,
            -0.02476164884865284,
            0.017474383115768433,
            -0.005174153484404087,
            0.009158482775092125,
            0.019135132431983948,
            0.024861184880137444,
            -0.014781528152525425,
            0.012568560428917408,
            -0.02666868455708027,
            0.00716673769056797,
            -0.003860695753246546,
            -0.0027264112140983343,
            0.02277587540447712,
            0.01871473342180252,
            -0.00043548428220674396,
            0.016676519066095352,
            -0.015141176991164684,
            -0.02410302311182022,
            0.0027093221433460712,
            -0.02802928350865841,
            -0.030104592442512512,
            0.01561000943183899,
            0.00589132122695446,
            -0.026590866968035698,
            0.00038730737287551165,
            0.011244178749620914,
            -0.031234771013259888,
            0.0026060279924422503,
            0.01546446792781353,
            -0.01861107349395752,
            0.008834123611450195,
            -0.024721069261431694,
            0.04456909000873566,
            0.00629098666831851,
            0.0003626530524343252,
            -0.00766548840329051,
            0.0073922467418015,
            0.027224790304899216,
            -0.016715725883841515,
            -0.03720264881849289,
            -0.007172879297286272,
            0.011611013673245907,
            0.01618659682571888,
            -0.019644517451524734,
            -0.008800785057246685,
            -0.0245787613093853,
            0.003941599745303392,
            -0.01056650560349226,
            -0.025440797209739685,
            0.017658041790127754,
            0.023383643478155136,
            -0.027071211487054825,
            0.025082891806960106,
            -0.009763645939528942,
            0.00121557735837996,
            -0.015751661732792854,
            0.028650743886828423,
            -0.0039179278537631035,
            0.017307477071881294,
            0.004613582044839859,
            -0.02671271190047264,
            -0.001963901799172163,
            0.020300433039665222,
            0.018649734556674957,
            0.0056953439489007,
            0.006705279927700758,
            0.019544249400496483,
            -0.004144724924117327,
            -0.027569066733121872,
            -0.07176188379526138,
            0.005004451144486666,
            0.021501664072275162,
            0.004382988903671503,
            -0.02395247481763363,
            0.022224465385079384,
            0.009582278318703175,
            -0.04208559915423393,
            0.03585461899638176,
            -0.010034066624939442,
            0.004064458888024092,
            0.017674513161182404,
            0.013706179335713387,
            -0.018528379499912262,
            -0.02253219112753868,
            -0.006077231839299202,
            0.006762917153537273,
            0.00032788192038424313,
            -0.0023111237678676844,
            0.03796696662902832,
            0.03527958318591118,
            0.026498768478631973,
            -0.027042176574468613,
            -0.024633167311549187,
            -0.05028947815299034,
            -0.010104970075190067,
            -0.018843095749616623,
            -0.047343749552965164,
            0.0020588969346135855,
            -0.020809723064303398,
            -0.022836824879050255,
            -0.014977697283029556,
            0.015680676326155663,
            0.07033440470695496,
            0.02102397009730339,
            0.017172347754240036,
            -0.007885223254561424,
            0.014065248891711235,
            -0.025830848142504692,
            -0.07110846787691116,
            -0.05890936776995659,
            0.021882515400648117,
            0.03579743206501007,
            0.02105684019625187,
            0.016423655673861504,
            -0.010438449680805206,
            -0.03552999347448349,
            -0.026637179777026176,
            -0.0462544783949852,
            0.0036295903846621513,
            0.01860981434583664,
            0.003643857780843973,
            0.03215575963258743,
            -0.07482866197824478,
            -0.02067534439265728,
            -0.021062765270471573,
            -0.011938384734094143,
            -0.029822232201695442,
            0.04746851697564125,
            -0.002647739602252841,
            0.014408168382942677,
            -0.031281523406505585,
            -0.017763148993253708,
            -0.018651437014341354,
            -0.00043808462214656174,
            0.01676245592534542,
            -0.011847021989524364,
            0.011181977577507496,
            -0.014231477864086628,
            -0.01966453343629837,
            -0.03453155234456062,
            0.00672907242551446,
            0.011873288080096245,
            0.019286194816231728,
            0.014649121090769768,
            0.019322970882058144,
            -0.005074334796518087,
            0.0034073423594236374,
            -0.0009680279763415456,
            0.0283358097076416,
            -0.011481798253953457,
            0.027744149789214134,
            0.025607608258724213,
            -0.0022379341535270214,
            -0.01847916468977928,
            0.00909159891307354,
            0.03787725046277046,
            -0.004637470468878746,
            0.010250329039990902,
            -0.006122450344264507,
            0.02352958358824253,
            0.022008389234542847,
            -0.008191064931452274,
            0.022003935649991035,
            0.03020290471613407,
            -0.02032829262316227,
            0.002507708966732025,
            0.021543005481362343,
            0.007735060527920723,
            0.034328069537878036,
            -0.010482723824679852,
            0.014315018430352211,
            0.01325901411473751,
            0.035128671675920486,
            -0.008703027851879597,
            0.028083469718694687,
            -0.034258194267749786,
            -0.019927365705370903,
            -0.01650313287973404,
            0.0032322010956704617,
            0.05456610023975372,
            -0.05117027834057808,
            0.02819514088332653,
            -0.0012344826245680451,
            0.045113082975149155,
            0.008075235411524773,
            -0.010688147507607937,
            -0.06104014441370964,
            -0.0012919694418087602,
            -0.018934838473796844,
            -0.01763700321316719,
            0.00816430151462555,
            -0.016980761662125587,
            -0.026781300082802773,
            -0.037342142313718796,
            0.02440463751554489,
            0.0193597674369812,
            0.0014313423307612538,
            0.034919373691082,
            0.03165816143155098,
            0.004971318878233433,
            0.01981768012046814,
            -0.02467072196304798,
            -0.026484735310077667,
            0.0010450847912579775,
            0.021459467709064484,
            0.01867464929819107,
            0.02909395843744278,
            -0.005789014045149088,
            0.002848956733942032,
            0.0321841686964035,
            0.0407305546104908,
            0.007278705947101116,
            0.012875676155090332,
            -0.014329682104289532,
            -0.006714310497045517,
            0.00478662783280015,
            -0.022859923541545868,
            -0.0038093843031674623,
            -0.019632944837212563,
            0.02302214317023754,
            0.018325265496969223,
            0.00631699850782752,
            0.025805510580539703,
            -0.00950840674340725,
            -0.034904804080724716,
            0.043418627232313156,
            -0.003576666582375765,
            -0.020461898297071457,
            -0.001033460139296949,
            0.07683641463518143,
            -0.028561413288116455,
            -0.007204320281744003,
            0.0010838138405233622,
            0.027157871052622795,
            0.011588460765779018,
            -0.03291475772857666,
            0.006589514669030905,
            0.006232026498764753,
            -0.012664269655942917,
            0.03683977201581001,
            -0.022906523197889328,
            0.007100892253220081,
            -0.031664762645959854,
            0.003365805372595787,
            0.007539530750364065,
            -0.018801067024469376,
            -0.0013520711800083518,
            0.02456524781882763,
            0.008785468526184559,
            0.03921946510672569,
            0.02425433322787285,
            -0.00945876631885767,
            -0.017275812104344368,
            -0.02839682251214981,
            -0.0198513176292181,
            -0.004512947052717209,
            0.03401592746376991,
            0.012940945103764534,
            0.06188666447997093,
            -0.01892925053834915,
            -0.005316997412592173,
            0.0026428350247442722,
            -0.005803964100778103,
            -0.0272784736007452,
            0.026412736624479294,
            -0.020876087248325348,
            0.06829550117254257,
            0.006514011882245541,
            -0.021677879616618156,
            -0.01041835080832243,
            -0.030763043090701103,
            0.031354233622550964,
            -0.0004854128637816757,
            -0.017101332545280457,
            0.023458560928702354,
            -0.0035218559205532074,
            0.01532995980232954,
            -0.005890797823667526,
            0.0469948947429657,
            -0.010099130682647228,
            0.026071801781654358,
            -0.023221397772431374,
            -0.028936434537172318,
            -0.020553570240736008,
            0.05181555449962616,
            0.015049337409436703,
            -0.018788345158100128,
            0.029911348596215248,
            0.0024444812443107367,
            -0.02533544786274433,
            -0.0032659319695085287,
            0.02699047140777111,
            0.007269008085131645,
            -0.022761601954698563,
            0.010961880907416344,
            -0.01170271821320057,
            0.01739039644598961,
            0.005381295457482338,
            -0.009414782747626305,
            -0.00692732585594058,
            -0.014080213382840157,
            -0.017349373549222946,
            0.010676948353648186,
            0.009378110058605671,
            -0.02452089637517929,
            9.9256714747753e-05,
            0.02608456276357174,
            -0.012144091539084911,
            0.005305702332407236,
            -0.010094608180224895,
            -0.0051144883036613464,
            -0.00831226073205471,
            0.02989530749619007,
            -0.017525125294923782,
            -0.011200862936675549,
            0.009105994366109371,
            -0.04965818673372269,
            0.04999784380197525,
            -0.0026524728164076805,
            0.02664339914917946,
            -0.008675983175635338,
            -0.010555362328886986,
            0.010415440425276756,
            0.05007290840148926,
            -0.023069923743605614,
            0.023813512176275253,
            0.018905604258179665,
            0.0063461316749453545,
            -0.016187572851777077,
            -0.027565430849790573,
            0.014566270634531975,
            -0.04691890627145767,
            -0.014233129099011421,
            0.018465835601091385,
            -0.007131929509341717,
            0.010821283794939518,
            0.02019207738339901,
            0.0396798811852932,
            -0.02046911045908928,
            -0.02014068141579628
        ],
        [
            0.015253803692758083,
            -0.028298281133174896,
            -0.005828020162880421,
            -0.02402236871421337,
            -0.024102704599499702,
            0.04328504949808121,
            -0.019280344247817993,
            0.021786624565720558,
            0.012784346006810665,
            0.03247061371803284,
            0.026957973837852478,
            -0.014604543335735798,
            0.03707129508256912,
            0.0021303731482475996,
            0.00651077926158905,
            0.039182037115097046,
            0.030797161161899567,
            -0.016619032248854637,
            0.005336221307516098,
            0.01827068068087101,
            0.006058673840016127,
            -0.01578390970826149,
            -0.012998265214264393,
            0.005339992698282003,
            -0.002213293919339776,
            0.017631884664297104,
            -0.006034563295543194,
            -0.017124583944678307,
            0.008992848917841911,
            0.029693804681301117,
            0.0319499596953392,
            -0.03897080570459366,
            0.0212770514190197,
            -0.0011433623731136322,
            -0.010569509118795395,
            -0.0003907698846887797,
            -0.011657839640974998,
            0.014249549247324467,
            0.034689389169216156,
            0.01867172308266163,
            0.004490705206990242,
            0.03310757875442505,
            0.01306090410798788,
            0.004115243908017874,
            0.0018153776181861758,
            -0.021350018680095673,
            0.010340957902371883,
            3.9502494473708794e-05,
            0.018181435763835907,
            0.010835337452590466,
            0.01902068220078945,
            -0.01748524233698845,
            0.042407259345054626,
            -0.005834205076098442,
            -0.030026676133275032,
            -0.029077580198645592,
            0.013228941708803177,
            0.015059034340083599,
            0.021086284890770912,
            0.026762353256344795,
            -0.03614163026213646,
            -0.010753341019153595,
            -0.008641554042696953,
            0.032882872968912125,
            -0.02123613841831684,
            -0.031186942011117935,
            -0.027480632066726685,
            0.025998996570706367,
            -0.01131075993180275,
            -0.018538422882556915,
            -0.0064430818893015385,
            0.025413140654563904,
            -0.006751712411642075,
            -0.021382352337241173,
            -0.04911911487579346,
            -0.02624589577317238,
            -0.0213549192994833,
            -0.020287830382585526,
            -0.0063790613785386086,
            0.03334495425224304,
            0.02386474423110485,
            0.01936652883887291,
            -0.012760964222252369,
            0.002136566676199436,
            -0.007061484269797802,
            0.021249568089842796,
            0.024204837158322334,
            -0.02438860759139061,
            0.0015305336564779282,
            -0.00028156620101071894,
            -0.004954978358000517,
            -0.014756593853235245,
            0.029650915414094925,
            -0.0169218759983778,
            0.003725465852767229,
            0.02390928380191326,
            0.0017796613974496722,
            -0.011049795895814896,
            0.016092251986265182,
            0.007982657290995121,
            -0.035203929990530014,
            -0.028094254434108734,
            -0.023686517030000687,
            -0.022482553496956825,
            -0.009231350384652615,
            -0.008689824491739273,
            0.0178518109023571,
            0.021621089428663254,
            -0.05338752269744873,
            -0.009866037406027317,
            0.049941640347242355,
            0.039350565522909164,
            0.008456330746412277,
            -0.003784998320043087,
            0.026513777673244476,
            -0.016666658222675323,
            0.005031890701502562,
            0.002046651905402541,
            0.03201323747634888,
            -0.008227471262216568,
            0.019912458956241608,
            0.028128881007432938,
            -0.05138073116540909,
            -0.01697390154004097,
            -0.018127011135220528,
            0.017184322699904442,
            0.013451899401843548,
            0.02011856809258461,
            -0.016346702352166176,
            -0.008178086951375008,
            0.022217314690351486,
            0.0011165555333718657,
            -0.03330836817622185,
            -0.01024012640118599,
            -0.010735394433140755,
            -0.03412263095378876,
            -0.008253965526819229,
            -0.01705525815486908,
            0.003620560746639967,
            0.00589175708591938,
            -0.008134805597364902,
            0.016050895676016808,
            -0.001887472695671022,
            0.021205758675932884,
            -0.06148521229624748,
            -0.007762272376567125,
            0.04167594015598297,
            0.011064366437494755,
            0.026551278308033943,
            0.009250722825527191,
            0.00638764351606369,
            -0.01036321371793747,
            0.011339699849486351,
            -0.020135998725891113,
            0.010487842373549938,
            0.020598694682121277,
            -0.03960121423006058,
            0.02127637155354023,
            -0.014758173376321793,
            -0.011875547468662262,
            0.013246498070657253,
            0.0097550880163908,
            -0.0034923446364700794,
            0.014598308131098747,
            0.012070393189787865,
            -0.034371793270111084,
            -0.04887047037482262,
            0.0032934483606368303,
            -0.05288198962807655,
            0.02606857195496559,
            0.0176541805267334,
            0.00655295280739665,
            0.018311619758605957,
            -0.018053477630019188,
            0.0010096307378262281,
            0.016901066526770592,
            -0.02802334725856781,
            0.009055118076503277,
            -0.01625632680952549,
            -0.011043932288885117,
            -0.037428706884384155,
            0.008305053226649761,
            0.0002739447809290141,
            -0.015987109392881393,
            0.022020934149622917,
            -0.020179137587547302,
            -8.730046829441562e-06,
            0.0040403977036476135,
            0.027052799239754677,
            0.006034090183675289,
            -0.008329986594617367,
            -0.03233829140663147,
            -0.04233032464981079,
            -0.025881050154566765,
            0.008558757603168488,
            -0.008721208199858665,
            -0.041659802198410034,
            0.028182463720440865,
            0.018567729741334915,
            0.02036213129758835,
            0.0031290773767977953,
            -0.029933778569102287,
            -0.020066305994987488,
            0.009404201060533524,
            -0.025547511875629425,
            -0.006973755545914173,
            -0.03295908123254776,
            0.012626365758478642,
            -0.012821337208151817,
            -0.028394104912877083,
            0.010670607909560204,
            -0.015922045335173607,
            0.026822621002793312,
            -0.009932934306561947,
            -0.025192659348249435,
            0.0007305093458853662,
            0.03126300498843193,
            -0.013086597435176373,
            0.03157224878668785,
            -0.010129035450518131,
            0.03319660946726799,
            0.013158891350030899,
            0.04200127348303795,
            0.030107153579592705,
            0.006952890660613775,
            0.02881867066025734,
            -0.02042917162179947,
            0.019110212102532387,
            -0.0031213914044201374,
            0.033130817115306854,
            -0.013170928694307804,
            0.014609318226575851,
            -0.004139330703765154,
            -0.03231184929609299,
            -0.049350589513778687,
            -0.02626759745180607,
            0.03396548330783844,
            -0.013386910781264305,
            0.009703274816274643,
            0.01105443574488163,
            -0.01704317517578602,
            0.009938756935298443,
            0.00048706697998568416,
            0.02593730203807354,
            -0.010306216776371002,
            -0.02435590885579586,
            0.03284282237291336,
            -0.016533976420760155,
            0.02216070331633091,
            -0.0020502309780567884,
            0.011951925233006477,
            0.001811685157008469,
            -0.0123728783801198,
            -0.008580576628446579,
            0.0038907919079065323,
            -0.020177002996206284,
            0.010673501528799534,
            0.009862495586276054,
            -0.04496080428361893,
            0.012823546305298805,
            -0.0017201901646330953,
            0.01696278341114521,
            -0.021132001653313637,
            -0.013993147760629654,
            -0.022506602108478546,
            -0.02936841920018196,
            -0.010461844503879547,
            -0.02284935675561428,
            0.014018488116562366,
            -0.026988696306943893,
            -0.006990642286837101,
            -0.03840361163020134,
            0.00028372055385261774,
            -0.017163556069135666,
            0.007813653908669949,
            -0.02166108228266239,
            0.037755150347948074,
            -0.03433934971690178,
            0.021622084081172943,
            0.00018713176541496068,
            -0.028670430183410645,
            -0.0399116575717926,
            -0.02158188447356224,
            -0.03376475349068642,
            0.015220449306070805,
            0.005677856504917145,
            -0.011883330531418324,
            0.0005859985249117017,
            0.019380629062652588,
            0.00903006549924612,
            -0.021450376138091087,
            -0.03169720992445946,
            0.0037717174272984266,
            0.017485376447439194,
            0.012761864811182022,
            -0.038886066526174545,
            -0.023213615640997887,
            0.008087544701993465,
            -0.05624288320541382,
            -0.03304798901081085,
            0.032347775995731354,
            0.03079785406589508,
            -0.0005745050148107111,
            0.02080928348004818,
            -0.0038462835364043713,
            -0.0295046828687191,
            0.020203139632940292,
            0.0031867027282714844,
            -0.017828192561864853,
            -0.0032015491742640734,
            0.01938905566930771,
            -0.0012801868142560124,
            -0.005327275488525629,
            -0.029327576979994774,
            -0.036438681185245514,
            -0.024061840027570724,
            -0.004217231646180153,
            0.01948736049234867,
            -0.014168298803269863,
            -0.016144482418894768,
            -0.008916742168366909,
            0.024204548448324203,
            0.01412287913262844,
            0.03613332659006119,
            -0.030798085033893585,
            0.007243030704557896,
            -0.024196108803153038,
            0.01758921705186367,
            -0.0341486856341362,
            -0.02070992812514305,
            -0.030188042670488358,
            -0.01346032414585352,
            0.008150016888976097,
            -0.044579509645700455,
            0.011269308626651764,
            -0.043836578726768494,
            0.035207848995923996,
            0.029331011697649956,
            0.0030303983949124813,
            0.022365018725395203,
            0.031158333644270897,
            0.023470522835850716,
            -0.029100898653268814,
            -0.0013334770919755101,
            0.011220544576644897,
            0.04381849616765976,
            0.004464976489543915,
            -0.040122952312231064,
            -0.00011961559357587248,
            0.002387492684647441,
            0.03225977346301079,
            0.0011906115105375648,
            0.03627260401844978,
            0.03109310381114483,
            -0.04721527919173241,
            0.0391773097217083,
            -0.006346822250634432,
            -0.03719741478562355,
            0.004536828957498074,
            0.029405267909169197,
            0.026463791728019714,
            0.015386606566607952,
            -0.02380778267979622,
            0.019143495708703995,
            -0.011956662870943546,
            -0.013494821265339851,
            0.024267179891467094,
            -0.010420313104987144,
            0.00658830814063549,
            0.033818889409303665,
            0.030545741319656372,
            0.0022724068257957697,
            0.0208668764680624,
            -0.0062020509503781796,
            -0.022034531459212303,
            0.012449441477656364,
            0.025749344378709793,
            -0.006211812607944012,
            -0.00408055679872632,
            0.015782522037625313,
            0.019761808216571808,
            -0.017952969297766685,
            0.016121024265885353,
            0.03032141923904419,
            0.00337774190120399,
            0.04373103380203247,
            0.0033154068514704704,
            0.006010507233440876,
            -0.024557584896683693,
            0.008822682313621044,
            -0.013076950795948505,
            -0.041065409779548645,
            -0.0348120853304863,
            0.001797575387172401,
            -0.022072669118642807,
            0.005201622843742371,
            -0.011015860363841057,
            -0.014771295711398125,
            0.014006799086928368,
            0.029968656599521637,
            -0.013051792979240417,
            -0.02652573585510254,
            -0.023456737399101257,
            -0.007027955260127783,
            0.0020328208338469267,
            -0.03209276869893074,
            0.011814359575510025,
            -0.012429574504494667,
            -0.004310655873268843,
            -0.024996543303132057,
            -0.012057574465870857,
            0.03572544455528259,
            0.024926938116550446,
            -0.019058246165513992,
            -0.014940585009753704,
            0.030465416610240936,
            -0.01214087288826704,
            -0.03836878761649132,
            -0.029730187729001045,
            0.0008480059332214296,
            -0.041051916778087616,
            -0.018796557560563087,
            0.014749336056411266,
            0.005798927042633295,
            0.01595182903110981,
            0.0005927354213781655,
            -0.006889833603054285,
            0.010766974650323391,
            0.003080601105466485,
            -0.0488920658826828,
            0.00017371439025737345,
            -0.0025441700126975775,
            -0.014156701043248177,
            0.009878096170723438,
            -0.013766095042228699,
            0.02121736668050289,
            -0.038004886358976364,
            -0.05192342400550842,
            -0.037710946053266525,
            0.026731308549642563,
            -0.04625214636325836,
            -0.017718002200126648,
            -0.00879136472940445,
            -0.010763254947960377,
            0.0011773076839745045,
            0.027021393179893494,
            0.001369121833704412,
            -0.05948544666171074,
            0.016297869384288788,
            0.02271284908056259,
            0.028427019715309143,
            0.00447183707728982,
            0.009055893868207932,
            -0.007980246096849442,
            -0.015796532854437828,
            -0.013244728557765484,
            0.02095598168671131,
            -0.028250835835933685,
            0.006100472528487444,
            0.03174051642417908,
            0.025625817477703094,
            -0.006725220941007137,
            -0.004525285679847002,
            -0.015239262022078037,
            -0.022916678339242935,
            -0.027829473838210106,
            -0.026049094274640083,
            0.006039752624928951,
            0.007089677732437849,
            -0.025401560589671135,
            0.02381288819015026,
            -0.0018505655461922288,
            0.0028328271582722664,
            -0.0264882892370224,
            0.026694074273109436,
            0.0006280253874137998,
            0.0008781516226008534,
            0.008047104813158512,
            -0.04448527842760086,
            -0.0059120249934494495,
            -0.022548722103238106,
            0.020643455907702446,
            -0.010774741880595684,
            0.006402293685823679,
            -0.0023081630934029818,
            0.0318278931081295,
            0.021016638725996017,
            0.000578597595449537,
            -0.06552403420209885,
            0.02114940620958805,
            0.01921849511563778,
            -0.0036255966406315565,
            -0.0281459279358387,
            0.00983696710318327,
            0.011823389679193497,
            0.018259892240166664,
            0.012119455263018608,
            0.0020652737002819777,
            0.02303505502641201,
            0.001992101315408945,
            0.0077096689492464066,
            -0.010662856511771679,
            -0.007459729444235563,
            0.011253888718783855,
            0.002490050159394741,
            0.0024666334502398968,
            0.020105579867959023,
            -0.024270467460155487,
            0.02997877262532711,
            -0.01410597376525402,
            -0.012265280820429325,
            0.005026801954954863,
            0.005978097207844257,
            -0.028162837028503418,
            0.0236858818680048,
            -8.013963815756142e-05,
            -0.025534365326166153,
            0.01630363240838051,
            -0.029978280887007713,
            -0.03108157031238079,
            0.021258627995848656,
            -0.02091829851269722,
            0.028753088787198067,
            -0.012065884657204151,
            -0.05204182118177414,
            -0.011942589655518532,
            -0.023529795929789543,
            0.026325833052396774,
            0.02173892967402935,
            0.021134110167622566,
            -0.009096299298107624,
            -0.011506951414048672,
            -0.02123062126338482,
            -0.025775393471121788,
            0.01334513071924448,
            -0.009883713908493519,
            -0.02340787835419178,
            -0.005657191853970289,
            0.01525625865906477,
            0.01748676225543022,
            0.0025547966361045837,
            -0.008868160657584667,
            -0.0014154216041788459,
            -0.0014301020419225097,
            0.029107794165611267,
            -0.06184769421815872,
            0.031217848882079124,
            0.006038416642695665,
            0.0022461595945060253,
            -0.0005234607378952205,
            -0.009220998734235764,
            0.004606819245964289,
            -0.017403146252036095,
            -0.010098201222717762,
            -0.0043892585672438145,
            0.00725615443661809,
            -0.020076582208275795,
            0.014772110618650913,
            0.011562065221369267,
            -0.014735431410372257,
            0.000647521810606122,
            0.016108239069581032,
            0.004258294589817524,
            -0.03788493201136589,
            -0.02596416138112545,
            -0.030738843604922295,
            0.019373487681150436,
            0.025458697229623795,
            0.0012650657445192337,
            -0.021323880180716515,
            0.02689019776880741,
            -0.03414059802889824,
            0.005549300462007523,
            -0.012749416753649712,
            0.018286069855093956,
            0.018994932994246483,
            -0.0015871698269620538,
            -0.010573510080575943,
            0.0027145883068442345,
            -0.011174410581588745,
            0.01265974622219801,
            0.025375474244356155,
            -0.021657783538103104,
            0.01400817558169365,
            -0.019358113408088684,
            -0.0022221810650080442,
            -0.015682147815823555,
            -0.015579507686197758,
            -0.02876277081668377,
            -0.019078746438026428,
            0.005891726352274418,
            0.01640128344297409,
            -0.017384132370352745,
            -0.02689988911151886,
            0.006332035176455975,
            0.007582623511552811,
            -0.005643639247864485,
            0.024366259574890137,
            -0.03159506618976593,
            0.027262374758720398,
            -0.04073271527886391,
            -0.003627276746556163,
            0.03607805818319321,
            0.017206229269504547,
            -0.03597612679004669,
            -0.02998623065650463,
            0.00333421491086483,
            -0.017694927752017975,
            0.019113514572381973,
            -0.0013353986432775855,
            0.03127275034785271,
            -0.009589012712240219,
            0.01792927272617817,
            0.006299858447164297,
            -0.0021423487924039364,
            -0.006491011008620262,
            0.028228430077433586,
            0.030968431383371353,
            -0.014855572953820229,
            -0.015030167996883392,
            0.031593672931194305,
            -0.005807923618704081,
            -0.005414854735136032,
            0.02216433361172676,
            0.009651821106672287,
            -0.01690235733985901,
            0.012802256271243095,
            -0.03291727229952812,
            -0.017954304814338684,
            -0.025702839717268944,
            0.005778591148555279,
            0.007829805836081505,
            -0.03105265274643898,
            0.005818441044539213,
            -0.011284485459327698,
            0.014475603587925434,
            0.02254837565124035,
            -0.005131109151989222,
            -0.0003807663742918521,
            0.024467092007398605,
            0.02985537238419056,
            -0.028369812294840813,
            0.009160768240690231,
            0.029910104349255562,
            0.006936820689588785,
            -0.03676715865731239,
            0.00288891582749784,
            0.009028101339936256,
            0.004335072357207537,
            0.005181074142456055,
            -0.022999729961156845,
            0.0032353077549487352,
            -0.0023140921257436275,
            0.02878633327782154,
            -0.049319788813591,
            -0.008812148123979568,
            0.011333205737173557,
            0.025292811915278435,
            -0.002593098906800151,
            0.01259103137999773,
            0.002691559260711074,
            -0.030421806499361992,
            0.0116356760263443,
            -0.0023108937311917543,
            -0.003744184970855713,
            -0.02655915357172489,
            -0.023265689611434937,
            0.0011482903501018882,
            -0.020689914003014565,
            -0.03875681385397911,
            0.036774907261133194,
            -0.06839830428361893,
            0.02228606678545475,
            0.03945296257734299,
            0.027731744572520256,
            0.01795637235045433,
            -0.0033492648508399725,
            -0.0009617492905817926,
            0.030266905203461647,
            -0.010992767289280891,
            0.007634107489138842,
            0.018023502081632614,
            0.027412081137299538,
            -0.01578208990395069,
            -0.002836346160620451,
            -0.010607276111841202,
            0.00998874194920063,
            -0.003789159469306469,
            -0.003613824024796486,
            -0.011306967586278915,
            -0.0042994460090994835,
            -0.03188633173704147,
            0.02138974517583847,
            0.017585389316082,
            0.006088723428547382,
            -0.007954937405884266,
            0.022643394768238068,
            0.039016563445329666,
            -0.019273357465863228,
            -0.03808710724115372,
            0.027378803119063377,
            -0.046090204268693924,
            0.026456331834197044,
            -0.004117682110518217,
            -0.016180284321308136,
            0.011594950221478939,
            -0.01860850676894188,
            0.0038672343362122774,
            -0.041614972054958344,
            0.0025116046890616417,
            -0.0003576251328922808,
            0.02178763970732689,
            -0.01903703808784485,
            -0.021431636065244675,
            0.006808681413531303,
            -0.015659432858228683,
            0.01190216839313507,
            -0.009667322039604187,
            -0.015135182067751884,
            -0.01692507043480873,
            0.018192358314990997,
            0.027209104970097542,
            0.03152146562933922,
            0.01779165491461754,
            0.030306320637464523,
            0.008221576921641827,
            0.0022863559424877167,
            0.01482383906841278,
            -0.0344947874546051,
            -0.016320127993822098,
            -0.007766380440443754,
            -0.01848440244793892,
            0.03199790418148041,
            0.002812969498336315,
            -0.009848115965723991,
            -0.001147391041740775,
            -0.03286602720618248,
            0.03152764216065407,
            0.026634875684976578,
            -0.024744372814893723,
            -0.009431838989257812,
            -0.022052615880966187,
            0.027585214003920555,
            0.026320580393075943,
            -0.04311123490333557,
            0.04228157550096512,
            -0.05254913866519928,
            0.02112056128680706,
            0.027834292501211166,
            0.030849426984786987,
            -0.006554755382239819,
            -0.023738374933600426,
            -0.013991833664476871,
            0.036522276699543,
            -0.0035222384613007307,
            0.018418574705719948,
            0.003933496307581663,
            0.046044278889894485,
            0.02112979255616665,
            -0.034239761531353,
            -0.020495954900979996,
            0.003710757475346327,
            0.02030380815267563,
            0.025040404871106148,
            -0.017729375511407852,
            -0.034054048359394073,
            -0.008786719292402267,
            0.0012716000201180577,
            -0.00416941661387682,
            -0.0160836111754179,
            0.03714141249656677,
            0.020916268229484558,
            0.0059481956996023655,
            -0.016564328223466873,
            -0.030963296070694923,
            0.018053578212857246,
            0.01262104231864214,
            0.008594930171966553,
            -0.011180007830262184,
            -0.025077644735574722,
            0.03573554381728172,
            -0.02583809569478035,
            -0.00025647913571447134,
            -0.016052812337875366,
            0.021122904494404793,
            0.011820127256214619,
            -0.024729959666728973,
            0.0027643274515867233,
            0.009742112830281258,
            -0.028337616473436356,
            0.023108042776584625,
            0.0026537226513028145,
            0.020521661266684532,
            0.047250837087631226,
            0.02001611702144146,
            -0.01835186965763569,
            -0.030629443004727364,
            0.01733643375337124,
            -0.0020835804753005505,
            -0.02629358135163784,
            -0.038990359753370285,
            0.0041275713592767715,
            -0.039817795157432556,
            -0.049874402582645416,
            -0.05201391503214836,
            -0.016956588253378868,
            -0.03840012475848198,
            -0.013963847421109676,
            0.025969482958316803,
            0.004511609673500061,
            -0.02906578779220581,
            -0.0175302866846323,
            0.02745380625128746,
            -0.008347561582922935,
            -0.033037248998880386,
            -0.021307986229658127,
            0.020684516057372093,
            -0.008543314412236214,
            -0.02073497883975506,
            -0.009652768261730671,
            -0.006270293612033129,
            -0.01858275569975376,
            -0.01003272458910942,
            -0.01572492904961109,
            -0.009372560307383537,
            0.004591857083141804,
            0.014106187969446182,
            0.0015912979142740369,
            0.01796462945640087,
            -0.008053814992308617,
            -0.0017685701604932547,
            -0.012864853255450726,
            0.024696916341781616,
            0.03357014060020447,
            -0.0540684312582016,
            -0.011944432742893696,
            0.014554719440639019,
            0.0059819514863193035,
            0.0252962876111269,
            -0.021869972348213196,
            -0.0017143674194812775,
            0.012401431798934937,
            -0.02786317840218544,
            -0.00422332901507616,
            0.011357495561242104,
            -0.015614038333296776,
            0.016908278688788414,
            -0.003258929355069995,
            -0.011964588426053524,
            0.009474070742726326,
            -0.023071296513080597,
            0.013100942596793175,
            0.007666782941669226,
            -0.02884700708091259,
            0.01337043009698391,
            -0.03288983926177025,
            -0.02413295954465866,
            -0.023629004135727882,
            0.03941274434328079,
            -0.006025027018040419,
            -0.01588580571115017,
            0.0037082007620483637,
            -0.022499972954392433,
            -0.02139197289943695,
            0.019880397245287895,
            0.016664907336235046,
            -0.009589818306267262,
            -0.013548178598284721,
            0.019385160878300667,
            -0.007503530476242304,
            -0.0582524910569191,
            -0.005982464645057917,
            0.010942883789539337,
            0.0068802195601165295,
            -0.0103615066036582,
            -0.011906295083463192,
            -0.009481795132160187,
            -0.05507688596844673,
            -0.010811643674969673,
            -0.0067772455513477325,
            0.00829471554607153,
            0.010087557137012482,
            0.018423669040203094,
            -0.0037181167863309383,
            0.008311266079545021,
            -0.007172597572207451,
            0.007739574648439884,
            -0.009958640672266483,
            -0.014031347818672657,
            0.0012898817658424377,
            -0.0046456013806164265,
            0.011346179991960526,
            0.013103271834552288,
            -0.027189429849386215,
            -0.00046393831144087017,
            0.00874318741261959,
            -0.023027896881103516,
            -0.010504990816116333,
            0.017865383997559547,
            -0.01754351705312729,
            0.01796826533973217,
            -0.026968378573656082,
            -0.021000906825065613,
            -0.01820637471973896,
            0.023046884685754776,
            -0.035607364028692245,
            0.026891181245446205,
            -0.012966692447662354,
            -0.035128895193338394,
            -0.0018668936099857092,
            -0.011316805146634579,
            -0.027992919087409973,
            -6.468735227826983e-05,
            0.004417874850332737,
            -0.03817088156938553,
            0.014056181535124779,
            0.04107608646154404,
            0.01783609949052334,
            0.022071655839681625,
            -0.020527902990579605,
            -0.025037789717316628,
            -0.04360632598400116,
            0.030936025083065033,
            -0.03503052890300751,
            -0.011762616224586964,
            0.01677779294550419,
            0.0006943570915609598,
            0.011978620663285255,
            -0.014230206608772278,
            -0.02269008755683899,
            0.013312075287103653,
            0.0016542895464226604,
            -0.014545472338795662,
            0.01708155870437622,
            0.01212974451482296,
            0.0069351173005998135,
            -0.010526502504944801,
            0.015071436762809753,
            -0.0013299767160788178,
            0.026465551927685738,
            -0.006444438360631466,
            -0.011306360363960266,
            -0.016972731798887253,
            -0.004667861852794886,
            -0.009534924291074276,
            -0.025951478630304337,
            0.026520973071455956,
            -0.004536507651209831,
            0.019200608134269714,
            -0.029756467789411545,
            -0.04232760891318321,
            0.017231430858373642,
            0.028396181762218475,
            0.007620859891176224,
            -0.00955952238291502,
            0.02348078228533268,
            -0.03397740423679352,
            0.0227360762655735,
            0.025698628276586533,
            0.024406801909208298,
            -0.0030377369839698076,
            -0.026323417201638222,
            -0.024897443130612373,
            0.018663931638002396,
            -0.004054357297718525,
            0.014458773657679558,
            -0.015094983391463757,
            0.025500411167740822,
            0.003987322095781565,
            0.0023756062146276236,
            -0.027455342933535576,
            -0.014365258626639843,
            0.00804544985294342,
            0.011538010090589523,
            -0.010831857100129128,
            6.697453500237316e-05,
            -0.004025856498628855,
            -0.009862898848950863,
            -0.006560408975929022,
            0.00523806270211935,
            -0.030012020841240883,
            0.029268210753798485,
            -0.0029803013894706964,
            -0.025791078805923462,
            0.015763448551297188,
            0.024177663028240204,
            -0.001888785744085908,
            -0.018935445696115494,
            0.022894812747836113,
            0.02652163989841938,
            0.019284779205918312,
            0.027451636269688606,
            -0.019445247948169708,
            0.009793887846171856,
            0.016187477856874466,
            -0.05131928622722626,
            -0.00762360543012619,
            -0.009748078882694244,
            -0.018617214635014534,
            -0.008609510026872158,
            -0.029596656560897827,
            -0.00813721027225256,
            0.021617522463202477,
            0.005908586084842682,
            -0.016261011362075806,
            -0.03215211629867554,
            0.004179160110652447,
            0.0066783069632947445,
            -0.010885318741202354,
            -0.02793755941092968,
            -0.008861751295626163,
            -0.012824074365198612,
            0.003421501722186804,
            0.023619942367076874,
            0.012424093671143055,
            0.042548276484012604,
            -0.01828625053167343,
            -0.021579761058092117,
            0.006476183421909809,
            0.010602002963423729,
            0.01833859458565712,
            -0.00867819506675005,
            0.011091949418187141,
            -0.004142258316278458
        ],
        [
            -0.04139718413352966,
            -0.01211788970977068,
            0.019799714908003807,
            -0.008427568711340427,
            0.029798373579978943,
            0.03529258072376251,
            -0.015299760736525059,
            -0.02529752068221569,
            -0.02258402109146118,
            0.02778969332575798,
            -0.043308474123477936,
            -0.00866024661809206,
            0.0061334045603871346,
            -0.008166643790900707,
            0.024237439036369324,
            -0.02954844757914543,
            -0.0022926675155758858,
            -0.011309807188808918,
            0.014885391108691692,
            0.012482868507504463,
            -0.019064761698246002,
            0.010759251192212105,
            -0.006586073897778988,
            0.008449827320873737,
            0.03128845989704132,
            -0.01788867451250553,
            -2.873793891922105e-05,
            -0.016292091459035873,
            0.0034921634942293167,
            -0.008570263162255287,
            -0.015368836000561714,
            -0.011918623000383377,
            -0.002060741651803255,
            0.01012203935533762,
            0.01787971705198288,
            0.023422889411449432,
            0.03390863910317421,
            0.016411537304520607,
            0.005251373164355755,
            -0.018659429624676704,
            -0.020494716241955757,
            0.023250391706824303,
            0.005580849479883909,
            0.020414521917700768,
            -0.013908352702856064,
            0.02076287940144539,
            0.01811213605105877,
            0.008413895033299923,
            0.018923506140708923,
            0.02571544423699379,
            0.02831132337450981,
            -0.0007081750081852078,
            0.03738747909665108,
            -0.005811809096485376,
            -0.011867417022585869,
            0.012889517471194267,
            -0.0007200139807537198,
            -0.013283995911478996,
            0.0263703353703022,
            0.00962964165955782,
            0.010082203894853592,
            -0.025727204978466034,
            -0.006220961920917034,
            -0.019160401076078415,
            0.03554569184780121,
            -0.030961042270064354,
            -0.03494632616639137,
            -0.00636612344533205,
            0.0022189936134964228,
            -0.04027100279927254,
            -0.001703996560536325,
            0.007636807393282652,
            -0.013322879560291767,
            -0.020441312342882156,
            -0.0014483063714578748,
            0.016184061765670776,
            -0.03635185584425926,
            -0.0272870771586895,
            -0.010414429940283298,
            0.026451075449585915,
            0.03156379610300064,
            0.005393746308982372,
            -0.020027432590723038,
            0.027751430869102478,
            0.030090060085058212,
            0.01618378609418869,
            0.0036723364610224962,
            -0.016128376126289368,
            -0.022262949496507645,
            0.01065143384039402,
            0.02552666887640953,
            -0.03557810187339783,
            0.02105783298611641,
            -0.003959238529205322,
            -0.009781600907444954,
            -0.0089680515229702,
            -0.019506273791193962,
            -0.020755570381879807,
            0.02528986893594265,
            0.005501693580299616,
            -0.0192679762840271,
            -0.01366347260773182,
            -0.003689100034534931,
            -7.841055776225403e-05,
            0.0380004458129406,
            0.0037639010697603226,
            0.021624157205224037,
            -0.00664996774867177,
            0.03991049528121948,
            0.034877367317676544,
            0.0034427314531058073,
            -0.0012090129312127829,
            -0.013052081689238548,
            0.01455770805478096,
            -0.01645137183368206,
            0.026099536567926407,
            0.016670415177941322,
            -0.005946806166321039,
            0.02122056856751442,
            -0.023065054789185524,
            0.01513308472931385,
            -0.0038745799101889133,
            -0.052281454205513,
            0.023455113172531128,
            -0.027865083888173103,
            -0.024028457701206207,
            0.01103695947676897,
            -0.01339789666235447,
            0.008476252667605877,
            -0.005639927461743355,
            0.01623714715242386,
            0.01328916847705841,
            -0.012010167352855206,
            -0.022107329219579697,
            -0.0256368275731802,
            0.03198808804154396,
            -0.010744983330368996,
            0.021703604608774185,
            -0.013508306816220284,
            0.004763696808367968,
            -0.013680050149559975,
            -0.00795612670481205,
            0.017683185636997223,
            0.0035314687993377447,
            0.030055586248636246,
            -0.0010839843889698386,
            0.024822572246193886,
            -0.026609474793076515,
            0.005023885518312454,
            0.015305474400520325,
            0.01561784092336893,
            -0.01711542345583439,
            -0.016379939392209053,
            -0.007027496118098497,
            -0.031150365248322487,
            0.030748674646019936,
            -0.014435341581702232,
            -0.03284997120499611,
            -0.0069153811782598495,
            0.04002472758293152,
            0.0035748821683228016,
            -0.005117143504321575,
            -0.044930048286914825,
            -0.025484446436166763,
            -0.014711655676364899,
            -0.05336012318730354,
            0.0034071223344653845,
            -0.008731232956051826,
            -0.02851557917892933,
            0.02491629868745804,
            -0.0005324803059920669,
            0.02141217142343521,
            0.025731747969985008,
            0.03980755805969238,
            0.02576260268688202,
            0.027886196970939636,
            0.004219281021505594,
            -0.016456620767712593,
            -0.034533992409706116,
            0.00930491741746664,
            -0.020788947120308876,
            -0.0009585502557456493,
            0.00456381356343627,
            -0.022445350885391235,
            0.01770811527967453,
            0.025460755452513695,
            -0.025263654068112373,
            -0.03183138370513916,
            -0.01865953952074051,
            0.0024930969811975956,
            -0.012619085609912872,
            -0.02542773447930813,
            -0.03857637569308281,
            -0.013026939705014229,
            -0.014702740125358105,
            0.024790575727820396,
            -0.017975788563489914,
            0.020831948146224022,
            0.019934656098484993,
            -0.022906431928277016,
            0.028050359338521957,
            -0.011519350111484528,
            -0.026028499007225037,
            0.030847040936350822,
            0.020991476252675056,
            -0.011201081797480583,
            0.006890717428177595,
            -0.004419801756739616,
            -0.032418251037597656,
            0.01282681804150343,
            0.02568402700126171,
            -0.01131465658545494,
            0.038349613547325134,
            0.0016284502344205976,
            0.00013890127593185753,
            -0.003217733697965741,
            -0.03532526269555092,
            0.005800244864076376,
            -0.005771530792117119,
            -0.0015060630394145846,
            -0.011687892489135265,
            0.029935821890830994,
            0.022923866286873817,
            -0.0027561380993574858,
            0.009322511032223701,
            -0.052876368165016174,
            -0.011027991771697998,
            -0.005995717830955982,
            0.0006855612737126648,
            -0.020262690261006355,
            0.009717469103634357,
            0.029018301516771317,
            0.02123272605240345,
            -0.009771769866347313,
            0.01955389976501465,
            0.029946625232696533,
            -0.01703045517206192,
            0.018523650243878365,
            -0.0018310268642380834,
            -0.030795816332101822,
            -0.02051701582968235,
            -0.01514919102191925,
            0.021735262125730515,
            0.022457854822278023,
            0.029553942382335663,
            0.0242314375936985,
            0.019800754263997078,
            0.03071833774447441,
            -0.020021764561533928,
            0.004475393798202276,
            -0.011249836534261703,
            0.017841311171650887,
            -0.014674696139991283,
            0.031135061755776405,
            -0.015088291838765144,
            0.015029458329081535,
            0.0037655362393707037,
            0.024306705221533775,
            -0.015157441608607769,
            -0.009158977307379246,
            0.008330687880516052,
            -0.0034227524884045124,
            -0.008587823249399662,
            -0.03562495857477188,
            -0.007897832430899143,
            -0.0411791130900383,
            -0.01453386154025793,
            0.009148671291768551,
            0.005064839497208595,
            0.01500773150473833,
            -0.01668524742126465,
            -0.03381451219320297,
            0.007147994823753834,
            0.027895858511328697,
            0.011837668716907501,
            0.027231881394982338,
            -0.019789252430200577,
            -0.015848523005843163,
            0.02968783862888813,
            0.00856813509017229,
            0.027915993705391884,
            -0.0046720970422029495,
            -0.00920313410460949,
            0.016835590824484825,
            0.0282436590641737,
            -0.010708587244153023,
            0.014949641190469265,
            0.008549245074391365,
            -0.006567999254912138,
            -0.028227226808667183,
            0.013090400025248528,
            -0.007721979171037674,
            -0.016059042885899544,
            -0.029600301757454872,
            -0.0013012583367526531,
            0.012411167845129967,
            -0.009807190857827663,
            -0.016286684200167656,
            0.027761414647102356,
            0.025793926790356636,
            -0.009827326983213425,
            0.011176724918186665,
            0.028053928166627884,
            0.008926049806177616,
            0.01634926162660122,
            0.03247512876987457,
            -0.008187899366021156,
            0.012542937882244587,
            0.008006763644516468,
            0.03180453181266785,
            0.0305524542927742,
            -0.0016698559047654271,
            0.0037998610641807318,
            0.025927133858203888,
            0.02800496108829975,
            -0.011340780183672905,
            -0.0017830339493229985,
            0.01706971973180771,
            -0.0054184687323868275,
            0.014820081181824207,
            -0.012969471514225006,
            -0.020017439499497414,
            -0.01963706500828266,
            -0.005635105539113283,
            -0.013991430401802063,
            -0.0007121920352801681,
            0.023381462320685387,
            -0.0022119174245744944,
            0.027256915345788002,
            -0.008796031586825848,
            -0.030014507472515106,
            -0.010773953050374985,
            0.01455059926956892,
            0.03528673201799393,
            0.026589035987854004,
            -0.010648269206285477,
            -0.0014269249513745308,
            0.025989701971411705,
            -0.02428085170686245,
            0.014789118431508541,
            -0.017054930329322815,
            -0.037542786449193954,
            -0.01299510058015585,
            0.013687673024833202,
            0.021383272483944893,
            0.006553368177264929,
            -0.01418209820985794,
            0.003759859362617135,
            -0.00040612174780108035,
            0.010889485478401184,
            0.04275421053171158,
            -0.01658199355006218,
            -0.023476557806134224,
            -0.01365644857287407,
            -0.009509162046015263,
            0.0063756052404642105,
            0.002941814251244068,
            0.0020645051263272762,
            0.007099834270775318,
            -0.008236628025770187,
            0.009208320640027523,
            0.01614641211926937,
            0.010784433223307133,
            -0.025313518941402435,
            -0.0016934284940361977,
            -0.03187522292137146,
            -0.013672705739736557,
            -0.021551091223955154,
            0.00628929166123271,
            0.019029509276151657,
            -0.008960925973951817,
            0.030839724466204643,
            -0.027220305055379868,
            0.017904330044984818,
            0.0122466329485178,
            0.026976248249411583,
            0.017267398536205292,
            0.004166265018284321,
            0.0009574226569384336,
            -0.03321243077516556,
            0.014580574817955494,
            -0.017903240397572517,
            -0.0018460017163306475,
            -0.0028407026547938585,
            -0.004433535039424896,
            -0.002496130997315049,
            0.004440031945705414,
            0.007498814258724451,
            0.026232250034809113,
            0.004554293118417263,
            -0.03305083513259888,
            0.010456830263137817,
            -0.028730442747473717,
            0.00686971191316843,
            -0.025553632527589798,
            -0.027282938361167908,
            0.027808068320155144,
            -0.0009256303310394287,
            -0.034845344722270966,
            0.036716558039188385,
            0.00254453276284039,
            0.0034097337629646063,
            -0.01393083855509758,
            0.01999511942267418,
            -0.0035908077843487263,
            0.005941031035035849,
            0.0365433543920517,
            0.0013002358609810472,
            0.017448076978325844,
            0.014752495102584362,
            -0.018670786172151566,
            0.016604209318757057,
            0.02112586796283722,
            0.0028951973654329777,
            0.033643752336502075,
            0.004951221402734518,
            0.007961566559970379,
            -0.010257680900394917,
            0.028742071241140366,
            -0.02751418948173523,
            -0.017228852957487106,
            0.02664567530155182,
            0.03262324631214142,
            0.02018028125166893,
            -0.018427696079015732,
            0.023349694907665253,
            0.010109798051416874,
            0.008387977257370949,
            0.023449406027793884,
            -0.0046508656814694405,
            -0.007908676750957966,
            0.014794641174376011,
            0.01026717759668827,
            -0.019518226385116577,
            -0.00677984394133091,
            0.020898863673210144,
            0.00860174186527729,
            -0.012679699808359146,
            -0.01074758730828762,
            -0.018503986299037933,
            0.037000104784965515,
            -0.010085932910442352,
            -0.01862545870244503,
            0.008742760866880417,
            -0.0003330166800878942,
            -0.01998206041753292,
            -0.003039023606106639,
            -0.011767556890845299,
            0.04959232360124588,
            0.002724044257774949,
            -0.015236327424645424,
            0.016831200569868088,
            0.03274575248360634,
            -0.01600615121424198,
            0.006341948173940182,
            -0.0029766487423330545,
            0.0007561214151792228,
            -0.01570834405720234,
            -0.005526803433895111,
            -0.028088178485631943,
            0.0017327165696769953,
            -0.031796250492334366,
            -0.0304934773594141,
            -0.0027735659386962652,
            0.02204541675746441,
            0.0017559946281835437,
            -0.04395933821797371,
            0.006752028129994869,
            0.029647057875990868,
            0.01648944430053234,
            -0.012955577112734318,
            0.019095802679657936,
            0.029307110235095024,
            0.02965671196579933,
            0.030559439212083817,
            -0.04055958241224289,
            -0.02277829870581627,
            -0.0021211067214608192,
            0.021739019080996513,
            -0.00633451109752059,
            -0.015674058347940445,
            0.0322568379342556,
            -0.04861833527684212,
            -0.00146016594953835,
            -0.025548990815877914,
            -0.01175281498581171,
            0.024961957708001137,
            0.01410607248544693,
            -0.08286771178245544,
            -0.03437885269522667,
            0.022085146978497505,
            -0.02615237422287464,
            0.0039099897257983685,
            0.01618156023323536,
            -0.030322058126330376,
            0.031263887882232666,
            0.017471378669142723,
            0.013165717013180256,
            -0.0008837726782076061,
            0.010594826191663742,
            0.028416944667696953,
            0.001717085251584649,
            0.015960078686475754,
            -0.024531902745366096,
            -0.0034560791682451963,
            -0.012102119624614716,
            -0.022199518978595734,
            -0.031370408833026886,
            0.027197865769267082,
            -0.014025503769516945,
            0.007174674887210131,
            -0.035088084638118744,
            0.02997923456132412,
            0.020820776000618935,
            0.004507978446781635,
            0.024549316614866257,
            0.024480223655700684,
            0.021596049889922142,
            -0.02093273214995861,
            -0.004437187220901251,
            0.000583506771363318,
            0.004617028869688511,
            -0.005176088772714138,
            0.01506037823855877,
            0.042761631309986115,
            -0.0005133236991241574,
            0.013016480952501297,
            -0.02051389589905739,
            0.035734545439481735,
            -0.003243293147534132,
            0.03182392567396164,
            -0.00808296725153923,
            -0.017481034621596336,
            0.031163007020950317,
            -0.024002647027373314,
            -0.02176242507994175,
            0.015158167108893394,
            -0.015628134831786156,
            -0.02347656525671482,
            0.002391610061749816,
            0.0010717734694480896,
            0.00399415660649538,
            0.02221064455807209,
            0.01854151487350464,
            0.014419646933674812,
            0.008929773233830929,
            -0.01651252619922161,
            -0.04797372594475746,
            0.02382354997098446,
            -0.00020389561541378498,
            -0.02022332325577736,
            0.00360079831443727,
            -0.02504744566977024,
            0.00685030547901988,
            0.014333066530525684,
            -0.013877857476472855,
            -0.028540218248963356,
            -0.028712255880236626,
            0.012281459756195545,
            -0.005298973526805639,
            -0.030072368681430817,
            -0.005234974902123213,
            -0.004109060391783714,
            -0.00931855384260416,
            0.0036176929716020823,
            -0.02495105192065239,
            0.014369117096066475,
            0.016938257962465286,
            0.012809788808226585,
            0.014294392429292202,
            0.005476695019751787,
            0.025789663195610046,
            -0.002257143147289753,
            0.023071516305208206,
            0.00018937961431220174,
            -0.015150726772844791,
            0.012438768520951271,
            -0.026042480021715164,
            -0.021094294264912605,
            0.03163094446063042,
            0.030895031988620758,
            0.020505353808403015,
            0.0007426305674016476,
            -0.02535385824739933,
            0.0263673085719347,
            -0.014043111354112625,
            0.017408834770321846,
            -0.03181639313697815,
            0.011031859554350376,
            0.018935849890112877,
            0.013637403026223183,
            -0.0021864313166588545,
            0.020968301221728325,
            0.027612686157226562,
            -0.02471522055566311,
            -0.021689485758543015,
            0.010108116082847118,
            -0.0233480092138052,
            0.032606787979602814,
            -0.027828743681311607,
            0.021145762875676155,
            -0.015675652772188187,
            0.01618902198970318,
            -0.01531168632209301,
            0.0101321404799819,
            -0.013027983717620373,
            -0.02253328263759613,
            0.01514645665884018,
            -0.03219319507479668,
            -0.011225820519030094,
            -0.0002774230088107288,
            -0.008589210920035839,
            0.008335331454873085,
            -0.012312838807702065,
            -0.015024171210825443,
            -0.00021349832240957767,
            0.0029030528385192156,
            0.02197450026869774,
            0.025738924741744995,
            -0.04307599365711212,
            -0.026257062330842018,
            -0.002704101614654064,
            -0.017346687614917755,
            -0.029265929013490677,
            0.019972028210759163,
            -0.026792209595441818,
            -0.016109898686408997,
            0.016526255756616592,
            -0.033791277557611465,
            -0.014696648344397545,
            -0.0017445903504267335,
            0.027931468561291695,
            -0.020644383504986763,
            0.029020998626947403,
            0.017331447452306747,
            0.02976018376648426,
            -0.017698395997285843,
            0.021084589883685112,
            0.016430135816335678,
            0.0013831107644364238,
            -0.0386057011783123,
            -0.03716448321938515,
            -0.029707176610827446,
            0.040516145527362823,
            -0.02530362643301487,
            0.021550917997956276,
            -0.0037434385158121586,
            -0.017076512798666954,
            0.0029251426458358765,
            -0.010038219392299652,
            0.0389769971370697,
            0.03769492357969284,
            -0.035215020179748535,
            -0.001939068315550685,
            0.002136118011549115,
            0.022500116378068924,
            0.012826341204345226,
            0.02280491590499878,
            -0.015636129304766655,
            -0.007000310812145472,
            -0.05474235862493515,
            0.005303093697875738,
            0.014071201905608177,
            0.007102989591658115,
            0.024518990889191628,
            0.002689241897314787,
            0.02261103317141533,
            0.030956104397773743,
            0.012676027603447437,
            0.007995395921170712,
            0.002354787662625313,
            -0.01712055318057537,
            0.018520385026931763,
            0.009057399816811085,
            0.038556281477212906,
            -0.002190756844356656,
            0.02201058529317379,
            -0.01221343781799078,
            0.02955443412065506,
            -0.02012510970234871,
            -0.008491192013025284,
            0.004297630395740271,
            0.019298648461699486,
            0.02762618660926819,
            0.027631234377622604,
            -2.59575062955264e-05,
            -0.024848697707057,
            0.009426198899745941,
            -0.021190093830227852,
            -0.008624016307294369,
            0.040695782750844955,
            -0.025497928261756897,
            0.011831063777208328,
            -0.009297476150095463,
            0.01502447109669447,
            -0.0335739366710186,
            0.01026986725628376,
            0.022461548447608948,
            0.006911463104188442,
            -0.01178206317126751,
            0.025175713002681732,
            -0.004578611813485622,
            0.03206533193588257,
            -0.023769836872816086,
            0.010622486472129822,
            0.03387495502829552,
            0.00919144693762064,
            -0.006433528382331133,
            0.018629757687449455,
            -0.008746938779950142,
            -0.024535883218050003,
            0.0293216984719038,
            0.016275841742753983,
            -0.014481140300631523,
            0.024555280804634094,
            -3.5519700759323314e-05,
            -0.0537375807762146,
            0.031941890716552734,
            -0.029136812314391136,
            -0.028440356254577637,
            -0.007888184860348701,
            0.02157045528292656,
            -0.005547135137021542,
            -0.020060477778315544,
            0.023886024951934814,
            -0.032290536910295486,
            -0.016613971441984177,
            -0.02505653165280819,
            0.0027434963267296553,
            -0.0043453373946249485,
            0.00386672536842525,
            -0.02192121371626854,
            -0.014517180621623993,
            -0.002194367814809084,
            -0.007284483406692743,
            -0.004098168108612299,
            0.0045388671569526196,
            0.007940399460494518,
            0.016524501144886017,
            2.4658664187882096e-05,
            -0.027733009308576584,
            -0.025649774819612503,
            0.022412855178117752,
            0.002977825002744794,
            -0.002490542596206069,
            -0.0009811141062527895,
            -0.05384291708469391,
            0.02892048843204975,
            0.0056776124984025955,
            0.011841606348752975,
            0.03000549040734768,
            0.013337969779968262,
            -0.028112929314374924,
            0.03631241247057915,
            -0.0040734270587563515,
            -0.023635290563106537,
            -0.0003216041950508952,
            -0.006565024144947529,
            0.010722034610807896,
            0.002235013758763671,
            0.016280539333820343,
            -0.041628606617450714,
            -0.03148862347006798,
            0.0017929055029526353,
            0.013913932256400585,
            0.00927166547626257,
            0.0182652585208416,
            -0.028478119522333145,
            -0.02038731426000595,
            0.018475502729415894,
            0.0338100902736187,
            0.02363157831132412,
            0.017064901068806648,
            0.03265461325645447,
            0.019904693588614464,
            -0.025164229795336723,
            -0.001952607650309801,
            0.00245031644590199,
            -0.0168844573199749,
            0.02107287012040615,
            0.002665542531758547,
            -0.010308081284165382,
            0.019681736826896667,
            0.0074827540665864944,
            -0.0158584825694561,
            -0.010973508469760418,
            0.03301364183425903,
            -0.03801273554563522,
            0.0041630943305790424,
            -0.0005427610594779253,
            0.01354227215051651,
            -0.00891695637255907,
            0.01849023811519146,
            -0.04261591657996178,
            0.024577340111136436,
            -0.017552997916936874,
            -0.008573249913752079,
            -0.010857051238417625,
            -0.024048199877142906,
            -0.005708063952624798,
            0.01298278383910656,
            -0.004945600405335426,
            -0.04993607848882675,
            -0.002877491759136319,
            -0.0334484726190567,
            -0.0018287375569343567,
            0.011311645619571209,
            0.027692345902323723,
            -0.025815028697252274,
            -0.024041011929512024,
            0.024069732055068016,
            0.005394279956817627,
            -0.002211329760029912,
            0.02094174176454544,
            0.0017388567794114351,
            0.018365656957030296,
            -0.003614416578784585,
            -0.0036641396582126617,
            0.009192104451358318,
            0.022869952023029327,
            -0.006010761950165033,
            0.017495635896921158,
            -0.01305715087801218,
            -0.010382642969489098,
            0.031310390681028366,
            -0.022107822820544243,
            -0.012374669313430786,
            -0.030160455033183098,
            -0.013146008364856243,
            0.016247034072875977,
            -0.03185422718524933,
            0.006782456301152706,
            -0.0052932556718587875,
            0.006086716428399086,
            0.02874404937028885,
            -0.00396454893052578,
            0.014963221736252308,
            0.032403718680143356,
            0.01079438254237175,
            -0.0031910196412354708,
            0.0014150169445201755,
            -0.007926194928586483,
            0.005745120346546173,
            -0.019800400361418724,
            0.017005465924739838,
            -0.0194548349827528,
            -0.011173896491527557,
            -0.03256966173648834,
            -0.03585520386695862,
            -0.021860092878341675,
            -0.013159561902284622,
            0.01711721159517765,
            0.021171364933252335,
            0.008143875747919083,
            0.013485084287822247,
            0.017022516578435898,
            0.0037129782140254974,
            0.006908519193530083,
            0.031180325895547867,
            0.01854524202644825,
            -0.0012944421032443643,
            -0.02789725922048092,
            0.051994554698467255,
            0.01837345026433468,
            0.008397884666919708,
            -0.012925214134156704,
            -0.003379551460966468,
            -0.026685738936066628,
            0.005870843306183815,
            0.020446334034204483,
            0.028652017936110497,
            -0.0035027964040637016,
            -0.015640920028090477,
            -0.018173418939113617,
            -0.011515352874994278,
            -0.02442503161728382,
            -0.009350014850497246,
            0.017638646066188812,
            0.03542209044098854,
            0.029429692775011063,
            0.0011374015593901277,
            0.007248722016811371,
            -0.018494704738259315,
            0.018903657793998718,
            -0.003654984524473548,
            -0.0011679762974381447,
            0.000546297465916723,
            -0.0037137314211577177,
            0.0256987102329731,
            0.02323141135275364,
            0.02246362902224064,
            0.025549152866005898,
            0.03600834682583809,
            -0.009289121255278587,
            0.019200174137949944,
            -0.001980410423129797,
            -0.01498755719512701,
            -0.003843829734250903,
            0.017440950497984886,
            -0.03643092140555382,
            0.026366811245679855,
            -0.01891382597386837,
            -0.02017318829894066,
            0.00857242289930582,
            0.03430606424808502,
            -0.02173263020813465,
            -0.03290082886815071,
            0.025173719972372055,
            -0.01452647801488638,
            -0.020715083926916122,
            0.02286691963672638,
            -0.011494279839098454,
            -0.010780726559460163,
            -0.045069411396980286,
            -0.01051667146384716,
            0.0009718525689095259,
            0.008197680115699768,
            -0.027736054733395576,
            0.02156762033700943,
            0.007708413526415825,
            -0.014362748712301254,
            0.012584703974425793,
            -0.01838867925107479,
            -0.013750404119491577,
            0.018536152318120003,
            0.029429972171783447,
            0.01331248413771391,
            -0.013826464302837849,
            0.01744774356484413,
            0.005312891211360693,
            -0.00504732271656394,
            -0.014353310689330101,
            -0.009525052271783352,
            0.015104793943464756,
            0.03409712761640549,
            -0.03851138427853584,
            0.030022835358977318,
            0.0008783862576819956,
            0.006715027149766684,
            -0.0010111868614330888,
            -0.036296483129262924,
            0.010893015190958977,
            0.029625624418258667,
            0.018440868705511093,
            -0.01754862628877163,
            0.006369882728904486,
            0.020602110773324966,
            0.04929160326719284,
            -0.02098124474287033,
            -0.013547118753194809,
            -0.005704390816390514,
            0.01571515202522278,
            -0.014175708405673504,
            -0.030352085828781128,
            0.008690965361893177,
            0.003420548513531685,
            -0.014121807180345058,
            0.03379673883318901,
            0.0046653603203594685,
            -0.032819125801324844,
            0.003663464216515422,
            -0.009370780549943447,
            0.030997829511761665,
            0.012544697150588036,
            -0.030908364802598953,
            -0.0015494374092668295,
            0.009951547719538212,
            0.023482348769903183,
            0.01040623988956213,
            0.01442995760589838,
            0.004500518087297678,
            -0.002492576139047742,
            -0.02648053877055645,
            0.029186010360717773,
            0.020929737016558647,
            0.023935358971357346,
            -0.03261876106262207,
            -0.010439731180667877,
            0.001913530519232154,
            0.03242999315261841,
            -0.045372962951660156,
            -0.017119275406003,
            0.05449456721544266,
            0.01187010109424591,
            -0.006814489606767893,
            0.012943546287715435,
            0.0222293883562088,
            -0.009798421524465084,
            0.028002383187413216,
            -0.007997128181159496,
            0.01705223135650158,
            0.006129261571913958,
            -0.012783809565007687,
            -0.012884923256933689,
            0.015591990202665329,
            0.026244400069117546,
            -0.02056024968624115,
            -0.030468279495835304,
            0.03465777635574341,
            0.009755712002515793,
            -0.003051909152418375,
            0.022735068574547768,
            -0.012564427219331264,
            -0.012346713803708553,
            0.029399164021015167,
            0.016076790168881416,
            0.005462288856506348,
            0.0075652399100363255,
            -0.024622568860650063,
            -0.03243863955140114,
            0.02078549936413765,
            -0.014012281782925129,
            -0.008100221864879131,
            0.017564482986927032,
            -0.018372543156147003,
            0.04178384691476822,
            0.011076721362769604,
            -0.03325391188263893,
            -0.007861617021262646,
            -0.027291856706142426,
            -0.00022246103617362678,
            0.01397143118083477,
            0.03268224000930786,
            0.028613796457648277
        ],
        [
            -0.01931137777864933,
            -0.012708543799817562,
            0.01646045222878456,
            0.019359668716788292,
            0.015280557796359062,
            -0.016500862315297127,
            -0.0063165356405079365,
            -0.0434715561568737,
            -0.026868188753724098,
            0.00937512144446373,
            0.025730188935995102,
            0.03363988921046257,
            0.011328686960041523,
            -0.023763425648212433,
            0.02896181307733059,
            -0.040818601846694946,
            0.013732134364545345,
            -0.023041505366563797,
            -0.008500037714838982,
            0.016145100817084312,
            0.00824071653187275,
            0.005158159416168928,
            -0.00504660839214921,
            -0.018242109566926956,
            0.054998692125082016,
            -0.016569502651691437,
            0.011823579668998718,
            -0.010669238865375519,
            -0.008522299118340015,
            0.0017160421703010798,
            -0.017437031492590904,
            0.024740463122725487,
            -0.029787786304950714,
            -0.020461808890104294,
            -0.015022185631096363,
            0.008003446273505688,
            -0.004631678108125925,
            0.02916993573307991,
            0.01715393178164959,
            0.015220889821648598,
            0.012104925699532032,
            -0.012703429907560349,
            -0.031947530806064606,
            0.036692243069410324,
            0.016135627403855324,
            0.015582536347210407,
            -0.015307566151022911,
            0.04596976190805435,
            -0.009200621396303177,
            -0.030577311292290688,
            0.003662779228761792,
            0.0155985988676548,
            -0.02320191264152527,
            0.030708840116858482,
            0.003116945968940854,
            -0.020080050453543663,
            -0.002411419991403818,
            -0.015806283801794052,
            0.005225247237831354,
            0.0065324208699166775,
            0.03250231221318245,
            -0.01824650540947914,
            0.008718627505004406,
            0.03264401853084564,
            0.0022622346878051758,
            0.014674325473606586,
            0.017929067835211754,
            0.012489935383200645,
            0.00956218782812357,
            0.027934961020946503,
            0.0308142751455307,
            0.0007842989289201796,
            -0.009199905209243298,
            -0.015683071687817574,
            -0.00741713959723711,
            -0.014443840831518173,
            0.018903259187936783,
            0.0057035526260733604,
            -0.011273417621850967,
            0.020010171458125114,
            -0.019648753106594086,
            0.02435378171503544,
            -0.0027910652570426464,
            -0.014375650323927402,
            -0.01968996971845627,
            -0.007271593436598778,
            0.03850424289703369,
            -0.0233304426074028,
            0.03224729746580124,
            0.02602297067642212,
            0.02960372157394886,
            -0.012345697730779648,
            0.02453838847577572,
            0.0005128193297423422,
            -0.020954307168722153,
            -0.0010929737472906709,
            0.005787018220871687,
            0.02694186568260193,
            -0.020441658794879913,
            -0.018317997455596924,
            0.012371380813419819,
            0.011076495051383972,
            -0.012643859721720219,
            0.017919430509209633,
            0.0008435235940851271,
            -0.028454575687646866,
            -0.014133649878203869,
            0.009069873951375484,
            0.009083165787160397,
            -0.007331975270062685,
            -0.006504695862531662,
            -0.03354276716709137,
            -0.022183991968631744,
            -0.010526498779654503,
            -0.05454445630311966,
            -0.013594835065305233,
            0.014672481454908848,
            -0.022909097373485565,
            0.039823297411203384,
            0.04020789638161659,
            0.00366944819688797,
            -0.015199120156466961,
            -0.014960057102143764,
            0.03083919920027256,
            0.03150049224495888,
            0.02739405632019043,
            0.0383017361164093,
            -0.024609234184026718,
            0.014312097802758217,
            -0.014818983152508736,
            -0.025429733097553253,
            0.03714574873447418,
            0.01127410028129816,
            0.009644721634685993,
            0.021923793479800224,
            0.0006085987552069128,
            0.00794727448374033,
            -0.014831595122814178,
            -0.029673313722014427,
            0.034559477120637894,
            -0.005102648865431547,
            0.005147850140929222,
            0.037930212914943695,
            -0.008752230554819107,
            0.01825741119682789,
            0.008809936232864857,
            0.024069586768746376,
            -0.013223361223936081,
            0.01765594817698002,
            -0.007700275629758835,
            -0.018001770600676537,
            -0.005987941287457943,
            0.008799638599157333,
            0.02407866343855858,
            -0.0017238750588148832,
            0.005523809231817722,
            0.030097903683781624,
            -0.0037831743247807026,
            0.007521843072026968,
            0.013215790502727032,
            0.022258592769503593,
            0.009677296504378319,
            -0.011670020408928394,
            0.016691526398062706,
            -0.02650243416428566,
            0.049932099878787994,
            0.022509953007102013,
            -0.027284644544124603,
            0.012723738327622414,
            -0.013203756883740425,
            0.001974860206246376,
            -0.003636637469753623,
            -0.009359368123114109,
            -0.03120173141360283,
            0.03070693276822567,
            -0.00066102494020015,
            0.011801370419561863,
            -0.005036840680986643,
            0.009532774798572063,
            0.03938331454992294,
            0.011266497895121574,
            0.0011558409314602613,
            -0.02858860045671463,
            -0.0013900096528232098,
            0.02734939381480217,
            0.01586199551820755,
            -0.0009290272719226778,
            -0.005183457862585783,
            -0.01122293435037136,
            -0.01376105472445488,
            0.04785541817545891,
            -0.010020439513027668,
            -0.03436138853430748,
            -0.024927224963903427,
            0.005155465565621853,
            -0.009836876764893532,
            0.03360007330775261,
            0.01846097595989704,
            0.000438693881733343,
            -0.027606673538684845,
            0.002649222733452916,
            -0.0011935089714825153,
            0.00788041204214096,
            -0.01986696571111679,
            -0.03758419305086136,
            0.003891774220392108,
            0.022063395008444786,
            0.03886713832616806,
            0.023046176880598068,
            -0.02877744287252426,
            0.033517975360155106,
            0.02895365096628666,
            -0.002746506128460169,
            -0.028441959992051125,
            -0.015227282419800758,
            0.023869451135396957,
            -0.027045877650380135,
            -0.027075693011283875,
            0.032518256455659866,
            0.03310759738087654,
            0.016891637817025185,
            -0.011292314156889915,
            -0.030281318351626396,
            -0.014595498330891132,
            0.0026368116959929466,
            -0.020824268460273743,
            -0.032911866903305054,
            0.011995880864560604,
            -0.0016205953434109688,
            0.014973226003348827,
            -0.03210274502635002,
            -0.021298909559845924,
            0.005951013416051865,
            -0.02021794766187668,
            0.030141694471240044,
            0.007081182207912207,
            -0.0036562590394169092,
            0.023672955110669136,
            -0.004662578459829092,
            -0.04005582630634308,
            -0.026625972241163254,
            0.038369257003068924,
            -0.01562388613820076,
            -0.00989970937371254,
            0.008861290290951729,
            0.021901749074459076,
            0.031132793053984642,
            0.0026548656169325113,
            -0.0016476353630423546,
            0.023600829765200615,
            -0.003247351385653019,
            0.026670776307582855,
            0.020977932959794998,
            0.012190517969429493,
            -0.030384758487343788,
            -0.011826497502624989,
            -0.028234055265784264,
            -0.031266871839761734,
            0.006670595612376928,
            -0.026917176321148872,
            -0.007873193360865116,
            0.013508356176316738,
            0.0015282270032912493,
            -0.0010309470817446709,
            0.017179742455482483,
            -0.007689603604376316,
            -0.004848977085202932,
            0.017944198101758957,
            0.03144894912838936,
            -0.023980170488357544,
            -0.009417753666639328,
            0.01845577359199524,
            -0.000871840282343328,
            -0.019149674102663994,
            -0.02568044513463974,
            -0.009123589843511581,
            -0.008707117289304733,
            0.004625424742698669,
            0.011854361742734909,
            -0.02927260287106037,
            0.03670045733451843,
            0.024355512112379074,
            -0.027378734201192856,
            0.012843756005167961,
            0.0017129709012806416,
            -0.006002778187394142,
            0.008696909062564373,
            0.03672037646174431,
            -0.015467125922441483,
            0.025574607774615288,
            -0.029783759266138077,
            -0.011285264045000076,
            0.017174964770674706,
            0.00411106226965785,
            -0.0017092496855184436,
            -0.02062630094587803,
            0.02597939223051071,
            -0.00911882147192955,
            0.03855878487229347,
            0.0009065989288501441,
            -0.018746254965662956,
            0.03962497413158417,
            -0.02094986103475094,
            0.028887629508972168,
            0.0052306014113128185,
            0.030565639957785606,
            0.03590117767453194,
            0.046921104192733765,
            -0.026790006086230278,
            0.04525251314043999,
            0.042722489684820175,
            0.023625094443559647,
            -0.02001439966261387,
            -0.011995919048786163,
            0.004671312868595123,
            0.005735947750508785,
            -0.023588204756379128,
            0.0014118580147624016,
            -0.006737026385962963,
            0.0067115165293216705,
            0.022415565326809883,
            0.0022791423834860325,
            -0.010429205372929573,
            0.020571960136294365,
            0.0012730071321129799,
            0.0026873911265283823,
            0.01584545522928238,
            -0.000162765005370602,
            -0.00799151137471199,
            -0.006299295462667942,
            0.012709032744169235,
            -0.017337419092655182,
            0.038089994341135025,
            -0.017546596005558968,
            -0.01996701769530773,
            -0.01984681747853756,
            0.02334488555788994,
            -0.0015515080885961652,
            -0.01410217210650444,
            0.026607604697346687,
            -0.009765633381903172,
            0.00507352827116847,
            -0.0509672537446022,
            -0.0026806562673300505,
            0.008315817452967167,
            0.030741671100258827,
            0.04122771695256233,
            0.0016755391843616962,
            0.02081628330051899,
            -0.006737225688993931,
            0.02488917112350464,
            0.012357136234641075,
            0.00218749837949872,
            -0.04794398695230484,
            0.0076897479593753815,
            0.011967786587774754,
            0.009202596731483936,
            0.02275904268026352,
            0.010407648049294949,
            -0.009120630100369453,
            0.015594931319355965,
            -0.02393217384815216,
            0.012269658036530018,
            0.02601419761776924,
            0.015556585043668747,
            -0.004682548809796572,
            0.04652666300535202,
            -0.0030755805782973766,
            -0.03421667218208313,
            -0.010891450569033623,
            0.009939709678292274,
            -0.0055322227999567986,
            -0.024422578513622284,
            0.016227779909968376,
            0.011443650349974632,
            0.04158758744597435,
            -0.028882943093776703,
            -0.029702192172408104,
            -0.05790220573544502,
            -0.018832631409168243,
            -0.046135518699884415,
            -0.020880572497844696,
            -0.006948087364435196,
            0.0048721954226493835,
            0.02878783456981182,
            -0.051676277071237564,
            -0.0018103516194969416,
            0.030634790658950806,
            -0.012629231438040733,
            0.006591416895389557,
            0.03194159269332886,
            -0.02290666662156582,
            -0.015245767310261726,
            0.02184971421957016,
            0.060023244470357895,
            -0.010022463276982307,
            -0.01299766544252634,
            0.03151342645287514,
            -0.02970154769718647,
            0.017539294436573982,
            0.016683857887983322,
            -0.006513529922813177,
            0.004830370657145977,
            -0.007291864603757858,
            0.009496373124420643,
            -0.03044211119413376,
            -0.022099079564213753,
            -0.009866372682154179,
            -0.008654186502099037,
            0.010523397475481033,
            -0.0008457793155685067,
            0.019427793100476265,
            -0.027525845915079117,
            -0.012589297257363796,
            0.026753557845950127,
            -0.006938910111784935,
            -0.0012690093135461211,
            -0.06409891694784164,
            0.04267708212137222,
            -0.021639883518218994,
            -0.008451364934444427,
            0.013693831861019135,
            0.02183547429740429,
            0.001692803343757987,
            -0.021901972591876984,
            0.04340853542089462,
            -0.007545325439423323,
            -0.018468013033270836,
            0.026468787342309952,
            0.0031090062111616135,
            0.007081626448780298,
            -0.022838957607746124,
            0.013102095574140549,
            0.026874477043747902,
            0.020274415612220764,
            -0.009143580682575703,
            -0.014767655171453953,
            -0.016461104154586792,
            -0.013437910936772823,
            0.023525167256593704,
            0.01397347915917635,
            0.002290423261001706,
            -0.021981101483106613,
            -0.003587801940739155,
            0.01804461143910885,
            0.002066351706162095,
            -0.03879616782069206,
            -0.07410946488380432,
            -0.009215982630848885,
            -0.0012687679845839739,
            -0.003780762432143092,
            0.014945060014724731,
            0.03385243937373161,
            0.012309622950851917,
            -0.005435390863567591,
            -0.010904551483690739,
            -0.01503688469529152,
            -0.02578156441450119,
            0.019549954682588577,
            0.010325564071536064,
            -0.02833371050655842,
            -0.025849564000964165,
            0.013806777074933052,
            0.06029583886265755,
            0.029291804879903793,
            -0.021628644317388535,
            -0.017161747440695763,
            0.005181309767067432,
            0.015871984884142876,
            0.026281407102942467,
            -0.02828473225235939,
            0.000999476294964552,
            -0.010024470277130604,
            -0.009850523434579372,
            -0.03775141015648842,
            0.015461726114153862,
            0.007598526310175657,
            0.03700451925396919,
            0.015663251280784607,
            -0.010115870274603367,
            -0.0020144039299339056,
            0.022932078689336777,
            0.01783115789294243,
            -0.021929247304797173,
            -0.006506507284939289,
            -0.04719273000955582,
            -0.014754414558410645,
            -0.022516747936606407,
            0.0560574010014534,
            -0.0242470633238554,
            -0.03001677431166172,
            -0.019054381176829338,
            0.01400886569172144,
            0.03080555610358715,
            0.007817612029612064,
            0.04557272419333458,
            0.020918339490890503,
            0.018435435369610786,
            -0.012487889267504215,
            0.0015688898274675012,
            -0.018753454089164734,
            0.006281853187829256,
            -0.015961119905114174,
            0.005837142001837492,
            -0.018314968794584274,
            0.012844298966228962,
            8.977652760222554e-05,
            -0.0023588838521391153,
            0.01996406354010105,
            -0.03319132700562477,
            -0.0014144382439553738,
            0.006127454340457916,
            -0.00019830999372061342,
            0.03904835879802704,
            -0.01052272878587246,
            -0.0012791090412065387,
            0.004772268235683441,
            0.024836206808686256,
            -0.016423964872956276,
            0.002985979663208127,
            -0.024523597210645676,
            0.014894726686179638,
            0.0043910532258450985,
            0.034140802919864655,
            -0.007497847080230713,
            0.020355382934212685,
            0.05237535759806633,
            0.01010163314640522,
            0.024001989513635635,
            -0.02342458814382553,
            -0.007766749244183302,
            -0.01777682639658451,
            0.01694289594888687,
            -0.02866695635020733,
            0.0013409670209512115,
            0.016642050817608833,
            -0.027198662981390953,
            0.01111094281077385,
            -0.010544519871473312,
            0.012341639958322048,
            0.015924429520964622,
            -0.02649921551346779,
            -0.0404135137796402,
            -0.04003092274069786,
            0.01460552029311657,
            0.028197510167956352,
            -0.0071735745295882225,
            -0.021700378507375717,
            0.016234053298830986,
            -0.02701723389327526,
            0.0006492602406069636,
            -0.025816313922405243,
            0.009641269221901894,
            0.023385902866721153,
            0.027003377676010132,
            -0.008448116481304169,
            -0.005158618558198214,
            -0.005470669362694025,
            -0.003820938989520073,
            0.008910143747925758,
            0.01663113757967949,
            0.006120598874986172,
            0.025965070351958275,
            0.014897330664098263,
            -0.007241102866828442,
            0.027094881981611252,
            -0.024857765063643456,
            -0.017464419826865196,
            0.014055711217224598,
            0.008947762660682201,
            -0.008104788139462471,
            -0.007643253542482853,
            -0.01984363980591297,
            -0.007440095767378807,
            0.02110598422586918,
            -0.02146756835281849,
            0.002652575494721532,
            -0.019726192578673363,
            -0.02050236240029335,
            0.0016057080356404185,
            -0.014482170343399048,
            -0.010754511691629887,
            -0.010719667188823223,
            0.006550027057528496,
            -0.01466590166091919,
            0.003342003095895052,
            0.012626806274056435,
            0.016565319150686264,
            0.007426613941788673,
            -0.015286806039512157,
            -0.028640640899538994,
            0.029556578025221825,
            0.02261139079928398,
            -0.01791142113506794,
            -0.00041184216388501227,
            0.028409719467163086,
            -0.018491026014089584,
            0.015053805895149708,
            0.010116190649569035,
            0.001817228621803224,
            0.023183230310678482,
            -0.0048585375770926476,
            0.016491737216711044,
            -0.028569862246513367,
            -0.023097136989235878,
            -0.029612353071570396,
            -0.0294130090624094,
            -0.0305187925696373,
            -0.019628658890724182,
            -0.022131051868200302,
            -0.019086724147200584,
            -0.030423706397414207,
            0.009793242439627647,
            0.02859598957002163,
            -0.03803368657827377,
            -0.013204608112573624,
            0.0315885916352272,
            -0.01655304804444313,
            -0.026517948135733604,
            -0.01854889467358589,
            0.004309619776904583,
            0.0402306392788887,
            -0.024843953549861908,
            -0.03262423723936081,
            -0.008332906290888786,
            -0.019011612981557846,
            0.01670275814831257,
            0.0076226601377129555,
            0.022661102935671806,
            -0.025787105783820152,
            0.010764379985630512,
            -0.0038648329209536314,
            -0.03118198737502098,
            -0.024960413575172424,
            0.02658730000257492,
            0.036453329026699066,
            0.01182190328836441,
            -0.019828785210847855,
            -0.014861172996461391,
            -0.027212653309106827,
            -0.002052891766652465,
            0.022332623600959778,
            -0.011589929461479187,
            0.004977816715836525,
            -0.013605272397398949,
            0.017110949382185936,
            -0.008975566364824772,
            -0.0033737521152943373,
            -0.017290903255343437,
            -0.005381948314607143,
            -0.023839328438043594,
            -0.0013443854404613376,
            0.006420927122235298,
            -0.029154997318983078,
            -0.009510362520813942,
            0.025062410160899162,
            0.039146989583969116,
            0.01634586788713932,
            0.02547765150666237,
            -0.02314891666173935,
            0.0071488856337964535,
            -0.017805663868784904,
            -0.021154092624783516,
            -0.010903787799179554,
            -0.012890507467091084,
            0.009995629079639912,
            -0.0050722165033221245,
            -0.01761435531079769,
            -0.022050026804208755,
            -0.006760930176824331,
            -0.015876593068242073,
            -0.02865944430232048,
            -0.030164049938321114,
            0.0036749858409166336,
            -0.003666357370093465,
            -0.025402216240763664,
            0.018107851967215538,
            -0.024236373603343964,
            -0.015735968947410583,
            0.02160245180130005,
            -0.0018713411409407854,
            -0.007063151802867651,
            -0.009754529222846031,
            0.023269375786185265,
            0.0086779510602355,
            0.009618847630918026,
            -0.03139149770140648,
            -0.013006007298827171,
            0.008887620642781258,
            -0.020617030560970306,
            -0.008186816237866879,
            -0.01152587030082941,
            0.0016483943909406662,
            -0.008001049980521202,
            -0.032264310866594315,
            -0.013397480361163616,
            -0.022098856046795845,
            -0.016044864431023598,
            -0.031373776495456696,
            -0.02387867122888565,
            -0.033484071493148804,
            -0.018273228779435158,
            -0.014945770613849163,
            -0.004130259156227112,
            0.03192645311355591,
            -0.03386764973402023,
            -0.016722826287150383,
            0.014729210175573826,
            0.041548192501068115,
            0.008620452135801315,
            -0.02263573929667473,
            0.009795748628675938,
            0.028916364535689354,
            -0.009954567067325115,
            0.00965410191565752,
            0.029824629426002502,
            -0.04420643672347069,
            0.02313864417374134,
            -0.0329798087477684,
            -0.022709526121616364,
            -0.021400006487965584,
            -0.02012360282242298,
            0.015017891302704811,
            -0.036478567868471146,
            -0.014349437318742275,
            0.025502681732177734,
            0.003972065169364214,
            0.010623070411384106,
            0.003797391429543495,
            -0.021139154210686684,
            -0.019429167732596397,
            0.02039910852909088,
            -0.008742108941078186,
            -0.024407722055912018,
            0.003480109153315425,
            0.018866848200559616,
            -0.009453533217310905,
            0.023935524746775627,
            -0.039733290672302246,
            0.03741356357932091,
            0.037070922553539276,
            0.030410917475819588,
            0.00193178397603333,
            -0.0007571218884550035,
            0.024739393964409828,
            -0.023939304053783417,
            0.10164744406938553,
            -0.009415349923074245,
            0.025674397125840187,
            0.02131296880543232,
            0.024461109191179276,
            -0.005022893194109201,
            0.010967567563056946,
            0.025961175560951233,
            0.01689836010336876,
            0.005240997765213251,
            -0.03000744804739952,
            0.00032479153014719486,
            -0.004860587418079376,
            0.03371967747807503,
            0.013705622404813766,
            -0.014046628028154373,
            -0.018511122092604637,
            -0.017417695373296738,
            0.02943650260567665,
            -0.013887954875826836,
            0.015565893612802029,
            -0.0007471065036952496,
            -0.0036239579785615206,
            0.013458303175866604,
            0.01922345533967018,
            0.024871116504073143,
            0.028898073360323906,
            0.023819297552108765,
            -0.024231282994151115,
            -0.006906713824719191,
            -0.019799113273620605,
            0.02173886075615883,
            -0.004764726385474205,
            0.00765229482203722,
            -0.014408757910132408,
            -0.013410286977887154,
            -0.008041506633162498,
            0.015320376493036747,
            0.017317714169621468,
            0.014361993409693241,
            0.04532375559210777,
            0.016676438972353935,
            0.02642870508134365,
            0.001910149003379047,
            -0.005132721737027168,
            -0.011030560359358788,
            0.01892269216477871,
            -0.0025858450680971146,
            -0.0037547466345131397,
            0.030876677483320236,
            0.014551854692399502,
            0.002335658995434642,
            0.012567305937409401,
            0.01381405908614397,
            0.015172230079770088,
            -0.007511088624596596,
            0.02434312365949154,
            -0.015079965814948082,
            -0.039145953953266144,
            0.012161972001194954,
            -0.021221011877059937,
            -0.015417240560054779,
            0.012716379947960377,
            -0.013310487382113934,
            -0.017785850912332535,
            -0.022978875786066055,
            0.036526408046483994,
            -0.0012107116635888815,
            -0.031232640147209167,
            -0.011842470616102219,
            0.03599372133612633,
            -0.020548854023218155,
            0.02156299352645874,
            -0.0011938546085730195,
            -0.01167657133191824,
            0.014962397515773773,
            0.019162829965353012,
            0.01617186889052391,
            0.03487660363316536,
            0.0334223210811615,
            -0.00890783779323101,
            0.016658935695886612,
            -0.010982234962284565,
            0.003543182974681258,
            0.04130062833428383,
            -0.00027533757383935153,
            0.015463637188076973,
            -0.034432679414749146,
            0.013215417042374611,
            0.028493735939264297,
            0.010947542265057564,
            0.025984439998865128,
            -0.006963679101318121,
            -0.01269568968564272,
            -0.018567083403468132,
            0.008968932554125786,
            -0.026967372745275497,
            0.003690024372190237,
            -0.0048475549556314945,
            0.03000558167695999,
            -0.0029225677717477083,
            0.034951481968164444,
            -0.028582863509655,
            0.0023313374258577824,
            -0.022508129477500916,
            0.015180285088717937,
            0.035247866064310074,
            -0.0025896946899592876,
            0.0025891438126564026,
            0.02056076005101204,
            0.024374106898903847,
            0.015536864288151264,
            0.04347412660717964,
            0.029266949743032455,
            -0.021768253296613693,
            -0.01414134819060564,
            -0.005929592996835709,
            0.03806458041071892,
            -0.00010618305532261729,
            0.021053189411759377,
            -0.017311815172433853,
            0.016069402918219566,
            0.028198229148983955,
            -0.047991953790187836,
            -0.02064749225974083,
            0.0026196411345154047,
            -0.04231303930282593,
            -0.030426690354943275,
            -0.002542011672630906,
            0.019490623846650124,
            -0.017383793368935585,
            0.07106553018093109,
            0.019717389717698097,
            -0.027380581945180893,
            0.005786520428955555,
            0.02803381159901619,
            -0.0004471968568395823,
            0.014730634167790413,
            0.023769831284880638,
            -0.03194893151521683,
            0.014692015945911407,
            -0.005993780680000782,
            0.019959142431616783,
            0.011708091013133526,
            0.04349271208047867,
            0.0031872750259935856,
            0.007667742669582367,
            0.04724806547164917,
            -0.04156668856739998,
            0.027380766347050667,
            0.007831772789359093,
            0.01620253548026085,
            0.02691328525543213,
            0.020865434780716896,
            0.026566870510578156,
            0.019536878913640976,
            -0.019633648917078972,
            -0.03143179789185524,
            0.02507571503520012,
            -0.010296578519046307,
            -0.0016504744999110699,
            0.026352647691965103,
            0.017118293792009354,
            0.005282796919345856,
            0.012147396802902222,
            -0.018220752477645874,
            -0.021145137026906013,
            0.03026554174721241,
            0.02183285728096962,
            0.015542852692306042,
            0.022351596504449844,
            -0.012333747930824757,
            -0.03197730332612991,
            -0.023140978068113327,
            -0.023752832785248756,
            -0.016492420807480812,
            -0.028729155659675598,
            0.018850646913051605,
            -0.05159933120012283,
            -0.005254523362964392,
            -0.027182450518012047,
            -0.008975161239504814,
            -0.029167896136641502,
            -0.023026108741760254,
            0.013389737345278263,
            0.02189839631319046,
            0.02029288187623024,
            0.0008608987554907799,
            0.004822905175387859,
            0.012680672109127045,
            -0.014399643987417221,
            0.022523831576108932,
            -0.03217611834406853,
            -0.02239331416785717,
            0.022751765325665474,
            0.030851811170578003,
            0.025695759803056717,
            0.0038216684479266405,
            0.0113747613504529,
            0.008000839501619339,
            0.025329401716589928,
            -0.0018734760815277696,
            0.0334882028400898,
            0.005802909377962351,
            0.015049690380692482,
            0.010730567388236523,
            0.02499171905219555,
            -0.022848939523100853,
            -0.005148711148649454,
            0.04818786680698395,
            0.0008232967229560018,
            0.01878420263528824,
            -0.004932565148919821,
            -0.004598940722644329,
            -0.031345803290605545,
            0.011800476349890232,
            0.04322749003767967,
            -0.020554648712277412,
            -0.0035953428596258163,
            -0.01219208911061287,
            -0.025166315957903862,
            0.03764290735125542,
            -0.009714278392493725,
            -0.01003892719745636,
            0.009996109642088413,
            0.013092425651848316,
            -0.0014723710482940078,
            0.030734483152627945,
            -0.003476975951343775,
            0.010310003533959389,
            0.013332516886293888,
            -0.0270235538482666,
            0.025730498135089874,
            -0.0070378826931118965,
            -0.05105277895927429,
            -0.03426070883870125,
            -0.01548080611974001,
            -0.015114424750208855,
            -0.0398707389831543,
            -0.0290965735912323,
            0.0011814901372417808,
            -0.00917970109730959,
            0.040501948446035385,
            0.004805016331374645,
            0.008585910312831402,
            0.022543516010046005,
            -0.03489963337779045,
            -0.020285844802856445,
            -0.02919553406536579,
            -0.0038670275826007128,
            0.05902653560042381,
            0.017240263521671295,
            0.024708455428481102,
            0.02200421132147312,
            -0.006382469087839127,
            -0.018640052527189255,
            0.020089931786060333,
            0.01017290074378252,
            0.012269552797079086,
            -0.016320258378982544,
            -0.010689781978726387,
            0.01916850171983242,
            0.03381722420454025,
            -0.02632203884422779,
            0.04216422513127327,
            0.036605242639780045,
            -0.02286583185195923,
            0.004301819019019604,
            0.00884939730167389,
            0.017088308930397034,
            0.03402792662382126,
            -0.018878011032938957,
            -0.0055034710094332695,
            -0.03286363556981087,
            -0.013206027448177338,
            0.023291362449526787,
            -0.005345498211681843,
            -0.014752174727618694
        ],
        [
            -0.002410316141322255,
            -0.003863927209749818,
            -0.02487979270517826,
            -0.007027313578873873,
            -0.020773986354470253,
            0.0055528804659843445,
            -0.025597000494599342,
            -0.0020070597529411316,
            -0.018551697954535484,
            -0.028239382430911064,
            0.02778538130223751,
            -0.016860930249094963,
            0.02753719873726368,
            0.0006997581222094595,
            0.01416909322142601,
            -0.010355689562857151,
            -0.014826502650976181,
            -0.02963140793144703,
            -0.005277761723846197,
            0.023208873346447945,
            0.01073503028601408,
            0.029509712010622025,
            -0.017986811697483063,
            0.004752043168991804,
            0.0030713926535099745,
            -0.026309048756957054,
            -0.01875920780003071,
            -0.017054710537195206,
            0.012958036735653877,
            0.019161637872457504,
            0.014914354309439659,
            -0.029504157602787018,
            0.00704948091879487,
            0.02974388189613819,
            -0.02100772224366665,
            -0.012376398779451847,
            -0.0036800666712224483,
            0.024356340989470482,
            -0.02748320810496807,
            0.026586955413222313,
            -0.02396170049905777,
            -0.009710190817713737,
            -0.022254731506109238,
            -0.009338745847344398,
            0.0031729512847959995,
            0.000617128040175885,
            -0.008176361210644245,
            -0.012489544227719307,
            0.01153100561350584,
            -0.00450896704569459,
            0.014860137365758419,
            0.0028277768287807703,
            -0.015091431327164173,
            0.016695106402039528,
            -0.025494668632745743,
            0.027864713221788406,
            -0.026460515335202217,
            0.028717515990138054,
            -0.005598742049187422,
            -0.018109234049916267,
            -0.029184896498918533,
            -0.005235113203525543,
            -0.01242718193680048,
            -0.00530444597825408,
            -0.03076738491654396,
            0.02469507046043873,
            0.0008393777534365654,
            0.026649748906493187,
            0.028299201279878616,
            0.015285210683941841,
            -0.01242651604115963,
            0.004297817591577768,
            0.002471494721248746,
            0.013291588053107262,
            0.02267548069357872,
            0.0006056443671695888,
            0.024669582024216652,
            0.000947522756177932,
            0.03170048072934151,
            -0.015502833761274815,
            -0.010393385775387287,
            -0.01639280468225479,
            0.010585258714854717,
            -0.01313869096338749,
            -0.006864324677735567,
            -0.026980524882674217,
            -0.021399343386292458,
            0.018190134316682816,
            -0.007635266054421663,
            -0.024501526728272438,
            0.0022724331356585026,
            0.003130509052425623,
            -0.027137601748108864,
            0.006559457629919052,
            -0.015335354022681713,
            -0.025485610589385033,
            0.02638208121061325,
            -0.015026437118649483,
            -0.019494658336043358,
            0.010167302563786507,
            0.02589654177427292,
            -0.0022347355261445045,
            -0.011051308363676071,
            0.023016879335045815,
            0.0051536099053919315,
            0.0014382770750671625,
            0.008711498230695724,
            -0.021935658529400826,
            0.0059887501411139965,
            0.020501758903265,
            -0.010943896137177944,
            0.02612946555018425,
            -0.0021824613213539124,
            -0.013228094205260277,
            0.010596465319395065,
            0.023232994601130486,
            -0.01441904716193676,
            0.010296613909304142,
            0.012928174808621407,
            0.002771663013845682,
            -0.005519466008991003,
            0.024764882400631905,
            0.02893797680735588,
            -0.00982676912099123,
            -0.009945105761289597,
            -0.02107655256986618,
            -0.03149429336190224,
            0.025759587064385414,
            -0.024196241050958633,
            -0.012175492942333221,
            -0.007399966474622488,
            -0.009236549958586693,
            -0.022826720029115677,
            0.018845729529857635,
            0.00027744704857468605,
            -0.002593828132376075,
            0.02354118414223194,
            0.028723299503326416,
            0.0019406512146815658,
            0.016197839751839638,
            0.022135434672236443,
            -0.02689484693109989,
            0.013432293199002743,
            -0.025429794564843178,
            -0.01749589294195175,
            -0.030882947146892548,
            -0.0005527033936232328,
            -0.018994314596056938,
            0.017442641779780388,
            -0.013904538936913013,
            0.017577599734067917,
            -0.01379115879535675,
            0.009821572341024876,
            -0.020350048318505287,
            -0.0017781435744836926,
            -0.014757822267711163,
            -0.028682278469204903,
            0.01244298741221428,
            0.0063068144954741,
            0.019099922850728035,
            0.008397373370826244,
            0.028567558154463768,
            0.02124292403459549,
            -0.0062660290859639645,
            0.0234892126172781,
            -0.0005172208766452968,
            -0.0013622374972328544,
            -0.006108637899160385,
            -0.021850895136594772,
            -0.013881121762096882,
            0.025763189420104027,
            0.0017597589176148176,
            -0.028614165261387825,
            0.018025999888777733,
            -0.0030138615984469652,
            0.010889044031500816,
            0.00589762395247817,
            0.020690757781267166,
            0.020402681082487106,
            -0.03048825077712536,
            0.0016136394115164876,
            -0.005624774843454361,
            0.021106498315930367,
            0.030190613120794296,
            0.02636726386845112,
            0.0007528667338192463,
            -0.0222494974732399,
            -0.026265988126397133,
            0.017763776704669,
            -0.012239763513207436,
            -0.019397597759962082,
            -0.010014318861067295,
            -0.030228937044739723,
            0.023611998185515404,
            0.0139541607350111,
            -0.03010469302535057,
            -0.015311602503061295,
            0.01760108955204487,
            0.0011743739014491439,
            0.0008431508904322982,
            -0.02823931910097599,
            -0.022982800379395485,
            0.02795964665710926,
            -0.005384107585996389,
            0.007544063962996006,
            0.013411483727395535,
            0.024636168032884598,
            0.0015338532393798232,
            -0.010157694108784199,
            -0.016533972695469856,
            0.02503027580678463,
            -0.02617938071489334,
            0.008488702587783337,
            -0.02158438228070736,
            -0.0018797940574586391,
            0.009492590092122555,
            -0.012120344676077366,
            -0.00976976566016674,
            0.009235044941306114,
            0.005650355014950037,
            -0.027116188779473305,
            -0.014713202603161335,
            -0.00452087540179491,
            -0.02267841063439846,
            0.01791277714073658,
            -0.025573281571269035,
            -0.023823468014597893,
            -0.010797729715704918,
            -0.006018721032887697,
            0.024359388276934624,
            0.020436353981494904,
            -0.032233357429504395,
            -0.022802678868174553,
            0.01819230057299137,
            -0.011066201142966747,
            -0.012706761248409748,
            -0.012369826436042786,
            0.023634150624275208,
            -0.010757745243608952,
            0.020951757207512856,
            -0.02794766053557396,
            0.01033436693251133,
            0.0014938212698325515,
            -0.024211004376411438,
            -0.012281608767807484,
            0.0045851306058466434,
            -0.03156097233295441,
            -0.014006254263222218,
            -0.0114199910312891,
            0.023576442152261734,
            0.027149105444550514,
            0.024844195693731308,
            0.027746964246034622,
            -0.014470045454800129,
            0.011315049603581429,
            0.028488418087363243,
            0.014110914431512356,
            6.842870789114386e-05,
            0.006569940131157637,
            -0.0008972297073341906,
            0.015234091319143772,
            0.0005843504914082587,
            -0.005990625359117985,
            0.008564195595681667,
            -0.003970006015151739,
            -0.011060390621423721,
            0.015506615862250328,
            -0.028117800131440163,
            -0.028687288984656334,
            -0.019374283030629158,
            -0.010637947358191013,
            -0.010561899282038212,
            0.0068689798936247826,
            -0.026616854593157768,
            -0.028079085052013397,
            0.02478254958987236,
            0.015969285741448402,
            -0.004058454185724258,
            -0.007839102298021317,
            -0.029170280322432518,
            0.025038788095116615,
            0.028077928349375725,
            0.01004946418106556,
            -0.00162807188462466,
            0.0062597827054560184,
            -0.027819007635116577,
            0.0007614382775500417,
            -0.01891625113785267,
            0.01684386469423771,
            -0.0034120166674256325,
            0.003061967436224222,
            -0.020143697038292885,
            0.027853181585669518,
            0.025207512080669403,
            -0.01638411171734333,
            0.012657655403017998,
            0.010923562571406364,
            0.009309718385338783,
            0.02936611883342266,
            0.007600170094519854,
            0.025155194103717804,
            0.004341914784163237,
            -0.022313281893730164,
            0.02262219227850437,
            -0.016050642356276512,
            -0.015753572806715965,
            -0.009073459543287754,
            -0.030772743746638298,
            -0.02938135899603367,
            -0.013736077584326267,
            0.018685469403862953,
            -0.020984062924981117,
            -0.013571418821811676,
            -0.007553899195045233,
            -0.025756144896149635,
            -0.0047392272390425205,
            -0.008818965405225754,
            -0.016524551436305046,
            -0.006289605516940355,
            0.008360529318451881,
            -0.03153970465064049,
            -0.025944344699382782,
            -0.0099676214158535,
            -0.02838950790464878,
            -0.02968938834965229,
            0.001315964967943728,
            -0.00046430996735580266,
            -0.014646855182945728,
            -0.017667917534708977,
            0.02952437289059162,
            0.02639753371477127,
            0.011234210804104805,
            0.029006613418459892,
            0.024752356112003326,
            0.017403995618224144,
            0.0022803465835750103,
            -0.015701862052083015,
            0.02684101276099682,
            -0.008441543206572533,
            0.01681390590965748,
            -0.014949271455407143,
            -0.0050587840378284454,
            0.014300782233476639,
            0.02858048491179943,
            -0.0047010742127895355,
            0.019734537228941917,
            -0.007198384962975979,
            -0.024043170735239983,
            0.018139028921723366,
            -0.0070108394138514996,
            -0.020790301263332367,
            -0.020205022767186165,
            -0.021927611902356148,
            0.006433762144297361,
            -0.011971547268331051,
            -0.017413366585969925,
            0.01803152821958065,
            0.015029299072921276,
            -0.025075368583202362,
            -0.022809576243162155,
            -0.01136306393891573,
            0.011720141395926476,
            -0.0034297658130526543,
            -0.0216533150523901,
            0.014746949076652527,
            0.014618268236517906,
            0.02225622534751892,
            -0.025722285732626915,
            -0.02750088833272457,
            -0.028191762045025826,
            0.029734689742326736,
            -0.0035169508773833513,
            0.011991553008556366,
            0.012791857123374939,
            -0.025219233706593513,
            0.00890794675797224,
            0.02130625955760479,
            0.02158035710453987,
            0.009451352059841156,
            0.021443888545036316,
            0.029111385345458984,
            0.005928962025791407,
            0.013585517182946205,
            -0.014657733961939812,
            -0.02356056682765484,
            -0.024819914251565933,
            -0.01864096336066723,
            0.024181054905056953,
            0.012503757141530514,
            -0.03003278560936451,
            -0.027556607499718666,
            0.019505275413393974,
            0.01627214625477791,
            -0.010882203467190266,
            -0.024828139692544937,
            0.011534016579389572,
            0.0016754887765273452,
            0.015392783097922802,
            -0.0037612742744386196,
            0.007973939180374146,
            0.004110498353838921,
            0.02697001025080681,
            -0.00726505508646369,
            0.0038805592339485884,
            -0.02348296158015728,
            0.02262280136346817,
            0.02752397209405899,
            -0.026589220389723778,
            0.019744934514164925,
            -0.0021769755985587835,
            -0.025715572759509087,
            0.022463740780949593,
            -0.009402994066476822,
            0.020191045477986336,
            -0.016366735100746155,
            -0.026336390525102615,
            0.010360674932599068,
            0.00961395539343357,
            -0.014855480752885342,
            -0.031625911593437195,
            -0.014874431304633617,
            -0.015687786042690277,
            0.0045324363745749,
            0.010766254737973213,
            -0.022060927003622055,
            0.0061183348298072815,
            0.004938880912959576,
            0.023862499743700027,
            0.027223924174904823,
            -0.017553336918354034,
            -0.004730789922177792,
            -0.00928412564098835,
            -0.011300376616418362,
            -0.012418071739375591,
            0.013447954319417477,
            0.027838435024023056,
            -0.019406171515583992,
            -0.03152606636285782,
            -0.0041544693522155285,
            0.004523853305727243,
            -0.011251380667090416,
            -0.010199376381933689,
            -0.010339106433093548,
            -0.017626000568270683,
            0.011097687296569347,
            -0.004632689990103245,
            -0.02648979239165783,
            0.010714434087276459,
            0.02451176755130291,
            0.0019992052111774683,
            0.020091934129595757,
            -0.010528827086091042,
            -0.025087129324674606,
            -0.03058270923793316,
            0.01473110169172287,
            0.025896219536662102,
            -0.005524957086890936,
            -0.02202695794403553,
            -0.002106206025928259,
            0.005538529716432095,
            0.0004563475667964667,
            -0.004254844505339861,
            -0.017267530784010887,
            -0.014744811691343784,
            -0.01368249673396349,
            -0.023766590282320976,
            -0.03095182590186596,
            -0.009616606868803501,
            0.0120232617482543,
            -0.023382682353258133,
            -0.003127612406387925,
            0.011523726396262646,
            -0.020384380593895912,
            -0.014167950488626957,
            -0.02955140545964241,
            0.029875755310058594,
            -0.03158411756157875,
            -0.011046272702515125,
            -0.0037507254164665937,
            -0.01120728999376297,
            -0.006402334198355675,
            0.025856008753180504,
            -0.0162004753947258,
            -0.008852014318108559,
            -0.022191273048520088,
            0.020930321887135506,
            -0.016297267749905586,
            0.0037695730570703745,
            0.021232394501566887,
            -0.028397291898727417,
            0.022384127601981163,
            0.0022067264653742313,
            0.0013154729967936873,
            0.030906129628419876,
            0.02381422184407711,
            0.021538669243454933,
            0.022904718294739723,
            -0.024580644443631172,
            0.00020772439893335104,
            -0.029414447024464607,
            0.021360190585255623,
            -0.02032831311225891,
            -0.025172248482704163,
            0.012759910896420479,
            -0.018425503745675087,
            -0.006660675164312124,
            0.001040285686030984,
            0.0111131202429533,
            -0.007492342032492161,
            -0.026052404195070267,
            -0.02736126072704792,
            -0.01634279452264309,
            -0.021642956882715225,
            0.002550106029957533,
            0.0195024311542511,
            -0.007346311118453741,
            -0.020128997042775154,
            0.0255604051053524,
            -0.024913907051086426,
            0.014352506957948208,
            -0.015375015325844288,
            0.01651432178914547,
            0.012851377949118614,
            -0.0019093762384727597,
            -0.013383697718381882,
            -0.020277218893170357,
            0.011510573327541351,
            0.02326332964003086,
            0.027015473693609238,
            -0.027710288763046265,
            0.01864754408597946,
            -0.006187913008034229,
            0.0269663967192173,
            -0.029347091913223267,
            0.02953256480395794,
            -0.008580544032156467,
            -0.015346279367804527,
            0.010600168257951736,
            -0.014528372325003147,
            -0.022373637184500694,
            0.015574382618069649,
            -0.00788860209286213,
            -0.024456489831209183,
            0.0002544703602325171,
            0.024047473445534706,
            0.02839217148721218,
            -0.005473696626722813,
            0.02805417589843273,
            0.027207566425204277,
            0.015576857142150402,
            0.006174857262521982,
            0.026938388124108315,
            -0.026752281934022903,
            -0.03135373070836067,
            -0.02176024578511715,
            0.011043124832212925,
            0.017048556357622147,
            0.013318556360900402,
            -0.01231314055621624,
            0.02431134320795536,
            -0.028935402631759644,
            0.025778556242585182,
            0.01090679969638586,
            -0.030529214069247246,
            -0.02451873943209648,
            -0.015481620095670223,
            -0.013763935305178165,
            -0.027847375720739365,
            0.012665603309869766,
            -0.02828686311841011,
            -0.01924554817378521,
            -0.0036927657201886177,
            -0.010513589717447758,
            -0.015476365573704243,
            -0.019996915012598038,
            0.02361416630446911,
            -0.015172810293734074,
            -0.015671323984861374,
            -0.024573802947998047,
            -0.007507152855396271,
            0.0110036451369524,
            -0.0035859467461705208,
            -0.020929595455527306,
            -0.023968104273080826,
            -0.011530938558280468,
            -0.010037087835371494,
            6.565715011674911e-05,
            -0.021304989233613014,
            -0.017962215468287468,
            -0.02174912765622139,
            -0.00526327732950449,
            -0.004687880165874958,
            0.02684750407934189,
            0.009180054068565369,
            -0.0026830672286450863,
            0.02447456493973732,
            0.006461813114583492,
            0.028536982834339142,
            0.01385512761771679,
            -0.004734791349619627,
            -0.0216362364590168,
            0.021326608955860138,
            0.02545706368982792,
            0.006918857339769602,
            0.0253771860152483,
            0.015796516090631485,
            0.01702757179737091,
            0.013688287697732449,
            0.02540029212832451,
            -0.011985880322754383,
            -0.009588554501533508,
            -0.009928634390234947,
            -0.022037873044610023,
            -0.0317772775888443,
            0.02250484935939312,
            -0.0076721906661987305,
            0.007252309937030077,
            0.010972185991704464,
            0.015649529173970222,
            0.0022954968735575676,
            0.009099561721086502,
            -0.01575555093586445,
            0.017631396651268005,
            -0.007950291968882084,
            -0.015830708667635918,
            0.00021591750555671751,
            0.024671273306012154,
            -0.008608353324234486,
            -0.003709831740707159,
            -0.019455187022686005,
            0.026455678045749664,
            -0.027246488258242607,
            0.016489645466208458,
            0.005178963299840689,
            -0.0026316388975828886,
            -0.020332075655460358,
            0.03181011974811554,
            0.02114514261484146,
            -0.028359754011034966,
            -0.02481362782418728,
            0.018147403374314308,
            0.011195302940905094,
            0.025393357500433922,
            0.0039244769141077995,
            -0.029782341793179512,
            -0.02806974947452545,
            -0.006175006274133921,
            0.000826219329610467,
            0.013458686880767345,
            0.023281117901206017,
            0.001245970604941249,
            -0.03171442821621895,
            -0.020292283967137337,
            -0.004603689070791006,
            0.007870463654398918,
            -0.009081622585654259,
            -0.030031923204660416,
            0.020391101017594337,
            0.00019406033970881253,
            -0.024654675275087357,
            0.006221157032996416,
            0.012746619060635567,
            0.0029249025974422693,
            -0.024243056774139404,
            0.004429391119629145,
            0.006424068007618189,
            0.020131705328822136,
            -0.026487313210964203,
            0.018594564869999886,
            0.007155474275350571,
            -0.014379789121448994,
            -0.02401943877339363,
            -0.015557386912405491,
            -0.01929173246026039,
            0.027097726240754128,
            0.025540100410580635,
            -0.0014499437529593706,
            -0.0032903195824474096,
            0.00683767581358552,
            0.02617041952908039,
            0.016461189836263657,
            -0.01655367575585842,
            -0.007875358685851097,
            0.023909978568553925,
            0.023731281980872154,
            0.010005058720707893,
            -0.022288814187049866,
            -0.025210794061422348,
            -0.0133331548422575,
            0.00616190442815423,
            -0.026208167895674706,
            -0.009576668962836266,
            -0.014113561250269413,
            0.01566733606159687,
            0.023779185488820076,
            -0.028346218168735504,
            0.0005736564635299146,
            -0.029152831062674522,
            -0.026423685252666473,
            0.02117304690182209,
            -0.023512324318289757,
            -0.020969264209270477,
            -0.0021195635199546814,
            -0.02623187191784382,
            0.02556966058909893,
            0.00884623359888792,
            0.00415227422490716,
            0.012503433972597122,
            0.000522398971952498,
            0.02793506532907486,
            -0.014476352371275425,
            0.022204039618372917,
            -0.0034744776785373688,
            -0.009153665043413639,
            -0.029336979612708092,
            -0.007539079990237951,
            -0.003639503149315715,
            -0.0012175114825367928,
            -0.029797593131661415,
            -0.007324551697820425,
            0.004564228001981974,
            0.02121456153690815,
            0.017503848299384117,
            -0.0143661517649889,
            0.01836027391254902,
            0.015623974613845348,
            0.004763959441334009,
            -0.018707500770688057,
            -0.01395434606820345,
            -0.025846416130661964,
            0.011249257251620293,
            0.020752329379320145,
            -0.01720241643488407,
            0.010911187157034874,
            -0.0018097618594765663,
            0.007270247675478458,
            -0.01947079412639141,
            -0.010256954468786716,
            0.02946777082979679,
            0.02328408695757389,
            0.020555343478918076,
            -0.008437669835984707,
            -0.007859386503696442,
            -0.029900027438998222,
            0.019941110163927078,
            0.00047225781599991024,
            -0.0174720361828804,
            0.009298599325120449,
            0.00443577254191041,
            -0.011087855324149132,
            -0.03184298053383827,
            0.010102009400725365,
            -0.025912586599588394,
            0.0275874063372612,
            -0.023172078654170036,
            0.0008203606121242046,
            0.016315458342432976,
            0.006186290178447962,
            0.0013342559104785323,
            -0.00920998677611351,
            0.024234585464000702,
            -0.019053485244512558,
            -0.013976332731544971,
            -0.02711222879588604,
            -0.019580023363232613,
            0.019934989511966705,
            0.00769417267292738,
            -0.015188866294920444,
            -0.007930038496851921,
            -0.025331301614642143,
            -0.027994440868496895,
            0.0031378825660794973,
            -0.023260273039340973,
            0.001764081884175539,
            0.00957348383963108,
            0.026566002517938614,
            0.02961781993508339,
            -0.024684660136699677,
            0.016813496127724648,
            0.008480384014546871,
            0.004768766462802887,
            -0.02428877167403698,
            0.021365147083997726,
            -0.03221577778458595,
            -0.03130558505654335,
            0.027774475514888763,
            0.025585617870092392,
            0.02343829534947872,
            0.01578533835709095,
            0.0013456724118441343,
            -0.026407495141029358,
            0.013241705484688282,
            0.027016563341021538,
            -0.0025202278047800064,
            -0.02346767857670784,
            -0.013456256128847599,
            0.0006657051853835583,
            -0.020023634657263756,
            -0.02730209194123745,
            -0.03003234602510929,
            0.028329448774456978,
            -0.023954585194587708,
            -0.005426488816738129,
            0.018892362713813782,
            0.014377669431269169,
            0.01714494824409485,
            0.011634481139481068,
            -0.011745499446988106,
            -0.0013003939529880881,
            -0.015166010707616806,
            -0.0035638073459267616,
            -0.02991172857582569,
            -0.008937409147620201,
            0.01184916403144598,
            -0.011843711137771606,
            0.012734053656458855,
            -0.01613970287144184,
            0.004736954811960459,
            -0.009043443948030472,
            -0.021792009472846985,
            -0.017589706927537918,
            0.0009032535599544644,
            -0.0027388976886868477,
            -0.01797073520720005,
            -0.013992814347147942,
            -0.011268475092947483,
            0.019202368333935738,
            0.02928140200674534,
            -0.006862619426101446,
            0.007137237582355738,
            -0.01729651913046837,
            -0.011974752880632877,
            0.013032051734626293,
            -0.006070658564567566,
            -0.025802113115787506,
            0.004145367536693811,
            0.017424017190933228,
            -0.027857499197125435,
            -0.02962973155081272,
            -0.02985037863254547,
            0.030130689963698387,
            -0.012038205750286579,
            0.0030524858739227057,
            0.0058652376756072044,
            0.023258371278643608,
            -0.01833184063434601,
            -0.006102853454649448,
            -0.02565222978591919,
            0.021955925971269608,
            0.027344247326254845,
            0.0034181722439825535,
            -0.023839538916945457,
            0.0020177613478153944,
            -0.01936754770576954,
            0.0007171689649112523,
            0.018657123669981956,
            0.01606440171599388,
            -0.001454786746762693,
            -0.0031471543479710817,
            -0.02146461419761181,
            0.0013665965525433421,
            -0.005004839040338993,
            -0.0040391357615590096,
            -0.00866928044706583,
            0.011104334145784378,
            0.016550349071621895,
            -0.0018143776105716825,
            0.0253139715641737,
            0.0015826844610273838,
            -0.01993602328002453,
            -0.0035583991557359695,
            -0.022872986271977425,
            0.01747416891157627,
            -0.006907814648002386,
            0.016959523782134056,
            -0.01739685609936714,
            -0.007643618620932102,
            0.0209788978099823,
            -0.02316918782889843,
            0.009075788781046867,
            -0.021997973322868347,
            -0.01150165218859911,
            -0.005251689814031124,
            -0.008994483388960361,
            0.02578815631568432,
            0.0044128550216555595,
            0.02327589876949787,
            -0.024230577051639557,
            -0.031240785494446754,
            -0.014220455661416054,
            -0.021228158846497536,
            0.01256459392607212,
            0.003663430456072092,
            -0.0257896576076746,
            0.001139434752985835,
            -0.020091496407985687,
            0.004441666416823864,
            0.018051251769065857,
            -0.00792078860104084,
            0.025740910321474075,
            0.01717047393321991,
            -0.017122631892561913,
            0.011523026041686535,
            0.0030642536003142595,
            0.017070135101675987,
            0.00980681274086237,
            -0.01768486201763153,
            0.006709727458655834,
            -0.02676720730960369,
            0.0254646148532629,
            -0.01176995225250721,
            -0.025629011914134026,
            -0.019809512421488762,
            0.024996155872941017,
            -0.021768679842352867,
            0.010991748422384262,
            0.013971594162285328,
            0.027919435873627663,
            0.019295552745461464,
            0.0017265885835513473,
            0.02584657445549965,
            -0.021455800160765648,
            0.002588316798210144,
            0.0022683225106447935,
            -0.025932559743523598,
            -0.01834239438176155,
            -0.03098188154399395,
            -0.01635471172630787,
            0.019454771652817726,
            0.003382971975952387,
            0.0004549950535874814,
            -0.0011753900907933712,
            -0.0019527662079781294,
            -0.012591235339641571,
            -0.009241622872650623,
            0.027415411546826363,
            -0.014859968796372414,
            -0.028946883976459503,
            0.0066390736028552055,
            -0.03093814104795456,
            -0.016125457361340523,
            -0.024717090651392937,
            -0.012121712788939476,
            -0.012586782686412334,
            -0.019541744142770767,
            -0.023739704862236977,
            0.008095787838101387,
            -0.027170630171895027,
            -0.028007520362734795,
            0.02247871644794941,
            -0.027322057634592056,
            -0.023867378011345863,
            -0.005024238023906946,
            -0.017351703718304634,
            0.026169298216700554,
            -0.01850593276321888,
            0.012223156169056892,
            0.012995569966733456,
            0.022014401853084564,
            -0.013116422109305859,
            -0.027172597125172615,
            0.01029413565993309,
            -0.0169009268283844,
            -0.02565530128777027,
            -0.012941285967826843,
            -0.0028821954037994146,
            -0.01923518069088459,
            0.020038967952132225,
            -0.02347799390554428,
            -0.01523782778531313,
            -0.030078191310167313,
            -0.03211728483438492,
            -0.016828270629048347,
            0.02365260384976864,
            -0.030054012313485146,
            0.0010361805325374007,
            0.02054963819682598,
            -0.03164972737431526,
            -0.012054193764925003,
            0.004007688257843256,
            0.003465510904788971,
            -0.003982972353696823,
            0.013558119535446167,
            -0.016946466639637947,
            0.021354787051677704,
            -0.00020586387836374342,
            -0.010047833435237408,
            -0.01320501510053873,
            -0.019770054146647453,
            -0.011194286867976189,
            0.017928656190633774,
            0.017926249653100967,
            0.015677552670240402,
            -0.015505400486290455,
            -0.008256246335804462,
            -0.0011412985622882843,
            0.016643593087792397,
            -0.022087862715125084,
            0.00386990699917078,
            -0.012542614713311195,
            0.007893114350736141,
            -0.019558710977435112,
            0.008939697407186031,
            0.02641034685075283,
            0.01857815682888031,
            0.0036375022027641535,
            0.025934381410479546,
            0.026641612872481346,
            -0.003445891197770834,
            -0.0033489700872451067,
            0.028174282982945442,
            0.002030911622568965,
            0.01882237382233143,
            0.01760069467127323,
            -0.008903611451387405,
            -0.023480022326111794,
            0.025716768577694893,
            -0.0150498878210783,
            -0.028874864801764488,
            -0.009046783670783043,
            -0.028592977672815323,
            -0.029472067952156067,
            0.028498820960521698,
            -0.027040544897317886
        ],
        [
            -0.019471503794193268,
            0.023818476125597954,
            0.012023902498185635,
            0.019301045686006546,
            0.011381078511476517,
            0.012032664380967617,
            -0.021091001108288765,
            0.03600342944264412,
            -0.017237529158592224,
            -0.03138972818851471,
            0.01065326202660799,
            0.037688881158828735,
            -0.025435300543904305,
            0.00656795222312212,
            0.03653315082192421,
            0.019889380782842636,
            -0.0032716805581003428,
            -0.01752522774040699,
            -0.01477956585586071,
            0.010141010396182537,
            -0.003278146730735898,
            -0.02115141786634922,
            -0.013815861195325851,
            -0.017961589619517326,
            -0.018839865922927856,
            0.03157353401184082,
            -0.014916068874299526,
            -0.015487111173570156,
            0.0007953052991069853,
            0.004048032686114311,
            -0.020423902198672295,
            0.0239336509257555,
            -0.03164399787783623,
            -0.001036104396916926,
            -0.007047741208225489,
            -0.01697770692408085,
            -0.017135733738541603,
            -0.00654469383880496,
            -0.024899309501051903,
            0.010039456188678741,
            -0.005719800014048815,
            -0.012818158604204655,
            -0.009942573495209217,
            -0.011317344382405281,
            -0.024902280420064926,
            -0.02694959193468094,
            0.002060375642031431,
            0.009467904455959797,
            0.026885030791163445,
            0.005729039665311575,
            0.004182533826678991,
            0.002725448925048113,
            0.029264362528920174,
            0.010213960893452168,
            0.004200588911771774,
            0.007371706422418356,
            0.0016890403348952532,
            -0.009189664386212826,
            0.030524050816893578,
            0.028278395533561707,
            0.01807246170938015,
            0.01899164356291294,
            -0.008039712905883789,
            -0.03274989128112793,
            0.013384570367634296,
            -0.01412937231361866,
            -0.03229070454835892,
            -0.013177399523556232,
            0.019005389884114265,
            -0.018166782334446907,
            -0.02204192243516445,
            -0.017328696325421333,
            0.007447132375091314,
            -0.011804619804024696,
            -0.0009992081904783845,
            -0.03529467061161995,
            -0.025397781282663345,
            -0.028584405779838562,
            -0.01788508892059326,
            0.015858083963394165,
            0.029361065477132797,
            0.01822729967534542,
            0.02236693724989891,
            -0.037134844809770584,
            0.02365642413496971,
            -0.015745989978313446,
            -0.006632552482187748,
            -0.01771596446633339,
            0.034027986228466034,
            0.022266004234552383,
            -0.00021388161985669285,
            -0.008459691889584064,
            -0.012641914188861847,
            0.0003934661508537829,
            -0.020334703847765923,
            0.03304070606827736,
            -0.02605562098324299,
            0.019856197759509087,
            -0.039482273161411285,
            -0.017527228221297264,
            -0.01774056814610958,
            -0.021986445412039757,
            -0.015240214765071869,
            0.01454389002174139,
            -0.009112580679357052,
            -0.023501690477132797,
            -0.007912852801382542,
            0.007158151362091303,
            0.0034012645483016968,
            0.03465172275900841,
            0.03607458993792534,
            9.061246964847669e-05,
            0.007387865334749222,
            0.02475680783390999,
            -0.03437244892120361,
            0.03104669228196144,
            -0.018425166606903076,
            0.016227662563323975,
            -0.006781588774174452,
            -0.031322430819272995,
            -0.036431729793548584,
            -0.0050014592707157135,
            -0.04763149470090866,
            -0.01963094249367714,
            0.005085039883852005,
            -0.024302003905177116,
            -0.004910307005047798,
            -0.023690398782491684,
            0.0142898578196764,
            0.013267502188682556,
            -0.0018829588079825044,
            -0.006857589352875948,
            -0.047606371343135834,
            -0.003118963213637471,
            0.003887392580509186,
            -0.018170667812228203,
            0.010883198119699955,
            -0.03386881202459335,
            -0.0021381082478910685,
            0.010930100455880165,
            -0.00162643869407475,
            -0.0138452323153615,
            -0.030205396935343742,
            0.03096805512905121,
            0.004106482956558466,
            -0.024151155725121498,
            -0.02468680962920189,
            -0.023372264578938484,
            -0.0057063028216362,
            -0.010358819738030434,
            -0.003445965936407447,
            -0.024458155035972595,
            -0.030709553509950638,
            0.03383120521903038,
            0.0239790640771389,
            -0.03638824075460434,
            -0.022408029064536095,
            0.019450200721621513,
            -0.05159543827176094,
            0.04877140372991562,
            -0.011062012054026127,
            -0.010624044574797153,
            0.001831827568821609,
            0.0017404231475666165,
            0.02815002016723156,
            -0.03636504337191582,
            -0.01717706024646759,
            0.01776478812098503,
            -0.011047504842281342,
            0.018749527633190155,
            0.014161679893732071,
            -0.040367331355810165,
            -0.049182768911123276,
            -0.003413434373214841,
            0.02298402227461338,
            0.011761187575757504,
            -0.0339147225022316,
            0.012492700479924679,
            -0.02278837375342846,
            0.0037878176663070917,
            0.02739184908568859,
            0.01594569720327854,
            0.033351343125104904,
            0.036156680434942245,
            0.0103600537404418,
            0.03432384878396988,
            0.03933605179190636,
            0.01295341644436121,
            -0.024316558614373207,
            -0.03206027299165726,
            -0.07699023932218552,
            0.0161531250923872,
            -0.04692944139242172,
            -0.034248292446136475,
            0.0035962124820798635,
            -0.009135374799370766,
            -0.01681697927415371,
            -0.027669209986925125,
            -0.037558261305093765,
            0.029438987374305725,
            -0.029500124976038933,
            -0.004923152271658182,
            -0.025354277342557907,
            0.001545859151519835,
            -0.015520383603870869,
            0.020552504807710648,
            0.011998564936220646,
            0.010668600909411907,
            -0.020348796620965004,
            0.025744983926415443,
            0.02629023604094982,
            -0.01041292306035757,
            -0.04628949239850044,
            0.022397682070732117,
            -0.004179982002824545,
            -0.020078329369425774,
            0.0010871588019654155,
            -0.019855154678225517,
            -0.0306974109262228,
            0.009449874982237816,
            -0.0011160566937178373,
            -0.019747577607631683,
            -0.008639423176646233,
            -0.014314553700387478,
            0.00029510597232729197,
            0.005904093384742737,
            -0.02165013924241066,
            0.035946913063526154,
            0.0021563416812568903,
            -0.0338188074529171,
            0.02196323685348034,
            -0.023780811578035355,
            0.005399757064878941,
            0.028904514387249947,
            0.006718734744936228,
            0.003188745118677616,
            0.014355617575347424,
            -0.005146238021552563,
            0.036700211465358734,
            0.02667553909122944,
            0.00875250156968832,
            -0.003538647200912237,
            0.01813732273876667,
            0.00770066911354661,
            -0.005937077570706606,
            0.013813156634569168,
            -0.03848609700798988,
            -0.028612375259399414,
            -0.009006784297525883,
            -0.024185167625546455,
            -0.013952002860605717,
            0.01286210399121046,
            -0.0064321053214371204,
            -0.032428693026304245,
            -0.001924452488310635,
            0.0004633620847016573,
            0.000416541617596522,
            -0.01682949997484684,
            -0.024455472826957703,
            -0.0050473143346607685,
            -0.005719589069485664,
            0.008343994617462158,
            -0.02384881302714348,
            -0.02780235931277275,
            0.010886378586292267,
            -0.009391481988132,
            0.0002660654718056321,
            0.007340454962104559,
            -0.018372461199760437,
            0.021335914731025696,
            -0.010200994089245796,
            -0.005586276762187481,
            -0.007160325534641743,
            0.020072216168045998,
            0.0022444352507591248,
            0.02048921212553978,
            0.007769686169922352,
            -0.016776487231254578,
            -0.004592503886669874,
            0.01450381614267826,
            -0.0009263909887522459,
            -0.02826187200844288,
            0.01388306450098753,
            -0.004219444468617439,
            0.007713266648352146,
            -0.003500252030789852,
            -0.037491802126169205,
            0.02317272685468197,
            -0.02874494343996048,
            -0.025653274729847908,
            0.03088703565299511,
            0.018994834274053574,
            -0.023580854758620262,
            0.034239914268255234,
            0.016722677275538445,
            -0.021199434995651245,
            0.02177908457815647,
            -0.01332901418209076,
            0.02069326862692833,
            0.018400803208351135,
            0.02529374696314335,
            -0.012388353236019611,
            0.008592559956014156,
            0.0175459161400795,
            0.03200224041938782,
            -0.014073518104851246,
            0.027358917519450188,
            0.003227178007364273,
            -0.02171286754310131,
            0.019205354154109955,
            0.022687425836920738,
            0.01296198833733797,
            0.008855834603309631,
            -0.011360456235706806,
            0.0026513792108744383,
            0.024263139814138412,
            -0.049815792590379715,
            0.013095485046505928,
            -0.031875673681497574,
            -0.025454208254814148,
            0.021486598998308182,
            -0.020383942872285843,
            0.022282246500253677,
            -0.0025765409227460623,
            -0.0229483712464571,
            0.006186607293784618,
            0.03261791542172432,
            -0.02504664473235607,
            -0.0326981395483017,
            0.009230045601725578,
            -0.0016672206111252308,
            -0.010093041695654392,
            0.01491069607436657,
            -0.03562402352690697,
            -0.000983097474090755,
            -0.024443136528134346,
            -0.003497686702758074,
            0.014861809089779854,
            0.021001333370804787,
            0.04275370016694069,
            -0.006981430575251579,
            0.0023605860769748688,
            -0.0033020006958395243,
            0.024479152634739876,
            -0.025522135198116302,
            0.011857002973556519,
            0.024415498599410057,
            -0.023899095132946968,
            0.02062179520726204,
            -0.0021592997945845127,
            0.016255490481853485,
            -0.009752750396728516,
            4.3535110307857394e-05,
            -0.02361259050667286,
            -0.011402424424886703,
            -0.023602180182933807,
            -0.028281621634960175,
            0.011745299212634563,
            0.008906888775527477,
            0.049547627568244934,
            -0.016905413940548897,
            0.008509530685842037,
            0.0045949057675898075,
            -0.010355274192988873,
            -0.033132415264844894,
            -0.006621690467000008,
            0.030777083709836006,
            -0.004473859444260597,
            -0.02519283816218376,
            -0.01933394931256771,
            -0.007660267408937216,
            0.0165773443877697,
            -0.005498765502125025,
            0.024813922122120857,
            0.005646648816764355,
            0.01914857141673565,
            -0.015464027412235737,
            0.01914766989648342,
            0.007750770077109337,
            -0.023722469806671143,
            0.009440800175070763,
            0.016125887632369995,
            -0.010359649546444416,
            -0.008685309439897537,
            -0.03047262690961361,
            -0.00036037154495716095,
            0.021020730957388878,
            0.04178852215409279,
            -0.01808866672217846,
            0.020627763122320175,
            -0.018096385523676872,
            0.023150481283664703,
            0.024950729683041573,
            -0.03811158239841461,
            -0.016354680061340332,
            0.012642587535083294,
            0.013259402476251125,
            -0.03632930666208267,
            0.010971352458000183,
            -0.006795354187488556,
            0.0056039378978312016,
            -0.010551880113780499,
            -0.0002529854536987841,
            -0.02611592784523964,
            0.019280269742012024,
            0.020793752744793892,
            0.016559436917304993,
            0.001248297980055213,
            0.03676386550068855,
            0.0363353006541729,
            0.00535852974280715,
            0.015945961698889732,
            0.015281911939382553,
            -0.016442816704511642,
            0.04256075993180275,
            -0.009882123209536076,
            0.030821658670902252,
            -0.008883886970579624,
            -0.002676180796697736,
            0.013502765446901321,
            0.008689514361321926,
            0.005448387935757637,
            -0.09049061685800552,
            -0.010142117738723755,
            -0.02311777137219906,
            -0.012049844488501549,
            0.00912407971918583,
            -0.007386733312159777,
            0.024553285911679268,
            -0.04068298265337944,
            0.010169938206672668,
            -0.017625434324145317,
            0.02222936972975731,
            0.0228356271982193,
            0.013317416422069073,
            -0.00407114764675498,
            -0.00807398371398449,
            -0.003032912965863943,
            -0.017771737650036812,
            0.003242362989112735,
            0.026536986231803894,
            0.01902943290770054,
            -0.05034075677394867,
            0.004295246209949255,
            0.008036330342292786,
            0.002356778597459197,
            0.027126304805278778,
            -0.055304884910583496,
            0.03412012755870819,
            0.006263671442866325,
            0.019855910912156105,
            0.004281902220100164,
            0.024985745549201965,
            0.02667906880378723,
            0.003074380336329341,
            -0.029210135340690613,
            0.010639352723956108,
            0.016966966912150383,
            0.011115464381873608,
            0.0008784356177784503,
            0.029644975438714027,
            -0.0065352944657206535,
            0.013139070942997932,
            0.01672476902604103,
            -0.029173752292990685,
            -0.018589463084936142,
            -0.043098706752061844,
            -0.04648503288626671,
            0.007046137936413288,
            0.02284781076014042,
            0.029921989887952805,
            0.029499737545847893,
            0.007455151993781328,
            -0.005997735541313887,
            -0.03283008560538292,
            0.009423859417438507,
            -0.00080034852726385,
            0.03678228333592415,
            -0.019088812172412872,
            -0.015516920946538448,
            0.0019625863060355186,
            0.025570927187800407,
            -0.038666218519210815,
            0.03316468000411987,
            0.019498663023114204,
            0.019505904987454414,
            -0.030177772045135498,
            0.009251239709556103,
            0.010761970654129982,
            -0.04797833785414696,
            0.018692422658205032,
            0.006288168951869011,
            0.00531177781522274,
            -0.030142728239297867,
            -0.05371713265776634,
            -0.010990875773131847,
            0.013887107372283936,
            0.021301276981830597,
            0.025510553270578384,
            0.001702816691249609,
            0.03598470240831375,
            -0.0018364570569247007,
            -0.017187336459755898,
            0.04787665605545044,
            -0.03061477653682232,
            -0.026034938171505928,
            0.003656914457678795,
            0.01742335967719555,
            0.017095021903514862,
            -0.011277739889919758,
            -0.02540384791791439,
            0.00413163099437952,
            -0.00917400699108839,
            0.020771553739905357,
            0.02774037979543209,
            0.028317084535956383,
            -0.029082495719194412,
            0.02632199414074421,
            0.038022592663764954,
            0.013969666324555874,
            0.008701536804437637,
            0.0016681273700669408,
            0.025928882881999016,
            0.024394115433096886,
            -0.03154568001627922,
            0.011342700570821762,
            -0.024962518364191055,
            0.002430107444524765,
            0.02356703206896782,
            0.009144908748567104,
            0.014314231462776661,
            0.0058507658541202545,
            -0.024105830118060112,
            0.023836206644773483,
            -0.002027820562943816,
            -0.0065983617678284645,
            -0.00435934541746974,
            -0.02534649521112442,
            -0.035506609827280045,
            0.013767968863248825,
            0.00548295583575964,
            -0.005080851260572672,
            -0.04617450386285782,
            -0.024336375296115875,
            0.012177812866866589,
            0.03438323363661766,
            0.021824944764375687,
            0.014847940765321255,
            0.014681783504784107,
            -0.011664156801998615,
            0.012535246089100838,
            0.031136175617575645,
            -0.01180256251245737,
            0.009944101795554161,
            -0.0235280804336071,
            0.03196766972541809,
            0.020549319684505463,
            -0.025003740563988686,
            0.008619253523647785,
            -0.013362315483391285,
            0.015032351948320866,
            -0.001429643714800477,
            0.018413512036204338,
            0.007998770102858543,
            0.0015330833848565817,
            0.006574596744030714,
            0.01585700735449791,
            0.021705927327275276,
            0.019439581781625748,
            -0.02297479659318924,
            0.006386874709278345,
            0.002150302054360509,
            -0.0028865544591099024,
            -0.007342643104493618,
            -0.01560093741863966,
            -0.032269686460494995,
            -0.0083058075979352,
            0.012108903378248215,
            0.009162284433841705,
            0.005818450357764959,
            -0.02838321216404438,
            0.0027187976520508528,
            -0.024343591183423996,
            0.016790490597486496,
            0.012273886241018772,
            0.009842931292951107,
            0.011376803740859032,
            -0.018606023862957954,
            0.0051943897269666195,
            0.01461348682641983,
            0.02728589065372944,
            -0.019562624394893646,
            -0.01795858144760132,
            -0.004512681160122156,
            -0.0020569523330777884,
            0.013446503318846226,
            -0.011333825066685677,
            -0.02148970030248165,
            0.008415001444518566,
            -0.010002750903367996,
            0.010647241957485676,
            -0.028487026691436768,
            -0.028532275930047035,
            -0.0011927556479349732,
            -0.018648648634552956,
            0.009660349227488041,
            -0.015831252560019493,
            0.02949102595448494,
            -0.04443000629544258,
            0.04338822141289711,
            -0.010405105538666248,
            0.004709125030785799,
            0.036749888211488724,
            0.008717048913240433,
            -0.006532682571560144,
            -0.05431728810071945,
            0.02480592206120491,
            0.012643145397305489,
            0.034675683826208115,
            -0.01819959655404091,
            -0.040017396211624146,
            0.009625157341361046,
            -0.030455276370048523,
            -0.02679562196135521,
            -0.00976576004177332,
            0.0017091699410229921,
            -0.014148371294140816,
            -0.029058538377285004,
            -0.021385157480835915,
            0.024331485852599144,
            0.019036434590816498,
            -0.025087827816605568,
            -0.0010218550451099873,
            -0.021536633372306824,
            -0.017907937988638878,
            -0.027721179649233818,
            0.030504465103149414,
            -0.04623992741107941,
            0.03237636387348175,
            -0.011842473410069942,
            0.00825381651520729,
            -0.04896976426243782,
            0.014552553184330463,
            -0.013059647753834724,
            0.009985888376832008,
            -0.022301867604255676,
            0.021186817437410355,
            0.01538286916911602,
            0.01482186559587717,
            -0.00394976232200861,
            -0.008142543025314808,
            -0.02435695007443428,
            -0.025978095829486847,
            0.013365613296627998,
            -0.010645843110978603,
            0.012178825214505196,
            0.020551471039652824,
            0.02208111435174942,
            0.004420628771185875,
            0.004441831726580858,
            0.005206383764743805,
            -0.041673410683870316,
            -0.006791098043322563,
            -0.024467557668685913,
            -0.007705803494900465,
            -0.004738223273307085,
            0.0013033079449087381,
            0.015024824999272823,
            0.02627519704401493,
            0.0009109321981668472,
            -0.013855098746716976,
            -0.02736814133822918,
            0.015492390841245651,
            -0.003977278247475624,
            0.00912012904882431,
            -0.02084064669907093,
            -0.005591275170445442,
            -0.0006443694583140314,
            0.005052176769822836,
            -0.00937124714255333,
            -0.014664417132735252,
            0.011891436763107777,
            -0.007722616661339998,
            0.02188473381102085,
            0.022289501503109932,
            0.014920844696462154,
            -0.006863619200885296,
            -0.00856938399374485,
            0.0003697072097565979,
            0.01742393709719181,
            -0.025824902579188347,
            -0.02473784238100052,
            -0.014885787852108479,
            -0.021699510514736176,
            -0.013260939158499241,
            -0.018671110272407532,
            0.015906469896435738,
            0.030755665153265,
            0.014814657159149647,
            0.009796853177249432,
            0.026797126978635788,
            0.04121418297290802,
            -0.009531237185001373,
            0.019667385146021843,
            -0.010938234627246857,
            0.004029689822345972,
            -0.016221461817622185,
            0.030243270099163055,
            0.007556629832834005,
            0.006658927071839571,
            0.03352387249469757,
            0.011028212495148182,
            0.006938722915947437,
            -0.0020963039714843035,
            0.03205407038331032,
            -0.0011053907219320536,
            0.015706202015280724,
            -0.005623112432658672,
            -0.01851184293627739,
            0.0289499219506979,
            -0.007344919256865978,
            -0.004754149820655584,
            0.011914650909602642,
            0.006363825406879187,
            -0.01863235794007778,
            -0.01649341732263565,
            0.0016129605937749147,
            0.007307745981961489,
            0.021353088319301605,
            0.014322376810014248,
            -0.034556902945041656,
            0.019981233403086662,
            0.030931811779737473,
            0.011067566461861134,
            -0.06034139171242714,
            0.002916750032454729,
            -0.018944155424833298,
            9.984129064832814e-06,
            -0.008835646323859692,
            -0.022588251158595085,
            -0.01602749712765217,
            0.025637945160269737,
            0.004776590969413519,
            0.03453487902879715,
            0.0296546071767807,
            0.0342385433614254,
            -0.008281993679702282,
            0.014931178651750088,
            0.018880169838666916,
            -0.018208079040050507,
            -0.005080754868686199,
            -0.011130273342132568,
            -0.01575019396841526,
            0.010343121364712715,
            -0.02563948929309845,
            0.014494848437607288,
            -0.0013198151718825102,
            -0.028922442346811295,
            -0.031078238040208817,
            -0.014012548141181469,
            -0.006298397667706013,
            -0.027166953310370445,
            -0.006007201503962278,
            -0.01811841130256653,
            0.011136217974126339,
            0.004817379638552666,
            -0.017668012529611588,
            0.013606378808617592,
            0.028176680207252502,
            -0.004073222167789936,
            0.010495221242308617,
            -0.011585664935410023,
            -0.013313871808350086,
            0.00888874288648367,
            -0.021186809986829758,
            0.013782048597931862,
            0.022993020713329315,
            0.03199586272239685,
            -0.022344255819916725,
            -0.03199416771531105,
            -0.030519448220729828,
            0.02863830141723156,
            -0.005931832827627659,
            0.03279966488480568,
            -0.0030903099104762077,
            0.021121053025126457,
            0.014416017569601536,
            0.0021719704382121563,
            0.034385550767183304,
            0.0024195159785449505,
            0.02710074745118618,
            -0.022271862253546715,
            -0.041729461401700974,
            -0.009603888727724552,
            -0.014234299771487713,
            0.0469200536608696,
            -0.026401948183774948,
            0.03539153188467026,
            0.004789524711668491,
            -0.03379807993769646,
            -0.019455647096037865,
            0.019365429878234863,
            -0.012200339697301388,
            0.018141545355319977,
            0.0006575877196155488,
            0.003222388681024313,
            -0.03448134660720825,
            0.03112529218196869,
            -0.025940220803022385,
            -0.026227988302707672,
            -0.011934444308280945,
            0.020460957661271095,
            -0.02199767529964447,
            0.016205651685595512,
            -0.023872025310993195,
            0.035662587732076645,
            0.0005286479135975242,
            0.010275076143443584,
            -0.004946886096149683,
            0.003987304866313934,
            0.029943715780973434,
            0.005723004695028067,
            -0.016753150150179863,
            0.021883636713027954,
            0.040344927459955215,
            0.015379886142909527,
            0.007455694954842329,
            -0.019303355365991592,
            0.019033286720514297,
            -0.017627183347940445,
            -0.03195822611451149,
            -0.010640979744493961,
            0.04208828881382942,
            0.007511121220886707,
            -0.0002852418983820826,
            -0.0007248318288475275,
            0.00023045965644996613,
            0.020745987072587013,
            -0.022429222241044044,
            0.014724332839250565,
            -0.0013119734358042479,
            -0.014488207176327705,
            0.015250821597874165,
            0.00922631286084652,
            0.026918988674879074,
            -0.0019710201304405928,
            -0.021081658080220222,
            -0.025984222069382668,
            0.009124686010181904,
            0.008327893912792206,
            0.00870504043996334,
            -0.019925041124224663,
            0.007761741988360882,
            0.004452202469110489,
            0.002843985566869378,
            0.00623207725584507,
            -0.015003657899796963,
            0.015911225229501724,
            -0.002697203541174531,
            -0.009801739826798439,
            0.012111837044358253,
            -0.007300341501832008,
            -0.051858752965927124,
            -0.00875953771173954,
            -0.027059204876422882,
            0.0021946514025330544,
            -0.02330728806555271,
            -0.0035405494272708893,
            -0.0040695443749427795,
            -0.005281930789351463,
            -0.0042498791590332985,
            -0.0005720161716453731,
            -0.01390017382800579,
            0.0018314187182113528,
            0.026284970343112946,
            -0.03704039752483368,
            -0.02341398224234581,
            0.038542553782463074,
            -0.019245484843850136,
            -0.010921410284936428,
            0.009846740402281284,
            0.014886494725942612,
            0.020917829126119614,
            0.033217668533325195,
            -0.03443310409784317,
            0.005701711867004633,
            -0.021092969924211502,
            0.011198494583368301,
            0.0209367573261261,
            0.02425657957792282,
            0.008489093743264675,
            0.008260452188551426,
            -0.01065978966653347,
            0.005315216723829508,
            -0.03848688304424286,
            0.005898264702409506,
            0.004731795750558376,
            0.017637498676776886,
            -0.005710709374397993,
            -0.0036770303267985582,
            -0.049406662583351135,
            -0.01563359424471855,
            -0.01301149558275938,
            0.02014557644724846,
            0.014559498056769371,
            0.04178769141435623,
            0.03164063021540642,
            0.013491884805262089,
            0.002301190048456192,
            -0.027634702622890472,
            0.0102852089330554,
            0.03036358579993248,
            -0.014290169812738895,
            0.0007177575025707483,
            -0.0054365224204957485,
            0.011497878469526768,
            -0.008902874775230885,
            -0.025202104821801186,
            0.006643371656537056,
            0.01339232362806797,
            -0.01198082696646452,
            -0.014825870282948017,
            0.017853280529379845,
            0.003273156937211752,
            0.016563842073082924,
            0.005771413911134005,
            0.01689908280968666,
            -0.022983673959970474,
            0.007542227860540152,
            0.005775496829301119,
            -0.005288364365696907,
            0.01767224632203579,
            0.017354769632220268,
            0.005885282531380653,
            -0.023540819063782692,
            0.01990075595676899,
            0.026298657059669495,
            -0.01401947345584631,
            -0.024221867322921753,
            0.003158464329317212,
            -0.0031882976181805134,
            -0.008229910396039486,
            0.04065048694610596,
            -0.007566683925688267,
            0.02368670515716076,
            0.02740795537829399,
            0.01789124496281147,
            0.03175549581646919,
            -0.023183859884738922,
            -0.03600076213479042,
            0.022222835570573807,
            0.02009906992316246,
            0.012564099393785,
            -0.015860270708799362,
            -0.002889207797124982,
            0.012063288129866123,
            0.006371691357344389,
            -0.008238188922405243,
            0.012055311352014542,
            0.035449545830488205,
            0.0031608787830919027,
            -0.043870504945516586,
            0.038770098239183426,
            -0.02842492051422596,
            0.019721269607543945,
            0.03028803877532482,
            0.025030234828591347,
            0.004790653474628925,
            -0.012433941476047039,
            0.030559679493308067,
            -0.01177067868411541,
            0.003928217105567455,
            0.005012137815356255,
            0.020015539601445198,
            -0.02805444784462452,
            -0.019646044820547104,
            -0.0011682829353958368,
            0.031497035175561905,
            0.013348455540835857,
            -0.01279313676059246,
            -0.007930031046271324,
            -0.02244730107486248,
            -0.015544802881777287,
            0.0122109055519104,
            0.02142561972141266,
            -0.019295554608106613,
            0.007781260181218386,
            0.006763062439858913,
            0.017290275543928146,
            0.03489983454346657,
            -0.027805302292108536,
            0.00494357431307435,
            -0.029640911146998405,
            0.022322840988636017,
            0.02884429693222046,
            -0.014695004560053349,
            -0.024999186396598816,
            0.006636717822402716,
            -0.008630515076220036,
            0.01744242198765278,
            -0.024020439013838768,
            0.017705194652080536,
            0.028974387794733047,
            0.002925284905359149,
            0.022427605465054512,
            0.00035888905404135585,
            -0.0026132918428629637,
            0.010669270530343056,
            0.007398853078484535,
            0.009755486622452736,
            -0.013277869671583176,
            0.01421261578798294,
            0.01971643790602684,
            0.024989929050207138,
            0.029984060674905777,
            -0.001770664006471634,
            0.012849624268710613,
            -0.006324477028101683,
            0.008611299097537994,
            0.008385149762034416,
            -0.05824025720357895,
            -0.0014430327573791146,
            0.030682608485221863,
            -0.010121717117726803
        ],
        [
            -0.029571592807769775,
            -0.026025447994470596,
            0.01142614521086216,
            -0.02711234800517559,
            -0.03658856451511383,
            -0.03571191802620888,
            -1.8916503904620185e-05,
            -0.05269375070929527,
            -0.010890314355492592,
            0.03186557814478874,
            -0.007890468463301659,
            -0.04009201377630234,
            -0.030465813353657722,
            0.02778198942542076,
            -0.052246030420064926,
            -0.03976607322692871,
            0.01132643036544323,
            -0.010587740689516068,
            0.07701089233160019,
            0.005420785862952471,
            0.03343496099114418,
            -0.025368867442011833,
            -0.013363305479288101,
            0.015981879085302353,
            -0.049308162182569504,
            0.006512041669338942,
            0.010356303304433823,
            -0.001775339711457491,
            -0.0009420941350981593,
            -0.03796963393688202,
            -0.03351008519530296,
            -0.0027440551202744246,
            -0.029207753017544746,
            0.024445107206702232,
            0.01370637584477663,
            -0.01245066523551941,
            -0.03656226769089699,
            -0.01141861081123352,
            0.019492685794830322,
            0.019618872553110123,
            0.03056562878191471,
            0.02398011088371277,
            0.0200507715344429,
            0.011641578748822212,
            -0.028881579637527466,
            -0.03195430338382721,
            -0.07754524052143097,
            0.03706911578774452,
            -0.01517464779317379,
            -0.02558920346200466,
            0.004548702854663134,
            0.03352126106619835,
            0.028666941449046135,
            0.013295476324856281,
            -0.046999119222164154,
            0.05134093388915062,
            0.03933168202638626,
            -0.02850555256009102,
            -0.036923106759786606,
            0.014878837391734123,
            -0.02844368852674961,
            -0.018295422196388245,
            0.005984388291835785,
            -0.026591233909130096,
            -0.043410588055849075,
            0.024095429107546806,
            -0.014323812909424305,
            -0.029123779386281967,
            -0.0295000858604908,
            0.0021345496643334627,
            -0.027184754610061646,
            0.0001832949201343581,
            -0.001840831944718957,
            -0.021687494590878487,
            0.05168759450316429,
            -0.005910135339945555,
            -0.0674549788236618,
            0.02392389252781868,
            -0.035857219249010086,
            0.0005661298637278378,
            -0.004941029939800501,
            0.022789916023612022,
            -0.03152501583099365,
            -0.038644250482320786,
            -0.011369718238711357,
            -0.011499794200062752,
            0.035131342709064484,
            -0.004109429661184549,
            0.004504485987126827,
            -0.09864754229784012,
            -0.04094803333282471,
            0.02588503248989582,
            0.016321109607815742,
            0.0303163044154644,
            -0.004886044189333916,
            -0.0012778110103681684,
            0.03245396539568901,
            -0.005267711356282234,
            -0.005503068678081036,
            -0.011008037254214287,
            0.039965711534023285,
            -0.07975059747695923,
            -0.026857435703277588,
            -0.0067272004671394825,
            -0.014999068342149258,
            0.030275868251919746,
            -0.003403370501473546,
            -0.03292541950941086,
            0.036755725741386414,
            0.010483394376933575,
            -0.011220292188227177,
            0.02491808868944645,
            9.255490294890478e-05,
            -0.05247991532087326,
            0.009525898844003677,
            0.002600559266284108,
            -0.002122057368978858,
            -0.05309225618839264,
            -0.014226751402020454,
            -0.008542049676179886,
            -0.03309934586286545,
            -0.05514988303184509,
            0.105514295399189,
            0.026817448437213898,
            0.009840826503932476,
            -0.05443790927529335,
            -0.039579495787620544,
            0.0017613295931369066,
            -0.006135288625955582,
            -0.06277205049991608,
            -0.013557239435613155,
            0.02218443527817726,
            -0.01206066831946373,
            -0.013306941837072372,
            -0.0005509490147233009,
            -0.020962826907634735,
            -0.01435714028775692,
            0.032085005193948746,
            0.035588037222623825,
            0.016383260488510132,
            0.007151981815695763,
            -0.03471093252301216,
            0.014082396402955055,
            0.023156264796853065,
            0.02306271530687809,
            -0.02673271670937538,
            0.050554946064949036,
            -0.03565031290054321,
            0.04049694165587425,
            -0.013477186672389507,
            0.011267528869211674,
            -0.017019925639033318,
            0.0193766001611948,
            -0.04689610376954079,
            0.019158167764544487,
            -0.024866104125976562,
            -0.0003929601807612926,
            0.016886031255126,
            0.02284327708184719,
            -0.030473215505480766,
            -0.029867444187402725,
            0.04328007996082306,
            0.03096277266740799,
            -0.02813219651579857,
            -0.036663103848695755,
            0.06963998824357986,
            0.0025740545243024826,
            0.05372998118400574,
            -0.06266608834266663,
            0.031221026554703712,
            -0.014663669280707836,
            -0.029736798256635666,
            0.06221751123666763,
            0.01846887357532978,
            -0.02496016025543213,
            -0.008524575270712376,
            -0.02710418775677681,
            0.02316095307469368,
            0.0337107814848423,
            -0.025583641603589058,
            -0.04544932767748833,
            0.034089088439941406,
            -0.017077770084142685,
            -0.010141572915017605,
            -0.03770357742905617,
            -0.02279461920261383,
            0.0016244539292529225,
            0.0005337862530723214,
            0.01425846666097641,
            -0.012968048453330994,
            -0.03516564890742302,
            -0.01347765326499939,
            -0.03523440286517143,
            -0.008836419321596622,
            0.010954826138913631,
            0.007926863618195057,
            0.007090815342962742,
            -0.020455697551369667,
            0.005694510880857706,
            0.022837037220597267,
            -0.008077631704509258,
            0.0007649612962268293,
            -0.025848546996712685,
            -0.03153824061155319,
            0.009918012656271458,
            -0.007681775372475386,
            0.08130008727312088,
            -0.015353674069046974,
            -0.002210807055234909,
            0.025176003575325012,
            -0.0372781902551651,
            0.006311318837106228,
            -0.0014147106558084488,
            0.004655260592699051,
            -0.04514380171895027,
            0.033764999359846115,
            -0.019580697640776634,
            -0.002280183369293809,
            -0.05918524041771889,
            0.014320687390863895,
            -0.026205718517303467,
            -0.03312843665480614,
            0.038705941289663315,
            -0.029773805290460587,
            0.0034599497448652983,
            -0.02301868423819542,
            -0.02329831011593342,
            -0.010447906330227852,
            0.02103019878268242,
            -0.02376643940806389,
            0.017866840586066246,
            0.043728746473789215,
            -0.002618431579321623,
            -0.012414675205945969,
            -0.03881632164120674,
            0.02326347678899765,
            0.004342318046838045,
            0.016460325568914413,
            -0.00844512414187193,
            -0.031169507652521133,
            0.01962852291762829,
            -0.030636562034487724,
            0.006787443533539772,
            -0.006554280873388052,
            0.01245835330337286,
            0.003648210084065795,
            -0.06364841014146805,
            0.008335529826581478,
            0.0026710079982876778,
            0.026547404006123543,
            -0.040711659938097,
            0.03634614124894142,
            -0.004313111305236816,
            -0.023342400789260864,
            -0.002893617143854499,
            -0.020921120420098305,
            0.019437985494732857,
            -0.023565107956528664,
            -0.045047566294670105,
            0.02553933672606945,
            0.02635238692164421,
            0.048100151121616364,
            0.04117697849869728,
            0.05243498086929321,
            -0.01417757011950016,
            0.036736033856868744,
            -0.006234125699847937,
            0.004641415551304817,
            -0.04821348935365677,
            0.014144375920295715,
            0.004262800328433514,
            -0.00042317298357374966,
            -0.028854480013251305,
            -0.023854002356529236,
            -0.011752120219171047,
            0.04231288284063339,
            0.00993360299617052,
            0.030139928683638573,
            -0.004892675206065178,
            -0.012923273257911205,
            -0.0099757956340909,
            -0.03753175586462021,
            -0.04342197999358177,
            -0.007808732334524393,
            -0.0173602644354105,
            -0.011884637176990509,
            0.031177818775177002,
            0.028258906677365303,
            0.010730557143688202,
            -0.015167657285928726,
            -0.050058651715517044,
            -0.025058580562472343,
            -0.041624341160058975,
            0.006693669129163027,
            -0.06516450643539429,
            -0.014306641183793545,
            0.049085162580013275,
            0.007433612830936909,
            -0.053029078990221024,
            0.021456975489854813,
            -0.03528975322842598,
            -0.014496807008981705,
            0.01983022131025791,
            -0.04046175256371498,
            0.03623354807496071,
            0.02304028533399105,
            0.058354612439870834,
            0.021972015500068665,
            0.04638208821415901,
            -0.03247656300663948,
            0.0016898739850148559,
            0.020893193781375885,
            0.026123838499188423,
            0.0038949521258473396,
            -0.013314850628376007,
            0.035764578729867935,
            0.0308050736784935,
            0.03974439948797226,
            -0.01076117716729641,
            -0.0010813678381964564,
            -0.021799050271511078,
            0.06106400117278099,
            0.03876142203807831,
            -0.011772016063332558,
            -0.007854513823986053,
            0.0024822899140417576,
            -0.026454905048012733,
            0.05708882585167885,
            0.001160195330157876,
            0.01939447410404682,
            0.01027936115860939,
            0.04108873009681702,
            0.005506966263055801,
            -0.011533720418810844,
            -0.050323404371738434,
            0.027322035282850266,
            0.013546300120651722,
            -0.007966242730617523,
            -0.006298855412751436,
            0.0026589673943817616,
            0.014671306125819683,
            0.02702576294541359,
            -0.024089695885777473,
            0.008561943657696247,
            -0.01085067167878151,
            0.04630674049258232,
            -0.0107703423127532,
            0.029115738347172737,
            -0.02336670644581318,
            0.04608685523271561,
            0.0009526399080641568,
            -0.02346453256905079,
            -0.0033259009942412376,
            0.022983552888035774,
            0.008767463266849518,
            0.028018435463309288,
            -0.02404630556702614,
            0.013538215309381485,
            -0.009275873191654682,
            -0.01863839104771614,
            -0.013574433512985706,
            0.026163559406995773,
            0.0038671777583658695,
            0.012338774278759956,
            -0.03157174959778786,
            -0.014969922602176666,
            0.058747854083776474,
            -0.00657309265807271,
            -0.03908485546708107,
            0.013146260753273964,
            0.030394116416573524,
            -0.027306251227855682,
            0.03551122173666954,
            0.005644768476486206,
            0.0026493987534195185,
            -0.023146910592913628,
            -0.004287999123334885,
            0.03319207578897476,
            0.06231401488184929,
            -0.025192588567733765,
            0.08791693300008774,
            -0.02127564698457718,
            0.009807296097278595,
            0.006082903128117323,
            0.031342215836048126,
            0.012275825254619122,
            0.017273539677262306,
            -0.04027402028441429,
            -0.05221753567457199,
            -0.0022525321692228317,
            0.001930781058035791,
            -0.031196463853120804,
            -0.05861232802271843,
            -0.07210584729909897,
            -0.07507123053073883,
            -0.015326970256865025,
            -0.019643066450953484,
            0.010004413314163685,
            -0.02290024608373642,
            -0.03612566366791725,
            -0.006052596960216761,
            0.021841594949364662,
            -0.020363429561257362,
            0.030946075916290283,
            0.019548920914530754,
            0.0014136245008558035,
            0.02182723954319954,
            -0.07645320147275925,
            0.01220659352838993,
            -0.008601326495409012,
            0.04311668500304222,
            0.011536269448697567,
            -0.009031818248331547,
            -0.02228211797773838,
            -0.017891820520162582,
            -0.009253021329641342,
            -0.019349269568920135,
            -0.012891172431409359,
            0.027960728853940964,
            -0.004685611464083195,
            -0.01276011299341917,
            0.025126459077000618,
            0.05230390653014183,
            0.030224770307540894,
            -0.01752028614282608,
            0.002215938875451684,
            -0.04936281591653824,
            0.008583913557231426,
            -0.04196250066161156,
            0.0112930191680789,
            -0.00015007233014330268,
            -0.011204412207007408,
            -0.019071750342845917,
            0.03593844175338745,
            0.007416233886033297,
            -0.008472324348986149,
            -0.009751006960868835,
            -0.009509149007499218,
            0.025613173842430115,
            -0.0004722759476862848,
            0.023873114958405495,
            -0.021873043850064278,
            -0.005188374780118465,
            -0.0038504195399582386,
            -0.0024447888135910034,
            0.011502807959914207,
            -0.018913334235548973,
            -0.12656933069229126,
            0.04699229449033737,
            -0.008077643811702728,
            -0.02549087628722191,
            -0.01752437651157379,
            0.04655716195702553,
            0.0026266525965183973,
            0.011949829757213593,
            0.015058716759085655,
            0.0062917498871684074,
            -0.012477497570216656,
            -0.03141738101840019,
            -0.026302574202418327,
            -0.061171118170022964,
            0.023157117888331413,
            -0.0027914801612496376,
            -0.07843191176652908,
            0.02880011685192585,
            0.02512376755475998,
            0.029124390333890915,
            -0.03570741415023804,
            0.0027859616093337536,
            0.01608124189078808,
            0.022028964012861252,
            0.005062997806817293,
            -0.03590930625796318,
            -0.056318510323762894,
            0.003446439281105995,
            0.04877756908535957,
            -0.00640214653685689,
            0.03308949992060661,
            -0.011278084479272366,
            -0.007107896730303764,
            0.018761977553367615,
            -0.014064484275877476,
            0.03248804062604904,
            -0.02317013218998909,
            0.0055603706277906895,
            -0.019749194383621216,
            -0.03727668896317482,
            -0.0641942098736763,
            -0.11925064027309418,
            0.034449268132448196,
            -0.036009274423122406,
            -0.0015846053138375282,
            0.005273585207760334,
            0.02761600725352764,
            0.012992996722459793,
            -0.006941809784621,
            0.03511008620262146,
            0.0009478172287344933,
            0.02207360789179802,
            0.019709497690200806,
            -0.02183840237557888,
            -0.04839545860886574,
            -0.001080696820281446,
            0.015296478755772114,
            0.03750952333211899,
            0.000335616321535781,
            -0.05573152378201485,
            0.025482406839728355,
            0.012197671458125114,
            0.03761228546500206,
            -0.0030769777949899435,
            0.05440597981214523,
            -0.013584496453404427,
            -0.012748369947075844,
            -0.03410938009619713,
            -0.03712129220366478,
            -0.031244447454810143,
            -0.02094287984073162,
            -0.00623236782848835,
            -0.043385300785303116,
            0.02275688946247101,
            0.014898822642862797,
            0.0084789227694273,
            0.021621378138661385,
            -0.014665607362985611,
            -0.043663714081048965,
            0.05811244249343872,
            0.02579001896083355,
            0.03109310194849968,
            0.001525222440250218,
            0.015737829729914665,
            -0.0030474888626486063,
            -0.0021370097529143095,
            -0.019473709166049957,
            0.006259554997086525,
            -0.027052050456404686,
            0.02453257516026497,
            -0.0016862319316715002,
            0.015185418538749218,
            0.037108924239873886,
            -0.038356125354766846,
            0.010209575295448303,
            0.019137034192681313,
            -0.03705383464694023,
            -0.030329572036862373,
            -0.03850356116890907,
            -0.013448437675833702,
            -0.048909105360507965,
            -0.031043248251080513,
            0.010830695740878582,
            -0.016565032303333282,
            -0.022795001044869423,
            -0.026824411004781723,
            -0.05441015213727951,
            -0.02618037909269333,
            -0.04099566116929054,
            -0.04529953375458717,
            -0.009855268523097038,
            -0.017812324687838554,
            0.008647071197628975,
            0.03156663477420807,
            0.007352213375270367,
            0.016328144818544388,
            0.06552758812904358,
            -0.009630187414586544,
            -0.006941791623830795,
            0.02610333077609539,
            -0.0034434539265930653,
            0.020118769258260727,
            -0.05321021378040314,
            0.006854730192571878,
            0.005125424358993769,
            0.01792534999549389,
            -0.017275115475058556,
            0.03344020992517471,
            0.014256151393055916,
            0.02906874381005764,
            0.005536687094718218,
            -0.051748842000961304,
            0.015719681978225708,
            0.016839569434523582,
            -0.05377207323908806,
            -0.02732149325311184,
            0.036399442702531815,
            0.023334378376603127,
            -0.016428034752607346,
            -0.018047817051410675,
            -0.018139557912945747,
            0.026059124618768692,
            -0.022734856233000755,
            0.06004772335290909,
            0.03752213716506958,
            0.018971338868141174,
            0.025062821805477142,
            -0.03402205929160118,
            0.014047784730792046,
            0.03162523731589317,
            0.016357479616999626,
            0.017431192100048065,
            0.029315533116459846,
            -0.02891097404062748,
            0.01533645298331976,
            -0.029334325343370438,
            -0.03181300312280655,
            -0.012891077436506748,
            -0.038538627326488495,
            -0.03082505613565445,
            -0.00683640968054533,
            -0.036901697516441345,
            0.006520334165543318,
            0.005235523451119661,
            -0.017475802451372147,
            0.006705553270876408,
            0.0026749020908027887,
            0.007356459740549326,
            0.00897183921188116,
            -0.007081897463649511,
            0.010091817937791348,
            -0.03235965594649315,
            -0.007007796783000231,
            -0.026265574619174004,
            -0.021088644862174988,
            -0.0007082074880599976,
            -0.011232519522309303,
            -0.00416647270321846,
            0.01980917528271675,
            -0.029674915596842766,
            -0.02558465488255024,
            -0.0551186203956604,
            0.023299602791666985,
            0.06655542552471161,
            -0.006852715276181698,
            -0.04063436761498451,
            -0.0017299995524808764,
            -0.07143718749284744,
            0.03308532387018204,
            0.009448579512536526,
            0.030146364122629166,
            -0.0056377192959189415,
            -0.007751846686005592,
            0.04274292662739754,
            0.041156962513923645,
            0.0513218492269516,
            0.024098029360175133,
            -0.030922947451472282,
            -0.004468430299311876,
            0.038582611829042435,
            -0.0034246554132550955,
            0.0028906783554702997,
            -0.007635466288775206,
            -0.022408785298466682,
            -0.04307620972394943,
            -0.010579198598861694,
            -0.03683583810925484,
            0.03982493281364441,
            0.03266597539186478,
            0.00851859338581562,
            -0.011688759550452232,
            0.00879343319684267,
            0.05347156897187233,
            0.03708547353744507,
            0.005066261626780033,
            -0.012240657582879066,
            -0.0025418249424546957,
            -0.01215497124940157,
            -0.006239741574972868,
            -0.005161279812455177,
            -0.021288849413394928,
            -0.042255111038684845,
            -0.012226100079715252,
            -0.05628088116645813,
            0.02751239947974682,
            0.029305104166269302,
            0.028426241129636765,
            -0.005416168365627527,
            0.03840520605444908,
            0.005968641955405474,
            -0.03390994668006897,
            0.013263318687677383,
            -0.04142124950885773,
            0.023440321907401085,
            0.02303399331867695,
            -0.007673401385545731,
            -0.025440208613872528,
            0.0048680719919502735,
            -0.014171667397022247,
            0.06385011970996857,
            0.0071133957244455814,
            -0.00905973557382822,
            0.04837476834654808,
            0.019680529832839966,
            0.005411120597273111,
            0.03436079993844032,
            0.02510763518512249,
            -0.016064651310443878,
            0.014386731199920177,
            -0.0013094627065584064,
            0.010199321433901787,
            -0.010019457899034023,
            -0.0010496035683900118,
            0.03554532304406166,
            0.004498659633100033,
            0.009595273993909359,
            0.0069613950327038765,
            -0.02638109400868416,
            0.03685907647013664,
            0.01047351211309433,
            0.010995117016136646,
            0.010214239358901978,
            0.02007927931845188,
            -0.026702549308538437,
            -0.0064360611140728,
            0.011514244601130486,
            -0.013072516769170761,
            0.005475642625242472,
            0.00807051733136177,
            -0.009132105857133865,
            -0.0022312388755381107,
            -0.013352983631193638,
            -0.004205205477774143,
            -0.039243895560503006,
            -0.01961308903992176,
            0.02652054838836193,
            -0.0070151761174201965,
            -0.03690103068947792,
            -0.03982583060860634,
            -0.0027299418579787016,
            0.007342294789850712,
            -0.04163854196667671,
            0.00021052775264251977,
            -0.005266757681965828,
            0.004591144621372223,
            -0.07593656331300735,
            0.0015327128348872066,
            0.023879049345850945,
            -0.009857924655079842,
            -0.03606795519590378,
            -0.010269995778799057,
            0.08566775918006897,
            0.02435752935707569,
            -0.021058840677142143,
            0.020475009456276894,
            -0.008402364328503609,
            0.023671280592679977,
            -0.024166638031601906,
            0.025543976575136185,
            0.028572048991918564,
            -0.0013834888814017177,
            -0.04410308972001076,
            -0.03280459716916084,
            0.04401697963476181,
            -0.006663945037871599,
            -0.0021091175731271505,
            0.02693372406065464,
            -0.030292313545942307,
            0.020111534744501114,
            0.003441543783992529,
            -0.03760349005460739,
            0.012045665644109249,
            0.024080729112029076,
            0.03221295028924942,
            -0.029247663915157318,
            -0.017521919682621956,
            -0.026739418506622314,
            -0.0017333336872979999,
            0.043955013155937195,
            0.00975148193538189,
            0.001245013321749866,
            -0.009871347807347775,
            0.018993215635418892,
            -0.009941300377249718,
            -0.0011514048092067242,
            -0.008900278247892857,
            0.08192725479602814,
            -0.004043990280479193,
            0.06474336236715317,
            0.007583923172205687,
            0.03173775598406792,
            0.00327712157741189,
            -0.03171561658382416,
            0.03760698437690735,
            0.00773467356339097,
            -0.06005438417196274,
            -0.01154810469597578,
            -0.029165130108594894,
            0.040366753935813904,
            0.025516677647829056,
            0.02557959221303463,
            -0.009662906639277935,
            0.012119950726628304,
            -0.007778694853186607,
            -0.04240047186613083,
            -0.007598213851451874,
            -0.01041232980787754,
            -0.03111426904797554,
            -0.040437016636133194,
            0.013768086209893227,
            0.019945604726672173,
            -0.005171897355467081,
            0.0060785687528550625,
            -0.013466281816363335,
            -0.006210528314113617,
            -0.010655995458364487,
            -0.011839913204312325,
            -0.019481917843222618,
            0.05267712473869324,
            0.037538252770900726,
            -0.03454781323671341,
            0.01402529887855053,
            0.0018768050940707326,
            0.051559798419475555,
            -0.011293872259557247,
            -0.004767878912389278,
            0.0031018885783851147,
            -0.015193086117506027,
            0.018402228131890297,
            -0.03551758453249931,
            -0.008502363227307796,
            -0.009513415396213531,
            0.0027410162147134542,
            -0.012380806729197502,
            0.013379042968153954,
            -0.06317707151174545,
            -0.02527192421257496,
            0.010681195184588432,
            0.0030429374892264605,
            -0.01945132575929165,
            0.025317560881376266,
            0.0019579892978072166,
            -0.014099640771746635,
            0.01228334754705429,
            -0.017535867169499397,
            0.02625279314815998,
            -0.013272259384393692,
            0.06414064019918442,
            -0.01042858324944973,
            0.01905255764722824,
            -0.004620871972292662,
            -0.010559650138020515,
            -0.03038838319480419,
            -0.012062878347933292,
            -0.01241348497569561,
            0.028996508568525314,
            0.027378469705581665,
            -0.0147637240588665,
            -0.029545627534389496,
            -0.007721218280494213,
            -0.02112865447998047,
            0.020238617435097694,
            0.01826028898358345,
            0.01241439487785101,
            -0.01515557337552309,
            0.04630974307656288,
            0.018408220261335373,
            -0.022278426215052605,
            -0.011643645353615284,
            -0.012204522266983986,
            -0.013221092522144318,
            -0.030104711651802063,
            0.05158143863081932,
            0.029018551111221313,
            0.00952088925987482,
            0.0451805517077446,
            -0.09871082752943039,
            0.03736671805381775,
            -0.02785574458539486,
            -0.01833776757121086,
            -0.0016986250411719084,
            -0.03628820180892944,
            0.0193081796169281,
            0.03388698399066925,
            0.001117528649047017,
            -0.12921245396137238,
            -0.0037035986315459013,
            0.020677708089351654,
            0.017748957499861717,
            0.037192586809396744,
            -0.012887637130916119,
            0.040382903069257736,
            0.0020711319521069527,
            0.008790557272732258,
            -0.021619034931063652,
            -0.0499272383749485,
            -0.005510621704161167,
            0.009286152198910713,
            0.040499597787857056,
            -0.02461112290620804,
            -0.006724856793880463,
            0.021343518048524857,
            -0.05072040110826492,
            0.014960484579205513,
            0.011201041750609875,
            0.02224775031208992,
            -0.03192051127552986,
            -0.00838556606322527,
            -0.029081916436553,
            -0.011672438122332096,
            0.01659032329916954,
            -0.010795091278851032,
            -0.014110681600868702,
            0.037635643035173416,
            -0.01451135240495205,
            0.014002778567373753,
            0.021847674623131752,
            0.06944364309310913,
            0.03384409472346306,
            -0.08827387541532516,
            0.023775886744260788,
            -0.03158743679523468,
            0.023309413343667984,
            0.0023973919451236725,
            0.008308387361466885,
            -0.05237245559692383,
            0.038142845034599304,
            -0.047727953642606735,
            -0.03319818899035454,
            0.00038789393147453666,
            -0.007427582982927561,
            -0.03276244178414345,
            -0.018296625465154648,
            -0.0013375880662351847,
            0.026509230956435204,
            0.028494611382484436,
            0.029009250923991203,
            0.008791511878371239,
            0.026896625757217407,
            0.02463570237159729,
            0.03568944334983826,
            -0.00374061637558043,
            0.05773238092660904,
            -0.029679449275135994,
            0.02397218346595764,
            0.01901640184223652,
            -0.012260532937943935,
            0.031889718025922775,
            -0.009114513173699379,
            0.01666727103292942,
            -0.011853227391839027,
            0.0267045795917511,
            -0.01591772958636284,
            0.0033189665991812944,
            -0.017418473958969116,
            0.06277498602867126,
            0.03275072202086449,
            -0.02270122803747654,
            -0.005479253828525543,
            -0.02565746195614338,
            -0.00449583912268281,
            0.004469820763915777,
            -0.0081987539306283,
            0.005730757024139166,
            -0.016210252419114113,
            -0.015949541702866554,
            -0.04503250494599342,
            0.07154078781604767,
            0.031381867825984955,
            -0.01905898004770279,
            0.035967838019132614,
            -0.015158828347921371,
            -0.028052164241671562,
            -0.0010730025824159384,
            -0.020698919892311096,
            0.0341007336974144,
            0.03113902173936367,
            0.01705181412398815,
            0.01326278131455183,
            -0.07885830849409103,
            0.021165531128644943,
            -0.031421199440956116,
            0.012793323956429958,
            -0.02168012037873268,
            0.004694057162851095,
            -0.00778370862826705,
            -0.003015234600752592,
            0.0072878580540418625,
            0.03313566371798515,
            0.05829177796840668,
            0.008517444133758545,
            -0.037308964878320694,
            -0.006904692389070988,
            -0.0001936841435963288,
            0.020333869382739067,
            -0.0020332413259893656,
            -0.003126697614789009,
            0.04601604491472244,
            0.014900078065693378,
            0.004416889976710081,
            0.010612836107611656,
            -0.01445509772747755,
            0.018884753808379173,
            0.0304232370108366,
            0.021051649004220963,
            -0.015550929121673107,
            -0.0042514256201684475,
            -0.000935420801397413,
            -0.023625565692782402,
            0.0038903821259737015,
            -0.05040036141872406,
            0.020241709426045418,
            -0.023416461423039436,
            -0.026130393147468567,
            0.027511442080140114,
            1.986668758036103e-05,
            -0.051590658724308014,
            -0.02237112447619438,
            -0.02042827568948269,
            0.006486539728939533,
            0.04774433374404907,
            -0.02642880193889141,
            -0.031734805554151535,
            -0.003947946708649397,
            -0.053746726363897324,
            0.04931820556521416,
            -0.003907599486410618,
            0.014079323969781399,
            -0.04462152346968651,
            0.015358508564531803,
            0.017023643478751183,
            -0.003949228208512068,
            -0.014657748863101006,
            -0.043104138225317,
            0.0026653166860342026,
            0.025669720023870468,
            -6.251157901715487e-05
        ],
        [
            0.025072000920772552,
            -0.0020782570354640484,
            -0.0211589727550745,
            -0.011235744692385197,
            0.022633912041783333,
            -0.05038619041442871,
            0.0168263241648674,
            -0.014541427604854107,
            0.009616391733288765,
            0.0051686023361980915,
            0.035595741122961044,
            0.03207120671868324,
            0.007190738804638386,
            -0.02510998025536537,
            -0.0359853133559227,
            -0.005248680245131254,
            0.013299932703375816,
            -0.05084484443068504,
            0.027236029505729675,
            0.006937452591955662,
            0.013685354962944984,
            0.001095679821446538,
            -0.003980547189712524,
            -0.005285707768052816,
            0.014224892482161522,
            -0.011728902347385883,
            -0.02389942854642868,
            0.021437207236886024,
            0.024856984615325928,
            -0.025889907032251358,
            0.007184525020420551,
            -0.025309771299362183,
            -0.0179633479565382,
            -0.016389820724725723,
            0.014174694195389748,
            -0.01234797015786171,
            -0.012605712749063969,
            -0.020567936822772026,
            -0.029132461175322533,
            -0.0068700904957950115,
            0.021600453183054924,
            -0.06392200291156769,
            -0.026373153552412987,
            0.005640444345772266,
            0.003700344357639551,
            0.016238868236541748,
            0.01699715480208397,
            0.013818771578371525,
            0.006082132458686829,
            -0.005181307438760996,
            -0.0159955732524395,
            0.0021165874786674976,
            -0.0038404357619583607,
            0.022284433245658875,
            -0.06383740901947021,
            0.030396167188882828,
            -0.010176125913858414,
            0.0030283161904662848,
            -0.019351739436388016,
            -0.037128813564777374,
            0.02716999500989914,
            -0.03865772858262062,
            -0.02087153121829033,
            -0.046992313116788864,
            -0.024315383285284042,
            -0.053563401103019714,
            -0.0005214229458943009,
            -0.031067442148923874,
            0.006781716365367174,
            0.04112314060330391,
            0.01622781530022621,
            -0.018629034981131554,
            -0.042854759842157364,
            -0.029117070138454437,
            -0.03390258923172951,
            -0.025362417101860046,
            0.032365452498197556,
            0.03216475248336792,
            -0.012646214105188847,
            0.034205999225378036,
            0.0067769563756883144,
            0.0032332392875105143,
            -0.02084725722670555,
            -0.016587741672992706,
            -0.0038801932241767645,
            -0.04861705005168915,
            -0.0222147349268198,
            -0.009776306338608265,
            0.0038546191062778234,
            -0.047972422093153,
            0.0033347869757562876,
            0.004739813040941954,
            -0.03425091132521629,
            -0.04577735438942909,
            0.0200184378772974,
            -0.00041861855424940586,
            0.018682122230529785,
            0.0019530185963958502,
            -0.09059186279773712,
            -0.015877647325396538,
            -0.029712272807955742,
            0.048371437937021255,
            0.00646448927000165,
            -0.04240905120968819,
            0.005695729982107878,
            0.0035739284940063953,
            -0.003958971705287695,
            0.007394087966531515,
            0.017202846705913544,
            -0.0010932544246315956,
            0.013062139973044395,
            0.030622320249676704,
            -0.015518568456172943,
            -0.015365286730229855,
            0.029877325519919395,
            -0.01991112157702446,
            -0.046200234442949295,
            -0.0044595180079340935,
            0.004600252490490675,
            0.005181051325052977,
            0.026081426069140434,
            0.008949476294219494,
            -0.017334699630737305,
            -0.00671782623976469,
            0.03909091278910637,
            -0.0013441123301163316,
            -0.018799930810928345,
            -0.01618688367307186,
            -0.015421755611896515,
            0.01582726649940014,
            0.040307044982910156,
            0.006639845669269562,
            0.05375826731324196,
            0.013963193632662296,
            -0.02001696266233921,
            0.012969034723937511,
            0.01368425227701664,
            0.01948106288909912,
            -0.015965396538376808,
            -0.015262572094798088,
            0.03427732363343239,
            -0.042775120586156845,
            -0.06952280551195145,
            -0.007936703972518444,
            -0.010230150073766708,
            0.0390438437461853,
            -0.009697622619569302,
            0.05702052265405655,
            -0.01737941801548004,
            -0.022799808531999588,
            0.03607815131545067,
            0.017385918647050858,
            0.03917583078145981,
            0.03785879909992218,
            -0.04246300086379051,
            -0.004343091510236263,
            0.017553698271512985,
            0.02101164683699608,
            -0.02535780891776085,
            0.016818862408399582,
            -0.027085531502962112,
            0.0025502995122224092,
            0.01660395786166191,
            -0.01871969923377037,
            -0.004446792881935835,
            0.0008454268681816757,
            0.0690867006778717,
            -0.05556119978427887,
            -0.059602413326501846,
            -0.0020574447698891163,
            -0.014298804104328156,
            0.022636864334344864,
            -0.007935434579849243,
            -0.009022252634167671,
            0.043851789087057114,
            0.0063445065170526505,
            -0.0161148514598608,
            0.04754546657204628,
            -0.012195275165140629,
            0.011965343728661537,
            -0.029201559722423553,
            0.004319836851209402,
            -0.028296666219830513,
            0.038810402154922485,
            0.028899475932121277,
            -0.0317213349044323,
            0.04170474410057068,
            0.006619093474000692,
            0.00589470099657774,
            0.03383636102080345,
            0.030813749879598618,
            0.026181748136878014,
            -0.024262309074401855,
            0.005758432671427727,
            -0.025166569277644157,
            -0.00017538675456307828,
            0.00955621525645256,
            -0.019073307514190674,
            -0.006922264117747545,
            -0.02539176493883133,
            -0.021115481853485107,
            0.0008304717484861612,
            0.04241342097520828,
            -0.022784283384680748,
            -0.01828300952911377,
            -0.011570875532925129,
            -0.04452134668827057,
            0.062249407172203064,
            -0.02186661586165428,
            -0.00947597436606884,
            0.031764496117830276,
            0.03179130330681801,
            0.023502785712480545,
            -0.05409052595496178,
            0.0058342753909528255,
            -0.015425721183419228,
            0.0016860360046848655,
            0.010410476475954056,
            -0.020802726969122887,
            0.013349439017474651,
            -0.012920706532895565,
            0.011361907236278057,
            -0.033110443502664566,
            -0.01663602516055107,
            -0.010598475113511086,
            0.05094470828771591,
            -0.006111538968980312,
            0.006524228490889072,
            -0.01939995214343071,
            -0.021212566643953323,
            -0.028848299756646156,
            -0.06383777409791946,
            -0.003492991905659437,
            -0.02648514322936535,
            -0.024891754612326622,
            -0.03780965507030487,
            0.0004193120403215289,
            -0.02639753185212612,
            0.015792490914463997,
            -0.010195288807153702,
            0.019958991557359695,
            0.03457195684313774,
            0.02693277597427368,
            0.009438643231987953,
            0.02279551886022091,
            -0.010709794238209724,
            0.006426648702472448,
            -0.02865804359316826,
            -0.026366163045167923,
            -0.006380856037139893,
            0.014781194739043713,
            -0.01887885108590126,
            -0.0199862290173769,
            -0.012669130228459835,
            0.015661342069506645,
            -0.007822024635970592,
            -0.02449190802872181,
            -0.04229149967432022,
            0.0370388999581337,
            -0.003650292754173279,
            0.00848509930074215,
            0.00972815416753292,
            0.07236213982105255,
            0.007119500543922186,
            0.017742227762937546,
            0.035799894481897354,
            -0.003028945531696081,
            -0.011309296824038029,
            -0.022406520321965218,
            -0.025561660528182983,
            -0.021055735647678375,
            0.00203609187155962,
            -0.034179504960775375,
            -0.008437961339950562,
            -0.010221278294920921,
            0.021393660455942154,
            -0.019854439422488213,
            0.02038300596177578,
            0.010424700565636158,
            -0.02381204441189766,
            0.06296946853399277,
            0.03972405940294266,
            -0.012798372656106949,
            0.03227516636252403,
            -0.0027151063550263643,
            0.0012425812892615795,
            -0.005182329565286636,
            0.004316436126828194,
            0.024641267955303192,
            0.04622117057442665,
            -0.027149733155965805,
            0.019062316045165062,
            0.0245984997600317,
            -0.015663715079426765,
            0.004499663133174181,
            0.029955223202705383,
            -0.029733693227171898,
            -0.02011670172214508,
            0.07463093101978302,
            0.015816284343600273,
            -0.04127315804362297,
            -0.00803489051759243,
            -0.001390810706652701,
            -0.030508562922477722,
            -0.015450612641870975,
            -0.023338377475738525,
            0.0060315742157399654,
            0.01472644042223692,
            0.03439835458993912,
            0.016958443447947502,
            -0.005462081171572208,
            -0.001783096930012107,
            -0.022945448756217957,
            -0.011140590533614159,
            -0.0042994581162929535,
            0.009218739345669746,
            0.04724475368857384,
            -0.015650641173124313,
            0.02787642553448677,
            -0.008398654870688915,
            -0.005166606046259403,
            -0.008902350440621376,
            -0.0009930209489539266,
            -0.028352851048111916,
            -0.00931544415652752,
            -0.042413342744112015,
            -0.0076494705863296986,
            -0.022231893613934517,
            -0.015111017972230911,
            -0.006056917365640402,
            0.03295528516173363,
            -0.02583770826458931,
            -0.019727127626538277,
            0.03202706575393677,
            -0.01384140457957983,
            0.010778434574604034,
            0.0020056453067809343,
            0.02870563045144081,
            -0.01604432798922062,
            0.021918881684541702,
            -0.024800993502140045,
            0.015827210620045662,
            -0.05180257931351662,
            -0.026335177943110466,
            -0.03271479904651642,
            -0.010052685625851154,
            -0.025457991287112236,
            0.02668849192559719,
            -0.003962754271924496,
            0.035557106137275696,
            0.03145522251725197,
            0.0626876950263977,
            0.02310536615550518,
            -0.008305419236421585,
            0.0751502513885498,
            -0.03189609944820404,
            0.024359261617064476,
            -0.02526160515844822,
            -0.012157399207353592,
            -0.022845741361379623,
            0.028899969533085823,
            -0.01903562806546688,
            0.031504880636930466,
            -0.041544824838638306,
            0.028097305446863174,
            0.03999500721693039,
            -0.0002654960844665766,
            0.013556393794715405,
            -0.005736880004405975,
            -0.003941259812563658,
            -0.009046749211847782,
            -0.02851073443889618,
            0.024875957518815994,
            0.041583504527807236,
            -2.7562040486373007e-05,
            0.07199728488922119,
            0.01639176346361637,
            -0.03387729823589325,
            -0.025190548971295357,
            -0.01919194683432579,
            -0.03706171363592148,
            -0.0053425622172653675,
            0.012516487389802933,
            -0.01991288550198078,
            0.025805065408349037,
            -0.07499886304140091,
            0.024267936125397682,
            0.0002909512841142714,
            -0.018755195662379265,
            -0.04186711832880974,
            -0.012990998104214668,
            -0.01969132386147976,
            0.01646481268107891,
            -0.02070360817015171,
            0.03604772314429283,
            -0.014483909122645855,
            0.0044369446113705635,
            -0.0008291690028272569,
            -0.010367460548877716,
            -0.011443599127233028,
            0.006632870063185692,
            -0.030217517167329788,
            0.021969083696603775,
            -0.01807723380625248,
            -0.0334678515791893,
            -0.033678848296403885,
            0.001505133812315762,
            0.051863476634025574,
            0.007231744937598705,
            -0.0022589322179555893,
            -0.00251581659540534,
            -0.008945677429437637,
            -0.01148571353405714,
            -0.02656261809170246,
            0.03996509686112404,
            -0.0002254928695037961,
            0.0001866022648755461,
            -0.05338244512677193,
            -0.01865364983677864,
            -0.008259779773652554,
            -0.021868770942091942,
            -0.0366373136639595,
            -0.04740555211901665,
            0.00408521993085742,
            0.04746781662106514,
            0.01968863606452942,
            -0.02472519502043724,
            -0.02679692953824997,
            0.013232311233878136,
            0.002343322616070509,
            -0.02714422717690468,
            -0.010339849628508091,
            0.008534491993486881,
            0.016344452276825905,
            -0.013049217872321606,
            -0.0011270870454609394,
            0.014148387126624584,
            -0.0050724828615784645,
            0.01109972782433033,
            -0.006711694877594709,
            0.08187626302242279,
            0.03488582372665405,
            -0.01989845186471939,
            -0.014844714663922787,
            0.009010609239339828,
            -0.022275365889072418,
            -0.0016459855251014233,
            -0.02193458378314972,
            -0.045563217252492905,
            -0.04529596492648125,
            -0.010692493990063667,
            0.003794017480686307,
            -0.01189152617007494,
            0.02544049173593521,
            -0.0136967608705163,
            -0.007817862555384636,
            0.018001442775130272,
            0.011566010303795338,
            0.007721449248492718,
            -0.012763887643814087,
            -0.0054481965489685535,
            -0.023392805829644203,
            0.023658767342567444,
            0.023539965972304344,
            -0.024484923109412193,
            -0.01916450820863247,
            -0.0035373582504689693,
            0.0055763134732842445,
            -0.015646997839212418,
            -0.0013961230870336294,
            0.04501961171627045,
            0.014417565427720547,
            -0.0062336307018995285,
            0.007876557298004627,
            -0.019465139135718346,
            0.011109457351267338,
            -0.05424803867936134,
            0.020215608179569244,
            -0.01024096179753542,
            -0.0026190371718257666,
            0.04668121039867401,
            -0.0055910018272697926,
            0.01269389595836401,
            0.011654944159090519,
            -0.010566865094006062,
            -0.030069401487708092,
            0.022045111283659935,
            -0.0032927447464317083,
            -0.01861756108701229,
            0.007055493071675301,
            0.006656365469098091,
            0.029201483353972435,
            -0.028664492070674896,
            0.034113772213459015,
            -0.02672518976032734,
            0.03322727233171463,
            0.041248392313718796,
            -0.0072861588560044765,
            0.00024672577274031937,
            0.003132897661998868,
            0.030280405655503273,
            -0.0016907973913475871,
            0.01740196719765663,
            -0.009641933254897594,
            0.015838485211133957,
            0.02251422218978405,
            0.02617110311985016,
            0.0028581321239471436,
            0.007676312234252691,
            -0.0015396985691040754,
            -0.007001920137554407,
            0.0031956499442458153,
            -0.018642058596014977,
            0.041791338473558426,
            -0.0015925266779959202,
            -0.006939761806279421,
            0.024457093328237534,
            0.0063087367452681065,
            0.03119678795337677,
            0.005663649644702673,
            0.036204688251018524,
            0.008281373418867588,
            0.02678055129945278,
            0.029780693352222443,
            -0.028147956356406212,
            -0.02835189923644066,
            -0.029129840433597565,
            -0.0016595744527876377,
            -0.0050466787070035934,
            0.013685428537428379,
            -0.0129514429718256,
            0.005473102442920208,
            -0.012987549416720867,
            -0.02423972450196743,
            0.015343332663178444,
            0.05369144305586815,
            0.017994757741689682,
            -0.029019644483923912,
            -0.007586678955703974,
            0.0048832641914486885,
            0.01919347234070301,
            0.019201381132006645,
            0.00387117569334805,
            -0.02161388285458088,
            -0.024822333827614784,
            0.01351576391607523,
            0.015105734579265118,
            -0.046894267201423645,
            -0.027777206152677536,
            0.00022380490554496646,
            -0.03182639181613922,
            -0.030104970559477806,
            -0.05213041976094246,
            0.03920099139213562,
            -0.007194104604423046,
            -0.011915579438209534,
            0.00485923420637846,
            -0.01685173064470291,
            -0.018657920882105827,
            0.028623785823583603,
            0.005818983074277639,
            -0.0018253271700814366,
            0.011259663850069046,
            -0.012356112711131573,
            0.003510350128635764,
            -0.006611473858356476,
            0.004453442990779877,
            0.006448943633586168,
            -0.019943460822105408,
            -0.036333974450826645,
            -0.04363734647631645,
            0.017427092418074608,
            0.0072913686744868755,
            -0.005267562344670296,
            0.017509447410702705,
            -0.059103984385728836,
            0.04685840383172035,
            -0.01442757062613964,
            -0.00012337560474406928,
            0.013357908464968204,
            -0.026661992073059082,
            0.027301721274852753,
            0.0010813926346600056,
            -0.008184610866010189,
            0.01630718819797039,
            -0.019721299409866333,
            -0.0502580963075161,
            -0.01601138338446617,
            -0.00030404896824620664,
            -0.013180029578506947,
            -0.002565164351835847,
            0.004305259790271521,
            0.008240223862230778,
            -0.03040209226310253,
            0.0019026240333914757,
            0.04582859203219414,
            -0.05277595669031143,
            -0.03173547610640526,
            -0.04450919106602669,
            -0.02727995254099369,
            -0.03240440413355827,
            0.029668165370821953,
            -0.03768325597047806,
            -0.024884236976504326,
            -0.026285115629434586,
            -0.048864949494600296,
            0.026517022401094437,
            -0.010777724906802177,
            0.011487850919365883,
            -0.022586321458220482,
            -0.0034171580336987972,
            -0.011154690757393837,
            0.021732233464717865,
            0.011832534335553646,
            0.004112554248422384,
            -0.048471599817276,
            0.011634907685220242,
            0.044642090797424316,
            -0.018102478235960007,
            0.02085375040769577,
            0.005431044846773148,
            0.024213718250393867,
            0.029489491134881973,
            0.036829251796007156,
            -0.0008748336113058031,
            0.010836634784936905,
            0.012066504918038845,
            0.04574897140264511,
            0.020828306674957275,
            0.0008204660844057798,
            -0.005045984871685505,
            -0.006339384242892265,
            -0.011637125164270401,
            0.02498798817396164,
            0.02571343071758747,
            -0.031500790268182755,
            -0.04582441225647926,
            0.008770035579800606,
            -0.009355723857879639,
            0.004489796236157417,
            0.005916285794228315,
            -0.033716943114995956,
            0.07059074193239212,
            -0.02938886731863022,
            -0.04337921738624573,
            -0.04064637050032616,
            0.030543150380253792,
            0.0044485293328762054,
            0.02151031792163849,
            0.011812319047749043,
            0.019132278859615326,
            0.023249000310897827,
            0.019836170598864555,
            -0.0038627057801932096,
            0.026792362332344055,
            0.01924234814941883,
            -0.011913315393030643,
            -0.02357269451022148,
            -0.010868476703763008,
            -0.016247574239969254,
            -0.011959795840084553,
            0.02430199272930622,
            0.02292654477059841,
            0.029454760253429413,
            0.0007934768218547106,
            0.017426135018467903,
            0.06476102769374847,
            0.015564829111099243,
            -0.010260090231895447,
            0.016698718070983887,
            -0.02453053742647171,
            0.03755314275622368,
            0.0012374722864478827,
            0.031463272869586945,
            0.003995065111666918,
            0.01488865353167057,
            -0.031229620799422264,
            -0.030011123046278954,
            -0.025799188762903214,
            0.028783107176423073,
            -0.05018772557377815,
            0.011358866468071938,
            -0.024973465129733086,
            -0.04114901274442673,
            0.0017740704352036119,
            0.022310364991426468,
            -0.018536798655986786,
            0.006649306043982506,
            -0.01199849508702755,
            0.028797755017876625,
            -0.00924658216536045,
            -0.04698162525892258,
            -0.010289310477674007,
            -0.02813791111111641,
            0.007266586646437645,
            -0.0015747420256957412,
            -0.01682712882757187,
            0.005603483412414789,
            -0.040036968886852264,
            -0.011938808485865593,
            -0.02920570597052574,
            -0.00012114065611967817,
            -0.028172001242637634,
            0.0007624799618497491,
            0.01819881796836853,
            0.04681285843253136,
            0.03240984305739403,
            -0.019956471398472786,
            0.008135093376040459,
            0.03544135019183159,
            0.03312850371003151,
            0.004937305580824614,
            -0.03081672452390194,
            0.011260136030614376,
            0.008062879554927349,
            0.00036051831557415426,
            0.016048364341259003,
            0.024435214698314667,
            -0.021756034344434738,
            0.019885549321770668,
            -0.0011153933592140675,
            0.02302349917590618,
            0.025418363511562347,
            0.01848760060966015,
            -0.012625993229448795,
            0.007800440303981304,
            -0.02502177469432354,
            0.04039692506194115,
            0.0039440942928195,
            -0.003150644712150097,
            0.02235592156648636,
            -0.007277143653482199,
            0.0195834431797266,
            0.0005307316314429045,
            0.008311404846608639,
            0.02058514393866062,
            0.003058218164369464,
            0.014261418022215366,
            -0.008527026511728764,
            0.0009344439022243023,
            0.031884677708148956,
            0.00635953526943922,
            0.004271170124411583,
            -0.003213308984413743,
            -0.023945963010191917,
            0.038770634680986404,
            0.02455822564661503,
            -0.02883831411600113,
            -0.022693736478686333,
            -0.0234475526958704,
            0.004444229882210493,
            0.009848668240010738,
            -0.001410281634889543,
            -0.038580264896154404,
            -0.016116885468363762,
            -0.015392128378152847,
            -0.021260730922222137,
            0.0048545910976827145,
            -0.02919885888695717,
            0.007989821955561638,
            0.005661939270794392,
            0.010932410135865211,
            0.05345762148499489,
            -0.009565305896103382,
            0.04587896168231964,
            -0.009817717596888542,
            0.013217988424003124,
            -0.02072194404900074,
            -0.017318911850452423,
            -0.01207112055271864,
            0.029651010408997536,
            -0.001585434889420867,
            0.029461251571774483,
            0.0429171547293663,
            -0.023680316284298897,
            -0.024350622668862343,
            -0.009453017264604568,
            -0.01462559588253498,
            0.009616685099899769,
            -0.0010074456222355366,
            -0.029057728126645088,
            -0.036663077771663666,
            -0.022401390597224236,
            0.026231249794363976,
            -0.017993630841374397,
            -0.02807288058102131,
            0.006891401018947363,
            -0.0007613130146637559,
            0.014914631843566895,
            -0.0087822824716568,
            0.013216981664299965,
            0.0023388878908008337,
            -0.03599720075726509,
            0.04254202917218208,
            0.009336888790130615,
            0.05622150003910065,
            -0.012521019205451012,
            -0.041052401065826416,
            0.02828984707593918,
            -0.03225770965218544,
            -0.026346715167164803,
            -0.04102862626314163,
            -0.008536952547729015,
            0.022893978282809258,
            0.004657265264540911,
            -0.018425311893224716,
            -0.022720517590641975,
            0.012026500888168812,
            -0.013751931488513947,
            0.021089717745780945,
            -0.017372814938426018,
            0.035845592617988586,
            -0.004158291034400463,
            0.028379175812005997,
            -0.008522287011146545,
            0.015166489407420158,
            -0.02166595496237278,
            0.03720366209745407,
            0.00681547774001956,
            0.011663654819130898,
            0.0028622786048799753,
            0.011377939954400063,
            0.028390178456902504,
            -0.003279096679762006,
            -0.04876140505075455,
            -0.005210351664572954,
            -0.01283782534301281,
            0.028166750445961952,
            -0.024764033034443855,
            0.007084302604198456,
            0.04261122643947601,
            -0.01186357717961073,
            0.0043881675228476524,
            -0.01640661060810089,
            -0.016702448949217796,
            0.00734571972861886,
            0.028486568480730057,
            -0.008280272595584393,
            -0.004227923694998026,
            0.027239453047513962,
            -0.049890052527189255,
            0.045691292732954025,
            0.006897991057485342,
            0.007232119794934988,
            0.014021291397511959,
            0.004846497438848019,
            0.02488693594932556,
            0.013995553366839886,
            -0.012912733480334282,
            -0.035470493137836456,
            -0.030956540256738663,
            -0.0240239966660738,
            -0.008617764338850975,
            0.010834801942110062,
            0.006669019814580679,
            -0.008868491277098656,
            -0.003430737415328622,
            -0.028247669339179993,
            -0.003054172033444047,
            -0.03261725977063179,
            -0.03660334274172783,
            0.003613406093791127,
            -0.025045320391654968,
            0.011062508448958397,
            -0.03330229967832565,
            0.006904639303684235,
            -0.02633405104279518,
            0.024548383429646492,
            -0.0025929647963494062,
            -0.012539070099592209,
            -0.011679740622639656,
            -0.006691116373986006,
            0.015347745269536972,
            -0.03943415731191635,
            -0.0023755477741360664,
            -0.015413989312946796,
            0.021454976871609688,
            -0.01911269687116146,
            -0.014112837612628937,
            0.021976111456751823,
            -0.025119997560977936,
            0.059509407728910446,
            0.02851484902203083,
            0.017559239640831947,
            0.011218158528208733,
            -0.033519841730594635,
            0.0170134324580431,
            0.021516263484954834,
            0.044926658272743225,
            -0.02176845632493496,
            -0.011987347155809402,
            -0.003597044153138995,
            0.004366430453956127,
            0.036227136850357056,
            -0.045712679624557495,
            0.0050955843180418015,
            -0.01813066564500332,
            -0.019844552502036095,
            -0.004605189897119999,
            -0.00623024208471179,
            0.02486245334148407,
            0.019903816282749176,
            0.0027537294663488865,
            0.04791068658232689,
            -0.041035424917936325,
            0.01545063965022564,
            0.04927040636539459,
            0.005558633711189032,
            0.0011113258078694344,
            -0.0028347880579531193,
            -0.024866988882422447,
            0.011900554411113262,
            -0.013747901655733585,
            0.04071735218167305,
            -0.035940662026405334,
            -0.025950603187084198,
            -0.006585046648979187,
            -0.029037943109869957,
            0.016220156103372574,
            -0.017166724428534508,
            0.012995904311537743,
            -0.022640297189354897,
            -0.05090053007006645,
            -0.0016313883243128657,
            -0.009413857944309711,
            -0.005989951081573963,
            0.005278786178678274,
            0.0070722526870667934,
            -0.01636815257370472,
            0.015427306294441223,
            0.029545016586780548,
            -0.0003904505865648389,
            -0.0068862708285450935,
            0.03861037641763687,
            0.037017904222011566,
            0.00861289817839861,
            0.007271613460034132,
            -0.008748024702072144,
            0.0253442395478487,
            0.010129239410161972,
            -0.006235971115529537,
            0.010908707976341248,
            0.00766458036378026,
            -0.00789911299943924,
            -0.0534013994038105,
            -0.01792256534099579,
            -0.02052413858473301,
            -0.06726283580064774,
            -0.036304738372564316,
            -0.017004838213324547,
            0.06498546153306961,
            0.03621505945920944,
            -0.012130249291658401,
            -0.001564672915264964,
            0.004263211507350206,
            0.013639407232403755,
            -0.0023699174635112286,
            -0.0296177938580513,
            -0.011340335011482239,
            -0.03860577940940857,
            -0.005521418061107397,
            0.019001878798007965,
            0.07529653608798981,
            -0.04729687422513962,
            -0.005310610868036747,
            0.008834448643028736,
            -0.024652155116200447,
            -0.04902984946966171,
            0.03866752237081528,
            -0.03681764751672745,
            -0.018792124465107918,
            -0.029261378571391106,
            0.03669076785445213,
            -0.022521499544382095,
            0.016199704259634018,
            0.023337319493293762,
            0.07238995283842087,
            -0.0279807411134243,
            0.015794476494193077,
            0.03333946317434311,
            -0.0016588822472840548,
            0.0314527191221714,
            0.018335910513997078,
            -0.02025226131081581,
            -0.0026133686769753695,
            -0.041822969913482666,
            -0.028759678825736046,
            -0.006734611000865698,
            -0.011974665336310863,
            0.025763120502233505,
            -0.0307789109647274,
            -0.005726591218262911,
            -0.03896528482437134,
            0.022544652223587036,
            -0.010776064358651638,
            0.03727973252534866,
            -0.011360465548932552,
            -0.02234296128153801,
            -0.03746774047613144,
            -0.01844978891313076,
            0.032482028007507324,
            -0.01117248646914959,
            0.03466585651040077,
            0.016621321439743042,
            -0.008829989470541477,
            0.024108711630105972,
            0.008116528391838074,
            -0.07476220279932022,
            0.01760515756905079,
            -0.011512910947203636,
            -0.0011165884789079428,
            0.057059381157159805,
            -0.02844538725912571,
            -0.04199647158384323,
            -0.010580774396657944,
            -0.03256505727767944,
            0.003520755795761943,
            -0.006285152863711119,
            0.0016568974824622273,
            0.005297654774039984,
            0.03671581298112869,
            0.011574667878448963,
            -0.018743325024843216,
            0.0008677791920490563
        ],
        [
            -0.015794413164258003,
            0.037759240716695786,
            -0.011296099983155727,
            0.010633602738380432,
            0.01842399686574936,
            0.026209156960248947,
            0.002197271678596735,
            -0.004919057711958885,
            0.015052860602736473,
            -0.05538491904735565,
            0.036408837884664536,
            -0.03036954440176487,
            0.0031277297530323267,
            0.03690808266401291,
            0.01183946244418621,
            -0.005684651900082827,
            0.014929554425179958,
            -0.02500569075345993,
            0.02426842413842678,
            -0.01346655748784542,
            0.03596733510494232,
            -0.021781165152788162,
            -0.013924608007073402,
            -0.010096076875925064,
            0.016927089542150497,
            -0.022718705236911774,
            -0.017764389514923096,
            -0.03572709858417511,
            -0.01798757165670395,
            -0.005824796389788389,
            0.03283095359802246,
            -0.007399311289191246,
            -0.02564755454659462,
            0.02756742388010025,
            0.001549677923321724,
            0.003934674896299839,
            0.018540339544415474,
            -0.016092484816908836,
            -0.004476429894566536,
            0.0271567665040493,
            0.027895240113139153,
            -0.03261145204305649,
            -0.03137889504432678,
            0.021061979234218597,
            0.02911023423075676,
            -0.02068950980901718,
            0.009462854824960232,
            0.008608398027718067,
            0.041257020086050034,
            0.03239137679338455,
            -0.016524355858564377,
            0.015364401042461395,
            0.01281750202178955,
            -0.02802971936762333,
            0.001716559985652566,
            0.011121788993477821,
            -0.006213095039129257,
            0.002018399303779006,
            -0.009331487119197845,
            -0.02957153506577015,
            -0.012790991924703121,
            -0.012803049758076668,
            -0.008161335252225399,
            0.006200416013598442,
            0.0037397388368844986,
            0.03550640866160393,
            0.018149906769394875,
            0.017414964735507965,
            -0.02069677785038948,
            -0.024339264258742332,
            -0.03767857328057289,
            -0.012129232287406921,
            -0.011506074108183384,
            -0.003590453416109085,
            -0.004375895485281944,
            0.0065050735138356686,
            -0.02429358661174774,
            -0.012413380667567253,
            -0.016898293048143387,
            -0.03173185884952545,
            -0.03850897401571274,
            7.506003021262586e-05,
            -0.029547173529863358,
            -0.0014425001572817564,
            -0.003268816042691469,
            0.0033947895281016827,
            -0.026543352752923965,
            -0.016385458409786224,
            -0.017697598785161972,
            0.0031841997988522053,
            -0.026184093207120895,
            0.034118395298719406,
            0.007768802344799042,
            -0.04197615385055542,
            -0.0179947167634964,
            0.008299262262880802,
            0.017915086820721626,
            0.012230304069817066,
            -0.0513889417052269,
            -0.020299604162573814,
            0.020287474617362022,
            0.04345255345106125,
            0.03948042169213295,
            -0.027422945946455002,
            -0.025614798069000244,
            0.02601822465658188,
            -0.011953667737543583,
            0.00782039389014244,
            -0.0266283992677927,
            0.010909232310950756,
            0.013270674273371696,
            0.04909706860780716,
            -0.025676267221570015,
            0.010262991301715374,
            0.016169751062989235,
            -0.014244160614907742,
            -0.015311714261770248,
            0.006152365356683731,
            0.021084262058138847,
            -0.018819080665707588,
            -0.0077419523149728775,
            -0.028797516599297523,
            -0.03250759840011597,
            0.018052369356155396,
            0.03928734362125397,
            -0.014064492657780647,
            0.0030607315711677074,
            0.0011890300083905458,
            0.026498349383473396,
            0.02944762632250786,
            0.0005809536087326705,
            -0.005344062112271786,
            -0.004393660929054022,
            0.005197911523282528,
            0.02009422332048416,
            -0.018647106364369392,
            0.004992659669369459,
            0.028506936505436897,
            0.015313491225242615,
            0.02313411422073841,
            -0.021863674744963646,
            0.004792023450136185,
            0.0001528203720226884,
            0.02715115062892437,
            0.04007558152079582,
            0.029694415628910065,
            -0.0015152556588873267,
            0.014435304328799248,
            0.0272684283554554,
            0.0061934166587889194,
            0.022706957533955574,
            0.005659737158566713,
            -0.000594140263274312,
            0.023818127810955048,
            0.03859814628958702,
            0.00966537557542324,
            0.0259512010961771,
            0.03812025859951973,
            -0.03819897025823593,
            0.023472653701901436,
            -0.024000383913517,
            -0.0054245516657829285,
            0.006463967729359865,
            0.02463456802070141,
            0.020766334608197212,
            -0.00035582372220233083,
            0.019541600719094276,
            0.021022988483309746,
            -0.0011681915493682027,
            -0.0050335959531366825,
            -0.019962767139077187,
            -0.005395499523729086,
            -0.03592073544859886,
            -0.0075439149513840675,
            0.04397451877593994,
            0.01723673939704895,
            -0.003969413693994284,
            -0.0027196258306503296,
            0.01878427155315876,
            0.05339573323726654,
            -0.0007966247503645718,
            0.0031306371092796326,
            -0.03324228152632713,
            -0.008617942221462727,
            -0.002995712449774146,
            0.008982792496681213,
            -0.0005894196801818907,
            -0.025588341057300568,
            -0.030359093099832535,
            0.021496713161468506,
            -0.01658306084573269,
            -0.006168679799884558,
            -0.0328921377658844,
            -0.014588870108127594,
            -0.005729593336582184,
            0.0054935673251748085,
            0.007350987754762173,
            -0.020451853051781654,
            -0.019068308174610138,
            -0.023754075169563293,
            -0.010620759800076485,
            -0.016234664246439934,
            0.03076966106891632,
            -0.026197727769613266,
            -0.055909160524606705,
            0.03903178125619888,
            -0.029573244974017143,
            -0.012527023442089558,
            0.027305791154503822,
            0.02949773520231247,
            -0.02528792805969715,
            0.003878985298797488,
            -0.001138424384407699,
            0.014291869476437569,
            0.005704923998564482,
            -0.008110929280519485,
            0.025831332430243492,
            0.02035139873623848,
            -0.0004744142643176019,
            0.02101723849773407,
            -0.005234391428530216,
            -0.027167681604623795,
            0.0076692793518304825,
            -0.04038575291633606,
            0.0035411447752267122,
            0.008612721227109432,
            -0.004620868247002363,
            0.01416759192943573,
            0.024726124480366707,
            0.03365679830312729,
            0.018931163474917412,
            -0.02001996338367462,
            -0.0031182151287794113,
            -0.030825620517134666,
            0.012822037562727928,
            -0.009784523397684097,
            0.040214456617832184,
            0.00956811010837555,
            0.012672294862568378,
            0.0032472535967826843,
            -0.005740394350141287,
            0.020632192492485046,
            0.011128593236207962,
            0.02990059182047844,
            0.009220887906849384,
            0.02163957618176937,
            0.022554516792297363,
            0.02989923767745495,
            -0.028078898787498474,
            0.04176802933216095,
            -0.036271557211875916,
            0.0031043612398207188,
            -0.03044128231704235,
            -0.009999428875744343,
            -0.02355167828500271,
            -0.0040297782979905605,
            -0.00671827606856823,
            0.02088303118944168,
            -0.024573417380452156,
            0.015285315923392773,
            0.022245805710554123,
            0.018893297761678696,
            -0.015556398779153824,
            0.0013528583804145455,
            -0.03202670067548752,
            0.0171936247497797,
            -0.003871326334774494,
            0.015510443598031998,
            0.012562322430312634,
            0.036230992525815964,
            -0.0004873124707955867,
            -0.014120124280452728,
            -0.004270053002983332,
            -0.018858110532164574,
            -0.04486488923430443,
            -0.038919951766729355,
            -0.005773189477622509,
            -0.015840701758861542,
            -0.0048723770305514336,
            0.010034607723355293,
            -0.009593548253178596,
            0.010057191364467144,
            0.016910569742321968,
            -0.0005207288777455688,
            0.015088632702827454,
            0.038960427045822144,
            0.000161200892762281,
            -0.008633112534880638,
            -0.014664708636701107,
            0.008340229280292988,
            -0.012265860103070736,
            -0.0089180339127779,
            -0.00932244211435318,
            0.0018773030024021864,
            -0.006525690201669931,
            0.030138207599520683,
            -0.004940338432788849,
            -0.013553887605667114,
            -0.004200927913188934,
            0.009626947343349457,
            0.004831057041883469,
            -0.011354992166161537,
            0.0024517627898603678,
            -0.02579846791923046,
            -0.03129385784268379,
            -0.048703741282224655,
            0.021966271102428436,
            0.028636569157242775,
            -0.033324453979730606,
            0.02267380617558956,
            -0.03185950219631195,
            0.01718084327876568,
            -0.009990430437028408,
            -0.0009572117705829442,
            0.01427898183465004,
            0.04627247527241707,
            -0.01113449689000845,
            0.0021621081978082657,
            -0.015018422156572342,
            0.02578066475689411,
            -0.009069900028407574,
            -0.018128031864762306,
            -0.023012714460492134,
            0.054902177304029465,
            0.01389702595770359,
            -0.015134651213884354,
            0.04421774670481682,
            -0.023964742198586464,
            -0.00393508467823267,
            -0.015224941074848175,
            0.03373400494456291,
            -0.0008128898916766047,
            0.001366998883895576,
            -0.034448254853487015,
            0.00423769373446703,
            -0.0060479179956018925,
            0.02616935968399048,
            -0.009077798575162888,
            -0.029289815574884415,
            0.024183042347431183,
            -0.01849747821688652,
            0.010912452824413776,
            -0.035314373672008514,
            0.02158416248857975,
            0.00047150845057331026,
            0.008685666136443615,
            -0.008110787719488144,
            -0.02158558927476406,
            0.01505576353520155,
            -0.011328362859785557,
            0.00035038794158026576,
            0.017387691885232925,
            0.019244030117988586,
            0.0323001854121685,
            -0.006560145411640406,
            -0.008923911489546299,
            0.026194799691438675,
            -0.03651048243045807,
            0.03130144625902176,
            -0.0040008025243878365,
            -0.0018152574775740504,
            0.020276203751564026,
            0.04635889455676079,
            0.0012710189912468195,
            -0.07059665769338608,
            0.011861225590109825,
            -0.025610851123929024,
            -0.00045159473665989935,
            -0.013273302465677261,
            -0.007295897696167231,
            0.028398143127560616,
            0.009622530080378056,
            0.005017747636884451,
            -0.008529309183359146,
            0.02309776097536087,
            0.03853049874305725,
            -0.02755238302052021,
            0.018106618896126747,
            -0.01855274848639965,
            0.004811776801943779,
            0.020537134259939194,
            -0.01841198466718197,
            -0.03159162774682045,
            -0.032743267714977264,
            -0.017841340973973274,
            -0.04265279695391655,
            -0.020482243970036507,
            -0.02965548262000084,
            -0.010555949993431568,
            -0.009129202924668789,
            -0.0020168146584182978,
            -0.010669237934052944,
            0.03054647520184517,
            0.031222935765981674,
            0.04126765578985214,
            -0.03286481276154518,
            -0.010607200674712658,
            0.0342940092086792,
            -0.001287537976168096,
            0.00033098040148615837,
            0.03997618332505226,
            0.02318008430302143,
            -0.0105111263692379,
            -0.019670573994517326,
            -0.020726660266518593,
            0.020291171967983246,
            0.020666731521487236,
            0.007555292919278145,
            0.009043505415320396,
            0.014909928664565086,
            0.030734261497855186,
            -0.005216092336922884,
            -0.007205560803413391,
            -0.036668598651885986,
            0.02012750692665577,
            0.0331001803278923,
            -0.04702504351735115,
            -0.02385421097278595,
            0.001750621828250587,
            -0.005849865265190601,
            0.014871644787490368,
            -0.03190627321600914,
            0.008497499860823154,
            0.02738816663622856,
            0.039078328758478165,
            0.0327179990708828,
            -0.020178522914648056,
            0.04157548025250435,
            -0.028836330398917198,
            -0.017169125378131866,
            -0.035299189388751984,
            -0.02547367475926876,
            -0.025591766461730003,
            0.024456733837723732,
            0.00021727057173848152,
            -0.012626918032765388,
            -0.021456919610500336,
            -0.0030236768070608377,
            -0.004647571127861738,
            -0.007211155258119106,
            -0.008245407603681087,
            -0.017916548997163773,
            0.011543517000973225,
            0.022637028247117996,
            0.0011463143164291978,
            -0.0038947705179452896,
            0.024194147437810898,
            -0.06741955876350403,
            0.016385309398174286,
            0.026209162548184395,
            0.012414630502462387,
            0.004932685289531946,
            -0.029842562973499298,
            -0.00814131461083889,
            -0.015345682390034199,
            0.02385481633245945,
            -0.006123141385614872,
            -0.033598341047763824,
            0.013184873387217522,
            0.0013358513824641705,
            0.012887394055724144,
            -0.03086872212588787,
            0.020634328946471214,
            0.004125291481614113,
            0.01741074211895466,
            0.023889129981398582,
            0.022267773747444153,
            -0.011329608038067818,
            -0.02951226383447647,
            0.03230071812868118,
            0.028957972303032875,
            -0.00820158701390028,
            0.025820886716246605,
            0.008460283279418945,
            0.004068028647452593,
            0.006627812050282955,
            0.02528068795800209,
            0.030988801270723343,
            -0.03014953061938286,
            0.010461367666721344,
            -0.004505650140345097,
            -0.028795216232538223,
            -0.019558273255825043,
            0.021772831678390503,
            0.03894970193505287,
            -0.0063364882953464985,
            0.004408570937812328,
            0.0077522872015833855,
            0.03969908878207207,
            0.026118572801351547,
            0.003948667552322149,
            0.007020913530141115,
            0.01640760339796543,
            0.010523064993321896,
            -0.018328189849853516,
            -0.030936580151319504,
            -0.0108468197286129,
            0.00434342585504055,
            -0.013398092240095139,
            0.021783988922834396,
            0.036005742847919464,
            0.012832078151404858,
            0.019335497170686722,
            0.03954607620835304,
            0.014132427982985973,
            0.025478385388851166,
            0.0022030542604625225,
            -0.018231064081192017,
            -0.030240725725889206,
            0.0018945764750242233,
            -0.015862291678786278,
            0.003223016858100891,
            0.015652291476726532,
            0.032247673720121384,
            0.012431783601641655,
            -0.029281046241521835,
            -0.007262344006448984,
            0.020156417042016983,
            0.039030954241752625,
            0.02371474914252758,
            0.002688154811039567,
            0.013675494119524956,
            0.02203814499080181,
            -0.012722732499241829,
            -0.018820080906152725,
            0.008356326259672642,
            0.008068999275565147,
            -0.01599649339914322,
            -0.0044670095667243,
            0.03436504304409027,
            0.027697915211319923,
            0.010440655052661896,
            -0.025881187990307808,
            -0.023857776075601578,
            -0.007642640732228756,
            -0.0020119240507483482,
            -0.013705008663237095,
            0.00011242715845583007,
            -0.014766352251172066,
            0.01011000107973814,
            -0.025417394936084747,
            -0.006757712922990322,
            -0.01907293125987053,
            -0.048082612454891205,
            0.003975060302764177,
            -0.06510705500841141,
            -0.0032553139608353376,
            0.029994094744324684,
            0.010578267276287079,
            0.002463114680722356,
            -0.010123140178620815,
            -0.0073196724988520145,
            0.011307488195598125,
            -0.005604705773293972,
            -0.035701144486665726,
            0.009837666526436806,
            -0.021189790219068527,
            0.02760251611471176,
            0.004279098007827997,
            0.004918316844850779,
            -0.02589559555053711,
            -0.022021541371941566,
            -0.027356691658496857,
            0.028638239949941635,
            0.009448155760765076,
            0.013718193396925926,
            0.013914762996137142,
            -0.00048615990090183914,
            0.003727070987224579,
            0.01153020840138197,
            0.02933642826974392,
            0.020978979766368866,
            -0.02384965866804123,
            0.010315225459635258,
            -0.025400657206773758,
            -0.0059233782812952995,
            -0.00971782486885786,
            -0.010960057377815247,
            -0.002923864871263504,
            0.009907200001180172,
            0.007679648231714964,
            0.04215426370501518,
            0.04802234470844269,
            0.01837446168065071,
            -0.01625308394432068,
            0.017509251832962036,
            -0.03138278052210808,
            -0.008358430117368698,
            -0.03146100044250488,
            -0.020927133038640022,
            -0.0022796171251684427,
            0.01768973283469677,
            -0.014989454299211502,
            0.006041029933840036,
            -0.009174187667667866,
            0.013471066020429134,
            -0.0055425651371479034,
            0.03390761837363243,
            -0.0014770212583243847,
            -0.020806463435292244,
            0.010419932194054127,
            0.004485008772462606,
            -0.03173193335533142,
            9.98928735498339e-05,
            -0.026507636532187462,
            -9.911035158438608e-05,
            -0.010589131154119968,
            -0.010896877385675907,
            -0.00992006529122591,
            -0.005416359286755323,
            -0.023723796010017395,
            0.032200295478105545,
            0.011489491909742355,
            0.02902926877140999,
            -0.0058680083602666855,
            0.023231912404298782,
            0.0010786015773192048,
            0.02902449294924736,
            -0.02420785278081894,
            0.023372313007712364,
            0.032385118305683136,
            0.012726624496281147,
            -0.02638477087020874,
            -0.00562447402626276,
            -0.006415682379156351,
            0.022665197029709816,
            -0.014337923377752304,
            -0.013245138339698315,
            0.002795925596728921,
            -0.021408168599009514,
            -0.0151865528896451,
            0.018759092316031456,
            0.01488659717142582,
            0.0119968606159091,
            0.003805208718404174,
            0.006949052680283785,
            -0.0027154034469276667,
            -0.028505535796284676,
            0.019084062427282333,
            -0.005037413444370031,
            0.03180767223238945,
            0.029409371316432953,
            0.030603734776377678,
            -0.033990271389484406,
            -0.00895396713167429,
            0.007000379730015993,
            0.0017960554687306285,
            -0.004114963114261627,
            0.006222090218216181,
            0.030744969844818115,
            -0.005021193064749241,
            -0.008854311890900135,
            -0.016125110909342766,
            -0.0017068940214812756,
            -0.004703053738921881,
            -0.01505647599697113,
            0.007965209893882275,
            -0.010750728659331799,
            0.025632895529270172,
            -0.025384526699781418,
            -0.041313476860523224,
            0.03032367303967476,
            -0.03067692182958126,
            0.034477390348911285,
            0.0021130447275936604,
            -0.016560077667236328,
            -0.005749075207859278,
            0.00831369124352932,
            0.027759846299886703,
            -0.008529829792678356,
            0.003894760273396969,
            -0.016965167596936226,
            0.024118896573781967,
            -0.059261977672576904,
            0.001364170340821147,
            0.03512516990303993,
            -0.03033982403576374,
            -0.0012897588312625885,
            -0.02373254857957363,
            0.009485437534749508,
            -0.004389164503663778,
            0.020622914656996727,
            0.003755499143153429,
            -0.01086229644715786,
            0.03409354016184807,
            -0.01481711771339178,
            0.004149890970438719,
            0.015125945210456848,
            0.00833444856107235,
            -0.002959879580885172,
            -0.025640109553933144,
            -0.040725503116846085,
            -0.01218976266682148,
            -0.03422044962644577,
            -0.017720982432365417,
            -0.024607926607131958,
            0.01856370083987713,
            0.002476815367117524,
            -0.0009887681808322668,
            -0.01688413880765438,
            0.03204910457134247,
            0.03694702684879303,
            0.0036134589463472366,
            0.008866697549819946,
            0.045779045671224594,
            -0.007249280344694853,
            0.03011615388095379,
            -0.009694582782685757,
            0.03900560736656189,
            -0.015069421380758286,
            -0.031257662922143936,
            -0.0008925573783926666,
            0.018345966935157776,
            -0.023404086008667946,
            0.003351307474076748,
            0.027897698804736137,
            -0.02229904569685459,
            0.009759806096553802,
            0.025085877627134323,
            0.028985843062400818,
            0.006988817360252142,
            -0.025686636567115784,
            0.03724066540598869,
            0.041996125131845474,
            -0.012856733985245228,
            0.01708332635462284,
            -0.0044481223449110985,
            -0.017132963985204697,
            -0.01586192660033703,
            0.032373085618019104,
            0.021133633330464363,
            0.0034525934606790543,
            -0.014533626846969128,
            0.025794753804802895,
            -0.02116796374320984,
            0.026911988854408264,
            -0.03436217084527016,
            0.008623632602393627,
            0.0492924340069294,
            0.0030711269937455654,
            0.01940985582768917,
            -0.034046050161123276,
            -0.01737998053431511,
            0.03282240033149719,
            0.001369092264212668,
            -0.034277401864528656,
            0.07827935367822647,
            -0.014185084030032158,
            -0.010825580917298794,
            0.0183713361620903,
            0.02555066905915737,
            0.005768094677478075,
            0.0009980384493246675,
            0.024975338950753212,
            -0.01758391782641411,
            -0.002831342862918973,
            -0.011770649813115597,
            0.037143535912036896,
            0.033413875848054886,
            0.014221186749637127,
            0.049816057085990906,
            -0.036871690303087234,
            0.01995144411921501,
            -0.04658094793558121,
            0.012400888837873936,
            0.002414202783256769,
            0.016711372882127762,
            0.029693428426980972,
            0.013599220663309097,
            0.04151235148310661,
            0.006613861303776503,
            0.04396020621061325,
            0.01563292369246483,
            -0.02991091087460518,
            -0.029578108340501785,
            0.01648501306772232,
            0.011453287675976753,
            0.0010048162657767534,
            -0.018413133919239044,
            0.009230813011527061,
            -0.00020086116273887455,
            0.01664285734295845,
            -0.029022635892033577,
            0.01276935450732708,
            0.008316070772707462,
            0.0062009613029658794,
            0.035119250416755676,
            0.006092192605137825,
            -0.03325524553656578,
            0.002783843781799078,
            0.04994184151291847,
            -0.031376514583826065,
            -0.006775692570954561,
            0.015459001064300537,
            -0.06563156098127365,
            -0.001674860599450767,
            0.012037756852805614,
            -0.029369018971920013,
            0.0271245576441288,
            0.01041338685899973,
            0.021356919780373573,
            0.019685188308358192,
            -0.02170584909617901,
            -0.0002458300441503525,
            0.019819607958197594,
            -0.005682674236595631,
            0.0008920786203816533,
            0.013684280216693878,
            0.026712879538536072,
            0.020288940519094467,
            -0.038911666721105576,
            -0.019768433645367622,
            -0.03609312325716019,
            0.005886359605938196,
            -0.01661830022931099,
            -0.017076918855309486,
            -0.014926872216165066,
            0.038964301347732544,
            0.012631530873477459,
            -0.0506138913333416,
            -0.0031303565483540297,
            0.007514819968491793,
            0.015952182933688164,
            0.0032235323451459408,
            -0.026574667543172836,
            -0.02273450419306755,
            -0.010694976896047592,
            -0.018725242465734482,
            -0.01940890960395336,
            -0.015305673703551292,
            -0.0007823584019206464,
            0.013812217861413956,
            0.008360812440514565,
            -0.0032508959993720055,
            0.005216123536229134,
            0.0014925204450264573,
            0.020623983815312386,
            -0.007199026178568602,
            -6.91636887495406e-05,
            -0.04443283751606941,
            -0.007961958646774292,
            -0.005896647926419973,
            -0.009750008583068848,
            0.010368282906711102,
            -0.019636940211057663,
            0.023858636617660522,
            0.03111250139772892,
            -0.015091631561517715,
            -0.024787670001387596,
            -0.04554799571633339,
            0.004627620801329613,
            0.03293716162443161,
            0.00743808364495635,
            -0.015788806602358818,
            0.034100376069545746,
            0.014876187779009342,
            -0.029323097318410873,
            0.024791410192847252,
            -0.001579442759975791,
            -0.006606698036193848,
            0.028749847784638405,
            -0.016243258491158485,
            -0.009818924590945244,
            -0.025710970163345337,
            0.037441276013851166,
            -0.00034497323213145137,
            0.004967947490513325,
            -0.0025244932621717453,
            0.011818686500191689,
            -0.01187097653746605,
            0.012977715581655502,
            -0.008249420672655106,
            -0.008640678599476814,
            -0.008691368624567986,
            0.025589684024453163,
            -0.0031376956030726433,
            0.014650855213403702,
            0.04733118414878845,
            0.027597064152359962,
            0.018361080437898636,
            -0.0028437278233468533,
            -0.006162135861814022,
            0.01989832893013954,
            -0.0344746969640255,
            -0.02962537854909897,
            0.016262421384453773,
            0.014014643616974354,
            -0.014496579766273499,
            0.011676912195980549,
            -0.03773847222328186,
            -0.056551773101091385,
            -0.007628838066011667,
            -0.04432901740074158,
            -0.03407588601112366,
            0.023000165820121765,
            0.013365336693823338,
            0.01673748716711998,
            -0.013701134361326694,
            -0.021986309438943863,
            0.0024729962460696697,
            0.027726419270038605,
            -0.01387413777410984,
            -0.00846680998802185,
            0.01942826807498932,
            -0.022199612110853195,
            0.005641091614961624,
            -0.0077782790176570415,
            -0.009611624293029308,
            0.018759382888674736,
            -0.028067296370863914,
            -0.007871020585298538,
            -0.0044322204776108265,
            -0.01992781274020672,
            0.02253749594092369,
            0.021636473014950752,
            -0.002475822577252984,
            -0.03542529046535492,
            0.006050815340131521,
            0.03023625910282135,
            0.016009684652090073,
            -0.01657826267182827,
            -0.011498297564685345,
            0.0024188647512346506,
            0.04518149420619011,
            -0.043748099356889725,
            -0.0022768161725252867,
            -0.020199470221996307,
            0.03655405342578888,
            -0.037779372185468674,
            -0.018340256065130234,
            -0.0230887271463871,
            0.018609950318932533,
            0.02426040917634964,
            0.008289104327559471,
            -0.007419299799948931,
            0.015334773808717728,
            0.016546308994293213,
            0.030841683968901634,
            0.024387594312429428,
            -0.05211535468697548,
            0.011639975942671299,
            0.005000979173928499,
            0.002415840979665518,
            -0.01075518224388361,
            0.02458972856402397,
            -0.015124461613595486,
            -0.010560975410044193,
            0.0016891593113541603,
            0.037112388759851456,
            -0.030689451843500137,
            0.049375686794519424,
            -0.021542295813560486,
            -0.019330644980072975,
            -0.04680025950074196,
            0.06483712047338486,
            -0.01976368948817253,
            0.014902336522936821,
            0.026184413582086563,
            -0.005522924475371838,
            -0.0072397589683532715,
            0.024282626807689667,
            -0.007211276795715094,
            -0.02734444849193096,
            0.007640250958502293,
            0.011688318103551865,
            0.026898832991719246,
            0.026486506685614586,
            -0.0181475467979908,
            -0.026766935363411903,
            0.02171279489994049,
            -0.01915101520717144,
            -0.019624317064881325,
            0.013202466070652008,
            -0.02554069459438324,
            -0.03625721484422684,
            0.010647818446159363,
            0.013269997201859951,
            -0.03259345889091492,
            0.0004605394206009805,
            0.00600043497979641,
            0.032656848430633545,
            0.011214882135391235,
            -0.05062606930732727,
            0.021207690238952637,
            0.03335723280906677,
            0.018767980858683586,
            0.0034861541353166103,
            0.008197987452149391,
            0.028349021449685097,
            0.010024402290582657,
            -0.0054908450692892075,
            -0.011013870127499104,
            -0.01959334872663021,
            0.028716273605823517,
            0.010446351952850819,
            -0.02661534957587719,
            0.011435853317379951,
            0.014543181285262108,
            0.023716527968645096,
            0.0003407566109672189,
            0.02531442604959011,
            -0.004143139813095331,
            -0.003923292737454176,
            0.008517079055309296,
            -0.03815724700689316,
            0.013704131357371807,
            -0.002179306698963046,
            0.015988381579518318,
            -0.0014514366630464792,
            -0.007200240157544613,
            -0.013372897170484066,
            0.010474246926605701,
            0.026649080216884613,
            0.01957702450454235,
            -0.00931389071047306,
            -0.0003298712836112827,
            -0.008012881502509117,
            0.014540464617311954,
            -0.016962861642241478,
            -0.007513961289077997,
            -0.0006563967326655984,
            0.02517884597182274,
            0.03720630705356598,
            -0.002849005162715912
        ],
        [
            -0.008831122890114784,
            0.02750227600336075,
            0.02587522380053997,
            0.03144015744328499,
            0.014981606975197792,
            -0.043275777250528336,
            -0.024483444169163704,
            -0.00466889888048172,
            0.014020010828971863,
            0.01060458179563284,
            -0.03564384952187538,
            -0.007331843487918377,
            0.025982841849327087,
            -0.0280900988727808,
            -0.027798812836408615,
            -0.030462009832262993,
            -0.01043862197548151,
            -0.010830518789589405,
            -0.03381117060780525,
            -0.018808111548423767,
            0.020048173144459724,
            0.019398575648665428,
            0.010087271220982075,
            -0.03721994161605835,
            0.015038002282381058,
            -0.03588215634226799,
            -0.02388812229037285,
            -0.018638717010617256,
            0.011069280095398426,
            -0.018866978585720062,
            0.021790485829114914,
            -0.00871670339256525,
            -0.006558686960488558,
            -0.004017817322164774,
            0.02239292301237583,
            0.009177879430353642,
            -0.023589467629790306,
            0.024345817044377327,
            -0.0013049400877207518,
            0.021779781207442284,
            0.029192941263318062,
            0.010564230382442474,
            0.009589030407369137,
            -0.011668914929032326,
            -0.003225814551115036,
            0.02972380444407463,
            -0.00464379508048296,
            -0.017757274210453033,
            -0.017201341688632965,
            0.02117462269961834,
            -0.02519678883254528,
            0.015511598438024521,
            0.012713473290205002,
            -0.008425476960837841,
            0.0012877348344773054,
            -0.021407049149274826,
            0.013557889498770237,
            -0.009385391138494015,
            0.015388448722660542,
            0.012554913759231567,
            0.021020347252488136,
            0.004998605232685804,
            0.03836749494075775,
            -0.02801608294248581,
            -0.023262539878487587,
            0.001617755857296288,
            0.036095600575208664,
            -0.005099947564303875,
            -0.0032901628874242306,
            -0.0008006900316104293,
            -0.023597711697220802,
            -0.018270619213581085,
            0.023853296414017677,
            -0.021179793402552605,
            0.038129616528749466,
            -0.016612591221928596,
            -0.00660068029537797,
            0.016425976529717445,
            0.02705317735671997,
            0.011215714737772942,
            -0.015218382701277733,
            -0.007694197352975607,
            -0.02352786250412464,
            -0.005096485372632742,
            -0.004480109084397554,
            -0.00480791088193655,
            0.0015186533564701676,
            -0.02593596652150154,
            0.036868710070848465,
            -0.01267594750970602,
            -0.00416137557476759,
            0.03463071957230568,
            -0.011115007102489471,
            0.009818547405302525,
            -0.002233651466667652,
            -0.005605795420706272,
            -0.015024883672595024,
            0.01751273311674595,
            0.08361391723155975,
            0.004192519001662731,
            -0.02279665321111679,
            0.005345542915165424,
            0.037657737731933594,
            -0.007790481671690941,
            0.032602835446596146,
            -0.028223656117916107,
            -0.025907117873430252,
            0.02452908828854561,
            0.022450676187872887,
            0.011836948804557323,
            -0.0031108432449400425,
            0.01681879721581936,
            0.00955068040639162,
            -0.020174365490674973,
            0.006350778974592686,
            -0.01281186193227768,
            0.019651655107736588,
            -0.02442101016640663,
            -0.03158532455563545,
            0.004816344007849693,
            0.00795249454677105,
            0.01677917316555977,
            0.010222191922366619,
            0.03238283470273018,
            -0.0037694864440709352,
            -0.0023238779976963997,
            0.017020296305418015,
            -0.0057447245344519615,
            -0.012473306618630886,
            0.021310433745384216,
            0.0011756442254409194,
            -0.007895341143012047,
            0.021985197439789772,
            0.0034581872168928385,
            -0.018356170505285263,
            -0.013643107376992702,
            0.0016551526496186852,
            -0.044272005558013916,
            -0.014530003070831299,
            -0.013402090407907963,
            0.03391163423657417,
            0.013728237710893154,
            -0.03390134125947952,
            -0.016811473295092583,
            0.02764854021370411,
            0.026394184678792953,
            0.032350875437259674,
            0.03778605908155441,
            -0.006207919213920832,
            0.01892845891416073,
            0.024030491709709167,
            -0.006073006894439459,
            0.02916380576789379,
            -0.014378566294908524,
            0.0026661588344722986,
            0.009122219868004322,
            0.025857534259557724,
            -0.00011828892456833273,
            0.006625335197895765,
            0.002229359932243824,
            -0.007685599382966757,
            0.01708907075226307,
            -0.0202779658138752,
            0.010513890534639359,
            0.024178773164749146,
            -0.02135206200182438,
            -0.035343799740076065,
            0.008025194518268108,
            0.006841219495981932,
            0.025630906224250793,
            -0.006525049917399883,
            -0.0014375477330759168,
            0.01586942933499813,
            -0.011858108453452587,
            -0.024578144773840904,
            -0.03658110275864601,
            0.010294887237250805,
            -0.010975684970617294,
            0.04656647890806198,
            0.009056230075657368,
            -0.023476891219615936,
            -0.027740977704524994,
            0.00081820652121678,
            -0.0002459729730617255,
            0.035256315022706985,
            0.02122511714696884,
            -0.013192353770136833,
            0.02240854687988758,
            0.0007703097071498632,
            -0.017557233572006226,
            0.003235000418499112,
            0.011858637444674969,
            -0.010728581808507442,
            0.0007794691482558846,
            0.01991754025220871,
            0.010450195521116257,
            -0.0299479179084301,
            -0.0119015509262681,
            -0.013917743228375912,
            -0.025354284793138504,
            0.027767404913902283,
            0.008410968817770481,
            0.027738390490412712,
            -0.008005856536328793,
            0.017252808436751366,
            -0.027297381311655045,
            -0.020518304780125618,
            0.02718677558004856,
            -0.01826733909547329,
            -0.002129514003172517,
            0.03201514482498169,
            -0.024065187200903893,
            -0.005770930554717779,
            0.015605139546096325,
            0.02208595909178257,
            0.025133991613984108,
            0.029077624902129173,
            0.03690953925251961,
            -0.004413621965795755,
            -0.009363396093249321,
            -0.01968526840209961,
            0.0170056764036417,
            0.018662989139556885,
            0.025400400161743164,
            -0.00798921100795269,
            -0.04295819625258446,
            0.015845471993088722,
            -0.005502010229974985,
            -0.018669281154870987,
            0.02874710224568844,
            -0.020440751686692238,
            0.003584576305001974,
            -0.007367084268480539,
            0.0019413097761571407,
            0.04909593611955643,
            0.027491740882396698,
            -0.014354569837450981,
            0.03217840567231178,
            -0.027841826900839806,
            0.026737533509731293,
            0.019732479006052017,
            0.018726609647274017,
            -0.02136421948671341,
            0.007526921108365059,
            0.0019515566527843475,
            0.015406779013574123,
            -0.001023915596306324,
            0.02088652364909649,
            0.024387748911976814,
            -0.013403750956058502,
            -0.018408330157399178,
            -0.01611349917948246,
            0.02552708238363266,
            -0.004610090982168913,
            -0.028893429785966873,
            0.01037124264985323,
            0.005670758429914713,
            -0.04980001971125603,
            0.0038778551388531923,
            0.015886425971984863,
            -0.008831134997308254,
            -0.02582530304789543,
            -0.0260536577552557,
            0.02937495708465576,
            0.010046531446278095,
            0.009369603358209133,
            -0.0007775758858770132,
            0.023842904716730118,
            0.028035389259457588,
            -0.005423284601420164,
            0.008897388353943825,
            0.010345584712922573,
            -0.009837681427598,
            -0.010945919901132584,
            0.024977685883641243,
            0.018093017861247063,
            0.021686993539333344,
            -0.006170621607452631,
            0.0034582556691020727,
            -0.015915555879473686,
            0.00619643647223711,
            -0.017402218654751778,
            0.0019129269057884812,
            -0.0039032220374792814,
            0.023085298016667366,
            0.016212796792387962,
            -0.0020494975615292788,
            -0.03644772991538048,
            0.017687242478132248,
            -0.012882336974143982,
            0.006270129233598709,
            0.02492464706301689,
            0.00266153528355062,
            0.004188003484159708,
            -0.007520249113440514,
            -0.006601399276405573,
            -0.0048035625368356705,
            0.017496176064014435,
            0.03870284929871559,
            0.02957160957157612,
            0.0032738943118602037,
            -0.004812788683921099,
            0.018063489347696304,
            -0.02609626203775406,
            0.017123304307460785,
            0.0024239039048552513,
            -0.019050458446145058,
            -0.030342545360326767,
            -0.03113763965666294,
            -0.0073150889948010445,
            -2.540616515034344e-05,
            -0.029551496729254723,
            0.03691210225224495,
            -0.01956297643482685,
            0.02901177480816841,
            -0.02485097013413906,
            0.027421532198786736,
            0.012417539022862911,
            0.016240036115050316,
            -0.015644779428839684,
            0.004721064120531082,
            -0.007211236283183098,
            0.02797003649175167,
            -0.007332568988204002,
            0.019229965284466743,
            0.024788210168480873,
            0.03272920846939087,
            0.008896786719560623,
            0.007041648495942354,
            0.020285915583372116,
            -0.02936588227748871,
            0.01317315362393856,
            0.039810653775930405,
            0.03321298211812973,
            0.011601117439568043,
            -0.026128552854061127,
            -0.000711098313331604,
            0.036727823317050934,
            0.016955465078353882,
            -0.02311955951154232,
            0.024695510044693947,
            -0.04931659996509552,
            0.019316500052809715,
            0.0163330789655447,
            0.004310126882046461,
            -0.04532124474644661,
            0.045467559248209,
            -0.009929756633937359,
            -0.024740271270275116,
            -0.009264019317924976,
            -0.019991718232631683,
            -0.00029186459141783416,
            -0.028248632326722145,
            -0.0005274537834338844,
            -0.04079621657729149,
            -0.02569711208343506,
            0.01395119447261095,
            -0.004882404115051031,
            -0.019929036498069763,
            0.022511951625347137,
            0.030999455600976944,
            -0.006877330597490072,
            0.007567057851701975,
            -0.030577965080738068,
            0.004804651252925396,
            -0.008135260082781315,
            -0.014965269714593887,
            0.02211449295282364,
            0.030020056292414665,
            -0.01735914871096611,
            0.005487031303346157,
            -0.011950585059821606,
            0.010377985425293446,
            -0.009907923638820648,
            0.015796078369021416,
            -0.01824214868247509,
            -0.02397797629237175,
            -0.029998699203133583,
            0.015457777306437492,
            -0.009159555658698082,
            0.008023815229535103,
            -0.020425109192728996,
            -0.011412974447011948,
            0.008683983236551285,
            -0.034590158611536026,
            0.028099752962589264,
            -0.023691615089774132,
            -0.009649296291172504,
            -0.009732266888022423,
            0.00032216907129622996,
            -0.014259117655456066,
            -0.021797165274620056,
            -0.01397507730871439,
            -0.0009757993393577635,
            -0.0036136230919510126,
            -0.004739578813314438,
            0.03517228364944458,
            -0.017490340396761894,
            -0.028118152171373367,
            -0.02652863599359989,
            -0.008462464436888695,
            -0.008685617707669735,
            -0.02133740670979023,
            -0.028883857652544975,
            0.003615539288148284,
            -0.02864665538072586,
            0.033708538860082626,
            0.026708250865340233,
            0.01090279035270214,
            -0.011048031970858574,
            0.0005057975649833679,
            0.00992079172283411,
            -0.005927097983658314,
            0.031135549768805504,
            -0.018974512815475464,
            -0.017166296020150185,
            0.013319243676960468,
            0.025378664955496788,
            -0.0024326774291694164,
            0.031683482229709625,
            -0.028445575386285782,
            0.012846915051341057,
            -0.001192868105135858,
            -0.007603376172482967,
            0.019481396302580833,
            0.040203679352998734,
            -0.005417514126747847,
            -0.0006274725310504436,
            0.02735532447695732,
            0.0004679419216699898,
            -0.005582151934504509,
            0.01678258180618286,
            -0.028583599254488945,
            0.012474366463720798,
            -0.005261315498501062,
            -0.007129427045583725,
            0.0034398529678583145,
            -0.023887813091278076,
            0.01953853666782379,
            -0.011060397140681744,
            -0.027567071840167046,
            -0.023308487609028816,
            0.029067834839224815,
            -0.014350815676152706,
            -0.02150975912809372,
            -0.012977438047528267,
            0.037958696484565735,
            0.06656777858734131,
            0.020062975585460663,
            0.006873199716210365,
            0.024064600467681885,
            -0.013121046125888824,
            -0.006394185591489077,
            0.034887827932834625,
            -0.006327342242002487,
            -0.032813552767038345,
            -0.020267026498913765,
            -0.027541643008589745,
            -0.011163500137627125,
            -0.026946889236569405,
            0.018357859924435616,
            0.00756053114309907,
            -0.009244089014828205,
            0.02713717147707939,
            -0.007542464416474104,
            0.030665330588817596,
            -0.02637428417801857,
            0.022242486476898193,
            0.021703405305743217,
            0.0003740921092685312,
            -0.0012299427762627602,
            0.010555373504757881,
            0.025822138413786888,
            -0.008439532481133938,
            -0.02024815045297146,
            0.0072755152359604836,
            0.01670130342245102,
            -0.013083425350487232,
            0.03534035384654999,
            -0.03402214124798775,
            0.005481867119669914,
            0.014896736480295658,
            0.0109553886577487,
            0.022507309913635254,
            0.038480255752801895,
            -0.02602796070277691,
            -0.008384312503039837,
            0.0326688252389431,
            0.04584088176488876,
            -0.021731605753302574,
            0.04088535159826279,
            0.03193775936961174,
            -0.008019358851015568,
            -0.029301710426807404,
            0.028634212911128998,
            0.0038277972489595413,
            0.002832945669069886,
            0.00022701291891280562,
            -0.0013728502672165632,
            0.028466586023569107,
            -0.0169863048940897,
            -0.0029468140564858913,
            0.03083142824470997,
            0.03759162500500679,
            -0.03228354454040527,
            -0.001599740469828248,
            0.018113411962985992,
            0.014524008147418499,
            -0.006352048367261887,
            -0.020933838561177254,
            -0.013266704976558685,
            -0.027690056711435318,
            -0.007879326120018959,
            0.0034655907656997442,
            0.04026022180914879,
            -0.028105471283197403,
            0.02629813179373741,
            0.010451030917465687,
            -0.0024711007717996836,
            0.01965148374438286,
            0.01740110106766224,
            -0.01546053122729063,
            0.010043752379715443,
            -0.011075769551098347,
            0.012212834320962429,
            0.009274175390601158,
            0.01966845989227295,
            -0.03978103771805763,
            0.007476661819964647,
            -0.008037339895963669,
            -0.0033304321113973856,
            -0.03451579436659813,
            0.010697642341256142,
            -0.00529820192605257,
            -0.0039113666862249374,
            0.018839271739125252,
            -0.013754311017692089,
            0.01657736860215664,
            0.01620676927268505,
            -0.023179206997156143,
            -0.0042385561391711235,
            0.03550780937075615,
            -0.01794927380979061,
            0.0595441572368145,
            0.02197275683283806,
            -0.016259122639894485,
            0.01772826723754406,
            -0.023774387314915657,
            -0.009592168033123016,
            0.011666996404528618,
            -0.0041554649360477924,
            0.0018397956155240536,
            -0.0077192531898617744,
            -0.028487911447882652,
            -0.036533452570438385,
            0.03625696897506714,
            -0.020817844197154045,
            0.004834742750972509,
            0.029577022418379784,
            0.015317562036216259,
            0.011722033843398094,
            -0.008069811388850212,
            0.03502688556909561,
            -0.015995709225535393,
            0.02487952634692192,
            0.006335417740046978,
            -0.011947231367230415,
            -0.024250544607639313,
            -0.022080937400460243,
            -0.0031307667959481478,
            0.005647914484143257,
            0.03133318945765495,
            0.025773314759135246,
            0.028215691447257996,
            -0.04273815453052521,
            0.009487086907029152,
            0.017838625237345695,
            0.005070410668849945,
            0.017649320885539055,
            0.019597046077251434,
            0.014419752173125744,
            -0.01693837158381939,
            0.03226391598582268,
            0.028063973411917686,
            0.0039402334950864315,
            0.029297320172190666,
            0.01930825039744377,
            0.03405395522713661,
            7.303651364054531e-05,
            0.004227850586175919,
            -0.028497304767370224,
            0.016688218340277672,
            -0.022916993126273155,
            -0.033370908349752426,
            0.022197267040610313,
            -0.013383520767092705,
            0.03969735652208328,
            -0.00664348853752017,
            0.025242717936635017,
            0.009774838574230671,
            0.011120078153908253,
            0.003509340574964881,
            0.02307279035449028,
            -0.028616292402148247,
            -0.007720796391367912,
            0.014598328620195389,
            0.019793519750237465,
            0.020075678825378418,
            -0.010951153934001923,
            -0.03752060979604721,
            0.0035337472800165415,
            0.00750508438795805,
            0.029489418491721153,
            -0.0008672505500726402,
            0.02404027059674263,
            0.021766703575849533,
            0.026075944304466248,
            -0.03266122564673424,
            -0.008016711100935936,
            -0.007944981567561626,
            0.0017779069021344185,
            -0.022511521354317665,
            -0.027618922293186188,
            0.02486727200448513,
            0.00965980812907219,
            -0.017044341191649437,
            0.01998857408761978,
            -0.021765314042568207,
            -0.024042103439569473,
            -0.0019078466575592756,
            -0.004755824338644743,
            -0.010588536038994789,
            0.020057590678334236,
            0.03470071032643318,
            0.02445281855762005,
            0.026266716420650482,
            -0.01378682255744934,
            0.023849887773394585,
            0.005980978719890118,
            0.008425258100032806,
            0.00270122941583395,
            -0.022074228152632713,
            -0.020628493279218674,
            -0.006081542931497097,
            0.012546321377158165,
            0.00073987030191347,
            0.02741127833724022,
            0.021090997382998466,
            -0.01685267686843872,
            0.00235917279496789,
            -0.0025287505704909563,
            0.015767943114042282,
            -0.0011553775984793901,
            0.014240892603993416,
            -0.02863079309463501,
            0.01935083419084549,
            -0.03326818719506264,
            0.012840227223932743,
            -0.0016642308328300714,
            -0.030745292082428932,
            -0.025046808645129204,
            -0.028545953333377838,
            -0.020695392042398453,
            0.019540634006261826,
            -0.021853461861610413,
            -0.010360706597566605,
            -0.02818642184138298,
            0.011311781592667103,
            0.002380767371505499,
            0.010527060367166996,
            0.017876407131552696,
            0.008282850496470928,
            -0.0013310565846040845,
            0.026235681027173996,
            0.009848522022366524,
            0.014085270464420319,
            0.02359597012400627,
            -0.011790175922214985,
            -0.003914478700608015,
            -0.011241361498832703,
            -0.007363856770098209,
            -0.016618456691503525,
            -0.00638717832043767,
            0.010135222226381302,
            -0.022619172930717468,
            0.011632787063717842,
            -0.044354669749736786,
            0.04431577026844025,
            0.03279988467693329,
            -0.011324422433972359,
            0.016341261565685272,
            0.000904593791346997,
            0.0054343813098967075,
            0.016079485416412354,
            0.005867294501513243,
            -0.02009805478155613,
            0.01631823740899563,
            -0.024151112884283066,
            -0.008101575076580048,
            0.016160117462277412,
            -0.01916053704917431,
            0.017698826268315315,
            -0.019829759374260902,
            -0.02546597085893154,
            -0.02677823230624199,
            -0.002898218110203743,
            -0.009820054285228252,
            0.01853160187602043,
            -0.0248127281665802,
            0.014109695330262184,
            -0.012934774160385132,
            0.032013580203056335,
            -0.00600090529769659,
            -0.0029666770715266466,
            -0.028859402984380722,
            0.006838038098067045,
            0.0013744889292865992,
            0.005440529435873032,
            -0.019360678270459175,
            -0.026920093223452568,
            0.010679520666599274,
            0.008692027069628239,
            0.013540767133235931,
            0.030122336000204086,
            0.01253811176866293,
            0.015601929277181625,
            0.005798344500362873,
            -0.013003014959394932,
            0.008036220446228981,
            -0.006786417681723833,
            0.03422855958342552,
            0.033982113003730774,
            -0.009016572497785091,
            0.02531619742512703,
            0.016338154673576355,
            -0.011827881447970867,
            0.0012928363867104053,
            0.016544964164495468,
            0.009434239938855171,
            -0.023982824757695198,
            0.023890573531389236,
            0.0017262791516259313,
            -0.028099486604332924,
            -0.013078617863357067,
            -0.015535969287157059,
            -0.01977982372045517,
            0.020475514233112335,
            0.02469911240041256,
            -0.0057699549943208694,
            0.011409340426325798,
            0.025514524430036545,
            0.012474722228944302,
            0.029475724324584007,
            -0.003789166221395135,
            0.024200240150094032,
            -0.028275126591324806,
            -0.015312599018216133,
            -0.028639482334256172,
            0.02107072062790394,
            -0.013305474072694778,
            0.0013439346803352237,
            0.023540787398815155,
            0.015976211056113243,
            0.007693946361541748,
            -0.004747571423649788,
            0.020164892077445984,
            -0.01196239423006773,
            0.03197765350341797,
            0.0192651879042387,
            -0.0037937695160508156,
            0.003097223350778222,
            -0.017721151933073997,
            -0.013681103475391865,
            0.014967769384384155,
            -0.0279729962348938,
            -0.03413451090455055,
            -0.0030998080037534237,
            -0.009737959131598473,
            0.01762998290359974,
            0.01814936101436615,
            -0.01884542405605316,
            -0.014747556298971176,
            0.04092764854431152,
            0.02664252370595932,
            -0.01759374514222145,
            0.014367489144206047,
            -0.02262723445892334,
            0.016430258750915527,
            0.0011923025595024228,
            0.03083731420338154,
            -0.014769205823540688,
            0.001367824268527329,
            -0.013600919395685196,
            -0.018590129911899567,
            0.03889596089720726,
            0.013425871729850769,
            0.009706511162221432,
            -0.004847926553338766,
            -0.009981976822018623,
            0.004798322916030884,
            0.0010544544784352183,
            0.030512703582644463,
            0.000520193949341774,
            0.023550143465399742,
            0.010127564892172813,
            -0.03392935171723366,
            -0.00995443481951952,
            0.03884114325046539,
            0.011579685844480991,
            -0.04812981188297272,
            0.006001179572194815,
            0.04282628372311592,
            -0.0048673865385353565,
            -0.0028797867707908154,
            0.009206545539200306,
            0.0015574536519125104,
            -0.011315899901092052,
            0.0334244966506958,
            -0.023332631215453148,
            -0.0330476351082325,
            -0.014183642342686653,
            -0.012668975628912449,
            -0.009782031178474426,
            -0.028638461604714394,
            0.01060478575527668,
            -0.005407970864325762,
            -0.008905027993023396,
            -0.02715369127690792,
            0.0080444710329175,
            -0.02624211460351944,
            -0.023122994229197502,
            0.01352167222648859,
            -0.03454558178782463,
            0.010292494669556618,
            -0.023169009014964104,
            0.006221805699169636,
            0.03401270508766174,
            -0.01738574355840683,
            0.0032665703911334276,
            -0.006926925852894783,
            0.0031543599907308817,
            -0.005079925525933504,
            -0.018497182056307793,
            -0.0067084128968417645,
            -0.024259865283966064,
            0.01019262708723545,
            0.011317471973598003,
            -0.007734090555459261,
            -0.006913166027516127,
            -0.009131032973527908,
            -0.015883726999163628,
            0.0038219187408685684,
            -0.006481710355728865,
            -0.006295082625001669,
            0.014934850856661797,
            -0.020502986386418343,
            0.004705874715000391,
            -0.009878616780042648,
            -0.022605309262871742,
            0.008468209765851498,
            -0.007647600956261158,
            0.00959320180118084,
            -0.005402769893407822,
            0.0023286500945687294,
            0.010853978805243969,
            -0.010787895880639553,
            0.004537450615316629,
            0.03467179462313652,
            0.019733503460884094,
            0.0014573337975889444,
            0.01926446333527565,
            0.02624850906431675,
            -0.013476378284394741,
            0.017154864966869354,
            -0.031969811767339706,
            -0.017686666920781136,
            0.009647026658058167,
            -0.017488187178969383,
            0.023298874497413635,
            -0.02240036055445671,
            0.019439255818724632,
            0.01204968336969614,
            -0.017688557505607605,
            -0.0015170889673754573,
            0.015743285417556763,
            0.00933173019438982,
            -0.00982512254267931,
            0.040949612855911255,
            0.017261918634176254,
            0.012809141539037228,
            -0.01837504655122757,
            0.005879758391529322,
            0.007388867903500795,
            0.0379110686480999,
            0.02345348708331585,
            -0.03763915225863457,
            0.014629938639700413,
            0.000995964976027608,
            0.005935826804488897,
            -0.024655329063534737,
            0.019801665097475052,
            -0.013589626178145409,
            -0.01748030073940754,
            -0.0015765500720590353,
            0.017165442928671837,
            0.028809454292058945,
            -0.004377720877528191,
            -0.026626376435160637,
            0.02016972191631794,
            0.029075762256979942,
            -0.036481406539678574,
            0.003943655174225569,
            0.010800987482070923,
            -0.02188551053404808,
            0.0190733652561903,
            0.018530534580349922,
            0.0009000124991871417,
            -0.0316188707947731,
            0.030262555927038193,
            -0.027033137157559395,
            0.010824308730661869,
            -0.00597698800265789,
            -0.010016243904829025,
            0.025029858574271202,
            0.01279232744127512,
            -0.017516443505883217,
            0.010755670256912708,
            0.011158001609146595,
            -0.026137270033359528,
            0.006930857431143522,
            0.010075821541249752,
            -0.00789377000182867,
            -0.010897621512413025,
            -0.04147392883896828,
            -0.004406045190989971,
            0.002781422808766365,
            -0.02620459534227848,
            -0.007165261544287205,
            0.016811765730381012,
            -0.00428951159119606,
            0.015473848208785057,
            -0.005062086973339319,
            0.014365575276315212,
            -0.02612820267677307,
            -0.03037480264902115,
            -0.000645447347778827,
            -0.01548390556126833,
            0.015631698071956635,
            0.026071924716234207,
            0.011252876371145248,
            0.015531642362475395,
            -0.0036749679129570723,
            0.004296824801713228,
            -0.013573706150054932,
            0.017773674800992012,
            -0.01727796159684658,
            0.01592961885035038,
            -0.028725897893309593,
            -0.024081066250801086,
            -0.00024768547154963017,
            0.004466251935809851,
            0.027811504900455475,
            0.04174520820379257,
            0.0071292901411652565,
            -0.003659989219158888,
            -0.027212366461753845,
            -0.0421665795147419,
            0.01778673753142357,
            0.005794773809611797,
            -0.027192581444978714,
            0.0018805142026394606,
            0.02787940762937069,
            0.029027283191680908,
            0.025243686512112617,
            -0.01302895974367857,
            0.001930390135385096,
            0.019913094118237495,
            0.008397380821406841,
            0.02239696867763996,
            -0.002146656159311533,
            -0.009235775098204613,
            -0.006932148709893227,
            0.008951200172305107,
            0.03188656270503998,
            -0.028278373181819916,
            0.0056605264544487,
            -0.003922299947589636,
            0.0234407689422369,
            0.011459063738584518,
            0.004489534068852663,
            -0.02696872502565384,
            -0.029511241242289543,
            -0.023965002968907356,
            0.0069665866903960705,
            -0.00013183038390707225,
            0.01959335245192051,
            -0.022934138774871826,
            0.008340653963387012,
            0.010310091078281403,
            -0.006589021999388933,
            -0.025422973558306694,
            -0.0003480274463072419,
            0.023271361365914345,
            0.004982634447515011,
            0.009692138992249966,
            0.021289421245455742,
            -0.0018552006222307682,
            0.03060854785144329,
            0.02253582514822483,
            0.025158487260341644,
            0.038109611719846725,
            0.02638380229473114,
            0.00034065148793160915,
            -0.013472743332386017,
            0.009903392754495144,
            0.023886792361736298,
            0.00451365252956748,
            0.03163570165634155,
            -0.0359758585691452,
            -0.008566029369831085,
            0.029417959973216057,
            0.028272824361920357,
            -0.026759082451462746,
            -0.018653249368071556
        ],
        [
            -0.008302704431116581,
            -0.004387193825095892,
            -0.01763428933918476,
            -0.009049836546182632,
            -0.0008951284689828753,
            -0.01925249770283699,
            0.0283479206264019,
            0.04870298132300377,
            -0.015858247876167297,
            -0.01212957315146923,
            -0.021093696355819702,
            2.7133562980452552e-05,
            -0.005401244852691889,
            -0.01528612244874239,
            -0.0013469631085172296,
            -0.01701357774436474,
            -0.014930934645235538,
            -0.00665327301248908,
            0.0036307249683886766,
            -0.002593815792351961,
            0.02577071823179722,
            -0.010849284008145332,
            0.0015690600266680121,
            0.013310480862855911,
            0.04155081883072853,
            0.023052209988236427,
            0.019294705241918564,
            -0.008839194662868977,
            -0.014828924089670181,
            -0.03532151132822037,
            -0.015511653386056423,
            -0.011061020195484161,
            -0.021120840683579445,
            -0.02718948945403099,
            -0.00921283382922411,
            -0.028882917016744614,
            0.0015908870846033096,
            -0.025507640093564987,
            0.00014294072752818465,
            -0.007751354482024908,
            -0.0314720943570137,
            0.02909015491604805,
            -0.010522267781198025,
            -0.021222146227955818,
            -0.013454613275825977,
            0.005053861066699028,
            -0.0018974256236106157,
            -0.010061328299343586,
            0.00979194138199091,
            0.0211894940584898,
            -0.02872011996805668,
            -0.004187529440969229,
            -0.02210158109664917,
            -0.015209461562335491,
            -0.04660601541399956,
            -0.009710848331451416,
            -0.011695729568600655,
            -0.01806577481329441,
            -0.03646834194660187,
            0.003311364445835352,
            -0.025865694507956505,
            0.010486027225852013,
            -0.027594154700636864,
            -0.0013275935780256987,
            0.00837367121130228,
            -0.01944774203002453,
            0.013246198184788227,
            0.03359111398458481,
            0.0009320636745542288,
            -0.011488553136587143,
            0.010051419958472252,
            0.029153460636734962,
            0.007130883168429136,
            -0.005178540013730526,
            -0.013752349652349949,
            0.004290343262255192,
            -0.02351011335849762,
            -0.02225308120250702,
            -0.047780852764844894,
            -0.00490654306486249,
            -0.022122209891676903,
            0.019469982013106346,
            -0.01041776780039072,
            0.03311308100819588,
            -0.020150503143668175,
            -0.008652188815176487,
            0.028939150273799896,
            0.003997303079813719,
            0.005360676907002926,
            -0.024381538853049278,
            0.0010944304522126913,
            -0.010490156710147858,
            0.03417167812585831,
            0.006146348547190428,
            0.025958161801099777,
            0.004836282692849636,
            0.008998420089483261,
            0.02883627451956272,
            0.05173245817422867,
            -0.026988638564944267,
            -0.015154712833464146,
            -0.004553462844341993,
            -0.01819060742855072,
            0.037799909710884094,
            -0.0263812318444252,
            0.023537976667284966,
            -0.01348454412072897,
            -0.020702797919511795,
            -0.030852997675538063,
            0.015081563033163548,
            -0.014982049353420734,
            -0.0037019001320004463,
            0.029735814779996872,
            -0.01410074532032013,
            0.02958562783896923,
            0.015246493741869926,
            0.018159454688429832,
            -0.029846664518117905,
            0.036991462111473083,
            0.010160875506699085,
            0.012721356935799122,
            -0.01994439959526062,
            0.008876672014594078,
            -0.03243442624807358,
            0.014867431484162807,
            -0.02020326815545559,
            0.007584670092910528,
            -0.020364131778478622,
            0.024230895563960075,
            0.03544773906469345,
            -0.006868409458547831,
            0.021198894828557968,
            0.04510805383324623,
            -0.032922569662332535,
            -0.004517348483204842,
            0.0017741359770298004,
            -0.017747260630130768,
            -0.019551651552319527,
            -0.024651160463690758,
            0.006432732567191124,
            0.027125392109155655,
            0.005255427677184343,
            -0.06268863379955292,
            -0.0012896363623440266,
            -0.00887623243033886,
            -0.0034299215767532587,
            -0.05342536419630051,
            0.018442103639245033,
            0.0073082055896520615,
            -0.02784978412091732,
            0.0307974461466074,
            -0.024248752743005753,
            0.025711040943861008,
            0.0083638746291399,
            -0.04281045123934746,
            -0.032244209200143814,
            0.02548360265791416,
            -0.0252423994243145,
            0.025394875556230545,
            -0.0283371489495039,
            -0.012885164469480515,
            0.02210649661719799,
            -0.011165312491357327,
            0.020614992827177048,
            0.02140325866639614,
            0.00016561191296204925,
            0.025512393563985825,
            0.016978546977043152,
            0.013029612600803375,
            -0.0016313649248331785,
            0.00953677948564291,
            -0.01660575158894062,
            -0.017985275015234947,
            0.019856760278344154,
            0.00780120026320219,
            -0.028703220188617706,
            -0.03353826701641083,
            0.01039361022412777,
            -0.018312953412532806,
            0.0041941022500395775,
            -0.01534285955131054,
            0.015785597264766693,
            -0.019176233559846878,
            -0.022163400426506996,
            0.010026971809566021,
            0.026798171922564507,
            0.01265833992511034,
            -0.025516586378216743,
            -0.0024915062822401524,
            -0.005990468431264162,
            -0.008351866155862808,
            0.01682327315211296,
            -0.043118156492710114,
            0.030790232121944427,
            0.01832943968474865,
            -0.011465712450444698,
            0.013067577965557575,
            -0.02792626991868019,
            -0.003320057410746813,
            0.0061101773753762245,
            0.011380186304450035,
            0.02574847638607025,
            0.02249620482325554,
            -0.01621142402291298,
            0.03403607755899429,
            -0.0033817989751696587,
            -0.05393495783209801,
            -0.02207433618605137,
            0.022333329543471336,
            -0.017759252339601517,
            0.024531008675694466,
            0.013518381863832474,
            0.020936600863933563,
            -0.00820852443575859,
            -0.017777668312191963,
            -0.02034127339720726,
            -0.0026138420216739178,
            0.01894066482782364,
            0.013448968529701233,
            0.015691502019762993,
            0.02460055612027645,
            -0.003488548332825303,
            0.018215086311101913,
            -0.00651978375390172,
            0.03420645371079445,
            0.0037993111182004213,
            -0.0427868627011776,
            0.027855277061462402,
            0.010201293975114822,
            -0.02506786212325096,
            0.03232855349779129,
            -0.008782334625720978,
            -0.010527977719902992,
            0.013751720078289509,
            -0.003320439951494336,
            0.0022586428094655275,
            0.04260527715086937,
            -0.0240920539945364,
            -0.011877534911036491,
            -0.01055251993238926,
            -0.0014672833494842052,
            -0.010211779735982418,
            0.021567223593592644,
            0.018771328032016754,
            -0.03376061096787453,
            0.027744071558117867,
            0.004726962652057409,
            -0.009083405137062073,
            0.014800704084336758,
            -0.031419720500707626,
            -0.019353359937667847,
            -0.025654925033450127,
            0.0330808088183403,
            0.024883011355996132,
            0.005183447618037462,
            0.03399834409356117,
            0.030868057161569595,
            -0.030493950471282005,
            0.011183847673237324,
            -0.01235765591263771,
            0.003289459738880396,
            -0.015255853533744812,
            0.017773538827896118,
            0.03359381854534149,
            -0.020297668874263763,
            0.027855226770043373,
            0.0003388677432667464,
            -0.005065936595201492,
            -0.023102030158042908,
            0.0023192795924842358,
            -0.03882986307144165,
            0.014918774366378784,
            -0.00027147249784320593,
            0.0001410840341122821,
            0.005337908864021301,
            -0.0029321752954274416,
            -0.001444823108613491,
            -0.005367395468056202,
            -0.006806233897805214,
            0.013206527568399906,
            -0.0026913974434137344,
            -0.0020691929385066032,
            0.003319759154692292,
            -0.0165809765458107,
            -0.023587975651025772,
            0.010248945094645023,
            0.008738217875361443,
            -0.030305491760373116,
            0.018154775723814964,
            0.006977388169616461,
            0.012380273081362247,
            0.017653657123446465,
            0.022653713822364807,
            0.011640453711152077,
            0.002256662119179964,
            0.007820019498467445,
            -0.004678642377257347,
            -0.0019261245615780354,
            0.006994332652539015,
            -0.025527769699692726,
            -0.01977069489657879,
            -0.024080632254481316,
            -0.03108658455312252,
            -0.010700106620788574,
            -0.02247481234371662,
            -0.018780997022986412,
            -0.003168358001857996,
            -0.01144350040704012,
            -0.008790198713541031,
            -0.0008664201595820487,
            -0.012149730697274208,
            -0.01674117147922516,
            -0.02164657972753048,
            -0.018086522817611694,
            0.007566336076706648,
            -0.04763467237353325,
            -0.011082116514444351,
            0.02044668421149254,
            0.02601844072341919,
            -0.013364083133637905,
            0.002052856143563986,
            0.007476664148271084,
            -0.05976666882634163,
            0.02720808982849121,
            -0.013304048217833042,
            0.012990995310246944,
            0.03290756046772003,
            0.006307451985776424,
            -0.00031444450723938644,
            0.022768212482333183,
            -0.003600186202675104,
            -0.006318334955722094,
            0.045786432921886444,
            0.01912696100771427,
            -0.015756186097860336,
            -0.02192940190434456,
            -0.013642309233546257,
            -0.005700315348803997,
            0.008913260884582996,
            -0.0006014397949911654,
            0.020701352506875992,
            -0.017153432592749596,
            -0.036121245473623276,
            -0.015598738566040993,
            0.0111533273011446,
            0.011656890623271465,
            -0.03673133999109268,
            -0.012648249045014381,
            0.03735150396823883,
            0.03434758633375168,
            -0.02289508283138275,
            0.027683034539222717,
            -0.0040613804012537,
            0.037327710539102554,
            0.008191895671188831,
            -0.016951605677604675,
            0.004237113520503044,
            -0.01796809211373329,
            0.036129556596279144,
            -0.04788042977452278,
            0.02495587058365345,
            0.006028085947036743,
            0.014922825619578362,
            0.02846159227192402,
            0.0020895912311971188,
            -0.025013770908117294,
            -0.05091819167137146,
            -0.0037870954256504774,
            0.016471443697810173,
            -0.018564000725746155,
            0.03293229639530182,
            -0.00443852785974741,
            -0.0017932658083736897,
            0.0003698437358252704,
            0.016329770907759666,
            -0.013734116218984127,
            -0.019440798088908195,
            0.03686290234327316,
            0.008564422838389874,
            0.02521323226392269,
            0.014950745739042759,
            0.015557841397821903,
            0.030493147671222687,
            -0.026796000078320503,
            0.022710775956511497,
            -0.012503054924309254,
            -0.023935934528708458,
            -0.028055116534233093,
            -0.009884560480713844,
            0.017687104642391205,
            -0.01834777183830738,
            -0.007073899731040001,
            0.020325936377048492,
            -0.044098712503910065,
            -0.007565806154161692,
            -0.03450798615813255,
            0.02745383232831955,
            -0.024658070877194405,
            -0.00625799922272563,
            -0.030221041291952133,
            0.01950458437204361,
            -0.01747734472155571,
            0.012181183323264122,
            0.02611335925757885,
            -0.00300207594409585,
            -0.004682442173361778,
            -0.007230797316879034,
            0.01979791559278965,
            -0.01283810380846262,
            -0.017608968541026115,
            0.011677086353302002,
            -0.027071824297308922,
            -0.015612722374498844,
            0.007140394765883684,
            -0.05737612023949623,
            0.005145248491317034,
            -0.012082005850970745,
            -0.02280331403017044,
            0.012840652838349342,
            0.01905997283756733,
            0.013812634162604809,
            -0.03158373013138771,
            0.006559332832694054,
            -0.020932434126734734,
            -0.01951815001666546,
            0.031460344791412354,
            0.04246897250413895,
            0.009203705005347729,
            0.015465321950614452,
            0.0036321752704679966,
            -4.815400461666286e-05,
            -0.011872060596942902,
            -0.014705945737659931,
            -0.039076853543519974,
            0.007465145085006952,
            -0.0014390142168849707,
            -0.013327140361070633,
            0.0049044545739889145,
            0.014519010670483112,
            -0.019545579329133034,
            -0.026462456211447716,
            -0.019587820395827293,
            -0.010279766283929348,
            -0.020315740257501602,
            -0.03295138478279114,
            0.02939409203827381,
            0.015620595775544643,
            0.023066306486725807,
            0.02057773806154728,
            0.020016683265566826,
            0.00534222275018692,
            -0.026332808658480644,
            -0.00603793328627944,
            -0.008730543777346611,
            0.0030558211728930473,
            0.021074270829558372,
            0.03129780292510986,
            0.00041605898877605796,
            0.003534912597388029,
            -0.011980563402175903,
            0.02155832201242447,
            0.01515196729451418,
            -0.015512502752244473,
            0.0017293234122917056,
            0.011134705506265163,
            0.014569152146577835,
            0.02303069271147251,
            -0.016765160486102104,
            0.0061942460015416145,
            -0.022959966212511063,
            -0.018200131133198738,
            -0.019787130877375603,
            0.0016861050389707088,
            0.020624062046408653,
            -0.018429534509778023,
            0.0019287728937342763,
            0.018286902457475662,
            0.010458148084580898,
            0.013878113590180874,
            0.015949757769703865,
            -0.008786304853856564,
            0.014260797761380672,
            0.04306788370013237,
            0.018462691456079483,
            0.020450444892048836,
            -0.006399504840373993,
            -0.011949639767408371,
            0.022826429456472397,
            -0.049481119960546494,
            -0.0015695567708462477,
            -0.010497215203940868,
            0.0005531299975700676,
            -0.01822574809193611,
            -0.022168314084410667,
            0.010153970681130886,
            0.029721371829509735,
            -0.008735913783311844,
            0.003268706612288952,
            -0.0023438138887286186,
            0.03282659873366356,
            0.017230786383152008,
            0.00882199127227068,
            -0.003660226473584771,
            -0.0328138992190361,
            0.021940426900982857,
            0.004235061816871166,
            0.023774906992912292,
            0.007818449288606644,
            0.0025658435188233852,
            -0.02126234583556652,
            -0.003971209283918142,
            0.037518903613090515,
            0.0028125073295086622,
            -0.030380578711628914,
            0.014536281116306782,
            0.014698605053126812,
            -0.006482139229774475,
            0.00036992988316342235,
            0.022830650210380554,
            0.004510736558586359,
            0.01992657221853733,
            0.003436257364228368,
            -0.0037436364218592644,
            -0.0020875290501862764,
            -0.006443347316235304,
            -0.03033229149878025,
            -0.021129529923200607,
            -0.006074507255107164,
            -0.00238195713609457,
            -0.019450446590781212,
            -0.016416383907198906,
            -0.027890466153621674,
            0.05507804453372955,
            -0.008965558372437954,
            0.014697995968163013,
            -0.0036372144240885973,
            0.015763919800519943,
            -0.0069310604594647884,
            0.0325809046626091,
            0.007466575596481562,
            -0.011508150957524776,
            0.018901929259300232,
            0.030025916174054146,
            0.03454161062836647,
            -0.022167762741446495,
            0.012509128078818321,
            -0.008429187349975109,
            0.014795514754951,
            -0.000978731201030314,
            -0.00342811131849885,
            -0.005586670245975256,
            -0.012635862454771996,
            -0.04960387200117111,
            0.010075190104544163,
            -0.010611177422106266,
            -0.0033519475255161524,
            0.014799575321376324,
            0.019362684339284897,
            0.026299145072698593,
            -0.023609351366758347,
            -0.02361927181482315,
            0.004396890290081501,
            -0.0028033098205924034,
            0.028509872034192085,
            -0.021824868395924568,
            -0.02594149112701416,
            -0.03976236283779144,
            -0.03623132035136223,
            0.01706702634692192,
            -0.013640861958265305,
            -0.022175652906298637,
            -0.029826108366250992,
            -0.03647138923406601,
            0.0009322778205387294,
            -0.003695722669363022,
            0.0189741849899292,
            -0.014104979112744331,
            0.01722380518913269,
            0.024948829784989357,
            -0.0017367227701470256,
            -0.011833454482257366,
            0.032293081283569336,
            -0.016894206404685974,
            0.022889114916324615,
            0.02041899971663952,
            0.004478876478970051,
            0.0186160895973444,
            0.003628161735832691,
            -0.015985721722245216,
            -0.0008081269916146994,
            0.04247764125466347,
            0.017757723107933998,
            -0.026769977062940598,
            0.00570105854421854,
            0.024101505056023598,
            -0.03667265549302101,
            0.010815908201038837,
            -0.017093371599912643,
            -0.0004980466328561306,
            -0.017505012452602386,
            0.018035374581813812,
            0.01809806004166603,
            -0.028300205245614052,
            -0.02315087430179119,
            -0.006431132555007935,
            0.002741777803748846,
            -0.03320968523621559,
            0.019416654482483864,
            0.02295590378344059,
            -0.033763337880373,
            -0.025525884702801704,
            -0.004095758311450481,
            0.004865908995270729,
            0.014947731979191303,
            0.022213825955986977,
            0.028531260788440704,
            -0.007080950774252415,
            -0.005232399329543114,
            0.03453205153346062,
            0.028904592618346214,
            -0.016585364937782288,
            0.009022269397974014,
            0.007630694657564163,
            0.0033996785059571266,
            0.03202987462282181,
            0.014194034039974213,
            -0.028598621487617493,
            0.023892108350992203,
            0.030563512817025185,
            -0.002194035332649946,
            0.011893011629581451,
            0.00014992053911555558,
            0.014877432025969028,
            0.033422939479351044,
            -0.012096589431166649,
            -0.05533808842301369,
            -0.026780128479003906,
            -0.01039560791105032,
            -0.023216107860207558,
            0.02288789674639702,
            -0.01567958854138851,
            -0.01829545944929123,
            0.01001650933176279,
            -0.043244604021310806,
            -0.0038756728172302246,
            0.014210982248187065,
            0.006146418396383524,
            0.02820030227303505,
            0.008228492923080921,
            -0.012777559459209442,
            -0.04504808411002159,
            0.02242404781281948,
            -0.012142020277678967,
            0.0263321902602911,
            0.037556372582912445,
            -0.016846299171447754,
            -0.01539183221757412,
            0.0011215087724849582,
            -0.02709854394197464,
            0.01420779898762703,
            0.03225782513618469,
            -0.006946838926523924,
            -0.026531502604484558,
            -0.008943834342062473,
            -0.0002747976395767182,
            -0.01991848833858967,
            -0.045827507972717285,
            -0.022445039823651314,
            -0.02273799106478691,
            0.018332960084080696,
            0.00683120172470808,
            0.010316315107047558,
            -0.027755655348300934,
            -0.02192903682589531,
            -0.010317604057490826,
            -0.0030105675105005503,
            -0.015239661559462547,
            0.017913056537508965,
            -0.008361034095287323,
            -0.015244768932461739,
            0.05892990902066231,
            -0.006016076542437077,
            -0.004869976546615362,
            0.01297401636838913,
            0.027135515585541725,
            0.031444426625967026,
            -0.00761814508587122,
            0.048661068081855774,
            -0.030374575406312943,
            0.01581822894513607,
            -0.049810994416475296,
            -0.0024789245799183846,
            0.010441863909363747,
            0.02928534522652626,
            0.0037440096493810415,
            0.026519261300563812,
            -0.02362646721303463,
            0.004997735843062401,
            0.0263510774821043,
            0.020164156332612038,
            0.03299391642212868,
            -0.05007525160908699,
            7.907264807727188e-05,
            0.03382285311818123,
            -0.010067177936434746,
            0.009225424379110336,
            -0.0017519354587420821,
            -0.012750889174640179,
            -0.012185745872557163,
            -0.02471652626991272,
            -0.01336041558533907,
            0.005017525982111692,
            0.011536672711372375,
            -0.013854733668267727,
            0.008240926079452038,
            -0.021024076268076897,
            0.06106826663017273,
            -0.03051554411649704,
            0.02890295721590519,
            0.03771454840898514,
            0.01452585868537426,
            0.02118685282766819,
            0.0066712093539536,
            -0.008111580274999142,
            -0.029097553342580795,
            -0.02346835285425186,
            -0.00749445054680109,
            0.013381865806877613,
            -0.013884355314075947,
            0.0074493009597063065,
            -0.019491897895932198,
            0.012777063064277172,
            0.0005808920250274241,
            0.012204917147755623,
            0.01196498703211546,
            -0.015776533633470535,
            0.00419544568285346,
            0.026573870331048965,
            -0.023553915321826935,
            0.005149099975824356,
            0.01765458658337593,
            0.0384092703461647,
            -0.008269669488072395,
            0.006478132680058479,
            -0.027597365900874138,
            -0.018602238968014717,
            0.03641904518008232,
            -0.0384557768702507,
            0.03092256747186184,
            0.030045388266444206,
            0.029393283650279045,
            0.02539258822798729,
            -0.029358595609664917,
            -0.033510297536849976,
            -0.01402952428907156,
            -0.005514481570571661,
            -0.02145705372095108,
            -0.0017067916924133897,
            -0.04136122390627861,
            0.009310226887464523,
            0.009673825465142727,
            0.014484524726867676,
            0.006376542150974274,
            0.011685265228152275,
            -0.012887579388916492,
            0.028291044756770134,
            0.01498478651046753,
            -0.022649945691227913,
            -0.007891190238296986,
            0.034235868602991104,
            0.006149953231215477,
            0.02265588752925396,
            -0.0001816038420656696,
            0.02789698913693428,
            0.02777489274740219,
            -0.004724656697362661,
            0.005904270801693201,
            0.012015310116112232,
            0.013196545653045177,
            0.01991930603981018,
            -0.03351375088095665,
            0.014276176691055298,
            0.024148104712367058,
            -0.032445408403873444,
            -0.013735526241362095,
            -0.008321582339704037,
            -0.015578232705593109,
            -0.014821273274719715,
            0.023058949038386345,
            0.013889472931623459,
            -0.005744074936956167,
            -0.028518108651041985,
            -0.052185170352458954,
            -0.019764449447393417,
            0.009401913732290268,
            -0.025056488811969757,
            -0.017428826540708542,
            0.013875354081392288,
            0.02875548228621483,
            -0.017512841150164604,
            0.02669796161353588,
            -0.017867185175418854,
            -0.03701497241854668,
            -0.011207060888409615,
            -0.007583174388855696,
            -0.010448531247675419,
            0.036844220012426376,
            -0.01846117153763771,
            0.012716593220829964,
            0.0005914327339269221,
            -0.034943144768476486,
            -0.03946583345532417,
            0.017613789066672325,
            0.02750462107360363,
            0.012371556833386421,
            -0.00223372271284461,
            -0.025824012234807014,
            -0.004991201218217611,
            0.01707974262535572,
            -0.014590100385248661,
            -0.008609466254711151,
            -0.005142200738191605,
            0.018826013430953026,
            -0.013880975544452667,
            0.015571274794638157,
            -0.0151694156229496,
            0.025719327852129936,
            0.0029986752197146416,
            0.0230938158929348,
            -0.003334623761475086,
            -0.04262757673859596,
            -0.008634752593934536,
            0.010821632109582424,
            0.026343023404479027,
            0.033200059086084366,
            -0.011747856624424458,
            0.021575476974248886,
            -0.022693868726491928,
            -0.02010551653802395,
            -0.00044501066440716386,
            -0.02291506715118885,
            -0.0054984320886433125,
            -0.015353263355791569,
            0.005825759842991829,
            -0.010073456913232803,
            -0.028288215398788452,
            0.0193464457988739,
            -0.0002531058562453836,
            -0.019192874431610107,
            0.024984410032629967,
            0.012867257930338383,
            0.019199952483177185,
            0.03493908420205116,
            0.025155797600746155,
            0.016351312398910522,
            0.017427297309041023,
            0.004134438466280699,
            0.01885882578790188,
            -0.05885495990514755,
            -0.01248750276863575,
            -0.0010068423580378294,
            -0.01104711089283228,
            0.0031945358496159315,
            -0.0006024087779223919,
            -0.014273093082010746,
            0.03336073458194733,
            0.022880131378769875,
            0.013170838356018066,
            0.005959744099527597,
            0.008825737982988358,
            -0.009438627399504185,
            -0.01007327064871788,
            0.02799605205655098,
            0.009372128173708916,
            0.03237345069646835,
            -0.02448912337422371,
            -0.004245343152433634,
            -0.01836095191538334,
            0.0008361446089111269,
            0.018515532836318016,
            0.024115433916449547,
            0.01950564980506897,
            -0.03526908531785011,
            0.034611620008945465,
            0.03861597552895546,
            -0.0024775778874754906,
            0.01801043562591076,
            -0.02940196916460991,
            0.02395772933959961,
            0.0011181156150996685,
            0.010639820247888565,
            -0.008497579023241997,
            -0.010064384900033474,
            -0.018291624262928963,
            0.021132582798600197,
            0.008893147110939026,
            -0.007743450812995434,
            0.03596024215221405,
            0.0316038578748703,
            0.013267108239233494,
            0.006529360543936491,
            0.006650204304605722,
            0.012132305651903152,
            -0.047032251954078674,
            0.018406912684440613,
            -0.0017687483923509717,
            -0.01739226095378399,
            0.03217346593737602,
            0.014594096690416336,
            0.02676689438521862,
            0.02221022918820381,
            0.0009623655932955444,
            0.014321780763566494,
            0.03889312222599983,
            0.03106406144797802,
            -0.013637599535286427,
            0.012570890597999096,
            0.02240174449980259,
            -0.004294692073017359,
            0.0024642038624733686,
            0.011198907159268856,
            -0.02463115006685257,
            0.021680595353245735,
            -0.018524065613746643,
            0.01980707421898842,
            0.0005968760815449059,
            -0.022692516446113586,
            -0.02340463176369667,
            0.0008140795398503542,
            -0.0016240658005699515,
            0.006712780334055424,
            0.031004605814814568,
            -0.01216166839003563,
            -0.018845047801733017,
            -0.031509172171354294,
            -0.02920294925570488,
            -0.0006583690410479903,
            0.026866843923926353,
            -0.015278175473213196,
            0.015305698849260807,
            0.010098229162395,
            -0.005097696091979742,
            0.01431475579738617,
            0.020364901050925255,
            -0.04359646514058113,
            0.00845006387680769,
            -0.008323690854012966,
            -0.022875672206282616,
            0.011900168843567371,
            -0.009931853041052818,
            -0.01689399592578411,
            -0.024172350764274597,
            0.018926095217466354,
            0.02736625075340271,
            -0.017241831868886948,
            -0.016610359773039818,
            0.03426291048526764,
            -0.020665250718593597,
            0.008721812628209591,
            0.010573865845799446,
            -0.005510903894901276,
            0.010360870510339737,
            -0.03188648447394371,
            -0.005664399825036526,
            0.011483676731586456,
            -0.014139496721327305,
            0.027236774563789368,
            0.027143295854330063,
            0.02717229165136814,
            0.024510549381375313,
            -0.026744017377495766,
            0.03350145369768143,
            0.031105609610676765,
            0.01254169549793005,
            0.028624502941966057,
            0.005664086434990168,
            0.005021160468459129,
            0.020241253077983856,
            -0.0215306356549263,
            0.02253742516040802,
            0.03417370095849037,
            -0.026015888899564743,
            0.018511231988668442,
            0.03814740106463432,
            0.00458329264074564,
            0.017146890982985497,
            -0.03315029665827751,
            -0.021593019366264343,
            -0.011265805922448635,
            0.02003326825797558,
            0.005024806130677462,
            -0.026074936613440514,
            -0.035473018884658813,
            0.0244501531124115,
            0.028057998046278954,
            -0.024993764236569405,
            -0.0020444393157958984,
            0.01698809675872326,
            -0.008076648227870464,
            0.021622492000460625,
            -0.018305080011487007,
            -0.014778247103095055,
            -0.0007044797530397773,
            -0.024733157828450203,
            -0.013730627484619617,
            0.021170085296034813,
            0.027197903022170067,
            -0.016933927312493324,
            0.020363258197903633,
            0.021087469533085823,
            -0.033430393785238266,
            -0.00042499808478169143,
            -0.001754667959176004,
            0.010382475331425667,
            -0.050518669188022614,
            -0.02719523385167122,
            0.013226321898400784,
            -0.008456750772893429,
            -0.002098923549056053,
            -0.011348509229719639,
            0.012615982443094254,
            -0.02707967907190323,
            -0.006765755824744701,
            -0.005315705668181181
        ],
        [
            -0.004737540148198605,
            0.006434411276131868,
            -0.006983648054301739,
            -0.06063050404191017,
            0.0020581227727234364,
            -0.005028592422604561,
            -0.03022729977965355,
            -0.028573427349328995,
            0.020792126655578613,
            -0.011552639305591583,
            0.012659940868616104,
            -0.030834577977657318,
            0.011129518039524555,
            0.016915619373321533,
            0.010837075300514698,
            -0.04629462957382202,
            0.003914687316864729,
            -0.018563885241746902,
            0.040539730340242386,
            -0.021214690059423447,
            0.0032993732020258904,
            -0.014235951006412506,
            -0.012097279541194439,
            0.012796861119568348,
            0.021950766444206238,
            0.007152882870286703,
            -0.022468896582722664,
            0.016097942367196083,
            -0.022688843309879303,
            0.0018397606909275055,
            -0.008070663549005985,
            -0.04136711359024048,
            0.03458883613348007,
            0.015202999114990234,
            0.003121587447822094,
            -0.06472276896238327,
            0.025564046576619148,
            -0.010212578810751438,
            -0.006895551458001137,
            0.02306510880589485,
            -9.16855969990138e-06,
            -0.05112322419881821,
            0.007621013559401035,
            -0.005915976129472256,
            0.02736806683242321,
            0.004919345490634441,
            -0.023985836654901505,
            0.005839068442583084,
            0.014078517444431782,
            0.008129410445690155,
            -0.019209282472729683,
            0.02891833893954754,
            -0.02777303196489811,
            -0.03941929712891579,
            0.02989892289042473,
            0.011232053861021996,
            -0.00034177579800598323,
            0.015925657004117966,
            0.006467606406658888,
            0.03585079684853554,
            -0.014039549976587296,
            -0.017901385203003883,
            -0.06918491423130035,
            0.017518868669867516,
            -0.006991266272962093,
            0.012974263168871403,
            -0.037265799939632416,
            0.01803501322865486,
            -0.007846811786293983,
            0.029831625521183014,
            -0.02457147277891636,
            -0.01802969165146351,
            0.02529088594019413,
            0.00897538848221302,
            -0.023333247750997543,
            -0.0016093243611976504,
            -0.012997684068977833,
            -0.013298168778419495,
            0.003584460122510791,
            -0.05132536590099335,
            0.012820499949157238,
            0.018326373770833015,
            -0.03290778398513794,
            -0.0033851023763418198,
            0.022382637485861778,
            0.015978308394551277,
            0.032939184457063675,
            0.014798889867961407,
            -0.036812588572502136,
            -0.014828362502157688,
            0.0006035292753949761,
            -0.011044308543205261,
            -0.002155694179236889,
            -0.04901883378624916,
            0.006039046216756105,
            0.008359799161553383,
            -0.012772942893207073,
            0.02422882243990898,
            -0.05453561991453171,
            0.0004192805499769747,
            0.03474519029259682,
            0.06548869609832764,
            -0.003206611843779683,
            0.011962324380874634,
            0.018430905416607857,
            0.01340429950505495,
            0.00098242552485317,
            0.02517736330628395,
            0.05898009240627289,
            -0.00775547418743372,
            -0.02797882631421089,
            -0.007559475488960743,
            0.007685314863920212,
            0.010903366841375828,
            0.10234473645687103,
            -0.03239933401346207,
            0.01782975159585476,
            -0.007351086009293795,
            0.03029760532081127,
            0.006541926879435778,
            0.0022576746996492147,
            0.027379831299185753,
            0.01192014105618,
            -0.01074486505240202,
            0.039341263473033905,
            0.045399975031614304,
            0.004008655436336994,
            -0.029859066009521484,
            -0.006711090449243784,
            -0.008092930540442467,
            0.017940694466233253,
            0.05444147810339928,
            0.04725312441587448,
            0.07538042217493057,
            0.006632035598158836,
            0.012899955734610558,
            -0.0380110889673233,
            0.023302346467971802,
            -0.06022733449935913,
            -0.022186238318681717,
            -0.010855183005332947,
            -0.016739793121814728,
            -0.028511550277471542,
            -0.05203947797417641,
            0.04932237043976784,
            -0.013618873432278633,
            -0.012488714419305325,
            -0.012452252209186554,
            -0.013553307391703129,
            0.04325588420033455,
            -0.04634714126586914,
            0.04624253883957863,
            -0.0748017430305481,
            0.03588045760989189,
            0.01859251968562603,
            0.00846047792583704,
            -0.032167840749025345,
            0.01610228419303894,
            0.011884446255862713,
            0.06944257020950317,
            -0.0028533930890262127,
            0.021573785692453384,
            0.02523895911872387,
            0.023665735498070717,
            0.01610954850912094,
            0.021007733419537544,
            0.022406848147511482,
            -0.05281168967485428,
            -0.04703216627240181,
            -0.008363978937268257,
            0.0070359413512051105,
            0.04740923270583153,
            -0.0179744902998209,
            0.008479641750454903,
            0.002461738418787718,
            0.01343282125890255,
            -0.036434706300497055,
            -0.014415150508284569,
            -0.02392531931400299,
            -0.046511173248291016,
            0.004131793975830078,
            0.015763115137815475,
            -0.026172511279582977,
            0.04650065675377846,
            -0.03655582293868065,
            -0.00036379232187755406,
            -0.017252840101718903,
            -0.026031695306301117,
            0.01036471500992775,
            -0.01674490235745907,
            0.009356275200843811,
            -0.022888924926519394,
            -0.0008272497216239572,
            0.011459359899163246,
            0.027621319517493248,
            0.01290967222303152,
            -0.011302027851343155,
            -0.019751306623220444,
            0.04335053265094757,
            0.006782031152397394,
            0.006399419158697128,
            0.007889986969530582,
            -0.05658144876360893,
            -0.02273804135620594,
            -0.027606818825006485,
            -0.04667171463370323,
            0.02674831636250019,
            0.06642921268939972,
            -0.05656396597623825,
            0.022970212623476982,
            -0.030978547409176826,
            -0.0018266034312546253,
            0.010653623379766941,
            -0.006262483540922403,
            0.05018194019794464,
            -0.023386262357234955,
            -0.002513133455067873,
            -0.028396110981702805,
            0.007175799459218979,
            0.02127067558467388,
            0.009295684285461903,
            0.042638301849365234,
            -0.04388342425227165,
            0.0134596461430192,
            0.02532573603093624,
            -0.055129263550043106,
            -0.00031709609902463853,
            -0.029788855463266373,
            0.02568526566028595,
            -0.006350181996822357,
            -0.015196542255580425,
            -0.02535402402281761,
            -0.052738167345523834,
            0.01290456298738718,
            0.01858084835112095,
            0.026729922741651535,
            0.0013711508363485336,
            -0.06364013999700546,
            -0.03733512759208679,
            0.03357066586613655,
            -0.004710030741989613,
            -0.02876381017267704,
            0.023887600749731064,
            -0.0008342480869032443,
            0.00972719956189394,
            0.014813798479735851,
            0.0328427292406559,
            -0.009774409234523773,
            0.023454545065760612,
            0.028879908844828606,
            -0.01675802282989025,
            0.008382067084312439,
            -0.055435992777347565,
            0.010265921242535114,
            -0.005719409324228764,
            -0.025141840800642967,
            -0.015946703031659126,
            0.005872318986803293,
            0.009099054150283337,
            0.036610495299100876,
            -0.010119469836354256,
            0.016653653234243393,
            -0.013355536386370659,
            -0.0037140329368412495,
            0.01087950635701418,
            -0.06605541706085205,
            -0.006038902327418327,
            -0.0010738902492448688,
            0.041653357446193695,
            -0.05000011622905731,
            -0.029159657657146454,
            -0.020164543762803078,
            -0.02258303388953209,
            -0.012685021385550499,
            -0.006996821612119675,
            0.0014910916797816753,
            -0.0030143065378069878,
            -0.019815117120742798,
            -0.006666701752692461,
            -0.03257862478494644,
            0.0007100151851773262,
            -0.020291227847337723,
            0.027995562180876732,
            0.0006673740572296083,
            -0.04131044074892998,
            -0.04595596343278885,
            0.0029903228860348463,
            -0.050310052931308746,
            -0.04715438559651375,
            -0.06521889567375183,
            -0.005735670682042837,
            -0.0017923820996657014,
            0.009763180278241634,
            0.03988727554678917,
            -0.0005561737925745547,
            0.04283105581998825,
            0.04193900525569916,
            -0.0028345338068902493,
            0.011980236507952213,
            0.030888911336660385,
            -0.015311884693801403,
            0.03198991343379021,
            -0.02871493436396122,
            -0.054394423961639404,
            0.02214651182293892,
            -0.0024650245904922485,
            -0.018802953884005547,
            0.046468112617731094,
            0.010214834474027157,
            -0.003261308651417494,
            0.03672538697719574,
            0.028299963101744652,
            -0.007207777351140976,
            -0.019919123500585556,
            -0.0017866567941382527,
            0.014944583177566528,
            0.0015393320936709642,
            0.04199546203017235,
            -0.009273429401218891,
            0.023968003690242767,
            0.0018279892392456532,
            -0.09685487300157547,
            -0.01700611226260662,
            0.039545074105262756,
            -0.0023403805680572987,
            0.024194946512579918,
            0.048965808004140854,
            0.02038058079779148,
            -0.041154585778713226,
            -0.002316672820597887,
            0.0252496600151062,
            0.0034039237070828676,
            0.02547059766948223,
            -0.012885044328868389,
            -0.01209336705505848,
            0.023801976814866066,
            0.02771039493381977,
            0.015531103126704693,
            -0.019884228706359863,
            -0.029103361070156097,
            0.004869482479989529,
            -0.030079752206802368,
            -0.05655881389975548,
            -0.031903401017189026,
            0.030112557113170624,
            0.016671795397996902,
            -0.007020711433142424,
            -0.02384299971163273,
            0.0430326946079731,
            -0.028740333393216133,
            -0.00043513585114851594,
            -0.026660621166229248,
            0.018890544772148132,
            -0.04900173470377922,
            0.02571667730808258,
            -0.04262564703822136,
            0.0036716784816235304,
            -0.04308273643255234,
            0.008678239770233631,
            -0.010949031449854374,
            0.016036570072174072,
            0.022614851593971252,
            0.010014859028160572,
            0.00829003844410181,
            0.026927191764116287,
            0.038061581552028656,
            0.04402395710349083,
            -0.01703943870961666,
            -0.0038167405873537064,
            -0.0069459886290133,
            -0.03471700847148895,
            -0.01032009907066822,
            -0.004537091590464115,
            0.008644797839224339,
            0.030083291232585907,
            -0.0013491581194102764,
            -0.039988186210393906,
            0.032637521624565125,
            0.006949940696358681,
            0.012798571027815342,
            0.018656978383660316,
            -0.007984815165400505,
            -0.012825153768062592,
            0.013124409131705761,
            -0.04768580570816994,
            -0.05409688502550125,
            0.01393365953117609,
            0.002254800871014595,
            -0.028495371341705322,
            -0.0306786447763443,
            0.02610562928020954,
            0.013582314364612103,
            -0.03731857240200043,
            -0.004418462049216032,
            -0.004134670365601778,
            -0.030902277678251266,
            -0.001715116435661912,
            0.015516942366957664,
            -0.0035562850534915924,
            -0.03151315450668335,
            0.03695915266871452,
            0.020728185772895813,
            -0.014606254175305367,
            -0.009914563968777657,
            0.007480671163648367,
            0.03848052769899368,
            0.024722667410969734,
            0.008163392543792725,
            0.006155724637210369,
            0.0018315787892788649,
            0.02811974287033081,
            -0.007689084857702255,
            -0.04848313331604004,
            0.0032117313239723444,
            0.022857291623950005,
            0.009653713554143906,
            0.011277496814727783,
            0.029567014425992966,
            0.028810475021600723,
            -0.011979400180280209,
            0.042327895760536194,
            0.0172100942581892,
            -0.03987664729356766,
            0.025719212368130684,
            -0.00411270372569561,
            0.03224962577223778,
            0.01828034035861492,
            -0.016124632209539413,
            -0.017586782574653625,
            -0.02560778148472309,
            0.012024963274598122,
            -0.009344748221337795,
            0.00519915483891964,
            0.04939241707324982,
            -0.019265715032815933,
            -0.0004560921515803784,
            0.0026543722487986088,
            0.04436003789305687,
            -0.032422877848148346,
            0.029284723103046417,
            -0.016992850229144096,
            -0.018047811463475227,
            -0.02636745385825634,
            -0.011864560656249523,
            0.01833389326930046,
            -0.020621096715331078,
            0.005855172406882048,
            -0.046800609678030014,
            -0.03584090620279312,
            -0.022116955369710922,
            0.023803217336535454,
            0.003089836100116372,
            0.01855900138616562,
            -0.020162079483270645,
            -0.026391828432679176,
            0.008975956588983536,
            0.02657466009259224,
            -0.02454315684735775,
            -0.027884801849722862,
            0.008031761273741722,
            0.003282653633505106,
            0.0024621663615107536,
            0.002220887690782547,
            0.026299644261598587,
            -0.0163237564265728,
            -0.01016466598957777,
            -0.027426425367593765,
            0.05384892225265503,
            -0.007855689153075218,
            0.0234579648822546,
            0.020556580275297165,
            0.015477876178920269,
            -0.041639894247055054,
            0.013917996548116207,
            -0.011622305028140545,
            0.041359346359968185,
            0.020845569670200348,
            0.041211724281311035,
            -0.02431645616889,
            0.004050054587423801,
            0.013888341374695301,
            -0.005890592932701111,
            -0.019518457353115082,
            0.01051697414368391,
            0.004367238841950893,
            -0.06465890258550644,
            0.0011536626843735576,
            -0.05150075629353523,
            -0.0449226088821888,
            -0.011807618662714958,
            -0.023940034210681915,
            -0.007773959543555975,
            0.017028216272592545,
            0.0042848484590649605,
            0.008447663858532906,
            0.004160491283982992,
            0.04228929057717323,
            -0.026566864922642708,
            -0.0201879870146513,
            -0.004176589660346508,
            -0.03365740552544594,
            -0.020747654139995575,
            -0.04106081277132034,
            -0.006367186550050974,
            -0.03130507096648216,
            -0.006229079328477383,
            -0.011662551201879978,
            -0.03175894543528557,
            -0.02233595959842205,
            0.020436596125364304,
            -0.0016595855122432113,
            0.02447098307311535,
            -0.013430756516754627,
            -0.008295047096908092,
            -0.032040033489465714,
            0.006484608631581068,
            0.003720875596627593,
            0.014927508309483528,
            0.027379317209124565,
            0.015742672607302666,
            0.011455184780061245,
            -0.02789567969739437,
            -0.014638775028288364,
            0.005262608639895916,
            -0.009053604677319527,
            -0.024129705503582954,
            -0.01339887548238039,
            0.01605570688843727,
            -0.024298492819070816,
            -0.06017790734767914,
            0.006630500312894583,
            -0.05880524218082428,
            0.03917936608195305,
            -0.011655371636152267,
            -0.008490505628287792,
            0.018252331763505936,
            -0.02171487547457218,
            -0.02511955052614212,
            -0.005300923250615597,
            -0.009225141257047653,
            0.03455802798271179,
            0.00017199690046254545,
            -0.005664641968905926,
            0.016236813738942146,
            -0.011805249378085136,
            0.025448009371757507,
            -0.013255683705210686,
            -0.05753880366683006,
            -0.01649506948888302,
            -0.012742936611175537,
            -0.02068696916103363,
            -0.025818495079874992,
            -0.05011321231722832,
            -0.009376313537359238,
            -0.014243587851524353,
            0.002352968091145158,
            -0.029927723109722137,
            0.00037138222251087427,
            0.02383393980562687,
            0.006966737098991871,
            0.0029632432851940393,
            -0.026057612150907516,
            -0.00026884677936322987,
            -0.007536342833191156,
            -0.018915018066763878,
            -0.002200599992647767,
            -0.040599480271339417,
            -0.03412601724267006,
            0.015039839781820774,
            0.0013209630269557238,
            0.007529821712523699,
            0.015528138726949692,
            0.004792573396116495,
            -0.03027147240936756,
            0.059411097317934036,
            -0.04186723008751869,
            0.03716951608657837,
            -0.03355840593576431,
            0.04260874539613724,
            0.016439363360404968,
            -0.022754093632102013,
            -0.0063874744810163975,
            0.012479289434850216,
            -0.03322947397828102,
            -0.025248929858207703,
            -0.01659456267952919,
            -0.02599269151687622,
            -0.025697289034724236,
            0.0012409859336912632,
            0.007526735309511423,
            0.023631256073713303,
            0.01621631346642971,
            0.008800085633993149,
            0.0037224125117063522,
            0.011609291657805443,
            -0.0038679102435708046,
            0.023428846150636673,
            -0.014477867633104324,
            0.0025370658840984106,
            -0.05125730112195015,
            -0.030845900997519493,
            0.0020591674838215113,
            -7.003216160228476e-05,
            -0.02180265262722969,
            -0.01368846744298935,
            -0.01941850781440735,
            0.0014624432660639286,
            -0.029030341655015945,
            -0.034495364874601364,
            -0.05960746854543686,
            -0.00263580703176558,
            0.011329080909490585,
            -0.003985178656876087,
            -0.0019913867581635714,
            0.033955253660678864,
            -0.019591959193348885,
            0.008155900985002518,
            -0.004584577865898609,
            0.032180339097976685,
            0.006910609547048807,
            -0.05830715596675873,
            0.0197035763412714,
            -0.012525591999292374,
            -0.03248609974980354,
            -0.011472629383206367,
            -0.043745338916778564,
            0.015086058527231216,
            0.017402799800038338,
            0.029606446623802185,
            0.011738510802388191,
            0.01250193640589714,
            0.015003886073827744,
            0.01727190800011158,
            -0.0015256350161507726,
            -0.014743938110768795,
            0.03179852291941643,
            -0.02207707241177559,
            -0.015895843505859375,
            0.04113594815135002,
            -0.015714792534708977,
            0.026900852099061012,
            0.00982208177447319,
            0.0034503485076129436,
            -0.005358056165277958,
            0.030730659142136574,
            0.00887711625546217,
            -0.010569573380053043,
            0.01881360076367855,
            -0.020537879317998886,
            -0.02540639601647854,
            0.03316935896873474,
            0.031010890379548073,
            -0.00725934561342001,
            -0.027276985347270966,
            -0.014946985058486462,
            -0.01956573687493801,
            -0.00800574105232954,
            0.0033959189895540476,
            -0.003402406582608819,
            -0.0012457931879907846,
            -0.033135928213596344,
            0.020819688215851784,
            0.00659608468413353,
            0.021821938455104828,
            -0.004103806335479021,
            -0.0181126706302166,
            0.05311686918139458,
            0.010939880274236202,
            -0.02199026197195053,
            0.02625505067408085,
            -0.010470508597791195,
            -0.0036812392063438892,
            0.004185075871646404,
            0.023932605981826782,
            -0.011291415430605412,
            -0.0011384447570890188,
            -0.056333716958761215,
            -0.023628178983926773,
            0.021206701174378395,
            0.0255700945854187,
            0.029884379357099533,
            0.013851149939000607,
            -0.004876601044088602,
            -0.00814481545239687,
            -0.024077679961919785,
            0.02297714166343212,
            -0.01733708754181862,
            0.06761664152145386,
            -0.037427179515361786,
            -0.020402921363711357,
            0.027437493205070496,
            -0.027976034209132195,
            0.003666424658149481,
            -0.037802405655384064,
            0.01032217312604189,
            -0.036685511469841,
            0.015711287036538124,
            -0.04746919497847557,
            -0.017879679799079895,
            -0.011655081994831562,
            -0.009824533015489578,
            -0.0732342079281807,
            -0.03410584107041359,
            -0.029686184599995613,
            0.014325919561088085,
            -0.036230262368917465,
            0.02943565510213375,
            -0.031173130497336388,
            0.026609672233462334,
            -0.0031891963444650173,
            0.0035739513114094734,
            -0.022572489455342293,
            -0.025297818705439568,
            0.0026395076420158148,
            0.0034954585134983063,
            -0.01241345889866352,
            -0.018282437697052956,
            0.04490402713418007,
            -0.009051322937011719,
            0.008987866342067719,
            -0.016695525497198105,
            0.01275685802102089,
            -0.05818209424614906,
            0.0056158145889639854,
            -0.021786415949463844,
            0.00561086181551218,
            -0.016836216673254967,
            -0.015043276362121105,
            -0.007793216500431299,
            0.009072160348296165,
            0.01602293737232685,
            0.018180953338742256,
            0.04508896544575691,
            0.01094177458435297,
            -0.015357794240117073,
            0.024387827143073082,
            -0.01769774965941906,
            0.03613521531224251,
            -0.01648503541946411,
            -0.023102816194295883,
            0.01083112508058548,
            -0.008480803109705448,
            0.015269458293914795,
            0.0265984907746315,
            -0.0012192390859127045,
            -0.011942951008677483,
            0.0026269929949194193,
            0.03263062238693237,
            -0.02363794669508934,
            -0.013872168026864529,
            0.0063165659084916115,
            0.014138148166239262,
            -0.027952272444963455,
            0.04049080237746239,
            0.0059518213383853436,
            0.04938359931111336,
            0.0004343053442426026,
            0.015846628695726395,
            -0.0717388316988945,
            0.02801831252872944,
            -0.05028878524899483,
            -0.040654413402080536,
            -0.003181125270202756,
            -0.03537199646234512,
            0.003562945406883955,
            -0.020051216706633568,
            -0.050506770610809326,
            -0.006423995830118656,
            -0.03517378121614456,
            -0.002896492835134268,
            0.05626213923096657,
            -0.0018945725169032812,
            0.005359087139368057,
            0.006805745419114828,
            -0.005189016927033663,
            0.04179364815354347,
            -0.08582381159067154,
            0.027000868692994118,
            -0.0006782932323403656,
            -0.011398552916944027,
            -0.036555420607328415,
            0.015806810930371284,
            -0.0038185729645192623,
            -0.04546832665801048,
            -0.011617060750722885,
            -0.009691906161606312,
            -0.03282017260789871,
            -0.0057271309196949005,
            0.0006390457274392247,
            0.028815539553761482,
            0.014370489865541458,
            0.011533679440617561,
            0.011248028837144375,
            0.0054886601865291595,
            -0.018588785082101822,
            0.029435958713293076,
            -0.00010556355118751526,
            0.015374261885881424,
            -0.019464537501335144,
            -0.04473060369491577,
            -0.03398851305246353,
            -0.013822104781866074,
            -0.06239406019449234,
            -0.006420359015464783,
            -0.038024116307497025,
            -0.012655984610319138,
            0.039344459772109985,
            -0.02329842746257782,
            0.004754488356411457,
            0.05105515569448471,
            -0.09022195637226105,
            -0.062057238072156906,
            -0.009686877019703388,
            0.0018787032458931208,
            -0.0021944204345345497,
            -0.03131330385804176,
            -0.019784383475780487,
            -0.03459081053733826,
            -0.049111507833004,
            -0.0033058214467018843,
            -0.005029750522226095,
            0.024381687864661217,
            0.008843544870615005,
            0.025915317237377167,
            -0.009241095744073391,
            0.00513695040717721,
            0.025431064888834953,
            -0.02399437315762043,
            0.02465582825243473,
            -0.0015591317787766457,
            0.035418953746557236,
            -0.009947591461241245,
            0.06148407235741615,
            -0.02443438395857811,
            0.06090294569730759,
            -0.03190170228481293,
            0.030920254066586494,
            0.007627484388649464,
            -0.026949487626552582,
            0.058499157428741455,
            0.014567588455975056,
            0.004957966040819883,
            -0.03699841350317001,
            0.028479382395744324,
            -0.002582915360108018,
            -0.017725616693496704,
            -0.018781250342726707,
            -0.004816967528313398,
            0.029170380905270576,
            -0.02232280559837818,
            0.01964437961578369,
            -0.004271314479410648,
            0.0392095223069191,
            -0.02209358662366867,
            -0.006252722814679146,
            -0.0033584178891032934,
            -0.03874659165740013,
            0.03488593548536301,
            0.039246201515197754,
            -0.004559734370559454,
            -0.08132241666316986,
            0.010395530611276627,
            0.0033342065289616585,
            -0.03958877548575401,
            0.003059893613681197,
            -0.020515626296401024,
            0.01491633988916874,
            -0.022932106629014015,
            0.023659348487854004,
            -0.04863715544342995,
            0.032427411526441574,
            0.030641542747616768,
            -0.022751914337277412,
            0.016942283138632774,
            0.015281789936125278,
            0.007495173253118992,
            -0.01444870326668024,
            -0.044144295156002045,
            0.005466504488140345,
            -0.016203859820961952,
            0.03190382197499275,
            0.030551835894584656,
            -0.01616141013801098,
            0.03346975892782211,
            -0.050024956464767456,
            -0.054801225662231445,
            -0.015162709169089794,
            0.023983247578144073,
            0.04187446087598801,
            -0.020432861521840096,
            -0.02744991146028042,
            0.011265060864388943,
            -0.02445155568420887,
            0.034192465245723724,
            -0.016288992017507553,
            0.007869861088693142,
            0.022284450009465218,
            0.017410090193152428,
            -0.03999911993741989,
            0.037988629192113876,
            0.02249639295041561,
            -0.004787665791809559,
            -0.01640567183494568,
            -0.031027425080537796,
            0.044307541102170944,
            0.01758977584540844,
            -0.010973385535180569,
            -0.006083719898015261,
            0.027599716559052467,
            -0.022485794499516487,
            -0.026498230174183846,
            -0.0495668463408947,
            0.00576882716268301,
            0.025656193494796753,
            0.034541334956884384,
            -0.012520182877779007,
            0.054679643362760544,
            0.007277726661413908,
            -0.008985905908048153,
            -0.0073884050361812115,
            -0.06491350382566452,
            0.0012567839585244656,
            -0.0537230484187603,
            0.05892660841345787,
            0.033807173371315,
            0.003061565337702632,
            0.01588849350810051,
            -0.08487846702337265,
            -0.008562995120882988,
            -0.023898448795080185,
            0.008702131919562817,
            -0.0030292219016700983,
            0.027711275964975357,
            0.026150349527597427,
            0.0002441744727548212,
            -0.0028810729272663593,
            -0.006526458077132702,
            -0.021534228697419167,
            0.002193429274484515,
            0.018876010552048683,
            -0.01440189965069294,
            -0.012969650328159332,
            -0.03565141186118126,
            -0.00852176919579506,
            -0.004221657291054726,
            0.005093211308121681,
            -0.017239300534129143,
            0.010662801563739777,
            -0.011675857938826084,
            0.0008357345359399915,
            0.060589663684368134,
            0.018297305330634117,
            -0.04671160504221916,
            -0.04287295788526535,
            0.007183675654232502,
            -0.0049300906248390675,
            -0.022978633642196655,
            -0.024023229256272316,
            -0.016169825568795204,
            -0.040026042610406876,
            -0.017935100942850113,
            -0.007109381724148989,
            -0.04211561754345894,
            -0.020056068897247314,
            -0.0478062704205513,
            0.016288237646222115,
            0.0010004971409216523,
            0.020969843491911888,
            -0.009565833024680614,
            0.0272789616137743,
            -0.03518892824649811,
            0.02138669602572918,
            -0.05583937466144562,
            0.03763093426823616,
            -0.054956987500190735,
            -0.004407444968819618,
            0.0431482307612896,
            -0.05525171011686325,
            0.006990774068981409,
            -0.01895757205784321,
            -0.046421270817518234,
            0.007717556320130825,
            -0.05698426812887192,
            -0.02080671861767769,
            0.013939362950623035,
            0.012244515120983124,
            0.02794128842651844,
            0.0007271020440384746,
            -0.015345071442425251,
            -0.00015204554074443877,
            -0.017385050654411316,
            -0.030102672055363655,
            -0.014445558190345764,
            -0.006846470758318901,
            -0.0391484871506691,
            -0.028653224930167198,
            -0.02543916553258896,
            -0.02844964899122715,
            0.044811248779296875,
            0.003938653971999884,
            0.03278825059533119,
            -0.0030532425735145807,
            -0.04911563917994499,
            0.01833168789744377,
            0.01608946919441223,
            -0.013947778381407261,
            -0.004414991941303015,
            -0.02827848494052887,
            0.024421347305178642,
            -0.030917173251509666,
            0.005254491697996855,
            -0.016907446086406708,
            0.030800985172390938,
            -0.013983107171952724,
            -0.020424362272024155,
            -0.027166342362761497,
            0.043115824460983276,
            0.04304375499486923,
            0.0028887006919831038,
            0.007999582216143608,
            -0.003931954503059387,
            0.05751010403037071,
            0.03244708478450775,
            0.042206767946481705
        ],
        [
            -0.0073593552224338055,
            0.022768622264266014,
            -0.028055910021066666,
            -0.0034181626979261637,
            -0.01126437820494175,
            -0.0021761939860880375,
            0.02261970192193985,
            0.058311376720666885,
            -0.0050219083204865456,
            0.03297945111989975,
            0.020993735641241074,
            0.024371784180402756,
            -0.04581556096673012,
            -0.03550693020224571,
            -0.0719020739197731,
            -0.030700918287038803,
            -0.02948167361319065,
            -0.034739911556243896,
            0.035586073994636536,
            0.009558428078889847,
            0.0001283285819226876,
            0.0102919340133667,
            0.05084912106394768,
            0.015135527588427067,
            0.01128504890948534,
            -0.0014230742817744613,
            0.008631172589957714,
            -0.010789052583277225,
            0.02059580758213997,
            0.010340975597500801,
            0.027250004932284355,
            -0.013044258579611778,
            0.020877161994576454,
            -0.0068192677572369576,
            0.03186245262622833,
            -0.012667507864534855,
            0.02117471769452095,
            -0.02657020278275013,
            -0.01890110783278942,
            0.026754550635814667,
            0.014793490059673786,
            0.026468489319086075,
            0.008856849744915962,
            -0.009128952398896217,
            0.00372539134696126,
            -0.007812891155481339,
            -0.009861307218670845,
            0.009562499821186066,
            0.02413666807115078,
            0.03171853721141815,
            0.007260648533701897,
            0.004845950286835432,
            0.049245305359363556,
            0.039801519364118576,
            -0.02850213274359703,
            -0.03311166912317276,
            0.01358440425246954,
            0.00267966091632843,
            -0.04036201909184456,
            -0.0029069185256958008,
            -0.03709632530808449,
            0.007547580171376467,
            -0.010901574045419693,
            -0.007163860835134983,
            -0.046636659651994705,
            -0.04759763181209564,
            0.029127087444067,
            -0.0029703525360673666,
            -0.002380022779107094,
            -0.02123255841434002,
            0.020830415189266205,
            0.02919091284275055,
            -0.001590368221513927,
            -0.0019574477337300777,
            -0.007555486634373665,
            -0.0317687913775444,
            0.014407670125365257,
            -0.03628776594996452,
            0.0331437811255455,
            0.019770927727222443,
            0.014349871315062046,
            0.024680091068148613,
            0.026784993708133698,
            -0.006138594821095467,
            -0.01353584136813879,
            -0.018079351633787155,
            0.023956377059221268,
            0.016070228070020676,
            -0.011711941100656986,
            0.0050895605236291885,
            0.038544751703739166,
            -0.03684629499912262,
            -0.0043869176879525185,
            0.014864286407828331,
            -0.0035748521331697702,
            -0.01011299341917038,
            0.005763564258813858,
            -0.013140672817826271,
            0.05704522505402565,
            -0.004811466205865145,
            0.006984403822571039,
            -0.0007897320319898427,
            -0.005385277327150106,
            0.01017669029533863,
            -0.0031654159538447857,
            -0.0018340515671297908,
            0.013953395187854767,
            0.01338999718427658,
            -0.0475863516330719,
            -0.02962193638086319,
            0.050349161028862,
            0.0016259723342955112,
            -0.007990550249814987,
            0.02294558472931385,
            -0.005223598796874285,
            0.023125270381569862,
            0.013459381647408009,
            -0.015172580257058144,
            -0.02090466581285,
            0.037501197308301926,
            -0.016928477212786674,
            -0.013052391819655895,
            -0.0009175920276902616,
            0.004604080226272345,
            -0.02858135476708412,
            -0.029211455956101418,
            0.02815718576312065,
            -0.011875409632921219,
            -0.016934240236878395,
            -0.03078601509332657,
            0.028533924371004105,
            0.02585737034678459,
            -0.010207269340753555,
            0.0028766116593033075,
            0.01628306694328785,
            0.012672650627791882,
            0.012085339985787868,
            -0.007876040413975716,
            0.030918296426534653,
            0.004212725907564163,
            0.017268924042582512,
            0.022741226479411125,
            0.010459423996508121,
            -0.025211667641997337,
            0.011309246532619,
            0.010287726297974586,
            -0.011146776378154755,
            -0.030550630763173103,
            0.021561725065112114,
            0.02566824108362198,
            -0.019915301352739334,
            -0.014696155674755573,
            -0.0003584790974855423,
            0.0037142173387110233,
            -0.03431936725974083,
            0.013065663166344166,
            0.02511441707611084,
            -0.003881952026858926,
            0.0402810201048851,
            0.025750013068318367,
            -0.019596925005316734,
            -0.004752690903842449,
            0.03340909257531166,
            0.02529289200901985,
            0.01851264387369156,
            0.011479743756353855,
            -0.055549636483192444,
            -0.013045639730989933,
            -0.03101770579814911,
            0.0005182353197596967,
            0.007571460213512182,
            0.006765337195247412,
            -0.0171193890273571,
            0.04037752375006676,
            -0.014729279093444347,
            -0.02219437062740326,
            -0.014958791434764862,
            -0.028344042599201202,
            0.016948958858847618,
            -0.01858891360461712,
            0.006455465219914913,
            -0.010044221766293049,
            0.013403980992734432,
            0.01941046677529812,
            0.015369510278105736,
            0.024478625506162643,
            0.02115107886493206,
            -0.025426343083381653,
            -0.025715623050928116,
            -0.009774502366781235,
            0.005247332621365786,
            -0.00925721600651741,
            -0.06276281923055649,
            0.004812633153051138,
            -0.011812694370746613,
            -0.011421671137213707,
            -0.064060740172863,
            0.001401358749717474,
            0.0021994044072926044,
            0.015687011182308197,
            0.005850294139236212,
            0.015322532504796982,
            -0.007397822104394436,
            0.012229681946337223,
            -0.0030186553485691547,
            0.00869732815772295,
            -0.04634174704551697,
            0.010964672081172466,
            0.03536205738782883,
            -0.004077428951859474,
            0.009756505489349365,
            -0.04032926633954048,
            0.02295178920030594,
            -0.014184797182679176,
            -0.013801795430481434,
            -0.030915671959519386,
            -0.005502718035131693,
            0.02686166763305664,
            -0.020262422040104866,
            -0.009828521870076656,
            -0.030069636180996895,
            0.021110910922288895,
            -0.0013861550251021981,
            -0.02014509215950966,
            0.02101745270192623,
            -0.061377495527267456,
            0.010044855996966362,
            -0.011225002817809582,
            0.011127720586955547,
            -0.005076202563941479,
            0.017293138429522514,
            0.022736016660928726,
            0.010483678430318832,
            -0.017029255628585815,
            -0.036918506026268005,
            0.014942153356969357,
            0.042702995240688324,
            0.024853898212313652,
            -0.030396629124879837,
            -0.00450159190222621,
            -0.01572524756193161,
            0.03615352511405945,
            -0.04826326295733452,
            -0.024320164695382118,
            0.029789356514811516,
            -0.017896991223096848,
            -0.022926226258277893,
            -0.008568831719458103,
            0.014672334305942059,
            0.005143982823938131,
            0.003268066793680191,
            -0.013335560448467731,
            0.006530871149152517,
            0.00506571726873517,
            -0.026365747675299644,
            0.013522296212613583,
            -0.004291420336812735,
            0.02297293022274971,
            -0.07377688586711884,
            -0.0415155366063118,
            0.001400226028636098,
            -0.04273981973528862,
            0.0054716006852686405,
            0.0400051586329937,
            0.003157688770443201,
            -0.037082307040691376,
            -0.0043396414257586,
            -0.016102604568004608,
            0.014030622318387032,
            0.014078380540013313,
            0.02653524838387966,
            -0.011109307408332825,
            -0.013254383578896523,
            0.04669821262359619,
            0.0029920637607574463,
            0.017746299505233765,
            0.024430496618151665,
            -0.021227242425084114,
            0.008538912981748581,
            -0.04103095829486847,
            0.021320577710866928,
            -0.0173852127045393,
            0.020652716979384422,
            0.004667379427701235,
            0.01272899005562067,
            0.0038822272326797247,
            -0.026340298354625702,
            0.006216634064912796,
            -0.01935780979692936,
            0.015876587480306625,
            0.02110222727060318,
            0.035111360251903534,
            -0.008347026072442532,
            -0.01193492766469717,
            -0.03540728986263275,
            0.0032412249129265547,
            -0.018993619829416275,
            0.008406844921410084,
            0.016606012359261513,
            0.009365289472043514,
            -0.017268912866711617,
            0.032594483345746994,
            -0.009034238755702972,
            -0.008550289086997509,
            0.0064858077093958855,
            -0.036672718822956085,
            -0.029136553406715393,
            -0.0067649600096046925,
            0.023729464039206505,
            -0.01955471746623516,
            -0.04480770230293274,
            0.003208732232451439,
            -0.015743466094136238,
            -0.018456637859344482,
            -0.028900915756821632,
            -0.01821945048868656,
            0.003950102720409632,
            -0.023341063410043716,
            -0.00035078349174000323,
            0.039817169308662415,
            0.02014053426682949,
            0.007571308873593807,
            -0.017549633979797363,
            0.04708798602223396,
            -0.01435798592865467,
            0.005654017440974712,
            -0.06019764021039009,
            -0.03182077407836914,
            -0.00989142432808876,
            0.030084986239671707,
            -0.032500166445970535,
            0.04094356670975685,
            0.02503974549472332,
            -0.024507589638233185,
            0.02888103388249874,
            -0.05065416544675827,
            -0.020158860832452774,
            0.01950399950146675,
            0.0029185721650719643,
            -0.0015242736553773284,
            0.017136426642537117,
            -0.005562336649745703,
            0.013938223011791706,
            0.017386343330144882,
            -0.006351291202008724,
            0.05284160375595093,
            0.00048016957589425147,
            -0.04213103652000427,
            -0.02150072157382965,
            -0.03881082311272621,
            0.04362742230296135,
            -0.021545976400375366,
            0.012214787304401398,
            0.03416254743933678,
            -0.062103282660245895,
            0.012040494941174984,
            0.013735872693359852,
            -0.017282459884881973,
            0.003822345519438386,
            -0.003082851879298687,
            -0.009466011077165604,
            0.0028697096277028322,
            0.045765552669763565,
            0.019140304997563362,
            -0.020714685320854187,
            0.025749320164322853,
            0.00929206795990467,
            -0.005164769012480974,
            -5.79744009883143e-05,
            -0.004667277913540602,
            0.00126637180801481,
            -0.008226889185607433,
            0.030680503696203232,
            0.01856272667646408,
            -0.02902865782380104,
            -0.00714052515104413,
            0.03549959883093834,
            0.022676698863506317,
            -0.0345282219350338,
            0.008091162890195847,
            0.0681953877210617,
            -0.01358211413025856,
            0.03445512428879738,
            0.01834505796432495,
            -0.03683188185095787,
            -0.0005214705015532672,
            -0.02305465191602707,
            -0.0010846408549696207,
            -0.0047385357320308685,
            0.017485763877630234,
            0.020777326077222824,
            0.045319609344005585,
            -0.044515423476696014,
            0.003344215452671051,
            -0.01760115474462509,
            -0.03666040673851967,
            0.016201576218008995,
            -0.0009949131635949016,
            0.03742442652583122,
            -0.024114180356264114,
            0.026592465117573738,
            -0.03337201476097107,
            0.002915371675044298,
            0.00782474409788847,
            -0.017179708927869797,
            0.004103474784642458,
            0.002167438855394721,
            -0.01688746176660061,
            -0.012887515127658844,
            -0.033971112221479416,
            0.0030086622573435307,
            0.009585820138454437,
            -0.008155278861522675,
            -0.025874817743897438,
            0.034226372838020325,
            -0.006544811651110649,
            0.0021871665958315134,
            -0.026666637510061264,
            0.00786647479981184,
            0.03372926265001297,
            -0.08747170120477676,
            0.012176327407360077,
            0.054222241044044495,
            0.0011322371428832412,
            -0.06215832382440567,
            -0.058912716805934906,
            -0.01092679612338543,
            -0.010330352932214737,
            0.007692000363022089,
            -0.07310235500335693,
            -0.04072660207748413,
            0.010641428641974926,
            0.02590605616569519,
            0.028439197689294815,
            -0.008846323937177658,
            0.015398399904370308,
            -0.02157275378704071,
            -0.04381789639592171,
            -0.0391436368227005,
            -0.0014128821203485131,
            -0.011518788523972034,
            -0.01608409360051155,
            0.02056567370891571,
            -0.020139995962381363,
            -0.03869467228651047,
            0.022687135264277458,
            0.01729157194495201,
            -0.030780227854847908,
            0.016776015982031822,
            0.06379828602075577,
            0.01770622469484806,
            0.02694329060614109,
            0.003354536136612296,
            -0.002857410814613104,
            0.02906317450106144,
            -0.03893977403640747,
            -0.007448738906532526,
            0.029835177585482597,
            0.0356546975672245,
            -0.04106922820210457,
            0.03004002384841442,
            0.03219645842909813,
            -0.00949123315513134,
            -0.06527625769376755,
            0.0022660009562969208,
            0.02993020787835121,
            -0.02189422957599163,
            0.0046790894120931625,
            -0.021985644474625587,
            -0.015878424048423767,
            0.014944279566407204,
            0.0032410400453954935,
            -0.04247111827135086,
            -0.016353478655219078,
            0.03344370424747467,
            -0.0765606015920639,
            0.008035434409976006,
            0.009536676108837128,
            -0.0662742629647255,
            0.04790705814957619,
            -0.001835263567045331,
            0.02658984437584877,
            0.040432315319776535,
            0.006425011903047562,
            -0.06716661900281906,
            -0.013970629312098026,
            -0.02835812047123909,
            -0.006981637794524431,
            -0.06009914353489876,
            0.000503692077472806,
            -0.016748197376728058,
            0.024387210607528687,
            0.02593199349939823,
            0.011227698065340519,
            -0.016705596819519997,
            0.029965830966830254,
            0.030200699344277382,
            -0.03220149874687195,
            0.016835326328873634,
            0.026297487318515778,
            -0.013820725493133068,
            -0.020262163132429123,
            -0.013397054746747017,
            0.030082162469625473,
            0.017766984179615974,
            0.029040934517979622,
            0.02137591503560543,
            -0.023292532190680504,
            0.016933012753725052,
            0.038681093603372574,
            -0.0011085093719884753,
            0.01268755178898573,
            -0.03293764591217041,
            0.03812326118350029,
            0.028048334643244743,
            0.023974426090717316,
            -0.016380293294787407,
            -0.01789703778922558,
            -0.028860583901405334,
            -0.025547120720148087,
            0.010104750283062458,
            0.007048655766993761,
            -0.03414122760295868,
            0.002265676623210311,
            0.025639083236455917,
            -0.027936730533838272,
            0.09815972298383713,
            0.009706343524158001,
            0.024368438869714737,
            0.025987893342971802,
            0.003559305565431714,
            0.0033567186910659075,
            -0.06463012099266052,
            -0.029078790917992592,
            0.012761414982378483,
            0.003102052491158247,
            0.02154409885406494,
            0.003383164992555976,
            0.007785510271787643,
            0.02532014437019825,
            0.014549529179930687,
            0.0390399806201458,
            0.042855340987443924,
            0.05230690538883209,
            0.018800508230924606,
            -0.03484785929322243,
            0.010341078974306583,
            -0.07524915784597397,
            0.021638380363583565,
            0.01601242646574974,
            -0.03575886785984039,
            -0.005762519780546427,
            -0.033947739750146866,
            -0.015702765434980392,
            0.02852601930499077,
            -0.005704868584871292,
            -0.08299319446086884,
            -0.024259380996227264,
            -0.009417643770575523,
            0.013754641637206078,
            -0.02400651015341282,
            -0.0031460148748010397,
            0.028111208230257034,
            0.02291407622396946,
            -0.04344329610466957,
            0.0022807151544839144,
            0.0005553562077693641,
            0.0013309740461409092,
            0.017874356359243393,
            -0.037814583629369736,
            0.023836473003029823,
            -0.02647848054766655,
            0.010408243164420128,
            -0.014471727423369884,
            0.002810148289427161,
            -0.003633026732131839,
            -0.028594283387064934,
            -0.0161057747900486,
            -0.027848457917571068,
            -0.028558775782585144,
            -0.0025719490367919207,
            0.006636605132371187,
            -0.017165668308734894,
            0.010825905948877335,
            0.008949111215770245,
            0.01628010906279087,
            0.01339789479970932,
            0.0018738452345132828,
            -0.015533617697656155,
            -0.02532525546848774,
            0.009362651966512203,
            0.011461718007922173,
            -0.031405188143253326,
            0.0035590436309576035,
            -0.003180574392899871,
            -0.00017223959730472416,
            -0.03273676335811615,
            -0.011223658919334412,
            0.02160109393298626,
            -0.0033739390783011913,
            0.015121975913643837,
            -0.010401633568108082,
            -0.012522194534540176,
            0.013881158083677292,
            0.015933077782392502,
            0.01639033854007721,
            0.020205020904541016,
            -0.025572989135980606,
            -0.004431677516549826,
            0.01735825277864933,
            0.004071837291121483,
            -0.01601579785346985,
            0.010115454904735088,
            -0.023881517350673676,
            -0.0023822328075766563,
            -9.339266398455948e-05,
            0.028449857607483864,
            -0.026982031762599945,
            -0.0326482318341732,
            -0.009031802415847778,
            -0.01252460852265358,
            -0.0010176670039072633,
            -0.011516965925693512,
            0.03214479237794876,
            0.03703027218580246,
            0.012801951728761196,
            -0.0053832270205020905,
            -0.04890662431716919,
            -6.439495336962864e-05,
            -0.01569957844913006,
            0.002211856422945857,
            -0.01465555652976036,
            0.026990415528416634,
            -0.00013373397814575583,
            0.0009534658747725189,
            0.04074036329984665,
            -0.021257586777210236,
            -0.016498064622282982,
            0.03789578378200531,
            0.01272090245038271,
            0.022651437669992447,
            -0.014484072104096413,
            -0.03880980983376503,
            0.034278105944395065,
            -0.0038045772816985846,
            0.008511081337928772,
            0.013284021988511086,
            -0.0021532068494707346,
            -0.007008326705545187,
            0.018735447898507118,
            0.03398378565907478,
            0.01608600653707981,
            0.005156772676855326,
            0.004570784047245979,
            0.0016680245753377676,
            -0.02250324748456478,
            0.015303128398954868,
            -0.029545454308390617,
            0.0032297957222908735,
            0.019914060831069946,
            0.004531551152467728,
            -0.014181730337440968,
            0.026693008840084076,
            0.019534235820174217,
            -0.017698198556900024,
            0.025198208168148994,
            -0.03842996060848236,
            -0.010087131522595882,
            -0.02964802458882332,
            -0.032534126192331314,
            0.005378102418035269,
            -0.03873985633254051,
            -0.019655801355838776,
            0.04384875297546387,
            -0.011068210937082767,
            0.0032716647256165743,
            -0.0010662813438102603,
            0.0001244556624442339,
            0.011195644736289978,
            0.012863030657172203,
            0.028989601880311966,
            0.009640924632549286,
            -0.024673650041222572,
            -0.022786227986216545,
            -0.025607679039239883,
            -0.026446780189871788,
            0.008896528743207455,
            -0.0017968678148463368,
            0.013936814852058887,
            -0.006410212721675634,
            0.03598940744996071,
            0.01627359166741371,
            -0.0029996673110872507,
            -0.03088301606476307,
            -0.01749921590089798,
            0.02705846168100834,
            0.009840351529419422,
            0.025171684101223946,
            -0.0321262963116169,
            0.027885539457201958,
            0.023759344592690468,
            0.01644764095544815,
            0.00018133418052457273,
            -0.012266700156033039,
            0.008185822516679764,
            -0.002817018423229456,
            0.00915323756635189,
            0.024740975350141525,
            -0.019309062510728836,
            -0.04465150460600853,
            -0.011312819086015224,
            -0.007418240886181593,
            0.014757763594388962,
            -0.005885602906346321,
            -0.021856939420104027,
            0.03958042338490486,
            -0.05935872346162796,
            0.012805735692381859,
            0.000546964060049504,
            -0.01910678669810295,
            0.027759363874793053,
            -0.008219602517783642,
            -0.011596099473536015,
            0.016136566177010536,
            -0.015348714776337147,
            -0.05841866135597229,
            0.010269563645124435,
            0.018600035458803177,
            -0.008347978815436363,
            -0.017063137143850327,
            0.03871000558137894,
            -0.02790718711912632,
            -0.021036960184574127,
            -0.012838306836783886,
            -0.0311279259622097,
            -0.008840984664857388,
            -0.009114935994148254,
            0.009222898632287979,
            -0.0032695347908884287,
            0.0052214921452105045,
            -0.036638855934143066,
            -0.002340104430913925,
            0.003713470185175538,
            0.028235798701643944,
            -0.007572938688099384,
            0.04314058646559715,
            -0.047672830522060394,
            -0.015207887627184391,
            0.014124150387942791,
            -0.012197340838611126,
            0.015817923471331596,
            0.01297296304255724,
            0.0009351864573545754,
            0.06006159633398056,
            0.012295760214328766,
            0.009730259887874126,
            0.036804039031267166,
            -0.00038759352173656225,
            -0.036998357623815536,
            0.04249618574976921,
            -0.022936994209885597,
            0.04415283724665642,
            0.01970534585416317,
            0.008188995532691479,
            -0.007717193104326725,
            0.009673472493886948,
            -0.0038540279492735863,
            -0.017383957281708717,
            0.014786532148718834,
            0.021637337282299995,
            0.026560010388493538,
            0.06433092057704926,
            0.015293028205633163,
            0.008075978606939316,
            -0.012707103043794632,
            0.020091397687792778,
            -0.020244847983121872,
            0.01741921529173851,
            0.009229624643921852,
            -0.0009786853333935142,
            0.06877608597278595,
            -0.010954814031720161,
            -0.03461813926696777,
            0.04310322180390358,
            -0.02338792197406292,
            -0.01548774167895317,
            0.001610986073501408,
            -0.004100595600903034,
            -0.017345229163765907,
            -0.035851601511240005,
            0.002195056527853012,
            -0.006429553497582674,
            -0.016584644094109535,
            -0.01291702315211296,
            0.07712692767381668,
            0.016436895355582237,
            -0.017420247197151184,
            9.06142158783041e-05,
            -0.01169564574956894,
            0.0020346627570688725,
            -0.02722855657339096,
            -0.03675050660967827,
            -0.0030516148544847965,
            0.04509725794196129,
            -0.025326361879706383,
            -0.02216837741434574,
            -0.07439780235290527,
            0.03399675711989403,
            -0.013761582784354687,
            0.010022549889981747,
            0.02293628454208374,
            0.03307875990867615,
            0.0288163460791111,
            -0.024684611707925797,
            -0.02365429326891899,
            0.004064237233251333,
            -0.018814872950315475,
            -0.03360437601804733,
            -0.003596535651013255,
            -0.07968004792928696,
            0.010032141581177711,
            0.018198100849986076,
            -0.04022366553544998,
            -0.023625873029232025,
            -0.006823452189564705,
            -0.009275640361011028,
            0.01355048455297947,
            -0.013653510250151157,
            0.01474055927246809,
            0.009047538973391056,
            0.0367203913629055,
            0.030146010220050812,
            -0.013366151601076126,
            -0.018796293064951897,
            -0.01709367334842682,
            0.029314661398530006,
            -0.04240236058831215,
            0.013201183639466763,
            0.013673294335603714,
            -0.011012728326022625,
            0.0033374724444001913,
            0.012276493944227695,
            0.011885453015565872,
            -0.018060695379972458,
            0.006359472870826721,
            -0.014639344066381454,
            0.006711353547871113,
            -0.006172999273985624,
            -0.009803103283047676,
            0.009243193082511425,
            -0.02866416424512863,
            -0.006887335330247879,
            -0.023636257275938988,
            -0.009977192617952824,
            -0.0019583129324018955,
            0.009291446767747402,
            0.004786831326782703,
            -0.01233107689768076,
            0.013809259049594402,
            -0.02794426493346691,
            0.015152525156736374,
            0.04331355541944504,
            -0.03633851185441017,
            0.04194347932934761,
            -0.021045103669166565,
            -0.03467697277665138,
            0.010374421253800392,
            -0.052489135414361954,
            0.001278354087844491,
            0.047633346170186996,
            -0.03357502073049545,
            0.014750183559954166,
            -0.02945864200592041,
            0.03262082859873772,
            0.005137069616466761,
            -0.01484132930636406,
            -0.00019583453831728548,
            0.03237561136484146,
            -0.007639539428055286,
            0.0032206380274146795,
            -0.003114755265414715,
            0.04935391992330551,
            0.0008076685480773449,
            -0.015440222807228565,
            0.01908908784389496,
            0.019091079011559486,
            -0.015980105847120285,
            0.06518274545669556,
            -0.018459659069776535,
            0.011247298680245876,
            0.033262934535741806,
            -0.016646767035126686,
            0.009939160197973251,
            -0.02510504052042961,
            0.004412403795868158,
            -0.007815093733370304,
            -0.0002695026923902333,
            0.019964229315519333,
            -0.002864609006792307,
            0.013997198082506657,
            0.012454817071557045,
            -0.02675676718354225,
            -0.02090202085673809,
            0.008210835978388786,
            0.01914219744503498,
            0.021973948925733566,
            -0.034062355756759644,
            -0.006566784810274839,
            0.00768498657271266,
            0.021524766460061073,
            0.010516849346458912,
            -0.005882881581783295,
            0.008829575963318348,
            -0.03880181908607483,
            -0.050042230635881424,
            -0.00954634603112936,
            0.007910257205367088,
            0.03366499021649361,
            0.023916082456707954,
            -0.008902854286134243,
            -0.00354580907151103,
            0.033326297998428345,
            -0.04800853133201599,
            -0.01697533391416073,
            0.021249141544103622,
            0.0015206546522676945,
            -0.03713216632604599,
            0.026088453829288483,
            -0.015425936318933964,
            0.00478794751688838,
            0.008995567448437214,
            0.017789609730243683,
            0.009535802528262138,
            0.0014983303844928741,
            0.07316447049379349,
            -0.017245424911379814,
            -0.023853803053498268,
            0.035840101540088654,
            0.03193103149533272,
            0.012065235525369644,
            0.017174361273646355,
            -0.015413027256727219,
            -0.02812143601477146,
            -0.022480536252260208,
            0.019320257008075714,
            -0.0032516305800527334,
            -0.0066873603500425816,
            0.0056455316953361034,
            -0.01961234025657177,
            -0.03043001890182495,
            -0.02121013216674328,
            0.02333751879632473,
            0.018237102776765823,
            0.01212142501026392,
            0.00867824349552393,
            0.029497692361474037,
            -0.019748467952013016,
            0.038814425468444824,
            0.0431172214448452,
            -0.0121228639036417,
            -0.02874492108821869,
            0.020877376198768616,
            0.037514276802539825,
            0.04245765134692192,
            0.012011876329779625,
            0.0032716188579797745,
            -0.04277230426669121,
            0.02031722106039524,
            -0.015426878817379475,
            -0.004896815866231918,
            0.0022655457723885775,
            -0.002832097467035055,
            -0.028063079342246056,
            0.012732825241982937,
            -0.005736858583986759,
            -0.022492999210953712,
            0.008484174497425556,
            -0.019924847409129143,
            -0.04036908969283104,
            -0.020763037726283073,
            -0.059324152767658234,
            0.04202279448509216,
            0.03321004658937454,
            0.01591743342578411,
            0.014042960479855537,
            -0.031299736350774765,
            -0.01541802380234003,
            0.038540203124284744,
            -0.0025524687953293324,
            0.012848562560975552,
            0.023118581622838974,
            -0.016043420881032944,
            -0.04482634365558624,
            -0.01541557814925909,
            0.0346621535718441,
            0.01143679954111576,
            -0.05409489944577217,
            -0.01221669651567936,
            0.012805690988898277,
            -0.019040364772081375,
            -0.019485969096422195,
            -0.05469324812293053,
            -0.017370836809277534,
            -0.012578812427818775,
            -0.006142376456409693,
            -0.02838742360472679,
            0.013633517548441887,
            0.013557597994804382,
            -0.0034202199894934893,
            -0.018459908664226532,
            0.007533394265919924,
            -0.013590744696557522,
            0.004223364405333996,
            0.0054897405207157135,
            0.019378352910280228,
            -0.03654821962118149,
            -0.040244210511446,
            -0.027455266565084457,
            -0.011478633619844913,
            0.015980608761310577,
            0.00778881274163723,
            -0.08080745488405228,
            0.013322383165359497,
            -0.006163481622934341
        ],
        [
            0.027656417340040207,
            -0.037536054849624634,
            0.02103486843407154,
            -0.01709771901369095,
            -0.022360697388648987,
            -0.0292491652071476,
            -0.023725466802716255,
            -0.03422107174992561,
            0.013192464597523212,
            0.022806202992796898,
            -0.021010084077715874,
            -0.018232541158795357,
            0.014018174260854721,
            0.03655412793159485,
            -0.02765068970620632,
            0.004597266670316458,
            0.0016712247161194682,
            0.030918553471565247,
            -0.028565766289830208,
            0.012522108852863312,
            -0.03639000654220581,
            -0.003797399578616023,
            -0.02775947004556656,
            0.0030367905274033546,
            -0.01864103600382805,
            -0.026142409071326256,
            -0.0232077669352293,
            0.016653843224048615,
            0.012608282268047333,
            -0.014913849532604218,
            -0.010906842537224293,
            0.015968332067131996,
            -0.02328888326883316,
            0.013100175186991692,
            0.0069792489521205425,
            -0.0028313256334513426,
            -0.023598575964570045,
            0.008947276510298252,
            -0.03220205008983612,
            -0.01596057042479515,
            -0.00964350625872612,
            -0.0017214281251654029,
            0.029220806434750557,
            -0.02342338114976883,
            0.02344614453613758,
            -0.03443022817373276,
            0.014027925208210945,
            0.04992574453353882,
            -0.012331272475421429,
            -0.004057488404214382,
            0.00815165787935257,
            -0.017044955864548683,
            0.023044321686029434,
            -0.02238355576992035,
            0.02678556554019451,
            0.00997539609670639,
            -0.006993722636252642,
            -0.008391684852540493,
            -0.001761459861882031,
            0.015198667533695698,
            0.012422751635313034,
            0.004768901038914919,
            0.018169015645980835,
            -0.011424927040934563,
            -0.002967176027595997,
            0.03237074986100197,
            -0.0006323577836155891,
            -0.02754155360162258,
            -0.0026251773815602064,
            0.03351031243801117,
            0.009803380817174911,
            -0.02394937165081501,
            -0.02397327497601509,
            0.017320338636636734,
            -0.004906332120299339,
            0.008308537304401398,
            0.0177789144217968,
            -0.035510171204805374,
            0.012600275687873363,
            0.00276266410946846,
            0.03105972707271576,
            0.00555554311722517,
            0.02773675136268139,
            -0.025469215586781502,
            0.005638717673718929,
            0.030800681561231613,
            -0.03809363767504692,
            -0.022929124534130096,
            -0.009174861013889313,
            -0.03401114419102669,
            -0.014213471673429012,
            -0.01609303429722786,
            0.005020462442189455,
            0.040043022483587265,
            0.029441891238093376,
            0.01800326257944107,
            -0.01684899814426899,
            0.004086363594979048,
            -0.0777120590209961,
            0.01596043072640896,
            0.016540711745619774,
            0.03220096230506897,
            -0.006270376965403557,
            0.015886956825852394,
            -0.03480781987309456,
            -0.011775363236665726,
            0.020462652668356895,
            0.03736916929483414,
            -0.035921599715948105,
            -0.007867198437452316,
            -0.01723603531718254,
            0.012958837673068047,
            0.023867996409535408,
            -0.014361373148858547,
            -0.06566013395786285,
            0.03109608218073845,
            0.018722480162978172,
            0.0037640805821865797,
            -0.010838538408279419,
            0.01982364058494568,
            0.023882117122411728,
            0.021850449964404106,
            0.004165107384324074,
            0.010561499744653702,
            -0.02553521655499935,
            -0.022464649751782417,
            -0.024738142266869545,
            0.021309902891516685,
            -0.0226505845785141,
            0.001716348808258772,
            0.004000290762633085,
            0.015611943788826466,
            -0.022948842495679855,
            -0.03591635450720787,
            -0.01017469260841608,
            -0.006798099260777235,
            -0.018837103620171547,
            0.01105793472379446,
            0.01997791975736618,
            0.008464237675070763,
            0.012140878476202488,
            0.0008353000157512724,
            -0.013720248825848103,
            -0.018341219052672386,
            -0.03431401401758194,
            0.01103781908750534,
            0.029958155006170273,
            0.016511408612132072,
            -0.0030480052810162306,
            0.014482997357845306,
            0.0031541541684418917,
            0.008954768069088459,
            -0.003124275477603078,
            0.04350123926997185,
            0.03298841416835785,
            0.004053498152643442,
            0.007801355328410864,
            0.01809963583946228,
            -0.02588389813899994,
            -0.01884172484278679,
            0.026464134454727173,
            -0.02251947484910488,
            0.02487216517329216,
            0.008555404841899872,
            -0.006501523777842522,
            0.034606147557497025,
            0.02963704988360405,
            0.02524466998875141,
            0.03531300276517868,
            0.023413855582475662,
            0.03551492094993591,
            -0.004359230864793062,
            -0.021223895251750946,
            -0.0029451833106577396,
            -0.036109305918216705,
            0.031127074733376503,
            0.005009767133742571,
            0.021319011226296425,
            0.022990422323346138,
            0.0006437210831791162,
            0.0055809421464800835,
            0.021128274500370026,
            0.010066266171634197,
            0.01038977038115263,
            -0.023493856191635132,
            0.005586098413914442,
            0.005793208256363869,
            0.012106540612876415,
            -0.021913155913352966,
            0.02402978390455246,
            0.016263870522379875,
            -0.006905660964548588,
            -0.020711475983262062,
            -0.01971048302948475,
            -0.02290092408657074,
            -0.023361599072813988,
            0.010036175139248371,
            -0.012948707677423954,
            0.03342714533209801,
            0.016115732491016388,
            -0.03658163547515869,
            -0.016562065109610558,
            -0.024252450093626976,
            0.0010159413795918226,
            -0.0034707190934568644,
            -0.019743185490369797,
            -0.03744408115744591,
            0.0297361072152853,
            -0.005656200461089611,
            0.016561705619096756,
            -0.029463784769177437,
            0.029144592583179474,
            -0.014722251333296299,
            -0.010563026182353497,
            -0.018045971170067787,
            -0.026196936145424843,
            0.015692152082920074,
            0.006983617786318064,
            0.01872417703270912,
            -0.017448922619223595,
            0.027423547580838203,
            0.0033059539273381233,
            0.03845469281077385,
            -0.010295284911990166,
            0.029746204614639282,
            -0.04325507581233978,
            0.02648227848112583,
            -0.005902289412915707,
            -0.014829151332378387,
            2.553545891714748e-05,
            0.013587530702352524,
            0.013097581453621387,
            0.007498975843191147,
            0.020643670111894608,
            -0.0371163934469223,
            0.016486842185258865,
            -0.015212597325444221,
            0.02769322693347931,
            0.015455394983291626,
            0.001957046566531062,
            -0.02699027769267559,
            -0.014679056592285633,
            0.026513896882534027,
            0.015793656930327415,
            0.02905578911304474,
            -0.0011557108955457807,
            -0.020637689158320427,
            0.01772417686879635,
            -0.01756107620894909,
            -0.0010198411764577031,
            -0.025433653965592384,
            0.02571900747716427,
            0.019540460780262947,
            -0.025342518463730812,
            -0.01790616288781166,
            0.013764702714979649,
            0.01020675990730524,
            0.0033280032221227884,
            -0.005647812504321337,
            -0.009036953561007977,
            -0.02491343580186367,
            0.019687553867697716,
            0.012785442173480988,
            0.007186312694102526,
            0.0388663150370121,
            0.01564660295844078,
            0.02882845140993595,
            0.028982562944293022,
            -0.01738312467932701,
            0.037065353244543076,
            0.00372065301053226,
            0.0006682600942440331,
            0.02530854567885399,
            0.004533353261649609,
            0.03090006299316883,
            0.033839527517557144,
            0.005200299434363842,
            -0.01148508582264185,
            -0.04295862466096878,
            -0.006701341830193996,
            0.02371809259057045,
            0.0035794556606560946,
            -0.014227173291146755,
            0.040073495358228683,
            -0.008371477015316486,
            -0.03465796262025833,
            0.0025154834147542715,
            0.007527247536927462,
            0.011236285790801048,
            -0.017751291394233704,
            -0.02521282248198986,
            0.036322809755802155,
            -0.020180048421025276,
            0.007038612850010395,
            0.03511441871523857,
            -0.02866259589791298,
            0.02047981321811676,
            0.01810159534215927,
            -0.02834366075694561,
            0.02066066861152649,
            -0.020038116723299026,
            0.006072917953133583,
            -0.01702573336660862,
            0.029260806739330292,
            0.03154304251074791,
            -0.005355140659958124,
            0.04185672104358673,
            -0.00545004615560174,
            -0.018430493772029877,
            -0.021271632984280586,
            0.04234981909394264,
            -0.02998332679271698,
            -0.002320966450497508,
            -0.017764637246727943,
            -0.0109791848808527,
            0.029274625703692436,
            0.013921846635639668,
            0.009087140671908855,
            -0.004523195791989565,
            -0.02781101129949093,
            -0.017563732340931892,
            0.02910958230495453,
            0.04450226575136185,
            -0.016595417633652687,
            0.029095608741044998,
            -0.02930561453104019,
            0.0025888339150696993,
            -0.0010823369957506657,
            0.0389651358127594,
            -0.009679031558334827,
            -0.008531874977052212,
            -0.0003927176585420966,
            -0.004101184196770191,
            -0.005277121439576149,
            -0.00488610565662384,
            0.00882959645241499,
            0.029881399124860764,
            0.02109062299132347,
            0.003447882132604718,
            0.020364148542284966,
            0.020686766132712364,
            0.04769067466259003,
            -0.030083155259490013,
            -0.022982263937592506,
            -0.03316633775830269,
            -0.00945250317454338,
            -0.0249787587672472,
            -0.00888301245868206,
            0.0023858642671257257,
            -0.03375142812728882,
            -0.0004238184483256191,
            0.016567343845963478,
            0.01981884427368641,
            -0.03894311934709549,
            -0.01171186938881874,
            -0.01495912205427885,
            -0.0018449696945026517,
            0.03511098027229309,
            -0.014467325061559677,
            0.011801940388977528,
            -0.022423716261982918,
            -0.0023089260794222355,
            0.009699404239654541,
            -0.030478648841381073,
            0.01643635891377926,
            0.027575422078371048,
            -0.0013443944044411182,
            -0.013892807066440582,
            0.02027714252471924,
            0.03687567636370659,
            0.024469567462801933,
            0.013700279407203197,
            -0.0034178094938397408,
            0.00841477420181036,
            0.0009249940048903227,
            0.014302222989499569,
            0.020210182294249535,
            -0.01342218741774559,
            -0.02158636972308159,
            0.0008260368485935032,
            -0.005919481161981821,
            0.009919257834553719,
            0.007057797163724899,
            0.008987155742943287,
            -0.010553923435509205,
            -0.034035634249448776,
            0.001581246149726212,
            0.023329701274633408,
            -0.015296922996640205,
            0.0021319955121725798,
            0.017359530553221703,
            -0.034756679087877274,
            -0.025428062304854393,
            -0.013746777549386024,
            0.018537895753979683,
            0.03494725003838539,
            0.013889582827687263,
            0.0019469825783744454,
            -0.010085019282996655,
            0.005534050986170769,
            -0.018988637253642082,
            0.005279520992189646,
            0.014535564929246902,
            -0.01588919572532177,
            -0.01769227534532547,
            -0.01682790368795395,
            -0.022779516875743866,
            0.007382970303297043,
            -0.02128327451646328,
            0.004420503508299589,
            -0.005696916952729225,
            0.009839600883424282,
            0.001920135342516005,
            -0.0010636175284162164,
            -0.00013409616076387465,
            -0.00336651224642992,
            0.007550607901066542,
            -0.0032488787546753883,
            -0.021327249705791473,
            0.02611493319272995,
            -0.00717579759657383,
            -0.0012113302946090698,
            -0.010016486980021,
            0.016976086422801018,
            0.03356727585196495,
            -7.822885527275503e-05,
            0.020843656733632088,
            0.020220395177602768,
            0.006610310170799494,
            -0.011452505365014076,
            0.041648607701063156,
            -0.006728003267198801,
            0.011130400002002716,
            -0.054783184081315994,
            0.030931981280446053,
            -0.018320059403777122,
            0.033939920365810394,
            0.008947896771132946,
            0.013514873571693897,
            -0.000303607783280313,
            0.0124616464599967,
            0.014869693666696548,
            0.0018192639108747244,
            0.015196280553936958,
            -0.02852337621152401,
            0.02281852811574936,
            -0.0026865550316870213,
            -0.0621047168970108,
            -0.0012544766068458557,
            -0.018335264176130295,
            0.00447571836411953,
            -0.02422228269279003,
            0.038248706609010696,
            0.019505614414811134,
            0.04086294770240784,
            0.007893922738730907,
            0.006355458404868841,
            -0.003714973106980324,
            0.013850393705070019,
            -0.021511206403374672,
            0.02255214750766754,
            -0.006322966888546944,
            -0.008783110417425632,
            -0.0008443508995696902,
            -0.025342509150505066,
            0.009283377788960934,
            -0.021296750754117966,
            0.0022468341048806906,
            0.03039778769016266,
            0.0007761317538097501,
            -0.02896210364997387,
            -0.020157156512141228,
            0.028822124004364014,
            0.02548081986606121,
            -0.009538479149341583,
            -0.014423144049942493,
            -0.012961149215698242,
            0.037051972001791,
            -0.0145733542740345,
            0.031571950763463974,
            -0.020905746147036552,
            0.002875105245038867,
            0.04294562339782715,
            -0.009110973216593266,
            0.024597464129328728,
            0.013430226594209671,
            -0.0046567656099796295,
            0.02243102714419365,
            0.019527962431311607,
            0.003739256178960204,
            0.0248480923473835,
            0.022212427109479904,
            0.02521444857120514,
            -0.009920105338096619,
            -0.03482687100768089,
            -0.0010164635023102164,
            0.02024993486702442,
            0.00764072360470891,
            -0.014715730212628841,
            -0.03603652864694595,
            0.018993183970451355,
            0.024497386068105698,
            -0.02983417548239231,
            -0.0010186536237597466,
            0.021746760234236717,
            -0.029542380943894386,
            0.023881729692220688,
            0.028840595856308937,
            -0.009040647186338902,
            0.007387241348624229,
            0.0020031570456922054,
            -0.002290103817358613,
            -0.024922585114836693,
            0.021134410053491592,
            -0.027799386531114578,
            -0.011664727702736855,
            -0.0009652936714701355,
            -0.025023693218827248,
            -0.0003874310932587832,
            -0.029099062085151672,
            -0.023029441013932228,
            -0.002240858506411314,
            0.02733948826789856,
            0.024011163040995598,
            -0.01437967550009489,
            -0.014003850519657135,
            -0.029133306816220284,
            0.025458186864852905,
            0.002787592587992549,
            -0.0016607054276391864,
            0.022214211523532867,
            0.029778648167848587,
            -0.04263022914528847,
            -0.02784775011241436,
            -0.026383191347122192,
            -0.024339495226740837,
            0.02032010816037655,
            0.023621466010808945,
            -0.02257334068417549,
            0.018547244369983673,
            -0.016121169552206993,
            -0.027309978380799294,
            -0.018636468797922134,
            -0.0005906575825065374,
            -0.02419259212911129,
            0.056449245661497116,
            0.018839716911315918,
            0.011178644374012947,
            -0.024421503767371178,
            0.003272355766966939,
            0.011529823765158653,
            0.003214907366782427,
            0.039727773517370224,
            -0.0064471387304365635,
            -0.002821143716573715,
            -0.007908638566732407,
            -0.018435504287481308,
            0.012741485610604286,
            -0.022193724289536476,
            -0.017493026331067085,
            0.023670228198170662,
            0.01935913972556591,
            0.009200051426887512,
            -0.05358561873435974,
            0.022187823429703712,
            0.032716576009988785,
            0.03918348625302315,
            0.03297216445207596,
            0.02042766474187374,
            0.03262932598590851,
            -0.02183426357805729,
            -0.011368081904947758,
            0.006434599868953228,
            -0.003298464696854353,
            0.002413879381492734,
            0.032828375697135925,
            0.03016994334757328,
            -0.020741239190101624,
            0.025935010984539986,
            -0.02553008683025837,
            -0.017542719841003418,
            -0.03097115084528923,
            0.027509311214089394,
            0.026276711374521255,
            0.017832865938544273,
            -0.021032443270087242,
            0.0035132046323269606,
            0.032377202063798904,
            0.01869579218327999,
            0.02268601395189762,
            0.025351310148835182,
            -0.022738017141819,
            0.00014914550411049277,
            -0.025592835620045662,
            -0.007237102370709181,
            0.019921213388442993,
            0.0012096477439627051,
            -0.022368356585502625,
            -0.01571463793516159,
            0.03402102366089821,
            0.0049611832946538925,
            -0.005318129435181618,
            0.024674370884895325,
            0.008542045019567013,
            0.027179820463061333,
            0.02419237419962883,
            -0.004163370002061129,
            -0.014735445380210876,
            0.031236551702022552,
            0.020054418593645096,
            0.014184286817908287,
            0.0032902841921895742,
            0.027460256591439247,
            -0.01588553749024868,
            -0.026862917467951775,
            -0.04726770892739296,
            0.005386881995946169,
            -0.010136093944311142,
            -0.003605577163398266,
            -0.012534327805042267,
            0.026477055624127388,
            0.032765597105026245,
            -0.01736612245440483,
            0.022436702623963356,
            0.03431465104222298,
            0.0033345140982419252,
            0.02770196460187435,
            -0.013816478662192822,
            -0.015591385774314404,
            0.014262096025049686,
            -0.020907355472445488,
            -0.015155424363911152,
            -0.012912739999592304,
            0.020417189225554466,
            -0.033452630043029785,
            0.018894439563155174,
            -0.008251284249126911,
            -0.02933565527200699,
            -0.03540954366326332,
            -0.01675650291144848,
            0.03343098610639572,
            0.0036172352265566587,
            -0.039120517671108246,
            0.0198475681245327,
            0.004872600082308054,
            -0.02403487265110016,
            -0.013879337348043919,
            0.02773292548954487,
            -0.003963048569858074,
            -0.0013984926044940948,
            -0.022649507969617844,
            0.00542271975427866,
            -0.026628021150827408,
            -0.0128224678337574,
            0.024264665320515633,
            0.02223551832139492,
            0.01872718147933483,
            0.031471095979213715,
            0.0004890531999990344,
            0.01642840914428234,
            0.01892213337123394,
            0.0024852771311998367,
            0.011320938356220722,
            0.00831669569015503,
            0.01563066802918911,
            -0.0030287671834230423,
            0.016590988263487816,
            0.004866148345172405,
            0.03324710577726364,
            -0.01935390941798687,
            0.012263837270438671,
            0.061177607625722885,
            -0.013889120891690254,
            0.01634097844362259,
            -0.028354911133646965,
            0.0028392085805535316,
            0.0067988550290465355,
            -0.011112114414572716,
            0.0006805903394706547,
            -0.028093982487916946,
            -0.017958680167794228,
            -0.010980531573295593,
            -0.022701654583215714,
            -0.0021599780302494764,
            -0.03687407821416855,
            -0.009869811125099659,
            -0.02787819877266884,
            0.008505027741193771,
            0.04205169156193733,
            0.027266694232821465,
            -0.028883125633001328,
            0.009064387530088425,
            0.02319125272333622,
            -0.018353041261434555,
            0.006621889770030975,
            -0.024736743420362473,
            -0.018280422315001488,
            -0.019745787605643272,
            -0.011689357459545135,
            -0.006892504170536995,
            -0.02292613312602043,
            0.033800218254327774,
            0.011149542406201363,
            0.03902703523635864,
            0.02644404023885727,
            -0.017432717606425285,
            -0.010859004221856594,
            -0.011234807781875134,
            0.02139679156243801,
            0.010860322043299675,
            0.02677026577293873,
            0.002284399000927806,
            0.0005983259179629385,
            0.03407779335975647,
            0.03509971499443054,
            -0.044936176389455795,
            -0.009854395873844624,
            -0.01120809093117714,
            -0.013079151511192322,
            0.006290525663644075,
            -0.022082321345806122,
            0.022500762715935707,
            -0.023950757458806038,
            -0.02859015204012394,
            -0.021343503147363663,
            0.015329458750784397,
            -0.02117217890918255,
            0.014707609079778194,
            0.01585555076599121,
            -0.024608971551060677,
            -0.0027362441178411245,
            0.005568942055106163,
            0.02061251737177372,
            -0.013630617409944534,
            -0.015210594050586224,
            -0.0062284511514008045,
            -0.010579132474958897,
            0.0003122654452454299,
            0.023886466398835182,
            -0.002897217171266675,
            -0.003659977810457349,
            0.022095993161201477,
            -0.0169368963688612,
            0.026587650179862976,
            0.02252115309238434,
            0.0008897538064047694,
            0.01927102357149124,
            0.02589656598865986,
            0.018013734370470047,
            -0.019374100491404533,
            0.01768552139401436,
            0.003308783983811736,
            -0.027305282652378082,
            -0.04315446689724922,
            0.022403772920370102,
            0.03732478618621826,
            -0.024861956015229225,
            -0.053120698779821396,
            0.022515689954161644,
            0.018622344359755516,
            -0.025158250704407692,
            9.511958342045546e-05,
            0.02069164626300335,
            0.025416627526283264,
            0.020611567422747612,
            0.006031678058207035,
            -0.002360996790230274,
            0.00989085715264082,
            0.018324274569749832,
            -0.006720168050378561,
            0.030959345400333405,
            0.016374068334698677,
            0.011473515070974827,
            0.016097910702228546,
            0.0035132071934640408,
            -0.00545151811093092,
            0.028987884521484375,
            -0.04989900439977646,
            -0.031148342415690422,
            0.018996519967913628,
            -0.02211996726691723,
            -0.026759613305330276,
            0.006202698685228825,
            -0.001436829217709601,
            0.0027887786272913218,
            -0.002290295669808984,
            0.0325072705745697,
            0.005233069881796837,
            -0.002162856049835682,
            0.014954343438148499,
            0.009251097217202187,
            -0.014062500558793545,
            0.01648312620818615,
            -0.04078284278512001,
            -0.012974263168871403,
            -0.04390496388077736,
            -0.014140594750642776,
            0.014101020991802216,
            -0.00815406534820795,
            0.021134957671165466,
            -0.015642348676919937,
            -0.02911514788866043,
            0.015094203874468803,
            0.006497174967080355,
            0.005788123235106468,
            0.030080175027251244,
            0.010294030420482159,
            -0.0040810368955135345,
            -0.013893460854887962,
            0.03188727796077728,
            -0.01293958630412817,
            -0.03435267135500908,
            0.0027479873970150948,
            0.020113466307520866,
            0.008374340832233429,
            -0.0031979430932551622,
            0.013073328882455826,
            -0.023350458592176437,
            0.02147400565445423,
            0.01785387471318245,
            -0.017624717205762863,
            -0.03683682158589363,
            0.01653362065553665,
            -0.0003626640245784074,
            -0.0134427510201931,
            0.003993496298789978,
            -0.03144145384430885,
            -0.020551614463329315,
            -0.008674249984323978,
            0.018303560093045235,
            0.014951315708458424,
            0.007835973054170609,
            -0.011144440621137619,
            0.025955382734537125,
            0.029777593910694122,
            0.024404900148510933,
            0.008303469978272915,
            0.009533951058983803,
            -0.027361618354916573,
            0.005183938425034285,
            0.025193991139531136,
            0.02124468795955181,
            0.028391681611537933,
            -0.007937164977192879,
            0.010168449953198433,
            0.013012668117880821,
            -0.04679621756076813,
            0.009074272587895393,
            -0.030975772067904472,
            -0.008788534440100193,
            0.005506127141416073,
            0.009651162661612034,
            0.03473914414644241,
            0.01421030517667532,
            -0.013431278988718987,
            -0.028819464147090912,
            0.020179904997348785,
            -0.01953870989382267,
            0.02639680728316307,
            -0.012942210771143436,
            -0.00840594619512558,
            0.026032457128167152,
            -0.014867590740323067,
            -0.0005497533711604774,
            0.011325397528707981,
            0.0034551117569208145,
            0.029889525845646858,
            -0.017646489664912224,
            -0.01107888575643301,
            -0.006534868851304054,
            0.023119863122701645,
            0.0005965063464827836,
            -0.024555249139666557,
            -0.038072261959314346,
            0.011607483960688114,
            -0.005142902955412865,
            -0.012471086345613003,
            -0.041924286633729935,
            -0.025371810421347618,
            0.01000830065459013,
            -0.015912799164652824,
            -0.008788536302745342,
            0.011080389842391014,
            -0.030118238180875778,
            0.0232516061514616,
            0.0029353885911405087,
            0.008020208217203617,
            -0.03031131438910961,
            0.03411848470568657,
            -0.0285186730325222,
            -0.010801764205098152,
            0.01920829527080059,
            0.015401987358927727,
            0.05501773580908775,
            -0.04367070272564888,
            -0.026564430445432663,
            -0.024300592020154,
            -0.03181041032075882,
            0.0004049945273436606,
            -0.019869700074195862,
            0.016775058582425117,
            -0.020435363054275513,
            0.030694790184497833,
            -0.010677293874323368,
            -0.021789396181702614,
            0.0028646253049373627,
            0.004598935600370169,
            0.008419018238782883,
            0.021315153688192368,
            -0.017551781609654427,
            -0.03709401935338974,
            -0.019805727526545525,
            -0.032999102026224136,
            0.020481856539845467,
            0.011041327379643917,
            0.03351674973964691,
            -0.019216861575841904,
            0.006845811847597361,
            0.024638675153255463,
            0.022587714716792107,
            -0.012553680688142776,
            -0.01659553311765194,
            0.0009509939118288457,
            -0.004027436953037977,
            -0.017156176269054413,
            0.010926185175776482,
            0.010274844244122505,
            0.02597416564822197,
            -0.008231420069932938,
            -0.010410790331661701,
            -0.025856101885437965,
            0.02791077084839344,
            0.025562413036823273,
            0.026868876069784164,
            0.014976891689002514,
            -0.025936804711818695,
            0.023796025663614273,
            -0.03613099083304405,
            0.027389613911509514,
            -0.010602391324937344,
            -0.0010322026209905744,
            0.016385065391659737,
            -0.011506381444633007,
            -0.008950219489634037,
            0.03501606732606888,
            -0.02698685973882675,
            0.02854696661233902,
            -0.023707032203674316,
            -0.01039873156696558,
            0.03777635470032692,
            -0.005120533984154463,
            -0.005028230603784323,
            -0.027161451056599617,
            0.019602566957473755,
            0.00029045590781606734,
            0.03652734309434891,
            0.030140643939375877,
            0.020401906222105026,
            -0.022166455164551735,
            -0.027820777148008347,
            -0.004978734068572521,
            -0.003298478666692972,
            0.03273354843258858,
            -0.016321096569299698,
            -0.014448704198002815,
            0.021664831787347794,
            -0.025210479274392128,
            -0.025177152827382088,
            -0.015269116498529911,
            0.0359649658203125,
            -0.02654740959405899,
            -0.022316116839647293,
            -0.025558939203619957,
            0.02200901135802269,
            -0.02806701697409153,
            0.01249858271330595,
            -0.044972095638513565,
            -0.004272556863725185,
            -0.010202162899076939,
            -0.025902671739459038,
            -0.021059373393654823,
            0.013626843690872192,
            0.027297306805849075,
            0.025417182594537735,
            -0.036833059042692184,
            0.014901969581842422,
            -0.0032619766425341368,
            -0.02312919870018959,
            0.004028635565191507,
            -0.02781255543231964,
            0.03686351701617241,
            -0.012152062729001045,
            -0.01591513492166996,
            -0.023599326610565186,
            -0.011601871810853481,
            -0.022597970440983772,
            0.0017667165957391262,
            -0.010717067867517471,
            0.0018715616315603256,
            0.0027035956736654043,
            -0.004355359356850386,
            0.010213896632194519,
            -0.024437706917524338,
            -0.0011984730372205377,
            -0.022480061277747154,
            0.004025558475404978,
            -0.026953475549817085,
            0.00034736693487502635,
            -0.014119934290647507,
            0.006494995206594467,
            0.03105057217180729,
            0.016753176227211952,
            -0.003526215674355626,
            0.011320142075419426,
            0.03179708868265152,
            0.009222927503287792,
            -0.0029822285287082195,
            0.010481525212526321,
            -0.028289157897233963,
            -0.005637978203594685,
            -0.023831911385059357,
            0.000202116061700508,
            -0.0300461258739233,
            0.024148575961589813
        ],
        [
            -0.019215930253267288,
            -0.021241851150989532,
            -0.0005384907126426697,
            -0.004132453352212906,
            0.01880962774157524,
            -0.007119800895452499,
            -0.0152822807431221,
            0.010722242295742035,
            -0.02140628546476364,
            0.005714325699955225,
            -0.007127091754227877,
            -0.0015236511826515198,
            -0.030186574906110764,
            0.007240947335958481,
            -0.012812882661819458,
            0.011588353663682938,
            -0.0071305446326732635,
            0.017112230882048607,
            -0.029105043038725853,
            -0.006682419218122959,
            0.0013381658354774117,
            0.007777107879519463,
            0.017729882150888443,
            0.024287667125463486,
            0.023496389389038086,
            -0.02007530815899372,
            0.031242575496435165,
            -0.021076250821352005,
            0.029875312000513077,
            0.010137906298041344,
            0.017424434423446655,
            -0.032150063663721085,
            0.004572365432977676,
            0.029570860788226128,
            -0.029218867421150208,
            -0.0025689180474728346,
            -0.01270381547510624,
            0.016931835561990738,
            -0.0045155868865549564,
            -0.005499601364135742,
            -0.012575272470712662,
            -0.029558630660176277,
            -0.02032552845776081,
            0.007325551472604275,
            0.027659501880407333,
            -0.02933952957391739,
            0.010677729733288288,
            -0.02165987901389599,
            0.02418602630496025,
            0.004482440650463104,
            -0.008288443088531494,
            -0.025592805817723274,
            -0.0315249040722847,
            0.017618631944060326,
            -0.028561942279338837,
            -0.01907089725136757,
            0.028863981366157532,
            0.01611270383000374,
            -0.015550854615867138,
            -0.0066672260873019695,
            -0.025083592161536217,
            -0.02447427064180374,
            -0.015302971936762333,
            0.023026103153824806,
            0.023354852572083473,
            -0.02281317301094532,
            0.020791174843907356,
            0.0030483638402074575,
            -0.025920171290636063,
            0.01796172372996807,
            -0.030410174280405045,
            0.0176702830940485,
            0.016881873831152916,
            -0.028424184769392014,
            -0.006491961423307657,
            -0.029276356101036072,
            0.006114017218351364,
            -0.006056707352399826,
            0.017807690426707268,
            -0.011558342725038528,
            -0.019763797521591187,
            -0.001069932826794684,
            0.003915138076990843,
            0.019671481102705002,
            -0.025554977357387543,
            0.018322400748729706,
            0.01834918186068535,
            -0.02819736674427986,
            -0.020618828013539314,
            -0.02237752079963684,
            0.005984753370285034,
            0.016803044825792313,
            -0.005970237776637077,
            -0.019095730036497116,
            0.01502992957830429,
            -0.015352636575698853,
            0.025622203946113586,
            -0.02210114151239395,
            0.024663183838129044,
            -0.03116457164287567,
            -0.010775871574878693,
            -0.005760811269283295,
            -0.029379673302173615,
            -0.019833771511912346,
            -0.016895703971385956,
            0.018650542944669724,
            -0.006089936941862106,
            -0.008250541985034943,
            0.029757820069789886,
            0.00040025264024734497,
            -0.022148240357637405,
            0.011250220239162445,
            0.027437008917331696,
            -0.017899535596370697,
            0.0013726960169151425,
            -0.006323202978819609,
            0.001268390566110611,
            0.02748648263514042,
            0.0009840185521170497,
            0.02844884619116783,
            0.012956167571246624,
            -0.030626535415649414,
            -0.007844001054763794,
            -0.018107477575540543,
            -0.008222476579248905,
            -0.017200225964188576,
            0.00359334796667099,
            -0.002142690122127533,
            -0.007694803178310394,
            0.013921674340963364,
            0.01986604370176792,
            0.020295770838856697,
            0.02889978140592575,
            -0.018056876957416534,
            0.026918502524495125,
            0.0012967133661732078,
            0.005094039253890514,
            0.025204677134752274,
            -0.016554828733205795,
            0.00101475790143013,
            -0.004535045009106398,
            -0.009271956980228424,
            -0.0066856942139565945,
            -0.02493789792060852,
            0.009359974414110184,
            0.030869316309690475,
            0.009667908772826195,
            0.0028730551712214947,
            -0.024910252541303635,
            0.012866409495472908,
            -0.0010298402048647404,
            0.022038405761122704,
            0.026969484984874725,
            -0.029666202142834663,
            -0.021032871678471565,
            0.0045729270204901695,
            0.0022135297767817974,
            -0.021697405725717545,
            -0.01644567772746086,
            -0.002237170934677124,
            0.02043328806757927,
            -0.002315141260623932,
            -0.01729637011885643,
            -0.005853191018104553,
            0.030045930296182632,
            -0.005218908190727234,
            0.010381493717432022,
            0.02878161333501339,
            0.010982859879732132,
            -0.013627740554511547,
            -0.004763588309288025,
            -0.020617689937353134,
            -0.02558775059878826,
            -0.02124793641269207,
            -0.029576051980257034,
            0.005579143762588501,
            0.01695031113922596,
            -0.018671445548534393,
            -0.0256669744849205,
            0.024145182222127914,
            -0.023557599633932114,
            0.005540747195482254,
            -0.0207848958671093,
            0.004605259746313095,
            -0.019031323492527008,
            0.024270787835121155,
            -0.028882334008812904,
            -0.01537877693772316,
            -0.009748953394591808,
            -0.013745452277362347,
            -0.009598325937986374,
            0.024377930909395218,
            -0.027313489466905594,
            -0.002765323966741562,
            -0.00859894696623087,
            0.019732415676116943,
            -0.029674818739295006,
            0.024839751422405243,
            -0.005155622027814388,
            0.02776152640581131,
            0.004641786217689514,
            -0.029635116457939148,
            0.014602448791265488,
            0.010357433930039406,
            -0.02941538766026497,
            -0.028606638312339783,
            -0.002214081585407257,
            0.026560526341199875,
            0.014746058732271194,
            -0.022759810090065002,
            0.020084016025066376,
            -0.02033078856766224,
            0.015115316025912762,
            -0.006669881287962198,
            0.004543375689536333,
            0.0167128536850214,
            0.004485198296606541,
            -0.01387819740921259,
            -0.018232565373182297,
            -0.023619690909981728,
            -0.01011183112859726,
            0.025691747665405273,
            0.006786341313272715,
            0.007660090923309326,
            -0.021382739767432213,
            0.027457281947135925,
            -0.02082403004169464,
            0.015472259372472763,
            -0.02263115718960762,
            0.0007085413089953363,
            -0.020705079659819603,
            0.002720709890127182,
            0.012136026285588741,
            -0.027752066031098366,
            0.020107794553041458,
            0.028615234419703484,
            -0.014754727482795715,
            -0.0015765214338898659,
            -0.017735539004206657,
            -0.004144977778196335,
            -0.019347654655575752,
            -0.02451869659125805,
            -0.010091690346598625,
            -0.03136443719267845,
            -0.002533629536628723,
            -0.0036239668261259794,
            -0.023372523486614227,
            -0.02045629173517227,
            -0.024362344294786453,
            0.004863273352384567,
            -0.02791658230125904,
            0.008634377270936966,
            -0.014227397739887238,
            0.008759785443544388,
            -0.008370138704776764,
            0.008160613477230072,
            -0.013151280581951141,
            -0.007189181633293629,
            0.008645005524158478,
            -0.011469762772321701,
            -0.003153544384986162,
            -0.01625325158238411,
            0.024860043078660965,
            0.026928827166557312,
            -0.013763479888439178,
            -0.024553027004003525,
            0.0010498533956706524,
            0.022843029350042343,
            0.007872708141803741,
            -0.011182490736246109,
            -0.024696985259652138,
            -0.025998014956712723,
            0.024858925491571426,
            0.020027846097946167,
            0.011546645313501358,
            -0.028519874438643456,
            -0.00444035604596138,
            -0.00936106126755476,
            0.026113664731383324,
            -0.02228979580104351,
            0.007712598890066147,
            -0.017219528555870056,
            0.0037614963948726654,
            -0.015072368085384369,
            -0.027897726744413376,
            -0.021726293489336967,
            -0.02790861576795578,
            -0.010018456727266312,
            0.027411241084337234,
            0.006209764629602432,
            -0.0019960515201091766,
            -0.017904408276081085,
            -0.029034189879894257,
            -0.02328220009803772,
            -0.000770603131968528,
            0.0046525634825229645,
            -0.016575347632169724,
            0.026832647621631622,
            -0.0044489167630672455,
            0.014396265149116516,
            -0.019916167482733727,
            0.014981865882873535,
            -0.0009353228379040956,
            0.018070921301841736,
            0.014913082122802734,
            0.012161549180746078,
            -0.0109589584171772,
            0.024439431726932526,
            -0.003054222324863076,
            -0.03086571767926216,
            0.019861772656440735,
            0.013442229479551315,
            0.022762179374694824,
            -0.024026712402701378,
            -0.013789653778076172,
            -0.031090445816516876,
            0.02171543426811695,
            -0.022199971601366997,
            0.015544503927230835,
            -0.018743477761745453,
            -0.011799355037510395,
            -0.013862336054444313,
            -0.02612145058810711,
            -0.015477538108825684,
            -1.076792432286311e-05,
            0.00485294871032238,
            0.02449163794517517,
            0.001162465661764145,
            0.0012139827013015747,
            -0.014004305936396122,
            0.022836796939373016,
            0.020787900313735008,
            -0.008144821971654892,
            -0.015782780945301056,
            -0.007048603147268295,
            -0.013269595801830292,
            0.01232149824500084,
            -0.021509826183319092,
            0.012949090451002121,
            -0.01409245003014803,
            -0.0006290711462497711,
            -0.022532178089022636,
            0.0061835795640945435,
            0.014364045113325119,
            -0.022346682846546173,
            -0.029385242611169815,
            -0.011901739984750748,
            0.0009004059247672558,
            -0.02010994963347912,
            -0.028250228613615036,
            0.0023272931575775146,
            -0.011986353434622288,
            -0.03206111490726471,
            -0.010464247316122055,
            0.01850724220275879,
            0.006517925299704075,
            0.02910657972097397,
            0.008029267191886902,
            0.02285345084965229,
            0.005236055701971054,
            -0.028489280492067337,
            -0.02175980433821678,
            -0.02956106886267662,
            0.01329180970788002,
            0.01331821084022522,
            0.005539874080568552,
            0.021276988089084625,
            0.020042482763528824,
            -0.009676680900156498,
            -0.008441836573183537,
            -0.030274368822574615,
            -0.015953559428453445,
            0.00252329558134079,
            0.02611636370420456,
            0.026721538975834846,
            -0.005665681324899197,
            0.016454525291919708,
            0.011052846908569336,
            0.018692059442400932,
            -0.015781454741954803,
            -0.015065349638462067,
            -0.021073689684271812,
            -0.022495023906230927,
            0.0283630583435297,
            0.001063179224729538,
            -0.00852762907743454,
            0.01272246241569519,
            -0.00022841617465019226,
            -0.007542524486780167,
            -0.012778442353010178,
            0.023282423615455627,
            0.0006180703639984131,
            -0.025475017726421356,
            -0.020116563886404037,
            -0.006074734032154083,
            0.02055111713707447,
            -0.00448588328436017,
            0.027795448899269104,
            0.006630256772041321,
            -0.02824857085943222,
            0.012830894440412521,
            -0.01636824570596218,
            -0.007522379048168659,
            -0.024742258712649345,
            0.013006401248276234,
            -0.018983613699674606,
            -0.014019079506397247,
            -0.01563975214958191,
            -0.02173970639705658,
            -0.03087081015110016,
            -0.015278575010597706,
            -0.014005769044160843,
            -0.03160576894879341,
            -0.026962563395500183,
            0.026391275227069855,
            -0.0025906162336468697,
            0.019939083606004715,
            -0.0011124685406684875,
            0.007428908254951239,
            0.02407054975628853,
            0.0017420090734958649,
            -0.01483628898859024,
            0.013817761093378067,
            0.009239551611244678,
            -0.01368076354265213,
            -0.025272179394960403,
            -0.007172606885433197,
            0.022257640957832336,
            -0.02321700006723404,
            0.013430271297693253,
            0.0013589151203632355,
            0.020995892584323883,
            -0.020671958103775978,
            0.028717178851366043,
            0.0036600944586098194,
            -0.01340063288807869,
            0.01973852887749672,
            0.028738129884004593,
            0.005374860018491745,
            -0.010354316793382168,
            -0.01217225193977356,
            0.0022118259221315384,
            0.008756671100854874,
            0.007447861135005951,
            0.027962742373347282,
            -0.0258322786539793,
            -0.009605247527360916,
            -0.02438296750187874,
            -0.014857366681098938,
            -0.001074615865945816,
            -0.02002783864736557,
            -0.019964303821325302,
            0.02220820263028145,
            0.02842475101351738,
            0.004080235958099365,
            0.0019040779443457723,
            -0.011750102043151855,
            -0.014777719974517822,
            0.010617047548294067,
            0.012203115969896317,
            0.02292782813310623,
            0.011084739118814468,
            -0.020274776965379715,
            -0.024403845891356468,
            -0.018138738349080086,
            -0.003271927358582616,
            -0.02424497716128826,
            -0.027862995862960815,
            0.001965067582204938,
            0.029339062049984932,
            0.02756832167506218,
            -0.0041716499254107475,
            -0.02413683757185936,
            0.0041693709790706635,
            0.006555342115461826,
            0.01780727133154869,
            -0.028505945578217506,
            0.006988696288317442,
            0.018978621810674667,
            -0.010783057659864426,
            0.018654707819223404,
            -0.0028030984103679657,
            0.004781953990459442,
            -0.02572309784591198,
            -0.00870823860168457,
            0.018175235018134117,
            -0.016574634239077568,
            -0.00028337910771369934,
            -0.02764986641705036,
            0.011091340333223343,
            -0.02162073366343975,
            -0.02783183380961418,
            0.018240302801132202,
            0.006768982857465744,
            -0.0054823122918605804,
            -0.008645698428153992,
            0.009181222878396511,
            -0.016730885952711105,
            -0.030724644660949707,
            -0.010209593921899796,
            -0.027480505406856537,
            0.01393955945968628,
            -0.004511326551437378,
            0.009093889966607094,
            0.0010277413530275226,
            0.012881696224212646,
            -0.030186884105205536,
            0.018571993336081505,
            0.006733533926308155,
            0.011992339976131916,
            0.01232639979571104,
            0.023077327758073807,
            0.008132926188409328,
            0.005450453609228134,
            -7.937848567962646e-05,
            -0.004646085202693939,
            -0.01070215180516243,
            0.02989482879638672,
            -0.028404101729393005,
            -0.029383137822151184,
            -0.008934272453188896,
            -0.029884502291679382,
            -0.01933935098350048,
            0.01289050281047821,
            0.026532016694545746,
            0.028204023838043213,
            -0.0025244057178497314,
            0.01831444911658764,
            -0.029018767178058624,
            -0.025077082216739655,
            0.026040926575660706,
            0.012668557465076447,
            0.029973218217492104,
            0.004793040454387665,
            0.0268214363604784,
            0.01976643316447735,
            -0.001507727662101388,
            -0.003621547482907772,
            -0.031188275665044785,
            -0.022129124030470848,
            -0.029072269797325134,
            0.012360397726297379,
            0.02008012682199478,
            0.015945833176374435,
            0.008655745536088943,
            -0.02948489785194397,
            0.017075197771191597,
            -0.009173570200800896,
            -0.02545946277678013,
            -0.00023162737488746643,
            0.01847570389509201,
            -0.02516387403011322,
            -0.010496445000171661,
            -0.020463550463318825,
            0.025704842060804367,
            -0.008449389599263668,
            -0.006799277383834124,
            -0.016772698611021042,
            -0.0017824965761974454,
            -0.02384846843779087,
            -0.02303558960556984,
            0.02690502256155014,
            0.005561429541558027,
            -0.026797009631991386,
            -0.021243948489427567,
            0.019526347517967224,
            0.02228383533656597,
            -0.013787670060992241,
            0.023188650608062744,
            0.025588544085621834,
            -0.021585803478956223,
            -0.0039018651004880667,
            0.02729705721139908,
            -0.0010828226804733276,
            0.02927255630493164,
            -0.005177229642868042,
            0.0056756180711090565,
            -0.022350352257490158,
            -0.015633337199687958,
            -0.020471813157200813,
            -0.010183591395616531,
            -0.012707524001598358,
            0.0009885318577289581,
            0.02333216555416584,
            0.010418616235256195,
            -0.010784706100821495,
            -0.015460260212421417,
            0.02791861817240715,
            0.023441145196557045,
            0.002283308422192931,
            0.014806697145104408,
            -0.03142743185162544,
            0.025388037785887718,
            -0.03140584006905556,
            -0.01099215168505907,
            -0.005124568939208984,
            -0.009446091949939728,
            0.006894115824252367,
            0.011666623875498772,
            -0.021690893918275833,
            0.027098234742879868,
            -0.013532858341932297,
            -0.014198683202266693,
            -0.022583555430173874,
            -0.020467227324843407,
            -0.025950424373149872,
            0.024157270789146423,
            -0.005770741030573845,
            -0.029174892231822014,
            0.02951614186167717,
            0.027313442900776863,
            -0.008549924939870834,
            0.02513454481959343,
            0.0018875321839004755,
            -0.03024185076355934,
            -0.031194550916552544,
            0.0025461618788540363,
            -0.01989045739173889,
            0.016723083332180977,
            -0.024454962462186813,
            -0.015805207192897797,
            0.025004155933856964,
            -0.020712323486804962,
            -0.0319051668047905,
            -0.015362132340669632,
            -0.02588273212313652,
            0.0016676858067512512,
            -0.001006776699796319,
            0.025986887514591217,
            0.009391186758875847,
            -0.002413719892501831,
            -0.02661362662911415,
            0.008945446461439133,
            0.029735827818512917,
            -0.02472948282957077,
            -0.028368692845106125,
            0.027071358636021614,
            0.02037295326590538,
            0.02291617915034294,
            0.0027599427849054337,
            0.007206210866570473,
            -0.012198924086987972,
            -0.010433458723127842,
            0.01981940306723118,
            0.02079109288752079,
            0.0011661355383694172,
            -0.010222341865301132,
            -0.00910941418260336,
            -0.0003496515564620495,
            0.02763032354414463,
            -0.03050721436738968,
            0.019585583359003067,
            0.014132301323115826,
            0.02484939806163311,
            0.003303486853837967,
            0.021160978823900223,
            0.0043609775602817535,
            0.017912304028868675,
            0.0031684378627687693,
            -0.010888127610087395,
            0.01453782245516777,
            -0.0064489878714084625,
            -0.025808915495872498,
            0.023823166266083717,
            0.0017362373182550073,
            -0.02594027668237686,
            -0.030754990875720978,
            0.01784943789243698,
            -0.00788329541683197,
            0.021636443212628365,
            0.009515661746263504,
            -0.014014258980751038,
            -0.00016004772624000907,
            0.0012961290776729584,
            -0.007856111973524094,
            -0.018417533487081528,
            0.019947797060012817,
            0.0023964745923876762,
            -0.012417499907314777,
            0.0018853220390155911,
            0.0036158596631139517,
            0.027588054537773132,
            -0.01987905241549015,
            0.018397070467472076,
            0.010706438682973385,
            -0.031088754534721375,
            0.016404837369918823,
            -0.021527614444494247,
            0.010049405507743359,
            0.002602994441986084,
            -0.01704171672463417,
            -0.01316734217107296,
            -0.022377341985702515,
            0.02398190088570118,
            -0.018173586577177048,
            0.008777841925621033,
            -0.025619227439165115,
            0.02190006710588932,
            -0.03021395578980446,
            0.015263230539858341,
            -0.027808252722024918,
            0.019955070689320564,
            -0.02686881273984909,
            0.023255648091435432,
            -0.019515622407197952,
            -0.016280870884656906,
            -0.009170378558337688,
            0.007070101797580719,
            0.0008707307279109955,
            0.019559703767299652,
            -0.0212172232568264,
            0.016329947859048843,
            -0.01432964950799942,
            -0.01194422971457243,
            -0.023207202553749084,
            -0.026194214820861816,
            -0.023486372083425522,
            0.019257228821516037,
            0.00813300721347332,
            0.023959597572684288,
            0.02746506780385971,
            -0.0270390547811985,
            -0.002071104012429714,
            0.02338634803891182,
            -0.0008601018926128745,
            0.027972618117928505,
            0.013829831965267658,
            -0.020155057311058044,
            -0.030763842165470123,
            0.02522370219230652,
            0.0019314102828502655,
            0.022252317517995834,
            0.0013490766286849976,
            -0.006573943886905909,
            -0.01766807958483696,
            -0.020145710557699203,
            0.016798272728919983,
            -0.0042485930025577545,
            -0.010664008557796478,
            -0.004009906202554703,
            -0.0016563968965783715,
            0.02766214683651924,
            -0.019515378400683403,
            0.02556169405579567,
            0.01621902361512184,
            0.02601981908082962,
            0.01529703289270401,
            0.0036421408876776695,
            0.014650823548436165,
            -0.0022763723973184824,
            0.021632874384522438,
            -0.025972191244363785,
            -0.0314391553401947,
            -0.016285281628370285,
            0.00529859634116292,
            -0.02158374711871147,
            -0.00302298367023468,
            0.013778168708086014,
            -0.001252471236512065,
            -0.02829497680068016,
            0.028027130290865898,
            0.0003267750144004822,
            0.0018239074852317572,
            -0.028686802834272385,
            -0.008284341543912888,
            -0.0011488121235743165,
            -0.018193695694208145,
            0.015277803875505924,
            -0.007663830183446407,
            0.028800733387470245,
            0.01028062216937542,
            -0.0009806379675865173,
            -0.018587756901979446,
            -0.007146354764699936,
            0.025462383404374123,
            -0.011853465810418129,
            0.014454271644353867,
            -0.0007093158201314509,
            0.02717527747154236,
            -0.0002472170162945986,
            -0.008601557463407516,
            -0.02407738007605076,
            -0.01248225849121809,
            0.0255034938454628,
            0.02384563349187374,
            0.007721416652202606,
            0.027008989825844765,
            0.008487759158015251,
            0.007763281464576721,
            -0.027383625507354736,
            0.012851651757955551,
            -0.018196841701865196,
            0.02391931042075157,
            0.02889808639883995,
            0.0009290017187595367,
            -0.0057348161935806274,
            -0.02860625460743904,
            -0.010601157322525978,
            -0.028900571167469025,
            0.009033518843352795,
            0.011982466094195843,
            0.010121853090822697,
            -0.00035981088876724243,
            -0.003422573208808899,
            0.024625306949019432,
            -0.00969536229968071,
            0.013929888606071472,
            0.010876838117837906,
            -0.025806039571762085,
            -0.012150879949331284,
            0.031157389283180237,
            -0.00013253465294837952,
            -0.013701880350708961,
            -0.022823618724942207,
            -0.004367753863334656,
            -0.005621284246444702,
            -0.0070244669914245605,
            0.0271921306848526,
            -0.018922891467809677,
            0.007674422115087509,
            -0.024134133011102676,
            0.021894343197345734,
            -0.004716845694929361,
            0.0060616061091423035,
            -0.020957427099347115,
            -0.022868746891617775,
            0.025220025330781937,
            -0.0006400597048923373,
            -0.023173611611127853,
            -0.01724250242114067,
            -0.011293519288301468,
            -0.012913284823298454,
            0.0008873841143213212,
            0.015078126452863216,
            0.022309353575110435,
            -0.01567838340997696,
            -0.00908925011754036,
            0.02064495161175728,
            0.028048422187566757,
            0.018793173134326935,
            -0.025407806038856506,
            0.012764768674969673,
            0.016813360154628754,
            -0.02884598821401596,
            -0.027267830446362495,
            0.00641327491030097,
            0.014779649674892426,
            -0.0018332533072680235,
            0.010599572211503983,
            0.02651921473443508,
            -0.004683858249336481,
            -0.025732513517141342,
            -0.009209435433149338,
            -0.0058853416703641415,
            -0.022186368703842163,
            0.020794354379177094,
            0.007195468991994858,
            -0.016507238149642944,
            0.007959622889757156,
            0.002348341280594468,
            -0.02489929459989071,
            -0.02995530143380165,
            0.030109532177448273,
            0.009743895381689072,
            -0.0017351587302982807,
            -0.026954760774970055,
            -0.024814430624246597,
            0.012420915067195892,
            -0.028132889419794083,
            -0.017885789275169373,
            0.026863738894462585,
            -0.0006008893251419067,
            -0.02539026364684105,
            -0.027275964617729187,
            -0.02062414586544037,
            -0.015478475950658321,
            0.0034881681203842163,
            0.014219310134649277,
            -0.0059691909700632095,
            0.00795532763004303,
            0.0030658391769975424,
            0.00011632964015007019,
            -0.0029892735183238983,
            0.009098334237933159,
            -0.014021720737218857,
            -0.015346001833677292,
            0.028281059116125107,
            0.028694063425064087,
            -0.030049767345190048,
            -0.00630660355091095,
            -0.028677672147750854,
            0.005686402786523104,
            -0.028535082936286926,
            -0.030857663601636887,
            -0.0309455543756485,
            0.010179691016674042,
            -0.028067700564861298,
            0.014780502766370773,
            -0.018272193148732185,
            0.0027977104764431715,
            -0.004306294023990631,
            0.004304181784391403,
            -0.00617557018995285,
            -0.02933555841445923,
            0.02509104460477829,
            0.010750554502010345,
            -0.010807468555867672,
            0.008251877501606941,
            -0.005413651466369629,
            0.005782396532595158,
            -0.01836167275905609,
            -0.015122726559638977,
            -0.026626236736774445,
            -0.014920800924301147,
            -0.00651564821600914,
            0.006744799669831991,
            0.004181351512670517,
            0.0023340843617916107,
            -0.017815478146076202,
            -0.001429130556061864,
            -0.023106122389435768,
            0.024538522586226463,
            -0.017384741455316544,
            -0.011430919170379639,
            0.019931424409151077,
            0.022650303319096565,
            -0.004432838875800371,
            0.001435905578546226,
            -0.01146366260945797,
            -0.008966077119112015,
            0.02737291157245636,
            0.0015745870769023895,
            -0.015747759491205215,
            -0.02240065112709999,
            -0.025429196655750275,
            0.01520409807562828,
            -0.018549161031842232,
            -0.01887434907257557,
            -0.03019840270280838,
            -0.027739714831113815,
            -0.008547913283109665,
            4.0825456380844116e-05,
            -0.00592169351875782,
            -0.0037946023512631655,
            -0.018185259774327278,
            -0.006680559366941452,
            0.02010359615087509,
            -0.026530161499977112,
            -0.029009368270635605,
            0.006895866245031357,
            -0.00017177313566207886,
            0.02646416611969471,
            -0.013051594607532024,
            -0.01166464015841484,
            -0.009300692938268185,
            0.017173204571008682,
            0.02901012822985649,
            -0.028307663276791573,
            0.01566380262374878,
            -0.025699498131871223,
            0.025128401815891266,
            -0.019110029563307762,
            0.028848543763160706,
            -0.007779192179441452,
            -0.01637440174818039,
            0.019846662878990173,
            -0.009102757088840008,
            0.0063903420232236385,
            0.008299775421619415,
            0.011518217623233795,
            -0.009951257146894932,
            -0.0009227870032191277,
            -0.030385348945856094,
            0.007802579551935196,
            0.023744910955429077,
            -0.008250197395682335,
            -0.01219332218170166,
            -0.010048963129520416,
            0.02230207249522209,
            -0.02890031971037388,
            -0.030406620353460312,
            0.0129706971347332,
            -0.02128312736749649,
            -0.016078300774097443,
            0.024585289880633354,
            -0.014229292050004005,
            0.023362062871456146,
            -0.024703219532966614,
            0.006118841003626585,
            -0.02667391672730446,
            0.019976837560534477,
            -0.011056874878704548,
            -0.0038888221606612206,
            -0.027435651049017906,
            0.01664772257208824,
            -0.02310304343700409,
            0.004895869642496109,
            0.02168109267950058,
            0.00425361841917038,
            0.02143355831503868,
            0.016718614846467972,
            -0.002290971577167511,
            0.02218550629913807,
            -0.008182460442185402,
            0.005020923912525177,
            -0.029212988913059235,
            -0.005010850727558136,
            -0.010948540642857552,
            -0.024225806817412376,
            0.011323785409331322,
            -0.025960132479667664,
            0.006331246346235275,
            -0.0047464072704315186,
            -0.00612137233838439,
            0.027758799493312836,
            -0.004266355652362108,
            -0.00664982944726944,
            -0.011766064912080765,
            -0.01276223361492157,
            -0.006127942353487015,
            0.002595677971839905,
            -0.006405305117368698,
            0.024557549506425858,
            -0.017178162932395935
        ],
        [
            0.006946333218365908,
            0.0029510457534343004,
            0.001835899194702506,
            0.02417800761759281,
            0.005704862996935844,
            -0.04021330922842026,
            -0.001343409065157175,
            0.0035374541766941547,
            0.019034573808312416,
            0.004939352627843618,
            -4.740680742543191e-05,
            -0.009501996450126171,
            0.009402875788509846,
            0.008867047727108002,
            0.02041224204003811,
            0.041831016540527344,
            0.02865750342607498,
            0.010396357625722885,
            -0.0235461313277483,
            -0.020339030772447586,
            -0.031080063432455063,
            0.018925068899989128,
            -0.016435258090496063,
            -0.020423106849193573,
            0.025365913286805153,
            -0.0041268328204751015,
            0.032737329602241516,
            0.01867881417274475,
            0.01579160802066326,
            0.009763380512595177,
            0.002907430287450552,
            0.021321028470993042,
            0.0057401396334171295,
            0.008058900944888592,
            -0.009846502915024757,
            -0.007017942611128092,
            0.02139306627213955,
            0.019454751163721085,
            0.0029457034543156624,
            0.029720542952418327,
            0.021265460178256035,
            -0.01282039750367403,
            0.010582471266388893,
            -0.01739993877708912,
            0.04597007483243942,
            0.02119850367307663,
            0.040091875940561295,
            0.021308233961462975,
            0.013470637612044811,
            0.0020638490095734596,
            -0.01595299132168293,
            0.0009575333097018301,
            -0.03242918848991394,
            -0.03685759752988815,
            0.0014785489765927196,
            0.019995711743831635,
            -0.016647690907120705,
            0.006396657321602106,
            -0.02481662854552269,
            0.017351511865854263,
            0.020915890112519264,
            0.016384726390242577,
            0.0055611999705433846,
            0.02787681855261326,
            -0.020096179097890854,
            -0.01668834500014782,
            0.01530307624489069,
            0.025299740955233574,
            -0.0018730940064415336,
            -0.001872509834356606,
            0.01300417073071003,
            0.025744276121258736,
            -0.03072306327521801,
            0.011515852995216846,
            -0.017439335584640503,
            0.053379178047180176,
            0.03361539542675018,
            0.018387338146567345,
            0.01732141338288784,
            0.009460474364459515,
            -0.03503111004829407,
            -0.014136644080281258,
            0.03188459202647209,
            -0.007466158829629421,
            -0.0438937246799469,
            -0.002113476162776351,
            -0.02477133646607399,
            -0.00941636972129345,
            0.03847690671682358,
            0.011585751548409462,
            -0.02362120896577835,
            -0.005687002092599869,
            0.004978300537914038,
            0.003832059446722269,
            0.05083698034286499,
            0.0060660019516944885,
            -0.001448323018848896,
            -0.030936632305383682,
            0.008656339719891548,
            0.023166589438915253,
            -0.019785646349191666,
            0.014217536896467209,
            0.02691473439335823,
            0.007951861247420311,
            -0.011854908429086208,
            0.015225209295749664,
            0.011888658627867699,
            -0.0431281141936779,
            0.03268478065729141,
            0.015972528606653214,
            -0.05399816855788231,
            0.02023596689105034,
            -0.012807132676243782,
            0.024878930300474167,
            0.011477628722786903,
            -0.00945913977921009,
            -0.007102521602064371,
            0.019465427845716476,
            -0.011833938769996166,
            0.01431900355964899,
            -0.030612707138061523,
            -0.02953866869211197,
            -0.017203541472554207,
            0.005975255277007818,
            0.002671237103641033,
            0.028510281816124916,
            -0.00018412730423733592,
            -0.0022416117135435343,
            0.029202409088611603,
            -0.0003730315074790269,
            -0.01672489568591118,
            0.011938192881643772,
            0.018680699169635773,
            0.007236814592033625,
            0.014862529002130032,
            0.03164443373680115,
            -0.01107376255095005,
            -0.009886308573186398,
            -0.018400585278868675,
            -0.03126988559961319,
            0.0027194321155548096,
            -0.020907822996377945,
            -0.01700810343027115,
            0.009742232039570808,
            -0.009150034748017788,
            0.0535261332988739,
            0.01159705687314272,
            0.03309297189116478,
            0.025500627234578133,
            -0.03357658535242081,
            0.007430856581777334,
            0.023315856233239174,
            0.017740970477461815,
            0.020795192569494247,
            0.004989084787666798,
            0.0036450654733926058,
            0.02978876605629921,
            -0.02024807222187519,
            -0.04005216807126999,
            0.02920168824493885,
            0.017957480624318123,
            -0.012124400585889816,
            -0.004400976467877626,
            0.03597718104720116,
            0.00268487143330276,
            -0.027970798313617706,
            0.017052507027983665,
            0.03532050922513008,
            0.02368534356355667,
            0.01301775686442852,
            0.02399258315563202,
            0.013296867720782757,
            0.015476133674383163,
            0.004937560763210058,
            0.02616439200937748,
            -0.019644420593976974,
            -0.009467359632253647,
            0.02736777439713478,
            -0.015919426456093788,
            -0.0075004491955041885,
            0.026256132870912552,
            -0.04547470435500145,
            -0.011776180006563663,
            0.029086636379361153,
            0.015870707109570503,
            -0.005787645000964403,
            0.004112508147954941,
            -0.006837570574134588,
            0.02566593885421753,
            0.03405431658029556,
            -0.0009400418493896723,
            0.039759762585163116,
            0.04569200426340103,
            0.02204865776002407,
            -0.02684066817164421,
            0.0064807068556547165,
            -0.014070127159357071,
            -0.013984214514493942,
            -0.0027007057797163725,
            -0.010457629337906837,
            0.016141138970851898,
            0.01548489835113287,
            -0.0009994317078962922,
            0.0003172411525156349,
            -0.04344072565436363,
            0.015385695733129978,
            0.022454531863331795,
            0.02865702658891678,
            0.034150391817092896,
            0.0109382513910532,
            0.012317966669797897,
            0.032891906797885895,
            0.003446258371695876,
            -0.0017152371583506465,
            -0.008327879942953587,
            0.018701279535889626,
            -0.0008378415950573981,
            -0.0036409911699593067,
            0.0022660435643047094,
            0.04021622613072395,
            0.0355316586792469,
            -0.020896203815937042,
            0.024693479761481285,
            0.007189583964645863,
            0.004134424030780792,
            0.0744662955403328,
            -0.015646951273083687,
            0.009218073450028896,
            -0.049911316484212875,
            -0.007890569977462292,
            0.019145028665661812,
            -0.01732770912349224,
            0.0018163317581638694,
            -0.005157939158380032,
            0.008154936134815216,
            -0.02745697647333145,
            0.0016134317265823483,
            0.005928465165197849,
            -0.030741935595870018,
            0.03608180955052376,
            0.005276486277580261,
            -0.005078257992863655,
            0.00983192678540945,
            -0.013390620239078999,
            -0.008034374564886093,
            0.03831026330590248,
            0.017896924167871475,
            0.01225831639021635,
            -0.02124415896832943,
            0.000608213187661022,
            -0.03508581221103668,
            -0.009881233796477318,
            0.03064921870827675,
            -0.0025709650944918394,
            -0.02914505824446678,
            0.005013213027268648,
            -0.037004970014095306,
            -0.015561241656541824,
            0.0017668871441856027,
            0.0027304482646286488,
            -0.023742785677313805,
            0.018264038488268852,
            0.03224777802824974,
            0.007354502100497484,
            -0.004185107070952654,
            0.05222383141517639,
            0.012414761818945408,
            -0.013160009868443012,
            0.0030551236122846603,
            -0.0019705744925886393,
            -0.012674929574131966,
            -0.006681059021502733,
            0.019593505188822746,
            -0.03623052313923836,
            0.021519383415579796,
            0.008341069333255291,
            -0.015482038259506226,
            -0.013499782420694828,
            0.0068031782284379005,
            0.013675753027200699,
            -0.004218760412186384,
            -0.0017289177048951387,
            -0.015719076618552208,
            0.04256902262568474,
            0.013772564940154552,
            0.02488543651998043,
            -0.009087607264518738,
            0.046568699181079865,
            0.0046904124319553375,
            0.023446999490261078,
            0.028723133727908134,
            0.024129917845129967,
            0.029909715056419373,
            -0.02630631998181343,
            -0.03311125934123993,
            0.014603141695261002,
            -0.012592033483088017,
            0.02781088650226593,
            -0.02360600419342518,
            0.007694206666201353,
            0.04005897417664528,
            0.007127365097403526,
            -0.001909698941744864,
            0.008193169720470905,
            -0.03772072121500969,
            -0.05536194518208504,
            0.008240883238613605,
            -0.01793193630874157,
            -0.00429457938298583,
            -0.04504014924168587,
            -0.004789585247635841,
            0.02272113598883152,
            -0.019753286615014076,
            0.014482572674751282,
            0.02694542519748211,
            0.04132455214858055,
            0.0221856776624918,
            0.013270603492856026,
            0.03332431614398956,
            0.0066911932080984116,
            -0.0141377542167902,
            -0.008200337179005146,
            0.015481309033930302,
            0.006276717875152826,
            -0.025085056200623512,
            0.0032405441161245108,
            -0.04659323766827583,
            0.02158895507454872,
            -0.027721768245100975,
            0.0008369323331862688,
            -0.012500098906457424,
            -0.040854234248399734,
            0.016425859183073044,
            -0.014680544845759869,
            0.027412807568907738,
            0.032150547951459885,
            0.03322010114789009,
            -0.01882224716246128,
            -0.0021916283294558525,
            -0.026424214243888855,
            -0.03275707736611366,
            -0.014833452180027962,
            0.04078824818134308,
            -0.014164017513394356,
            -0.0022056198213249445,
            0.01550751831382513,
            -0.014012602157890797,
            -0.03414709493517876,
            0.002373788971453905,
            0.04396698251366615,
            -0.030891338363289833,
            -0.007952529937028885,
            -0.019852224737405777,
            -0.004810819402337074,
            0.01071109063923359,
            0.016012970358133316,
            0.03508758172392845,
            0.004855724051594734,
            -0.0028262236155569553,
            0.010966788977384567,
            0.025750495493412018,
            0.030169473960995674,
            0.002895165467634797,
            -0.0014815310714766383,
            -0.01553567685186863,
            0.0027107333298772573,
            -0.02107180841267109,
            -0.02290900982916355,
            -0.012474892660975456,
            -0.010700617916882038,
            0.005889057647436857,
            -0.001799974124878645,
            -0.014042854309082031,
            -0.024582020938396454,
            0.024573680013418198,
            0.019815392792224884,
            0.0032753271516412497,
            -0.009611769579350948,
            0.012201180681586266,
            -0.008617277257144451,
            -0.011783153750002384,
            -0.03126155212521553,
            -0.02000194415450096,
            0.018683549016714096,
            -0.0008817092748358846,
            0.027144046500325203,
            0.025544501841068268,
            -0.007064110599458218,
            0.010986457578837872,
            0.0030522469896823168,
            -0.024245424196124077,
            0.014891909435391426,
            -0.010114563629031181,
            -0.008062337525188923,
            0.0324624627828598,
            0.018710410222411156,
            -0.004121180158108473,
            0.029589060693979263,
            -0.01529929880052805,
            0.026295451447367668,
            -0.0033559496514499187,
            -0.013857503421604633,
            -0.03217403590679169,
            0.022590452805161476,
            -0.006727284751832485,
            -0.013403212651610374,
            0.010124458000063896,
            -0.007109480444341898,
            -0.014747006818652153,
            -0.011684423312544823,
            0.007980958558619022,
            -0.021049022674560547,
            0.010438639670610428,
            0.010872510261833668,
            0.006634002085775137,
            -0.009862658567726612,
            0.009000953286886215,
            0.011458050459623337,
            -0.00975844543427229,
            -0.0009342266712337732,
            0.007827569730579853,
            -0.018366379663348198,
            -0.029127586632966995,
            0.014453611336648464,
            0.00407759565860033,
            0.005522348452359438,
            -0.005831731483340263,
            0.017879335209727287,
            0.00806659646332264,
            -0.016171099618077278,
            -0.01181132160127163,
            -0.009997694753110409,
            -0.001292926725000143,
            -0.02220631018280983,
            -0.01665758527815342,
            -0.021461352705955505,
            0.00647441390901804,
            -0.0003624359960667789,
            -0.0038710064254701138,
            0.0023011977318674326,
            -0.023978430777788162,
            0.02229945920407772,
            -0.020404046401381493,
            0.01865619793534279,
            0.017590316012501717,
            -0.019351208582520485,
            0.03338204324245453,
            -0.015569234266877174,
            0.026802608743309975,
            0.009008820168673992,
            0.018990609794855118,
            -0.024266382679343224,
            -0.03571179881691933,
            -0.004560735076665878,
            -0.013846335001289845,
            -0.005691765807569027,
            -0.004527722019702196,
            -0.022730771452188492,
            -0.006239740177989006,
            -0.021656610071659088,
            0.020730705931782722,
            0.005718386732041836,
            0.010149063542485237,
            0.011248335242271423,
            0.00022584178077522665,
            -0.015384029597043991,
            0.0073064169846475124,
            0.0014137359103187919,
            -0.00338770542293787,
            -0.0017878663493320346,
            -0.01826944760978222,
            0.007009351160377264,
            -0.01026025041937828,
            -0.001800620462745428,
            -0.04251242056488991,
            0.007500062230974436,
            -0.010821951553225517,
            0.025147510692477226,
            0.017807215452194214,
            -0.0033856506925076246,
            0.001122659770771861,
            0.0013446926604956388,
            0.012711131013929844,
            -0.013728177174925804,
            0.034356918185949326,
            0.025930387899279594,
            0.011324753984808922,
            0.014052082784473896,
            0.07636723667383194,
            -0.008481168188154697,
            0.010348832234740257,
            -0.010293593630194664,
            0.008550313301384449,
            0.010231833904981613,
            0.01434556394815445,
            -0.01146787591278553,
            0.019700760021805763,
            -0.0006919334409758449,
            -0.0036123055033385754,
            -0.019678600132465363,
            -0.00011617355630733073,
            -0.012298017740249634,
            0.020586559548974037,
            0.01940988376736641,
            -0.01962299644947052,
            -0.021179359406232834,
            -0.009190571494400501,
            -0.01839747093617916,
            -0.021052036434412003,
            0.010563074611127377,
            -0.03541170805692673,
            -0.016206294298171997,
            0.0018068497302010655,
            -0.02080398052930832,
            0.011734108440577984,
            -0.0038544146809726954,
            0.03714721277356148,
            -0.012774482369422913,
            -0.009791425429284573,
            -0.005434463266283274,
            -0.009376956149935722,
            -0.02077924646437168,
            0.01467153150588274,
            -0.03288428857922554,
            -0.032241519540548325,
            0.043682266026735306,
            -0.05807046219706535,
            -0.018747951835393906,
            0.004427814856171608,
            -0.03154713287949562,
            0.008397938683629036,
            0.0019127657869830728,
            0.02165139839053154,
            0.012827759608626366,
            0.029093995690345764,
            -0.026175497099757195,
            0.024488575756549835,
            0.03313716873526573,
            0.025427918881177902,
            0.020214853808283806,
            -0.03153456375002861,
            -0.014841591939330101,
            -0.034303199499845505,
            -0.0023521315306425095,
            -0.018848275765776634,
            -0.02389850653707981,
            -0.020256387069821358,
            0.01617540419101715,
            0.008415513671934605,
            -0.024454783648252487,
            0.0046317083761096,
            0.012111084535717964,
            0.03474268317222595,
            0.02801080048084259,
            0.020623890683054924,
            0.03378010913729668,
            0.05912219360470772,
            -0.017023662105202675,
            0.012374862097203732,
            0.020377594977617264,
            0.020914996042847633,
            0.029310505837202072,
            -0.008172541856765747,
            0.02694167196750641,
            -0.0025993529707193375,
            0.02104610949754715,
            -0.03874635696411133,
            -0.02898438461124897,
            -0.007961638271808624,
            -0.022433381527662277,
            0.018830588087439537,
            -0.013367218896746635,
            -0.04285837709903717,
            0.012249639257788658,
            0.016439422965049744,
            -0.04744177684187889,
            0.02864024043083191,
            -0.038461558520793915,
            -0.015214554965496063,
            0.016787035390734673,
            -0.0023564344737678766,
            0.037938192486763,
            0.020539645105600357,
            0.008841165341436863,
            -0.040395479649305344,
            -0.026062339544296265,
            0.03496836870908737,
            -0.0034215182531625032,
            -0.015414404682815075,
            0.027510588988661766,
            0.0054283058270812035,
            0.02774570882320404,
            0.022996405139565468,
            0.024624517187476158,
            -0.011754443868994713,
            -0.02555147185921669,
            -0.01077241636812687,
            0.014676257036626339,
            0.024738818407058716,
            -0.02112790010869503,
            0.008225258439779282,
            -0.02783443033695221,
            -0.029943250119686127,
            -0.003672367660328746,
            0.030905164778232574,
            0.006977978628128767,
            0.012161836959421635,
            0.0037844821345061064,
            0.023846013471484184,
            0.022294549271464348,
            -0.0022574018221348524,
            -0.01567932777106762,
            0.019767900928854942,
            -0.005096046254038811,
            -0.0003393149236217141,
            0.032327525317668915,
            0.026477232575416565,
            0.014204978942871094,
            -0.01211997028440237,
            0.0039801751263439655,
            0.03188033029437065,
            -0.004050062503665686,
            -0.017076395452022552,
            -0.016949044540524483,
            -0.008181233890354633,
            0.02999451942741871,
            0.01764957793056965,
            -0.0016097893239930272,
            0.012567601166665554,
            -0.0156524907797575,
            0.03891661390662193,
            -0.02724628709256649,
            0.02005493827164173,
            0.03294235095381737,
            0.008606881834566593,
            -0.018589837476611137,
            -0.008162828162312508,
            -0.0012868568301200867,
            -0.016809290274977684,
            -0.01871652714908123,
            0.03809821233153343,
            0.012353567406535149,
            -0.027200430631637573,
            -0.01770525611937046,
            -0.007358507718890905,
            -0.032561611384153366,
            0.003644447773694992,
            -0.031110713258385658,
            -0.006791167892515659,
            -0.00974078755825758,
            -0.021990865468978882,
            0.014417209662497044,
            0.015786360949277878,
            -0.029950998723506927,
            0.019928084686398506,
            0.005255158059298992,
            -0.05185302719473839,
            -0.0216168612241745,
            -0.033098772168159485,
            0.020024893805384636,
            -0.0016999903600662947,
            -0.014259091578423977,
            -0.016178332269191742,
            0.014816058799624443,
            0.002801012946292758,
            -0.0028467015363276005,
            -0.01316511444747448,
            0.02426462434232235,
            0.006667653098702431,
            0.023298906162381172,
            -0.03120117262005806,
            -0.0256965309381485,
            0.019219981506466866,
            0.01514734048396349,
            -0.010465208441019058,
            2.359497193538118e-05,
            0.002843244932591915,
            -0.011541983112692833,
            -0.02877410128712654,
            0.01910313405096531,
            -0.04392503574490547,
            0.01946309581398964,
            -0.0017181013245135546,
            -0.007665880955755711,
            -0.008057503961026669,
            -0.006474736612290144,
            0.01086580753326416,
            0.023665208369493484,
            -0.0024490503128618,
            -0.0013486258685588837,
            -0.016856340691447258,
            0.009107041172683239,
            0.0327458381652832,
            -0.028077084571123123,
            0.008699032478034496,
            0.022415146231651306,
            0.017746804282069206,
            0.009908461011946201,
            0.012103283777832985,
            0.0157192163169384,
            0.017108086496591568,
            0.006928183138370514,
            -0.024589277803897858,
            -0.02767503820359707,
            -0.03622422367334366,
            -0.0510888546705246,
            0.02470770664513111,
            -0.04739310219883919,
            -0.027528991922736168,
            0.03724869713187218,
            0.025490233674645424,
            -0.022215859964489937,
            0.039718955755233765,
            -0.006579059641808271,
            0.0354338176548481,
            0.026410983875393867,
            0.03023890219628811,
            -0.0017264073248952627,
            -0.024934295564889908,
            -0.007908671163022518,
            0.025870738551020622,
            -0.029460472986102104,
            -0.0006995594594627619,
            -0.007181497290730476,
            0.0037068345118314028,
            0.013614052906632423,
            0.024911295622587204,
            -0.013310058042407036,
            -0.015819432213902473,
            0.028604278340935707,
            -0.01543336734175682,
            0.01889132149517536,
            -0.025964204221963882,
            -0.01423574611544609,
            0.005966033320873976,
            0.002611559582874179,
            -0.008335520513355732,
            0.009052980691194534,
            -0.014472614042460918,
            0.0060765244998037815,
            0.03923042118549347,
            -0.008386162109673023,
            0.023922612890601158,
            -3.460769585217349e-05,
            0.006825245451182127,
            -0.04804447293281555,
            0.06435705721378326,
            -0.04605540633201599,
            -0.012299305759370327,
            0.007144615054130554,
            -0.03222174942493439,
            -0.01788744516670704,
            0.014380275271832943,
            -0.04980536922812462,
            0.004984045866876841,
            -0.012300880625844002,
            -0.0026475924532860518,
            -0.010828575119376183,
            -0.014586974866688251,
            0.008639506064355373,
            0.019258977845311165,
            0.037260595709085464,
            -0.014611165970563889,
            0.014508891850709915,
            -0.0052319779060781,
            0.01227598637342453,
            0.010069627314805984,
            -0.00550265284255147,
            0.004131839144974947,
            -0.026254484429955482,
            -0.004458436276763678,
            -0.00036025987355969846,
            0.0058981976471841335,
            0.035689935088157654,
            -0.01161256805062294,
            -0.030161069706082344,
            0.015543942339718342,
            -0.023774053901433945,
            -0.01930096186697483,
            0.0007570752641186118,
            0.0031968054827302694,
            -0.028459548950195312,
            0.010889592580497265,
            -0.010100451298058033,
            -0.012879765592515469,
            0.024782681837677956,
            0.03214860334992409,
            0.022924767807126045,
            0.005740079563111067,
            0.019739899784326553,
            -0.008161785081028938,
            -0.003326224163174629,
            -0.0015525801572948694,
            -0.0002120155986631289,
            -0.04296064376831055,
            0.01623658835887909,
            0.015210785903036594,
            0.010546496137976646,
            0.011074503883719444,
            0.027342049404978752,
            -0.004534123931080103,
            0.002246904419735074,
            0.01765139028429985,
            -0.004188022576272488,
            0.029007231816649437,
            -0.011746583506464958,
            0.004429344087839127,
            0.028035420924425125,
            0.007181708700954914,
            -0.004544949624687433,
            0.008957900106906891,
            0.0400475338101387,
            -0.000971080909948796,
            -0.013306445442140102,
            -0.0046994127333164215,
            -0.02336864545941353,
            -0.03603360056877136,
            -0.0040650139562785625,
            -0.013118617236614227,
            -0.0254567451775074,
            0.020334109663963318,
            -0.022370807826519012,
            -0.004618912935256958,
            -0.025049174204468727,
            0.016460496932268143,
            0.025054119527339935,
            -0.0327424481511116,
            0.0064424979500472546,
            0.025424672290682793,
            0.007889857515692711,
            -0.031970929354429245,
            0.003075085813179612,
            0.015119850635528564,
            -0.004630079958587885,
            0.04248804971575737,
            -0.010232862085103989,
            0.00034620738006196916,
            -0.018218547105789185,
            0.014183278195559978,
            0.015033482573926449,
            -0.00374356796965003,
            -0.014255113899707794,
            -0.00684482092037797,
            -0.00537863839417696,
            0.021832004189491272,
            0.01713733747601509,
            -0.011247425340116024,
            0.0196083914488554,
            -0.015930162742733955,
            -0.026604827493429184,
            -0.031402140855789185,
            0.030392875894904137,
            -0.019796453416347504,
            -0.026664992794394493,
            -0.017087392508983612,
            -0.01653561368584633,
            -0.024182651191949844,
            -0.02464243397116661,
            -0.052643612027168274,
            -0.01693972572684288,
            -0.01533291582018137,
            0.006057983264327049,
            -0.019408416002988815,
            -0.005678757093846798,
            0.009265484288334846,
            0.0008451284375041723,
            -0.027259981259703636,
            0.009936276823282242,
            -0.006066134665161371,
            -0.012663288973271847,
            -0.025044580921530724,
            -0.02944357506930828,
            0.008177697658538818,
            -0.030513321980834007,
            -0.023283550515770912,
            -0.018893994390964508,
            0.01873454451560974,
            -0.012654743157327175,
            0.02096923440694809,
            -0.0075941248796880245,
            -0.002407458145171404,
            -0.02059916779398918,
            -0.017679061740636826,
            -0.00798606313765049,
            -0.012742120772600174,
            -0.029777660965919495,
            0.005576911848038435,
            -0.007117305416613817,
            -0.027278970927000046,
            -0.016199342906475067,
            0.020512020215392113,
            0.02099291794002056,
            0.005520136561244726,
            0.034345898777246475,
            -0.03280467912554741,
            0.012670491822063923,
            0.019594397395849228,
            0.009396793320775032,
            -0.022536199539899826,
            0.039194267243146896,
            -0.04322202876210213,
            0.0027611576952040195,
            0.0015982670010998845,
            0.030258741229772568,
            0.0008075957302935421,
            -0.005825489293783903,
            0.05097687244415283,
            0.014562389813363552,
            -0.013688617385923862,
            0.01323095802217722,
            0.010283363983035088,
            0.01830597221851349,
            0.012449880130589008,
            0.0028155993204563856,
            0.018590165302157402,
            0.00380327133461833,
            -0.03657544031739235,
            0.012638766318559647,
            -0.011216570623219013,
            0.02431231550872326,
            -0.011624807491898537,
            -0.006263036746531725,
            0.023336920887231827,
            0.03258117660880089,
            -0.03332443907856941,
            -0.003435866441577673,
            0.021419208496809006,
            -0.015906069427728653,
            0.013858254998922348,
            0.04655095562338829,
            0.009386608377099037,
            0.031409092247486115,
            0.011160326190292835,
            -0.009556533768773079,
            -0.029978787526488304,
            0.025533363223075867,
            0.007653020787984133,
            -0.024934209883213043,
            0.024177812039852142,
            0.0028653328772634268,
            -0.0021308741997927427,
            -0.011530152522027493,
            0.0196494422852993,
            -0.00985929649323225,
            0.03969034552574158,
            -0.01454995572566986,
            -0.019528113305568695,
            0.004607971291989088,
            0.005390635225921869,
            0.022382821887731552,
            0.009778713807463646,
            0.019253836944699287,
            0.022749945521354675,
            0.038030415773391724,
            0.03840174153447151,
            -0.02118816412985325,
            0.012965931557118893,
            -0.030854079872369766,
            0.0219394713640213,
            0.04000787436962128,
            0.03339747339487076,
            0.01723192073404789,
            0.014481847174465656,
            -0.0056363497860729694,
            0.03008837066590786,
            -0.025095701217651367,
            -0.015955941751599312,
            -0.021246295422315598,
            -0.004321952350437641,
            0.030884819105267525,
            -0.02685336396098137,
            -0.0009002801962196827,
            -0.010073290206491947,
            -0.018623797222971916,
            0.02556392177939415,
            -0.018268924206495285,
            0.009489089250564575,
            0.03608504682779312,
            0.013118674978613853,
            0.01566619984805584,
            0.020077073946595192,
            -0.0019369719084352255,
            0.0040700845420360565,
            -0.0016980321379378438,
            0.029736898839473724,
            0.02106935903429985,
            -0.022461365908384323,
            0.0005672532133758068,
            0.022870687767863274,
            -0.023275313898921013,
            -0.017789624631404877,
            0.008178171701729298,
            0.030021004378795624,
            -0.009065450169146061,
            0.018046652898192406,
            -0.0118053974583745,
            -0.0019843741320073605,
            0.03235273063182831,
            -0.007012057118117809,
            -0.004894043784588575,
            0.032596975564956665,
            -0.004055131692439318,
            0.03315126523375511,
            0.02129868045449257,
            0.0006079827435314655,
            -0.03510967269539833,
            0.030593564733862877,
            -0.03276481479406357,
            -0.022898510098457336,
            0.023396333679556847,
            0.0028826023917645216,
            0.02029891312122345,
            0.02928011491894722,
            0.015530655160546303,
            0.0071158986538648605,
            -0.010918313637375832,
            -0.03293900564312935,
            0.009120325557887554,
            0.019222073256969452,
            0.009237537160515785,
            -0.007464543916285038,
            0.0030483922455459833,
            -0.020767109468579292,
            -0.0415598601102829
        ],
        [
            -0.02394883893430233,
            -0.031544677913188934,
            -0.018177427351474762,
            0.021514631807804108,
            -0.0013561587547883391,
            -0.005692383740097284,
            0.006775546818971634,
            -0.04237953945994377,
            0.010073685087263584,
            -0.019864637404680252,
            0.017091648653149605,
            0.018854836001992226,
            0.008110681548714638,
            -0.03348841145634651,
            -0.03726017102599144,
            0.05935646593570709,
            -0.017713619396090508,
            0.0009026032639667392,
            0.01283166091889143,
            0.01529912743717432,
            -0.003182755783200264,
            0.008425392210483551,
            -0.024776360020041466,
            0.006031645927578211,
            -0.04573352262377739,
            -0.03258441016077995,
            0.06504633277654648,
            -0.007001813035458326,
            0.010640627704560757,
            0.01798349991440773,
            0.02134856954216957,
            0.013973142951726913,
            0.03762215003371239,
            -0.014878297224640846,
            0.00029850879218429327,
            0.013550637289881706,
            -0.0010047260439023376,
            -0.04103202745318413,
            -0.021389136090874672,
            -0.0200201328843832,
            -0.038379646837711334,
            0.022494135424494743,
            0.013821049593389034,
            0.018138457089662552,
            -0.029052482917904854,
            -0.0021682854276150465,
            0.02960655651986599,
            0.04321547597646713,
            0.017882613465189934,
            0.01272297091782093,
            -0.007693951949477196,
            -0.024303708225488663,
            -0.06913896650075912,
            -0.009956946596503258,
            0.025153888389468193,
            0.05261000245809555,
            0.02092757634818554,
            -0.016479168087244034,
            0.01767880469560623,
            -0.021189702674746513,
            -0.05528431758284569,
            -0.0028881949838250875,
            0.04157678410410881,
            0.0027243609074503183,
            -0.006113100331276655,
            -0.06281056255102158,
            0.0559636652469635,
            0.006301965564489365,
            0.005190221592783928,
            0.0012348813470453024,
            0.0008815453038550913,
            -0.010580751113593578,
            -0.022078610956668854,
            -0.00670945318415761,
            0.041450828313827515,
            0.03340964391827583,
            0.013622640632092953,
            0.024793019518256187,
            -0.023884065449237823,
            0.008811390027403831,
            0.01023098360747099,
            -0.036708611994981766,
            0.0024087876081466675,
            -0.027862243354320526,
            0.04241631552577019,
            -0.05533195659518242,
            -0.028169797733426094,
            -0.00401502288877964,
            -0.041790880262851715,
            -0.10181881487369537,
            0.03786318749189377,
            0.03310635685920715,
            -0.05562932789325714,
            -0.0019834344275295734,
            -0.016641216352581978,
            -0.03985803201794624,
            0.026372138410806656,
            -0.002538763452321291,
            0.0654207170009613,
            -0.0337538868188858,
            -0.030623504891991615,
            -0.03372211754322052,
            0.0069260792806744576,
            0.008005774579942226,
            -0.03308044373989105,
            0.002463635290041566,
            -0.02481580339372158,
            -0.026346776634454727,
            -0.0014862807001918554,
            -0.03196115791797638,
            0.00130087963771075,
            0.03524402529001236,
            0.03250763192772865,
            -0.010743510909378529,
            -0.01218764204531908,
            -0.03745460510253906,
            0.0044161551631987095,
            -0.006328187882900238,
            0.00876945536583662,
            0.030253330245614052,
            -0.007777417078614235,
            0.002199722221121192,
            -0.05336257070302963,
            0.001348748686723411,
            0.021461617201566696,
            -0.0413547120988369,
            -0.03879229724407196,
            -0.005301538854837418,
            -0.023117130622267723,
            -0.030294319614768028,
            -0.02161618508398533,
            0.0566922165453434,
            -0.03950602561235428,
            -0.01164812222123146,
            0.04083945229649544,
            0.024551788344979286,
            0.0031261141411960125,
            0.015928758308291435,
            0.04071555659174919,
            -0.011412302032113075,
            -0.004876542370766401,
            -0.024221323430538177,
            0.040043704211711884,
            0.010739213787019253,
            -0.05644959956407547,
            0.0352950356900692,
            0.023859746754169464,
            -0.033517345786094666,
            -0.021733298897743225,
            -0.010959973558783531,
            0.01716865785419941,
            -0.01554079633206129,
            -0.02567869797348976,
            0.0008850130834616721,
            -0.01422272901982069,
            -0.020902203395962715,
            0.03544021025300026,
            0.016814397647976875,
            0.02882666513323784,
            -0.05296284332871437,
            -0.012232384644448757,
            -0.02045096829533577,
            0.009324774146080017,
            0.001691781566478312,
            -0.010425102896988392,
            -0.027643226087093353,
            -0.03925279155373573,
            0.051015011966228485,
            0.0018115588463842869,
            0.009410515427589417,
            -0.01995532400906086,
            -0.014808963984251022,
            0.01095761638134718,
            0.008503703400492668,
            -0.020381279289722443,
            -0.014416810125112534,
            -0.040754806250333786,
            0.013969709165394306,
            -0.014248544350266457,
            -0.05367102101445198,
            -0.0611272007226944,
            0.012739402242004871,
            0.003745217341929674,
            -0.00019849342061206698,
            -0.04205892235040665,
            0.017626626417040825,
            0.043669845908880234,
            0.028089961037039757,
            0.04783974215388298,
            0.035302650183439255,
            0.0005138860433362424,
            0.04220300167798996,
            -0.019056877121329308,
            -0.013222401961684227,
            0.0033688864205032587,
            -0.0036425150465220213,
            -0.0044371890835464,
            0.010186620056629181,
            0.036970920860767365,
            -0.009568867273628712,
            -0.013566671870648861,
            -0.002326431218534708,
            -0.04470251873135567,
            -0.01706208474934101,
            -0.017762990668416023,
            -0.016782265156507492,
            0.051988162100315094,
            -0.014628981240093708,
            -0.022878039628267288,
            -0.019505908712744713,
            0.041624218225479126,
            -0.006259346846491098,
            -0.0349690318107605,
            0.013889678753912449,
            0.003404449438676238,
            0.03122517466545105,
            0.03576861694455147,
            -0.001961443107575178,
            -0.010344470851123333,
            0.010668459348380566,
            0.018536115065217018,
            0.00028216070495545864,
            0.04356716200709343,
            0.03455658629536629,
            -0.03200807794928551,
            -0.012459349818527699,
            -0.001104138558730483,
            0.018540941178798676,
            0.0028498731553554535,
            0.017221076413989067,
            0.006031554192304611,
            0.0307308416813612,
            -0.007321319077163935,
            0.004244295880198479,
            -0.02840903028845787,
            0.018987970426678658,
            -0.014571563340723515,
            -0.010331953875720501,
            0.017400838434696198,
            0.02394809201359749,
            0.014358317479491234,
            0.015749040991067886,
            0.038446974009275436,
            0.021772075444459915,
            0.007765035144984722,
            0.0030556973069906235,
            -0.02881680428981781,
            0.013861631974577904,
            -0.01902133971452713,
            -0.01467104721814394,
            0.0026909084990620613,
            0.024720169603824615,
            -0.015543781220912933,
            -0.004155205562710762,
            -0.024394357576966286,
            0.023513205349445343,
            -0.01240383181720972,
            0.0150758670642972,
            -0.06749407202005386,
            -0.025058133527636528,
            0.0009387223399244249,
            -0.04001011326909065,
            -0.03991976007819176,
            0.006400479003787041,
            0.025114301592111588,
            0.07332000881433487,
            0.0032310946844518185,
            -0.021027391776442528,
            -0.02316655032336712,
            -0.01202125009149313,
            -0.0036372998729348183,
            -0.029291678220033646,
            -0.041867177933454514,
            0.0226631797850132,
            0.025491129606962204,
            -0.01676303707063198,
            0.02877967059612274,
            0.010569999925792217,
            -0.04776930436491966,
            -0.008330835960805416,
            0.023261120542883873,
            0.006614913232624531,
            0.009153616614639759,
            0.008587848395109177,
            0.011368649080395699,
            0.020891426131129265,
            0.0310901440680027,
            -0.009239782579243183,
            0.006732970476150513,
            0.021518563851714134,
            -0.024624209851026535,
            0.019712423905730247,
            -0.02580580860376358,
            0.03248310461640358,
            -0.020121946930885315,
            0.00683355238288641,
            0.023561730980873108,
            0.018559632822871208,
            -0.06049780920147896,
            -0.031637951731681824,
            -0.002033880213275552,
            0.0023649120703339577,
            -0.020444076508283615,
            -0.04123532772064209,
            0.009685583412647247,
            -0.0021499136928468943,
            -0.0428481288254261,
            -0.02426380105316639,
            -0.013003509491682053,
            -0.026646139100193977,
            -0.016813507303595543,
            -0.00803085882216692,
            -0.024102702736854553,
            0.022385381162166595,
            -0.0072201997973024845,
            0.009658750146627426,
            0.02864164672791958,
            0.03496265038847923,
            0.019647253677248955,
            0.005912694148719311,
            -0.011663651093840599,
            -0.0037727002054452896,
            0.0156413521617651,
            -0.04720168933272362,
            0.009065690450370312,
            0.02898881398141384,
            -0.011720983311533928,
            0.0034988848492503166,
            0.014371641911566257,
            0.024881208315491676,
            -0.00885002687573433,
            -0.003865394741296768,
            -0.013258672319352627,
            -0.01454665046185255,
            -0.03704656660556793,
            -0.010786064900457859,
            -0.029035072773694992,
            -0.010550524108111858,
            0.009475881233811378,
            0.02943272329866886,
            -0.03927126154303551,
            -0.019365394487977028,
            0.060620181262493134,
            0.033442482352256775,
            -0.017046701163053513,
            0.021396560594439507,
            -0.018295301124453545,
            0.038045451045036316,
            0.027770211920142174,
            0.03104589879512787,
            -0.016147296875715256,
            -0.036141350865364075,
            -0.021082166582345963,
            -0.0059819575399160385,
            -0.006782629061490297,
            -0.012899826280772686,
            -0.04901028797030449,
            0.0013318368000909686,
            -0.031862128525972366,
            -0.02405102364718914,
            -0.01207002718001604,
            -0.004046667832881212,
            -0.05272201821208,
            -0.00014359355554915965,
            0.031807176768779755,
            0.009262198582291603,
            -0.014275796711444855,
            0.008820420131087303,
            -0.0023003453388810158,
            -0.018285444006323814,
            0.014839086681604385,
            -0.03198092058300972,
            0.03162277862429619,
            -0.03100488893687725,
            0.014209480956196785,
            -0.015771295875310898,
            0.03183393552899361,
            0.054594606161117554,
            0.035676222294569016,
            -0.024066820740699768,
            0.0005440703243948519,
            -0.014205952174961567,
            -0.023689940571784973,
            -0.0010469729313626885,
            -0.002515907632187009,
            0.07894746214151382,
            0.013249941170215607,
            -0.004987417254596949,
            -0.010286720469594002,
            -0.05078361928462982,
            0.016691630706191063,
            0.031534627079963684,
            0.004885256290435791,
            0.026450788602232933,
            0.029498638585209846,
            -0.007183680776506662,
            -0.0016394404228776693,
            0.034107476472854614,
            -0.02244197577238083,
            0.007278256118297577,
            0.0058386665768921375,
            -0.011091543361544609,
            0.028966045007109642,
            -0.03759587183594704,
            -0.023900948464870453,
            -0.021138228476047516,
            0.029431695118546486,
            0.015080567449331284,
            0.017796378582715988,
            0.023836879059672356,
            0.023338524624705315,
            0.015390011481940746,
            0.06332209706306458,
            -0.0032905256375670433,
            -0.04675872251391411,
            -0.0274968184530735,
            0.03230367973446846,
            0.013746654614806175,
            0.029303481802344322,
            0.01680586487054825,
            -0.0006320499815046787,
            0.054777346551418304,
            -0.05366196855902672,
            0.00329203880392015,
            0.03173838183283806,
            -0.05527184158563614,
            -0.04750911518931389,
            0.015325875952839851,
            0.023549173027276993,
            -0.012989191338419914,
            0.046831950545310974,
            0.032536882907152176,
            -0.02574186772108078,
            -0.027118314057588577,
            -0.001789789879694581,
            0.02184903807938099,
            0.01540202833712101,
            0.006778654642403126,
            0.026605280116200447,
            -0.00985475443303585,
            -0.0494803711771965,
            -0.024017348885536194,
            -0.023131784051656723,
            0.035417962819337845,
            -0.01855619251728058,
            -0.01203249953687191,
            -0.011633016169071198,
            -0.09623423963785172,
            -0.023770669475197792,
            0.016340769827365875,
            -0.014409448020160198,
            0.01516210287809372,
            0.020776454359292984,
            0.00827269721776247,
            0.013005574233829975,
            0.0236628707498312,
            0.02014950104057789,
            0.00649638194590807,
            0.006635803729295731,
            -0.0034521720372140408,
            -0.045906588435173035,
            0.005767778027802706,
            0.0010141701204702258,
            0.025447579100728035,
            0.01918852888047695,
            -0.030806096270680428,
            0.0205971859395504,
            -0.034789275377988815,
            0.01676955632865429,
            -0.014753159135580063,
            0.00032308220397681,
            -0.01006343588232994,
            0.009779264219105244,
            -0.01931198500096798,
            -0.022364500910043716,
            0.0036067028995603323,
            -0.030465953052043915,
            0.014906913042068481,
            -0.012535784393548965,
            -0.03620941936969757,
            -0.0391421839594841,
            0.023310281336307526,
            0.001957464497536421,
            0.030160604044795036,
            0.0151180699467659,
            0.02483995072543621,
            -0.023330535739660263,
            -0.015940451994538307,
            -0.08085105568170547,
            0.022999942302703857,
            -0.02870900370180607,
            0.02756618522107601,
            -0.009594826027750969,
            -0.005210625007748604,
            0.031300101429224014,
            0.036753080785274506,
            0.06296496093273163,
            0.00136244494933635,
            -0.03327688202261925,
            0.012110657058656216,
            -0.035263899713754654,
            -0.024158651009202003,
            -0.003347832476720214,
            -0.05028654634952545,
            -0.012691422365605831,
            -0.01891491562128067,
            0.02385500632226467,
            -0.02569218911230564,
            -0.017627349123358727,
            -0.0029182150028645992,
            0.0179093386977911,
            0.0054959445260465145,
            0.024156343191862106,
            -0.021492745727300644,
            0.0011825188994407654,
            0.01589362695813179,
            -0.0051599787548184395,
            0.007401659153401852,
            -0.03542652353644371,
            -0.0013930887216702104,
            0.002657028380781412,
            -0.0022008896339684725,
            0.013174072839319706,
            -0.02990875020623207,
            -0.05176343023777008,
            -0.045182615518569946,
            -0.04000317305326462,
            0.011189325712621212,
            -0.003269027918577194,
            -0.0004922052030451596,
            -0.008259394206106663,
            -0.040479883551597595,
            -0.0008405171101912856,
            0.014591443352401257,
            0.007947060279548168,
            -0.06542821228504181,
            0.0011008220026269555,
            -0.03367462009191513,
            0.0367247574031353,
            0.0156024731695652,
            -0.018276194110512733,
            0.03197525441646576,
            -0.03380027040839195,
            0.015043850988149643,
            -0.013334579765796661,
            -0.010944802314043045,
            0.027722250670194626,
            0.014871274121105671,
            -0.027615003287792206,
            0.008582943119108677,
            -0.010868778452277184,
            -0.0029929212760180235,
            0.0006023173918947577,
            -0.037643373012542725,
            0.04636722430586815,
            -0.0034405027981847525,
            0.02889917977154255,
            0.0038418653421103954,
            -0.024357348680496216,
            -0.01564687117934227,
            -0.04359930753707886,
            -0.017253587022423744,
            0.004095789510756731,
            0.0537530891597271,
            0.026439549401402473,
            -0.016998037695884705,
            0.027064964175224304,
            0.020412812009453773,
            -0.013294978067278862,
            -0.026953062042593956,
            0.019169501960277557,
            0.007653340231627226,
            -0.0042042480781674385,
            0.00011585401807678863,
            0.03683466836810112,
            0.002747076563537121,
            0.04667326807975769,
            -0.04807613044977188,
            -0.0050249951891601086,
            0.014193686656653881,
            -0.028400178998708725,
            0.005955874919891357,
            0.015514388680458069,
            0.04580219089984894,
            -0.013765787705779076,
            -0.027837110683321953,
            0.0346798337996006,
            -0.024240614846348763,
            -0.014112786389887333,
            0.014579583890736103,
            -0.005466115660965443,
            -0.007000702433288097,
            -0.02517061121761799,
            0.004442591220140457,
            0.002047513611614704,
            0.01482588704675436,
            0.002481319010257721,
            -0.021809257566928864,
            -0.03427626192569733,
            0.01726662553846836,
            -0.042630311101675034,
            0.009317746385931969,
            0.025522924959659576,
            -0.016751429066061974,
            -0.0033080321736633778,
            -0.022565651684999466,
            -0.028156472370028496,
            -0.023920884355902672,
            -0.02877575159072876,
            0.009898469783365726,
            0.0008484653080813587,
            0.010631845332682133,
            -0.023850776255130768,
            0.002348665613681078,
            -0.007986702024936676,
            0.010826246812939644,
            0.000635927717667073,
            0.00034873466938734055,
            0.023913105949759483,
            0.003989588003605604,
            -0.023491229861974716,
            -0.007008210755884647,
            0.02172713354229927,
            0.01692206971347332,
            -0.007093511056154966,
            0.01594085991382599,
            -0.02929595671594143,
            -0.0131718460470438,
            0.008935181424021721,
            0.028247514739632607,
            0.057816095650196075,
            -0.007657675538212061,
            -0.022998396307229996,
            0.04595658928155899,
            -0.011942989192903042,
            0.01604357361793518,
            -0.017269469797611237,
            0.002600362291559577,
            0.015173385851085186,
            -0.0025417462456971407,
            0.033707864582538605,
            0.008343256078660488,
            -0.00570698594674468,
            -0.057395707815885544,
            0.0169085506349802,
            -0.011879627592861652,
            -0.01923169009387493,
            -0.03634073585271835,
            -0.03040957823395729,
            -0.02259976975619793,
            -0.039317935705184937,
            -0.023068221285939217,
            -0.010358515195548534,
            -0.02899741567671299,
            0.018579181283712387,
            -0.005066756624728441,
            -0.01431327871978283,
            -0.004313077311962843,
            -0.06874289363622665,
            0.009295515716075897,
            0.050938740372657776,
            -0.027681002393364906,
            0.024554172530770302,
            0.007251324597746134,
            0.02006329782307148,
            -0.017501497641205788,
            -0.0006343534914776683,
            -0.024941863492131233,
            0.037213195115327835,
            -0.06421054154634476,
            -0.010565649718046188,
            -0.023237319663167,
            0.025130916386842728,
            -0.008435973897576332,
            0.0055639599449932575,
            0.031394265592098236,
            0.029055973514914513,
            -0.019153527915477753,
            -0.023324310779571533,
            0.003017926588654518,
            0.039300233125686646,
            0.008563615381717682,
            -0.016689004376530647,
            0.007734532002359629,
            0.015378158539533615,
            0.0311130378395319,
            0.052211105823516846,
            -0.004117501899600029,
            0.0423569492995739,
            0.0028534000739455223,
            -0.002372003858909011,
            -0.027978118509054184,
            0.016356073319911957,
            -0.017285631969571114,
            0.0030641790945082903,
            0.011712562292814255,
            0.02070462517440319,
            0.02659960649907589,
            -0.02104644663631916,
            -0.0027739708311855793,
            0.03102446347475052,
            -0.05502656474709511,
            -0.000881086743902415,
            0.009736328385770321,
            0.010684852488338947,
            0.02731744572520256,
            0.019366178661584854,
            0.01978445053100586,
            0.029118793085217476,
            -0.04120541736483574,
            0.03372703865170479,
            -0.015011577866971493,
            0.010757874697446823,
            -0.003313435008749366,
            0.002584377769380808,
            -0.021939555183053017,
            0.03657735139131546,
            0.0024595526047050953,
            -0.020875021815299988,
            -0.002858997555449605,
            0.024790940806269646,
            -0.033743102103471756,
            0.001424468937329948,
            0.036599159240722656,
            0.009032960049808025,
            -0.0018745219567790627,
            -0.0012794419890269637,
            -0.0035266655031591654,
            0.02080398239195347,
            -0.011357618495821953,
            0.005315407644957304,
            0.0236362274736166,
            -0.029685180634260178,
            -0.013135070912539959,
            0.020861931145191193,
            0.0072341617196798325,
            -0.02111663669347763,
            -0.01783786155283451,
            0.05737491324543953,
            -0.02551264502108097,
            -0.025721827521920204,
            -0.02613114006817341,
            0.011910309083759785,
            0.009322543628513813,
            0.014366963878273964,
            0.05418998748064041,
            -0.08459637314081192,
            -0.010433263145387173,
            -0.010227241553366184,
            0.011547928676009178,
            -0.026889650151133537,
            0.01653677225112915,
            -0.002410838846117258,
            0.04515215754508972,
            -0.03383510932326317,
            0.0010598523076623678,
            -0.014664873480796814,
            -0.018775375559926033,
            0.0634179338812828,
            -0.012680641375482082,
            0.007021445780992508,
            0.013054270297288895,
            0.01860559545457363,
            0.004145451821386814,
            -0.03997987508773804,
            -0.0012735771015286446,
            -0.01601448468863964,
            -0.024809909984469414,
            0.01615501567721367,
            -0.018509438261389732,
            0.024593792855739594,
            -0.006343871355056763,
            0.013549636118113995,
            0.01842944696545601,
            0.025860371068120003,
            0.020255958661437035,
            0.035049375146627426,
            -0.02112872153520584,
            -0.0157371386885643,
            -0.01343006081879139,
            0.02266283705830574,
            -0.0018506427295506,
            0.04446343332529068,
            0.032053302973508835,
            -0.006147688254714012,
            -0.0013649112079292536,
            -0.022318849340081215,
            0.033399831503629684,
            0.015137667767703533,
            -0.01659684255719185,
            -0.0062413206323981285,
            0.002462978009134531,
            0.03330284729599953,
            0.01664244383573532,
            0.013448191806674004,
            -0.04972459748387337,
            -0.030789058655500412,
            -0.011408761143684387,
            0.016251936554908752,
            0.01195207703858614,
            -0.024165203794836998,
            0.0030527939088642597,
            -0.0027364189736545086,
            -0.01612287200987339,
            -0.012744209729135036,
            -0.00817397516220808,
            0.000623574829660356,
            -0.005793531890958548,
            0.006894026882946491,
            -0.025473812595009804,
            -0.024060139432549477,
            0.011911412701010704,
            0.037050940096378326,
            -0.007744253147393465,
            -0.06473458558320999,
            -0.0089060477912426,
            -0.027955107390880585,
            0.024875067174434662,
            -0.017181606963276863,
            0.004680509679019451,
            0.051153507083654404,
            -0.03734476864337921,
            -0.009690124541521072,
            0.02211838588118553,
            0.015324448235332966,
            0.006702051963657141,
            0.0028935009613633156,
            -0.019742418080568314,
            4.2377425415907055e-05,
            0.017079168930649757,
            -0.008388252928853035,
            0.008911965414881706,
            0.003248104127123952,
            -0.0015236729523167014,
            0.0080193430185318,
            0.018410351127386093,
            -0.026284776628017426,
            -0.02919442020356655,
            -0.00856669619679451,
            0.03335512802004814,
            -0.006985499057918787,
            0.006830794736742973,
            -0.02930847369134426,
            0.014222821220755577,
            0.029241107404232025,
            0.008736534975469112,
            -0.0008264416246674955,
            -0.040964022278785706,
            0.0001898081973195076,
            0.04300184175372124,
            -0.012865434400737286,
            -0.008233024738729,
            0.0219762921333313,
            0.022310322150588036,
            0.019936583936214447,
            0.03439539298415184,
            -0.011194058693945408,
            0.04188425838947296,
            -0.021287890151143074,
            0.01146014779806137,
            0.013578791171312332,
            0.028155669569969177,
            -0.02994890697300434,
            -0.004445336293429136,
            0.04008466750383377,
            -0.0034990222193300724,
            0.02960265800356865,
            0.01619609072804451,
            -0.027508383616805077,
            0.0341920480132103,
            0.004776373505592346,
            0.016972510144114494,
            0.04515921697020531,
            0.030520064756274223,
            -0.0026368959806859493,
            -0.04868922382593155,
            0.018767017871141434,
            -0.052273381501436234,
            -0.009171843528747559,
            0.028644468635320663,
            -0.052427977323532104,
            0.0005927239544689655,
            0.006369560491293669,
            0.008609169162809849,
            -0.047352150082588196,
            -0.029479315504431725,
            0.006042346358299255,
            0.06719352304935455,
            -0.011325356550514698,
            0.0038472022861242294,
            0.05756137892603874,
            0.015099115669727325,
            -0.03406906872987747,
            -0.04329124093055725,
            0.01061876118183136,
            -0.02258024923503399,
            -0.02724316157400608,
            0.021573316305875778,
            0.01719595491886139,
            -0.028758658096194267,
            0.022652536630630493,
            0.02908877469599247,
            -0.0073725273832678795,
            0.00871318206191063,
            0.014243843965232372,
            -0.010802581906318665,
            -0.04317641630768776,
            -0.019021963700652122,
            -0.013155922293663025,
            0.009449816308915615,
            0.017860522493720055,
            -0.03325118497014046,
            -0.023856643587350845,
            0.010666344314813614,
            -0.022640913724899292,
            0.006796861998736858,
            -0.01589314639568329,
            0.0055390577763319016,
            -0.018086781725287437,
            0.014430619776248932,
            0.033941201865673065,
            0.004920510575175285,
            0.022656071931123734,
            -0.0006494056433439255,
            0.04099806770682335,
            -0.010062163695693016,
            -0.000119085430924315,
            0.009380691684782505,
            0.023835496976971626,
            -0.045246947556734085,
            -0.005800563842058182,
            0.020569611340761185,
            0.014214138500392437,
            0.013868289068341255,
            -0.030437160283327103,
            0.001853827852755785,
            -0.0008429919253103435,
            0.0371471531689167,
            -0.03278813883662224,
            0.023857848718762398,
            -0.020449938252568245,
            -0.012675982899963856,
            -0.019917063415050507,
            0.02373710460960865,
            0.03557350113987923,
            0.020619360730051994,
            0.0024179548490792513,
            0.02851245552301407,
            -0.08805061131715775,
            0.0376700684428215,
            -0.03929244726896286,
            0.021482588723301888,
            0.006457549519836903,
            0.02694106660783291,
            5.8568122767610475e-05,
            0.032055627554655075,
            -0.03166116401553154,
            -0.004111274611204863,
            -0.010820389725267887,
            -0.03367291018366814,
            -0.027492424473166466,
            -0.052992213517427444,
            -0.0073014963418245316,
            -0.008435627445578575,
            -0.02359110303223133,
            -0.02512960135936737,
            -0.04041131213307381,
            0.014756821095943451,
            -0.02204250358045101,
            -0.0072625321336090565,
            0.013555292040109634,
            0.008706366643309593,
            -0.02706998586654663,
            0.011590799316763878,
            -0.03452810272574425,
            0.013836740516126156,
            0.005504970904439688,
            -0.018450679257512093,
            0.001926687778905034,
            -0.028487112373113632,
            0.01712939329445362,
            0.02900751121342182,
            -0.034328363835811615,
            0.02657184936106205,
            -0.018153568729758263,
            -0.016310961917042732,
            -0.07048702239990234,
            0.01563834771513939,
            -0.002924400381743908,
            -0.024317506700754166,
            0.01772432215511799,
            -0.04020478203892708,
            0.004538003820925951,
            -0.012278548441827297,
            -0.018496522679924965,
            -0.06024032458662987,
            -0.022841719910502434,
            0.02858508937060833,
            -0.006107655819505453,
            0.007835133001208305,
            0.016656244173645973,
            -0.01146193128079176,
            -0.012900425121188164,
            0.0033819624222815037,
            0.037382420152425766,
            -0.025113841518759727,
            0.015059806406497955,
            -0.033547498285770416,
            -0.00014783865481149405,
            -0.053138021379709244,
            -0.027778413146734238,
            0.009142735041677952,
            0.0014859058428555727,
            0.031712524592876434,
            -0.0262601338326931,
            -0.018145181238651276,
            -0.039166007190942764,
            -0.0025182380340993404,
            0.04979846999049187,
            -0.008779219351708889,
            0.0022622959222644567,
            -0.07661337405443192,
            0.04200182855129242,
            0.003917024005204439
        ],
        [
            -0.012217402458190918,
            0.010708436369895935,
            -0.004959880840033293,
            0.009941957890987396,
            0.009497204795479774,
            0.019137408584356308,
            -0.043817490339279175,
            0.0070646824315190315,
            -0.017500871792435646,
            0.035193104296922684,
            -0.019431304186582565,
            -0.024546390399336815,
            -0.01971164159476757,
            0.003451577154919505,
            -0.03021343983709812,
            0.007167298812419176,
            -0.021449480205774307,
            0.007631347980350256,
            0.023633647710084915,
            -0.038577429950237274,
            0.013146154582500458,
            -0.014320867136120796,
            0.00519865145906806,
            0.027957987040281296,
            -0.050768446177244186,
            0.010002454742789268,
            -0.036382224410772324,
            0.0486396849155426,
            0.010713794268667698,
            0.006102978251874447,
            0.018430465832352638,
            -0.04093515872955322,
            0.03699669986963272,
            0.006628293544054031,
            0.010426817461848259,
            0.03946492820978165,
            -0.050894126296043396,
            0.015248787589371204,
            0.009062734432518482,
            0.011753806844353676,
            0.010159309953451157,
            -0.015393880195915699,
            0.0052914610132575035,
            0.01034922432154417,
            -0.03362979739904404,
            0.015129288658499718,
            0.010460599325597286,
            -0.03892425447702408,
            0.0024146682117134333,
            -0.011611396446824074,
            -0.013475747779011726,
            -0.015500213019549847,
            0.021054768934845924,
            -0.04640040919184685,
            -0.009083854034543037,
            0.02002672664821148,
            -0.027729367837309837,
            0.02607860416173935,
            0.02515387162566185,
            -0.022893549874424934,
            -0.031216511502861977,
            -0.01503236684948206,
            -0.03667132928967476,
            -0.0025190827436745167,
            0.013223860412836075,
            -0.034893617033958435,
            0.023089313879609108,
            0.015074129216372967,
            0.034522537142038345,
            -0.01721513271331787,
            -0.012679025530815125,
            -0.014123148284852505,
            -0.02067541889846325,
            -0.005326419603079557,
            -0.010074078105390072,
            -0.03917160630226135,
            0.01703007146716118,
            -0.037200458347797394,
            -0.013614088296890259,
            -0.04430464282631874,
            0.018404800444841385,
            -0.03378480672836304,
            -0.03988521918654442,
            0.012498002499341965,
            -0.030701637268066406,
            0.03127434104681015,
            -0.01539728045463562,
            0.030356379225850105,
            0.015605087392032146,
            0.030403470620512962,
            0.005101126618683338,
            0.025110529735684395,
            -0.004018038976937532,
            -0.007013489492237568,
            -0.0011563439620658755,
            -0.019866885617375374,
            0.08293083310127258,
            0.007187035866081715,
            -0.07512026280164719,
            0.0017480563838034868,
            0.007220918778330088,
            -0.025500571355223656,
            0.02078215219080448,
            0.016900673508644104,
            -0.01514467503875494,
            -0.04076124355196953,
            0.03195016086101532,
            0.01623929850757122,
            -0.07433004677295685,
            -0.02297852374613285,
            -0.026360608637332916,
            0.0054340423084795475,
            -0.022212818264961243,
            0.005396351683884859,
            0.014020098373293877,
            0.005464379675686359,
            0.012280370108783245,
            0.005500222556293011,
            0.017919356003403664,
            0.012974143959581852,
            0.0034142860677093267,
            0.024061430245637894,
            -0.04076380282640457,
            -0.004664761014282703,
            0.015179828740656376,
            0.005487174727022648,
            -0.009970281273126602,
            0.025410862639546394,
            -0.040387559682130814,
            0.023412397131323814,
            -0.021024396643042564,
            -0.0016734374221414328,
            0.011642752215266228,
            0.019184529781341553,
            0.023924551904201508,
            -0.008445055224001408,
            0.0476217046380043,
            -0.013254637829959393,
            -0.02498891018331051,
            -0.022737625986337662,
            -0.007818753831088543,
            -0.037544380873441696,
            0.03302736207842827,
            -0.06371532380580902,
            0.0029666470363736153,
            0.025792326778173447,
            -0.002633460331708193,
            0.027491163462400436,
            0.010296194814145565,
            0.0035999570973217487,
            -0.03340890631079674,
            -0.029438208788633347,
            -0.04799136891961098,
            0.046311601996421814,
            -0.012821942567825317,
            -0.026016702875494957,
            -0.013934819959104061,
            0.0026644524186849594,
            0.0034574526362121105,
            -0.04571076110005379,
            -0.017407961189746857,
            0.014839572831988335,
            0.05347102880477905,
            0.028344707563519478,
            0.028068117797374725,
            -0.022007882595062256,
            0.05877531319856644,
            -0.02202056162059307,
            0.03376765921711922,
            -0.017602328211069107,
            0.02756369672715664,
            -0.04025522246956825,
            0.0029366272501647472,
            -0.018532177433371544,
            -0.005594078917056322,
            -0.001514653442427516,
            0.016389913856983185,
            -0.0033127572387456894,
            0.004948325455188751,
            -0.04533335193991661,
            0.008341200649738312,
            -0.03917628526687622,
            0.0426354818046093,
            -0.028426500037312508,
            0.0034896789584308863,
            -0.0009824504377320409,
            -0.004795503802597523,
            -0.0042686681263148785,
            0.01682966761291027,
            -0.0171210877597332,
            -0.02808285690844059,
            -0.016706189140677452,
            0.056352779269218445,
            -0.03451965004205704,
            0.007387707009911537,
            -0.013103722594678402,
            -0.005598209332674742,
            0.01988532580435276,
            -0.008174573071300983,
            0.019711874425411224,
            -0.007609047461301088,
            0.003341024974361062,
            -0.04121917858719826,
            0.0060091810300946236,
            -0.025226537138223648,
            -0.04028195142745972,
            -0.04595085605978966,
            0.005892957095056772,
            0.014893458224833012,
            0.00819344725459814,
            0.003112016012892127,
            -0.022276872768998146,
            0.006081852596253157,
            -0.010958939790725708,
            -0.03688376396894455,
            -0.035803958773612976,
            0.027875103056430817,
            0.0037897848524153233,
            0.02404968999326229,
            -0.005207077134400606,
            0.02985912375152111,
            0.07348493486642838,
            -0.030426010489463806,
            0.01066949125379324,
            0.006072462536394596,
            0.0064695123583078384,
            0.0053459154441952705,
            -0.014463401399552822,
            -0.010682482272386551,
            -0.02804948203265667,
            -0.04772443696856499,
            0.04631202295422554,
            0.010298418812453747,
            -0.008038235828280449,
            0.01951485313475132,
            0.01565735787153244,
            0.047631725668907166,
            -0.03009878471493721,
            -0.005707583390176296,
            -0.010009892284870148,
            -0.020999260246753693,
            -0.00045402563409879804,
            0.025838827714323997,
            -0.0016967181582003832,
            0.04760734364390373,
            0.023301390931010246,
            -0.013318362645804882,
            0.00012676339247263968,
            0.004372434224933386,
            0.0056763943284749985,
            0.0022182229440659285,
            -0.002624902408570051,
            0.035597242414951324,
            -0.01942090131342411,
            -0.0022148743737488985,
            -0.014111456461250782,
            -0.013999177142977715,
            0.03016945719718933,
            -0.033280033618211746,
            0.02133653499186039,
            -0.020213516429066658,
            -0.03900561481714249,
            0.016927536576986313,
            -0.0033259131014347076,
            0.018753405660390854,
            0.044350516051054,
            0.00015113090921659023,
            0.0053111473098397255,
            0.011852544732391834,
            0.005784910637885332,
            -0.00452234223484993,
            0.005210317671298981,
            -0.013112097047269344,
            0.01483662985265255,
            -0.011528284288942814,
            -0.0036876783706247807,
            -0.00832271110266447,
            0.01417060662060976,
            -0.03753768280148506,
            -0.012481841258704662,
            -0.010047311894595623,
            0.01704813912510872,
            0.004158958327025175,
            0.007261443883180618,
            0.027778765186667442,
            -0.00014586471661459655,
            0.01379125565290451,
            0.04972587898373604,
            -0.016216877847909927,
            -0.03405992314219475,
            0.005530235357582569,
            -0.019069623202085495,
            0.016357896849513054,
            -0.007631156127899885,
            -0.014118812046945095,
            -0.02613050490617752,
            0.020274173468351364,
            0.06496915966272354,
            -0.020787861198186874,
            -0.02778898924589157,
            -0.014453056268393993,
            -0.025850757956504822,
            -0.004838007036596537,
            0.018042685464024544,
            0.005349207203835249,
            0.050807904452085495,
            0.00289455009624362,
            -0.011051522567868233,
            0.029028141871094704,
            0.0244621392339468,
            0.013985127210617065,
            -0.021885424852371216,
            -0.034769244492053986,
            -0.014041908085346222,
            0.01176877599209547,
            0.05078240483999252,
            -0.031854297965765,
            0.023764999583363533,
            0.01748610846698284,
            -0.025420526042580605,
            -0.03445085138082504,
            -0.027698997408151627,
            0.00869250763207674,
            -0.04852208495140076,
            -0.004049655050039291,
            -0.035345591604709625,
            -0.00952503364533186,
            0.034682709723711014,
            -0.007834037765860558,
            0.0054350439459085464,
            -0.00892937183380127,
            -0.009870660491287708,
            -0.06023743748664856,
            -0.017604561522603035,
            0.00017405906692147255,
            -0.02206244133412838,
            0.0005711009725928307,
            -0.0018066405318677425,
            0.00299280253238976,
            -0.014844466000795364,
            -0.006640038453042507,
            -0.012208877131342888,
            -0.0148521913215518,
            -0.02408338524401188,
            0.004011818673461676,
            0.04207027703523636,
            0.01652097888290882,
            -0.018620850518345833,
            -0.022410176694393158,
            -0.018401823937892914,
            0.022655131295323372,
            0.022961314767599106,
            -0.07221672683954239,
            -0.009386901743710041,
            0.023272080346941948,
            -0.024092096835374832,
            -0.022989189252257347,
            -0.014297445304691792,
            -0.031246395781636238,
            0.018557898700237274,
            0.00040744527359493077,
            0.03285384178161621,
            0.003668500343337655,
            -0.006406585685908794,
            0.05567841976881027,
            0.016435345634818077,
            0.007244063541293144,
            -0.03246624022722244,
            -0.023478858172893524,
            -0.03283517807722092,
            0.005695629399269819,
            -0.006653826218098402,
            0.020423943176865578,
            0.056126538664102554,
            0.004063268192112446,
            -0.021265672519803047,
            -0.0014896636130288243,
            0.005595088936388493,
            -0.01582951471209526,
            0.0313071683049202,
            0.05412407964468002,
            -0.00845708604902029,
            0.013052240014076233,
            -0.006262508686631918,
            -0.05671704560518265,
            -0.07811680436134338,
            -0.017315195873379707,
            -0.012646730989217758,
            0.009939955547451973,
            -0.04775555804371834,
            -0.034846145659685135,
            -0.016735564917325974,
            -0.047614943236112595,
            0.009653795510530472,
            0.023387037217617035,
            -0.061285536736249924,
            -0.03284851089119911,
            0.027453932911157608,
            0.029794275760650635,
            0.003665064461529255,
            0.009064767509698868,
            -0.039167389273643494,
            0.018064646050333977,
            0.007307831197977066,
            -0.00237602018751204,
            -0.007190218660980463,
            0.012781944125890732,
            0.007123025134205818,
            0.01786896027624607,
            0.02661360241472721,
            -0.002786550670862198,
            0.01531283464282751,
            -0.023638995364308357,
            -0.014075145125389099,
            0.03312591090798378,
            -0.01765616610646248,
            -8.301786874653772e-05,
            -0.03817348927259445,
            0.015371015295386314,
            -0.007705401629209518,
            -0.007106379140168428,
            0.011509441770613194,
            -0.05460035055875778,
            -0.009713768027722836,
            -0.043993763625621796,
            0.08280675858259201,
            -0.025444649159908295,
            -0.035384297370910645,
            -0.009815247729420662,
            0.03173239901661873,
            0.07246556133031845,
            -0.014269278384745121,
            0.009445363655686378,
            -0.00696367584168911,
            -0.013705255463719368,
            5.0882699724752456e-05,
            0.007522957865148783,
            -0.005548938177525997,
            0.011713570915162563,
            -0.004125434905290604,
            0.0014173471136018634,
            0.02563093788921833,
            -0.029977841302752495,
            -0.013211900368332863,
            0.00977085530757904,
            0.0074903834611177444,
            0.01373967807739973,
            -0.05326629430055618,
            -0.022931575775146484,
            -0.007249964866787195,
            0.02794080413877964,
            0.017000043764710426,
            -0.016632448881864548,
            0.02727353759109974,
            -0.013495015911757946,
            0.03001159429550171,
            0.014977500773966312,
            -0.029092805460095406,
            0.0022717341780662537,
            0.010886232368648052,
            0.009362480603158474,
            0.021774835884571075,
            -0.06389282643795013,
            0.05512281507253647,
            0.022908890619874,
            -0.05958675220608711,
            -0.039398159831762314,
            -0.06449920684099197,
            0.012986513786017895,
            -0.0055920728482306,
            -0.03998687118291855,
            0.03437843918800354,
            -0.03203718736767769,
            0.00586738483980298,
            0.037592414766550064,
            0.0431692898273468,
            0.014255951158702374,
            -0.02834027260541916,
            -0.03301393985748291,
            -0.007699150592088699,
            0.0013770750956609845,
            0.03376445919275284,
            0.012225818820297718,
            0.0016823438927531242,
            0.01577051728963852,
            0.03339562192559242,
            -0.03779565542936325,
            0.0136460205540061,
            0.08938896656036377,
            0.024862121790647507,
            -0.03064807504415512,
            -0.008061209693551064,
            0.002353271935135126,
            0.06021856144070625,
            -0.03510836139321327,
            -0.009206150658428669,
            -0.008201587945222855,
            0.012803073041141033,
            -0.01331477239727974,
            0.028306040912866592,
            -0.0025586269330233335,
            -0.019376974552869797,
            -0.002937243552878499,
            -0.053645867854356766,
            -0.02537461742758751,
            -0.02220776490867138,
            0.022043377161026,
            -0.030161282047629356,
            -0.04178432747721672,
            0.003425938542932272,
            -0.00350558920763433,
            -0.005845078267157078,
            0.017325399443507195,
            0.036025259643793106,
            -0.02282361499965191,
            0.02133592963218689,
            -0.01908705197274685,
            0.003747713053599,
            0.017820635810494423,
            -0.0004737763083539903,
            0.014929753728210926,
            -0.00013174442574381828,
            -0.0027621916960924864,
            0.015296386554837227,
            -0.042345836758613586,
            0.03787955641746521,
            -0.061661187559366226,
            -0.021425578743219376,
            -3.2210868084803224e-05,
            0.06318749487400055,
            -0.024981500580906868,
            -0.03443324193358421,
            0.004096608608961105,
            -0.0062746210023760796,
            -0.007710810285061598,
            -0.03341962769627571,
            0.018331237137317657,
            -0.004012871067970991,
            0.024714412167668343,
            0.026016585528850555,
            -0.021931873634457588,
            0.018507689237594604,
            0.034981582313776016,
            -0.03237012028694153,
            -0.001013769069686532,
            -0.0209336094558239,
            -0.03182346373796463,
            0.02743666246533394,
            -0.023388363420963287,
            0.029502304270863533,
            -0.011458572000265121,
            0.002936409553512931,
            -0.03380339965224266,
            0.02266857773065567,
            0.013124301098287106,
            0.012391445226967335,
            0.03147270157933235,
            -0.02093055285513401,
            0.015910768881440163,
            0.01282876543700695,
            -0.06810799241065979,
            0.03333667665719986,
            -0.015366775915026665,
            -0.04378243163228035,
            0.04285377636551857,
            0.01433319877833128,
            -0.004683610983192921,
            -0.06532776355743408,
            0.022366410121321678,
            0.01022057794034481,
            0.02677655778825283,
            -0.011728411540389061,
            0.01026158593595028,
            -0.0014020266244187951,
            0.04811612144112587,
            -0.01743393763899803,
            -0.003120912704616785,
            -0.004258656874299049,
            -0.020057762041687965,
            -0.008562585338950157,
            -0.01135975867509842,
            0.0018516042036935687,
            0.0139686968177557,
            -0.025570670142769814,
            -0.03527301177382469,
            0.015124292112886906,
            0.019430244341492653,
            -0.032566413283348083,
            -0.01884002611041069,
            0.0021683808881789446,
            0.014880364760756493,
            0.010114024393260479,
            -0.03634468466043472,
            -0.04489881172776222,
            0.011893539689481258,
            -0.011664279736578465,
            -0.016817858442664146,
            -0.004655000753700733,
            -0.013213682919740677,
            0.010223040357232094,
            0.08805722743272781,
            -0.02505812793970108,
            0.046126965433359146,
            0.027398528531193733,
            -0.02366636134684086,
            -0.008079675026237965,
            0.003651662031188607,
            -0.01845669001340866,
            -0.029040733352303505,
            0.05256640911102295,
            0.02875889092683792,
            0.008557413704693317,
            -0.031085921451449394,
            0.025007959455251694,
            -0.02266429178416729,
            0.02272569201886654,
            -0.001229525194503367,
            -0.03313085809350014,
            0.038304079324007034,
            -0.053097307682037354,
            -0.012524295598268509,
            -0.05506238713860512,
            0.017157725989818573,
            -0.013343816623091698,
            -0.026821758598089218,
            0.047695521265268326,
            -0.037365563213825226,
            -0.01891016773879528,
            -0.018518418073654175,
            0.005302363075315952,
            0.020481614395976067,
            0.02479644864797592,
            -0.011715146712958813,
            0.02924092672765255,
            -0.041350677609443665,
            0.024603579193353653,
            -0.035808078944683075,
            -0.013105877675116062,
            -0.01959986984729767,
            0.015390865504741669,
            -0.06119782105088234,
            -0.03508644551038742,
            -0.02753669023513794,
            0.03334450721740723,
            0.00864427350461483,
            -0.0071084583178162575,
            0.02475503832101822,
            -0.00078664964530617,
            -0.02186886966228485,
            0.006037644110620022,
            0.0012481549056246877,
            -0.0002958053373731673,
            -0.004506354220211506,
            -0.0406922809779644,
            0.05441318452358246,
            -0.00032444237149320543,
            0.00995390024036169,
            -0.013665415346622467,
            -0.028858313336968422,
            0.04479871317744255,
            0.01764855720102787,
            -0.031025467440485954,
            0.027227051556110382,
            -0.02370101399719715,
            0.028230197727680206,
            -0.020415669307112694,
            -0.02468804083764553,
            0.02432665228843689,
            -0.014559869654476643,
            -0.014015261083841324,
            0.013781401328742504,
            0.003032948123291135,
            -0.016301153227686882,
            0.03801697865128517,
            -0.012920812703669071,
            0.0018471638904884458,
            0.00024152254627551883,
            -0.003607857506722212,
            0.022687137126922607,
            0.005249419249594212,
            0.0007227770984172821,
            -0.061582278460264206,
            -0.016599785536527634,
            -0.02776123397052288,
            0.07047149538993835,
            -0.013338025659322739,
            0.06812336295843124,
            0.006607697810977697,
            0.0034485370852053165,
            0.007546405773609877,
            -0.02359430491924286,
            -0.04140545055270195,
            0.004228493664413691,
            0.032953765243291855,
            -0.027088480070233345,
            0.004695394542068243,
            -0.04319551959633827,
            -0.016890790313482285,
            -0.035604335367679596,
            0.013729501515626907,
            0.02664409577846527,
            0.004576244857162237,
            0.015054500661790371,
            0.023211343213915825,
            -0.01128208078444004,
            -0.05583855137228966,
            -0.021194377914071083,
            0.021962443366646767,
            -0.008659111335873604,
            0.002637268742546439,
            0.026227811351418495,
            0.009000720456242561,
            -0.0035715252161026,
            0.018736500293016434,
            0.028529737144708633,
            -0.006718260236084461,
            -0.06164969503879547,
            -0.010133981704711914,
            -0.021140262484550476,
            -0.01508880965411663,
            0.0017479504458606243,
            -0.021419944241642952,
            -0.019014671444892883,
            -0.010259611532092094,
            -0.024091290310025215,
            -0.021439237520098686,
            -0.002853809390217066,
            -0.02171151153743267,
            0.02922123484313488,
            0.006493105087429285,
            -0.06758765876293182,
            -0.019785230979323387,
            0.006231648847460747,
            -0.0016100596403703094,
            -0.0449482686817646,
            -0.009759362787008286,
            -0.032273612916469574,
            0.03265629708766937,
            0.0021687846165150404,
            0.003033395390957594,
            -0.02704731747508049,
            -0.02054581046104431,
            -0.010726159438490868,
            0.005724688526242971,
            -0.002060086466372013,
            0.022881997749209404,
            -0.013172896578907967,
            -0.046687718480825424,
            -0.008663646876811981,
            -0.0108744902536273,
            0.025248289108276367,
            -0.021931013092398643,
            -0.028369402512907982,
            -0.04976953938603401,
            -0.01664074882864952,
            -0.019453875720500946,
            -0.012524751015007496,
            -0.009332245215773582,
            0.005126351490616798,
            -0.01600169949233532,
            0.008843822404742241,
            -0.0738089457154274,
            -0.007743135094642639,
            -0.006389201618731022,
            0.004344829358160496,
            -0.02800491265952587,
            -0.029003841802477837,
            0.01798219606280327,
            -0.015137127600610256,
            -0.027840089052915573,
            0.05500325933098793,
            -0.03937481343746185,
            -0.004963936749845743,
            0.00020827441767323762,
            -0.01727871410548687,
            0.050175946205854416,
            0.015048777684569359,
            -0.018153144046664238,
            0.014046287164092064,
            -0.042860597372055054,
            0.01856343075633049,
            0.020645981654524803,
            -0.007542058359831572,
            0.024824077263474464,
            0.0019169924780726433,
            0.019357765093445778,
            -0.04482255131006241,
            -0.011525396257638931,
            0.0007568286382593215,
            -0.008268292993307114,
            -0.041937801986932755,
            0.029510363936424255,
            0.0082419253885746,
            -0.008127501234412193,
            -0.01318301260471344,
            -0.040307216346263885,
            -0.01715782657265663,
            0.038685861974954605,
            0.0043600318022072315,
            0.033098988234996796,
            -0.03547373414039612,
            -0.011351551860570908,
            -0.030742602422833443,
            -0.0389586016535759,
            0.014943625777959824,
            0.016544407233595848,
            0.025019479915499687,
            -0.00014232081593945622,
            0.028461545705795288,
            -0.029574088752269745,
            0.015513850376009941,
            0.06580623239278793,
            0.03472106531262398,
            0.004541951697319746,
            0.030935199931263924,
            0.022289058193564415,
            0.022933240979909897,
            -0.029997901991009712,
            0.019020697101950645,
            0.029913557693362236,
            0.039126016199588776,
            -0.010874895378947258,
            0.025291642174124718,
            0.02869626134634018,
            0.01853710412979126,
            -0.023267647251486778,
            0.00177434750366956,
            -0.013770311139523983,
            -0.0045465147122740746,
            0.023869285359978676,
            0.011880742385983467,
            -0.0073168580420315266,
            0.044085822999477386,
            -0.03599264472723007,
            -0.010380358435213566,
            -0.004544755443930626,
            -0.03652910143136978,
            -0.026744473725557327,
            -0.03923095017671585,
            0.021430835127830505,
            0.037563975900411606,
            0.0225225817412138,
            0.0326683484017849,
            0.04870596155524254,
            0.0002464940771460533,
            0.015057390555739403,
            0.025133533403277397,
            -0.0018897303380072117,
            -0.0011965015437453985,
            0.023515870794653893,
            -0.022672533988952637,
            -0.019902972504496574,
            -0.0004477003531064838,
            -0.05962396413087845,
            0.010898247361183167,
            -0.0086890310049057,
            0.007026942912489176,
            -0.022964652627706528,
            0.0031144069507718086,
            -0.045389141887426376,
            -0.011539990082383156,
            0.014499129727482796,
            -0.029203925281763077,
            -0.02381918765604496,
            -0.016100715845823288,
            -0.013125915080308914,
            -0.0329505056142807,
            -0.004460910800844431,
            -0.001292829285375774,
            0.0430898442864418,
            0.017883118242025375,
            0.034868236631155014,
            -0.024817446246743202,
            -0.012326467782258987,
            0.027600521221756935,
            -0.06090028956532478,
            -0.02553214319050312,
            -0.004832184407860041,
            0.003041617339476943,
            -0.02236873097717762,
            0.025894053280353546,
            0.010271872393786907,
            -0.007959339767694473,
            0.015300393104553223,
            0.021941669285297394,
            0.008293156512081623,
            0.011644294485449791,
            0.05759251490235329,
            -0.01900959201157093,
            0.013205205090343952,
            0.04736770689487457,
            -0.020014116540551186,
            0.020339185371994972,
            0.05098628252744675,
            0.01130658108741045,
            -0.01670180819928646,
            0.005381215829402208,
            -0.009968475438654423,
            -0.013068615458905697,
            0.02766522206366062,
            -0.06823297590017319,
            -0.012019139714539051,
            0.033568721264600754,
            -0.027814602479338646,
            0.0039344108663499355,
            0.004135159309953451,
            -0.01998959854245186,
            -0.038700222969055176,
            0.029928836971521378,
            0.010440580546855927,
            -0.015203672461211681,
            0.011039091274142265,
            -0.03249858319759369,
            0.0382201112806797,
            0.043882861733436584,
            -0.030503029003739357,
            0.04425179585814476,
            -0.024547109380364418,
            -0.014051221311092377,
            0.012981132604181767,
            -0.020616712048649788,
            -0.029267987236380577,
            0.008854305371642113,
            -0.03416435420513153,
            -0.01755087822675705,
            -0.03771720826625824,
            -0.019384779036045074,
            -0.019090820103883743,
            -0.010969561524689198,
            0.012713036499917507,
            -0.020617565140128136,
            0.0173772145062685,
            -0.012138732708990574,
            -0.011801915243268013,
            0.02164459601044655,
            0.024885904043912888,
            0.0310844536870718,
            -0.03680691868066788,
            -0.020477542653679848,
            -0.0025590932928025723,
            -0.0253390371799469,
            0.02334516867995262,
            0.0021888529881834984,
            -0.06813666969537735,
            0.067310631275177,
            -0.009682996198534966,
            -0.00431901216506958,
            -0.00860684085637331,
            -0.012305966578423977,
            0.01879008859395981,
            0.020777320489287376,
            0.01781371235847473,
            -0.007005015388131142,
            -0.01944991946220398,
            0.02574200928211212,
            0.03596702963113785,
            0.00021397379168774933,
            -0.033041320741176605,
            0.016072744503617287,
            -0.028135795146226883,
            0.02893201634287834,
            0.007608724758028984,
            0.024157488718628883,
            -0.03872178494930267,
            -0.031527575105428696,
            0.0008941293344832957,
            0.019688738510012627,
            -0.026178812608122826,
            0.02699432708323002,
            -0.012152417562901974,
            0.03563041239976883,
            0.02645963430404663,
            0.0007066829712130129,
            0.0009454626706428826,
            0.011096293106675148,
            0.014853962697088718,
            0.06285307556390762,
            -0.007706009317189455,
            0.019256189465522766,
            0.00832792092114687,
            -0.01894358918070793,
            0.03645097464323044,
            -0.04272925481200218,
            -0.03372160717844963,
            -0.010789124295115471,
            -0.03356141597032547,
            0.0058858683332800865,
            0.01409479696303606,
            0.028217243030667305,
            0.007688725832849741,
            -0.005739414598792791,
            -0.013557626865804195,
            -0.054595813155174255,
            0.008379116654396057,
            0.01723402552306652,
            0.008674326352775097,
            -0.054623544216156006,
            -0.047345150262117386,
            0.05241566523909569,
            -0.04170981049537659,
            0.0074631874449551105,
            0.006024365313351154,
            -0.04685709998011589,
            0.011717628687620163,
            0.003356544068083167,
            0.0149436816573143,
            -0.008141674101352692,
            0.0346776619553566,
            0.028280431404709816,
            -0.02427186444401741,
            0.03442387655377388,
            -0.021555602550506592,
            0.004428324289619923,
            -0.033747538924217224,
            0.028891660273075104,
            -0.008179040625691414,
            -0.011673657223582268,
            -0.000653866387438029,
            -0.06066024675965309,
            0.009921154007315636,
            0.03169732913374901,
            0.02870328351855278,
            -0.01603185571730137,
            -0.01465460006147623
        ],
        [
            -0.018890831619501114,
            -0.013263276778161526,
            0.005090396851301193,
            -0.0005808560526929796,
            -0.0058113448321819305,
            -0.027125993743538857,
            -0.019243525341153145,
            -0.02511628344655037,
            -0.013903371058404446,
            0.0032240368891507387,
            0.04466085508465767,
            -0.016015468165278435,
            -0.011994286440312862,
            -0.024962380528450012,
            -0.06191408634185791,
            0.04167875275015831,
            -0.008169959299266338,
            0.00900380127131939,
            0.023579392582178116,
            0.004587660543620586,
            -0.03595627099275589,
            -0.020333794876933098,
            0.02908269874751568,
            -0.04281841591000557,
            -0.025614099577069283,
            0.009404211305081844,
            0.0046994066797196865,
            0.018402768298983574,
            0.005989795550704002,
            -0.043759725987911224,
            -0.03175624459981918,
            -0.058111611753702164,
            0.02585604228079319,
            -0.015335631556808949,
            -0.026717351749539375,
            0.02580687589943409,
            0.02425605058670044,
            -0.015284927561879158,
            0.044963423162698746,
            -0.005846058018505573,
            -0.0038237653207033873,
            -0.02978971041738987,
            0.04628407210111618,
            -0.0012339310487732291,
            0.019656945019960403,
            -0.002164625795558095,
            -0.004308879375457764,
            -0.03140128776431084,
            -0.024017788469791412,
            0.009366907179355621,
            0.015338773839175701,
            -0.02474011667072773,
            -0.01266052108258009,
            -0.027483006939291954,
            0.005473362747579813,
            -0.026308532804250717,
            0.0023837743792682886,
            0.016097592189908028,
            -0.009511083364486694,
            0.0005448321462608874,
            -0.007145239040255547,
            0.0016290342900902033,
            0.022511586546897888,
            0.027345839887857437,
            -0.03348013386130333,
            -0.02132480964064598,
            0.009660305455327034,
            0.004629087168723345,
            -0.014909542165696621,
            -0.01083588507026434,
            -0.0015390468761324883,
            0.01751021482050419,
            0.00872178003191948,
            -0.005120419897139072,
            0.04912230744957924,
            -0.07208245247602463,
            0.041418202221393585,
            0.009310458786785603,
            -0.04721871763467789,
            0.036240387707948685,
            -0.00764055410400033,
            -0.019682342186570168,
            0.02111017145216465,
            0.03322482854127884,
            -0.03950455039739609,
            0.0032645512837916613,
            0.004301159176975489,
            -0.0027177617885172367,
            0.0071630231104791164,
            -0.014111228287220001,
            -0.006934428121894598,
            -0.009044007398188114,
            0.01752813719213009,
            -0.01454643439501524,
            0.02267301082611084,
            -0.002087545348331332,
            -0.03058241866528988,
            -0.0807853639125824,
            0.059728581458330154,
            0.009077895432710648,
            0.029690701514482498,
            -0.006344901397824287,
            -0.0024063207674771547,
            0.025825979188084602,
            0.003053209977224469,
            -0.02865437977015972,
            -0.040617939084768295,
            -0.03121204674243927,
            -0.018990065902471542,
            0.0023817038163542747,
            -0.0042386120185256,
            0.008493338711559772,
            0.02049289271235466,
            -0.003951988182961941,
            -0.03136611729860306,
            -0.03199690580368042,
            -0.0116345239803195,
            0.008849368430674076,
            0.012901871465146542,
            0.02926628664135933,
            -0.00842992216348648,
            -0.004649340640753508,
            0.05949629843235016,
            0.03893694281578064,
            -0.008105037733912468,
            0.013034293428063393,
            -0.02017512172460556,
            -0.00869547575712204,
            -3.599989213398658e-05,
            0.0019972496666014194,
            0.015077569521963596,
            0.04811401292681694,
            0.004989961627870798,
            -0.04463274031877518,
            0.0056334370747208595,
            0.016200682148337364,
            -0.01931929402053356,
            -0.06935077160596848,
            0.033694077283144,
            -0.008838221430778503,
            -0.011574548669159412,
            -0.015977026894688606,
            0.015959657728672028,
            -0.038111213594675064,
            -0.01285555399954319,
            0.03943098336458206,
            -0.03675737977027893,
            -0.053780119866132736,
            -0.012902473099529743,
            0.035087667405605316,
            0.027004854753613472,
            0.021271100267767906,
            -0.02063744328916073,
            -0.006051142234355211,
            0.008088869974017143,
            -0.025682134553790092,
            0.012102349661290646,
            -0.04029732942581177,
            0.02067001536488533,
            -0.023731546476483345,
            -0.053273413330316544,
            0.014516307972371578,
            0.03149963542819023,
            0.013717219233512878,
            -0.020411597564816475,
            0.007120083086192608,
            0.003538131481036544,
            0.03196802735328674,
            0.028421496972441673,
            -0.011820336803793907,
            0.006047227885574102,
            -0.011989576742053032,
            -0.004885029047727585,
            0.014620264992117882,
            0.015832994133234024,
            0.014862227253615856,
            -0.005272835958749056,
            -0.028565503656864166,
            -0.026504913344979286,
            0.025831079110503197,
            -0.04917985573410988,
            -0.00778899434953928,
            -0.00246234517544508,
            0.029461631551384926,
            -0.021479053422808647,
            -0.000517573207616806,
            -0.0010361580643802881,
            0.028639156371355057,
            0.030418874695897102,
            -0.016206610947847366,
            -0.014098328538239002,
            0.007295827381312847,
            -0.04842684045433998,
            0.030735820531845093,
            0.014081103727221489,
            -0.03846844658255577,
            -0.05185820534825325,
            0.015696853399276733,
            0.0014559811679646373,
            0.008678155019879341,
            0.01829301193356514,
            0.03548942133784294,
            -0.0073607368394732475,
            0.009359894320368767,
            -0.01622205227613449,
            -0.007126884069293737,
            0.009244373999536037,
            0.015964437276124954,
            0.0006903702742420137,
            -0.02373935654759407,
            0.008343622088432312,
            0.004148164298385382,
            -0.012840322218835354,
            -0.050695400685071945,
            6.169808330014348e-05,
            -0.018643248826265335,
            -0.003628853242844343,
            0.015265458263456821,
            -0.010185216553509235,
            -0.01802477054297924,
            0.037115901708602905,
            -0.0417647548019886,
            -0.02561531402170658,
            -0.011956661008298397,
            -0.009069586172699928,
            -0.02616811729967594,
            -0.03297979757189751,
            -0.025674933567643166,
            -0.005170443560928106,
            -0.006868836935609579,
            0.03862634301185608,
            0.01955813728272915,
            0.0021783746778964996,
            0.032812848687171936,
            0.002811554353684187,
            0.016712479293346405,
            -0.007815282791852951,
            -0.01503007486462593,
            0.014747700653970242,
            0.029084663838148117,
            0.021154427900910378,
            0.024345921352505684,
            0.03257665038108826,
            -0.015748504549264908,
            -0.02887885458767414,
            0.03325025364756584,
            -0.014823377132415771,
            -0.015901746228337288,
            -0.015261415392160416,
            -0.02385111153125763,
            -0.01624143309891224,
            0.008936545811593533,
            -0.01345976535230875,
            -0.050337206572294235,
            0.022122850641608238,
            0.029446594417095184,
            -0.03682028129696846,
            0.044199489057064056,
            -0.0598195418715477,
            -0.0014312987914308906,
            0.037773486226797104,
            -0.047346826642751694,
            0.01777399517595768,
            0.012887489050626755,
            0.03262763097882271,
            0.07880603522062302,
            0.014682073146104813,
            0.018360503017902374,
            0.013017140328884125,
            -0.02268245257437229,
            0.007012011017650366,
            0.0006227734265848994,
            -0.011332702822983265,
            -0.029934439808130264,
            0.0046220747753977776,
            0.019014179706573486,
            -0.004754460882395506,
            -0.0395594984292984,
            -0.003700193017721176,
            0.018529733642935753,
            0.01700003445148468,
            -0.034537579864263535,
            -0.028896408155560493,
            -0.020343130454421043,
            0.014878020621836185,
            -0.0032444926910102367,
            -0.0003275972849223763,
            -0.015039196237921715,
            -0.027607258409261703,
            0.026564044877886772,
            0.009086552076041698,
            0.015375865623354912,
            -0.02272125892341137,
            -0.0035735408309847116,
            0.00722410948947072,
            0.021965257823467255,
            0.030380399897694588,
            0.037183552980422974,
            -0.03326159343123436,
            -0.032680824398994446,
            -0.016774864867329597,
            0.02586516924202442,
            -0.019308920949697495,
            -0.013278712518513203,
            -0.008185493759810925,
            -0.04045296087861061,
            -0.025220006704330444,
            0.0012221138458698988,
            0.029809070751070976,
            0.02519696205854416,
            0.004383899737149477,
            -0.0324883833527565,
            -0.021820714697241783,
            -0.014527355320751667,
            -0.00032793747959658504,
            0.0465591698884964,
            -0.03365311771631241,
            0.016651576384902,
            -0.037907298654317856,
            0.0027166197542101145,
            0.03792204707860947,
            -0.019444435834884644,
            0.014742670580744743,
            -0.0054202028550207615,
            0.005244992673397064,
            0.013889581896364689,
            -0.00018468867347110063,
            0.006575212348252535,
            -0.013124462217092514,
            0.008461551740765572,
            -0.037709545344114304,
            0.029051220044493675,
            -0.030736949294805527,
            -0.03917543962597847,
            -0.0264482032507658,
            -0.023922685533761978,
            0.007118983194231987,
            0.02873428538441658,
            0.007151935249567032,
            0.011860757134854794,
            -0.04356519505381584,
            -0.02941858395934105,
            0.05495452508330345,
            -0.009233702905476093,
            0.02803138829767704,
            0.0062307920306921005,
            -0.018919004127383232,
            0.039396125823259354,
            -0.0031098572071641684,
            0.015518076717853546,
            -0.012773701921105385,
            0.005222463048994541,
            0.0058812364004552364,
            0.010827395133674145,
            0.01201601605862379,
            -0.04767392948269844,
            -0.007098767440766096,
            -0.001741160056553781,
            -0.029031474143266678,
            0.017361242324113846,
            0.017084652557969093,
            0.03646091744303703,
            0.0505443699657917,
            0.016847185790538788,
            -0.00041600526310503483,
            0.026627324521541595,
            -0.01884753629565239,
            0.059973180294036865,
            0.0014654151163995266,
            0.024688633158802986,
            0.005523180589079857,
            0.01343066617846489,
            -0.020467279478907585,
            0.0034630729351192713,
            -0.027174413204193115,
            -0.02722376398742199,
            0.04103555902838707,
            -0.010042804293334484,
            0.038595687597990036,
            -0.01065004337579012,
            -0.07056760042905807,
            -0.014360329136252403,
            -0.023877879604697227,
            0.029472094029188156,
            0.03697030991315842,
            0.04638901725411415,
            0.022926948964595795,
            -0.07590486109256744,
            0.011023863218724728,
            -0.03002476505935192,
            -0.021382909268140793,
            0.03671860694885254,
            -0.02100946009159088,
            0.013122959062457085,
            -0.01575014367699623,
            0.020296089351177216,
            0.010036072693765163,
            0.014798763208091259,
            -0.02321612648665905,
            0.002636559307575226,
            0.017710005864501,
            0.017358025535941124,
            -0.025879675522446632,
            -7.597212243126705e-05,
            -0.035281531512737274,
            -0.002889381954446435,
            0.02464035153388977,
            0.019759725779294968,
            0.020840853452682495,
            -0.014775888994336128,
            -0.025981368497014046,
            -0.001026196638122201,
            0.009928315877914429,
            -0.011724558658897877,
            -0.0271899476647377,
            0.02618521824479103,
            0.01307336613535881,
            -0.03968391939997673,
            -0.023120306432247162,
            -0.012821445241570473,
            0.027142642065882683,
            -0.02358156256377697,
            -0.023933127522468567,
            0.02957521565258503,
            0.03270481154322624,
            -0.003914936445653439,
            -0.058991938829422,
            -0.0017727888189256191,
            -0.0054840631783008575,
            -0.018821561709046364,
            0.0069804019294679165,
            0.00978267751634121,
            0.004139075055718422,
            -0.026836421340703964,
            3.0225970476749353e-05,
            -0.0038714460097253323,
            0.060615960508584976,
            0.012830146588385105,
            0.05720263347029686,
            0.02309795841574669,
            -0.006722085177898407,
            -0.005121852736920118,
            0.034486204385757446,
            -0.03398123383522034,
            0.018728582188487053,
            -0.01730170287191868,
            -0.02065674401819706,
            -0.03914997726678848,
            -0.0395374596118927,
            -0.00175717961974442,
            0.03103669174015522,
            -0.004359484650194645,
            -0.06904935091733932,
            -0.011736498214304447,
            -0.006104430183768272,
            0.0036241980269551277,
            -0.00751231936737895,
            0.02448456920683384,
            0.019382938742637634,
            -0.0011888631852343678,
            -0.0337686762213707,
            0.006630811840295792,
            0.008653811179101467,
            -0.021118968725204468,
            -0.04446371644735336,
            0.018269401043653488,
            0.004703058395534754,
            -0.021599089726805687,
            0.00775480130687356,
            -0.046035297214984894,
            -0.014200008474290371,
            -0.002761846175417304,
            -0.009587351232767105,
            -0.03816327825188637,
            -0.029986536130309105,
            0.02200811356306076,
            0.021528946235775948,
            0.00423020776361227,
            -0.017001645639538765,
            -0.03381287306547165,
            -0.05148063600063324,
            -0.001950397971086204,
            0.01951572299003601,
            -0.007831075228750706,
            0.04991718381643295,
            0.029902100563049316,
            -0.003141014603897929,
            0.05786912888288498,
            0.015214541926980019,
            -0.060259636491537094,
            0.01851474866271019,
            -0.013482540845870972,
            -0.02381308190524578,
            -0.03839851915836334,
            0.02118728682398796,
            0.02836088091135025,
            0.003488532267510891,
            -0.02462080307304859,
            0.014452886767685413,
            -0.01547334436327219,
            0.0010170318419113755,
            -0.0008243836928158998,
            -0.03563567250967026,
            -0.028846375644207,
            -0.002698745345696807,
            0.01345349196344614,
            0.016291391104459763,
            0.006791666150093079,
            0.012510121800005436,
            0.006050496827811003,
            0.025597315281629562,
            0.022099843248724937,
            -0.03082035854458809,
            -0.03169207647442818,
            -0.016212573274970055,
            0.00213050190359354,
            0.02515942044556141,
            0.057022903114557266,
            -0.021192794665694237,
            -0.05284307152032852,
            -0.014595563523471355,
            0.006570553872734308,
            -0.016224922612309456,
            -0.0719669908285141,
            -0.047527674585580826,
            -0.01835096813738346,
            -0.07342533767223358,
            -0.03342254459857941,
            -0.0064902957528829575,
            0.003410624573007226,
            -0.006727863568812609,
            -0.04999001324176788,
            0.011397224850952625,
            0.010859256610274315,
            -0.02040831744670868,
            -0.04535442590713501,
            0.024075277149677277,
            -0.05966366454958916,
            0.024158334359526634,
            0.02391749434173107,
            -0.024501487612724304,
            0.005282659083604813,
            -0.05618782714009285,
            0.020671911537647247,
            -0.01372572686523199,
            -0.06795471906661987,
            -0.01269243098795414,
            0.01800762489438057,
            0.01086950208991766,
            0.04962843284010887,
            0.023233037441968918,
            -0.014671561308205128,
            -0.023058803752064705,
            -0.0008985253516584635,
            0.03507879748940468,
            -0.00029116671066731215,
            -0.005596680101007223,
            0.030947571620345116,
            -0.01194795686751604,
            0.019198939204216003,
            0.018639151006937027,
            -0.008281099610030651,
            -0.034198127686977386,
            0.027018440887331963,
            0.017739033326506615,
            -0.018458476290106773,
            -0.0288096871227026,
            -0.0232782531529665,
            0.021254615858197212,
            -0.02957567386329174,
            0.014680584892630577,
            -0.00933916587382555,
            -0.03433253616094589,
            -0.014250753447413445,
            0.004906078800559044,
            -0.027404114603996277,
            0.003390677971765399,
            -0.004357786849141121,
            0.008710389956831932,
            -0.023348301649093628,
            -0.005812438204884529,
            -0.02529139257967472,
            0.029228489845991135,
            0.04165288060903549,
            -0.05134912207722664,
            -0.027570459991693497,
            0.03208784759044647,
            0.02100032940506935,
            0.025927040725946426,
            -0.007503217086195946,
            0.009984277188777924,
            -0.004215186927467585,
            -0.03595322370529175,
            -0.02538432739675045,
            -0.027745716273784637,
            -0.004549176897853613,
            -0.012691287323832512,
            0.023110756650567055,
            -0.023537978529930115,
            -0.015688715502619743,
            -0.06156175583600998,
            -0.02533261477947235,
            0.015536784194409847,
            -0.02200477011501789,
            -0.0077545358799397945,
            -0.037427566945552826,
            -0.010390213690698147,
            -0.018972568213939667,
            0.012230738997459412,
            0.0033786939457058907,
            -0.01538426335901022,
            5.0907769036712125e-05,
            -0.017354924231767654,
            -0.014682497829198837,
            0.03188743069767952,
            0.03555326536297798,
            -0.008722810074687004,
            -0.01938406564295292,
            -0.03324675187468529,
            0.051574043929576874,
            0.01906401850283146,
            0.01974865421652794,
            0.006298758089542389,
            0.008871390484273434,
            0.01975387893617153,
            0.012522730976343155,
            -0.021881723776459694,
            -0.030454281717538834,
            0.006583681795746088,
            0.02627003937959671,
            0.034093063324689865,
            -0.032800398766994476,
            0.014142515137791634,
            -0.0307083111256361,
            -0.06759527325630188,
            -0.08038782328367233,
            0.0164385624229908,
            -0.023048195987939835,
            -0.003935776650905609,
            -0.02817877195775509,
            -0.008688850328326225,
            0.029391221702098846,
            -0.056447140872478485,
            -0.0050458237528800964,
            -0.0029264972545206547,
            -0.013510970398783684,
            0.00631429348140955,
            0.004161560907959938,
            0.002200513146817684,
            -0.010595709085464478,
            -0.0012726463610306382,
            0.020892370492219925,
            -0.0022226094733923674,
            -0.03134129196405411,
            0.042363330721855164,
            -0.04698840156197548,
            -0.04297249764204025,
            0.017550764605402946,
            -0.022480661049485207,
            -0.013248881325125694,
            0.016397768631577492,
            -0.01800687611103058,
            0.018282165750861168,
            0.004095729906111956,
            -0.013385261408984661,
            -0.001074257306754589,
            0.0049689579755067825,
            -8.383890963159502e-05,
            -0.014636166393756866,
            -0.05592712014913559,
            0.021108435466885567,
            -0.07108508795499802,
            0.00026965272263623774,
            0.030383387580513954,
            -0.007169695105403662,
            0.024570615962147713,
            -0.02320082299411297,
            -0.0037979260087013245,
            0.016481371596455574,
            -0.0035448989365249872,
            0.060571830719709396,
            0.00028269359609112144,
            0.03769036382436752,
            -0.05179301276803017,
            0.0336931012570858,
            -0.0021688330452889204,
            0.02000821940600872,
            -0.011467981152236462,
            0.03693247213959694,
            -0.04500557482242584,
            0.007539925631135702,
            -0.04514651373028755,
            0.04542911425232887,
            -0.018015548586845398,
            0.002734857378527522,
            0.023587653413414955,
            0.01731906458735466,
            0.02140987291932106,
            0.03149127960205078,
            -0.015346075408160686,
            0.006139820907264948,
            -0.0065100728534162045,
            -0.06372182071208954,
            0.014988292939960957,
            -0.005883370526134968,
            -0.010392671450972557,
            -0.004171295557171106,
            0.00925937108695507,
            0.002349150599911809,
            0.031974323093891144,
            -0.0016984703252092004,
            -0.015420375391840935,
            0.008637514896690845,
            -0.016997840255498886,
            -0.01406532246619463,
            0.0044140866957604885,
            0.05147499591112137,
            -0.0003959802561439574,
            0.033139605075120926,
            0.01549847424030304,
            0.027187040075659752,
            0.018406499177217484,
            0.02558608166873455,
            -0.006769827101379633,
            -0.004426497500389814,
            0.005913806147873402,
            0.023280931636691093,
            -0.015553098171949387,
            0.021437590941786766,
            -0.024357223883271217,
            0.012797795236110687,
            -0.002312534023076296,
            0.03517453745007515,
            -0.023633668199181557,
            0.01886843889951706,
            0.022427266463637352,
            0.023044215515255928,
            -0.03535696491599083,
            0.05101308226585388,
            -0.0075918701477348804,
            -0.059743572026491165,
            -0.002086247317492962,
            0.032631389796733856,
            -0.014231137931346893,
            -0.005544379819184542,
            -0.016835330054163933,
            0.00570670235902071,
            0.027954699471592903,
            -0.022272344678640366,
            0.027920369058847427,
            -0.023605700582265854,
            0.022043775767087936,
            -0.016024461016058922,
            -0.044715408235788345,
            -0.006120763253420591,
            -0.047267671674489975,
            -0.03536493331193924,
            -0.08530471473932266,
            0.013684829697012901,
            -0.050736237317323685,
            0.0007245694287121296,
            0.017674291506409645,
            0.01041304413229227,
            0.0013313423842191696,
            0.023629888892173767,
            -0.08814898878335953,
            -0.018109584227204323,
            0.024047760292887688,
            0.020418662577867508,
            -0.032479822635650635,
            0.017830848693847656,
            -0.04883234202861786,
            -0.003974549937993288,
            -0.05107501149177551,
            0.012105953879654408,
            -0.02277914248406887,
            0.05471235141158104,
            -0.007688959129154682,
            -0.00309554161503911,
            -0.006008222233504057,
            -0.012070703320205212,
            -0.027508659288287163,
            0.01663888990879059,
            -0.014301503077149391,
            -0.0026688817888498306,
            -0.004113321658223867,
            0.023613717406988144,
            0.03314053639769554,
            0.012377878651022911,
            -0.01777459867298603,
            -0.016169553622603416,
            -0.041685666888952255,
            -0.00042333410237915814,
            0.010315615683794022,
            0.03218105435371399,
            0.02220235951244831,
            -0.07198695838451385,
            -0.03312774747610092,
            -0.02964707277715206,
            -0.025136291980743408,
            -0.022790543735027313,
            0.015771616250276566,
            0.006069279741495848,
            -0.08253205567598343,
            0.014169440604746342,
            -0.051949393004179,
            0.02996993251144886,
            0.003912239335477352,
            -0.035819023847579956,
            -0.029888613149523735,
            -0.011601358652114868,
            0.0027706592809408903,
            -0.0382159948348999,
            -0.010607650503516197,
            -0.057121630758047104,
            0.0023755088914185762,
            -0.03119783103466034,
            0.018732884898781776,
            -0.003772343508899212,
            0.0055291978642344475,
            0.004072669893503189,
            -0.04059246927499771,
            0.0023978082463145256,
            0.0208426546305418,
            0.029338901862502098,
            -0.0016710906056687236,
            0.021874312311410904,
            -0.011696286499500275,
            -0.0039796833880245686,
            0.004996118601411581,
            -0.018992796540260315,
            0.019749188795685768,
            -0.026649704203009605,
            -0.040828149765729904,
            -0.017817245796322823,
            0.002294146455824375,
            -0.028276415541768074,
            0.0028347817715257406,
            0.01858620159327984,
            -0.02140187844634056,
            -0.015279264189302921,
            -0.0006772343767806888,
            -0.05380894988775253,
            0.027317043393850327,
            0.007253152783960104,
            -0.04552413523197174,
            0.02482626587152481,
            0.02779962308704853,
            -0.003095280844718218,
            -0.004931315779685974,
            -0.026854030787944794,
            -0.018089551478624344,
            0.023889994248747826,
            -0.028577672317624092,
            0.013373189605772495,
            -0.008022885769605637,
            -0.03889723867177963,
            -0.0023910189047455788,
            -0.073767751455307,
            -0.014645733870565891,
            0.01461048237979412,
            0.012826483696699142,
            0.019966190680861473,
            -0.028462136164307594,
            0.04717082902789116,
            0.027532001957297325,
            -0.008994733914732933,
            -0.017170436680316925,
            0.02913632243871689,
            0.03559078648686409,
            0.06122250109910965,
            -0.0014384174719452858,
            0.0378807932138443,
            0.03168528899550438,
            -0.006500346586108208,
            -0.024103472009301186,
            -0.02331068553030491,
            -0.05312798544764519,
            -0.009334206581115723,
            -0.02768622525036335,
            -0.021139858290553093,
            -0.05102556571364403,
            -0.030149957165122032,
            -0.029729239642620087,
            -0.008896074257791042,
            -0.0008774512680247426,
            0.03719516098499298,
            0.07497851550579071,
            0.0012564598582684994,
            0.012828546576201916,
            0.005376643966883421,
            0.003925593104213476,
            -0.016602836549282074,
            0.001450624200515449,
            -0.007225825916975737,
            0.025045782327651978,
            0.009044955484569073,
            0.013183132745325565,
            0.017571428790688515,
            -0.01698308438062668,
            -0.011730724014341831,
            -0.025803882628679276,
            -0.015569881536066532,
            -0.009283160790801048,
            -0.0031293577048927546,
            -0.011201826855540276,
            -0.06845911592245102,
            -0.0164483655244112,
            -0.020154600962996483,
            -0.029040371999144554,
            -0.017063910141587257,
            0.01222427561879158,
            0.003938596695661545,
            -0.026300571858882904,
            -0.050894878804683685,
            -0.03055286593735218,
            -0.051986854523420334,
            0.00421288562938571,
            -0.005012360401451588,
            -0.00400819955393672,
            0.03174663707613945,
            0.036711499094963074,
            -0.03353133425116539,
            0.0038515960332006216,
            0.003994079772382975,
            -0.04343098774552345,
            0.016227565705776215,
            -0.0017054133350029588,
            0.015856098383665085,
            0.020846478641033173,
            -0.012658651918172836,
            -0.011146214790642262,
            -0.05779438093304634,
            0.002070745686069131,
            -0.06146950647234917,
            0.02719229646027088,
            -0.019041243940591812,
            0.023973485454916954,
            -0.008014910854399204,
            -0.019313964992761612,
            0.021804235875606537,
            -0.012219564989209175,
            -0.007163673173636198,
            -0.013790955767035484,
            -0.003102170070633292,
            -0.005956646986305714,
            0.012605871073901653,
            0.004169555846601725,
            -0.057260625064373016,
            0.04317856952548027,
            -0.07920175045728683,
            -0.005993540398776531,
            -0.004824587609618902,
            0.015280730091035366,
            -0.029648378491401672,
            -0.020837493240833282,
            -0.02322928048670292,
            -0.021755605936050415,
            0.020106306299567223,
            -0.026784641668200493,
            0.015209180302917957,
            -0.0004684761806856841,
            -0.012418943457305431,
            -0.05244831740856171,
            -0.019279304891824722,
            0.007090986706316471,
            -0.02412964589893818,
            0.02677515335381031,
            -0.038150109350681305,
            -0.015339854173362255,
            -0.007547382730990648,
            -0.005496695637702942,
            -0.03445303067564964,
            -0.029347967356443405,
            0.008488151244819164,
            -0.013695668429136276,
            -0.05831877142190933,
            0.021919939666986465,
            -0.02177828550338745,
            0.010162499733269215,
            -0.042402490973472595,
            -0.025651421397924423,
            -0.03745025396347046,
            -0.0029228816274553537,
            -0.042723398655653,
            0.011194621212780476,
            -0.004257448948919773,
            -0.018743788823485374,
            0.012726138345897198,
            -0.03546267747879028,
            -0.05729139968752861,
            -0.03720025718212128,
            -0.04974231868982315,
            0.000785904354415834,
            0.015521234832704067,
            0.05190201848745346,
            -0.03411472961306572,
            0.019938502460718155,
            0.04433367773890495,
            -0.007293424569070339,
            -0.017750784754753113,
            -0.00990644097328186,
            -0.006791459396481514,
            -0.015924980863928795,
            0.004298382438719273,
            -0.020600665360689163,
            0.006697096861898899,
            -0.05751274153590202,
            0.0003881605516653508,
            -0.0038272342644631863,
            0.016979876905679703,
            0.02884817123413086,
            0.014541808515787125,
            0.011969761922955513,
            -0.0383530855178833,
            -0.02195289172232151,
            -0.03720369562506676,
            -0.01126748789101839,
            0.014108036644756794,
            -0.011059785261750221,
            -0.029699964448809624,
            0.0019454980501905084,
            0.07843183726072311,
            -0.008505256846547127
        ],
        [
            0.014744321815669537,
            0.0014283576747402549,
            -0.02700093574821949,
            -0.0034490504767745733,
            -0.031149473041296005,
            -0.004156793933361769,
            -0.017069729045033455,
            -0.01786101795732975,
            -0.011796792969107628,
            -0.017884355038404465,
            -0.0258588045835495,
            0.006950401701033115,
            -0.02975270338356495,
            0.0036403730046004057,
            0.005453942343592644,
            -0.028708256781101227,
            0.01744360849261284,
            0.006412935443222523,
            0.0008883673581294715,
            0.016368478536605835,
            -0.031917110085487366,
            -0.010638897307217121,
            -0.0011826513800770044,
            0.01225277315825224,
            -0.024517185986042023,
            -0.029270105063915253,
            0.016383573412895203,
            -0.0224002692848444,
            -0.009351896122097969,
            0.0038141945842653513,
            0.009505661204457283,
            0.005640808958560228,
            0.011165297590196133,
            -0.021369369700551033,
            -0.025140784680843353,
            -0.022836552932858467,
            0.02669483982026577,
            -0.030929025262594223,
            -0.03148224204778671,
            -0.02667323499917984,
            0.019551662728190422,
            -0.023238833993673325,
            -0.006639709696173668,
            -0.017388001084327698,
            0.014582552015781403,
            0.005096938461065292,
            0.009504335932433605,
            0.021132176741957664,
            0.014110660180449486,
            0.026021640747785568,
            -0.031541608273983,
            0.0025521572679281235,
            -0.028915533795952797,
            0.016585953533649445,
            0.009077129885554314,
            0.011456424370408058,
            0.014627278782427311,
            -0.00014444635598920286,
            -0.019269553944468498,
            0.020324477925896645,
            -0.014554881490767002,
            -0.032198015600442886,
            0.013750354759395123,
            -0.027756288647651672,
            -0.030938928946852684,
            0.02499283105134964,
            0.019518719986081123,
            0.0036459718830883503,
            0.017980186268687248,
            0.01434901263564825,
            0.010013588704168797,
            0.008203237317502499,
            -0.027494560927152634,
            -0.03122042864561081,
            0.003100462257862091,
            -0.0076482901349663734,
            -0.021496085450053215,
            -0.006175894755870104,
            0.01838739402592182,
            0.021275201812386513,
            -0.02218717150390148,
            0.010831454768776894,
            0.02813715673983097,
            0.008762575685977936,
            0.021402662619948387,
            -0.012995670549571514,
            0.012617199681699276,
            0.015249472111463547,
            0.010480094701051712,
            -0.01178937591612339,
            -0.0022311387583613396,
            -0.03092202916741371,
            0.01119354460388422,
            -0.0006160407792776823,
            0.004350610077381134,
            -0.008575927466154099,
            0.026212383061647415,
            -0.027286885306239128,
            -0.03004273772239685,
            -0.029494671151041985,
            -0.00019907235400751233,
            -0.027940766885876656,
            0.016833245754241943,
            -0.026116149500012398,
            -0.021374795585870743,
            -0.02563490718603134,
            -0.02118842862546444,
            0.0020008659921586514,
            -0.022059520706534386,
            -0.00477391155436635,
            -0.031993936747312546,
            0.011718661524355412,
            0.020221536979079247,
            0.0030617101583629847,
            -0.027680780738592148,
            0.00204521999694407,
            0.019853321835398674,
            0.0078089917078614235,
            0.0011331095593050122,
            -0.015846723690629005,
            -0.00897663738578558,
            -0.002483982592821121,
            -0.029963472858071327,
            0.0030332854948937893,
            -0.020002039149403572,
            -0.028749434277415276,
            0.013942022807896137,
            -0.027895549312233925,
            0.0152869438752532,
            0.01454260665923357,
            0.001136599574238062,
            -0.029394013807177544,
            -0.005939683876931667,
            0.02211098186671734,
            0.027009008452296257,
            -0.015047146938741207,
            -0.006128490436822176,
            -0.016424957662820816,
            0.02819911204278469,
            -0.029179297387599945,
            0.0019077297765761614,
            -0.016768803820014,
            0.027409657835960388,
            0.004751141648739576,
            0.001426701550371945,
            0.006062790285795927,
            0.0060974289663136005,
            0.005790371913462877,
            -0.027168145403265953,
            -0.01920395717024803,
            -0.017514092847704887,
            0.030077002942562103,
            -0.0048054857179522514,
            0.02651088498532772,
            0.016975650563836098,
            -0.003723664442077279,
            -0.017693975940346718,
            0.02998199127614498,
            -0.026630856096744537,
            0.016829658299684525,
            0.016644470393657684,
            0.004814831539988518,
            0.0005101627903059125,
            -0.02144869975745678,
            0.010706819593906403,
            0.014344032853841782,
            0.029640264809131622,
            -0.024607399478554726,
            -0.02088806964457035,
            0.029655475169420242,
            -0.0006419518031179905,
            0.028478048741817474,
            -0.005515106953680515,
            0.020441947504878044,
            -0.0015181907219812274,
            0.014149641618132591,
            -0.01725112833082676,
            -0.011635053902864456,
            0.010274556465446949,
            -0.02736809477210045,
            -0.022877277806401253,
            0.016314320266246796,
            -0.018439538776874542,
            -0.02963927760720253,
            -0.006783113814890385,
            -0.010778161697089672,
            0.02420095168054104,
            0.02944384701550007,
            0.01704465225338936,
            -0.008496303111314774,
            -0.0023491401225328445,
            -0.022402213886380196,
            -0.013927338644862175,
            -0.0017235440900549293,
            -0.02051868662238121,
            -0.0018199703190475702,
            0.030785787850618362,
            0.021927569061517715,
            -0.008585385046899319,
            -0.006785502191632986,
            -0.03141053393483162,
            0.004742221906781197,
            0.0007180367829278111,
            0.01986847072839737,
            -0.020315630361437798,
            -0.02613215334713459,
            -0.02002076618373394,
            0.01635904423892498,
            -0.024162447080016136,
            -0.00976910162717104,
            -0.004090939648449421,
            -0.00683198357000947,
            -0.014242391102015972,
            -0.018315469846129417,
            0.023403745144605637,
            -0.019597114995121956,
            0.015297853387892246,
            -0.007085136137902737,
            -0.009432955645024776,
            -0.013588089495897293,
            0.0036926669999957085,
            -0.015452641062438488,
            -0.015831509605050087,
            -0.027943186461925507,
            -0.016332771629095078,
            0.01490130927413702,
            0.01546177826821804,
            0.005214842967689037,
            0.007537654601037502,
            0.004822493065148592,
            0.01821458339691162,
            -0.011574874632060528,
            0.002090857829898596,
            0.0003915720444638282,
            0.014559448696672916,
            -0.028590861707925797,
            -0.011670609936118126,
            0.013552004471421242,
            0.004529820755124092,
            0.0012850750936195254,
            0.023053985089063644,
            0.0035609998740255833,
            -0.007044584956020117,
            0.00811956450343132,
            -0.001776405842974782,
            -0.02617262862622738,
            0.01580377295613289,
            0.006309149321168661,
            0.002870643977075815,
            0.025688843801617622,
            0.0005162166780792177,
            -0.013336801901459694,
            -0.020492825657129288,
            -0.021964624524116516,
            -0.013921097852289677,
            0.026399221271276474,
            -0.013863022439181805,
            0.007475878112018108,
            -0.010129457339644432,
            -0.029295140877366066,
            0.01157775241881609,
            0.004017833154648542,
            0.028874613344669342,
            -0.006825834512710571,
            -0.021245790645480156,
            0.0015610599657520652,
            0.009220273233950138,
            0.007185827940702438,
            0.025947870686650276,
            -0.004669428337365389,
            -0.00940968468785286,
            -0.015411026775836945,
            -0.030676359310746193,
            -0.021214907988905907,
            0.014742541126906872,
            -0.0013869196409359574,
            0.005481652449816465,
            -0.027034787461161613,
            0.004056904464960098,
            0.002814310370013118,
            0.023971054702997208,
            -0.03162159398198128,
            -0.02690662443637848,
            -0.019175106659531593,
            0.01351525541394949,
            0.009616859257221222,
            0.011029732413589954,
            -0.021838031709194183,
            0.02149067632853985,
            0.025897163897752762,
            -0.01192470733076334,
            0.02805892564356327,
            -0.0274354275316,
            0.0033062284346669912,
            0.012520876713097095,
            0.024580538272857666,
            -0.013364344835281372,
            -0.002911581192165613,
            -0.010378524661064148,
            -0.022124625742435455,
            -0.013702641241252422,
            -0.02353852242231369,
            -0.018286719918251038,
            0.0202611293643713,
            0.01504063606262207,
            0.012007351033389568,
            0.007220451720058918,
            -0.02433135360479355,
            0.015730584040284157,
            -0.00808238610625267,
            0.019153347238898277,
            -0.003604260738939047,
            -0.017022140324115753,
            -0.023646624758839607,
            0.01350017823278904,
            -0.021359480917453766,
            0.026588954031467438,
            0.005954865366220474,
            0.02360316552221775,
            0.010116416029632092,
            0.006258671171963215,
            -0.00790185946971178,
            -0.014892641454935074,
            0.0164105836302042,
            -0.0003489858645480126,
            0.024864982813596725,
            0.02363358996808529,
            0.008254624903202057,
            0.011956342495977879,
            -0.017483340576291084,
            -0.031097717583179474,
            -0.029773199930787086,
            0.01980222575366497,
            -0.01291715819388628,
            0.008112688548862934,
            -0.020490041002631187,
            -0.02682078257203102,
            0.004549100529402494,
            -0.026104344055056572,
            0.009741346351802349,
            0.0215699914842844,
            -0.011146383360028267,
            -0.011508211493492126,
            -0.01921113207936287,
            0.011415963992476463,
            -0.02646598592400551,
            0.007661531679332256,
            0.011796421371400356,
            0.022778546437621117,
            0.01223753672093153,
            0.018862314522266388,
            0.0020711589604616165,
            0.01145019382238388,
            0.027773845940828323,
            -0.004279856104403734,
            -0.0011776821920648217,
            0.004099990241229534,
            -0.020069275051355362,
            -0.013797814026474953,
            0.012257910333573818,
            -0.020401306450366974,
            -0.013728327117860317,
            0.02775290049612522,
            0.0164239089936018,
            -0.02212621457874775,
            -0.007378695532679558,
            0.025147797539830208,
            0.02523915097117424,
            -0.001457591075450182,
            -0.018585553392767906,
            -0.029322214424610138,
            -0.03161712363362312,
            -0.007470299489796162,
            0.01812923140823841,
            -0.010069746524095535,
            -0.007338900584727526,
            0.02341962791979313,
            0.004536563064903021,
            -0.002968902699649334,
            0.026480764150619507,
            -0.01737426407635212,
            0.007467128802090883,
            -0.026304977014660835,
            -0.030968034639954567,
            -0.015327036380767822,
            -0.02209811843931675,
            0.017978712916374207,
            -0.006172127556055784,
            -0.008446276187896729,
            -0.0021988218650221825,
            -0.023189052939414978,
            -0.015908939763903618,
            0.007392688654363155,
            -0.025559313595294952,
            0.00011726797674782574,
            -0.0037739824037998915,
            0.02672838792204857,
            -0.001155012403614819,
            -0.008443249389529228,
            0.02411269210278988,
            -0.004385621286928654,
            -0.0038874568417668343,
            0.004625372588634491,
            -0.020687544718384743,
            0.009850500151515007,
            -0.0024918238632380962,
            0.013720497488975525,
            0.012790980748832226,
            -0.020205682143568993,
            -0.026481566950678825,
            0.026119982823729515,
            0.0004680831334553659,
            -0.006903074681758881,
            0.010231704451143742,
            0.017957394942641258,
            -0.006906532682478428,
            0.013599112629890442,
            0.0215271208435297,
            -0.02120298705995083,
            0.0067782290279865265,
            0.0037068587262183428,
            -0.0004142499528825283,
            -0.0017128644976764917,
            -0.02193811908364296,
            -0.005047483369708061,
            -0.017971741035580635,
            0.0038106113206595182,
            -0.014005159959197044,
            0.02050609327852726,
            -0.0015455723041668534,
            -0.00823740940541029,
            0.019066374748945236,
            -0.008474340662360191,
            -0.014482212252914906,
            0.004363361746072769,
            0.0013058746699243784,
            -0.008727304637432098,
            0.0263746939599514,
            -0.01578698679804802,
            -0.015642521902918816,
            -0.0032075962517410517,
            0.027131376788020134,
            0.023374250158667564,
            0.006290884222835302,
            -0.011959163472056389,
            0.025508269667625427,
            -0.02662152796983719,
            -0.01746593415737152,
            0.02753523737192154,
            -0.008199216797947884,
            -0.006720776669681072,
            0.01669139973819256,
            0.01843104511499405,
            0.028904039412736893,
            -0.018050922080874443,
            0.029509223997592926,
            -0.022158684208989143,
            -0.018159372732043266,
            -0.021337173879146576,
            -0.01643110252916813,
            0.02959822118282318,
            -0.01196455117315054,
            -0.012288370169699192,
            0.011664804071187973,
            0.0036550674121826887,
            -0.027095820754766464,
            0.017982453107833862,
            -0.01873086765408516,
            0.019698651507496834,
            0.004069532733410597,
            -0.0240461565554142,
            -0.02316923812031746,
            0.020247744396328926,
            0.0021403594873845577,
            -0.0256387647241354,
            -0.007336704060435295,
            -0.0219294223934412,
            0.02929747849702835,
            0.003346978919580579,
            0.006024002563208342,
            -0.016609087586402893,
            -0.0009507177164778113,
            0.0290057510137558,
            0.02360665239393711,
            0.013006940484046936,
            0.016355367377400398,
            -0.017584023997187614,
            0.016544247046113014,
            -0.0022807284258306026,
            -0.03112749755382538,
            -0.018952473998069763,
            0.01001123245805502,
            0.01448764093220234,
            -0.010377784259617329,
            -0.02694135531783104,
            -0.010422391816973686,
            0.012556682340800762,
            0.013128028251230717,
            -0.006507339421659708,
            -0.0010150419548153877,
            0.0165982935577631,
            0.009372660890221596,
            -0.019084269180893898,
            -0.011416228488087654,
            -0.024443775415420532,
            -0.027284227311611176,
            0.02679484523832798,
            -0.010717930272221565,
            -0.024065732955932617,
            0.01530508603900671,
            0.023557281121611595,
            -0.021548978984355927,
            -0.009741381742060184,
            0.028559040278196335,
            -0.02921104058623314,
            0.015668485313653946,
            0.006250142119824886,
            9.369568579131737e-05,
            0.003967244643718004,
            0.012110746465623379,
            -0.017338255420327187,
            0.015611573122441769,
            0.01899641565978527,
            0.014530565589666367,
            0.009843789041042328,
            0.02326018549501896,
            0.030178623273968697,
            -0.02659510262310505,
            0.023169687017798424,
            0.014855015091598034,
            0.02723010629415512,
            -0.02484624832868576,
            0.01936795748770237,
            0.018454184755682945,
            -0.02813575603067875,
            0.016037998721003532,
            0.020125869661569595,
            0.025107258930802345,
            0.005343105178326368,
            -0.0019123972160741687,
            0.006929947528988123,
            -0.0197659432888031,
            0.006333170458674431,
            0.008695545606315136,
            0.02047991007566452,
            0.007351620588451624,
            0.027870681136846542,
            0.019067149609327316,
            0.018930282443761826,
            0.011536901816725731,
            -0.00388219952583313,
            -0.012587154284119606,
            0.020518146455287933,
            -0.004701633937656879,
            -0.03229355067014694,
            0.007777126971632242,
            -0.0033882255665957928,
            -0.0019491735147312284,
            0.02656843326985836,
            0.0027087305206805468,
            -0.014918998815119267,
            -0.020025664940476418,
            0.010384929366409779,
            -0.012021335773169994,
            -0.02118765003979206,
            0.0052097877487540245,
            0.008054563775658607,
            -0.011452834121882915,
            0.005748451687395573,
            -0.029193200170993805,
            -0.010688995011150837,
            -0.02899559959769249,
            -0.019979391247034073,
            -0.030013417825102806,
            -0.009628166444599628,
            0.014464147388935089,
            -0.031486183404922485,
            0.02073551155626774,
            -0.008010736666619778,
            0.02491873688995838,
            -0.024338550865650177,
            -0.014447785913944244,
            0.029608720913529396,
            -0.019091468304395676,
            -5.1516442908905447e-05,
            -0.0060853841714560986,
            -0.015654783695936203,
            -0.006055555306375027,
            -0.021156534552574158,
            -0.03212810307741165,
            0.020946044474840164,
            6.14082018728368e-05,
            0.001581898657605052,
            -0.004359076730906963,
            -0.003329746425151825,
            0.019366860389709473,
            0.024054767563939095,
            -0.0031861376482993364,
            -0.00369607238098979,
            0.023278316482901573,
            0.021223293617367744,
            -0.021270114928483963,
            -0.02953018620610237,
            -0.015199776738882065,
            0.029020361602306366,
            0.00441299332305789,
            -0.027746081352233887,
            -0.02515926957130432,
            0.018886692821979523,
            0.0048513556830585,
            0.025869304314255714,
            -0.0019688704051077366,
            -0.0290844663977623,
            -0.022668596357107162,
            0.002990192733705044,
            -0.010059285908937454,
            -0.006685223430395126,
            -0.02019265480339527,
            0.008557824417948723,
            -0.012616464868187904,
            0.019989294931292534,
            -0.010965021327137947,
            0.020676735788583755,
            0.025970052927732468,
            0.01982557401061058,
            0.011143457144498825,
            -0.021009275689721107,
            0.0009176256717182696,
            -0.02568003349006176,
            0.010243204422295094,
            0.013410299085080624,
            -0.013633934780955315,
            6.091557224863209e-06,
            0.008510048501193523,
            -0.012432861141860485,
            -0.022085076197981834,
            -0.01846478134393692,
            -0.025511302053928375,
            0.01936645619571209,
            -0.02963898703455925,
            0.029870960861444473,
            -0.025594182312488556,
            -0.020191246643662453,
            -0.01754114218056202,
            -0.03043632209300995,
            -0.02351161651313305,
            0.021334555000066757,
            -0.015950173139572144,
            0.030026372522115707,
            -0.01133660227060318,
            -0.024255892261862755,
            0.0037883140612393618,
            -0.013545596040785313,
            -0.006694560870528221,
            -0.014587823301553726,
            0.019324421882629395,
            -0.006812418811023235,
            -0.01746552251279354,
            -0.0063817487098276615,
            0.004169182386249304,
            0.027060508728027344,
            -0.01705084554851055,
            0.024608172476291656,
            -0.007177992723882198,
            -1.3141987437848002e-05,
            0.019983336329460144,
            0.017771054059267044,
            -0.0024008278269320726,
            0.02100539579987526,
            0.010837435722351074,
            0.005731629673391581,
            -0.0156489796936512,
            0.030077287927269936,
            0.008816160261631012,
            -0.03027239441871643,
            -0.018050044775009155,
            0.015174256637692451,
            -3.0670082196593285e-05,
            -0.01895260065793991,
            -0.00035555619979277253,
            0.007785518188029528,
            0.003524328349158168,
            0.0012410306371748447,
            0.014528453350067139,
            -0.010084833018481731,
            -0.011904717423021793,
            0.012518353760242462,
            -0.022676100954413414,
            0.009234427474439144,
            -0.03105136938393116,
            0.018438775092363358,
            0.021293576806783676,
            -0.027231920510530472,
            -0.011073077097535133,
            -0.02313905395567417,
            0.02919752337038517,
            0.013795497827231884,
            0.01751750148832798,
            -0.030071068555116653,
            0.008014528080821037,
            -0.019357506185770035,
            0.0010061954380944371,
            -0.004588396288454533,
            -0.020443439483642578,
            0.003929957281798124,
            0.006893163081258535,
            0.004910778719931841,
            -0.005418827757239342,
            -0.01713033951818943,
            0.028265424072742462,
            0.02300996519625187,
            -0.0015946720959618688,
            0.007720510475337505,
            -0.028449807316064835,
            -0.025822043418884277,
            -0.02582540735602379,
            0.008917463012039661,
            0.02081676758825779,
            0.013912176713347435,
            0.014306685887277126,
            -0.013620336540043354,
            0.02414104714989662,
            -0.00042242417111992836,
            -0.011670701205730438,
            0.004123035818338394,
            0.021803399547934532,
            -0.019760893657803535,
            -0.010932411067187786,
            0.01794334687292576,
            -0.011550691910088062,
            -0.0024905477184802294,
            0.020048929378390312,
            -0.020484307780861855,
            -0.02146739885210991,
            0.028914008289575577,
            0.022242067381739616,
            0.004982629790902138,
            0.008932525292038918,
            -0.0003370707854628563,
            0.00912055466324091,
            -0.02775876224040985,
            -0.017237136140465736,
            0.005214868113398552,
            0.0016891455743461847,
            -0.019527357071638107,
            -0.012737727724015713,
            -0.010242096148431301,
            -0.003156404010951519,
            -0.031730279326438904,
            -0.016352539882063866,
            -0.002351643517613411,
            0.020715929567813873,
            -0.02363589033484459,
            -0.0262366384267807,
            -0.030202491208910942,
            0.014821872115135193,
            -0.02319706231355667,
            -0.009673170745372772,
            -0.016364717856049538,
            -0.0125461770221591,
            0.0006825215532444417,
            0.02165934257209301,
            0.023473083972930908,
            -0.009971748106181622,
            0.0005778857739642262,
            -0.024405382573604584,
            0.016096848994493484,
            -0.016945121809840202,
            0.025956034660339355,
            0.014075118117034435,
            0.020863797515630722,
            -0.0017730514518916607,
            -0.024842040613293648,
            -0.01449371874332428,
            0.0016106093535199761,
            0.03210115805268288,
            -0.009721602313220501,
            0.017579345032572746,
            -0.0300462506711483,
            -0.025568341836333275,
            -0.0180633794516325,
            -0.011647810228168964,
            0.020081957802176476,
            -0.018000677227973938,
            -0.0017283334163948894,
            -0.032026294618844986,
            0.022880857810378075,
            0.025420665740966797,
            0.015171452425420284,
            -0.012533536180853844,
            -0.02578398771584034,
            0.0029248022474348545,
            0.021286996081471443,
            -0.014400588348507881,
            -0.018168536946177483,
            0.014970095828175545,
            0.017389656975865364,
            -0.013605615124106407,
            -0.029383093118667603,
            0.025360558182001114,
            0.014250444248318672,
            0.009781592525541782,
            0.02461448311805725,
            -0.023718805983662605,
            0.0012823825236409903,
            0.0067968182265758514,
            -0.006725523620843887,
            0.005113391671329737,
            -0.0031285106670111418,
            -0.01668381318449974,
            0.008784065023064613,
            -0.022216228768229485,
            -0.0030853720381855965,
            -0.008226306177675724,
            -0.008494731970131397,
            -0.02818031795322895,
            0.008547008968889713,
            -0.0013054021401330829,
            9.408150071976706e-05,
            0.027173742651939392,
            -0.005961891729384661,
            0.02904491312801838,
            -0.010055815801024437,
            -0.0319267213344574,
            -0.018637103959918022,
            -0.013651777990162373,
            -0.018808964639902115,
            -0.018212303519248962,
            0.029542772099375725,
            -0.027843954041600227,
            0.005557897966355085,
            0.017204787582159042,
            -0.020267030224204063,
            -0.01798505336046219,
            -0.016506191343069077,
            0.023091016337275505,
            0.008034422062337399,
            0.02084861695766449,
            0.016841735690832138,
            -0.021194832399487495,
            -0.020920058712363243,
            -0.006120345555245876,
            0.011695099994540215,
            0.003981893416494131,
            -0.023954331874847412,
            -0.019730063155293465,
            -0.0022802550811320543,
            0.014454047195613384,
            0.023532094433903694,
            0.02250353991985321,
            0.029868053272366524,
            -0.022882597520947456,
            -0.005376683082431555,
            0.01925233006477356,
            0.023221995681524277,
            0.015466883778572083,
            -0.017073821276426315,
            -0.0064352573826909065,
            0.021262330934405327,
            -0.025151221081614494,
            -0.013802828267216682,
            0.027716854587197304,
            0.00033953471574932337,
            0.009716506116092205,
            0.010673493146896362,
            -0.011541387997567654,
            0.016835175454616547,
            0.011051175184547901,
            0.029531119391322136,
            -0.009966214187443256,
            -0.0012250060681253672,
            -0.012585584074258804,
            0.008305658586323261,
            -0.018072834238409996,
            -0.017814159393310547,
            0.004455085378140211,
            -0.023586666211485863,
            0.011576195247471333,
            -0.002215876244008541,
            0.000672368798404932,
            0.021983597427606583,
            0.013240247964859009,
            -0.030368633568286896,
            0.022427713498473167,
            0.011148876510560513,
            0.02887611836194992,
            -0.003731106873601675,
            -0.0285921823233366,
            -0.026970738545060158,
            0.007952106185257435,
            -0.02819070592522621,
            -0.006783988326787949,
            0.007044210098683834,
            -0.024005236104130745,
            0.017841601744294167,
            0.016495566815137863,
            0.008613889105618,
            -0.0015388473402708769,
            -0.015872279182076454,
            -0.029319150373339653,
            -0.02699360065162182,
            0.01676643267273903,
            -0.024804580956697464,
            -0.027981428429484367,
            0.021860819309949875,
            0.014313954859972,
            0.015624971129000187,
            -0.011166862212121487,
            -0.011660374701023102,
            0.009870904497802258,
            -0.022592492401599884,
            -0.01839715614914894,
            -0.025957072153687477,
            0.014527021907269955,
            -0.026046033948659897,
            -0.010425293818116188,
            0.029335882514715195,
            0.018162742257118225,
            0.010974375531077385,
            0.011141602881252766,
            0.0247811246663332,
            -0.02579374983906746,
            0.005788424983620644,
            -0.02601713128387928,
            0.027025287970900536,
            0.005416289437562227,
            -0.0023865674156695604,
            0.025376051664352417,
            -0.016705740243196487,
            0.0005095520755276084,
            0.000609984970651567,
            0.004270583391189575,
            -0.021990155801177025,
            -0.0021130461245775223,
            0.01141454465687275,
            0.023356745019555092,
            -0.027496561408042908,
            -0.015809467062354088,
            -0.002734392648562789,
            -0.01773897558450699,
            -0.006692691706120968,
            0.0031850391533225775,
            -0.023323211818933487,
            0.026925478130578995,
            0.020366743206977844,
            -0.009027495980262756,
            0.0055859447456896305,
            -0.012539380230009556,
            0.006375059951096773,
            0.007200158201158047,
            0.002327349968254566,
            0.008052848279476166,
            -0.0011749514378607273,
            0.024781079962849617,
            0.01646352931857109,
            0.012059489265084267,
            0.029309341683983803,
            -0.013086720369756222,
            0.018432805314660072,
            -0.02631649747490883,
            -0.02242705039680004,
            0.0016657623928040266,
            -0.01666630432009697,
            0.008954533375799656,
            0.01742919161915779,
            0.009366268292069435,
            -0.0027353973127901554,
            0.009968176484107971,
            -0.000988541985861957,
            0.004700707271695137,
            -0.005254799500107765,
            0.016709845513105392,
            -0.019112607464194298,
            -0.02903490886092186,
            -0.02400711178779602,
            0.018161214888095856,
            -0.024283144623041153,
            -0.024248061701655388,
            -0.027773847803473473,
            0.027466503903269768,
            0.019283579662442207,
            -0.008656221441924572,
            -0.001472756383009255,
            -0.020159002393484116,
            -0.0187898650765419,
            0.010412163101136684,
            -0.0015050929505378008,
            -0.01705603487789631,
            -0.014481926336884499,
            0.023485615849494934,
            -0.008423213846981525,
            0.013825848698616028,
            0.029559215530753136,
            -0.016455557197332382,
            0.010524089448153973,
            0.0244886614382267,
            0.007616150192916393,
            0.014115565456449986,
            -0.031851571053266525,
            -0.01727406308054924,
            -0.023512739688158035,
            0.02580520510673523,
            0.005718308035284281,
            0.028217526152729988,
            -0.01938161626458168,
            -0.026711177080869675,
            0.021842392161488533,
            0.004877044353634119,
            0.016726788133382797,
            -0.009126297198235989,
            -0.032025765627622604,
            -0.017054662108421326,
            0.008812250569462776,
            0.01922903209924698,
            0.030025741085410118,
            -0.03161770850419998,
            0.022622879594564438,
            0.015925832092761993,
            0.0038357048761099577,
            -0.0005061579868197441,
            0.009950229898095131,
            -0.026030059903860092,
            0.014483018778264523,
            -0.022435979917645454
        ],
        [
            -0.010166089981794357,
            -0.018569210544228554,
            -0.00528854550793767,
            -0.021561942994594574,
            -0.029320955276489258,
            0.029793988913297653,
            0.01870065927505493,
            -0.035823289304971695,
            -0.012240879237651825,
            0.03102336823940277,
            -0.025984035804867744,
            0.03148876875638962,
            0.03705647587776184,
            0.046080853790044785,
            -0.014413653872907162,
            0.007220415398478508,
            -0.028767118230462074,
            -0.008728675544261932,
            -0.02119448408484459,
            0.007634369656443596,
            -0.02189532294869423,
            -0.039172783493995667,
            -0.051627401262521744,
            0.004787574056535959,
            -0.02170785702764988,
            -0.01721290871500969,
            0.006679998245090246,
            0.00673673115670681,
            -0.028768043965101242,
            -0.008669150993227959,
            -0.010594777762889862,
            0.009195300750434399,
            0.02032407931983471,
            0.021130848675966263,
            -0.0028579060453921556,
            -0.017713356763124466,
            0.01563677191734314,
            -0.015813440084457397,
            0.025408484041690826,
            -0.02436593361198902,
            -0.039059411734342575,
            -0.03634237125515938,
            0.021073071286082268,
            0.011797687038779259,
            0.020864877849817276,
            -0.02302660420536995,
            0.027345940470695496,
            0.026485726237297058,
            0.02423706091940403,
            -0.01814907044172287,
            0.033041972666978836,
            -0.009993341751396656,
            0.047155771404504776,
            -0.015968160703778267,
            0.03288111835718155,
            0.015557860024273396,
            -0.02395775355398655,
            -0.024950258433818817,
            0.02383074350655079,
            -0.004697443917393684,
            0.04086395353078842,
            0.02568512223660946,
            0.016295451670885086,
            0.008809718303382397,
            0.008842140436172485,
            -0.002478358568623662,
            0.008673625998198986,
            0.022621216252446175,
            0.04212336614727974,
            0.010854354128241539,
            -7.490973075618967e-05,
            -0.006975374650210142,
            0.01153894979506731,
            -0.022045588120818138,
            0.017014609649777412,
            0.011650294065475464,
            0.008681892417371273,
            -0.02086043171584606,
            0.042973682284355164,
            -0.01276883203536272,
            0.006716781761497259,
            0.01472878735512495,
            -0.03433484211564064,
            -0.009352541528642178,
            -0.00016018067253753543,
            0.04152895510196686,
            -0.00782814435660839,
            -0.020444951951503754,
            -0.012700178660452366,
            0.05307077243924141,
            0.025296486914157867,
            0.02007950283586979,
            -0.006158000789582729,
            -0.04466516524553299,
            0.05076848343014717,
            -0.017223788425326347,
            -0.018002541735768318,
            -0.009161299094557762,
            -0.041384506970644,
            0.01787465251982212,
            0.015526018105447292,
            -0.03002231940627098,
            -0.0007035561138764024,
            0.014638383872807026,
            -0.024895494803786278,
            -0.006349344737827778,
            0.023331845179200172,
            0.01857164315879345,
            -0.002863793633878231,
            0.020552514120936394,
            -0.0471293069422245,
            -0.019682040438055992,
            0.0009594802395440638,
            -0.030965417623519897,
            -0.03958268091082573,
            0.02995678409934044,
            0.003225254360586405,
            0.00241924193687737,
            0.006689127068966627,
            0.01564878411591053,
            0.029515966773033142,
            -0.0016963628586381674,
            -0.0013857970479875803,
            0.017640626057982445,
            -0.01516787614673376,
            0.007190031930804253,
            -0.005584145896136761,
            -0.0318710133433342,
            -0.019778063520789146,
            -0.009297940880060196,
            0.006602635141462088,
            -0.04222872108221054,
            -0.029092928394675255,
            0.056881148368120193,
            -0.030502118170261383,
            -0.014250537380576134,
            -0.03852270916104317,
            0.04632433131337166,
            0.03416784107685089,
            0.006800803821533918,
            0.0025172752793878317,
            0.012458994053304195,
            0.015610870905220509,
            -0.016875218600034714,
            0.045646607875823975,
            0.0002158473216695711,
            0.0070476527325809,
            0.03615080937743187,
            0.016390303149819374,
            0.016463177278637886,
            -0.022038744762539864,
            0.0015141464537009597,
            -0.04090746119618416,
            -0.018590109422802925,
            0.023480355739593506,
            0.04071107879281044,
            0.02081095427274704,
            -0.03644968941807747,
            0.005309165455400944,
            -0.03838474676012993,
            -0.013928866945207119,
            -0.024269867688417435,
            -0.017052538692951202,
            0.011418896727263927,
            0.022706754505634308,
            0.010054802522063255,
            -0.03744984418153763,
            -0.04518004134297371,
            -0.021777663379907608,
            -0.006853417959064245,
            -0.02352386713027954,
            -0.024346821010112762,
            0.04163207486271858,
            -0.02856418304145336,
            0.011775698512792587,
            -0.013146370649337769,
            0.022625574842095375,
            0.0019500369671732187,
            -0.01994004286825657,
            -0.0073019713163375854,
            0.043017931282520294,
            -0.0008191431406885386,
            -0.03184525668621063,
            -0.014402346685528755,
            -0.009685849770903587,
            0.01882026344537735,
            -0.04183877259492874,
            -0.025122223421931267,
            0.03947636857628822,
            0.018519368022680283,
            0.019020114094018936,
            -0.0005732056451961398,
            0.07988830655813217,
            -0.014479606412351131,
            0.011022255755960941,
            -0.027599681168794632,
            -0.008753188885748386,
            0.005372160580009222,
            0.0002355720935156569,
            0.02532804384827614,
            0.0250034611672163,
            0.014529125764966011,
            -0.02213776856660843,
            0.023194098845124245,
            -0.020700449123978615,
            -0.010818039998412132,
            -0.020877791568636894,
            -0.004603338427841663,
            -0.05806432291865349,
            0.021617522463202477,
            -0.02091304026544094,
            -0.028581388294696808,
            -0.007524400483816862,
            0.03382731229066849,
            0.008545571938157082,
            -0.0243569016456604,
            0.006806434132158756,
            0.030341627076268196,
            -0.02346169762313366,
            -0.01632184535264969,
            0.023732643574476242,
            -0.0077524445950984955,
            0.05392850562930107,
            -0.04960889741778374,
            -0.035647764801979065,
            -0.019311968237161636,
            0.02090037427842617,
            -0.016502752900123596,
            0.028993623331189156,
            0.03040076605975628,
            0.020924730226397514,
            0.014944478869438171,
            0.050110116600990295,
            0.033070553094148636,
            0.04380820319056511,
            -0.004515048582106829,
            -0.003254844341427088,
            -0.026458295062184334,
            -0.020867876708507538,
            -2.0380381101858802e-05,
            -0.044352076947689056,
            0.019471287727355957,
            0.0037348943296819925,
            -0.02203773893415928,
            0.004253794904798269,
            -0.01914357952773571,
            -0.015344412997364998,
            0.03278142586350441,
            0.025896284729242325,
            0.009936048649251461,
            0.01517467200756073,
            -0.02618379332125187,
            -0.03759407624602318,
            -0.012892713770270348,
            0.0022600118536502123,
            0.03533944860100746,
            -0.001355086686089635,
            0.010133016854524612,
            -0.0005859917728230357,
            0.023673824965953827,
            -0.011969130486249924,
            0.02724667266011238,
            -0.038695771247148514,
            -0.017636118456721306,
            -0.003105461597442627,
            -0.023759892210364342,
            0.01092403195798397,
            -0.003188809147104621,
            -0.0174536295235157,
            0.013912535272538662,
            -0.010367035865783691,
            0.030085334554314613,
            0.008990728296339512,
            0.004341679159551859,
            -0.012843539007008076,
            -0.02537216804921627,
            0.03163658827543259,
            -0.020564671605825424,
            -0.04667479544878006,
            -0.019084885716438293,
            -0.02903982438147068,
            0.02267037145793438,
            0.004466963931918144,
            -0.03914815932512283,
            0.017788929864764214,
            0.021146664395928383,
            -0.009046519175171852,
            -0.009819733910262585,
            -0.05618390813469887,
            -0.03137456998229027,
            -0.01996799372136593,
            0.030003324151039124,
            -0.009102975949645042,
            -0.023339180275797844,
            0.035267118364572525,
            -0.010271841660141945,
            0.004431376699358225,
            -0.018410272896289825,
            0.02640261873602867,
            0.0025885184295475483,
            -0.028655510395765305,
            0.026571987196803093,
            0.00025019265012815595,
            -0.006412843242287636,
            -0.008806036785244942,
            -0.04725728929042816,
            0.020774243399500847,
            0.006301813758909702,
            -0.030113814398646355,
            0.03534938022494316,
            0.041502635926008224,
            0.0445634163916111,
            -0.007244264241307974,
            0.00033270681160502136,
            0.0013085633981972933,
            -0.006384697277098894,
            0.0026751484256237745,
            0.038263291120529175,
            0.009286968968808651,
            0.0107455775141716,
            0.0019023820059373975,
            0.01808033138513565,
            -0.0163398589938879,
            -0.05169219896197319,
            -0.017421405762434006,
            0.04302108287811279,
            -0.03587072342634201,
            0.015138051472604275,
            -0.0029314111452549696,
            -0.0004413198330439627,
            0.020606549456715584,
            -0.00011200761946383864,
            0.02140677347779274,
            -0.005528944078832865,
            0.022018222138285637,
            0.01785896345973015,
            0.02336476556956768,
            0.0004148234147578478,
            -0.0324644036591053,
            -0.012701760046184063,
            0.0001572547625983134,
            0.025150392204523087,
            0.038940493017435074,
            -0.012836575508117676,
            -0.02095775306224823,
            -0.017708109691739082,
            0.027231911197304726,
            -0.05680190771818161,
            -0.024034574627876282,
            -0.03639544919133186,
            0.025358866900205612,
            -0.018828777596354485,
            0.02511623129248619,
            -0.021907836198806763,
            -0.007966282777488232,
            0.013535360805690289,
            0.01436651311814785,
            0.022848881781101227,
            -0.0007693564402870834,
            -0.017313139513134956,
            0.012153956107795238,
            0.03626589849591255,
            0.026866722851991653,
            -0.018452079966664314,
            -0.023010829463601112,
            -0.023986544460058212,
            -0.06715770810842514,
            -0.00539071299135685,
            -0.02159295231103897,
            0.01314232312142849,
            0.017212839797139168,
            -0.006747793406248093,
            -0.005236045923084021,
            0.02338573709130287,
            -0.020914725959300995,
            0.027880914509296417,
            0.01508242730051279,
            -0.018435874953866005,
            -0.00020828402193728834,
            0.03733096644282341,
            -0.03415963053703308,
            -0.034590836614370346,
            0.037820424884557724,
            -0.010656249709427357,
            -0.04810064658522606,
            0.019273720681667328,
            0.006877821870148182,
            -0.019428078085184097,
            0.0063347769901156425,
            0.02155895344913006,
            0.007656766101717949,
            0.004654260352253914,
            -0.016598228365182877,
            0.04368393495678902,
            -0.004182757809758186,
            0.0037047225050628185,
            0.011404014192521572,
            -0.012800125405192375,
            0.016931412741541862,
            -0.0028445646166801453,
            0.04614754766225815,
            -0.019265472888946533,
            0.019323525950312614,
            0.01861432008445263,
            0.01671750098466873,
            -0.012124435044825077,
            0.0014660812448710203,
            -0.04074196517467499,
            0.0122157521545887,
            0.0023252421524375677,
            -0.034267257899045944,
            0.01868177019059658,
            -0.05177295207977295,
            0.027504581958055496,
            0.012804431840777397,
            -0.017507467418909073,
            0.02814628556370735,
            -0.0897839143872261,
            -0.05473148450255394,
            -0.008787069469690323,
            0.013010727241635323,
            -0.05005743354558945,
            -0.003044698853045702,
            -0.0013630448374897242,
            -0.024660799652338028,
            -0.008953513577580452,
            -0.006151699926704168,
            0.0041029974818229675,
            0.0007272509974427521,
            0.006292739417403936,
            -0.04585636779665947,
            0.0043183877132833,
            0.018782375380396843,
            -0.017714399844408035,
            9.602747013559565e-05,
            0.0050984760746359825,
            -0.002335772616788745,
            -0.0014891253085806966,
            0.022739818319678307,
            0.026244763284921646,
            0.002631781157106161,
            0.003646868746727705,
            -0.019164323806762695,
            0.039740074425935745,
            0.011148952879011631,
            -0.025575758889317513,
            0.026743056252598763,
            -0.08054663240909576,
            -0.009905681014060974,
            0.011935178190469742,
            -0.002757605165243149,
            -0.015876103192567825,
            -0.0017435846384614706,
            -0.018506957218050957,
            0.039369139820337296,
            0.0087179159745574,
            -0.04654745012521744,
            -0.003702756715938449,
            0.030443480238318443,
            0.00706165237352252,
            -0.000902986153960228,
            -0.0029399937484413385,
            -0.03246591240167618,
            0.01963716372847557,
            0.021187061443924904,
            -0.02117171511054039,
            -0.02707688696682453,
            0.028371138498187065,
            0.01612609438598156,
            -0.0117519311606884,
            0.0004463047080207616,
            -0.006980499252676964,
            -0.01818854734301567,
            0.03713856264948845,
            0.017541367560625076,
            -0.003478693775832653,
            0.02374480478465557,
            -0.015091321431100368,
            -0.004365452099591494,
            0.034276124089956284,
            -0.01992655172944069,
            0.01944733038544655,
            0.009737586602568626,
            0.018267696723341942,
            -0.03667041286826134,
            0.0016123983077704906,
            -0.019650064408779144,
            0.01165637094527483,
            0.06092808395624161,
            -0.006211698986589909,
            0.029458394274115562,
            0.02456938661634922,
            -0.01896671950817108,
            -0.03029480390250683,
            -0.02025754190981388,
            -0.018829861655831337,
            -0.0350528359413147,
            -0.045346178114414215,
            -0.02105727419257164,
            0.0056419046595692635,
            -0.018504785373806953,
            -0.027478348463773727,
            -0.012699324637651443,
            0.051435668021440506,
            -0.025592602789402008,
            -0.01182646956294775,
            0.00181966635864228,
            -6.704914994770661e-05,
            0.02232988364994526,
            0.0362338162958622,
            0.007564431056380272,
            0.04919943958520889,
            0.021062176674604416,
            0.026376530528068542,
            -0.0019510568818077445,
            -0.004021165426820517,
            0.050099123269319534,
            0.03616300970315933,
            0.030277062207460403,
            -0.04664319381117821,
            -0.02563140168786049,
            -0.007814103737473488,
            -0.014444751664996147,
            -0.03312255069613457,
            -0.04609492048621178,
            0.004159853328019381,
            -0.003299103118479252,
            0.011725472286343575,
            -0.009932425804436207,
            -0.03211098536849022,
            0.018827300518751144,
            0.011041445657610893,
            0.02440628409385681,
            -0.028234394267201424,
            -0.008501688949763775,
            0.036609943956136703,
            -0.013541843742132187,
            0.013057059608399868,
            -0.02713002823293209,
            -0.010641735047101974,
            -0.021881872788071632,
            0.005360038485378027,
            -0.0023153931833803654,
            -0.009593921713531017,
            -0.02533033676445484,
            -0.05199042335152626,
            -0.030747558921575546,
            0.0685792788863182,
            -0.003147531533613801,
            0.023384714499115944,
            -0.003467195201665163,
            0.013180536217987537,
            0.003512343857437372,
            0.026720529422163963,
            0.01830984279513359,
            -0.01552923209965229,
            0.026385415345430374,
            -0.016098225489258766,
            0.008503076620399952,
            -0.01567034423351288,
            0.03230739012360573,
            -0.018462521955370903,
            0.01059016864746809,
            -0.0473371185362339,
            -0.062647745013237,
            -0.005680593196302652,
            0.011329321190714836,
            -0.015422736294567585,
            0.006958036217838526,
            -0.010270302183926105,
            0.038489930331707,
            -0.01539725810289383,
            -0.008647332899272442,
            -0.038179781287908554,
            -0.03668719902634621,
            -0.018256494775414467,
            0.041164156049489975,
            0.04779346287250519,
            0.04463804140686989,
            0.007429524324834347,
            -0.005815764423459768,
            -0.035674113780260086,
            0.05239829048514366,
            -0.012273747473955154,
            0.0031926895026117563,
            0.01888960413634777,
            -0.011415473185479641,
            -0.006303844973444939,
            -0.037419643253088,
            -0.017764795571565628,
            0.008547157049179077,
            0.039211489260196686,
            0.0011774832382798195,
            0.03897649422287941,
            0.02036633901298046,
            -0.004427406471222639,
            0.008391312323510647,
            0.019312890246510506,
            -0.016226353123784065,
            -0.04893547296524048,
            0.05802145600318909,
            -0.017918502911925316,
            0.014093440026044846,
            0.008691995404660702,
            0.013397501781582832,
            -0.03350101038813591,
            0.0012946224305778742,
            -0.033705901354551315,
            0.008156046271324158,
            -0.046147797256708145,
            0.0013109546853229403,
            0.0148670868948102,
            0.014919031411409378,
            -0.011706727556884289,
            0.03396960720419884,
            0.0009636174654588103,
            -0.04002467915415764,
            -0.016049230471253395,
            0.024452904239296913,
            -0.03406393527984619,
            0.013570456765592098,
            0.0315028578042984,
            -0.009683705866336823,
            0.02197232097387314,
            0.018863068893551826,
            -0.0461038202047348,
            0.012958543375134468,
            0.0313686765730381,
            0.007211701013147831,
            0.02514505758881569,
            0.048148851841688156,
            -0.009691165760159492,
            0.0077742296271026134,
            -0.00606933468952775,
            0.030332541093230247,
            -0.01580469310283661,
            -0.0026825799141079187,
            0.018659820780158043,
            -0.022525550797581673,
            0.027252864092588425,
            0.05635298043489456,
            -0.08631951361894608,
            -0.02032598666846752,
            0.05464869737625122,
            -0.009920395910739899,
            -0.0472530722618103,
            -0.020284444093704224,
            -0.018025578930974007,
            -0.03354407474398613,
            0.03825647011399269,
            0.0056459312327206135,
            0.045087769627571106,
            -0.010356779210269451,
            0.0025362465530633926,
            0.0014246649807319045,
            -0.017659302800893784,
            0.005589340813457966,
            -0.004655969794839621,
            -0.0023539019748568535,
            0.0009403979638591409,
            -0.04665716737508774,
            -0.016025159507989883,
            0.01501066330820322,
            0.0043259551748633385,
            0.02120904065668583,
            0.012713582254946232,
            -4.6277309593278915e-05,
            -0.024382421746850014,
            -0.016662122681736946,
            -0.009357118047773838,
            -0.0341857373714447,
            0.00875530019402504,
            -0.002349297283217311,
            0.03865128383040428,
            0.03432901203632355,
            0.011131430976092815,
            -0.003038313239812851,
            -0.02556396834552288,
            3.71129899576772e-05,
            -0.019347473978996277,
            -0.11179977655410767,
            -0.014373904094099998,
            0.020463110879063606,
            -0.027858054265379906,
            -0.008947285823523998,
            -0.01189899817109108,
            -0.023673895746469498,
            0.025824138894677162,
            0.01313644740730524,
            -0.04084402695298195,
            -0.009338805451989174,
            0.019642626866698265,
            0.0037586914841085672,
            0.0065883975476026535,
            -0.0011390314903110266,
            -0.020765554159879684,
            -0.01474738772958517,
            -0.02769581973552704,
            -0.025112764909863472,
            0.006045425310730934,
            -0.01859390176832676,
            0.004836910404264927,
            0.013888130895793438,
            0.01883014850318432,
            0.025864148512482643,
            -0.006772931199520826,
            -0.02341928519308567,
            -0.019986916333436966,
            -0.007377575151622295,
            0.03756003826856613,
            0.002390505513176322,
            -0.025988420471549034,
            0.012591300532221794,
            0.005205422639846802,
            0.04223519191145897,
            -0.012227748520672321,
            -0.07737842202186584,
            0.02007124200463295,
            -0.029624370858073235,
            0.02120283991098404,
            0.019286148250102997,
            -0.002080771140754223,
            0.008398715406656265,
            -0.05416802689433098,
            -0.0008477509254589677,
            0.028358465060591698,
            0.017741916701197624,
            -0.007870834320783615,
            -0.022576265037059784,
            -0.007772362791001797,
            0.009391164407134056,
            0.004725850652903318,
            -0.043018970638513565,
            0.0198499895632267,
            0.005107290111482143,
            -0.0002244253846583888,
            0.021217403933405876,
            -0.03839904069900513,
            0.03582679480314255,
            -0.018277643248438835,
            0.0123897735029459,
            -0.017686882987618446,
            0.019131530076265335,
            -0.0012192731956019998,
            -0.00332312542013824,
            -0.04947154223918915,
            0.00030158256413415074,
            0.05319339036941528,
            0.017876693978905678,
            0.018157023936510086,
            -0.022475894540548325,
            0.0138400262221694,
            -0.036712903529405594,
            -0.027174293994903564,
            -0.0007301057921722531,
            -0.003511276328936219,
            -0.032449591904878616,
            -0.07362698763608932,
            0.016620976850390434,
            0.023104248568415642,
            0.039770692586898804,
            -0.005327953025698662,
            -0.01421249657869339,
            0.022651953622698784,
            -0.00537068210542202,
            0.03312588855624199,
            -0.0049412986263632774,
            -0.0217375960201025,
            -0.023545879870653152,
            -0.0011669411323964596,
            -0.022169053554534912,
            0.02984536439180374,
            0.038144685328006744,
            -0.026564626023173332,
            -0.027369176968932152,
            -0.02103055827319622,
            -0.02691042609512806,
            -0.006275756750255823,
            -0.011978121474385262,
            -0.017930978909134865,
            0.027016382664442062,
            -0.020575422793626785,
            0.00887253601104021,
            -0.02430645190179348,
            -0.042266808450222015,
            0.031643372029066086,
            0.011606644839048386,
            0.0264921635389328,
            0.007307227700948715,
            0.031334877014160156,
            0.04811864346265793,
            0.0026095875073224306,
            0.03706521913409233,
            0.005581969860941172,
            0.021289078518748283,
            -0.027493571862578392,
            0.02004914917051792,
            0.03841809183359146,
            0.00472649559378624,
            0.048374347388744354,
            -0.00911139976233244,
            0.005688787903636694,
            -0.055308613926172256,
            -0.0005094388616271317,
            0.012484115548431873,
            0.02719319425523281,
            -0.011699444614350796,
            0.009862232021987438,
            -0.026880335062742233,
            0.02137778140604496,
            -0.04659353941679001,
            -0.039077162742614746,
            -0.01777508109807968,
            -0.01803782396018505,
            0.04440434277057648,
            0.009007160551846027,
            0.03320799767971039,
            -0.007802457548677921,
            -0.051312729716300964,
            -0.008657058700919151,
            -0.03367335721850395,
            0.0013114787871018052,
            0.036679480224847794,
            0.0013755294494330883,
            0.016144391149282455,
            0.026129579171538353,
            -0.0021251868456602097,
            0.027057452127337456,
            0.01418126281350851,
            -0.03418082743883133,
            -0.0003533512935973704,
            -0.009416148997843266,
            -0.005217139609158039,
            0.010804292745888233,
            -0.002333394018933177,
            -0.016731122508645058,
            0.031091896817088127,
            0.014847276732325554,
            -0.03684212639927864,
            0.0007391417748294771,
            -0.012172844260931015,
            0.028609903529286385,
            -0.07728773355484009,
            -0.008687205612659454,
            -0.015509502030909061,
            0.014303715899586678,
            -0.03785629943013191,
            0.023553207516670227,
            -0.020573096349835396,
            -0.009452737867832184,
            0.01293467078357935,
            -0.008457868359982967,
            0.014781215228140354,
            -0.0022736378014087677,
            0.026091137900948524,
            0.041109781712293625,
            -0.030283760279417038,
            0.005269100423902273,
            0.0394902378320694,
            0.01604895479977131,
            -0.02545698545873165,
            -0.005244376603513956,
            -0.016026347875595093,
            0.016699397936463356,
            0.02948235720396042,
            0.0022255403455346823,
            -0.01626412197947502,
            -0.022928394377231598,
            -0.010390807874500751,
            -0.022875182330608368,
            -0.0013673531357198954,
            -0.0019527875119820237,
            0.03840013965964317,
            0.026497984305024147,
            -0.015156875364482403,
            0.028030874207615852,
            -0.027197210118174553,
            -0.005653634667396545,
            0.00388327450491488,
            0.023787934333086014,
            -0.007534277159720659,
            -0.05963458493351936,
            -0.017205482348799706,
            -0.034018926322460175,
            -0.03876044601202011,
            0.00846260879188776,
            0.014559478498995304,
            -0.05176820233464241,
            0.005983018781989813,
            -0.009931332431733608,
            -0.035737425088882446,
            0.006530217360705137,
            0.008393452502787113,
            0.0911576971411705,
            -0.0050400979816913605,
            0.00880188774317503,
            -0.032907553017139435,
            0.04923063516616821,
            0.009254241362214088,
            -0.0454675629734993,
            -0.030656160786747932,
            0.019815823063254356,
            -0.002642106031998992,
            0.0166533924639225,
            -0.04576054587960243,
            -0.023081768304109573,
            0.010323090478777885,
            0.01733795739710331,
            0.03384919837117195,
            -0.0021915738470852375,
            0.01301637478172779,
            0.024294599890708923,
            0.009629841893911362,
            -0.039745744317770004,
            0.0010723096784204245,
            0.03111484833061695,
            -0.03244173899292946,
            -0.017957519739866257,
            -0.0014501733239740133,
            -0.021298393607139587,
            0.005573204252868891,
            -0.024122416973114014,
            -0.005124845542013645,
            -0.0001964449038496241,
            -0.10245766490697861,
            0.0239226333796978,
            -0.00903578195720911,
            -0.01144210621714592,
            0.0017398064956068993,
            0.04004354029893875,
            0.016565853729844093,
            -0.0009375481167808175,
            -0.014215424656867981,
            0.014399884268641472,
            0.015771353617310524,
            0.0032151706982403994,
            0.02186860889196396,
            -0.00903088878840208,
            0.032306428998708725,
            0.005864794831722975,
            -0.01581348106265068,
            0.012026174925267696,
            -0.0023640980944037437,
            0.01558732707053423,
            0.025026308372616768,
            -0.03567146882414818,
            0.010682026855647564,
            0.009031686000525951,
            -0.005158750806003809,
            0.02183077484369278,
            0.018409207463264465,
            0.012454730458557606,
            0.006990114226937294,
            0.015942944213747978,
            -0.032013218849897385,
            -0.007311688270419836,
            -0.023219779133796692,
            0.02085849642753601,
            0.026696905493736267,
            0.014151045121252537,
            -0.01380235143005848,
            0.014131893403828144,
            0.008579128421843052,
            0.001106792944483459,
            -0.021421687677502632,
            0.02465512976050377,
            0.023115668445825577,
            0.0031459543388336897,
            -0.0237333495169878,
            -0.008755027316510677,
            0.02279743365943432,
            0.036156006157398224,
            -0.032798975706100464,
            -0.019877951592206955,
            0.012852353975176811,
            0.030566804111003876,
            0.011805646121501923,
            -0.019284687936306,
            0.023164914920926094,
            0.007351620122790337,
            -0.06183662265539169,
            -0.01807972975075245,
            0.039913445711135864,
            0.013820243068039417,
            -0.017283421009778976,
            0.0017472503241151571,
            0.012911525554955006,
            0.017690088599920273,
            -0.010692454874515533,
            -0.0193384550511837,
            0.015762237831950188,
            0.013454288244247437,
            0.04416801780462265,
            -0.03006446734070778,
            -0.013295897282660007,
            -0.003984405193477869,
            -0.03750791400671005,
            0.0002748474071267992,
            0.007238435093313456,
            0.01888185925781727,
            0.022326486185193062,
            0.0183080043643713,
            -0.001946647185832262,
            -0.024910317733883858,
            0.00830623134970665,
            0.019733093678951263,
            0.02118549309670925,
            -0.0019978785421699286,
            0.005924187134951353,
            -0.00647262716665864,
            0.03100205771625042,
            0.02013782225549221,
            0.019556082785129547,
            0.04354499280452728,
            0.012044137343764305,
            0.020486528053879738,
            -0.025551188737154007,
            0.0018521930323913693,
            0.04678049311041832,
            0.019200554117560387,
            -0.0259030032902956,
            0.027601132169365883,
            -0.01824214681982994,
            -0.0198245607316494
        ],
        [
            0.0018788790330290794,
            0.021987101063132286,
            0.024486713111400604,
            -0.01729412004351616,
            0.032037653028964996,
            0.023270778357982635,
            0.027004139497876167,
            0.028902122750878334,
            0.006997760385274887,
            -0.012542533688247204,
            -0.01821933686733246,
            0.013928371481597424,
            0.01821754314005375,
            0.005466690752655268,
            0.05662023276090622,
            -0.0010147980647161603,
            0.004798348061740398,
            0.029725778847932816,
            0.05905865505337715,
            -0.02521708607673645,
            0.016412297263741493,
            -0.011867559514939785,
            0.008719545789062977,
            0.0004988533910363913,
            0.010837103240191936,
            0.02079281024634838,
            0.005071013234555721,
            0.004226492717862129,
            -0.005949481390416622,
            -0.03464632108807564,
            0.050534624606370926,
            -0.01573602296411991,
            -0.02254585549235344,
            -0.03617893531918526,
            0.018602723255753517,
            -0.017124637961387634,
            0.010645520873367786,
            -0.01100633479654789,
            0.01782768778502941,
            -0.025232184678316116,
            0.033241644501686096,
            0.02244982309639454,
            -0.0020469569135457277,
            -0.029515672475099564,
            0.023027893155813217,
            -0.027433890849351883,
            -0.027309903874993324,
            0.0018888750346377492,
            0.0020881667733192444,
            0.0354035310447216,
            -0.013952508568763733,
            -7.383870251942426e-05,
            -0.022199610248208046,
            -0.021131716668605804,
            0.022267628461122513,
            -0.002560526365414262,
            0.03361033275723457,
            0.026968181133270264,
            0.003243809100240469,
            0.00572447944432497,
            0.014242707751691341,
            0.014036105014383793,
            -0.06766964495182037,
            -0.0008409916772507131,
            -0.007537806406617165,
            0.03020418807864189,
            -0.016863269731402397,
            0.011407018639147282,
            0.0023288517259061337,
            0.03117184527218342,
            0.007595915347337723,
            -0.040088359266519547,
            -0.00929630920290947,
            -0.004755053669214249,
            -0.09056973457336426,
            0.03778529539704323,
            0.0280719343572855,
            0.016410548239946365,
            -0.011897987686097622,
            0.027101684361696243,
            -0.030506769195199013,
            0.06685365736484528,
            0.010990872979164124,
            0.015958214178681374,
            -0.009013833478093147,
            -0.008616291917860508,
            0.02684652805328369,
            0.01057109422981739,
            -0.001376917352899909,
            -0.05286295339465141,
            0.056023623794317245,
            -0.00034398314892314374,
            -0.008397701196372509,
            0.01657170243561268,
            0.03458687663078308,
            -0.007494600489735603,
            0.025184588506817818,
            0.016319699585437775,
            -0.009759102948009968,
            0.008015019819140434,
            0.01794096827507019,
            0.0514371432363987,
            -0.017797129228711128,
            -0.0154364462941885,
            -0.008366938680410385,
            -0.019321678206324577,
            -0.007105827331542969,
            -0.022167865186929703,
            -0.008953560143709183,
            0.0019595713820308447,
            0.04417760297656059,
            -0.03506569564342499,
            0.026874655857682228,
            -0.025877760723233223,
            -0.0012932522222399712,
            0.013325914740562439,
            0.00613765325397253,
            -0.020586702972650528,
            -0.0019467304227873683,
            0.020729942247271538,
            -0.010115726850926876,
            0.010706222616136074,
            -0.0173269584774971,
            -0.022252757102251053,
            0.02530807815492153,
            -0.013234758749604225,
            -1.8006950995186344e-05,
            -0.006974006071686745,
            0.0009750108001753688,
            0.027616916224360466,
            -0.008271814323961735,
            0.04924826696515083,
            0.004549890756607056,
            -0.007642729207873344,
            -0.03159201890230179,
            -0.02980930358171463,
            -0.0022740031126886606,
            0.003109487472102046,
            -0.02028731070458889,
            0.0012088939547538757,
            -0.0003659822978079319,
            -0.01202328596264124,
            0.018212925642728806,
            -0.023537643253803253,
            0.026101432740688324,
            -0.016401462256908417,
            0.018540548160672188,
            -0.02959205023944378,
            0.00933822337538004,
            -0.007365109398961067,
            0.007701580878347158,
            -0.057319190353155136,
            -0.025739077478647232,
            -0.044042397290468216,
            -0.008118928410112858,
            -0.012622922658920288,
            0.017283879220485687,
            0.034004244953393936,
            0.012806648388504982,
            -0.02374824322760105,
            0.013010292313992977,
            -0.005744471214711666,
            0.026540251448750496,
            0.0035282201133668423,
            0.026161838322877884,
            -0.02352352999150753,
            0.04066849499940872,
            0.0279034785926342,
            -0.028097841888666153,
            -0.0009804663714021444,
            0.04260717332363129,
            -0.03205973282456398,
            -0.01629500649869442,
            -0.023039110004901886,
            0.025917625054717064,
            -0.03937295824289322,
            -0.018106846138834953,
            -0.0044094533659517765,
            -0.014393406920135021,
            -0.008146104402840137,
            -0.00928694661706686,
            0.020597616210579872,
            0.011970528401434422,
            -0.0007776570273563266,
            0.03009847365319729,
            0.01036140788346529,
            -0.017263447865843773,
            0.0037269785534590483,
            -0.0385189950466156,
            0.046155136078596115,
            0.05446399003267288,
            0.020191526040434837,
            -0.012105295434594154,
            0.0021048455964773893,
            -0.027476441115140915,
            -0.02285434491932392,
            0.03234383463859558,
            -0.0057358695194125175,
            0.01144884992390871,
            -0.0165389496833086,
            0.00815490260720253,
            -0.018093043938279152,
            -0.06150330603122711,
            0.012834329158067703,
            -0.012320274487137794,
            0.02341402880847454,
            -0.016859276220202446,
            0.04506219923496246,
            0.02268661931157112,
            -0.028866423293948174,
            0.014736953191459179,
            0.00324822636321187,
            0.0072793057188391685,
            0.0021419664844870567,
            8.966752648120746e-05,
            0.021701008081436157,
            0.015118462033569813,
            -0.005120392423123121,
            -0.018046414479613304,
            0.03622572869062424,
            -0.0012486978666856885,
            0.03301301598548889,
            -0.031061213463544846,
            0.0044046672992408276,
            -0.0021313007455319166,
            -0.018265947699546814,
            0.018932517617940903,
            -0.0024881011340767145,
            0.025120990350842476,
            0.031077416613698006,
            -0.03477844223380089,
            -0.013457424007356167,
            -0.009812508709728718,
            -0.03683217242360115,
            -0.01707630231976509,
            0.010897127911448479,
            0.023669559508562088,
            0.02900422364473343,
            0.018520882353186607,
            -0.002513807499781251,
            -0.039087727665901184,
            -0.0245143324136734,
            -0.009683378972113132,
            -0.011845502071082592,
            0.005523864645510912,
            -0.035929761826992035,
            0.05519982799887657,
            0.005197321530431509,
            -0.018715307116508484,
            0.015315073542296886,
            -0.0073574623093008995,
            -0.009222211316227913,
            -0.02248290367424488,
            -0.0022297250106930733,
            -0.006529287900775671,
            -0.010948789305984974,
            0.03622884303331375,
            0.012218317948281765,
            0.02654067426919937,
            0.009087874554097652,
            0.02742341160774231,
            0.015452016144990921,
            -0.013511835597455502,
            -0.0030810206662863493,
            -0.03031088225543499,
            0.0273111704736948,
            -0.025946220383048058,
            0.0019650321919471025,
            0.02272675186395645,
            -0.041676685214042664,
            -6.0301332268863916e-05,
            -0.01613188162446022,
            0.012611370533704758,
            0.03744978830218315,
            0.021286126226186752,
            0.024445200338959694,
            0.006989638786762953,
            0.008162453770637512,
            0.0005945268203504384,
            0.014343548566102982,
            -0.025372259318828583,
            0.01576114445924759,
            -0.01830209232866764,
            -0.09916133433580399,
            -0.0461442694067955,
            -0.025706175714731216,
            0.0362178273499012,
            -0.06056249886751175,
            0.022371184080839157,
            0.011424921452999115,
            -0.024147221818566322,
            0.014007174409925938,
            5.956900349701755e-05,
            0.012613389641046524,
            -0.012712047435343266,
            0.01987721398472786,
            -0.01072696689516306,
            0.013279218226671219,
            -0.04255928099155426,
            0.007252628915011883,
            -0.012764734216034412,
            0.04237326234579086,
            0.01783001609146595,
            -0.034239813685417175,
            0.009155714884400368,
            -0.047350384294986725,
            0.024819983169436455,
            0.007033421192318201,
            0.04088268056511879,
            0.03871719911694527,
            -0.0032801947090774775,
            0.011025532148778439,
            -0.013214951381087303,
            -0.008848743513226509,
            0.01625780388712883,
            0.06627456098794937,
            -0.031085504218935966,
            0.010348563082516193,
            -0.015107488259673119,
            0.013684144243597984,
            -0.014868342317640781,
            -0.05103966221213341,
            -0.06746064126491547,
            0.05437520518898964,
            -0.03741016238927841,
            0.022781310603022575,
            0.04942288622260094,
            0.0019007171504199505,
            0.013629738241434097,
            0.029318518936634064,
            0.025486603379249573,
            -0.006461792159825563,
            0.02238617092370987,
            -0.019006773829460144,
            0.003343599382787943,
            -0.009815995581448078,
            0.007334444671869278,
            -0.031268369406461716,
            0.028511466458439827,
            0.012716218829154968,
            -0.0055300467647612095,
            -0.0052701979875564575,
            -0.06780963391065598,
            -0.011706284247338772,
            0.021986404433846474,
            0.02520286664366722,
            0.0338013730943203,
            0.02806152030825615,
            -0.01754489727318287,
            0.022547569125890732,
            -0.012900336645543575,
            -0.02461918443441391,
            0.06341636925935745,
            -0.026426995173096657,
            0.030192693695425987,
            -0.024561943486332893,
            -0.0013792462414130569,
            0.013530601747334003,
            -0.01974351704120636,
            0.02280641719698906,
            -0.017602941021323204,
            -0.038656894117593765,
            -0.013560991734266281,
            0.0055427695624530315,
            0.023964116349816322,
            0.0038163061253726482,
            0.028797462582588196,
            -0.003122366266325116,
            0.016808677464723587,
            -0.01178219635039568,
            -0.041849199682474136,
            0.0002525082090869546,
            0.03112727776169777,
            0.007825199514627457,
            0.026271194219589233,
            0.005342364311218262,
            -0.01616782695055008,
            0.014972494915127754,
            0.0532812662422657,
            -0.018563728779554367,
            -0.047779474407434464,
            -0.019380973652005196,
            0.010286160744726658,
            -0.022532334551215172,
            -0.024356218054890633,
            0.04776129871606827,
            0.01206100732088089,
            0.012589801102876663,
            0.04254627227783203,
            -0.0027795361820608377,
            -0.013781815767288208,
            -0.006798719987273216,
            0.015447534620761871,
            0.04128925874829292,
            0.03937172889709473,
            -0.005053397733718157,
            -0.02636617422103882,
            0.015099202282726765,
            0.029723141342401505,
            0.016986539587378502,
            -0.01891613006591797,
            0.04613639786839485,
            0.01760612241923809,
            0.012799134477972984,
            -0.0014371464494615793,
            -0.01004568487405777,
            0.025015689432621002,
            0.020918624475598335,
            0.024526674300432205,
            0.02155221812427044,
            -0.0388241671025753,
            -0.00844042282551527,
            0.022845668718218803,
            0.001731220749206841,
            0.00548130227252841,
            -0.015017963945865631,
            -0.021018104627728462,
            -0.019963463768363,
            -0.002404311206191778,
            -0.009165171533823013,
            0.012905529700219631,
            0.020999381318688393,
            0.07967884838581085,
            0.0024631107226014137,
            0.03145439922809601,
            0.023596860468387604,
            -0.008384110406041145,
            -0.024880968034267426,
            -0.017382508143782616,
            -0.004419597797095776,
            -0.017080947756767273,
            0.0009168037213385105,
            -0.041298650205135345,
            -0.0003486134228296578,
            0.014735711738467216,
            0.04372900351881981,
            0.000526282237842679,
            0.026289887726306915,
            0.027830973267555237,
            -0.012522881850600243,
            -0.021777523681521416,
            -0.056250978261232376,
            -0.031090807169675827,
            0.00984046421945095,
            -0.002531707752496004,
            -0.013379906304180622,
            0.0027201531920582056,
            -0.0076581253670156,
            -0.008218523114919662,
            -0.026582371443510056,
            -0.0013802628964185715,
            0.015336088836193085,
            -0.0026573215145617723,
            0.022086940705776215,
            0.008449643850326538,
            0.02182878740131855,
            0.055472481995821,
            0.015026653185486794,
            -0.020368866622447968,
            -0.024105584248900414,
            0.012494276277720928,
            0.04468237981200218,
            0.018327021971344948,
            0.07300595194101334,
            0.01760251075029373,
            0.031748488545417786,
            0.0015473796520382166,
            0.013841909356415272,
            -0.009552295319736004,
            -0.03539460897445679,
            0.01955917291343212,
            -0.015929678454995155,
            0.01380507554858923,
            -0.00933372974395752,
            0.014495828188955784,
            0.013776551932096481,
            0.029612334445118904,
            -0.012498716823756695,
            0.0048693520948290825,
            -0.04036200791597366,
            0.004556868225336075,
            0.025021327659487724,
            -0.06828920543193817,
            0.025594353675842285,
            0.02323261834681034,
            -0.013166832737624645,
            -0.006832593586295843,
            0.009567978791892529,
            -0.11172442883253098,
            0.010371142998337746,
            -0.01510102953761816,
            -0.036759909242391586,
            -0.015026495791971684,
            0.009821858257055283,
            0.01379008125513792,
            0.02044026553630829,
            -0.06186137720942497,
            0.0032666781917214394,
            0.004927787464112043,
            0.025362882763147354,
            -0.02777983248233795,
            -0.021738911047577858,
            -0.0009782176930457354,
            -0.004450855776667595,
            -0.013540664687752724,
            0.009758498519659042,
            -0.029392700642347336,
            -0.007879072800278664,
            -0.06291331350803375,
            -0.03996517136693001,
            0.009771501645445824,
            0.05405237153172493,
            0.0010037901811301708,
            -0.012631605379283428,
            0.019737491384148598,
            0.03247525170445442,
            -0.010079512372612953,
            -0.020799798890948296,
            -0.009605424478650093,
            -0.022681523114442825,
            -0.024536341428756714,
            -0.02418951876461506,
            -0.01396251656115055,
            0.0027981072198599577,
            -0.019577432423830032,
            -0.017328649759292603,
            0.027519825845956802,
            -0.0023461340460926294,
            -0.0019086296670138836,
            -0.003659429494291544,
            0.007194156292825937,
            -0.011393124237656593,
            0.007574245799332857,
            -0.032975099980831146,
            0.026031741872429848,
            0.04976657032966614,
            -0.012256830930709839,
            -0.019179247319698334,
            -0.03143179044127464,
            -0.021374588832259178,
            -0.002930285641923547,
            0.02855643257498741,
            0.04838225245475769,
            0.01870853640139103,
            0.03284870833158493,
            0.0040211305022239685,
            -0.003111792029812932,
            0.030479760840535164,
            0.021256837993860245,
            -0.02435806579887867,
            -0.02561655454337597,
            0.017612436786293983,
            1.093026298804034e-06,
            0.009296897798776627,
            -0.00499373022466898,
            0.007973873987793922,
            0.007502808701246977,
            -0.031066322699189186,
            -0.019696952775120735,
            -0.02921196073293686,
            -0.002273673890158534,
            -0.005583864636719227,
            -0.034365810453891754,
            0.01396927796304226,
            0.042232662439346313,
            0.02039872482419014,
            -0.02799484133720398,
            0.013754463754594326,
            -0.026259787380695343,
            -0.02255946211516857,
            0.001117565669119358,
            -0.002279791748151183,
            0.033474717289209366,
            0.041665300726890564,
            -0.04977423697710037,
            0.018032213672995567,
            0.03177512064576149,
            -0.045330122113227844,
            0.05193368345499039,
            -0.018375590443611145,
            0.02677953615784645,
            -0.003917480353266001,
            0.01940101571381092,
            -0.028384316712617874,
            0.023800889030098915,
            -0.04042321443557739,
            -0.010025225579738617,
            -0.020221568644046783,
            0.019547123461961746,
            4.299384818295948e-05,
            -0.039784643799066544,
            -0.009618179872632027,
            -0.023877983912825584,
            -0.009290419518947601,
            0.024400796741247177,
            0.015001424588263035,
            -0.0008647410431876779,
            0.0226869136095047,
            -0.018663853406906128,
            0.01492072083055973,
            0.0018092009704560041,
            -0.011487871408462524,
            0.027554722502827644,
            0.009313571266829967,
            -0.015634555369615555,
            -0.010097749531269073,
            -0.03376013785600662,
            -0.02615348994731903,
            -0.030953289940953255,
            0.025921085849404335,
            -0.009194817394018173,
            0.009784911759197712,
            0.0024705815594643354,
            0.0005659730522893369,
            -0.005736194550991058,
            0.004369172267615795,
            -0.04088062793016434,
            -0.013699749484658241,
            -0.028840260580182076,
            0.02872915379703045,
            -0.048916928470134735,
            0.019364168867468834,
            0.010918479412794113,
            -0.02666383795440197,
            0.019726088270545006,
            0.02634025551378727,
            0.013632415793836117,
            -0.035024311393499374,
            0.03783620148897171,
            -0.00011226751666981727,
            -0.010611982084810734,
            -0.014576461166143417,
            -0.006669397000223398,
            -0.043987903743982315,
            0.003630749648436904,
            0.011411622166633606,
            -0.022459113970398903,
            0.022626658901572227,
            0.03978104516863823,
            -0.03705591708421707,
            0.009520311839878559,
            0.028888234868645668,
            0.04718035086989403,
            -0.010944507084786892,
            0.021275319159030914,
            -0.016478637233376503,
            -0.024392180144786835,
            -0.03039815090596676,
            0.0168552678078413,
            0.0027409743051975965,
            0.011666037142276764,
            0.03846640884876251,
            0.03738608583807945,
            0.023562131449580193,
            -0.006658269092440605,
            0.02166224829852581,
            -0.021332979202270508,
            0.0023571234196424484,
            0.01719042658805847,
            -0.020307792350649834,
            -0.025079352781176567,
            -0.019332798197865486,
            -0.03538643196225166,
            -0.04588855803012848,
            -0.006362958811223507,
            -0.019799156114459038,
            0.017674466595053673,
            -0.01843436434864998,
            -0.01217204425483942,
            0.017983853816986084,
            0.04001099243760109,
            0.000768172787502408,
            0.019417624920606613,
            0.025676213204860687,
            0.019156239926815033,
            -0.021319955587387085,
            -0.015627285465598106,
            -0.021586408838629723,
            0.009632437489926815,
            0.003863598220050335,
            -0.023833898827433586,
            0.025490302592515945,
            0.012511628679931164,
            -0.02477770671248436,
            0.01117777731269598,
            0.03190254792571068,
            -0.01321891788393259,
            0.011751358397305012,
            -0.008236481808125973,
            -0.04211614280939102,
            -0.020860178396105766,
            -0.03519999980926514,
            0.0189866553992033,
            -0.03114648535847664,
            0.004856267012655735,
            0.0004086077387910336,
            0.008641542866826057,
            -0.03001607209444046,
            0.001931381644681096,
            -0.014089656993746758,
            0.025013048201799393,
            -0.07008755952119827,
            0.02027100697159767,
            -0.010650714859366417,
            -0.018391184508800507,
            0.020316312089562416,
            -0.01152743585407734,
            0.034715358167886734,
            -0.02664540335536003,
            -0.029470540583133698,
            0.01952909119427204,
            0.03554090857505798,
            0.02101518213748932,
            0.00855253916233778,
            -0.025848153978586197,
            -0.01721274107694626,
            0.04173512011766434,
            -0.03519052267074585,
            -0.024772120639681816,
            0.024359166622161865,
            -0.01920231804251671,
            0.008520854637026787,
            -0.012110116891562939,
            -0.03831736743450165,
            -0.015076111070811749,
            0.006048098672181368,
            -0.020486807450652122,
            -0.04139822721481323,
            -0.04224644973874092,
            0.029981503263115883,
            -0.027479708194732666,
            0.018388083204627037,
            0.02198481187224388,
            -0.005295893177390099,
            0.010563939809799194,
            -0.01751936599612236,
            -0.005176512058824301,
            -8.780936695984565e-06,
            0.01864517666399479,
            -0.02779533714056015,
            -0.0031220109667629004,
            -0.035433679819107056,
            -0.06932057440280914,
            -0.024967072531580925,
            -0.027629783377051353,
            0.009887897409498692,
            0.02923431806266308,
            0.013098478317260742,
            0.004250949714332819,
            0.017369870096445084,
            -0.022227536886930466,
            -0.0016758479177951813,
            0.016873333603143692,
            -0.0005585456383414567,
            0.02285008132457733,
            0.010781624354422092,
            -0.017441624775528908,
            0.08823004364967346,
            -0.0030388974118977785,
            0.007695138454437256,
            0.007363291922956705,
            -0.021957479417324066,
            -0.004799478687345982,
            0.013894117437303066,
            -0.0043973540887236595,
            0.020424870774149895,
            0.016076279804110527,
            0.027248119935393333,
            -0.015126291662454605,
            0.02875615283846855,
            0.006301830988377333,
            0.034283701330423355,
            -0.015538684092462063,
            0.011274253018200397,
            -0.03469110280275345,
            -0.004760072100907564,
            0.012159088626503944,
            0.013676258735358715,
            -0.02028612233698368,
            -0.019451722502708435,
            0.019365547224879265,
            -0.010387666523456573,
            -0.0047558327205479145,
            -0.008301202207803726,
            0.014920628629624844,
            0.0018352944171056151,
            -0.0200864989310503,
            -0.00859710294753313,
            0.01843058131635189,
            0.023355884477496147,
            0.025592073798179626,
            -0.005042459815740585,
            0.003959420137107372,
            0.0480927936732769,
            -0.011381168849766254,
            0.02250542677938938,
            0.023745212703943253,
            -0.0567709282040596,
            -0.01570369116961956,
            -0.022085580974817276,
            -0.01334332674741745,
            0.0462597981095314,
            0.04066000133752823,
            0.0027305891271680593,
            -0.022336145862936974,
            -0.035662274807691574,
            0.04901557415723801,
            -0.004177065100520849,
            0.0034891776740550995,
            0.002668727422133088,
            -0.009074016474187374,
            -0.002593842102214694,
            0.04000864923000336,
            0.0345296710729599,
            -0.04623322933912277,
            -0.02398422360420227,
            0.0030564097687602043,
            -0.03786313533782959,
            0.051686979830265045,
            0.020826727151870728,
            -0.001526327570900321,
            -0.032373957335948944,
            -0.016617964953184128,
            -0.014592362567782402,
            -0.07941906899213791,
            -0.011236627586185932,
            0.004509739577770233,
            -0.016138898208737373,
            -0.020061755552887917,
            -0.007216448895633221,
            -0.006970441900193691,
            -0.015443796291947365,
            -0.0038683589082211256,
            -0.008904877118766308,
            0.022258995100855827,
            0.009236006997525692,
            -0.011637886054813862,
            0.017564158886671066,
            0.003039068076759577,
            -0.05390191823244095,
            0.021284256130456924,
            -0.05679396167397499,
            0.023883838206529617,
            0.039005208760499954,
            -0.021522900089621544,
            0.005642900243401527,
            0.03244127333164215,
            -0.01283029094338417,
            -0.020001161843538284,
            -0.038195230066776276,
            0.021921897307038307,
            0.01310165785253048,
            0.04418284073472023,
            -0.01391148567199707,
            0.04608197882771492,
            -0.04086749628186226,
            -0.0038630980998277664,
            0.016507338732481003,
            0.036339081823825836,
            0.038392726331949234,
            -0.004569669719785452,
            0.02466299571096897,
            -0.05647633597254753,
            -0.013493823818862438,
            -0.05862659215927124,
            0.005216273944824934,
            0.010056007653474808,
            -0.002035820158198476,
            -0.013945207931101322,
            -0.05781121924519539,
            0.01173300389200449,
            0.013440720736980438,
            -0.00048248362145386636,
            0.014855986461043358,
            0.023005222901701927,
            -0.040470290929079056,
            -0.002851491328328848,
            -0.07467824220657349,
            0.02383476123213768,
            0.030115025117993355,
            0.01149029191583395,
            -0.061492983251810074,
            0.016531750559806824,
            0.03482672572135925,
            -0.02955005131661892,
            -0.0010247690370306373,
            -0.024165626615285873,
            -0.08324982970952988,
            0.021422019228339195,
            0.011402789503335953,
            -0.012034292332828045,
            0.03762197494506836,
            0.002571882214397192,
            0.04525516554713249,
            0.005369197111576796,
            -0.017880026251077652,
            -0.02594263292849064,
            0.031592562794685364,
            -0.005433293059468269,
            -0.013866915367543697,
            0.0006554087740369141,
            0.013633213937282562,
            -0.00939515046775341,
            0.0009611384593881667,
            0.03309689089655876,
            -0.030007973313331604,
            -0.013758594170212746,
            0.027094047516584396,
            0.004823456518352032,
            -0.013696201145648956,
            0.03339725732803345,
            -0.009559037163853645,
            0.020987652242183685,
            0.0030812991317361593,
            -0.0059272535145282745,
            0.00791105441749096,
            -0.013712729327380657,
            0.016913671046495438,
            0.02464224025607109,
            -0.0017281270120292902,
            0.015558981336653233,
            0.0256706140935421,
            -0.0017572117503732443,
            -0.012268505990505219,
            -0.05753416568040848,
            -0.035363320261240005,
            -0.014138779602944851,
            0.020863724872469902,
            -0.031346891075372696,
            0.013882872648537159,
            0.02774694561958313,
            0.0035100255627185106,
            0.006432992871850729,
            0.0010376694845035672,
            0.0036401983816176653,
            0.027846336364746094,
            -0.0025540306232869625,
            -0.016677340492606163,
            -0.021083058789372444,
            -0.06368324160575867,
            -0.028412392362952232,
            0.03286339342594147,
            -0.06306638568639755,
            0.007589778862893581,
            -0.020606575533747673,
            0.0022646982688456774,
            -0.007560019381344318,
            -0.019665949046611786,
            0.03440585732460022,
            -0.01094143744558096,
            0.01427629217505455,
            -0.025841033086180687,
            -0.024742931127548218,
            -0.02608787827193737,
            -0.016295060515403748,
            -0.011930239386856556,
            -0.013378961011767387,
            -0.024146804586052895,
            0.003816361306235194,
            0.024938898161053658,
            0.01708807237446308,
            0.005025969818234444,
            -0.05189070105552673,
            0.023138411343097687,
            -0.08316677063703537,
            0.010879186913371086,
            0.012469652108848095,
            0.021099548786878586,
            -0.02525966614484787,
            0.015099382027983665,
            0.010731890797615051,
            -0.024204915389418602,
            -0.005373077932745218,
            0.0017172462539747357,
            -0.03495854139328003,
            0.012481442652642727,
            0.039783041924238205,
            -0.01233053021132946,
            -0.016937563195824623,
            0.009896048344671726,
            0.0344284288585186,
            -0.04489123076200485,
            -0.03460711985826492,
            -0.020526358857750893,
            -0.004936304874718189,
            0.01555111724883318,
            0.011421553790569305,
            -0.0128262247890234,
            0.011233074590563774,
            -0.020900607109069824,
            -0.01578488014638424,
            0.03707604482769966,
            -0.02935362607240677,
            -0.015858516097068787,
            -0.02326565608382225,
            -0.03708774596452713,
            0.027376320213079453,
            0.010724231600761414,
            -0.009195067919790745,
            0.029580703005194664,
            -0.006256474182009697,
            0.019120829179883003,
            0.02429814822971821,
            0.020199531689286232,
            -0.02809949964284897,
            0.035613302141427994,
            -0.01151387207210064,
            -0.0065871234983205795,
            -0.024775180965662003,
            -0.022184139117598534,
            -0.0635356679558754,
            0.016987578943371773,
            -0.035530056804418564,
            -0.007093578111380339,
            0.005373032763600349,
            -0.00296210078522563,
            0.007604563608765602,
            0.03902668133378029,
            -0.0059827277436852455,
            -0.030014092102646828,
            -0.00850894395262003,
            0.038513243198394775,
            -0.009230786003172398,
            0.008734333328902721
        ],
        [
            -0.016170909628272057,
            0.030960440635681152,
            -0.029282040894031525,
            -0.04104004055261612,
            -0.04092846065759659,
            -0.0357852578163147,
            0.0028249064926058054,
            -0.06490675359964371,
            0.002941387938335538,
            -0.04091167077422142,
            0.029297446832060814,
            -0.0007303694146685302,
            -0.08189253509044647,
            -0.033449336886405945,
            -0.011759700253605843,
            0.030117187649011612,
            -0.08040077239274979,
            0.028880715370178223,
            -0.01881231740117073,
            -0.039455071091651917,
            -0.005165036302059889,
            -0.02532866783440113,
            -0.012689465656876564,
            -0.05876076593995094,
            0.00833148043602705,
            -0.04351970925927162,
            0.01667504943907261,
            -0.023103218525648117,
            -0.012121377512812614,
            0.01770896464586258,
            0.0011080653639510274,
            0.008845653384923935,
            -0.034103017300367355,
            0.021427631378173828,
            -0.007739346474409103,
            -0.08300672471523285,
            0.03491669520735741,
            0.0036214415449649096,
            0.039937280118465424,
            0.0027052913792431355,
            0.027698339894413948,
            -0.023695960640907288,
            0.0037595226895064116,
            0.03319652006030083,
            -0.0046783494763076305,
            0.003256307914853096,
            -0.026210306212306023,
            0.04425346106290817,
            0.008839349262416363,
            0.05833519995212555,
            -0.01913348026573658,
            0.005726777017116547,
            0.009061235003173351,
            -0.018207943066954613,
            0.011612272821366787,
            -0.04810411483049393,
            -0.026137344539165497,
            -0.03943292424082756,
            -0.03477104753255844,
            0.03588423505425453,
            0.024961277842521667,
            -0.009409129619598389,
            0.02082548476755619,
            0.03726448118686676,
            0.005504388362169266,
            0.028339605778455734,
            -0.024929728358983994,
            0.014785298146307468,
            0.027259081602096558,
            0.03267676383256912,
            -0.006773438770323992,
            0.002212062943726778,
            -0.038898274302482605,
            -0.01283290795981884,
            0.030399298295378685,
            -0.011723286472260952,
            -0.019205667078495026,
            -0.03005019761621952,
            -0.027150047942996025,
            -0.05083071440458298,
            -0.05054745078086853,
            0.03761536628007889,
            -0.045014314353466034,
            -0.024048734456300735,
            -0.012777581810951233,
            0.026568984612822533,
            -0.0032021047081798315,
            0.027441244572401047,
            -0.0068080853670835495,
            -0.028085120022296906,
            -1.3261109415907413e-05,
            0.01799274981021881,
            -0.012469704262912273,
            0.05827072262763977,
            0.007369854021817446,
            -0.009140484035015106,
            -0.01754298061132431,
            -0.007366985082626343,
            -0.06847643107175827,
            0.008016400970518589,
            0.026618465781211853,
            0.024662530049681664,
            0.029952004551887512,
            0.030458811670541763,
            0.000908697780687362,
            0.013620439916849136,
            -0.003935927525162697,
            0.01590706780552864,
            -0.033942174166440964,
            0.021301666274666786,
            -0.00043348458711989224,
            0.013239361345767975,
            -0.006314502097666264,
            -0.008966069668531418,
            -0.013886524364352226,
            -0.03967364504933357,
            0.019536152482032776,
            0.01617140881717205,
            0.00644811661913991,
            -0.020953120663762093,
            -0.0019767398480325937,
            -0.03449087589979172,
            0.025677761062979698,
            -0.03024037927389145,
            -0.004002988804131746,
            -0.024741800501942635,
            0.004500428214669228,
            -0.025580264627933502,
            0.0006377645186148584,
            0.04367750510573387,
            -0.04122687876224518,
            0.0063858903013169765,
            -0.048530444502830505,
            0.034048307687044144,
            0.005158694926649332,
            -0.046418603509664536,
            -0.004751760978251696,
            0.003897868562489748,
            -0.031586386263370514,
            0.022746805101633072,
            -0.01790439896285534,
            -0.0021944865584373474,
            -0.01831631176173687,
            -0.016572942957282066,
            0.043241050094366074,
            -0.016971711069345474,
            0.02101512812077999,
            0.04719935730099678,
            0.0021905426401644945,
            -0.006667668465524912,
            0.023813463747501373,
            0.06125659868121147,
            -0.054666463285684586,
            -0.02899346686899662,
            -0.018380731344223022,
            -0.0033822967670857906,
            0.01643717847764492,
            -0.026459001004695892,
            -0.028867248445749283,
            0.029994262382388115,
            -0.011774829588830471,
            -0.025034574791789055,
            -0.00608227401971817,
            0.008158477954566479,
            0.025382399559020996,
            0.011187516152858734,
            -0.02420813962817192,
            0.0016870651161298156,
            -0.07100869715213776,
            -0.025224890559911728,
            0.011452026665210724,
            -0.019537337124347687,
            -0.07677680999040604,
            -0.005401269532740116,
            -0.010111358016729355,
            0.028322089463472366,
            0.017439287155866623,
            0.036762893199920654,
            -0.003102273214608431,
            -0.029977897182106972,
            0.06576637178659439,
            -0.008479108102619648,
            0.00868185143917799,
            0.04209108278155327,
            -0.030030570924282074,
            -0.010088110342621803,
            0.03981738165020943,
            0.013180066831409931,
            0.000246356736170128,
            0.013596758246421814,
            -0.002787856850773096,
            0.016701435670256615,
            -0.04279646277427673,
            0.01988375559449196,
            -0.021455632522702217,
            0.004125890322029591,
            -0.0030997551511973143,
            -0.006115024443715811,
            0.01919066533446312,
            0.033900439739227295,
            -0.013381747528910637,
            -0.054661259055137634,
            -0.03265593945980072,
            0.019462643191218376,
            -0.028005624189972878,
            -0.023086806759238243,
            -0.004800146445631981,
            0.03562658652663231,
            -0.005721752997487783,
            0.0195600725710392,
            0.01488325372338295,
            0.017244480550289154,
            0.03806496039032936,
            -0.04375350475311279,
            0.031185297295451164,
            0.03305383399128914,
            0.012670252472162247,
            0.026036622002720833,
            -0.0455193966627121,
            0.0025912791024893522,
            0.03698878362774849,
            0.04479217901825905,
            0.0012326746946200728,
            0.0037678831722587347,
            -0.04384393244981766,
            0.04208869859576225,
            0.02845500223338604,
            -0.009524693712592125,
            0.035996031016111374,
            0.015985919162631035,
            0.016663286834955215,
            -0.05497536435723305,
            0.018901187926530838,
            0.02420576848089695,
            0.01144534070044756,
            -0.01905311457812786,
            -0.021062837913632393,
            -0.048536255955696106,
            0.007711224257946014,
            -0.006920522544533014,
            0.00780341774225235,
            -0.01575796864926815,
            -0.021263018250465393,
            -0.004691984970122576,
            0.01394855696707964,
            -0.0034727733582258224,
            0.028332654386758804,
            0.0024315190967172384,
            -0.020394306629896164,
            -0.0036610979586839676,
            -0.023741863667964935,
            0.02809792198240757,
            -0.11596747487783432,
            -0.030823467299342155,
            0.001301401760429144,
            0.0236427653580904,
            -0.0026128203608095646,
            0.047223154455423355,
            0.0026979995891451836,
            0.03724862262606621,
            0.04187662526965141,
            -0.037377916276454926,
            -0.05017191544175148,
            -0.026277560740709305,
            -0.008763068355619907,
            -0.05273207277059555,
            -0.008693949319422245,
            0.000603973981924355,
            -0.023906759917736053,
            -0.03264772891998291,
            -0.017689282074570656,
            0.013676080852746964,
            0.006335739511996508,
            0.024882426485419273,
            0.0001987336145248264,
            -0.03261265903711319,
            0.05117667093873024,
            -0.02802431769669056,
            0.001023494522087276,
            0.030459444969892502,
            -0.025320127606391907,
            0.02555248700082302,
            -0.050782062113285065,
            0.03134667128324509,
            -0.049151789397001266,
            0.026750437915325165,
            -0.012086588889360428,
            0.04533196613192558,
            -0.04401955008506775,
            -0.00012377914390526712,
            -0.008540784940123558,
            -0.02091672271490097,
            0.0023418981581926346,
            0.013496600091457367,
            0.019464338198304176,
            0.0036435367073863745,
            -0.016086429357528687,
            0.009438972920179367,
            -0.04308784008026123,
            0.01205221563577652,
            -0.0048204450868070126,
            0.030832765623927116,
            -0.02446073852479458,
            -0.05407579243183136,
            -0.03856150433421135,
            -0.0037114641163498163,
            -0.013935187831521034,
            0.04041949659585953,
            0.045694854110479355,
            -0.008204015903174877,
            0.022511180490255356,
            0.005360045004636049,
            -0.007568103261291981,
            0.016520660370588303,
            0.028817351907491684,
            0.02980397269129753,
            0.0026295112911611795,
            0.0022109365090727806,
            -0.03804755210876465,
            -0.024987580254673958,
            0.015523913316428661,
            0.05413663759827614,
            0.0743207111954689,
            -0.06703469157218933,
            -0.006199005991220474,
            0.04494569078087807,
            -0.04693009331822395,
            0.029032500460743904,
            -0.038412950932979584,
            0.013792410492897034,
            0.0306087713688612,
            0.015519397333264351,
            -0.046074967831373215,
            -0.006876273546367884,
            0.03095855936408043,
            -0.043442610651254654,
            0.025338493287563324,
            0.02009972184896469,
            -0.027136098593473434,
            0.02583342418074608,
            -0.0006395297823473811,
            0.0012259839568287134,
            0.013650697655975819,
            -0.01283459085971117,
            -0.006229897495359182,
            -0.016248619183897972,
            0.03725570812821388,
            0.009738403372466564,
            -0.033829402178525925,
            0.023087548092007637,
            0.029086323454976082,
            0.019957637414336205,
            0.03514554351568222,
            -0.010666018351912498,
            0.034021101891994476,
            -0.015884364023804665,
            -0.008733747527003288,
            -0.010022994130849838,
            -0.000344111438607797,
            -0.03182448074221611,
            -0.013375258073210716,
            -0.03480064123868942,
            0.05792272835969925,
            0.03282558545470238,
            0.03363463655114174,
            -0.01927565410733223,
            0.021671941503882408,
            -0.020637283101677895,
            0.030995672568678856,
            0.01375537272542715,
            0.0034023672342300415,
            -0.02408946491777897,
            -0.023535287007689476,
            0.027284888550639153,
            -0.018784446641802788,
            0.038812726736068726,
            0.036276575177907944,
            0.013377435505390167,
            0.015351714566349983,
            -0.013540558516979218,
            0.024337276816368103,
            -0.03874998167157173,
            -0.012640216387808323,
            -0.006556591019034386,
            -0.015228554606437683,
            0.00252793962135911,
            0.019646037369966507,
            -0.01799768954515457,
            0.03483353182673454,
            -0.06186022609472275,
            -0.0005728669930249453,
            0.009071046486496925,
            -0.06180344149470329,
            0.015918686985969543,
            -0.010315580293536186,
            0.04062962159514427,
            -0.011508602648973465,
            0.03416368365287781,
            0.02358027547597885,
            0.03207867965102196,
            -0.016437239944934845,
            -0.02257966622710228,
            -0.02803550474345684,
            0.02391953580081463,
            0.022459866479039192,
            0.009678898379206657,
            -0.03509175404906273,
            -0.025014715269207954,
            0.011815543286502361,
            0.01688157580792904,
            -0.06659000366926193,
            -0.029327725991606712,
            -0.002997670555487275,
            -0.0419144481420517,
            -0.011354761198163033,
            -0.006207338068634272,
            -0.0016946889227256179,
            0.005966374650597572,
            -0.05442174896597862,
            -0.00989502016454935,
            -0.007753386627882719,
            -0.0442458800971508,
            0.07638506591320038,
            0.004112315364181995,
            -0.019084511324763298,
            -0.07839270681142807,
            -0.03505384176969528,
            0.05122910067439079,
            0.013612796552479267,
            -0.03202417492866516,
            -0.008136539719998837,
            0.01902402751147747,
            0.004241708666086197,
            0.06848449259996414,
            0.0011412437306717038,
            0.04694165289402008,
            -0.005583303514868021,
            -0.004265987779945135,
            0.013751465827226639,
            0.004524394404143095,
            0.0324886217713356,
            -0.03594702482223511,
            0.014975866302847862,
            -0.049345243722200394,
            -0.018386688083410263,
            -0.04411182180047035,
            -0.030293406918644905,
            -0.01120559312403202,
            -0.04096366837620735,
            0.006239741109311581,
            0.0336771123111248,
            -0.0396258682012558,
            -0.016629602760076523,
            0.008765260688960552,
            0.04375225305557251,
            0.0035334634594619274,
            0.01916230097413063,
            0.03921133652329445,
            0.03868213668465614,
            -0.005685222335159779,
            -0.006491636857390404,
            0.043435465544462204,
            -0.04052175581455231,
            0.021011151373386383,
            0.012434659525752068,
            0.009446078911423683,
            0.03276951611042023,
            0.04158046469092369,
            -0.020992465317249298,
            -0.01819666661322117,
            0.0387033075094223,
            -0.015869086608290672,
            -0.03391282632946968,
            0.00928692240267992,
            -0.003160020336508751,
            -0.03506748005747795,
            -0.026680098846554756,
            0.009345917031168938,
            -0.03924121707677841,
            0.013343799859285355,
            0.02639714628458023,
            -0.040815673768520355,
            -0.0723666101694107,
            0.018342599272727966,
            0.04284769669175148,
            0.04004264250397682,
            -0.014175995253026485,
            -0.04174293577671051,
            -0.059558793902397156,
            -0.03937842696905136,
            -0.019927892833948135,
            -0.021224530413746834,
            0.004169716965407133,
            -0.03787750005722046,
            0.017437070608139038,
            0.018773064017295837,
            -0.0017085479339584708,
            0.005897553637623787,
            0.014283698983490467,
            -0.012362931855022907,
            0.015522615052759647,
            0.021746695041656494,
            -0.014830376021564007,
            -0.024974007159471512,
            0.04357427358627319,
            -0.03214043006300926,
            -0.04154140129685402,
            0.008760379627346992,
            0.008172506466507912,
            0.004399755969643593,
            0.014773650094866753,
            -0.029998017475008965,
            -0.035662148147821426,
            -0.01388302817940712,
            0.0007284084567800164,
            0.012492993846535683,
            0.03337480500340462,
            0.022960003465414047,
            -0.009660763666033745,
            0.017770204693078995,
            0.002238300396129489,
            0.02492723986506462,
            0.04001251608133316,
            -0.021915540099143982,
            0.007194226607680321,
            0.003516624914482236,
            -0.059124238789081573,
            0.003606099635362625,
            0.014401583932340145,
            -0.02093941904604435,
            0.026003174483776093,
            -0.07518219947814941,
            0.03870851919054985,
            0.010052459314465523,
            -0.015876444056630135,
            -0.010271440260112286,
            -0.01799512654542923,
            -0.021187180653214455,
            -0.037068188190460205,
            0.020749874413013458,
            0.004452797118574381,
            -0.012279090471565723,
            0.021281762048602104,
            0.0510309636592865,
            -0.00290985731408,
            0.026377202942967415,
            -0.021096741780638695,
            -0.06952609866857529,
            -0.035881683230400085,
            0.04262707009911537,
            0.006153370253741741,
            -0.021207163110375404,
            -0.00592683395370841,
            0.013851107098162174,
            -0.07607722282409668,
            -0.01813533902168274,
            -0.0036718088667839766,
            -0.01235789805650711,
            0.02170833759009838,
            0.009777568280696869,
            -0.029301544651389122,
            -0.026982329785823822,
            -0.028636611998081207,
            0.030677448958158493,
            -0.00023756803420837969,
            0.014918246306478977,
            -0.005328408442437649,
            0.01449337787926197,
            0.01670437678694725,
            -0.05712977424263954,
            -0.005640205927193165,
            -1.586186840540904e-06,
            -0.007708740420639515,
            0.028665168210864067,
            -0.05209311842918396,
            0.011405217461287975,
            -0.003116174601018429,
            -0.020474692806601524,
            -0.018722213804721832,
            -0.00027568195946514606,
            0.013919610530138016,
            -0.019100148230791092,
            -0.0012226790422573686,
            0.046817656606435776,
            -0.012182090431451797,
            0.027190733700990677,
            0.00010421573097119108,
            -0.026389673352241516,
            -0.023313486948609352,
            0.00806746631860733,
            -0.007960730232298374,
            -0.03476458787918091,
            0.02717052772641182,
            0.013292687945067883,
            0.027566494420170784,
            0.018263239413499832,
            0.015580111183226109,
            0.026527252048254013,
            0.04187025874853134,
            -0.03870676830410957,
            -0.024394391104578972,
            -0.021141981706023216,
            0.055140867829322815,
            -0.04554448649287224,
            -0.004717962350696325,
            -0.0383470319211483,
            -0.0410909466445446,
            0.001166812377050519,
            -0.008646314963698387,
            -0.014525887556374073,
            0.0036703613586723804,
            -0.02168866991996765,
            0.024116868153214455,
            -0.021060602739453316,
            0.025447171181440353,
            -0.05705622211098671,
            -0.006190051324665546,
            0.006279380060732365,
            -0.012371737509965897,
            -0.02521425113081932,
            -0.0008615715196356177,
            -0.009532701224088669,
            0.000699315220117569,
            0.010600871406495571,
            0.006785256788134575,
            -0.042069293558597565,
            0.01687566004693508,
            -0.0385303758084774,
            -9.006456821225584e-05,
            0.033646050840616226,
            0.01125798188149929,
            0.014593441970646381,
            -0.03698628395795822,
            -0.019443262368440628,
            0.011663519777357578,
            -0.044666338711977005,
            0.005707520991563797,
            0.026108363643288612,
            0.026934687048196793,
            0.009682483971118927,
            0.03484589233994484,
            0.0057616084814071655,
            -0.034466955810785294,
            0.005183808039873838,
            0.012645035982131958,
            -0.0483204610645771,
            0.020621128380298615,
            -0.010520382784307003,
            -0.08023275434970856,
            0.006077625788748264,
            0.009234520606696606,
            0.025923222303390503,
            -0.02336365357041359,
            -0.012970595620572567,
            -0.011199548840522766,
            -0.030295826494693756,
            -0.0392669141292572,
            -0.002979101613163948,
            -0.021030524745583534,
            0.01071702130138874,
            -0.008686251007020473,
            -0.025214651599526405,
            -0.028649093583226204,
            -0.009007235057651997,
            -0.013349656015634537,
            0.02305181697010994,
            0.01951625570654869,
            -0.009985323064029217,
            0.010721931234002113,
            -0.012914767488837242,
            -0.05059603974223137,
            0.02672230824828148,
            0.02892129123210907,
            0.05331670865416527,
            -0.013065936975181103,
            0.05907653272151947,
            0.01227350253611803,
            -0.03251035511493683,
            0.026950117200613022,
            -0.0028345438186079264,
            0.023795712739229202,
            0.04144184663891792,
            0.007784668356180191,
            -0.004180308897048235,
            0.028603320941329002,
            -0.002994973910972476,
            -0.04152452573180199,
            0.018064996227622032,
            -0.019062349572777748,
            -0.06650874018669128,
            -0.045588213950395584,
            0.022177180275321007,
            -0.02280323952436447,
            -0.0295452568680048,
            0.00894667487591505,
            -0.04593486711382866,
            -0.023649178445339203,
            0.0063843191601336,
            0.02807924710214138,
            -0.049896128475666046,
            -0.016158100217580795,
            0.03232962638139725,
            -0.033702198415994644,
            -0.020857028663158417,
            0.023309405893087387,
            -0.06675446778535843,
            -0.002721315249800682,
            -0.02965632453560829,
            -0.00904989242553711,
            -0.03213685750961304,
            0.015229932963848114,
            -0.023632869124412537,
            -0.04256931692361832,
            0.01638856530189514,
            -0.0038828880060464144,
            -0.020787864923477173,
            -0.05454490706324577,
            0.014719211496412754,
            0.012907631695270538,
            -0.015255473554134369,
            0.02476942166686058,
            -0.0029374880250543356,
            -0.01997414417564869,
            -0.017010975629091263,
            -0.026514330878853798,
            -0.026081018149852753,
            -0.025036582723259926,
            -0.004933997057378292,
            -0.04387651011347771,
            0.019659150391817093,
            -0.008662601932883263,
            0.028480375185608864,
            -0.032692551612854004,
            -0.03891238197684288,
            0.011110475286841393,
            0.04428575187921524,
            0.014633171260356903,
            -0.0029970102477818727,
            0.05574654042720795,
            -0.011725914664566517,
            -0.012125476263463497,
            -0.029603349044919014,
            -0.046218134462833405,
            0.03792904317378998,
            -0.025547148659825325,
            -0.059179291129112244,
            -0.05879482999444008,
            0.036669887602329254,
            -0.035375822335481644,
            -0.03785182163119316,
            -0.015393072739243507,
            -0.03145192191004753,
            0.0010921319480985403,
            0.004787830635905266,
            0.01353831309825182,
            0.024270949885249138,
            -0.04589139670133591,
            -0.03679700940847397,
            0.0026514872442930937,
            -0.014495065435767174,
            0.00512289023026824,
            -0.07598086446523666,
            0.002193959429860115,
            -0.005034394562244415,
            -0.0007368990336544812,
            -0.017754076048731804,
            -0.024800842627882957,
            -0.004580920562148094,
            -0.03372165560722351,
            -0.05610852316021919,
            -0.05432775616645813,
            0.02604776620864868,
            0.05608590692281723,
            0.04209998622536659,
            0.0009353410569019616,
            -0.0011412013554945588,
            -0.015339388512074947,
            -0.01156338769942522,
            0.01855226792395115,
            0.025188280269503593,
            -0.06057523190975189,
            -0.00469854986295104,
            0.018083497881889343,
            -0.0014039044035598636,
            0.003189508803188801,
            -4.839383109356277e-05,
            0.03802991658449173,
            0.0220529492944479,
            -0.02627607062458992,
            -0.0108365248888731,
            0.04007834568619728,
            0.002510456135496497,
            0.01652955822646618,
            -0.11207149177789688,
            0.001131381606683135,
            0.028353016823530197,
            -0.09089484065771103,
            0.021449696272611618,
            -0.01514486689120531,
            -0.03139648959040642,
            -0.01932310126721859,
            0.023650847375392914,
            -0.032332926988601685,
            0.002267856150865555,
            -0.016538206487894058,
            -0.03115111030638218,
            0.03412296995520592,
            -0.00379546033218503,
            -0.021779026836156845,
            -0.005142929498106241,
            -0.015597891993820667,
            0.031409427523612976,
            0.013468793593347073,
            0.03553469106554985,
            -0.008597529493272305,
            -0.028306804597377777,
            -0.030998606234788895,
            0.009277024306356907,
            -0.016365893185138702,
            -0.019819878041744232,
            -0.028157871216535568,
            0.024764209985733032,
            -0.03851061314344406,
            0.04665351286530495,
            0.017486821860074997,
            0.01943507231771946,
            0.040107931941747665,
            -0.017884166911244392,
            -0.024882562458515167,
            0.020888227969408035,
            0.028465794399380684,
            -0.0024237544275820255,
            -0.013296744786202908,
            0.008442350663244724,
            -0.05052775889635086,
            0.02583330310881138,
            0.026331432163715363,
            -0.004038183484226465,
            -0.028416747227311134,
            0.028276076540350914,
            -0.004357249476015568,
            -0.05709712579846382,
            -0.02932213619351387,
            0.029132099822163582,
            -0.02096383273601532,
            -0.03488108515739441,
            0.010846784338355064,
            -0.007702297065407038,
            -0.00250036409124732,
            -0.03987755998969078,
            -0.0005138712585903704,
            0.04699907824397087,
            -0.016902592033147812,
            0.015427680686116219,
            0.03665480017662048,
            0.016947301104664803,
            0.01024477556347847,
            0.04812625050544739,
            0.041393984109163284,
            -0.03721687197685242,
            -0.010992641560733318,
            -0.029259195551276207,
            -0.01591738872230053,
            0.01885105110704899,
            0.02583678998053074,
            0.01788163371384144,
            -0.01805894635617733,
            -0.001163782668299973,
            -0.02226649969816208,
            -0.056375544518232346,
            0.06052478775382042,
            -0.0017713680863380432,
            0.01647143065929413,
            0.009112861938774586,
            0.026791438460350037,
            0.005524344742298126,
            0.009585834108293056,
            -0.002523283939808607,
            -0.004487780388444662,
            -0.012984772212803364,
            0.04864627495408058,
            -0.028863515704870224,
            -0.01036655344069004,
            0.040180593729019165,
            -0.07918650656938553,
            -0.043026357889175415,
            -0.0006399204139597714,
            -0.010933136567473412,
            -1.2494825568865053e-05,
            -0.02584340050816536,
            -0.01631828211247921,
            0.019593093544244766,
            0.0016990682343021035,
            -0.011350274085998535,
            0.03370038792490959,
            0.035640276968479156,
            -0.011227848939597607,
            -0.006776916328817606,
            -0.010001026093959808,
            -0.03555086627602577,
            0.005817632656544447,
            -0.015329777263104916,
            0.032738491892814636,
            -0.0040739416144788265,
            -0.032392486929893494,
            0.056399326771497726,
            -0.023002970963716507,
            -0.006652146577835083,
            0.008773038163781166,
            -0.020150771364569664,
            -0.0035718108993023634,
            -0.016262277960777283,
            0.03144625946879387,
            0.02932378649711609,
            -0.02194245345890522,
            0.011363775469362736,
            -0.02976025827229023,
            0.01748472824692726,
            0.017570102587342262,
            -0.014449668116867542,
            -0.03088686242699623,
            0.049550652503967285,
            -0.0741523876786232,
            -0.01349156815558672,
            -0.03538060933351517,
            -0.04552239552140236,
            0.029150808230042458,
            0.03469790145754814,
            -0.03579871356487274,
            -0.014014612883329391,
            0.0012047815835103393,
            -0.011103152297437191,
            -0.004815978463739157,
            -0.006609880365431309,
            -0.015654664486646652,
            -0.006578046828508377,
            -0.0243905670940876,
            -0.01072051003575325,
            -0.010262529365718365,
            -0.0342545360326767,
            0.010395878925919533,
            0.011015292257070541,
            -0.011872151866555214,
            0.03419249504804611,
            -0.0566416010260582,
            0.02744731307029724,
            0.011233141645789146,
            0.01819765195250511,
            -0.009990149177610874,
            -0.021305283531546593,
            0.01260435488075018,
            0.006344540975987911,
            0.003969068173319101,
            -0.0041313874535262585,
            -0.039573751389980316,
            -0.01686958037316799,
            -0.046656083315610886,
            0.005572224035859108,
            -0.003631447209045291,
            -0.02336999773979187,
            -0.023116132244467735,
            0.026156792417168617,
            -0.012880191206932068,
            -0.025893688201904297,
            0.012172569520771503,
            0.005212079267948866,
            -0.03969374671578407,
            0.0137650016695261,
            0.034247756004333496,
            -0.012746288441121578,
            -0.034314122051000595,
            0.0008202794706448913,
            -0.004687528591603041,
            0.03757348656654358,
            -0.004058234393596649,
            0.013680931180715561,
            -0.0033892786595970392,
            0.016041120514273643,
            -0.03164956346154213,
            -0.0011850560549646616,
            -0.029035983607172966,
            -0.02726883441209793,
            -0.002091130241751671,
            -0.017595931887626648,
            -0.005279662553220987,
            -0.039563532918691635,
            0.0005621568416245282,
            -0.0032291365787386894,
            -0.01811438612639904,
            -0.05224660411477089,
            0.01853046752512455,
            -0.03933123126626015,
            0.015513144433498383,
            0.030041050165891647,
            -0.018989022821187973,
            -0.07599226385354996,
            -0.0010331477969884872,
            -0.004774237982928753,
            0.044179417192935944,
            -0.013637777417898178,
            0.0024686474353075027,
            -0.007348428480327129,
            -0.011114913038909435,
            -0.01398999709635973,
            0.009408541023731232,
            0.01598351262509823,
            -0.05380573496222496,
            0.020305192098021507,
            0.029861358925700188,
            -0.01295524649322033,
            -0.017261845991015434,
            0.034937720745801926,
            0.0021301398519426584,
            0.0488731823861599,
            0.03521829470992088,
            -0.006941090803593397,
            -0.03663196787238121,
            0.016640521585941315,
            0.02404351532459259,
            -0.029898270964622498,
            -0.03693103790283203,
            0.03033253364264965,
            0.054942552000284195,
            -0.021619578823447227
        ],
        [
            -0.0016062671784311533,
            0.021000616252422333,
            -0.03190620243549347,
            -0.010833103209733963,
            -0.02958719991147518,
            0.025142919272184372,
            -0.0068506537936627865,
            0.010800324380397797,
            0.022955479100346565,
            0.009974452666938305,
            0.01180222351104021,
            0.016304323449730873,
            -0.0082780122756958,
            0.018736960366368294,
            -0.01740911416709423,
            -0.020305603742599487,
            0.005378664471209049,
            -0.007096241228282452,
            -0.009793995879590511,
            -0.03061176836490631,
            -0.024866774678230286,
            0.02254713699221611,
            0.004231907427310944,
            -0.022419022396206856,
            -0.027849368751049042,
            0.0062915263697505,
            -0.03145023062825203,
            -0.024527372792363167,
            -0.03070303052663803,
            -0.024078458547592163,
            -0.018452666699886322,
            -0.02720717526972294,
            -0.0040327212773263454,
            -0.015399008058011532,
            0.01371456403285265,
            -0.016383012756705284,
            0.012291540391743183,
            -0.015268427319824696,
            -0.014111978933215141,
            0.014964450150728226,
            0.025299178436398506,
            -0.02239212766289711,
            0.013247210532426834,
            -0.01022319495677948,
            0.008927006274461746,
            0.01491016335785389,
            0.024471957236528397,
            -0.020294122397899628,
            0.030100669711828232,
            -0.00492381677031517,
            -0.002315029501914978,
            -0.004642121028155088,
            -0.006345209199935198,
            -0.023790309205651283,
            0.004970341920852661,
            -0.029704757034778595,
            -0.027186203747987747,
            -0.024335497990250587,
            0.027834704145789146,
            -0.018369238823652267,
            -0.0005577586707659066,
            0.026211777701973915,
            0.010514505207538605,
            0.008576391264796257,
            -0.017693500965833664,
            -0.0039380863308906555,
            0.004997838754206896,
            0.010840889997780323,
            0.02649114839732647,
            -0.029439441859722137,
            0.026648901402950287,
            -0.020493337884545326,
            0.013161183334887028,
            0.000769070815294981,
            0.025563808158040047,
            -0.012493368238210678,
            0.01498810388147831,
            0.006176128517836332,
            -0.02781190164387226,
            -0.009833119809627533,
            0.0018370889592915773,
            0.006391110830008984,
            -0.009073569439351559,
            -0.0056641665287315845,
            -0.012661581858992577,
            -0.02943344973027706,
            0.0007346814963966608,
            -0.011531200259923935,
            -0.0026852120645344257,
            -0.030892817303538322,
            -0.0011659376323223114,
            -0.003679131157696247,
            0.01759912818670273,
            -0.0029216441325843334,
            -0.011654834263026714,
            -0.023626314476132393,
            -0.007964400574564934,
            -0.022403070703148842,
            -0.01485755667090416,
            -0.005750142503529787,
            0.01724224165081978,
            0.022230183705687523,
            -0.003849824657663703,
            -0.02619617059826851,
            0.029756734147667885,
            0.013372205197811127,
            0.00977778434753418,
            0.0001444984518457204,
            -0.014220139943063259,
            0.0019213774939998984,
            0.012848434038460255,
            -0.026289036497473717,
            0.015517989173531532,
            0.00555498618632555,
            0.02658064290881157,
            -0.0016682995483279228,
            0.006599932909011841,
            -0.023964783176779747,
            -0.02305004559457302,
            0.023765098303556442,
            0.018324220553040504,
            -0.010541832074522972,
            0.019450396299362183,
            -0.01943443901836872,
            0.002734366338700056,
            0.012573902495205402,
            0.02037050575017929,
            -0.02757679857313633,
            0.02527938410639763,
            0.0149963004514575,
            0.008443421684205532,
            0.008198551833629608,
            -0.018764354288578033,
            0.005051342770457268,
            0.016766853630542755,
            -0.005283202510327101,
            0.026324976235628128,
            -0.026237888261675835,
            0.0046407668851315975,
            -0.012214738875627518,
            -0.019241133704781532,
            -0.0075262561440467834,
            0.013197577558457851,
            0.0035336585715413094,
            0.01604589633643627,
            0.006910516414791346,
            0.01897881180047989,
            0.0017023667460307479,
            -0.031994838267564774,
            0.01927509345114231,
            0.002872447483241558,
            -0.01258277427405119,
            0.01667666807770729,
            0.02677486091852188,
            0.003054500324651599,
            0.0073032137006521225,
            0.024736911058425903,
            -0.006283111870288849,
            -0.025282664224505424,
            -0.016217129305005074,
            0.019184056669473648,
            0.01702280342578888,
            -0.016520598903298378,
            -0.025378605350852013,
            0.023271795362234116,
            0.025516223162412643,
            0.0073483046144247055,
            0.0009496328420937061,
            0.010354716330766678,
            -0.020960558205842972,
            0.027853265404701233,
            0.016636474058032036,
            0.018108703196048737,
            0.016360359266400337,
            -0.020242566242814064,
            -0.023085325956344604,
            -0.030676444992423058,
            0.01595870777964592,
            -0.026713775470852852,
            0.002206308301538229,
            0.019745169207453728,
            -0.01665489934384823,
            0.01483165193349123,
            0.0008325105882249773,
            0.003665080526843667,
            0.015528060495853424,
            0.015553537756204605,
            0.0010642963461577892,
            0.022964928299188614,
            0.0009771814802661538,
            -0.02845761552453041,
            0.019707752391695976,
            0.028485916554927826,
            -0.017556190490722656,
            0.0077649811282753944,
            -0.02328738383948803,
            -0.010977674275636673,
            -0.026999086141586304,
            -0.02662886120378971,
            0.005260077305138111,
            -0.007556052878499031,
            0.004656477365642786,
            0.01090802252292633,
            0.015730470418930054,
            0.01111997663974762,
            -0.01942254975438118,
            0.0004557474167086184,
            0.020026223734021187,
            0.01514529064297676,
            0.02898688055574894,
            -0.027950748801231384,
            0.024694077670574188,
            0.02619839645922184,
            0.007778999395668507,
            0.01400906965136528,
            0.013353417627513409,
            -0.004943480249494314,
            0.003580872667953372,
            -0.013942527584731579,
            -0.013842368498444557,
            -0.022316114977002144,
            -0.012258857488632202,
            -0.019101502373814583,
            -0.009403279982507229,
            -0.02120291069149971,
            0.023349549621343613,
            -0.024020683020353317,
            0.02492818795144558,
            -0.008036399260163307,
            -0.03237434849143028,
            -0.027479471638798714,
            -0.0035033589228987694,
            -0.010532994754612446,
            -0.006617213133722544,
            -0.031361162662506104,
            0.016229836270213127,
            0.013271781615912914,
            0.004819786176085472,
            0.016556086018681526,
            0.028388293460011482,
            0.015211139805614948,
            0.010052099823951721,
            -0.030426934361457825,
            -0.014688750728964806,
            0.025502730160951614,
            0.005008050240576267,
            -0.028400978073477745,
            0.002678333083167672,
            -0.001765618217177689,
            0.005424800794571638,
            -0.026066958904266357,
            0.020151741802692413,
            0.027693556621670723,
            -0.021783525124192238,
            0.010381857864558697,
            -0.0191813874989748,
            0.017402635887265205,
            0.02570994198322296,
            0.004603710491210222,
            0.026452112942934036,
            0.0020789639092981815,
            -0.024177351966500282,
            -0.007873293943703175,
            -0.016555583104491234,
            -0.008928687311708927,
            -0.008016729727387428,
            0.012272351421415806,
            0.014905930496752262,
            -0.0014040753012523055,
            0.005815044045448303,
            -0.0014842345844954252,
            0.011875038966536522,
            0.02105691097676754,
            -0.015477053821086884,
            0.00955691747367382,
            0.00799626111984253,
            -0.008991978131234646,
            0.008960199542343616,
            0.01619371958076954,
            -0.030452469363808632,
            -0.002428655978292227,
            0.0034706236328929663,
            0.023882098495960236,
            0.014109154231846333,
            -0.01720251329243183,
            -0.03168712183833122,
            -0.001042477786540985,
            0.001352560007944703,
            0.010125182569026947,
            -0.01781386323273182,
            -0.00798974558711052,
            0.006303391419351101,
            -0.01895984821021557,
            -0.009497477672994137,
            -0.0010835559805855155,
            0.025951731950044632,
            0.024960951879620552,
            -0.03141605854034424,
            -0.02515380084514618,
            -0.027191992849111557,
            -0.010394041426479816,
            -0.005785471294075251,
            0.02443697303533554,
            -0.0031114958692342043,
            -0.011443808674812317,
            -0.018141549080610275,
            0.009892163798213005,
            0.00614956533536315,
            -0.0015301017556339502,
            0.009013366885483265,
            -0.006602609064429998,
            -0.024966860190033913,
            0.00598426116630435,
            -0.013710195198655128,
            -0.01911998726427555,
            0.026734312996268272,
            -0.031142592430114746,
            0.015716150403022766,
            -0.002355902921408415,
            -0.03180136904120445,
            -0.02747715637087822,
            -0.012894082814455032,
            -0.02422013320028782,
            -0.0037555096205323935,
            -0.0034818758722394705,
            0.004975478630512953,
            0.017739586532115936,
            -0.005959618836641312,
            -0.011480036191642284,
            -0.014023114927113056,
            0.00832021702080965,
            0.02985525131225586,
            -0.0171313788741827,
            -0.0012459352146834135,
            -0.004177149385213852,
            0.029533538967370987,
            -0.024444809183478355,
            -0.024840423837304115,
            0.005909094586968422,
            0.010847117751836777,
            -0.01846165582537651,
            -0.00888794008642435,
            0.009998075664043427,
            0.007653980050235987,
            -0.02408735454082489,
            -0.009947661310434341,
            -0.029215529561042786,
            -0.017620649188756943,
            0.012260692194104195,
            -0.03179412707686424,
            -0.015204789116978645,
            -0.012800014577805996,
            0.02036319300532341,
            -0.013982513919472694,
            -0.02951163612306118,
            0.0025139679200947285,
            0.01743997447192669,
            -0.0012111293617635965,
            -0.0017387134721502662,
            -0.003992444835603237,
            0.003020895179361105,
            0.0006686398992314935,
            0.003249154658988118,
            0.027304314076900482,
            -0.03213119134306908,
            -0.006165720522403717,
            -0.017444968223571777,
            0.011938512325286865,
            -0.024792801588773727,
            0.008071480318903923,
            -0.007838800549507141,
            0.015211199410259724,
            0.01875228062272072,
            -0.028998520225286484,
            -0.00448573799803853,
            -0.018011802807450294,
            -0.010052387602627277,
            0.008604999631643295,
            0.028494814410805702,
            -0.013997557573020458,
            -0.02960077114403248,
            0.00026361027266830206,
            0.00835189875215292,
            -0.026066336780786514,
            -0.020423531532287598,
            0.004139941185712814,
            0.019968071952462196,
            -0.0161382295191288,
            -0.008042700588703156,
            -0.01835768111050129,
            0.008192443288862705,
            -0.015377813950181007,
            0.00474135996773839,
            -0.030566222965717316,
            0.01731772907078266,
            0.0055613587610423565,
            -0.01606610417366028,
            -0.0015403717989102006,
            0.011097931303083897,
            0.0202031247317791,
            -0.031521640717983246,
            0.012880111113190651,
            0.00882659014314413,
            -0.014863967895507812,
            -0.024836070835590363,
            -0.0199440885335207,
            -0.010987500660121441,
            0.031005118042230606,
            0.0003171444695908576,
            0.010441906750202179,
            -0.0065609607845544815,
            -0.02699700929224491,
            -0.009333173744380474,
            0.020708277821540833,
            -0.028892043977975845,
            -0.029542062431573868,
            0.01896500028669834,
            0.01889689266681671,
            -0.02588505670428276,
            0.00021781503164675087,
            -0.0023513936903327703,
            -0.026979273185133934,
            0.009721934795379639,
            -0.015835270285606384,
            0.012995637953281403,
            -0.014354217797517776,
            0.029768360778689384,
            0.014001340605318546,
            -0.003794729011133313,
            -0.0011380454525351524,
            0.007632081862539053,
            0.004538451787084341,
            0.021191498264670372,
            -0.014487138949334621,
            -0.019478250294923782,
            0.02162112668156624,
            -0.021912889555096626,
            0.027722971513867378,
            -0.019076291471719742,
            0.0032936970237642527,
            -0.012537386268377304,
            0.001374963321723044,
            0.02018236182630062,
            0.02033425122499466,
            -0.028328707441687584,
            0.029062781482934952,
            0.025022201240062714,
            -0.0004834468709304929,
            0.0266074500977993,
            0.019937338307499886,
            -0.028271161019802094,
            0.002708567539229989,
            0.012514880858361721,
            -0.0064828041940927505,
            -0.031077003106474876,
            -0.0061097159050405025,
            -0.028900548815727234,
            -0.027965562418103218,
            0.0012580272741615772,
            -0.025028809905052185,
            0.007238931953907013,
            -0.027559004724025726,
            -0.0174513291567564,
            -0.006316889077425003,
            0.007676659617573023,
            0.013650326058268547,
            -0.027503125369548798,
            -0.0033981353044509888,
            -0.01573316752910614,
            0.008208406157791615,
            -0.02279498614370823,
            0.02110350877046585,
            0.027271931990981102,
            -0.027332302182912827,
            -0.0005470177275128663,
            0.027874603867530823,
            0.009325213730335236,
            -0.023529374971985817,
            -0.018456444144248962,
            0.008615030907094479,
            -0.006608337629586458,
            -0.013724706135690212,
            0.014726590365171432,
            -0.02137192338705063,
            -0.0152596989646554,
            0.018016457557678223,
            -0.02978142909705639,
            -0.029048673808574677,
            -0.0016172818141058087,
            -0.020767362788319588,
            -0.006790296174585819,
            0.01030104048550129,
            -0.004830130375921726,
            0.01940116100013256,
            -0.0220956914126873,
            0.016748875379562378,
            0.024527324363589287,
            -0.023829415440559387,
            -0.016647163778543472,
            -0.026364628225564957,
            0.013318188488483429,
            -0.024978427216410637,
            -0.020341360941529274,
            -0.021111803129315376,
            0.01125757023692131,
            0.002291638869792223,
            -0.01434206310659647,
            0.010843262076377869,
            0.029374640434980392,
            -0.015610242262482643,
            0.021574147045612335,
            0.019580965861678123,
            0.013627841137349606,
            0.022104674950242043,
            -0.011329006403684616,
            -0.01992054283618927,
            -0.011715858243405819,
            -0.01819111593067646,
            0.014249141328036785,
            -0.01775735802948475,
            -0.020642468705773354,
            -0.0054959943518042564,
            0.011690717190504074,
            0.012588334269821644,
            0.02461393177509308,
            0.028711587190628052,
            0.026866214349865913,
            -0.004118548706173897,
            0.01596696674823761,
            0.020091280341148376,
            -0.011343076825141907,
            -0.027127476409077644,
            -0.00888785906136036,
            0.011356759816408157,
            -0.0203926432877779,
            0.02824997901916504,
            -0.008093500509858131,
            -0.022800033912062645,
            -0.028101028874516487,
            0.013955687172710896,
            0.02441653050482273,
            -0.00571644539013505,
            0.02316231280565262,
            0.017534129321575165,
            -0.0013837446458637714,
            0.027781397104263306,
            0.002131675835698843,
            -0.009513075463473797,
            -0.006266941782087088,
            -0.014586922712624073,
            -0.017450647428631783,
            -0.019572637975215912,
            0.016842465847730637,
            0.0158256646245718,
            0.02389855682849884,
            -0.015168854966759682,
            0.020655056461691856,
            -0.0190177820622921,
            0.027445461601018906,
            -0.01921897940337658,
            0.02043040096759796,
            0.026240896433591843,
            -0.003365088254213333,
            0.02343931794166565,
            0.002801873255521059,
            -0.026343505829572678,
            0.006694395560771227,
            0.02524993009865284,
            -0.006036464124917984,
            -0.027111832052469254,
            0.028676824644207954,
            -0.03225293383002281,
            0.0008769239066168666,
            -0.030922049656510353,
            0.01989557407796383,
            -0.01466257032006979,
            0.018651921302080154,
            -0.010057087056338787,
            0.022219466045498848,
            0.014398862607777119,
            -0.008268524892628193,
            0.01781659573316574,
            -0.02349838986992836,
            0.029271692037582397,
            -0.0025655541103333235,
            0.020930230617523193,
            -0.018001841381192207,
            -0.008520560339093208,
            0.005759699270129204,
            0.018193423748016357,
            -0.003442564746364951,
            -0.013898210600018501,
            0.005835171323269606,
            0.01619090512394905,
            -0.011648216284811497,
            -0.020044974982738495,
            -0.02104274183511734,
            0.028309514746069908,
            -0.0010574323823675513,
            -0.03210349753499031,
            -0.009944560006260872,
            -0.005180185195058584,
            0.02315441332757473,
            -0.001992503646761179,
            -0.003748896298930049,
            0.024218391627073288,
            -0.01827826164662838,
            -0.001527520827949047,
            -0.0023057949729263783,
            -0.02128244936466217,
            -0.01939956471323967,
            0.0013979707146063447,
            0.01285385899245739,
            -0.019958825781941414,
            0.01617749035358429,
            -0.007869970984756947,
            -0.0008431093883700669,
            0.010364470072090626,
            -0.021102217957377434,
            0.009101206436753273,
            -0.0007796764839440584,
            -0.02001870982348919,
            -0.020864974707365036,
            -0.03236134722828865,
            -0.02224109135568142,
            -0.0007037351024337113,
            0.022248078137636185,
            -0.015626993030309677,
            -0.018851157277822495,
            0.012662743218243122,
            0.011691734194755554,
            -0.007391570135951042,
            -0.01976645365357399,
            -0.03185262158513069,
            0.002034169854596257,
            -0.006184011232107878,
            0.005251198075711727,
            0.006791526451706886,
            0.0007612172630615532,
            0.009251770563423634,
            0.00769128929823637,
            -0.00457517895847559,
            -0.024625424295663834,
            -0.03152666240930557,
            0.0028764489106833935,
            -0.02843918651342392,
            0.013674149289727211,
            0.0141445342451334,
            -0.003425468225032091,
            -0.025682639330625534,
            -0.0005579155404120684,
            0.004177614115178585,
            -0.0014191239606589079,
            0.026965416967868805,
            -0.030801797285676003,
            -0.011652902700006962,
            -0.029417769983410835,
            0.0007140957750380039,
            0.02451605349779129,
            0.012727796100080013,
            0.01965472102165222,
            0.0017009779112413526,
            0.015210801735520363,
            0.023873234167695045,
            -0.019530825316905975,
            -0.0018192939460277557,
            -0.021120494231581688,
            -0.02501073107123375,
            0.006395965348929167,
            -0.01589677855372429,
            0.02602512016892433,
            0.008775732479989529,
            -0.03184652701020241,
            -0.013027916662395,
            0.005284499377012253,
            0.026249872520565987,
            0.012497988529503345,
            0.022932326421141624,
            -0.03182793781161308,
            -0.0017014446202665567,
            -0.008726978674530983,
            -0.0027648545801639557,
            -0.005344776902347803,
            0.004465822130441666,
            -0.024206887930631638,
            -0.0032012606970965862,
            -0.019194316118955612,
            0.02133089490234852,
            6.058730650693178e-05,
            -0.005090436898171902,
            0.011700781062245369,
            -0.01970304735004902,
            0.029980672523379326,
            -0.03207480162382126,
            -0.016640469431877136,
            -0.021505452692508698,
            -0.009344340302050114,
            -0.017862370237708092,
            0.005552378948777914,
            0.005857130512595177,
            -0.0005529654445126653,
            0.019719794392585754,
            -0.02319175750017166,
            -0.01631729118525982,
            -0.00576137937605381,
            -0.016758058220148087,
            0.02808343432843685,
            -0.024719923734664917,
            0.01678812876343727,
            -0.009920915588736534,
            -0.011444552801549435,
            0.012704672291874886,
            0.018943073228001595,
            -0.01489112339913845,
            -0.009480336681008339,
            -0.030917419120669365,
            -0.027303075417876244,
            -0.008963312953710556,
            -0.021237429231405258,
            0.016595203429460526,
            -0.0018945817137137055,
            0.01200233306735754,
            -0.02938901074230671,
            0.025802362710237503,
            -0.006905542686581612,
            -0.010420149192214012,
            0.013458890840411186,
            0.00030129647348076105,
            0.010487568564713001,
            0.01790197379887104,
            0.004775822628289461,
            -0.0036201756447553635,
            -0.021609880030155182,
            0.017110774293541908,
            -0.02125333622097969,
            0.021016430109739304,
            -0.03001001663506031,
            0.027485614642500877,
            0.021993957459926605,
            -0.015548205934464931,
            0.012695380486547947,
            -0.032020192593336105,
            0.021092738956212997,
            0.020933199673891068,
            0.028313811868429184,
            -0.017353111878037453,
            -0.01621021330356598,
            0.023154282942414284,
            0.01959679089486599,
            -0.026798877865076065,
            -0.017160290852189064,
            0.007192919962108135,
            -0.0015827090246602893,
            0.023721616715192795,
            0.013894992880523205,
            -0.0006081140018068254,
            0.005675936583429575,
            0.00323736434802413,
            -0.012495599687099457,
            0.02431124448776245,
            0.0032314748968929052,
            0.02902120165526867,
            0.02863042987883091,
            0.023533545434474945,
            0.011039657518267632,
            0.029719864949584007,
            0.003037855727598071,
            -0.01980980858206749,
            -0.018968885764479637,
            -0.008127286098897457,
            -0.028552228584885597,
            0.023432640358805656,
            -0.01602645218372345,
            -0.024731894955039024,
            -0.002163558965548873,
            0.026888437569141388,
            -0.030669914558529854,
            0.016246676445007324,
            -0.020195607095956802,
            -0.019456801936030388,
            0.014775804243981838,
            -0.006570280995219946,
            0.01187567412853241,
            0.015590190887451172,
            -0.032747432589530945,
            -0.004314695950597525,
            0.005073083098977804,
            0.011671111918985844,
            -0.02878527343273163,
            0.009567889384925365,
            0.0016800290904939175,
            -0.007050164509564638,
            -0.02796143852174282,
            0.020956452935934067,
            -0.011771861463785172,
            -0.0048720031045377254,
            -0.002977989614009857,
            0.023655366152524948,
            0.012167253531515598,
            -0.027167823165655136,
            -0.02458551526069641,
            -0.005929936189204454,
            -0.012267266400158405,
            0.009378445334732533,
            -0.03024810180068016,
            -0.015346542932093143,
            -0.012868802063167095,
            0.008145704865455627,
            -0.022559819743037224,
            -0.005115634761750698,
            0.028444577008485794,
            -0.023786382749676704,
            -0.023376233875751495,
            -0.022610824555158615,
            0.011442599818110466,
            0.0008376904297620058,
            0.026979433372616768,
            0.013743588700890541,
            0.0075816502794623375,
            -0.02178463339805603,
            0.03086686134338379,
            0.016504459083080292,
            0.022140787914395332,
            -0.02503453940153122,
            -0.021816078573465347,
            0.009070226922631264,
            -0.006382315419614315,
            0.017375756055116653,
            -0.00582529604434967,
            -0.016361456364393234,
            0.011454279534518719,
            -0.02944141998887062,
            0.008080817759037018,
            -0.015120534226298332,
            -0.02230231463909149,
            -0.02685222402215004,
            -0.005992046091705561,
            -0.01309330016374588,
            0.02476755529642105,
            -0.03179801627993584,
            0.008384290151298046,
            -0.01211363635957241,
            -0.02475545182824135,
            -0.018345320597290993,
            0.0215375404804945,
            -0.025881025940179825,
            -0.002989648375660181,
            -0.0279384758323431,
            -0.013056041672825813,
            -0.023422885686159134,
            0.00365873659029603,
            -0.02693042904138565,
            0.027243439108133316,
            0.008405614644289017,
            -0.027328597381711006,
            -0.006773332133889198,
            0.009900268167257309,
            0.016671909019351006,
            -0.031855326145887375,
            0.029624000191688538,
            0.012276476249098778,
            -0.0016220600809901953,
            -0.026729019358754158,
            -0.009590044617652893,
            -0.0194135382771492,
            -0.030459266155958176,
            0.02788999304175377,
            -0.030497848987579346,
            -0.0022736587561666965,
            -0.028215155005455017,
            -0.008977383375167847,
            -0.010148830711841583,
            -0.020283430814743042,
            0.012158673256635666,
            0.023529142141342163,
            -0.003227602457627654,
            -0.021269995719194412,
            0.014087863266468048,
            0.014800358563661575,
            -0.0010637604864314198,
            0.017398776486516,
            -0.018826045095920563,
            -0.009577970020473003,
            -0.026206495240330696,
            -0.001324644428677857,
            -0.024450697004795074,
            -0.021132105961441994,
            0.027433527633547783,
            -0.02705521509051323,
            -0.0027641039341688156,
            -0.01994824782013893,
            0.014247898012399673,
            0.009948556311428547,
            -0.009093830361962318,
            -0.023682165890932083,
            0.016574855893850327,
            -0.023343170061707497,
            -0.021535489708185196,
            0.004041608888655901,
            -0.006709693465381861,
            -0.021074792370200157,
            -0.009226486086845398,
            0.01654139719903469,
            -0.007512114942073822,
            -0.029926488175988197,
            -0.004160061478614807,
            -0.011687970720231533,
            -0.02278915047645569,
            -0.008397510275244713,
            0.02822750061750412,
            -0.016794566065073013,
            0.003056126646697521,
            -0.006903122644871473,
            -0.020157262682914734,
            -0.01889190636575222,
            -0.016171935945749283,
            0.01428388711065054,
            -0.0017259545857086778,
            0.0006800393457524478,
            -0.02874181792140007,
            -0.00032512188772670925,
            0.029280871152877808,
            -0.007017130497843027,
            -0.029308080673217773,
            -0.025154611095786095,
            -0.011772987432777882,
            0.013237839564681053,
            -0.015811098739504814,
            -0.00859514344483614,
            -0.01601935364305973,
            -0.015011858195066452,
            -0.0010016241576522589,
            -0.012557313777506351,
            -0.011086109094321728,
            0.0007931008585728705,
            -0.01688452810049057,
            -0.014331996440887451,
            0.0275761466473341,
            0.002418248448520899,
            0.024037126451730728,
            -0.015556580387055874,
            0.010561549104750156,
            0.021532198414206505,
            0.009608346968889236,
            -0.012967213056981564,
            -0.003906677011400461,
            -0.005688187666237354,
            -0.008982334285974503,
            -0.019731387495994568,
            -0.018645882606506348,
            0.002695392817258835,
            -0.01853220723569393,
            -0.007184195332229137,
            -0.026031730696558952,
            -0.024064060300588608,
            0.019664309918880463,
            0.026166310533881187,
            -0.008323894813656807,
            0.016859062016010284,
            0.005480899475514889,
            0.000632732582744211,
            -0.025212407112121582,
            0.019499214366078377,
            0.015659281983971596,
            -0.02781396172940731,
            0.009192665107548237,
            -0.020686553791165352,
            -0.030408432707190514,
            0.020902985706925392,
            -0.005206755828112364,
            -0.01568535901606083,
            0.02741924487054348,
            0.001492094132117927,
            -0.017407145351171494,
            -0.006298907101154327,
            -0.0018279657233506441,
            -0.00957440584897995,
            0.021321816369891167,
            -0.01953119970858097,
            -0.026650840416550636,
            -0.013229407370090485,
            0.022182974964380264,
            -0.0008354756864719093,
            0.02079024165868759,
            0.01734476536512375,
            -0.012211636640131474,
            -0.027076082304120064,
            0.002329224022105336,
            -0.017230138182640076,
            0.0021354949567466974,
            0.008795545436441898,
            0.022694583982229233,
            0.016307473182678223,
            0.009230785071849823,
            0.019138190895318985,
            0.025756390765309334,
            0.016717281192541122,
            -0.021897485479712486,
            -0.014494368806481361,
            0.0020894892513751984,
            -0.023236824199557304,
            -0.014808456413447857,
            0.012185036204755306,
            -0.017507608979940414,
            0.021880457177758217,
            -0.020421933382749557,
            0.029411911964416504,
            0.002187933074310422,
            0.023497354239225388,
            -0.006309504620730877,
            -0.002056655241176486,
            0.008112674579024315,
            -0.03146437928080559,
            -0.0016059280605986714,
            -0.02460588328540325,
            -0.02080785483121872,
            0.027634063735604286,
            0.02829177863895893,
            -0.02812204137444496,
            -0.0032170561607927084,
            0.0032368197571486235,
            0.01112191379070282,
            -0.009377565234899521,
            0.015296495519578457
        ],
        [
            0.010076441802084446,
            -0.022975068539381027,
            0.012804265134036541,
            0.028781680390238762,
            0.015442478470504284,
            0.019237535074353218,
            -0.013148914091289043,
            0.05133815482258797,
            -0.0006163337966427207,
            0.034029025584459305,
            -0.060276951640844345,
            -0.0010637822560966015,
            0.0357876755297184,
            0.03810303658246994,
            0.005434973165392876,
            -0.03075907751917839,
            -0.05018084496259689,
            0.03243847191333771,
            0.018252091482281685,
            -0.005430751480162144,
            -0.035083115100860596,
            0.018149061128497124,
            0.02151932194828987,
            -0.016755906865000725,
            0.03846452757716179,
            0.001024648081511259,
            -0.03464381396770477,
            -0.023813491687178612,
            -0.027702132239937782,
            0.00840360950678587,
            -0.017991019412875175,
            0.025294901803135872,
            0.027054889127612114,
            0.018125997856259346,
            -0.014708951115608215,
            -0.03628019616007805,
            0.02309688925743103,
            0.004648471251130104,
            -0.0014786915853619576,
            0.029748234897851944,
            0.013294219970703125,
            0.04719147831201553,
            0.003641178598627448,
            0.009411538019776344,
            0.006314018275588751,
            -0.012703929096460342,
            0.001113232458010316,
            -0.011774103157222271,
            0.013827592134475708,
            -0.040539950132369995,
            0.03458065912127495,
            -0.015543770045042038,
            -0.07562004774808884,
            -0.014318505302071571,
            -0.034665510058403015,
            -0.014269293285906315,
            -0.016522109508514404,
            0.018314529210329056,
            -0.010781882330775261,
            0.0025136377662420273,
            -0.008903682231903076,
            -0.011373596265912056,
            -0.04431695491075516,
            0.0063940975815057755,
            0.010754788294434547,
            0.027208510786294937,
            -0.0012672939337790012,
            -0.016630712896585464,
            0.010576090775430202,
            0.007674334570765495,
            0.008752529509365559,
            0.019752075895667076,
            0.014705441892147064,
            -0.008337917737662792,
            -0.004893975798040628,
            -0.03734927251935005,
            0.013651279732584953,
            -0.03508501499891281,
            -0.03110668435692787,
            -0.0013136961497366428,
            0.020730752497911453,
            -0.01575555093586445,
            0.01507957000285387,
            0.01586829125881195,
            -0.02471347525715828,
            0.014441385865211487,
            -0.023667044937610626,
            -0.04314214363694191,
            0.002206942532211542,
            0.019776180386543274,
            0.004186347126960754,
            0.014308275654911995,
            0.007503887638449669,
            -0.0028284466825425625,
            0.010127364657819271,
            -0.04617995768785477,
            -0.022314000874757767,
            -0.01605275087058544,
            -0.06397141516208649,
            -0.03219304233789444,
            0.02658689022064209,
            0.024659154936671257,
            -0.010686637833714485,
            0.03884587809443474,
            0.005643424112349749,
            0.004494421184062958,
            0.01660708338022232,
            -0.001071202801540494,
            0.04478984326124191,
            -0.00028886826476082206,
            0.005778627470135689,
            0.006247146055102348,
            0.005496442783623934,
            0.02954154461622238,
            -0.0023430471774190664,
            0.003504188498482108,
            0.03225139155983925,
            -0.0028637947980314493,
            -0.008220999501645565,
            0.0064238994382321835,
            -0.003736295271664858,
            0.045288655906915665,
            -0.031916771084070206,
            -0.01127608958631754,
            -0.02475077658891678,
            0.01361634861677885,
            0.04482516273856163,
            0.024249685928225517,
            -0.01934317871928215,
            -0.007790390867739916,
            0.0008802656084299088,
            0.007681163027882576,
            -0.020022394135594368,
            -0.009494340047240257,
            0.011299613863229752,
            -0.037852395325899124,
            0.0237523652613163,
            -0.02817053347826004,
            -0.01966537907719612,
            0.03526347875595093,
            -0.019477443769574165,
            0.0471336804330349,
            0.0051651387475430965,
            -0.011190484277904034,
            0.03608401119709015,
            0.0022003056947141886,
            0.01722179353237152,
            0.004892080556601286,
            0.015784157440066338,
            0.03503044322133064,
            -0.009275224059820175,
            -0.010397559031844139,
            -0.003545912681147456,
            -0.011554699391126633,
            0.013996186666190624,
            0.00600942550227046,
            -0.032459672540426254,
            -0.04945862293243408,
            -0.012046619318425655,
            -0.03212852403521538,
            0.0484275259077549,
            0.02708587609231472,
            -0.0004430027329362929,
            0.004646615125238895,
            -0.0018911309307441115,
            -0.036733657121658325,
            -0.047795288264751434,
            0.006803401280194521,
            -0.01495280396193266,
            -0.0077700125984847546,
            0.02230660431087017,
            0.03947833925485611,
            -0.033794105052948,
            0.03213147446513176,
            0.000992765766568482,
            0.014690104871988297,
            0.038767073303461075,
            -0.004147669300436974,
            0.017561746761202812,
            -0.006479754112660885,
            -0.022885620594024658,
            0.023025136440992355,
            -0.02663671225309372,
            0.03262527287006378,
            -0.02797052077949047,
            -0.015204614959657192,
            -0.0474473237991333,
            -0.01225923839956522,
            0.010465078987181187,
            -0.024883782491087914,
            0.01722888834774494,
            -0.026992114260792732,
            -0.010800241492688656,
            0.002609953284263611,
            0.02253953367471695,
            -0.005785565823316574,
            0.004950122442096472,
            -0.026052692905068398,
            -0.011249504052102566,
            0.02078082039952278,
            -0.0010044617811217904,
            -0.020811721682548523,
            -0.025877371430397034,
            -0.007314387708902359,
            -0.053063154220581055,
            -0.0036479115951806307,
            0.006760557647794485,
            0.019435737282037735,
            -0.026615183800458908,
            -0.007474612444639206,
            0.02472316287457943,
            0.033213574439287186,
            0.0051381210796535015,
            -0.015394794754683971,
            -0.024263709783554077,
            -0.021246882155537605,
            0.016983434557914734,
            -0.024355096742510796,
            0.00857043731957674,
            -0.006119925994426012,
            -0.009662059135735035,
            0.0036602921318262815,
            -1.16426699605654e-05,
            0.019782809540629387,
            -0.014084985479712486,
            -0.02285633236169815,
            -0.003336116671562195,
            -0.024278443306684494,
            -0.041488129645586014,
            -0.03191722556948662,
            -0.015449403785169125,
            0.01712726801633835,
            -0.04852822422981262,
            0.02684715948998928,
            0.029052771627902985,
            0.021519485861063004,
            -0.00495864124968648,
            -0.02767271175980568,
            -0.01820330135524273,
            0.0041140541434288025,
            -0.0021143213380128145,
            -0.02248825877904892,
            -6.488709186669439e-05,
            0.0286135021597147,
            0.01490823645144701,
            0.007847022265195847,
            0.03819802775979042,
            0.011576362885534763,
            -0.014487910084426403,
            -0.013323478400707245,
            0.007466860581189394,
            0.0038178437389433384,
            -0.05050535872578621,
            0.001286263926886022,
            -0.011275759898126125,
            0.005087672267109156,
            0.0370459146797657,
            0.03568257391452789,
            -0.006936992052942514,
            0.017323143780231476,
            -0.05425313115119934,
            0.01045491173863411,
            -0.02409176528453827,
            -0.001410587807185948,
            -0.000552615150809288,
            -0.04281622916460037,
            -0.012113620527088642,
            0.010621028020977974,
            -0.013207678683102131,
            -0.05514618381857872,
            -0.02566252276301384,
            -0.014796438626945019,
            0.013320643454790115,
            -0.011169970966875553,
            0.004413827322423458,
            0.022263934835791588,
            0.03324373438954353,
            0.030832689255475998,
            0.004997956100851297,
            -0.04147934541106224,
            0.022147120907902718,
            0.01531093567609787,
            -0.010452693328261375,
            -0.03097577951848507,
            -0.015481834299862385,
            -0.010374270379543304,
            -0.0222378708422184,
            -0.04807388409972191,
            0.021500755101442337,
            -0.008662684820592403,
            -0.005569306667894125,
            -0.023526977747678757,
            0.013908406719565392,
            0.005825909320265055,
            -0.010327637195587158,
            -0.009693048894405365,
            -0.020082833245396614,
            0.030252395197749138,
            -0.001301316311582923,
            0.038734983652830124,
            0.021237924695014954,
            -0.017822571098804474,
            -0.0023217303678393364,
            0.0024376169312745333,
            -0.01601213403046131,
            0.035194285213947296,
            0.02864033542573452,
            -0.005625094752758741,
            -0.040633708238601685,
            0.00346482265740633,
            -0.02200094610452652,
            -0.0015068227658048272,
            0.03129042312502861,
            0.035470519214868546,
            0.05344272777438164,
            -0.013741062954068184,
            0.03394630178809166,
            0.00270103313960135,
            -0.03257083520293236,
            0.0161343552172184,
            -0.018121106550097466,
            -0.025420015677809715,
            -0.005737354978919029,
            0.06517907977104187,
            -0.024680322036147118,
            0.03445364534854889,
            0.04817403107881546,
            0.047455839812755585,
            0.02208668552339077,
            -0.010968608781695366,
            -0.023182954639196396,
            0.020893394947052002,
            -0.003459885949268937,
            -0.022895388305187225,
            0.017630424350500107,
            0.052997637540102005,
            -0.013857065699994564,
            -0.04553230106830597,
            0.04172747582197189,
            -0.04537781700491905,
            0.02698059380054474,
            -0.020459679886698723,
            0.004298906773328781,
            -0.013658449053764343,
            0.009223906323313713,
            0.043299149721860886,
            -0.005042805802077055,
            0.0038609637413173914,
            -0.01783769391477108,
            0.010742628015577793,
            -0.052651308476924896,
            -0.07003869116306305,
            0.038813166320323944,
            0.014362753368914127,
            -0.035892147570848465,
            -0.02232144959270954,
            -0.026820052415132523,
            -0.011122491210699081,
            -0.018457045778632164,
            0.026511143893003464,
            0.043627429753541946,
            0.031244926154613495,
            -0.009998517110943794,
            -0.017729034647345543,
            0.0013111154548823833,
            0.00017094629583880305,
            0.008627746254205704,
            -0.027968022972345352,
            0.024889269843697548,
            0.020301437005400658,
            -0.03174084052443504,
            0.02563067153096199,
            0.030529260635375977,
            0.0023545371368527412,
            0.014475236646831036,
            0.012976356782019138,
            0.022104615345597267,
            0.008877964690327644,
            -0.0036332495510578156,
            0.019351806491613388,
            0.02052079513669014,
            -0.02523551508784294,
            -0.041726648807525635,
            -0.0011870010057464242,
            -0.0023683193139731884,
            0.05669289454817772,
            -0.01081825327128172,
            -0.010360048152506351,
            -0.03964519500732422,
            0.02555527351796627,
            -0.013258935883641243,
            -0.012762016616761684,
            0.015815895050764084,
            0.047771792858839035,
            -0.07473507523536682,
            -0.00017458287766203284,
            0.011020098812878132,
            -0.011860154569149017,
            -0.011835405603051186,
            0.009020104072988033,
            -0.04041588306427002,
            0.009288669563829899,
            0.033203575760126114,
            0.004656089469790459,
            -0.011340660974383354,
            -0.002370369154959917,
            0.047598112374544144,
            0.018087420612573624,
            -0.0043668667785823345,
            -0.0026472501922398806,
            -0.0019272897625342011,
            0.05400039255619049,
            0.020651957020163536,
            0.024830961599946022,
            0.003842039732262492,
            0.011669444851577282,
            0.014078783802688122,
            0.03989388793706894,
            0.045322615653276443,
            0.023773279041051865,
            -0.015268831513822079,
            -0.040055494755506516,
            -0.08357694000005722,
            0.03219339996576309,
            -0.014617260545492172,
            0.00533814262598753,
            0.05907754972577095,
            0.023252416402101517,
            -0.015476550906896591,
            0.03461351990699768,
            -0.017051760107278824,
            0.022856246680021286,
            -0.018645448610186577,
            0.002175502944737673,
            -0.03847309947013855,
            0.03035951964557171,
            -0.0013121337397024035,
            0.01180143840610981,
            -0.010659107938408852,
            -0.031842269003391266,
            -0.00767920259386301,
            -0.027527693659067154,
            -0.016113929450511932,
            -0.010593964718282223,
            -0.03125493600964546,
            -0.003176550380885601,
            0.020677465945482254,
            0.012925449758768082,
            -0.01644631288945675,
            -0.019828395918011665,
            0.02845153771340847,
            -0.020065434277057648,
            0.03859531506896019,
            0.015879008919000626,
            0.004039017483592033,
            -0.015511679463088512,
            0.058472663164138794,
            -0.031227121129631996,
            -0.002023138338699937,
            0.028705382719635963,
            0.00018373632337898016,
            -0.04033307358622551,
            -0.03630409017205238,
            0.04717083275318146,
            -0.016894061118364334,
            -0.0015309350565075874,
            -0.01464508380740881,
            0.022541197016835213,
            0.006030905991792679,
            0.01130027137696743,
            -0.04277819022536278,
            -0.0035044595133513212,
            -0.018220102414488792,
            0.04037773981690407,
            0.025340436026453972,
            -0.03390314429998398,
            -0.017253216356039047,
            -0.02276408113539219,
            0.01116262562572956,
            0.006941024214029312,
            -0.04293506592512131,
            -0.01900528185069561,
            -0.013631814159452915,
            -0.037374820560216904,
            -0.020694701001048088,
            0.007872498594224453,
            0.004192911554127932,
            0.021531134843826294,
            -0.08900735527276993,
            -0.011849691160023212,
            -0.011128220707178116,
            0.009543688967823982,
            0.015986816957592964,
            -0.051810119301080704,
            -0.013326466083526611,
            0.0021310383453965187,
            -0.005723738577216864,
            -0.0250804852694273,
            0.025118308141827583,
            -0.011610966175794601,
            0.018155710771679878,
            -0.008863559924066067,
            0.0035236673429608345,
            -0.0032597845420241356,
            0.0039495863020420074,
            0.019707292318344116,
            0.03340093046426773,
            -0.03103533387184143,
            0.00889440905302763,
            -0.008512948639690876,
            -0.021004999056458473,
            0.04648038372397423,
            0.013505474664270878,
            -0.03793136402964592,
            0.01561021525412798,
            -0.01374291442334652,
            -0.02762693166732788,
            -0.0036944181192666292,
            -0.021935373544692993,
            0.0314706414937973,
            -0.017736075446009636,
            0.02520926110446453,
            0.004799442831426859,
            0.005631145555526018,
            -0.027507102116942406,
            -0.02822631411254406,
            0.01060726772993803,
            -0.03177538514137268,
            0.01658843457698822,
            -0.07588855922222137,
            0.034069132059812546,
            0.01069557759910822,
            -0.003100356552749872,
            0.016548743471503258,
            0.004840013105422258,
            -0.018625197932124138,
            0.026515545323491096,
            0.008568222634494305,
            0.00493263453245163,
            -0.013566887006163597,
            0.034585848450660706,
            -0.03511286899447441,
            -0.005773582495748997,
            0.03251289576292038,
            0.04219500347971916,
            0.0012459721183404326,
            -0.014216874726116657,
            -0.034318968653678894,
            -0.007011345587670803,
            0.021683579310774803,
            0.030165812000632286,
            -0.02704409509897232,
            -0.012464323081076145,
            0.028293853625655174,
            -0.016497895121574402,
            -0.019950678572058678,
            0.012215876951813698,
            -0.00925376359373331,
            -0.014106573536992073,
            0.0015826286980882287,
            -0.01906917244195938,
            -0.028814641758799553,
            0.0418233647942543,
            -0.007641691714525223,
            -0.008618897758424282,
            -0.0410628467798233,
            -0.048304930329322815,
            0.024237221106886864,
            0.03148419037461281,
            0.05078093335032463,
            0.016943925991654396,
            -0.026096757501363754,
            0.03375428542494774,
            0.006420471705496311,
            0.07634761929512024,
            0.013674471527338028,
            0.011587885208427906,
            -0.003370206104591489,
            0.0387863889336586,
            -0.032849159091711044,
            0.013675565831363201,
            5.4112515499582514e-05,
            -0.0409640409052372,
            -0.0028633654583245516,
            -0.002892353106290102,
            -0.024682432413101196,
            -0.017638618126511574,
            0.010043373331427574,
            0.010210955515503883,
            0.03584080934524536,
            0.04917246848344803,
            -0.0053342427127063274,
            0.030932951718568802,
            0.006787891499698162,
            -0.01348870899528265,
            0.0016382684698328376,
            -0.013757779262959957,
            0.022136224433779716,
            0.009366156533360481,
            -0.020686885342001915,
            -0.02270507626235485,
            -0.01668943092226982,
            0.0008855685591697693,
            -0.019809279590845108,
            0.0009581185295246542,
            0.015138610266149044,
            0.004482834134250879,
            0.012446499429643154,
            -0.009006199426949024,
            0.00141733733471483,
            -0.010631745681166649,
            0.030562883242964745,
            -0.026868976652622223,
            0.026856347918510437,
            -0.009448261931538582,
            -0.004346858244389296,
            -0.0010434741852805018,
            -0.014921994879841805,
            0.008194416761398315,
            -0.02306240424513817,
            -0.04884250462055206,
            -0.032409846782684326,
            0.012281764298677444,
            -0.028676092624664307,
            -0.029710937291383743,
            0.015102170407772064,
            -0.008062108419835567,
            -0.0025046428199857473,
            -0.0418752059340477,
            -0.008921042084693909,
            -0.033878400921821594,
            0.02020864002406597,
            -0.01829618774354458,
            0.007908125407993793,
            -0.01728685200214386,
            -0.036903265863657,
            0.004969584755599499,
            -0.04150982201099396,
            -0.019398236647248268,
            -0.005924581550061703,
            -0.04846547171473503,
            -0.0206268560141325,
            -0.01056571677327156,
            -0.027420734986662865,
            0.023079071193933487,
            0.0034833780955523252,
            -0.046198345720767975,
            -0.006398476660251617,
            -0.0057219513691961765,
            -0.0009070096421055496,
            0.0030814865604043007,
            0.030287371948361397,
            -0.03849935531616211,
            0.03472520783543587,
            -0.020347299054265022,
            -0.002750126412138343,
            0.013667214661836624,
            0.011508647352457047,
            -0.011812102049589157,
            0.014462091960012913,
            -0.031266678124666214,
            0.025012914091348648,
            0.023090872913599014,
            0.01397329568862915,
            0.019076520577073097,
            -0.0023756700102239847,
            -0.003015305381268263,
            0.012776871211826801,
            -0.012112367898225784,
            0.015121103264391422,
            0.044197842478752136,
            -0.002722483826801181,
            -0.006811058148741722,
            -0.01755671016871929,
            0.010137660428881645,
            -0.006596140097826719,
            -0.021118488162755966,
            0.04010612517595291,
            -0.03589363768696785,
            0.02228941023349762,
            0.007131797727197409,
            -0.004800920840352774,
            -0.027978630736470222,
            -0.026528172194957733,
            -0.026978319510817528,
            -0.003781915409490466,
            -0.040885355323553085,
            0.017713313922286034,
            -0.03114020638167858,
            -0.0004303535388316959,
            -0.03558581322431564,
            -0.022122275084257126,
            -0.007326075341552496,
            -0.007753212004899979,
            -0.020557118579745293,
            0.011084659025073051,
            -0.031037865206599236,
            -0.013648790307343006,
            0.035012032836675644,
            -0.01633473113179207,
            -0.0021524839103221893,
            -0.027286656200885773,
            0.04694829881191254,
            0.03845471888780594,
            -0.04482339695096016,
            0.017534105107188225,
            0.017286965623497963,
            0.0023703768383711576,
            -0.009634265676140785,
            -0.038260627537965775,
            0.014759979210793972,
            0.04339722543954849,
            -0.04257966950535774,
            0.01951868087053299,
            -0.0007177442312240601,
            0.01301498617976904,
            -0.029131175950169563,
            0.02709933929145336,
            -0.043812524527311325,
            0.006738146301358938,
            0.0019575683400034904,
            -0.000672243011649698,
            -0.03724728152155876,
            -0.0572347454726696,
            -0.012180616147816181,
            0.002765227109193802,
            -0.026324665173888206,
            0.005758155137300491,
            -0.03205770626664162,
            0.022328494116663933,
            -0.06190509721636772,
            0.05209815129637718,
            -0.018115945160388947,
            0.0024661151692271233,
            0.0017860245425254107,
            -0.023362062871456146,
            0.0059738014824688435,
            0.0269048810005188,
            -0.010184149257838726,
            -0.0018052930245175958,
            0.0028451038524508476,
            -0.014665614813566208,
            -0.017344677820801735,
            0.033888980746269226,
            -0.001212136005051434,
            -0.02694314531981945,
            -0.005041748750954866,
            -0.037185654044151306,
            -0.012164385989308357,
            -0.007738857995718718,
            0.030447129160165787,
            -0.013768204487860203,
            -0.012530623935163021,
            0.001024522352963686,
            0.023947611451148987,
            0.03862149268388748,
            -0.032804153859615326,
            0.0009465260081924498,
            -0.0618530809879303,
            0.025541702285408974,
            -0.028433717787265778,
            -0.05351712927222252,
            0.03188087418675423,
            -0.036555323749780655,
            -0.015696605667471886,
            -4.7368783270940185e-05,
            0.0058923508040606976,
            -0.022159414365887642,
            -0.005025788675993681,
            0.024641817435622215,
            -0.005720293149352074,
            -0.012627210468053818,
            0.003931224811822176,
            -0.01916135661303997,
            -0.013311079703271389,
            0.05507565662264824,
            -0.04157429188489914,
            -0.02328021265566349,
            0.010120498016476631,
            -0.002031235257163644,
            0.0037165808025747538,
            0.013512921519577503,
            0.013612070120871067,
            -0.03775588423013687,
            -0.057299789041280746,
            -0.001919653033837676,
            -0.027267444878816605,
            -0.017466284334659576,
            -0.020940108224749565,
            -0.016213292255997658,
            0.0006105915526859462,
            0.04664253443479538,
            -0.020246533676981926,
            -0.05139217525720596,
            -0.041113726794719696,
            -0.01770656369626522,
            0.04046211391687393,
            0.007512583397328854,
            -0.00562627287581563,
            0.011034157127141953,
            -0.027657680213451385,
            -0.00830081757158041,
            -0.004425856750458479,
            0.01647457294166088,
            -0.003867826657369733,
            0.019858343526721,
            0.012492900714278221,
            0.0038283553440123796,
            -0.022093862295150757,
            -0.038534972816705704,
            -0.016323307529091835,
            -0.0858428105711937,
            -0.015552898868918419,
            0.005099189933389425,
            0.0001781437749741599,
            -0.005429435055702925,
            0.003514328273013234,
            -0.04785571247339249,
            0.0023253688123077154,
            0.033582653850317,
            0.01528231892734766,
            -0.012412969954311848,
            -0.0026893483009189367,
            -0.015002164989709854,
            -0.007412292994558811,
            0.00035804903018288314,
            0.00039620854659006,
            -0.03792790696024895,
            0.01857694238424301,
            0.004346117842942476,
            0.02148626372218132,
            0.028860019519925117,
            -0.007479099091142416,
            -0.020266631618142128,
            0.020705852657556534,
            0.004390198737382889,
            0.0025568099226802588,
            0.008979365229606628,
            0.005003763362765312,
            0.02139441668987274,
            0.019502250477671623,
            -0.025795184075832367,
            0.010676298290491104,
            0.02036544680595398,
            -0.016186069697141647,
            0.011027142405509949,
            -0.036463964730501175,
            0.027216732501983643,
            0.0288331788033247,
            0.008958403952419758,
            0.012724301777780056,
            -0.0005852609756402671,
            0.017444996163249016,
            -0.020928040146827698,
            0.02111233025789261,
            -0.021531004458665848,
            0.00019742525182664394,
            -0.0010600793175399303,
            0.056212425231933594,
            0.002912250580266118,
            -0.04067518189549446,
            -0.05033177509903908,
            -0.019280582666397095,
            -0.0007306336774490774,
            0.011707893572747707,
            -0.0034148215781897306,
            -0.001163041335530579,
            0.01499978918582201,
            0.0024826121516525745,
            0.026549696922302246,
            0.009998193942010403,
            0.0013414265122264624,
            0.01330410223454237,
            0.008112547919154167,
            -0.0030586079228669405,
            0.012676556594669819,
            0.0023454695474356413,
            0.01583085022866726,
            0.013851226307451725,
            -0.007767930626869202,
            -0.007385584060102701,
            0.034962285310029984,
            -0.023684361949563026,
            0.019647695124149323,
            -0.006386519409716129,
            -0.032214242964982986,
            0.009971223771572113,
            -0.037587717175483704,
            -0.022178158164024353,
            0.02438410557806492,
            -0.022198176011443138,
            0.01673632487654686,
            0.02130887471139431,
            0.047174397855997086,
            -0.03732363134622574,
            -0.021670877933502197,
            -0.003337541362270713,
            -0.014218461699783802,
            0.016139065846800804,
            0.05820083245635033,
            0.012142376974225044,
            0.002647579414770007,
            0.015027026645839214,
            0.0017440066440030932,
            0.026355748996138573,
            0.004483299795538187,
            0.014841684140264988,
            -0.027091482654213905,
            0.0070673259906470776,
            0.040686558932065964,
            0.02562711015343666,
            -0.047787345945835114,
            0.006841837428510189,
            0.0029463006649166346,
            0.015469375997781754,
            0.03864336013793945,
            0.05355817824602127,
            0.019598636776208878,
            0.020113514736294746,
            -0.007509923540055752,
            0.006633236538618803,
            0.015900803729891777,
            -0.008226125501096249,
            -0.028213253244757652,
            0.010975387878715992,
            -0.03974181041121483,
            0.01862008310854435,
            -0.06286292523145676,
            0.00105242058634758,
            -0.002777948509901762,
            0.02058515138924122,
            -0.00906009878963232,
            0.02183229848742485,
            0.008292224258184433,
            -0.018976332619786263,
            -0.0160995963960886,
            0.00822201557457447,
            -0.013061617501080036,
            0.011938181705772877,
            -0.004642446991056204,
            -0.008399032056331635,
            -0.0030515873804688454,
            0.003722931956872344,
            -0.00677929911762476,
            -0.0026182199362665415,
            -0.009657823480665684,
            -0.029457297176122665,
            -0.041634056717157364,
            -0.007090074010193348,
            0.031091731041669846,
            -0.04660794883966446,
            0.0066257561556994915,
            0.01094280555844307,
            -0.022883964702486992,
            0.018583454191684723,
            0.01933441497385502,
            0.024311518296599388,
            0.017096301540732384,
            -0.0018627637764438987,
            -0.006505635567009449,
            -0.05839935690164566,
            -0.02290305681526661,
            0.0038196092937141657,
            0.0010460017947480083,
            -0.03155907988548279,
            -0.00982283242046833,
            0.0325651541352272,
            0.005831680726259947,
            -0.039102908223867416,
            0.007411076687276363,
            -0.007756613194942474,
            -0.036247480660676956,
            -0.01078643836081028,
            0.011977903544902802,
            -0.011965281330049038,
            -0.03653067722916603,
            0.0007470726268365979,
            -0.006871027406305075,
            0.006148874759674072,
            -0.0009905042825266719,
            -0.0021695157047361135,
            0.007025798317044973,
            -0.034636978060007095,
            -0.027730366215109825,
            -0.017927270382642746,
            -0.002642157720401883,
            -0.011074521578848362,
            0.009516790509223938,
            -0.021436475217342377,
            0.018479695543646812,
            -0.002091426169499755,
            0.026822702959179878,
            0.013633692637085915,
            -0.012509032152593136,
            0.023810410872101784,
            -0.042682401835918427,
            -0.014667351730167866,
            0.010955810546875,
            0.011325136758387089,
            -0.02872566320002079,
            0.010158558376133442,
            0.03328995034098625,
            -0.01967841200530529,
            0.011061785742640495,
            0.000698458927217871,
            -0.011771746911108494,
            0.024481114000082016,
            -0.030332202091813087,
            0.034826021641492844,
            0.004111354239284992,
            0.03967258334159851,
            -0.035634398460388184,
            -0.014080815948545933,
            -0.02840293012559414,
            0.04387521743774414,
            -0.027552416548132896,
            0.02446773089468479,
            0.04369382932782173,
            -0.003870710963383317,
            0.0112153934314847,
            0.01202589925378561,
            -0.02112014964222908,
            0.025997154414653778,
            0.03145287185907364
        ],
        [
            -0.004202553536742926,
            0.02385694347321987,
            -0.014112144708633423,
            0.028758380562067032,
            0.01790436916053295,
            0.019968459382653236,
            -0.009748249314725399,
            0.03480881080031395,
            -0.019346531480550766,
            -0.012636643834412098,
            0.009646061807870865,
            -0.017167868092656136,
            -0.006883449852466583,
            0.012769934721291065,
            -0.030388152226805687,
            0.03206591680645943,
            0.025536324828863144,
            0.01683174818754196,
            -0.006856406573206186,
            0.015007621608674526,
            0.037923380732536316,
            -0.010093661025166512,
            0.0071137142367661,
            -0.028257301077246666,
            0.015677014365792274,
            -0.012576560489833355,
            -0.0010767454514279962,
            0.013203039765357971,
            0.0146195562556386,
            0.003274595830589533,
            -0.02951151318848133,
            0.028548313304781914,
            0.030930759385228157,
            -0.007325667887926102,
            0.006824389100074768,
            -0.00362984836101532,
            0.04070735722780228,
            0.01777687482535839,
            0.031419120728969574,
            0.02149033173918724,
            -0.01857682690024376,
            -0.004356457386165857,
            0.02002761699259281,
            -0.003444713307544589,
            -0.010684178210794926,
            -0.04717622697353363,
            -0.017802497372031212,
            0.002559524029493332,
            -0.017747128382325172,
            0.0007410062244161963,
            -0.016215361654758453,
            -0.026801295578479767,
            0.0446038655936718,
            -0.0043981727212667465,
            -0.008016613312065601,
            0.03428463265299797,
            -0.005976149346679449,
            -0.014441038481891155,
            -0.006114953197538853,
            -0.015481115318834782,
            0.012379557825624943,
            0.004555683117359877,
            0.04045301675796509,
            -0.0006986249936744571,
            0.030854057520627975,
            0.007242093328386545,
            0.00853805709630251,
            -0.008285433053970337,
            0.018367143347859383,
            -0.002532443031668663,
            -0.0257753673940897,
            0.034302081912755966,
            0.012243849225342274,
            0.004410864319652319,
            -0.011419963091611862,
            0.007839377969503403,
            0.04714791476726532,
            -0.009573890827596188,
            0.00978812575340271,
            -0.02752225287258625,
            -0.03759676590561867,
            0.010877309367060661,
            0.018977655097842216,
            -0.004982306156307459,
            0.005508920177817345,
            -0.021209249272942543,
            0.0012534585548564792,
            0.029797468334436417,
            0.026399292051792145,
            0.023181144148111343,
            -0.0037516343872994184,
            -0.02396748773753643,
            0.03102271445095539,
            -0.01794285885989666,
            0.020091673359274864,
            0.0073544797487556934,
            0.013911326415836811,
            -0.02231382578611374,
            0.06492184102535248,
            -0.01753251813352108,
            -0.02304654009640217,
            -0.061111267656087875,
            -0.024622231721878052,
            -0.002417669165879488,
            -0.018170446157455444,
            0.020156437531113625,
            -0.006195675116032362,
            -0.01658492535352707,
            0.00036838804953731596,
            -0.017371928319334984,
            0.024508383125066757,
            -0.007652331609278917,
            0.0013572241878136992,
            0.021559463813900948,
            0.03531563654541969,
            0.02747701294720173,
            -0.028125133365392685,
            0.005106591619551182,
            0.009709032252430916,
            0.023860499262809753,
            0.0015835589729249477,
            0.017320172861218452,
            -0.011120684444904327,
            -0.02662089094519615,
            0.014048402197659016,
            -0.028568774461746216,
            0.02819039486348629,
            0.017205653712153435,
            0.017203086987137794,
            0.021665029227733612,
            -0.008129141293466091,
            -0.008368893526494503,
            -0.021397434175014496,
            -0.01299799419939518,
            -0.009227548725903034,
            -0.030313845723867416,
            -0.005701930727809668,
            0.036127399653196335,
            0.019716428592801094,
            0.011217202059924603,
            0.004757840186357498,
            -0.022477325052022934,
            -0.022281428799033165,
            -0.0022924807853996754,
            0.01627734676003456,
            0.010630860924720764,
            -0.013789468444883823,
            -0.00528425769880414,
            0.00627810787409544,
            -0.006339319050312042,
            0.0027840198017656803,
            -0.015700003132224083,
            0.023731665685772896,
            -0.024980394169688225,
            -0.034410297870635986,
            -0.026977336034178734,
            -0.02043778821825981,
            -0.0009997078450396657,
            0.012675083242356777,
            -0.05293803662061691,
            0.008713536895811558,
            0.03359830379486084,
            0.007386441808193922,
            0.03434712439775467,
            -0.004048424307256937,
            -0.002985276747494936,
            0.016997918486595154,
            0.0296025350689888,
            0.028371548280119896,
            0.02345122955739498,
            0.031845785677433014,
            -0.019239766523241997,
            0.02488030679523945,
            0.0014712862903252244,
            0.04871876537799835,
            0.020020732656121254,
            0.020274188369512558,
            -0.00010856960579985753,
            -0.011893870308995247,
            0.027427125722169876,
            -0.011935959570109844,
            0.0019018100574612617,
            -0.02278873138129711,
            0.003990195691585541,
            0.012288910336792469,
            -0.022471057251095772,
            0.01679033227264881,
            -0.013600164093077183,
            0.01726428046822548,
            -0.012037149630486965,
            -0.00703580304980278,
            -0.004612336866557598,
            0.06969137489795685,
            -0.0015087967040017247,
            0.03070029430091381,
            -0.021601418033242226,
            -0.004533161874860525,
            -0.019844625145196915,
            -0.01904151774942875,
            0.02853843942284584,
            0.013990389183163643,
            -0.013462120667099953,
            -0.00591216841712594,
            0.012032068334519863,
            0.03596192225813866,
            0.01658848486840725,
            0.02518315054476261,
            0.024852680042386055,
            0.026906073093414307,
            0.024170810356736183,
            -0.006536849308758974,
            -0.01439724862575531,
            -0.022252406924962997,
            -0.018082981929183006,
            -0.0015568062663078308,
            -0.01179009024053812,
            -0.02018384449183941,
            0.030396556481719017,
            0.028706945478916168,
            -0.018875163048505783,
            -0.025933001190423965,
            -0.03254025802016258,
            0.006895539816468954,
            -0.0013607112923637033,
            0.017350323498249054,
            -0.004966188222169876,
            -0.011857549659907818,
            0.0057191429659724236,
            0.021870087832212448,
            0.028874989598989487,
            -0.004242374561727047,
            0.008888518437743187,
            0.0345485582947731,
            -0.010550634004175663,
            -0.01635243557393551,
            0.036290980875492096,
            0.02220974676311016,
            -0.026335839182138443,
            -0.017107900232076645,
            -0.018133750185370445,
            -0.016073424369096756,
            -0.022603800520300865,
            -0.024773402139544487,
            -0.02412060834467411,
            -0.018321990966796875,
            0.0004759853763971478,
            0.031653132289648056,
            0.00941309705376625,
            0.015732228755950928,
            -0.023824723437428474,
            0.004766568075865507,
            0.011006894521415234,
            -0.012834877707064152,
            0.025769520550966263,
            0.016598980873823166,
            -0.02193066105246544,
            -0.01836671121418476,
            0.019051052629947662,
            -0.02960338070988655,
            -0.028723588213324547,
            -0.005756609607487917,
            0.029996296390891075,
            -0.020643142983317375,
            -0.018606532365083694,
            -0.015507874079048634,
            -0.02490140125155449,
            -0.019274089485406876,
            -0.005426465999335051,
            -0.03235730528831482,
            0.011681041680276394,
            -0.008609400130808353,
            -0.003381262766197324,
            0.022041909396648407,
            -0.012719727121293545,
            -0.012506924569606781,
            0.028916917741298676,
            0.03352506086230278,
            0.0272607933729887,
            0.0010261052520945668,
            0.013324975967407227,
            -0.02298237942159176,
            0.004486157558858395,
            0.01575937680900097,
            -0.017575137317180634,
            0.030643034726381302,
            0.018451154232025146,
            -0.0164113100618124,
            -0.04855526238679886,
            0.022253189235925674,
            0.029041945934295654,
            0.0021225493401288986,
            -0.02789311483502388,
            -0.01691989041864872,
            -0.029702799394726753,
            -0.009580973535776138,
            -0.0056556458584964275,
            0.008045007474720478,
            0.026799097657203674,
            -0.02829761616885662,
            0.018874045461416245,
            -0.003995392471551895,
            0.007743098773062229,
            -0.022800849750638008,
            -0.012114351615309715,
            0.010296503081917763,
            -0.02077753283083439,
            -0.010007870383560658,
            0.026254577562212944,
            0.007033092435449362,
            -0.04738232493400574,
            0.004142611287534237,
            0.028104830533266068,
            -0.019190829247236252,
            0.019039377570152283,
            0.019715214148163795,
            -0.01882573403418064,
            0.0234972033649683,
            0.020009249448776245,
            0.025483213365077972,
            0.014135882258415222,
            -0.024409456178545952,
            0.038825247436761856,
            -0.0015038979472592473,
            0.005601712502539158,
            0.03472381457686424,
            -0.02680107206106186,
            -0.007319581229239702,
            -0.0025646693538874388,
            -0.011756951920688152,
            0.029035264626145363,
            0.02090323157608509,
            0.0076890061609447,
            -0.004350145347416401,
            0.013924757018685341,
            4.329120656620944e-06,
            -0.04177255183458328,
            0.008534696884453297,
            0.015347322449088097,
            0.01247457880526781,
            0.027713321149349213,
            -0.004103634972125292,
            0.008779390715062618,
            -0.021393688395619392,
            -0.0002724161313381046,
            0.012319150380790234,
            0.03587126359343529,
            -0.00038855650927871466,
            0.00048116390826180577,
            -0.0066721802577376366,
            0.023830458521842957,
            0.03612848371267319,
            0.013621820136904716,
            0.0007864790968596935,
            0.0032206675969064236,
            0.029646504670381546,
            0.01889442838728428,
            -0.0014562204014509916,
            -0.021411146968603134,
            0.0379621721804142,
            -0.009245792403817177,
            -0.01300529483705759,
            -0.02923579514026642,
            -0.012030516751110554,
            -0.004098206292837858,
            -0.02311517484486103,
            0.03558200225234032,
            -0.026569396257400513,
            0.01842436008155346,
            0.024430744349956512,
            -0.012577023357152939,
            0.03072171099483967,
            -0.020174114033579826,
            0.03223695233464241,
            0.030207501724362373,
            0.005386701785027981,
            -0.03919105604290962,
            0.0235280841588974,
            0.009297813288867474,
            0.04350420460104942,
            0.018043842166662216,
            0.027720792219042778,
            0.017784954980015755,
            0.023277554661035538,
            0.025975970551371574,
            0.026046419516205788,
            -0.010823198594152927,
            -0.008137955330312252,
            -0.026390960440039635,
            0.020917069166898727,
            0.019380124285817146,
            0.007593825459480286,
            -0.008868678472936153,
            0.017091214656829834,
            0.023877177387475967,
            0.05319099500775337,
            0.031595200300216675,
            -0.020436467602849007,
            -0.004450060427188873,
            0.012942959554493427,
            0.02748597227036953,
            -0.020092565566301346,
            0.012124507687985897,
            -0.005530151072889566,
            -0.023917198181152344,
            0.023051878437399864,
            0.024585824459791183,
            -0.006322695408016443,
            -0.04114358499646187,
            0.02015361376106739,
            -0.005225288216024637,
            -0.0029796194285154343,
            0.013991541229188442,
            0.024594543501734734,
            -0.012086853384971619,
            -0.016901716589927673,
            0.02439119666814804,
            0.0030666321981698275,
            -0.0033330770675092936,
            0.010632042773067951,
            0.00021791986364405602,
            0.024398967623710632,
            -0.012785556726157665,
            0.033621467649936676,
            0.025399737060070038,
            0.023676050826907158,
            0.02886253036558628,
            0.0184209942817688,
            -0.016834096983075142,
            0.029476793482899666,
            -0.010360216721892357,
            0.018824616447091103,
            0.011574187316000462,
            0.007660265080630779,
            0.05977099761366844,
            0.02213386259973049,
            -0.0013122472446411848,
            -0.004386356566101313,
            0.007057067006826401,
            0.024713220074772835,
            0.008415648713707924,
            -0.024776622653007507,
            0.0173445176333189,
            0.034578580409288406,
            -0.03983050584793091,
            -0.0037797605618834496,
            -0.01652589812874794,
            0.03611788526177406,
            0.06017550081014633,
            0.04290702939033508,
            -0.002991980640217662,
            0.003256943542510271,
            0.027858268469572067,
            -0.025987235829234123,
            0.011322622187435627,
            0.006368930451571941,
            0.018112702295184135,
            0.005316649563610554,
            0.029915690422058105,
            0.018522944301366806,
            -0.013049825094640255,
            0.02828356623649597,
            0.0015191545244306326,
            -0.0005886760191060603,
            -0.026818765327334404,
            -0.0008154282695613801,
            -0.0244487002491951,
            0.021180367097258568,
            -0.05629242956638336,
            0.02671901136636734,
            -0.015345134772360325,
            0.03521764278411865,
            0.022646142169833183,
            -0.006066259928047657,
            -0.019017459824681282,
            0.01083498913794756,
            -0.0231651421636343,
            0.008641023188829422,
            -0.035270269960165024,
            -0.004498468711972237,
            -0.01869823969900608,
            0.005465552676469088,
            0.01632021553814411,
            0.007934801280498505,
            0.012994816526770592,
            -0.009603611193597317,
            -0.011584515683352947,
            0.024163275957107544,
            0.023986583575606346,
            -0.038861747831106186,
            0.03552863374352455,
            -0.03108408860862255,
            -0.019866256043314934,
            0.01692163571715355,
            -0.014889095909893513,
            0.04739198833703995,
            0.008237822912633419,
            -0.024077391251921654,
            -0.002015874022617936,
            -0.017033252865076065,
            -0.0032373229041695595,
            0.020183226093649864,
            -0.02825639583170414,
            -0.015613209456205368,
            0.004919209983199835,
            -0.0163814015686512,
            0.01865410804748535,
            0.0001762170431902632,
            0.02314121462404728,
            -0.006629587151110172,
            -0.010922196321189404,
            -0.01375183928757906,
            0.013886536471545696,
            0.017309311777353287,
            0.009423863142728806,
            0.029857758432626724,
            -0.02901347167789936,
            -0.006675543263554573,
            -0.008167492225766182,
            0.01572568714618683,
            0.01795312762260437,
            0.017202068120241165,
            -0.020955374464392662,
            -0.02701180800795555,
            -0.0016715200617909431,
            0.01156886201351881,
            0.011395598761737347,
            0.047475263476371765,
            0.03019663318991661,
            -0.023111071437597275,
            0.028346912935376167,
            -0.00915791466832161,
            0.007135123014450073,
            -0.002917555393651128,
            0.023165958002209663,
            -0.023323021829128265,
            -0.02834179811179638,
            -0.015689024701714516,
            -0.009748478420078754,
            -0.0030354985501617193,
            0.007879683747887611,
            -0.02550925873219967,
            -0.021242493763566017,
            0.016969971358776093,
            0.029774073511362076,
            -0.025327742099761963,
            -0.03512532263994217,
            0.012688814662396908,
            -0.009538203477859497,
            -0.00641047116369009,
            -0.02290412224829197,
            -0.0241721011698246,
            0.002738533541560173,
            0.0026394871529191732,
            -0.04375067353248596,
            -0.0072229220531880856,
            -0.027279630303382874,
            0.009159233421087265,
            0.0022705153096467257,
            -0.0021669811103492975,
            -0.030010277405381203,
            0.024975745007395744,
            0.029912030324339867,
            0.005163226742297411,
            -0.004835203289985657,
            -0.019721174612641335,
            -0.039953265339136124,
            0.024811239913105965,
            0.029955746605992317,
            -0.020934592932462692,
            0.024435238912701607,
            -0.02047649957239628,
            -0.0012969390954822302,
            -0.00987247284501791,
            0.026624832302331924,
            -0.032076600939035416,
            0.0349382609128952,
            0.007833413779735565,
            -0.014437745325267315,
            0.018584806472063065,
            -3.1174371542874724e-05,
            0.036760684102773666,
            0.012256922200322151,
            -0.017745571210980415,
            0.03349572420120239,
            0.011101447977125645,
            0.007123930379748344,
            0.00546306325122714,
            0.017071444541215897,
            0.029228774830698967,
            -0.019373688846826553,
            -0.011038042604923248,
            -0.0006086378125473857,
            -0.006119408179074526,
            0.0003035170375369489,
            0.019788477569818497,
            0.008658318780362606,
            0.009331184439361095,
            0.006504254415631294,
            0.028405899181962013,
            -0.010157930664718151,
            -0.014062096364796162,
            0.006174031645059586,
            -0.0038226309698075056,
            -0.025324901565909386,
            0.02346646413207054,
            0.029837720096111298,
            0.021289877593517303,
            -0.023934608325362206,
            0.03492305800318718,
            -0.011181867681443691,
            0.024944642558693886,
            0.034223522990942,
            -0.02196655049920082,
            -0.003140042768791318,
            0.03117944300174713,
            0.05418369919061661,
            -0.018297191709280014,
            -0.023603729903697968,
            0.010465960949659348,
            -0.00025022224872373044,
            -0.007454763166606426,
            0.033180732280015945,
            -0.008125968277454376,
            -0.004979690071195364,
            -0.011569014750421047,
            -0.009298433549702168,
            0.029592974111437798,
            0.014931540004909039,
            0.04237839952111244,
            0.0034050389658659697,
            -0.026739537715911865,
            -0.014609397388994694,
            -0.0046685440465807915,
            -0.04973231628537178,
            0.002283793408423662,
            0.01682962290942669,
            0.027716394513845444,
            0.025133894756436348,
            0.0054159038700163364,
            -0.02222268469631672,
            0.017840981483459473,
            -0.009470293298363686,
            -0.012077917344868183,
            0.0009783016284927726,
            -0.030949000269174576,
            0.010576607659459114,
            -0.017340928316116333,
            0.027579311281442642,
            0.013681734912097454,
            0.028293557465076447,
            0.029078688472509384,
            0.004959692247211933,
            -0.013018136844038963,
            -0.011618069373071194,
            0.02601015381515026,
            0.03256480395793915,
            -0.04568912833929062,
            -0.02278812788426876,
            -0.0004758328141178936,
            0.017106104642152786,
            0.024931523948907852,
            -0.027114009484648705,
            -0.007070673163980246,
            0.008902066387236118,
            -0.013137192465364933,
            -0.00515347346663475,
            -0.025145040825009346,
            -0.0004863225040026009,
            -0.02902096137404442,
            0.015778716653585434,
            0.04416589438915253,
            -0.004607665352523327,
            -0.017839835956692696,
            0.02013099566102028,
            -0.01591857708990574,
            -0.005184409208595753,
            0.0356961190700531,
            0.0057590072974562645,
            -0.013212230987846851,
            -0.013321667909622192,
            0.014889602549374104,
            0.012872475199401379,
            0.012873177416622639,
            0.028653545305132866,
            0.02900192327797413,
            -0.03110427036881447,
            0.007044075522571802,
            0.030394336208701134,
            -0.02148067019879818,
            -0.03778126835823059,
            0.03661369904875755,
            0.0027737317141145468,
            -0.04655974730849266,
            -0.00014638171705882996,
            0.007111333776265383,
            0.0034438646398484707,
            0.013416072353720665,
            0.027258284389972687,
            -0.008273148909211159,
            -0.01869908906519413,
            0.013691319152712822,
            0.011646922677755356,
            0.022954199463129044,
            -0.01850299909710884,
            -0.006313457153737545,
            0.03323504701256752,
            -0.01329323835670948,
            0.014343139715492725,
            -0.020338626578450203,
            -0.015686791390180588,
            -0.0294340793043375,
            -0.008260593749582767,
            0.031959161162376404,
            0.03767559304833412,
            -0.003904233453795314,
            0.0029845060780644417,
            0.019020915031433105,
            -0.023831374943256378,
            -0.008213229477405548,
            0.016639459878206253,
            0.038896817713975906,
            0.016957653686404228,
            0.00010488419502507895,
            -0.007260461337864399,
            0.006218745373189449,
            0.00987707357853651,
            -0.011594501323997974,
            -0.026748670265078545,
            -0.01795128546655178,
            0.01634446159005165,
            0.04220336675643921,
            -0.017464518547058105,
            -0.03444289416074753,
            -0.0002662971382960677,
            0.020198378711938858,
            0.018654361367225647,
            -0.00500077148899436,
            0.023128049448132515,
            -0.008848818950355053,
            -0.004693616647273302,
            0.01717342995107174,
            -0.016799863427877426,
            0.026104802265763283,
            0.015604577027261257,
            -0.005564572289586067,
            0.033021487295627594,
            0.002366631990298629,
            0.007922817021608353,
            -0.03096921183168888,
            -0.016067149117588997,
            -0.02517480030655861,
            -0.00424619996920228,
            -0.013285214081406593,
            0.002614823170006275,
            0.02809263952076435,
            0.011142128147184849,
            0.002638312289491296,
            0.05250311642885208,
            0.020284488797187805,
            -0.02222011797130108,
            -0.010720387101173401,
            0.02353697456419468,
            0.004206844139844179,
            -0.0172912385314703,
            0.029734140262007713,
            0.016910845413804054,
            0.015090510249137878,
            0.010918048210442066,
            0.01625618152320385,
            0.04067602381110191,
            -0.01843666471540928,
            0.005025350023061037,
            -0.021549876779317856,
            -0.025524582713842392,
            -0.03026699088513851,
            0.033063434064388275,
            -0.0062020826153457165,
            0.02687825635075569,
            -0.004677492193877697,
            0.03505849465727806,
            0.005317938979715109,
            0.01746782660484314,
            -0.03522676229476929,
            0.020418886095285416,
            -0.006951386108994484,
            -0.027015957981348038,
            -0.015084990300238132,
            -0.04066842794418335,
            -0.016020813956856728,
            0.0047868359833955765,
            0.0037964475341141224,
            0.014075877144932747,
            -0.03334284573793411,
            0.016226237639784813,
            0.014121615327894688,
            -0.006349583622068167,
            -0.005364706274122,
            -0.03290492296218872,
            0.002038918435573578,
            -0.01186596229672432,
            -0.023616835474967957,
            0.02859232947230339,
            -0.018227335065603256,
            0.009695162996649742,
            0.04540597274899483,
            0.00861959345638752,
            0.024108832702040672,
            0.01664830930531025,
            0.02705024927854538,
            0.026424957439303398,
            0.007624220568686724,
            -0.009501478634774685,
            -0.00827833916991949,
            -0.01501674298197031,
            0.02010936662554741,
            0.004306385293602943,
            0.017271393910050392,
            -0.03799707442522049,
            -0.013712355867028236,
            0.01909646950662136,
            -0.024205554276704788,
            0.050628744065761566,
            -0.03220337629318237,
            0.008222853764891624,
            -0.046697769314050674,
            -0.0015636604512110353,
            0.02973967418074608,
            0.02811143733561039,
            -0.013866734690964222,
            0.023361876606941223,
            -0.0009283603867515922,
            0.007390266750007868,
            0.017356693744659424,
            -0.011974002234637737,
            -0.06212450936436653,
            0.020747795701026917,
            -0.023867439478635788,
            0.03706945478916168,
            -0.000711623637471348,
            -0.007900476455688477,
            0.031009268015623093,
            0.005836897064000368,
            0.020801376551389694,
            0.012380359694361687,
            -0.003024497302249074,
            -0.02048810012638569,
            0.00777584919705987,
            0.009009705856442451,
            0.016483407467603683,
            -0.01453065313398838,
            0.020252274349331856,
            -0.01129402406513691,
            -0.03690718114376068,
            0.009613096714019775,
            0.016953226178884506,
            0.010566494427621365,
            0.0005063762073405087,
            -0.029742244631052017,
            -0.011985559947788715,
            0.038168665021657944,
            -0.01482376828789711,
            -0.012484651058912277,
            0.01500258781015873,
            -0.008657397702336311,
            -0.00663659768179059,
            0.003343567019328475,
            0.020075324922800064,
            -0.023096902295947075,
            0.02833469957113266,
            0.030882401391863823,
            0.029727797955274582,
            -0.02940415032207966,
            0.024736138060688972,
            0.016232239082455635,
            -0.027683399617671967,
            -0.008533179759979248,
            0.03965003788471222,
            0.0525861456990242,
            0.0014437620993703604,
            0.026542702689766884,
            -0.010380718857049942,
            -0.012379443272948265,
            -0.00850575603544712,
            0.003791124327108264,
            0.024778468534350395,
            -0.013827292248606682,
            -0.0005518364487215877,
            0.01877741515636444,
            -0.004325095564126968,
            0.002608958398923278,
            -0.010644608177244663,
            -0.02025442011654377,
            0.016837768256664276,
            -0.033455636352300644,
            0.05540446192026138,
            0.030631205067038536,
            -0.01144071388989687,
            0.022678770124912262,
            0.008720476180315018,
            -0.019310619682073593,
            0.03130481019616127,
            0.016259411349892616,
            0.009911797940731049,
            0.004089741501957178,
            -0.03746215999126434,
            -0.0272750873118639,
            0.008785327896475792,
            0.006335057318210602,
            0.028816217556595802,
            -0.001768323127180338,
            0.01130392774939537,
            -0.02916533127427101,
            0.007103594485670328,
            -0.01254662498831749,
            -0.006750981789082289,
            -0.02524983324110508,
            0.019513897597789764,
            0.02624811790883541,
            -0.03515055775642395,
            0.02500266209244728,
            -0.0024670802522450686,
            0.03762153163552284,
            0.028250521048903465,
            -0.009412149898707867,
            0.026624547317624092,
            -0.013932844623923302,
            -0.005196000449359417,
            -0.008985919877886772,
            -0.03228776901960373,
            0.010755015537142754,
            0.032633226364851,
            -0.004398747347295284,
            -0.0242081917822361,
            -0.04845815896987915,
            -0.012630059383809566,
            -0.020640507340431213,
            -0.0067368606105446815,
            0.02081114985048771,
            0.02499421499669552,
            -0.007369424682110548,
            -0.0185854472219944,
            -0.03588133305311203,
            0.015154789201915264,
            0.01425198744982481,
            0.02483227290213108,
            0.015757570043206215,
            -0.027006004005670547,
            -0.03863254189491272,
            0.014450560323894024,
            0.009817199781537056,
            0.034879960119724274,
            -0.003948674071580172,
            0.022744711488485336,
            -0.0140894940122962,
            0.048125009983778,
            -0.03242244943976402,
            -0.004609700758010149,
            -0.01982526294887066,
            -0.007766403257846832,
            -0.01264654379338026,
            -0.014266986399888992,
            -0.0033230071421712637,
            -0.013495842926204205,
            -0.021546171978116035,
            -0.007141246926039457,
            0.013702365569770336,
            0.029671629890799522,
            0.017091156914830208,
            0.00013802992179989815,
            -0.029805229976773262,
            -0.02648025192320347,
            0.022167155519127846,
            -0.005663641262799501,
            -0.004307386465370655,
            -0.012279627844691277,
            -0.04033281281590462,
            0.03139067441225052,
            0.007995015010237694,
            -0.004671753384172916,
            -0.008287787437438965,
            0.050683338195085526,
            0.005957151763141155,
            0.015704181045293808,
            0.012052677571773529,
            -0.008728682063519955,
            0.007872211746871471,
            0.018752500414848328,
            0.0035384485963732004,
            0.03132014349102974,
            0.022854529321193695,
            0.0008035217761062086,
            -0.016222745180130005,
            -0.017739329487085342,
            0.02267438918352127,
            -0.00250071007758379,
            0.019703255966305733,
            0.02313484624028206,
            0.028990911319851875,
            0.032601095736026764,
            0.017829768359661102,
            0.011100945994257927,
            -0.030489541590213776,
            0.023073749616742134,
            0.013274486176669598,
            0.02935921773314476,
            0.02072170004248619,
            0.014078810811042786,
            -0.002604682231321931,
            0.024214250966906548,
            -9.77434956439538e-06,
            -0.009249753318727016,
            0.0034083540085703135,
            -0.023029588162899017,
            0.0240589939057827,
            0.011393994092941284,
            -0.023268643766641617,
            0.004363804589956999,
            -0.025688525289297104,
            0.00024388794554397464,
            0.023984059691429138,
            0.0065963175147771835,
            -0.013196578249335289,
            0.020401250571012497,
            0.015619724988937378
        ],
        [
            0.023402802646160126,
            0.03158404678106308,
            -0.010731639340519905,
            -0.012026410549879074,
            0.016139164566993713,
            0.03943303972482681,
            0.03737684711813927,
            0.02084517851471901,
            0.029103083536028862,
            -0.03454010188579559,
            -0.014180121012032032,
            -0.026828616857528687,
            0.008248415775597095,
            6.027596828062087e-05,
            0.04601516202092171,
            -0.0011676823487505317,
            -0.02107682079076767,
            0.01477829460054636,
            -0.02624838799238205,
            0.03313414007425308,
            0.038918718695640564,
            -0.011733196675777435,
            0.031341761350631714,
            0.038074757903814316,
            -0.01207895576953888,
            0.0006782307173125446,
            -0.036640871316194534,
            0.01473548449575901,
            -0.02383517101407051,
            -0.016489218920469284,
            -0.02624599076807499,
            -0.0065804943442344666,
            0.005969052202999592,
            -0.004687554202973843,
            0.011104787699878216,
            -0.022168520838022232,
            -0.03799513727426529,
            -0.017690518870949745,
            0.014836970716714859,
            -0.012232217006385326,
            4.667900429922156e-05,
            -0.011641097255051136,
            0.00031157766352407634,
            -0.01237489003688097,
            -0.023276951164007187,
            0.008086594752967358,
            0.0018345379503443837,
            0.00930080283433199,
            -0.002248937962576747,
            -0.007095970679074526,
            -0.010211694985628128,
            0.014825960621237755,
            0.016459466889500618,
            0.004252567421644926,
            -0.01719597540795803,
            0.014171471819281578,
            -0.024988701567053795,
            -0.028302958235144615,
            -0.009806821122765541,
            -0.0277369674295187,
            -0.020539985969662666,
            -0.006411809474229813,
            -0.0036464305594563484,
            -0.020830253139138222,
            0.03033546917140484,
            -0.012120124883949757,
            0.0005928185419179499,
            -0.021794253960251808,
            0.024558039382100105,
            -0.0060729761607944965,
            -0.007910585030913353,
            -0.0061073219403624535,
            -0.028184417635202408,
            -0.021562671288847923,
            -0.008512604981660843,
            0.007779924664646387,
            0.015866925939917564,
            -0.003029643325135112,
            -0.0031225222628563643,
            -0.008181372657418251,
            -0.039001792669296265,
            0.04206279292702675,
            0.032552167773246765,
            0.013994618318974972,
            0.03426344320178032,
            0.013843749649822712,
            -0.02526247873902321,
            -0.01083111297339201,
            -0.007150424178689718,
            0.014655775390565395,
            0.016875632107257843,
            0.0173176396638155,
            -0.0199901070445776,
            -0.0501432828605175,
            0.007862662896513939,
            -0.024207359179854393,
            0.010786793194711208,
            -0.013488286174833775,
            -0.005928795784711838,
            0.004543479532003403,
            -0.004634857643395662,
            -0.03882770612835884,
            -0.022463735193014145,
            -0.012047694995999336,
            -0.02181139588356018,
            0.026219552382826805,
            -0.02974644862115383,
            0.013599674217402935,
            -0.04520774260163307,
            -0.005338740535080433,
            -0.022065039724111557,
            -0.008929655887186527,
            -0.0025545840617269278,
            0.032899998128414154,
            0.05265289917588234,
            0.019061457365751266,
            0.021893922239542007,
            -0.027398400008678436,
            0.004681796301156282,
            -0.03122120164334774,
            -0.016317643225193024,
            0.009283497929573059,
            0.00925691518932581,
            0.003843204816803336,
            -0.024442065507173538,
            -0.015012100338935852,
            -0.023366278037428856,
            0.0005599688156507909,
            -0.01624513603746891,
            -0.004890554118901491,
            -0.006029180251061916,
            0.01531384140253067,
            -0.035984061658382416,
            -0.02323090098798275,
            -0.019930189475417137,
            0.009349288418889046,
            0.006899317726492882,
            -0.014105171896517277,
            0.0015790894394740462,
            -0.004410078749060631,
            0.013618363067507744,
            -0.003967543598264456,
            -0.00628177123144269,
            -0.01509550679475069,
            -0.05483660846948624,
            0.020700320601463318,
            -0.004384019877761602,
            -0.0287482887506485,
            0.004830309189856052,
            0.004819737747311592,
            -0.03166981041431427,
            -0.0018014474771916866,
            -0.009938758797943592,
            0.035541485995054245,
            -0.013623966835439205,
            -0.003559977049008012,
            0.013325046747922897,
            0.0322086364030838,
            -0.0016877868911251426,
            -0.022593464702367783,
            0.034677814692258835,
            0.004497012123465538,
            0.025734135881066322,
            -0.019779598340392113,
            0.007714302744716406,
            -0.03660447895526886,
            -0.018815990537405014,
            0.04702851176261902,
            -0.0020077305380254984,
            0.03708251938223839,
            -0.009602345526218414,
            0.017604202032089233,
            0.025229748338460922,
            -0.016872799023985863,
            -0.005018896423280239,
            -0.02255231887102127,
            0.003591508837416768,
            0.015877731144428253,
            -0.036292146891355515,
            -0.017489055171608925,
            0.030072230845689774,
            -0.002304430352523923,
            0.04893091693520546,
            -0.0234530009329319,
            -0.022708557546138763,
            0.007441736292093992,
            0.0075640431605279446,
            -0.005840651225298643,
            0.02051607519388199,
            0.0005103917210362852,
            -0.03493715077638626,
            -0.0196183193475008,
            -0.01758866012096405,
            0.01960705779492855,
            0.028554975986480713,
            -0.01999119110405445,
            0.0024670418351888657,
            -0.02299407310783863,
            -0.014885131269693375,
            0.03140811249613762,
            0.017139317467808723,
            -0.0032508177682757378,
            0.03831867873668671,
            0.031389545649290085,
            -0.023922907188534737,
            0.00821623858064413,
            0.027417786419391632,
            -0.012562197633087635,
            0.0410475917160511,
            -0.0018539302982389927,
            0.02049916237592697,
            -0.004197021014988422,
            0.009796733036637306,
            0.029715625569224358,
            0.0234975665807724,
            -0.0061898063868284225,
            0.014967327006161213,
            0.03342878818511963,
            0.0007328091887757182,
            0.03421532362699509,
            -0.03321024775505066,
            -0.0002876549551729113,
            0.01537634339183569,
            -0.036685243248939514,
            -0.0009888304630294442,
            0.023449743166565895,
            -0.007514819968491793,
            0.03478023782372475,
            0.022166982293128967,
            0.020830024033784866,
            0.004597772844135761,
            -0.01602623239159584,
            -0.025519832968711853,
            0.029836930334568024,
            0.011295824311673641,
            0.012743723578751087,
            -0.028773056343197823,
            0.009340721182525158,
            0.02801055833697319,
            -0.02744114398956299,
            0.023534739390015602,
            -0.02184915356338024,
            -0.01657010056078434,
            0.024797284975647926,
            -0.004988974891602993,
            0.02926524542272091,
            -0.005462939850986004,
            -0.0319429412484169,
            0.004700056742876768,
            0.010027694515883923,
            0.013720642775297165,
            0.011282659135758877,
            -0.026984700933098793,
            -0.019129743799567223,
            -0.02804107964038849,
            0.003006397280842066,
            -0.0052221836522221565,
            -0.014920981600880623,
            0.0023115051444619894,
            -0.019090354442596436,
            0.005176190752536058,
            -0.036254748702049255,
            -0.016366152092814445,
            0.001868529710918665,
            0.0022045935038477182,
            0.000525253766681999,
            0.010477475821971893,
            0.02042466774582863,
            0.020683176815509796,
            0.020774681121110916,
            -0.0066604227758944035,
            0.002337896963581443,
            0.009454939514398575,
            0.02040378749370575,
            0.0038273248355835676,
            -0.004788955673575401,
            0.0038527813740074635,
            -0.014205437153577805,
            -0.005897723603993654,
            -0.013686059974133968,
            0.012827994301915169,
            -0.025223204866051674,
            -0.012641592882573605,
            0.02708086185157299,
            0.03155007213354111,
            -0.008708699606359005,
            0.016820495948195457,
            0.007126341108232737,
            -0.014270953834056854,
            -0.02225005440413952,
            -0.022488746792078018,
            -0.017509115859866142,
            -0.022603759542107582,
            -0.0005071763298474252,
            -0.00995037890970707,
            -0.011278506368398666,
            -0.017254849895834923,
            0.007145433686673641,
            -0.02086426503956318,
            -0.02135717310011387,
            -0.01608547754585743,
            -0.027300823479890823,
            0.019075388088822365,
            0.010876541025936604,
            0.01963801309466362,
            -0.017911488190293312,
            0.015426520258188248,
            0.0140733877196908,
            0.018516002222895622,
            -0.02183777466416359,
            0.006591880228370428,
            0.027500053867697716,
            -0.014603852294385433,
            0.026381708681583405,
            0.011883161030709743,
            -0.009251049719750881,
            0.024496333673596382,
            0.018648797646164894,
            0.015856893733143806,
            -0.0054733771830797195,
            -0.009356462396681309,
            0.012275832705199718,
            0.006832605693489313,
            0.014337895438075066,
            -0.025639230385422707,
            -0.005222824867814779,
            -0.0472378171980381,
            -0.0071482788771390915,
            -0.00785626657307148,
            -0.014729603193700314,
            -0.00918770395219326,
            -0.006809733808040619,
            -0.016717681661248207,
            0.005059733055531979,
            -0.03259271755814552,
            0.00942542776465416,
            -0.0029126263689249754,
            -0.041948601603507996,
            0.022690311074256897,
            -0.024790801107883453,
            0.011805154383182526,
            -0.01830928958952427,
            -0.030165627598762512,
            0.014985408633947372,
            -0.027921315282583237,
            0.03930550068616867,
            -0.0281336922198534,
            0.013353574089705944,
            0.0020373857114464045,
            -0.018481817096471786,
            -0.015667853876948357,
            -0.014991536736488342,
            -0.008276058360934258,
            0.04155898466706276,
            -0.02765544503927231,
            -0.008353490382432938,
            0.026641029864549637,
            -0.003606510581448674,
            0.01396174356341362,
            0.03275483846664429,
            0.022687911987304688,
            0.018463606014847755,
            0.026675937697291374,
            0.014779463410377502,
            0.04516955837607384,
            0.025208178907632828,
            0.015307795256376266,
            -0.004609950352460146,
            -0.0016114411409944296,
            0.019902383908629417,
            0.01324107963591814,
            0.007208052556961775,
            0.02206648327410221,
            -0.029801348224282265,
            -0.013307763263583183,
            0.008434365503489971,
            0.004248225130140781,
            0.025021998211741447,
            -0.0026890847366303205,
            -0.021529745310544968,
            0.057811588048934937,
            -0.007714233361184597,
            -0.01251902338117361,
            -0.031224602833390236,
            -0.025129521265625954,
            0.0033782138489186764,
            0.0032326739747077227,
            0.0074889399111270905,
            -0.01857752352952957,
            -0.0037833305541425943,
            -0.01937348023056984,
            0.011499322950839996,
            0.01429804414510727,
            -0.03758925572037697,
            -0.05342547595500946,
            -0.033192675560712814,
            -0.014945628121495247,
            -0.02553880773484707,
            5.1925177103839815e-05,
            0.02278102934360504,
            -0.025191055610775948,
            -0.018561815842986107,
            -0.011407647281885147,
            0.02640904113650322,
            -0.0010744839673861861,
            -0.02360265888273716,
            0.0038233899977058172,
            0.0009228494600392878,
            -0.02211858332157135,
            0.02672778069972992,
            0.03185391053557396,
            0.015282432548701763,
            0.03042696788907051,
            -0.0008180173463188112,
            -0.025455644354224205,
            0.0007021714700385928,
            -0.0286179780960083,
            -0.030810635536909103,
            0.035543762147426605,
            -0.011505511589348316,
            -0.03167638182640076,
            -0.006148096639662981,
            -0.02236635610461235,
            0.01661975495517254,
            0.007366427220404148,
            -0.0407555028796196,
            -0.016680588945746422,
            -0.013340559788048267,
            0.018949925899505615,
            -0.013094143010675907,
            -0.023557065054774284,
            0.018270323053002357,
            0.023713992908596992,
            -0.0006474917754530907,
            0.007765399292111397,
            -0.02113388665020466,
            -0.0005225262138992548,
            0.024754907935857773,
            0.02017567679286003,
            0.017618732526898384,
            -0.043934717774391174,
            -0.00017012887110468,
            0.015827156603336334,
            0.018277477473020554,
            -0.014737644232809544,
            0.004386682529002428,
            -0.02935335598886013,
            0.059249311685562134,
            0.005626654718071222,
            0.04128319025039673,
            -0.023543184623122215,
            -0.020732250064611435,
            0.00498097063973546,
            0.0018359450623393059,
            0.012217853218317032,
            -0.026966825127601624,
            0.016143357381224632,
            0.012944412417709827,
            -0.03309514373540878,
            0.01233843807131052,
            -0.01643352396786213,
            -0.02826816216111183,
            0.015988333150744438,
            0.008905631490051746,
            -0.0329020693898201,
            0.03448009490966797,
            0.0036842923145741224,
            -0.0153804961591959,
            -0.014428108930587769,
            -0.02505268156528473,
            0.01082912739366293,
            0.0015252918237820268,
            0.015693575143814087,
            -0.019909197464585304,
            0.026281381025910378,
            0.026575738564133644,
            -0.025412574410438538,
            -0.039377473294734955,
            -0.024996312335133553,
            0.0034476101864129305,
            -0.03164571151137352,
            -0.002137473551556468,
            -0.016131490468978882,
            -0.010946148075163364,
            -0.038959652185440063,
            0.013081471435725689,
            0.027027782052755356,
            -0.010675315745174885,
            -0.023653492331504822,
            0.0070493826642632484,
            0.01917753927409649,
            0.034717969596385956,
            -0.011264709755778313,
            -0.030349116772413254,
            0.00048305513337254524,
            0.02925071306526661,
            0.0311517845839262,
            0.01470713596791029,
            0.022539004683494568,
            0.02036169357597828,
            -0.009824971668422222,
            0.019429737702012062,
            0.040408845990896225,
            0.022754449397325516,
            -0.0030057162512093782,
            0.0055516972206532955,
            -0.008213473483920097,
            0.03246637061238289,
            -0.014749771915376186,
            -0.007499798201024532,
            0.017551308497786522,
            0.017314493656158447,
            0.014954992569983006,
            -0.03277462348341942,
            -0.005564088001847267,
            0.01687556877732277,
            -0.0005217569414526224,
            -0.010106698609888554,
            0.013485752046108246,
            0.027892131358385086,
            -0.016776587814092636,
            0.020539836958050728,
            -0.020036868751049042,
            -0.015560859814286232,
            -0.025254858657717705,
            -0.01113464031368494,
            -0.041602637618780136,
            0.0076844156719744205,
            0.025775671005249023,
            0.018349971622228622,
            -0.01270880363881588,
            -0.026709232479333878,
            -0.001850818982347846,
            -0.0068883090279996395,
            0.014631479047238827,
            0.013928907923400402,
            -0.00543834688141942,
            0.013516079634428024,
            0.024637872353196144,
            0.014178554527461529,
            -0.019264230504631996,
            0.022614331915974617,
            0.02510632760822773,
            -0.03533335030078888,
            -0.010477913543581963,
            -0.011046813800930977,
            0.024095220491290092,
            -0.029766375198960304,
            -0.01184767484664917,
            -0.010245569981634617,
            0.000466782134026289,
            0.034945566207170486,
            0.011683495715260506,
            0.009745079092681408,
            -0.030938927084207535,
            0.0037279564421623945,
            -0.012688972055912018,
            -0.011145791038870811,
            0.019297167658805847,
            0.005169054958969355,
            -0.04687560722231865,
            -0.02185133844614029,
            -0.024469800293445587,
            0.05665053427219391,
            -0.009522603824734688,
            0.016920385882258415,
            -0.0019027875969186425,
            -0.013067184947431087,
            -0.022076409310102463,
            0.028233863413333893,
            0.012757730670273304,
            0.007793462369590998,
            -0.0017652837559580803,
            0.03036147728562355,
            -0.013037814758718014,
            0.028038782998919487,
            -0.03397292271256447,
            -0.026037730276584625,
            -0.02477944642305374,
            -0.013523705303668976,
            0.0007086751284077764,
            0.017743032425642014,
            0.016782686114311218,
            -0.014309166930615902,
            -0.02571002021431923,
            -0.02222694456577301,
            -0.0034571781288832426,
            -0.02232150174677372,
            0.027084775269031525,
            0.01246514543890953,
            0.00694619445130229,
            -0.023172631859779358,
            0.02149852178990841,
            -0.010573228821158409,
            -0.022028643637895584,
            0.0020371524151414633,
            -0.020724449306726456,
            0.018378114327788353,
            0.02204979956150055,
            0.01077068131417036,
            -0.014890207909047604,
            0.013852584175765514,
            -0.01920100674033165,
            0.026280440390110016,
            -0.006364356726408005,
            -0.01887383498251438,
            0.03014364093542099,
            -0.028034953400492668,
            0.012623855844140053,
            0.05289922654628754,
            0.002356342040002346,
            0.02888142690062523,
            0.006213332060724497,
            -0.015463068149983883,
            0.02991531230509281,
            0.014188899658620358,
            -0.02379801869392395,
            0.013451309874653816,
            0.011737695895135403,
            -0.04761454463005066,
            0.028855182230472565,
            -0.03244371712207794,
            0.017870817333459854,
            -0.02435503527522087,
            0.022897807881236076,
            -0.028839096426963806,
            -0.00929275806993246,
            0.001172474236227572,
            0.001219825237058103,
            0.013836233876645565,
            -0.033254846930503845,
            0.031025342643260956,
            0.021205933764576912,
            0.0349017009139061,
            -0.016213301569223404,
            0.006182014010846615,
            -0.006408305373042822,
            0.002510564401745796,
            0.03252311423420906,
            0.007077421061694622,
            0.013870478607714176,
            0.0231916643679142,
            0.051421768963336945,
            0.034709446132183075,
            0.02258652076125145,
            -0.016963442787528038,
            -0.030887026339769363,
            0.029161248356103897,
            0.01745101995766163,
            0.017519569024443626,
            -0.010456007905304432,
            0.024112746119499207,
            -0.006814820226281881,
            0.03505526855587959,
            0.009407482109963894,
            0.009744711220264435,
            0.017895611003041267,
            -0.017600545659661293,
            0.019487490877509117,
            0.014620845206081867,
            -0.014884140342473984,
            -0.021363819018006325,
            0.027479832991957664,
            0.008223630487918854,
            0.030424531549215317,
            -0.0011494866339489818,
            -0.045033231377601624,
            0.029105650261044502,
            -0.0032341922633349895,
            0.026114054024219513,
            -0.025396492332220078,
            -0.04647258296608925,
            -0.009263187646865845,
            -0.044258859008550644,
            -0.015133208595216274,
            0.03744608163833618,
            -0.007566589396446943,
            0.028903251513838768,
            -0.006639359053224325,
            -0.004199751652777195,
            -0.023566391319036484,
            0.008900923654437065,
            0.0210694819688797,
            0.016223113983869553,
            0.03613744303584099,
            0.012394065037369728,
            0.02337305247783661,
            -0.015590796247124672,
            0.001984188798815012,
            0.012404055334627628,
            -0.0055395164526999,
            0.011972527951002121,
            0.0034944405779242516,
            0.004126346204429865,
            -0.01424957811832428,
            0.03160941228270531,
            0.022379552945494652,
            0.012098907493054867,
            -0.01492035947740078,
            -0.02588639035820961,
            0.024351218715310097,
            0.01207337249070406,
            0.005537943448871374,
            -0.024523988366127014,
            0.002963234903290868,
            -0.036922387778759,
            -0.0011866214917972684,
            -0.027036521583795547,
            0.001108598429709673,
            -0.055609703063964844,
            -0.011824400164186954,
            0.017887573689222336,
            -0.047982823103666306,
            -0.030777698382735252,
            0.007744654547423124,
            -0.006790608633309603,
            -0.018412062898278236,
            -0.026126636192202568,
            -0.01949532888829708,
            -0.002745342440903187,
            -0.006165618542581797,
            -0.008809036575257778,
            0.029087867587804794,
            0.03135016933083534,
            0.010730905458331108,
            0.00550020532682538,
            -0.004043490160256624,
            0.017747854813933372,
            0.0013654365902766585,
            -0.008084678091108799,
            -0.025850698351860046,
            -0.02550950087606907,
            -0.026301130652427673,
            0.016724711284041405,
            0.006166451144963503,
            -0.0071811615489423275,
            -0.026332229375839233,
            -0.006738125346601009,
            0.025340385735034943,
            -0.025851916521787643,
            0.0047696479596197605,
            0.005982988979667425,
            -0.022309865802526474,
            0.015516589395701885,
            -0.027805980294942856,
            -0.006287786178290844,
            -0.029835831373929977,
            -0.01775561273097992,
            -0.009297367185354233,
            -0.008893951773643494,
            0.008815228007733822,
            0.032587844878435135,
            0.026153521612286568,
            0.016180096194148064,
            -0.010079934261739254,
            0.009240331128239632,
            0.011190944351255894,
            0.011722416616976261,
            -0.014154808595776558,
            -0.004285195376724005,
            0.01999097689986229,
            -0.010501397773623466,
            -0.025624601170420647,
            -0.009452265687286854,
            -0.0004546817799564451,
            -0.034863270819187164,
            0.03101835586130619,
            -0.010743766091763973,
            0.002300054067745805,
            -0.027283594012260437,
            0.02646661177277565,
            0.033682968467473984,
            -0.0017797618638724089,
            0.004854886326938868,
            -0.02944919839501381,
            -0.001811761292628944,
            -0.0017361413920298219,
            0.034317661076784134,
            0.020546499639749527,
            -0.0035588317550718784,
            -0.006498285103589296,
            -0.0014987068716436625,
            -0.027408303692936897,
            0.04064454138278961,
            0.025063293054699898,
            -0.01895553432404995,
            -0.019632888957858086,
            -0.003563698846846819,
            -0.03818664327263832,
            -0.03180806338787079,
            0.01180531457066536,
            -0.013814075849950314,
            0.012828235514461994,
            -0.025579437613487244,
            -0.007396278902888298,
            0.012255522422492504,
            0.014382529072463512,
            0.0009776715887710452,
            -0.02866332419216633,
            -0.0347510427236557,
            -0.010412887670099735,
            0.0030211943667382,
            -0.0028843036852777004,
            0.02201281674206257,
            -0.0012015656102448702,
            0.024423368275165558,
            -0.026382872834801674,
            0.004340775776654482,
            -0.00853029265999794,
            -0.012642419897019863,
            -0.0626789927482605,
            0.04099971428513527,
            0.016961652785539627,
            0.02613428793847561,
            -0.014760194346308708,
            -0.00429559824988246,
            -0.019101472571492195,
            0.02814404107630253,
            0.011530064046382904,
            -0.043669212609529495,
            -0.025205489248037338,
            -0.012760977260768414,
            0.009355033747851849,
            0.03490079939365387,
            -0.03558925539255142,
            0.00043123564682900906,
            0.005627673584967852,
            0.030619101598858833,
            0.001800609054043889,
            0.008993200026452541,
            0.010152380913496017,
            0.020063508301973343,
            -0.005895054899156094,
            0.02211937867105007,
            0.015950476750731468,
            0.011531817726790905,
            -0.027662716805934906,
            -0.012564544565975666,
            -0.008312128484249115,
            0.017623335123062134,
            -0.013355264440178871,
            0.013072790578007698,
            -0.0058976225554943085,
            0.012717755511403084,
            -0.006390942260622978,
            0.019364207983016968,
            -0.006176278926432133,
            0.012072828598320484,
            -0.0129203787073493,
            -0.009043813683092594,
            0.005322805605828762,
            -7.222052317956695e-06,
            -0.02153640054166317,
            0.03305450454354286,
            -0.015643401071429253,
            -0.02574038691818714,
            0.009866299107670784,
            0.0304098017513752,
            -0.03406522795557976,
            -0.018306057900190353,
            -0.01478460431098938,
            -0.01739887334406376,
            -0.020478596910834312,
            -0.014748587273061275,
            0.006908409297466278,
            0.0013693065848201513,
            0.020451627671718597,
            0.03612922504544258,
            -0.030250148847699165,
            -0.008125826716423035,
            -0.05673960968852043,
            0.016483411192893982,
            -0.04161183163523674,
            0.016097117215394974,
            -0.02197408303618431,
            0.02491445094347,
            0.035988934338092804,
            -0.00851301196962595,
            0.0019436079310253263,
            -0.01489794161170721,
            -0.012003219686448574,
            0.03821514919400215,
            -0.009059738367795944,
            -0.01826472021639347,
            -0.006653074640780687,
            0.009374639950692654,
            -0.0202641561627388,
            0.019357478246092796,
            -0.02165289595723152,
            -0.0003383657895028591,
            -0.025910135358572006,
            0.006331363692879677,
            0.032151609659194946,
            -0.03888988494873047,
            -0.008319497108459473,
            0.0014532784698531032,
            0.026530152186751366,
            -0.008776934817433357,
            -0.0006980116595514119,
            -0.01144549809396267,
            -0.03814863786101341,
            0.025631221011281013,
            -0.012778189964592457,
            -0.01901613175868988,
            -0.025837136432528496,
            -0.019132127985358238,
            -0.010077834129333496,
            -0.003610089886933565,
            0.01538638025522232,
            0.004242586437612772,
            0.020714247599244118,
            -0.013029482215642929,
            0.02380472980439663,
            -0.015815824270248413,
            -0.013057156465947628,
            0.018885789439082146,
            -0.0017343685030937195,
            0.01279895007610321,
            -0.002139513147994876,
            -0.01485692709684372,
            0.03863256797194481,
            -0.012166802771389484,
            -0.004122847691178322,
            -0.02504999376833439,
            -0.03177257999777794,
            0.02324678562581539,
            -0.005441136658191681,
            -0.005861950106918812,
            0.02869667112827301,
            0.023263869807124138,
            0.019387563690543175,
            -0.03580888733267784,
            -0.02170746400952339,
            0.005099937319755554,
            0.02528523840010166,
            -0.011460884474217892,
            0.009330020286142826,
            -0.013194624334573746,
            0.013685503974556923,
            0.029441041871905327,
            0.014050351455807686,
            -0.01326144952327013,
            -0.03262476995587349,
            0.03094363398849964,
            -0.02764243632555008,
            0.01437348872423172,
            0.011503743007779121,
            -0.0007707687327638268,
            0.026604998856782913,
            0.0006242280942387879,
            -0.058554429560899734,
            0.025286249816417694,
            -0.008699601516127586,
            0.023117290809750557,
            0.0011477724183350801,
            -0.01602191850543022,
            0.020654411986470222,
            0.004986237734556198,
            0.010169661603868008,
            0.024189092218875885,
            -0.03308888524770737,
            0.001526069245301187,
            -0.03366546705365181,
            0.0028309959452599287,
            -0.024384068325161934,
            0.017900729551911354,
            0.040643610060214996,
            0.0018347174627706409,
            0.00920968595892191,
            0.026502298191189766,
            0.02420451119542122,
            -0.03315631300210953,
            -0.0016448501264676452,
            0.027325691655278206,
            0.015597062185406685,
            -0.020417075604200363,
            0.05622163414955139,
            -0.013493497855961323,
            -0.008988936431705952,
            0.037026502192020416,
            0.03948693349957466,
            0.012807789258658886,
            0.006378584541380405,
            -0.024617131799459457,
            0.0038619765546172857,
            0.023074204102158546,
            -0.010499829426407814,
            0.015695719048380852,
            0.02700391784310341,
            -0.002423674799501896,
            0.01655602641403675,
            0.019043685868382454,
            0.012828531675040722,
            -0.01288095023483038,
            -0.008453665301203728,
            0.008901279419660568,
            -0.07861462980508804,
            -0.0314168743789196,
            -0.004900567699223757,
            0.021501347422599792,
            0.03628791123628616,
            0.016934983432292938,
            0.002569726435467601,
            0.031632211059331894,
            0.019822295755147934,
            -0.019357385113835335,
            0.028055276721715927,
            0.0085984505712986,
            0.018528549000620842,
            -0.0037587853148579597,
            -0.011178217828273773,
            -0.06440912187099457,
            0.010819179937243462,
            -0.004739726893603802,
            0.019668472930788994,
            -0.010321548208594322,
            -0.02489515021443367,
            -0.02590116299688816,
            -0.022281356155872345,
            -0.024167392402887344,
            -0.010463508777320385,
            0.0058058397844433784,
            -0.019472172483801842,
            -0.005561179015785456
        ],
        [
            0.03701148182153702,
            -0.0266770888119936,
            0.030627703294157982,
            -0.017732122913002968,
            -0.006101259030401707,
            -0.04003904387354851,
            0.01575382985174656,
            -0.006949414499104023,
            -0.0010322186863049865,
            0.002862033899873495,
            -0.011521183885633945,
            -0.012086139991879463,
            -0.013406846672296524,
            -0.017651081085205078,
            0.05085982754826546,
            0.008967585861682892,
            0.012710809707641602,
            0.007655410096049309,
            0.005447503179311752,
            -0.014418326318264008,
            -0.02674754150211811,
            0.022532911971211433,
            -0.009266284294426441,
            0.005785816814750433,
            0.018214410170912743,
            -0.015834931284189224,
            -0.006038296036422253,
            -0.018832845613360405,
            -0.03516001999378204,
            0.0002604207838885486,
            0.011665301397442818,
            -0.01487155631184578,
            -0.030700158327817917,
            0.027559032663702965,
            -0.009080365300178528,
            -0.02404817007482052,
            -0.031884532421827316,
            0.008974318392574787,
            0.016571737825870514,
            0.013627701438963413,
            -0.009230632334947586,
            -0.025951329618692398,
            -0.030206063762307167,
            -0.005020707845687866,
            0.008776456117630005,
            0.018954314291477203,
            0.0031234952621161938,
            -0.0135693633928895,
            -0.004437569063156843,
            -0.036377377808094025,
            -0.0050670732744038105,
            0.016749367117881775,
            -0.03251428157091141,
            0.00012353909551165998,
            -0.005952129606157541,
            0.010251112282276154,
            -0.008570555597543716,
            -0.031765252351760864,
            0.030195998027920723,
            -0.002658874960616231,
            -0.03290421515703201,
            0.03364993631839752,
            -0.012109198607504368,
            -0.01267177239060402,
            -0.03267446532845497,
            -0.018203705549240112,
            0.006842515431344509,
            -0.029506884515285492,
            -0.028236592188477516,
            -0.007472157943993807,
            0.015771400183439255,
            0.010642320849001408,
            -0.008134915493428707,
            0.022592702880501747,
            -0.028142882511019707,
            -0.021296460181474686,
            -0.008465860970318317,
            -0.02032409980893135,
            0.004847396165132523,
            0.015219686552882195,
            0.015695730224251747,
            0.046663518995046616,
            0.0019400794990360737,
            -0.015321017242968082,
            -0.02941751852631569,
            0.00039558345451951027,
            -0.010733765549957752,
            0.002735408954322338,
            0.01603594608604908,
            -0.007612251676619053,
            0.015075244940817356,
            -0.026284867897629738,
            0.019436970353126526,
            -0.020722337067127228,
            0.02465023472905159,
            0.022210735827684402,
            0.061400145292282104,
            0.00718736182898283,
            -0.024843445047736168,
            -0.003800412407144904,
            0.02323739416897297,
            -0.018325218930840492,
            0.018000612035393715,
            -0.032805752009153366,
            0.02665996551513672,
            -0.015475379303097725,
            -0.0028293896466493607,
            -0.02480781450867653,
            0.0059410096146166325,
            0.01994883082807064,
            0.004241299815475941,
            -0.023901822045445442,
            -0.00724033685401082,
            -0.023555953055620193,
            -0.01922033168375492,
            0.02804691344499588,
            0.025436092168092728,
            0.0032679843716323376,
            0.027849320322275162,
            0.013559685088694096,
            0.02723648212850094,
            -0.00848452653735876,
            0.040220603346824646,
            0.008105944842100143,
            0.030377138406038284,
            -0.02020540088415146,
            -0.02572505734860897,
            0.009294558316469193,
            0.019060572609305382,
            -0.021309852600097656,
            0.02937914803624153,
            0.0069837141782045364,
            0.04680129885673523,
            0.03338564187288284,
            -0.007990088313817978,
            0.028806082904338837,
            0.005627411417663097,
            0.048112861812114716,
            -0.010701035149395466,
            -0.046562742441892624,
            -0.004699483048170805,
            -0.003958472516387701,
            0.033080972731113434,
            -0.010884311981499195,
            -0.04027171432971954,
            0.05223777890205383,
            -0.013869511894881725,
            0.014168142341077328,
            0.0038726564962416887,
            -0.02504156343638897,
            0.046784114092588425,
            0.054385602474212646,
            -0.0013503931695595384,
            0.02575620450079441,
            0.02054513804614544,
            -0.051202189177274704,
            -0.012078091502189636,
            0.0032975117210298777,
            -0.02745373360812664,
            -0.004597525577992201,
            0.029773980379104614,
            0.0009715961059555411,
            0.008139021694660187,
            -0.03774644061923027,
            0.0012152254348620772,
            0.0440123975276947,
            0.023259788751602173,
            -0.0011063896818086505,
            -0.036101195961236954,
            0.031827978789806366,
            0.023934654891490936,
            0.03361969441175461,
            -0.009134847670793533,
            -0.02030275948345661,
            -0.0010884690564125776,
            0.0324481725692749,
            -0.0007194878999143839,
            -0.009636306203901768,
            -0.029318349435925484,
            0.006567260716110468,
            0.0015215823659673333,
            0.001095513696782291,
            -0.02892368845641613,
            0.016196586191654205,
            0.002583092777058482,
            0.017252059653401375,
            0.02603304013609886,
            -0.012688237242400646,
            -0.011493089608848095,
            0.0003750163596123457,
            0.017914971336722374,
            0.030837249010801315,
            -0.03725714609026909,
            -0.008801315911114216,
            -0.011621727608144283,
            0.0004909376730211079,
            0.022878771647810936,
            0.005083792377263308,
            0.016563674435019493,
            0.00797481182962656,
            0.008810278959572315,
            -0.004985320381820202,
            0.007889102213084698,
            -0.021694866940379143,
            0.02333766594529152,
            0.010860051028430462,
            0.006515584886074066,
            0.035352274775505066,
            -0.01236438937485218,
            -0.015382422134280205,
            -0.01798270456492901,
            0.005795083940029144,
            0.017978472635149956,
            0.020901428535580635,
            0.03196430951356888,
            -0.014168825000524521,
            -0.011745224706828594,
            0.0012719808146357536,
            -0.002802446484565735,
            0.03669103980064392,
            -0.02477346360683441,
            -0.009386963210999966,
            0.003891417756676674,
            -0.009358849376440048,
            0.002914624521508813,
            0.004054602701216936,
            0.01246225368231535,
            -0.02210199274122715,
            -0.03766725957393646,
            -0.0016626114957034588,
            0.02031378634274006,
            -0.021875305101275444,
            0.0040164547972381115,
            0.018794352188706398,
            0.0011260940227657557,
            0.014066528528928757,
            0.053759731352329254,
            -0.0019648494198918343,
            0.014816073700785637,
            0.0035291535314172506,
            0.02150852233171463,
            -0.020004302263259888,
            0.03217284753918648,
            0.028973903506994247,
            -0.005590138025581837,
            -0.016551973298192024,
            -0.019958334043622017,
            -0.006944594439119101,
            -0.029804039746522903,
            0.011182375252246857,
            -0.031072793528437614,
            0.011973378248512745,
            -0.013562653213739395,
            0.013725947588682175,
            -0.019729293882846832,
            -0.007470520678907633,
            0.02330518141388893,
            0.0030273920856416225,
            0.010726965963840485,
            0.03433994948863983,
            -0.009482680819928646,
            0.0322134830057621,
            -0.044607196003198624,
            -0.012632510624825954,
            -0.014727848581969738,
            -0.00988354068249464,
            -0.009980953298509121,
            -0.022036351263523102,
            -0.030322179198265076,
            0.0005026844446547329,
            -0.03829582780599594,
            -0.011879205703735352,
            0.019664928317070007,
            0.0003055199049413204,
            -0.0017983898287639022,
            -0.022039905190467834,
            0.004621744155883789,
            -0.012368832714855671,
            -0.0013444691430777311,
            -0.03168826177716255,
            -0.024161536246538162,
            0.016074683517217636,
            -0.022359715774655342,
            -0.01732409931719303,
            0.02347632311284542,
            -0.005943953059613705,
            0.032054878771305084,
            0.0136768389493227,
            0.01544850692152977,
            0.02362726256251335,
            -0.02418157272040844,
            0.023610278964042664,
            -0.01003528106957674,
            0.017746340483427048,
            0.04136676341295242,
            -0.012813009321689606,
            -0.004132071975618601,
            -0.004140723031014204,
            -0.0713326632976532,
            0.022528188303112984,
            -0.0020438325591385365,
            0.029993074014782906,
            0.009683949872851372,
            0.012001821771264076,
            -0.02068488486111164,
            -0.026078680530190468,
            -0.038294341415166855,
            0.014678321778774261,
            -0.0193113274872303,
            0.0006377808167599142,
            -0.027478007599711418,
            -0.037838295102119446,
            0.027078507468104362,
            -0.014682907611131668,
            0.00476756552234292,
            0.0030089630745351315,
            -0.020896179601550102,
            0.016952957957983017,
            0.0070734331384301186,
            -0.004417405929416418,
            0.012041301466524601,
            -0.010564331896603107,
            0.006661299616098404,
            0.0032434111926704645,
            -0.03763042017817497,
            0.0034975202288478613,
            0.03377822786569595,
            -0.009292745031416416,
            0.001062208553776145,
            0.03114476427435875,
            -0.0058566248044371605,
            -0.026867372915148735,
            -0.011132542975246906,
            -0.007671501953154802,
            -0.03371821716427803,
            -0.006922825239598751,
            0.005235613789409399,
            -0.016430364921689034,
            0.009870248846709728,
            -0.016871245577931404,
            -0.04571863263845444,
            0.03599844500422478,
            0.0007349633960984647,
            0.00800029281526804,
            -0.03648410364985466,
            0.0036960789002478123,
            0.009841609746217728,
            -0.02500707283616066,
            0.01871107518672943,
            0.02701813355088234,
            0.008234948851168156,
            0.010017240419983864,
            -0.025330454111099243,
            -0.0021283954847604036,
            0.014626805670559406,
            0.0016847074730321765,
            0.023552311584353447,
            0.030237197875976562,
            0.02228509820997715,
            0.021051855757832527,
            0.008888881653547287,
            -0.015371769666671753,
            0.014829199761152267,
            -0.01573765277862549,
            -0.025896906852722168,
            0.0014612781815230846,
            0.0259650070220232,
            -0.019231455400586128,
            0.025843922048807144,
            0.02784050814807415,
            0.0317552350461483,
            0.02696153149008751,
            0.00901062786579132,
            -0.00964825414121151,
            -0.021890461444854736,
            0.00023740575124975294,
            0.0014302359195426106,
            -0.0276007242500782,
            -0.04121429845690727,
            -0.025689374655485153,
            -0.033769842237234116,
            -0.02092006802558899,
            0.035897813737392426,
            0.025110725313425064,
            0.027998046949505806,
            -0.014525717124342918,
            0.007121336180716753,
            -0.019970526918768883,
            -0.03285031020641327,
            -0.03867639601230621,
            -0.002477710135281086,
            -0.012272226624190807,
            0.0009300626697950065,
            -0.03260833024978638,
            0.04945861175656319,
            0.016473708674311638,
            -0.03487429395318031,
            0.013623595237731934,
            -0.007879524491727352,
            -0.009546097368001938,
            0.02626791037619114,
            0.009102655574679375,
            -0.023285172879695892,
            0.015288763679564,
            -0.02337116375565529,
            -0.011790657415986061,
            -0.008213560096919537,
            0.005857621785253286,
            -0.013804194517433643,
            -0.003735325997695327,
            -0.002454358385875821,
            -0.01277953665703535,
            -0.007380140013992786,
            -0.05354313179850578,
            0.005642956122756004,
            0.027781004086136818,
            0.026000872254371643,
            0.008065147325396538,
            0.03442161902785301,
            0.010376974940299988,
            0.02011876553297043,
            0.0014566276222467422,
            0.04257836937904358,
            0.01705939881503582,
            0.06269696354866028,
            -0.02117055654525757,
            -0.041497308760881424,
            0.024846548214554787,
            0.027898728847503662,
            0.00931497197598219,
            0.017633678391575813,
            -0.02957555465400219,
            0.0029427241533994675,
            -0.037214361131191254,
            -0.0041784909553825855,
            -0.034285515546798706,
            0.034277912229299545,
            -0.05371975526213646,
            -0.010315676219761372,
            0.045102499425411224,
            0.03947894647717476,
            0.0092318095266819,
            -0.015433911234140396,
            -0.027912061661481857,
            0.002445017686113715,
            -0.019174771383404732,
            -0.022690588608384132,
            -0.09833722561597824,
            -0.023692702874541283,
            -0.03212566673755646,
            0.013922988437116146,
            -0.03203839436173439,
            -0.00547917140647769,
            -0.019962649792432785,
            -0.028197675943374634,
            -0.017667148262262344,
            0.0027044194284826517,
            -0.04129130393266678,
            -0.031712405383586884,
            -0.0010605634888634086,
            -0.04160228371620178,
            0.013255631551146507,
            0.029225651174783707,
            -0.001418968429788947,
            0.0008204945479519665,
            -0.021600963547825813,
            0.03421926870942116,
            0.032663505524396896,
            -0.006674973294138908,
            0.007553694304078817,
            0.02858533337712288,
            0.009889774024486542,
            0.034974608570337296,
            -0.01727376878261566,
            0.025064315646886826,
            -0.004019927699118853,
            -0.0354621596634388,
            -0.016246842220425606,
            0.007576697506010532,
            0.02624734491109848,
            -0.0065581160597503185,
            -0.003897328395396471,
            0.00601490493863821,
            0.029320791363716125,
            0.05367797985672951,
            0.015590287744998932,
            0.00020739306637551636,
            0.012352561578154564,
            -0.06164030730724335,
            0.025843914598226547,
            0.03507610037922859,
            0.029341191053390503,
            -0.006013554986566305,
            -0.004482931457459927,
            -0.03857450559735298,
            -0.009908534586429596,
            0.001043350319378078,
            0.01790425553917885,
            -0.005157100036740303,
            -0.020659636706113815,
            -0.01684476062655449,
            -0.005048725288361311,
            -0.02775118127465248,
            -0.009953227825462818,
            0.004043016582727432,
            0.012591809034347534,
            -0.006817302666604519,
            0.0040511745028197765,
            0.0014164316235110164,
            -0.015984484925866127,
            -0.024399571120738983,
            0.01954568549990654,
            0.022292673587799072,
            -0.0051843770779669285,
            -0.008327171206474304,
            -0.00965458806604147,
            -0.010190877132117748,
            0.008306281641125679,
            -0.010707290843129158,
            0.012005994096398354,
            0.015012573450803757,
            0.02942206710577011,
            0.004427205305546522,
            0.038031432777643204,
            -0.04507681727409363,
            -8.380461804335937e-05,
            -0.035540226846933365,
            0.007534596603363752,
            0.010234922170639038,
            0.03433245047926903,
            -0.01730733923614025,
            -0.004941144492477179,
            -0.009860692545771599,
            0.0019094730960205197,
            -0.02618645690381527,
            -0.009653604589402676,
            -0.023337997496128082,
            0.007251367438584566,
            -0.0095948101952672,
            0.01587476208806038,
            0.03230622783303261,
            -0.0301437359303236,
            -0.010504556819796562,
            -0.01135072112083435,
            0.032213930040597916,
            0.0277146827429533,
            -0.005001365207135677,
            -0.02731003239750862,
            0.006724780425429344,
            0.017361650243401527,
            -0.011788354255259037,
            -0.008392450399696827,
            0.03633340075612068,
            -0.008822749368846416,
            0.006525457836687565,
            -0.02815443091094494,
            -0.006162400357425213,
            0.027879269793629646,
            -0.023542361333966255,
            -0.005811609327793121,
            -0.013477694243192673,
            -0.028529483824968338,
            0.02999376691877842,
            0.004880847875028849,
            0.0386379212141037,
            -0.013564269058406353,
            0.03705943375825882,
            -0.0008123557199724019,
            0.021182658150792122,
            -0.014607282355427742,
            -0.022042684257030487,
            -0.01973102241754532,
            0.01983526349067688,
            0.046618301421403885,
            0.005192601587623358,
            -0.006617329549044371,
            0.025056974962353706,
            -0.0442633256316185,
            0.006582825444638729,
            0.006168824154883623,
            -0.019972145557403564,
            0.03830931708216667,
            0.014518069103360176,
            0.008163209073245525,
            0.009709482081234455,
            0.011408211663365364,
            -0.0249447263777256,
            0.02492540143430233,
            -0.005721962545067072,
            -0.0062526375986635685,
            0.017176596447825432,
            0.015146435238420963,
            0.023492634296417236,
            0.0038682823069393635,
            -0.021215146407485008,
            -0.024072032421827316,
            0.00014534989895764738,
            -0.00614016130566597,
            -0.018817145377397537,
            0.007787290029227734,
            -0.06340458244085312,
            0.016646599397063255,
            -0.033354900777339935,
            0.01692509651184082,
            0.024853471666574478,
            0.02192654088139534,
            -0.007941984571516514,
            0.03161344677209854,
            0.04044555500149727,
            0.021970046684145927,
            0.013409139588475227,
            -0.01887498050928116,
            0.012086153961718082,
            0.03525235131382942,
            -0.023727405816316605,
            0.025672977790236473,
            0.030661260709166527,
            -0.015051525086164474,
            -0.023325281217694283,
            0.03385268151760101,
            0.027069969102740288,
            0.019078856334090233,
            0.024113141000270844,
            0.001572098582983017,
            -0.006492628715932369,
            0.019422747194767,
            0.011782205663621426,
            0.028116323053836823,
            0.03090808354318142,
            0.010515657253563404,
            -0.035673413425683975,
            -0.015575018711388111,
            -0.03185649588704109,
            -0.006727434694766998,
            -0.002621571533381939,
            0.0017783826915547252,
            0.0006268678698688745,
            -0.02685287594795227,
            0.014032592996954918,
            -0.03532799705862999,
            0.024884166195988655,
            0.01912064105272293,
            0.045852918177843094,
            -0.034513261169195175,
            -0.0009590834961272776,
            -0.014449011534452438,
            -0.0003956164582632482,
            0.009254095144569874,
            0.030693836510181427,
            0.02534223161637783,
            0.04902920499444008,
            0.003332450520247221,
            0.012143822386860847,
            0.03062312677502632,
            0.0027446041349321604,
            -0.030447548255324364,
            -0.002582003129646182,
            -0.017799686640501022,
            -0.022183826193213463,
            0.011508454568684101,
            0.03911016136407852,
            0.027325903996825218,
            0.017609193921089172,
            0.03150571510195732,
            -0.02579377591609955,
            -0.015492552891373634,
            -0.00543402973562479,
            0.02535763569176197,
            -0.008721519261598587,
            -0.05015978589653969,
            -0.014884944073855877,
            -0.033146392554044724,
            -0.014586734585464,
            -0.010080433450639248,
            0.01027737371623516,
            -0.018002478405833244,
            0.02702726610004902,
            -0.019503867253661156,
            -0.027379462495446205,
            -0.019436396658420563,
            -0.010442971251904964,
            -0.026821570470929146,
            0.0037610004656016827,
            0.011137555353343487,
            0.017937593162059784,
            -0.005242485087364912,
            0.01671369932591915,
            -0.04100793972611427,
            0.014567025937139988,
            -0.002801302121952176,
            -0.031844932585954666,
            0.03543346747756004,
            0.02641727216541767,
            0.0054546380415558815,
            -0.0338653139770031,
            -0.003691917285323143,
            -0.02777763083577156,
            0.0037435214035212994,
            -0.02735489420592785,
            -0.014531231485307217,
            0.007827815599739552,
            0.04335113987326622,
            -0.005563012324273586,
            0.015297685749828815,
            -0.028103364631533623,
            0.030583593994379044,
            0.011135594919323921,
            -0.022622637450695038,
            0.00511021725833416,
            0.04423141106963158,
            0.006285317242145538,
            0.013533624820411205,
            0.01485647726804018,
            -0.016868948936462402,
            0.014705200679600239,
            -0.02105589397251606,
            0.07632598280906677,
            -0.002504106843844056,
            0.02733585238456726,
            -0.0022193249315023422,
            0.012505491264164448,
            -0.007255459204316139,
            -0.014435315504670143,
            -0.008370252326130867,
            0.03003636933863163,
            0.017047962173819542,
            -0.017977552488446236,
            0.024448886513710022,
            0.038868799805641174,
            0.0026294656563550234,
            0.013877026736736298,
            -0.006102244835346937,
            0.038870614022016525,
            0.014555939473211765,
            0.005624558310955763,
            -0.028850041329860687,
            -0.01176960114389658,
            0.0074919043108820915,
            -0.001301912940107286,
            -0.023280711844563484,
            -0.011059494689106941,
            0.02435409463942051,
            -0.007351293228566647,
            0.016513856127858162,
            0.038468196988105774,
            -0.006185913924127817,
            -0.024537894874811172,
            -0.029264112934470177,
            0.02495669759809971,
            0.02470889873802662,
            -0.017330385744571686,
            0.008015946485102177,
            0.024402068927884102,
            -0.014645498245954514,
            0.0051776268519461155,
            0.01458416786044836,
            -0.016514433547854424,
            0.019405312836170197,
            0.03574029356241226,
            -0.028213387355208397,
            0.022316448390483856,
            0.03742486238479614,
            0.031640637665987015,
            0.0023769503459334373,
            -0.01194620318710804,
            0.030439618974924088,
            0.03348349779844284,
            -0.004504527896642685,
            0.0010215064976364374,
            -0.001662574359215796,
            -0.004471318796277046,
            -0.013230190612375736,
            0.011103545315563679,
            0.0028651596512645483,
            -0.028802847489714622,
            0.020535437390208244,
            0.013759840279817581,
            -0.032765842974185944,
            -0.028500940650701523,
            0.01246890239417553,
            -0.02734452672302723,
            -0.0062941741198301315,
            0.01995224691927433,
            0.029451828449964523,
            0.019217446446418762,
            -0.03549289330840111,
            0.04334329813718796,
            0.0009259729413315654,
            0.021244339644908905,
            0.031454138457775116,
            0.008166543208062649,
            0.03426408767700195,
            0.0035146712325513363,
            -0.006494965869933367,
            -0.040997300297021866,
            0.03356727212667465,
            0.018703676760196686,
            -0.013294393196702003,
            -0.012861589901149273,
            0.020527131855487823,
            0.00029071522294543684,
            -0.020675668492913246,
            0.006063548382371664,
            -0.021297309547662735,
            0.02625158429145813,
            -0.004634265322238207,
            -0.01931823045015335,
            -0.036277804523706436,
            -0.04959001764655113,
            -0.009364113211631775,
            -0.020412616431713104,
            0.00991299282759428,
            -0.03205079957842827,
            0.012752199545502663,
            -0.01436071190983057,
            -0.0037380834110081196,
            0.00937814824283123,
            0.025246774777770042,
            0.02517286129295826,
            -0.026347694918513298,
            0.0037035266868770123,
            -0.004308274947106838,
            0.030586933717131615,
            -0.006249053403735161,
            0.053798891603946686,
            0.037824247032403946,
            -0.03858692944049835,
            0.030117260292172432,
            -0.015145284123718739,
            0.0038900787476450205,
            -0.037924692034721375,
            0.024140868335962296,
            0.013260032050311565,
            -0.023707054555416107,
            0.030662449076771736,
            0.024268006905913353,
            -0.01609727181494236,
            -0.014150864444673061,
            -0.0266270749270916,
            0.00947949755936861,
            -0.02471589483320713,
            0.013736561872065067,
            0.025611015036702156,
            -0.010199791751801968,
            -0.015956196933984756,
            0.004228515550494194,
            -0.01918673887848854,
            0.0010307073825970292,
            -0.043606244027614594,
            0.011485020630061626,
            -0.03626774623990059,
            -0.001040678471326828,
            -0.0399927943944931,
            0.023901870474219322,
            0.015970271080732346,
            0.031090009957551956,
            0.04561819136142731,
            -0.019643329083919525,
            0.003978878725320101,
            0.01377896498888731,
            0.008564647287130356,
            0.021694384515285492,
            0.0016724616289138794,
            -0.02651999145746231,
            0.009781318716704845,
            -0.014910462312400341,
            -0.0004155895730946213,
            -0.0007446917588822544,
            -0.029563304036855698,
            -0.030047429725527763,
            -0.04578922688961029,
            -0.02207242324948311,
            0.01020107977092266,
            -0.026862401515245438,
            -0.018315711989998817,
            -0.024263393133878708,
            0.017816854640841484,
            -0.05182462930679321,
            0.00244562653824687,
            0.0035483785904943943,
            0.010977380909025669,
            -0.021643448621034622,
            0.0006923560285940766,
            0.021543264389038086,
            -0.024978812783956528,
            0.01720426417887211,
            -0.0006075327983126044,
            -0.03714387118816376,
            -0.025271892547607422,
            -0.017620699480175972,
            0.02082427591085434,
            0.014627435244619846,
            -0.0010271075880154967,
            0.05267363041639328,
            -0.033083390444517136,
            -0.028145980089902878,
            -0.016210362315177917,
            0.026861637830734253,
            -0.022631455212831497,
            0.02240775339305401,
            0.011915067210793495,
            -0.02154800295829773,
            0.0036957357078790665,
            0.020757947117090225,
            -0.022363413125276566,
            0.021990420296788216,
            0.0008833453175611794,
            0.012260661460459232,
            0.013535166159272194,
            -0.028115669265389442,
            5.5804244766477495e-05,
            0.02049410715699196,
            0.02228977344930172,
            0.06620527058839798,
            -0.01395727414637804,
            -0.02909235656261444,
            0.04212634265422821,
            -0.01647009141743183,
            -0.011580826714634895,
            0.008367079310119152,
            0.0048525528982281685,
            -0.04380543902516365,
            0.010720353573560715,
            0.05948222801089287,
            -0.03238015994429588,
            -0.019126728177070618,
            -0.008378990925848484,
            -0.03588252514600754,
            -0.029987342655658722,
            -0.018452584743499756,
            -0.022719766944646835,
            0.019676949828863144,
            0.0031064688228070736,
            0.0029896204359829426,
            0.04496757313609123,
            -0.012254485860466957,
            -0.03669455647468567,
            -0.018527790904045105,
            0.012113986536860466,
            0.006459955591708422,
            0.0016411410178989172,
            0.0025710309855639935,
            0.0096531817689538,
            -0.009948475286364555,
            -0.08332406729459763,
            0.034389764070510864,
            0.017438046634197235,
            -0.004422286059707403,
            -0.01060220692306757,
            0.008661824278533459,
            0.002773320535197854,
            0.017924703657627106,
            0.03732015937566757,
            -0.009114285930991173,
            0.016315799206495285,
            -0.001100149005651474,
            -0.027344759553670883,
            -0.01294922735542059,
            0.0029996593948453665,
            0.02467728964984417,
            -0.021399211138486862,
            -0.03781423717737198,
            -0.016652686521410942,
            -0.027739331126213074,
            0.013059696182608604,
            -0.012139123864471912,
            -0.004201865289360285,
            0.01557088177651167,
            -0.03987320140004158,
            -0.021598495543003082,
            -0.010830705054104328,
            -0.03205467015504837,
            -0.00012976308062206954,
            0.011738083325326443,
            -0.04210358485579491,
            -0.027081165462732315,
            0.026688333600759506,
            0.016747938469052315,
            -0.003848000429570675,
            0.012995961122214794,
            0.051710765808820724,
            0.032008785754442215,
            -0.0310563575476408,
            -0.019411839544773102,
            -0.01792350970208645,
            -0.005022062920033932,
            -0.028689134865999222,
            0.02159167267382145,
            0.007174021098762751,
            -0.0014923443086445332,
            0.004828757140785456,
            0.0008954065851867199,
            -0.007656577974557877,
            0.034570496529340744,
            -0.011295720003545284,
            -0.018188603222370148,
            0.010762162506580353,
            -0.014067047275602818,
            -0.005983715411275625,
            0.012679903768002987,
            0.013976368121802807,
            0.03477439656853676,
            0.03851175308227539,
            -0.013595566153526306,
            -0.020000653341412544,
            0.015727641060948372,
            0.03799435496330261,
            0.03441067412495613,
            0.0004899105988442898,
            0.02810915932059288,
            -0.018931150436401367,
            -0.008007760159671307,
            -0.015052028931677341,
            -0.010660963132977486,
            0.054819297045469284,
            0.006387768313288689,
            -0.020162073895335197,
            0.01858786679804325,
            0.007507782895117998,
            0.009031952358782291,
            0.015577415935695171,
            -0.08612855523824692,
            0.031849853694438934,
            -0.0433187335729599
        ],
        [
            0.01590126007795334,
            0.04905441403388977,
            0.024931656196713448,
            -0.015042983926832676,
            -0.004213633947074413,
            -0.048927318304777145,
            -0.0042732409201562405,
            -0.030330169945955276,
            -0.019440293312072754,
            0.01752651296555996,
            -0.008059220388531685,
            -0.013809826225042343,
            -0.028019674122333527,
            -0.024586457759141922,
            -0.08410398662090302,
            0.010065214708447456,
            -0.0040129912085831165,
            0.01142452098429203,
            -0.015063379891216755,
            -0.021578339859843254,
            -0.04160536080598831,
            -0.0014877181965857744,
            0.026985706761479378,
            -0.04772045090794563,
            -0.018547898158431053,
            0.027988027781248093,
            -0.009751768782734871,
            -0.0115501768887043,
            -0.05368110537528992,
            -0.07685767114162445,
            -0.0059314146637916565,
            0.03185674548149109,
            -0.00987895019352436,
            -0.004690831992775202,
            -0.0005295334267430007,
            0.05023929476737976,
            -0.023493856191635132,
            0.008193698711693287,
            0.035200294107198715,
            -0.0029211898799985647,
            0.026894157752394676,
            0.005334895104169846,
            -0.02050626091659069,
            0.00941329076886177,
            -0.0005160948494449258,
            -0.04855654761195183,
            -0.02250668592751026,
            -0.011170221492648125,
            -0.004170346073806286,
            0.03753398358821869,
            0.0009033411042764783,
            0.003065697615966201,
            -0.013292904943227768,
            0.017623895779252052,
            -0.002138652140274644,
            -0.01872064359486103,
            -0.006012577097862959,
            0.010643796063959599,
            -0.0212992113083601,
            0.008766859769821167,
            -0.013329937122762203,
            0.005671596620231867,
            -0.023087916895747185,
            0.008799240924417973,
            -0.05469992756843567,
            0.014117855578660965,
            0.014218929223716259,
            -0.034456733614206314,
            0.006652986165136099,
            -0.002857490675523877,
            0.028102697804570198,
            -0.007039493881165981,
            0.017516588792204857,
            -0.00347452936694026,
            -0.025317713618278503,
            0.03475978970527649,
            -0.020450741052627563,
            0.02899153344333172,
            -0.026778699830174446,
            0.006219927687197924,
            -0.00018575045396573842,
            -0.017774466425180435,
            0.016044458374381065,
            0.004310593008995056,
            0.032626282423734665,
            -0.0011447964934632182,
            -0.028018757700920105,
            0.03283418342471123,
            0.024748176336288452,
            -0.0688362717628479,
            0.0323103666305542,
            -0.015388811938464642,
            -0.05314621329307556,
            0.00613893149420619,
            0.00924756657332182,
            0.002218441106379032,
            -0.018338093534111977,
            -4.125391205889173e-05,
            0.017785655334591866,
            -0.01872198097407818,
            0.005549509543925524,
            -0.037640321999788284,
            0.02746080979704857,
            0.02054603211581707,
            0.02751963585615158,
            0.022572360932826996,
            0.04082879051566124,
            0.03101581521332264,
            0.007624020334333181,
            0.01512469258159399,
            -0.027774924412369728,
            0.0020193769596517086,
            0.018597863614559174,
            0.013240296393632889,
            0.007459201384335756,
            0.003640133887529373,
            0.004887287970632315,
            -0.04740268737077713,
            -0.040773604065179825,
            -0.03187357261776924,
            -0.024457363411784172,
            0.02164427377283573,
            0.08027675002813339,
            0.023665394634008408,
            0.03924525901675224,
            0.044779323041439056,
            -0.042464468628168106,
            0.0016296535031870008,
            -0.0014042393304407597,
            0.006453946698457003,
            -0.03071410395205021,
            -0.010408464819192886,
            -0.02267737127840519,
            0.00010463459329912439,
            -0.011794107966125011,
            -0.013229926116764545,
            0.023085955530405045,
            0.022685429081320763,
            -0.03129469230771065,
            -0.02859942801296711,
            0.0018379053799435496,
            0.02862672135233879,
            -0.012583485804498196,
            0.01855146884918213,
            -0.009522883221507072,
            0.011789800599217415,
            -0.028587330132722855,
            -0.040817901492118835,
            0.011934323236346245,
            -0.029893064871430397,
            0.021862536668777466,
            -0.016653021797537804,
            -0.013335425406694412,
            0.007218061946332455,
            -0.03653133288025856,
            0.0033141018357127905,
            -0.02849704399704933,
            -0.04843549430370331,
            0.017872102558612823,
            -0.09156113862991333,
            0.01282658614218235,
            0.03703942894935608,
            0.034011516720056534,
            0.02591017447412014,
            0.0072148521430790424,
            0.006976737175136805,
            0.03013020008802414,
            0.0028546468820422888,
            -0.039353568106889725,
            0.003924173768609762,
            0.036793846637010574,
            -0.004731039982289076,
            0.019007360562682152,
            0.023831218481063843,
            0.013039193116128445,
            0.02858513593673706,
            -0.06623338907957077,
            0.02957848273217678,
            -0.003706773743033409,
            -0.03312353417277336,
            0.010195448994636536,
            -0.012619949877262115,
            0.011241885833442211,
            0.0011627796338871121,
            -0.008962104097008705,
            0.03752680495381355,
            -0.035191912204027176,
            -0.004825359210371971,
            -0.0027497734408825636,
            0.029984362423419952,
            -0.020916473120450974,
            0.015222468413412571,
            -0.026906948536634445,
            -0.015505321323871613,
            0.02551412209868431,
            0.02638600952923298,
            0.018021302297711372,
            0.002833584789186716,
            -0.03843371942639351,
            0.006714109797030687,
            0.024038231000304222,
            -0.014140291139483452,
            -0.052245523780584335,
            0.0006308340816758573,
            0.04778968542814255,
            -0.026464151218533516,
            0.029868440702557564,
            -0.003093999344855547,
            0.02050005830824375,
            -0.014977599494159222,
            0.04100484028458595,
            -0.0271473191678524,
            -0.08106496930122375,
            -0.014561413787305355,
            -0.021750422194600105,
            -0.013691659085452557,
            0.003317394061014056,
            -0.030073130503296852,
            -0.025646470487117767,
            0.007016725372523069,
            -0.006037615239620209,
            0.023415109142661095,
            -0.028531555086374283,
            0.03894919902086258,
            0.014857342466711998,
            0.029003804549574852,
            -0.014150077477097511,
            -0.012074598111212254,
            -0.004840434994548559,
            0.01120360754430294,
            0.015168688260018826,
            0.004049621522426605,
            0.012648588046431541,
            -0.0056962850503623486,
            -0.05535358563065529,
            0.015094533562660217,
            -0.007242524065077305,
            0.024788014590740204,
            0.02036953903734684,
            -0.00826592743396759,
            0.013525391928851604,
            0.02804901823401451,
            -0.02899768203496933,
            0.03447836637496948,
            0.007252044975757599,
            -0.022662710398435593,
            -0.0358077809214592,
            -0.028261033818125725,
            -0.017364146187901497,
            0.01780501753091812,
            0.014427884481847286,
            -0.00736804585903883,
            0.026635667309165,
            -0.024453232064843178,
            -0.008614159189164639,
            -0.0008122514700517058,
            0.011081678792834282,
            -0.0027574303094297647,
            0.024227207526564598,
            0.017146900296211243,
            -0.006924307439476252,
            -0.05802852287888527,
            0.011801033280789852,
            -0.006648769602179527,
            0.024216709658503532,
            0.031914059072732925,
            -0.024147838354110718,
            0.008548971265554428,
            0.0162684116512537,
            0.02592301554977894,
            -0.00506277522072196,
            -0.007875591516494751,
            -0.017569875344634056,
            0.024720918387174606,
            0.0095903305336833,
            0.0009832349605858326,
            -0.014338411390781403,
            -0.0219635758548975,
            -0.02707444690167904,
            -0.0010487273102626204,
            -0.0025789125356823206,
            0.004529888276010752,
            -0.020548123866319656,
            0.04378250241279602,
            -0.011921667493879795,
            0.017567647621035576,
            0.010559477843344212,
            0.008236619643867016,
            -0.004525922704488039,
            -0.014205917716026306,
            -0.03968670219182968,
            -0.030807431787252426,
            -0.021190796047449112,
            0.030791956931352615,
            0.0069845495745539665,
            0.0007894505397416651,
            0.003959396854043007,
            0.031434569507837296,
            -0.0042115566320717335,
            0.026308860629796982,
            0.03743496537208557,
            0.03288653865456581,
            0.010066618211567402,
            0.023801201954483986,
            0.01850050874054432,
            0.061333052814006805,
            -0.019223449751734734,
            -0.04810323193669319,
            -0.0006887168274261057,
            0.010106355883181095,
            -0.0825388953089714,
            -0.01207753922790289,
            0.0274189505726099,
            -0.0016957137268036604,
            0.041741203516721725,
            0.012524046003818512,
            0.011991173960268497,
            0.03731127828359604,
            -0.02624058723449707,
            -0.012444889172911644,
            0.028212353587150574,
            -0.01423163153231144,
            -0.010008104145526886,
            -0.09444598853588104,
            0.021162444725632668,
            0.04606633260846138,
            -0.07046110183000565,
            0.013236994855105877,
            -0.011349445208907127,
            -0.031985096633434296,
            0.0039373827166855335,
            -0.014763890765607357,
            0.0004162692348472774,
            -0.04031127691268921,
            -0.052362240850925446,
            -0.07873456925153732,
            -0.030115360394120216,
            -0.03227294608950615,
            -0.007046481128782034,
            0.007583854254335165,
            0.013335210271179676,
            -0.014287345111370087,
            0.029523635283112526,
            -0.0282181054353714,
            0.005910823587328196,
            0.031182944774627686,
            0.030963873490691185,
            0.042803630232810974,
            0.001151275704614818,
            0.026421649381518364,
            -0.028628727421164513,
            -0.04422372579574585,
            0.028796184808015823,
            0.0281112939119339,
            0.018267087638378143,
            -0.012693410739302635,
            -0.003337610512971878,
            0.03688724711537361,
            0.017344623804092407,
            0.014843389391899109,
            0.00693713640794158,
            0.022864844650030136,
            -0.023700334131717682,
            0.02321467362344265,
            -0.013819152489304543,
            -0.01498714741319418,
            -0.0167838167399168,
            0.027717938646674156,
            0.001643513678573072,
            0.044899843633174896,
            -0.02553706429898739,
            -0.01904529519379139,
            -0.009558821097016335,
            -0.0076132165268063545,
            -0.010156244039535522,
            -0.04507360979914665,
            -0.016500668600201607,
            0.02092578634619713,
            0.05280258134007454,
            0.015421772375702858,
            0.00549484696239233,
            0.014066588133573532,
            -0.025255603715777397,
            0.0312747098505497,
            0.0002578625571914017,
            -0.005688627250492573,
            -0.0010147633729502559,
            -0.012812734581530094,
            0.009516281075775623,
            -0.033455487340688705,
            0.003510974580422044,
            0.0018487853230908513,
            -0.022813789546489716,
            0.01346864365041256,
            -0.017935140058398247,
            0.029612582176923752,
            0.009813748300075531,
            -0.04089438542723656,
            0.025412729009985924,
            0.026127653196454048,
            -0.0009659597417339683,
            -0.0033305997494608164,
            0.02269596792757511,
            0.029491351917386055,
            0.01193920522928238,
            0.028807854279875755,
            -0.03140370547771454,
            0.02237754501402378,
            0.002501462120562792,
            0.014230795204639435,
            0.001024266006425023,
            0.004756577778607607,
            0.007951077073812485,
            0.020617075264453888,
            0.013123400509357452,
            -0.014319784939289093,
            -0.018646249547600746,
            0.05187853053212166,
            -0.012898128479719162,
            0.004857753403484821,
            0.034190163016319275,
            0.0032904543913900852,
            -0.015479788184165955,
            -0.006305991671979427,
            -0.007415669038891792,
            0.04333639144897461,
            -0.0510978139936924,
            0.028503956273198128,
            -0.004617145285010338,
            -0.010335278697311878,
            -0.04789504036307335,
            0.02371000498533249,
            0.0024610927794128656,
            0.013964624144136906,
            -0.010982968844473362,
            0.012882682494819164,
            -0.012500488199293613,
            0.03504535183310509,
            0.004204809665679932,
            0.009870769456028938,
            0.00928160734474659,
            0.030199512839317322,
            -0.021825524047017097,
            0.02806592732667923,
            -0.0359899066388607,
            0.027448875829577446,
            0.01536730956286192,
            -0.05393226072192192,
            -0.019235963001847267,
            -0.007721766829490662,
            0.0068024094216525555,
            -0.012481877580285072,
            0.0115707041695714,
            0.011456270702183247,
            0.038085050880908966,
            0.008902047760784626,
            0.004353384021669626,
            0.024645492434501648,
            -0.017006130889058113,
            -0.021281464025378227,
            -0.0534791462123394,
            0.00851296354085207,
            -0.030299238860607147,
            -0.0682586282491684,
            0.00278190104290843,
            0.014570493251085281,
            -0.015811365097761154,
            0.016330916434526443,
            0.02251986414194107,
            0.008302966132760048,
            0.0023176923859864473,
            0.036880847066640854,
            0.007966982200741768,
            -0.011474412865936756,
            -0.000392614136217162,
            0.004584317095577717,
            0.02738899737596512,
            0.015918293967843056,
            -0.009135222993791103,
            0.017773767933249474,
            -0.033528659492731094,
            -0.004836469888687134,
            0.00023508432786911726,
            0.01640620268881321,
            0.012815049849450588,
            0.02751128561794758,
            -0.03887436166405678,
            -0.01941440813243389,
            -0.09034185856580734,
            0.0196473840624094,
            -0.03442976623773575,
            -0.0206899493932724,
            -0.027057534083724022,
            0.005961184855550528,
            0.009601164609193802,
            0.008556243032217026,
            0.02157432585954666,
            -0.012758736498653889,
            -0.008362752385437489,
            0.040854182094335556,
            0.0053559523075819016,
            -0.015560672618448734,
            0.0093761021271348,
            -0.04020410031080246,
            0.030917903408408165,
            -0.017987940460443497,
            0.02957756072282791,
            0.024242015555500984,
            -0.02532385103404522,
            0.0020919358357787132,
            -0.0012769137974828482,
            -0.011196992360055447,
            0.024717574939131737,
            0.02070576883852482,
            0.03179149329662323,
            0.025691557675600052,
            -0.003043595002964139,
            0.03161735087633133,
            -0.008865153416991234,
            0.03513634204864502,
            -0.004840543493628502,
            0.033785250037908554,
            0.015585706569254398,
            -0.024921083822846413,
            -0.08431438356637955,
            -0.08560365438461304,
            0.007617700379341841,
            0.011786241084337234,
            0.023034151643514633,
            -0.014712678268551826,
            -0.015597527846693993,
            -0.015559247694909573,
            -0.02582109160721302,
            0.010139861144125462,
            0.003162386128678918,
            -0.07877217233181,
            -0.008403248153626919,
            -0.006084059365093708,
            -0.020624324679374695,
            -0.015046868473291397,
            -0.011337373405694962,
            0.04363523796200752,
            -0.015130397863686085,
            0.04696296155452728,
            0.022640064358711243,
            0.030612284317612648,
            0.0019860020838677883,
            -0.0016848287777975202,
            -0.009051612578332424,
            0.030557608231902122,
            0.00519615737721324,
            -0.02484474703669548,
            0.024525625631213188,
            -0.03168894350528717,
            -0.004872938152402639,
            -0.01043663825839758,
            -0.004514842759817839,
            0.02074328437447548,
            0.019808467477560043,
            -0.013478754088282585,
            0.02842489257454872,
            -0.026439202949404716,
            -0.014455593191087246,
            0.01435282826423645,
            0.017194166779518127,
            0.01624021865427494,
            -0.025627385824918747,
            0.003186814719811082,
            -0.008470417000353336,
            -0.014153563417494297,
            0.0058100526221096516,
            0.03542860597372055,
            -0.010076956823468208,
            0.029256559908390045,
            0.007769508752971888,
            0.016245387494564056,
            -0.022382499650120735,
            -0.007380501367151737,
            0.04125715047121048,
            -0.021789684891700745,
            0.014229903928935528,
            -0.018586911261081696,
            -0.009801127016544342,
            0.028957663103938103,
            0.007173912134021521,
            -0.042743001133203506,
            -0.015148564241826534,
            0.01680271327495575,
            0.03131938725709915,
            0.05755576863884926,
            0.00943856779485941,
            -0.038150038570165634,
            0.012137314304709435,
            -0.009034787304699421,
            0.03721559792757034,
            0.007872308604419231,
            -0.0009559023310430348,
            0.021922210231423378,
            -0.014631971716880798,
            0.0418647900223732,
            -0.06597846746444702,
            -0.006225700490176678,
            -0.021900054067373276,
            0.022250382229685783,
            0.013473556376993656,
            -0.007150671444833279,
            0.019943727180361748,
            0.01761684939265251,
            -0.03856749087572098,
            0.04108230024576187,
            0.016976313665509224,
            -0.020083412528038025,
            0.004644150845706463,
            0.009684689342975616,
            0.011366887018084526,
            -0.025630682706832886,
            0.009777778759598732,
            -0.04560701921582222,
            0.036440785974264145,
            0.01710810698568821,
            -0.013044167309999466,
            0.00040031279786489904,
            0.02185603603720665,
            0.02298244647681713,
            0.0005186606431379914,
            -0.02704303339123726,
            -0.00014133502554614097,
            -0.013153782114386559,
            -0.004954777657985687,
            -0.027071043848991394,
            -0.0016191687900573015,
            -0.045262254774570465,
            0.02643994241952896,
            -0.023868968710303307,
            -0.034394122660160065,
            0.010506145656108856,
            0.02353411354124546,
            -0.04971824586391449,
            0.025906730443239212,
            -0.0023177610710263252,
            -0.0013156725326552987,
            0.025742897763848305,
            -0.004283491522073746,
            -0.0593082420527935,
            -0.007525526452809572,
            -0.02019353024661541,
            -0.041296638548374176,
            0.0012055449187755585,
            0.03314576670527458,
            9.993805724661797e-05,
            0.015710320323705673,
            0.0026428888086229563,
            -0.004976950120180845,
            -0.02341679483652115,
            0.020057857036590576,
            0.01232493668794632,
            -0.051978543400764465,
            -0.010617850348353386,
            -0.005141220986843109,
            0.036756694316864014,
            -0.00032229445059783757,
            0.00042357799247838557,
            -0.013943412341177464,
            -0.03909243270754814,
            0.04186584800481796,
            0.03367508947849274,
            0.022187218070030212,
            0.01264404971152544,
            0.009707987308502197,
            -0.02725532278418541,
            -0.025464946404099464,
            -0.03481151908636093,
            -0.0038524887058883905,
            -0.005208893213421106,
            0.01321223471313715,
            0.013651721179485321,
            0.012261182069778442,
            0.028543857857584953,
            0.02522345632314682,
            -0.0006247396813705564,
            -0.02359245903789997,
            0.02420222759246826,
            0.00739350076764822,
            0.013839141465723515,
            0.017018470913171768,
            0.007024003192782402,
            -0.03509734943509102,
            -0.009231329895555973,
            0.01538026798516512,
            0.04950106516480446,
            0.0062696379609405994,
            0.02796163409948349,
            0.030764274299144745,
            0.0034396089613437653,
            -0.0028865195345133543,
            -0.06170227378606796,
            -0.007378848735243082,
            -0.030330749228596687,
            0.03196701407432556,
            0.03212342411279678,
            -0.0008605446200817823,
            0.0075405677780508995,
            0.00473290029913187,
            0.003562470432370901,
            0.01782311126589775,
            -0.03257506713271141,
            0.03267769515514374,
            0.010842008516192436,
            0.015318245626986027,
            0.03266511484980583,
            -0.02858898416161537,
            -0.010241118259727955,
            0.008778894320130348,
            0.004159215837717056,
            0.03251003101468086,
            -0.039037566632032394,
            0.05722426250576973,
            -0.025922240689396858,
            -0.03195786476135254,
            -0.00699254497885704,
            0.011537096463143826,
            0.023988164961338043,
            0.024291934445500374,
            0.007371252402663231,
            0.025591576471924782,
            -0.0008899263921193779,
            0.03871333226561546,
            -0.022986965253949165,
            0.0070244488306343555,
            0.00968857854604721,
            -0.033746350556612015,
            -0.04357273876667023,
            -0.006565123796463013,
            0.019161511212587357,
            -0.018871931359171867,
            0.009790124371647835,
            -0.015620637685060501,
            0.013625291176140308,
            0.02875225991010666,
            -0.000639251375105232,
            -0.012218630872666836,
            -0.02033158205449581,
            -0.0032203018199652433,
            0.02453439123928547,
            -0.007373414933681488,
            0.0068264310248196125,
            0.016237424686551094,
            0.012525146827101707,
            -0.021025458350777626,
            -0.005354421213269234,
            0.006469390355050564,
            0.001670793630182743,
            0.030614357441663742,
            0.025446651503443718,
            0.0018040603026747704,
            0.012928674928843975,
            -0.02226896397769451,
            -0.002992355963215232,
            0.0026890423614531755,
            0.006802137475460768,
            -0.018035324290394783,
            -0.015922393649816513,
            0.03732055798172951,
            0.028378985822200775,
            -0.007264283485710621,
            -0.011599848046898842,
            0.014608691446483135,
            0.023987453430891037,
            0.0011031965259462595,
            -0.013119645416736603,
            0.020135534927248955,
            0.008755587972700596,
            0.009319732896983624,
            0.02497505582869053,
            -0.020020566880702972,
            0.00424139853566885,
            0.002228286350145936,
            0.019028855487704277,
            0.014660004526376724,
            -0.016552194952964783,
            0.007719740737229586,
            -0.002004101639613509,
            -0.009428244084119797,
            -0.013950801454484463,
            0.00881381705403328,
            0.0037598267663270235,
            0.055071521550416946,
            -0.01544281281530857,
            0.034790072590112686,
            0.047851335257291794,
            0.014921213500201702,
            0.00036162292235530913,
            0.028267234563827515,
            -0.039970867335796356,
            0.00523283751681447,
            -0.04988522827625275,
            -0.045070581138134,
            -0.009399074129760265,
            -0.031923241913318634,
            -0.03208674117922783,
            0.0037585445679724216,
            0.006937290541827679,
            -0.04512440413236618,
            0.012562941759824753,
            0.02127305418252945,
            -0.03584989905357361,
            0.013682843185961246,
            0.015899933874607086,
            -0.0027804896235466003,
            -0.0031426623463630676,
            -0.0032275584526360035,
            -0.012709058821201324,
            -0.0025821197777986526,
            -0.00936843454837799,
            -0.03952833265066147,
            0.03969589248299599,
            -0.0013662369456142187,
            -0.022872399538755417,
            0.0059663220308721066,
            0.0035991419572383165,
            -0.0005589465727098286,
            -0.008226701989769936,
            -0.014278225600719452,
            -0.020830513909459114,
            -0.023957587778568268,
            -0.018109621480107307,
            0.0034796632826328278,
            -0.015586070716381073,
            -0.009545553475618362,
            -0.0015312930336222053,
            0.005830292124301195,
            0.029510244727134705,
            0.00829711277037859,
            0.026053793728351593,
            -0.014437534846365452,
            0.02408287674188614,
            -0.008014112710952759,
            -0.030784443020820618,
            -0.013232392258942127,
            0.002065245294943452,
            0.041389305144548416,
            -0.009801958687603474,
            -0.004766913130879402,
            0.01488999929279089,
            0.001322075491771102,
            -0.015058904886245728,
            0.017046408727765083,
            -0.009170477278530598,
            0.030680105090141296,
            0.054676543921232224,
            -0.003436681581661105,
            0.01805056817829609,
            0.007660997565835714,
            -0.014807392843067646,
            0.00455761281773448,
            0.0015216636238619685,
            -0.028666622936725616,
            0.01773598976433277,
            -0.04941245913505554,
            0.02035355195403099,
            0.02403934858739376,
            0.004347103647887707,
            -0.06394048035144806,
            0.007882682606577873,
            -0.010681095533072948,
            0.009810170158743858,
            -0.0078958198428154,
            0.015257268212735653,
            -0.0024811597540974617,
            -0.008981279097497463,
            -0.031005632132291794,
            0.04076904058456421,
            0.00139915244653821,
            0.03522448614239693,
            0.022629233077168465,
            -0.033029705286026,
            0.005180561449378729,
            -0.04017208144068718,
            -0.028122520074248314,
            0.010463288053870201,
            -0.037687964737415314,
            0.011756420135498047,
            -0.024532927200198174,
            0.014280415140092373,
            0.045514319092035294,
            -0.0048506795428693295,
            0.002141425386071205,
            0.04399579018354416,
            -0.020190566778182983,
            0.008792075328528881,
            0.03589586913585663,
            -0.0084126777946949,
            -0.010083802044391632,
            0.02751431055366993,
            0.011350723914802074,
            0.00488157756626606,
            -0.00832704920321703,
            0.05023439601063728,
            0.022185059264302254,
            -0.014369871467351913,
            -0.02045203186571598,
            -0.031127410009503365,
            0.01883619837462902,
            -0.0301681999117136,
            0.01442998368293047,
            0.055843424052000046,
            0.009232635609805584,
            0.0043896399438381195,
            0.004400394856929779,
            0.05723441764712334,
            -0.0007093697786331177,
            -0.020926430821418762,
            -0.05365145951509476,
            0.07894657552242279,
            -0.050759825855493546,
            -0.014402065426111221,
            -0.025845110416412354,
            0.0026755104772746563,
            -0.013650652021169662,
            -0.01835326850414276,
            -0.002319944789633155,
            -0.0032211116049438715,
            -0.013706675730645657,
            -0.01764488033950329,
            -0.025662969797849655,
            -0.01601993478834629,
            -0.022279590368270874,
            0.018042439594864845,
            -0.01936241425573826,
            -0.03273145854473114,
            0.014068125747144222,
            0.012606749311089516,
            -0.0027096543926745653,
            -0.08657316863536835,
            -0.019099026918411255,
            0.02922174520790577,
            0.019163327291607857,
            -0.017807913944125175,
            -0.0010533080203458667,
            -0.023725876584649086,
            0.0071556768380105495,
            -0.013486335054039955,
            0.0017293045530095696,
            -0.004761868622153997,
            -0.03030976839363575,
            0.011699286289513111,
            -0.010099544189870358,
            0.03726346045732498,
            -0.02862546592950821,
            0.00824719201773405,
            -0.017132682725787163,
            0.0008210681844502687,
            -0.023202946409583092,
            0.01291059423238039,
            0.009694484062492847,
            0.03921825811266899,
            -0.01569163240492344,
            -0.02272031456232071,
            0.018187500536441803,
            -0.005397610366344452,
            -0.028602631762623787,
            0.00012364983558654785,
            -0.021070972084999084,
            -0.022645387798547745,
            -0.011029624380171299,
            -0.02294014021754265,
            -0.029116207733750343,
            0.02506224624812603,
            -0.040376365184783936,
            0.013371034525334835,
            0.023684829473495483,
            0.025704655796289444,
            -0.005723822396248579,
            0.012909925542771816,
            0.000747267680708319,
            -0.02748647704720497,
            0.018275197595357895,
            -0.019412335008382797,
            0.036686237901449203,
            -0.008620847947895527,
            0.04608087241649628,
            0.03112827055156231,
            -0.060701292008161545,
            0.0189772117882967,
            0.007145174778997898,
            0.039542995393276215,
            -0.03491394594311714,
            0.003919983748346567,
            -0.009672489017248154,
            -0.014153135009109974,
            -0.022896353155374527,
            0.019963854923844337,
            -0.029916275292634964,
            -0.002304528607055545,
            -0.03388930857181549,
            -0.01662335731089115,
            -0.003262588754296303,
            0.027499504387378693,
            0.0019427110673859715,
            0.0012038592249155045,
            -0.0004839956236537546,
            0.05711283162236214,
            -0.011375359259545803,
            0.007133669685572386,
            0.03120574541389942,
            -0.006240518763661385,
            0.006624152883887291,
            -0.032701630145311356,
            0.00838735792785883,
            0.00013018460595048964,
            -0.023524895310401917,
            0.002702797297388315,
            0.03261499106884003,
            0.04713895544409752,
            -0.01356834452599287,
            -0.06911741942167282,
            -0.012211780063807964,
            0.026339327916502953,
            0.03202742338180542,
            -0.00028695238870568573,
            0.0065686884336173534,
            -0.013606362976133823,
            0.023063642904162407,
            -0.04125996679067612
        ],
        [
            0.009679551236331463,
            -0.011504617519676685,
            -0.02214924246072769,
            0.016845732927322388,
            -0.022663848474621773,
            0.004879423882812262,
            -0.024939626455307007,
            -0.01728573627769947,
            0.012545004487037659,
            0.029050925746560097,
            0.0027042259462177753,
            0.019603261724114418,
            0.012947254814207554,
            0.02712247520685196,
            0.03218534216284752,
            -0.04189760982990265,
            -0.008435191586613655,
            -0.011165877804160118,
            -0.013799196109175682,
            0.024787720292806625,
            -0.011173226870596409,
            -0.013958602212369442,
            -0.024480469524860382,
            0.023815903812646866,
            0.01758001185953617,
            0.011524160392582417,
            -0.02161879651248455,
            -0.011742444708943367,
            0.0037997770123183727,
            -0.004182922188192606,
            0.0027014364022761583,
            0.020698752254247665,
            0.012299262918531895,
            0.01549384742975235,
            0.014913668856024742,
            0.0028170489240437746,
            0.014123895205557346,
            -0.012374484911561012,
            -0.029246117919683456,
            -0.009753837250173092,
            -0.021283293142914772,
            -0.0411568284034729,
            -0.027834801003336906,
            0.002020430751144886,
            -0.019956987351179123,
            -0.03450695797801018,
            0.0014617699198424816,
            0.015835624188184738,
            0.00274607352912426,
            0.00142927176784724,
            -0.009539242833852768,
            0.004927288740873337,
            0.0034953379072248936,
            0.005043095909059048,
            0.02326105535030365,
            -0.010128965601325035,
            -0.01249149814248085,
            0.0036586271598935127,
            0.010590319521725178,
            -0.005188018549233675,
            -0.025168554857373238,
            0.022247180342674255,
            0.01184988021850586,
            -0.006594343576580286,
            0.004895878490060568,
            0.007370314560830593,
            -0.03505977615714073,
            0.028468305245041847,
            0.021702658385038376,
            -0.01735418289899826,
            0.016705162823200226,
            0.006589082535356283,
            -0.020473478361964226,
            0.023327946662902832,
            0.04056406766176224,
            -0.002828634809702635,
            -0.016717715188860893,
            -0.01698724366724491,
            -0.02939469926059246,
            -0.01945354789495468,
            0.029698913916945457,
            0.018741432577371597,
            0.01682085171341896,
            -0.02722458727657795,
            -0.0012746098218485713,
            0.02078784629702568,
            -0.01623990386724472,
            -0.03664328157901764,
            0.011357522569596767,
            0.012169535271823406,
            0.027965184301137924,
            -0.030707888305187225,
            0.022913489490747452,
            -0.005071238148957491,
            0.018582643941044807,
            -0.020733028650283813,
            0.030162973329424858,
            -0.0028571798466145992,
            -0.028652211651206017,
            -0.023404741659760475,
            -0.0007442341884598136,
            -0.0070884148590266705,
            -0.011428898200392723,
            0.0235176719725132,
            0.02640293911099434,
            -0.00970486644655466,
            0.022155912593007088,
            0.024050792679190636,
            -0.03401776775717735,
            0.008137773722410202,
            -0.034456055611371994,
            0.02004009671509266,
            0.016666729003190994,
            0.03439841419458389,
            -0.06897849589586258,
            -0.012206488288939,
            0.033239930868148804,
            -0.04689963161945343,
            0.042897529900074005,
            0.02449829690158367,
            0.01007888000458479,
            -0.012962008826434612,
            0.053750693798065186,
            -0.005819735582917929,
            0.024231228977441788,
            0.02600051648914814,
            0.0011608521454036236,
            0.0348689891397953,
            0.0332665853202343,
            0.00701136514544487,
            -0.018352385610342026,
            -0.011460534296929836,
            0.014553999528288841,
            0.03255962207913399,
            0.022641560062766075,
            0.02087455801665783,
            0.004709642846137285,
            0.008797798305749893,
            -0.01244524959474802,
            -0.008981636725366116,
            0.008614669553935528,
            -0.01426775474101305,
            0.03296196088194847,
            0.022698597982525826,
            -0.03391502425074577,
            0.0004606875008903444,
            0.012450940907001495,
            -0.004491717088967562,
            -0.020717427134513855,
            0.0029834338929504156,
            0.03482277691364288,
            0.03512481600046158,
            -0.01796150393784046,
            0.021534552797675133,
            0.014993306249380112,
            -0.015091843903064728,
            0.020715458318591118,
            0.02594158984720707,
            -0.0212711188942194,
            -0.007996518164873123,
            -0.012964943423867226,
            -0.022013794630765915,
            0.022413216531276703,
            -0.01794828474521637,
            0.03882519528269768,
            0.010966038331389427,
            0.004702861420810223,
            0.024454088881611824,
            0.010196534916758537,
            0.02715364471077919,
            0.020889058709144592,
            0.006272300146520138,
            0.006940937601029873,
            0.008670929819345474,
            0.014268717728555202,
            -0.0009001980070024729,
            0.017807098105549812,
            -0.021711818873882294,
            -0.017486311495304108,
            0.008348189294338226,
            0.025441819801926613,
            -0.014023230411112309,
            0.04703981429338455,
            0.017470011487603188,
            0.004309939686208963,
            0.006329758558422327,
            -0.018846740946173668,
            -0.013540741056203842,
            0.0006988617824390531,
            -0.024564696475863457,
            -0.027432389557361603,
            -0.017970914021134377,
            -0.02485029771924019,
            0.016638096421957016,
            0.009653124026954174,
            0.031375639140605927,
            0.01574806682765484,
            0.0003699487424455583,
            0.024600697681307793,
            0.017094286158680916,
            -0.02314707450568676,
            0.026953725144267082,
            -0.014466683380305767,
            0.027254480868577957,
            -0.014959074556827545,
            0.006355057470500469,
            0.0036601161118596792,
            -0.01118633896112442,
            0.018841270357370377,
            -0.009759792126715183,
            -0.030009517446160316,
            0.0036581442691385746,
            -0.015524306334555149,
            0.018915243446826935,
            -0.011589683592319489,
            0.03375795856118202,
            -0.004842424765229225,
            -0.0163761917501688,
            0.0005736688035540283,
            0.016679145395755768,
            -0.018680011853575706,
            -0.005461457651108503,
            0.04305693879723549,
            0.0020635745022445917,
            -0.01971985399723053,
            -0.011250314302742481,
            -0.012624874711036682,
            -0.015400366857647896,
            -0.000960904115345329,
            0.0014236816205084324,
            -0.018588505685329437,
            0.02375662326812744,
            -0.006257906556129456,
            -0.02971426211297512,
            -0.04609880596399307,
            -0.01675279065966606,
            0.016945084556937218,
            0.021520521491765976,
            -0.015957782045006752,
            -0.01726009137928486,
            0.006056370213627815,
            0.009342086501419544,
            -0.008124767802655697,
            -0.001054357853718102,
            0.010445281863212585,
            0.010140368714928627,
            -0.017198439687490463,
            -0.019437937065958977,
            -0.031478215008974075,
            -0.0173310749232769,
            -0.02071181870996952,
            0.014848136343061924,
            -0.010890449397265911,
            0.004352725110948086,
            -0.012981012463569641,
            -0.023499982431530952,
            -0.0202628280967474,
            -0.009109360165894032,
            0.006020793691277504,
            -0.014677340164780617,
            0.021776145324110985,
            0.0072305211797356606,
            0.013512125238776207,
            0.045732513070106506,
            0.007185867056250572,
            0.01358023565262556,
            -0.014892945997416973,
            0.0038920301012694836,
            -0.02629927173256874,
            -0.015619795769453049,
            -0.00959231797605753,
            0.010540974326431751,
            0.002524532610550523,
            -0.03176767751574516,
            0.016676517203450203,
            0.025734277442097664,
            0.024634797126054764,
            0.01395403128117323,
            -0.004785874392837286,
            0.00223180721513927,
            0.02387826144695282,
            0.021042896434664726,
            -0.020422589033842087,
            0.04399624466896057,
            -0.01071494072675705,
            0.004631213843822479,
            -0.017182867974042892,
            0.03646435588598251,
            -0.01910046860575676,
            0.02282882109284401,
            -0.011138332076370716,
            0.005162297748029232,
            0.013327519409358501,
            0.020626481622457504,
            -0.005162169691175222,
            -0.019980620592832565,
            -0.01178576797246933,
            0.026580672711133957,
            -0.010595671832561493,
            -0.024625424295663834,
            0.010051107965409756,
            -0.005311764311045408,
            0.002263421891257167,
            0.002859467174857855,
            -0.00785139948129654,
            -0.01627376861870289,
            -0.010548769496381283,
            0.011041362769901752,
            0.004878225736320019,
            0.03730357810854912,
            0.0018988059600815177,
            0.012704419903457165,
            0.007221924606710672,
            -0.018657132983207703,
            -0.030821505934000015,
            0.04016898572444916,
            0.00042724673403427005,
            0.022496772930026054,
            0.013273983262479305,
            0.01057612057775259,
            0.024863336235284805,
            -0.021910734474658966,
            0.0012142673367634416,
            0.020247170701622963,
            -0.017107021063566208,
            0.015190747566521168,
            0.0035476640332490206,
            -0.016012264415621758,
            0.014706054702401161,
            0.022315479815006256,
            -0.02909507416188717,
            -0.03259682655334473,
            -0.023644622415304184,
            0.026235343888401985,
            -0.009215588681399822,
            0.013815244659781456,
            -0.018259234726428986,
            0.00399588979780674,
            0.013438627123832703,
            0.010852104984223843,
            0.034535039216279984,
            0.007278149016201496,
            -0.0036259472835808992,
            -0.024103015661239624,
            -0.01228029653429985,
            -0.010774791240692139,
            -0.03322403505444527,
            -0.022785181179642677,
            -0.02111666463315487,
            -0.039461538195610046,
            -0.01925429329276085,
            -0.01766306534409523,
            -0.02967076562345028,
            -0.01899956539273262,
            0.0067718722857534885,
            -0.01658187434077263,
            0.007123791147023439,
            -0.01614045351743698,
            -0.020188091322779655,
            -0.002293291501700878,
            0.029943624511361122,
            0.015913603827357292,
            0.02884701080620289,
            -0.0006499928422272205,
            0.03395013511180878,
            0.031876835972070694,
            0.00395034346729517,
            0.0005750870914198458,
            -0.01809443160891533,
            0.027263890951871872,
            -0.01986243575811386,
            0.010646750219166279,
            -0.0048502725549042225,
            0.00021205039229243994,
            -0.030064737424254417,
            0.0463871955871582,
            0.0010351723758503795,
            0.02766338363289833,
            -0.03466289862990379,
            -0.04242018237709999,
            0.035973865538835526,
            -0.02459666319191456,
            0.010395124554634094,
            -0.010075296275317669,
            -0.01932200975716114,
            -0.020811988040804863,
            0.005706088151782751,
            0.013770236633718014,
            0.010431168600916862,
            0.014747685752809048,
            -0.03353092819452286,
            -0.030743159353733063,
            0.02030407451093197,
            -0.02912001870572567,
            0.004881635773926973,
            -0.014721446670591831,
            0.019538816064596176,
            -0.002078802790492773,
            -0.025076113641262054,
            0.005549896974116564,
            -0.019071009010076523,
            -0.004587643779814243,
            0.007323902100324631,
            -0.02140640653669834,
            0.024324052035808563,
            -0.024746287614107132,
            -0.018604563549160957,
            0.002841308480128646,
            0.03489202260971069,
            -0.02042403630912304,
            -0.019491679966449738,
            0.00799034908413887,
            0.0012618666514754295,
            0.005858003161847591,
            4.2047173337778077e-05,
            0.03365936502814293,
            -0.003017573384568095,
            -0.007249508053064346,
            0.014932109043002129,
            0.021495435386896133,
            0.009436976164579391,
            -0.01577567681670189,
            -0.028242062777280807,
            0.024553513154387474,
            -0.009372896514832973,
            0.018197212368249893,
            -0.0061178868636488914,
            -0.0026448972057551146,
            0.028942851349711418,
            0.006680124904960394,
            0.003537518670782447,
            0.014072010293602943,
            -0.011363580822944641,
            -0.005450282245874405,
            -0.01723519153892994,
            -0.05072368308901787,
            -0.0062428745441138744,
            0.036642562597990036,
            -0.0024783120024949312,
            -0.012069322168827057,
            0.014417704194784164,
            0.022429414093494415,
            -0.004022675566375256,
            0.0014737228630110621,
            0.02313420921564102,
            -0.021647494286298752,
            -0.046569738537073135,
            -0.0583648644387722,
            -0.00911517534404993,
            0.029903966933488846,
            -0.017122436314821243,
            0.013305477797985077,
            0.023725397884845734,
            -0.02057143673300743,
            0.02882409654557705,
            -0.02133098617196083,
            0.016159262508153915,
            -0.015558800660073757,
            -0.00837892945855856,
            -0.02310974709689617,
            0.009999815374612808,
            -0.00513929408043623,
            -0.016320761293172836,
            -0.00579973915591836,
            0.02176419273018837,
            -0.03460758924484253,
            0.010998375713825226,
            -0.01852325350046158,
            0.03265365585684776,
            0.03246556967496872,
            0.007584320846945047,
            0.02773580327630043,
            0.0004045401292387396,
            0.006360488478094339,
            0.02687949500977993,
            0.0503426119685173,
            0.035573627799749374,
            0.005674562882632017,
            0.004742616321891546,
            0.02865293249487877,
            -0.025157062336802483,
            0.017238344997167587,
            -0.006239804904907942,
            0.0009327152511104941,
            0.010998920537531376,
            0.009151672944426537,
            0.007526766043156385,
            -0.02145874872803688,
            -0.029912520200014114,
            -0.013578470796346664,
            0.002833184553310275,
            -0.0025649198796600103,
            -0.01814286597073078,
            -0.02971046045422554,
            -0.05997757613658905,
            -0.012889689765870571,
            0.03965521976351738,
            -0.013758882880210876,
            -0.005291215609759092,
            0.007677991408854723,
            -0.0006087728543207049,
            -0.02764264866709709,
            -0.010603862814605236,
            0.0014477090444415808,
            0.022252697497606277,
            0.0009739393717609346,
            0.01897481456398964,
            0.03357866033911705,
            0.017702408134937286,
            -0.013293310068547726,
            -0.023432182148098946,
            -0.0016775296535342932,
            -0.017945317551493645,
            -0.006452486850321293,
            -0.0023102082777768373,
            0.0013230321928858757,
            0.013909991830587387,
            -0.015526030212640762,
            0.005348706152290106,
            0.020145650953054428,
            -0.02114369347691536,
            0.03306388482451439,
            0.0013820601161569357,
            0.005428704433143139,
            -0.03490898385643959,
            -0.01541862916201353,
            -0.01900813914835453,
            0.009527194313704967,
            0.0023473226465284824,
            -0.01229768991470337,
            -0.017085419967770576,
            -0.013293245807290077,
            0.010068236850202084,
            0.004662986844778061,
            -0.019700724631547928,
            0.0311200600117445,
            0.02784612961113453,
            0.027936212718486786,
            0.00105549325235188,
            0.010615312494337559,
            0.00672740675508976,
            -0.03260381147265434,
            0.007012070156633854,
            -0.00817652978003025,
            0.03343047574162483,
            0.021069716662168503,
            -0.009450308047235012,
            0.011850647628307343,
            0.02033359557390213,
            0.0016436855075880885,
            0.011796526610851288,
            -0.01536574773490429,
            0.008621894754469395,
            0.006793703883886337,
            0.024102307856082916,
            0.0011229233350604773,
            -0.03019411675632,
            -0.022633833810687065,
            -0.018454670906066895,
            -0.015566802583634853,
            -0.041316960006952286,
            0.00575343519449234,
            -0.005831976421177387,
            -0.017081769183278084,
            0.00011587850895011798,
            -0.0035969216842204332,
            0.0001153014090959914,
            0.007524651475250721,
            0.0137594323605299,
            0.028985528275370598,
            0.0014179989229887724,
            0.023768778890371323,
            -0.00920372549444437,
            0.03431195765733719,
            0.035295382142066956,
            0.0007468180265277624,
            -0.009401816874742508,
            -0.02576654590666294,
            -0.034586939960718155,
            0.03153281658887863,
            -0.004465383943170309,
            0.014996071346104145,
            0.015160460956394672,
            -0.025614045560359955,
            0.027613649144768715,
            0.01791243441402912,
            -0.031134765595197678,
            0.024757741019129753,
            0.01716907136142254,
            0.03199106827378273,
            -0.0008877800428308547,
            0.009759967215359211,
            0.030997401103377342,
            0.02394545078277588,
            -0.02047375962138176,
            0.03887896239757538,
            -0.021672895178198814,
            -0.015095017850399017,
            0.007956213317811489,
            -0.011088605038821697,
            0.008973626419901848,
            -0.008705643005669117,
            -0.022291293367743492,
            -0.03131833299994469,
            -0.014560531824827194,
            -0.004211912862956524,
            0.03150968253612518,
            -0.008153757080435753,
            0.00964245293289423,
            0.013787816278636456,
            0.023319527506828308,
            -0.016155235469341278,
            0.02915860339999199,
            -0.009918270632624626,
            -0.014684890396893024,
            0.005945584271103144,
            0.009470042772591114,
            0.03125476464629173,
            0.0021506219636648893,
            -0.02291152812540531,
            -0.010176461189985275,
            -0.005048870574682951,
            -0.019008178263902664,
            -0.029142215847969055,
            0.023988574743270874,
            -0.017164308577775955,
            0.023490384221076965,
            0.040363751351833344,
            -0.03623942285776138,
            0.0006066940841265023,
            -0.013220427557826042,
            0.021895311772823334,
            -0.043452609330415726,
            -0.003776080207899213,
            0.002719451440498233,
            -0.010797638446092606,
            0.009861767292022705,
            0.032346855849027634,
            -0.010840414091944695,
            -0.016566799953579903,
            -0.013837198726832867,
            -0.014285867102444172,
            0.015900082886219025,
            -0.01711103320121765,
            -0.021267425268888474,
            -0.0009709598962217569,
            0.04276097193360329,
            -0.018761401996016502,
            0.02223452739417553,
            -0.011482239700853825,
            0.02893608622252941,
            -0.01791726052761078,
            0.005870666820555925,
            -0.008862474001944065,
            -0.0022692731581628323,
            0.021898597478866577,
            0.04551396891474724,
            0.01763354241847992,
            0.03540961444377899,
            0.029111530631780624,
            0.021606391295790672,
            -0.004016309976577759,
            -0.010904083959758282,
            -0.005451291333884001,
            -0.0019959374330937862,
            0.02443687431514263,
            0.04141831398010254,
            0.012623528018593788,
            -0.029769470915198326,
            -0.014345763251185417,
            -0.00835985504090786,
            0.0439581461250782,
            0.01618235558271408,
            0.024628136307001114,
            -0.004544433671981096,
            -0.01604408770799637,
            0.003304757410660386,
            -0.02126748114824295,
            0.03490037843585014,
            0.01800622045993805,
            -0.023573359474539757,
            -0.0240519680082798,
            0.023693202063441277,
            -0.01647615246474743,
            0.010875048115849495,
            0.019179269671440125,
            -0.014087390154600143,
            -0.006665131542831659,
            -0.01708921790122986,
            0.0032224750611931086,
            -0.022442864254117012,
            0.025894640013575554,
            0.0017943322891369462,
            0.03190099820494652,
            0.01727510243654251,
            0.0032115664798766375,
            0.003712508361786604,
            0.01762051321566105,
            -0.028046676889061928,
            0.014652597717940807,
            -0.007256726268678904,
            0.03184986859560013,
            -0.002437494695186615,
            0.03474189341068268,
            0.020042886957526207,
            0.011979417875409126,
            0.02163826860487461,
            -0.028759421780705452,
            0.03089957870543003,
            -0.006619937252253294,
            -0.004627713467925787,
            0.017049454152584076,
            0.010941078886389732,
            -0.03437023609876633,
            -0.011723300442099571,
            0.004775713197886944,
            -0.03597543388605118,
            -0.014865191653370857,
            0.025732913985848427,
            0.025255410000681877,
            -0.027363162487745285,
            0.006727168336510658,
            -0.018997076898813248,
            -0.05206407606601715,
            0.01857677660882473,
            -0.008016739971935749,
            -0.012032250873744488,
            -0.00313854799605906,
            -0.02395137958228588,
            -0.013038985431194305,
            0.03310292586684227,
            -0.03485151380300522,
            0.020150966942310333,
            -0.0022089944686740637,
            -0.025578709319233894,
            -0.023265358060598373,
            -0.020071430131793022,
            -0.0016527827829122543,
            0.03562673553824425,
            1.3555362784245517e-05,
            -0.020346317440271378,
            0.0002956229727715254,
            0.017198365181684494,
            0.008811100386083126,
            -0.017361080273985863,
            -0.033351872116327286,
            -0.013279914855957031,
            0.010340948589146137,
            0.03418491780757904,
            0.02745560184121132,
            0.012698303908109665,
            0.02116970904171467,
            -0.0005802068044431508,
            -0.007884044200181961,
            0.022086050361394882,
            -0.025062013417482376,
            0.006653051357716322,
            -0.04216720536351204,
            0.032232679426670074,
            -0.011280070059001446,
            0.01226064469665289,
            0.0349234864115715,
            0.009326997213065624,
            -0.029852809384465218,
            0.0466240756213665,
            -0.018053891137242317,
            0.014883983880281448,
            -0.02063886821269989,
            0.009251079522073269,
            -0.010668512433767319,
            -0.002140624448657036,
            -0.02446504309773445,
            0.023989127948880196,
            0.008240007795393467,
            -0.0034053900744765997,
            0.03723064064979553,
            -0.03405226394534111,
            -0.01633470132946968,
            -0.0019979444332420826,
            -0.015659436583518982,
            -0.025316564366221428,
            0.01622159592807293,
            -0.01932026445865631,
            -0.005796225741505623,
            -0.008023795671761036,
            -0.02399950474500656,
            0.03211136534810066,
            -0.021645374596118927,
            -0.011417917907238007,
            -0.030463652685284615,
            0.035480204969644547,
            -0.02552707865834236,
            -0.018840039148926735,
            0.023542525246739388,
            -0.07417403906583786,
            -0.003447212977334857,
            -0.014258008450269699,
            0.01980471797287464,
            0.004888580180704594,
            -0.04110969975590706,
            0.024912243708968163,
            0.008903875946998596,
            0.020593862980604172,
            -0.016102800145745277,
            0.002457368653267622,
            0.0031860494054853916,
            -0.03272465243935585,
            -0.011349312029778957,
            0.010394711047410965,
            -0.014689456671476364,
            -0.049766022711992264,
            0.052736733108758926,
            0.022145863622426987,
            -0.001527104526758194,
            0.010455572046339512,
            -0.02998158521950245,
            0.029892314225435257,
            0.00794954877346754,
            0.03306366130709648,
            0.0003204536915291101,
            -0.01391509734094143,
            0.027342047542333603,
            0.025697477161884308,
            -0.016900410875678062,
            0.03888644278049469,
            -0.010508356615900993,
            0.0036072349175810814,
            -0.0001998383813770488,
            0.002536327112466097,
            0.005665045231580734,
            0.018881266936659813,
            -0.024309534579515457,
            0.03645157441496849,
            0.019981104880571365,
            0.05486874282360077,
            0.011594432406127453,
            0.0010648060124367476,
            0.007218594197183847,
            -0.002695881761610508,
            0.038449421525001526,
            0.0027701416984200478,
            0.03500808775424957,
            0.0038399260956794024,
            -0.027644623070955276,
            -0.0054812743328511715,
            0.018588513135910034,
            -0.009168471209704876,
            -0.005059533752501011,
            -0.0035108719021081924,
            0.028168736025691032,
            0.018024992197752,
            -0.010088831186294556,
            0.04240595921874046,
            0.010006415657699108,
            0.015311198309063911,
            0.02521718107163906,
            0.006846525240689516,
            -0.03670807555317879,
            -0.022518394514918327,
            -0.008944478817284107,
            0.01741868071258068,
            -0.021391185000538826,
            -0.0046195681206882,
            -0.03472001105546951,
            0.004958919249475002,
            0.004165853373706341,
            -0.03149319440126419,
            -0.013490697368979454,
            0.00670485058799386,
            -0.001252301037311554,
            -0.03645700588822365,
            0.006019040010869503,
            -0.052838586270809174,
            -0.043572284281253815,
            -0.01884293556213379,
            0.007237694226205349,
            -0.022048523649573326,
            -0.011415278539061546,
            0.02168629877269268,
            -0.009177975356578827,
            0.0024284874089062214,
            -0.0024480530992150307,
            0.030746597796678543,
            -0.006608078721910715,
            0.0005297638708725572,
            -0.00453064264729619,
            -0.02908163331449032,
            -0.012369159609079361,
            -0.03186141327023506,
            0.028830647468566895,
            0.032357413321733475,
            -0.009916579350829124,
            -0.0016116145998239517,
            0.017149878665804863,
            -0.022575654089450836,
            0.017825596034526825,
            0.011714823544025421,
            -0.01762455701828003,
            0.014120987616479397,
            0.014299450442194939,
            -0.03238406032323837,
            0.02750910073518753,
            0.040855325758457184,
            0.0013811218086630106,
            -0.0027234824374318123,
            0.011473346501588821,
            -0.0168161503970623,
            0.03292826563119888,
            -0.014447475783526897,
            -0.007918217219412327,
            -0.013714010827243328,
            -0.00735315540805459,
            -0.023322585970163345,
            0.0018391783814877272,
            -0.02853257767856121,
            -0.018211305141448975,
            0.015434155240654945,
            -0.01702919416129589,
            -0.0065528773702681065,
            -0.016962599009275436,
            0.02339889295399189,
            -0.0010622590780258179,
            0.015695806592702866,
            -0.017649326473474503,
            -0.03304850682616234,
            -0.024563660845160484,
            -0.0051092845387756824,
            -0.040868960320949554,
            0.017288897186517715,
            -0.010890796780586243,
            -0.02818496711552143,
            0.006684806663542986,
            0.016858894377946854,
            0.008411207236349583,
            -0.0020693226251751184,
            -0.011487973853945732,
            0.03242113068699837,
            0.012758667580783367,
            -0.009704448282718658,
            -0.00920788198709488,
            -0.0008191967499442399,
            -0.02810659632086754,
            0.041387878358364105,
            0.024599192664027214,
            0.05152563005685806,
            0.0004280187131371349,
            0.01218837033957243,
            0.03584805876016617,
            -0.02026255801320076,
            -0.01893901452422142,
            0.0028929910622537136,
            0.006497518625110388,
            0.02484404295682907,
            -0.000561549561098218,
            -0.027798932045698166,
            0.0016047053504735231,
            0.006752112880349159,
            0.03646096587181091,
            -0.041327960789203644,
            -0.014904089272022247,
            -0.0009832849027588964,
            -0.029980482533574104,
            -0.007178004831075668,
            0.007516557350754738,
            -0.008099495433270931,
            0.009567351080477238,
            -0.040009982883930206,
            0.013704949989914894,
            0.017082128673791885,
            0.007717639673501253,
            -0.01032546442002058,
            0.021062174811959267,
            0.014521511271595955,
            -0.013985015451908112,
            0.025758393108844757,
            -0.02046332322061062,
            0.03266213834285736,
            -0.024534126743674278,
            -0.0022296891547739506,
            0.003139183158054948,
            -0.004114373121410608,
            0.025311660021543503,
            -0.020278235897421837,
            -0.009435229934751987,
            -0.010285861790180206,
            -0.019266942515969276,
            0.016006218269467354,
            -0.004162453580647707,
            0.024175165221095085,
            0.007421383634209633,
            0.0024310224689543247,
            -0.016357457265257835,
            -0.008648109622299671,
            0.018537038937211037,
            -0.009708508849143982,
            -0.033553920686244965,
            0.004633534699678421,
            0.01219186745584011,
            -0.054051999002695084,
            -0.02131905034184456,
            -0.004018538631498814,
            0.022510195150971413,
            -0.025465356186032295,
            0.02570665441453457,
            0.0146872503682971,
            -0.026500564068555832,
            -0.009126164950430393,
            -0.004860604181885719,
            -0.0097011374309659,
            -0.03992496058344841,
            0.023129114881157875,
            -0.014748536050319672,
            -0.01580461859703064,
            -0.01490664854645729,
            -0.012483062222599983,
            -0.020046669989824295,
            -0.004685080144554377,
            0.027903685346245766,
            0.016002384945750237,
            -0.0031300289556384087,
            -0.006743487901985645,
            -0.022463863715529442,
            0.0144687844440341,
            0.0160187017172575
        ],
        [
            0.0025936344172805548,
            -0.02359703555703163,
            -0.02152257040143013,
            0.011155351996421814,
            -0.014553282409906387,
            -0.00959017500281334,
            0.014475502073764801,
            -0.009366331622004509,
            -0.014297837391495705,
            0.032976992428302765,
            -0.03284582868218422,
            -0.028789857402443886,
            0.01791783608496189,
            0.012534897774457932,
            0.07258100062608719,
            -0.0534883588552475,
            0.018203288316726685,
            0.0020872231107205153,
            -0.015957679599523544,
            -0.009182577021420002,
            0.006980482954531908,
            -0.0031822538003325462,
            -0.042429368942976,
            -0.006914450321346521,
            -0.005010560620576143,
            -0.004319272469729185,
            -0.009518912062048912,
            -0.012578573077917099,
            0.032962583005428314,
            0.015835462138056755,
            -0.006324414163827896,
            -0.004990242887288332,
            0.008320437744259834,
            -0.00761884031817317,
            0.04338913410902023,
            -0.002906777197495103,
            0.0009940940653905272,
            -0.007478487677872181,
            -0.012249113991856575,
            -0.0010577489156275988,
            -0.012701238505542278,
            0.02436736412346363,
            0.01782768778502941,
            -0.003729996969923377,
            -0.019606351852416992,
            -0.04563245177268982,
            8.912863995647058e-05,
            0.0324501097202301,
            -0.012384272180497646,
            0.010046827606856823,
            0.010713514871895313,
            0.019574608653783798,
            0.027613317593932152,
            -0.020380932837724686,
            0.011837342754006386,
            -0.011614618822932243,
            0.021926574409008026,
            -0.006808054633438587,
            -0.013888190500438213,
            -0.026403749361634254,
            -0.019484907388687134,
            0.006264796946197748,
            -0.007881535217165947,
            -0.014674900099635124,
            -0.04785340279340744,
            0.0044172052294015884,
            -0.03290274739265442,
            0.019633641466498375,
            -0.018439920619130135,
            -0.007050246000289917,
            0.004557984881103039,
            -0.018649596720933914,
            0.027894752100110054,
            0.002549682045355439,
            -0.00017325370572507381,
            0.003362146206200123,
            0.00396159989759326,
            -0.009957953356206417,
            -0.03393084928393364,
            0.006414404138922691,
            -0.008888046257197857,
            0.0147896409034729,
            0.021006805822253227,
            -0.0101874815300107,
            -0.020160377025604248,
            0.010999534279108047,
            -0.005132389720529318,
            0.002439337782561779,
            -0.01680821366608143,
            -0.0222417414188385,
            -0.0020755091682076454,
            0.015215407125651836,
            -0.014384835958480835,
            0.005197534337639809,
            -0.03142853453755379,
            0.006176014430820942,
            -0.019549572840332985,
            0.033759552985429764,
            -0.06824938207864761,
            -0.0042493618093431,
            -0.03676190972328186,
            0.030135424807667732,
            0.008700131438672543,
            0.011565390042960644,
            0.0171463992446661,
            -0.006690461654216051,
            0.03217761591076851,
            0.009874045848846436,
            -0.001804876490496099,
            -0.017896359786391258,
            0.02174036204814911,
            -0.019841458648443222,
            0.01427109818905592,
            0.009298380464315414,
            -0.03486285358667374,
            0.008820325136184692,
            -0.024564582854509354,
            -0.017765387892723083,
            0.015306344255805016,
            0.021116608753800392,
            0.029311859980225563,
            0.03423274680972099,
            -0.00441139517351985,
            0.011274510994553566,
            -0.01670132391154766,
            0.010709116235375404,
            0.02694394625723362,
            -0.02606605924665928,
            0.030342189595103264,
            -0.004352421499788761,
            0.008007025346159935,
            -0.0038669228088110685,
            -0.008099383674561977,
            0.014260789379477501,
            0.011496750637888908,
            -0.013891471549868584,
            0.040624234825372696,
            -0.007311307825148106,
            -0.02763877995312214,
            0.00023964951105881482,
            0.0038303202018141747,
            0.0017364773666486144,
            0.001759371138177812,
            -0.02204989828169346,
            0.021502314135432243,
            -0.01533526461571455,
            0.017082346603274345,
            -0.027245571836829185,
            0.035685669630765915,
            -0.011652396060526371,
            0.032433073967695236,
            0.056923311203718185,
            -0.00017429333820473403,
            0.06098025664687157,
            0.03992190212011337,
            0.023243345320224762,
            -0.017717568203806877,
            -0.014509676955640316,
            -0.03886411711573601,
            0.026193134486675262,
            -0.007963007315993309,
            0.028879843652248383,
            -0.03588682413101196,
            0.014119555242359638,
            -0.007677400950342417,
            -0.006608039606362581,
            -0.0034305057488381863,
            0.027396488934755325,
            -0.03657142445445061,
            0.009909950196743011,
            -0.004047710914164782,
            -0.0047739045694470406,
            -0.00399461854249239,
            0.022693725302815437,
            -0.0007555620977655053,
            0.02319095842540264,
            -0.029932444915175438,
            0.007171767298132181,
            -0.012847757898271084,
            0.00179566559381783,
            -0.00984385795891285,
            0.010928492061793804,
            0.023939143866300583,
            0.03747947886586189,
            -0.0068912566639482975,
            0.016443757340312004,
            0.011325298808515072,
            0.02991914562880993,
            0.007430456578731537,
            -0.00211920659057796,
            0.006123687606304884,
            0.013033812865614891,
            -0.09468945115804672,
            -0.01531466655433178,
            0.0062322155572474,
            0.01378484908491373,
            -0.021939458325505257,
            0.008938898332417011,
            -0.002938418183475733,
            0.012576323933899403,
            -0.026899153366684914,
            0.011669761501252651,
            0.02995450235903263,
            -0.021176084876060486,
            -0.03392811492085457,
            0.002997060539200902,
            0.03596334531903267,
            -0.003934485837817192,
            0.0026349471881985664,
            -0.01986510492861271,
            0.007284846622496843,
            0.01708303950726986,
            0.015118056908249855,
            0.02367868460714817,
            -0.023399602621793747,
            -0.028186963871121407,
            -0.03943648561835289,
            -0.01963098533451557,
            0.01611868478357792,
            0.0019400417804718018,
            -0.025707244873046875,
            0.035855140537023544,
            0.02155303582549095,
            -0.0012986373621970415,
            0.028271451592445374,
            -0.017304444685578346,
            0.02525230124592781,
            0.02905057556927204,
            0.013054839335381985,
            0.002054051961749792,
            0.011077763512730598,
            0.007447125855833292,
            0.02180444449186325,
            0.019736452028155327,
            -0.01749037206172943,
            0.009316476061940193,
            -0.018097203224897385,
            0.023688219487667084,
            0.0010127180721610785,
            -0.0021015536040067673,
            -0.012555859051644802,
            -0.009289362467825413,
            0.015775030478835106,
            0.010088595561683178,
            0.0436086542904377,
            -0.024164868518710136,
            0.017361100763082504,
            -0.008228279650211334,
            0.012820498086512089,
            0.009094984270632267,
            -0.022971095517277718,
            -0.017453743144869804,
            -0.007759502157568932,
            -0.00685243820771575,
            -0.004994639195501804,
            0.009720854461193085,
            0.0248600821942091,
            -0.0007259458070620894,
            -0.028486263006925583,
            -0.0010654641082510352,
            -0.008290724828839302,
            0.014753284864127636,
            -0.0036310579162091017,
            0.007501346990466118,
            0.008333827368915081,
            -0.02670043334364891,
            -0.02275460585951805,
            -0.013458614237606525,
            -0.0016329021891579032,
            -0.03654441237449646,
            -0.0068603139370679855,
            -0.012718581594526768,
            0.024412566795945168,
            0.011684926226735115,
            0.007189547177404165,
            0.003624918404966593,
            0.001009968458674848,
            -0.009487076662480831,
            0.013461122289299965,
            -0.030019164085388184,
            -0.01669630967080593,
            0.029789714142680168,
            -0.0008478904492221773,
            -0.0030590479727834463,
            -0.010212707333266735,
            0.017209114506840706,
            -0.01656077802181244,
            0.016746971756219864,
            -0.01024116761982441,
            -0.028960615396499634,
            -0.020889535546302795,
            -0.029475321993231773,
            0.010844947770237923,
            -0.00194255611859262,
            0.010123850777745247,
            0.01307579968124628,
            0.004844297654926777,
            0.0028232235927134752,
            -0.026625480502843857,
            -0.020558947697281837,
            -0.021209144964814186,
            -0.013007372617721558,
            0.012430194765329361,
            0.021769829094409943,
            -0.019192513078451157,
            0.055380042642354965,
            0.00047758928849361837,
            0.05359513312578201,
            -0.020877350121736526,
            -0.017001772299408913,
            -0.007840222679078579,
            -0.026293372735381126,
            -0.02865736000239849,
            0.020946789532899857,
            -0.0006281763198785484,
            0.002302308566868305,
            0.0032440973445773125,
            0.0009636739268898964,
            0.022448711097240448,
            0.00038259930443018675,
            0.027325410395860672,
            -0.020323017612099648,
            0.028737278655171394,
            0.008847435936331749,
            0.012188722379505634,
            -0.004826684482395649,
            -0.010579838417470455,
            -0.0030760110821574926,
            -0.013656813651323318,
            -0.020357713103294373,
            -0.003271770430728793,
            -0.02594168670475483,
            0.017047910019755363,
            0.012344876304268837,
            -0.004763540346175432,
            -0.0014213609974831343,
            0.01776394434273243,
            0.017799966037273407,
            -0.008012068457901478,
            0.009721074253320694,
            0.034858230501413345,
            0.0017543556168675423,
            -0.0493440143764019,
            0.02772720530629158,
            -0.023800799623131752,
            0.025657476857304573,
            0.03013721853494644,
            0.005888830870389938,
            0.02835932746529579,
            -0.01435675285756588,
            -0.03384305164217949,
            -0.0021666008979082108,
            -0.0005044957506470382,
            0.0035994022618979216,
            -0.031227167695760727,
            0.012608647346496582,
            0.0065969969145953655,
            0.025695186108350754,
            0.00489237392321229,
            0.014805549755692482,
            0.010512418113648891,
            0.026101741939783096,
            -0.004448768682777882,
            -0.029452888295054436,
            0.010350090451538563,
            -0.01701248064637184,
            0.049655716866254807,
            -0.0035673927050083876,
            -0.030911460518836975,
            0.019170468673110008,
            -0.025752250105142593,
            0.024231547489762306,
            -0.011893906630575657,
            0.0356818363070488,
            0.018202802166342735,
            0.01208155881613493,
            -0.03728114813566208,
            0.03378261998295784,
            0.01588381454348564,
            0.025595810264348984,
            0.015161074697971344,
            -0.01255554985255003,
            0.023081522434949875,
            0.016559474170207977,
            -0.0359288826584816,
            -0.00987289473414421,
            -0.014221017248928547,
            -0.010693344287574291,
            -0.02602027729153633,
            0.005867964122444391,
            -0.015119129791855812,
            0.011335179209709167,
            -0.02629133127629757,
            -0.023367907851934433,
            0.04489491134881973,
            0.00808375608175993,
            -0.011801832355558872,
            -0.036293379962444305,
            0.026792587712407112,
            -0.003567962907254696,
            -0.042179521173238754,
            0.03532552346587181,
            0.008246930316090584,
            -0.019629184156656265,
            -0.013237831182777882,
            -0.0069063277915120125,
            -0.005237203557044268,
            -0.000766387558542192,
            0.01994437910616398,
            0.014085017144680023,
            0.007583966478705406,
            0.0019602570682764053,
            -0.00021512378589250147,
            -0.01801392249763012,
            0.02590329945087433,
            -0.02516065537929535,
            -0.006351349409669638,
            -0.0089850639924407,
            0.025168731808662415,
            0.010525240562856197,
            -0.013036503456532955,
            0.016889750957489014,
            0.008165872655808926,
            -0.008055138401687145,
            -0.020076345652341843,
            -0.00513903284445405,
            -0.011220555752515793,
            0.01502571813762188,
            0.02322966605424881,
            0.01026502437889576,
            0.022134123370051384,
            0.011760304681956768,
            -0.016992533579468727,
            0.030772384256124496,
            -4.141242243349552e-05,
            0.023292850703001022,
            -0.009778941981494427,
            -0.006335902027785778,
            -0.007581508718430996,
            -0.024917609989643097,
            -0.02881326898932457,
            0.02938104420900345,
            -0.014821047894656658,
            -0.008631478995084763,
            0.017151610925793648,
            0.00022790237562730908,
            -0.020179692655801773,
            -0.08878472447395325,
            -0.017889276146888733,
            -0.008423621766269207,
            -0.015500434674322605,
            0.035629257559776306,
            0.055382147431373596,
            -0.01570005901157856,
            -0.0026688927318900824,
            -0.0033906553871929646,
            0.03820021077990532,
            -0.019762789830565453,
            -0.008226638659834862,
            0.02104022167623043,
            0.00591179309412837,
            0.006677333265542984,
            -0.026731064543128014,
            -0.0035933596082031727,
            0.021632976830005646,
            -0.016890624538064003,
            0.009306698106229305,
            -0.0063059451058506966,
            0.02216438576579094,
            0.006922433152794838,
            0.008220940828323364,
            0.0023359712213277817,
            0.02703695185482502,
            0.03242811560630798,
            0.017357058823108673,
            0.012059925124049187,
            -0.013483913615345955,
            0.04194331169128418,
            -0.008223517797887325,
            0.025378476828336716,
            0.01023790892213583,
            0.014876835979521275,
            -0.037824761122465134,
            -0.021996010094881058,
            0.002999334828928113,
            -0.006516939494758844,
            0.017948484048247337,
            -0.0023994669318199158,
            -0.1178462952375412,
            -0.016118356958031654,
            0.03056345507502556,
            -0.011576495133340359,
            -0.02317669428884983,
            -0.021547045558691025,
            0.0008526589372195303,
            0.014398634433746338,
            -0.018030107021331787,
            0.01995146833360195,
            0.0077136666513979435,
            -0.012055767700076103,
            0.021374575793743134,
            0.02921370416879654,
            0.026651350781321526,
            -0.03118724562227726,
            -0.009524240158498287,
            -0.009088827297091484,
            -0.0014489642344415188,
            0.011974249966442585,
            -0.012361838482320309,
            0.015467341989278793,
            0.001567236497066915,
            -0.012401025742292404,
            -0.011872319504618645,
            0.0469231903553009,
            0.010324543341994286,
            0.012919384986162186,
            -0.010607690550386906,
            -0.0017232673708349466,
            -0.01436038687825203,
            -0.029379036277532578,
            -0.00032918379292823374,
            -0.0022877620067447424,
            0.011135824024677277,
            0.047828130424022675,
            -0.020190062001347542,
            -0.001356917549856007,
            0.025752155110239983,
            -0.004912952426820993,
            -0.0033834464848041534,
            0.010675613768398762,
            0.001994852675125003,
            0.031072232872247696,
            -0.026548311114311218,
            -0.00929162185639143,
            -0.016389692202210426,
            0.02203300967812538,
            0.02686309441924095,
            -0.03022591397166252,
            -0.017809715121984482,
            -0.02327038161456585,
            0.008036763407289982,
            0.0040067764930427074,
            -0.012241561897099018,
            0.007412032689899206,
            0.017425833269953728,
            0.046901509165763855,
            -0.0006677014171145856,
            -0.015359251759946346,
            -0.008001367561519146,
            -0.017469795420765877,
            0.009984908625483513,
            -0.0029446508269757032,
            -0.0248907208442688,
            0.03226605802774429,
            0.029668446630239487,
            -0.028433773666620255,
            -0.043039366602897644,
            -0.01727301999926567,
            0.018457241356372833,
            0.015136602334678173,
            -0.03203582391142845,
            -0.015367956832051277,
            -0.006054578814655542,
            -5.166242317500291e-06,
            0.027123527601361275,
            0.014948144555091858,
            0.020172296091914177,
            -0.002672038273885846,
            0.014867780730128288,
            0.003924992401152849,
            0.022154206410050392,
            0.005105928517878056,
            0.008212285116314888,
            0.013294079340994358,
            0.005630733910948038,
            0.0367632694542408,
            0.028109541162848473,
            -0.015368156135082245,
            -0.022876519709825516,
            -0.0032447504345327616,
            -0.01232974510639906,
            0.039129797369241714,
            -0.022155584767460823,
            -0.04040997102856636,
            0.015229747630655766,
            0.018024858087301254,
            -0.028907766565680504,
            0.0009486022172495723,
            -0.008626895025372505,
            -0.016000807285308838,
            -0.02074391208589077,
            0.008274851366877556,
            -0.004927997477352619,
            0.010940689593553543,
            0.0055322349071502686,
            -0.0013506504474207759,
            -0.017573058605194092,
            -0.03775656223297119,
            0.009317190386354923,
            0.02173852175474167,
            0.0006163993966765702,
            0.0015125663485378027,
            0.009784522466361523,
            -0.011866903863847256,
            0.030292602255940437,
            0.002002580091357231,
            0.0118589261546731,
            0.009487820789217949,
            0.010177618823945522,
            0.016032954677939415,
            -0.00038416762254200876,
            -0.023257652297616005,
            0.029213836416602135,
            -0.012322693131864071,
            -0.010969569906592369,
            0.010100995190441608,
            0.017573457211256027,
            0.024181215092539787,
            0.018247216939926147,
            -0.029207155108451843,
            -0.022884001955389977,
            -0.02722288854420185,
            -0.021395087242126465,
            -0.03852041810750961,
            -0.023695264011621475,
            0.017566286027431488,
            -0.0014471984468400478,
            0.024610288441181183,
            0.0170021690428257,
            -0.024528998881578445,
            -0.009608682245016098,
            0.016794651746749878,
            -0.01997939497232437,
            -0.003995416685938835,
            -0.011532958596944809,
            0.038100212812423706,
            0.02835162729024887,
            -0.012470741756260395,
            -0.02504977583885193,
            -0.015348527580499649,
            -0.014854056760668755,
            0.02225179225206375,
            0.013772672042250633,
            0.02415262535214424,
            -0.0044236560352146626,
            0.0014115634839981794,
            0.0012001547729596496,
            0.014003430493175983,
            -0.016870906576514244,
            -0.008318508975207806,
            0.019786465913057327,
            0.029932785779237747,
            -0.012061589397490025,
            -0.009747805073857307,
            -0.026721643283963203,
            -0.010467981919646263,
            0.04687628895044327,
            0.011876235716044903,
            0.0025878597516566515,
            0.0007699481793679297,
            0.017173653468489647,
            0.010923625901341438,
            0.011184116825461388,
            -0.014426729641854763,
            0.016436846926808357,
            -0.018469512462615967,
            0.03894143924117088,
            1.858431460277643e-05,
            0.023177187889814377,
            -0.0402369350194931,
            -0.05563795194029808,
            -0.022137001156806946,
            -0.007637438364326954,
            -0.020661216229200363,
            -0.023749081417918205,
            0.002922711195424199,
            0.022836418822407722,
            -0.02556455507874489,
            -0.016619622707366943,
            -0.011737566441297531,
            0.004347297828644514,
            0.0011218271683901548,
            -0.02106003277003765,
            -0.03352160379290581,
            0.0012547688093036413,
            0.010994854383170605,
            0.0032539190724492073,
            0.01117322500795126,
            -0.016180982813239098,
            0.018139148131012917,
            0.028066836297512054,
            -0.031010035425424576,
            -0.03079402819275856,
            -0.0025773998349905014,
            0.026004089042544365,
            -0.035032302141189575,
            -0.0006956516299396753,
            -0.01701452024281025,
            -0.029915472492575645,
            0.037210263311862946,
            0.011350126937031746,
            0.0059682042337954044,
            0.03498752415180206,
            0.03095102868974209,
            0.029161572456359863,
            -0.005848766304552555,
            0.015580099076032639,
            -0.017542840912938118,
            0.0023767463862895966,
            0.0037508683744817972,
            0.017784500494599342,
            -0.0039004862774163485,
            0.01209101639688015,
            -0.00510137714445591,
            -0.013057095929980278,
            -0.013743061572313309,
            -0.062757708132267,
            -0.026074374094605446,
            0.017562691122293472,
            0.0024007430765777826,
            -0.026321973651647568,
            0.021374041214585304,
            -0.025812312960624695,
            0.016105985268950462,
            -0.029229620471596718,
            -0.012853642925620079,
            0.016627678647637367,
            -0.016805734485387802,
            -0.02603296935558319,
            -0.005748780909925699,
            -0.017584506422281265,
            -0.02421620860695839,
            0.017537511885166168,
            -0.02096835896372795,
            -0.0005882728728465736,
            -0.011931521818041801,
            0.0320894792675972,
            0.005156953819096088,
            -0.0022915820591151714,
            0.05226796865463257,
            -0.029499966651201248,
            -0.004346085246652365,
            0.02444872446358204,
            0.01662197895348072,
            0.02505667693912983,
            0.009896614588797092,
            0.02308274805545807,
            -0.009089830331504345,
            -0.01733117550611496,
            -0.01781606115400791,
            0.02978770062327385,
            0.006180592812597752,
            0.012418684549629688,
            0.005860738921910524,
            0.023507416248321533,
            0.034968845546245575,
            0.011107216589152813,
            -0.027793359011411667,
            -0.027169005945324898,
            0.0020791683346033096,
            0.030354969203472137,
            -0.05079629272222519,
            0.03327305614948273,
            0.009830644354224205,
            -0.005634864792227745,
            0.00409846194088459,
            0.004662869963794947,
            0.02034643106162548,
            0.0015905714826658368,
            -0.01182312797755003,
            0.012027609162032604,
            -0.010545761324465275,
            0.03544456884264946,
            0.0008281927439384162,
            0.02543521858751774,
            0.005675043445080519,
            0.009632055647671223,
            0.022563083097338676,
            0.023206910118460655,
            -0.005515186116099358,
            0.021088864654302597,
            -0.014538591727614403,
            0.022174857556819916,
            -0.028979070484638214,
            -0.015147779136896133,
            -0.015889042988419533,
            0.0009047914063557982,
            0.010225251317024231,
            -0.0012694327160716057,
            0.009173593483865261,
            0.0047293296083807945,
            0.03687091916799545,
            -0.013431231491267681,
            0.00786784291267395,
            -0.014519591815769672,
            -0.0036068407353013754,
            0.021997222676873207,
            0.009788856841623783,
            -0.0015551684191450477,
            -0.034290164709091187,
            0.027686957269906998,
            0.017423739656805992,
            -0.0003969905956182629,
            -0.03308488801121712,
            -0.004747918341308832,
            0.010888912715017796,
            -0.027596060186624527,
            -0.005382157862186432,
            0.00799453817307949,
            0.004137607757002115,
            0.007260960526764393,
            0.016500089317560196,
            -0.008955453522503376,
            -0.01064646802842617,
            -0.004476223606616259,
            0.01117641944438219,
            0.0200832299888134,
            0.0016373945400118828,
            -0.00013112378655932844,
            -0.010490553453564644,
            0.02619951404631138,
            0.005704312585294247,
            -0.018817583099007607,
            0.008566112257540226,
            -0.024838455021381378,
            0.016032930463552475,
            0.020916171371936798,
            0.0319950133562088,
            0.025480935350060463,
            -0.0166326891630888,
            -0.017935527488589287,
            -0.016034850850701332,
            0.04238599166274071,
            0.01874375157058239,
            0.056052230298519135,
            -0.014109315350651741,
            -0.03182912617921829,
            -0.02142665721476078,
            0.013626431114971638,
            0.04498931020498276,
            0.020234478637576103,
            0.030072329565882683,
            -0.0034909683745354414,
            -0.036992207169532776,
            -0.031073659658432007,
            -0.029962986707687378,
            -0.0081886425614357,
            -0.0010810575913637877,
            -0.04127499833703041,
            0.01362735778093338,
            -0.029412463307380676,
            0.013921978883445263,
            0.003363084513694048,
            -0.02838384546339512,
            0.005606147460639477,
            0.023633407428860664,
            -0.013785216957330704,
            -0.0020197564736008644,
            0.03777610510587692,
            0.00018051304505206645,
            -0.028951767832040787,
            0.030376331880688667,
            -0.0028833183459937572,
            0.002336501842364669,
            -0.015820441767573357,
            0.03477822616696358,
            0.02866562269628048,
            -0.030016567558050156,
            0.032842230051755905,
            0.007929794490337372,
            -0.005499562714248896,
            -0.020692018792033195,
            -0.014765216037631035,
            -0.02161705493927002,
            0.027247413992881775,
            0.011059422977268696,
            -0.010044866241514683,
            0.0010366813512519002,
            0.031729552894830704,
            -0.02598496340215206,
            0.0015294316690415144,
            0.024735448881983757,
            -0.010737625882029533,
            0.009051461704075336,
            -0.026665277779102325,
            0.014506198465824127,
            -0.016549531370401382,
            0.014121445827186108,
            -0.03296371176838875,
            -0.007605757098644972,
            -0.00827536080032587,
            0.014680887572467327,
            0.01772596687078476,
            -0.0019621241372078657,
            -0.02327120304107666,
            0.004345430061221123,
            0.004104746971279383,
            -0.010832651518285275,
            0.013193790800869465,
            -0.029941067099571228,
            0.01753862202167511,
            -5.8481324231252074e-05,
            0.005327777471393347,
            -0.014865996316075325,
            -0.0044691492803394794,
            -0.010131998918950558,
            -0.025466840714216232,
            0.004325664136558771,
            0.02285061590373516,
            -0.003051806939765811,
            -0.010849650017917156,
            0.027257852256298065,
            0.0363432876765728,
            -0.021647999063134193,
            -0.001375386375002563,
            -0.02482028491795063,
            0.02267472818493843,
            0.01199640054255724,
            0.03475095331668854,
            0.008541416376829147,
            0.0015370184555649757,
            -0.0004608101735357195,
            0.016394082456827164,
            0.030612969771027565,
            0.007654455024749041,
            0.0430302768945694,
            0.012246029451489449,
            -0.029243791475892067,
            -0.01700940728187561,
            -0.00186301046051085,
            0.02120024710893631,
            0.0003893711545970291,
            -0.005912534426897764,
            0.011182717978954315,
            0.04725075513124466,
            -0.035197485238313675,
            -0.022078435868024826,
            0.017974894493818283,
            0.005008460488170385,
            -0.006153077352792025,
            0.011145533993840218,
            0.02333339862525463,
            0.009359562769532204,
            0.012377426028251648,
            0.01560341939330101,
            -0.006804781500250101,
            0.028133394196629524,
            0.048880767077207565,
            0.019206492230296135,
            0.014692433178424835,
            0.007054740097373724,
            -0.012880870141088963,
            -0.006331648211926222,
            -0.03202284500002861,
            0.013669813983142376,
            0.033556193113327026,
            0.022818908095359802,
            -0.0003033493703696877,
            -0.005256940145045519,
            0.01980460248887539,
            -0.03304681554436684,
            -0.026668023318052292,
            0.03622443601489067,
            0.013212562538683414,
            -0.022530758753418922,
            0.027678921818733215,
            -0.010785208083689213,
            0.015034692361950874,
            -0.01990310661494732,
            -0.00946133490651846,
            0.022711345925927162,
            0.013957623392343521,
            -0.021081196144223213,
            -0.0032191381324082613,
            -0.01218797080218792,
            0.026339007541537285,
            -0.016189532354474068,
            -0.034606825560331345,
            -0.009590432979166508,
            -0.007702184375375509,
            -0.04731614142656326,
            -0.016067463904619217,
            0.012638469226658344,
            0.025197777897119522,
            -0.00905624683946371,
            -0.028875108808279037,
            -0.026410380378365517,
            -0.004353936295956373,
            0.0001945970143424347,
            0.004893540870398283,
            -0.00016107260307762772,
            -0.008054036647081375,
            0.0026711185928434134,
            -0.01596064120531082,
            -0.015208747237920761,
            -0.007895567454397678,
            0.00828916672617197,
            -0.02532068081200123,
            -0.0167690496891737,
            0.007213562726974487,
            -0.029379289597272873,
            0.02286478690803051,
            -0.010653139092028141,
            -0.01634368672966957,
            0.014217386022210121,
            0.005968053825199604,
            0.02075907029211521,
            -0.009469715878367424,
            -0.024752233177423477,
            -0.027318516746163368,
            0.002979826647788286,
            -0.019027387723326683,
            0.004843911621719599,
            0.010384527035057545,
            0.015525364316999912,
            0.0119858393445611,
            0.016173794865608215,
            -0.014588273130357265,
            -0.017371766269207,
            -0.012156910263001919,
            -0.012732493691146374,
            0.005422578193247318,
            0.00287904916331172,
            0.00029935516067780554
        ],
        [
            0.024886785075068474,
            0.020187092944979668,
            -0.031632859259843826,
            0.03307507932186127,
            0.0019047607202082872,
            -0.023074986413121223,
            0.03436879813671112,
            -0.015630356967449188,
            -0.007100533694028854,
            -0.013513205572962761,
            -0.013172127306461334,
            -0.00814399030059576,
            0.003731697564944625,
            -0.0003503928310237825,
            0.024205034598708153,
            0.03154982253909111,
            0.03941551595926285,
            -0.018121760338544846,
            0.006527311634272337,
            -0.009818247519433498,
            -0.010389674454927444,
            0.00036825163988396525,
            -0.01382725965231657,
            -0.0339980311691761,
            -0.00010908261174336076,
            -0.021732468158006668,
            -0.011993839405477047,
            -0.010860471054911613,
            -0.009926696307957172,
            0.013764724135398865,
            -0.0012723658001050353,
            -0.000714248395524919,
            -0.02664036676287651,
            -0.019397983327507973,
            -0.026069141924381256,
            -0.000854221114423126,
            -0.02992938458919525,
            0.015039569698274136,
            0.024228263646364212,
            -0.03133247047662735,
            -0.027370339259505272,
            -0.023333923891186714,
            -0.026454780250787735,
            -0.0032560057006776333,
            0.046886127442121506,
            0.014883399941027164,
            -0.010106462985277176,
            -0.002447534119710326,
            0.0016175702912732959,
            -0.05222456529736519,
            -0.022868594154715538,
            -0.009300406090915203,
            -0.01390423160046339,
            0.016777807846665382,
            -0.014367058873176575,
            -0.011186202988028526,
            -0.02353104017674923,
            -0.022982994094491005,
            0.03172783553600311,
            -0.024607565253973007,
            0.029554715380072594,
            -0.017584344372153282,
            -0.02575116977095604,
            -0.011041637510061264,
            0.010810981504619122,
            0.034625351428985596,
            -0.013032771646976471,
            -0.021371277049183846,
            -0.01772685907781124,
            0.0366138331592083,
            -0.013138655573129654,
            0.005134951323270798,
            0.011247693561017513,
            0.02583027444779873,
            -0.004440442658960819,
            -0.014256016351282597,
            0.02436862140893936,
            0.021507322788238525,
            -0.0051763951778411865,
            -0.06328742951154709,
            -0.020328158512711525,
            0.028395576402544975,
            -0.01940365508198738,
            -0.028592970222234726,
            -0.02681310847401619,
            -0.0014701950130984187,
            -0.028875527903437614,
            0.01863621547818184,
            0.019655603915452957,
            0.02234271727502346,
            -0.021309077739715576,
            -0.010751908645033836,
            -0.012359759770333767,
            0.008976996876299381,
            0.01814800128340721,
            -0.013877276331186295,
            0.005030070431530476,
            0.003001009114086628,
            -0.061803292483091354,
            0.014306103810667992,
            -0.01804921217262745,
            0.018257876858115196,
            0.005655020009726286,
            0.027321387082338333,
            0.011694890446960926,
            -0.0023369649425148964,
            0.011004091240465641,
            -0.008279918693006039,
            0.0033668633550405502,
            0.013236772269010544,
            0.029388856142759323,
            0.011150199919939041,
            -0.01173242088407278,
            -0.018225355073809624,
            -0.02552008628845215,
            -0.00947966706007719,
            -0.016802789643406868,
            -0.023219211027026176,
            -0.006692987866699696,
            0.034477416425943375,
            0.005603207740932703,
            0.017416877672076225,
            0.047438085079193115,
            -0.0028244375716894865,
            0.006900652777403593,
            -0.0035564154386520386,
            -0.03968686982989311,
            0.0007188942981883883,
            -0.020135173574090004,
            -0.004634461365640163,
            -0.035442519932985306,
            -0.03533817455172539,
            -0.0003393344231881201,
            0.02031133510172367,
            0.004233691841363907,
            -0.032538000494241714,
            -0.02790236286818981,
            -0.022661717608571053,
            0.017297541722655296,
            -0.006792714353650808,
            -0.014909940771758556,
            0.03598424047231674,
            0.0033541240263730288,
            0.0043728346936404705,
            -0.0022950703278183937,
            0.02485569380223751,
            0.0019765528850257397,
            0.013292757794260979,
            -0.018349608406424522,
            -0.013017455115914345,
            0.002720549702644348,
            -0.035083796828985214,
            0.025836635380983353,
            -0.04797198995947838,
            -0.021895643323659897,
            0.017786474898457527,
            0.027693597599864006,
            0.00027236048481427133,
            -0.018536610528826714,
            0.01806708239018917,
            -0.021987782791256905,
            -0.0364004410803318,
            -0.009871907532215118,
            0.00305271428078413,
            0.018576906993985176,
            0.024720923975110054,
            -0.0026071458123624325,
            0.006196598056703806,
            0.02826041169464588,
            0.022429633885622025,
            -0.013561908155679703,
            -0.023358162492513657,
            -0.03832336887717247,
            -0.03185606002807617,
            0.013851691037416458,
            0.0004438516916707158,
            0.01124311052262783,
            -0.015843428671360016,
            0.007552471477538347,
            -0.011252611875534058,
            -0.02012290619313717,
            0.02411794476211071,
            -0.020973460748791695,
            0.005765183363109827,
            -0.019327428191900253,
            0.030589565634727478,
            0.016302749514579773,
            -0.016666783019900322,
            0.005833419039845467,
            0.01022227294743061,
            -0.019623050466179848,
            -0.007391680497676134,
            0.041582029312849045,
            -0.018433427438139915,
            0.008457168005406857,
            -0.0037797631230205297,
            -0.008566330187022686,
            0.01180893275886774,
            0.023079851642251015,
            0.016345102339982986,
            0.005951884668320417,
            -0.007840786129236221,
            -0.015327545814216137,
            -0.02592981606721878,
            0.019643476232886314,
            -0.007601490709930658,
            -0.009616853669285774,
            0.0027823522686958313,
            0.016372429206967354,
            0.0010610406752675772,
            -0.025946935638785362,
            0.02184436097741127,
            0.015591978095471859,
            -0.013628526590764523,
            -0.00053015211597085,
            -0.014008542522788048,
            0.02062232233583927,
            0.027137424796819687,
            -0.0033019129186868668,
            0.015385834500193596,
            0.0039029978215694427,
            0.01642524264752865,
            -0.025882789865136147,
            0.016743019223213196,
            0.006473273038864136,
            0.03213103488087654,
            -0.027221763506531715,
            0.024288291111588478,
            -0.01451884862035513,
            0.016655897721648216,
            0.03170640021562576,
            0.008813614957034588,
            -0.011454629711806774,
            0.014148401096463203,
            0.04110470041632652,
            0.01958324760198593,
            0.029049208387732506,
            0.006126106716692448,
            0.03065704181790352,
            0.019814128056168556,
            -0.039128270000219345,
            -0.026794517412781715,
            -0.02237689681351185,
            -0.016292110085487366,
            -0.036333173513412476,
            0.011771555058658123,
            0.009191825985908508,
            6.899832806084305e-05,
            -0.023721637204289436,
            -0.02269200049340725,
            -0.0034163878299295902,
            0.01877666637301445,
            -0.01009127777069807,
            -0.002617304213345051,
            0.025254471227526665,
            -0.0016041808994486928,
            -0.018330935388803482,
            -0.038467735052108765,
            0.022005634382367134,
            0.008287695236504078,
            -0.03966381400823593,
            0.011126140132546425,
            -0.01807362027466297,
            0.028431270271539688,
            0.009874239563941956,
            -0.008894200436770916,
            0.01770932413637638,
            0.02489973045885563,
            -0.029403313994407654,
            -0.013527222909033298,
            0.013649274595081806,
            0.020796198397874832,
            -0.03456561639904976,
            -0.02475162222981453,
            0.025563081726431847,
            0.018156399950385094,
            0.005061484407633543,
            0.010653873905539513,
            0.011250253766775131,
            -0.0037482159677892923,
            0.01744329184293747,
            0.009844181127846241,
            -0.031616292893886566,
            -0.03967290371656418,
            -0.018242724239826202,
            -0.0071906778030097485,
            0.022626258432865143,
            0.005640626884996891,
            0.03107467107474804,
            -0.015225538983941078,
            0.024151407182216644,
            -0.0301862470805645,
            0.015913624316453934,
            -0.01836477220058441,
            0.025943849235773087,
            0.04113714024424553,
            0.032543305307626724,
            0.03158873692154884,
            -0.012540711089968681,
            0.025180067867040634,
            -0.014939514920115471,
            0.021088358014822006,
            -0.0099756745621562,
            0.008617483079433441,
            0.0007066805846989155,
            0.02262762561440468,
            0.0018711703596636653,
            -0.0005403583054430783,
            -0.003553305519744754,
            0.00628303037956357,
            -0.02832675538957119,
            0.02568540908396244,
            0.01622387208044529,
            -0.013873723335564137,
            0.006940786261111498,
            0.024652279913425446,
            0.02991706319153309,
            -0.014406850561499596,
            -0.007842539809644222,
            -0.016932988539338112,
            0.027853218838572502,
            -0.04979632422327995,
            -0.0586061105132103,
            -0.004549894481897354,
            0.0007360828458331525,
            0.0043161725625395775,
            -0.008691912516951561,
            0.006917007267475128,
            -0.010182006284594536,
            0.024119433015584946,
            -0.006713073700666428,
            -0.025984512642025948,
            0.0031523092184215784,
            -0.01766069605946541,
            0.004017387051135302,
            0.0023371390998363495,
            -0.02264237031340599,
            -0.02861209399998188,
            0.021696196869015694,
            0.00012385881564114243,
            -0.006721789948642254,
            -0.0140775041654706,
            -0.023949218913912773,
            -0.0012900347355753183,
            0.0035715859849005938,
            0.017171088606119156,
            0.022790292277932167,
            -0.006344159599393606,
            -0.026276128366589546,
            0.02597701922059059,
            0.044312380254268646,
            -0.004985424689948559,
            0.03383907675743103,
            -0.025731060653924942,
            -0.00606670742854476,
            0.04194927588105202,
            -0.041730739176273346,
            -0.011191210709512234,
            0.025070279836654663,
            0.02726803533732891,
            0.024097280576825142,
            0.02978280372917652,
            0.02914310060441494,
            -0.011517087928950787,
            -0.012234563939273357,
            -0.009707476943731308,
            -0.03260745108127594,
            -0.0303892120718956,
            0.007807163521647453,
            0.0012365038273856044,
            -0.029118455946445465,
            0.019460633397102356,
            0.03773896396160126,
            0.030102692544460297,
            -0.023179497569799423,
            0.022889848798513412,
            0.03349471837282181,
            0.01878456585109234,
            -0.008044680580496788,
            0.0031380127184093,
            -0.0013438630849123001,
            0.02453460730612278,
            0.02235294133424759,
            0.010422714985907078,
            0.016102107241749763,
            0.015611594542860985,
            0.005750834010541439,
            0.0023928252048790455,
            -0.029869215562939644,
            -0.04503730684518814,
            -0.005367936100810766,
            -0.023544887080788612,
            0.007535136304795742,
            0.01742391102015972,
            0.02647840976715088,
            0.018056105822324753,
            0.02266596630215645,
            -0.02600310742855072,
            -0.016132887452840805,
            0.026323825120925903,
            0.012527307495474815,
            0.029779380187392235,
            0.037991639226675034,
            0.007244964130222797,
            0.019389718770980835,
            0.03439970687031746,
            0.02483317255973816,
            0.003762409556657076,
            -0.012296443805098534,
            0.027272669598460197,
            -0.007047420833259821,
            0.009166723117232323,
            -0.026056304574012756,
            -0.03058396279811859,
            -0.02121039293706417,
            -0.024861954152584076,
            -0.015337858349084854,
            -0.008105214685201645,
            -0.008155451156198978,
            -0.023725302889943123,
            -0.003992705140262842,
            -0.024226145818829536,
            0.016534509137272835,
            -0.010028891265392303,
            -0.008588171564042568,
            0.016931338235735893,
            -0.008282848633825779,
            -0.03677504137158394,
            1.2603697996382834e-06,
            -0.005994454026222229,
            0.016532162204384804,
            -0.03559277579188347,
            -0.026350826025009155,
            -0.007823795080184937,
            0.011578950099647045,
            -0.009068780578672886,
            -0.005673494655638933,
            0.04330703616142273,
            -0.00783343706279993,
            0.012029560282826424,
            -0.020953839644789696,
            0.009481466375291348,
            -0.025516528636217117,
            -0.018970636650919914,
            -0.028486071154475212,
            -0.011850550770759583,
            0.016766207292675972,
            0.04528860002756119,
            -0.018042217940092087,
            -0.03374285250902176,
            -0.01074619684368372,
            0.013512213714420795,
            -0.00898559670895338,
            0.0002355623582843691,
            0.009869339875876904,
            0.019235730171203613,
            -0.019250662997364998,
            0.00019889856048393995,
            -0.01634644903242588,
            -0.03342200070619583,
            -0.01549245323985815,
            0.025134610012173653,
            0.03611649572849274,
            0.033385392278432846,
            0.0333385244011879,
            -0.0021810103207826614,
            0.02290882170200348,
            -0.033091578632593155,
            0.014783434569835663,
            -0.004821190610527992,
            -0.009238558821380138,
            0.031773943454027176,
            -0.022813117131590843,
            -0.035699717700481415,
            -0.0032440717332065105,
            0.0012935044942423701,
            0.0356978103518486,
            0.013880978338420391,
            0.028730515390634537,
            -0.027569720521569252,
            0.03118954412639141,
            -0.017519809305667877,
            0.06672748178243637,
            0.027902234345674515,
            0.023233434185385704,
            -0.029800089076161385,
            -0.016307668760418892,
            -0.015115239657461643,
            0.10838797688484192,
            0.03469075262546539,
            -0.0017975630471482873,
            0.013543807901442051,
            -0.023050472140312195,
            0.02288389764726162,
            -0.0001105308037949726,
            -0.017958078533411026,
            0.019366325810551643,
            0.017109986394643784,
            0.004777524620294571,
            0.0006170631386339664,
            -0.01925213262438774,
            0.025508666411042213,
            0.00086866260971874,
            0.025207921862602234,
            -0.001194818876683712,
            -0.014776138588786125,
            -0.011523768305778503,
            0.0008001695387065411,
            0.019698424264788628,
            -0.005369299557060003,
            -0.022730467841029167,
            0.017217935994267464,
            -0.0056188078597188,
            0.008495736867189407,
            -0.011557729914784431,
            0.006812816020101309,
            0.028747210279107094,
            0.010487501509487629,
            -0.003270490560680628,
            0.0040777940303087234,
            0.0036875184159725904,
            -0.01933084800839424,
            -0.019488461315631866,
            -0.03332922235131264,
            -0.015092623420059681,
            0.04711930453777313,
            -0.048290740698575974,
            -0.011907024309039116,
            0.017950212582945824,
            0.01951906830072403,
            0.0348161943256855,
            0.024032756686210632,
            -0.000400572462240234,
            0.00508489552885294,
            0.004769621416926384,
            0.010528362356126308,
            -0.028036007657647133,
            0.014385773800313473,
            -0.03524932637810707,
            -0.0191477220505476,
            -0.02949131280183792,
            0.03062731772661209,
            0.01022851001471281,
            -0.06240151822566986,
            -0.026369288563728333,
            0.05980927497148514,
            -0.025242015719413757,
            0.0018040708964690566,
            0.008796768262982368,
            0.021038180217146873,
            0.029240436851978302,
            0.03249349072575569,
            0.015884922817349434,
            0.0053693875670433044,
            -0.02039751596748829,
            0.01990034058690071,
            0.019027862697839737,
            0.004922160878777504,
            -0.00700744241476059,
            -0.02170073613524437,
            -0.0051227412186563015,
            0.02013903111219406,
            0.007943054661154747,
            -0.039262015372514725,
            -0.022079387679696083,
            0.03605717420578003,
            -0.029312310740351677,
            0.03254587575793266,
            -0.006917996797710657,
            0.0028731327038258314,
            0.03450977802276611,
            -0.011467431671917439,
            -0.0019655840005725622,
            -0.012871287763118744,
            0.016568604856729507,
            -0.011030158959329128,
            0.009555402211844921,
            -0.022896697744727135,
            0.027768274769186974,
            -0.028686609119176865,
            0.018245790153741837,
            -0.007538166828453541,
            0.009041378274559975,
            -0.032197434455156326,
            0.023041730746626854,
            -0.023973286151885986,
            0.0373043492436409,
            -0.01624593324959278,
            0.023782702162861824,
            -0.007501495070755482,
            -0.004488274920731783,
            0.03706742823123932,
            0.004281382076442242,
            -0.007982863113284111,
            0.030726654455065727,
            0.006221173796802759,
            -0.026721065863966942,
            0.02444791980087757,
            0.03213629499077797,
            -0.001368493540212512,
            0.026134828105568886,
            -0.01090686023235321,
            -0.00795011781156063,
            0.03266602382063866,
            0.0069080195389688015,
            0.026404447853565216,
            -0.010026874020695686,
            -0.009203570894896984,
            -0.028185397386550903,
            0.013345041312277317,
            -0.018160458654165268,
            0.015072577632963657,
            0.028465362265706062,
            0.026174671947956085,
            0.003071880666539073,
            0.004638819955289364,
            -0.0051086535677313805,
            0.01262217853218317,
            0.004732043482363224,
            0.03229469805955887,
            0.0342487096786499,
            0.020737744867801666,
            -0.02625364065170288,
            -0.005336718633770943,
            0.0010810394305735826,
            -0.03605533763766289,
            -0.0027722481172531843,
            -0.008470176719129086,
            0.0039497665129601955,
            0.008993220515549183,
            0.0320168174803257,
            0.013239869847893715,
            0.006803795229643583,
            -0.032769642770290375,
            0.019735485315322876,
            0.019613781943917274,
            -0.019835500046610832,
            0.0181185994297266,
            0.01710052788257599,
            0.01566588133573532,
            -0.005984622985124588,
            0.017782440409064293,
            0.013829560950398445,
            0.0037953797727823257,
            -0.016171889379620552,
            0.018286826089024544,
            0.031706295907497406,
            -0.006414641160517931,
            0.013635290786623955,
            0.03389531001448631,
            -0.0005312343128025532,
            -0.023671679198741913,
            -0.022382887080311775,
            0.006116959732025862,
            0.011617375537753105,
            -0.0033025427255779505,
            -0.032364677637815475,
            -0.0016821199096739292,
            0.021152295172214508,
            0.01966012641787529,
            -0.015609871596097946,
            0.027461357414722443,
            0.016683069989085197,
            0.004582884255796671,
            -0.0283849835395813,
            -0.00707694236189127,
            0.028104374185204506,
            0.028303178027272224,
            0.007415987551212311,
            0.03441222012042999,
            -0.037925951182842255,
            0.020464541390538216,
            -0.03131068870425224,
            -0.027682606130838394,
            0.015342983417212963,
            0.02413553185760975,
            -0.006327485200017691,
            0.027539970353245735,
            0.018779871985316277,
            -0.013938536867499352,
            -0.045174289494752884,
            -0.02146856114268303,
            -0.010859893634915352,
            -0.007908609695732594,
            -0.045084867626428604,
            0.015510482713580132,
            0.010840509086847305,
            0.02811238542199135,
            0.0054067084565758705,
            -0.01559214387089014,
            -0.027043480426073074,
            0.01101526990532875,
            0.005823793355375528,
            -0.007228187285363674,
            0.02977237105369568,
            -0.03890262171626091,
            -0.009898283518850803,
            -0.026708126068115234,
            -0.02518591284751892,
            -0.02847599983215332,
            0.006485954392701387,
            -0.019703177735209465,
            -0.013027727603912354,
            0.027249757200479507,
            0.024909237399697304,
            0.006936938501894474,
            0.0186152420938015,
            -0.009125646203756332,
            -0.0077277133241295815,
            0.007029030006378889,
            -0.017712228000164032,
            -0.010154109448194504,
            0.028955664485692978,
            0.01966489478945732,
            0.024899033829569817,
            -0.002040255581960082,
            -0.012787440791726112,
            -0.002081562066450715,
            0.008047140203416348,
            0.02594105526804924,
            -0.0013623031554743648,
            -0.0018749876180663705,
            0.010906929150223732,
            -0.040217798203229904,
            -0.020668087527155876,
            -0.011939997784793377,
            0.0029527086298912764,
            0.018325434997677803,
            -0.020332494750618935,
            -0.010853970423340797,
            -0.01570979133248329,
            0.02257268689572811,
            -0.003916273824870586,
            -0.025594020262360573,
            -0.012194418348371983,
            2.780300928861834e-05,
            -0.028870251029729843,
            -0.011449710465967655,
            -0.002800218528136611,
            -0.0032328369561582804,
            0.01459212601184845,
            0.031104501336812973,
            -0.001352490740828216,
            -0.012541628442704678,
            -0.009987220168113708,
            -0.01831754297018051,
            0.02143603377044201,
            -0.035003382712602615,
            0.02720654010772705,
            0.017829373478889465,
            -0.013417934067547321,
            -0.007878817617893219,
            0.0013804673217236996,
            -0.02982068620622158,
            0.005064275115728378,
            0.02366424910724163,
            -0.01158639695495367,
            0.01687745191156864,
            -0.013188487850129604,
            -0.02541373111307621,
            -0.015082500874996185,
            -0.036752838641405106,
            -0.010123273357748985,
            -0.028787193819880486,
            -0.024926824495196342,
            0.032455530017614365,
            -0.024094097316265106,
            0.02303502894937992,
            -0.014034193009138107,
            0.004789718426764011,
            -0.0003424689348321408,
            -0.040402334183454514,
            0.032194834202528,
            0.011386093683540821,
            -0.015218615531921387,
            0.015363317914307117,
            -0.007608802989125252,
            -0.027247313410043716,
            -0.0012666014954447746,
            0.013395404443144798,
            0.021059049293398857,
            0.023642607033252716,
            -0.019029401242733,
            0.007644678000360727,
            -0.024603739380836487,
            0.04094051569700241,
            0.02887578308582306,
            0.013413739390671253,
            -0.004651205614209175,
            0.03468265011906624,
            0.03015977144241333,
            0.012445823289453983,
            -0.017066290602087975,
            0.03443412855267525,
            -0.023061256855726242,
            0.015323012135922909,
            -0.006607139483094215,
            -0.019108712673187256,
            0.02979435585439205,
            0.02877839468419552,
            -0.008141649886965752,
            -0.009977499954402447,
            -0.021829944103956223,
            -0.020777495577931404,
            0.03464947268366814,
            -0.01554506178945303,
            0.019316013902425766,
            0.0344984345138073,
            -0.01987900212407112,
            0.004313248675316572,
            -0.008380932733416557,
            -0.008687964640557766,
            -0.023123884573578835,
            -0.013222937472164631,
            0.011989415623247623,
            -0.015644026920199394,
            -0.00933991651982069,
            0.023626159876585007,
            0.01419849693775177,
            -0.005681298673152924,
            0.009285420179367065,
            -0.02101677469909191,
            0.014874180778861046,
            -0.0092001399025321,
            -0.031525105237960815,
            0.018089449033141136,
            0.005700334906578064,
            0.021808862686157227,
            0.0027829455211758614,
            0.028379030525684357,
            -0.0017551975324749947,
            -0.023906070739030838,
            -0.013126286678016186,
            0.017278557643294334,
            0.037474583834409714,
            0.03233834356069565,
            0.004002018831670284,
            -0.022162064909934998,
            -0.022140253335237503,
            0.00507349194958806,
            0.03121170401573181,
            0.012678858824074268,
            0.0048247575759887695,
            -0.004714767448604107,
            -0.017029646784067154,
            0.01006881520152092,
            0.01519021112471819,
            0.023764783516526222,
            0.0031386418268084526,
            0.03319878503680229,
            0.03268741816282272,
            0.011152006685733795,
            -0.03570215404033661,
            0.011008087545633316,
            0.02210376225411892,
            -0.003787100547924638,
            0.011448103934526443,
            -0.007419008295983076,
            0.027192816138267517,
            -0.016122085973620415,
            0.008243672549724579,
            -0.023934995755553246,
            0.018108338117599487,
            -0.011344993487000465,
            -0.0005417975480668247,
            -2.397194111836143e-05,
            0.020619528368115425,
            -0.006042156834155321,
            -0.0024933521635830402,
            0.026156341657042503,
            0.0025118866469711065,
            0.01615123450756073,
            0.05654982849955559,
            -0.013327540829777718,
            -0.028367038816213608,
            0.017126981168985367,
            -0.0233044121414423,
            -0.02297878824174404,
            0.006167495157569647,
            -0.004818925634026527,
            0.02960127592086792,
            -0.0020676730200648308,
            0.03238793462514877,
            -0.002782711060717702,
            -0.010102708823978901,
            0.01993728056550026,
            0.019707711413502693,
            -0.015559962019324303,
            -0.019424177706241608,
            0.02144697494804859,
            0.0004553213366307318,
            0.02004443295300007,
            -0.012906917370855808,
            0.000918896053917706,
            -0.016240468248724937,
            -0.01298065111041069,
            0.016477592289447784,
            0.025692496448755264,
            0.014023790135979652,
            7.500287028960884e-05,
            -0.0006257756613194942,
            -0.017774298787117004,
            -0.01979118399322033,
            0.017861098051071167,
            -0.00931384414434433,
            0.005616700742393732,
            0.008394233882427216,
            -0.003394616534933448,
            -0.01038595661520958,
            -0.022198691964149475,
            -0.07055950164794922,
            -0.0009041218436323106,
            0.008163854479789734,
            -0.012374048121273518,
            0.019204266369342804,
            -0.0032733636908233166,
            -0.03591180592775345,
            0.024415677413344383,
            0.028969191014766693,
            0.004299115855246782,
            -0.0022368079517036676,
            0.03303634747862816,
            -0.01779376156628132,
            0.005132035817950964,
            -0.045103803277015686,
            0.004865266848355532,
            0.028080809861421585,
            -0.03809613734483719,
            -0.004160599783062935,
            0.010413942858576775,
            0.007587553933262825,
            -0.029006725177168846,
            0.01365506649017334,
            -0.0018871740903705359,
            0.006043965928256512,
            0.02538304775953293,
            0.008326185867190361,
            -0.03310823068022728,
            -0.021998591721057892,
            0.022877061739563942,
            0.02250582166016102,
            0.0020572582725435495,
            -0.027578778564929962,
            0.02950451895594597,
            -0.0034418264403939247,
            -0.004405689891427755,
            0.005602468270808458,
            0.015345023944973946,
            -0.01562889665365219,
            0.004652428440749645,
            0.0008716715965420008,
            -0.02476583793759346,
            0.0067485845647752285,
            -0.000828652351628989,
            0.01228125300258398,
            -0.03129453584551811,
            -0.007438325323164463,
            -0.026803242042660713,
            -0.010384583845734596,
            0.04160362854599953,
            0.024490585550665855,
            -0.008211810141801834,
            -0.0024996811989694834,
            0.008973445743322372,
            0.024655571207404137,
            0.0018318915972486138,
            0.017553778365254402,
            -0.023989280685782433,
            0.008188076317310333,
            0.033297840505838394,
            0.006451526191085577,
            -0.022517630830407143,
            -0.03635784238576889,
            -0.00468189362436533,
            -0.017253447324037552,
            0.004970639478415251,
            0.023387230932712555,
            0.02950264886021614,
            -0.015163633041083813,
            -0.010976389050483704,
            -0.03265475481748581,
            -0.006309300195425749,
            -0.015190763399004936,
            -0.003913160413503647,
            -0.018959665670990944,
            0.026339134201407433,
            -0.04167300835251808,
            0.022620731964707375,
            0.022077731788158417,
            0.010184421204030514,
            -0.00664491904899478,
            0.01716390997171402,
            -0.012212133966386318,
            0.0008858832297846675,
            -0.027619492262601852,
            0.0013522313674911857,
            0.013019573874771595,
            -0.03328491002321243,
            0.015998484566807747,
            0.020910192281007767,
            0.018597321584820747,
            0.05042741820216179,
            -0.00021996430587023497,
            0.02862820029258728,
            -0.011688063852488995,
            0.018539661541581154,
            -0.005236564204096794,
            0.0032362656202167273,
            0.037161245942115784,
            0.022951917722821236,
            0.029484091326594353,
            0.03350832685828209,
            -0.011619224213063717,
            0.03760550543665886,
            -0.046544063836336136,
            0.018579592928290367,
            -0.010230645537376404,
            -0.015094361267983913,
            -0.008480447344481945,
            0.009847233071923256,
            -0.030480733141303062,
            0.005950077902525663,
            0.008196940645575523,
            -0.02347741834819317
        ],
        [
            0.016710707917809486,
            0.019352789968252182,
            0.009064951911568642,
            0.023467516526579857,
            0.029671555384993553,
            0.0010461597703397274,
            -0.011017858050763607,
            -0.029924413189291954,
            0.016708774492144585,
            -0.017296310514211655,
            -0.008070447482168674,
            -0.018996138125658035,
            0.03978843614459038,
            0.016005905345082283,
            -0.022351836785674095,
            0.014161979779601097,
            0.004239842761307955,
            -0.02652670443058014,
            -1.2671454896917567e-05,
            -0.011442369781434536,
            0.020863065496087074,
            0.0020741350017488003,
            0.013215414248406887,
            0.015348070301115513,
            0.01119595393538475,
            -0.022458679974079132,
            -0.027625270187854767,
            0.010999474674463272,
            -0.021732861176133156,
            0.008383097127079964,
            0.0227707177400589,
            0.013162626884877682,
            -0.012810924090445042,
            0.010421519167721272,
            0.013937358744442463,
            0.019901759922504425,
            -0.027888556942343712,
            -0.017344631254673004,
            0.018254604190587997,
            -0.005294234491884708,
            0.009278946556150913,
            0.029016831889748573,
            0.004423337988555431,
            -0.012757470831274986,
            0.014277644455432892,
            -0.003018277697265148,
            -0.0038799403700977564,
            0.03821371868252754,
            -0.0011368382256478071,
            0.004802213981747627,
            -0.02816273272037506,
            -0.03358983248472214,
            -0.016301052644848824,
            -0.04216814413666725,
            -0.022618137300014496,
            0.023482734337449074,
            0.018991531804203987,
            -0.007540126796811819,
            -0.027945855632424355,
            -0.025508929044008255,
            0.01678079552948475,
            -0.010474937967956066,
            0.019774634391069412,
            0.005725436843931675,
            0.0013362776953727007,
            -0.006217175163328648,
            -0.0069570764899253845,
            -0.010722864419221878,
            -0.007619301788508892,
            0.04483824595808983,
            0.021208861842751503,
            0.008872960694134235,
            0.02506764978170395,
            0.012187216430902481,
            -0.017895426601171494,
            0.006889151409268379,
            -0.0205988809466362,
            0.0070662545040249825,
            0.0023098664823919535,
            -0.024250216782093048,
            -0.0344376415014267,
            -0.03045811504125595,
            0.024569906294345856,
            -0.008660906925797462,
            0.00679965503513813,
            -0.03476591035723686,
            -0.03177940845489502,
            -0.03931807726621628,
            -0.007207128219306469,
            0.023913539946079254,
            -0.03816878795623779,
            0.03298216313123703,
            -0.011312287300825119,
            -0.018106864765286446,
            0.022926412522792816,
            -0.02620304375886917,
            -0.048953827470541,
            -0.03010745719075203,
            -0.06406556814908981,
            -0.009825541637837887,
            0.02514403685927391,
            -0.016480082646012306,
            -0.028530817478895187,
            -0.00333384913392365,
            0.005685898475348949,
            0.0034823233727365732,
            -0.004191794898360968,
            -0.0027526572812348604,
            0.017782894894480705,
            -0.00690938625484705,
            0.014219768345355988,
            0.006208313163369894,
            -0.012405570596456528,
            0.033527687191963196,
            0.07025942951440811,
            -0.018963176757097244,
            0.002527195494621992,
            0.00782131776213646,
            -0.044363878667354584,
            -0.005542079918086529,
            -0.0007797718863002956,
            -0.023207174614071846,
            0.022133974358439445,
            0.001230057212524116,
            -0.030535545200109482,
            -0.009240024723112583,
            -0.014987880364060402,
            0.030225422233343124,
            -0.023134086281061172,
            0.009339820593595505,
            0.024135570973157883,
            -0.014818836934864521,
            -0.04064042493700981,
            -0.036663856357336044,
            0.015929272398352623,
            -0.031194424256682396,
            0.007045603822916746,
            -0.02536073513329029,
            -0.012792348861694336,
            -0.008602002635598183,
            -0.026868397369980812,
            -0.014007608406245708,
            -0.02417876571416855,
            -0.022206824272871017,
            0.002554982900619507,
            -0.0019785405602306128,
            0.028100252151489258,
            -0.011188099160790443,
            -0.007119019515812397,
            0.02851828560233116,
            0.001546443672850728,
            0.00023693370167165995,
            -0.002436228096485138,
            -0.005325709469616413,
            -0.014823498204350471,
            -0.02206779271364212,
            0.024924099445343018,
            -0.0029682342428714037,
            0.00649699242785573,
            -0.0507274754345417,
            0.02748250588774681,
            -0.02574397437274456,
            0.0035366250667721033,
            0.01292971521615982,
            -0.014566659927368164,
            0.001509201480075717,
            -0.008536344394087791,
            0.027062436565756798,
            0.030376378446817398,
            0.018073642626404762,
            -0.016093803569674492,
            -0.03293713182210922,
            0.036118172109127045,
            -0.02810581587255001,
            0.0316217839717865,
            0.012992496602237225,
            -0.008422216400504112,
            -0.0011945192236453295,
            0.024977650493383408,
            0.008510450832545757,
            -0.021523449569940567,
            -0.01717814989387989,
            0.022625042125582695,
            0.0012275628978386521,
            -0.01271478459239006,
            -0.01722262054681778,
            0.007886559702455997,
            0.0028319142293184996,
            -0.0030577697325497866,
            -0.02172427996993065,
            -0.027480972930788994,
            -0.023861808702349663,
            0.06448574364185333,
            0.008191823959350586,
            -0.020365187898278236,
            0.02830134890973568,
            0.0221847016364336,
            0.02585034817457199,
            -0.0164396483451128,
            0.03315321356058121,
            -0.031452860683202744,
            -0.036497827619314194,
            0.013427003286778927,
            -0.00752464821562171,
            -0.021107612177729607,
            0.024700457230210304,
            0.002500229747965932,
            -0.008688917383551598,
            -0.01764596253633499,
            -0.0007880784687586129,
            0.030404232442378998,
            0.04890432953834534,
            -0.022633126005530357,
            0.01310481783002615,
            -0.019324615597724915,
            -0.009709875099360943,
            0.025804586708545685,
            0.021398460492491722,
            0.015331757254898548,
            0.023786278441548347,
            -0.03430943936109543,
            -0.03674010932445526,
            0.028065932914614677,
            0.012684253044426441,
            -0.012377654202282429,
            0.0066626546904444695,
            -0.018764091655611992,
            -0.030429404228925705,
            -0.02726653404533863,
            -0.027765508741140366,
            0.001472993055358529,
            0.01560088898986578,
            -0.0064022354781627655,
            -0.02734408713877201,
            -0.037584174424409866,
            0.018401777371764183,
            -0.007638934068381786,
            0.03314267471432686,
            -0.0297883078455925,
            -0.04022655636072159,
            0.02895568124949932,
            -0.003992651589214802,
            0.01690731942653656,
            0.017811166122555733,
            -0.022182008251547813,
            0.028670718893408775,
            -0.019516699016094208,
            0.0003455537371337414,
            0.022184694185853004,
            0.022763103246688843,
            -0.011687986552715302,
            -0.007931927219033241,
            -0.017063487321138382,
            -0.018427912145853043,
            -0.022316349670290947,
            -0.019100593402981758,
            0.026289386674761772,
            0.005396383814513683,
            0.011229331605136395,
            0.030867256224155426,
            0.01155510451644659,
            -0.01733783446252346,
            0.002032058546319604,
            -0.004193478729575872,
            -0.00287071755155921,
            -0.03131074085831642,
            0.011785889975726604,
            0.006908881012350321,
            0.021832451224327087,
            0.01730661280453205,
            0.009498937986791134,
            -0.02565125934779644,
            -0.019635966047644615,
            0.017775068059563637,
            0.024511491879820824,
            -0.0072577279061079025,
            -0.028320852667093277,
            -0.019055649638175964,
            0.009843842126429081,
            -0.009824884124100208,
            0.022385304793715477,
            -0.01128143910318613,
            0.039224326610565186,
            0.003873786423355341,
            -0.010776345618069172,
            0.023908628150820732,
            -0.00283176195807755,
            -0.016170760616660118,
            -0.0027922906447201967,
            0.005636365152895451,
            -0.0214224886149168,
            -0.01815461367368698,
            0.02138739451766014,
            0.01728169247508049,
            0.013638866133987904,
            0.03231218829751015,
            0.012652453035116196,
            -0.018800489604473114,
            0.03234068676829338,
            -0.02752513624727726,
            0.015477622859179974,
            -0.011213469319045544,
            0.000593764940276742,
            0.021507859230041504,
            -4.780066319653997e-06,
            -0.022617705166339874,
            -0.022477611899375916,
            0.006921254564076662,
            0.035526059567928314,
            -0.03290284052491188,
            -0.02892492525279522,
            -0.026421112939715385,
            0.0028897866141051054,
            0.016216104850172997,
            -0.004072297364473343,
            -0.009579280391335487,
            0.008797775022685528,
            0.018436521291732788,
            -0.030489346012473106,
            -0.021287500858306885,
            0.036583684384822845,
            -0.02703733928501606,
            0.031031247228384018,
            0.03568871691823006,
            0.022667789831757545,
            -0.017724858596920967,
            0.009605837985873222,
            0.008283923380076885,
            0.026020999997854233,
            -0.012349049560725689,
            -0.03827239200472832,
            -0.03318043798208237,
            -0.010241599753499031,
            -0.032340288162231445,
            0.02577047236263752,
            0.008999362587928772,
            -0.012554558925330639,
            0.009416243061423302,
            -0.020243598148226738,
            -0.02587796561419964,
            -0.008060219697654247,
            -0.04574649780988693,
            0.025453971698880196,
            0.012336074374616146,
            -0.004302718210965395,
            -0.0202143806964159,
            0.006331345997750759,
            -0.0008892599144019186,
            -0.019748173654079437,
            0.012659483589231968,
            0.0054818144999444485,
            0.0038505636621266603,
            0.001000543823465705,
            0.004060734063386917,
            -0.017688250169157982,
            0.03149433061480522,
            0.029787452891469002,
            0.02918964996933937,
            0.02328336052596569,
            0.009325414896011353,
            0.028695544227957726,
            -0.006625113543123007,
            -0.028666462749242783,
            -0.02153226174414158,
            0.024635547772049904,
            -0.011995667591691017,
            0.015195133164525032,
            0.01629573665559292,
            0.03169550001621246,
            0.021935462951660156,
            -0.018559744581580162,
            0.0034691649489104748,
            -0.011551564559340477,
            -0.004747052676975727,
            0.002256512176245451,
            -0.010660809464752674,
            0.017433078959584236,
            0.03444478288292885,
            0.02732018567621708,
            0.027106961235404015,
            0.0007334832916967571,
            -0.00414464995265007,
            0.004731453489512205,
            0.014494171366095543,
            -0.02724515274167061,
            0.030298247933387756,
            0.010980257764458656,
            -0.0441470630466938,
            0.022638607770204544,
            0.01514305081218481,
            0.012227238155901432,
            0.00025189598090946674,
            0.032557692378759384,
            0.03757472336292267,
            -0.04687074199318886,
            -0.0149848572909832,
            -0.025891413912177086,
            0.025696508586406708,
            0.017443260177969933,
            -0.034694816917181015,
            0.003661893308162689,
            0.01834561489522457,
            0.010402008891105652,
            -0.029927391558885574,
            0.02642892301082611,
            0.023663751780986786,
            0.01690950058400631,
            -0.014611580409109592,
            0.01870342716574669,
            -0.032830096781253815,
            -0.01629970408976078,
            0.024170352146029472,
            -0.020217521116137505,
            0.0035790461115539074,
            -0.03734670951962471,
            0.023439032956957817,
            -0.009150438942015171,
            0.02613201178610325,
            0.014204944483935833,
            -0.02244163304567337,
            0.03606465086340904,
            0.01558619737625122,
            -0.04705753177404404,
            0.027286214753985405,
            -0.02738138847053051,
            0.024609358981251717,
            -0.014805956743657589,
            -0.027043038979172707,
            0.02067944034934044,
            0.00954632367938757,
            -0.032577693462371826,
            0.02586205303668976,
            -0.030630365014076233,
            0.02616034261882305,
            -0.021518049761652946,
            0.007417725864797831,
            0.008528443053364754,
            0.005602962337434292,
            0.021185243502259254,
            -0.0065095690079033375,
            -0.011986663565039635,
            -0.013915023766458035,
            0.004042906686663628,
            -0.025083402171730995,
            0.009177831932902336,
            -0.0006131271948106587,
            0.00977836549282074,
            0.052957192063331604,
            -0.006345412228256464,
            0.024868372827768326,
            0.026473911479115486,
            -0.02695154957473278,
            -0.02282070741057396,
            0.015802649781107903,
            -0.01995900273323059,
            -0.018005410209298134,
            -0.038889121264219284,
            -0.013707449659705162,
            -0.02201896719634533,
            0.0030241766944527626,
            -0.011486703529953957,
            0.0141453817486763,
            -0.030686676502227783,
            -0.031274545937776566,
            -0.026668116450309753,
            0.006056663114577532,
            0.0350043810904026,
            -0.06537497043609619,
            -0.011435156688094139,
            -0.014787152409553528,
            -0.045941200107336044,
            0.002363021718338132,
            0.005445633549243212,
            0.018639622256159782,
            -0.017799947410821915,
            -0.003360023722052574,
            0.005848656874150038,
            0.003820999525487423,
            -0.008279994130134583,
            0.025644715875387192,
            -0.00790366530418396,
            -0.014368904754519463,
            0.02439923956990242,
            0.01420639269053936,
            -0.030203865841031075,
            -0.014229475520551205,
            0.01435442641377449,
            0.03042471408843994,
            -0.0016732896910980344,
            0.009813662618398666,
            0.029105525463819504,
            -0.01914672553539276,
            -0.0034653961192816496,
            -0.025806063786149025,
            -0.03289923071861267,
            0.027214415371418,
            -0.007995102554559708,
            -0.01899627223610878,
            -0.025779908522963524,
            -0.010608621872961521,
            -0.02069101110100746,
            0.02363463118672371,
            -0.009052423760294914,
            0.042350273579359055,
            0.026456674560904503,
            -0.013308128342032433,
            -0.02359519526362419,
            0.001210540533065796,
            0.00938950851559639,
            -0.026089925318956375,
            -0.020512806251645088,
            -0.004244781099259853,
            0.033403340727090836,
            -0.010256743989884853,
            0.02702498808503151,
            -0.0031196551863104105,
            -0.01412764098495245,
            0.01654021255671978,
            -0.008768243715167046,
            -0.00457011116668582,
            -0.011830978095531464,
            0.01021863054484129,
            0.0004874169244430959,
            0.02330656349658966,
            0.04878437891602516,
            0.01471640169620514,
            0.044795922935009,
            -0.01905617117881775,
            0.036940909922122955,
            -0.012647606432437897,
            -0.005860509350895882,
            -0.01038362830877304,
            0.03438551351428032,
            0.022489629685878754,
            -0.02669362537562847,
            -0.018091265112161636,
            0.027272989973425865,
            0.0011995095992460847,
            0.032481107860803604,
            -0.006458364427089691,
            0.010622609406709671,
            -0.01971614360809326,
            0.03515254706144333,
            0.0015930531080812216,
            -0.019981594756245613,
            -0.019812945276498795,
            -0.001426093396730721,
            0.00711815757676959,
            0.021489905193448067,
            0.019992899149656296,
            -0.018840422853827477,
            -0.011425654403865337,
            -0.01762530393898487,
            0.030201507732272148,
            0.009374896995723248,
            -0.013570314273238182,
            -0.004083233419805765,
            -0.00338869821280241,
            0.011896960437297821,
            0.002413660753518343,
            -0.0040599931962788105,
            -0.023953501135110855,
            -0.018880045041441917,
            0.0006139791221357882,
            0.0017412363085895777,
            -0.01125050988048315,
            0.013014230877161026,
            -0.012901400215923786,
            -0.02063804864883423,
            -0.0004443066718522459,
            0.01785418391227722,
            0.01945248804986477,
            0.012752611190080643,
            -0.024128776043653488,
            0.010477392002940178,
            0.0003928885271307081,
            0.002026832429692149,
            -0.01538785919547081,
            -0.014850405976176262,
            0.0035241672303527594,
            0.019631804898381233,
            -0.023400427773594856,
            -0.013885042630136013,
            0.026690270751714706,
            0.005830878857523203,
            -0.01874157227575779,
            -0.01084353867918253,
            0.023288926109671593,
            0.019473528489470482,
            -0.009683872573077679,
            -0.011738896369934082,
            -0.021448535844683647,
            -0.0013369462685659528,
            -0.027378268539905548,
            -0.009705448523163795,
            -0.0039064171724021435,
            -0.006273136008530855,
            -0.00987625028938055,
            0.020062189549207687,
            0.0010788609506562352,
            -0.012599565088748932,
            -0.03238263726234436,
            -0.00874609500169754,
            0.03245490789413452,
            0.00940393004566431,
            0.011778480373322964,
            0.004782818723469973,
            0.013029094785451889,
            -0.008041477762162685,
            -0.004549503792077303,
            0.026404092088341713,
            0.01959379017353058,
            -0.03844545781612396,
            0.009210441261529922,
            -0.022905850782990456,
            0.03582574799656868,
            0.004797071684151888,
            0.0039955247193574905,
            0.0037514292635023594,
            -0.019806450232863426,
            0.05981583148241043,
            0.02662678435444832,
            0.00021379441022872925,
            0.015792615711688995,
            -0.03794116526842117,
            -0.003661937080323696,
            -0.023334583267569542,
            -0.006688170600682497,
            -0.02124476246535778,
            -0.016693780198693275,
            0.024877451360225677,
            0.028833996504545212,
            0.01572641171514988,
            -0.012164135463535786,
            0.019552571699023247,
            0.010222597979009151,
            0.01627318002283573,
            0.004932362120598555,
            0.02862115576863289,
            0.027859460562467575,
            -0.0014643993927165866,
            0.0197517778724432,
            -0.037495650351047516,
            -0.036240752786397934,
            -0.013542956672608852,
            0.009641162119805813,
            -0.017799140885472298,
            0.022143356502056122,
            -0.02120559848845005,
            -0.013125824742019176,
            -0.03359052166342735,
            -0.006423583719879389,
            0.00397862633690238,
            0.025187361985445023,
            -0.005021796096116304,
            -0.024283716455101967,
            -0.023660197854042053,
            -0.017404140904545784,
            0.027096714824438095,
            0.022147124633193016,
            -0.010889168828725815,
            0.0004797618312295526,
            -0.01320207305252552,
            -0.027342889457941055,
            0.021124133840203285,
            -0.005272501613944769,
            -0.02625354751944542,
            0.016435205936431885,
            -0.024340778589248657,
            -0.003999311476945877,
            0.00018748680304270238,
            0.02065381221473217,
            -0.028319101780653,
            -0.01432090811431408,
            0.0319703184068203,
            -0.009857073426246643,
            0.007139569614082575,
            -0.00633042398840189,
            0.008744634687900543,
            0.002962322672829032,
            -0.024311689659953117,
            -0.015307106077671051,
            0.016260387375950813,
            -0.025720959529280663,
            -0.006797092966735363,
            0.03203196078538895,
            0.005441414657980204,
            -0.01957673579454422,
            0.010436981916427612,
            -0.012338167056441307,
            -0.006260312162339687,
            0.02390279248356819,
            -0.000393194000935182,
            -0.01434612087905407,
            -0.014718768186867237,
            0.029566576704382896,
            -0.014702480286359787,
            -0.022172942757606506,
            0.03267135098576546,
            0.03511883690953255,
            0.0040148901753127575,
            0.044740475714206696,
            0.012202735058963299,
            -0.0018832681234925985,
            0.007548903580754995,
            -0.025926243513822556,
            0.01596290059387684,
            -0.008546735160052776,
            -0.018939344212412834,
            -0.03496367484331131,
            -0.0009835187811404467,
            0.002296476624906063,
            0.005228228867053986,
            -0.011265872046351433,
            -0.017648253589868546,
            -0.010406955145299435,
            -0.008464389480650425,
            0.039385292679071426,
            -0.015508653596043587,
            -0.011793629266321659,
            -0.025732893496751785,
            -0.004358229227364063,
            0.014591783285140991,
            -0.007008661050349474,
            0.020139312371611595,
            0.025439759716391563,
            0.007798479404300451,
            0.001878563896752894,
            0.007860579527914524,
            -0.018510811030864716,
            -0.040098439902067184,
            0.01847684755921364,
            -0.005115970503538847,
            -0.011657548137009144,
            -0.02059168368577957,
            0.002597124082967639,
            0.010068398900330067,
            0.0055720070376992226,
            0.018171114847064018,
            0.02111920341849327,
            0.012296893633902073,
            0.021267229691147804,
            -0.009233113378286362,
            0.026403889060020447,
            0.01552878599613905,
            -0.0042215134017169476,
            0.050712112337350845,
            -0.003858625888824463,
            0.020020121708512306,
            0.035328567028045654,
            -0.013478457927703857,
            -0.0151011161506176,
            -0.021833103150129318,
            -0.004029995761811733,
            0.025644270703196526,
            -0.02897503226995468,
            -0.024549636989831924,
            0.03375272825360298,
            -0.02389245666563511,
            -0.03261232003569603,
            0.014860255643725395,
            -0.050719767808914185,
            0.010702485218644142,
            -0.02182803489267826,
            0.0014844500692561269,
            0.024560540914535522,
            0.008680048398673534,
            0.024597875773906708,
            -0.027956204488873482,
            -0.008680671453475952,
            0.026012644171714783,
            -0.005491530988365412,
            0.0138353630900383,
            -0.018863912671804428,
            -0.014324070885777473,
            0.02618660032749176,
            0.02584845945239067,
            0.01691274531185627,
            0.016838714480400085,
            0.020686840638518333,
            0.0011792435543611646,
            -0.02480342425405979,
            0.038588400930166245,
            0.023245232179760933,
            -0.021454980596899986,
            0.004182645585387945,
            -0.029026979580521584,
            0.01960182376205921,
            -0.009428927674889565,
            0.025712067261338234,
            -0.030460050329566002,
            0.02238207310438156,
            0.036824822425842285,
            0.03777949512004852,
            0.04068153351545334,
            -0.02030113898217678,
            0.024747569113969803,
            -0.0010777346324175596,
            -0.014511722140014172,
            0.022959304973483086,
            -0.012632654048502445,
            -0.017668524757027626,
            0.0160573348402977,
            -0.015472345985472202,
            0.008918209932744503,
            0.01838078163564205,
            0.02966620773077011,
            0.020898155868053436,
            0.019572695717215538,
            -0.02216939628124237,
            0.039744965732097626,
            0.031182119622826576,
            -0.008229241706430912,
            0.015665344893932343,
            0.010877032764256,
            1.0721172429839498e-06,
            -0.005736242514103651,
            0.031057197600603104,
            -0.0028604334220290184,
            -0.01736351288855076,
            0.019799118861556053,
            -0.017603076994419098,
            0.03453165292739868,
            0.016405189409852028,
            -0.003871745662763715,
            -0.01788453385233879,
            0.007676765788346529,
            -0.011806109920144081,
            0.0044891866855323315,
            0.01953166536986828,
            0.005338849499821663,
            -0.020254986360669136,
            -0.009041877463459969,
            0.02067965269088745,
            -0.025281080976128578,
            0.027606181800365448,
            -0.014346969313919544,
            0.006604135036468506,
            0.024110523983836174,
            -0.009717862121760845,
            0.007534516975283623,
            0.02312200516462326,
            0.03315242752432823,
            0.002194239292293787,
            0.013903019018471241,
            -0.021634843200445175,
            -0.03015049546957016,
            0.018315861001610756,
            0.02429300919175148,
            -0.002813144354149699,
            0.0037807796616107225,
            -0.0070917545817792416,
            0.0060256775468587875,
            -0.024073706939816475,
            0.02799910493195057,
            -0.012651489116251469,
            0.02418578788638115,
            0.01534738764166832,
            0.0060824803076684475,
            0.038257990032434464,
            0.008878961205482483,
            -0.029384039342403412,
            0.035631172358989716,
            0.011064190417528152,
            0.009197930805385113,
            0.00978817231953144,
            -0.02403987944126129,
            0.007425121497362852,
            -0.008600011467933655,
            0.019512105733156204,
            0.016895491629838943,
            0.022879010066390038,
            -0.0014818201307207346,
            0.03931815177202225,
            -0.014795384369790554,
            -0.006911650765687227,
            -0.011263985186815262,
            0.005392018239945173,
            0.025525907054543495,
            0.02224990539252758,
            -0.018015671521425247,
            -0.018925020471215248,
            -0.010648266412317753,
            0.0001599024108145386,
            -0.013789454475045204,
            0.01875152252614498,
            0.013768048956990242,
            0.03934336453676224,
            -0.015564928762614727,
            -0.01981889270246029,
            -0.07276253402233124,
            -0.03359483927488327,
            -0.02107296884059906,
            -0.002714654430747032,
            0.024055728688836098,
            0.019429299980401993,
            0.015378558076918125,
            0.020931243896484375,
            0.017017623409628868,
            0.045797981321811676,
            -0.023755107074975967,
            0.024732675403356552,
            -0.027545714750885963,
            0.0074327015317976475,
            0.0013616670621559024,
            -0.025579672306776047,
            0.0003105541691184044,
            -0.009974840097129345,
            -0.023002468049526215,
            -0.00923900119960308,
            -0.004099286161363125,
            -0.03474428877234459,
            -0.018296552821993828,
            -0.0060660094022750854,
            -0.05813019350171089,
            0.02250128798186779,
            0.008016900159418583,
            -0.0011371144792065024,
            0.0036946218460798264,
            -0.006935270503163338,
            -0.0024281051009893417,
            0.031067367643117905,
            -0.0071584549732506275,
            -0.03536717966198921,
            -0.020964324474334717,
            0.02265350893139839,
            0.0332426056265831,
            0.00880031380802393,
            0.005452807992696762,
            0.009675339795649052,
            -0.002480129012838006,
            -0.015691056847572327,
            0.012230167165398598,
            -0.05139247328042984,
            -0.005785104352980852,
            -0.028167910873889923,
            1.8675320461625233e-05,
            -0.007889789529144764,
            0.009922266937792301,
            0.010660956613719463,
            0.02891138195991516,
            0.012769454158842564,
            -0.01775231584906578,
            -0.016973968595266342,
            -0.014884691685438156,
            -0.020682442933321,
            0.022880472242832184,
            -0.013563117012381554,
            -0.018284182995557785,
            -0.02398405224084854,
            -0.0026458785869181156,
            0.018842628225684166,
            -0.010461052879691124,
            0.0004479593480937183,
            -0.005748646799474955,
            -0.024147648364305496,
            -0.001702809357084334,
            -0.024577589705586433,
            0.03507270663976669,
            -0.0009276074706576765,
            0.004415101371705532,
            0.0015283172251656651,
            0.0119389733299613,
            -0.01948900707066059,
            -0.002368994290009141,
            -0.012560509145259857,
            0.0173940472304821,
            -0.005704614799469709,
            -0.022430384531617165,
            0.01142844557762146,
            -0.02126627415418625,
            -0.006653844844549894,
            -0.017752258107066154,
            -0.02421429008245468,
            0.024143340066075325,
            0.005773517303168774,
            -0.004618123639374971,
            0.024379555135965347,
            0.00209820712916553,
            0.02017170935869217,
            0.02056718058884144,
            0.013374660164117813,
            -0.027515927329659462,
            -0.019968565553426743,
            0.00034079942270182073,
            0.02766735479235649,
            -0.02220993861556053,
            -0.009899377822875977,
            0.01676916889846325,
            -0.0018816371448338032,
            -0.023458875715732574,
            -0.022469105198979378,
            0.004502334166318178,
            0.02967265248298645,
            0.019467558711767197,
            0.012750604189932346,
            -0.0019239792600274086,
            -0.026646561920642853,
            -0.055970530956983566,
            0.013790958561003208,
            0.03417329117655754,
            0.024389291182160378,
            -0.027493778616189957,
            -0.009958936832845211,
            -0.003551033791154623,
            0.029705695807933807,
            0.02981092780828476,
            -0.06131213530898094,
            -0.034555647522211075,
            0.014979894272983074,
            -0.03013577312231064,
            0.015297278761863708,
            0.023982973769307137,
            -0.025324027985334396,
            0.005307564977556467,
            0.0018739179940894246,
            -0.03547951206564903,
            0.0049605593085289,
            0.03585708141326904,
            0.007749014534056187,
            -0.004117620177567005,
            0.02382783032953739,
            0.03082461468875408,
            0.018922880291938782
        ],
        [
            0.007631844375282526,
            -0.011447343043982983,
            0.012040937319397926,
            -0.017889417707920074,
            0.025521762669086456,
            -0.009633236564695835,
            -0.02739458903670311,
            -0.04302609711885452,
            0.004886317066848278,
            0.02404545620083809,
            0.04067114368081093,
            0.007604452781379223,
            0.021317509934306145,
            0.030827118083834648,
            0.012033870443701744,
            0.03646767511963844,
            0.0030168492812663317,
            -0.004870832897722721,
            -0.009140417911112309,
            -0.02656071074306965,
            -0.03926989063620567,
            0.03566289693117142,
            -0.018759747967123985,
            0.0005714701837860048,
            -0.017298977822065353,
            0.00016862896154634655,
            0.02487383596599102,
            0.008988790214061737,
            -0.005049510858952999,
            0.0015058716526255012,
            -0.014356055296957493,
            -0.01760151982307434,
            0.018184959888458252,
            0.009152907878160477,
            -0.0018955921987071633,
            0.010564684867858887,
            -0.02829705737531185,
            0.016091929748654366,
            0.01945320889353752,
            0.007741284091025591,
            -0.0120228361338377,
            0.003076319582760334,
            0.02627573348581791,
            -0.037128206342458725,
            -0.011857820674777031,
            0.028318990021944046,
            0.01839364692568779,
            -0.05153479054570198,
            -0.03260708972811699,
            -0.0016366624040529132,
            -0.03828301280736923,
            -0.005969911348074675,
            0.03557954356074333,
            -0.02232320047914982,
            -0.03681231662631035,
            0.0021982197649776936,
            0.02115008234977722,
            -0.029140086844563484,
            0.03234739601612091,
            -0.0004820090834982693,
            -0.0005865731509402394,
            0.0018479115096852183,
            -0.008106313645839691,
            -0.012354802340269089,
            -0.02213708497583866,
            0.014925247989594936,
            0.010767619125545025,
            -0.03386276587843895,
            0.016459913924336433,
            -0.008626280352473259,
            -0.014951170422136784,
            0.014448490925133228,
            -0.015345685184001923,
            0.0005986242322251201,
            0.02409302070736885,
            -0.019125521183013916,
            0.028034290298819542,
            -0.031415000557899475,
            0.0056417398154735565,
            0.02279549650847912,
            0.03177129477262497,
            -0.02408622018992901,
            -0.013973282650113106,
            0.022545307874679565,
            0.014072011224925518,
            0.025937844067811966,
            -0.015216782689094543,
            0.02054925262928009,
            0.011094107292592525,
            -0.021853458136320114,
            -0.0011713493149727583,
            0.027170199900865555,
            -0.017222773283720016,
            0.018251223489642143,
            0.0030679611954838037,
            -0.02667955495417118,
            0.025844577699899673,
            -0.015674196183681488,
            0.059195905923843384,
            0.033716581761837006,
            0.004769832827150822,
            -0.04087366536259651,
            0.024373184889554977,
            -0.015140807256102562,
            0.026038413867354393,
            0.011038276366889477,
            0.010311861522495747,
            -0.004554130136966705,
            0.0332053117454052,
            0.03429349139332771,
            0.0209368783980608,
            0.03483947739005089,
            -0.0006957983714528382,
            -0.020507682114839554,
            0.032271143049001694,
            0.006158391013741493,
            -0.0195979755371809,
            -0.03086935728788376,
            -0.016255632042884827,
            -0.02331620827317238,
            0.005714130587875843,
            0.012812070548534393,
            -0.007985558360815048,
            0.01548780407756567,
            0.005493227858096361,
            0.011744758114218712,
            -0.04985307529568672,
            0.0063383313827216625,
            0.0005798654165118933,
            -0.03407752141356468,
            0.019171640276908875,
            0.00106359226629138,
            0.003432597266510129,
            -0.009305031970143318,
            -0.018304437398910522,
            -0.004896881058812141,
            -0.029628846794366837,
            -0.013265402987599373,
            0.015193505212664604,
            0.030252711847424507,
            -0.019848868250846863,
            0.030477972701191902,
            0.005628726910799742,
            -0.027120929211378098,
            0.016263136640191078,
            -0.013812489807605743,
            -0.011982396245002747,
            0.020791860297322273,
            -0.0014002667739987373,
            -0.021661462262272835,
            0.02899162843823433,
            -0.10282811522483826,
            -0.002033533761277795,
            0.004404169507324696,
            -0.04396282508969307,
            -0.019843757152557373,
            -0.0066298311576247215,
            -0.001620787545107305,
            -0.0026756697334349155,
            0.010255471803247929,
            -0.0124792056158185,
            -0.018515290692448616,
            0.035507168620824814,
            0.025880033150315285,
            -0.0006394078955054283,
            -0.02895774319767952,
            -0.01167549379169941,
            -0.01739688031375408,
            0.027446506544947624,
            -0.0036548941861838102,
            -0.023989619687199593,
            0.014249722473323345,
            -0.004203126300126314,
            -0.02843431755900383,
            0.009729540906846523,
            -0.0036260290071368217,
            -0.002280147047713399,
            -0.021736012771725655,
            -0.0005060834810137749,
            0.0011850402224808931,
            -0.0185786671936512,
            -0.008579788729548454,
            0.028798848390579224,
            -0.009236450307071209,
            0.012115884572267532,
            -0.011151404120028019,
            -0.01753583736717701,
            -0.009271225892007351,
            0.04100740700960159,
            0.026832127943634987,
            -0.015858784317970276,
            0.014257707633078098,
            -0.02263951115310192,
            -0.03223792836070061,
            -0.008323702961206436,
            0.033980000764131546,
            -0.02282588556408882,
            -0.01818629913032055,
            -0.01713271625339985,
            0.01007015723735094,
            0.026225069537758827,
            0.012509413063526154,
            0.003368433564901352,
            -0.006892193108797073,
            0.02999064512550831,
            0.0273011215031147,
            -0.03901755437254906,
            -0.01246937457472086,
            0.023548293858766556,
            -0.021325726062059402,
            -0.0221073180437088,
            0.0006689091678708792,
            -0.028163302689790726,
            -0.014286793768405914,
            -0.006959839258342981,
            0.02077505923807621,
            -0.00566035695374012,
            -0.030459439381957054,
            0.02353593334555626,
            -0.013389264233410358,
            -0.024719400331377983,
            0.03621024638414383,
            -0.03116169199347496,
            -0.008271563798189163,
            -0.02945832721889019,
            -0.044208183884620667,
            -0.004146883729845285,
            -0.010883980430662632,
            0.009291439317166805,
            -0.016929157078266144,
            -0.004833385348320007,
            -0.043247804045677185,
            -0.006838114466518164,
            -0.003406947012990713,
            -0.03485338017344475,
            -0.03130251541733742,
            -0.0039052485954016447,
            0.023407133296132088,
            -0.0049642338417470455,
            0.025009652599692345,
            0.02398400381207466,
            -0.005803542211651802,
            -0.015119172632694244,
            0.027219131588935852,
            0.005430338438600302,
            0.0008603536989539862,
            -0.01617487147450447,
            -0.004558676388114691,
            -0.01452064048498869,
            -0.014773799106478691,
            -0.01610025390982628,
            0.03634292259812355,
            -0.012035963125526905,
            0.0071967472322285175,
            -0.004193900153040886,
            0.025052201002836227,
            0.007764468900859356,
            0.014510533772408962,
            -0.011713307350873947,
            0.0021699974313378334,
            -0.04676979407668114,
            -0.027159683406352997,
            0.038393743336200714,
            0.03334076330065727,
            0.03843230754137039,
            0.0011957919923588634,
            -0.009096480906009674,
            0.032117851078510284,
            -0.025660349056124687,
            -0.012658028863370419,
            -0.026580851525068283,
            0.02701924927532673,
            0.00608231034129858,
            0.01935543492436409,
            -0.004010828677564859,
            -0.0060349637642502785,
            0.00450262101367116,
            0.004041483160108328,
            0.009631066583096981,
            -0.028695322573184967,
            -0.009128198027610779,
            -0.010842039249837399,
            0.020854447036981583,
            -0.006240705959498882,
            -0.02793208137154579,
            0.010009285993874073,
            -5.164638059795834e-05,
            0.015739766880869865,
            0.03123367764055729,
            0.023606518283486366,
            0.029210958629846573,
            -0.0038843636866658926,
            -0.021969424560666084,
            -0.023605158552527428,
            0.00544246518984437,
            0.011982045136392117,
            -0.01651999168097973,
            0.006569337099790573,
            0.004785565193742514,
            -0.014720253646373749,
            0.01962176337838173,
            -0.004435814451426268,
            0.007787322625517845,
            -0.025160163640975952,
            -0.02034391090273857,
            -0.02473057061433792,
            0.01428417768329382,
            -0.020187094807624817,
            0.017143046483397484,
            -0.007764887530356646,
            -0.03966754674911499,
            -0.03300026059150696,
            0.0268031544983387,
            -0.024318497627973557,
            0.003551247762516141,
            -0.002055640332400799,
            -0.01655241847038269,
            -0.016076749190688133,
            -0.04563749581575394,
            -0.038889143615961075,
            0.013237761333584785,
            0.006043858360499144,
            -0.023751594126224518,
            -0.043803080916404724,
            0.012403609231114388,
            0.01996302604675293,
            -0.028718942776322365,
            -0.0009688106365501881,
            0.02208837680518627,
            0.02638266235589981,
            0.022577296942472458,
            0.012006305158138275,
            -0.008047071285545826,
            0.01932566985487938,
            -0.012324072420597076,
            -0.0014664219925180078,
            -0.002728436840698123,
            0.00044896607869304717,
            0.03236452117562294,
            0.017896262928843498,
            0.012768146581947803,
            -0.006029461044818163,
            0.0014999554259702563,
            0.006753816269338131,
            -0.011469574645161629,
            -0.01041859295219183,
            0.007568530272692442,
            0.010196324437856674,
            -0.012053485959768295,
            0.005249657668173313,
            0.0070766881108284,
            0.02237395942211151,
            -0.01819797232747078,
            -0.023369301110506058,
            -0.0008046291768550873,
            0.027430713176727295,
            -0.031142625957727432,
            -0.0023294189013540745,
            0.01891929842531681,
            0.007415020372718573,
            0.03137807920575142,
            0.01678743213415146,
            0.03159674257040024,
            -0.0228413138538599,
            -0.017097128555178642,
            -0.01430460438132286,
            -0.0032767350785434246,
            0.022355876863002777,
            -0.010851765051484108,
            0.005465929862111807,
            0.0010992179159075022,
            0.0167829766869545,
            0.0046142702922225,
            0.00774920592084527,
            0.021630367264151573,
            -0.01715005189180374,
            0.036942120641469955,
            -0.018150387331843376,
            -0.005198380909860134,
            0.01648128218948841,
            0.030916711315512657,
            0.009105917997658253,
            0.01803993247449398,
            0.010343535803258419,
            -0.0074856821447610855,
            -0.010768535546958447,
            0.01180424913764,
            -0.0072675240226089954,
            -0.014027909375727177,
            0.005500652361661196,
            -0.017739787697792053,
            0.009944462217390537,
            0.004610608331859112,
            -0.021670831367373466,
            0.022806379944086075,
            0.024017710238695145,
            0.01354716531932354,
            0.022036893293261528,
            -0.006736655253916979,
            -0.0319850891828537,
            0.03520327806472778,
            0.020538052543997765,
            -0.012199397198855877,
            -0.0534781739115715,
            -0.040089644491672516,
            0.015573803335428238,
            0.010690612718462944,
            -0.006848996505141258,
            -0.026070695370435715,
            0.011186497285962105,
            -0.03312687575817108,
            -0.0317896269261837,
            -0.04029426351189613,
            0.0306092482060194,
            -0.0010190345346927643,
            -0.01884470134973526,
            0.02508302964270115,
            0.03986024484038353,
            0.01264758687466383,
            0.03064168617129326,
            0.0129619799554348,
            -0.008098587393760681,
            -0.0036204534117132425,
            0.025859735906124115,
            -0.0033835081849247217,
            -0.012204187922179699,
            -0.007529453840106726,
            -0.04060084745287895,
            0.025071779265999794,
            0.030399693176150322,
            0.023573171347379684,
            0.003245317842811346,
            -0.00825461558997631,
            0.004510425962507725,
            0.0035054481122642756,
            -0.0001950151054188609,
            0.011725562624633312,
            -0.032665401697158813,
            0.0077638705261051655,
            -0.03572437912225723,
            0.003875692142173648,
            0.00852352473884821,
            0.01614779606461525,
            -0.04126913845539093,
            0.0009712035534903407,
            -0.02206062525510788,
            -0.029383152723312378,
            -0.04876931384205818,
            0.002716918010264635,
            -0.018003862351179123,
            -0.014538516290485859,
            -0.0035835327580571175,
            -0.042957913130521774,
            0.024215303361415863,
            -0.045591577887535095,
            0.02585454471409321,
            -0.009892493486404419,
            0.020742857828736305,
            0.034102000296115875,
            0.015996325761079788,
            -0.036685142666101456,
            0.009995900094509125,
            -0.01949034072458744,
            -0.02111651748418808,
            0.030728425830602646,
            0.004558246117085218,
            0.01631581038236618,
            0.028908036649227142,
            0.0042130909860134125,
            -0.013417071662843227,
            -0.0011064575519412756,
            0.02457018755376339,
            0.00029563732095994055,
            -0.04230303689837456,
            0.015551909804344177,
            -0.019173528999090195,
            -0.025626905262470245,
            0.01595834270119667,
            0.010581701062619686,
            0.026481924578547478,
            -0.009515319019556046,
            -0.027739237993955612,
            0.0363604910671711,
            0.01585865579545498,
            0.02135000005364418,
            -0.006318128667771816,
            4.160712342127226e-05,
            -0.01018462609499693,
            0.029697947204113007,
            -0.009896657429635525,
            0.007940283045172691,
            0.016863709315657616,
            0.01751227118074894,
            -0.0033523349557071924,
            -0.00500430166721344,
            0.025090958923101425,
            -0.025353914126753807,
            0.005432581063359976,
            0.00794393289834261,
            0.03068883903324604,
            0.016160983592271805,
            -0.07075024396181107,
            0.004810257349163294,
            -0.025728924199938774,
            0.028538942337036133,
            -0.028983240947127342,
            0.014927269890904427,
            -0.032501187175512314,
            -0.033731527626514435,
            -0.006596001796424389,
            0.009002638049423695,
            -0.046024300158023834,
            -0.014402581378817558,
            0.02246684394776821,
            -0.006724603474140167,
            0.03399711102247238,
            -0.033399321138858795,
            0.0016857304144650698,
            -0.0020359051413834095,
            -0.003498140024021268,
            0.03240268677473068,
            0.03568318858742714,
            0.011825934052467346,
            -0.027697831392288208,
            0.02653498202562332,
            0.013016973622143269,
            0.024533459916710854,
            -0.022289156913757324,
            0.012736925855278969,
            -0.028524985536932945,
            0.026573097333312035,
            0.014127697795629501,
            0.033206380903720856,
            -0.02193335071206093,
            0.005281224846839905,
            -0.02603823132812977,
            0.02336268499493599,
            -0.019517268985509872,
            -0.03260894492268562,
            -0.014114635065197945,
            -0.021669860929250717,
            0.0166768841445446,
            -0.026230299845337868,
            0.01765887625515461,
            0.0003635763714555651,
            0.0033213552087545395,
            -0.02233116328716278,
            -0.006505579687654972,
            -0.002004294190555811,
            0.037996694445610046,
            0.00581621378660202,
            -0.000637910736259073,
            -0.01014345046132803,
            -0.006018597632646561,
            0.015920693054795265,
            0.009605089202523232,
            -0.03379160165786743,
            0.02482384257018566,
            0.0029583980794996023,
            0.0318068191409111,
            0.00462770601734519,
            0.01100710779428482,
            -0.01699041947722435,
            0.0020243171602487564,
            -0.004556539002805948,
            0.012978066690266132,
            -0.04574982449412346,
            0.02018204890191555,
            -0.011014181189239025,
            -0.02094891294836998,
            -0.012783141806721687,
            -0.02164800465106964,
            -0.030014216899871826,
            0.020187804475426674,
            0.00683505367487669,
            0.01716846041381359,
            -0.026700420305132866,
            -0.010108239948749542,
            -0.007406614255160093,
            -0.006279082503169775,
            0.0041650934144854546,
            -0.015854941681027412,
            0.014698206447064877,
            -0.033316802233457565,
            -0.024754513055086136,
            0.01063455268740654,
            0.015188204124569893,
            0.0008986435714177787,
            0.003531573573127389,
            -0.023390723392367363,
            0.03385729342699051,
            -0.0015411145286634564,
            -0.026277024298906326,
            0.009873853996396065,
            0.026110541075468063,
            -0.005831307731568813,
            -0.0026756366714835167,
            -0.013606947846710682,
            -0.006196090951561928,
            -0.018333911895751953,
            0.010660331696271896,
            0.011564894579350948,
            0.029994377866387367,
            0.02347363904118538,
            -0.021049905568361282,
            0.00701924180611968,
            -0.025810787454247475,
            -0.029729638248682022,
            0.008426716551184654,
            0.02068435400724411,
            -0.0021632115822285414,
            -0.020440196618437767,
            0.00740470178425312,
            -0.01221921481192112,
            -0.008977845311164856,
            -0.02050374448299408,
            0.009039780125021935,
            -0.016541432589292526,
            0.004918365739285946,
            0.0012526834616437554,
            -0.052284929901361465,
            -0.025633178651332855,
            0.03015759587287903,
            0.008495187386870384,
            0.032348763197660446,
            0.03350253775715828,
            0.028040597215294838,
            -0.023466428741812706,
            0.018476979807019234,
            0.01669624075293541,
            0.01467299833893776,
            0.009208177216351032,
            0.02133779227733612,
            0.011860713362693787,
            -0.0021247996482998133,
            0.01552855595946312,
            0.025952357798814774,
            -0.035596977919340134,
            0.02029249630868435,
            0.027863716706633568,
            -0.018495991826057434,
            -0.02623945102095604,
            0.008349994197487831,
            0.014089207164943218,
            -0.015251673758029938,
            0.028349347412586212,
            0.005883055739104748,
            -0.02623705007135868,
            -0.0013502475339919329,
            -0.011972757056355476,
            0.018757933750748634,
            -0.019602522253990173,
            0.001736931037157774,
            -0.027572007849812508,
            -0.03182730823755264,
            -0.008973378688097,
            0.010440390557050705,
            -0.027542486786842346,
            -0.010101105086505413,
            -0.0013639380922541022,
            0.004917725455015898,
            0.01204390823841095,
            0.015022363513708115,
            0.012232334353029728,
            0.012903640046715736,
            0.02335035614669323,
            -0.016016611829400063,
            0.031974781304597855,
            -0.012704617343842983,
            0.014564457349479198,
            0.026546385139226913,
            0.004131811670958996,
            -0.019804969429969788,
            0.014681617729365826,
            0.009063906967639923,
            0.042332641780376434,
            0.017713751643896103,
            0.0327192097902298,
            0.009738483466207981,
            -0.030435653403401375,
            0.03370382636785507,
            0.019773604348301888,
            -0.005390000529587269,
            0.013621166348457336,
            0.021945741027593613,
            0.016844891011714935,
            -0.005763173568993807,
            0.015566523186862469,
            0.027302389964461327,
            0.014369284734129906,
            -7.91372949606739e-05,
            -0.010157114826142788,
            0.015585488639771938,
            -0.004817621782422066,
            -0.016139989718794823,
            -0.046321406960487366,
            -0.036142412573099136,
            -0.016770150512456894,
            0.04155502840876579,
            0.01806691475212574,
            -0.02004268579185009,
            -0.0252638328820467,
            0.030058110132813454,
            0.0022418794687837362,
            0.003079677000641823,
            -0.023994579911231995,
            -0.030694853514432907,
            0.0011658607982099056,
            0.0033945890609174967,
            0.011095238849520683,
            0.02747180499136448,
            -0.0038794907741248608,
            0.001260902383364737,
            -0.012172729708254337,
            -0.010796669870615005,
            -0.03037087246775627,
            0.010556490160524845,
            0.03132830560207367,
            -0.0006104654748924077,
            -0.019422877579927444,
            0.0071662478148937225,
            -0.00756614888086915,
            0.022745996713638306,
            -0.013345353305339813,
            0.013877826742827892,
            -0.00045645839418284595,
            -0.02887837216258049,
            0.010069037787616253,
            0.033987339586019516,
            0.03144669905304909,
            0.0276495311409235,
            0.039242859929800034,
            -0.0040044961497187614,
            0.008370048366487026,
            0.003368981182575226,
            0.0024889891501516104,
            0.01817578822374344,
            0.007263382896780968,
            0.024248629808425903,
            0.007072384934872389,
            -0.011727836914360523,
            0.02643631398677826,
            0.018026545643806458,
            -0.022410182282328606,
            -0.006731173023581505,
            0.014471134170889854,
            0.01768082194030285,
            -0.023173974826931953,
            -0.006247775629162788,
            -0.014656406827270985,
            0.017726050689816475,
            0.004300503991544247,
            0.012473220936954021,
            -0.027817174792289734,
            0.0019414231646806002,
            0.0005859441589564085,
            -0.025657163932919502,
            0.008307457901537418,
            0.0060744695365428925,
            -0.001959053799510002,
            -0.054758038371801376,
            0.042320046573877335,
            0.008988798595964909,
            0.029174599796533585,
            -0.020725511014461517,
            0.017812198027968407,
            0.039502181112766266,
            0.024701504036784172,
            -0.021988986060023308,
            -0.0026979108806699514,
            -0.0024574981071054935,
            -0.02746959589421749,
            -0.01185514498502016,
            0.03142509236931801,
            0.024231284856796265,
            0.017301060259342194,
            -0.008162224665284157,
            -0.05813121050596237,
            -0.022395124658942223,
            0.013236156664788723,
            0.03188726678490639,
            -0.00046828636550344527,
            -0.02121726982295513,
            -0.008414135314524174,
            -0.019337857142090797,
            0.022419311106204987,
            0.0021820298861712217,
            -0.006192994769662619,
            0.00744633050635457,
            -0.018150106072425842,
            0.011357128620147705,
            -0.025625279173254967,
            0.003120488952845335,
            -0.004455598536878824,
            -0.026859039440751076,
            -0.03869803994894028,
            -0.008682750165462494,
            -0.0013726998586207628,
            -0.0085054412484169,
            -0.0018211131682619452,
            0.011516300030052662,
            0.006044967100024223,
            -0.016746679320931435,
            0.044950708746910095,
            -8.677085133967921e-05,
            0.04999317601323128,
            0.019575057551264763,
            0.009614285081624985,
            -0.032901130616664886,
            0.0013075388269498944,
            0.023722033947706223,
            0.013674816116690636,
            -0.02263433113694191,
            0.011602412909269333,
            0.02911546640098095,
            0.021266750991344452,
            -0.02330293320119381,
            -0.024374695494771004,
            -0.020798133686184883,
            -0.021358702331781387,
            -0.042353395372629166,
            -0.0482369065284729,
            -0.0018954495899379253,
            0.007643867749720812,
            0.03313925862312317,
            -0.003201038809493184,
            0.001443776418454945,
            -0.007391029503196478,
            0.028611553832888603,
            -0.0010458765318617225,
            0.03474336490035057,
            -0.019862810149788857,
            -0.007443982642143965,
            -0.034390226006507874,
            -0.03395581990480423,
            -0.01882750168442726,
            0.018576372414827347,
            -0.017894823104143143,
            -0.025934096425771713,
            -0.004467072896659374,
            -0.004515815060585737,
            0.018991297110915184,
            0.016694793477654457,
            0.008715781383216381,
            0.01713760383427143,
            -0.011308259330689907,
            0.017902778461575508,
            0.0023403798695653677,
            0.032063230872154236,
            0.03309129923582077,
            0.011895216070115566,
            -0.019229361787438393,
            0.02194991521537304,
            -0.0009885338367894292,
            -0.0029531330801546574,
            0.0030161389149725437,
            0.03383215516805649,
            -0.005446016322821379,
            0.019099395722150803,
            0.00262068142183125,
            -0.04011143371462822,
            -0.014638237655162811,
            -0.016581667587161064,
            -0.04394720867276192,
            0.0048578763380646706,
            -0.01549952756613493,
            0.015219664201140404,
            0.0011671216925606132,
            0.0053006792441010475,
            -0.01075943186879158,
            0.04940173402428627,
            -0.021893590688705444,
            -0.005146357696503401,
            0.0038516297936439514,
            0.0158257894217968,
            -0.030634626746177673,
            -0.0015721737872809172,
            -0.0069827670231461525,
            -0.02956896461546421,
            -0.02194523625075817,
            0.021388432011008263,
            -0.026077641174197197,
            0.04007990285754204,
            0.031516771763563156,
            0.011240729130804539,
            0.0019074400188401341,
            0.009607945568859577,
            0.012388198636472225,
            -0.019976601004600525,
            0.03174560144543648,
            0.0012930483790114522,
            -0.025229454040527344,
            0.016712045297026634,
            0.0003144501824863255,
            0.05036203935742378,
            -0.03345312923192978,
            0.013219933025538921,
            0.015944816172122955,
            0.021326173096895218,
            -0.019171735271811485,
            -0.0092313839122653,
            0.006449783220887184,
            -0.027623184025287628,
            0.0006763554411008954,
            0.008340985514223576,
            0.054136406630277634,
            -0.01652717776596546,
            0.023320328444242477,
            0.038002222776412964,
            0.004659112077206373,
            -0.04164082929491997,
            0.0011527059832587838,
            -0.010946257039904594,
            -0.04896683618426323,
            -0.014662611298263073,
            0.005980265326797962,
            -0.02568928152322769,
            0.004981583915650845,
            0.0368763729929924,
            -0.0007863081409595907,
            0.013817684724926949,
            0.03266211226582527,
            0.0197511687874794,
            0.00027835130458697677,
            0.022031692788004875,
            -0.04170370474457741,
            -0.010855307802557945,
            0.006994258612394333,
            0.02512010559439659,
            -0.012001649476587772,
            -0.027890853583812714,
            0.027820883318781853,
            0.01196005754172802,
            -0.026959577575325966,
            0.010481205768883228,
            -0.008334311656653881,
            -0.00020728247181978077,
            0.004504572134464979,
            0.0076850964687764645,
            0.020441899076104164,
            -0.007929583080112934,
            0.010996107943356037,
            -0.04191137105226517,
            -0.03879903256893158,
            -0.029111122712492943,
            0.024495523422956467,
            -0.042847394943237305,
            0.0074864840134978294,
            -0.031759463250637054,
            0.011639723554253578,
            0.050004150718450546,
            -0.012490437366068363,
            -0.026494048535823822,
            0.004710040520876646,
            -0.023413248360157013,
            0.018527090549468994,
            -0.020312443375587463,
            -0.0042506433092057705,
            0.013330047950148582,
            -0.024780889973044395,
            -0.03395053371787071,
            -0.0024828542955219746,
            0.03798537328839302,
            0.007559749763458967,
            0.0033187007065862417,
            -0.013703115284442902,
            -0.047195542603731155,
            0.027923129498958588,
            0.020409489050507545,
            -0.0019349235808476806,
            0.01143984217196703,
            0.02570594660937786,
            0.006406395696103573,
            0.006417314987629652,
            -0.01831871084868908,
            -0.016858991235494614,
            0.01738911308348179,
            -0.0010519260540604591,
            -0.0005020741373300552,
            0.04090224206447601,
            0.00676369946449995,
            0.014961916022002697,
            0.01732557639479637,
            -0.026186659932136536,
            -0.00718815578147769,
            0.010863665491342545,
            -0.026557357981801033,
            -0.013136573135852814,
            0.03346603736281395,
            0.021647635847330093,
            -0.02981913648545742,
            -0.01584700308740139,
            0.027239203453063965,
            0.0020354429725557566,
            -0.019562195986509323,
            0.050367191433906555,
            -0.008471481502056122,
            0.02844882383942604,
            0.03965204954147339,
            -0.01931258849799633,
            -0.01272079348564148,
            -0.0006611578282900155,
            0.00958535261452198,
            -0.019998077303171158,
            -0.016529953107237816,
            0.024119049310684204,
            -0.01519687194377184,
            -0.007937612012028694,
            0.012798243202269077,
            -0.025868143886327744,
            -0.015025608241558075,
            -0.01746312901377678,
            0.009763898327946663,
            -0.016064578667283058,
            -0.019613903015851974,
            0.03069145977497101,
            0.012767931446433067,
            0.014751313254237175,
            -0.004325037822127342,
            -0.001375129446387291,
            -0.01989818550646305,
            0.02244430221617222,
            0.0442100465297699,
            0.0203546192497015
        ],
        [
            0.028118262067437172,
            0.022007161751389503,
            0.023404138162732124,
            0.0014252056134864688,
            -0.0019859522581100464,
            -0.0026203831657767296,
            -0.0002559048298280686,
            -0.018005089834332466,
            0.025935232639312744,
            0.028448712080717087,
            -0.011126487515866756,
            -0.02374608814716339,
            -0.028690386563539505,
            0.016956627368927002,
            0.062242671847343445,
            0.024580493569374084,
            0.010447566397488117,
            -0.0001256376999663189,
            -0.013209106400609016,
            0.0017893349286168814,
            0.013181520625948906,
            0.015869105234742165,
            0.006301543675363064,
            -0.009204991161823273,
            0.008540024980902672,
            -0.005992870312184095,
            0.017258871346712112,
            -0.01465870812535286,
            -0.028642501682043076,
            0.017296116799116135,
            -0.009181161411106586,
            -0.012057464569807053,
            -0.02155251055955887,
            0.012196872383356094,
            0.021516311913728714,
            0.036607686430215836,
            -0.01553526520729065,
            -0.009242799133062363,
            0.03528151661157608,
            0.0007077693589963019,
            -0.019814327359199524,
            -0.016544360667467117,
            0.028371959924697876,
            0.02683381177484989,
            -0.015756255015730858,
            0.026260221377015114,
            0.030122604221105576,
            0.026518523693084717,
            0.01765250414609909,
            0.0015202182112261653,
            -0.004835244733840227,
            -0.006781147327274084,
            0.030951714143157005,
            0.03605583682656288,
            0.018052732571959496,
            0.009055071510374546,
            0.011941203847527504,
            -0.008666480891406536,
            0.010332569479942322,
            -0.017456835135817528,
            0.01895645633339882,
            -0.004844686947762966,
            -0.0008810126455500722,
            0.01733512058854103,
            0.022322727367281914,
            0.015703726559877396,
            -0.0177227221429348,
            -0.012652437202632427,
            -0.029294714331626892,
            0.01807214878499508,
            -0.028685366734862328,
            -0.01172628253698349,
            -0.022989029064774513,
            -0.02476271241903305,
            0.007629635278135538,
            0.004094900097697973,
            0.014699837192893028,
            -0.031238658353686333,
            0.022389454767107964,
            0.0009361737174913287,
            0.008022199384868145,
            0.028959807008504868,
            -0.019012445583939552,
            -0.030674505978822708,
            0.023900408297777176,
            0.01786733977496624,
            -0.009402189403772354,
            -0.01942114718258381,
            -0.0035627465695142746,
            -0.012395450845360756,
            0.02133713848888874,
            0.009727842174470425,
            -0.02610204368829727,
            0.010663822293281555,
            -0.018593942746520042,
            -0.004476530011743307,
            0.015283082611858845,
            0.028724653646349907,
            0.01734485849738121,
            0.02158772386610508,
            -0.02827155962586403,
            -0.019478702917695045,
            0.029609860852360725,
            -0.007689354475587606,
            -0.0006798013928346336,
            -0.010002249851822853,
            8.032764162635431e-05,
            0.01600666530430317,
            0.01715887151658535,
            0.0001206203960464336,
            0.009955559857189655,
            0.0373382605612278,
            0.004204312339425087,
            0.015712080523371696,
            -0.04595202952623367,
            -0.01686648465692997,
            0.00028763164300471544,
            -0.025241144001483917,
            -0.016465354710817337,
            -0.02188246324658394,
            -0.0023764159996062517,
            -0.017581524327397346,
            0.018006129190325737,
            -0.014513893984258175,
            -0.005427835043519735,
            -0.012956581078469753,
            0.009793197736144066,
            -0.015801485627889633,
            0.028169238939881325,
            -0.014928244985640049,
            -0.024442728608846664,
            0.007557317614555359,
            0.005970967002213001,
            0.012257871218025684,
            -0.009553288109600544,
            0.017218956723809242,
            0.02449427917599678,
            -0.033823490142822266,
            0.019730931147933006,
            0.015451500192284584,
            0.0015178041066974401,
            -0.030626462772488594,
            -0.007749977055937052,
            -0.002211371436715126,
            0.028126033022999763,
            0.0164584219455719,
            0.008547485806047916,
            -0.0156550295650959,
            -0.019040394574403763,
            -0.025948377326130867,
            -0.02889229729771614,
            -0.030741680413484573,
            -0.022342100739479065,
            0.0168573297560215,
            0.026752851903438568,
            0.02823370322585106,
            -0.023999501019716263,
            0.008013428188860416,
            -0.005043285433202982,
            0.004815859254449606,
            0.004499735776335001,
            -0.026883689686655998,
            -0.017740368843078613,
            -0.03055000863969326,
            0.019387833774089813,
            -0.03188449144363403,
            -0.005535919684916735,
            -0.004794359672814608,
            0.003749776864424348,
            -0.00792989693582058,
            0.020684773102402687,
            -0.0005217372090555727,
            -0.01069281343370676,
            0.006139502394944429,
            0.012871797196567059,
            0.025704076513648033,
            0.008934389799833298,
            0.006929360330104828,
            -0.0033906742464751005,
            -0.033135659992694855,
            -0.034996747970581055,
            0.007094773463904858,
            0.03993714600801468,
            0.010261181741952896,
            0.002313306787982583,
            -0.021219387650489807,
            -0.01803302951157093,
            0.027603451162576675,
            -0.025425609201192856,
            -0.014427823014557362,
            0.005674005951732397,
            -0.01212825533002615,
            -0.01969071850180626,
            -0.02397971786558628,
            -9.028280328493565e-05,
            0.032599952071905136,
            0.010047161020338535,
            0.007071672007441521,
            0.01984383724629879,
            0.016444316133856773,
            0.02492837980389595,
            0.02416500635445118,
            0.010607318952679634,
            -0.010107538662850857,
            -0.020948143675923347,
            0.019456714391708374,
            0.02071274071931839,
            0.00438071321696043,
            0.01989688351750374,
            -0.033743374049663544,
            0.0022522478830069304,
            0.03467528522014618,
            -0.00919224414974451,
            0.03211161494255066,
            -0.016811387613415718,
            0.017378872260451317,
            0.03145310655236244,
            0.030085714533925056,
            0.01426350325345993,
            0.017315024510025978,
            -0.0033415392972528934,
            -0.018962731584906578,
            0.013704166747629642,
            0.002981622237712145,
            0.005212564021348953,
            -0.05060083419084549,
            -0.011353838257491589,
            -0.012750670313835144,
            0.03460827097296715,
            0.012370141223073006,
            -0.02968587726354599,
            0.02389298379421234,
            0.004421362653374672,
            0.03252106159925461,
            0.0093263303861022,
            -0.026184262707829475,
            -0.031903281807899475,
            -0.027027133852243423,
            -0.017772121354937553,
            0.013805211521685123,
            0.0008017512154765427,
            -0.02210039086639881,
            0.023974139243364334,
            0.023211365565657616,
            -0.0243995264172554,
            0.011562311090528965,
            0.0027862926945090294,
            0.012552048079669476,
            0.0029351436533033848,
            -0.018175270408391953,
            0.01768822781741619,
            0.02433357574045658,
            0.006510747130960226,
            -0.012710112147033215,
            -0.013505088165402412,
            -0.026034656912088394,
            -0.00017958036914933473,
            0.014318350702524185,
            -0.021941248327493668,
            -0.03649081289768219,
            0.03295113518834114,
            -0.010562317445874214,
            -0.019941002130508423,
            0.016157669946551323,
            -0.014910487458109856,
            0.01762210763990879,
            -0.016117257997393608,
            -0.018191564828157425,
            -0.006307994481176138,
            0.008636663667857647,
            -0.0029670754447579384,
            0.02281167171895504,
            0.02684055268764496,
            -0.00017636182019487023,
            -0.027700994163751602,
            -0.02383382059633732,
            -0.005716689862310886,
            0.021566206589341164,
            0.00027773715555667877,
            -0.014389805495738983,
            -0.0026525722350925207,
            0.008740919642150402,
            -0.02250286564230919,
            0.02146245166659355,
            -0.012036453932523727,
            0.016455352306365967,
            -0.025024961680173874,
            0.014251003041863441,
            -0.02832500822842121,
            0.009860394522547722,
            0.00303219654597342,
            0.011091923341155052,
            0.02616228722035885,
            -0.027627062052488327,
            0.01386038213968277,
            0.012580523267388344,
            -0.012963548302650452,
            -0.024498168379068375,
            -0.010142632760107517,
            -0.01927986554801464,
            0.0076864780858159065,
            -0.004790560342371464,
            -0.005364752374589443,
            0.018360560759902,
            0.022294986993074417,
            -0.0192433949559927,
            0.012746691703796387,
            -0.011429102160036564,
            0.0034856104757636786,
            0.045038435608148575,
            -0.01944923587143421,
            -0.01507478766143322,
            0.014190410263836384,
            0.0068352543748915195,
            0.02189830131828785,
            -0.006786765530705452,
            0.006063986103981733,
            -0.007242479361593723,
            0.028125673532485962,
            0.012953574769198895,
            0.006701340898871422,
            -0.008644758723676205,
            0.018589220941066742,
            0.022565288469195366,
            -0.025016695261001587,
            0.01572522707283497,
            -0.024586649611592293,
            -0.03147047385573387,
            -0.007294897921383381,
            -0.015854429453611374,
            -0.010698842816054821,
            -0.0172907542437315,
            0.00882466696202755,
            0.03243764117360115,
            0.02898259647190571,
            -0.005068810191005468,
            0.007337592542171478,
            0.008673618547618389,
            0.031070467084646225,
            -0.01398991048336029,
            -0.02583080343902111,
            -0.022751320153474808,
            -0.0007454371661879122,
            -0.006687721703201532,
            -0.033537399023771286,
            0.018046636134386063,
            0.007122302893549204,
            0.011329667642712593,
            0.0026799615006893873,
            0.009035610593855381,
            0.02965831197798252,
            0.029532847926020622,
            -0.018852535635232925,
            4.947113848174922e-05,
            0.002751612337306142,
            0.003950770013034344,
            -0.0017836155602708459,
            -0.03221150487661362,
            0.031161272898316383,
            -0.018750283867120743,
            -0.021203109994530678,
            -0.01602150686085224,
            0.001289933337830007,
            -0.006020221393555403,
            -0.041999053210020065,
            0.02010539546608925,
            -0.0023583481088280678,
            0.003037819406017661,
            -0.02001761458814144,
            0.0176448542624712,
            -0.002439140575006604,
            0.002353568095713854,
            -0.016653427854180336,
            0.02727024257183075,
            -0.016027145087718964,
            -0.021987978368997574,
            -0.004708835855126381,
            0.019561853259801865,
            0.031565986573696136,
            -0.012209875509142876,
            0.00872256513684988,
            -0.019037257879972458,
            0.010686617344617844,
            -0.015234504826366901,
            0.014755770564079285,
            0.0018809218890964985,
            -0.0056870607659220695,
            0.032634373754262924,
            0.018045229837298393,
            0.025659168139100075,
            0.02522345818579197,
            0.018383830785751343,
            -0.016130417585372925,
            -0.017226872965693474,
            -0.06197715178132057,
            -0.02724733017385006,
            0.018977006897330284,
            -0.004606202710419893,
            0.0022586106788367033,
            -0.0011526020243763924,
            -0.01352680567651987,
            0.011082676239311695,
            0.00975709781050682,
            0.017557237297296524,
            0.0012721025850623846,
            -0.025621969252824783,
            0.0005048112361691892,
            -0.007084191776812077,
            -0.005005217157304287,
            0.024400513619184494,
            -0.02719186246395111,
            -0.01223888248205185,
            -0.026464901864528656,
            -0.01898932084441185,
            0.029366962611675262,
            0.013027600012719631,
            0.019836625084280968,
            0.005129803903400898,
            0.03477203845977783,
            0.012991725467145443,
            0.040886398404836655,
            -0.028499500826001167,
            -0.017717981711030006,
            0.0023928771261125803,
            -0.02673601731657982,
            0.014113434590399265,
            -0.0011709921527653933,
            -0.03005988337099552,
            -0.009327779524028301,
            0.02167799510061741,
            0.02302076295018196,
            -0.018819261342287064,
            0.005372788291424513,
            -0.027898486703634262,
            0.01589214988052845,
            -0.002657033037394285,
            -0.032432835549116135,
            0.00796631257981062,
            -0.0020529599860310555,
            -0.020671462640166283,
            0.020260093733668327,
            -0.018788592889904976,
            0.016973713412880898,
            0.03472688049077988,
            -0.026917388662695885,
            -0.027757037431001663,
            -0.014477681368589401,
            -0.021629633381962776,
            -0.031036581844091415,
            0.030744457617402077,
            0.018168102949857712,
            0.011271584779024124,
            0.03640281781554222,
            0.01002497784793377,
            0.022139940410852432,
            -0.02342027984559536,
            -0.010678604245185852,
            -0.02000158280134201,
            -0.012948950752615929,
            0.011260117404162884,
            0.02115359716117382,
            0.021455762907862663,
            0.005069864448159933,
            0.0028810107614845037,
            0.025169987231492996,
            -0.018844587728381157,
            -0.0039256541058421135,
            -0.03133701533079147,
            0.016849644482135773,
            -0.002981605241075158,
            0.010558979585766792,
            0.013595092110335827,
            0.013975790701806545,
            -0.0070208776742219925,
            0.024698764085769653,
            0.007244869600981474,
            -0.001448045833967626,
            -0.01968476176261902,
            -0.017042160034179688,
            0.01678108237683773,
            0.010498504154384136,
            -0.003424923401325941,
            0.040927376598119736,
            0.024899234995245934,
            -0.025241613388061523,
            0.007677440531551838,
            -0.00729986559599638,
            0.01722806878387928,
            0.023181729018688202,
            -0.02035924419760704,
            0.022385623306035995,
            -0.02218938246369362,
            -0.01476749312132597,
            -0.02696533501148224,
            0.009370961226522923,
            -0.015299482271075249,
            0.014401378110051155,
            0.028920097276568413,
            -0.032046735286712646,
            -0.029981181025505066,
            -0.01307078916579485,
            -0.006238837726414204,
            -0.016302863135933876,
            0.02303212322294712,
            0.007610320579260588,
            0.027929004281759262,
            0.009889420121908188,
            -0.0009379114490002394,
            0.0197615884244442,
            0.0038332545664161444,
            0.01753760315477848,
            -0.024296576157212257,
            -0.005001494660973549,
            0.008524654433131218,
            0.030772864818572998,
            0.00023926519497763366,
            0.03156444802880287,
            -0.01096698921173811,
            -0.0002556882391218096,
            0.02538338676095009,
            0.00800993014127016,
            0.013474233448505402,
            0.004417211748659611,
            -0.01828056201338768,
            -0.01243878249078989,
            0.03695853054523468,
            -0.009852442890405655,
            0.024263305589556694,
            -0.009289098903536797,
            -0.02398681826889515,
            0.002482176525518298,
            -0.005553421564400196,
            -0.0222058966755867,
            -0.02384011261165142,
            0.010785729624330997,
            0.007381401490420103,
            0.03020835854113102,
            0.023003559559583664,
            -0.02580094337463379,
            0.012792613357305527,
            0.0013571419985964894,
            0.004508706741034985,
            0.0028177741914987564,
            -0.013237432576715946,
            0.027056269347667694,
            0.016866523772478104,
            0.013260056264698505,
            -0.014947525225579739,
            0.0004926431574858725,
            -0.023082446306943893,
            0.00890663918107748,
            -0.005939530208706856,
            0.0016231194604188204,
            -0.02526801824569702,
            -0.006201999261975288,
            -0.016765838488936424,
            0.03346601128578186,
            -0.012614147737622261,
            0.015943270176649094,
            0.02429923787713051,
            -0.0012676448095589876,
            -0.01988394744694233,
            -0.010319157503545284,
            0.025985389947891235,
            0.025046873837709427,
            0.013412884436547756,
            -0.020367242395877838,
            -0.03317902237176895,
            0.029828768223524094,
            -0.013318152166903019,
            -0.0005846283165737987,
            0.023095304146409035,
            0.02333502098917961,
            -0.023043403401970863,
            -0.010447381064295769,
            0.005991812329739332,
            -0.010250209830701351,
            0.009022642858326435,
            0.011609990149736404,
            -0.0048841084353625774,
            0.022675326094031334,
            0.009176494553685188,
            -0.01761123165488243,
            -0.023253802210092545,
            0.0037308689206838608,
            -0.023941896855831146,
            0.022554902359843254,
            0.01697554439306259,
            -0.013751578517258167,
            -0.007264682091772556,
            0.01647149957716465,
            0.020034458488225937,
            0.00026470908778719604,
            -0.03781399875879288,
            0.03350762277841568,
            -0.0264291875064373,
            0.022129351273179054,
            0.02230614796280861,
            0.008015508763492107,
            0.005324671044945717,
            0.0258845966309309,
            0.02460625022649765,
            0.0022866264916956425,
            0.005324984900653362,
            0.011730315163731575,
            0.01993863843381405,
            -0.004976818338036537,
            0.008705795742571354,
            -0.012559575960040092,
            0.04054578021168709,
            -0.011768821626901627,
            -0.00894248764961958,
            -0.02153656631708145,
            -0.03168926388025284,
            -0.026016227900981903,
            -0.021598121151328087,
            0.027787042781710625,
            0.027101455256342888,
            0.0034375141840428114,
            -0.02712734416127205,
            0.004082769155502319,
            0.027680767700076103,
            0.00276222825050354,
            -0.03191843628883362,
            0.012831919826567173,
            -0.03201371058821678,
            -0.018703659996390343,
            -0.006088731810450554,
            0.0004265465249773115,
            -0.019616343080997467,
            0.018716957420110703,
            0.014574182219803333,
            -0.01789824664592743,
            -0.00512608140707016,
            -0.016313565894961357,
            -0.006723599508404732,
            -0.016540681943297386,
            -0.03508064150810242,
            -0.0020501259714365005,
            0.022046402096748352,
            -0.025376737117767334,
            -0.013054419308900833,
            0.032334305346012115,
            0.012626881711184978,
            0.015243430621922016,
            -0.00046895837294869125,
            0.006414173170924187,
            -0.007203107234090567,
            0.030547715723514557,
            -0.004023196641355753,
            -0.022644365206360817,
            0.0004251417994964868,
            0.013874102383852005,
            0.01881631836295128,
            0.004651451017707586,
            -0.01514713279902935,
            0.005141200963407755,
            -0.02340187132358551,
            -0.02939833328127861,
            -0.021322501823306084,
            -0.013445944525301456,
            0.027176005765795708,
            0.009135734289884567,
            0.010170522145926952,
            -0.0009297687211073935,
            0.015657545998692513,
            0.014598604291677475,
            0.010173977352678776,
            0.025193067267537117,
            0.025281066074967384,
            -0.006901441607624292,
            -0.027816912159323692,
            0.014915922656655312,
            -0.01991170085966587,
            -0.022446779534220695,
            0.007179172243922949,
            0.016805648803710938,
            0.004222671966999769,
            -0.013551658019423485,
            0.010290922597050667,
            0.04449263587594032,
            0.006916472222656012,
            -0.0028318362310528755,
            0.02407623454928398,
            0.000532864942215383,
            -0.017557015642523766,
            -0.015146821737289429,
            -0.0033075378742069006,
            -0.010935910046100616,
            0.009014519862830639,
            -0.007561821024864912,
            -0.010045269504189491,
            -0.0007035123417153955,
            -0.016000540927052498,
            -0.016591263934969902,
            0.010232356376945972,
            -0.014702078886330128,
            0.028966419398784637,
            0.01750163361430168,
            -0.008416134864091873,
            0.01394419465214014,
            -0.03096362203359604,
            0.014966735616326332,
            -0.0004698749689850956,
            -0.0027923951856791973,
            0.015450523234903812,
            -0.018216265365481377,
            0.025889204815030098,
            -0.018342789262533188,
            -0.019158808514475822,
            -0.03373904526233673,
            -0.023726796731352806,
            -0.034508705139160156,
            0.011316540651023388,
            -0.014091111719608307,
            -0.03356750309467316,
            0.004234667401760817,
            -0.023939019069075584,
            0.02320372685790062,
            0.0051459395326673985,
            0.02608903869986534,
            0.026425328105688095,
            -0.00030539120780304074,
            0.0002862952242139727,
            -0.01993776112794876,
            -0.02916184440255165,
            0.012137260288000107,
            0.028298527002334595,
            -0.0029998724348843098,
            -0.002283550798892975,
            -0.014146819710731506,
            0.01870284043252468,
            0.0016298651462420821,
            -0.01985313929617405,
            -0.0032890448346734047,
            0.023505911231040955,
            0.021033277735114098,
            0.02017015404999256,
            0.006269738543778658,
            0.018727753311395645,
            0.020863376557826996,
            0.032347988337278366,
            0.030192440375685692,
            -0.02870582975447178,
            -0.020580647513270378,
            0.010543178766965866,
            0.002584031317383051,
            0.018739044666290283,
            0.007708752527832985,
            0.012549100443720818,
            0.01530079822987318,
            0.025528693571686745,
            0.0078030540607869625,
            0.01837042346596718,
            0.008735522627830505,
            0.0025964409578591585,
            0.020343387499451637,
            -0.042051296681165695,
            0.014911078847944736,
            -0.014417815953493118,
            -0.015272355638444424,
            -0.0002797050110530108,
            0.02649599127471447,
            0.031603697687387466,
            0.018018243834376335,
            0.033849578350782394,
            -0.009993882849812508,
            -0.02807781659066677,
            -0.02931477501988411,
            -0.01931997388601303,
            0.004770081024616957,
            0.0002536569372750819,
            -0.02275208756327629,
            0.02809257060289383,
            0.004684208892285824,
            -0.007133430801331997,
            0.02318865805864334,
            0.025117777287960052,
            0.028887445107102394,
            -0.014105262234807014,
            0.03661908581852913,
            0.022405220195651054,
            0.027564721181988716,
            0.01564142480492592,
            -0.033168528228998184,
            0.034439053386449814,
            0.009129622019827366,
            0.0166912991553545,
            -0.01941068097949028,
            -0.04862416908144951,
            -0.021390387788414955,
            0.01137152686715126,
            -0.0358617790043354,
            -0.0038261478766798973,
            -0.02209748513996601,
            0.003541001118719578,
            -0.0052033523097634315,
            -0.018935680389404297,
            -0.016880476847290993,
            0.03662339597940445,
            0.0171357374638319,
            -0.015481725335121155,
            -0.03465760126709938,
            0.006131565663963556,
            0.014546569436788559,
            0.014225460588932037,
            -0.021904224529862404,
            0.021816080436110497,
            -0.013859741389751434,
            0.019012657925486565,
            0.033895500004291534,
            0.03185189887881279,
            0.026763616129755974,
            -0.014854105189442635,
            0.012454030103981495,
            0.005906895734369755,
            0.008943053893744946,
            -0.012678103521466255,
            0.013122884556651115,
            0.012770092114806175,
            -0.012370595708489418,
            0.010473348200321198,
            -0.01597088761627674,
            -0.02093617431819439,
            -0.006240140181034803,
            0.02034153789281845,
            0.005639808252453804,
            -0.02145255357027054,
            0.015545234084129333,
            -0.004980084020644426,
            -0.017716532573103905,
            -0.00481009716168046,
            0.02458369918167591,
            0.026675723493099213,
            -0.00879112258553505,
            0.030372383072972298,
            0.02382388524711132,
            0.02638896554708481,
            0.02756853587925434,
            0.03184787929058075,
            -0.005931653548032045,
            0.023855511099100113,
            -0.02616202086210251,
            0.028059199452400208,
            0.017352694645524025,
            -0.011327649466693401,
            -0.0070664770901203156,
            -0.03249788656830788,
            -0.0011983790900558233,
            -0.0027356897480785847,
            0.005061028990894556,
            -0.015052060596644878,
            -0.027664829045534134,
            -0.002185392426326871,
            0.0037950105033814907,
            -0.009158486500382423,
            -0.01933055929839611,
            -0.024236628785729408,
            -0.005798099096864462,
            0.004607830196619034,
            0.031103476881980896,
            -0.016130367293953896,
            -0.023855019360780716,
            0.008500631898641586,
            -0.04510132223367691,
            0.016568824648857117,
            0.021595418453216553,
            -0.03896961361169815,
            -0.008189694955945015,
            0.01005290262401104,
            0.018142273649573326,
            -0.013755773194134235,
            0.001123954774811864,
            0.0057508684694767,
            0.019922297447919846,
            -0.04178908094763756,
            -0.02199937216937542,
            0.05199214071035385,
            0.015206191688776016,
            0.009058325551450253,
            0.013206228613853455,
            0.002523874631151557,
            -0.024140579625964165,
            0.028353137895464897,
            -0.017757926136255264,
            -0.002542286179959774,
            0.006863181944936514,
            0.03155083954334259,
            0.028494389727711678,
            0.015706392005085945,
            0.016436345875263214,
            -0.0068483054637908936,
            -0.01643984764814377,
            -0.013980221003293991,
            -0.015236892737448215,
            -0.004649460781365633,
            0.024783995002508163,
            0.02649652026593685,
            0.017291177064180374,
            -0.03420485183596611,
            -0.021788597106933594,
            -0.009522386826574802,
            -0.002981805242598057,
            -0.011161258444190025,
            -0.002240039175376296,
            -0.007058331277221441,
            -0.010637698695063591,
            -0.025571109727025032,
            -0.007025917526334524,
            0.009524556808173656,
            -0.015130716376006603,
            0.036531537771224976,
            -0.0021023594308644533,
            0.01395751815289259,
            0.013313155621290207,
            -0.012014137580990791,
            0.01621437259018421,
            0.009666473604738712,
            0.011252432130277157,
            0.019154559820890427,
            -0.01884729601442814,
            0.015579389408230782,
            0.027098096907138824,
            -0.0012945656199008226,
            0.04324556887149811,
            -0.01863117143511772,
            -0.0003001915756613016,
            -0.013738138601183891,
            0.02115282602608204,
            -0.014004785567522049,
            0.019552888348698616,
            0.0028096747118979692,
            -0.006050014868378639,
            0.03740869462490082,
            0.0270689707249403,
            -0.003159116255119443,
            0.020925795659422874,
            -0.006186001002788544,
            0.013977999798953533,
            -0.027461441233754158,
            -0.013026116415858269,
            0.017772622406482697,
            0.0476204939186573,
            0.02683425135910511,
            0.028714992105960846,
            0.028419721871614456,
            -0.014281002804636955,
            0.025261687114834785,
            0.03828069940209389,
            0.0029817845206707716,
            -0.004345369059592485,
            -0.0013083016965538263,
            0.01216805912554264,
            0.026716241613030434,
            0.025037525221705437,
            0.031190181151032448,
            0.028504643589258194,
            0.02055351249873638,
            -0.014675949700176716,
            0.03270015865564346,
            -0.009257826954126358,
            0.01773265190422535,
            0.04222751781344414,
            0.009733160026371479,
            -0.023096054792404175,
            -0.017882999032735825,
            0.024586834013462067,
            0.012680677697062492,
            0.03413521125912666,
            0.019585920497775078,
            0.005211069248616695,
            0.015576768666505814,
            -0.024875713512301445,
            -0.004103547427803278,
            -0.012479080818593502,
            -0.007560993079096079,
            0.007503874599933624,
            -0.0048120091669261456,
            -0.022382602095603943,
            -0.01823447085916996,
            0.0038700539153069258,
            -0.009875038638710976,
            -0.008795731700956821,
            -0.011457320302724838,
            -0.018890155479311943,
            -0.00148794986307621,
            0.025585437193512917,
            -0.026590192690491676,
            0.03111720085144043,
            -0.014606290496885777,
            -0.03115823119878769,
            0.02267920784652233,
            -0.024578740820288658,
            -0.02023276500403881,
            0.021768487989902496,
            0.02551022544503212,
            -0.04609699919819832,
            -0.03206191956996918,
            0.007258425932377577,
            0.00045867959852330387,
            -0.030517688021063805,
            0.029354700818657875,
            0.006135780364274979,
            -0.018480435013771057,
            -0.030051570385694504,
            0.011186480522155762,
            0.006112076807767153,
            0.021881546825170517,
            -0.012821395881474018,
            0.008327838033437729,
            0.0006373837240971625,
            0.006703372113406658,
            0.00604544160887599,
            -0.026217946782708168,
            0.015799542888998985,
            -0.03076956979930401,
            -0.012700323946774006,
            -0.031225910410284996,
            0.012903726659715176,
            -0.004924108274281025,
            0.0120773920789361,
            0.030861785635352135,
            0.012897380627691746,
            -0.016833018511533737
        ],
        [
            -0.009174725040793419,
            0.0357174426317215,
            -0.0010404926724731922,
            -0.030000144615769386,
            -0.016310323029756546,
            0.01706652157008648,
            -0.027698613703250885,
            0.033959563821554184,
            -0.021784741431474686,
            -0.018021365627646446,
            -0.0368046909570694,
            0.0034870037343353033,
            0.049300022423267365,
            0.008787864819169044,
            0.010197408497333527,
            0.0351225882768631,
            -0.02137136086821556,
            -0.003685688367113471,
            -0.01638025790452957,
            -0.014812065288424492,
            -0.01866169460117817,
            -0.010918335057795048,
            0.04247376695275307,
            -0.02375617064535618,
            -0.015187403187155724,
            -0.03244854137301445,
            0.02853771112859249,
            0.009606079198420048,
            -0.021847091615200043,
            -0.018140658736228943,
            -0.01523448433727026,
            0.01809033378958702,
            0.007387266959995031,
            -0.04003036022186279,
            -0.026676790788769722,
            0.01917886547744274,
            -0.018942546099424362,
            0.014599168673157692,
            3.748498420463875e-05,
            -0.0415845587849617,
            -0.0013371995883062482,
            0.015584750100970268,
            -0.03531436249613762,
            0.01600087806582451,
            0.0015798845561221242,
            0.042273521423339844,
            0.01596406102180481,
            0.022966418415308,
            0.013903073966503143,
            0.030785897746682167,
            -0.02342163771390915,
            -0.02317740209400654,
            0.012809105217456818,
            -0.043261896818876266,
            -0.015264561399817467,
            -0.0007902126526460052,
            0.030526719987392426,
            0.0058463700115680695,
            0.01549392007291317,
            -0.021072832867503166,
            -0.019869519397616386,
            0.036909230053424835,
            -0.013600884936749935,
            0.012264673598110676,
            0.034558217972517014,
            0.01697438955307007,
            -0.007002457045018673,
            -0.01092217955738306,
            -0.019067520275712013,
            0.025003893300890923,
            0.01616852544248104,
            -0.026459621265530586,
            -0.0009945266647264361,
            -0.018061406910419464,
            -0.01428762823343277,
            0.022058311849832535,
            0.02428564801812172,
            -0.01786690391600132,
            0.04567158594727516,
            -0.029484910890460014,
            0.01503559947013855,
            0.01664608344435692,
            -0.025129534304142,
            -0.015009223483502865,
            -0.028028445318341255,
            0.016622748225927353,
            -0.020004190504550934,
            -0.0010339475702494383,
            0.008346331305801868,
            -0.03959221392869949,
            -0.004290401935577393,
            -0.0035497169010341167,
            0.01934506557881832,
            -0.023081164807081223,
            -0.012349113821983337,
            0.009660853073000908,
            -0.04795378819108009,
            0.026391025632619858,
            0.024627622216939926,
            -0.016795458272099495,
            0.03172099217772484,
            0.02738773077726364,
            -0.017881251871585846,
            0.019284291192889214,
            -0.018545562401413918,
            -0.03514638543128967,
            -0.025080962106585503,
            -0.0023426422849297523,
            0.0069350069388747215,
            -0.023963185027241707,
            0.01679743267595768,
            -0.0023076990619301796,
            0.01847437582910061,
            -0.009401695802807808,
            -0.02539176680147648,
            0.0024235500022768974,
            -0.003792230971157551,
            0.008127610199153423,
            0.0239741001278162,
            -0.027103973552584648,
            0.011217741295695305,
            -0.008274187333881855,
            0.0013204040005803108,
            -0.02182973176240921,
            -0.00850342120975256,
            -0.0069938343949615955,
            0.029180331155657768,
            0.012630161829292774,
            -0.001627572113648057,
            -0.005283975042402744,
            -0.008642264641821384,
            0.037920597940683365,
            0.02855386771261692,
            0.03150619566440582,
            -0.05648721009492874,
            -0.03386915475130081,
            0.005737823899835348,
            -0.0108180595561862,
            -0.010818232782185078,
            0.01895347237586975,
            -0.022043321281671524,
            -0.0266499575227499,
            0.017093783244490623,
            -0.01753179542720318,
            -0.009520338848233223,
            -0.008457723073661327,
            -0.002269860589876771,
            -0.009691299870610237,
            0.0241647157818079,
            0.012707577086985111,
            -0.026106402277946472,
            0.01148542482405901,
            0.015137936919927597,
            -0.03610650822520256,
            0.039373841136693954,
            0.03927898034453392,
            -0.005347439553588629,
            -0.0029622965957969427,
            0.028795596212148666,
            0.009018936194479465,
            -0.019174909219145775,
            0.0345616415143013,
            0.016579415649175644,
            -0.017210090532898903,
            0.021832754835486412,
            0.031628672033548355,
            -0.020450707525014877,
            -0.021768169477581978,
            0.024752460420131683,
            0.010113288648426533,
            -0.027009477838873863,
            0.00534610403701663,
            -0.022913742810487747,
            0.03273732587695122,
            0.017173288390040398,
            0.05037534609436989,
            -0.01600366272032261,
            0.006899314466863871,
            0.025345630943775177,
            0.010084296576678753,
            0.01625756174325943,
            0.020584017038345337,
            0.02591893821954727,
            0.012265382334589958,
            0.02811756357550621,
            0.029393339529633522,
            0.008626187220215797,
            -0.039059996604919434,
            -0.03464025259017944,
            0.030922945588827133,
            0.029144680127501488,
            0.011630161665380001,
            -0.021092316135764122,
            -0.025165822356939316,
            0.02332140877842903,
            0.007699387148022652,
            -0.006552842445671558,
            -0.01519494317471981,
            0.0021884567104279995,
            -0.0020321840420365334,
            -0.016211846843361855,
            0.006418330129235983,
            -0.03602227568626404,
            -0.0294782854616642,
            -0.040845975279808044,
            -0.02295396476984024,
            -0.03221625089645386,
            0.05172940716147423,
            0.02621336281299591,
            0.012912982143461704,
            0.0226213987916708,
            -0.01068824902176857,
            0.034102968871593475,
            0.022956112399697304,
            -0.015623189508914948,
            0.022348353639245033,
            0.009072416462004185,
            -0.03021172620356083,
            0.002081675222143531,
            -0.0112278638407588,
            -0.008700485341250896,
            0.011844263412058353,
            -0.016036033630371094,
            -0.025395935401320457,
            0.015068650245666504,
            -0.01056662853807211,
            0.03687679022550583,
            0.011182553134858608,
            -0.01923453062772751,
            0.02969658002257347,
            -0.002569552045315504,
            0.027583416551351547,
            -0.02606978826224804,
            0.01136576198041439,
            0.03226883336901665,
            -0.024594804272055626,
            0.02601865492761135,
            0.01524362526834011,
            -0.010778342373669147,
            -0.007775806821882725,
            -0.008236202411353588,
            -0.026417475193738937,
            0.01660514622926712,
            0.023017851635813713,
            -0.02663053758442402,
            0.017652291804552078,
            -0.0024221648927778006,
            -0.004340311512351036,
            0.004136245232075453,
            -0.034868549555540085,
            -0.029645413160324097,
            -0.025405146181583405,
            0.006611111108213663,
            -0.025661608204245567,
            0.025481034070253372,
            -0.011429236270487309,
            0.005484647583216429,
            0.033496156334877014,
            0.000988588435575366,
            0.01970517821609974,
            0.07242787629365921,
            -0.017303744331002235,
            0.011487701907753944,
            0.01718446984887123,
            0.014332912862300873,
            -0.006494073662906885,
            0.03435421362519264,
            -0.02176930196583271,
            0.01875740848481655,
            -0.004059034399688244,
            0.001579830888658762,
            -0.01417818758636713,
            0.02216256782412529,
            -0.009523887187242508,
            -0.0007561990641988814,
            0.0012996653094887733,
            0.04164141044020653,
            -0.011780167929828167,
            -0.007597422227263451,
            -0.00011576651013456285,
            -0.04004647210240364,
            0.024836501106619835,
            -0.010336654260754585,
            0.04476001113653183,
            -0.0037660126108676195,
            0.0006997833261266351,
            0.0350140780210495,
            0.040483810007572174,
            -0.004554393235594034,
            0.002899810904636979,
            -0.007154438178986311,
            0.010184375569224358,
            -0.004796036519110203,
            -0.039328962564468384,
            0.009268227033317089,
            0.022619904950261116,
            -0.0178842693567276,
            0.014531152322888374,
            0.02127138525247574,
            -0.008623749949038029,
            0.02783082239329815,
            0.015086043626070023,
            0.0016861805925145745,
            0.02680368535220623,
            -0.05330377817153931,
            -0.007854679599404335,
            -0.028048885986208916,
            -0.0323648527264595,
            0.008813661523163319,
            0.0017117689130827785,
            0.0020224829204380512,
            0.010544029995799065,
            0.004446688108146191,
            -0.023425497114658356,
            0.028796395286917686,
            0.028377901762723923,
            0.0019537846092134714,
            -0.0337623730301857,
            -0.0300234854221344,
            -0.022789938375353813,
            0.011954198591411114,
            -0.04635687917470932,
            0.02044624835252762,
            0.02542029321193695,
            0.012365004979074001,
            -0.008593481034040451,
            0.009897502139210701,
            0.012801669538021088,
            0.0008027409785427153,
            -0.03038031980395317,
            0.034097786992788315,
            0.017671290785074234,
            -6.587991083506495e-05,
            -0.02845734730362892,
            0.02425103634595871,
            0.0311368927359581,
            0.025149863213300705,
            0.022857315838336945,
            0.028395477682352066,
            0.010004683397710323,
            -0.01364967878907919,
            0.06280189752578735,
            -0.028293590992689133,
            0.005690144840627909,
            -0.017557689920067787,
            -0.02885393425822258,
            0.026109950616955757,
            -0.021139070391654968,
            -0.028424344956874847,
            0.020330365747213364,
            -0.011037817224860191,
            -0.03090686909854412,
            0.009000726044178009,
            -0.029352227225899696,
            -0.000382867205189541,
            0.005470355972647667,
            0.005542077589780092,
            -0.038579948246479034,
            -0.0038673970848321915,
            -0.0007527200505137444,
            -0.00603894330561161,
            -0.04194542393088341,
            0.01046807412058115,
            0.02738027833402157,
            0.018780449405312538,
            -0.015730172395706177,
            0.025722481310367584,
            0.01564859226346016,
            0.0520859993994236,
            0.01926996186375618,
            -0.041165098547935486,
            -0.02136477455496788,
            0.02320524863898754,
            0.01489911787211895,
            0.013782900758087635,
            -0.0022392228711396456,
            0.033532511442899704,
            0.02467627078294754,
            0.03917548060417175,
            0.023040255531668663,
            0.02108140103518963,
            -0.014347533695399761,
            -0.014853346161544323,
            -0.008618275634944439,
            -0.0011652720859274268,
            0.027643589302897453,
            -0.018463462591171265,
            -0.03691140562295914,
            -0.004300018772482872,
            0.024250593036413193,
            0.02450941689312458,
            0.03861050680279732,
            -0.00609643803909421,
            -0.003146435832604766,
            -0.008078941144049168,
            -0.013934709131717682,
            0.011235573329031467,
            0.03225546330213547,
            -0.008650120347738266,
            0.07370489090681076,
            -0.018550870940089226,
            -0.006002613343298435,
            0.024776553735136986,
            -0.010396871715784073,
            0.01630338281393051,
            -0.012830295599997044,
            0.011163298971951008,
            -0.01807103306055069,
            -0.0031340455170720816,
            0.0266720000654459,
            -0.020870037376880646,
            -0.014104769565165043,
            0.013225835748016834,
            0.02046581357717514,
            0.03913039341568947,
            0.0359884537756443,
            0.028269240632653236,
            0.00778822461143136,
            -0.00876439455896616,
            -0.038392599672079086,
            0.04163973033428192,
            -0.0028656241483986378,
            -0.02378269098699093,
            -0.01883956417441368,
            -0.012883322313427925,
            -0.008172214031219482,
            -0.024921389296650887,
            0.07144924998283386,
            0.02280079759657383,
            0.02329936809837818,
            0.040370430797338486,
            0.010676917620003223,
            -0.03660779446363449,
            -0.010442842729389668,
            -0.027701813727617264,
            0.008290656842291355,
            0.02373674139380455,
            0.011978999711573124,
            0.010111913084983826,
            0.017971443012356758,
            0.017599280923604965,
            -0.026582660153508186,
            -0.026838265359401703,
            0.03462392836809158,
            -0.02290261909365654,
            -0.032320376485586166,
            -0.015402101911604404,
            -0.01088734995573759,
            0.030022229999303818,
            -0.005398900713771582,
            -0.026700977236032486,
            -0.020210236310958862,
            0.042143914848566055,
            0.0015902376035228372,
            0.04782794415950775,
            -0.036311954259872437,
            0.006927084177732468,
            -0.016042519360780716,
            -0.02518192119896412,
            -0.0024991363752633333,
            0.019414860755205154,
            0.018183963373303413,
            0.011587562039494514,
            -0.03059517964720726,
            -0.002964562503620982,
            -0.027976252138614655,
            0.01987738162279129,
            -0.01583104580640793,
            -0.005008612759411335,
            0.0040143937803804874,
            -0.0012855534441769123,
            0.008518572896718979,
            -0.028550542891025543,
            0.053444162011146545,
            -0.007261051796376705,
            0.003410645527765155,
            -0.0012468521017581224,
            -0.00828483048826456,
            0.027765724807977676,
            0.033206235617399216,
            0.0002241565234726295,
            0.0072626713663339615,
            -0.005936942528933287,
            -0.037936847656965256,
            0.046676479279994965,
            -0.007777211256325245,
            0.020899133756756783,
            -0.0314641073346138,
            -0.04361797496676445,
            0.012010976672172546,
            -0.0021098994184285402,
            -0.029081227257847786,
            0.028669413179159164,
            0.012801522389054298,
            0.03714967891573906,
            0.008410847745835781,
            0.0546431690454483,
            -0.01998468115925789,
            -0.030711131170392036,
            -0.017698703333735466,
            -0.01479084137827158,
            0.026656119152903557,
            0.006829631514847279,
            -0.011793854646384716,
            0.005532435607165098,
            0.014858396723866463,
            0.0038227648474276066,
            -0.015658222138881683,
            -0.02107200026512146,
            -0.003940599970519543,
            0.016449283808469772,
            -0.0012910414952784777,
            0.0215582437813282,
            0.010256762616336346,
            -0.011249533854424953,
            -0.01113962847739458,
            0.018010739237070084,
            -0.0260406956076622,
            -0.0039776903577148914,
            0.01913825422525406,
            -0.006929272320121527,
            -0.0036975459661334753,
            -0.013306681998074055,
            -0.02267470769584179,
            0.007305810693651438,
            -0.024144910275936127,
            -0.05215417593717575,
            -0.018392907455563545,
            0.027840621769428253,
            0.03997781500220299,
            0.007379353512078524,
            0.023152774199843407,
            0.0009101951727643609,
            0.01558306347578764,
            -0.010615807957947254,
            0.01087450236082077,
            0.009021255187690258,
            0.018120622262358665,
            0.013221603818237782,
            0.015230067074298859,
            -0.009918823838233948,
            0.022472159937024117,
            0.028198299929499626,
            0.0470186322927475,
            0.0001441779750166461,
            0.031984325498342514,
            -0.02316616103053093,
            -0.05726891756057739,
            -0.005028769373893738,
            0.013059425167739391,
            0.010144485160708427,
            -0.006302468944340944,
            0.023309694603085518,
            -0.012328816577792168,
            0.054313335567712784,
            -0.007621115073561668,
            -0.025727679952979088,
            0.0006127050728537142,
            0.021925898268818855,
            0.02213883213698864,
            0.008407642133533955,
            0.01884441077709198,
            -0.009518864564597607,
            0.02894546650350094,
            0.024986565113067627,
            0.020147176459431648,
            -0.03721199929714203,
            -0.041409775614738464,
            0.012064702808856964,
            0.008082451298832893,
            0.006559615954756737,
            -0.013720936141908169,
            0.03029298037290573,
            0.02455325983464718,
            -0.02590007148683071,
            -0.019249485805630684,
            -0.03223482519388199,
            0.024957237765192986,
            0.01612670160830021,
            0.010401730425655842,
            0.016360590234398842,
            -0.0028781818691641092,
            0.03595980629324913,
            -0.03487543761730194,
            -0.01055259071290493,
            -0.023824697360396385,
            -0.012482943013310432,
            -0.0347549244761467,
            -0.0072389324195683,
            0.0022907450329512358,
            -0.04029092192649841,
            0.012929909862577915,
            -0.00841573253273964,
            -0.025598201900720596,
            0.004850435070693493,
            0.0031804197933524847,
            0.00200609490275383,
            -0.0018091744277626276,
            -0.013992534019052982,
            0.007785171270370483,
            0.015356218442320824,
            -0.0020875949412584305,
            0.022538073360919952,
            -0.023246625438332558,
            0.03044060990214348,
            0.03006071224808693,
            -0.011741322465240955,
            0.007738944608718157,
            -0.0016636308282613754,
            0.03027207776904106,
            -0.003980354405939579,
            -0.0016990755684673786,
            -0.009804249741137028,
            -0.004883739165961742,
            0.01742962747812271,
            -0.011566181667149067,
            -0.021434249356389046,
            0.02295590378344059,
            -0.030249040573835373,
            0.001577603630721569,
            -0.013025844469666481,
            0.0324283242225647,
            -0.0005058981478214264,
            0.008687332272529602,
            0.036881934851408005,
            -0.022765319794416428,
            0.002328036352992058,
            -0.00697731040418148,
            0.03706995025277138,
            -0.03208644688129425,
            -0.0026296451687812805,
            -0.011926145292818546,
            0.007471452932804823,
            0.02686586230993271,
            0.02482195571064949,
            0.017303403466939926,
            0.02612420916557312,
            0.004331468604505062,
            0.029517661780118942,
            0.015904905274510384,
            0.028554510325193405,
            -0.009505280293524265,
            -0.009333830326795578,
            -0.04892007261514664,
            -0.012552392669022083,
            0.007623966317623854,
            -0.008286566473543644,
            -0.019655853509902954,
            0.01703060418367386,
            -0.022385092452168465,
            0.03454582765698433,
            -0.007501173298805952,
            0.003469722345471382,
            0.012590715661644936,
            0.03585650026798248,
            0.012759167701005936,
            -0.02232583798468113,
            -0.004405570216476917,
            -0.014116237871348858,
            -0.021635238081216812,
            0.029589422047138214,
            0.03971434012055397,
            -0.02517528459429741,
            -0.037157922983169556,
            0.008727090433239937,
            -0.004731442779302597,
            -0.020951008424162865,
            -0.01521551888436079,
            -0.023021548986434937,
            0.00753515399992466,
            0.0452578030526638,
            0.02676168642938137,
            -0.02552713453769684,
            0.016238223761320114,
            -0.01803479716181755,
            -0.05501771345734596,
            0.0015043254243209958,
            -0.03973853960633278,
            -0.026192691177129745,
            0.0250832699239254,
            0.03557481989264488,
            -0.007818730548024178,
            0.009302660822868347,
            0.017199674621224403,
            0.020653165876865387,
            -0.0218046847730875,
            -0.01875101588666439,
            0.026014158502221107,
            -0.020086685195565224,
            -0.011024055071175098,
            -0.02200627513229847,
            0.027834447100758553,
            -0.00402686046436429,
            0.012692532502114773,
            -0.013940052129328251,
            -0.01373059581965208,
            0.02711157500743866,
            -0.017163056880235672,
            0.012102171778678894,
            -0.01632198505103588,
            0.012309095822274685,
            0.007362824864685535,
            -0.006771077401936054,
            0.010349804535508156,
            0.03855529800057411,
            -0.0020568245090544224,
            0.01529037393629551,
            0.016379915177822113,
            0.02266150526702404,
            0.004253210499882698,
            0.02145877480506897,
            0.005207411013543606,
            -0.015136790461838245,
            -0.0003207263653166592,
            -0.006902057211846113,
            0.012491528876125813,
            -0.0045337798073887825,
            0.01065899059176445,
            -0.01563253439962864,
            0.03068738803267479,
            0.041886795312166214,
            -0.007550728973001242,
            -0.008901841007173061,
            -0.02887006849050522,
            -0.003785112639889121,
            -0.022507328540086746,
            -0.015825072303414345,
            -0.011295250616967678,
            -0.04056642949581146,
            0.00323235965333879,
            0.012947233393788338,
            0.009287751279771328,
            -0.00593925267457962,
            -0.02940540201961994,
            -0.0030235452577471733,
            0.01852385699748993,
            0.03879055753350258,
            -0.027586722746491432,
            -0.015917040407657623,
            -0.015882793813943863,
            0.018978793174028397,
            -0.017928482964634895,
            -0.00451709795743227,
            -0.021339094266295433,
            -0.009142576716840267,
            -0.009273115545511246,
            -0.05988917872309685,
            -0.010695739649236202,
            -0.01702887751162052,
            0.028661642223596573,
            0.020924856886267662,
            -0.014218294061720371,
            0.009727482683956623,
            0.01824488863348961,
            -0.03651592507958412,
            0.03245972841978073,
            -0.02920645847916603,
            0.0077927736565470695,
            -0.03135782852768898,
            -0.013753516599535942,
            -0.05306448042392731,
            -0.05816923826932907,
            0.0006952371331863105,
            -0.019659634679555893,
            0.020636482164263725,
            -0.0034061535261571407,
            -0.006092661526054144,
            0.016027916222810745,
            -0.031683456152677536,
            0.014653411693871021,
            0.0068880897015333176,
            0.005394036415964365,
            0.007221696898341179,
            -0.0182332843542099,
            0.0012675438774749637,
            -0.014444025233387947,
            -0.005696309730410576,
            0.008487091399729252,
            -0.02185576781630516,
            -0.002329627051949501,
            -0.022713735699653625,
            0.030239418148994446,
            -0.00743873929604888,
            -0.007695857901126146,
            0.007428871002048254,
            -0.02562946453690529,
            0.01943260245025158,
            0.021297501400113106,
            0.045804575085639954,
            0.007862119004130363,
            0.01190306805074215,
            -0.018881160765886307,
            -0.002238654298707843,
            0.013825995847582817,
            0.0031149759888648987,
            -0.027505621314048767,
            0.011406485922634602,
            0.015736624598503113,
            0.011013866402208805,
            -0.001410270924679935,
            0.0065529681742191315,
            0.03233417868614197,
            0.04131291061639786,
            0.017985297366976738,
            -0.043640732765197754,
            -0.03539561107754707,
            -0.005555976647883654,
            0.007999529130756855,
            -0.010396629571914673,
            -0.018001263961195946,
            0.007374437525868416,
            -0.0010782519821077585,
            0.02683987095952034,
            0.015469548292458057,
            0.02485687844455242,
            0.013620109297335148,
            -0.04875288903713226,
            0.002552357502281666,
            -0.028548305854201317,
            -0.03148261085152626,
            -0.01635926403105259,
            -0.027215562760829926,
            -0.00047897215699777007,
            0.016732146963477135,
            0.019523099064826965,
            -0.005736154969781637,
            0.004033732693642378,
            0.033619534224271774,
            0.024678265675902367,
            0.024649672210216522,
            0.023267332464456558,
            -0.0011439435184001923,
            -0.04653485119342804,
            -0.01763288490474224,
            -0.007189152762293816,
            -0.0015282070962712169,
            0.006703687831759453,
            -0.02403998002409935,
            -0.011061635799705982,
            -0.024659914895892143,
            -0.04778067395091057,
            -0.06781904399394989,
            0.03210637345910072,
            -0.013712694868445396,
            -0.02200283668935299,
            0.021540895104408264,
            -0.016722433269023895,
            -0.019538724794983864,
            0.013638283126056194,
            -0.04346873238682747,
            0.00820890162140131,
            -0.003193094627931714,
            0.026649175211787224,
            -0.02496298775076866,
            0.04537924751639366,
            0.0007040452910587192,
            0.00438739825040102,
            0.036025699228048325,
            -0.014237050898373127,
            -0.05819213390350342,
            0.038312312215566635,
            -6.880730506964028e-05,
            0.015371178276836872,
            -0.006870718207210302,
            0.0168573260307312,
            0.008923397399485111,
            0.019711965695023537,
            0.006612428463995457,
            0.003959203138947487,
            0.01279496680945158,
            0.005038043018430471,
            -0.006791319698095322,
            0.008723955601453781,
            -0.0042000324465334415,
            -0.0005465023568831384,
            -0.004656306002289057,
            0.021172596141695976,
            -0.03473706543445587,
            0.001211897935718298,
            -0.02012578956782818,
            -0.00935776624828577,
            -0.024329455569386482,
            -0.0225653238594532,
            -0.002283018082380295,
            -0.008586758747696877,
            0.027792884036898613,
            -0.03481537476181984,
            -0.006367678288370371,
            0.025900118052959442,
            0.02192516252398491,
            0.026807403191924095,
            -0.00955705251544714,
            0.00859979446977377,
            -0.002574571408331394,
            0.01061664242297411,
            -0.017577333375811577,
            0.026751406490802765,
            -0.0034546738024801016,
            -0.04202092066407204,
            -0.016141174361109734,
            -0.017107227817177773,
            -0.018914418295025826,
            0.02931784652173519,
            -0.007255913224071264,
            0.010957670398056507,
            0.004700883291661739,
            -0.005165254231542349,
            -0.002481412375345826,
            -0.008176500909030437,
            -0.03748958557844162,
            0.0022070377599447966,
            0.0034206444397568703,
            -0.003148980438709259,
            0.012616804800927639,
            0.01963493600487709,
            -0.006269756238907576,
            0.047461047768592834,
            -0.01649441197514534,
            0.02073303982615471,
            0.00806071050465107,
            0.0060285055078566074,
            -0.010243878699839115,
            -0.024308795109391212,
            0.013385379686951637,
            0.02130146138370037,
            -0.051858097314834595,
            -0.03430458903312683,
            -0.05909106135368347,
            -0.031742293387651443,
            -0.018202640116214752,
            -0.023718276992440224,
            0.028576139360666275,
            0.0015474657993763685,
            -0.027379808947443962,
            -0.004614600446075201,
            -0.0038492802996188402,
            -0.021530205383896828,
            0.01622084155678749,
            0.024548379704356194,
            -0.012669598683714867,
            -0.01637117937207222,
            0.023496970534324646,
            0.02460307814180851,
            -0.03060298226773739,
            0.02843208983540535,
            -0.01565747708082199,
            -0.026723619550466537,
            0.04462704062461853,
            0.004061226267367601,
            0.013099849224090576,
            0.005491391755640507,
            -0.006943323649466038,
            -0.025879863649606705,
            0.0010003055213019252,
            -0.016558460891246796,
            0.004829778335988522,
            0.0058899009600281715,
            0.026653161272406578,
            -0.016086148098111153,
            -0.012364585883915424,
            0.03836032748222351,
            0.02767021395266056,
            -0.04579520598053932,
            -0.0076734572649002075,
            -0.023176662623882294,
            0.03403604030609131,
            0.029252685606479645,
            0.00798280630260706,
            0.0010779588483273983,
            -0.0052667236886918545,
            -0.011670665815472603,
            0.02695843204855919,
            0.003367052646353841,
            -0.007652358617633581,
            -0.02558453381061554,
            -0.013688907027244568,
            0.013391382060945034,
            -0.008410348556935787,
            0.02260192483663559,
            -0.021352659910917282,
            -0.02541727013885975,
            0.017940113320946693,
            -0.03554889187216759,
            0.014387871138751507,
            0.03062354028224945,
            0.015726611018180847,
            0.04277276620268822,
            0.006704309955239296,
            0.01293936651200056,
            -0.009421331807971,
            0.017554624006152153,
            -0.007675140630453825,
            -0.03284201771020889,
            0.003514731302857399,
            -0.04396435245871544,
            -0.018274283036589622,
            0.01932494528591633,
            0.034690938889980316,
            -0.004091047681868076,
            0.01700986735522747,
            0.0039104581810534,
            0.01019535306841135,
            0.041739095002412796,
            -0.008282124064862728,
            0.024882936850190163,
            -0.015250912867486477,
            -0.004145535174757242,
            -0.016598718240857124,
            -0.02535931020975113,
            0.014950706623494625,
            0.02420518733561039,
            0.0036971261724829674,
            -0.00039523400482721627,
            0.0136723592877388,
            0.06818962097167969,
            0.005849151406437159,
            -0.03241702541708946,
            -0.022990163415670395,
            0.04092540219426155,
            0.003200544510036707,
            0.02680370584130287,
            -0.02703452855348587,
            0.016984332352876663,
            -0.03733305633068085
        ],
        [
            0.029900338500738144,
            -0.0012596287997439504,
            0.020758697763085365,
            0.013626554049551487,
            -0.0021432978101074696,
            0.006322534289211035,
            0.001010316889733076,
            -0.011672480963170528,
            0.0019263222347944975,
            -0.018711594864726067,
            -0.008602728135883808,
            0.02059493213891983,
            0.011612692847847939,
            -0.02267679013311863,
            0.008255261927843094,
            -0.0011085491860285401,
            0.023616870865225792,
            0.009155225940048695,
            0.003558679483830929,
            -0.00038643088191747665,
            0.02839193493127823,
            -0.02930348366498947,
            0.02734910137951374,
            -0.024173535406589508,
            -0.01530985813587904,
            -0.0232451893389225,
            0.013497027568519115,
            0.016645735129714012,
            -0.0002571959048509598,
            0.004743387922644615,
            0.03623282536864281,
            -0.030862940475344658,
            0.035455070436000824,
            0.012500102631747723,
            -0.014346575364470482,
            -0.003529939567670226,
            -0.019399989396333694,
            0.013000371865928173,
            0.01786126382648945,
            0.01225994247943163,
            -0.020462017506361008,
            -0.004995955619961023,
            -0.009952613152563572,
            -0.010355029255151749,
            0.034244656562805176,
            0.020092813298106194,
            -0.033312078565359116,
            0.004771532956510782,
            -0.005453679244965315,
            0.02114347741007805,
            0.011062758043408394,
            -0.029148871079087257,
            -0.02659003622829914,
            -0.01763603650033474,
            -0.009900330565869808,
            -0.010898768901824951,
            -0.008024475537240505,
            0.015840737149119377,
            0.027530144900083542,
            0.004369551781564951,
            0.03278764709830284,
            -0.017507608979940414,
            -0.013456390239298344,
            0.011444546282291412,
            -0.006627945229411125,
            0.039963122457265854,
            -0.0009536298457533121,
            0.024701396003365517,
            0.027736930176615715,
            -0.004132694564759731,
            -0.0006481560412794352,
            -0.019061189144849777,
            0.03043399192392826,
            0.017545655369758606,
            -0.041494037955999374,
            -0.008534853346645832,
            0.01320361066609621,
            0.027549125254154205,
            -0.025931652635335922,
            -0.0073550608940422535,
            -0.0037286391016095877,
            -0.006766047794371843,
            -0.0032144549768418074,
            -0.003585540223866701,
            0.011960988864302635,
            0.012519892305135727,
            -0.005053476430475712,
            0.01636146567761898,
            -0.013560213148593903,
            0.010486174374818802,
            0.020345760509371758,
            0.019165081903338432,
            0.00618192320689559,
            -0.0398532934486866,
            0.026838647201657295,
            -0.039479710161685944,
            -0.005027054809033871,
            0.027102144435048103,
            -0.03378743305802345,
            -0.011471141129732132,
            0.038762882351875305,
            -0.009001619182527065,
            -0.0026012673042714596,
            0.01464915368705988,
            0.015456409193575382,
            0.031080465763807297,
            -0.022164512425661087,
            -0.02056477591395378,
            0.00508098304271698,
            0.0052315243519842625,
            0.0019709717016667128,
            -0.023671895265579224,
            0.020636187866330147,
            -0.021155962720513344,
            0.03165283426642418,
            -0.0015496803680434823,
            -0.009896586649119854,
            -0.027899732813239098,
            -0.012672588229179382,
            0.005060150753706694,
            0.023242445662617683,
            0.027386317029595375,
            0.016367515549063683,
            -0.03003205545246601,
            -0.026390887796878815,
            -0.01819150149822235,
            -0.003335680579766631,
            0.0034625367261469364,
            -0.019637789577245712,
            0.02989504113793373,
            0.006844735238701105,
            -0.008509400300681591,
            -0.006261840928345919,
            -0.007894864305853844,
            0.023548956960439682,
            0.007099085953086615,
            -0.010265878401696682,
            0.0008980014827102423,
            0.014553689397871494,
            -0.003594450419768691,
            0.015124554745852947,
            -0.022004686295986176,
            0.009263059124350548,
            -0.0163933914154768,
            -0.013959365896880627,
            0.02818997949361801,
            -0.018089966848492622,
            -0.014042092487215996,
            -0.0110760647803545,
            -0.004444277845323086,
            -0.014253363013267517,
            -0.004723163787275553,
            0.019658802077174187,
            0.006108385976403952,
            -0.011619801633059978,
            0.01667838729918003,
            -0.011131481267511845,
            -0.024440940469503403,
            -0.015648743137717247,
            -0.014092735014855862,
            -0.018799323588609695,
            0.02200119011104107,
            -0.006927222944796085,
            -0.013447968289256096,
            0.012346907518804073,
            -0.013186987489461899,
            0.01092540379613638,
            0.011377409100532532,
            -0.02749202586710453,
            -0.005357617046684027,
            0.021487217396497726,
            -0.017615456134080887,
            0.011961596086621284,
            -0.0010924162343144417,
            0.0011914584320038557,
            0.020736975595355034,
            -0.003073235973715782,
            0.041320495307445526,
            0.009844728745520115,
            -0.020765798166394234,
            -0.009763654321432114,
            -0.0004490641877055168,
            0.02861301600933075,
            0.02219272404909134,
            -0.012466257438063622,
            0.01879984140396118,
            -0.01189334411174059,
            -0.00049325090367347,
            -0.015135777182877064,
            0.002769783139228821,
            0.007866909727454185,
            0.015376828610897064,
            0.034543249756097794,
            0.01816120557487011,
            -0.020042134448885918,
            -0.00982584711164236,
            -2.7635929654934444e-05,
            0.02005891688168049,
            0.030857611447572708,
            0.0021047836635261774,
            0.005343289580196142,
            0.020486807450652122,
            -0.024300483986735344,
            0.0005567748448811471,
            -0.012076015584170818,
            -0.0143421096727252,
            -0.031199149787425995,
            0.008363003842532635,
            0.025385230779647827,
            0.003272952977567911,
            0.009240604005753994,
            0.02520911954343319,
            0.00788609404116869,
            0.003826783737167716,
            -0.010888834483921528,
            -0.005883080884814262,
            -0.017479125410318375,
            -0.0267876535654068,
            -0.008516422472894192,
            0.028779195621609688,
            0.010624835267663002,
            0.009742730297148228,
            -0.0192177165299654,
            -0.007316323928534985,
            0.014492672868072987,
            0.05027999356389046,
            0.013152659870684147,
            -0.0016166855348274112,
            -0.023274283856153488,
            -0.022953256964683533,
            -0.003652013838291168,
            -0.02064822055399418,
            -0.01715395413339138,
            0.01767301745712757,
            0.013794722966849804,
            0.01483409944921732,
            0.03664885833859444,
            0.028555210679769516,
            0.0064771948382258415,
            0.024801060557365417,
            0.010441053658723831,
            -0.028665034100413322,
            0.0019576495978981256,
            -0.020921755582094193,
            0.015972699970006943,
            0.004311859142035246,
            0.0016021507326513529,
            0.0202433280646801,
            -0.016004176810383797,
            -0.015109657309949398,
            0.028444668278098106,
            -0.009799656458199024,
            0.00241444306448102,
            0.018481425940990448,
            -0.006321433931589127,
            -0.02352745458483696,
            -0.0019956256728619337,
            0.00910900067538023,
            0.031488072127103806,
            0.001256851595826447,
            -0.021228814497590065,
            0.017294544726610184,
            0.016129810363054276,
            -0.014416195452213287,
            0.01139457244426012,
            -0.01406857930123806,
            -0.026079213246703148,
            -0.026350710541009903,
            -0.02202283777296543,
            0.042315710335969925,
            0.006153046619147062,
            0.024363314732909203,
            -0.0009694425971247256,
            -0.02334717847406864,
            -0.006347887217998505,
            0.027774319052696228,
            -0.017405826598405838,
            0.013576922938227654,
            0.03925289958715439,
            -0.020260177552700043,
            -0.023651771247386932,
            0.0259177777916193,
            0.020047931000590324,
            -0.029813606292009354,
            0.0023949858732521534,
            -0.02349577285349369,
            0.026684792712330818,
            0.004973273724317551,
            -0.016688551753759384,
            -0.026372095569968224,
            0.01737598516047001,
            -0.02272108569741249,
            -0.018852252513170242,
            -0.013210365548729897,
            -0.0017626355402171612,
            0.00027161548496223986,
            -0.009019342251121998,
            -0.010303121991455555,
            -0.027823198586702347,
            -0.006124382838606834,
            0.025972135365009308,
            -0.022020380944013596,
            0.015836691483855247,
            0.004673817660659552,
            -0.03144317865371704,
            -0.008506917394697666,
            0.02345612458884716,
            0.01395356748253107,
            -0.03649592399597168,
            -0.011013212613761425,
            0.012391307391226292,
            -0.010016637854278088,
            0.0027110734954476357,
            0.027304796501994133,
            0.03168603777885437,
            0.011566365137696266,
            0.014645518735051155,
            -0.031808800995349884,
            0.004892157856374979,
            -0.010575909167528152,
            0.023268263787031174,
            0.01772353984415531,
            -0.0029134482610970736,
            0.019184205681085587,
            0.03354422003030777,
            0.026272304356098175,
            -0.011228091083467007,
            0.01836712472140789,
            0.031189458444714546,
            -0.01642933487892151,
            0.026048356667160988,
            0.027206340804696083,
            0.036431293934583664,
            0.02323850616812706,
            -0.018036384135484695,
            -0.012646100483834743,
            0.0238436758518219,
            -0.013242295011878014,
            -0.01761646196246147,
            -0.03648386895656586,
            -0.022029653191566467,
            -0.00036010172334499657,
            -0.018290875479578972,
            -0.017629727721214294,
            -0.01663336716592312,
            0.0170154869556427,
            -0.03554033488035202,
            -0.006173649337142706,
            0.030876513570547104,
            0.007399846334010363,
            0.025006769225001335,
            0.008931249380111694,
            0.03121226467192173,
            0.00519506074488163,
            0.03523062914609909,
            -0.0033731949515640736,
            0.00041716150008141994,
            -0.020646480843424797,
            0.011331469751894474,
            -0.04156946763396263,
            0.013875486329197884,
            0.011446129530668259,
            -0.02981758676469326,
            0.009857314638793468,
            0.009114365093410015,
            0.010171985253691673,
            -0.029418878257274628,
            0.01611308939754963,
            -0.016956187784671783,
            -0.0034979498013854027,
            0.01619947887957096,
            -0.013624788261950016,
            0.009505701251327991,
            0.0157439224421978,
            0.02714185230433941,
            -0.00954208243638277,
            0.01377764344215393,
            0.0335501953959465,
            -0.011470338329672813,
            0.013297689147293568,
            0.009681531228125095,
            -0.012444273568689823,
            0.030966220423579216,
            0.025826551020145416,
            0.05467119812965393,
            0.005523609463125467,
            -0.004253362771123648,
            -0.009374394081532955,
            0.02949080616235733,
            -0.010684686712920666,
            -0.011434420943260193,
            -0.01814354956150055,
            0.02354647032916546,
            -0.018334977328777313,
            0.025485482066869736,
            -0.031444001942873,
            0.0007034528534859419,
            0.013512388803064823,
            0.004872680176049471,
            0.020602906122803688,
            0.03790324553847313,
            0.017278941348195076,
            -0.0008511202177032828,
            -0.020184092223644257,
            -0.011890516616404057,
            -0.014780203811824322,
            0.028191013261675835,
            -0.027894215658307076,
            0.01757308654487133,
            0.01839173398911953,
            0.028820503503084183,
            0.013658685609698296,
            -0.013914022594690323,
            0.029200008139014244,
            0.005950452759861946,
            0.019188452512025833,
            -0.02288513258099556,
            0.004444671794772148,
            -0.017604459077119827,
            0.012643751688301563,
            -0.009892528876662254,
            0.018984947353601456,
            -0.006062549073249102,
            0.00615617586299777,
            -0.012002592906355858,
            0.019927503541111946,
            0.028247399255633354,
            -0.0016469869296997786,
            -0.01912493072450161,
            -0.029623495414853096,
            0.010769719257950783,
            -0.008659488521516323,
            0.018834080547094345,
            0.03213466703891754,
            -0.010028524324297905,
            -0.031808849424123764,
            -0.02133406139910221,
            -0.026254095137119293,
            0.024328185245394707,
            -0.0001811456895666197,
            -0.01402181014418602,
            0.010761641897261143,
            0.01595296524465084,
            -0.015536239370703697,
            0.025837406516075134,
            0.0011547048343345523,
            -0.0061364187858998775,
            0.043171901255846024,
            -0.022826775908470154,
            0.011423960328102112,
            0.030677782371640205,
            0.014183124527335167,
            -0.0226610004901886,
            0.005835513584315777,
            0.005112482700496912,
            -0.0040168981067836285,
            0.005742852110415697,
            0.026810556650161743,
            -0.015483113937079906,
            0.004879047628492117,
            -0.03226305916905403,
            -0.02020341530442238,
            0.011876995675265789,
            0.01214397232979536,
            -0.03444887325167656,
            0.024313343688845634,
            0.03358960524201393,
            -0.04116060584783554,
            0.02715829573571682,
            -0.010497482493519783,
            -0.010720508173108101,
            0.02823049947619438,
            0.00348495552316308,
            0.007044409401714802,
            -0.00967989582568407,
            0.00703702075406909,
            -0.010065941140055656,
            -0.02660961076617241,
            -0.024638084694743156,
            0.00969309825450182,
            -0.017712237313389778,
            -0.004079650621861219,
            0.005028523039072752,
            0.010185067541897297,
            0.02870226837694645,
            0.015284170396625996,
            0.010097607038915157,
            0.012050741352140903,
            0.022085312753915787,
            0.0066469451412558556,
            -0.004209006205201149,
            -0.02022530511021614,
            -0.030523376539349556,
            -0.017694512382149696,
            0.006652070675045252,
            0.01536580454558134,
            -0.021752815693616867,
            0.019249729812145233,
            -0.014413830824196339,
            -0.028908666223287582,
            0.0037252395413815975,
            -0.025027986615896225,
            0.020017309114336967,
            0.014352789148688316,
            0.029887856915593147,
            -0.022717075422406197,
            -0.01452413946390152,
            0.020495209842920303,
            -0.025549454614520073,
            -0.016121577471494675,
            0.031587909907102585,
            0.024640988558530807,
            -0.013431012630462646,
            -0.036301884800195694,
            -0.013079957105219364,
            0.008169358596205711,
            0.006733683869242668,
            -0.015652524307370186,
            0.022562330588698387,
            0.02325240708887577,
            -0.015869850292801857,
            0.005809070076793432,
            0.01678462326526642,
            -0.009569731540977955,
            0.012873317115008831,
            -0.006924714893102646,
            0.02765021100640297,
            0.029258158057928085,
            -0.012585003860294819,
            0.012785079888999462,
            0.004940524697303772,
            0.03486333414912224,
            -0.016564177349209785,
            0.024493401870131493,
            -0.0035454537719488144,
            0.0008452116744592786,
            0.024403156712651253,
            -0.017215780913829803,
            0.027262240648269653,
            0.0008974703378044069,
            -0.009060083888471127,
            -0.00981519091874361,
            0.01956351101398468,
            -0.016803186386823654,
            0.026444753631949425,
            -0.05035246163606644,
            -0.0010411642724648118,
            -0.0017688545631244779,
            -0.004645595792680979,
            -0.02018629014492035,
            0.03125520423054695,
            -0.007211372256278992,
            0.002537054941058159,
            -0.02758832648396492,
            -0.0059818546287715435,
            0.03094577044248581,
            0.03406799957156181,
            0.005308173131197691,
            0.00017417986236978322,
            -0.02036481723189354,
            0.0048345839604735374,
            0.030616970732808113,
            -0.0039219637401402,
            -0.01669001393020153,
            -0.00883979257196188,
            -0.023810377344489098,
            -0.0005123971495777369,
            -0.021055253222584724,
            0.0051667215302586555,
            0.03281761705875397,
            0.0037418603897094727,
            0.020197801291942596,
            0.014069163240492344,
            0.025423988699913025,
            0.014501024968922138,
            0.020145829766988754,
            0.012754336930811405,
            -0.004632291384041309,
            0.009737665764987469,
            0.018665984272956848,
            -0.013121305964887142,
            0.021959906443953514,
            0.026215311139822006,
            0.033493973314762115,
            0.025817671790719032,
            0.0063203126192092896,
            0.011127934791147709,
            -0.007186889182776213,
            0.026973536238074303,
            0.026296813040971756,
            0.012754707597196102,
            -0.024090128019452095,
            -0.008371556177735329,
            0.014203514903783798,
            -0.0011451407335698605,
            -0.016119051724672318,
            -0.028785355389118195,
            0.00976387970149517,
            -0.008038659580051899,
            -0.014758756384253502,
            -0.0058517069555819035,
            -0.022506054490804672,
            -0.02518121711909771,
            0.015878021717071533,
            0.02600094862282276,
            -0.0176292285323143,
            -0.02394523099064827,
            -0.00427828636020422,
            -0.003923322074115276,
            -0.025018885731697083,
            -0.0008777623879723251,
            0.016102125868201256,
            0.014581773430109024,
            0.029218953102827072,
            -0.0014197095297276974,
            0.006633758544921875,
            -0.012193200178444386,
            -0.0076105305925011635,
            0.03535715118050575,
            0.0034720622934401035,
            -0.024829095229506493,
            -0.0001488680427428335,
            -0.030561313033103943,
            0.01595456711947918,
            -0.030496079474687576,
            0.03737185522913933,
            0.006812078878283501,
            0.023905472829937935,
            0.019473154097795486,
            0.030071208253502846,
            0.016729574650526047,
            0.01837063953280449,
            -0.018963851034641266,
            0.017762914299964905,
            0.008874389342963696,
            0.02719353511929512,
            -0.0009055351838469505,
            -0.01842692494392395,
            0.01945444755256176,
            -0.014981389977037907,
            -0.0056615849025547504,
            0.03947131708264351,
            0.022458121180534363,
            0.00022354107932187617,
            0.03634857386350632,
            0.011354433372616768,
            -0.0003314747882541269,
            0.00769503228366375,
            0.0066696046851575375,
            0.023149505257606506,
            0.016498582437634468,
            0.012180974707007408,
            0.02591419778764248,
            0.015824085101485252,
            -0.015620572492480278,
            -0.026554254814982414,
            0.01640508882701397,
            -0.011066598817706108,
            0.0008571054204367101,
            0.04193168878555298,
            0.023893915116786957,
            0.017361899837851524,
            0.004585463088005781,
            0.0015024748863652349,
            0.03522011637687683,
            0.026905454695224762,
            -0.0026631257496774197,
            -0.0036477195098996162,
            -0.018034527078270912,
            0.03091391734778881,
            -0.033337682485580444,
            0.0017291606636717916,
            0.019547797739505768,
            -0.03110717050731182,
            -0.0155510064214468,
            0.015637608245015144,
            0.0074840509332716465,
            -0.022893335670232773,
            0.007776456885039806,
            -0.006475724279880524,
            -0.03183196112513542,
            0.0347832515835762,
            -0.00427705654874444,
            0.0025946847163140774,
            -0.016280535608530045,
            -0.016802752390503883,
            0.030895961448550224,
            0.008206340484321117,
            -0.030145859345793724,
            -0.01136817503720522,
            -0.005295787006616592,
            0.0001566829887451604,
            -0.0036516825202852488,
            0.029845986515283585,
            0.034322626888751984,
            -0.0023622100707143545,
            -0.019873756915330887,
            -0.012096358463168144,
            -0.008638243190944195,
            -0.02521514520049095,
            0.00012168394459877163,
            -0.02122809737920761,
            0.012409810908138752,
            0.018061906099319458,
            0.01956416293978691,
            -0.013179255649447441,
            -0.004107664339244366,
            -0.02382615953683853,
            0.010515290312469006,
            0.017837347462773323,
            -0.024182617664337158,
            0.025653835386037827,
            0.0381552018225193,
            -0.005123564973473549,
            0.010153275914490223,
            0.02944333851337433,
            0.037838246673345566,
            -0.029460931196808815,
            -0.022888632491230965,
            0.0010345790069550276,
            -0.03962978720664978,
            0.009722642600536346,
            -0.0008348679402843118,
            -0.0017000635853037238,
            0.027073901146650314,
            0.000871472351718694,
            0.0013187670847401023,
            0.006495872512459755,
            0.016426993533968925,
            0.0036735793109983206,
            0.015245751477777958,
            -0.006089529953896999,
            0.010408850386738777,
            0.0002973001392092556,
            0.02209063619375229,
            -0.023950673639774323,
            0.006694637704640627,
            0.022460779175162315,
            0.0007249954505823553,
            0.012438416481018066,
            0.014637595042586327,
            -0.021502522751688957,
            0.025210432708263397,
            0.025409523397684097,
            0.007066999562084675,
            0.03301559016108513,
            0.03191405162215233,
            -0.015077892690896988,
            0.01659672148525715,
            -0.011361873708665371,
            0.03204643353819847,
            -0.0012437214609235525,
            -0.024939851835370064,
            -0.030233319848775864,
            -0.005914250388741493,
            -0.011872554197907448,
            -0.03146427497267723,
            0.005267703905701637,
            0.0248605627566576,
            -0.022127429023385048,
            0.019925246015191078,
            0.008338376879692078,
            0.02694990299642086,
            0.015955477952957153,
            0.0028291891794651747,
            0.02227315492928028,
            -0.008724254556000233,
            0.0045516095124185085,
            -0.02731217071413994,
            0.01426253654062748,
            -0.025426890701055527,
            0.013290577568113804,
            7.237346289912239e-05,
            0.026302188634872437,
            -0.01922132819890976,
            0.02933870442211628,
            0.008651717565953732,
            0.005379288457334042,
            -0.012606033124029636,
            -0.013818432576954365,
            -0.01965021900832653,
            0.00632525933906436,
            0.017889702692627907,
            0.02235192060470581,
            0.024631449952721596,
            5.0568098231451586e-05,
            -0.03787524998188019,
            0.008790701627731323,
            0.017712395638227463,
            0.0381229929625988,
            -0.03537246212363243,
            0.0141139579936862,
            0.007770643103867769,
            0.023926587775349617,
            -0.020107189193367958,
            -0.021232707425951958,
            0.005027941893786192,
            0.0017507008742541075,
            0.00898147001862526,
            -0.004698458593338728,
            -0.015887318179011345,
            0.014212447218596935,
            0.010365619324147701,
            0.0003707935393322259,
            0.028244508430361748,
            -0.024425029754638672,
            0.0031774532981216908,
            -5.292879359330982e-05,
            -0.024523286148905754,
            -0.012620261870324612,
            0.014121328480541706,
            -0.02788284234702587,
            -0.0036201379261910915,
            -0.00565911503508687,
            0.022804919630289078,
            -0.02447228506207466,
            0.010448331013321877,
            -0.0176426749676466,
            -0.018344642594456673,
            0.007533195894211531,
            -0.008798517286777496,
            0.006146661005914211,
            0.029791539534926414,
            -0.02304466813802719,
            -0.0362895131111145,
            -0.006146769039332867,
            -0.006392247974872589,
            -0.0011398829519748688,
            -0.005585741717368364,
            0.028361916542053223,
            -0.010871877893805504,
            -0.004404778126627207,
            0.026090234518051147,
            -0.012983900494873524,
            -0.019064810127019882,
            -0.01610146090388298,
            0.005410402547568083,
            0.02475375309586525,
            0.022846313193440437,
            -0.02376086451113224,
            0.006860210560262203,
            -0.0053289164789021015,
            -0.01469503715634346,
            0.037965402007102966,
            -0.013517295010387897,
            0.01807381398975849,
            0.006500976160168648,
            0.0341157391667366,
            -0.018871087580919266,
            0.0235609021037817,
            -0.015336616896092892,
            0.015689609572291374,
            -0.012198135256767273,
            -0.012647868134081364,
            -0.0328751839697361,
            0.023723812773823738,
            0.0018369393656030297,
            -0.029587235301733017,
            -0.029195941984653473,
            -0.007854261435568333,
            0.03267179802060127,
            -0.0031792009249329567,
            0.002283553360030055,
            -0.023108290508389473,
            0.009432178921997547,
            0.011445311829447746,
            -0.023396819829940796,
            -0.006669458467513323,
            -0.02567359246313572,
            -0.013613510876893997,
            -0.026071688160300255,
            -0.018685713410377502,
            0.03324020281434059,
            0.01745363511145115,
            0.025053510442376137,
            -0.010765300132334232,
            0.010970739647746086,
            0.029130281880497932,
            0.022810397669672966,
            -0.01289051678031683,
            0.015718940645456314,
            0.022646408528089523,
            -0.02759922854602337,
            -0.0026812185533344746,
            0.007803419604897499,
            0.026438703760504723,
            0.030994068831205368,
            -0.03055030293762684,
            -0.01688418723642826,
            0.009814751334488392,
            -0.031577788293361664,
            -0.008223927579820156,
            0.0029038600623607635,
            0.0035871504805982113,
            0.0035340217873454094,
            0.017392141744494438,
            -0.010811793617904186,
            -0.01685558445751667,
            -0.022338906303048134,
            0.009430755861103535,
            -0.022306332364678383,
            -0.02168220654129982,
            -0.0012322540860623121,
            -0.03347215801477432,
            -0.026959534734487534,
            -0.030550552532076836,
            -0.013434065505862236,
            0.025660976767539978,
            0.0023917146027088165,
            -0.0009115897701121867,
            -0.027061108499765396,
            -0.017406191676855087,
            -0.010488685220479965,
            0.04702519625425339,
            -0.011351187713444233,
            0.0030850146431475878,
            0.035108212381601334,
            0.015133793465793133,
            0.012648161500692368,
            0.03165406361222267,
            0.0015703419921919703,
            -0.019567344337701797,
            0.027575546875596046,
            0.01357717253267765,
            0.015660196542739868,
            0.021133624017238617,
            0.00854477658867836,
            -0.012991683557629585,
            -0.005073350854218006,
            0.033872224390506744,
            -0.016643259674310684,
            -0.012318880297243595,
            0.003551935311406851,
            -0.00921643991023302,
            -0.018033770844340324,
            -0.004154525231570005,
            0.00504459161311388,
            0.021428674459457397,
            0.028013892471790314,
            -0.01185015682131052,
            -0.025426317006349564,
            0.006402002647519112,
            -0.014215193688869476,
            0.024131860584020615,
            -0.005606058053672314,
            -0.01834123395383358,
            0.006986786145716906,
            -0.04372579604387283,
            -0.01986020617187023,
            0.03093981184065342,
            0.03490674868226051,
            0.02082858420908451,
            -0.026833420619368553,
            0.017200984060764313,
            0.02964264340698719,
            -0.017763258889317513,
            0.029312407597899437,
            0.010903545655310154,
            -0.014668385498225689,
            -0.015421605668962002,
            0.006947315763682127,
            0.001733417040668428,
            -0.008393042720854282,
            0.010048099793493748,
            0.0053468425758183,
            0.024186210706830025,
            0.018634354695677757,
            0.0251979511231184,
            0.0271721500903368,
            0.03296356275677681,
            -0.00814107060432434,
            -0.013662351295351982,
            0.01758875697851181,
            -0.0034392490051686764,
            -0.03571467101573944,
            -0.015996001660823822,
            0.011449025012552738,
            0.02669372409582138,
            -0.01472848653793335,
            -0.029361821711063385,
            -0.01619771681725979,
            -0.005267350003123283,
            -0.027491886168718338,
            0.0051404801197350025,
            0.030591454356908798,
            -0.00013602474064100534,
            0.0225603599101305,
            0.017706891521811485,
            -0.008654621429741383,
            -0.023724978789687157,
            -0.02379836142063141,
            0.03352118656039238,
            -0.011319838464260101,
            0.0030854863580316305,
            -0.024965643882751465,
            -0.020506439730525017,
            -0.0030774534679949284,
            0.005823703017085791,
            0.027489956468343735,
            -0.02041970007121563,
            -0.0034409440122544765,
            0.02502741850912571,
            0.008777912706136703,
            -0.0073949191719293594,
            -0.006454454734921455,
            -0.005369830410927534,
            -0.004865332506597042,
            -0.018531503155827522,
            0.02914102002978325,
            -0.004118739161640406,
            -0.0007149973534978926,
            0.03314775228500366,
            0.029022471979260445,
            -0.024771401658654213,
            0.0028746607713401318,
            -0.002092042937874794,
            0.005546217784285545,
            0.01668526791036129,
            0.038814477622509,
            -0.02204155921936035,
            0.01597980409860611,
            0.006144949235022068,
            0.0001004183359327726,
            0.010936994105577469
        ],
        [
            0.0285069290548563,
            -0.02390487678349018,
            -0.009332125075161457,
            -0.023692462593317032,
            0.028009599074721336,
            -0.004332061391323805,
            -0.011380692012608051,
            0.005299424286931753,
            -0.004727992229163647,
            -0.006301002111285925,
            -0.027117302641272545,
            0.022636177018284798,
            0.022507954388856888,
            -0.005590558052062988,
            -0.025199679657816887,
            0.009991191327571869,
            -0.025704246014356613,
            -0.00043709666351787746,
            0.022249139845371246,
            -0.02310747094452381,
            0.0023244107142090797,
            -0.018979819491505623,
            0.012643369846045971,
            0.007823978550732136,
            -0.01232257578521967,
            0.02273683063685894,
            -0.003478027880191803,
            -0.01708412542939186,
            -0.02838311344385147,
            0.006582674104720354,
            0.012130986899137497,
            -0.007370648439973593,
            -0.019295403733849525,
            -0.012254489585757256,
            -0.006892495322972536,
            -0.029752463102340698,
            0.017257461324334145,
            -0.003555410308763385,
            -0.008869440294802189,
            0.01893969066441059,
            0.0003164623340126127,
            -0.013017223216593266,
            0.03015298582613468,
            -0.027667324990034103,
            -0.01669410988688469,
            -0.0010799471056088805,
            0.018840277567505836,
            -0.0009860516292974353,
            -0.026728685945272446,
            0.018251262605190277,
            0.029282113537192345,
            -0.0035366439260542393,
            -0.009228297509253025,
            -0.02710236795246601,
            0.025095421820878983,
            -0.0018625520169734955,
            0.016599418595433235,
            -0.004414164926856756,
            -0.010128987021744251,
            -0.012619213201105595,
            -0.0044088042341172695,
            -0.03210236132144928,
            0.02050977759063244,
            -0.00943665485829115,
            0.008329179137945175,
            0.01804124377667904,
            0.010038108564913273,
            -0.009563658386468887,
            0.010631736367940903,
            -0.0267235916107893,
            0.009546617045998573,
            0.0021637254394590855,
            0.029592448845505714,
            0.02131631411612034,
            -0.03161386772990227,
            -0.013578886166214943,
            0.020096899941563606,
            -0.01423153281211853,
            0.002623923122882843,
            -0.025228004902601242,
            0.015867048874497414,
            -0.0026901380624622107,
            -0.03164941444993019,
            -0.02169967256486416,
            0.011748257093131542,
            0.03220704197883606,
            0.002244093455374241,
            -0.0026530243922024965,
            0.022361598908901215,
            0.015024915337562561,
            -0.020066916942596436,
            0.009102595038712025,
            -0.009593496099114418,
            0.008394267410039902,
            0.01196194626390934,
            -0.02822052873671055,
            0.006613176316022873,
            -0.02364019677042961,
            -0.008440680801868439,
            -0.017156312242150307,
            0.024499785155057907,
            -0.0076450565829873085,
            0.027371343225240707,
            0.022574391216039658,
            -0.012496432289481163,
            -0.022725488990545273,
            -0.008541581220924854,
            -0.030518729239702225,
            0.0041829985566437244,
            0.018165811896324158,
            -0.02062135748565197,
            -0.002860315376892686,
            -0.027982529252767563,
            0.011007155291736126,
            0.011614244431257248,
            0.027924975380301476,
            -0.024089260026812553,
            -0.01656316965818405,
            -0.00521745253354311,
            -0.00659025227651,
            -0.022748645395040512,
            0.016420120373368263,
            -0.005943034775555134,
            -0.020040038973093033,
            0.0047348057851195335,
            0.002099656732752919,
            -0.0290916059166193,
            0.024398917332291603,
            0.027377238497138023,
            -0.029760098084807396,
            0.00753180542960763,
            -0.018277302384376526,
            0.028080690652132034,
            0.01387302577495575,
            0.008930142037570477,
            -0.012281017377972603,
            0.02045626938343048,
            -0.024984322488307953,
            -0.030094759538769722,
            0.02100040391087532,
            -0.027331722900271416,
            0.004692401271313429,
            0.015182155184447765,
            -0.03219500556588173,
            -0.01243666559457779,
            0.005914620589464903,
            -0.024566398933529854,
            -0.01205457840114832,
            0.0032670532818883657,
            0.028262292966246605,
            -0.021865246817469597,
            0.008051127195358276,
            0.014469261281192303,
            -0.020775439217686653,
            -0.015405046753585339,
            0.011086737737059593,
            -0.020510464906692505,
            0.006808544509112835,
            -0.025464022532105446,
            0.018615426495671272,
            -0.019825302064418793,
            0.02631247788667679,
            0.015335212461650372,
            -0.010413461364805698,
            -0.03091306984424591,
            0.02017548680305481,
            0.00406760536134243,
            0.014715525321662426,
            0.015805799514055252,
            0.008537238463759422,
            0.013216318562626839,
            0.021958045661449432,
            0.013554404489696026,
            0.020244747400283813,
            -0.02049965411424637,
            0.026266181841492653,
            -0.02758941426873207,
            0.009673334658145905,
            0.020174244418740273,
            -0.020267805084586143,
            0.004369296133518219,
            -0.02176264300942421,
            0.0017711706459522247,
            -0.027527807280421257,
            -0.014974670484662056,
            -0.024555476382374763,
            -0.026062609627842903,
            -0.005960711743682623,
            0.0008246850338764489,
            -0.014362132176756859,
            -0.022500434890389442,
            -0.02652558870613575,
            -0.013848882168531418,
            -0.017729321494698524,
            -0.030057091265916824,
            -0.004904707428067923,
            -0.009227371774613857,
            -0.016017494723200798,
            0.01400286890566349,
            0.021872900426387787,
            -0.013629765249788761,
            -0.01444618497043848,
            0.014220536686480045,
            -0.03053426183760166,
            0.007899267598986626,
            0.00637544970959425,
            -0.026925891637802124,
            -0.016270268708467484,
            0.01674371398985386,
            -0.023611480370163918,
            0.010626032017171383,
            0.024391133338212967,
            -0.024127278476953506,
            -0.007306847721338272,
            -0.026688629761338234,
            -0.026394350454211235,
            -0.01072989497333765,
            -0.011286336928606033,
            -0.024643274024128914,
            -0.02824101783335209,
            0.013271154835820198,
            -0.012972951866686344,
            0.020590990781784058,
            -0.024511968716979027,
            0.001137970364652574,
            -0.0025886259973049164,
            0.018399136140942574,
            -0.005718016065657139,
            -0.029286688193678856,
            -0.0289569441229105,
            0.01760515756905079,
            -0.01063381228595972,
            -0.008528195321559906,
            -0.014578749425709248,
            0.014486122876405716,
            0.015561259351670742,
            -0.028108133003115654,
            -0.029048165306448936,
            -0.01900726743042469,
            -0.025865690782666206,
            -0.013387827202677727,
            -0.02561195380985737,
            -0.003139388980343938,
            0.005668057594448328,
            -0.009636588394641876,
            -0.008075953461229801,
            0.029243478551506996,
            -0.011139538139104843,
            -0.010089912451803684,
            0.01555082481354475,
            -0.004610214848071337,
            0.0012610352132469416,
            0.014843294396996498,
            -0.029117528349161148,
            0.00047905038809403777,
            -0.006407317239791155,
            -0.008209832943975925,
            0.028710059821605682,
            -0.0014931239420548081,
            0.028754407539963722,
            -0.021145597100257874,
            0.011161565780639648,
            -0.022025858983397484,
            -0.005605485290288925,
            -0.01986534707248211,
            0.026745948940515518,
            -0.009094363078474998,
            -0.021262282505631447,
            -0.023595429956912994,
            -0.009701830334961414,
            -0.02044404298067093,
            -0.017831210047006607,
            0.02254285104572773,
            0.024115823209285736,
            0.018401093780994415,
            -0.031938131898641586,
            0.015167541801929474,
            0.005797623656690121,
            -0.00023404114472214133,
            0.021088436245918274,
            0.012951746582984924,
            0.0044691115617752075,
            -0.0099788848310709,
            0.0017722385236993432,
            -0.001992241945117712,
            0.01233054231852293,
            -0.004940603859722614,
            0.001692212070338428,
            -0.031570445746183395,
            0.006997304502874613,
            0.0009544629137963057,
            0.02251415327191353,
            0.014021532610058784,
            -0.0014564943267032504,
            0.004535399843007326,
            -0.018615316599607468,
            -0.0127241350710392,
            -0.012536485679447651,
            0.019300518557429314,
            0.007151845842599869,
            0.008516536094248295,
            0.015566177666187286,
            -0.0017099331598728895,
            -0.02877231128513813,
            -0.019808979704976082,
            0.02857884205877781,
            0.014698799699544907,
            0.0156401339918375,
            -0.0172694344073534,
            0.012978143990039825,
            0.0068962909281253815,
            -0.010939336381852627,
            0.022012898698449135,
            -0.016562631353735924,
            0.015688970685005188,
            0.02283819392323494,
            0.025291165336966515,
            0.004221285227686167,
            -0.008671428076922894,
            -0.015461955219507217,
            0.019027838483452797,
            -0.021166183054447174,
            -0.015042346902191639,
            -0.008215170353651047,
            -0.02721644751727581,
            0.028950335457921028,
            -0.01833702251315117,
            -0.029048455879092216,
            0.026959313079714775,
            -0.02013314515352249,
            -0.022187810391187668,
            0.025812741369009018,
            0.014759968034923077,
            0.001962953247129917,
            -0.00842706486582756,
            -0.0029392018914222717,
            -0.02199658565223217,
            -0.005012758541852236,
            -0.026135964319109917,
            -0.0013572322204709053,
            -0.0169539675116539,
            -0.022246375679969788,
            -0.0003205037210136652,
            0.004236327949911356,
            0.010304204188287258,
            -0.008094996213912964,
            0.014696856960654259,
            0.009997944347560406,
            0.00919809378683567,
            0.03081386722624302,
            0.006241974886506796,
            -0.011099889874458313,
            0.011366412974894047,
            -0.030372589826583862,
            -0.0008761442149989307,
            -0.020312273874878883,
            -0.016231659799814224,
            -0.014917602762579918,
            -0.01493372954428196,
            -0.003251881804317236,
            3.3459051337558776e-05,
            0.003652813844382763,
            -0.01606108993291855,
            -0.021397924050688744,
            0.014376131817698479,
            -0.017579460516572,
            -0.030809443444013596,
            0.006349899340420961,
            -0.008890447206795216,
            0.014766479842364788,
            -0.01994412951171398,
            -0.003044918878003955,
            0.004995320457965136,
            -0.0042195506393909454,
            0.026435870677232742,
            -0.021682318300008774,
            0.0008827619603835046,
            -0.013397975824773312,
            0.0014564805896952748,
            -0.013626948930323124,
            -0.009063933044672012,
            -0.024264661595225334,
            -0.011090640909969807,
            0.01836717315018177,
            -0.007825840264558792,
            0.0027930848300457,
            0.02740056812763214,
            0.0059605552814900875,
            -0.015111629851162434,
            -0.010611359030008316,
            0.025360314175486565,
            0.02682013064622879,
            -0.02173691801726818,
            0.018763674423098564,
            -0.0013234992511570454,
            0.002624074462801218,
            -0.023661287501454353,
            0.006352495402097702,
            0.020520636811852455,
            -0.0039503020234405994,
            0.010428653098642826,
            -0.02119593881070614,
            -0.006157365627586842,
            -0.02097811922430992,
            0.008620012551546097,
            0.02454592101275921,
            0.02701484225690365,
            -0.010648678988218307,
            -0.026405325159430504,
            -0.020117130130529404,
            -0.018916860222816467,
            -0.018140384927392006,
            -0.029000472277402878,
            0.010120140388607979,
            -0.012927684932947159,
            -0.019563643261790276,
            -0.01136741042137146,
            -0.02306579053401947,
            -0.014422339387238026,
            -0.02889496646821499,
            0.011194810271263123,
            -0.015172947198152542,
            0.01650053635239601,
            0.025186045095324516,
            0.021022062748670578,
            -0.020343441516160965,
            -0.009217429906129837,
            -0.027411796152591705,
            -0.021861376240849495,
            -0.02605270966887474,
            0.01790102943778038,
            0.025339769199490547,
            -0.006935042794793844,
            -0.01925617642700672,
            -0.030413156375288963,
            -0.002003232715651393,
            -0.015498390421271324,
            -0.023823164403438568,
            0.011568717658519745,
            -0.0118947708979249,
            -0.01056390255689621,
            0.014164527878165245,
            0.004557858221232891,
            -0.027652543038129807,
            -0.029429985210299492,
            0.012738390825688839,
            0.0223368052393198,
            0.015358642674982548,
            -0.024648813530802727,
            0.0053559765219688416,
            0.019402049481868744,
            -0.01471368782222271,
            0.026892278343439102,
            0.02369009144604206,
            -0.022678660228848457,
            -0.010817993432283401,
            0.021130146458745003,
            0.009987137280404568,
            -0.0313725583255291,
            -0.021587714552879333,
            -0.030229227617383003,
            0.02726825512945652,
            -0.022849345579743385,
            0.0051350644789636135,
            -0.031160293146967888,
            -0.015187649056315422,
            0.005581414792686701,
            0.028540270403027534,
            0.019262271001935005,
            -0.02197028324007988,
            -0.01981016993522644,
            -0.002002985682338476,
            -0.026723116636276245,
            -0.013934380374848843,
            0.026408882811665535,
            -0.01514768972992897,
            -0.017833735793828964,
            -0.0013678229879587889,
            -0.030713025480508804,
            -0.023131705820560455,
            -0.02083733305335045,
            0.023531580343842506,
            -0.022144440561532974,
            -0.026571298018097878,
            0.017798110842704773,
            0.023268571123480797,
            -0.0210815891623497,
            0.02842840738594532,
            -0.018686100840568542,
            0.007746309973299503,
            -0.019353589043021202,
            -0.010360476560890675,
            0.00947895459830761,
            0.010928750038146973,
            -0.026883438229560852,
            -0.011255810037255287,
            -0.003515050746500492,
            -0.02442198432981968,
            -0.03215843066573143,
            0.018036900088191032,
            0.0025595752522349358,
            0.02165374346077442,
            0.010293216444551945,
            0.008213012479245663,
            0.026885785162448883,
            -0.029802292585372925,
            0.005675388965755701,
            0.026283668354153633,
            0.008868644945323467,
            0.028230782598257065,
            -0.003588497405871749,
            -8.631870150566101e-05,
            0.00606850441545248,
            -0.0043990351259708405,
            0.0035257318522781134,
            -0.0035734327975660563,
            -0.029090818017721176,
            0.02606445923447609,
            -0.021332833915948868,
            -0.01419187430292368,
            0.029992559924721718,
            -0.019745292142033577,
            0.028912873938679695,
            -0.018363280221819878,
            0.014452311210334301,
            -0.01750156655907631,
            -0.02214430645108223,
            -0.011054587550461292,
            -0.01594136469066143,
            -0.01960112154483795,
            0.01969141513109207,
            0.024265997111797333,
            0.018843455240130424,
            -0.012390327639877796,
            -0.024354327470064163,
            -0.02925022318959236,
            -0.016954204067587852,
            -0.008397417142987251,
            -0.008977603167295456,
            0.003111357567831874,
            0.005357920192182064,
            0.02326771430671215,
            0.027725214138627052,
            -0.024384094402194023,
            0.011477181687951088,
            0.022976389154791832,
            0.017220424488186836,
            -0.030942879617214203,
            0.015642056241631508,
            -0.016470514237880707,
            -0.02742510661482811,
            0.013052764348685741,
            -0.02300463616847992,
            0.023478914052248,
            -0.012809871695935726,
            0.017835713922977448,
            0.014444700442254543,
            0.0022575370967388153,
            -0.014748887158930302,
            -0.00852121226489544,
            0.02198389545083046,
            -0.00847829319536686,
            0.0023829699493944645,
            0.009033123962581158,
            -0.008440201170742512,
            0.01787967048585415,
            0.0003029282670468092,
            0.0007108437130227685,
            -0.028520019724965096,
            -0.019175183027982712,
            0.013651954010128975,
            -0.022485919296741486,
            -0.009731694124639034,
            0.02903221733868122,
            -0.020949330180883408,
            -0.02168773114681244,
            -0.021672772243618965,
            -0.025965560227632523,
            -0.0166800357401371,
            -0.010984738357365131,
            0.011148140765726566,
            -0.025039352476596832,
            -0.0138687239959836,
            0.012366446666419506,
            -0.012449086643755436,
            -0.004111111629754305,
            -0.0005865720449946821,
            0.01803666539490223,
            -0.025010736659169197,
            0.014561413787305355,
            -0.011795544065535069,
            -0.007556011434644461,
            -0.02004178613424301,
            0.009131815284490585,
            0.012965322472155094,
            -0.011290941387414932,
            -0.02180529199540615,
            -0.003422865178436041,
            -0.01957758329808712,
            0.029084462672472,
            0.020510569214820862,
            0.005115344654768705,
            -0.022399328649044037,
            0.024018744006752968,
            0.020944073796272278,
            0.00841363426297903,
            -0.015383110381662846,
            0.029956893995404243,
            -0.008312256075441837,
            -0.021275168284773827,
            -0.025326063856482506,
            0.02880028821527958,
            -0.026962531730532646,
            -0.019512105733156204,
            -0.009655644185841084,
            -0.020033592358231544,
            0.008283368311822414,
            -0.008573304861783981,
            -0.019721966236829758,
            0.015244673937559128,
            -0.0269194133579731,
            0.027301589027047157,
            0.02137572318315506,
            0.01909061335027218,
            0.016155287623405457,
            -0.01185270119458437,
            0.02798747830092907,
            -0.013635100796818733,
            -0.012885370291769505,
            0.009740909561514854,
            -0.012021281756460667,
            -0.02393069677054882,
            0.01971050538122654,
            -0.0263279527425766,
            -0.027503330260515213,
            -0.010225296020507812,
            0.02209317497909069,
            -0.015576989389955997,
            0.002115401905030012,
            0.021168768405914307,
            -0.031243151053786278,
            -0.010581646114587784,
            -0.015834324061870575,
            -0.016530921682715416,
            -0.009336504153907299,
            0.008504374884068966,
            -0.005130378995090723,
            -0.006044980604201555,
            -0.011226830072700977,
            -0.0029472943861037493,
            0.014863710850477219,
            0.012704376131296158,
            0.015163774602115154,
            0.026018202304840088,
            -0.02161998301744461,
            -0.00024707367992959917,
            -0.031568147242069244,
            0.0151069900020957,
            0.022328108549118042,
            -0.00011390592408133671,
            0.0048200334422290325,
            -0.026580549776554108,
            -0.027447039261460304,
            0.0064175864681601524,
            -0.020712295547127724,
            0.009418567642569542,
            -0.007178323809057474,
            0.002532361540943384,
            0.0006842834991402924,
            0.01685386151075363,
            0.018325934186577797,
            0.01879243552684784,
            -0.005296093411743641,
            0.030101554468274117,
            0.0011079227551817894,
            0.026631180197000504,
            0.015276743099093437,
            -0.024080900475382805,
            -0.01790529116988182,
            -0.023988639935851097,
            0.02347855642437935,
            0.00785836111754179,
            0.014524543657898903,
            0.0011775492457672954,
            -0.015764500945806503,
            0.019851520657539368,
            -0.0168448518961668,
            0.0056793950498104095,
            0.009619566611945629,
            0.02243587002158165,
            0.008250989019870758,
            -0.011824540793895721,
            -0.005603112746030092,
            -0.013100420124828815,
            0.02411435730755329,
            -0.0245659202337265,
            -0.009546727873384953,
            0.011707795783877373,
            -0.020806752145290375,
            -0.0015749410958960652,
            -0.0068237148225307465,
            0.001184373628348112,
            0.0035477331839501858,
            0.007387339137494564,
            0.008257102221250534,
            0.025652073323726654,
            -0.02413412742316723,
            -0.0021385925356298685,
            -0.023292310535907745,
            -0.030137524008750916,
            -0.028836069628596306,
            -0.014660060405731201,
            0.016550520434975624,
            -0.001444359659217298,
            0.009933076798915863,
            -0.023858124390244484,
            0.005183582194149494,
            -0.003032047301530838,
            0.019953234121203423,
            -0.008389522321522236,
            -0.03151150420308113,
            -0.030924784019589424,
            -0.019989149644970894,
            -0.017244791612029076,
            0.01669786311686039,
            -0.028131533414125443,
            -0.013829393312335014,
            0.027156813070178032,
            0.02723424881696701,
            -0.03217853605747223,
            -0.025444429367780685,
            -0.005568522959947586,
            0.029316235333681107,
            0.024982815608382225,
            -0.027694564312696457,
            0.026807699352502823,
            -0.014210817404091358,
            0.014820054173469543,
            -0.0220336951315403,
            -0.001613382250070572,
            -0.012129428796470165,
            -0.006180153228342533,
            -0.011194085702300072,
            -0.027569234371185303,
            -0.013330288231372833,
            -0.0013363845646381378,
            -0.02059284597635269,
            -0.02339145913720131,
            0.01598195731639862,
            0.010783063247799873,
            0.02941063791513443,
            0.019449507817626,
            -0.01861088164150715,
            -0.024092353880405426,
            -0.0010828054510056973,
            -0.02834947034716606,
            0.00476845633238554,
            -0.028493063524365425,
            -0.022603390738368034,
            -0.015837080776691437,
            -0.0017589543713256717,
            -0.02422722429037094,
            0.018857700750231743,
            0.01226250734180212,
            0.016320252791047096,
            0.004113443195819855,
            -0.0062362938188016415,
            0.012773381546139717,
            0.018069980666041374,
            -0.011672734282910824,
            0.02894570492208004,
            -0.004359524697065353,
            0.0033222397323697805,
            0.01143055409193039,
            -0.010915027000010014,
            0.005964736454188824,
            -0.0014453391777351499,
            0.028714671730995178,
            0.007695067673921585,
            -0.025215359404683113,
            -0.02706678956747055,
            -0.03119709901511669,
            -0.012212757021188736,
            0.0009772952180355787,
            0.011617998592555523,
            -0.009420838207006454,
            -0.0006042190361768007,
            -0.03184216842055321,
            0.02132435329258442,
            0.017151303589344025,
            0.017519529908895493,
            -0.02311829850077629,
            -0.016569796949625015,
            0.0003604968951549381,
            -0.03202016279101372,
            0.025379421189427376,
            0.0010856642620638013,
            -0.021156489849090576,
            -0.004429691005498171,
            0.02548573911190033,
            0.003306781640276313,
            -0.009121999144554138,
            0.022984391078352928,
            0.007026430685073137,
            0.007118473760783672,
            -0.01664837636053562,
            0.023665085434913635,
            -0.011783012188971043,
            0.010386200621724129,
            0.02277420461177826,
            0.01682455465197563,
            -0.009607289917767048,
            0.019618496298789978,
            0.01274100225418806,
            -0.024657316505908966,
            -0.017400993034243584,
            -0.011870772577822208,
            0.014965544454753399,
            -0.03163934126496315,
            -0.011767805553972721,
            -0.02656741440296173,
            0.017176564782857895,
            -0.013288917951285839,
            0.00652359938248992,
            -0.012575650587677956,
            -0.0034917930606752634,
            0.024364622309803963,
            0.006374190095812082,
            -0.027244990691542625,
            0.0003463657048996538,
            -0.021533560007810593,
            0.002536891493946314,
            -0.014842313714325428,
            -0.0005759122432209551,
            0.0167539082467556,
            -0.017389828339219093,
            0.011042196303606033,
            -0.019826704636216164,
            -0.004634828772395849,
            -0.022027865052223206,
            0.025126082822680473,
            0.020045066252350807,
            0.013914327137172222,
            0.014269976876676083,
            0.02623051218688488,
            0.018439998850226402,
            -0.007888205349445343,
            -0.01615162193775177,
            0.026805484667420387,
            -0.03149872273206711,
            -0.02022395096719265,
            0.004458183888345957,
            -0.026886090636253357,
            0.01601611264050007,
            -0.028017817065119743,
            0.014982514083385468,
            0.007452833000570536,
            -0.024242687970399857,
            0.020612969994544983,
            -0.01504369918256998,
            0.01033584401011467,
            -0.0143757164478302,
            -0.009568661451339722,
            -0.02235443703830242,
            0.015135757625102997,
            -0.018899844959378242,
            0.014347105287015438,
            0.028089839965105057,
            -0.021318167448043823,
            0.012368354946374893,
            -0.009456862695515156,
            -0.000887595524545759,
            -0.010886596515774727,
            0.007522754371166229,
            -0.026012055575847626,
            0.017810586839914322,
            0.02586718089878559,
            0.01000325009226799,
            -0.01323152519762516,
            0.0260505098849535,
            0.02179407700896263,
            -0.023832781240344048,
            0.01385395135730505,
            0.012486479245126247,
            0.017249487340450287,
            -0.027477238327264786,
            -0.003462401917204261,
            0.008705508895218372,
            -0.01168358325958252,
            -0.030181987211108208,
            -0.003059451235458255,
            -0.02962765283882618,
            0.022952599450945854,
            -0.01567063480615616,
            -0.020268112421035767,
            0.020147908478975296,
            -0.022579992190003395,
            0.01513680350035429,
            0.0024235614109784365,
            -0.022378625348210335,
            -0.0199953131377697,
            0.004088911693543196,
            -0.02393255941569805,
            -0.02697359211742878,
            0.004324938636273146,
            -0.014851586893200874,
            -0.004175892099738121,
            0.02243763953447342,
            0.013501394540071487,
            0.014077901840209961,
            -0.025811415165662766,
            0.02519984357059002,
            0.007055813912302256,
            0.022015873342752457,
            0.024655692279338837,
            -0.031586069613695145,
            0.02331012487411499,
            0.009123633615672588,
            0.029891541227698326,
            0.023803452029824257,
            0.012200823985040188,
            0.005353280808776617,
            -0.0035428665578365326,
            0.006456160452216864,
            0.025676310062408447,
            0.028799768537282944,
            0.002115237293764949,
            0.007861289195716381,
            0.027846697717905045,
            -0.012471187859773636,
            0.028386719524860382,
            -0.018567724153399467,
            0.019463658332824707,
            -0.02632293850183487,
            -0.005801319610327482,
            -0.00873768050223589,
            0.016026660799980164,
            0.025079717859625816,
            0.006453673355281353,
            -0.01177940983325243,
            -0.01525251753628254,
            0.005742477253079414,
            0.023508843034505844,
            -0.026955440640449524,
            0.006553728133440018,
            -0.016482045873999596,
            0.00752596789970994,
            0.0033420585095882416,
            0.006821546237915754,
            0.003906408790498972,
            0.028372153639793396,
            -0.025238385424017906,
            -0.0301467627286911,
            -0.0012353408383205533,
            -0.021044936031103134,
            -0.011912197805941105,
            0.013616166077554226,
            0.021753447130322456,
            -0.0006865783361718059,
            -0.0009298744262196124,
            0.008256974630057812,
            0.02222142368555069,
            -0.016146115958690643,
            -0.026383133605122566,
            -0.013243724592030048,
            -0.019150231033563614,
            0.0007028840482234955,
            0.0039225732907652855,
            0.003986297640949488,
            -0.021491479128599167,
            -0.007599675562232733,
            -0.023052256554365158,
            0.007262832019478083,
            -0.00874571967869997,
            0.020311594009399414,
            -0.015306971967220306,
            0.02578994259238243,
            0.02527339570224285,
            -0.001104791066609323,
            -0.008678589947521687,
            -0.023217499256134033,
            -0.012324669398367405,
            0.01842787116765976,
            0.02884148620069027,
            0.020355187356472015,
            0.006289648823440075,
            -0.016734570264816284,
            -0.022728394716978073,
            -0.015477367676794529,
            0.017482494935393333,
            -0.02026471495628357,
            -0.027102509513497353,
            -0.02478427067399025,
            0.017629122361540794,
            0.029312273487448692,
            -0.01387410331517458,
            0.026085443794727325,
            -7.235663815663429e-06,
            -0.021293126046657562,
            0.028734780848026276,
            0.013828090392053127,
            -0.030541028827428818,
            0.012748824432492256,
            0.015156726352870464,
            -0.003277077339589596,
            -0.0057712579146027565,
            -0.024974949657917023,
            0.028833303600549698,
            0.026816776022315025,
            -0.005921453703194857,
            0.006958696059882641,
            0.023278918117284775,
            -0.031141208484768867,
            -0.012646221555769444,
            0.027040936052799225,
            -0.01153636910021305,
            0.01958060823380947,
            -0.0005680032190866768,
            0.030057478696107864,
            0.029172370210289955,
            0.020423080772161484,
            -0.00024086982011795044,
            -0.021776393055915833,
            0.027040936052799225
        ],
        [
            0.014002351090312004,
            -0.047170624136924744,
            -0.011963168159127235,
            0.023012004792690277,
            -0.027617132291197777,
            -0.051286183297634125,
            0.016300559043884277,
            -0.0075065093114972115,
            -0.013491318561136723,
            0.012746376916766167,
            0.005561654921621084,
            0.011550617404282093,
            0.002306461799889803,
            0.024586020037531853,
            -0.025477826595306396,
            -0.049744073301553726,
            -0.00502614863216877,
            0.0315178744494915,
            0.01758727803826332,
            0.020281432196497917,
            -0.030769547447562218,
            0.012486452236771584,
            0.054245978593826294,
            0.015805039554834366,
            -0.06736990809440613,
            0.01032761950045824,
            -0.06509581953287125,
            -0.008968403562903404,
            -0.021305082365870476,
            -0.03722105920314789,
            -0.01110281702131033,
            -0.004318060353398323,
            0.031069669872522354,
            0.023004086688160896,
            0.030994361266493797,
            0.031231006607413292,
            -0.03126927837729454,
            0.0019212482729926705,
            0.0010770970256999135,
            0.025961022824048996,
            -0.04950214549899101,
            -0.0043033030815422535,
            -0.0020319875329732895,
            0.007242964114993811,
            -0.06675125658512115,
            0.022718004882335663,
            -0.024124015122652054,
            -0.01085096038877964,
            -0.027668422088027,
            0.01308426633477211,
            0.028120767325162888,
            -0.03250276297330856,
            -0.008892170153558254,
            0.01451759785413742,
            -0.0007029552944004536,
            -0.047418929636478424,
            -0.005368901416659355,
            -0.01433031726628542,
            -0.02965383417904377,
            0.032691940665245056,
            -0.051905665546655655,
            -0.0012188361724838614,
            0.02045564353466034,
            -0.04289973899722099,
            -0.01301825512200594,
            -0.01594364084303379,
            -0.009291375055909157,
            -0.03638599067926407,
            -0.003176760161295533,
            0.007137374486774206,
            0.03133079409599304,
            0.025234533473849297,
            -0.001762967323884368,
            -0.018808696419000626,
            -0.04626557230949402,
            -0.037865784019231796,
            -0.00948050245642662,
            -0.044399555772542953,
            -0.000779698311816901,
            -0.0028919680044054985,
            -0.021602217108011246,
            -0.024529963731765747,
            0.024677233770489693,
            0.002677665092051029,
            0.01913289539515972,
            0.0070487139746546745,
            -0.0034063628409057856,
            -0.004786587320268154,
            -0.03840655833482742,
            -0.049145568162202835,
            0.04866098612546921,
            0.00868226494640112,
            -0.0020592124201357365,
            -0.04085737466812134,
            -0.07074529677629471,
            0.018212303519248962,
            0.0035720672458410263,
            -0.03398857265710831,
            -0.06592629104852676,
            -0.026921387761831284,
            -0.01767362281680107,
            -0.013683727942407131,
            0.008772198110818863,
            0.019443970173597336,
            0.016016457229852676,
            -0.004521101247519255,
            0.02456212416291237,
            0.025814088061451912,
            -0.07023563981056213,
            0.0033949553035199642,
            -0.03307162970304489,
            -0.007964059710502625,
            -0.027507025748491287,
            0.015622076578438282,
            0.037751454859972,
            -0.011939375661313534,
            -0.021078165620565414,
            -0.0026109893806278706,
            0.017762642353773117,
            -0.024885078892111778,
            -0.0007732466328889132,
            0.004955552518367767,
            -0.01694929413497448,
            -0.0418156161904335,
            -0.034797634929418564,
            -0.007522860076278448,
            -0.010538133792579174,
            -0.00711811101064086,
            -0.04388195648789406,
            0.00844596792012453,
            0.0052620284259319305,
            0.040523428469896317,
            -0.02483675442636013,
            -0.05050772428512573,
            -0.01046871580183506,
            -0.017256004735827446,
            -0.0030769570730626583,
            -0.0439579002559185,
            0.023897411301732063,
            -0.021312760189175606,
            -0.016165051609277725,
            -0.023466747254133224,
            0.010251477360725403,
            0.029593450948596,
            -0.07887344807386398,
            -0.031821418553590775,
            0.004379654303193092,
            0.010696006007492542,
            0.010092281736433506,
            0.01517639309167862,
            0.014568914659321308,
            0.05003967508673668,
            -0.018603404983878136,
            0.040124379098415375,
            -0.034634076058864594,
            -0.056887924671173096,
            -0.004056460689753294,
            0.002603947650641203,
            0.039567165076732635,
            -0.010378004983067513,
            -0.006367871072143316,
            -0.005491980817168951,
            -0.008868683129549026,
            -0.008901389315724373,
            0.05468536913394928,
            -0.009877067059278488,
            -0.07006441056728363,
            0.04082460701465607,
            -0.028571762144565582,
            -0.02397988922894001,
            -0.022813066840171814,
            -0.020678706467151642,
            -0.005533833988010883,
            0.0172836072742939,
            -0.007612318266183138,
            0.00337558938190341,
            -0.04652884975075722,
            0.029473770409822464,
            -0.0017156623071059585,
            -0.02701227366924286,
            0.024519551545381546,
            -0.036522384732961655,
            0.008479609154164791,
            0.02691490761935711,
            -0.027894914150238037,
            0.006725755054503679,
            0.0231458842754364,
            0.03344601392745972,
            -0.01294051855802536,
            0.009962756186723709,
            -0.015168939717113972,
            -0.00421484187245369,
            -0.06399805843830109,
            0.018175000324845314,
            -0.01061235461384058,
            0.012961767613887787,
            -0.0029276059940457344,
            0.012096012011170387,
            0.01916687749326229,
            0.007232116535305977,
            0.009731910191476345,
            0.004252468701452017,
            0.02894091047346592,
            -0.0032616618555039167,
            -0.07215626537799835,
            0.027022594586014748,
            -0.027801483869552612,
            -0.042546723037958145,
            0.03249717876315117,
            -0.010437526740133762,
            -0.007600733079016209,
            -0.017711704596877098,
            -0.04175190255045891,
            -0.01367208268493414,
            -0.02557322382926941,
            -0.004831188824027777,
            -0.015457306057214737,
            0.009605399332940578,
            0.04217149317264557,
            -0.023367924615740776,
            0.013932147063314915,
            0.0337761752307415,
            0.02964238077402115,
            -0.02152293734252453,
            -0.018482208251953125,
            -0.009365498088300228,
            0.05187627300620079,
            0.018027041107416153,
            -0.06778691709041595,
            0.0014613376697525382,
            0.003039035014808178,
            0.0009062619064934552,
            0.004851943347603083,
            0.030377864837646484,
            -0.05828773230314255,
            -0.011557957157492638,
            0.025040727108716965,
            -0.03293377533555031,
            0.0018485572654753923,
            -0.01699209399521351,
            0.0030017150565981865,
            0.020790820941329002,
            -0.024229664355516434,
            0.012234880588948727,
            0.021975304931402206,
            0.013702403753995895,
            0.022266319021582603,
            -0.004062434658408165,
            -0.02748371660709381,
            0.016576634719967842,
            0.005094635300338268,
            -0.015243432484567165,
            -0.03218504786491394,
            -0.018759965896606445,
            -0.010242197662591934,
            0.0003953337436541915,
            0.002917619189247489,
            -0.020442727953195572,
            -0.04185398668050766,
            0.012190543115139008,
            0.038656704127788544,
            -0.011909366585314274,
            -0.0713047906756401,
            -0.03133944794535637,
            -0.014632081612944603,
            -0.03555208444595337,
            -0.013948199339210987,
            0.0011858056532219052,
            -0.0069691697135567665,
            0.03245588019490242,
            -0.024073394015431404,
            0.023864515125751495,
            0.032968826591968536,
            -0.027387982234358788,
            0.026530273258686066,
            0.0319785438477993,
            0.015499843284487724,
            0.004339233972132206,
            -0.036557942628860474,
            0.03383827582001686,
            -0.030918877571821213,
            -0.030596202239394188,
            0.0124844154343009,
            0.035573624074459076,
            -0.018128162249922752,
            0.0014348608674481511,
            0.03885452821850777,
            0.011684402823448181,
            0.007507590111345053,
            0.006422846578061581,
            -0.018727364018559456,
            0.014892191626131535,
            -0.004555134568363428,
            -0.003769900882616639,
            0.014469130896031857,
            -0.06929916888475418,
            -0.0307781882584095,
            0.03357649967074394,
            -0.052998051047325134,
            0.008342958055436611,
            -0.034155722707509995,
            -0.01805957406759262,
            -0.020717188715934753,
            -0.026347119361162186,
            0.0006710577290505171,
            0.029367920011281967,
            0.01205593254417181,
            0.03246850520372391,
            0.0231340229511261,
            0.05312361940741539,
            0.015490897931158543,
            0.012881679460406303,
            -0.023000076413154602,
            -0.01800808124244213,
            -0.019189884886145592,
            0.016216296702623367,
            0.028212903067469597,
            0.031237507238984108,
            -0.009913277812302113,
            -0.030022799968719482,
            -0.009423106908798218,
            0.05059494078159332,
            0.01851366087794304,
            0.0042874678038060665,
            0.02688729204237461,
            0.02112540602684021,
            -0.030233407393097878,
            -0.008624863810837269,
            -0.054188814014196396,
            -0.003193837823346257,
            -0.048825908452272415,
            0.010181313380599022,
            -0.016716863960027695,
            -0.012351036071777344,
            0.016512632369995117,
            0.014592896215617657,
            -0.03455324098467827,
            -0.04677322879433632,
            -0.002963417675346136,
            -0.005050520412623882,
            0.0005440842360258102,
            -0.022234827280044556,
            -0.011256459169089794,
            0.007215708959847689,
            -0.023432210087776184,
            0.05761796608567238,
            -0.02664176933467388,
            -0.02155308425426483,
            0.010510211810469627,
            -0.048661891371011734,
            0.01866420917212963,
            0.06088290736079216,
            -0.026069924235343933,
            -0.01912517100572586,
            -0.05734856054186821,
            -0.00714853685349226,
            -0.022494211792945862,
            0.012718375772237778,
            -0.01837889477610588,
            -0.0008504170109517872,
            0.01471232995390892,
            0.02467694878578186,
            -0.045882392674684525,
            -0.026967652142047882,
            0.023259079083800316,
            0.015012513846158981,
            0.0017044319538399577,
            -0.038523536175489426,
            -0.012181021273136139,
            -0.02783272974193096,
            -0.014052131213247776,
            -0.026350267231464386,
            -0.02403687871992588,
            -0.1015264019370079,
            -0.015883591026067734,
            0.021283293142914772,
            0.029239704832434654,
            0.045298583805561066,
            0.029756002128124237,
            -0.008658669888973236,
            0.06793650984764099,
            0.01916259527206421,
            -0.026540817692875862,
            0.010352537035942078,
            -0.04165678098797798,
            -0.020039761438965797,
            -0.022027039900422096,
            -0.011552234180271626,
            -0.049609918147325516,
            -0.007580457255244255,
            -0.030620789155364037,
            -0.042910896241664886,
            -0.01405385136604309,
            -0.024969778954982758,
            -0.05846349149942398,
            -0.003926687873899937,
            -3.4060311008943245e-05,
            0.010448162443935871,
            -0.021539364010095596,
            0.026447994634509087,
            -0.07053165137767792,
            -0.01015501283109188,
            0.004124470520764589,
            -0.01717100851237774,
            -0.017317987978458405,
            -0.001819620723836124,
            -0.026793787255883217,
            0.04803438484668732,
            0.013773626647889614,
            0.02365063689649105,
            0.013900174759328365,
            0.03030126914381981,
            0.058942798525094986,
            -0.013360468670725822,
            -0.0022763225715607405,
            -0.034472737461328506,
            0.017095651477575302,
            -0.037331368774175644,
            -0.022218050435185432,
            0.02170756459236145,
            -0.048876795917749405,
            0.06255809217691422,
            -0.0260327085852623,
            -0.01650337688624859,
            -0.03611291944980621,
            -0.011603118851780891,
            -0.018955048173666,
            -0.0011779096676036716,
            0.000857501057907939,
            -0.05681990087032318,
            0.0010361999738961458,
            -0.02126346156001091,
            -0.021214263513684273,
            -0.010337200947105885,
            -0.0017829035641625524,
            0.02154376357793808,
            -0.07246188819408417,
            0.03678330034017563,
            -0.02067241445183754,
            0.02340913750231266,
            -0.04306195303797722,
            0.03511562943458557,
            -0.04228322207927704,
            0.027804626151919365,
            0.03956116735935211,
            0.0397900752723217,
            0.004431921523064375,
            -0.08292405307292938,
            -0.022407030686736107,
            0.017691174522042274,
            0.012241275981068611,
            -0.020713819190859795,
            0.015480785630643368,
            -0.025996750220656395,
            -0.039338864386081696,
            0.021725041791796684,
            -0.018287964165210724,
            -0.011856727302074432,
            0.00964207574725151,
            -0.02585882507264614,
            -0.034976083785295486,
            -0.02269032783806324,
            -0.030930491164326668,
            -0.0019038484897464514,
            0.019356930628418922,
            0.01019939873367548,
            -0.02861829474568367,
            -0.053771283477544785,
            0.010409673675894737,
            -0.024062754586338997,
            0.007138521410524845,
            -0.005151409190148115,
            -0.05718891695141792,
            0.011305119842290878,
            0.0029860269278287888,
            0.019126299768686295,
            -0.01850372739136219,
            0.0038260347209870815,
            0.007806301582604647,
            0.00886907521635294,
            -0.014916066080331802,
            -0.02031722478568554,
            -0.04870535060763359,
            0.012499234639108181,
            -0.0031519168987870216,
            -0.026736896485090256,
            0.0243839044123888,
            0.012898893095552921,
            -0.09605929255485535,
            -0.0015315371565520763,
            0.023444870486855507,
            -0.005823449231684208,
            -0.00880119763314724,
            -0.02777780033648014,
            -0.015437657944858074,
            -0.021718811243772507,
            0.02626052312552929,
            -0.023812362924218178,
            0.008572377264499664,
            -0.0020045838318765163,
            -0.0016967696137726307,
            0.013853783719241619,
            -0.008962554857134819,
            0.014470462687313557,
            -0.024698661640286446,
            0.017003841698169708,
            0.031480997800827026,
            -0.013972445391118526,
            0.004089479334652424,
            0.03207813575863838,
            -0.03818691521883011,
            0.005038380157202482,
            0.020224763080477715,
            -0.05054274946451187,
            -0.03909115493297577,
            0.02066301554441452,
            -0.042179521173238754,
            -0.008563759736716747,
            -0.00790340919047594,
            0.0028906625229865313,
            -0.00696371216326952,
            0.029494276270270348,
            0.017702462151646614,
            -0.021739277988672256,
            -0.052912864834070206,
            -0.030008822679519653,
            -0.07614681869745255,
            -0.025805283337831497,
            0.013263768516480923,
            0.04909222945570946,
            -0.0399249829351902,
            -0.02418515272438526,
            -0.039895426481962204,
            0.01820346713066101,
            0.002250095596536994,
            -0.026724262163043022,
            0.025547584518790245,
            -0.039425693452358246,
            -0.004502652212977409,
            -0.026168297976255417,
            -0.012115677818655968,
            -0.023631149902939796,
            -0.010790418833494186,
            -0.06211891397833824,
            -0.007027672603726387,
            -0.041132114827632904,
            -0.021592281758785248,
            -0.015246264636516571,
            0.02441263385117054,
            0.037909116595983505,
            0.005612698849290609,
            0.024835744872689247,
            -0.01966601051390171,
            0.004926704335957766,
            0.005745292641222477,
            0.0018207296961918473,
            -0.005311590153723955,
            -0.014312381856143475,
            -0.005301972851157188,
            0.021080413833260536,
            -0.02523227222263813,
            0.03178700804710388,
            0.023331040516495705,
            0.00683047529309988,
            0.01869003288447857,
            -0.049247946590185165,
            0.013948294334113598,
            -0.022765185683965683,
            0.0393592044711113,
            -0.009990615770220757,
            -0.025394601747393608,
            0.013016553595662117,
            -0.006094553042203188,
            -0.009471045807003975,
            0.03118816390633583,
            -0.008720355108380318,
            0.0038584645371884108,
            -0.03768352419137955,
            -0.05275645852088928,
            0.018106620758771896,
            -0.024918191134929657,
            0.007655115332454443,
            -0.004392016679048538,
            0.001965814270079136,
            -0.015949130058288574,
            0.02670772559940815,
            -0.04966080188751221,
            -0.01650809869170189,
            0.02029990218579769,
            -0.049841053783893585,
            0.038851428776979446,
            0.0024727373383939266,
            -0.033608727157115936,
            0.028426088392734528,
            -0.005910890642553568,
            0.020477060228586197,
            0.00475685577839613,
            -0.02678201161324978,
            -0.00339046074077487,
            -0.056333400309085846,
            -0.04589522257447243,
            -0.04038418084383011,
            0.06626523286104202,
            0.012933997437357903,
            -0.03550388664007187,
            -0.042467448860406876,
            -0.010290129110217094,
            -0.015864847227931023,
            0.007538644131273031,
            0.0379163920879364,
            0.008165569975972176,
            -0.005602357443422079,
            0.0016865093493834138,
            -0.01250689197331667,
            0.006576046347618103,
            0.00017408189887646586,
            0.012113514356315136,
            0.024192092940211296,
            -0.017857708036899567,
            -0.06580347567796707,
            0.033976517617702484,
            -0.01612960360944271,
            -0.005643177777528763,
            0.01209693681448698,
            0.012785370461642742,
            -0.044533297419548035,
            -0.06961603462696075,
            0.0006409172783605754,
            -0.022867536172270775,
            -0.0299789197742939,
            0.037904173135757446,
            -0.03014942817389965,
            -0.015904566273093224,
            0.013304250314831734,
            -0.04750368371605873,
            0.01543015893548727,
            0.014150170609354973,
            -0.007261261809617281,
            -0.04125085473060608,
            0.010234878398478031,
            0.04319573938846588,
            0.03787434846162796,
            0.008546167053282261,
            -0.012866796925663948,
            -0.02519693598151207,
            0.018466506153345108,
            -0.01830403134226799,
            -0.008032580837607384,
            -0.014726221561431885,
            0.017057977616786957,
            0.01719946227967739,
            -0.01200104970484972,
            -0.01234278455376625,
            0.00496514979749918,
            0.05653257668018341,
            0.008242268115282059,
            0.019477661699056625,
            0.0035608336329460144,
            -0.06653717905282974,
            0.014079561457037926,
            -0.04526195302605629,
            0.012646792456507683,
            0.0352594219148159,
            0.025203553959727287,
            -0.01714588701725006,
            -0.0358441062271595,
            -0.025253934785723686,
            0.020463747903704643,
            0.034230999648571014,
            -0.005799112841486931,
            -0.00778854638338089,
            -0.11007050424814224,
            0.023168930783867836,
            0.009920472279191017,
            0.010656694881618023,
            0.03555795177817345,
            -0.0303881224244833,
            0.015953920781612396,
            0.011459574103355408,
            0.017506133764982224,
            -0.025045128539204597,
            -0.007758854888379574,
            0.025824392214417458,
            -0.013177121058106422,
            0.030311379581689835,
            -0.00716544920578599,
            -0.012028317898511887,
            -0.04778122529387474,
            0.013345614075660706,
            0.023514296859502792,
            0.03476221114397049,
            -0.023888282477855682,
            0.03947203978896141,
            0.015989206731319427,
            -0.007645066361874342,
            -0.008352383971214294,
            -0.04237125441431999,
            0.03130825608968735,
            -0.038005948066711426,
            -0.02430475875735283,
            -0.008583270944654942,
            0.00415084371343255,
            0.014470583759248257,
            -0.0001312277017859742,
            -0.025852104648947716,
            0.009438774548470974,
            0.017783809453248978,
            -0.036705389618873596,
            -0.04611621052026749,
            -0.0667104423046112,
            0.003660641610622406,
            0.01810751110315323,
            -0.045224595814943314,
            -0.003951164428144693,
            -0.021540775895118713,
            -0.051982440054416656,
            -0.10017856955528259,
            -0.04147077724337578,
            0.0160323828458786,
            -0.015263356268405914,
            0.0045599257573485374,
            0.004954327829182148,
            -0.0011234234552830458,
            -0.011347094550728798,
            0.03426405414938927,
            -0.020719274878501892,
            -0.020379846915602684,
            0.03499811515212059,
            0.01906859129667282,
            -0.028850816190242767,
            -0.03483559936285019,
            -0.0118080023676157,
            0.020825117826461792,
            0.00018781336257234216,
            -0.06618042290210724,
            0.011122822761535645,
            0.0027294750325381756,
            -0.022346729412674904,
            0.021810289472341537,
            0.013776776380836964,
            -0.004553263075649738,
            -0.02727343700826168,
            0.030098307877779007,
            0.03627011179924011,
            -0.006932195276021957,
            0.01506776548922062,
            -0.0842490941286087,
            -0.006381846498697996,
            0.002684742910787463,
            0.010425505228340626,
            -0.03912036493420601,
            -0.004656103439629078,
            0.00793207623064518,
            0.05198139324784279,
            0.006284359842538834,
            0.04249763488769531,
            -0.009558566845953465,
            -0.020087245851755142,
            0.026024959981441498,
            0.004783800803124905,
            0.00582867069169879,
            -0.0978177934885025,
            0.007270307280123234,
            0.0012991965049877763,
            -0.008173685520887375,
            -0.014836486428976059,
            0.007859594188630581,
            -0.012370928190648556,
            0.020244691520929337,
            -0.007096605841070414,
            -0.014890042133629322,
            -0.024016160517930984,
            -0.004418057855218649,
            0.053006865084171295,
            0.0011779683409258723,
            -0.007587413303554058,
            -0.012622239999473095,
            0.004457974806427956,
            0.022011378780007362,
            -0.004853738006204367,
            -0.015909651294350624,
            0.019810359925031662,
            0.01242037396878004,
            0.02144947089254856,
            -0.006864319089800119,
            -0.0031047440133988857,
            0.0023448672145605087,
            0.026833046227693558,
            -0.010531216859817505,
            -0.0419786237180233,
            0.0506712831556797,
            0.0003722790570463985,
            0.024379266425967216,
            0.04170973226428032,
            -0.049447521567344666,
            -0.019042447209358215,
            -0.026092303916811943,
            -0.005348971579223871,
            -0.009175089187920094,
            -0.011727322824299335,
            0.0040461355820298195,
            0.04530666396021843,
            -0.012708302587270737,
            0.02908124029636383,
            -0.005179016850888729,
            -0.029227573424577713,
            -0.0337611548602581,
            -0.013267610222101212,
            -0.021342037245631218,
            0.014261717908084393,
            -0.04908229038119316,
            0.03473479300737381,
            -0.0015252070734277368,
            0.025600582361221313,
            -0.0995275154709816,
            -0.027707809582352638,
            -0.011732428334653378,
            -0.005925485864281654,
            0.006857376545667648,
            -0.014203814789652824,
            0.03503824770450592,
            0.0268869511783123,
            -0.015666140243411064,
            0.026949776336550713,
            -0.014140671119093895,
            -0.04380881413817406,
            -0.02967316098511219,
            0.012360731139779091,
            -0.02144026756286621,
            -0.027476977556943893,
            0.01047369185835123,
            0.007343968842178583,
            0.01791369915008545,
            -0.008942310698330402,
            0.01902124658226967,
            0.012672974728047848,
            -0.039061494171619415,
            -0.00924854725599289,
            0.022735849022865295,
            0.03631629794836044,
            -0.00750490790233016,
            -0.0018115631537511945,
            0.01724993996322155,
            0.0345303975045681,
            0.016285067424178123,
            -0.030233321711421013,
            0.006098642013967037,
            -0.00067330920137465,
            -0.003651909762993455,
            0.030941715463995934,
            -0.02616896666586399,
            0.024011366069316864,
            -0.033994585275650024,
            -0.02039458602666855,
            -0.002852525096386671,
            -0.013979225419461727,
            0.019419541582465172,
            0.04239023104310036,
            0.01728109084069729,
            0.00896839052438736,
            -0.011863783933222294,
            -0.021720757707953453,
            -0.08468006551265717,
            -0.037934813648462296,
            -0.01076709758490324,
            -0.00025204994017258286,
            0.006526417098939419,
            0.006680935621261597,
            -0.015524603426456451,
            0.01163571234792471,
            -0.05670131370425224,
            0.024378085508942604,
            -0.04176205024123192,
            0.04149740934371948,
            0.06887244433164597,
            -0.04121687635779381,
            -0.001436778693459928,
            -0.06031685695052147,
            -0.001014617388136685,
            0.017686093226075172,
            -0.012007494457066059,
            0.020371565595269203,
            -0.004335201811045408,
            0.014868914149701595,
            -0.007237428333610296,
            -0.007834107615053654,
            -0.010979601182043552,
            0.025197211652994156,
            -0.04446135088801384,
            -0.023835590109229088,
            0.04848829284310341,
            -0.0007622476550750434,
            0.01873069815337658,
            -0.031371451914310455,
            0.020878810435533524,
            -0.023615822196006775,
            -0.03385080769658089,
            -0.04856910929083824,
            -0.012657598592340946,
            0.006394300609827042,
            -0.06715547293424606,
            -0.01582016795873642,
            -0.002417569048702717,
            -0.020143497735261917,
            0.009726292453706264,
            -0.016365082934498787,
            -0.020628929138183594,
            -0.03491698205471039,
            0.013054605573415756,
            0.01966218091547489,
            -0.026756970211863518,
            -0.01544403750449419,
            0.00953905750066042,
            -0.04432090371847153,
            0.022485092282295227,
            -0.0009731105528771877,
            0.041793134063482285,
            -0.030173974111676216,
            0.025819506496191025,
            -0.034589361399412155,
            0.027620576322078705,
            -0.05351947620511055,
            -0.003157940460368991,
            -0.07066543400287628,
            -0.013700311072170734,
            0.049699943512678146,
            0.009014440700411797,
            0.013044137507677078,
            0.0020179643761366606,
            -0.05315506085753441,
            -0.00896503683179617,
            -0.01052880473434925,
            0.020874833688139915,
            0.009373525157570839,
            0.044809695333242416,
            -0.028144367039203644,
            -0.016058258712291718,
            0.01848204806447029,
            -0.02554292418062687,
            -0.009825232438743114,
            0.010903303511440754,
            -0.014213262125849724,
            0.0301465205848217,
            0.027882622554898262,
            0.0015922796446830034,
            -0.0025610188022255898,
            0.031776655465364456,
            0.09406571090221405,
            -0.014358356595039368,
            0.0012870680075138807,
            -0.07206229120492935,
            0.024503745138645172,
            0.02652519755065441,
            -0.02214457467198372,
            0.010357292369008064,
            0.03926606848835945,
            0.019346846267580986,
            -0.022798262536525726,
            -0.0035867509432137012,
            -0.042385805398225784,
            -0.023337380960583687,
            -0.03447959944605827,
            -0.017914140596985817,
            -0.05762649327516556,
            -0.013264641165733337,
            -0.007589451503008604,
            -0.015099660493433475,
            -0.01525054220110178,
            0.03695346415042877,
            -0.04200589656829834,
            0.009669184684753418,
            0.00535324914380908,
            0.009453073143959045,
            0.03307104483246803,
            -0.026872092857956886,
            -0.0013034304138273,
            0.016814015805721283,
            -0.021706487983465195,
            0.013058673590421677,
            0.028379207476973534,
            0.011579945683479309,
            -0.00738917151466012,
            0.014467836357653141,
            -0.022399375215172768,
            0.01624695211648941,
            0.005660661496222019,
            -0.03224942088127136,
            -0.019155386835336685,
            0.011790175922214985,
            -0.011996179819107056,
            -0.057386163622140884,
            0.022817488759756088,
            0.010496936738491058,
            0.0042503755539655685,
            0.03020826354622841,
            -0.03598739206790924,
            -0.052700985223054886,
            -0.04647226259112358,
            -0.045207057148218155,
            -0.020368462428450584,
            0.007049586158245802,
            0.0320994108915329,
            0.027564603835344315,
            -0.01837051659822464,
            -0.03597979620099068,
            0.01695120520889759,
            0.006680140737444162,
            -0.003480949904769659,
            0.034378085285425186,
            -0.04846959561109543,
            0.02843645215034485,
            -0.0299562755972147,
            0.019177285954356194,
            -0.004550055135041475,
            0.005569507833570242,
            -0.027303582057356834,
            -0.020954007282853127,
            -0.009159722365438938,
            0.002595883794128895,
            0.0004908710834570229,
            0.047813959419727325,
            0.028814848512411118,
            -0.04082806780934334,
            -0.009301586076617241
        ],
        [
            -0.033207155764102936,
            -0.03933456540107727,
            -0.012969160452485085,
            -0.021282481029629707,
            0.032411690801382065,
            0.026233496144413948,
            -0.01339787244796753,
            -0.010104787535965443,
            -0.026692567393183708,
            0.004834294784814119,
            -0.01610185205936432,
            0.01882747933268547,
            0.011408048681914806,
            -0.014457534067332745,
            -0.04704766348004341,
            -0.03669549152255058,
            -0.030652612447738647,
            0.01070203073322773,
            0.01343946997076273,
            0.011153693310916424,
            -0.01848861761391163,
            0.027163030579686165,
            0.021599696949124336,
            0.0007717651315033436,
            -0.01414936501532793,
            -0.012755916453897953,
            0.013737067580223083,
            0.002797271590679884,
            0.03026588261127472,
            0.002432839712128043,
            0.0263088196516037,
            -0.022134792059659958,
            0.02558896318078041,
            0.00971798412501812,
            -0.005990278907120228,
            0.04098350927233696,
            -0.006449622102081776,
            -0.0305110402405262,
            -0.01818293146789074,
            0.030892957001924515,
            0.010921847075223923,
            0.036387212574481964,
            0.006755593232810497,
            -0.0003973135899286717,
            -0.020688626915216446,
            0.023491518571972847,
            -0.015179380774497986,
            -0.010991737246513367,
            -0.005905619356781244,
            -0.0063170818611979485,
            0.017628643661737442,
            -0.013198004104197025,
            -0.0020732199773192406,
            -0.004745795391499996,
            -0.021120261400938034,
            0.00860225036740303,
            -0.02621644362807274,
            0.008005893789231777,
            -0.0035092656034976244,
            0.0029214329551905394,
            0.0007948956335894763,
            0.018917333334684372,
            0.01537187397480011,
            -0.0001595326029928401,
            0.024671968072652817,
            -0.012192095629870892,
            -0.006747743580490351,
            0.020623918622732162,
            0.021991824731230736,
            0.012633209116756916,
            0.001314955297857523,
            0.02638999931514263,
            -0.0005856321076862514,
            0.029405906796455383,
            0.021100735291838646,
            -0.0163168516010046,
            -0.03215532749891281,
            -0.026043875142931938,
            0.032439425587654114,
            0.023092398419976234,
            0.015834294259548187,
            -0.018538830801844597,
            0.03638384863734245,
            -0.02771582268178463,
            0.0022384761832654476,
            0.018957743421196938,
            -0.005116390064358711,
            -0.01588856242597103,
            -0.02081005834043026,
            -0.02585885114967823,
            0.00127124332357198,
            0.013733669184148312,
            0.020902076736092567,
            -0.01958218216896057,
            0.012071342207491398,
            0.0009269053116440773,
            0.0190475694835186,
            -0.011420379392802715,
            0.007851134985685349,
            0.031925495713949203,
            -0.008521752431988716,
            -0.016468660905957222,
            0.013712248764932156,
            0.017643606290221214,
            -0.022113902494311333,
            0.0019101530779153109,
            0.03342757001519203,
            0.024997834116220474,
            -0.009198226034641266,
            -0.02584298700094223,
            -0.013928243890404701,
            -0.02201034314930439,
            -0.017227942124009132,
            0.016924286261200905,
            -0.05407203733921051,
            0.030651990324258804,
            -0.008945520035922527,
            -0.027088390663266182,
            0.023654239252209663,
            -0.008374799974262714,
            0.025280848145484924,
            -0.02415888011455536,
            -0.02283262275159359,
            0.008642163127660751,
            -0.0011475797509774566,
            -0.0197389367967844,
            -0.020449720323085785,
            0.02127641811966896,
            0.003690202720463276,
            0.017216794192790985,
            0.016322672367095947,
            -0.012835836037993431,
            -0.060604531317949295,
            -0.02853616699576378,
            -0.012219210155308247,
            -0.010237440466880798,
            0.020534086972475052,
            -0.006446894723922014,
            0.010213343426585197,
            0.03169079124927521,
            -0.02292972430586815,
            0.019566936418414116,
            0.013251244090497494,
            -0.008401528932154179,
            -0.023416200652718544,
            -0.04344886541366577,
            -0.026075346395373344,
            -0.03860275447368622,
            0.01231984794139862,
            -0.0069412486627697945,
            -0.01811634749174118,
            -0.016488350927829742,
            -0.037127964198589325,
            0.006788104306906462,
            0.029928024858236313,
            0.002443754579871893,
            -0.019096624106168747,
            -0.015122748911380768,
            0.0094838822260499,
            0.011045502498745918,
            0.013073552399873734,
            0.0015995011199265718,
            -0.0036098777782171965,
            0.018070774152874947,
            -0.019246306270360947,
            -0.022402262315154076,
            -0.02782638557255268,
            0.01395958662033081,
            0.0007151950849220157,
            0.021718885749578476,
            -0.030943933874368668,
            0.03804202377796173,
            0.020511141046881676,
            0.010328644886612892,
            -0.0215108934789896,
            0.0025015706196427345,
            0.0018604883225634694,
            0.027447303757071495,
            -0.00259558972902596,
            -0.013338031247258186,
            0.03471238911151886,
            -0.003125131130218506,
            -0.015232566744089127,
            -0.003698475193232298,
            0.009576798416674137,
            -0.02827843837440014,
            -0.015244514681398869,
            -0.008390553295612335,
            0.016723845154047012,
            0.0008720482583157718,
            -0.013843616470694542,
            -0.021507099270820618,
            -0.06618456542491913,
            -0.01997329853475094,
            0.020343653857707977,
            -0.03129425272345543,
            -0.011746250092983246,
            -0.027305668219923973,
            -0.005122545175254345,
            0.021946078166365623,
            0.003103172406554222,
            0.03263143077492714,
            -0.00656400341540575,
            -0.002795996842905879,
            0.01686595380306244,
            -0.0012476691044867039,
            0.037690695375204086,
            0.002033211523666978,
            0.0016557278577238321,
            -0.01446130033582449,
            0.018198294565081596,
            -0.040276214480400085,
            -0.004898633807897568,
            0.012605715543031693,
            -0.016670938581228256,
            -0.0013801183085888624,
            0.00910180527716875,
            0.02183431200683117,
            0.011684007942676544,
            -0.026643797755241394,
            -0.004613528028130531,
            0.008441855199635029,
            0.00310824578627944,
            0.025349950417876244,
            0.016616079956293106,
            0.032725073397159576,
            -0.050866734236478806,
            -0.009895299561321735,
            -0.0022872393019497395,
            -0.009174705483019352,
            -0.023532111197710037,
            0.024930255487561226,
            -0.012572470121085644,
            0.02268996648490429,
            -0.022238772362470627,
            -0.008059652522206306,
            -0.020285069942474365,
            -0.007552530616521835,
            0.0029644579626619816,
            -0.00660565122961998,
            0.022904450073838234,
            -0.015705551952123642,
            0.037590328603982925,
            -0.00891115888953209,
            0.03467101603746414,
            0.015790490433573723,
            -0.03171727806329727,
            -0.007132039405405521,
            -0.003423467744141817,
            -0.03874300420284271,
            -0.02873506397008896,
            0.03531063348054886,
            0.0075292084366083145,
            -0.010432067327201366,
            0.00549852242693305,
            0.004777698311954737,
            -0.021180126816034317,
            -0.022626694291830063,
            0.020561842247843742,
            -0.021457668393850327,
            0.0015297342324629426,
            0.024420948699116707,
            0.006827299017459154,
            0.0038444355595856905,
            0.016901547089219093,
            0.0028373084496706724,
            0.0038808046374469995,
            -0.004820573143661022,
            0.024907084181904793,
            0.013927499763667583,
            -0.008851252496242523,
            -0.02964520826935768,
            0.005129559431225061,
            -0.035652488470077515,
            0.013844774104654789,
            0.03510096296668053,
            -0.024379227310419083,
            0.018621055409312248,
            -0.004866557661443949,
            0.0289026889950037,
            -0.019707728177309036,
            -0.02811308763921261,
            -0.02819810062646866,
            0.02470850571990013,
            -0.012375172227621078,
            0.007724446710199118,
            0.00043054803973063827,
            0.04000328108668327,
            0.0022955150343477726,
            0.0077524068765342236,
            0.013050857000052929,
            0.01969914697110653,
            0.0039065442979335785,
            0.016381148248910904,
            0.031014567241072655,
            -0.01907292567193508,
            -0.026971345767378807,
            -0.0157069880515337,
            -0.039367157965898514,
            -0.02843385376036167,
            0.035755984485149384,
            0.00458882749080658,
            0.023560790345072746,
            -0.016575414687395096,
            -0.006230887956917286,
            -0.006460706237703562,
            0.025508331134915352,
            -0.0028781602159142494,
            -0.010904747992753983,
            -0.009600442834198475,
            0.01677114889025688,
            0.012935690581798553,
            -0.007281178142875433,
            -0.01850838027894497,
            -0.009536671452224255,
            -0.01572423428297043,
            0.018468312919139862,
            0.03810657560825348,
            0.026282990351319313,
            -0.005722846370190382,
            -0.02946764975786209,
            -0.015743238851428032,
            0.009464457631111145,
            0.03576036915183067,
            0.008269909769296646,
            0.019295182079076767,
            0.04406855255365372,
            0.024540593847632408,
            -0.02129635587334633,
            0.008270133286714554,
            -0.030005784705281258,
            -0.018338220193982124,
            -0.043329495936632156,
            0.010850667022168636,
            -0.024128805845975876,
            0.033570703119039536,
            0.014280827715992928,
            0.019216667860746384,
            0.031037557870149612,
            -0.018700959160923958,
            0.0017140741692855954,
            -0.0005269836401566863,
            0.03616337105631828,
            0.013603528961539268,
            -0.029462886974215508,
            0.003053912427276373,
            -0.011742793954908848,
            0.011964439414441586,
            0.009269936010241508,
            0.02456657774746418,
            -0.03063519299030304,
            0.01462306547909975,
            -0.036704111844301224,
            -0.023501185700297356,
            -0.021374167874455452,
            0.014744403772056103,
            0.0014299112372100353,
            -0.010025875642895699,
            0.026021474972367287,
            -0.006548698525875807,
            -0.02759634703397751,
            -0.00031202592072077096,
            -0.001401669578626752,
            0.027222560718655586,
            0.04292772710323334,
            0.014719818718731403,
            0.006446407176554203,
            -0.010348356328904629,
            -0.007068102713674307,
            0.03000255860388279,
            -0.0036469008773565292,
            0.01541591715067625,
            -0.022761128842830658,
            -5.917600446991855e-06,
            -0.005596178118139505,
            -0.03923797607421875,
            -0.036254554986953735,
            0.009846929460763931,
            -0.021398380398750305,
            -0.006828350946307182,
            -0.0055960374884307384,
            0.023491377010941505,
            0.016876505687832832,
            0.019931161776185036,
            -0.03687307611107826,
            -0.013305512256920338,
            0.03088938631117344,
            -0.04818374291062355,
            0.01015903614461422,
            0.00037887386861257255,
            -0.007482666522264481,
            -0.0089992955327034,
            -0.03121456876397133,
            -0.015550393611192703,
            -0.08057452738285065,
            -0.01870276965200901,
            -3.1458093872061e-05,
            -0.021716183051466942,
            0.01175051461905241,
            0.013588239438831806,
            -0.017794929444789886,
            0.02117038704454899,
            0.020051661878824234,
            -0.011436205357313156,
            -0.024214396253228188,
            -0.0057575698010623455,
            0.001060680951923132,
            0.008855226449668407,
            0.03137173503637314,
            0.023243585601449013,
            -0.043903809040784836,
            0.030050082132220268,
            0.009780214168131351,
            -0.010437386110424995,
            -0.008796459063887596,
            -0.008162220008671284,
            -0.007417643442749977,
            -0.016178051009774208,
            -0.025720100849866867,
            -0.014894489198923111,
            -0.032011788338422775,
            -0.007963377051055431,
            -0.01845821551978588,
            0.012188595719635487,
            -0.02910129725933075,
            -0.010587923228740692,
            -0.007756001316010952,
            -0.014442544430494308,
            0.029192917048931122,
            0.020760610699653625,
            0.00016056271851994097,
            0.03755329176783562,
            0.0008007204160094261,
            -0.02173765003681183,
            0.01589120179414749,
            0.008945943787693977,
            -0.012151237577199936,
            0.0037858830764889717,
            0.001291555818170309,
            -0.03240605816245079,
            0.019494805485010147,
            0.006003160495311022,
            -0.019573168829083443,
            -0.023064279928803444,
            -0.023478038609027863,
            -0.005788637325167656,
            -0.020466087386012077,
            -0.03782753273844719,
            -0.036946218460798264,
            -0.021577682346105576,
            -0.02317090705037117,
            0.026301003992557526,
            -0.0035465566907078028,
            -0.008848856203258038,
            -0.038131363689899445,
            -0.009229128248989582,
            0.016114050522446632,
            -0.012663190253078938,
            -0.0018406521994620562,
            0.03409447520971298,
            0.025745559483766556,
            -0.006870877463370562,
            -0.006659527774900198,
            -0.016051625832915306,
            0.01773715205490589,
            -0.0066355443559587,
            -0.03142837807536125,
            -0.036215007305145264,
            -0.012427648529410362,
            0.03366672247648239,
            0.015028699301183224,
            -0.025008821859955788,
            -0.024215655401349068,
            0.01953134872019291,
            -0.005303609650582075,
            0.031027719378471375,
            -0.0271215308457613,
            -0.013816612772643566,
            0.005631072912365198,
            0.03422042727470398,
            -0.025642722845077515,
            -0.016061345115303993,
            0.043062370270490646,
            -0.02328725904226303,
            -0.01730469986796379,
            0.022369273006916046,
            -0.04145433381199837,
            -0.03123359940946102,
            -0.07463010400533676,
            -0.008542689494788647,
            -0.04352586716413498,
            -0.006931792479008436,
            -0.015651652589440346,
            -0.0169625636190176,
            -0.03352462872862816,
            0.024982977658510208,
            0.009889073669910431,
            -0.011836097575724125,
            0.013794960454106331,
            -0.007545866072177887,
            0.008246545679867268,
            -0.007924554869532585,
            -0.01101246103644371,
            -0.05008089914917946,
            0.027171483263373375,
            -0.011466935276985168,
            0.02099362388253212,
            0.026326632127165794,
            -0.019667664542794228,
            -0.005166708026081324,
            0.03159265220165253,
            0.01566852070391178,
            0.02901136875152588,
            -0.032427165657281876,
            -0.05885240435600281,
            0.03563590720295906,
            0.005855358671396971,
            -0.011679109185934067,
            0.004757238086313009,
            -0.008349885232746601,
            -0.0018494182731956244,
            -0.026389990001916885,
            0.023045705631375313,
            0.00922955758869648,
            0.05375182628631592,
            -0.0136690903455019,
            0.023097138851881027,
            0.012838373892009258,
            0.028957199305295944,
            -0.024835607036948204,
            0.02206197939813137,
            -0.005223892629146576,
            -0.01177184283733368,
            0.01896977424621582,
            0.013008944690227509,
            -0.03324756398797035,
            0.024115532636642456,
            -0.027845680713653564,
            0.006398867350071669,
            -0.007549785077571869,
            9.222609378412017e-07,
            0.001187576912343502,
            -0.011936253868043423,
            -0.028058752417564392,
            0.01744096539914608,
            -0.022509921342134476,
            0.010460051707923412,
            -0.05597351863980293,
            -0.02083045430481434,
            -0.006628375966101885,
            0.005481149535626173,
            -0.01396077312529087,
            0.0002703125064726919,
            0.0038636866956949234,
            0.016088055446743965,
            0.015507412143051624,
            -0.02159135602414608,
            0.01666245236992836,
            0.012460645288228989,
            0.012683707289397717,
            -0.005562593229115009,
            0.004393226001411676,
            0.0033922488801181316,
            0.012381792068481445,
            0.02976149134337902,
            0.01835174858570099,
            -0.012005786411464214,
            0.019500257447361946,
            -0.025843404233455658,
            -0.0179778840392828,
            0.032591357827186584,
            -0.030289912596344948,
            -0.026440856978297234,
            0.02125774882733822,
            0.021209867671132088,
            -0.0051682391203939915,
            0.001426844042725861,
            -0.012282788753509521,
            0.014173238538205624,
            -0.009125394746661186,
            -0.009736694395542145,
            0.02130443975329399,
            -0.02699822559952736,
            0.02606629952788353,
            0.0018961942987516522,
            -0.002689732937142253,
            0.02490079775452614,
            0.026178088039159775,
            0.012304028496146202,
            0.013210616074502468,
            -0.013840004801750183,
            -0.020172590389847755,
            0.015111447311937809,
            -0.02020333521068096,
            -0.013208284042775631,
            0.010706469416618347,
            0.007722613401710987,
            0.017346393316984177,
            -0.0020169680938124657,
            -0.0021722684614360332,
            -0.019578561186790466,
            0.033003173768520355,
            -0.02208240143954754,
            -0.0002827565767802298,
            0.007046829909086227,
            -0.01774122193455696,
            -0.0007629654137417674,
            0.02216586098074913,
            0.00011412698222557083,
            0.004564608912914991,
            -0.024929476901888847,
            0.03248096629977226,
            -0.02805446833372116,
            0.006741587072610855,
            0.0024214209988713264,
            0.010655853897333145,
            0.03635609894990921,
            -0.02704145945608616,
            -0.012377413921058178,
            -0.026298649609088898,
            -0.05672118067741394,
            -0.030165499076247215,
            -0.027485709637403488,
            0.019258389249444008,
            0.016350919380784035,
            0.012799388729035854,
            0.004531688056886196,
            0.024309605360031128,
            -0.008141941390931606,
            0.010670201852917671,
            0.00641517573967576,
            0.030689693987369537,
            0.017475632950663567,
            -0.0008460491080768406,
            -0.036351654678583145,
            0.030118657276034355,
            0.018862763419747353,
            0.03535156697034836,
            -0.009744161739945412,
            -0.02072380669414997,
            -0.03203636780381203,
            -0.02435273863375187,
            0.026124626398086548,
            0.04580925405025482,
            -0.03302154317498207,
            -0.022823134437203407,
            -0.0030138108413666487,
            0.024246791377663612,
            -0.020884942263364792,
            0.018029311671853065,
            -0.02493925392627716,
            -0.0029661173466593027,
            -0.04048742353916168,
            -0.010832017287611961,
            -0.01577315665781498,
            0.016383958980441093,
            0.03564062714576721,
            0.017985505983233452,
            -0.0010658340761438012,
            0.03853300213813782,
            0.019451836124062538,
            0.01850183866918087,
            -0.0031899006571620703,
            0.0007655272493138909,
            0.03126421198248863,
            0.012583506293594837,
            0.005131084006279707,
            -0.013670523650944233,
            -0.017343083396553993,
            -0.02673555165529251,
            0.027421245351433754,
            -0.0033024786971509457,
            0.0029326509684324265,
            -0.0006977561861276627,
            0.032156165689229965,
            0.012395482510328293,
            0.0037300153635442257,
            -0.013989157974720001,
            0.03738541156053543,
            -0.008901393972337246,
            0.025070175528526306,
            -0.002832570346072316,
            -0.025212464854121208,
            -0.03337729722261429,
            0.00023911079915706068,
            0.023205703124403954,
            -0.02359437756240368,
            -0.03078610636293888,
            -0.03426118195056915,
            0.030140038579702377,
            0.012306624092161655,
            0.01242986973375082,
            0.015474323183298111,
            0.011558008380234241,
            0.004166134167462587,
            0.006283494643867016,
            -0.006159274373203516,
            0.02823732979595661,
            -0.005252813454717398,
            0.029923569411039352,
            -0.02539423108100891,
            -0.006272443104535341,
            0.02908245474100113,
            -0.02433595433831215,
            -0.04697994887828827,
            0.029076265171170235,
            -0.005354974884539843,
            0.003259136341512203,
            -0.01437323447316885,
            0.007884466089308262,
            -0.008984741754829884,
            0.023298127576708794,
            -0.049521248787641525,
            -0.015943612903356552,
            -0.02687845751643181,
            0.008677002042531967,
            -0.0592828094959259,
            -0.010624510236084461,
            -0.019718673080205917,
            -0.02012617327272892,
            -0.004658161196857691,
            -0.009656201116740704,
            0.0041299075819551945,
            -0.036120228469371796,
            0.015354559756815434,
            0.023678801953792572,
            0.006929776165634394,
            -0.011553000658750534,
            0.000428342871600762,
            0.021644597873091698,
            -0.013819858431816101,
            0.0016883291536942124,
            0.0025687306188046932,
            -0.02907573990523815,
            0.020265834406018257,
            -0.03267044946551323,
            -0.009174283593893051,
            -0.008092998526990414,
            -0.008928836323320866,
            -0.013093805871903896,
            0.009301929734647274,
            0.011894594877958298,
            0.0228380486369133,
            -0.003214899217709899,
            -0.009056460112333298,
            -0.014141794294118881,
            -0.08924470096826553,
            0.009415257722139359,
            -0.027216577902436256,
            0.01628364808857441,
            0.029267016798257828,
            -0.0021802482660859823,
            0.015888161957263947,
            -0.010663065128028393,
            0.032624054700136185,
            -0.030956659466028214,
            0.008075990714132786,
            -0.033039093017578125,
            0.010091771371662617,
            -0.010707413777709007,
            -0.0035829173866659403,
            0.03017570823431015,
            0.016279755160212517,
            0.015255667269229889,
            -0.029436225071549416,
            -0.009284604340791702,
            -0.02159455418586731,
            -0.02995380014181137,
            -0.0009708128636702895,
            -0.03080514818429947,
            0.005918953102082014,
            -0.0296862181276083,
            -0.04607032984495163,
            0.03570706024765968,
            -0.006669462192803621,
            0.005302520468831062,
            -0.006297412794083357,
            0.028789158910512924,
            0.009136185981333256,
            -0.01931278593838215,
            -0.007199712563306093,
            0.006417823489755392,
            -0.0016794814728200436,
            -0.027604088187217712,
            -0.03680507093667984,
            0.023439431563019753,
            0.0031741417478770018,
            -0.009741678833961487,
            -0.00396362878382206,
            -0.014826841652393341,
            0.016674166545271873,
            -0.02703310362994671,
            -0.0391903817653656,
            -0.005403758492320776,
            -0.033783137798309326,
            -0.032762616872787476,
            -0.03469404578208923,
            0.01804416812956333,
            0.01645851321518421,
            0.021512512117624283,
            -0.012048982083797455,
            -0.0028046416118741035,
            -0.016325028613209724,
            0.00546324672177434,
            -0.013918737880885601,
            -0.0012439906131476164,
            0.0031980518251657486,
            -0.02015225775539875,
            0.023221300914883614,
            0.011353542096912861,
            -0.006871092598885298,
            5.941408858234354e-07,
            -0.0021514114923775196,
            -0.027294520288705826,
            0.03363065794110298,
            0.029153861105442047,
            -0.00401297677308321,
            0.017173798754811287,
            -0.009410448372364044,
            0.01922854594886303,
            -0.00512463366612792,
            -0.0009405803284607828,
            0.008783095516264439,
            -0.016421593725681305,
            0.04671759530901909,
            -0.021983329206705093,
            0.027603011578321457,
            -0.017279695719480515,
            -0.023396093398332596,
            -0.015795400366187096,
            0.003128675976768136,
            0.02880271151661873,
            0.008232364431023598,
            -0.021081851795315742,
            0.036001160740852356,
            0.004502633586525917,
            0.026242418214678764,
            0.008168687112629414,
            0.007426803465932608,
            -0.01145460456609726,
            -0.0161538515239954,
            -0.012764853425323963,
            0.026321958750486374,
            -0.014053724706172943,
            -0.0058960807509720325,
            0.020485032349824905,
            0.023689432069659233,
            0.00906152743846178,
            -0.00833864789456129,
            -0.012254906818270683,
            0.01988818496465683,
            0.026738163083791733,
            0.052880074828863144,
            0.01174125261604786,
            0.012100126594305038,
            0.03267097473144531,
            0.01954350247979164,
            0.0028885367792099714,
            -0.00519730057567358,
            0.018842704594135284,
            -0.007884661667048931,
            -0.015532661229372025,
            0.01393673662096262,
            0.008757336996495724,
            0.00903185922652483,
            -0.026330679655075073,
            0.03313877061009407,
            -0.02966552972793579,
            0.014712103642523289,
            0.0007596079376526177,
            0.0021560718305408955,
            -0.018128875643014908,
            -0.03741026297211647,
            0.03224429860711098,
            -0.014550257474184036,
            0.016211798414587975,
            0.004756364971399307,
            0.0031354050152003765,
            0.004069790244102478,
            -0.009311772882938385,
            -0.04153214395046234,
            0.026111038401722908,
            -0.030735783278942108,
            0.013148275204002857,
            -0.016006173565983772,
            -0.004744634963572025,
            -0.030960053205490112,
            0.03643162176012993,
            -0.025636514648795128,
            -0.022558480501174927,
            0.008057870902121067,
            -0.0055381800048053265,
            0.013903439044952393,
            0.010667326860129833,
            -0.014311951585114002,
            0.032614339143037796,
            0.028636442497372627,
            -0.04549471661448479,
            0.018336085602641106,
            0.0018201634520664811,
            0.023118002340197563,
            0.004713592119514942,
            -0.002970857312902808,
            0.0003005048492923379,
            0.005335692781955004,
            -8.352018630830571e-05,
            -0.024267448112368584,
            -0.03279881551861763,
            0.029413416981697083,
            0.02934744767844677,
            0.029295306652784348,
            -0.037946224212646484,
            -0.015498126856982708,
            0.029204901307821274,
            -0.037296559661626816,
            0.01962735503911972,
            0.017733154818415642,
            -0.02349092997610569,
            0.007942888885736465,
            -0.004680892452597618,
            0.02852611616253853,
            0.025465600192546844,
            0.01713821291923523,
            -0.04116753488779068,
            0.0072695170529186726,
            0.02408510446548462,
            -0.01994408667087555,
            0.017014091834425926,
            0.03680764138698578,
            -0.031708505004644394,
            -0.004947239067405462,
            -0.023238880559802055,
            -0.01512774359434843,
            0.026623887941241264,
            0.014685391448438168,
            -0.03469359129667282,
            -0.006550613325089216,
            0.0402243472635746,
            0.01645314134657383,
            -0.02536187134683132,
            0.005630678031593561,
            0.01674702949821949,
            -0.007597026415169239,
            0.0274428129196167,
            0.01160764042288065,
            -0.009692803025245667,
            -0.00831631664186716,
            -0.009989289566874504,
            -0.04007170349359512,
            0.028400622308254242,
            -0.013890142552554607,
            0.011366495862603188,
            -0.015537828207015991,
            0.024078380316495895,
            -0.020975276827812195,
            0.03343655914068222,
            0.0017608007183298469,
            -0.01852520741522312,
            -0.022566761821508408,
            -0.0274821650236845,
            0.01091234665364027,
            -0.00933405477553606,
            -0.035292383283376694,
            -0.009435342624783516,
            0.003755084006115794,
            -0.00204599485732615,
            -0.036174241453409195,
            -0.026057137176394463,
            -0.009855876676738262,
            -0.01204306073486805,
            0.013921220786869526,
            0.009111464023590088,
            0.020917411893606186,
            0.0040758876129984856,
            -0.022977503016591072,
            -0.01926863007247448,
            0.03665825352072716,
            0.02962026372551918,
            0.02122168056666851,
            -0.017020024359226227,
            -0.004560439847409725,
            0.012802735902369022,
            -0.03421320766210556,
            0.0113999517634511,
            0.015398849733173847,
            0.020041828975081444,
            0.009290347807109356,
            0.02809310145676136,
            0.007284345105290413,
            0.01430470123887062,
            0.019252995029091835,
            0.012397541664540768,
            -0.031792931258678436,
            -0.029446760192513466,
            -0.010538718663156033,
            0.000835704558994621,
            -0.028968051075935364,
            0.0012451043585315347,
            0.013464760035276413,
            -0.018039271235466003,
            -0.0179439689964056,
            0.00860977079719305,
            -0.024645764380693436,
            0.016487907618284225,
            -0.01269520167261362,
            0.005336833652108908,
            -0.02279633656144142,
            0.03626693785190582,
            -0.017343351617455482,
            -0.014148683287203312,
            0.02227063849568367,
            -0.024330047890543938,
            -0.021302565932273865,
            -0.043066054582595825,
            0.02471572905778885,
            -0.008638258092105389,
            0.031408678740262985,
            -0.0037025283090770245,
            -0.02612033486366272,
            0.022493552416563034,
            0.0053262063302099705,
            -0.019335418939590454,
            -0.013176880776882172,
            -0.02439657412469387
        ],
        [
            -0.024648500606417656,
            0.029210977256298065,
            -0.024907633662223816,
            0.011946375481784344,
            0.02971646562218666,
            -0.005636268295347691,
            -0.026021024212241173,
            0.0164006520062685,
            0.00795807410031557,
            0.013116413727402687,
            0.01646013744175434,
            0.027742058038711548,
            0.008672571741044521,
            -0.02058076299726963,
            -0.041263483464717865,
            -0.014150679111480713,
            -0.011649920605123043,
            0.020872704684734344,
            -0.038132451474666595,
            -0.029294652864336967,
            0.004069966729730368,
            -0.007061954587697983,
            0.02035117708146572,
            -0.048741720616817474,
            0.0048621101304888725,
            0.01816275157034397,
            -0.04045771062374115,
            0.00799346249550581,
            -0.022196440026164055,
            -0.012551895342767239,
            -0.01611318439245224,
            -0.016477424651384354,
            -0.008550361730158329,
            0.008717391639947891,
            -0.01741422899067402,
            -0.010596456937491894,
            0.039881717413663864,
            0.010380267165601254,
            0.027582576498389244,
            -0.004839737433940172,
            -0.03337226063013077,
            -0.00950657669454813,
            -0.019209647551178932,
            -0.0300607830286026,
            -0.02900923788547516,
            0.03846646845340729,
            0.012263333424925804,
            0.012318605557084084,
            0.013717634603381157,
            0.0031710851471871138,
            0.0035426400136202574,
            -0.02976369485259056,
            -0.011254949495196342,
            0.007948994636535645,
            -0.0033372128382325172,
            0.009160715155303478,
            -0.02554055117070675,
            0.0017800267087295651,
            -0.010047467425465584,
            -0.001498786616139114,
            -0.008933556266129017,
            -0.011518057435750961,
            0.030121512711048126,
            0.0066991462372243404,
            0.025751890614628792,
            0.011416463181376457,
            -0.047993630170822144,
            -0.0009439223213121295,
            0.02282334864139557,
            0.004639009013772011,
            0.02200588770210743,
            -0.0046316650696098804,
            -0.03387775272130966,
            -0.02567364275455475,
            -0.07527118921279907,
            0.0158438291400671,
            -0.0066010551527142525,
            -0.025555165484547615,
            -0.02642959915101528,
            0.0012342134723439813,
            -0.014548070728778839,
            -0.058213960379362106,
            -0.021620512008666992,
            0.0006590984994545579,
            -0.018589120358228683,
            -0.020117444917559624,
            -0.029937561601400375,
            0.01896149106323719,
            0.03539709374308586,
            0.0038844922091811895,
            -0.01776881515979767,
            -0.014238077215850353,
            -0.021730463951826096,
            -0.026087932288646698,
            0.03262835741043091,
            -0.013926354236900806,
            0.004788171499967575,
            -0.02851090021431446,
            0.06401417404413223,
            0.03334971144795418,
            -0.011782241985201836,
            -0.00936101097613573,
            0.02577691338956356,
            -0.04500500485301018,
            0.04259506240487099,
            -0.00402465695515275,
            0.0017233536345884204,
            -0.0057718572206795216,
            -0.03471916913986206,
            -0.006481079384684563,
            0.033930983394384384,
            0.027853738516569138,
            0.038868531584739685,
            -0.02032821998000145,
            0.048970870673656464,
            -0.02588077262043953,
            -0.010711452923715115,
            -0.01712033897638321,
            -0.01747259497642517,
            -0.01915558986365795,
            0.013886576518416405,
            -0.014852741733193398,
            0.055789873003959656,
            0.004112627822905779,
            0.017063109204173088,
            -0.013533664867281914,
            0.04612652212381363,
            -1.3004062566324137e-05,
            -0.01559560839086771,
            -0.013589748181402683,
            0.025634026154875755,
            -0.00061992765404284,
            -0.005419348366558552,
            -0.002163595287129283,
            0.02136780135333538,
            -0.028127560392022133,
            -0.007903775200247765,
            -0.022492442280054092,
            -0.018381865695118904,
            0.03434126079082489,
            0.010879600420594215,
            0.025538574904203415,
            0.004549381323158741,
            -0.0010365549242123961,
            -0.04168149456381798,
            0.0212910994887352,
            -0.008131212554872036,
            0.019202306866645813,
            -0.0006854956154711545,
            -0.03915373235940933,
            -0.04832316190004349,
            0.04801340401172638,
            -0.008195002563297749,
            -0.05571432039141655,
            -0.048866044729948044,
            0.006026879418641329,
            0.03849106281995773,
            0.015497641637921333,
            -0.02136641927063465,
            0.05703924596309662,
            0.008121579885482788,
            0.0029438300989568233,
            0.0033820108510553837,
            -0.008167644962668419,
            -0.027932709082961082,
            -0.024596262723207474,
            -0.004592579789459705,
            -0.024662496522068977,
            -0.007751562166959047,
            0.0007951651350595057,
            0.00392407039180398,
            0.021658511832356453,
            -0.02260938100516796,
            -0.05029280111193657,
            0.010742495767772198,
            -0.02323811873793602,
            -0.011679311282932758,
            0.0064122299663722515,
            0.04284615442156792,
            0.0014686292270198464,
            -0.0018871523207053542,
            -0.02900107577443123,
            -0.022473342716693878,
            0.031545255333185196,
            -0.0192100927233696,
            0.005933193489909172,
            -0.02805456519126892,
            0.004088343121111393,
            -0.03294149413704872,
            -0.005897182505577803,
            0.024292217567563057,
            -0.014944012276828289,
            0.03740270063281059,
            0.018429428339004517,
            0.02369793877005577,
            -0.026492012664675713,
            -0.017873449251055717,
            0.03716709092259407,
            -0.034095123410224915,
            0.04134995862841606,
            0.027417577803134918,
            -0.014384531415998936,
            0.06365887075662613,
            -0.023625390604138374,
            0.023035438731312752,
            -0.006648609414696693,
            -0.041473791003227234,
            0.015432511456310749,
            0.0005113762454129755,
            -0.01793111488223076,
            0.01853417046368122,
            -0.021943919360637665,
            -0.012006967328488827,
            -0.017564594745635986,
            -0.02488161064684391,
            0.0006923216860741377,
            -0.025172105059027672,
            0.015110150910913944,
            -0.005659755785018206,
            0.030652621760964394,
            0.009099336341023445,
            0.025194304063916206,
            0.027730869129300117,
            0.02466580644249916,
            -0.0034984552767127752,
            -0.03537818416953087,
            -0.03558271750807762,
            -0.011324945837259293,
            0.020322639495134354,
            -0.016585536301136017,
            0.026546258479356766,
            0.00793383363634348,
            -0.023485397920012474,
            0.020262233912944794,
            0.00916574988514185,
            -0.044224027544260025,
            0.008995133452117443,
            0.013293749652802944,
            0.028450466692447662,
            0.02614271640777588,
            -0.02869548462331295,
            -0.01892157830297947,
            -0.03394164890050888,
            0.040851522237062454,
            -0.0060540251433849335,
            -0.010213329456746578,
            0.027451932430267334,
            0.029046054929494858,
            -0.012685051187872887,
            -0.010229932144284248,
            0.019749920815229416,
            -0.025300201028585434,
            0.02293560653924942,
            -0.00811522826552391,
            -0.0020928801968693733,
            0.020428936928510666,
            -0.00730231124907732,
            -0.0369885228574276,
            0.00205357582308352,
            0.02768954448401928,
            0.010216932743787766,
            0.030717208981513977,
            -0.014622795395553112,
            -0.021959219127893448,
            0.005430013872683048,
            -0.00015278620412573218,
            -0.01385020837187767,
            0.041222698986530304,
            -0.0015811505727469921,
            0.01780373603105545,
            -0.012658720836043358,
            0.01382532063871622,
            0.020787838846445084,
            -0.005421745590865612,
            -0.005732710938900709,
            0.014238635078072548,
            -0.0350472554564476,
            -0.023874085396528244,
            -0.026242664083838463,
            0.020910045132040977,
            0.02599356323480606,
            0.03178741782903671,
            0.037420373409986496,
            -0.016585666686296463,
            0.0023466674610972404,
            -0.008433738723397255,
            -0.021236654371023178,
            0.010161426849663258,
            0.0009227937553077936,
            0.016149990260601044,
            -0.002405049977824092,
            0.044458117336034775,
            0.014576158486306667,
            -0.01739686168730259,
            0.0011902156984433532,
            0.02308773249387741,
            0.022662224248051643,
            0.01963505521416664,
            -0.0005878435331396759,
            -0.011328086256980896,
            -0.016581719741225243,
            0.0020937907975167036,
            0.015346252359449863,
            0.025637701153755188,
            -0.016894623637199402,
            0.02522599697113037,
            -0.04106071963906288,
            0.03709778934717178,
            0.0014338595792651176,
            -0.019226357340812683,
            0.01945194974541664,
            0.0034485238138586283,
            0.028562769293785095,
            0.028699880465865135,
            -0.013488722033798695,
            0.02502218447625637,
            0.012940967455506325,
            0.0202566497027874,
            -0.009390698745846748,
            6.419041710614692e-06,
            0.031107766553759575,
            -0.007536513265222311,
            -0.041421785950660706,
            0.007631033193320036,
            0.012677410617470741,
            0.023736871778964996,
            0.03572545200586319,
            -0.006127005442976952,
            -0.00714187091216445,
            0.01181517168879509,
            -0.0035425557289272547,
            -0.0009185941307805479,
            0.041643667966127396,
            -0.0038952676113694906,
            0.02016446739435196,
            -0.024323448538780212,
            0.01364814955741167,
            0.023044537752866745,
            0.02471908926963806,
            -0.02724812924861908,
            -0.0278223417699337,
            -0.0424104705452919,
            -0.022172564640641212,
            -0.0016064067604020238,
            0.015253336168825626,
            -0.03010602667927742,
            0.04622075334191322,
            0.03387060761451721,
            0.002345633925870061,
            0.0033042849972844124,
            0.014578036032617092,
            0.03957853838801384,
            -0.05341199040412903,
            -0.01351568941026926,
            0.02079436182975769,
            0.004753129091113806,
            0.0390387699007988,
            -0.0036945082247257233,
            0.028667625039815903,
            0.005218783393502235,
            -0.007244617212563753,
            -0.031544167548418045,
            -0.004675139673054218,
            0.018508270382881165,
            -0.032272763550281525,
            0.02435610257089138,
            -0.01759837195277214,
            0.011865148320794106,
            0.00908093061298132,
            -0.029238637536764145,
            0.0271233431994915,
            -0.010589680634438992,
            0.008784297853708267,
            0.003996596205979586,
            0.004736620932817459,
            0.02903381548821926,
            0.0285969115793705,
            -0.016941657289862633,
            -0.01201687753200531,
            0.024044737219810486,
            -0.015056108124554157,
            0.03046426549553871,
            -0.006465663202106953,
            -0.033677101135253906,
            -0.006490962579846382,
            0.004162645433098078,
            0.022597244009375572,
            0.004119581542909145,
            -0.03173202648758888,
            -0.0026119796093553305,
            -0.011256647296249866,
            -0.005623584613204002,
            -0.0003402026486583054,
            0.035477638244628906,
            0.00250145117752254,
            0.03458414599299431,
            -0.014149738475680351,
            0.013027410954236984,
            -0.032254528254270554,
            0.01598989963531494,
            0.01947209984064102,
            -0.009217729791998863,
            0.017438093200325966,
            0.009631195105612278,
            -0.01644073985517025,
            0.0011877835495397449,
            0.01312415674328804,
            0.01046863291412592,
            0.031031891703605652,
            -0.010250679217278957,
            -0.015308130532503128,
            -0.009592226706445217,
            -0.016878755763173103,
            0.026474053040146828,
            -0.0032477250788360834,
            -0.0010921387001872063,
            0.0011232903925701976,
            0.01353808119893074,
            -0.036358024924993515,
            0.021608849987387657,
            -0.03386862948536873,
            -0.005499481689184904,
            -0.05493439733982086,
            0.013220795430243015,
            -0.006964278407394886,
            0.017674386501312256,
            0.032718054950237274,
            -0.007622846867889166,
            0.013925164937973022,
            -0.008522355929017067,
            -0.010845589451491833,
            0.014042911119759083,
            -0.030461285263299942,
            0.018240079283714294,
            -0.022835856303572655,
            -0.0038994252681732178,
            0.04131001606583595,
            -0.016596190631389618,
            0.02923855371773243,
            -0.018825335428118706,
            -0.009340409189462662,
            -0.028577668592333794,
            0.03301836922764778,
            -0.007749113719910383,
            -0.02702902816236019,
            0.0010247190948575735,
            -0.020711194723844528,
            -0.007136711850762367,
            -0.025180822238326073,
            -0.009172576479613781,
            -0.00654528709128499,
            0.006701419595628977,
            0.007821043021976948,
            -0.010736992582678795,
            0.00028191349701955914,
            -0.025511791929602623,
            0.013848754577338696,
            0.043210677802562714,
            0.004170598462224007,
            -0.0021732666064053774,
            -0.0061060781590640545,
            -0.012913190759718418,
            -0.020495185628533363,
            -0.02834565006196499,
            0.027875524014234543,
            -0.004765854217112064,
            0.020967997610569,
            0.006858955603092909,
            -0.02976929023861885,
            0.013540389016270638,
            -0.01063756924122572,
            0.01959116943180561,
            -0.022616038098931313,
            0.0013600904494524002,
            0.02168240211904049,
            0.0008630207739770412,
            -0.02007296122610569,
            0.012014592997729778,
            0.0035394509322941303,
            0.03459016978740692,
            -0.026408560574054718,
            0.009574816562235355,
            -0.006143840961158276,
            -0.010911026038229465,
            -0.012511322274804115,
            -0.01171309407800436,
            -0.00804906152188778,
            0.05504598468542099,
            0.06850360333919525,
            -0.0012476163683459163,
            0.007627726532518864,
            -0.006465237587690353,
            0.015919450670480728,
            0.00011058550444431603,
            -0.03382145240902901,
            -0.002452772343531251,
            -0.005552798509597778,
            0.008203421719372272,
            -0.007905972190201283,
            0.004263763315975666,
            -0.0043168035335838795,
            -0.01204545795917511,
            -0.0034703025594353676,
            -0.006256560329347849,
            -0.020651422441005707,
            -0.019629446789622307,
            -0.012801640667021275,
            0.0007195824873633683,
            2.411716423011967e-06,
            0.0028625212144106627,
            0.02592051960527897,
            0.016270555555820465,
            0.029087668284773827,
            0.030357088893651962,
            0.04911675676703453,
            0.017198164016008377,
            0.027224188670516014,
            0.009605081751942635,
            0.03649982810020447,
            0.025035051628947258,
            -0.01202003937214613,
            0.009170143865048885,
            0.004790234379470348,
            0.0031847769860178232,
            -0.003660667920485139,
            0.008385495282709599,
            0.009024640545248985,
            0.026277702301740646,
            0.002726895036175847,
            0.002325821667909622,
            0.019823145121335983,
            -0.03059113398194313,
            -0.06024497002363205,
            -0.02358703315258026,
            0.027851954102516174,
            -0.01873360201716423,
            0.0027601695619523525,
            0.008956846781075,
            0.003607703372836113,
            -0.01499907672405243,
            0.03598056361079216,
            0.03865762799978256,
            0.03268265351653099,
            0.046366289258003235,
            0.029029961675405502,
            0.025978602468967438,
            -0.037797607481479645,
            0.026610109955072403,
            0.01718647964298725,
            0.007425097282975912,
            0.003401563037186861,
            -0.008802476339042187,
            0.032576896250247955,
            0.006092796567827463,
            0.02378731779754162,
            0.006611207965761423,
            0.002408333122730255,
            -0.035182561725378036,
            -0.030753253027796745,
            0.033384211361408234,
            0.04426394775509834,
            -0.005559504963457584,
            0.033669035881757736,
            -0.03761051222681999,
            0.008583856746554375,
            0.008515852503478527,
            0.00020028906874358654,
            0.0011939069954678416,
            -0.009676331654191017,
            -0.017073672264814377,
            -0.011472952552139759,
            -0.015517748892307281,
            0.022050054743885994,
            0.013970156200230122,
            0.03501712903380394,
            -0.02708430588245392,
            -0.050879061222076416,
            0.005864406004548073,
            0.03476940840482712,
            0.015546566806733608,
            -0.011647650972008705,
            0.028887731954455376,
            -0.02735021710395813,
            0.006973909214138985,
            0.01209227368235588,
            0.0011720384936779737,
            -0.012173846364021301,
            0.04153446853160858,
            -0.008389455266296864,
            -0.03195904567837715,
            0.0189877450466156,
            -0.014609645120799541,
            0.031116051599383354,
            -0.004051782190799713,
            0.023162931203842163,
            -0.005204669199883938,
            0.005689582321792841,
            0.0006106716464273632,
            -0.017174391075968742,
            0.016629159450531006,
            0.002865562215447426,
            0.0076445043087005615,
            0.01800115779042244,
            0.0018170041730627418,
            0.004601458087563515,
            -0.021508624777197838,
            -0.029665261507034302,
            -0.010141247883439064,
            -0.030497711151838303,
            -0.005101530347019434,
            -0.025622520595788956,
            0.03561825677752495,
            0.008241181261837482,
            -0.008962961845099926,
            0.05192536115646362,
            -0.014144038781523705,
            -0.03201984614133835,
            0.029632285237312317,
            -0.0017239373410120606,
            0.021208683028817177,
            0.03000486083328724,
            -0.004507301840931177,
            -0.012866105884313583,
            0.03623170033097267,
            0.004160474520176649,
            -0.03855590149760246,
            0.02852492593228817,
            -0.029097162187099457,
            0.036845363676548004,
            0.0235003512352705,
            0.018022123724222183,
            0.02566394954919815,
            -0.0035037172492593527,
            -0.05026595667004585,
            -0.010684577748179436,
            -0.008603229187428951,
            0.007548512890934944,
            -0.02495616488158703,
            0.021024247631430626,
            -0.0037645502015948296,
            0.009694976732134819,
            -0.08264094591140747,
            -0.052963726222515106,
            -0.03891595080494881,
            0.017645256593823433,
            -0.01276487112045288,
            0.0029268511570990086,
            0.012136882171034813,
            -0.01006777212023735,
            -0.0248697642236948,
            0.017720496281981468,
            0.02671772986650467,
            -0.010344683192670345,
            0.027588319033384323,
            -0.02959415689110756,
            0.0005302618956193328,
            -0.013956167735159397,
            0.02783593349158764,
            -0.03540319576859474,
            -0.04929583519697189,
            -0.0025986141990870237,
            0.0299157053232193,
            -0.008380009792745113,
            -0.011806836351752281,
            0.009273236617445946,
            -0.024014471098780632,
            -0.024887925013899803,
            0.00012459915888030082,
            0.004778499249368906,
            -0.021202165633440018,
            0.04246732220053673,
            -0.024077242240309715,
            0.03208426386117935,
            -0.019574377685785294,
            0.0006840139394626021,
            0.02215232141315937,
            0.0202373918145895,
            0.02148570492863655,
            -0.02807537466287613,
            0.006945849396288395,
            -0.022967470809817314,
            -0.018414458259940147,
            -0.033755917102098465,
            0.01433921605348587,
            -0.015884356573224068,
            -0.016961796209216118,
            -0.04662460833787918,
            0.0336146242916584,
            -0.040690626949071884,
            0.007529293652623892,
            -0.02825579047203064,
            -0.004543191287666559,
            -0.0009230565628968179,
            0.016158346086740494,
            0.024481970816850662,
            0.025554345920681953,
            -0.004296851810067892,
            -0.04383013769984245,
            -0.02571481466293335,
            0.0013146812561899424,
            0.003921180032193661,
            0.026961570605635643,
            0.012570861726999283,
            -0.04119499772787094,
            0.01046406477689743,
            0.004266007337719202,
            -0.00959907565265894,
            -0.009714084677398205,
            0.009677146561443806,
            0.02264057658612728,
            0.02272133156657219,
            -0.012911636382341385,
            0.02709091082215309,
            -0.0027118322905153036,
            -0.021411199122667313,
            -0.009686997160315514,
            0.02324794977903366,
            0.01600693166255951,
            0.020610550418496132,
            0.028607044368982315,
            -0.020514048635959625,
            -0.01724429987370968,
            -0.002768302569165826,
            -0.0017843820387497544,
            0.0354330874979496,
            0.019533760845661163,
            0.014153101481497288,
            0.0049026161432266235,
            -0.022047510370612144,
            0.013883336447179317,
            0.02673332579433918,
            -0.027736375108361244,
            0.002076017903164029,
            0.031172815710306168,
            -0.024055590853095055,
            0.016463344916701317,
            -0.012865539640188217,
            0.022531859576702118,
            0.014920147135853767,
            -0.000983768724836409,
            -0.01284222211688757,
            0.006734003312885761,
            0.015630098059773445,
            0.02233099937438965,
            -0.005552658811211586,
            0.02588658221065998,
            0.07722148299217224,
            -0.07201492786407471,
            -0.006952597759664059,
            -0.017217475920915604,
            -0.0007322127348743379,
            -0.02006683126091957,
            0.016865652054548264,
            -0.0033179752063006163,
            0.025330565869808197,
            0.021682659164071083,
            -0.03298807144165039,
            -0.027029752731323242,
            -0.009029064327478409,
            -0.006708426401019096,
            -0.01735948584973812,
            0.0236495491117239,
            -0.02722572162747383,
            0.010338904336094856,
            0.04773278161883354,
            -0.009719730354845524,
            0.019689716398715973,
            0.005082748830318451,
            0.004652620293200016,
            0.0327364020049572,
            0.030269678682088852,
            0.02393362671136856,
            0.02161100134253502,
            0.0035436670295894146,
            -0.0386069156229496,
            -0.026390139013528824,
            -0.0010772023815661669,
            0.02564755268394947,
            0.019024042412638664,
            0.03553711622953415,
            0.025907650589942932,
            0.019347243010997772,
            -0.03376275300979614,
            -0.03051092103123665,
            -0.01303273905068636,
            0.0006356099038384855,
            0.013400889001786709,
            -0.03792285546660423,
            -0.004386969842016697,
            0.023499123752117157,
            -0.0005672812112607062,
            -0.02531471662223339,
            0.007926535792648792,
            -0.006542759016156197,
            0.04770771414041519,
            0.033612582832574844,
            0.011135819368064404,
            0.02445557527244091,
            -0.023168470710515976,
            0.002539138775318861,
            -0.042105112224817276,
            0.039411965757608414,
            -0.038058873265981674,
            -0.0016052407445386052,
            -0.03011319227516651,
            0.01373535580933094,
            0.0029410801362246275,
            0.026948226615786552,
            -0.018585555255413055,
            -0.01587589457631111,
            0.006642614025622606,
            -0.03406163305044174,
            -0.009541171602904797,
            -0.002106761559844017,
            -0.029671747237443924,
            -0.015901988372206688,
            0.007587358355522156,
            -0.049464013427495956,
            0.009201289154589176,
            -0.01637388952076435,
            0.03243018686771393,
            0.0247359499335289,
            0.01687691919505596,
            0.03391547128558159,
            -0.017468132078647614,
            0.007094806060194969,
            -0.005313495639711618,
            -0.022810915485024452,
            -0.01214540284126997,
            0.032618071883916855,
            -0.022713545709848404,
            -0.018587831407785416,
            -0.009031900204718113,
            -0.01076741237193346,
            -0.03343608230352402,
            0.009267795830965042,
            0.014924428425729275,
            -0.0062622795812785625,
            0.003528052242472768,
            -0.005351485684514046,
            -0.027586495503783226,
            0.004020608961582184,
            0.01542008575052023,
            -0.007633070461452007,
            0.014197183772921562,
            0.015369490720331669,
            0.0063562290742993355,
            -0.008516131900250912,
            -0.005262297578155994,
            0.03763177990913391,
            0.025745533406734467,
            -0.015956800431013107,
            -0.039758309721946716,
            -0.005146089009940624,
            -0.023440614342689514,
            0.0032890543807297945,
            -0.008771651424467564,
            -0.03358233720064163,
            -0.017479995265603065,
            0.0013286691391840577,
            0.0015258853090927005,
            0.024425243958830833,
            0.017294537276029587,
            0.002259685890749097,
            -0.014656607061624527,
            -0.029242968186736107,
            0.029572775587439537,
            -0.022027475759387016,
            -0.010178884491324425,
            -0.027987267822027206,
            -0.013045073486864567,
            0.024187857285141945,
            0.023771904408931732,
            -0.0033509712666273117,
            -0.0153450733050704,
            0.028933677822351456,
            0.003649497404694557,
            -0.005589899141341448,
            0.013854476623237133,
            0.020221618935465813,
            -0.008917062543332577,
            0.02733469009399414,
            0.05501141399145126,
            -0.02079596184194088,
            0.02605951763689518,
            -0.021451637148857117,
            0.0030211650300771,
            -0.013413206674158573,
            -0.02813587710261345,
            -0.006961268838495016,
            -0.005506717134267092,
            -0.02247532643377781,
            -0.05602962151169777,
            -0.013064561411738396,
            -0.03404950350522995,
            0.013257309794425964,
            0.026242123916745186,
            0.03150803968310356,
            -0.04691058024764061,
            -0.04141863062977791,
            -0.027727078646421432,
            0.015906859189271927,
            0.0066122934222221375,
            0.023569831624627113,
            -0.02066534385085106,
            0.028971759602427483,
            0.011884432286024094,
            -0.02919073961675167,
            -0.009046204388141632,
            -0.02074817754328251,
            -0.012711490504443645,
            0.006716128904372454,
            -0.017672689631581306,
            -0.017034446820616722,
            -0.03021974116563797,
            0.026561496779322624,
            0.021463314071297646,
            0.0024430740159004927,
            0.008097459562122822,
            -0.027740374207496643,
            -0.016480471938848495,
            -0.002955046249553561,
            -0.019798463210463524,
            0.020779486745595932,
            -0.02090216800570488,
            -0.027555014938116074,
            0.026755696162581444,
            -0.0008826153352856636,
            0.01519712619483471,
            -0.015828819945454597,
            -0.005615621339529753,
            -0.04242695868015289,
            -0.0047188326716423035,
            0.036440979689359665,
            0.018299400806427002,
            -0.02693087048828602,
            -0.026939604431390762,
            0.00017913911142386496,
            -0.02270314283668995,
            0.04368860274553299,
            0.026288336142897606,
            0.03632142022252083,
            -0.012755009345710278,
            0.03739191219210625,
            -0.022152075543999672,
            0.039576586335897446,
            0.02533724159002304,
            -0.0012991499388590455,
            0.01511937752366066,
            -0.015405818819999695,
            0.004606526345014572,
            -0.022223617881536484,
            -0.014438007958233356,
            -0.03611075133085251,
            -0.04662207141518593,
            -0.013128935359418392,
            0.028270253911614418,
            -0.010397863574326038,
            0.0133912218734622,
            -0.020158929750323296,
            0.03067217767238617,
            -0.0008451133617199957,
            0.003647963749244809,
            0.006473650690168142,
            0.048707373440265656,
            0.02291211299598217,
            -0.0013929870910942554,
            -0.02336871065199375,
            -0.029527291655540466,
            -0.00036471005296334624,
            -0.02164197340607643,
            -0.024347839877009392,
            0.01857670210301876,
            -0.023102402687072754,
            0.016926037147641182,
            -0.030869390815496445,
            0.016359183937311172,
            0.02536405622959137,
            0.032477036118507385,
            0.034214090555906296,
            0.029541034251451492,
            -0.008521607145667076,
            0.015676436945796013,
            0.004629415459930897,
            0.01614983193576336,
            -0.0026929243467748165,
            -0.019665034487843513,
            -0.01400894857943058,
            -0.028486447408795357,
            0.03306325152516365,
            0.020860567688941956,
            0.014030398800969124,
            0.015542729757726192,
            -0.014334120787680149,
            0.032605547457933426,
            -0.02627146989107132,
            -0.010824659839272499,
            0.013941519893705845,
            -0.012106136418879032,
            -0.022780047729611397,
            -0.027005081996321678,
            0.023416489362716675,
            -0.03418944776058197,
            0.0031411247327923775,
            -0.012071214616298676,
            -0.015100428834557533,
            0.0040668766014277935,
            -0.02041759341955185,
            0.02450384944677353,
            -0.002877303631976247,
            0.00861822348088026,
            0.0024270096328109503,
            0.032141655683517456,
            0.025284526869654655,
            -0.022575251758098602,
            -0.061767540872097015,
            -0.009642654098570347,
            0.010032448917627335,
            0.031148871406912804,
            0.021366167813539505,
            0.04125336557626724,
            -0.0025801067240536213,
            -0.015074040740728378,
            -0.01851981319487095
        ],
        [
            0.02396981790661812,
            -0.039758820086717606,
            -0.018713822588324547,
            0.02714589051902294,
            0.021676959469914436,
            -0.05498002469539642,
            -0.012368330731987953,
            -0.004258947912603617,
            -0.020579269155859947,
            0.003203170606866479,
            0.03620664030313492,
            -0.01351466029882431,
            -0.018104277551174164,
            -4.3625868784147315e-06,
            0.03132263198494911,
            -0.03469090908765793,
            0.026889393106102943,
            0.01326113659888506,
            -0.01954161562025547,
            0.006646336521953344,
            0.031456321477890015,
            0.005292094778269529,
            -0.03665317967534065,
            -0.034702859818935394,
            0.03721960261464119,
            -0.014514502137899399,
            -0.022111324593424797,
            -0.058276884257793427,
            0.006971788592636585,
            0.002282708417624235,
            0.018408112227916718,
            -0.024026375263929367,
            0.03514539450407028,
            0.01113501749932766,
            0.018221953883767128,
            -0.05948135629296303,
            0.017436441034078598,
            0.01073133759200573,
            -0.028824206441640854,
            6.0738762840628624e-05,
            -0.04787735641002655,
            -0.006261312868446112,
            0.011457505635917187,
            0.03317752853035927,
            -0.02093600481748581,
            -0.014626536518335342,
            -0.0024194084107875824,
            -0.02382248267531395,
            -0.046484556049108505,
            -0.0028472088743001223,
            0.0062476820312440395,
            -0.010163792409002781,
            -0.013107240200042725,
            -0.012756165117025375,
            -0.006647167261689901,
            0.009617053903639317,
            -0.025889230892062187,
            -0.04376734048128128,
            0.03944582864642143,
            0.01486615464091301,
            0.05066196992993355,
            0.011592849157750607,
            0.03325008973479271,
            0.0014123987639322877,
            -0.019551504403352737,
            0.02185691148042679,
            0.009189490228891373,
            0.022192349657416344,
            -0.012452280148863792,
            -0.023822886869311333,
            0.0009152860729955137,
            -0.018445337191224098,
            0.00931299477815628,
            -0.03140971064567566,
            -0.030132364481687546,
            -0.00017210673831868917,
            -0.02020619623363018,
            -0.022797346115112305,
            0.027521129697561264,
            0.016841156408190727,
            9.197702456731349e-05,
            -0.0009761098772287369,
            0.030269542708992958,
            0.02887057699263096,
            -0.028348146006464958,
            0.022051984444260597,
            -0.0008023162372410297,
            -0.05312536284327507,
            0.017974896356463432,
            0.02519502304494381,
            0.01432652398943901,
            -0.03351004421710968,
            0.038170624524354935,
            0.017802655696868896,
            0.050530992448329926,
            0.01209657359868288,
            -0.04123246669769287,
            -0.00015377401723526418,
            -0.05648380517959595,
            0.032862644642591476,
            -0.022208623588085175,
            -0.011052175424993038,
            -0.015457800589501858,
            0.027202311903238297,
            -0.011947695165872574,
            -0.04861157760024071,
            0.03203826770186424,
            0.025334754958748817,
            0.057207830250263214,
            0.005100139882415533,
            0.010513019748032093,
            -0.0021131972316652536,
            -0.028827905654907227,
            -0.03259478136897087,
            0.023324046283960342,
            0.01570364460349083,
            -0.0036475518718361855,
            0.002152015920728445,
            0.02162269689142704,
            0.039009321480989456,
            -0.023904306814074516,
            -0.019736694172024727,
            0.029464907944202423,
            -0.026689739897847176,
            -0.030436793342232704,
            0.013310610316693783,
            0.008263805881142616,
            0.004540094640105963,
            0.006043208763003349,
            -0.009655616246163845,
            -0.008891194127500057,
            0.012469318695366383,
            0.04928832873702049,
            -0.02400265634059906,
            0.0011199788423255086,
            -0.007546722888946533,
            -0.019350562244653702,
            -0.043009087443351746,
            0.023910880088806152,
            0.01917082443833351,
            0.023985605686903,
            -0.02001970261335373,
            -0.043092209845781326,
            -0.009770088829100132,
            -0.0238469410687685,
            0.029582113027572632,
            -0.017921634018421173,
            0.04420437663793564,
            -0.010222268290817738,
            0.0036908637266606092,
            -0.0059397267177701,
            0.029260940849781036,
            -0.0011268166126683354,
            0.05248832330107689,
            -0.026926623657345772,
            -0.032607950270175934,
            -0.019814180210232735,
            0.010287501849234104,
            -0.01690025068819523,
            -0.005855577532202005,
            0.028309660032391548,
            -0.018564045429229736,
            0.0099190017208457,
            0.00877438671886921,
            -0.000729631632566452,
            0.004785461816936731,
            0.02335812896490097,
            -0.04640422388911247,
            -0.03280671685934067,
            -0.0034734546206891537,
            0.019412904977798462,
            0.004787821788340807,
            -0.05756981670856476,
            0.012303045950829983,
            -0.00787273421883583,
            0.0284056905657053,
            -0.014223720878362656,
            -0.010780285112559795,
            -0.03492476046085358,
            0.004757074639201164,
            -0.0025199123192578554,
            -0.0046621966175735,
            -0.020509254187345505,
            -0.018450330942869186,
            0.03246637433767319,
            -0.010870832949876785,
            -0.031588971614837646,
            -0.0033646291121840477,
            -0.02315467782318592,
            0.022227248176932335,
            0.015151827596127987,
            -0.004448737949132919,
            -0.035374514758586884,
            0.00870152935385704,
            -0.0109383100643754,
            0.03177688643336296,
            0.010361092165112495,
            -0.040284473448991776,
            -0.022309046238660812,
            0.02924492582678795,
            -0.01611088402569294,
            -0.021181417629122734,
            0.006295794155448675,
            -0.017566261813044548,
            -0.020122798159718513,
            -0.0011403608368709683,
            -0.022002940997481346,
            0.0032836284954100847,
            0.005998055916279554,
            -0.01751420460641384,
            0.0003251593152526766,
            0.001432308810763061,
            0.028538282960653305,
            0.028488554060459137,
            -0.010446760803461075,
            -0.008285677060484886,
            -0.028447050601243973,
            0.035145629197359085,
            -0.031108887866139412,
            0.02411334589123726,
            0.03019857592880726,
            0.004089623223990202,
            -0.0083558838814497,
            -0.022019490599632263,
            0.0161345973610878,
            -0.041489679366350174,
            0.017391392961144447,
            -0.01295158639550209,
            -0.03242899104952812,
            -0.017944615334272385,
            0.0031595982145518064,
            -0.020708955824375153,
            -0.0031188856810331345,
            -0.027582263574004173,
            -0.01437352318316698,
            0.03717055916786194,
            0.03699476644396782,
            0.005672241095453501,
            0.0030174634885042906,
            -0.024099187925457954,
            0.0074929469265043736,
            0.019199848175048828,
            0.023785334080457687,
            -0.015330798923969269,
            0.01258005015552044,
            0.02796667441725731,
            0.017713474109768867,
            0.026154307648539543,
            -0.006561359856277704,
            -0.021840205416083336,
            0.012929404154419899,
            -0.041951581835746765,
            0.017345329746603966,
            -0.01159563846886158,
            0.03478459268808365,
            0.01965426653623581,
            0.0021667135879397392,
            -0.014065541326999664,
            0.025684837251901627,
            0.019525589421391487,
            0.01573748141527176,
            0.06147914007306099,
            -0.01588774286210537,
            0.022129034623503685,
            0.012592540122568607,
            -0.019295383244752884,
            0.030742909759283066,
            0.017254801467061043,
            -0.0016526618273928761,
            0.03619819134473801,
            -0.030101168900728226,
            -0.04190562292933464,
            -0.024753529578447342,
            0.005271103233098984,
            -0.022438984364271164,
            -0.03894805163145065,
            0.03465113043785095,
            -0.035898394882678986,
            0.04082510992884636,
            0.012781941331923008,
            -0.04697497934103012,
            0.0004174419736955315,
            0.002414490096271038,
            -0.019465897232294083,
            0.019311077892780304,
            -0.008386391215026379,
            -0.0027676441241055727,
            -0.01701277308166027,
            -0.015811027958989143,
            -0.02266312576830387,
            0.0272695180028677,
            0.026950139552354813,
            0.0032702137250453234,
            -0.005305636674165726,
            0.0016853068955242634,
            0.0012671197764575481,
            -0.045313511043787,
            0.005199810490012169,
            -0.018453776836395264,
            0.0032042143866419792,
            -0.026953835040330887,
            -0.03366661071777344,
            -0.04548586532473564,
            -0.005350384395569563,
            0.03162143751978874,
            -0.004562024027109146,
            -0.020720921456813812,
            0.047220565378665924,
            -0.01628289557993412,
            0.024751733988523483,
            -0.00558874337002635,
            0.01681683398783207,
            -0.034389596432447433,
            0.0135691212490201,
            0.01472239289432764,
            0.0141847999766469,
            0.015853896737098694,
            0.04374415799975395,
            0.003220559796318412,
            0.00040850829100236297,
            -0.012683773413300514,
            0.0024289779830724,
            0.02427632361650467,
            -0.019688665866851807,
            0.02293049916625023,
            0.00011469314631540328,
            0.032980598509311676,
            0.016353348270058632,
            -0.042334213852882385,
            -0.0012993891723453999,
            0.023642271757125854,
            -0.006962413899600506,
            0.0164449755102396,
            -0.03187236189842224,
            0.010336251929402351,
            0.025803808122873306,
            -0.007752306759357452,
            -0.011833344586193562,
            -0.003586612641811371,
            -0.0336991623044014,
            0.0035554138012230396,
            0.01782349683344364,
            0.0014958473620936275,
            0.013618444092571735,
            0.01660868152976036,
            0.03469662368297577,
            -0.027850663289427757,
            -0.013184723444283009,
            0.01653280295431614,
            -0.024208584800362587,
            -0.005038756877183914,
            0.01742405816912651,
            -0.010432190261781216,
            -0.01638326421380043,
            0.029466133564710617,
            -0.018869316205382347,
            -0.005909325554966927,
            0.038065213710069656,
            -0.031133200973272324,
            -0.01698584295809269,
            -0.012037803418934345,
            -0.009404218755662441,
            -0.027382176369428635,
            -0.03021513670682907,
            0.03160524368286133,
            -0.02281970903277397,
            0.013744626194238663,
            -0.042908795177936554,
            0.00012205890379846096,
            0.020546140149235725,
            -0.009619057178497314,
            -0.01879548281431198,
            -0.008326378650963306,
            -0.024116341024637222,
            -0.00976524967700243,
            0.002665421925485134,
            0.018657581880688667,
            -0.018222473561763763,
            -0.009435450658202171,
            0.02714431658387184,
            -0.04946528375148773,
            0.01902644708752632,
            0.021654529497027397,
            0.009982018731534481,
            -0.021090898662805557,
            -0.03100661002099514,
            0.019537270069122314,
            -0.0050278836861252785,
            -0.003692918922752142,
            0.024203678593039513,
            0.007133684121072292,
            0.013255027122795582,
            -0.0064300112426280975,
            0.01414790190756321,
            0.022960256785154343,
            0.020899822935461998,
            0.008432397618889809,
            0.00298605696298182,
            0.000369490182492882,
            -0.022321021184325218,
            0.03689311817288399,
            -0.019720232114195824,
            -0.003976318519562483,
            0.017013315111398697,
            0.002501812530681491,
            -0.0006058719591237605,
            0.011303333565592766,
            0.017275342717766762,
            0.030225198715925217,
            0.018927261233329773,
            -0.0021252974402159452,
            -0.0051385946571826935,
            -0.004531584680080414,
            -0.043408092111349106,
            -0.012251769192516804,
            0.01984487473964691,
            0.0022814779076725245,
            -0.0009167800308205187,
            0.018694421276450157,
            0.011613897047936916,
            -0.020587073639035225,
            0.006828103214502335,
            0.016297074034810066,
            0.006461188662797213,
            -0.02342614158987999,
            0.03345639258623123,
            0.0487353578209877,
            -0.010304903611540794,
            -0.01464893389493227,
            0.017642097547650337,
            -0.02470225840806961,
            0.02133680135011673,
            -0.013942157849669456,
            -0.020138731226325035,
            0.03519819304347038,
            -0.035150252282619476,
            0.03722912073135376,
            -0.06359365582466125,
            -0.011822279542684555,
            0.03051391802728176,
            0.012541940435767174,
            0.031000349670648575,
            0.0017580183921381831,
            0.02545394003391266,
            0.02601224184036255,
            0.021687345579266548,
            -0.013198276050388813,
            -0.0668722540140152,
            0.00104439165443182,
            -0.06415297836065292,
            0.03262891620397568,
            -0.00715172104537487,
            -0.0070854476653039455,
            -0.004745840094983578,
            -0.016740286722779274,
            -0.01905330829322338,
            0.007371335756033659,
            -0.009531302377581596,
            0.006984309758991003,
            0.015139482915401459,
            -0.006685590837150812,
            0.023223841562867165,
            0.020769087597727776,
            0.00438470346853137,
            -0.046615008264780045,
            0.020953118801116943,
            0.01388357300311327,
            -0.038767047226428986,
            -0.022237930446863174,
            0.03334715589880943,
            0.007584664039313793,
            -0.0007124199182726443,
            0.02451709844172001,
            0.000655381300020963,
            0.0076502347365021706,
            -0.03195441514253616,
            0.011766357347369194,
            -0.020514298230409622,
            -0.015684761106967926,
            -0.021438511088490486,
            0.037056416273117065,
            -0.01624996028840542,
            -0.008656997233629227,
            -0.015511034056544304,
            -0.036556243896484375,
            0.01959376409649849,
            -0.02644098363816738,
            0.006160110700875521,
            0.030202848836779594,
            -0.03736972436308861,
            0.01791691966354847,
            -0.005028132814913988,
            0.013446491211652756,
            -0.007256429176777601,
            -0.05408274754881859,
            0.008861847221851349,
            0.026308588683605194,
            -0.029848696663975716,
            0.018679890781641006,
            0.02062436379492283,
            -0.023047855123877525,
            -0.02846447378396988,
            -0.03059605322778225,
            0.003061574650928378,
            -0.0219500083476305,
            0.011138652451336384,
            -0.01968989335000515,
            -0.021801507100462914,
            0.009725100360810757,
            -0.020302612334489822,
            0.007440503221005201,
            -0.0025381497107446194,
            0.008464537560939789,
            0.01610727608203888,
            0.022436778992414474,
            -0.014999858103692532,
            -0.04609972983598709,
            0.0068511804565787315,
            -0.005992758087813854,
            -0.020523378625512123,
            0.037668753415346146,
            0.029650112614035606,
            0.01594890095293522,
            -0.011037459596991539,
            0.008641596883535385,
            -0.012197162955999374,
            0.007927069440484047,
            0.01768815889954567,
            -0.009317457675933838,
            0.007725185714662075,
            -0.01712477020919323,
            -0.003538255812600255,
            0.03314519301056862,
            0.020747477188706398,
            -0.014944975264370441,
            0.02732963301241398,
            0.010636559687554836,
            -0.012920713983476162,
            -0.03241420537233353,
            -0.007727255113422871,
            0.022602878510951996,
            0.009472871199250221,
            -0.024285173043608665,
            -0.05125606432557106,
            0.0027428732719272375,
            -0.0030237988103181124,
            -0.01496234629303217,
            -0.05979134514927864,
            0.011351984925568104,
            0.019079571589827538,
            0.028671909123659134,
            0.012847702950239182,
            -0.003300293581560254,
            0.02948872186243534,
            0.005506528075784445,
            -0.04266931861639023,
            -0.04845266789197922,
            -0.009303168393671513,
            0.0022919480688869953,
            -0.0047737290151417255,
            -0.01876666769385338,
            -0.010427488014101982,
            0.012313821353018284,
            -0.0018971218960359693,
            -0.011164798401296139,
            -0.021116027608513832,
            0.01254864502698183,
            -0.013598423451185226,
            -0.030803503468632698,
            -0.015136142261326313,
            -0.02159486524760723,
            -0.06172221899032593,
            -0.023550231009721756,
            0.007578766439110041,
            -0.021569520235061646,
            -0.013003277592360973,
            0.005971882492303848,
            0.003018592717126012,
            0.04199901968240738,
            -0.029350582510232925,
            -0.025729645043611526,
            0.026465298607945442,
            0.0074592288583517075,
            -0.010682986117899418,
            -0.02651185169816017,
            0.024609195068478584,
            -0.0012236395850777626,
            0.004044545814394951,
            -0.01893741264939308,
            -0.02414809539914131,
            0.028964165598154068,
            0.01951986365020275,
            -0.0002319307968718931,
            0.03879700228571892,
            -0.0066655483096838,
            -0.046558480709791183,
            -0.02583535760641098,
            -0.03095913492143154,
            -0.0210038423538208,
            -0.050391245633363724,
            -0.011968209408223629,
            -0.03566770255565643,
            -0.018189985305070877,
            0.021329240873456,
            -0.022030413150787354,
            -0.02185913547873497,
            0.029631230980157852,
            0.02044108137488365,
            0.0376417301595211,
            -0.015511843375861645,
            0.023087169975042343,
            -0.03086961805820465,
            0.0303332656621933,
            -0.00357980839908123,
            -0.017690546810626984,
            -0.016065213829278946,
            -0.01942569948732853,
            -0.009590499103069305,
            -0.005180384963750839,
            0.03339641913771629,
            -0.005524102598428726,
            -0.016969984397292137,
            -0.01335185021162033,
            0.023263540118932724,
            -0.01839395798742771,
            0.032411959022283554,
            0.022231528535485268,
            -0.009795076213777065,
            0.031228672713041306,
            -0.017912356182932854,
            -0.025834448635578156,
            0.013294410891830921,
            -0.004149009473621845,
            -0.018579917028546333,
            0.005657217465341091,
            0.0013385500060394406,
            -0.009190726093947887,
            -0.043956175446510315,
            -0.03085820935666561,
            0.008053962141275406,
            0.01747162826359272,
            0.011918719857931137,
            0.007290404289960861,
            0.013874898664653301,
            -0.005077930632978678,
            -0.02380358800292015,
            0.013702663592994213,
            -0.019899962469935417,
            0.008016439154744148,
            -0.0002821233356371522,
            0.02588222734630108,
            0.02403690107166767,
            -0.014499856159090996,
            -0.010151274502277374,
            -0.020292481407523155,
            -0.03827940300107002,
            -0.025600824505090714,
            -0.002166098216548562,
            0.0019519993802532554,
            0.03140728920698166,
            0.023051226511597633,
            0.02534678764641285,
            0.031195921823382378,
            0.0087633291259408,
            0.031489282846450806,
            -0.009227143600583076,
            -0.04686174541711807,
            0.02046825736761093,
            -0.02193097211420536,
            0.007805617526173592,
            -0.014038843102753162,
            0.0233521219342947,
            -0.021826334297657013,
            0.035321347415447235,
            0.027797697111964226,
            0.011873345822095871,
            0.008917699567973614,
            -0.010450134053826332,
            0.010061209090054035,
            0.031245119869709015,
            -0.03642816096544266,
            0.020262500271201134,
            0.011372596956789494,
            0.005310880485922098,
            0.00853417906910181,
            0.007082789670675993,
            -0.007050071377307177,
            -0.04117031767964363,
            0.011766362935304642,
            -0.03955956920981407,
            0.023835310712456703,
            -0.02418087050318718,
            -0.031360428780317307,
            0.018414504826068878,
            0.025042792782187462,
            -0.0230892114341259,
            0.002685264917090535,
            0.017123090103268623,
            -0.00023549816978629678,
            0.012902626767754555,
            0.006841251626610756,
            0.04448042809963226,
            -0.022302450612187386,
            -0.0037869696971029043,
            -0.010267679579555988,
            -0.017265165224671364,
            0.006894906051456928,
            -0.03690953552722931,
            -0.03933211788535118,
            0.06436765938997269,
            -0.0052416264079511166,
            0.008203280158340931,
            -0.00915351789444685,
            -0.024116406217217445,
            -0.028303857892751694,
            0.02545490674674511,
            -0.042697519063949585,
            -0.0052225664258003235,
            0.02821420691907406,
            0.005236602388322353,
            0.039351776242256165,
            -0.009667031466960907,
            0.028388837352395058,
            0.019425194710493088,
            0.015018276870250702,
            -0.0006106734508648515,
            0.012726922519505024,
            -0.01147729717195034,
            0.00031977568869479,
            0.00595640717074275,
            -0.009003128856420517,
            0.012625491246581078,
            0.015448615886271,
            -0.018092216923832893,
            0.0054021128453314304,
            -0.02376220002770424,
            -0.020245034247636795,
            -0.00021948266658000648,
            -0.05627638101577759,
            -0.008061090484261513,
            0.027025850489735603,
            -0.017930002883076668,
            0.027509769424796104,
            -0.0026776965241879225,
            0.021788297221064568,
            -0.004729089792817831,
            0.03159237280488014,
            -0.022879967465996742,
            -0.0016896656015887856,
            0.019342029467225075,
            -0.015966517850756645,
            -0.0072683002799749374,
            -0.03237633407115936,
            -0.09257932752370834,
            0.017775142565369606,
            -0.012914533726871014,
            -0.002348089125007391,
            0.030258577316999435,
            0.003542474005371332,
            -0.037357062101364136,
            -0.008810826577246189,
            0.021339012309908867,
            -0.011842033825814724,
            -0.00410854909569025,
            0.02030750922858715,
            0.047774698585271835,
            0.02444327063858509,
            0.010404465720057487,
            0.015402867458760738,
            0.0067554437555372715,
            -0.0011646384373307228,
            0.00666431849822402,
            -0.033314403146505356,
            -0.033437687903642654,
            -0.012735310010612011,
            0.025792669504880905,
            -0.006950842682272196,
            -0.01084114983677864,
            -0.05379596725106239,
            -0.021825062111020088,
            0.031616177409887314,
            -0.024068603292107582,
            -0.022548483684659004,
            0.009427135810256004,
            0.02392154559493065,
            -0.0349118635058403,
            -0.023401552811264992,
            -0.00984082743525505,
            -0.020557884126901627,
            0.032348766922950745,
            -0.03846593201160431,
            0.0013894373551011086,
            0.018301120027899742,
            0.009344277903437614,
            0.0028682772535830736,
            0.02206088788807392,
            -0.0027154595591127872,
            0.032896917313337326,
            -0.028470614925026894,
            0.010004098527133465,
            -0.03826109319925308,
            -0.012212193571031094,
            -0.0003464408218860626,
            -0.04667062684893608,
            0.02988177351653576,
            -0.06624110043048859,
            -0.0029476250056177378,
            0.010942257009446621,
            -0.003505101427435875,
            -0.05295231565833092,
            -0.013949211686849594,
            0.02652953751385212,
            -0.019365673884749413,
            0.03182077780365944,
            0.04389319568872452,
            -0.011625508777797222,
            0.00678588030859828,
            0.024584172293543816,
            0.021753408014774323,
            0.0033859331160783768,
            -0.033690955489873886,
            0.009881245903670788,
            -0.025292010977864265,
            0.03035551682114601,
            0.05253332108259201,
            -0.007436348125338554,
            -0.021241463720798492,
            -0.02121354080736637,
            -0.019429076462984085,
            -0.02821178175508976,
            -0.0467931404709816,
            0.016405552625656128,
            0.0401461236178875,
            0.048565760254859924,
            0.01673903688788414,
            -0.020956819877028465,
            0.010172390379011631,
            0.03152168169617653,
            -0.012408163398504257,
            -0.022687995806336403,
            0.023082643747329712,
            0.0014292700216174126,
            -0.02605794556438923,
            -0.023651791736483574,
            0.011073583737015724,
            -0.04510211944580078,
            -0.008383024483919144,
            0.02502753585577011,
            -0.043910279870033264,
            0.010051456280052662,
            0.005902288015931845,
            0.014322527684271336,
            -0.014292995445430279,
            -0.012111512944102287,
            0.017093945294618607,
            0.01751723326742649,
            -0.0155482804402709,
            0.00808622408658266,
            0.021108362823724747,
            -0.020879168063402176,
            -0.017793618142604828,
            -0.04676646739244461,
            -0.03425690159201622,
            0.014559974893927574,
            0.030933422967791557,
            0.029116317629814148,
            -0.008810605853796005,
            -0.03354666009545326,
            0.031128311529755592,
            -0.03586374223232269,
            -0.003325480967760086,
            -0.006759059149771929,
            0.00042637644219212234,
            -0.00605759397149086,
            -0.0029476964846253395,
            0.039285872131586075,
            0.026843642815947533,
            -0.021260054782032967,
            0.008800896815955639,
            -0.009700579568743706,
            0.05850406736135483,
            -0.017421767115592957,
            0.039460815489292145,
            -0.011423539370298386,
            -0.007526600267738104,
            -0.029134513810276985,
            -0.00019384930783417076,
            -0.017197508364915848,
            0.0006147075910121202,
            0.02168179489672184,
            0.008509909734129906,
            0.023760104551911354,
            -0.020197883248329163,
            0.04107588157057762,
            -0.034242648631334305,
            -0.02777584083378315,
            -0.00959150679409504,
            0.00492614321410656,
            -0.010633736848831177,
            -0.0008849877049215138,
            0.037283673882484436,
            -0.03596070036292076,
            0.0026496124919503927,
            -0.008087283931672573,
            -0.03100118599832058,
            0.013730838894844055,
            -0.003128553507849574,
            0.0023196525871753693,
            -0.00692108366638422,
            -0.01634650118649006,
            -0.004520860966295004,
            0.0006121281185187399,
            0.011816933751106262,
            -0.011225081980228424,
            -0.02461974509060383,
            0.01155330054461956,
            -0.005462830886244774,
            -0.03700963407754898,
            -0.02865465171635151,
            -0.009512372314929962,
            -0.011815052479505539,
            -0.07391805946826935,
            -0.0067328098230063915,
            0.010423158295452595,
            -0.03307661786675453,
            -0.021108785644173622,
            -0.009658677503466606,
            -0.030605388805270195,
            0.007984563708305359,
            0.030520346015691757,
            -0.0019027880625799298,
            0.011388035491108894,
            0.003663410199806094,
            -0.019739242270588875,
            0.03138580545783043,
            -0.02909289114177227,
            -0.018229635432362556,
            0.020497102290391922,
            -0.016388138756155968,
            0.027804404497146606,
            -0.01422919426113367,
            -0.04097236320376396,
            0.023926284164190292,
            0.000831799756269902,
            0.008397528901696205,
            -0.001949781202711165,
            -0.013617477379739285,
            -0.09249544888734818,
            -0.027305323630571365,
            0.028797002509236336,
            0.07807493954896927,
            -0.0073325131088495255,
            0.012080708518624306,
            -0.00341579201631248,
            0.029689326882362366,
            -0.0030414157081395388,
            0.013517742045223713,
            0.01618506945669651,
            0.04767776280641556,
            0.0058623324148356915,
            -0.008844968862831593,
            0.010334455408155918,
            0.0019174355547875166,
            -0.037441451102495193,
            -0.0054708244279026985,
            0.007156262174248695,
            0.04361257329583168,
            0.01644171215593815,
            0.03414767608046532,
            -0.042495839297771454,
            0.00046004357864148915,
            0.020167596638202667,
            0.001889410545118153,
            -0.009938947856426239,
            0.01809442788362503,
            -0.006679183337837458,
            -0.01874343492090702,
            -0.011675295419991016,
            -0.021297456696629524,
            -0.005838892888277769,
            0.0020197678823024035,
            0.004530342761427164,
            0.022725291550159454,
            0.023493489250540733,
            -0.012460500933229923,
            -0.023083876818418503,
            0.003674126463010907,
            0.032661568373441696,
            0.0032893740572035313,
            0.029111668467521667,
            0.01956363022327423,
            -0.013734710402786732,
            -0.015085142105817795,
            0.00619372958317399,
            0.010624310933053493,
            -0.03287660330533981,
            -0.002324221655726433,
            0.026436489075422287,
            -0.05654038488864899,
            0.014739763922989368,
            -0.030508793890476227,
            -0.029736420139670372,
            -0.02215597592294216,
            -0.017945772036910057,
            -0.02904350310564041,
            -0.022275054827332497,
            -0.019413497298955917,
            -0.0027695591561496258,
            -0.003497449215501547,
            0.011258628219366074,
            -0.003687969408929348,
            0.029321830719709396,
            0.02473808266222477,
            0.004184436984360218,
            -0.017750196158885956,
            -0.027402376756072044,
            -0.005887657403945923,
            -0.003951593302190304,
            0.0030474108643829823,
            -0.00915889535099268,
            0.02643660455942154,
            -0.04301954433321953,
            0.035428039729595184,
            -0.02752159908413887
        ],
        [
            -0.05259309709072113,
            -0.014467543922364712,
            0.00498689990490675,
            -0.03723832219839096,
            -0.03172539547085762,
            0.0395875982940197,
            -0.0021892290096729994,
            0.02313248999416828,
            0.013352854177355766,
            0.04300311952829361,
            -0.02621147409081459,
            0.03390241041779518,
            0.030679531395435333,
            0.01086221169680357,
            -0.013431159779429436,
            -0.0445886068046093,
            -0.029329627752304077,
            -0.024594416841864586,
            -0.01536993496119976,
            0.01911853440105915,
            -0.023131927475333214,
            -0.015814047306776047,
            -0.01385981310158968,
            -0.017195485532283783,
            0.05717480927705765,
            -0.0090145543217659,
            0.031534284353256226,
            -0.00506777036935091,
            0.025632042437791824,
            0.02957041747868061,
            -0.04117921367287636,
            0.023035310208797455,
            0.03231392800807953,
            0.036430153995752335,
            -0.03026595711708069,
            0.02918286994099617,
            -0.010195519775152206,
            -0.035160090774297714,
            -0.013203431852161884,
            0.008554426021873951,
            0.027752595022320747,
            0.01259519811719656,
            -0.003488487796857953,
            0.0066825589165091515,
            -0.08215939998626709,
            -0.015459169633686543,
            -0.014299989677965641,
            0.03684655949473381,
            0.004884749185293913,
            -0.03842001035809517,
            -0.00598451541736722,
            -0.01918134279549122,
            0.01419739704579115,
            -0.006918101105839014,
            -0.027952736243605614,
            0.025395333766937256,
            0.0016751136863604188,
            -0.009197957813739777,
            -0.0010978227946907282,
            0.014991323463618755,
            0.00729768630117178,
            -0.025967422872781754,
            -0.014213098213076591,
            -0.021693184971809387,
            0.016554825007915497,
            -0.004977572709321976,
            -0.042218659073114395,
            0.0038501888047903776,
            -0.0071396417915821075,
            -0.04582067206501961,
            0.011365050449967384,
            0.0021157581359148026,
            0.04155252128839493,
            -0.006466832477599382,
            0.004889360163360834,
            0.030049817636609077,
            0.033954743295907974,
            0.008596221916377544,
            0.039074189960956573,
            0.04802333936095238,
            -0.044644467532634735,
            0.004063381347805262,
            -0.004199528601020575,
            0.0006855613901279867,
            -0.02384835109114647,
            -0.01919759437441826,
            0.014442753978073597,
            0.04391362890601158,
            0.020043203607201576,
            0.026048926636576653,
            0.01388341560959816,
            -0.03873061761260033,
            -0.03826712816953659,
            -0.009041768498718739,
            -0.017246443778276443,
            0.021928489208221436,
            0.0592542439699173,
            0.02487618289887905,
            -0.019103247672319412,
            -0.005831610411405563,
            0.0019032226409763098,
            0.005723900161683559,
            0.011615331284701824,
            -0.034378260374069214,
            -0.018065016716718674,
            0.011722649447619915,
            -0.03211857005953789,
            -0.027719441801309586,
            0.0009446702315472066,
            0.007112396415323019,
            -0.07368054986000061,
            -0.024878744035959244,
            -0.019024154171347618,
            -0.03244232013821602,
            -0.005895734298974276,
            -0.03002226911485195,
            -0.006383253261446953,
            -0.022154800593852997,
            -0.008694681338965893,
            -0.008453667163848877,
            0.018574872985482216,
            0.00623647216707468,
            0.04147137328982353,
            0.022181019186973572,
            0.02489735744893551,
            -0.019667740911245346,
            -0.0666002556681633,
            0.0005876743234694004,
            0.0036947987973690033,
            0.020897069945931435,
            -0.005405631847679615,
            0.014231277629733086,
            -0.02591024525463581,
            -0.044694215059280396,
            0.01675824075937271,
            -0.034864116460084915,
            -0.004543439019471407,
            -0.007995790801942348,
            -0.03242923319339752,
            -0.025833752006292343,
            0.007089904043823481,
            0.014907518401741982,
            -0.0018201816128566861,
            -0.016102124005556107,
            -0.007148806005716324,
            -0.01524987444281578,
            -0.023533569648861885,
            -0.014545319601893425,
            -0.02030530571937561,
            0.03326956182718277,
            0.02488870546221733,
            0.01770397648215294,
            0.0053868526592850685,
            -0.013055643998086452,
            0.008162859827280045,
            0.005216322373598814,
            0.0027724297251552343,
            0.04221232607960701,
            0.05790146440267563,
            -0.042743440717458725,
            0.010714986361563206,
            0.0040750508196651936,
            -0.028771283105015755,
            -0.01140902005136013,
            0.00953744538128376,
            -0.06436686217784882,
            0.0196616742759943,
            -0.0009355495567433536,
            0.06356663256883621,
            0.026236027479171753,
            -0.024855222553014755,
            -0.01625632680952549,
            0.05409741401672363,
            0.014574175700545311,
            -0.011550112627446651,
            -0.010751241818070412,
            0.039426323026418686,
            -0.028110196813941002,
            -0.0025202559772878885,
            0.008442200720310211,
            -0.05512036383152008,
            0.005652169696986675,
            0.052584365010261536,
            0.01541660912334919,
            -0.02661702409386635,
            0.019357431679964066,
            -0.0007187548326328397,
            -0.0020702960900962353,
            0.0044766743667423725,
            -0.0182503629475832,
            0.020822575315833092,
            0.01520115602761507,
            0.003947981633245945,
            0.00356304575689137,
            -0.00798569992184639,
            0.012186563573777676,
            0.005722192116081715,
            0.007999914698302746,
            -0.02630695328116417,
            0.00022096504108048975,
            -0.03747585415840149,
            -0.008887292817234993,
            -0.03710244968533516,
            -0.015147349797189236,
            0.05081746354699135,
            0.014581573195755482,
            -0.03467245399951935,
            -0.04216424748301506,
            0.022149501368403435,
            -0.011418170295655727,
            -0.016742831096053123,
            -0.00031959425541572273,
            -0.034072522073984146,
            0.007655787281692028,
            -0.03325941786170006,
            -0.002125822240486741,
            -0.04117271676659584,
            -0.014816973358392715,
            0.021661698818206787,
            -0.002252233447507024,
            -0.05476614087820053,
            0.027720890939235687,
            -0.015133263543248177,
            -0.0036593149416148663,
            -0.024028515443205833,
            0.06653198599815369,
            0.0211336649954319,
            0.03251552954316139,
            0.026555106043815613,
            0.034493159502744675,
            -0.009258684702217579,
            0.03248312324285507,
            -0.016002023592591286,
            -0.011196598410606384,
            0.021211329847574234,
            -0.006303808186203241,
            0.0020237781573086977,
            0.01693554036319256,
            0.028796907514333725,
            -3.616692265495658e-05,
            0.01839437335729599,
            0.033203206956386566,
            -0.02059555985033512,
            0.010660224594175816,
            -0.00014640796871390194,
            0.004031292162835598,
            0.028192345052957535,
            -0.038839079439640045,
            0.007704983465373516,
            -0.029505280777812004,
            -0.023770198225975037,
            0.02432606741786003,
            0.010280423797667027,
            -0.0036142291501164436,
            -0.005763857159763575,
            0.0028190482407808304,
            -0.025190232321619987,
            -0.011233558878302574,
            0.03232864663004875,
            -0.016856724396348,
            -0.008514869958162308,
            -0.013919759541749954,
            0.01505762804299593,
            0.00986710749566555,
            -0.014213038608431816,
            -0.027290958911180496,
            -0.01356381457298994,
            0.016171922907233238,
            -0.04020773246884346,
            0.019239509478211403,
            0.012424365617334843,
            -0.03784286603331566,
            0.004850987810641527,
            -0.029676713049411774,
            0.012726793996989727,
            0.029741300269961357,
            0.033784810453653336,
            0.007114312145859003,
            -0.0030358131043612957,
            -0.0020286431536078453,
            0.015259488485753536,
            0.031204674392938614,
            -0.0381680503487587,
            -0.0063017322681844234,
            0.01797698251903057,
            0.019033197313547134,
            0.02213645540177822,
            0.03841801732778549,
            0.011083086021244526,
            0.034792572259902954,
            -0.012443494983017445,
            -0.015423662960529327,
            0.024418871849775314,
            -0.03539249300956726,
            -0.011659679003059864,
            0.016228189691901207,
            -0.012613901868462563,
            -0.03815407678484917,
            0.01716875098645687,
            -0.002035439945757389,
            -0.005163455847650766,
            0.003019802737981081,
            -0.02294996567070484,
            0.007219252642244101,
            0.024950256571173668,
            0.010934354737401009,
            0.006748068146407604,
            -0.03145386651158333,
            -0.036081306636333466,
            0.02839711867272854,
            -0.036204852163791656,
            -0.00658946018666029,
            0.024446073919534683,
            0.014961027540266514,
            -0.02833530306816101,
            -0.031644757837057114,
            -0.01469726674258709,
            0.00338052143342793,
            0.014486589469015598,
            0.029674211516976357,
            -0.03769274428486824,
            -0.0056182569824159145,
            0.028672680258750916,
            -0.018985353410243988,
            -0.009058264084160328,
            -0.004626555368304253,
            -0.042212795466184616,
            0.0040536364540457726,
            -0.0013410927494987845,
            0.028020689263939857,
            -0.01924665831029415,
            -0.0018392405472695827,
            -0.020778201520442963,
            0.03703989461064339,
            0.019670985639095306,
            -0.012383468449115753,
            -0.0075397309847176075,
            -0.003659503534436226,
            -0.02390175499022007,
            -0.06525946408510208,
            0.016889989376068115,
            -0.0320068895816803,
            0.02176460437476635,
            0.006580786779522896,
            0.017317723482847214,
            -0.03649301826953888,
            -0.06136156991124153,
            0.01093422994017601,
            -0.010877818800508976,
            -0.00699697807431221,
            0.03234339505434036,
            -0.007300110999494791,
            -0.06166619062423706,
            0.00632854737341404,
            -0.0020422760862857103,
            0.0295148603618145,
            -0.01062107179313898,
            0.006469359155744314,
            0.001511876587755978,
            -0.03048461303114891,
            -0.03005012311041355,
            -0.03002307005226612,
            0.03334501013159752,
            0.03654227778315544,
            0.06430123746395111,
            0.002093270421028137,
            -0.04561777040362358,
            0.0339139960706234,
            -0.006616038270294666,
            -0.016657792031764984,
            0.007735417690128088,
            -0.026109162718057632,
            0.044610459357500076,
            -0.020739292725920677,
            0.0013448516838252544,
            0.046144839376211166,
            -0.03336738795042038,
            -0.007220846135169268,
            -0.028028685599565506,
            -0.03629310056567192,
            -0.00988601241260767,
            0.00850991066545248,
            -0.0063888905569911,
            -0.028545554727315903,
            0.03431610390543938,
            0.03239082545042038,
            0.0252873282879591,
            -0.03396094590425491,
            0.007831979542970657,
            0.015183606185019016,
            -0.034621626138687134,
            0.004434274975210428,
            -0.03329268470406532,
            0.009551122784614563,
            -0.035277996212244034,
            -0.022416971623897552,
            0.01656818389892578,
            -0.0025503605138510466,
            0.015240552835166454,
            0.02104601077735424,
            0.017637308686971664,
            -0.017413809895515442,
            0.020259980112314224,
            -0.00762089341878891,
            -0.04193856567144394,
            0.011986728757619858,
            0.01291092298924923,
            0.06967136263847351,
            0.023822154849767685,
            -0.0368138886988163,
            -0.04488196596503258,
            0.002286671195179224,
            0.006080229766666889,
            0.04473380371928215,
            -0.021975291892886162,
            0.025421619415283203,
            0.011268723756074905,
            0.007833238691091537,
            0.0093690799549222,
            -0.029651323333382607,
            0.013761724345386028,
            0.01882466860115528,
            -0.007587390020489693,
            0.0026800765190273523,
            0.024744028225541115,
            0.009768093936145306,
            -0.03807545453310013,
            -0.025218859314918518,
            -0.02020992897450924,
            0.022808214649558067,
            0.033189285546541214,
            -0.022684257477521896,
            0.03022165037691593,
            0.010904123075306416,
            0.025714516639709473,
            0.006816426757723093,
            0.029773665592074394,
            0.04423942416906357,
            0.013695795089006424,
            0.014894002117216587,
            0.009181476198136806,
            0.037508558481931686,
            0.01758638583123684,
            -0.044507429003715515,
            0.016790417954325676,
            -0.024849306792020798,
            0.025762176141142845,
            0.06451908499002457,
            0.00013399557792581618,
            0.0042780013754963875,
            -0.012310042046010494,
            -0.026808442547917366,
            -0.08356831967830658,
            0.011575773358345032,
            -0.04226043447852135,
            -0.017494209110736847,
            0.006044555455446243,
            0.003985018935054541,
            0.024597538635134697,
            0.03348100557923317,
            -0.04376726970076561,
            -0.008865660056471825,
            0.013158890418708324,
            -0.027442622929811478,
            0.004162055440247059,
            -0.02996256947517395,
            -0.002669286448508501,
            -0.05013314634561539,
            0.01613716036081314,
            0.028007427230477333,
            0.008847619406878948,
            -0.0033118203282356262,
            0.03086695447564125,
            0.03087550215423107,
            -0.0011248987866565585,
            -0.029141588136553764,
            -0.05436787009239197,
            0.009730955585837364,
            -0.0010673021897673607,
            -0.02965729683637619,
            0.0038945747073739767,
            -0.0009358362876810133,
            0.03922590985894203,
            0.015548582188785076,
            0.057671770453453064,
            -0.011459292843937874,
            0.0035178824327886105,
            -0.017357340082526207,
            0.031468573957681656,
            0.05010763555765152,
            -0.012929290533065796,
            -0.02920185588300228,
            -0.0069835251197218895,
            0.001615801709704101,
            -0.07796823233366013,
            0.008512912318110466,
            0.022756805643439293,
            -0.0038720692973583937,
            -0.01806293986737728,
            -0.019622288644313812,
            -0.03939954563975334,
            0.0366678349673748,
            -0.01867494359612465,
            -0.007860064506530762,
            -0.05255475267767906,
            -0.021201657131314278,
            0.029833711683750153,
            0.003440373344346881,
            0.022708624601364136,
            -0.02015465311706066,
            -0.011629200540482998,
            0.03328937664628029,
            0.02670987881720066,
            -0.009346994571387768,
            0.002219565212726593,
            -0.011744656600058079,
            0.018336577340960503,
            0.02933712676167488,
            -0.0030421169940382242,
            0.03257691487669945,
            -0.010753525421023369,
            0.007500976324081421,
            -0.021506300196051598,
            0.023140287026762962,
            0.028522279113531113,
            -0.02902766317129135,
            0.04181746020913124,
            -0.02696048654615879,
            -0.01838981732726097,
            0.03484366834163666,
            -0.0189532358199358,
            0.0225901547819376,
            0.042978305369615555,
            0.010663716122508049,
            -0.010234838351607323,
            0.02208755537867546,
            -0.03503740578889847,
            0.02866680920124054,
            -0.006851542741060257,
            -0.011576937511563301,
            -0.031842608004808426,
            0.03298869729042053,
            0.026017673313617706,
            -0.01185273565351963,
            -0.01579071767628193,
            -0.009790854528546333,
            -0.019365213811397552,
            0.028401795774698257,
            -0.01347881369292736,
            0.010258168913424015,
            -0.010935296304523945,
            -0.0034372189547866583,
            0.0072660427540540695,
            0.02675933763384819,
            0.029748287051916122,
            0.018453462049365044,
            -0.020222248509526253,
            0.0017159163253381848,
            0.001042621093802154,
            0.030253587290644646,
            0.005191018804907799,
            0.005251482594758272,
            -0.0018955195555463433,
            -0.015335285104811192,
            -0.01515468955039978,
            -0.002466832287609577,
            -0.025691991671919823,
            0.02263454534113407,
            -0.006359819322824478,
            0.041894782334566116,
            0.008119022473692894,
            -0.019231503829360008,
            0.010160699486732483,
            -0.0021614220459014177,
            0.00016587857680860907,
            -0.021806510165333748,
            -0.018290700390934944,
            -0.002168696839362383,
            -0.038381244987249374,
            0.0058240024372935295,
            0.018545856699347496,
            -0.00037881237221881747,
            0.033637549728155136,
            0.016546232625842094,
            0.019890503957867622,
            0.026910491287708282,
            -0.008520700968801975,
            0.0334443524479866,
            -0.0053448304533958435,
            -0.036604154855012894,
            -0.002304088557139039,
            -0.05241052061319351,
            0.009627998806536198,
            -0.028120893985033035,
            -0.010080565698444843,
            -0.010788565501570702,
            -0.0024942168965935707,
            -0.04292013496160507,
            0.0027705375105142593,
            -0.014461404643952847,
            0.05189613252878189,
            0.03655080124735832,
            0.051239874213933945,
            0.03062875010073185,
            -0.036707859486341476,
            -0.03295805677771568,
            -0.006438716780394316,
            -0.022367116063833237,
            0.021058963611721992,
            0.018289361149072647,
            -0.012119041755795479,
            0.0006011812365613878,
            0.010299982503056526,
            -0.015800997614860535,
            -0.027046432718634605,
            0.05812656879425049,
            0.027101915329694748,
            -0.028000177815556526,
            -0.016153963282704353,
            0.03349759057164192,
            0.0035118849482387304,
            -0.053380563855171204,
            0.008082538843154907,
            0.017405593767762184,
            -0.0018517448334023356,
            -0.013031554408371449,
            -0.05769052729010582,
            0.025537531822919846,
            0.02157468907535076,
            -0.01543079037219286,
            -0.05306136608123779,
            0.008841915056109428,
            -0.0180344358086586,
            0.022076891735196114,
            0.012363449670374393,
            -0.017290158197283745,
            -0.05523432791233063,
            0.015938730910420418,
            0.0014324454823508859,
            0.010558802634477615,
            -0.02699938789010048,
            -0.05213998258113861,
            -0.05678216367959976,
            0.0041323453187942505,
            -0.02186465822160244,
            0.04091699793934822,
            -0.004634738899767399,
            0.013131204061210155,
            -0.006644493900239468,
            -0.0539182685315609,
            -0.029089447110891342,
            -0.00605780491605401,
            -0.006126899737864733,
            0.018273215740919113,
            0.037271976470947266,
            -0.014797228388488293,
            0.0478653647005558,
            -0.03115207888185978,
            0.005870628636330366,
            -0.02026376500725746,
            0.0014961051056161523,
            0.037728115916252136,
            0.012045114301145077,
            -0.029539018869400024,
            -0.018990222364664078,
            0.020666515454649925,
            0.02672925963997841,
            -0.008289100602269173,
            0.025500668212771416,
            -0.014845688827335835,
            -0.0384453684091568,
            0.026326460763812065,
            0.0044741639867424965,
            -0.043823398649692535,
            0.014450161717832088,
            -0.04468081519007683,
            0.0198033656924963,
            0.03331306576728821,
            -0.015336916781961918,
            0.023446684703230858,
            -0.014135738834738731,
            0.010481460951268673,
            0.010339928790926933,
            0.0005547405453398824,
            0.026292404159903526,
            -0.00698330532759428,
            0.03271671384572983,
            -0.04574601724743843,
            0.004463235381990671,
            0.0008697137236595154,
            0.02166477032005787,
            -0.03168482333421707,
            0.03312027454376221,
            -0.00041102978866547346,
            -0.01706986501812935,
            0.004777922760695219,
            -0.009565845131874084,
            -0.011987843550741673,
            0.0009979463648051023,
            0.0035656734835356474,
            -0.03935519605875015,
            0.022221803665161133,
            0.009817146696150303,
            0.011403449811041355,
            -0.027886640280485153,
            -0.0127287358045578,
            -0.0076204938814044,
            0.04527783393859863,
            -9.20151433092542e-06,
            -0.012450325302779675,
            8.278653695015237e-06,
            -0.016730880364775658,
            -0.02856544218957424,
            -0.0020627661142498255,
            -0.006670359987765551,
            0.019934790208935738,
            -0.060511235147714615,
            0.0381787046790123,
            -0.017891686409711838,
            0.015180431306362152,
            0.00466146832332015,
            0.021885473281145096,
            0.030041750520467758,
            0.034213416278362274,
            0.0190855972468853,
            -0.023093432188034058,
            0.019498232752084732,
            -0.00028685538563877344,
            0.030719727277755737,
            0.02334665320813656,
            -0.0107804574072361,
            -0.039250437170267105,
            -0.010934262536466122,
            0.002209090394899249,
            0.011284838430583477,
            0.01458760816603899,
            0.010834199376404285,
            0.01934366673231125,
            -0.024729212746024132,
            0.013115130364894867,
            0.028582263737916946,
            0.04551567882299423,
            -0.004112890921533108,
            0.0124070905148983,
            -0.014794678427278996,
            -0.011362369172275066,
            -0.07478024810552597,
            0.0036740906070917845,
            0.0194430910050869,
            -0.007445237133651972,
            0.011998060159385204,
            -0.000994579284451902,
            -0.002534099156036973,
            0.014401399530470371,
            0.00497187115252018,
            -0.04009811952710152,
            0.033164702355861664,
            -0.05227133259177208,
            0.02104007452726364,
            0.0001100924564525485,
            -0.04982101172208786,
            0.05017808824777603,
            0.003674248931929469,
            0.043817322701215744,
            -0.03153761848807335,
            0.009935354813933372,
            -0.011728229932487011,
            -0.010025125928223133,
            -0.022113706916570663,
            0.013863468542695045,
            -0.025262925773859024,
            -0.000551063334569335,
            -0.005193741526454687,
            -0.035075780004262924,
            -0.018224701285362244,
            -0.005699170753359795,
            0.05204630643129349,
            0.02784867398440838,
            -0.025562018156051636,
            0.004092962946742773,
            -0.030417831614613533,
            0.012751789763569832,
            0.00042591968667693436,
            0.03753838315606117,
            0.0335233248770237,
            0.00510088074952364,
            -0.045680075883865356,
            1.749794705574459e-06,
            0.044082336127758026,
            0.0035200419370085,
            0.023864513263106346,
            0.004467012360692024,
            -0.007308907341212034,
            0.07822529971599579,
            0.014071102254092693,
            -0.0026993337087333202,
            -0.00917101465165615,
            -0.03656624257564545,
            -0.008845563046634197,
            -0.0023267692886292934,
            0.018050456419587135,
            -0.02708345092833042,
            0.01596975140273571,
            -0.0022060435730963945,
            0.04276411980390549,
            0.021678190678358078,
            0.010914880782365799,
            -0.013301942497491837,
            0.009252406656742096,
            -0.0005933590582571924,
            -0.01388595812022686,
            0.0019428200321272016,
            -0.0035888473503291607,
            -0.024495521560311317,
            -0.015207367949187756,
            -0.010263398289680481,
            0.030042046681046486,
            -0.0016135257901623845,
            0.019770467653870583,
            -0.02929024212062359,
            -0.0022448073141276836,
            -0.01960097625851631,
            -0.016466302797198296,
            0.023723067715764046,
            -0.012645846232771873,
            0.018757468089461327,
            0.0022476359736174345,
            0.030709564685821533,
            0.023490751162171364,
            0.00031647615833207965,
            -0.014431691728532314,
            -0.004860203713178635,
            0.02602672018110752,
            0.033270206302404404,
            -0.055262234061956406,
            0.021143270656466484,
            -0.021548761054873466,
            0.009722860530018806,
            -0.016312820836901665,
            0.0062231868505477905,
            -0.036691296845674515,
            0.01335985492914915,
            0.012830705381929874,
            -0.01093131024390459,
            0.005291245877742767,
            0.004516143351793289,
            -0.01588473655283451,
            0.004530475940555334,
            0.006334919016808271,
            -0.022790540009737015,
            0.00622119614854455,
            -0.005688152741640806,
            -0.03889037296175957,
            -0.010367889888584614,
            -0.0011236286954954267,
            -0.03619895875453949,
            -0.02831844612956047,
            0.034563206136226654,
            -0.026930255815386772,
            -0.06370730698108673,
            -0.00022020595497451723,
            -0.011503967456519604,
            0.02540302276611328,
            -0.029545700177550316,
            0.01960441656410694,
            -0.04294879734516144,
            -0.031833402812480927,
            -0.02115422673523426,
            -0.017475290223956108,
            -0.028654439374804497,
            0.0299952682107687,
            0.017068278044462204,
            -0.057141080498695374,
            0.006879853084683418,
            -0.00021911576914135367,
            -0.004783902782946825,
            0.027180885896086693,
            -0.004383365157991648,
            0.0246889665722847,
            -0.011747315526008606,
            -0.013007053174078465,
            -0.0011413191678002477,
            -0.06810831278562546,
            -0.02320324443280697,
            0.02152998559176922,
            0.0017725330544635653,
            -0.014801302924752235,
            -0.0016606850549578667,
            0.029749810695648193,
            0.01565638929605484,
            0.05359437316656113,
            -0.0011962255230173469,
            0.016432559117674828,
            -0.01577952317893505,
            -0.045057930052280426,
            0.004086720757186413,
            -0.022240614518523216,
            0.004469514358788729,
            -0.001230416470207274,
            0.03859003260731697,
            0.032281070947647095,
            0.036350350826978683,
            -0.00714124646037817,
            0.005744094029068947,
            -0.027180315926671028,
            -0.026184821501374245,
            0.008867395110428333,
            0.050254032015800476,
            0.01788795180618763,
            0.022881103679537773,
            -0.006596880964934826,
            -0.02275000885128975,
            -0.035820942372083664,
            -0.032234955579042435,
            0.01305322628468275,
            -0.002623403212055564,
            -0.03019174560904503,
            -0.002119770273566246,
            -0.030478302389383316,
            0.025531960651278496,
            -0.009948812425136566,
            -0.05518541857600212,
            -0.005349143873900175,
            -0.04577890783548355,
            0.017275890335440636,
            -0.022532766684889793,
            -0.0431472584605217,
            -0.031247610226273537,
            -0.012571633793413639,
            -0.02149926871061325,
            0.005224422551691532,
            -0.019630739465355873,
            0.0010325789917260408,
            0.021948227658867836,
            0.014789489097893238,
            -0.019482042640447617,
            0.008748180232942104,
            -0.013519886881113052,
            0.04646524414420128,
            0.004459529183804989,
            0.031154224649071693,
            0.0001591725304024294,
            0.00644267164170742,
            0.011392418295145035,
            0.018878862261772156,
            0.026158001273870468,
            -0.002386868465691805,
            0.042186178267002106,
            0.00455636577680707,
            -0.008025129325687885,
            -0.07032806426286697,
            -0.008038285188376904,
            0.004551692865788937,
            0.028683200478553772,
            -0.01875287853181362,
            0.03625604882836342,
            0.00676119327545166,
            -0.01481330581009388,
            -0.0580623559653759,
            -0.010561434552073479,
            -0.002805502386763692,
            -0.017571380361914635,
            -0.009780148975551128,
            0.004911499097943306,
            -0.016011402010917664,
            0.011341772973537445,
            0.023039164021611214,
            -0.04261766001582146,
            -0.029277196153998375,
            0.012028958648443222,
            -0.021157866343855858,
            -0.016581224277615547,
            0.027654970064759254,
            -0.031045088544487953,
            0.03464047610759735,
            -0.05369212105870247,
            0.05338970944285393,
            -0.0044678435660898685,
            -0.011628404259681702,
            0.001901009352877736,
            0.026648428291082382,
            0.016512440517544746,
            -0.008348208852112293,
            0.01976415328681469,
            0.005672333296388388,
            0.030736088752746582,
            -0.030511222779750824,
            -0.01943516731262207,
            -0.036756664514541626,
            0.00982628483325243,
            0.007681859657168388,
            0.027342433109879494,
            -0.025452136993408203,
            -0.00013182011025492102,
            0.000625818909611553,
            0.04420505836606026,
            0.04201994463801384,
            0.027046043425798416,
            0.038082145154476166,
            -0.005362583789974451,
            0.035536304116249084,
            -0.028970496729016304,
            0.030991366133093834,
            -0.009086615405976772,
            -0.011163844726979733,
            0.016560377553105354,
            -0.004358336329460144,
            -0.049110304564237595,
            0.010230878368020058,
            0.0028884524945169687,
            0.017217716202139854,
            -0.07405741512775421,
            -0.004294258542358875,
            -0.03936515748500824,
            0.020525481551885605,
            0.0219590924680233,
            0.0046578049659729,
            0.00148131197784096,
            0.02625623717904091,
            0.011332454159855843,
            0.018146714195609093,
            0.010029394179582596,
            0.012723253108561039,
            0.003085578326135874
        ],
        [
            0.023184258490800858,
            -0.043495308607816696,
            -0.009522912092506886,
            0.028858641162514687,
            -0.01241118274629116,
            -0.007568688597530127,
            -0.0022246723528951406,
            -0.0018680109642446041,
            0.0014817568007856607,
            -0.0012596147134900093,
            -0.03612981364130974,
            -0.0056563690304756165,
            -0.022520987316966057,
            -0.04000364616513252,
            -0.026026934385299683,
            -0.03464766964316368,
            -0.03441350534558296,
            0.018279749900102615,
            0.01600438915193081,
            -0.021969160065054893,
            -0.008862975053489208,
            -0.027967048808932304,
            0.046048909425735474,
            -0.007370140869170427,
            -0.012843888252973557,
            -0.025723962113261223,
            0.023886049166321754,
            0.007336929906159639,
            -0.020878715440630913,
            0.03141794726252556,
            0.014002001844346523,
            0.016300830990076065,
            0.002176114823669195,
            -0.010039576329290867,
            0.03172415867447853,
            0.015832792967557907,
            0.02473863586783409,
            -0.02115134708583355,
            -0.015424764715135098,
            -0.010655797086656094,
            0.031427059322595596,
            0.025518016889691353,
            0.04079252481460571,
            0.01277835015207529,
            0.00010562688112258911,
            0.0270862877368927,
            -0.00534400437027216,
            -0.00580724747851491,
            -0.020869651809334755,
            -0.03638256713747978,
            0.02147800661623478,
            0.01707368716597557,
            -0.010563919320702553,
            -0.03847111389040947,
            -0.05493023246526718,
            -0.016782596707344055,
            -0.0019438066519796848,
            0.03325461223721504,
            -0.02092738077044487,
            0.023803289979696274,
            -0.026072291657328606,
            0.019120117649435997,
            -0.029756808653473854,
            -0.030745046213269234,
            -0.008207074366509914,
            -0.01131435390561819,
            -0.033070072531700134,
            0.031102260574698448,
            0.01706906035542488,
            -0.016357893124222755,
            0.006273210048675537,
            0.0004001841298304498,
            -0.024608993902802467,
            -0.029475964605808258,
            -0.06225872039794922,
            -0.035917848348617554,
            -0.02673419564962387,
            -0.012382118962705135,
            -0.00031407640199176967,
            -0.001710384152829647,
            -0.020276576280593872,
            0.02084137499332428,
            0.024355316534638405,
            -0.018593911081552505,
            0.01871498115360737,
            -0.02345232479274273,
            0.016320785507559776,
            -0.019132429733872414,
            -0.02400163561105728,
            0.00416441448032856,
            -0.0334031879901886,
            -0.012185079045593739,
            -0.013733522966504097,
            0.009924792684614658,
            -0.00046915869461372495,
            -0.010007829405367374,
            0.017681624740362167,
            -0.016367247328162193,
            0.021053213626146317,
            -0.017671946436166763,
            0.008918697014451027,
            0.007869426161050797,
            -0.018066423013806343,
            -0.010202198289334774,
            0.030864974483847618,
            0.01153046265244484,
            -0.026181044057011604,
            0.002211946528404951,
            -0.03637665882706642,
            0.024300675839185715,
            -0.0266236811876297,
            -0.01789792627096176,
            0.027952471747994423,
            -0.019034938886761665,
            0.010432313196361065,
            0.005685381591320038,
            -0.00035348135861568153,
            0.039191365242004395,
            0.021691283211112022,
            -0.02348632551729679,
            -0.005615930072963238,
            0.020009517669677734,
            0.02817620150744915,
            0.014404241926968098,
            -0.024563951417803764,
            0.026985634118318558,
            -0.017145780846476555,
            -0.007670233026146889,
            0.02927001751959324,
            0.024877632036805153,
            -0.003874208079650998,
            0.006287836004048586,
            -0.004415398929268122,
            0.003024402540177107,
            -0.028533177450299263,
            0.0035911689046770334,
            0.00020653883984778076,
            -0.002279153559356928,
            -0.030063357204198837,
            -0.017517978325486183,
            -0.013115650042891502,
            0.003916698042303324,
            -0.02260425314307213,
            0.03326708823442459,
            -0.0013182034017518163,
            0.020823774859309196,
            -0.022212540730834007,
            0.03156174719333649,
            0.012156250886619091,
            0.0019263880094513297,
            -0.03237450122833252,
            0.014783332124352455,
            0.022508734837174416,
            0.003935385029762983,
            0.031596358865499496,
            -0.009348802268505096,
            0.009426437318325043,
            -0.014545624144375324,
            -0.013994846493005753,
            -0.014897582121193409,
            -0.003593322355300188,
            -0.023880556225776672,
            -0.0291308481246233,
            0.016389772295951843,
            -0.009073995053768158,
            0.005191978067159653,
            0.025220898911356926,
            -0.006420601159334183,
            -0.01783168502151966,
            0.021215714514255524,
            0.019099900498986244,
            -0.004087734967470169,
            0.03040941245853901,
            0.00033678236650303006,
            -0.00824784766882658,
            -0.009141438640654087,
            -0.013881107792258263,
            0.017078623175621033,
            0.009022432379424572,
            -0.00347928237169981,
            -0.018546968698501587,
            0.02109723538160324,
            0.013509640470147133,
            -0.01717151515185833,
            0.02738005481660366,
            -0.020713847130537033,
            -0.04348107799887657,
            0.010815371759235859,
            0.018131138756871223,
            0.016648976132273674,
            -0.016533493995666504,
            -0.01720239594578743,
            -0.02808290906250477,
            -0.03132561966776848,
            0.016513554379343987,
            -0.0221357811242342,
            0.03324161469936371,
            0.009940916672348976,
            -0.03530538082122803,
            0.014499030075967312,
            -0.028525378555059433,
            0.01190053578466177,
            0.00028709517209790647,
            0.010953264310956001,
            0.031070474535226822,
            0.022397901862859726,
            0.011925529688596725,
            -0.008293412625789642,
            0.0034011967945843935,
            0.002932887524366379,
            -0.006266229785978794,
            0.006047377362847328,
            -0.02380956895649433,
            0.010560289025306702,
            0.003261108184233308,
            0.01777546852827072,
            0.012705666944384575,
            -0.026634225621819496,
            0.029923200607299805,
            -0.023818666115403175,
            -0.027133550494909286,
            -0.03394250571727753,
            -0.003616252914071083,
            -0.008584864437580109,
            0.013806643895804882,
            -0.010727397166192532,
            -0.011754550971090794,
            -0.0008402323583140969,
            0.006952611263841391,
            0.00793388206511736,
            -0.025229796767234802,
            -0.00919018592685461,
            -0.03631292283535004,
            -0.02895229496061802,
            -0.018731046468019485,
            0.030363021418452263,
            0.0338318906724453,
            0.02154422551393509,
            -0.003272930858656764,
            0.016258791089057922,
            -0.01883784867823124,
            0.018244696781039238,
            0.01988709531724453,
            0.009027345106005669,
            0.004791388753801584,
            -0.019291307777166367,
            0.0026514059863984585,
            -0.006754911970347166,
            -0.01906580850481987,
            0.030706826597452164,
            0.013907304033637047,
            -0.019881868734955788,
            -0.005147336982190609,
            -0.01437357161194086,
            -0.021145930513739586,
            -0.026109520345926285,
            -0.0042769392021000385,
            0.014851509593427181,
            0.003938168287277222,
            0.012296650558710098,
            0.00032785674557089806,
            0.020818188786506653,
            -0.019370920956134796,
            0.0174613855779171,
            -0.022574407979846,
            0.012977548874914646,
            0.02320266142487526,
            0.008928929455578327,
            0.0018212274881079793,
            0.005254312884062529,
            -0.010338879190385342,
            -0.001433344790711999,
            0.021890811622142792,
            0.01591036096215248,
            0.005230227019637823,
            -0.000902213272638619,
            0.012347069568932056,
            -0.020712828263640404,
            0.025914954021573067,
            0.006293760146945715,
            -0.017224617302417755,
            -0.018953556194901466,
            -0.009594975039362907,
            -0.01022383850067854,
            -0.004430016037076712,
            0.030409112572669983,
            0.011969180777668953,
            -0.04099520668387413,
            0.02729155495762825,
            -0.02331610582768917,
            0.018099399283528328,
            -0.013389055617153645,
            -0.005507123656570911,
            -0.0039027994498610497,
            0.028671296313405037,
            0.009819512255489826,
            -0.015511679463088512,
            -0.015585619956254959,
            -0.030182205140590668,
            0.007609260734170675,
            0.030352095142006874,
            -0.029299790039658546,
            -0.005465857684612274,
            0.019914308562874794,
            -0.011247235350310802,
            -0.00847155973315239,
            0.02331642061471939,
            -0.012443158775568008,
            0.0027368939481675625,
            -0.023749087005853653,
            0.016162345185875893,
            0.027707936242222786,
            0.023071028292179108,
            0.024929825216531754,
            0.022501744329929352,
            0.010462934151291847,
            0.008682743646204472,
            0.028507981449365616,
            -0.018779998645186424,
            -0.003717290237545967,
            -0.010702786035835743,
            0.010746411047875881,
            0.008181828074157238,
            0.024062883108854294,
            0.03140934929251671,
            0.029588596895337105,
            0.00159698654897511,
            -0.03418507054448128,
            0.008430331945419312,
            0.013845349662005901,
            -0.027844328433275223,
            -0.01565176621079445,
            -0.004341476131230593,
            -0.0061610108241438866,
            0.01246555708348751,
            -0.013500701636075974,
            0.01259252242743969,
            -0.00795600563287735,
            0.01094825379550457,
            -0.044400423765182495,
            0.01942521706223488,
            -0.027913937345147133,
            -0.014625893905758858,
            -0.021970367059111595,
            0.0017544530564919114,
            0.01033078134059906,
            -0.03875063359737396,
            0.008334090933203697,
            -0.0011709592072293162,
            0.001357773900963366,
            -0.017881792038679123,
            -0.004283807706087828,
            0.021485207602381706,
            0.029575221240520477,
            0.008917887695133686,
            -0.02068091742694378,
            -0.0032240611035376787,
            0.01488194428384304,
            0.01911611296236515,
            -0.015618174336850643,
            0.010142069309949875,
            0.014742442406713963,
            -0.018347345292568207,
            -0.0057488661259412766,
            0.029088448733091354,
            0.014065911993384361,
            0.0014102531131356955,
            -0.02563701756298542,
            0.03310924023389816,
            -0.025649864226579666,
            -0.0004731107328552753,
            -0.021028215065598488,
            -0.010331828147172928,
            0.014079819433391094,
            0.0026415272150188684,
            0.01589333452284336,
            -0.03566640987992287,
            0.04742404446005821,
            -0.007649213075637817,
            -0.025076745077967644,
            0.001693813013844192,
            -0.00038843206129968166,
            -0.026351189240813255,
            0.0077952612191438675,
            0.021287573501467705,
            0.03849988430738449,
            -0.02808409184217453,
            0.01621612161397934,
            0.004394085146486759,
            9.250929724657908e-05,
            0.010221604257822037,
            0.024545399472117424,
            0.017339222133159637,
            0.024744782596826553,
            0.013747110962867737,
            -0.0010428226087242365,
            -0.0033655783627182245,
            -0.03600752353668213,
            -0.006399359554052353,
            -0.0012325086863711476,
            -0.01921999081969261,
            -0.005255270749330521,
            0.03132503852248192,
            -0.009402712807059288,
            0.015897756442427635,
            0.02655790001153946,
            -0.023189926519989967,
            0.014099444262683392,
            -0.014874368906021118,
            0.01627589762210846,
            0.021634714677929878,
            0.015322337858378887,
            -0.012823143042623997,
            0.005189962685108185,
            0.0007969142752699554,
            -0.010679737664759159,
            -0.004177976865321398,
            -0.012249535880982876,
            -0.019975053146481514,
            -0.0018358973320573568,
            -0.002292714547365904,
            0.037887219339609146,
            -0.009245098568499088,
            -0.0005328533588908613,
            0.007511436473578215,
            0.025448855012655258,
            0.012608234770596027,
            -0.019730204716324806,
            -0.0035979358945041895,
            0.014635259285569191,
            -0.005180374253541231,
            0.01843363791704178,
            -0.020223679021000862,
            -0.005085845943540335,
            0.011497283354401588,
            -0.003067601006478071,
            0.007097247056663036,
            -0.009474062360823154,
            0.02554546482861042,
            -0.002772509353235364,
            -0.012484143488109112,
            0.02385900914669037,
            0.015589896589517593,
            -0.00571752293035388,
            0.01067416463047266,
            -0.025059238076210022,
            -0.009447875432670116,
            0.04677899554371834,
            0.0037100936751812696,
            -0.002699136035516858,
            -0.023252511397004128,
            0.010593087412416935,
            -0.0013876210432499647,
            0.02652633562684059,
            -0.027485854923725128,
            0.00032705991179682314,
            0.020241044461727142,
            0.015208950266242027,
            0.01982821896672249,
            0.023526020348072052,
            0.00265887682326138,
            0.01399895828217268,
            -0.006329153198748827,
            -0.008513539098203182,
            -0.01980075053870678,
            0.0087415287271142,
            0.03054868057370186,
            -0.003592094639316201,
            -0.0040461658500134945,
            -0.01123820897191763,
            0.005458625964820385,
            0.004232099745422602,
            0.025684505701065063,
            0.003987319301813841,
            -0.020407631993293762,
            0.02602112852036953,
            0.02203342132270336,
            -0.0036559724248945713,
            0.019697804003953934,
            0.002205293858423829,
            -0.029528677463531494,
            0.03522069379687309,
            0.02694012224674225,
            0.012585621327161789,
            0.03930875286459923,
            -0.03166896477341652,
            0.024625523015856743,
            -0.035785969346761703,
            -0.07425496727228165,
            -0.012035759165883064,
            -0.0189814493060112,
            -0.01948339305818081,
            -0.016588596627116203,
            0.017636341974139214,
            0.02035902440547943,
            -0.010131032206118107,
            0.00745395477861166,
            0.016934530809521675,
            0.018742971122264862,
            -0.02807866595685482,
            -0.009771677665412426,
            -0.014469091780483723,
            0.011544676497578621,
            -0.01788131333887577,
            -0.004086396656930447,
            -0.010061325505375862,
            0.0067072464153170586,
            -0.027245819568634033,
            -0.016623549163341522,
            -0.04295442998409271,
            -0.020166905596852303,
            0.030409125611186028,
            0.009895632043480873,
            -0.033266015350818634,
            0.02327318862080574,
            0.021779479458928108,
            2.3113600036595017e-05,
            -0.012659987434744835,
            0.021117975935339928,
            0.025014683604240417,
            -0.004223054740577936,
            0.0166801568120718,
            -0.0074154967442154884,
            -0.0034374198876321316,
            0.01326045673340559,
            0.01400449126958847,
            0.02617225982248783,
            0.0021591081749647856,
            -0.011057709343731403,
            0.011443388648331165,
            0.0002934577059932053,
            -0.02067207358777523,
            0.03884775936603546,
            -0.013410677202045918,
            0.009677570313215256,
            -0.003817964345216751,
            -0.029020844027400017,
            -0.006650160998106003,
            0.03678077459335327,
            -0.010615630075335503,
            0.0022341536823660135,
            0.029002822935581207,
            0.025048870593309402,
            0.008620950393378735,
            0.016993243247270584,
            -0.04869639128446579,
            0.0040636491030454636,
            0.002074850257486105,
            -0.023496268317103386,
            -0.010602492839097977,
            -0.011839780025184155,
            0.0059668621979653835,
            -0.03426281362771988,
            0.0003532454138621688,
            -0.0210272166877985,
            0.006810232065618038,
            -0.004938764963299036,
            -0.009437103755772114,
            0.003563718870282173,
            -0.005167991388589144,
            0.0371636301279068,
            -0.029602285474538803,
            0.02830914966762066,
            0.02533577010035515,
            -0.0007264439482241869,
            -0.020590661093592644,
            -0.02970799431204796,
            0.005438261665403843,
            0.02710908092558384,
            0.022005589678883553,
            -0.018631665036082268,
            -0.007152918726205826,
            0.016608260571956635,
            0.006698680110275745,
            -0.024159342050552368,
            0.00876240897923708,
            -0.005969212390482426,
            0.02993854135274887,
            0.024146167561411858,
            -0.0021308804862201214,
            0.014614361338317394,
            0.021977083757519722,
            -0.02513735555112362,
            -0.02215559594333172,
            0.026387738063931465,
            -0.031106702983379364,
            0.00062571867601946,
            0.01147623360157013,
            0.018025977537035942,
            0.018058976158499718,
            -0.020318256691098213,
            -0.011185250245034695,
            -0.02041574940085411,
            -0.008512035943567753,
            -0.02031109668314457,
            0.0022586567793041468,
            0.030964205041527748,
            0.020657453685998917,
            0.005612920504063368,
            -0.026554705575108528,
            -0.01699221506714821,
            -0.00931093655526638,
            0.004506364930421114,
            -0.027296556159853935,
            -0.0035443943925201893,
            0.0007144870469346642,
            0.018172213807702065,
            0.008726956322789192,
            -0.017161069437861443,
            0.006301028188318014,
            -0.0006029965006746352,
            0.015304161235690117,
            -0.0014806102262809873,
            0.025460530072450638,
            -0.0070716277696192265,
            0.04078817367553711,
            0.034139879047870636,
            -0.020598018541932106,
            0.027710190042853355,
            0.015563279390335083,
            -0.019210727885365486,
            -0.0270518958568573,
            -0.025644391775131226,
            -0.016414595767855644,
            0.019758006557822227,
            0.01855771243572235,
            -0.027801869437098503,
            -0.019466688856482506,
            -0.013052709400653839,
            0.0034910126123577356,
            -0.024049920961260796,
            0.027925783768296242,
            -0.0019780087750405073,
            0.002794699976220727,
            0.015976592898368835,
            -0.011210093274712563,
            -0.03961719945073128,
            0.006058095023036003,
            -0.028899939730763435,
            -0.0010335909901186824,
            0.000786125601734966,
            0.021130995824933052,
            0.032393407076597214,
            -0.008708835579454899,
            0.03225019946694374,
            0.027753639966249466,
            0.008421073667705059,
            -0.014089883305132389,
            -0.02860240265727043,
            0.015396900475025177,
            0.03049170970916748,
            0.01734786480665207,
            0.013617927208542824,
            -0.0017301334301009774,
            0.005919220857322216,
            0.030440554022789,
            -0.041419219225645065,
            0.020368365570902824,
            0.004355181474238634,
            -0.028303518891334534,
            0.030398236587643623,
            0.017721647396683693,
            -0.014694476500153542,
            0.022850289940834045,
            0.032377395778894424,
            0.0019041284685954452,
            -0.013570386916399002,
            0.034455519169569016,
            0.03213172033429146,
            -0.0036663871724158525,
            -0.013108315877616405,
            0.021903784945607185,
            0.02096608094871044,
            -0.038846760988235474,
            0.035368289798498154,
            -0.014271880500018597,
            0.024288516491651535,
            0.03084096498787403,
            -0.002973426366224885,
            -0.01015728060156107,
            -0.043203338980674744,
            -0.00514947809278965,
            0.018866322934627533,
            -0.0012554581044241786,
            -0.013543934561312199,
            0.010193702764809132,
            -0.010495983064174652,
            0.03717130795121193,
            0.00519479950889945,
            -0.022331232205033302,
            1.7169531929539517e-05,
            -0.011153236031532288,
            -0.001022528507746756,
            -0.0015793421771377325,
            0.018223902210593224,
            -0.010700942017138004,
            0.0038577790837734938,
            -0.003187300404533744,
            0.005253337323665619,
            -0.008494646288454533,
            0.005272509530186653,
            -0.027994642034173012,
            0.024669287726283073,
            -0.007148479577153921,
            0.008738428354263306,
            0.016608702018857002,
            -0.01569848693907261,
            -0.024796467274427414,
            -0.028760535642504692,
            -0.033405132591724396,
            -0.021541601046919823,
            0.022102512419223785,
            -0.0018646852113306522,
            0.026909956708550453,
            -0.005533560179173946,
            0.021667392924427986,
            0.014512057416141033,
            -0.0012223495868965983,
            -0.0193807203322649,
            -0.02273530326783657,
            0.002641871105879545,
            0.020369861274957657,
            -0.01773403026163578,
            -0.018930848687887192,
            -0.003146776929497719,
            0.0005147068877704442,
            0.02632632479071617,
            0.01759946718811989,
            0.009965657256543636,
            0.013456594198942184,
            -0.0009942310862243176,
            -0.01386263221502304,
            -0.005967970006167889,
            -0.006282922346144915,
            0.020384982228279114,
            -0.010298704728484154,
            -0.012857544235885143,
            -0.011710132472217083,
            -0.0048660277388989925,
            0.02814478985965252,
            -0.00963601004332304,
            0.02984526753425598,
            0.02260863594710827,
            0.015544326975941658,
            -0.014938914217054844,
            0.03701505437493324,
            -0.05225364863872528,
            -0.026782555505633354,
            -0.016408003866672516,
            0.0014484096318483353,
            0.03548964858055115,
            0.031413499265909195,
            0.023686643689870834,
            0.009422271512448788,
            0.028044361621141434,
            -0.016763053834438324,
            -0.023443320766091347,
            -0.017993804067373276,
            -0.02538623847067356,
            0.008232507854700089,
            0.021023044362664223,
            -0.022234605625271797,
            0.0014589059865102172,
            0.018418224528431892,
            0.0086818253621459,
            0.008217689581215382,
            -0.022621862590312958,
            0.02920059859752655,
            0.026147546246647835,
            0.031759630888700485,
            0.0026221503503620625,
            -2.374411269556731e-05,
            -0.02778388187289238,
            -0.0005525799351744354,
            0.02575823850929737,
            -0.018355006352066994,
            0.03977309539914131,
            0.04035581275820732,
            -0.005401079077273607,
            0.014375022612512112,
            0.013797870837152004,
            0.023160487413406372,
            0.008149750530719757,
            -0.009326361119747162,
            0.011117750778794289,
            0.027389397844672203,
            -0.04723358899354935,
            -0.034093305468559265,
            0.006747150793671608,
            -0.014695074409246445,
            -0.0022022079210728407,
            0.010043906047940254,
            -0.001031777122989297,
            0.017152074724435806,
            0.0013962122611701488,
            0.02927291952073574,
            0.0024771082680672407,
            -0.00042007354204542935,
            -0.0034067609813064337,
            0.02033154107630253,
            0.033007703721523285,
            -0.032679472118616104,
            0.027006955817341805,
            -0.03988763689994812,
            -0.0010410670656710863,
            0.0022040591575205326,
            -0.011559959501028061,
            -0.020395230501890182,
            0.01255886722356081,
            0.004963423125445843,
            -0.002880857326090336,
            -0.004951292183250189,
            0.03326983377337456,
            -0.013195019215345383,
            -0.017579689621925354,
            -0.004785900004208088,
            -0.007658996153622866,
            -0.011197205632925034,
            0.006982651073485613,
            0.006488812156021595,
            0.022995568811893463,
            0.0051674144342541695,
            0.03258700668811798,
            -0.019733265042304993,
            -0.019169308245182037,
            -0.035502608865499496,
            -0.00811078492552042,
            -0.006010189652442932,
            0.012474820017814636,
            0.030769776552915573,
            -0.03458358719944954,
            -0.026039857417345047,
            -0.010985293425619602,
            -0.01163395494222641,
            0.027491098269820213,
            0.031290166079998016,
            0.030760735273361206,
            -0.028578942641615868,
            -0.011730280704796314,
            0.003519259160384536,
            0.015981532633304596,
            -0.016392426565289497,
            0.014772589318454266,
            0.019230011850595474,
            0.026363350450992584,
            -0.01048881746828556,
            -0.011152554303407669,
            0.008990515023469925,
            -0.002785318298265338,
            0.004401151090860367,
            0.007582822348922491,
            -0.012145557440817356,
            0.02578684873878956,
            0.031414858996868134,
            0.022485563531517982,
            0.0202577393501997,
            -0.014022789895534515,
            -0.006368526257574558,
            -0.01217313390225172,
            -0.006319739855825901,
            0.02838481217622757,
            0.021068716421723366,
            -0.026260316371917725,
            0.009484710171818733,
            0.01278414111584425,
            -0.01866217516362667,
            0.0012674437602981925,
            -0.004993163049221039,
            -0.018879897892475128,
            -0.010970056988298893,
            -0.023024708032608032,
            -0.01630772463977337,
            0.010437906719744205,
            -0.014991511590778828,
            0.027342962101101875,
            0.01174345426261425,
            -0.025541437789797783,
            0.016446033492684364,
            0.006049411371350288,
            0.019350076094269753,
            0.02052169479429722,
            -0.0013093458255752921,
            -0.021045513451099396,
            0.001642431947402656,
            0.005485105328261852,
            -0.011743023991584778,
            -0.002941220998764038,
            -0.013622492551803589,
            0.006022847723215818,
            0.018045226112008095,
            0.002186395926401019,
            0.01318258885294199,
            0.020151309669017792,
            0.004837607499212027,
            0.033988725394010544,
            -0.021166251972317696,
            0.02644648216664791,
            0.007333113346248865,
            -0.02968941070139408,
            0.02156977914273739,
            -0.02417675405740738,
            0.022102445363998413,
            0.037148039788007736,
            -0.03575063869357109,
            -0.012266743928194046,
            -0.0005831782473251224,
            -0.0006108577363193035,
            -0.016246967017650604,
            -0.02210068143904209,
            0.017526274546980858,
            0.02037885971367359,
            -0.016367940232157707,
            0.032392747700214386,
            -0.005209079012274742,
            -0.015540470369160175,
            -0.02553504705429077,
            0.007296280469745398,
            -0.015506774187088013,
            0.033583804965019226,
            0.0062973410822451115,
            -0.019858073443174362,
            -0.005505403503775597,
            -0.008043027482926846,
            0.018708935007452965,
            -0.04359961673617363,
            0.028830213472247124,
            -0.02265828102827072,
            -0.03733642399311066,
            0.029102671891450882,
            0.011236905120313168,
            -0.02476150169968605,
            -0.020878098905086517,
            -0.020811349153518677,
            -0.013368994928896427,
            0.020965393632650375,
            0.0013638853561133146,
            0.015424391254782677,
            0.015183222480118275,
            -0.012106750160455704,
            0.0002302081702509895,
            -0.024139709770679474,
            0.03670932352542877,
            -0.030781753361225128,
            0.015535426326096058,
            -0.012365455739200115,
            -0.0030770720914006233,
            -0.009653274901211262,
            0.02063618041574955,
            0.026115426793694496,
            -0.015306266956031322,
            -0.02827533707022667,
            -0.022936854511499405,
            -0.002130575245246291,
            -0.013333799317479134,
            -0.019144326448440552,
            -0.01720571517944336,
            0.0315980538725853,
            -0.015500812791287899,
            0.03452810272574425,
            0.026189057156443596,
            -0.03355895355343819,
            -0.015395400114357471,
            -0.011006657034158707,
            0.010945170186460018,
            0.01114861574023962,
            -0.0076635004952549934,
            0.0013062531361356378,
            -0.029346134513616562,
            0.031218882650136948,
            -0.011852258816361427,
            -0.02026316151022911,
            -0.000791883678175509,
            0.013021177612245083,
            0.02000981569290161,
            0.029034845530986786,
            -0.008686698041856289,
            -0.01093673799186945,
            -0.013568779453635216,
            0.02117730677127838,
            -0.02178536169230938,
            0.029713211581110954,
            0.0077072130516171455,
            -0.016583172604441643,
            -0.026721050962805748,
            -0.016236986964941025,
            0.02500510960817337,
            0.004115474876016378,
            0.01528199017047882,
            -0.005999740678817034,
            -0.015868794173002243,
            -0.0028846506029367447,
            0.027415793389081955,
            -0.004528565797954798,
            -0.002567128511145711,
            0.025990331545472145,
            0.0362682119011879,
            0.05559857189655304,
            0.005167874041944742,
            -0.017701679840683937,
            -0.013549716211855412,
            0.02465720660984516,
            0.017404919490218163,
            -0.00546984001994133,
            -0.01227387972176075,
            0.019747238606214523,
            -0.019369225949048996,
            0.02374727465212345,
            -0.02688906341791153,
            0.0283027496188879,
            0.03375024348497391,
            -0.033614084124565125,
            -0.038705792278051376,
            0.02126978151500225,
            -0.008025847375392914,
            -0.02095700055360794,
            0.012598071247339249,
            -0.029858360067009926,
            0.03417178988456726,
            -0.014493909664452076,
            -0.02382577769458294,
            0.002473952015861869,
            -0.000136621281853877,
            0.010435148142278194,
            0.024937829002738
        ],
        [
            0.02613888867199421,
            -0.035293832421302795,
            0.008596350438892841,
            -0.041283972561359406,
            -0.013956005685031414,
            -0.055599842220544815,
            0.01523500680923462,
            0.01600225269794464,
            0.019750075414776802,
            -0.014409109018743038,
            0.003104065777733922,
            -0.02449323795735836,
            0.000985747785307467,
            0.025063563138246536,
            -0.034092120826244354,
            -0.02160351164638996,
            0.03320586681365967,
            0.0015737451612949371,
            -0.0007022551144473255,
            0.006809374317526817,
            0.0339946486055851,
            0.026617063209414482,
            -0.004054359160363674,
            -0.0011606677435338497,
            -0.01921847276389599,
            -0.03024325706064701,
            0.013818468898534775,
            -0.04790749028325081,
            -0.019161654636263847,
            -0.03553740307688713,
            -0.016585882753133774,
            -0.005612388718873262,
            0.027744635939598083,
            -0.02355595864355564,
            0.014365638606250286,
            -0.0008466051076538861,
            -0.013274637050926685,
            -0.020438387989997864,
            -0.03666721656918526,
            -0.009325919672846794,
            -0.008944656699895859,
            -0.0038330277893692255,
            0.007975462824106216,
            -0.0006399440462701023,
            0.024272920563817024,
            0.0058616348542273045,
            -0.037346385419368744,
            0.010747485794126987,
            -0.006464078091084957,
            0.04027332365512848,
            -0.008791959844529629,
            -0.00033490691566839814,
            -0.0327049195766449,
            -0.01496973168104887,
            -0.022908305749297142,
            -0.04107283428311348,
            -0.02213134989142418,
            0.011148480698466301,
            -0.0376010425388813,
            -0.03513352945446968,
            0.046532269567251205,
            0.02284342423081398,
            -0.0009455238468945026,
            -0.01322709210216999,
            -0.030762827023863792,
            -0.04374483600258827,
            0.010897999629378319,
            -0.013218668289482594,
            -0.01667790114879608,
            0.017209140583872795,
            0.01976473070681095,
            0.01161788310855627,
            -0.006366913206875324,
            -0.012795815244317055,
            -0.02195555344223976,
            0.020523665472865105,
            0.025176960974931717,
            -0.010350632481276989,
            -0.05006508156657219,
            -0.010634584352374077,
            -0.022158220410346985,
            -0.03732747957110405,
            0.02846529707312584,
            0.00033643501228652894,
            -0.03772072121500969,
            -0.006402465980499983,
            0.023098748177289963,
            -0.0320977121591568,
            -0.00272172037512064,
            -0.018761076033115387,
            -0.012348259799182415,
            0.0098881172016263,
            0.010608779266476631,
            0.004505156073719263,
            0.024937890470027924,
            0.026285573840141296,
            -0.024534255266189575,
            -0.006043702829629183,
            -0.04429619014263153,
            0.015456089749932289,
            0.02017420157790184,
            0.04051908105611801,
            -0.031491100788116455,
            0.006191587541252375,
            -0.02754065953195095,
            -0.05346420034766197,
            -0.005548242945224047,
            -0.00760507071390748,
            0.012512000277638435,
            -0.016930844634771347,
            -0.0038435212336480618,
            -0.017002051696181297,
            -0.02663792297244072,
            0.029460208490490913,
            0.012095077894628048,
            0.0050939591601490974,
            0.015201806090772152,
            0.03041306883096695,
            0.028544029220938683,
            0.030455781146883965,
            0.017363019287586212,
            0.021037425845861435,
            -0.01597399264574051,
            -0.025209937244653702,
            0.024730468168854713,
            -0.032561078667640686,
            0.04323352128267288,
            0.03319815173745155,
            -0.017099065706133842,
            0.04842495918273926,
            -0.0019693507347255945,
            0.03005697764456272,
            0.04045262932777405,
            0.041692666709423065,
            -0.01713879406452179,
            0.03121093474328518,
            -0.023501649498939514,
            0.031094780191779137,
            0.023872768506407738,
            0.007034005597233772,
            0.0022095784079283476,
            0.03171314299106598,
            -0.02341907098889351,
            0.003671412356197834,
            0.05385374277830124,
            0.00359799200668931,
            0.007094265893101692,
            0.020401161164045334,
            0.006394732277840376,
            0.011771008372306824,
            0.02503242716193199,
            0.054802436381578445,
            0.009929036721587181,
            0.029453914612531662,
            -0.03951133415102959,
            0.01365817990154028,
            0.001447138492949307,
            -0.028497332707047462,
            -0.003087330376729369,
            0.031350985169410706,
            0.022678561508655548,
            -0.011418703012168407,
            0.0015740852104499936,
            -0.024217138066887856,
            -0.027510568499565125,
            0.016690056771039963,
            0.07327394932508469,
            -0.011521857231855392,
            -0.02936793491244316,
            -0.015524781309068203,
            -0.020836113020777702,
            0.010291085578501225,
            -0.04359086975455284,
            0.029587889090180397,
            -0.010402727872133255,
            0.030291426926851273,
            -0.008793875575065613,
            -0.007830164395272732,
            -0.03348594903945923,
            0.028778668493032455,
            0.02176653780043125,
            0.000221005902858451,
            -0.01449165865778923,
            0.034477926790714264,
            -0.008966172114014626,
            0.01305922120809555,
            0.00015716474445071071,
            -0.0047179292887449265,
            0.011513935402035713,
            -0.022371036931872368,
            0.03895358368754387,
            0.029341138899326324,
            0.0072026606649160385,
            -0.03719675913453102,
            0.00823395885527134,
            -0.013220391236245632,
            0.012899654917418957,
            -0.0025770249776542187,
            0.02425832860171795,
            -0.003363278927281499,
            0.005907210521399975,
            -0.0030471959616988897,
            0.03157525882124901,
            0.015003545209765434,
            -0.03193294629454613,
            0.03430141881108284,
            -0.04795941710472107,
            -0.008762230165302753,
            -0.015612807124853134,
            -0.0017590192146599293,
            -0.01620767079293728,
            0.035992201417684555,
            -0.0028484310023486614,
            -0.03093389794230461,
            0.035799380391836166,
            -0.01421278528869152,
            -0.014828093349933624,
            0.018531182780861855,
            0.0015071154339239001,
            0.007618635892868042,
            -0.017754457890987396,
            -0.02502492256462574,
            -0.026213129982352257,
            0.032998502254486084,
            -0.0051496196538209915,
            -0.05306525155901909,
            -0.0032751248218119144,
            -0.004394531715661287,
            -0.01129593513906002,
            0.008362874388694763,
            -0.022248992696404457,
            -0.039372317492961884,
            0.015986604616045952,
            -0.021114272996783257,
            -0.06748569756746292,
            -0.01435784064233303,
            0.05650045722723007,
            0.023416895419359207,
            0.004548818804323673,
            0.005545943509787321,
            -0.0017143714940175414,
            0.025078801438212395,
            0.013439302332699299,
            -0.016254253685474396,
            -0.016573425382375717,
            0.012780169025063515,
            0.013212227262556553,
            -0.056988079100847244,
            0.027505602687597275,
            0.03901094198226929,
            0.01988047920167446,
            -0.03426302224397659,
            0.03238510712981224,
            -0.007726574316620827,
            0.003570731496438384,
            -0.014006811194121838,
            0.02927556447684765,
            -0.0036737308837473392,
            0.0519934743642807,
            -0.024812061339616776,
            0.01554066315293312,
            0.006945916451513767,
            0.015079351142048836,
            0.023293906822800636,
            -0.002842800924554467,
            -0.016042208299040794,
            0.01972334086894989,
            -0.03318670764565468,
            -0.019687963649630547,
            0.03755141794681549,
            -0.04219295084476471,
            0.016240231692790985,
            0.02848881669342518,
            -0.003370600752532482,
            -0.046858418732881546,
            0.01932399719953537,
            -0.00858806911855936,
            0.011221837252378464,
            0.008404014632105827,
            0.021936681121587753,
            -0.02631412260234356,
            -0.01993774063885212,
            -0.02020370215177536,
            -0.013389269821345806,
            -0.013500170782208443,
            0.018448924645781517,
            0.04217620566487312,
            -0.012397891841828823,
            -0.026585791260004044,
            0.01762676239013672,
            -0.022873276844620705,
            -0.011851479299366474,
            -0.01885366067290306,
            0.0043129464611411095,
            -0.007716488558799028,
            0.00820477306842804,
            -0.00048631703248247504,
            0.018461914733052254,
            0.04520589858293533,
            0.020683659240603447,
            -0.021009167656302452,
            -0.00917647872120142,
            -0.03922025486826897,
            -0.04638209566473961,
            -0.02010491117835045,
            -0.011697428300976753,
            -0.02118510566651821,
            0.033875081688165665,
            0.0020620834548026323,
            0.043814729899168015,
            -0.04057825729250908,
            0.002665555104613304,
            -0.024750864133238792,
            -0.018369650468230247,
            -0.015297682024538517,
            0.01599235087633133,
            -0.015630502253770828,
            -0.025775495916604996,
            0.012021946720778942,
            0.03571675717830658,
            0.02727692201733589,
            -0.007241879124194384,
            0.042062219232320786,
            0.0011221925960853696,
            0.008342098444700241,
            -0.006273380480706692,
            0.009412962011992931,
            -0.02636655978858471,
            -0.024332227185368538,
            -0.031475730240345,
            0.02482271008193493,
            -0.007879258133471012,
            -0.006634702906012535,
            -0.038100991398096085,
            0.02253049612045288,
            -0.00756866717711091,
            -0.016118714585900307,
            -0.00042156019480898976,
            -0.007672436535358429,
            -0.003229863476008177,
            0.01699482463300228,
            0.02390311285853386,
            -0.0035736996214836836,
            -0.005986402276903391,
            0.008093861863017082,
            -0.014205829240381718,
            0.010877072811126709,
            0.022576240822672844,
            0.03363652899861336,
            0.06069006025791168,
            0.014378577470779419,
            0.052888549864292145,
            0.017453962936997414,
            -0.010991198942065239,
            0.024360833689570427,
            -0.039369821548461914,
            0.016770118847489357,
            0.014486509375274181,
            0.018752973526716232,
            -0.00801730714738369,
            -0.019949451088905334,
            -0.02999860979616642,
            0.006222478114068508,
            -0.013285605236887932,
            -0.03457031771540642,
            -0.008508829399943352,
            -0.010282652452588081,
            0.015181899070739746,
            0.011463820934295654,
            -0.01644003950059414,
            0.002460619667544961,
            0.014288731850683689,
            0.004673871677368879,
            -0.03358234092593193,
            -0.04271665960550308,
            -0.014828994870185852,
            0.02067001536488533,
            -0.01605653390288353,
            -0.03777090832591057,
            0.013025299645960331,
            -0.05914203077554703,
            -0.01626255176961422,
            0.00902026891708374,
            -0.012012920342385769,
            -0.012714577838778496,
            -0.06797406077384949,
            -0.007170462515205145,
            -0.05781606584787369,
            -0.004457069560885429,
            -0.015472630970180035,
            0.01839253678917885,
            -0.004376443102955818,
            -0.005551555193960667,
            -0.004516104701906443,
            0.02937937341630459,
            -0.03414422273635864,
            -0.02652594819664955,
            0.05715024098753929,
            0.0023467058781534433,
            0.04625152423977852,
            0.007122787646949291,
            -0.0015563849592581391,
            -0.0051108188927173615,
            -0.00559289800003171,
            -0.004191116895526648,
            0.008251749910414219,
            -0.030522694811224937,
            0.024232642725110054,
            -0.02268390543758869,
            0.00012635304301511496,
            -0.002792760729789734,
            -0.0008255753782577813,
            0.030472813174128532,
            -0.004541141912341118,
            0.003712080419063568,
            -0.00871837604790926,
            -0.02681737393140793,
            -0.040730252861976624,
            0.019254693761467934,
            0.026404285803437233,
            -0.04429170861840248,
            0.04285948723554611,
            0.032793208956718445,
            0.002526922384276986,
            0.009990436024963856,
            0.03247084468603134,
            0.04268985614180565,
            -0.04007263109087944,
            0.009858326986432076,
            -0.0075150602497160435,
            0.002317747799679637,
            -0.013182048685848713,
            0.028652656823396683,
            0.04700205847620964,
            0.008181320503354073,
            -0.03406290337443352,
            0.013909673318266869,
            -0.04264489933848381,
            0.008232740685343742,
            0.013900965452194214,
            0.01623978279531002,
            0.02799779735505581,
            0.005623881705105305,
            -0.008713996037840843,
            -0.0043877349235117435,
            0.006656654179096222,
            0.015692928805947304,
            -0.05955815687775612,
            0.00951308198273182,
            -0.029501473531126976,
            -0.01759510487318039,
            -0.027568291872739792,
            0.044794779270887375,
            -0.02595437876880169,
            -0.034391336143016815,
            -0.033867452293634415,
            0.025109723210334778,
            -0.003259435296058655,
            -0.022493373602628708,
            -0.005104628391563892,
            0.06177520006895065,
            -0.03844630345702171,
            -0.0015381904086098075,
            -0.012343148700892925,
            -0.015922201797366142,
            0.01852968893945217,
            0.02586865797638893,
            -0.007935506291687489,
            -0.01666261814534664,
            0.012030192650854588,
            -0.011905509978532791,
            -0.018361177295446396,
            -0.022889507934451103,
            0.02529602125287056,
            -0.028913892805576324,
            -0.009684889577329159,
            -0.01112399809062481,
            0.008538520894944668,
            0.009221144951879978,
            -0.000982747646048665,
            -0.008073863573372364,
            0.0029373387806117535,
            -0.032360054552555084,
            0.016595181077718735,
            0.057615671306848526,
            -0.017201777547597885,
            -0.013296137563884258,
            -0.011063309386372566,
            0.002176255453377962,
            -0.013926656916737556,
            0.013658211566507816,
            -0.003247489919885993,
            -0.0158830713480711,
            0.010206601582467556,
            0.0161336250603199,
            0.02588343806564808,
            -0.007139482069760561,
            -0.03253169730305672,
            0.024519415572285652,
            0.03000270389020443,
            0.03338078036904335,
            0.020128268748521805,
            -0.031326163560152054,
            -0.02902424894273281,
            -0.04441017657518387,
            0.0011146899778395891,
            0.030497834086418152,
            0.026417825371026993,
            0.01247835997492075,
            -0.014447118155658245,
            0.019769860431551933,
            0.035949867218732834,
            -0.03359220176935196,
            -0.01729186251759529,
            0.03851010277867317,
            -0.02420942671597004,
            -0.02149813249707222,
            0.004352257587015629,
            0.04365275427699089,
            0.0323847234249115,
            0.005875458940863609,
            -0.0016615708591416478,
            -0.009108359925448895,
            -0.02347532846033573,
            -0.001455733086913824,
            -0.01862572506070137,
            0.021516229957342148,
            0.0012088713701814413,
            -0.01516688521951437,
            -0.019514713436365128,
            -0.007084506098181009,
            -0.0004738781717605889,
            -0.011382856406271458,
            -0.027099046856164932,
            0.0240604430437088,
            -0.029440805315971375,
            -0.007529652677476406,
            0.007456712890416384,
            -0.004827792290598154,
            0.0019920039921998978,
            0.0364670529961586,
            -0.012258578091859818,
            0.03608516976237297,
            -0.013382863253355026,
            -0.0005156045081093907,
            -0.021700125187635422,
            0.011138470843434334,
            -0.04533343389630318,
            -0.02228841744363308,
            0.014938371255993843,
            -0.012196325697004795,
            0.004621685948222876,
            -0.04111672565340996,
            0.019206959754228592,
            -0.02851894497871399,
            -0.02379370480775833,
            0.005568370688706636,
            -0.02342107705771923,
            0.00837008934468031,
            0.01994846761226654,
            -0.016951028257608414,
            0.03569032996892929,
            0.02321653626859188,
            0.03548133373260498,
            0.01786920242011547,
            -0.0047130342572927475,
            0.014963400550186634,
            0.0013895687879994512,
            -0.025479450821876526,
            0.007552618160843849,
            -0.017109934240579605,
            -0.029781227931380272,
            -0.032514140009880066,
            0.014730140566825867,
            -0.0187088456004858,
            -0.03534935042262077,
            0.006129022222012281,
            -0.020128615200519562,
            0.015049039386212826,
            -0.03945961222052574,
            -0.023201538249850273,
            -0.007784743327647448,
            0.03657865524291992,
            0.012286546640098095,
            -0.01196842361241579,
            -0.01309171412140131,
            -0.00173378421459347,
            0.0014024166157469153,
            0.00044475513277575374,
            -0.0010040287161245942,
            -0.015051765367388725,
            0.02434438467025757,
            -0.022187620401382446,
            0.004849399905651808,
            0.003649535123258829,
            -0.02464890480041504,
            -0.02684350498020649,
            -0.002449783030897379,
            -0.011513639241456985,
            0.01383538730442524,
            0.02610686421394348,
            -0.025058118626475334,
            -0.016311027109622955,
            -0.0248675849288702,
            -0.0008876748033799231,
            -0.02476951852440834,
            0.001547897350974381,
            0.03150881081819534,
            -0.01799592562019825,
            0.0019184363773092628,
            0.0033545338083058596,
            -0.015682874247431755,
            -0.01119937188923359,
            0.023428793996572495,
            -0.006662917323410511,
            0.015381705947220325,
            -0.0390905998647213,
            0.026707161217927933,
            -0.034663379192352295,
            0.038435306400060654,
            0.02401750162243843,
            -0.026842618361115456,
            0.026676878333091736,
            0.0018825528677552938,
            0.01782500557601452,
            0.00810093991458416,
            -0.02156442403793335,
            -0.0008404934778809547,
            -0.02721230313181877,
            -7.952465966809541e-05,
            0.027598122134804726,
            -0.011299005709588528,
            -0.008088924922049046,
            -0.008532888256013393,
            -0.049612436443567276,
            -0.01009279303252697,
            0.013817977160215378,
            -0.007473422680050135,
            0.005202396772801876,
            -0.006560702342540026,
            0.0007551147718913853,
            0.010882815346121788,
            -0.04510829970240593,
            -0.019234368577599525,
            0.027097709476947784,
            0.002522099297493696,
            0.018986929208040237,
            0.015637466683983803,
            0.028217243030667305,
            -0.01766461320221424,
            0.03510455787181854,
            -0.008396957069635391,
            0.01565687358379364,
            0.028338806703686714,
            0.010852000676095486,
            0.010656332597136497,
            -0.00011728956451406702,
            -0.03624473884701729,
            -0.038382235914468765,
            -0.024643655866384506,
            0.010701063089072704,
            -0.02346160262823105,
            -0.007066020742058754,
            0.023974277079105377,
            0.010371411219239235,
            -0.02929716370999813,
            -0.011952104046940804,
            0.02574228122830391,
            -0.02828802540898323,
            0.01576714776456356,
            0.028224855661392212,
            0.02748062275350094,
            -0.02757192589342594,
            -0.005947774276137352,
            -0.006288279313594103,
            -0.010476839728653431,
            -0.018199879676103592,
            0.0062969098798930645,
            -0.01224815659224987,
            -0.005857081152498722,
            0.016378475353121758,
            -0.020919043570756912,
            0.0030108285136520863,
            -0.030849514529109,
            0.014536888338625431,
            -0.02031036838889122,
            0.013207480311393738,
            -0.04383225739002228,
            0.011941500939428806,
            -0.029855532571673393,
            0.0034585895482450724,
            -0.054118309170007706,
            0.016161635518074036,
            0.030661361292004585,
            -0.031428903341293335,
            0.002456426853314042,
            0.019270267337560654,
            0.02608921192586422,
            -0.00028416168061085045,
            -0.018872573971748352,
            -0.011456186883151531,
            -0.0020795734599232674,
            -0.043955348432064056,
            0.018746105954051018,
            0.00026947283186018467,
            -0.008018669672310352,
            -0.009377110749483109,
            0.022238174453377724,
            0.018375016748905182,
            0.060073371976614,
            0.0212699044495821,
            -0.02406676858663559,
            -0.007514822296798229,
            -0.01508098654448986,
            0.04176711291074753,
            0.02804851531982422,
            0.015233603306114674,
            0.03332618623971939,
            -0.010498766787350178,
            0.032426636666059494,
            0.024084849283099174,
            0.0017961513949558139,
            -0.01406287495046854,
            0.03251079469919205,
            -0.03561939299106598,
            -0.04828088358044624,
            0.026892289519309998,
            0.01982247270643711,
            0.017447222024202347,
            0.005368457641452551,
            -0.004827299155294895,
            0.003523300401866436,
            0.03383021429181099,
            0.020039759576320648,
            -0.02928651124238968,
            -0.003404720453545451,
            0.02199886180460453,
            0.010620984248816967,
            -0.04862874746322632,
            0.03271080181002617,
            -0.0005602382589131594,
            0.03321445360779762,
            0.029528344050049782,
            -0.07317464053630829,
            0.005549348425120115,
            0.007501956541091204,
            0.07878469675779343,
            0.016317041590809822,
            -0.023919804021716118,
            -0.0011059941025450826,
            0.010192464105784893,
            -0.004871685057878494,
            -0.01571032777428627,
            -0.03271240368485451,
            0.01198749989271164,
            -0.003224567975848913,
            0.008424760773777962,
            -0.0029907170683145523,
            0.04283139109611511,
            -0.0009348962921649218,
            -0.01810450293123722,
            0.0027369630988687277,
            -0.007365524303168058,
            0.02000337466597557,
            0.028062352910637856,
            -0.05781284719705582,
            -0.022776851430535316,
            0.007355814334005117,
            -0.009783724322915077,
            -0.010394864715635777,
            0.031921908259391785,
            -0.02966802380979061,
            0.010482218116521835,
            0.021004656329751015,
            -0.0048698619939386845,
            -0.03307979926466942,
            -0.016235003247857094,
            0.016408905386924744,
            0.011593918316066265,
            -0.00019484815129544586,
            -0.02609884738922119,
            -0.017286479473114014,
            -0.03177625685930252,
            -0.013847040012478828,
            -0.0050405943766236305,
            -0.015404418110847473,
            -0.05052420124411583,
            0.02014685794711113,
            0.01575632393360138,
            0.030175136402249336,
            -0.033911220729351044,
            0.009341453202068806,
            -0.0009428077610209584,
            0.021301716566085815,
            -0.021803371608257294,
            0.005474270321428776,
            -0.002109105698764324,
            0.002909864531829953,
            0.025380011647939682,
            0.007282141130417585,
            -0.024977969005703926,
            -0.020848795771598816,
            0.029257703572511673,
            -0.0514996200799942,
            0.041831426322460175,
            -0.02860042080283165,
            0.03539799898862839,
            -0.0037262411788105965,
            -0.01920459046959877,
            -0.07652158290147781,
            0.029094697907567024,
            -0.020083023235201836,
            0.001935779582709074,
            0.00047731277300044894,
            0.04933825135231018,
            0.008688376285135746,
            0.019018376246094704,
            0.007889136672019958,
            -0.0026588081382215023,
            0.032116204500198364,
            -0.01747605763375759,
            0.02415076270699501,
            -0.00250423070974648,
            -0.014706824906170368,
            -0.013047166168689728,
            0.008903522975742817,
            0.006325076334178448,
            -0.01687915064394474,
            0.002531216014176607,
            -0.012155275791883469,
            0.015032309107482433,
            0.012270876206457615,
            0.0004864840302616358,
            -0.01412296760827303,
            0.051705118268728256,
            -0.01867501810193062,
            -0.019118810072541237,
            -0.005955904722213745,
            0.022772567346692085,
            -0.010003863833844662,
            -0.006486115511506796,
            0.004374220967292786,
            0.03258824720978737,
            -0.01999802514910698,
            -0.017766820266842842,
            -0.02451283112168312,
            0.0005253574345260859,
            0.007029073778539896,
            0.04156332090497017,
            0.01884831301867962,
            -0.0442742295563221,
            0.0068346853367984295,
            0.00028867748915217817,
            -0.04862465709447861,
            0.01391431875526905,
            -0.014637059532105923,
            0.005297814961522818,
            -0.008101316168904305,
            0.038771942257881165,
            -0.018212109804153442,
            -0.01954789087176323,
            -0.035169798880815506,
            -0.02379259280860424,
            0.012309289537370205,
            -0.007289028726518154,
            0.012809730134904385,
            0.013232884928584099,
            0.017460700124502182,
            0.030077407136559486,
            -0.010849160142242908,
            0.07876158505678177,
            0.008606787770986557,
            -0.049337103962898254,
            -0.028729725629091263,
            0.024580903351306915,
            -0.0320737361907959,
            -0.026437534019351006,
            -0.0459589920938015,
            0.017260635271668434,
            -0.0200644563883543,
            -0.00013123615644872189,
            0.04003806784749031,
            0.06658218055963516,
            -0.012974482960999012,
            0.019070910289883614,
            -0.03375070542097092,
            0.022538071498274803,
            -0.026158347725868225,
            0.00801476277410984,
            0.004374423064291477,
            0.011160314083099365,
            0.005320834927260876,
            -0.014848664402961731,
            0.0156934205442667,
            -0.02003513276576996,
            0.02706550434231758,
            -0.00801144540309906,
            0.02367229200899601,
            -0.008093264885246754,
            0.04620295763015747,
            0.04009561985731125,
            -0.03184127435088158,
            0.0066699981689453125,
            0.022394059225916862,
            -0.024112291634082794,
            0.04909534752368927,
            0.020817389711737633,
            0.005498707294464111,
            0.009648936800658703,
            -0.020711662247776985,
            0.027270151302218437,
            -0.004959389567375183,
            0.007718405220657587,
            -0.0020275760907679796,
            -0.054114509373903275,
            -0.047054558992385864,
            0.04702703654766083,
            -0.016284706071019173,
            -0.01909046806395054,
            -0.03195209801197052,
            0.016863681375980377,
            -0.060686614364385605,
            -0.045240648090839386,
            0.05067892372608185,
            0.004918328952044249,
            -0.004144477192312479,
            0.0004049227572977543,
            -0.0033330125734210014,
            -0.01667839288711548,
            0.017663899809122086,
            -0.02150924876332283,
            0.02322370745241642,
            -0.004636377561837435,
            0.022687766700983047,
            -0.010470874607563019,
            0.021854324266314507,
            0.005418794695287943,
            0.012661370448768139,
            -0.006991869769990444,
            0.03983106464147568,
            0.014120920561254025,
            0.006418466102331877,
            0.0010905057424679399,
            -0.006563073489814997,
            0.06532636284828186,
            -0.025673512369394302,
            0.007033324800431728,
            -0.015644432976841927,
            0.00032786469091661274,
            0.009805357083678246,
            0.0469878651201725,
            -0.028675468638539314,
            -0.04509662836790085,
            -0.025975365191698074,
            0.0202630665153265,
            0.02530623786151409,
            0.019531359896063805,
            0.002596374833956361,
            0.054922766983509064,
            -0.038346726447343826,
            -0.005869951564818621,
            0.012092642486095428,
            0.017911864444613457,
            -0.003079069312661886,
            0.004259040113538504,
            -0.009353883564472198,
            -0.0013216506922617555,
            -0.0020401228684931993,
            0.04451591148972511,
            0.018713757395744324,
            -0.04930386319756508,
            0.019489232450723648,
            0.038932301104068756,
            0.02328488603234291,
            -0.024944845587015152,
            -0.011638573370873928,
            0.02316465973854065,
            0.011448709294199944,
            -0.039610885083675385,
            -0.024817701429128647,
            -0.027139678597450256,
            0.010705599561333656,
            0.02757073938846588,
            0.020902132615447044,
            -0.018606632947921753,
            -0.009321867488324642,
            0.04105816036462784,
            0.030924690887331963,
            0.006829713471233845,
            -0.02536945417523384,
            -0.02082771435379982,
            0.0050054085440933704,
            0.008849568665027618,
            -0.0064479815773665905,
            -0.030478108674287796,
            0.04000501707196236,
            0.05038215592503548,
            -0.003994349390268326,
            -0.021833214908838272,
            -0.0035381626803427935,
            -0.0026588544715195894,
            -0.026698961853981018,
            0.037187907844781876,
            -0.019571756944060326,
            -0.013498252257704735,
            -0.002411283552646637,
            0.02500508539378643,
            0.0011473576305434108,
            -0.005719953216612339,
            -0.009384856559336185,
            0.039163291454315186,
            0.00239285989664495,
            -0.007312314584851265,
            -0.019894259050488472,
            0.00771979708224535,
            -0.03196464478969574,
            -0.022997179999947548,
            0.02594316005706787,
            -0.012176374904811382,
            0.03224749118089676,
            0.03491600602865219,
            0.05570858344435692,
            0.04013914242386818,
            0.006003826390951872
        ],
        [
            0.009139630012214184,
            -0.004831364843994379,
            0.011082097887992859,
            0.019368143752217293,
            0.015148558653891087,
            0.0241021029651165,
            -0.034773118793964386,
            0.019816337153315544,
            -0.010327350348234177,
            -7.3001392593141645e-06,
            -0.007002929225564003,
            -0.005313998553901911,
            -0.006933959200978279,
            -0.018453095108270645,
            -0.0712815597653389,
            0.03524289280176163,
            -0.017904415726661682,
            -0.019269365817308426,
            -0.019530657678842545,
            0.011951861903071404,
            0.02291465550661087,
            -0.011310740374028683,
            -0.005925794132053852,
            0.026151388883590698,
            0.0013713238295167685,
            0.008017280139029026,
            0.0009313734481111169,
            -0.02250630408525467,
            -0.006953747943043709,
            -0.019467193633317947,
            0.00761417718604207,
            0.011545207351446152,
            0.02290521375834942,
            -0.04539396986365318,
            0.010086153633892536,
            -0.0019064361695200205,
            0.020375670865178108,
            -0.023656068369746208,
            -0.005274390336126089,
            0.0005682996707037091,
            -0.041115738451480865,
            0.003101477399468422,
            -0.014832763001322746,
            -0.04046518728137016,
            0.014672648161649704,
            0.011872043833136559,
            -0.026074441149830818,
            -0.004568743985146284,
            0.004873607307672501,
            -0.00991000235080719,
            0.0074552614241838455,
            -0.045670393854379654,
            0.0034609478898346424,
            -0.004009089432656765,
            -0.028984325006604195,
            0.007402550429105759,
            0.037584710866212845,
            0.0073042968288064,
            0.02390030212700367,
            -0.02062148042023182,
            -0.024878842756152153,
            0.02783268690109253,
            0.014679282903671265,
            -0.0009082290343940258,
            0.03085547499358654,
            -0.00943649373948574,
            0.0454091802239418,
            0.00014127217582426965,
            0.0041843899525702,
            -0.02748056687414646,
            -0.012686747126281261,
            -0.025902440771460533,
            0.030653975903987885,
            0.009467163123190403,
            0.05504618585109711,
            -0.020187560468912125,
            0.03185524046421051,
            -0.015694931149482727,
            -0.006672651972621679,
            0.0016378581058233976,
            0.023295817896723747,
            0.011133357882499695,
            -0.009051449596881866,
            -0.033431526273489,
            0.03575516492128372,
            -0.013197706080973148,
            0.0043469807133078575,
            0.018325360491871834,
            0.0008276435546576977,
            0.019247286021709442,
            0.02156814932823181,
            0.02881954424083233,
            -0.04341795668005943,
            0.0022069504484534264,
            0.012745995074510574,
            0.027676314115524292,
            -0.006658758502453566,
            0.029434382915496826,
            0.10806675255298615,
            0.011250633746385574,
            -0.010212451219558716,
            0.013968554325401783,
            0.00750721013173461,
            -3.773337812162936e-05,
            0.013204222545027733,
            0.019120953977108,
            -0.012152970768511295,
            0.02356158196926117,
            0.02343885600566864,
            0.029322564601898193,
            -0.031002117320895195,
            -0.017484089359641075,
            -0.012445059604942799,
            0.02983647957444191,
            0.009025610983371735,
            0.015811465680599213,
            0.02285786159336567,
            -0.035225387662649155,
            0.0036308555863797665,
            -0.0437648706138134,
            0.018321964889764786,
            0.03292582184076309,
            -0.06826701760292053,
            -0.015278051607310772,
            -0.012671866454184055,
            -0.029456153512001038,
            0.013235022313892841,
            -0.02602684311568737,
            -0.01709938235580921,
            0.03002086840569973,
            -0.010590328834950924,
            0.008799434639513493,
            0.01056741178035736,
            -0.04225856810808182,
            0.02087567001581192,
            0.02261137217283249,
            0.023015156388282776,
            -0.032798394560813904,
            0.0218465905636549,
            0.021881720051169395,
            -0.018339302390813828,
            -0.0009203418740071356,
            0.022060992196202278,
            0.023751061409711838,
            -0.009599284268915653,
            -0.0098001379519701,
            0.02726290374994278,
            -0.03537986800074577,
            -0.008044546470046043,
            0.02341204509139061,
            -0.005233684554696083,
            -0.06407494843006134,
            -0.004857589956372976,
            -0.019295884296298027,
            0.015236197970807552,
            0.019403653219342232,
            0.02888236567378044,
            -0.014507368206977844,
            0.022609170526266098,
            0.01950916275382042,
            -0.02792641893029213,
            -0.008630409836769104,
            0.003952365834265947,
            -0.016450930386781693,
            -0.0006542485789395869,
            -0.007345148827880621,
            0.03491881489753723,
            0.04440585523843765,
            0.009181052446365356,
            -0.022817885503172874,
            -0.07089132070541382,
            0.0032591072376817465,
            0.021239370107650757,
            0.024230865761637688,
            0.023500999435782433,
            -0.0047819605097174644,
            0.03657609224319458,
            -0.0023662210442125797,
            0.008951389230787754,
            0.011295526288449764,
            0.011772179044783115,
            0.005617649760097265,
            -0.008824050426483154,
            -0.01189036201685667,
            -0.017955230548977852,
            -0.03609444946050644,
            0.03506503999233246,
            -0.02887381985783577,
            -0.007049900945276022,
            0.007420555222779512,
            0.003500359132885933,
            -0.0008416033233515918,
            -0.06471503525972366,
            0.021321894600987434,
            -0.0018840008415281773,
            0.01043904572725296,
            -0.001048858743160963,
            0.02489916980266571,
            -0.0037314211949706078,
            -0.027173327282071114,
            0.028411874547600746,
            0.02361029013991356,
            -0.01930840313434601,
            0.02064570225775242,
            0.020507732406258583,
            -0.010117650032043457,
            0.021525951102375984,
            0.013349475339055061,
            0.022769281640648842,
            0.017471883445978165,
            -0.006783834658563137,
            -0.053282517939805984,
            -0.03657424449920654,
            -0.020673861727118492,
            -0.046452540904283524,
            -0.016744127497076988,
            0.017950961366295815,
            -0.03241622820496559,
            -0.021787039935588837,
            -0.04017946869134903,
            0.0065794046968221664,
            -0.02316107414662838,
            0.034613337367773056,
            0.025905385613441467,
            -0.008875148370862007,
            0.01623436063528061,
            -0.03343960642814636,
            -0.023192906752228737,
            0.0007831106777302921,
            -0.03256310522556305,
            -0.009127657860517502,
            0.0414663664996624,
            0.0034695512149482965,
            -0.023234350606799126,
            -0.014910989440977573,
            -0.0026161735877394676,
            -0.06774479895830154,
            0.000552516255993396,
            0.006209738086909056,
            -0.029730770736932755,
            -0.028839286416769028,
            -0.0014764920342713594,
            -0.024455901235342026,
            -0.010693182237446308,
            0.03603721410036087,
            -0.018005505204200745,
            0.00014782752259634435,
            0.012776109389960766,
            -0.006986269727349281,
            0.007717201951891184,
            -0.015254841186106205,
            -0.017443425953388214,
            -0.019280454143881798,
            0.019352847710251808,
            -0.01367925014346838,
            0.011551105417311192,
            0.026303730905056,
            0.013148698955774307,
            -0.007624582387506962,
            0.016299597918987274,
            -0.010666470974683762,
            0.03269648551940918,
            -0.016058089211583138,
            0.007389815524220467,
            0.038582585752010345,
            -0.03594014421105385,
            0.013488280586898327,
            0.028462223708629608,
            0.014324457384645939,
            -0.08400463312864304,
            6.225103425094858e-05,
            0.016987869516015053,
            -0.008624404668807983,
            0.02950267679989338,
            -0.003709070850163698,
            0.031952761113643646,
            -0.007497142534703016,
            -0.02750438079237938,
            0.01977037638425827,
            -0.05486034229397774,
            -0.017860744148492813,
            0.024717403575778008,
            0.016554957255721092,
            0.022417131811380386,
            0.03192297741770744,
            0.0023110490292310715,
            0.03454483672976494,
            -0.011896009556949139,
            -0.027861695736646652,
            -0.03983357548713684,
            -0.016194939613342285,
            0.019551612436771393,
            -0.026970956474542618,
            -0.01728665456175804,
            0.002192704239860177,
            -0.008714933879673481,
            0.02376861684024334,
            -0.015228798612952232,
            -0.0126400887966156,
            0.024750806391239166,
            0.014637047424912453,
            -0.03930256515741348,
            0.03236568346619606,
            0.008761436678469181,
            0.02660973183810711,
            -0.000661075406242162,
            -0.0007162047550082207,
            -0.040397025644779205,
            -0.012698743492364883,
            0.003879668191075325,
            0.0427994504570961,
            0.0202006995677948,
            -0.017618926241993904,
            -0.00041370626422576606,
            -0.002925472566857934,
            -0.05968908220529556,
            -0.0720396339893341,
            -0.009579937905073166,
            -0.0023713200353085995,
            -0.025423508137464523,
            -0.03715301677584648,
            -0.03346820920705795,
            0.015354163944721222,
            -0.003314036875963211,
            -0.028408905491232872,
            -0.025804825127124786,
            0.0018003174336627126,
            -0.019912362098693848,
            0.01215305645018816,
            -0.021779118105769157,
            -0.01418529823422432,
            0.026827499270439148,
            -0.020593492314219475,
            -0.013733221217989922,
            0.02655688486993313,
            0.02305825799703598,
            -0.01415009330958128,
            -0.015204125083982944,
            0.02615066058933735,
            0.02073022909462452,
            -0.015034264884889126,
            0.02860766090452671,
            0.041906602680683136,
            -0.03947461023926735,
            0.02863461710512638,
            0.003604460507631302,
            -0.00722626643255353,
            -0.021337414160370827,
            0.0037607692647725344,
            -0.015116444788873196,
            0.0032785136718302965,
            -0.014698109589517117,
            0.03553280606865883,
            -0.013572914525866508,
            -0.004649340640753508,
            -0.0005006275023333728,
            0.020175829529762268,
            -0.07031356543302536,
            -0.012053437530994415,
            0.022490015253424644,
            -0.021473353728652,
            -0.01974535919725895,
            -0.025468651205301285,
            0.02009236440062523,
            0.005167167633771896,
            -0.009280461817979813,
            -0.04649996757507324,
            0.033231914043426514,
            -0.003673098748549819,
            -0.03402836248278618,
            -0.040675729513168335,
            0.005991811864078045,
            -0.04637886956334114,
            0.02379184029996395,
            0.007088066544383764,
            -0.04544809088110924,
            -0.03627796098589897,
            0.021231474354863167,
            -0.035240624099969864,
            0.012539354152977467,
            0.03032347932457924,
            0.003924017772078514,
            -0.018871046602725983,
            0.006939293816685677,
            0.011112552136182785,
            0.0599680095911026,
            0.027685541659593582,
            -0.0012354198843240738,
            0.023820580914616585,
            0.027828756719827652,
            -0.001856724382378161,
            -0.023696687072515488,
            -0.03175573796033859,
            -0.019813871011137962,
            0.005074850749224424,
            -0.026914503425359726,
            -0.02935187891125679,
            -0.029253093525767326,
            -0.03253956139087677,
            0.004455135203897953,
            -0.024482620880007744,
            -0.014733131043612957,
            0.022650573402643204,
            0.02506871335208416,
            -0.035757604986429214,
            0.00683278264477849,
            0.02057773619890213,
            -0.013254720717668533,
            0.02363370731472969,
            -0.012190100736916065,
            -0.018149923533201218,
            0.0036768848076462746,
            0.019604049623012543,
            -0.016503315418958664,
            0.01586095802485943,
            -0.03317983075976372,
            -0.038161750882864,
            -0.0329788438975811,
            -0.002663814229890704,
            0.018223416060209274,
            -0.053649477660655975,
            0.0362800769507885,
            -0.07947150617837906,
            -0.005204538349062204,
            -0.015860576182603836,
            -0.04388708621263504,
            0.0258778166025877,
            0.052945248782634735,
            0.020129786804318428,
            0.022939465939998627,
            -0.009733914397656918,
            0.014438492245972157,
            0.022609863430261612,
            -0.0010566050186753273,
            0.026543082669377327,
            0.048687998205423355,
            0.008126085624098778,
            0.0033801316749304533,
            0.02162514068186283,
            -0.011805512942373753,
            -0.04021386057138443,
            0.022151663899421692,
            -0.03661387786269188,
            -0.015634335577487946,
            0.014203805476427078,
            -0.01686154305934906,
            0.006644181441515684,
            -0.00962638109922409,
            0.020698316395282745,
            0.03497911989688873,
            0.00825025700032711,
            -0.01497852336615324,
            -0.007595969829708338,
            -0.023929422721266747,
            0.024212883785367012,
            0.007927807047963142,
            0.039876919239759445,
            0.013363269157707691,
            0.021096521988511086,
            -0.029164709150791168,
            0.004751271568238735,
            0.0065978397615253925,
            -0.0031171515583992004,
            -0.016021976247429848,
            -0.015861760824918747,
            -0.028495391830801964,
            -0.009718000888824463,
            -0.035336483269929886,
            0.007864173501729965,
            0.025801707059144974,
            -0.00442733708769083,
            -0.006440600845962763,
            0.016209857538342476,
            0.017106249928474426,
            0.015417972579598427,
            -0.048717983067035675,
            0.010577822104096413,
            0.024105999618768692,
            0.026428930461406708,
            0.03472376614809036,
            0.008633569814264774,
            0.029323743656277657,
            -0.03134620562195778,
            0.026451967656612396,
            -0.0002324907691217959,
            0.03746699169278145,
            0.008041830733418465,
            0.038394711911678314,
            -0.13019339740276337,
            -0.006604722235351801,
            0.010919942520558834,
            0.020372427999973297,
            -0.020975034683942795,
            -0.02546033263206482,
            -0.005964342039078474,
            -0.02750360406935215,
            0.012835628353059292,
            0.03237233683466911,
            -0.0048401555977761745,
            -0.05095027759671211,
            -0.019105736166238785,
            -0.028944242745637894,
            -0.001949924393557012,
            -0.0080948481336236,
            -0.0030832430347800255,
            -0.02891482412815094,
            -0.0045113819651305676,
            0.0036981075536459684,
            -0.002769801300019026,
            -0.01025982666760683,
            -6.819020927650854e-05,
            -0.03364766761660576,
            0.03608538582921028,
            -0.016560787335038185,
            -0.0003868185158353299,
            -0.00916285254061222,
            0.036799825727939606,
            0.021513482555747032,
            -0.044005345553159714,
            0.01189204677939415,
            0.010932163335382938,
            -0.005907981190830469,
            -0.023347441107034683,
            0.014287224039435387,
            0.007706217933446169,
            -0.025471776723861694,
            -0.027180694043636322,
            0.013115687295794487,
            -0.027349265292286873,
            0.016254842281341553,
            0.025424132123589516,
            -0.028796421363949776,
            0.0011889466550201178,
            0.03140520304441452,
            0.02755603939294815,
            -0.0709277093410492,
            0.004449905827641487,
            0.013027409091591835,
            0.03743434697389603,
            -0.013774755410850048,
            -0.010715319775044918,
            -0.01964559592306614,
            -0.008473540656268597,
            -0.01891373284161091,
            0.01914992555975914,
            -0.07603483647108078,
            -0.00784778967499733,
            -0.009661721996963024,
            0.023441646248102188,
            -0.004292615689337254,
            0.014893319457769394,
            0.03178374096751213,
            -0.002060685073956847,
            -0.026570407673716545,
            -0.014890664257109165,
            0.0017566396854817867,
            -0.015186833217740059,
            0.03680432215332985,
            0.0034284465946257114,
            -0.009701092727482319,
            -0.025460803881287575,
            -0.026181882247328758,
            -0.029473401606082916,
            -0.04640087112784386,
            -0.013784120790660381,
            -0.005324236117303371,
            -0.026269521564245224,
            -0.024646367877721786,
            0.010340405628085136,
            -0.0116617102175951,
            0.02340569533407688,
            -0.010435668751597404,
            -0.012664424255490303,
            -0.0076129622757434845,
            0.0062722801230847836,
            0.008373234421014786,
            -0.0012569882674142718,
            0.01689128763973713,
            -0.003563373116776347,
            0.0204337015748024,
            -0.0244456734508276,
            0.022086167708039284,
            -0.02727048099040985,
            0.04673466086387634,
            0.00792693067342043,
            -0.00981704518198967,
            -0.012108761817216873,
            -0.041411273181438446,
            -0.026912640780210495,
            -0.014292136766016483,
            -0.008760584518313408,
            -0.005698393099009991,
            -0.008916235528886318,
            0.01622847467660904,
            -0.029272252693772316,
            0.0165494866669178,
            0.01999959722161293,
            0.011456419713795185,
            -0.018545737490057945,
            0.0030520774889737368,
            0.028122998774051666,
            0.00134000345133245,
            0.015054608695209026,
            -0.028760302811861038,
            0.0038871169090270996,
            -0.001776963472366333,
            -0.014757261611521244,
            -0.032495759427547455,
            0.03834971413016319,
            0.005181602202355862,
            -0.023627949878573418,
            -0.01887892745435238,
            -0.03999677672982216,
            0.010660456493496895,
            0.018613960593938828,
            -0.012841353192925453,
            -0.03087153099477291,
            -0.004249413963407278,
            -0.027862057089805603,
            -0.000854098645504564,
            0.003233240218833089,
            -0.04061294347047806,
            -0.02185341715812683,
            0.011195946484804153,
            0.03770894184708595,
            -0.01279729139059782,
            0.014375322498381138,
            -0.02701653726398945,
            3.909762824605423e-07,
            0.029547689482569695,
            0.008009465411305428,
            0.012084485962986946,
            -0.01457514800131321,
            0.016949160024523735,
            -0.047741591930389404,
            0.02375633269548416,
            -0.04930254817008972,
            0.012289493344724178,
            -0.006309212185442448,
            0.0021686970721930265,
            -0.08086851239204407,
            -0.03618929162621498,
            0.006914833094924688,
            0.048295728862285614,
            0.026283610612154007,
            0.017913607880473137,
            0.01282263733446598,
            -0.00476700346916914,
            -0.007936442270874977,
            -0.018014980480074883,
            0.035506390035152435,
            -0.010215024463832378,
            -0.02445955015718937,
            -0.024882853031158447,
            0.009137487970292568,
            -0.025290869176387787,
            0.024707363918423653,
            -0.03484774008393288,
            0.02370547316968441,
            -0.021457485854625702,
            -0.01250085886567831,
            0.012537677772343159,
            0.01480887085199356,
            0.008774066343903542,
            -0.004311134107410908,
            -0.012834209948778152,
            0.01640934683382511,
            -0.005233944859355688,
            0.025558626279234886,
            -0.0276812631636858,
            0.015547593124210835,
            -0.004401469137519598,
            0.00772832939401269,
            -0.0036772224120795727,
            -0.023497754707932472,
            -0.01191528420895338,
            -0.012533588334918022,
            0.008656051009893417,
            0.006900024134665728,
            -0.005417359061539173,
            0.04628854990005493,
            -0.018403753638267517,
            0.039383966475725174,
            0.00829940102994442,
            0.009980184957385063,
            -0.009803642518818378,
            0.04885876551270485,
            -0.029909199103713036,
            -0.017847999930381775,
            0.000993340159766376,
            0.05516158044338226,
            -0.04163939878344536,
            -0.03676179051399231,
            -0.016244228929281235,
            -0.006237722001969814,
            0.002995229559019208,
            -0.017593445256352425,
            -0.011923722922801971,
            0.021633269265294075,
            -0.012672924436628819,
            0.0066888523288071156,
            -0.01302025280892849,
            -0.022795485332608223,
            -0.0464789979159832,
            0.02843785472214222,
            0.020643461495637894,
            -0.06412414461374283,
            -0.014893886633217335,
            0.03592592477798462,
            -0.015597159042954445,
            0.009018152952194214,
            0.03197779506444931,
            -0.03762165457010269,
            -0.008740288205444813,
            0.01669757440686226,
            -0.012107590213418007,
            0.019381500780582428,
            0.004711344372481108,
            0.024255715310573578,
            0.005831953603774309,
            0.001660166890360415,
            0.02830507606267929,
            -0.004775513894855976,
            0.02383793517947197,
            -0.0051430328749120235,
            -0.029382366687059402,
            0.0009649631101638079,
            -0.016671596094965935,
            0.02184854820370674,
            -0.02422097511589527,
            0.0031859376467764378,
            -0.02166801504790783,
            0.0250781811773777,
            -0.029830116778612137,
            -0.0037639723159372807,
            0.009612474590539932,
            0.009274642914533615,
            -0.014881310984492302,
            0.013400579802691936,
            0.030573032796382904,
            -0.02975594252347946,
            -0.0009460488799959421,
            0.0028031556867063046,
            0.0025881417095661163,
            0.004774847999215126,
            0.032820675522089005,
            -0.02783149853348732,
            0.0455157645046711,
            0.011020253412425518,
            0.024498160928487778,
            -0.027399541810154915,
            0.022335432469844818,
            -0.01750296913087368,
            0.03925499692559242,
            0.02431318163871765,
            0.0016163343098014593,
            0.04180620238184929,
            -0.004018360748887062,
            -0.05992024764418602,
            0.022041890770196915,
            0.005601136479526758,
            0.034784700721502304,
            -0.02458394318819046,
            0.02722390741109848,
            0.01873302087187767,
            -0.07291337847709656,
            -0.03085342049598694,
            -0.03214912861585617,
            -0.034388117492198944,
            -0.021044224500656128,
            0.022138038650155067,
            0.020374678075313568,
            -0.011495424434542656,
            -0.0008938141399994493,
            -0.00986469816416502,
            -0.02100970223546028,
            0.01966802030801773,
            0.04027201607823372,
            -0.016523726284503937,
            -0.009019667282700539,
            -0.03287995979189873,
            -0.015970498323440552,
            -0.01362906489521265,
            0.009007221087813377,
            -0.00793182197958231,
            0.01813690923154354,
            0.009416177868843079,
            -0.03532955050468445,
            -0.008578693494200706,
            -0.04253598675131798,
            0.040577374398708344,
            -0.007658466696739197,
            -0.0036290884017944336,
            -0.016710497438907623,
            -0.00991126149892807,
            0.014997030608355999,
            0.03266681730747223,
            -0.010775701142847538,
            0.014816394075751305,
            -0.0311444029211998,
            -0.018852602690458298,
            0.026017116382718086,
            -0.0028255514334887266,
            -0.04163702204823494,
            -0.061363983899354935,
            -0.023244978860020638,
            0.0035407328978180885,
            -0.011325687170028687,
            0.025501376017928123,
            0.02540535479784012,
            -0.05517847463488579,
            0.03186774253845215,
            -0.020898597314953804,
            -0.0026529845781624317,
            -0.004315129481256008,
            0.013655222952365875,
            -0.018244890496134758,
            0.02503853477537632,
            -0.017310529947280884,
            0.0077780247665941715,
            0.015095391310751438,
            0.009096842259168625,
            0.0030973919201642275,
            -0.017449792474508286,
            -0.030290750786662102,
            0.025321369990706444,
            -0.010625883936882019,
            0.00041300823795609176,
            -0.027211058884859085,
            -0.02702965959906578,
            -0.015212474390864372,
            0.008369259536266327,
            0.015421164222061634,
            -0.015788063406944275,
            0.037715669721364975,
            -0.015404977835714817,
            0.033439043909311295,
            0.0016217550728470087,
            -0.010950859636068344,
            0.01957613229751587,
            -0.01233068760484457,
            -0.02645682357251644,
            -0.017611589282751083,
            -0.056929804384708405,
            0.005676731467247009,
            0.01009227056056261,
            0.013947302475571632,
            -0.033487360924482346,
            -0.015534589998424053,
            -0.01997017301619053,
            -0.012208141386508942,
            0.01693802699446678,
            0.0025362500455230474,
            -0.02267518639564514,
            0.024689702317118645,
            0.018772123381495476,
            -0.003074327716603875,
            0.03251960128545761,
            0.022835910320281982,
            0.009121548384428024,
            -0.01235155202448368,
            0.005214249715209007,
            -0.01568172685801983,
            0.003537566401064396,
            -0.05512160435318947,
            0.021947620436549187,
            0.024553176015615463,
            -0.00045342344674281776,
            0.0041978806257247925,
            0.0066787730902433395,
            -0.031609907746315,
            -0.020770074799656868,
            -0.021458907052874565,
            0.033627286553382874,
            -0.02965184859931469,
            0.015192113816738129,
            -0.043258413672447205,
            -0.058058708906173706,
            -0.02291867695748806,
            -0.028539270162582397,
            0.008816741406917572,
            0.036952316761016846,
            0.0167013481259346,
            0.019701054319739342,
            0.01676284521818161,
            0.027442418038845062,
            -0.0038563888520002365,
            0.0306225698441267,
            0.019190462306141853,
            0.0021978344302624464,
            0.039853960275650024,
            -0.018828699365258217,
            -0.002991607179865241,
            -0.04879368841648102,
            -0.047761376947164536,
            0.006415383890271187,
            -0.005042290780693293,
            -0.01531511265784502,
            0.00046362372813746333,
            -0.01389418262988329,
            0.015249418094754219,
            0.03634915500879288,
            0.026285022497177124,
            0.024206874892115593,
            0.028345175087451935,
            0.01029767096042633,
            -0.024884119629859924,
            0.03706469014286995,
            0.02820686437189579,
            -0.0024635703302919865,
            -0.014764422550797462,
            0.01648184098303318,
            -0.024579687044024467,
            0.005711499601602554,
            0.011968055739998817,
            0.029072055593132973,
            0.0013509250711649656,
            -0.01871681772172451,
            0.014981506392359734,
            0.006026130635291338,
            0.034521277993917465,
            -0.04012596607208252,
            -0.015497993677854538,
            -0.03637100011110306,
            0.06902292370796204,
            0.045720599591732025,
            0.02287304773926735,
            -0.018294358626008034,
            -0.026901595294475555,
            0.005409649107605219,
            -0.022048447281122208,
            0.0014505840372294188,
            0.005397701170295477,
            -0.0007643395219929516,
            0.028772857040166855,
            0.014746325090527534,
            -0.037920016795396805,
            -0.01417822577059269,
            -0.031052054837346077,
            -0.019269399344921112,
            0.02267467975616455,
            -0.0018899592105299234,
            0.06168851628899574,
            0.021803421899676323,
            0.016586758196353912,
            -0.013336148113012314,
            -0.027504995465278625,
            -0.01727708801627159,
            0.009121986106038094,
            0.012345935218036175,
            0.01471544336527586,
            -0.028932098299264908,
            -0.024755509570240974,
            0.0327945351600647,
            -0.046020086854696274,
            0.01999133825302124,
            -0.0069923135451972485,
            -0.025181490927934647,
            -0.014756708405911922,
            0.035151004791259766,
            0.009069817140698433,
            -0.05287934094667435,
            0.015775583684444427,
            0.03870809078216553,
            -0.030650600790977478,
            0.016746660694479942,
            0.04235051944851875,
            0.010066049173474312,
            -0.01682373881340027,
            0.028431273996829987,
            -0.025335269048810005,
            -0.04018934816122055,
            -0.0033153074327856302,
            -0.03032565675675869,
            0.007718292530626059,
            0.01584492437541485,
            -0.0011679240269586444,
            -0.02286926470696926,
            -0.012483442202210426,
            0.024941133335232735,
            -0.09997591376304626,
            0.02257668413221836,
            0.06776532530784607,
            0.011332529596984386,
            -0.01805953122675419,
            -0.025227468460798264,
            0.035627808421850204,
            -0.009843125008046627,
            0.0075582703575491905,
            -0.0054314215667545795,
            -0.01765330508351326,
            -0.023965002968907356,
            -0.015189718455076218,
            -0.014579051174223423,
            -0.013230613432824612,
            -0.02861560322344303,
            0.009807446040213108,
            0.025159720331430435,
            -0.010828289203345776,
            0.010630474425852299,
            -0.01133100688457489,
            -0.011231153272092342,
            0.015580972656607628,
            -0.018990768119692802,
            0.025431619957089424,
            -0.04192746803164482,
            -0.026201965287327766,
            0.004822197370231152,
            0.01724342815577984,
            -0.02533763460814953,
            0.021134575828909874,
            0.025922028347849846,
            -0.01378289982676506,
            0.027744349092245102,
            -0.01618191972374916,
            -0.013258085586130619,
            0.020129401236772537,
            0.012867330573499203,
            -0.0020412022713571787,
            -0.018175940960645676,
            0.020385494455695152,
            -0.059478744864463806,
            -0.0465942807495594,
            -0.010170589201152325
        ],
        [
            -0.03771637752652168,
            -0.015789175406098366,
            -0.0034016603603959084,
            0.010844742879271507,
            0.00960511714220047,
            -0.013851931318640709,
            -0.010930420830845833,
            0.007034784182906151,
            0.0048399921506643295,
            -0.012140452861785889,
            -0.02046186849474907,
            -0.019571907818317413,
            -0.03695293143391609,
            0.0371098518371582,
            -0.023889871314167976,
            -0.0378682017326355,
            -0.005475143436342478,
            0.005646323785185814,
            -0.014990017749369144,
            -0.025220060721039772,
            -0.01577186770737171,
            -0.021220160648226738,
            -0.004012800753116608,
            0.01990543119609356,
            -0.02469191700220108,
            -6.214508175617084e-05,
            0.01221489254385233,
            -0.008557906374335289,
            -0.004133620765060186,
            -0.027360856533050537,
            0.0033738473430275917,
            0.02340923622250557,
            -0.007420266047120094,
            -0.00991148129105568,
            0.03316833823919296,
            0.0044662971049547195,
            8.747669926378876e-05,
            0.033371202647686005,
            0.014049789868295193,
            -0.013679273426532745,
            -0.019369376823306084,
            -0.023771967738866806,
            0.005148585420101881,
            0.029671207070350647,
            0.015253451652824879,
            -0.0030804516281932592,
            -0.005767630413174629,
            -0.008731214329600334,
            -0.025022968649864197,
            -0.00017946546722669154,
            -0.00451208371669054,
            0.002317110076546669,
            -0.05160236358642578,
            0.015256400220096111,
            -0.003308366984128952,
            0.01567067764699459,
            0.023022715002298355,
            -0.026990074664354324,
            -0.00993151031434536,
            0.03611185774207115,
            0.031084328889846802,
            -0.01052180491387844,
            -0.026915276423096657,
            -0.010176226496696472,
            -0.016526388004422188,
            0.03129425644874573,
            -0.03096010722219944,
            0.009846776723861694,
            -0.019839728251099586,
            -0.0026921392418444157,
            0.015935050323605537,
            0.01314602792263031,
            -0.010580332949757576,
            -0.008657172322273254,
            -0.002309893723577261,
            -0.05084933340549469,
            0.015179217793047428,
            -0.012088989838957787,
            -0.003316310001537204,
            0.013121847994625568,
            -0.024230320006608963,
            -0.011746125295758247,
            -0.0228410791605711,
            0.028910590335726738,
            0.03342019394040108,
            0.03730527684092522,
            -0.008688454516232014,
            -0.02983001433312893,
            0.001309377490542829,
            -0.024955330416560173,
            -0.009599418379366398,
            0.019462762400507927,
            0.004880477208644152,
            -0.008724621497094631,
            0.010176705196499825,
            -0.010233666747808456,
            -0.014985262416303158,
            -0.021854493767023087,
            -0.05126899480819702,
            0.007125772535800934,
            0.01407955028116703,
            -0.0019068215042352676,
            -0.027281934395432472,
            -0.015015576966106892,
            -0.011530843563377857,
            0.0008229659288190305,
            -0.012303865514695644,
            0.007426063530147076,
            0.011650842614471912,
            0.03328821435570717,
            0.003113526152446866,
            0.007845975458621979,
            -0.009313550777733326,
            0.024252556264400482,
            -0.03393833339214325,
            -0.01171748898923397,
            -0.02869226224720478,
            -0.001249293563887477,
            0.0009366731392219663,
            0.010581603273749352,
            -0.025151189416646957,
            -0.005985933821648359,
            0.044127508997917175,
            -0.015686610713601112,
            0.02545064315199852,
            -0.004731977358460426,
            0.029007064178586006,
            -0.02672051265835762,
            0.00044729761430062354,
            0.01748865656554699,
            -0.012491054832935333,
            0.010249858722090721,
            0.022981710731983185,
            0.026396675035357475,
            -0.01783931627869606,
            -0.0068612536415457726,
            -0.018833208829164505,
            0.012669950723648071,
            -0.036244627088308334,
            0.0387091226875782,
            0.028947077691555023,
            -0.025058265775442123,
            0.009242205880582333,
            -0.0013447134988382459,
            0.02307855896651745,
            0.003100133500993252,
            0.024250367656350136,
            -0.012751778587698936,
            0.029508763924241066,
            -0.033307261765003204,
            0.026560118421912193,
            -0.022312795743346214,
            0.006142272613942623,
            0.044177405536174774,
            -0.001348712481558323,
            -0.0032506606075912714,
            0.036153942346572876,
            0.0270075723528862,
            0.013170876540243626,
            -0.0035560003016144037,
            -0.010404963046312332,
            -0.001264528022147715,
            -0.018878454342484474,
            -0.024653540924191475,
            0.002192126587033272,
            0.007797143422067165,
            -0.021196287125349045,
            0.0028397932182997465,
            -0.015249437652528286,
            0.0011368617415428162,
            0.004989102948457003,
            -0.027738943696022034,
            -0.019733158871531487,
            -0.02422490157186985,
            0.025416987016797066,
            0.020634295418858528,
            -0.048164110630750656,
            0.027019983157515526,
            0.009300208650529385,
            0.040934134274721146,
            -0.004365147557109594,
            -0.007783209439367056,
            -0.04515354707837105,
            -0.0075314161367714405,
            0.03309210017323494,
            0.01747404970228672,
            0.00458424910902977,
            -0.030397707596421242,
            0.002654740819707513,
            0.02650320529937744,
            -0.01767457090318203,
            0.007220660801976919,
            -0.018262939527630806,
            -0.020531712099909782,
            0.02026546187698841,
            -0.015034065581858158,
            -0.01633618399500847,
            -0.008144939318299294,
            0.00807354785501957,
            0.0075938212685287,
            0.024024466052651405,
            0.010466830804944038,
            0.0019654107745736837,
            -0.02029409073293209,
            -0.013612284325063229,
            -0.004402862396091223,
            0.0011284054489806294,
            -0.0009322351543232799,
            -0.006873598322272301,
            0.023334013298153877,
            0.008696364238858223,
            0.015080119483172894,
            0.01306366641074419,
            0.01600789651274681,
            -0.003107202472165227,
            -0.010761507786810398,
            -0.02065424807369709,
            -0.007635290734469891,
            0.023247085511684418,
            -0.00309380772523582,
            -0.010072668083012104,
            0.00872479472309351,
            -0.0006379650440067053,
            -0.015288502909243107,
            0.011296344920992851,
            0.02240140177309513,
            -0.0024890333879739046,
            -0.009860657155513763,
            0.007530984003096819,
            -0.019437672570347786,
            -0.008891317062079906,
            0.009098500944674015,
            -0.023328622803092003,
            -0.012955641373991966,
            -0.032583087682724,
            -0.002773621119558811,
            0.02716900035738945,
            0.006461998913437128,
            0.014591489918529987,
            0.01627240516245365,
            0.000952483678702265,
            -0.004236786626279354,
            -0.0025167367421090603,
            -0.02641279064118862,
            -0.0011823794338852167,
            -0.013621063902974129,
            0.01399045716971159,
            0.016064615920186043,
            -0.001689858385361731,
            0.022244594991207123,
            -0.028406627476215363,
            0.0007478352636098862,
            0.0008541963179595768,
            0.02759862318634987,
            0.0355287604033947,
            0.013037564232945442,
            0.02632523886859417,
            -0.01628855988383293,
            0.017885033041238785,
            0.004596225451678038,
            0.004047207068651915,
            -0.012610819190740585,
            0.0071378848515450954,
            0.016409514471888542,
            0.016551820561289787,
            -0.017418555915355682,
            0.027609800919890404,
            -0.008915048092603683,
            0.033384162932634354,
            -0.014334832318127155,
            0.010737868025898933,
            0.0037554979790002108,
            -0.0036427101586014032,
            0.022032495588064194,
            -0.021590590476989746,
            0.013162678107619286,
            0.02909538894891739,
            -0.018889814615249634,
            0.01307512354105711,
            -0.01593255065381527,
            -0.021071840077638626,
            -0.016553828492760658,
            0.001967234304174781,
            -0.01073094829916954,
            -0.009382097981870174,
            -0.01307479664683342,
            0.0017985369777306914,
            0.020331213250756264,
            -0.016588354483246803,
            0.015994887799024582,
            0.023661715909838676,
            -0.007096751593053341,
            0.028498908504843712,
            -0.024468934163451195,
            -0.005394023843109608,
            -0.019053945317864418,
            0.03856271877884865,
            0.023324424400925636,
            -0.027697162702679634,
            0.020147930830717087,
            -0.010073749348521233,
            -0.024600990116596222,
            0.013753603212535381,
            0.02849745936691761,
            0.004046094138175249,
            -0.014556113630533218,
            0.007140329107642174,
            -0.007788126822561026,
            -0.04068450257182121,
            0.003081618109717965,
            -0.029668526723980904,
            0.007109161000698805,
            -0.017369195818901062,
            0.008493457920849323,
            -0.02590741030871868,
            -0.025227736681699753,
            0.004715775605291128,
            -0.012690367177128792,
            0.006232523359358311,
            0.013601102866232395,
            0.020602308213710785,
            -0.05199255421757698,
            -0.011098391376435757,
            -0.01024188194423914,
            0.03461100906133652,
            -0.00836928840726614,
            -0.004130049142986536,
            0.010665438137948513,
            -0.031224466860294342,
            0.016892731189727783,
            0.01922502927482128,
            -0.020717516541481018,
            0.03044014982879162,
            0.03540721535682678,
            -0.020429635420441628,
            0.01761295273900032,
            -0.005318282172083855,
            -0.029821157455444336,
            0.030249956995248795,
            0.003998179920017719,
            -0.0028064700309187174,
            -0.03745296224951744,
            -0.02991451323032379,
            0.029193490743637085,
            0.01559415552765131,
            0.019328109920024872,
            0.01731191761791706,
            0.02537335455417633,
            -0.004697068128734827,
            0.005994000006467104,
            -0.030485350638628006,
            0.013403646647930145,
            -0.03461926057934761,
            -0.017491895705461502,
            0.018465222790837288,
            -0.023681599646806717,
            -0.0034045781940221786,
            -0.016791528090834618,
            -0.025428209453821182,
            0.019084375351667404,
            -0.007978820241987705,
            0.01689174212515354,
            -0.007159804459661245,
            -0.027451196685433388,
            0.020076198503375053,
            -0.02089577168226242,
            0.02255193702876568,
            -0.0015156982699409127,
            -0.01650620996952057,
            0.01911287009716034,
            0.027446161955595016,
            0.029405053704977036,
            0.009593282826244831,
            -0.012411529198288918,
            0.010168907232582569,
            -0.01666782796382904,
            0.01547228917479515,
            -0.011333274655044079,
            0.02885681949555874,
            -0.01624421775341034,
            0.051643576472997665,
            0.019311094656586647,
            -0.011196673847734928,
            0.017894627526402473,
            0.014734591357409954,
            -0.0451141856610775,
            -0.014854015782475471,
            0.005920157767832279,
            0.0053255255334079266,
            0.0317889004945755,
            0.030601801350712776,
            -0.03675586357712746,
            -0.011439073830842972,
            0.023173170164227486,
            0.03501816466450691,
            0.019182512536644936,
            0.008204270154237747,
            -0.029367083683609962,
            0.010231134481728077,
            0.00237415567971766,
            -0.02005496621131897,
            -0.022365711629390717,
            0.017952121794223785,
            0.0021480093710124493,
            0.012775334529578686,
            -0.01215245109051466,
            -0.004457440227270126,
            -0.005430458579212427,
            -0.0002840004744939506,
            -0.0023540460970252752,
            0.0016164692351594567,
            0.01631958782672882,
            0.032628126442432404,
            -0.02285715751349926,
            0.031226959079504013,
            0.032015763223171234,
            -0.017512552440166473,
            -0.03527477756142616,
            0.03762274235486984,
            0.034790851175785065,
            0.015485376119613647,
            -0.02062198705971241,
            0.033106785267591476,
            0.02443815767765045,
            0.019198473542928696,
            0.009731215424835682,
            0.0014420982915908098,
            0.004142419900745153,
            0.004876315128058195,
            -0.040099892765283585,
            -0.0371139757335186,
            -0.025383848696947098,
            0.004140128381550312,
            -0.0060563720762729645,
            -0.019674789160490036,
            -0.01740293577313423,
            -0.0220627598464489,
            -0.0005748859839513898,
            0.0027182099875062704,
            -0.022835927084088326,
            0.028360938653349876,
            -0.0034334054216742516,
            0.019567247480154037,
            0.004868000745773315,
            0.020047567784786224,
            -0.025692299008369446,
            0.010023266077041626,
            -0.05614949017763138,
            0.048617105931043625,
            0.019957464188337326,
            0.007887904532253742,
            -0.02289985865354538,
            -0.0032272415701299906,
            0.028349600732326508,
            -0.027535010129213333,
            -0.01138169877231121,
            0.014819432981312275,
            -0.0045367758721113205,
            0.02239304408431053,
            0.0027483850717544556,
            -0.020396744832396507,
            -0.019894137978553772,
            0.021699996665120125,
            -0.0014031059108674526,
            0.025685718283057213,
            0.022692646831274033,
            0.015531138516962528,
            -0.012507949955761433,
            0.004612315911799669,
            -0.012705308385193348,
            -0.007958033122122288,
            0.021855419501662254,
            0.00018540595192462206,
            0.015143143944442272,
            -0.01323811523616314,
            -0.01254844106733799,
            0.01394902914762497,
            0.01489785872399807,
            -0.022024597972631454,
            0.03306782990694046,
            -0.002237959997728467,
            0.032124388962984085,
            0.010445798747241497,
            -0.0150774409994483,
            -0.019054826349020004,
            0.01219159085303545,
            0.022902091965079308,
            -0.02061421424150467,
            -0.05140524357557297,
            -0.019513772800564766,
            -0.034559376537799835,
            0.01662443019449711,
            0.010513980872929096,
            -0.024044495075941086,
            -0.026455838233232498,
            0.02861359342932701,
            -0.025073044002056122,
            -0.0012459701392799616,
            -0.02188766561448574,
            0.03231477364897728,
            -0.00730634992942214,
            -0.016503551974892616,
            0.003399957437068224,
            -0.03849949315190315,
            -0.017980948090553284,
            0.01435662992298603,
            0.014870181679725647,
            0.001206376007758081,
            0.012268793769180775,
            -0.0384342260658741,
            -0.007426686119288206,
            0.00021960929734632373,
            -0.029632676392793655,
            -0.026176955550909042,
            0.023511923849582672,
            0.009653155691921711,
            -0.026246052235364914,
            0.03137091547250748,
            0.022094309329986572,
            -0.027210045605897903,
            0.013676240108907223,
            0.003508699592202902,
            0.012833765707910061,
            -0.020161930471658707,
            -0.02213670127093792,
            0.012375433929264545,
            -0.006815379485487938,
            -0.01238049566745758,
            0.008616061881184578,
            0.015313406474888325,
            -0.0059351613745093346,
            0.01915563829243183,
            -0.0012256067711859941,
            -0.023840714246034622,
            0.008237795904278755,
            -0.06815453618764877,
            0.005327400751411915,
            5.910591426072642e-05,
            0.02265036851167679,
            0.027380747720599174,
            0.013502722606062889,
            0.016312919557094574,
            -0.041446104645729065,
            -0.020228520035743713,
            0.026752276346087456,
            0.03403035178780556,
            0.0321001298725605,
            -0.04506653547286987,
            0.022610219195485115,
            -0.023151913657784462,
            -0.003940942231565714,
            -0.012575474567711353,
            -0.027736710384488106,
            -0.01701963320374489,
            -0.01842299848794937,
            -0.017333773896098137,
            -0.03657131642103195,
            -0.022863958030939102,
            0.0016172906616702676,
            -0.019050685688853264,
            -0.030534259974956512,
            0.020701613277196884,
            0.01886310987174511,
            -0.020622175186872482,
            0.012625268660485744,
            -0.011221356689929962,
            -0.01860654726624489,
            -0.020154615864157677,
            -0.0037363881710916758,
            0.03283347189426422,
            0.016334310173988342,
            0.01343830581754446,
            0.028059735894203186,
            -0.007996306754648685,
            -0.018779629841446877,
            -0.03963729366660118,
            -0.03036436252295971,
            0.001883361954241991,
            0.0171904768794775,
            0.022725142538547516,
            0.003280030330643058,
            0.007131968624889851,
            0.026239214465022087,
            -0.013616996817290783,
            0.03177578002214432,
            -0.015510612167418003,
            0.023758452385663986,
            0.023135773837566376,
            0.02126476727426052,
            -0.011854719370603561,
            0.014124594628810883,
            -0.008278717286884785,
            -0.005086714867502451,
            0.009818729013204575,
            -0.01192336156964302,
            0.014187074266374111,
            0.0015286230482161045,
            -0.02308758907020092,
            -0.02693827636539936,
            -0.008210909552872181,
            -0.006370887625962496,
            -0.01799052581191063,
            -0.009025026112794876,
            -0.018378745764493942,
            0.005689335521310568,
            -0.04631907120347023,
            -0.03319534286856651,
            -0.02946219965815544,
            -0.013459786772727966,
            0.009131361730396748,
            -0.03338878974318504,
            -0.0009788918541744351,
            -0.012852395884692669,
            -0.010020684450864792,
            -0.005573005881160498,
            0.017481857910752296,
            -0.023415762931108475,
            0.003552278969436884,
            0.028020765632390976,
            0.016870515421032906,
            0.007147465832531452,
            0.02676617167890072,
            -0.010298959910869598,
            -0.015438145957887173,
            0.002227590885013342,
            -0.021559730172157288,
            -0.03229055181145668,
            -0.020714981481432915,
            -0.021367451176047325,
            -0.02622477151453495,
            0.016321711242198944,
            0.018353553488850594,
            -0.024178462103009224,
            -0.00557996379211545,
            -0.02680596336722374,
            0.01466574426740408,
            0.013185937888920307,
            0.017710700631141663,
            0.018600212410092354,
            0.026101406663656235,
            0.02345004491508007,
            0.01509737316519022,
            -0.013770458288490772,
            -0.047164883464574814,
            0.024671737104654312,
            0.009702769108116627,
            -0.021222826093435287,
            -0.01745094545185566,
            0.013990241102874279,
            -0.009799463674426079,
            0.019346127286553383,
            -0.0032135341316461563,
            0.029766745865345,
            0.03271520510315895,
            0.004269033204764128,
            0.008478744886815548,
            0.014511963352560997,
            -0.00680935475975275,
            0.015417508780956268,
            -0.026274021714925766,
            -0.040432319045066833,
            -0.03344117850065231,
            -0.004784760065376759,
            -0.008210630156099796,
            0.006672225892543793,
            -0.01258901972323656,
            0.027675138786435127,
            0.029099171981215477,
            0.020817212760448456,
            -0.027289142832159996,
            -0.0486794039607048,
            0.01865963079035282,
            0.026610150933265686,
            -0.029517751187086105,
            0.03582540526986122,
            -0.034660860896110535,
            0.0068111722357571125,
            0.012895692139863968,
            -0.0006876680417917669,
            -0.004428468644618988,
            0.004115033429116011,
            0.027601223438978195,
            0.0010193176567554474,
            -0.04263714328408241,
            0.0016633485211059451,
            0.0019241124391555786,
            -0.023994263261556625,
            0.030530158430337906,
            -0.033678680658340454,
            -0.0050803739577531815,
            -0.026633519679307938,
            -0.01421435084193945,
            -0.02618354931473732,
            -0.02020992524921894,
            -0.003446809947490692,
            0.02668447606265545,
            0.035465918481349945,
            -0.01567446067929268,
            0.02564796805381775,
            0.0006167395040392876,
            0.019874336197972298,
            -0.00840480625629425,
            -0.010499972850084305,
            -0.012086612172424793,
            -0.0028114542365074158,
            0.004408372100442648,
            0.015116804279386997,
            -0.03228268399834633,
            -0.021091170608997345,
            -0.006230934523046017,
            -0.025864532217383385,
            0.005086873192340136,
            0.0332588367164135,
            0.004908002447336912,
            0.02710694633424282,
            0.020831260830163956,
            -0.06358508765697479,
            -0.010562166571617126,
            0.00431022560223937,
            -0.01772245019674301,
            -0.004452000372111797,
            0.019876442849636078,
            -0.022735003381967545,
            -0.009706243872642517,
            0.016920672729611397,
            -0.02610117569565773,
            0.014262286946177483,
            0.0023153512738645077,
            0.0012348329182714224,
            0.019517023116350174,
            -0.013067815452814102,
            -0.02216201275587082,
            0.02054465375840664,
            0.03481629118323326,
            -0.019165705889463425,
            -0.038572877645492554,
            -0.015384153462946415,
            -0.011530079878866673,
            -0.02133510820567608,
            0.042984362691640854,
            -0.01958363503217697,
            0.004067267756909132,
            -0.027641283348202705,
            0.038620758801698685,
            -0.024573510512709618,
            0.012288099154829979,
            0.05437721312046051,
            0.000576268881559372,
            -0.006712367758154869,
            -0.025267934426665306,
            0.008393294177949429,
            -0.01811334677040577,
            -0.019016575068235397,
            0.00415646331384778,
            -0.011463929899036884,
            0.01279287226498127,
            0.04191958159208298,
            0.005010046996176243,
            0.00038998565287329257,
            -0.00728477630764246,
            0.00960605964064598,
            -0.020874828100204468,
            -0.025498060509562492,
            -0.02835959941148758,
            0.03241905942559242,
            -0.00920775905251503,
            -0.02980724722146988,
            -0.0053751724772155285,
            -0.013634000904858112,
            0.04217502847313881,
            0.009002680890262127,
            0.0007889452390372753,
            -0.02175065688788891,
            0.022179417312145233,
            0.017940999940037727,
            -0.0005128455231897533,
            0.00438828906044364,
            0.0036662633065134287,
            -0.0129118412733078,
            0.03313310444355011,
            -0.029444070532917976,
            -0.007558035664260387,
            0.02982962876558304,
            -0.022308077663183212,
            -0.009655209258198738,
            -0.008397514931857586,
            0.00017379678320139647,
            -0.01996172033250332,
            -0.021651607006788254,
            0.023746466264128685,
            -0.0016928103286772966,
            -0.009418638423085213,
            0.026471341028809547,
            -0.04191173240542412,
            -0.050437089055776596,
            0.00495725404471159,
            0.007640473078936338,
            -0.0017093487549573183,
            0.025495540350675583,
            0.007717563305050135,
            -0.017922155559062958,
            0.034013744443655014,
            -0.02599940448999405,
            -0.00737171433866024,
            -0.0357644259929657,
            0.004002364352345467,
            -0.009317876771092415,
            -0.021957751363515854,
            0.004057890735566616,
            0.025816379114985466,
            -0.018345236778259277,
            -0.025339214131236076,
            -0.019232353195548058,
            0.008893952704966068,
            -0.008673924021422863,
            0.030277138575911522,
            0.03413357585668564,
            -0.020929664373397827,
            0.021814173087477684,
            -0.036499667912721634,
            -0.007776836398988962,
            0.04158109053969383,
            0.011202230118215084,
            -0.023469731211662292,
            -0.062088191509246826,
            -0.01747940666973591,
            0.015932587906718254,
            -0.014982986263930798,
            0.03125501796603203,
            -0.007188893388956785,
            -0.031243549659848213,
            -0.019342022016644478,
            0.010661687701940536,
            0.012009803205728531,
            0.028339818120002747,
            0.017425214871764183,
            -0.02918766438961029,
            0.026444697752594948,
            0.009505106136202812,
            -0.019362078979611397,
            -0.013483983464539051,
            -0.010676939971745014,
            -0.030185526236891747,
            0.010475149378180504,
            0.028639448806643486,
            -0.007628665305674076,
            -0.029681464657187462,
            0.037207819521427155,
            -0.0354166179895401,
            0.00012365101429168135,
            -0.012779860757291317,
            -0.01884237490594387,
            0.0008368922863155603,
            -0.02386145107448101,
            0.011631152592599392,
            -0.007655291818082333,
            0.01833517663180828,
            0.015503819100558758,
            -0.007387919817119837,
            0.001672428916208446,
            -0.027177194133400917,
            -0.01449771597981453,
            0.026870770379900932,
            0.008462222293019295,
            -0.027592286467552185,
            0.04077060520648956,
            0.014723110012710094,
            0.026369526982307434,
            -0.025990452617406845,
            -0.011532127857208252,
            -0.0293169803917408,
            0.0020672495011240244,
            -0.001099846907891333,
            0.00578119233250618,
            -1.4258498595154379e-05,
            -0.007791851181536913,
            0.0011037024669349194,
            -0.03852202370762825,
            0.013235431164503098,
            -0.020637081936001778,
            0.03278683125972748,
            0.03250013291835785,
            -0.011205539107322693,
            0.026013219729065895,
            -0.008298899978399277,
            0.0007044879603199661,
            -0.010133029893040657,
            -0.06065276637673378,
            0.011662180535495281,
            -0.00518396869301796,
            -0.018928758800029755,
            0.0025925098452717066,
            0.011148793622851372,
            0.0199116263538599,
            0.004983986262232065,
            0.017083434388041496,
            -0.03031044639647007,
            0.007584571372717619,
            0.02828328125178814,
            -0.031098533421754837,
            0.02355690859258175,
            0.02516813576221466,
            0.030651738867163658,
            0.02191663347184658,
            0.02980981022119522,
            -0.02638866938650608,
            -0.03665947914123535,
            -0.009106333367526531,
            0.03624601662158966,
            0.010156805627048016,
            0.026050109416246414,
            0.025420330464839935,
            0.0029952048789709806,
            0.021388456225395203,
            -0.0011715430300682783,
            0.008586583659052849,
            0.023983698338270187,
            0.03183553367853165,
            0.03605751320719719,
            0.024283425882458687,
            -0.0075198085978627205,
            0.012913270853459835,
            -0.022492723539471626,
            0.026866335421800613,
            0.013923689723014832,
            -0.003743065521121025,
            0.019117213785648346,
            0.024031344801187515,
            -0.022996608167886734,
            -0.012831493280827999,
            -0.00940075796097517,
            0.0279337540268898,
            -0.012081149965524673,
            -0.039435017853975296,
            0.005313399247825146,
            -0.03318551927804947,
            0.016329605132341385,
            0.024193234741687775,
            0.02237706072628498,
            -0.018786383792757988,
            -0.026255419477820396,
            0.00605593528598547,
            -0.0004466336395125836,
            0.012404663488268852,
            -0.006466094870120287,
            0.01971312239766121,
            -0.014417454600334167,
            0.006935343146324158,
            0.030246740207076073,
            -0.012126185931265354,
            0.027382027357816696,
            0.02395176887512207,
            0.013575991615653038,
            0.0071475147269666195,
            -0.019252708181738853,
            0.010499171912670135,
            0.022480212152004242,
            -0.010724231600761414,
            0.02926834672689438,
            -0.03169051557779312,
            -0.04778154939413071,
            -0.014835061505436897,
            0.005344683304429054,
            -0.008676651865243912,
            -0.01941194385290146,
            -0.002978395903483033,
            -0.027940254658460617,
            0.02999984845519066,
            -0.054638560861349106,
            0.028807206079363823,
            -0.023965399712324142,
            -0.018574601039290428,
            0.021211067214608192,
            -0.020450787618756294,
            -0.017596473917365074,
            -0.01736566610634327,
            0.028821272775530815,
            -0.013544135726988316,
            0.007066188380122185,
            -0.002642278326675296,
            -0.021827416494488716,
            -0.005162929650396109,
            -0.01556888036429882,
            -0.004722572863101959,
            -0.02040647342801094,
            0.012281319126486778,
            -0.038932546973228455,
            -0.031037529930472374,
            0.017032459378242493,
            0.00946955569088459,
            0.017811736091971397,
            -0.002501238137483597,
            0.014763547107577324,
            0.03594726324081421,
            0.039323240518569946,
            -0.04830574989318848,
            0.00220720493234694,
            0.002880118554458022,
            -0.0168014969676733,
            0.0065336464904248714,
            -0.015278811566531658,
            -0.02655266784131527,
            0.008227197453379631,
            -0.009112441912293434,
            -0.0061775385402143,
            -0.014437991194427013,
            0.033612269908189774,
            -0.03357876092195511,
            -0.0004204826836939901,
            0.0024022392462939024,
            0.03443882241845131,
            0.02039523422718048,
            -0.015500546433031559,
            0.02957550436258316,
            -0.018847843632102013,
            -0.0715799555182457,
            -0.005388491787016392,
            0.010514236986637115,
            -0.024832671508193016,
            0.020666277036070824,
            -0.0031614576000720263,
            -5.774866076535545e-05,
            -0.030239976942539215,
            -0.016055941581726074
        ],
        [
            -0.026711195707321167,
            0.0005900778342038393,
            -0.011886329390108585,
            0.021479126065969467,
            0.0230749249458313,
            -0.07131268084049225,
            -0.002348947338759899,
            -0.07729445397853851,
            0.013033452443778515,
            0.012831581756472588,
            -0.03152717649936676,
            0.03473209589719772,
            -0.05142296850681305,
            0.014739403501152992,
            0.04599869251251221,
            -0.01865256391465664,
            -0.0017194215906783938,
            0.03262356296181679,
            -0.009915470145642757,
            -0.01909240521490574,
            0.01200164295732975,
            -0.024406470358371735,
            -0.018858958035707474,
            0.03815780207514763,
            0.0462067611515522,
            0.028156911954283714,
            0.030658816918730736,
            0.005862732883542776,
            0.04137552157044411,
            0.01832738146185875,
            -0.031827982515096664,
            0.003536115400493145,
            -0.05824125185608864,
            -0.009085653349757195,
            -0.02603338100016117,
            0.028711514547467232,
            0.013310589827597141,
            -0.015745103359222412,
            -0.04375188797712326,
            -0.015272150747478008,
            0.002233310602605343,
            0.028376048430800438,
            0.020481521263718605,
            0.007456331048160791,
            0.020502902567386627,
            -0.06194596365094185,
            -0.03560721501708031,
            0.07838813960552216,
            -0.019044648855924606,
            0.016336791217327118,
            0.031178714707493782,
            0.002584178000688553,
            -0.05465640127658844,
            0.0068612778559327126,
            0.011553287506103516,
            -0.033319585025310516,
            -0.005889336112886667,
            0.0185465719550848,
            0.027898050844669342,
            0.0016948163975030184,
            0.021413538604974747,
            0.019198473542928696,
            0.030724121257662773,
            -0.025907976552844048,
            -0.005206468515098095,
            0.008062608540058136,
            -0.05130767822265625,
            0.013597914017736912,
            -0.022154441103339195,
            0.03674459829926491,
            -0.018234139308333397,
            0.01744985394179821,
            -0.02077818661928177,
            -0.029423069208860397,
            0.0873090922832489,
            0.029614338651299477,
            -0.01931282877922058,
            0.00037059112219139934,
            -0.02843235433101654,
            0.011610303074121475,
            -0.0019261251436546445,
            -0.019543781876564026,
            -0.013638659380376339,
            0.0043646167032420635,
            0.01467177551239729,
            0.024302363395690918,
            -0.021408189088106155,
            0.008984019979834557,
            0.04293885827064514,
            -0.020002124831080437,
            0.02243974432349205,
            0.009045343846082687,
            -0.04493626579642296,
            0.005110741592943668,
            0.02713487297296524,
            0.023634562268853188,
            -0.004671203438192606,
            0.03593432903289795,
            -0.011003896594047546,
            0.02412821725010872,
            0.027988020330667496,
            0.017357366159558296,
            0.010138659738004208,
            -0.06464885175228119,
            0.03870697319507599,
            0.042308259755373,
            -0.021211300045251846,
            -0.0012224902166053653,
            0.055402908474206924,
            0.02633327804505825,
            -0.010142603889107704,
            0.009384761564433575,
            0.01068608183413744,
            -0.008603300899267197,
            -0.09083688259124756,
            0.021155916154384613,
            -0.011756105348467827,
            -0.029874298721551895,
            0.01656177081167698,
            -0.030808499082922935,
            -0.018580947071313858,
            0.004043558146804571,
            -0.02623947523534298,
            -0.016447648406028748,
            0.026892881840467453,
            -0.01331189926713705,
            -0.03555770218372345,
            0.025222865864634514,
            0.025007035583257675,
            -0.0073442901484668255,
            -0.003259166143834591,
            0.007627752143889666,
            0.05823703855276108,
            -0.014507906511425972,
            0.004470593761652708,
            0.057762887328863144,
            -0.016222016885876656,
            0.010059304535388947,
            -0.0043676067143678665,
            -0.008031963370740414,
            -0.002103806473314762,
            -0.014514301903545856,
            -0.002454963279888034,
            -0.004517024382948875,
            -0.021465353667736053,
            -0.012772961519658566,
            -0.01126514095813036,
            0.014048771001398563,
            0.007028551772236824,
            -0.007044653408229351,
            0.037482473999261856,
            0.017745956778526306,
            -0.006063106935471296,
            -0.021741686388850212,
            -0.06497407704591751,
            -0.007777356542646885,
            -0.0006257782806642354,
            0.012174373492598534,
            0.018713705241680145,
            0.040078528225421906,
            -0.02505221962928772,
            0.03192872554063797,
            -0.0030079928692430258,
            -0.008083948865532875,
            -0.03219592943787575,
            0.06475091725587845,
            0.012384462170302868,
            0.013611037284135818,
            0.020107882097363472,
            -0.008869975805282593,
            -0.021056901663541794,
            0.037717461585998535,
            -0.0027874507941305637,
            -0.015066261403262615,
            0.016787061467766762,
            0.026883374899625778,
            -0.02771124057471752,
            0.0009753460763022304,
            -0.004680911544710398,
            0.024464234709739685,
            0.025607574731111526,
            -0.021549247205257416,
            0.01609065756201744,
            0.019453227519989014,
            0.0047668819315731525,
            0.026810865849256516,
            0.005294784437865019,
            -0.027882052585482597,
            -0.026487255468964577,
            -0.0030561257153749466,
            -0.004461771342903376,
            0.031450364738702774,
            -0.03859087824821472,
            -0.014607471413910389,
            0.010115762241184711,
            -0.007431190926581621,
            0.03948560357093811,
            -0.025490064173936844,
            -0.007285511586815119,
            -0.010512595996260643,
            -0.004868782591074705,
            0.01876586489379406,
            -0.03233671933412552,
            -0.012676454149186611,
            0.020585209131240845,
            -0.004013644065707922,
            0.018491143360733986,
            0.05635150149464607,
            0.0012930945958942175,
            0.032512422651052475,
            0.015903880819678307,
            0.021334370598196983,
            -0.06712796539068222,
            -0.03882092982530594,
            0.02937464229762554,
            0.00905225146561861,
            -0.04411480948328972,
            -0.016307709738612175,
            -0.03243587166070938,
            -0.05203435942530632,
            0.016366587951779366,
            0.04660066217184067,
            0.027821922674775124,
            -0.01132437214255333,
            -0.007699823006987572,
            -0.024661673232913017,
            0.0005373508320190012,
            -0.0010404475033283234,
            0.027550071477890015,
            0.024027740582823753,
            -0.01761866919696331,
            0.01296402420848608,
            -0.05185215175151825,
            0.003695283317938447,
            -0.029746968299150467,
            0.01593797467648983,
            -0.03970157727599144,
            0.027702180668711662,
            0.03845283389091492,
            0.05472298339009285,
            0.012632707133889198,
            -0.02502872794866562,
            -0.047345396131277084,
            -0.00910210981965065,
            0.0038867664989084005,
            -0.03482706844806671,
            0.0024646392557770014,
            -0.009109282866120338,
            0.03218832612037659,
            -0.020519495010375977,
            -0.022766469046473503,
            0.028775757178664207,
            0.012027504853904247,
            0.018269522115588188,
            0.0015968333464115858,
            0.01768377423286438,
            0.004894427489489317,
            -0.007271085865795612,
            0.018951555714011192,
            0.0016314893728122115,
            0.005236814729869366,
            -0.011721021495759487,
            0.06700433045625687,
            0.016535501927137375,
            0.037509769201278687,
            0.02806812897324562,
            -0.02522956021130085,
            -0.013556508347392082,
            -0.028028855100274086,
            0.013113878667354584,
            0.024785330519080162,
            -0.0073445518501102924,
            0.004003671929240227,
            0.02902108244597912,
            0.0014001772506162524,
            -0.013969570398330688,
            0.024846287444233894,
            0.01149173453450203,
            0.014023937284946442,
            0.01890837773680687,
            0.03538188710808754,
            0.03968724608421326,
            0.020203549414873123,
            0.02497974783182144,
            -0.0008273734711110592,
            0.017133936285972595,
            -0.008979241363704205,
            0.021242285147309303,
            -0.0035803671926259995,
            0.010354836471378803,
            0.0003271971654612571,
            0.0013368616346269846,
            -0.023417113348841667,
            0.015296283178031445,
            -0.0003688179131131619,
            0.003963920287787914,
            0.007646994665265083,
            0.021943338215351105,
            0.009077787399291992,
            -0.005993057508021593,
            -0.016889695078134537,
            0.03089214116334915,
            -0.029730163514614105,
            -0.022893255576491356,
            -0.026834210380911827,
            -0.041209522634744644,
            -0.00688439654186368,
            -0.00726463133469224,
            -0.016591187566518784,
            -0.03568167984485626,
            -0.06427814811468124,
            0.023266805335879326,
            0.019399123266339302,
            -0.00901271402835846,
            -0.0327984057366848,
            0.03908367082476616,
            0.012968926690518856,
            0.004688065964728594,
            0.01150160189718008,
            0.005379104986786842,
            -0.019753551110625267,
            0.06134216487407684,
            0.02384592592716217,
            -0.054586268961429596,
            -0.012466874904930592,
            -0.004386508371680975,
            -0.05835400149226189,
            -0.02365441992878914,
            0.01423664391040802,
            0.01937222108244896,
            -0.010806111618876457,
            0.0418260283768177,
            -0.030121613293886185,
            0.01926814764738083,
            -0.010247636586427689,
            -0.01626511849462986,
            -0.014813230372965336,
            -0.026732882484793663,
            -0.04153730720281601,
            0.007694662548601627,
            0.0008431021706201136,
            0.02067751996219158,
            -0.0029030023142695427,
            -0.033918630331754684,
            -0.03204548731446266,
            -0.07124049216508865,
            0.008788221515715122,
            0.04185659438371658,
            -0.01483157929033041,
            -0.013337692245841026,
            0.01246658992022276,
            0.023879630491137505,
            0.04619107395410538,
            -0.019317910075187683,
            -0.024448228999972343,
            -0.004832341335713863,
            -0.021736666560173035,
            0.008534872904419899,
            0.0319431871175766,
            -0.016928933560848236,
            -0.018547821789979935,
            0.011640684679150581,
            0.03573134168982506,
            -0.03113766573369503,
            -0.02567354403436184,
            -0.049034133553504944,
            0.010425404645502567,
            0.012337557040154934,
            -0.025178762152791023,
            0.01946347951889038,
            -0.010808072984218597,
            -0.017985906451940536,
            -0.009015982039272785,
            -0.018629122525453568,
            -0.001262428006157279,
            0.013193080201745033,
            0.015811027958989143,
            -0.04172884300351143,
            0.031377680599689484,
            -0.033165283501148224,
            -0.004520331975072622,
            0.026852494105696678,
            0.009578663855791092,
            0.011258995160460472,
            0.016551760956645012,
            -0.013032756745815277,
            0.02699548937380314,
            0.02104787342250347,
            0.0014078558888286352,
            -0.001908720238134265,
            0.012201222591102123,
            0.023982170969247818,
            0.005824345164000988,
            -0.0025640942621976137,
            -0.0052229296416044235,
            -0.008353752084076405,
            0.013196581043303013,
            0.007754588965326548,
            -0.011151187121868134,
            -0.0019962971564382315,
            0.030033595860004425,
            0.02790338173508644,
            -0.022433597594499588,
            -0.021024741232395172,
            -0.013200409710407257,
            -0.010414247401058674,
            0.012790448032319546,
            -0.0711902603507042,
            -0.013415057212114334,
            -0.012907246127724648,
            -0.02158281020820141,
            -0.013736442662775517,
            -0.025860963389277458,
            -0.01254390086978674,
            0.0019315649988129735,
            -0.008898931555449963,
            -0.021656647324562073,
            0.06860977411270142,
            -0.058543410152196884,
            -0.025667378678917885,
            0.02263442613184452,
            0.01643642596900463,
            -0.05794351547956467,
            0.00016029985272325575,
            0.025809505954384804,
            -0.07189225405454636,
            -0.019826091825962067,
            0.024723351001739502,
            0.0034698364324867725,
            0.007018806878477335,
            -0.007010942790657282,
            0.00808296911418438,
            0.012433797121047974,
            -0.013870599679648876,
            0.0030675409361720085,
            0.003681994741782546,
            -0.011668887920677662,
            0.016765927895903587,
            -0.015054741874337196,
            -0.0320788137614727,
            0.026983877643942833,
            -0.039659518748521805,
            0.006215157452970743,
            -0.002464164048433304,
            -0.042942967265844345,
            0.042580146342515945,
            -0.02143687941133976,
            -0.04592384770512581,
            -0.02343136817216873,
            -0.0038559837266802788,
            -0.004948470275849104,
            0.026726212352514267,
            -0.022355981171131134,
            -0.004674217663705349,
            0.04194542393088341,
            -0.017660751938819885,
            -0.0021582141052931547,
            -0.010656594298779964,
            -0.01947784423828125,
            -0.04326741397380829,
            -0.01124306209385395,
            -0.02058538608253002,
            0.009557603858411312,
            0.010526719503104687,
            -0.00767629686743021,
            0.008158789947628975,
            0.016232028603553772,
            0.008701317012310028,
            -0.015913264825940132,
            -0.010084301233291626,
            0.0059309485368430614,
            0.03130349516868591,
            0.0331011600792408,
            0.012292957864701748,
            0.008326868526637554,
            -0.05937182530760765,
            -0.030253907665610313,
            0.02156263031065464,
            -0.01206596102565527,
            -0.006874504499137402,
            0.0017739096656441689,
            0.03664814680814743,
            -0.03398742899298668,
            0.008810929022729397,
            0.008063304238021374,
            -0.013548681512475014,
            0.012092356570065022,
            -0.010890219360589981,
            -0.10088927298784256,
            0.02184971049427986,
            0.02985244430601597,
            -0.002638949081301689,
            0.01330036111176014,
            -0.00506543880328536,
            -0.014965198002755642,
            0.0353284552693367,
            0.004317306913435459,
            0.03349466621875763,
            0.034000325947999954,
            -0.015818342566490173,
            0.022899528965353966,
            -0.0196760855615139,
            0.02717544697225094,
            -0.023834800347685814,
            0.004903963766992092,
            0.01144015695899725,
            -0.02623622864484787,
            -0.015989994630217552,
            -0.031406667083501816,
            -0.034170620143413544,
            -0.006045714020729065,
            -0.03180078789591789,
            -0.013094455935060978,
            -0.002739597810432315,
            0.018868297338485718,
            0.02730695903301239,
            -0.022978022694587708,
            0.006714648101478815,
            0.03101803921163082,
            0.01524687372148037,
            0.029209742322564125,
            0.0071900710463523865,
            0.005707686301320791,
            0.014766207896173,
            0.05908893793821335,
            -0.06108054518699646,
            -0.033839184790849686,
            0.018460076302289963,
            0.012904593721032143,
            0.039618220180273056,
            0.021027248352766037,
            0.019353894516825676,
            -0.04527997970581055,
            0.012848315760493279,
            -0.001596391899511218,
            0.03871134668588638,
            -0.017217563465237617,
            -0.023476243019104004,
            -0.019195424392819405,
            -0.013802889734506607,
            -0.02089589275419712,
            0.03258012235164642,
            -0.014020764268934727,
            0.0021005715243518353,
            -0.03451158478856087,
            0.05551910772919655,
            0.03221866488456726,
            -0.04626082628965378,
            0.021518448367714882,
            -0.030959175899624825,
            -0.018562158569693565,
            -0.019335102289915085,
            0.011220607906579971,
            -0.07154697179794312,
            0.045159962028265,
            -0.0018189107067883015,
            -0.03935464844107628,
            -0.014780931174755096,
            -0.006937304511666298,
            -0.005439664237201214,
            0.03440379723906517,
            -0.01134150754660368,
            -0.0025251188781112432,
            0.014383499510586262,
            0.03527851402759552,
            0.00292217661626637,
            0.0434228889644146,
            -0.009569628164172173,
            -0.01667073555290699,
            0.014271114021539688,
            0.020428545773029327,
            0.01603235863149166,
            0.016611063852906227,
            0.024763569235801697,
            0.0319141261279583,
            0.020985575392842293,
            -0.009528455324470997,
            -0.0018823867430910468,
            -0.002197410212829709,
            -0.012586931698024273,
            0.012374049052596092,
            -0.013132939115166664,
            -0.009314630180597305,
            -0.013297692872583866,
            0.022617589682340622,
            0.01275042723864317,
            -0.013438869267702103,
            0.020484577864408493,
            -0.01737205497920513,
            -0.0192094799131155,
            -0.004523292649537325,
            -0.02828698791563511,
            -0.012076588347554207,
            0.025665676221251488,
            -0.013334989547729492,
            -0.014354554004967213,
            0.04581482335925102,
            0.00796325784176588,
            -0.012651481665670872,
            0.028549836948513985,
            -0.011041312478482723,
            0.01202066708356142,
            0.008462930098176003,
            -0.030349312350153923,
            -0.011745015159249306,
            -0.03464539721608162,
            -0.016459820792078972,
            0.002775167115032673,
            0.04099133238196373,
            0.02219315990805626,
            -0.006334360223263502,
            0.01571468636393547,
            0.042919740080833435,
            3.099407331319526e-05,
            0.018885299563407898,
            -0.04140805825591087,
            -0.018862023949623108,
            0.0013440776383504272,
            -0.023310702294111252,
            0.031162772327661514,
            0.020188426598906517,
            0.030750822275877,
            0.005276866257190704,
            -0.025038907304406166,
            0.0056378100998699665,
            0.03205937147140503,
            -0.01861046999692917,
            -0.0465557835996151,
            0.023286789655685425,
            0.042083997279405594,
            0.041812341660261154,
            0.016880683600902557,
            0.013766026124358177,
            -0.01111647766083479,
            -0.03204735368490219,
            0.021361300721764565,
            0.020638352259993553,
            -0.0006109530222602189,
            0.024957792833447456,
            -0.030045870691537857,
            0.036859262734651566,
            -0.043339621275663376,
            0.08820424973964691,
            -0.06409554183483124,
            -0.032997239381074905,
            -0.014150073751807213,
            0.026461360976099968,
            -0.025499528273940086,
            0.0170180294662714,
            -0.013947530649602413,
            -0.029382288455963135,
            -0.019944194704294205,
            0.015795594081282616,
            0.026004059240221977,
            0.002543174661695957,
            0.024849534034729004,
            -0.03415542468428612,
            0.033881042152643204,
            -0.03650325536727905,
            -0.029296042397618294,
            -0.0027553639374673367,
            0.010982473380863667,
            -0.04220658913254738,
            -0.015519194304943085,
            0.009913966059684753,
            -0.026904113590717316,
            0.014656329527497292,
            -0.0057272822596132755,
            -0.015813997015357018,
            0.015029000118374825,
            -0.034814197570085526,
            -0.03865853324532509,
            -0.019084976986050606,
            0.003577488474547863,
            -0.00960982870310545,
            -0.05400353670120239,
            0.027579667046666145,
            -0.032106757164001465,
            -0.0028683135751634836,
            -0.02219165489077568,
            -0.025989852845668793,
            0.006778600625693798,
            -0.003669673576951027,
            -0.02957490086555481,
            0.028355976566672325,
            -0.024199986830353737,
            -0.028642749413847923,
            -0.0273128692060709,
            0.023901280015707016,
            0.052305713295936584,
            0.010697096586227417,
            0.03960990905761719,
            -0.010969876311719418,
            0.007453121244907379,
            0.0011466705473139882,
            -0.025810612365603447,
            0.0006072513060644269,
            -0.02320329286158085,
            -0.0035701896995306015,
            0.014002312906086445,
            -0.001754318829625845,
            0.05618584528565407,
            0.004864552058279514,
            -0.03320065140724182,
            -0.029438024386763573,
            0.016321204602718353,
            -0.027839329093694687,
            0.018538739532232285,
            -0.013378438539803028,
            0.026407163590192795,
            -0.023915285244584084,
            -0.021827444434165955,
            -0.02292264625430107,
            -0.006885254755616188,
            -0.021686505526304245,
            -0.02973969839513302,
            -0.00041796971345320344,
            0.037395283579826355,
            -0.011802231892943382,
            0.017686590552330017,
            0.02244381234049797,
            -0.001050960854627192,
            0.006774634122848511,
            0.015180747024714947,
            0.024441303685307503,
            -0.02190428227186203,
            -0.025200700387358665,
            -0.02571972832083702,
            0.03743832930922508,
            -0.019844863563776016,
            0.002760599134489894,
            -0.0338614359498024,
            0.0373573824763298,
            -0.041261281818151474,
            0.042078208178281784,
            -0.028081605210900307,
            0.0038542700931429863,
            -0.03238711878657341,
            0.03949306905269623,
            0.0031766530591994524,
            0.013796544633805752,
            0.02471076510846615,
            -0.06471645832061768,
            -0.017135705798864365,
            -0.011912188492715359,
            0.0127593744546175,
            0.03512957692146301,
            0.02164830081164837,
            -0.03820398077368736,
            0.01484288927167654,
            0.01326829008758068,
            0.013090084306895733,
            0.03160390630364418,
            -0.010772456414997578,
            -0.010527982376515865,
            -0.029665768146514893,
            0.04876676946878433,
            0.025284413248300552,
            0.04244552180171013,
            0.013534333556890488,
            -0.05741323158144951,
            0.018741920590400696,
            0.026760203763842583,
            0.006879218854010105,
            -0.016288479790091515,
            -0.0036622679326683283,
            0.023685984313488007,
            5.675997817888856e-05,
            0.027346672490239143,
            0.006339798681437969,
            0.04219181463122368,
            -0.0023754280991852283,
            0.02640650048851967,
            -0.0016148904105648398,
            -0.02816193737089634,
            -0.010293853469192982,
            -0.007711568847298622,
            -0.025313014164566994,
            0.011837317608296871,
            -0.016654813662171364,
            0.010144357569515705,
            0.016713157296180725,
            0.005574190057814121,
            0.00635223463177681,
            0.01274122390896082,
            -0.03233979642391205,
            0.03362510725855827,
            -0.00018069273210130632,
            0.03739515691995621,
            0.03260378539562225,
            0.0184027049690485,
            0.006593404803425074,
            0.015282134525477886,
            -0.049765124917030334,
            0.00944400392472744,
            -0.002584262518212199,
            -0.019003307446837425,
            0.013552411459386349,
            -0.004487639758735895,
            -0.03755500167608261,
            -0.04012512043118477,
            0.005457134451717138,
            -0.010289257392287254,
            0.04385716840624809,
            -0.01780516281723976,
            0.015756791457533836,
            0.011343208141624928,
            0.037357255816459656,
            -0.009470303542912006,
            0.031080015003681183,
            -0.012821556068956852,
            0.013037369586527348,
            0.005737863946706057,
            0.04086577519774437,
            0.018997598439455032,
            -0.018669750541448593,
            -0.025220345705747604,
            -0.03878806158900261,
            0.019536107778549194,
            0.028268948197364807,
            0.032176725566387177,
            0.028742345049977303,
            -0.03936015069484711,
            -0.009450871497392654,
            0.07766053080558777,
            0.03601928800344467,
            0.0207622442394495,
            -0.004051982890814543,
            0.0028116509784013033,
            0.02382114715874195,
            -0.016317620873451233,
            -0.022348569706082344,
            0.03796207904815674,
            0.03616390377283096,
            -0.05542440339922905,
            0.019170258194208145,
            -0.00016508503176737577,
            -0.03475189954042435,
            -0.011338120326399803,
            0.03347131237387657,
            -0.007948912680149078,
            -0.031530335545539856,
            0.026735682040452957,
            -0.019391607493162155,
            0.008579735644161701,
            -0.019404955208301544,
            -0.008716105483472347,
            -0.014353441074490547,
            -0.009180980734527111,
            -0.03327898681163788,
            0.0007522967644035816,
            -0.014134212397038937,
            -0.006971028633415699,
            0.017767880111932755,
            -0.022552968934178352,
            0.010241067036986351,
            0.018468789756298065,
            0.04016616940498352,
            -0.041866742074489594,
            -0.0016633248887956142,
            -0.036327313631772995,
            0.010752933099865913,
            -0.05128316208720207,
            -0.02516804449260235,
            -0.023208213970065117,
            -0.04638409987092018,
            -0.005943525116890669,
            0.023165211081504822,
            0.005468468181788921,
            0.024995211511850357,
            0.017019808292388916,
            0.012638305313885212,
            0.009607954882085323,
            -0.00650552473962307,
            -0.028672195971012115,
            0.03485436365008354,
            0.04048765078186989,
            -0.005719853099435568,
            0.011808186769485474,
            -0.02279653027653694,
            0.017201298847794533,
            0.004936938174068928,
            -0.007946612313389778,
            0.015217888168990612,
            -0.008221782743930817,
            0.01413359958678484,
            0.008220569230616093,
            0.004744124598801136,
            0.003131729317829013,
            -0.029447659850120544,
            0.007711527869105339,
            -0.04770937189459801,
            -0.08239612728357315,
            0.02471776120364666,
            0.01903408393263817,
            0.0044416883029043674,
            0.018815433606505394,
            0.010128369554877281,
            0.019023070111870766,
            -0.015506262890994549,
            0.014795378781855106,
            -0.02935163863003254,
            -0.009822634048759937,
            0.022410869598388672,
            0.014552694745361805,
            0.013999102637171745,
            0.007089667022228241,
            0.01816517859697342,
            -0.009112946689128876,
            0.008729764260351658,
            -0.006348507013171911,
            0.004897660110145807,
            0.004200158175081015,
            -0.012458649463951588,
            -0.024660708382725716,
            -0.023375049233436584,
            -0.019674738869071007,
            0.01925882324576378,
            0.014532238245010376,
            -0.012714131735265255,
            0.017887897789478302,
            -0.02385513298213482,
            0.01296495832502842,
            0.033745329827070236,
            0.002579942112788558,
            0.005139857996255159,
            -0.007629053201526403,
            0.004679419565945864,
            -0.009874027222394943,
            -0.0006056264974176884,
            -0.017767809331417084,
            0.004932844545692205,
            0.006237023510038853,
            -0.04547305777668953,
            0.03157668933272362,
            0.0024650811683386564,
            0.027840957045555115,
            0.01357155479490757,
            0.03642814978957176,
            -0.005981141701340675,
            -0.036899060010910034,
            -0.04901343211531639,
            0.06584613770246506,
            -0.010881956666707993,
            -0.014896038919687271,
            -0.006611178629100323,
            -0.00011897522199433297,
            0.001305954996496439,
            -0.04392560198903084,
            0.023781904950737953,
            0.01816927269101143,
            -0.003495268290862441,
            -0.014145571738481522,
            -0.024986611679196358,
            -0.00274915574118495,
            -0.0019389919470995665,
            0.004677148070186377,
            0.018626438453793526,
            0.006188131868839264,
            0.0018355982610955834,
            -0.012730291113257408,
            -0.0292121022939682,
            0.05214070901274681,
            -0.024664321914315224,
            0.004399539437144995,
            -0.02827712707221508,
            -0.040010321885347366,
            0.027021558955311775,
            -0.03663141280412674,
            -0.005394906736910343,
            0.02495819516479969,
            -0.0077517530880868435,
            -0.006333361379802227,
            -0.0073693785816431046,
            0.0193492341786623,
            -0.002142326207831502,
            0.0013097838964313269,
            -0.01304483599960804,
            -0.02136847749352455,
            -0.0026736482977867126,
            -0.019801029935479164,
            -0.011497989296913147,
            -0.03823237121105194,
            0.00760026415809989,
            -0.027552494779229164,
            0.02464326284825802,
            -0.008779560215771198,
            0.004882579669356346,
            -0.02297608181834221,
            0.0058077373541891575,
            0.018366560339927673,
            -0.03356640040874481,
            0.020923318341374397,
            0.023542284965515137,
            -0.014394939877092838,
            -0.0022920193150639534,
            0.025184303522109985,
            0.019617078825831413,
            0.010437888093292713,
            -0.004225104581564665,
            -0.03065437823534012,
            0.015608672052621841,
            0.0010797487339004874,
            -0.014235110953450203,
            0.01190242450684309,
            -0.021197844296693802,
            -0.022981567308306694,
            -0.06836365908384323,
            0.0391041561961174,
            -0.0468449629843235,
            -0.0036116375122219324,
            0.028520099818706512,
            0.02762399986386299,
            -0.01186993345618248,
            -0.0032938995864242315,
            0.0017736318986862898,
            0.025000639259815216,
            -0.050563160330057144,
            0.03818152844905853,
            -0.013655202463269234,
            -0.021484553813934326,
            0.014358101412653923,
            -0.04781610891222954,
            0.0217724796384573
        ],
        [
            0.02687213569879532,
            0.036910925060510635,
            0.024595843628048897,
            -0.038396961987018585,
            -0.024794243276119232,
            0.010659686289727688,
            0.008391479961574078,
            -0.004492605105042458,
            -0.02391062118113041,
            0.02399500459432602,
            0.0059869056567549706,
            -0.018810397014021873,
            0.00472257612273097,
            0.0024228282272815704,
            -0.059660375118255615,
            0.009881654754281044,
            0.02049809694290161,
            -0.01758517324924469,
            0.020551219582557678,
            0.006769302301108837,
            0.0009231185540556908,
            0.03298725187778473,
            -0.01439216360449791,
            -0.02415440045297146,
            0.03387169912457466,
            -0.0028952239081263542,
            0.032834094017744064,
            0.014469888992607594,
            -0.005398219916969538,
            -0.049020957201719284,
            -0.0008869743323884904,
            -0.007300850935280323,
            -0.011887772008776665,
            0.02898973971605301,
            -0.016613762825727463,
            -0.031178610399365425,
            0.045101482421159744,
            0.009606495499610901,
            0.012051394209265709,
            -0.024413570761680603,
            0.030060021206736565,
            -0.01243782602250576,
            0.03638393431901932,
            -0.016999103128910065,
            0.03105134330689907,
            0.02737041935324669,
            0.01962805725634098,
            -0.04826623201370239,
            0.012195058166980743,
            0.00987032800912857,
            -0.04327801987528801,
            0.023627711459994316,
            0.02126959152519703,
            0.046122606843709946,
            -0.012584677897393703,
            -0.006950755603611469,
            -0.03094756044447422,
            0.029614616185426712,
            0.010734808631241322,
            -0.017871296033263206,
            0.013834320940077305,
            -0.00028092123102396727,
            0.016586529091000557,
            0.022205566987395287,
            0.006672913674265146,
            -0.01888870634138584,
            0.003936377353966236,
            -0.019375838339328766,
            -0.018092086538672447,
            0.017122749239206314,
            0.02953326888382435,
            0.03180577605962753,
            -0.034462880343198776,
            0.007633226923644543,
            0.03708983212709427,
            -0.04616709426045418,
            -0.0011317746248096228,
            -0.010180719196796417,
            0.03215906396508217,
            0.013711566105484962,
            -3.433390156715177e-05,
            0.005890900269150734,
            -0.012970912270247936,
            -0.008252031169831753,
            -0.020313380286097527,
            -0.04503161460161209,
            -0.02084038034081459,
            0.014826067723333836,
            0.007909216918051243,
            -0.037011221051216125,
            -0.016334690153598785,
            0.0329098217189312,
            0.006071921903640032,
            -0.030334021896123886,
            0.022414740175008774,
            0.024126645177602768,
            -0.031040171161293983,
            -0.004373264033347368,
            0.08398036658763885,
            0.02284032106399536,
            0.007178339641541243,
            -0.022861341014504433,
            0.027127793058753014,
            0.025838861241936684,
            0.006547318305820227,
            0.021318845450878143,
            -0.0010181247489526868,
            -0.062351044267416,
            0.03410625830292702,
            -0.013745685108006,
            0.009336813353002071,
            0.040371742099523544,
            -0.026944173499941826,
            -0.010916929692029953,
            -0.061119526624679565,
            0.026576906442642212,
            -0.0067865876480937,
            0.00016465144290123135,
            4.105398147657979e-06,
            -0.00658375071361661,
            0.014776219613850117,
            -0.006958249025046825,
            0.020854009315371513,
            0.024189161136746407,
            0.03765804320573807,
            0.02923225797712803,
            -0.00939002726227045,
            0.01643175259232521,
            -0.024063700810074806,
            -0.017790408805012703,
            -0.01172748301178217,
            0.00927507784217596,
            0.028122831135988235,
            0.00016595971828792244,
            0.01589212380349636,
            0.037089213728904724,
            0.039872389286756516,
            -0.003677146974951029,
            0.014260634779930115,
            -0.005945270415395498,
            -0.016102947294712067,
            -0.018139822408556938,
            0.007962426170706749,
            -0.008724916726350784,
            0.03054545260965824,
            0.02179420366883278,
            0.022705338895320892,
            0.021934833377599716,
            -0.0007787555223330855,
            0.02510037086904049,
            0.02162819728255272,
            -0.0449194498360157,
            0.0024609160609543324,
            -0.007473588455468416,
            -0.05142386630177498,
            0.015552072785794735,
            0.029529577121138573,
            0.01019886415451765,
            0.02422289364039898,
            0.016278382390737534,
            -0.03519183024764061,
            0.007534355856478214,
            0.0014081693952903152,
            0.009769958443939686,
            -0.034943677484989166,
            -0.0010617212392389774,
            0.03739788755774498,
            0.023959912359714508,
            -0.021436845883727074,
            -0.011953141540288925,
            -0.014054023660719395,
            -0.023056762292981148,
            0.023000938817858696,
            -0.0015945640625432134,
            0.03185795247554779,
            0.013666364364326,
            -0.03460371121764183,
            -0.030480168759822845,
            -0.013524329289793968,
            0.02247747965157032,
            -0.058806583285331726,
            0.02878335304558277,
            0.02731233276426792,
            -0.000664947961922735,
            3.062317773583345e-05,
            -0.02936745248734951,
            0.012342287227511406,
            0.021449662744998932,
            0.007753987330943346,
            0.017936447635293007,
            -0.029662327840924263,
            -0.006050357595086098,
            -0.024589359760284424,
            -0.01611199975013733,
            -0.025438319891691208,
            -0.047532182186841965,
            -0.04401775449514389,
            -0.023787522688508034,
            0.018166378140449524,
            0.007276603486388922,
            0.023859471082687378,
            -0.015805112197995186,
            -0.025990676134824753,
            -0.01471269503235817,
            0.04497576132416725,
            -0.013905739411711693,
            -0.030102577060461044,
            0.027822479605674744,
            0.012624901719391346,
            -0.021664025261998177,
            -0.018531689420342445,
            -0.02732613869011402,
            -0.018835727125406265,
            -0.052626822143793106,
            0.003853083122521639,
            0.0021606874652206898,
            -0.01764805242419243,
            -0.014627784490585327,
            0.00684494199231267,
            -0.01633453369140625,
            -0.0054380022920668125,
            -0.022641567513346672,
            0.019326115027070045,
            -0.012104829773306847,
            -0.021275144070386887,
            0.05619899928569794,
            -0.038934338837862015,
            -0.0031261304393410683,
            0.037935368716716766,
            -0.018100248649716377,
            0.014278259128332138,
            -0.0037947585806250572,
            -0.003659631824120879,
            0.033137597143650055,
            -0.015448029153048992,
            0.016104966402053833,
            0.014503191225230694,
            0.004965923260897398,
            -0.016552617773413658,
            -0.028656546026468277,
            -0.03179894760251045,
            0.024977589026093483,
            -0.008386055938899517,
            0.024897782132029533,
            -0.054113391786813736,
            0.00786210224032402,
            -0.02022942528128624,
            -0.008230199106037617,
            0.01847483031451702,
            -0.02936919964849949,
            0.012033636681735516,
            -0.01880785822868347,
            0.06679967790842056,
            -0.038507942110300064,
            -0.020926374942064285,
            -0.028682850301265717,
            -0.03292049840092659,
            0.022134294733405113,
            0.009033720009028912,
            -0.028580818325281143,
            -0.012395968660712242,
            -0.060663118958473206,
            0.061589691787958145,
            -0.017737839370965958,
            -0.02057783119380474,
            0.026923570781946182,
            -0.0013078951742500067,
            0.01570412889122963,
            -0.004548126831650734,
            0.004817637614905834,
            0.03186982497572899,
            0.039363060146570206,
            -0.04061565175652504,
            -0.0018754516495391726,
            0.03699605539441109,
            -0.041185177862644196,
            0.0054114037193357944,
            0.019055020064115524,
            0.02943326346576214,
            0.0037917003501206636,
            -0.03310083970427513,
            -0.01917191967368126,
            0.01630684919655323,
            -0.0165360439568758,
            -0.01773686148226261,
            0.0032204468734562397,
            -0.04355831816792488,
            -0.0271115992218256,
            -0.017559152096509933,
            0.02686406299471855,
            0.034676145762205124,
            -0.027900880202651024,
            -0.011010995134711266,
            -0.040492091327905655,
            0.024678809568285942,
            0.002138037933036685,
            -0.03593001887202263,
            -0.012720702216029167,
            0.039794351905584335,
            -0.01484772376716137,
            0.02388855814933777,
            0.020064005628228188,
            -0.01495704147964716,
            -0.025534644722938538,
            -0.018772698938846588,
            -0.03713861107826233,
            -0.03881582245230675,
            -0.013648828491568565,
            0.012727398425340652,
            0.02686820738017559,
            0.016509022563695908,
            -0.03294280171394348,
            -0.023075705394148827,
            0.010155685245990753,
            -0.038231052458286285,
            -0.017281102016568184,
            -0.016745828092098236,
            -0.02331722527742386,
            -0.008663794957101345,
            -0.015444180928170681,
            -0.018115585669875145,
            -0.008824710734188557,
            -0.01674528792500496,
            -0.028091615065932274,
            -0.010519564151763916,
            -0.01607382483780384,
            -0.016763828694820404,
            -0.027521083131432533,
            -0.018285438418388367,
            -0.01107910554856062,
            0.018590550869703293,
            0.004421176388859749,
            -0.014828096143901348,
            0.03007214330136776,
            -0.012423243373632431,
            -0.025989867746829987,
            -0.020764490589499474,
            -0.014524043537676334,
            0.0064353556372225285,
            0.04000765457749367,
            -0.006984391249716282,
            0.04268283024430275,
            0.059958841651678085,
            -0.01815519854426384,
            0.01023069303482771,
            0.03272686526179314,
            -0.012525363825261593,
            0.027884116396307945,
            0.010249133221805096,
            -0.0016985521651804447,
            0.04030604660511017,
            -0.015310726128518581,
            -0.008406484499573708,
            -0.013327056542038918,
            0.003392411395907402,
            0.0030603199265897274,
            -0.01650126650929451,
            -0.007737290114164352,
            -0.0009393071522936225,
            0.023943334817886353,
            -0.0008146017207764089,
            0.017818698659539223,
            0.052738599479198456,
            0.023243820294737816,
            -0.017689380794763565,
            0.006636418867856264,
            -0.056131165474653244,
            0.0060369339771568775,
            0.026249654591083527,
            0.018310299143195152,
            -0.016355711966753006,
            0.016031788662075996,
            -0.007321827579289675,
            0.012500805780291557,
            -0.030242053791880608,
            -0.009432925842702389,
            0.03760635852813721,
            -0.014889796264469624,
            -0.005940547678619623,
            0.009153700433671474,
            -0.07716989517211914,
            0.021875325590372086,
            -0.019831610843539238,
            0.006495431996881962,
            0.010646501556038857,
            0.012433228082954884,
            0.020477090030908585,
            -0.01908963918685913,
            -0.012312603183090687,
            -0.01157752238214016,
            0.023282669484615326,
            0.018052594736218452,
            0.01847158744931221,
            -0.012846899218857288,
            0.016827071085572243,
            0.008994902484118938,
            0.008852486498653889,
            0.042326491326093674,
            -0.020236004143953323,
            -0.02261219546198845,
            0.032384537160396576,
            0.02167893946170807,
            0.028835326433181763,
            -0.016165753826498985,
            -0.0395321398973465,
            0.0059003671631217,
            0.005931999068707228,
            -0.02916168048977852,
            -0.020286019891500473,
            0.008057335391640663,
            0.020371612161397934,
            -0.006851096171885729,
            -0.004985698498785496,
            0.011031138710677624,
            -0.022424476221203804,
            -0.0038780635222792625,
            -0.0022037720773369074,
            -0.07102956622838974,
            -0.04604818671941757,
            0.01919504441320896,
            0.0066373711451888084,
            -0.057771533727645874,
            0.016011826694011688,
            0.014246524311602116,
            0.013265102170407772,
            0.0823800191283226,
            -0.07197608798742294,
            0.011478033848106861,
            0.025420300662517548,
            -0.0041265301406383514,
            -0.05179436132311821,
            -0.01228230632841587,
            -0.008224389515817165,
            -0.016526810824871063,
            -0.016934936866164207,
            0.03564387187361717,
            0.046455711126327515,
            0.016771934926509857,
            0.0073325615376234055,
            0.02355331741273403,
            0.024192389100790024,
            0.0009896617848426104,
            0.01868385076522827,
            -0.02065338008105755,
            0.0032778431195765734,
            -0.010012079030275345,
            0.006744997575879097,
            -0.05538608506321907,
            0.008589512668550014,
            -0.02938193455338478,
            0.023059945553541183,
            0.012261130847036839,
            -0.02244856208562851,
            -0.005896811839193106,
            0.00579325994476676,
            -0.02540917880833149,
            -0.021752186119556427,
            0.020612424239516258,
            0.0007290102657862008,
            -0.024929571896791458,
            0.039949335157871246,
            0.011539221741259098,
            0.029816512018442154,
            0.01890430599451065,
            -0.0154189458116889,
            -0.022266333922743797,
            0.04596993699669838,
            0.02918078564107418,
            -0.025571534410119057,
            -0.0298107098788023,
            0.0036976728588342667,
            -0.004223411902785301,
            0.019812284037470818,
            0.004310449119657278,
            -0.06069564074277878,
            -0.010386457666754723,
            0.008223962038755417,
            0.01425689086318016,
            -0.019334295764565468,
            -0.029196476563811302,
            0.05281791836023331,
            -0.020576585084199905,
            0.003024591598659754,
            -0.013021674007177353,
            0.016529835760593414,
            0.007153839338570833,
            0.004155309405177832,
            0.031644728034734726,
            -0.0478639081120491,
            -0.003861483884975314,
            0.003037251066416502,
            -0.011719409376382828,
            0.008520794101059437,
            0.033439114689826965,
            0.041315313428640366,
            0.017685934901237488,
            0.007936284877359867,
            -0.013751582242548466,
            0.010157073847949505,
            -0.015521189197897911,
            -0.002693705726414919,
            -0.013782737776637077,
            -0.014837588183581829,
            0.022957824170589447,
            0.005920493975281715,
            -0.022850951179862022,
            -0.0020274240523576736,
            -0.022640889510512352,
            0.0019597269129008055,
            0.003615312511101365,
            -0.0034714117646217346,
            0.002970421453937888,
            -0.046071719378232956,
            -0.005789315793663263,
            0.04884061962366104,
            -0.025662198662757874,
            0.05644521862268448,
            0.01855994388461113,
            0.025831010192632675,
            -0.02989599108695984,
            0.028066571801900864,
            -0.004928676877170801,
            0.020368674769997597,
            -0.009199815802276134,
            0.08485288172960281,
            -0.05220768228173256,
            0.04907459393143654,
            0.021304968744516373,
            0.011230941861867905,
            -0.0005883361445739865,
            0.01272154413163662,
            -0.005383193027228117,
            -0.012394348159432411,
            0.013437630608677864,
            0.01226006168872118,
            0.016916580498218536,
            -0.02243073470890522,
            -0.018542245030403137,
            -0.05393574386835098,
            0.010675191879272461,
            0.03225186839699745,
            -0.02210671827197075,
            -0.0180344395339489,
            0.02688203565776348,
            0.007138687185943127,
            -0.05429176613688469,
            -0.0052863783203065395,
            -0.011709176003932953,
            0.01680697500705719,
            0.031857412308454514,
            -0.004904919303953648,
            -0.02260689064860344,
            -0.03204965591430664,
            0.0009244333487004042,
            -0.05746409296989441,
            -0.03190237283706665,
            -0.01568644866347313,
            -0.03452726826071739,
            -0.019351918250322342,
            -0.0024478365667164326,
            -0.015851600095629692,
            0.03313310071825981,
            0.014102093875408173,
            0.018050014972686768,
            -0.02689511515200138,
            0.024146586656570435,
            -0.005334042478352785,
            0.022511249408125877,
            -6.396471871994436e-05,
            -0.011209345422685146,
            0.005897012073546648,
            -0.028702454641461372,
            -0.05928618833422661,
            0.01150884572416544,
            -0.0016057868488132954,
            -0.014270861633121967,
            0.0007536748889833689,
            0.03044755756855011,
            -0.004064472392201424,
            -0.03438135236501694,
            -0.01594567857682705,
            -0.040057506412267685,
            0.019533304497599602,
            0.02875806763768196,
            -0.0353563167154789,
            -0.030035268515348434,
            -0.004367925226688385,
            0.0021645633969455957,
            0.005182833876460791,
            0.030586451292037964,
            -0.036269661039114,
            0.0036091564688831568,
            -0.009908599779009819,
            -0.015863902866840363,
            -0.021129829809069633,
            0.0030399602837860584,
            -0.006389941088855267,
            0.01972448267042637,
            -0.027196228504180908,
            -0.018752750009298325,
            -0.009477426297962666,
            0.03171922639012337,
            -0.005968321114778519,
            -0.0178533885627985,
            -0.008847594261169434,
            0.007454692851752043,
            0.0064318799413740635,
            -0.0022303967271000147,
            -0.0061185117810964584,
            -0.025987831875681877,
            -0.025641804561018944,
            -0.010804879479110241,
            -0.0125808110460639,
            -0.029780840501189232,
            0.023827653378248215,
            0.020226698368787766,
            -0.01558108814060688,
            -0.008767019957304,
            -0.025075146928429604,
            0.0012826962629333138,
            0.035105492919683456,
            0.01990777999162674,
            0.00045353174209594727,
            -0.019009124487638474,
            0.02695971354842186,
            -0.007437754422426224,
            0.020801134407520294,
            -0.038984984159469604,
            0.03324004262685776,
            -0.02230481244623661,
            0.007330781780183315,
            -0.01926104724407196,
            -0.002589701907709241,
            -0.01881316304206848,
            -0.053881701081991196,
            -0.02323463000357151,
            0.004009411204606295,
            0.01976073533296585,
            -0.008434929884970188,
            -0.0028574655298143625,
            0.001782878185622394,
            -0.02681790292263031,
            -0.0970996543765068,
            0.04248092323541641,
            0.0020108153112232685,
            -0.025017011910676956,
            0.03977378457784653,
            -0.004939745645970106,
            -0.0026604754384607077,
            0.013659356161952019,
            -0.011969979852437973,
            0.02479805424809456,
            0.009236029349267483,
            0.009324527345597744,
            -0.005852142348885536,
            0.0052360184490680695,
            -0.028737876564264297,
            0.012734214775264263,
            -0.01044380571693182,
            0.0030889783520251513,
            -0.004091826733201742,
            0.03075104020535946,
            -0.021152285858988762,
            -0.0240379236638546,
            -0.01285826601088047,
            -0.023293228819966316,
            0.017765050753951073,
            -0.030813228338956833,
            -0.01809309795498848,
            0.02821628749370575,
            0.03485456854104996,
            0.012419370003044605,
            0.0056627425365149975,
            0.02969307079911232,
            0.011230088770389557,
            0.02472386695444584,
            0.002092901151627302,
            -0.0010290831560268998,
            0.022244933992624283,
            0.013978959992527962,
            0.01788957603275776,
            0.015360639430582523,
            -0.0015666671097278595,
            -0.04055873677134514,
            -0.02871846593916416,
            -0.012436499819159508,
            0.009438307955861092,
            0.039960119873285294,
            -0.01708492822945118,
            -0.021643172949552536,
            -0.0215915497392416,
            -0.016153352335095406,
            -0.0029397837352007627,
            0.020984351634979248,
            0.009022587910294533,
            -0.030874619260430336,
            0.04256698116660118,
            0.028044380247592926,
            0.03210991993546486,
            -0.02209726721048355,
            -0.025240952149033546,
            0.014970586635172367,
            -0.030837513506412506,
            -0.016542209312319756,
            -0.02688644453883171,
            0.0007296589901670814,
            0.007431629579514265,
            0.042066145688295364,
            0.010230520740151405,
            0.010854889638721943,
            -0.016417009755969048,
            -0.007948371581733227,
            -0.021439289674162865,
            -0.021051160991191864,
            0.035819604992866516,
            0.004812863189727068,
            0.07093897461891174,
            0.012366842478513718,
            0.006584779359400272,
            0.014196299016475677,
            0.014238178730010986,
            -0.024593481793999672,
            -0.0118184145539999,
            0.025012914091348648,
            0.009445094503462315,
            -0.015681250020861626,
            -0.030283590778708458,
            -0.001284638768993318,
            0.009158462285995483,
            -0.00813102163374424,
            0.024518707767128944,
            -0.012051207013428211,
            0.026503516361117363,
            0.019609836861491203,
            0.037208572030067444,
            0.001973863458260894,
            -0.027690749615430832,
            -0.004745393991470337,
            0.052054163068532944,
            0.0026945271529257298,
            0.017704863101243973,
            0.019726425409317017,
            -0.008251425810158253,
            -0.033352069556713104,
            0.004448828753083944,
            -0.017842786386609077,
            0.017217161133885384,
            0.020579935982823372,
            0.014250299893319607,
            0.011583850719034672,
            0.010120375081896782,
            0.025286121293902397,
            0.017375703901052475,
            -0.03457312658429146,
            -0.016231436282396317,
            0.00206386880017817,
            -0.012378045357763767,
            -0.0026309234090149403,
            -0.07349980622529984,
            -0.010786598548293114,
            -0.009086329489946365,
            0.0732441321015358,
            -0.02902442403137684,
            0.027106570079922676,
            -0.029340794309973717,
            -0.031921692192554474,
            -0.028726717457175255,
            0.013426422141492367,
            -0.009850261732935905,
            0.029721591621637344,
            0.0099943270906806,
            0.06116193160414696,
            0.0066192010417580605,
            0.006811984349042177,
            -0.011693388223648071,
            -0.026242678984999657,
            0.023631807416677475,
            -0.02420320175588131,
            -0.015579578466713428,
            -0.006898046936839819,
            0.01157329324632883,
            0.023191429674625397,
            0.006758811417967081,
            -0.012232008390128613,
            -0.015523213893175125,
            0.0030238835606724024,
            0.026484403759241104,
            0.023639168590307236,
            0.02870219759643078,
            -0.021157659590244293,
            0.04262794554233551,
            0.004254911560565233,
            -0.014118898659944534,
            -0.008720360696315765,
            0.029390202835202217,
            0.03550207242369652,
            -0.04394664615392685,
            -0.014830153435468674,
            0.0016785755287855864,
            -0.023810546845197678,
            0.024455782026052475,
            -0.018329938873648643,
            -0.010126201435923576,
            -0.0313710980117321,
            -0.04327128082513809,
            -0.03277583420276642,
            0.03510319069027901,
            0.015124151483178139,
            -0.024000633507966995,
            0.014641152694821358,
            0.06076686084270477,
            0.014766565524041653,
            0.016799472272396088,
            -0.004239960573613644,
            0.019233575090765953,
            -0.0025026691146194935,
            -0.020489279180765152,
            0.029509568586945534,
            -0.022595465183258057,
            0.005264234263449907,
            0.02221253328025341,
            0.010147891007363796,
            0.0018686098046600819,
            0.02460920251905918,
            0.0138848340138793,
            0.01883719488978386,
            0.013453897088766098,
            -0.025465836748480797,
            0.007275925949215889,
            0.027971290051937103,
            -0.0198993943631649,
            0.023783430457115173,
            -0.008634082041680813,
            0.021236659958958626,
            -0.04119411110877991,
            0.004018931183964014,
            -0.008869720622897148,
            0.029929449781775475,
            0.029561974108219147,
            -0.012673384509980679,
            0.025922494009137154,
            -0.022791648283600807,
            -0.04770644009113312,
            0.023751484230160713,
            0.00491337152197957,
            0.012776489369571209,
            -0.004766868427395821,
            -0.004364196211099625,
            -0.010494834743440151,
            -0.021234849467873573,
            -0.021499132737517357,
            0.02422679029405117,
            -0.017497148364782333,
            -0.03453441709280014,
            -0.00802349392324686,
            -0.009001568891108036,
            -0.04268161952495575,
            0.02547813020646572,
            -0.021083662286400795,
            -0.0015430268831551075,
            -0.014842535369098186,
            -0.009587781503796577,
            0.03508888930082321,
            -0.014249135740101337,
            -0.017710505053400993,
            0.025184674188494682,
            0.005368337966501713,
            -0.012059248983860016,
            0.022341465577483177,
            0.048792239278554916,
            0.03197985142469406,
            -0.02589336223900318,
            0.03467787057161331,
            0.028762558475136757,
            -0.03392963111400604,
            -0.04120511934161186,
            -0.023964783176779747,
            0.0011009102454409003,
            0.0074123600497841835,
            0.014651256613433361,
            -0.016116375103592873,
            -0.030945895239710808,
            0.027467941865324974,
            0.016959307715296745,
            0.008464528247714043,
            0.016427036374807358,
            0.03396448865532875,
            0.09376530349254608,
            -0.015263722278177738,
            0.017243333160877228,
            -0.004141532350331545,
            -0.00791341345757246,
            -0.019576769322156906,
            -0.06145469844341278,
            0.03907179832458496,
            0.0034037362784147263,
            -0.022074921056628227,
            0.0219184011220932,
            0.010263158939778805,
            -0.011658691801130772,
            -0.008977577090263367,
            -0.000679527351167053,
            0.008700062520802021,
            0.032577622681856155,
            -0.027049701660871506,
            0.01641949638724327,
            -0.050604455173015594,
            0.00641679996624589,
            0.013907358981668949,
            -0.02308582328259945,
            -0.012284060008823872,
            0.02060546912252903,
            -0.003974035382270813,
            -0.013619261793792248,
            -0.01362741831690073,
            0.02679356001317501,
            -0.017159560695290565,
            0.005183070432394743,
            -0.022690756246447563,
            0.020441260188817978,
            0.0003472957469057292,
            0.006387355271726847,
            -0.0299798846244812,
            0.013990754261612892,
            0.046553149819374084,
            -0.003936331253498793,
            0.03066718392074108,
            0.03234463557600975,
            0.017772991210222244,
            0.008009613491594791,
            0.02665651962161064,
            -0.00748751126229763,
            0.022724740207195282,
            -0.005786144640296698,
            -0.00723985442891717,
            -0.020097874104976654,
            0.00113061279989779,
            0.012411186471581459,
            -0.032583728432655334,
            0.00775662949308753,
            0.03273509815335274,
            0.01316359918564558,
            -0.023227283731102943,
            -0.02581481635570526,
            -0.0149712348356843,
            0.0036295796744525433,
            -9.813527867663652e-05,
            -0.0009593918803147972,
            -0.014528182335197926,
            -0.0030199310276657343,
            0.03951077163219452,
            -0.012021888978779316,
            0.01703890785574913,
            0.006751061417162418,
            -0.02463354356586933,
            -0.024154316633939743,
            -0.0060510579496622086,
            -0.011088824830949306,
            0.054206568747758865,
            -0.027148915454745293,
            0.028199005872011185,
            0.03957536444067955,
            -0.0004233142826706171,
            -0.0006761819240637124,
            -0.025461547076702118,
            0.005219414830207825,
            -0.05137580260634422,
            0.016408804804086685,
            0.03135599195957184,
            0.01277652196586132,
            0.01566419191658497,
            0.009241728112101555,
            -0.011518391780555248,
            0.008816431276500225,
            0.04217785969376564,
            0.013942667283117771,
            0.0018049456411972642,
            -0.012160493060946465,
            -0.0336257629096508,
            -0.027648473158478737,
            -0.04763246700167656,
            -0.019565606489777565,
            0.048916444182395935,
            0.014988917857408524,
            0.04093095660209656,
            -0.021585233509540558,
            -0.06571997702121735,
            0.009419742040336132,
            0.015776867046952248,
            -0.005927480291575193,
            0.0207869540899992,
            -0.008802192285656929,
            -0.01624547317624092,
            0.023788923397660255,
            -0.0005115179228596389,
            0.07726266980171204,
            0.0517432764172554,
            -0.015488026663661003,
            0.01317346841096878,
            -0.02921103686094284,
            -0.01526318583637476,
            -0.013677643612027168,
            0.029643962159752846,
            0.020234648138284683,
            -0.004060160834342241,
            -0.01580316387116909,
            -0.0026756238657981157,
            0.008863232098519802,
            0.019102497026324272,
            -0.0012251462321728468,
            0.009298251941800117,
            0.039401303976774216,
            -0.0019939972553402185,
            0.010468046180903912,
            -0.0021088067442178726,
            0.003250598907470703,
            0.00783686712384224,
            0.008653733879327774,
            -0.017141977325081825,
            -0.01242737751454115,
            -0.005540051963180304,
            0.029564321041107178,
            0.008656585589051247,
            0.008273974992334843
        ],
        [
            -0.024041680619120598,
            -0.01075487770140171,
            0.02067379839718342,
            0.00025427088257856667,
            -0.03462827205657959,
            -0.03381054848432541,
            0.006446600891649723,
            0.034428078681230545,
            0.027287011966109276,
            -0.012898088432848454,
            -0.028073439374566078,
            0.00910685583949089,
            0.010039818473160267,
            0.0016134589677676558,
            -0.016577649861574173,
            0.012268831953406334,
            0.004040310624986887,
            -0.024493997916579247,
            -0.033511485904455185,
            0.010900713503360748,
            -0.021574271842837334,
            -0.015506051480770111,
            0.028556546196341515,
            -0.0016053292201831937,
            0.035148411989212036,
            0.005416263826191425,
            0.011079645715653896,
            0.024175278842449188,
            -0.00020337573369033635,
            0.018110984936356544,
            0.003060580464079976,
            -0.038620419800281525,
            0.004177235998213291,
            -0.034999895840883255,
            -0.009053856134414673,
            0.022975439205765724,
            0.0319339856505394,
            0.00830917526036501,
            -0.03525590896606445,
            0.011602734215557575,
            -0.04446914419531822,
            0.006867777556180954,
            0.01858709193766117,
            -0.003143944311887026,
            -0.014991246163845062,
            0.015890322625637054,
            0.02452617697417736,
            -0.03353115916252136,
            0.0217516478151083,
            0.005082899238914251,
            -0.045242100954055786,
            -0.02970103733241558,
            0.03663064166903496,
            0.0283197071403265,
            -0.009978624060750008,
            0.02046080492436886,
            -0.005269172601401806,
            -0.003920252900570631,
            0.014778009615838528,
            0.008433502167463303,
            -0.02867135778069496,
            -0.005637373309582472,
            -0.02351273223757744,
            -0.016661323606967926,
            0.02043834514915943,
            0.02401512861251831,
            0.023546086624264717,
            -0.005422270856797695,
            0.002857292303815484,
            0.0022349348291754723,
            0.02463376894593239,
            0.019459880888462067,
            -0.017612481489777565,
            0.005064984783530235,
            -0.046943288296461105,
            -0.02298630401492119,
            0.01586291380226612,
            -0.01952139101922512,
            0.011167428456246853,
            0.011459039524197578,
            0.027719488367438316,
            -0.005202616564929485,
            0.018248410895466805,
            0.005226049106568098,
            -0.0033995453268289566,
            0.0168813094496727,
            0.031333524733781815,
            0.0012252978049218655,
            -0.011219636537134647,
            -0.009836443699896336,
            -0.027539558708667755,
            0.030948456376791,
            0.026487428694963455,
            -0.005754167214035988,
            0.005744275636970997,
            0.02738623321056366,
            0.0006719471421092749,
            -0.0005441137473098934,
            0.017549408599734306,
            0.014355813153088093,
            0.007577366661280394,
            0.004640820901840925,
            -0.004096028860658407,
            0.014114131219685078,
            0.005323183257132769,
            -0.018499605357646942,
            -0.03903849050402641,
            -0.007422553841024637,
            -0.00727884704247117,
            0.01821930706501007,
            -0.01408503670245409,
            0.04332985356450081,
            -0.003580411896109581,
            -0.020559241995215416,
            -0.02618408389389515,
            0.015882374718785286,
            0.03045736253261566,
            0.02964305318892002,
            0.016200484707951546,
            0.024776656180620193,
            0.02052522450685501,
            0.023806126788258553,
            -0.00044993183109909296,
            0.010597583837807178,
            0.029875801876187325,
            -0.013545938767492771,
            -0.03460137918591499,
            -0.017466681078076363,
            0.0005979688139632344,
            -0.004362761043012142,
            -0.02440713904798031,
            0.02267943136394024,
            -0.03194385766983032,
            0.05438033118844032,
            0.03364172205328941,
            0.027693431824445724,
            -0.005632853135466576,
            0.005889023654162884,
            0.011825650930404663,
            -0.01642204262316227,
            0.010614744387567043,
            0.003448663977906108,
            0.008316795341670513,
            -0.0008489933097735047,
            0.028258956968784332,
            0.022499140352010727,
            0.028126370161771774,
            -0.027948454022407532,
            -0.0009174046572297812,
            -0.015329621732234955,
            0.023501353338360786,
            -0.011425042524933815,
            0.01131970901042223,
            -0.05303949862718582,
            0.0014891428872942924,
            -0.008556338027119637,
            -0.004276044201105833,
            0.004632767289876938,
            0.034279339015483856,
            -0.03319014236330986,
            -0.023161452263593674,
            0.029746098443865776,
            0.03246885538101196,
            -0.015503261238336563,
            0.009102403186261654,
            -0.014015483669936657,
            0.0013846983201801777,
            -0.038761354982852936,
            -0.008110444992780685,
            -0.02205030806362629,
            -0.019471339881420135,
            0.022781558334827423,
            0.03272230550646782,
            -0.021592319011688232,
            0.019916823133826256,
            -0.021574517711997032,
            0.021727874875068665,
            0.0312773734331131,
            -0.0037542320787906647,
            -0.006120722740888596,
            -0.015170776285231113,
            -0.024562953040003777,
            0.056157078593969345,
            -0.030125921592116356,
            -0.002111500594764948,
            -0.034977275878190994,
            0.0024349773302674294,
            0.0159599706530571,
            0.004773599095642567,
            -0.032508883625268936,
            -0.017066793516278267,
            0.022390272468328476,
            -0.05941232666373253,
            0.035845283418893814,
            -0.01731892116367817,
            0.0064121573232114315,
            0.012572783045470715,
            -0.00778495566919446,
            -0.010435333475470543,
            0.02776322141289711,
            -0.009118649177253246,
            -0.0010757141280919313,
            -0.035827331244945526,
            0.005933091975748539,
            0.030241012573242188,
            -0.02548072673380375,
            -0.06914621591567993,
            -0.042380575090646744,
            0.03374670818448067,
            -0.01621883735060692,
            -0.007664532866328955,
            0.014732501469552517,
            -0.022440772503614426,
            0.003875336842611432,
            -0.028820035979151726,
            -0.007537068333476782,
            -0.020656760782003403,
            -0.014155170880258083,
            -0.029236000031232834,
            -0.008442900143563747,
            -0.01626269333064556,
            -0.03243396058678627,
            0.0176750048995018,
            0.012822026386857033,
            -0.020757149904966354,
            0.00871645100414753,
            0.013087749481201172,
            0.023939484730362892,
            -0.001232024049386382,
            0.015758812427520752,
            0.012623833492398262,
            0.011990074068307877,
            -0.03231991082429886,
            -0.01712874509394169,
            0.018329724669456482,
            0.0014583219308406115,
            0.015511736273765564,
            -0.028187787160277367,
            -0.007168504409492016,
            -0.011982377618551254,
            -0.0036203975323587656,
            0.005775836296379566,
            0.005893124267458916,
            -0.004125738050788641,
            -0.018004372715950012,
            -0.0296788327395916,
            -0.02088906429708004,
            -0.001996762817725539,
            -0.01902296394109726,
            0.018614336848258972,
            -0.009461915120482445,
            0.01773529127240181,
            -0.03411489725112915,
            0.004188074730336666,
            0.007417944259941578,
            0.024957900866866112,
            0.007105809170752764,
            -0.021239159628748894,
            -0.02316702902317047,
            0.03768792003393173,
            -0.025062590837478638,
            -0.003553921589627862,
            -0.008305600844323635,
            -0.004904937464743853,
            0.0042364452965557575,
            0.012431778945028782,
            0.024640457704663277,
            -0.032365359365940094,
            0.0090091647580266,
            -0.0362900011241436,
            -0.03811945021152496,
            -0.05508783087134361,
            -0.015473985113203526,
            -0.03126406669616699,
            -0.005285991821438074,
            0.022127607837319374,
            0.007782395463436842,
            1.7819636468630051e-06,
            -0.006288812030106783,
            -0.028129467740654945,
            -0.043319910764694214,
            -0.006420013029128313,
            -0.011752612888813019,
            -0.015257634222507477,
            -0.03320354223251343,
            0.0036718249320983887,
            -0.022793063893914223,
            -0.03596013784408569,
            0.02051183395087719,
            -0.03551492840051651,
            0.0007802721229381859,
            0.01340126246213913,
            0.01468564197421074,
            0.016705170273780823,
            -0.0010967424605041742,
            -0.012277452275156975,
            0.008173398673534393,
            0.007156246807426214,
            0.013536660932004452,
            0.0027087079361081123,
            0.008092513307929039,
            -0.0209598820656538,
            -0.008910640142858028,
            -0.026441186666488647,
            -0.011505446396768093,
            -0.002051959279924631,
            0.011474868282675743,
            -0.027796320617198944,
            0.024699391797184944,
            0.0360589362680912,
            -0.02844066359102726,
            -0.011939563788473606,
            -0.02284039370715618,
            0.02114562876522541,
            0.015600456856191158,
            -0.020012563094496727,
            -0.03526706248521805,
            -0.004008391406387091,
            -0.029064057394862175,
            -0.02217634953558445,
            0.019296273589134216,
            0.012848783284425735,
            -0.012757455930113792,
            0.011267293244600296,
            0.012867797166109085,
            0.02408904954791069,
            0.002214315813034773,
            0.01036443654447794,
            0.025806764140725136,
            0.019480135291814804,
            0.015428834594786167,
            -0.013440846465528011,
            0.0014164324384182692,
            0.01590745337307453,
            0.009526650421321392,
            -0.03016260638833046,
            -0.006195668131113052,
            0.012718970887362957,
            0.0011533007491379976,
            0.026377977803349495,
            -0.001547916210256517,
            -0.010196552611887455,
            0.042453695088624954,
            0.01137933786958456,
            -0.017934734001755714,
            -0.01712411269545555,
            -0.044705018401145935,
            -0.01858404092490673,
            -0.010888063348829746,
            0.0405195914208889,
            0.034757595509290695,
            -0.02739116922020912,
            0.01367864478379488,
            -0.005235475953668356,
            -0.026564333587884903,
            -0.009929491207003593,
            0.022906331345438957,
            0.020075835287570953,
            -0.019404735416173935,
            -0.0159273911267519,
            0.001049472251906991,
            -0.020474141463637352,
            0.023252593353390694,
            0.027183905243873596,
            0.02058473601937294,
            -0.008603076450526714,
            -0.029748788103461266,
            0.031147425994277,
            -0.027315698564052582,
            -0.014856401830911636,
            -0.035061560571193695,
            -0.022607289254665375,
            -0.027467843145132065,
            -0.013677557930350304,
            -0.0037481444887816906,
            0.009906860068440437,
            -0.007852069102227688,
            -0.002525949152186513,
            -0.013096618466079235,
            -0.03322451934218407,
            0.010286936536431313,
            0.017703460529446602,
            -0.024681331589818,
            0.006829467136412859,
            -0.008984904736280441,
            0.004144318401813507,
            -0.012616882100701332,
            -0.02023843117058277,
            -0.010790606029331684,
            0.0034671705216169357,
            -0.011758764274418354,
            0.028879547491669655,
            -0.025692595168948174,
            -0.02153194509446621,
            -0.040294840931892395,
            0.02265406772494316,
            0.01530469674617052,
            0.023791665211319923,
            0.020405448973178864,
            0.004228738136589527,
            0.021587956696748734,
            0.019705820828676224,
            -0.03091190755367279,
            0.01622273214161396,
            0.009800856001675129,
            0.024368539452552795,
            -0.028288638219237328,
            0.025788921862840652,
            -0.0022923487704247236,
            -0.011005162261426449,
            -0.03257651627063751,
            -0.03409072384238243,
            0.02727789245545864,
            0.005361842457205057,
            -0.014460567384958267,
            0.009172575548291206,
            -0.01716393232345581,
            -0.0254430640488863,
            -0.03388780355453491,
            -0.0024903190787881613,
            -0.002444307319819927,
            -0.011689336970448494,
            0.017394881695508957,
            0.00914660282433033,
            0.03973215073347092,
            -0.004438144154846668,
            0.03840845078229904,
            0.034209366887807846,
            -0.023552920669317245,
            -0.022610848769545555,
            0.009554405696690083,
            -0.019554773345589638,
            0.01791178621351719,
            0.026132939383387566,
            0.0019768080674111843,
            0.0006884910981170833,
            0.00444769999012351,
            -0.010593722574412823,
            0.03248228877782822,
            0.020265109837055206,
            0.01226894836872816,
            -0.001990600023418665,
            0.017980938777327538,
            -0.03021816909313202,
            -0.022107809782028198,
            -0.01826491765677929,
            0.021070895716547966,
            0.01593049056828022,
            0.0010732847731560469,
            0.01096264086663723,
            -0.011669306084513664,
            0.0035510363522917032,
            -0.030712082982063293,
            -0.022954370826482773,
            -0.026824722066521645,
            0.019793441519141197,
            0.019463611766695976,
            -0.0015348601154983044,
            -0.018066024407744408,
            0.011402118019759655,
            0.016695907339453697,
            -0.028947172686457634,
            0.023072203621268272,
            -0.012296823784708977,
            0.015395782887935638,
            -0.015001031570136547,
            0.033247679471969604,
            0.01364867202937603,
            0.012236817739903927,
            -0.01188256498426199,
            0.01670285128057003,
            -0.03289658576250076,
            0.016733018681406975,
            0.015627797693014145,
            -0.03780530393123627,
            -0.009143365547060966,
            -0.018182463943958282,
            -0.03523048013448715,
            0.029918445274233818,
            0.03335445374250412,
            0.009261763654649258,
            0.029249656945466995,
            -0.008579371497035027,
            -0.004563286900520325,
            0.01378379762172699,
            -0.006553571205586195,
            -0.006965500768274069,
            -0.02797679789364338,
            -0.12246277928352356,
            0.035533394664525986,
            -0.03414985164999962,
            0.01909857988357544,
            -0.0030431277118623257,
            -0.021554093807935715,
            0.024804256856441498,
            -0.005715021397918463,
            -0.003813487011939287,
            0.005196638870984316,
            0.023214422166347504,
            -0.016421064734458923,
            -0.0018292914610356092,
            0.01874789223074913,
            0.006931248586624861,
            0.014180202968418598,
            -0.029496746137738228,
            0.02413810044527054,
            -0.0010794959962368011,
            -0.011716841720044613,
            0.02135482430458069,
            -0.012507558800280094,
            -0.013817793689668179,
            0.02697911486029625,
            -0.010635502636432648,
            -0.0026615564711391926,
            0.0035301302559673786,
            0.01718330755829811,
            0.001100298948585987,
            0.01151279266923666,
            0.020752476528286934,
            -0.006467245053499937,
            0.0011854659533128142,
            0.014565606601536274,
            0.021751997992396355,
            0.007748784497380257,
            0.00044299871660768986,
            -0.019157977774739265,
            -0.07200135290622711,
            0.0011434369953349233,
            0.022783568128943443,
            0.015311035327613354,
            0.0155953923240304,
            -0.016750330105423927,
            0.03481237217783928,
            -0.008881828747689724,
            0.0175775196403265,
            0.014221098273992538,
            -0.016063425689935684,
            0.027098994702100754,
            -0.0006844063755124807,
            0.013980359770357609,
            -0.0017710566753521562,
            0.004585007671266794,
            -0.021961035206913948,
            0.023839224129915237,
            0.002950402209535241,
            -0.011048468761146069,
            0.007738794200122356,
            -0.02165783941745758,
            -0.022367943078279495,
            -0.022081350907683372,
            0.004797661677002907,
            -0.031059300526976585,
            -0.023674067109823227,
            0.028992867097258568,
            0.011429252102971077,
            -0.023183254525065422,
            -0.0384506918489933,
            -0.0042890929616987705,
            -0.012060725130140781,
            -0.035600826144218445,
            -0.009767990559339523,
            -0.030664412304759026,
            -0.021885961294174194,
            -0.0006032736273482442,
            0.005064956843852997,
            -0.010353031568229198,
            -0.02507299743592739,
            0.008652088232338428,
            0.0006348033202812076,
            0.009269976988434792,
            0.026705818250775337,
            -0.034209173172712326,
            -0.029441077262163162,
            0.027540694922208786,
            0.03229072317481041,
            -0.038543667644262314,
            0.011402931995689869,
            0.020390300080180168,
            -0.053097695112228394,
            -0.010469402186572552,
            -0.0015706794802099466,
            -0.029148254543542862,
            -0.025539375841617584,
            0.012020103633403778,
            0.015754863619804382,
            0.02321774512529373,
            0.01669505052268505,
            0.019945330917835236,
            0.008365723304450512,
            0.012209544889628887,
            -0.005964438896626234,
            0.005917592905461788,
            0.01791011169552803,
            -0.014427253045141697,
            0.010129408910870552,
            -0.019716445356607437,
            0.02129625901579857,
            -0.012281984090805054,
            -0.02194005623459816,
            -0.009788088500499725,
            0.0277938861399889,
            0.028885936364531517,
            0.032078951597213745,
            -0.017889898270368576,
            0.006862710230052471,
            -0.025745950639247894,
            -0.012507306411862373,
            0.027149470522999763,
            -0.02456584945321083,
            -0.029436981305480003,
            0.01893618330359459,
            0.024337952956557274,
            -0.011873955838382244,
            -0.018035704270005226,
            -0.029941117390990257,
            -0.010518483817577362,
            -0.0248442180454731,
            0.02190331555902958,
            0.013685330748558044,
            0.029265930876135826,
            -0.04754074290394783,
            -0.019468257203698158,
            0.005648147314786911,
            -0.01646036095917225,
            0.029866397380828857,
            0.008572789840400219,
            -0.00187781045679003,
            0.0057054846547544,
            0.005716189742088318,
            0.008897412568330765,
            -0.032389409840106964,
            0.0023700883612036705,
            -0.019974464550614357,
            -0.006836491636931896,
            -0.003308109939098358,
            0.02365754172205925,
            -0.007324534468352795,
            0.013864219188690186,
            -0.011778026819229126,
            -0.009548267349600792,
            -0.018172424286603928,
            0.010636517778038979,
            -0.007529175374656916,
            0.00978855974972248,
            0.015020311810076237,
            0.018556836992502213,
            -0.06440392136573792,
            0.00888421107083559,
            0.033516284078359604,
            -0.001532459631562233,
            0.028211336582899094,
            0.02038111723959446,
            0.014372570440173149,
            -0.01897498406469822,
            0.010479948483407497,
            0.022900201380252838,
            0.01256503164768219,
            -0.024123510345816612,
            0.028331711888313293,
            0.010139194317162037,
            -0.01850220374763012,
            -0.011366886086761951,
            0.024195829406380653,
            -0.0331757515668869,
            0.025127168744802475,
            -0.009136448614299297,
            -0.007508030626922846,
            -0.028344346210360527,
            0.026278017088770866,
            0.026656469330191612,
            -0.024875065311789513,
            0.030118437483906746,
            -0.01964789442718029,
            -0.0019484690856188536,
            -0.014381470158696175,
            -0.02913673408329487,
            -5.1464718126226217e-05,
            0.011722235009074211,
            -0.006914752069860697,
            -0.00760952103883028,
            -0.04268054664134979,
            0.03189599886536598,
            -0.0006743490812368691,
            0.014694128185510635,
            0.0266517773270607,
            -0.01895357109606266,
            0.04112356901168823,
            -0.0011945093283429742,
            0.026458485051989555,
            -0.018128301948308945,
            0.022312290966510773,
            -0.006128411274403334,
            0.014515304006636143,
            -0.019425731152296066,
            0.028590237721800804,
            -0.016002479940652847,
            0.01189566683024168,
            0.020357398316264153,
            -0.021847577765583992,
            -0.002130575245246291,
            -0.023264538496732712,
            -0.008619085885584354,
            0.014177048578858376,
            0.025129089131951332,
            -0.005786145571619272,
            0.002647256478667259,
            0.024893619120121002,
            -0.0022904397919774055,
            -0.005477378144860268,
            -5.659792077494785e-05,
            0.006109811831265688,
            0.006375771481543779,
            -0.06509444862604141,
            0.007278658915311098,
            -0.011671033687889576,
            0.014447418972849846,
            -0.015972215682268143,
            -0.013181154616177082,
            0.015945667400956154,
            -0.006190608721226454,
            0.007905866950750351,
            0.01480600330978632,
            0.017003783956170082,
            0.02770683355629444,
            0.0166559386998415,
            -0.015873484313488007,
            0.0017702130135148764,
            -0.030038245022296906,
            0.02314329333603382,
            -0.005989965051412582,
            0.0033752205781638622,
            0.010980583727359772,
            0.0018824372673407197,
            -0.013321894221007824,
            0.00232256599701941,
            -0.023798974230885506,
            0.0035467727575451136,
            0.003828568384051323,
            0.022925706580281258,
            0.01960037834942341,
            -0.029590006917715073,
            -0.00501600094139576,
            -0.009836586192250252,
            -0.04086144641041756,
            0.03194703161716461,
            0.014210432767868042,
            -0.06517612189054489,
            0.028625383973121643,
            0.0008544649463146925,
            -0.0030086066108196974,
            0.004807721823453903,
            0.01076180674135685,
            -0.038457877933979034,
            0.014651703648269176,
            0.012604882009327412,
            0.03385221213102341,
            -0.02691129967570305,
            -0.05775634944438934,
            -0.03384556248784065,
            0.036624569445848465,
            -0.051212090998888016,
            0.001807046472094953,
            -0.02419688180088997,
            -0.021184135228395462,
            -0.005977149121463299,
            -0.019865425303578377,
            -0.005717640742659569,
            0.022642824798822403,
            -0.028084535151720047,
            -0.024934081360697746,
            -0.016474852338433266,
            0.004224210511893034,
            -0.03574589639902115,
            0.009478184394538403,
            0.013773173093795776,
            -0.030342455953359604,
            -0.01615149900317192,
            -0.006962763611227274,
            -0.006382544990628958,
            0.00309504522010684,
            -0.024268263950943947,
            -0.017964191734790802,
            0.005778392311185598,
            -0.02857973240315914,
            0.009012151509523392,
            -0.007673794869333506,
            -0.01328631304204464,
            -0.025523250922560692,
            0.042834244668483734,
            0.028605280444025993,
            0.03783896937966347,
            0.0020395154133439064,
            0.04401322081685066,
            0.0045997025445103645,
            0.0011314705479890108,
            0.004986939020454884,
            0.011286339722573757,
            0.014699788764119148,
            0.012987159192562103,
            -0.03981410339474678,
            -0.009398865513503551,
            0.028220226988196373,
            0.023396071046590805,
            -0.02836545743048191,
            -0.008109299466013908,
            0.005129143130034208,
            -0.002035445300862193,
            0.001018885406665504,
            0.0029844644013792276,
            0.005130062345415354,
            -0.026018982753157616,
            0.023953450843691826,
            -0.01941223070025444,
            0.017409658059477806,
            -0.011966980062425137,
            -0.028537774458527565,
            -0.013887481763958931,
            0.008244821801781654,
            0.02459547109901905,
            -0.019725482910871506,
            -0.05287916958332062,
            0.004800500348210335,
            0.02990104630589485,
            -0.0009161243215203285,
            -0.010983417741954327,
            -0.007667794823646545,
            0.024958474561572075,
            0.030139129608869553,
            0.009447387419641018,
            0.015056067146360874,
            0.004866053815931082,
            0.029796604067087173,
            0.008081020787358284,
            0.03095025196671486,
            -0.0515066422522068,
            -0.03102899342775345,
            0.01866091601550579,
            -0.0015017417026683688,
            0.009264450520277023,
            -0.0077968197874724865,
            -0.022821007296442986,
            -0.024481602013111115,
            0.017156798392534256,
            0.004146406892687082,
            -0.013822084292769432,
            0.029643617570400238,
            0.022698739543557167,
            0.01198329497128725,
            -0.010155613534152508,
            0.005587886553257704,
            0.012341862544417381,
            -0.028365574777126312,
            -0.00594223290681839,
            0.018418146297335625,
            0.0216586422175169,
            0.015496893785893917,
            0.005969526711851358,
            0.031157687306404114,
            -0.037294644862413406,
            -0.03128286078572273,
            0.006004618480801582,
            0.026227712631225586,
            0.03328520059585571,
            0.016047870740294456,
            -0.009303159080445766,
            0.015284959226846695,
            0.024577364325523376,
            -0.006483747623860836,
            -0.02581486478447914,
            -0.025787727907299995,
            -0.0032223165035247803,
            0.001989212818443775,
            -0.014212919399142265,
            0.021934106945991516,
            0.013903005048632622,
            0.0004975511110387743,
            -0.033399783074855804,
            -0.09256463497877121,
            0.0005272095440886915,
            0.010156122036278248,
            0.010462310165166855,
            0.006100118160247803,
            -0.03743655979633331,
            0.027274616062641144,
            0.03202284500002861,
            -0.011747184209525585,
            0.014240240678191185,
            -0.045459941029548645,
            -0.010448326356709003,
            0.018199987709522247,
            -0.02915557660162449,
            0.02096054144203663,
            0.03536614030599594,
            0.0018907713238149881,
            0.028168680146336555,
            0.0034797501284629107,
            0.02655760757625103,
            0.03019385039806366,
            0.027065694332122803,
            -0.025619814172387123,
            0.01615036465227604,
            0.00809087511152029,
            -0.007939490489661694,
            0.011041421443223953,
            0.0027042198926210403,
            -0.014365756884217262,
            -0.03701687604188919,
            0.027425171807408333,
            -0.027364231646060944,
            -0.02602263353765011,
            -0.022882383316755295,
            -0.03817835450172424,
            -0.02722889557480812,
            0.03325468674302101,
            0.026101021096110344,
            -0.032632313668727875,
            -0.020331304520368576,
            0.0005198430153541267,
            -7.94716615928337e-05,
            -0.01039972621947527,
            0.01392524316906929,
            -0.03925986588001251,
            0.018464231863617897,
            -0.003888494800776243,
            0.007606102619320154,
            -0.029974879696965218,
            0.01211133599281311,
            -0.03183355554938316,
            0.011554320342838764,
            -0.023036634549498558,
            -0.013089085929095745,
            0.0011020199162885547,
            0.017488623037934303,
            0.019674941897392273,
            0.013079163618385792,
            -0.02778758853673935,
            0.033436957746744156,
            0.008206921629607677,
            -0.0037645655684173107,
            -0.021656040102243423,
            -0.005160593893378973,
            -0.022725623100996017,
            -0.02195921540260315,
            -0.000932016468141228,
            -0.017987430095672607,
            0.0028192857280373573,
            0.005211861804127693,
            0.04005217179656029,
            -0.035926174372434616,
            -0.0220322348177433,
            -0.01854422502219677,
            -0.029063772410154343,
            -0.0016363562317565084,
            0.03253387287259102,
            -0.05221449211239815,
            -0.013936317525804043,
            -0.006877885665744543,
            0.019467948004603386,
            -0.058829907327890396,
            0.036088693886995316,
            0.029648158699274063,
            -0.005040826741605997,
            -0.015280511230230331,
            0.02195224165916443,
            -0.022785646840929985,
            -0.016642466187477112,
            0.002569109434261918,
            -0.00951135903596878,
            0.04133347421884537,
            0.0309266559779644,
            0.008989368565380573,
            0.008046722039580345,
            0.015376138500869274,
            0.007756296545267105,
            -0.018860915675759315,
            -0.02016330510377884,
            -0.011197584681212902,
            0.028823256492614746,
            -0.018438143655657768,
            0.01662759855389595,
            -0.03357742354273796,
            -0.032558560371398926,
            -0.024364048615098,
            -0.028370773419737816,
            0.00321405241265893,
            -0.012891104444861412,
            0.03769008070230484,
            -0.018261117860674858,
            0.011908979155123234,
            0.023279042914509773,
            -0.015324977226555347,
            -0.0031436211429536343,
            -0.004178392235189676,
            -0.018072837963700294,
            -0.004385349340736866,
            -0.04082801192998886,
            -0.034386079758405685,
            -0.024705737829208374,
            0.013894358649849892,
            -0.013540305197238922,
            0.013676892034709454,
            0.014843614771962166,
            0.015704793855547905,
            0.0007635297370143235,
            0.016837578266859055,
            0.001001162570901215,
            0.01449241116642952,
            -0.010543493553996086,
            -0.031331486999988556,
            -0.015839753672480583,
            -0.02619081363081932,
            0.009927633218467236,
            0.00931738130748272,
            0.025427697226405144,
            0.03570794686675072,
            0.03449716791510582,
            -0.013712613843381405,
            0.013914092443883419,
            0.028604425489902496,
            -0.020082274451851845,
            0.012551818042993546,
            -0.014100354164838791,
            0.01978750340640545,
            -0.010917716659605503
        ],
        [
            0.005445833783596754,
            0.007326176390051842,
            0.03360748663544655,
            0.029851706698536873,
            0.02317275106906891,
            -0.023246025666594505,
            -0.03195876628160477,
            -0.03563113510608673,
            -0.004986261948943138,
            -0.006676757242530584,
            0.016552245244383812,
            0.023583125323057175,
            0.013442092575132847,
            0.0016725349705666304,
            0.014584128744900227,
            -0.010183995589613914,
            0.01291466224938631,
            -0.007384253665804863,
            -0.03292270377278328,
            -0.0156638752669096,
            -0.006238036323338747,
            0.03034614957869053,
            -0.008662567473948002,
            0.017522066831588745,
            -0.013057275675237179,
            0.019295761361718178,
            0.038932785391807556,
            -0.016687890514731407,
            0.04091877117753029,
            -0.018634043633937836,
            -0.01411917619407177,
            -0.010117036290466785,
            -0.02774713933467865,
            0.009326801635324955,
            0.012445526197552681,
            -0.00554310716688633,
            -0.013059229589998722,
            -0.004550487268716097,
            -0.007288355380296707,
            -0.02494489587843418,
            0.015249681659042835,
            -0.004012193065136671,
            -0.026593534275889397,
            -0.0034596638288348913,
            0.00539009552448988,
            0.019284415990114212,
            -0.011382833123207092,
            0.012481454759836197,
            -0.018726781010627747,
            0.025115016847848892,
            -0.018114183098077774,
            0.02545783296227455,
            0.012494990602135658,
            0.01480959914624691,
            -0.03539104387164116,
            0.01651666685938835,
            0.0161155853420496,
            0.006809869781136513,
            -0.031383320689201355,
            -0.0292152538895607,
            0.011829613707959652,
            -0.003157493658363819,
            -0.010999178513884544,
            0.011177551001310349,
            0.019025050103664398,
            -0.0269488412886858,
            0.02478744275867939,
            0.03231959417462349,
            0.014318431727588177,
            0.027743875980377197,
            0.021449293941259384,
            0.03236012905836105,
            0.027906697243452072,
            -0.023929692804813385,
            -0.037079889327287674,
            -0.012344014830887318,
            -0.020735861733555794,
            0.001173508120700717,
            0.0020519946701824665,
            0.015039801597595215,
            0.011527454480528831,
            -0.0074317194521427155,
            0.010937950573861599,
            0.025299115106463432,
            0.029173903167247772,
            0.022429553791880608,
            0.005134256556630135,
            0.0040128096006810665,
            -0.027460401877760887,
            -0.004313645884394646,
            -0.007760492619127035,
            0.019021466374397278,
            0.015881024301052094,
            0.01131762471050024,
            0.02279316633939743,
            -0.009314564056694508,
            -0.018025321885943413,
            0.013232642784714699,
            0.059131648391485214,
            -0.0001328852231381461,
            -0.013915281742811203,
            0.041555508971214294,
            0.03412095829844475,
            -0.01664033904671669,
            -0.02640579082071781,
            -0.04777766019105911,
            -0.016058415174484253,
            -0.003441655309870839,
            -0.03912567347288132,
            0.019630571827292442,
            -0.02283504046499729,
            0.009882256388664246,
            -0.027735330164432526,
            -0.022645944729447365,
            -0.039944715797901154,
            0.03157224506139755,
            -0.016856171190738678,
            0.047253213822841644,
            -0.01208688784390688,
            0.012983494438230991,
            -0.013016187585890293,
            0.023336635902523994,
            -0.010836191475391388,
            0.017180509865283966,
            0.017912760376930237,
            -0.004581038374453783,
            0.010411572642624378,
            0.0077453721314668655,
            0.025437159463763237,
            -0.01618347503244877,
            -0.005087078083306551,
            -0.017862554639577866,
            0.033818669617176056,
            -0.025291668251156807,
            -0.006537952926009893,
            0.03791288286447525,
            0.02499040588736534,
            -0.0012518269941210747,
            0.004265890922397375,
            -0.023062709718942642,
            0.01569525897502899,
            0.033885788172483444,
            -0.036619871854782104,
            0.021480390802025795,
            -0.020413290709257126,
            -0.03341703861951828,
            0.036205269396305084,
            0.011327706277370453,
            0.020848717540502548,
            0.013364535756409168,
            0.028214460238814354,
            -0.006335108075290918,
            -0.013722497038543224,
            -0.006418545730412006,
            -0.017661793157458305,
            -0.007607672829180956,
            -0.0013813805999234319,
            0.017992926761507988,
            -0.013514664024114609,
            0.03022901341319084,
            -0.0046384334564208984,
            -0.02682262286543846,
            -0.04689542204141617,
            -0.023779915645718575,
            0.014346349984407425,
            0.016301918774843216,
            0.00020253544789738953,
            -0.020128881558775902,
            0.03250904381275177,
            -0.011500733904540539,
            -0.018749075010418892,
            0.012096828781068325,
            -0.013300610706210136,
            0.0190142635256052,
            0.006932354066520929,
            0.01895095594227314,
            0.02367923967540264,
            -0.027918005362153053,
            -0.00606238329783082,
            0.004859162028878927,
            -0.02943282388150692,
            -0.00318849366158247,
            -0.021640494465827942,
            0.030944544821977615,
            -0.005489149130880833,
            -0.020206362009048462,
            0.003275353228673339,
            0.012510525062680244,
            -0.021391216665506363,
            -0.03226538375020027,
            0.02298148348927498,
            -0.0022137535270303488,
            0.018040621653199196,
            -0.013470059260725975,
            -0.0054359412752091885,
            0.00029526391881518066,
            -0.01278007123619318,
            0.008594727143645287,
            -0.0100214509293437,
            0.010245724581182003,
            0.030276477336883545,
            -0.006746166851371527,
            0.02882855385541916,
            0.011650687083601952,
            0.07145652920007706,
            -0.021327856928110123,
            -0.017403528094291687,
            -0.0011613863753154874,
            -0.012595843523740768,
            -0.017427468672394753,
            0.015932081267237663,
            0.005499729886651039,
            -0.0020832943264395,
            -0.03779394179582596,
            0.009131657890975475,
            -0.028053194284439087,
            0.0047367592342197895,
            0.010373768396675587,
            0.0073059843853116035,
            0.01806747168302536,
            0.0017883095424622297,
            0.03353032097220421,
            0.008583653718233109,
            0.03534327819943428,
            -0.0013409830862656236,
            0.048766471445560455,
            0.012094986625015736,
            0.01414590422064066,
            -0.012664799578487873,
            -0.0003753284690901637,
            0.010990594513714314,
            -0.029339486733078957,
            0.020594660192728043,
            0.018396126106381416,
            0.005203498527407646,
            0.011452765204012394,
            -0.020728513598442078,
            0.005453992169350386,
            0.01292714849114418,
            -0.02367486245930195,
            -0.02113061398267746,
            -0.002260838635265827,
            -0.00668345345184207,
            -0.02516309916973114,
            0.046926259994506836,
            0.022231122478842735,
            0.015723980963230133,
            0.023089706897735596,
            0.012195361778140068,
            0.020300211384892464,
            -0.015103203244507313,
            -0.004618571139872074,
            -0.009798114188015461,
            -0.03277112916111946,
            -0.001939796027727425,
            0.03274570778012276,
            -0.008666150271892548,
            0.012169014662504196,
            -0.022652078419923782,
            -0.013041635975241661,
            -0.0005575926625169814,
            0.019022494554519653,
            -0.008261880837380886,
            0.006184927653521299,
            0.015531692653894424,
            -0.042482033371925354,
            0.0155878234654665,
            0.03010484203696251,
            0.024405650794506073,
            -0.024427266791462898,
            0.004862832371145487,
            0.02661614865064621,
            -0.026645394042134285,
            0.020847324281930923,
            0.002611824544146657,
            0.004963493440300226,
            0.009015070274472237,
            0.020080259069800377,
            -0.029677651822566986,
            0.020676203072071075,
            0.02908608317375183,
            0.00438351184129715,
            0.009994890540838242,
            0.015768365934491158,
            -0.020846018567681313,
            0.02334578149020672,
            -0.012812369503080845,
            -0.05013582482933998,
            -0.018896933645009995,
            0.03329285979270935,
            -0.0159457940608263,
            -0.0005987806944176555,
            -0.025039421394467354,
            0.009771554730832577,
            0.03290544077754021,
            0.006984535604715347,
            0.011050653643906116,
            0.03654356673359871,
            0.0031453249976038933,
            0.0057909805327653885,
            0.012543600052595139,
            -0.005644309334456921,
            0.011898748576641083,
            -0.008410363458096981,
            0.0033134310506284237,
            0.03892701119184494,
            -0.02506740763783455,
            0.02637047879397869,
            0.00984138809144497,
            -0.03079264611005783,
            -0.02413702756166458,
            0.026543069630861282,
            0.02731919102370739,
            0.027062734588980675,
            -0.015536990016698837,
            0.025657862424850464,
            0.012828352861106396,
            0.0033504399470984936,
            0.002934893826022744,
            -0.009853677824139595,
            0.01721651665866375,
            0.05785347521305084,
            -0.003665800206363201,
            -0.034364499151706696,
            0.0018472091760486364,
            -0.004829881712794304,
            0.03267098590731621,
            0.02408744767308235,
            -0.020340856164693832,
            -0.004131294321268797,
            -0.00400446867570281,
            -0.0025843679904937744,
            -0.027384871616959572,
            0.01994234137237072,
            -0.014981547370553017,
            -0.015679558739066124,
            -0.03159322962164879,
            -0.01994563266634941,
            -0.00861089862883091,
            0.0019760781433433294,
            0.002880299463868141,
            0.019941532984375954,
            0.003271248657256365,
            -0.002791823586449027,
            -0.0064966450445353985,
            -0.043223343789577484,
            -0.020628299564123154,
            -0.019173694774508476,
            -0.012818756513297558,
            0.03342125192284584,
            0.004871353507041931,
            0.02162759192287922,
            0.014968883246183395,
            -0.014417652040719986,
            -0.010563444346189499,
            -0.026859983801841736,
            -0.021856803447008133,
            0.007456260733306408,
            0.024840611964464188,
            0.02721485309302807,
            0.02984512597322464,
            0.005471882410347462,
            -0.022007951512932777,
            0.011358732357621193,
            -0.023748472332954407,
            -0.023853817954659462,
            -0.01442119013518095,
            0.01196238026022911,
            0.022432904690504074,
            -0.031930096447467804,
            -0.023259086534380913,
            0.023098669946193695,
            -0.005240350030362606,
            0.023191630840301514,
            0.026629887521266937,
            -0.01388308871537447,
            -0.01456355769187212,
            0.0062566339038312435,
            0.01312323845922947,
            0.029441120103001595,
            0.04492751136422157,
            0.004274558275938034,
            -0.020881319418549538,
            -0.007385338190943003,
            0.020427998155355453,
            -0.04423322528600693,
            -0.016253476962447166,
            -0.036883700639009476,
            -0.025260522961616516,
            -0.0008858435903675854,
            0.030078710988163948,
            0.00028248431044630706,
            -0.0013168383156880736,
            -0.027439437806606293,
            0.0011785667156800628,
            -0.015409610234200954,
            0.0041713109239935875,
            0.05610186606645584,
            0.029148578643798828,
            -0.0011977440444752574,
            -0.011102343909442425,
            0.017836404964327812,
            -0.009166710078716278,
            -0.028507372364401817,
            0.013158188201487064,
            0.006013799924403429,
            0.015334838069975376,
            0.014689203351736069,
            -0.02978747896850109,
            -0.02612973190844059,
            0.008941211737692356,
            -0.04030691087245941,
            0.010939011350274086,
            0.04111146926879883,
            0.02365822158753872,
            -0.022479979321360588,
            0.0027676152531057596,
            -0.007866760715842247,
            -0.037882447242736816,
            -0.02540374919772148,
            -0.030556023120880127,
            0.026869146153330803,
            -0.012554673478007317,
            -0.0016038732137531042,
            -0.006426680367439985,
            -0.013198516331613064,
            0.031728215515613556,
            0.018903227522969246,
            0.0015153896529227495,
            0.02666815184056759,
            0.02483728714287281,
            -0.0005337876500561833,
            -0.01486886665225029,
            0.007486128713935614,
            0.02375844307243824,
            0.027461126446723938,
            0.0031603407114744186,
            0.010816174559295177,
            0.01604551449418068,
            -0.01028774306178093,
            -0.009332682006061077,
            -0.0075441999360919,
            0.02759159542620182,
            0.007560152094811201,
            0.01580420695245266,
            -0.029200078919529915,
            -0.017067058011889458,
            -0.006850329227745533,
            -9.614458394935355e-05,
            -0.0022308167535811663,
            -0.008431007154285908,
            0.02867334708571434,
            -0.007799518760293722,
            -0.024227028712630272,
            0.032094087451696396,
            0.01543508842587471,
            -0.01969018206000328,
            0.034482043236494064,
            0.0034194861073046923,
            0.027470720931887627,
            0.038416098803281784,
            -0.015512024983763695,
            -0.009433421306312084,
            0.0008730438421480358,
            0.010865134187042713,
            0.02038501761853695,
            0.010202224366366863,
            0.021374357864260674,
            0.01673278957605362,
            0.02066485397517681,
            -0.021126743406057358,
            0.00030266543035395443,
            -0.013705370016396046,
            0.020969249308109283,
            -0.014643369242548943,
            0.021040063351392746,
            0.00918017327785492,
            -0.044900692999362946,
            -0.024811821058392525,
            -0.013199694454669952,
            0.028527211397886276,
            -0.007956095039844513,
            -0.01528842281550169,
            0.0026988715399056673,
            -0.03423500806093216,
            0.0006091354298405349,
            0.020178528502583504,
            -0.01840094104409218,
            -0.025035081431269646,
            0.0034662180114537477,
            -0.012836325913667679,
            -0.018869420513510704,
            0.008014853112399578,
            0.012770486064255238,
            -0.014893262647092342,
            0.020933279767632484,
            0.0036242117639631033,
            0.03066624142229557,
            0.014173542149364948,
            0.008724432438611984,
            0.024248676374554634,
            0.035965222865343094,
            -0.02909282222390175,
            0.01685119792819023,
            0.014495162293314934,
            -0.009385272860527039,
            -0.004171374719589949,
            0.009010755456984043,
            -0.017683280631899834,
            -0.024926286190748215,
            0.011724300682544708,
            -0.014580417424440384,
            -0.029126610606908798,
            -0.01704946905374527,
            -0.012330529280006886,
            0.022656556218862534,
            -0.021570676937699318,
            -0.0017875045305117965,
            0.0010708002373576164,
            0.020508764311671257,
            0.02980932593345642,
            -0.005819560028612614,
            0.012446253560483456,
            -0.0049314796924591064,
            -0.0017211881931871176,
            0.01209963858127594,
            0.0034416739363223314,
            0.010634318925440311,
            0.023329995572566986,
            0.03892873227596283,
            0.012173817493021488,
            0.02295944094657898,
            0.01173554640263319,
            -0.021303780376911163,
            -0.0019899127073585987,
            0.02731305919587612,
            -0.019927335903048515,
            0.018225321546196938,
            0.008884313516318798,
            0.016804078593850136,
            -0.010919947177171707,
            0.006971700582653284,
            0.027405615895986557,
            0.03703776374459267,
            0.015557514503598213,
            -7.751134944555815e-06,
            0.005220543593168259,
            -0.03844717890024185,
            0.004186017904430628,
            -0.043279677629470825,
            -0.009654736146330833,
            -0.016501203179359436,
            -0.026487354189157486,
            0.015440871007740498,
            -6.410043715732172e-05,
            0.00032938842196017504,
            -0.04681434854865074,
            0.005695756059139967,
            0.028135286644101143,
            0.021499620750546455,
            0.011898939497768879,
            -0.022492583841085434,
            0.01784546487033367,
            0.004180689342319965,
            0.008679023012518883,
            -0.010373794473707676,
            -0.002131420187652111,
            0.017690610140562057,
            0.027523381635546684,
            0.0030159573070704937,
            -0.03452382981777191,
            0.010679800063371658,
            0.0327465757727623,
            -0.028942221775650978,
            -0.005329332780092955,
            -0.0009399060509167612,
            0.006810767576098442,
            0.0323953852057457,
            0.019917601719498634,
            0.02758343517780304,
            0.0046994369477033615,
            0.015939446166157722,
            -0.01725531555712223,
            -0.005524280481040478,
            -0.02757362276315689,
            0.015072472393512726,
            -0.005889696069061756,
            0.011754196137189865,
            -0.020969346165657043,
            0.011622701771557331,
            0.002466117963194847,
            -0.007993790321052074,
            0.010523425415158272,
            0.006113030947744846,
            0.006855564657598734,
            0.001405810471624136,
            0.002785488497465849,
            -0.005029020830988884,
            -0.018753834068775177,
            0.02297980710864067,
            -0.010363871231675148,
            -0.02340415120124817,
            -0.017339590936899185,
            -0.0009583119535818696,
            -0.0059256358072161674,
            -0.03399535268545151,
            0.015893427655100822,
            0.010362307541072369,
            -0.021528594195842743,
            -0.012207437306642532,
            0.03368077799677849,
            0.01661454141139984,
            -0.024248875677585602,
            0.007873770780861378,
            0.0008200978045351803,
            -0.017666734755039215,
            -0.03113139234483242,
            0.04037941247224808,
            -0.003517121309414506,
            0.005819675512611866,
            -0.008903348818421364,
            0.009165243245661259,
            -0.031144190579652786,
            0.002108275657519698,
            0.022339314222335815,
            0.006111491937190294,
            0.01728365756571293,
            0.004531307611614466,
            -0.008416586555540562,
            0.00950406864285469,
            0.012615999206900597,
            -0.020630765706300735,
            -0.00840796623378992,
            0.015276559628546238,
            0.0007680976414121687,
            -0.0017631810624152422,
            -0.0258038192987442,
            0.025201817974448204,
            0.020800378173589706,
            -0.0240597166121006,
            -0.023928791284561157,
            0.02808477357029915,
            -0.033421967178583145,
            0.00257868692278862,
            -0.009685758501291275,
            -0.017438625916838646,
            0.0003146681119687855,
            0.017238926142454147,
            -0.026436753571033478,
            0.003178162733092904,
            -0.010942080058157444,
            0.02790427766740322,
            -0.003035981208086014,
            -0.03110583685338497,
            0.005765065085142851,
            0.005925610661506653,
            0.03535296022891998,
            0.0074509140104055405,
            0.04250986501574516,
            -0.04883623123168945,
            -0.005663373041898012,
            0.02418140321969986,
            -0.028912758454680443,
            0.013276185840368271,
            0.0003508673107717186,
            0.019357474520802498,
            0.0009976883884519339,
            0.022817373275756836,
            0.030860625207424164,
            -0.01780773513019085,
            0.02630053088068962,
            0.002814259845763445,
            0.01035044901072979,
            -0.01735864020884037,
            0.02461150288581848,
            -0.023128964006900787,
            -0.017505599185824394,
            0.016978899016976357,
            -0.03896461799740791,
            -0.030660169199109077,
            -0.024807773530483246,
            -0.010358047671616077,
            -0.00422830693423748,
            0.017742028459906578,
            -0.010947109200060368,
            -0.011417840607464314,
            0.00703529454767704,
            0.0016896803863346577,
            -0.021364780142903328,
            0.02588745206594467,
            0.014129957184195518,
            -0.02401912771165371,
            0.038976311683654785,
            0.03429865092039108,
            0.010834090411663055,
            -0.028336310759186745,
            -0.0005035248468630016,
            -0.023754585534334183,
            0.006742261350154877,
            -0.009320252574980259,
            0.00213754759170115,
            0.03619186207652092,
            0.00335485115647316,
            0.009302166290581226,
            0.020738553255796432,
            -0.005389564670622349,
            0.004942831117659807,
            -0.012954752892255783,
            0.014657571911811829,
            0.012747244909405708,
            0.006909992545843124,
            -0.012176557444036007,
            -0.007022544741630554,
            -0.03149235621094704,
            -0.02318066731095314,
            0.02573305182158947,
            -0.04373462125658989,
            0.021513361483812332,
            -0.024318493902683258,
            -0.010861793532967567,
            -0.006329950410872698,
            0.006201339419931173,
            0.008827711455523968,
            0.01501262467354536,
            -0.007515874691307545,
            0.022737862542271614,
            -0.025964202359318733,
            -0.0027700872160494328,
            -0.01730170100927353,
            -0.005142903421074152,
            0.04230684041976929,
            0.0081021124497056,
            0.019168732687830925,
            -0.017162898555397987,
            0.007853700779378414,
            0.017510375007987022,
            0.01917019672691822,
            0.0238934438675642,
            0.02667287364602089,
            0.026291321963071823,
            0.004735844675451517,
            -0.007058633025735617,
            -0.023640437051653862,
            0.027709204703569412,
            0.025706054642796516,
            0.026901518926024437,
            -0.03600141406059265,
            0.018023831769824028,
            -0.01451834011822939,
            -0.00952142383903265,
            0.0038857448380440474,
            0.03125912696123123,
            -0.019770264625549316,
            -0.02813950926065445,
            0.02546265907585621,
            0.002707192674279213,
            0.03231688588857651,
            -0.04643518105149269,
            -0.055149976164102554,
            0.01826510764658451,
            0.01280950102955103,
            0.047577593475580215,
            -0.010318606160581112,
            -0.00027481341385282576,
            0.024702847003936768,
            -0.03209994360804558,
            -0.015957443043589592,
            -0.015221614390611649,
            -0.022762339562177658,
            -0.03171179071068764,
            -0.004945296794176102,
            -0.003182669635862112,
            -0.01109891664236784,
            0.026895539835095406,
            0.029970692470669746,
            -0.027390852570533752,
            0.0424068421125412,
            -0.041041359305381775,
            0.02439112216234207,
            -0.03251798450946808,
            0.013431943953037262,
            -0.029556259512901306,
            0.03825492411851883,
            -0.028892068192362785,
            -0.022114992141723633,
            0.012103445827960968,
            0.0003032117383554578,
            -0.027466358616948128,
            -0.013660121709108353,
            -0.01585463620722294,
            -0.014749659225344658,
            0.0053760395385324955,
            0.010767900384962559,
            0.022135229781270027,
            0.053610458970069885,
            0.0021930248476564884,
            0.019118428230285645,
            0.025817081332206726,
            0.009126520715653896,
            -0.011526129208505154,
            -0.02656041830778122,
            -0.01985824666917324,
            0.03277420625090599,
            0.021384449675679207,
            0.011870728805661201,
            0.02489410527050495,
            0.006074012257158756,
            -0.031370773911476135,
            0.0017794260056689382,
            -0.020181138068437576,
            0.0057230377569794655,
            -0.02492794394493103,
            0.0052642785012722015,
            -0.0043123760260641575,
            0.02800191566348076,
            -0.004790705628693104,
            -0.024329377338290215,
            0.03222320228815079,
            0.011490315198898315,
            0.005618118215352297,
            -0.00884921383112669,
            0.0105682872235775,
            0.0132304010912776,
            0.020912103354930878,
            0.016994815319776535,
            -0.0034219520166516304,
            0.035758744925260544,
            0.03102056123316288,
            -0.03203977644443512,
            0.0258221123367548,
            0.01455135177820921,
            -0.021779373288154602,
            0.03360802307724953,
            0.012826921418309212,
            -0.03827160969376564,
            -0.027125416323542595,
            -0.015365100465714931,
            -0.003665994154289365,
            0.009044887498021126,
            0.008740142919123173,
            0.020979465916752815,
            -0.0001616173394722864,
            0.0002526583266444504,
            -0.023791149258613586,
            -0.018968546763062477,
            0.01358781848102808,
            -0.03389425203204155,
            -0.017915667966008186,
            -0.02155783399939537,
            0.019275985658168793,
            0.03103158064186573,
            0.005285123363137245,
            -0.0024095375556498766,
            -0.028626007959246635,
            0.0020332313142716885,
            0.01484135165810585,
            -0.014569173566997051,
            0.004968252498656511,
            0.015775008127093315,
            0.02019639126956463,
            -0.03348689153790474,
            -0.02979958802461624,
            -0.005450108554214239,
            0.03740960359573364,
            -0.02455724962055683,
            0.023489011451601982,
            -0.005397265311330557,
            0.023989366367459297,
            0.030934954062104225,
            -0.002371451584622264,
            0.017813699319958687,
            0.009461335837841034,
            0.005876394920051098,
            0.0197787843644619,
            0.029625438153743744,
            -0.011106446385383606,
            -0.023608442395925522,
            -0.048192098736763,
            -0.015178732573986053,
            -0.027261558920145035,
            -0.003919097129255533,
            -0.020052563399076462,
            0.003890272928401828,
            -0.036145612597465515,
            0.026941128075122833,
            0.02633449248969555,
            0.020453767850995064,
            -0.06759636104106903,
            0.017362091690301895,
            0.026595931500196457,
            0.0020206712651997805,
            -0.002769673941656947,
            -0.009768364951014519,
            0.007488654460757971,
            0.03409017622470856,
            0.009680712595582008,
            0.004201051313430071,
            -0.013086770661175251,
            -0.021210575476288795,
            0.01938149891793728,
            0.03143226355314255,
            -0.02232351340353489,
            0.0005172033561393619,
            0.019198240712285042,
            0.0036974838003516197,
            -0.02006964199244976,
            -0.024489454925060272,
            0.012819269672036171,
            0.02868930995464325,
            -0.0014415576588362455,
            -0.03136533498764038,
            0.017896583303809166,
            0.023079445585608482,
            0.01412136759608984,
            -0.031796567142009735,
            -0.01570432074368,
            0.011212969198822975,
            0.0032680800650268793,
            0.004733457695692778,
            -0.008855112828314304,
            -0.02371201664209366,
            0.019797949120402336,
            -0.029426179826259613,
            -0.020216811448335648,
            -0.008529577404260635,
            -0.0035399040207266808,
            -0.001708694500848651,
            -0.034477412700653076,
            0.026979496702551842,
            -0.020572589710354805,
            -0.022508610039949417,
            0.003106821095570922,
            -0.02103693224489689,
            0.028195517137646675,
            -0.00857345201075077,
            -0.004886385053396225,
            -0.013882894068956375,
            0.012919208034873009,
            0.02624453790485859,
            0.020559657365083694,
            -0.0012690143194049597,
            0.03384682163596153,
            0.0016129793366417289,
            0.002220790134742856,
            -0.027328871190547943,
            0.023266980424523354,
            0.03329434245824814,
            -0.01708892732858658,
            0.024520738050341606,
            -0.024462053552269936,
            -0.02502145990729332,
            -0.01335668470710516,
            -0.02295798808336258,
            -0.03007330931723118,
            -0.021337810903787613,
            0.029047062620520592,
            -0.018502548336982727,
            -0.008185341954231262,
            0.025370119139552116,
            -0.03346453234553337,
            0.012169758789241314,
            0.023125139996409416,
            -0.00170972291380167,
            -0.009144395589828491,
            0.009228832088410854,
            0.02868567779660225,
            0.01357230730354786,
            0.01759992353618145,
            0.024545900523662567,
            -0.02256603352725506,
            -0.02197898179292679,
            -2.162082091672346e-05,
            0.006251977756619453,
            0.01771112158894539,
            0.0259707048535347,
            0.005775533150881529,
            0.02160501852631569,
            -0.028161631897091866,
            0.004117563832551241,
            0.018259858712553978,
            -0.02305545099079609,
            -0.010095066390931606,
            0.03957981616258621,
            0.003871292807161808,
            0.005211889278143644,
            0.010512539185583591,
            0.002295874757692218,
            -0.03478720784187317,
            0.008519872091710567,
            -0.019324958324432373,
            -0.030633041635155678,
            0.007593388203531504,
            0.01890363171696663,
            0.025273270905017853,
            0.02024008333683014,
            0.013523572124540806,
            0.022783230990171432,
            -0.011918540112674236,
            0.03248795494437218,
            -0.01772778108716011,
            0.020806893706321716,
            0.023621167987585068,
            0.01447527389973402,
            0.02484518475830555,
            0.02969031035900116,
            -0.009789335541427135,
            -0.010348014533519745,
            -0.0014580878196284175,
            -0.001164932968094945,
            0.012492367997765541,
            -0.0046837530098855495,
            0.0406026765704155,
            0.006073588039726019,
            0.0034708459861576557,
            0.017549660056829453,
            0.03445559740066528,
            0.02662595920264721,
            -0.021471645683050156,
            0.013969071209430695,
            0.007935982197523117,
            0.017918430268764496,
            -0.00039226844091899693,
            -0.027820812538266182,
            0.01980840414762497
        ],
        [
            -0.0032992903143167496,
            0.02248017117381096,
            -0.007934845983982086,
            -0.013239194639027119,
            0.02562806010246277,
            -0.031015809625387192,
            -0.011958673596382141,
            -0.02354162186384201,
            0.018367545679211617,
            -0.027647435665130615,
            -0.03643151745200157,
            0.03200944513082504,
            0.009712811559438705,
            -0.015871180221438408,
            -0.04884243384003639,
            0.020533401519060135,
            0.01525798998773098,
            -0.017346203327178955,
            0.028119809925556183,
            -0.006879779044538736,
            -0.039725515991449356,
            0.013488168828189373,
            -0.028634952381253242,
            0.03474731370806694,
            -0.025012405589222908,
            0.004779511597007513,
            0.02035919576883316,
            -0.009134931489825249,
            0.004711003974080086,
            -0.01384853944182396,
            -0.012989839538931847,
            -0.016470491886138916,
            -0.024714795872569084,
            -0.02667917311191559,
            -0.012419805862009525,
            0.0048627955839037895,
            0.005313520319759846,
            -0.012627269141376019,
            0.02471201866865158,
            0.00018142243789043278,
            -0.0004834858700633049,
            -0.01760757528245449,
            0.031199349090456963,
            0.00020799526828341186,
            0.033348746597766876,
            0.007818296551704407,
            -0.012095697224140167,
            -0.006859692744910717,
            0.028587251901626587,
            -0.004761888645589352,
            0.024672674015164375,
            0.023254578933119774,
            -0.0038187000900506973,
            -0.012418725527822971,
            -0.01601106859743595,
            0.017069799825549126,
            -0.02151906117796898,
            -0.021023781970143318,
            -0.00950328167527914,
            -0.005686832591891289,
            -0.02130948379635811,
            -0.004134026356041431,
            -0.012176016345620155,
            0.011220610700547695,
            -0.033449687063694,
            -0.01051496621221304,
            -0.03946336731314659,
            -0.022778335958719254,
            -0.016751468181610107,
            0.007270068395882845,
            -0.02828279510140419,
            -0.020742198452353477,
            -0.023584892973303795,
            -0.009978726506233215,
            0.0005278272437863052,
            0.014019842259585857,
            -0.0366414412856102,
            0.02271418273448944,
            -0.02014749124646187,
            0.029656467959284782,
            -0.02809290774166584,
            -0.018751652911305428,
            0.011860284022986889,
            0.016429075971245766,
            0.008539676666259766,
            -0.0032954567577689886,
            0.014605021104216576,
            -0.0314607173204422,
            0.01192545983940363,
            -0.026020076125860214,
            0.015184340998530388,
            -0.0019353000679984689,
            -0.018873373046517372,
            -0.014772336930036545,
            0.005301435012370348,
            0.01859789341688156,
            -0.0011913245543837547,
            -0.022807598114013672,
            -0.047041021287441254,
            0.017552150413393974,
            0.02603827975690365,
            -0.019906964153051376,
            -0.03400805592536926,
            0.000571535318158567,
            -0.0068695577792823315,
            0.041402556002140045,
            0.04012306407094002,
            0.04719872027635574,
            -0.008512101136147976,
            0.00949985533952713,
            -0.024413080886006355,
            -0.022096779197454453,
            0.01638459600508213,
            -0.024871788918972015,
            0.025184445083141327,
            0.02398383989930153,
            -0.026672691106796265,
            -0.01505470834672451,
            0.012985401786863804,
            0.009165163151919842,
            -0.02656986005604267,
            0.026645515114068985,
            -0.02656819298863411,
            0.009450293146073818,
            0.005951823201030493,
            -0.011390517465770245,
            -0.020565392449498177,
            0.0054873209446668625,
            -0.017100781202316284,
            -0.007023096550256014,
            0.0289093479514122,
            -0.017987724393606186,
            0.02505112811923027,
            -0.0448128879070282,
            -0.0018417018000036478,
            0.02813657745718956,
            -0.012164805084466934,
            -0.009288636967539787,
            -0.005196342710405588,
            -0.0006631644209846854,
            0.0150424400344491,
            -0.023877402767539024,
            0.0036609331145882607,
            0.020864084362983704,
            0.018862411379814148,
            -0.026022082194685936,
            0.0018476595869287848,
            0.01609709858894348,
            -0.005754377692937851,
            0.02922860160470009,
            0.012581393122673035,
            -0.0006031306693330407,
            0.022001205012202263,
            0.02353411726653576,
            0.025052521377801895,
            0.010363345965743065,
            -0.004527998622506857,
            0.03033253736793995,
            0.022433310747146606,
            -0.01907971128821373,
            0.004817978013306856,
            -0.002031188691034913,
            -0.0017526857554912567,
            -0.02823680080473423,
            -0.01064903661608696,
            0.005470496602356434,
            0.025075096637010574,
            0.015204041264951229,
            -0.0188485998660326,
            0.018415631726384163,
            0.016525423154234886,
            0.029257778078317642,
            -0.014822608791291714,
            -0.014347286894917488,
            -0.005772129632532597,
            0.0035862319637089968,
            0.008311468176543713,
            0.0024113624822348356,
            0.010979159735143185,
            -0.0029169400222599506,
            0.03925499692559242,
            -0.028012048453092575,
            -0.005804450251162052,
            0.002580892061814666,
            0.010731973685324192,
            -0.00723009230569005,
            0.0273284949362278,
            -0.018788175657391548,
            0.022129682824015617,
            0.023232746869325638,
            0.024316955357789993,
            0.0013999493094161153,
            -0.010012517683207989,
            0.0010210676118731499,
            -0.02546059712767601,
            -0.017765024676918983,
            0.0270076934248209,
            0.001110837678425014,
            -0.0032588534522801638,
            0.012109657749533653,
            0.011642680503427982,
            -0.02136041410267353,
            -0.014072899706661701,
            -0.011884684674441814,
            -0.04898839071393013,
            -0.023992693051695824,
            -0.01813935488462448,
            -0.01995108276605606,
            0.027957919985055923,
            -0.023511776700615883,
            -0.015779882669448853,
            0.0018886817852035165,
            -9.801890882954467e-06,
            0.010264736600220203,
            -0.021739987656474113,
            0.00959525816142559,
            0.01803404837846756,
            0.018772955983877182,
            0.01058129034936428,
            0.02575303055346012,
            0.011895637959241867,
            0.006286190822720528,
            0.02598598226904869,
            0.011902421712875366,
            0.009788928553462029,
            0.0561336874961853,
            -0.0023241317830979824,
            0.015484048053622246,
            -0.023764463141560555,
            -0.01068851538002491,
            0.01289275847375393,
            0.02509918063879013,
            0.01640692725777626,
            0.025216272100806236,
            -0.015941906720399857,
            0.01692984625697136,
            -0.0183580219745636,
            0.02269618771970272,
            -0.007487258408218622,
            -0.02034597471356392,
            0.027215875685214996,
            0.025065433233976364,
            0.02755698747932911,
            -0.0012668432900682092,
            0.00036558604915626347,
            -0.018220696598291397,
            0.030710211023688316,
            -0.0023513557389378548,
            0.006954710930585861,
            -0.02581472136080265,
            -0.008002720773220062,
            -0.008277874439954758,
            0.013013389892876148,
            -0.010112427175045013,
            -0.019520102068781853,
            -0.005251067690551281,
            0.0005667406949214637,
            -0.009439371526241302,
            0.021842045709490776,
            0.019416706636548042,
            0.0028808240313082933,
            0.02311483398079872,
            -0.019755356013774872,
            -0.011547737754881382,
            0.014376448467373848,
            0.014420442283153534,
            -0.0054073031060397625,
            0.024637209251523018,
            0.01595926657319069,
            -0.00625159265473485,
            -0.02378019690513611,
            0.012659326195716858,
            -0.024218278005719185,
            0.013351640664041042,
            0.01909463107585907,
            0.007756381295621395,
            -0.03299114853143692,
            0.02034742571413517,
            0.02828351967036724,
            0.029809802770614624,
            0.014386176131665707,
            -0.015502410009503365,
            0.004656497854739428,
            -0.006629283539950848,
            0.02082790806889534,
            0.00017600576393306255,
            0.017510807141661644,
            0.0019522854126989841,
            0.012205858714878559,
            0.020349137485027313,
            0.011041240766644478,
            -0.0077860974706709385,
            0.010326248593628407,
            0.02396264299750328,
            -0.006335726007819176,
            -0.014643264003098011,
            0.001725999522022903,
            -0.01606154814362526,
            -0.000330991402734071,
            0.007819633930921555,
            -0.01661037467420101,
            -0.027861537411808968,
            0.022698519751429558,
            0.020104261115193367,
            -0.025034690275788307,
            0.009754774160683155,
            0.01750561036169529,
            -0.027452049776911736,
            0.04018497094511986,
            0.013689960353076458,
            0.04173767566680908,
            0.028320148587226868,
            0.008418564684689045,
            0.019057877361774445,
            -0.013562776148319244,
            0.03218519687652588,
            0.023862749338150024,
            0.021482521668076515,
            0.0048529235646128654,
            0.025318706408143044,
            0.025464864447712898,
            -0.021823516115546227,
            -0.021547960117459297,
            0.03511358052492142,
            0.014253107830882072,
            -0.029792090877890587,
            -0.025345616042613983,
            0.011648128740489483,
            -0.020489241927862167,
            -0.00991432461887598,
            -0.00916608702391386,
            -0.0009999627945944667,
            -0.007037611678242683,
            -0.011174923740327358,
            0.006304434034973383,
            -0.007404131814837456,
            0.004199765156954527,
            -0.019127508625388145,
            -0.013873578980565071,
            -0.0074493675492703915,
            0.0007932544685900211,
            -0.0008274935535155237,
            0.009962964802980423,
            0.032918158918619156,
            -0.019528964534401894,
            0.03643138334155083,
            0.01561603881418705,
            0.006880217231810093,
            0.02446044236421585,
            0.02338450588285923,
            -0.009979017078876495,
            0.02589484676718712,
            0.0400175005197525,
            -0.01706911250948906,
            -0.00222254591062665,
            0.01175945345312357,
            -0.02499946765601635,
            0.029564982280135155,
            -0.005327969323843718,
            -0.004128576256334782,
            -0.0103600537404418,
            0.003502332605421543,
            0.00870939064770937,
            -0.006729493848979473,
            -0.014277954585850239,
            0.030162012204527855,
            -0.02295420877635479,
            -0.021592890843749046,
            0.016437729820609093,
            -0.01636403426527977,
            0.035316549241542816,
            0.011208765208721161,
            -0.03361915796995163,
            -0.00949056725949049,
            0.0029216501861810684,
            -0.004971364047378302,
            -0.024792106822133064,
            -0.0087828254327178,
            0.02712509036064148,
            -0.01579170487821102,
            -0.0034252724144607782,
            0.026198860257864,
            -0.016196949407458305,
            -0.023852739483118057,
            -0.027468260377645493,
            0.0021254983730614185,
            0.004614268429577351,
            0.018906351178884506,
            -0.014072624035179615,
            -9.118260641116649e-05,
            -0.005197947844862938,
            -0.01951589807868004,
            -0.017924245446920395,
            0.019789177924394608,
            -0.022860679775476456,
            0.013738533481955528,
            -0.028649697080254555,
            0.005821566097438335,
            0.018338518217206,
            0.019866308197379112,
            -0.009965847246348858,
            -0.0019802027381956577,
            0.026528453454375267,
            -0.021167336031794548,
            0.009510704316198826,
            -0.01838507130742073,
            -0.016018737107515335,
            0.043698057532310486,
            0.019203277304768562,
            0.014175091870129108,
            0.007018831092864275,
            0.003837266005575657,
            0.0332985483109951,
            0.011122002266347408,
            -0.005256451200693846,
            0.023975159972906113,
            -0.02384219877421856,
            0.027102859690785408,
            -0.0194858331233263,
            0.01786380633711815,
            0.030444564297795296,
            0.031642716377973557,
            0.00181358412373811,
            0.024183742702007294,
            -0.0360550694167614,
            0.021954447031021118,
            0.030982403084635735,
            -0.0010819832095876336,
            -0.004008610267192125,
            0.021019795909523964,
            -0.00587636511772871,
            -0.019306980073451996,
            0.01082391943782568,
            0.0065214913338422775,
            0.025376519188284874,
            0.019158944487571716,
            -0.03366090729832649,
            0.025405224412679672,
            -0.014405756257474422,
            0.027076957747340202,
            0.03399860858917236,
            -0.004331514239311218,
            -0.02123166434466839,
            0.01503435242921114,
            -0.013956243172287941,
            0.0051064747385680676,
            0.018195865675807,
            0.005092367064207792,
            -0.01996605284512043,
            -0.01500999741256237,
            -0.01576351560652256,
            0.009291443973779678,
            -0.009272904135286808,
            -0.008720687590539455,
            -0.027156226336956024,
            -0.003239770419895649,
            0.01542371790856123,
            -0.0010317531414330006,
            -0.00010636792285367846,
            0.008082618936896324,
            -0.011482004076242447,
            0.004411817993968725,
            0.018875444307923317,
            -0.012719926424324512,
            -0.023879511281847954,
            0.027132099494338036,
            0.002007292816415429,
            0.012011316604912281,
            -0.019208913668990135,
            0.03515808656811714,
            -0.009328434243798256,
            -0.00919381994754076,
            0.005482618696987629,
            0.00732942670583725,
            0.01110574696213007,
            -0.013698755763471127,
            0.01812327466905117,
            0.021206509321928024,
            -0.011178607121109962,
            -0.023600859567523003,
            0.004939098376780748,
            -0.015862563624978065,
            -0.017822520807385445,
            0.023912224918603897,
            0.015664495527744293,
            0.006421437952667475,
            -0.005531645379960537,
            0.019113585352897644,
            -0.018556488677859306,
            0.015053512528538704,
            0.02081139385700226,
            0.023277973756194115,
            0.014793605543673038,
            -0.03663726896047592,
            0.006716415751725435,
            0.03344440087676048,
            0.01380578987300396,
            0.017784222960472107,
            0.034008800983428955,
            -0.024495277553796768,
            0.010340405628085136,
            0.013722329400479794,
            0.012044849805533886,
            0.0032555691432207823,
            0.02832069620490074,
            0.012578658759593964,
            0.02694046124815941,
            -0.02408803626894951,
            0.018488945439457893,
            -0.008473926223814487,
            0.0255734845995903,
            0.01549607515335083,
            -0.015081818215548992,
            -0.02470913901925087,
            0.002308801282197237,
            -0.015847813338041306,
            -0.02403796836733818,
            0.04047521948814392,
            0.030760951340198517,
            0.0015671196160838008,
            0.016972443088889122,
            0.02940012700855732,
            -0.01437691505998373,
            -0.004404087550938129,
            -0.03536740690469742,
            -0.02781093493103981,
            -0.0406651571393013,
            0.015773052349686623,
            0.029414506629109383,
            -0.015036295168101788,
            -0.022639483213424683,
            0.0186171792447567,
            -0.012314977124333382,
            0.003044344484806061,
            0.019404469057917595,
            -0.003949879202991724,
            -0.014719794504344463,
            0.0078036789782345295,
            0.0177097599953413,
            0.0021247840486466885,
            0.031176336109638214,
            -0.0008300966001115739,
            -0.0391416922211647,
            0.01725420542061329,
            -0.028037002310156822,
            -0.038852714002132416,
            -0.0007276991964317858,
            0.03286566585302353,
            0.018489928916096687,
            0.040977511554956436,
            0.031031355261802673,
            0.01399709191173315,
            0.0009842681465670466,
            0.002111546229571104,
            -0.01785879395902157,
            -0.007834806106984615,
            -0.013972905464470387,
            -0.014945432543754578,
            0.032193463295698166,
            0.03428879752755165,
            -0.02760136127471924,
            -0.006141125224530697,
            -0.011782601475715637,
            -0.0031196759082376957,
            0.028994150459766388,
            0.017547806724905968,
            -0.0026121833361685276,
            -0.00014975098019931465,
            0.01746468059718609,
            -0.01837690733373165,
            -0.0031340476125478745,
            0.03130625933408737,
            0.0026888649445027113,
            -0.009736445732414722,
            0.029797783121466637,
            -0.019866419956088066,
            -0.02072751522064209,
            0.006929062772542238,
            -0.003457074286416173,
            -0.022245177999138832,
            0.0007080732611939311,
            -0.023499617353081703,
            -0.014333930797874928,
            -0.0045171333476901054,
            -0.030431054532527924,
            0.012034595012664795,
            -0.01359767559915781,
            -0.00219425605610013,
            0.009716615080833435,
            0.0006842229049652815,
            0.04173837602138519,
            0.02801510877907276,
            -0.001684422604739666,
            -0.0011320797493681312,
            -0.014933368191123009,
            0.0021012190263718367,
            -0.023906182497739792,
            -0.0015953732654452324,
            -0.0020680504385381937,
            -0.006090864073485136,
            0.007531117182224989,
            0.029379408806562424,
            0.011096316389739513,
            0.03053041361272335,
            0.019213583320379257,
            -0.011220615357160568,
            0.003954666666686535,
            -0.01078922301530838,
            -0.02647271379828453,
            -0.00795857235789299,
            0.014316098764538765,
            0.01879972219467163,
            -0.02659258246421814,
            -0.011325276456773281,
            -0.0011148862540721893,
            -0.0005751957069151103,
            -0.011349529027938843,
            -0.015915479511022568,
            0.03822700306773186,
            -0.018552027642726898,
            -0.014252301305532455,
            -0.008079937659204006,
            -0.022216495126485825,
            0.0007246275781653821,
            0.030319426208734512,
            -0.010860896669328213,
            0.003907390404492617,
            0.030441969633102417,
            0.0006061060703359544,
            0.024289816617965698,
            -0.0062280865386128426,
            0.0222032368183136,
            -0.027906784787774086,
            -0.024885933846235275,
            0.007909337058663368,
            -0.0023477838840335608,
            0.0012531157117336988,
            -0.0028633459005504847,
            0.01855153776705265,
            0.02122965082526207,
            0.008119232952594757,
            0.029949268326163292,
            0.0376366525888443,
            0.0028287998866289854,
            0.019015101715922356,
            -0.007882917299866676,
            0.01759945973753929,
            -0.0018164735520258546,
            -0.0012071839300915599,
            0.02652420662343502,
            0.008480085991322994,
            0.023206036537885666,
            0.008667847141623497,
            -0.02198118157684803,
            -0.01916579343378544,
            -0.020456958562135696,
            -0.011305555701255798,
            0.02524956874549389,
            0.01594088040292263,
            0.004399826284497976,
            0.008437590673565865,
            0.006462660618126392,
            -0.0036425034049898386,
            0.010586857795715332,
            -0.02529522031545639,
            -0.013987011276185513,
            0.011475708335638046,
            -0.020260121673345566,
            0.02652333676815033,
            -0.03340689465403557,
            -0.04931510612368584,
            0.037417761981487274,
            0.0004709120839834213,
            0.008885590359568596,
            0.013423388823866844,
            -0.01084031444042921,
            -0.01246236078441143,
            -0.01238417997956276,
            -0.0027204249054193497,
            -0.016238518059253693,
            0.006284614559262991,
            0.02373698726296425,
            0.018567662686109543,
            -0.02132582664489746,
            -0.020287055522203445,
            0.02117055468261242,
            -0.015564873814582825,
            -0.023261411115527153,
            0.024785116314888,
            -0.0033978039864450693,
            -0.019801445305347443,
            0.015384289436042309,
            0.022364092990756035,
            0.012684743851423264,
            -0.01834181137382984,
            0.015370307490229607,
            0.0020128374453634024,
            0.03297707810997963,
            -0.010656405240297318,
            0.028459815308451653,
            -0.004235688131302595,
            -0.028690721839666367,
            -0.009369887411594391,
            0.0307209063321352,
            0.0029275461565703154,
            -0.03741884231567383,
            0.020264143124222755,
            -0.011714340187609196,
            -0.024801770225167274,
            -0.0053945560939610004,
            0.004404288716614246,
            0.0153016597032547,
            0.0063084871508181095,
            -0.009655985981225967,
            -0.005070432089269161,
            -0.0016250315820798278,
            -0.029724787920713425,
            0.004552717320621014,
            -0.030614232644438744,
            0.020526424050331116,
            -0.014082815498113632,
            -0.019419997930526733,
            0.0010732219088822603,
            0.014698182232677937,
            0.010104650631546974,
            0.03297402337193489,
            0.020381875336170197,
            0.008831522427499294,
            0.022262170910835266,
            -0.0013832019176334143,
            -0.007364433724433184,
            0.029189227148890495,
            0.012707453221082687,
            0.010187633335590363,
            0.023198125883936882,
            -0.011869333684444427,
            0.017489079385995865,
            -0.026634246110916138,
            0.03375469893217087,
            -0.0009264488471671939,
            -0.008126532658934593,
            -0.021418575197458267,
            -0.01825610175728798,
            -0.018285904079675674,
            -0.005736981984227896,
            -0.014993280172348022,
            -0.012676323764026165,
            0.031020332127809525,
            -0.0173280518501997,
            -0.006114157382398844,
            0.0201711505651474,
            -0.002303390996530652,
            0.028069965541362762,
            0.002506539924070239,
            -0.0013781992020085454,
            -0.011954325251281261,
            0.022166121751070023,
            0.003536203410476446,
            0.02261093631386757,
            0.025592941790819168,
            0.03391652926802635,
            0.015158864669501781,
            -0.009390383958816528,
            0.01737160235643387,
            0.026334255933761597,
            -0.014127476140856743,
            -0.004129190929234028,
            0.025242969393730164,
            -0.0217409860342741,
            0.027729598805308342,
            0.004016640596091747,
            -0.012164912186563015,
            0.01397751085460186,
            0.03851860761642456,
            0.02815334126353264,
            -0.01008570846170187,
            -0.038451388478279114,
            -0.010556818917393684,
            -0.01662267930805683,
            -0.011929119937121868,
            0.00875314325094223,
            -0.017642945051193237,
            -0.00689636031165719,
            -0.01248715166002512,
            0.025918157771229744,
            -0.010635508224368095,
            0.03148747235536575,
            0.0011287870584055781,
            -0.032441116869449615,
            -0.03577471897006035,
            0.023282332345843315,
            -0.0010342479217797518,
            0.0007818238227628171,
            0.0008312570280395448,
            -0.046821340918540955,
            -0.007737281732261181,
            0.013020715676248074,
            0.005820977035909891,
            0.026572052389383316,
            0.012327036820352077,
            0.027158230543136597,
            0.010021783411502838,
            -0.01975345052778721,
            -0.04171926900744438,
            0.02133641205728054,
            -0.016233695670962334,
            0.01784001663327217,
            -0.015609764494001865,
            0.023498818278312683,
            -0.0048634959384799,
            0.003792596748098731,
            0.017436694353818893,
            -0.013023645617067814,
            0.015153396874666214,
            -0.015775473788380623,
            0.005816993303596973,
            0.0029237696435302496,
            0.033865638077259064,
            -0.006935496814548969,
            0.005584804806858301,
            -0.02964714914560318,
            -0.005878962576389313,
            -0.021919285878539085,
            -0.028986286371946335,
            -0.03481960669159889,
            -0.0014403657987713814,
            0.0033301538787782192,
            -0.019337039440870285,
            0.0023808039259165525,
            -0.024440135806798935,
            0.016244592145085335,
            -0.02339538000524044,
            -0.022820839658379555,
            0.02209831029176712,
            0.018831145018339157,
            0.0024985705967992544,
            0.027224015444517136,
            -0.00449301628395915,
            -0.00026091004838235676,
            0.027757931500673294,
            -0.016653049737215042,
            0.015209021978080273,
            -0.01627677120268345,
            -0.019078899174928665,
            -0.024907372891902924,
            0.020921172574162483,
            -0.01915391907095909,
            -0.020543422549962997,
            0.03654675558209419,
            0.021819669753313065,
            -0.005244158674031496,
            0.02832612209022045,
            -0.008814769797027111,
            0.015468169003725052,
            0.000967717613093555,
            0.006155719514936209,
            -0.03173607587814331,
            -0.012743860483169556,
            -0.035826366394758224,
            -0.01772543042898178,
            0.03284168615937233,
            -0.009208615869283676,
            -0.012276744470000267,
            -0.04008442535996437,
            -0.016462281346321106,
            0.01370486430823803,
            0.018511926755309105,
            -0.008274959400296211,
            0.007149112410843372,
            -0.010998628102242947,
            -0.014238212257623672,
            0.01621025986969471,
            -0.05082014203071594,
            -0.016609560698270798,
            0.04537944495677948,
            0.004276528023183346,
            0.004569497425109148,
            -0.005532197188585997,
            0.03076907992362976,
            0.007322120014578104,
            -0.019594883546233177,
            0.006404955871403217,
            -0.05077401176095009,
            0.01021360233426094,
            -0.008155432529747486,
            0.022945772856473923,
            -0.013801664113998413,
            -0.04758180305361748,
            -0.02376687526702881,
            -0.014773435890674591,
            -0.013345718383789062,
            0.02987596020102501,
            -0.03278512880206108,
            0.03362496942281723,
            -0.04131939262151718,
            -0.015507171861827374,
            0.024468962103128433,
            -0.023615164682269096,
            0.03541076183319092,
            0.004559007473289967,
            -0.015041832812130451,
            -0.02071470580995083,
            0.0034231971949338913,
            0.00266108731739223,
            0.03057597018778324,
            0.006040138658136129,
            -0.016009967774152756,
            0.0035105030983686447,
            -0.02112334407866001,
            -0.020162733271718025,
            -0.018734145909547806,
            -0.024749716743826866,
            0.0222791600972414,
            0.032899048179388046,
            0.029953330755233765,
            0.017143312841653824,
            -0.011950205080211163,
            -0.010320884175598621,
            0.031047243624925613,
            -0.020966390147805214,
            0.019268324598670006,
            -0.029915975406765938,
            -0.031608957797288895,
            0.014947697520256042,
            -0.02288087271153927,
            -0.016859764233231544,
            0.00819931086152792,
            0.002701530698686838,
            0.029564639553427696,
            -0.006376645062118769,
            -0.016890395432710648,
            -0.001025622128508985,
            -0.014125180430710316,
            0.012647416442632675,
            -0.035555288195610046,
            0.013875075615942478,
            0.023682689294219017,
            -0.00033774765324778855,
            0.009627127088606358,
            0.010818881914019585,
            -0.01825033500790596,
            0.011121115647256374,
            0.0022605680860579014,
            -0.005992320831865072,
            0.008018998429179192,
            0.0003259704390075058,
            0.018270807340741158,
            0.029503775760531425,
            0.008661235682666302,
            0.013351867906749249,
            0.0051561747677624226,
            0.029470108449459076,
            0.0011168135097250342,
            -0.007827489636838436,
            -0.013285700231790543,
            0.018157852813601494,
            -0.013235486112535,
            0.0005150926881469786,
            0.012885764241218567,
            -0.02860415354371071,
            0.00537886144593358,
            -0.01596624031662941,
            0.0014248976949602365,
            -0.007227207068353891,
            -0.024547098204493523,
            -0.030972037464380264,
            -0.03012101911008358,
            0.026735683903098106,
            -0.03542529419064522,
            -0.0033270788844674826,
            -0.004934985656291246,
            -0.018949849531054497,
            0.0303523950278759,
            0.016068708151578903,
            -0.010806913487613201,
            0.03486643731594086,
            0.024894963949918747,
            0.007974022999405861,
            -0.015182578936219215,
            0.02632436715066433,
            0.0047591207548975945,
            0.03267314285039902,
            -0.0269570741802454,
            -0.00820094533264637,
            0.011929932050406933,
            -0.013454551808536053,
            0.04116617143154144,
            0.006347210146486759,
            -0.026201672852039337,
            -0.022428303956985474,
            -0.009912601672112942,
            0.03344506770372391,
            -0.022236380726099014,
            0.030527152121067047,
            -0.018663296476006508,
            0.01236382219940424,
            0.009286604821681976,
            0.008366035297513008,
            0.02559996396303177,
            -0.017715392634272575,
            0.026062171906232834,
            0.0018594124121591449,
            0.028402211144566536,
            -0.006008680444210768,
            -0.017473870888352394,
            -0.022555381059646606,
            0.01652281917631626,
            0.017101552337408066,
            0.02335682325065136,
            0.00399601086974144,
            0.02232557162642479,
            -0.010532571002840996,
            -0.009483938105404377,
            -0.014825182966887951,
            0.02806970290839672,
            0.006504251156002283,
            -0.012182663194835186,
            0.027364473789930344,
            0.024591417983174324,
            -0.013378117233514786,
            0.011040141806006432,
            -0.00023660718579776585,
            -0.019130313768982887,
            -0.020252930000424385
        ]
    ],
    "linear_relu_stack.2.bias": [
        -0.012314612045884132,
        -0.008624853566288948,
        0.025251396000385284,
        -0.03030373714864254,
        0.027829011902213097,
        0.031169380992650986,
        0.015284896828234196,
        -0.00598203856498003,
        -0.012988526374101639,
        -0.02165832743048668,
        -0.007242079824209213,
        0.02607746794819832,
        -0.02682720310986042,
        0.0017594365635886788,
        -0.02735774591565132,
        0.0018862232100218534,
        0.0030524234753102064,
        -0.009946255944669247,
        -0.02312377654016018,
        0.004022486042231321,
        -0.002738082315772772,
        -0.004953417927026749,
        0.016684753820300102,
        0.01678784005343914,
        0.007523639593273401,
        -0.015589159913361073,
        -0.015861274674534798,
        0.003123251488432288,
        -0.014245612546801567,
        0.01898259110748768,
        -0.02990216575562954,
        -0.029928987845778465,
        -0.017695192247629166,
        0.02902841567993164,
        -0.028141211718320847,
        0.025601718574762344,
        0.00498815206810832,
        0.029275674372911453,
        0.0165125522762537,
        -0.03601815924048424,
        0.015672162175178528,
        -0.013112539425492287,
        -0.0022979960776865482,
        0.02124856971204281,
        0.026868576183915138,
        0.013794313184916973,
        0.022163039073348045,
        0.030069127678871155,
        0.0008917894447222352,
        0.03178374469280243,
        -0.024698520079255104,
        -0.024437928572297096,
        0.026175925508141518,
        0.020482178777456284,
        -0.013194209896028042,
        0.02577715925872326,
        -0.012714192271232605,
        -0.019026339054107666,
        0.002327249851077795,
        -0.02700398862361908,
        0.015816157683730125,
        0.00048199683078564703,
        0.02062585949897766,
        0.01992419920861721,
        0.009207598865032196,
        0.01669430546462536,
        -0.005587269086390734,
        0.015168722718954086,
        -0.02085711993277073,
        -0.02684333547949791,
        -0.019483644515275955,
        -0.014909278601408005,
        0.013574407435953617,
        0.011873225681483746,
        -0.017637699842453003,
        0.0028642562683671713,
        0.016604352742433548,
        0.033435799181461334,
        0.0031277111265808344,
        0.01790579780936241,
        -0.00018353044288232923,
        0.0012650571297854185,
        0.007143876980990171,
        -0.027382217347621918,
        0.015339714474976063,
        -0.02747242897748947,
        0.003116717329248786,
        -0.010509435087442398,
        0.0004090213915333152,
        0.0033748408313840628,
        0.020926276221871376,
        -0.012108750641345978,
        0.02730473503470421,
        -0.032097477465867996,
        -0.020745856687426567,
        0.003622775198891759,
        -0.022197047248482704,
        -0.021653035655617714,
        0.0011117714457213879,
        0.008390098810195923,
        0.005518684629350901,
        -0.01600305549800396,
        -0.03201263025403023,
        0.019950473681092262,
        -0.019828055053949356,
        -0.0017134672962129116,
        -0.006442021578550339,
        0.007105640601366758,
        0.024553999304771423,
        0.012879008427262306,
        -0.004090938251465559,
        -0.0192811731249094,
        -0.018368668854236603,
        0.01329401321709156,
        0.009344453923404217,
        0.013769990764558315,
        0.02481856383383274,
        -0.02266749180853367,
        0.008600719273090363,
        -0.019940737634897232,
        0.018468743190169334,
        -0.02598630264401436,
        -0.0187878105789423,
        0.023101158440113068,
        0.013978013768792152,
        -0.025187866762280464,
        -0.013120027258992195,
        0.02000158280134201,
        -0.016371572390198708,
        -0.016722770407795906,
        -0.027351217344403267,
        -0.025456512346863747,
        -0.016997061669826508,
        -0.013972179032862186,
        -0.01913776993751526,
        0.01102132722735405,
        0.005889817140996456,
        0.02669149823486805,
        -0.003800792619585991,
        -0.015127269551157951,
        -0.02308902144432068,
        0.010193613357841969,
        0.00041908060666173697,
        -0.03349902853369713,
        0.02126980759203434,
        0.003802479011937976,
        -0.002082592574879527,
        -0.0338347852230072,
        0.01030559092760086,
        0.012583714909851551,
        0.00963538233190775,
        -0.005826917476952076,
        -0.01671791262924671,
        0.02073504589498043,
        -0.029116174206137657,
        0.019940346479415894,
        0.01912345178425312,
        0.0336989164352417,
        0.029915355145931244,
        0.027297789230942726,
        -0.023423559963703156,
        -0.01995592750608921,
        -0.020764969289302826,
        -0.008490470238029957,
        -0.020701222121715546,
        -0.0010559875518083572,
        -0.013120339252054691,
        -0.0008417060598731041,
        0.009921606630086899,
        -0.014014722779393196,
        -0.0024829073809087276,
        0.012864398770034313,
        0.027023693546652794,
        0.0372462160885334,
        0.02754002995789051,
        0.013935482129454613,
        0.011780411005020142,
        0.01000022329390049,
        0.0031894203275442123,
        0.02872917801141739,
        0.009650779888033867,
        -0.022346951067447662,
        -0.020462127402424812,
        -0.014615931548178196,
        0.02757706306874752,
        0.019783467054367065,
        -0.014336777850985527,
        -0.016198936849832535,
        0.00767012033611536,
        0.021329205483198166,
        -0.0295589379966259,
        0.01427565049380064,
        0.014450791291892529,
        0.00316608021967113,
        0.0023177480325102806,
        0.026906335726380348,
        0.022742051631212234,
        -0.029226401820778847,
        -0.00864038523286581,
        0.019132034853100777,
        0.0038765298668295145,
        0.01692284271121025,
        0.01431835163384676,
        0.011469446122646332,
        0.018127838149666786,
        -0.0015707918209955096,
        -0.0024638522882014513,
        0.01901799999177456,
        -0.018360229209065437,
        -0.011066450737416744,
        0.0026300426106899977,
        0.01926051825284958,
        0.027253499254584312,
        0.026829075068235397,
        0.025792740285396576,
        -0.01809629797935486,
        0.004077252000570297,
        0.02799723856151104,
        0.0290539488196373,
        -0.02102823182940483,
        -0.013152527622878551,
        -0.014159725978970528,
        -0.020784875378012657,
        0.020707249641418457,
        0.008776097558438778,
        0.02940448187291622,
        -0.025477953255176544,
        -0.009004349820315838,
        0.027537712827324867,
        -0.009534303098917007,
        0.00413119699805975,
        0.008419846184551716,
        0.0013275619130581617,
        0.002407100750133395,
        0.009568527340888977,
        -0.008706468157470226,
        0.013072828762233257,
        -0.025872493162751198,
        -0.019713666290044785,
        -0.01598033495247364,
        0.031708475202322006,
        0.023188447579741478,
        0.0014100940898060799,
        0.00584244541823864,
        -0.003838407341390848,
        -0.016998231410980225,
        0.023940719664096832,
        -0.028441712260246277,
        -0.0030749859288334846,
        -0.018349308520555496,
        0.005032109562307596,
        -0.001793274306692183,
        0.01210473757237196,
        -0.01206477452069521,
        0.01626056246459484,
        0.014828917570412159
    ],
    "linear_relu_stack.4.weight": [
        [
            -0.07624393701553345,
            -0.04607719928026199,
            0.02731713466346264,
            -0.08710334450006485,
            0.002176001202315092,
            -0.042355380952358246,
            0.02366040088236332,
            -0.041122112423181534,
            -0.10633985698223114,
            0.00988555047661066,
            -0.012886340729892254,
            0.034236226230859756,
            0.0034232428297400475,
            -0.054679106920957565,
            0.05945058912038803,
            0.026435410603880882,
            0.0073486617766320705,
            0.05289644002914429,
            -0.11881665140390396,
            -0.05698183923959732,
            -0.01296671386808157,
            0.08479610085487366,
            -0.07400520145893097,
            -0.053819507360458374,
            -0.007865602150559425,
            0.0822683796286583,
            0.04161197319626808,
            -0.028637751936912537,
            -0.07594813406467438,
            0.08455782383680344,
            -0.0374797023832798,
            0.026398424059152603,
            0.023254921659827232,
            -0.06268376111984253,
            -0.0352073609828949,
            -0.03496525064110756,
            0.08753295987844467,
            0.02484429068863392,
            -0.04571361467242241,
            -0.11406601965427399,
            -0.06754165142774582,
            0.02623325027525425,
            0.06609796732664108,
            0.03898092359304428,
            -0.009139526635408401,
            0.054540958255529404,
            0.07504978775978088,
            -0.052260130643844604,
            0.02365146018564701,
            -0.10765867680311203,
            0.014741138555109501,
            0.07952997833490372,
            0.06616426259279251,
            -6.371986819431186e-05,
            0.04848118871450424,
            -0.08405065536499023,
            0.14429640769958496,
            -0.020412886515259743,
            0.03651978448033333,
            0.00740223191678524,
            0.0789177417755127,
            -0.0498921163380146,
            0.0707404837012291,
            0.012960618361830711,
            -0.02389444224536419,
            -0.00831172987818718,
            0.06689539551734924,
            -0.06368227303028107,
            0.013401428237557411,
            -0.009612185880541801,
            -0.03825831785798073,
            -0.05204807594418526,
            0.031549122184515,
            -0.04331762343645096,
            0.038939036428928375,
            -0.06764664500951767,
            -0.04227343946695328,
            -0.04366626217961311,
            0.0802021399140358,
            0.00039143607136793435,
            -0.08044875413179398,
            -0.03636869788169861,
            0.03670615330338478,
            -0.011558718048036098,
            -0.09433868527412415,
            -0.06186958774924278,
            -0.06236393377184868,
            -0.021983671933412552,
            0.03190963715314865,
            0.07209807634353638,
            -0.02132234536111355,
            0.02111078053712845,
            0.0033567941281944513,
            -0.018380040302872658,
            0.02336355857551098,
            0.013306939043104649,
            0.0036478694528341293,
            -0.1153722032904625,
            -0.03360866755247116,
            0.05996309965848923,
            0.008403569459915161,
            -0.028018133714795113,
            -0.13377715647220612,
            0.08329474925994873,
            -0.02966674417257309,
            0.04671448469161987,
            0.06362465769052505,
            -0.10174974054098129,
            -0.028067924082279205,
            0.027190804481506348,
            0.06311479955911636,
            0.025813711807131767,
            -0.07258313149213791,
            -0.07667554914951324,
            -0.06497716158628464,
            -0.07388297468423843,
            -0.04831746965646744,
            0.04718060791492462,
            -0.0641130656003952,
            -0.05216992273926735,
            0.08304890990257263,
            -0.001870426582172513,
            -0.06376242637634277,
            -0.023857731372117996,
            0.03468293324112892,
            0.06604459136724472,
            0.004849527031183243,
            0.0401778519153595,
            -0.04724149405956268,
            -0.040703002363443375,
            0.06753958016633987,
            -0.010420003905892372,
            0.0033512720838189125,
            -0.051682669669389725,
            0.07492293417453766,
            0.009334581904113293,
            0.0016620849492028356,
            0.06577335298061371,
            -0.08526884764432907,
            0.05979064106941223,
            -0.0798066034913063,
            0.06819425523281097,
            0.05249648541212082,
            -0.037465132772922516,
            -0.017357703298330307,
            -0.042066410183906555,
            0.017841236665844917,
            0.0073624346405267715,
            0.08727432042360306,
            0.015795793384313583,
            -0.030195442959666252,
            -0.09219109266996384,
            0.012892955914139748,
            0.04284873977303505,
            0.04727676883339882,
            0.016836069524288177,
            0.07229814678430557,
            -0.03580247610807419,
            -0.0720866397023201,
            -0.0604744553565979,
            0.025538349524140358,
            0.0014941823901608586,
            0.010237918235361576,
            0.06443090736865997,
            0.0631195604801178,
            0.037330884486436844,
            0.014895947650074959,
            -0.025913191959261894,
            -0.05054304376244545,
            0.06566890329122543,
            0.049634017050266266,
            0.0415089875459671,
            0.0036731900181621313,
            0.05425130948424339,
            -0.06794154644012451,
            0.056912098079919815,
            0.05523356795310974,
            -0.05625614896416664,
            -0.03572612255811691,
            -0.04383225366473198,
            0.022246118634939194,
            0.0016633165068924427,
            -0.03602151572704315,
            0.0936891958117485,
            0.0034290996845811605,
            0.0022966794203966856,
            -0.022397931665182114,
            -0.01936003752052784,
            0.0331365242600441,
            0.022929366677999496,
            0.007158081512898207,
            0.02340020053088665,
            -0.0449160635471344,
            0.004340714775025845,
            -0.06761938333511353,
            -0.015759460628032684,
            -0.08570489287376404,
            0.07256263494491577,
            0.008212764747440815,
            0.04174134135246277,
            0.06368890404701233,
            -0.06303106993436813,
            0.10273534804582596,
            -0.027050407603383064,
            0.07022233307361603,
            -0.060635410249233246,
            -0.04340656101703644,
            0.05221600458025932,
            0.05027033016085625,
            0.046355798840522766,
            0.04822691157460213,
            0.11731240153312683,
            0.031257327646017075,
            0.010111497715115547,
            0.014672411605715752,
            -0.1088680550456047,
            -0.01285555399954319,
            0.05426677316427231,
            -0.024614155292510986,
            0.07769189774990082,
            -0.039835598319768906,
            0.0684453696012497,
            0.02043214626610279,
            -0.01595800369977951,
            0.06748421490192413,
            -0.05122748762369156,
            -0.06862301379442215,
            -0.04376855120062828,
            -0.04855598136782646,
            -0.035308919847011566,
            -0.05557830631732941,
            0.09704495966434479,
            0.04257269203662872,
            0.052863266319036484,
            0.019514519721269608,
            0.03984261304140091,
            -0.05364452302455902,
            0.019684655591845512,
            0.03706105053424835,
            -0.09455204755067825,
            0.05377190187573433,
            -0.05011327937245369,
            -0.013654702343046665,
            -0.048957936465740204,
            0.056420646607875824,
            0.06538064032793045,
            -0.06518088281154633,
            0.008250710554420948,
            0.03581370785832405,
            -0.10432037711143494,
            0.0454644151031971,
            0.03269986808300018,
            0.014281593263149261,
            -0.0895783007144928,
            -0.021472889930009842,
            0.021414514631032944
        ],
        [
            0.002111731795594096,
            -0.013367901556193829,
            0.05195572227239609,
            0.06731246411800385,
            -0.04115604981780052,
            -0.04727528616786003,
            -0.03939666971564293,
            -0.10010796040296555,
            -0.06160964071750641,
            0.04799335077404976,
            -0.1241844892501831,
            -0.02631504088640213,
            -0.053064946085214615,
            -0.04133278876543045,
            -0.09292586892843246,
            -0.03799700736999512,
            0.02544151060283184,
            0.007120917085558176,
            0.09229280799627304,
            0.06601438671350479,
            0.07432020455598831,
            -0.07048841565847397,
            -0.03626454994082451,
            0.0016746234614402056,
            -0.004785437602549791,
            -0.01852177456021309,
            0.0419032983481884,
            0.09001116454601288,
            0.05775797739624977,
            0.0008321993518620729,
            0.06914032995700836,
            -0.028044510632753372,
            -0.06666763126850128,
            0.09811238944530487,
            0.03348579630255699,
            0.007012173533439636,
            0.009916452690958977,
            0.04699104279279709,
            -0.060414690524339676,
            -0.005590885411947966,
            0.009155603125691414,
            0.04288605973124504,
            -0.053874868899583817,
            0.025134252384305,
            -0.032202430069446564,
            -0.004628257360309362,
            -0.028260989114642143,
            0.05314984917640686,
            -0.006955335848033428,
            -0.08155877143144608,
            0.07024778425693512,
            -0.008522571995854378,
            -0.009097137488424778,
            0.06029922887682915,
            0.05425342917442322,
            -0.040897104889154434,
            0.020287085324525833,
            0.03519435226917267,
            -0.09988078474998474,
            0.04728241264820099,
            -0.08368882536888123,
            0.03316150978207588,
            -0.03254536911845207,
            0.05268540233373642,
            0.02949453331530094,
            -0.08275376260280609,
            0.04786086827516556,
            -0.043009839951992035,
            -0.05901990830898285,
            -0.056676942855119705,
            0.18058012425899506,
            0.015078042633831501,
            -0.0827280804514885,
            0.0685875415802002,
            -0.013829073868691921,
            0.05888616293668747,
            0.033761560916900635,
            -0.01572362706065178,
            0.06861316412687302,
            0.05884166434407234,
            0.06277977675199509,
            -0.05094992369413376,
            0.019253000617027283,
            0.023100992664694786,
            0.09608659893274307,
            0.06223859637975693,
            -0.059549279510974884,
            -0.003934247884899378,
            -0.013140248134732246,
            0.011276260949671268,
            -0.015200944617390633,
            -0.0010805368656292558,
            -0.04132550582289696,
            0.04632888734340668,
            -0.12582886219024658,
            0.03726177290081978,
            0.009940871968865395,
            -0.07796383649110794,
            0.060128793120384216,
            -0.06083959713578224,
            -0.0616321787238121,
            -0.0008361666696146131,
            0.003794872434809804,
            -0.11035139858722687,
            -0.01952841691672802,
            0.0486983023583889,
            0.04255448281764984,
            -0.06404388695955276,
            0.05929487198591232,
            0.00901580136269331,
            -0.07222546637058258,
            0.03725158050656319,
            -0.006988328881561756,
            0.07049568742513657,
            0.008066833019256592,
            0.11621809005737305,
            0.03990684822201729,
            0.005898917093873024,
            0.013249797746539116,
            0.047291647642850876,
            -0.009015210904181004,
            0.06615346670150757,
            0.03426050767302513,
            -0.08026643842458725,
            -0.00011535314115462825,
            0.016791753470897675,
            0.012740625999867916,
            -0.007687462493777275,
            0.0737309381365776,
            0.09124774485826492,
            0.0456286184489727,
            0.040655285120010376,
            0.017605550587177277,
            -0.02621331624686718,
            -0.049744751304388046,
            -0.004520293325185776,
            -0.07591061294078827,
            0.028100093826651573,
            -0.04739990457892418,
            0.04781670868396759,
            0.02558806724846363,
            0.015908371657133102,
            -0.030276358127593994,
            -0.07574442028999329,
            -0.0982055813074112,
            -0.05994552746415138,
            0.044245362281799316,
            -0.04880925267934799,
            -0.0934867337346077,
            -0.07475583255290985,
            -0.017862755805253983,
            -0.09155956655740738,
            0.05884954333305359,
            0.05448693037033081,
            -0.02268795110285282,
            0.06774549186229706,
            -0.05025096237659454,
            0.013054220005869865,
            -0.0024579393211752176,
            -0.0036963929887861013,
            -0.05309663712978363,
            0.04597698524594307,
            -0.062253437936306,
            -0.03251536190509796,
            -0.0030013162177056074,
            -0.013967209495604038,
            -0.017134932801127434,
            0.08760208636522293,
            -0.0794711634516716,
            -0.0569005124270916,
            -0.04676147922873497,
            -0.01161395013332367,
            0.07499519735574722,
            0.051705554127693176,
            0.07374401390552521,
            0.016364751383662224,
            -0.06573117524385452,
            0.10124250501394272,
            0.053481947630643845,
            -0.057353779673576355,
            -0.07984846085309982,
            -0.05878448486328125,
            -0.04006170108914375,
            -0.07711862772703171,
            0.007814554497599602,
            -0.055035900324583054,
            0.0636015385389328,
            0.06182517856359482,
            -0.10685212165117264,
            0.05338649824261665,
            0.01772933453321457,
            -0.045690786093473434,
            -0.07871410995721817,
            -0.07686570286750793,
            0.018490979447960854,
            0.00785352848470211,
            -0.02308075688779354,
            0.0327022522687912,
            0.05781467258930206,
            -0.08324024081230164,
            0.05650727078318596,
            0.07648786157369614,
            -0.014245428144931793,
            0.03726158291101456,
            -0.04152681306004524,
            -0.02780548855662346,
            -0.061881911009550095,
            -0.050509486347436905,
            0.004962076898664236,
            -0.031280841678380966,
            -0.0849515050649643,
            -0.022974329069256783,
            -0.0053314389660954475,
            0.04855547472834587,
            0.05712496489286423,
            -0.06652330607175827,
            -0.03743048757314682,
            -0.010766411200165749,
            0.001824180013500154,
            0.07482007890939713,
            0.11862514168024063,
            -0.04249094799160957,
            0.09823544323444366,
            -0.029257871210575104,
            -0.10151693969964981,
            0.04227689653635025,
            -0.11482062935829163,
            0.010433622635900974,
            -0.047776661813259125,
            0.015025234781205654,
            0.03366657346487045,
            -0.042565446346998215,
            0.023334287106990814,
            -0.03438960015773773,
            -0.06984147429466248,
            0.08663732558488846,
            0.04711629077792168,
            0.06016901135444641,
            0.037439942359924316,
            0.012347237206995487,
            0.05625739321112633,
            -0.0034385721664875746,
            -0.056490082293748856,
            -0.03867097571492195,
            -0.017887694761157036,
            -0.0752132460474968,
            0.024965498596429825,
            0.058354452252388,
            -0.07448422908782959,
            -0.10301610082387924,
            -0.05645858496427536,
            -0.03762848302721977,
            0.06797664612531662,
            0.023875296115875244,
            -0.06518319994211197,
            0.05378947779536247
        ],
        [
            0.04281235858798027,
            0.06698250025510788,
            0.03203415125608444,
            -0.027021564543247223,
            0.05792754516005516,
            0.016103330999612808,
            -0.012852843850851059,
            -0.0290480125695467,
            0.09960711747407913,
            -0.05788709223270416,
            -0.03282910957932472,
            0.011498992331326008,
            -0.022986844182014465,
            0.0731833353638649,
            -0.027167346328496933,
            0.054046399891376495,
            0.01951800100505352,
            -0.02176334708929062,
            -0.10374299436807632,
            0.022883979603648186,
            0.004497217945754528,
            0.08389405161142349,
            -0.017319612205028534,
            -0.03233097493648529,
            -0.02905849553644657,
            0.02073601633310318,
            -0.06649429351091385,
            -0.07095517218112946,
            0.04156973585486412,
            -0.03541799634695053,
            -0.052554383873939514,
            0.09509417414665222,
            0.10900816321372986,
            -0.02116233855485916,
            -0.01662970520555973,
            -0.05192232131958008,
            0.000983805046416819,
            0.07977080345153809,
            0.03456920012831688,
            0.03693271055817604,
            -0.06866246461868286,
            0.04431695491075516,
            -0.05517646297812462,
            0.0008129627094604075,
            0.03658578172326088,
            0.019725453108549118,
            0.04034432768821716,
            0.016695760190486908,
            0.06981796026229858,
            0.12534654140472412,
            -0.030755503103137016,
            0.0025880883913487196,
            -0.07193871587514877,
            -0.034115783870220184,
            -0.0744599997997284,
            0.02896835282444954,
            -0.07561603933572769,
            0.010708600282669067,
            0.12102573364973068,
            0.0026491833850741386,
            -0.09615673869848251,
            0.035492513328790665,
            0.020363086834549904,
            -0.06075432896614075,
            0.05008484423160553,
            0.06717023998498917,
            -0.04058312624692917,
            -0.017735116183757782,
            -0.05653788521885872,
            0.09060436487197876,
            -0.12311114370822906,
            -0.0014366897521540523,
            0.08008450269699097,
            0.010453534312546253,
            0.072953000664711,
            -0.04752076417207718,
            -0.08052030205726624,
            -0.07524635642766953,
            -0.010666904971003532,
            0.06853615492582321,
            0.07450952380895615,
            0.04249615594744682,
            0.02442687191069126,
            0.031337760388851166,
            -0.047444842755794525,
            0.034220319241285324,
            0.07415253669023514,
            -0.05286628007888794,
            -0.07697118818759918,
            -0.011156698688864708,
            0.0880725234746933,
            -0.09284099191427231,
            -0.03877903148531914,
            0.043812207877635956,
            -0.05417858436703682,
            0.12425481528043747,
            0.0749039351940155,
            0.05358806997537613,
            0.039653901010751724,
            -0.013181498274207115,
            -0.08039089292287827,
            0.04597005993127823,
            0.07446032762527466,
            0.03251167759299278,
            0.056001581251621246,
            0.03826243057847023,
            -0.03125617280602455,
            -0.0518813319504261,
            -0.07043846696615219,
            0.025553280487656593,
            -0.05582691356539726,
            -0.0039878417737782,
            0.03776305541396141,
            0.0017915605567395687,
            -0.07641950994729996,
            -0.08105099946260452,
            0.039643142372369766,
            0.006439744029194117,
            0.05194198340177536,
            -0.0038159936666488647,
            0.0499834343791008,
            -0.006663930602371693,
            0.0034384471364319324,
            -0.08202306181192398,
            -0.03954625129699707,
            0.03495106101036072,
            0.050782039761543274,
            -0.004069740418344736,
            -0.09725142270326614,
            -0.05860693007707596,
            -0.02211618423461914,
            0.0335465706884861,
            0.05709999427199364,
            0.049542929977178574,
            0.08356160670518875,
            0.06104457378387451,
            -0.07560192793607712,
            -0.010231267660856247,
            0.007003298494964838,
            0.02324921265244484,
            0.039179783314466476,
            -0.06140987202525139,
            -0.06641324609518051,
            0.036930061876773834,
            0.01434413529932499,
            -0.08580635488033295,
            -0.07239270955324173,
            -0.0011871295282617211,
            -0.001035846769809723,
            0.07692992687225342,
            0.06150997430086136,
            0.08913661539554596,
            -0.02328997477889061,
            0.05428647622466087,
            0.038668036460876465,
            -0.08324930816888809,
            0.0367322601377964,
            0.05308851972222328,
            0.020510930567979813,
            -0.073005311191082,
            -0.07596832513809204,
            0.006823893170803785,
            0.024731216952204704,
            0.00014896155335009098,
            -0.026432612910866737,
            0.010390193201601505,
            0.04153139516711235,
            -0.06334448605775833,
            0.07190296798944473,
            -0.00048025103751569986,
            -0.09657800197601318,
            0.05469086766242981,
            -0.0196969173848629,
            -0.09031231701374054,
            -0.0638042762875557,
            -0.022393453866243362,
            -0.06921029090881348,
            -0.09494204074144363,
            -0.023730570450425148,
            0.03723365068435669,
            -0.047839678823947906,
            0.06767388433218002,
            -0.08224360644817352,
            0.0674959346652031,
            0.031466543674468994,
            0.0590226985514164,
            -0.07487720251083374,
            -0.04097837582230568,
            0.017871784046292305,
            -0.062237318605184555,
            0.0644611269235611,
            -0.05535420402884483,
            0.02835065871477127,
            0.005470255855470896,
            -0.03724042698740959,
            -0.043699711561203,
            -0.05014519393444061,
            0.05696932598948479,
            -0.06703382730484009,
            -0.05203792452812195,
            0.013167810626327991,
            0.0768536701798439,
            0.0009242740925401449,
            0.06734661757946014,
            -0.0591161847114563,
            -0.0063972072675824165,
            0.04838673770427704,
            -0.010559949092566967,
            -0.05469609424471855,
            0.0037464594934135675,
            -0.0901046097278595,
            -0.021015392616391182,
            -0.009657613933086395,
            0.03125223517417908,
            0.022059328854084015,
            -0.007534456439316273,
            0.10273805260658264,
            -0.06330136954784393,
            -0.0017057209042832255,
            -0.034247688949108124,
            -0.1094260960817337,
            -0.027129899710416794,
            -0.03055306151509285,
            -0.027846358716487885,
            -0.07409797608852386,
            -0.012124620378017426,
            0.08025657385587692,
            -0.05403418093919754,
            0.008188161998987198,
            0.07854273170232773,
            -0.0030726497061550617,
            -0.06838836520910263,
            -0.10397180914878845,
            -0.06988940387964249,
            -0.011592716909945011,
            0.023822423070669174,
            0.0650591179728508,
            0.049789220094680786,
            0.04244992882013321,
            -0.0007185096619650722,
            -0.0029366889502853155,
            -0.04012225195765495,
            -0.049497079104185104,
            -0.035693492740392685,
            0.08305421471595764,
            0.021724700927734375,
            0.003101799637079239,
            0.06343643367290497,
            0.004787725396454334,
            -0.017688030377030373,
            -0.017824433743953705,
            -0.11768179386854172,
            0.05145062133669853,
            -0.0034860435407608747,
            0.030946215614676476,
            -0.07344076782464981
        ],
        [
            -0.013076757080852985,
            0.06882575154304504,
            0.06857596337795258,
            -0.0040237391367554665,
            0.025765571743249893,
            0.06540623307228088,
            -0.09153126925230026,
            -0.07088880985975266,
            -0.08704573661088943,
            -0.13739635050296783,
            -0.10605939477682114,
            0.02511063776910305,
            -0.022872203961014748,
            -0.04724825546145439,
            0.0751422643661499,
            0.043640244752168655,
            -0.1219792366027832,
            0.07474401593208313,
            -0.025463175028562546,
            0.017694881185889244,
            -0.011998698115348816,
            -0.10674098879098892,
            0.1716306060552597,
            0.07698278874158859,
            -0.04710870608687401,
            -0.010881936177611351,
            -0.006305771414190531,
            -0.04314630478620529,
            -0.07538200914859772,
            -0.05377773568034172,
            0.06329453736543655,
            -0.011113067157566547,
            -0.019416965544223785,
            -0.07697268575429916,
            0.043747421354055405,
            0.04975336045026779,
            -0.04072066396474838,
            -0.03549657762050629,
            -0.03879833221435547,
            0.10985147207975388,
            0.02548935078084469,
            -0.04792962223291397,
            -0.008442142978310585,
            0.08489485830068588,
            0.07323344796895981,
            -0.021997977048158646,
            -0.053746603429317474,
            0.020629558712244034,
            0.036218609660863876,
            -0.017874116078019142,
            -0.0010030808625742793,
            -0.06360969692468643,
            0.01738821528851986,
            -0.05928456783294678,
            0.055638790130615234,
            0.018209097906947136,
            -0.081174336373806,
            0.09980344027280807,
            -0.028490988537669182,
            -0.09943389147520065,
            0.09866021573543549,
            0.06828439235687256,
            -0.028804833069443703,
            -0.07263754308223724,
            -0.054757386445999146,
            -0.03731006011366844,
            -0.0551544614136219,
            0.035332392901182175,
            0.03485535830259323,
            -0.042963940650224686,
            -0.13114182651042938,
            -0.027308069169521332,
            -0.00543513847514987,
            0.07896517962217331,
            -0.04097045958042145,
            -0.000786308606620878,
            -0.06408405303955078,
            0.012077230028808117,
            0.028278356418013573,
            -0.0865282490849495,
            0.003517540404573083,
            0.04702946916222572,
            -0.023436473682522774,
            -0.07528503239154816,
            -0.08264055848121643,
            -0.05146072059869766,
            -0.03215206786990166,
            0.09768248349428177,
            0.06903383880853653,
            -0.03683438524603844,
            -0.05568157136440277,
            -0.0451960563659668,
            0.02097153477370739,
            -0.0833863615989685,
            -0.030345840379595757,
            -0.05512198060750961,
            0.051558900624513626,
            -0.048336487263441086,
            0.06267927587032318,
            0.034895479679107666,
            0.07502976059913635,
            -0.049581706523895264,
            0.07529420405626297,
            0.05850733444094658,
            0.061150141060352325,
            -0.05634448677301407,
            0.05586959794163704,
            0.15079021453857422,
            0.02990823984146118,
            -0.007813243195414543,
            0.04986383393406868,
            -0.06952674686908722,
            0.01943604089319706,
            -0.05006637051701546,
            0.0928468182682991,
            -0.08267217129468918,
            0.059073422104120255,
            -0.028506098315119743,
            0.06859523057937622,
            -0.023049894720315933,
            -0.02173430286347866,
            0.030155779793858528,
            0.07335469126701355,
            -0.08774107694625854,
            0.04565099626779556,
            -0.04667452350258827,
            0.06694047152996063,
            0.05863159894943237,
            0.02126232162117958,
            -0.07298243790864944,
            -0.025907715782523155,
            0.033655229955911636,
            -0.06209585443139076,
            -0.0006193438312038779,
            0.06038183718919754,
            0.06459292769432068,
            -0.07522298395633698,
            -0.03095930628478527,
            -0.016591811552643776,
            0.04600853845477104,
            0.022326797246932983,
            -0.017967240884900093,
            0.03522471338510513,
            -0.0430154874920845,
            -0.04827088117599487,
            0.01101048942655325,
            0.008002381771802902,
            0.11279954016208649,
            0.06291995197534561,
            -0.06626966595649719,
            0.025422437116503716,
            -0.10369313508272171,
            -0.04000157490372658,
            -0.022966202348470688,
            -0.0034389663487672806,
            -0.06724798679351807,
            0.04495133087038994,
            0.06966595351696014,
            0.041974183171987534,
            0.05261336639523506,
            -0.06024651229381561,
            -0.10711167007684708,
            -0.027654126286506653,
            0.05724266171455383,
            -0.05409115552902222,
            -0.06154601275920868,
            0.03458964824676514,
            0.026144972071051598,
            -0.053017474710941315,
            0.04332714527845383,
            0.07839622348546982,
            -0.1402532309293747,
            0.013844499364495277,
            0.05063197389245033,
            -0.0425250269472599,
            0.06715833395719528,
            0.04832475632429123,
            -0.026427660137414932,
            -0.011525982990860939,
            0.014593420550227165,
            -0.11188897490501404,
            0.029964733868837357,
            0.03013588860630989,
            -0.06710447371006012,
            0.02636045776307583,
            0.034482624381780624,
            -0.03356119990348816,
            0.0035335668362677097,
            0.035664185881614685,
            0.058779843151569366,
            0.08472014963626862,
            -0.044117480516433716,
            0.039640530943870544,
            0.03006969951093197,
            -0.0628206729888916,
            -0.031948987394571304,
            -0.005981358699500561,
            -0.05409495159983635,
            0.0036695851013064384,
            -0.06873009353876114,
            0.04238824173808098,
            -0.027728162705898285,
            -0.03487183526158333,
            0.032568663358688354,
            -0.07926585525274277,
            -0.04082941636443138,
            0.03307349607348442,
            0.03592076152563095,
            -0.02580687589943409,
            -0.03914181515574455,
            0.028378820046782494,
            -0.09975127130746841,
            0.00672235619276762,
            -0.014115034602582455,
            0.040271978825330734,
            0.06649317592382431,
            -0.02861202508211136,
            0.049865394830703735,
            0.03939308971166611,
            -0.017625868320465088,
            -0.04238849878311157,
            -0.12686388194561005,
            -0.08111350238323212,
            0.04049864038825035,
            -0.05198431760072708,
            0.07934503257274628,
            0.002370792208239436,
            -0.04442700743675232,
            0.09418752789497375,
            0.00961422547698021,
            -0.05196790397167206,
            0.0023598468396812677,
            0.017263926565647125,
            0.010599091649055481,
            0.03628414496779442,
            -0.026799602434039116,
            -0.053917914628982544,
            -0.0851227268576622,
            0.006405968219041824,
            0.05216139182448387,
            0.04793137311935425,
            0.0288432277739048,
            -0.07420826703310013,
            0.00032505334820598364,
            -0.07597628235816956,
            0.0031766630709171295,
            0.03628595173358917,
            0.07448509335517883,
            0.056297045201063156,
            -0.020336249843239784,
            0.06195491924881935,
            -0.01875387877225876,
            -0.08548103272914886,
            -0.00030275253811851144,
            -0.052782509475946426,
            0.05753869563341141
        ],
        [
            -0.05518321692943573,
            -0.040253978222608566,
            0.05546976253390312,
            -0.04061140492558479,
            0.002655460499227047,
            0.0317314937710762,
            -0.026768557727336884,
            -0.005593488458544016,
            -0.03451934829354286,
            -0.021477317437529564,
            -0.023256059736013412,
            0.0029145905282348394,
            -0.0646001547574997,
            0.021245459094643593,
            -0.03949696570634842,
            -0.0308865737169981,
            -0.019709071144461632,
            0.047111447900533676,
            -0.006928996182978153,
            -0.017230698838829994,
            -0.08655618131160736,
            -0.0180936511605978,
            -0.018657563254237175,
            0.030975766479969025,
            0.08544034510850906,
            -0.09521251171827316,
            -0.014448151923716068,
            -0.06590267270803452,
            0.05684874951839447,
            -0.013559660874307156,
            -0.01325030904263258,
            0.022557586431503296,
            -0.026548106223344803,
            0.05894431844353676,
            -0.09177105873823166,
            0.06207403168082237,
            -0.07048019021749496,
            -0.10500497370958328,
            0.13278509676456451,
            0.06753496825695038,
            0.04488670453429222,
            -0.002021226566284895,
            0.05740240588784218,
            -0.06267189979553223,
            -0.01432888675481081,
            0.053564831614494324,
            0.038405198603868484,
            0.05941804125905037,
            -0.017454680055379868,
            -0.045280903577804565,
            -0.05183159187436104,
            0.02955266647040844,
            -0.004757664166390896,
            -0.03657790645956993,
            -0.005354869645088911,
            -2.3568565666209906e-05,
            -0.06492447853088379,
            -0.024577636271715164,
            -0.055582668632268906,
            -0.002378750592470169,
            -0.021932948380708694,
            -0.04878760874271393,
            0.030195003375411034,
            -0.036554448306560516,
            0.05390463396906853,
            0.03496042266488075,
            0.05244427174329758,
            0.047732990235090256,
            0.02393549680709839,
            0.020550845190882683,
            -0.05573107674717903,
            -0.01973656751215458,
            -0.056524258106946945,
            0.024104496464133263,
            0.019522136077284813,
            0.05846399813890457,
            -0.027185160666704178,
            -0.08770851045846939,
            -0.06445014476776123,
            0.049678735435009,
            0.026611797511577606,
            -0.07666463404893875,
            -0.07813525944948196,
            -0.010453922674059868,
            -0.02939704805612564,
            -0.05686929076910019,
            0.06631716340780258,
            0.0014059343375265598,
            0.01463754940778017,
            -0.030717236921191216,
            0.03409966826438904,
            0.019686250016093254,
            0.002111255656927824,
            -0.07626644521951675,
            0.04344061389565468,
            0.00854270439594984,
            0.025973163545131683,
            -0.04065064340829849,
            0.031203849241137505,
            0.10430904477834702,
            -0.04739665240049362,
            0.06273207813501358,
            0.033353790640830994,
            0.03592299297451973,
            0.048626817762851715,
            0.04254428669810295,
            0.02104683220386505,
            -0.09853560477495193,
            -0.06551382690668106,
            0.05645749345421791,
            -0.008370664902031422,
            -0.070829376578331,
            0.03999936580657959,
            0.055807407945394516,
            0.001855578157119453,
            -0.047952454537153244,
            -0.03246001899242401,
            0.030709506943821907,
            0.015079897828400135,
            -0.05390295758843422,
            0.030293293297290802,
            0.045641109347343445,
            0.057972509413957596,
            0.04674753546714783,
            -0.07766834646463394,
            -0.07316369563341141,
            -0.07187224179506302,
            -0.06316615641117096,
            0.00988861732184887,
            0.014774289913475513,
            0.026830153539776802,
            -0.007282232400029898,
            0.021617896854877472,
            0.005594163201749325,
            0.037150558084249496,
            -0.012815416790544987,
            0.05160536989569664,
            0.01609702967107296,
            -0.01225305162370205,
            -0.07148302346467972,
            -0.08390253037214279,
            0.03972578048706055,
            0.02862851694226265,
            -0.05456269904971123,
            0.04305802285671234,
            0.034278228878974915,
            0.013485799543559551,
            -0.01726587302982807,
            -0.05602157860994339,
            0.021105453372001648,
            -0.03630691394209862,
            0.018567346036434174,
            -0.07361405342817307,
            0.004162527620792389,
            -0.04659564420580864,
            -0.037293944507837296,
            0.019478661939501762,
            -0.05516636371612549,
            -0.039264000952243805,
            0.005545216612517834,
            -0.05123460292816162,
            0.047501664608716965,
            0.07133880257606506,
            -0.056943293660879135,
            -0.01940225623548031,
            -0.07186984270811081,
            -0.006245619151741266,
            -0.056686531752347946,
            0.03566894307732582,
            0.0677800104022026,
            -0.037221312522888184,
            -0.042666368186473846,
            0.06423291563987732,
            0.02271099016070366,
            0.04875136539340019,
            0.018097594380378723,
            -0.040947556495666504,
            -0.0060807098634541035,
            0.04185470566153526,
            0.03216322883963585,
            0.05579286441206932,
            -0.03837064281105995,
            0.007142441347241402,
            -0.032227225601673126,
            0.03408333659172058,
            0.010625671595335007,
            0.07302824407815933,
            -0.013244327157735825,
            -0.0355684794485569,
            -0.05385706201195717,
            -0.10120490938425064,
            0.07240232825279236,
            -0.011166929267346859,
            0.037912189960479736,
            -0.049765266478061676,
            0.013280481100082397,
            -0.0285300612449646,
            -0.04942898079752922,
            -0.01881464757025242,
            0.011630033142864704,
            -0.0574122853577137,
            0.04376954957842827,
            -0.0063062384724617004,
            -0.06895656883716583,
            -0.0077140796929597855,
            -0.06414630264043808,
            0.051606617867946625,
            -0.05216118320822716,
            -0.0035506957210600376,
            0.024704687297344208,
            0.008343539200723171,
            -0.018303489312529564,
            0.006650317460298538,
            0.0583607517182827,
            0.05056615173816681,
            0.0489744134247303,
            -0.05656491592526436,
            -0.03337387368083,
            -0.05250829830765724,
            0.014283738099038601,
            -0.017205553129315376,
            -0.0233876034617424,
            -0.04097394272685051,
            -0.02755826897919178,
            -0.048503849655389786,
            -0.001831796602346003,
            0.04354129731655121,
            0.017757747322320938,
            0.025578364729881287,
            0.0036694000009447336,
            0.06105333939194679,
            0.04874427244067192,
            0.03815750405192375,
            0.007659559603780508,
            -0.0010445030638948083,
            -0.020234504714608192,
            0.06461242586374283,
            0.03811591491103172,
            -0.05335773155093193,
            -0.04604658484458923,
            0.03829976171255112,
            -0.03126436844468117,
            -0.0780971497297287,
            0.06462539732456207,
            0.021284274756908417,
            -0.06447400152683258,
            -0.03468463197350502,
            0.04977508261799812,
            -0.03334826976060867,
            -0.0228149201720953,
            0.06518684327602386,
            0.03482077643275261,
            0.02335958182811737,
            -0.07310713082551956,
            -0.026066254824399948,
            0.06357850134372711
        ],
        [
            -0.05503690987825394,
            -0.005932252388447523,
            -0.0140527980402112,
            -0.06483282148838043,
            0.012907189317047596,
            0.08965442329645157,
            0.050804443657398224,
            0.0809575766324997,
            -0.04685145989060402,
            0.1490519642829895,
            -0.010612240061163902,
            -0.0424581877887249,
            -0.01410764642059803,
            -0.03751271590590477,
            0.01696164160966873,
            -0.06507649272680283,
            0.02757306955754757,
            -0.07202919572591782,
            0.038615431636571884,
            -0.011840865015983582,
            0.08044018596410751,
            0.08688360452651978,
            -0.032932642847299576,
            -0.043735321611166,
            0.06757080554962158,
            0.027743803337216377,
            0.03304857760667801,
            0.018562283366918564,
            -0.07193189859390259,
            0.038862667977809906,
            -0.10101266205310822,
            -0.031245846301317215,
            0.06395412981510162,
            0.014780223369598389,
            0.04325401782989502,
            0.043763354420661926,
            0.022483626380562782,
            0.04081011191010475,
            0.10901395976543427,
            0.07873769849538803,
            -0.029114453122019768,
            0.01943528652191162,
            -0.0674714520573616,
            -0.060505058616399765,
            -0.06572510302066803,
            -0.054470740258693695,
            -0.06270991265773773,
            -0.02122393809258938,
            -0.009911339730024338,
            -0.06438694894313812,
            0.03549889475107193,
            0.03979760408401489,
            -0.010061689652502537,
            -0.10566680878400803,
            0.0663403645157814,
            0.005121494177728891,
            -0.019117990508675575,
            -0.005676663480699062,
            0.03949107229709625,
            -0.06740090996026993,
            -0.013418079353868961,
            0.01272491179406643,
            0.05277704820036888,
            0.07761278748512268,
            0.028127331286668777,
            0.053623151034116745,
            -0.03852049261331558,
            0.056518297642469406,
            0.05989187955856323,
            0.05228504538536072,
            0.05498077720403671,
            0.018176522105932236,
            -0.04391057789325714,
            0.049131374806165695,
            -0.013906124047935009,
            0.006129577290266752,
            0.008358239196240902,
            0.025861188769340515,
            -0.026790499687194824,
            0.011728288605809212,
            -0.0395011305809021,
            -0.035330597311258316,
            0.06337661296129227,
            0.0610513873398304,
            -0.08451753109693527,
            0.00812196359038353,
            0.017962127923965454,
            -0.014006325043737888,
            0.01753735914826393,
            0.029881872236728668,
            0.08796349912881851,
            -0.039631642401218414,
            -0.06463320553302765,
            0.0649409219622612,
            0.09543228149414062,
            0.01515650562942028,
            -0.02908291108906269,
            -0.028505973517894745,
            0.05124698206782341,
            0.016288410872220993,
            -0.048581719398498535,
            -0.022624926641583443,
            0.0305815190076828,
            0.04523684084415436,
            -0.09096330404281616,
            -0.022786585614085197,
            -0.06616758555173874,
            0.13255520164966583,
            0.04782761633396149,
            0.037647295743227005,
            -0.015341092832386494,
            -0.01995631866157055,
            0.057861845940351486,
            -0.06472448259592056,
            -0.06805288046598434,
            -0.04432997480034828,
            -0.05076418071985245,
            -0.06005480885505676,
            0.04669083282351494,
            -0.005809834226965904,
            -0.010040093213319778,
            0.0434374064207077,
            0.06434392929077148,
            0.12207313627004623,
            -0.052250999957323074,
            0.0058680009096860886,
            0.04067398980259895,
            -0.034370265901088715,
            0.06434780359268188,
            -0.06345934420824051,
            -0.05047835409641266,
            0.0507391132414341,
            -0.0015089736552909017,
            0.020701603963971138,
            0.04758302867412567,
            0.008934768848121166,
            0.013187277130782604,
            -0.05741524696350098,
            0.023045992478728294,
            0.06382358819246292,
            0.02345304563641548,
            -0.018704013898968697,
            -0.05681871250271797,
            0.05972709134221077,
            -0.06359153240919113,
            0.03104534186422825,
            -0.005084761418402195,
            -0.018943505361676216,
            0.05920695513486862,
            0.06261564046144485,
            0.04977146536111832,
            -0.0328483060002327,
            -0.05438551306724548,
            -0.0169674064964056,
            0.004579316824674606,
            0.04643022641539574,
            -0.07362152636051178,
            -0.07412207871675491,
            -0.020036926493048668,
            -0.059107955545186996,
            0.033211153000593185,
            0.04793066903948784,
            -0.0025434440467506647,
            -0.0543866902589798,
            0.01717018336057663,
            0.0726657509803772,
            0.047269582748413086,
            0.0026912414468824863,
            -0.09199997782707214,
            -0.05225100740790367,
            -0.03554384037852287,
            0.08444378525018692,
            0.021435653790831566,
            0.028354795649647713,
            -0.06271065771579742,
            -0.057934824377298355,
            0.00031289688195101917,
            0.013066920451819897,
            0.06809937953948975,
            -0.05544992908835411,
            0.11942994594573975,
            0.007461130153387785,
            -0.04246344417333603,
            0.0691782534122467,
            -0.10236182063817978,
            0.03864354267716408,
            0.04117170348763466,
            0.011429155245423317,
            -0.0687822476029396,
            0.06955841928720474,
            -0.06518494337797165,
            -0.06901600956916809,
            -0.0014237448340281844,
            -0.0552973710000515,
            0.06632516533136368,
            -0.028929483145475388,
            -0.021407904103398323,
            0.02303651161491871,
            0.11227893829345703,
            0.07731688022613525,
            0.04282831773161888,
            0.06275591254234314,
            0.011272845789790154,
            -0.07900187373161316,
            0.048281896859407425,
            0.03964929282665253,
            0.04040459543466568,
            0.02670859545469284,
            0.01129713375121355,
            0.08016899228096008,
            0.12444040179252625,
            0.020137310028076172,
            0.03898566961288452,
            -0.07148172706365585,
            -0.004808841273188591,
            -0.02766520157456398,
            0.07786450535058975,
            -0.00769280968233943,
            0.024356618523597717,
            -0.08220017701387405,
            0.09172309935092926,
            0.10967303812503815,
            -0.04614917188882828,
            0.027259586378932,
            -0.05395505949854851,
            0.013005697168409824,
            -0.055620986968278885,
            0.022556623443961143,
            0.01192332524806261,
            -0.00399443507194519,
            0.07578396797180176,
            0.007557861506938934,
            0.047768644988536835,
            0.07167360186576843,
            0.04647239297628403,
            -0.04184465855360031,
            -0.01289560366421938,
            0.009634293615818024,
            0.06637343764305115,
            -0.09478511661291122,
            -0.005534844007343054,
            -0.06053576245903969,
            0.13072168827056885,
            0.04732692614197731,
            -0.03979022055864334,
            -0.06816213577985764,
            -0.059812139719724655,
            0.01211935468018055,
            -0.03389875590801239,
            0.0443679578602314,
            0.01414289977401495,
            0.04987778514623642,
            -0.06484568864107132,
            -0.026357807219028473,
            -0.07966934144496918,
            0.06446423381567001
        ],
        [
            0.046698346734046936,
            0.009289749898016453,
            -0.043205320835113525,
            -0.0026221920270472765,
            0.08473644405603409,
            -0.05412795767188072,
            0.06908039003610611,
            -0.04491376131772995,
            0.05479379743337631,
            0.08476898819208145,
            0.10896218568086624,
            0.05204242840409279,
            0.07383137941360474,
            0.06591539829969406,
            0.04638345167040825,
            -0.04765661433339119,
            0.06996820122003555,
            -0.07755352556705475,
            0.04475102946162224,
            -0.08888605982065201,
            -0.02879377268254757,
            -0.06931178271770477,
            -0.10784759372472763,
            0.038570452481508255,
            -0.05058968812227249,
            -0.04400644451379776,
            0.06717570126056671,
            -0.05566151440143585,
            0.07557559758424759,
            0.0323052853345871,
            -0.019048938527703285,
            -0.09543706476688385,
            -0.005233964882791042,
            -0.0069989352487027645,
            0.009961970150470734,
            0.04738260805606842,
            -1.1904659004358109e-05,
            -0.022137098014354706,
            -0.015970604494214058,
            -0.04628177732229233,
            0.004172614775598049,
            -0.032798293977975845,
            -0.023068275302648544,
            -0.07206335663795471,
            0.034734394401311874,
            -0.0540081225335598,
            0.033146198838949203,
            -0.00482554966583848,
            -0.04505543410778046,
            -0.03966856747865677,
            -0.03258911147713661,
            -0.027140112593770027,
            -0.0038503482937812805,
            -0.017719389870762825,
            -0.03799890726804733,
            -0.0029313312843441963,
            0.011068540625274181,
            -0.04246004298329353,
            -0.03727293014526367,
            -0.05379254370927811,
            -0.006692773196846247,
            -0.013156811706721783,
            -0.05313270166516304,
            0.0366835743188858,
            -0.006866408511996269,
            -0.0744236633181572,
            0.0036571312230080366,
            0.025130119174718857,
            0.0753498524427414,
            -0.088394895195961,
            0.0843200534582138,
            -0.06154664233326912,
            -0.024922553449869156,
            0.06347119808197021,
            -0.013737475499510765,
            -0.025632943958044052,
            0.027608690783381462,
            0.09909028559923172,
            -0.034864094108343124,
            0.031478967517614365,
            0.06293991208076477,
            0.05148686096072197,
            -0.08370392769575119,
            0.08707499504089355,
            0.02542729489505291,
            0.04415891319513321,
            0.060760412365198135,
            0.028358131647109985,
            0.08987552672624588,
            0.05338921397924423,
            -0.04817575588822365,
            0.09235375374555588,
            -0.00349877099506557,
            0.06146892532706261,
            -0.08104129135608673,
            -0.05821196734905243,
            -0.0034703367855399847,
            0.061025843024253845,
            -0.0268742423504591,
            0.01814708486199379,
            0.0030039427801966667,
            0.023384524509310722,
            0.04510079324245453,
            -0.043170444667339325,
            0.040175069123506546,
            0.045652132481336594,
            0.05279073864221573,
            0.019847476854920387,
            0.08874983340501785,
            -0.06275692582130432,
            -0.05385847017168999,
            -0.07619566470384598,
            0.05571984499692917,
            -0.00720815546810627,
            -0.0519111305475235,
            -0.0447026751935482,
            0.04496386647224426,
            0.038538552820682526,
            -0.0757063701748848,
            -0.02143915183842182,
            -0.08585857599973679,
            -0.04138213396072388,
            0.05084395781159401,
            -0.040469639003276825,
            -0.021875880658626556,
            0.048718467354774475,
            -0.07170717418193817,
            -0.05384717136621475,
            0.022684162482619286,
            0.010120208375155926,
            -0.041498079895973206,
            -0.08807265758514404,
            0.03929505497217178,
            0.04745936021208763,
            -0.051344551146030426,
            -0.0785178691148758,
            0.02396557852625847,
            0.03881414979696274,
            -0.028493836522102356,
            0.0033491060603410006,
            0.044748395681381226,
            0.07047150284051895,
            -0.009413407184183598,
            0.07851718366146088,
            -0.008482879027724266,
            -0.06739513576030731,
            0.07885905355215073,
            -0.0705530196428299,
            -0.09214039891958237,
            -0.04382431507110596,
            0.0483424998819828,
            -0.0014964669244363904,
            -0.00401384849101305,
            -0.06293363869190216,
            -0.03040388785302639,
            0.0189678892493248,
            -0.04744841530919075,
            0.04534237086772919,
            -0.06096218153834343,
            0.09257873147726059,
            0.00909117516130209,
            0.058517299592494965,
            0.04004991427063942,
            0.06333865970373154,
            -0.041769061237573624,
            0.049497339874506,
            0.02341105230152607,
            0.007383896503597498,
            0.048441193997859955,
            -0.05861397460103035,
            -0.011457637883722782,
            0.026653431355953217,
            -0.07573017477989197,
            -0.009389801882207394,
            0.05030520260334015,
            0.06334204226732254,
            0.0745854601264,
            0.04229474812746048,
            0.05404011905193329,
            -0.06030788645148277,
            0.05704312399029732,
            0.09056296199560165,
            0.07306015491485596,
            0.03765726089477539,
            0.05634792521595955,
            0.01306871511042118,
            -0.037849850952625275,
            0.03182288631796837,
            -0.06079179421067238,
            -0.014158620499074459,
            0.012480025179684162,
            -0.042634267359972,
            0.052960507571697235,
            0.07253625243902206,
            -0.03087647072970867,
            -0.045978475362062454,
            -0.022597281262278557,
            0.008369303308427334,
            -0.04217245429754257,
            0.08195814490318298,
            0.022822892293334007,
            -0.06441793590784073,
            -0.010301655158400536,
            0.06480297446250916,
            0.03154737502336502,
            -0.0042932596988976,
            0.06210764870047569,
            0.042491886764764786,
            -0.040788087993860245,
            0.03600962460041046,
            -0.08669371902942657,
            -0.02198483794927597,
            -0.05204547941684723,
            0.0647275447845459,
            -0.059042323380708694,
            -0.09024519473314285,
            -0.07641226053237915,
            0.07991743087768555,
            0.031421903520822525,
            -0.01706005074083805,
            0.011952060274779797,
            0.009679144248366356,
            -0.0018325535347685218,
            -0.04388117790222168,
            0.0770350769162178,
            -0.04055808484554291,
            0.01946759596467018,
            0.017140168696641922,
            -0.09103067219257355,
            -0.08148473501205444,
            -0.077613465487957,
            -0.031753286719322205,
            -0.04111281409859657,
            0.029774269089102745,
            0.02468719705939293,
            -0.015279079787433147,
            -0.005882499273866415,
            0.05323706194758415,
            0.0361986979842186,
            -0.01064759399741888,
            -0.07006018608808517,
            -0.04314484819769859,
            0.009913519956171513,
            0.005628781858831644,
            0.025381997227668762,
            -0.007366356439888477,
            -0.07485517114400864,
            -0.025315221399068832,
            -0.015946565195918083,
            0.07654190808534622,
            -0.0023735719732940197,
            0.10190343111753464,
            0.024308418855071068,
            -0.02854059264063835,
            0.024274008348584175,
            0.01432930026203394
        ],
        [
            -0.03919056057929993,
            0.04689265415072441,
            0.04252466931939125,
            -0.03786669299006462,
            -0.02817126177251339,
            -0.06617838889360428,
            0.0028122023213654757,
            0.09154848754405975,
            0.0746113508939743,
            -0.045444369316101074,
            0.11223296821117401,
            -0.04862535744905472,
            -0.026036353781819344,
            0.002533089369535446,
            0.03410439193248749,
            -0.04193476215004921,
            0.07329029589891434,
            -0.02113463543355465,
            0.011145418509840965,
            -0.05497365817427635,
            -0.03597533702850342,
            -0.0341038852930069,
            0.029785271733999252,
            -0.02515232376754284,
            0.04242774471640587,
            0.06094897538423538,
            -0.030729837715625763,
            0.060844432562589645,
            0.06574606150388718,
            -0.025285523384809494,
            0.004548479802906513,
            0.03295240178704262,
            0.03784051537513733,
            0.04266534745693207,
            -0.006743628066033125,
            0.03217175975441933,
            -0.044682882726192474,
            0.06444498151540756,
            -0.10209488123655319,
            -0.14135681092739105,
            -0.03253839164972305,
            0.07609004527330399,
            0.09083550423383713,
            0.04282919690012932,
            -0.024372244253754616,
            0.02868516929447651,
            -0.01599750481545925,
            -0.04928009957075119,
            -0.005182518158107996,
            -0.011375044472515583,
            -0.02690519019961357,
            0.018597455695271492,
            0.03331609070301056,
            0.042070768773555756,
            0.0343492366373539,
            -0.08149419724941254,
            -0.06340644508600235,
            -0.0026273250114172697,
            -0.08046078681945801,
            0.0729091614484787,
            0.03780272975564003,
            -0.03789899870753288,
            -0.06041829288005829,
            -0.03251058980822563,
            -0.008450225926935673,
            0.06480909138917923,
            0.037934809923172,
            -0.01616804115474224,
            -0.07949725538492203,
            0.017502211034297943,
            -0.06096208095550537,
            -0.02195875346660614,
            -0.026801971718668938,
            -0.015028445981442928,
            -0.030594803392887115,
            -0.047434691339731216,
            -0.11905921995639801,
            0.0027297819033265114,
            -0.02527669258415699,
            -0.07167660444974899,
            0.04507612809538841,
            0.04074748978018761,
            -0.01108397077769041,
            -0.03937257453799248,
            0.03322527930140495,
            0.001359945279546082,
            0.03739403933286667,
            0.08116630464792252,
            0.054022081196308136,
            0.005226581357419491,
            -0.02251194603741169,
            -0.09287591278553009,
            -0.013768685050308704,
            0.06977808475494385,
            0.11270412802696228,
            0.015345817431807518,
            0.04778279736638069,
            -0.07115362584590912,
            -0.07244287431240082,
            -0.15467023849487305,
            0.0016809760127216578,
            -0.05538158491253853,
            -0.11678841710090637,
            0.0630907490849495,
            0.036210354417562485,
            -0.003745180321857333,
            -0.01759438030421734,
            -0.039830319583415985,
            -0.018456637859344482,
            -0.0707394927740097,
            0.04320627450942993,
            -0.055769726634025574,
            -0.07439255714416504,
            -0.025569729506969452,
            0.03607363998889923,
            -0.05235033109784126,
            -0.024471450597047806,
            -0.022585252299904823,
            -0.04183739796280861,
            0.06563659757375717,
            -0.0498473085463047,
            -0.010870267637073994,
            -0.04357105866074562,
            0.01582939177751541,
            -0.06434106826782227,
            -0.02317618578672409,
            0.031763918697834015,
            0.05524333566427231,
            0.06610025465488434,
            -0.0427035391330719,
            -0.010500367730855942,
            0.016107354313135147,
            -0.06108100712299347,
            0.05911838635802269,
            -0.01165104005485773,
            0.011618142947554588,
            0.05873502418398857,
            0.05176373943686485,
            0.020916175097227097,
            -0.006209494546055794,
            -0.022997910156846046,
            0.05779226869344711,
            -0.06748068332672119,
            -0.0650455430150032,
            0.07260366529226303,
            0.00809460412710905,
            0.02323601394891739,
            -0.0004648161120712757,
            0.06214937940239906,
            0.038284990936517715,
            -0.06693612784147263,
            0.05368304252624512,
            0.033759914338588715,
            0.05273447558283806,
            0.03763521462678909,
            -0.0481177382171154,
            -0.05071914568543434,
            0.05111345648765564,
            0.0505974180996418,
            -0.05464558303356171,
            -0.010274686850607395,
            0.02399529330432415,
            0.0028142312075942755,
            -0.02747923880815506,
            0.03151676803827286,
            -0.019151804968714714,
            0.05597976595163345,
            0.052409034222364426,
            0.018029533326625824,
            0.053768567740917206,
            -0.028353335335850716,
            0.023510735481977463,
            0.05334389582276344,
            0.05560044199228287,
            0.05012776702642441,
            -0.061087220907211304,
            0.052252836525440216,
            -0.02857598476111889,
            0.038009438663721085,
            0.045104365795850754,
            0.07735157757997513,
            0.0474807471036911,
            0.039020512253046036,
            -0.01907065138220787,
            0.0484858900308609,
            -0.05119416117668152,
            -0.007494272664189339,
            0.044708430767059326,
            -0.122881680727005,
            0.03764405474066734,
            -0.00457037054002285,
            0.025255851447582245,
            -0.006453292444348335,
            0.055115729570388794,
            0.031813956797122955,
            -0.03010195679962635,
            0.06635064631700516,
            -0.0565192848443985,
            0.028703689575195312,
            -0.0009396214736625552,
            0.0013776094419881701,
            -0.040133025497198105,
            -0.07915964722633362,
            0.03888595104217529,
            -0.07691606879234314,
            0.06994591653347015,
            0.0008815065957605839,
            -0.0938258245587349,
            -0.0072629875503480434,
            -0.06672128289937973,
            0.007899697870016098,
            0.061220429837703705,
            -0.047618426382541656,
            -0.06547148525714874,
            0.0016928258119150996,
            -0.02298780344426632,
            -0.04219125583767891,
            0.05682920292019844,
            0.04968271404504776,
            -0.019870223477482796,
            0.05260106921195984,
            -0.1414068192243576,
            -0.064661405980587,
            -0.0010904058581218123,
            0.05111660063266754,
            -0.06107614189386368,
            -0.04629368707537651,
            -0.01774740405380726,
            0.03816919028759003,
            0.03347858414053917,
            0.011232640594244003,
            0.0024178705643862486,
            -0.06378762423992157,
            0.04076746851205826,
            -0.03330335393548012,
            -0.05690304562449455,
            0.016319485381245613,
            -0.021143052726984024,
            -0.031243884935975075,
            0.04098526015877724,
            0.057343125343322754,
            0.05824940279126167,
            0.021053513512015343,
            0.058639075607061386,
            -0.05323677510023117,
            0.04182618856430054,
            0.1439237892627716,
            0.033703021705150604,
            0.03826307877898216,
            -0.015687523409724236,
            -0.010294637642800808,
            0.044693589210510254,
            -0.04281631112098694,
            0.06774091720581055,
            0.022655123844742775,
            0.049777738749980927
        ],
        [
            0.021433115005493164,
            0.03764684125781059,
            0.05299496278166771,
            0.09175610542297363,
            -0.08892366290092468,
            0.004890793468803167,
            -0.032197609543800354,
            -0.051773007959127426,
            0.04287935793399811,
            -0.05506628006696701,
            -0.04288759455084801,
            -0.023698745295405388,
            0.0003328262537252158,
            0.000888949551153928,
            0.05581933632493019,
            0.07503154873847961,
            -0.052691500633955,
            0.04546463489532471,
            0.09409327059984207,
            -0.041001096367836,
            0.059380993247032166,
            -0.0010914927115663886,
            0.005864985287189484,
            0.03298475593328476,
            0.014218597672879696,
            0.006566318217664957,
            -0.006245986092835665,
            0.05666544288396835,
            0.038448262959718704,
            -0.08219673484563828,
            0.024425296112895012,
            -0.04549530893564224,
            -0.05513419210910797,
            0.08911119401454926,
            -0.03038419596850872,
            -0.0251662265509367,
            -0.05979754775762558,
            -0.04959387332201004,
            -0.09344230592250824,
            -0.02937285043299198,
            0.04373373091220856,
            -0.03550896793603897,
            0.03303772956132889,
            -0.07879386842250824,
            -0.017477605491876602,
            0.03171389922499657,
            0.012961510568857193,
            0.03279673308134079,
            -0.06924024224281311,
            0.05549664422869682,
            0.04585206136107445,
            -0.10145583003759384,
            0.018469559028744698,
            0.06712427735328674,
            -0.016314348205924034,
            0.0644800215959549,
            -0.06614569574594498,
            -0.1010824665427208,
            0.04319005832076073,
            0.04978617653250694,
            -0.054334186017513275,
            -0.038462262600660324,
            -0.012783003970980644,
            0.09287931025028229,
            -0.04146385192871094,
            0.01347033679485321,
            -0.06059340387582779,
            0.023597853258252144,
            -0.006537836976349354,
            -0.059639766812324524,
            -0.031304679811000824,
            0.055161431431770325,
            0.08032770454883575,
            -0.029351022094488144,
            -0.06636376678943634,
            0.06880029290914536,
            0.03696339949965477,
            -0.04154577478766441,
            -0.05625184252858162,
            0.07300745695829391,
            0.04321528971195221,
            0.016006367281079292,
            0.003778602695092559,
            0.0024023044388741255,
            0.060259513556957245,
            0.008112835697829723,
            -0.07369086891412735,
            -0.11582796275615692,
            -0.051422469317913055,
            -0.06417446583509445,
            -0.09302119165658951,
            -0.016305504366755486,
            0.056752149015665054,
            0.03797131031751633,
            0.041526176035404205,
            -0.010146393440663815,
            -0.008837027475237846,
            0.09266012161970139,
            -0.02909434400498867,
            -0.10995493829250336,
            0.03307841345667839,
            -0.06245534121990204,
            0.04927017539739609,
            -0.007617184426635504,
            0.013070125132799149,
            -0.003095414489507675,
            0.05626429244875908,
            -0.08159952610731125,
            -0.06232985481619835,
            -0.042182501405477524,
            0.020169170573353767,
            0.007484333124011755,
            0.04174499586224556,
            0.07465066760778427,
            0.05967665836215019,
            0.13392436504364014,
            -0.07425130158662796,
            -0.004781652241945267,
            -0.06820452958345413,
            0.0794108659029007,
            -0.08252773433923721,
            -0.028533272445201874,
            0.013515186496078968,
            -0.003620065748691559,
            0.06927498430013657,
            -0.021683281287550926,
            -0.05208170786499977,
            0.005230359733104706,
            0.012110008858144283,
            -0.03402264788746834,
            -0.026320019736886024,
            0.008588490076363087,
            0.06727079302072525,
            -0.08919727802276611,
            0.017929673194885254,
            0.02599526196718216,
            -0.008002919144928455,
            -0.02307761274278164,
            0.06656958907842636,
            0.049561887979507446,
            0.05076203867793083,
            0.04637444391846657,
            0.05041501671075821,
            0.09223277866840363,
            0.03707998991012573,
            0.07361709326505661,
            -0.017405806109309196,
            -0.026295972988009453,
            -0.041420456022024155,
            0.06194086745381355,
            0.06675489246845245,
            0.10436054319143295,
            0.06559296697378159,
            0.05450694262981415,
            -0.0980609580874443,
            -0.029724163934588432,
            -0.037367306649684906,
            -0.04691985622048378,
            0.005875614006072283,
            -0.025129593908786774,
            0.06411857157945633,
            -0.0008057598606683314,
            -0.04150579869747162,
            -0.05480227246880531,
            0.007864278741180897,
            0.07700309157371521,
            0.05036650225520134,
            -0.07064350694417953,
            -0.052907656878232956,
            0.06002559885382652,
            0.03134608641266823,
            -0.07887396216392517,
            -0.09076563268899918,
            -0.03380708396434784,
            0.04399615526199341,
            0.045238010585308075,
            0.05431811138987541,
            -0.04751243069767952,
            -0.05513066053390503,
            0.05169401690363884,
            0.014222453348338604,
            -0.01439665723592043,
            -0.06275653839111328,
            -0.011204387992620468,
            0.01540745422244072,
            0.03405914083123207,
            -0.03523234277963638,
            -0.06658169627189636,
            -0.02208218351006508,
            0.04659102484583855,
            0.004459424875676632,
            -0.025579769164323807,
            0.07783385366201401,
            0.0575142502784729,
            -0.0862422063946724,
            -0.044221729040145874,
            0.11290150880813599,
            -0.08033127337694168,
            -0.12019586563110352,
            0.016555698588490486,
            -0.04580899700522423,
            0.021484030410647392,
            -0.03739482909440994,
            -0.02159981243312359,
            -0.050953932106494904,
            -0.0021255542524158955,
            -0.010050271637737751,
            0.0003238173376303166,
            -0.022959504276514053,
            -0.0022782979067415,
            -0.0996561199426651,
            0.08002868294715881,
            0.07422924786806107,
            -0.005098644644021988,
            -0.027333304286003113,
            0.0978885218501091,
            -0.022633802145719528,
            0.018355468288064003,
            0.056046321988105774,
            0.053569089621305466,
            -0.05405176430940628,
            -0.022265098989009857,
            -0.04731746390461922,
            0.0003849342174362391,
            0.061087995767593384,
            0.09362742304801941,
            0.06852332502603531,
            0.04905467480421066,
            0.0255229864269495,
            0.029676737263798714,
            0.016267603263258934,
            0.027105901390314102,
            -0.06972574442625046,
            -0.0023972990456968546,
            0.008653845638036728,
            0.013835246674716473,
            0.06930802762508392,
            -0.008876585401594639,
            -0.02888813428580761,
            0.04182736203074455,
            0.05065803974866867,
            -0.0374520979821682,
            0.04572634398937225,
            -0.07947366684675217,
            -0.017845991998910904,
            0.06656892597675323,
            -0.10567834228277206,
            -0.031789131462574005,
            0.034245919436216354,
            -0.07127728313207626,
            0.040256842970848083,
            0.051891740411520004,
            -0.04754592478275299,
            -0.031177978962659836,
            -0.049752842634916306,
            0.05338206887245178
        ]
    ],
    "linear_relu_stack.4.bias": [
        -0.015483071096241474,
        -0.04077065736055374,
        0.011820108629763126,
        -0.055182237178087234,
        -0.010186674073338509,
        0.030525799840688705,
        -0.042320482432842255,
        -0.016704853624105453,
        0.03292648866772652
    ]
}

export default model;