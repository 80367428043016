import { useState } from "react"
import "./BruteForceDescription.css"

function CardForExperts() {
    const [isToggled, setIsToggled] = useState(false)
    return (
        <>
            <button 
                className="description-name"
                onClick={() => setIsToggled(!isToggled)}
            >
                Model Card For Experts
                {isToggled && <div className="close-btn">X</div>}
            </button>
            {isToggled && <div className="description-bottom-div">
                <div className="bottom-description-container">
                    <h4 className="second-heading">What is a model card?</h4>
                    <p className="model-description">
                      Model cards are a proposed way of giving of disclosing 
                      information about a machine learning model to those who
                      interact with it. The original paper describing model cards
                      can be found <a href="https://arxiv.org/pdf/1810.03993">here</a>.
                    </p>
                    <h4 className="second-heading">Model Architecture:</h4>

                    <p className="model-description" >
                      The model is a deep neural network. Much of the training 
                      procedure was borrowed from this <a href="https://pytorch.org/tutorials/beginner/basics/quickstart_tutorial.html">image classification tutorial</a>, 
                      but I had to end up modifying lots of the code to work for 
                      this application.
                    </p>

                    <p className="model-description">
                    The final model I came up with had 3 layers: 
                    <ol>
                      <li>First layer: 9 inputs with 1024 outputs and a ReLU activation</li>
                      <li>Second layer: 1024 inputs with 256 outputs and a ReLU activation</li>
                      <li>Third layer: 256 inputs with 9 outputs and no activation function</li>
                    </ol>
                    I kept the model internationally as simple as possible because 
                    I knew I wanted to end up coding forward passes in Javascript 
                    to run in the browser. The ideal architecture would at least 
                    include a softmax in the final layer as it is a 
                    classification problem.
                    </p>

                    <h4 className="second-heading">Training Data:</h4>
                    <p className="model-description">
                    The model was trained on 260,000 simulated games of 
                    tic-tac-toe where player x moved almost randomly and player 
                    o moved randomly unless there was an obvious move to either 
                    win the game or prevent giving x the opportunity to win the 
                    game.
                    </p>
                    <p className="model-description">
                    The model was only then trained on games where O won. The 
                    board before O's turn was the input to the model and a 
                    one-hot vector representing the index that O moved was the label.
                    </p>

                    <h4 className="second-heading">Training:</h4>
                    <p className="model-description">
                    Model was trained extensively, while maintaining similar 
                    accuracy in the training and testing sets:
                    </p>
                    <img src="experts1.png" className="description-img"></img>
                    <p className="model-description">
                    The orange line on the graph above is for the testing set 
                    and the blue line is for the training set. The x axis is the 
                    average loss for a set of 200 examples.
                    </p>
                    <h4 className="second-heading">Model Strengths:</h4>
                    <p className="model-description">
                    The model acts reasonably when the correct move is very 
                    obvious. One example of this is shown below, with the 
                    explanations coming from the “gold standard” technique 
                    described above.
                    </p>
                    <img src="experts2.png" className="description-img"></img>
                    <h4 className="second-heading">Model Weaknesses:</h4>
                    <p className="model-description">
                    The model fails most when the correct answer is not entirely 
                    obvious, or when coming up with the right move would take 
                    some extra reasoning. Below is one such example (again with 
                    the gold standard explanation technique).
                    </p>
                    <img src="experts3.png" className="description-img"></img>
                    <h4 className="second-heading">Intended Use:</h4>
                    <p className="model-description">
                    This model should be used for educational purposes. It may 
                    also be a reasonable benchmark for training your own 
                    tic-tac-toe models. The moves the model makes are not 
                    guaranteed to be examples of good strategy. 
                    </p>
                </div>
                {/* <img src="brute_force_copy.png" className="brute-force-graphic bottom-description-container"></img> */}
                {/* <div>hello</div> */}
            </div>}
        </>
    )
}

export default CardForExperts