import "./Header.css"

function Header() {
  return (
    <header>
      <h1>Explainable Tic-Tac-Toe</h1>
    </header>
  )
}

export default Header;
