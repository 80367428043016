import model from "./modelAsJsVar"

const matmul = (matrix, vector) => {
  let output = []

  for (let i = 0; i < matrix.length; i++) {
    const row = matrix[i]

    let sum = 0
    for (let j =0; j < row.length; j++) {
        sum += matrix[i][j] * vector[j]
    }
    output.push(sum)
  }
  return output
}

const addBias = (biasVector, vector) => {
  let output = []
  for (let i =0; i< vector.length; i++) {
    output.push(vector[i] + biasVector[i])
  }
  return output
}

const ReLU = (vector) => {
  let output = []
  for (let i = 0; i < vector.length; i++) {
    if (vector[i] >= 0) {
      output.push(vector[i])
    } else {
      output.push(0)
    }
  }
  return output;
}

const getValidMoves = (board) => {
  let validIdxs = []

  for (let i = 0; i < board.length; i++) {
    for (let j = 0; j < board[0].length; j++) {
      if (board[i][j] == "") {
        validIdxs.push(i*3 + j);
      }
    }
  }
  return validIdxs;
}

const selectMoveFromOutput = (board, output) => {
  const validIdxs = getValidMoves(board)
  // console.log(validIdxs)

  let max_score = -10
  let best_idx = 0
  for (let i = 0; i < output.length; i++) {
    if (validIdxs.includes(i)) {
      // console.log(i, output[i])
      if (output[i] > max_score) {
        max_score = output[i]
        best_idx = i
      }
    }
  }
  return best_idx
}

const runModel = (board) => {
  const firstMatrix = model['linear_relu_stack.0.weight']
  const firstBiasVector = model['linear_relu_stack.0.bias']
  const secondMatrix = model['linear_relu_stack.2.weight']
  const secondBiasVector = model['linear_relu_stack.2.bias']
  const thirdMatrix = model['linear_relu_stack.4.weight']
  const thirdBiasVecotr = model['linear_relu_stack.4.bias']

  // console.log(board)

  const modelInputStr = []
  for (let i =0; i < board.length; i++) {
    for (let j= 0; j < board[0].length; j++) {
      modelInputStr.push(board[i][j])
    }
  }
  let modelInput = []

  for (let i = 0; i < modelInputStr.length; i++) {
    // console.log(modelInputStr[i])
    if (modelInputStr[i] === '') {
      modelInput.push(0);
    } else if (modelInputStr[i] === "X") {
      modelInput.push(1);
    } else {
      modelInput.push(-1);
    }
  }

  // console.log(modelInput)
  const secondMatrixInput = ReLU(addBias(firstBiasVector, matmul(firstMatrix, modelInput)))
  const thirdMatrixInput = ReLU(addBias(secondBiasVector, matmul(secondMatrix, secondMatrixInput)))
  const output = addBias(thirdBiasVecotr, matmul(thirdMatrix, thirdMatrixInput))

  // console.log(output)
  const best_idx = selectMoveFromOutput(board, output)
  return [[Math.floor(best_idx / 3), best_idx % 3], output]
}

export default runModel