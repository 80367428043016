import { useState } from "react"
import "./BruteForceDescription.css"

function LIMEDescription() {
    const [isToggled, setIsToggled] = useState(false)
    return (
      <>
        <button 
          className="description-name"
          onClick={() => setIsToggled(!isToggled)}
        >
        Technical Description of LIME
        {isToggled && <div className="close-btn">X</div>}
        </button>
        {isToggled && <div className="description-bottom-div">
          <div className="bottom-description-container">
            <p className="model-description" style={{marginTop: "35px"}}>
            The original <a href="https://dl.acm.org/doi/pdf/10.1145/2939672.2939778">LIME paper</a> proposes using interpretable models to 
            provide explanations for model behaviors in a controlled 
            circumstance. The idea behind the paper is by limiting the input to 
            a smaller space, you can approximate model behavior well with a more 
            interpretable model.
            </p>
            <p className="model-description">
            In my implementation, I borrowed the framework and the intuition 
            from the authors, but I had to put my own spin on it for this 
            application. The exact proposal the author's use would suggest that 
            I sample explanations by adding masks of 0s, but this doesn't make 
            any sense when the inputs or possible explanations are already so 
            sparse. What I did instead was train linear models to produce the 
            same labels as the deep neural network, and use the weights for a 
            given move as an explanation.
            </p>
            <p className="model-description">
            While the deep neural network you are playing against in the web 
            interface is a single model, there are a total of 9 linear models 
            used for the lime explanations. A different model is used based on 
            the initial move on the board. The squares for the LIME board in the 
            interface above are colored based on the weights in a row of the 
            relevant model based on the relevant move. Green means that square 
            contributed positively to the chosen move while red means the square 
            contributed negatively. In other words, green squares explain why a 
            model may have chosen a move while red squares are places that may 
            have made the model unsure.
            </p>

            <p className="model-description">
            The image below shows a heatmaps of the weights of one of the LIME 
            models. Notice how small and simple they are. There are 18 inputs 
            (the first 9 for a bitmap of where Xs are located and the next 9 
            for a bitmap of where Os are). The outputs represent the expected 
            moves and the weights represent the explanations turned to colors 
            in the web interface.
            </p>
          </div>
          <img src="LIME_copy.png" className="brute-force-graphic bottom-description-container"></img>
            {/* <div>hello</div> */}
        </div>}
      </>
  )
}

export default LIMEDescription