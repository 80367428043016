const LIME_obj = {
    "0": [
        [
            -0.0014149480572243855,
            -0.00012263778375089446,
            -0.020237597939080566,
            -0.00029479434568680776,
            -2.2812017712241537,
            0.0017508655234319845,
            0.026726185675668448,
            -0.07637692607918083,
            0.0253895882698184,
            0.4901565998592797,
            -0.13164046667186988,
            1.437740129360163,
            -1.0455822207053314,
            -0.0020713448539571096,
            0.9875426388043608,
            0.38929320275727625,
            -0.25452525205554893,
            0.32832336349063973
        ],
        [
            0.001648804605300307,
            -0.1759122369465681,
            0.7972482588619645,
            -0.1754183152004343,
            0.2897601457493653,
            -0.1604559556662659,
            -0.16020013760216809,
            -0.1647692481728671,
            -0.2011191995059428,
            -2.0377710447625654,
            0.48193022773422434,
            1.2862356116110059,
            1.4160471922660407,
            0.17698327341712733,
            -1.2246091721045407,
            -0.591800781893831,
            -0.47500614518409556,
            -0.32262985853360365
        ],
        [
            0.0017712534123575743,
            0.848977688154599,
            -0.16166708585351325,
            -0.15057055588991905,
            0.05107089682578815,
            -0.07314120623175144,
            -0.19478609947499512,
            -0.22163562453334132,
            -0.1740431556640234,
            0.6925511554484213,
            -1.4784464322010356,
            -0.2245285536766021,
            0.7433365329328127,
            0.15153149090799997,
            1.6505768496900939,
            2.29303665645386,
            -0.7238723965823609,
            -0.972150967803077
        ],
        [
            0.0008266189873267808,
            -0.26571977895599985,
            -0.29717146151726315,
            -0.2654500462817893,
            -0.13496847677051602,
            -0.22108073406129383,
            0.7278876048916065,
            -0.3038262612110282,
            -0.27492778266888834,
            -1.401413774984737,
            -1.2163828384089932,
            0.9788048247137607,
            -2.084811695482284,
            0.2654630543476451,
            -1.187611831272245,
            0.05585071981266527,
            -1.0532469209319795,
            -0.08318843010074853
        ],
        [
            0.9996675966043231,
            -0.8207248450078072,
            -0.8126200201648465,
            -0.8207926163090077,
            0.6990698886909948,
            -0.8124794538437293,
            -0.8296391566991322,
            -0.8508019045930643,
            -0.8145089609726068,
            -1.319561279362529,
            -0.05596245727470142,
            -1.059869140722357,
            1.0620754780482933,
            -0.17974331252252324,
            -0.7779491841353349,
            0.1462856592584262,
            -3.070262384925333,
            1.683853446305808
        ],
        [
            0.0010910488013243002,
            0.04362348654001784,
            0.03578727670799947,
            0.043844040599056465,
            0.21309451374120097,
            0.056264392942960234,
            0.035343751060719616,
            0.07066063489263577,
            0.026140731322567796,
            -0.9745755703443829,
            0.6132890334189751,
            0.7829100427821316,
            -0.6154591996126516,
            -0.042584387917589804,
            1.8194579433684295,
            0.9523203967635173,
            0.8366472952460152,
            0.18408701962917753
        ],
        [
            -0.0015071898379114097,
            -0.6766753100454083,
            -0.6909652743617966,
            0.32307536930513286,
            0.02179960312206814,
            -0.6870539179693319,
            -0.651067221035855,
            0.2789358162108152,
            0.3488646736586184,
            1.3584182277324701,
            -0.5446928293594522,
            -1.206620078210824,
            0.03341084136864289,
            0.6747814852808318,
            0.4811879923260033,
            1.540265694545424,
            -0.2814527878965105,
            -0.2126194781193954
        ],
        [
            -0.004238251860127674,
            -0.08411032345847812,
            -0.05818182457803785,
            -0.08530278265084197,
            -0.8130395142508104,
            0.8421300667785462,
            -0.0711650164524502,
            -0.04182616923721841,
            -0.020813542086756634,
            -1.3877529892170164,
            0.936588813379304,
            0.9571952720980216,
            0.25836756112695636,
            0.08138160617653065,
            -1.057074724585927,
            1.6372247884868072,
            0.4810945938109637,
            -0.23758462169393493
        ],
        [
            -0.0031807331526383614,
            -0.48741708972820863,
            -0.49671357922684695,
            -0.4884278785195312,
            -0.6402182606527309,
            -0.5070830271298107,
            -0.5033080901350185,
            -0.42864526517753443,
            -0.4375275324161249,
            -0.7057961294263804,
            2.9361886953797875,
            -0.2028180043973707,
            -0.5585748660214737,
            0.48491808997553676,
            0.04282286709104887,
            -1.052578291421028,
            1.2793919460010403,
            0.40981863226772086
        ]
    ],
    "1": [
        [
            -0.4573421404431192,
            -0.005118846117083181,
            0.542550696844876,
            -0.4354623196110525,
            -0.16894467370791638,
            0.5245455873236978,
            0.3954438174846095,
            -0.37112164833986716,
            -0.44125141180954913,
            -1.7053965374656497,
            1.153988652621409,
            2.5795415040636156,
            0.31759182727013185,
            0.45215565768894456,
            -0.4321284043988561,
            0.2590655479933893,
            1.1349677434324799,
            2.039306175338261
        ],
        [
            0.6652274129053419,
            0.0015740205410258016,
            0.6653666978862122,
            0.6890073101349522,
            0.9619642623413,
            0.6584864698995149,
            0.7015746102775349,
            0.6372056838944792,
            0.6623460020665487,
            -0.19037267908769595,
            -2.2301213706304934,
            -0.7951039419945947,
            0.15303598885943234,
            -0.6638194472154186,
            1.0060605803778362,
            -0.8847020600172409,
            -0.45622265843475185,
            0.022700542365039572
        ],
        [
            0.6154039121885226,
            -0.0015428504558396192,
            -0.38455718781185805,
            -0.4317781652586998,
            1.197814603334416,
            -0.387397493252256,
            -0.3398320727018524,
            -0.3487005678872113,
            -0.3857149193827509,
            -1.3308074797795966,
            -0.599612649571511,
            -0.06845671848245699,
            -1.2399541720411946,
            0.3832295684358949,
            1.6070548698742637,
            -0.17079130621455463,
            -0.9945775051316939,
            -0.8947636840529954
        ],
        [
            0.2112993149285242,
            0.00015668455406997556,
            0.21145656594884962,
            0.20745743836168723,
            0.9716177294693449,
            0.20595030683676913,
            0.23291113963180726,
            0.20124296350215767,
            0.2238669233245777,
            -0.3279909926606013,
            -1.6415180119025312,
            -0.7884904755937997,
            0.4979690062270003,
            -0.21138213238489106,
            -1.706130878162531,
            0.17148089716134143,
            0.7104314274944256,
            0.05050611400475756
        ],
        [
            0.03870775538893402,
            0.9972669712189361,
            0.03814557575994785,
            0.03891296616922688,
            -1.3591406622609197,
            0.03188444525990854,
            0.022870595642927212,
            0.14705136303010719,
            -0.03954850157479638,
            0.03627622127584713,
            2.7491296905264884,
            -0.1817746910492378,
            0.05161174209141314,
            -1.0398463625703152,
            -0.5215281500873864,
            -0.5088692278070941,
            -0.7792842276307367,
            -0.5677351327623141
        ],
        [
            0.35473315403351535,
            0.001248213148679441,
            0.3548279160314519,
            0.34242779256833406,
            0.36870270248724624,
            0.36359102579031627,
            0.3733926463363727,
            0.31878875296288856,
            0.3710751595223846,
            0.15778605847953767,
            -1.036976465353936,
            0.6064752144919958,
            -2.317525498535427,
            -0.35378337581790975,
            -0.5424517544885169,
            0.8183139402028434,
            -1.3723557874487844,
            -0.6459607215100147
        ],
        [
            0.2784333744185565,
            -0.005344144080944738,
            0.27805917489405996,
            1.2204227752375696,
            -0.9536778171813473,
            0.2904539090104693,
            0.1539891087072613,
            0.3729576503570701,
            1.2896363058875941,
            0.18754016624103761,
            0.18528654280382703,
            -1.1328106835571365,
            0.9902615829617935,
            -0.2833515401333592,
            -0.5891237045123578,
            0.032651139046331636,
            -2.437546084530834,
            0.3082456139046963
        ],
        [
            -0.016129691638668422,
            -0.001014804593959993,
            -0.015620518258000161,
            -0.03157491380154124,
            -0.5470357488871204,
            -0.030177851682047806,
            -0.014936611315810654,
            -0.038582338808031615,
            0.04959180138098343,
            -0.17941080764841155,
            0.449223836960712,
            -0.22155366507688265,
            -0.3314106678316247,
            0.013984995382646146,
            2.179335457451891,
            0.36947836823466074,
            -0.10465417661869256,
            0.7473908197787207
        ],
        [
            -0.4556564343182362,
            0.003083536746210347,
            -0.45579415073774965,
            -0.4878652855333177,
            -0.626957208175426,
            -0.43602477116682775,
            -0.3539866095777829,
            0.5074302850679027,
            -0.4869999959681236,
            -0.4981490730884843,
            -1.0533757802931083,
            1.6053194237729724,
            -0.5878361108197917,
            0.4592982998957709,
            0.6948336277135798,
            0.3828499074249521,
            -1.9180005195715515,
            1.385549889823867
        ]
    ],
    "2": [
        [
            -1.0046050829396949,
            -0.06620472770975877,
            0.0004638100342185114,
            -1.1018507000772528,
            0.4262956679345405,
            -1.0677296014709297,
            -1.0632939688269332,
            -1.0662472146553665,
            -1.088541367664875,
            0.059404138380513116,
            -1.5149293457025206,
            -0.21579603761649252,
            0.23040998880940414,
            1.0667232395392992,
            -0.7996753003969501,
            -1.2578375193904414,
            0.24507571838941333,
            -0.2321440153742275
        ],
        [
            0.6215311469227184,
            -0.2909162622625324,
            0.0026178814283627618,
            -0.3462734959944347,
            0.22749548685605256,
            -0.2888162084307717,
            -0.1873209632111027,
            -0.2648485091678472,
            -0.2666599613256249,
            0.383628762831558,
            0.017859656157583424,
            -0.6441413009020699,
            -1.240863895034133,
            0.29330338392659944,
            0.10667216713545567,
            -0.25068461408305004,
            -0.08474419415208072,
            -0.6930138713123953
        ],
        [
            0.5350809980334276,
            0.49921747366225294,
            0.0007778955612549469,
            0.44725381366300004,
            -1.4557905333312418,
            0.49841281778690943,
            0.5300393325754459,
            0.47316660796858523,
            0.526198067445852,
            -0.8945895292590663,
            -0.33797530391046676,
            1.2638499996970358,
            0.20325734596445202,
            -0.49832407118812594,
            0.5391654624667621,
            -0.6591703118292297,
            0.0034587070627399093,
            1.2604070115930996
        ],
        [
            -1.2790472164445377,
            -1.2515667731599223,
            0.00011942207090640497,
            -1.265358761865179,
            -0.4382431947468378,
            -1.2507932178565255,
            -1.2430133749962484,
            -1.2636791431653507,
            -1.174222642869692,
            -0.19044012420496628,
            0.2629387753629918,
            -1.4499096764296562,
            -1.0969914621263726,
            1.2514141374429502,
            -0.42245833034193464,
            0.4665495315069458,
            0.4855412844747691,
            0.975615724861903
        ],
        [
            0.2884819846049857,
            0.3254843691535923,
            0.9998199789346871,
            0.30903818508993924,
            0.5164876030101888,
            0.3265429679178982,
            0.33895310838993564,
            0.2900317739321558,
            0.4591223600847904,
            0.0727043389091306,
            0.28721596549221967,
            -1.0565313223651742,
            1.9907250921578403,
            -1.3261326133929239,
            0.5157229637408884,
            0.8232028180933887,
            -0.3144628551661737,
            -1.5010170863428118
        ],
        [
            -0.04920897013322492,
            -0.1079633371175571,
            -0.001471174495826883,
            -0.035660267227094436,
            0.9294106166093726,
            -0.10966552204168913,
            -0.12628959839456186,
            -0.12664412937401257,
            0.6996858052154009,
            -1.0270761698547215,
            -1.1974670880365166,
            0.5963494258843715,
            0.4820327720790881,
            0.10744271350302449,
            0.9946294944191758,
            0.3458192656501327,
            0.6919641704674797,
            -1.2905451265968995
        ],
        [
            0.12716337339273157,
            0.03897803070896868,
            -0.00020999550331064448,
            0.06708940795156276,
            -0.7885095870654021,
            0.036536545554726255,
            0.05021949171822368,
            0.03294393670917856,
            -0.19265554562478263,
            -0.27585974099124544,
            -0.02867003325976058,
            -0.37019571973035914,
            -0.5820985551679165,
            -0.038019091767007386,
            -0.5140414986895617,
            2.5615092377447812,
            0.8802016076529332,
            -0.22621641635870718
        ],
        [
            0.4114978389148604,
            0.38476064894000256,
            0.0006758880787842777,
            0.37279885375635485,
            -0.004789693494306536,
            0.3840362050506118,
            0.36314399328749836,
            0.44181779239260627,
            0.3254733303022069,
            0.9288820156333405,
            0.8624152337844123,
            1.3808287472630254,
            -2.280282703924433,
            -0.38417123483940335,
            -2.290816368238952,
            -2.800880211729382,
            -0.5551136862857599,
            -0.3134841302955928
        ],
        [
            0.013020147169552907,
            0.04384943705382656,
            -0.0013098997201803986,
            1.0570079517877666,
            -0.2312419760674157,
            1.0448123244691205,
            1.0039000852810507,
            1.0116933639669963,
            0.2039618872935955,
            -0.21806870208187284,
            0.31801443861062906,
            0.751676715115922,
            -0.28415862424051486,
            -0.045801928280443514,
            1.4942073380378451,
            0.6723899318607549,
            -0.024078368104289927,
            -0.597420690115456
        ]
    ],
    "3": [
        [
            -0.4685846463169028,
            -0.4085923815007654,
            -0.4609017575342309,
            -0.0008197012328772636,
            0.2409966010084904,
            -0.4751113337969776,
            0.5312031897794067,
            -0.4826208671234576,
            -0.5235439489741072,
            0.6606501936295318,
            -0.19057559513175976,
            0.7399461351956972,
            -2.232066572218566,
            0.46733879396344324,
            -1.0528965222093256,
            -0.09054740696747203,
            -1.183615805455241,
            -1.4913081481188513
        ],
        [
            -0.5295430492331169,
            -0.5204019374339932,
            -0.5734031425960963,
            0.00018305135738777193,
            -0.5172356694970921,
            -0.5182257942699395,
            -0.5296772467543395,
            -0.45184541331447897,
            -0.5449634459062336,
            -1.2007243110210966,
            0.7881657074017822,
            -1.921028453760268,
            0.11361220361956126,
            0.529951168135779,
            2.25606216609324,
            0.06386617049639084,
            -1.10320748800503,
            0.725321892632184
        ],
        [
            0.26749206055190133,
            0.24038417992046568,
            0.28708564770186396,
            0.0002516700293735741,
            -0.6268394542110942,
            0.220636342157486,
            0.26710062184012556,
            0.23198439652091332,
            0.34141110885905424,
            0.6295228036981421,
            -0.7199222168783289,
            -2.612713438587598,
            -0.5617498235104037,
            -0.26757937941508186,
            -1.4366782644018532,
            1.219941918753224,
            1.6487416428508712,
            0.4512999107184255
        ],
        [
            -0.4070147360625136,
            -0.4358629707189006,
            -0.45366271758566,
            0.0006596014423029998,
            -1.2779241040382432,
            -0.3529013533567775,
            -0.40685513655416733,
            -0.34034774548326346,
            -0.40648146226570786,
            0.40639010364782663,
            -0.4557016872286048,
            0.6485145474920939,
            0.6041199034907531,
            0.40837213927880767,
            -1.1256061983935395,
            -0.5502567347282853,
            -0.5527991758243397,
            1.0740653292552635
        ],
        [
            0.2708489132799381,
            0.2098441928573517,
            0.29223830427002645,
            1.000670704692041,
            1.4416749947548537,
            0.29165855502001303,
            0.27142720680704197,
            0.27657648281276853,
            0.2927990219863066,
            -3.9552220043247104,
            -1.8404864550754785,
            -0.7018173026771635,
            0.013155624238428051,
            -1.2697650211285512,
            0.1687831449615697,
            0.7878800198118452,
            1.6392025720775774,
            1.1271620195741239
        ],
        [
            0.2086311228068293,
            0.20042518026829365,
            0.2855966103404667,
            -0.00032656281834532637,
            -0.08585386567669749,
            0.15034378338407509,
            0.20895982760706733,
            0.1551944221983715,
            0.21111564210497635,
            0.14005851948212567,
            -0.3362733377820181,
            0.5924563027437538,
            -0.8879568044438486,
            -0.2095499722932657,
            -0.14899241865978288,
            1.6624787048718568,
            -0.7486905841607483,
            -0.623223128782468
        ],
        [
            0.5334489154013996,
            0.49568677629257224,
            -0.43190000427666525,
            0.00033627521560121416,
            -0.6261685665911265,
            0.5183847046752502,
            -0.4660100967574819,
            0.5604891883469092,
            0.5329204115026474,
            -1.8394279429968328,
            0.7432772044845083,
            -0.1394132576923214,
            0.43288211926394365,
            0.46693477893087065,
            -0.35822555640923015,
            -0.6476354872373117,
            -0.1422695517409856,
            0.2249726983171766
        ],
        [
            -0.1395573500325951,
            -0.09269484270532148,
            -0.19516689410720792,
            -0.00035423680048251655,
            -1.2716365168220822,
            -0.15629691477257612,
            -0.14079106148476908,
            -0.19727578658226994,
            -0.08245371320586904,
            -0.2127388272104652,
            -0.4040336325367271,
            -0.7468816556299496,
            -1.1152698835879316,
            0.13889982765319542,
            -2.084402097976453,
            -0.31436280494810864,
            0.4900190381883896,
            0.8945180063673357
        ],
        [
            0.031150412468228132,
            0.10008344888676551,
            0.9645009942528927,
            -0.0003925109559779348,
            -1.6675001508648575,
            0.01357935585856277,
            0.030619922197249582,
            0.16991646605937685,
            -0.03185087202533113,
            1.611723542605166,
            1.0159332536661956,
            0.38290644674250157,
            1.1145024322348671,
            -0.03172070382740134,
            -1.343745189648667,
            -0.49004445968729426,
            0.11741330871162256,
            0.7446926327097985
        ]
    ],
    "4": [
        [
            -0.0576985652586558,
            -0.7548299084211323,
            -0.7549024917116832,
            -0.7549670212725177,
            1.0000058811164922,
            -0.7549132400914306,
            -0.7549132932789289,
            -0.7550535840879946,
            -0.7550436465813739,
            -0.24503031331066188,
            -0.8672858759427319,
            -1.4232056732029068,
            2.203282394512527,
            -0.6234861175417025,
            -0.12717835804065042,
            -0.5108506253102036,
            1.3116407228938902,
            -1.5273526103824868
        ],
        [
            -0.7400079634575328,
            -0.17229027093847404,
            -0.17255786031317577,
            -0.17227752026639848,
            3.0263981982899104e-05,
            -0.1722125728716544,
            -0.1723872086181391,
            0.8276854089801824,
            -0.17266457241361258,
            0.17239095764967272,
            -1.5165089925125157,
            0.04028055736786881,
            -0.2935752661741193,
            -0.21688773654330187,
            -1.493195179871238,
            -0.7889784113568373,
            2.047516981959727,
            0.3452871468076393
        ],
        [
            0.7259866361679986,
            0.7563611830215535,
            0.7564139807921393,
            0.7562703398643068,
            5.37434820475537e-06,
            0.7563256629816503,
            1.7562681724561633,
            0.7563348046491003,
            1.7561251609281936,
            -0.7563075023592093,
            -1.0838842747072515,
            -0.077235409347247,
            -0.06157307865239825,
            -0.20250741022639795,
            0.46950657310093413,
            -0.31412055664918864,
            0.6711228651469743,
            -0.4555418589314915
        ],
        [
            0.8264220936558339,
            -0.6122574102906734,
            -0.6118736196922513,
            -0.6122945274985021,
            -2.392079771614264e-05,
            0.38775486300595247,
            -0.6121654833611592,
            -0.6121539775984651,
            -0.6120766479987616,
            0.6121282486054919,
            -1.4411376784515202,
            2.0414570748930303,
            -0.8080265523526792,
            0.4671865751580464,
            0.727090937795352,
            -0.16209722578693264,
            -1.275849668950358,
            -0.7732996994851902
        ],
        [
            -0.7702489674739142,
            -0.2665251324263033,
            -0.2664418152378308,
            -0.26647304003055367,
            -5.172603645126505e-07,
            -0.26658793365005007,
            -0.2665342883056144,
            -0.26643540631596163,
            -0.26651862645434754,
            0.26648839318444617,
            0.1673081806932183,
            -0.68362644149306,
            1.398818663604335,
            1.1288578428439482,
            1.0564004750336733,
            1.2612970941247676,
            -0.18528771978678998,
            -0.05364719149930287
        ],
        [
            -0.5037627707971475,
            0.07593996314728071,
            0.07550594679516474,
            1.0758021563422486,
            1.2777147443109103e-05,
            0.0759908153392203,
            0.07588622381790927,
            0.07581268449044953,
            0.07586779113656121,
            -0.07580324311338736,
            -0.45273229406792137,
            -0.9941656001324326,
            -0.6180855541889201,
            -0.9611840413502583,
            0.304513093195093,
            -0.973403182461378,
            0.5866346295785562,
            -1.4212055162261488
        ],
        [
            -0.16356343097473003,
            -0.02649771846758946,
            0.973170190388971,
            -0.026561631577744035,
            2.323875355822385e-05,
            -0.02659291082093816,
            -0.026623842673195133,
            -0.026646643144887478,
            -0.026783100348589464,
            0.026670450031965314,
            0.2902380266929622,
            2.0004923338098153,
            -1.4353127565913342,
            2.1017997597325695,
            1.6744570553977558,
            -0.028728263765993266,
            0.5403424500772246,
            -0.581357513915237
        ],
        [
            0.5478320069841341,
            0.4133565961209434,
            -0.5865318847121066,
            -0.5866409164301598,
            -3.775355659733549e-05,
            -0.5863199317501906,
            -0.5864321265711021,
            -0.586493522430632,
            -0.5860036073872968,
            0.5864295188095952,
            0.4586023272341185,
            -0.9920801339827342,
            1.0914515504216862,
            0.7109000142885051,
            -0.6937739533776742,
            0.11808574082109421,
            -0.4125203718832525,
            -1.1184224010358084
        ],
        [
            1.2081563060968743,
            0.051878855694503,
            0.05189824727235619,
            0.05174963529133793,
            -1.0965079832057192e-05,
            0.05177744153064005,
            0.05181745785856302,
            0.051735435155079196,
            0.05190746853493645,
            -0.05180941755611971,
            2.714455781363434,
            -1.2517722819587318,
            -0.08175681263670535,
            -0.8423764998579847,
            -1.3080178245849026,
            0.5967532208850675,
            1.8009322762507598,
            -0.6105635850189762
        ]
    ],
    "5": [
        [
            -0.012921492298477525,
            0.8483824409570968,
            -0.032970073799501426,
            -0.043324664752307486,
            0.04184384639892274,
            -0.00220541962236155,
            -0.021960022212847614,
            0.03737693061041056,
            -0.034583533812750825,
            2.4775444823247765,
            0.028440918813346785,
            -0.7370499217447201,
            -0.6339255136323476,
            0.027949223125078707,
            -2.763589851348872,
            -3.195133997402469,
            0.6601739738140089,
            -0.21522616253346258
        ],
        [
            0.6505385467427709,
            0.6283060793778232,
            0.6662481759851131,
            0.6469594670215402,
            -1.6000876902390762,
            -0.0021345384881097337,
            0.6468044107403039,
            0.7241578327185447,
            0.6640599759135963,
            -0.9648055980186784,
            -1.0327207826530023,
            0.19649176903417911,
            0.3613037809964845,
            -0.6701383250545604,
            -0.1452505456398973,
            0.3231589134488603,
            -0.641948847736087,
            -1.105856626634369
        ],
        [
            -0.08855514392056725,
            -0.15635899896652275,
            -0.23368493272794602,
            -0.31034245926987997,
            0.2551191012605659,
            0.004971673585019793,
            -0.1753545602845038,
            0.6395776888878227,
            0.7721884332518094,
            -0.6139646628308152,
            -1.0755334677832895,
            -0.18530457683345572,
            -0.5532945849495796,
            0.24172604656575902,
            0.34354925493026417,
            -0.3941121596583565,
            0.9945824486041206,
            -1.1820970768758716
        ],
        [
            -0.7107071622781318,
            -0.5715747146456629,
            -0.580337538752285,
            -0.5353388857092829,
            -1.2423822807315394,
            -0.001264270641783972,
            -0.569472428565248,
            -0.5616598899190915,
            -0.5823183989803036,
            0.6163745413506063,
            -0.5224914279579937,
            0.5596987771127289,
            0.10165024064267227,
            0.5792442399294959,
            0.008583372300935761,
            0.6833168034329876,
            1.3952937611969194,
            -0.267976704701729
        ],
        [
            -0.018238439773131998,
            -0.08913290192424811,
            -0.0831221111632116,
            -0.07399045340322695,
            0.9298028734295828,
            1.0013544289019218,
            -0.08169507266244545,
            -0.11024397237994685,
            -0.08137715703957936,
            0.0013896621254793017,
            -0.8399268651156447,
            0.9817495848357389,
            -2.418663067306388,
            -0.9151295372418482,
            0.06840255727066522,
            0.8806294856043376,
            0.10844357419632286,
            -1.447598966304975
        ],
        [
            -0.8962786352105164,
            -0.8728817158510785,
            -0.8520475269240669,
            -0.9094394921921128,
            0.29137341193909366,
            -0.002605325447465395,
            -0.8592648435850573,
            -0.7889830979633942,
            -0.8548491510300575,
            -1.2439315659302883,
            -1.894417038271806,
            -0.15682758764756746,
            1.3453585396099874,
            0.8477702414210767,
            0.23018029362148285,
            1.5156148502015614,
            -1.0300923587890882,
            0.23407953740584125
        ],
        [
            -0.21759012511864964,
            -0.1555828164387276,
            -0.11561983091958786,
            0.9403755361944597,
            0.9384977988414048,
            -0.0007075441864157938,
            -0.07020794681974062,
            -0.10450242016937072,
            -0.1165111233117999,
            1.8439768103147665,
            0.4793685475032875,
            0.8448616372704404,
            -0.8927111599628093,
            0.11467900525313815,
            0.45080630647548947,
            1.484562595305781,
            -1.427205598582155,
            -0.7290659536132769
        ],
        [
            0.6553565551515325,
            -0.39609816189858094,
            -0.4097658487428214,
            -0.4198240991203463,
            0.2617827887188909,
            9.787942512302132e-05,
            0.543326476441339,
            -0.4044813067764945,
            -0.40961554556063806,
            0.10235724710977938,
            -0.6293453190242726,
            -0.0638703320152746,
            0.16924084149648597,
            0.4096337467440082,
            -2.433362374857634,
            0.2711790152076725,
            -1.9152355500892113,
            0.42491914108049483
        ],
        [
            -0.8007713955374635,
            -0.8176688029444965,
            0.17720910576280557,
            -0.8531315147324663,
            0.4449835070884366,
            -0.001271900127983293,
            -0.868541169550476,
            -0.7867346556496156,
            -0.8242114130213998,
            -0.051103365378396756,
            -1.570263591836512,
            0.2590943586226187,
            0.20634555652077416,
            0.8205978066982529,
            0.548634330173296,
            0.3092622568815273,
            -2.1561121468702966,
            0.5113046240290061
        ]
    ],
    "6": [
        [
            -0.7664743381869682,
            0.18226174213065988,
            -0.6510967331541331,
            0.2732219716756348,
            -0.23389025865451418,
            -0.6557908046356413,
            -0.0020428043550996876,
            -0.7302130290138483,
            -0.7028911175703483,
            0.38068722092215956,
            1.7134322455938582,
            -0.892677525819143,
            1.2432080572790591,
            0.7273129116746699,
            1.3322359844451368,
            -0.5004410657811939,
            -0.19168979431934682,
            0.19922937003749747
        ],
        [
            -0.7029818154833397,
            -0.6716752743250733,
            -0.5559497756571654,
            -0.6320538593520463,
            0.1359700695262286,
            -0.6101486956045935,
            -0.0007420369933472561,
            -0.6337885342965809,
            -0.6085209951729025,
            0.8835890696813197,
            -0.7147903156702647,
            0.19314024571413607,
            0.11856508118035705,
            0.6331069728549784,
            -1.083140030695057,
            1.2583614340890252,
            -0.36625398374042395,
            1.3565305735462978
        ],
        [
            0.13489386814277154,
            -0.01400904500093447,
            0.08083732668193445,
            0.054014619997143534,
            -0.2251548592867706,
            0.08913891435926931,
            -0.0010471948172723552,
            0.05175902598587728,
            0.009687525301906807,
            0.8525877015437652,
            -0.023946790683656325,
            0.8194235084825331,
            0.8921740680401232,
            -0.05391374999464173,
            1.9394766796698553,
            -0.8971212752324714,
            -0.45093707556432494,
            -0.40412701888670866
        ],
        [
            1.2116713981584526,
            0.1506764368825394,
            0.19072373870853127,
            0.15847333662157387,
            1.9651682066990868,
            0.09234694576740246,
            0.0015162470211700946,
            0.15877816873460288,
            0.1216432511107892,
            2.507029354235278,
            -0.35408326044821453,
            -0.20279134175489164,
            -1.853611472900059,
            -0.1566383273786454,
            0.03324796611500504,
            -1.009816162824812,
            -0.21191570238220148,
            0.8580455338634989
        ],
        [
            0.36958886890296466,
            0.28682723113538444,
            0.4847161640192716,
            0.3511152257779639,
            0.47421322746113087,
            0.3159185117140746,
            1.0003329814108735,
            0.34852023081855654,
            0.2804192016065993,
            -1.0001628279651638,
            -0.6435790089951778,
            1.164647038900903,
            -1.1412474336778131,
            -1.3477158363908412,
            0.15874105750496428,
            2.195325130797052,
            -0.5934536219038035,
            0.042561421917939594
        ],
        [
            -0.13468291495869314,
            -0.254071160677855,
            -0.11929048567907136,
            -0.16515342584464743,
            1.8916281629984906,
            -0.09948039696340238,
            -0.0018662874285234083,
            -0.16832159861585239,
            -0.17640291501212657,
            0.075320656432793,
            -1.1634971420074438,
            -1.3499840490975004,
            -1.6086353880960396,
            0.16523562571870726,
            -1.3858677645095017,
            -1.1401171381075577,
            -0.5215290055146539,
            0.13752126696268643
        ],
        [
            0.35180489873274334,
            0.19901827430888078,
            0.2641829235060099,
            0.22758553971909792,
            1.110000857506538,
            0.3072376074704047,
            -0.002425890249275906,
            0.2242069026180002,
            0.034873185579038346,
            -2.1148009810575568,
            -2.9674748766425156,
            1.6523060848057345,
            1.8954588809094326,
            -0.22829527983007403,
            -0.013407645440390362,
            0.6706379395165462,
            -0.5871914326156545,
            2.797256544707318
        ],
        [
            0.15395009738070656,
            0.11146346294956666,
            0.020464354901708698,
            0.0652779988638315,
            -0.7416057845364424,
            0.9694444017129018,
            0.0025119090444542715,
            0.06786481582520458,
            1.0297591397965844,
            -0.2762212034512014,
            -0.021102786891594942,
            1.373980032902098,
            -0.3399906727379816,
            -0.06447475948806453,
            0.59366350470844,
            0.05729037901071622,
            0.630633509749294,
            2.990381785583089
        ],
        [
            -0.27860580831860177,
            -0.2366537864060225,
            0.7225178401887478,
            -0.2709187845560315,
            -0.4832403624953396,
            -0.2982382996505742,
            0.0007617892856277264,
            0.7302088308254471,
            -0.2777730061754298,
            1.1348475893968553,
            0.7135477886053698,
            -1.6629232291504903,
            -1.330808126342064,
            0.2709702722322835,
            -0.6960365856499838,
            -0.42177718001181747,
            0.8562725943959855,
            0.7831175064471939
        ]
    ],
    "7": [
        [
            -0.2130418420551797,
            -0.42118354021280946,
            -0.17723500865231218,
            -0.0625394371398429,
            1.116797680246783,
            -0.20341652614889427,
            -0.20107527418424803,
            0.0004168747672315316,
            -0.20443237572572176,
            -0.3718877408828851,
            -1.3756449533798265,
            -0.48497816781436776,
            0.5227022027540632,
            0.20261179681695776,
            -1.2435140405595344,
            1.1878084236873958,
            0.07381915771130049,
            0.5289475667388589
        ],
        [
            0.08457197043747529,
            0.028716615128482677,
            0.09786677064902596,
            0.14486114077484655,
            0.5074073721816081,
            0.09109975988008619,
            0.09236872671103605,
            0.0003737121825101286,
            0.09107557423708355,
            -1.1123444795325,
            0.17511756920704022,
            0.0953257615050675,
            0.40990531344955505,
            -0.09163924770608851,
            -0.8259399818125593,
            -0.5005857732234896,
            -1.2474777628305695,
            -0.042890545942050905
        ],
        [
            -0.3066249363781113,
            -0.3288909396365498,
            -0.22148997223292544,
            -0.2637341664233571,
            0.42300547671625977,
            0.6667835412653386,
            -0.2929266950205673,
            0.000469612856180126,
            -0.2944904554520189,
            0.4522599169862627,
            1.450104077349803,
            -0.14885377548614773,
            0.2606820798217388,
            0.29387551808532125,
            -0.5868482639628215,
            -1.1392924851945088,
            0.011419396467177276,
            0.8519124728714295
        ],
        [
            0.02734004473263077,
            -0.12291768540825429,
            0.030744550210653972,
            0.06588948698079126,
            0.8842465889828917,
            0.005387960902330966,
            0.01246039667108383,
            -0.000930362644912583,
            0.01109786512805876,
            -0.22335841123773356,
            -1.1813880589609134,
            -0.3629490105408481,
            0.45965480965836536,
            -0.012938741225002645,
            0.5602152534568644,
            -1.3899775406762622,
            1.0641230471435772,
            -0.16203427454829872
        ],
        [
            0.05703687510313752,
            0.02257324300098591,
            -0.0006950577320607243,
            0.0936099969345177,
            0.5317840029459855,
            0.06467102787210136,
            0.0481761902113087,
            0.9997825778367844,
            0.047487988346235466,
            -0.9723283188893637,
            -0.843361009309289,
            1.537604354446657,
            -2.583608670424454,
            -1.0485646271975744,
            -0.7281225930175153,
            0.3902702136039852,
            0.04200215363062687,
            -1.9153865629123068
        ],
        [
            -0.45924709030480565,
            -0.4253571826792296,
            -0.39453652441731574,
            -0.5635687667213894,
            -2.097082461971808,
            -0.41948581805351604,
            -0.44112841160728616,
            0.00041286776501490096,
            -0.4378687732486407,
            0.7420887760003818,
            -0.7596297127239622,
            -0.36467530144094273,
            -0.5310479163148744,
            0.4420249261527247,
            0.15408763454273117,
            -0.8056385630221605,
            0.905018412298652,
            1.1798033373890313
        ],
        [
            0.9032775171319277,
            -0.09233098737768368,
            -0.12706114276190622,
            0.9325118566173142,
            1.087524628690377,
            -0.11183956989161561,
            -0.10454275372646861,
            -0.00017768601670316154,
            0.8944806685283174,
            2.106993698872505,
            -0.29726394378881227,
            1.1797029254265958,
            -0.21424919326851047,
            0.10408525302285462,
            -0.2740011232374672,
            0.5906685749719681,
            1.0681277293474682,
            -1.2414035644778276
        ],
        [
            -0.4308436024446589,
            -0.45155646831608115,
            -0.4378469912258336,
            -0.4238751069418039,
            0.48354919110827765,
            -0.4269307748917272,
            -0.43228282091422965,
            -6.984141268279681e-05,
            -0.43239048700442084,
            0.5990113305930321,
            1.406810035361228,
            0.7108941701670181,
            -0.24777039361706915,
            0.4322587399855225,
            -0.6398243249245444,
            0.2324857569361124,
            1.201093477494693,
            -0.31251009787717515
        ],
        [
            -0.4625683700360864,
            0.41001444145427385,
            0.5211302967545618,
            -0.3606964952311824,
            0.06371184675804395,
            -0.4475944034377545,
            0.5392657088527741,
            0.00015947690796083884,
            -0.46293567087814225,
            -1.549245888500989,
            -1.5206254019487746,
            1.488004015924153,
            -2.5387538534567606,
            0.4613387753646898,
            1.2240618440959836,
            -0.12305826416887049,
            0.22117337073426097,
            1.2370448001743874
        ]
    ],
    "8": [
        [
            0.2843536147798104,
            0.23980360651904903,
            0.33501459129243444,
            0.19473319815700715,
            -1.473784922406027,
            0.27366752127320476,
            0.2886747993620538,
            0.2720730290979809,
            0.0003955763488073547,
            -0.03652014710925859,
            -0.14189575012002398,
            -0.5390598552647469,
            0.7010398957927423,
            -0.2715457478893306,
            0.7644309280921934,
            0.49025379909396893,
            0.13099513246257022,
            0.10565940803651996
        ],
        [
            -0.1342224160848339,
            -0.23300090709832372,
            -0.16907390350898094,
            -0.08531780407382014,
            -2.208252866935817,
            -0.1596719017331527,
            -0.18277118735347275,
            -0.16416985068257048,
            0.001400449757971951,
            -0.39707806384846217,
            0.29611951636264006,
            -1.6490946788671832,
            -1.0758655322263908,
            0.16642947405489344,
            -1.2613365813246362,
            -0.23632079216198407,
            0.3644057983376005,
            1.0008925870186223
        ],
        [
            -0.1672407176883953,
            -0.12762331438763036,
            -0.14807249766073047,
            -0.19567476359340938,
            -1.5442881764912804,
            0.850731392260009,
            -0.10853200192515546,
            -0.14701729589600776,
            -0.0003445115751411155,
            -0.41564387155546617,
            -1.3473460160472557,
            -1.0309593321108872,
            -0.37979336998462143,
            0.14632572361445467,
            2.022880591627518,
            2.2405995483866783,
            -0.32205611435766,
            -0.9985899904517629
        ],
        [
            -0.10346612440382068,
            -0.24360219471185576,
            -0.16755706259850178,
            -0.16166727869520378,
            2.114723196155312,
            -0.15257220194083612,
            -0.12125675018929184,
            -0.15673799819526846,
            0.0018439696591281336,
            0.8482685022335361,
            -0.4460778550396667,
            1.16051290961409,
            0.2815238257341133,
            0.15960123663528283,
            -0.8068617202907447,
            0.6416244688590037,
            -0.12443189729267697,
            -1.1026007389106625
        ],
        [
            -0.0735934906673735,
            -0.11966817026305628,
            -0.07247527215423208,
            -0.13627804106323022,
            -0.3208514689246667,
            -0.09877546701425886,
            -0.10601983705238731,
            -0.10012098956740009,
            1.0002890432569174,
            -0.32153266178070167,
            1.241965229852123,
            -0.17139109582437928,
            1.1395586220394913,
            -0.8994342426314778,
            -1.0910048504975367,
            -1.0393594430130768,
            -0.051677464482957305,
            -0.8614237525759382
        ],
        [
            0.15467930416580722,
            0.13914834035241247,
            1.1998245583536624,
            0.21575088311502735,
            -0.9730619587132321,
            0.1835184236773491,
            0.20702243654406863,
            0.181735664629639,
            0.0007860613391641224,
            0.1513465317151422,
            0.8928945027449384,
            0.8312477333486876,
            0.17296532057961586,
            -0.18062911081710903,
            -2.067316921487982,
            -1.2015754354082728,
            -0.5188394935727014,
            2.996715435588481
        ],
        [
            0.329535794589644,
            0.24414192479214206,
            -0.6449270826962578,
            0.32481418201801454,
            -0.3118793317010694,
            -0.6785521554085812,
            -0.6679312215508376,
            0.3174417888844529,
            0.0013167337394489852,
            0.2847548760689621,
            -0.05860331159492255,
            -1.1004005602138285,
            -0.49876566956165524,
            0.6845707246163056,
            -1.4978797208439434,
            1.1934839803100605,
            -1.3770317865781703,
            1.1771249236206638
        ],
        [
            -0.2592753226524604,
            -0.19483186333148267,
            -0.2655917125800751,
            -0.2167749000077875,
            0.06015692646992105,
            -0.24975491767464902,
            0.7069572833245114,
            -0.24796079341834523,
            -0.0010625413353477148,
            0.8551353413651278,
            0.0031665513965813694,
            0.13696322070626046,
            -2.4619631382020657,
            0.24649417371551832,
            1.887799570054871,
            -0.22368828748954056,
            1.0342075426367092,
            -0.5142402785610319
        ],
        [
            -0.20747633541160346,
            -0.26825061513311294,
            -0.23687958788728974,
            -0.21745273058366252,
            1.6689780364600297,
            -0.22735500354177018,
            -0.22056842164715798,
            -0.2294452175027889,
            0.0008176956888393189,
            -0.21005661679710455,
            -1.0984611132925635,
            -1.0527935031299707,
            0.9113172755118102,
            0.2307587530709511,
            0.47079748418119016,
            -1.3420030124632274,
            -1.2501665429900561,
            0.7470463264179593
        ]
    ]
}

export default LIME_obj