import { useState } from "react"
import "./BruteForceDescription.css"

function ConfidenceDescription() {
    const [isToggled, setIsToggled] = useState(false)
    return (
      <>
        <button 
          className="description-name"
          onClick={() => setIsToggled(!isToggled)}
        >
        Technical Description of Confidence
        {isToggled && <div className="close-btn">X</div>}
        </button>
        {isToggled && <div className="description-bottom-div">
          <div className="bottom-description-container">
            <p className="model-description" style={{marginTop: "35px"}}>
            The model running on this website is a deep neural network. The 
            output of the network was optimized to predict the next move based 
            on simulated games of tic-tac-toe that the player playing “O” won. 
            The output of the network is therefore “the best guess” the model 
            has for where it should play. In the confidence explanation, I color 
            squares the model really wants to move to bright green, with lighter 
            greens representing places where the model is less sure. The 
            brighter the green, the more confident the model is that moving to 
            a given location would be a good idea.
            </p>
            
          </div>
        </div>}
      </>
  )
}

export default ConfidenceDescription