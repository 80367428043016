import "./MainBoard.css"
import "../App.css"

const numToLetter = {
  0: 'a',
  1: 'b',
  2: 'c', 
  3: 'd',
  4: 'e',
  5: 'f',
  6: 'g',
  7: 'h',
  8: 'i'
}

function numToColorStr(num) {
  if (num === 0) {
    return "rgb(255, 255,255)"
  }

  if (num < 0) {
    num *= -2
    if (num > 1) {
      num = 1
    }
    // console.log(num)
    return `rgb(255, ${((1- num) * 255) - 50}, ${((1- num) * 255) - 50})`
  } else {
    if (num > 1) {
      num = 1
    }
    return `rgb(${((1- num) * 255) - 50}, 255, ${((1- num) * 255) - 50})`
  }
}

function BruteForceBoard( {pieces_arr, updateBoard, bruteForceOutput} ) {
  // console.log(bruteForceOutput)
  return (
    <div>
      <h2 className="board-header">Gold Standard Explanation</h2>
      {pieces_arr.map((row, row_idx) => {
        return (
          <div key={row_idx} className="row">
            {row.map((piece_str, col_idx) => {
            //   console.log("here")
              return (
                <div
                  style={{backgroundColor: numToColorStr(bruteForceOutput[numToLetter[row_idx*3 + col_idx]])}}
                  key={row_idx * 10 + col_idx}
                  className="square"
                  onClick={() => {
                    updateBoard(row_idx, col_idx)
                  }}
                >{piece_str}</div>
              )
            })}
          </div>
        )
      })} 
    </div>
  )
}

export default BruteForceBoard;