import { useState } from "react"
import "./BruteForceDescription.css"

function Resources() {
    const [isToggled, setIsToggled] = useState(false)
    return (
        <>
            <button 
                className="description-name"
                onClick={() => setIsToggled(!isToggled)}
            >
                Resources
                {isToggled && <div className="close-btn">X</div>}
            </button>
            {isToggled && <div className="description-bottom-div">
                <div className="bottom-description-container">
                    <p className="model-description">
                      Below are a few resources I used to make this website. You
                      can take a look for yourself if you would like to learn more.
                      <ol>
                        <li>The original <a href="https://dl.acm.org/doi/pdf/10.1145/2939672.2939778">LIME paper</a> I referenced for the LIME explanations above.</li>
                        <li>The original <a href="https://arxiv.org/pdf/1810.03993">model card paper</a> I referenced in making the model cards above</li>
                        <li>This <a href="https://pytorch.org/tutorials/beginner/basics/quickstart_tutorial.html">pytorch tutorial</a> for an outline of the training process I used to train the model running on this website</li>
                      </ol>
                    </p>
                </div>
                {/* <img src="brute_force_copy.png" className="brute-force-graphic bottom-description-container"></img> */}
                {/* <div>hello</div> */}
            </div>}
        </>
    )
}

export default Resources